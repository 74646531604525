import React from 'react';
import { useTranslation } from 'react-i18next';
import DataControlAccordion from 'pages/TimeManagement/subroutes/StaffSchedules/components/DataControls/DataControlAccordion';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import OfferCard from '../OfferCard';
import { Col, Row } from 'shared/components/Layout';
import { capitalize } from 'shared/util/string';
import { Container } from 'react-bootstrap';
import {
  Application,
  ApplicationFlowType,
  ApplicationScheduleOffer,
  ApplicationScheduleOfferState,
} from 'generated/graphql';
import { useLeadsContext } from '../../LeadsContext';
import Alert from 'shared/components/Alert';
import { get } from 'lodash';

const NO_OP = () => {};

const BulkApproveModal = () => {
  const { t } = useTranslation();
  const {
    isBulkApproveModalOpen: isOpen,
    tableState,
    setIsBulkApproveModalOpen,
    approveApplicationsLoading: loading,
    handleBulkApprove,
    bulkApproveValidState,
  } = useLeadsContext();

  const selectedApplications = tableState.selectedRows as Application[];

  // all applications passed should be in the same state, either ApprovalWaitlistPending or ApprovalPending
  // so checking the first application schedule offer is sufficient to tell is for waitlisted or pending approval
  const isForWaitlistedApplications =
    get(selectedApplications, '0.children[0].offers[0].state') ===
    ApplicationScheduleOfferState.ApprovalWaitlistPending;

  const onClose = () => setIsBulkApproveModalOpen(false);

  const handleClose = () => {
    onClose();
  };

  return (
    <CenteredModal
      title={
        selectedApplications.length > 1
          ? t('enrollment.lead-management.bulk-approve-applications')
          : t('enrollment.lead-management.single-approve-application')
      }
      show={isOpen}
      onHide={handleClose}
      className="bulk-approve-modal"
      dialogClassName="p-0"
      closeOnPrimaryCallback={false}
      primaryChoice={t('enrollment.lead-management.approve')}
      primaryCallback={handleBulkApprove}
      primaryButtonProps={{ loading }}
      secondaryChoice={capitalize(t('spelling.cancel'))}
      secondaryCallback={handleClose}
      enforceFocus={false}
      size="xl"
    >
      {isForWaitlistedApplications && (
        <>
          <Alert variant="warning" className="mb-2">
            {t('enrollment.lead-management.bulk-approve-applications-warning')}
          </Alert>
          <Alert variant="info" className="mb-2">
            {t('enrollment.lead-management.bulk-approve-applications-info')}
          </Alert>
        </>
      )}

      <div>
        <Row className="offer-body">
          <Col>
            {selectedApplications.length > 1 && (
              <label>
                {t('enrollment.lead-management.approve-applications', {
                  count: selectedApplications.length,
                })}
              </label>
            )}
            {selectedApplications.map((application, index) => (
              <DataControlAccordion
                title={`${index + 1}. ${application.firstName} ${application.lastName}`}
                className="px-4 py-2 mb-2 border"
                defaultOpen={index === 0}
                key={application.id}
              >
                <Container fluid className="p-0 m-0">
                  {application.children
                    // offer states need matters
                    .filter((c) =>
                      c.offers.some((o) =>
                        bulkApproveValidState.includes((o?.state as ApplicationScheduleOfferState) ?? '')
                      )
                    )
                    .map((child, index) => {
                      const { offers } = child;
                      // offer states need matters
                      const offersCanBeApproved = offers.filter((offer) =>
                        bulkApproveValidState.includes(offer?.state as ApplicationScheduleOfferState)
                      );
                      return (
                        <OfferCard
                          applicationFlowType={ApplicationFlowType.InquireProgramEnrollmentApprove}
                          child={child}
                          // approval pending application does not allow to change
                          handleChange={NO_OP}
                          handleDayToggle={NO_OP}
                          schedules={offersCanBeApproved as ApplicationScheduleOffer[]}
                          includeChildInfo
                          isDisabled
                          centers={application.centers}
                        />
                      );
                    })}
                </Container>
              </DataControlAccordion>
            ))}
          </Col>
        </Row>
      </div>
    </CenteredModal>
  );
};

export default BulkApproveModal;
