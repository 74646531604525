import React, { useCallback, useEffect, useState } from 'react';
import PubNub from 'pubnub';
import Messenger from 'shared/components/Messenger/Messenger';
import { useGetCenterChannelsQuery, Channel, ChannelType } from 'generated/graphql';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { PubNubProvider } from 'pubnub-react';
import config from 'config';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

interface IProps {
  hasWritePermission: boolean;
  hasDeletePermission: boolean;
  authToken: string;
  authChannels: string[] | undefined;
  publishKey: string;
  subscribeKey: string;
}

const CenterMessagingTab: React.FC<IProps> = ({
  hasWritePermission,
  hasDeletePermission,
  authToken,
  authChannels,
  publishKey,
  subscribeKey,
}) => {
  const { t } = useTranslation(['comms']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId);

  const [selectedCenters, setSelectedCenters] = useState<ITableFilterOption[]>([]);
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [pollInterval, setPollInterval] = useState(1000 * 30); // initially poll every 30 seconds

  const {
    data: getCenterChannels,
    loading: loadingCenterChannels,
    error: getCenterChannelsError,
    refetch: refetchCenterChannels,
  } = useGetCenterChannelsQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    pollInterval: pollInterval,
    variables: {
      businessId: businessId ?? '',
      pageNumber: tableState.activePage,
      pageSize: tableState.pageSize,
      searchTerm: searchTerm,
      centerId: selectedCenters.length > 0 ? selectedCenters.map((c) => c.value) : authChannels,
    },
  });

  const centerChannels = getCenterChannels ? getCenterChannels?.getCenterChannels?.data : [];
  const totalCenterChannels = getCenterChannels ? getCenterChannels?.getCenterChannels?.totalRecords : 0;

  const onSearchTermChange = useCallback(
    debounce((newSearchTerm: string) => {
      setSearchTerm(newSearchTerm);
      if (tableState.activePage !== 1) tableFunctions.changePage(1, tableState.pageSize);
    }, 500),
    []
  );

  const onCentersSelect = (centerFilter: ITableFilterOption[]) => {
    if (tableState.activePage !== 1) tableFunctions.changePage(1, tableState.pageSize);
    setSelectedCenters(centerFilter);
  };

  const handleRefetch = useCallback(() => {
    refetchCenterChannels({
      businessId: businessId ?? '',
      pageNumber: 1,
      pageSize: tableState.pageSize,
      searchTerm: searchTerm,
      centerId: selectedCenters.length > 0 ? selectedCenters.map((c) => c.value) : authChannels,
    });
  }, [tableFunctions, refetchCenterChannels]);

  const onFocusFunction = () => {
    setPollInterval(1000 * 30); // poll every 30 seconds when looking at page
  };

  const onBlurFunction = () => {
    setPollInterval(1000 * 60 * 10); // poll every 10 minutes when not looking at page
  };

  useEffect(() => {
    onFocusFunction();
    window.addEventListener('focus', onFocusFunction);
    window.addEventListener('blur', onBlurFunction);

    return () => {
      onBlurFunction();
      window.removeEventListener('focus', onFocusFunction);
      window.removeEventListener('blur', onBlurFunction);
    };
  }, []);

  useEffect(() => {
    if (getCenterChannelsError)
      showToast(t('comms:getChannels.error', { channelType: 'announcement', plural: 's' }), 'error');
  }, [getCenterChannels, getCenterChannelsError]);

  const pubnub = new PubNub({
    publishKey: publishKey ?? config.pubNubConfig.publishKey,
    subscribeKey: subscribeKey ?? config.pubNubConfig.subscribeKey,
    uuid: user?.id ?? businessId ?? 'DefaultUser',
    restore: true,
    heartbeatInterval: 500,
    logVerbosity:
      businessId === 'aca26847-7862-4f3f-ad1c-f393ed8056d3' || businessId === 'ecee07be-0be7-48a3-a570-4930bec79cd5',
  });
  pubnub.setToken(authToken);
  pubnub.unsubscribeAll();

  return (
    <Messenger
      pubnub={pubnub}
      authCenters={authChannels ?? []}
      initialChannels={centerChannels ?? []}
      loadingChannels={loadingCenterChannels}
      channelsType={ChannelType.Center}
      hasWritePermission={hasWritePermission}
      hasDeletePermission={hasDeletePermission}
      dataSize={totalCenterChannels ?? 0}
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      selectedCenters={selectedCenters}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      onSearchTermChange={onSearchTermChange}
      setSelectedCenters={onCentersSelect}
      refetchChannels={handleRefetch}
    />
  );
};

export default CenterMessagingTab;
