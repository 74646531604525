import { combineReducers } from 'redux';
import { sessionReducer } from '../pages/Login/duck/reducers';
import { recoverPasswordReducer } from '../pages/RecoverPassword/duck/reducers';
import importedData from './importedData/reducers';
import user from './user/reducers';
import entities from './entities/reducers';
import employeesReducer from './employees/reducers';

import { LOGOUT } from '../pages/Login/duck/types';
import { classesReducers } from 'pages/Centers/subroutes/Classes/duck/reducers';
import { contactsReducers } from 'pages/Families/subroutes/Contacts/duck/reducers';
import { childrenReducers } from 'pages/Families/subroutes/Children/duck/reducers';
import { accountsReducers } from 'pages/Families/subroutes/Accounts/duck/reducers';
import { centersReducers } from 'pages/Centers/duck/reducers';
import { businessesReducers } from 'pages/Businesses/subroutes/BusinessProfiles/duck/reducers';
import { timeOffReducers } from 'pages/TimeManagement/subroutes/TimeOff/duck/reducers';
import { enrollmentReducers } from 'pages/Enrollment/duck/reducers';
import onBoardingReducer from './onBoarding/reducers';
import { timezoneReducers } from './timezones/reducers';
import { feesReducers } from 'pages/Centers/subroutes/Fees/duck/reducers';
import { contextReducers } from './context/reducers';
import { childAttendanceReducers } from 'pages/Attendance/duck/reducers';
import { providerReducers } from 'pages/Businesses/subroutes/BusinessProfile/duck/reducers';
import { personnelReducers } from 'pages/Businesses/subroutes/BusinessProfile/duck/reducers';
import { transactionReducers } from 'pages/BillingTransactions/duck/reducers';
import { billingSettingsReducers } from 'pages/Billing/Settings/duck/reducers';
import { profilesReducers } from 'pages/Employees/subroutes/Profiles/duck/reducers';
import iSClaimReducer from 'pages/ISCases/components/CreateISCaseClaimForm/duck/reducers';
import { discountReducers } from 'pages/BillingDiscounts/duck/reducers';
import { agencyReducers } from 'pages/USSubsidies/Agencies/duck/reducers';
import { agencyAccountChildrenReducers } from 'pages/USSubsidies/SubsidyChildren/duck/reducers';
import { paymentBatchesReducers } from 'pages/BillingPayments/duck/reducers';
import { agencyPaymentReducers } from 'pages/USSubsidies/AgencyBilling/duck/reducers';
import { busRostersReducers } from 'pages/BusRosterEdit/duck/reducers';
import { timelogReducers } from 'pages/TimeManagement/subroutes/TimeLogs/duck/reducers';
import { notificationReducers } from 'pages/NotificationsV2/duck/reducers';
import { enrolmentFieldValidationStateReducers } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/duck/reducers';
import { depositReducers } from 'pages/BillingDeposits/duck/reducers';
import { commsReducers } from 'pages/Engagement/duck/reducers';

const KangarootimeReducers = combineReducers({
  context: contextReducers, // NOTE: would like to see context + session combined. the session reducers live by the login code and refactoring seemed a bit out of scope
  session: sessionReducer,
  recoverPassword: recoverPasswordReducer,
  user,
  entities, // may want to circle back and refactor this out in the future with the introduction of the `businesses` slice
  importedData,
  classes: classesReducers,
  contacts: contactsReducers,
  children: childrenReducers,
  onBoarding: onBoardingReducer,
  accounts: accountsReducers,
  centers: centersReducers,
  businesses: businessesReducers,
  timeOff: timeOffReducers,
  enrollment: enrollmentReducers,
  timezone: timezoneReducers,
  fees: feesReducers,
  employees: employeesReducer,
  attendance: childAttendanceReducers,
  personnel: personnelReducers,
  provider: providerReducers,
  billing: combineReducers({
    transactions: transactionReducers,
    paymentBatches: paymentBatchesReducers,
    settings: billingSettingsReducers,
    agency: agencyReducers,
    agencyAccountChildren: agencyAccountChildrenReducers,
    agencyPayments: agencyPaymentReducers,
    deposits: depositReducers,
  }),
  profiles: profilesReducers,
  isCaseClaim: iSClaimReducer,
  discounts: discountReducers,
  busRosters: busRostersReducers,
  timelog: timelogReducers,
  notificationsV2: notificationReducers,
  enrolmentFormFieldState: enrolmentFieldValidationStateReducers,
  comms: commsReducers,
});

const rootReducer = (state: any, action: any) => {
  // reset redux state on logout
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return KangarootimeReducers(state, action);
};

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
