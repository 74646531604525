import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { recoverPasswordRequest } from '../duck/actions';
import Button from 'react-bootstrap/Button';
import { EmailInput } from 'shared/components/TextInput';
import { isEmailSyntaxValid, isEmailValid } from 'shared/util/email';
import errorMessage from 'shared/constants/errorMessages';

const SendEmail = () => {
  const dispatch = useDispatch();
  const [email, updateEmail] = useState('');

  const recoverPassword = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(recoverPasswordRequest(email));
    },
    [dispatch, email]
  );

  return (
    <form className="d-flex flex-column" onSubmit={recoverPassword}>
      <p className="h1 font-weight-bold mb-8">Password Recovery</p>
      <p className="mb-8">Enter the email you're using for your account.</p>
      <div className="d-flex flex-wrap mb-8">
        <EmailInput
          className="flex-grow-1 mr-2"
          label="Email Address"
          value={email}
          onChange={updateEmail}
          isInvalid={!isEmailValid(email)}
          errorText={!isEmailSyntaxValid(email) ? errorMessage.invalidEmailSyntax : errorMessage.invalidEmailDomain}
        />
        <Button disabled={!isEmailValid(email)} className="mt-7" type="submit">
          Continue
        </Button>
      </div>
      <Link
        to="/login"
        className="no-underline align-self-center title text-text-subheading-color pb-1 border-bottom border-text-subheading-color"
      >
        Back to Sign in
      </Link>
    </form>
  );
};

export default SendEmail;
