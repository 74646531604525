import * as types from './types';

export interface IDepositStateShape {
  deposits: IDeposit[];
}

const initialState: IDepositStateShape = {
  deposits: [],
};

export const depositReducers = (
  state: IDepositStateShape = initialState,
  action: types.DepositActionTypes
): IDepositStateShape => {
  switch (action.type) {
    case types.CREATE_DEPOSIT:
      return {
        ...state,
        deposits: [...state.deposits, ...action.deposits],
      };
    default:
      return state;
  }
};
