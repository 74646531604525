import React from 'react';
import * as Mui from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { useTimer } from 'use-timer';
import { useUnlockLock } from 'gql/kisiIntegration/mutations';

import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

const styles = {
  root: {
    width: '50px',
    height: '24px',
    padding: '0px',
  },
  switchBase: {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    transform: 'translateX(8px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(50px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#17AA57',
        border: 0,
        opacity: 1,
        '&:before': {
          color: 'white',
          content: "'blah'",
          display: 'flex',
          paddingLeft: '12px',
          paddingTop: '7px',
        },
      },
    },
  },
  thumb: {
    color: 'white',
    width: '20px',
    height: '20px',
    margin: '1px',
  },
  track: {
    borderRadius: '20px',
    backgroundColor: '#818181',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'white',
      fontSize: '11px',
      position: 'absolute',
      top: '6px',
    },
    '&:after': {
      content: "'ok !important'",
      left: '8px',
    },
    '&:before': {
      content: "'test'",
      right: '7px',
    },
  },
  checked: {
    color: '#23bf58 !important',
    transform: 'translateX(26px) !important',
  },
};

interface ILocksTableRowProps {
  lock: ILock;
}

const ManageLocksTableRow: React.FC<ILocksTableRowProps> = ({ lock }) => {
  const [isLocked, setIsLocked] = React.useState(true);

  const { time, start, pause, reset, status } = useTimer({
    initialTime: 5,
    timerType: 'DECREMENTAL',
  });

  const [unlockLock] = useUnlockLock({
    variables: {
      lockId: lock.lockId,
      input: {},
    },
    onCompleted: (response) => {
      if (response) {
        // showToast(response.error, 'error');
        console.log('Success: ', response);
      }
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  const handleUnlock = () => {
    unlockLock();
    setIsLocked(false);
    start();
  };

  React.useEffect(() => {
    if (time === 0) {
      reset();
      setIsLocked(true);
    }
  }, [time, status, reset]);

  return (
    <Mui.TableRow>
      <Mui.TableCell>{lock.name}</Mui.TableCell>
      <Mui.TableCell align="right">
        <Mui.FormControlLabel
          value="isLocked"
          control={
            <Mui.IconButton
              color={isLocked ? 'success' : 'default'}
              // sx={{ bgcolor: isLocked ? '#18a957' : 'inherit' }}
              aria-label="lock"
              onClick={handleUnlock}
            >
              {isLocked ? <LockIcon /> : <LockOpenIcon />}
            </Mui.IconButton>
          }
          // label={isLocked ? 'Locked' : 'Unlocked'}
          label={
            <Mui.Typography color={isLocked ? '#18a957' : 'inherit'}>{isLocked ? 'Locked' : 'Unlocked'}</Mui.Typography>
          }
          labelPlacement="start"
          checked={isLocked}
          color="red"
        />
        {status === 'RUNNING' && <Mui.Typography fontSize="0.7rem">Locking in... {time} seconds</Mui.Typography>}
      </Mui.TableCell>
    </Mui.TableRow>
  );
};

export default ManageLocksTableRow;
