import React, { useCallback } from 'react';
import { ButtonProps } from 'react-bootstrap';

interface IProps extends ButtonProps {
  id?: string;
  children: React.ReactNode | null;
  loading?: boolean;
  className?: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ButtonAsLink: React.FC<IProps> = ({ className, children, onClick, ...props }) => {
  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    },
    [onClick]
  );

  return (
    <button {...props} type="button" className={`${className || ''}  btn btn-link p-0`} onClick={handleClick}>
      {children}
    </button>
  );
};

export default ButtonAsLink;
