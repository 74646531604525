export const COMPLETE_STEP = 'COMPLETE_STEP';
export const RESET_ON_BOARDING_STATE = 'RESET_ON_BOARDING_STATE';

interface IUpdateOnboardingStatus {
  type: typeof COMPLETE_STEP;
  payload: number;
}
interface IUpdateResetOnboardingState {
  type: typeof RESET_ON_BOARDING_STATE;
}

export type OnboardingActionTypes = IUpdateResetOnboardingState | IUpdateOnboardingStatus;
