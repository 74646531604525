import { MutationHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { adviseChildNoLongerAtRiskResultFields, certificateFields } from './fields';

export const CREATE_CCSS_CERTIFICATE = gql`
  mutation($input: CreateCcssCertificateInput!) {
    createCcssCertificate(input: $input) {
      ${certificateFields}
    }
  }
`;

export const useCreateCcssCertificate = () => {
  return useMutation<{ createCcssCertificate: ICcssCertificate[] }, { input: ICreateCcssCertificateInput }>(
    CREATE_CCSS_CERTIFICATE,
    {}
  );
};

export const EDIT_CCSS_CERTIFICATE = gql`
  mutation($input: EditCcssCertificateInput!) {
    editCcssCertificate(input: $input) {
      ${certificateFields}
    }
  }
`;

export const useEditCcssCertificate = () => {
  return useMutation<{ editCcssCertificate: ICcssCertificate }, { input: IEditCcssCertificateInput }>(
    EDIT_CCSS_CERTIFICATE,
    {}
  );
};

interface IAdviseChildNoLongerAtRiskVariables {
  input: IAdviseChildNoLongerAtRiskInput;
}

export const ADVISE_CHILD_NO_LONGER_AT_RISK = () => gql`
  mutation AdviseChildNoLongerAtRisk($input: AdviseChildNoLongerAtRiskInput!) {
    adviseChildNoLongerAtRisk(input: $input) {
      ${adviseChildNoLongerAtRiskResultFields}
    }
  }
`;

export const useAdviseChildNoLongerAtRisk = (
  options?: MutationHookOptions<IAdviseChildNoLongerAtRiskData, IAdviseChildNoLongerAtRiskVariables>
) =>
  useMutation<IAdviseChildNoLongerAtRiskData, IAdviseChildNoLongerAtRiskVariables>(
    ADVISE_CHILD_NO_LONGER_AT_RISK(),
    {}
  );

interface ICancelCcssCertificateVariables {
  input: ICancelCcssCertificateInput;
}

export const CANCEL_CCSS_CERTIFICATE = (fields: string = certificateFields) => gql`
  mutation CancelCcssCertificate($input: CancelCcssCertificateInput!) {
    cancelCcssCertificate(input: $input) {
      ${fields}
    }
  }
`;

export const useCancelCcssCertificate = (
  options?: MutationHookOptions<ICancelCcssCertificateData, ICancelCcssCertificateVariables>,
  fields?: string
) => useMutation<ICancelCcssCertificateData, ICancelCcssCertificateVariables>(CANCEL_CCSS_CERTIFICATE(fields), {});
