import React, { useCallback, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import PageWrapper from 'shared/components/PageWrapper';
import Select from 'shared/components/Select';
import ReportingListView from './ReportingListView';
import ReportingDashboardView from './ReportingDashboardView';
import { ReportingCategoryEnum } from 'shared/constants/enums/reportingEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useDownloadCsv } from 'shared/hooks/useDownloadCsv';

const REPORTING_CATEGORY_OPTIONS = [
  { label: 'All Categories', value: null },
  { label: 'Finance', value: ReportingCategoryEnum.FINANCE },
  { label: 'Families', value: ReportingCategoryEnum.FAMILIES },
  { label: 'Attendance', value: ReportingCategoryEnum.ATTENDANCE },
];

type ReportingTabKeys = 'list-view' | 'dashboard-view';

interface IProps {}

const Reporting: React.FC<IProps> = ({ ...props }) => {
  const downloadCsv = useDownloadCsv();
  const [activeTab, setActiveTab] = useState<ReportingTabKeys>('list-view');
  const [activeCategoryFilter, setActiveCategoryFilter] = useState<ReportingCategoryEnum | null>(null); // null will represent "all categoryes"
  const canViewReport = useHasRoleAreaLevel({
    area: AreaType.Report,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const downloadPdf = useCallback((link: string) => {
    const download = window.open(link);
    return !(!download || download.closed || typeof download.closed == 'undefined');
  }, []);

  return (
    <PageWrapper pageTitle="Exports">
      <Tab.Container
        id="reporting-tabs-group"
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key as ReportingTabKeys)}
      >
        <div className="kt-reporting-tabs-wrapper mb-4">
          {canViewReport && (
            <Select
              options={REPORTING_CATEGORY_OPTIONS}
              value={REPORTING_CATEGORY_OPTIONS.find((opt) => opt.value === activeCategoryFilter)}
              onChange={(option) => setActiveCategoryFilter(option.value)}
              className="kt-reporting-category-select"
            />
          )}
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="list-view">
            <ReportingListView
              activeReportCategory={activeCategoryFilter}
              onDownloadCsv={downloadCsv}
              onDownloadPdf={downloadPdf}
              canViewReport={canViewReport}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="dashboard-view">
            <ReportingDashboardView onDownloadCsv={downloadCsv} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </PageWrapper>
  );
};

export default Reporting;
