//region Search_Enrollment_Form
import { QueryHookOptions, QueryResult, QueryTuple } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { InputVariable } from 'gql/program/mutation';
import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import { showToast } from 'shared/components/Toast';

const Search_Enrollment_Form = () => gql`
  query ($businessId: ID!, $input: SearchEnrollmentFormInput!) {
    searchEnrollmentForm(businessId: $businessId, input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        businessId
        formSchema
        usedByCenterIds
        id
        name
        isDraft
        version
      }
    }
  }
`;

export interface ISearchEnrollmentFormData {
  searchEnrollmentForm: ISearchEnrollmentFormPagedData;
}

interface ISearchEnrollmentFormPagedData {
  data: EnrollmentForm[];
  totalRecords: number;
}

export interface ISearchEnrollmentFormInput extends PageableDto {
  centerIds?: string[];
  includeNoCenter?: boolean;
}

type SearchInputVariables = InputVariable<ISearchEnrollmentFormInput> & {
  businessId: string;
};

export const useLazySearchEnrollmentForm = (
  options?: QueryHookOptions<ISearchEnrollmentFormData, SearchInputVariables>
): QueryTuple<ISearchEnrollmentFormData, SearchInputVariables> =>
  useLazyQuery<ISearchEnrollmentFormData, SearchInputVariables>(Search_Enrollment_Form(), {
    fetchPolicy: 'cache-and-network',
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });

export const useSearchEnrollmentForm = (
  options?: QueryHookOptions<ISearchEnrollmentFormData, SearchInputVariables>
): QueryResult<ISearchEnrollmentFormData, SearchInputVariables> =>
  useQuery<ISearchEnrollmentFormData, SearchInputVariables>(Search_Enrollment_Form(), {
    fetchPolicy: 'cache-and-network',
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });
//endregion
