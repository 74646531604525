import React, { useState, useCallback, useMemo } from 'react';
import { orderBy } from 'lodash';
import DataTable from 'shared/components/DataTable';
import ActionDropdown from 'shared/components/ActionDropdown';
import { faInfoCircle, faPencilAlt, faTimes, faTrashAlt, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { sortBy } from 'lodash';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { IconButtonCircle } from 'shared/components/Buttons';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { Box, Paper } from '@mui/material';

type TableDataFields =
  | 'name'
  | 'frequency'
  | 'chargeDay'
  | 'invoiceSchedule'
  | 'period'
  | 'additionalChargePeriod'
  | 'business'
  | 'center';

interface IProps {
  data: IBillingCycleTemplate[];
  loading: boolean;
  columns: any[]; // shape of column props from react-bootstrap-table-next
  onEdit: (template: IBillingCycleTemplate) => void;
  onDelete: (template: IBillingCycleTemplate) => void;
  onView: (template: IBillingCycleTemplate) => void;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const BillingCycleTemplatesTable: React.FC<IProps> = ({ data, loading, columns, onEdit, onDelete, onView }) => {
  const canEditBillingCylceTemplate = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.BillingCycleTemplates,
    level: RoleLevelType.Edit,
  });
  const canDeleteBillingCylceTemplate = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.BillingCycleTemplates,
    level: RoleLevelType.Delete,
  });

  const [sortField, setSortField] = useState<{ field: TableDataFields; direction: 'ASCENDING' | 'DESCENDING' }>({
    field: 'name',
    direction: 'ASCENDING',
  });
  const user = useSelector((state: RootState) => state.user);
  const { data: businessesData } = useGetAllowedEntities();
  const { data: centers } = useGetActiveCentersWithLoading();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const [selectedCenters, setSelectedCenters] = useState<ITableFilterOption[]>([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState<ITableFilterOption[]>([]);
  const filteredBillingCycles = useMemo(
    () =>
      data.filter(
        (template) =>
          selectedCenters.length === 0 ||
          !template.centerId ||
          !!selectedCenters.find((sc) => sc.value === template.centerId)
      ),
    [data, selectedCenters]
  );

  // convert the N DAYS/WEEKS/MONTHS to a representation of days so the table can be sorted properly
  const convertTimeUnitColumnToDays = useCallback(
    (unit: TimeUnit | null | undefined, amount: number | null | undefined): number => {
      if (!amount) {
        amount = 0;
      }

      let dayMultiplier = 1;

      switch (unit) {
        case 'DAYS':
          dayMultiplier = 1;
          break;
        case 'WEEKS':
          dayMultiplier = 7;
          break;
        case 'MONTHS':
          dayMultiplier = 30;
          break;
        default:
          break;
      }

      return dayMultiplier * amount;
    },
    []
  );

  const sortData = useCallback(
    (arr: IBillingCycleTemplate[]): IBillingCycleTemplate[] => {
      const direction = sortField.direction === 'ASCENDING' ? 'asc' : 'desc';

      switch (sortField.field) {
        case 'name':
          return orderBy(arr, (t) => t.name.toLowerCase(), direction);
        case 'frequency':
          return orderBy(arr, (t) => t.frequency, direction);
        case 'invoiceSchedule':
          return orderBy(
            arr,
            (t) => convertTimeUnitColumnToDays(t.invoiceSchedule.unit, t.invoiceSchedule.value),
            direction
          );
        case 'period':
          return orderBy(arr, (t) => t.period, direction);
        case 'additionalChargePeriod':
          return orderBy(
            arr,
            (t) => convertTimeUnitColumnToDays(t?.additionalChargePeriod?.unit, t?.additionalChargePeriod?.value),
            direction
          );
        case 'business':
          return orderBy(arr, (t) => t.business.name.toLowerCase(), direction);
        case 'center':
          return orderBy(arr, (t) => t.center?.name.toLowerCase() ?? '', direction);
        default:
          return arr;
      }
    },
    [sortField, convertTimeUnitColumnToDays]
  );

  const getDropdownOptionsForBillingCycles = useCallback(
    (row: IBillingCycleTemplate): IDropdownAction[] => {
      const actions: { label: string; onClick: () => void; icon: IconDefinition }[] = [];

      actions.push({ label: 'View Info', onClick: () => onView(row), icon: faInfoCircle });

      if (canEditBillingCylceTemplate) actions.push({ label: 'Edit', onClick: () => onEdit(row), icon: faPencilAlt });
      if (canDeleteBillingCylceTemplate)
        actions.push({ label: 'Delete', onClick: () => onDelete(row), icon: faTrashAlt });

      return actions;
    },
    [canEditBillingCylceTemplate, canDeleteBillingCylceTemplate, onView, onEdit, onDelete]
  );

  return (
    <div>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        {centers && centers.length > 1 && (
          <Paper
            elevation={1}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '.5rem 1rem',
              width: '100%',
            }}
          >
            {user?.isInternal && (
              <DropdownFilter
                title="Business"
                className="mr-4"
                selectedFilters={selectedBusinesses}
                options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                onFilterSelect={setSelectedBusinesses}
              />
            )}
            <DropdownFilter
              title={fieldLabels.center}
              className="mr-4"
              selectedFilters={selectedCenters}
              options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
              onFilterSelect={setSelectedCenters}
            />
            <IconButtonCircle
              icon={faTimes}
              onClick={() => {
                setSelectedBusinesses([]);
                setSelectedCenters([]);
              }}
              tooltipDirection="bottom"
              tooltipText="Clear Filters"
            />
          </Paper>
        )}
      </Box>
      <DataTable
        noPadding
        className="py-8"
        data={sortData(filteredBillingCycles)}
        dataSize={filteredBillingCycles.length}
        handleRowClick={(e, r) => onView(r)}
        showPagination={user?.isInternal ?? false}
        showLoadingOverlay={loading}
        showSelect={false}
        onSort={(field, direction) => setSortField({ field: field as TableDataFields, direction })}
        columns={[
          ...columns,
          {
            text: 'Actions',
            dataField: '',
            isDummyField: true,
            align: 'center',
            headerClasses: 'text-center',
            formatter: (cell: any, row: IBillingCycleTemplate) => (
              <ActionDropdown actions={getDropdownOptionsForBillingCycles(row)} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default BillingCycleTemplatesTable;
