import React, { useState, useCallback, useMemo } from 'react';
import { CreateProgramSteps, useProgramsContext } from '../../context/ProgramsContext';
import { useTranslation } from 'react-i18next';

import ProgramModal from '../ProgramModal';

const CreateProgramModal: React.FC = () => {
  const {
    programFormData,
    isDateValid,
    createProgramLoading,
    step,
    formErrors,
    isCreateProgramModalOpen,
    handleCreateProgram,
    setIsCreateProgramModalOpen,
    resetProgramFormData,
    setStep,
    setProgramFormData,
    programGroups,
  } = useProgramsContext();

  const { t } = useTranslation();

  const modalTitle =
    step === CreateProgramSteps.programDetails
      ? t('enrollment.programs.modal.step-1-title', {
          modalTitle: t('enrollment.programs.create-program'),
        })
      : t('enrollment.programs.modal.step-2-title', {
          modalTitle: t('enrollment.programs.create-program'),
        });

  return (
    <ProgramModal
      confirmTitle={t('enrollment.programs.create-program')}
      formData={programFormData}
      isDateValid={isDateValid}
      loading={createProgramLoading}
      handleSubmit={handleCreateProgram}
      setIsModalOpen={setIsCreateProgramModalOpen}
      step={step}
      setStep={setStep}
      formErrors={formErrors}
      isModalOpen={isCreateProgramModalOpen}
      modalTitle={modalTitle}
      resetForm={resetProgramFormData}
      setFormData={setProgramFormData}
      programGroups={programGroups}
    />
  );
};

export default CreateProgramModal;
