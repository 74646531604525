import moment from 'moment';
import React from 'react';
import ActionDropdown from 'shared/components/ActionDropdown';
import { PersonAvatar } from 'shared/components/Avatar';
import DataTable from 'shared/components/DataTable';
import { Row } from 'shared/components/Layout';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useFormatDate from 'shared/hooks/useFormatDate';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { getFullName } from 'shared/util/string';

interface IProps {
  timezone: string;
  immunizations: IImmunization[];
  loading: boolean;
  className?: string;
  onDelete: (immunization: IImmunization) => void;
  onEdit: (immunization: IImmunization) => void;
}

const ImmunizationsTable: React.FC<IProps> = ({
  immunizations,
  loading,
  className,
  timezone,
  onDelete,
  onEdit,
  ...props
}) => {
  const formatDate = useFormatDate();
  const hasEditChildDocumentsPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Documents,
    level: RoleLevelType.Edit,
  });
  const hasDeleteChildDocumentsPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Documents,
    level: RoleLevelType.Delete,
  });
  const hasViewChildDocumentsPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Documents,
    level: RoleLevelType.Read,
  });

  return loading ? (
    <DataTableLoadingSkeleton />
  ) : immunizations.length ? (
    <div className="p-32px">
      <DataTable
        keyField="id"
        data={immunizations}
        showSelect={false}
        showPagination={false}
        columns={[
          {
            text: 'Status',
            dataField: 'activeDocument.id',
            formatter: (cell: any, immunization: IImmunization) => {
              const expirationDate = immunization?.activeDocument?.expirationDate;
              const isExpired = expirationDate
                ? moment.tz(expirationDate, timezone).hours(0).isBefore(moment.tz(timezone))
                : false;
              return (
                <Row>
                  <div className={`tag px-4 text-white ${isExpired ? 'bg-danger' : 'bg-success'}`}>
                    <div className="ml-auto mr-auto">{isExpired ? 'Expired' : 'Up to Date'}</div>
                  </div>
                </Row>
              );
            },
          },
          {
            text: 'Expiry Date',
            dataField: 'activeDocument.expirationDate',
            formatter: (date: string) => (date ? formatDate(moment.tz(date, timezone), 'MM/DD/YYYY') : ''),
          },
          {
            text: 'Review Date',
            dataField: 'activeDocument.reviewDate',
            formatter: (date: string) => (date ? formatDate(date, 'MM/DD/YYYY') : ''),
          },
          ...(hasViewChildDocumentsPermission
            ? [
                {
                  text: 'File Name',
                  dataField: 'activeDocument',
                  formatter: (document: IWellnessDocument, row: any) => (
                    <a
                      key={document.id}
                      href={document.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mr-2"
                    >
                      {document.name}
                    </a>
                  ),
                },
              ]
            : []),
          {
            text: 'Submitted By',
            dataField: 'createdBy',
            classes: 'md-column',
            formatter: (cell: string, immunization: IImmunization) => (
              <div className="d-flex flex-row">
                <PersonAvatar
                  size="xs"
                  person={immunization.lastModifiedByPerson ?? immunization.createdByPerson}
                  className="mr-2"
                />
                {`${getFullName(immunization.lastModifiedByPerson ?? immunization.createdByPerson)} on ${formatDate(
                  immunization.lastModifiedAt ?? immunization.createdAt,
                  'MM/DD/YYYY'
                )}`}
              </div>
            ),
          },
          ...(hasEditChildDocumentsPermission || hasDeleteChildDocumentsPermission
            ? [
                {
                  text: 'Actions',
                  dataField: '',
                  isDummyField: true,
                  align: 'center',
                  headerClasses: 'text-center',
                  formatter: (cell: any, row: IImmunization) => (
                    <ActionDropdown
                      actions={[
                        ...(hasEditChildDocumentsPermission ? [{ label: 'Edit', onClick: () => onEdit(row) }] : []),
                        ...(hasDeleteChildDocumentsPermission
                          ? [{ label: 'Delete', onClick: () => onDelete(row) }]
                          : []),
                      ]}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </div>
  ) : (
    <div>
      There are no immunization records for this child. Click the Add Record button in the top right to add one.
    </div>
  );
};

export default ImmunizationsTable;
