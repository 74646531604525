import { ONBOARDING_STEPS } from 'shared/constants/Onboarding';
import { COMPLETE_STEP, RESET_ON_BOARDING_STATE, OnboardingActionTypes } from './types';
export interface IOnBoardingState {
  stepsStatus: Array<boolean>;
}

const stepsStatus: Array<boolean> = [];
stepsStatus[ONBOARDING_STEPS.completeProfile] = false;
stepsStatus[ONBOARDING_STEPS.addCenter] = false;
stepsStatus[ONBOARDING_STEPS.setupClass] = false;
stepsStatus[ONBOARDING_STEPS.inviteEmployee] = false;
stepsStatus[ONBOARDING_STEPS.addFamily] = false;

const initialState: IOnBoardingState = {
  stepsStatus,
};

const onBoardingReducer = (state: IOnBoardingState = initialState, action: OnboardingActionTypes) => {
  switch (action.type) {
    case COMPLETE_STEP: {
      const statuses = [...state.stepsStatus];
      statuses[action.payload] = true;
      return {
        ...state,
        stepsStatus: [...statuses],
      };
    }
    case RESET_ON_BOARDING_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default onBoardingReducer;
