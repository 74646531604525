import * as types from './types';
import { IAgencyPaymentsTableFilters } from './reducers';

export const getAgencyPayments = (
  completedPayments: IPagedResult<IAgencyPayment>,
  draftPayments: IPagedResult<IAgencyPayment>
): types.AgencyPaymentActionTypes => ({
  type: types.GET_AGENCY_PAYMENTS,
  completedPayments,
  draftPayments,
});

export const createAgencyPayment = (payment: IAgencyPayment): types.AgencyPaymentActionTypes => ({
  type: types.CREATE_AGENCY_PAYMENT,
  payment,
});

export const updateAgencyPayment = (payment: IAgencyPayment): types.AgencyPaymentActionTypes => ({
  type: types.UPDATE_AGENCY_PAYMENT,
  payment,
});

export const deleteAgencyPayment = (payment: IAgencyPayment): types.AgencyPaymentActionTypes => ({
  type: types.DELETE_AGENCY_PAYMENT,
  payment,
});

export const updateAgencyPaymentTableFilters = (
  filters: Partial<IAgencyPaymentsTableFilters>
): types.AgencyPaymentActionTypes => ({
  type: types.UPDATE_AGENCY_PAYMENT_TABLE_FILTERS,
  filters,
});
