import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { showToast } from 'shared/components/Toast';

const SEND_VERIFY_EMAIL = gql`
  mutation {
    sendEmailVerificationLinkForMe
  }
`;

const VerifyEmailAlert: React.FC<{}> = () => {
  const [sendVerifyEmailLink] = useMutation(SEND_VERIFY_EMAIL, {
    onCompleted: () => {
      showToast('Verification link sent to your inbox successfully.', 'success');
    },
  });

  return (
    <Alert className="bg-info-10">
      <Alert.Heading as="h5">
        <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
        Your email is not verified!
      </Alert.Heading>
      <p className="mb-0">
        Please click <Alert.Link onClick={() => sendVerifyEmailLink()}>here</Alert.Link> to recieve a verification link
        in your inbox.
      </p>
    </Alert>
  );
};

export default VerifyEmailAlert;
