import {
  BillingSettingsActionTypes,
  GET_TRANSACTION_TYPES,
  CREATE_TRANSACTION_TYPES,
  ARCHIVE_TRANSACTION_TYPE,
  GET_BILLING_CYCLE_TEMPLATES,
  CREATE_BILLING_CYCLE_TEMPLATE,
  UPDATE_BILLING_CYCLE_TEMPLATE,
  DELETE_BILLING_CYCLE_TEMPLATE,
  UPDATE_TRANSACTION_TYPE,
} from './types';
import { uniqBy } from 'lodash';
import i18n from 'i18n';

interface IBillingSettingsStateShape {
  transactionTypes: {
    all: ITransactionType[];
  };
  templates: {
    all: IBillingCycleTemplate[];
    byId: Record<string, IBillingCycleTemplate>;
  };
}

const initialState: IBillingSettingsStateShape = {
  transactionTypes: {
    all: [],
  },
  templates: {
    all: [],
    byId: {},
  },
};

const CHECK_TRANSACTION_TYPE_ID = '00000000-0000-0000-0000-000000000004';

export const billingSettingsReducers = (
  state: IBillingSettingsStateShape = initialState,
  action: BillingSettingsActionTypes
): IBillingSettingsStateShape => {
  switch (action.type) {
    case UPDATE_TRANSACTION_TYPE:
      return {
        ...state,
        transactionTypes: {
          ...state.transactionTypes,
          all: uniqBy([...state.transactionTypes.all, { ...action.transactionType }], (tt) => tt.id),
        },
      };
    case GET_TRANSACTION_TYPES:
    case CREATE_TRANSACTION_TYPES:
      const transactionTypes = action.transactionTypes.map((t) => {
        if (t.id === CHECK_TRANSACTION_TYPE_ID) {
          t.name = i18n.t('billing.types.check');
        }

        return t;
      });
      return {
        ...state,
        transactionTypes: {
          ...state.transactionTypes,
          all: uniqBy([...state.transactionTypes.all, ...transactionTypes], (tt) => tt.id),
        },
      };
    case ARCHIVE_TRANSACTION_TYPE:
      return {
        ...state,
        transactionTypes: {
          ...state.transactionTypes,
          all: state.transactionTypes.all.map((t) => (t.id === action.transactionType.id ? action.transactionType : t)),
        },
      };
    case GET_BILLING_CYCLE_TEMPLATES:
      return {
        ...state,
        templates: {
          ...state.templates,
          all: action.templates,
          byId: Object.fromEntries(action.templates.map((template) => [template.id, template])),
        },
      };
    case CREATE_BILLING_CYCLE_TEMPLATE:
      return {
        ...state,
        templates: {
          ...state.templates,
          byId: { ...state.templates.byId, [action.template.id]: action.template },
        },
      };
    case UPDATE_BILLING_CYCLE_TEMPLATE:
      return {
        ...state,
        templates: {
          ...state.templates,
          byId: {
            ...state.templates.byId,
            [action.template.id]: {
              ...action.template,
            },
          },
        },
      };
    case DELETE_BILLING_CYCLE_TEMPLATE:
      const filteredTemplates = state.templates.all.filter((t) => t.id !== action.template.id);

      return {
        ...state,
        templates: {
          ...state.templates,
          byId: Object.fromEntries(filteredTemplates.map((t) => [t.id, t])),
        },
      };
    default:
      return state;
  }
};
