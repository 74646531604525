import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetPaginatedTransactionTypes } from 'gql/transaction/queries';
import BillingTransactionsFilters from './BillingTransactionsFilters';
import BillingTransactionsTable from './BillingTransactionsTable';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { orderBy } from 'lodash';

import CreateNewTransaction from './CreateTransactionType';

interface INewTransactionTypeModalState {
  open: boolean;
  businessId: string | null;
  centerId: string | null;
}

interface IProps {
  canViewBusinessLevelTransactionTypes: boolean;
  showCreateModal: boolean;
  dismissCreateModal: () => void;
}

interface ISortBy {
  field: string;
  direction: SortDirection;
}

type SortDirection = 'ASCENDING' | 'DESCENDING';

const BillingTransactionsTab: React.FC<IProps> = ({
  canViewBusinessLevelTransactionTypes,
  showCreateModal,
  dismissCreateModal,
  ...props
}) => {
  const [isTuition, setIsTuition] = React.useState<boolean | null>(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [centerSearchText, setCenterSearchText] = React.useState('');
  const [selectedCenters, setSelectedCenters] = React.useState<ICenter[] | []>([]);
  const [archivedSwitch, setArchivedSwitch] = React.useState(false);

  const [sort, setSort] = React.useState<ISortBy>({
    field: 'Name',
    direction: 'ASCENDING',
  });

  const dispatch = useDispatch();
  const selectedBusinessId = useSelector((state: RootState) => state.context.businessId);

  // logic taken from CenterSelectBanner
  const { data: centers, loading: centersLoading } = useGetActiveCentersWithLoading();
  const centersForBusiness = React.useMemo(
    () =>
      orderBy(
        centers?.filter((c) => c.entityId === selectedBusinessId) || [],
        (c) => c.name.toLocaleLowerCase(),
        'asc'
      ),
    [selectedBusinessId, centers]
  );

  const {
    loading: getPaginatedTransactionTypesLoading,
    data: paginatedTransactionTypeData,
    refetch: refetchTransactionTypes,
  } = useGetPaginatedTransactionTypes({
    variables: {
      input: {
        businessId: selectedBusinessId ?? '',
        centerIds: selectedCenters.length > 0 ? selectedCenters.map((c) => c.id) : centersForBusiness.map((c) => c.id),
        page: page,
        size: size,
        manualTransactionTypesOnly: true,
        isTuition: isTuition,
        nameContains: centerSearchText,
        isArchived: archivedSwitch,
        sortDirection: sort.direction,
        sortField: sort.field,
      },
    },
    skip: !selectedBusinessId,
  });

  React.useEffect(() => {
    setPage(1);
  }, [size, centerSearchText, isTuition, selectedCenters, archivedSwitch]);

  return (
    <>
      <>
        <BillingTransactionsFilters
          key={'key'}
          index={1}
          changeRowsPerPage={(x) => setSize(x)}
          changeCenterSearchText={(x) => setCenterSearchText(x)}
          changeIsTuition={(x) => setIsTuition(x)}
          changeSelectedCenters={(x) => setSelectedCenters(x)}
          changeArchivedSwitch={(x) => setArchivedSwitch(x)}
          centers={centersForBusiness}
        />
        <BillingTransactionsTable
          transactionTypes={paginatedTransactionTypeData?.getPaginatedTransactionTypes.data ?? []}
          canViewBusinessLevelTransactionTypes={canViewBusinessLevelTransactionTypes}
          loading={getPaginatedTransactionTypesLoading}
          page={page}
          setPage={setPage}
          size={size}
          totalCount={paginatedTransactionTypeData?.getPaginatedTransactionTypes.totalRecords ?? 0}
          refetch={() => refetchTransactionTypes()}
          sort={sort}
          setSort={setSort}
        />
        <CreateNewTransaction
          isOpen={showCreateModal}
          businessId={selectedBusinessId ?? ''}
          centers={centersForBusiness}
          onClose={() => dismissCreateModal()}
          refetch={() => refetchTransactionTypes()}
        />
      </>
    </>
  );
};

export default BillingTransactionsTab;
