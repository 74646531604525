import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { setCurrentBusinessConfiguration } from 'store/context/actions';

export const GET_BUSINESS_CONFIGURATION_OPTIONS_BY_ID = gql`
  query ($id: ID!) {
    getBusinessConfigurationByBusinessId(id: $id) {
      id
      businessId
      educationEnabled
      parentContactManagementEnabled
      mealTrackingEnabled
      activityFeedReleaseSchedule
      educationFeatures {
        feature
        isEnabled
      }
      expressCheckinEnabled
    }
  }
`;

export const useGetBusinessConfigurationOptionsById = (
  options?: QueryHookOptions<{ getBusinessConfigurationByBusinessId: IBusinessConfiguration }, { id: string }>
) => {
  const dispatch = useDispatch();
  return useQuery<{ getBusinessConfigurationByBusinessId: IBusinessConfiguration }, { id: string }>(
    GET_BUSINESS_CONFIGURATION_OPTIONS_BY_ID,
    {
      fetchPolicy: 'network-only',
      ...options,
      onCompleted: (getBusinessConfigData) => {
        getBusinessConfigData &&
          dispatch(setCurrentBusinessConfiguration(getBusinessConfigData.getBusinessConfigurationByBusinessId));
      },
    }
  );
};
