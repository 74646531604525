import React from 'react';
import { Col, Row } from 'shared/components/Layout';
import moment from 'moment';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

interface INextCycleDates {
  start: string;
  end: string;
  due: string;
  invoiceSent: string;
  lateCharges: string;
}

interface IProps {
  cycle: IBillingCycleTemplate | INewBillingCycleTemplateFormData | IBillingCycleFormData;
  colSize?: number;
}

const NextCycle: React.FC<IProps> = ({ cycle, colSize, ...props }) => {
  const { t } = useTranslation();

  const dayOfWeekToNumber = useCallback((day: DayOfWeek): number => {
    switch (day) {
      case 'SUNDAY':
        return 0;
      case 'MONDAY':
        return 1;
      case 'TUESDAY':
        return 2;
      case 'WEDNESDAY':
        return 3;
      case 'THURSDAY':
        return 4;
      case 'FRIDAY':
        return 5;
      case 'SATURDAY':
        return 6;
      default:
        return 0;
    }
  }, []);

  const getAdvanceCycle = useCallback((): INextCycleDates => {
    let end;
    let due;
    let invoiceSent;

    const today = moment();
    let start = today.clone();

    switch (cycle.frequency) {
      case 'WEEKLY':
        start = today.clone().add(1, 'weeks').startOf('week');
        end = today.clone().add(1, 'weeks').endOf('week');
        due = today.clone().day(dayOfWeekToNumber(cycle.dayOfWeek as DayOfWeek));
        invoiceSent = due
          .clone()
          .subtract(
            cycle.invoiceSchedule?.value,
            cycle.invoiceSchedule?.unit === 'DAYS'
              ? 'days'
              : cycle.invoiceSchedule?.unit === 'WEEKS'
              ? 'weeks'
              : 'months'
          );
        break;
      case 'BIWEEKLY':
        start = today.clone().add(1, 'weeks').startOf('week');
        end = today.clone().add(2, 'weeks').endOf('week');
        due = today.clone().day(dayOfWeekToNumber(cycle.dayOfWeek as DayOfWeek));
        invoiceSent = due
          .clone()
          .subtract(
            cycle.invoiceSchedule?.value,
            cycle.invoiceSchedule?.unit === 'DAYS'
              ? 'days'
              : cycle.invoiceSchedule?.unit === 'WEEKS'
              ? 'weeks'
              : 'months'
          );
        break;
      case 'MONTHLY':
        start = today.clone().add(1, 'months').startOf('month');
        end = today.clone().add(1, 'months').endOf('month');

        if (cycle.dayOfMonth) {
          due = cycle.dayOfMonth !== -1 ? today.clone().date(cycle.dayOfMonth) : today.clone().endOf('month');

          invoiceSent = due
            .clone()
            .subtract(
              cycle.invoiceSchedule?.value,
              cycle.invoiceSchedule?.unit === 'DAYS'
                ? 'days'
                : cycle.invoiceSchedule?.unit === 'WEEKS'
                ? 'weeks'
                : 'months'
            );
        }

        break;
      case 'QUARTERLY':
        start = today.clone().add(1, 'months').startOf('month');
        end = today.clone().add(3, 'months').endOf('month');

        if (cycle.dayOfMonth) {
          due = cycle.dayOfMonth !== -1 ? today.clone().date(cycle.dayOfMonth) : today.clone().endOf('month');

          invoiceSent = due
            .clone()
            .subtract(
              cycle.invoiceSchedule?.value,
              cycle.invoiceSchedule?.unit === 'DAYS'
                ? 'days'
                : cycle.invoiceSchedule?.unit === 'WEEKS'
                ? 'weeks'
                : 'months'
            );
        }

        break;
    }

    return {
      start: start.format('MMM Do'),
      end: end?.format('MMM Do') ?? '--',
      due: due?.format('MMM Do') ?? '--',
      invoiceSent: invoiceSent?.format('MMM Do') ?? '--',
      lateCharges:
        due && Boolean(cycle.gracePeriodDays) ? due.add(cycle.gracePeriodDays, 'days').format('MMM Do') : '--',
    };
  }, [
    cycle.dayOfMonth,
    cycle.dayOfWeek,
    cycle.frequency,
    cycle.gracePeriodDays,
    cycle.invoiceSchedule,
    dayOfWeekToNumber,
  ]);

  const getArrearsCycle = useCallback((): INextCycleDates => {
    let end;
    let due;
    let invoiceSent;

    const today = moment();
    let start = today.clone();

    switch (cycle.frequency) {
      case 'WEEKLY':
        start = today.clone().subtract(1, 'weeks').startOf('week');
        end = start.clone().endOf('week');
        due = today.clone().day(dayOfWeekToNumber(cycle.dayOfWeek as DayOfWeek));
        invoiceSent = due
          .clone()
          .subtract(
            cycle.invoiceSchedule?.value,
            cycle.invoiceSchedule?.unit === 'DAYS'
              ? 'days'
              : cycle.invoiceSchedule?.unit === 'WEEKS'
              ? 'weeks'
              : 'months'
          );
        break;
      case 'BIWEEKLY':
        start = today.clone().subtract(2, 'weeks').startOf('week');
        end = today.clone().subtract(1, 'weeks').endOf('week');
        due = start.clone().add(1, 'weeks').endOf('week');
        due = today.clone().day(dayOfWeekToNumber(cycle.dayOfWeek as DayOfWeek));
        invoiceSent = due
          .clone()
          .subtract(
            cycle.invoiceSchedule?.value,
            cycle.invoiceSchedule?.unit === 'DAYS'
              ? 'days'
              : cycle.invoiceSchedule?.unit === 'WEEKS'
              ? 'weeks'
              : 'months'
          );
        break;
      case 'MONTHLY':
        start = today.clone().subtract(1, 'months').startOf('month');
        end = start.clone().endOf('month');

        if (cycle.dayOfMonth) {
          if (cycle.dayOfMonth !== -1) {
            due = today.clone().date(cycle.dayOfMonth);
          } else {
            due = today.clone().endOf('month');
          }

          invoiceSent = due
            .clone()
            .subtract(
              cycle.invoiceSchedule?.value,
              cycle.invoiceSchedule?.unit === 'DAYS'
                ? 'days'
                : cycle.invoiceSchedule?.unit === 'WEEKS'
                ? 'weeks'
                : 'months'
            );
        }

        break;
      case 'QUARTERLY':
        start = today.clone().subtract(3, 'months').startOf('month');
        end = today.clone().subtract(1, 'months').endOf('month');

        if (cycle.dayOfMonth) {
          due = cycle.dayOfMonth !== -1 ? today.clone().date(cycle.dayOfMonth) : today.clone().endOf('month');

          invoiceSent = due
            .clone()
            .subtract(
              cycle.invoiceSchedule?.value,
              cycle.invoiceSchedule?.unit === 'DAYS'
                ? 'days'
                : cycle.invoiceSchedule?.unit === 'WEEKS'
                ? 'weeks'
                : 'months'
            );
        }

        break;
    }

    return {
      start: start.format('MMM Do'),
      end: end?.format('MMM Do') ?? '--',
      due: due?.format('MMM Do') ?? '--',
      invoiceSent: invoiceSent?.format('MMM Do') ?? '--',
      lateCharges:
        due && Boolean(cycle.gracePeriodDays) ? due.add(cycle.gracePeriodDays, 'days').format('MMM Do') : '--',
    };
  }, [
    cycle.dayOfMonth,
    cycle.dayOfWeek,
    cycle.frequency,
    cycle.gracePeriodDays,
    cycle.invoiceSchedule,
    dayOfWeekToNumber,
  ]);

  const getCurrentCycle = useCallback((): INextCycleDates => {
    let end;
    let due;
    let invoiceSent;

    const today = moment();
    let start = today.clone();

    switch (cycle.frequency) {
      case 'WEEKLY':
        start = today.clone().startOf('week');
        end = today.clone().endOf('week');
        due = today.clone().day(dayOfWeekToNumber(cycle.dayOfWeek as DayOfWeek));
        invoiceSent = due
          .clone()
          .subtract(
            cycle.invoiceSchedule?.value,
            cycle.invoiceSchedule?.unit === 'DAYS'
              ? 'days'
              : cycle.invoiceSchedule?.unit === 'WEEKS'
              ? 'weeks'
              : 'months'
          );
        break;
      case 'BIWEEKLY':
        start = today.clone().startOf('week');
        end = today.clone().add(1, 'weeks').endOf('week');
        due = today.clone().day(dayOfWeekToNumber(cycle.dayOfWeek as DayOfWeek));

        if (cycle.frequencyPayPeriod === 'SECOND_WEEK') {
          due = due.add(1, 'week');
        }

        invoiceSent = due
          .clone()
          .subtract(
            cycle.invoiceSchedule?.value,
            cycle.invoiceSchedule?.unit === 'DAYS'
              ? 'days'
              : cycle.invoiceSchedule?.unit === 'WEEKS'
              ? 'weeks'
              : 'months'
          );
        break;
      case 'MONTHLY':
        start = today.clone().startOf('month');
        end = today.clone().endOf('month');

        if (cycle.dayOfMonth) {
          due = cycle.dayOfMonth !== -1 ? today.clone().date(cycle.dayOfMonth) : today.clone().endOf('month');

          invoiceSent = due
            .clone()
            .subtract(
              cycle.invoiceSchedule?.value,
              cycle.invoiceSchedule?.unit === 'DAYS'
                ? 'days'
                : cycle.invoiceSchedule?.unit === 'WEEKS'
                ? 'weeks'
                : 'months'
            );
        }

        break;
      case 'QUARTERLY':
        start = today.clone().startOf('month');
        end = today.clone().add(2, 'months').endOf('month');

        if (cycle.dayOfMonth) {
          due = cycle.dayOfMonth !== -1 ? today.clone().date(cycle.dayOfMonth) : today.clone().endOf('month');

          if (cycle.frequencyPayPeriod === 'SECOND_MONTH') {
            due = due.add(1, 'month');
          }

          if (cycle.frequencyPayPeriod === 'THIRD_MONTH') {
            due = due.add(2, 'month');
          }

          invoiceSent = due
            .clone()
            .subtract(
              cycle.invoiceSchedule?.value,
              cycle.invoiceSchedule?.unit === 'DAYS'
                ? 'days'
                : cycle.invoiceSchedule?.unit === 'WEEKS'
                ? 'weeks'
                : 'months'
            );
        }

        break;

      case 'CUSTOM':
        if (cycle.billingPeriods && cycle.billingPeriods.length > 0) {
          start = moment(cycle.billingPeriods[0].startDate);
          end = moment(cycle.billingPeriods[0].endDate);
          due = moment(cycle.billingPeriods[0].paymentDueDate);
          invoiceSent = due
            .clone()
            .subtract(
              cycle.invoiceSchedule?.value,
              cycle.invoiceSchedule?.unit === 'DAYS'
                ? 'days'
                : cycle.invoiceSchedule?.unit === 'WEEKS'
                ? 'weeks'
                : 'months'
            );
        }

        break;
    }

    return {
      start: start.format('MMM Do'),
      end: end?.format('MMM Do') ?? '--',
      due: due?.format('MMM Do') ?? '--',
      invoiceSent: invoiceSent?.format('MMM Do') ?? '--',
      lateCharges:
        due && Boolean(cycle.gracePeriodDays) ? due.add(cycle.gracePeriodDays, 'days').format('MMM Do') : '--',
    };
  }, [
    cycle.frequency,
    cycle.gracePeriodDays,
    cycle.dayOfWeek,
    cycle.invoiceSchedule,
    cycle.frequencyPayPeriod,
    cycle.dayOfMonth,
    dayOfWeekToNumber,
  ]);

  const dates =
    cycle.period === 'ADVANCE' ? getAdvanceCycle() : cycle.period === 'ARREARS' ? getArrearsCycle() : getCurrentCycle();

  return (
    <div>
      <Row noGutters className="">
        <Col xs={colSize ?? 12}>
          <Row noGutters className="">
            <b>
              {capitalize(t('spelling.payments'))} {t('spelling.for')} {capitalize(t('spelling.service'))}:
            </b>
          </Row>
          <Row className="align-items-start mb-4 ml-1">
            {dates.start} - {dates.end}
          </Row>
        </Col>
        <Col xs={colSize ?? 12}>
          <Row noGutters className="">
            <b>
              {capitalize(t('spelling.payments'))} {t('spelling.due')}:
            </b>
          </Row>
          <Row noGutters className="align-items-start mb-4 ml-1">
            {dates.due}
          </Row>
        </Col>
        <Col xs={colSize ?? 12}>
          <Row noGutters className="">
            <b>
              {capitalize(t('spelling.itemized'))} {t('spelling.bill')} {t('spelling.sent')}:
            </b>
          </Row>
          <Row noGutters className="align-items-start mb-4 ml-1">
            {dates.invoiceSent}
          </Row>
        </Col>
        <Col xs={colSize ?? 12}>
          <Row noGutters className="">
            <b>Late charges applied:</b>
          </Row>
          <Row noGutters className="align-items-start mb-4 ml-1">
            {dates.lateCharges}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NextCycle;
