import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';

interface IDestroyBusinessData {
  destroyBusiness: string; // the id
}

interface IDestroyBusinessVariables {
  id: string;
}

export const DESTROY_BUSINESS = gql`
  mutation ($id: ID!) {
    destroyBusiness(id: $id)
  }
`;

export const useDestroyBusiness = (options?: MutationHookOptions<IDestroyBusinessData, IDestroyBusinessVariables>) =>
  useMutation<IDestroyBusinessData, IDestroyBusinessVariables>(DESTROY_BUSINESS, options);
