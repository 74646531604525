import {
  EnrollmentActionTypes,
  UPDATE_ENROLLMENT_DASHBOARD_CLASSES_DATA,
  UPDATE_ENROLLMENT_TABLE_WEEK,
  UPDATE_INCLUDE_FUTURE_CONTRACTS,
  UPDATE_INCLUDE_LTB_OFFERS,
  UPDATE_VISIBLE_CENTER_FOR_ENROLLMENT,
  UPDATE_VISIBLE_CLASS_FOR_ENROLLMENT,
} from './types';
import moment from 'moment';

export const updateEnrollmentTableWeek = (
  startOfWeek: moment.Moment,
  endOfWeek: moment.Moment
): EnrollmentActionTypes => ({
  type: UPDATE_ENROLLMENT_TABLE_WEEK,
  startOfWeek,
  endOfWeek,
});

// allow business id for internal users
export const updateVisibleCenterForEnrollment = (centerId: string, businessId?: string): EnrollmentActionTypes => ({
  type: UPDATE_VISIBLE_CENTER_FOR_ENROLLMENT,
  centerId,
  businessId,
});

export const updateEnrollmentDashboardClassesData = (classes: IClass[]): EnrollmentActionTypes => ({
  type: UPDATE_ENROLLMENT_DASHBOARD_CLASSES_DATA,
  classes,
});

export const updateVisibleClassForEnrollment = (_class: IClass): EnrollmentActionTypes => ({
  type: UPDATE_VISIBLE_CLASS_FOR_ENROLLMENT,
  _class,
});

export const updateIncludeFutureContracts = (includeFutureContracts: boolean): EnrollmentActionTypes => ({
  type: UPDATE_INCLUDE_FUTURE_CONTRACTS,
  includeFutureContracts,
});

export const updateIncludeLtbOffers = (includeLtbOffers: boolean): EnrollmentActionTypes => ({
  type: UPDATE_INCLUDE_LTB_OFFERS,
  includeLtbOffers,
});
