import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { pagedPersonnel } from './fields';

interface IGetProviderPersonnel {
  getPersonnelByProviderId: IPagedResult<ICcssPersonnel>;
}

interface IGetServicePersonnel {
  getPersonnelByServiceId: IPagedResult<ICcssPersonnel>;
}

interface IGetProviderPersonnelVariables {
  businessId: string;
  providerId: string;
  pageNumber?: number;
  pageSize?: number;
  sortDtos?: IElasticsearchSortFilter[];
  name?: string;
}

interface IGetServicePersonnelVariables {
  businessId: string;
  serviceId: string;
  pageNumber: number;
  pageSize: number;
  sortDtos?: IElasticsearchSortFilter[];
  name?: string;
}

export const GET_PROVIDER_PERSONNEL = gql`
  query($businessId: ID!, $providerId: ID!, $pageNumber: Int, $pageSize: Int) {
    getPersonnelByProviderId(businessId: $businessId, providerId: $providerId, pageNumber: $pageNumber, pageSize: $pageSize) {
      ${pagedPersonnel}
    }
  }
`;

export const GET_SERVICE_PERSONNEL = gql`
  query($businessId: ID!, $serviceId: ID!, $pageNumber: Int!, $pageSize: Int!) {
    getPersonnelByServiceId(businessId: $businessId, serviceId: $serviceId, pageNumber: $pageNumber, pageSize: $pageSize) {
      ${pagedPersonnel}
    }
  }
`;

export const useGetProviderPersonnel = (
  options?: QueryHookOptions<IGetProviderPersonnel, IGetProviderPersonnelVariables>
) =>
  useQuery<IGetProviderPersonnel, IGetProviderPersonnelVariables>(GET_PROVIDER_PERSONNEL, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetServicePersonnel = (
  options?: QueryHookOptions<IGetServicePersonnel, IGetServicePersonnelVariables>
) =>
  useQuery(GET_SERVICE_PERSONNEL, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
