import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IChildAccountDetails } from './ChildEmergencyContacts';
import { Col, Row } from 'shared/components/Layout';
import { useGetExpectedSessionsForAccountChild } from 'gql/session/queries';
import moment from 'moment';
import Avatar from 'shared/components/Avatar';
import { getFullName, stringToHueDegree } from 'shared/util/string';
import AccountLink from 'shared/components/AccountLink/AccountLink';
import Button, { ButtonAsLink, IconButton } from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faPen, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import Tooltip from 'shared/components/Tooltip';
import { useUpdateChildEmergencyContacts } from '../../../graphql/mutations';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import PhoneInput from 'shared/components/PhoneInput';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import Alert from 'shared/components/Alert';
import Checkbox from 'shared/components/Checkbox';
import { showToast } from 'shared/components/Toast';

interface IProps {
  child: IChild;
  currentChildEmergencyContactIds: string[];
  childAccountDetails: IChildAccountDetails;
  allChildAccounts: IChildAccountDetails[];
  handleBeginEditing: () => void;
  handleStopEditing: () => void;
  editingAccountId?: string | null | undefined;
}

const EmergencyContactsForm: React.FC<IProps> = ({
  child,
  currentChildEmergencyContactIds,
  childAccountDetails,
  allChildAccounts,
  handleBeginEditing,
  handleStopEditing,
  editingAccountId,
}) => {
  const todaysDate = moment().format('YYYY-MM-DD');
  const [accountEmergencyContactIds, setAccountEmergencyContactIds] = useState<string[]>([]);
  const [confirmAcknowledgement, setConfirmAcknowledgement] = useState<boolean>(false);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);

  const [updateChildEmergencyContacts, { loading }] = useUpdateChildEmergencyContacts(child);

  const { data: sessionsForAccountChild, loading: loadingSessionForAccountChild } =
    useGetExpectedSessionsForAccountChild(
      {
        variables: {
          input: {
            accountChildId: childAccountDetails.accountChildId,
            startDate: todaysDate,
            endDate: todaysDate,
          },
        },
      },
      'id contractId date timeEntries { id date timeIn timeOut } class { id careType } accountId'
    );

  const todaySessionContactsText = useMemo(() => {
    const sessionCareTypes = sessionsForAccountChild?.getExpectedSessionsForAccountChildInTimeframe
      .filter((session) => session.class?.careType !== null)
      .map((session) => session.class?.careType);

    if (sessionCareTypes && sessionCareTypes.length > 0) {
      if (sessionCareTypes.length > 2) return 'Today’s Contacts';

      switch (sessionCareTypes[0]) {
        case 'BSC':
          return 'Morning Contacts';
        case 'ASC':
          return 'Afternoon Contacts';
        case 'LDC':
        case 'VAC':
        case 'NON':
        default:
          return 'Today’s Contacts';
      }
    }
    return null;
  }, [sessionsForAccountChild]);

  const readOnly = useMemo(() => {
    return !(editingAccountId === childAccountDetails.accountId);
  }, [editingAccountId]);

  const getEmergencyContactsOtherAccounts = useCallback(
    (contactIds: string[]) => {
      return contactIds.map((contactId) => {
        const contact = childAccountDetails.accountContactsForChild.find((c) => c.id === contactId);
        const otherAccounts = allChildAccounts
          .filter(
            (account) =>
              account.accountId !== childAccountDetails.accountId &&
              account.accountContactsForChild.some((ac) => ac.id === contactId)
          )
          .map((account) => account.accountName);

        return {
          contactId: contactId,
          contactName: getFullName(contact),
          OtherAccountNames: otherAccounts,
        };
      });
    },
    [childAccountDetails]
  );

  const [addedEmergencyContacts, removeEmergencyContacts] = useMemo(() => {
    const currentAccountEmergencyContactIds = childAccountDetails.currentAccountEmergencyContactIds;
    const addedIds = accountEmergencyContactIds.filter(
      (id) => !currentAccountEmergencyContactIds.includes(id) && id !== ''
    );
    const removedIds = currentAccountEmergencyContactIds.filter(
      (id) => !accountEmergencyContactIds.includes(id) && id !== ''
    );

    const addedContacts = getEmergencyContactsOtherAccounts(addedIds);
    const removedContacts = getEmergencyContactsOtherAccounts(removedIds);

    return [addedContacts, removedContacts];
  }, [accountEmergencyContactIds]);

  const [showWarning, showWarningAddedContacts, showWarningRemovedContacts] = useMemo(() => {
    const addedContactsOnOtherAccounts = addedEmergencyContacts.some((contact) => contact.OtherAccountNames.length > 0);
    const removedContactsOnOtherAccounts = removeEmergencyContacts.some(
      (contact) => contact.OtherAccountNames.length > 0
    );

    return [
      addedContactsOnOtherAccounts || removedContactsOnOtherAccounts,
      addedContactsOnOtherAccounts,
      removedContactsOnOtherAccounts,
    ];
  }, [childAccountDetails, addedEmergencyContacts, removeEmergencyContacts]);

  const contactIsPrimaryOnAnotherAccount = useCallback((contactId: string) => {
    return allChildAccounts.some(
      (account) =>
        account.accountId !== childAccountDetails.accountId &&
        account.accountContactsForChild.some((ac) => ac.id === contactId && ac.isPrimary)
    );
  }, []);

  const cancelEditing = useCallback(() => {
    setAccountEmergencyContactIds(
      childAccountDetails.accountContactsForChild
        .filter((c) => currentChildEmergencyContactIds.some((ec) => ec === c.id))
        .map((c) => c.id)
    );
    setFormIsDirty(false);
    setConfirmAcknowledgement(false);
    handleStopEditing();
  }, [childAccountDetails, handleStopEditing]);

  const addEmergencyContact = useCallback(() => {
    setAccountEmergencyContactIds((prev) => [...prev, '']);
    setFormIsDirty(true);
    setConfirmAcknowledgement(false);
  }, []);

  const updateEmergencyContact = useCallback((contactId: string, index: number) => {
    setAccountEmergencyContactIds((prev) => prev.map((id, i) => (i === index ? contactId : id)));
    setFormIsDirty(true);
    setConfirmAcknowledgement(false);
  }, []);

  const removeEmergencyContact = useCallback((index: number) => {
    setAccountEmergencyContactIds((prev) => prev.filter((id, i) => i !== index));
    setFormIsDirty(true);
    setConfirmAcknowledgement(false);
  }, []);

  const submitForm = useCallback(() => {
    updateChildEmergencyContacts({
      variables: {
        input: {
          id: child.id,
          toAdd: addedEmergencyContacts.map((c) => c.contactId),
          toRemove: removeEmergencyContacts.map((c) => c.contactId),
        },
      },
    })
      .then(() => {
        showToast('Emergency contacts updated successfully.', 'success');
        setFormIsDirty(false);
        setConfirmAcknowledgement(false);
        handleStopEditing();
      })
      .catch(() => {
        showToast('There was an error updating emergency contacts. Please try again later.', 'error');
      });
  }, [child.id, addedEmergencyContacts, removeEmergencyContacts, updateChildEmergencyContacts]);

  const contactCanPickup = useCallback(
    (contactId: string): boolean => {
      const contact = child.contacts?.find((c) => c.id === contactId);
      return contact?.accountPermissions?.some((ap) => ap.permissions.includes('Pickup')) ?? false;
    },
    [child]
  );

  useEffect(() => {
    setAccountEmergencyContactIds(childAccountDetails.currentAccountEmergencyContactIds);
  }, [childAccountDetails]);

  return (
    <div className="emergency-contact-form mb-5">
      <Row noGutters className="mb-3">
        {todaySessionContactsText !== null && (
          <div>
            <small className="session-pill px-2 py-1 rounded border sm mr-2">{todaySessionContactsText}</small>
          </div>
        )}
        {/* {sessionContactTypes.length == 1 &&
          sessionContactTypes.map((contactType) => {
            return (
              <div>
                <small className="session-pill px-2 py-1 rounded border sm mr-2">{contactType}</small>
              </div>
            );
          })} */}
      </Row>
      <Row noGutters justify="between" className="mb-3 pb-3 account-section">
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            <Avatar
              color={`hsl(${stringToHueDegree(childAccountDetails.accountName)}, ${
                stringToHueDegree(childAccountDetails.accountName) < 50 ? '100%' : '40%'
              }, 40%`}
              size={'md'}
              initials={childAccountDetails.accountName.charAt(0).toUpperCase()}
              image={''}
              className="mr-3"
            />
            <div>
              <p className="small-font mb-0">Account name:</p>
              <p className="mb-0">
                <AccountLink
                  accountId={childAccountDetails.accountId}
                  accountName={childAccountDetails.accountName}
                  openInNewTab
                />
              </p>
              <p className="small-font mb-0">
                <i>{childAccountDetails.centerName}</i>
              </p>
            </div>
          </div>
        </div>
        <div>
          {editingAccountId === childAccountDetails.accountId && ( // currently editing this account
            <ButtonAsLink className="sm mr-auto" onClick={() => addEmergencyContact()}>
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add emergency contact
            </ButtonAsLink>
          )}
          {editingAccountId !== null &&
            editingAccountId !== undefined &&
            editingAccountId !== childAccountDetails.accountId && ( // editing another account
              <Tooltip
                text={'This account cannot be edited until the other account changes are completed.'}
                direction={'left'}
              >
                <div className="sm mr-auto">
                  <FontAwesomeIcon icon={faPen} className="mr-2" />
                  Edit
                </div>
              </Tooltip>
            )}
          {(editingAccountId === null || editingAccountId === undefined) && ( // not editing yet
            <ButtonAsLink className="sm mr-auto" onClick={() => handleBeginEditing()}>
              <FontAwesomeIcon icon={faPen} className="mr-2" />
              Edit
            </ButtonAsLink>
          )}
        </div>
      </Row>
      {accountEmergencyContactIds.length <= 0 && (
        <Row noGutters>
          <p className={'mb-0'}>
            <b>No emergency contacts have been added to this account</b>
          </p>
          <p>
            Add a contact by clicking on the 'Edit' button above to ensure the right people will be contacted in an
            emergency.
          </p>
        </Row>
      )}
      {accountEmergencyContactIds.length > 0 &&
        accountEmergencyContactIds.map((contactId, index) => {
          const contact = childAccountDetails.accountContactsForChild.find((c) => c.id === contactId);
          const disableRemoveButton = readOnly || contactIsPrimaryOnAnotherAccount(contactId);
          const tooltipText =
            !readOnly && contactIsPrimaryOnAnotherAccount(contactId)
              ? 'This contact is a primary contact on another account and cannot be removed.'
              : currentChildEmergencyContactIds.length === 1
              ? 'This contact cannot be removed as at least one emergency contact is required'
              : undefined;

          return (
            <>
              <Row align="start" key={`child-emergency-contact-${index}`}>
                <Col sm={11}>
                  <Select
                    label="Name"
                    options={childAccountDetails.accountContactsForChild.map((c) => ({
                      value: c.id,
                      label: getFullName(c),
                      canPickup: contactCanPickup(c.id),
                      isPrimary: c.isPrimary,
                    }))}
                    value={contact?.id}
                    isOptionDisabled={(o: any) => accountEmergencyContactIds.includes(o.value) || !o.canPickup}
                    formatOptionLabel={(option: any) => (
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="mr-4">{option.label}</div>
                        <div className="d-flex flex-row align-items-center">
                          {option.isPrimary && (
                            <div className="ml-auto">
                              <small className="contact-pill mr-2 px-2 py-1 rounded border sm">Primary</small>
                            </div>
                          )}
                          {option.canPickup && (
                            <div className="ml-auto">
                              <small className="bg-info-10 px-2 py-1 rounded border sm">Permitted to Pick Up</small>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    onChange={(o: any) => updateEmergencyContact(o.value, index)}
                    disabled={disableRemoveButton}
                  />
                </Col>
                <Col sm={1}>
                  <IconButton
                    icon={faTrashAlt}
                    onClick={() => removeEmergencyContact(index)}
                    className="mt-8"
                    disabled={disableRemoveButton || currentChildEmergencyContactIds.length === 1}
                    tooltipText={tooltipText}
                    tooltipDirection={'left'}
                  />
                </Col>
              </Row>
              {contact && (
                <Row>
                  <Col>
                    <TextInput
                      label="Relationship"
                      value={contact?.relationshipType ? childContactRelationship[contact.relationshipType] : undefined}
                      onChange={() => {}}
                      disabled={true}
                    />
                  </Col>
                  <Col>
                    <PhoneInput
                      label="Phone Number"
                      value={contact?.primaryPhoneNumber?.number}
                      onChange={() => {}}
                      disabled={true}
                    />
                  </Col>
                </Row>
              )}
            </>
          );
        })}
      {showWarning && formIsDirty && (
        <Alert className="ec-warning" variant="warning" header="The following actions will be applied once saved">
          <div>
            {showWarningAddedContacts &&
              addedEmergencyContacts
                .filter((contact) => contact.OtherAccountNames.length > 0)
                .map((contact) => (
                  <div>
                    <p>{contact.contactName} will also be added as an Emergency Contact to the following accounts:</p>
                    <ul>
                      {contact.OtherAccountNames.map((accountName) => (
                        <li>{accountName}</li>
                      ))}
                    </ul>
                  </div>
                ))}
            {showWarningRemovedContacts &&
              removeEmergencyContacts
                .filter((contact) => contact.OtherAccountNames.length > 0)
                .map((contact) => (
                  <div>
                    <p>{contact.contactName} will also be removed as an Emergency Contact to the following accounts:</p>
                    <ul>
                      {contact.OtherAccountNames.map((accountName) => (
                        <li>{accountName}</li>
                      ))}
                    </ul>
                  </div>
                ))}
            <hr />
            <Checkbox
              label="I acknowledge these actions and wish to proceed."
              value={confirmAcknowledgement}
              onChange={(checked) => {
                setConfirmAcknowledgement(checked);
              }}
              className="mb-4"
            />
          </div>
        </Alert>
      )}
      {editingAccountId === childAccountDetails.accountId && (
        <Row noGutters justify="end" className="mt-4 pt-3 submit-ec-section">
          <Button className={'mr-4'} variant="light" onClick={() => cancelEditing()} disabled={false}>
            Cancel
          </Button>
          <Button
            disabled={(showWarning && !confirmAcknowledgement) || !formIsDirty}
            loading={loading}
            onClick={() => submitForm()}
            type="submit"
          >
            Submit
          </Button>
        </Row>
      )}
    </div>
  );
};

export default EmergencyContactsForm;
