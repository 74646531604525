import React, { useCallback, useEffect, useState } from 'react';
import { useCreateMessageAttachment, useMarkMessageAttachment } from 'gql/communications/mutations';
import { IMessageAttachment } from 'shared/types/channel';

interface IResponse {
  loading: boolean;
  data: IMessageAttachment | null;
  error: any;
}

type AttachmentMetaData = {
  centerId: string;
  businessId: string;
  fileName: string;
  mimetype: string;
};

type UploadAttachmentFn = (file: File, attachmentData: AttachmentMetaData) => Promise<void>;

const useUploadMessageAttachmentFile = (): [UploadAttachmentFn, IResponse] => {
  const [response, setResponse] = useState<IResponse>({
    loading: false,
    data: null,
    error: null,
  });
  const [file, setFile] = useState<File | null>(null);
  const [fileUploadedToS3, setFileUploadedToS3] = useState<Boolean>(false);

  const [attachmentMetaData, setAttachmentMetaData] = useState<AttachmentMetaData | null>();

  const [createAttachmentFn, presignedUrlResponse] = useCreateMessageAttachment();
  const [markMessageAttachmentFn, markMessageAttachmentFnResponse] = useMarkMessageAttachment();

  const requestPresignedUrlFn = useCallback(
    async (file, attachmentData) => {
      setFileUploadedToS3(false);
      setResponse({ ...response, loading: true });
      try {
        setFile(file);
        setAttachmentMetaData(attachmentData);
        await createAttachmentFn({
          variables: {
            businessId: attachmentData?.businessId ?? '',
            input: {
              businessId: attachmentData?.businessId ?? '',
              centerId: attachmentData?.centerId ?? '',
              fileName: attachmentData?.fileName ?? '',
              mimetype: attachmentData?.mimetype ?? '',
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    [createAttachmentFn]
  );

  const presignedUrl = React.useMemo(() => {
    return presignedUrlResponse.data?.createMessageAttachment?.presignedUploadUrl;
  }, [presignedUrlResponse]);

  useEffect(() => {
    (async () => {
      try {
        if (file && presignedUrl) {
          if (!fileUploadedToS3) {
            const formData = new FormData();
            //formData.append('Content-Type', (file as File).type);
            // formData.append('file', file as File);
            await fetch(presignedUrl, {
              method: 'PUT',
              body: file,
            });
            setFileUploadedToS3(true);
            var putAttachmentResult = await markMessageAttachmentFn({
              variables: {
                businessId: attachmentMetaData?.businessId ?? '',
                centerId: attachmentMetaData?.centerId ?? '',
                attachmentId: presignedUrlResponse?.data?.createMessageAttachment.id ?? '',
              },
            });
            setResponse({
              data: putAttachmentResult.data?.markMessageAttachmentUploaded ?? null,
              error: putAttachmentResult.errors,
              loading: false,
            });
          } else if (attachmentMetaData && !presignedUrl && presignedUrlResponse) {
            setResponse({
              error: presignedUrlResponse.error,
              data: null,
              loading: false,
            });
          }
        }
      } catch (error) {
        setFile(null);
        setAttachmentMetaData(null);
        setResponse({ error, data: null, loading: false });
      }
    })();
  }, [attachmentMetaData, presignedUrl, file, fileUploadedToS3, presignedUrlResponse, markMessageAttachmentFn]);

  return [requestPresignedUrlFn, response];
};

export default useUploadMessageAttachmentFile;
