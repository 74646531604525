import i18next from 'i18next';
import absenceReasons, {
  additionalAbsenceReasons,
} from '../../../../../shared/constants/dropdownOptions/absenceReasons';
import moment from 'moment';

interface EndCareEnrollmentMetrics {
  getEndCareEnrollmentMetrics: {
    accountCount: number | undefined;
    sessionCount: number | undefined;
    totalDue: number | undefined;
    sessionIds: string[];
  };
}

export interface IEndCareEnrollmentExpandRowProps {
  row: {
    accountId: string;
    accountName: string;
    centerId: string;
    centerName: string;
    accountChildId: string;
    childId: string;
    childFirstName: string;
    childLastName: string;
    enrollmentId: string;
    totalDue: string | number;
    transactionId: string;
    sessionIds: string[];
  };
  actionType?: string;
  refetch: () => void;
}

export const endOfCareActiveTableColumns = [
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.date'),
    dataField: 'date',
    classes: 'md-column py-4 mw-100',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.class'),
    dataField: 'className',
    classes: 'md-column py-4 mw-100',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.care-type'),
    dataField: 'careType',
    classes: 'md-column py-4 mw-100',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.reason'),
    dataField: 'absenceReason',
    classes: 'md-column py-4 mw-100',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.fee'),
    dataField: 'sessionCost',
    classes: 'md-column py-4 mw-100',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.subsidy-amount'),
    dataField: 'subsidyAmount',
    classes: 'md-column py-4 text-center mw-100',
  },
];

export const HeadersForEndOfCareActiveTable = [
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.child-name'),
    dataField: 'childFirstName',
    sort: true,
    classes: 'md-column py-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.accounts'),
    dataField: 'accountName',
    sort: true,
    classes: 'md-column py-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.enrollment-id'),
    dataField: 'enrollmentId',
    sort: false,
    classes: 'md-column py-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.status'),
    dataField: 'status',
    sort: false,
    classes: 'md-column py-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.center'),
    dataField: 'centerName',
    sort: true,
    classes: 'md-column py-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.active-tab-header.total-due'),
    dataField: `totalDue`,
    sort: false,
    classes: 'md-column py-4',
  },
];

export const eoCareCardsData = (getEndCareEnrollmentMetricsInput: EndCareEnrollmentMetrics) => [
  {
    id: 1,
    name: i18next.t('subsidies:end-care.active-tab-cards.title.total-due'),
    description: i18next.t('subsidies:end-care.active-tab-cards.description.total-due'),
    amount: `${getEndCareEnrollmentMetricsInput?.getEndCareEnrollmentMetrics?.totalDue?.toFixed(2) ?? 0}`,
    finances: true,
  },
  {
    id: 2,
    name: i18next.t('subsidies:end-care.active-tab-cards.title.accounts'),
    description: i18next.t('subsidies:end-care.active-tab-cards.description.accounts'),
    amount: getEndCareEnrollmentMetricsInput?.getEndCareEnrollmentMetrics.accountCount ?? 0,
    finances: false,
  },
  {
    id: 3,
    name: i18next.t('subsidies:end-care.active-tab-cards.title.sessions'),
    description: i18next.t('subsidies:end-care.active-tab-cards.description.sessions'),
    amount: getEndCareEnrollmentMetricsInput?.getEndCareEnrollmentMetrics.sessionCount ?? 0,
    finances: false,
  },
];

export const HeadersForEndOfCareHistoryTable = [
  {
    text: i18next.t('subsidies:end-care.table-headers.history-tab-header.action-date'),
    dataField: 'date',
    sort: true,
    classes: 'md-column py-4 mw-100 pr-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.history-tab-header.action-by'),
    dataField: 'actionBy',
    sort: false,
    classes: 'md-column py-4 mw-100 pr-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.history-tab-header.action-type'),
    dataField: 'actionType',
    sort: true,
    classes: 'md-column py-4 mw-100 pr-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.history-tab-header.child-name'),
    dataField: 'childFirstName',
    sort: true,
    classes: 'md-column py-4 mw-100 pr-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.history-tab-header.account'),
    dataField: 'accountName',
    sort: true,
    classes: 'md-column py-4 mw-100 pr-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.history-tab-header.center'),
    dataField: 'centerName',
    sort: true,
    classes: 'md-column py-4 mw-100 pr-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.history-tab-header.enrollment-id'),
    dataField: 'enrollmentId',
    sort: false,
    classes: 'md-column py-4 mw-100 pr-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.history-tab-header.status'),
    dataField: 'status',
    sort: false,
    classes: 'md-column py-4 mw-100 pr-4',
  },
  {
    text: i18next.t('subsidies:end-care.table-headers.history-tab-header.total-due'),
    dataField: 'totalDue',
    sort: false,
    classes: 'md-column py-4 mw-100 text-center',
  },
];

export const actionTypesForHistory = [
  { label: i18next.t('subsidies:end-care.action-types.unhandled'), value: 'UNHANDLED' },
  { label: i18next.t('subsidies:end-care.action-types.hide'), value: 'HIDE' },
  { label: i18next.t('subsidies:end-care.action-types.show'), value: 'REVEAL' },
  { label: i18next.t('subsidies:end-care.action-types.void'), value: 'VOID' },
  { label: i18next.t('subsidies:end-care.action-types.charge-full-fee'), value: 'CHARGE_FULL_FEE' },
];

export const careTypesForHistory = [
  { label: i18next.t('subsidies:end-care.care-types.after-school-care'), value: 'ASC' },
  { label: i18next.t('subsidies:end-care.care-types.before-school-care'), value: 'BSC' },
  { label: i18next.t('subsidies:end-care.care-types.long-day-care'), value: 'LDC' },
  { label: i18next.t('subsidies:end-care.care-types.vacation-care'), value: 'VAC' },
  { label: i18next.t('subsidies:end-care.care-types.no-ccs'), value: 'No CCS' },
];

export const enrollmentStatusesForTable = [
  { label: i18next.t('subsidies:end-care.enrollment-status.approved'), value: 'APPROV' },
  { label: i18next.t('subsidies:end-care.enrollment-status.ceased'), value: 'CEASED' },
  { label: i18next.t('subsidies:end-care.enrollment-status.confirmed'), value: 'CONFIR' },
  { label: i18next.t('subsidies:end-care.enrollment-status.disputed'), value: 'DISPUT' },
  { label: i18next.t('subsidies:end-care.enrollment-status.manual-review'), value: 'MANUAL' },
  { label: i18next.t('subsidies:end-care.enrollment-status.not-approved'), value: 'NOTAPP' },
  { label: i18next.t('subsidies:end-care.enrollment-status.pending-eligibility'), value: 'PENDEL' },
  { label: i18next.t('subsidies:end-care.enrollment-status.pending-confirmation'), value: 'PENDIN' },
  { label: i18next.t('subsidies:end-care.enrollment-status.received'), value: 'RECEIV' },
  { label: i18next.t('subsidies:end-care.enrollment-status.rejected'), value: 'REJECT' },
  { label: i18next.t('subsidies:end-care.enrollment-status.withdrawn'), value: 'WITHDR' },
];

const absenceTypeForTable = [
  { label: i18next.t('subsidies:end-care.action-types.additional'), value: 'ADDITIONAL' },
  { label: i18next.t('subsidies:end-care.action-types.standart'), value: 'STANDARD' },
];

export const getAbsenceReasonOptions = (absenceType: string, reason: string) => {
  if (absenceType === null && reason === null) return '';
  if (absenceType && reason === null) {
    const data = absenceTypeForTable.find((i) => i.value === absenceType);
    return data?.label;
  }

  if (absenceType === 'ADDITIONAL') {
    const data = additionalAbsenceReasons.filter((i) => i.value === reason);
    if (Boolean(data.length)) return data[0].label;
  } else {
    const data = absenceReasons.filter((i) => i.value === reason);
    if (Boolean(data.length)) return data[0].label;
  }
};

export const formatDate = (date: string, timezone: string) => {
  return moment(date).tz(timezone).format('LL');
};

export const getActionType = (action: string) => {
  if (action == null) return '';

  const data = actionTypesForHistory.filter((i) => i.value === action);
  if (Boolean(data.length)) return data[0].label;
};

export const getCareType = (care: string) => {
  if (care == null) return '';

  const data = careTypesForHistory.filter((i) => i.value === care);
  if (Boolean(data.length)) return data[0].label;
};

export const getEnrollmentStatus = (status: string | null) => {
  if (status == null) return '';

  const data = enrollmentStatusesForTable.filter((i) => i.value === status);
  if (Boolean(data.length)) return data[0].label;
};
