import { GET_USER_SUCCESS, UserActionTypes } from './types';
import User from 'shared/types/user';

const initialState = null;

const userReducer = (state: User | null = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case GET_USER_SUCCESS: {
      return action.user;
    }
    default:
      return state;
  }
};

export default userReducer;
