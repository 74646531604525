import React from 'react';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import _ from 'lodash';
import { useCreateApiKeyWebHook, useUpdateApiKeyWebHook } from '../../../../gql/apiKeyWebHooks/mutations';
import { showToast } from 'shared/components/Toast';
import { IIntegrationApiKey, IApiKeyWebHook } from './ApiKey';

interface IProps {
  addHookDialogOpen: boolean;
  setAddHookDialogOpen: (val: boolean) => void;
  refetchKeys: () => void;
  apiKey: IIntegrationApiKey;
}

const newWebHook: any = {
  id: '',
  name: '',
  url: '',
  webHookType: '',
  webHookApiKey: '',
};

const AddApiKeyWebHookDialog: React.FC<IProps> = ({
  addHookDialogOpen,
  setAddHookDialogOpen,
  refetchKeys,
  apiKey,
  ...props
}) => {
  const [webhook, setWebHook] = React.useState(newWebHook);
  const [createApiKeyHookfn] = useCreateApiKeyWebHook({
    onCompleted: () => {
      setAddHookDialogOpen(false);
      refetchKeys();
      showToast('Webhook created', 'success');
    },
    onError: (e) => {
      showToast(e.message, 'error');
    },
  });

  const [updateApiKeyHookfn] = useUpdateApiKeyWebHook({
    onCompleted: () => {
      setAddHookDialogOpen(false);
      refetchKeys();
      showToast('Webhook updated', 'success');
    },
    onError: (e) => {
      showToast(e.message, 'error');
    },
  });

  const onSubmit = () => {
    if (apiKey.apiKeyWebHooks?.length > 0) {
      updateApiKeyHookfn({
        variables: {
          input: {
            id: webhook.id,
            apiKeyId: apiKey.id,
            businessId: apiKey.businessId,
            url: webhook.url,
            webHookType: webhook.type,
            webHookApiKey: webhook.apikey,
          },
        },
      });
    } else {
      createApiKeyHookfn({
        variables: {
          input: {
            apiKeyId: apiKey.id,
            businessId: apiKey.businessId,
            url: webhook.url,
            webHookType: webhook.type,
            webHookApiKey: webhook.apikey,
          },
        },
      });
    }
  };

  React.useEffect(() => {
    if (apiKey.apiKeyWebHooks?.length > 0) {
      const wh = apiKey.apiKeyWebHooks[0];
      setWebHook({ id: wh.id, name: '', type: wh.webHookType, url: wh.url, apikey: wh.webHookApiKey! });
    }
  }, [apiKey.apiKeyWebHooks]);

  return (
    <>
      <Mui.Dialog
        PaperProps={{
          sx: {
            position: 'absolute',
            height: '100%',
            width: '33.3%',
            right: -32,
            overflow: 'visible',
            maxHeight: '100%',
          },
        }}
        open={addHookDialogOpen}
        onClose={() => setAddHookDialogOpen(false)}
      >
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography display="flex" variant="h4" sx={{ alignItems: 'center' }}>
            {apiKey.apiKeyWebHooks?.length > 0 ? 'Edit Key WebHook' : 'Create Key WebHook'}
          </Mui.Typography>
          <Mui.IconButton onClick={(e) => setAddHookDialogOpen(false)}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%' }}>
          <Mui.Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
            <Mui.TextField
              fullWidth
              value={webhook.url}
              id="outlined-basic"
              label="Hook Url"
              variant="outlined"
              onChange={(e: any) => setWebHook((prev) => ({ ...prev, url: e.target.value }))}
            />
            <Mui.TextField
              fullWidth
              value={webhook.type}
              id="outlined-basic"
              label="Hook Type (enter Account/Class/Contact/Movement)"
              variant="outlined"
              onChange={(e: any) => setWebHook((prev) => ({ ...prev, type: e.target.value }))}
            />
            <Mui.TextField
              fullWidth
              value={webhook.apikey}
              id="outlined-basic"
              label="Hook Api Key"
              variant="outlined"
              onChange={(e: any) => setWebHook((prev) => ({ ...prev, apikey: e.target.value }))}
            />
          </Mui.Stack>
        </Mui.DialogContent>
        <Mui.Box style={{ margin: '8px' }}>
          <Mui.DialogActions>
            <Mui.Button onClick={(e) => setAddHookDialogOpen(false)}>Cancel</Mui.Button>
            <Mui.Button color="secondary" variant="contained" onClick={() => onSubmit()}>
              Save
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Box>
      </Mui.Dialog>
    </>
  );
};

export default AddApiKeyWebHookDialog;
