import React, { useCallback, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faUpload, faEllipsisV, faPencilAlt, faPlus } from '@fortawesome/pro-light-svg-icons';
import Card from 'shared/components/Card';
import TextInput from 'shared/components/TextInput';
import { IconButton } from 'shared/components/Buttons';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import ActionDropdown from 'shared/components/ActionDropdown';

interface IProps {
  title: string;
  color: string;
  data: IHealthTerm[];
  onSubmit: (name: string) => void;
  onArchive: (term: IHealthTerm) => void;
  onUnarchive: (term: IHealthTerm) => void;
  onEdit?: (term: IHealthTerm) => void;
  loading: boolean;
}

const HealthWellnessTypeCard: React.FC<IProps> = ({
  title,
  color,
  data,
  onSubmit,
  onArchive,
  onUnarchive,
  onEdit,
  loading,
  ...props
}) => {
  const [typeName, setTypeName] = useState<string>('');
  const handleSubmit = useCallback(
    (value: string) => {
      setTypeName('');
      onSubmit(value);
    },
    [onSubmit]
  );

  const renderAdditionalAction = useCallback(
    (item: IHealthTerm): JSX.Element | null => {
      // renders an empty button so that the colored box maintains the same width as the others
      if (item.archivedAt && !onEdit) {
        return (
          <IconButton
            icon={faUpload}
            onClick={() => onUnarchive(item)}
            className="px-3 ml-2 bg-white fg-gray"
            tooltipText="Unarchive Type?"
          />
        );
      }

      if (onEdit) {
        return (
          <ActionDropdown
            className="flex-grow-0"
            customIconButton={<IconButton icon={faEllipsisV} className="px-3 ml-2 bg-white" />}
            actions={[
              ...(!item.archivedAt
                ? [
                    {
                      label: 'Edit Allergens',
                      icon: faPencilAlt,
                      onClick: () => onEdit(item),
                    },
                  ]
                : []),
              ...(!item.archivedAt
                ? [
                    {
                      label: 'Archive',
                      icon: faArchive,

                      onClick: () => onArchive(item),
                    },
                  ]
                : [
                    {
                      label: 'Unarchive',
                      icon: faUpload,
                      onClick: () => onUnarchive(item),
                    },
                  ]),
            ]}
          />
        );
      }

      return (
        <IconButton
          icon={faArchive}
          onClick={() => onArchive(item)}
          className="px-3 ml-2 bg-white"
          tooltipText="Archive Type?"
        />
      );
    },
    [onEdit, onArchive]
  );

  return (
    <Card header={title} bodyClassName="p-4 d-flex flex-column" className="kt-healthwellness-item-card">
      <div className="d-flex flex-column flex-1">
        <div className="kt-healthwellness-item-list-container">
          {loading ? (
            <LoadingLines number={6} />
          ) : (
            <ul className="unstyled-list kt-healthwellness-item-list">
              {data.map((item: IHealthTerm, idx: number) => (
                <li key={`${title}-item-${idx}`} className="mb-1">
                  <div
                    className="text-white text-overflow-ellipsis"
                    style={{ backgroundColor: color, opacity: item.archivedAt ? 0.5 : 1 }}
                  >
                    {item.name}
                  </div>
                  {renderAdditionalAction(item)}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="d-flex flex-column flex-1 justify-content-end">
          <div>
            <hr className="horizontal-divider mb-4" />
          </div>
          <InputGroup className="flex-nowrap">
            <TextInput
              placeholder="+ Add New"
              value={typeName}
              onChange={(value: string) => setTypeName(value)}
              name={`${title}-type`}
              className="mb-0"
            />
            <InputGroup.Prepend className="ml-1">
              <Button
                onClick={() => handleSubmit(typeName)}
                className="px-3 rounded h-100"
                variant="secondary"
                disabled={typeName.trim() === ''}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </InputGroup.Prepend>
          </InputGroup>
        </div>
      </div>
    </Card>
  );
};

export default HealthWellnessTypeCard;
