import React, { useCallback } from 'react';
import RBCard from 'react-bootstrap/Card';
import RBTable from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import Checkbox from 'shared/components/Checkbox';
import { RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { capitalize } from 'shared/util/string';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';

interface IProps {
  permissionGroupLabel: string;
  permissions: IRoleAreaLevel[];
  canEditRole: boolean;
  onPermissionLevelChange: (permission: IRoleAreaLevel, level: RoleLevelType) => void;
}

const RolePermissionGroup: React.FC<IProps> = ({
  permissionGroupLabel,
  permissions,
  canEditRole,
  onPermissionLevelChange,
  ...props
}) => {
  const { t, i18n } = useTranslation(['translation', 'permissions']);

  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;
  const AdpFeature = Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.subFeatures ?? undefined;
  const AdpTimeEnabled = AdpFeature?.find((x) => x.type === 'TimeAndAttendance')?.enabled ?? false;
  const getAreaLevelLabel = useCallback(
    (areaLevel: IRoleAreaLevel): string => {
      // @ts-ignore - TypeScript doesn't like the dynamic lookup
      return t(`permissions:area-levels.${areaLevel.area.toLowerCase()}.${areaLevel.permission.toLowerCase()}.label`);
    },
    [t]
  );

  const getAreaLevelDescription = useCallback(
    (areaLevel: IRoleAreaLevel): string => {
      // @ts-ignore - TypeScript doesn't like the dynamic lookup
      return t(
        // @ts-ignore - TypeScript doesn't like the dynamic lookup
        `permissions:area-levels.${areaLevel.area.toLowerCase()}.${areaLevel.permission.toLowerCase()}.description`
      );
    },
    [t]
  );

  function isPermissionActive(areaLevel: IRoleAreaLevel) {
    // @ts-ignore - TypeScript doesn't like the dynamic lookup
    return i18n.exists(`permissions:area-levels.${areaLevel.area.toLowerCase()}.${areaLevel.permission.toLowerCase()}`);
  }

  const hasInheritedPermission = useCallback((areaLevel: IRoleAreaLevel, level: RoleLevelType): boolean => {
    return (areaLevel.level & level) === level;
  }, []);

  return (
    <RBCard className="m-0">
      <RBCard.Header>{permissionGroupLabel}</RBCard.Header>
      <RBCard.Body className="p-4 border-bottom">
        <RBTable responsive className="kt-role-permission-group-table">
          <thead>
            <tr>
              <th>{capitalize(t('translation:spelling.permission'))}</th>
              <th>{capitalize(t('translation:spelling.read-only'))}</th>
              <th>{capitalize(t('translation:spelling.edit'))}</th>
              <th>{capitalize(t('translation:spelling.create'))}</th>
              <th>{capitalize(t('translation:spelling.delete'))}</th>
            </tr>
          </thead>
          <tbody>
            {permissions
              .filter((al) => isPermissionActive(al))
              .filter((al) => getAreaLevelDescription(al) !== 'Coming Soon!')
              .map((areaLevel: IRoleAreaLevel, idx: number) => (
                <tr key={`role-area-level-group-${idx}`}>
                  <td>
                    <div className="kt-role-permission-label">{getAreaLevelLabel(areaLevel)}</div>
                    <div className="kt-role-permission-sublabel">{getAreaLevelDescription(areaLevel)}</div>
                  </td>
                  <td>
                    <Checkbox
                      value={hasInheritedPermission(areaLevel, RoleLevelType.Read)}
                      onChange={(checked: boolean) =>
                        onPermissionLevelChange(areaLevel, checked ? RoleLevelType.Read : RoleLevelType.None)
                      }
                      disabled={hasInheritedPermission(areaLevel, RoleLevelType.Edit) || !canEditRole}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={
                        hasInheritedPermission(areaLevel, RoleLevelType.Edit) &&
                        (areaLevel.permission === 'PayRate' ? !AdpEnabled : true) &&
                        (areaLevel.permission === 'TimeLog' ? !AdpTimeEnabled : true) &&
                        (areaLevel.permission === 'TimeOff' ? !AdpTimeEnabled : true)
                      }
                      onChange={(checked: boolean) =>
                        onPermissionLevelChange(areaLevel, checked ? RoleLevelType.Edit : RoleLevelType.None)
                      }
                      disabled={
                        hasInheritedPermission(areaLevel, RoleLevelType.Create) ||
                        !canEditRole ||
                        (areaLevel.permission === 'PayRate' ? AdpEnabled : false) ||
                        (areaLevel.permission === 'TimeLog' ? AdpTimeEnabled : false) ||
                        (areaLevel.permission === 'TimeOff' ? AdpTimeEnabled : false)
                      }
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={
                        hasInheritedPermission(areaLevel, RoleLevelType.Create) &&
                        (areaLevel.permission === 'PayRate' ? !AdpEnabled : true) &&
                        (areaLevel.permission === 'TimeLog' ? !AdpTimeEnabled : true) &&
                        (areaLevel.permission === 'TimeOff' ? !AdpTimeEnabled : true)
                      }
                      onChange={(checked: boolean) =>
                        onPermissionLevelChange(areaLevel, checked ? RoleLevelType.Create : RoleLevelType.None)
                      }
                      disabled={
                        hasInheritedPermission(areaLevel, RoleLevelType.Delete) ||
                        !canEditRole ||
                        (areaLevel.permission === 'PayRate' ? AdpEnabled : false) ||
                        (areaLevel.permission === 'TimeLog' ? AdpTimeEnabled : false) ||
                        (areaLevel.permission === 'TimeOff' ? AdpTimeEnabled : false)
                      }
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={
                        hasInheritedPermission(areaLevel, RoleLevelType.Delete) &&
                        (areaLevel.permission === 'PayRate' ? !AdpEnabled : true) &&
                        (areaLevel.permission === 'TimeLog' ? !AdpTimeEnabled : true) &&
                        (areaLevel.permission === 'TimeOff' ? !AdpTimeEnabled : true)
                      }
                      onChange={(checked: boolean) =>
                        onPermissionLevelChange(areaLevel, checked ? RoleLevelType.Delete : RoleLevelType.None)
                      }
                      disabled={
                        !canEditRole ||
                        (areaLevel.permission === 'PayRate' ? AdpEnabled : false) ||
                        (areaLevel.permission === 'TimeLog' ? AdpTimeEnabled : false) ||
                        (areaLevel.permission === 'TimeOff' ? AdpTimeEnabled : false)
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </RBTable>
      </RBCard.Body>
    </RBCard>
  );
};

export default RolePermissionGroup;
