import React, { useState, useCallback } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Row, Col } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import { isBlank } from 'shared/util/string';
import errorMessage from 'shared/constants/errorMessages';

const DEFAULT_POSITION_TYPES: ISelectMenuItem[] = [
  { value: 'Z7', label: 'Chairperson' },
  { value: 'Z8', label: 'Chief Executive Officer' },
  { value: 'Z9', label: 'Child Care Service Director' },
  { value: 'Z10', label: 'Company Director' },
  { value: 'Z11', label: 'Company Financial Officer' },
  { value: 'Z12', label: 'Company Secretary' },
  { value: 'Z13', label: 'Coordinator' },
  { value: 'Z14', label: 'Executive Officer' },
  { value: 'Z15', label: 'General Manager' },
  { value: 'Z16', label: 'Manager' },
  { value: 'Z17', label: 'Nominated Supervisor' },
  { value: 'Z18', label: 'Operator' },
  { value: 'Z19', label: 'President' },
  { value: 'Z20', label: 'Principal' },
  { value: 'Z21', label: 'Program Manager' },
  { value: 'Z22', label: 'Treasurer' },
  { value: 'Z23', label: 'Other' },
];

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  businessId: string;
}

const CreatePersonnelModal: React.FC<IProps> = ({ isOpen, onClose, businessId }) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [positionType, setPositionType] = useState<PersonnelPositionType>('Z7');
  const [startDate, setStartDate] = useState<string>('');

  const formDisabled: boolean = isBlank(firstName) || isBlank(lastName) || isBlank(startDate);

  const resetForm = useCallback(() => {
    setFirstName('');
    setLastName('');
    setPositionType('Z7');
    setStartDate('');
  }, []);

  const dismissModal = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  // todo: saving

  return (
    <SideModalDrawer
      title="New Personnel"
      show={isOpen}
      onHide={dismissModal}
      primaryChoice="Update"
      // primaryCallback={() => {
      //   save(personnel);
      //   resetForm();
      // }}
      secondaryChoice="Cancel"
      secondaryCallback={dismissModal}
      primaryButtonProps={{ disabled: formDisabled }}
      footerHelperText={formDisabled ? errorMessage.formRequirements : ''}
    >
      <div className="mb-4">
        <small>Add personnel with management and control rights</small>
      </div>

      <Row>
        <Col>
          <TextInput required label="First Name" onChange={setFirstName} value={firstName}></TextInput>
        </Col>
        <Col>
          <TextInput required label="Last Name" onChange={setLastName} value={lastName}></TextInput>
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            label="Position Type"
            options={DEFAULT_POSITION_TYPES}
            onChange={(option: ISelectMenuItem) => setPositionType(option.value)}
            value={positionType}
          ></Select>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <DateInput required label="Start Date" date={startDate} onDateSelect={setStartDate} />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default CreatePersonnelModal;
