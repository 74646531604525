import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import PageWrapper from 'shared/components/PageWrapper';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import CenterEnrollmentAvailabilityCard from './components/CenterEnrollmentAvailabilityCard';
import { RootState } from 'store/reducers';
import { updateVisibleCenterForEnrollment } from 'pages/Enrollment/duck/actions';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';

interface IProps extends RouteComponentProps<{}, any, {}> {}

const Enroll: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);

  const selectedCenterId = useSelector((state: RootState) => state.enrollment.activeCenter.id);
  const startOfWeek = useSelector((state: RootState) => state.enrollment.activeTimeframe.start);
  const endOfWeek = useSelector((state: RootState) => state.enrollment.activeTimeframe.end);
  const classesData = useSelector((state: RootState) => state.enrollment.dashboard.availabilityDataset);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const navigateToCenterEnrollmentOverview = useCallback(
    (_: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      history.push('/enrollment/management');
    },
    [history]
  );

  useEffect(() => {
    currentBusinessId && dispatch(updateVisibleCenterForEnrollment('', currentBusinessId));
  }, [dispatch, currentBusinessId]);

  useEffect(() => {
    currentCenterId && dispatch(updateVisibleCenterForEnrollment(currentCenterId));
  }, [dispatch, currentCenterId]);

  return (
    <PageWrapper pageTitle={fieldLabels.enrollment} applyPadding={false}>
      <CenterSelectBanner pageName={fieldLabels.enrollment.toLowerCase()} />
      <PageWrapperBody>
        <Row>
          <Column>
            <CenterEnrollmentAvailabilityCard
              data={classesData}
              onClick={navigateToCenterEnrollmentOverview}
              currentCenterId={selectedCenterId}
              initialStartOfWeek={startOfWeek}
              initialEndOfWeek={endOfWeek}
            />
          </Column>
          {/* <Column xl={5}> */}
          {/* revenue dashboard card */}
          {/* </Column> */}
        </Row>
      </PageWrapperBody>
    </PageWrapper>
  );
};

export default Enroll;
