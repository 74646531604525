import React, { useCallback, useState, useEffect } from 'react';
import TextInput from 'shared/components/TextInput';
import { Row, Col } from 'shared/components/Layout';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { useUpdateAccount } from 'gql/account/mutations';
import { showToast } from 'shared/components/Toast';

interface IProps {
  account: IAccount;
  readOnly: boolean;
}

const AccountInformation: React.FC<IProps> = ({ account: savedAccount, readOnly }) => {
  const [account, updateAccount] = useState(savedAccount);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [updateAccountFn, { loading }] = useUpdateAccount();

  const handleChange = useCallback(
    (value: any, name: string) => {
      updateAccount({ ...account, [name]: value });
      setFormIsDirty(true);
    },
    [account]
  );

  useEffect(() => {
    !formIsDirty && updateAccount(savedAccount);
  }, [formIsDirty, savedAccount]);

  const save = useCallback(() => {
    updateAccountFn({
      variables: { input: { id: account.id, name: account.name } },
    })
      .then(() => {
        showToast('Account updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch(() => {
        showToast('There was an error updating the account. Please try again later.', 'error');
      });
  }, [account.id, account.name, updateAccountFn]);

  return (
    <FormWrapper2
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onSave={save}
      onCancel={() => updateAccount(savedAccount)}
      loading={loading}
    >
      <Row align="start">
        <Col>
          <TextInput
            label="Account Name"
            value={account.name}
            onChange={(value) => handleChange(value, 'name')}
            disabled={readOnly}
          />
        </Col>
        <Col>{''}</Col>
      </Row>
    </FormWrapper2>
  );
};

export default AccountInformation;
