export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE';
export const REVERT_DAYS_OF_CARE = 'REVERT_DAYS_OF_CARE';

export const UPDATE_DAYS_OF_CARE_NF2F_HOURS = 'UPDATE_DAYS_OF_CARE_NF2F_HOURS';
export const UPDATE_DAYS_OF_CARE_F2F_HOURS = 'UPDATE_DAYS_OF_CARE_F2F_HOURS';
export const UPDATE_DAYS_OF_CARE = 'UPDATE_DAYS_OF_CARE';

export const UPDATE_EDUCATORS = 'UPDATE_EDUCATORS';
export const ADD_TEMPORARY_EDUCATOR = 'ADD_TEMPORARY_EDUCATOR';
export const DELETE_TEMPORARY_EDUCATOR = 'DELETE_TEMPORARY_EDUCATOR';

export const UPDATE_TEMPORARY_EDUCATOR_HOURS = 'UPDATE_TEMPORARY_EDUCATOR_HOURS';
export const UPDATE_EDUCATOR_F2F_HOURS = 'UPDATE_EDUCATOR_F2F_HOURS';
export const UPDATE_EDUCATOR_NONF2F_HOURS = 'UPDATE_EDUCATOR_NONF2F_HOURS';
export const UPDATE_TEMPORARY_EDUCATOR_F2F_HOURS = 'UPDATE_TEMPORARY_EDUCATOR_F2F_HOURS';
export const UPDATE_TEMPORARY_EDUCATOR_NONF2F_HOURS = 'UPDATE_TEMPORARY_EDUCATOR_NONF2F_HOURS';
export const RESET_CLAIM_FORM = 'RESET_CLAIM_FORM';

interface ISetFormValues {
  type: typeof SET_FORM_VALUES;
  formValues: ISCaseClaimCreate;
}

interface ISetFieldValue {
  type: typeof SET_FIELD_VALUE;
  name: keyof ISCaseClaimCreate;
  value: any;
}

interface IAddTempEducator {
  type: typeof ADD_TEMPORARY_EDUCATOR;
}

interface IUpdateEducator {
  type: typeof UPDATE_EDUCATORS;
  educators: IStaff[];
}

interface IUpdateEducatorF2FHours {
  type: typeof UPDATE_EDUCATOR_F2F_HOURS | typeof UPDATE_TEMPORARY_EDUCATOR_F2F_HOURS;
  educator: string;
  day: DayOfCare;
  faceToFaceHours: number;
}

interface IUpdateEducatorNonF2FHours {
  type: typeof UPDATE_EDUCATOR_NONF2F_HOURS | typeof UPDATE_TEMPORARY_EDUCATOR_NONF2F_HOURS;
  educator: string;
  day: DayOfCare;
  nonFaceToFaceHours: number;
}

interface IDeleteTempEducator {
  type: typeof DELETE_TEMPORARY_EDUCATOR;
  index: number;
}

interface IUpdateDaysOfCareF2FHours {
  type: typeof UPDATE_DAYS_OF_CARE_F2F_HOURS;
  index: number;
  f2f: number;
}

interface IUpdateDaysOfCareNF2FHours {
  type: typeof UPDATE_DAYS_OF_CARE_NF2F_HOURS;
  index: number;
  nf2f: number;
}

interface IUpdateDaysOfCare {
  type: typeof UPDATE_DAYS_OF_CARE;
  index: number;
  day: DayOfCare;
}

interface IResetClaimForm {
  type: typeof RESET_CLAIM_FORM;
}

export type ISClaimActionTypes =
  | ISetFieldValue
  | ISetFormValues
  | IAddTempEducator
  | IUpdateEducator
  | IUpdateEducatorF2FHours
  | IUpdateEducatorNonF2FHours
  | IDeleteTempEducator
  | IUpdateDaysOfCareF2FHours
  | IUpdateDaysOfCareNF2FHours
  | IUpdateDaysOfCare
  | IResetClaimForm;
