import React, { useCallback, useState } from 'react';
import ApplicationCenterSettingsTable from './ApplicationCenterSettingsTable';
import {
  ApplicationFlowType,
  CenterSettings,
  EnrollmentOptions,
  SortDirection,
  useGetApplicationCenterSettingsPaginatedQuery,
} from 'generated/graphql';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useDatatableState from 'shared/hooks/useDatatableState';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import { STATES, US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  flowType: ApplicationFlowType;
}

export interface IFormattedApplicationCenterSetting extends CenterSettings {
  id: string;
  address?: { state?: { keyword?: string } | null } | null;
}

const ApplicationCenterSettings: React.FC<IProps> = ({ flowType, ...props }) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const [formattedTableData, setFormattedTableData] = useState<IFormattedApplicationCenterSetting[]>([]);
  const [totalResults, setTotalResults] = useState<number>(0);

  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('center', initalTableSort);

  const { data, loading, error, refetch } = useGetApplicationCenterSettingsPaginatedQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      input: {
        filter: { [SEARCH_EXPRESSIONS.ALL]: [...tableState.searchExpressions, ...tableState.filterExpressions] },
        from: tableState.currentItemOffset,
        size: tableState.pageSize,
        sort: [
          {
            field: 'name.keyword',
            direction: SortDirection.Ascending,
          },
        ],
      },
    },
    onCompleted: (resp) => {
      const formattedInitialData = resp?.searchCenters.data.map((center) => {
        const address = {
          ...center.address,
          state: { keyword: STATES[center.address?.state ?? ''] },
        };

        return {
          id: center.id,
          centerName: center.name,
          address: address,
          businessId: center.applicationSetting?.businessId ?? businessId ?? '',
          centerId: center.id,
          feeType: center.applicationSetting?.feeType,
          feeAmount: center.applicationSetting?.feeAmount ?? 0,
          askFamilyAboutSubsidy: center.applicationSetting?.askFamilyAboutSubsidy ?? false,
          enrollmentOptions: center.applicationSetting?.enrollmentOptions ?? EnrollmentOptions.PermanentOnly,
        };
      });
      setFormattedTableData(formattedInitialData ?? []);
      setTotalResults(resp.searchCenters.totalResults);
    },
    onError: (err) => showToast(t('enrollment:reenroll-center-settings.get-error'), 'error'),
  });

  return (
    <ApplicationCenterSettingsTable
      flowType={flowType}
      tableData={formattedTableData}
      dataLoading={loading}
      totalResults={totalResults}
      handleRefetch={() =>
        refetch({
          input: {
            filter: { [SEARCH_EXPRESSIONS.ALL]: [...tableState.searchExpressions, ...tableState.filterExpressions] },
            from: tableState.currentItemOffset,
            size: tableState.pageSize,
            sort: [
              {
                field: 'name.keyword',
                direction: SortDirection.Ascending,
              },
            ],
          },
        })
      }
      tableState={tableState}
      tableFunctions={tableFunctions}
    />
  );
};

export default ApplicationCenterSettings;
