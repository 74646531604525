import { gql, useQuery } from '@apollo/client';
import { appliedAccountDiscountFields } from 'gql/discount/fields';

interface IGetDiscountTabData {
  getAccountById: IAccount;
}

interface IGetDiscountTabDataVariables {
  id: string;
}

const discountTabFields: string = `
id
centerId
entityId
familyId
discounts {
  ${appliedAccountDiscountFields}
}
`;

const GET_DISCOUNT_TAB_DATA_BY_ACCOUNT_ID = gql`
  query ($id: ID!) {
    getAccountById(id: $id) {
      ${discountTabFields}
    }
  }
`;

export const useGetDiscountTabDataByAccountId = (accountId: string) =>
  useQuery<IGetDiscountTabData, IGetDiscountTabDataVariables>(GET_DISCOUNT_TAB_DATA_BY_ACCOUNT_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id: accountId,
    },
  });

interface IGetAppliedAccountDiscountsAuthData {
  getAppliedAccountDiscountsForAccount: IAccount;
}

interface IGetAppliedAccountDiscountsAuthVariables {
  accountId: string;
}

const discountAuthFields: string = `
id
accountId
discountAuth
`;

const GET_ACCOUNT_APPLIED_DISCOUNTS_AUTH_BY_ACCOUNT_ID = gql`
  query ($accountId: ID!) {
    getAppliedAccountDiscountsForAccount(accountId: $accountId) {
      ${discountAuthFields}
    }
  }
`;

export const useGetAppliedAccountDiscountsAuthByAccountId = (accountId: string) =>
  useQuery<IGetAppliedAccountDiscountsAuthData, IGetAppliedAccountDiscountsAuthVariables>(
    GET_ACCOUNT_APPLIED_DISCOUNTS_AUTH_BY_ACCOUNT_ID,
    {
      variables: {
        accountId,
      },
    }
  );
