import * as types from './types';

const initialState = {
  isEmailSent: false,
  isPasswordReset: false,
};

export const recoverPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        message: null,
        isEmailSent: true,
      };
    case types.CREATE_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordReset: true,
      };
    case types.RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        message: action.error.message,
      };
    case types.CREATE_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        message: action.error.message,
      };
    case types.CLEAR_REDUX_STATE:
      return initialState;
    default:
      return state;
  }
};
