export const stateTerritoryBodyFields = `
  noticeGivenDate
  noticeToStateTerritory
  notifiedByStateTerritory
  notifiedByPersonFirstName
  notifiedByPersonLastName
  organisationName
  statePersonEmail
  statePersonNameOrId
  statePersonPhoneNumber
  stateReferenceNumber
  text
  type
`;

const certificateSubFields = `
  id
  certificateId
  childId
  evidenceHeld
  startDate
  endDate
  status
  weeksAtRisk
  child {
    firstname
    nickname
    lastname
  }
  stateTerritoryBody {
    ${stateTerritoryBodyFields}
  }
  riskReasons
  decisionMakingAgency
  recordStatus
  exceptionalCircumstanceText
  exceptionalCircumstanceReason
`;

export const certificateFields = `
  ${certificateSubFields}
  previousCertificates {
    ${certificateSubFields}
  }
`;

export const ccssCertDeterCategoryForCenterResultFields = `
  pending
  active
  expiring
  expired
`;

const accsCertDert = `
  id
  certDertType
  accsId
  childId
  evidenceHeld
  startDate
  endDate
  certificateStatus
  determinationStatus
  weeksAtRisk
  stateTerritoryBody {
    noticeToStateTerritory
    notifiedByStateTerritory
    stateReferenceNumber
    noticeGivenDate
    organisationName
    notifiedByPersonFirstName
    notifiedByPersonLastName
  }
  riskReasons
  centerName
  childName
  category
  child {
    avatar {
      url
    }
    fullName
    firstname
    lastname
    nickname
  }
  accountName
`;

export const accsCertDertPagedResultFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${accsCertDert}
  }
`;

export const determinationFields = `
  id
  determinationId
  childId
  startDate
  endDate
  status
  weeksAtRisk
  category
  stateTerritoryBody {
    ${stateTerritoryBodyFields}
  }
  riskReasons
`;

export const determinationFieldsWithPrevious = `
  ${determinationFields}
  previousDeterminations {
    ${determinationFields}
  }
`;

export const adviseChildNoLongerAtRiskResultFields = `
  certficate {
    ${certificateFields}
  }
  determination {
    ${determinationFieldsWithPrevious}
  }
`;
