import React from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import IconButtonCircle from './IconButtonCircle';

interface IProps {
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
  tooltipText?: string;
  tooltipDirection?: 'left' | 'right' | 'top' | 'bottom';
}

// TODO: add the emit of the click event to this
const TinyDeleteButton: React.FC<IProps> = ({ onClick, className, tooltipDirection, tooltipText, ...props }) => {
  return (
    <IconButtonCircle
      size="xs"
      color="white"
      backgroundColor="slate"
      className={`tiny-delete-button flex-shrink-0 ${className || ''}`}
      onClick={onClick}
      icon={faTimes}
      tooltipText={tooltipText}
      tooltipDirection={tooltipDirection}
    />
  );
};

TinyDeleteButton.defaultProps = {
  onClick: () => {},
};

export default TinyDeleteButton;
