import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { useDispatch } from 'react-redux';
import { updateEnrollmentDashboardClassesData } from 'pages/Enrollment/duck/actions';

interface IGetClassesForCenterData {
  getClassesForCenter: IClass[];
}

interface IGetClassesForCenterVariables {
  centerId: string;
  start: string;
  end: string;
  includeLtbOfferings: boolean;
  includeFutureContracts: boolean;
}

export const GET_CLASSES_FOR_CENTER = gql`
  query (
    $centerId: String!
    $start: Date!
    $end: Date!
    $includeLtbOfferings: Boolean!
    $includeFutureContracts: Boolean!
  ) {
    getClassesForCenter(centerId: $centerId) {
      id
      name
      startsAt
      endsAt
      archivedAt
      capacity
      regulation {
        id
        state
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        youngestStartAge {
          age
          unit
        }
        oldestEndAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      enrollmentDataForTimeframe(
        start: $start
        end: $end
        includeLtbOfferings: $includeLtbOfferings
        includeFutureContracts: $includeFutureContracts
      ) {
        metricsByWeek {
          startOfWeek
          fte
          ftePercentage
          totalEnrollments
          totalExpectedRevenue
        }
      }
      glCode
    }
  }
`;

export const useGetClassesForCenter = (centerId: string, start: string, end: string) => {
  const dispatch = useDispatch();

  return useQuery<IGetClassesForCenterData, IGetClassesForCenterVariables>(GET_CLASSES_FOR_CENTER, {
    variables: {
      centerId,
      start,
      end,
      includeLtbOfferings: true,
      includeFutureContracts: true,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch(updateEnrollmentDashboardClassesData(data.getClassesForCenter ?? []));
    },
  });
};
