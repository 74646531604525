import React from 'react';
import MonthPicker from 'shared/components/DateInput/MonthPicker';
import MonthViewCalendar from './MonthViewCalendar';
import Button from 'shared/components/Buttons/Button';
import moment from 'moment';

interface IProps {
  date: string;
  updateDate: (date: string) => void;
  timeOffRequests: ITimeOff[];
  setSelectedTimeOff: (t: ITimeOff) => void;
}

const MonthView: React.FC<IProps> = ({ date, updateDate, timeOffRequests, setSelectedTimeOff }) => {
  return (
    <div>
      <div className="bg-white rounded p-2 box-shadow-lg d-flex align-items-center mb-2">
        <Button
          variant="outline-light"
          className="px-4 text-text-color ml-auto mr-4 today-button"
          onClick={() => updateDate(moment().toISOString())}
        >
          Today
        </Button>
        <MonthPicker className="mr-auto" date={date} updateDate={updateDate} />
      </div>
      <MonthViewCalendar
        className="kt-my-time-off-calendar"
        timeOffRequests={timeOffRequests}
        date={date}
        updateDate={updateDate}
        setSelectedTimeOff={setSelectedTimeOff}
      />
    </div>
  );
};

export default MonthView;
