import { useEditCcssCertificate } from 'gql/ccssCertificates/mutations';
import React from 'react';
import { showToast } from 'shared/components/Toast';
import CreateCertificateModal, { IFormErrors, getEmptyErrorState } from './CreateCertificateModal';
import { ICertificateModalControlsProps } from './types';
import { useDispatch } from 'react-redux';
import { getCcsCertificateForAccountChild } from 'pages/Families/subroutes/Accounts/duck/actions';
import { isValidPhoneNumber } from 'shared/util/string';
import { isEmailValid } from 'shared/util/email';
import { ApolloError } from '@apollo/client';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  account: IAccount;
  certificateInEdit: ICcssCertificate;
}

const EditCertificateModal: React.FC<IProps> = ({ isOpen, onClose, account, certificateInEdit }) => {
  // Doing this badness to strip the __typename from the stateTerritoryBody if the stateTerritoryBody exists.
  // __typename appears to be an apollo thing/fault.
  let stateTerritoryBody: IStateTerritoryBody | undefined = undefined;
  if (certificateInEdit.stateTerritoryBody) {
    const { __typename, ...rest } = certificateInEdit.stateTerritoryBody! as any;
    stateTerritoryBody = rest;
  }

  const populatedCertificateInput: ICreateCcssCertificateInput = {
    businessId: account.entityId,
    accountId: account.id,
    centerId: account.centerId,
    children: [certificateInEdit.childId],
    evidenceHeld: certificateInEdit.evidenceHeld,
    startDate: certificateInEdit.startDate,
    weeksAtRisk: certificateInEdit.weeksAtRisk,
    isDeclarationGiven: false,
    stateTerritoryBody: {
      ...emptyStateTerritoryBody,
      ...(stateTerritoryBody ?? {}),
      statePersonPhoneNumber: certificateInEdit.stateTerritoryBody?.statePersonPhoneNumber ?? undefined,
    },
    riskReasons: certificateInEdit.riskReasons ?? [],
  };

  const [onSubmit, loading] = useOnSubmitEditCertificate(certificateInEdit.id);

  return (
    <CreateCertificateModal
      title="Edit Certificate"
      isOpen={isOpen}
      onClose={onClose}
      account={account}
      modalControlsProps={controlInputProps}
      selectedChildId={certificateInEdit.childId}
      baseCertificateInput={populatedCertificateInput}
      onValidateForm={onValidateForm}
      onSubmit={onSubmit}
      loadingExternal={loading}
    />
  );
};

export default EditCertificateModal;

const controlInputProps: ICertificateModalControlsProps = {
  centerName: { disabled: true },
  startDate: { disabled: true },
  weeksAtRisk: { disabled: true },
  evidenceHeld: { disabled: true },
  child: { disabled: true },
  riskReasons: { disabled: true },
};

const emptyStateTerritoryBody = {
  noticeGivenDate: '',
  noticeToStateTerritory: false,
  notifiedByStateTerritory: false,
  organisationName: '',
  statePersonPhoneNumber: '',
};

function onValidateForm(
  setFormErrors: (error: IFormErrors) => void,
  setIsFormValid: (isValid: boolean) => void,
  certificate: ICreateCcssCertificateInput
) {
  let hasError = false;
  const errors = getEmptyErrorState();

  if (certificate.stateTerritoryBody) {
    if (!certificate.stateTerritoryBody.noticeGivenDate) {
      errors.stateTerritoryBody.noticeGivenDate = 'Notice date is required';
      hasError = true;
    }

    if (
      !certificate.stateTerritoryBody.noticeToStateTerritory &&
      !certificate.stateTerritoryBody.notifiedByStateTerritory
    ) {
      errors.stateTerritoryBody.notice = 'Notice is required';
      hasError = true;
    }

    if (certificate.stateTerritoryBody.noticeToStateTerritory && !certificate.stateTerritoryBody.organisationName) {
      errors.stateTerritoryBody.organisationName = 'Organisation name is required';
      hasError = true;
    }

    if (!certificate.stateTerritoryBody.type) {
      errors.stateTerritoryBody.type = 'Body type is required';
      hasError = true;
    }

    if (
      certificate.stateTerritoryBody.statePersonEmail &&
      !isEmailValid(certificate.stateTerritoryBody.statePersonEmail)
    ) {
      errors.stateTerritoryBody.statePersonEmail = 'Email is invalid';
      hasError = true;
    }

    if (
      certificate.stateTerritoryBody.statePersonPhoneNumber &&
      !isValidPhoneNumber(certificate.stateTerritoryBody.statePersonPhoneNumber)
    ) {
      errors.stateTerritoryBody.statePersonPhoneNumber = 'Phone number provided is invalid';
      hasError = true;
    }
  }

  if (!certificate.isDeclarationGiven) {
    errors.isDeclarationGiven = 'You must declare the information provided is true and correct';
    hasError = true;
  }

  for (const doc of certificate.supportingDocuments ?? []) {
    if (!doc.type) {
      hasError = true;
      errors.supportingDocuments.push({
        error: 'Document type is required',
        file: doc.file.name,
      });
    }
  }

  setFormErrors(errors);
  setIsFormValid(!hasError);
}

function useOnSubmitEditCertificate(
  certificateId: string
): [(certificate: ICreateCcssCertificateInput, account: IAccount, handleClose: () => void) => void, boolean] {
  const [editCcssCertificate, { loading }] = useEditCcssCertificate();
  const dispatch = useDispatch();

  function onSubmit(certificate: ICreateCcssCertificateInput, account: IAccount, handleClose: () => void) {
    const editCertificateInput: IEditCcssCertificateInput = {
      businessId: account.entityId,
      accountId: account.id,
      centerId: account.centerId,
      isDeclarationGiven: certificate.isDeclarationGiven,
      stateTerritoryBody: certificate.stateTerritoryBody,
      supportingDocuments: certificate.supportingDocuments,
      certificateId: certificateId,
    };

    editCcssCertificate({
      variables: {
        input: { ...editCertificateInput },
      },
    })
      .then((data) => {
        if (data.data?.editCcssCertificate) {
          const resultCertificate = data.data.editCcssCertificate;
          dispatch(
            // Will this work or do I need a list?
            getCcsCertificateForAccountChild(account.id, resultCertificate.id, [resultCertificate])
          );
        }
        showToast('Certificate Updated', 'success');
        handleClose();
      })
      .catch((error) => {
        if (error instanceof ApolloError) {
          showToast(
            `${error.graphQLErrors
              .map((err) => {
                // @ts-ignore
                return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
              })
              .join(', ')}`,
            'error'
          );
        } else {
          showToast(error, 'error');
        }
      });
  }

  return [onSubmit, loading];
}
