import React, { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import Select from 'shared/components/Select';
import { sortBy } from 'lodash';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import { useGetPositionsForBusiness } from 'shared/hooks/useGetPositionsForBusiness';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import TextInput from '../TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

export interface IPositionFormData {
  id?: string;
  positionId: string;
  personId: string;
  name: string;
  scopeId: string;
  scopeType: ScopeType;
  businessId: string;
  payRate: number | null;
  type: PayRateType | null;
  isExempt: boolean;
}

interface IProps {
  position: IPositionFormData;
  assignedStaffPositions: IPositionFormData[];
  updatePosition: (position: IPositionFormData) => void;
  businessId: string;
  className?: string;
  disablePositionDropdown?: boolean;
  disabled?: boolean;
  enableEditPayrate?: boolean;
  enablePayrateView?: boolean;
  adpEnabled: boolean;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const EmployeePositionInputs: React.FC<IProps> = ({
  position,
  assignedStaffPositions,
  updatePosition,
  businessId,
  className,
  disablePositionDropdown = false,
  disabled = false,
  enableEditPayrate = false,
  enablePayrateView = false,
  adpEnabled = false,
}) => {
  const { data: positionsForBusiness, loading: loadingPositionsForBusiness } = useGetPositionsForBusiness(businessId);
  const { k2WebPtoTypes } = useFlags();

  // Localise positions for business based on DEFECT-3246
  const localisedPositionsForBusiness = () => {
    let positions =
      positionsForBusiness?.getPositionsByBusinessId.map((p) => ({
        ...p,
        name: p.name.replace(/Center|Centre/g, fieldLabels.center),
      })) ?? [];

    return positions;
  };

  return (
    <div className={`d-flex flex-wrap ${className}`}>
      <Select
        required
        id="position-dropdown-input"
        label="Position"
        value={position.positionId ?? null}
        helpTooltipText="Title or scope of responsibilities."
        options={sortBy(
          localisedPositionsForBusiness().filter(
            (p) => !assignedStaffPositions.some((a) => a.name === p.name && a.positionId !== position.positionId)
          ),
          (p) => p.name
        ).map((p) => ({ value: p.id, label: p.name }))}
        isLoading={loadingPositionsForBusiness}
        onChange={(option) => updatePosition({ ...position, positionId: option.value, name: option.label })}
        className="w-260px mr-4"
        disabled={disablePositionDropdown || disabled}
      />
      {!enablePayrateView ? (
        <TextInput label="Pay Rate" value={'* * * *'} disabled={true} />
      ) : (
        <CurrencyInput2
          label="Pay Rate"
          onChange={(value) => updatePosition({ ...position, payRate: value })}
          value={position.payRate}
          step="0.01"
          min="0"
          disabled={disabled || !enableEditPayrate}
          className="border-radius-0 mr-4"
          appendNode={
            <DropdownButton
              alignRight
              noCaret={false}
              disabled={disabled || !enableEditPayrate}
              variant="outline-secondary"
              as={InputGroup.Append}
              title={
                <span className="pl-1">
                  {`${position.type}`} <FontAwesomeIcon transform={'right-5'} icon={faAngleDown} />
                </span>
              }
            >
              <DropdownItem onClick={() => updatePosition({ ...position, type: 'Hourly' })}>Hourly</DropdownItem>
              <DropdownItem onClick={() => updatePosition({ ...position, type: 'Salary' })}>Salary</DropdownItem>
            </DropdownButton>
          }
        />
      )}

      {k2WebPtoTypes && adpEnabled && (
        <Select
          id="overtime-dropdown-input"
          label="Overtime Pay"
          components={{ DropdownIndicator: null }}
          value={position.isExempt ? 'Exempt' : 'Non-Exempt'}
          options={['Exempt', 'Non-Exempt'].map((p) => ({ value: p, label: p }))}
          //isLoading={loadingPositionsForBusiness}
          onChange={() => {}}
          className="w-200px mr-4"
          disabled={true}
        />
      )}
    </div>
  );
};

export default EmployeePositionInputs;
