import React from 'react';
import Row from './Row';
import { ILayoutProps } from './LayoutProps';

const RowWithMb: React.FC<ILayoutProps> = ({ justify, align, className, children, ...props }) => {
  return (
    <Row {...props} justify={justify} align={align} className={`mb-4 ${className || ''}`}>
      {children}
    </Row>
  );
};

export default RowWithMb;
