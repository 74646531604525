import { QueryHookOptions, LazyQueryHookOptions } from '@apollo/client';
import { useQuery, useLazyQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { itemizedBillFields, itemizedBillWithTransactionFields, itemizedBillSummaryFields } from './fields';

interface IGetItemizedBillData {
  getItemizedBill: IItemizedBill;
}

interface IGetItemizedBillSummaryData {
  getItemizedBillSummary: IItemizedBillSummary;
}

interface IGetItemizedBillVariables {
  id: string;
}

interface IGetItemizedBillSummaryVariables {
  id: string;
}

interface IGetItemizedBillsByAccountIdData {
  getItemizedBillsByAccountId: IItemizedBillWithoutTransactions[];
}

interface IGetItemizedBillsByAccountIdVariables {
  accountId: string;
}

interface IGetItemizedBillPdfData {
  getItemizedBillPdf: string | null;
}

interface IGetItemizedBillSummaryPdfData {
  getItemizedBillSummaryPdf: string | null;
}

interface IGetItemizedBillVariables {
  id: string;
}

export const GET_ITEMIZED_BILL = (fields: string = itemizedBillWithTransactionFields) => gql`
  query ($id: ID!) {
    getItemizedBill(id: $id) {
      ${fields}
    }
  }
`;

export const GET_ITEMIZED_BILL_SUMMARY = (fields: string = itemizedBillSummaryFields) => gql`
  query ($id: ID!) {
    getItemizedBillSummary(id: $id) {
      ${fields}
    }
  }
`;

export const GET_ITEMIZED_BILLS_FOR_ACCOUNT = (fields: string = itemizedBillFields) => gql`
  query ($accountId: ID!) {
    getItemizedBillsByAccountId(accountId: $accountId) {
      ${fields}
    }
  }
`;

export const GET_ITEMIZED_BILL_PDF = () => gql`
  query ($id: ID!) {
    getItemizedBillPdf(id: $id)
  }
`;

export const GET_ITEMIZED_BILL_SUMMARY_PDF = () => gql`
  query ($id: ID!) {
    getItemizedBillSummaryPdf(id: $id)
  }
`;

export const useGetItemizedBill = (
  options?: QueryHookOptions<IGetItemizedBillData, IGetItemizedBillVariables>,
  fields?: string
) => useQuery<IGetItemizedBillData, IGetItemizedBillVariables>(GET_ITEMIZED_BILL(fields), options);

export const useGetItemizedBillSummary = (
  options?: QueryHookOptions<IGetItemizedBillSummaryData, IGetItemizedBillSummaryVariables>,
  fields?: string
) =>
  useQuery<IGetItemizedBillSummaryData, IGetItemizedBillSummaryVariables>(GET_ITEMIZED_BILL_SUMMARY(fields), options);

export const useGetItemizedBillByAccount = (
  options?: QueryHookOptions<IGetItemizedBillsByAccountIdData, IGetItemizedBillsByAccountIdVariables>,
  fields?: string
) =>
  useQuery<IGetItemizedBillsByAccountIdData, IGetItemizedBillsByAccountIdVariables>(
    GET_ITEMIZED_BILLS_FOR_ACCOUNT(fields),
    options
  );

export const useGetItemizedBillPdfLazy = (
  options?: LazyQueryHookOptions<IGetItemizedBillPdfData, IGetItemizedBillVariables>
) => useLazyQuery<IGetItemizedBillPdfData, IGetItemizedBillVariables>(GET_ITEMIZED_BILL_PDF(), options);

export const useGetItemizedBillSummaryPdfLazy = (
  options?: LazyQueryHookOptions<IGetItemizedBillSummaryPdfData, IGetItemizedBillSummaryVariables>
) =>
  useLazyQuery<IGetItemizedBillSummaryPdfData, IGetItemizedBillSummaryVariables>(
    GET_ITEMIZED_BILL_SUMMARY_PDF(),
    options
  );
