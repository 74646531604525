export const timeCardLock = `
  archivedAt
  archivedBy
  id
  businessId
  centerId
  createdAt
  createdBy
  lockedUntil
`;

export const timeCardLockPagedResult = `
  pageNumber
  pageSize
  totalRecords
  data{
    ${timeCardLock}
  }
`;

export const lockedUntilForCenterResult = `
  data {
    businessId
    centerId
    lockedUntil
    createdBy
    createdAt
  }
`;
