import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';

type Props = {
  teacher: IProgramTeacher | undefined;
  required: boolean;
  onChange: (returnData: IProgramTeacher) => void;
};

interface IForm {
  programTeacher: IProgramTeacher | undefined;
}

export default function EducatorQualifications({ teacher, required, onChange }: Props) {
  const [formData, setFormData] = useState<IForm>({
    programTeacher: teacher,
  });

  const qualificationStatusOptions = [
    { value: 'None', label: 'None' },
    { value: 'Qualified', label: 'Qualified' },
    { value: 'WorkingTowards', label: 'Working towards' },
    { value: 'NotRecognized', label: 'Not recognised' },
  ];

  const handleChange = useCallback(
    (value: string) => {
      if (formData.programTeacher) {
        const teacher = {
          id: formData.programTeacher.id,
          firstName: formData.programTeacher.firstName,
          lastName: formData.programTeacher.lastName,
          staffId: formData.programTeacher.staffId,
          qualificationStatus: value,
        };

        setFormData((prevState) => {
          return { ...prevState, ['programTeacher']: teacher };
        });
        onChange(teacher);
      }
    },
    [onChange, formData.programTeacher]
  );

  return (
    <>
      <hr />
      <Row className="mb-4 mt-4" align="start">
        <Col>
          <label>
            {formData.programTeacher?.firstName} {formData.programTeacher?.lastName}
          </label>
        </Col>
        <Col>
          <Select
            label="Qualification"
            required={required}
            value={formData.programTeacher?.qualificationStatus}
            options={qualificationStatusOptions}
            onChange={(o: ISelectMenuItem) => {
              handleChange(o.value);
            }}
          />
        </Col>
      </Row>
    </>
  );
}
