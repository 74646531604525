import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { Col, Row } from 'shared/components/Layout';
import Card from 'shared/components/Card';
import { CreateButton } from 'shared/components/Buttons';
import CreatePersonnelModal from './CreatePersonnelModal';
import CreateProviderModal from './CreateProviderModal';
import EditProviderModal from './EditProviderModal';
import DataTable from 'shared/components/DataTable';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import Tag from 'shared/components/Tag/StatusTag';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';
import _ from 'lodash';
import ActionDropdown from 'shared/components/ActionDropdown';
import ViewLinkedCentersModal from './ViewLinkedCentersModal';
import { useGetPersonnelByBusinessId, useGetProvidersByBusinessId } from 'gql/business/queries';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  business: IBusiness;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const CcssProviderTab: React.FC<IProps> = ({ business, ...props }) => {
  const personnel = useSelector((state: RootState) => state.personnel.personnel) ?? [];
  const providers = useSelector((state: RootState) => state.provider.providers) ?? [];
  const [showPersonnelModal, setShowPersonnelModal] = useState<boolean>(false);
  const [showProviderCreateModal, setShowProviderCreateModal] = useState<boolean>(false);
  const [showProviderEditModal, setShowProviderEditModal] = useState<boolean>(false);
  const [providerInEdit, setProviderInEdit] = useState<IProvider | null>(null);
  const [showLinkedCentersModal, setShowLinkedCentersModal] = useState<boolean>(false);
  const [showLinkedCentersProvider, setShowLinkedCentersProvider] = useState('');

  const { loading: getPersonnelLoading } = useGetPersonnelByBusinessId({
    variables: {
      businessId: business.id,
      role: 'MANCON',
    },
  });

  const { loading: getProvidersLoading } = useGetProvidersByBusinessId({
    variables: {
      businessId: business.id,
    },
  });

  const dismissPersonnelModal = useCallback(() => {
    setShowPersonnelModal(false);
  }, []);

  const dismissCreateProviderModal = useCallback(() => {
    setShowProviderCreateModal(false);
  }, []);

  const dismissEditProviderModal = useCallback(() => {
    setShowProviderEditModal(false);
  }, []);

  const dismissShowLinkedCentersModal = useCallback(() => {
    setShowLinkedCentersModal(false);
  }, []);

  const onEditClick = (providerId: string) => {
    setShowProviderEditModal(true);
    const providerInEdit = providers.find((p) => p.id === providerId);
    providerInEdit && setProviderInEdit(providerInEdit);
  };

  const onViewLinkedCentersClick = (providerId: string) => {
    setShowLinkedCentersProvider(providerId);
    setShowLinkedCentersModal(true);
  };

  return (
    <>
      <Row align="start">
        <Col>
          <Card
            header={
              <div className="d-flex align-items-center">
                <div>{'Management or Control Personnel'}</div>
              </div>
            }
          >
            {getPersonnelLoading ? (
              <DataTableLoadingSkeleton />
            ) : (
              <DataTable
                className="personnel-table"
                data={personnel}
                dataSize={personnel.length}
                showSelect={false}
                showPagination={false}
                noPadding
                columns={[
                  {
                    text: 'First Name',
                    dataField: 'firstName',
                    sort: true,
                  },
                  {
                    text: 'Last Name',
                    dataField: 'lastName',
                    sort: true,
                  },
                  {
                    text: 'Position',
                    dataField: '',
                    formatter: (_cell: string, row: ICcssPersonnel) => {
                      const lastProviderRole = _(sortBy(row.providerRoles, (pr) => pr.startDate)).last()!;
                      const positionText =
                        lastProviderRole?.positionText ?? /* todo: convert the enum */ lastProviderRole?.position;

                      return <Row>{positionText}</Row>;
                    },
                  },
                ]}
              />
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            header={
              <div className="d-flex align-items-center">
                <div>{'CCS Providers'}</div>
                <CreateButton
                  className="ml-auto"
                  id="create-provider-btn"
                  onClick={() => setShowProviderCreateModal(true)}
                >
                  Add Provider
                </CreateButton>
              </div>
            }
          >
            {getProvidersLoading ? (
              <DataTableLoadingSkeleton />
            ) : (
              <DataTable
                className="provider-table"
                data={providers}
                dataSize={providers.length}
                showSelect={false}
                showPagination={false}
                noPadding
                columns={[
                  {
                    text: 'Provider Name',
                    dataField: 'name',
                    sort: true,
                  },
                  {
                    text: 'Status',
                    dataField: 'endDate',
                    formatter: (cell: string, row: any) => (
                      <Row>
                        <Tag
                          status={moment(row.endDate).date >= moment.now ? 'Active' : 'Inactive'}
                          className="mr-auto"
                        />
                      </Row>
                    ),
                  },
                  {
                    text: 'Commencement Date',
                    dataField: 'commencementDate',
                    sort: true,
                    formatter: (commencementDate?: string) =>
                      !!commencementDate ? moment(commencementDate).format('DD/MM/YYYY') : '',
                  },
                  {
                    text: 'Proda Expiry',
                    dataField: 'deviceExpiry',
                    sort: true,
                    formatter: (deviceExpiry: string) => moment(deviceExpiry).format('DD/MM/YYYY'),
                  },
                  {
                    text: 'Actions',
                    dataField: '',
                    align: 'center',
                    headerClasses: 'text-center',
                    formatter: (cell: string, row: any) => (
                      <ActionDropdown
                        actions={[
                          {
                            label: `View Linked ${fieldLabels.center}s`,
                            onClick: () => {
                              onViewLinkedCentersClick(row.id);
                            },
                          },
                          {
                            label: 'Edit',
                            onClick: () => {
                              onEditClick(row.id);
                            },
                          },
                        ]}
                      />
                    ),
                  },
                ]}
              />
            )}
          </Card>
        </Col>
      </Row>
      <CreatePersonnelModal
        isOpen={showPersonnelModal}
        onClose={dismissPersonnelModal}
        businessId={business.id}
      ></CreatePersonnelModal>

      <CreateProviderModal
        isOpen={showProviderCreateModal}
        onClose={dismissCreateProviderModal}
        businessId={business.id}
      ></CreateProviderModal>

      <ViewLinkedCentersModal
        providerId={showLinkedCentersProvider}
        isOpen={showLinkedCentersModal}
        businessId={business.id}
        onClose={dismissShowLinkedCentersModal}
      />

      {providerInEdit !== null && (
        <EditProviderModal
          provider={providerInEdit}
          isOpen={showProviderEditModal}
          onClose={dismissEditProviderModal}
        ></EditProviderModal>
      )}
    </>
  );
};

export default CcssProviderTab;
