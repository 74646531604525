import React, { useState } from 'react';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';

import DataTable from 'shared/components/DataTable';
import { TableHeader } from 'shared/components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';

import Tooltip from 'shared/components/Tooltip';
import colors from '_colors.module.scss';
import Select from 'shared/components/Select';

interface ITableProps {
  data: ICcsAltArrangement[];
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  providers: IProvider[];
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  activeFilters: ICcsDebtFilter;
  onActiveFilterChange: (value: ICcsDebtFilter) => void;
}

const CCSDebtsTable: React.FC<ITableProps> = ({
  data,
  loading,
  dataSize,
  pageSize,
  activePage,
  activeFilters,
  providers,
  onActiveFilterChange,
  onPageChange,
  onSizePerPageChange,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const onExpand = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    const expanded = isExpand ? [row.id] : [];
    setExpandedRows(expanded);
  };

  const handleProviderChange = (provider: ITableFilterOption) => {
    onActiveFilterChange({ ...activeFilters, providerId: provider.value });
  };

  return (
    <>
      <TableHeader>
        <div className="d-flex my-2">
          <Select
            placeholder="Provider"
            options={providers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
            onChange={handleProviderChange}
            className="mr-4 my-2"
            value={activeFilters.providerId}
          />
        </div>
      </TableHeader>
      <SpinnerTableOverlay show={loading}>
        <DataTable
          keyField="id"
          noPadding={true}
          data={data}
          dataSize={dataSize}
          handleRowClick={() => {}}
          hasOverlay={false}
          pageSize={pageSize}
          activePage={activePage}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          showPagination={false}
          showSelect={false}
          className="ccs-table"
          expandHeaderColumnRenderer={(props) => <></>}
          onExpand={onExpand}
          expanded={expandedRows}
          defaultSortField="created"
          defaultSorting="desc"
          onlyOneExpanding={true}
          columns={[
            {
              text: 'ID',
              dataField: 'id',
            },
            {
              text: 'Provider ID',
              dataField: 'providerId',
            },
            {
              text: 'Type',
              dataField: 'type',
            },
            {
              text: (
                <>
                  Status{' '}
                  <Tooltip text="[placeholder]">
                    <FontAwesomeIcon icon={faInfoCircle} color={colors.steelBlue} />
                  </Tooltip>
                </>
              ),
              dataField: 'status',
            },
            {
              text: 'Submitted Date',
              dataField: 'dateSubmitted',
              align: 'center',
              headerClasses: 'text-center',
              formatter: (cell: string) => (cell ? moment(cell).format('DD/MM/YYYY') : ''),
            },
            {
              text: 'Start Date',
              dataField: 'startDate',
              align: 'center',
              headerClasses: 'text-center',
              formatter: (cell: string) => (cell ? moment(cell).format('DD/MM/YYYY') : ''),
            },
            {
              text: 'Payment Period',
              dataField: 'paymentPeriod',
              formatter: (cell: string) => (cell ? `${cell} month/s` : ''),
            },
            {
              text: 'Offset Percentage',
              dataField: 'offsetPercentage',
              headerClasses: 'text-center',
              align: 'center',
              formatter: (cell: number) => <span className="pr-5">{cell * 100}%</span>,
            },

            {
              text: 'Total Amount',
              dataField: 'totalAmount',
              headerClasses: 'text-right pr-8 w-80',
              align: 'right',
              formatter: (cell: number) => (cell ? <span className="pr-5">${cell.toFixed(2)}</span> : ''),
            },
          ]}
        />
      </SpinnerTableOverlay>
    </>
  );
};

export default CCSDebtsTable;
