import { paymentMethodFields } from 'gql/paymentMethod/fields';
import { s3SignedUrlFields } from 'gql/s3SignedUrl/fields';
import { isRegion } from 'shared/util/region';

export const GET_ENTITY: string = `
  id
  name
  email
  website
  avatar {
    url
  }
  inviteCode
  address {
    address1
    address2
    city
    state
    postalCode
    country
  }
  timezone
  phoneNumber
  primaryContact {
    id
    firstname
    lastname
    email
    avatar {
      url
    }
    email
    roleship {
      name
    }
    role {
      id
      name
      businessId
      scopeType
      areaLevels {
        roleId
        area
        level
        permission
      }
    }
  }
  secondaryContact {
    id
    firstname
    lastname
    email
    avatar {
      url
    }
    email
    roleship {
      name
    }
    role {
      id
      name
      businessId
      scopeType
      areaLevels {
        roleId
        area
        level
        permission
      }
    }
  }
  accountOwner {
    id
    firstname
    lastname
    email
  }
  accountOwners {
    id
    firstname
    lastname
    avatar {
      url
    }
    email
    roleship {
      name
    }
    role {
      id
      name
      businessId
      scopeType
      areaLevels {
        roleId
        area
        level
        permission
      }
    }
    employmentStatus
  }
  tags {
    id
    entityId
    name
    category
  }
  dataType
  paymentMethods {
    ${paymentMethodFields}
  }
  primaryPaymentMethodId
  payrixCustomerId
  centers {
    id
    name
    avatar {
      ${s3SignedUrlFields}
    }
  }
  ${isRegion('US') ? 'kisiApiKey' : ''}
`;

export const providerFields = `
  id
  name
  providerId
  organisationId
  communicationsPersonId
  deviceName
`;

export const providerEditFields = `
  id
  deviceName
  otac
`;

export const createReturnFeeReductionFields = `
  enrolmentId
  sessionReportStartDate
  amountNotPassedOnToIndividual
  amountPassedOnToIndividual
  totalFeeReductionAmountForWeek
  returnFeeReductionReason
`;

export const createCustomFieldDataFields = `
  enrolmentId
  sessionReportStartDate
  amountNotPassedOnToIndividual
  amountPassedOnToIndividual
  totalFeeReductionAmountForWeek
  returnFeeReductionReason
`;

export const createCustomFieldFields = `
  businessId
  centerIds
  label
  area
  displayOrder
  notForConsoleDisplay
  type
  attributesAsString
  id
`;

export const customFieldsFields = `
id
businessId
label
area
type
displayOrder
notForConsoleDisplay
attributes
centerIds
`;

export const customFieldsPagedResultsFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${customFieldsFields}
  }
`;
