import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import AvailabilityTable from 'pages/Enrollment/subroutes/Management/Tabs/Overview/components/AvailabilityTable';
import SearchAvailability from 'pages/Enrollment/subroutes/Management/Tabs/Overview/components/SearchAvailability';
import SearchAvailabilityResults from 'shared/components/SearchAvailabilityResults';
import useSearchAvailability from 'shared/hooks/useSearchAvailability';
import moment from 'moment';
import { getIsBirthdateWithinClassRange } from 'shared/util/getIsChildWithinClassAgeRange';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import Select from 'shared/components/Select';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { Application, ApplicationChild } from 'generated/graphql';
import { orderBy } from 'lodash';
import Spinner from 'shared/components/Spinner';

interface IProps {
  application?: Application;
  handleClose: () => void;
  isOpen: boolean;
  activeChild?: ApplicationChild;
  setChild: (child: ApplicationChild) => void;
}

const SearchAvailabilityModal: React.FC<IProps> = ({ isOpen, application, activeChild, handleClose, setChild }) => {
  const centerDetails =
    useSelector((state: RootState) => state.centers.all).filter((c) => application?.centers.includes(c.id)) ?? [];
  const [centerId, setCenterId] = useState<string>(application?.centers?.[0] ?? '');

  const activeClasses = useSelector((state: RootState) => state.classes.all).filter((c) => !c.archivedAt);

  const { state: searchState, fn: searchFn } = useSearchAvailability({
    selectedCenterId: centerId,
    searchInput: {
      startDate: '',
      birthDate: '',
      classes: [],
    },
  });

  const hasCreateEnrollmentLeadManagmentPermissions = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementMain,
    level: RoleLevelType.Create,
  });

  const { data, loading, firstDateInView, dataDateRange, classesToFilter, classes } = searchState;

  const { setClassesToFilter, setDataDateRange, setFirstDateInView, fetchClasses } = searchFn;

  const { t } = useTranslation();
  const [selectedCareType, setSelectedCareType] = useState<string | undefined>();
  const startDate = activeChild?.enquiries[0].startDate ?? '';
  const birthDate = activeChild?.dateOfBirth ?? '';
  const schedule = activeChild?.enquiries[0];

  useEffect(() => {
    setClassesToFilter(
      activeClasses.filter((c) => getIsBirthdateWithinClassRange(birthDate, c) && c.careType === schedule?.careType)
    );
    fetchClasses(startDate);
    setSelectedCareType(schedule?.careType);
  }, [activeChild, birthDate]);

  useEffect(() => {
    if (application?.centers.length === 1) {
      setCenterId(application?.centers?.[0]);
    }
  }, [application?.centers]);

  const schedules = activeChild?.enquiries || [];
  const careTypeOptions = schedules?.map((s) => s.careType);

  const handleMakeOffer = () => {
    handleClose();
  };

  return (
    <Modal centered backdrop="static" show={isOpen} onHide={handleClose} size="xl">
      <Modal.Header closeButton className="pb-4">
        <div className="d-flex flex-column">
          <Modal.Title as="h3">
            {t('enrollment.lead-management.search-availability-for', {
              accountName: application?.accountName,
            })}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {centerDetails.length > 1 && (
            <Col lg={3}>
              <label>{capitalize(t('spelling.center'))}</label>
              <Select
                options={orderBy(
                  centerDetails.map((c) => ({
                    ...c,
                    value: c.id,
                    label: c.name,
                  })),
                  (opt) => opt.name,
                  'asc'
                )}
                value={centerId}
                onChange={(center) => setCenterId(center.id)}
              />
            </Col>
          )}
          <Col lg={3}>
            <label>{capitalize(t('spelling.child'))}</label>
            <Select
              options={application?.children || []}
              getOptionLabel={(c: ApplicationChild) => `${c.firstName} ${c.lastName}`}
              value={activeChild}
              getOptionValue={(c: ApplicationChild) => c.id}
              onChange={(selected: ApplicationChild) => setChild(selected)}
            />
          </Col>
        </Row>
        <div className="d-flex align-items-center">
          <DateInput
            date={startDate}
            onDateSelect={(startDate) => {}}
            label="Expected Start"
            required={true}
            disabled
            className="mr-4"
          />
          <DateInput
            date={birthDate}
            onDateSelect={(birthDate) => {}}
            label="Birth Date"
            required={true}
            disabled
            className="mr-4"
          />
          {birthDate && <div className="mr-4">{getAgeStringFromDateOfBirth(moment(birthDate))}</div>}

          <Select
            options={careTypeOptions ?? []}
            className="max-width-200"
            label="Care Type"
            value={selectedCareType}
            onChange={(value: string) => {
              // filter classes with care types
              setSelectedCareType(value);
              setClassesToFilter(
                activeClasses.filter((c) => getIsBirthdateWithinClassRange(birthDate, c) && c.careType === value)
              );
            }}
          />
        </div>
        {loading && data === undefined && <Spinner className="text-gray mr-2" />}
        <SearchAvailabilityResults
          data={data}
          loading={loading}
          classes={classes}
          dataDateRange={dataDateRange}
          firstDateInView={firstDateInView}
          classesToFilter={classesToFilter}
          strict
          setDataDateRange={setDataDateRange}
          setFirstDateInView={setFirstDateInView}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleMakeOffer} disabled={!hasCreateEnrollmentLeadManagmentPermissions}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchAvailabilityModal;
