import React, { useState, useCallback } from 'react';
import { somePropsEmpty } from 'shared/util/object';
import spaceIcons from 'shared/constants/SpaceIcons';
import colors from '_colors.module.scss';
import errorMessage from 'shared/constants/errorMessages';
import SpaceInputs from 'pages/Centers/components/SpaceInputs';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { useCreateNewSpace } from '../graphql/mutations';
import { showToast } from 'shared/components/Toast';

interface IProps {
  centerId: string;
  isOpen: boolean;
  onClose: () => void;
}

const AddSpaceForClassModal: React.FC<IProps> = ({ isOpen, onClose, centerId }) => {
  const newSpaceObject: INewSpace = {
    centerId,
    name: '',
    color: colors.paleGrey,
    icon: Object.keys(spaceIcons)[0],
  };
  const [space, updateSpace] = useState(newSpaceObject);
  const resetForm = useCallback(() => {
    updateSpace(newSpaceObject);
  }, [newSpaceObject]);

  const [addSpaceMutation, { loading }] = useCreateNewSpace();

  const addSpace = useCallback(async () => {
    await addSpaceMutation({
      variables: { input: space },
    }).then(
      () => {
        showToast('Space added.', 'success');
        onClose();
        resetForm();
      },
      () => {
        showToast(errorMessage.generic, 'error');
        onClose();
        resetForm();
      }
    );
  }, [addSpaceMutation, onClose, resetForm, space]);

  return (
    <ConfirmationModal
      title="New Space"
      show={isOpen}
      onHide={() => {
        onClose();
        resetForm();
      }}
      primaryChoice="Add"
      hideOnCallback={false}
      primaryCallback={addSpace}
      secondaryCallback={resetForm}
      primaryButtonProps={{
        disabled: somePropsEmpty(space),
        loading,
      }}
    >
      <SpaceInputs
        space={space}
        updateSpace={(updatedSpace: INewSpace) => {
          updateSpace(updatedSpace);
        }}
      />
      {somePropsEmpty(space) && <small>{errorMessage.formRequirements}</small>}
    </ConfirmationModal>
  );
};

export default AddSpaceForClassModal;
