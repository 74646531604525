import moment from 'moment';
import cast from './cast';

export const getIsBirthdateWithinClassRange = (birthDate: string, _class: IClass): boolean => {
  const regulation = _class.regulationOverride || _class.regulation;
  const earliestBirthDate = moment()
    .subtract(regulation.endAge.age, cast<'week' | 'month' | 'year'>(regulation.endAge.unit.toLocaleLowerCase()))
    .format();
  const latestBirthDate = moment()
    .subtract(regulation.startAge.age, cast<'week' | 'month' | 'year'>(regulation.startAge.unit.toLocaleLowerCase()))
    .format();
  const eligibleBirthdate = moment(birthDate).isBetween(earliestBirthDate, latestBirthDate);
  return eligibleBirthdate;
};
