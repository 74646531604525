import React from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { Row, Col } from 'shared/components/Layout';
import { IconButtonCircle } from 'shared/components/Buttons';
import cast from 'shared/util/cast';
import colors from '_colors.module.scss';

export const statusOptions = [
  {
    label: 'Active',
    value: 'active',
    indicatorColor: colors.seafoamGreen,
  },
  {
    label: 'Inactive',
    value: 'inactive',
    indicatorColor: colors.gray,
  },
  {
    label: 'Archived',
    value: 'archived',
    indicatorColor: colors.orange,
  },
];

export const jsFilters: Record<string, (_class: IClass) => Boolean> = {
  active: (_class: IClass) =>
    Boolean(
      !_class.archivedAt &&
        _class.startsAt &&
        _class.startsAt < new Date().toISOString() &&
        (!_class.endsAt || _class.endsAt > new Date().toISOString())
    ),
  inactive: (_class: IClass) =>
    Boolean(
      !_class.archivedAt &&
        (!_class.startsAt ||
          _class.startsAt > new Date().toISOString() ||
          (_class.endsAt && _class.endsAt < new Date().toISOString()))
    ),
  archived: (_class: IClass) => Boolean(_class.archivedAt),
};

interface IProps {
  selectedStatuses: ITableFilterOption[];
  onSearch: (term: string) => void;
  onStatusSelect: (states: ITableFilterOption[]) => void;
}

const FilterGroup: React.FC<IProps> = ({ selectedStatuses, onSearch, onStatusSelect, ...props }) => {
  return (
    <div className="d-flex align-items-center flex-row flex-wrap flex-grow-1">
      <TableSearch placeholder="Search Classes" className="mr-auto" onChange={onSearch} />
      <div className="d-flex flex-row align-items-center">
        <DropdownFilter
          title="Status"
          selectedFilters={selectedStatuses}
          options={cast<ITableFilterOption[]>(statusOptions)}
          onFilterSelect={onStatusSelect}
          className="mr-4"
        />
        <IconButtonCircle
          icon={faTimes}
          onClick={() => onStatusSelect([])}
          disabled={!selectedStatuses.length}
          tooltipDirection="bottom"
          tooltipText="Clear Filters"
        />
      </div>
    </div>
  );
};

export default FilterGroup;
