import React, { useState, useCallback, useMemo } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import Collapse from 'react-bootstrap/Collapse';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  deactivateCenter?: () => void;
  deleteCenter?: () => void;
  centers: ICenter[];
  action: 'DEACTIVATE' | 'DELETE';
}

interface IModalLanguage {
  title: string;
  primaryText: JSX.Element | string;
  confirmationText: JSX.Element | string;
  primaryButtonText: string;
  primaryButtonAction?: () => void;
}

// modal that shows a two-step process for confirmation used with deactivating and deleting a center
const DeactivateCenterModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  onClose,
  deactivateCenter,
  deleteCenter,
  action,
  centers,
  ...props
}) => {
  const [showSecondaryWarning, setShowSecondaryWarning] = useState<boolean>(false);

  const revealSecondaryWarning = useCallback(() => setShowSecondaryWarning(true), []);

  const resetModalState = useCallback(() => {
    // make sure the secondary warning is hidden if visible
    if (showSecondaryWarning) {
      setShowSecondaryWarning(false);
    }
  }, [showSecondaryWarning]);

  const dimissModal = useCallback(() => {
    resetModalState();

    onClose && onClose();
  }, [resetModalState, onClose]);

  const centerNamesArray = centers?.map((c) => c.name);
  const stringOfCenterNames =
    centers &&
    [centerNamesArray.slice(0, -1).join(', '), centerNamesArray.slice(-1)[0]].join(
      centerNamesArray.length < 2 ? '' : ' and '
    );
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const modalLanguage: IModalLanguage = useMemo(() => {
    if (/DELETE/.test(action)) {
      return {
        title: `Delete ${fieldLabels.center}${centers?.length > 1 ? 's' : ''}?`,
        primaryText: (
          <div>
            Do you want to delete <b>{stringOfCenterNames}</b>? This action is permanent and cannot be undone.
          </div>
        ),
        confirmationText: (
          <div>
            Once confirmed, data can no longer be accessed. Are you sure you want to delete <b>{stringOfCenterNames}</b>
            ?
          </div>
        ),
        primaryButtonText: 'Delete',
        primaryButtonAction: deleteCenter,
      };
    } else if (/DEACTIVATE/.test(action)) {
      return {
        title: `Deactivate ${fieldLabels.center}${centers?.length > 1 ? 's' : ''}?`,
        primaryText: (
          <div>
            Are you sure you want to deactivate <b>{stringOfCenterNames}</b>? This will deactivate all staff, children,{' '}
            {fieldLabels.enrollment}s, etc.
          </div>
        ),
        confirmationText: (
          <div>
            Are you postive that you want to deactivate <b>{stringOfCenterNames}</b>?
          </div>
        ),
        primaryButtonText: 'Deactivate',
        primaryButtonAction: deactivateCenter,
      };
    } else {
      return {
        title: '',
        primaryText: '',
        confirmationText: '',
        primaryButtonText: '',
        primaryButtonAction: () => {},
      };
    }
  }, [action, centers, deactivateCenter, deleteCenter, stringOfCenterNames]);

  return (
    // @ts-ignore
    <ConfirmationModal
      title={modalLanguage.title}
      show={isOpen}
      onHide={dimissModal}
      hideOnCallback={false}
      primaryChoice={showSecondaryWarning ? 'Confirm' : modalLanguage.primaryButtonText}
      primaryCallback={showSecondaryWarning ? modalLanguage.primaryButtonAction : revealSecondaryWarning}
      primaryButtonProps={{
        variant: 'danger',
        loading: isLoading,
      }}
      secondaryCallback={dimissModal}
      onExited={resetModalState}
    >
      {modalLanguage.primaryText}
      <Collapse in={showSecondaryWarning}>
        <div className="mt-4">{modalLanguage.confirmationText}</div>
      </Collapse>
    </ConfirmationModal>
  );
};

export default DeactivateCenterModal;
