import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import PageWrapper from 'shared/components/PageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { omit } from 'lodash';
import { useGetNotificationsV2, INotificationV2QueryInput } from 'gql/notificationsV2/queries';
import { useMarkNotificationsTogetherV2, useMarkNotificationV2 } from 'gql/notificationsV2/mutations';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import NotificationsV2TableHeader from './components/NotificationsTableHeader';
import NotificationV2Table from './components/NotificationsTable';
import NotificationsBulkActions from './components/NotificationsBulkActions';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { setNotifications, updateNotifications } from './duck/action';
import customEvents from 'shared/constants/customEvents';
import './_notificationsV2.scss';
import MuteNotificationsConfirmModal from './components/MuteNotificationsConfirmationModal';
import { useUpdateNotificationSubscriptions } from 'gql/notificationSubscription/mutations';
import { useTranslation } from 'react-i18next';
import { useGetUserNotificationSubscriptions } from 'gql/notificationSubscription/queries';

const syncNotificationMiniScreen = new Event(customEvents.REFETCH_NOTIFICATION_V2_MINI_SCREEN);

const NotificationsV2 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const user = useSelector((state: RootState) => state.user);
  const businessTimezone =
    useSelector((state: RootState) => state.timezone.byBusinessId)[currentBusinessId ?? user?.entityId ?? ''] ??
    moment.tz.guess();
  const tableData = useSelector((state: RootState) => state.notificationsV2.tableData);
  const [tableState, tableFunctions] = useDatatableState();
  const [filters, setFilters] = useState<Omit<INotificationV2QueryInput, 'pageNumber' | 'pageSize'>>({});
  const [showMuteModal, setShowMuteModal] = useState(false);
  const [selectedNotification, setNotification] = useState<INotificationV2 | null>(null);
  const [selectedNotifications, setSelectedNotifications] = useState<INotificationV2[]>([]);

  const queryVariables = useMemo(() => {
    if (moment(filters.startDate).isBefore(moment(filters.endDate))) {
      return filters;
    }
    return omit(filters, 'startDate', 'endDate');
  }, [filters]);

  const { data: subscriptions, refetch: refetchSubscriptions } = useGetUserNotificationSubscriptions({
    variables: {
      personId: user?.id ?? '',
    },
    skip: user == null,
  });

  const { loading, data, error, refetch } = useGetNotificationsV2(
    {
      pageNumber: tableState.activePage,
      pageSize: tableState.pageSize,
      ...queryVariables,
    },
    {
      onCompleted: (result) => {
        dispatch(setNotifications(result.notifications.data));
      },
    }
  );

  const [markNotificationV2fn] = useMarkNotificationV2({
    onCompleted: (result) => {
      dispatch(updateNotifications(result.markNotification));
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });
  const [markNotificationInDropdownV2fn] = useMarkNotificationsTogetherV2();

  const [muteNotification] = useUpdateNotificationSubscriptions({
    onCompleted: (result) => {
      showToast(
        t('notification-v2.mute-confirmation-modal.success', {
          type: selectedNotification?.type,
        }),
        'success'
      );
      refetchSubscriptions();
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  const muteModalPrimaryCallback = () => {
    muteNotification({
      variables: {
        personId: user?.id ?? '',
        subscriptions: [
          {
            type: selectedNotification?.type ?? '',
            category: selectedNotification?.category ?? 'Enrolment',
            enabled: false,
          },
        ],
      },
    });
  };

  const handleMarkNotification = useCallback(
    (notification: INotificationV2, markAs: NotificationV2MarkAs) => {
      const read = (() => {
        if (markAs === 'read') {
          return true;
        }
        if (markAs === 'unread') {
          return false;
        }
        return undefined;
      })();

      const archived = (() => {
        if (markAs === 'archived') {
          return true;
        }
        if (markAs === 'unarchive') {
          return false;
        }
        return undefined;
      })();

      return markNotificationV2fn({
        variables: {
          notificationId: notification.id,
          archived,
          read,
        },
      }).then(() => {
        if (markAs === 'unread') {
          document.dispatchEvent(syncNotificationMiniScreen);
        }
      });
    },
    [markNotificationV2fn]
  );

  const handleMarkAllNotification = useCallback(
    (notifications: string[], markAs: NotificationV2MarkAs) => {
      const read = (() => {
        if (markAs === 'read') {
          return true;
        }
        if (markAs === 'unread') {
          return false;
        }
        return undefined;
      })();

      const archived = (() => {
        if (markAs === 'archived') {
          return true;
        }
        if (markAs === 'unarchive') {
          return false;
        }
        return undefined;
      })();

      return markNotificationInDropdownV2fn({
        variables: {
          notificationIds: notifications,
          archived,
          read,
        },
      }).then(() => {
        if (markAs === 'unread') {
          document.dispatchEvent(syncNotificationMiniScreen);
        }
      });
    },
    [markNotificationInDropdownV2fn]
  );

  useEffect(() => {
    if (error) {
      showToast(getApolloErrorMessage(error), 'error');
    }
  }, [error]);

  const onExpand = (row: any, isExpand: boolean) => {
    if (isExpand && row.read === false) {
      setTimeout(() => {
        handleMarkNotification(row, 'read');
      }, 0);
    }
  };

  const handleRefetch = useCallback(() => {
    refetch().then((result) => {
      dispatch(setNotifications(result.data.notifications.data));
    });
  }, [refetch]);

  useEffect(() => {
    // to provide a mechanism to refresh the data that can be triggered by another components (e.g. mini-notification dropdown)
    document.addEventListener(customEvents.REFETCH_NOTIFICATION_V2_MAIN_SCREEN, handleRefetch);

    return () => {
      document.removeEventListener(customEvents.REFETCH_NOTIFICATION_V2_MAIN_SCREEN, handleRefetch);
    };
  }, [handleRefetch]);

  return (
    <PageWrapper pageTitle="Notifications" applyPadding={false}>
      <MuteNotificationsConfirmModal
        isOpen={showMuteModal}
        onClose={() => setShowMuteModal(false)}
        onConfirm={muteModalPrimaryCallback}
        category={selectedNotification?.category ?? null}
        type={selectedNotification?.type ?? null}
      />
      <Container fluid className="kt-notifications-v2-container">
        {selectedNotifications.length > 0 ? (
          <NotificationsBulkActions
            selectedNotifications={selectedNotifications}
            handleClose={() => setSelectedNotifications([])}
            handleMarkNotification={handleMarkAllNotification}
            refetch={refetch}
          />
        ) : (
          <NotificationsV2TableHeader filters={filters} setFilters={setFilters} />
        )}
        <NotificationV2Table
          notifications={tableData ?? []}
          loading={loading}
          pagination={{
            pageNumber: tableState.activePage,
            pageSize: tableState.pageSize,
            totalRecords: data?.notifications.totalRecords ?? 0,
          }}
          subscriptions={subscriptions?.userNotificationSubscriptions ?? []}
          timezone={businessTimezone}
          selectedNotifications={selectedNotifications}
          updateSelectedNotifications={setSelectedNotifications}
          handleMarkNotification={handleMarkNotification}
          handleNotifSubscription={(notification: INotificationV2) => {
            setShowMuteModal(true);
            setNotification(notification);
          }}
          onExpand={onExpand}
          onPageChange={tableFunctions.changePage}
        />
      </Container>
    </PageWrapper>
  );
};

export default NotificationsV2;
