import config from 'config';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';
import Tooltip from 'shared/components/Tooltip';
import './_launchDarklyFlag.scss';

interface IProps {
  show: boolean;
  position?: 'top-left' | 'top-right';
  children: React.ReactNode;
  tooltipPosition?: 'right' | 'left';
}

/**
 * wrapper component that will render the provided children components based on `show`
 * `show` should evaluate a LaunchDarkly flag and provide a boolean
 */
const LaunchDarklyFlag: React.FC<IProps> = ({ show, position = 'top-right', children, tooltipPosition, ...props }) => {
  // don't hide or show badge if the environment is develop
  if (process.env.NODE_ENV === 'development') {
    return <>{children}</>;
  }

  if (!show) {
    return null;
  }

  return (
    <div className="position-relative w-100">
      <Tooltip text="Limited Release" direction={tooltipPosition || 'right'}>
        <div className={`k2-lauchdarkly-flag  k2-lauchdarkly-flag-${position}`}>
          <FontAwesomeIcon icon={faRocketLaunch} color="#FFFFFF" size="sm" />
        </div>
      </Tooltip>
      {children}
    </div>
  );
};

export default LaunchDarklyFlag;
