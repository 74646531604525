import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MedicalProfessionalMetadata,
  Page,
  SecondContactSectionMetadata,
  SectionMetadata,
} from 'shared/types/enrollment-form';
import { SectionMetaDataErrors, getMinContactMetadataErrors } from '../../utils';
import { cloneDeep, isUndefined } from 'lodash';
import { NumberInput } from 'shared/components/TextInput';

interface IProps {
  page: Page;
  onSectionMetadataChange: (sectionMetadata: SectionMetadata) => void;
  personType?: string;
}

const MinRequiredPerson = ({ page, onSectionMetadataChange, personType }: IProps) => {
  const { t } = useTranslation(['enrollment']);
  const sectionMetadata = useMemo((): SectionMetadata<MedicalProfessionalMetadata | undefined> => {
    return page.sections.find((section) => section.namespace === 'medicalProfessionals')!
      .sectionMetadata as SectionMetadata<MedicalProfessionalMetadata>;
  }, [page.sections]);

  const { minMedicalProfessionals } = useMemo((): Omit<MedicalProfessionalMetadata, 'type'> => {
    const metadata = sectionMetadata;
    return {
      minMedicalProfessionals: metadata?.properties?.minMedicalProfessionals,
    };
  }, [sectionMetadata]);

  const errors = useMemo<{ [key: string]: string | undefined }>(() => {
    const metadataErrors: SectionMetaDataErrors = {};

    if (isUndefined(minMedicalProfessionals) || isNaN(minMedicalProfessionals)) {
      metadataErrors.minMedicalProfessionals = 'Min health professionals per child is required';
    }

    return metadataErrors;
  }, [minMedicalProfessionals]);

  const updateValue = useCallback(
    (name: keyof Omit<MedicalProfessionalMetadata, 'type'>, value) => {
      let metadata = cloneDeep(sectionMetadata);

      if (!metadata) {
        metadata = {
          type: 'MedicalProfessionalMetadata',
          properties: {},
        } as SectionMetadata<MedicalProfessionalMetadata>;
      }

      metadata!.properties![name] = Number(value);

      onSectionMetadataChange({ ...metadata });
    },
    [sectionMetadata, onSectionMetadataChange]
  );

  return (
    <div className="min-required-contact">
      <h6>{'Minimum Required Health Professionals Per Child'}</h6>
      <div className="d-flex">
        <NumberInput
          label={'Min Health Professionals'}
          value={minMedicalProfessionals}
          onChange={(value) => updateValue('minMedicalProfessionals', value)}
          className="min-required-contact-input"
          required
          numberFormat={{
            allowNegative: false,
            decimalScale: 0,
          }}
          isInvalid={Boolean(errors.minMedicalProfessionals)}
        />
      </div>
      {Object.values(errors).map((error) => (
        <small className="text-danger d-block">{error}</small>
      ))}
    </div>
  );
};

export default MinRequiredPerson;
