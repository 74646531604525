import { unionBy } from 'lodash';
import * as type from './types';
import { ICcsEntitlement } from 'shared/types/ccsEntitlement';
import _ from 'lodash';

interface IAccountContractStateShape {
  /**
   * key: account id
   * value: dictionary whose keys are child ids and the values are arrays of contracts
   */
  byAccountId: Record<string, Record<string, IContract[]>>;
}

interface IAccountCcsEnrolmentStateShape {
  /**
   * key: account id
   * value: dictionary whose keys are child ids and the values are arrays of ccs enrolments
   */
  byAccountId: Record<string, Record<string, ICcsEnrolment[]>>;
}

interface IAccountCcsCertificateStateShape {
  /**
   * key: account id
   * value: dictionary whose keys are child ids and the values are arrays of ccs certificates
   */
  byAccountId: Record<string, Record<string, ICcssCertificate[]>>;
}

interface IAccountCcsEntitlementStateShape {
  /**
   * key: account id
   * value: dictionary whose keys are child ids and the value is the current ccs entitlement
   */
  byAccountId: Record<string, Record<string, ICcsEntitlement>>;
}

interface IAccountCcsDeterminationStateShape {
  /**
   * key: account id
   * value: dictionary whose keys are child ids and the values are arrays of ccs determinations
   */
  byAccountId: Record<string, Record<string, ICcssDetermination[]>>;
}

interface IAccountItemizedBillsStateShape {
  byAccountId: Record<
    string,
    {
      itemizedBills: IItemizedBillWithoutTransactions[]; // data for the select dropdown
      selectedItemizedBillId: string | null;
      selectedItemizedBill: IItemizedBill | null;
    }
  >;
}

export interface IAccountsStateShape {
  all: IAccount[];
  totalResults: number;
  byId: Record<string, IAccount>;
  contracts: IAccountContractStateShape;
  ccsEnrolments: IAccountCcsEnrolmentStateShape;
  ccsCertificates: IAccountCcsCertificateStateShape;
  ccsEntitlements: IAccountCcsEntitlementStateShape;
  ccsDeterminations: IAccountCcsDeterminationStateShape;
  itemizedBills: IAccountItemizedBillsStateShape;
}

const initialState: IAccountsStateShape = {
  all: [],
  totalResults: 0,
  byId: {},
  contracts: {
    byAccountId: {},
  },
  ccsEnrolments: {
    byAccountId: {},
  },
  ccsCertificates: {
    byAccountId: {},
  },
  ccsEntitlements: {
    byAccountId: {},
  },
  ccsDeterminations: {
    byAccountId: {},
  },
  itemizedBills: {
    byAccountId: {},
  },
};

export const accountsReducers = (
  state: IAccountsStateShape = initialState,
  action: type.AccountActionTypes
): IAccountsStateShape => {
  let contractsByAccountId = { ...state.contracts.byAccountId };
  let accountContractsObj, accountChildContracts;
  let ccsCertificatesByAccountId = { ...state.ccsCertificates.byAccountId };
  let ccsEntitlementsByAccountId = { ...state.ccsEntitlements.byAccountId };
  let ccsDeterminationsByAccountId = { ...state.ccsDeterminations.byAccountId };

  switch (action.type) {
    case type.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        all: action.accounts,
        totalResults: action.totalResults,
        byId: Object.fromEntries(action.accounts.map((a: IAccount) => [a.id, a])),
      };
    case type.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.account],
        byId: { ...state.byId, [action.account.id]: action.account },
      };
    case type.GET_ACCOUNT_BY_ID_SUCCESS:
    case type.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        all: [
          ...state.all.filter((a) => a.id !== action.account.id),
          { ...state.byId[action.account.id], ...action.account },
        ],
        byId: {
          ...state.byId,
          [action.account.id]: {
            ...state.byId[action.account.id],
            ...action.account,
          },
        },
      };
    case type.GET_PAYMENT_METHODS_FOR_ACCOUNT_SUCCESS:
      let accountCopy = {
        ...state.byId[action.accountId as string],
        paymentMethods: action.paymentMethods,
      };
      return {
        ...state,
        all: state.all.map((account) => (account.id === action.accountId ? accountCopy : account)),
        byId: {
          ...state.byId,
          [action.accountId]: accountCopy,
        },
      };
    case type.CREATE_PAYMENT_METHOD_FOR_ACCOUNT_SUCCESS:
      const acc = state.byId[action.paymentMethod.accountId as string];
      if (acc) {
        acc.paymentMethods = [...(acc.paymentMethods ?? []), action.paymentMethod];

        acc.paymentMethods = acc.paymentMethods.map((pm) => ({
          ...pm,
          isAutoPay: action.paymentMethod.isAutoPay ? action.paymentMethod.id === pm.id : pm.isAutoPay,
          isPrimary: action.paymentMethod.isPrimary ? action.paymentMethod.id === pm.id : pm.isPrimary,
        }));
      }

      return {
        ...state,
        all: state.all.map((account) => (account.id === acc?.id ? acc : account)),
        byId: {
          ...state.byId,
          [acc.id]: acc,
        },
      };
    case type.ADD_NEW_CONTACT_TO_ACCOUNT_SUCCESS:
      const accountContactAddedTo = state.byId[action.accountId];
      if (accountContactAddedTo) {
        accountContactAddedTo.contacts = [...(accountContactAddedTo.contacts ?? []), action.contact];
      }

      return {
        ...state,
        all: state.all.map((account) => (account.id === accountContactAddedTo?.id ? accountContactAddedTo : account)),
        byId: {
          ...state.byId,
          [accountContactAddedTo.id]: accountContactAddedTo,
        },
      };
    case type.UPDATE_CONTACT_RELATIONSHIPS_SUCCESS:
      const accountUpdated = state.byId[action.accountId];
      if (accountUpdated) {
        accountUpdated.contacts = (accountUpdated.contacts ?? []).map((contact) =>
          contact.id === action.contactId
            ? {
                ...contact,
                children: action.children,
                isPrimary: action.isPrimary,
                email: action.email ?? contact.email,
                contactAccountPermissions: contact.contactAccountPermissions.map((cap) => {
                  return cap.accountId === action.accountId ? { ...cap, permissions: action.permissions ?? [] } : cap;
                }),
              }
            : contact
        );
      }

      return {
        ...state,
        all: state.all.map((account) => (account.id === accountUpdated?.id ? accountUpdated : account)),
        byId: {
          ...state.byId,
          [accountUpdated.id]: accountUpdated,
        },
      };
    case type.REMOVE_CONTACT_FROM_ACCOUNT_SUCCESS:
      const accountContactRemovedFrom = state.byId[action.accountId];
      if (accountContactRemovedFrom) {
        accountContactRemovedFrom.contacts = (accountContactRemovedFrom.contacts ?? []).filter(
          (c) => c.id !== action.contactId
        );
      }

      return {
        ...state,
        all: state.all.map((account) =>
          account.id === accountContactRemovedFrom?.id ? accountContactRemovedFrom : account
        ),
        byId: {
          ...state.byId,
          [accountContactRemovedFrom.id]: accountContactRemovedFrom,
        },
      };
    case type.ARCHIVE_CHILD_ON_ACCOUNT_SUCCESS:
      const accountChildArchivedOn = state.byId[action.accountId];
      if (accountChildArchivedOn) {
        accountChildArchivedOn.children = (accountChildArchivedOn.children ?? []).map((c) =>
          c.id === action.childId ? { ...c, archivedAt: new Date().toISOString() } : c
        );
        accountChildArchivedOn.contacts = (accountChildArchivedOn.contacts ?? []).map((contact) => ({
          ...contact,
          children: contact.children.filter((c) => c.childId !== action.childId),
        }));
      }

      return {
        ...state,
        all: state.all.map((account) => (account.id === action.accountId ? accountChildArchivedOn : account)),
        byId: {
          ...state.byId,
          [accountChildArchivedOn.id]: accountChildArchivedOn,
        },
      };
    case type.UNARCHIVE_CHILD_ON_ACCOUNT_SUCCESS:
      const accountChildUnarchived = state.byId[action.accountId];
      if (accountChildUnarchived) {
        accountChildUnarchived.children = (accountChildUnarchived.children ?? []).map((c) =>
          c.id === action.childId ? { ...c, archivedAt: null } : c
        );
        accountChildUnarchived.contacts = (accountChildUnarchived.contacts ?? []).map((contact) => ({
          ...contact,
          children: contact.children.filter((c) => c.childId !== action.childId),
        }));
      }

      return {
        ...state,
        all: state.all.map((account) => (account.id === action.accountId ? accountChildUnarchived : account)),
        byId: {
          ...state.byId,
          [accountChildUnarchived.id]: accountChildUnarchived,
        },
      };
    case type.REMOVE_CHILD_ON_ACCOUNT_SUCCESS:
      const accountChildRemovedOn = state.byId[action.accountId];
      if (accountChildRemovedOn) {
        accountChildRemovedOn.children = (accountChildRemovedOn.children ?? []).filter((c) => c.id !== action.childId);
        accountChildRemovedOn.contacts = (accountChildRemovedOn.contacts ?? []).map((contact) => ({
          ...contact,
          children: contact.children.filter((c) => c.childId !== action.childId),
        }));
      }

      return {
        ...state,
        all: state.all.map((account) => (account.id === action.accountId ? accountChildRemovedOn : account)),
        byId: {
          ...state.byId,
          [accountChildRemovedOn.id]: accountChildRemovedOn,
        },
      };
    case type.UPDATE_PAYMENT_METHOD_FOR_ACCOUNT_SUCCESS:
      const accountPayment = state.byId[action.paymentMethod.accountId as string];

      if (accountPayment) {
        accountPayment.paymentMethods = accountPayment.paymentMethods?.length
          ? accountPayment.paymentMethods.map((paymentMethod) =>
              paymentMethod.id === action.paymentMethod.id ? action.paymentMethod : paymentMethod
            )
          : [action.paymentMethod];
      }

      return {
        ...state,
        all: state.all.map((account) => (account.id === accountPayment?.id ? accountPayment : account)),
        byId: {
          ...state.byId,
          [accountPayment.id]: accountPayment,
        },
      };
    case type.SET_ACCOUNT_DEFAULT_PAYMENT_METHOD:
      const accountToUpdateDefaultMethod = state.byId[action.accountId];

      if (accountToUpdateDefaultMethod) {
        // assumes the payment method is already in the array
        accountToUpdateDefaultMethod.paymentMethods = accountToUpdateDefaultMethod.paymentMethods.map((pm) => ({
          ...pm,
          isPrimary: pm.id === action.paymentMethodId,
        }));
      }

      return {
        ...state,
        all: state.all.map((account) =>
          account.id === accountToUpdateDefaultMethod?.id ? accountToUpdateDefaultMethod : account
        ),
        byId: {
          ...state.byId,
          [accountToUpdateDefaultMethod.id]: accountToUpdateDefaultMethod,
        },
      };
    case type.SET_ACCOUNT_AUTOPAY_PAYMENT_METHOD:
      const accountToUpdateAutopay = state.byId[action.accountId];

      if (accountToUpdateAutopay) {
        // assumes the payment method is already in the array
        accountToUpdateAutopay.paymentMethods = accountToUpdateAutopay.paymentMethods.map((pm) => ({
          ...pm,
          isAutoPay: pm.id === action.paymentMethodId,
        }));
      }

      return {
        ...state,
        all: state.all.map((account) => (account.id === accountToUpdateAutopay?.id ? accountToUpdateAutopay : account)),
        byId: {
          ...state.byId,
          [accountToUpdateAutopay.id]: accountToUpdateAutopay,
        },
      };
    case type.REMOVE_ACCOUNT_PAYMENT_METHOD:
      const accountToRemovePaymentMethod = state.byId[action.accountId];

      if (accountToRemovePaymentMethod) {
        accountToRemovePaymentMethod.paymentMethods = accountToRemovePaymentMethod.paymentMethods.filter(
          (pm) => pm.id !== action.paymentMethodId
        );
      }

      return {
        ...state,
        all: state.all.map((account) =>
          account.id === accountToRemovePaymentMethod?.id ? accountToRemovePaymentMethod : account
        ),
        byId: {
          ...state.byId,
          [accountToRemovePaymentMethod.id]: accountToRemovePaymentMethod,
        },
      };
    case type.GET_CONTRACTS_FOR_ACCOUNT_CHILD:
      if (!contractsByAccountId[action.accountId]) {
        contractsByAccountId[action.accountId] = {
          [action.childId]: action.contracts,
        };
      } else if (contractsByAccountId[action.accountId] && !contractsByAccountId[action.accountId][action.childId]) {
        contractsByAccountId[action.accountId][action.childId] = action.contracts;
      } else {
        const contractsArr = unionBy(
          contractsByAccountId[action.accountId][action.childId],
          action.contracts,
          (contract) => contract.id
        );

        contractsByAccountId[action.accountId][action.childId] = contractsArr;
      }

      return {
        ...state,
        contracts: {
          ...state.contracts,
          byAccountId: {
            ...state.contracts.byAccountId,
            ...contractsByAccountId,
          },
        },
      };
    case type.CREATE_CONTRACT:
      accountContractsObj = contractsByAccountId[action.contract.accountId] ?? {};
      accountChildContracts = accountContractsObj[action.contract.childId] ?? [];
      return {
        ...state,
        contracts: {
          ...state.contracts,
          byAccountId: {
            ...state.contracts.byAccountId,
            [action.contract.accountId]: {
              ...accountContractsObj,
              [action.contract.childId]: [...accountChildContracts, action.contract],
            },
          },
        },
      };
    case type.UPDATE_CONTRACT:
      accountContractsObj = contractsByAccountId[action.contract.accountId] ?? {};
      accountChildContracts = accountContractsObj[action.contract.childId] ?? [];
      if (action.contract && action.newContract) {
        action.contract.lastModifiedAt = action.newContract.createdAt;
        action.contract.lastModifiedBy = action.newContract.createdBy;
        action.contract.lastModifiedByAccount = action.newContract.createdByAccount;
      }

      return {
        ...state,
        contracts: {
          ...state.contracts,
          byAccountId: {
            ...state.contracts.byAccountId,
            [action.contract.accountId]: {
              ...accountContractsObj,
              [action.contract.childId]: accountChildContracts.map((c) =>
                c.id === action.contract.id ? action.contract : c
              ),
            },
          },
        },
      };
    case type.DELETE_CONTRACT:
      accountContractsObj = contractsByAccountId[action.contract.accountId] ?? {};
      accountChildContracts = accountContractsObj[action.contract.childId] ?? [];

      return {
        ...state,
        contracts: {
          ...state.contracts,
          byAccountId: {
            ...state.contracts.byAccountId,
            [action.contract.accountId]: {
              ...accountContractsObj,
              [action.contract.childId]: accountChildContracts.filter((c) => c.id !== action.contract.id),
            },
          },
        },
      };

    case type.SET_ACCOUNT_BILLING_CYCLE:
      return {
        ...state,
        all: state.all.map((a) => (a.id === action.cycle.accountId ? { ...a, billingCycle: action.cycle } : a)),
        byId: {
          ...state.byId,
          [action.cycle.accountId]: {
            ...state.byId[action.cycle.accountId],
            billingCycle: action.cycle,
          },
        },
      };

    case type.GET_CCS_CERTIFICATE_FOR_ACCOUNT_CHILD:
      if (!ccsCertificatesByAccountId[action.accountId]) {
        ccsCertificatesByAccountId[action.accountId] = {
          [action.childId]: action.certificate,
        };
      } else if (
        ccsCertificatesByAccountId[action.accountId] &&
        !ccsCertificatesByAccountId[action.accountId][action.childId]
      ) {
        ccsCertificatesByAccountId[action.accountId][action.childId] = action.certificate;
      } else {
        const certificatesArr = unionBy(
          ccsCertificatesByAccountId[action.accountId][action.childId],
          action.certificate,
          (certificate) => certificate.certificateId
        );

        ccsCertificatesByAccountId[action.accountId][action.childId] = certificatesArr;
      }

      return {
        ...state,
        ccsCertificates: {
          ...state.ccsCertificates,
          byAccountId: {
            ...state.ccsCertificates.byAccountId,
            ...ccsCertificatesByAccountId,
          },
        },
      };

    case type.GET_CCS_ENTITLEMENTS_FOR_ACCOUNT_CHILD:
      if (!ccsEntitlementsByAccountId[action.accountId]) {
        ccsEntitlementsByAccountId[action.accountId] = {
          [action.childId]: action.entitlement,
        };
      } else {
        ccsEntitlementsByAccountId[action.accountId][action.childId] = action.entitlement;
      }

      return {
        ...state,
        ccsEntitlements: {
          ...state.ccsEntitlements,
          byAccountId: {
            ...state.ccsEntitlements.byAccountId,
            ...ccsEntitlementsByAccountId,
          },
        },
      };

    case type.GET_CCS_DETERMINATION_FOR_ACCOUNT_CHILD:
      if (!ccsDeterminationsByAccountId[action.accountId]) {
        ccsDeterminationsByAccountId[action.accountId] = {
          [action.childId]: action.determination,
        };
      } else if (
        ccsDeterminationsByAccountId[action.accountId] &&
        !ccsDeterminationsByAccountId[action.accountId][action.childId]
      ) {
        ccsDeterminationsByAccountId[action.accountId][action.childId] = action.determination;
      } else {
        const determinationsArr = unionBy(
          ccsDeterminationsByAccountId[action.accountId][action.childId],
          action.determination,
          (determination) => determination.determinationId
        );

        ccsDeterminationsByAccountId[action.accountId][action.childId] = determinationsArr;
      }

      return {
        ...state,
        ccsDeterminations: {
          ...state.ccsDeterminations,
          byAccountId: {
            ...state.ccsDeterminations.byAccountId,
            ...ccsDeterminationsByAccountId,
          },
        },
      };
    case type.ADD_TRANSACTION_AMOUNT_TO_ACCOUNT_BALANCE:
      const accountToUpdateBalance = state.byId[action.accountId];
      if (accountToUpdateBalance) {
        const byId = {
          ...state.byId,
          [action.accountId]: {
            ...accountToUpdateBalance,
            balance: (accountToUpdateBalance.balance ?? 0) + action.amount,
          },
        };
        return {
          ...state,
          byId,
          all: Object.values(byId),
        };
      }
      return state;
    case type.UPDATE_CERTIFICATE:
      const accountCertificates = ccsCertificatesByAccountId[action.accountId][action.certificate.childId];
      const updatedCertificates = accountCertificates.map((c: ICcssCertificate) => {
        return c.certificateId === action.certificate.certificateId ? action.certificate : c;
      });
      ccsCertificatesByAccountId[action.accountId][action.certificate.childId] = updatedCertificates;

      return {
        ...state,
        ccsCertificates: {
          ...state.ccsCertificates,
          byAccountId: {
            ...state.ccsCertificates.byAccountId,
            ...ccsCertificatesByAccountId,
          },
        },
      };
    case type.UPDATE_DETERMINATION:
      const accountDeterminations = {
        ...ccsDeterminationsByAccountId[action.accountId][action.determination.childId as string],
      };
      const updatedDeterminations = accountDeterminations.map((d: ICcssDetermination) => {
        return d.determinationId === action.determination.determinationId ? action.determination : d;
      });
      ccsDeterminationsByAccountId[action.accountId][action.determination.childId as string] = updatedDeterminations;

      return {
        ...state,
        ccsDeterminations: {
          ...state.ccsDeterminations,
          byAccountId: {
            ...state.ccsDeterminations.byAccountId,
            ...ccsDeterminationsByAccountId,
          },
        },
      };
    case type.APPLY_DISCOUNT_TO_ACCOUNT_SUCCESS:
      return {
        ...state,
        all: state.all.map((account) =>
          account.id === action.accountId
            ? {
                ...account,
                discounts: [...account.discounts, ...action.appliedDiscounts],
              }
            : account
        ),
        byId: {
          ...state.byId,
          [action.accountId]: {
            ...state.byId[action.accountId],
            discounts: [...state.byId[action.accountId].discounts, ...action.appliedDiscounts],
          },
        },
      };
    case type.UPDATE_APPLIED_ACCOUNT_DISCOUNT_SUCCESS:
      return {
        ...state,
        all: state.all.map((account) =>
          account.id === action.accountId
            ? {
                ...account,
                discounts: account.discounts.map((d) =>
                  d.id === action.appliedDiscount.id ? action.appliedDiscount : d
                ),
              }
            : account
        ),
        byId: {
          ...state.byId,
          [action.accountId]: {
            ...state.byId[action.accountId],
            discounts: state.byId[action.accountId].discounts.map((d) =>
              d.id === action.appliedDiscount.id ? action.appliedDiscount : d
            ),
          },
        },
      };
    case type.REMOVE_APPLIED_ACCOUNT_DISCOUNT_SUCCESS:
      const accountDiscountRemovedFrom = state.byId[action.appliedDiscount.accountId];
      const discountToRemove = accountDiscountRemovedFrom.discounts.find((d) => d.id === action.appliedDiscount.id);

      if (discountToRemove) {
        const wasEnded = discountToRemove.endDate === null && action.appliedDiscount.endDate !== null;

        accountDiscountRemovedFrom.discounts = wasEnded
          ? accountDiscountRemovedFrom.discounts.map((d) =>
              d.id === action.appliedDiscount.id ? { ...action.appliedDiscount } : d
            )
          : accountDiscountRemovedFrom.discounts.filter((d) => d.id !== action.appliedDiscount.id);
      }

      return {
        ...state,
        all: state.all.map((account) =>
          account.id === accountDiscountRemovedFrom.id ? accountDiscountRemovedFrom : account
        ),
        byId: {
          ...state.byId,
          [accountDiscountRemovedFrom.id]: accountDiscountRemovedFrom,
        },
      };
    case type.GET_ACCOUNT_ITEMIZED_BILLS_SUCCESS:
      let accountToUpdateItemizedBills = state.itemizedBills.byAccountId[action.accountId];

      if (!accountToUpdateItemizedBills) {
        accountToUpdateItemizedBills = {
          itemizedBills: [],
          selectedItemizedBill: null,
          selectedItemizedBillId: null,
        };
      }

      accountToUpdateItemizedBills.itemizedBills = action.itemizedBills;
      accountToUpdateItemizedBills.selectedItemizedBillId = !accountToUpdateItemizedBills.selectedItemizedBillId
        ? action.itemizedBills.find((ib) => ib.isCurrentBill)?.id ?? null
        : accountToUpdateItemizedBills.selectedItemizedBillId;

      return {
        ...state,
        itemizedBills: {
          ...state.itemizedBills,
          byAccountId: {
            ...state.itemizedBills.byAccountId,
            [action.accountId]: accountToUpdateItemizedBills,
          },
        },
      };
    case type.SET_SELECTED_ACCOUNT_ITEMIZED_BILL:
      let accountToUpdateSelectedItemizedBills = state.itemizedBills.byAccountId[action.accountId];

      if (!accountToUpdateSelectedItemizedBills) {
        accountToUpdateSelectedItemizedBills = {
          itemizedBills: [],
          selectedItemizedBill: null,
          selectedItemizedBillId: null,
        };
      }

      accountToUpdateSelectedItemizedBills.selectedItemizedBillId = action.itemizedBillId;

      return {
        ...state,
        itemizedBills: {
          ...state.itemizedBills,
          byAccountId: {
            ...state.itemizedBills.byAccountId,
            [action.accountId]: accountToUpdateSelectedItemizedBills,
          },
        },
      };
    case type.GET_SELECTED_ACCOUNT_ITEMIZED_BILL:
      let accountToUpdateSelectedItemizedBill = state.itemizedBills.byAccountId[action.accountId];

      if (!accountToUpdateSelectedItemizedBill) {
        accountToUpdateSelectedItemizedBill = {
          itemizedBills: [],
          selectedItemizedBill: null,
          selectedItemizedBillId: null,
        };
      }

      accountToUpdateSelectedItemizedBill.selectedItemizedBillId = action.itemizedBill.id;
      accountToUpdateSelectedItemizedBill.selectedItemizedBill = action.itemizedBill;

      return {
        ...state,
        itemizedBills: {
          ...state.itemizedBills,
          byAccountId: {
            ...state.itemizedBills.byAccountId,
            [action.accountId]: accountToUpdateSelectedItemizedBill,
          },
        },
      };
    case type.CREATE_NEW_MANUAL_ITEMIZED_BILL:
      let accountToInsertCreatedItemizedBill = state.itemizedBills.byAccountId[action.accountId];

      if (!accountToInsertCreatedItemizedBill) {
        accountToInsertCreatedItemizedBill = {
          itemizedBills: [],
          selectedItemizedBill: null,
          selectedItemizedBillId: null,
        };
      }

      accountToInsertCreatedItemizedBill.selectedItemizedBillId = action.itemizedBill.id;
      accountToInsertCreatedItemizedBill.selectedItemizedBill = action.itemizedBill;
      accountToInsertCreatedItemizedBill.itemizedBills = [
        ...accountToInsertCreatedItemizedBill.itemizedBills,
        action.itemizedBill,
      ];

      return {
        ...state,
        itemizedBills: {
          ...state.itemizedBills,
          byAccountId: {
            ...state.itemizedBills.byAccountId,
            [action.accountId]: accountToInsertCreatedItemizedBill,
          },
        },
      };
    case type.GET_ACCOUNT_BILLING_CYCLE_SUCCESS:
      if (!action.billingCycle) {
        return state;
      }

      return {
        ...state,
        all: state.all.map((a) =>
          a.id === action.billingCycle.accountId ? { ...a, billingCycle: action.billingCycle } : a
        ),
        byId: {
          ...state.byId,
          [action.billingCycle.accountId]: {
            ...state.byId[action.billingCycle.accountId],
            billingCycle: action.billingCycle,
          },
        },
      };
    default:
      return state;
  }
};
