import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import HelpTooltip from '../Tooltip/HelpTooltip';
import './dateInput.scss';
import { DefaultCountry } from './countryCodes';

export interface DateRangeIProps {
  id?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  appendToBody?: boolean;
  startDate?: string | null;
  endDate?: string | null;
  onChange?: (dates: { startDate: string; endDate: string }) => void;
  className?: string;
  placeholder?: string;
  isOutsideRange?: (day: any) => boolean;
  helpTooltipText?: string;
  timezone?: string;
}

const DateRangeInput: React.FC<DateRangeIProps> = ({
  id = 'date-range-input',
  label,
  required = false,
  disabled = false,
  appendToBody = false,
  startDate,
  endDate,
  onChange,
  className,
  placeholder = 'Select Date',
  isOutsideRange = () => false,
  helpTooltipText,
  timezone,
  ...props
}) => {
  const [focusedInput, setFocusedInput] = useState<any>();

  const onDatesChange = useCallback(
    (dates) => {
      onChange && onChange({ startDate: dates.startDate?.toString(), endDate: dates.endDate?.toString() });
    },
    [onChange]
  );

  // return an array of <select> components where each value is a year
  const renderYearSelection = useCallback(() => {
    const years: React.ReactNode[] = [];

    // generate an array of years from 100 years prior to 10 years in the future
    for (let i = moment().year() - 100; i <= moment().year() + 10; i++) {
      years.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return years;
  }, []);

  return (
    <div className={`kt-date-input k2-date-range  ${className}`}>
      {label && (
        <div className="d-flex flex-row">
          {label && <Form.Label>{label}</Form.Label>}
          {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
          {helpTooltipText && <HelpTooltip text={helpTooltipText} />}
        </div>
      )}
      <DateRangePicker
        enableOutsideDays
        hideKeyboardShortcutsPanel
        startDateId={`${id}-startDate`}
        endDateId={`${id}-endDate`}
        customInputIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
        customArrowIcon={<span>-</span>}
        displayFormat={DefaultCountry.dateFormat}
        firstDayOfWeek={DefaultCountry.firstDayOfWeek}
        startDate={startDate ? moment(startDate).tz(timezone ?? moment.tz.guess()) : null}
        endDate={endDate ? moment(endDate).tz(timezone ?? moment.tz.guess()) : null}
        numberOfMonths={1}
        focusedInput={focusedInput}
        onFocusChange={(input) => setFocusedInput(input)}
        disabled={disabled}
        isOutsideRange={isOutsideRange}
        onDatesChange={onDatesChange}
        keepOpenOnDateSelect={true}
        navPrev={<div />} // disable default month navigation component since we render custom ones with `renderMonthElement`
        navNext={<div />} // disable default month navigation component since we render custom ones with `renderMonthElement`
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
          <div className="d-flex justify-content-center">
            <div>
              <select
                className="kt-date-input-select"
                value={month.month()}
                onChange={(event) => onMonthSelect(month, event.target.value)}
              >
                {moment.months().map((label, value) => (
                  <option value={value} key={label}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select
                className="kt-date-input-select"
                value={month.year()}
                onChange={(event) => onYearSelect(month, event.target.value)}
              >
                {renderYearSelection()}
              </select>
            </div>
          </div>
        )}
        {...props}
      />
    </div>
  );
};

export default DateRangeInput;
