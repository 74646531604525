import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createNewPasswordRequest } from '../duck/actions';
import Button from 'react-bootstrap/Button';
import { EmailInput, CreatePasswordInput } from 'shared/components/TextInput';
import { meetsAllRequirements } from 'shared/util/passwordRequirements';
import { isEmailSyntaxValid, isEmailValid } from 'shared/util/email';
import errorMessage from 'shared/constants/errorMessages';

const CreateNewPassword = ({ code }) => {
  const dispatch = useDispatch();
  const [email, updateEmail] = useState('');
  const [newPassword, updateNewPassword] = useState('');

  const createNewPassword = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(createNewPasswordRequest(email, code, newPassword));
    },
    [dispatch, email, code, newPassword]
  );

  return (
    <form className="d-flex flex-column" onSubmit={createNewPassword}>
      <p className="h1 font-weight-bold mb-8">Password Recovery</p>
      <EmailInput
        className="flex-grow-1 mb-4"
        label="Email Address"
        value={email}
        onChange={updateEmail}
        isInvalid={!isEmailValid(email)}
        errorText={!isEmailSyntaxValid(email) ? errorMessage.invalidEmailSyntax : errorMessage.invalidEmailDomain}
      />
      <div className="d-flex flex-wrap">
        <CreatePasswordInput
          className="flex-grow-1 mr-2"
          label="New Password"
          value={newPassword}
          onChange={updateNewPassword}
          placement="left"
          isInvalid={!meetsAllRequirements(newPassword)}
          errorText={errorMessage.passwordRequirements}
        />
        <Button className="mt-7" type="submit" disabled={!meetsAllRequirements(newPassword) || !isEmailValid(email)}>
          Continue
        </Button>
      </div>
    </form>
  );
};

export default CreateNewPassword;
