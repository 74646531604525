import React, { useCallback, useState } from 'react';
import { CardActionDropdown } from 'shared/components/Card';
import { useDeleteTag } from '../graphql/mutations';
import DeleteTagModal from './DeleteTagModal';
import { tagColorNames } from 'shared/constants/tagColors';
import TextInput from 'shared/components/TextInput';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IProps {
  tag: ITag;
  tagInEditMode: string;
  setFormData: (formData: IUpdateTag) => void;
  formData: IUpdateTag;
  enableEditModeForTag: (id: string) => void;
  baseId?: string;
}

const TagRow: React.FC<IProps> = ({
  tag,
  tagInEditMode,
  setFormData,
  formData,
  enableEditModeForTag,
  baseId = '',
  ...props
}) => {
  const [deleteTag] = useDeleteTag();
  const [deleteTagModalIsVisible, setDeleteTagModalIsVisible] = useState(false);
  const hasEditTagPermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Tags,
    level: RoleLevelType.Edit,
  });
  const hasDeleteTagPermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Tags,
    level: RoleLevelType.Delete,
  });
  const showAdditionalActionsForTag = hasEditTagPermission || hasDeleteTagPermission;

  const showDeleteTagModal = useCallback(() => setDeleteTagModalIsVisible(true), [setDeleteTagModalIsVisible]);
  const hideDeleteTagModal = useCallback(() => setDeleteTagModalIsVisible(false), [setDeleteTagModalIsVisible]);
  const handleDeleteTag = useCallback(() => {
    tag &&
      tag.entityId &&
      deleteTag(tag.id, tag.entityId).then(() => {
        hideDeleteTagModal();
      });
  }, [deleteTag, tag, hideDeleteTagModal]);

  const handleChange = useCallback(
    (value, name) => {
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [setFormData, formData]
  );

  return (
    <div className="mb-1 d-flex flex-row">
      {tagInEditMode === tag.id ? (
        <TextInput
          id="update-tag-input"
          className="display-tags--tag-input"
          name="name"
          value={formData.name}
          onChange={handleChange}
          key={tag.id}
        />
      ) : (
        <div
          id={baseId}
          className={`bg-${tagColorNames[tag.category]} flex-fill text-white display-tags--tag py-1 px-3 mr-2`}
        >
          {tag.name}
        </div>
      )}
      <div className="display-tags--tag-dd-btn">
        {tagInEditMode !== tag.id && showAdditionalActionsForTag && (
          <CardActionDropdown
            id={`${baseId}-dropdown-btn`}
            actions={[
              ...(hasEditTagPermission
                ? [
                    {
                      id: 'edit-tag-dropdown-btn',
                      label: 'Edit',
                      onClick: () => {
                        enableEditModeForTag(tag.id);
                        setFormData(tag);
                      },
                    },
                  ]
                : []),
              ...(hasDeleteTagPermission
                ? [
                    {
                      id: 'delete-tag-dropdown-btn',
                      label: 'Delete',
                      onClick: () => {
                        showDeleteTagModal();
                      },
                    },
                  ]
                : []),
            ]}
          />
        )}
      </div>
      <DeleteTagModal
        isOpen={deleteTagModalIsVisible}
        // TODO: Wire this up
        isLoading={false}
        onClose={hideDeleteTagModal}
        deleteTag={handleDeleteTag}
        tag={tag}
      />
    </div>
  );
};

export default TagRow;
