import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { IApplicationSettings } from 'shared/types/application';
import { applicationSettingsFields } from './fields';
import { ApplicationFlowType } from 'generated/graphql';

export const UPDATE_APPLICATION_SETTINGS = gql`
  mutation($input: UpdateApplicationSettingInput!) {
    updateApplicationSetting(input: $input) {
      ${applicationSettingsFields}
    }
  }
`;

interface IUpdateApplicationSettingsInput extends TenantAware {
  offersExpireInDays: number;
  flowType: ApplicationFlowType;
  isUseProgramGroup?: boolean;
  isUsePerProgramGroupFees?: boolean;
}

export const useUpdateApplicationSettings = (
  options: MutationHookOptions<
    { updateApplicationSetting: IApplicationSettings },
    { input: IUpdateApplicationSettingsInput }
  >
) => {
  return useMutation<{ updateApplicationSetting: IApplicationSettings }, { input: IUpdateApplicationSettingsInput }>(
    UPDATE_APPLICATION_SETTINGS,
    options
  );
};
