import React, { useState } from 'react';
import { useGetAccountDeposits } from 'gql/deposit/queries';
import AccountDepositsTable from './AccountDepositsTable';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import Button, { CreateButton } from 'shared/components/Buttons';
import CreateDepositModal from 'shared/components/CreateDepositModal';
import ReturnDepositModal from 'pages/BillingDeposits/components/ReturnDepositModal';
import AccountDepositOverviewCard from './AccountDepositOverviewCard';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import { orderBy } from 'lodash';

interface IProps {
  account?: IAccount;
  accountId: string;
}

const AccountDeposits: React.FC<IProps> = ({ accountId, account, ...props }) => {
  const [createDepositModalIsOpen, setCreateDepositModalIsOpen] = useState(false);
  const [returnDepositModalIsOpen, setReturnDepositModalIsOpen] = useState(false);

  const hasCreatedBaseDepositPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });

  const hasReturnDepositPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });

  const accounts: IAccount[] = [account] as IAccount[];
  const accountIds: string[] = [accountId];

  const {
    data: deposits,
    loading,
    refetch: refetchDeposits,
  } = useGetAccountDeposits({
    variables: {
      input: {
        accountId: accountId,
      },
    },
    skip: accountId == null || accountId === '',
  });
  const balance = deposits?.getAccountDeposits.reduce((sum, t) => sum + t.amount, 0) ?? 0;
  const sortedDeposits = orderBy(deposits?.getAccountDeposits || [], 'date', 'desc');

  return (
    <div className="mx-auto">
      <div>
        <div className="d-flex align-items-center mb-4">
          <h3>Deposits</h3>
          <Button
            variant="secondary"
            className="ml-auto"
            onClick={() => setReturnDepositModalIsOpen(true)}
            disabled={!hasReturnDepositPermissions || balance === 0 || !account}
          >
            Return Deposit
          </Button>
          {hasCreatedBaseDepositPermissions && account && (
            <CreateButton className="ml-4" onClick={() => setCreateDepositModalIsOpen(true)}>
              Add Deposit Record
            </CreateButton>
          )}
        </div>
        <AccountDepositOverviewCard balance={balance} loading={loading} />

        {loading && !deposits ? (
          <DataTableLoadingSkeleton />
        ) : (
          <AccountDepositsTable loading={loading} data={sortedDeposits} account={account} />
        )}

        {createDepositModalIsOpen && (
          <CreateDepositModal
            isOpen={createDepositModalIsOpen}
            onClose={() => setCreateDepositModalIsOpen(false)}
            accounts={accounts}
            accountIds={accountIds}
            children={account?.children}
            refetchDeposits={refetchDeposits}
          />
        )}

        {returnDepositModalIsOpen && (
          <ReturnDepositModal
            isOpen={returnDepositModalIsOpen}
            onClose={() => setReturnDepositModalIsOpen(false)}
            account={account}
            accountId={accountId}
            selectedDepositBalances={null}
            refetchDeposits={refetchDeposits}
          />
        )}
      </div>
    </div>
  );
};

export default AccountDeposits;
