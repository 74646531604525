import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageWrapper from 'shared/components/PageWrapper';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from 'react-bootstrap';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import CenterMessagingTab from './Tabs/CenterMessagingTab';
import AccountMessagingTab from './Tabs/AccountMessagingTab';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { CommsCenterSetting, useGetChatAuthTokenQuery, useGetCommsAllCenterSettingsQuery } from 'generated/graphql';
import { showToast } from 'shared/components/Toast';
import MessagingOff from 'shared/components/Messenger/MessagingOff';
import moment from 'moment';

type MessagingTab = 'announcements' | 'accounts';

interface IRouteParams {
  activeTab: MessagingTab;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const Messaging: React.FC<IProps> = ({ ...props }) => {
  const { activeTab } = props.match.params;
  const [activeTabKey, setActiveTabKey] = useState<MessagingTab>(activeTab ?? 'announcements');
  const { t } = useTranslation(['comms', 'translation']);
  const history = useHistory();
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const businessMessaging = useSelector((state: RootState) => state.comms.usesComms);

  const [authAnnouncementCenters, setAuthAnnouncementCenters] = useState<CommsCenterSetting[] | undefined | null>([]);
  const [authAccountCenters, setAuthAccountCenters] = useState<CommsCenterSetting[] | undefined | null>([]);

  const [authToken, setAuthToken] = useState<string | null | undefined>();
  const [authTokenExpiry, setAuthExpiryToken] = useState<string | null | undefined>();
  const [publishKey, setPublishKey] = useState<string | null | undefined>();
  const [subscribeKey, setSubscribeKey] = useState<string | null | undefined>();

  const hasAnnouncementReadPermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const hasAnnouncementWritePermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const hasAnnouncementDeletePermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });

  const hasAccountReadPermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.AccountMessaging,
    level: RoleLevelType.Read,
  });

  const hasAccountWritePermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.AccountMessaging,
    level: RoleLevelType.Edit,
  });

  const hasAccountDeletePermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.AccountMessaging,
    level: RoleLevelType.Delete,
  });

  const hasCenterEditPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const hasFamilyAccountsReadPemrission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const {
    data: getChatAuthTokenData,
    loading: loadingAuthToken,
    error: getAuthTokenError,
    refetch: refetchAuthToken,
  } = useGetChatAuthTokenQuery({
    skip: businessId === '' || businessId === null,
    variables: {
      businessId: businessId ?? '',
    },
  });

  const {
    data: getAllCenterSettings,
    loading: loadingAllCenterSettings,
    error: allCenterSettingsError,
  } = useGetCommsAllCenterSettingsQuery({
    skip: businessId === '' || businessId === null,
    variables: {
      businessId: businessId ?? '',
      pageNumber: 1,
      pageSize: 2000, // set page size to high number to try and fetch all comms center settings
    },
  });

  useEffect(() => {
    if (allCenterSettingsError)
      showToast(`${t('comms:unable-to-get-messaging')} ${t('translation:spelling.center')} Settings`, 'error');
    setAuthAccountCenters(getAllCenterSettings?.getCommsAllCenterSettings?.data.filter((c) => c.usesAccountChannels));
    setAuthAnnouncementCenters(
      getAllCenterSettings?.getCommsAllCenterSettings?.data.filter((c) => c.usesBroadcastChannels)
    );
  }, [getAllCenterSettings, allCenterSettingsError]);

  useEffect(() => {
    if (getAuthTokenError)
      showToast(`${t('comms:unable-to-get-messaging')} ${t('translation:spelling.authorization')}`, 'error');
    setAuthToken(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.authToken : undefined);
    setAuthExpiryToken(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.expiryDateTimeOffset : undefined);
    setPublishKey(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.publishKey : undefined);
    setSubscribeKey(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.subscribeKey : undefined);
  }, [getAuthTokenError, getChatAuthTokenData]);

  useEffect(() => {
    // refresh token 5 minutes before it expires
    const expiryminus5mins = moment(authTokenExpiry).subtract(5, 'minutes');
    const diffInMilisec = expiryminus5mins.diff(moment()).valueOf();
    setTimeout(refetchAuthToken, diffInMilisec);
  }, [authTokenExpiry]);
  return (
    <>
      {businessMessaging && (
        <PageWrapper pageTitle={t('comms:page-title')} applyPadding={true}>
          {(!publishKey || !subscribeKey) &&
            !loadingAllCenterSettings &&
            !loadingAuthToken &&
            (hasAnnouncementReadPermission || hasAccountReadPermission) && (
              <MessagingOff
                hasCenterEditPermission={hasCenterEditPermission}
                title={
                  (authAnnouncementCenters && authAnnouncementCenters.length > 0) ||
                  (authAccountCenters && authAccountCenters.length > 0)
                    ? t('comms:messaging-off.title.other')
                    : t('comms:messaging-off.title.main', { channelType: 'All' })
                }
                reasonText={
                  (authAnnouncementCenters && authAnnouncementCenters.length > 0) ||
                  (authAccountCenters && authAccountCenters.length > 0)
                    ? t('comms:messaging-off.reason.no-channels')
                    : t('comms:messaging-off.reason.adjust-settings')
                }
              />
            )}
          {publishKey && subscribeKey && !loadingAllCenterSettings && !loadingAuthToken && (
            <Tabs
              id="messaging-tabs"
              activeKey={activeTabKey}
              onSelect={(tab) => {
                setActiveTabKey(tab as MessagingTab);
                history.push(`/engagement/messaging/${tab}`);
              }}
            >
              <Tab eventKey="announcements" title="Announcements">
                {!hasAnnouncementReadPermission && (
                  <MessagingOff
                    hasCenterEditPermission={hasCenterEditPermission}
                    title={t('comms:messaging-off.title.other', { channelType: 'Announcement' })}
                    reasonText={t('comms:messaging-off.reason.no-permission.main', { channelType: 'Announcement' })}
                  />
                )}
                {hasAnnouncementReadPermission && authAnnouncementCenters && authAnnouncementCenters.length <= 0 && (
                  <MessagingOff
                    hasCenterEditPermission={hasCenterEditPermission}
                    title={t('comms:messaging-off.title.main', { channelType: 'Announcement' })}
                    reasonText={t('comms:messaging-off.reason.adjust-settings')}
                  />
                )}
                {hasAnnouncementReadPermission && authAnnouncementCenters && authAnnouncementCenters?.length > 0 && (
                  <CenterMessagingTab
                    hasWritePermission={hasAnnouncementWritePermission}
                    hasDeletePermission={hasAnnouncementDeletePermission}
                    authToken={authToken ?? ''}
                    authChannels={authAnnouncementCenters?.map((c) => c.centerId ?? '')}
                    publishKey={publishKey ?? ''}
                    subscribeKey={subscribeKey ?? ''}
                  />
                )}
              </Tab>
              <Tab eventKey="account" title="Accounts">
                {(!hasFamilyAccountsReadPemrission || !hasAccountReadPermission) && (
                  <MessagingOff
                    hasCenterEditPermission={hasCenterEditPermission}
                    title={t('comms:messaging-off.title.other', { channelType: 'Account' })}
                    reasonText={
                      !hasFamilyAccountsReadPemrission
                        ? t('comms:messaging-off.reason.no-permission.account')
                        : t('comms:messaging-off.reason.no-permission.main')
                    }
                  />
                )}
                {hasFamilyAccountsReadPemrission && hasAccountReadPermission && (
                  <>
                    {(!authAccountCenters || (authAccountCenters && authAccountCenters.length <= 0)) && (
                      <MessagingOff
                        hasCenterEditPermission={hasCenterEditPermission}
                        title={t('comms:messaging-off.title.main', { channelType: 'Account' })}
                        reasonText={t('comms:messaging-off.reason.adjust-settings')}
                      />
                    )}
                    {authAccountCenters && authAccountCenters?.length > 0 && (
                      <AccountMessagingTab
                        hasWritePermission={hasAccountWritePermission}
                        hasDeletePermission={hasAccountDeletePermission}
                        authToken={authToken ?? ''}
                        authChannels={authAccountCenters?.map((c) => c.centerId ?? '')}
                        publishKey={publishKey ?? ''}
                        subscribeKey={subscribeKey ?? ''}
                      />
                    )}
                  </>
                )}
              </Tab>
            </Tabs>
          )}
        </PageWrapper>
      )}
    </>
  );
};

export default Messaging;
