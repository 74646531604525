import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';

interface IGetSessionCountByTimeFrameData {
  getSessionCountByTimeFrame: number;
}

interface IGetSessionCountByTimeFrameVariables {
  input: {
    classIds?: string[] | null;
    centerId: string;
    startDate: string;
    endDate: string;
  };
}

export const GET_SESSION_COUNT = gql`
  query ($input: CasualBookingCountInput!) {
    getSessionCountByTimeFrame(input: $input)
  }
`;

export const useGetSessionCountByTimeFrame = (
  options?: QueryHookOptions<IGetSessionCountByTimeFrameData, IGetSessionCountByTimeFrameVariables>
) =>
  useQuery<IGetSessionCountByTimeFrameData, IGetSessionCountByTimeFrameVariables>(GET_SESSION_COUNT, {
    fetchPolicy: 'cache-first',
    ...options,
  });
