import React from 'react';
import { Col, Row } from 'shared/components/Layout';
import { isTimeRangeInvalid } from 'shared/util/timeUtils';
import TimeInput from '../../../../../../../../shared/components/TimePicker/TimeInput';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  contract: ICreateContractInput;
  updateContract: React.Dispatch<React.SetStateAction<ICreateContractInput>>;
  className?: string;
}

const CasualTimeSlotsInputs: React.FC<IProps> = ({ contract, updateContract, className }) => {
  return (
    <div className={className}>
      <Row>
        <Col xs={6}>
          <div className="d-flex flex-row">
            <Form.Label>Drop Off</Form.Label>
          </div>
          <TimeInput
            isAM={true}
            value={contract.timeslots[0].dropOffTime ?? null}
            onChange={(time) =>
              updateContract((prev) => ({
                ...prev,
                timeslots: prev.timeslots.map((ts) => ({ ...ts, dropOffTime: time })),
              }))
            }
          />
        </Col>
        <Col xs={6}>
          <div className="d-flex flex-row">
            <Form.Label>Pick Up</Form.Label>
          </div>
          <TimeInput
            isAM={false}
            value={contract.timeslots[0].pickUpTime ?? null}
            onChange={(time) =>
              updateContract((prev) => ({
                ...prev,
                timeslots: prev.timeslots.map((ts) => ({ ...ts, pickUpTime: time })),
              }))
            }
          />
        </Col>
      </Row>
      {isTimeRangeInvalid(contract.timeslots[0]?.dropOffTime, contract.timeslots[0]?.pickUpTime) && (
        <div className="text-danger small my-2">The pickup time must be after the drop off time.</div>
      )}
    </div>
  );
};

export default CasualTimeSlotsInputs;
