import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';
import { GET_CENTER, GET_CENTER_SCHEDULE_FOR_WEEK, scheduleFields } from './queries';
import { GET_ATTENDANCE } from '../../TimeLogs/graphql/queries';
import deleteCacheForQuery from 'shared/util/deleteCacheForQuery';

interface ICreateCenterLocationVariables {
  input: {
    name: string;
    centerId: string;
  };
}

interface ICreateCenterLocationData {
  createCenterLocation: ILocation;
}

interface IRemoveCenterLocationVariables {
  input: {
    id: string;
    centerId: string;
  };
}

interface IRemoveCenterLocationData {
  removeCenterLocation: ILocation;
}

interface ICreateScheduleVariables {
  input: {
    centerId: string;
    start: string;
    end: string;
  };
}

interface ICreateScheduleData {
  createSchedule: ISchedule;
}

interface ICreateShiftVariables {
  input: {
    centerId: string;
    scheduleId: string;
    personId?: string | null;
    classId?: string | null;
    locationId?: string | null;
    positionId?: string | null;
    breakMinutes: number;
    paidBreak: boolean;
    note?: string | null;
    dates: IDateTimeRange[];
  };
}

interface ICreateShiftData {
  createShift: IShift[];
}

interface IUpdateShiftVariables {
  input: {
    id: string;
    centerId: string;
    personId?: string | null;
    positionId?: string | null;
    classId?: string | null;
    locationId?: string | null;
    startTime: string;
    endTime: string;
    breakMinutes: number;
    paidBreak: boolean;
    note?: string | null;
  };
}

interface IUpdateShiftData {
  updateShift: IShift;
}

interface IDeleteShiftsVariables {
  input: {
    ids: string[];
    centerId: string;
  };
}

interface IDeleteShiftsData {
  deleteShifts: string[];
}

interface ICopyScheduleVariables {
  input: {
    id: string;
    centerId: string;
    weekToCopyTo: string;
  };
}

interface ICopyScheduleData {
  copySchedule: ISchedule;
}

interface ICopyShiftVariables {
  input: {
    id: string;
    centerId: string;
    dates: IDateTimeRange[];
    locationId?: string;
    classId?: string;
  };
}

interface ICopyShiftData {
  copyShift: IShift[];
}

interface IPublishScheduleVariables {
  id: string;
  centerId: string;
}

interface IPublishScheduleData {
  publishSchedule: ISchedule;
}

interface IUnpublishScheduleVariables {
  id: string;
  centerId: string;
}

interface IUnpublishScheduleData {
  unpublishSchedule: ISchedule;
}

export const CREATE_CENTER_LOCATION = gql`
  mutation ($input: CreateLocationInput!) {
    createCenterLocation(input: $input) {
      id
      name
      createdAt
      archivedAt
    }
  }
`;

export const REMOVE_CENTER_LOCATION = gql`
  mutation ($input: RemoveLocationInput!) {
    removeCenterLocation(input: $input) {
      id
      name
      createdAt
      archivedAt
    }
  }
`;

export const CREATE_NEW_SCHEDULE = gql`
  mutation ($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      id
      centerId
      startTime
      endTime
      publishStatus
    }
  }
`;

export const CREATE_NEW_SHIFT = gql`
  mutation ($input: CreateShiftInput!) {
    createShift(input: $input) {
      id
      scheduleId
      centerId
      classId
      locationId
      personId
      positionId
      startTime
      endTime
      breakMinutes
      paidBreak
      note
      person {
        id
        nickname
        firstname
        lastname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
          colorCode
        }
      }
      position {
        id
        title
        positionName
      }
      location {
        id
        name
      }
    }
  }
`;

export const UPDATE_SHIFT = gql`
  mutation ($input: UpdateShiftInput!) {
    updateShift(input: $input) {
      id
      scheduleId
      centerId
      classId
      locationId
      personId
      positionId
      startTime
      endTime
      breakMinutes
      paidBreak
      note
      person {
        id
        nickname
        firstname
        lastname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
          colorCode
        }
      }
      position {
        id
        title
        positionName
      }
      location {
        id
        name
      }
    }
  }
`;

export const DELETE_SHIFTS = gql`
  mutation ($input: DeleteShiftsInput!) {
    deleteShifts(input: $input)
  }
`;

export const COPY_SCHEDULE = gql`
  mutation ($input: CopyScheduleInput!) {
    copySchedule(input: $input) {
      id
      centerId
      startTime
      endTime
      publishStatus
      shifts {
        id
        scheduleId
        centerId
        classId
        locationId
        personId
        positionId
        startTime
        endTime
        breakMinutes
        paidBreak
        note
        person {
          id
          nickname
          firstname
          lastname
          avatar {
            url
          }
          classAssignments {
            id
            startsAt
            endsAt
            colorCode
          }
        }
        position {
          id
          title
          positionName
        }
        class {
          id
          name
          colorCode
        }
        location {
          id
          name
        }
      }
    }
  }
`;

export const COPY_SHIFT = gql`
  mutation ($input: CopyShiftInput!) {
    copyShift(input: $input) {
      id
      scheduleId
      centerId
      classId
      locationId
      personId
      positionId
      startTime
      endTime
      breakMinutes
      paidBreak
      note
      person {
        id
        nickname
        firstname
        lastname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
          colorCode
        }
      }
      position {
        id
        title
        positionName
      }
      class {
        id
        name
        colorCode
      }
      location {
        id
        name
      }
    }
  }
`;

export const PUBLISH_SCHEDULE = gql`
  mutation($id: ID!, $centerId: ID!) {
    publishSchedule(id: $id, centerId: $centerId) {
      ${scheduleFields}
    }
  }
`;

export const UNPUBLISH_SCHEDULE = gql`
  mutation ($id: ID!, $centerId: ID!) {
    unpublishSchedule(id: $id, centerId: $centerId) {
      id
      centerId
      startTime
      publishStatus
    }
  }
`;

export const useCreateCenterLocation = (
  centerId: string,
  options?: MutationHookOptions<ICreateCenterLocationData, ICreateCenterLocationVariables> | undefined
) =>
  useMutation<ICreateCenterLocationData, ICreateCenterLocationVariables>(CREATE_CENTER_LOCATION, {
    ...options,
    update: (cache, { data }) => {
      // have to update the locations for the center in cache as well so they don't disappear when changing dates
      if (data?.createCenterLocation) {
        const cached = cache.readQuery({
          query: GET_CENTER,
          variables: {
            id: centerId,
          },
        });

        // @ts-ignore
        if (cached.getCenter) {
          cache.writeQuery({
            query: GET_CENTER,
            variables: {
              id: centerId,
            },
            data: {
              getCenter: {
                // @ts-ignore
                ...cached.getCenter,
                // @ts-ignore
                locations: [...(cached.getCenter.locations ?? []), data.createCenterLocation],
              },
            },
          });
        }
      }
    },
  });

export const useRemoveCenterLocation = (
  centerId: string,
  options?: MutationHookOptions<IRemoveCenterLocationData, IRemoveCenterLocationVariables> | undefined
) =>
  useMutation<IRemoveCenterLocationData, IRemoveCenterLocationVariables>(REMOVE_CENTER_LOCATION, {
    ...options,
    update: (cache, { data }) => {
      // have to update the locations for the center in cache as well so they don't disappear when changing dates
      if (data?.removeCenterLocation) {
        const cached = cache.readQuery({
          query: GET_CENTER,
          variables: {
            id: centerId,
          },
        });

        // @ts-ignore
        if (cached.getCenter) {
          // @ts-ignore
          const filtered = (cached.getCenter.locations ?? []).filter(
            (location: ILocation) => location.id !== data.removeCenterLocation.id
          );

          cache.writeQuery({
            query: GET_CENTER,
            variables: {
              id: centerId,
            },
            data: {
              getCenter: {
                // @ts-ignore
                ...cached.getCenter,
                locations: filtered,
              },
            },
          });
        }
      }
    },
  });

export const useCreateNewSchedule = (
  options?: MutationHookOptions<ICreateScheduleData, ICreateScheduleVariables> | undefined
) => useMutation<ICreateScheduleData, ICreateScheduleVariables>(CREATE_NEW_SCHEDULE, options);

export const useCreateNewShift = (options?: MutationHookOptions<ICreateShiftData, ICreateShiftVariables> | undefined) =>
  useMutation<ICreateShiftData, ICreateShiftVariables>(CREATE_NEW_SHIFT, options);

export const useUpdateShift = (options?: MutationHookOptions<IUpdateShiftData, IUpdateShiftVariables> | undefined) =>
  useMutation<IUpdateShiftData, IUpdateShiftVariables>(UPDATE_SHIFT, options);

export const useDeleteShifts = (options?: MutationHookOptions<IDeleteShiftsData, IDeleteShiftsVariables> | undefined) =>
  useMutation<IDeleteShiftsData, IDeleteShiftsVariables>(DELETE_SHIFTS, options);

export const useCopySchedule = (options?: MutationHookOptions<ICopyScheduleData, ICopyScheduleVariables> | undefined) =>
  useMutation<ICopyScheduleData, ICopyScheduleVariables>(COPY_SCHEDULE, {
    ...options,
    update: (cache, { data }) => {
      // write the new information to the cache so it's readily availabile to the user when they request the schedule for this week
      if (data?.copySchedule) {
        const centerId = data.copySchedule.centerId;
        const date = data.copySchedule.startTime;

        cache.writeQuery({
          query: GET_CENTER_SCHEDULE_FOR_WEEK,
          variables: {
            centerId,
            date,
          },
          data: {
            getCenterScheduleForWeek: data.copySchedule,
          },
        });
      }
    },
  });

export const useCopyShift = (options?: MutationHookOptions<ICopyShiftData, ICopyShiftVariables> | undefined) =>
  useMutation<ICopyShiftData, ICopyShiftVariables>(COPY_SHIFT, options);

export const usePublishSchedule = (
  options?: MutationHookOptions<IPublishScheduleData, IPublishScheduleVariables> | undefined
) =>
  useMutation<IPublishScheduleData, IPublishScheduleVariables>(PUBLISH_SCHEDULE, {
    ...options,
    update: (cache, { data }) => {
      if (data?.publishSchedule) {
        const centerId = data.publishSchedule.centerId;
        const date = data.publishSchedule.startTime;

        cache.writeQuery({
          query: GET_CENTER_SCHEDULE_FOR_WEEK,
          variables: {
            centerId,
            date,
          },
          data: {
            getCenterScheduleForWeek: data.publishSchedule,
          },
        });
      }
      // @ts-ignore
      deleteCacheForQuery('getAttendance')(cache);
    },
  });

export const useUnpublishSchedule = (
  options?: MutationHookOptions<IUnpublishScheduleData, IUnpublishScheduleVariables> | undefined
) => useMutation<IUnpublishScheduleData, IUnpublishScheduleVariables>(UNPUBLISH_SCHEDULE, options);
