import React from 'react';
import { Row, Col } from 'shared/components/Layout';
import TimePicker from 'shared/components/TimePicker';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  start: moment.Moment | null;
  end: moment.Moment | null;
  onChange: (start: moment.Moment | null, end: moment.Moment | null) => void;
  className?: string;
  timezone?: string;
}

const TimeRangePicker: React.FC<IProps> = ({ start, end, onChange, className, timezone, ...props }) => {
  return (
    <div className="d-flex flex-column">
      <Row noGutters justify="between" className={`flex-noWrap time-range-container ${className}`}>
        <Col>
          <TimePicker value={start} onChange={(time) => onChange(time, end)} timezone={timezone} />
        </Col>
        <div className="mx-2">
          <FontAwesomeIcon icon={faChevronRight} size="sm" />
        </div>
        <Col>
          <TimePicker value={end} onChange={(time) => onChange(start, time)} timezone={timezone} />
        </Col>
      </Row>
      {start &&
        end &&
        moment()
          .hours(start.hours())
          .minutes(start.minutes())
          .isAfter(moment().hours(end.hours()).minutes(end.minutes())) && (
          <Row className="mt-2" noGutters>
            <small className="text-danger">Invalid timeframe.</small>
          </Row>
        )}
    </div>
  );
};

export default TimeRangePicker;
