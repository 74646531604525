import moment from 'moment';

export function dateToDateTime(date: string, startOrEnd: 'startOf' | 'endOf'): Date {
  let momentDate = moment(date);
  if (startOrEnd === 'startOf') {
    momentDate = momentDate.startOf('day');
  } else {
    momentDate = momentDate.endOf('day');
  }
  return momentDate.toDate();
}
