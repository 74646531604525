import React, { useState, useCallback } from 'react';
import LoggedOutPage from 'shared/components/SignUpPage';
import EmailLogin from './EmailLogin';
import SSOLogin from './SSOLogin';
import signUp from './images/Sign-Up.svg';
import { Row, Col } from 'shared/components/Layout';
import Button from 'shared/components/Buttons';
import useWindowSize from 'shared/hooks/useWindowSize';
import { Link } from 'react-router-dom';

interface IProps {
  isAuthed: boolean;
}

const Login: React.FC<IProps> = ({ isAuthed, ...props }) => {
  const isUserSSO = localStorage.getItem('isUserSSO') === 'true';
  const [email, updateEmail] = useState('');
  const [showSSO, setShowSSO] = useState(isUserSSO);
  const toggleSSO = useCallback(() => setShowSSO(!showSSO), [showSSO]);
  const windowSize = useWindowSize();
  const isMobileScreenSize = windowSize.innerWidth <= 768;

  return (
    <Row noGutters align="stretch" className="h-100">
      <Col>
        <LoggedOutPage isAuthed={isAuthed}>
          {showSSO ? (
            <SSOLogin toggleSSO={toggleSSO} email={email} updateEmail={updateEmail} />
          ) : (
            <EmailLogin toggleSSO={toggleSSO} email={email} updateEmail={updateEmail} />
          )}
        </LoggedOutPage>
      </Col>
      <Col md={5} lg={4} className="bg-secondary flex-grow-0 text-center p-8">
        <div style={{ marginTop: '88px' }}></div>
        {!isMobileScreenSize && <img src={signUp} alt="business" className="w-100 my-8" />}
      </Col>
    </Row>
  );
};

export default Login;
