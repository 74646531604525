export const providerContactFields = `
  dateOfEvent
  phoneNumber
  mobileNumber
  email
`;

export const providerNameFields = `
  dateOfEvent
  name
  type
`;
export const providerAddressFields = `
  type
  streetLine1
  streetLine2
  suburb
  state
  postcode
  startDate
`;
export const providerFinancialDetailsFields = `
  dateOfEvent
  bsb
  accountNumber
  accountName
  applyToAllServices
`;
