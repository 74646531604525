import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import PhoneNumberAndTypeInput from 'shared/components/PhoneNumberAndTypeInput';
import errors from 'shared/constants/errorMessages';
import Form from 'react-bootstrap/Form';
import TextInput from 'shared/components/TextInput';
import { INewContactWithPermissions } from './AddContactModalForm';
import { isRegion } from 'shared/util/region';
import { Col, Row } from 'shared/components/Layout';
import AddContactTypeOptions from './AddContactTypeOptions';
import Checkbox from 'shared/components/Checkbox';
import { Collapse } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { useQuery } from 'shared/apis/core';
import { ISearchContactsData, ISearchContactsVariables } from '../../../../../Contacts/graphql/queries';
import { gql } from '@apollo/client';
import debouncePromise from 'debounce-promise';
import Avatar from 'shared/components/Avatar';
import {
  getFullName,
  getInitials,
  isBlank,
  isValidPhoneNumber,
  parsePhoneNumberWithRegion,
  stringToHsl,
} from 'shared/util/string';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmailValid } from 'shared/util/email';

interface IProps {
  contact: INewContactWithPermissions;
  setContact: Dispatch<SetStateAction<INewContactWithPermissions>>;
  account: IAccount;
  sendInvite: boolean;
  sendInviteToggle: () => void;
}

const AddContactDemographicForm: React.FC<IProps> = ({
  contact,
  setContact,
  account,
  sendInvite,
  sendInviteToggle,
  ...props
}) => {
  const { k2SendAppInvite } = useFlags();
  const isAuRegion = isRegion('AU');
  const [selectedContact, setSelectedContact] = useState<IContact | null>(null);
  const [showAddExistingContactInput, setShowAddExistingContactInput] = useState<{ show: boolean; term: string }>({
    show: false,
    term: '',
  });

  const { loading: searchContactsLoading, refetch: searchContactsFn } = useQuery<
    ISearchContactsData,
    ISearchContactsVariables
  >(
    gql`
      query ($input: SearchInput!) {
        searchContacts(input: $input) {
          id
          firstname
          lastname
          email
          nickname
          avatar {
            url
          }
          primaryPhoneNumber {
            number
            type
          }
        }
      }
    `,
    { skip: true }
  );

  const debounceContactSearch = useCallback(
    debouncePromise((term: string): Promise<IContact[]> => {
      let search: ISearchExpression;
      if (!isBlank(term)) {
        if (/\s/.test(term)) {
          const [first, last] = term.split(' ');
          search = {
            all: [
              { term: { field: 'firstName', value: first.toLowerCase(), predicate: 'CONTAINS' } },
              { term: { field: 'lastName', value: last.toLowerCase(), predicate: 'CONTAINS' } },
            ],
          };
        } else {
          search = {
            any: [
              { term: { field: 'firstName', value: term.toLowerCase(), predicate: 'CONTAINS' } },
              { term: { field: 'lastName', value: term.toLowerCase(), predicate: 'CONTAINS' } },
            ],
          };
        }
        return new Promise((resolve) =>
          searchContactsFn({
            input: {
              from: 0,
              size: 25,
              filter: {
                all: [search],
              },
              sort: [{ field: 'lastName.keyword', direction: 'ASCENDING' }],
            },
          })
            .then((response) => resolve(response.data?.searchContacts ?? []))
            .catch((err) => resolve([]))
        );
      }

      return Promise.resolve([]);
    }, 500),
    [searchContactsFn]
  );

  const handleContactSearchInput = useCallback(
    (term: string) => {
      setShowAddExistingContactInput((prev) => ({ ...prev, term }));

      return debounceContactSearch(term);
    },
    [debounceContactSearch]
  );

  useEffect(() => {
    setContact((prev) => ({
      ...prev,
      email: selectedContact && isEmailValid(selectedContact?.email) ? selectedContact?.email : contact.email,
      firstname: selectedContact?.firstname ?? contact.firstname,
      lastname: selectedContact?.lastname ?? contact.lastname,
      primaryPhoneNumber: selectedContact?.primaryPhoneNumber ?? contact.primaryPhoneNumber,
      existingContactId: selectedContact?.id ?? null,
    }));
  }, [setContact, selectedContact]);

  return (
    <Form>
      <AddContactTypeOptions contact={contact} setContact={setContact} account={account} />
      <Row className="mb-4">
        <Col>
          <Checkbox
            label="Add Existing Contact?"
            value={showAddExistingContactInput.show}
            onChange={(checked) => {
              setShowAddExistingContactInput({ show: checked, term: '' });
              setSelectedContact(null);
              setContact((prev) => ({ ...prev, existingContact: checked }));
            }}
          />
        </Col>
      </Row>
      <Collapse in={showAddExistingContactInput.show}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Contact</Form.Label>
              <AsyncSelect
                isLoading={searchContactsLoading}
                value={selectedContact}
                defaultOptions={[]}
                placeholder="Search"
                components={{ IndicatorSeparator: null }}
                className="react-select-container w-100"
                classNamePrefix="react-select"
                loadOptions={(inputValue) => handleContactSearchInput(inputValue)}
                loadingMessage={() => 'Searching contacts...'}
                formatOptionLabel={(option: IContact) => (
                  <div className="d-flex flex-row align-items-center">
                    <Avatar
                      color={stringToHsl(option.id)}
                      size="sm"
                      image={option.avatar?.url ?? ''}
                      initials={getInitials(option)}
                    />
                    <div className="d-flex flex-column ml-2 text-truncate sm">
                      <div>{getFullName(option)}</div>
                      <div>
                        {option.primaryPhoneNumber && option.primaryPhoneNumber.number && (
                          <small>
                            Phone: {parsePhoneNumberWithRegion(option.primaryPhoneNumber.number).formatNational()}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                onChange={(option) => setSelectedContact(option as IContact)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Collapse>
      <Row>
        <Col>
          <TextInput
            required
            disabled={showAddExistingContactInput.show}
            label="First Name"
            value={selectedContact ? selectedContact.firstname : contact.firstname}
            onChange={(value) => setContact((prev) => ({ ...prev, firstname: value }))}
          />
        </Col>
        <Col>
          <TextInput
            disabled={showAddExistingContactInput.show}
            required={!isAuRegion}
            label="Last Name"
            value={selectedContact ? selectedContact.lastname : contact.lastname}
            onChange={(value) => setContact((prev) => ({ ...prev, lastname: value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            disabled={showAddExistingContactInput.show && !!selectedContact && isEmailValid(selectedContact.email)}
            required={contact.isPrimary}
            label="Email"
            value={selectedContact && isEmailValid(selectedContact.email) ? selectedContact.email : contact.email}
            onChange={(value) => setContact((prev) => ({ ...prev, email: value }))}
            type="email"
            isInvalid={
              selectedContact
                ? !isEmailValid(selectedContact.email) && selectedContact.isPrimary
                : !isEmailValid(contact.email) && contact.isPrimary
            }
          />
        </Col>
      </Row>
      {k2SendAppInvite && (
        <Row className="mb-4">
          <Col>
            <Checkbox label="Send confirmation email" value={sendInvite} onChange={() => sendInviteToggle()} />
          </Col>
        </Row>
      )}
      <Row className="mb-4">
        <Col>
          <PhoneNumberAndTypeInput
            disabled={showAddExistingContactInput.show}
            extraDiv={false}
            required={true}
            label="Primary Phone Number"
            phoneNumber={
              (selectedContact
                ? selectedContact.primaryPhoneNumber
                : contact.primaryPhoneNumber ?? {
                    number: '',
                    type: undefined,
                  }) ?? {
                number: '',
                type: undefined,
              }
            }
            updatePhoneNumber={(primaryPhoneNumber) => setContact((prev) => ({ ...prev, primaryPhoneNumber }))}
            errorText={errors.invalidPhoneNumber}
            isInvalid={Boolean(
              contact.primaryPhoneNumber?.number && !isValidPhoneNumber(contact.primaryPhoneNumber?.number)
            )}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default AddContactDemographicForm;
