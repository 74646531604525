import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RBCard from 'react-bootstrap/Card';
import Button from 'shared/components/Buttons';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { useGetRolesForBusiness } from 'shared/hooks/useGetRolesForBusiness';
import { RootState } from 'store/reducers';
import { roleFields } from 'gql/role/fields';
import _, { orderBy } from 'lodash';
import { showToast } from 'shared/components/Toast';
import { useUpdateRole } from 'gql/role/mutations';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import * as Mui from '@mui/material';
import { useGetBusinessFeatureOptionsById } from '../../../../gql/businessFeature/queries';
import { useUpsertBusinessFeature } from '../../../../gql/businessFeature/mutations';
import BusinessSelect from 'shared/components/BusinessSelect';
import { Col, Row } from 'react-bootstrap';
import Switch from 'shared/components/Switch';
import useHasRoleAreaLevel from '../../../../shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from '../../../../shared/constants/enums/permissionsEnums';

interface IProps {}

const AdpTab: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['translation']);
  const user = useSelector((state: RootState) => state.user);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [updateAdpRoleLoading, setAdpUpdateRoleLoading] = useState(false);
  const [updateRoleFn] = useUpdateRole();
  const [selectedEntityId, setSelectedEntityId] = useState<string | undefined>();
  const entityId = user?.entityId || selectedEntityId;
  const [workforceEnabled, setWorkforceEnabled] = React.useState<boolean>(false);
  const [vantageEnabled, setVantageEnabled] = React.useState<boolean>(false);
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const businessSubFeatures =
    Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.subFeatures ?? undefined;
  const [isDisable, setIsDisable] = React.useState<boolean>(!entityId);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(true);
  const [isTimeAndAttendance, setIsTimeAndAttendance] = useState(false);

  const hasBusinessEditPermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const { data: getRolesForBusinessData, loading, refetch } = useGetRolesForBusiness(entityId!, roleFields);

  const { data, refetch: featureRefetch } = useGetBusinessFeatureOptionsById({
    skip: _.isEmpty(entityId),
    variables: {
      id: entityId!,
      type: 'All',
    },
  });

  const [upsertBusinessFeature] = useUpsertBusinessFeature({
    onCompleted: (getBusinessFeatureData) => {
      showToast(
        `Integration for ${
          getBusinessFeatureData.upsertBusinessFeature.type === 'AdpWorkforce'
            ? `Workforce Now ${getBusinessFeatureData.upsertBusinessFeature.enabled ? 'enabled' : 'disabled'}`
            : `AdpVantage ${
                getBusinessFeatureData.upsertBusinessFeature.enabled ? 'enabled' : 'disabled'
              }  for business`
        }`,
        'success'
      );
      setIsDisable(false);
      refetch().then((r) => setRoles(orderBy(r.data.getRolesForBusiness, (role) => role.hierarchyLevel, 'asc')));
    },
    onError: () => {
      setIsDisable(false);
      showToast('Error updating Integration Settings', 'error');
    },
  });

  const [disableBusinessFeature] = useUpsertBusinessFeature({
    onCompleted: (getBusinessFeatureData) => {
      showToast(
        `Integration for ${workforceEnabled ? 'Vantage disabled' : 'Workforce Now disabled'} for business`,
        'success'
      );
      setIsDisable(false);
    },
    onError: () => {
      setIsDisable(false);
      showToast('Error updating Integration Settings', 'error');
    },
  });

  const roleVariables = (role: IRole) => {
    return {
      variables: {
        input: {
          roleId: role.id,
          name: role.name,
          hierarchyLevel: role.hierarchyLevel,
          areaLevels: role.areaLevels.map((al) => ({
            roleId: al.roleId,
            area: al.area,
            permission: al.permission,
            level: al.level,
          })),
          scheduleVisibility: role.scheduleVisibility,
          isIntegrationRole: role.isIntegrationRole,
        },
      },
    };
  };

  const onSave = async () => {
    setAdpUpdateRoleLoading(true);
    try {
      await Promise.all(
        roles.map(async (role: IRole) => {
          return await updateRoleFn(roleVariables(role));
        })
      );
      await upsertBusinessFeature({
        variables: {
          input: {
            businessId: entityId!,
            type: workforceEnabled ? 'AdpWorkforce' : 'AdpVantage',
            enabled: true,
            subFeatures: [
              {
                type: 'TimeAndAttendance',
                enabled: isTimeAndAttendance,
              },
            ],
          },
        },
      });
      showToast('Roles updated successfully.', 'success');
    } catch (error) {
      showToast('There was an error trying to update roles. Please try again later.', 'error');
    }
    setIsButtonsDisabled(true);
    setAdpUpdateRoleLoading(false);
  };

  const revertChanges = () => {
    setIsButtonsDisabled(true);
    setRoles([]);
    setIsTimeAndAttendance(!!data?.getBusinessFeatureByBusinessId[0].subFeatures[0].enabled);
  };

  const handleChange = (role: IRole, checked: boolean) => {
    const updatedRole = { ...role, isIntegrationRole: checked };
    const newRoles = [...roles].map((role) =>
      role.id === updatedRole.id
        ? { ...updatedRole, isIntegrationRole: checked }
        : { ...role, isIntegrationRole: false }
    );
    setIsButtonsDisabled(false);
    setRoles(newRoles);
  };

  const onToggleWorkforce = async (value: boolean) => {
    setIsDisable(true);
    setWorkforceEnabled(value);
    setVantageEnabled(false);
    setIsTimeAndAttendance(value);
    await disableBusinessFeature({
      variables: {
        input: {
          businessId: entityId!,
          type: 'AdpVantage',
          enabled: false,
          subFeatures: [
            {
              type: 'TimeAndAttendance',
              enabled: false,
            },
          ],
        },
      },
    });
    await upsertBusinessFeature({
      variables: {
        input: {
          businessId: entityId!,
          type: 'AdpWorkforce',
          enabled: value,
          subFeatures: [
            {
              type: 'TimeAndAttendance',
              enabled: true,
            },
          ],
        },
      },
    });
  };

  const onToggleVantage = async (value: boolean) => {
    setIsDisable(true);
    setWorkforceEnabled(false);
    setVantageEnabled(value);
    await disableBusinessFeature({
      variables: {
        input: {
          businessId: entityId!,
          type: 'AdpWorkforce',
          enabled: false,
          subFeatures: [
            {
              type: 'TimeAndAttendance',
              enabled: false,
            },
          ],
        },
      },
    });
    await upsertBusinessFeature({
      variables: {
        input: {
          businessId: entityId!,
          type: 'AdpVantage',
          enabled: value,
          subFeatures: [
            {
              type: 'TimeAndAttendance',
              enabled: false,
            },
          ],
        },
      },
    });
  };

  const handleTimeChange = (value: boolean) => {
    setIsButtonsDisabled(false);
    setIsTimeAndAttendance(value);
  };

  useEffect(() => {
    // only set state from query if the local state is empty and the query is not loading (to prevent reading cached data)
    if (getRolesForBusinessData?.getRolesForBusiness && roles.length === 0 && !loading) {
      setRoles(orderBy(getRolesForBusinessData.getRolesForBusiness, (role) => role.hierarchyLevel, 'asc'));
    }
  }, [roles, loading, getRolesForBusinessData, data]);

  useEffect(() => {
    setWorkforceEnabled(Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled ?? false);
    setVantageEnabled(Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ?? false);
    setIsTimeAndAttendance(businessSubFeatures?.find((x) => x.type === 'TimeAndAttendance')?.enabled ?? false);
  }, [businessFeatures]);

  return (
    <PageWrapperBody>
      <Col>
        {user?.isInternal && (
          <BusinessSelect
            selectedBusinessId={selectedEntityId}
            onSelectBusiness={(entity) => setSelectedEntityId(entity.id)}
            className="p-4 max-width-400 m-auto"
          />
        )}
      </Col>
      <RBCard>
        <RBCard.Header className="pt-5" title="ADP Workforce Now Integration">
          <Row>
            <Col className="pt-2">ADP Workforce Now Integration</Col>
            <Col sm="12" md="6">
              <Switch
                labelSide="top"
                className="mb-2 float-right"
                aria-describedby="enable or disable the ADP features"
                onChange={(e) => onToggleWorkforce(!workforceEnabled)}
                value={workforceEnabled}
                disabled={!user?.isInternal}
              />
            </Col>
          </Row>
        </RBCard.Header>
        {entityId && workforceEnabled && (
          <RBCard.Body className="m-0">
            <Col className="pb-2">Time and Attendance </Col>
            <Mui.RadioGroup style={{ width: '100%' }}>
              <Mui.FormControlLabel
                style={{ margin: 0, width: '100%' }}
                className="d-flex"
                defaultChecked={data?.getBusinessFeatureByBusinessId[0].subFeatures[0].enabled}
                checked={isTimeAndAttendance}
                control={<Mui.Radio size="small" />}
                label="Enabled"
                onChange={() => handleTimeChange(true)}
              />
              <Mui.FormControlLabel
                style={{ margin: 0, width: '100%', paddingBottom: '10px' }}
                className="d-flex"
                defaultChecked={!data?.getBusinessFeatureByBusinessId[0].subFeatures[0].enabled}
                checked={!isTimeAndAttendance}
                control={<Mui.Radio size="small" />}
                label="Disabled"
                onChange={() => handleTimeChange(false)}
              />
            </Mui.RadioGroup>
            <Col>Role to automatically import user into</Col>
            <Mui.RadioGroup className="pt-2 pb-4" value={roles.values()}>
              {roles.map((role: IRole, idx: number) => (
                <tr key={`role-${idx}`}>
                  <td>
                    <Mui.FormControlLabel
                      className="d-flex"
                      key={role.name}
                      value={role.isIntegrationRole}
                      checked={role.isIntegrationRole}
                      style={{ margin: 0, width: '100%' }}
                      control={<Mui.Radio size="small" />}
                      label={role.name}
                      onChange={(event, checked) => {
                        handleChange(role, checked);
                      }}
                      disabled={role.name === 'Account Owner' || !hasBusinessEditPermission}
                    />
                  </td>
                </tr>
              ))}
            </Mui.RadioGroup>
          </RBCard.Body>
        )}
        {workforceEnabled && (
          <RBCard.Body className="p-4 d-flex justify-content-end border-top">
            <Button
              className="mr-4"
              variant="light"
              onClick={revertChanges}
              disabled={
                isButtonsDisabled ||
                (updateAdpRoleLoading && loading) ||
                !workforceEnabled ||
                !hasBusinessEditPermission
              }
            >
              {capitalize(t('translation:spelling.cancel'))}
            </Button>
            <Button
              disabled={
                isButtonsDisabled ||
                (updateAdpRoleLoading && loading) ||
                !workforceEnabled ||
                !hasBusinessEditPermission
              }
              loading={updateAdpRoleLoading}
              onClick={() => onSave()}
            >
              {capitalize(t('translation:spelling.save'))}
            </Button>
          </RBCard.Body>
        )}
      </RBCard>
      <RBCard>
        <RBCard.Header className="pt-5" title="ADP Vantage Integration">
          <Row>
            <Col className="pt-2">ADP Vantage Integration</Col>
            <Col sm="12" md="6">
              <Switch
                labelSide="top"
                className="mb-2 float-right"
                aria-describedby="enable or disable the ADP features"
                onChange={(e) => onToggleVantage(!vantageEnabled)}
                value={vantageEnabled}
                disabled={!user?.isInternal}
              />
            </Col>
          </Row>
        </RBCard.Header>
        {entityId && vantageEnabled && (
          <RBCard.Body className="m-0">
            <Col className="pb-2">Time and Attendance </Col>
            <Mui.RadioGroup style={{ width: '100%' }}>
              <Mui.FormControlLabel
                style={{ margin: 0, width: '100%' }}
                className="d-flex"
                checked={false}
                control={<Mui.Radio size="small" />}
                label="Enabled"
                disabled={true}
              />
              <Mui.FormControlLabel
                style={{ margin: 0, width: '100%', paddingBottom: '10px' }}
                className="d-flex"
                checked={true}
                control={<Mui.Radio size="small" />}
                label="Disabled"
              />
            </Mui.RadioGroup>
            <Col>Role to automatically import user into</Col>
            <Mui.RadioGroup className="pt-2 pb-4" value={roles.values()}>
              {roles.map((role: IRole, idx: number) => (
                <tr key={`role-${idx}`}>
                  <td>
                    <Mui.FormControlLabel
                      className="d-flex"
                      key={role.name}
                      value={role.isIntegrationRole}
                      checked={role.isIntegrationRole}
                      style={{ margin: 0, width: '100%' }}
                      control={<Mui.Radio size="small" />}
                      label={role.name}
                      onChange={(event, checked) => {
                        handleChange(role, checked);
                      }}
                      disabled={role.name === 'Account Owner' || !hasBusinessEditPermission}
                    />
                  </td>
                </tr>
              ))}
            </Mui.RadioGroup>
          </RBCard.Body>
        )}
        {vantageEnabled && (
          <RBCard.Body className="p-4 d-flex justify-content-end border-top">
            <Button
              className="mr-4"
              variant="light"
              onClick={revertChanges}
              disabled={
                isButtonsDisabled || (updateAdpRoleLoading && loading) || !vantageEnabled || !hasBusinessEditPermission
              }
            >
              {capitalize(t('translation:spelling.cancel'))}
            </Button>
            <Button
              disabled={
                isButtonsDisabled || (updateAdpRoleLoading && loading) || !vantageEnabled || !hasBusinessEditPermission
              }
              loading={updateAdpRoleLoading}
              onClick={() => onSave()}
            >
              {capitalize(t('translation:spelling.save'))}
            </Button>
          </RBCard.Body>
        )}
      </RBCard>
    </PageWrapperBody>
  );
};

export default AdpTab;
