import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { lockedUntilForCenterResult, timeCardLock, timeCardLockPagedResult } from './fields';
import { QueryHookOptions } from '@apollo/client';

export const SEARCH_TIME_CARD_LOCK = gql`
  query ($input: SearchTimeCardLocksInput) {
    searchTimeCardLock(input: $input) {
      ${timeCardLockPagedResult}
    }
  }
`;

export const useSearchTimeCardLock = (
  options?: QueryHookOptions<{ searchTimeCardLock: ITimeCardLockPagedResult }, { input: ISearchTimeCardLocksInput }>
) => useQuery(SEARCH_TIME_CARD_LOCK, { fetchPolicy: 'network-only', ...options });

export const GET_LOCKED_UNTIL_FOR_BUSINESS = gql`
  query ($input: BusinessSearchTimeCardLockedUntilInput) {
    getLockedUntilForBusiness(input: $input) {
      data{
        ${timeCardLock}
      }
    }
  }
`;

export const useGetLockedUntilForBusiness = (
  options?: QueryHookOptions<
    { getLockedUntilForBusiness: ISearchTimeCardLockedUntilResult },
    { input: IBusinessSearchTimeCardLockedUntilInput }
  >
) =>
  useQuery<
    { getLockedUntilForBusiness: ISearchTimeCardLockedUntilResult },
    { input: IBusinessSearchTimeCardLockedUntilInput }
  >(GET_LOCKED_UNTIL_FOR_BUSINESS, { fetchPolicy: 'network-only', ...options });

export const GET_LOCKED_UNTIL_FOR_CENTER = gql`
  query ($input: CenterSearchTimeCardLockedUntilInput!) {
    getLockedUntilForCenter(input: $input) {
      ${lockedUntilForCenterResult}
    }
  }
`;

export const useGetLockedUntilForCenter = (
  options?: QueryHookOptions<
    { getLockedUntilForCenter: ISearchTimeCardLockedUntilResult },
    { input: ISearchTimeCardLockedUntilInput }
  >
) => useQuery(GET_LOCKED_UNTIL_FOR_CENTER, { fetchPolicy: 'network-only', ...options });
