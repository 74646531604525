import { useLazyQuery } from '@apollo/client';
import { useGetPaginatedBatchesAndDisbursementsForBusinessLazy } from 'gql/batch/queries';
import {
  IGetOccupancyTotalReportVariables,
  useBillingSummaryPdfReportLazy,
  useEndedContractsReportLazy,
  useGetAccountContactInformationReportLazy,
  useGetAdpWeeklyPayrollReportLazy,
  useGetAttendanceTotalsPerWeekLazy,
  useGetBalanceByDatePdfReportLazy,
  useGetBalanceByDateReportLazy,
  useGetDisbursementsMonthlyLazy,
  useGetChildAttendanceSummaryLazy,
  useGetChildCheckInReportLazy,
  useGetChildLastAttendanceByDateReportLazy,
  useGetChildTrackingPdfReportLazy,
  useGetChildWellnessPdfReportLazy,
  useGetChildWellnessReportLazy,
  useGetClassAttendanceRosterPdfReportLazy,
  useGetContractEditsPdfReportLazy,
  useGetContractFeeDetailedPdfReportLazy,
  useGetContractFeePdfReportLazy,
  useGetContractReportLazy,
  useGetDebtExcelReportLazy,
  useGetDebtCsvReportLazy,
  useGetDepositsHeldReportLazy,
  useGetEmailAddressReportLazy,
  useGetEmergencyContactPdfReportLazy,
  useGetEmergencyContactReportLazy,
  useGetEmployeeTimeSheetReportLazy,
  useGetExcursionReportLazy,
  useGetFatZebraTransactionsReportLazy,
  useGetNameToFacePdfReportLazy,
  useGetNameToFaceReportLazy,
  useGetNewContractReportLazy,
  useGetOccupancyTotalReportLazy,
  useGetOccupancyWeeklyTotalReportLazy,
  useGetOutstandingCcsReportLazy,
  useGetQLDCensusReportLazy,
  useGetQuarterHourCheckInPdfReportLazy,
  useGetReportList,
  useGetRoyaltyPdfReportLazy,
  useGetSageGlReportLazy,
  useGetSessionFeesAndTimesExcelLazy,
  useGetSessionFeesAndTimesCsvLazy,
  useGetSignInSignOutReportLazy,
  useGetTransactionTotalsByTransactionTypeReportLazy,
  useGetTransactionTotalsReportLazy,
  useGetWeeklyAttendancePdfReportLazy,
  useGetWeeklyPayrollReportLazy,
  IMealTrackingReportInput,
  useGetBillingEditsPdfReportLazy,
} from 'gql/reports/queries';
import {
  GetSageGlDetailReportQuery,
  GetSageGlDetailReportQueryVariables,
  IGetSageGlDetailResponse,
} from 'gql/reports/queries/sageGlDetailsQuery';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { orderBy } from 'lodash';
import moment from 'moment';
import { getReadableReportName } from 'pages/Reporting/reportingUtils';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { TimeLogNoticeModal } from 'shared/components/Modals/TimeLogNoticeModal';
import { showToast } from 'shared/components/Toast';
import { ReportingCategoryEnum, ReportIntervalEnum, ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { isRegion } from 'shared/util/region';
import { RootState } from 'store/reducers';
import getApolloErrorMessage from '../../../shared/util/getApolloErrorMessage';
import DebtReportModal from './DebtReportModal';
import ExcursionReportModal from './ExcursionReportModal';
import MultipleCenterReportModal from './MultipleCenterReportModal';
import ReportFormatMultipleCenterReportModal from './ReportFormatMultipleCenterReportModal';
import ReportsListTable from './ReportsListTable';
import SingleDateMultipleCenterReportModal from './SingleDateMultipleCenterReportModal';
import SingleDateMultipleCenterTagFilterReportModal from './SingleDateMultipleCenterTagFilterReportModal';
import SingleDateMultipleClassReportModal from './SingleDateMultipleClassReportModal';
import SingleDateSingleCenterReportModal from './SingleDateSingleCenterReportModal';
import TimeframeChildSpaceReportModal from './TimeframeChildSpaceReportModal';
import TimeframeMultipleCenterReportModal from './TimeframeMultipleCenterReportModal';
import TimeframeMultipleEmployeeReportModal from './TimeframeMultipleEmployeeReportModal';
import TimeframeSingleCenterReportModal from './TimeframeSingleCenterReportModal';
import TimeframeMultipleClassReportModal from './TimieframeMultipleClassReportModal';
import TimeframeMultipleClassTagFilterReportModal from './TimeframeMultipleClassTagFilterReportModal';
import WeeklyOccupancyReportModal from './WeeklyOccupancyReportModal';
import axios, { AxiosError } from 'axios';
import config from 'config';
import CustomFieldsReportModal from './CustomFieldsReportModal';
import {
  CustomFieldsReportInput,
  useGetCustomFieldsReportLazyQuery,
  useGetEmployeeTimeSheetExcelReportLazyQuery,
} from 'generated/graphql';
import TimeframeMultipleCenterTagFilterReportModal from './TimeframeMultipleCenterTagFilterReportModal';
import EmergencyContactReportModal from './EmergencyContactReportModal';

interface IReportModalStateShape {
  open: boolean;
  report: IReport | null;
  failedReportDownload?: string | null;
}

interface IProps {
  activeReportCategory: ReportingCategoryEnum | null; // null represents the "all" selection
  onDownloadCsv: (rows: string[][], filename: string, extension?: string, eol?: string) => void;
  onDownloadPdf: (link: string) => boolean;
  canViewReport: boolean;
}

const ReportingListView: React.FC<IProps> = ({
  activeReportCategory,
  onDownloadCsv,
  onDownloadPdf,
  canViewReport,
  ...props
}) => {
  const { k2OccupancyReportWeekly, k2TimeLogLock } = useFlags();

  const isAuRegion = isRegion('AU');
  const [showNoticeModal, setShowNoticeModal] = useState(false);
  const [reportForTimeLogNotice, setReportForTimeLogNotice] = useState<IReport>();

  const user = useSelector((state: RootState) => state.user);
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);
  const [singleDateReportModalState, setSingleDateReportModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });
  const [singleDateReportTagFilterModalState, setSingleDateReportTagFilterModalState] =
    useState<IReportModalStateShape>({
      open: false,
      report: null,
    });
  const [debtReportModalState, setDebtReportModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });
  const [singleDateSingleCenterReportModalState, setSingleDateSingleCenterModalState] =
    useState<IReportModalStateShape>({ open: false, report: null });
  const [timeframeMultipleClassesModalState, setTimeframeMultipleClassesModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });
  const [timeframeMultipleClassesTagFilterModalState, setTimeframeMultipleClassesTagFilterModalState] =
    useState<IReportModalStateShape>({
      open: false,
      report: null,
    });
  const [timeframeReportModalState, setTimeframeReportModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });
  const [timeframeTagFilterReportModalState, setTimeframeTagFilterReportModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });
  const [multipleCenterReportModalState, setMultipleCenterReportModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });
  const [reportFormatMultipleCenterReportModalState, setReportFormatMultipleCenterReportModalState] =
    useState<IReportModalStateShape>({ open: false, report: null });

  const [emergencyContactReportModalState, setEmergencyContactReportModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });

  const [multipleClassReportModalState, setMultipleClassReportModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });
  const [timeframeMultipleEmployeesModalState, setTimeframeMultipleEmployeesModalState] =
    useState<IReportModalStateShape>({ open: false, report: null });

  const [weeklyOccupancyReportModalState, setWeeklyOccupancyReportModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });

  const handleError = (error: any) => showToast(getApolloErrorMessage(error), 'error');

  const [timeframeChildSpaceModalState, setTimeframeChildSpaceModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
    failedReportDownload: null,
  });

  const [timeframeSingleCenterModalState, setTimeframeSingleCenterModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });

  const reportDataToFile = useReportDataToFile();

  const [excursionModalState, setExcursionModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });

  const [customFieldsModalState, setCustomFieldsModalState] = useState<IReportModalStateShape>({
    open: false,
    report: null,
  });

  const { loading: getReportListLoading, data: getReportListData } = useGetReportList();
  const [singleDateMultipleCenterLoading, setsingleDateMultipleCenterLoading] = useState<boolean>(false);

  const [getAttendanceTotalsPerWeekReportFn, { loading: getAttendanceTotalsPerWeekLoading }] =
    useGetAttendanceTotalsPerWeekLazy({
      onCompleted: (result) => {
        if (!singleDateReportModalState.report) return;
        reportDataToFile.downloadXlsxFromBase64(
          result.getAttendanceTotalsPerWeekReport,
          ReportTypeEnum.ATTENDANCE_TOTALS_PER_WEEK
        );
        setSingleDateReportModalState({ open: false, report: null });
      },
      onError: handleError,
    });

  const [getBalanceByDateReportFn, { loading: getBalanceByDateLoading }] = useGetBalanceByDateReportLazy({
    onCompleted: (result) => {
      if (!singleDateReportTagFilterModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(
        result.getBalanceByDateReport,
        isRegion('US') ? 'ACCOUNT_BALANCES' : ReportTypeEnum.BALANCE_AT_DATE
      );
      setSingleDateReportTagFilterModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getBalanceByDatePdfReportFn, { loading: getBalanceByDatePdfReportLoading }] = useGetBalanceByDatePdfReportLazy(
    {
      onCompleted: (result) => {
        if (!singleDateReportTagFilterModalState.report) return;
        onDownloadPdf(result.getBalanceByDatePdfReport);
        setSingleDateReportTagFilterModalState({ open: false, report: null });
      },
      onError: handleError,
    }
  );
  const [getDisbursementsMonthlyReportFn, { loading: getDisbursementsMonthlyLoading }] = useGetDisbursementsMonthlyLazy(
    {
      onCompleted: (result) => {
        if (!singleDateReportModalState.report) return;
        reportDataToFile.downloadXlsxFromBase64(
          result.getPayrixDisbursementsMonthlyReport,
          ReportTypeEnum.DISBURSEMENTS_MONTHLY
        );
        setSingleDateReportModalState({ open: false, report: null });
      },
      onError: handleError,
    }
  );
  const [getContractFeePdfReportFn, { loading: getContractFeePdfReportLoading }] = useGetContractFeePdfReportLazy({
    onCompleted: (result) => {
      if (!singleDateReportModalState.report) return;
      onDownloadPdf(result.getContractFeePdfReport);
      setSingleDateReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getContractFeeDetailedPdfReportFn, { loading: getContractFeeDetailedPdfReportLoading }] =
    useGetContractFeeDetailedPdfReportLazy({
      onCompleted: (result) => {
        if (!singleDateReportModalState.report) return;
        onDownloadPdf(result.getContractFeeDetailedPdfReport);
        setSingleDateReportModalState({ open: false, report: null });
      },
      onError: handleError,
    });
  const [getFatZebraTransactionsReportFn, { loading: getFatZebraTransactionsLoading }] =
    useGetFatZebraTransactionsReportLazy({
      onCompleted: (result) => {
        if (!timeframeReportModalState.report) return;
        reportDataToFile.downloadXlsxFromBase64(
          result.getFatZebraTransactionsReport,
          ReportTypeEnum.FAT_ZEBRA_TRANSACTIONS
        );
        setTimeframeReportModalState({ open: false, report: null });
      },
      onError: handleError,
    });
  const [getDebtExcelReportFn, { loading: getDebtExcelReportLoading }] = useGetDebtExcelReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(result.getDebtExcelReport, 'DEBT_REPORT');
      setDebtReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getDebtCsvReportFn, { loading: getDebtCsvReportLoading }] = useGetDebtCsvReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadCsvFromString(result.getDebtCsvReport, 'DEBT_REPORT');
      setDebtReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getOutstandingCcsReportFn, { loading: getOutstandingCcsReportLoading }] = useGetOutstandingCcsReportLazy({
    onCompleted: (result) => {
      if (!timeframeReportModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getCcsOutstandingReport, ReportTypeEnum.OUTSTANDING_CCS);
      setTimeframeReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getContractReportFn, { loading: getContractReportLoading }] = useGetContractReportLazy({
    onCompleted: (result) => {
      if (!singleDateReportModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getContractReport, 'CONTRACT_REPORT');
      setSingleDateReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getContractTagFilterReportFn, { loading: getContractTagFilterReportLoading }] = useGetContractReportLazy({
    onCompleted: (result) => {
      if (!singleDateReportTagFilterModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getContractReport, 'CONTRACT_REPORT');
      setSingleDateReportTagFilterModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getNewContractReportFn, { loading: getNewContractReportLoading }] = useGetNewContractReportLazy({
    onCompleted: (result) => {
      if (!singleDateReportModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getNewContractReport, 'NEW_CONTRACT_REPORT');
      setSingleDateReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getSessionFeesAndTimesExcelFn, { loading: getSessionFeesAndTimesExcelLoading }] =
    useGetSessionFeesAndTimesExcelLazy({
      onCompleted: (result) => {
        if (!timeframeTagFilterReportModalState.report) return;
        reportDataToFile.downloadXlsxFromBase64(result.getAttendanceReportExcel, 'SESSION_FEES_AND_TIMES');
        setTimeframeTagFilterReportModalState({ open: false, report: null });
      },
      onError: handleError,
    });
  const [getSessionFeesAndTimesCsvFn, { loading: getSessionFeesAndTimesCsvLoading }] = useGetSessionFeesAndTimesCsvLazy(
    {
      onCompleted: (result) => {
        if (!timeframeTagFilterReportModalState.report) return;
        reportDataToFile.downloadCsvFromString(result.getAttendanceReportCsv, 'SESSION_FEES_AND_TIMES');
        setTimeframeTagFilterReportModalState({ open: false, report: null });
      },
      onError: handleError,
    }
  );
  const [getTransactionTotalsReportFn, { loading: getTransactionTotalsLoading }] = useGetTransactionTotalsReportLazy({
    onCompleted: (result) => {
      if (!timeframeReportModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getTransactionTotalReport, 'TRANSACTION_TOTALS');
      setTimeframeReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getTransactionTotalsByTransactionTypeReportFn, { loading: getTransactionTotalsByTransactionTypeLoading }] =
    useGetTransactionTotalsByTransactionTypeReportLazy({
      onCompleted: (result) => {
        if (!timeframeReportModalState.report) return;
        reportDataToFile.downloadXlsxFromBase64(
          result.getTransactionTotalByTransactionTypeReport,
          'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE'
        );
        setTimeframeReportModalState({ open: false, report: null });
      },
      onError: handleError,
    });
  const [getOccupancyTotalReportFn, { loading: getOccupancyTotalReportLoading }] = useGetOccupancyTotalReportLazy({
    onCompleted: (result) => {
      if (!timeframeReportModalState.report) return;
      onDownloadCsv(reportDataToFile.occupancyTotals(result.getOccupancyTotalReport), 'OCCUPANCY_TOTAL');
      setTimeframeReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getOccupancyWeeklyTotalReportFn, { loading: getOccupancyWeeklyTotalReportLoading }] =
    useGetOccupancyWeeklyTotalReportLazy({
      onCompleted: (result) => {
        reportDataToFile.downloadXlsxFromBase64(result.getOccupancyWeeklyTotalReport, 'OCCUPANCY_WEEKLY_TOTAL');
        setWeeklyOccupancyReportModalState({ open: false, report: null });
      },
      onError: handleError,
    });
  const [getWeeklyPayrollReportFn, { loading: getWeeklyPayrollReportLoading }] = useGetWeeklyPayrollReportLazy({
    onCompleted: (result) => {
      if (!timeframeReportModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getWeeklyPayrollReport, 'WEEKLY_PAYROLL');
      setTimeframeReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getDepositsHeldReportFn, { loading: getDepositsHeldReportLoading }] = useGetDepositsHeldReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(result.getDepositsHeldReport, ReportTypeEnum.DEPOSITS_HELD);
      setMultipleCenterReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });
  const [getEmergencyContactReportFn, { loading: getEmergencyContactReportLoading }] = useGetEmergencyContactReportLazy(
    {
      onCompleted: (result) => {
        if (!emergencyContactReportModalState.report) return;
        reportDataToFile.downloadXlsxFromBase64(result.getEmergencyContactReport, 'EMERGENCY_CONTACT');
        setReportFormatMultipleCenterReportModalState({ open: false, report: null });
      },
      onError: handleError,
    }
  );
  const [getAdpWeeklyPayrollReportFn, { loading: getAdpWeeklyPayrollReportLoading }] = useGetAdpWeeklyPayrollReportLazy(
    {
      onCompleted: (result) => {
        if (!timeframeReportModalState.report) return;
        reportDataToFile.downloadCsvFromString(result.getAdpWeeklyPayrollReport, 'ADP_WEEKLY_PAYROLL');
        setTimeframeReportModalState({ open: false, report: null });
      },
      onError: handleError,
    }
  );
  const [getSageGlImportFn, { loading: getSageGlReportLoading }] = useGetSageGlReportLazy({
    onCompleted: (result) => {
      if (!timeframeReportModalState.report) return;
      onDownloadCsv(reportDataToFile.sageGlReport(result.getSageGlReport), 'SAGE_GL', 'csv', 'windows');
      setTimeframeReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const [getSageGlDetailsFn, { loading: getSageGlDetailsLoading }] = useLazyQuery<
    IGetSageGlDetailResponse,
    GetSageGlDetailReportQueryVariables
  >(GetSageGlDetailReportQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      if (!timeframeReportModalState.report) return;
      onDownloadCsv(
        reportDataToFile.sageGlDetails(result.getSageGlDetailsReport.rows),
        ReportTypeEnum.SAGE_GL_DETAILS,
        'csv',
        'windows'
      );
      setTimeframeReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const [getSageCashReportFn, { loading: getSageCashReportLoading }] =
    useGetPaginatedBatchesAndDisbursementsForBusinessLazy({
      onCompleted: (result) => {
        if (!timeframeReportModalState.report) return;
        onDownloadCsv(
          reportDataToFile.sageCashReport(
            result.getPaginatedBatchesForBusiness,
            result.getPayrixDisbursementsForCenters
          ),
          'SAGE_CASHIMP',
          'csv'
        );
        setTimeframeReportModalState({ open: false, report: null });
      },
      onError: handleError,
    });
  const [getEmergencyContactPdfReportFn, { loading: getEmergencyContactPdfReportLoading }] =
    useGetEmergencyContactPdfReportLazy({
      onCompleted: (result) => {
        onDownloadPdf(result.getEmergencyContactPdfReport);
      },
      onError: handleError,
    });

  const [billingSummaryPdfReportLazyFn, { loading: billingSummaryPdfReportLazyLoading }] =
    useBillingSummaryPdfReportLazy({
      onCompleted: (result) => {
        if (!timeframeReportModalState.report) return;
        onDownloadPdf(result.getBillingSummaryPdfReport);
        setTimeframeReportModalState({ open: false, report: null });
      },
      onError: handleError,
    });

  const [getEmployeeTimeSheetReportFn, { loading: getEmployeeTimeSheetReportLoading }] =
    useGetEmployeeTimeSheetReportLazy({
      onCompleted: (result) => {
        if (!timeframeMultipleEmployeesModalState.report) return;
        onDownloadPdf(result.getEmployeeTimeSheetReport);
        setTimeframeMultipleEmployeesModalState({ open: false, report: null });
      },
      onError: handleError,
    });

  const [getEmployeeTimeSheetExcelReportFn, { loading: getEmployeeTimeSheetExcelReportLoading }] =
    useGetEmployeeTimeSheetExcelReportLazyQuery({
      onCompleted: (result) => {
        if (!timeframeMultipleEmployeesModalState.report) return;
        reportDataToFile.downloadXlsxFromBase64(result.getEmployeeTimeSheetExcelReport, 'EMPLOYEE_TIMESHEET');
        setTimeframeMultipleEmployeesModalState({ open: false, report: null });
      },
      onError: handleError,
    });

  const [getWeeklyAttendancePdfReportFn, { loading: getWeeklyAttendancePdfReportLoading }] =
    useGetWeeklyAttendancePdfReportLazy({
      onCompleted: (result) => {
        onDownloadPdf(result.getWeeklyAttendanceReport);
      },
      onError: handleError,
    });

  const [getClassAttendanceRosterPdfReportFn, { loading: getClassAttendanceRosterPdfReportLoading }] =
    useGetClassAttendanceRosterPdfReportLazy({
      onCompleted: (result) => {
        onDownloadPdf(result.getClassAttendanceRosterReport);
      },
      onError: handleError,
    });

  const [getQuarterHourCheckInFn, { loading: getQuarterHourCheckInLoading }] = useGetQuarterHourCheckInPdfReportLazy({
    onCompleted: (result) => {
      onDownloadPdf(result.getQuarterHourCheckInReport);
    },
    onError: handleError,
  });

  const [getChildWellnessReportFn, { loading: getChildWellnessReportLazyLoading }] = useGetChildWellnessReportLazy({
    onCompleted: (result) => {
      if (!timeframeMultipleClassesModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getChildWellnessReport, 'CHILD_HEALTH_AND_WELLNESS');
      setTimeframeMultipleClassesModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const [getChildWellnessPdfReportFn, { loading: getChildWellnessPdfReportLazyLoading }] =
    useGetChildWellnessPdfReportLazy({
      onCompleted: (result) => {
        if (!timeframeMultipleClassesModalState.report) return;
        onDownloadPdf(result.getChildWellnessPdfReport);
        setTimeframeMultipleClassesModalState({ open: false, report: null });
      },
      onError: handleError,
    });

  const [getNameToFaceReportFn, { loading: getNameToFaceReportLoading }] = useGetNameToFaceReportLazy({
    onCompleted: (result) => {
      if (!timeframeMultipleClassesModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getNameToFaceReport, 'NAME_TO_FACE');
      setTimeframeMultipleClassesModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const [getNameToFacePdfReportFn, { loading: getNameToFacePdfReportLoading }] = useGetNameToFacePdfReportLazy({
    onCompleted: (result) => {
      if (!timeframeMultipleClassesModalState.report) return;
      if (onDownloadPdf(result.getNameToFacePdfReport)) {
        setTimeframeMultipleClassesModalState({ open: false, report: null });
      } else {
        setTimeframeMultipleClassesModalState({
          ...timeframeMultipleClassesModalState,
          failedReportDownload: result.getNameToFacePdfReport,
        });
      }
    },
    onError: handleError,
  });

  const [getChildCheckInReportFn, { loading: getChildCheckInReportLoading }] = useGetChildCheckInReportLazy({
    onCompleted: (result) => {
      if (!timeframeMultipleClassesModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getChildCheckInReport, 'CHILD_CHECK_IN');
      setTimeframeMultipleClassesModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const [getChildAttendanceSummaryFn, { loading: getChildAttendanceSummaryLoading }] = useGetChildAttendanceSummaryLazy(
    {
      onCompleted: (result) => {
        if (!timeframeMultipleClassesTagFilterModalState.report) return;
        reportDataToFile.downloadXlsxFromBase64(result.getChildAttendanceSummaryReport, 'CHILD_MISCELLANEOUS_REPORT');
        setTimeframeMultipleClassesTagFilterModalState({ open: false, report: null });
      },
      onError: handleError,
    }
  );

  const [getSignInSignOutReportFn, { loading: getSignInSignOutReportLoading }] = useGetSignInSignOutReportLazy({
    onCompleted: (result) => {
      onDownloadPdf(result.getSignInSignOutReport);
    },
    onError: handleError,
  });

  const [getChildLastAttendanceByDateReportFn, { loading: getChildLastAttendanceByDateLoading }] =
    useGetChildLastAttendanceByDateReportLazy({
      onCompleted: (result) => {
        if (!singleDateReportModalState.report) return;
        reportDataToFile.downloadXlsxFromBase64(
          result.getChildLastAttendanceReport,
          ReportTypeEnum.CHILD_LAST_ATTENDANCE
        );
        setSingleDateReportModalState({ open: false, report: null });
      },
      onError: handleError,
    });

  const [getAccountContactInformationReportFn, { loading: getAccountContactInformationReportLoading }] =
    useGetAccountContactInformationReportLazy({
      onCompleted: (result) => {
        if (!reportFormatMultipleCenterReportModalState.report) return;

        reportDataToFile.downloadXlsxFromBase64(
          result.getAccountContactInformationReport,
          'ACCOUNT_CONTACT_INFORMATION'
        );
        setReportFormatMultipleCenterReportModalState({ open: false, report: null });
      },
      onError: handleError,
    });

  const [getRoyaltyPdfReportFn, { loading: getRoyaltyPdfReportLoading }] = useGetRoyaltyPdfReportLazy({
    onCompleted: (result) => {
      onDownloadPdf(result.getRoyaltyPdfReport);
    },
    onError: handleError,
  });

  const [getChildTrackingPdfReportFn, { loading: getChildTrackingPdfReportLoading }] = useGetChildTrackingPdfReportLazy(
    {
      onCompleted: (result) => {
        if (!timeframeChildSpaceModalState.report) return;
        if (onDownloadPdf(result.getChildTrackingPdfReport)) {
          setTimeframeChildSpaceModalState({ open: false, report: null });
        } else {
          setTimeframeChildSpaceModalState({
            ...timeframeChildSpaceModalState,
            failedReportDownload: result.getChildTrackingPdfReport,
          });
        }
      },
      onError: handleError,
    }
  );

  const [getEndedContractsReportFn, { loading: getEndedContractsLoading }] = useEndedContractsReportLazy({
    onCompleted: (result) => {
      if (!timeframeReportModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getEndedContractsReport, ReportTypeEnum.ENDED_CONTRACTS);
      setTimeframeReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const [getEmailAddressReportFn, { loading: getEmailAddressLoading }] = useGetEmailAddressReportLazy({
    onCompleted: (result) => {
      if (!singleDateReportTagFilterModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getEmailAddressReport, ReportTypeEnum.EMAIL_ADDRESS);
      setSingleDateReportTagFilterModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const [getQLDCensusReport, { loading: getQLDCensusLoading }] = useGetQLDCensusReportLazy({
    onCompleted: (result) => {
      if (!singleDateReportModalState.report) return;
      reportDataToFile.downloadXlsxFromBase64(result.getQLDCensusReport, ReportTypeEnum.QLD_CENSUS);
      setSingleDateReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const [getContractEditsPdfReportFn, { loading: getContractEditsPdfReportLoading }] = useGetContractEditsPdfReportLazy(
    {
      onCompleted: (result) => {
        onDownloadPdf(result.getContractEditsPdfReport);
        setTimeframeSingleCenterModalState({ open: false, report: null });
      },
      onError: handleError,
    }
  );

  const [getBillingEditsPdfReportFn, { loading: getBillingEditsPdfReportLoading }] = useGetBillingEditsPdfReportLazy({
    onCompleted: (result) => {
      onDownloadPdf(result.getBillingEditsPdfReport);
      setTimeframeSingleCenterModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const [getExcursionPdfReportFn, { loading: getExcursionPdfReportLoading }] = useGetExcursionReportLazy({
    onCompleted: (result) => {
      onDownloadPdf(result.getExcursionPdfReport);
      setExcursionModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const [getCustomFieldsReportFn, { loading: getCustomFieldsReportLoading }] = useGetCustomFieldsReportLazyQuery({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(result.getCustomFieldsReport, 'CUSTOM_FIELD_REPORT');
      setDebtReportModalState({ open: false, report: null });
    },
    onError: handleError,
  });

  const filterReportsListByCategory = useCallback(
    (reports: IReport[], category: ReportingCategoryEnum | null): IReport[] => {
      let filteredReports = orderBy(
        category ? reports.filter((report) => report.categoryType === category) : reports,
        (report) => report.type,
        'asc'
      );

      if (!canViewReport) {
        filteredReports = filteredReports.filter((rep) => rep.type === ReportTypeEnum.EMERGENCY_CONTACTS);
      }

      return filteredReports;
    },
    [canViewReport]
  );

  const handleBlankPdfSubmit = useCallback(
    (reportType: ReportType) => {
      switch (reportType) {
        case ReportTypeEnum.SIGN_IN_OUT:
          getSignInSignOutReportFn({});
          break;
        default:
          break;
      }
    },
    [getSignInSignOutReportFn]
  );
  const handleRunReportClick = useCallback(
    (report: IReport) => {
      switch (report.type) {
        case ReportTypeEnum.WEEKLY_ATTENDANCE:
        case ReportTypeEnum.CLASS_ATTENDANCE_ROSTER:
          setMultipleClassReportModalState({ open: true, report });
          break;
        case ReportTypeEnum.OCCUPANCY_TOTALS:
          k2OccupancyReportWeekly
            ? setWeeklyOccupancyReportModalState({ open: true, report })
            : setTimeframeReportModalState({ open: true, report });
          break;
        case ReportTypeEnum.BILLING_SUMMARY:
        case ReportTypeEnum.FAT_ZEBRA_TRANSACTIONS:
        case ReportTypeEnum.OUTSTANDING_CCS:
        case ReportTypeEnum.TRANSACTION_TOTALS_PER_WEEK:
        case ReportTypeEnum.TRANSACTION_TOTALS_PER_MONTH:
        case ReportTypeEnum.TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_WEEK:
        case ReportTypeEnum.TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH:
        case ReportTypeEnum.ADP_WEEKLY_PAYROLL:
        case ReportTypeEnum.SAGE_GL:
        case ReportTypeEnum.SAGE_CASH:
        case ReportTypeEnum.ROYALTY:
        case ReportTypeEnum.ENDED_CONTRACTS:
        case ReportTypeEnum.SAGE_GL_DETAILS:
          setTimeframeReportModalState({ open: true, report });
          break;
        case ReportTypeEnum.SESSION_FEES_AND_TIMES:
          setTimeframeTagFilterReportModalState({ open: true, report });
          break;
        case ReportTypeEnum.WEEKLY_PAYROLL:
          if (k2TimeLogLock) {
            setShowNoticeModal(true);
            setReportForTimeLogNotice(report);
          } else {
            setTimeframeReportModalState({ open: true, report });
          }
          break;
        case ReportTypeEnum.ACCOUNT_CONTACT_INFORMATION:
          setReportFormatMultipleCenterReportModalState({ open: true, report });
          break;
        case ReportTypeEnum.EMERGENCY_CONTACTS:
          setEmergencyContactReportModalState({ open: true, report });
          break;
        case ReportTypeEnum.SIGN_IN_OUT:
          handleBlankPdfSubmit(report.type);
          break;
        case ReportTypeEnum.CONTRACT:
        case ReportTypeEnum.BALANCE_AT_DATE:
        case ReportTypeEnum.EMAIL_ADDRESS:
          setSingleDateReportTagFilterModalState({ open: true, report });
          break;
        case ReportTypeEnum.NEW_CONTRACTS:
        case ReportTypeEnum.CHILD_LAST_ATTENDANCE:
        case ReportTypeEnum.CONTRACT_FEE_OVERVIEW:
        case ReportTypeEnum.CONTRACT_FEE_DETAILED_OVERVIEW:
        case ReportTypeEnum.QLD_CENSUS:
        case ReportTypeEnum.ATTENDANCE_TOTALS_PER_WEEK:
        case ReportTypeEnum.DISBURSEMENTS_MONTHLY:
        case ReportTypeEnum.MEAL_TRACKING:
          setSingleDateReportModalState({ open: true, report });
          break;
        case ReportTypeEnum.DEBT:
          setDebtReportModalState({ open: true, report });
          break;
        case ReportTypeEnum.NAME_TO_FACE:
        case ReportTypeEnum.CHILD_CHECK_IN:
        case ReportTypeEnum.CHILD_HEALTH_AND_WELLNESS:
          setTimeframeMultipleClassesModalState({ open: true, report });
          break;
        case ReportTypeEnum.CHILD_ATTENDANCE_SUMMARY:
          setTimeframeMultipleClassesTagFilterModalState({ open: true, report });
          break;
        case ReportTypeEnum.QUARTER_HOUR_CHECK_IN:
          setSingleDateSingleCenterModalState({ open: true, report });
          break;
        case ReportTypeEnum.EMPLOYEE_TIMESHEET:
          setTimeframeMultipleEmployeesModalState({ open: true, report });
          break;
        case ReportTypeEnum.CHILD_TRACKING:
          setTimeframeChildSpaceModalState({ open: true, report, failedReportDownload: null });
          break;
        case ReportTypeEnum.CONTRACT_EDITS:
        case ReportTypeEnum.BILLING_EDITS:
          setTimeframeSingleCenterModalState({ open: true, report });
          break;
        case ReportTypeEnum.EXCURSION:
          setExcursionModalState({ open: true, report });
          break;
        case ReportTypeEnum.DEPOSITS_HELD:
          setMultipleCenterReportModalState({ open: true, report });
          break;
        case ReportTypeEnum.CUSTOM_FIELD_REPORT:
          setCustomFieldsModalState({ open: true, report });
          break;
        default:
          break;
      }
    },
    [handleBlankPdfSubmit, k2OccupancyReportWeekly, k2TimeLogLock]
  );

  const handleCustomFieldsReportSubmit = useCallback(
    (reportInput: CustomFieldsReportInput) => {
      getCustomFieldsReportFn({ variables: { input: reportInput } });
    },
    [getCustomFieldsReportFn]
  );

  const handleExcursionReportSubmit = useCallback(
    (date: string | null, centerId: string, classIds: string[], checkpointsCount: number) => {
      const { report } = excursionModalState;

      if (!report) {
        return;
      }

      getExcursionPdfReportFn({
        variables: {
          input: {
            businessId: user?.entityId ?? '',
            centerId,
            classIds,
            date: date != null ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            checkpointsCount: !!checkpointsCount ? checkpointsCount : 1,
          },
        },
      });
    },
    [excursionModalState, getExcursionPdfReportFn, user]
  );

  const handleDebtReportSubmit = useCallback(
    (
      date: string | null,
      centerIds: string[],
      transactionDate: TransactionDateType,
      excludeCredit: boolean,
      excludeZero: boolean,
      format: 'EXCEL' | 'CSV',
      statusType?: AccountStatusType
    ) => {
      const { report } = debtReportModalState;
      if (!report) {
        return;
      }

      if (format === 'CSV') {
        getDebtCsvReportFn({
          variables: {
            input: {
              centerIds,
              runAtDate: moment(date).format('YYYY-MM-DD'),
              businessId: user?.entityId ?? '',
              transactionDateType: transactionDate,
              statusType: statusType,
              excludeCredit,
              excludeZero,
            },
          },
        });

        return;
      }

      getDebtExcelReportFn({
        variables: {
          input: {
            centerIds,
            runAtDate: moment(date).format('YYYY-MM-DD'),
            businessId: user?.entityId ?? '',
            transactionDateType: transactionDate,
            statusType: statusType,
            excludeCredit,
            excludeZero,
          },
        },
      });
    },
    [debtReportModalState, getDebtCsvReportFn, getDebtExcelReportFn]
  );

  const handleSingleDateReportSubmit = useCallback(
    (
      date: string | null,
      centerIds: string[],
      format: 'EXCEL' | 'PDF',
      statusList: AccountStatusType[],
      isPrimary?: boolean,
      statusType?: AccountStatusType,
      includeNonCcs?: boolean
    ) => {
      const { report } = singleDateReportModalState;

      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.ATTENDANCE_TOTALS_PER_WEEK:
          getAttendanceTotalsPerWeekReportFn({
            variables: {
              input: {
                businessId: user?.entityId ?? '',
                centerIds,
                week: moment(date).format('YYYY-MM-DD'),
                includeNonCcs: includeNonCcs ?? false,
              },
            },
          });
          break;
        case ReportTypeEnum.DISBURSEMENTS_MONTHLY:
          getDisbursementsMonthlyReportFn({
            variables: {
              input: {
                businessId: user?.entityId ?? '',
                centerIds,
                month: moment(date).startOf('month').format('YYYY-MM-DD'),
              },
            },
          });
          break;
        case ReportTypeEnum.MEAL_TRACKING:
          setsingleDateMultipleCenterLoading(true);
          const input: IMealTrackingReportInput = {
            businessId: user?.entityId ?? '',
            centers: centerIds,
            month: moment(date).startOf('month').format('YYYY-MM-DD'),
          };
          axios
            .put<string>(`${config.api.k2.dashboard}/meal/tracking/pdf`, input, {
              headers: { authorization: `Bearer ${jwtToken}` },
            })
            .then(function (response) {
              onDownloadPdf(response.data);
            })
            .catch(function () {
              showToast(
                'There was an error while running the report. If this continues please contact support.',
                'error'
              );
            })
            .finally(function () {
              setsingleDateMultipleCenterLoading(false);
              setSingleDateReportModalState({ open: false, report: null });
            });
          break;
        case ReportTypeEnum.CONTRACT:
          getContractReportFn({
            variables: {
              input: {
                centerIds,
                businessId: user?.entityId ?? '',
                runAtDate: moment(date).format('YYYY-MM-DD'),
                childTagId: null,
              },
            },
          });
          break;
        case ReportTypeEnum.NEW_CONTRACTS:
          getNewContractReportFn({
            variables: {
              input: {
                centerIds,
                businessId: user?.entityId ?? '',
                runAtDate: moment(date).format('YYYY-MM-DD'),
                childTagId: null,
              },
            },
          });
          break;
        case ReportTypeEnum.CHILD_LAST_ATTENDANCE:
          getChildLastAttendanceByDateReportFn({
            variables: {
              input: {
                centerIds,
                date: moment(date).format('YYYY-MM-DD'),
                businessId: user?.entityId ?? '',
                accountStatus: statusType,
              },
            },
          });
          break;
        case ReportTypeEnum.CONTRACT_FEE_OVERVIEW:
          getContractFeePdfReportFn({
            variables: {
              input: {
                centerIds,
                businessId: user?.entityId ?? '',
                date: moment(date).format('YYYY-MM-DD'),
              },
            },
          });
          break;
        case ReportTypeEnum.CONTRACT_FEE_DETAILED_OVERVIEW:
          getContractFeeDetailedPdfReportFn({
            variables: {
              input: {
                centerIds,
                businessId: user?.entityId ?? '',
                date: moment(date).format('YYYY-MM-DD'),
              },
            },
          });
          break;
        case ReportTypeEnum.QLD_CENSUS:
          getQLDCensusReport({
            variables: {
              input: {
                centerIds,
                businessId: user?.entityId ?? '',
                startDate: moment(date).format('YYYY-MM-DD'),
                endDate: moment(date).day(6).endOf('d').format('YYYY-MM-DD'),
              },
            },
          });
          break;
        default:
          break;
      }
    },
    [
      getDisbursementsMonthlyReportFn,
      getAttendanceTotalsPerWeekReportFn,
      singleDateReportModalState,
      getContractReportFn,
      user,
      getChildLastAttendanceByDateReportFn,
      getContractFeePdfReportFn,
      getContractFeeDetailedPdfReportFn,
      getNewContractReportFn,
      getQLDCensusReport,
      jwtToken,
      onDownloadPdf,
    ]
  );

  const handleSingleDateTagFilterReportSubmit = useCallback(
    (
      date: string | null,
      centerIds: string[],
      format: 'EXCEL' | 'PDF',
      statusList: AccountStatusType[],
      accountTagId: string | null,
      childTagId: string | null,
      contactTagId: string | null,
      isPrimary?: boolean,
      statusType?: AccountStatusType
    ) => {
      const { report } = singleDateReportTagFilterModalState;

      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.EMAIL_ADDRESS:
          getEmailAddressReportFn({
            variables: {
              input: {
                centerIds,
                businessId: user?.entityId ?? '',
                statusList,
                isPrimary,
                contactTagId: contactTagId,
              },
            },
          });
          break;

        case ReportTypeEnum.BALANCE_AT_DATE:
          if (format === 'PDF') {
            getBalanceByDatePdfReportFn({
              variables: {
                input: {
                  centerIds,
                  date: date != null ? moment(date).format('YYYY-MM-DD') : null,
                  businessId: user?.entityId ?? '',
                  createdOn: moment().format('YYYY-MM-DD'),
                  statusType: statusType,
                  accountTagId: accountTagId,
                },
              },
            });
          } else {
            getBalanceByDateReportFn({
              variables: {
                input: {
                  centerIds,
                  date: date != null ? moment(date).format('YYYY-MM-DD') : null,
                  businessId: user?.entityId ?? '',
                  statusType: statusType,
                  accountTagId: accountTagId,
                },
              },
            });
          }
          break;
        case ReportTypeEnum.CONTRACT:
          getContractTagFilterReportFn({
            variables: {
              input: {
                centerIds,
                businessId: user?.entityId ?? '',
                runAtDate: moment(date).format('YYYY-MM-DD'),
                childTagId: childTagId,
              },
            },
          });
          break;
        default:
          break;
      }
    },
    [singleDateReportTagFilterModalState, getContractTagFilterReportFn, user?.entityId]
  );

  const handleSingleDateSingleCenterReportSubmit = useCallback(
    (date: string, centerId: string) => {
      const { report } = singleDateSingleCenterReportModalState;
      if (!report) {
        return;
      }

      switch (report.type) {
        case ReportTypeEnum.QUARTER_HOUR_CHECK_IN:
          getQuarterHourCheckInFn({
            variables: {
              input: {
                centerId: centerId,
                date: moment(date).format('YYYY-MM-DD'),
              },
            },
          });
          break;
        default:
          break;
      }
    },
    [singleDateSingleCenterReportModalState, getQuarterHourCheckInFn]
  );

  const handleSingleDateMultipleClassReportSubmit = useCallback(
    (date: string, centerId: string, classIds: string[], format: 'EXCEL' | 'PDF') => {
      const { report } = multipleClassReportModalState;
      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.WEEKLY_ATTENDANCE:
          getWeeklyAttendancePdfReportFn({
            variables: {
              input: {
                centerId,
                startOfWeek: moment(date).format('YYYY-MM-DD'),
                businessId: user?.entityId ?? '',
                classIds,
              },
            },
          });
          break;
        case ReportTypeEnum.CLASS_ATTENDANCE_ROSTER:
          getClassAttendanceRosterPdfReportFn({
            variables: {
              input: {
                centerId,
                date: moment(date).format('YYYY-MM-DD'),
                businessId: user?.entityId ?? '',
                classIds,
              },
            },
          });
          break;
        default:
          break;
      }
    },
    [user, multipleClassReportModalState, getWeeklyAttendancePdfReportFn, getClassAttendanceRosterPdfReportFn]
  );

  const handleTimeframeMultipleClassReportSubmit = useCallback(
    (
      startDate: string,
      endDate: string | null,
      centerId: string,
      classIds: string[],
      conditionIds: string[],
      format: 'EXCEL' | 'PDF'
    ) => {
      const { report } = timeframeMultipleClassesModalState;
      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.NAME_TO_FACE:
          if (format === 'PDF') {
            getNameToFacePdfReportFn({
              variables: {
                input: {
                  centerId,
                  startDate: moment(startDate).format('YYYY-MM-DD'),
                  endDate: moment(endDate).format('YYYY-MM-DD'),
                  classIds,
                },
              },
            });
          } else {
            getNameToFaceReportFn({
              variables: {
                input: {
                  centerId,
                  startDate: moment(startDate).format('YYYY-MM-DD'),
                  endDate: moment(endDate).format('YYYY-MM-DD'),
                  classIds,
                },
              },
            });
          }
          break;
        case ReportTypeEnum.CHILD_CHECK_IN:
          getChildCheckInReportFn({
            variables: {
              input: {
                centerId,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                classIds,
              },
            },
          });
          break;
        case ReportTypeEnum.CHILD_ATTENDANCE_SUMMARY:
          getChildAttendanceSummaryFn({
            variables: {
              input: {
                businessId: user?.entityId ?? '',
                centerId,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                classIds,
                childTagId: null,
              },
            },
          });
          break;
        case ReportTypeEnum.CHILD_HEALTH_AND_WELLNESS:
          if (format === 'PDF') {
            getChildWellnessPdfReportFn({
              variables: {
                input: {
                  centerId,
                  startDate: moment(startDate).format('YYYY-MM-DD'),
                  endDate: moment(endDate).format('YYYY-MM-DD'),
                  businessId: user?.entityId ?? '',
                  classIds,
                  conditionIds,
                },
              },
            });
          } else {
            getChildWellnessReportFn({
              variables: {
                input: {
                  centerId,
                  startDate: moment(startDate).format('YYYY-MM-DD'),
                  endDate: moment(endDate).format('YYYY-MM-DD'),
                  businessId: user?.entityId ?? '',
                  classIds,
                  conditionIds,
                },
              },
            });
          }
          break;
        default:
          break;
      }
    },
    [
      timeframeMultipleClassesModalState,
      getNameToFaceReportFn,
      getNameToFacePdfReportFn,
      getChildCheckInReportFn,
      getChildWellnessPdfReportFn,
      user,
      getChildWellnessReportFn,
      getChildAttendanceSummaryFn,
    ]
  );

  const handleTimeframeMultipleClassTagFilterReportSubmit = useCallback(
    (
      startDate: string,
      endDate: string | null,
      centerId: string,
      classIds: string[] | null,
      childTagId: string | null
    ) => {
      const { report } = timeframeMultipleClassesTagFilterModalState;
      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.CHILD_ATTENDANCE_SUMMARY:
          getChildAttendanceSummaryFn({
            variables: {
              input: {
                businessId: user?.entityId ?? '',
                centerId,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                classIds,
                childTagId,
              },
            },
          });
          break;
        default:
          break;
      }
    },
    [timeframeMultipleClassesTagFilterModalState, user, getChildAttendanceSummaryFn]
  );

  const handleTimeframeMultipleEmployeeReportSubmit = (
    startDate: string,
    endDate: string | null,
    centerId: string,
    employeeIds: string[],
    format: 'EXCEL' | 'PDF'
  ) => {
    const { report } = timeframeMultipleEmployeesModalState;
    if (!report) return;
    switch (report.type) {
      case ReportTypeEnum.EMPLOYEE_TIMESHEET:
        const reportVariables = {
          input: {
            businessId: user?.entityId!,
            centerId,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            staffIds: employeeIds,
          },
        };
        if (format === 'PDF') {
          getEmployeeTimeSheetReportFn({
            variables: reportVariables,
          });
        } else {
          getEmployeeTimeSheetExcelReportFn({
            variables: reportVariables,
          });
        }
        break;
      default:
        break;
    }
  };

  const handleTimeframeReportSubmit = useCallback(
    (
      start: string,
      end: string,
      centerIds: string[],
      format: 'CSV' | 'EXCEL',
      transactionDate: TransactionDateType,
      includeSubsidyPayments: boolean,
      includeLiabilities: boolean,
      statusType?: AccountStatusType
    ) => {
      const { report } = timeframeReportModalState;
      const startDate = moment(start).format('YYYY-MM-DD');
      const endDate = moment(end).format('YYYY-MM-DD');
      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.OUTSTANDING_CCS:
          getOutstandingCcsReportFn({
            variables: {
              input: {
                centerIds,
                businessId: user?.entityId ?? '',
                startDate,
                endDate,
                dateType: transactionDate || 'APPLIES',
                accountStatus: statusType,
              },
            },
          });
          break;
        case ReportTypeEnum.FAT_ZEBRA_TRANSACTIONS:
          getFatZebraTransactionsReportFn({
            variables: {
              input: {
                businessId: user?.entityId ?? '',
                centerIds,
                startDate,
                endDate,
              },
            },
          });
          break;
        case ReportTypeEnum.ENDED_CONTRACTS:
          getEndedContractsReportFn({
            variables: {
              input: {
                businessId: user?.entityId ?? '',
                centerIds,
                startDate,
                endDate,
              },
            },
          });
          break;
        case ReportTypeEnum.BILLING_SUMMARY:
          billingSummaryPdfReportLazyFn({
            variables: {
              input: {
                businessId: user?.entityId ?? '',
                centerIds,
                startDate,
                endDate,
              },
            },
          });
          break;
        case ReportTypeEnum.TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH:
        case ReportTypeEnum.TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_WEEK:
          getTransactionTotalsByTransactionTypeReportFn({
            variables: {
              input: {
                centerIds,
                startDate,
                endDate,
                businessId: user?.entityId ?? '',
                interval:
                  report.type === ReportTypeEnum.TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH
                    ? ReportIntervalEnum.MONTH
                    : ReportIntervalEnum.WEEK,
                transactionDate,
                includeLiabilities: includeLiabilities,
              },
            },
          });
          break;
        case ReportTypeEnum.TRANSACTION_TOTALS_PER_WEEK:
        case ReportTypeEnum.TRANSACTION_TOTALS_PER_MONTH:
          getTransactionTotalsReportFn({
            variables: {
              input: {
                centerIds,
                startDate,
                endDate,
                businessId: user?.entityId ?? '',
                interval:
                  report.type === ReportTypeEnum.TRANSACTION_TOTALS_PER_MONTH
                    ? ReportIntervalEnum.MONTH
                    : ReportIntervalEnum.WEEK,
                transactionDate,
              },
            },
          });
          break;
        case ReportTypeEnum.OCCUPANCY_TOTALS:
          if (!k2OccupancyReportWeekly) {
            getOccupancyTotalReportFn({
              variables: {
                input: {
                  centerIds,
                  startDate,
                  endDate,
                  businessId: user?.entityId ?? '',
                  classIds: [],
                  excludeNonCcsCareType: false,
                  excludeClassesWithNoAttendance: false,
                },
              },
            });
          }
          break;
        case ReportTypeEnum.ADP_WEEKLY_PAYROLL:
          getAdpWeeklyPayrollReportFn({
            variables: {
              input: {
                centerIds,
                startDate,
                endDate,
                businessId: user?.entityId ?? '',
              },
            },
          });
          break;
        case ReportTypeEnum.WEEKLY_PAYROLL:
          getWeeklyPayrollReportFn({
            variables: {
              input: {
                centerIds,
                startDate,
                endDate,
                businessId: user?.entityId ?? '',
              },
            },
          });
          break;
        case ReportTypeEnum.SAGE_GL:
          getSageGlImportFn({
            variables: {
              input: {
                centerIds,
                startDate,
                endDate,
              },
            },
          });
          break;
        case ReportTypeEnum.SAGE_GL_DETAILS:
          getSageGlDetailsFn({ variables: { centerIds, startDate, endDate } });
          break;
        case ReportTypeEnum.SAGE_CASH:
          getSageCashReportFn({
            variables: {
              input: {
                searchKey: '',
                businessId: user?.entityId ?? '',
                centerIds,
                start: startDate,
                end: endDate,
                sortBy: '',
                sortDirection: 'asc',
                pageNumber: 0,
                pageSize: 1000000,
                omitAutomatedBatches: false,
              },
              input2: {
                businessId: user?.entityId ?? '',
                centerIds,
                startDate,
                endDate,
                pageNumber: 0,
                pageSize: 1000000,
                sortBy: 'name', // This doesn't matter it just needs to have a sort
                sortDirection: 'asc',
                searchTerm: '',
              },
            },
          });
          break;
        case ReportTypeEnum.ROYALTY:
          getRoyaltyPdfReportFn({
            variables: {
              input: {
                businessId: user?.entityId ?? '',
                centerIds: centerIds,
                startDate,
                endDate,
                includeSubsidyPayments: includeSubsidyPayments,
              },
            },
          });
          break;
      }
    },
    [
      timeframeReportModalState,
      getOutstandingCcsReportFn,
      user?.entityId,
      getFatZebraTransactionsReportFn,
      getEndedContractsReportFn,
      billingSummaryPdfReportLazyFn,
      getTransactionTotalsByTransactionTypeReportFn,
      getTransactionTotalsReportFn,
      k2OccupancyReportWeekly,
      getAdpWeeklyPayrollReportFn,
      getWeeklyPayrollReportFn,
      getSageGlImportFn,
      getSageGlDetailsFn,
      getSageCashReportFn,
      getRoyaltyPdfReportFn,
      getOccupancyTotalReportFn,
    ]
  );

  const handleTimeframeTagFilterReportSubmit = useCallback(
    (
      start: string,
      end: string,
      centerIds: string[],
      format: 'CSV' | 'EXCEL',
      transactionDate: TransactionDateType,
      includeSubsidyPayments: boolean,
      includeLiabilities: boolean,
      selectedAccountTag: string | null,
      selectedChildTag: string | null,
      statusType?: AccountStatusType
    ) => {
      const { report } = timeframeTagFilterReportModalState;
      const startDate = moment(start).format('YYYY-MM-DD');
      const endDate = moment(end).format('YYYY-MM-DD');
      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.SESSION_FEES_AND_TIMES:
          if (format === 'EXCEL') {
            getSessionFeesAndTimesExcelFn({
              variables: {
                input: {
                  centerIds,
                  startDate,
                  endDate,
                  businessId: user?.entityId ?? '',
                  selectedAccountTag,
                  selectedChildTag,
                },
              },
            });
            break;
          }
          getSessionFeesAndTimesCsvFn({
            variables: {
              input: {
                centerIds,
                startDate,
                endDate,
                businessId: user?.entityId ?? '',
                selectedAccountTag,
                selectedChildTag,
              },
            },
          });
          break;
      }
    },
    [timeframeTagFilterReportModalState, getSessionFeesAndTimesCsvFn, user?.entityId, getSessionFeesAndTimesExcelFn]
  );

  const handleReportFormatMultipleCenterReportSubmit = useCallback(
    (centerIds: string[], format: 'EXCEL' | 'PDF', statusType?: AccountStatusType, classIds?: string[]) => {
      const { report } = reportFormatMultipleCenterReportModalState;

      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.ACCOUNT_CONTACT_INFORMATION:
          getAccountContactInformationReportFn({
            variables: {
              input: {
                centerIds,
                businessId: user?.entityId ?? '',
                date: moment().format('YYYY-MM-DD'),
                statusType: statusType,
              },
            },
          });
          break;
        default:
          break;
      }
    },
    [reportFormatMultipleCenterReportModalState, getAccountContactInformationReportFn, user?.entityId]
  );

  const handleEmergencyContactReportSubmit = useCallback(
    (centerIds: string[], format: 'EXCEL' | 'PDF', date: string, classIds?: string[]) => {
      const { report } = emergencyContactReportModalState;

      if (!report) return;

      if (format === 'PDF') {
        getEmergencyContactPdfReportFn({
          variables: {
            input: { centerIds, businessId: user?.entityId ?? '', classIds: classIds ?? [], date },
          },
        });
      } else {
        getEmergencyContactReportFn({
          variables: {
            input: { centerIds, businessId: user?.entityId ?? '', classIds: classIds ?? [], date },
          },
        });
      }
    },
    [emergencyContactReportModalState, getEmergencyContactPdfReportFn, user?.entityId, getEmergencyContactReportFn]
  );

  const handleMultipleCenterReportSubmit = useCallback(
    (centerIds: string[]) => {
      const { report } = multipleCenterReportModalState;

      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.DEPOSITS_HELD:
          getDepositsHeldReportFn({
            variables: {
              input: {
                businessId: user?.entityId ?? '',
                centerIds,
                startDate: null,
                endDate: null,
              },
            },
          });
          break;
        default:
          break;
      }
    },
    [user, multipleCenterReportModalState, getDepositsHeldReportFn]
  );

  const handleWeeklyOccupancyReportModalSubmit = useCallback(
    (input: IGetOccupancyTotalReportVariables) => {
      const { report } = weeklyOccupancyReportModalState;

      if (!report) return;

      if (k2OccupancyReportWeekly) {
        getOccupancyWeeklyTotalReportFn({
          variables: {
            input: {
              centerIds: input.input.centerIds,
              startDate: moment(input.input.startDate).format('YYYY-MM-DD'),
              endDate: moment(input.input.endDate).format('YYYY-MM-DD'),
              businessId: user?.entityId ?? '',
              classIds: input.input.classIds,
              excludeNonCcsCareType: input.input.excludeNonCcsCareType,
              excludeClassesWithNoAttendance: input.input.excludeClassesWithNoAttendance,
              includeLeadApplications: input.input.includeLeadApplications,
            },
          },
        });
      }
    },
    [getOccupancyWeeklyTotalReportFn, weeklyOccupancyReportModalState, user, k2OccupancyReportWeekly]
  );

  const handleTimeframeChildSpaceReportSubmit = useCallback(
    (
      startDate: string,
      endDate: string,
      centerId: string,
      spaceId: string | null,
      childId: string | null,
      format: 'EXCEL' | 'PDF'
    ) => {
      const { report } = timeframeChildSpaceModalState;
      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.CHILD_TRACKING:
          getChildTrackingPdfReportFn({
            variables: {
              input: {
                businessId: user?.entityId ?? '',
                centerId,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                spaceId,
                childId,
              },
            },
          });
          break;
        default:
          break;
      }
    },
    [timeframeChildSpaceModalState, getChildTrackingPdfReportFn]
  );

  const handleTimeframeSingleCenterReportSubmit = useCallback(
    (centerId: string, accountId: string | null, startDate: string, endDate: string, format: 'EXCEL' | 'PDF') => {
      const { report } = timeframeSingleCenterModalState;
      if (!report) return;

      switch (report.type) {
        case ReportTypeEnum.CONTRACT_EDITS:
          getContractEditsPdfReportFn({
            variables: {
              input: {
                ...{
                  businessId: user?.entityId ?? '',
                  centerId,
                  startDate: moment(startDate).format('YYYY-MM-DD'),
                  endDate: moment(endDate).format('YYYY-MM-DD'),
                },
                ...(accountId && { accountId }),
              },
            },
          });
          break;
        case ReportTypeEnum.BILLING_EDITS:
          getBillingEditsPdfReportFn({
            variables: {
              input: {
                ...{
                  businessId: user?.entityId ?? '',
                  centerId,
                  startDate: moment(startDate).format('YYYY-MM-DD'),
                  endDate: moment(endDate).format('YYYY-MM-DD'),
                },
                ...(accountId && { accountId }),
              },
            },
          });
          break;
        default:
          break;
      }
    },
    [timeframeSingleCenterModalState, getContractEditsPdfReportFn, user?.entityId, getBillingEditsPdfReportFn]
  );

  const timeframeReportType = timeframeReportModalState.report?.type;

  const showTransactionDate =
    timeframeReportType === 'TRANSACTION_TOTALS_PER_MONTH' ||
    timeframeReportType === 'TRANSACTION_TOTALS_PER_WEEK' ||
    timeframeReportType === 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH' ||
    timeframeReportType === 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_WEEK' ||
    timeframeReportType === 'OUTSTANDING_CCS';

  const hideArchivedClassesReportTypes: Array<ReportType | undefined> = [
    ReportTypeEnum.WEEKLY_ATTENDANCE,
    ReportTypeEnum.CLASS_ATTENDANCE_ROSTER,
    ReportTypeEnum.CHILD_HEALTH_AND_WELLNESS,
  ];

  const hideInactiveClassesReportType: Array<ReportType | undefined> = [ReportTypeEnum.WEEKLY_ATTENDANCE];

  const getStartOfFinancialYear = (): string => {
    const currentMoment = moment.utc();

    const currentFinancialYearStartDate = moment
      .utc()
      .set({ year: currentMoment.year(), month: 6, date: 1 })
      .startOf('day');

    return (
      currentMoment.isAfter(currentFinancialYearStartDate)
        ? currentFinancialYearStartDate.startOf('isoWeek')
        : currentFinancialYearStartDate.add(-1, 'year').startOf('isoWeek')
    ).format('YYYY-MM-DD');
  };

  return (
    <>
      <ReportsListTable
        isLoading={getReportListLoading && !getReportListData}
        reports={filterReportsListByCategory(getReportListData?.getReportList ?? [], activeReportCategory)}
        onRunReportClick={handleRunReportClick}
      />
      <DebtReportModal
        isOpen={debtReportModalState.open}
        reportName={debtReportModalState.report ? getReadableReportName(debtReportModalState.report.type) : ''}
        isLoading={getDebtCsvReportLoading || getDebtExcelReportLoading}
        onClose={() => setDebtReportModalState({ open: false, report: null })}
        onSubmit={handleDebtReportSubmit}
      />

      <SingleDateMultipleCenterReportModal
        isOpen={singleDateReportModalState.open}
        reportName={
          singleDateReportModalState.report ? getReadableReportName(singleDateReportModalState.report.type) : ''
        }
        isLoading={
          getBalanceByDateLoading ||
          getBalanceByDatePdfReportLoading ||
          getContractFeePdfReportLoading ||
          getContractFeeDetailedPdfReportLoading ||
          getChildLastAttendanceByDateLoading ||
          getContractReportLoading ||
          getNewContractReportLoading ||
          getEmailAddressLoading ||
          getQLDCensusLoading ||
          getAttendanceTotalsPerWeekLoading ||
          getDisbursementsMonthlyLoading ||
          singleDateMultipleCenterLoading
        }
        onClose={() => setSingleDateReportModalState({ open: false, report: null })}
        onSubmit={handleSingleDateReportSubmit}
        hasAccountStatusOption={
          singleDateReportModalState.report?.type === 'BALANCE_AT_DATE' ||
          singleDateReportModalState.report?.type === 'CHILD_LAST_ATTENDANCE'
        }
        hasMultipleAccountStatusOption={singleDateReportModalState.report?.type === 'EMAIL_ADDRESS'}
        hasAccountContactTypeOption={singleDateReportModalState.report?.type === 'EMAIL_ADDRESS'}
        showReportFormat={singleDateReportModalState.report?.type === 'BALANCE_AT_DATE'}
        hasDateInput={
          (singleDateReportModalState.report?.type === 'BALANCE_AT_DATE' && isRegion('US')) ||
          singleDateReportModalState.report?.type === 'EMAIL_ADDRESS'
            ? false
            : true
        }
        showMonthOnly={
          singleDateReportModalState.report?.type === 'DISBURSEMENTS_MONTHLY' ||
          singleDateReportModalState.report?.type === 'MEAL_TRACKING'
        }
        showCareTypeSwitch={singleDateReportModalState.report?.type === 'ATTENDANCE_TOTALS_PER_WEEK'}
      />
      <SingleDateMultipleCenterTagFilterReportModal
        isOpen={singleDateReportTagFilterModalState.open}
        reportName={
          singleDateReportTagFilterModalState.report
            ? getReadableReportName(singleDateReportTagFilterModalState.report.type)
            : ''
        }
        isLoading={
          getBalanceByDateLoading ||
          getBalanceByDatePdfReportLoading ||
          getContractFeePdfReportLoading ||
          getContractFeeDetailedPdfReportLoading ||
          getChildLastAttendanceByDateLoading ||
          getContractTagFilterReportLoading ||
          getNewContractReportLoading ||
          getEmailAddressLoading ||
          getQLDCensusLoading ||
          getAttendanceTotalsPerWeekLoading ||
          getDisbursementsMonthlyLoading ||
          singleDateMultipleCenterLoading
        }
        onClose={() => setSingleDateReportTagFilterModalState({ open: false, report: null })}
        onSubmit={handleSingleDateTagFilterReportSubmit}
        hasAccountStatusOption={
          singleDateReportTagFilterModalState.report?.type === 'BALANCE_AT_DATE' ||
          singleDateReportTagFilterModalState.report?.type === 'CHILD_LAST_ATTENDANCE'
        }
        hasMultipleAccountStatusOption={singleDateReportTagFilterModalState.report?.type === 'EMAIL_ADDRESS'}
        hasAccountContactTypeOption={singleDateReportTagFilterModalState.report?.type === 'EMAIL_ADDRESS'}
        showReportFormat={singleDateReportTagFilterModalState.report?.type === 'BALANCE_AT_DATE'}
        hasDateInput={
          (singleDateReportTagFilterModalState.report?.type === 'BALANCE_AT_DATE' && isRegion('US')) ||
          singleDateReportTagFilterModalState.report?.type === 'EMAIL_ADDRESS'
            ? false
            : true
        }
        showMonthOnly={
          singleDateReportTagFilterModalState.report?.type === 'DISBURSEMENTS_MONTHLY' ||
          singleDateReportTagFilterModalState.report?.type === 'MEAL_TRACKING'
        }
        showChildTags={singleDateReportTagFilterModalState.report?.type === 'CONTRACT'}
        showAccountTags={singleDateReportTagFilterModalState.report?.type === 'BALANCE_AT_DATE'}
        showContactTags={singleDateReportTagFilterModalState.report?.type === 'EMAIL_ADDRESS'}
      />
      <SingleDateSingleCenterReportModal
        isOpen={singleDateSingleCenterReportModalState.open}
        reportName={
          singleDateSingleCenterReportModalState.report
            ? getReadableReportName(singleDateSingleCenterReportModalState.report.type)
            : ''
        }
        isLoading={getQuarterHourCheckInLoading}
        onClose={() => setSingleDateSingleCenterModalState({ open: false, report: null })}
        onSubmit={handleSingleDateSingleCenterReportSubmit}
      />
      <TimeframeMultipleCenterReportModal
        isOpen={timeframeReportModalState.open}
        reportName={
          timeframeReportModalState.report ? getReadableReportName(timeframeReportModalState.report.type) : ''
        }
        timeframeType={
          timeframeReportModalState.report?.type === 'TRANSACTION_TOTALS_PER_MONTH' ||
          timeframeReportModalState.report?.type === 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH'
            ? 'MONTH'
            : 'WEEK'
        }
        hasAccountStatusOption={timeframeReportModalState.report?.type === 'OUTSTANDING_CCS'}
        defaultDate={timeframeReportModalState.report?.type === 'OUTSTANDING_CCS' ? getStartOfFinancialYear() : null}
        showTransactionDate={showTransactionDate}
        showIncludeSubsidyToggle={timeframeReportModalState.report?.type === 'ROYALTY'}
        showIncludeLiabilitiesToggle={
          timeframeReportModalState.report?.type === 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH' ||
          timeframeReportModalState.report?.type === 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_WEEK'
        }
        isLoading={
          getFatZebraTransactionsLoading ||
          getOutstandingCcsReportLoading ||
          getTransactionTotalsLoading ||
          getSessionFeesAndTimesExcelLoading ||
          getSessionFeesAndTimesCsvLoading ||
          getTransactionTotalsByTransactionTypeLoading ||
          getOccupancyTotalReportLoading ||
          getOccupancyWeeklyTotalReportLoading ||
          getWeeklyPayrollReportLoading ||
          getAdpWeeklyPayrollReportLoading ||
          getSageGlReportLoading ||
          getSageGlDetailsLoading ||
          getSageCashReportLoading ||
          getRoyaltyPdfReportLoading ||
          billingSummaryPdfReportLazyLoading ||
          getEndedContractsLoading
        }
        onClose={() => setTimeframeReportModalState({ open: false, report: null })}
        onSubmit={handleTimeframeReportSubmit}
        showFormatSelector={timeframeReportModalState.report?.type === 'SESSION_FEES_AND_TIMES'}
      />
      <TimeframeMultipleCenterTagFilterReportModal
        isOpen={timeframeTagFilterReportModalState.open}
        reportName={
          timeframeTagFilterReportModalState.report
            ? getReadableReportName(timeframeTagFilterReportModalState.report.type)
            : ''
        }
        timeframeType={
          timeframeTagFilterReportModalState.report?.type === 'TRANSACTION_TOTALS_PER_MONTH' ||
          timeframeTagFilterReportModalState.report?.type === 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH'
            ? 'MONTH'
            : 'WEEK'
        }
        hasAccountStatusOption={timeframeTagFilterReportModalState.report?.type === 'OUTSTANDING_CCS'}
        defaultDate={
          timeframeTagFilterReportModalState.report?.type === 'OUTSTANDING_CCS' ? getStartOfFinancialYear() : null
        }
        showTransactionDate={showTransactionDate}
        showIncludeSubsidyToggle={timeframeTagFilterReportModalState.report?.type === 'ROYALTY'}
        showIncludeLiabilitiesToggle={
          timeframeTagFilterReportModalState.report?.type === 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH' ||
          timeframeTagFilterReportModalState.report?.type === 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_WEEK'
        }
        isLoading={
          getFatZebraTransactionsLoading ||
          getOutstandingCcsReportLoading ||
          getTransactionTotalsLoading ||
          getSessionFeesAndTimesExcelLoading ||
          getSessionFeesAndTimesCsvLoading ||
          getTransactionTotalsByTransactionTypeLoading ||
          getOccupancyTotalReportLoading ||
          getOccupancyWeeklyTotalReportLoading ||
          getWeeklyPayrollReportLoading ||
          getAdpWeeklyPayrollReportLoading ||
          getSageGlReportLoading ||
          getSageGlDetailsLoading ||
          getSageCashReportLoading ||
          getRoyaltyPdfReportLoading ||
          billingSummaryPdfReportLazyLoading ||
          getEndedContractsLoading
        }
        onClose={() => setTimeframeTagFilterReportModalState({ open: false, report: null })}
        onSubmit={handleTimeframeTagFilterReportSubmit}
        showFormatSelector={timeframeTagFilterReportModalState.report?.type === 'SESSION_FEES_AND_TIMES'}
      />
      <MultipleCenterReportModal
        isOpen={multipleCenterReportModalState.open}
        reportName={
          multipleCenterReportModalState.report ? getReadableReportName(multipleCenterReportModalState.report.type) : ''
        }
        isLoading={getDepositsHeldReportLoading || getEmergencyContactReportLoading}
        onClose={() => setMultipleCenterReportModalState({ open: false, report: null })}
        onSubmit={handleMultipleCenterReportSubmit}
      />
      <ReportFormatMultipleCenterReportModal
        isOpen={reportFormatMultipleCenterReportModalState.open}
        showFormats={
          reportFormatMultipleCenterReportModalState?.report?.type !== ReportTypeEnum.ACCOUNT_CONTACT_INFORMATION
        }
        hasAccountStatusOption={
          reportFormatMultipleCenterReportModalState?.report?.type === ReportTypeEnum.ACCOUNT_CONTACT_INFORMATION &&
          !isAuRegion
        }
        reportName={
          reportFormatMultipleCenterReportModalState.report
            ? getReadableReportName(reportFormatMultipleCenterReportModalState.report.type)
            : ''
        }
        isLoading={
          getEmergencyContactReportLoading ||
          getEmergencyContactPdfReportLoading ||
          getAccountContactInformationReportLoading
        }
        onClose={() =>
          setReportFormatMultipleCenterReportModalState({
            open: false,
            report: null,
          })
        }
        onSubmit={handleReportFormatMultipleCenterReportSubmit}
        showClasses={reportFormatMultipleCenterReportModalState?.report?.type === ReportTypeEnum.EMERGENCY_CONTACTS}
      />
      <EmergencyContactReportModal
        isOpen={emergencyContactReportModalState.open}
        reportName={
          emergencyContactReportModalState.report
            ? getReadableReportName(emergencyContactReportModalState.report.type)
            : ''
        }
        isLoading={getEmergencyContactReportLoading || getEmergencyContactPdfReportLoading}
        onClose={() =>
          setEmergencyContactReportModalState({
            open: false,
            report: null,
          })
        }
        onSubmit={handleEmergencyContactReportSubmit}
      />
      <SingleDateMultipleClassReportModal
        isOpen={multipleClassReportModalState.open}
        reportName={
          multipleClassReportModalState.report ? getReadableReportName(multipleClassReportModalState.report.type) : ''
        }
        hideArchivedClasses={hideArchivedClassesReportTypes.includes(multipleClassReportModalState.report?.type)}
        hideInactiveClasses={hideInactiveClassesReportType.includes(multipleClassReportModalState.report?.type)}
        isLoading={getWeeklyAttendancePdfReportLoading || getClassAttendanceRosterPdfReportLoading}
        onClose={() => setMultipleClassReportModalState({ open: false, report: null })}
        onSubmit={handleSingleDateMultipleClassReportSubmit}
      />
      <ExcursionReportModal
        isOpen={excursionModalState.open}
        reportName={getReadableReportName('EXCURSION')}
        isLoading={getExcursionPdfReportLoading}
        onClose={() => setExcursionModalState({ open: false, report: null })}
        onSubmit={handleExcursionReportSubmit}
      />
      <WeeklyOccupancyReportModal
        isOpen={weeklyOccupancyReportModalState.open}
        reportName={
          weeklyOccupancyReportModalState.report
            ? getReadableReportName(weeklyOccupancyReportModalState.report.type)
            : ''
        }
        isLoading={getOccupancyWeeklyTotalReportLoading}
        onClose={() => setWeeklyOccupancyReportModalState({ open: false, report: null })}
        onSubmit={handleWeeklyOccupancyReportModalSubmit}
      />
      <TimeframeMultipleClassReportModal
        isOpen={timeframeMultipleClassesModalState.open}
        reportName={
          timeframeMultipleClassesModalState.report
            ? getReadableReportName(timeframeMultipleClassesModalState.report.type)
            : ''
        }
        failedReportDownload={timeframeMultipleClassesModalState.failedReportDownload ?? null}
        resetFailedDownload={() =>
          setTimeframeChildSpaceModalState({ ...timeframeChildSpaceModalState, failedReportDownload: null })
        }
        isLoading={
          getNameToFaceReportLoading ||
          getNameToFacePdfReportLoading ||
          getChildCheckInReportLoading ||
          getChildWellnessReportLazyLoading ||
          getChildWellnessPdfReportLazyLoading ||
          getChildAttendanceSummaryLoading
        }
        hideArchivedClasses={hideArchivedClassesReportTypes.includes(timeframeMultipleClassesModalState.report?.type)}
        hideInactiveClasses={hideInactiveClassesReportType.includes(timeframeMultipleClassesModalState.report?.type)}
        onClose={() => setTimeframeMultipleClassesModalState({ open: false, report: null, failedReportDownload: null })}
        onSubmit={handleTimeframeMultipleClassReportSubmit}
        showReportFormat={
          timeframeMultipleClassesModalState.report?.type === ReportTypeEnum.CHILD_HEALTH_AND_WELLNESS ||
          timeframeMultipleClassesModalState.report?.type === ReportTypeEnum.NAME_TO_FACE
        }
        className={
          timeframeMultipleClassesModalState.report?.type === ReportTypeEnum.CHILD_HEALTH_AND_WELLNESS
            ? 'child-wellness-report'
            : ''
        }
        showConditions={timeframeMultipleClassesModalState.report?.type === ReportTypeEnum.CHILD_HEALTH_AND_WELLNESS}
        pastOnly={timeframeMultipleClassesModalState.report?.type === ReportTypeEnum.CHILD_CHECK_IN}
      />
      <TimeframeMultipleClassTagFilterReportModal
        isOpen={timeframeMultipleClassesTagFilterModalState.open}
        reportName={
          timeframeMultipleClassesTagFilterModalState.report
            ? getReadableReportName(timeframeMultipleClassesTagFilterModalState.report.type)
            : ''
        }
        failedReportDownload={timeframeMultipleClassesTagFilterModalState.failedReportDownload ?? null}
        resetFailedDownload={() =>
          setTimeframeChildSpaceModalState({ ...timeframeChildSpaceModalState, failedReportDownload: null })
        }
        entityId={user?.entityId ?? ''}
        isLoading={getChildAttendanceSummaryLoading}
        hideArchivedClasses={hideArchivedClassesReportTypes.includes(
          timeframeMultipleClassesTagFilterModalState.report?.type
        )}
        hideInactiveClasses={hideInactiveClassesReportType.includes(
          timeframeMultipleClassesTagFilterModalState.report?.type
        )}
        onClose={() =>
          setTimeframeMultipleClassesTagFilterModalState({ open: false, report: null, failedReportDownload: null })
        }
        onSubmit={handleTimeframeMultipleClassTagFilterReportSubmit}
      />
      <TimeframeMultipleEmployeeReportModal
        isOpen={timeframeMultipleEmployeesModalState.open}
        reportName={
          timeframeMultipleEmployeesModalState.report
            ? getReadableReportName(timeframeMultipleEmployeesModalState.report.type)
            : ''
        }
        isLoading={getEmployeeTimeSheetReportLoading || getEmployeeTimeSheetExcelReportLoading}
        onClose={() => setTimeframeMultipleEmployeesModalState({ open: false, report: null })}
        onSubmit={handleTimeframeMultipleEmployeeReportSubmit}
        showReportFormat
      />
      <TimeframeChildSpaceReportModal
        isOpen={timeframeChildSpaceModalState.open}
        reportName={
          timeframeChildSpaceModalState.report ? getReadableReportName(timeframeChildSpaceModalState.report.type) : ''
        }
        failedReportDownload={timeframeChildSpaceModalState.failedReportDownload ?? null}
        isLoading={getChildTrackingPdfReportLoading}
        resetFailedDownload={() =>
          setTimeframeChildSpaceModalState({ ...timeframeChildSpaceModalState, failedReportDownload: null })
        }
        onClose={() => setTimeframeChildSpaceModalState({ open: false, report: null, failedReportDownload: null })}
        onSubmit={handleTimeframeChildSpaceReportSubmit}
        showReportFormat={false}
      />
      <TimeframeSingleCenterReportModal
        isOpen={timeframeSingleCenterModalState.open}
        reportName={
          timeframeSingleCenterModalState.report
            ? getReadableReportName(timeframeSingleCenterModalState.report.type)
            : ''
        }
        isLoading={getContractEditsPdfReportLoading || getBillingEditsPdfReportLoading}
        onClose={() => setTimeframeSingleCenterModalState({ open: false, report: null })}
        onSubmit={handleTimeframeSingleCenterReportSubmit}
        showReportFormat={false}
      />
      <TimeLogNoticeModal
        show={showNoticeModal}
        proceedFnCallback={() => {
          setShowNoticeModal(false);
          setTimeframeReportModalState({ open: true, report: reportForTimeLogNotice! });
        }}
        cancelFnCallback={() => {
          setShowNoticeModal(false);
          setReportForTimeLogNotice(undefined);
        }}
      ></TimeLogNoticeModal>
      <CustomFieldsReportModal
        isOpen={customFieldsModalState.open}
        reportName={getReadableReportName('CUSTOM_FIELD_REPORT')}
        isLoading={getCustomFieldsReportLoading}
        onClose={() => setCustomFieldsModalState({ open: false, report: null })}
        onSubmit={handleCustomFieldsReportSubmit}
      />
    </>
  );
};

export default ReportingListView;
