import React from 'react';
import Checkbox from 'shared/components/Checkbox';
import DisabledOverlay from 'shared/components/Overlays/Disabled';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import { toProperCase } from 'shared/util/string';
import { OperationHoursType, ICenterOperationsAvailabilityTimes, ICenterOperationsDayAvailability } from './types';
import TimeRangePicker2 from 'shared/components/TimePicker/TimeRangePicker2';

interface IProps {
  title: string;
  data: ICenterOperationsAvailabilityTimes;
  operationsType: OperationHoursType;
  disabled: boolean;
  onTimeChange: (
    operationType: OperationHoursType,
    day: DayOfWeek,
    startTime: string | null,
    endTime: string | null
  ) => void;
  onDaySelect: (operationType: OperationHoursType, day: DayOfWeek, enabled: boolean) => void;
}

const WeekOperationHours: React.FC<IProps> = ({
  title,
  data,
  operationsType,
  onTimeChange,
  onDaySelect,
  disabled,
  ...props
}) => {
  const rowPadding: string = operationsType === 'staff' ? 'pr-4' : 'pl-4';

  return (
    <div>
      <span className={`d-inline-block mb-6 kt-center-operations-title-text ${rowPadding}`}>{title}</span>
      {
        // @ts-ignore - ignoring Type 'string' is not assignable to type 'DayOfWeek'.ts(2345)
        Object.keys(data).map((key: DayOfWeek, idx: number) => {
          const value: ICenterOperationsDayAvailability = data[key];

          return (
            <Row
              className={rowPadding + ' kt-center-operations-day-row align-items-center'}
              key={`${operationsType}-operation-hours-${key}`}
            >
              <Column lg={3} className="d-flex align-items-center mr-8">
                <Checkbox
                  label={toProperCase(key)}
                  value={value.dayEnabled}
                  onChange={(isChecked: boolean) => onDaySelect(operationsType, key, isChecked)}
                  disabled={disabled}
                />
              </Column>
              <Column lg>
                <DisabledOverlay show={!value.dayEnabled || disabled}>
                  <TimeRangePicker2
                    start={value.startTimeString ?? null}
                    end={value.endTimeString ?? null}
                    onChange={(startTime: string | null, endTime: string | null) =>
                      onTimeChange(operationsType, key, startTime, endTime)
                    }
                  />
                </DisabledOverlay>
              </Column>
            </Row>
          );
        })
      }
    </div>
  );
};

export default WeekOperationHours;
