import React, { useState, useCallback } from 'react';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import Tag from 'shared/components/Tag';
import { useGetStaffTagOptions } from 'gql/staff/queries';
import { useUpdateStaffTags } from 'gql/staff/mutations';
import { Row } from 'shared/components/Layout';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import Alert from 'shared/components/Alert';
import errorMessage from 'shared/constants/errorMessages';
import { showToast } from 'shared/components/Toast';

interface IProps {
  staff: IStaff;
  readOnly?: boolean;
}

const TagsForm: React.FC<IProps> = ({ staff, readOnly = false }) => {
  const { data } = useGetStaffTagOptions(staff.entityId);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [tags, setTags] = useState(staff.tags || []);
  const resetTags = useCallback(() => setTags(staff.tags || []), [staff.tags]);
  const tagOptions: ITag[] = (data && data.getStaffTagOptions) || [];
  const selectedTagIds: string[] = tags.map((tag) => tag.id);
  const unselectedTags: ITag[] = tagOptions.filter((tag) => !selectedTagIds.includes(tag.id));
  const unselectedTagIds: string[] = unselectedTags.map((tag) => tag.id);
  const addTag = useCallback((selectedTag) => setTags([...tags, selectedTag]), [tags]);
  const removeTag = useCallback((selectedTag) => setTags(tags.filter((tag) => tag.id !== selectedTag.id)), [tags]);
  const [updateStaffTags, { loading, error }] = useUpdateStaffTags();
  const save = useCallback(() => {
    updateStaffTags(staff, selectedTagIds, unselectedTagIds).then(() => {
      showToast('Tags updated successfully.', 'success');
      setFormIsDirty(false);
    });
  }, [updateStaffTags, staff, selectedTagIds, unselectedTagIds]);

  if (error) {
    return <Alert variant="danger">{errorMessage.generic}</Alert>;
  }
  if (loading) {
    return <LoadingLines number={2} />;
  }

  return (
    <FormWrapper2 formIsDirty={formIsDirty} toggleDirty={setFormIsDirty} onCancel={resetTags} onSave={save}>
      <small>Select a tag to apply it to your employee. Deselect a tag to remove it.</small>
      {tags && (
        <Row noGutters className="mx-n1 mt-4">
          {tags.map((tag, i) => (
            <div key={i} className="px-1 mb-2">
              <Tag
                editState="SELECTED"
                tag={tag}
                onClick={() => {
                  if (!readOnly) {
                    setFormIsDirty(true);
                    removeTag(tag);
                  }
                }}
              />
            </div>
          ))}
          {unselectedTags.map((tag, i) => (
            <div key={i} className="px-1 mb-2">
              <Tag
                editState="DESELECTED"
                tag={tag}
                onClick={() => {
                  if (!readOnly) {
                    setFormIsDirty(true);
                    addTag(tag);
                  }
                }}
              />
            </div>
          ))}
        </Row>
      )}
    </FormWrapper2>
  );
};

export default TagsForm;
