import React, { useState } from 'react';
import { Row, Col } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import { useTranslation } from 'react-i18next';
import DateInput from 'shared/components/DateInput';
import Form from 'react-bootstrap/Form';
import { TimeRange, ClosureResolution, IFormData } from '../types';
import { useGetSessionCountByTimeFrame } from 'gql/casualBooking/queries';
import { isFormDataValid } from '../helpers';
import useUniqueId from 'shared/hooks/useUniqueId';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from 'shared/components/Buttons';
import classnames from 'classnames';
import Line from 'shared/components/LoadingSkeletons/Line';
import { isDateRangeInvalid } from 'shared/util/timeUtils';
import MultipleClassSelect from 'shared/components/Select/MultipleClassSelect';
import moment from 'moment';

interface IManageClosuresFormProps {
  formData: IFormData;
  setFormData: (formData: IFormData) => void;
  isRemovable: boolean;
  removeFormData: () => void;
  centerId: string;
}

const ManageClosuresForm = ({
  formData,
  setFormData,
  isRemovable,
  removeFormData,
  centerId,
}: IManageClosuresFormProps) => {
  const { t } = useTranslation();
  const id = useUniqueId();
  const isInputValid = isFormDataValid(formData);

  const [isClassSelectMenuOpen, setIsClassSelectMenuOpen] = useState(false);

  const { data, loading } = useGetSessionCountByTimeFrame({
    variables: {
      input: {
        centerId,
        // the GQL layer can optimize BE request if passing null to represent all classIds
        classIds: formData.isAllClassesSelected ? null : formData.classIds,
        startDate: formData.startDate,
        endDate: formData.timeRange === TimeRange.Single ? formData.startDate : formData.endDate ?? '',
      },
    },
    // because the class selector is a multiple selector
    // so we avoid unnecessary api calls if the select menu is still open otherwise a api call will be triggered on every option click
    skip: !isInputValid || isClassSelectMenuOpen,
  });

  const invalidDateframe =
    formData.timeRange === TimeRange.Multiple
      ? Boolean(isDateRangeInvalid(formData.startDate, formData.endDate))
      : false;

  return (
    <div className="px-5 bg-white py-3 px-5 mb-2">
      {isRemovable && (
        <Row justify="end" noGutters>
          <IconButton
            icon={faTimes}
            onClick={() => removeFormData()}
            className=""
            tooltipDirection="bottom"
            tooltipText="Remove"
          />
        </Row>
      )}
      <Row className={classnames({ 'pt-6': !isRemovable })}>
        <Col md={4}>
          <MultipleClassSelect
            required
            selectedClassIds={formData.classIds}
            useNullForAllOption={false}
            onSelect={(classIds, isAllOptionSelected) => {
              setFormData({ ...formData, classIds: classIds ?? [], isAllClassesSelected: isAllOptionSelected });
            }}
            centerId={centerId}
            disableAllOption={false}
            label={t('attendance.manage-closures.classes-to-close')}
            onMenuOpen={() => setIsClassSelectMenuOpen(true)}
            onMenuClose={() => setIsClassSelectMenuOpen(false)}
            className="mb-0"
          />
        </Col>
        <Col md={2}>
          <Select
            required
            label={t('attendance.manage-closures.time-range')}
            options={Object.values(TimeRange).map((timeRange) => timeRange)}
            value={formData.timeRange}
            onChange={(v) => setFormData({ ...formData, timeRange: v })}
            className="mb-0"
          />
        </Col>
        <Col md={3}>
          <DateInput
            required
            label={t('attendance.manage-closures.close-start-date')}
            date={formData.startDate}
            isValid={!invalidDateframe}
            onDateSelect={(input) =>
              setFormData({
                ...formData,
                startDate: input,
              })
            }
            className="kt-date-input-no-max-width"
            dateOnly
            isOutsideRange={(date: moment.Moment) => date.isBefore(moment().startOf('day'))}
          />
        </Col>
        <Col md={3}>
          <DateInput
            required={formData.timeRange === TimeRange.Multiple}
            disabled={formData.timeRange === TimeRange.Single}
            label={t('attendance.manage-closures.close-end-date')}
            date={formData.endDate}
            isValid={!invalidDateframe}
            onDateSelect={(input) =>
              setFormData({
                ...formData,
                endDate: input,
              })
            }
            className="kt-date-input-no-max-width"
            dateOnly
            isOutsideRange={(date: moment.Moment) => date.isBefore(moment().startOf('day'))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              {loading ? (
                <Line className="w-100" />
              ) : (
                t('attendance.manage-closures.existing-bookings-found', {
                  count: data?.getSessionCountByTimeFrame ?? 0,
                })
              )}
            </Form.Label>
            <Form.Check
              inline
              label={t('attendance.manage-closures.mark-students-absent', { count: 5 })}
              name={`closure-resolution-${id}`}
              id={`ratio-${ClosureResolution.ABSENT}-${id}`}
              type="radio"
              checked={formData.closureResolution === ClosureResolution.ABSENT}
              onChange={() => setFormData({ ...formData, closureResolution: ClosureResolution.ABSENT })}
            />
            <Form.Check
              inline
              label={t('attendance.manage-closures.clear-bookings')}
              name={`closure-resolution-${id}`}
              id={`ratio-${ClosureResolution.CLEAR}-${id}`}
              type="radio"
              checked={formData.closureResolution === ClosureResolution.CLEAR}
              onChange={() => setFormData({ ...formData, closureResolution: ClosureResolution.CLEAR })}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default ManageClosuresForm;
