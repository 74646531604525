import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RBCard from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { RootState } from 'store/reducers';
import { useGetStaff } from 'shared/hooks/useGetPerson';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import { CreateButton } from 'shared/components/Buttons';
import { GET_STAFF, useGetTimeSheetsForPerson, useGetTimeOffForPerson } from './graphql/queries';
import ControlHeader from './components/ControlHeader';
import PeriodTotalsCard from './components/PeriodTotalsCard';
import WeekTimeSheetCard from './components/WeekTimeSheetCard';
import WeekTimeSheetLoader from './components/WeekTimeSheetCard/WeekTimeSheetLoader';
import cast from 'shared/util/cast';
import { groupAttendanceByWeek, calculateTrainingHours, calculateTotalTimeOffHours } from './utils/time';
import { useUpdateTimeEntry } from './graphql/mutations';
import { showToast } from 'shared/components/Toast';
import NewTimeEntryModal from './components/NewTimeEntryModal';
import DataCardsRow from './components/DataCardsRow';
import { getFullName } from 'shared/util/string';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { RoleLevelType, AreaType, PermissionType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {}

export interface ITimeframeShape {
  start: moment.Moment | null;
  end: moment.Moment | null;
  viewType: 'WEEK' | 'CUSTOM';
}

interface ILocationShape {
  personId: string;
  date?: string;
}

const EmployeeTimeSheets: React.FC<IProps> = ({ ...props }) => {
  const {
    state: { personId, date },
  } = useLocation<ILocationShape>();
  const user = useSelector((state: RootState) => state.user);
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const [timeframe, setTimeframe] = useState<ITimeframeShape>({
    start: moment(date).startOf('week') ?? moment().startOf('week'),
    end: moment(date).endOf('week') ?? moment().endOf('week'),
    viewType: 'WEEK',
  });
  const [newTimeEntryModalOpen, setNewTimeEntryModalOpen] = useState(false);
  const [filteredCenter, setFilteredCenter] = useState<ICenter | null>(null);
  const [updateTimeEntryFn, { loading: updateTimeEntryLoading }] = useUpdateTimeEntry();
  const { loading: getStaffLoading, data: getStaffData } = useGetStaff(personId, GET_STAFF);
  const scopeId = getStaffData?.getStaff.primaryCenterId ?? getStaffData?.getStaff.entityId ?? '';
  const scopeType = getStaffData?.getStaff.roleship.scopeType || 'CENTER';
  const {
    loading: getTimeSheetsForPersonLoading,
    data: getTimeSheetsForPersonData,
    refetch: refetchTimeSheetsForPerson,
  } = useGetTimeSheetsForPerson({
    personId,
    startDate: (timeframe.start ?? moment().startOf('week')).format('YYYY-MM-DD'),
    endDate: (timeframe.end ?? moment().endOf('week')).format('YYYY-MM-DD'),
  });
  const {
    loading: getTimeOffRequestsLoading,
    data: getTimeOffRequestsData,
    refetch: refetchTimeOffRequests,
  } = useGetTimeOffForPerson(
    (timeframe.start ?? moment().startOf('week')).format('YYYY-MM-DD'),
    (timeframe.end ?? moment().endOf('week')).format('YYYY-MM-DD'),
    scopeId,
    scopeType
  );

  const hasCanEditPermission = useHasRoleAreaLevel({
    area: AreaType.Schedule,
    permission: PermissionType.TimeLog,
    level: RoleLevelType.Edit,
  });

  useEffect(() => {
    if (timeframe.start && timeframe.end && personId) {
      refetchTimeSheetsForPerson({
        input: {
          personId,
          startDate: timeframe.start.format('YYYY-MM-DD'),
          endDate: timeframe.end.format('YYYY-MM-DD'),
        },
      });

      refetchTimeOffRequests({
        input: {
          scopeType,
          scopeId,
          startTime: timeframe.start.format('YYYY-MM-DD'),
          endTime: timeframe.end.format('YYYY-MM-DD'),
        },
      });
    }
  }, [timeframe, personId, filteredCenter, refetchTimeSheetsForPerson, refetchTimeOffRequests, scopeId, scopeType]);

  useEffect(() => {
    if (getStaffData?.getStaff) {
      if (
        cast<IStaff>(getStaffData.getStaff).roleship.scopeType === 'CENTER' &&
        cast<IStaff>(getStaffData.getStaff).roleship.scopes.length === 1
      ) {
        setFilteredCenter(cast<ICenter[]>(cast<IStaff>(getStaffData.getStaff).roleship.scopes)[0]);
      }
    }
  }, [getStaffData]);

  // the api returns the total as hours and minutes separately but the ui calls for a decimal to be displayed, ex: 80.4
  const calculateAttendanceTimeTotal = useCallback((hours: number, minutes: number): number => {
    const decimalMinutes = minutes / 60;

    return hours + decimalMinutes;
  }, []);

  const calculateTotalsForTimeframe = useCallback(
    (attendance: IAttendance[], type: 'scheduled' | 'worked'): number => {
      const hoursProp = type === 'scheduled' ? 'totalHoursScheduled' : 'totalHoursWorked';
      const totalHours = attendance.reduce((acc, curr) => (acc += curr[hoursProp].hours), 0);
      const totalMinutes = attendance.reduce((acc, curr) => (acc += curr[hoursProp].minutes), 0);

      return calculateAttendanceTimeTotal(totalHours, totalMinutes);
    },
    [calculateAttendanceTimeTotal]
  );

  const getCenterFilterOptions = useCallback((): ICenter[] => {
    if (getStaffData?.getStaff) {
      if (cast<IStaff>(getStaffData.getStaff).roleship.scopeType === 'CENTER') {
        return cast<ICenter[]>(cast<IStaff>(getStaffData.getStaff).roleship.scopes);
      } else {
        // business level scope will only have one scope (the business)
        return cast<IBusiness>(cast<IStaff>(getStaffData.getStaff).roleship.scopes[0]).centers;
      }
    }

    return [];
  }, [getStaffData]);

  const saveTimeEntryUpdates = useCallback(
    async (updates: Record<string, ITimeEntry>) => {
      return Promise.all(
        Object.entries(updates).map(([timeEntryId, timeEntry]) => {
          const timezone = timezonesByCenter[timeEntry.centerId] ?? momentTz.tz.guess();

          return updateTimeEntryFn({
            variables: {
              input: {
                id: timeEntryId,
                centerId: timeEntry.centerId,
                positionId: timeEntry.positionId,
                trackedTimeIn: momentTz(timeEntry.trackedTimeIn).tz(timezone).toISOString(),
                trackedTimeOut: timeEntry.trackedTimeOut
                  ? momentTz(timeEntry.trackedTimeOut).tz(timezone).toISOString()
                  : undefined,
                type: timeEntry.type,
                note: timeEntry.note ?? null,
              },
            },
          });
        })
      )
        .then(() => {
          refetchTimeSheetsForPerson();
          showToast('Updates saved successfully.', 'success');
        })
        .catch(() => {
          showToast('There was an error updating your time entries.', 'error');
        });
    },
    [refetchTimeSheetsForPerson, updateTimeEntryFn, timezonesByCenter]
  );

  const approvedTimeOffRequests =
    getTimeOffRequestsData?.getTimeOffRequestsByScope.filter(
      (req) => req.personId === personId && req.status === 'Approved'
    ) ?? [];
  const timeOffHours = calculateTotalTimeOffHours(approvedTimeOffRequests);

  return (
    <ProfilePageWrapper
      title={getFullName(getStaffData?.getStaff)}
      loading={getStaffLoading}
      readOnlyAvatar
      avatarLoading={getStaffLoading}
      avatarUrl={getStaffData?.getStaff.avatar?.url ?? ''}
      buttonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Schedule, permission: PermissionType.TimeLog, level: RoleLevelType.Create }}
        >
          {filteredCenter ? (
            <CreateButton onClick={() => setNewTimeEntryModalOpen(true)}>Add Time Entry</CreateButton>
          ) : null}
        </HasRoleAreaLevel>
      }
    >
      <Container fluid>
        <ControlHeader
          timeframe={timeframe}
          selectedCenterId={filteredCenter?.id ?? null}
          centerSelectOptions={getCenterFilterOptions()}
          updateTimeframe={setTimeframe}
          onCenterSelect={setFilteredCenter}
        />
        <DataCardsRow
          loading={getTimeSheetsForPersonLoading || getTimeOffRequestsLoading}
          trainingHours={calculateTrainingHours(getTimeSheetsForPersonData?.getAttendance ?? []).toFixed(2)}
          paidTimeOffHours={timeOffHours.paid.toFixed(2)}
          unpaidTimeOffHours={timeOffHours.unpaid.toFixed(2)}
        />
        <PeriodTotalsCard
          scheduled={calculateTotalsForTimeframe(getTimeSheetsForPersonData?.getAttendance ?? [], 'scheduled')}
          actual={calculateTotalsForTimeframe(getTimeSheetsForPersonData?.getAttendance ?? [], 'worked')}
        />
        {getTimeSheetsForPersonLoading ? (
          <WeekTimeSheetLoader />
        ) : (
          groupAttendanceByWeek(getTimeSheetsForPersonData?.getAttendance)
            .filter((attendance) => (filteredCenter ? attendance.centerId === filteredCenter.id : attendance))
            .map((attendance: IAttendance, idx: number) => (
              <WeekTimeSheetCard
                key={`time-sheet-${attendance.centerId}-${idx}`}
                attendance={attendance}
                canPerformEdit={hasCanEditPermission}
                savingTimeEntryUpdates={updateTimeEntryLoading}
                saveTimeEntryUpdates={saveTimeEntryUpdates}
                staffPositions={getStaffData?.getStaff.positions ?? []}
              />
            ))
        )}
        {!getTimeSheetsForPersonLoading && getTimeSheetsForPersonData?.getAttendance.length === 0 && (
          <RBCard className="mx-0 mt-0 mb-4">
            <RBCard.Body className="px-8 py-4">No data found for selected timeframe.</RBCard.Body>
          </RBCard>
        )}
      </Container>
      {filteredCenter && getStaffData?.getStaff && (
        <NewTimeEntryModal
          refetchAttendance={refetchTimeSheetsForPerson}
          isOpen={newTimeEntryModalOpen}
          onClose={() => setNewTimeEntryModalOpen(false)}
          person={getStaffData?.getStaff}
          selectedCenter={filteredCenter}
          centerId={filteredCenter.id}
          entityId={getStaffData?.getStaff.entityId}
        />
      )}
    </ProfilePageWrapper>
  );
};

export default EmployeeTimeSheets;
