import React from 'react';
import moment from 'moment';
import Tooltip from 'shared/components/Tooltip';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  timeOff: ITimeOff;
  centerTimezone: string;
}

const TimeOffBlock: React.FC<IProps> = ({ timeOff, centerTimezone, ...props }) => {
  const tooltipText = timeOff.allDay
    ? 'All Day'
    : `${moment(timeOff.startTime).tz(centerTimezone).format('h:mma')} - ${moment(timeOff.endTime)
        .tz(centerTimezone)
        .format('h:mma')}`;

  return (
    <Tooltip direction="top" text={tooltipText}>
      <div {...props} className="time-entry time-off">
        <span className="ml-4">Time Off</span>
      </div>
    </Tooltip>
  );
};

export default TimeOffBlock;
