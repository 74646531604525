import React, { useCallback, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import PageWrapper from 'shared/components/PageWrapper';
import Badge from 'shared/components/Badge';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AgencyPaymentsTable from './AgencyPaymentsTable';
import { useGetPaginatedAgencyPaymentsForBusiness } from 'gql/agency/queries';
import { getAgencyPayments } from './duck/actions';
import DeleteAgencyPaymentModal from './DeleteAgencyPaymentModal';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

type AgencyBillingTabs = 'completed' | 'drafts';

interface IDeleteAgencyPaymentModalState {
  open: boolean;
  payment: IAgencyPayment | null;
}

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const AgencyBilling: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['subsidies']);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const [activeTab, setActiveTab] = useState<AgencyBillingTabs>('completed');
  const [deleteModal, setDeleteModal] = useState<IDeleteAgencyPaymentModalState>({ open: false, payment: null });

  const agencyPaymentsTableData = useSelector((state: RootState) => state.billing.agencyPayments.tableData);
  const agencyPaymentsTableFilters = useSelector((state: RootState) => state.billing.agencyPayments.tableFilters);

  const hasCreateAgencyBillingPaymentPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Create,
  });

  const { loading: getPaginatedAgencyPaymentsForBusinessLoading } = useGetPaginatedAgencyPaymentsForBusiness({
    variables: {
      completedInput: {
        businessId: currentBusinessId ?? '',
        searchKey: agencyPaymentsTableFilters.searchTerm,
        sortBy: agencyPaymentsTableFilters.sortColumn,
        sortDirection: agencyPaymentsTableFilters.sortDirection,
        pageNumber: agencyPaymentsTableFilters.completed.pageNumber,
        pageSize: agencyPaymentsTableFilters.completed.pageSize,
        agencyIds: agencyPaymentsTableFilters.agencyIds,
        centerIds: agencyPaymentsTableFilters.centerIds,
        start: agencyPaymentsTableFilters.startDate,
        end: agencyPaymentsTableFilters.endDate,
      },
      draftInput: {
        businessId: currentBusinessId ?? '',
        searchKey: agencyPaymentsTableFilters.searchTerm,
        sortBy: agencyPaymentsTableFilters.sortColumn,
        sortDirection: agencyPaymentsTableFilters.sortDirection,
        pageNumber: agencyPaymentsTableFilters.draft.pageNumber,
        pageSize: agencyPaymentsTableFilters.draft.pageSize,
        agencyIds: agencyPaymentsTableFilters.agencyIds,
        centerIds: agencyPaymentsTableFilters.centerIds,
        start: agencyPaymentsTableFilters.startDate,
        end: agencyPaymentsTableFilters.endDate,
      },
    },
    skip: !currentBusinessId,
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      dispatch(
        getAgencyPayments(
          result.getPaginatedAgencyPaymentsForBusiness.completed,
          result.getPaginatedAgencyPaymentsForBusiness.draft
        )
      );
    },
  });

  const navigateToAgencyPageView = useCallback(
    (id: string = '') => {
      if (id) {
        history.push(`/subsidies/billing/payment/${id}`);
      } else {
        history.push(`/subsidies/billing/payment`);
      }
    },
    [history]
  );

  return (
    <PageWrapper
      pageTitle={t('subsidies:agency-billing.page-title')}
      mobileButtonComponent={
        <CirclePlusButton variant="primary" className="my-4" onClick={() => navigateToAgencyPageView()} />
      }
      buttonComponent={
        hasCreateAgencyBillingPaymentPermission && (
          <CreateButton id="create-agency-btn" onClick={() => navigateToAgencyPageView()}>
            {t('subsidies:agency-billing.create-button-text')}
          </CreateButton>
        )
      }
    >
      {user?.isInternal && <CenterSelectBanner pageName="agencies" showCenterSelect={false} />}
      <Tabs activeKey={activeTab} onSelect={(key) => setActiveTab(key as AgencyBillingTabs)}>
        <Tab eventKey="completed" title="Completed">
          <AgencyPaymentsTable
            draftPaymentsOnly={false}
            businessId={currentBusinessId ?? ''}
            pagedTableData={agencyPaymentsTableData.completed}
            isLoading={getPaginatedAgencyPaymentsForBusinessLoading}
            onView={(payment) => navigateToAgencyPageView(payment.id)}
          />
        </Tab>
        <Tab
          eventKey="drafts"
          title={
            <div className="d-flex align-items-center">
              Drafts <Badge count={agencyPaymentsTableData.draft.totalRecords} variant="pill" className="ml-2" />
            </div>
          }
        >
          <AgencyPaymentsTable
            draftPaymentsOnly
            businessId={currentBusinessId ?? ''}
            pagedTableData={agencyPaymentsTableData.draft}
            isLoading={getPaginatedAgencyPaymentsForBusinessLoading}
            onView={(payment) => navigateToAgencyPageView(payment.id)}
            onEdit={(payment) => navigateToAgencyPageView(payment.id)}
            onDelete={(payment) => setDeleteModal({ open: true, payment })}
          />
        </Tab>
      </Tabs>
      {deleteModal.open && (
        <DeleteAgencyPaymentModal
          isOpen={deleteModal.open}
          agencyPayment={deleteModal.payment as IAgencyPayment}
          onClose={() => setDeleteModal({ open: false, payment: null })}
        />
      )}
    </PageWrapper>
  );
};

export default AgencyBilling;
