import React from 'react';
import { faBars, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import config from 'config';
// import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import Navbar from 'react-bootstrap/Navbar';
import { IconButton } from 'shared/components/Buttons';
// import TextInput from 'shared/components/TextInput';
import { VerticalDivider } from 'shared/components/Dividers';
import { IRoute } from 'shared/util/routes';
import { isRegion } from 'shared/util/region';
import ProfileMenu from '../ProfileDropdownMenu';
import K2MobileMenu from '../K2MobileMenu';
import NotificationsDropdownV2 from '../NotificationsDropdownV2';

interface IProps {
  mobileMenuExpanded: boolean;
  expandSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  expandMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  mobileMenuRoutes: IRoute[];
  navigateTo: (route: IRoute) => void;
}

const K2TopBar: React.FC<IProps> = ({
  mobileMenuExpanded,
  expandSidebar,
  expandMobileMenu,
  mobileMenuRoutes,
  navigateTo,
  ...props
}) => {
  return (
    <Navbar
      className="k2-navigation-topbar"
      variant="dark"
      bg="white"
      expand="lg"
      expanded={mobileMenuExpanded}
      sticky="top"
    >
      <div className="d-none d-lg-block ml-6">
        <IconButton icon={faBars} onClick={() => expandSidebar((prev) => !prev)} />
      </div>
      <Navbar.Toggle
        as="div"
        onClick={() => expandMobileMenu((prev) => !prev)}
        aria-controls="kangarootime-navbar-collapse-nav"
      >
        <IconButton icon={faBars} />
      </Navbar.Toggle>
      <div className="ml-md-12 d-flex flex-grow-1 align-content-center">
        {/* <div className='align-self-center k2-navigation-topbar-input-container'>
          <TextInput
            className='mb-0'
            placeholder='Search'
          />
        </div> */}
        <div className="d-flex flex-grow-1 align-items-center justify-content-end">
          {/* <VerticalDivider /> */}
          {/* <IconButton icon={faEnvelope} className='mr-6' /> */}
          {isRegion('AU') && (
            <>
              <IconButton
                icon={faInfoCircle}
                onClick={() => {
                  window.open(config.knowledgeLibrary.apiUrl, '_blank');
                }}
              />
              <VerticalDivider />
            </>
          )}
          <NotificationsDropdownV2 />
          <VerticalDivider />
          <ProfileMenu closeDrawer={() => {}} />
        </div>
      </div>
      <K2MobileMenu routes={mobileMenuRoutes} navigateTo={navigateTo} toggleMobileMenu={expandMobileMenu} />
    </Navbar>
  );
};

export default K2TopBar;
