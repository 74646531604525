import React, { useState, useCallback } from 'react';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import moment from 'moment';
import AvailabilityInputs from 'shared/components/AvailabilityInputs';
import {
  IAvailabilityObject,
  emptyWeek,
  areTimesOverlapped,
} from 'shared/components/AvailabilityInputs/AvailabilityInputs';
import { useUpdateMyAvailability } from 'pages/MyProfile/graphql/mutations';
import { showToast } from 'shared/components/Toast';
import { isValid24HourString } from 'shared/util/timeUtils';

interface IProps {
  staff: IStaff;
}

const AvailabilityForm: React.FC<IProps> = ({ staff }) => {
  const currentStaffAvailabilityObject: IAvailabilityObject = staff.availability?.approved.reduce(
    (prevVal, dayAvailability) => ({
      ...prevVal,
      [dayAvailability.dayOfWeek]: {
        ...dayAvailability,
        dayEnabled: true,
        times: dayAvailability.times.map((time) => ({
          start: moment()
            .hours(parseInt(time.start.split(':')[0]))
            .minutes(parseInt(time.start.split(':')[1]))
            .seconds(0),
          end: moment()
            .hours(parseInt(time.end.split(':')[0]))
            .minutes(parseInt(time.end.split(':')[1]))
            .seconds(0),
        })),
      },
    }),
    {}
  );

  const [availability, updateAvailability] = useState<IAvailabilityObject>({
    ...emptyWeek,
    ...currentStaffAvailabilityObject,
  });
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [saveAvailability, { loading }] = useUpdateMyAvailability();

  const save = useCallback(() => {
    saveAvailability({
      variables: {
        input: {
          days: Object.values(availability)
            .filter((a) => a.dayEnabled)
            .map((a) => ({
              dayOfWeek: a.dayOfWeek,
              times: a.times.map((t) => ({ start: t.start?.format('HH:mm'), end: t.end?.format('HH:mm') })),
            })),
        },
      },
    })
      .then(() => {
        showToast('Availability updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch(() => {
        showToast('There was an error updating your availability. Please try again later.', 'error');
      });
  }, [availability, saveAvailability]);

  const overlappingTimeRange = Object.values(availability).some((a) =>
    a.times.some((t1, i1) => a.times.some((t2, i2) => i1 !== i2 && areTimesOverlapped(t1, t2)))
  );
  const invalidTimeRange = Object.values(availability).some((a) => a.times.some((t) => t.start?.isAfter(t.end)));
  const invalidTimeStrings = Object.values(availability).some((a) => {
    console.log(a.times);
    a.times.some((t) => !isValid24HourString(t.startTimeString) || !isValid24HourString(t.endTimeString));
  });
  console.log('overlappingTimeRange', overlappingTimeRange);
  console.log('invalidTimeRange', invalidTimeRange);
  console.log('invalidTimeStrings', invalidTimeStrings);

  const formInvalid = overlappingTimeRange || invalidTimeRange || invalidTimeStrings;

  return (
    <FormWrapper2
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onSave={save}
      onCancel={() => {
        updateAvailability({ ...emptyWeek, ...currentStaffAvailabilityObject });
      }}
      loading={loading}
      saveDisabled={formInvalid}
      toggleDirtyOnSave={false}
    >
      <h6 className="sans-serif font-weight-semi-bold mb-2">Submit your workday availability to your supervisor.</h6>
      <p className="sm">Select (+) for split shifts, if needed.</p>
      <AvailabilityInputs
        availability={availability}
        updateAvailability={updateAvailability}
        onChange={() => setFormIsDirty(true)}
      />
    </FormWrapper2>
  );
};

export default AvailabilityForm;
