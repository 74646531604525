import React, { useCallback } from 'react';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import relationships from 'shared/constants/enums/RelationshipEnum';
import { isBlank } from 'shared/util/string';
import PhoneNumberAndTypeInput from '../PhoneNumberAndTypeInput';

const relationshipOptions = Object.values(relationships);

interface IProps {
  emergencyContact: IEmergencyContact;
  updateEmergencyContact: (emergencyContact: IEmergencyContact) => void;
  readOnly?: boolean;
  required?: boolean;
}

const EmergencyContactInput: React.FC<IProps> = ({
  emergencyContact,
  updateEmergencyContact,
  readOnly = false,
  required = false,
}) => {
  const handleChange = useCallback(
    (value, name) => {
      updateEmergencyContact({ ...emergencyContact, [name]: value });
    },
    [emergencyContact, updateEmergencyContact]
  );

  const isOtherRelationship =
    !isBlank(emergencyContact.relationship) && !relationshipOptions.includes(emergencyContact.relationship);

  const handlePhoneNumberChange = (value: IPhoneNumber) => {
    const { number, type } = value;
    updateEmergencyContact({ ...emergencyContact, phoneNumber: number, phoneNumberType: type });
  };
  return (
    <div>
      <Row>
        <Col md>
          <TextInput
            required={required}
            label="First Name"
            name="emergency-contact-fn"
            value={emergencyContact.firstname}
            onChange={(value: string) => handleChange(value, 'firstname')}
            disabled={readOnly}
          />
        </Col>
        <Col md>
          <TextInput
            required={required}
            label="Last Name"
            name="emergency-contact-ln"
            value={emergencyContact.lastname}
            onChange={(value: string) => handleChange(value, 'lastname')}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Row align="end">
            <Col md>
              <Select
                required={required}
                name="relationship"
                label="Relationship"
                options={[...relationshipOptions, 'Other']}
                value={isOtherRelationship ? 'Other' : emergencyContact.relationship}
                onChange={handleChange}
                disabled={readOnly}
              />
            </Col>
            {isOtherRelationship && (
              <Col md>
                <TextInput
                  placeholder="Specify"
                  name="relationship"
                  value={emergencyContact.relationship === 'Other' ? '' : emergencyContact.relationship}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col lg={12}>
          <PhoneNumberAndTypeInput
            phoneNumber={{
              number: emergencyContact.phoneNumber,
              type: emergencyContact.phoneNumberType,
            }}
            updatePhoneNumber={handlePhoneNumberChange}
            required={required}
            label="Phone Number"
            extraDiv={false}
            showErrorMessage={false}
            disabled={readOnly}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EmergencyContactInput;
