import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import Checkbox from 'shared/components/Checkbox';
import DateInput from 'shared/components/DateInput';
import { FileUploadDropbox } from 'shared/components/FileOperations';
import FormLabel from 'shared/components/Form/FormLabel';
import { Col, Row } from 'shared/components/Layout';
import useK2Upload from 'shared/hooks/useK2Upload';
import { emptyK2File } from 'shared/util/file';
import ImportInstruction from '../ImportInstruction';
import UploadButton from '../UploadButton';
interface IProps extends IImportStepProps {
  importHistory: (data: IQikKidsHistoryVariables) => void;
}

const HistoryImport: React.FC<IProps> = ({ importHistory, center, loading, completed = false, shouldReset }) => {
  const { t } = useTranslation('ktAdmin');
  const [isDryRun, setDryRun] = useState(true);
  const [date, setDate] = useState('');

  useEffect(() => {
    if (shouldReset) setDate('');
  }, [shouldReset]);

  const { files: careTypeFiles, setFiles: setCareTypeFiles, k2File: careTypeK2File } = useK2Upload(shouldReset);
  const {
    files: transactionsFiles,
    setFiles: setTransactionFiles,
    k2File: transactionsK2File,
  } = useK2Upload(shouldReset);
  const { files: sessionsFiles, setFiles: setSessionsFiles, k2File: sessionsK2File } = useK2Upload(shouldReset);
  const {
    files: futureSessionsFiles,
    setFiles: setFutureSessionFiles,
    k2File: futureSessionsK2File,
  } = useK2Upload(shouldReset);

  const handleUpload = () => {
    importHistory({
      businessId: center?.entityId || '',
      centerId: center?.id || '',
      careTypes: careTypeK2File || emptyK2File,
      sessions: sessionsK2File || emptyK2File,
      transactions: transactionsK2File || emptyK2File,
      futureSessions: futureSessionsK2File || emptyK2File,
      zeroBalanceDate: date ? date : null,
      dryRun: isDryRun,
    });
  };

  const selectedFiles =
    careTypeFiles.length + sessionsFiles.length + transactionsFiles.length + futureSessionsFiles.length;

  return (
    <Card
      header={t('data-importer.step-number', {
        number: 4,
        title: t('data-importer.history'),
      })}
    >
      <ImportInstruction isDryRun={isDryRun} setDryRun={setDryRun} />

      <DateInput label={t('data-importer.zero-balance-date')} date={date} onDateSelect={(date) => setDate(date)} />
      <Row className="mb-4">
        <Col>
          <FormLabel required>{t('data-importer.care-type')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setCareTypeFiles}
            uploadedFiles={careTypeFiles}
            setUploadedFiles={setCareTypeFiles}
            withProgress={false}
          />
        </Col>
        <Col>
          <FormLabel required>{t('data-importer.sessions')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setSessionsFiles}
            uploadedFiles={sessionsFiles}
            setUploadedFiles={setSessionsFiles}
            withProgress={false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormLabel required>{t('data-importer.transactions')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setTransactionFiles}
            uploadedFiles={transactionsFiles}
            setUploadedFiles={setTransactionFiles}
            withProgress={false}
          />
        </Col>
        <Col>
          <FormLabel required>{t('data-importer.future-sessions')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setFutureSessionFiles}
            uploadedFiles={futureSessionsFiles}
            setUploadedFiles={setFutureSessionFiles}
            withProgress={false}
          />
        </Col>
      </Row>

      <UploadButton
        onClick={handleUpload}
        selectedFiles={selectedFiles}
        totalFiles={4}
        disabled={selectedFiles < 4}
        completed={completed}
        loading={loading}
      />
    </Card>
  );
};

export default HistoryImport;
