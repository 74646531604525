import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import Button, { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { debounce } from 'lodash';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTranslation } from 'react-i18next';
import useGetActiveCenters, { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import moment from 'moment';
import { showToast } from 'shared/components/Toast';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useGetChannelActivity, useGetStudentChannels } from 'gql/communications/queries';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faNote, faNoteSticky, faPlus, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import AddMessageForm from './components/AddMessageForm';
import _ from 'lodash';
import ChannelMessagesTable from './components/ChannelMessagesTable';
import ChannelStatisticsBar from './components/ChannelStatisticsBar';
import Avatar from 'shared/components/Avatar';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import colors from '_colors.module.scss';
import { ActivityType, ChannelType, useCreateChatActivityMutation } from 'generated/graphql';
import User from 'shared/types/user';

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
};

interface IRouteProps {
  centerId?: string;
  channelId?: string;
}

interface IProps extends RouteComponentProps<IRouteProps, any, {}> {}

const Channel: React.FC<IProps> = ({ ...props }) => {
  // Search Centers and Generic Stuff
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const channelId = props.match.params.channelId ?? '';
  const centerId = props.match.params.centerId ?? '';
  const centerOptions = useGetActiveCenters().filter((center) => center.entityId === businessId);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showAddMessageModal, setShowAddMessageModal] = useState(false);
  const [showMarkReadConfirmationModal, setShowMarkReadConfirmationModal] = useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [from, setFrom] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageRecordSize, setPageRecordSize] = React.useState(100);

  const user = useSelector<RootState, User | null>((state: RootState) => state.user);

  React.useEffect(() => {
    updateSearchValue(searchInput);
  }, [searchInput]);

  const updateSearchValue = React.useCallback(
    _.debounce((input: string) => {
      setSearchQuery(input);
      setCurrentPage(0);
      setFrom(0);
    }, 500),
    []
  );

  const canCreateMessage = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.StudentMessaging,
    level: RoleLevelType.Create,
  });

  // ******* Channel Data Stuff
  const {
    data: channelData,
    loading: channelDataLoading,
    refetch: refetchChannelData,
  } = useGetChannelActivity({
    variables: {
      businessId: businessId ?? '',
      channelId: channelId,
      searchText: searchQuery,
      pageNumber: from,
      pageSize: pageRecordSize,
    },
    skip: businessId == null || businessId === '' || channelId == null || channelId === '',
    // onCompleted: (result) => {
    //   console.log('*** result: ', result);
    // },
  });

  // ******* End Channel Data Stuff

  const {
    data: channelDetailsData,
    loading: channelDetailsDataLoading,
    refetch: channelDetailsDataRefetch,
  } = useGetStudentChannels({
    variables: {
      businessId: businessId ?? '',
      centerId: centerId ?? '',
      accountChildIds: [channelId],
      pageNumber: 0,
      pageSize: 1,
      searchTerm: '',
      includeInactiveChannels: true,
    },
    skip:
      businessId == null ||
      businessId === '' ||
      centerId == null ||
      centerId === '' ||
      channelId == null ||
      channelId === '',
  });

  const [createChatActivity, { data, loading: createActivityLoading, error: createChatActivityError }] =
    useCreateChatActivityMutation({
      onError: (err) => showToast('Unable to send user data', 'error'),
      onCompleted: (response) => {
        showToast(`Successfully Marked Channel As Read.`, 'success');
        refetchChannelData();
        setShowMarkReadConfirmationModal(false);
        channelDetailsDataRefetch();
      },
    });

  const resetPage = () => {
    setShowAddMessageModal(false);
    setFrom(0);
    setCurrentPage(0);
    setSearchInput('');
    refetchChannelData();
  };

  return (
    <PageWrapper
      badge={
        <Mui.CardHeader
          avatar={
            <Avatar
              image={channelDetailsData?.getStudentChannels?.data[0]?.accountChild?.avatar?.url ?? ''}
              size="md"
              initials={`${channelDetailsData?.getStudentChannels?.data[0]?.accountChild?.firstname.charAt(0) ?? ''}${
                channelDetailsData?.getStudentChannels?.data[0]?.accountChild?.lastname.charAt(0) ?? ''
              }`}
            />
          }
          title={
            <Mui.Typography variant="body1">{`${
              channelDetailsData?.getStudentChannels?.data[0]?.accountChild?.firstname ?? ''
            } ${channelDetailsData?.getStudentChannels?.data[0]?.accountChild?.lastname ?? ''}`}</Mui.Typography>
          }
          subheader={channelDetailsData?.getStudentChannels?.data[0]?.accountChild?.account?.name ?? ''}
          sx={{ borderBottom: 0 }}
        />
      }
      applyPadding={true}
      mobileButtonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Comms, permission: PermissionType.StudentMessaging, level: RoleLevelType.Create }}
        >
          <CirclePlusButton
            variant="primary"
            className="mt-4 mb-4"
            disabled={!channelId}
            onClick={() => setShowAddMessageModal(true)}
          />
        </HasRoleAreaLevel>
      }
      buttonComponent={
        <>
          <HasRoleAreaLevel
            action={{ area: AreaType.Comms, permission: PermissionType.StudentMessaging, level: RoleLevelType.Edit }}
          >
            <Button className="my-2 mr-2" onClick={() => setShowMarkReadConfirmationModal(true)} variant="secondary">
              Mark Messages as Read
            </Button>
          </HasRoleAreaLevel>
          <HasRoleAreaLevel
            action={{ area: AreaType.Comms, permission: PermissionType.StudentMessaging, level: RoleLevelType.Create }}
          >
            <Mui.Box sx={{ marginTop: '8px' }}>
              <CreateButton disabled={!channelId} onClick={() => setShowAddMessageModal(true)}>
                Add Message
              </CreateButton>
            </Mui.Box>
          </HasRoleAreaLevel>
        </>
      }
    >
      {/* MY TABLE */}
      <Mui.Box>
        {!channelData && !channelDetailsDataLoading ? (
          <Mui.Stack direction="column" spacing={2} alignItems="center" marginTop={4}>
            <FontAwesomeIcon icon={faNoteSticky} size="4x" />
            <Mui.Typography>There are no Messages available to display.</Mui.Typography>
          </Mui.Stack>
        ) : (
          <>
            <ChannelStatisticsBar
              currentBusinessId={businessId ?? ''}
              currentCenterId={centerId ?? ''}
              currentChannelId={channelId}
              channel={channelDetailsData?.getStudentChannels?.data[0]}
              channelLoading={channelDetailsDataLoading}
            />
            <Mui.Card sx={{ marginTop: '24px' }} elevation={0}>
              <Mui.CardContent sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
                <Mui.Box display="flex" justifyContent="space-between">
                  <Mui.TextField
                    variant="outlined"
                    value={searchInput}
                    onChange={(evt) => setSearchInput(evt.target.value)}
                    InputProps={{
                      startAdornment: (
                        <Mui.InputAdornment position="start">
                          <FontAwesomeIcon size="xs" icon={faSearch} />
                        </Mui.InputAdornment>
                      ),
                      endAdornment: (
                        <Mui.InputAdornment position="end">
                          <Mui.IconButton onClick={() => setSearchInput('')}>
                            <FontAwesomeIcon size="xs" icon={faTimes} />
                          </Mui.IconButton>
                        </Mui.InputAdornment>
                      ),
                    }}
                    inputProps={{
                      'aria-label': 'Search',
                    }}
                    placeholder="Search"
                    size="small"
                    sx={{
                      minWidth: '400px',
                      '& legend': {
                        width: 0,
                      },
                    }}
                  />
                </Mui.Box>
              </Mui.CardContent>
            </Mui.Card>

            <ChannelMessagesTable
              channelId={channelId}
              messageList={channelData?.getChannelActivity?.data ?? []}
              messageListLoading={channelDataLoading}
              from={from}
              setFrom={setFrom}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageRecordSize={pageRecordSize}
              setPageRecordSize={setPageRecordSize}
              totalItems={channelData?.getChannelActivity?.totalRecords ?? 0}
              refetchChannel={refetchChannelData}
            />
          </>
        )}
      </Mui.Box>
      {canCreateMessage && (
        <AddMessageForm
          isOpen={showAddMessageModal}
          onClose={resetPage}
          centerOptions={centerOptions}
          currentCenterId={centerId}
          businessId={businessId}
          channelId={channelId}
        />
      )}
      {/* Mark Messages as Read Modal */}
      <Mui.Dialog open={showMarkReadConfirmationModal} onClose={() => setShowMarkReadConfirmationModal(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography variant="dialogTitle">Mark Messages as Read</Mui.Typography>
          <Mui.IconButton onClick={() => setShowMarkReadConfirmationModal(false)} disableRipple sx={styles.closeIcon}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>{`Are you sure you want to mark all messages as read?`}</Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setShowMarkReadConfirmationModal(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button
            variant="contained"
            color="secondary"
            onClick={() => {
              createChatActivity({
                variables: {
                  businessId: businessId ?? '',
                  input: {
                    businessId: businessId ?? '',
                    channelType: ChannelType.Student,
                    channelId: channelId,
                    personId: user?.id ?? '',
                    activityType: ActivityType.ChannelJoined,
                    activityTime: 0,
                  },
                },
              });
            }}
          >
            Confirm
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </PageWrapper>
  );
};

export default Channel;
