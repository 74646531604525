import React, { useState, useCallback, useEffect, useMemo } from 'react';
import EmergencyContactsForm from './EmergencyContactsForm';

export interface IChildAccountDetails {
  accountChildId: string;
  accountId: string;
  accountName: string;
  centerId: string;
  centerName: string;
  accountContactsForChild: IChildContact[];
  currentAccountEmergencyContactIds: string[];
}

interface IChildAccountContact {
  firstName: string;
}

interface IProps {
  child: IChild;
  readOnly?: boolean;
}

const ChildEmergencyContacts: React.FC<IProps> = ({ child, readOnly = false }) => {
  const [editingAccountId, setEditingAccountId] = useState<string | null>(null);

  const childEmergencyContactIds = useMemo(() => {
    return child.emergencyContacts.map((ec) => ec.contactId);
  }, [child.emergencyContacts]);

  const childAccounts = useMemo<IChildAccountDetails[]>(() => {
    return child.accounts.map((account) => {
      const accountContacts = child.contacts
        .filter((contact) => contact.accountPermissions.some((ap) => ap.accountId === account.id))
        .map((contact) => ({
          ...contact,
          isPrimary: contact.accountPermissions.some((ap) => ap.accountId === account.id && ap.isPrimary),
        }))
        .sort((a, b) => {
          if (a.isPrimary && !b.isPrimary) {
            return -1;
          } else if (!a.isPrimary && b.isPrimary) {
            return 1;
          } else {
            return a.firstname.localeCompare(b.firstname);
          }
        });

      return {
        accountChildId: account.children.find((c) => c.id === child.id)?.accountChildId ?? '',
        accountId: account.id,
        accountName: account.name,
        centerId: account.center?.id ?? '',
        centerName: account.center?.name ?? '',
        accountContactsForChild: accountContacts,
        currentAccountEmergencyContactIds: accountContacts
          .filter((c) => childEmergencyContactIds.some((ec) => ec === c.id))
          .map((c) => c.id),
      };
    });
  }, [child, childEmergencyContactIds]);

  return (
    <>
      {childAccounts.map((accountDetails) => {
        return (
          <EmergencyContactsForm
            child={child}
            key={`child-account-${accountDetails.accountId}`}
            currentChildEmergencyContactIds={childEmergencyContactIds}
            childAccountDetails={accountDetails}
            allChildAccounts={childAccounts}
            handleBeginEditing={() => setEditingAccountId(accountDetails.accountId)}
            handleStopEditing={() => setEditingAccountId(null)}
            editingAccountId={editingAccountId}
          />
        );
      })}
    </>
  );
};

export default ChildEmergencyContacts;
