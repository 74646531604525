import React, { Dispatch, SetStateAction, useState } from 'react';
import AddContactTypeOptions from './AddContactTypeOptions';
import { INewContactWithPermissions } from './AddContactModalForm';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import ContactChildrenInputs from '../ContactChildrenInputs';
import { useGetAccountContactPermissions } from '../../../../../../../../gql/account/queries';
import AccountPermissionInputs from '../AccountPermissionsInputs';

interface IProps {
  contact: INewContactWithPermissions;
  setContact: Dispatch<SetStateAction<INewContactWithPermissions>>;
  account: IAccount;
}

const AddContactConfigurations: React.FC<IProps> = ({ contact, setContact, account }) => {
  const { t } = useTranslation(['accounts']);
  const { data: getAccountContactPermissionsData } = useGetAccountContactPermissions();
  const [accountPermissions, setAccountPermissions] = useState<AccountPermissions[]>(contact.accountPermissions);

  return (
    <Form>
      <AddContactTypeOptions contact={contact} setContact={setContact} account={account} />
      <div className="mb-6">
        <h5 className="mb-4">{t('accounts:contacts.relationshipsFormTitle')}</h5>
        <div className="ml-2">
          <ContactChildrenInputs
            isPrimaryContact={contact.isPrimary}
            childOptions={account.children.filter((child) => !child.archivedAt)}
            contactChildren={contact.childPermissions}
            updateContactChildren={(childPermissions) => {
              setContact((prev) => ({ ...prev, childPermissions }));
            }}
          />
        </div>
      </div>
      {!contact.isPrimary && (
        <>
          <div className="mb-6">
            <h5 className="mb-4">{t('accounts:contacts.accountPermissionsFormTitle')}</h5>
            {getAccountContactPermissionsData?.getAccountContactPermissions.map((p, i) => {
              return (
                <div key={i}>
                  <AccountPermissionInputs
                    permission={p}
                    accountPermissions={accountPermissions}
                    setContact={setContact}
                    setAccountPermissions={setAccountPermissions}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </Form>
  );
};

export default AddContactConfigurations;
