import React from 'react';
import DataControlAccordion from 'pages/TimeManagement/subroutes/StaffSchedules/components/DataControls/DataControlAccordion';
import { IMenu } from '../CcsAccountManagement';

interface IProps {
  updateMenu: (items: Array<IMenu>) => void;
  menu: Array<IMenu>;
}

const CcsAccountManagementMenu: React.FC<IProps> = ({ menu, updateMenu = (items: Array<IMenu>) => {} }) => {
  const onItemClick = (key: string) => {
    const newMenu = menu.map((item) =>
      item.key === key ? { ...item, isSelected: true } : { ...item, isSelected: false }
    );
    updateMenu(newMenu);
  };

  return (
    <DataControlAccordion className="mb-4 notifiable-events" title="Notifiable Events">
      <ul className="ccs-account-management nav-menu pb-4">
        {menu.map((item) => (
          <li key={item.key} className={`d-flex flex-row align-items-center ${item.isSelected ? 'active' : ''}`}>
            <p onClick={() => onItemClick(item.key)}>{item.name}</p>
          </li>
        ))}
      </ul>
    </DataControlAccordion>
  );
};

export default CcsAccountManagementMenu;
