import React, { useCallback, useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';

// @ts-ignore
const MONTHS = [...Array(12).keys()];

interface IProps {
  label?: string;
  required?: boolean;
  helpTooltipText?: string;
  className?: string;
  date: string | null;
  onMonthClick: (date: string) => void;
}

const MonthPicker2: React.FC<IProps> = ({
  label,
  required,
  helpTooltipText,
  className,
  date,
  onMonthClick,
  ...props
}) => {
  const [year, setYear] = useState<number>(moment().year());

  const handleMonthClick = useCallback(
    (month: number) => {
      const date = moment({ month, year }).toISOString();

      onMonthClick(date);
    },
    [year, onMonthClick]
  );

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="bottom"
      overlay={
        <Popover id="popover-contained" style={{ maxWidth: 'none' }}>
          <Popover.Content>
            <div className="d-flex flex-row align-items-center my-2">
              <FontAwesomeIcon
                icon={faChevronLeft}
                size="lg"
                color="gray"
                className="flex-1"
                role="button"
                onClick={() => setYear((prev) => prev - 1)}
              />
              <div className="flex-1 text-center kt-month-picker-year-label">{year}</div>
              <FontAwesomeIcon
                icon={faChevronRight}
                size="lg"
                color="gray"
                className="flex-1"
                role="button"
                onClick={() => setYear((prev) => prev + 1)}
              />
            </div>
            <div className="kt-month-picker-month-grid">
              {MONTHS.map((monthNumber: number, idx: number) => (
                <div key={idx}>
                  <button
                    className={classnames('btn', {
                      active: date && moment(date).month() === monthNumber && moment(date).year() === year,
                    })}
                    onClick={() => handleMonthClick(monthNumber)}
                  >
                    {moment().month(monthNumber).format('MMM')}
                  </button>
                </div>
              ))}
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <Form.Group className={classnames('kt-date-input kt-month-picker', className)}>
        {label && (
          <div className="d-flex flex-row">
            {label && <Form.Label>{label}</Form.Label>}
            {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
            {helpTooltipText && <HelpTooltip text={helpTooltipText} />}
          </div>
        )}
        <div
          className="form-control cursor-pointer d-flex align-items-center"
          style={{ maxWidth: 'none' }}
          role="button"
          tabIndex={-1}
        >
          {date && moment(date).format('MMMM YYYY')}
        </div>
      </Form.Group>
    </OverlayTrigger>
  );
};

export default MonthPicker2;
