import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import Card, { BoldCardHeader } from 'shared/components/Card';
import Button from 'shared/components/Buttons';
import PaymentMethod from 'shared/components/PaymentMethods/PaymentMethod';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { capitalize } from 'shared/util/string';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import colors from '_colors.module.scss';

interface IProps {
  hasAutoPay: boolean;
  loading: boolean;
  disabled?: boolean;
  onClick: () => void;
  paymentMethod?: IPaymentMethod;
}

const AutoPayManageCard: React.FC<IProps> = ({
  hasAutoPay,
  paymentMethod,
  loading,
  disabled = false,
  onClick,
  ...props
}) => {
  const { t } = useTranslation(['billing', 'translation']);
  const cardBodyClassNames = classnames('p-4');
  const canEditPaymentMethodsPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.PaymentMethods,
    level: RoleLevelType.Edit,
  });

  return (
    <div>
      <Card
        bodyClassName={cardBodyClassNames}
        onClick={onClick}
        loading={loading}
        loadingLines={2}
        style={{ minHeight: 140, border: `1px solid #9eb0c7`, borderRadius: '6px' }}
      >
        <div className="d-flex justify-content-between">
          <div>
            <div className="mb-2">
              <FontAwesomeIcon icon={faSync} className="mr-4" size="lg" color="#1679C6" />

              <BoldCardHeader
                className="text-dark d-inline font-weight-bold"
                text={capitalize(t('translation:spelling.autopay'))}
              />

              {hasAutoPay ? (
                <ColoredBackgroundTag text="ON" color={colors.success} className="ml-2" />
              ) : (
                <ColoredBackgroundTag text="OFF" color={colors.gray} className="ml-2" />
              )}
            </div>
            {paymentMethod ? (
              <PaymentMethod showCardHolder paymentMethod={paymentMethod} className="payment-summary-card" />
            ) : (
              <div>
                <small>{t('billing:payment-methods.auto-pay-no-setup')}</small>
              </div>
            )}
          </div>
          {canEditPaymentMethodsPermissions && (
            <Button variant="outline-primary" disabled={disabled}>
              {t('translation:spelling.manage')}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default AutoPayManageCard;
