import React, { useState, useCallback, useEffect } from 'react';
import { useGetDiscountTabDataByAccountId } from '../queries';
import AccountDiscountsTable from './AccountDiscountsTable';
import AddDiscountModal from './AddDiscountModal';
import { AppliedAccountDiscountTableData } from './DiscountTabTypes';
import EditDiscountModal from './EditDiscountModal';
import RemoveDiscountModal from './RemoveDiscountModal';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';

interface IAppliedDiscountModalShape {
  open: boolean;
  discount: AppliedAccountDiscountTableData | null;
}

interface IProps {
  accountId: string;
  accountCenterId: string;
  accountEntityId: string;
  accountName: string;
  children: IAccountChild[];
}

const DiscountsTab = (props: IProps) => {
  const { loading, data } = useGetDiscountTabDataByAccountId(props.accountId);

  const getAccountChildForId = useCallback(
    (accountChildId: string): IAccountChild | null => {
      return props.children.find((child) => child.accountChildId === accountChildId) ?? null;
    },
    [data, props.accountId, props.children]
  );

  const handleSetDiscounts = (): AppliedAccountDiscountTableData[] => {
    return (data ? data.getAccountById.discounts : []).map((d) => ({
      ...d,
      accountChild: d.accountChildId ? getAccountChildForId(d.accountChildId) : null,
    }));
  };

  const [discounts, setDiscounts] = useState<AppliedAccountDiscountTableData[]>(handleSetDiscounts());

  const updateDiscount = (discount: IAppliedAccountDiscount) =>
    setDiscounts(
      (discounts ?? []).map((d) =>
        d.id === discount.id
          ? {
              ...discount,
              accountChild: discount.accountChildId ? getAccountChildForId(discount.accountChildId) : null,
            }
          : d
      )
    );
  const createDiscount = (newDiscounts: IAppliedAccountDiscount[]) => {
    const discountsToCreate = newDiscounts.map((d) => ({
      ...d,
      accountChild: d.accountChildId ? getAccountChildForId(d.accountChildId) : null,
    }));
    setDiscounts([...(discounts ?? []), ...discountsToCreate]);
  };
  const deleteDiscount = (discount: IAppliedAccountDiscount) =>
    setDiscounts(discounts.filter((a) => a.id !== discount.id));

  useEffect(() => {
    setDiscounts(handleSetDiscounts());
  }, [data]);

  const [showAddDiscountModal, setShowAddDiscountModal] = useState<boolean>(false);
  const handleShowAdd = () => setShowAddDiscountModal(true);
  const handleHideAdd = () => setShowAddDiscountModal(false);

  const [editDiscountModal, setEditDiscountModal] = useState<IAppliedDiscountModalShape>({
    open: false,
    discount: null,
  });
  const closeEditModal = () => setEditDiscountModal({ open: false, discount: null });

  const [deleteDiscountModal, setDeleteDiscountModal] = useState<IAppliedDiscountModalShape>({
    open: false,
    discount: null,
  });
  const closeDeleteModal = () => setDeleteDiscountModal({ open: false, discount: null });

  return (
    <>
      <div>
        <div>
          <h3>Discounts</h3>
        </div>
        {loading ? (
          <LoadingLines number={4} />
        ) : (
          <AccountDiscountsTable
            data={discounts}
            add={handleShowAdd}
            edit={(discount) => setEditDiscountModal({ open: true, discount })}
            remove={(discount) => setDeleteDiscountModal({ open: true, discount })}
          />
        )}
      </div>
      {!loading && props.accountEntityId && props.accountEntityId.length > 0 && (
        <AddDiscountModal
          isOpen={showAddDiscountModal}
          accountId={props.accountId}
          accountCenterId={props.accountCenterId}
          accountEntityId={props.accountEntityId}
          accountName={props.accountName}
          accountChildren={props.children}
          discounts={discounts}
          onAdd={createDiscount}
          onClose={handleHideAdd}
        />
      )}
      {!loading && props.accountEntityId && props.accountEntityId.length > 0 && editDiscountModal.discount && (
        <EditDiscountModal
          isOpen={editDiscountModal.open}
          accountEntityId={props.accountEntityId}
          accountName={props.accountName}
          discount={editDiscountModal.discount}
          onEdit={updateDiscount}
          onClose={closeEditModal}
        />
      )}
      {!loading && deleteDiscountModal.discount && (
        <RemoveDiscountModal
          isOpen={deleteDiscountModal.open}
          discount={deleteDiscountModal.discount}
          onRemove={deleteDiscount}
          onClose={closeDeleteModal}
        />
      )}
    </>
  );
};

export default DiscountsTab;
