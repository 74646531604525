import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { maxBy } from 'lodash';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Row, Col } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import { useTranslation } from 'react-i18next';
import { useGetDiscountsWithFilters } from 'gql/discount/queries';
import { getFullName } from 'shared/util/string';
import { isRegion } from 'shared/util/region';
import { useUpdateAppliedAccountDiscount } from 'gql/discount/mutations';
import { showToast } from 'shared/components/Toast';
import { PersonAvatar } from 'shared/components/Avatar';
import { AppliedAccountDiscountTableData } from './DiscountTabTypes';

interface IFormShape {
  id: string;
  start: string;
  end: string | null;
}

interface IProps {
  isOpen: boolean;
  accountEntityId: string;
  accountName: string;
  discount: AppliedAccountDiscountTableData;
  onEdit: (editedDiscount: IAppliedAccountDiscount) => void;
  onClose: () => void;
}

const EditDiscountModal: React.FC<IProps> = ({ isOpen, discount, onClose, ...props }) => {
  // Per Product: if the discount has started only the end date can be adjusted
  const discountHasStarted = moment(discount.startDate).isSameOrBefore(moment());

  const { t } = useTranslation(['billing']);
  const [formData, setFormData] = useState<IFormShape>({
    id: discount.id,
    start: discount.startDate,
    end: discount.endDate,
  });

  const { data: getDiscountsWithFiltersData, loading: getDiscountsForBusinessLoading } = useGetDiscountsWithFilters({
    variables: {
      input: {
        businessId: props.accountEntityId,
      },
    },
    skip: !props.accountEntityId,
  });

  const [updateAppliedDiscountFn, { loading: updateAppliedDiscountLoading }] = useUpdateAppliedAccountDiscount({
    onCompleted: (result) => {
      showToast(t('billing:discounts.account.edit-applied-modal.success-toast-message'), 'success');
      props.onEdit(result.updateAppliedAccountDiscount);
      handleClose();
    },
    onError: (err) => {
      showToast(t('billing:discounts.account.edit-applied-modal.error-toast-message'), 'error');
    },
  });

  const maxDiscountDate = useCallback((discount: IDiscount | undefined): moment.Moment | null => {
    if (discount === undefined) return null;

    const map = discount.schedules.map((d) => d.endDate);
    const max = maxBy(map, (date) => (date == null ? null : new Date(date).getUTCMilliseconds()));
    if (max === undefined || max === null) return null;

    return moment(max);
  }, []);

  const isStartDateOutsideRange = useCallback(
    (day: any): boolean => {
      const dayIsAfterEndDate = formData.end !== null && moment(day).isSameOrAfter(moment(formData.end));

      // AU says that discounts can be backdated
      if (isRegion('AU')) {
        return dayIsAfterEndDate;
      }

      return moment(day).isBefore(moment()) || dayIsAfterEndDate;
    },
    [formData.end]
  );

  const isEndDateOutsideRange = useCallback(
    (day: any, discount: IDiscount | undefined): boolean => {
      const dayIsBeforeStartDate = moment(day).isSameOrBefore(moment(formData.start));
      const dayIsAfterDiscountEndDate = moment(day).subtract(1, 'days').isAfter(maxDiscountDate(discount));
      return dayIsBeforeStartDate || dayIsAfterDiscountEndDate;
    },
    [formData.start, formData.end]
  );

  const handleSave = useCallback(() => {
    updateAppliedDiscountFn({
      variables: {
        input: {
          id: formData.id,
          startDate: formData.start,
          endDate: formData.end,
        },
      },
    });
  }, [formData, updateAppliedDiscountFn]);

  const handleClose = useCallback(() => {
    setFormData({
      id: '',
      start: '',
      end: null,
    });
    onClose();
  }, [onClose]);

  const formDisabled = !formData.start;

  return (
    <SideModalDrawer
      title={t('billing:discounts.account.edit-applied-modal.title')}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice={t('billing:discounts.modal-inputs.primary-action-button')}
      secondaryChoice={t('billing:discounts.modal-inputs.secondary-action-button')}
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: formDisabled, loading: updateAppliedDiscountLoading }}
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <Row className="mb-4">
        <Col>
          <Form.Label>{t('billing:discounts.account.edit-applied-modal.discount-applied-to-label')}</Form.Label>
          {discount.accountChild ? (
            <div className="d-flex align-items-center">
              <PersonAvatar size="sm" person={discount.accountChild} className="mr-2" />
              <span>{getFullName(discount.accountChild)}</span>
            </div>
          ) : (
            `${props.accountName} Account`
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <DateInput
            required
            disabled={discountHasStarted && !isRegion('AU')}
            label={t('billing:discounts.modal-inputs.start-date-input-label')}
            date={formData.start}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, start: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={isStartDateOutsideRange}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <DateInput
            label={t('billing:discounts.modal-inputs.end-date-input-label')}
            date={formData.end}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, end: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(day) =>
              isEndDateOutsideRange(
                day,
                (getDiscountsWithFiltersData?.getDiscountsWithFilters ?? []).find((d) => d.id === discount.discountId)
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            disabled
            label={t('billing:discounts.account.edit-applied-modal.discount-input-label')}
            value={(getDiscountsWithFiltersData?.getDiscountsWithFilters ?? []).find(
              (d) => d.id === discount.discountId
            )}
            options={getDiscountsWithFiltersData?.getDiscountsWithFilters ?? []}
            onChange={(option: IDiscount) => {}}
            getOptionLabel={(option: IDiscount) => option.name}
            getOptionValue={(option: IDiscount) => option.id}
            isLoading={getDiscountsForBusinessLoading}
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default EditDiscountModal;
