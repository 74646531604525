import React, { useState, useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import Collapse from 'react-bootstrap/Collapse';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { getFullName } from 'shared/util/string';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  staff: IStaff;
  center: ICenter;
  loading: boolean;
  removeFn: () => void;
}

const RemoveAffiliationModal: React.FC<IProps> = ({ isOpen, onClose, staff, center, removeFn, loading }) => {
  const [showSecondaryWarning, setShowSecondaryWarning] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    onClose();
    setShowSecondaryWarning(false);
  }, [onClose]);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <ConfirmationModal
      title="Remove Affiliation?"
      show={isOpen}
      onHide={handleClose}
      hideOnCallback={false}
      primaryChoice={showSecondaryWarning ? 'Confirm' : 'Remove'}
      primaryCallback={showSecondaryWarning ? removeFn : () => setShowSecondaryWarning(true)}
      primaryButtonProps={{ variant: 'danger', loading }}
    >
      <p>
        Do you wish to remove <b>{getFullName(staff)}</b> from <b>{center.name}</b>?
      </p>
      <Collapse in={showSecondaryWarning}>
        <div className="mt-4">
          <p>
            By removing this {fieldLabels.center.toLowerCase()}, you are stopping all permissions and access{' '}
            <b>{getFullName(staff)}</b> has to <b>{center.name}</b>. Do you wish to proceed?
          </p>
        </div>
      </Collapse>
    </ConfirmationModal>
  );
};

export default RemoveAffiliationModal;
