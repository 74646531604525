import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateApplicationSettings } from 'gql/applicationSetting/mutation';
import { useGetApplicationSettings } from 'gql/applicationSetting/queries';
import Card from 'shared/components/Card';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Col, Row } from 'shared/components/Layout';
import Input from 'shared/components/TextInput/Input';
import { showToast } from 'shared/components/Toast';
import { capitalize } from 'shared/util/string';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import Select from 'shared/components/Select';
import { IApplicationSettings } from 'shared/types/application';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationFlowType, ApplicationFlowType as flowType } from 'generated/graphql';
import ApplicationCenterSettings from './ApplicationCenterSettings';

interface IProps {
  businessId: string;
}
const initialState: IApplicationSettings = {
  offersExpireInDays: 0,
  flowType: ApplicationFlowType.InquireOfferEnrollment,
};
const Settings: React.FC<IProps> = ({ businessId }) => {
  const { t } = useTranslation(['business', 'translation']);
  const { k2NewLeadFlow } = useFlags();
  const [formData, setFormData] = useState(initialState);
  const [isDirty, setDirty] = useState(false);

  const { refetch } = useGetApplicationSettings({
    variables: {
      input: {
        businessId,
      },
    },
    onCompleted: (res) => {
      const { offersExpireInDays, flowType } = res.getApplicationSetting ?? {};

      setFormData({
        offersExpireInDays: offersExpireInDays ?? 0,
        flowType: flowType ?? ApplicationFlowType.InquireOfferEnrollment,
      });
    },
  });

  const hasCreateEnrollmentLeadSettingsEditPermissions = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Edit,
  });

  const applicationFlowOptions: {
    value: flowType;
    label: string;
  }[] = [
    {
      value: flowType.InquireOfferEnrollment,
      label: t('business:enrolment.settings-tab.inquire-offer-enrollment-label'),
    },
    {
      value: flowType.InquireProgramEnrollmentApprove,
      label: t('business:enrolment.settings-tab.inquire-program-enrollment-approve-label'),
    },
    ...(k2NewLeadFlow
      ? [
          {
            value: flowType.InquireProgramFormPayApprove,
            label: t('business:enrolment.settings-tab.inquire-program-form-pay-approve-label'),
          },
        ]
      : []),
  ];

  const [updateSetting, { loading }] = useUpdateApplicationSettings({
    onCompleted: (res) => {
      showToast(t('business:enrolment.settings-tab.settings-update-success'), 'success');
      setDirty(false);
      const { offersExpireInDays, flowType } = res.updateApplicationSetting ?? {};

      setFormData({
        offersExpireInDays: offersExpireInDays,
        flowType: flowType,
      });

      window.location.reload();
    },
    onError: () => {
      t('translation:general.error');
    },
  });

  const handleSave = () => {
    updateSetting({
      variables: {
        input: {
          businessId,
          offersExpireInDays: formData.offersExpireInDays ?? 0,
          flowType: formData.flowType ?? ApplicationFlowType.InquireOfferEnrollment,
        },
      },
    });
  };

  const handleExpiryDaysChange = useCallback(
    (value: string) => {
      setFormData({
        ...formData,
        offersExpireInDays: parseInt(value),
      });
      setDirty(true);
    },
    [formData, setDirty]
  );

  const handleFlowChange = useCallback(
    (value: ApplicationFlowType) => {
      setFormData({
        ...formData,
        flowType: value,
      });
      setDirty(true);
    },
    [formData, setDirty]
  );

  const handleCancel = useCallback(() => {
    refetch();
    setDirty(false);
  }, [setDirty, refetch]);

  return (
    <>
      <Row>
        <Col lg={6}>
          <Card header={t('business:enrolment.settings-tab.offer-settings-title')}>
            <FormWrapper2
              formIsDirty={isDirty}
              toggleDirty={setDirty}
              onCancel={handleCancel}
              onSave={handleSave}
              toggleDirtyOnSave={true}
              hasPermission={hasCreateEnrollmentLeadSettingsEditPermissions}
              loading={loading}
            >
              <p>{t('business:enrolment.settings-tab.offer-expiry-select-description')}</p>
              <Row>
                <Col lg={3} xl={2}>
                  <Input
                    value={formData.offersExpireInDays ?? 0}
                    type="number"
                    onChange={handleExpiryDaysChange}
                    label={capitalize(t('translation:spelling.days'))}
                    min="0"
                  />
                </Col>
              </Row>
              <Row className="mb-8">
                <Col lg={10} xl={7}>
                  <Select
                    options={applicationFlowOptions}
                    label={t('business:enrolment.settings-tab.application-flow-type')}
                    onChange={(option) => handleFlowChange(option.value)}
                    value={formData.flowType}
                  />
                </Col>
              </Row>
            </FormWrapper2>
          </Card>
        </Col>
      </Row>
      {<ApplicationCenterSettings flowType={formData.flowType as flowType} />}
    </>
  );
};
export default Settings;
