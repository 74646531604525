import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import Container from 'react-bootstrap/Container';
import Column from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProfileTab from './Tabs/Profile/ProfileTab';
import MedicalTab from './Tabs/Medical/MedicalTab';
import DocumentsTab from './Tabs/Documents/DocumentsTab';
import EmploymentTab from './Tabs/Employment';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import { useGetStaffGetKisiShare, useGetStaffProfileInformation } from 'gql/staff/queries';
import useUniqueId from 'shared/hooks/useUniqueId';
import useUpdateAvatar from 'shared/hooks/useUpdateAvatar';
import { RootState } from 'store/reducers';
import { getFullName } from 'shared/util/string';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import './profile.scss';
import InternalNotes from './Tabs/InternalNotes/InternalNotes';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import KisiTab from './Tabs/Kisi/KisiTab';
import { isRegion } from 'shared/util/region';
import HasRoleAreaLevel from '../../../../shared/components/HasRoleAreaLevel';
import { CreateButton } from '../../../../shared/components/Buttons';
import AddMedicalConditionModal from './Tabs/Medical/AddMedicalConditionModal';

interface IRouteParams {
  id: string;
  activeTab: string;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const Profile: React.FC<IProps> = ({ ...props }) => {
  const { activeTab, id } = props.match.params;
  const [activeTabKey, setActiveTabKey] = React.useState<string>(activeTab ?? 'profile');
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const tabId: string = useUniqueId('staff-tag-tab-group');
  const personId: string = props.match.params.id ?? '';
  const staffName: string = '';
  const { k2KisiIntegration } = useFlags();

  const [showAddHealthItemModal, setShowAddHealthItemModal] = useState<boolean>(false);

  const hasBusinessLevelPermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  // eslint-disable-next-line
  const { loading, error, data, refetch } = useGetStaffProfileInformation(personId);
  const staff = data?.getStaff;
  const { data: kisidata } = useGetStaffGetKisiShare(personId, staff?.entityId ?? '');
  const staffIsDeactivated = staff?.employmentStatus === 'Deactivated' ?? false;
  const [updateStaffAvatarFn, { loading: avatarLoading }] = useUpdateAvatar('staff');

  const hasStaffInternalNotesPermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Notes,
    level: RoleLevelType.Read,
  });

  const getSubtitle = useCallback(() => {
    if (user?.isInternal && staff) {
      return staff.id;
    }

    return '';
  }, [staff, user]);

  const tabPermissions = useMemo(() => {
    if (user) {
      return {
        profile: {
          view: user.hasAreaPermissionLevel({
            area: AreaType.Staff,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          }),
          full: user.hasAreaPermissionLevel({
            area: AreaType.Staff,
            permission: PermissionType.Base,
            level: RoleLevelType.Create | RoleLevelType.Edit | RoleLevelType.Delete,
          }),
        },
        medical: {
          view: user.hasAreaPermissionLevel({
            area: AreaType.Staff,
            permission: PermissionType.Medical,
            level: RoleLevelType.Read,
          }),
          full: user.hasAreaPermissionLevel({
            area: AreaType.Staff,
            permission: PermissionType.Medical,
            level: RoleLevelType.Create | RoleLevelType.Edit | RoleLevelType.Delete,
          }),
        },
        employment: {
          view: user.hasAreaPermissionLevel({
            area: AreaType.Staff,
            permission: PermissionType.Employment,
            level: RoleLevelType.Read,
          }),
          full: user.hasAreaPermissionLevel({
            area: AreaType.Staff,
            permission: PermissionType.Employment,
            level: RoleLevelType.Create | RoleLevelType.Delete | RoleLevelType.Edit,
          }),
          edit: user.hasAreaPermissionLevel({
            area: AreaType.Staff,
            permission: PermissionType.Employment,
            level: RoleLevelType.Edit,
          }),
        },
        documents: {
          view: false,
          full: false,
        },
        notes: {
          view: user.hasAreaPermissionLevel({
            area: AreaType.Staff,
            permission: PermissionType.Notes,
            level: RoleLevelType.Read,
          }),
          full: user.hasAreaPermissionLevel({
            area: AreaType.Staff,
            permission: PermissionType.Notes,
            level: RoleLevelType.Create | RoleLevelType.Edit | RoleLevelType.Delete,
          }),
        },
      };
    }

    return {
      profile: { view: false, full: false },
      medical: { view: false, full: false },
      employment: { view: false, full: false },
      documents: { view: false, full: false },
      notes: { view: false, full: false },
    };
  }, [user]);

  const refetchStaff = React.useCallback(() => {
    refetch({ id: personId });
  }, []);

  React.useEffect(() => {
    setActiveTabKey(activeTab ?? 'profile');
  }, [activeTab]);

  const renderPageButton = useCallback((): JSX.Element | null => {
    switch (activeTab) {
      case 'medical':
        return (
          <HasRoleAreaLevel
            action={{ area: AreaType.Staff, permission: PermissionType.Documents, level: RoleLevelType.Create }}
          >
            <CreateButton onClick={() => setShowAddHealthItemModal(true)}>Add Condition</CreateButton>
          </HasRoleAreaLevel>
        );
      default:
        return null;
    }
  }, [activeTab]);

  return (
    <ProfilePageWrapper
      title={staff ? getFullName(staff) : staffName}
      subtitle={getSubtitle()}
      loading={loading}
      avatarUrl={staff?.avatar?.url}
      readOnlyAvatar={staff?.employmentStatus === 'Deactivated'}
      onAvatarClick={(file: File) => {
        staff && updateStaffAvatarFn({ id: staff.id, avatar: file });
      }}
      avatarLoading={avatarLoading}
      buttonComponent={renderPageButton()}
    >
      <Container fluid>
        <Column className="p-sm-0">
          <Tabs
            activeKey={activeTabKey}
            id={tabId}
            onSelect={(tab) => {
              setActiveTabKey(tab ?? 'profile');
              history.push(`/employees/profiles/${id}/${tab}`);
            }}
          >
            {tabPermissions.profile.view && (
              <Tab eventKey="profile" title="Profile">
                <ProfileTab
                  data={data?.getStaff}
                  loading={loading}
                  error={error}
                  readOnly={!tabPermissions.profile.full || staffIsDeactivated}
                  canDeactivateStaff={tabPermissions.employment.full}
                  refetchStaff={refetchStaff}
                />
              </Tab>
            )}
            {tabPermissions.employment.view && (
              <Tab eventKey="employment" title="Employment">
                <EmploymentTab
                  staff={staff}
                  loading={loading}
                  readOnly={!tabPermissions.employment.edit || staffIsDeactivated}
                  haveAnyPermissions={!tabPermissions.employment.full && !tabPermissions.employment.view}
                  editPermission={tabPermissions.employment.edit}
                />
              </Tab>
            )}
            {tabPermissions.medical.view && (
              <Tab eventKey="medical" title="Medical">
                <MedicalTab staffId={personId} readOnly={!tabPermissions.medical.full || staffIsDeactivated} />
              </Tab>
            )}
            {tabPermissions.documents.view && (
              <Tab eventKey="documents" title="Documents">
                <DocumentsTab personId={personId} readOnly={!tabPermissions.documents.full || staffIsDeactivated} />
              </Tab>
            )}
            {hasStaffInternalNotesPermission && user?.id !== personId && (
              <Tab eventKey="internal-notes" title="Internal Notes">
                <InternalNotes personId={personId} />
              </Tab>
            )}
            {isRegion('US') && k2KisiIntegration && hasBusinessLevelPermission && !user?.isInternal && (
              <Tab eventKey="kisi" title="Kisi">
                <KisiTab data={data?.getStaff} kisiData={kisidata?.getKisiShare} loading={loading} />
              </Tab>
            )}
          </Tabs>
        </Column>
      </Container>
      <AddMedicalConditionModal
        isOpen={showAddHealthItemModal}
        onClose={() => setShowAddHealthItemModal(false)}
        staffId={personId}
      />
    </ProfilePageWrapper>
  );
};

export default Profile;
