import React, { useMemo, useState, useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import GapWaiverHeader from './components/GapWaiverHeader';
import GapWaiverTable from './components/GapWaiverTable';
import Button from 'shared/components/Buttons';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { map, omit } from 'lodash';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useEligibleChecker from 'shared/hooks/useGapWaiveEligibleChecker';
import { useWaiveAbsenceGapFee } from 'gql/session/mutations';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import moment from 'moment';
import config from 'config';
import './_gapWaiverModal.scss';

interface IProps {
  data: ISession[];
  loading: boolean;
  isOpen: boolean;
  classOptions: IClass[];
  classIds: string[] | null;
  setClassIds: (classIds: string[] | null) => void;
  date: string;
  setDate: (date: string) => void;
  selectedClasses?: IClass[] | null;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onClose: () => void;
  refetchData: () => void;
}

interface ISelectedSessionsToWaive {
  [sessionId: string]: {
    checkboxValue: boolean;
    sessionData: ISession;
  };
}

const GapWaiverModal: React.FC<IProps> = ({
  data,
  loading,
  isOpen,
  classOptions,
  classIds,
  setClassIds,
  selectedClasses,
  date,
  setDate,
  searchTerm,
  setSearchTerm,
  onClose,
  refetchData,
}) => {
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const { t } = useTranslation();
  const dateSettings = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;
  const [selectedSessionsToWaive, setSelectedSessionsToWaive] = useState<ISelectedSessionsToWaive>({});
  const [waiveAbsenceGapFeeFn, { loading: waiveAbsenceGapFeeSubmitting }] = useWaiveAbsenceGapFee();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const absentSessionsOfWeek = useMemo(() => data.filter((s) => s.absence), [data]);
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();
  const startDate = useMemo(
    () => moment(date).tz(timezone).day(dateSettings.weekDayStart),
    [date, timezone, dateSettings]
  );
  const endDate = useMemo(() => moment(date).tz(timezone).day(dateSettings.weekDayEnd), [date, timezone, dateSettings]);

  const {
    loading: eligibleCheckLoading,
    gapWaiveEligibilityMap,
    childrenEnrolmentTypeMap,
  } = useEligibleChecker(
    absentSessionsOfWeek,
    selectedCenterId,
    startDate.format('YYYY-MM-DD'),
    endDate.format('YYYY-MM-DD'),
    config.locale.region
  );

  const handleCheckboxChange = useCallback(
    (session: ISession | undefined, value: boolean) => {
      if (!session) return;

      if (value) {
        setSelectedSessionsToWaive((prev) => ({
          ...prev,
          [session.id]: {
            checkboxValue: value,
            sessionData: session,
          },
        }));
      } else {
        setSelectedSessionsToWaive((prev) => omit(prev, session.id));
      }
    },
    [setSelectedSessionsToWaive]
  );

  const checkboxValues = useMemo(
    () =>
      Object.values(selectedSessionsToWaive).reduce(
        (accumulator, { checkboxValue, sessionData }) => ({
          ...accumulator,
          [sessionData.id]: checkboxValue,
        }),
        {}
      ),
    [selectedSessionsToWaive]
  );

  const selectedSessionsToWaiveCount = useMemo(
    () => Object.keys(selectedSessionsToWaive).length,
    [selectedSessionsToWaive]
  );

  const isPrimaryButtonDisabled = selectedSessionsToWaiveCount === 0;

  const handleClearAllSelectedSessionsToWaive = useCallback(() => setSelectedSessionsToWaive({}), []);

  useEffect(() => {
    setSelectedSessionsToWaive({});
  }, [data]);

  const handleWaiveAbsenceGapFee = useCallback(() => {
    const input: { centerId: string; sessionId: string }[] = [];
    for (const [key, value] of Object.entries(selectedSessionsToWaive)) {
      if (value.checkboxValue) {
        input.push({
          centerId: value.sessionData.centerId,
          sessionId: key,
        });
      }
    }

    waiveAbsenceGapFeeFn({
      variables: { input },
    })
      .then(() => {
        showToast(t('gap-waiver.waive-fee-success-toast-message'), 'success');
      })
      .catch((err) => showToast(getApolloErrorMessage(err), 'error'))
      .finally(() => {
        handleClearAllSelectedSessionsToWaive();
        refetchData();
        setShowConfirmationModal(false);
      });
  }, [selectedSessionsToWaive, waiveAbsenceGapFeeFn]);

  return (
    <>
      <Modal
        centered
        backdrop="static"
        show={isOpen}
        onHide={onClose}
        scrollable={true}
        size="xl"
        fullscreen={true}
        className="gap-waiver-modal"
      >
        <Modal.Header closeButton className="px-6 py-4">
          <Modal.Title as="h5">{t('gap-waiver.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2 pb-4 px-4 gap-waiver-confirmation-container">
          <GapWaiverHeader
            classOptions={classOptions}
            classIds={classIds}
            setClassIds={setClassIds}
            timezone={timezone}
            setDate={setDate}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            startDate={startDate}
            endDate={endDate}
          />
          <GapWaiverTable
            absentSessionsOfWeek={absentSessionsOfWeek}
            childrenEnrolmentTypeMap={childrenEnrolmentTypeMap}
            gapWaiveEligibilityMap={gapWaiveEligibilityMap}
            loading={loading || eligibleCheckLoading}
            timezone={timezone}
            startOfWeek={startDate.format('YYYY-MM-DD')}
            checkboxValues={checkboxValues}
            handleCheckboxChange={handleCheckboxChange}
          />
        </Modal.Body>
        <Modal.Footer className="px-4 py-2">
          <Button variant="light" className="mr-3" onClick={onClose}>
            {t('gap-waiver.secondary-button-text')}
          </Button>
          <Button disabled={isPrimaryButtonDisabled} variant="primary" onClick={() => setShowConfirmationModal(true)}>
            {t('gap-waiver.primary-button-text')}
          </Button>
        </Modal.Footer>
      </Modal>
      <CenteredModal
        title={t('gap-waiver.confirmation-dialog.title')}
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        primaryCallback={handleWaiveAbsenceGapFee}
        primaryChoice={t('gap-waiver.confirmation-dialog.primary-button-text')}
        secondaryCallback={() => setShowConfirmationModal(false)}
        primaryButtonProps={{ loading: waiveAbsenceGapFeeSubmitting }}
      >
        <p>{t('gap-waiver.confirmation-dialog.message')}</p>
        <Table borderless className="simple-styled-table">
          <thead>
            <tr>
              <th>{t('gap-waiver.confirmation-dialog.table.column-label.child')}</th>
              <th>{t('gap-waiver.confirmation-dialog.table.column-label.class')}</th>
              <th>{t('gap-waiver.confirmation-dialog.table.column-label.date')}</th>
            </tr>
          </thead>
          <tbody>
            {map(selectedSessionsToWaive, ({ sessionData }) => (
              <tr key={sessionData.id}>
                <td>
                  {sessionData.child.firstname} {sessionData.child.lastname}
                </td>
                <td>{sessionData.class.name}</td>
                <td>{sessionData?.absence?.date || ''}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CenteredModal>
    </>
  );
};

export default GapWaiverModal;
