import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'shared/components/Toast';
import { useGetRolesForBusinessThatUserCanManage } from 'shared/hooks/useGetRolesForBusiness';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import { useUpsertTransactionTypeAuthorizationRoles } from 'gql/transactionTypeAuthorizationRoles/mutations';
import { updateTransactionType } from '../../duck/actions';
import { useUpdateTransactionType } from 'gql/transaction/mutations';
import useIsGlCodeInputValid from 'shared/hooks/useIsGlCodeInputValid';
import { isRegion } from 'shared/util/region';
import RightModalDrawer from 'shared/components/MUI/RightModalDrawer';
import * as Mui from '@mui/material';
import GlCodesDropdown from 'shared/components/MUI/GlCodesDropdown';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingButton from '@mui/lab/LoadingButton';

import i18n from 'i18n';
import { capitalize } from 'lodash';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
import { CurrencyTrans } from 'shared/components/Currency';

interface IRoleOption {
  value: string;
  label: string;
}

interface ITransactionTypeShape {
  name: string;
  isDebit?: boolean;
  glCode: string | null;
  roles: IRoleOption[] | [];
  isTuition: boolean;
  isDefaultTransactionType?: boolean;
  glCodeMapping?: IGLCodeMapping | null;
}

interface ITransactionType {
  id?: string;
  areaType?: string;
  attachedToEntityId?: string;
  name?: string;
  noteType?: string;
  center?: ICenter | null;
  glCode: string | null;
  glCodeMapping?: IGLCodeMapping | null;
  comment?: string;
  createdBy?: string;
  createdAt?: string;
  createdByAccount?: IStaff;
  isCredit?: boolean;
  isDebit?: boolean;
  isTuition?: boolean;
  authorisedRoles?: string[];
  isDefaultType: boolean;
}

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

interface IProps {
  isOpen: boolean;
  businessId: string | null;
  centerId: string | null;
  transactionTypeId: string;
  transactionType: ITransactionType;
  onClose: () => void;
  refetch: () => void;
}

const EditTransactionType: React.FC<IProps> = ({
  isOpen,
  businessId,
  centerId,
  transactionTypeId,
  transactionType,
  onClose,
  refetch,
  ...props
}) => {
  const isDefaultTransactionType = React.useMemo(() => transactionType.isDefaultType, [transactionType]);

  const [updatedGlCodesMapping, setUpdatedGlCodesMapping] = React.useState<IGLCodeMapping | null>(null);
  const { t } = useTranslation(['translation']);
  const dispatch = useDispatch();
  const { k2GlCodeActivationCheck } = useFlags();
  const currentUser = useSelector((state: RootState) => state.user);

  const { data: roles } = useGetRolesForBusinessThatUserCanManage(businessId ?? '', currentUser);
  const roleOptions = roles.map((role) => ({ value: role.id, label: role.name }));
  const [formData, setFormData] = React.useState<ITransactionTypeShape>({
    name: transactionType.name ?? '',
    glCode: transactionType.glCode,
    roles: roleOptions.filter((x) => transactionType.authorisedRoles?.includes(x.value)),
    isTuition: transactionType.isTuition ?? false,
    glCodeMapping: transactionType.glCodeMapping,
    isDebit: transactionType.isDebit,
  });
  const [saveResult, setSaveResult] = React.useState<ITransactionTypeShape | null>(null);

  const [updateTransactionTypeFn, { loading: updateTransactionTypeLoading }] = useUpdateTransactionType({
    onCompleted: (result) => {
      // refetch();
      setUpdatedGlCodesMapping(result.updateTransactionType.glCodeMapping ?? null);
      setSaveResult({
        name: result.updateTransactionType.name,
        glCode: result.updateTransactionType.glCode,
        roles: roleOptions.filter((x) => result.updateTransactionType.authorisedRoles?.includes(x.value)),
        isTuition: result.updateTransactionType.isTuition,
        glCodeMapping: result.updateTransactionType.glCodeMapping,
        isDebit: result.updateTransactionType.isDebit,
      });

      const resultTransactionType = result?.updateTransactionType;
      // forcing the null check as it is impossible to get a null here, or we have bigger problem to look at
      resultTransactionType!.glCode = result.updateTransactionType.glCode;
      resultTransactionType!.glCodeMapping = result.updateTransactionType.glCodeMapping;
      resultTransactionType!.isTuition = result.updateTransactionType.isTuition;
      dispatch(updateTransactionType(resultTransactionType!));
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });
  const [upsertTransactionTypeFn, { loading: upsertTransactionTypeLoading }] =
    useUpsertTransactionTypeAuthorizationRoles({
      onCompleted: (result) => {
        refetch();
        if (saveResult !== null) {
          setFormData(
            Object.assign(saveResult, {
              roles: roleOptions.filter((x) =>
                result.upsertTransactionTypeAuthorizationRoles.roleIds?.includes(x.value)
              ),
            })
          );
        }

        if (result?.upsertTransactionTypeAuthorizationRoles) {
          showToast(t('translation:billing.settings.transactions.roleAssociatedSuccessfully'), 'success');
          const resultTransactionType =
            result?.upsertTransactionTypeAuthorizationRoles.transactionTypeId === transactionTypeId
              ? transactionType
              : null;
          resultTransactionType!.authorisedRoles = result?.upsertTransactionTypeAuthorizationRoles.roleIds;
        }
      },
      onError: (error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      },
    });

  const handleUpdate = React.useCallback((update: ITransactionTypeShape) => {
    setFormData(update);
  }, []);

  const handleClose = () => {
    setFormData({
      name: transactionType.name ?? '',
      glCode: transactionType.glCode,
      roles: roleOptions.filter((x) => transactionType.authorisedRoles?.includes(x.value)),
      isTuition: transactionType.isTuition ?? false,
      glCodeMapping: transactionType.glCodeMapping,
      isDebit: transactionType.isDebit,
    });
    onClose();
  };

  const handleSubmit = React.useCallback(async () => {
    if (businessId) {
      if (!isDefaultTransactionType) {
        await updateTransactionTypeFn({
          variables: {
            input: {
              id: transactionTypeId,
              name: formData.name,
              isDebit: formData.isDebit!,
              glCode: formData.glCode,
              isTuition: formData.isTuition,
              glCodeMapping:
                (formData.glCodeMapping?.glCodeId ?? '') === (formData.glCodeMapping?.id ?? '')
                  ? null
                  : formData.glCodeMapping?.id === ''
                  ? { glCodeId: formData.glCodeMapping.glCodeId }
                  : formData.glCodeMapping,
            },
          },
        });
      }
      await upsertTransactionTypeFn({
        variables: {
          input: {
            businessId,
            data: {
              transactionTypeId: transactionTypeId,
              roleIds: formData.roles.length > 0 ? formData.roles.map((x) => x.value) : [],
            },
          },
        },
      });
      onClose();
    }
  }, [updateTransactionTypeFn, upsertTransactionTypeFn, businessId, formData, transactionTypeId]);

  const { isGlCodeMandatory } = useIsGlCodeInputValid(formData.glCode, formData.glCodeMapping);

  return (
    <>
      <RightModalDrawer
        open={isOpen}
        onClose={handleClose}
        title={t('translation:billing.settings.transactions.editTransactionType')}
      >
        <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '16px' }}>
          <Mui.Stack direction="column" spacing={2} marginLeft="16px">
            <Mui.Box>
              <Mui.FormLabel id="transaction-type-name">
                <span>{t('translation:billing.settings.transactions.typeName')}</span>
              </Mui.FormLabel>
              <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                <Mui.Typography variant="body1" fontSize="18px" sx={{ opacity: '38%' }}>
                  {formData.name}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>

            <Mui.FormLabel id="transaction-type-radio-group">
              {t('translation:billing.settings.transactions.typeOfTransaction')}
            </Mui.FormLabel>
            <Mui.RadioGroup
              value={formData.isDebit ? 'Debit' : 'Credit'}
              aria-labelledby="transaction-type-radio-group"
              name="transaction-type-radio-buttons-group"
              style={{ margin: 0 }}
            >
              <Mui.FormControlLabel
                disabled={true}
                value={'Debit'}
                style={{ margin: 0 }}
                control={<Mui.Radio size="small" style={{ margin: 0 }} />}
                label={<CurrencyTrans>Debit</CurrencyTrans>}
              />
              <Mui.FormControlLabel
                disabled={true}
                value={'Credit'}
                style={{ margin: 0 }}
                control={<Mui.Radio size="small" style={{ margin: 0 }} />}
                label={<CurrencyTrans>Credit</CurrencyTrans>}
              />
            </Mui.RadioGroup>
            {isRegion('US') && (
              <>
                <Mui.FormLabel id="tuition-radio-group">Is this tuition?</Mui.FormLabel>
                <Mui.RadioGroup
                  value={formData.isTuition ? 'Yes' : 'No'}
                  aria-labelledby="tuition-radio-group"
                  name="tuition-buttons-group"
                  onChange={(evt) =>
                    handleUpdate({ ...formData, isTuition: evt.target.value === 'Yes' ? true : false })
                  }
                  style={{ margin: 0 }}
                >
                  <Mui.FormControlLabel
                    disabled={isDefaultTransactionType}
                    value={'Yes'}
                    style={{ margin: 0 }}
                    control={<Mui.Radio size="small" style={{ margin: 0 }} />}
                    label={'Yes'}
                  />
                  <Mui.FormControlLabel
                    disabled={isDefaultTransactionType}
                    value={'No'}
                    style={{ margin: 0 }}
                    control={<Mui.Radio size="small" style={{ margin: 0 }} />}
                    label={'No'}
                  />
                </Mui.RadioGroup>
              </>
            )}
            <Mui.Box>
              <Mui.FormLabel id="transaction-type-center">
                <span>{capitalize(i18n.t('translation:spelling.center'))}</span>
              </Mui.FormLabel>
              <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                <Mui.Typography variant="body1" fontSize="18px" sx={{ opacity: '38%' }}>
                  {transactionType.center?.name ?? `All ${capitalize(i18n.t('translation:spelling.center_plural'))}`}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
            <SelectMultiple
              required
              label={t('translation:billing.settings.transactions.roles')}
              newLabelStyle
              onChange={(value) =>
                handleUpdate({
                  ...formData,
                  roles: value ?? [],
                })
              }
              options={roleOptions.sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []}
              value={formData.roles}
              getOptionLabel={(option: IRoleOption) => option.label}
              getOptionValue={(option: IRoleOption) => option.value}
              displayCountInContainerRenderer={(selectedCount) =>
                selectedCount > 0
                  ? selectedCount + ' ' + (selectedCount === 1 ? `Role` : `Roles`) + ' Selected'
                  : `All Roles`
              }
            />

            {!isDefaultTransactionType && (
              <>
                {k2GlCodeActivationCheck ? (
                  <GlCodesDropdown
                    required={isGlCodeMandatory}
                    glCodeMapping={formData.glCodeMapping}
                    onChange={(glCode, oldGlCode) => {
                      handleUpdate({
                        ...formData,
                        glCode: glCode?.glCode ?? null,
                        glCodeMapping: {
                          id: oldGlCode?.id ?? '',
                          glCodeId: glCode?.id ?? '',
                        },
                      });
                    }}
                    areaType={isDefaultTransactionType ? 'SYSTEM_TRANSACTION_TYPE' : 'CUSTOM_TRANSACTION_TYPE'}
                    updatedGlCodesMapping={updatedGlCodesMapping}
                  />
                ) : (
                  <Mui.FormControlLabel
                    label={t('translation:billing.settings.transactions.glCode')}
                    labelPlacement="top"
                    control={
                      <Mui.TextField
                        size="small"
                        value={formData.glCode}
                        onChange={(evt) =>
                          handleUpdate({ ...formData, glCode: evt.target.value.replace(/[\W_]/g, '') })
                        }
                        fullWidth
                        sx={styles.inputStyles}
                      />
                    }
                    componentsProps={{
                      typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
                    }}
                    style={{ margin: 0 }}
                  />
                )}
              </>
            )}
          </Mui.Stack>
        </Mui.DialogContent>

        <Mui.Box style={{ margin: '8px' }}>
          <Mui.DialogActions>
            <Mui.Button variant="cancel" onClick={() => handleClose()}>
              Cancel
            </Mui.Button>
            <LoadingButton
              color="secondary"
              loading={updateTransactionTypeLoading || upsertTransactionTypeLoading}
              variant="contained"
              onClick={() => handleSubmit()}
              id="modal-drawer-primary-btn"
            >
              Save
            </LoadingButton>
          </Mui.DialogActions>
        </Mui.Box>
      </RightModalDrawer>
    </>
  );
};

export default EditTransactionType;
