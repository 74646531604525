import { useGetCustomFieldByIdQuery, useGetSystemFieldsQuery } from 'generated/graphql';
import { SystemFieldBuilder } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/page-section/field-container/kt-field/system-field-builder';
import { useEffect, useMemo, useState } from 'react';
import { SystemField } from 'shared/types/enrollment-form';

export const SYSTEM_REQUIRED_FIELDS = ['firstName', 'lastName', 'phoneNumbers', 'relationships'];

const SYSTEM_DEFINED_FIELDS = [
  ...SYSTEM_REQUIRED_FIELDS,
  'email',
  'dateOfBirth',
  'middleName',
  'crn',
  'hasMedicalCondition',
  'hasImmunisation',
  'grade',
  'address',
  'billingCycle',
  'paymentMethod',
  'gender',
  'indigenousStatus',
];

/**
 * A proxified hook to return the SYSTEM FIELDS or the Backend'd field
 * @param businessId
 * @param customFieldId
 */
export const useFieldMetadata = (businessId: string, customFieldId: string) => {
  const [state, setState] = useState<{ fieldMetadata: SystemField | undefined; loading: boolean }>({
    fieldMetadata: undefined,
    loading: true,
  });
  const isSystemDefinedField = useMemo(() => SYSTEM_DEFINED_FIELDS.indexOf(customFieldId) > -1, [customFieldId]);

  const { data, loading } = useGetCustomFieldByIdQuery({
    // this is gonna hit the server multiple times but given its volumes, it's not a big deal and the result is cached
    variables: {
      businessId: businessId,
      customFieldId: customFieldId,
    },
    skip: isSystemDefinedField,
  });

  const { data: systemFieldsData } = useGetSystemFieldsQuery();

  useEffect(() => {
    const systemFieldFromDB = systemFieldsData?.getSystemFields?.find((f) => f?.id === customFieldId);
    if (isSystemDefinedField) {
      setState({ loading: false, fieldMetadata: SystemFieldBuilder.build(customFieldId) });
    } else if (!!systemFieldFromDB) {
      setState({
        fieldMetadata: systemFieldFromDB as SystemField,
        loading: false,
      });
    } else {
      setState({
        fieldMetadata: data?.getCustomFieldById as SystemField,
        loading: loading,
      });
    }
  }, [customFieldId, data, isSystemDefinedField, loading, systemFieldsData]);

  return { ...state };
};
