import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Container from 'react-bootstrap/Container';
import { union, difference, groupBy } from 'lodash';
import PageWrapper from 'shared/components/PageWrapper';
import { showTimezoneToast, showToast } from 'shared/components/Toast';
import TimeframeControls from './components/TimeframeControls';
import ViewControls from './components/ViewControls';
import DataControls from './components/DataControls';
import { useGetCenter, useGetCenterScheduleForWeek, useSearchSchedulableStaff } from './graphql/queries';
import {
  useCreateCenterLocation,
  useRemoveCenterLocation,
  useCreateNewSchedule,
  useCreateNewShift,
  useUpdateShift,
  useDeleteShifts,
  useCopySchedule,
  useCopyShift,
  usePublishSchedule,
  useUnpublishSchedule,
} from './graphql/mutations';
import { useGetTimeOffForScope } from 'pages/TimeManagement/subroutes/TimeOff/grapgql/queries';
import ScheduleContainer from './components/ScheduleContainer/ScheduleContainer';
import SideShiftModal, { IShiftFormShape } from './components/SideShiftModal/SideShiftModal';
import ScheduleStatusButton from './components/ScheduleStatusButton';
import ScheduleOverview from './components/ScheduleOverview';
import ScheduleViewContext from './providers/scheduleViewProvider';
import ScheduleDataFilterContext from './providers/scheduleDataFilterProvider';
import ShiftDetailsModal from './components/ShiftDetailsModal';
import DeleteShiftsModal from './components/DeleteShfitsModal';
import CopyScheduleModal from './components/CopyScheduleModal';
import CopyShiftModal from './components/CopyShiftModal';
import EmployeeOverview from './components/EmployeeOverview';
import TimeOffForWeek from './components/TimeOffForWeek';
import { exportScheduleToCSV, groupShifts, sortClassByRatio } from './services/scheduleService';
import { PrintService } from 'shared/services/printService';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useWindowSize from 'shared/hooks/useWindowSize';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useGetSessionDataForStaffSchedule } from 'gql/session/queries';
import { isTheSameTimezone } from '../helpers';

interface IScheduleState {
  startDate: moment.Moment;
  endDate: moment.Moment;
  calendarType: CalendarViewType;
  tableType: ScheduleTableView;
  dataViewType: ScheduleDataListType;
  selectedClassIds: string[];
  selectedStaffIds: string[];
  selectedLocationIds: string[];
  [key: string]: any; // for dynamic variable property in handleDataControlSelect()
}

interface IShiftCreationState {
  idToAssociateShiftTo: string; // classId or locationId
  idType: 'CLASS' | 'LOCATION';
  dateSelected: moment.Moment; // date used for context when clicking the plus button to create a shift
  modalOpen: boolean;
  existingShiftInformation?: IShift | null; // create and edit use the same component, if we are editing a shift that infromation will be saved in this variables
}

interface IShiftDetailState {
  shift: IShift | null;
  modalOpen: boolean;
}

interface IProps {}

const StaffSchedules: React.FC<IProps> = ({ ...props }) => {
  /**
   * FUTURE NOTE TO JAMES: k2WebWeekendScheduling is currently used in the following components:
   * StaffSchedules, ScheduleContainer, ClassSchedule, TimeOffForWeek
   *
   * since this flag will live in the application for awhile, I just used the flag where needed.
   * when the time comes to remove the flag and weekend scheduling is a feature, I highly recommend
   * putting that information in a Context Provider and passing having those components consume it
   */
  const { k2WebWeekendScheduling } = useFlags();
  const printService = new PrintService();
  const dateSettings = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;

  // state hooks
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const centerTimezone = timezonesByCenter[currentCenterId ?? ''] ?? momentTz.tz.guess();

  const [scheduleState, setScheduleState] = useState<IScheduleState>({
    startDate: moment().tz(centerTimezone).startOf(dateSettings.week),
    endDate: moment().tz(centerTimezone).endOf(dateSettings.week),
    dataViewType: 'CLASS',
    calendarType: 'WEEK',
    tableType: 'SHIFTS',
    selectedClassIds: [],
    selectedStaffIds: [],
    selectedLocationIds: [],
  });

  const startOfWeek = scheduleState.startDate.clone().startOf(dateSettings.week).toISOString();
  const endOfWeek = scheduleState.startDate.clone().endOf(dateSettings.week).toISOString();

  // state for the create shift modal
  const [shiftCreationState, setShiftCreationState] = useState<IShiftCreationState>({
    idToAssociateShiftTo: '',
    idType: 'CLASS',
    dateSelected: moment().tz(centerTimezone),
    modalOpen: false,
    existingShiftInformation: null,
  });
  // state for the view shift details modal
  const [shiftDetailState, setShiftDetailState] = useState<IShiftDetailState>({
    modalOpen: false,
    shift: null,
  });
  const [copyShiftModalState, setCopyShiftModalState] = useState<IShiftDetailState>({
    modalOpen: false,
    shift: null,
  });
  const [schedule, updateSchedule] = useState<ISchedule | null>(null);
  const [deleteShiftsModalOpen, setDeleteShiftsModalOpen] = useState<boolean>(false);
  const [copyScheduleModalOpen, setCopyScheduleModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isTheSameTimezone(centerTimezone)) {
      showTimezoneToast(centerTimezone);
    }

    // need to reset moment dates when time zone is updated
    setScheduleState({
      ...scheduleState,
      startDate: moment().tz(centerTimezone).startOf(dateSettings.week),
      endDate: moment().tz(centerTimezone).endOf(dateSettings.week),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerTimezone]);

  // data fetching hooks
  const { loading: loadingCenter, data: centerData } = useGetCenter(currentCenterId ?? '');
  const { loading: loadingActiveStaff, data: activeStaffData } = useSearchSchedulableStaff(currentCenterId ?? '');
  const {
    loading: loadingScheduleForWeek,
    data: centerScheduleForWeek,
    refetch: refetchCenterScheduleForWeek,
  } = useGetCenterScheduleForWeek(currentCenterId ?? '', startOfWeek);
  const { loading: timeOffForCenterLoading, data: timeOffForCenterData } = useGetTimeOffForScope({
    scopeType: 'CENTER',
    scopeId: currentCenterId ?? '',
    startTime: startOfWeek,
    endTime: endOfWeek,
  });

  const { loading: timeOffForBusinessLoading, data: timeOffForBusinessData } = useGetTimeOffForScope({
    scopeType: 'ENTITY',
    scopeId: currentBusinessId ?? '',
    startTime: startOfWeek,
    endTime: endOfWeek,
  });

  const getTimeOffRequests = () => {
    const centerTimeOffs = timeOffForCenterData?.getTimeOffRequestsByScope ?? [];
    const businessTimeOffs = timeOffForBusinessData?.getTimeOffRequestsByScope ?? [];
    return centerTimeOffs.concat(businessTimeOffs).filter((t) => t.person.role.scheduleVisibility);
  };

  const centerLocations = useMemo(() => {
    const shiftsByLocationId = groupBy(
      centerScheduleForWeek?.getCenterScheduleForWeek?.shifts ?? [],
      (shift) => shift.locationId
    );

    // even if the location is archived, still display it if there are shifts scheduled for it for the given week
    return (
      centerData?.getCenter.locations.filter(
        (location) =>
          !location.archivedAt ||
          moment(location.archivedAt).isAfter(startOfWeek) ||
          Boolean(shiftsByLocationId[location.id])
      ) ?? []
    );
  }, [centerData, startOfWeek, centerScheduleForWeek]);

  const centerClasses = useMemo(
    () =>
      sortClassByRatio(
        centerData?.getCenter.classes.filter((c) => {
          const hasNotEnded = c.endsAt ? moment(c.endsAt).isAfter(startOfWeek) : true;
          const isArchived = c.archivedAt && moment(c.archivedAt).isBefore(startOfWeek);
          return hasNotEnded && moment(c.startsAt).isBefore(endOfWeek) && !isArchived;
        }) ?? []
      ),
    [centerData, endOfWeek, startOfWeek]
  );
  const centerStaff = useMemo(() => activeStaffData?.searchSchedulableStaff.data ?? [], [activeStaffData]);

  const { loading: sessionsLoading, data: sessionData } = useGetSessionDataForStaffSchedule({
    variables: {
      input: { centerId: currentCenterId ?? '', startDate: startOfWeek, endDate: endOfWeek },
    },
  });

  // mutation hooks
  const [createCenterLocationFn, { loading: createLocationLoading }] = useCreateCenterLocation(currentCenterId ?? '');
  const [removeCenterLocationFn, { loading: removeLocationLoading }] = useRemoveCenterLocation(currentCenterId ?? '');
  const [createScheduleFn, { loading: createScheduleLoading }] = useCreateNewSchedule({
    onCompleted: (data) => {
      updateSchedule(data.createSchedule);
      showToast('Schedule created successfully.', 'success');
    },
    onError: (err) => {
      showToast('Something went wrong.', 'error');
    },
  });
  const [publishScheduleFn, { loading: publishScheduleLoading }] = usePublishSchedule({
    onCompleted: (data) => {
      schedule && updateSchedule({ ...schedule, publishStatus: 'PUBLISHED' });
      showToast('Schedule published successfully.', 'success');
    },
  });
  const [unpublishScheduleFn, { loading: unpublishScheduleLoading }] = useUnpublishSchedule({
    onCompleted: (data) => {
      schedule && updateSchedule({ ...schedule, publishStatus: 'DRAFT' });
      showToast('Schedule unpublished successfully.', 'success');
    },
  });
  const [copyScheduleFn, { loading: copyScheduleLoading }] = useCopySchedule({
    onCompleted: (data) => {
      setCopyScheduleModalOpen(false);
      showToast('Schedule copied successfully.', 'success');
      // Refetch schedule after copying schedule
      refetchCenterScheduleForWeek();
    },
    onError: (err) => {
      showToast('There was an error copying your schedule.', 'error');
    },
  });
  const [createShiftFn, { loading: createShiftLoading }] = useCreateNewShift({
    onCompleted: (data) => {
      const newShifts = data.createShift.filter((s) => s.scheduleId === schedule?.id);
      schedule &&
        updateSchedule({ ...schedule, shifts: schedule.shifts ? [...schedule.shifts, ...newShifts] : newShifts });
      setShiftCreationState((prev) => ({ ...prev, modalOpen: false }));
      showToast('Shift created successfully.', 'success');
      // Refetch schedule after creating shift
      refetchCenterScheduleForWeek();
    },
    onError: (err) => {
      console.log(err);
      showToast('Something went wrong.', 'error');
    },
  });
  const [updateShiftFn, { loading: updateShiftLoading }] = useUpdateShift({
    onCompleted: (data) => {
      schedule &&
        updateSchedule({
          ...schedule,
          shifts: schedule.shifts?.map((s) => (s.id === data.updateShift.id ? data.updateShift : s)),
        });
      setShiftCreationState((prev) => ({ ...prev, modalOpen: false }));
      showToast('Shift updated successfully.', 'success');
      // Refetch schedule after updating shift
      refetchCenterScheduleForWeek();
    },
    onError: (err) => {
      showToast('Something went wrong.', 'error');
    },
  });
  const [deleteShiftsFn, { loading: deleteShiftsLoading }] = useDeleteShifts({
    onCompleted: (data) => {
      schedule &&
        updateSchedule({ ...schedule, shifts: schedule.shifts?.filter((s) => !data.deleteShifts.includes(s.id)) });
      const multipleDeleted = data.deleteShifts.length > 1;
      setDeleteShiftsModalOpen(false);
      setShiftDetailState((prev) => ({ ...prev, modalOpen: false, shift: null }));
      showToast(`${multipleDeleted ? 'Shifts' : 'Shift'} deleted successfully.`, 'success');
      // Refetch schedule after deleting shift
      refetchCenterScheduleForWeek();
    },
  });
  const [copyShiftFn, { loading: copyShiftLoading }] = useCopyShift({
    onCompleted: (data) => {
      const newShifts = data.copyShift.filter((s) => s.scheduleId === schedule?.id);
      schedule &&
        updateSchedule({ ...schedule, shifts: schedule.shifts ? [...schedule.shifts, ...newShifts] : newShifts });
      setCopyShiftModalState((prev) => ({ ...prev, modalOpen: false, shift: null }));
      showToast('Copied shift successfully.', 'success');
      // Refetch schedule after copying shift
      refetchCenterScheduleForWeek();
    },
  });

  // effects
  useEffect(() => {
    updateSchedule(centerScheduleForWeek?.getCenterScheduleForWeek ?? null);
  }, [centerScheduleForWeek]);

  useEffect(() => {
    // watch for changes to the date and update the active classes for timeframe (uses the week's start date as the cut off)
    if (centerData?.getCenter) {
      // update our service with the active classes and locations for the week
      setScheduleState((prev) => ({
        ...prev,
        selectedClassIds: centerClasses.map((c: IClass) => c.id),
        selectedLocationIds: centerLocations.map((location) => location.id),
      }));
    }
  }, [centerClasses, centerData, centerLocations]);

  useEffect(() => {
    if (centerStaff) {
      setScheduleState((prev) => ({
        ...prev,
        selectedStaffIds: centerStaff.map((staff) => staff.id),
      }));
    }
  }, [centerStaff]);

  // functions
  /**
   * create an array of moment objects representing the visible dates of the current week
   */
  const visibleDates = useMemo((): moment.Moment[] => {
    const dates: moment.Moment[] = [];
    const start = scheduleState.startDate.clone();
    const end = scheduleState.endDate.clone();
    const current = start.clone();

    while (current.isSameOrBefore(end)) {
      if (k2WebWeekendScheduling) {
        dates.push(current.clone());
      } else if (current.day() > 0 && current.day() < 6) {
        // if the flag is turned off then we only care about weekdays
        dates.push(current.clone());
      }

      // only return the first day of the week, not sure if I like this lol
      if (scheduleState.calendarType === 'DAY' && dates.length === 1) {
        break;
      }

      current.add(1, 'day');
    }

    return dates;
  }, [scheduleState.startDate, scheduleState.endDate, scheduleState.calendarType, k2WebWeekendScheduling]);

  // handle all changes from the DataControl component to determine which data should be visible
  const handleDataControlSelect = useCallback(
    (data: any[], selected: boolean, type: 'CLASSES' | 'STAFF' | 'LOCATIONS') => {
      let statePropertyToUpdate: string;

      switch (type) {
        case 'CLASSES':
          statePropertyToUpdate = 'selectedClassIds';
          break;
        case 'STAFF':
          statePropertyToUpdate = 'selectedStaffIds';
          break;
        case 'LOCATIONS':
          statePropertyToUpdate = 'selectedLocationIds';
          break;
        default:
          return;
      }

      if (selected) {
        setScheduleState((prev) => ({
          ...prev,
          [statePropertyToUpdate]: union(
            prev[statePropertyToUpdate],
            data.map((s) => s.id)
          ),
        }));
      } else {
        setScheduleState((prev) => ({
          ...prev,
          [statePropertyToUpdate]: difference(
            prev[statePropertyToUpdate],
            data.map((s) => s.id)
          ),
        }));
      }
    },
    []
  );

  const handleChangeCalendarView = useCallback((view: CalendarViewType) => {
    setScheduleState((prev) => ({
      ...prev,
      calendarType: view,
      // if we are going from the day view -> week view, we need to reset our start and end dates for the week
      startDate: view === 'WEEK' ? prev.startDate.clone().startOf(dateSettings.week) : prev.startDate,
      endDate: view === 'WEEK' ? prev.startDate.clone().endOf(dateSettings.week) : prev.endDate,
    }));
  }, []);

  // call mutation to create a new custom location to schedule at
  const createNewCenterLocation = useCallback(
    (name: string) => {
      if (name && currentCenterId) {
        createCenterLocationFn({
          variables: {
            input: {
              name,
              centerId: currentCenterId,
            },
          },
        })
          .then(() => {
            showToast('Location created successfully.', 'success');
          })
          .catch(() => {
            showToast('There was an error trying to create your location.', 'error');
          });
      }
    },
    [currentCenterId, createCenterLocationFn]
  );

  // call mutation to remove a custom location
  const removeCenterLocation = useCallback(
    (id: string) => {
      if (id && currentCenterId) {
        removeCenterLocationFn({
          variables: {
            input: {
              id,
              centerId: currentCenterId,
            },
          },
        })
          .then(() => {
            showToast('Removed location successfully.', 'success');
          })
          .catch((err) => {
            showToast(
              `${err.graphQLErrors
                .map((err: any) => {
                  return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
                })
                .join(', ')}`,
              'error'
            );
          });
      }
    },
    [currentCenterId, removeCenterLocationFn]
  );

  // preparation to start creating a shift associated to either a Class or Location
  const addNewShiftAssociatedTo = useCallback(
    (date: moment.Moment, idToAssociateShiftTo: string, idType: 'CLASS' | 'LOCATION') => {
      setShiftCreationState({
        idToAssociateShiftTo,
        idType,
        dateSelected: date,
        modalOpen: true,
      });
    },
    []
  );

  // call mutation to create new shift(s) for the current week's schedule
  const createShiftForSchedule = useCallback(
    (shiftInfo: IShiftFormShape, dates: IDateTimeRange[], schedule: ISchedule) => {
      if (schedule) {
        createShiftFn({
          variables: {
            input: {
              scheduleId: schedule.id,
              centerId: schedule.centerId,
              personId: shiftInfo.personId ?? null,
              positionId: shiftInfo.positionId ?? null,
              classId: shiftInfo.classId,
              locationId: shiftInfo.locationId,
              breakMinutes: shiftInfo.breakMinutes,
              paidBreak: shiftInfo.paidBreak,
              note: shiftInfo.note ?? null,
              dates: dates,
            },
          },
        });
      }
    },
    [createShiftFn, shiftCreationState.idType, shiftCreationState.idToAssociateShiftTo]
  );

  // call mutation to update an existing shift
  const updateExistingShift = useCallback(
    (shiftInfo: IShiftFormShape) => {
      if (shiftInfo.id && schedule) {
        updateShiftFn({
          variables: {
            input: {
              id: shiftInfo.id,
              centerId: schedule.centerId,
              personId: shiftInfo.personId ?? null,
              positionId: shiftInfo.positionId ?? null,
              classId: shiftInfo.classId,
              locationId: shiftInfo.locationId,
              startTime: shiftInfo.startTime.toISOString(),
              endTime: shiftInfo.endTime.toISOString(),
              breakMinutes: shiftInfo.breakMinutes,
              paidBreak: shiftInfo.paidBreak,
              note: shiftInfo.note ?? null,
            },
          },
        });
      }
    },
    [schedule, updateShiftFn]
  );

  // handle the save action fomr <SideShiftModal />. this will either edit an existing shift or create a new one
  const saveShift = useCallback(
    async (shift: IShiftFormShape, dates: IDateTimeRange[] = []) => {
      if (shift.id) {
        updateExistingShift(shift);
      } else if (schedule) {
        createShiftForSchedule(shift, dates, schedule);
      } else {
        createScheduleFn({
          variables: {
            input: {
              centerId: currentCenterId ?? '',
              start: startOfWeek,
              end: endOfWeek,
            },
          },
        }).then((data) => {
          data?.data?.createSchedule && createShiftForSchedule(shift, dates, data.data?.createSchedule);
        });
      }
    },
    [schedule, currentCenterId, createScheduleFn, startOfWeek, endOfWeek, updateExistingShift, createShiftForSchedule]
  );

  // handle showing/dismissing modals when a shift has been selected to edit
  const selectShiftToEdit = useCallback(
    (shift: IShift) => {
      // dismiss the shift details modal...
      setShiftDetailState((prev) => ({ ...prev, modalOpen: false, shift: null }));

      // ...and open the side shift modal
      setShiftCreationState({
        modalOpen: true,
        idToAssociateShiftTo: shift.scheduleId,
        idType: shift.locationId ? 'LOCATION' : 'CLASS',
        dateSelected: moment(shift.startTime).tz(centerTimezone),
        existingShiftInformation: shift,
      });
    },
    [centerTimezone]
  );

  // call mutation to delete all shifts for the selected dates
  const deleteShiftsForDates = useCallback(
    (dates: moment.Moment[]) => {
      if (dates.length && schedule) {
        const shiftIdsForSelectedDates = schedule.shifts
          ?.filter((shift: IShift) => dates.some((d) => d.isSame(moment(shift.startTime).tz(centerTimezone), 'd')))
          ?.map((shift: IShift) => shift.id);

        deleteShiftsFn({
          variables: {
            input: {
              ids: shiftIdsForSelectedDates,
              centerId: schedule.centerId,
            },
          },
        });
      }
    },
    [centerTimezone, deleteShiftsFn, schedule]
  );

  const windowSize = useWindowSize();
  const isMobile = useCallback(() => windowSize.innerWidth <= 768, [windowSize]);

  return (
    <PageWrapper
      pageTitle={centerData?.getCenter ? `${centerData?.getCenter?.name} Schedule` : ''}
      buttonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Schedule, permission: PermissionType.Base, level: RoleLevelType.Create }}
        >
          <ScheduleStatusButton
            canCreateSchedule
            startOfCurrentWeek={startOfWeek}
            scheduleForCurrentWeek={schedule ?? null}
            loading={
              createScheduleLoading || publishScheduleLoading || unpublishScheduleLoading || loadingScheduleForWeek
            }
            onPublishSchedule={() =>
              publishScheduleFn({
                variables: {
                  id: schedule?.id ?? '',
                  centerId: schedule?.centerId ?? '',
                },
              })
            }
            onUnpublishSchedule={() =>
              unpublishScheduleFn({
                variables: {
                  id: schedule?.id ?? '',
                  centerId: schedule?.centerId ?? '',
                },
              })
            }
          />
        </HasRoleAreaLevel>
      }
    >
      <CenterSelectBanner className={!isMobile() ? 'mx-n8 mt-n8 mb-8' : undefined} pageName="the schedule" />
      <Container fluid>
        <div className="kt-staff-schedules-grid">
          <div>
            <DataControls
              dataViewType={scheduleState.dataViewType}
              loading={loadingCenter || loadingActiveStaff}
              activeClassesInTimeframe={centerClasses}
              activeStaff={centerStaff}
              activeLocations={centerLocations}
              selectedClassIds={scheduleState.selectedClassIds}
              selectedStaffIds={scheduleState.selectedStaffIds}
              selectedLocationIds={scheduleState.selectedLocationIds}
              locationButtonLoading={createLocationLoading || removeLocationLoading}
              onClassSelect={(classes: IClass[], selected: boolean) =>
                handleDataControlSelect(classes, selected, 'CLASSES')
              }
              onStaffSelect={(staff: IStaff[], selected: boolean) => handleDataControlSelect(staff, selected, 'STAFF')}
              onLocationSelect={(locations: ILocation[], selected: boolean) =>
                handleDataControlSelect(locations, selected, 'LOCATIONS')
              }
              onAddCustomLocation={(name: string) => createNewCenterLocation(name)}
              onRemoveLocation={(id: string) => removeCenterLocation(id)}
              onChangeDataView={(view: ScheduleDataListType) =>
                setScheduleState((prev) => ({ ...prev, dataViewType: view }))
              }
            />
          </div>
          <div>
            <ViewControls
              tableView={scheduleState.tableType}
              changeTableView={(view) => setScheduleState((prev) => ({ ...prev, tableType: view }))}
            />
            <TimeframeControls
              isSchedulePublished={schedule?.publishStatus === 'PUBLISHED' ?? false}
              startDate={scheduleState.startDate}
              endDate={scheduleState.endDate}
              calendarView={scheduleState.calendarType}
              changeCalendarView={handleChangeCalendarView}
              onDateChange={(date) =>
                setScheduleState((prev) => ({ ...prev, startDate: date.startDate, endDate: date.endDate }))
              }
              onCopySchedule={() => setCopyScheduleModalOpen(true)}
              onPrintSchedule={() => printService.printElementById('kt-employee-schedules-container')}
              onExportSchedule={() =>
                schedule && exportScheduleToCSV(schedule, centerClasses, centerLocations, centerTimezone)
              }
              onClearSchedule={() => setDeleteShiftsModalOpen(true)}
            />
            {scheduleState.dataViewType === 'CLASS' ? (
              <ScheduleContainer calendarType={scheduleState.calendarType} datesToRender={visibleDates}>
                {/* {!loadingScheduleForWeek && !getCurrentWeekSchedule() && <EmptyScheduleCard />} */}
                <ScheduleViewContext.Provider value={scheduleState.tableType}>
                  <ScheduleDataFilterContext.Provider
                    value={{
                      classIds: scheduleState.selectedClassIds,
                      locationIds: scheduleState.selectedLocationIds,
                      employeeIds: scheduleState.selectedStaffIds,
                    }}
                  >
                    <>
                      <HasRoleAreaLevel
                        action={{
                          area: AreaType.Schedule,
                          permission: PermissionType.TimeOff,
                          level: RoleLevelType.Read,
                        }}
                      >
                        <TimeOffForWeek
                          calendarType={scheduleState.calendarType}
                          loading={timeOffForCenterLoading || timeOffForBusinessLoading}
                          timeOffRequests={getTimeOffRequests()}
                          startOfWeek={moment(startOfWeek).tz(centerTimezone)}
                        />
                      </HasRoleAreaLevel>
                      <ScheduleOverview
                        classes={centerClasses}
                        locations={centerLocations}
                        shiftsForCurrentWeek={groupShifts(schedule ?? undefined)}
                        datesForWeek={visibleDates}
                        startOfWeek={moment(startOfWeek).tz(centerTimezone)}
                        calendarType={scheduleState.calendarType}
                        weekHasSchedule
                        loadingSchedule={loadingScheduleForWeek}
                        onNewShift={addNewShiftAssociatedTo}
                        onShiftSelect={(shift) => setShiftDetailState((prev) => ({ ...prev, modalOpen: true, shift }))}
                        isSchedulePublished={schedule?.publishStatus === 'PUBLISHED'}
                        sessions={sessionData?.getExpectedSessions ?? []}
                        sessionsLoading={sessionsLoading}
                      />
                    </>
                  </ScheduleDataFilterContext.Provider>
                </ScheduleViewContext.Provider>
              </ScheduleContainer>
            ) : (
              <ScheduleViewContext.Provider value={scheduleState.tableType}>
                <ScheduleDataFilterContext.Provider
                  value={{
                    classIds: scheduleState.selectedClassIds,
                    locationIds: scheduleState.selectedLocationIds,
                    employeeIds: scheduleState.selectedStaffIds,
                  }}
                >
                  <EmployeeOverview
                    datesForWeek={visibleDates}
                    employees={centerStaff}
                    shiftsForCurrentWeek={schedule?.shifts ?? []}
                  />
                </ScheduleDataFilterContext.Provider>
              </ScheduleViewContext.Provider>
            )}
          </div>
        </div>
      </Container>
      {shiftCreationState.modalOpen && (
        <SideShiftModal
          isOpen={shiftCreationState.modalOpen}
          onClose={() =>
            setShiftCreationState((prev) => ({
              ...prev,
              modalOpen: false,
              idToAssociateShiftTo: '',
              idType: 'CLASS',
              existingShiftInformation: null,
            }))
          }
          loading={createShiftLoading || updateShiftLoading}
          onSave={(data: IShiftFormShape, dates: IDateTimeRange[] = []) => saveShift(data, dates)}
          entityId={centerData?.getCenter.entityId ?? ''}
          centerId={centerData?.getCenter.id ?? ''}
          selectedDate={shiftCreationState.dateSelected.tz(centerTimezone)}
          datesForWeek={visibleDates}
          existingShiftToEdit={shiftCreationState.existingShiftInformation ?? null}
          timeOffRequestsForWeek={getTimeOffRequests()}
          locationId={shiftCreationState.idType === 'LOCATION' ? shiftCreationState.idToAssociateShiftTo : undefined}
          classId={shiftCreationState.idType === 'CLASS' ? shiftCreationState.idToAssociateShiftTo : undefined}
          classes={centerClasses}
          locations={centerLocations}
        />
      )}
      {shiftDetailState.modalOpen && (
        <ShiftDetailsModal
          isOpen={shiftDetailState.modalOpen}
          shift={shiftDetailState.shift}
          isLoading={deleteShiftsLoading}
          disableActionButtons={schedule?.publishStatus === 'PUBLISHED'}
          onClose={() => setShiftDetailState((prev) => ({ ...prev, modalOpen: false, shift: null }))}
          onCopyShift={(shift: IShift) => {
            // close details modal and open the copy shift modal
            setShiftDetailState((prev) => ({ ...prev, modalOpen: false, shift: null }));
            setCopyShiftModalState((prev) => ({ ...prev, modalOpen: true, shift }));
          }}
          onEditShift={selectShiftToEdit}
          onDeleteShift={(shift: IShift) =>
            deleteShiftsFn({
              variables: {
                input: {
                  ids: [shift.id],
                  centerId: schedule?.centerId ?? '',
                },
              },
            })
          }
        />
      )}
      <DeleteShiftsModal
        isOpen={deleteShiftsModalOpen}
        isLoading={deleteShiftsLoading}
        onClose={() => setDeleteShiftsModalOpen(false)}
        onSave={deleteShiftsForDates}
        scheduleStartDate={moment(schedule?.startTime).tz(centerTimezone)}
      />
      <CopyScheduleModal
        isOpen={copyScheduleModalOpen}
        isLoading={copyScheduleLoading}
        centerId={schedule?.centerId ?? ''}
        startOfVisibleWeek={moment(startOfWeek).tz(centerTimezone)}
        onClose={() => setCopyScheduleModalOpen(false)}
        onSave={(weekToCopyTo) =>
          copyScheduleFn({
            variables: {
              input: {
                id: schedule?.id ?? '',
                centerId: schedule?.centerId ?? '',
                weekToCopyTo: weekToCopyTo.toISOString(),
              },
            },
          })
        }
      />
      {copyShiftModalState.modalOpen && copyShiftModalState.shift && (
        <CopyShiftModal
          isOpen={copyShiftModalState.modalOpen}
          isLoading={copyShiftLoading}
          shift={copyShiftModalState.shift}
          classes={centerClasses}
          locations={centerLocations}
          onClose={() => setCopyShiftModalState((prev) => ({ ...prev, modalOpen: false, shift: null }))}
          onSave={(
            shiftId: string,
            dates: IDateTimeRange[],
            locationId: string | undefined,
            classId: string | undefined
          ) =>
            copyShiftFn({
              variables: {
                input: {
                  id: shiftId,
                  centerId: schedule?.centerId ?? '',
                  dates: dates,
                  locationId: locationId,
                  classId: classId,
                },
              },
            })
          }
        />
      )}
    </PageWrapper>
  );
};

export default StaffSchedules;
