import React from 'react';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { getInitials, stringToHsl } from 'shared/util/string';

interface IProps {
  data: IISCase;
}

export enum DayDisplayEnum {
  Monday = 'M',
  Tuesday = 'T',
  Wednesday = 'W',
  Thursday = 'Th',
  Friday = 'F',
  Saturday = 'S',
  Sunday = 'Su',
}

enum DayIndexEnum {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

interface ChildDayOfCare {
  child: IChild;
  childId: string;
  days: string[];
}

const ISCaseChildrenTable: React.FC<IProps> = ({ data }) => {
  const mapChildrenDays = (accumulator: Record<string, ChildDayOfCare>, currentChild: ISCaseChildDayOfCare) => {
    accumulator[currentChild.childId] = accumulator[currentChild.childId] ?? { ...currentChild };
    const child = accumulator[currentChild.childId];

    // initialize days
    child.days = child.days || [];
    const dayKey: number = DayIndexEnum[currentChild.dayOfCare];
    child.days[dayKey] = DayDisplayEnum[currentChild.dayOfCare];
    return accumulator;
  };

  const childrenDays = data.children.reduce(mapChildrenDays, {});
  return (
    <table id="children-details-table" className="table-simple">
      <thead>
        <tr>
          <th>Child</th>
          <th>Attending Days</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(childrenDays).map((key) => {
          const { child, childId } = childrenDays[key];
          return (
            <tr key={childId}>
              <td>
                <AvatarDataTableCell
                  size="sm"
                  color={stringToHsl(childId)}
                  initials={getInitials(child)}
                  avatar={child.avatar?.url ?? ''}
                  primaryText={child.fullName}
                />
              </td>
              <td className="pl-4">{childrenDays[childId].days.filter((day: string) => Boolean(day)).join(', ')}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ISCaseChildrenTable;
