import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ProgramGroupModal from '../ProgramGroupModal/ProgramGroupModal';
import { useCreateEnrollmentProgramGroup } from '../../../hooks/useCreateEnrollmentProgramGroup';
import useGetEnrollmentProgramTable from '../../../hooks/useGetEnrollmentProgramTable';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchProgramGroups: () => void;
}

const initialProgramGroupInput = {
  isActive: false,
  name: '',
  description: '',
  businessId: '',
  programIds: [],
};
const CreateProgramGroupModal: React.FC<IProps> = ({ isModalOpen, refetchProgramGroups, setIsModalOpen }) => {
  const { t } = useTranslation();

  const { tableProps, refetch } = useGetEnrollmentProgramTable({
    withNoProgramGroup: true,
    pageSize: 10,
  });

  const onCompleted = () => {
    showToast(t('enrollment.program-groups.create-program-group-success'), 'success');
    handleClose();
    refetchProgramGroups();
  };
  const { loading, businessTimeZone, values, onSubmit, onFieldUpdate, setValues } = useCreateEnrollmentProgramGroup({
    tableProps,
    onCompleted,
  });

  const handleClose = useCallback(() => {
    setValues(initialProgramGroupInput);
    setIsModalOpen(false);
    tableProps.tableFunctions.changePage(1, 10);
    tableProps.tableFunctions.updateSelectedRows([]);
  }, [setIsModalOpen]);

  useEffect(() => {
    if (isModalOpen) refetch();
  }, [isModalOpen]);

  return (
    <>
      <ProgramGroupModal
        title={t('enrollment.program-groups.create-program-group')}
        isModalOpen={isModalOpen}
        tableProps={tableProps}
        handleClose={handleClose}
        loading={loading}
        values={values}
        businessTimeZone={businessTimeZone}
        onSubmit={onSubmit}
        onFieldUpdate={onFieldUpdate}
      />
    </>
  );
};

export default CreateProgramGroupModal;
