// define all routes in this file, then it is used to both build the menu and build
// the list of routes for the router
import {
  faHomeAlt,
  faSchool,
  faUsersClass,
  faIdBadge,
  faChild,
  faBuilding,
  faReceipt,
  faLandmark,
  faUserPlus,
  faArrowToBottom,
  faAnalytics,
  faFileAlt,
  faCog,
  faBusinessTime,
  faTags,
  IconDefinition,
  faUserCircle,
  faUserCheck,
  faHistory,
  faCalendarAlt,
  faCalendarTimes,
  faUsers,
  faRocketLaunch,
  faUserChart,
  faMoneyCheckAlt,
  faCalendarCheck,
  faCreditCard,
  faBook,
  faDollarSign,
  faChartLine,
  faTools,
  faFileInvoiceDollar,
  faLock,
  faBadgePercent,
  faImage,
  faBusSchool,
  faCalendarEdit,
  faImages,
  faKey,
  faBrowser,
  faBookAlt,
  faComments,
  faPuzzlePiece,
  faFileSpreadsheet,
  faThumbtack,
  faChalkboardTeacher,
  faGraduationCap,
  faEnvelopesBulk,
  faCalendarLinesPen,
  faMoneyCheckDollar,
} from '@fortawesome/pro-light-svg-icons';
import Api from 'pages/Api';
import Dashboard from 'pages/Dashboard';
import BusinessProfiles from 'pages/Businesses/subroutes/BusinessProfiles';
import CenterProfiles from 'pages/Centers/subroutes/Profiles';
import StaffProfiles from 'pages/Employees/subroutes/Profiles';
import CreatedStaffProfiles from 'pages/Employees/subroutes/CreatedStaffProfiles';
import InvitedStaffProfiles from 'pages/Employees/subroutes/InvitedStaffProfiles';
import RequestedStaffProfiles from 'pages/Employees/subroutes/RequestedStaffProfiles';
import Regulations from 'pages/Regulations';
import BusinessProfile from 'pages/Businesses/subroutes/BusinessProfile';
import CenterProfile from 'pages/Centers/subroutes/Profile';
import StaffProfile from 'pages/Employees/subroutes/Profile';
import CenterClasses from 'pages/Centers/subroutes/Classes';
import ManageTags from 'pages/Businesses/subroutes/ManageTags/ManageTags';
import CenterOperations from 'pages/Settings/Operations';

import action from 'shared/constants/enums/actionEnum';
import MyProfile from 'pages/MyProfile';
import NotificationsV2 from 'pages/NotificationsV2';
import Class from 'pages/Centers/subroutes/Class';
import StaffSchedules from 'pages/TimeManagement/subroutes/StaffSchedules/StaffSchedules';
import { MyTimeOff, EmployeeTimeOff } from 'pages/TimeManagement/subroutes/TimeOff';
import MySchedule from 'pages/TimeManagement/subroutes/MySchedule';
import EmployeeAttendance from 'pages/TimeManagement/subroutes/TimeLogs';
import EmployeeTimeSheets from 'pages/TimeManagement/subroutes/TimeSheets';
import Contacts from 'pages/Families/subroutes/Contacts/Contacts';
import Contact from 'pages/Families/subroutes/Contacts/Contact';
import Children from 'pages/Families/subroutes/Children';
import Child from 'pages/Families/subroutes/Children/Child';
import Accounts from 'pages/Families/subroutes/Accounts';
import Account from 'pages/Families/subroutes/Accounts/Account';
import EnrollmentDashboard from 'pages/Enrollment/subroutes/Dashboard';
import EnrollmentManagement from 'pages/Enrollment/subroutes/Management';
import Fees from 'pages/Centers/subroutes/Fees';
import Attendance, { TodayAttendance } from 'pages/Attendance';
import AttendanceSettings from 'pages/AttendanceSettings';
import Transactions from 'pages/BillingTransactions';
import BillingSettings from 'pages/Billing/Settings';
import Subsidies from 'pages/Subsidies';
import { isRegion } from './region';
import CCSSMessages from 'pages/CCSSMessages';
import Payments from 'pages/BillingPayments';
import CcsManagement from 'pages/Businesses/subroutes/CcsManagement';
import KindyFunding from 'pages/Subsidies/KindyFunding/KindyFunding';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import ISCases from 'pages/ISCases';
import BillingDashboard from 'pages/BillingDashboard';
import BusinessConfiguration from 'pages/Settings/BusinessConfiguration';
import ServiceFeeGroups from 'pages/Settings/ServiceFeeConfiguration/subroutes/ServiceFeeGroups';
import ServiceFeeGroup from 'pages/Settings/ServiceFeeConfiguration/subroutes/ServiceFeeGroup';
import Reporting from 'pages/Reporting';
import Statement from 'pages/Billing/Statements/Statement';
import BillingAccounts from 'pages/BillingAccounts/BillingAccounts';
import USBillingAccounts from 'US/Billing/BillingAccounts';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import RoleHierarchy from 'pages/Settings/RoleHierarchy';
import RolePermissions from 'pages/Settings/RolePermissions';
import Programs from 'pages/Centers/subroutes/Programs/Programs';
import Discounts from 'pages/BillingDiscounts';
import EnrollmentSettings from 'pages/Enrollment/subroutes/Settings/EnrollmentSettings';
import Agencies from 'pages/USSubsidies/Agencies';
import Program from 'pages/Centers/subroutes/Program/Program';
import SubsidyChildren from 'pages/USSubsidies/SubsidyChildren';
import LeadManagement from 'pages/Enrollment/subroutes/LeadManagement';
import Photos from 'pages/Photos/subroutes/Dashboard';
import AgencyBilling from 'pages/USSubsidies/AgencyBilling';
import SubsidyPayment from 'pages/USSubsidies/SubsidyPayment';
import BusRoster from 'pages/BusRoster/BusRoster';
import CreateBusRoster from 'pages/BusRosterCreate';
import EditBusRoster from 'pages/BusRosterEdit';
import TimeCards from 'pages/TimeManagement/subroutes/TimeCards';
import PhotoSettings from 'pages/Photos/subroutes/PhotoSettings';
import Gallery from 'pages/Photos/subroutes/Gallery';
import ApiKeyManagement from '../../pages/Api/Keys/ApiKeyManagment';
import EnrollmentPrograms from 'pages/Enrollment/subroutes/Programs';
import BusinessSettings from 'pages/Businesses/subroutes/BusinessSettings';
import EditRoyaltyFee from 'pages/Businesses/subroutes/BusinessSettings/components/RoyaltyFees/EditRoyaltyFee';
import ManageClosures from 'pages/Attendance/subroutes/manageClosures';
import ManageCapacities from 'pages/Attendance/subroutes/manageCapacities';
import EnrollmentFormDetail from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/enrollment-form-detail';
import AgencyPayments from 'pages/USSubsidies/AgencyPayments';
import CreateAgencyPayment from 'pages/USSubsidies/CreateAgencyPayment';
import EndOfCareTab from '../../pages/Subsidies/EndOfCareTab';
import { AnalyticsFE } from 'AnalyticsMicroFrontend';
import Messaging from 'pages/Engagement/subroutes/Messaging';
import BulletinBoards from 'pages/Engagement/subroutes/BulletinBoards';
import MyTimeCard from '../../pages/TimeManagement/subroutes/TimeCards/MyTimeCards';
import MessagingSettings from 'pages/Engagement/subroutes/Settings/MessagingSettings';
import Integrations from '../../pages/Settings/Integrations/Integrations';
import CreateBulkTransaction from 'pages/Billing/BulkTransactions/CreateBulkTransaction';
import BulkTransactionsDetailsPage from 'pages/Billing/BulkTransactions/pages/BulkTransactionsDetailsPage';
import AgencyPayment from 'pages/USSubsidies/AgencyPayment';
import { routes as AgencyRecRoutes } from '../../USSubsidies/AgencyReconciliation';
import { routes as ItemizedBillRoutes } from '../../ItemizedBIlls';
import Communications from 'pages/Communications/Communications';
import Channel from 'pages/Communications/Channel';
import _ from 'lodash';
import SiteMap from 'pages/SiteMap';
import FranchiseRoyaltyReport from 'pages/FranchiseRoyaltyReport';

export interface IRoute {
  name: string;
  icon?: IconDefinition | null;
  route: string;
  component?: React.ReactNode | null;
  doNotShowInSidebar?: boolean; // don't show in main purple sidebar
  default?: boolean;
  baseAppRoute?: boolean;
  matchesActiveRoute?: string;
  requiredPermission?: string;
  subRoutes?: Record<string, IRoute>;
  launchDarklyFlag?: string; // key of a flag in LaunchDarkly. value needs to be true in order for the route to be included
  negativeLaunchDarklyFlag?: string; // key of a flag in LaunchDarkly. if value is true, the route will not be included.
  navLinkTo?: string; //unless provided, will use route in nav link
  requiredAreaLevel?: IAreaPermissionLevel[]; // handle case where different areas are nested under a navigation option (i.e. Center, Class, Fee)
  ignoreLaunchDarklyFlag?: boolean; // if true, route will be included regardless of launchDarklyFlag
  matchReduxStoreValue?: {
    // if the value in the reduxStorePath match expectedValue, the route will be included
    reduxStorePath: string;
    expectedValue: any;
  };
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

// every route must contain subroutes and each route must have a default route
const routes: Record<string, IRoute> = {
  home: {
    name: 'Home',
    icon: faHomeAlt,
    route: '/home',
    component: Dashboard,
    default: true,
    baseAppRoute: true,
  },
  user: {
    name: 'User',
    icon: faUserCircle,
    route: '/user',
    doNotShowInSidebar: true,
    component: MyProfile,
    subRoutes: {
      timeCard: {
        name: 'My Time Cards',
        icon: faHistory,
        route: '/user/time-cards',
        component: MyTimeCard,
        doNotShowInSidebar: true,
      },
      schedule: {
        name: 'My Schedule',
        icon: faCalendarAlt,
        route: '/user/schedule',
        component: MySchedule,
        doNotShowInSidebar: true,
      },
      timeOff: {
        name: 'My Time Off',
        icon: faCalendarTimes,
        route: '/user/time-off',
        component: MyTimeOff,
        doNotShowInSidebar: true,
      },
      profileTabs: {
        name: 'My Profile',
        icon: faUserCircle,
        route: '/user/profile/:activeTab',
        component: MyProfile,
        doNotShowInSidebar: true,
      },
    },
  },
  regulations: {
    //internal
    name: 'Regulations',
    icon: faFileAlt,
    route: '/regulations',
    component: Regulations,
    default: true,
    requiredPermission: action.INTERNAL_ONLY,
  },
  businesses: {
    name: 'Business',
    icon: faBuilding,
    route: '/businesses',
    requiredAreaLevel: [
      {
        area: AreaType.Business,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Business,
        permission: PermissionType.Tags,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      profiles: {
        //internal
        requiredPermission: action.INTERNAL_ONLY,
        name: 'Profiles',
        icon: faBuilding,
        route: '/businesses/profiles',
        component: BusinessProfiles,
        default: true,
        requiredAreaLevel: [
          {
            area: AreaType.Business,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      profile: {
        name: 'Profile',
        // config & CCMS -- but this is cross dependant
        icon: faBuilding,
        route: '/businesses/:id',
        component: BusinessProfile,
        default: true,
        requiredAreaLevel: [
          {
            area: AreaType.Business,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      profileTabs: {
        name: 'Profile',
        icon: faBuilding,
        route: '/businesses/:id/:activeTab',
        component: BusinessProfile,
        default: true,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Business,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      ccsAccount: {
        name: 'CCS Provider Management',
        icon: faLandmark,
        route: '/ccs/:activeTab',
        navLinkTo: '/ccs/provider-management',
        component: CcsManagement,
        doNotShowInSidebar: !isRegion('AU'),
        requiredPermission: action.VIEW_CCSS,
      },
      manageTags: {
        name: 'Tags',
        icon: faTags,
        route: '/manage-tags',
        component: ManageTags,
        requiredAreaLevel: [
          {
            area: AreaType.Business,
            permission: PermissionType.Tags,
            level: RoleLevelType.Read,
          },
        ],
      },
      settings: {
        name: 'Settings',
        icon: faCog,
        route: '/business/:id/settings',
        component: BusinessSettings,
        requiredAreaLevel: [
          {
            area: AreaType.Business,
            permission: PermissionType.Tags,
            level: RoleLevelType.Read,
          },
        ],
      },
      editRoyaltyFee: {
        name: 'Royalty Fee',
        route: '/business/:id/settings/royaltyFee/:feeId',
        doNotShowInSidebar: true,
        component: EditRoyaltyFee,
        launchDarklyFlag: 'k2RoyaltyFeeConfiguration',
        requiredAreaLevel: [
          {
            area: AreaType.Business,
            permission: PermissionType.Tags,
            level: RoleLevelType.Read,
          },
        ],
      },
    },
  },
  centers: {
    name: `${fieldLabels.center}s`,
    icon: faSchool,
    route: '/centers',
    requiredAreaLevel: [
      {
        area: AreaType.Center,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Center,
        permission: PermissionType.Classes,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Center,
        permission: PermissionType.Fees,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      profiles: {
        name: 'Profiles',
        icon: faSchool,
        route: '/centers/profiles',
        component: CenterProfiles,
        default: true,
        requiredAreaLevel: [
          {
            area: AreaType.Center,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      profile: {
        name: 'Profile',
        icon: null,
        route: '/centers/profiles/:id',
        component: CenterProfile,
        matchesActiveRoute: '/centers/profiles',
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Center,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      classes: {
        name: 'Classes',
        icon: faUsersClass,
        route: '/centers/classes',
        component: CenterClasses,
        requiredAreaLevel: [
          {
            area: AreaType.Center,
            permission: PermissionType.Classes,
            level: RoleLevelType.Read,
          },
        ],
      },
      class: {
        name: 'Class',
        icon: null,
        route: '/centers/classes/:id',
        matchesActiveRoute: '/centers/classes',
        component: Class,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Center,
            permission: PermissionType.Classes,
            level: RoleLevelType.Read,
          },
        ],
      },
      addClass: {
        name: 'Add Class',
        icon: null,
        route: '/centers/classes/new',
        matchesActiveRoute: '/centers/classes',
        component: Class,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Center,
            permission: PermissionType.Classes,
            level: RoleLevelType.Create,
          },
        ],
      },
      fees: {
        name: 'Fees',
        icon: faMoneyCheckAlt,
        route: '/centers/fees',
        component: Fees,
        requiredAreaLevel: [
          {
            area: AreaType.Center,
            permission: PermissionType.Fees,
            level: RoleLevelType.Read,
          },
        ],
      },
      programs: {
        name: 'Programs',
        icon: faBook,
        doNotShowInSidebar: !isRegion('AU'),
        route: '/centers/programs',
        component: Programs,
        requiredAreaLevel: [
          {
            area: 'Agency',
            level: RoleLevelType.Read,
            permission: 'Base',
          },
        ],
      },
      program: {
        requiredAreaLevel: [
          {
            area: 'Agency',
            level: RoleLevelType.Read,
            permission: 'Base',
          },
        ],
        name: 'Program',
        icon: null,
        route: '/centers/programs/:id',
        component: Program,
        doNotShowInSidebar: true,
      },
    },
  },
  employees: {
    name: 'Employees',
    icon: faIdBadge,
    route: '/employees',
    requiredAreaLevel: [
      {
        area: AreaType.Schedule,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Schedule,
        permission: PermissionType.TimeLog,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Schedule,
        permission: PermissionType.TimeOff,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Staff,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      profiles: {
        name: 'Profiles',
        icon: faIdBadge,
        route: '/employees/profiles',
        component: StaffProfiles,
        default: true,
        requiredAreaLevel: [
          {
            area: AreaType.Staff,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
        subRoutes: {
          created: {
            name: 'Created',
            route: '/employees/profiles/created',
            component: CreatedStaffProfiles,
            requiredAreaLevel: [
              {
                area: AreaType.Staff,
                permission: PermissionType.Base,
                level: RoleLevelType.Create,
              },
            ],
          },
          invited: {
            name: 'Invited',
            route: '/employees/profiles/invited',
            component: InvitedStaffProfiles,
            requiredAreaLevel: [
              {
                area: AreaType.Staff,
                permission: PermissionType.Base,
                level: RoleLevelType.Read,
              },
            ],
          },
          requested: {
            name: 'Requested',
            route: '/employees/profiles/requested',
            component: RequestedStaffProfiles,
            requiredAreaLevel: [
              {
                area: AreaType.Staff,
                permission: PermissionType.Base,
                level: RoleLevelType.Read,
              },
            ],
          },
        },
      },
      profile: {
        name: 'Profile',
        icon: null,
        route: '/employees/profiles/:id',
        component: StaffProfile,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Staff,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      profileTabs: {
        name: 'Profile',
        icon: null,
        route: '/employees/profiles/:id/:activeTab',
        component: StaffProfile,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Staff,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      timeLog: {
        name: 'Time Log',
        icon: faHistory,
        route: '/employees/time-logs/:activeTab',
        navLinkTo: '/employees/time-logs/time-log',
        component: EmployeeAttendance,
        requiredAreaLevel: [
          {
            area: AreaType.Schedule,
            permission: PermissionType.TimeLog,
            level: RoleLevelType.Read,
          },
        ],
      },
      schedule: {
        name: 'Schedule',
        icon: faCalendarAlt,
        route: '/employees/schedule',
        component: StaffSchedules,
        requiredAreaLevel: [
          {
            area: AreaType.Schedule,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      timeOff: {
        name: 'Time Off',
        icon: faCalendarTimes,
        route: '/employees/time-off',
        component: EmployeeTimeOff,
        requiredAreaLevel: [
          {
            area: AreaType.Schedule,
            permission: PermissionType.TimeOff,
            level: RoleLevelType.Read,
          },
        ],
      },
      timeSheets: {
        name: 'Time Sheets',
        doNotShowInSidebar: true,
        route: '/employees/time-log/time-sheets',
        component: EmployeeTimeSheets,
        requiredAreaLevel: [
          {
            area: AreaType.Schedule,
            permission: PermissionType.TimeLog,
            level: RoleLevelType.Read,
          },
        ],
      },
      timeCard: {
        name: 'Time Card',
        doNotShowInSidebar: true,
        route: '/employees/time-log/time-card',
        component: TimeCards,
        requiredAreaLevel: [
          {
            area: AreaType.Schedule,
            permission: PermissionType.TimeLog,
            level: RoleLevelType.Read,
          },
        ],
      },
    },
  },
  families: {
    name: 'Families',
    icon: faUsers,
    route: '/families',
    requiredAreaLevel: [
      {
        area: AreaType.Account,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Contact,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Child,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      accounts: {
        name: 'Accounts',
        icon: faUsers,
        route: '/families/accounts',
        component: Accounts,
        requiredAreaLevel: [
          {
            area: AreaType.Account,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      account: {
        name: 'Account',
        icon: null,
        route: '/families/accounts/:id/:activeTab/:subtab?',
        component: Account,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Account,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
        subRoutes: { ...ItemizedBillRoutes },
      },
      contacts: {
        name: 'Contacts',
        icon: faUserCheck,
        route: '/families/contacts',
        component: Contacts,
        requiredAreaLevel: [
          {
            area: AreaType.Contact,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      contact: {
        name: 'Contact',
        icon: null,
        route: '/families/contacts/:id',
        component: Contact,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Contact,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      children: {
        name: 'Children',
        icon: faChild,
        route: '/families/children',
        component: Children,
        requiredAreaLevel: [
          {
            area: AreaType.Child,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      child: {
        name: 'child',
        route: '/families/children/:id',
        component: Child,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Child,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
    },
  },
  enrollment: {
    name: `${fieldLabels.enrollment}`,
    icon: faUserPlus,
    route: '/enrollment',
    requiredAreaLevel: [
      {
        area: AreaType.Enrollment,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      dashboard: {
        name: 'Dashboard',
        icon: faUserChart,
        route: '/enrollment/dashboard',
        component: EnrollmentDashboard,
        requiredAreaLevel: [
          {
            area: AreaType.Enrollment,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
        launchDarklyFlag: 'k2EnrolmentsDashboard',
      },
      managment: {
        name: 'Management',
        route: '/enrollment/management',
        component: EnrollmentManagement,
        icon: faUsersClass,
        requiredAreaLevel: [
          {
            area: AreaType.Enrollment,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      leadManagment: {
        name: 'Lead Management',
        route: '/enrollment/lead-management',
        component: LeadManagement,
        icon: faUsersClass,
        requiredAreaLevel: [
          {
            area: AreaType.Enrollment,
            permission: PermissionType.LeadManagementMain,
            level: RoleLevelType.Read,
          },
        ],
        subRoutes: {
          leadManagmentByType: {
            name: 'Lead Management',
            icon: null,
            route: '/enrollment/lead-management/:stage',
            matchesActiveRoute: '/enrollment/lead-management',
            component: LeadManagement,
            doNotShowInSidebar: true,
            requiredAreaLevel: [
              {
                area: AreaType.Enrollment,
                permission: PermissionType.LeadManagementMain,
                level: RoleLevelType.Read,
              },
            ],
          },
        },
      },
      programs: {
        name: 'Programs',
        route: '/enrollment/programs/:activeTab',
        navLinkTo: '/enrollment/programs/individual',
        component: EnrollmentPrograms,
        icon: faBook,
        requiredAreaLevel: [
          {
            area: AreaType.Enrollment,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
        matchReduxStoreValue: {
          reduxStorePath: 'entities.allEntities.{{businessId}}.applicationFlowType',
          expectedValue: ['INQUIRE_PROGRAM_ENROLLMENT_APPROVE', 'INQUIRE_PROGRAM_FORM_PAY_APPROVE'],
        },
      },
      settings: {
        name: 'Settings',
        route: '/enrollment/settings/:activeTab',
        navLinkTo: '/enrollment/settings/links',
        component: EnrollmentSettings,
        icon: faCog,
        requiredAreaLevel: [
          {
            area: AreaType.Enrollment,
            permission: PermissionType.LeadManagementSettings,
            level: RoleLevelType.Read,
          },
        ],
        subRoutes: {
          enrollmentForm: {
            route: '/enrollment/settings/enrollment-form/:id',
            component: EnrollmentFormDetail,
            name: 'Enrollment Form',
          },
        },
      },
    },
  },
  attendanceWithCasualBooking: {
    name: 'Attendance',
    icon: faCalendarCheck,
    route: '/attendance',
    requiredAreaLevel: [
      {
        area: AreaType.Attendance,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      attendance: {
        name: 'Attendance / Bookings',
        icon: faCalendarEdit,
        route: '/attendance/attendance',
        component: Attendance,
        requiredAreaLevel: [
          {
            area: AreaType.Attendance,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      today: {
        name: 'Today',
        icon: faCalendarCheck,
        route: '/attendance/today',
        component: TodayAttendance,
        requiredAreaLevel: [
          {
            area: AreaType.Attendance,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      manageCapacities: {
        name: 'Manage Capacities',
        route: '/attendance/manage/capacities',
        component: ManageCapacities,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Attendance,
            permission: PermissionType.CasualBookingManagement,
            level: RoleLevelType.Edit,
          },
        ],
      },
      manageClosures: {
        name: 'Manage Closures',
        route: '/attendance/manage/closures',
        component: ManageClosures,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Attendance,
            permission: PermissionType.CasualBookingManagement,
            level: RoleLevelType.Edit,
          },
        ],
      },
      settings: {
        name: 'Settings',
        icon: faCog,
        route: '/attendance/settings/:activeTab',
        navLinkTo: '/attendance/settings/booking-rules',
        component: AttendanceSettings,
        launchDarklyFlag: 'k2CasualBooking',
        requiredAreaLevel: [
          {
            area: AreaType.Attendance,
            permission: PermissionType.CasualBookingSettings,
            level: RoleLevelType.Edit,
          },
        ],
      },
    },
  },
  busRoster: {
    name: 'Bus Roster',
    icon: faBusSchool,
    route: '/bus-roster',
    launchDarklyFlag: 'k2BusRoster',
    component: BusRoster,
    requiredAreaLevel: [
      {
        area: AreaType.Attendance,
        permission: PermissionType.BusRoster,
        level: RoleLevelType.Read,
      },
    ],
  },
  newBusRoster: {
    name: 'New Bus Roster',
    route: '/bus-roster/create',
    component: CreateBusRoster,
    doNotShowInSidebar: true,
    launchDarklyFlag: 'k2BusRoster',
    requiredAreaLevel: [
      {
        area: AreaType.Attendance,
        permission: PermissionType.BusRoster,
        level: RoleLevelType.Create,
      },
    ],
  },
  updateBusRoster: {
    name: 'Edit Bus Roster',
    route: '/bus-roster/edit/:id',
    component: EditBusRoster,
    doNotShowInSidebar: true,
    launchDarklyFlag: 'k2BusRoster',
    requiredAreaLevel: [
      {
        area: AreaType.Attendance,
        permission: PermissionType.BusRoster,
        level: RoleLevelType.Edit,
      },
    ],
  },
  photos: {
    name: 'Photos',
    icon: faImage,
    route: '/photos',
    launchDarklyFlag: 'k2WebPhotoGallery',
    ignoreLaunchDarklyFlag: isRegion('US'),
    requiredAreaLevel: [
      {
        area: AreaType.Activities,
        permission: PermissionType.Photo,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      dashboard: {
        name: 'Dashboard',
        icon: faImage,
        route: '/photos/dashboard',
        component: Photos,
      },
      gallery: {
        name: 'Gallery',
        icon: faImages,
        route: '/photos/gallery',
        component: Gallery,
      },
      settings: {
        name: 'Settings',
        icon: faCog,
        route: '/photos/settings',
        component: PhotoSettings,
        requiredAreaLevel: [
          {
            area: AreaType.Activities,
            permission: PermissionType.Photo,
            level: RoleLevelType.Edit,
          },
        ],
      },
    },
  },
  subsidies: {
    name: 'Subsidies',
    icon: faLandmark,
    route: '/subsidies',
    component: Subsidies,
    doNotShowInSidebar: !isRegion('AU'),
    requiredPermission: action.VIEW_CCSS,
    subRoutes: {
      dashboard: {
        name: 'CCS Subsidies',
        icon: faLandmark,
        route: '/subsidies',
        component: Subsidies,
        doNotShowInSidebar: !isRegion('AU'),
        requiredPermission: action.VIEW_CCSS,
      },
      messages: {
        name: 'CCS Messages',
        icon: faLandmark,
        route: '/ccs-messages',
        component: CCSSMessages,
        doNotShowInSidebar: !isRegion('AU'),
        requiredPermission: action.VIEW_CCSS,
      },
      isCases: {
        name: 'IS Cases',
        icon: faLandmark,
        route: '/is-cases/:activeTab',
        navLinkTo: '/is-cases/summary',
        component: ISCases,
        doNotShowInSidebar: !isRegion('AU'),
        requiredPermission: action.VIEW_CCSS,
      },
      kindyFunding: {
        name: 'Kindy Funding',
        icon: faLandmark,
        route: '/kindy-funding',
        component: KindyFunding,
        doNotShowInSidebar: !isRegion('AU'),
        requiredPermission: action.VIEW_CCSS,
      },
      endOfCare: {
        name: 'End of Care',
        icon: faLandmark,
        route: '/end-of-care/:activeTab',
        navLinkTo: '/end-of-care/active',
        component: EndOfCareTab,
        doNotShowInSidebar: !isRegion('AU'),
        requiredPermission: action.VIEW_CCSS,
        requiredAreaLevel: [
          {
            area: AreaType.Enrollment,
            permission: PermissionType.EndOfCare,
            level: RoleLevelType.Read,
          },
        ],
        launchDarklyFlag: 'k2EndOfCare',
      },
    },
  },
  usSubsidies: {
    name: 'Subsidies',
    icon: faLandmark,
    route: '/subsidies',
    doNotShowInSidebar: !isRegion('US'),
    requiredAreaLevel: [
      {
        area: AreaType.Agency,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Agency,
        permission: PermissionType.AgencyMain,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Agency,
        permission: PermissionType.AgencyChild,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Billing,
        permission: PermissionType.Payments,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      agencies: {
        name: 'Agencies',
        route: '/subsidies/agencies',
        component: Agencies,
        icon: faLandmark,
        requiredAreaLevel: [
          {
            area: AreaType.Agency,
            permission: PermissionType.AgencyMain,
            level: RoleLevelType.Read,
          },
        ],
      },
      children: {
        name: 'Children',
        route: '/subsidies/children',
        component: SubsidyChildren,
        icon: faChild,
        requiredAreaLevel: [
          {
            area: AreaType.Agency,
            permission: PermissionType.AgencyChild,
            level: RoleLevelType.Read,
          },
        ],
      },
      billing: {
        name: 'Agency Billing',
        route: '/subsidies/billing',
        component: AgencyBilling,
        icon: faMoneyCheckAlt,
        requiredAreaLevel: [
          {
            area: AreaType.Billing,
            permission: PermissionType.Payments,
            level: RoleLevelType.Read,
          },
        ],
      },
      agencyPayments: {
        name: 'Agency Payments',
        route: '/subsidies/payments',
        component: AgencyPayments,
        icon: faMoneyCheckAlt,
        launchDarklyFlag: 'k2AgencyReconciliation',
        requiredAreaLevel: [
          {
            area: AreaType.Billing,
            permission: PermissionType.Payments,
            level: RoleLevelType.Read,
          },
        ],
      },
      createAgencyPayment: {
        name: 'Add Agency Payment',
        route: '/subsidies/payments/create-payment',
        component: CreateAgencyPayment,
        doNotShowInSidebar: true,
      },
      agencyPayment: {
        name: 'Agency Payment Details',
        route: '/subsidies/payments/:agencyPaymentId',
        component: AgencyPayment,
        doNotShowInSidebar: true,
      },
      agencyBillingPayment: {
        name: 'New Subsidy Payment',
        route: '/subsidies/billing/payment',
        component: SubsidyPayment,
        doNotShowInSidebar: true,
      },
      existingAgencyBillingPayment: {
        name: 'Subsidy Payment',
        route: '/subsidies/billing/payment/:id',
        component: SubsidyPayment,
        doNotShowInSidebar: true,
      },
      ...AgencyRecRoutes,
    },
  },
  billing: {
    name: 'Billing',
    icon: faCreditCard,
    route: '/billing',
    requiredAreaLevel: [
      {
        area: AreaType.Billing,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Billing,
        permission: PermissionType.Payments,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Billing,
        permission: PermissionType.BillingSettings,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      dashboard: {
        name: 'Dashboard',
        icon: faChartLine,
        route: '/billing/dashboard',
        component: BillingDashboard,
        requiredAreaLevel: [
          {
            area: AreaType.Billing,
            permission: PermissionType.Payments,
            level: RoleLevelType.Read,
          },
        ],
      },
      accounts: {
        name: 'Accounts',
        icon: faUsers,
        route: '/billing/accounts',
        component: isRegion('AU') ? BillingAccounts : USBillingAccounts,
        requiredAreaLevel: [
          {
            area: AreaType.Billing,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      transactions: {
        name: 'Transactions',
        icon: faReceipt,
        route: '/billing/transactions',
        component: Transactions,
        requiredAreaLevel: [
          {
            area: AreaType.Billing,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      bulkTransactionDetails: {
        name: 'Bulk Transaction Details',
        route: '/billing/bulk-transactions/:bulkTransactionId/details',
        doNotShowInSidebar: true,
        launchDarklyFlag: 'bulkTransactions',
        requiredAreaLevel: [{ area: AreaType.Billing, permission: PermissionType.Base, level: RoleLevelType.Create }],
        component: BulkTransactionsDetailsPage,
      },
      createBulkTransaction: {
        name: 'Bulk Transaction Create',
        icon: faReceipt,
        route: '/billing/bulk-transactions/create',
        component: CreateBulkTransaction,
        doNotShowInSidebar: true,
        launchDarklyFlag: 'bulkTransactions',
        requiredAreaLevel: [
          {
            area: AreaType.Billing,
            permission: PermissionType.Base,
            level: RoleLevelType.Create,
          },
        ],
      },
      payments: {
        name: 'Payments',
        icon: faDollarSign,
        route: '/billing/payments',
        component: Payments,
        requiredAreaLevel: [
          {
            area: AreaType.Billing,
            permission: PermissionType.Payments,
            level: RoleLevelType.Read,
          },
        ],
      },
      statements: {
        name: 'Statements',
        icon: faFileInvoiceDollar,
        route: '/billing/statement',
        component: Statement,
        doNotShowInSidebar: !isRegion('AU'),
        requiredAreaLevel: [
          {
            area: AreaType.Statement,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      discounts: {
        name: 'Discounts',
        icon: faBadgePercent,
        route: '/billing/discounts',
        component: Discounts,
        launchDarklyFlag: 'k2Discounts',
        requiredAreaLevel: [
          {
            area: AreaType.Billing,
            permission: PermissionType.DiscountManagement,
            level: RoleLevelType.Read,
          },
        ],
      },
      settings: {
        name: 'Settings',
        icon: faCog,
        route: '/billing/settings/:activeTab',
        component: BillingSettings,
        navLinkTo: '/billing/settings/surcharges',
        requiredAreaLevel: [
          {
            area: AreaType.Billing,
            permission: PermissionType.BillingSettings,
            level: RoleLevelType.Read,
          },
        ],
      },
    },
  },
  engagement: {
    name: 'Engagement',
    icon: faComments,
    route: '/engagement',
    requiredAreaLevel: [
      {
        area: AreaType.Comms,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Comms,
        permission: PermissionType.AccountMessaging,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Comms,
        permission: PermissionType.BulletinBoard,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Comms,
        permission: PermissionType.StudentMessaging,
        level: RoleLevelType.Read,
      },
    ],
    matchReduxStoreValue: {
      reduxStorePath: 'comms.usesComms',
      expectedValue: true,
    },
    subRoutes: {
      messaging: {
        name: 'Messaging',
        icon: faComments,
        route: '/engagement/messaging/:activeTab',
        navLinkTo: '/engagement/messaging/announcements',
        component: Messaging,
        requiredAreaLevel: [
          {
            area: AreaType.Comms,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
          {
            area: AreaType.Comms,
            permission: PermissionType.AccountMessaging,
            level: RoleLevelType.Read,
          },
        ],
      },
      bulletinBoard: {
        name: 'Bulletin Boards',
        icon: faThumbtack,
        route: '/engagement/bulletinboards',
        component: BulletinBoards,
        requiredAreaLevel: [
          {
            area: AreaType.Comms,
            permission: PermissionType.BulletinBoard,
            level: RoleLevelType.Read,
          },
        ],
      },
      // TEMPORARY PLACEMENT
      studentMessaging: {
        name: 'Student Messaging',
        icon: faEnvelopesBulk,
        route: '/communications/student-messaging',
        component: Communications,
        requiredAreaLevel: [
          {
            area: AreaType.Comms,
            permission: PermissionType.StudentMessaging,
            level: RoleLevelType.Read,
          },
        ],
        default: true,
        baseAppRoute: true,
      },
      channel: {
        name: 'Channel',
        icon: null,
        doNotShowInSidebar: true,
        route: '/communications/student-messaging/center/:centerId/channel/:channelId',
        component: Channel,
        default: true,
        baseAppRoute: true,
      },
      messagingSettings: {
        name: 'Settings',
        icon: faCog,
        route: '/engagement/settings',
        component: MessagingSettings,
        requiredAreaLevel: [
          {
            area: AreaType.Center,
            permission: PermissionType.Base,
            level: RoleLevelType.Edit,
          },
        ],
      },
    },
  },
  analytics: {
    name: 'Analytics',
    route: '/analytics',
    icon: faAnalytics,
    navLinkTo: '/analytics/exports',
    requiredAreaLevel: [
      {
        area: AreaType.Report,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      exports: {
        name: 'Exports',
        icon: faArrowToBottom,
        route: '/analytics/exports',
        component: Reporting,
        requiredAreaLevel: [
          {
            area: AreaType.Report,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      reporting: {
        name: 'Reporting',
        icon: faFileSpreadsheet,
        route: '/analytics/reporting',
        component: AnalyticsFE,
      },
      reportingSection: {
        name: 'Resource Directory',
        route: '/analytics/reporting/:tabId',
        doNotShowInSidebar: true,
        component: AnalyticsFE,
      },
      royaltyReport: {
        name: 'Royalties',
        route: '/royalties',
        launchDarklyFlag: 'k2KrkRoyaltyReports',
        component: FranchiseRoyaltyReport,
        icon: faMoneyCheckDollar,
        requiredAreaLevel: [
          {
            area: AreaType.Report,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      analyticsPermissions: {
        name: 'Permissions',
        route: '/analytics/permissions',
        launchDarklyFlag: 'k2AnalyticsEmbededReportsPermissions',
        component: AnalyticsFE,
        icon: faLock,
        requiredAreaLevel: [
          {
            area: AreaType.Report,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
    },
  },
  settings: {
    name: 'Configuration',
    icon: faCog,
    route: '/settings',
    requiredAreaLevel: [
      {
        area: AreaType.Staff,
        permission: PermissionType.Base,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Center,
        permission: PermissionType.Base,
        level: RoleLevelType.Edit,
      },
      {
        area: AreaType.Business,
        permission: PermissionType.Base,
        level: RoleLevelType.Create,
      },
      {
        area: AreaType.Business,
        permission: PermissionType.Roles,
        level: RoleLevelType.Read,
      },
      {
        area: AreaType.Business,
        permission: PermissionType.Roles,
        level: RoleLevelType.Read,
      },
    ],
    subRoutes: {
      operations: {
        name: 'Operations',
        icon: faBusinessTime,
        route: '/settings/center/operations/:activeTab',
        navLinkTo: '/settings/center/operations/businessHours',
        component: CenterOperations,
        requiredAreaLevel: [
          {
            area: AreaType.Operations,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
      businessConfiguration: {
        name: 'Configuration',
        icon: faTools,
        route: '/settings/configuration',
        component: BusinessConfiguration,
        requiredPermission: action.INTERNAL_ONLY,
      },
      serviceFeeConfiguration: {
        name: 'Service Fees',
        icon: faTools,
        route: '/settings/service-fees',
        component: ServiceFeeGroups,
        requiredPermission: action.INTERNAL_ONLY,
        subRoutes: {
          serviceFee: {
            name: 'Service Fee',
            icon: null,
            route: '/settings/service-fees/:id',
            matchesActiveRoute: '/settings/service-fees',
            component: ServiceFeeGroup,
            doNotShowInSidebar: true,
            requiredPermission: action.INTERNAL_ONLY,
          },
          serviceFeeCreate: {
            name: 'Add Service Fee',
            icon: null,
            route: '/settings/service-fees/new',
            matchesActiveRoute: '/settings/service-fees',
            component: ServiceFeeGroup,
            doNotShowInSidebar: true,
            requiredPermission: action.INTERNAL_ONLY,
          },
        },
      },
      rolesAndPermissionsHierarchy: {
        name: 'Roles and Permissions',
        icon: faLock,
        route: '/settings/roles',
        component: RoleHierarchy,
        requiredAreaLevel: [
          {
            area: AreaType.Business,
            permission: PermissionType.Roles,
            level: RoleLevelType.Read,
          },
        ],
      },
      rolePermissions: {
        name: 'Role Permissions',
        icon: null,
        route: '/settings/roles/:id',
        component: RolePermissions,
        doNotShowInSidebar: true,
        requiredAreaLevel: [
          {
            area: AreaType.Business,
            permission: PermissionType.Roles,
            level: RoleLevelType.Read,
          },
        ],
      },
      integrations: {
        name: 'Integrations',
        icon: faPuzzlePiece,
        route: '/settings/integrations/:activeTab',
        navLinkTo: '/settings/integrations/ADP',
        component: Integrations,
        requiredAreaLevel: [
          {
            area: AreaType.Business,
            permission: PermissionType.Base,
            level: RoleLevelType.Read,
          },
        ],
      },
    },
  },
  notificationsV2: {
    name: 'NotificationsV2',
    doNotShowInSidebar: true,
    route: '/notifications/v2',
    component: NotificationsV2,
  },
  api: {
    name: 'API',
    icon: faRocketLaunch,
    route: '/api',
    launchDarklyFlag: 'k2WebApi',
    subRoutes: {
      keys: {
        name: 'Keys',
        icon: faKey,
        route: '/api/keys',
        component: ApiKeyManagement,
        launchDarklyFlag: 'k2WebApiKeyManagement',
        requiredPermission: action.INTERNAL_ONLY,
      },
      sandbox: {
        name: 'Sandbox',
        icon: faBrowser,
        route: '/api/sandbox',
        component: Api,
      },
    },
  },
  sitemap: {
    name: 'Sitemap',
    icon: faRocketLaunch,
    route: '/sitemap',
    doNotShowInSidebar: true,
    component: SiteMap,
  },
};
export default routes;
