import React, { useCallback } from 'react';
import { useDeleteTrainingTimeEvent, useRemoveEmployeeFromTrainingTimeEvent } from 'gql/trainingTimeEvent/mutations';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { deleteTrainingTimeEvent, removeTrainingTimeEventFromEmployeeTimecard } from '../../duck/actions';
import { TrainingHolidayEvent } from '../TrainingTimeEventModal';

interface IProps {
  isOpen: boolean;
  trainingTimeEvent: ITrainingHolidayEvent | null;
  employeeIdToRemoveFromTrainingTime?: string | null;
  onClose: () => void;
}

const DeleteTrainingTimeEventModal: React.FC<IProps> = ({
  isOpen,
  trainingTimeEvent,
  employeeIdToRemoveFromTrainingTime = null,
  onClose,
  ...props
}) => {
  const { t } = useTranslation(['timelog']);
  const dispatch = useDispatch();
  const [deleteTrainingTimeEventFn, { loading: deleteTrainingTimeEventLoading }] = useDeleteTrainingTimeEvent({
    onCompleted: (result) => {
      showToast(t('timelog:training-time.delete-success-toast'), 'success');
      dispatch(deleteTrainingTimeEvent(result.deleteTrainingTimeEvent ?? ''));
      onClose();
    },
    onError: (err) => {
      showToast(t('timelog:training-time.delete-failure-toast'), 'error');
    },
  });

  const [removeTrainingEventForEmployeeFn, { loading: removeTrainingEventForEmployeeLoading }] =
    useRemoveEmployeeFromTrainingTimeEvent({
      onCompleted: (result) => {
        if (result.removeEmployeeFromTrainingTimeEvent && employeeIdToRemoveFromTrainingTime) {
          dispatch(
            removeTrainingTimeEventFromEmployeeTimecard(
              employeeIdToRemoveFromTrainingTime,
              result.removeEmployeeFromTrainingTimeEvent
            )
          );
        }
        showToast(t('timelog:training-time.delete-success-toast'), 'success');
        onClose();
      },
      onError: (err) => {
        showToast(t('timelog:training-time.delete-failure-toast'), 'error');
      },
    });

  const handleConfirm = useCallback(() => {
    if (!trainingTimeEvent) return;

    if (employeeIdToRemoveFromTrainingTime) {
      removeTrainingEventForEmployeeFn({
        variables: {
          input: {
            trainingTimeEventId: trainingTimeEvent.id,
            employeeId: employeeIdToRemoveFromTrainingTime,
          },
        },
      });
    } else {
      deleteTrainingTimeEventFn({
        variables: {
          input: {
            id: trainingTimeEvent.id,
          },
        },
      });
    }
  }, [
    trainingTimeEvent,
    employeeIdToRemoveFromTrainingTime,
    deleteTrainingTimeEventFn,
    removeTrainingEventForEmployeeFn,
  ]);

  if (!trainingTimeEvent) return null;

  const event = new TrainingHolidayEvent(trainingTimeEvent);

  return (
    <ConfirmationModal
      show={isOpen}
      title={t('timelog:training-time.delete-modal-title')}
      primaryButtonProps={{
        loading: deleteTrainingTimeEventLoading || removeTrainingEventForEmployeeLoading,
        disabled: deleteTrainingTimeEventLoading || removeTrainingEventForEmployeeLoading,
        variant: 'danger',
      }}
      primaryCallback={handleConfirm}
      primaryChoice="Delete"
      secondaryChoice="Cancel"
      onHide={onClose}
      hideOnCallback={false}
    >
      <p>{t('timelog:training-time.delete-modal-body', { name: trainingTimeEvent?.name ?? '' })}</p>
      <p className="text-danger">
        {t('timelog:training-time.delete-modal-body-detail', {
          hours: trainingTimeEvent?.hours ?? 0,
          type: event.typeToString().toLowerCase(),
          employeeCount: employeeIdToRemoveFromTrainingTime ? 1 : (trainingTimeEvent?.staffIds ?? []).length,
        })}
      </p>
    </ConfirmationModal>
  );
};

export default DeleteTrainingTimeEventModal;
