import businessFields from 'gql/business/fields';
import centerFields from 'gql/center/fields';

export const roleshipFields: string = `
  roleId
  name
  permissions
  scopeType
  scopes {
    ... on Center {
      ${centerFields}
    }
    ... on Entity {
      ${businessFields}
    }
  }
`;
