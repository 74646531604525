import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import { useCreateOfferSchedule, useSendApplicationOffer, useUpdateOfferSchedule } from 'gql/application/mutations';
import { showToast } from 'shared/components/Toast';
import { capitalize } from 'shared/util/string';
import {
  ApplicationChild,
  Application,
  ApplicationScheduleOffer,
  ApplicationScheduleOfferState,
  useMarkLostMutation,
  Maybe,
  useUpsertApplicationDepositMutation,
  ApplicationFlowType,
  ApplicationStage,
} from 'generated/graphql';
import Button, { ButtonAsLink } from 'shared/components/Buttons';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { ICreateOfferSchedule, IUpdateOfferSchedule } from 'shared/types/application';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DepositInput, { IDeposit, getDepositInValidState } from '../ApplicationDeposit';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { currencyFormat } from 'shared/util/currency';
import { isRegion } from 'shared/util/region';
import { IDepositEditModalState } from '../ViewOffers/ViewOffers';
import { useLeadsContext } from '../../../LeadsContext';
import { generateOfferScheduleInput, isProgramBasedFlow, toggleDayInWeek } from '../../../utils';
import MarkAsLostConfirmModal from '../MarkAsLostConfirmModal';
import EditDepositModal from '../EditDepositModal';
import { ApplicationContext } from 'shared/contexts/ApplicationContext';
import colors from '_colors.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPenToSquare } from '@fortawesome/pro-light-svg-icons';
import MultiChildEnquiryDetails from '../MultiChildEnquiryDetails';
import OfferAccordion from '../../OfferCard/OfferAccordion';
import { SingleAccordion } from 'shared/components/Accordion';

export interface IMakeOffersProps {
  child?: ApplicationChild;
  application: Application;
  isApplicationAction?: boolean;
  refetch: () => void;
  refetchAggregateData: () => void;
  openSearch: () => void;
  setChild: (child: ApplicationChild) => void;
  validApplicationScheduleOfferStates: ApplicationScheduleOfferState[];
}

type IOfferForm = Record<string, ApplicationScheduleOffer[]>;

const MakeOffers: React.FC<IMakeOffersProps> = ({
  child,
  application,
  isApplicationAction,
  refetch,
  refetchAggregateData,
  setChild,
  openSearch,
  validApplicationScheduleOfferStates,
}) => {
  const { t } = useTranslation();
  const { k2ApplicationDeposit } = useFlags();
  const timezoneByCenterId = useSelector((state: RootState) => state.timezone.byCenterId);
  const isAuRegion = isRegion('AU');
  const { isSupportMode } = useContext(ApplicationContext);
  const timezone = timezoneByCenterId[application.centers[0]] ?? moment.tz.guess();

  const hasEditApplicationPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementMain,
    level: RoleLevelType.Edit,
  });

  const [depositEditModalState, setDepositEditModalState] = useState<IDepositEditModalState | null>(null);

  const depositEditModalStateInValid = getDepositInValidState(
    depositEditModalState?.collected,
    depositEditModalState?.appliesDate
  );

  const {
    businessId,
    applicationFlowType,
    hasEditEnrollmentLeadManagementPermissions,
    activeTab,
    setEditApplicationModalState,
    setSelectedApplication,
  } = useLeadsContext();
  const centerId = application.centers?.[0] ?? '';
  const [selectedChildren, setSelectedChildren] = useState<undefined | ApplicationChild[]>([]);
  const [nonOfferedChildren, setNonOfferedChildren] = useState<undefined | ApplicationChild[]>([]);
  const [showMarkAsLostConfirmModal, setShowMarkAsLostConfirmModal] = useState(false);
  const [newDeposit, setNewDeposit] = useState<IDeposit>({ value: null, isCollected: false });

  const useAdvancedApplicationEditFeature =
    activeTab === ApplicationStage.Waitlisted && applicationFlowType === ApplicationFlowType.InquireOfferEnrollment;

  const [createOfferSchedule, { loading: createLoading }] = useCreateOfferSchedule({
    onError: (err) => showToast(err.message, 'error'),
  });

  const [updateOfferSchedule, { loading: updateLoading }] = useUpdateOfferSchedule({
    onError: (err) => showToast(err.message, 'error'),
  });

  const [sendApplicationOffer, { loading }] = useSendApplicationOffer({
    onError: (err) => showToast(err.message, 'error'),
    onCompleted: () => {
      showToast(t('enrollment.lead-management.send-offer-success'), 'success');
      refetch();
      refetchAggregateData();
    },
  });

  const initialFormData: undefined | IOfferForm = application?.children?.reduce(
    (acc: IOfferForm, curr: ApplicationChild) => {
      // check children with schedule offer
      const offerCareTypes = curr.offers
        .filter(
          (offer) =>
            (validApplicationScheduleOfferStates as string[]).includes(offer?.state ?? '') &&
            offer?.applicationOfferId === undefined
        )
        .map((o) => o?.careType);

      const unsavedEnquiries = curr.enquiries
        .filter((e) => !offerCareTypes.includes(e.careType))
        .map((e) => ({ ...e, id: undefined }));

      const savedOffers = curr.offers.filter(
        (offer) =>
          (validApplicationScheduleOfferStates as string[]).includes(offer?.state ?? '') &&
          offer?.applicationOfferId === undefined
      );

      // @ts-ignore
      acc[curr.id] = cloneDeep([...savedOffers, ...unsavedEnquiries]).map((sched) => {
        const secondWeek = sched?.days.filter((d) => d.weekType === 'WEEK2') ?? [];
        // default the cycle type to the enquiry
        const cycleType =
          sched?.cycleType && sched?.cycleType !== ''
            ? sched?.cycleType
            : secondWeek.length > 0
            ? 'BIWEEKLY'
            : 'WEEKLY';
        return { ...sched, cycleType };
      });
      return acc;
    },
    {}
  );

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    setFormData(initialFormData);
    if (child) setSelectedChildren([child]);
    if (isApplicationAction) setSelectedChildren(nonOfferedChildren);
  }, [application?.id, isApplicationAction, child, nonOfferedChildren]);

  useEffect(() => {
    setNonOfferedChildren(
      application.children.filter(
        (c) => c.offers.length <= 0 || c.offers.every((o) => o?.state === ApplicationScheduleOfferState.Rejected)
      )
    );
  }, [application.children]);

  const sendOffer = (scheduleIds: string[]) => {
    sendApplicationOffer({
      variables: {
        input: {
          applicationId: application?.id ?? '',
          businessId,
          scheduleOfferIds: scheduleIds,
          depositAmount: newDeposit.value,
          depositCollected: newDeposit.isCollected,
          depositAppliesDate: newDeposit.appliesDate,
        },
      },
    });
  };

  const handleMakeOffer = async () => {
    const { updateRequests, createRequests } = getSaveRequests();

    try {
      const created = await Promise.all(createRequests);
      const updated = await Promise.all(updateRequests);

      const createdIds = created
        .map((res) => {
          return res?.data?.createOfferSchedule.id ?? '';
        })
        .filter((s) => s !== '');

      const updatedIds = updated
        .map((res) => {
          return res?.data?.updateOfferSchedule.id ?? '';
        })
        .filter((s) => s !== '');

      sendOffer([...createdIds, ...updatedIds]);
    } catch (err) {
      showToast(t('enrollment.lead-management.error-saving'), 'error');
    }
  };

  const getSaveRequests = useCallback(() => {
    const newSchedules: ICreateOfferSchedule[] = [];
    const existingSchedules: IUpdateOfferSchedule[] = [];
    if (formData) {
      for (let index = 0; index < Object.keys(formData).length; index++) {
        const child = Object.keys(formData)[index];
        const schedules = formData[child] ?? [];

        // dont include the unselected children
        if (!selectedChildren?.map((c) => c.id)?.includes(child)) {
          continue;
        }

        for (let j = 0; j < schedules.length; j++) {
          const sched = schedules[j];
          const isNew = sched?.id === undefined;
          const value = generateOfferScheduleInput(sched, child, businessId, centerId, application);

          // ignore schedule if it has no class and with offer
          if (sched.classId && !sched.applicationOfferId) {
            if (isNew) newSchedules.push(value);
            else existingSchedules.push(value);
          }
        }
      }
    }

    const createRequests = newSchedules.map((input) =>
      createOfferSchedule({
        variables: {
          input,
        },
      })
    );
    const updateRequests = existingSchedules.map((input) =>
      updateOfferSchedule({
        variables: {
          input,
        },
      })
    );
    return { createRequests, updateRequests };
  }, [application, businessId, centerId, createOfferSchedule, formData, selectedChildren, updateOfferSchedule]);

  const checkAvailability = (c: ApplicationChild) => {
    setChild(c);
    openSearch();
  };

  const handleChange = (value: any, field: keyof ApplicationScheduleOffer, childId: string, schedIndex: number) => {
    if (formData) {
      const scheds = [...formData[childId]];
      const schedCopy: ApplicationScheduleOffer = { ...scheds[schedIndex] };
      schedCopy[field] = value;
      scheds[schedIndex] = schedCopy;
      setFormData({ ...formData, [childId]: scheds });
    }
  };

  const handleDayToggle = (value: WeekDay, weekType: WeekType, childId: string, schedIndex: number) => {
    if (formData) {
      const scheds = [...formData[childId]];
      const schedCopy: ApplicationScheduleOffer = { ...scheds[schedIndex] };
      let firstWeek = schedCopy?.days?.filter((d) => d.weekType === 'WEEK1');
      let secondWeek = schedCopy?.days?.filter((d) => d.weekType === 'WEEK2');

      if (weekType === 'WEEK1') {
        firstWeek = toggleDayInWeek(value, weekType, firstWeek);
      } else {
        secondWeek = toggleDayInWeek(value, weekType, secondWeek);
      }

      schedCopy.days = [...firstWeek, ...secondWeek];
      // @ts-ignore
      scheds[schedIndex] = schedCopy;
      setFormData({ ...formData, [childId]: scheds });
    }
  };

  const hasMulitpleChildren = (application?.children?.length ?? 0) > 1;

  const isFormValid = useMemo(() => {
    let isValid = true;
    selectedChildren?.forEach((c) => {
      if (formData) {
        const sched = formData[c.id];
        const hasMissingData = sched.filter(
          (s) =>
            s.startDate === undefined ||
            s.classId === undefined ||
            s.feeId === undefined ||
            (s.days.length === 0 && s.cycleType !== 'CASUAL')
        );
        if (hasMissingData.length > 0) {
          isValid = false;
        }
      }
    });
    return isValid;
  }, [selectedChildren, formData]);

  const depositInputInvalid = getDepositInValidState(newDeposit.isCollected, newDeposit.appliesDate);

  const allScheduleOffers = application.children.reduce<Maybe<ApplicationScheduleOffer>[]>((acc, child) => {
    const scheduleOffers = child.offers.filter((offer) =>
      (validApplicationScheduleOfferStates as string[]).includes(offer?.state ?? '')
    );
    return [...acc, ...scheduleOffers];
  }, []);

  const [markLostMutation, { loading: markLostLoading }] = useMarkLostMutation({
    variables: {
      input: {
        businessId: businessId ?? '',
        applicationId: application?.id,
        scheduleOfferIds: allScheduleOffers.map((o) => o?.id ?? ''),
      },
    },
    onCompleted: () => {
      setShowMarkAsLostConfirmModal(false);
      refetch();
      refetchAggregateData();
    },
  });

  const handleMarkAsLost = () => markLostMutation();

  const [upsertApplicationDepositMutation, { loading: upsertDepositLoading }] = useUpsertApplicationDepositMutation({
    onError: (err) => showToast(getApolloErrorMessage(err), 'error'),
    onCompleted: () => {
      setDepositEditModalState(null);
      showToast(t('enrollment.lead-management.deposit.update-success-msg'), 'success');
    },
  });

  const handleUpsertApplicationDeposit = async () => {
    const isUpdate = Boolean(depositEditModalState?.id);
    const isCreate = !isUpdate;

    if (isCreate && (!depositEditModalState?.amount || depositEditModalState.amount <= 0)) {
      return;
    }

    await upsertApplicationDepositMutation({
      variables: {
        input: {
          applicationId: application.id,
          businessId: businessId,
          deposit: {
            transactionId: depositEditModalState?.id,
            amount: depositEditModalState?.amount ?? 0,
            collected: depositEditModalState?.collected ?? false,
            appliesDate: depositEditModalState?.collected ? depositEditModalState?.appliesDate : null,
            centerId,
          },
        },
      },
    });
  };

  const handleEditApplication = useCallback(
    (application: Application) => {
      setSelectedApplication(application);
      setEditApplicationModalState({
        isOpen: true,
        editLevel: useAdvancedApplicationEditFeature ? 'advanced' : 'basic',
      });
    },
    [setSelectedApplication, setEditApplicationModalState, useAdvancedApplicationEditFeature]
  );

  const renderTitle = () => {
    const childrenNames = nonOfferedChildren?.map((c) => `${c.firstName} ${c.lastName}`).join(', ');
    const enquiryDate = moment(application.enquiryDate).tz(timezone).format(t('formatters.MMM D, YYYY'));

    return (
      <div className="d-flex justify-content-between mr-3">
        <div className="d-flex">
          <h4>{childrenNames}</h4>
        </div>
        <div className="d-flex justify-content-center">
          {isSupportMode && (
            <a
              className="ml-4"
              onClick={async (e) => {
                e.stopPropagation();
                if (navigator?.clipboard) {
                  await navigator.clipboard.writeText(application.id);
                  showToast('ApplicationId copied to clipboard', 'info');
                }
              }}
            >
              {t('enrollment.lead-management.copy-applicationId')}
            </a>
          )}
          {hasEditEnrollmentLeadManagementPermissions && (
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faPenToSquare} className="mr-2" color={colors.primary} />
              <ButtonAsLink
                disabled={activeTab === ApplicationStage.Completed || activeTab === ApplicationStage.Lost}
                variant="outline-secondary"
                onClick={() => handleEditApplication(application)}
                className="mr-2 text-primary"
              >
                {useAdvancedApplicationEditFeature
                  ? `Edit ${capitalize(t('spelling.inquiry'))} Details`
                  : 'Edit Guardian Info'}
              </ButtonAsLink>
            </div>
          )}
          <div className="px-4 py-1 lead-label" style={{ border: `1px solid ${colors.gray}` }}>
            <p>
              <strong>{t('enrollment.lead-management.submitted-on')}</strong>: {enquiryDate}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="make-offer-body">
        <SingleAccordion title={renderTitle()} loadingLines={2} className="mb-4">
          <div className="offer-inquiry-details">
            <MultiChildEnquiryDetails children={nonOfferedChildren ?? []} offerId={application.id} />
          </div>
          <div className="schedule-offers">
            <div className="d-flex justify-content-between mb-3">
              <h5>{t('enrollment.lead-management.offer-details')}</h5>
            </div>
            {nonOfferedChildren?.map((child, idx) => {
              const scheds = formData ? formData[child?.id] : [];
              const centerId = application.centers[0];

              return (
                <div>
                  {checkAvailability && (
                    <div className="d-flex justify-content-end">
                      <ButtonAsLink onClick={() => checkAvailability(child)} className="text-primary">
                        <FontAwesomeIcon icon={faCalendar} className="mr-2" color={colors.primary} />
                        {t('enrollment.lead-management.check-availability')}
                        {` for ${child.firstName}`}
                      </ButtonAsLink>
                    </div>
                  )}
                  {scheds.map((sched, schedIndex) => {
                    const subtitle =
                      applicationFlowType === ApplicationFlowType.InquireOfferEnrollment
                        ? sched.careType
                        : sched.program?.name ?? sched.careType;

                    return (
                      <OfferAccordion
                        key={sched.id}
                        schedule={sched}
                        handleChange={(value, field) => handleChange(value, field, child.id, schedIndex)}
                        handleDayToggle={(value, weekType) => handleDayToggle(value, weekType, child.id, schedIndex)}
                        title={`${child.firstName} ${child.lastName}`}
                        isDisabled={false}
                        centers={application.centers}
                        subtitle={subtitle}
                        summaryMode={false}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </SingleAccordion>
        {hasMulitpleChildren && (
          <Row className="offer-body">
            <Col md={12} justify="end">
              <h6>{t('enrollment.lead-management.send-offer-for')}</h6>
              <Select
                options={nonOfferedChildren || []}
                getOptionLabel={(c: ApplicationChild) => `${c.firstName} ${c.lastName}`}
                isMulti
                value={selectedChildren}
                getOptionValue={(c: ApplicationChild) => c.id}
                onChange={(selected: ApplicationChild[]) => setSelectedChildren(selected)}
              />
            </Col>
          </Row>
        )}
        {isProgramBasedFlow(applicationFlowType) && (
          <p>
            {/* applications should only ever have one applicationOffer. a $0 fee means that there is either no application fee 
              or the fee hasn't been paid yet*/}
            {t('enrollment.lead-management.application-fee.title', {
              amount: currencyFormat(application.offers![0]?.applicationPaidAmount ?? 0),
              transactionId:
                !isAuRegion && application.offers![0]?.applicationPaidAmount !== null
                  ? `(${application.offers![0]?.applicationPaymentReference})` ?? ''
                  : '',
            })}
            <span>
              <HelpTooltip text={t(`enrollment.lead-management.application-fee.tooltip`)} direction="top" />
            </span>
          </p>
        )}
        {k2ApplicationDeposit && (
          <>
            {/* list existing application deposits
             * if an offer was rejected by the parent, the application would back to waitlist
             * so if there are any existing deposits, we should show them and allow the user to edit them
             */}
            {application.applicationDeposits.map((deposit) => (
              <DepositInput
                key={deposit.id}
                isCollected={deposit.collected}
                onEditClick={() =>
                  setDepositEditModalState({
                    isOpen: true,
                    amount: deposit.amount,
                    collected: deposit.collected,
                    id: deposit.id,
                  })
                }
                value={deposit.amount}
                showEdit={hasEditApplicationPermission}
                isDisabled={true}
              />
            ))}
            {/* deposit input can be filled along with offer details
             * if there are existing deposits, we should not show the input
             */}
            {application.applicationDeposits.length === 0 && (
              <DepositInput
                isCollected={newDeposit.isCollected}
                onChange={(v, isCollected, appliesDate) => {
                  setNewDeposit({
                    value: v,
                    isCollected,
                    appliesDate,
                  });
                }}
                value={newDeposit.value}
                appliesDate={newDeposit.appliesDate}
                showEdit={false}
                showDatePicker={newDeposit.isCollected}
                inValid={depositInputInvalid}
              />
            )}
          </>
        )}
        <Row>
          <Col md={12} className="d-flex justify-content-between pt-2">
            {hasEditApplicationPermission && (
              <Button onClick={() => setShowMarkAsLostConfirmModal(true)} variant="light" className="mr-2">
                {t('enrollment.lead-management.mark-as-lost')}
              </Button>
            )}
            <div>
              <Button onClick={() => refetch()} variant="light" className="mr-2">
                {capitalize(t('spelling.cancel'))}
              </Button>
              <Button
                onClick={handleMakeOffer}
                disabled={!isFormValid || createLoading || updateLoading || Boolean(depositInputInvalid)}
                loading={loading}
                variant="primary"
              >
                {t('enrollment.lead-management.send-offer')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <MarkAsLostConfirmModal
        show={showMarkAsLostConfirmModal}
        onHide={() => setShowMarkAsLostConfirmModal(false)}
        onConfirm={handleMarkAsLost}
        loading={markLostLoading}
      />
      <EditDepositModal
        isOpen={depositEditModalState?.isOpen ?? false}
        onHide={() => setDepositEditModalState(null)}
        onSave={handleUpsertApplicationDeposit}
        isSubmitting={upsertDepositLoading}
        isDepositCollected={depositEditModalState?.collected ?? false}
        depositAmount={depositEditModalState?.amount ?? 0}
        depositAppliesDate={depositEditModalState?.appliesDate}
        onChange={(amount, isCollected, appliesDate) =>
          setDepositEditModalState({
            amount: amount ?? 0,
            collected: isCollected,
            id: depositEditModalState?.id ?? null,
            appliesDate,
            isOpen: true,
          })
        }
        inValid={depositEditModalStateInValid}
      />
    </>
  );
};

export default MakeOffers;
