import { ApplicationFeeType, EnrollmentOptions } from 'generated/graphql';

export const applicationCenterSettingsFields: string = `
  data {
    businessId
    centerId
    centerName
    feeType
    feeAmount
    askFamilyAboutSubsidy
  }
`;

export const updateApplicationCenterSettingsFields: string = `
  businessId
  centerId
  feeType
  feeAmount
  askFamilyAboutSubsidy
`;

export interface ICenterSettings {
  businessId: string;
  centerId: string;
  centerName: string;
  feeType?: ApplicationFeeType | undefined;
  feeAmount?: number;
  askFamilyAboutSubsidy?: boolean | undefined;
}

export interface IGetCenterSettings {
  data: ICenterSettings[];
}

export interface ICenterSettingsInput {
  businessId: string;
  centerId: string;
  feeType?: ApplicationFeeType | undefined;
  feeAmount?: number;
  askFamilyAboutSubsidy?: boolean | undefined;
  enrollmentOptions?: EnrollmentOptions | undefined;
}
