import React from 'react';
import { Row, Collapse, Col } from 'react-bootstrap';
import DataTable from 'shared/components/DataTable';
import moment from 'moment';
import { ISCaseTypeLabelEnum } from 'shared/constants/enums/isCaseEnums';

interface IProps {
  data: IISInnovativeCaseClaim[];
  type: ISCaseTypeLabelEnum;
  open: boolean;
}

const ISInnovativeShowCasesTable: React.FC<IProps> = ({ data, open, type }) => {
  return (
    <Collapse in={open}>
      <Row>
        <Col>
          <DataTable
            className={'ccs-account-management table-striped'}
            noPadding={true}
            data={data}
            handleRowClick={() => {}}
            dataSize={data.length}
            showPagination={false}
            showSelect={false}
            showLoadingOverlay={false}
            columns={[
              { text: 'Innovative Solution Case Claim Id', dataField: 'claimId', sort: true },
              { text: 'Payment Type', dataField: 'paymentType', sort: true },
              { text: 'Innovative Solution Case Claim Status', dataField: 'status', sort: true },
              { text: 'Case Type', dataField: 'id', sort: true, formatter: (cell: string) => <>{type}</> },
              {
                text: 'Date Created',
                dataField: 'created',
                sort: true,
                formatter: (cell: string) => moment(cell).format('DD/MM/YYYY'),
              },
            ]}
            renderHeader={(paginationProps: any) => <></>}
          />
        </Col>
      </Row>
    </Collapse>
  );
};

export default ISInnovativeShowCasesTable;
