import _ from 'lodash';
import { getI18n } from 'react-i18next';

function t(key: string): string {
  const i18n = getI18n();
  return i18n.getResource('en', 'translation', `class.care-type.${key}`);
}

const careTypes: { label: string; value: string }[] = [
  { value: 'ASC', label: t('ASC') },
  { value: 'BSC', label: t('BSC') },
  { value: 'LDC', label: t('LDC') },
  { value: 'VAC', label: t('VAC') },
  { value: 'No CCS', label: t('No CCS') },
];

const usCareTypes = careTypes.filter((o) => o.value !== 'No CCS');

export default careTypes;
export const US_Care_Types = usCareTypes;
