import React, { useMemo } from 'react';
import { PersonAvatar } from 'shared/components/Avatar';
import Button, { IconButton } from 'shared/components/Buttons';
import useFormatDate from 'shared/hooks/useFormatDate';
import { getFullName } from 'shared/util/string';
import { currencyFormat } from 'shared/util/currency';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';

interface IProps {
  transaction: ITransaction;
  appliedDiscountTransactionsLoading?: boolean;
  onAddNewDiscount: (transaction: ITransaction) => void;
  onRemoveAppliedDiscount: (discountTransaction: ITransaction) => void;
}

type RowProps = {
  discountTransaction: ITransaction;
} & Pick<IProps, 'onRemoveAppliedDiscount'>;

const DiscountTransactionRow = (props: RowProps) => {
  const formatDate = useFormatDate();
  const { t } = useTranslation(['billing']);
  const handleRemoveDiscount = () => props.onRemoveAppliedDiscount(props.discountTransaction);

  return (
    <tr className="shadow-none">
      <td className="pl-2">{props.discountTransaction.description}</td>
      <td>{currencyFormat(props.discountTransaction.amount)}</td>
      <td>
        <div className="d-flex flex-row align-items-center">
          <div>
            {props.discountTransaction.createdByPerson ? (
              <span>
                <PersonAvatar size="xs" person={props.discountTransaction.createdByPerson} className="mr-2" />
                {getFullName(props.discountTransaction.createdByPerson)}
              </span>
            ) : (
              'System'
            )}
            <span> on {formatDate(props.discountTransaction.createdAt, 'MM/DD/YYYY')}</span>
          </div>
          <HasRoleAreaLevel
            action={{
              area: AreaType.Billing,
              permission: PermissionType.Discount,
              level: RoleLevelType.Delete,
            }}
          >
            <IconButton
              icon={faTrashAlt}
              className="ml-auto"
              tooltipText={t('billing:transactions.applied-discounts-section.remove-discount-tooltip')}
              onClick={handleRemoveDiscount}
            />
          </HasRoleAreaLevel>
        </div>
      </td>
    </tr>
  );
};

// discounts shouldn't be applied to a transaction that was reversed or a transaction representing a reversal
const AppliedDiscountTransactionsSection = (props: IProps) => {
  const { t } = useTranslation(['billing']);
  const handleAddNewDiscount = () => props.onAddNewDiscount(props.transaction);

  const visibleTransactionDiscounts = (props.transaction.appliedDiscountTransactions ?? []).filter(
    (discountTransaction) => !discountTransaction.reversedByTransactionId
  );

  const discountedTotal = useMemo(
    () => visibleTransactionDiscounts.reduce((acc, curr) => (acc += curr.amount), 0),
    [visibleTransactionDiscounts]
  );

  return (
    <section className="mt-4">
      <h6>{t('billing:transactions.applied-discounts-section.section-label')}</h6>
      {props.appliedDiscountTransactionsLoading ? (
        <LoadingLines number={4} />
      ) : (
        <>
          {props.transaction.appliedDiscountTransactions && visibleTransactionDiscounts.length > 0 && (
            <table className="k2-billing-transaction-discount-table mb-2 w-100">
              <thead>
                <tr className="shadow-none">
                  <th className="pl-2">{t('billing:transactions.applied-discounts-section.description-column')}</th>
                  <th>{t('billing:transactions.applied-discounts-section.amount-column')}</th>
                  <th colSpan={2}>{t('billing:transactions.applied-discounts-section.applied-by-column')}</th>
                </tr>
              </thead>
              <tbody>
                {visibleTransactionDiscounts.map((discountTransaction, idx) => (
                  <DiscountTransactionRow
                    key={`transaction-discount-${discountTransaction.id}-${idx}`}
                    discountTransaction={discountTransaction}
                    onRemoveAppliedDiscount={props.onRemoveAppliedDiscount}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr className="shadow-none">
                  <td className="pl-2">
                    {t('billing:transactions.applied-discounts-section.total-discounted-column')}
                  </td>
                  <td colSpan={3}>
                    <div className="d-flex">
                      {currencyFormat(discountedTotal)}
                      <span className="ml-auto">
                        <b>{currencyFormat(props.transaction.amount + discountedTotal)} after discounts</b>
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
          {props.transaction.amount + discountedTotal < 0 && (
            <HasRoleAreaLevel
              action={{ area: AreaType.Billing, permission: PermissionType.Discount, level: RoleLevelType.Create }}
            >
              <Button onClick={handleAddNewDiscount}>
                {t('billing:transactions.applied-discounts-section.add-new-button')}
              </Button>
            </HasRoleAreaLevel>
          )}
        </>
      )}
    </section>
  );
};

export default AppliedDiscountTransactionsSection;
