import { PayrixDisbursement } from 'generated/graphql';
import { ISageGlDetailsReportData } from 'gql/reports/queries/sageGlDetailsQuery';
import { capitalize, groupBy, orderBy } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Note: Use double quotes ("") to wrap a column that has a comma in it
const useReportDataToFile = () => {
  const { t } = useTranslation();

  const createEmptyRow = (numberOfColumns: number): string[] => new Array(numberOfColumns).fill('');

  /**
   * Generate rows for the csv that contains the meta data for a given report
   */
  const generateMetadataRow = (meta: IReportMetaData): string[][] => {
    const paramsHeaderRow = ['Generated On', 'Generated By', 'Params Used'];
    const paramsRow = [
      moment(meta.generatedOn ?? '').format(t('formatters.date')),
      `"${meta.generatedBy ?? ''}"`,
      `"${meta.params.map((param) => `${param.key}: '${param.value}'`)}"`,
    ];

    return [paramsHeaderRow, paramsRow];
  };

  return {
    downloadCsvFromString: (data: string, filename: string, extension: string = 'csv', dateOnly: boolean = false) => {
      const dateFormat: string = t('formatters.YYYY/MM/DD');
      const modifiedDateFormat = dateFormat.replace(/\//g, '.');
      const now = moment().format(dateOnly ? modifiedDateFormat : `${modifiedDateFormat}_HH.mm.ss`);
      const file = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      const downloadLink = document.createElement('a');

      downloadLink.download = `${filename}_${now}.${extension}`;
      downloadLink.href = window.URL.createObjectURL(file);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);

      downloadLink.click();
    },
    downloadXlsxFromBase64: (data: string, filename: string, extension: string = 'xlsx', dateOnly: boolean = false) => {
      const dateFormat: string = t('formatters.YYYY/MM/DD');
      const modifiedDateFormat = dateFormat.replace(/\//g, '.');
      const now = moment().format(dateOnly ? modifiedDateFormat : `${modifiedDateFormat}_HH.mm.ss`);

      const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
      const downloadLink = document.createElement('a');

      downloadLink.download = `${filename}_${now}.${extension}`;
      downloadLink.href = mediaType + data;
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);

      downloadLink.click();
    },
    transactionTotals: (data: ITransactionTotalReport): string[][] => {
      const headers = [
        'Date',
        `${t('spelling.capitalCenter')} Name`,
        'Fee',
        'Discounts',
        'Early / Late',
        'Subsidy',
        'Credits',
        'Debits',
        'Payments',
        'Totals',
        'External Id',
      ];
      const rows: string[][] = [...generateMetadataRow(data.reportMetaData), createEmptyRow(10), headers];

      const dataGroupedByCenter = groupBy(data.reportData, (row) => row.centerId);

      Object.keys(dataGroupedByCenter).forEach((centerId) => {
        const centerGrouping = dataGroupedByCenter[centerId];
        const groupDataSortedByDate = orderBy(centerGrouping, (row) => moment(row.date), 'asc');

        groupDataSortedByDate.forEach((row) => {
          rows.push([
            moment(row.date).format(t('formatters.date')),
            row.centerName,
            `${row.feeAmount.toFixed(2)}`,
            `${row.discountAmount.toFixed(2)}`,
            `${row.earlyLateAmount.toFixed(2)}`,
            `${row.subsidyAmount.toFixed(2)}`,
            `${row.creditAmount.toFixed(2)}`,
            `${row.debitAmount.toFixed(2)}`,
            `${row.paymentAmount.toFixed(2)}`,
            `${row.totalAmount.toFixed(2)}`,
            row.centerExternalId ?? '',
          ]);
        });

        // sum and create totals row for the group
        const groupTotals = groupDataSortedByDate.reduce(
          (acc, curr) => ({
            ...acc,
            fee: acc.fee + curr.feeAmount,
            discount: acc.discount + curr.discountAmount,
            earlyLate: acc.earlyLate + curr.earlyLateAmount,
            subsidy: acc.subsidy + curr.subsidyAmount,
            credit: acc.credit + curr.creditAmount,
            debit: acc.debit + curr.debitAmount,
            payment: acc.payment + curr.paymentAmount,
            total: acc.total + curr.totalAmount,
          }),
          {
            fee: 0,
            discount: 0,
            earlyLate: 0,
            subsidy: 0,
            credit: 0,
            debit: 0,
            payment: 0,
            total: 0,
          }
        );

        rows.push([
          'Total',
          '',
          `${groupTotals.fee.toFixed(2)}`,
          `${groupTotals.discount.toFixed(2)}`,
          `${groupTotals.earlyLate.toFixed(2)}`,
          `${groupTotals.subsidy.toFixed(2)}`,
          `${groupTotals.credit.toFixed(2)}`,
          `${groupTotals.debit.toFixed(2)}`,
          `${groupTotals.payment.toFixed(2)}`,
          `${groupTotals.total.toFixed(2)}`,
          '',
        ]);
      });

      return rows;
    },
    occupancyTotals: (data: IOccupancyTotalReport): string[][] => {
      const headers = [
        `${t('spelling.capitalCenter')} Name`,
        'Class Name',
        'Date',
        'Day',
        'Count',
        `${capitalize(t('spelling.utilization'))} (%)`,
        'Capacity',
      ];
      const rows: string[][] = [...generateMetadataRow(data.reportMetaData), createEmptyRow(7), headers];

      const dataGroupedByCenter = groupBy(data.reportData, (row) => row.centerId);

      Object.keys(dataGroupedByCenter).forEach((centerId) => {
        const centerGrouping = dataGroupedByCenter[centerId];
        const groupingSortedByDateAndClass = orderBy(
          centerGrouping,
          [(group) => group.class, (group) => group.date],
          ['asc', 'asc']
        );

        groupingSortedByDateAndClass.forEach((row) => {
          rows.push([
            row.center,
            row.class,
            moment(row.date).format(t('formatters.date')),
            row.dayOfWeek,
            `${row.count}`,
            `${row.utilization.toFixed(4)}`,
            `${row.capacity}`,
          ]);
        });
      });

      return rows;
    },
    sageGlReport: (data: ISageGlImportReportWrapper): string[][] => {
      const groupedCenterName = groupBy(data.records, 'centerName');
      const records: any = [];
      const endDate = moment(data.endDate);

      records.push([
        `\`@HDR\``,
        `\`@BATCH\``,
        endDate.format('YYYYMMDD'),
        `Net Revenue Posting for ${endDate.format('MMM D YYYY')}`,
      ]);

      records.push([`\`@HDR\``, `\`JOURNAL\``, `\`JE\`,`]);

      const writeLine = (
        cb: ISageGlImportReportData,
        overrideGl: string | null = null,
        useAbsolute: boolean = false
      ) => {
        const date = moment(cb.date);
        const month = `0${date.month() + 1}`.slice(-2);
        const year = date.year();
        const amount = cb.amount.toFixed(2);

        const codeValuePart1 = `${overrideGl ?? cb.glCode}`;
        let codeValuePart2 = '';
        const centerNameSplit = cb.centerName.split('-');
        if (centerNameSplit.length > 1) {
          codeValuePart2 = `${cb.centerName.split('-')[1].trim()}`;
        }

        const glCode = codeValuePart1 + codeValuePart2;

        records.push([
          glCode,
          '',
          year,
          month,
          date.format('YYYYMMDD'),
          'JE',
          '2000',
          '',
          '',
          cb.centerName,
          '',
          '',
          '',
          'USD',
          useAbsolute ? Math.abs(parseFloat(amount)) : amount,
          '',
          '',
          '',
          'CNI',
        ]);
      };

      const sum = (prev: ISageGlImportReportData, next: ISageGlImportReportData) => {
        return {
          ...prev,
          amount: prev.amount + next.amount,
        };
      };

      for (const [_, value] of Object.entries(groupedCenterName)) {
        const offsetRecord = value.reduce(sum);
        writeLine(offsetRecord, `1101`, true);

        for (const record of orderBy(value, [(record) => record.glCode], ['asc'])) {
          writeLine(record);
        }
      }

      records.push([`@END,JOURNAL`, `@END,BATCH`]);

      return records;
    },
    sageGlDetails: (data: ISageGlDetailsReportData[]) => {
      // Csv Helpers, this is going to be a template for refactoring all the reports
      // // Csv Format Definition
      type CsvFormat = { headerName: string; attributeName: keyof ISageGlDetailsReportData }[];
      const csvFormat: CsvFormat = [
        { headerName: 'Center Name', attributeName: 'centerName' },
        { headerName: 'Account Name', attributeName: 'accountName' },
        { headerName: 'Transaction Number', attributeName: 'transactionNumber' },
        { headerName: 'Date', attributeName: 'date' },
        { headerName: 'Type', attributeName: 'type' },
        { headerName: 'Billed Amount', attributeName: 'amount' },
        { headerName: 'Billed Fee Name', attributeName: 'billedFeeName' },
        { headerName: 'Adjustment Gl Code', attributeName: 'adjustmentGlCode' },
        { headerName: 'Adjustment Amount', attributeName: 'adjustment' },
        { headerName: 'Adjustment Name', attributeName: 'adjustmentFeeName' },
        { headerName: 'Standard Fee Name', attributeName: 'originalSessionFeeName' },
        { headerName: 'Standard Amount', attributeName: 'originalCost' },
        { headerName: 'Child Name', attributeName: 'child' },
        { headerName: 'Gl Code', attributeName: 'glCode' },
      ];
      const getHeaders = (csvFormat: CsvFormat) => csvFormat.map((f) => f.headerName); // this should go into a CsvFormat class when refactored
      // // Csv Builder Helpers - these will be private methods of the CSV "library"
      const escapeFn = (value: string) => value.replaceAll('"', '""');
      const toCsvValue = (value: unknown): string => {
        if (typeof value === 'string') {
          return `"${escapeFn(value)}"`;
        } else if (typeof value === 'number') {
          return value.toString(10);
        } else if (value === undefined || value === null) {
          return '';
        }
        return toCsvValue(value.toString()); // handles case where an object or something random is passed in
      };
      const buildCsvData = (data: ISageGlDetailsReportData[], csvFormat: CsvFormat): string[][] => {
        const csv: any[][] = [];
        csv.push(getHeaders(csvFormat).map(toCsvValue));
        data.forEach((r) => {
          const row: string[] = [];
          csvFormat.forEach((f) => row.push(toCsvValue(r[f.attributeName])));
          csv.push(row);
        });
        return csv;
      };
      // End Csv Helpers
      const csvData = buildCsvData(data, csvFormat);
      console.log('DEV:', csvData);
      return csvData;
    },
    sageCashReport: (batches: IPagedResult<IBatch>, disbursements: PayrixDisbursement[]): string[][] => {
      const colAConstant = '1026';
      const colCConstant = '2000';
      const colDConstant = '1101';
      const rows: string[][] = [];

      batches.data.forEach((b) => {
        const disbursement = disbursements.find((d) => d.id === b.documentNumber);
        const amount = disbursement !== undefined ? disbursement.amount / 100 : b.amount; // the payrix disbursement amount will be in cents
        const centerName = b.center.name;
        const centerId = centerName.includes('-') ? `${centerName.split('-')[1]?.trim()}00` : centerName;

        rows.push([
          `"${colAConstant}"`,
          `"${moment(b.createdAt).format('MM/DD/YYYY')}"`,
          `"${colCConstant}"`,
          `"${colDConstant}"`,
          `"${centerId}"`,
          `"${b.userFriendlyId}"`,
          `"${amount.toFixed(2)}"`,
        ]);
      });

      return rows;
    },
  };
};

export default useReportDataToFile;
