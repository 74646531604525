import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { paymentMethodFields } from '../fields';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeAccountPaymentMethod } from 'pages/Families/subroutes/Accounts/duck/actions';

interface ICreateAuthorizedPaymentData {
  createAuthorizedPersonPaymentMethod: IPaymentMethod;
}

interface ICreateAuthorizedPaymentMethodVariables {
  input: ICreatePaymentMethodInput;
}

interface IDeletePersonPaymentMethodData {
  deletePersonPaymentMethod: IPaymentMethod;
}

interface IDeletePaymentMethodVariables {
  id: string;
}

export const CREATE_AUTHORIZED_PERSON_PAYMENT_METHOD = (fields: string = paymentMethodFields) =>
  gql` mutation ($input: CreateAuthorizedPersonPaymentMethodInput!) {
    createAuthorizedPersonPaymentMethod(input: $input) {
      ${fields}
    }
  }`;

export const DELETE_PERSON_PAYMENT_METHOD = (fields: string = paymentMethodFields) => gql`
  mutation($id: ID!) {
    deletePersonPaymentMethod(id: $id) {
      ${fields}
    }
  }
`;

export const useCreateAuthorizedPersonPaymentMethod = (
  options?: MutationHookOptions<ICreateAuthorizedPaymentData, ICreateAuthorizedPaymentMethodVariables>,
  fields?: string
) =>
  useMutation<ICreateAuthorizedPaymentData, ICreateAuthorizedPaymentMethodVariables>(
    CREATE_AUTHORIZED_PERSON_PAYMENT_METHOD(fields),
    {
      ...options,
    }
  );

export const useDeletePersonPaymentMethod = (
  options?: MutationHookOptions<IDeletePersonPaymentMethodData, IDeletePaymentMethodVariables>,
  fields?: string
) =>
  useMutation<IDeletePersonPaymentMethodData, IDeletePaymentMethodVariables>(DELETE_PERSON_PAYMENT_METHOD(fields), {
    ...options,
  });

export const useDeletePersonPaymentMethods: (
  accountId: string
) => [(paymentMethods: IPaymentMethod[], accountId: string) => Promise<void>, IMutationResponse] = (
  accountId: string
) => {
  const [response, setResponse] = useState<IMutationResponse>({
    loading: false,
    error: null,
    data: null,
  });
  const dispatch = useDispatch();

  const [deletePaymentMethod] = useDeletePersonPaymentMethod();

  const deletePaymentMethods = useCallback(
    async (paymentMethods: IPaymentMethod[], accountId: string) => {
      setResponse({ ...response, loading: true });
      try {
        const data = await Promise.all(
          paymentMethods.map(async (paymentMethod) =>
            deletePaymentMethod({
              variables: { id: paymentMethod.id },
            })
          )
        );

        if (data) {
          for (let i = 0; i < data?.length; i++) {
            const res = data[i];

            if (res.data?.deletePersonPaymentMethod) {
              dispatch(removeAccountPaymentMethod(accountId, res.data.deletePersonPaymentMethod.id));
            }
          }
        }

        setResponse({ ...response, loading: false, data });
      } catch (error) {
        setResponse({ ...response, loading: false, error });
      }
    },
    [deletePaymentMethod, response]
  );

  return [deletePaymentMethods, response];
};
