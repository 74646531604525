import { TABLE_DEFAULTS } from 'shared/components/DataTable';
import * as types from './types';

export interface IAgencyAccountChildrenStateShape {
  tableData: IAgencyAccountChild[];
  tableMetaData: {
    totalRecords: number;
    numberOfExpired: number;
    numberOfExpiringSoon: number;
    numberOfActive: number;
    numberOfFuture: number;
  };
  tableFilters: IAgencyAccountChildrenTableFilters;
}

const initialState: IAgencyAccountChildrenStateShape = {
  tableData: [],
  tableMetaData: {
    totalRecords: 0,
    numberOfExpired: 0,
    numberOfExpiringSoon: 0,
    numberOfActive: 0,
    numberOfFuture: 0,
  },
  tableFilters: {
    term: '',
    centerIds: [],
    agencyIds: [],
    statuses: [],
    sortField: 'name',
    sortDirection: 'asc',
    pageNumber: TABLE_DEFAULTS.PAGE,
    pageSize: TABLE_DEFAULTS.PAGE_SIZE,
  },
};

export const agencyAccountChildrenReducers = (
  state: IAgencyAccountChildrenStateShape = initialState,
  action: types.AgencyAccountChildrenActionTypes
): IAgencyAccountChildrenStateShape => {
  switch (action.type) {
    case types.GET_AGENCY_ACCOUNT_CHILDREN_TABLE_DATA:
      return {
        ...state,
        tableData: action.tableData.data,
        tableMetaData: {
          totalRecords: action.tableData.totalRecords,
          numberOfExpired: action.tableData.metaData.numberOfExpired,
          numberOfActive: action.tableData.metaData.numberOfActive,
          numberOfExpiringSoon: action.tableData.metaData.numberOfExpiringSoon,
          numberOfFuture: action.tableData.metaData.numberOfFuture,
        },
      };
    case types.UPDATE_AGENCY_ACCOUNT_CHILDREN_TABLE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.tableFilters,
        },
      };
    case types.UPDATE_AGENCY_ACCOUNT_CHILD:
      return {
        ...state,
        tableData: state.tableData.map((agencyAccountChild) =>
          agencyAccountChild.id === action.agencyAccountChild.id ? action.agencyAccountChild : agencyAccountChild
        ),
      };
    default:
      return state;
  }
};
