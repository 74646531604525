import { isUndefined } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'shared/components/Switch';

interface IProps {
  title: string;
  isArchived?: boolean;
  onArchivedToggleChange?: (showArchived: boolean) => void;
}

export default ({ isArchived, onArchivedToggleChange, title }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-between">
      {title}
      {!isUndefined(isArchived) && !isUndefined(onArchivedToggleChange) && (
        <Switch
          checkedText="Yes"
          uncheckedText="No"
          value={isArchived}
          onChange={(v) => onArchivedToggleChange(v)}
          height={30}
          label={t('custom-data-fields.show-archived')}
          labelSide="left"
        />
      )}
    </div>
  );
};
