import React, { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ClassInformation from './ClassInformation';
import ClassAssignment from './ClassAssignment';
import ClassRegulation from './ClassRegulation';
import ClassRegulationOverride from './ClassRegulation/ClassRegulationOverride';
import { useSearchActiveStaffForCenter, useGetCenterDetails } from 'pages/Centers/subroutes/Class/graphql/queries';
import { useGetRegulations } from 'pages/Regulations/graphql/queries';
import { IClassStateShape } from 'pages/Centers/subroutes/Class/Class';
import ClassDefaultFees from './ClassDefaultFees';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import moment from 'moment';

// NOTE: this is repeated in Regulations.tsx. when we have time to refactor this sohuld be moved elsewhere
const ageToNumberOfDays = (regulationAge: IRegulationAge): number => {
  const { age, unit } = regulationAge;

  switch (unit) {
    case 'YEAR':
      return age * 365;
    case 'MONTH':
      return age * 30;
    case 'WEEK':
      return age * 7;
    default:
      return age;
  }
};

interface IProps {
  classId: string;
  centerId: string;
  classData: IClassStateShape;
  feeOptions: IFee[];
  updateClass: React.Dispatch<React.SetStateAction<IClassStateShape>>;
  openNewSpaceModal: () => void;
  openNewFeeModal: () => void;
}

const OverviewTab: React.FC<IProps> = ({
  centerId,
  classId,
  classData,
  feeOptions,
  updateClass,
  openNewSpaceModal,
  openNewFeeModal,
  ...props
}) => {
  const { data: getCenterDetailsData, loading: getCenterDetailsLoading } = useGetCenterDetails(centerId);
  const { data: staffForCenter, loading: fetchingStaff } = useSearchActiveStaffForCenter(centerId);
  const { data: regulationsData, loading: fetchingRegulations } = useGetRegulations();
  const hasEditClassPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Classes,
    level: RoleLevelType.Edit,
  });

  const isReadOnly = Boolean(classData.archivedAt) || !hasEditClassPermission;
  const centerTimezone = getCenterDetailsData?.getCenter?.timezone ?? moment.tz.guess();

  return (
    <Fragment>
      <Row>
        <Column xl={8} md={12} className="mb-4 min-width-66-percent">
          <ClassInformation
            _class={classData}
            updateClass={updateClass}
            fetchingSpaces={getCenterDetailsLoading}
            spaceOptions={getCenterDetailsData?.getCenter?.spaces ?? []}
            openNewSpaceModal={() => openNewSpaceModal()}
            isReadOnly={isReadOnly}
            timezone={centerTimezone}
          />
        </Column>
        <Column className="mb-4">
          <ClassAssignment
            isViewOnly={isReadOnly}
            assignedStaff={classData.staffAssignments ?? []}
            updateClass={updateClass}
            fetchingStaff={fetchingStaff}
            staffOptions={staffForCenter?.searchStaff.data ?? []}
            classStartDate={classData.startsAt}
            classEndDate={classData.endsAt ?? null}
          />
        </Column>
      </Row>
      <Row className="mb-4">
        <Column xl={8}>
          <Row>
            <Column xl={6}>
              <ClassRegulation
                isViewOnly={isReadOnly}
                selectedRegulationId={classData.regulationId}
                centerAddressState={classData.regulation?.state ?? getCenterDetailsData?.getCenter?.address.state ?? ''}
                fetchingRegulations={fetchingRegulations}
                regulationOptions={regulationsData?.getAllRegulations ?? []}
                onChange={(reg: IRegulation) =>
                  updateClass((prev: IClassStateShape) => ({
                    ...prev,
                    capacity: !prev.capacity ? reg.capacity : prev.capacity,
                    regulationId: reg.id,
                    regulation: { ...reg },
                    regulationOverride: undefined,
                  }))
                }
                classCapacity={classData.capacity}
                onCapacityChange={(value) =>
                  updateClass((prev: IClassStateShape) => ({
                    ...prev,
                    capacity: value,
                  }))
                }
              />
            </Column>
            <Column xl={6}>
              <ClassRegulationOverride
                isViewOnly={isReadOnly}
                hasOverride={Boolean(classData.regulationOverride)}
                // @ts-ignore
                baseRegulation={classData.regulation}
                existingOverride={classData.regulationOverride}
                updateOverrides={(reg) =>
                  updateClass((prev: IClassStateShape) => ({ ...prev, regulationOverride: reg }))
                }
                ageToNumberOfDays={ageToNumberOfDays}
              />
            </Column>
          </Row>
        </Column>
        {
          <HasRoleAreaLevel
            action={{ area: AreaType.Center, permission: PermissionType.Fees, level: RoleLevelType.Read }}
          >
            <Column xl={4}>
              <ClassDefaultFees
                classId={classId}
                openNewFeeModal={openNewFeeModal}
                feeOptions={feeOptions}
                permanentFeeId={classData.defaultPermanentFeeId}
                casualFeeId={classData.defaultCasualFeeId}
                onFeeSelect={(type, fee) =>
                  updateClass((prev) => ({
                    ...prev,
                    [type]: fee?.id ?? null,
                  }))
                }
                isReadOnly={isReadOnly}
              />
            </Column>
          </HasRoleAreaLevel>
        }
      </Row>
    </Fragment>
  );
};

export default OverviewTab;
