import { find } from 'lodash';
import moment from 'moment';
import React from 'react';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { ccsCertificateStatusEnum } from 'shared/constants/enums/ccssEnums';
import { getInitials, stringToHsl } from 'shared/util/string';

export interface ICcsCertificateDetails {
  id: string;
  child: IAccountChild;
  certificate: ICcssCertificate;
  previousCertificates: ICcsCertificateDetails[];
}

interface ITableProps {
  data: ICcsCertificateDetails[];
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
}

const dateFormat = 'DD/MM/YYYY';

const CertificatesTable: React.FC<ITableProps> = ({ data, expandRow, loading }) => {
  return (
    <DataTable
      noPadding={true}
      data={data}
      expandRow={expandRow}
      showLoadingOverlay={loading}
      showSelect={false}
      showPagination={false}
      expandHeaderColumnRenderer={(props) => <></>} // We don't have a table header so hide it
      columns={[
        {
          headerAttrs: {
            hidden: true,
          },
          text: '',
          dataField: 'avatar',
          sort: true,
          formatter: (cell: string, row: ICcsCertificateDetails) => (
            <AvatarDataTableCell
              color={stringToHsl(row.child.id)}
              initials={getInitials(row.child)}
              avatar={row.child.avatar?.url ?? ''}
              primaryText={row.child.fullName}
            />
          ),
        },
        {
          text: '',
          dataField: 'type',
          formatter: (cell: string, row: ICcsCertificateDetails) => `Certificate`,
        },
        {
          text: '',
          dataField: 'certificateId',
          formatter: (cell: string, row: ICcsCertificateDetails) => `${row.certificate.certificateId}`,
        },
        {
          text: '',
          dataField: 'endDate',
          formatter: (cell: string, row: ICcsCertificateDetails) => {
            return `${moment(row.certificate.startDate).format(dateFormat)} 
            - 
            ${moment(row.certificate.endDate).format(dateFormat)}`;
          },
        },
        {
          text: '',
          dataField: 'status',
          formatter: (cell: string, row: ICcsCertificateDetails) =>
            `${
              find(ccsCertificateStatusEnum, (status) => status.value === row.certificate.status)?.label ?? 'Unknown'
            }`,
        },
      ]}
    />
  );
};

export default CertificatesTable;
