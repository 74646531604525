import React, { useEffect, useMemo, useState } from 'react';
import BSCard from 'react-bootstrap/Card';
import SearchInput from '../../SearchInput/SearchInput';
import { capitalize } from 'shared/util/string';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { Row } from 'react-bootstrap';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import DataTable, { SizePerPage, TableHeader } from '../../DataTable';
import DropdownFilter from '../../Dropdown/DropdownFilter';
import { useMediaQuery } from '@mui/material';
import theme from 'muiTheme';
import { Channel } from 'pages/Engagement/subroutes/Messaging/Tabs/AccountMessagingTab/AccountMessagingTab';
import ChannelCard from './ChannelCard';
import { ChannelType } from 'generated/graphql';

interface IProps {
  hasWritePermission: boolean;
  hideUnreadIndicator?: boolean;
  authCenters: string[] | undefined;
  channels?: Channel[];
  currentChannel: string | undefined;
  channelType?: ChannelType;
  accountsPage?: boolean;
  loading?: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  selectedCenters: ITableFilterOption[];
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  onSearchTermChange: (searchTerm: string) => void;
  searchTerm?: string;
  setSelectedCenters: (centerFilter: ITableFilterOption[]) => void;
  handleChannelSwitch: (channel: Channel) => void;
  handleOpenNewMessageModal: () => void;
}

interface FilteredChannels extends Channel {
  id: string;
  active: boolean;
  unread: boolean;
}

const Channels: React.FC<IProps> = ({
  hasWritePermission,
  hideUnreadIndicator,
  authCenters,
  channels,
  currentChannel,
  channelType,
  accountsPage,
  loading,
  dataSize,
  pageSize,
  activePage,
  selectedCenters,
  onPageChange,
  onSizePerPageChange,
  onSearchTermChange,
  searchTerm = '',
  setSelectedCenters,
  handleChannelSwitch,
  handleOpenNewMessageModal,
}) => {
  const { t } = useTranslation(['comms', 'translation']);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();
  const centers = useSelector(
    (state: RootState) =>
      state.centers.all
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((c) => !c.deactivatedAt && authCenters?.includes(c.id)) ?? []
  );

  const [filteredChannels, setFilteredChannels] = useState<FilteredChannels[] | undefined>();

  useEffect(() => {
    setFilteredChannels(
      channels
        ? channels
            .map((c) => {
              return {
                ...c,
                id: c.channelIdentifier,
                active: currentChannel === c.channelIdentifier,
                unread:
                  currentChannel !== c.channelIdentifier &&
                  c.lastActive !== null &&
                  c.lastActive !== undefined &&
                  (moment(c.lastActive).tz(timezone).isAfter(moment(c.lastAccessedByPersonId).tz(timezone), 'minute') ||
                    !c.lastAccessedByPersonId),
              };
            })
            .sort((a, b) => moment(b.lastActive).tz(timezone).diff(moment(a.lastActive).tz(timezone)))
        : []
    );
  }, [channels, currentChannel]);

  return (
    <>
      <BSCard className="`mx-0 mt-0 mb-4 ml-0 channels-list">
        <BSCard.Header className="border-radius-0 font-weight-bold channels-list-header">
          <Row>
            <div className="card-title pr-2 pl-2">{t('comms:channels.title')}</div>
            {hasWritePermission && !accountsPage && (
              <div className="new-ms-btn-container pr-2 pl-2">
                <button className="new-ms-btn" onClick={handleOpenNewMessageModal}>
                  <span>
                    {channelType && channelType === ChannelType.Center
                      ? capitalize(t('comms:new-announcement'))
                      : capitalize(t('comms:new-message'))}
                  </span>
                  <FontAwesomeIcon icon={faPen} />
                </button>
              </div>
            )}
          </Row>
        </BSCard.Header>
        <BSCard.Body>
          <DataTable
            className="channels-table"
            data={filteredChannels ?? []}
            dataSize={dataSize}
            striped={false}
            showSelect={false}
            handleRowClick={(row) => {}}
            noDataText={'No Active Channels'}
            showLoadingOverlay={loading}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            activePage={activePage}
            columns={[
              {
                text: 'Channels',
                dataField: 'channel.id',
                formatter: (cell: any, channel: any) => (
                  <>
                    <ChannelCard
                      channelKey={channel.id}
                      channelName={channel.name}
                      className={`pn-channel ${currentChannel === channel.channelIdentifier ? 'active' : ''}`}
                      channelId={channel.id}
                      account={channel.account}
                      unread={channel.unread}
                      lastActive={channel.lastActive ?? channel.lastAccessedByPersonId}
                      hideUnreadIndicator={hideUnreadIndicator}
                      onClick={() => {
                        handleChannelSwitch(channel);
                      }}
                    />
                  </>
                ),
              },
            ]}
            renderHeader={(paginationProps: any) =>
              !accountsPage && (
                <TableHeader className="flex-column align-items-center">
                  <div className="d-flex justify-content-between w-100 filter-container">
                    <SizePerPage paginationProps={paginationProps} />
                    <DropdownFilter
                      title={capitalize(t('translation:spelling.center'))}
                      className={isMobile ? ' my-1 mr-4' : 'mr-4'}
                      options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
                      onFilterSelect={(options) => setSelectedCenters(options)}
                      selectedFilters={selectedCenters}
                    />
                  </div>
                  <div className="search-container w-100 pb-3">
                    <SearchInput onChange={onSearchTermChange} searchString={searchTerm} className={'mt-3'} />
                  </div>
                </TableHeader>
              )
            }
          />
        </BSCard.Body>
      </BSCard>
    </>
  );
};

export default Channels;
