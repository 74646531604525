import React, { useCallback, useState } from 'react';
import Cards, { Focused } from 'react-credit-cards-2';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { NumberInput } from 'shared/components/TextInput';
import { Col, Row } from 'shared/components/Layout';
import { useUpdatePaymentMethod } from 'gql/paymentMethod/mutations';
import { showToast } from 'shared/components/Toast';
import { getCardValidation } from 'shared/components/CreditCardInputs/utils';

interface IProps {
  isOpen: boolean;
  centerTimezone?: Timezone;
  creditCard: IPaymentMethod;
  /**
   * callback that is invoked after a successful request
   */
  onSave: (paymentMethod: IPaymentMethod) => void;
  onClose: () => void;
}

const UpdateCreditCardModal: React.FC<IProps> = ({ isOpen, creditCard, centerTimezone, onSave, onClose, ...props }) => {
  /**
   * The expiration date that we are providing (from our api) will be in the format of YYYY-MM-DD.
   * React-credit-cards expects the expiration to be in the format of MM/YY or MMYYYYY (we're electing to use the former).
   * This should only be necessary on the initial render.
   */
  const formatExpirationDate = useCallback((expiration: string): string => {
    // split on the characters '-' and '/'
    const [year, month, day] = expiration.split(/[/,-]+/);

    return `${month}${year.substr(-2)}`;
  }, []);

  /**
   * Ensure that the value displayed in expiration date input matches the mask of ##/##.
   * If it doesn't, i.e. a string is provided for MM/YYYY trim the year portion to match the mask.
   * This should only really affect the incoming string on initial render since the mask will
   * prevent invalid formatted strings to be entered
   */
  const formatExpirationDateForInputDisplay = useCallback((expiration: string): string => {
    // Regex that matches the a string with the format of two numbers, an optional slash character, and then four numbers.
    // If the provided string matches this regex then a new string will be created using the first and last two numbers.
    const extendedDateStringRegex = /^\d{2}\/?\d{2}(\d{2})$/;

    if (extendedDateStringRegex.test(expiration)) {
      const firstTwoDigits = expiration.substr(0, 2);
      const lastTwoDigits = expiration.slice(-2);

      return `${firstTwoDigits}${lastTwoDigits}`;
    }

    return expiration;
  }, []);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<ICreditCardInput>({
    ccv: '',
    expiration: formatExpirationDate(creditCard.expirationDate as string),
    method: '',
    name: '',
    number: `${creditCard.lastFour}`.padStart(16, '*'),
  });
  const [inputFocus, setInputFocus] = useState<Focused>('expiry');
  const [updatePaymentMethodFn] = useUpdatePaymentMethod({
    onCompleted: (result) => {
      showToast('Payment method updated successfully.', 'success');
      onSave(result.updatePaymentMethod);
      handleClose();
    },
    onError: (err) => {
      showToast('There was an error trying to save your payment method. Please try again later.', 'error');
    },
  });

  const handleInputFocus = useCallback((event) => {
    setInputFocus(event.target.name === 'ccv' ? 'cvc' : event.target.name);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = useCallback(async () => {
    setLoading(true);

    if (formData.expiration && formData.ccv) {
      updatePaymentMethodFn({
        variables: {
          input: {
            paymentMethodId: creditCard.id,
            month: parseInt(formData.expiration.slice(0, 2)),
            year: parseInt(`20${formData.expiration.slice(2, 4)}`),
          },
        },
      });
    }

    setLoading(false);
  }, [formData, creditCard, updatePaymentMethodFn]);

  // only really care about the results of `isExpirationDateValid` and `isCvsValid`
  const cardValidation = getCardValidation({
    ...creditCard,
    name: '',
    number: creditCard.lastFour,
    method: creditCard.type,
    ccv: formData.ccv,
    expiration: formData.expiration,
  });

  return (
    <SideModalDrawer
      title="Edit card details"
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={handleSave}
      primaryButtonProps={{
        disabled: !cardValidation.isExpirationDateValid || !cardValidation.isCvsValid || loading,
        loading,
      }}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
    >
      <div className="mb-4">
        <Cards
          preview
          issuer={creditCard.type}
          cvc={formData.ccv}
          expiry={formData.expiration}
          focused={inputFocus}
          name={''}
          number={formData.number}
          placeholders={{ name: '' }}
        />
      </div>
      <Row align="start">
        <Col>
          <NumberInput
            label="Expiry Date"
            name="expiration"
            value={formatExpirationDateForInputDisplay(formData.expiration)}
            placeholder="MM/YY"
            onFocus={handleInputFocus}
            numberFormat={{ format: '##/##', isNumericString: true }}
            onChange={(expiration) => setFormData((prev) => ({ ...prev, expiration }))}
            required
            isInvalid={!cardValidation.isExpirationDateValid}
            errorText="Date is invalid or has already passed."
          />
        </Col>
        <Col>
          <NumberInput
            label="CVC"
            name="ccv"
            placeholder="Security Code"
            value={formData.ccv}
            onFocus={handleInputFocus}
            onChange={(ccv) => setFormData((prev) => ({ ...prev, ccv }))}
            numberFormat={{ format: creditCard.type === 'AMEX' ? '####' : '###', isNumericString: true }}
            required
            isInvalid={!cardValidation.isCvsValid}
          />
        </Col>
      </Row>
      <small>
        As a security precaution we cannot show you the current ccv on record for the credit card ending in{' '}
        {creditCard.lastFour}.
      </small>
    </SideModalDrawer>
  );
};

export default UpdateCreditCardModal;
