import * as type from './types';
import { uniqBy } from 'lodash';

interface ITimeOffStateShape {
  all: ITimeOff[];
  byId: Record<string, ITimeOff>;
}

const initialState: ITimeOffStateShape = {
  all: [],
  byId: {},
};

export const timeOffReducers = (
  state: ITimeOffStateShape = initialState,
  action: type.TimeOffActionTypes
): ITimeOffStateShape => {
  switch (action.type) {
    case type.GET_TIME_OFF_SUCCESS:
      const aggregatedTimeOff = uniqBy([...state.all, ...action.timeOff], 'id');
      return {
        all: aggregatedTimeOff,
        byId: Object.fromEntries(aggregatedTimeOff.map((timeOff: ITimeOff) => [timeOff.id, timeOff])),
      };
    case type.ADD_TIME_OFF_SUCCESS:
      return {
        all: [...state.all, action.timeOff],
        byId: { ...state.byId, [action.timeOff.id]: action.timeOff },
      };
    case type.UPDATE_TIME_OFF_SUCCESS:
      return {
        all: [
          ...state.all.filter((timeOff) => timeOff.id !== action.timeOff.id),
          { ...state.byId[action.timeOff.id], ...action.timeOff },
        ],
        byId: { ...state.byId, [action.timeOff.id]: { ...state.byId[action.timeOff.id], ...action.timeOff } },
      };
    case type.CANCEL_TIME_OFF_SUCCESS:
      delete state.byId[action.timeOff.id];
      return {
        all: [...state.all.filter((timeOff) => timeOff.id !== action.timeOff.id)],
        byId: state.byId,
      };
    default:
      return state;
  }
};
