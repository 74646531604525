import {
  Calendar,
  momentLocalizer,
  Formats,
  EventPropGetter,
  DateFormatFunction,
  DayPropGetter,
} from 'react-big-calendar';
import moment from 'moment';
import React, { SyntheticEvent } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import MonthViewTimeOffEvent from './MonthViewTimeOffEvent';
import { Row, Col } from 'shared/components/Layout';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface IProps {
  className?: string;
  date: string;
  timeOffRequests: ITimeOff[];
  updateDate: (date: string) => void;
  setSelectedTimeOff: (t: ITimeOff) => void;
}

const localizer = momentLocalizer(moment);

const dayFormat: DateFormatFunction = (date, culture, localizer) => localizer?.format(date, 'DD', 'en') || 'DD';

const formats: Formats = { dayFormat };

const eventStyleGetter: EventPropGetter<ITimeOff> = (timeOff: ITimeOff) => {
  return { className: `time-off-event time-off-${timeOff.status?.toLowerCase()}` };
};

const TimeOffCalendar: React.FC<IProps> = ({ className, date, timeOffRequests, updateDate, setSelectedTimeOff }) => {
  const timezonesByCenterId = useSelector((state: RootState) => state.timezone.byCenterId);
  const timezonesByBusinessId = useSelector((state: RootState) => state.timezone.byBusinessId);

  const currentMonth = moment(date).month();
  const dayOfWeek = moment(date).day();
  const weekStart = dayOfWeek === 6 ? moment(date).add(1, 'week').day(0) : moment(date).day(0);
  const isCurrentWeek = (day: moment.Moment) =>
    day.isSameOrAfter(moment(weekStart)) && day.isSameOrBefore(moment(weekStart).day(6));

  const dayForEachWeekOfMonth = [0, 1, 2, 3, 4, 5]
    .map((n) => moment(date).startOf('month').day(1).add(n, 'weeks'))
    .filter((d) => d.month() === currentMonth || d.day(5).month() === currentMonth);

  const dayPropGetter: DayPropGetter = (calDate) => {
    const isOutsideRange = moment(calDate.toISOString()).month() !== currentMonth;
    const numberOfEvents = timeOffRequests.filter((t) =>
      moment(calDate.toString()).isBetween(t.startTime, t.endTime, 'days', '[]')
    ).length;
    const additionalNumberOfEvents = numberOfEvents - 3;
    return {
      className: classnames(
        { 'outside-range': isOutsideRange },
        {
          [`additional-events-${additionalNumberOfEvents}`]:
            additionalNumberOfEvents > 0 && additionalNumberOfEvents < 16,
        },
        { [`additional-events-over-15`]: Boolean(additionalNumberOfEvents > 15) }
      ),
    };
  };

  const onSelectEvent = (event: ITimeOff, e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedTimeOff(event);
  };

  return (
    <div className={className}>
      <Row noGutters className="text-center bg-white rounded sm text-gray border-bottom">
        <Col>
          <div className="mt-4 mb-2">Monday</div>
        </Col>
        <Col>
          <div className="mt-4 mb-2">Tuesday</div>
        </Col>
        <Col>
          <div className="mt-4 mb-2">Wednesday</div>
        </Col>
        <Col>
          <div className="mt-4 mb-2">Thursday</div>
        </Col>
        <Col>
          <div className="mt-4 mb-2">Friday</div>
        </Col>
      </Row>
      {dayForEachWeekOfMonth.map((day, i) => (
        <div
          key={i}
          onClick={() => {
            updateDate(day.toISOString());
          }}
        >
          <Calendar
            formats={formats}
            className={isCurrentWeek(day) ? 'current-week' : ''}
            localizer={localizer}
            date={day.toDate()}
            events={timeOffRequests}
            views={['work_week']}
            defaultView="work_week"
            toolbar={false}
            startAccessor={(timeOff: ITimeOff) => {
              const timezone =
                (timeOff.centerId ? timezonesByCenterId[timeOff.centerId] : timezonesByBusinessId[timeOff.entityId]) ??
                moment.tz.guess();
              const startTimeInCenterTZ = moment(timeOff.startTime).tz(timezone).startOf('day');

              // react-big-calendar uses browser timezone, so we must pass it a new date in the browser time zone with the year/month/day/hours from our center time zone.
              // https://github.com/jquense/react-big-calendar/issues/118
              const startTimeForCal = new Date(
                startTimeInCenterTZ.year(),
                startTimeInCenterTZ.month(),
                startTimeInCenterTZ.date(),
                startTimeInCenterTZ.hour(),
                startTimeInCenterTZ.minute(),
                0
              );
              return startTimeForCal;
            }}
            endAccessor={(timeOff: ITimeOff) => {
              const timezone =
                (timeOff.centerId ? timezonesByCenterId[timeOff.centerId] : timezonesByBusinessId[timeOff.entityId]) ??
                moment.tz.guess();
              const endTimeInCenterTZ = timeOff.allDay
                ? moment(timeOff.endTime).tz(timezone).endOf('day')
                : moment(timeOff.endTime).tz(timezone).startOf('day');
              const endTimeForCal = new Date(
                endTimeInCenterTZ.year(),
                endTimeInCenterTZ.month(),
                endTimeInCenterTZ.date(),
                endTimeInCenterTZ.hour(),
                endTimeInCenterTZ.minute(),
                0
              );
              return endTimeForCal;
            }}
            components={{ event: MonthViewTimeOffEvent }}
            eventPropGetter={eventStyleGetter}
            dayPropGetter={dayPropGetter}
            onNavigate={() => {}}
            onSelectEvent={onSelectEvent}
          />
        </div>
      ))}
    </div>
  );
};

export default TimeOffCalendar;
