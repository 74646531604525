import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { adjustmentFields } from './fields';

interface IGetAdjustmentsForBusinessData {
  getAdjustmentsForBusiness: IAdjustment[];
}

interface IGetAdjustmentsForBusinessVariables {
  input: IGetAdjustmentsForBusinessInput;
}

export const GET_ADJUSTMENTS_FOR_BUSINESS = (fields: string = adjustmentFields) => gql`
  query ($input: GetAdjustmentsInput!) {
    getAdjustmentsForBusiness(input: $input) {
      ${fields}
    }
  }
`;

export const useGetAdjustmentsForBusiness = (
  options?: QueryHookOptions<IGetAdjustmentsForBusinessData, IGetAdjustmentsForBusinessVariables>,
  fields?: string
) =>
  useQuery<IGetAdjustmentsForBusinessData, IGetAdjustmentsForBusinessVariables>(
    GET_ADJUSTMENTS_FOR_BUSINESS(fields),
    options
  );
