import { gql } from '@apollo/client';
import { regulationFields } from 'shared/constants/graphqlFields';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';

interface IGetRegulationsData {
  getAllRegulations: IRegulation[];
}

interface IGetRegulationsVariables {}

export const GET_REGULATIONS = gql`
  query {
    getAllRegulations {
      ${regulationFields}
    }
  }
`;

export const useGetRegulations = (
  options?: QueryHookOptions<IGetRegulationsData, IGetRegulationsVariables> | undefined
) => useQuery<IGetRegulationsData, IGetRegulationsVariables>(GET_REGULATIONS, options);
