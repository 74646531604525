import React, { useCallback, useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { groupBy, orderBy } from 'lodash';
import Table from 'react-bootstrap/Table';
import ControlContainer from '../ControlContainer';
import AvatarCell from './AvatarCell';
import ShiftCell from './ShiftCell';
import ScheduleDataFilterContext from '../../providers/scheduleDataFilterProvider';

interface IProps {
  employees: IStaff[];
  datesForWeek: moment.Moment[];
  shiftsForCurrentWeek: IShift[];
}

// this component is responsible for providing an employee view of the current week
const EmployeeOverview: React.FC<IProps> = ({ employees, datesForWeek, shiftsForCurrentWeek, ...props }) => {
  const activeDataFiltersContextValue = useContext(ScheduleDataFilterContext);
  const [employeesToShow, setEmployeesToShow] = useState<IStaff[]>(employees);
  const allShiftsGroupedByDay = groupBy(shiftsForCurrentWeek, (s) => moment(s.startTime).day());
  // group all shifts by employee, sort shifts on start time to make sure they are in order
  const shiftsGroupedByEmployee = groupBy(
    orderBy(shiftsForCurrentWeek, (s) => moment(s.startTime), 'asc'),
    'personId'
  );
  // group employee shifts by day of week (0 - sunday, 6 - saturday)
  const shiftsGroupedByEmployeeByDay: Record<string, Record<number, IShift[]>> = Object.keys(
    shiftsGroupedByEmployee
  ).reduce((acc, curr) => {
    const value = shiftsGroupedByEmployee[curr];

    return {
      ...acc,
      [curr]: groupBy(value, (s) => moment(s.startTime).day()),
    };
  }, {});

  useEffect(() => {
    setEmployeesToShow(employees.filter((staff) => activeDataFiltersContextValue.employeeIds.includes(staff.id)));
  }, [employees, activeDataFiltersContextValue.employeeIds]);

  // calculate the number of hours an employee has worked for the current week
  const calculateHoursWorkedForShifts = useCallback((shifts: IShift[]): number => {
    const minutesWorked: number = shifts.reduce((acc: number, curr: IShift) => {
      const { startTime, endTime, breakMinutes, paidBreak } = curr;
      const breakMinutesToSubtract = paidBreak ? 0 : breakMinutes;

      return acc + (moment(endTime).diff(moment(startTime), 'minutes') - breakMinutesToSubtract);
    }, 0);

    return Number(minutesWorked / 60);
  }, []);

  /**
   * calculate the number of hours scheduled for a day
   * this will also look at the selected employees to show and adjust the totals accordingly
   */
  const calculateDayTotalHours = useCallback(
    (day: number): number => {
      const allShiftsForDay = allShiftsGroupedByDay[day] ?? [];

      if (allShiftsForDay.length) {
        const employeeIdsToShow = employeesToShow.map((staff) => staff.id);
        const assignedShifts = allShiftsForDay.filter(
          (s) => s.personId && s.personId !== null && employeeIdsToShow.includes(s.personId)
        );

        return calculateHoursWorkedForShifts(assignedShifts);
      }

      return 0;
    },
    [allShiftsGroupedByDay, calculateHoursWorkedForShifts, employeesToShow]
  );

  return (
    <ControlContainer className="p-0" id="kt-employee-schedules-container">
      <Table responsive borderless className="kt-schedules-employee-schedule-table">
        <thead>
          <tr>
            <th />
            {datesForWeek.map((date: moment.Moment, idx: number) => (
              <th key={`employee-overview-date-${date.format('dddd_D')}-${idx}`}>
                <div className="d-none d-md-block">{date.format('dddd, D')}</div>
                <div className="d-block d-md-none">{date.format('ddd, D')}</div>
              </th>
            ))}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {employeesToShow.map((staff: IStaff, idx: number) => {
            const employeeShiftsForWeek = shiftsGroupedByEmployee[staff.id] ?? [];
            const employeeShiftsForWeekGroupedByDay = shiftsGroupedByEmployeeByDay[staff.id] ?? {};
            const hoursWorked = calculateHoursWorkedForShifts(employeeShiftsForWeek);
            const hoursTextClasses = classnames({
              'text-center': true,
              'text-success': hoursWorked >= 30 && hoursWorked < 40,
              'text-danger': hoursWorked >= 40,
            });
            // get timeframe for each shift and stack
            return (
              <tr key={`kt-schedule-employee-table-row-${staff.id}`}>
                <AvatarCell staff={staff} />
                {datesForWeek.map((date: moment.Moment, idx: number) => {
                  const employeeShiftsForDate = employeeShiftsForWeekGroupedByDay[date.day()] ?? [];
                  // TODO: time off requests

                  if (employeeShiftsForDate.length) {
                    return (
                      <td key={`employee-shift-${date.day()}-${idx}`} className="p-0">
                        {employeeShiftsForDate.map((shift: IShift, j: number) => (
                          <ShiftCell key={`employee-shift-${shift.id}-${date.day()}-${j}`} shift={shift} />
                        ))}
                      </td>
                    );
                  }

                  return <td key={`employee-shift-${date.day()}-${idx}-empty`} />;
                })}
                <td className={hoursTextClasses}>{hoursWorked.toFixed(2)}</td>
              </tr>
            );
          })}
          <tr>
            <td />
            {datesForWeek.map((date: moment.Moment, idx: number) => (
              <td key={`day-total-hours-${date.day()}-${idx}`} className="text-center">
                {calculateDayTotalHours(date.day()).toFixed(2)}
              </td>
            ))}
            <td />
          </tr>
        </tbody>
      </Table>
    </ControlContainer>
  );
};

export default EmployeeOverview;
