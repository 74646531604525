import config from 'config';
import React, { useEffect, useMemo } from 'react';
import { Hub } from '@aws-amplify/core';
import { Helmet } from 'react-helmet';
import { useUpdateRestoreId } from '../../pages/MyProfile/graphql/mutations';
import { useFlags } from 'launchdarkly-react-client-sdk';

declare global {
  interface Window {
    fcWidget: {
      init: (settings: IFreshchatSettings) => void;
      isInitialized: () => boolean;
      destroy: () => void;
      on: (e: string, f: (res: any) => void) => void;
    };
  }
}

interface IFreshchatSettings {
  token: string;
  host: string;
  externalId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  restoreId: string | null;
}

const host = config.freshchat.apiUrl;
const token = config.freshchat.apiKey;
const widgetUrl = `${host}/js/widget.js`;

interface IProps {
  user: IPerson;
}

const Freshchat: React.FC<IProps> = ({ user }) => {
  const enabled = useMemo(() => config.env.startsWith('prod'), []);

  const [updateRestoreIdFn] = useUpdateRestoreId();
  const BootFreshChat = () => {
    let settings: IFreshchatSettings = {
      token: token,
      host: host,
      firstName: user?.firstname ?? '',
      lastName: user?.lastname ?? '',
      externalId: user?.id ?? '',
      restoreId: user?.restoreId ?? null,
    };

    if (user?.email) settings.email = user?.email;

    if (window.fcWidget && !window.fcWidget?.isInitialized()) {
      window.fcWidget.init(settings);
    }
    if (window.fcWidget)
      window.fcWidget.on('user:created', function (resp) {
        if (resp?.data?.restoreId) {
          updateRestoreIdFn({
            variables: {
              input: {
                restoreId: resp.data.restoreId,
              },
            },
          });
        }
      });
  };

  useEffect(() => {
    if (enabled) {
      Hub.listen('auth', (capsule) => {
        const { event, data } = capsule.payload;
        if (event === 'signOut') window?.fcWidget?.destroy();
      });
    }
  }, [enabled]);

  useEffect(() => {
    let interval: any;

    if (enabled) {
      interval = setInterval(() => {
        if (window.fcWidget) {
          // initialize the widget only when it is loaded
          BootFreshChat();
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [enabled]);

  if (!enabled) return null;

  return (
    <Helmet>
      <script async src={widgetUrl} id="freshchat-js-sdk" />
    </Helmet>
  );
};

export default Freshchat;
