import React, { useState, useEffect } from 'react';
//components
import { CardDeck } from 'react-bootstrap';
import NumberCard from '../../components/NumberCard/NumberCard';
//utils
import { useGetArrangementMetrics } from '../graphql/queries';
import { useTranslation } from 'react-i18next';
import { cwaStatusCardsColors } from 'shared/constants/tagColors';
import { capitalize } from 'shared/util/string';

interface IProps {
  handleFilteredStatus: (value: string[]) => void;
  entityId: string;
  onSelect: (value: string) => void;
  selectedStatuses: any[];
}

export const CwaCards: React.FC<IProps> = ({ handleFilteredStatus, entityId, onSelect, selectedStatuses }) => {
  const { t } = useTranslation(['subsidies']);

  const { loading, data } = useGetArrangementMetrics({ businessId: entityId });

  useEffect(() => {
    handleFilteredStatus(selectedStatuses);
  }, [selectedStatuses]);

  const cards = [
    <NumberCard
      key="ready-to-be-sent"
      title={capitalize(t('subsidies:cwa.status.ready-to-be-sent'))}
      subTitle={t('subsidies:cwa.status.subtitles.ready-to-be-sent')}
      number={data?.getArrangementMetrics.readyToBeSentCount}
      numberColor={cwaStatusCardsColors.READY_TO_BE_SENT}
      onClick={() => onSelect('READY_TO_BE_SENT')}
      active={selectedStatuses.find((f) => f === 'READY_TO_BE_SENT')}
    />,
    <NumberCard
      key="pending"
      title={capitalize(t('subsidies:cwa.status.pending'))}
      subTitle={t('subsidies:cwa.status.subtitles.pending')}
      number={data?.getArrangementMetrics.pendingCount}
      numberColor={cwaStatusCardsColors.PENDING}
      onClick={() => onSelect('PENDING')}
      active={selectedStatuses.find((f) => f === 'PENDING')}
    />,
    <NumberCard
      key="rejected"
      title={capitalize(t('subsidies:cwa.status.rejected'))}
      subTitle={t('subsidies:cwa.status.subtitles.rejected')}
      number={data?.getArrangementMetrics.rejectedCount}
      numberColor={cwaStatusCardsColors.FAILED}
      onClick={() => onSelect('REJECTED')}
      active={selectedStatuses.find((f) => f === 'REJECTED')}
    />,
  ];

  if (loading === true) return <></>;
  return <CardDeck>{cards.map((card) => card)}</CardDeck>;
};
