import Container from './Accordion';
import Item from './Item';
import SingleAccordion from './SingleAccordion';

export default {
  Container,
  Item,
};

export { SingleAccordion };
