import React, { useState, useCallback } from 'react';
import AccountDiscountsTable from './AccountDiscountsTable';
import AddDiscountModal from './AddDiscountModal';
import EditDiscountModal from './EditDiscountModal';
import RemoveDiscountModal from './RemoveDiscountModal';

interface IAppliedDiscountModalShape {
  open: boolean;
  discount: IAppliedAccountDiscount | null;
}

interface IProps {
  account: IAccount | null;
}

const DiscountsTab: React.FC<IProps> = ({ account, ...props }) => {
  const [showAddDiscountModal, setShowAddDiscountModal] = useState<boolean>(false);
  const [editDiscountModal, setEditDiscountModal] = useState<IAppliedDiscountModalShape>({
    open: false,
    discount: null,
  });
  const [deleteDiscountModal, setDeleteDiscountModal] = useState<IAppliedDiscountModalShape>({
    open: false,
    discount: null,
  });

  const getAccountChildForId = useCallback(
    (accountChildId: string): IAccountChild | null => {
      return (account?.children ?? []).find((child) => child.accountChildId === accountChildId) ?? null;
    },
    [account]
  );

  return (
    <>
      <div>
        <div>
          <h3>Discounts</h3>
        </div>
        <AccountDiscountsTable
          data={account?.discounts ?? []}
          getAccountChild={getAccountChildForId}
          onAddDiscount={() => setShowAddDiscountModal(true)}
          onEditDiscount={(discount) => setEditDiscountModal({ open: true, discount })}
          onRemoveDiscount={(discount) => setDeleteDiscountModal({ open: true, discount })}
          noPadding={true}
        />
      </div>
      {account && (
        <AddDiscountModal
          isOpen={showAddDiscountModal}
          account={account}
          onClose={() => setShowAddDiscountModal(false)}
        />
      )}
      {account && editDiscountModal.discount && (
        <EditDiscountModal
          isOpen={editDiscountModal.open}
          account={account}
          discount={editDiscountModal.discount}
          getAccountChild={getAccountChildForId}
          onClose={() => setEditDiscountModal({ open: false, discount: null })}
        />
      )}
      {deleteDiscountModal.discount && (
        <RemoveDiscountModal
          isOpen={deleteDiscountModal.open}
          discount={deleteDiscountModal.discount}
          onClose={() => setDeleteDiscountModal({ open: false, discount: null })}
        />
      )}
    </>
  );
};

export default DiscountsTab;
