import React, { useMemo } from 'react';
import moment from 'moment';
import DataTable from 'shared/components/DataTable';
import Button from 'shared/components/Buttons';
import Spinner from 'shared/components/Spinner';
import TodayTimeEntries from './TodayTimeEntries';
import { getFullName } from 'shared/util/string';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTranslation } from 'react-i18next';
import { useGetCenter } from 'gql/center/queries';
import { getAttendanceStatus } from '../TodayAttendance';

interface IProps {
  attendanceSessions: ISession[];
  loading: boolean;
  timezone: string;
  date: string;
  handleCheckIn: (sessions: ISession[]) => void;
  handleCheckOut: (sessions: ISession[]) => void;
  onSort: (field: string, direction: 'asc' | 'desc') => void;
  childIdsInLoadingState: string[];
  centerId: string;
  onClearSession: (session: ISession) => void;
}

const TodayAttendanceTable: React.FC<IProps> = ({
  attendanceSessions,
  loading,
  timezone,
  date,
  handleCheckIn,
  handleCheckOut,
  onSort,
  childIdsInLoadingState,
  centerId: selectedCenterId,
  onClearSession,
}) => {
  const isToday = useMemo(() => moment().tz(timezone).isSame(moment(date), 'd'), [date, timezone]);

  const { t } = useTranslation();

  const hasCheckInPermission = useHasRoleAreaLevel({
    area: AreaType.Attendance,
    permission: PermissionType.CheckInOut,
    level: RoleLevelType.Read,
  });

  const hasEditSessionPermission = useHasRoleAreaLevel({
    area: AreaType.Attendance,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const gradeOptions = useMemo(
    () => [
      { value: 'PREK', label: t('child.grades.prek') },
      { value: 'PREP', label: t('child.grades.prep') },
      { value: 'FIRST', label: '1' },
      { value: 'SECOND', label: '2' },
      { value: 'THIRD', label: '3' },
      { value: 'FOURTH', label: '4' },
      { value: 'FIFTH', label: '5' },
      { value: 'SIXTH', label: '6' },
      { value: 'SEVENTH', label: '7' },
    ],
    [t]
  );

  const getGradeDisplay = (grade: Grade) => (!!grade ? gradeOptions.find((item) => item.value === grade)?.label : '');

  const centerData = useGetCenter(
    {
      variables: {
        id: selectedCenterId,
      },
      fetchPolicy: 'cache-first',
    },
    `serviceType`
  );

  return (
    <DataTable
      className={'attendance-table day-table'}
      data={attendanceSessions}
      dataSize={attendanceSessions.length}
      showLoadingOverlay={loading || centerData.loading}
      showPagination={false}
      noPadding
      showSelect={false}
      onSort={(field, direction) => onSort(field, direction === 'ASCENDING' ? 'asc' : 'desc')}
      noDataText="No Attendance Found."
      rowClasses={(row: ISession) => row.classId}
      columns={[
        {
          text: t('attendance.today.columns.child'),
          headerClasses: 'text-left',
          dataField: 'child.lastname',
          sort: true,
          formatter: (cell: any, row: ISession) => getFullName(row.child),
        },
        {
          text: t('attendance.today.columns.grade'),
          headerClasses: 'text-left',
          dataField: 'child.grade',
          sort: true,
          formatter: (grade: Grade) => getGradeDisplay(grade),
        },
        {
          text: t('attendance.today.columns.class'),
          headerClasses: 'text-left',
          dataField: 'class.name',
          sort: true,
        },
        {
          text: t('attendance.today.columns.status'),
          headerClasses: 'text-left',
          sort: true,
          dataField: 'status',
          formatter: (status: string, row: ISession) => getAttendanceStatus(row, timezone),
        },
        {
          text: t('attendance.today.columns.check-in-out'),
          dataField: 'dropOffTime',
          headerClasses: 'text-left',
          formatter: (time: string, session: ISession) => <TodayTimeEntries session={session} timezone={timezone} />,
        },
        {
          text: t('attendance.today.columns.actions'),
          dataField: '',
          headerClasses: 'text-left',
          formatter: (cell: any, session: ISession) => (
            <div className="d-flex">
              {isToday &&
                !session.absence &&
                (childIdsInLoadingState.includes(session.childId) ? (
                  <div>
                    <Spinner className="text-gray mr-2" />
                  </div>
                ) : (
                  <>
                    {hasCheckInPermission && (
                      <>
                        {session.timeEntries.some((te) => !te.timeOut) ? (
                          <Button size="sm" variant="light" onClick={() => handleCheckOut([session])} className="mr-1">
                            {t('attendance.today.buttons.check-out')}
                          </Button>
                        ) : (
                          <Button size="sm" variant="light" onClick={() => handleCheckIn([session])} className="mr-1">
                            {t('attendance.today.buttons.check-in')}
                          </Button>
                        )}
                        {hasEditSessionPermission &&
                          ((session.timeEntries.length > 0 && session.timeEntries.every((te) => te.timeOut)) ||
                            session.absence) && (
                            <Button
                              size="sm"
                              variant="light"
                              onClick={() => onClearSession(session)}
                              disabled={!session.metadata.isWritable}
                            >
                              {t('attendance.today.buttons.clear')}
                            </Button>
                          )}
                      </>
                    )}
                  </>
                ))}
            </div>
          ),
        },
      ]}
    />
  );
};

export default TodayAttendanceTable;
