import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import Stepper from '../Stepper';
import { useUpdateOnboardingStatus } from 'pages/MyProfile/graphql/mutations';
import { showToast } from '../../Toast';
import { Spinner } from 'react-bootstrap';
import useGetMe from 'shared/hooks/useGetMe';
import { ONBOARDING_STEPS } from 'shared/constants/Onboarding';

interface IProps {
  expanded: boolean;
  closeToggleOnClick?: () => void;
  disabledStepClickHandler: () => void;
  stepperItems: IStepperItem[];
  stepsStatus: Array<boolean>;
  onItemClick: (route: any) => void;
}

interface IRoleSteps {
  role: string;
  steps: number[];
}

const OnboardingModalDrawer: React.FC<IProps> = ({
  expanded,
  closeToggleOnClick,
  disabledStepClickHandler,
  stepperItems,
  onItemClick,
  stepsStatus,
}) => {
  const { data, loading, refetch } = useGetMe();
  const person: IStaff | IPerson | null = data?.getMe ?? null;
  const [updateOnboardingStatusFn, { loading: updateOnboardingStatusLoading }] = useUpdateOnboardingStatus();

  const closeToggleClickHandler = () => {
    closeToggleOnClick && closeToggleOnClick();
  };

  const containerClassNames = classnames({
    'k2-onboarding-modal-container': true,
    hidden: person?.onboardingStatus === 'Skipped' || person?.onboardingStatus === 'Completed',
    expanded,
    compressed: !expanded,
  });

  const modalInnerClassNames = classnames({
    expanded,
    compressed: !expanded,
    'd-flex': true,
    'k2-onboarding-modal-container-inner': true,
  });

  const modalHeaderClassNames = classnames({
    expanded,
    compressed: !expanded,
    'd-flex': true,
    'onboarding-modal-header': true,
  });

  const updateStatus = () => {
    refetch()
      .then(() => {
        updateOnboardingStatusFn({
          variables: {
            input: { onboardingStatus: 'Skipped' },
          },
        });
      })
      .catch(() => showToast('There was an error saving your changes.', 'error'));
  };

  return (
    <div className={containerClassNames}>
      <div className={modalInnerClassNames}>
        <div className={modalHeaderClassNames}>
          <img className="logo" alt={'Logo'} src={'/apple-touch-icon.png'} width="64px" />
          <div className="d-flex align-items-center justify-content-center arrow-icon-container">
            <FontAwesomeIcon
              className="arrow-icon"
              onClick={closeToggleClickHandler}
              rotation={expanded ? undefined : 180}
              size="2x"
              icon={faAngleLeft}
            />
          </div>
        </div>
        <div className="d-flex onboarding-modal-body flex-column">
          <div className="heading">
            <h2>You're just a few clicks away from completing your onboarding!</h2>
            <small>Management made easy</small>
          </div>
          <Stepper
            showDescription={expanded}
            steps={stepperItems}
            onItemClick={onItemClick}
            disabledStepClickHandler={disabledStepClickHandler}
            stepsStatus={stepsStatus}
          />
        </div>
        {stepsStatus[ONBOARDING_STEPS.completeProfile] && (
          <div className="onboarding-modal-footer d-flex flex-grow-1 mb-10 align-items-end justify-content-center">
            <div className="onboarding-footer-content text-center">
              {updateOnboardingStatusLoading || loading ? (
                <>
                  <span className="cursor-pointer">Saving...</span>
                  <Spinner animation="border" size="sm" />
                </>
              ) : (
                <span className="cursor-pointer" onClick={updateStatus}>
                  Got it! Skip.
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingModalDrawer;
