import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
interface IProps {
  declarationType: DeclarationType;
}

export enum DeclarationType {
  Certficate,
  Determination,
}

const declarationTexts: Record<DeclarationType, JSX.Element> = {
  [DeclarationType.Certficate]: (
    <div>
      I declare that:
      <ul>
        <li>The information I have provided is true and correct; and </li>
        <li>
          Where this certificate is made against an ACCS enrolment - I have made reasonable but unsuccessful attempts to
          identify an individual eligible for CCS for this child; and{' '}
        </li>
        <li>That this certificate is provided under 85CB of the A New Tax System (Family Assistance) Act 1999</li>
      </ul>
      I understand that:
      <ul>
        <li>
          The Provider must give an appropriate State/Territory body notice that this provider considers the child to
          whom this certificate relates is or was at risk of serious harm of abuse or neglect no later than six (6)
          weeks after the day this certificate takes effect.
        </li>
        <li>Giving false or misleading information is a serious offense. </li>
        <li>I must notify Services Australia of any change(s) to this information.</li>
        <li>I have kept records of observations and evidence to support my certifying that the child is at risk.</li>
      </ul>
    </div>
  ),
  [DeclarationType.Determination]: (
    <div>
      I declare that:
      <ul>
        <li>The information I have provided is true and correct; and </li>
        <li>
          Where this determination is made against an ACCS enrolment - I have made reasonable but unsuccessful attempts
          to identify an individual eligible for CCS for this child.
        </li>
      </ul>
      I understand that:
      <ul>
        <li>Giving false or misleading information is a serious offense.</li>
        <li>I must notify Services Australia of any change(s) to this information.</li>
        <li>I have kept records of observations and evidence to support my certifying that the child is at risk.</li>
      </ul>
    </div>
  ),
};

const DeclarationAccordion: React.FC<IProps> = ({ declarationType }) => {
  return (
    <Accordion
      className={'d-flex flex-column justify-content-center pb-4'}
      defaultActiveKey="-1"
      style={{ width: '100%' }}
    >
      <Accordion.Toggle
        eventKey="100"
        className="kt-account-ccs-tab-accordion-toggle"
        // @ts-ignore
        type="button"
      >
        <div className="float-center">
          <span>View Declaration Agreement</span>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse className="" eventKey="100">
        {declarationTexts[declarationType]}
      </Accordion.Collapse>
    </Accordion>
  );
};

export default DeclarationAccordion;
