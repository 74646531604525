export const reportListFields: string = `
  type
  categoryType
  lastGeneratedOn
  formatTypes
`;

export const reportMetadataFields: string = `
  type
  categoryType
  generatedOn
  generatedBy
  params {
    key
    value
  }
`;

export const transactionTotalsReportFields: string = `
  date
  centerName
  centerId
  centerExternalId
  transactionTypeId
  transactionTypeName
  feeAmount
  discountAmount
  earlyLateAmount
  subsidyAmount
  creditAmount
  debitAmount
  paymentAmount
  totalAmount
  transactionCount
`;

export const occupancyTotalReportFields: string = `
  center
  centerId
  class
  classId
  date
  dayOfWeek
  count
  capacity
  utilization
`;

export const sageGlReportFields: string = `
  startDate
  endDate
  records {
    glCode
    amount
    centerName
    date
  }
`;
