import { IntegrationType, IntegrationMappingType, ReasonForEndingContract } from 'generated/graphql';
import { mapValues } from 'lodash';
import { useTranslation } from 'react-i18next';
import IntegrationMappingForm from '../MappingForm/IntegrationMappingForm';

interface IProps {
  businessId: string;
}

const CcrmWithdrawlReasonsMapForm = ({ businessId }: IProps) => {
  const { t } = useTranslation(['accounts']);

  const sources: Record<ReasonForEndingContract, { sourceHint?: string; sourceName?: string }> = {
    ADDING_DAYS: { sourceName: t('accounts:reasonForContractEnding.options.ADDING_DAYS') },
    CARE_NOT_REQUIRED: { sourceName: t('accounts:reasonForContractEnding.options.CARE_NOT_REQUIRED') },
    CENTER_DISMISSAL: { sourceName: t('accounts:reasonForContractEnding.options.CENTER_DISMISSAL') },
    CHANGING_TO_CASUAL: { sourceName: t('accounts:reasonForContractEnding.options.CHANGING_TO_CASUAL') },
    CHANGING_TO_PERMANENT: { sourceName: t('accounts:reasonForContractEnding.options.CHANGING_TO_PERMANENT') },
    CONTRACT_CHANGE: { sourceName: t('accounts:reasonForContractEnding.options.CONTRACT_CHANGE') },
    COVID: { sourceName: t('accounts:reasonForContractEnding.options.COVID') },
    DECREASING_DAYS: { sourceName: t('accounts:reasonForContractEnding.options.DECREASING_DAYS') },
    ENDING_CARE: { sourceName: t('accounts:reasonForContractEnding.options.ENDING_CARE') },
    EXTENDED_HOLIDAYS: { sourceName: t('accounts:reasonForContractEnding.options.EXTENDED_HOLIDAYS') },
    EXTENDED_MEDICAL: { sourceName: t('accounts:reasonForContractEnding.options.EXTENDED_MEDICAL') },
    EXTENDED_TIME_OFF: { sourceName: t('accounts:reasonForContractEnding.options.EXTENDED_TIME_OFF') },
    GOING_TO_KINDERGARTEN: { sourceName: t('accounts:reasonForContractEnding.options.GOING_TO_KINDERGARTEN') },
    GOING_TO_SCHOOL: { sourceName: t('accounts:reasonForContractEnding.options.GOING_TO_SCHOOL') },
    LEAVE_CENTER: { sourceName: t('accounts:reasonForContractEnding.options.LEAVE_CENTER') },
    MOVING_AWAY: { sourceName: t('accounts:reasonForContractEnding.options.MOVING_AWAY') },
    ROOM_CHANGE: { sourceName: t('accounts:reasonForContractEnding.options.ROOM_CHANGE') },
    TEMPORARY_ENROLLMENT: { sourceName: t('accounts:reasonForContractEnding.options.TEMPORARY_ENROLLMENT') },
    ACCOUNT_DEBT: { sourceName: t('accounts:reasonForContractEnding.options.ACCOUNT_DEBT') },
    RE_ENROLLMENT: { sourceName: t('accounts:reasonForContractEnding.options.RE_ENROLLMENT') },
  };

  return (
    <IntegrationMappingForm
      businessId={businessId}
      integrationType={IntegrationType.ChildcareCrm}
      integrationMappingType={IntegrationMappingType.WithdrawalReasons}
      sources={mapValues(sources, (x) => ({ ...x, sourceHint: x.sourceName }))}
    />
  );
};

export default CcrmWithdrawlReasonsMapForm;
