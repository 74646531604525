export const defaultPositionFields = `
  id
  businessId
  name
  archivedAt
  assignedStaffCount
`;

export const pagedListPositions = `
  totalRecords
  pageNumber
  pageSize
  data {
    ${defaultPositionFields}
 }
`;
