import { useGetISCaseClaimPaymentsForCenters } from 'gql/iSCase/queries';
import { head } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { currencyFormat } from 'shared/util/currency';
import { displayDuration } from 'shared/util/timeUtils';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
const dateFormat = 'DD/MM/YYYY';

interface IProps {}

const ISCasesPaymentsTab: React.FC<IProps> = () => {
  const { data: centers } = useGetActiveCentersWithLoading();
  const [selectedCenterIds, setSelectedCenterIds] = useState<string[]>([]);
  const [sortOption, setSortOption] = useState<IElasticsearchSortFilter | undefined>({
    field: 'weekEnding',
    direction: 'DESCENDING',
  });
  const [searchText, setSearchText] = useState<string>('');
  const [tableState, tableFunctions] = useDatatableState();

  const { data: caseClaimPaymentsQueryResult, loading: caseClaimPaymentsQueryLoading } =
    useGetISCaseClaimPaymentsForCenters({
      variables: {
        input: {
          businessId: head(centers)?.entityId ?? '',
          centerIds: selectedCenterIds,
          searchText: searchText,
          sortDtos: sortOption ? [sortOption] : undefined,
          pageSize: tableState.pageSize,
          pageNumber: tableState.activePage,
        },
      },
    });
  const caseClaimPayments = caseClaimPaymentsQueryResult?.getIsCaseClaimPaymentsForCenters;

  return (
    <>
      <TableHeader>
        <div className="d-flex justify-content-between w-100 my-2">
          <TableSearch onChange={setSearchText} placeholder="Search by IS case Id or child" />
          <DropdownFilter
            dropdownId="centerFilter"
            title={fieldLabels.center}
            placeholder={fieldLabels.center}
            options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
            onFilterSelect={(values) => setSelectedCenterIds(values.map((x) => x.value))}
            selectedFilters={
              selectedCenterIds?.map((id) => ({
                label: centers?.find((c) => c.id === id)?.name ?? 'ERROR',
                value: id,
              })) ?? []
            }
          />
        </div>
      </TableHeader>
      <DataTable
        noPadding={true}
        data={caseClaimPayments?.data ?? []}
        dataSize={caseClaimPayments?.totalRecords ?? 0}
        keyField="isCaseId"
        showSelect={false}
        showPagination={true}
        showLoadingOverlay={caseClaimPaymentsQueryLoading}
        {...tableState}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        onPageChange={tableFunctions.changePage}
        onSort={(field, direction) => setSortOption({ field, direction })}
        columns={[
          {
            text: `${fieldLabels.center}`,
            dataField: 'centerName',
            sort: true,
          },
          {
            text: 'Case ID',
            dataField: 'isCaseId',
            sort: true,
          },
          {
            text: 'Week Ending',
            dataField: 'weekEnding',
            sort: true,
            formatter: (_: any, row: IIsCaseClaimPayment) => moment(row.weekEnding).format(dateFormat),
          },
          {
            text: 'Payment Type',
            dataField: 'paymentType',
            sort: true,
          },
          {
            text: 'Service Provision',
            dataField: 'serviceProvision',
            formatter: (_: any, row: IIsCaseClaimPayment) => serviceProvisionFormatStrings[row.serviceProvision],
            sort: true,
          },
          {
            text: 'Calculation Details',
            dataField: 'calculationDetails',
            formatter: (_: any, row: IIsCaseClaimPayment) => row.calculationDetails ?? 'N/A',
            sort: true,
          },
          {
            text: 'Hours Claimed',
            dataField: 'hoursClaimed',
            align: 'right',
            formatter: (_: any, row: IIsCaseClaimPayment) => displayDuration(row.hoursClaimed),
            sort: true,
          },
          {
            text: 'Hours Paid',
            dataField: 'hoursPaid',
            align: 'right',
            formatter: (_: any, row: IIsCaseClaimPayment) => displayDuration(row.hoursPaid),
            sort: true,
          },
          {
            text: 'Payment Amount',
            dataField: 'paymentAmount',
            align: 'right',
            formatter: (_: any, row: IIsCaseClaimPayment) => currencyFormat(row.paymentAmount, true),
            sort: true,
          },
        ]}
      />
    </>
  );
};

export default ISCasesPaymentsTab;

const serviceProvisionFormatStrings: Readonly<Record<IsServiceProvisionType, string>> = {
  FACE_TO_FACE: 'Face-to-Face',
  NOT_FACE_TO_FACE: 'Not Face-to-Face',
};
