import React, { useState } from 'react';
import TextInput from 'shared/components/TextInput';
import { Row, Col } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import DateInput from 'shared/components/DateInput';
import { Form } from 'react-bootstrap';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import './busRosterDetailInputs.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ConfirmDestinationRemovalModal from './ConfirmDestinationRemovalModal';
import moment from 'moment';

interface IProps {
  formData: IBusRosterInformationForm;
  assignedChildren: IBusRosterAccountChildGridShape[] | [];
  destinationOptions: IDestinationStateShape[];
  onChange: React.Dispatch<React.SetStateAction<IBusRosterInformationForm>>;
  onAddDestination: () => void;
  onRemoveDestination: (destinationId: string) => void;
}

const BusRosterDetailInputs: React.FC<IProps> = ({
  formData,
  assignedChildren,
  destinationOptions,
  onChange,
  onAddDestination,
  onRemoveDestination,
  ...props
}) => {
  const { t } = useTranslation();
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const centerSelectOptions: ICenter[] = useGetActiveCenters().filter((c) => c.entityId === formData.businessId);
  const [showConfirmDestinationRemovalModal, setShowConfirmDestinationRemovalModal] =
    useState<IConfirmDestinationRemovalModalShape>();
  const { isValid: isDateValid, error: errorMessage } = dateValidation(formData.effectiveDate, formData.endDate);

  function dateValidation(effectiveDate?: string, endDate?: string) {
    let isValid = true;
    let error = '';
    isValid = !!effectiveDate || !!endDate;

    if (
      isValid &&
      (moment(effectiveDate).isAfter(moment(endDate).endOf('day')) ||
        moment(endDate).isBefore(moment(effectiveDate).startOf('day')))
    ) {
      isValid = false;
      error = t('bus-roster.date-error-message');
    }

    return { isValid, error };
  }

  return (
    <>
      <Row align="end">
        <Col xs={12} sm={4} className="order-1">
          <TextInput
            id="bus-roster-name-input"
            name="name"
            label={t('bus-roster.input-labels.name')}
            value={formData.name}
            onChange={(value) =>
              onChange((prev) => ({
                ...prev,
                name: value,
              }))
            }
            required
          />
        </Col>
        <Col xs={12} sm={4} className="order-2">
          <Select
            required
            id="center-input"
            label={`${fieldLabels.center}`}
            value={formData.centerId}
            options={centerSelectOptions.map((c) => ({ value: c.id, label: c.name }))}
            onChange={(option: any) => {
              onChange((prev) => ({
                ...prev,
                centerId: option.value,
              }));
            }}
            disabled={!formData.businessId}
          />
        </Col>
        <Col xs={12} sm={4} className="order-3">
          <Select
            id="destinations-select-input"
            label={t('bus-roster.input-labels.destinations')}
            options={destinationOptions?.map((d) => ({ value: d.value, label: d.label }))}
            onChange={(options) => {
              if (options == null || options?.length < formData.destinations?.length) {
                var removedDestinationId = formData.destinations.filter(
                  (d: any) => !options?.some((o: any) => o.value == d)
                );
                if (assignedChildren.some((child) => child.input.destination === removedDestinationId[0])) {
                  setShowConfirmDestinationRemovalModal({
                    isOpen: true,
                    removeDestinationName: destinationOptions.find((d) => d.value == removedDestinationId[0])?.label!,
                    removeDestinationId: removedDestinationId[0],
                    newDestinationOptions: options,
                  });
                } else {
                  onChange((prev) => ({ ...prev, destinations: options?.map((opt: any) => opt.value) }));
                }
              } else {
                onChange((prev) => ({ ...prev, destinations: options?.map((opt: any) => opt.value) }));
              }
            }}
            value={
              destinationOptions?.filter((c) => formData.destinations?.includes(c.value)).length == 0
                ? null
                : destinationOptions?.filter((c) => formData.destinations?.includes(c.value))
            }
            isMulti
            showAddNew
            required
            onAddNew={onAddDestination}
          ></Select>
        </Col>
      </Row>
      <Row align="end">
        <Col xs={12} sm={4} className="order-4">
          <DateInput
            label={t('bus-roster.input-labels.effective-date')}
            date={formData.effectiveDate}
            onDateSelect={(date) => onChange((prev) => ({ ...prev, effectiveDate: date }))}
            required
            isOutsideRange={(day) => day.isAfter(moment(formData.endDate).endOf('day'))}
            hasCustomValidation={true}
            isValid={dateValidation(formData.effectiveDate, formData.endDate).isValid}
          />
        </Col>
        <Col xs={12} sm={4} className="order-5">
          <DateInput
            label={t('bus-roster.input-labels.end-date')}
            date={formData.endDate}
            onDateSelect={(date) => onChange((prev) => ({ ...prev, endDate: date }))}
            required
            isOutsideRange={(day) => day.isBefore(moment(formData.effectiveDate).startOf('day'))}
            hasCustomValidation={true}
            isValid={dateValidation(formData.effectiveDate, formData.endDate).isValid}
          />
        </Col>
        <Col xs={12} sm={4} className="order-6 order-sm-8">
          {!isDateValid && errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}
        </Col>
        <Col xs={12} sm={4} className="order-6">
          <Form.Group>
            <div className="d-flex flex-row">
              <Form.Label>{t('bus-roster.input-labels.bus-runs')}</Form.Label>
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
            <Form.Check
              inline
              label={'AM'}
              name="bus-runs"
              type="radio"
              checked={formData.type.toUpperCase() === 'AM'}
              onChange={(event) => onChange((prev) => ({ ...prev, type: 'AM' }))}
            />
            <Form.Check
              inline
              label={'PM'}
              name="bus-runs"
              type="radio"
              checked={formData.type.toUpperCase() === 'PM'}
              onChange={(event) => onChange((prev) => ({ ...prev, type: 'PM' }))}
            />
            <Form.Check
              inline
              label={'Both'}
              name="bus-runs"
              type="radio"
              checked={formData.type.toUpperCase() === 'BOTH'}
              onChange={(event) => onChange((prev) => ({ ...prev, type: 'BOTH' }))}
            />
          </Form.Group>
        </Col>
      </Row>
      <ConfirmDestinationRemovalModal
        isOpen={showConfirmDestinationRemovalModal?.isOpen ?? false}
        newDestinations={showConfirmDestinationRemovalModal?.newDestinationOptions}
        removeDestinationName={showConfirmDestinationRemovalModal?.removeDestinationName!}
        removeDestinationId={showConfirmDestinationRemovalModal?.removeDestinationId!}
        onClose={() =>
          setShowConfirmDestinationRemovalModal({
            isOpen: false,
            newDestinationOptions: [],
            removeDestinationName: '',
            removeDestinationId: '',
          })
        }
        onConfirm={(newDestinationOptions, removedDestinationId) => {
          onRemoveDestination(removedDestinationId);
          onChange((prev) => ({ ...prev, destinations: newDestinationOptions?.map((opt: any) => opt.value) }));
        }}
      ></ConfirmDestinationRemovalModal>
    </>
  );
};

export default BusRosterDetailInputs;
