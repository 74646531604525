import React, { useState, useCallback } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import {
  useSearchActiveStaffForCenter,
  useSearchActiveStaffScopedToEntity,
} from 'shared/hooks/useSearchActiveStaffForCenter';
import {
  GET_ACTIVE_STAFF_FOR_NEW_TIME_ENTRY,
  useGetShiftsForPerson,
  useLazyGetOpenTimeEntryForPersonAtCenter,
} from '../../graphql/queries';
import { useCreateTimeEntryV2 } from '../../graphql/mutations';
import cast from 'shared/util/cast';
import { showToast } from 'shared/components/Toast';
import ScheduledShiftsList from './ScheduledShiftsList';
import { DateInputWithTimezone } from 'shared/components/DateInput';
import { RootState } from 'store/reducers';
import ClockOutOpenTimeEntry from './ClockOutOpenTimeEntry';
import { getFullName } from 'shared/util/string';
import TimeInput from 'shared/components/TimePicker/TimeInput';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  person?: IStaff;
  centerId: string;
  entityId: string;
  selectedCenter?: ICenter | null;
  refetchAttendance: any;
}

interface IFormDataShape {
  date: string | undefined;
  personId: string | null;
  person: IStaff | null;
  positionId: string | null;
  position: IStaffPosition | null;
  type: TimeEntryType | TimeEntryTypeV2 | null;
  startTime: string | null;
  endTime: string | null;
  note?: string;
}

const newTimeEntry: IFormDataShape = {
  date: undefined,
  personId: null,
  person: null,
  positionId: null,
  position: null,
  type: 'SHIFT',
  startTime: null,
  endTime: null,
  note: '',
};

const NewTimeEntryModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  person = null,
  centerId,
  entityId,
  selectedCenter = null,
  refetchAttendance,
}) => {
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const timezone = timezonesByCenter[centerId] ?? momentTz.tz.guess();
  const [formData, setFormData] = useState<IFormDataShape>({
    ...newTimeEntry,
    person,
    personId: person?.id ?? '',
  });
  const { data: getShiftsForPersonForDateData } = useGetShiftsForPerson(
    formData.personId ?? '',
    formData.date ? moment(formData.date).tz(timezone).startOf('day').toISOString() : '',
    formData.date ? moment(formData.date).tz(timezone).endOf('day').toISOString() : ''
  );
  const { loading: searchActiveStaffLoading, data: searchActiveStaffData } = useSearchActiveStaffForCenter(
    centerId,
    GET_ACTIVE_STAFF_FOR_NEW_TIME_ENTRY
  );
  const { loading: searchActiveEntityStaffLoading, data: entityStaffData } = useSearchActiveStaffScopedToEntity(
    entityId,
    GET_ACTIVE_STAFF_FOR_NEW_TIME_ENTRY
  );

  const [
    loadOpenTimeEntryForPersonAtCenter,
    { loading: openTimeEntryForPersonAtCenterLoading, data: getOpenTimeEntryForPersonAtCenterData },
  ] = useLazyGetOpenTimeEntryForPersonAtCenter();

  const centerStaff = searchActiveStaffData?.searchStaff.data ?? [];
  const entityStaff = entityStaffData?.searchStaff.data ?? [];
  const openTimeEntriesForSelectedEmployee =
    getOpenTimeEntryForPersonAtCenterData?.getOpenTimeEntryForPersonAtCenter ?? null;

  const [createTimeEntryFnV2, { loading: createTimeEntryLoadingV2 }] = useCreateTimeEntryV2();
  const isTimeEntryToday = formData.date && moment().tz(timezone).isSame(formData.date, 'd');
  const startTimeWithDate = moment(formData.date)
    .tz(timezone)
    .hours(Number(formData.startTime?.split(':')[0]))
    .minutes(Number(formData.startTime?.split(':')[1]));
  const endTimeWithDate = formData.endTime
    ? moment(formData.date)
        .tz(timezone)
        .hours(Number(formData.endTime?.split(':')[0]))
        .minutes(Number(formData.endTime?.split(':')[1]))
    : null;
  const invalidTime =
    formData.endTime &&
    formData.startTime &&
    !moment(formData.endTime, 'HH:mm').isAfter(moment(formData.startTime, 'HH:mm'));
  const isEndTimeInFuture = endTimeWithDate?.isAfter(moment().tz(timezone));
  const isStartTimeInFuture = startTimeWithDate.isAfter(moment().tz(timezone));

  const loadOpenTimeEntryForSelectedPersonAtCenter = useCallback(
    (option: IStaff) => {
      loadOpenTimeEntryForPersonAtCenter({
        variables: {
          input: {
            centerId: centerId,
            personId: option.id,
          },
        },
      });
    },
    [centerId, loadOpenTimeEntryForPersonAtCenter]
  );

  const isFormValid =
    (formData.endTime || isTimeEntryToday) &&
    !invalidTime &&
    formData.personId &&
    formData.type &&
    formData.startTime &&
    formData.date &&
    !isEndTimeInFuture &&
    !isStartTimeInFuture;

  const handleClose = useCallback(() => {
    onClose();
    setFormData({ ...newTimeEntry, person, personId: person?.id ?? '' });
  }, [onClose, person]);

  const handleSubmit = useCallback(
    (data: IFormDataShape) => {
      createTimeEntryFnV2({
        variables: {
          input: {
            centerId,
            personId: cast<string>(data.personId),
            positionId: cast<string>(data.positionId),
            type: cast<TimeEntryTypeV2>(data.type),
            timeIn: startTimeWithDate.toISOString(),
            timeOut: endTimeWithDate?.toISOString(),
            note: data.note ?? null,
          },
        },
      })
        .then(() => {
          refetchAttendance();
          setFormData({ ...newTimeEntry, person, personId: person?.id ?? '' });
          onClose();
          showToast('Time entry created successfully.', 'success');
        })
        .catch((e) => {
          showToast('There was an error creating this time entry.', 'error');
        });
    },
    [centerId, createTimeEntryFnV2, endTimeWithDate, onClose, person, refetchAttendance, startTimeWithDate]
  );

  const changePerson = useCallback(
    (option: IStaff) => {
      setFormData((prev) => ({
        ...prev,
        personId: option.id,
        person: option,
        positionId: null,
        position: null,
      }));
      loadOpenTimeEntryForSelectedPersonAtCenter(option);
    },
    [loadOpenTimeEntryForSelectedPersonAtCenter]
  );

  return (
    <SideModalDrawer
      title="New Time Entry"
      show={isOpen}
      onHide={handleClose}
      dialogClassName=""
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={() => handleSubmit(formData)}
      primaryButtonProps={{
        disabled: !isFormValid || createTimeEntryLoadingV2,
        loading: createTimeEntryLoadingV2,
      }}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <Form>
        <div className="mb-4">
          Add an employee's work hours to their timesheet
          {selectedCenter && ` at ${selectedCenter.name}`}.
        </div>
        <Row>
          <Column>
            <DateInputWithTimezone
              required
              label="Date"
              date={formData.date}
              onDateSelect={(date) => setFormData((prev) => ({ ...prev, date }))}
              isOutsideRange={(day) => moment(day).isAfter(moment(), 'date')}
              timezone={timezone}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Select
              required
              label="Employee"
              value={formData.person}
              options={person ? [person] : [...centerStaff, ...entityStaff]}
              onChange={(option: IStaff) => changePerson(option)}
              getOptionValue={(option: IStaff) => option.id}
              getOptionLabel={(option: IStaff) => getFullName(option)}
              isLoading={searchActiveStaffLoading || searchActiveEntityStaffLoading}
              disabled={Boolean(person)}
            />
          </Column>
        </Row>
        {!openTimeEntryForPersonAtCenterLoading &&
          (!Boolean(openTimeEntriesForSelectedEmployee) ? (
            <>
              {formData.person && (
                <Row>
                  <Column>
                    <ScheduledShiftsList
                      scheduledShifts={getShiftsForPersonForDateData?.getShiftsForPersonForDates ?? []}
                      timezonesByCenter={timezonesByCenter}
                    />
                  </Column>
                </Row>
              )}
              <Row>
                <Column>
                  <Select
                    required
                    label="Position"
                    options={(formData.person?.positions ?? []).filter((p) =>
                      p.scopeType === 'CENTER' ? p.scopeId === (selectedCenter?.id ?? '') : p
                    )}
                    getOptionValue={(option: IStaffPosition) => option.id}
                    getOptionLabel={(option: IStaffPosition) => option.positionName}
                    // TODO: should be change person
                    onChange={(option: IStaffPosition) =>
                      setFormData((prev) => ({
                        ...prev,
                        positionId: option.id,
                        position: option,
                      }))
                    }
                    disabled={!formData.personId}
                  />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Select
                    required
                    label="Type"
                    value={formData.type}
                    options={[
                      { label: 'Shift', value: 'SHIFT' },
                      { label: 'Training', value: 'TRAINING' },
                    ]}
                    onChange={(option) => setFormData((prev) => ({ ...prev, type: option.value }))}
                  />
                </Column>
              </Row>
              <Row className="mb-4">
                <Column>
                  <div className="d-flex flex-row">
                    <Form.Label>In</Form.Label>
                    <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
                  </div>
                  <TimeInput
                    isAM={true}
                    value={formData.startTime}
                    onChange={(startTime) => {
                      setFormData((prev) => ({ ...prev, startTime }));
                    }}
                  />
                </Column>
                <Column>
                  <Form.Label>Out</Form.Label>
                  <TimeInput
                    isAM={false}
                    value={formData.endTime}
                    onChange={(endTime) => setFormData((prev) => ({ ...prev, endTime }))}
                  />
                </Column>
              </Row>
            </>
          ) : (
            formData.person &&
            openTimeEntriesForSelectedEmployee && (
              <ClockOutOpenTimeEntry
                timeEntry={openTimeEntriesForSelectedEmployee}
                timezone={timezone}
                selectedStaff={formData.person}
                loadOpenTimeEntriesForSelectedPerson={loadOpenTimeEntryForSelectedPersonAtCenter}
              />
            )
          ))}
      </Form>
    </SideModalDrawer>
  );
};

export default NewTimeEntryModal;
