import { orderBy } from 'lodash';
import React from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { SingleAccordion } from 'shared/components/Accordion';
import { PersonAvatar } from 'shared/components/Avatar';
import Button from 'shared/components/Buttons';
import { Row, Col } from 'shared/components/Layout';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import { RiskTag } from 'shared/components/Tag';
import useFormatDate from 'shared/hooks/useFormatDate';
import { camelCaseToSpaced, getFullName } from 'shared/util/string';

interface IProps {
  condition: IWellnessCondition;
  revisions: IWellnessConditionRevision[];
  loading: boolean;
  isOpen: boolean;
  onHide: () => void;
}

const ConditionHistoryModal: React.FC<IProps> = ({ condition, revisions, loading, isOpen, onHide }) => {
  const formatDate = useFormatDate();

  return (
    <Modal backdrop="static" centered show={isOpen} onHide={onHide} scrollable={true} size="lg">
      <Modal.Header closeButton>
        <h4>History</h4>
      </Modal.Header>
      <Modal.Body className="p-4 m-0">
        {loading ? (
          <LoadingLines />
        ) : (
          <div>
            {condition.archivedAt && (
              <div className="px-4 py-3 border-bottom">
                <Row>
                  <Col xs={2}>Archived</Col>
                  <Col xs={2}>{formatDate(condition.archivedAt, 'MM/DD/YYYY')}</Col>
                  <Col xs={3}>{getFullName(condition.archivedByPerson)}</Col>
                </Row>
              </div>
            )}
            {orderBy(revisions, 'revisedAt', 'desc').map((revision, index) => (
              <SingleAccordion
                defaultOpen={false}
                className="flex-grow-1 box-shadow-0 border-bottom border-silver"
                bodyClassName="box-shadow-0"
                titleClassName="border-bottom-0 text-text-color font-weight-normal"
                title={
                  <Row>
                    <Col xs={2}>{index === revisions.length - 1 ? 'Submitted' : 'Edited'}</Col>
                    <Col xs={2}>{formatDate(revision.revisedAt, 'MM/DD/YYYY')}</Col>
                    <Col xs={3}>{getFullName(revision.revisedByPerson)}</Col>
                    {index !== revisions.length - 1 && (
                      <Col xs={5}>
                        Changes:{' '}
                        {[
                          revision.levelChanged && condition.levelLabel,
                          revision.symptomsChanged && condition.symptomsLabel,
                          revision.freeTextChanged && condition.freeTextLabel,
                          revision.documentsChanged && 'documents',
                        ]
                          .filter((c) => c)
                          .join(', ')}
                      </Col>
                    )}
                  </Row>
                }
              >
                <div>
                  <Row className="mb-4">
                    <Col>
                      <Form.Label>Type</Form.Label>
                      {condition.typeName}
                    </Col>
                    <Col>
                      <Form.Label>Name</Form.Label>
                      {condition.name}
                    </Col>
                    <Col>
                      <Form.Label>{condition.levelLabel}</Form.Label>
                      <Row noGutters>
                        <RiskTag risk={revision.version.level} />
                      </Row>
                    </Col>
                  </Row>
                  <Row align="start">
                    {revision.version.symptoms && revision.version.symptomsLabel && (
                      <Col>
                        <Form.Label>{revision.version.symptomsLabel}</Form.Label>
                        <Row className="mb-4" noGutters>
                          {revision.version.symptoms.map((s) => (
                            <div className="tag mr-2 mb-2"> {camelCaseToSpaced(s)} </div>
                          ))}
                        </Row>
                      </Col>
                    )}
                    <Col>
                      <Form.Label>{revision.version.freeTextLabel}</Form.Label>
                      <div className="mb-4">{revision.version.freeText}</div>
                    </Col>
                  </Row>
                  <Form.Label>{index === revisions.length - 1 ? 'Submitted ' : 'Edited '} By:</Form.Label>
                  <PersonAvatar size="xs" person={revision.revisedByPerson} className="mr-2" />
                  {getFullName(revision.revisedByPerson)}
                  {' on '}
                  {formatDate(revision.revisedAt, 'MM/DD/YYYY')}
                </div>
              </SingleAccordion>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className={`border-top-0`}>
        <Button variant="light" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConditionHistoryModal;
