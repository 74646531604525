import React from 'react';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  fteCount: number;
  capcityCount: number;
  totalEnrollmentsCount: number;
  totalEnrollmentPercentage: number;
}

const TotalsRow: React.FC<IProps> = ({
  fteCount,
  capcityCount,
  totalEnrollmentsCount,
  totalEnrollmentPercentage,
  ...props
}) => {
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  return (
    <tr>
      <td colSpan={2}>{fieldLabels.center} Totals</td>
      <td>{capcityCount}</td>
      <td>{totalEnrollmentsCount}</td>
      <td>{fteCount.toFixed(2)}</td>
      <td>{totalEnrollmentPercentage.toFixed(2)}%</td>
    </tr>
  );
};

export default TotalsRow;
