import { gql } from '@apollo/client';
import { useQuery, useLazyQuery } from 'shared/apis/core';
import {
  ccsEnrolmentCategoryCountResultFields,
  ccsEnrolmentPagedResultFields,
  enrolmentFields,
  arrangementTypeFields,
} from './fields';
import { QueryHookOptions, LazyQueryHookOptions } from '@apollo/client';

interface IGetCcsEnrolmentsForChildrenVariables {
  businessId: string;
  accountId: string;
  centerId: string;
  children: string[];
}

interface IGetCcsEnrolmentsForChildrenData {
  getCcsEnrolmentsForChildren: ICcsEnrolment[];
}

export const GET_CCS_ENROLMENTS_FOR_CHILDREN = (fields: string = enrolmentFields) => gql`
  query($businessId: ID!, $accountId: ID!, $centerId: ID!, $children:[ID!]!) {
    getCcsEnrolmentsForChildren(businessId: $businessId, accountId: $accountId, centerId: $centerId, children: $children) {
      ${fields}
    }
  }
`;

export const useGetCcsEnrolmentsForChildren = (
  options?: QueryHookOptions<IGetCcsEnrolmentsForChildrenData, IGetCcsEnrolmentsForChildrenVariables>,
  fields?: string
) =>
  useQuery<IGetCcsEnrolmentsForChildrenData, IGetCcsEnrolmentsForChildrenVariables>(
    GET_CCS_ENROLMENTS_FOR_CHILDREN(fields),
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

const GET_CCS_ENROLMENTS_FOR_CENTERS = (fields: string = ccsEnrolmentPagedResultFields) => gql`
  query($businessId: ID!, $centerIds: [ID!]!, $category: EnrolmentCategory, $searchText: String, $pageNumber: Int, $pageSize: Int, $sortDtos: [SearchSort], $statusType: AccountStatusType, $statusAtDate: String) {
    getCcsEnrolmentsForCenters(businessId: $businessId, centerIds: $centerIds, category: $category, searchText: $searchText, pageNumber: $pageNumber, pageSize: $pageSize, sortDtos: $sortDtos, statusType: $statusType, statusAtDate: $statusAtDate) {
      ${fields}
    }
  }
`;

interface IGetCcsEnrolmentsForCentersData {
  getCcsEnrolmentsForCenters: IPagedResult<ICcsEnrolment>;
}

interface IGetCcsEnrolmentsForCentersVariables {
  businessId: string;
  centerIds: string[];
  category?: EnrolmentCategory;
  searchText?: string;
  pageNumber?: number;
  pageSize?: number;
  sortDtos?: IElasticsearchSortFilter[];
  statusType?: AccountStatusType;
  statusAtDate: string;
}

export const useGetCcsEnrolmentsForCenters = (
  options?: QueryHookOptions<IGetCcsEnrolmentsForCentersData, IGetCcsEnrolmentsForCentersVariables>,
  fields?: string
) =>
  useQuery<IGetCcsEnrolmentsForCentersData, IGetCcsEnrolmentsForCentersVariables>(
    GET_CCS_ENROLMENTS_FOR_CENTERS(fields),
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

const GET_CCS_ENROLMENTCATEGORY_COUNT_FOR_CENTERS = (fields: string = ccsEnrolmentCategoryCountResultFields) => gql`
  query($businessId: ID!, $centerIds: [ID!]!) {
    getCcsEnrolmentCategoryCountForCenters(businessId: $businessId, centerIds: $centerIds) {
      ${fields}
    }
  }
`;

interface IGetCcsEnrolmentCategoryCountForCentersData {
  getCcsEnrolmentCategoryCountForCenters: Record<EnrolmentCategory, number>;
}

interface IGetCcsEnrolmentCategoryCountForCentersVariables {
  businessId: string;
  centerIds: string[];
}

export const useGetCcsEnrolmentCategoryCountForCenters = (
  options?: QueryHookOptions<
    IGetCcsEnrolmentCategoryCountForCentersData,
    IGetCcsEnrolmentCategoryCountForCentersVariables
  >,
  fields?: string
) =>
  useQuery<IGetCcsEnrolmentCategoryCountForCentersData, IGetCcsEnrolmentCategoryCountForCentersVariables>(
    GET_CCS_ENROLMENTCATEGORY_COUNT_FOR_CENTERS(fields),
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

export const GET_ARRANGEMENT_TYPE_FOR_CHILDREN_IN_TIME_FRAME = gql`
  query ($centerId:ID!,$childIds:[ID!]!,$startDate:String!,$endDate:String!){
    getArrangementTypeForChildrenInTimeFrame(centerId:$centerId,childIds:$childIds,startDate:$startDate,endDate:$endDate){
      ${arrangementTypeFields}
    }
  }
`;

interface IGetArrangementTypeForChildrenInTimeFrameVariables {
  centerId: string;
  childIds: string[];
  startDate: string;
  endDate: string;
}

interface IGetArrangementTypeForChildrenInTimeFrameData {
  getArrangementTypeForChildrenInTimeFrame: ArrangementTypeDto[];
}

export const useGetArrangementTypeForChildrenInTimeFrameLazy = (
  options?: LazyQueryHookOptions<
    IGetArrangementTypeForChildrenInTimeFrameData,
    IGetArrangementTypeForChildrenInTimeFrameVariables
  >
) =>
  useLazyQuery<IGetArrangementTypeForChildrenInTimeFrameData, IGetArrangementTypeForChildrenInTimeFrameVariables>(
    GET_ARRANGEMENT_TYPE_FOR_CHILDREN_IN_TIME_FRAME,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

interface IGetUnlinkedEnrolmentsForAccountData {
  getUnlinkedEnrolmentsForAccount: ICcsEnrolment[];
}

interface IGetUnlinkedEnrolmentsForAccountVariables {
  centerId: string;
  accountId: string;
}

export const GET_UNLINKED_ENROLMENTS = gql`
  query ($centerId:ID!, $accountId:ID!){
    getUnlinkedEnrolmentsForAccount(centerId:$centerId, accountId:$accountId){
      ${enrolmentFields}
    }
  }
`;

export const useGetUnlinkedEnrolmentsForAccount = (
  options?: QueryHookOptions<IGetUnlinkedEnrolmentsForAccountData, IGetUnlinkedEnrolmentsForAccountVariables>
) =>
  useQuery<IGetUnlinkedEnrolmentsForAccountData, IGetUnlinkedEnrolmentsForAccountVariables>(GET_UNLINKED_ENROLMENTS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
