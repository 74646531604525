export const billingCycleTemplateFields = `
  id
  name
  businessId
  period
  frequency
  centerId
  centerIds
  centers {
    id
    name
  }
  createdAt
  dayOfWeek
  dayOfMonth
  invoiceSchedule {
    unit
    value
  }
  additionalChargePeriod {
    unit
    value
  }
  deactivatedAt
  business {
    id
    name
  }
  cycleType
  accountIds
  frequencyPayPeriod
  gracePeriodDays
  lateChargeAmount
`;

export const billingCycleFields = `
  id
  accountId
  startDate
  endDate
  nextInvoiceDate
  nextBillingDate
  nextPaymentDate
  period
  frequency
  createdAt
  createdBy
  dayOfWeek
  dayOfMonth
  invoiceSchedule {
    unit
    value
  }
  additionalChargePeriod {
    unit
    value
  }
  transactionLimit
  isPaused
  nextBillingPeriod {
    start
    end
  }
  cycleType
  billingCycleTemplateId
  gracePeriodDays
  lateChargeAmount
  billingPeriods {
    startDate
    endDate
    paymentDueDate
  }
`;
