import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { setCurrentBusinessFeature } from '../../store/context/actions';

export const GET_BUSINESS_FEATURES_BY_ID = gql`
  query ($id: ID!, $type: BusinessFeatureType!) {
    getBusinessFeatureByBusinessId(id: $id, type: $type) {
      id
      businessId
      type
      enabled
      subFeatures {
        type
        enabled
      }
    }
  }
`;

export const useGetBusinessFeatureOptionsById = (
  options?: QueryHookOptions<
    { getBusinessFeatureByBusinessId: IBusinessFeature[] },
    { id: string; type: BusinessFeatureType | null }
  >
) => {
  const dispatch = useDispatch();
  return useQuery<
    { getBusinessFeatureByBusinessId: IBusinessFeature[] },
    { id: string; type: BusinessFeatureType | null }
  >(GET_BUSINESS_FEATURES_BY_ID, {
    fetchPolicy: 'network-only',
    ...options,
    onCompleted: (getBusinessFeatureData) => {
      getBusinessFeatureData &&
        dispatch(setCurrentBusinessFeature(getBusinessFeatureData.getBusinessFeatureByBusinessId));
    },
  });
};
