import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import centerFields from './fields';

type ItemizedBillType = 'SUMMARY' | 'DETAILED';

interface IUpdateCenterMerchantIdData {
  updateCenterMerchantId: IMerchant;
}

interface IUpdateCenterMerchantIdVariables {
  input: {
    id: string;
    merchantId?: string | null;
    isSandbox: boolean;
  };
}

interface IUpdatePassThroughSurchargeData {
  updatePassThroughSurcharge: ICenterBillingSettings;
}

interface IUpdatePassThroughSurchargeVariables {
  input: {
    centerId: string;
    passThroughAchSurcharge?: boolean;
    passThroughCreditSurcharge?: boolean;
  };
}

interface IUpdatePassThroughDishonorData {
  updatePassThroughDishonor: ICenterBillingSettings;
}

interface IUpdatePassThroughDishonorVariables {
  input: {
    centerId: string;
    passThroughDishonor: boolean;
    dishonorFeeAmount?: number;
  };
}

interface IUpdatePassThroughSurchargeForAllData {
  updatePassThroughSurchargeForAll: ICenterBillingSettings[];
}

interface IUpdatePassThroughSurchargeForAllVariables {
  input: {
    businessId: string;
    passThroughSurcharge: boolean;
    passThroughAchSurcharge: boolean | null;
    passThroughCreditSurcharge: boolean;
  };
}

interface IUpdateCenterPayPeriodVariables {
  input: {
    id: string;
    payPeriodWeekStart: DayOfWeek;
    payPeriodWeekEnd: DayOfWeek;
  };
}

interface IUpdateCenterPayPeriodData {
  updateCenterPayPeriod: ICenter;
}

interface IUpdateItemizedBillTypeVariables {
  input: {
    centerIds: string[];
    type: ItemizedBillType;
  };
}

interface IUpdateItemizedBillTypeData {
  updateItemizedBillType: ICenterBillingSettings[];
}

export const UPDATE_CENTER_MERCHANT_ID = () => gql`
  mutation ($input: UpdateCenterMerchantIdInput!) {
    updateCenterMerchantId(input: $input) {
      id
      processor
      merchantId
      merchantToken
      isSandbox
    }
  }
`;

export const UPDATE_PASS_THROUGH_SURCHARGE_FOR_CENTER = () => gql`
  mutation ($input: UpdatePassThroughSurchargeInput!) {
    updatePassThroughSurcharge(input: $input) {
      centerId
      passThroughSurcharge
      passThroughAchSurcharge
      passThroughCreditSurcharge
      lastCharged
    }
  }
`;

export const UPDATE_PASS_THROUGH_DISHONOR_FOR_CENTER = () => gql`
  mutation ($input: UpdatePassThroughDishonorInput!) {
    updatePassThroughDishonor(input: $input) {
      centerId
      passThroughDishonor
      dishonorFeeAmount
    }
  }
`;

export const UPDATE_PASS_THROUGH_SURCHARGE_FOR_BUSINESS = () => gql`
  mutation ($input: UpdatePassThroughSurchargeForAllInput!) {
    updatePassThroughSurchargeForAll(input: $input) {
      centerId
      passThroughSurcharge
      passThroughAchSurcharge
      passThroughCreditSurcharge
      lastCharged
    }
  }
`;

export const UPDATE_CENTER_PAY_PERIOD = (fields: string = centerFields) => gql`
  mutation($input: UpdateCenterPayPeriodInput!) {
    updateCenterPayPeriod(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_ITEMIZED_BILL_TYPE_FOR_CENTERS = gql`
  mutation ($input: UpdateBillingSettingItemizedBillsInput!) {
    updateItemizedBillType(input: $input) {
      center {
        id
        name
        avatar {
          url
        }
        address {
          city
          state
          country
        }
        tags {
          id
          name
          category
        }
      }
      itemizedBillType
      groupSubsidyAndSessionLineItems
    }
  }
`;

export const useUpdateCenterMerchantId = (
  options?: MutationHookOptions<IUpdateCenterMerchantIdData, IUpdateCenterMerchantIdVariables>,
  fields?: string
) => useMutation<IUpdateCenterMerchantIdData, IUpdateCenterMerchantIdVariables>(UPDATE_CENTER_MERCHANT_ID(), options);

export const useUpdatePassThroughSurcharge = (
  options?: MutationHookOptions<IUpdatePassThroughSurchargeData, IUpdatePassThroughSurchargeVariables>,
  fields?: string
) =>
  useMutation<IUpdatePassThroughSurchargeData, IUpdatePassThroughSurchargeVariables>(
    UPDATE_PASS_THROUGH_SURCHARGE_FOR_CENTER(),
    options
  );

export const useUpdatePassThroughSurchargeForAll = (
  options?: MutationHookOptions<IUpdatePassThroughSurchargeForAllData, IUpdatePassThroughSurchargeForAllVariables>,
  fields?: string
) =>
  useMutation<IUpdatePassThroughSurchargeForAllData, IUpdatePassThroughSurchargeForAllVariables>(
    UPDATE_PASS_THROUGH_SURCHARGE_FOR_BUSINESS(),
    options
  );

export const useUpdatePassThroughDishonor = (
  options?: MutationHookOptions<IUpdatePassThroughDishonorData, IUpdatePassThroughDishonorVariables>
) =>
  useMutation<IUpdatePassThroughDishonorData, IUpdatePassThroughDishonorVariables>(
    UPDATE_PASS_THROUGH_DISHONOR_FOR_CENTER(),
    options
  );

export const useUpdateCenterPayPeriod = (
  options?: MutationHookOptions<IUpdateCenterPayPeriodData, IUpdateCenterPayPeriodVariables>,
  fields?: string
) =>
  useMutation<IUpdateCenterPayPeriodData, IUpdateCenterPayPeriodVariables>(UPDATE_CENTER_PAY_PERIOD(fields), options);

export const useUpdateItemizedBillTypeForCenters = (
  options?: MutationHookOptions<IUpdateItemizedBillTypeData, IUpdateItemizedBillTypeVariables>
) =>
  useMutation<IUpdateItemizedBillTypeData, IUpdateItemizedBillTypeVariables>(
    UPDATE_ITEMIZED_BILL_TYPE_FOR_CENTERS,
    options
  );
