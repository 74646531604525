import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { applicationSettingsFields } from './fields';
import { IApplicationSettings } from 'shared/types/application';

export const GET_APPLICATION_SETTINGS = gql`
  query($input: GetApplicationSettingInput!) {
    getApplicationSetting(input: $input) {
      ${applicationSettingsFields}
    }
  }
`;

interface IGetApplicationSettings {
  getApplicationSetting: IApplicationSettings;
}

export const useGetApplicationSettings = (options?: QueryHookOptions<IGetApplicationSettings, Input<TenantAware>>) =>
  useQuery(GET_APPLICATION_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
