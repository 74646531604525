import axios from 'axios';
import config from 'config';
import { IBulkTransactionDetail } from 'pages/Billing/BulkTransactions/interfaces/IBulkTransactionDetail';
import { TargetType } from 'pages/Billing/BulkTransactions/interfaces/TargetType';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

enum Resolutions {
  RETRY = 0,
  SKIP = 1,
}

interface IUseBulkTransactionDetails {
  bulkTransaction?: IBulkTransactionDetail;
  resolveTarget: (targetId: string, resolution?: Resolutions) => void;
  isLoadingBulkTransaction: boolean;
}

/**
 * Provides data for displaying bulk transaction, and a method for resolving target failures
 * @param bulkTransactionId
 * @param options
 * @returns
 */
export const useBulkTransactionsDetails = (
  bulkTransactionId: string,
  options?: {
    onResolutionError?: (message: string) => void;
    onResolutionSuccess?: () => void;
    onErrorLoadingBulkTransaction?: (message: string) => void;
  }
): IUseBulkTransactionDetails => {
  const [bulkTransaction, setBulkTransactionData] = useState<IBulkTransactionDetail | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);
  useEffect(() => {
    async function loadBulkTransaction() {
      try {
        const response = await axios.get(`${config.api.billing.uri}/api/v2/bulk-transactions/${bulkTransactionId}`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });
        const targetType = (() => {
          if (/child/i.test(response.data.applyTo)) {
            return TargetType.Children;
          }
          return TargetType.Account;
        })();
        setBulkTransactionData({
          ...response.data,
          targetType,
        });
      } catch (e) {
        console.error('ERROR LOADING BULK TRANSACTIONS:', e);
        let message: string = 'Unexpected Error';
        if (e instanceof Error) {
          message = e.message;
        }
        if (options?.onErrorLoadingBulkTransaction) options.onErrorLoadingBulkTransaction(message);
      } finally {
        setIsLoading(false);
      }
    }
    if (bulkTransactionId) {
      setIsLoading(true);
      loadBulkTransaction();
    }
  }, [bulkTransactionId, jwtToken, options]);

  const resolveFailedTarget = useCallback(
    async (targetId: string, resolution: Resolutions = Resolutions.RETRY) => {
      try {
        const response = await axios.post(
          `${config.api.billing.uri}/api/v2/bulk-transactions/resolve/${targetId}/${resolution}`,
          {},
          { headers: { Authorization: `Bearer ${jwtToken}` } }
        );
        const updatedTarget = response.data;
        setBulkTransactionData((prev: IBulkTransactionDetail | undefined) => {
          if (prev === undefined) return prev;
          const updatedTargets = [...prev.targets];
          const targetIndex = prev?.targets.findIndex((t) => t.id === targetId);
          if (targetIndex !== -1) {
            updatedTargets.splice(targetIndex, 1, updatedTarget);
          }
          return { ...prev, targets: updatedTargets };
        });
        if (options?.onResolutionSuccess) options.onResolutionSuccess();
      } catch (e) {
        console.error(`Error resolving target ${targetId} with resolution ${resolution}:`, e);
        if (options?.onResolutionError) options.onResolutionError('Error ');
      }
    },
    [jwtToken, options]
  );

  return {
    bulkTransaction,
    resolveTarget: resolveFailedTarget,
    isLoadingBulkTransaction: isLoading,
  };
};
