import React, { useState, useCallback, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import { SingleAccordion } from 'shared/components/Accordion';
import Card from 'shared/components/Card';
import { ApolloError } from '@apollo/client';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import { ChangeEmailCard, ChangeEmailModal } from 'shared/components/ChangeEmail';
import AlertContainer from 'shared/components/AlertContainer';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import ChangePinCard from 'shared/components/ChangePin/ChangePinCard';
import ChangePinModal from 'shared/components/ChangePin/ChangePinModal';
import { useUpdatePinForPerson } from 'gql/person/mutations';
import { showToast } from 'shared/components/Toast';

import KisiCard from './KisiCard';

interface IProps {
  data: IStaff | undefined;
  kisiData: IKisiShare | undefined;
  loading: boolean;
  // error: ApolloError | undefined;
  // readOnly: boolean;
  // canDeactivateStaff?: boolean;
  // kisiActive: boolean;
  // refetchStaff: () => void;
}

const ProfileTab: React.FC<IProps> = ({ data, kisiData, loading, ...props }) => {
  return (
    <Row>
      <Column lg>
        <KisiCard kisiShareInfo={kisiData} personInfo={data} loading={loading} loadingLines={7} />
      </Column>
    </Row>
  );
};

export default ProfileTab;
