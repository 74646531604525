import { MutationHookOptions, gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { enrolmentFields } from './fields';

export const ADD_CHILD_CCS_ENROLMENT = gql`
  mutation($input: AddChildCcsEnrolmentInput!) {
    addChildCcsEnrolment(input: $input) {
      ${enrolmentFields}
    }
  }
`;

export const EDIT_CHILD_CCS_ENROLMENT = gql`
  mutation($input: EditChildCcsEnrolmentInput!) {
    editChildCcsEnrolment(input: $input) {
      ${enrolmentFields}
    }
  }
`;

export const useAddChildCcsEnrolment = () => {
  return useMutation<{ addChildCcsEnrolment: ICcsEnrolment }, { input: IAddCcsEnrolmentInput }>(
    ADD_CHILD_CCS_ENROLMENT,
    {}
  );
};

export const useEditChildCcsEnrolment = () => {
  return useMutation<{ editChildCcsEnrolment: ICcsEnrolment }, { input: IEditCcsEnrolmentInput }>(
    EDIT_CHILD_CCS_ENROLMENT,
    {}
  );
};

export const UNLINK_CCS_ENROLMENT_BY_ID = gql`
  mutation ($centerId: ID!, $accountId: ID!, $enrolmentId: ID!) {
    unlinkEnrolmentById(centerId: $centerId, accountId: $accountId, enrolmentId: $enrolmentId)
  }
`;

export const useUnlinkEnrolmentById = (
  options: MutationHookOptions<
    { unlinkEnrolmentById: boolean },
    { centerId: string; accountId: string; enrolmentId: string }
  >
) => {
  return useMutation<{ unlinkEnrolmentById: boolean }, { centerId: string; accountId: string; enrolmentId: string }>(
    UNLINK_CCS_ENROLMENT_BY_ID,
    options
  );
};

export const LINK_CCS_ENROLMENT_BY_ID = gql`
  mutation ($centerId: ID!, $accountId: ID!, $enrolmentId: ID!) {
    linkEnrolmentById(centerId: $centerId, accountId: $accountId, enrolmentId: $enrolmentId)
  }
`;

export const useLinkEnrolmentById = (
  options: MutationHookOptions<
    { linkEnrolmentById: boolean },
    { centerId: string; accountId: string; enrolmentId: string }
  >
) => {
  return useMutation<{ linkEnrolmentById: boolean }, { centerId: string; accountId: string; enrolmentId: string }>(
    LINK_CCS_ENROLMENT_BY_ID,
    options
  );
};
