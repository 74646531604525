import React from 'react';
import _ from 'lodash';

import * as Mui from '@mui/material';

import colors from '_colors.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { useGetPhotoStatistics } from 'pages/Photos/gql/query';

interface IProps {
  refetchStats: boolean;
  setRefetchStats: (x: boolean) => void;
}

const PhotoCounts: React.FC<IProps> = ({ refetchStats, setRefetchStats }) => {
  const today = moment().format('YYYY-MM-DD');
  const centerId = useSelector((state: RootState) => state.context.centerId) ?? '';

  const { data, loading, refetch } = useGetPhotoStatistics({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: _.isNil(centerId) || _.isEmpty(centerId),
    variables: {
      input: {
        centerIds: [centerId],
        takenAfter: moment(today).startOf('day').format(),
        takenBefore: moment(today).endOf('day').format(),
        from: 1,
        size: 18,
      },
    },
  });

  React.useEffect(() => {
    if (refetchStats) {
      refetch({
        input: {
          centerIds: [centerId],
          takenAfter: moment(today).startOf('day').format(),
          takenBefore: moment(today).endOf('day').format(),
          from: 1,
          size: 18,
        },
      });
      setRefetchStats(false);
    }
  }, [refetchStats]);

  return (
    <>
      <Mui.Grid container spacing={2} sx={{ paddingBottom: '16px' }}>
        <Mui.Grid item lg={4} md={4} sm={6} xs={12}>
          {loading ? (
            <Mui.Skeleton variant="rectangular" height={71} />
          ) : (
            <Mui.Card elevation={0}>
              <Mui.CardContent
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Mui.Typography variant="h4" sx={{ paddingRight: '6px' }}>
                  Photos Approved Today
                </Mui.Typography>
                <Mui.Divider orientation="vertical" flexItem />
                <Mui.Typography variant="h2" sx={{ paddingLeft: '6px', color: colors.success }}>
                  {_.isNil(data) ? '' : data?.getPhotoStatistics.approvedCount}
                </Mui.Typography>
              </Mui.CardContent>
            </Mui.Card>
          )}
        </Mui.Grid>
        <Mui.Grid item lg={4} md={4} sm={6} xs={12}>
          {loading ? (
            <Mui.Skeleton variant="rectangular" height={71} />
          ) : (
            <Mui.Card elevation={0}>
              <Mui.CardContent
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Mui.Typography variant="h4" sx={{ paddingRight: '6px' }}>
                  Photos Waiting Approval
                </Mui.Typography>
                <Mui.Divider orientation="vertical" flexItem />
                <Mui.Typography variant="h2" sx={{ paddingLeft: '6px', color: colors.danger }}>
                  {_.isNil(data) ? '' : data?.getPhotoStatistics.waitingForApprovalCount}
                </Mui.Typography>
              </Mui.CardContent>
            </Mui.Card>
          )}
        </Mui.Grid>
        <Mui.Grid item lg={4} md={4} sm={6} xs={12}>
          {loading ? (
            <Mui.Skeleton variant="rectangular" height={71} />
          ) : (
            <Mui.Card elevation={0}>
              <Mui.CardContent
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Mui.Typography variant="h4" sx={{ paddingRight: '6px' }}>
                  Photos Declined Today
                </Mui.Typography>
                <Mui.Divider orientation="vertical" flexItem />
                <Mui.Typography variant="h2" sx={{ paddingLeft: '6px' }}>
                  {_.isNil(data) ? '' : data?.getPhotoStatistics.declinedCount}
                </Mui.Typography>
              </Mui.CardContent>
            </Mui.Card>
          )}
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};

export default PhotoCounts;
