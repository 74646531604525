import { useUpdateContract } from 'gql/contract/mutations';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideModalDrawer from 'shared/components/ModalDrawer';
import ContractInputs from './ContractInputs';
import * as actions from '../../../duck/actions';
import { showToast } from 'shared/components/Toast';
import cast from 'shared/util/cast';
import { omitTypename } from 'shared/util/object';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { useGetClassesForCenter } from 'gql/class/queries';
import {
  ContractValidationError,
  isFeeScheduleInvalidWithStartDate,
  isValidContract,
} from './helpers/ContractValidation';
import { isBlank } from 'shared/util/string';
import { isNil } from 'lodash';
import { RootState } from 'store/reducers';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import Alert from 'shared/components/Alert';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  contract: IContract;
  existingContracts: IContract[];
  activeChildSubsidyEnrollments?: IAgencyAccountChildEnrollment[];
}

const UpdateContractModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  contract: savedContract,
  existingContracts,
  activeChildSubsidyEnrollments,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contract, updateContract] = useState<ICreateContractInput>(cast<ICreateContractInput>({ ...savedContract }));
  const accountData: IAccount = useSelector((state: RootState) => state.accounts.byId[contract.accountId]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [updateContractFn, { loading }] = useUpdateContract({
    onCompleted: (data) => {
      dispatch(actions.updateContract(data.updateContract));
      showToast('Contract updated successfully.', 'success');
      onClose();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  // queries and data
  const { data: getClassesForCenterData, loading: classesLoading } = useGetClassesForCenter({
    variables: { centerId: contract.centerId },
    fetchPolicy: 'network-only',
  });

  const availableClasses = getClassesForCenterData?.getClassesForCenter.filter((c) => !c.archivedAt) ?? [];
  const selectedClass = availableClasses.find((c) => c.id === contract.classId);
  const availableFees = selectedClass ? selectedClass.fees.filter((f) => !f.deactivatedAt) : [];
  const selectedFee = selectedClass?.fees.find((f) => f.id === contract.permanentFeeId);
  const isContractStartDateFeeScheduleValid =
    !isBlank(contract?.startDate) &&
    !isNil(selectedFee) &&
    !isFeeScheduleInvalidWithStartDate(contract.startDate, selectedFee);

  const childWithSubsidyHasSelectedFlatRateFee =
    (savedContract.associatedSubsidies ?? []).length > 0 && !isNil(selectedFee) && selectedFee?.feeType === 'FLAT_RATE';

  const [isUsingStandardFee, setIsUsingStandardFee] = useState<boolean>(contract.originalFeeId ? true : false);
  const validationResults = isValidContract(
    contract,
    accountData,
    activeChildSubsidyEnrollments,
    selectedFee,
    isUsingStandardFee
  );
  const isBillingCycleMissing = validationResults.validationErrors.some(
    (e) => e.validationError === ContractValidationError.MissingBillingCycle
  );

  const handleSave = useCallback(() => {
    // If you change this please change UpdateContractWithReasonModal.tsx
    const contractInput: IUpdateContractInput = {
      accountId: contract.accountId,
      adjustmentId: contract.adjustmentId,
      casualFeeId: contract.casualFeeId,
      centerId: contract.centerId,
      classId: contract.classId,
      cycleType: contract.cycleType,
      endDate: contract.endDate,
      id: savedContract.id,
      originalFeeId: contract.originalFeeId,
      permanentFeeId: contract.permanentFeeId,
      reasonForEnding: contract.reasonForEnding,
      startDate: contract.startDate,
      timeslots: contract.timeslots,
      updateType: 'UPDATE_EXISTING',
    };

    updateContractFn({
      variables: {
        input: cast<IUpdateContractInput>(omitTypename(contractInput, true)),
      },
    });
  }, [contract, updateContractFn]);

  const getStandardFeeState = (checked: boolean): void => {
    setIsUsingStandardFee(checked);
  };

  return (
    <div>
      <SideModalDrawer
        title="Update Contract"
        show={isOpen}
        onHide={onClose}
        dialogClassName=""
        closeOnPrimaryCallback={false}
        primaryChoice="Save"
        primaryCallback={() => setShowConfirmationModal(true)}
        primaryButtonProps={{ disabled: !validationResults.isValid }}
        secondaryChoice="Cancel"
        secondaryCallback={onClose}
        enforceFocus={false}
      >
        {isBillingCycleMissing && (
          <Alert variant="warning" className="shadow-none mb-4">
            {t('enrollment.contracts.update-modal.missing-billing-cycle-error')}
          </Alert>
        )}
        <ContractInputs
          contract={contract}
          updateContract={updateContract}
          existingContracts={existingContracts}
          availableClasses={availableClasses}
          selectedClass={selectedClass}
          availbleFees={availableFees}
          isLoadingClasses={classesLoading}
          isContractStartDateFeeScheduleValid={isContractStartDateFeeScheduleValid}
          selectedFee={selectedFee}
          getStandardFeeState={getStandardFeeState}
          contractValidationResults={validationResults}
        />
      </SideModalDrawer>
      <ConfirmationModal
        title="Confirm Changes"
        show={showConfirmationModal}
        onHide={() => {
          setShowConfirmationModal(false);
          onClose();
        }}
        primaryChoice="Confirm"
        primaryCallback={handleSave}
        primaryButtonProps={{ variant: 'danger', loading }}
        hideOnCallback={false}
      >
        Are you sure you want to make these changes to the existing contract? Once confirmed, this action cannot be
        undone.
      </ConfirmationModal>
    </div>
  );
};

export default UpdateContractModal;
