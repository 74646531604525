import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

interface IGetPositionsForBusinessVariables {
  businessId: string;
}

interface IGetPositionsForBusinessData {
  getPositionsByBusinessId: IPosition[];
}

export const GET_POSITIONS_FOR_BUSINESS = gql`
  query ($businessId: ID!) {
    getPositionsByBusinessId(businessId: $businessId) {
      id
      name
      businessId
    }
  }
`;

export const useGetPositionsForBusiness = (businessId: string) =>
  useQuery<IGetPositionsForBusinessData, IGetPositionsForBusinessVariables>(GET_POSITIONS_FOR_BUSINESS, {
    variables: {
      businessId: businessId,
    },
    skip: !businessId || businessId === '',
  });
