import React, { useCallback, useContext, useMemo, useState } from 'react';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import moment, { Moment } from 'moment';
import colors from '_colors.module.scss';
import { Col, Row } from 'shared/components/Layout';
import Button, { ButtonAsLink, CreateButton, IconButton } from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';
import { capitalize, Dictionary, groupBy } from 'lodash';
import LeaveDaysDetailModel from './LeaveDaysDetailModel/LeaveDaysDetailModel';
import Select from 'shared/components/Select';
import { OptionsType } from 'react-select';
import DateInput from 'shared/components/DateInput';
import { useCreateProgramLeaveDay } from 'gql/programLeaveDay/mutation';
import { ProgramContext, ProgramContextShape } from '../Program';
import { showToast } from '../../../../../shared/components/Toast';
import { RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { DefaultCountry } from 'shared/components/DateInput/countryCodes';
import './programLeaveDay.scss';
import { getClosureTypeLabel } from 'pages/Subsidies/KindyFunding/utils';

export type ProgramLeaveDayComponentProps = {
  leaveDays: IProgramLeaveDay[];
  refetch: () => void;
};

export interface QuarterStatsShape {
  totalDays: number;
  records: IProgramLeaveDay[];
}

export default function ProgramLeaveDayComponent({ leaveDays, refetch }: ProgramLeaveDayComponentProps) {
  const { t } = useTranslation(['translation', 'subsidies']);
  const programContext = useContext<ProgramContextShape>(ProgramContext);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>('startDate');
  const [newLeaveType, setNewLeaveType] = useState<LeaveType>();
  const [showLeaveDaysDetail, setShowLeaveDayDetail] = useState<boolean>(false);
  const [isStartDateValid, setStartDateValid] = useState<boolean>(true);
  const [isNewLeaveTypeValid, setNewLeaveTypeValid] = useState<boolean>(true);

  const leaveDayOptions: OptionsType<{ label: string; value: LeaveType }> = [
    { label: 'Unplanned', value: 'UNPLANNED' },
    { label: 'Planned', value: 'PLANNED' },
    { label: 'Public Holiday', value: 'PublicHoliday' },
    { label: 'Pupil Free / Planning', value: 'PupilFree' },
    { label: 'Emergency / Safety', value: 'Emergency' },
  ];

  const isDayHighlighted = useCallback((day: Moment) => {
    const find = leaveDays.find((value) => {
      const start = moment(value.startDate);
      const end = moment(value.endDate);
      return day.isBetween(start, end, 'day', '[]');
    });
    return find != null;
  }, []);

  const currentQuarter = useMemo<[Moment, Moment]>(() => {
    const today = moment();
    const thisQuarter = today.quarter();
    const startOfQuarterDate = today.startOf('quarter');
    startOfQuarterDate.day(1); // get the monday
    if (startOfQuarterDate.quarter() !== thisQuarter) {
      // if the monday is in last quarter, add 1 week today to get next monday of the quarter
      startOfQuarterDate.add(1, 'week');
    }

    const endOfQuarterDate = startOfQuarterDate.clone().add(13, 'week').subtract(1, 'day');
    const moments: [Moment, Moment] = [startOfQuarterDate, endOfQuarterDate];
    return moments;
  }, []);
  const [currentShownQuarter, setCurrentShownQuarter] = useState<[Moment, Moment]>(currentQuarter);

  const previousQuarter = useCallback(() => {
    const previousQuarterDate = currentShownQuarter[0].clone().subtract(7, 'day');
    const startOfQuarterDate = previousQuarterDate.clone().startOf('quarter');
    startOfQuarterDate.day(1); // get the monday
    if (startOfQuarterDate.quarter() !== previousQuarterDate.quarter()) {
      // if the monday is in last quarter, add 1 week today to get next monday of the quarter
      startOfQuarterDate.add(1, 'week');
    }

    const endOfQuarterDate = startOfQuarterDate.clone().add(13, 'week').subtract(1, 'day');
    const moments: [Moment, Moment] = [startOfQuarterDate, endOfQuarterDate];
    setCurrentShownQuarter(moments);
  }, [currentShownQuarter]);
  const nextQuarter = useCallback(() => {
    const nextQuarterDate = currentShownQuarter[1].clone().add(7, 'day');
    const startOfQuarterDate = nextQuarterDate.clone().startOf('quarter');
    startOfQuarterDate.day(1); // get the monday
    if (startOfQuarterDate.quarter() !== nextQuarterDate.quarter()) {
      // if the monday is in next quarter, add 1 week today to get previous monday of the quarter
      startOfQuarterDate.add(1, 'week');
    }

    const endOfQuarterDate = startOfQuarterDate.clone().add(13, 'week').subtract(1, 'day');
    const moments: [Moment, Moment] = [startOfQuarterDate, endOfQuarterDate];
    setCurrentShownQuarter(moments);
  }, [currentShownQuarter]);

  const currentQuarterData = useMemo<Dictionary<QuarterStatsShape>>(() => {
    const dictionary = groupBy<IProgramLeaveDay>(leaveDays, (o) => o.leaveType) as Record<
      LeaveType,
      IProgramLeaveDay[]
    >;
    const dict: Record<LeaveType, QuarterStatsShape> = {} as Record<LeaveType, QuarterStatsShape>;

    for (let key of Object.keys(dictionary)) {
      const dictionaryElements = dictionary[key as LeaveType];

      let numberOfDays = 0;
      const dayElements: IProgramLeaveDay[] = [];

      dictionaryElements.forEach((de) => {
        let dates: string[] = [];

        for (let m = moment(de.startDate); m.isSameOrBefore(de.endDate); m.add(1, 'days')) {
          dates.push(m.format('YYYY-MM-DD'));
        }

        dates.forEach((date) => {
          if (moment(date).isBetween(currentShownQuarter[0], currentShownQuarter[1], 'day', '[]')) numberOfDays++;
        });

        if (
          moment(de.startDate).isBetween(currentShownQuarter[0], currentShownQuarter[1], 'day', '[]') ||
          moment(de.endDate).isBetween(currentShownQuarter[0], currentShownQuarter[1], 'day', '[]')
        ) {
          dayElements.push(de);
        }
      });

      dict[key as LeaveType] = {
        totalDays: numberOfDays,
        records: dayElements,
      };
    }

    return dict;
  }, [currentShownQuarter, leaveDays]);

  const onFocusChange = useCallback((arg: FocusedInputShape | null) => {
    console.log(arg);
    setFocusedInput(arg ?? 'startDate');
  }, []);

  const [createProgramLeaveDayFn, { loading: isCreating }] = useCreateProgramLeaveDay();
  const onCreateNewLeaveDay = useCallback(async () => {
    if (startDate == null) {
      setStartDateValid(false);
      showToast(t('translation:core.field-required', { fieldName: 'startDate' }), 'error');
      return;
    } else {
      setStartDateValid(true);
    }

    if (newLeaveType == null) {
      setNewLeaveTypeValid(false);
      showToast(t('translation:core.field-required', { fieldName: 'leaveType' }), 'error');
      return;
    } else {
      setNewLeaveTypeValid(true);
    }
    const startDate1 = startDate!.format('YYYY-MM-DD');
    const iCreateProgramLeaveDayDataExecutionResult = await createProgramLeaveDayFn({
      variables: {
        input: {
          businessId: programContext.businessId!,
          data: [
            {
              startDate: startDate1,
              endDate: endDate?.format('YYYY-MM-DD') ?? startDate1,
              leaveType: newLeaveType!,
              programId: programContext.programId!,
            },
          ],
        },
      },
    });

    refetch();
  }, [startDate, endDate, newLeaveType]);

  return (
    <>
      <p>{t('subsidies:program.create-closure-day-instruction')}</p>
      <Row className="mt-8">
        <Col lg={6} md={12} className="leave-day-picker mb-4">
          <DayPickerRangeController
            // renderDayContents={(day: moment.Moment) => 1 }
            hideKeyboardShortcutsPanel={true}
            startDate={startDate} // momentPropTypes.momentObj or null,
            endDate={endDate} // momentPropTypes.momentObj or null,
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={onFocusChange} // PropTypes.func.isRequired,
            // initialVisibleMonth={() => currentShownQuarter[0]} // PropTypes.func or null,
            isDayHighlighted={isDayHighlighted}
          />
        </Col>
        <Col lg={1}> </Col>
        <Col xl={4} lg={12} className="program-leave-form">
          <DateInput
            required
            isValid={isStartDateValid}
            label={t('subsidies:program.start-date')}
            date={startDate?.format()}
            onDateSelect={(input) => setStartDate(moment(input))}
            className="kt-date-input-no-max-width"
            dateOnly
          />
          <DateInput
            label={t('subsidies:program.ends-on')}
            date={endDate?.format()}
            onDateSelect={(input) => setEndDate(moment(input))}
            className="kt-date-input-no-max-width"
            dateOnly
          />
          <Select
            label={t('subsidies:program.type')}
            className="mb-0 flex-grow-0"
            isInvalid={!isNewLeaveTypeValid}
            required
            options={leaveDayOptions}
            onChange={(o) => {
              setNewLeaveType(o.value);
            }}
          ></Select>
          <CreateButton
            className="mt-6"
            block
            onClick={onCreateNewLeaveDay}
            disabled={!(Boolean(newLeaveType) && Boolean(startDate))}
            permission={{
              permission: 'Base',
              level: RoleLevelType.Edit,
              area: 'Agency',
            }}
          >
            {t('subsidies:program.mark')}
          </CreateButton>
        </Col>
      </Row>
      <hr />
      <Row className="mt-6">
        <Col xl={12}>
          {Object.keys(currentQuarterData).length > 0 && (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0">{t('subsidies:program.closure-dates')}</h5>
              </div>
              <Row className="mt-8 mb-8">
                <Col className="kt-week-input d-flex justify-content-center">
                  <InputGroup className="flex-nowrap" style={{ width: 'unset' }}>
                    <InputGroup.Prepend>
                      <Button variant="light" onClick={previousQuarter}>
                        <FontAwesomeIcon icon={faChevronLeft} size="sm" color="gray" />
                      </Button>
                    </InputGroup.Prepend>
                    <div
                      style={{ width: 'unset' }}
                      className="form-control cursor-pointer d-flex align-items-center flex-grow-0"
                      role="button"
                      tabIndex={-1}
                    >
                      <FontAwesomeIcon icon={faCalendarAlt} size="lg" className="mr-3" />
                      {currentShownQuarter[0]?.format(DefaultCountry.dateFormat)} -{' '}
                      {currentShownQuarter[1]?.format(DefaultCountry.dateFormat)}
                    </div>
                    <InputGroup.Append>
                      <Button variant="light" onClick={nextQuarter}>
                        <FontAwesomeIcon icon={faChevronRight} size="sm" color="gray" />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <ul>
                      {Object.keys(currentQuarterData).map((o) => (
                        <li key={o}>
                          <Row>
                            <Col>{getClosureTypeLabel(o)}</Col>
                            <Col>{currentQuarterData[o as LeaveType].totalDays}</Col>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {Object.keys(currentQuarterData).length == 0 && (
            <>
              <Row>
                <Col>
                  <span>{t('subsidies:program.no-closure-days')}</span>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <ButtonAsLink
          className="text-primary"
          onClick={() => {
            setShowLeaveDayDetail(!showLeaveDaysDetail);
          }}
        >
          {t('subsidies:program.view-dates')}
        </ButtonAsLink>
      </div>

      <LeaveDaysDetailModel
        data={currentQuarterData}
        showLeaveDaysDetail={showLeaveDaysDetail}
        onHide={(isRefetch) => {
          setShowLeaveDayDetail(false);
          if (isRefetch) refetch();
        }}
      ></LeaveDaysDetailModel>
    </>
  );
}
