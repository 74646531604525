import React from 'react';
import BStrapToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

const ToggleButton = ({ options, label, disabled, ...props }) => {
  return (
    <div>
      {label && (
        <div>
          <label>{label}</label>
        </div>
      )}
      <ToggleButtonGroup {...props}>
        {options.map((option, index) => (
          <BStrapToggleButton
            variant="secondary"
            disabled={disabled}
            className="btn-toggle"
            key={index}
            value={option.value}
          >
            {option.label}
          </BStrapToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default ToggleButton;
