import React, { useState, useCallback, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';
import { debounce } from 'lodash';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import errorMessage from 'shared/constants/errorMessages';
import DateInput from 'shared/components/DateInput';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import moment from 'moment';
import { useAddChildToAccount, useAddExistingChildToExistingAccount } from 'gql/account/mutations';
import { showToast } from 'shared/components/Toast';
import CrnInput from 'shared/components/CrnInput';
import { isRegion } from 'shared/util/region';
import ChildContactsInputs from './Tabs/ContactsTab/ChildContactsInputs';
import { useGetChildContactPermissions } from 'gql/contact/queries';
import { useGetChildByIdLazy } from 'gql/child/queries';
import { getFullName, getInitials, isBlank, stringToHsl } from 'shared/util/string';
import Avatar from 'shared/components/Avatar';
import { useDispatch } from 'react-redux';
import { updateAccountSuccess } from '../duck/actions';
import { useTranslation } from 'react-i18next';

type AddChildToAccountTabs = 'new' | 'existing';

const newChild = {
  firstname: '',
  lastname: '',
  dob: '',
  ccssAttributes: {
    crn: '',
  },
};

interface IExistingChildFormShape {
  childId: string | null;
  child: IChild | null;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  account: IAccount;
}

const AddChildToAccountModal: React.FC<IProps> = ({ isOpen, onClose, account }) => {
  const isAuRegion = isRegion('AU');
  const { t } = useTranslation(['accounts']);
  const dispatch = useDispatch();
  const [activeTabKey, setActiveTabKey] = useState<AddChildToAccountTabs>('new');
  const [addChildToAccount, { loading: addChildToAccountLoading }] = useAddChildToAccount();
  const { data: getChildContactPermissionsData } = useGetChildContactPermissions();
  const [getChildByIdFn, { loading: getChildByIdLoading }] = useGetChildByIdLazy(
    {
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        setExistingChild((prev) => ({ ...prev, child: result.getChildById }));
      },
    },
    `id firstname lastname nickname fullName dob avatar { url }`
  );
  const [addExistingChildToExistingAccountFn, { loading: addExistingChildToExistingAccountLoading }] =
    useAddExistingChildToExistingAccount({
      onCompleted: (result) => {
        showToast(`Successfully added ${getFullName(existingChild.child)} to the ${account.name} account.`, 'success');
        dispatch(
          updateAccountSuccess({
            ...account,
            children: [...account.children, { ...existingChild.child, archivedAt: '' } as IAccountChild],
          })
        );
        handleClose();
      },
      onError: (error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      },
    });
  const permissionsOptions = getChildContactPermissionsData?.getChildContactRelationshipPermissions ?? [];
  const [child, updateChild] = useState(newChild);
  const defaultContacts =
    account.contacts
      ?.filter((c) => c.isPrimary)
      .map((c) => ({
        contactId: c.id,
        relationship: '',
        permissions: permissionsOptions.map((permission) => permission.value),
      })) ?? [];

  const [childContacts, setChildContacts] = useState<IAccountChildContactInput[]>(defaultContacts);
  const [existingChild, setExistingChild] = useState<IExistingChildFormShape>({ childId: null, child: null });

  useEffect(() => {
    if (account.contacts && account.contacts?.length > 0) {
      setChildContacts(
        account.contacts
          ?.filter((c) => c.isPrimary)
          .map((c) => ({
            contactId: c.id,
            relationship: '',
            permissions: permissionsOptions.map((permission) => permission.value),
          })) ?? []
      );
    }
  }, [account.contacts, permissionsOptions]);

  const handleChange = useCallback(
    (value: any, name: string) => {
      updateChild({ ...child, [name]: value });
    },
    [child]
  );

  const handleClose = useCallback(() => {
    onClose();
    updateChild(newChild);
    setActiveTabKey('new');
    setExistingChild({ childId: null, child: null });
    setChildContacts([]);
  }, [onClose]);

  const handleAddNewChild = useCallback(() => {
    const { ccssAttributes, ...rest } = { ...child };

    addChildToAccount({
      variables: {
        input: {
          accountId: account.id,
          child: {
            ...rest,
            entityId: account.entityId,
            centerId: account.centerId,
            familyId: account.familyId,
            childCcssAttributes: isAuRegion ? ccssAttributes : undefined,
          },
          childContacts: childContacts.map((contact) => ({
            contactId: contact.contactId,
            permissions: contact.permissions,
            relationship: contact.relationship,
          })),
        },
      },
    })
      .then(() => {
        showToast('Child added successfully.', 'success');
        handleClose();
      })
      .catch((error) => {
        const content = error.graphQLErrors
          ? `${error.graphQLErrors
              .map((err) => {
                return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
              })
              .join(', ')}`
          : 'There was an error adding the child . Please try again later.';
        showToast(content, 'error');
      });
  }, [
    account.centerId,
    account.entityId,
    account.familyId,
    account.id,
    addChildToAccount,
    child,
    handleClose,
    childContacts,
  ]);

  const handleAddExistingChild = useCallback(() => {
    if (existingChild.child && existingChild.childId) {
      addExistingChildToExistingAccountFn({
        variables: {
          input: {
            accountId: account.id,
            childId: existingChild.childId.trim(),
            relationships: childContacts.map((contact) => ({
              contactId: contact.contactId,
              permissions: contact.permissions,
              relationship: contact.relationship,
            })),
          },
        },
      });
    }
  }, [existingChild, childContacts, account, addExistingChildToExistingAccountFn]);

  const debouncedChildLookup = useCallback(
    debounce((childId: string) => {
      !isBlank(childId) && getChildByIdFn({ variables: { id: childId.trim() } });
    }, 250),
    [getChildByIdFn]
  );

  const handleChildIdInput = useCallback(
    (childId: string) => {
      setExistingChild((prev) => ({ ...prev, childId, child: null }));

      debouncedChildLookup(childId);
    },
    [debouncedChildLookup]
  );

  const isFormDisabled = useCallback(() => {
    return activeTabKey === 'new'
      ? !child.firstname ||
          !child.lastname ||
          !moment(child.dob).isValid() ||
          !childContacts.every((contact) => !isBlank(contact.relationship))
      : !existingChild.child ||
          !existingChild.childId ||
          childContacts.some((contact) => isBlank(contact.relationship));
  }, [child, existingChild, childContacts, activeTabKey]);

  return (
    <SideModalDrawer
      title="Add Child"
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Add Child"
      primaryCallback={() => (activeTabKey === 'new' ? handleAddNewChild() : handleAddExistingChild())}
      primaryButtonProps={{
        disabled: isFormDisabled(),
        loading: addChildToAccountLoading || addExistingChildToExistingAccountLoading,
      }}
      closeOnPrimaryCallback={false}
      className="wide-side-modal"
    >
      <Tabs
        id="add-child-to-account-tabs"
        activeKey={activeTabKey}
        onSelect={(tab) => setActiveTabKey(tab as AddChildToAccountTabs)}
      >
        <Tab eventKey="new" title="New">
          <Row align="start">
            <Col>
              <TextInput
                required
                label="First Name"
                value={child.firstname}
                onChange={(value) => handleChange(value, 'firstname')}
                errorText={errorMessage.genericFirstName}
              />
            </Col>
            <Col>
              <TextInput
                required
                label="Last Name"
                value={child.lastname}
                onChange={(value) => handleChange(value, 'lastname')}
                errorText={errorMessage.genericLastName}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DateInput
                required
                label="Date of Birth"
                date={child.dob}
                onDateSelect={(value) => handleChange(value, 'dob')}
                dateOnly
              />
            </Col>
            <Col className="d-flex align-items-center">{getAgeStringFromDateOfBirth(moment(child.dob))}</Col>
          </Row>
          {isAuRegion && (
            <Row className="mt-4">
              <Col>
                <CrnInput
                  value={child.ccssAttributes.crn}
                  onChange={(crn) =>
                    updateChild((prev) => ({ ...prev, ccssAttributes: { ...prev.ccssAttributes, crn } }))
                  }
                  label="CRN"
                />
              </Col>
              <Col>{''}</Col>
            </Row>
          )}
        </Tab>
        <Tab eventKey="existing" title="Existing">
          <Row>
            <Col>
              <TextInput
                label="Child ID"
                value={existingChild.childId ?? ''}
                helperText="Hint: You can find the ID of a child in their profile"
                onChange={(value) => handleChildIdInput(value)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              {existingChild.childId && !existingChild.child && getChildByIdLoading && (
                <div className="d-flex align-items-center">
                  Searching for child...
                  <Spinner className="spinner-small ml-4" animation="border" role="status" variant="secondary" />
                </div>
              )}
              {existingChild.childId && !existingChild.child && !getChildByIdLoading && <div>No child found</div>}
              {existingChild.childId && existingChild.child && !getChildByIdLoading && (
                <div className="d-flex flex-row align-items-center">
                  <Avatar
                    color={stringToHsl(existingChild.child.id)}
                    size="sm"
                    image={existingChild.child.avatar?.url ?? ''}
                    initials={getInitials(existingChild.child)}
                  />
                  <div className="ml-2 text-truncate sm">{getFullName(existingChild.child)}</div>
                </div>
              )}
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <h5 className="mb-6 mt-6">{t('accounts:contacts.relationshipsAndPermissions')}</h5>
      <ChildContactsInputs
        contactOptions={account.contacts ?? []}
        childContacts={childContacts}
        updateChildContacts={setChildContacts}
      />
    </SideModalDrawer>
  );
};

export default AddChildToAccountModal;
