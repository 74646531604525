import React, { useState, useCallback } from 'react';
import { Col, Row } from 'shared/components/Layout';
import BusinessInformationForm from './BusinessInformationForm';
import Card from 'shared/components/Card';
import BusinessAccountOwner from './BusinessAccountOwner';
import TextInput from 'shared/components/TextInput';
import Button from 'shared/components/Buttons';
import { useUpdateEntityInviteCode } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import PersonCard from 'shared/components/Card/PersonCard';
import { ContactModal } from 'shared/components/ContactDisplayComponents';
import '../../businessProfile.scss';
import BusinessCentersCard from './BusinessCentersCard';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';

interface IProps {
  business: IBusiness;
  loading: boolean;
}

const ProfileTab: React.FC<IProps> = ({ business, loading = true, ...props }) => {
  const [businessContactModal, setBusinessContactModal] = useState({
    isOpen: false,
    name: '',
    role: '',
    email: '',
  });
  const [updateInviteFn, { loading: inviteLoading }] = useUpdateEntityInviteCode();
  // if a query is loading all data may not be laoded so render loaders (previous behavior)

  const showContactDetails = useCallback((person: IStaff) => {
    setBusinessContactModal({
      isOpen: true,
      name: `${person.firstname} ${person.lastname}`,
      role: person.role.name,
      email: person.email,
    });
  }, []);

  const dismissContactModal = useCallback(() => {
    setBusinessContactModal({
      isOpen: false,
      name: '',
      role: '',
      email: '',
    });
  }, []);

  const canEditBasePermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  return (
    <>
      <Row align="start">
        <Col>
          <Card header="Business Information" loading={loading} loadingLines={4}>
            {business && <BusinessInformationForm business={business} readOnly={!canEditBasePermission} />}
          </Card>
          <Card header="Business Code" loading={loading} loadingLines={4}>
            <p>
              This code is unique to your business and used to invite new administrators. It’s important to note that if
              you have invitations sent out, regenerating this code will void them.{' '}
            </p>
            <div className="d-flex justify-content-start align-items-end">
              <TextInput
                disabled={true}
                value={business?.inviteCode?.toUpperCase()}
                label="Code"
                className="mb-0 mr-4 xs-input flex-grow-0"
              />
              <HasRoleAreaLevel
                action={{ area: AreaType.Business, permission: PermissionType.Base, level: RoleLevelType.Edit }}
              >
                <Button
                  onClick={() =>
                    business &&
                    updateInviteFn({ variables: { id: business?.id } })
                      .then(() => {
                        showToast('Code updated successfully.', 'success');
                      })
                      .catch(() => {
                        showToast('There was an error updating your code. Please try again later.', 'error');
                      })
                  }
                  loading={inviteLoading}
                  variant="secondary"
                >
                  Generate New Code
                </Button>
              </HasRoleAreaLevel>
            </div>
          </Card>
        </Col>
        <Col>
          <HasRoleAreaLevel
            action={{ area: AreaType.Center, permission: PermissionType.Base, level: RoleLevelType.Read }}
          >
            <BusinessCentersCard business={business} loading={loading} />
          </HasRoleAreaLevel>
          <HasRoleAreaLevel
            action={{ area: AreaType.Business, permission: PermissionType.Base, level: RoleLevelType.Edit }}
          >
            <Card header="Account Owners" loading={loading} loadingLines={3}>
              {business && <BusinessAccountOwner business={business} />}
            </Card>
          </HasRoleAreaLevel>
          <div className="kt-business-contacts-grid">
            {business?.primaryContact && (
              <PersonCard
                className="kt-business-contacts-card"
                hasAdditionalActions={false}
                loading={loading}
                person={business.primaryContact}
                subtitle="Primary Contact"
                onClick={() => showContactDetails(business.primaryContact)}
              />
            )}
            {business?.secondaryContact && (
              <PersonCard
                className="kt-business-contacts-card"
                hasAdditionalActions={false}
                loading={loading}
                person={business.secondaryContact}
                subtitle="Secondary Contact"
                onClick={() => business.secondaryContact && showContactDetails(business.secondaryContact)}
              />
            )}
            {(business?.accountOwners ?? [])
              .filter(
                (person: IStaff) =>
                  person.id !== business?.primaryContact?.id &&
                  person.id !== business?.secondaryContact?.id &&
                  (person.employmentStatus === 'Active' || person.employmentStatus === 'Pending')
              )
              .map((person: IStaff, idx: number) => (
                <PersonCard
                  hasAdditionalActions={false}
                  className="kt-business-contacts-card"
                  key={`business-account-owner-${person.id}-${idx}`}
                  person={person}
                  onClick={() => showContactDetails(person)}
                />
              ))}
          </div>
        </Col>
      </Row>
      <ContactModal
        isOpen={businessContactModal.isOpen}
        onClose={dismissContactModal}
        name={businessContactModal.name}
        role={businessContactModal.role}
        email={businessContactModal.email}
      />
    </>
  );
};

export default ProfileTab;
