import {
  TimezoneActionTypes,
  CREATE_CENTER_TIMEZONE_HASH,
  ADD_CENTER_TO_TIMEZONE_HASH,
  CREATE_BUSINESS_TIMEZONE_HASH,
} from './types';

export const createCenterTimezoneHash = (centers: ICenter[]): TimezoneActionTypes => ({
  type: CREATE_CENTER_TIMEZONE_HASH,
  centers,
});

export const addCenterToTimezoneHash = (center: ICenter): TimezoneActionTypes => ({
  type: ADD_CENTER_TO_TIMEZONE_HASH,
  center,
});

export const createBusinessTimezoneHash = (businesses: IEntity[]): TimezoneActionTypes => ({
  type: CREATE_BUSINESS_TIMEZONE_HASH,
  businesses,
});
