import React, { useState, useEffect, useMemo, useCallback } from 'react';
import DataTable from 'shared/components/DataTable';
import Select from 'shared/components/Select';
import Container from 'react-bootstrap/Container';
import { chunk, startCase } from 'lodash';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { TableHeader, TableSearch } from 'shared/components/DataTable';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { sortBy, capitalize } from 'lodash';
import { isRegion } from 'shared/util/region';
import { tagFields } from 'gql/tag/fields';
import careTypes, { US_Care_Types } from 'shared/constants/dropdownOptions/careTypes';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import { useTranslation } from 'react-i18next';
import { useSearchCenters } from 'gql/center/queries';
import { useProgramsContext, IProgramCenter } from '../../context/ProgramsContext';
import applicationFeeTypes from 'shared/constants/dropdownOptions/applicationFeeTypes';
import { ApplicationFeeType } from 'shared/constants/enums/applicationFeeType';
import ApplicationFeeInput from './ApplicationFeeInput';
import { useGetCenterStatesInUse } from 'shared/hooks/useGetCenterStatesInUse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EnrollmentOptions } from 'generated/graphql';

const MINI_GQL_FIELDS = `
  id
  name
  tags {
    ${tagFields}
  }
  address {
    city
    state
  }
  classes{
    id
    name
    capacity
    careType
    fees{
      id
      name
    }
  }
`;

interface IFormattedData extends ICenter {
  programCenterId?: string;
  selectedClassId: string;
  selectedClassName: string;
  selectedFeeId: string;
  selectedFeeName: string;
  selectedApplicationFeeType?: ApplicationFeeType;
  selectedApplicationFeeTypeName?: string;
  applicationFeeAmount?: number;
  selectedEnrolmentOption: EnrollmentOptions;
  selectedCasualFeeId?: string;
  selectedCasualFeeName?: string;
}

interface ILinkToClassesTableProps {
  show: boolean;
}

const LinkToClassesTable: React.FC<ILinkToClassesTableProps> = ({ show }) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const { setProgramFormData, programFormData, activeProgram } = useProgramsContext();
  const [tableState, tableFunctions] = useDatatableState();
  const [formattedData, setFormattedData] = useState<IFormattedData[]>([]);
  const { loading, data } = useSearchCenters(
    {
      variables: {
        input: {
          filter: { term: { field: 'active', predicate: 'ACTIVE' } },
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          size: 10000,
          from: 0,
        },
      },
    },
    MINI_GQL_FIELDS
  );

  const statesInUse: string[] = useGetCenterStatesInUse()?.data?.getCenterStatesUsedAcrossEntity || [];
  const states =
    !!statesInUse && statesInUse.length > 0
      ? US_STATE_SELECT_OPTIONS.filter((state) => statesInUse.includes(state.value))
      : US_STATE_SELECT_OPTIONS;

  const stateOptions: ITableFilterOption[] = sortBy(states, ['label']).map((state) => ({
    label: state.label,
    value: state.value,
  }));

  const careTypeOptions = useMemo(() => (isRegion('US') ? US_Care_Types : careTypes), []);
  const feeTypeOptions = applicationFeeTypes;
  const enrollmentOptionsFilterOptions: ITableFilterOption[] = useMemo(() => {
    return Object.values(EnrollmentOptions).map((source) => {
      return { label: t(`enrollment:enrollment-options.${source as EnrollmentOptions}`), value: source };
    });
  }, []);

  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];
  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name']).map((tag) => ({
    label: tag.name,
    value: tag.id,
  }));

  const [searchTerm, setSearchTerm] = useState('');
  const [tagsFilters, setTagsFilters] = useState<ITableFilterOption[]>([]);
  const [stateFilters, setStateFilters] = useState<ITableFilterOption[]>([]);
  const [careTypesFilter, setCareTypeFilter] = useState<ITableFilterOption[]>([]);

  const filteredData = useMemo(() => {
    let result = formattedData;

    if (searchTerm) {
      result = result.filter((center) => {
        return center.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    if (stateFilters.length) {
      result = result.filter((center) => stateFilters.some((filter) => filter.value === center.address.state));
    }

    if (tagsFilters.length) {
      result = result.filter((center) => {
        return tagsFilters.some((filter) => {
          return center.tags.some((tag) => tag.id === filter.value);
        });
      });
    }

    if (careTypesFilter.length) {
      const selectedCareTypes = careTypesFilter.map((option) => option.value);
      result = result.filter((center) => {
        const matchedClasses = center.classes.filter((c) => selectedCareTypes.includes(c.careType ?? ''));
        return matchedClasses.length > 0;
      });
    }

    return result;
  }, [formattedData, searchTerm, careTypesFilter, stateFilters, tagsFilters]);

  const activePageData = useMemo(() => {
    return chunk(filteredData, tableState.pageSize)[tableState.activePage - 1] ?? [];
  }, [filteredData, tableState]);

  const nonSelectable = useMemo(
    () =>
      activePageData.filter((center) => !center.selectedClassId || !center.selectedFeeId).map((center) => center.id),
    [activePageData]
  );

  const handleSearchTermChange = useCallback(
    (v: string) => {
      setSearchTerm(v);
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [tableState.pageSize, tableFunctions]
  );

  const handleFiltersSelect = useCallback(
    (values: ITableFilterOption[], target: 'tags' | 'state' | 'careType') => {
      switch (target) {
        case 'tags':
          setTagsFilters(values);
          break;
        case 'state':
          setStateFilters(values);
          break;
        case 'careType':
          setCareTypeFilter(values);
          break;
        default:
          break;
      }
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [tableState.pageSize, tableFunctions]
  );

  const handleClassSelect = useCallback(
    (params: Omit<IProgramCenter, 'centerName'>) => {
      setFormattedData(
        formattedData.map((center) => {
          if (center.id === params.centerId) {
            return {
              ...center,
              selectedClassId: params.classId,
              selectedClassName: params.className,
              selectedFeeId: params.feeId,
              selectedFeeName: params.feeName,
              selectedEnrolmentOptions: params.enrollmentOptions,
              selectedCasualFeeId: params.casualFeeId,
              selectedCasualFeeName: params.casualFeeName,
            };
          }
          return center;
        })
      );
    },
    [formattedData]
  );

  const handleFeeSelect = useCallback(
    (centerId: string, feeId: string, feeName: string) => {
      setFormattedData(
        formattedData.map((center) => {
          if (center.id === centerId) {
            return {
              ...center,
              selectedFeeId: feeId,
              selectedFeeName: feeName,
            };
          }
          return center;
        })
      );
    },
    [formattedData]
  );

  const handleCasualFeeSelect = useCallback(
    (centerId: string, feeId: string, feeName: string) => {
      setFormattedData(
        formattedData.map((center) => {
          if (center.id === centerId) {
            return {
              ...center,
              selectedCasualFeeId: feeId,
              selectedCasualFeeName: feeName,
            };
          }
          return center;
        })
      );
    },
    [formattedData]
  );

  const handleApplicationFeeTypeSelect = useCallback(
    (centerId: string, applicationFeeType?: ApplicationFeeType, applicationFeeTypeName?: string) => {
      setFormattedData(
        formattedData.map((center) => {
          if (center.id === centerId) {
            return {
              ...center,
              selectedApplicationFeeType: applicationFeeType,
              selectedApplicationFeeTypeName: applicationFeeTypeName,
              applicationFeeAmount: applicationFeeType ? center.applicationFeeAmount : 0,
            };
          }
          return center;
        })
      );
    },
    [formattedData]
  );

  const handleApplicationFeeAmountChange = useCallback(
    (centerId: string, applicationFeeAmount?: number) => {
      setFormattedData(
        formattedData.map((center) => {
          if (center.id === centerId) {
            return {
              ...center,
              applicationFeeAmount,
            };
          }
          return center;
        })
      );
    },
    [formattedData]
  );

  const handleEnrollmentOptionsChange = useCallback((centerId: string, value: EnrollmentOptions) => {
    setFormattedData((prev) => {
      return prev.map((c) => ({
        ...c,
        selectedEnrolmentOption: centerId === c.id ? value : c.selectedEnrolmentOption,
      }));
    });
  }, []);

  const handleClearFilters = useCallback(() => {
    setSearchTerm('');
    setStateFilters([]);
    setTagsFilters([]);
    setCareTypeFilter([]);
    tableFunctions.changePage(1, tableState.pageSize);
  }, [tableFunctions, tableState.pageSize]);

  useEffect(() => {
    if (data) {
      // prepare table data
      const tableData = data.searchCenters.data.map((center) => {
        const programCenter = activeProgram?.programCenters?.find((c) => c.centerId === center.id);
        if (programCenter) {
          return {
            ...center,
            programCenterId: programCenter.id,
            selectedClassId: programCenter.classId ?? '',
            selectedClassName: programCenter.className ?? '',
            selectedFeeId: programCenter.feeId ?? '',
            selectedFeeName: programCenter.feeName ?? '',
            selectedApplicationFeeType: programCenter.applicationFeeType,
            applicationFeeAmount: programCenter.applicationFeeAmount,
            selectedEnrolmentOption: programCenter.enrollmentOptions,
            selectedCasualFeeId: programCenter.casualFeeId ?? center.classes[0]?.fees[0]?.id, // default to first available fee for the centre
            selectedCasualFeeName: programCenter.casualFeeName ?? center.classes[0]?.fees[0]?.name,
          };
        }
        return {
          ...center,
          selectedClassId: center.classes[0]?.id ?? '',
          selectedClassName: center.classes[0]?.name ?? '',
          selectedFeeId: center.classes[0]?.fees[0]?.id ?? '',
          selectedFeeName: center.classes[0]?.fees[0]?.name ?? '',
          selectedEnrolmentOption: EnrollmentOptions.PermanentOnly,
          selectedCasualFeeId: center.classes[0]?.fees[0]?.id ?? '',
          selectedCasualFeeName: center.classes[0]?.fees[0]?.name ?? '',
        };
      });

      // set table data
      setFormattedData(tableData);

      // when this component is used to edit a existing program,
      // need to initiate default value of selected rows
      if (activeProgram) {
        const { programCenters } = activeProgram ?? {};
        if (programCenters && programCenters?.length > 0) {
          tableFunctions.updateSelectedRows(
            programCenters?.map((center) => {
              const c = tableData.find((a) => a.id === center.centerId) ?? {};

              return {
                ...c,
                selectedClassId: center.classId,
                selectedClassName: center.className ?? '',
                selectedFeeId: center.feeId ?? '',
                selectedFeeName: center.feeName ?? '',
                selectedApplicationFeeType: center.applicationFeeType,
                applicationFeeAmount: center.applicationFeeAmount,
                selectedCasualFeeId: center.casualFeeId ?? '',
                selectedCasualFeeName: center.casualFeeName ?? '',
                selectedEnrolmentOption: center.enrollmentOptions,
              } as IFormattedData;
            })
          );
        }
      }
    }
  }, [data, activeProgram]);

  useEffect(() => {
    // data in selectedRows could be stale when class/fee selection is changed
    const programCenters = (tableState.selectedRows as IFormattedData[]).map((row) => {
      // find fresh data from formattedData as source of truth
      const center = formattedData.find((center) => center.id === row.id);
      return {
        centerId: center?.id ?? '',
        centerName: center?.name ?? '',
        classId: center?.selectedClassId ?? '',
        className: center?.selectedClassName ?? '',
        feeId: center?.selectedFeeId ?? '',
        feeName: center?.selectedFeeName ?? '',
        applicationFeeType: center?.selectedApplicationFeeType,
        applicationFeeAmount: center?.applicationFeeAmount,
        id: center?.programCenterId,
        enrollmentOptions: center?.selectedEnrolmentOption ?? EnrollmentOptions.PermanentOnly,
        casualFeeId: center?.selectedCasualFeeId,
        casualFeeName: center?.selectedCasualFeeName,
      };
    });

    setProgramFormData((prev) => ({
      ...prev,
      programCenters,
    }));
  }, [tableState.selectedRows, setProgramFormData, formattedData]);

  // closeMenuOnScroll is to avoid graphical issues relate to select menu on modal scroll.
  const closeMenuOnScroll = (e: Event) => {
    let result: boolean;
    try {
      // @ts-ignore
      result = e.target.className.includes('overflow-scroll modal-body');
    } catch (error) {
      result = false;
    }
    return result;
  };

  return (
    <Container
      fluid
      className={`${show ? 'd-block' : 'd-none'} kt-enrollment-programs-create-modal-link-to-classes-container`}
    >
      <DataTable
        noPadding
        showSelect={true}
        selectedRows={tableState.selectedRows}
        updateSelectedRows={tableFunctions.updateSelectedRows}
        showPagination={true}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        pageSize={tableState.pageSize}
        data={activePageData}
        dataSize={filteredData.length}
        showLoadingOverlay={loading}
        noDataText={t('translation:enrollment.programs.modal.no-data-text')}
        nonSelectable={nonSelectable}
        renderHeader={() => (
          <TableHeader className="justify-content-between px-0">
            <TableSearch
              className="max-width-200 m-0"
              onChange={handleSearchTermChange}
              placeholder={`${capitalize(t('translation:spelling.search'))} ${capitalize(
                t('translation:spelling.center')
              )}`}
              value={searchTerm}
              enableDebounce={false}
            />
            <div className="d-flex flex-direction-row align-items-center">
              <DropdownFilter
                title={t('translation:general.care-types')}
                className="ml-2"
                selectedFilters={careTypesFilter}
                options={careTypeOptions}
                onFilterSelect={(v) => handleFiltersSelect(v, 'careType')}
                showToolTip={false}
              />
              <DropdownFilter
                title={t('translation:enrollment.programs.modal.state-filter-title')}
                className="ml-2"
                selectedFilters={stateFilters}
                options={stateOptions}
                onFilterSelect={(v) => handleFiltersSelect(v, 'state')}
              />
              <DropdownFilter
                title={t('translation:enrollment.programs.modal.tags-filter-title')}
                className="ml-2"
                selectedFilters={tagsFilters}
                options={tagOptions}
                onFilterSelect={(v) => handleFiltersSelect(v, 'tags')}
              />
              <IconButtonCircle
                icon={faTimes}
                onClick={handleClearFilters}
                tooltipDirection="bottom"
                tooltipText={t('translation:enrollment.programs.modal.clear-filter-icon-tooltip')}
                className="mx-4 my-2"
              />
            </div>
          </TableHeader>
        )}
        columns={[
          {
            text: capitalize(t('translation:spelling.center')),
            dataField: 'name',
          },
          {
            text: capitalize(t('translation:spelling.class')),
            dataField: 'selectedClassId',
            classes: 'md-column',
            formatter: (cell: string, row: IFormattedData) => {
              const selectedClass = row.classes.find((c) => c.id === cell);
              const selectedOptionValue = selectedClass ? { label: selectedClass.name, value: selectedClass.id } : null;
              const selectedCareTypes = careTypesFilter.map((c) => c.value);
              const onChange = (v: ITableFilterOption) => {
                const cls = row.classes.find((c) => c.id === v.value);
                const fee = cls?.fees[0];

                handleClassSelect({
                  centerId: row.id,
                  classId: v.value,
                  className: v.label,
                  feeId: fee?.id ?? '',
                  feeName: fee?.name ?? '',
                  casualFeeId: row.selectedCasualFeeId,
                  casualFeeName: row.selectedCasualFeeName,
                  enrollmentOptions: row.selectedEnrolmentOption,
                });
              };

              return (
                <Select
                  options={row.classes.map((c) => ({
                    label: c.name,
                    value: c.id,
                    isDisabled: careTypesFilter.length > 0 ? !selectedCareTypes.includes(c.careType ?? '') : false,
                  }))}
                  onChange={onChange}
                  value={selectedOptionValue}
                  isMulti={false}
                  className="m-0"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                  closeMenuOnScroll={closeMenuOnScroll}
                />
              );
            },
          },
          {
            text: startCase(t('enrollment:enrollment-options-title')),
            dataField: 'selectedEnrolmentOption',
            classes: 'mid-column',
            formatter: (cell: number, row: IFormattedData) => {
              return (
                <Select
                  options={enrollmentOptionsFilterOptions}
                  onChange={(v) => handleEnrollmentOptionsChange(row.id, v.value)}
                  value={row.selectedEnrolmentOption as EnrollmentOptions}
                  isMulti={false}
                  className="m-0"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                  closeMenuOnScroll={true}
                />
              );
            },
          },
          {
            text: t('translation:enrollment.programs.application-fee-type'),
            dataField: 'selectedFeeType',
            classes: 'mid-column',
            formatter: (cell: string, row: IFormattedData) => {
              const selectedApplicationFeeType = feeTypeOptions.find((f) => f.value === row.selectedApplicationFeeType);
              return (
                <Select
                  options={feeTypeOptions}
                  onChange={(v) => handleApplicationFeeTypeSelect(row.id, v.value, v.label)}
                  value={selectedApplicationFeeType}
                  isMulti={false}
                  className="m-0"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                  closeMenuOnScroll={closeMenuOnScroll}
                />
              );
            },
          },
          {
            text: t('translation:enrollment.programs.application-fee'),
            dataField: 'applicationFeeAmount',
            classes: 'mid-column',
            formatter: (cell: number, row: IFormattedData) => {
              return (
                <ApplicationFeeInput
                  isRequired={!!row.selectedApplicationFeeType}
                  value={cell}
                  setValue={(v) => handleApplicationFeeAmountChange(row.id, v)}
                  disabled={!row.selectedApplicationFeeType}
                />
              );
            },
          },
          {
            text: `Permanent ${capitalize(t('translation:spelling.fee'))}`,
            dataField: 'selectedFeeId',
            classes: 'mid-column',
            formatter: (cell: string, row: IFormattedData) => {
              const selectedClass = row.classes.find((c) => c.id === row.selectedClassId);
              const selectedFee = selectedClass?.fees.find((f) => f.id === row.selectedFeeId);
              const selectedOptionValue = selectedFee ? { label: selectedFee.name, value: selectedFee.id } : null;
              return (
                <Select
                  options={(selectedClass?.fees ?? []).map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  onChange={(v) => handleFeeSelect(row.id, v.value, v.label)}
                  value={selectedOptionValue}
                  isMulti={false}
                  className="m-0"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                  closeMenuOnScroll={closeMenuOnScroll}
                  disabled={row.selectedEnrolmentOption === EnrollmentOptions.CasualOnly}
                />
              );
            },
          },
          {
            text: `Casual ${capitalize(t('translation:spelling.fee'))}`,
            dataField: 'selectedCasualFeeId',
            classes: 'mid-column',
            formatter: (cell: string, row: IFormattedData) => {
              const selectedClass = row.classes.find((c) => c.id === row.selectedClassId);
              const selectedFee = selectedClass?.fees.find((f) => f.id === row.selectedCasualFeeId);
              const selectedOptionValue = selectedFee ? { label: selectedFee.name, value: selectedFee.id } : null;
              return (
                <Select
                  options={(selectedClass?.fees ?? []).map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  onChange={(v) => handleCasualFeeSelect(row.id, v.value, v.label)}
                  value={selectedOptionValue}
                  isMulti={false}
                  className="m-0"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                  closeMenuOnScroll={closeMenuOnScroll}
                  disabled={row.selectedEnrolmentOption === EnrollmentOptions.PermanentOnly}
                />
              );
            },
          },
        ]}
      />
    </Container>
  );
};

export default React.memo(LinkToClassesTable);
