import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import KangarootimeReducers from './reducers';
import KangarootimeSagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [];
// @ts-ignore
middlewares.push(sagaMiddleware);

// only add redux-logger in development
if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  middlewares.push(logger);
}

const store = createStore(KangarootimeReducers, undefined, composeWithDevTools(applyMiddleware(...middlewares)));

sagaMiddleware.run(KangarootimeSagas);

export default store;
