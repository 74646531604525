import { allergyWithDocumentsFields } from 'gql/allergy/fields';
import { immunizationFields } from 'gql/immunization/fields';
import { medicalConditionWithDocumentsFields } from 'gql/medicalCondition/fields';
import { restrictionWithDocumentsFields } from 'gql/restriction/fields';
import { isRegion } from 'shared/util/region';

const ccssAttributeFields = `
  ccssAttributes {
    crn
    isCrnEditable
  }
`;

export const childFields = `
  id
  firstname
  lastname
  nickname
  dob
  indigenousStatus
  gender
  grade
  avatar {
    url
  }
  residesWith {
    id
    firstname
    lastname
    nickname
    address {
      address1
      address2
      city
      state
      postalCode
      country
    }
  }
  contacts {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
    email
    primaryPhoneNumber {
      number
      type
    }
    address {
      address1
      address2
      city
      state
      postalCode
      country
    }
    relationshipType
    accountPermissions {
      accountId
      isPrimary
      permissions
      account {
        id
        name
        center {
          id
          name
        }
      }
    }

  }
  accounts {
    id
    name
    entityId
    centerId
    center {
      id
      name
    }
    children {
      id
      accountChildId
      archivedAt
    }
  }
  tags {
    id
    entityId
    name
    category
  }
  emergencyContacts {
    id
    childId
    contactId
    firstName
    lastName
    phoneNumber {
      number
      type
    }
    relationshipType
  }
  ${isRegion('AU') ? ccssAttributeFields : ''}
  mealStatus {
    mealPricing
  }
  restrictAccessToChildDocuments
`;

export const childWellnessDataFields = `
  id
  allergies {
    ${allergyWithDocumentsFields}
  }
  medicalConditions {
    ${medicalConditionWithDocumentsFields}
  }
  restrictions {
    ${restrictionWithDocumentsFields}
  }
`;

export const childImmunizationFields = `
  id
  immunizations {
    ${immunizationFields}
  }
`;
