export const payCodeFields: string = `
  id
  businessId
  centerId
  description
  code
  name
  createdAt
  createdBy
  updatedAt
  updatedBy
  archivedAt
  archivedBy
  trainingTimeEventType
  overtimeEligible
`;
