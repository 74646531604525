import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showToast } from 'shared/components/Toast';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { useDeleteBatch } from 'gql/batch/mutations';
import { deleteBatchSuccess } from '../duck/actions';

interface IProps {
  isOpen: boolean;
  batch: IBatch;
  onClose: () => void;
}

const UnbatchModal: React.FC<IProps> = ({ isOpen, batch, onClose, ...props }) => {
  const { t } = useTranslation(['billing']);
  const dispatch = useDispatch();

  const [unbatchFn, { loading: unbatchLoading }] = useDeleteBatch({
    onCompleted: (result) => {
      showToast(t('billing:transactions.unbatch-modal.success-toast-message'), 'success');
      dispatch(deleteBatchSuccess(result.deleteBatch.batch, result.deleteBatch.unbatchedPaymentTransactions));
      onClose();
    },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string'
              ? err.message
              : // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
                err.message?.message?.toString() ?? t('billing:transactions.unbatch-modal.error-toast-message');
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  return (
    <ConfirmationModal
      title={t('billing:transactions.unbatch-modal.title', { userFriendlyId: batch.userFriendlyId })}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice={t('billing:transactions.unbatch-modal.primary-button-text')}
      primaryCallback={() => unbatchFn({ variables: { id: batch.id } })}
      primaryButtonProps={{ variant: 'danger', loading: unbatchLoading }}
    >
      <p>
        {t('billing:transactions.unbatch-modal.unbatch-information-text', { userFriendlyId: batch.userFriendlyId })}
      </p>
      <p>{t('billing:transactions.unbatch-modal.unbatch-information-text-body')}</p>
    </ConfirmationModal>
  );
};

export default UnbatchModal;
