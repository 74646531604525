import { Bill } from '../types/Bill';
import { APIError, useBillApi } from './useBillingApi';

export function useGetBill(params: { billId: string }): {
  data?: Bill;
  error?: APIError;
  loading: boolean;
  refetch: () => void;
} {
  const toReturn = useBillApi('GET', `api/v2/billing-activity/bill/${params.billId}`);
  return toReturn;
}
