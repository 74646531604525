import React, { useCallback, useState } from 'react';
import Card from 'react-bootstrap/Card';
import TagRow from './TagRow';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { useCreateTag, useUpdateTag } from '../graphql/mutations';
import { LoadingLines, Header } from 'shared/components/LoadingSkeletons';
import { Form } from 'react-bootstrap';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IProps {
  type: IEnumDefinition;
  entityId: string;
  tags: ITag[];
  loading: boolean;
}

const TAG: IUpdateTag = {
  id: '',
};

const NEW_TAG = {
  name: '',
};

const DisplayTags: React.FC<IProps> = ({ type, tags, entityId, loading, ...props }) => {
  const [updateTag, { loading: updateLoading }] = useUpdateTag();
  const [updateFormData, setUpdateFormData] = useState(TAG);
  const [createTag, { loading: createLoading }] = useCreateTag();
  const [addFormData, setAddFormData] = useState(NEW_TAG);

  const [tagInEditMode, setTagInEditMode] = useState('');

  const enableEditModeForTag = useCallback((id: string) => setTagInEditMode(id), [setTagInEditMode]);

  const handleAddTag = useCallback(
    (e) => {
      e.preventDefault();
      entityId &&
        addFormData &&
        addFormData.name &&
        createTag({
          entityId,
          name: addFormData.name,
          category: type.value,
        });
      setAddFormData(NEW_TAG);
    },
    [createTag, entityId, addFormData, type]
  );

  const handleUpdateTag = useCallback(() => {
    entityId &&
      updateFormData &&
      updateFormData.name &&
      updateTag({
        id: tagInEditMode,
        entityId,
        name: updateFormData.name,
        category: type.value,
      });
    setUpdateFormData(TAG);
    setTagInEditMode('');
  }, [updateTag, entityId, updateFormData, type, tagInEditMode]);

  const handleChange = useCallback(
    (e) => {
      setAddFormData({
        ...addFormData,
        [e.target.name]: e.target.value,
      });
    },
    [setAddFormData, addFormData]
  );

  return (
    <Card className="h-100 display-tags mb-4">
      <h6 className="p-4 m-0 font-weight-bold">
        {loading || updateLoading || createLoading ? <Header /> : `${type.label} Tags`}
      </h6>
      <hr className="m-0 mb-4" />
      <div className="display-tags--tag-container px-4 mb-2 overflow-auto">
        {loading || updateLoading || createLoading ? (
          <LoadingLines />
        ) : (
          tags &&
          tags.map((t: ITag, idx: number) => {
            return (
              <TagRow
                key={t.id}
                tag={t}
                enableEditModeForTag={enableEditModeForTag}
                tagInEditMode={tagInEditMode}
                formData={updateFormData}
                setFormData={setUpdateFormData}
                baseId={`${type.label}-tag-${idx}`}
              />
            );
          })
        )}
      </div>
      {tagInEditMode === '' ? (
        <HasRoleAreaLevel
          action={{
            area: AreaType.Business,
            permission: PermissionType.Tags,
            level: RoleLevelType.Create,
          }}
        >
          <div className="mt-auto px-4">
            <Form onSubmit={handleAddTag}>
              <InputGroup className=" mb-4 diplay-tags--tag-input">
                <FormControl
                  name="name"
                  id={`${type.label}-tagName`}
                  autoComplete="off"
                  placeholder="Add Tag"
                  value={addFormData && addFormData.name}
                  onChange={handleChange}
                />
                <InputGroup.Prepend>
                  <Button
                    id={`${type.label}-tagName-btn`}
                    disabled={addFormData && !addFormData.name.length}
                    onClick={handleAddTag}
                    className="px-3"
                    variant="outline-secondary"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </InputGroup.Prepend>
              </InputGroup>
            </Form>
          </div>
        </HasRoleAreaLevel>
      ) : (
        <HasRoleAreaLevel
          action={{
            area: AreaType.Business,
            permission: PermissionType.Tags,
            level: RoleLevelType.Edit,
          }}
        >
          <div className="d-flex mb-3 display-tags--edit-btns px-4">
            <Button
              id="cancel-tag-btn"
              variant="outline-secondary"
              className="mr-1 btn-flex-fill"
              onClick={() => {
                setTagInEditMode('');
              }}
            >
              Cancel
            </Button>
            <Button id="save-tag-btn" variant="primary" className="ml-1 btn-flex-fill" onClick={handleUpdateTag}>
              Save
            </Button>
          </div>
        </HasRoleAreaLevel>
      )}
    </Card>
  );
};

export default DisplayTags;
