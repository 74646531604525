import React from 'react';
import { ToastContainer, ToastContainerProps, Slide } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';
import 'react-toastify/dist/ReactToastify.css'; // initialize react-toastify styles
import './_toast.scss'; // kt additional styling

interface ICloseButtonProps {
  closeToast?: () => void;
}

const CloseButton: React.FC<ICloseButtonProps> = ({ closeToast }) => (
  <FontAwesomeIcon icon={faTimes} color={colors.iconColor} onClick={closeToast} />
);

const Toast: React.FC<ToastContainerProps> = ({ ...props }) => {
  return <ToastContainer {...props} closeOnClick pauseOnHover closeButton={<CloseButton />} transition={Slide} />;
};

export default Toast;
