import React, { useEffect, useState } from 'react';
//components
import { ModalWindowCWA } from './ModalWindowCWA';
import { showToast } from 'shared/components/Toast';
import { TableHeader } from 'shared/components/DataTable';
import { IconButtonCircle } from 'shared/components/Buttons';
//utils
import { useTranslation } from 'react-i18next';
import { faEnvelope, faBan } from '@fortawesome/pro-solid-svg-icons';
import { useBulkCancelArrangements, useBulkSendEmail } from '../graphql/mutations';
import { GetComplyingWrittenArrangementPage } from '../graphql/queries';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { getMultiValueName } from '../common';

type SelectedRows = GetComplyingWrittenArrangementPage['getComplyingWrittenArrangementPage']['data'][number];

interface IProps {
  selectedRowItems: SelectedRows[];
  removeAllSelectedRows: () => void;
  entityId: string;
  refetch: () => void;
}

interface ModalData {
  title: string;
  content: string;
  submit: string;
  isOpen: boolean;
  type: string;
}

interface Icon {
  id: number;
  icon: IconDefinition;
  tooltipText: string;
  action: string;
}

export const SelectedItemsHeader: React.FC<IProps> = ({
  selectedRowItems,
  removeAllSelectedRows,
  entityId,
  refetch,
}) => {
  const { t } = useTranslation(['subsidies']);
  const [modal, setModal] = useState<ModalData>({
    title: '',
    content: '',
    submit: '',
    isOpen: false,
    type: '',
  });
  const [arrangementIds, setArrangementIds] = useState<string[]>([]);

  useEffect(() => {
    setArrangementIds(selectedRowItems?.map((i) => i.id) ?? []);
  }, [selectedRowItems]);

  const [bulkSend, { loading: loadingBulkSend }] = useBulkSendEmail({
    businessId: entityId,
    arrangementIds,
  });

  const [bulkCancel, { loading: loadingBulkCancel }] = useBulkCancelArrangements({
    businessId: entityId,
    arrangementIds,
  });

  const isArrayOnlyFromReadyToSend = selectedRowItems.some(
    (i) => i.status === 'Cancelled' || i.status === 'Rejected' || i.status === 'Pending' || i.status === 'Approved'
  );

  const iconButtons: Icon[] = [
    { id: 1, icon: faEnvelope, tooltipText: t('subsidies:cwa.tooltip.bulk-send-action'), action: 'send' },
    { id: 2, icon: faBan, tooltipText: t('subsidies:cwa.tooltip.bulk-cancel-arrangement'), action: 'cancel' },
  ];

  const settingModalWindow = (action) => {
    if (action === 'cancel') {
      setModal({
        title: t('subsidies:cwa.modal.title.bulk-cancel-arrangement'),
        content:
          selectedRowItems.length === 1
            ? t('subsidies:cwa.modal.text.bulk-cancel-arrangement-one')
            : t('subsidies:cwa.modal.text.bulk-cancel-arrangement-many', { numberOfChildren: selectedRowItems.length }),
        submit: getMultiValueName(t('subsidies:cwa.modal.submit.cancel-arrangement'), selectedRowItems.length),
        isOpen: true,
        type: 'cancel',
      });
    }
    if (action === 'send') {
      setModal({
        title: t('subsidies:cwa.modal.title.bulk-send-email'),
        content:
          selectedRowItems.length === 1
            ? t('subsidies:cwa.modal.text.bulk-send-email-one')
            : t('subsidies:cwa.modal.text.bulk-send-email-many', { numberOfChildren: selectedRowItems.length }),
        submit: getMultiValueName(t('subsidies:cwa.modal.submit.send-email'), selectedRowItems.length),
        isOpen: true,
        type: 'send',
      });
    }
  };

  const hideModal = () => {
    setModal({
      title: '',
      content: '',
      submit: '',
      isOpen: false,
      type: '',
    });
    removeAllSelectedRows();
  };

  const submitBulkModal = () => {
    if (modal.type === 'send') {
      bulkSend()
        .then(() => {
          !loadingBulkSend && showToast(t('subsidies:cwa.message-toasts.success.send-email'), 'success');
          hideModal();
        })
        .then(() => refetch())
        .catch(() => {
          !loadingBulkSend && showToast(t('subsidies:cwa.message-toasts.error.send-email'), 'error');
          hideModal();
        });
    }
    if (modal.type === 'cancel') {
      bulkCancel()
        .then(() => {
          !loadingBulkCancel && showToast(t('subsidies:cwa.message-toasts.success.cancel-arrangement'), 'success');
          hideModal();
        })
        .then(() => refetch())
        .catch(() => {
          !loadingBulkCancel && showToast(t('subsidies:cwa.message-toasts.error.cancel-arrangement'), 'error');
          hideModal();
        });
    }
  };

  return (
    <>
      <TableHeader className="px-1 mt-2 flex-wrap">
        {isArrayOnlyFromReadyToSend ? (
          <div>{t('subsidies:cwa.table-headers.different-statuses')}</div>
        ) : (
          <React.Fragment>
            {iconButtons.map((icon) => (
              <IconButtonCircle
                key={icon.id}
                id="icon-header-btn"
                iconSize="2x"
                icon={icon.icon}
                tooltipDirection="top"
                tooltipText={icon.tooltipText}
                onClick={() => settingModalWindow(icon.action)}
                className="mr-2"
              />
            ))}
          </React.Fragment>
        )}
      </TableHeader>
      <ModalWindowCWA modal={modal} hide={hideModal} submit={submitBulkModal} />
    </>
  );
};
