import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

export interface IGetLinkCodesVariables {
  businessId: string;
}

export interface ILinkGenerateLinkCodeVariables extends IGetLinkCodesVariables {
  name: string;
  centerIds: string[];
}

export interface IUpdateLinkCodeVariables extends IGetLinkCodesVariables {
  name: string;
  linkCode: string;
  centerIds: string[];
}

export interface IGetLinkCodesData {
  getLinkCodes: ILink[];
}

export interface IGenerateLinkCodeData {
  generateLinkCode: ILink;
}

export interface IDeleteLinkCodeData {
  deleteLinkCode: boolean;
}

export interface IDeleteLinkCodeVariables extends IGetLinkCodesVariables {
  linkCode: string;
}

export interface ILink {
  linkCode: string;
  businessId: string;
  name: string;
  associatedCenterIds?: string[];
}

export const GET_LINK_CODES = gql`
  query ($businessId: ID!) {
    getLinkCodes(businessId: $businessId) {
      linkCode
      name
      businessId
      associatedCenterIds
    }
  }
`;

export const useGetLinkCodes = (businessId: string) =>
  useQuery<IGetLinkCodesData, IGetLinkCodesVariables>(GET_LINK_CODES, {
    variables: { businessId },
    fetchPolicy: 'network-only',
    onCompleted: (data: IGetLinkCodesData) => {
      return [];
    },
  });
