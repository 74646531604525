import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniq } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import { ButtonAsLink, IconButton } from 'shared/components/Buttons';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import { NumberInput } from 'shared/components/TextInput';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import {
  feeStructureCanHaveVariableRates,
  getQualifyingNumberLabelForFeeStructure,
  getRateOptionsForFeeStructure,
} from '../helpers/FeeInputHelpers';
import TimeInput from '../../../../../shared/components/TimePicker/TimeInput';
import Form from 'react-bootstrap/Form';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  feeStructure: FeeStructure;
  feeSchedule: IFeeSchedule | IFeeScheduleInEdit;
  updateFeeSchedule: (fee: IFeeSchedule | IFeeScheduleInEdit) => void;
  isReadOnly: boolean;
}

const FeeScheduleInputs: React.FC<IProps> = ({ feeStructure, feeSchedule, updateFeeSchedule, isReadOnly }) => {
  const handleUpdateSchedule = useCallback(
    (value: any, key: string) => {
      updateFeeSchedule({ ...feeSchedule, [key]: value });
    },
    [feeSchedule, updateFeeSchedule]
  );

  const handleUpdateRate = useCallback(
    (rate: IFeeRate, index: number) => {
      handleUpdateSchedule(
        feeSchedule.rates.map((r, i) => (index === i ? rate : r)),
        'rates'
      );
    },
    [feeSchedule.rates, handleUpdateSchedule]
  );

  const handleRemoveRate = useCallback(
    (index: number) => {
      handleUpdateSchedule(
        feeSchedule.rates.filter((r, i) => index !== i),
        'rates'
      );
    },
    [feeSchedule.rates, handleUpdateSchedule]
  );

  const handleAddRate = useCallback(() => {
    handleUpdateSchedule([...feeSchedule.rates, { value: 0, qualifyingNumber: undefined }], 'rates');
  }, [feeSchedule.rates, handleUpdateSchedule]);

  const hasMultipleRates = feeStructureCanHaveVariableRates(feeStructure);

  // Dev Note Duplicate code from EditFeeModal and CreateFeeModal
  const hasDuplicateRates =
    hasMultipleRates &&
    feeSchedule.rates.filter((r) => r.qualifyingNumber > 0).length !==
      uniq(feeSchedule.rates.map((r) => r.qualifyingNumber).filter((r) => r > 0)).length;
  const quantifyingNumberLabel = getQualifyingNumberLabelForFeeStructure(feeStructure);
  const invalidTime =
    feeSchedule.endTime &&
    feeSchedule.startTime &&
    !moment(feeSchedule.endTime, 'HH:mm').isAfter(moment(feeSchedule.startTime, 'HH:mm'));
  const overTimeCostDetails = [feeSchedule.overtimeCost, feeSchedule.overtimeInterval, feeSchedule.overtimeGracePeriod];
  const validOverTimeCostDetails =
    overTimeCostDetails.every((ovt) => Boolean(ovt)) || overTimeCostDetails.every((ovt) => !Boolean(ovt));

  return (
    <Row align="start">
      <Col lg={6}>
        <Row className="max-width-200">
          <Col>
            <div className="d-flex flex-row">
              <Form.Label>Start Time</Form.Label>
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
            <TimeInput
              isAM={true}
              value={feeSchedule.startTime ?? null}
              onChange={(time) => {
                handleUpdateSchedule(time, 'startTime');
              }}
              disabled={isReadOnly}
            />
          </Col>
        </Row>
        <Row className="max-width-200">
          <Col>
            <div className="d-flex flex-row pt-3">
              <Form.Label>End Time</Form.Label>
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
            <TimeInput
              isAM={false}
              value={feeSchedule.endTime ?? null}
              onChange={(time) => {
                handleUpdateSchedule(time, 'endTime');
              }}
              disabled={isReadOnly}
            />
          </Col>
        </Row>
        {invalidTime && <div className="text-danger sm mt-n1 mb-2">End time must be after start time.</div>}
        <p className="sm font-weight-semi-bold mt-4 mb-2">Late pick-up / Early drop-off Fees</p>
        <Row align="end">
          <Col lg={12} xl={6}>
            <div className="d-flex align-items-center">
              <CurrencyInput
                className="border-radius-0"
                onChange={(value) => handleUpdateSchedule(value, 'overtimeCost')}
                value={feeSchedule.overtimeCost ?? null}
                label="Cost"
                disabled={isReadOnly}
              />
              <div className="ml-4 mt-4"> / </div>
            </div>
          </Col>
          <Col lg={12} xl={6}>
            <NumberInput
              onChange={(value) => handleUpdateSchedule(value, 'overtimeInterval')}
              value={feeSchedule.overtimeInterval}
              appendText="min"
              numberFormat={{ allowNegative: false }}
              disabled={isReadOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} xl={6}>
            <NumberInput
              label="Grace Period"
              onChange={(value) => handleUpdateSchedule(value, 'overtimeGracePeriod')}
              value={feeSchedule.overtimeGracePeriod}
              appendText="min"
              numberFormat={{ allowNegative: false }}
              disabled={isReadOnly}
            />
          </Col>
        </Row>
        {!validOverTimeCostDetails && (
          <div className="text-danger sm mt-n1 mb-2">
            Complete all 3 late pick-up / early drop-off fees fields to continue.
          </div>
        )}
      </Col>
      <Col justify="center">
        {feeSchedule.rates.map((rate, i) => (
          <Row key={i} className="flex-wrap">
            <Col md={12} lg={6}>
              <CurrencyInput
                className="mb-2 border-radius-0"
                label={i < 1 ? 'Cost' : undefined}
                value={rate.value ?? null}
                onChange={(value) => handleUpdateRate({ ...rate, value: value }, i)}
                disabled={isReadOnly}
              />
            </Col>
            {feeStructureCanHaveVariableRates(feeStructure) && (
              <Col md={8} lg={4}>
                <Select
                  label={i < 1 ? quantifyingNumberLabel + 's' : undefined}
                  value={rate.qualifyingNumber}
                  options={getRateOptionsForFeeStructure(feeStructure)}
                  onChange={(qualifyingNumber) => handleUpdateRate({ ...rate, qualifyingNumber }, i)}
                  className="mb-2"
                  disabled={isReadOnly}
                  placeholder="-"
                />
              </Col>
            )}
            {i > 0 && !isReadOnly && (
              <IconButton className="mb-2 ml-2" icon={faTrashAlt} onClick={() => handleRemoveRate(i)} />
            )}
          </Row>
        ))}
        {hasDuplicateRates && (
          <div className="text-danger sm mt-n1 mb-2">
            Multiple rates cannot exist for the same number of {quantifyingNumberLabel.toLocaleLowerCase() + 's'}.{' '}
          </div>
        )}
        {hasDuplicateRates && (
          <div className="text-danger sm mt-n1 mb-2">
            Multiple rates cannot exist for the same number of {quantifyingNumberLabel.toLocaleLowerCase() + 's'}.{' '}
          </div>
        )}
        {!isReadOnly &&
          ((feeStructure === 'NUMBER_OF_DAYS' && feeSchedule.rates.length < 7) ||
            (feeStructure === 'NUMBER_OF_SIBLINGS' && feeSchedule.rates.length < 10)) && (
            <ButtonAsLink onClick={handleAddRate}>
              {' '}
              <FontAwesomeIcon icon={faPlus} size="sm" className="mr-2" />
              Add another {quantifyingNumberLabel}
            </ButtonAsLink>
          )}
      </Col>
    </Row>
  );
};

export default FeeScheduleInputs;
