import React, { useCallback } from 'react';
import moment from 'moment';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import Select from 'shared/components/Select';
import ActionDropdown from 'shared/components/ActionDropdown';
import { IconButtonCircle } from 'shared/components/Buttons';
import ControlContainer from '../ControlContainer';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  calendarView: CalendarViewType;
  isSchedulePublished: boolean;
  changeCalendarView: (view: CalendarViewType) => void;
  onDateChange: (dates: { startDate: moment.Moment; endDate: moment.Moment }) => void;
  onCopySchedule: () => void;
  onPrintSchedule: () => void;
  onExportSchedule: () => void;
  onClearSchedule: () => void;
}

/**
 * This component houses components that control the visible timeframe of a schedule.
 * A schedule is usually viewed by week but can also be viewed on a day-by-day basis
 */
const TimeframeControls: React.FC<IProps> = ({
  startDate = moment().startOf('week'),
  endDate = moment().endOf('week'),
  calendarView = 'WEEK',
  isSchedulePublished = false,
  changeCalendarView,
  onDateChange,
  onCopySchedule,
  onPrintSchedule,
  onExportSchedule,
  onClearSchedule,
  ...props
}) => {
  const canCreateSchedule = useHasRoleAreaLevel({
    area: AreaType.Schedule,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });
  const canDeleteSchedule = useHasRoleAreaLevel({
    area: AreaType.Schedule,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });

  const handleDateChange = useCallback(
    (dates: { startDate: moment.Moment; endDate: moment.Moment }) => {
      let startDate = dates.startDate;
      let endDate = dates.endDate;

      if (calendarView === 'DAY') {
        // skip over weekends since we don't allow scheduling on the weekends
        if (startDate.day() === 0) {
          // skip to friday, substract 1 week
          startDate = startDate.clone().startOf('week').add(-1, 'week').day(5);
        } else if (startDate.day() === 6) {
          // skip to monday, add 1 week
          startDate = startDate.clone().startOf('week').add(1, 'week').day(1);
        }
      }

      onDateChange({ startDate, endDate });
    },
    [onDateChange, calendarView]
  );

  return (
    <ControlContainer className="kt-schedules-timeframe-control-container mb-2">
      <div className="kt-schedules-timeframe-control-grid">
        <div className="kt-schedules-timeframe-control-label">
          <h5>Schedule</h5>
        </div>
        <div className="kt-schedules-timeframe-control-calendar-control mx-sm-auto">
          <WeekPicker
            displayFormat="MMM D"
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            reactDatesController={calendarView === 'DAY' ? 'SINGLE' : 'RANGE'}
          />
          <Select
            className="ml-sm-2 kt-schedules-timeframe-control-calendar-control-type"
            value={calendarView}
            options={[
              { label: 'Day', value: 'DAY' },
              { label: 'Week', value: 'WEEK' },
            ]}
            onChange={(option) => changeCalendarView(option.value)}
          />
        </div>
        <div className="kt-schedules-timeframe-control-actions">
          <ActionDropdown
            actions={[
              ...(canCreateSchedule
                ? [
                    {
                      label: 'Copy',
                      onClick: onCopySchedule,
                    },
                  ]
                : []),
              ...(canDeleteSchedule
                ? [
                    {
                      label: 'Clear',
                      disabled: isSchedulePublished,
                      onClick: onClearSchedule,
                    },
                  ]
                : []),
              {
                label: 'Print',
                onClick: onPrintSchedule,
              },
              {
                label: 'Export',
                onClick: onExportSchedule,
              },
            ]}
            customIconButton={
              <IconButtonCircle
                className="rounded-circle circle-md bg-pale-grey"
                icon={faEllipsisV}
                backgroundColor="transparent"
              />
            }
          />
        </div>
      </div>
    </ControlContainer>
  );
};

export default TimeframeControls;
