import React, { useCallback, useMemo, useState } from 'react';
//components
import NotifiableServiceEventsContainer from '../NotifiableServiceEventsContainer';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import Checkbox from 'shared/components/Checkbox';
import TextInput from 'shared/components/TextInput';
import PhoneInput from 'shared/components/PhoneInput';
import { showToast } from 'shared/components/Toast';
//utils
import { useStopOperatingService } from '../../graphql/mutations';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../store/reducers';
import moment from 'moment';

interface IProps {
  businessId: string;
}

interface IStopOperatingSale {
  providerName: string;
  contactName?: string;
  email?: string;
  phoneNumber: string;
  mobileNumber?: string;
  expectedDate: string;
}

interface IProviderStopOperatingInput {
  date: string;
  areParentsNotified: boolean;
  isClosureSale: boolean;
  notifyDept: boolean;
  service: any;
  sale?: IStopOperatingSale | null;
}

export const ProviderStopOperatingService: React.FC<IProps> = ({ businessId }) => {
  const emptyInputs = {
    date: '',
    areParentsNotified: false,
    isClosureSale: false,
    notifyDept: false,
    service: undefined,
  };
  const emptySaleInputs = {
    providerName: '',
    contactName: '',
    email: '',
    phoneNumber: '',
    mobileNumber: '',
    expectedDate: '',
  };

  const { t } = useTranslation('business');
  const [formData, setFormData] = useState<IProviderStopOperatingInput>(emptyInputs);
  const [formSaleData, setFormSaleData] = useState<IStopOperatingSale>(emptySaleInputs);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [stopOperatingService, { loading: stopOperatingLoading }] = useStopOperatingService();

  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();

  const todayDate = moment().tz(timezone);
  const dayAfterYear = moment().tz(timezone).add(1, 'years').calendar();

  const isFormValid = useMemo(() => !!formData.date && !!formData.service && !!formData.service.value, [formData]);

  const isFormWithSaleValid = useMemo(
    () => !!formSaleData.providerName && !!formSaleData.phoneNumber && !!formSaleData.expectedDate,
    [formSaleData]
  );

  const handleSave = useCallback(() => {
    const saleForm = formData.isClosureSale
      ? {
          providerName: formSaleData.providerName,
          contactName: formSaleData.contactName,
          email: formSaleData.email,
          phoneNumber: formSaleData.phoneNumber.replace('+61', '0'),
          mobileNumber: formSaleData.mobileNumber && formSaleData.mobileNumber.replace('+61', '0'),
          expectedDate: formSaleData.expectedDate,
        }
      : null;
    stopOperatingService({
      variables: {
        input: {
          businessId,
          serviceId: formData.service.value,
          expectedClosureDate: formData.date,
          isParentsNotified: formData.areParentsNotified,
          isSale: formData.isClosureSale,
          notifyDept: formData.notifyDept,
          sale: saleForm,
        },
      },
    })
      .then(() => {
        showToast(t('ccs-management.provider-stops-operating-service.message-toast.success'), 'success');
      })
      .catch((err) => {
        err = err.toString().replace('Error: GraphQL error:', '').trim();
        const errorMessage = !!err ? err : t('ccs-management.message-error');
        showToast(errorMessage, 'error');
      });
  }, [stopOperatingService, formData, formSaleData]);

  return (
    <NotifiableServiceEventsContainer
      title={t('ccs-management.provider-stops-operating-service.title')}
      notificationMessage={t('ccs-management.provider-stops-operating-service.notification')}
      onCancel={() => setFormData(emptyInputs)}
      onSave={handleSave}
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      saveDisabled={formData.isClosureSale ? !isFormValid : !isFormWithSaleValid && !isFormValid}
      isSaving={stopOperatingLoading}
      serviceOnChange={(option) => {
        setFormData({ ...formData, service: option });
        setFormIsDirty(true);
      }}
      selectedService={formData.service?.value}
      child={
        <>
          <Row>
            <Col className="mb-2">
              <Row>
                <Col>
                  <DateInput
                    required
                    label={t('ccs-management.provider-stops-operating-service.labels.date')}
                    date={formData.date}
                    isOutsideRange={(date: moment.Moment) =>
                      date.isBefore(moment(todayDate).startOf('day')) || date.isAfter(moment(dayAfterYear).endOf('day'))
                    }
                    onDateSelect={(date: string) => setFormData({ ...formData, date: date })}
                    disabled={false}
                  />
                </Col>
              </Row>
            </Col>
            <Col>{''}</Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <Checkbox
                label={t('ccs-management.provider-stops-operating-service.labels.are-parents-notified')}
                value={formData.areParentsNotified}
                onChange={(value: boolean) => setFormData({ ...formData, areParentsNotified: value })}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <Checkbox
                label={t('ccs-management.provider-stops-operating-service.labels.notify-dep')}
                value={formData.notifyDept}
                onChange={(value: boolean) => setFormData({ ...formData, notifyDept: value })}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <Checkbox
                label={t('ccs-management.provider-stops-operating-service.labels.is-sale')}
                value={formData.isClosureSale}
                onChange={(value: boolean) => setFormData({ ...formData, isClosureSale: value })}
              />
            </Col>
            <Col>{''}</Col>
          </Row>
          {!!formData.isClosureSale && (
            <>
              <Row>
                <Col className="mb-2">
                  <TextInput
                    required
                    label={t('ccs-management.provider-stops-operating-service.labels.name-provider')}
                    name="providerName"
                    value={formSaleData.providerName}
                    onChange={(value: string) => setFormSaleData({ ...formSaleData, providerName: value })}
                    disabled={false}
                  />
                </Col>
                <Col>{''}</Col>
              </Row>
              <Row>
                <Col className="mb-2">
                  <TextInput
                    required={false}
                    label={t('ccs-management.provider-stops-operating-service.labels.contact-name')}
                    name="contactPerson"
                    value={formSaleData.contactName}
                    onChange={(value: string) => setFormSaleData({ ...formSaleData, contactName: value })}
                    disabled={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-2">
                  <PhoneInput
                    required
                    label={t('ccs-management.provider-stops-operating-service.labels.contact-phone')}
                    name="phoneNumber"
                    value={formSaleData.phoneNumber}
                    onChange={(value: string) => setFormSaleData({ ...formSaleData, phoneNumber: value })}
                    disabled={false}
                    phoneNotMobileNumber={true}
                  />
                </Col>
                <Col>{''}</Col>
              </Row>
              <Row>
                <Col className="mb-2">
                  <PhoneInput
                    required={false}
                    label={t('ccs-management.provider-stops-operating-service.labels.contact-mobile')}
                    name="mobileNumber"
                    value={formSaleData.mobileNumber}
                    onChange={(value: string) => setFormSaleData({ ...formSaleData, mobileNumber: value })}
                    disabled={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-2">
                  <TextInput
                    required={false}
                    label={t('ccs-management.provider-stops-operating-service.labels.contact-email')}
                    name="email"
                    value={formSaleData.email}
                    onChange={(value: string) => setFormSaleData({ ...formSaleData, email: value })}
                    disabled={false}
                  />
                </Col>
                <Col>{''}</Col>
              </Row>
              <Row>
                <Col className="mb-2">
                  <Row>
                    <Col>
                      <DateInput
                        required
                        date={formSaleData.expectedDate}
                        label={t('ccs-management.provider-stops-operating-service.labels.anticipadet-date')}
                        onDateSelect={(date: string) => setFormSaleData({ ...formSaleData, expectedDate: date })}
                        disabled={false}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>{''}</Col>
              </Row>
            </>
          )}
        </>
      }
    />
  );
};
