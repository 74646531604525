import React, { useState } from 'react';

import ISInnovativeSolutionsDetailsTable from './ISInnovativeSolutionsDetailsTable';

import { Row, Col } from 'react-bootstrap';
import ISInnovativeSolutionsSecondDetailsTable from './ISInnovativeSolutionsSecondDetailsTable';
import ISInnovativeSolutionsDetailsDescription from './ISInnovativeSolutionsDetailsDescription';
import ISInnovativeShowCasesTable from './ISInnovativeShowCasesTable';
import { ISCaseTypeLabelEnum } from 'shared/constants/enums/isCaseEnums';
interface IProps {
  data: IISInnovativeCasesForCenters;
}

const ISInnovativeSolutionsDetailsRow: React.FC<IProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Row className="is-case-details-row">
        <Col lg={4} md={4} xs={12} className="case-details-table-col">
          <ISInnovativeSolutionsDetailsTable data={data} />
        </Col>
        <Col lg={4} md={4} xs={12} className="children-table-col">
          <ISInnovativeSolutionsSecondDetailsTable data={data} />
        </Col>
        <Col lg={4} md={4} xs={12} className="maximum-hours-container mt-md-4 mt-sm-4 mt-lg-0">
          <ISInnovativeSolutionsDetailsDescription data={data} />
        </Col>
      </Row>
      <hr />
      <div className="position-relative more-details-container font-weight-semi-bold">
        {
          <p className="more-details-link" onClick={() => setOpen(!open)}>
            {open ? 'Hide Claims' : 'Show Claims'}
          </p>
        }
      </div>
      <ISInnovativeShowCasesTable
        data={data.claims}
        open={open}
        type={ISCaseTypeLabelEnum[data.type as ISInnovativeCaseType]}
      />
    </>
  );
};

export default ISInnovativeSolutionsDetailsRow;
