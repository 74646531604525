import React, { useState, useCallback } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { isBlank } from 'shared/util/string';
import errorMessage from 'shared/constants/errorMessages';
import { useUpdateProvider } from 'pages/Businesses/subroutes/BusinessProfile/graphql/mutations';
import { useDispatch } from 'react-redux';
import { showToast } from 'shared/components/Toast';
import * as actions from 'pages/Businesses/subroutes/BusinessProfile/duck/actions';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  provider: IProvider;
}

const EditProviderModal: React.FC<IProps> = ({ isOpen, onClose, provider }) => {
  const [updatedProvider, setUpdateProvider] = useState<IUpdateProviderInput>({
    providerId: provider.id,
    businessId: provider.businessId,
    deviceName: provider.deviceName,
    communicationsPersonId: provider.communicationsPersonId,
    otac: '',
  });

  const handleUpdateProvider = useCallback(
    (value, name) => setUpdateProvider({ ...updatedProvider, [name]: value }),
    [updatedProvider]
  );

  const dispatch = useDispatch();
  const [updateProvider, { loading }] = useUpdateProvider({
    onCompleted: (data) => dispatch(actions.updateProvider(data.updateProvider)),
  });

  const handleClose = useCallback(() => {
    onClose();
    setUpdateProvider({
      providerId: provider.id,
      businessId: provider.businessId,
      deviceName: provider.deviceName,
      communicationsPersonId: provider.communicationsPersonId,
      otac: '',
    });
  }, []);

  const handleSave = useCallback(() => {
    updateProvider({
      variables: {
        input: {
          ...updatedProvider,
        },
      },
    })
      .then((updatedProvider) => {
        if (updatedProvider.data?.updateProvider) {
          showToast('Provider updated successfully.', 'success');
          handleClose();
        }
      })
      .catch((err) => {
        showToast('There was an error.', 'error');
      });
  }, [updateProvider, updatedProvider, handleClose]);

  const isFormValid: boolean =
    !isBlank(updatedProvider.communicationsPersonId) &&
    !isBlank(updatedProvider.deviceName) &&
    !isBlank(updatedProvider.otac);

  return (
    <SideModalDrawer
      title="Edit Provider"
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Update"
      primaryCallback={handleSave}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      closeOnPrimaryCallback={false}
      primaryButtonProps={{ disabled: !isFormValid, loading }}
      footerHelperText={!isFormValid ? errorMessage.formRequirements : ''}
    >
      <form>
        <Row>
          <Col>
            <TextInput required name="name" label="Provider Name" value={provider.name} disabled={true}></TextInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="providerId"
              label="Provider ID"
              value={provider.providerId}
              disabled={true}
            ></TextInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="organisationId"
              label="Organisation ID"
              value={provider.organisationId}
              disabled={true}
            ></TextInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="communicationsPersonId"
              label="Communications Person ID"
              value={updatedProvider.communicationsPersonId}
              onChange={handleUpdateProvider}
            ></TextInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="deviceName"
              label="Device Name"
              onChange={handleUpdateProvider}
              value={updatedProvider.deviceName}
              helperText="This field is case sensitive and should be exactly the same as the one used on the PRODA website"
            ></TextInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="otac"
              label="One-time Security Code"
              onChange={handleUpdateProvider}
              value={updatedProvider.otac ?? ''}
              helperText="This is also referred to as OTAC and you get this from the PRODA website"
            ></TextInput>
          </Col>
        </Row>
      </form>
    </SideModalDrawer>
  );
};

export default EditProviderModal;
