import React from 'react';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import i18n from 'i18n';
import { capitalize, isEmpty } from 'lodash';
import { LoadingLines } from 'shared/components/LoadingSkeletons';

import KisiPlacesTableRow from './KisiPlacesTableRow';
import { kisiPairedCenters } from 'gql/kisiIntegration/fields';

interface ISortBy {
  field: string;
  direction: SortDirection;
}

interface IProps {
  loading: boolean;
  page: number;
  setPage: (x: number) => void;
  size: number;
  totalCount: number;
  refetch: () => void;
  sort: ISortBy;
  setSort: (x: ISortBy) => void;
  listPairedPlaces: IKisiPairedCenter[];
}

type SortDirection = 'ASCENDING' | 'DESCENDING';

const columns = [
  {
    name: capitalize(i18n.t('translation:spelling.center_plural')),
    field: 'center',
    displayLabel: true,
    alignLeft: true,
    sortable: false,
  },
  { name: 'Kisi Place name', field: 'kisiPlaceName', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'Kisi Place ID', field: 'kisPlaceId', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'Kisi Group ID', field: 'kisShareId', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'Manage Locks', field: 'manageLocks', displayLabel: false, alignLeft: false, sortable: false },
];

const KisiPlacesTable: React.FC<IProps> = ({
  loading,
  page,
  setPage,
  size,
  totalCount,
  refetch,
  sort,
  setSort,
  listPairedPlaces,
  ...props
}) => {
  const [pageNumber, setPageNumber] = React.useState(0);

  const changeSort = (field: string) => {
    setSort({
      field: field,
      direction: sort.direction === 'ASCENDING' ? 'DESCENDING' : 'ASCENDING',
    });
  };

  React.useEffect(() => {
    console.log('listPairedPlaceslistPairedPlaces', listPairedPlaces);
  }, [listPairedPlaces]);

  return (
    <>
      {loading ? (
        <LoadingLines number={4} />
      ) : (
        <Mui.Box sx={{ width: '100%' }}>
          <Mui.Paper sx={{ mb: 2 }} elevation={0}>
            <Mui.TableContainer sx={{ overflow: 'visible' }}>
              <Mui.Table aria-labelledby="billing-transactions-table" style={{ width: '100%' }}>
                <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
                  {columns.map((column) => (
                    <Mui.TableCell key={column.name} align={column.alignLeft ? 'left' : 'center'} padding={'normal'}>
                      <Mui.Typography variant="tableHeadCells">
                        {column.displayLabel && column.name}
                        {column.sortable && (
                          <Mui.IconButton onClick={() => changeSort(column.field)}>
                            <FontAwesomeIcon
                              size="xs"
                              icon={
                                sort.field === column.field
                                  ? sort.direction === 'ASCENDING'
                                    ? faAngleDown
                                    : faAngleUp
                                  : faAngleDown
                              }
                            />
                          </Mui.IconButton>
                        )}
                      </Mui.Typography>
                    </Mui.TableCell>
                  ))}
                </Mui.TableHead>
                <Mui.TableBody>
                  {isEmpty(listPairedPlaces) ? (
                    <Mui.Box
                      sx={{ width: '100%', margin: 0 }}
                      display="flex"
                      bgcolor="white"
                      // alignItems="center"
                      // justifyContent="center"
                    >
                      <Mui.Typography variant="body1" padding={'16px'}>
                        Enter a valid Kisi Api Key to get list of Kisi Places
                      </Mui.Typography>
                    </Mui.Box>
                  ) : (
                    listPairedPlaces.map((place: IKisiPairedCenter, index: number) => (
                      <KisiPlacesTableRow
                        canViewBusinessLevelTransactionTypes={true}
                        key={place.kisiPlaceId}
                        index={index}
                        refetch={() => refetch()}
                        row={place}
                      />
                    ))
                  )}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
            {false && (
              <Mui.Box
                sx={{ width: '100%', margin: 0 }}
                display="flex"
                bgcolor="white"
                alignItems="center"
                justifyContent="center"
              >
                <Mui.Typography variant="body1" padding={'16px'}>
                  Oops. We don’t have any data for that. Try another search.
                </Mui.Typography>
              </Mui.Box>
            )}
          </Mui.Paper>
        </Mui.Box>
      )}
    </>
  );
};

export default KisiPlacesTable;
