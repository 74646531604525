import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'shared/components/DataTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import Button from 'shared/components/Buttons';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import Alert from 'shared/components/Alert';
import AgencyEnrollmentStatus from 'shared/Agency/AgencyEnrollments/AgencyEnrollmentStatus';
import { AgencyEnrollmentStatusType } from 'generated/graphql';

export interface EnrollmentTableRow {
  accountChildId: string;
  /**
   * this is typically the id used at the agency for this enrollment.
   */
  agencyEnrollmentId?: string;
  agencyName: string;
  avatarUrl?: string;
  childFullName: string;
  childInitials: string;
  /**
   * end date of the enrollment
   */
  endDate: string;
  /**
   * The id of the enrollment itself
   */
  enrollmentId: string;
  enrollmentStatus: string;
  expectedSubsidyAmount: string;
  isArchived: boolean;
  /**
   * any notes on the enrollment
   */
  notes: string;
  /**
   * Start date of the enrollment
   */
  startDate: string;
  subsidyPeriodType: string;
}

interface IProps {
  isLoading: boolean;
  data: EnrollmentTableRow[];
  dataSize?: number;
  onViewChildAttendance?: (accountChildId: string) => void;
  onArchiveEnrollment?: (enrollmentId: string) => void;
  onEditChildAgencyEnrollment?: (enrollmentId: string) => void;
}
const ChildSubsidyTable: React.FC<IProps> = ({
  data,
  isLoading,
  onArchiveEnrollment,
  onEditChildAgencyEnrollment,
  onViewChildAttendance,
}) => {
  const { t } = useTranslation(['subsidies', 'translation']);
  const [tableState, tableFunctions] = useDatatableState();

  const hasEditChildAgencyPermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.AgencyChild,
    level: RoleLevelType.Edit,
  });

  const deleteChildAgencyPermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.AgencyChild,
    level: RoleLevelType.Delete,
  });

  return (
    <DataTable
      noPadding
      data={data}
      dataSize={data.length}
      showSelect={false}
      showPagination={false}
      showLoadingOverlay={isLoading}
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      columns={[
        {
          //@ts-ignore
          text: t('subsidies:agencies.account-subsidy-table.child-column'),
          dataField: 'childLastName',
          sort: true,
          formatter: (cell: any, row: EnrollmentTableRow) => (
            <AvatarDataTableCell
              initials={`${row.childInitials}`}
              avatar={row.avatarUrl ?? ''}
              primaryText={row.childFullName}
              secondaryText={row.agencyEnrollmentId}
            />
          ),
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.subsidy-column'),
          dataField: 'agencyName',
          sort: true,
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.start-column'),
          dataField: 'startDate',
          sort: false,
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.end-column'),
          dataField: 'endDate',
          sort: false,
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.expected-column'),
          dataField: 'expectedSubsidyAmount',
          sort: false,
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.subsidy-period-column'),
          dataField: 'subsidyPeriodType',
          formatter: (cell: string) => {
            switch (cell ?? '') {
              case 'SESSION':
                return 'Session';
              case 'EVERY_TWO_WEEKS':
                return 'Every two weeks';
              case 'MONTHLY':
                return 'Monthly';
              case 'WEEKLY':
                return 'Weekly';
              default:
                return '';
            }
          },
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.status-column'),
          dataField: 'enrollmentStatus',
          sort: true,
          formatter: (cell: AgencyEnrollmentStatusType) => {
            return <AgencyEnrollmentStatus status={cell}></AgencyEnrollmentStatus>;
          },
        },
      ]}
      expandRow={(row: EnrollmentTableRow) => (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div>
                <label>Notes</label>
              </div>
              <div>{row.notes}</div>
            </div>
            <div>
              {onEditChildAgencyEnrollment && hasEditChildAgencyPermission && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    onEditChildAgencyEnrollment(row.enrollmentId);
                  }}
                  className="mr-4"
                >
                  {t('subsidies:agencies.account-subsidy-table.edit-subsidy-button')}
                </Button>
              )}
              {onViewChildAttendance && (
                <Button
                  variant="outline-primary"
                  onClick={() => onViewChildAttendance(row.accountChildId)}
                  className="mr-4"
                >
                  {t('subsidies:agencies.account-subsidy-table.view-child-attendance-button')}
                </Button>
              )}
              {onArchiveEnrollment && deleteChildAgencyPermission && (
                <Button variant="outline-danger" onClick={() => onArchiveEnrollment(row.enrollmentId)}>
                  {t('subsidies:agencies.account-subsidy-table.archive-assignment-button')}
                </Button>
              )}
            </div>
          </div>
          <div>
            {row.isArchived && (
              <Alert variant="info" className="mb-4 w-25">
                {t('subsidies:agencies.account-subsidy-table.archived-message')}
              </Alert>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default ChildSubsidyTable;
