import { useGetAllergyHistoryLazy } from 'gql/allergy/queries';
import { useGetMedicalConditionHistoryLazy } from 'gql/medicalCondition/queries';
import { useGetRestrictionHistoryLazy } from 'gql/restriction/queries';
import { useEffect } from 'react';

export const useGetWellnessConditionHistory = (category: WellnessCategoryType, conditionId: string) => {
  const [getMedicalHistory, { data: medicalHistoryData, loading: medicalHistoryLoading }] =
    useGetMedicalConditionHistoryLazy(conditionId);
  const [getAllergyHistory, { data: allergyHistoryData, loading: allergyHistoryLoading }] =
    useGetAllergyHistoryLazy(conditionId);
  const [getRestrictionHistory, { data: restrictionHistoryData, loading: restrictionHistoryLoading }] =
    useGetRestrictionHistoryLazy(conditionId);

  useEffect(() => {
    switch (category) {
      case 'Medical':
        getMedicalHistory();
        console.log(getMedicalHistory());
        break;

      case 'Allergy':
        getAllergyHistory();
        break;

      case 'Restriction':
        getRestrictionHistory();
        break;
    }
  }, []);

  switch (category) {
    case 'Medical': {
      return { loading: medicalHistoryLoading, data: medicalHistoryData?.getMedicalConditionHistory ?? [] };
    }
    case 'Allergy': {
      return { loading: allergyHistoryLoading, data: allergyHistoryData?.getAllergyHistory ?? [] };
    }
    case 'Restriction': {
      return { loading: restrictionHistoryLoading, data: restrictionHistoryData?.getRestrictionHistory ?? [] };
    }
  }
};
