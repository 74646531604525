import { gql } from '@apollo/client';
import { useQuery, useLazyQuery } from 'shared/apis/core';

interface IGetClassesForCenterVariables {
  centerId: string;
}

interface IGetClassesForCenterData {
  getClassesForCenter: IClass[];
}

interface IGetCenterVariables {
  id: string;
}

interface IGetCenterData {
  getCenter: ICenter;
}

interface IGetCenterScheduleForWeekData {
  getCenterScheduleForWeek: ISchedule | null;
}

interface IGetCenterScheduleForWeekVariables {
  centerId: string;
  date: string;
}

interface IGetShiftsForPersonForDatesVariables {
  personId: string;
  dates: IDateTimeRange[];
}

interface IGetShiftsForPersonForDatesData {
  getShiftsForPersonForDates: IShift[];
}

interface ISearchSchedulableStaffData {
  searchSchedulableStaff: {
    totalSize: number;
    data: IStaff[];
  };
}

interface ISearchSchedulableVariables {
  centerId: string;
}

export const scheduleFields = `
  id
  centerId
  startTime
  endTime
  publishStatus
  shifts {
    id
    scheduleId
    centerId
    classId
    locationId
    personId
    positionId
    centerId
    startTime
    endTime
    breakMinutes
    paidBreak
    note
    person {
      id
      nickname
      firstname
      lastname
      avatar {
        url
      }
      classAssignments {
        id
        startsAt
        endsAt
        colorCode
      }
    }
    position {
      id
      title
      positionName
    }
    location {
      id
      name
    }
  }
`;

export const GET_ACTIVE_STAFF = gql`
  query ($input: SearchInput!) {
    searchStaff(input: $input) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
          colorCode
        }
      }
    }
  }
`;

export const GET_ACTIVE_STAFF_FOR_NEW_SHIFT = gql`
  query ($input: SearchInput!) {
    searchStaff(input: $input) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        positions {
          id
          positionId
          positionName
        }
      }
    }
  }
`;

export const GET_SCHEDULABLE_STAFF = gql`
  query ($centerId: ID!) {
    searchSchedulableStaff(centerId: $centerId) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
        role {
          id
          name
          scheduleVisibility
        }
        classAssignments {
          id
          startsAt
          endsAt
          colorCode
        }
        positions {
          id
          scopeId
          scopeType
          positionId
          positionName
        }
        availability {
          approved {
            dayOfWeek
            times {
              start
              end
            }
          }
          requested {
            dayOfWeek
            times {
              start
              end
            }
          }
        }
      }
    }
  }
`;

export const GET_CENTER_CLASSES = gql`
  query ($centerId: String!) {
    getClassesForCenter(centerId: $centerId) {
      id
      centerId
      name
      startsAt
      endsAt
      colorCode
      regulation {
        id
        ratioChildren
        ratioTeachers
      }
    }
  }
`;

export const GET_CENTER = gql`
  query ($id: String!) {
    getCenter(id: $id) {
      id
      entityId
      name
      classes {
        id
        centerId
        name
        startsAt
        endsAt
        archivedAt
        colorCode
        regulation {
          id
          ratioChildren
          ratioTeachers
        }
        regulationOverride {
          ratioChildren
          ratioTeachers
        }
      }
      locations {
        id
        name
        createdAt
        archivedAt
      }
    }
  }
`;

export const GET_CENTER_SCHEDULE_FOR_WEEK = gql`
  query($centerId: ID!, $date: DateTime!) {
    getCenterScheduleForWeek(centerId: $centerId, date: $date) {
      ${scheduleFields}
    }
  }
`;

export const GET_SHIFTS_FOR_PERSON_FOR_DATES = gql`
  query ($personId: ID!, $dates: [DateTimeRangeInput!]!) {
    getShiftsForPersonForDates(personId: $personId, dates: $dates) {
      id
      startTime
      endTime
    }
  }
`;

export const useGetCenterClasses = (centerId: string) =>
  useQuery<IGetClassesForCenterData, IGetClassesForCenterVariables>(GET_CENTER_CLASSES, {
    skip: centerId === '',
    variables: {
      centerId,
    },
  });

export const useGetCenter = (id: string) =>
  useQuery<IGetCenterData, IGetCenterVariables>(GET_CENTER, {
    skip: id === '',
    variables: {
      id,
    },
  });

export const useGetCenterScheduleForWeek = (centerId: string, date: string) =>
  useQuery<IGetCenterScheduleForWeekData, IGetCenterScheduleForWeekVariables>(GET_CENTER_SCHEDULE_FOR_WEEK, {
    skip: centerId === '' || date === '',
    variables: {
      centerId,
      date,
    },
    fetchPolicy: 'network-only',
  });

export const useGetShiftsForPersonForDatesLazy = () =>
  useLazyQuery<IGetShiftsForPersonForDatesData, IGetShiftsForPersonForDatesVariables>(GET_SHIFTS_FOR_PERSON_FOR_DATES, {
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      console.log(`There was an error fetching shifts for this person.`);
    },
  });

export const useSearchSchedulableStaff = (centerId: string) =>
  useQuery<ISearchSchedulableStaffData, ISearchSchedulableVariables>(GET_SCHEDULABLE_STAFF, {
    skip: centerId === '',
    variables: {
      centerId,
    },
  });
