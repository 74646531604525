import { useGetAccsServiceMetricsForCenters } from 'gql/accsServiceMetrics/queries';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import DataTable from 'shared/components/DataTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import moment from 'moment';
import { find } from 'lodash';
import { accsApplicationTypeEnum } from 'shared/constants/enums/ccssEnums';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  businessId: string;
  centerIds: string[];
  isOpen: boolean;
  onClose: () => void;
}

const dateFormat = 'DD/MM/YYYY';

const ViewAccsServiceMetricModal: React.FC<IProps> = ({ businessId, centerIds, isOpen, onClose }) => {
  const [tableState, tableFunctions] = useDatatableState();

  const { data: serviceMetrics, loading: serviceMetricsLoading } = useGetAccsServiceMetricsForCenters({
    variables: {
      businessId: businessId,
      centerIds: centerIds,
    },
  });
  type ServiceMetricRow = { id: number; data: IAccsServiceMetric };
  const rows: ServiceMetricRow[] = useMemo(
    () => serviceMetrics?.getAccsMetricsForCenters?.map((value, index) => ({ id: index, data: value })) ?? [],
    [serviceMetrics]
  );
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <CenteredModal
      size="xl"
      show={isOpen}
      onHide={onClose}
      title="ACCS Service Metrics"
      primaryChoice="Close"
      primaryCallback={onClose}
      hasSecondaryChoice={false}
    >
      <DataTable
        data={rows}
        dataSize={rows.length}
        pageSize={10}
        showLoadingOverlay={serviceMetricsLoading}
        showSelect={false}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        activePage={tableState.activePage}
        updateSelectedRows={tableFunctions.updateSelectedRows}
        noPadding
        columns={[
          {
            text: fieldLabels.center,
            dataField: 'data.centerName',
          },
          {
            text: 'Application Type',
            dataField: 'data.applicationType',
            formatter: (cell: string) =>
              `${find(accsApplicationTypeEnum, (application) => cell === application.value)?.label ?? 'Unknown'}`,
          },
          {
            text: '% Cap',
            dataField: 'data.percentageCap',
            formatter: (cell: string, row: ServiceMetricRow) => `${cell}%`,
            align: 'right',
            headerAlign: 'right',
          },
          {
            text: 'Children at Risk',
            dataField: 'data.numberOfChildrenAtRisk',
            align: 'right',
            headerAlign: 'right',
          },
          {
            text: '% Children At Risk',
            dataField: 'data.percentageOfChildrenAtRisk',
            formatter: (cell: string, row: ServiceMetricRow) => `${cell}%`,
            align: 'right',
            headerAlign: 'right',
          },
          {
            text: 'Last Updated',
            dataField: 'data.lastUpdated',
            formatter: (cell: string) => `${moment(cell).format(dateFormat)}`,
            align: 'center',
            headerAlign: 'center',
          },
        ]}
      />
    </CenteredModal>
  );
};

export default ViewAccsServiceMetricModal;
