import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import './_accordion.scss';

interface IProps {
  title?: string | React.ReactNode;
  eventKey: string;
  isOpen?: boolean;
  titleClassName?: string;
  className?: string;
}

const Item: React.FC<IProps> = ({
  title,
  eventKey, // provided when wrapped in <Accordion.Container />
  isOpen = false,
  titleClassName,
  className,
  ...props
}) => {
  const [isAccordionOpen, toggleAccordion] = useState(isOpen);

  return (
    <Card className={`m-0 ${className}`}>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={eventKey}
        className={`kt-accordion-card-header ${titleClassName}`}
        onClick={() => toggleAccordion(!isAccordionOpen)}
      >
        <div className="d-flex flex-row align-items-center">
          <span className="kt-accordion-title flex-grow-1">{title}</span>
          <FontAwesomeIcon color={colors.dark} icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey} className="kt-accordion-collapse border-bottom-0">
        <Card.Body>{props.children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default Item;
