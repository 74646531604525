import { Form } from 'react-bootstrap';
import { Col, Row } from 'shared/components/Layout';
import FormLabel from 'shared/components/Form/FormLabel';
import Checkbox from 'shared/components/Checkbox';
import Button from 'shared/components/Buttons';
import FileSimpleDropbox from 'shared/components/FileOperations/FileSimpleDropbox';
import { useState } from 'react';
import { get } from 'lodash';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import { ImportEzChildTrackContractsInput, useImportEzChildTrackContractsMutation } from 'generated/graphql';
import Alert from 'shared/components/Alert';
import useUniqueId from 'shared/hooks/useUniqueId';
import useGetBusinessName from 'shared/hooks/useGetBusinessName';
import ConfirmationModal from 'shared/components/ConfirmationModal';

type FormData = Partial<Omit<ImportEzChildTrackContractsInput, 'businessId'>>;

interface IProps {
  businessId: string;
}

const acceptedFileExtensions = '.xlsx';

const EzChildTrackContractsForm = ({ businessId }: IProps) => {
  const _id = useUniqueId();
  const { t } = useTranslation(['integrations']);
  const businessName = useGetBusinessName(businessId);

  // Form data
  const [showConfirm, setShowConfirm] = useState(false);
  const [formData, setFormData] = useState<FormData>({ dryRun: true });
  const handleChange = (value: FormData) => setFormData({ ...formData, ...value });
  const getProps = <t extends keyof FormData>(field: t) => ({
    default: {
      value: formData[field],
      onChange: (value: FormData[t]) => handleChange({ [field]: value }),
    },
    forFileDropbox: {
      value: [get(formData, field, [])].flat(),
      onChange: (f: File[]) => handleChange({ [field]: f[0] }),
      acceptedFileExts: acceptedFileExtensions,
    },
  });

  // Validation - extract to a function if too big.
  // Also this can be simplifed if we don't want to count the files.
  const files = [formData.enrollmentAttendanceFile, formData.programMappingFile];
  const requiredFiles = files.length;
  const validFileCount = files.filter((x) => !!x).length;
  const canCompleteForm = requiredFiles === validFileCount;

  // Submit
  const [importEzChildTrackContracts, { loading }] = useImportEzChildTrackContractsMutation();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    importEzChildTrackContracts({
      variables: {
        input: {
          businessId,
          dryRun: formData.dryRun ?? false,
          enrollmentAttendanceFile: formData.enrollmentAttendanceFile,
          programMappingFile: formData.programMappingFile,
        },
      },
    })
      .then((x) => showToast(t('integrations:migration.families.success'), 'success'))
      .catch((err) => showToast(`${err.toString()}`, 'error'));
  };

  return (
    <Form onSubmit={onSubmit} id={_id}>
      <Row>
        <Col as={'p'}>
          {t('integrations:migration.uploadInstructions', { allowedFileExtensions: acceptedFileExtensions })}
        </Col>
        <Col>
          <Checkbox
            label={t('integrations:migration.dry-run')}
            className="text-right"
            {...getProps('dryRun').default}
          />
        </Col>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="ezChildTrackContractsEnrollmentAttendance">
          <FormLabel required>Enrollment Attendance</FormLabel>
          <FileSimpleDropbox {...getProps('enrollmentAttendanceFile').forFileDropbox} />
        </Form.Group>
        <Form.Group as={Col} controlId="ezChildTrackContractsProgramMapping">
          <FormLabel required>Program Mapping</FormLabel>
          <FileSimpleDropbox {...getProps('programMappingFile').forFileDropbox} />
        </Form.Group>
      </Row>
      <Row>
        <Col justify="end" className="d-flex">
          <Alert variant="warning" show={loading}>
            WARNING: This can take a long time, do not navigate away from this page or the migration will fail. Check
            seq logs to track progress.
          </Alert>
          <Button
            disabled={!canCompleteForm}
            type="button"
            loading={loading}
            onClick={() => {
              setShowConfirm(true);
            }}
          >
            {t('integrations:migration.submit-button')}
          </Button>
          <ConfirmationModal
            show={showConfirm}
            onHide={() => setShowConfirm(false)}
            primaryButtonProps={{ type: 'submit', form: _id }}
            title="Confirm"
          >
            <p>Are you sure you want to start the contracts migration for business?:</p>
            <p>{businessName}</p>
            <p>{businessId}</p>
            <p>This action cannot be undone.</p>
          </ConfirmationModal>
        </Col>
      </Row>
    </Form>
  );
};

export default EzChildTrackContractsForm;
