import {
  AccountStatusType,
  ReEnrollChild,
  useGetReenrollmentAccountContractsQuery,
  useRequestUpdateEnrolmentFormMutation,
} from 'generated/graphql';
import Button from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import moment from 'moment';
import { Row } from 'shared/components/Layout';
import './_childrenTable.scss';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import DataTable from 'shared/components/DataTable';
import { showToast } from 'shared/components/Toast';
import { capitalize } from 'lodash';
import { showConfirm } from 'shared/components/ConfirmationContainer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IProps {
  accountId: string;
  accountCenterId: string;
  handleSendRequest: () => void;
  isSubmitting: boolean;
}

const ChildrenTable: React.FC<IProps> = ({ accountId, accountCenterId, handleSendRequest, isSubmitting }) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const [childrenWithContracts, setChildrenWithContracts] = useState<ReEnrollChild[] | undefined | null>([]);
  const hasSendRequestPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.FamilyEnrollmentRequests,
    level: RoleLevelType.Edit,
  });
  const hasUpdateDetailsPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.FamilyUpdateDetailsRequests,
    level: RoleLevelType.Edit,
  });

  const { data, loading, error } = useGetReenrollmentAccountContractsQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      input: {
        businessId: businessId ?? '',
        accountId: accountId,
      },
    },
  });

  const [requestUpdateDetailsMutation, { loading: requestUpdateDetailsMutationSubmitting }] =
    useRequestUpdateEnrolmentFormMutation({
      onError: () => showToast(t('enrollment:update-enrollment-form-error'), 'error'),
      onCompleted: () => {
        showToast(t('enrollment:update-enrollment-form-ok'), 'success');
      },
    });

  const onConfirmSendUpdateDetailsRequest = async () => {
    requestUpdateDetailsMutation({
      variables: {
        input: {
          businessId: businessId ?? '',
          filter: {
            includeAllAccountIds: false,
            centerIds: [accountCenterId],
            accountStatuses: [AccountStatusType.Active, AccountStatusType.Inactive],
            asOfDate: moment().format('YYYY-MM-DD'),
            includeAccountIds: [accountId],
          },
        },
      },
    });
  };

  const handleSendUpdateDetailsRequest = () => {
    showConfirm({
      title: t('enrollment:update-enrollment-form'),
      message: (
        <>
          You have selected 1 account. Would you like to send requests to account primary contacts now, to update their
          child and family details?
        </>
      ),
      primaryButtonLabel: t('enrollment:update-enrollment-form-text'),
      onConfirm: onConfirmSendUpdateDetailsRequest,
      primaryButtonVariant: 'primary',
    });
  };

  useEffect(() => {
    setChildrenWithContracts(data?.getReenrollmentAccountContracts);
  }, [data]);

  return (
    <>
      <DataTable
        className="reenroll-child-table"
        data={childrenWithContracts ?? []}
        showLoadingOverlay={loading}
        showSelect={false}
        showPagination={false}
        columns={[
          {
            text: t('enrollment:reenroll-children-table.table-fields.children'),
            dataField: 'firstname',
            formatter: (cell: any, child: ReEnrollChild) => {
              return ((child?.nickname || child?.firstname) ?? '') + ' ' + (child?.lastname ?? '');
            },
          },
          {
            text: t('enrollment:reenroll-children-table.table-fields.dob'),
            dataField: 'dob',
            formatter: (cell: any, child: ReEnrollChild) => {
              return moment(child?.dob).format(t('translation:formatters.MM/DD/YYYY'));
            },
          },
          {
            text: t('enrollment:reenroll-children-table.table-fields.age'),
            dataField: '',
            formatter: (cell: any, child: ReEnrollChild) => {
              return getAgeStringFromDateOfBirth(moment(child?.dob)).replace(/\(|\)/g, '');
            },
          },
          {
            text: t('enrollment:reenroll-children-table.table-fields.contract-start'),
            dataField: '',
            formatter: (cell: any, child: ReEnrollChild) => {
              const lastIndex = child?.contracts?.length ?? -1;
              const latestContract = lastIndex >= 0 && child?.contracts ? child?.contracts[lastIndex - 1] : undefined;
              return latestContract?.startDate
                ? moment(latestContract?.startDate).format(t('translation:formatters.MM/DD/YYYY'))
                : '';
            },
          },
          {
            text: t('enrollment:reenroll-children-table.table-fields.contract-end'),
            dataField: '',
            formatter: (cell: any, child: ReEnrollChild) => {
              const lastIndex = child?.contracts?.length ?? -1;
              const latestContract = lastIndex >= 0 && child?.contracts ? child?.contracts[lastIndex - 1] : undefined;
              return latestContract?.endDate
                ? moment(latestContract?.endDate).format(t('translation:formatters.MM/DD/YYYY'))
                : '';
            },
          },
        ]}
      />
      <Row className="justify-content-end ml-0 mr-0">
        {hasUpdateDetailsPermission && (
          <Button
            variant="secondary"
            loading={requestUpdateDetailsMutationSubmitting}
            onClick={handleSendUpdateDetailsRequest}
            className="mr-4"
          >
            {t('enrollment:update-enrollment-form-text')}
          </Button>
        )}

        {hasSendRequestPermission && (
          <Button variant="primary" loading={isSubmitting} onClick={handleSendRequest}>
            {t('enrollment:request-reenroll-text')}
          </Button>
        )}
      </Row>
    </>
  );
};

export default ChildrenTable;
