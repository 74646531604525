import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { useUnarchiveHealthTerm } from 'gql/healthTerm/mutations';
import { WellnessCondition } from 'shared/constants/enums/wellnessConditions';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  healthTerm: IHealthTerm;
  onUnarchiveSuccess: (unarchivedHealthTerm: IHealthTerm) => void;
}

const UnarchiveHealthWellnessTypeModal: React.FC<IProps> = ({ isOpen, onClose, healthTerm, onUnarchiveSuccess }) => {
  const { t } = useTranslation();
  const [unarchiveHealthTermFn, { loading }] = useUnarchiveHealthTerm({
    onCompleted: (result) => {
      const unarchivedTerm = result.unarchiveHealthTerm;
      onUnarchiveSuccess(unarchivedTerm);
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleUnarchive = useCallback((id: string) => {
    unarchiveHealthTermFn({
      variables: {
        id,
      },
    })
      .then(() => {
        showToast(t('settings.configuration.health-and-wellness.toast.unarchive-success'), 'success');
        onClose();
      })
      .catch((error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      });
  }, []);

  return (
    <ConfirmationModal
      title={`${t('settings.configuration.health-and-wellness.unarchive-modal.title', {
        healthTermTypeName: healthTerm.type.toString(),
        condition: (healthTerm.type as String) == WellnessCondition.Medical ? `${t('spelling.capitalCondition')}` : '',
      })}`}
      className={(healthTerm.type as String) == WellnessCondition.Medical ? 'health-wellness-unarchive' : ''}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Unarchive"
      primaryCallback={() => handleUnarchive(healthTerm.id)}
      primaryButtonProps={{ variant: 'success', loading }}
    >
      <p>{`${t('settings.configuration.health-and-wellness.unarchive-modal.body-text', {
        healthTermName: healthTerm.name,
      })}`}</p>
    </ConfirmationModal>
  );
};

export default UnarchiveHealthWellnessTypeModal;
