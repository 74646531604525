import { useCallback, useState } from 'react';
import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { GET_TAGS } from './queries';
import { ExecutionResult } from 'graphql';

// // GRAPHQL
const CREATE_TAG = gql`
  mutation ($input: TagInput!) {
    createTag(input: $input) {
      entityId
      name
      id
      category
    }
  }
`;

const DELETE_TAG = gql`
  mutation ($id: ID!) {
    deleteTag(id: $id) {
      id
    }
  }
`;

const UPDATE_TAG = gql`
  mutation ($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
      name
      category
    }
  }
`;

// CUSTOM HOOKS

/**
 * Create a new tag at an entity
 */
export const useCreateTag: () => [(input: INewTag) => Promise<void>, IMutationResponse] = () => {
  const [response, setResponse] = useState({
    loading: false,
    error: null,
    data: null,
  });

  const [createTagMutation] = useMutation(CREATE_TAG);

  const useCreateTagFn = useCallback(
    async (tagDetails: INewTag) => {
      setResponse({ ...response, loading: true });
      try {
        const {
          data: { createTag },
        }: ExecutionResult<any> = await createTagMutation({
          variables: {
            input: {
              entityId: tagDetails.entityId,
              name: tagDetails.name,
              category: tagDetails.category,
            },
          },
          refetchQueries: [
            {
              query: GET_TAGS,
              variables: {
                id: tagDetails.entityId,
              },
            },
          ],
        });

        setResponse({
          loading: false,
          error: null,
          data: createTag,
        });
      } catch (error: any) {
        setResponse({
          loading: false,
          error,
          data: null,
        });
      }
    },
    [createTagMutation, response]
  );

  return [useCreateTagFn, response];
};

export const useDeleteTag: () => [(id: string, entityId: string) => Promise<void>, IMutationResponse] = () => {
  const [response, setResponse] = useState({
    loading: false,
    error: null,
    data: null,
  });

  const [deleteTagMutation] = useMutation(DELETE_TAG);

  const useDeleteTagFn = useCallback(
    async (id: string, entityId: string) => {
      setResponse({ ...response, loading: true });
      try {
        const {
          data: { deleteTag },
        }: ExecutionResult<any> = await deleteTagMutation({
          variables: {
            id,
          },
          refetchQueries: [
            {
              query: GET_TAGS,
              variables: {
                id: entityId,
              },
            },
          ],
        });

        setResponse({
          loading: false,
          error: null,
          data: deleteTag,
        });
      } catch (error: any) {
        setResponse({
          loading: false,
          error,
          data: null,
        });
      }
    },
    [deleteTagMutation, response]
  );

  return [useDeleteTagFn, response];
};

export const useUpdateTag: () => [(input: ITag) => Promise<void>, IMutationResponse] = () => {
  const [response, setResponse] = useState({
    loading: false,
    error: null,
    data: null,
  });

  const [updateTagMutation] = useMutation(UPDATE_TAG);

  const useUpdateTagFn = useCallback(
    async (tagDetails: ITag) => {
      setResponse({ ...response, loading: true });
      try {
        const {
          data: { updateTag },
        }: ExecutionResult<any> = await updateTagMutation({
          variables: {
            input: {
              id: tagDetails.id,
              name: tagDetails.name,
              category: tagDetails.category,
            },
          },
          refetchQueries: [
            {
              query: GET_TAGS,
              variables: {
                id: tagDetails.entityId,
              },
            },
          ],
        });

        setResponse({
          loading: false,
          error: null,
          data: updateTag,
        });
      } catch (error: any) {
        setResponse({
          loading: false,
          error,
          data: null,
        });
      }
    },
    [updateTagMutation, response]
  );

  return [useUpdateTagFn, response];
};
