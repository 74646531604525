import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import { RootState } from 'store/reducers';
import ProfileTab from './components/Profile/ProfileTab';
import { useGetContactByIdRedux } from '../graphql/queries';
import ChildrenTab from './ChildrenTab';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ContactCustomFieldsTab from './components/ContactCustomFieldsTab';
import { useTranslation } from 'react-i18next';
import { useGetCustomFieldValues } from 'gql/customField/queries';
import { compact, isEmpty } from 'lodash';
import useUpdateAvatar from 'shared/hooks/useUpdateAvatar';

interface IRouteParams {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const Contact: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const hasEditContactPermission = useHasRoleAreaLevel({
    area: AreaType.Contact,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const hasViewChildPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const contactId: string = props.match.params.id ?? '';
  const contact: IContact | undefined = useSelector((state: RootState) => state.contacts.byId)[contactId];
  const user = useSelector((state: RootState) => state.user);
  const { loading } = useGetContactByIdRedux(contactId);

  const businessId = useSelector((state: RootState) => state.user?.entityId) ?? '';
  var contactCenterIds = compact(contact?.accounts?.map((x) => x.centerId) ?? []);
  const [showArchived, setShowArchived] = useState(false);

  const [updateAvatarFn, { loading: avatarLoading }] = useUpdateAvatar('contact');

  const {
    data,
    loading: customFieldLoading,
    refetch,
  } = useGetCustomFieldValues({
    variables: {
      input: {
        businessId: businessId,
        centerIds: contactCenterIds ?? [],
        entityId: contact?.id ?? '',
        area: 'Contact',
        isArchived: showArchived,
      },
    },
    skip: isEmpty(contact) || businessId === '' || contactCenterIds.length === 0,
  });
  const customFields = data?.getCustomFieldValues.customFields ?? [];

  return (
    <ProfilePageWrapper
      title={`${contact?.firstname ?? ''} ${contact?.lastname ?? ''}`}
      subtitle={user?.isInternal ? contactId : ''}
      loading={loading}
      avatarUrl={contact?.avatar?.url}
      onAvatarClick={(file: File) => {
        contact && updateAvatarFn({ id: contact.id, avatar: file });
      }}
      avatarLoading={avatarLoading}
    >
      <div className="mx-auto profile-page-width">
        <Tabs defaultActiveKey="profile" id="contact-tabs">
          <Tab eventKey="profile" title="Profile">
            <ProfileTab contact={contact} loading={loading} readOnly={!hasEditContactPermission} />
          </Tab>
          {hasViewChildPermission && (
            <Tab eventKey="children" title="Children">
              <ChildrenTab contact={contact} loading={loading} />
            </Tab>
          )}
          <Tab eventKey="customFields" title={t('custom-data-fields.tab-title')} id="contact-tabs-tab-custom-field">
            <ContactCustomFieldsTab
              contact={contact}
              loading={loading || customFieldLoading}
              customFields={customFields}
              values={data?.getCustomFieldValues?.values ?? []}
              refetch={refetch}
              onShowArchivedChange={setShowArchived}
              showArchived={showArchived}
            />
          </Tab>
        </Tabs>
      </div>
    </ProfilePageWrapper>
  );
};

export default Contact;
