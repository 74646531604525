import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { IRoute } from 'shared/util/routes';
import { get } from 'lodash';

type MatchReduxVStoreValue = Exclude<IRoute['matchReduxStoreValue'], undefined>;

export default () => {
  const store = useSelector((state: RootState) => state);

  const isMatch = useCallback(
    (matchReduxVStoreValue: MatchReduxVStoreValue) => {
      const { reduxStorePath, expectedValue } = matchReduxVStoreValue;
      const path = reduxStorePath.replace('{{businessId}}', store.context.businessId ?? '');

      if (Array.isArray(expectedValue)) {
        const value = get(store, path, undefined);
        return expectedValue.some((e) => e === value);
      }

      return get(store, path, undefined) === expectedValue;
    },
    [store]
  );

  return isMatch;
};
