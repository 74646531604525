import React, { useCallback, useEffect, useState } from 'react';
import { IGetEnrollmentProgramsData, useGetEnrollmentPrograms } from 'gql/enrollment/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { IEnrollmentProgramsTableProps } from '../components/EnrollmentProgramsTable/EnrollmentProgramsTable';

interface Params {
  withNoProgramGroup?: boolean;
  pageSize?: number;
  programGroupId?: string;
  onCompleted?: (data: IGetEnrollmentProgramsData) => void;
}
const useGetEnrollmentProgramTable = ({
  withNoProgramGroup = false,
  pageSize = 25,
  programGroupId,
  onCompleted,
}: Params = {}) => {
  const { data: centers } = useGetActiveCentersWithLoading();
  const { businessId, centerFilterIds } = useSelector((state: RootState) => state.context);

  const [tableState, tableFn] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState<ISortDto[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSort([{ field, direction }]);
  }, []);

  useEffect(() => {
    if (pageSize) {
      tableFn.changeSizePerPage(pageSize);
    }
  }, [pageSize]);

  const { data, loading, refetch } = useGetEnrollmentPrograms({
    variables: {
      input: {
        businessId: businessId ?? '',
        centerIds: centerFilterIds.length > 0 ? centerFilterIds : centers?.map((c) => c.id) ?? [],
        pagination: {
          pageNumber: tableState.activePage,
          pageSize: tableState.pageSize,
        },
        searchText: searchTerm !== '' ? searchTerm : undefined,
        sort: sort,
        withNoProgramGroup: !!withNoProgramGroup || undefined,
        programGroupId,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted,
    skip: !Boolean(businessId) || centers?.length === 0,
  });

  const handleSearchDebounced = useCallback((value: string) => {
    setSearchTerm(value);
    tableFn.changePage(1, tableState.pageSize);
  }, []);

  const tableProps: IEnrollmentProgramsTableProps = {
    centers: centers ?? [],
    totalResults: data?.getEnrollmentPrograms?.totalRecords ?? 0,
    tableFunctions: tableFn,
    tableState: tableState,
    businessId: businessId ?? '',
    loading: loading,
    centerFilterIds: centerFilterIds,
    data: data?.getEnrollmentPrograms?.data ?? [],
    setSearchTerm: handleSearchDebounced,
    searchTerm: searchTerm,
    onSort: handleSort,
  };

  return {
    tableProps,
    refetch,
  };
};

export default useGetEnrollmentProgramTable;
