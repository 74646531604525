import config from 'config';

interface ICountryCodeInfo {
  dateFormat: string;
  firstDayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

const northernAmericaCodeInfo: ICountryCodeInfo = {
  dateFormat: 'M/D/YYYY',
  firstDayOfWeek: 0,
};

const COUNTRY_CODES: Record<string, ICountryCodeInfo> = {
  AU: { dateFormat: 'D/M/YYYY', firstDayOfWeek: 1 },
  CA: northernAmericaCodeInfo,
  US: northernAmericaCodeInfo,
};

export const DefaultCountry = COUNTRY_CODES[config.locale.region];
