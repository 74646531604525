import React from 'react';
import Select, { IProps as IKTSelectProps } from './Select';
import CLASS_COLORS from 'shared/constants/ClassColors';

const ColorSelect: React.FC<Omit<IKTSelectProps, 'options'>> = ({ ...props }) => {
  return (
    <Select
      {...props}
      className="color-select"
      isSearchable={false}
      options={CLASS_COLORS}
      placeholder=""
      formatOptionLabel={(option: any) => (
        <div className="rounded-circle circle-xs" style={{ backgroundColor: option.value }} />
      )}
    />
  );
};

export default ColorSelect;
