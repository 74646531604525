import React, { useCallback, useState } from 'react';
import i18n from 'i18n';
import { useSelector } from 'react-redux';
import Button from 'shared/components/Buttons';
import Select from 'shared/components/Select';
import { getFullName } from 'shared/util/string';
import { RootState } from 'store/reducers';
import User from 'shared/types/user';
import { useCreateSessionAmendment } from 'gql/sessionAmendment/mutations';
import { showToast } from 'shared/components/Toast';
import moment from 'moment';
import { Col, Row } from 'shared/components/Layout';
import { useTranslation } from 'react-i18next';

interface IProps {
  staffs: IStaff[];
  businessId: string;
  refreshDataGrid: () => void;
}

const AttendanceTempAccessForm: React.FC<IProps> = ({ staffs, businessId, refreshDataGrid }) => {
  const { t } = useTranslation();
  const user = useSelector<RootState, User | null>((state: RootState) => state.user);
  const options = staffs.map((staff) => ({
    value: staff.id,
    label: getFullName(staff),
  }));

  const [selectedStaffs, setSelectedStaffs] = useState<{ value: string; label: string }[] | null>(null);

  const [createSessionAmendment] = useCreateSessionAmendment({
    onCompleted: (result) => {
      if (result?.createSessionAmendment) {
        showToast(i18n.t('translation:general.updateSuccessful'), 'success');
        refreshDataGrid();
        setSelectedStaffs(null);
      }
    },
  });

  const handleGiveAccessBtn = useCallback(async () => {
    await createSessionAmendment({
      variables: {
        input: {
          businessId: businessId,
          startDate: moment().format('YYYY-MM-DD'),
          expiryDate: moment().add(1, 'day').format('YYYY-MM-DD'),
          authorizedByPersonId: user!.id,
          authorizedPersonIds: selectedStaffs?.map((value) => value.value) ?? [],
        },
      },
    });
  }, [selectedStaffs, businessId]);
  return (
    <div style={{ minHeight: 206 }}>
      <p>{t('attendance.history-control.grant-access-info')}</p>
      <Row>
        <Col md={12} lg={5} xl={6}>
          <Select
            options={options}
            isMulti
            placeholder={t('attendance.history-control.select-staff')}
            onChange={(options: any) => {
              setSelectedStaffs(options);
            }}
            value={selectedStaffs}
          />
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Button
              className="mb-4"
              onClick={handleGiveAccessBtn}
              disabled={selectedStaffs == null || selectedStaffs.length === 0}
            >
              {t('attendance.history-control.grant-access')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AttendanceTempAccessForm;
