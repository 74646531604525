import React, { useCallback } from 'react';
import moment from 'moment';
import useFormatDate from 'shared/hooks/useFormatDate';
import DataTable, { SizePerPage, TableHeader } from 'shared/components/DataTable';
import ActionDropdown from 'shared/components/ActionDropdown';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState2';
import FilterGroup from './FilterGroup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetClassesForCenters } from 'pages/Centers/subroutes/Classes/graphql/queries';
import { updateSearchFilter, updateSearchSort } from 'pages/Centers/subroutes/Fees/duck/actions';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getLabelForFeeType } from 'pages/Centers/subroutes/Fees/helpers/FeeInputHelpers';
import { getActiveSchedule } from 'pages/Centers/subroutes/Fees/helpers/FeeHelpers';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

interface IProps {
  activeCenterId?: string | null;
  data: IFee[];
  loading: boolean;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  handleRowClick: (event: React.SyntheticEvent | null, rowData: IFee) => void;
  handleReactivateFee: (fee: IFee) => void;
  handleDeactivateFee: (fee: IFee) => void;
  displayingSingleClass?: boolean;
  totalFees: number;
  noPadding: boolean;
}

const FeesTable: React.FC<IProps> = ({
  data,
  loading,
  tableState,
  tableFunctions,
  handleRowClick,
  handleReactivateFee,
  handleDeactivateFee,
  displayingSingleClass = false,
  activeCenterId = null,
  totalFees,
  noPadding = false,
  ...props
}) => {
  const { k2FlatRateFees } = useFlags();
  const dispatch = useDispatch();
  const formatDate = useFormatDate();
  const {
    fees: { searchFilter },
  } = useSelector((state: RootState) => state);
  const hasEditFeePermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Fees,
    level: RoleLevelType.Edit,
  });
  const hasDeleteFeePermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Fees,
    level: RoleLevelType.Delete,
  });

  const { data: classesData } = useGetClassesForCenters(activeCenterId ?? '');

  const actionsForRow = useCallback(
    (row: IFee): IDropdownAction[] => {
      const actions: IDropdownAction[] = [];

      if (hasEditFeePermission) {
        actions.push({
          label: 'Edit',
          onClick: () => handleRowClick(null, row),
        });
      }

      if (hasDeleteFeePermission) {
        if (!row.deactivatedAt) {
          actions.push({
            label: 'Deactivate',
            disabled: row.deactivatedAt !== null,
            onClick: () => handleDeactivateFee(row),
          });
        } else {
          actions.push({
            label: 'Reactivate',
            disabled: row.deactivatedAt === null,
            onClick: () => handleReactivateFee(row),
          });
        }
      }

      return actions;
    },
    [hasEditFeePermission, hasDeleteFeePermission, handleRowClick, handleReactivateFee, handleDeactivateFee]
  );

  const structureTypeToString = useCallback((type: FeeStructure): string => {
    switch (type) {
      case 'SESSION':
        return 'Session';
      case 'NUMBER_OF_DAYS':
        return 'Days per Week';
      case 'NUMBER_OF_SIBLINGS':
        return 'Number of Siblings';
      case 'SINGLE_FLAT_RATE':
        return 'Single Flat Rate';
      default:
        return '';
    }
  }, []);

  const utilizationToPercentString = useCallback(
    (utilization: number): string => `${(utilization * 100).toFixed(0)}%`,
    []
  );
  const showFeeType = k2FlatRateFees;

  const columns = [
    {
      text: 'Name',
      dataField: 'name.keyword',
      sort: true,
      formatter: (cell: string, row: IFee) => row.name,
    },
    {
      text: 'Start Date',
      formatter: (cell: string, row: IFee) => {
        const schedule = getActiveSchedule<IFeeSchedule>(row.schedules);

        if (schedule) {
          return schedule.startDate ? formatDate(moment(schedule.startDate)) : '';
        }

        return '';
      },
    },
    {
      text: 'Fee Structure',
      formatter: (cell: string, row: IFee) => structureTypeToString(row.feeStructure),
    },
    {
      text: 'Fee',
      formatter: (cell: string, row: IFee) => {
        const schedule = getActiveSchedule<IFeeSchedule>(row.schedules);

        if (schedule) {
          return schedule.rates.map((r) => `$${r.value.toFixed(2)}`).join(', ');
        }

        return '';
      },
    },
    {
      text: fieldLabels.utilization,
      formatter: (cell: string, row: IFee) => utilizationToPercentString(row.utilization),
    },
    (hasEditFeePermission || hasDeleteFeePermission) && {
      text: 'Actions',
      dataField: '',
      align: 'center',
      headerClasses: 'text-center',
      formatter: (cell: any, row: IFee) => <ActionDropdown actions={actionsForRow(row)} />,
    },
  ];
  if (showFeeType) {
    // add the feeType Column to the table column
    columns.splice(2, 0, {
      text: 'Fee Type',
      formatter: (cell: string, row: IFee) => getLabelForFeeType(row.feeType),
    });
  }

  return (
    <DataTable
      data={data}
      dataSize={totalFees}
      pageSize={tableState.pageSize}
      showLoadingOverlay={loading}
      showSelect={false}
      handleRowClick={hasEditFeePermission ? handleRowClick : undefined}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      activePage={tableState.activePage}
      selectedRows={tableState.selectedRows}
      updateSelectedRows={tableFunctions.updateSelectedRows}
      onSort={(field, direction) => dispatch(updateSearchSort(field, direction))}
      noPadding={noPadding}
      columns={columns}
      renderHeader={(paginationProps: any, searchProps: any) => (
        <TableHeader className="flex-wrap">
          <SizePerPage paginationProps={paginationProps} />
          <FilterGroup
            handleUpdateSearch={tableFunctions.updateSearchExpressions}
            hanldeUpdateFilter={(filter) => dispatch(updateSearchFilter(filter))}
            filter={searchFilter}
            classOptions={classesData?.getClassesForCenter ?? []}
            showClassFilter={!displayingSingleClass}
          />
        </TableHeader>
      )}
    />
  );
};

export default FeesTable;
