import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { IconDefinition, faRocket } from '@fortawesome/pro-light-svg-icons';
import { Row } from 'shared/components/Layout';
import Tooltip from '../Tooltip';

interface IConditionalToolTipProps {
  children: React.ReactNode;
  tooltipText?: string;
  tooltipDirection?: 'left' | 'right' | 'top' | 'bottom';
  tooltipPopperConfig?: any;
}

const ConditionalToolTip: React.FC<IConditionalToolTipProps> = ({
  children,
  tooltipText,
  tooltipDirection = 'top',
  tooltipPopperConfig = {},
}) => {
  if (tooltipText) {
    return (
      <Tooltip text={tooltipText} direction={tooltipDirection} popperConfig={tooltipPopperConfig}>
        {children}
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
};

interface IProps {
  icon?: IconDefinition;
  id?: string;
  iconSize?: 'xs' | 'sm' | 'lg' | '1x' | '2x' | '3x';
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  color?: string;
  backgroundColor?: string;
  className?: string;
  size?: circleSizes;
  disabled?: boolean;
  tooltipText?: string;
  tooltipDirection?: 'left' | 'right' | 'top' | 'bottom';
  tooltipPopperConfig?: any;
  iconComponent?: any;
}

const IconButtonCircle: React.FC<IProps> = ({
  icon = faRocket,
  id,
  iconSize = '1x',
  onClick,
  color,
  backgroundColor = 'pale-grey',
  className,
  size = 'md',
  disabled,
  tooltipText,
  tooltipDirection,
  tooltipPopperConfig,
  iconComponent,
  ...props
}) => (
  <ConditionalToolTip
    tooltipText={tooltipText}
    tooltipDirection={tooltipDirection}
    tooltipPopperConfig={tooltipPopperConfig}
  >
    <Button
      {...props}
      id={id}
      disabled={disabled}
      onClick={onClick}
      className={`bg-${backgroundColor} kt-button rounded-circle circle-${size} icon-button ${className}`}
      variant="light"
    >
      <Row align="center" justify="center" noGutters className={`icon-container circle-${size}`}>
        <div className="m-auto">
          {iconComponent ? iconComponent : <FontAwesomeIcon size={iconSize} icon={icon} color={color} />}
        </div>
      </Row>
    </Button>
  </ConditionalToolTip>
);

export default IconButtonCircle;
