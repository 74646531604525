import React from 'react';
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import useWindowSize from 'shared/hooks/useWindowSize';
import PropTypes from 'prop-types';

const DataTableSizePerPage = ({ paginationProps, ...props }) => {
  //   const windowSize = useWindowSize();
  //   const isMobile = windowSize.innerWidth <= 768;

  return <SizePerPageDropdownStandalone {...paginationProps} {...props} />;
};

DataTableSizePerPage.propTypes = {
  pagination: PropTypes.object,
};

export default DataTableSizePerPage;
