import React, { useCallback } from 'react';
import { useDeleteChildImmunizationRecord } from 'gql/immunization/mutations';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { deleteChildImmunization } from '../../../duck/actions';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  immunization: IImmunization | null;
}

const DeleteImmunizationModal: React.FC<IProps> = ({ isOpen, onClose, immunization, ...props }) => {
  const dispatch = useDispatch();
  const [deleteChildImmunizationFn, { loading }] = useDeleteChildImmunizationRecord({
    onCompleted: (result) => {
      dispatch(deleteChildImmunization(result.deleteChildImmunizationRecord));
      showToast('Immunization deleted successfully.', 'success');
      onClose();
    },
    onError: (error) => {
      showToast('There was an error deleting this immunization record.', 'error');
    },
  });

  const handleSave = useCallback(() => {
    if (immunization) {
      deleteChildImmunizationFn({
        variables: {
          childId: immunization.childId,
          immunizationId: immunization.id,
        },
      });
    }
  }, [deleteChildImmunizationFn, immunization]);

  return (
    <ConfirmationModal
      title="Delete Immunization?"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Delete"
      primaryCallback={handleSave}
      primaryButtonProps={{ loading, variant: 'danger' }}
      hideOnCallback={false}
    >
      Are you sure you want to delete this immunization and any related history for this entry? Once deleted, it will no
      longer be viewable or accessible.
    </ConfirmationModal>
  );
};

export default DeleteImmunizationModal;
