import moment from 'moment';
import { dateToDateTime } from './DateToDateTime';
import { BillingScheduleBillingPeriod, iCalendarEvent } from './types';

const subTitleDateFormat = 'MMM D';

export function mapPeriodsToEvents(periods?: BillingScheduleBillingPeriod[]): iCalendarEvent[] {
  if (!periods) return [];
  periods = periods?.slice().sort((a, b) => {
    const aStartDate = moment(a.startDate);
    const bStartDate = moment(b.startDate);
    if (aStartDate.isBefore(bStartDate)) {
      return -1;
    } else if (aStartDate.isAfter(bStartDate)) {
      return 1;
    }
    return 0;
  });
  const events: iCalendarEvent[] = [];
  for (let billingPeriod of periods) {
    const { startDate, endDate, paymentDueDate, invoiceDate, index } = billingPeriod;
    const periodString = `${moment(dateToDateTime(startDate, 'startOf')).format(subTitleDateFormat)} - ${moment(
      dateToDateTime(endDate, 'endOf')
    ).format(subTitleDateFormat)}`;
    let isPastInvoiceDate = moment(invoiceDate).endOf('day').isBefore(moment().add(1, 'day'));
    const isOdd = index % 2 !== 0;
    const eventFactory = (eventArgs: {
      start: string;
      end: string;
      title: string;
      eventType: iCalendarEvent['eventType'];
      isOdd: boolean;
    }): iCalendarEvent => {
      return {
        start: dateToDateTime(eventArgs.start, 'startOf'),
        end: dateToDateTime(eventArgs.end, 'endOf'),
        title: eventArgs.title,
        allDay: true,
        // Currently this means the billing period window to invoice has past
        isPast: isPastInvoiceDate,
        eventType: eventArgs.eventType,
        isOdd,
      };
    };
    events.push(
      eventFactory({
        start: startDate,
        end: endDate,
        title: `Billing Period ${periodString}`,
        eventType: 'billingperiod',
        isOdd,
      })
    );
    events.push(
      eventFactory({
        start: paymentDueDate,
        end: paymentDueDate,
        title: `Payment Due: ${periodString}`,
        eventType: 'payment',
        isOdd,
      })
    );
    events.push(
      eventFactory({
        start: invoiceDate,
        end: invoiceDate,
        title: `Bill: ${periodString}`,
        eventType: 'invoice',
        isOdd,
      })
    );
  }
  return events;
}
