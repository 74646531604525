//region Create_Enrollment_Form
import { gql } from '@apollo/client';
import { MutationHookOptions, MutationTuple } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { InputVariable } from 'gql/program/mutation';
import { useMutation } from 'shared/apis/core';
import showToast from 'shared/components/Toast/showToast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

const Create_Enrollment_Form = () => gql`
  mutation ($input: CreateEnrollmentFormInput!) {
    createEnrollmentForm(input: $input) {
      businessId
      formSchema
      isDraft
      usedByCenterIds
      id
      name
    }
  }
`;

export type CreateEnrollmentFormData = {
  createEnrollmentForm: EnrollmentForm;
};

export type CreateEnrollmentFormInput = {
  businessId: string;
  formSchema?: object;
  usedByCenterIds?: string[];
  id?: string;
  name: string;
};

export const useCreateEnrollmentForm = (
  options?: MutationHookOptions<CreateEnrollmentFormData, InputVariable<CreateEnrollmentFormInput>>
): MutationTuple<CreateEnrollmentFormData, InputVariable<CreateEnrollmentFormInput>> =>
  useMutation<CreateEnrollmentFormData, InputVariable<CreateEnrollmentFormInput>>(Create_Enrollment_Form(), {
    ...options,
    onError: (error: ApolloError) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });
//endregion
