import React from 'react';
import CcsAbsenceCards from '../components/CcsAbsenceCards/CcsAbsenceCards';
import Card from 'shared/components/Card';
import BSCard from 'react-bootstrap/Card';
import { useGetCcsAbsencesCategoriesForCenters } from 'gql/ccssAbsences/queries';

const AbsenceWidget: React.FC<{ businessId: string; centerIds: string[] }> = ({ businessId, centerIds }) => {
  const { data, loading } = useGetCcsAbsencesCategoriesForCenters({
    variables: {
      input: {
        businessId: businessId,
        centerIds: centerIds,
      },
    },
  });

  return (
    <Card>
      <BSCard.Title>Absences</BSCard.Title>
      <CcsAbsenceCards data={data?.getCcsAbsencesCategoriesForCenter} loading={loading} />
    </Card>
  );
};

export default AbsenceWidget;
