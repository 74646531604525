import React, { useEffect, useState } from 'react';
import AddressInput from 'shared/components/AddressInput/AddressInputWithAutoComplete';
import { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { ICustomFieldProps } from '../CustomField';
const newAddress: IAddress = {
  address1: '',
  city: '',
  state: '',
  postalCode: '',
  country: DEFAULT_COUNTRY,
};

const Address: React.FC<ICustomFieldProps> = ({ customField, value, disabled, isReadOnly, onChange }) => {
  const [address, setAddress] = useState(newAddress);

  const handleChange = (address: IAddress) => {
    onChange(JSON.stringify(address), customField.id);
  };
  useEffect(() => {
    // parse and set address
    if (value !== null && typeof value === 'string') {
      try {
        const parsedAddress: IAddress = JSON.parse(value);
        setAddress(parsedAddress);
      } catch {}
    }
  }, [value]);

  return (
    <div>
      <h6>{customField.label}</h6>
      <AddressInput onChange={handleChange} showHelperText={false} address={address} />
      <hr />
    </div>
  );
};
export default Address;
