import React, { useCallback } from 'react';
import {
  Application,
  ApplicationFlowType,
  ApplicationScheduleOfferState,
  ApplicationState,
  Maybe,
} from 'generated/graphql';
import { Col, Row } from 'shared/components/Layout';
import { ButtonAsLink } from 'shared/components/Buttons';
import { useLeadsContext } from '../../LeadsContext';
import { ApplicationStage } from 'generated/graphql';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import colors from '_colors.module.scss';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import ApplicationNotesButton from './ApplicationNotes/ApplicationNotesButton';

interface IProps {
  application: Application;
  showStatus: boolean;
  useAdvancedApplicationEditFeature: boolean;
}

export default ({ application, showStatus, useAdvancedApplicationEditFeature }: IProps) => {
  const { t } = useTranslation();

  const {
    setEditApplicationModalState,
    setSelectedApplication,
    hasEditEnrollmentLeadManagementPermissions,
    activeTab,
    applicationFlowType,
  } = useLeadsContext();

  const handleEditApplication = useCallback(
    (application: Application) => {
      setSelectedApplication(application);
      setEditApplicationModalState({
        isOpen: true,
        editLevel: useAdvancedApplicationEditFeature ? 'advanced' : 'basic',
      });
    },
    [setSelectedApplication, setEditApplicationModalState, useAdvancedApplicationEditFeature]
  );

  const getStatus = (flowType?: Maybe<ApplicationFlowType>) => {
    const isAllProgramOnWatilist = application.children
      .flatMap((c) => c.offers)
      .every((o) => o?.state === ApplicationScheduleOfferState.OfferedWaitlist);

    if (flowType == ApplicationFlowType.InquireProgramFormPayApprove) {
      switch (application?.state) {
        case ApplicationState.Offered:
          if (isAllProgramOnWatilist) {
            return { text: 'Waitlisted, Awaiting Form', color: colors.danger };
          }
          return { text: 'Offered, Awaiting Form', color: colors.danger };
        case ApplicationState.OfferedPendingAcceptance:
          if (isAllProgramOnWatilist) {
            return { text: 'Waitlisted, Awaiting Acceptance', color: colors.danger };
          }
          return { text: 'Offered, Awaiting Acceptance', color: colors.danger };

        case ApplicationState.AcceptedOfferPendingPayment:
          return { text: 'Accepted, Awaiting Payment', color: colors.danger };
      }
      return { text: 'Offered', color: colors.danger };
    }

    switch (application?.state) {
      case ApplicationState.ApplicantAcceptedOffer:
      case ApplicationState.Accepted:
        return { text: 'Accepted, Awaiting Form', color: colors.success };
      case ApplicationState.OnWaitlist:
        return { text: 'Waitlist Offered', color: colors.info };
      case ApplicationState.AcceptedOfferPendingPayment:
        return { text: 'Accepted, Awaiting Payment', color: colors.danger };
    }
    return { text: 'Awaiting Acceptance', color: colors.danger };
  };

  const renderStatusPill = (flowType?: Maybe<ApplicationFlowType>) => {
    const status = getStatus(flowType);
    return (
      <div className="d-flex justify-content-end">
        <ColoredBackgroundTag color={status.color} text={status.text} className="rounded-tag" variant="pill" as="div" />
      </div>
    );
  };

  return (
    <Row className="lead-contact-details">
      <Col md={9} lg={10}>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-wrap mr-2">
            <span className="font-weight-bold mr-2">Guardian Information:</span>
            <span>
              {application.firstName} {application.lastName}
            </span>
          </div>
          <div className="d-flex flex-wrap">
            <span className="font-weight-bold mr-2">Phone Number:</span>
            <a href={`tel:${application.phoneNumber}`} className="mr-2 d-block">
              {application.phoneNumber}
            </a>
          </div>
          <div className="d-flex flex-wrap">
            <span className="font-weight-bold mr-2">Email:</span>
            <a href={`mailto:${application.email}`} className="mr-2 d-block">
              {application.email}
            </a>
          </div>

          {/* for normal flow, the edit enquiry details button lives with the expandable enquiry details component.
          On all other tabs this is used to edit only the guardian info*/}
          {hasEditEnrollmentLeadManagementPermissions &&
            activeTab !== ApplicationStage.Waitlisted &&
            applicationFlowType === ApplicationFlowType.InquireOfferEnrollment && (
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faPenToSquare} className="mr-2" />
                <ButtonAsLink
                  disabled={activeTab === ApplicationStage.Completed || activeTab === ApplicationStage.Lost}
                  variant="outline-secondary"
                  onClick={() => handleEditApplication(application)}
                  className="mr-2"
                >
                  {useAdvancedApplicationEditFeature
                    ? `Edit ${capitalize(t('spelling.inquiry'))} Details`
                    : 'Edit Guardian Info'}
                </ButtonAsLink>
              </div>
            )}
          <ApplicationNotesButton applicationId={application.id} />
        </div>
      </Col>
      <Col md={3} lg={2}>
        {showStatus && renderStatusPill(application.appliedFlowType)}
      </Col>
    </Row>
  );
};
