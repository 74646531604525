import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { RootState } from 'store/reducers';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';
import ChildrenTab from 'pages/USSubsidies/SubsidyChildren/ChildrenTab';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';

interface IRouteParams {}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const SubsidyChildren: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['subsidies']);
  const user = useSelector((state: RootState) => state.user);
  const activeBusinessId = useSelector((state: RootState) => state.context.businessId);

  return (
    <PageWrapper pageTitle={t('subsidies:children.page-title')} applyPadding={false}>
      {user?.isInternal && <CenterSelectBanner pageName="subsidies" showCenterSelect={false} />}
      <PageWrapperBody>
        <Tabs id="subsidy-children-tabs" defaultActiveKey="overview">
          <Tab title={t('subsidies:children.child-tab')} eventKey="overview">
            <ChildrenTab />
          </Tab>
        </Tabs>
      </PageWrapperBody>
    </PageWrapper>
  );
};

export default SubsidyChildren;
