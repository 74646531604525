import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import { Field, SectionType } from 'shared/types/enrollment-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import Checkbox from 'shared/components/Checkbox';
import Button from 'shared/components/Buttons';
import { Form } from 'react-bootstrap';
import { CustomFieldType } from 'generated/graphql';
import { EnrolmentFormDetailContext } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/enrollment-form-detail';
import TextInput from 'shared/components/TextInput';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import FileUploadDropbox from 'shared/components/FileOperations/FileUploadDropbox';
import AddressInput from 'shared/components/AddressInput';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateFieldValidationState } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/duck/actions';
import { showConfirm } from 'shared/components/ConfirmationContainer';
import HtmlText from 'shared/components/CustomField/HtmlText';
import { useFieldMetadata } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/hooks/useFieldMetadata';
import { parseOptions } from 'shared/components/CustomField/utils';
import { Option } from 'react-select/src/filters';
import { faArrowsUpDownLeftRight } from 'shared/constants/customIcons';
import colors from '_colors.module.scss';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { checkIsSystemRequiredField } from '../../../utils';
import { validate as uuidValidate } from 'uuid';
import Select from 'shared/components/Select';
import DateInput from 'shared/components/DateInput';

export type KtFieldProps = {
  field: Field;
  sectionType?: SectionType;
  onFieldChanged: (field: Field | null) => void;
  draggableProvidedDragHandleProps?: DraggableProvidedDragHandleProps;
};

export default function KtField({
  field,
  onFieldChanged,
  sectionType,
  draggableProvidedDragHandleProps,
}: KtFieldProps) {
  const enrolmentFormDetailContextShape = useContext(EnrolmentFormDetailContext);
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation(['enrollment']);

  const dispatch = useDispatch();

  const { fieldMetadata, loading } = useFieldMetadata(enrolmentFormDetailContextShape.businessId, field.name);

  const options = useMemo(() => {
    if (fieldMetadata == null) {
      return [];
    }

    if (['Combo', 'Radio'].includes(fieldMetadata!.type)) {
      return fieldMetadata!.attributesAsString != null ? parseOptions(fieldMetadata!.attributesAsString!) : [];
    }

    return [] as string[];
  }, [fieldMetadata]);

  const customFieldPredefinedValue = useMemo(() => {
    if (fieldMetadata?.attributesAsString) {
      return JSON.parse(fieldMetadata?.attributesAsString ?? '');
    }
    return '';
  }, [fieldMetadata?.attributesAsString]);

  const isFieldValidForSelectedCentres = useMemo(() => {
    if (fieldMetadata?.centerIds?.length === 0 || !uuidValidate(fieldMetadata?.id ?? '')) {
      // the custom field is applied to all centres or it is a system field
      return true;
    }

    return (
      enrolmentFormDetailContextShape.usedByCenterIds?.every((cid) => fieldMetadata?.centerIds?.indexOf(cid) !== -1) ??
      false
    );
  }, [fieldMetadata, enrolmentFormDetailContextShape]);

  const isFieldArchived = useMemo(() => {
    return Boolean(fieldMetadata?.archivedAt) && Boolean(fieldMetadata?.archivedBy);
  }, [fieldMetadata]);

  useEffect(() => {
    dispatch(
      updateFieldValidationState(enrolmentFormDetailContextShape.enrolmentFormId, {
        fieldName: field.name,
        isValid: isFieldValidForSelectedCentres,
        isArchived: isFieldArchived,
      })
    );
  }, [
    dispatch,
    isFieldValidForSelectedCentres,
    enrolmentFormDetailContextShape.enrolmentFormId,
    field.name,
    isFieldArchived,
  ]);

  const handleRemove = async () => {
    onFieldChanged(null);
    dispatch(
      updateFieldValidationState(enrolmentFormDetailContextShape.enrolmentFormId, {
        fieldName: field.name,
        isValid: undefined,
        isArchived: undefined,
      })
    );
  };

  const isSystemRequiredField = React.useMemo(
    () => checkIsSystemRequiredField(field.name, sectionType ?? ''),
    [field.name, sectionType]
  );

  const helpText =
    field.type === CustomFieldType.GroupedFields
      ? undefined
      : t('enrollment:form.kt-field.links-to', {
          fieldType: fieldMetadata?.type ?? '',
          fieldName: fieldMetadata?.label ?? '',
        });

  const getFieldDemonstration = useCallback(
    (fieldType: CustomFieldType) => {
      switch (fieldType) {
        case CustomFieldType.Text:
          return (
            <div>
              <small>{t('enrollment:form.kt-field.messages.text')} </small>
              <div className="kt-field-metadata">
                <TextInput
                  type="text"
                  placeholder="Parent type to answer"
                  value=""
                  className="mb-0"
                  onChange={() => {}}
                  disabled
                />
              </div>
            </div>
          );

        case CustomFieldType.Combo:
        case CustomFieldType.MultiSelectCombo:
          return (
            <div>
              <small>{t('enrollment:form.kt-field.messages.combo')} </small>
              <div className="kt-field-metadata">
                {options.map((o) => {
                  if (typeof o === 'string') return <Form.Check disabled type={'radio'} label={o} />;
                  else {
                    return <Form.Check disabled type={'radio'} label={(o as Option).label} />;
                  }
                })}
              </div>
            </div>
          );

        case CustomFieldType.Checkbox:
          return (
            <div>
              <small>{t('enrollment:form.kt-field.messages.checkbox')} </small>
              <div className="kt-field-metadata">
                <Checkbox label={fieldMetadata!.label} onChange={() => {}} disabled />
              </div>
            </div>
          );

        case CustomFieldType.Radio:
          return (
            <div>
              <small>{t('enrollment:form.kt-field.messages.radio')} </small>
              <div className="kt-field-metadata">
                {options.map((o) => {
                  if (typeof o === 'object') {
                    return <Form.Check disabled type={'radio'} label={o.label} />;
                  } else {
                    return <Form.Check disabled type={'radio'} label={o} />;
                  }
                })}
              </div>
            </div>
          );

        case CustomFieldType.Document:
          return (
            <div>
              <small>{t('enrollment:form.kt-field.messages.document')} </small>
              <div className="kt-field-metadata" style={{ pointerEvents: 'none' }}>
                <FileUploadDropbox
                  multiple={false}
                  onFileAdd={() => {}}
                  setUploadedFiles={() => {}}
                  withProgress={false}
                />
              </div>
            </div>
          );

        case CustomFieldType.Address:
          return (
            <div>
              <small>{t('enrollment:form.kt-field.messages.address')} </small>
              <div className="kt-field-metadata">
                <AddressInput
                  onChange={() => {}}
                  errorText=""
                  addressLabel={fieldMetadata!.label}
                  disabled={true}
                  required={false}
                />
              </div>
            </div>
          );

        case CustomFieldType.Signature:
          return (
            <div>
              <small>{t('enrollment:form.kt-field.messages.signature')}</small>
              <div
                className="signature-container"
                style={{ position: 'relative', width: '70%', height: '150px', color: '#8e9bac' }}
              >
                <div className="rectangle" style={{ height: '100%', border: '1px solid #8e9bac' }}></div>
                <div
                  className="clearButton"
                  style={{ position: 'absolute', bottom: '10px', right: '10px', textDecoration: 'underline' }}
                >
                  Clear
                </div>
              </div>
            </div>
          );

        case CustomFieldType.HtmlText:
          return (
            <div>
              <small>{t('enrollment:form.kt-field.messages.html-text')} </small>
              <div className="kt-field-metadata">
                <HtmlText
                  value={field.text ?? customFieldPredefinedValue} // default to the original text value
                  onChange={(value) => {
                    onFieldChanged({ ...field, text: value });
                  }}
                  // most in the following fields ain't required, only the label and id need to be the true values
                  customField={{
                    businessId: enrolmentFormDetailContextShape.businessId,
                    displayOrder: 0,
                    notForConsoleDisplay: false,
                    label: fieldMetadata?.label!,
                    centerIds: fieldMetadata!.centerIds!,
                    id: fieldMetadata!.id,
                    area: '',
                    type: fieldMetadata!.type as CustomFieldType,
                    attributesAsString: fieldMetadata?.attributesAsString ?? '',
                  }}
                />
              </div>
              <small>{t('enrollment:form.kt-field.messages.rendered-html')} </small>
              <div className="kt-field-metadata" dangerouslySetInnerHTML={{ __html: field.text ?? '' }} />
            </div>
          );

        case CustomFieldType.Date:
          return (
            <div>
              <small>{t('enrollment:form.kt-field.messages.date')} </small>
              <div className="kt-field-metadata">
                <DateInput disabled date={null} className="flex-grow-0" dateOnly placeholder={'Select date'} />
              </div>
            </div>
          );
        case CustomFieldType.ContactSelector:
          return (
            <div>
              <small>{t('enrollment:form.kt-field.messages.contact')} </small>
              <div className="kt-field-metadata">
                <Select placeholder="Select primary/secondary contact" options={[]} onChange={() => {}} />
              </div>
            </div>
          );
        default:
          return null;
      }
    },
    [onFieldChanged, field, fieldMetadata, options, t]
  );

  if (loading) return <LoadingLines />;

  return (
    <>
      {!isFieldValidForSelectedCentres && (
        <small className="text-danger mb-1">{t('enrollment:form.errors.invalid-field')}</small>
      )}
      {isFieldArchived && <small className="text-danger mb-1">{t('enrollment:form.errors.archived-field')}</small>}
      <Row justify="between" align="start" noGutters className="pb-1">
        <Col xs="auto">
          <div {...(draggableProvidedDragHandleProps ?? {})}>
            <FontAwesomeIcon
              size="1x"
              icon={faArrowsUpDownLeftRight}
              color={colors['dark-gray']}
              title={t('enrollment:form.drag-n-drop-question')}
              className="mr-2"
            />
          </div>
        </Col>
        <Col md={8}>
          <div className="kt-field-label font-weight-bold d-flex align-items-center">
            <TextInput
              helpTooltipText={helpText}
              type="text"
              label={t('enrollment:form.kt-field.edit-question-label')}
              placeholder={fieldMetadata?.label ?? ''}
              value={field.label}
              disabled={field.type === CustomFieldType.GroupedFields}
              className="mb-0"
              onChange={(value) => {
                onFieldChanged({ ...field, label: value });
              }}
            />
            {fieldMetadata?.type !== CustomFieldType.HtmlText && fieldMetadata?.type !== CustomFieldType.GroupedFields && (
              <Button variant="default" onClick={() => setShowDetails((prev) => !prev)} className="m-0 p-0">
                <FontAwesomeIcon
                  size="2x"
                  icon={showDetails ? faAngleUp : faAngleDown}
                  className="ml-3 mt-4"
                  title={
                    showDetails
                      ? t('enrollment:form.question-hide-details')
                      : t('enrollment:form.question-show-details')
                  }
                />
              </Button>
            )}
          </div>
          {showDetails &&
            fieldMetadata?.type !== CustomFieldType.HtmlText &&
            getFieldDemonstration(fieldMetadata?.type as CustomFieldType)}
          {/* HTML text always show details */}
          {fieldMetadata?.type === CustomFieldType.HtmlText &&
            getFieldDemonstration(fieldMetadata!.type as CustomFieldType)}
        </Col>
        <Col>
          <div className="d-flex justify-content-end  align-items-center">
            {field.type !== CustomFieldType.HtmlText && // remove the checkbox for HtmlText because it makes no sense to have it required
              field.type !== CustomFieldType.GroupedFields && ( // GroupedFields are managed by the backend
                <Checkbox
                  disabled={isSystemRequiredField}
                  label="Required"
                  value={field.isMandatory ?? false}
                  onChange={(value) => {
                    onFieldChanged({ ...field, isMandatory: value });
                  }}
                  className="d-flex align-items-center"
                />
              )}
            {!isSystemRequiredField && (
              <Button
                variant="link"
                onClick={() =>
                  showConfirm({
                    title: t('enrollment:form.remove-question-modal.title'),
                    message: t('enrollment:form.remove-question-modal.message'),
                    onConfirm: handleRemove,
                  })
                }
              >
                <FontAwesomeIcon size="1x" icon={faTrashAlt} />
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}
