import React, { useMemo, useCallback } from 'react';
import DataTable, { SizePerPage, TableHeader, BulkActions } from 'shared/components/DataTable';
import FilterGroup from './FilterGroup';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import { faTag } from '@fortawesome/pro-solid-svg-icons';
import uniq from 'lodash/uniq';
import cast from 'shared/util/cast';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { staffStatusColorHexes } from 'shared/constants/tagColors';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import { NetworkStatus } from '@apollo/client';
import { ISearchStaffData } from '../../graphql/queries';
import ActionDropdown from 'shared/components/ActionDropdown';
import staticText from 'shared/constants/staticText';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { getFullName, getInitials } from 'shared/util/string';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers';
import { sortBy } from 'lodash';

interface IProps {
  handleRowClick: (event: React.SyntheticEvent, rowData: IStaff) => void;
  handleActivateStaff: (center: IStaff) => void;
  handleDeactivateStaff: (center: IStaff) => void;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  data: ISearchStaffData | undefined;
  loading: boolean;
  networkStatus: NetworkStatus;
}

const StaffProfilesTable: React.FC<IProps> = ({
  handleRowClick,
  handleActivateStaff,
  handleDeactivateStaff,
  tableState,
  tableFunctions,
  data,
  loading,
  networkStatus,
}) => {
  const centers = useGetActiveCenters();
  const tags: ITag[] =
    useGetTagsInUse(TagsTypeElasticIndex.Person)?.data?.getTagsUsedAcrossEntity?.filter(
      (tag) => tag.category === 'STAFF'
    ) || [];
  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name'])
    .map((tag) => ({
      label: tag.name,
      value: tag.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;
  const canEditStaffEmployment =
    !AdpEnabled &&
    useHasRoleAreaLevel({
      area: AreaType.Staff,
      permission: PermissionType.Employment,
      level: RoleLevelType.Edit,
    });

  const tableActionsForRow = useCallback(
    (cell: any, row: IStaff) => {
      const actions: IDropdownAction[] = [];
      actions.push({
        label: 'Edit',
        onClick: () => {
          handleRowClick(cell, row);
        },
      });
      if (row.employmentStatus === 'Deactivated' && canEditStaffEmployment) {
        actions.push({
          label: 'Reactivate',
          onClick: () => {
            handleActivateStaff(row);
          },
        });
      }
      if (row.employmentStatus === 'Active' && canEditStaffEmployment) {
        actions.push({
          label: 'Deactivate',
          onClick: () => {
            handleDeactivateStaff(row);
          },
        });
      }
      return actions;
    },
    [canEditStaffEmployment, handleRowClick, handleActivateStaff, handleDeactivateStaff]
  );

  const bulkActions = useMemo(
    () => [
      {
        icon: faTag,
        tooltip: 'Add Tags',
        onClick: () => console.log('bulk add tags'), // TODO
      },
    ],
    []
  );

  // TODO: will need to show the empty state data card if no people are returned from the initial query

  const tableData: IStaff[] = data ? data.searchStaff.data : [];
  const formattedTabledData = tableData.map((person) => ({
    ...person,
    address: { ...person.address, state: { keyword: person.address?.state?.split('-')[1] } },
    centers: uniq(
      !person.roleship
        ? ['']
        : person.roleship.scopeType === 'CENTER'
        ? cast<ICenter[]>(person.roleship.scopes).map((scope: ICenter) => scope.name)
        : ['All']
    ),
  }));

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <DataTable
      noPadding={true}
      data={formattedTabledData}
      handleRowClick={handleRowClick}
      dataSize={data ? data.searchStaff.totalResults : 0}
      pageSize={tableState.pageSize}
      showLoadingOverlay={loading}
      columns={[
        {
          text: 'Name',
          dataField: 'lastname.keyword',
          sort: true,
          formatter: (cell: string, row: IStaff) => (
            <AvatarDataTableCell
              initials={getInitials(row)}
              avatar={row.avatar && row.avatar.url}
              primaryText={getFullName(row)}
              secondaryText={row.verifiedEmail}
            />
          ),
        },
        {
          text: 'Role',
          dataField: 'roleship.roleId.keyword',
          sort: true,
          formatter: (cell: any, row: IStaff) => row.roleship?.name,
        },
        {
          text: fieldLabels.center,
          dataField: 'primaryCenterName.keyword',
          sort: true,
          formatter: (cell: any, row: IStaff) => row.primaryCenter?.name ?? '',
        },
        { text: 'State', dataField: 'address.state.keyword', sort: true },
        {
          text: 'Status',
          dataField: 'employmentStatus',
          sort: true,
          formatter: (cell: any, row: IStaff) => (
            <div className="d-flex">
              <ColoredBackgroundTag
                color={staffStatusColorHexes[row.employmentStatus]}
                text={`${row.employmentStatus} ${!row.employmentStartDate ? '(No Start Date)' : ''}`}
                tooltipDirection={'top'}
                tooltipText={staticText.employmentStatusHelper[row.employmentStatus]}
              />
            </div>
          ),
        },
        {
          text: 'Actions',
          dataField: '',
          align: 'center',
          headerClasses: 'text-center',
          formatter: (cell: any, row: IStaff) => <ActionDropdown actions={tableActionsForRow(cell, row)} />,
        },
      ]}
      renderHeader={(paginationProps: any) => (
        <TableHeader className="flex-wrap">
          {tableState.selectedRows.length ? (
            <BulkActions bulkActions={bulkActions} />
          ) : (
            <>
              <SizePerPage paginationProps={paginationProps} />
              <FilterGroup
                tableState={tableState}
                tableFunctions={tableFunctions}
                tags={tagOptions}
                centers={centers}
              />
            </>
          )}
        </TableHeader>
      )}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      activePage={tableState.activePage}
      selectedRows={tableState.selectedRows}
      updateSelectedRows={tableFunctions.updateSelectedRows}
      onSort={tableFunctions.updateSort}
    />
  );
};

export default StaffProfilesTable;
