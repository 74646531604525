import { useRemoveDiscountFromTransaction } from 'gql/transaction/mutations';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { removeAppliedDiscountToTransaction } from '../duck/action';

interface IProps {
  isOpen: boolean;
  transaction: ITransaction;
  discountTransaction: ITransaction;
  /**
   * Is called when the modal wants to close. Either by hitting cancel, the x button, or having finished saving.
   * @returns
   */
  onClose: () => void;
  /**
   * Is called when the Discount has been removed from the transaction
   * @returns
   */
  onComplete?: (discountedTransactionId: string) => void;
}

const RemoveDiscountFromTransactionModal: React.FC<IProps> = ({
  isOpen,
  transaction,
  discountTransaction,
  onClose,
  onComplete = () => {},
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['billing']);
  const [removeDiscountFromTransactionFn, { loading: removeDiscountLoading }] = useRemoveDiscountFromTransaction({
    onCompleted: (result) => {
      showToast(t('billing:transactions.remove-discount-modal.success-toast-message'), 'success');
      dispatch(removeAppliedDiscountToTransaction(transaction.id, result.removeDiscountFromTransaction));
      onClose();
      onComplete(transaction.id);
    },
    onError: (_err) => {
      showToast(t('billing:transactions.remove-discount-modal.error-toast-message'), 'error');
    },
  });

  const handleSave = useCallback(() => {
    removeDiscountFromTransactionFn({
      variables: {
        input: {
          transactionId: transaction.id,
          discountTransactionId: discountTransaction.id,
        },
      },
    });
  }, [transaction, discountTransaction, removeDiscountFromTransactionFn]);

  return (
    <ConfirmationModal
      title={t('billing:transactions.remove-discount-modal.title')}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice={t('billing:transactions.remove-discount-modal.primary-button')}
      primaryCallback={handleSave}
      primaryButtonProps={{
        variant: 'danger',
        disabled: false,
        loading: removeDiscountLoading,
      }}
      secondaryChoice={t('billing:transactions.remove-discount-modal.secondary-button')}
      secondaryCallback={onClose}
    >
      <p>{t('billing:transactions.remove-discount-modal.body-text')}</p>
    </ConfirmationModal>
  );
};

export default RemoveDiscountFromTransactionModal;
