export const weeklySubsidyFields = `
  sessionReportSynchronisationId
  sessionReportSynchronisationErrorDetails
  centerId
  centerName
  accountId
  accountName
  childId
  childName
  weekEnding
  estimated
  paid
  status
  sessionIds
  subsidyEntitlements {
    sessionId
    enrolmentId
    eventId
    reason
    recipient
  }
  child {
    id
    firstname,
    lastname,
    nickname,
    fullName,
    avatar {
      url
    }
  }
  reasonForLateChange
`;

export const weeklySubsidyPagedResultsFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${weeklySubsidyFields}
  }
`;

export const weeklySubsidyCategoryFields = `
  UNSUBMITTED
  PENDING
  ERRORED
  FINALISED
`;
