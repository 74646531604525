export const royaltyFeeConfig = `
  id
  businessId
  includeTransactionDebit
  includeFees
`;

export const royaltyFee = `
  id
  businessId
  name
  percentage
  royaltyExceptions{
    id
    name
    overridePercentage
    royaltyFeeId
    participantCenterIds
  }
`;

export const royaltyFeeData = `
data{
  ${royaltyFee}
}`;

export const royaltyFeeException = `
  id
  name
  overridePercentage
  royaltyFeeId
  participantCenterIds
`;
