import React, { useContext, useState, useCallback } from 'react';
import useDatatableState, { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState2';
import { useGetGLCodeAssignments } from 'gql/glCode/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useUpdateGLCodeAssignments } from 'gql/glCode/mutations';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { useTranslation } from 'react-i18next';
import { useGetGLCodes } from 'gql/glCode/queries';

export type TabKey = 'list' | 'assignments' | 'settings';

type SubTabKey = Exclude<GLCodeAreaType, 'NOT_SET'>;

export interface IGLCodeContext {
  GLCodeTabKey: TabKey;
  setGLCodeTabKey: React.Dispatch<React.SetStateAction<TabKey>>;
  GLCodeAssignmentTableState: IDatatableState;
  GLCodeAssignmentTableFunction: IStateControls;
  activeSubTab: SubTabKey;
  setActiveSubTab: (key: SubTabKey) => void;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleSort: (field: string, direction: ElasticsearchSortDirection) => void;
  getGLCodeAssignmentsLoading: boolean;
  data?: IGLCodeAssignment[];
  updateGLCodeAssignmentsLoading: boolean;
  handleUpdateGLCodeAssignments: () => void;
  selectedGLCodeId: string | null;
  setSelectedGLCodeId: React.Dispatch<React.SetStateAction<string | null>>;
  glCodeListDataLoading: boolean;
  glCodeListData?: IGLCode[];
  hideMapped: boolean;
  setHideMapped: React.Dispatch<React.SetStateAction<boolean>>;
  totalRecords: number;
  refetchGlCode: () => void;
}

export const GLCodeContext = React.createContext<IGLCodeContext | null>(null);

export default ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['billing', 'translation']);
  const [activeTab, setActiveTab] = useState<TabKey>('list');
  const [activeSubTab, setActiveSubTab] = useState<SubTabKey>('FEE');
  const [tableState, tableFn] = useDatatableState();
  const [hideMapped, setHideMapped] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? user?.entityId ?? '';
  const centerFilterIds = useSelector((state: RootState) => state.context.centerFilterIds);
  const [searchText, setSearchText] = useState('');
  const [sort, setSortOptions] = useState<ISearchSort[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const [glCodeListSort] = useState<ISearchSort[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const [selectedGLCodeId, setSelectedGLCodeId] = useState<string | null>(null);

  const {
    data: glCodeListData,
    loading: glCodeListDataLoading,
    refetch: refetchGlCode,
  } = useGetGLCodes({
    variables: {
      input: {
        businessId,
        pageNumber: 1,
        pageSize: 10000,
        sortDtos: glCodeListSort,
        areaType: activeSubTab,
      },
    },
  });

  const {
    data,
    loading: getGLCodeAssignmentsLoading,
    refetch,
  } = useGetGLCodeAssignments({
    variables: {
      input: {
        businessId,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        areaType: activeSubTab ?? undefined,
        sortDtos: sort,
        searchText: searchText === '' ? undefined : searchText,
        centerIds: centerFilterIds.length > 0 ? centerFilterIds : undefined,
        hideMapped,
      },
    },
    skip: activeTab !== 'assignments',
  });

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field, direction }]);
  };

  const [updateGLCodeAssignments, { loading: updateGLCodeAssignmentsLoading }] = useUpdateGLCodeAssignments({
    onCompleted: async () => {
      refetch();
      showToast(t('billing:gl-codes.edit-gl-code-assignments-success'), 'success');
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const handleUpdateGLCodeAssignments = () => {
    updateGLCodeAssignments({
      variables: {
        input: {
          businessId,
          glCodeMappingsInput: tableState.selectedRows.map((row: IGLCodeAssignment) => ({
            id: row.glCodeMappingId,
            glCodeId: selectedGLCodeId,
            entityGuid: row.entityId,
            surchargeType: row.surchargeType,
          })),
        },
      },
    }).then(() => {
      tableFn.updateSelectedRows([]);
    });
  };

  const setActiveSubTabFn = useCallback(
    (key: SubTabKey) => {
      setActiveSubTab(key);
      tableFn.updateSelectedRows([]);
      setSearchText('');
      setSelectedGLCodeId(null);
    },
    [setActiveSubTab, tableFn]
  );

  return (
    <GLCodeContext.Provider
      value={{
        GLCodeTabKey: activeTab,
        setGLCodeTabKey: setActiveTab,
        GLCodeAssignmentTableState: tableState,
        GLCodeAssignmentTableFunction: tableFn,
        activeSubTab,
        setActiveSubTab: setActiveSubTabFn,
        setSearchText,
        searchText,
        handleSort,
        getGLCodeAssignmentsLoading,
        data: data?.getGLCodeAssignments.data,
        updateGLCodeAssignmentsLoading,
        handleUpdateGLCodeAssignments,
        selectedGLCodeId,
        setSelectedGLCodeId,
        glCodeListDataLoading,
        glCodeListData: glCodeListData?.getGLCodes.data,
        hideMapped,
        setHideMapped,
        totalRecords: data?.getGLCodeAssignments.totalRecords ?? 0,
        refetchGlCode,
      }}
    >
      {children}
    </GLCodeContext.Provider>
  );
};

export const useGLCodeContext = () => useContext(GLCodeContext)!;
