import React from 'react';
import config from 'config';
import Auth from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import 'graphiql/graphiql.min.css';
import './_api.scss';
import LaunchDarklyFlag from '../../shared/components/LaunchDarklyFlag';
import { useFlags } from 'launchdarkly-react-client-sdk';
const graphiql = require('graphiql'); // Can't import due to TS 3.8.x features :-(

export const Api: React.FC = ({ ...props }) => {
  const { k2WebApi } = useFlags();

  async function graphQLFetcher(graphQLParams: any) {
    const session: CognitoUserSession = await Auth.currentSession();
    return fetch(config.api.core.uri, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
      },
      body: JSON.stringify(graphQLParams),
    }).then((response) => response.json());
  }

  return (
    <LaunchDarklyFlag show={k2WebApi}>
      <graphiql.GraphiQL fetcher={graphQLFetcher} />
    </LaunchDarklyFlag>
  );
};
