import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButtonCard } from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { Col, Row } from 'shared/components/Layout';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import Switch from 'shared/components/Switch';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import AccountInformation from './AccountInformationForm';
import AddChildToAccountModal from './AddChildToAccountModal';
import ArchiveChildOnAccountModal from './ArchiveChildOnAccountModal';
import CardLoadingSkeleton from './CardLoadingSkeleton';
import ChildCard from './ChildCard';
import RemoveChildOnAccountModal from './RemoveChildOnAccountModal';
import BalanceCard from './Tabs/BillingTab/OverviewTab/BalanceCard';
import TagsForm from './TagsForm';
import UnarchiveChildOnAccountModal from './UnarchiveChildOnAccountModal';

interface IProps {
  account: IAccount;
  loading: boolean;
  showArchivedChildren: boolean;
  setShowArchivedChilden: (value: boolean) => void;
}

const ProfileTab: React.FC<IProps> = ({ account, loading, showArchivedChildren, setShowArchivedChilden }) => {
  const history = useHistory();
  const hasEditAccountPermission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const canEditAccountProfile = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const canEditAccountTags = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Tags,
    level: RoleLevelType.Edit,
  });

  const { k2AccountTags } = useFlags();

  const [showAddChildToAccountModal, setShowAddChildToAccountModal] = useState(false);
  const [childToArchive, setChildToArchive] = useState<IAccountChild | null>(null);
  const [childToRemove, setChildToRemove] = useState<IAccountChild | null>(null);
  const [childToUnarchive, setChildToUnarchive] = useState<IAccountChild | null>(null);
  return (
    <div>
      <Row align="start">
        <Col sm={12} md={6}>
          <Card header="Account Information" loading={loading} loadingLines={7}>
            {account && <AccountInformation account={account} readOnly={!hasEditAccountPermission} />}
          </Card>

          {k2AccountTags && (
            <HasRoleAreaLevel
              action={{ area: AreaType.Account, permission: PermissionType.Tags, level: RoleLevelType.Read }}
            >
              <Card header="Tags">
                {loading || !account ? (
                  <LoadingLines />
                ) : (
                  <TagsForm account={account} canPerformEdit={canEditAccountTags} />
                )}
              </Card>
            </HasRoleAreaLevel>
          )}
        </Col>
        <Col>
          <HasRoleAreaLevel
            action={{ area: AreaType.Billing, permission: PermissionType.Base, level: RoleLevelType.Read }}
          >
            <BalanceCard
              className="d-flex flex-grow-0"
              loading={loading}
              nextDueDate={account?.billingCycle?.nextPaymentDate ?? ''}
              balance={account?.balance ?? 0}
              pendingBalance={account?.pendingBalance ?? 0}
            />
          </HasRoleAreaLevel>
        </Col>
      </Row>
      <HasRoleAreaLevel action={{ area: AreaType.Child, permission: PermissionType.Base, level: RoleLevelType.Read }}>
        <section id="account-children-section">
          <Row className="mb-4" noGutters>
            <h4 className="my-4">Children</h4>
            <Switch
              label="Show archived children"
              labelSide="left"
              value={showArchivedChildren}
              onChange={(value: boolean) => setShowArchivedChilden(value)}
              className="ml-auto"
            />
          </Row>
          {loading ? (
            <Row noGutters>
              {' '}
              {[1, 2].map((i) => (
                <CardLoadingSkeleton className="mb-4 mr-4" key={i} />
              ))}
            </Row>
          ) : (
            <Row align="stretch" className="flex-wrap  mb-4">
              {orderBy(account?.children ?? [], 'firstname')
                .filter((c) => showArchivedChildren || !c.archivedAt)
                .map((child, i) => (
                  <Col md={6} xl={4} key={i} className="mb-4">
                    <ChildCard
                      key={i}
                      child={child}
                      className="h-100  cursor-pointer raised-hover-animated"
                      onClick={() => history.push(`/families/children/${child.id}`)}
                      onArchive={() => setChildToArchive(child)}
                      onRemove={() => setChildToRemove(child)}
                      onUnarchive={() => setChildToUnarchive(child)}
                    />
                  </Col>
                ))}
              <HasRoleAreaLevel
                action={{ area: AreaType.Child, permission: PermissionType.Base, level: RoleLevelType.Create }}
              >
                <Col md={6} xl={4} className="mb-4">
                  <IconButtonCard
                    className="h-100 raised-hover-animated"
                    onClick={() => setShowAddChildToAccountModal(true)}
                    icon={faPlus}
                    label="Add Another Child"
                  />
                </Col>
              </HasRoleAreaLevel>
            </Row>
          )}
        </section>
      </HasRoleAreaLevel>
      {account && !loading && showAddChildToAccountModal && (
        <AddChildToAccountModal
          isOpen={showAddChildToAccountModal}
          onClose={() => setShowAddChildToAccountModal(false)}
          account={account}
        />
      )}
      {account && childToArchive && !loading && (
        <ArchiveChildOnAccountModal
          isOpen={Boolean(childToArchive)}
          onClose={() => setChildToArchive(null)}
          child={childToArchive}
          account={account}
        />
      )}
      {account && childToRemove && !loading && (
        <RemoveChildOnAccountModal
          isOpen={Boolean(childToRemove)}
          onClose={() => setChildToRemove(null)}
          child={childToRemove}
          account={account}
        />
      )}
      {account && childToUnarchive && !loading && (
        <UnarchiveChildOnAccountModal
          isOpen={Boolean(childToUnarchive)}
          onClose={() => setChildToUnarchive(null)}
          child={childToUnarchive}
          account={account}
        />
      )}
    </div>
  );
};

export default ProfileTab;
