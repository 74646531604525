import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import { staffStatusColorHexes } from 'shared/constants/tagColors';

const { ALL, ANY, TERM } = SEARCH_EXPRESSIONS;
const { DOES_NOT_EXIST, EXISTS, BEFORE_NOW, AFTER_NOW } = PREDICATES;

export const created = {
  [ALL]: [
    { [TERM]: { field: 'cognitoConfiguredAt', predicate: DOES_NOT_EXIST } },
    { [TERM]: { field: 'invitedAt', predicate: DOES_NOT_EXIST } },
    { [TERM]: { field: 'roleship', predicate: EXISTS } },
    {
      [ANY]: [
        { [TERM]: { field: 'employmentEndDate', predicate: DOES_NOT_EXIST } },
        { [TERM]: { field: 'employmentEndDate', predicate: AFTER_NOW } },
      ],
    },
  ],
};

export const pending = {
  [ALL]: [
    { [TERM]: { field: 'cognitoConfiguredAt', predicate: EXISTS } },
    {
      [ANY]: [
        { [TERM]: { field: 'employmentStartDate', predicate: DOES_NOT_EXIST } },
        { [TERM]: { field: 'employmentStartDate', predicate: AFTER_NOW } },
      ],
    },
  ],
};

export const active = {
  [ALL]: [
    { [TERM]: { field: 'cognitoConfiguredAt', predicate: EXISTS } },
    { [TERM]: { field: 'employmentStartDate', predicate: BEFORE_NOW } },
    {
      [ANY]: [
        { [TERM]: { field: 'employmentEndDate', predicate: DOES_NOT_EXIST } },
        { [TERM]: { field: 'employmentEndDate', predicate: AFTER_NOW } },
      ],
    },
  ],
};

export const invited = {
  [ALL]: [
    { [TERM]: { field: 'invitedAt', predicate: EXISTS } },
    { [TERM]: { field: 'cognitoConfiguredAt', predicate: DOES_NOT_EXIST } },
    {
      [ANY]: [
        { [TERM]: { field: 'employmentEndDate', predicate: DOES_NOT_EXIST } },
        { [TERM]: { field: 'employmentEndDate', predicate: AFTER_NOW } },
      ],
    },
  ],
};

export const allInvited = {
  [ALL]: [{ [TERM]: { field: 'invitedAt', predicate: EXISTS } }],
};

export const deactivated = {
  [ALL]: [
    //employees can be terminated before setting up cognito
    //{ [TERM]: { field: 'cognitoConfiguredAt', predicate: EXISTS } },
    { [TERM]: { field: 'employmentEndDate', predicate: BEFORE_NOW } },
  ],
};

export const requested = { [TERM]: { field: 'roleship', predicate: DOES_NOT_EXIST } };

export const statusOptions: ITableFilterOption[] = [
  {
    label: 'Active',
    value: 'active',
    searchExpression: active,
    indicatorColor: staffStatusColorHexes.Active,
  },
  {
    label: 'Pending',
    value: 'pending',
    searchExpression: pending,
    indicatorColor: staffStatusColorHexes.Pending,
  },
  {
    label: 'Deactivated',
    value: 'deactivated',
    searchExpression: deactivated,
    indicatorColor: staffStatusColorHexes.Deactivated,
  },
];

export const statusOptionsTimeLog: ITableFilterOption[] = [
  {
    label: 'Active',
    value: 'active',
    searchExpression: active,
    indicatorColor: staffStatusColorHexes.Active,
  },
  {
    label: 'Pending',
    value: 'pending',
    searchExpression: pending,
    indicatorColor: staffStatusColorHexes.Pending,
  },
  {
    label: 'Deactivated',
    value: 'deactivated',
    searchExpression: deactivated,
    indicatorColor: staffStatusColorHexes.Deactivated,
  },
  {
    label: 'Invited',
    value: 'invited',
    searchExpression: invited,
    indicatorColor: staffStatusColorHexes.Invited,
  },
];

export const jsFilters: Record<string, (staff: IStaff) => Boolean> = {
  active: (staff: IStaff) =>
    Boolean(
      !!staff.cognitoConfiguredAt &&
        staff.employmentStartDate &&
        staff.employmentStartDate < new Date().toISOString() &&
        (!staff.employmentEndDate || staff.employmentEndDate > new Date().toISOString())
    ),
  pending: (staff: IStaff) =>
    !!staff.cognitoConfiguredAt && (!staff.employmentStartDate || staff.employmentStartDate > new Date().toISOString()),
  deactivated: (staff: IStaff) =>
    Boolean(
      !!staff.cognitoConfiguredAt && staff.employmentEndDate && staff.employmentEndDate < new Date().toISOString()
    ),
};
