import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { royaltyFee, royaltyFeeConfig, royaltyFeeException } from './fields';

interface IRoyaltyConfig {
  id: string;
  businessId: string;
  includeTransactionDebit: boolean;
  includeFees: boolean;
}

interface ICreateRoyaltyFeeInput {
  input: {
    businessId: string;
    name: string;
    percentage: number;
  };
}

interface IUpdateRoyaltyFeeInput {
  input: {
    royaltyFeeId: string;
    businessId: string;
    name: string;
    percentage: number;
  };
}

interface IUpsertRoyaltyFeeConfigInput {
  input: {
    businessId: string;
    includeTransactionDebit: boolean;
    includeFees: boolean;
  };
}
interface IRoyaltyFee {
  id: string;
  businessId: string;
  name: string;
  percentage: number;
}

interface ICreateRoyaltyFeeExceptionInput {
  input: {
    royaltyFeeId: string;
    businessId: string;
    name: string;
    overridePercentage: number;
    participantCenterIds: string[];
  };
}
interface IUpdateRoyaltyFeeExceptionInput {
  input: {
    royaltyFeeId: string;
    businessId: string;
    exceptionId: string;
    name: string;
    overridePercentage: number;
    participantCenterIds: string[];
  };
}

interface IRoyaltyFeeException {
  id: string;
  name: string;
  overridePercentage: number;
  royaltyFeeId: string;
  participantCenterIds: string[];
}

export const UPSERT_ROYALTY_CONFIG = gql`
  mutation($input: IUpsertRoyaltyFeeConfigInput!) {
    upsertRoyaltyConfig(input: $input){
       ${royaltyFeeConfig}
    }
  }
`;

export const CREATE_ROYALTY_FEE = gql`
  mutation($input: ICreateRoyaltyFeeInput!) {
    createRoyaltyFee(input: $input) {
      ${royaltyFee}
    }
  }
`;

export const UPDATE_ROYALTY_FEE = gql`
  mutation($input: IUpdateRoyaltyFeeInput!) {
    updateRoyaltyFee(input: $input) {
      ${royaltyFee}
    }
  }
`;

export const DELETE_ROYALTY_FEE = gql`
  mutation ($royaltyFeeId: ID!, $businessId: ID!) {
    deleteRoyaltyFee(royaltyFeeId: $royaltyFeeId, businessId: $businessId) {
      id
    }
  }
`;

export const CREATE_ROYALTY_FEE_EXCEPTION = gql`
  mutation($input: ICreateRoyaltyFeeExceptionInput!) {
    createRoyaltyFeeException(input: $input) {
      ${royaltyFeeException}
    }
  }
`;

export const UPDATE_ROYALTY_FEE_EXCEPTION = gql`
  mutation($input: IUpdateRoyaltyFeeExceptionInput!) {
    updateRoyaltyFeeException(input: $input) {
      ${royaltyFeeException}
    }
  }
`;

export const DELETE_ROYALTY_FEE_EXCEPTION = gql`
  mutation ($exceptionId: ID!, $royaltyFeeId: ID!, $businessId: ID!) {
    deleteRoyaltyFeeException(exceptionId: $exceptionId, royaltyFeeId: $royaltyFeeId, businessId: $businessId) {
      id
    }
  }
`;

export const useUpsertRoyaltyConfig = (input?: MutationHookOptions<IRoyaltyConfig, IUpsertRoyaltyFeeConfigInput>) =>
  useMutation<IRoyaltyConfig, IUpsertRoyaltyFeeConfigInput>(UPSERT_ROYALTY_CONFIG, input);

export const useCreateRoyaltyFee = (input?: MutationHookOptions<IRoyaltyFee, ICreateRoyaltyFeeInput>) =>
  useMutation<IRoyaltyFee, ICreateRoyaltyFeeInput>(CREATE_ROYALTY_FEE, input);

export const useUpdateRoyaltyFee = (input?: MutationHookOptions<IRoyaltyFee, IUpdateRoyaltyFeeInput>) =>
  useMutation<IRoyaltyFee, IUpdateRoyaltyFeeInput>(UPDATE_ROYALTY_FEE, input);

export const useDeleteRoyaltyFee = (
  input?: MutationHookOptions<{ deleteRoyaltyFee: IRoyaltyFee }, { royaltyFeeId: string; businessId: string }>
) =>
  useMutation<{ deleteRoyaltyFee: IRoyaltyFee }, { royaltyFeeId: string; businessId: string }>(
    DELETE_ROYALTY_FEE,
    input
  );

export const useCreateRoyaltyFeeException = (
  input?: MutationHookOptions<IRoyaltyFeeException, ICreateRoyaltyFeeExceptionInput>
) => useMutation<IRoyaltyFeeException, ICreateRoyaltyFeeExceptionInput>(CREATE_ROYALTY_FEE_EXCEPTION, input);

export const useUpdateRoyaltyFeeException = (
  input?: MutationHookOptions<IRoyaltyFeeException, IUpdateRoyaltyFeeExceptionInput>
) => useMutation<IRoyaltyFeeException, IUpdateRoyaltyFeeExceptionInput>(UPDATE_ROYALTY_FEE_EXCEPTION, input);

export const useDeleteRoyaltyFeeException = (
  input?: MutationHookOptions<IRoyaltyFeeException, { royaltyFeeId: string; businessId: string; exceptionId: string }>
) =>
  useMutation<IRoyaltyFeeException, { royaltyFeeId: string; businessId: string; exceptionId: string }>(
    DELETE_ROYALTY_FEE_EXCEPTION,
    input
  );
