import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ApplicationCenterSettingsModal from './UpdateApplicationSettingsModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import { capitalize, orderBy, startCase } from 'lodash';
import useDatatableState, { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState';
import { ApplicationFeeType, ApplicationFlowType, EnrollmentOptions } from 'generated/graphql';
import Card from 'shared/components/Card';
import { Row } from 'shared/components/Layout';
import DataTable, { SizePerPage, TableHeader } from 'shared/components/DataTable';
import FilterGroup from 'pages/Centers/subroutes/Profiles/components/CenterProfilesTable/FilterGroup';
import { STATES, US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { useGetCenterStatesInUse } from 'shared/hooks/useGetCenterStatesInUse';
import Select from 'shared/components/Select';
import applicationFeeTypes from 'shared/constants/dropdownOptions/applicationFeeTypes';
import { IFormattedApplicationCenterSetting } from './ApplicationCenterSettings';
import ApplicationFeeInput from 'pages/Enrollment/subroutes/Programs/components/CreateProgramModal/ApplicationFeeInput';
import _ from 'lodash';
import SelectedItemsTotal from 'pages/Enrollment/subroutes/Management/Tabs/ReEnrollment/Tabs/ReEnrollAccounts/components/SelectedItemsTotal';
import { showConfirm } from 'shared/components/ConfirmationContainer';
import { faFileCirclePlus } from '@fortawesome/pro-light-svg-icons';
import Button, { IconButtonCircle } from 'shared/components/Buttons';
import { useUpdateMultipleCentersSettings } from 'gql/applicationCenterSettings/mutation';
import { showToast } from 'shared/components/Toast';
import { ICenterSettingsInput } from 'gql/applicationCenterSettings/fields';
import { Table } from 'react-bootstrap';
import Currency from 'shared/components/Currency';
import { isProgramBasedFlow } from 'pages/Enrollment/subroutes/LeadManagement/utils';

interface IProps {
  flowType: ApplicationFlowType;
  tableData: IFormattedApplicationCenterSetting[];
  dataLoading?: boolean;
  totalResults: number;
  handleRefetch: () => void;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
}

const ApplicationCenterSettingsTable: React.FC<IProps> = ({
  flowType,
  dataLoading,
  tableData,
  totalResults,
  handleRefetch,
  tableState,
  tableFunctions,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const currentUser = useSelector((state: RootState) => state.user);
  const isInternalUser = currentUser?.isInternal;
  const [formattedTableData, setFormattedTableData] = useState<IFormattedApplicationCenterSetting[]>([]);
  const [changedCenters, setChangedCenters] = useState<IFormattedApplicationCenterSetting[]>([]);
  const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);
  const [showUpdateSettingsModal, setShowUpdateSettingsModal] = useState<boolean>(false);

  const { data: getAllowedEntitiesData } = useGetAllowedEntities(!isInternalUser);
  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];
  const tagsOptions: ITableFilterOption[] = orderBy(tags ?? [], (tag) => tag.name.toLocaleLowerCase(), 'asc').map(
    (tag) => ({ label: tag.name, value: tag.id })
  );

  const feeTypeOptions = applicationFeeTypes;
  const enrollmentOptionsFilterOptions: ITableFilterOption[] = useMemo(() => {
    return Object.values(EnrollmentOptions).map((source) => {
      return { label: t(`enrollment:enrollment-options.${source as EnrollmentOptions}`), value: source };
    });
  }, []);

  const subsidyQuestionOptions: { label: string; value: boolean }[] = useMemo(() => {
    return [
      { value: false, label: 'No' },
      { value: true, label: 'Yes' },
    ];
  }, []);

  const statesInUse: string[] = useGetCenterStatesInUse()?.data?.getCenterStatesUsedAcrossEntity || [];
  const states =
    !!statesInUse && statesInUse.length > 0
      ? US_STATE_SELECT_OPTIONS.filter((state) => statesInUse.includes(state.value))
      : US_STATE_SELECT_OPTIONS;

  const closeMenuOnScroll = (e: Event) => {
    let result: boolean;
    try {
      // @ts-ignore
      result = e.target.className.includes('overflow-scroll modal-body');
    } catch (error) {
      result = false;
    }
    return result;
  };

  const [updateMultipleCentersSettings, { loading: updateCenterSettingsLoading }] = useUpdateMultipleCentersSettings({
    onCompleted: (result) => {
      handleRefetch();
      showToast(t('enrollment:update-application-settings-modal.success-toast'), 'success');
    },
    onError: (error) => {
      showToast(capitalize(t('enrollment:update-application-settings-modal.error-toast', { error })), 'error');
    },
  });

  const handleEnrollmentOptionsChange = useCallback((centerId: string, value: EnrollmentOptions) => {
    setFormattedTableData((prev) => {
      return prev.map((c) => ({
        ...c,
        enrollmentOptions: centerId === c.id ? value : c.enrollmentOptions,
      }));
    });
  }, []);

  const handleApplicationFeeTypeSelect = useCallback((centerId: string, feeType?: ApplicationFeeType) => {
    setFormattedTableData((prev) => {
      return prev.map((c) => ({
        ...c,
        feeType: centerId === c.id ? feeType : c.feeType,
      }));
    });
  }, []);

  const handleApplicationFeeAmountChange = useCallback((centerId: string, applicationFeeAmount?: number) => {
    setFormattedTableData((prev) => {
      return prev.map((c) => ({
        ...c,
        feeAmount: centerId === c.id ? applicationFeeAmount ?? 0 : c.feeAmount,
      }));
    });
  }, []);

  const handleSubsidyQuestionToggle = useCallback((centerId: string, checked: boolean) => {
    setFormattedTableData((prev) => {
      return prev.map((c) => ({
        ...c,
        askFamilyAboutSubsidy: centerId === c.id ? checked ?? 0 : c.askFamilyAboutSubsidy,
      }));
    });
  }, []);

  const getColumns = useCallback(() => {
    const centerColumns = [
      {
        text: `${capitalize(t('translation:spelling.center'))} Name`,
        dataField: 'centerName',
        sort: true,
      },
      {
        text: 'State',
        dataField: 'address.state.keyword',
        sort: false,
      },
    ];

    const programFlowColumns = [
      {
        text: capitalize(t('enrollment:subsidy-question-table.table-fields.fee-type')),
        dataField: 'feeType',
        sort: false,
        formatter: (cell: string, row: IFormattedApplicationCenterSetting) => {
          const selectedApplicationFeeType = feeTypeOptions.find((f) => f.value === row.feeType);
          return (
            <Select
              options={feeTypeOptions}
              onChange={(v) => handleApplicationFeeTypeSelect(row.centerId, v.value)}
              value={selectedApplicationFeeType}
              isMulti={false}
              className="m-0"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
              closeMenuOnScroll={closeMenuOnScroll}
            />
          );
        },
      },
      {
        text: capitalize(t('enrollment:subsidy-question-table.table-fields.fee-amount')),
        dataField: 'feeAmmount',
        sort: false,
        formatter: (cell: number, row: IFormattedApplicationCenterSetting) => {
          return (
            <ApplicationFeeInput
              isRequired={!!row.feeType}
              value={row.feeAmount}
              setValue={(v) => handleApplicationFeeAmountChange(row.centerId, v)}
              disabled={!row.feeType}
            />
          );
        },
      },
      {
        text: capitalize(t('enrollment:subsidy-question-table.table-fields.subsidy')),
        dataField: 'askFamilyAboutSubsidy',
        sort: false,
        formatter: (cell: string, row: IFormattedApplicationCenterSetting) => {
          const selectedSubsidyQuestionValue = subsidyQuestionOptions.find(
            (f) => f.value === row.askFamilyAboutSubsidy
          );
          return (
            <Select
              options={subsidyQuestionOptions}
              onChange={(v) => handleSubsidyQuestionToggle(row.centerId, v.value)}
              value={selectedSubsidyQuestionValue}
              isMulti={false}
              className="m-0"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
              closeMenuOnScroll={closeMenuOnScroll}
            />
          );
        },
      },
    ];

    const basicFlowColumns = [
      {
        text: capitalize(t('enrollment:enrollment-options-title')),
        dataField: 'enrollmentOptions',
        sort: false,
        formatter: (cell: number, row: IFormattedApplicationCenterSetting) => {
          return (
            <Select
              options={enrollmentOptionsFilterOptions}
              onChange={(v) => handleEnrollmentOptionsChange(row.centerId, v.value)}
              value={row.enrollmentOptions as EnrollmentOptions}
              isMulti={false}
              className="m-0"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
              closeMenuOnScroll={true}
            />
          );
        },
      },
    ];

    return flowType === ApplicationFlowType.InquireOfferEnrollment
      ? [...centerColumns, ...basicFlowColumns]
      : [...centerColumns, ...programFlowColumns];
  }, [
    enrollmentOptionsFilterOptions,
    feeTypeOptions,
    flowType,
    handleApplicationFeeAmountChange,
    handleApplicationFeeTypeSelect,
    handleEnrollmentOptionsChange,
    handleSubsidyQuestionToggle,
    subsidyQuestionOptions,
    t,
  ]);

  const handleUnsavedChanges = useCallback(
    (runAfter: () => void) => {
      showConfirm({
        title: t('enrollment:unsaved-changes-modal.title', { totalChanges: changedCenters.length }),
        message: (
          <>
            <p>{t('enrollment:unsaved-changes-modal.body1')}</p>
            <p>{t('enrollment:unsaved-changes-modal.body2')}</p>
          </>
        ),
        primaryButtonLabel: t('enrollment:unsaved-changes-modal.continue'),
        onConfirm: async () => {
          setAllRowsSelected(false);
          tableFunctions.updateSelectedRows([]);
          runAfter();
        },
        primaryButtonVariant: 'primary',
      });
    },
    [changedCenters.length, t, tableFunctions]
  );

  const handleSaveMultiApplicationCenterSettings = useCallback(() => {
    const centerIds = changedCenters.map((c) => c.id);
    const centerSettingsList: ICenterSettingsInput[] = changedCenters.map((item) => {
      const centerSettingsInput: ICenterSettingsInput = {
        businessId: businessId ?? '',
        centerId: item.id,
        feeType: item.feeType ?? undefined,
        feeAmount: item.feeAmount ?? 0,
        askFamilyAboutSubsidy: item.askFamilyAboutSubsidy ?? undefined,
        enrollmentOptions: item.enrollmentOptions ?? undefined,
      };
      return centerSettingsInput;
    });
    updateMultipleCentersSettings({
      variables: { input: { businessId: businessId ?? '', centerIds, centerSettingsList } },
    });
  }, [businessId, changedCenters, updateMultipleCentersSettings]);

  const showUpdateSettingsConfirmModal = useCallback(() => {
    showConfirm({
      title: t('enrollment:save-changes-confirm-modal.title'),
      modalSize: 'xl',
      message: (
        <>
          <p>{t('enrollment:save-changes-confirm-modal.body1', { totalChanges: changedCenters.length })}</p>
          <Table borderless className="k2-create-enrol-program-confirmation-modal-table">
            <thead>
              <tr>
                <th>{capitalize(t('translation:spelling.center'))}</th>
                {flowType === ApplicationFlowType.InquireOfferEnrollment && (
                  <th>{startCase(t('translation:enrollment.programs.enrollment-options'))}</th>
                )}
                {isProgramBasedFlow(flowType) && (
                  <>
                    <th>{capitalize(t('enrollment:subsidy-question-table.table-fields.fee-type'))}</th>
                    <th>{capitalize(t('enrollment:subsidy-question-table.table-fields.fee-amount'))}</th>
                    <th>{capitalize(t('enrollment:subsidy-question-table.table-fields.subsidy'))}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {changedCenters.map((programCenter) => {
                const selectedApplicationFeeType = feeTypeOptions.find((f) => f.value === programCenter.feeType);
                return (
                  <tr key={programCenter.centerId}>
                    <td>{programCenter.centerName}</td>
                    {flowType === ApplicationFlowType.InquireOfferEnrollment && (
                      <td>
                        {t(`enrollment:enrollment-options.${programCenter.enrollmentOptions as EnrollmentOptions}`)}
                      </td>
                    )}
                    {isProgramBasedFlow(flowType) && (
                      <>
                        <td>{selectedApplicationFeeType?.label}</td>
                        <td>
                          <Currency amount={programCenter.feeAmount} />
                        </td>
                        <td>{programCenter.askFamilyAboutSubsidy ? 'Yes' : 'No'}</td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ),
      primaryButtonLabel: t('enrollment:save-changes-confirm-modal.save'),
      onConfirm: async () => {
        handleSaveMultiApplicationCenterSettings();
        tableFunctions.updateSelectedRows([]);
      },
      primaryButtonVariant: 'primary',
    });
  }, [changedCenters, feeTypeOptions, flowType, handleSaveMultiApplicationCenterSettings, t, tableFunctions]);

  useEffect(() => {
    if (tableData) setFormattedTableData(tableData);
    else setFormattedTableData([]);
  }, [tableData]);

  useEffect(() => {
    if (formattedTableData.length === tableData.length) {
      setChangedCenters(
        formattedTableData.filter(
          (c) =>
            !_.isEqual(
              tableData.find((t) => t.id === c.id),
              c
            )
        )
      );
    }
  }, [formattedTableData, tableData]);

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h3>{t('enrollment:center-settings')}</h3>
      </div>
      <Card>
        <DataTable
          data={formattedTableData ?? []}
          dataSize={totalResults}
          pageSize={tableState.pageSize}
          showLoadingOverlay={dataLoading}
          onPageChange={(page: number, sizePerPage: number) => {
            if (changedCenters.length > 0) {
              handleUnsavedChanges(() => tableFunctions.changePage(page, sizePerPage));
            } else {
              tableFunctions.changePage(page, sizePerPage);
            }
          }}
          activePage={tableState.activePage}
          showSelect={true}
          multiPageSelect
          selectedRows={allRowsSelected ? formattedTableData : tableState.selectedRows}
          updateSelectedRows={tableFunctions.updateSelectedRows}
          onSizePerPageChange={(sizePerPage: number) => {
            if (changedCenters.length > 0) {
              handleUnsavedChanges(() => tableFunctions.changeSizePerPage(sizePerPage));
            } else {
              if (tableState.activePage !== 1) tableFunctions.changePage(1, sizePerPage);
              tableFunctions.changeSizePerPage(sizePerPage);
            }
          }}
          columns={getColumns()}
          renderHeader={(paginationProps: any) => (
            <>
              <TableHeader className="d-flex flex-row align-items-center flex-wrap flex-grow-1">
                <SizePerPage paginationProps={paginationProps} />
                <FilterGroup
                  tableState={tableState}
                  tableFunctions={tableFunctions}
                  tags={tagsOptions}
                  states={states}
                  entities={getAllowedEntitiesData?.getAllowedEntities || []}
                  autoSelectedBusinessId={businessId ?? ''}
                  showUnsavedChangesWarning={changedCenters.length > 0}
                  totalChanges={changedCenters.length}
                  hideStatus
                />
              </TableHeader>
              <Row className="flex-nowrap justify-content-between px-4 py-2 m-0">
                {(tableState.selectedRows.length > 0 || allRowsSelected) && (
                  <IconButtonCircle
                    icon={faFileCirclePlus}
                    className="mr-2"
                    tooltipText={t('enrollment:update-center-settings')}
                    onClick={() => setShowUpdateSettingsModal(true)}
                  />
                )}
                <div className="d-flex flex-1 justify-content-end py-1">
                  <Button
                    className="ml-auto"
                    variant="primary"
                    loading={updateCenterSettingsLoading}
                    onClick={() => showUpdateSettingsConfirmModal()}
                    disabled={
                      tableData.filter(
                        (c) =>
                          !_.isEqual(
                            formattedTableData.find((t) => t.id === c.id),
                            c
                          )
                      ).length <= 0
                    }
                  >
                    {'Save'}
                  </Button>
                </div>
              </Row>
              {(tableState.selectedRows.length > 0 || allRowsSelected) && (
                <SelectedItemsTotal
                  centerSelect
                  totalRecords={totalResults ?? 0}
                  selectedRows={tableState.selectedRows.length}
                  allSelected={allRowsSelected}
                  handleClearSelectedRows={() => {
                    setAllRowsSelected(false);
                    tableFunctions.updateSelectedRows([]);
                  }}
                  handleSelectAllRows={() => {
                    setAllRowsSelected(true);
                    tableFunctions.updateSelectedRows([]);
                  }}
                  className="px-4 py-2 m-0"
                />
              )}
            </>
          )}
        />
      </Card>
      <ApplicationCenterSettingsModal
        flowType={flowType}
        isOpen={showUpdateSettingsModal}
        allCentersSelected={allRowsSelected}
        tableState={tableState}
        selectedCenters={allRowsSelected ? totalResults : tableState.selectedRows.length}
        onCancel={() => setShowUpdateSettingsModal(false)}
        onSave={() => handleRefetch()}
      />
    </>
  );
};

export default ApplicationCenterSettingsTable;
