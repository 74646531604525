import _, { capitalize } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'shared/components/Select';

interface IProps {
  options: ITableFilterOption[];
  selectedIds: string[] | null;
  useNullForAllOption?: boolean;
  onSelect: (Ids: string[] | null) => void;
  className?: string;
  isRequired?: boolean;
  disabled?: boolean;
  checkboxes?: boolean;
  helpTooltipText?: string;
  label?: string;
  placeholder?: string;
  selectAllLabel?: string;
  isLoading?: boolean;
  id?: string;
}

const SelectWithAllOption: React.FC<IProps> = ({
  options,
  selectedIds,
  useNullForAllOption,
  onSelect,
  className,
  isRequired = false,
  disabled = false,
  checkboxes = false,
  helpTooltipText,
  label,
  placeholder,
  selectAllLabel,
  isLoading,
  id,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'reporting']);

  const ALL_OPTIONS = {
    value: null,
    label: selectAllLabel ?? `Select All`,
  };

  const handleSelect = useCallback(
    (selected: ITableFilterOption[]) => {
      if (selected.some((o) => o.value === null)) {
        // we've selected all options
        onSelect(useNullForAllOption ? null : options.map((c) => c.value));
      } else {
        onSelect(selected.map((c) => c.value));
      }
    },
    [onSelect, options, useNullForAllOption]
  );

  return (
    <Select
      id={id}
      isMulti
      disabled={disabled}
      checkboxes={checkboxes}
      required={isRequired}
      isLoading={isLoading}
      className={`flex-wrap ${className}`}
      helpTooltipText={helpTooltipText}
      label={label}
      aria-label={`Custom Fields`}
      placeholder={placeholder ?? `Select...`}
      value={[ALL_OPTIONS, ...options].filter((c) => selectedIds?.includes(c.value ?? ''))}
      options={[ALL_OPTIONS, ...options]}
      onChange={handleSelect}
    />
  );
};

export default SelectWithAllOption;
