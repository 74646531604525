import React from 'react';
import { useSelector } from 'react-redux';
import { CirclePlusButton } from 'shared/components/Buttons';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { RootState } from 'store/reducers';

interface IProps {
  activeTab: string;
  onShowCreateBatchModal: () => void;
  onShowManualPaymentModal: () => void;
}

const action: IAreaPermissionLevel = {
  area: AreaType.Billing,
  permission: PermissionType.Payments,
  level: RoleLevelType.Create,
};

const MobileButtonComponents: React.FC<IProps> = ({ activeTab, onShowCreateBatchModal, onShowManualPaymentModal }) => {
  const centerIdToBatch = useSelector((state: RootState) => state.billing.paymentBatches.centerIdToBatch);

  const paymentsSelectedForBatching = useSelector(
    (state: RootState) => state.billing.paymentBatches.paymentTransactionsSelectedForBatching
  );

  switch (activeTab) {
    case 'payments':
      return (
        <HasRoleAreaLevel action={action}>
          <CirclePlusButton className="my-4" onClick={() => onShowManualPaymentModal()} variant="primary" />
        </HasRoleAreaLevel>
      );

    case 'unbatched':
      const disabled = !centerIdToBatch || !paymentsSelectedForBatching.length;

      return (
        <CirclePlusButton
          className="my-4"
          disabled={disabled}
          onClick={() => onShowCreateBatchModal()}
          variant="primary"
        />
      );

    default:
      return null;
  }
};

export default MobileButtonComponents;
