import React, { useCallback, useState, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { orderBy, uniq } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Row, Col } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import DateInput from 'shared/components/DateInput';
import TextInput from 'shared/components/TextInput';
import { getFullName, getInitials, stringToHueDegree } from 'shared/util/string';
import SelectOptionWithAvatar from 'shared/components/Select/SelectOptionWithAvatar';
import { useGetAgenciesForBusiness } from 'gql/agency/queries';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import { useAssignChildToAgency, useEditChildAgencyEnrollment } from 'gql/agency/mutations';
import { showToast } from 'shared/components/Toast';
import { updateAccountSuccess } from 'pages/Families/subroutes/Accounts/duck/actions';
import Checkbox from 'shared/components/Checkbox';
import { useGetContractsForAccountChild } from 'gql/contract/queries';
import useFormatDate from 'shared/hooks/useFormatDate';
import { TimezoneContext } from 'shared/contexts/timezoneContext';
import { components } from 'react-select';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Alert from 'shared/components/Alert';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IFormShape {
  accountChildId: string;
  start: string;
  end: string;
  agencyId: string;
  estimatedSubsidyAmount: number | null;
  notes: string;
  childSubsidyEnrollmentId: string | null;
  ignoreCopay: boolean;
  excludedContractIds: string[];
}

interface IProps {
  isOpen: boolean;
  account: IAccount;
  agencyAccountChildToEdit?: IAgencyAccountChild;
  agencyEnrollmentToEdit?: IAgencyAccountChildEnrollment;
  agencyEnrollments: IAgencyAccountChild[];
  onClose: (createdEnrollment?: boolean) => void;
}

const AddEditSubsidyModal: React.FC<IProps> = ({
  isOpen,
  account,
  agencyAccountChildToEdit,
  agencyEnrollmentToEdit,
  agencyEnrollments,
  onClose,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['subsidies']);
  const formatDate = useFormatDate();
  const accountCenterTimezoneContext = useContext(TimezoneContext);

  const isEdit = agencyAccountChildToEdit !== undefined && agencyEnrollmentToEdit !== undefined;

  const [invalidDatesWithContract, setInvalidDatesWithContract] = useState(false);

  // k2-edit-agency-enrollment controls whether users are allowed to edit
  // certain attributes of an agency enrollment after it has been created.
  const { k2EditAgencyEnrollment } = useFlags();

  const [formData, setFormData] = useState<IFormShape>(
    isEdit
      ? {
          accountChildId: agencyAccountChildToEdit!.accountChildId,
          start: agencyEnrollmentToEdit!.startDate,
          end: agencyEnrollmentToEdit!.endDate,
          agencyId: agencyAccountChildToEdit!.agencyId,
          estimatedSubsidyAmount: agencyEnrollmentToEdit!.subsidyPaymentPortion,
          notes: agencyEnrollmentToEdit!.notes ?? '',
          childSubsidyEnrollmentId: agencyEnrollmentToEdit!.childSubsidyEnrollmentId,
          ignoreCopay: agencyEnrollmentToEdit!.ignoreCopay,
          excludedContractIds: agencyEnrollmentToEdit!.excludedContractIds,
        }
      : {
          accountChildId: '',
          start: '',
          end: '',
          agencyId: '',
          estimatedSubsidyAmount: null,
          notes: '',
          childSubsidyEnrollmentId: null,
          ignoreCopay: false,
          excludedContractIds: [],
        }
  );

  const [exludeContactCheckBox, setExludeContactCheckBox] = useState(
    isEdit ? agencyEnrollmentToEdit!.excludedContractIds.length > 0 : false
  );

  const { data: getAgenciesData, loading: getAgenciesLoading } = useGetAgenciesForBusiness({
    variables: {
      businessId: account.entityId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: getContractsForChildData, loading: getContractsForChildLoading } = useGetContractsForAccountChild({
    variables: {
      accountChildId: formData.accountChildId,
    },
    skip: !formData.accountChildId,
    fetchPolicy: 'network-only',
  });

  const contractsForSelectedChild = useMemo(() => {
    return (
      getContractsForChildData?.getContractsForAccountChild
        .filter((contract) => contract.status !== 'EXPIRED' && ['WEEKLY', 'BIWEEKLY'].includes(contract.cycleType))
        .map((contract: IContract, idx: number) => {
          return {
            value: contract.id,
            label: `${contract.class?.name ?? ''} - ${formatDate(
              moment(contract.startDate).tz(accountCenterTimezoneContext.timezone),
              'MM/DD/YYYY'
            )} - ${
              contract.endDate
                ? formatDate(moment(contract.endDate).tz(accountCenterTimezoneContext.timezone), 'MM/DD/YYYY')
                : 'Ongoing'
            }`,
            feeType: contract.permanentFee?.feeType,
            // This handles whether or not the selected items can be cleared
            isFixed: contract.permanentFee?.feeType === 'FLAT_RATE',
          };
        }) ?? []
    );
  }, [accountCenterTimezoneContext.timezone, formatDate, getContractsForChildData?.getContractsForAccountChild]);

  const hasAgencyCompatibleContracts = useMemo(
    () => contractsForSelectedChild.filter((x) => x.feeType !== 'FLAT_RATE').length > 0,
    [contractsForSelectedChild]
  );
  const flatRateContracts = useMemo(
    () => contractsForSelectedChild.filter((x) => x.feeType === 'FLAT_RATE'),
    [contractsForSelectedChild]
  );

  flatRateContracts.forEach((flatRateContract) => {
    formData.excludedContractIds.push(flatRateContract.value);
  });

  const [assignChildToAgencyFn, { loading: assignChildToAgencyLoading }] = useAssignChildToAgency({
    onCompleted: (result) => {
      dispatch(
        updateAccountSuccess({
          ...account,
          agencyAccountChildren: [...(account.agencyAccountChildren ?? []), result.createAgencyAccountChild],
        })
      );
      showToast(t('subsidies:agencies.assign-child-modal.success-toast-message'), 'success');
      handleClose(true);
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  const [editChildAgencyEnrollmentFn, { loading: editChildAgencyEnrollmentLoading }] = useEditChildAgencyEnrollment({
    onCompleted: (result) => {
      dispatch(
        updateAccountSuccess({
          ...account,
          agencyAccountChildren: [
            ...(account.agencyAccountChildren ?? []).map((ac) =>
              ac.id === result.updateAgencyAccountChildEnrollment.id ? result.updateAgencyAccountChildEnrollment : ac
            ),
          ],
        })
      );
      showToast(t('subsidies:agencies.assign-child-modal.edit-success-toast-message'), 'success');
      handleClose(true);
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  const handleSave = useCallback(() => {
    if (!exludeContactCheckBox) {
      formData.excludedContractIds = [];
    }

    if (isEdit) {
      editChildAgencyEnrollmentFn({
        variables: {
          input: {
            agencyAccountChildEnrollmentId: agencyEnrollmentToEdit?.id ?? '',
            endDate: formData.end,
            excludedContractIds: uniq(formData.excludedContractIds),
            startDate: formData.start,
            subsidyPaymentPortion: formData.estimatedSubsidyAmount as number,
            ignoreCopay: formData.ignoreCopay,
            notes: formData.notes,
          },
        },
      });
    } else {
      assignChildToAgencyFn({
        variables: {
          input: {
            agencyId: formData.agencyId,
            accountChildId: formData.accountChildId,
            startDate: formData.start,
            endDate: formData.end,
            subsidyPaymentPortion: formData.estimatedSubsidyAmount as number,
            notes: formData.notes,
            childSubsidyEnrollmentId: formData.childSubsidyEnrollmentId,
            ignoreCopay: formData.ignoreCopay,
            excludedContractIds: uniq(formData.excludedContractIds),
          },
        },
      });
    }
  }, [
    formData,
    isEdit,
    exludeContactCheckBox,
    agencyEnrollmentToEdit,
    assignChildToAgencyFn,
    editChildAgencyEnrollmentFn,
  ]);

  const handleClose = useCallback(
    (createdEnrollment?: boolean) => {
      setFormData({
        accountChildId: '',
        start: '',
        end: '',
        agencyId: '',
        estimatedSubsidyAmount: null,
        notes: '',
        childSubsidyEnrollmentId: null,
        ignoreCopay: false,
        excludedContractIds: [],
      });
      onClose(createdEnrollment);
    },
    [onClose]
  );

  const getSubsidyPeriodTextForAgency = useCallback((agency: IAgency | null): string => {
    if (!agency) return '';

    switch (agency.subsidyPeriod) {
      case 'WEEKLY':
        return 'per week';
      case 'EVERY_TWO_WEEKS':
        return 'per every two weeks';
      case 'MONTHLY':
        return 'per month';
      case 'SESSION':
        return 'per session';
      default:
        return '';
    }
  }, []);

  const invalidDates = useCallback(
    (formattedDate: string): boolean => {
      formattedDate = moment(formattedDate).format('yyyy-MM-DD');

      const childsEnrollments = agencyEnrollments
        .find((a) => a.accountChildId === formData.accountChildId)
        ?.agencyAccountChildEnrollments.filter((e) => e.status !== 'ARCHIVED');

      if (!childsEnrollments) return false;

      return childsEnrollments.some(
        (e) =>
          e.id !== agencyEnrollmentToEdit?.id &&
          moment(formattedDate).isSameOrBefore(e.endDate) &&
          moment(formattedDate).isSameOrAfter(e.startDate)
      );
    },
    [agencyEnrollmentToEdit, agencyEnrollments, formData.accountChildId]
  );

  const findValidContract = useCallback(
    (formattedStartDate: string, formattedEndDate: string): boolean => {
      return (
        getContractsForChildData?.getContractsForAccountChild
          .filter((contract) => contract.status !== 'EXPIRED' && ['WEEKLY', 'BIWEEKLY'].includes(contract.cycleType))
          .some(
            (c) =>
              moment(formattedStartDate).isSameOrAfter(moment(c.startDate)) &&
              moment(formattedEndDate).isSameOrBefore(moment(c.endDate))
          ) ?? false
      );
    },
    [getContractsForChildData]
  );

  const hasInvalidStartDate: boolean = useMemo(() => {
    return !!(formData.start && invalidDates(formData.start));
  }, [formData.start, invalidDates]);
  const hasInvalidEndDate: boolean = useMemo(
    () => !!(formData.end && invalidDates(formData.end)),
    [formData.end, invalidDates]
  );

  const handleDateSelect = useCallback(
    (date: string, isStartDate: boolean) => {
      if (isStartDate) setFormData((prev) => ({ ...prev, start: date }));
      else setFormData((prev) => ({ ...prev, end: date }));

      const formattedDate = moment(date).format('yyyy-MM-DD');
      const formattedStartDate = isStartDate ? formattedDate : moment(formData.start).format('yyyy-MM-DD');
      const formattedEndDate = !isStartDate ? formattedDate : moment(formData.end).format('yyyy-MM-DD');

      if (!findValidContract(formattedStartDate, formattedEndDate) && formData.start && formData.end)
        setInvalidDatesWithContract(true);
      else setInvalidDatesWithContract(false);
    },
    [findValidContract, formData.end, formData.start]
  );

  const filteredAgencies = orderBy(
    (getAgenciesData?.getAgenciesForBusiness ?? []).filter(
      (agency) =>
        !agency.archivedAt &&
        (agency.centerIds === null || (agency.centerIds !== null && agency.centerIds?.includes(account.centerId)))
    ),
    (agency) => agency.name.toLowerCase(),
    'asc'
  );

  const formDisabled =
    !formData.accountChildId ||
    !formData.agencyId ||
    !formData.start ||
    !formData.end ||
    formData.estimatedSubsidyAmount === null ||
    formData.estimatedSubsidyAmount === undefined;

  const selectedAgency: IAgency | null =
    getAgenciesData?.getAgenciesForBusiness.find((agency) => agency.id === formData.agencyId) ?? null;

  const flatRateOptions = [
    {
      label: t('subsidies:agencies.assign-child-modal.auto-excluded-contracts'),
      options: flatRateContracts,
    },
  ];

  var fullContractListOptions = [...contractsForSelectedChild, ...flatRateOptions];
  var fullContractListValues = [...contractsForSelectedChild, ...flatRateContracts];

  // React-select component definition to remove the (x) from fixed items
  const MultiValueRemove = (props: any) => {
    if (props.data.isFixed) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
  };

  const accountChildrenOptions = useMemo(() => {
    let children: IAccountChild[] = account.children ?? [];
    let filter: (c: IAccountChild) => boolean;
    if (isEdit) {
      filter = (c) => c.accountChildId === formData.accountChildId;
    } else {
      filter = (c) => !!c.archivedAt === false;
    }
    return orderBy(
      children.filter(filter),
      [(c) => c.lastname.toLowerCase(), (c) => c.firstname.toLowerCase()],
      ['asc', 'asc']
    );
  }, [account.children, formData.accountChildId, isEdit]);

  return (
    <SideModalDrawer
      title={
        isEdit
          ? t('subsidies:agencies.assign-child-modal.edit-title')
          : t('subsidies:agencies.assign-child-modal.new-title')
      }
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      closeOnSecondaryCallback={false}
      primaryChoice={t('subsidies:agencies.assign-child-modal.primary-button-text')}
      primaryCallback={() => handleSave()}
      primaryButtonProps={{
        disabled: formDisabled,
        loading: assignChildToAgencyLoading || editChildAgencyEnrollmentLoading,
      }}
      secondaryCallback={() => handleClose()}
      enforceFocus={false}
    >
      <Row>
        <Col>
          <Select
            required
            label={t('subsidies:agencies.assign-child-modal.child-select-label')}
            options={accountChildrenOptions}
            onChange={(option: IAccountChild) =>
              setFormData((prev) => ({ ...prev, accountChildId: option.accountChildId }))
            }
            formatOptionLabel={(option: IAccountChild) => (
              <SelectOptionWithAvatar
                primaryText={getFullName(option)}
                avatar={option.avatar?.url ?? ''}
                initials={getInitials(option)}
                color={`hsl(${stringToHueDegree(option.id)}, ${
                  stringToHueDegree(option.id) < 50 ? '100%' : '40%'
                }, 40%`}
              />
            )}
            disabled={isEdit}
            value={account.children.find((c) => c.accountChildId === formData.accountChildId)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            label={t('subsidies:agencies.assign-child-modal.agency-select-label')}
            options={filteredAgencies}
            value={filteredAgencies.find((agency) => agency.id === formData.agencyId)}
            onChange={(option: IAgency) => setFormData((prev) => ({ ...prev, agencyId: option.id }))}
            isLoading={getAgenciesLoading}
            getOptionLabel={(option: IAgency) => option.name}
            getOptionValue={(option: IAgency) => option.id}
            disabled={isEdit}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            label={t('subsidies:agencies.assign-child-modal.agency-external-child-id-label')}
            value={formData.childSubsidyEnrollmentId}
            onChange={(value) => setFormData((prev) => ({ ...prev, childSubsidyEnrollmentId: value }))}
            disabled={isEdit}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            required
            label={t('subsidies:agencies.assign-child-modal.start-date-label')}
            date={formData.start}
            onDateSelect={(date) => {
              console.log('DEV:', 'on date select', date);
              handleDateSelect(date, true);
            }}
            className="kt-date-input-no-max-width"
            isOutsideRange={(day) => moment(day).isSameOrAfter(moment(formData.end))}
            disabled={(isEdit && !k2EditAgencyEnrollment) || !formData.accountChildId}
          />
        </Col>
        <Col>
          <DateInput
            required
            label={t('subsidies:agencies.assign-child-modal.end-date-label')}
            date={formData.end}
            onDateSelect={(date) => handleDateSelect(date, false)}
            className="kt-date-input-no-max-width"
            isOutsideRange={(day) => moment(day).isSameOrBefore(moment(formData.start))}
            disabled={!formData.accountChildId}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {hasInvalidStartDate && (
            <Alert className="mb-3" variant="warning">
              {t('subsidies:add-edit-subsidy-modal.select-date-error', { date: 'Start' })}
            </Alert>
          )}
        </Col>
        <Col>
          {hasInvalidEndDate && (
            <Alert className="mb-3" variant="warning">
              {t('subsidies:add-edit-subsidy-modal.select-date-error', { date: 'End' })}
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        {formData.accountChildId && !hasAgencyCompatibleContracts && (formData.start || formData.end) && (
          <Alert className="mb-3" variant="warning">
            {t('subsidies:add-edit-subsidy-modal.no-compatible-contracts')}
          </Alert>
        )}
        {formData.accountChildId && contractsForSelectedChild.length <= 0 && (formData.start || formData.end) && (
          <Alert className="mb-3" variant="warning">
            {t('subsidies:add-edit-subsidy-modal.no-contracts-error')}
          </Alert>
        )}
        {formData.accountChildId &&
          contractsForSelectedChild.length > 0 &&
          invalidDatesWithContract &&
          (formData.start || formData.end) &&
          !(hasInvalidStartDate || hasInvalidEndDate) && (
            <Alert className="mb-3" variant="warning">
              {t('subsidies:add-edit-subsidy-modal.contracts-dates-error')}
            </Alert>
          )}
      </Row>
      <Row>
        <Col>
          <CurrencyInput
            required
            className="border-radius-0"
            label={`${t('subsidies:agencies.assign-child-modal.expected-subsidy-label')} ${
              selectedAgency !== null
                ? selectedAgency.billingCalculationType === 'COPAY_AMOUNT'
                  ? '(Copay)'
                  : '(Subsidy)'
                : ''
            }`}
            value={formData.estimatedSubsidyAmount}
            onChange={(value) => setFormData((prev) => ({ ...prev, estimatedSubsidyAmount: value }))}
            prepend="$"
            step="0.01"
            disabled={isEdit && !k2EditAgencyEnrollment}
          />
        </Col>
        <Col>
          <div className="mt-4">{getSubsidyPeriodTextForAgency(selectedAgency)}</div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Checkbox
            label={t('subsidies:agencies.assign-child-modal.no-copay-checkbox-label')}
            value={formData.ignoreCopay}
            onChange={(checked) => setFormData((prev) => ({ ...prev, ignoreCopay: checked }))}
            disabled={isEdit && !k2EditAgencyEnrollment}
          />
        </Col>
      </Row>
      {formData.accountChildId && (
        <>
          <Row className={'mb-2'}>
            <Col>
              <Checkbox
                label={'Do you want to exclude any contracts ?'}
                value={exludeContactCheckBox}
                onChange={(checked) => setExludeContactCheckBox(checked)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            {exludeContactCheckBox && (
              <Col>
                <div>
                  <Form.Label>Excluded Contracts</Form.Label>
                  <small className="d-block mb-4">
                    Contracts that are excluded will not have agency billing applied.
                  </small>
                </div>
                {formData.accountChildId ? (
                  getContractsForChildLoading ? (
                    <div>Fetching contracts...</div>
                  ) : !getContractsForChildLoading &&
                    (getContractsForChildData?.getContractsForAccountChild ?? []).length > 0 ? (
                    <Select
                      isMulti
                      isClearable={(options: any) => options.some((v: any) => !v.isFixed)}
                      components={{ MultiValueRemove }}
                      value={fullContractListValues.filter((contract) =>
                        formData.excludedContractIds?.includes(contract.value)
                      )}
                      options={fullContractListOptions}
                      onChange={(value: any[]) =>
                        setFormData((prev) => ({ ...prev, excludedContractIds: value?.map((v) => v.value) ?? [] }))
                      }
                    />
                  ) : (
                    <div>There are no contracts for this child.</div>
                  )
                ) : (
                  <div>Select a child</div>
                )}
              </Col>
            )}
          </Row>
        </>
      )}
      <Row>
        <Col>
          <TextInput
            label={t('subsidies:agencies.assign-child-modal.notes-label')}
            value={formData.notes}
            onChange={(value) => setFormData((prev) => ({ ...prev, notes: value }))}
            as="textarea"
            rows={5}
            disabled={isEdit && !k2EditAgencyEnrollment}
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default AddEditSubsidyModal;
