import { showConfirm } from 'shared/components/ConfirmationContainer';
import { ReenrollmentGetAccountsFilterBy, useRequestUpdateEnrolmentFormMutation } from 'generated/graphql';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faFileEdit } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { showToast } from 'shared/components/Toast';

interface IProps {
  filterBy: ReenrollmentGetAccountsFilterBy;
  displayNumber: number;
}

const RequestUpdateEnrolmentFormAction: React.FC<IProps> = ({ filterBy, displayNumber }) => {
  const { t } = useTranslation(['translation', 'enrollment', 'business']);
  const businessId = useSelector((state: RootState) => state.context.businessId);

  const [mutation, { data, loading, error }] = useRequestUpdateEnrolmentFormMutation({
    onError: (err) => showToast(t('enrollment:update-enrollment-form-error'), 'error'),
    onCompleted: (response) => {
      showToast(t('enrollment:update-enrollment-form-ok'), 'success');
    },
  });

  const onConfirm = async () => {
    mutation({
      variables: {
        input: {
          businessId: businessId ?? '',
          filter: filterBy,
        },
      },
    });
  };

  const handleConfirm = () => {
    showConfirm({
      title: t('enrollment:update-enrollment-form'),
      message: (
        <>
          You have selected <strong>{displayNumber}</strong> accounts. Would you like to send requests to account
          primary contacts now, to update their child and family details?
        </>
      ),
      primaryButtonLabel: t('enrollment:update-enrollment-form-text'),
      onConfirm: onConfirm,
      primaryButtonVariant: 'primary',
    });
  };

  return (
    <IconButtonCircle
      icon={faFileEdit}
      className="mr-2"
      tooltipText={t('enrollment:update-enrollment-form')}
      onClick={handleConfirm}
    />
  );
};

export default RequestUpdateEnrolmentFormAction;
