import config from '../../config/config.json';
import axios, { AxiosRequestConfig } from 'axios';
import JSEncrypt from 'jsencrypt';

export class TokenizerService {
  private readonly jsEncrypt: JSEncrypt;

  constructor() {
    this.jsEncrypt = new JSEncrypt({});
  }

  encryptAndSendTokenizeRequest = async <T extends IPayrixToken | IFatZebraAch | IFatZebraCreditCardToken>(
    unencryptedRequestBody: ITokenizerRequestBody,
    token: string
  ): Promise<T> => {
    const encryptedRequest = await this.encrypt(unencryptedRequestBody);
    return (await this.tokenizeRequest(encryptedRequest, token)) as T;
  };

  private encrypt = async (unencryptedRequestParams: ITokenizerRequestBody): Promise<ITokenizerRequestBody> => {
    const publicKey = config.tokenizer.publicKey;
    this.jsEncrypt.setPrivateKey(publicKey);

    const encryptedRequestBody: Partial<ITokenizerRequestBody> = {};
    for (const [k, v] of Object.entries(unencryptedRequestParams)) {
      // TODO: Fix this, having a brain fart right now how to accurately type this
      // @ts-ignore
      encryptedRequestBody[k] = this.jsEncrypt.encrypt(v);
    }

    return encryptedRequestBody as ITokenizerRequestBody;
  };

  private tokenizeRequest = async <T>(encryptedRequestBody: ITokenizerRequestBody, token: string) => {
    const endpoint = `${config.tokenizer.apiUrl}/tokenize`;
    const options: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post<T>(endpoint, encryptedRequestBody, options);
    return response.data;
  };
}
