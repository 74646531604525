import React from 'react';
import { lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';

interface IProps {
  classBookingSettings: IClassBookingSettings;
}

const CasualBookingWindowCell: React.FC<IProps> = ({ classBookingSettings }) => {
  const {
    casualBookingSettings: { bookingPolicy },
  } = classBookingSettings;
  const { t } = useTranslation();

  if (bookingPolicy) {
    const { atLeastInAdvance, atMostInAdvance } = bookingPolicy;
    const startQty = Math.ceil(atLeastInAdvance.quantity);
    // @ts-ignore - t() is complaining about template literals
    const startUnit = t(`spelling.${lowerCase(atLeastInAdvance.unit)}${startQty > 1 ? 's' : ''}`);
    const endQty = Math.ceil(atMostInAdvance.quantity);
    // @ts-ignore - t() is complaining about template literals
    const endUnit = t(`spelling.${lowerCase(atMostInAdvance.unit)}${endQty > 1 ? 's' : ''}`);
    return <>{`${startQty} ${startUnit} - ${endQty} ${endUnit}`}</>;
  }

  return <>-</>;
};

export default CasualBookingWindowCell;
