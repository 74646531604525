import { gql } from '@apollo/client';
import { useQuery, useLazyQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { ccssServiceMessageFields, ccssProviderMessageFields, ccssCorrespondenceFile, ccssEventFields } from './fields';

interface IGetCcsServiceMessages {
  getCcssServiceMessages: IPagedResult<ICcsMessage>;
}

interface IGetCcsEvents {
  getCcssEvents: IPagedResult<ICcssEvent>;
}

interface IGetCcsServiceMessagesVariables {
  input: ICcsMessagesInput;
}

interface IGetCcsEventsVariables {
  input: IGetCcssEventInput;
}

interface IGetCcsProviderMessages {
  getCcssProviderMessages: IPagedResult<ICcsMessage>;
}

interface IGetCcsProviderMessagesVariables {
  input: ICcsProviderMessagesInput;
}

type CcssCorrespondenceFileContent = {
  content: string;
};
interface IGetCcsProviderCorrespondence {
  getCcssProviderCorrespondence: CcssCorrespondenceFileContent;
}

interface IGetCcsProviderCorrespondenceVariable {
  input: CcsProviderCorrespondenceInput;
}

interface IGetCcsServiceCorrespondence {
  getCcssServiceCorrespondence: CcssCorrespondenceFileContent;
}
interface IGetCcsServiceCorrespondenceVariable {
  input: CcsServiceCorrespondenceInput;
}

export const GET_CCS_SERVICE_MESSAGES = (fields: string = ccssServiceMessageFields) => gql`
  query($input: CcssServiceMessagesInput!) {
    getCcssServiceMessages(input: $input) {
      ${fields}
    }
  }
`;

export const GET_CCS_EVENTS = (fields: string = ccssEventFields) => gql`
  query($input: GetCcssEventInput!) {
    getCcssEvents(input: $input) {
      ${fields}
    }
  }
`;

export const GET_CCS_PROVIDER_MESSAGES = (fields: string = ccssProviderMessageFields) => gql`
  query($input: CcssProviderMessagesInput!) {
    getCcssProviderMessages(input: $input) {
      ${fields}
    }
  }
`;

export const GET_CCS_PROVIDER_CORRESPONDENCE = (fields: string = ccssProviderMessageFields) => gql`
  query($input: CcssProviderCorrespondenceInput!) {
    getCcssProviderCorrespondence(input: $input) {
      ${ccssCorrespondenceFile}
    }
  }
`;

export const GET_CCS_SERVICE_CORRESPONDENCE = gql`
  query($input: CcssServiceCorrespondenceInput!) {
    getCcssServiceCorrespondence(input: $input) {
      ${ccssCorrespondenceFile}
    }
  }
`;

export const useGetCcsProviderMessages = (
  options?: QueryHookOptions<IGetCcsProviderMessages, IGetCcsProviderMessagesVariables>,
  fields?: string
) =>
  useQuery(GET_CCS_PROVIDER_MESSAGES(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetCcsServiceMessages = (
  options?: QueryHookOptions<IGetCcsServiceMessages, IGetCcsServiceMessagesVariables>,
  fields?: string
) =>
  useQuery(GET_CCS_SERVICE_MESSAGES(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetCcsProviderCorrespondence = (
  options?: QueryHookOptions<IGetCcsProviderCorrespondence, IGetCcsProviderCorrespondenceVariable>,
  fields?: string
) =>
  useQuery(GET_CCS_PROVIDER_CORRESPONDENCE(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useLazyGetProviderCorrespondence = (
  options?: QueryHookOptions<IGetCcsProviderCorrespondence, IGetCcsProviderCorrespondenceVariable>
) =>
  useLazyQuery(GET_CCS_PROVIDER_CORRESPONDENCE(), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useLazyGetServiceCorrespondence = (
  options?: QueryHookOptions<IGetCcsServiceCorrespondence, IGetCcsServiceCorrespondenceVariable>
) =>
  useLazyQuery(GET_CCS_SERVICE_CORRESPONDENCE, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetCcssEvents = (options?: QueryHookOptions<IGetCcsEvents, IGetCcsEventsVariables>, fields?: string) =>
  useQuery(GET_CCS_EVENTS(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
