import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { useCreateInternalAccountNote } from './gql/mutation';
import RightModalDrawer from 'shared/components/MUI/RightModalDrawer';

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

interface IProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  refetch: () => void;
  personId: string;
}

const defaultNoteCategoriesOptions = [
  'Discipline',
  'General',
  'Development',
  'Coaching',
  'PerformanceReview',
  'Conflict',
  'Communication',
  'Recognition',
  'Attendance',
];

const AddNoteThread: React.FC<IProps> = ({ open, setOpen, refetch, personId }) => {
  const businessIdRedux = useSelector((state: RootState) => state.context.businessId);
  const businessId = _.isNil(businessIdRedux) ? '' : businessIdRedux;
  const [title, setTitle] = React.useState('');
  const [noteType, setNoteType] = React.useState('');
  const [noteComment, setNoteComment] = React.useState('');

  const [createNoteThreadFn] = useCreateInternalAccountNote({
    onCompleted: () => {
      refetch();
      showToast('Note Created', 'success');
      onClose();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onSave = () => {
    createNoteThreadFn({
      variables: {
        input: {
          businessId: businessId,
          attachedToId: personId,
          areaType: 'Staff',
          noteType: noteType,
          title: title,
          comment: noteComment,
        },
      },
    });
  };

  const onClose = () => {
    setOpen(false);
    setTitle('');
    setNoteType('');
    setNoteComment('');
  };

  return (
    <>
      <RightModalDrawer open={open} onClose={onClose} title="Note Thread">
        <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '16px' }}>
          <Mui.Stack direction="column" spacing={2} marginLeft="16px">
            <Mui.FormControlLabel
              label={
                <span>
                  Title
                  <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
                </span>
              }
              labelPlacement="top"
              control={
                <Mui.TextField
                  size="small"
                  value={title}
                  required
                  onChange={(evt) => setTitle(evt.target.value)}
                  fullWidth
                  sx={styles.inputStyles}
                />
              }
              componentsProps={{
                typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
              }}
              style={{ margin: 0 }}
            />
            <Mui.FormControlLabel
              label={
                <span>
                  Type
                  <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
                </span>
              }
              labelPlacement="top"
              control={
                <Mui.Select
                  size="small"
                  value={noteType}
                  fullWidth
                  sx={styles.inputStyles}
                  onChange={(evt) => setNoteType(evt.target.value)}
                >
                  {defaultNoteCategoriesOptions.map((option, index) => (
                    <Mui.MenuItem key={option} value={option}>
                      {option}
                    </Mui.MenuItem>
                  ))}
                </Mui.Select>
              }
              componentsProps={{
                typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
              }}
            />
            <Mui.FormControlLabel
              label={
                <span>
                  Note
                  <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
                </span>
              }
              labelPlacement="top"
              control={
                <Mui.TextField
                  size="small"
                  value={noteComment}
                  required
                  onChange={(evt) => setNoteComment(evt.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  sx={styles.inputStyles}
                  variant="outlined"
                />
              }
              componentsProps={{
                typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
              }}
            />
          </Mui.Stack>
        </Mui.DialogContent>
        <Mui.Box style={{ margin: '8px' }}>
          <Mui.DialogActions>
            <Mui.Button variant="cancel" onClick={() => onClose()}>
              Cancel
            </Mui.Button>
            <Mui.Button
              disabled={_.isEmpty(title) || _.isEmpty(noteType) || _.isEmpty(noteComment)}
              color="secondary"
              variant="contained"
              onClick={() => onSave()}
            >
              Save
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Box>
      </RightModalDrawer>
    </>
  );
};

export default AddNoteThread;
