import React, { useCallback } from 'react';
import Switch from 'shared/components/Switch/Switch';
import moment from 'moment';
import { toProperCase } from 'shared/util/string';
import { Row, Col } from 'shared/components/Layout';
import { IconButtonCircle, IconButton } from 'shared/components/Buttons';
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import cast from 'shared/util/cast';
import errorMessages from 'shared/constants/errorMessages';
import MobileAvailabilityInputs from './MobileAvailabilityInputs';
import { isValid24HourString } from 'shared/util/timeUtils';
import { Form } from 'react-bootstrap';
import TimeInput from '../TimePicker/TimeInput';

export interface ITimeRange {
  start: moment.Moment;
  end: moment.Moment;
  startTimeString: string;
  endTimeString: string;
}
export interface IDayAvailability {
  dayOfWeek: DayOfWeek;
  times: ITimeRange[];
  dayEnabled: boolean;
}

export type IAvailabilityObject = Record<string, IDayAvailability>;

const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

const defaultTime = {
  start: moment().hours(9).minutes(0).seconds(0),
  end: moment().hours(17).minutes(0).seconds(0),
  startTimeString: moment().hours(9).minutes(0).seconds(0).format('HH:mm'),
  endTimeString: moment().hours(17).minutes(0).seconds(0).format('HH:mm'),
};

export const emptyWeek: IAvailabilityObject = Object.fromEntries(
  daysOfWeek.map((day) => [
    day,
    {
      dayOfWeek: cast<DayOfWeek>(day),
      dayEnabled: false,
      times: [defaultTime],
    },
  ])
);

export const areTimesOverlapped: (t1: ITimeRange, t2: ITimeRange) => boolean = (t1, t2) =>
  t1.start?.isBetween(t2.start, t2.end) || t1.end?.isBetween(t2.start, t2.end);

interface IProps {
  readOnly?: boolean;
  availability: IAvailabilityObject;
  updateAvailability: React.Dispatch<React.SetStateAction<IAvailabilityObject>>;
  onChange?: () => any;
}

const AvailabilityInputs: React.FC<IProps> = ({
  readOnly = false,
  availability,
  updateAvailability,
  onChange = () => {},
}) => {
  const updateDayEnabled = useCallback(
    (day, val) => {
      updateAvailability((prev) => ({ ...prev, [day]: { ...prev[day], dayEnabled: val } }));
      onChange();
    },
    [onChange, updateAvailability]
  );

  const updateTime = useCallback(
    (day, index, updatedTime) => {
      updateAvailability((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          times: prev[day].times.map((time, i) => (i === index ? { ...time, ...updatedTime } : time)),
        },
      }));
      onChange();
    },
    [onChange, updateAvailability]
  );

  const addTime = useCallback(
    (day) => {
      updateAvailability((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          times: [
            ...prev[day].times,
            {
              start: prev[day].times[prev[day].times.length - 1].end?.clone(),
              end: prev[day].times[prev[day].times.length - 1].end?.clone().add(1, 'hours'),
              startTimeString: prev[day].times[prev[day].times.length - 1].end?.clone().format('HH:mm'),
              endTimeString: prev[day].times[prev[day].times.length - 1].end?.clone().add(1, 'hours').format('HH:mm'),
            },
          ],
        },
      }));
      onChange();
    },
    [onChange, updateAvailability]
  );

  const removeTime = useCallback(
    (day, index) => {
      updateAvailability((prev) => ({
        ...prev,
        [day]: { ...prev[day], times: prev[day].times.filter((t, i) => i !== index) },
      }));
      onChange();
    },
    [onChange, updateAvailability]
  );

  return (
    <div>
      <MobileAvailabilityInputs
        availability={availability}
        updateAvailability={updateAvailability}
        addTime={addTime}
        removeTime={removeTime}
        updateTime={updateTime}
        timesOverlap={areTimesOverlapped}
        updateDayEnabled={updateDayEnabled}
      />
      <div className="d-none d-lg-block">
        {daysOfWeek.map((day: string) => (
          <Row className="mb-6" align="start" noGutters key={day}>
            <Col sm={4} className="d-none d-lg-block">
              <div>
                <div className="d-flex align-items-center mt-8">
                  <div style={{ width: '88px' }}>{toProperCase(day)}</div>
                  <Switch
                    disabled={readOnly}
                    id={day}
                    className="mx-2"
                    value={availability[day].dayEnabled}
                    onChange={(val) => {
                      updateDayEnabled(day, val);
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col sm={8}>
              {availability[day].dayEnabled ? (
                <div>
                  {availability[day].times.map((time, index) => (
                    <div key={index} className="d-flex">
                      <div className="mr-2 justify-content-center">
                        <Form.Label>Start Time</Form.Label>
                        <TimeInput
                          isAM={true}
                          value={time.startTimeString ?? null}
                          onChange={(start: string | null) => {
                            const startTime = start !== null && isValid24HourString(start) ? start : '';
                            const [startHours, startMinutes] = startTime.split(':');
                            updateTime(day, index, {
                              start: time.start
                                ?.clone()
                                .set({ h: parseInt(startHours, 10), m: parseInt(startMinutes, 10) }),
                              startTimeString: start,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <Form.Label>End Time</Form.Label>
                        <TimeInput
                          isAM={false}
                          value={time.endTimeString ?? null}
                          onChange={(end: string | null) => {
                            const endtime = end !== null && isValid24HourString(end) ? end : '';
                            const [endHours, endMinutes] = endtime.split(':');
                            updateTime(day, index, {
                              end: time.end?.clone().set({ h: parseInt(endHours, 10), m: parseInt(endMinutes, 10) }),
                              endTimeString: end,
                            });
                          }}
                        />
                      </div>
                      {index === availability[day].times.length - 1 ? (
                        <IconButtonCircle
                          disabled={readOnly}
                          size="sm"
                          className="ml-4 my-1 mt-9"
                          backgroundColor="secondary"
                          color="white"
                          icon={faPlus}
                          tooltipText="Add New Split Shift"
                          onClick={() => addTime(day)}
                        />
                      ) : (
                        <IconButton
                          disabled={readOnly}
                          className="ml-4 sm-icon-width my-1 mt-4"
                          icon={faTrashAlt}
                          tooltipText="Remove Split Shift"
                          onClick={() => removeTime(day, index)}
                        />
                      )}
                    </div>
                  ))}
                  {availability[day].times.some((t1, i1) =>
                    availability[day].times.some((t2, i2) => i1 !== i2 && areTimesOverlapped(t1, t2))
                  ) && <small className="text-danger">{errorMessages.overlappingTimes}</small>}
                </div>
              ) : (
                <div className="my-2">
                  <i>Unavailable</i>
                </div>
              )}
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default AvailabilityInputs;
