import React from 'react';
import { addressFields } from 'gql/address/fields';

import _ from 'lodash';

import * as Mui from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { faAngleDown, faAngleUp, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';

import { STATES } from 'shared/constants/dropdownOptions/countryInfo';
import useDatatableState from 'shared/hooks/useDatatableState';
import { useSearchCenters } from 'gql/center/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButtonCircle } from '../../../../../../../shared/components/Buttons';
import CenterTableRow from './CenterTableRow';

export const CENTERS_FIELDS: string = `
  id
  entity {
    id
    name
  }
  name
  tags {
    id
    name
    category
  }
  avatar {
    url
  }
  address {
    ${addressFields}
  }
  timezone
  email
  externalId
`;

interface IProps {
  selectedCenters: string[];
  setSelectedCenters: (value: string[]) => void;
  participantCenterIds: string[] | null;
  alreadySelectedCenters: string[] | null;
}

const columns = [
  { name: 'id', displayLabel: false, alignLeft: true },
  { name: 'Center Name', displayLabel: true, alignLeft: true },
  { name: 'City', displayLabel: true, alignLeft: true },
  // { name: 'Tags', displayLabel: true, alignLeft: false },
];

const CentersTable: React.FC<IProps> = ({
  selectedCenters,
  setSelectedCenters,
  participantCenterIds,
  alreadySelectedCenters,
}) => {
  const theme = useTheme();
  const isMobile = Mui.useMediaQuery(theme.breakpoints.down('md'));
  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('center', initalTableSort);
  const [page, setPage] = React.useState(0);
  const [from, setFrom] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [centerSearchText, setCenterSearchText] = React.useState('');
  const [centerSearchValue, setCenterSearchValue] = React.useState('');
  const [centerNameSortDirection, setCenterNameSortDirection] = React.useState('ASCENDING');

  const query = tableFunctions.getElasticQuery();

  const { data } = useSearchCenters(
    {
      variables: {
        input: Object.assign(
          {},
          query,
          { from: from },
          { size: rowsPerPage },
          {
            filter: {
              all: [
                {
                  any: [
                    {
                      term: {
                        field: 'name',
                        predicate: 'CONTAINS',
                        value: centerSearchValue,
                      },
                    },
                  ],
                },
              ],
            },
            sort: [
              {
                field: 'name.keyword',
                direction: centerNameSortDirection,
              },
            ],
          }
        ),
      },
    },
    CENTERS_FIELDS
  );

  const formattedTableData =
    data?.searchCenters.data.map((center) => ({
      id: center.id,
      avatarUrl: center.avatar?.url,
      name: center.name,
      city: `${center.address?.city} , ${STATES[center.address?.state]}`,
      tags: center.tags,
    })) ?? [];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setFrom(0);
  };

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setFrom(page * rowsPerPage);
    setPage(page);
  };

  React.useEffect(() => {
    updateCenterSearchValue(centerSearchText);
  }, [centerSearchText]);

  const updateCenterSearchValue = React.useCallback(
    _.debounce((input: string) => {
      setCenterSearchValue(input);
    }, 500),
    []
  );

  const onChangeNameSort = () => {
    centerNameSortDirection === 'ASCENDING'
      ? setCenterNameSortDirection('DESCENDING')
      : setCenterNameSortDirection('ASCENDING');
  };

  return (
    <Mui.Box sx={{ width: '100%' }}>
      <Mui.CardContent>
        <Mui.Stack
          justifyContent={'space-between'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          spacing={2}
          direction={isMobile ? 'column' : 'row'}
        >
          <Mui.Stack
            alignItems={isMobile ? 'flex-start' : 'center'}
            spacing={2}
            direction={isMobile ? 'column' : 'row'}
          >
            <Mui.Box display="flex" alignItems="center">
              <Mui.Typography>Show</Mui.Typography>
              <Mui.Select
                sx={{
                  '& legend': {
                    width: 0,
                  },
                  marginLeft: '8px',
                }}
                inputProps={{
                  'aria-label': 'show number of centers in list',
                }}
                value={rowsPerPage.toString()}
                onChange={(evt) => setRowsPerPage(parseFloat(evt.target.value))}
                size={'small'}
              >
                <Mui.MenuItem value={5}>5</Mui.MenuItem>
                <Mui.MenuItem value={10}>10</Mui.MenuItem>
                <Mui.MenuItem value={25}>25</Mui.MenuItem>
              </Mui.Select>
            </Mui.Box>
            <Mui.TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search Centers"
              value={centerSearchText}
              onChange={(evt) => setCenterSearchText(evt.target.value)}
              size="small"
              sx={{
                minWidth: '500px',
                '& legend': {
                  width: 0,
                },
              }}
              InputProps={{
                startAdornment: (
                  <Mui.InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </Mui.InputAdornment>
                ),
              }}
            />
          </Mui.Stack>
          <IconButtonCircle icon={faTimes} onClick={(evt) => setCenterSearchText('')} tooltipText="Clear Search" />
        </Mui.Stack>
      </Mui.CardContent>
      <Mui.Divider />
      <Mui.Box sx={{ margin: '0px 0px' }}>
        <Mui.Table aria-labelledby="center-table" sx={{ backgroundColor: 'white', borderColor: 'white' }}>
          <Mui.TableHead sx={{ backgroundColor: 'white' }}>
            <Mui.TableRow sx={{ border: 'none' }}>
              {columns.map((column) => (
                <Mui.TableCell key={column.name} align={column.alignLeft ? 'left' : 'center'} padding={'normal'}>
                  <Mui.Typography variant="tableHeadCells">
                    {column.displayLabel && column.name}
                    {column.name === 'Center Name' && (
                      <Mui.IconButton onClick={() => onChangeNameSort()}>
                        <FontAwesomeIcon
                          size="xs"
                          icon={centerNameSortDirection === 'ASCENDING' ? faAngleDown : faAngleUp}
                        />
                      </Mui.IconButton>
                    )}
                  </Mui.Typography>
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {formattedTableData.map((row, index) => (
              <CenterTableRow
                row={row}
                alreadySelectedCenters={alreadySelectedCenters}
                selectedCenters={selectedCenters}
                setSelectedCenters={setSelectedCenters}
                key={row.id}
                participantCenterIds={participantCenterIds}
              />
            ))}
          </Mui.TableBody>
        </Mui.Table>
        <Mui.TablePagination
          sx={{
            '.MuiTablePagination-displayedRows': {
              marginY: 0,
              paddingY: 0,
            },
            border: 'none',
          }}
          rowsPerPageOptions={[10]}
          component="div"
          count={data?.searchCenters.totalResults ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Mui.Box>
    </Mui.Box>
  );
};

export default CentersTable;
