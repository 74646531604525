import React from 'react';
import { Col, Row } from 'shared/components/Layout';
import ServiceFeeCard from '../ServiceFeeCard';
import Button from 'shared/components/Buttons';

interface IProps {
  _serviceFeeGroup: IServiceFeeGroupForm;
  onAdd: () => void;
  onUpdate: (updateServiceFee: IServiceFee) => void;
  onDelete: (deleteServiceFee: IServiceFee) => void;
  onAddRuleGroup: (serviceFee: IServiceFee, parentGroup: IServiceFeeRuleGroup | undefined) => void;
  onUpdateRuleGroup: (serviceFee: IServiceFee, updateGroup: IServiceFeeRuleGroup) => void;
  onDeleteRuleGroup: (
    serviceFee: IServiceFee,
    deleteGroup: IServiceFeeRuleGroup,
    parentGroup: IServiceFeeRuleGroup | undefined
  ) => void;
  onAddRule: (parentGroup: IServiceFeeRuleGroup) => void;
  onUpdateRule: (serviceFee: IServiceFee, updateRule: IServiceFeeRule, parentGroup: IServiceFeeRuleGroup) => void;
  onDeleteRule: (deleteRule: IServiceFeeRule, parentGroup: IServiceFeeRuleGroup) => void;
}

const ServiceFeeManagement: React.FC<IProps> = ({
  _serviceFeeGroup,
  onAdd,
  onUpdate,
  onDelete,
  onAddRuleGroup,
  onUpdateRuleGroup,
  onDeleteRuleGroup,
  onAddRule,
  onUpdateRule,
  onDeleteRule,
}) => {
  return (
    <section id="service-fees-section">
      <Row className="mb-4" noGutters>
        <div className="border-radius-0 font-weight-bold my-4">Service Fees</div>
        <Button className="ml-auto" onClick={onAdd}>
          Add Service Fee
        </Button>
      </Row>
      <Row align="start">
        <Col xl={6} align="start" className="pr-5">
          <div className="border-radius-0 font-weight-bold my-4">Credit Card</div>
          {_serviceFeeGroup.serviceFees
            .filter((fee) => fee.serviceFeeType === 'CC')
            .map((fee) => (
              <ServiceFeeCard
                key={fee.id}
                serviceFee={fee}
                onUpdate={onUpdate}
                onDelete={onDelete}
                onAddRuleGroup={onAddRuleGroup}
                onUpdateRuleGroup={onUpdateRuleGroup}
                onDeleteRuleGroup={onDeleteRuleGroup}
                onAddRule={onAddRule}
                onUpdateRule={onUpdateRule}
                onDeleteRule={onDeleteRule}
              />
            ))}
        </Col>
        <Col xl={6} align="start" className="pl-5">
          <div className="border-radius-0 font-weight-bold my-4">ACH</div>
          {_serviceFeeGroup.serviceFees
            .filter((fee) => fee.serviceFeeType === 'ACH')
            .map((fee) => (
              <ServiceFeeCard
                key={fee.id}
                serviceFee={fee}
                onUpdate={onUpdate}
                onDelete={onDelete}
                onAddRuleGroup={onAddRuleGroup}
                onUpdateRuleGroup={onUpdateRuleGroup}
                onDeleteRuleGroup={onDeleteRuleGroup}
                onAddRule={onAddRule}
                onUpdateRule={onUpdateRule}
                onDeleteRule={onDeleteRule}
              />
            ))}
        </Col>
      </Row>
    </section>
  );
};

export default ServiceFeeManagement;
