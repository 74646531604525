import i18next from 'i18next';
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';

export interface IProviderAddressNotInput {
  type: PhysicalAddressType | string;
  streetLine1?: string;
  streetLine2?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
}

export interface IProviderAdministratorInput {
  email?: string;
  name: string;
  abn: string;
  phone: string;
  startDate: string;
  endDate: string;
  address?: IProviderAddressNotInput;
}

export interface IProviderEntersInput {
  status: string;
  startDate: string;
  endDate: string;
  providerId: string;
  admin?: IProviderAdministratorInput | null;
}

const statusForSelect = [
  { value: 'ADMN', label: i18next.t('business:ccs-management.provider-enters-into-admn-recp-liqd.statuses.admin') },
  { value: 'BANK', label: i18next.t('business:ccs-management.provider-enters-into-admn-recp-liqd.statuses.bank') },
  { value: 'LIQU', label: i18next.t('business:ccs-management.provider-enters-into-admn-recp-liqd.statuses.liq') },
  { value: 'RECE', label: i18next.t('business:ccs-management.provider-enters-into-admn-recp-liqd.statuses.rece') },
];

const addressTypeOptions = [
  {
    value: 'ZPHYSICAL',
    label: i18next.t('business:ccs-management.provider-enters-into-admn-recp-liqd.address-type.physical'),
  },
  {
    value: 'ZPOSTAL',
    label: i18next.t('business:ccs-management.provider-enters-into-admn-recp-liqd.address-type.postal'),
  },
];

const emptyInputs = {
  status: '',
  startDate: '',
  endDate: '',
  providerId: '',
};

const emptySaleInputs = {
  email: '',
  name: '',
  abn: '',
  phone: '',
  startDate: '',
  endDate: '',
};

const emptyAddressInputs = {
  streetLine1: '',
  streetLine2: '',
  suburb: '',
  state: '',
  postcode: '',
  type: '',
};

const stateOptionsForProvider = STATE_SELECT_OPTIONS['AU'].map(({ label, value }) => ({
  value: value.replace('AU-', ''),
  label,
}));

export {
  emptySaleInputs,
  emptyAddressInputs,
  addressTypeOptions,
  emptyInputs,
  statusForSelect,
  stateOptionsForProvider,
};
