import React from 'react';
//utils
import { useHistory } from 'react-router-dom';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { sortBy } from 'lodash';
import { EndOfCareTimelogsTab } from '../../EndOfCareTab';
//components
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EndOfCareActive from '../../Tabs/EndOfCareActive';
import EndOfCareHistory from '../../Tabs/EndOfCareHistory';

interface IProps {
  activeTab: EndOfCareTimelogsTab;
  setActiveTab: (tab: EndOfCareTimelogsTab) => void;
}

export const MenuTab: React.FC<IProps> = ({ activeTab, setActiveTab }) => {
  const history = useHistory();
  const { data: businessesData } = useGetAllowedEntities();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');

  return (
    <Tabs
      id="iscase-tab-group"
      activeKey={activeTab}
      unmountOnExit
      onSelect={(tab) => {
        setActiveTab(tab as EndOfCareTimelogsTab);
        history.push(`/end-of-care/${tab}`);
      }}
    >
      <Tab eventKey="active" title="Active">
        {businesses && businesses.length > 0 && <EndOfCareActive />}
      </Tab>
      <Tab eventKey="history" title="History">
        {businesses && businesses.length > 0 && <EndOfCareHistory />}
      </Tab>
    </Tabs>
  );
};
