import React, { useCallback, useState } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ActionDropdown from 'shared/components/ActionDropdown';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import Select from 'shared/components/Select';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState2';
import useFormatDate from 'shared/hooks/useFormatDate';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { setCurrentBusiness, setCurrentCenterFilters } from 'store/context/actions';
import { RootState } from 'store/reducers';
import { capitalize } from 'shared/util/string';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ProgramGroupRow from '../ProgramGroupRow/ProgramGroupRow';
import { EnrollmentProgramGroup } from 'generated/graphql';
import { accountStatusColorHexes } from 'shared/constants/tagColors';
import { useMediaQuery } from '@mui/material';
import theme from 'muiTheme';

export type ProgramGroupAction = 'edit' | 'delete';
export interface IEnrollmentProgramGroupsTableProps {
  data: EnrollmentProgramGroup[];
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  centers: ICenter[];
  businessId: string;
  centerFilterIds: string[];
  searchTerm: string;
  totalResults: number;
  loading: boolean;
  handleDelete: (group: EnrollmentProgramGroup) => void;
  handleEdit: (group: EnrollmentProgramGroup) => void;
  setSearchTerm: (value: string) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  setIsActiveFilter: (value: boolean | undefined) => void;
}
const EnrollmentProgramGroupsTable: React.FC<IEnrollmentProgramGroupsTableProps> = ({
  data,
  tableFunctions,
  tableState,
  businessId,
  centers,
  centerFilterIds,
  searchTerm,
  totalResults,
  loading,
  onSort,
  setSearchTerm,
  handleEdit = (program) => {},
  handleDelete,
  setIsActiveFilter,
}) => {
  const { t } = useTranslation();
  // TODO UPDATE FLAG
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { k2ProgramEnrolmentDates } = useFlags();
  const user = useSelector((state: RootState) => state.user);
  const allEntities = useSelector((state: RootState) => state.entities.allEntities);
  const businessTimeZone = allEntities[businessId]?.timezone || moment.tz.guess();
  const { data: businessesData } = useGetAllowedEntities();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const formatter = useFormatDate();
  const dispatch = useDispatch();
  const hasEditPermission = true;
  const [activeFilterList, setActiveFilterList] = useState<string[]>([]);

  const handleCenterFilterSelect = useCallback(
    (options: ITableFilterOption[]) => {
      dispatch(setCurrentCenterFilters((options ?? []).map((c) => c.value)));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const handleBusinessSelect = useCallback(
    (option: ITableFilterOption) => {
      dispatch(setCurrentBusiness(option.value));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const statusOptions: ITableFilterOption[] = [
    {
      label: capitalize(t('spelling.active')),
      value: 'Active',
      indicatorColor: accountStatusColorHexes.Active,
    },
    {
      label: capitalize(t('spelling.inactive')),
      value: 'Inactive',
      indicatorColor: accountStatusColorHexes.Inactive,
    },
  ];

  const handleActiveFilter = useCallback(
    (values: string[]) => {
      setActiveFilterList(values);
      if ((values.includes('Active') && values.includes('Inactive')) || values.length == 0) {
        setIsActiveFilter(undefined);
      } else {
        setIsActiveFilter(values.includes('Active'));
      }
    },
    [setIsActiveFilter]
  );

  return (
    <DataTable
      data={data}
      dataSize={totalResults}
      noPadding
      showSelect={false}
      showLoadingOverlay={loading}
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      onSort={onSort}
      className="enrollment-programs-table"
      expandRow={(row: EnrollmentProgramGroup) => <ProgramGroupRow programGroup={row} />}
      renderHeader={(paginationProps) => (
        <TableHeader className="flex-wrap justify-content-between">
          <SizePerPage paginationProps={paginationProps} />
          <TableSearch className="max-width-200" onChange={setSearchTerm} value={searchTerm} enableDebounce={false} />
          <div className="ml-auto d-flex align-items-center">
            {user?.isInternal && (
              <Select
                title={capitalize(t('spelling.business'))}
                className="mr-4 my-2 min-width-200"
                options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                value={businessId}
                name="business"
                onChange={handleBusinessSelect}
              />
            )}
            <DropdownFilter
              title={t('spelling.capitalCenter')}
              className="mr-4"
              selectedFilters={centerFilterIds}
              options={
                centers
                  ?.filter((c) => c.entityId === businessId)
                  .map((c) => ({ label: c.name, value: c.id }))
                  .sort((a, b) => a.label.localeCompare(b.label)) ?? []
              }
              onFilterSelect={handleCenterFilterSelect}
            />
            <DropdownFilter
              title={capitalize(t('spelling.status'))}
              className={isMobile ? ' mt-1 mr-3' : 'mr-3'}
              selectedFilters={activeFilterList}
              options={statusOptions}
              onFilterSelect={(values) => handleActiveFilter(values.map((v) => v.value))}
            />
          </div>
        </TableHeader>
      )}
      columns={[
        {
          text: capitalize(t('spelling.name')),
          dataField: 'name',
          sort: true,
        },
        {
          text: `Group ${t('spelling.start-date')}`,
          dataField: 'startDt',
          sort: true,
          formatter: (cell: string) => (cell ? formatter(cell) : '-'),
        },
        {
          text: `Group ${t('spelling.end-date')}`,
          dataField: 'endDt',
          sort: true,
          formatter: (cell: string) => (cell ? formatter(cell) : '-'),
        },
        {
          text: capitalize(t('spelling.status')),
          dataField: 'isActive',
          sort: true,
          formatter: (isActive: boolean) =>
            isActive ? capitalize(t('spelling.active')) : capitalize(t('spelling.inactive')),
        },
        {
          text: capitalize(t('spelling.actions')),
          dataField: '',
          classes: 'action-column',
          formatter: (cell: any, row: EnrollmentProgramGroup) => (
            <ActionDropdown
              actions={[
                ...(hasEditPermission
                  ? [
                      {
                        label: capitalize(t('spelling.edit')),
                        onClick: () => {
                          handleEdit(row);
                        },
                      },
                    ]
                  : []),
                ...(hasEditPermission
                  ? [
                      {
                        label: capitalize(t('spelling.delete')),
                        onClick: () => {
                          handleDelete(row);
                        },
                      },
                    ]
                  : []),
              ]}
            />
          ),
        },
      ]}
    />
  );
};

export default EnrollmentProgramGroupsTable;
