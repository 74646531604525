import React from 'react';

interface IProps {
  size?: circleSizes | number;
  className?: string;
  background?: string; // hex code
  children?: React.ReactNode | null;
  onClick?: () => void;
}

const Circle: React.FC<IProps> = ({ size = 'md', className, background, children, onClick }) => {
  return (
    <div
      className={`d-flex flex-shrink-0 rounded-circle circle-${size} ${className}`}
      style={
        typeof size === 'number' ? { width: size, height: size, background: background } : { background: background }
      }
      onClick={onClick}
      role={onClick ? 'button' : ''}
    >
      {children}
    </div>
  );
};

export default Circle;
