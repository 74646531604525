export const defaultNoteFields = `
  id
  businessId
  areaType
  attachedToEntityId
  title
  noteType
  comment
  createdAt
  associatedWith
  {
    objectId
    objectType
    display {
      displayValue
      displayAvatar
    }
  }
  createdBy
  createdByAccount{
    firstname
    lastname
    email
    avatar{
      url
    }
  }
  lastCommentedAt
  lastCommentedByAccount
  {
    firstname
    lastname
    email
    avatar{
      url
    }
  }
`;

export const pagedListNotes = `
  totalRecords
  pageNumber
  pageSize
  data{
    ${defaultNoteFields}
} `;
