import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { getFullName } from 'shared/util/string';
import { useRemoveChildOnAccount } from 'gql/account/mutations';
import { useGetAccountsForChild, useGetDoesChildHaveHistoryOnAccount } from 'gql/account/queries';
import Alert from 'shared/components/Alert';
import { Col, Row } from 'shared/components/Layout';
import Spinner from 'shared/components/Spinner';

interface IProps {
  isOpen: boolean;
  child: IAccountChild;
  onClose: () => void;
  account: IAccount;
}

const RemoveChildOnAccountModal: React.FC<IProps> = ({ isOpen, child, onClose, account, ...props }) => {
  const { data: childAccountsData, loading: childAccountLoading } = useGetAccountsForChild(child.id);
  const { data: historyData, loading: historyLoading } = useGetDoesChildHaveHistoryOnAccount(child.id, account.id);

  const [removeChildFn, { loading }] = useRemoveChildOnAccount(account.id, child.id);
  const handleSubmit = useCallback(() => {
    removeChildFn()
      .then(() => {
        showToast('Child removed successfully.', 'success');
        onClose();
      })
      .catch((error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      });
  }, []);

  const childExistsOnSingleAccount = childAccountsData?.getAccountsForChild.length === 1;
  const childHasHistory = historyData?.getDoesChildHaveHistoryOnAccount;

  return (
    <ConfirmationModal
      title="Remove Child on Account?"
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Remove"
      primaryCallback={handleSubmit}
      primaryButtonProps={{
        variant: 'danger',
        loading,
        disabled: childAccountLoading || historyLoading || childExistsOnSingleAccount || childHasHistory,
      }}
    >
      <p>
        When {getFullName(child)} is removed from this account, their association will be permanently removed. All
        account contact permissions will be terminated as well, once this child is removed.{' '}
      </p>
      <p>Are you sure you want to remove {getFullName(child)} from this account? This action cannot be undone.</p>
      {childAccountLoading || historyLoading ? (
        <Row noGutters>
          <div>Checking if child can be removed.</div>
          <Spinner small className="ml-2" />
        </Row>
      ) : (
        <div>
          {childExistsOnSingleAccount ? (
            <Alert variant="warning" className="mb-4">
              A child cannot be removed without being associated to at least 1 account.
            </Alert>
          ) : childHasHistory ? (
            <Alert variant="warning" className="mb-4">
              Child cannot be removed, only archived. They have a history on this account.
            </Alert>
          ) : (
            <Alert variant="info" className="mb-4">
              <Col>
                <div>Child will remain on the following accounts:</div>
                <ul>
                  {childAccountsData?.getAccountsForChild
                    .filter((a) => a.id !== account.id)
                    .map((a) => (
                      <li key={a.id}>
                        {a.name} at {a.center?.name}
                      </li>
                    ))}{' '}
                </ul>
              </Col>
            </Alert>
          )}
        </div>
      )}
    </ConfirmationModal>
  );
};

export default RemoveChildOnAccountModal;
