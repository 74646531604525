import React, { useState, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUndo,
  faArchive,
  faTrashAlt,
  faExclamationTriangle,
  faFileImport,
  faTimes,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import { Collapse, Form, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ActionDropdown from 'shared/components/ActionDropdown';
import Button, { CreateButton, IconButtonCircle } from 'shared/components/Buttons';
import DateInput from 'shared/components/DateInput';
import { HorizontalDivider } from 'shared/components/Dividers';
import FileDropbox from 'shared/components/FileOperations/FileDropbox';
import { Row, Col } from 'shared/components/Layout';
import { RiskTag } from 'shared/components/Tag';
import TextInput from 'shared/components/TextInput';
import useFormatDate from 'shared/hooks/useFormatDate';
import { camelCaseToSpaced, getFullName } from 'shared/util/string';
import { useUpdateWellnessConditionDocuments } from 'gql/medicalCondition/mutations';
import { showToast } from 'shared/components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { deleteChildDocuments, updateChildDocuments } from '../../../duck/actions';
import colors from '_colors.module.scss';
import { orderBy } from 'lodash';
import Switch from 'shared/components/Switch';
import { RootState } from 'store/reducers';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';

const DOCUMENT_STATUS_MAP = {
  Active: 0,
  Expired: 1,
  Archived: 2,
};

interface IDocumentUpdatesStateShape {
  documentIdsToArchive: string[];
  documentIdsToDelete: string[];
  documentsToAdd: IHealthConditionDocumentUploadInput[];
}

interface IProps {
  condition: IWellnessCondition;
  category: WellnessCategoryType;
  isOpen: boolean;
  canEdit: boolean;
  canDelete: boolean;
  onHide: () => void;
}

const ConditionDocumentsModal: React.FC<IProps> = ({ condition, category, isOpen, canEdit, canDelete, onHide }) => {
  const dispatch = useDispatch();
  const child = useSelector((state: RootState) => state.children.byId)[condition.childId];
  const childName = getFullName(child);

  const [showDragAndDrop, setShowDragAndDrop] = useState(false);
  const [showArchivedDocuments, setShowArchivedDocuments] = useState<boolean>(false);
  const [documents, setDocuments] = useState(
    orderBy(
      condition.documents ?? [],
      (doc) => {
        if (doc.archivedAt) return DOCUMENT_STATUS_MAP.Archived;
        if (doc.expirationDate !== null && moment(doc.expirationDate).isBefore(moment(), 'date'))
          return DOCUMENT_STATUS_MAP.Expired;

        return DOCUMENT_STATUS_MAP.Active;
      },
      'asc'
    )
  );
  const [documentIdsInEdit, setDocumentIdsInEdit] = useState<string[]>([]);
  const [sort, setSort] = useState<{ property: string; order: 'asc' | 'desc' }>({ property: 'name', order: 'asc' });

  const updatedDocuments = useMemo(
    () =>
      documents.filter((d) => {
        const orginal = condition.documents.find((doc) => doc.id == d.id);
        return orginal?.name !== d.name || orginal?.expirationDate !== d.expirationDate;
      }),
    [documentIdsInEdit, documents]
  );

  const [documentUpdates, setDocumentUpdates] = useState<IDocumentUpdatesStateShape>({
    documentIdsToArchive: [],
    documentIdsToDelete: [],
    documentsToAdd: [],
  });

  const formatDate = useFormatDate();
  const [updateWellnessConditionDocumentsFn, { loading: updateWellnessConditionDocumentsLoading }] =
    useUpdateWellnessConditionDocuments({
      onCompleted: (result) => {
        showToast('Documents updated successfully.', 'success');
        dispatch(updateChildDocuments(condition.childId, result.updateWellnessConditionDocuments));
        dispatch(deleteChildDocuments(condition.childId, documentUpdates.documentIdsToDelete));
        dismissModal();
      },
      onError: (error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      },
    });

  const dismissModal = useCallback(() => {
    setDocumentUpdates({
      documentIdsToArchive: [],
      documentIdsToDelete: [],
      documentsToAdd: [],
    });
    setShowDragAndDrop(false);
    setShowArchivedDocuments(false);
    // reset default order back to by status
    setDocuments((prev) =>
      orderBy(
        prev,
        (doc) => {
          if (doc.archivedAt) return DOCUMENT_STATUS_MAP.Archived;
          if (doc.expirationDate !== null && moment(doc.expirationDate).isBefore(moment(), 'date'))
            return DOCUMENT_STATUS_MAP.Expired;

          return DOCUMENT_STATUS_MAP.Active;
        },
        'asc'
      )
    );
    onHide();
  }, [onHide]);

  const handleUnarchiveDocumentClick = useCallback((document: IWellnessDocument) => {
    setDocumentUpdates((prev) => ({
      ...prev,
      documentIdsToArchive: prev.documentIdsToArchive.filter((id) => id !== document.id),
    }));
  }, []);

  const handleUndeleteDocumentClick = useCallback((document: IWellnessDocument) => {
    setDocumentUpdates((prev) => ({
      ...prev,
      documentIdsToDelete: prev.documentIdsToDelete.filter((id) => id !== document.id),
    }));
  }, []);

  const handleArchiveDocumentClick = useCallback((document: IWellnessDocument) => {
    setDocumentUpdates((prev) => ({
      ...prev,
      documentIdsToArchive: [...prev.documentIdsToArchive, document.id],
    }));
  }, []);

  const handleDeleteDocumentClick = useCallback((document: IWellnessDocument) => {
    setDocumentUpdates((prev) => ({
      ...prev,
      documentIdsToDelete: [...prev.documentIdsToDelete, document.id],
    }));
  }, []);

  const handleSave = useCallback(() => {
    updateWellnessConditionDocumentsFn({
      variables: {
        input: {
          wellnessConditionId: condition.id,
          toCreate: documentUpdates.documentsToAdd,
          toArchive: documentUpdates.documentIdsToArchive,
          toDelete: documentUpdates.documentIdsToDelete,
          toUpdate: updatedDocuments.map((d) => ({ id: d.id, filename: d.name, expirationDate: d.expirationDate })),
        },
      },
    });
  }, [condition, documentUpdates, updateWellnessConditionDocumentsFn, updatedDocuments]);

  const handleAddFiles = useCallback((files: File[]) => {
    setDocumentUpdates((prev) => ({
      ...prev,
      documentsToAdd: [
        ...prev.documentsToAdd,
        ...files.map((file) => ({
          file,
          filename: `${childName}_${file.name}`,
          expirationDate: null,
        })),
      ],
    }));
    setShowDragAndDrop(false);
  }, []);

  const handleUpdateDocument = useCallback((document: IWellnessDocument) => {
    setDocuments((prev) => prev.map((doc) => (doc.id === document.id ? document : doc)));
  }, []);

  const handleSort = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.preventDefault();
    // assume that the target will be the svg...
    let target = event.target as HTMLElement; // technically SVGSVGElement but TypeScript is throwing a fit casting HTMLElement -> SVGSVGElement

    // ... but sometimes the path element will be selected so select its parent svg
    if (event.target instanceof SVGPathElement) {
      target = event.target.parentElement as HTMLElement;
    }

    const currentSortDirection = target.getAttribute('data-sort-direction') as string;
    const sortProperty = target.getAttribute('data-sort-property') as string;
    const nextSortDirection = currentSortDirection === 'asc' ? 'desc' : 'asc';

    setSort((prev) => ({
      property: sortProperty,
      order: prev.property === sortProperty ? (prev.order === 'desc' ? 'asc' : 'desc') : 'desc',
    }));
    setDocuments((prev) => orderBy(prev, sortProperty, nextSortDirection));
  }, []);

  const getSortIcon = (sortProperty: string) => (
    <FontAwesomeIcon
      className="ml-4 icon-lowered cursor-pointer"
      icon={sortProperty === sort.property ? (sort.order === 'asc' ? faAngleUp : faAngleDown) : faAngleDown}
      onClick={handleSort}
      size="1x"
      data-sort-direction={sort.order}
      data-sort-property={sortProperty}
    />
  );

  const documentHasExpired = useCallback(
    (expirationDate: string | null): boolean =>
      expirationDate ? moment(expirationDate).isBefore(moment(), 'date') : false,
    []
  );

  const renderStatusColumnContent = useCallback(
    (document: IWellnessDocument): JSX.Element | null => {
      const isExpired = documentHasExpired(document.expirationDate);
      const isArchived = Boolean(document.archivedAt);
      const isActive = !isExpired && !isArchived;
      const text = isArchived ? 'Archived' : isExpired ? 'Expired' : 'Active';

      return (
        <div
          style={{ width: '94px' }}
          className={classnames('tag px-4', {
            'text-white': (isActive || isExpired) && !isArchived,
            'bg-success': isActive,
            'bg-danger': isExpired && !isArchived,
            'bg-pale-gray': isArchived,
          })}
        >
          <div className="mx-auto">{text}</div>
        </div>
      );
    },
    [documentHasExpired]
  );

  return (
    <Modal
      backdrop="static"
      centered
      show={isOpen}
      onHide={dismissModal}
      scrollable={false}
      size="lg"
      className="document-modal"
    >
      <Modal.Body className="p-4 m-0">
        <h4 className="mb-4">History</h4>
        <Row align="start">
          <Col xs={2}>
            <Form.Label>{condition.levelLabel}</Form.Label>
            <Row noGutters>
              <RiskTag risk={condition.level} />
            </Row>
          </Col>
          <Col xs={2}>
            <Form.Label>Type</Form.Label>
            {condition.typeName}
          </Col>
          <Col xs={2}>
            <Form.Label>Name</Form.Label>
            {condition.name}
          </Col>
          {condition.symptoms && (
            <Col>
              <Form.Label>{condition.symptomsLabel}</Form.Label>
              {condition.symptoms.map((s) => camelCaseToSpaced(s)).join(', ')}
            </Col>
          )}
          <Col>
            <Form.Label>{condition.freeTextLabel}</Form.Label>
            {condition.freeText}
          </Col>
        </Row>
        <HorizontalDivider />
        <div className="d-flex flex-row align-items-center mb-4">
          <h4>Documents</h4>
          <Switch
            label="Show archived"
            value={showArchivedDocuments}
            onChange={setShowArchivedDocuments}
            className="ml-auto"
            labelSide="left"
          />
        </div>
        {documents?.length > 0 && (
          <Table borderless className="collaped no-box-shadow every-other-row-gray">
            <thead>
              <tr>
                <th>
                  <span>Name</span>
                  {getSortIcon('name')}
                </th>
                <th>Uploaded By</th>
                <th>
                  <span>Expiry Date</span>
                  {getSortIcon('expirationDate')}
                </th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {documents
                .filter((doc) => (showArchivedDocuments ? true : !doc.archivedAt))
                .map((document) => {
                  const isExpired = documentHasExpired(document.expirationDate);
                  const isInEdit = documentIdsInEdit.includes(document.id);
                  return (
                    <tr>
                      <td>
                        {isInEdit ? (
                          <TextInput
                            className="mb-0"
                            value={document.name}
                            onChange={(name) => handleUpdateDocument({ ...document, name })}
                          />
                        ) : (
                          <span>
                            {documentUpdates.documentIdsToDelete.includes(document.id) && (
                              <FontAwesomeIcon icon={faTrashAlt} size="lg" className="mr-2" />
                            )}
                            {documentUpdates.documentIdsToArchive.includes(document.id) && (
                              <FontAwesomeIcon icon={faArchive} size="lg" className="mr-2" />
                            )}
                            <a
                              key={document.id}
                              href={document.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="mr-2"
                            >
                              {document.name}
                            </a>
                          </span>
                        )}
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="mr-2">{getFullName(document.createdByPerson)} </div>
                          {formatDate(document.createdAt, 'MM/DD/YYYY')}
                        </div>
                      </td>
                      <td style={{ overflow: 'visible' }}>
                        {isInEdit ? (
                          <DateInput
                            className="mb-0"
                            date={document.expirationDate}
                            onDateSelect={(expirationDate) => handleUpdateDocument({ ...document, expirationDate })}
                          />
                        ) : (
                          <div className={isExpired ? 'text-danger' : ''}>
                            {document.expirationDate ? formatDate(document.expirationDate, 'MM/DD/YYYY') : ''}
                          </div>
                        )}
                      </td>
                      <td>{renderStatusColumnContent(document)}</td>
                      <td>
                        {isInEdit ||
                        documentUpdates.documentIdsToArchive.includes(document.id) ||
                        documentUpdates.documentIdsToDelete.includes(document.id) ? (
                          <IconButtonCircle
                            iconSize="lg"
                            icon={faUndo}
                            tooltipText="Undo"
                            onClick={() => {
                              if (isInEdit) {
                                setDocumentIdsInEdit(documentIdsInEdit.filter((id) => id != document.id));
                                const original = condition.documents.find((d) => d.id === document.id);
                                original && handleUpdateDocument(original);
                              } else if (documentUpdates.documentIdsToArchive.includes(document.id)) {
                                handleUnarchiveDocumentClick(document);
                              } else {
                                handleUndeleteDocumentClick(document);
                              }
                            }}
                          />
                        ) : (
                          <ActionDropdown
                            actions={[
                              ...(!document.archivedAt
                                ? [
                                    ...(canEdit
                                      ? [
                                          {
                                            label: 'Edit',
                                            onClick: () => setDocumentIdsInEdit([...documentIdsInEdit, document.id]),
                                          },
                                        ]
                                      : []),
                                    ...(canDelete
                                      ? [{ label: 'Archive', onClick: () => handleArchiveDocumentClick(document) }]
                                      : []),
                                  ]
                                : []),
                              ...(canDelete
                                ? [{ label: 'Delete', onClick: () => handleDeleteDocumentClick(document) }]
                                : []),
                            ]}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}
        <HorizontalDivider />
        <Collapse in={showDragAndDrop}>
          <FileDropbox
            onFilesAdded={(e) => handleAddFiles(Array.from(e?.target?.files ?? []))}
            onDrop={handleAddFiles}
            className="mb-4"
          />
        </Collapse>
        {documentUpdates.documentsToAdd.map((file, i) => (
          <Row noGutters align="start" className="mb-4" key={i}>
            <FontAwesomeIcon className="mr-2 mt-8" size="2x" icon={faFileImport} color={colors.seafoamGreen} />
            <Col xs={4} className="mr-4">
              <TextInput
                className="mb-0"
                label="Name"
                value={file.filename}
                onChange={(filename) =>
                  setDocumentUpdates((prev) => ({
                    ...prev,
                    documentsToAdd: prev.documentsToAdd.map((doc, index) => (i !== index ? doc : { ...doc, filename })),
                  }))
                }
              />
            </Col>
            <Col xs={2}>
              <DateInput
                className="mb-0 mr-4"
                label="Expiration Date"
                date={file.expirationDate}
                onDateSelect={(expirationDate) =>
                  setDocumentUpdates((prev) => ({
                    ...prev,
                    documentsToAdd: prev.documentsToAdd.map((doc, index) =>
                      i !== index ? doc : { ...doc, expirationDate }
                    ),
                  }))
                }
              />
              {documentHasExpired(file.expirationDate) && (
                <Row noGutters className="mt-2">
                  <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} color={colors.warning} />
                  <small>Expired</small>
                </Row>
              )}
            </Col>
            <IconButtonCircle
              className="mt-8"
              size="sm"
              icon={faTimes}
              onClick={() =>
                setDocumentUpdates((prev) => ({
                  ...prev,
                  documentsToAdd: prev.documentsToAdd.filter((doc, index) => i !== index),
                }))
              }
            />
          </Row>
        ))}
        {documentUpdates.documentIdsToArchive.length > 0 && (
          <div className="text-danger">
            {documentUpdates.documentIdsToArchive.length} document(s) planned to be archived.
          </div>
        )}
        {documentUpdates.documentIdsToDelete.length > 0 && (
          <div className="text-danger">
            {documentUpdates.documentIdsToDelete.length} document(s) planned to be deleted.
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="border-top-0 p-4">
        <HasRoleAreaLevel
          action={{ area: AreaType.Child, permission: PermissionType.Documents, level: RoleLevelType.Create }}
        >
          <CreateButton variant="secondary" className="mr-auto mb-4" onClick={() => setShowDragAndDrop(true)}>
            Add Document(s)
          </CreateButton>
        </HasRoleAreaLevel>
        <Button variant="light" onClick={dismissModal} className="mr-4">
          Close
        </Button>
        <Button
          onClick={() => handleSave()}
          loading={updateWellnessConditionDocumentsLoading}
          disabled={Object.values(documentUpdates).every((arr: any[]) => !arr.length) && !updatedDocuments.length}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConditionDocumentsModal;
