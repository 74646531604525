import { useState, useCallback, useEffect } from 'react';
import { TABLE_DEFAULTS } from 'shared/components/DataTable';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

export interface IDatatableState {
  searchExpressions: ISearchExpression[];
  filterExpressions: ISearchExpression[];
  selectedFilters: ITableFiltersMap;
  sort: IElasticsearchSortFilter[];
  currentItemOffset: number;
  activePage: number;
  pageSize: number;
  selectedRows: any;
  query?: IElasticsearchQuery;
}

export type IDatatableStateUpdateFn = React.Dispatch<React.SetStateAction<IDatatableState>>;

export interface IStateControls {
  changePage: (page: number, sizePerPage: number) => void;
  changeSizePerPage: (sizePerPage: number) => void;
  updateSelectedFilters: (selectedFilters: ITableFiltersMap) => void;
  updateSearchExpressions: (searchExpressions: ISearchExpression[]) => void;
  updateSelectedRows: (rows: any) => void;
  updateSort: (field: string, direction: ElasticsearchSortDirection) => void;
  getElasticQuery: () => IElasticsearchQuery;
}

type tableName =
  | 'business'
  | 'staff'
  | 'center'
  | 'createdStaff'
  | 'invitedStaff'
  | 'requestedStaff'
  | 'contact'
  | 'children'
  | 'account'
  | 'billingAccount'
  | 'fees'
  | 'busRosterChildren'
  | 'billingPeriod'
  | 'itemizedBills'
  | 'serviceFeeGroups';

// hook to simply provide a consistent object pattern for keep track of a datatable's state
const useDatatableState = (
  name: tableName,
  sort?: IElasticsearchSortFilter[],
  selectedFilters?: ITableFiltersMap
): [IDatatableState, IStateControls, IDatatableStateUpdateFn, Boolean] => {
  const defaultTableState: IDatatableState = {
    searchExpressions: [],
    filterExpressions: [],
    selectedFilters: selectedFilters || {},
    sort: sort || [],
    currentItemOffset: TABLE_DEFAULTS.ITEM_OFFSET,
    activePage: TABLE_DEFAULTS.PAGE,
    pageSize: TABLE_DEFAULTS.PAGE_SIZE,
    selectedRows: [],
  };
  const user = useSelector((state: RootState) => state.user);
  const [isDirty, setDirty] = useState(false);
  const cachedTableState = localStorage.getItem(`${user?.email}-${name}TableState`);
  const initalTableState = cachedTableState
    ? { ...JSON.parse(cachedTableState), selectedRows: [], searchExpressions: [] }
    : defaultTableState;
  const [datatableState, setDatatableState] = useState<IDatatableState>(initalTableState);

  // handle table page changes
  const changePage = useCallback((page: number, sizePerPage: number) => {
    const newOffset: number = Math.max(0, (page - 1) * sizePerPage);
    setDirty(true);
    setDatatableState((current) => ({
      ...current,
      activePage: page,
      currentItemOffset: newOffset,
    }));
  }, []);

  // handle change the number of items to show on a page
  const changeSizePerPage = useCallback((sizePerPage: number) => {
    setDirty(true);
    setDatatableState((current) => ({
      ...current,
      pageSize: sizePerPage,
    }));
  }, []);

  // apply new dropdown filters, will resest the page back to 1
  const updateSelectedFilters = useCallback((selectedFilters: ITableFiltersMap) => {
    setDirty(true);
    setDatatableState((current) => ({
      ...current,
      currentItemOffset: TABLE_DEFAULTS.ITEM_OFFSET,
      activePage: TABLE_DEFAULTS.PAGE,
      selectedFilters,
      filterExpressions: Object.values(selectedFilters).some((f) => f?.length)
        ? Object.values(selectedFilters).map((filters) => ({
            [SEARCH_EXPRESSIONS.ANY]: filters?.map((filter) => filter.searchExpression) || [],
          }))
        : [],
    }));
  }, []);

  // apply new search term, will resest the page back to 1
  const updateSearchExpressions = useCallback((searchExpressions: ISearchExpression[]) => {
    setDirty(true);
    setDatatableState((current) => ({
      ...current,
      currentItemOffset: TABLE_DEFAULTS.ITEM_OFFSET,
      activePage: TABLE_DEFAULTS.PAGE,
      searchExpressions,
    }));
  }, []);

  const updateSelectedRows = useCallback((rows: any[]) => {
    setDirty(true);
    setDatatableState((current) => ({
      ...current,
      selectedRows: rows,
    }));
  }, []);

  const updateSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setDirty(true);
    setDatatableState((current) => ({
      ...current,
      sort: [{ field, direction }],
    }));
  }, []);

  const getElasticQuery = useCallback(() => {
    return {
      filter: { [SEARCH_EXPRESSIONS.ALL]: [...datatableState.searchExpressions, ...datatableState.filterExpressions] },
      sort: datatableState.sort,
      size: datatableState.pageSize,
      from: datatableState.currentItemOffset,
    };
  }, [datatableState]);

  useEffect(() => {
    const datatableStateWithFullQuery = { ...datatableState, query: getElasticQuery() };
    localStorage.setItem(`${user?.email}-${name}TableState`, JSON.stringify(datatableStateWithFullQuery));
  }, [datatableState, getElasticQuery, name, user]);

  return [
    datatableState,
    {
      changePage,
      changeSizePerPage,
      updateSelectedFilters,
      updateSearchExpressions,
      updateSelectedRows,
      updateSort,
      getElasticQuery,
    },
    setDatatableState, // can be used directly to update the state if the provided controls don't satisfy your needs
    isDirty,
  ];
};

export default useDatatableState;
