import React, { useMemo } from 'react';
import Select from 'shared/components/Select';
import { Row, Col as Column } from 'shared/components/Layout';
import { useGetAllergens } from 'gql/allergen/queries';
import { sortBy } from 'lodash';
import { useGetHealthTerms } from 'gql/healthTerm/queries';

const ALLERGEN_OPTIONS = [
  { label: 'Food', value: 'Food' },
  { label: 'Skin / Topical', value: 'SkinTopical' },
  { label: 'Insect', value: 'Insect' },
  { label: 'Medication', value: 'Medication' },
  { label: 'Environmental', value: 'Environmental' },
];

interface IProps {
  selectedAllergenType: IHealthTerm | null;
  selectedAllergen: IAllergen | null;
  onSelectAllergenType: (type: IHealthTerm) => void;
  onSelectAllegen: (allergen: IAllergen) => void;
  disableSelects: boolean;
}

const AllergenSelect: React.FC<IProps> = ({
  selectedAllergenType,
  selectedAllergen,
  onSelectAllergenType,
  onSelectAllegen,
  disableSelects = false,
  ...props
}) => {
  const { loading, data: allergenData } = useGetAllergens();
  const { loading: healthTermsLoading, data: healthTermsData } = useGetHealthTerms();
  const allergenTypeOptions = useMemo(
    () => healthTermsData?.getHealthTerms.filter((term) => term.type === 'Allergy' && !term.archivedAt) ?? [],
    [healthTermsData]
  );
  const allergenOptions: IAllergen[] = sortBy(
    allergenData?.getAllergens.filter((a) => !a.archivedAt) ?? [],
    (option: IAllergen) => option.name
  );

  return (
    <>
      <Row>
        <Column>
          <Select
            required
            label="Type"
            value={selectedAllergenType}
            options={allergenTypeOptions}
            onChange={(option) => onSelectAllergenType(option)}
            getOptionLabel={(option: IHealthTerm) => option.name}
            disabled={disableSelects}
            isLoading={healthTermsLoading}
            isOptionSelected={(option: IHealthTerm) => selectedAllergenType && option.id === selectedAllergenType.id}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Select
            required
            isLoading={loading}
            label="Name"
            value={selectedAllergen}
            options={allergenOptions.filter((allergen) => allergen.type.id === selectedAllergenType?.id)}
            formatOptionLabel={(option: IAllergen) => option.name}
            onChange={onSelectAllegen}
            isOptionSelected={(option: IAllergen) => selectedAllergen && option.id === selectedAllergen.id}
            filterOption={(option: { data: IAllergen }, filterString: string) =>
              option.data.name.toLowerCase().includes(filterString.toLowerCase())
            }
            disabled={disableSelects}
          />
        </Column>
      </Row>
    </>
  );
};

export default AllergenSelect;
