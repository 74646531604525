import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import Button from 'react-bootstrap/Button';
import { usePermission } from '../../hooks/usePermission';

const ReviewButton = ({ onClick, ...props }) => {
  const isPermitted = usePermission(props.permission);
  return (
    <Button onClick={onClick} {...props} disabled={!isPermitted || (isPermitted && props.disabled)}>
      <span className="pr-2">{props.children}</span>
      <FontAwesomeIcon icon={faAngleRight} />
    </Button>
  );
};

ReviewButton.propTypes = {
  onClick: PropTypes.func,
  permission: PropTypes.object,
};

export default ReviewButton;
