import {
  CentersActionTypes,
  GET_CENTERS_SUCCESS,
  GET_CENTER_SUCCESS,
  CREATE_CENTER,
  UPDATE_CENTER,
  UPDATE_CENTERS_TABLE_FILTERS,
  REMOVE_CENTER_FROM_STORE,
} from './types';

export interface ICentersStateShape {
  meta: {
    activeFilters: {
      status: {
        // values provided in CenterStatusSearchExpressions.ts
        active: boolean;
        deactivated: boolean;
        deleted: boolean;
      };
    };
  };
  all: ICenter[];
  byId: Record<string, ICenter>;
}

const initialState: ICentersStateShape = {
  meta: {
    activeFilters: {
      status: {
        active: false,
        deactivated: false,
        deleted: false,
      },
    },
  },
  all: [],
  byId: {},
};

export const centersReducers = (
  state: ICentersStateShape = initialState,
  action: CentersActionTypes
): ICentersStateShape => {
  const byId: Record<string, ICenter> = { ...state.byId };

  switch (action.type) {
    case GET_CENTERS_SUCCESS:
      return {
        ...state,
        all: action.centers,
        byId: action.centers.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: {
              ...(state.byId[curr.id] ?? {}),
              ...curr,
            },
          }),
          {}
        ),
      };
    case GET_CENTER_SUCCESS:
    case UPDATE_CENTER:
      byId[action.center.id] = {
        ...byId[action.center.id],
        ...action.center,
      };

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case CREATE_CENTER:
      byId[action.center.id] = action.center;

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case REMOVE_CENTER_FROM_STORE:
      if (byId[action.id]) {
        delete byId[action.id];
      }

      return {
        ...state,
        all: Object.values(byId),
        byId,
      };
    case UPDATE_CENTERS_TABLE_FILTERS:
      return {
        ...state,
        meta: {
          ...state.meta,
          activeFilters: {
            ...state.meta.activeFilters,
            status: {
              ...state.meta.activeFilters.status,
              ...action.filters.status,
            },
          },
        },
      };
    default:
      return state;
  }
};
