import { enrollmentProgramFields } from 'gql/enrollment/fields';

export const applicationFields: string = `
  id
  state
  enquiryDate
  firstName
  lastName
  phoneNumber
  email
  accountName
  state
  centers
  children {
    id
    firstName
    lastName
    dateOfBirth
    enquiries {
      id
      careType
      startDate
      days {
        id
        weekType
        dayOfWeek
      }
    }
    offers {
      id
      careType
      startDate
      applicationOfferId
      feeId
      classId
      fee {
        name
      }
      class {
        name
      }
      days {
        id
        weekType
        dayOfWeek
      }
      program {
        ${enrollmentProgramFields}
      } 
    }
  }
  offers {
    id
    linkCode
    enrolmentFormPdfLink
  }

`;

export const pagedApplicationFields = `
  data {
    ${applicationFields}
  }
  pageNumber
  pageSize
  totalRecords
`;

export const applicationOfferFields = `
  
`;
export const offerScheduleFields = `
  id
  classId
  careType
  startDate
  offerCenterId
  applicationOfferId
  cycleType
  days {
    id
    weekType
    dayOfWeek
  }
`;

export const offerFields = `
  id
  enrollmentForm
  applicationScheduleOfferIds
  enrolmentFormDataRecordId
`;
