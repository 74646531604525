import { useGetCenterSpaces } from 'gql/center/queries';
import { useGetSessionDataForChildSelect } from 'gql/session/queries';
import { sortBy } from 'lodash';
import moment from 'moment';
import { GET_CENTER_SPACES } from 'pages/Centers/subroutes/Profile/graphql/fields';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import BaseSelect from 'shared/components/Select/Base';
import Alert from 'shared/components/Alert';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { capitalize } from 'shared/util/string';

interface IFormStateShape {
  centerId: string;
  spaceId: string | null;
  childId: string | null;
  startDate: string | null;
  endDate: string | null;
  format: 'EXCEL' | 'PDF';
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  failedReportDownload: string | null;
  onSubmit: (
    centerId: string,
    startDate: string,
    endDate: string,
    spaceId: string | null,
    childId: string | null,
    format: 'EXCEL' | 'PDF'
  ) => void;
  onClose: () => void;
  resetFailedDownload: () => void;
  showReportFormat?: boolean;
  className?: string;
}

enum OptionalFilters {
  NONE = 'None',
  SPACE = 'Space',
  CHILD = 'Child',
}

const TimeframeChildSpaceReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  failedReportDownload,
  showReportFormat,
  resetFailedDownload,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<IFormStateShape>({
    centerId: '',
    spaceId: '',
    childId: '',
    startDate: null,
    endDate: null,
    format: 'EXCEL',
  });
  const centerSelectOptions: ICenter[] = useGetActiveCenters();

  const { data: sessionData, loading: childrenLoading } = useGetSessionDataForChildSelect({
    variables: {
      input: {
        centerId: formData.centerId,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
    },
  });

  const childSelectOptions =
    sortBy(
      sessionData?.getExpectedSessions.map((s) => ({
        label: `${s.child.firstname} ${s.child.lastname}`,
        value: s.accountChildId,
      })),
      'label'
    ) ?? [];

  const { data: spaceData, loading: spacesLoading } = useGetCenterSpaces(
    {
      variables: {
        centerId: formData.centerId ?? '',
      },
    },
    GET_CENTER_SPACES
  );

  const spaceSelectOptions =
    sortBy(
      spaceData?.getSpacesForCenter.map((s) => ({
        label: s.name,
        value: s.id,
      })),
      'label'
    ) ?? [];

  useEffect(() => {
    if (!isOpen) {
      clearTimeout(submitTimer);
      setFormData({
        centerId: '',
        spaceId: '',
        childId: '',
        startDate: null,
        endDate: null,
        format: 'PDF',
      });
    }
  }, [isOpen]);

  let submitTimer;

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (formData.centerId && formData.startDate && formData.endDate) {
      onSubmit(
        formData.startDate,
        formData.endDate,
        formData.centerId,
        formData.spaceId,
        formData.childId,
        formData.format
      );
      if (!!submitTimer) {
        clearTimeout(submitTimer);
      }
      submitTimer = setTimeout(() => {
        handleSubmit();
      }, 3 * 55 * 1000);
    }
  }, [formData, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({
      centerId: '',
      spaceId: '',
      childId: '',
      startDate: null,
      endDate: null,
      format: 'EXCEL',
    });
    onClose();
  }, [onClose]);

  const [selectedOptionalFilter, setSelectedOptionalFilter] = useState(OptionalFilters.NONE);

  useEffect(() => {
    if (selectedOptionalFilter === OptionalFilters.SPACE) {
      setFormData((prev) => ({ ...prev, childId: '' }));
    }
    if (selectedOptionalFilter === OptionalFilters.CHILD) {
      setFormData((prev) => ({ ...prev, spaceId: '' }));
    }
  }, [selectedOptionalFilter]);

  const downloadReport = () => {
    if (failedReportDownload) {
      window.open(failedReportDownload);
      handleClose();
    }
  };

  useEffect(() => {
    if (failedReportDownload) resetFailedDownload();
  }, [formData]);

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice={failedReportDownload ? 'Download Report' : 'Run Export'}
      primaryCallback={() => {
        failedReportDownload ? downloadReport() : handleSubmit();
      }}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled:
          !failedReportDownload &&
          (!formData.centerId ||
            !formData.startDate ||
            !formData.endDate ||
            (selectedOptionalFilter === OptionalFilters.SPACE && !formData.spaceId) ||
            (selectedOptionalFilter === OptionalFilters.CHILD && !formData.childId)),
        loading: isLoading,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      className={className}
    >
      <Row>
        <Col>
          <Select
            required
            id="center-input"
            label={capitalize(t('spelling.center'))}
            aria-label={`Select ${capitalize(t('spelling.center'))}`}
            value={formData.centerId}
            options={centerSelectOptions.map((c) => ({ value: c.id, label: c.name }))}
            onChange={(option: any) => setFormData((prev) => ({ ...prev, centerId: option.value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            required
            label={capitalize(t('spelling.start-date'))}
            date={formData.startDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, startDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(date: moment.Moment) =>
              formData.endDate ? date.isSameOrAfter(moment(formData.endDate), 'date') : false
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            required
            label={capitalize(t('spelling.end-date'))}
            date={formData.endDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, endDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(date: moment.Moment) =>
              formData.startDate ? date.isSameOrBefore(moment(formData.startDate), 'date') : false
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>Additional Filter</Form.Label>
          {Object.values(OptionalFilters).map((format) => {
            return (
              <div className="d-block">
                <input
                  type="radio"
                  name="report-format"
                  checked={selectedOptionalFilter === format}
                  onChange={() => setSelectedOptionalFilter(format)}
                />
                <label htmlFor="report-format" className="pl-2">
                  {format}
                </label>
              </div>
            );
          })}
        </Col>
      </Row>
      {selectedOptionalFilter === OptionalFilters.SPACE && (
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Space</Form.Label>
              <BaseSelect
                required
                id="class-input"
                isLoading={spacesLoading}
                aria-label={`Select Space`}
                options={[...spaceSelectOptions]}
                onChange={(option: any) => setFormData((prev) => ({ ...prev, spaceId: option.value }))}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {selectedOptionalFilter === OptionalFilters.CHILD && (
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Child</Form.Label>
              <BaseSelect
                required
                id="class-input"
                isLoading={childrenLoading}
                aria-label={`Select Child`}
                options={[...childSelectOptions]}
                onChange={(option: any) => setFormData((prev) => ({ ...prev, childId: option.value }))}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {showReportFormat && (
        <Row>
          <Col>
            <Select
              options={[
                { value: 'EXCEL', label: 'EXCEL' },
                { value: 'PDF', label: 'PDF' },
              ]}
              label={`${capitalize(t('spelling.format'))}(s)`}
              value={formData.format ?? { value: 'EXCEL', label: 'EXCEL' }}
              onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
              className="mb-0"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Col>
        </Row>
      )}
      {!!failedReportDownload && (
        <Alert className="mt-4" variant="success">
          <p className="mb-0">
            Your report was succesfully generated and can now be{' '}
            <a onClick={downloadReport} className="font-size-18 font-weight-normal cursor-pointer">
              downloaded
            </a>
            .
          </p>
        </Alert>
      )}
    </SideModalDrawer>
  );
};

export default TimeframeChildSpaceReportModal;
