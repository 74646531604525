import React, { useCallback, Fragment, useMemo } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';
import Form from 'react-bootstrap/Form';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import Select from 'shared/components/Select';
import TimeInput from 'shared/components/TimePicker/TimeInput';
import { getFullName, getInitials, stringToHsl } from 'shared/util/string';
import AvatarContent from 'shared/components/Avatar/AvatarContent';
import { useGetClassesForCenter } from 'gql/class/queries';
import { useGetFeesByClassId } from 'gql/fee/queries';
import { IconButtonCircle } from 'shared/components/Buttons';
import { INewTimeEntryShape, ISessionUpdateShape } from './EditSessionModal';
import { isTimeRangeInvalid } from 'shared/util/timeUtils';
import TextInput from 'shared/components/TextInput';
import { isRegion } from 'shared/util/region';
import { useTranslation } from 'react-i18next';

interface IProps {
  session: ISessionUpdateShape;
  timezone: Timezone;
  overlappingTimeframes: boolean;
  onUpdate: (session: ISessionUpdateShape) => void;
  updateDropOffPickUp: (time: string, direction: 'dropOff' | 'pickUp') => void;
}

const EditSessionGroup: React.FC<IProps> = ({
  session,
  timezone,
  overlappingTimeframes,
  onUpdate,
  updateDropOffPickUp,
  ...props
}) => {
  const isDateInThePast = useMemo(
    () => moment(session.date).tz(timezone).isBefore(moment().tz(timezone), 'd'),
    [session.date, timezone]
  );
  const isLateSubmission = useMemo(
    () => moment().diff(moment(session.date).startOf('week').tz(timezone), 'days') > 28,
    [session.date, timezone]
  );

  const { t } = useTranslation();
  const isAuRegion = isRegion('AU');

  const { loading: classesForCenterLoading, data: classesForCenterData } = useGetClassesForCenter({
    variables: {
      centerId: session.centerId ?? '',
    },
    skip: !session.centerId,
  });
  const { loading: feesByClassLoading, data: feesForClassData } = useGetFeesByClassId({
    variables: {
      classId: session.classId ?? '',
    },
    skip: !session.classId,
  });

  const classOptions = useMemo(() => {
    const filteredOptions = (classesForCenterData?.getClassesForCenter ?? []).filter(
      (c) => !c.archivedAt && (!c.endsAt || moment(session.date).isBefore(moment(c.endsAt)))
    );

    return orderBy(filteredOptions, (c) => c.name, 'asc');
  }, [classesForCenterData, session.date]);
  const feeOptions = useMemo(
    () =>
      orderBy(
        (feesForClassData?.getFeesByClassId ?? []).filter((f) => !f.deactivatedAt),
        (f) => f.name,
        'asc'
      ),
    [feesForClassData]
  );

  const convertDateTimeToTimeString = useCallback(
    (datetime: string | null): string | null => {
      // incoming string is a datetime, attempt to parse to its time format. otherwise leave as-is
      return datetime !== null && moment(datetime, moment.ISO_8601, true).isValid()
        ? moment(datetime).tz(timezone).format('HH:mm')
        : datetime;
    },
    [timezone]
  );

  /**
   * update an existing time entry record with a new time in/out
   * IMPORANT: expects the time entry to already be in the session's time entries array
   */
  const updateTimeEntry = useCallback(
    (timeEntry: IAttendanceTimeEntry, time: string, direction: 'in' | 'out') => {
      const updatedSession = { ...session };
      const updatedTimeEntry = { ...timeEntry };
      const updatedTimeEntries = [...updatedSession.timeEntries];

      if (direction === 'in') {
        updatedTimeEntry.timeIn = time;
      } else {
        updatedTimeEntry.timeOut = time;
      }

      const idxToUpdate = updatedTimeEntries.map((te) => te.id).indexOf(timeEntry.id);

      if (idxToUpdate !== -1) {
        updatedTimeEntries[idxToUpdate] = updatedTimeEntry;
        updatedSession.timeEntries = updatedTimeEntries;
      }

      onUpdate(updatedSession);
    },
    [onUpdate, session]
  );

  /**
   * update a "new" time entry object. this time entry exists in local state but does NOT have a record in the database
   * since we don't have an id to use to lookup and update the array, `idx` is the index this object falls in `session.newTimeEntries`
   */
  const updateNewTimeEntry = useCallback(
    (time: string, direction: 'in' | 'out', idx: number) => {
      const updatedSession = { ...session };
      const updatedTimeEntries = [...(updatedSession.newTimeEntries ?? [])];
      const newTimeEntry = updatedTimeEntries[idx];

      if (direction === 'in') {
        newTimeEntry.timeIn = time;
      } else {
        newTimeEntry.timeOut = time;
      }

      updatedTimeEntries[idx] = newTimeEntry;
      updatedSession.newTimeEntries = updatedTimeEntries;

      onUpdate(updatedSession);
    },
    [onUpdate, session]
  );

  const addNewTimeEntryGroup = useCallback(() => {
    const updatedSession = { ...session };
    const newTimEntry: INewTimeEntryShape = {
      sessionId: session.id ?? '',
      date: session.date,
      timeIn: null,
      timeOut: null,
    };

    if (!updatedSession.newTimeEntries) {
      updatedSession.newTimeEntries = [newTimEntry];
    } else {
      updatedSession.newTimeEntries = [...updatedSession.newTimeEntries, newTimEntry];
    }

    onUpdate(updatedSession);
  }, [onUpdate, session]);

  /**
   * time entries can only be created for a session if the session is for today or prior to today
   * and if the last time entry has been closed
   */
  const showAddTimeEntryButton = useCallback(
    (session: ISessionUpdateShape) => {
      let validNewEntries = true;

      if (Boolean(session.newTimeEntries) && (session.newTimeEntries ?? []).length > 0) {
        // @ts-ignore - boolean will check for undefined and null that typescript was complaining about
        const lastEntry = session.newTimeEntries[session.newTimeEntries.length - 1] as INewTimeEntryShape;

        validNewEntries =
          lastEntry.timeIn !== null &&
          lastEntry.timeOut !== null &&
          moment(lastEntry.timeIn).isBefore(moment(lastEntry.timeOut));
      }

      return (
        moment(session.date).tz(timezone).isSameOrBefore(moment().tz(timezone), 'date') &&
        (session.timeEntries.length > 0
          ? session.timeEntries[session.timeEntries.length - 1].timeOut !== null
          : true) &&
        validNewEntries
      );
    },
    [timezone]
  );

  return (
    <>
      <div className="d-flex flex-row mb-4">
        <div className="d-flex flex-column flex-1 mr-4">
          <Select
            label="Class"
            options={classOptions}
            onChange={(option: IClass) =>
              onUpdate({
                ...session,
                classId: option.id,
                class: option,
                feeId: null,
                fee: null,
              })
            }
            value={session.class}
            isLoading={classesForCenterLoading}
            getOptionLabel={(option: IClass) => option.name}
            getOptionValue={(option: IClass) => option.id}
            maxMenuHeight={160}
          />
        </div>
        <div className="d-flex flex-column flex-1 mr-4">
          <Select
            label="Fee"
            options={feeOptions}
            onChange={(option: IFee) => onUpdate({ ...session, feeId: option.id, fee: option })}
            value={session.fee}
            isLoading={feesByClassLoading}
            getOptionLabel={(option: IFee) => option.name}
            getOptionValue={(option: IFee) => option.id}
            maxMenuHeight={160}
          />
        </div>
        <div className="d-flex flex-column flex-grow-1 mr-4">
          <div className="d-flex flex-row">
            <div className="d-flex mr-2">
              <Form.Group>
                <Form.Label>Drop Off</Form.Label>
                <TimeInput
                  isAM={false}
                  value={session.dropOffTime}
                  onChange={(dropOffTime) => updateDropOffPickUp(dropOffTime, 'dropOff')}
                />
              </Form.Group>
            </div>
            <div className="d-flex mr-2 flex-grow-0">
              <Form.Group>
                <Form.Label>Pick Up</Form.Label>
                <TimeInput
                  isAM={true}
                  value={session.pickUpTime}
                  onChange={(pickUpTime) => updateDropOffPickUp(pickUpTime, 'pickUp')}
                />
              </Form.Group>
            </div>
          </div>
          {Boolean(isTimeRangeInvalid(session.dropOffTime, session.pickUpTime) ?? false) && (
            <div className="text-danger small mb-2 mt-n2">Invalid timeframe.</div>
          )}
          {session.timeEntries.length > 0 &&
            session.timeEntries.map((timeEntry: IAttendanceTimeEntry, idx: number) => (
              <div className="d-flex flex-column" key={`time-entry-in-${idx}`}>
                <div className="d-flex flex-row align-items-center">
                  <div className="d-flex mr-2">
                    <Form.Group>
                      <Form.Label>Check In</Form.Label>
                      <TimeInput
                        isAM={true}
                        value={convertDateTimeToTimeString(timeEntry.timeIn)}
                        onChange={(time) => updateTimeEntry(timeEntry, time, 'in')}
                      />
                    </Form.Group>
                  </div>
                  <div className="d-flex mr-2">
                    <Form.Group>
                      <div className="d-flex flex-column">
                        <Form.Label>By</Form.Label>
                        <div>
                          <div className="avatar d-inline-block float-none mr-2">
                            <AvatarContent
                              size="xs"
                              image={timeEntry.timeInBy.avatar?.url ?? ''}
                              initials={getInitials(timeEntry.timeInBy)}
                              alt={`${getFullName(timeEntry.timeInBy)} avatar`}
                              color={stringToHsl(timeEntry.timeInBy?.id ?? '1')}
                            />
                          </div>
                          <span>{getFullName(timeEntry.timeInBy)}</span>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div className="d-flex mr-2">
                    <Form.Group>
                      <Form.Label>Check Out</Form.Label>
                      <TimeInput
                        isAM={false}
                        value={convertDateTimeToTimeString(timeEntry.timeOut ?? null)}
                        onChange={(time) => updateTimeEntry(timeEntry, time, 'out')}
                      />
                    </Form.Group>
                  </div>
                  {timeEntry.timeOut && (
                    <div className="d-flex mr-2">
                      <Form.Group>
                        <Form.Label>By</Form.Label>
                        <div className="text-dark mb-2 d-flex" key={`time-entry-out-${timeEntry.id}-${idx}`}>
                          {timeEntry.timeOut && timeEntry.timeOutBy ? (
                            <Fragment>
                              <div className="avatar d-inline-block float-none mr-2">
                                <AvatarContent
                                  size="xs"
                                  image={timeEntry.timeOutBy?.avatar?.url ?? ''}
                                  initials={getInitials(timeEntry.timeOutBy)}
                                  alt={`${getFullName(timeEntry.timeOutBy)} avatar`}
                                  color={stringToHsl(timeEntry.timeOutBy?.id ?? '1')}
                                />
                              </div>
                              <span>{getFullName(timeEntry.timeOutBy)}</span>
                            </Fragment>
                          ) : null}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                </div>
                {Boolean(timeEntry.timeOut && !timeEntry.timeIn) && (
                  <div className="text-danger small mb-2 mt-n2">Must have a checkin time entered.</div>
                )}
                {timeEntry.timeOut &&
                  isTimeRangeInvalid(
                    convertDateTimeToTimeString(timeEntry.timeIn),
                    convertDateTimeToTimeString(timeEntry.timeOut)
                  ) && <div className="text-danger small mb-2 mt-n2">Invalid timeframe.</div>}
              </div>
            ))}
          {(session.newTimeEntries ?? []).map((newTimeEntry: INewTimeEntryShape, idx: number) => (
            <div className="d-flex flex-column" key={`new-time-entry-${idx}`}>
              <div className="d-flex flex-row align-items-center">
                <div className="d-flex mr-2">
                  <Form.Group>
                    <Form.Label>Check In</Form.Label>
                    <TimeInput
                      isAM={true}
                      value={newTimeEntry.timeIn}
                      onChange={(time) => updateNewTimeEntry(time, 'in', idx)}
                    />
                  </Form.Group>
                </div>
                <div className="d-flex">
                  <Form.Group>
                    <Form.Label>Check Out</Form.Label>
                    <TimeInput
                      isAM={false}
                      value={newTimeEntry.timeOut}
                      onChange={(time) => updateNewTimeEntry(time, 'out', idx)}
                    />
                  </Form.Group>
                </div>
              </div>
              {Boolean(newTimeEntry.timeOut && !newTimeEntry.timeIn) && (
                <div className="text-danger small mb-2 mt-n2">Must have a checkin time entered.</div>
              )}
              {isTimeRangeInvalid(newTimeEntry.timeIn, newTimeEntry.timeOut) && (
                <div className="text-danger small mb-2 mt-n2">Invalid timeframe.</div>
              )}
            </div>
          ))}
          {overlappingTimeframes && (
            <div className="text-danger small">One or more of the provided timeframes overlap.</div>
          )}
          {showAddTimeEntryButton(session) && (
            <IconButtonCircle icon={faPlus} onClick={() => addNewTimeEntryGroup()} tooltipText="Add Time Entry" />
          )}
        </div>
      </div>
      {isLateSubmission && isAuRegion && (
        <div className="d-flex flex-row mb-4">
          <div className="d-flex flex-column flex-1 mr-4">
            <TextInput
              name="lateSubmissionReason"
              label={t(`attendance.checkinout.reasonForLateChangeLabel`)}
              required
              value={session.reasonForLateChange}
              onChange={(value: string) => onUpdate({ ...session, reasonForLateChange: value })}
            />
          </div>
          <div className="d-flex flex-column flex-1 mr-4"></div>
        </div>
      )}
    </>
  );
};

export default EditSessionGroup;
