import moment from 'moment';

const useDateDiffInDays = (start: string, end: string): number => {
  const startDate = moment(start);
  const endDate = moment(end);

  const days = moment.duration(startDate.diff(endDate)).asDays();
  return Math.ceil(days);
};

export default useDateDiffInDays;
