import * as React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import rootReducer from './store/reducers';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1Bold: React.CSSProperties;
    pageHeader: React.CSSProperties;
    tableHeadCells: React.CSSProperties;
    cardHeader: React.CSSProperties;
    bodyDisabled: React.CSSProperties;
    dialogTitle: React.CSSProperties;
    inputLabel: React.CSSProperties;
    small: React.CSSProperties;
  }

  interface ButtonVariants {
    delete: React.CSSProperties;
    secondaryDelete?: React.CSSProperties;
    cancel: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1Bold?: React.CSSProperties;
    pageHeader?: React.CSSProperties;
    tableHeadCells?: React.CSSProperties;
    cardHeader?: React.CSSProperties;
    bodyDisabled?: React.CSSProperties;
    dialogTitle?: React.CSSProperties;
    inputLabel?: React.CSSProperties;
    small?: React.CSSProperties;
  }

  interface ButtonVariantsOptions {
    delete?: React.CSSProperties;
    secondaryDelete?: React.CSSProperties;
    cancel?: React.CSSProperties;
  }

  interface Palette {
    money: Palette['primary'];
  }

  interface PaletteOptions {
    money: PaletteOptions['primary'];
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Bold: true;
    pageHeader: true;
    tableHeadCells: true;
    cardHeader: true;
    bodyDisabled: true;
    dialogTitle: true;
    inputLabel: true;
    small: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    money: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    delete: true;
    secondaryDelete: true;
    cancel: true;
  }
}
const basicTheme = createTheme({});

const fontFamily = '"proxima-soft", "sans-serif"';

const k2Purple = '#513C5E';
const k2PurpleHover = '#684e79';
const k2Pink = '#ff005e';
const k2PinkHover = '#e60054';
const backgroundColor = '#F8FAFB';
const borderColor = '#9eb0c7';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: k2Purple,
    },
    secondary: {
      main: k2Pink,
    },
    background: {
      default: backgroundColor,
    },
    warning: {
      main: '#fdf0cc',
      dark: '#e1c06a',
    },
    success: {
      main: '#18a957',
    },
    info: {
      main: '#1579C6',
    },
    error: {
      main: '#B81111',
    },
    money: {
      main: '#4FA760',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: fontFamily,
    h1: {
      fontSize: '42',
      fontWeight: 500,
      fontFamily: fontFamily,
    },
    h1Bold: {
      fontSize: '42',
      fontWeight: 700,
      fontFamily: fontFamily,
    },
    h2: {
      fontSize: '30',
      fontWeight: 500,
      fontFamily: fontFamily,
    },
    h3: {
      fontSize: '28',
      fontWeight: 300,
      fontFamily: fontFamily,
    },
    h4: {
      fontSize: '24',
      fontWeight: 400,
      fontFamily: fontFamily,
    },
    h5: {
      fontSize: '19',
      fontWeight: 500,
      fontFamily: fontFamily,
    },
    h6: {
      fontSize: '17',
      fontWeight: 500,
      fontFamily: fontFamily,
    },
    body1: {
      fontSize: '1rem',
      color: '#516174',
      fontWeight: 400,
      fontFamily: '"Open Sans", sans-serif',
    },
    body2: {
      fontSize: '13px',
      color: '#8f9bad',
    },
    pageHeader: {
      fontFamily: fontFamily,
      fontWeight: 700,
      fontSize: '1.75rem',
      color: '#273142',
    },
    tableHeadCells: {
      fontFamily: fontFamily,
      fontWeight: 700,
      fontSize: '16px',
      color: '#273142',
    },
    cardHeader: {
      fontFamily: fontFamily,
      fontWeight: 500,
      fontSize: '24',
    },
    dialogTitle: {
      fontFamily: fontFamily,
      fontWeight: 700,
      fontSize: '1.75rem',
      display: 'flex',
      alignItems: 'center',
    },
    bodyDisabled: {
      fontSize: '1rem',
      color: '#B3BECC',
    },
    inputLabel: {
      fontSize: '1rem',
      color: '#516174',
      fontWeight: 400,
      fontFamily: '"Open Sans",sans-serif',
      lineHeight: '1.5',
    },
    small: {
      color: '#8e9bac',
      fontSize: '0.875em',
      fontWeight: 400,
    },
  },
  components: {
    MuiAutocomplete: { styleOverrides: { root: { backgroundColor: 'white' } } },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          // paddingLeft: '24px',
          // paddingRight: '24px',
        },
      },
      variants: [
        {
          props: { variant: 'delete' },
          style: {
            backgroundColor: '#b81111',
            color: 'white',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#8a0f0f',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { variant: 'secondaryDelete' },
          style: {
            border: '2px solid #b81111',
            color: '#b81111',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#dbd7d7',
            },
          },
        },
        {
          props: { variant: 'cancel' },
          style: {
            backgroundColor: '#f8fafb',
            color: '#22252A',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#CFD9E7',
              boxShadow: 'none',
            },
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${borderColor}`,
          },
        },
      ],
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #E2E7EE',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #E2E7EE',
          zIndex: 'z-index: 9999999999 !important;',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0 8px 16px 0 rgba(169, 194, 209, 0.1)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: 'white',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          display: 'flex',
          height: 35,
          justifyContent: 'center',
          padding: 0,
          width: 90,
        },
        switchBase: {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          transform: 'translateX(8px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(50px)',
            '& + .MuiSwitch-track': {
              backgroundColor: '#17AA57',
              border: 0,
              opacity: 1,
              '&:before': {
                color: 'white',
                content: "'ON'",
                display: 'flex',
                paddingLeft: '12px',
                paddingTop: '7px',
              },
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            border: '6px solid #fff',
            color: '#33cf4d',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: basicTheme.palette.grey[100],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
        },
        thumb: {
          boxSizing: 'border-box',
          height: 28,
          marginTop: '1px',
          width: 28,
        },
        track: {
          backgroundColor: '#C5D0DE',
          borderRadius: 49 / 2,
          opacity: 1,
          transition: basicTheme.transitions.create(['background-color'], {
            duration: 500,
          }),
          width: 80,
          '&:after': {
            color: '#516174',
            content: "'OFF'",
            display: 'flex',
            paddingRight: '12px',
            paddingTop: '7px',
            justifyContent: 'flex-end',
          },
          MuiTableRow: {
            styleOverrides: {
              root: {
                '&:nth-child(even)': {
                  backgroundColor: '#F1F4F8',
                },
              },
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '0.5rem',
          // boxShadow: 'none',
          border: '0.1rem solid #eaecf0',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1970px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundColor,
          border: 0,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '125',
          padding: '80px',
          borderBottom: 'solid 15px #F8FAFB',
          borderTop: 'solid 15px #F8FAFB',
          'th:first-of-type': {
            borderTopLeftRadius: '32px',
            borderBottomLeftRadius: '32px',
          },
          'th:last-of-type': {
            borderTopRightRadius: '32px',
            borderBottomRightRadius: '32px',
          },
          'td:first-of-type': {
            borderTopLeftRadius: '32px',
            borderBottomLeftRadius: '32px',
          },
          'td:last-of-type': {
            borderTopRightRadius: '32px',
            borderBottomRightRadius: '32px',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '125',
          padding: '80px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottom: 'solid 15px #F8FAFB',
          borderTop: 'solid 15px #F8FAFB',
          'th:first-of-type': {
            borderTopLeftRadius: '32px',
            borderBottomLeftRadius: '32px',
          },
          'th:last-of-type': {
            borderTopRightRadius: '32px',
            borderBottomRightRadius: '32px',
          },
          'td:first-of-type': {
            borderTopLeftRadius: '32px',
            borderBottomLeftRadius: '32px',
          },
          'td:last-of-type': {
            borderTopRightRadius: '32px',
            borderBottomRightRadius: '32px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", sans-serif',
          '&.Mui-disabled': {
            backgroundColor: '#F9FAFB',
            border: 'solid 1px #C5D0DE',
            borderRadius: '5px',
          },
        },
      },
    },
  },
});

const theme = responsiveFontSizes(defaultTheme);

export default theme;
