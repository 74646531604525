import { gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { billingCycleTemplateFields } from './fields';
import { getBillingCycleTemplates } from 'pages/Billing/Settings/duck/actions';
import { useK2Query } from 'shared/apis/k2Api';

interface IGetBillingCycleTemplatesData {
  getBillingCycleTemplates: IBillingCycleTemplate[];
}

interface IGetBillingCycleTemplatesVariables {
  centerIds?: string[];
}

interface IGetBillingCycleTemplateData {
  getBillingCycleTemplate: IBillingCycleTemplate;
}

interface IGetBillingCycleTemplateVariables {
  id: string;
}

interface IAccountAndBillingTemplatesQueryVariables {
  accountId: string;
}

interface IAccountAndBillingTemplatesQueryData {
  getAccountById: IAccount;
  getBillingCycleTemplates: IBillingCycleTemplate[];
}

interface IGetInvoiceDateQueryData {
  billingCycleInvoiceDate: string;
}

interface IGetInvoiceDateQueryVariables {
  message: ICreateAccountBillingCycleInput;
}

export const GET_BILLING_CYCLE_TEMPLATES = (fields: string = billingCycleTemplateFields) => gql`
  query ($centerIds: [ID!]) {
    getBillingCycleTemplates(centerIds: $centerIds) {
      ${fields}
    }
  }
`;

export const GET_BILLING_CYCLE_TEMPLATE = (fields: string = billingCycleTemplateFields) => gql`
  query($id: ID!) {
    getBillingCycleTemplate(id: $id) {
      ${fields}
    }
  }
`;

const ACCOUNT_AND_BILLING_TEMPLATES_QUERY = gql`
  query ($accountId: ID!) {
    getAccountById(id: $accountId) {
      id
      billingCycle {
        id
      }
    }

    getBillingCycleTemplates {
      id
      name
      cycleType
      period
      deactivatedAt
      frequency
      createdAt
      dayOfWeek
      dayOfMonth
      invoiceSchedule {
        unit
        value
      }
      additionalChargePeriod {
        unit
        value
      }
    }
  }
`;

const INVOICE_DATE_QUERY = gql`
  query ($message: CreateAccountBillingCycleMessageInput!) {
    billingCycleInvoiceDate(message: $message)
  }
`;

export const useGetBillingCycleTemplates = (
  options?: QueryHookOptions<IGetBillingCycleTemplatesData, IGetBillingCycleTemplatesVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();

  return useQuery<IGetBillingCycleTemplatesData, IGetBillingCycleTemplatesVariables>(
    GET_BILLING_CYCLE_TEMPLATES(fields),
    {
      onCompleted: (data) => {
        if (data?.getBillingCycleTemplates) {
          dispatch(getBillingCycleTemplates(data.getBillingCycleTemplates ?? []));
        }
      },
      ...options,
    }
  );
};

export const useGetBillingCycleTemplate = (
  options?: QueryHookOptions<IGetBillingCycleTemplateData, IGetBillingCycleTemplateVariables>,
  fields?: string
) =>
  useQuery<IGetBillingCycleTemplateData, IGetBillingCycleTemplateVariables>(GET_BILLING_CYCLE_TEMPLATE(fields), {
    ...options,
  });

export const useGetAccountAndBillingCycle = (
  options?: QueryHookOptions<IAccountAndBillingTemplatesQueryData, IAccountAndBillingTemplatesQueryVariables>
) =>
  useQuery<IAccountAndBillingTemplatesQueryData, IAccountAndBillingTemplatesQueryVariables>(
    ACCOUNT_AND_BILLING_TEMPLATES_QUERY,
    {
      ...options,
    }
  );

export const useGetBillingCycleInvoiceDate = (
  options?: QueryHookOptions<IGetInvoiceDateQueryData, IGetInvoiceDateQueryVariables>
) => useK2Query<IGetInvoiceDateQueryData, IGetInvoiceDateQueryVariables>(INVOICE_DATE_QUERY, { ...options });
