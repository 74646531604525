import React, { useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Button from 'shared/components/Buttons';
import { CodeInputs } from 'shared/components/TextInput';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (pin: string) => void;
  onClose: () => void;
}

const ChangePinModal: React.FC<IProps> = ({ isOpen, isLoading, onSubmit, onClose, ...props }) => {
  const [pinInputs, setPinInputs] = useState({
    new: '',
    confirmNew: '',
  });

  const dismissModal = useCallback(() => {
    setPinInputs({ new: '', confirmNew: '' });
    onClose();
  }, [onClose]);

  const validatePinForm = useCallback(() => {
    return /\d{4}/.test(pinInputs.new) && pinInputs.new === pinInputs.confirmNew;
  }, [pinInputs]);

  return (
    <Modal
      centered
      backdrop="static"
      show={isOpen}
      onHide={dismissModal}
      dialogClassName="kt-my-profile-change-pin-modal"
    >
      <Modal.Header closeButton className="border-bottom-0 pb-0">
        <Modal.Title as="h5">Change PIN</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="d-block mt-2 mb-4">Make sure your PIN is memorable.</span>
        <form autoComplete="off" onSubmit={() => onSubmit(pinInputs.new)}>
          <Row>
            <Column>
              <div className="mb-4">
                <CodeInputs
                  label="PIN"
                  fields={4}
                  type="number"
                  onChange={(value) => setPinInputs((prev) => ({ ...prev, new: value }))}
                  name="employee-change-pn"
                />
              </div>
              <div className="mb-8">
                <CodeInputs
                  label="Confirm PIN"
                  fields={4}
                  type="number"
                  onChange={(value) => setPinInputs((prev) => ({ ...prev, confirmNew: value }))}
                  name="employee-pn-confirm"
                />
              </div>
              <Button
                variant="primary"
                className="w-100"
                disabled={!validatePinForm() || isLoading}
                loading={isLoading}
                onClick={() => onSubmit(pinInputs.new)}
              >
                Save Changes
              </Button>
            </Column>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePinModal;
