import { faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { useCallback, useState } from 'react';
import { IconButtonCircle } from 'shared/components/Buttons';
import DataTable from 'shared/components/DataTable';

interface IProps {
  data: ICcmsApproval[];
  onDeleteClick?: (ccmsApproval: ICcmsApproval) => void;
  onEditClick?: (ccmsApproval: ICcmsApproval) => void;
}

const CcmsApprovalTable: React.FC<IProps> = ({ data, onDeleteClick, onEditClick }) => {
  return (
    <DataTable
      showSelect={false}
      showPagination={false}
      noPadding
      data={data}
      columns={[
        {
          text: 'CCMS Username',
          dataField: 'username',
          sort: true,
        },
        {
          text: 'Password',
          dataField: 'password',
          sort: true,
          formatter: () => '********', // Just fake password display as we never return it.
        },
        {
          text: 'Approval ID',
          dataField: 'approvalId',
          sort: true,
        },
        {
          text: '',
          dataField: '',
          sort: false,
          align: 'center',
          formatter: (_cell: string, row: ICcmsApproval) => (
            <>
              {onEditClick && (
                <IconButtonCircle className="ml-auto" onClick={() => onEditClick(row)} size="sm" icon={faPencilAlt} />
              )}
              {onDeleteClick && (
                <IconButtonCircle className="ml-4" onClick={() => onDeleteClick(row)} size="sm" icon={faTrashAlt} />
              )}
            </>
          ),
        },
      ]}
    />
  );
};

export default CcmsApprovalTable;
