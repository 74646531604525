import React from 'react';
import Select from 'shared/components/Select';
import absenceReasons, { IAbsenceReasonOption } from 'shared/constants/dropdownOptions/absenceReasons';
import { ISessionUpdateShape } from './EditSessionModal';

interface IProps {
  session: ISessionUpdateShape;
  onUpdate: (session: ISessionUpdateShape) => void;
}

const EditAbsenceGroup: React.FC<IProps> = ({ session, onUpdate, ...props }) => {
  // can't edit if there is no pre-existing absence
  if (!session.absence) {
    return null;
  }

  return (
    <div className="d-flex flex-row mb-4">
      <div className="d-flex flex-column flex-1 mr-4">
        <Select
          label="Reason for Absence"
          value={session.absence?.reason ?? null}
          options={absenceReasons}
          onChange={(option: IAbsenceReasonOption) =>
            onUpdate({
              ...session,
              absence: {
                ...(session.absence as IAbsence),
                reason: option.value,
              },
            })
          }
          getOptionLabel={(option: IAbsenceReasonOption) => option.label}
          getOptionValue={(option: IAbsenceReasonOption) => option.value}
          className="w-25"
        />
      </div>
    </div>
  );
};

export default EditAbsenceGroup;
