import { gql } from '@apollo/client';
import { useQuery, useLazyQuery } from 'shared/apis/core';
import { weeklySubsidyPagedResultsFields } from './fields';
import { QueryHookOptions } from '@apollo/client';

interface IGetWeeklySubsidySummaries {
  getWeeklySubsidySummaries: {
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: ISubsidyWeeklySummary[];
  };
}

interface IGetWeeklySubsidyCategories {
  getSubsidyCategories: {
    UNSUBMITTED: number;
    PENDING: number;
    ERRORED: number;
    FINALISED: number;
    unsubmittedEarliest?: Date;
    unsubmittedLatest?: Date;
    pendingEarliest?: Date;
    pendingLatest?: Date;
    erroredEarliest?: Date;
    erroredLatest?: Date;
    finalisedEarliest?: Date;
    finalisedLatest?: Date;
  };
}

interface IGetSessionById {
  getSessionById: ISession;
}

interface IGetSubsidyDashboard {
  getSubsidyDashboard: ISubsidyDashboard;
}

interface IGetSummariesVariables {
  input: ISubsidyWeeklySummaryInput;
}

interface IGetSubsidyCategoriesVariables {
  input: ISubsidyWeeklyCategoriesInput;
}
interface IGetSessionVariables {
  input: {
    sessionId: string;
  };
}

interface IGetSubsidyDashboardVariables {
  input: ISubsidyDashboardInput;
}

export const GET_WEEKLY_SUBSIDY_SUMMARIES = (fields: string = weeklySubsidyPagedResultsFields) => gql`
  query ($input: SubsidyWeeklySummaryInput!) {
    getWeeklySubsidySummaries(input: $input) {
      ${fields}
    }
  }
`;

export const GET_SESSION_BY_ID = gql`
  query ($input: GetSessionInput!) {
    getSessionById(input: $input) {
      void
      id
      date
      dropOffTime
      pickUpTime
      feeStartTime
      feeEndTime
      absence {
        id
        sessionId
        contractId
        date
        type
        reason
      }
      timeEntries {
        id
        sessionId
        contractId
        date
        timeIn
        timeOut
        timeInById
        timeOutById
      }
      subsidy {
        amount
      }
      metadata {
        isWritable
      }
    }
  }
`;

export const GET_SUBSIDY_DASHBOARD = gql`
  query ($input: SubsidyDashboardInput!) {
    getSubsidyDashboard(input: $input) {
      PENDING
      UNSUBMITTED
      ERRORED
    }
  }
`;

export const GET_SUBSIDY_CATEGORIES = gql`
  query ($input: SubsidyCategoriesInput!) {
    getSubsidyCategories(input: $input) {
      PENDING
      UNSUBMITTED
      FINALISED
      ERRORED
      unsubmittedEarliest
      unsubmittedLatest
      pendingEarliest
      pendingLatest
      erroredEarliest
      erroredLatest
      finalisedEarliest
      finalisedLatest
    }
  }
`;

export const useGetWeeklySubsidySummaries = (
  options?: QueryHookOptions<IGetWeeklySubsidySummaries, IGetSummariesVariables>,
  fields?: string
) =>
  useQuery<IGetWeeklySubsidySummaries, IGetSummariesVariables>(GET_WEEKLY_SUBSIDY_SUMMARIES(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetSessionById = (options?: QueryHookOptions<IGetSessionById, IGetSessionVariables>) =>
  useQuery(GET_SESSION_BY_ID, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetSubsidyDashboard = (
  options?: QueryHookOptions<IGetSubsidyDashboard, IGetSubsidyDashboardVariables>
) =>
  useQuery<IGetSubsidyDashboard, IGetSubsidyDashboardVariables>(GET_SUBSIDY_DASHBOARD, {
    fetchPolicy: 'cache-first',
    ...options,
  });

export const useGetSubsidyCategories = (
  options?: QueryHookOptions<IGetWeeklySubsidyCategories, IGetSubsidyCategoriesVariables>
) =>
  useQuery<IGetWeeklySubsidyCategories, IGetSubsidyCategoriesVariables>(GET_SUBSIDY_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
