import { gql } from '@apollo/client';
import { NOTIFICATION_FIELDS } from './fields';
import { useK2Query } from 'shared/apis/k2Api';
import { QueryHookOptions } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query (
    $archived: Boolean
    $read: Boolean
    $level: NotificationLevel
    $pageNumber: Int
    $pageSize: Int
    $startDate: DateTime
    $endDate: DateTime
    $types: [String!]
  ) {
    notifications(
      archived: $archived
      read: $read
      level: $level
      pageNumber: $pageNumber
      pageSize: $pageSize
      startDate: $startDate
      endDate: $endDate
      types: $types
    ) {
      pageNumber
      pageSize
      totalRecords
      data {
        ...NotificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS}
`;

export const GET_NOTIFICATIONS_TYPES = gql`
  query {
    notificationTypes {
      category
      type
    }
  }
`;

export interface INotificationsV2Data {
  notifications: IPagedResult<INotificationV2>;
}

export interface INotificationsV2DataTypes {
  notificationTypes: { category: string; type: string }[];
}

export interface INotificationV2QueryInput {
  archived?: boolean;
  read?: boolean;
  level?: NotificationLevel;
  type?: string;
  types?: string[];
  pageNumber?: number;
  pageSize?: number;
  startDate?: string;
  endDate?: string;
}
export interface INotificationTypesV2QueryInput {}

export const useGetNotificationsV2 = (
  input: INotificationV2QueryInput,
  options: Exclude<QueryHookOptions, 'query'> = {}
) => {
  return useK2Query<INotificationsV2Data, INotificationV2QueryInput>(GET_NOTIFICATIONS, {
    variables: { ...input },
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetNotificationsV2Types = (options: Exclude<QueryHookOptions, 'query'> = {}) => {
  return useK2Query<INotificationsV2DataTypes, INotificationTypesV2QueryInput>(GET_NOTIFICATIONS_TYPES, {
    fetchPolicy: 'network-only',
    ...options,
  });
};
