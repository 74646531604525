export const accountBillingOverviewFields = `
  accountId
  centerId
  name
  center {
    name
    id
  }
  gap
  amountDue
  balance
  previousBalance
  pendingBalance
  newCharges
  billingCycleFrequency
  dueDate
  isItemizedBillsPaused
  isBillingCycleEnabled
  hasAutoPay
  transactionLimit
`;
