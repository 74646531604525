import React, { useCallback, useState, useEffect, useContext } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Card from 'shared/components/Card';
import Table from 'react-bootstrap/Table';
import TimeEntryRow from './TimeEntryRow';
import NowIndicator from './NowIndicator';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import { TimezoneContext } from 'shared/contexts/timezoneContext';
import { DailyAttendanceWithPerson } from '../TimelogsTab/TimelogsTab';

interface IProps {
  loading: boolean;
  operationHoursForDate: IOperationHours | null;
  attendanceForDay: DailyAttendanceWithPerson[];
  date: moment.Moment;
  centerId: string;
}

const getFirstColumnWidth = () => {
  const firstColumnElement = document.querySelector('.daily-time-log-table th:first-child');
  if (firstColumnElement) {
    const computedStyle = getComputedStyle(firstColumnElement);
    return parseFloat(computedStyle.width);
  }
  return 0;
};

const EmployeeAttendance: React.FC<IProps> = ({ loading, attendanceForDay, operationHoursForDate, date, centerId }) => {
  const { timezone: centerTimezone } = useContext(TimezoneContext);

  const openTime = date.clone().tz(centerTimezone).hours(0).minutes(0).seconds(0);
  const closeTime = date.clone().tz(centerTimezone).hours(24).minutes(0).seconds(0);
  const totalHoursInView = closeTime.diff(openTime, 'h');

  const getHeaderHours = useCallback(() => {
    const headerHours: string[] = [];
    for (let i = 0; i < totalHoursInView; i++) {
      headerHours.push(openTime.clone().add(i, 'h').format('hA'));
    }
    return headerHours;
  }, [openTime, totalHoursInView]);

  const updateNowIndicatorPosition = useCallback(() => {
    const formattedOpenTime = momentTz(openTime).tz(centerTimezone).format(); // important to format times so both are using same time zone
    const now = momentTz().tz(centerTimezone).format();
    const hoursFromOpenTimeToNow = momentTz(now).tz(centerTimezone).diff(formattedOpenTime, 'minutes') / 60;
    //setNowIndicatorPosition((hoursFromOpenTimeToNow / totalHoursInView) * 75 + 9.2); // percentage of time taken * percentage of time portion of table + percentage of employee col
    setNowIndicatorPosition(hoursFromOpenTimeToNow * 72 + getFirstColumnWidth()); //currentHours * 72 pixels for each hour + pixels of first column width
  }, [openTime, centerTimezone]);

  const showNowIndicator = moment().isBetween(openTime, closeTime);
  const [nowIndicatorPosition, setNowIndicatorPosition] = useState<number>();

  // update the "now" indicator position every minute
  useEffect(() => {
    const updateNowPositioningInternval = setInterval(() => {
      updateNowIndicatorPosition();
    }, 60 * 1000);

    return () => clearInterval(updateNowPositioningInternval);
  }, [updateNowIndicatorPosition]);

  // on mount make sure the now indicator is in the correct position
  useEffect(() => {
    updateNowIndicatorPosition();
  }, [updateNowIndicatorPosition]);

  return (
    <Card bodyClassName="px-0 py-2">
      <Table responsive borderless className="daily-time-log-table position-relative">
        {showNowIndicator && <NowIndicator leftOffset={nowIndicatorPosition ?? 0} />}
        <thead>
          <tr>
            <th>Employee</th>
            {getHeaderHours().map((hour, i) => (
              <th colSpan={40} key={i}>
                {hour}
              </th>
            ))}
            <th colSpan={60}>Total</th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            attendanceForDay.map((attendance: DailyAttendanceWithPerson, i: number) => (
              <TimeEntryRow
                key={i}
                data={attendance}
                openTime={openTime}
                closeTime={closeTime}
                totalHoursInView={totalHoursInView}
                centerTimezone={centerTimezone}
                centerId={centerId}
              />
            ))}
        </tbody>
      </Table>
      {loading && (
        <div className="px-8">
          <LoadingLines number={4} />
        </div>
      )}
    </Card>
  );
};

export default EmployeeAttendance;
