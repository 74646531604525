import * as types from './types';

export interface IPersonnelStateShape {
  personnel: ICcssPersonnel[];
}

const initialPersonnelState: IPersonnelStateShape = {
  personnel: [],
};

export interface IProviderStateShape {
  providers: IProvider[];
}

const initialProviderState: IProviderStateShape = {
  providers: [],
};

export const personnelReducers = (
  state: IPersonnelStateShape = initialPersonnelState,
  action: types.PersonnelActionTypes
): IPersonnelStateShape => {
  switch (action.type) {
    case types.GET_PERSONNEL:
      return {
        ...state,
        personnel: action.personnel,
      };

    default:
      return state;
  }
};

export const providerReducers = (
  state: IProviderStateShape = initialProviderState,
  action: types.ProvidersActionTypes
): IProviderStateShape => {
  switch (action.type) {
    case types.GET_PROVIDERS:
      return {
        ...state,
        providers: action.providers,
      };
    case types.CREATE_PROVIDER:
      return {
        ...state,
        providers: [...state.providers, action.provider],
      };
    case types.UPDATE_PROVIDER:
      return {
        ...state,
        providers: [...state.providers.filter((p) => p.id !== action.provider.id), action.provider],
      };
    default:
      return state;
  }
};
