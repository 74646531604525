import React, { useCallback } from 'react';
import moment from 'moment';
import RBButton from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import AvatarContent from 'shared/components/Avatar/AvatarContent';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import colors from '_colors.module.scss';
import { Circle } from 'shared/components/Shapes';
import AvatarStatus from 'shared/components/Avatar/AvatarStatus';
import { getFullName, getInitials, stringToHueDegree } from 'shared/util/string';

interface IProps {
  children?: IAccountChild[];
  navigateToChildProfile: (child: IAccountChild) => void;
}

const AccountChildrenColumn: React.FC<IProps> = ({ children, navigateToChildProfile, ...props }) => {
  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // don't bubble the click up to the table component
    event.stopPropagation();
  }, []);

  const navigateToProfile = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, child: IAccountChild) => {
      event.stopPropagation();

      navigateToChildProfile(child);
    },
    [navigateToChildProfile]
  );
  return (
    <div>
      {(children ?? []).map((child, idx) => {
        const initials = getInitials(child);
        const hasAge = moment(child.dob).isSameOrBefore(moment());
        const age = hasAge
          ? getAgeStringFromDateOfBirth(moment(child.dob)).replace(/\(|\)/g, '')
          : moment(child.dob).format('M-D-YYYY');

        return (
          <OverlayTrigger
            rootClose
            key={`account-children-${child.id}-${idx}`}
            trigger="click"
            placement="right"
            overlay={
              <Popover id="child-details-popover">
                <Popover.Title className="bg-secondary text-white">{getFullName(child)}</Popover.Title>
                <Popover.Content className="d-flex flex-column">
                  <div className="mb-4">
                    <div className="d-flex flex-row align-items-center">
                      Status:
                      <div className="d-flex flex-row align-items-center ml-2">
                        <Circle size="xxs" background={colors.success} className="mr-1" />
                        Enrolled
                      </div>
                    </div>
                    <div>{hasAge ? `Age: ${age}` : `DoB: ${age}`}</div>
                    {/* <div>Start Date: ___</div> */}
                  </div>
                  <RBButton
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                      navigateToProfile(event, child)
                    }
                  >
                    Go to Profile
                  </RBButton>
                </Popover.Content>
              </Popover>
            }
          >
            {/* we're using the underlying Avatar components since there is strange behavior with the popover when the trigger is a span (what the Avatar uses) */}
            <div className="avatar mr-2">
              <AvatarContent
                size="md"
                image={child.avatar?.url ?? ''}
                initials={initials}
                alt={`${getFullName(child)} avatar`}
                color={`hsl(${stringToHueDegree(child.id)}, ${stringToHueDegree(child.id) < 50 ? '100%' : '40%'}, 40%`}
              />
            </div>
          </OverlayTrigger>
        );
      })}
    </div>
  );
};

export default AccountChildrenColumn;
