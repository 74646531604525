import React, { useCallback, useState } from 'react';
import Button, { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { TableHeader } from 'shared/components/DataTable';
import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { every } from 'lodash';

interface IProps {
  selectedNotifications: INotificationV2[];
  handleClose: () => void;
  handleMarkNotification: (notifications: string[], markAs: NotificationV2MarkAs) => Promise<void>;
  refetch: () => void;
}

const NotificationsBulkActions: React.FC<IProps> = ({
  selectedNotifications,
  handleClose,
  handleMarkNotification,
  refetch,
}) => {
  const { t } = useTranslation();
  const [isBulkMarkingAsRead, setIsBulkMarkingAsRead] = useState(false);
  const [isBulkMarkingAsArchived, setIsBulkMarkingAsArchived] = useState(false);

  const handleBulkAction = useCallback(
    (action) => {
      if (action === 'read') {
        setIsBulkMarkingAsRead(true);
      }
      if (action === 'archived') {
        setIsBulkMarkingAsArchived(true);
      }
      const notificationIds = selectedNotifications.map((i) => i.id);
      handleMarkNotification(notificationIds, action)
        .catch((errors) => {
          showToast(getApolloErrorMessage(errors[0]), 'error');
        })
        .finally(() => {
          refetch();
          setIsBulkMarkingAsRead(false);
          setIsBulkMarkingAsArchived(false);
          handleClose();
        });
    },
    [selectedNotifications, handleMarkNotification]
  );

  return (
    <TableHeader className="flex-wrap justify-content-between">
      <div className="d-flex flex-direction-row align-items-center">
        <Button
          variant="outline-primary mr-2"
          onClick={() => handleBulkAction('archived')}
          loading={isBulkMarkingAsArchived}
          disabled={every(selectedNotifications, { archived: true })}
        >
          {t('notification-v2.buttons.archive')}
        </Button>
        <Button
          variant="primary"
          onClick={() => handleBulkAction('read')}
          loading={isBulkMarkingAsRead}
          disabled={every(selectedNotifications, { read: true })}
        >
          {t('notification-v2.buttons.mark-as-read')}
        </Button>
      </div>
      <IconButtonCircle
        icon={faTimes}
        onClick={handleClose}
        tooltipDirection="bottom"
        tooltipText="Clear Filters"
        className="mr-4 my-2"
      />
    </TableHeader>
  );
};

export default NotificationsBulkActions;
