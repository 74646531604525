import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerTimes } from '@fortawesome/pro-light-svg-icons';
import { getFullName, getInitials, stringToHsl } from 'shared/util/string';
import AvatarContent from 'shared/components/Avatar/AvatarContent';
import colors from '_colors.module.scss';
import { ISessionUpdateShape } from './EditSessionModal';
import { Col, Row } from 'shared/components/Layout';
import { useTranslation } from 'react-i18next';

interface IProps {
  session: ISessionUpdateShape;
}

const AbsenceGroup: React.FC<IProps> = ({ session, ...props }) => {
  const absence = session.absence as IAbsence;
  const { t } = useTranslation(['enrollment', 'translation']);

  return (
    <Row className="flex-grow-1">
      <Col>
        <div className="d-flex flex-row align-items-center">
          <FontAwesomeIcon icon={faMapMarkerTimes} color={colors.secondaryOrange} size="2x" className="mr-4" />
          Marked Absent
        </div>
      </Col>
      <Col>
        <div className="font-size-12">By</div>
        <div className="d-flex flex-row">
          <div className="avatar d-inline-block float-none mr-2">
            <AvatarContent
              size="xs"
              image={absence.reportedBy?.avatar?.url ?? ''}
              initials={getInitials(absence.reportedBy)}
              alt={`${getFullName(absence.reportedBy)} avatar`}
              color={stringToHsl(absence.reportedBy?.id ?? '1')}
            />
          </div>
          <span>{getFullName(absence.reportedBy)}</span>
        </div>
      </Col>
      <Col>
        <div className="font-size-12">Reason</div>
        <div className="text-dark">
          {absence.type === 'HOLIDAY'
            ? 'Holiday'
            : absence.reason !== null
            ? t(`enrollment:absenceReasons.${absence.reason}`)
            : ''}
        </div>
      </Col>
    </Row>
  );
};

export default AbsenceGroup;
