const emergencyContactRelationship = {
  Parent: 'Parent',
  Grandparent: 'Grandparent',
  Sibling: 'Sibling',
  Relative: 'Relative',
  Roommate: 'Roommate',
  Friend: 'Friend',
  SpousePartner: 'Spouse / Partner',
};

export default emergencyContactRelationship;

export const childContactRelationship: Record<string, string> = {
  Parent: 'Parent',
  Sibling: 'Sibling',
  StepParent: 'Step Parent',
  Grandparent: 'Grandparent',
  Relative: 'Relative',
  FosterParent: 'Foster Parent',
  LegalGuardian: 'Legal Guardian',
  FamilyFriend: 'Family Friend',
  Caretaker: 'Caretaker',
};
