import React from 'react';
import Card from 'shared/components/Card';
import Button from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import Alert from 'shared/components/Alert';
import '../../myProfile.scss';

interface IProps {
  disabled?: boolean;
  showChangePasswordModal: () => void;
}

const ChangePasswordCard: React.FC<IProps> = ({ showChangePasswordModal, disabled = false, ...props }) => {
  return (
    <Card className="kt-my-profile-change-password-card">
      <div className="d-flex flex-column align-items-center">
        <div className="kt-my-profile-change-password-circle rounded-circle circle-lg d-flex justify-content-center align-items-center mb-4">
          <FontAwesomeIcon size="2x" icon={faKey} color={colors.warning} />
        </div>
        <span className="mb-4">Need to change your password?</span>
        <Button variant="outline-secondary" onClick={showChangePasswordModal} disabled={disabled}>
          Change Password
        </Button>
        {disabled && (
          <Alert className="mt-4" variant="info">
            Your email has not been verified. A verified email is required to change your password.
          </Alert>
        )}
      </div>
    </Card>
  );
};

export default ChangePasswordCard;
