import React, { useState, useCallback, useMemo } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Row, Col } from 'shared/components/Layout';
import CenterInformationCard from './Cards/CenterInformationCard';
import TagsCard from './Cards/TagsCard';
import ContactInformationCards from './Cards/ContactInformationCards';
import DeactivateCenterCard from './Cards/DeactivateCenterCard';
import InviteCodeCard from './Cards/InviteCodeCard';
import DeactivateCenterModal from 'pages/Centers/components/DeactivateCenterModal';
import {
  useDeactivateCenter,
  useDeleteCenter,
  useReactivateCenter,
} from 'pages/Centers/subroutes/Profile/graphql/mutations';
import Alert from 'shared/components/Alert';
import { showToast } from 'shared/components/Toast';
import ReactivateCenterModal from 'pages/Centers/components/ReactivateCenterModal';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {
  center: ICenter | undefined;
  canPerformEdit: boolean;
  loading: boolean;
}

const ProfileTab: React.FC<IProps> = ({ center, canPerformEdit, loading, ...props }) => {
  const [deactivateCenterModalIsVisible, setDeactivateCenterModalIsVisible] = useState(false);
  const [reactivateCenterModalIsVisible, setReactivateCenterModalIsVisible] = useState(false);
  const [deleteCenterModalIsVisible, setDeleteCenterModalIsVisible] = useState(false);
  const [deactivateCenterMutationFn, { loading: deactivateCenterLoading, error: deactivateCenterError }] =
    useDeactivateCenter();
  const [reactivateCenterMutationFn, { loading: reactivateCenterLoading, error: reactivateCenterError }] =
    useReactivateCenter();
  const [deleteCenterMutationFn, { loading: deleteCenterLoading, error: deleteCenterError }] = useDeleteCenter();
  const errorMessage = useMemo(
    () => deactivateCenterError || deleteCenterError || reactivateCenterError || null,
    [deactivateCenterError, deleteCenterError, reactivateCenterError]
  );

  // permissions
  const canEditCenterProfile = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const canEditCenterTags = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Tags,
    level: RoleLevelType.Edit,
  });

  // modal controls
  const showDeactivateCenterModal = useCallback(
    () => setDeactivateCenterModalIsVisible(true),
    [setDeactivateCenterModalIsVisible]
  );
  const showReactivateCenterModal = useCallback(
    () => setReactivateCenterModalIsVisible(true),
    [setReactivateCenterModalIsVisible]
  );
  const showDeleteCenterModal = useCallback(() => setDeleteCenterModalIsVisible(true), [setDeleteCenterModalIsVisible]);
  const hideDeactivateCenterModal = useCallback(
    () => setDeactivateCenterModalIsVisible(false),
    [setDeactivateCenterModalIsVisible]
  );
  const hideReactivateCenterModal = useCallback(
    () => setReactivateCenterModalIsVisible(false),
    [setReactivateCenterModalIsVisible]
  );
  const hideDeleteCenterModal = useCallback(
    () => setDeleteCenterModalIsVisible(false),
    [setDeleteCenterModalIsVisible]
  );

  const history = useHistory();
  const navigateToProfiles = useCallback(() => history.push('/centers/profiles'), [history]);

  // mutations
  const deactiveCenter = useCallback(() => {
    deactivateCenterMutationFn({
      variables: {
        id: center!.id,
      },
    })
      .then(() => {
        hideDeactivateCenterModal();
        showToast(`Successfully deactivated ${center!.name}.`, 'success');
        navigateToProfiles();
      })
      .catch(() => {
        hideDeactivateCenterModal();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
  }, [center, deactivateCenterMutationFn, hideDeactivateCenterModal, navigateToProfiles]);

  const reactiveCenter = useCallback(() => {
    reactivateCenterMutationFn({
      variables: {
        id: center!.id,
      },
    })
      .then(() => {
        hideReactivateCenterModal();
        showToast(`Successfully reactivated ${center!.name}.`, 'success');
      })
      .catch(() => {
        hideReactivateCenterModal();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
  }, [center, reactivateCenterMutationFn, hideReactivateCenterModal]);

  const deleteCenter = useCallback(() => {
    deleteCenterMutationFn({
      variables: {
        id: center!.id,
      },
    })
      .then(() => {
        hideDeleteCenterModal();
        showToast(`Successfully deleted ${center!.name}.`, 'success');
        navigateToProfiles();
      })
      .catch(() => {
        hideDeleteCenterModal();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
  }, [center, deleteCenterMutationFn, hideDeleteCenterModal, navigateToProfiles]);

  // not loading and we don't have data... something may have gone wrong
  if (!loading && !center) {
    return null;
  }

  return (
    <>
      {errorMessage && <Alert variant="danger">{errorMessage.message || 'Something went wrong.'}</Alert>}
      <Row align="start">
        <Col md={5}>
          <HasRoleAreaLevel
            action={{ area: AreaType.Center, permission: PermissionType.Base, level: RoleLevelType.Read }}
          >
            <CenterInformationCard loading={loading} center={center} canPerformEdit={canEditCenterProfile} />
          </HasRoleAreaLevel>
          <HasRoleAreaLevel
            action={{ area: AreaType.Center, permission: PermissionType.Tags, level: RoleLevelType.Read }}
          >
            <TagsCard loading={loading} center={center} canPerformEdit={canEditCenterTags} />
          </HasRoleAreaLevel>
          <HasRoleAreaLevel
            action={{ area: AreaType.Center, permission: PermissionType.Base, level: RoleLevelType.Delete }}
          >
            <DeactivateCenterCard
              loadingProfile={loading}
              performingMutation={deactivateCenterLoading || deleteCenterLoading || reactivateCenterLoading}
              onDeactivateClick={showDeactivateCenterModal}
              onDeleteClick={showDeleteCenterModal}
              onReactivateClick={showReactivateCenterModal}
              centerIsDeactivated={Boolean(center && center.deactivatedAt)}
              centerIsDeleted={Boolean(center && center.deletedAt)}
            />
          </HasRoleAreaLevel>
        </Col>
        <HasRoleAreaLevel
          action={{ area: AreaType.Center, permission: PermissionType.Base, level: RoleLevelType.Read }}
        >
          <Col>
            <InviteCodeCard loading={loading} center={center} canPerformEdit={canEditCenterProfile} />
            <ContactInformationCards loading={loading} center={center} canPerformEdit={canEditCenterProfile} />
          </Col>
        </HasRoleAreaLevel>
      </Row>
      {center && (
        <DeactivateCenterModal
          isOpen={deactivateCenterModalIsVisible}
          isLoading={deactivateCenterLoading}
          onClose={hideDeactivateCenterModal}
          deactivateCenter={deactiveCenter}
          action="DEACTIVATE"
          centers={[center]}
        />
      )}
      {center && (
        <DeactivateCenterModal
          isOpen={deleteCenterModalIsVisible}
          isLoading={deleteCenterLoading}
          onClose={hideDeleteCenterModal}
          deleteCenter={deleteCenter}
          action="DELETE"
          centers={[center]}
        />
      )}
      {center && (
        <ReactivateCenterModal
          isOpen={reactivateCenterModalIsVisible}
          isLoading={reactivateCenterLoading}
          onClose={hideReactivateCenterModal}
          reactivateCenter={reactiveCenter}
          centers={[center]}
        />
      )}
    </>
  );
};

export default ProfileTab;
