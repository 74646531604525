import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  Typography,
  Button,
  CardActions,
  Divider,
  Tooltip,
  Skeleton,
} from '@mui/material';
import React from 'react';
import NewBill from './BillModal';
import AddPayment from './AddPayment';
import useFormatDate from 'shared/hooks/useFormatDate';
import { useGetLatestBill } from './queries/useGetLatestBill';
import RenderWhen from 'US/Utilities/Components/RenderWhen';
import { ErrorContent } from './ErrorContent';

interface IProps {
  accountId: string;
}

const LatestBillCard: React.FC<IProps> = ({ accountId }) => {
  const formatDate = useFormatDate();
  const [openBill, setOpenBill] = React.useState<boolean>(false);

  const { data: bill, loading, error, refetch } = useGetLatestBill({ accountId });

  const handleCloseBill = React.useCallback(() => {
    setOpenBill(false);
  }, []);

  const handleOpenBill = React.useCallback(() => {
    setOpenBill(true);
  }, []);

  const [openAddPayment, setOpenAddPayment] = React.useState(false);

  const handleCloseAddPayment = React.useCallback(() => {
    setOpenAddPayment(false);
  }, []);

  return (
    <>
      <Card variant="outlined" sx={{ minWidth: '350px' }}>
        <Tooltip title="Latest Bill is the bill that was last sent to the family.">
          {/* this div is here to get around a bug with the tooltip issue is here https://github.com/mui/material-ui/issues/20014 */}
          <div>
            <CardHeader
              title="Latest Bill"
              sx={{ padding: '8px 6px 0px 16px', borderBottom: 'none' }}
              titleTypographyProps={{ fontSize: '1rem', fontWeight: 'normal' }}
            />
          </div>
        </Tooltip>
        <CardContent>
          <RenderWhen>
            <RenderWhen.If isTrue={loading || !!bill}>
              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <div>
                  <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Typography>Bill Total:</Typography>{' '}
                    {loading ? <Skeleton width="2rem" /> : <Typography>{bill?.totalDue}</Typography>}
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Typography>Paid:</Typography>{' '}
                    {loading ? <Skeleton width="2rem" /> : <Typography>{bill?.amountOfPaymentsMade}</Typography>}
                  </Stack>
                  <Divider />
                  <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Typography>Remaining:</Typography>
                    {loading ? (
                      <Skeleton width="2rem" />
                    ) : (
                      <Typography fontSize="1.2rem" fontWeight={600}>
                        {bill?.amountDueNow}
                      </Typography>
                    )}
                  </Stack>
                </div>
                <div>
                  <Typography>Sent On:</Typography>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <Typography fontSize="1rem" fontWeight={600}>
                      {formatDate(bill?.billDate ?? '', 'MM/DD/YY')}
                    </Typography>
                  )}
                  <Typography>Due On:</Typography>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <Typography fontSize="1rem" fontWeight={600}>
                      {formatDate(bill?.dueDate ?? '', 'MM/DD/YY')}
                    </Typography>
                  )}
                </div>
              </Stack>
            </RenderWhen.If>
            <RenderWhen.If isTrue={!!error && error.code === 404}>
              <Typography>{error?.message}</Typography>
            </RenderWhen.If>
            <RenderWhen.If isTrue={!!error}>
              <ErrorContent error={error} onTryAgain={refetch}></ErrorContent>
            </RenderWhen.If>
          </RenderWhen>
        </CardContent>
        <CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
          <RenderWhen>
            <RenderWhen.If isTrue={loading || !!bill}>
              {/* <Button onClick={handleOpenAddPayment} disabled={loading}>
                Add Payment
              </Button> */}
              <Button onClick={handleOpenBill} disabled={loading}>
                See Details
              </Button>
            </RenderWhen.If>
          </RenderWhen>
        </CardActions>
      </Card>
      <NewBill open={openBill} onClose={handleCloseBill} bill={bill ?? null} loading={loading} />
      <AddPayment
        isOpen={openAddPayment}
        onClose={handleCloseAddPayment}
        accountId={bill?.accountNo}
        onComplete={() => {}}
      />
    </>
  );
};

export default LatestBillCard;
