import React, { useContext } from 'react';
import { orderBy } from 'lodash';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ITimelogTableFilters } from '../duck/reducers';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import DayPicker from 'shared/components/DateInput/DayPicker';
import { TimezoneContext } from 'shared/contexts/timezoneContext';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { statusOptionsTimeLog } from 'shared/constants/StaffStatusSearchExpressions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  activeFilters: ITimelogTableFilters;
  roleOptions: IRole[];
  onFilterUpdate: (update: Partial<ITimelogTableFilters>) => void;
}

const DateAndFiltersControl: React.FC<IProps> = ({ activeFilters, roleOptions, onFilterUpdate, ...props }) => {
  const { t } = useTranslation(['timelog']);
  const centerTimezoneContext = useContext(TimezoneContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div
      className={
        isMobile
          ? 'bg-white px-4 justify-content-center py-2 d-flex flex-wrap align-items-center'
          : 'bg-white px-4 py-2 d-flex flex-sm-wrap flex-md-nowrap align-items-center'
      }
    >
      <ButtonGroup aria-label="Time log view toggle">
        <Button
          onClick={() => onFilterUpdate({ activeView: 'WEEK' })}
          variant={activeFilters.activeView === 'WEEK' ? 'secondary' : 'light'}
        >
          {t('timelog:time-log.week-view-btn')}
        </Button>
        <Button
          onClick={() => onFilterUpdate({ activeView: 'DAY' })}
          variant={activeFilters.activeView === 'DAY' ? 'secondary' : 'light'}
        >
          {t('timelog:time-log.day-view-btn')}
        </Button>
      </ButtonGroup>
      <div className={isMobile ? 'd-flex justify-content-center w-100 my-2' : 'd-flex justify-content-center w-100'}>
        {activeFilters.activeView === 'DAY' ? (
          <DayPicker
            className="flex-grow-0 mb-0 mr-2"
            displayFormat="ddd MMM D"
            date={moment(activeFilters.date).tz(centerTimezoneContext.timezone)}
            onChange={(date) => onFilterUpdate({ date: date.format() })}
          />
        ) : (
          <WeekPicker
            className="flex-grow-0 mb-0 mr-2"
            displayFormat="MMM D"
            startDate={moment(activeFilters.date).tz(centerTimezoneContext.timezone).startOf('week')}
            endDate={moment(activeFilters.date).tz(centerTimezoneContext.timezone).endOf('week')}
            rangeComponent={<span className="mx-1">-</span>}
            onChange={(dates) => onFilterUpdate({ date: dates.startDate.toISOString() })}
            reactDatesController="RANGE"
          />
        )}
      </div>
      <div className={isMobile ? 'd-flex flex-row justify-content-center' : 'd-flex flex-row'}>
        <DropdownFilter
          title={t('timelog:time-log.role-filter')}
          selectedFilters={activeFilters.roleIds}
          options={orderBy(
            roleOptions.filter((r) => r.scheduleVisibility),
            (r) => r.name,
            'asc'
          ).map((role) => ({ label: role.name, value: role.id }))}
          onFilterSelect={(filters) => onFilterUpdate({ roleIds: filters.map((f) => f.value) })}
          className={isMobile ? '' : 'mr-1'}
        />
        <DropdownFilter
          title={t('timelog:time-log.employee-status-filter')}
          selectedFilters={activeFilters.employeeStatus}
          options={statusOptionsTimeLog}
          onFilterSelect={(filters) => onFilterUpdate({ employeeStatus: filters.map((f) => f.value) })}
          className={isMobile ? 'mx-2' : 'ml-1'}
        />
      </div>
    </div>
  );
};

export default DateAndFiltersControl;
