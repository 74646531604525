import React, { useState } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';
import { ICustomFieldProps } from '../CustomField';
import { ContentBlock } from 'draft-js';

const getTextAlignClassName = (contentBlock: ContentBlock) => {
  switch (contentBlock.getData().get('textAlign')) {
    case 'ALIGN_LEFT':
      return 'text-align--left';

    case 'ALIGN_CENTER':
      return 'text-align--center';

    case 'ALIGN_RIGHT':
      return 'text-align--right';

    case 'ALIGN_JUSTIFY':
      return 'text-align--justify';

    default:
      return '';
  }
};

const getTextAlignStyles = (contentBlock: ContentBlock) => {
  switch (contentBlock.getData().get('textAlign')) {
    case 'ALIGN_LEFT':
      return {
        style: {
          textAlign: 'left',
        },
      };

    case 'ALIGN_CENTER':
      return {
        style: {
          textAlign: 'center',
        },
      };

    case 'ALIGN_RIGHT':
      return {
        style: {
          textAlign: 'right',
        },
      };

    case 'ALIGN_JUSTIFY':
      return {
        style: {
          textAlign: 'justify',
        },
      };

    default:
      return {};
  }
};

const getTextAlignBlockMetadata = (element: any) => {
  switch (element.style.textAlign) {
    case 'right':
      return {
        data: {
          textAlign: 'ALIGN_RIGHT',
        },
      };

    case 'center':
      return {
        data: {
          textAlign: 'ALIGN_CENTER',
        },
      };

    case 'justify':
      return {
        data: {
          textAlign: 'ALIGN_JUSTIFY',
        },
      };

    case 'left':
      return {
        data: {
          textAlign: 'ALIGN_LEFT',
        },
      };

    default:
      return {};
  }
};

const HtmlText: React.FC<Pick<ICustomFieldProps, 'customField' | 'value' | 'disabled' | 'isReadOnly' | 'onChange'>> = ({
  customField,
  value,
  disabled,
  isReadOnly,
  onChange,
}) => {
  const [html, setHtml] = useState(
    RichTextEditor.createEmptyValue().setContentFromString(value ?? '', 'html', {
      customBlockFn: getTextAlignBlockMetadata,
    })
  );

  const handleChange = (value: EditorValue) => {
    setHtml(value);
    onChange(value.toString('html', { blockStyleFn: getTextAlignStyles }), customField.id);
  };

  return (
    <div className="mb-2">
      <label>{customField.label}</label>
      <RichTextEditor
        value={html}
        onChange={handleChange}
        readOnly={isReadOnly}
        disabled={disabled}
        blockStyleFn={getTextAlignClassName}
      />
    </div>
  );
};
export default React.memo(HtmlText);
