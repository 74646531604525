import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, get } from 'lodash';
import moment from 'moment';
import React from 'react';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { accsCategoryFormatting, accsCategoryLabel } from 'shared/constants/enums/ccssEnums';
import { capitalize, getInitials, stringToHsl } from 'shared/util/string';
import colors from '_colors.module.scss';
import AccsCard from 'shared/components/AccsCard/AccsCard';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import cast from 'shared/util/cast';
import { useTranslation } from 'react-i18next';
import { accountStatusColorHexes } from 'shared/constants/tagColors';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';

interface ITableProps {
  data: IAccsCertDertForCenters[];
  dataSize?: number;
  pageSize?: number;
  activePage?: number;
  showLoadingOverlay?: boolean;
  showPagination?: boolean;
  onPageChange?: (page: number, sizePerPage: number) => void;
  onSizePerPageChange?: (sizePerPage: number) => void;
  onSort?: (field: string, direction: ElasticsearchSortDirection) => void;
  renderHeader?: (paginationProps: any, searchProps: any) => React.ReactElement | false;
  headerClasses?: string;
  onSearch?: (value: string) => void;
  filteredStatus?: string[];
  onStatusFilter?: (status: string[]) => void;
}

const dateFormat = 'DD/MM/YYYY';

const ACcsTable: React.FC<ITableProps> = ({
  data,
  dataSize,
  pageSize,
  showLoadingOverlay,
  onSizePerPageChange,
  activePage,
  onPageChange,
  onSort,
  showPagination,
  filteredStatus,
  onStatusFilter,
  headerClasses,
  onSearch,
}) => {
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const { t } = useTranslation();

  const statusOptions: ITableFilterOption[] = [
    {
      label: capitalize(t('spelling.active')),
      value: 'Active',
      indicatorColor: accountStatusColorHexes.Active,
    },
    {
      label: capitalize(t('spelling.inactive')),
      value: 'Inactive',
      indicatorColor: accountStatusColorHexes.Inactive,
    },
  ];

  return (
    <DataTable
      keyField="id"
      noPadding={true}
      data={data}
      dataSize={dataSize}
      pageSize={pageSize}
      activePage={activePage}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      showLoadingOverlay={showLoadingOverlay}
      showSelect={false}
      showPagination={showPagination}
      renderHeader={(paginationProps: any) => (
        <TableHeader className="flex-wrap">
          <SizePerPage paginationProps={paginationProps} />
          {onSearch && (
            <TableSearch
              className="mr-4"
              onChange={(value) => {
                onSearch(value);
              }}
              placeholder="Search"
            />
          )}
          {onStatusFilter && (
            <>
              <label className="ml-4 flex-nowrap my-2">{capitalize(t('spelling.accountStatusFilter'))}</label>
              <DropdownFilter
                title={capitalize(t('spelling.status'))}
                className="ml-2 my-2"
                selectedFilters={filteredStatus}
                options={statusOptions}
                onFilterSelect={(values) => onStatusFilter(values.map((value) => value.value))}
              />
            </>
          )}
        </TableHeader>
      )}
      onSort={onSort}
      defaultSortField={'submitted'}
      expandRow={expandRowDefault}
      headerClasses={headerClasses}
      columns={[
        {
          text: fieldLabels.center,
          dataField: 'centerName',
          sort: true,
          formatter: (cell: string, row: IAccsCertDertForCenters) => row.centerName ?? 'Unknown',
        },
        {
          text: 'Account',
          dataField: 'accountName',
          sort: true,
          formatter: (cell: string, row: IAccsCertDertForCenters) => row.accountName ?? 'External',
        },
        {
          text: 'Child',
          dataField: 'childName',
          sort: true,
          formatter: (cell: string, row: IAccsCertDertForCenters) => (
            <AvatarDataTableCell
              color={stringToHsl(row.childId)}
              initials={getInitials(cast<IChild>(row.child))}
              avatar={row.child.avatar?.url ?? ''}
              primaryText={row.child.fullName}
            />
          ),
        },
        {
          text: 'Type',
          dataField: 'certDertType',
          sort: true,
          formatter: (cell: string, row: IAccsCertDertForCenters) =>
            row.certDertType === 'CERTIFICATE' ? 'Certificate' : 'Determination',
        },
        {
          text: 'ID',
          dataField: 'accsId',
          sort: true,
          formatter: (cell: string, row: IAccsCertDertForCenters) => row.accsId,
        },
        {
          text: 'Date',
          dataField: 'startDate',
          sort: true,
          formatter: (cell: string, row: IAccsCertDertForCenters) =>
            `${moment(row.startDate).format(dateFormat)} - ${moment(row.endDate).format(dateFormat)}`,
        },
        {
          text: 'Status',
          dataField: 'category',
          sort: true,
          formatter: (cell: string, row: IAccsCertDertForCenters) => {
            let result: string =
              find(accsCategoryLabel, (status) => status.value.toLowerCase() === row.category.toLowerCase())?.label ??
              'Unknown';

            if (row.certDertType === 'DETERMINATION' && row.determinationStatus === 'REJECT') {
              result = 'Rejected';
            }
            return (
              <span>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faCircle}
                  color={get(accsCategoryFormatting, row.category.toLowerCase(), { color: colors.info }).color}
                  size="sm"
                />
                {`${result}`}
              </span>
            );
          },
        },
      ]}
    />
  );
};

const expandRowDefault = (row: IAccsCertDertForCenters) => <ExpandRowDefaultComponent row={row} />;

const ExpandRowDefaultComponent: React.FC<{ row: IAccsCertDertForCenters }> = ({ row }) => {
  return (
    <div>
      <AccsCard data={row} />
    </div>
  );
};

export default ACcsTable;
