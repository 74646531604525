import { useCallback } from 'react';
import { ReconciliationsPage } from '../components/ViewAccountsToReconcilePage';
import { useMultiAgencySelector } from '../hooks/useAgencySelector';
import { useCenterSelector } from '../hooks/useCenterSelector';
import { usePagination } from '../hooks/usePagination';
import { useReconciliationAccounts } from '../hooks/useReconciliationAccounts';
import { useSearch } from '../hooks/useSearch';

export default function ViewAccountsToReconcile() {
  const pagination = usePagination();

  const { searchText, handleSearchChange, debouncedSearchText, handleClear: handleClearSearch } = useSearch();

  const {
    centerOptions,
    handleSelectChange: handleCenterSelectorChange,
    selectedOptions,
    selectedCenters,
    handleClear: handleClearCenterFilters,
  } = useCenterSelector();

  const {
    agencyOptions,
    selectedOptions: selectedAgencyOptions,
    selectedAgencies,
    handleSelectionChange: handleSelectedAgenciesChange,
    handleClear: handleClearAgencyFilters,
  } = useMultiAgencySelector();

  const { reconciliationAccounts, totalReconciliationAccounts } = useReconciliationAccounts({
    agencyIds: selectedAgencies,
    centerIds: selectedCenters,
    search: debouncedSearchText,
    currentPage: pagination.activePage,
    pageSize: pagination.pageSize,
  });

  const handleClear = useCallback(() => {
    handleClearCenterFilters();
    handleClearAgencyFilters();
    handleClearSearch();
  }, [handleClearAgencyFilters, handleClearCenterFilters, handleClearSearch]);

  return (
    <ReconciliationsPage
      agencyOptions={agencyOptions}
      selectedAgencies={selectedAgencyOptions}
      accountsToReconcile={reconciliationAccounts}
      currentPage={pagination.activePage}
      pageSize={pagination.pageSize}
      totalDataSize={totalReconciliationAccounts}
      onPageSizeChange={pagination.handlePageSizeChange}
      onPageChange={pagination.handlePageChange}
      pageSizeOptions={pagination.pageSizeOptions}
      centerOptions={centerOptions}
      selectedCenters={selectedOptions}
      onSelectedCentersChange={handleCenterSelectorChange}
      searchString={searchText}
      onSearchChange={handleSearchChange}
      onClearFilters={handleClear}
      onSelectedAgenciesChange={handleSelectedAgenciesChange}
    ></ReconciliationsPage>
  );
}
