import { useCreateGLCodes, useUpdateGLCode, useArchiveGLCode } from 'gql/glCode/mutations';
import { useGetGLCodes } from 'gql/glCode/queries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { showToast } from 'shared/components/Toast';
import useDatatableState from 'shared/hooks/useDatatableState2';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { RootState } from 'store/reducers';
import ArchiveGLCodeModal from '../ArchiveGLCodeModal';
import CreateGLCodeModal from '../CreateGLCodeModal';
import EditGLCodeModal from '../EditGLCodeModal';
import { IGLCodeForm } from '../GLCodeForm/GLCodeForm';
import GLCodesTable from '../GLCodesTable';
import { useGLCodeContext } from '../../Context/GLCodeContext';

interface IProps {
  showCreateModal: boolean;
  dismissCreateModal: () => void;
}

const GLCodesTab: React.FC<IProps> = ({ showCreateModal, dismissCreateModal }) => {
  const { t } = useTranslation(['billing']);
  const [activeGLCode, setActiveGLCode] = useState<IGLCode | undefined>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [typeFilter, setTypeFilter] = useState<GLCodeAreaType | null>(null);
  const [tableState, tableFn] = useDatatableState();
  const user = useSelector((state: RootState) => state.user);
  const [sort, setSortOptions] = useState<ISearchSort[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? user?.entityId ?? '';

  const { refetchGlCode } = useGLCodeContext();
  const { data, loading, refetch } = useGetGLCodes({
    variables: {
      input: {
        businessId,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        areaType: typeFilter ?? undefined,
        sortDtos: sort,
        searchText: searchText === '' ? undefined : searchText,
      },
    },
  });

  const [createFn, { loading: createLoading }] = useCreateGLCodes({
    onCompleted: () => {
      dismissCreateModal();
      showToast(t('billing:gl-codes.create-gl-code-success'), 'success');
      refetch();
      refetchGlCode();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });
  const [updateFn, { loading: updateLoading }] = useUpdateGLCode({
    onCompleted: () => {
      showToast(t('billing:gl-codes.edit-gl-code-success'), 'success');
      setShowEditModal(false);
      refetch();
      refetchGlCode();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });
  const [archiveFn, { loading: archiveLoading }] = useArchiveGLCode({
    onCompleted: () => {
      showToast(t('billing:gl-codes.archive-gl-code-success'), 'success');
      setShowArchiveModal(false);
      refetch();
      refetchGlCode();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const handleEdit = (glCode: IGLCode) => {
    setShowEditModal(true);
    setActiveGLCode(glCode);
  };

  const handleArchive = (glCode: IGLCode) => {
    setShowArchiveModal(true);
    setActiveGLCode(glCode);
  };

  const onCreateSubmit = (newGLCode: IGLCodeForm) => {
    createFn({
      variables: {
        input: {
          glCode: newGLCode.glCode,
          types: newGLCode.types?.map((t) => t.value) ?? [],
          businessId,
          name: newGLCode.name,
        },
      },
    });
  };

  const onEditSubmit = (formData: IGLCodeForm) => {
    updateFn({
      variables: {
        input: {
          id: formData.id ?? '',
          glCode: formData.glCode,
          businessId,
          name: formData.name,
        },
      },
    });
  };

  const onArchiveSubmit = (glCodeId: string) => {
    archiveFn({
      variables: {
        input: {
          glCodeId: glCodeId,
          businessId,
        },
      },
    });
  };

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field: field === 'glCode' ? 'code' : field, direction }]);
  };

  return (
    <div>
      <GLCodesTable
        data={data?.getGLCodes?.data ?? []}
        totalResults={data?.getGLCodes?.totalRecords ?? 0}
        onSearch={(val) => {
          setSearchText(val);
        }}
        handleSort={handleSort}
        handleEdit={handleEdit}
        handleArchive={handleArchive}
        tableFunctions={tableFn}
        tableState={tableState}
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
        loading={loading}
      />
      <CreateGLCodeModal
        isOpen={showCreateModal}
        handleClose={dismissCreateModal}
        onSubmit={onCreateSubmit}
        loading={createLoading}
      />
      <EditGLCodeModal
        isOpen={showEditModal}
        onSubmit={onEditSubmit}
        glCode={activeGLCode}
        handleClose={() => {
          setShowEditModal(false);
        }}
        loading={updateLoading}
      />
      {activeGLCode && (
        <ArchiveGLCodeModal
          isOpen={showArchiveModal}
          onSubmit={onArchiveSubmit}
          glCode={activeGLCode}
          businessId={businessId}
          handleClose={() => {
            setShowArchiveModal(false);
          }}
          loading={archiveLoading}
        />
      )}
    </div>
  );
};

export default GLCodesTab;
