import React from 'react';
import { useTranslation } from 'react-i18next';
import CreatePaymentMethodButtons from '../CreatePaymentMethodButtons/CreatePaymentMethodButtons';
import PaymentMethodTable from '../PaymentMethodTable/PaymentMethodTable';
interface IProps {
  onCreateCardClick: () => void;
  onCreateAchClick: () => void;
  onViewPaymentMethod: (paymentMethod: IPaymentMethod) => void;
  onDeletePaymentMethod: (paymentMethod: IPaymentMethod) => void;
  onEditPaymentMethod: (paymentMethod: IPaymentMethod) => void;
  loading: boolean;
  showCreateAchButton?: boolean;
  paymentMethods: IPaymentMethod[];
}

const ContactPaymentRow: React.FC<IProps> = ({
  loading,
  paymentMethods,
  showCreateAchButton,
  onCreateAchClick,
  onCreateCardClick,
  onEditPaymentMethod,
  onViewPaymentMethod,
  onDeletePaymentMethod,
}) => {
  const { t } = useTranslation(['billing']);
  return (
    <div>
      {paymentMethods.length > 0 ? (
        <PaymentMethodTable
          paymentMethods={paymentMethods}
          onDeletePaymentMethod={onDeletePaymentMethod}
          onViewPaymentMethod={onViewPaymentMethod}
          onEditPaymentMethod={onEditPaymentMethod}
        />
      ) : (
        <span>{t('billing:payment-methods.no-payment-methods-found')}</span>
      )}
      <div className="ml-auto">
        <CreatePaymentMethodButtons
          onCreateAchClick={onCreateAchClick}
          onCreateCardClick={onCreateCardClick}
          loading={loading}
          showCreateAchButton={showCreateAchButton}
        />
      </div>
    </div>
  );
};
export default ContactPaymentRow;
