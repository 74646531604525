import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SideModalDrawer from 'shared/components/ModalDrawer';
import GLCodeForm from '../GLCodeForm';
import { IGLCodeForm } from '../GLCodeForm/GLCodeForm';
import { validateGLCodeForm } from '../GLCodeForm/validateGLCodeForm';
interface IProps {
  isOpen: boolean;
  loading: boolean;

  handleClose: () => void;
  onSubmit: (values: IGLCodeForm) => void;
}

const CreateGLCodeModal: React.FC<IProps> = ({ loading, isOpen, handleClose, onSubmit }) => {
  const { t } = useTranslation(['billing']);
  const initialForm: IGLCodeForm = {
    name: '',
    glCode: '',
    type: null,
  };
  const [formData, setFormData] = useState(initialForm);

  const handleSubmit = () => {
    onSubmit(formData);
  };

  const isValid = useMemo(() => {
    return validateGLCodeForm(formData, 'create');
  }, [formData]);

  const resetForm = () => {
    setFormData(initialForm);
  };

  useEffect(() => {
    if (!isOpen) resetForm();
  }, [isOpen]);

  return (
    <SideModalDrawer
      primaryButtonProps={{
        disabled: !isValid,
        loading,
      }}
      primaryCallback={handleSubmit}
      title={t('billing:gl-codes.create-gl-code-modal-title')}
      show={isOpen}
      closeOnPrimaryCallback={false}
      onHide={handleClose}
    >
      <GLCodeForm formData={formData} setFormData={setFormData} />
    </SideModalDrawer>
  );
};

export default CreateGLCodeModal;
