import {
  SET_FORM_VALUES,
  UPDATE_DAYS_OF_CARE_F2F_HOURS,
  UPDATE_DAYS_OF_CARE_NF2F_HOURS,
  UPDATE_DAYS_OF_CARE,
  ADD_TEMPORARY_EDUCATOR,
  ISClaimActionTypes,
  SET_FIELD_VALUE,
  UPDATE_EDUCATORS,
  UPDATE_EDUCATOR_F2F_HOURS,
  UPDATE_EDUCATOR_NONF2F_HOURS,
  DELETE_TEMPORARY_EDUCATOR,
  UPDATE_TEMPORARY_EDUCATOR_F2F_HOURS,
  UPDATE_TEMPORARY_EDUCATOR_NONF2F_HOURS,
  RESET_CLAIM_FORM,
} from './types';

export interface ICreateCaseClaimState {
  formValues: ISCaseClaimCreate;
  educatorHoursPerDay: IDayHoursPerEducator;
  tempEducatorHoursPerDay: IDayHoursPerEducator;
}
export const initialState: ICreateCaseClaimState = {
  formValues: {
    children: [],
    id: '',
    caseId: '',
    daysOfCare: [],
    educatorDays: [],
    temporaryEducators: [],
    educators: [],
    agreed: false,
    paymentType: undefined,
    weekEnding: '',
    caseDayClaims: [],
    educatorClaims: [],
    temporaryEducatorClaims: [],
  },
  educatorHoursPerDay: {},
  tempEducatorHoursPerDay: {},
};

const iSClaimReducer = (
  state: ICreateCaseClaimState = initialState,
  action: ISClaimActionTypes
): ICreateCaseClaimState => {
  switch (action.type) {
    case SET_FORM_VALUES: {
      return {
        ...state,
        formValues: action.formValues,
      };
    }
    case SET_FIELD_VALUE: {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: action.value,
        },
      };
    }
    case UPDATE_DAYS_OF_CARE: {
      const copyOfDaysOfCare = [...state.formValues.caseDayClaims];
      copyOfDaysOfCare[action.index].careDay = action.day;
      return {
        ...state,
        formValues: {
          ...state.formValues,
          caseDayClaims: copyOfDaysOfCare,
        },
      };
    }
    case UPDATE_DAYS_OF_CARE_NF2F_HOURS: {
      const copyOfDaysOfCare = [...state.formValues.caseDayClaims];
      copyOfDaysOfCare[action.index].nonFaceToFaceHours = action.nf2f;
      return {
        ...state,
        formValues: {
          ...state.formValues,
          caseDayClaims: copyOfDaysOfCare,
        },
      };
    }
    case UPDATE_DAYS_OF_CARE_F2F_HOURS: {
      const copyOfDaysOfCare = [...state.formValues.caseDayClaims];
      copyOfDaysOfCare[action.index].faceToFaceHours = action.f2f;
      return {
        ...state,
        formValues: {
          ...state.formValues,
          caseDayClaims: copyOfDaysOfCare,
        },
      };
    }
    case UPDATE_EDUCATORS: {
      const { educators } = action;
      const educatorHours = (educators ?? []).reduce((acc: IDayHoursPerEducator, curr: IStaff) => {
        const existing = state.educatorHoursPerDay[curr.id];
        return { ...acc, [curr.id]: existing || {} };
      }, {} as IDayHoursPerEducator);
      return {
        ...state,
        formValues: {
          ...state.formValues,
          educators: educators ?? [],
        },
        educatorHoursPerDay: educatorHours,
      };
    }
    case UPDATE_EDUCATOR_NONF2F_HOURS: {
      const newHoursMap: IDayHoursPerEducator = { ...state.educatorHoursPerDay };
      const educatorHours = newHoursMap[action.educator];
      educatorHours[action.day] = {
        faceToFaceHours: 0,
        ...educatorHours[action.day],
        nonFaceToFaceHours: action.nonFaceToFaceHours,
      };

      return {
        ...state,
        educatorHoursPerDay: newHoursMap,
      };
    }
    case UPDATE_EDUCATOR_F2F_HOURS: {
      const newHoursMap: IDayHoursPerEducator = { ...state.educatorHoursPerDay };
      const educatorHours = newHoursMap[action.educator];
      educatorHours[action.day] = {
        nonFaceToFaceHours: 0,
        ...educatorHours[action.day],
        faceToFaceHours: action.faceToFaceHours,
      };

      return {
        ...state,
        educatorHoursPerDay: newHoursMap,
      };
    }
    case ADD_TEMPORARY_EDUCATOR: {
      const educators = [...state.formValues.temporaryEducators];
      const educator = { firstName: '', lastName: '', id: `${Date.now()}` };
      return {
        ...state,
        formValues: {
          ...state.formValues,
          temporaryEducators: [...educators, educator],
        },
        tempEducatorHoursPerDay: {
          ...state.tempEducatorHoursPerDay,
          [educator.id]: {},
        },
      };
    }
    case DELETE_TEMPORARY_EDUCATOR: {
      const tempEducators = [...state.formValues.temporaryEducators];
      const educator = tempEducators.splice(action.index, 1);

      const newHours = { ...state.tempEducatorHoursPerDay };
      delete newHours[educator[0]?.id || ''];
      return {
        ...state,
        formValues: {
          ...state.formValues,
          temporaryEducators: tempEducators,
        },
        tempEducatorHoursPerDay: newHours,
      };
    }
    case UPDATE_TEMPORARY_EDUCATOR_F2F_HOURS: {
      const newHoursMap: IDayHoursPerEducator = { ...state.tempEducatorHoursPerDay };
      const educatorHours = newHoursMap[action.educator];
      educatorHours[action.day] = {
        nonFaceToFaceHours: 0,
        ...educatorHours[action.day],
        faceToFaceHours: action.faceToFaceHours,
      };

      return {
        ...state,
        tempEducatorHoursPerDay: newHoursMap,
      };
    }
    case UPDATE_TEMPORARY_EDUCATOR_NONF2F_HOURS: {
      const newHoursMap: IDayHoursPerEducator = { ...state.tempEducatorHoursPerDay };
      const educatorHours = newHoursMap[action.educator];
      educatorHours[action.day] = {
        faceToFaceHours: 0,
        ...educatorHours[action.day],
        nonFaceToFaceHours: action.nonFaceToFaceHours,
      };

      return {
        ...state,
        tempEducatorHoursPerDay: newHoursMap,
      };
    }

    case RESET_CLAIM_FORM: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default iSClaimReducer;
