import {
  ContextActionTypes,
  SET_CURRENT_BUSINESS,
  SET_CURRENT_CENTER,
  SET_CURRENT_BUSINESS_FILTERS,
  SET_CURRENT_CENTER_FILTERS,
  SET_CURRENT_BUSINESS_CONFIGURATION,
  SET_CURRENT_BUSINESS_FEATURE,
} from './types';

export const setCurrentBusiness = (businessId: string): ContextActionTypes => ({
  type: SET_CURRENT_BUSINESS,
  businessId,
});

export const setCurrentCenter = (centerId: string): ContextActionTypes => ({
  type: SET_CURRENT_CENTER,
  centerId,
});

export const setCurrentBusinessFilters = (businessIds: string[]): ContextActionTypes => ({
  type: SET_CURRENT_BUSINESS_FILTERS,
  businessIds,
});

export const setCurrentCenterFilters = (centerIds: string[]): ContextActionTypes => ({
  type: SET_CURRENT_CENTER_FILTERS,
  centerIds,
});

export const setCurrentBusinessConfiguration = (businessConfig: IBusinessConfiguration): ContextActionTypes => ({
  type: SET_CURRENT_BUSINESS_CONFIGURATION,
  businessConfig,
});

export const setCurrentBusinessFeature = (businessFeature: IBusinessFeature[]): ContextActionTypes => ({
  type: SET_CURRENT_BUSINESS_FEATURE,
  businessFeature,
});
