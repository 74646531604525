import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import { setCurrentBusiness, setCurrentCenter } from 'store/context/actions';
import Select from 'shared/components/Select';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { sortBy } from 'lodash';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  pageName: string;
  className?: string;
  /**
   * Sometimes you only want to display a business selector.
   *
   * Defaults to `true`
   */
  showCenterSelect?: boolean;
  removeDefaultBackground?: boolean;
  filteredCenterIds?: string[];
}

const BusinessAndCenterSelectBanner: React.FC<IProps> = ({
  pageName,
  className,
  showCenterSelect = true,
  removeDefaultBackground = false,
  filteredCenterIds,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const { data: businessesData, loading: businessesLoading } = useGetAllowedEntities();
  const { data: centers, loading: centersLoading } = useGetActiveCentersWithLoading();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  let filteredCenters = useMemo(() => {
    let businessCenters = centers?.filter((c) => !currentBusinessId || c.entityId === currentBusinessId) || [];
    businessCenters.sort((a, b) => a.name.localeCompare(b.name));
    if (filteredCenterIds && !user?.isInternal) {
      return businessCenters?.filter((c) => filteredCenterIds.includes(c.id));
    }
    return businessCenters;
  }, [centers, filteredCenterIds]);

  const handleUpdateCenterId = useCallback(
    (id) => {
      dispatch(setCurrentCenter(id));
      localStorage.setItem(`${user?.email}-last-selected-centerId`, id);
    },
    [user, dispatch]
  );

  useEffect(() => {
    if (filteredCenterIds && !user?.isInternal) handleUpdateCenterId(filteredCenters?.[0]?.id);
  }, []);

  const handleUpdateBusinessId = useCallback(
    (id) => {
      dispatch(setCurrentBusiness(id));
      localStorage.setItem(`${user?.email}-last-selected-businessId`, id);
    },
    [user, dispatch]
  );

  // when data has loaded, auto select business and center ids from cache or from first in list
  useEffect(() => {
    if (businessesData && centers) {
      const cachedBusinessId = localStorage.getItem(`${user?.email}-last-selected-businessId`) || '';
      const cachedCenterId = localStorage.getItem(`${user?.email}-last-selected-centerId`) || '';
      const activeCachedBusiness = businesses.find((b) => b.id === cachedBusinessId);
      const activeCachedCenter = centers.find((c) => c.id === cachedCenterId);
      const firstBusiness = businesses[0];
      const defaultBusinessId = user?.entityId || activeCachedBusiness?.id || firstBusiness?.id || '';
      const firstCenterAtDefaultBusiness = centers.filter((c) => c.entityId === defaultBusinessId)[0];
      const defaultCenterId = activeCachedCenter?.id || firstCenterAtDefaultBusiness?.id || '';

      handleUpdateBusinessId(defaultBusinessId);
      handleUpdateCenterId(defaultCenterId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessesData, centers]);

  if (!user?.isInternal && centers?.length === 1) {
    return <></>;
  }

  return (
    <div
      className={`d-flex align-items-center ${
        !removeDefaultBackground && 'bg-baby-blue'
      } py-2 px-4 rounded ${className} flex-wrap`}
    >
      <div className="mr-4 mt-2 mb-2">You are currently viewing {pageName} for: </div>
      {user?.isInternal && (
        <div className="mt-2 mb-2">
          <Select
            isLoading={businessesLoading}
            className="mb-0 mr-4 flex-grow-0 min-width-200"
            options={businesses?.map((b) => ({ value: b.id, label: b.name })) ?? []}
            value={currentBusinessId}
            onChange={(option) => {
              handleUpdateBusinessId(option.value);
              const firstCenterForSelectedBusiness = centers?.filter((c) => c.entityId === option.value)[0];
              handleUpdateCenterId(firstCenterForSelectedBusiness?.id ?? '');
            }}
          />
        </div>
      )}
      {showCenterSelect && (
        <div className="mr-4 mt-2 mb-2">
          <Select
            isLoading={centersLoading}
            className="mb-0 flex-grow-0 min-width-200"
            options={filteredCenters?.map((c) => ({ value: c.id, label: c.name })) ?? []}
            value={currentCenterId}
            onChange={(option) => handleUpdateCenterId(option.value)}
            noOptionsMessage={() => `No Active ${fieldLabels.center} Found.`}
          />
        </div>
      )}
    </div>
  );
};

export default BusinessAndCenterSelectBanner;
