import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { useTranslation } from 'react-i18next';
import CenterForecastsTab, { RefectchCentreForecastsHandler } from './CentersTab/CentersForecastList';
import ChildForecastsTab from './ChildrenTab/ChildrenForecastList';

type ForecastTabs = 'center' | 'children';

interface IProps {}

export type ExternalHandler = {
  refetchCenterForecasts: () => void;
};

const ForecastTab = forwardRef<ExternalHandler, IProps>(({}, ref) => {
  const { t } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState<ForecastTabs>('center');
  const refetchCentreForecastsRef = useRef<RefectchCentreForecastsHandler | null>(null);

  useImperativeHandle(ref, () => ({
    refetchCenterForecasts: () => {
      refetchCentreForecastsRef.current?.refetch();
    },
  }));

  return (
    <PageWrapperBody>
      <Tabs
        id="time-log-tabs"
        activeKey={activeTabKey}
        onSelect={(tab) => {
          setActiveTabKey(tab as ForecastTabs);
        }}
        className="mb-0 pl-4"
      >
        <Tab eventKey="center" title={t('spelling.center')}>
          <CenterForecastsTab ref={refetchCentreForecastsRef} />
        </Tab>
        <Tab eventKey="children" title="Children">
          <ChildForecastsTab />
        </Tab>
      </Tabs>
    </PageWrapperBody>
  );
});

export default ForecastTab;
