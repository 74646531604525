import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { ccmsApprovalFields } from './fields';

export const GET_CCMS_APPROVAL = (fields: string = ccmsApprovalFields) => gql`
  query ($businessId: ID!, $centerId: ID!) {
    getCcmsApproval(businessId: $businessId, centerId: $centerId) {
      ${fields}
    }
  }
`;

interface IGetCcmsApprovalData {
  getCcmsApproval: ICcmsApproval;
}

interface IGetCcmsApprovalVariables {
  businessId: string;
  centerId: string;
}

export const useGetCcmsApproval = (
  options?: QueryHookOptions<IGetCcmsApprovalData, IGetCcmsApprovalVariables>,
  fields?: string
) =>
  useQuery<IGetCcmsApprovalData, IGetCcmsApprovalVariables>(GET_CCMS_APPROVAL(fields), {
    fetchPolicy: 'network-only',
    ...options,
  });
