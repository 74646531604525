import { ICcsEntitlement } from 'shared/types/ccsEntitlement';

export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNT_BY_ID_SUCCESS = 'GET_ACCOUNT_BY_ID_SUCCESS';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const GET_CONTRACTS_FOR_ACCOUNT_CHILD = 'GET_CONTRACTS_FOR_ACCOUNT_CHILD';
export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const DELETE_CONTRACT = 'DELETE_CONTRACT';
export const SET_ACCOUNT_BILLING_CYCLE = 'SET_ACCOUNT_BILLING_CYCLE';
export const GET_CCS_CERTIFICATE_FOR_ACCOUNT_CHILD = 'GET_CCS_CERTIFICATE_FOR_ACCOUNT_CHILD';
export const GET_CCS_ENTITLEMENTS_FOR_ACCOUNT_CHILD = 'GET_CCS_ENTITLEMENTS_FOR_ACCOUNT_CHILD';
export const GET_CCS_DETERMINATION_FOR_ACCOUNT_CHILD = 'GET_CCS_DETERMINATION_FOR_ACCOUNT_CHILD';
export const GET_PAYMENT_METHODS_FOR_ACCOUNT_SUCCESS = 'GET_PAYMENT_METHODS_FOR_ACCOUNT_SUCCESS';
export const CREATE_PAYMENT_METHOD_FOR_ACCOUNT_SUCCESS = 'CREATE_PAYMENT_METHOD_FOR_ACCOUNT_SUCCESS';
export const UPDATE_PAYMENT_METHOD_FOR_ACCOUNT_SUCCESS = 'UPDATE_PAYMENT_METHOD_FOR_ACCOUNT_SUCCESS';
export const SET_ACCOUNT_DEFAULT_PAYMENT_METHOD = 'SET_ACCOUNT_DEFAULT_PAYMENT_METHOD';
export const SET_ACCOUNT_AUTOPAY_PAYMENT_METHOD = 'SET_ACCOUNT_AUTOPAY_PAYMENT_METHOD';
export const REMOVE_ACCOUNT_PAYMENT_METHOD = 'REMOVE_ACCOUNT_PAYMENT_METHOD';
export const ADD_TRANSACTION_AMOUNT_TO_ACCOUNT_BALANCE = 'ADD_TRANSACTION_AMOUNT_TO_ACCOUNT_BALANCE';
export const UPDATE_CERTIFICATE = 'UPDATE_CERTIFICATE';
export const UPDATE_DETERMINATION = 'UPDATE_DETERMINATION';
export const ADD_NEW_CONTACT_TO_ACCOUNT_SUCCESS = 'ADD_NEW_CONTACT_TO_ACCOUNT_SUCCESS';
export const UPDATE_CONTACT_RELATIONSHIPS_SUCCESS = 'UPDATE_CONTACT_RELATIONSHIPS_SUCCESS';
export const REMOVE_CONTACT_FROM_ACCOUNT_SUCCESS = 'REMOVE_CONTACT_FROM_ACCOUNT_SUCCESS';
export const ARCHIVE_CHILD_ON_ACCOUNT_SUCCESS = 'ARCHIVE_CHILD_ON_ACCOUNT_SUCCESS';
export const REMOVE_CHILD_ON_ACCOUNT_SUCCESS = 'REMOVE_CHILD_ON_ACCOUNT_SUCCESS';
export const UNARCHIVE_CHILD_ON_ACCOUNT_SUCCESS = 'UNARCHIVE_CHILD_ON_ACCOUNT_SUCCESS';
export const APPLY_DISCOUNT_TO_ACCOUNT_SUCCESS = 'APPLY_DISCOUNT_TO_ACCOUNT_SUCCESS';
export const UPDATE_APPLIED_ACCOUNT_DISCOUNT_SUCCESS = 'UPDATE_APPLIED_ACCOUNT_DISCOUNT_SUCCESS';
export const REMOVE_APPLIED_ACCOUNT_DISCOUNT_SUCCESS = 'REMOVE_APPLIED_ACCOUNT_DISCOUNT_SUCCESS';
export const GET_ACCOUNT_ITEMIZED_BILLS_SUCCESS = 'GET_ACCOUNT_ITEMIZED_BILLS_SUCCESS';
export const GET_ACCOUNT_BILLING_CYCLE_SUCCESS = 'GET_ACCOUNT_BILLING_CYCLE_SUCCESS';
export const SET_SELECTED_ACCOUNT_ITEMIZED_BILL = 'SET_SELECTED_ACCOUNT_ITEMIZED_BILL';
export const GET_SELECTED_ACCOUNT_ITEMIZED_BILL = 'GET_SELECTED_ACCOUNT_ITEMIZED_BILL';
export const CREATE_NEW_MANUAL_ITEMIZED_BILL = 'CREATE_NEW_MANUAL_ITEMIZED_BILL';

interface IGetAccountsSuccessAction {
  type: typeof GET_ACCOUNTS_SUCCESS;
  accounts: IAccount[];
  totalResults: number;
}

interface IGetAccountByIdSuccessAction {
  type: typeof GET_ACCOUNT_BY_ID_SUCCESS;
  account: IAccount;
}

interface IAddAccountSuccessAction {
  type: typeof ADD_ACCOUNT_SUCCESS;
  account: IAccount;
}

interface IUpdateAccountSuccessAction {
  type: typeof UPDATE_ACCOUNT_SUCCESS;
  account: IAccount;
}

interface IGetContractsForAccountChildAction {
  type: typeof GET_CONTRACTS_FOR_ACCOUNT_CHILD;
  accountId: string;
  childId: string;
  contracts: IContract[];
}

interface ICreateContractAction {
  type: typeof CREATE_CONTRACT;
  contract: IContract;
}
interface IUpdateContractAction {
  type: typeof UPDATE_CONTRACT;
  contract: IContract;
  newContract?: IContract;
}

interface IDeleteContractAction {
  type: typeof DELETE_CONTRACT;
  contract: IContract;
}

interface ISetAccountBillingCylceAction {
  type: typeof SET_ACCOUNT_BILLING_CYCLE;
  cycle: IBillingCycle;
}

interface IGetCcsCertifcateForAccountChildAction {
  type: typeof GET_CCS_CERTIFICATE_FOR_ACCOUNT_CHILD;
  accountId: string;
  childId: string;
  certificate: ICcssCertificate[];
}

interface IGetCcsEntitlementsForAccountChildAction {
  type: typeof GET_CCS_ENTITLEMENTS_FOR_ACCOUNT_CHILD;
  accountId: string;
  childId: string;
  entitlement: ICcsEntitlement;
}

interface IGetCcsDeterminationForAccountChildAction {
  type: typeof GET_CCS_DETERMINATION_FOR_ACCOUNT_CHILD;
  accountId: string;
  childId: string;
  determination: ICcssDetermination[];
}

interface IGetPaymentMethodsForAccountSuccessAction {
  type: typeof GET_PAYMENT_METHODS_FOR_ACCOUNT_SUCCESS;
  accountId: string;
  paymentMethods: IPaymentMethod[];
}

interface ICreatePaymentMethodForAccountSuccessAction {
  type: typeof CREATE_PAYMENT_METHOD_FOR_ACCOUNT_SUCCESS;
  paymentMethod: IPaymentMethod;
}

interface IUpdatePaymentMethodForAccountSuccessAction {
  type: typeof UPDATE_PAYMENT_METHOD_FOR_ACCOUNT_SUCCESS;
  paymentMethod: IPaymentMethod;
}

interface ISetAccountDefaultPaymentMethodAction {
  type: typeof SET_ACCOUNT_DEFAULT_PAYMENT_METHOD;
  accountId: string;
  paymentMethodId: string;
}

interface ISetAccountAutopayPaymentMethodAction {
  type: typeof SET_ACCOUNT_AUTOPAY_PAYMENT_METHOD;
  accountId: string;
  paymentMethodId: string | null;
}

interface IRemoveAccountPaymentMethodAction {
  type: typeof REMOVE_ACCOUNT_PAYMENT_METHOD;
  accountId: string;
  paymentMethodId: string;
}

interface IAddTransactionAmountToAccountBalanceAction {
  type: typeof ADD_TRANSACTION_AMOUNT_TO_ACCOUNT_BALANCE;
  accountId: string;
  amount: number;
}

interface IUpdateCertificate {
  type: typeof UPDATE_CERTIFICATE;
  accountId: string;
  certificate: ICcssCertificate;
}

interface IUpdateDetermination {
  type: typeof UPDATE_DETERMINATION;
  accountId: string;
  determination: ICcssDetermination;
}

interface IAddNewContactToAccountSuccessAction {
  type: typeof ADD_NEW_CONTACT_TO_ACCOUNT_SUCCESS;
  accountId: string;
  contact: IContact;
}
interface IUpdateContactRelationshipsAction {
  type: typeof UPDATE_CONTACT_RELATIONSHIPS_SUCCESS;
  accountId: string;
  contactId: string;
  children: IContactChild[];
  isPrimary: boolean;
  email?: string;
  permissions?: AccountPermissions[];
}

interface IRemoveContactFromAccountAction {
  type: typeof REMOVE_CONTACT_FROM_ACCOUNT_SUCCESS;
  accountId: string;
  contactId: string;
}

interface IArchiveChildOnAccountAction {
  type: typeof ARCHIVE_CHILD_ON_ACCOUNT_SUCCESS;
  accountId: string;
  childId: string;
}

interface IRemoveChildOnAccountAction {
  type: typeof REMOVE_CHILD_ON_ACCOUNT_SUCCESS;
  accountId: string;
  childId: string;
}

interface IUnarchiveChildOnAccountAction {
  type: typeof UNARCHIVE_CHILD_ON_ACCOUNT_SUCCESS;
  accountId: string;
  childId: string;
}

interface IApplyDiscountToAccountAction {
  type: typeof APPLY_DISCOUNT_TO_ACCOUNT_SUCCESS;
  accountId: string;
  appliedDiscounts: IAppliedAccountDiscount[];
}

interface IUpdateAppliedAccountDiscountAction {
  type: typeof UPDATE_APPLIED_ACCOUNT_DISCOUNT_SUCCESS;
  accountId: string;
  appliedDiscount: IAppliedAccountDiscount;
}

interface IRemoveAppliedAccountDiscountAction {
  type: typeof REMOVE_APPLIED_ACCOUNT_DISCOUNT_SUCCESS;
  appliedDiscount: IAppliedAccountDiscount;
}

interface IGetAccountItemizedBillsSuccessAction {
  type: typeof GET_ACCOUNT_ITEMIZED_BILLS_SUCCESS;
  accountId: string;
  itemizedBills: IItemizedBillWithoutTransactions[];
}

interface IGetAccountBillingCycleSuccessAction {
  type: typeof GET_ACCOUNT_BILLING_CYCLE_SUCCESS;
  accountId: string;
  billingCycle: IBillingCycle;
}

interface ISelectAccountItemizedBillAction {
  type: typeof SET_SELECTED_ACCOUNT_ITEMIZED_BILL;
  accountId: string;
  itemizedBillId: string;
}

interface IGetAccountItemizedBillAction {
  type: typeof GET_SELECTED_ACCOUNT_ITEMIZED_BILL;
  accountId: string;
  itemizedBill: IItemizedBill;
}

interface ICreateNewManualItemizedBillAction {
  type: typeof CREATE_NEW_MANUAL_ITEMIZED_BILL;
  accountId: string;
  itemizedBill: IItemizedBill;
}

export type AccountActionTypes =
  | IGetAccountsSuccessAction
  | IGetAccountByIdSuccessAction
  | IAddAccountSuccessAction
  | IUpdateAccountSuccessAction
  | IGetContractsForAccountChildAction
  | ICreateContractAction
  | IUpdateContractAction
  | IDeleteContractAction
  | ISetAccountBillingCylceAction
  | IGetCcsCertifcateForAccountChildAction
  | IGetCcsEntitlementsForAccountChildAction
  | IGetCcsDeterminationForAccountChildAction
  | IGetPaymentMethodsForAccountSuccessAction
  | ICreatePaymentMethodForAccountSuccessAction
  | IUpdatePaymentMethodForAccountSuccessAction
  | ISetAccountDefaultPaymentMethodAction
  | ISetAccountAutopayPaymentMethodAction
  | IRemoveAccountPaymentMethodAction
  | IAddTransactionAmountToAccountBalanceAction
  | IUpdateCertificate
  | IUpdateDetermination
  | IAddNewContactToAccountSuccessAction
  | IUpdateContactRelationshipsAction
  | IRemoveContactFromAccountAction
  | IArchiveChildOnAccountAction
  | IRemoveChildOnAccountAction
  | IUnarchiveChildOnAccountAction
  | IApplyDiscountToAccountAction
  | IUpdateAppliedAccountDiscountAction
  | IRemoveAppliedAccountDiscountAction
  | IGetAccountItemizedBillsSuccessAction
  | IGetAccountBillingCycleSuccessAction
  | ISelectAccountItemizedBillAction
  | IGetAccountItemizedBillAction
  | ICreateNewManualItemizedBillAction;
