import React from 'react';
import Card from 'react-bootstrap/Card';

const TableHeader = ({ children, className = '', ...props }) => {
  return (
    <Card className={`d-flex flex-row align-items-center px-4 py-2 m-0 table-header ${className || ''}`}>
      {children}
    </Card>
  );
};

export default TableHeader;
