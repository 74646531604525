import { gql } from '@apollo/client';
import { createCenter } from 'pages/Centers/duck/actions';
import { useDispatch } from 'react-redux';
import { useMutation } from 'shared/apis/core';
import { addCenterToTimezoneHash } from 'store/timezones/actions';

interface ICreateCenterData {
  createCenter: ICenter;
}

interface ICreateCenterVariables {
  input: {
    entityId: string;
    name: string;
    address: IAddress;
    phoneNumber: string;
    avatar: string;
    serviceType: CenterServiceType;
    primaryContactPersonId: string;
  };
}

export const CREATE_CENTER_AT_ENTITY = gql`
  mutation ($input: CreateCenterInput!) {
    createCenter(input: $input) {
      id
      name
      tags {
        name
        category
      }
      phoneNumber
      deactivatedAt
      entityId
      deletedAt
      avatar {
        url
      }
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }
      timezone
      owner {
        firstname
        lastname
      }
      serviceType
      primaryContactPersonId
    }
  }
`;

/**
 * Create a new center at an entity
 */
export const useCreateNewCenterAtEntity = () => {
  const dispatch = useDispatch();

  return useMutation<ICreateCenterData, ICreateCenterVariables>(CREATE_CENTER_AT_ENTITY, {
    onCompleted: (data) => {
      dispatch(createCenter(data.createCenter));
      dispatch(addCenterToTimezoneHash(data.createCenter));
    },
  });
};
