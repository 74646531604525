import React from 'react';

interface IProps {
  children: React.ReactNode | null;
}

const PageHeader: React.FC<IProps> = ({ ...props }) => {
  // styles for `app-page-content-header` are found in app.scss
  return (
    <div className="app-page-content-header d-flex align-items-center" style={{ gap: '.5rem' }}>
      {props.children}
    </div>
  );
};

export default PageHeader;
