import React, { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import { currencyFormat } from 'shared/util/currency';
import { findIndex } from 'lodash';
import { ActionType } from '../ReturnDepositModal';
import Tooltip from 'shared/components/Tooltip';

interface IProps {
  title: string;
  childId: string;
  onSelect: (selected: IAccountDepositSummary, actionType: ActionType) => void;
  selectedAccountDepositSummary: IAccountDepositSummary[];
  accountDepositSummary: IAccountDepositSummary;
  availableBalance: number;
  className?: string;
}

const DepositCheckOption: React.FC<IProps> = ({
  title = '',
  className = '',
  childId = '',
  onSelect,
  selectedAccountDepositSummary,
  accountDepositSummary,
  availableBalance,
  ...props
}) => {
  const [depositOption, setDepositOption] = useState<IAccountDepositSummary>(accountDepositSummary);
  const isSelected = findIndex(selectedAccountDepositSummary, (s) => s.childId === depositOption.childId) > -1;

  const classes = cx({
    'align-items-center kt-detailed-radio-option': true,
    selected: isSelected,
    [className]: true,
  });

  const handleChangeAmount = (value: string) => {
    setDepositOption({ ...depositOption, amount: parseFloat(value) });
  };

  return (
    <div id={title} className={classes}>
      <div>
        <div className="d-flex flex-row ">
          <div onClick={() => onSelect(depositOption, 'toggle')}>
            <FontAwesomeIcon
              size="2x"
              icon={isSelected ? faCheckSquare : faSquare}
              color={isSelected ? colors.secondary : colors.slate}
            />
          </div>
          <div className="flex-grow-1 ml-2 pt-1">
            {title}
            {!childId && (
              <Tooltip
                text="This deposit total was not linked to any individual child on the account"
                direction="right"
              >
                <FontAwesomeIcon icon={faInfoCircle} size="sm" className="ml-2" />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="mt-4 pl-8 pr-2">
          <CurrencyInput2
            className="border-radius-0 mb-2"
            required
            disabled={!isSelected}
            value={(depositOption.amount ?? 0).toFixed(2)}
            onChange={handleChangeAmount}
            onBlur={() => onSelect(depositOption, 'update')}
            errorText={
              depositOption.amount > availableBalance
                ? 'This amount exceeds the available deposit.'
                : 'This amount should be greater than $0.00.'
            }
            isInvalid={depositOption.amount > availableBalance || depositOption.amount <= 0}
          />
          <small className="d-block">{currencyFormat(availableBalance)} available</small>
        </div>
      </div>
    </div>
  );
};

export default DepositCheckOption;
