import React, { useState, useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import { CreateButton } from 'shared/components/Buttons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { debounce } from 'lodash';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTranslation } from 'react-i18next';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { useGetBusReportPdfLazy, useGetPaginatedBusRosters } from 'gql/busRoster/queries';
import BusRosterTable from './components/BusRosterTable';
import DeleteBusRosterConfirmationModal from './components/DeleteBusRosterConfirmationModal';
import DownloadBusRosterForm from './components/DownloadBusRosterFormModal';
import moment from 'moment';
import { showToast } from 'shared/components/Toast';

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

interface IBusRosterDeleteModalShape {
  isOpen: boolean;
  busRosterId: string | null;
}

interface IBusRosterDownloadFormModalShape {
  isOpen: boolean;
  busRoster: IBusRoster | null;
}

const BusRoster: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { businessId, centerFilterIds } = useSelector((state: RootState) => state.context);
  const [tableData, setTableData] = useState<IBusRoster[]>([]);
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState<IBusRosterSort[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const { data: centers } = useGetActiveCentersWithLoading();
  const [showDeleteBusRosterConfirmationModal, setShowDeleteBusRosterConfirmationModal] =
    useState<IBusRosterDeleteModalShape>({ isOpen: false, busRosterId: null });
  const [showBusRosterDownloadFormModal, setShowBusRosterDownloadFormModal] =
    useState<IBusRosterDownloadFormModalShape>({ isOpen: false, busRoster: null });
  const [reportFormData, setReportFormData] = useState<IBusRosterPdfDataShape>({
    busRosterId: null,
    startDate: null,
    endDate: null,
    additionalChildren: undefined,
  });

  const { data: busRosterData, loading: busRostersLoading } = useGetPaginatedBusRosters({
    variables: {
      input: {
        businessId: businessId ?? '',
        centerIds: centerFilterIds,
        sortBy: sort[0].field,
        sortDirection: sort[0].direction === 'ASCENDING' ? 'asc' : 'desc',
        searchKey: searchTerm,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
      },
    },
    skip: businessId == null || businessId === '',
    onCompleted: (result) => {
      setTableData(result.getPaginatedBusRosters.data);
    },
  });

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSort([{ field, direction }]);
  }, []);

  const handleSearchDebounced = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      tableFunctions.changePage(1, tableState.pageSize);
    }, 250),
    []
  );

  const [generateBusRosterPdfFn, { loading: generateBusRosterPdfLoading }] = useGetBusReportPdfLazy({
    onCompleted: (result) => {
      if (result.getBusRosterPdf && result.getBusRosterPdf.length > 0) {
        result.getBusRosterPdf.forEach((uri) => {
          window.open(uri, '_blank');
        });
      } else {
        showToast(t('bus-roster.download-bus-roster-pdf-error'), 'error');
      }
    },
    onError: (err) => {
      showToast(t('bus-roster.download-bus-roster-pdf-error'), 'error');
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <PageWrapper
      pageTitle={t('bus-roster.page-title')}
      applyPadding={true}
      buttonComponent={
        true === true && (
          <HasRoleAreaLevel
            action={{ area: AreaType.Attendance, permission: PermissionType.BusRoster, level: RoleLevelType.Create }}
          >
            <CreateButton onClick={() => history.push('/bus-roster/create')}>
              {t('bus-roster.buttons.add-bus-roster')}
            </CreateButton>
          </HasRoleAreaLevel>
        )
      }
    >
      <div>
        <BusRosterTable
          totalResults={busRosterData?.getPaginatedBusRosters.totalRecords || 0}
          tableState={tableState}
          tableFunctions={tableFunctions}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          pageSize={tableState.pageSize}
          activePage={tableState.activePage}
          loading={busRostersLoading}
          data={tableData ?? []}
          onSort={handleSort}
          centers={centers}
          setSearchTerm={handleSearchDebounced}
          downloadLoading={generateBusRosterPdfLoading}
          onDelete={(busRosterId) => setShowDeleteBusRosterConfirmationModal({ isOpen: true, busRosterId })}
          onDownload={(busRoster) => {
            setReportFormData((prev) => ({ ...prev, busRosterId: busRoster.id }));
            setShowBusRosterDownloadFormModal({ isOpen: true, busRoster: busRoster });
          }}
        ></BusRosterTable>
      </div>

      {showBusRosterDownloadFormModal.busRoster && (
        <DownloadBusRosterForm
          isOpen={showBusRosterDownloadFormModal.isOpen}
          busRoster={showBusRosterDownloadFormModal.busRoster}
          handleClose={() => {
            setShowBusRosterDownloadFormModal({ isOpen: false, busRoster: null });
            setReportFormData({ startDate: null, endDate: null, busRosterId: null, additionalChildren: undefined });
          }}
          handleDownload={() =>
            generateBusRosterPdfFn({
              variables: {
                additionalChildren: reportFormData.additionalChildren ?? 0,
                busRosterId: reportFormData.busRosterId!,
                endDate: moment(reportFormData.endDate).format('YYYY-MM-DD'),
                startDate: moment(reportFormData.startDate).format('YYYY-MM-DD'),
              },
            })
          }
          loading={generateBusRosterPdfLoading}
          formData={reportFormData}
          onUpdate={setReportFormData}
        ></DownloadBusRosterForm>
      )}

      {showDeleteBusRosterConfirmationModal.busRosterId && (
        <DeleteBusRosterConfirmationModal
          isOpen={showDeleteBusRosterConfirmationModal.isOpen}
          busRosterId={showDeleteBusRosterConfirmationModal.busRosterId}
          onClose={() => setShowDeleteBusRosterConfirmationModal({ isOpen: false, busRosterId: null })}
          onConfirm={(busRoster) => {
            setTableData((prev) => prev.filter((item) => item.id !== busRoster.id));
          }}
        ></DeleteBusRosterConfirmationModal>
      )}
    </PageWrapper>
  );
};

export default BusRoster;
