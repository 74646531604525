import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import Line from '../../LoadingSkeletons/Line';

/**
 * This component passes a render prop `formEditable` to its children
 * If `formEditable` is true, a 'cancel' and 'save' button are shown
 *
 * 12-9-2019: Not sure about the future of this component since we're moving more towards Accordions in the profile screens. This can most likely be removed at some point in the future.
 */
const ProfileCardContainer = ({ showEditIcon, label, isLoading, onCancel, onSave, ...props }) => {
  const [formEditable, setFormEditable] = useState(false);

  const toggleEditable = useCallback(() => {
    setFormEditable(!formEditable);
  }, [formEditable, setFormEditable]);

  const handleCancel = useCallback(() => {
    toggleEditable();

    onCancel && onCancel();
  }, [toggleEditable, onCancel]);

  const handleSave = useCallback(() => {
    toggleEditable();

    onSave && onSave();
  }, [toggleEditable, onSave]);

  return (
    <Card className="mx-0 p-8">
      {isLoading ? (
        [0, 1, 2, 3].map((i) => (
          <Row className="mt-4" key={i}>
            <Line />
          </Row>
        ))
      ) : (
        <>
          <Row>
            <Column>
              <h6 className="text-info">{label}</h6>
            </Column>
            {showEditIcon && !formEditable && (
              <FontAwesomeIcon icon={faEdit} size="2x" className="cursor-pointer" onClick={toggleEditable} />
            )}
          </Row>
          {props.children(formEditable)}
          {formEditable && (
            <Row className="mt-8 justify-content-end">
              <Button variant="light" className="mr-6" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="button" variant="primary" onClick={handleSave}>
                Save
              </Button>
            </Row>
          )}
        </>
      )}
    </Card>
  );
};

ProfileCardContainer.propTypes = {
  showEditIcon: PropTypes.bool,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

ProfileCardContainer.defaultProps = {
  showEditIcon: false,
  isLoading: false,
};

export default ProfileCardContainer;
