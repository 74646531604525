export const SET_CURRENT_BUSINESS = 'SET_CURRENT_BUSINESS';
export const SET_CURRENT_CENTER = 'SET_CURRENT_CENTER';
export const SET_CURRENT_BUSINESS_FILTERS = 'SET_CURRENT_BUSINESS_FILTERS';
export const SET_CURRENT_CENTER_FILTERS = 'SET_CURRENT_CENTER_FILTERS';
export const SET_CURRENT_BUSINESS_CONFIGURATION = 'SET_CURRENT_BUSINESS_CONFIGURATION';
export const SET_CURRENT_BUSINESS_FEATURE = 'SET_CURRENT_BUSINESS_FEATURE';

interface ISetCurrentBusinessAction {
  type: typeof SET_CURRENT_BUSINESS;
  businessId: string;
}

interface ISetCurrentCenterAction {
  type: typeof SET_CURRENT_CENTER;
  centerId: string;
}

interface ISetCurrentBusinessFiltersAction {
  type: typeof SET_CURRENT_BUSINESS_FILTERS;
  businessIds: string[];
}

interface ISetCurrentCenterFiltersAction {
  type: typeof SET_CURRENT_CENTER_FILTERS;
  centerIds: string[];
}

interface ISetCurrentBusinessConfigurationAction {
  type: typeof SET_CURRENT_BUSINESS_CONFIGURATION;
  businessConfig: IBusinessConfiguration | null;
}

interface ISetCurrentBusinessFeatureAction {
  type: typeof SET_CURRENT_BUSINESS_FEATURE;
  businessFeature: IBusinessFeature[];
}

export type ContextActionTypes =
  | ISetCurrentBusinessAction
  | ISetCurrentCenterAction
  | ISetCurrentBusinessFiltersAction
  | ISetCurrentCenterFiltersAction
  | ISetCurrentBusinessConfigurationAction
  | ISetCurrentBusinessFeatureAction;
