import i18next from 'i18next';
import { capitalize } from 'shared/util/string';
import { accountStatusColorHexes, cwaStatusCardsColors, cwaStatusColors } from 'shared/constants/tagColors';
import { formatDate } from '../EndOfCareTab/Tabs/EndOfCareActive/common';

type StatusCWA =
  | 'READY_TO_BE_SENT'
  | 'PENDING'
  | 'APPROVED'
  | 'REJECTED'
  | 'Cancelled'
  | 'Rejected'
  | 'Pending'
  | 'Approved'
  | 'CANCELLED'
  | 'Ready to be sent';

const statusOptionsForCWA: ITableFilterOption[] = [
  {
    label: capitalize(i18next.t('subsidies:cwa.status.approved')),
    value: 'APPROVED',
    indicatorColor: cwaStatusColors.COMPLETED,
  },
  {
    label: capitalize(i18next.t('subsidies:cwa.status.pending')),
    value: 'PENDING',
    indicatorColor: cwaStatusColors.PENDING,
  },
  {
    label: capitalize(i18next.t('subsidies:cwa.status.rejected')),
    value: 'REJECTED',
    indicatorColor: cwaStatusColors.FAILED,
  },
  {
    label: capitalize(i18next.t('subsidies:cwa.status.ready-to-be-sent')),
    value: 'READY_TO_BE_SENT',
    indicatorColor: cwaStatusColors.READY_TO_BE_SENT,
  },
  {
    label: capitalize(i18next.t('subsidies:cwa.status.cancelled')),
    value: 'CANCELLED',
    indicatorColor: cwaStatusColors.CANCELLED,
  },
];

const statusesForCWATable = [
  { label: capitalize(i18next.t('subsidies:cwa.status.ready-to-be-sent')), value: 'READY_TO_BE_SENT' },
  { label: capitalize(i18next.t('subsidies:cwa.status.pending')), value: 'PENDING' },
  { label: capitalize(i18next.t('subsidies:cwa.status.approved')), value: 'APPROVED' },
  { label: capitalize(i18next.t('subsidies:cwa.status.rejected')), value: 'REJECTED' },
  { label: capitalize(i18next.t('subsidies:cwa.status.cancelled')), value: 'CANCELLED' },
];

const statusAccountOptionsForCwa: ITableFilterOption[] = [
  {
    label: capitalize(i18next.t('translation:spelling.active')),
    value: 'Active',
    indicatorColor: accountStatusColorHexes.Active,
  },
  {
    label: capitalize(i18next.t('translation:spelling.inactive')),
    value: 'Inactive',
    indicatorColor: accountStatusColorHexes.Inactive,
  },
];

const statusOptionsForCWATable = [
  {
    value: capitalize(i18next.t('subsidies:cwa.status.approved')),
    indicatorColor: cwaStatusCardsColors.COMPLETED,
  },
  {
    value: capitalize(i18next.t('subsidies:cwa.status.pending')),
    indicatorColor: cwaStatusCardsColors.PENDING,
  },
  {
    value: capitalize(i18next.t('subsidies:cwa.status.rejected')),
    indicatorColor: cwaStatusCardsColors.FAILED,
  },
  {
    value: capitalize(i18next.t('subsidies:cwa.status.ready-to-be-sent')),
    indicatorColor: cwaStatusCardsColors.READY_TO_BE_SENT,
  },
  {
    value: capitalize(i18next.t('subsidies:cwa.status.cancelled')),
    indicatorColor: cwaStatusCardsColors.CANCELLED,
  },
];

const getStatusCWA = (status: StatusCWA | null) => {
  if (status == null) return '';

  const data = statusesForCWATable.filter((i) => i.value === status);
  if (Boolean(data.length)) return data[0].label;
};

const getNullableVariable = (variable: any, timezone: string) => {
  return variable ? formatDate(variable, timezone) : '-';
};

const getMultiValueName = (name: string, count: number) => {
  return `${name}${count > 1 ? 's' : ''}`;
};

export {
  getStatusCWA,
  getNullableVariable,
  statusOptionsForCWA,
  statusAccountOptionsForCwa,
  statusOptionsForCWATable,
  getMultiValueName,
};
