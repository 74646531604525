import React, { useCallback } from 'react';
import classnames from 'classnames';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'shared/components/Card';
import ContractOverviewTab from './ContractOverviewTab';
import ContractSubsidiesTab from './ContractSubsidiesTab';

type ContractTabType = 'overview' | 'subsidies';

interface IProps {
  contract: IContract;
  centerTimezone: Timezone;
  userCanUpdateContract: boolean;
  onUpdateContract: (contract: any) => void;
}

const ContractCard: React.FC<IProps> = ({
  contract,
  centerTimezone,
  userCanUpdateContract,
  onUpdateContract,
  ...props
}) => {
  const badgeClassNames = classnames({
    'badge text-light d-flex align-items-center justify-content-center': true,
    active: contract.status === 'ACTIVE',
    future: contract.status === 'FUTURE',
    expired: contract.status === 'EXPIRED',
  });

  const statusToString = useCallback((status: ContractStatus): string => {
    switch (status) {
      case 'ACTIVE':
        return 'Active';
      case 'EXPIRED':
        return 'Expired';
      case 'FUTURE':
        return 'Future';
      default:
        return '';
    }
  }, []);

  return (
    <Card
      className="flex-1 mx-1 h-100 kt-account-enrollment-tab-contract-card"
      headerClassName="border-0 d-flex justify-content-end pt-6"
      bodyClassName="pb-6 pt-0"
      header={<div className={badgeClassNames}>{statusToString(contract.status)}</div>}
    >
      <Tabs className="kt-account-enrollment-tab-contract-card-tabs">
        <Tab eventKey="overview" title="Overview">
          <ContractOverviewTab
            contract={contract}
            centerTimezone={centerTimezone}
            userCanUpdateContract={userCanUpdateContract}
            onUpdateContract={onUpdateContract}
          />
        </Tab>
        {(contract.associatedSubsidies ?? []).length > 0 && (
          <Tab eventKey="subsidies" title="Subsidies">
            <ContractSubsidiesTab contract={contract} />
          </Tab>
        )}
      </Tabs>
    </Card>
  );
};

export default ContractCard;
