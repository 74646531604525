import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { Circle } from 'shared/components/Shapes';
import { PersonAvatar } from 'shared/components/Avatar';
import colors from '_colors.module.scss';
import { Row, Col } from 'shared/components/Layout';
import { Modal } from 'react-bootstrap';
import Button from 'shared/components/Buttons';
import TimeOffReadOnlyDetails from './TimeOffReadOnlyDetails';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import TimeOffInputs from './TimeOffInputs';
import { useUpdateTimeOff, useCancelTimeOff } from '../../grapgql/mutations';
import { showToast } from 'shared/components/Toast';
import errorMessages from 'shared/constants/errorMessages';
import cast from 'shared/util/cast';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import { getFullName } from 'shared/util/string';
import { useSearchPayCodes } from 'gql/payCode/queries';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import { isEmpty } from 'lodash';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  timeOff: ITimeOff;
  isMyTimeOff?: boolean;
  hasEditPermission?: boolean;
}

const ReviewTimeOffModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  isMyTimeOff = false,
  timeOff: savedTimeOff,
  hasEditPermission = false,
}) => {
  const timezone =
    useSelector((state: RootState) =>
      savedTimeOff.centerId
        ? state.timezone.byCenterId[savedTimeOff.centerId]
        : state.timezone.byBusinessId[savedTimeOff.entityId]
    ) ?? moment.tz.guess();

  const [isInEdit, setIsInEdit] = useState(false);
  const [timeOff, updateTimeOff] = useState(savedTimeOff);
  const [lastSavedTimeoff, updateLastSavedTimeOff] = useState(savedTimeOff);
  const isTwoWeeksOld = moment(timeOff.createdAt).add(2, 'weeks').isBefore(moment()) && timeOff.status === 'Pending';
  const isDenyDisabled = !hasEditPermission || isMyTimeOff || timeOff.status === 'Denied';
  const isApproveDisabled = !hasEditPermission || isMyTimeOff || timeOff.status === 'Approved';
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpFeature = Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.subFeatures ?? undefined;
  const AdpTimeEnabled = AdpFeature?.find((x) => x.type === 'TimeAndAttendance')?.enabled ?? false;
  const [updateTimeOffFn, { loading }] = useUpdateTimeOff({
    onCompleted: (result) => {
      showToast('Time off updated.', 'success');
      updateTimeOff(result.updateTimeOffRequest);
      updateLastSavedTimeOff(result.updateTimeOffRequest);
      setIsInEdit(false);
    },
    onError: (err) => {
      showToast(errorMessages.generic, 'error');
      updateTimeOff(lastSavedTimeoff);
      setIsInEdit(false);
    },
  });
  const [cancelTimeOffFn, { loading: cancelLoading }] = useCancelTimeOff();

  const { data: ptoTypesData } = useSearchPayCodes({
    skip: isEmpty(timeOff.entityId),
    variables: {
      input: {
        businessId: timeOff.entityId,
        centerId: timeOff.centerId,
        from: 0,
        size: 10000, // if they have more than this ¯\_(ツ)_/¯
        sortDirection: 'ASCENDING',
        sortField: 'name',
        includeBusinessTypes: true,
      },
    },
  });

  const save = useCallback(() => {
    const input = {
      id: timeOff.id,
      allDay: timeOff.allDay,
      startTime: timeOff.allDay ? moment(timeOff.startTime).tz(timezone).startOf('d').toISOString() : timeOff.startTime,
      endTime: timeOff.allDay ? moment(timeOff.endTime).tz(timezone).startOf('d').toISOString() : timeOff.endTime,
      description: timeOff.description,
      hoursApproved: timeOff.hoursApproved,
      status: timeOff.status,
      ptoType: timeOff.ptoType,
    };

    updateTimeOffFn({ variables: { input } });
  }, [timeOff, timezone, updateTimeOffFn]);

  const approve = useCallback(() => {
    const input = {
      id: timeOff.id,
      status: 'Approved',
      hoursApproved: timeOff.hoursApproved,
    };
    updateTimeOffFn({ variables: { input } }).then(
      () => {
        showToast('Time off Approved.', 'success');
        onClose();
      },
      () => {
        showToast(errorMessages.generic, 'error');
        onClose();
      }
    );
  }, [onClose, timeOff.hoursApproved, timeOff.id, updateTimeOffFn]);

  const deny = useCallback(() => {
    const input = {
      id: timeOff.id,
      status: 'Denied',
      hoursApproved: timeOff.hoursApproved,
    };
    updateTimeOffFn({ variables: { input } }).then(
      () => {
        showToast('Time off Denied.', 'success');
        onClose();
      },
      () => {
        showToast(errorMessages.generic, 'error');
        onClose();
      }
    );
  }, [onClose, timeOff.hoursApproved, timeOff.id, updateTimeOffFn]);

  const cancel = useCallback(() => {
    const input = { id: cast<string>(timeOff.id) };
    cancelTimeOffFn({ variables: { input } }).then(
      () => {
        showToast('Time off canceled.', 'success');
        onClose();
      },
      () => {
        showToast(errorMessages.generic, 'error');
        onClose();
      }
    );
  }, [cancelTimeOffFn, onClose, timeOff.id]);

  const filterPtoTypes = useCallback((types?: IPayCodeType[]) => {
    if (!types) {
      return [];
    }
    return types.filter((type) => !type.trainingTimeEventType) ?? [];
  }, []);

  const getStatusColorClass = useCallback((status: string) => {
    let color = '';
    switch (status) {
      case 'Approved':
        color = colors.success;
        break;
      case 'Denied':
        color = colors.danger;
        break;
      case 'Pending':
        color = colors.orange;
        break;
      default:
        color = colors.gray;
    }
    return color;
  }, []);

  return (
    <Modal backdrop="static" show={isOpen} onHide={onClose} centered enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>Time Off Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col>
          <Row className="mb-4" align="start">
            <div className="d-flex align-items-center">
              <div>
                <Circle
                  className="mr-2"
                  size="xxs"
                  background={timeOff.person.classAssignments[0]?.colorCode || colors.gray}
                />
              </div>
              <PersonAvatar person={timeOff.person} className="mr-2" />
            </div>
            <div>
              <div>{getFullName(timeOff.person)}</div>
              <small>
                <span className={isTwoWeeksOld ? 'text-danger' : ''}>
                  <div>Request: {moment(timeOff.createdAt).tz(timezone).format('MM/DD/YY')}</div>
                  {isTwoWeeksOld && <div>(2 weeks or less in advance)</div>}
                </span>
              </small>
            </div>
            <div className="ml-10">
              <div>Status: </div>
              <div>
                <ColoredBackgroundTag color={getStatusColorClass(timeOff.status)} text={timeOff.status} />
              </div>
            </div>
          </Row>
          {isInEdit ? (
            <FormWrapper2
              formIsDirty={isInEdit}
              toggleDirty={setIsInEdit}
              toggleDirtyOnSave={false}
              onSave={save}
              loading={loading}
              onCancel={() => updateTimeOff(savedTimeOff)}
            >
              <TimeOffInputs
                timeOff={timeOff}
                updateTimeOff={updateTimeOff}
                timezone={timezone}
                // @ts-ignore
                ptoTypeOptions={filterPtoTypes(ptoTypesData?.searchPtoType.data)}
              />
            </FormWrapper2>
          ) : (
            <>
              <TimeOffReadOnlyDetails timeOff={timeOff} />
              {(hasEditPermission || (isMyTimeOff && timeOff.status === 'Pending')) && !AdpTimeEnabled && (
                <div className="d-flex flex-row ml-auto">
                  <a href="#" onClick={() => setIsInEdit(true)} className="text-decoration-secondary-underline">
                    Edit Request
                  </a>
                  <a href="#" onClick={cancel} className="ml-5 text-danger text-decoration-danger-underline">
                    Cancel Request
                  </a>
                </div>
              )}
            </>
          )}
        </Col>
      </Modal.Body>
      {!isInEdit && !AdpTimeEnabled && (
        <Modal.Footer className="p-4 border-top-1">
          <Row className="d-flex justify-content-between w-100">
            <Button onClick={deny} loading={loading} variant="danger" disabled={isDenyDisabled}>
              Deny
            </Button>
            <Button onClick={approve} loading={loading} className="mr-4" variant="success" disabled={isApproveDisabled}>
              Approve
            </Button>
          </Row>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ReviewTimeOffModal;
