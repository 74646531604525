import React from 'react';
import { useTranslation } from 'react-i18next';
import CenteredModal from 'shared/components/Modals/CenteredModal';

interface IConfirmBatchModalProps {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  paymentCount: number;
  paymentsTotal: number;
}
export function ConfirmBatchModal(props: IConfirmBatchModalProps) {
  const { t } = useTranslation(['billing']);
  const { show, onCancel, paymentCount, paymentsTotal, onConfirm } = props;
  return (
    <CenteredModal
      title="Create Batch"
      show={show}
      onHide={onCancel}
      secondaryCallback={onCancel}
      primaryChoice="Create Batch"
      primaryCallback={onConfirm}
    >
      <p>
        {t('billing:transactions.create-batch-modal.batch-information-text', {
          paymentCount: paymentCount,
          total: paymentsTotal.toFixed(2),
        })}
      </p>
      <p>{t('billing:transactions.create-batch-modal.batch-report-text')}</p>
    </CenteredModal>
  );
}
