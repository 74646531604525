import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split } from '@apollo/client/link/core';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';
import useRefreshToken from 'shared/hooks/useRefreshToken';

const cache = new InMemoryCache();

export const ApolloApiProvider = ({
  contextType,
  uri,
  websocketUri,
  token,
  children,
  /**
   * In order to use the graphql-codegen tool, we need wrap the Apollo Native ApolloProvider into this component
   * for core, please pass withApolloNativeProvider = true because we want to use the it as default
   * for k2Api, please leave the withApolloNativeProvider = false, because multiple ApolloProvider will causing problem.
   */
  withApolloNativeProvider = false,
}) => {
  const { refreshToken } = useRefreshToken();

  const client = React.useMemo(() => {
    const headers = {};

    // don't add an authorization header if no token is present, i.e. unauthenticated api calls during the join process
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const httpLink = createUploadLink({
      uri,
      headers,
    });

    const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        /**
         * some errors are returned as objects with a property 'message' that is a string
         * and some error are returned as objects with a property 'message' that is an object with a property 'message' that is a string
         */
        graphQLErrors.forEach((error) => {
          if (error?.extensions?.code === 'UNAUTHENTICATED' || error?.extensions?.code === 'AUTH_NOT_AUTHENTICATED') {
            refreshToken();
            return;
          }

          if (error.message && error.message.message) {
            Sentry.captureException(error.message.message, {
              tags: {
                kind: operation.operationName,
                path: error.path,
              },
              extra: {
                variables: operation.variables,
                path: error.path,
              },
            });
          } else {
            Sentry.captureException(error.message, {
              tags: {
                kind: operation.operationName,
                path: error.path,
              },
              extra: {
                variables: operation.variables,
                path: error.path,
              },
            });
          }
        });
      }

      if (networkError) {
        Sentry.captureException(networkError);
      }
    });

    const wsLink = new WebSocketLink({
      uri: websocketUri,
      options: {
        reconnect: !!token,
        timeout: 45000,
        connectionParams: {
          authToken: token,
        },
        reconnectionAttempts: 10,
      },
    });

    const link = split(
      // if this first param returns true, return the second param as link, else return the 3rd
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      errorLink.concat(httpLink)
    );

    return new ApolloClient({
      cache,
      link,
    });
  }, [uri, token, websocketUri]);

  if (withApolloNativeProvider) {
    return (
      <ApolloProvider client={client}>
        <contextType.Provider value={client}>{children}</contextType.Provider>
      </ApolloProvider>
    );
  }

  return <contextType.Provider value={client}>{children}</contextType.Provider>;
};
