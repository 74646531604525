import React from 'react';
import Container from 'react-bootstrap/Container';
import '../dashboard.scss';
const DashboardPageWrapper: React.FC = ({ ...props }) => {
  // styles for `app-page-content-body` are found in app.scss
  return (
    <div className="app-page-content-body">
      <div className="kt-dashboard-page-container">
        <Container fluid>{props.children}</Container>
      </div>
    </div>
  );
};

export default DashboardPageWrapper;
