import { useGetCustomFieldValues } from 'gql/customField/queries';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import useUpdateAvatar from 'shared/hooks/useUpdateAvatar';
import { getFullName } from 'shared/util/string';
import { RootState } from 'store/reducers';
import { useGetChildById } from '../graphql/queries';
import ChildCustomFieldsTab from './components/ChildCustomFieldsTab';
import ContactsTab from './components/ContactsTab';
import HealthWellnessTab from './components/HealthWellnessTab/HealthWellnessTab';
import ProfileTab from './components/Profile/ProfileTab';

type ChildProfileTab = 'profile' | 'contacts' | 'healthWellness' | 'immunizations' | 'customFields';

interface IRouteParams {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const Child: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const businessId = useSelector((state: RootState) => state.user?.entityId) ?? '';
  const [updateAvatarFn, { loading: avatarLoading }] = useUpdateAvatar('child');

  const hasEditChildPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const hasViewChildMedicalPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Documents,
    level: RoleLevelType.Read,
  });
  const hasViewContactsPermission = useHasRoleAreaLevel({
    area: AreaType.Contact,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const [activeTab, setActiveTab] = useState<ChildProfileTab>('profile');

  const childId: string = props.match.params.id ?? '';
  const child: IChild | undefined = useSelector((state: RootState) => state.children.byId)[childId];

  const { loading } = useGetChildById({
    variables: {
      id: childId,
    },
    skip: childId === '',
    fetchPolicy: 'no-cache',
  });

  var childCenterIds = child?.accounts?.map((x) => x.centerId) ?? [];
  const centerId = childCenterIds.length > 0 ? childCenterIds[0] : '';
  const timezone = useSelector((state: RootState) => state.timezone?.byCenterId[centerId]) ?? moment.tz.guess();
  const [showArchived, setShowArchived] = useState(false);

  const {
    data,
    loading: customFieldLoading,
    refetch,
  } = useGetCustomFieldValues({
    variables: {
      input: {
        businessId: businessId,
        centerIds: childCenterIds ?? [],
        entityId: child?.id ?? '',
        area: 'Child',
        isArchived: showArchived,
      },
    },
    skip: isEmpty(child) || businessId === '' || childCenterIds.length === 0,
  });

  const customFields = data?.getCustomFieldValues.customFields ?? [];

  return (
    <ProfilePageWrapper
      showCopyIconWithinSubtitle
      title={getFullName(child)}
      subtitle={`Child ID: ${childId}`}
      loading={loading}
      showAvatar={true}
      avatarUrl={child?.avatar?.url}
      onCopyClick={() => navigator.clipboard.writeText(childId)}
      onAvatarClick={(file: File) => {
        child && updateAvatarFn({ id: child.id, avatar: file });
      }}
      avatarLoading={avatarLoading}
    >
      <div className="mx-auto profile-page-width">
        <Tabs
          defaultActiveKey="profile"
          id="child-tabs"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key as ChildProfileTab)}
        >
          <Tab eventKey="profile" title="Profile" id="child-tabs-tab-profile">
            <ProfileTab child={child} loading={loading} readOnly={!hasEditChildPermission} />
          </Tab>
          {hasViewContactsPermission && (
            <Tab eventKey="contacts" title="Contacts" id="child-tabs-tab-contacts">
              <ContactsTab child={child} loading={loading} />
            </Tab>
          )}
          {hasViewChildMedicalPermission && (
            <Tab eventKey="healthWellness" title="Health & Wellness" id="child-tabs-tab-healthwellness">
              <HealthWellnessTab child={child} timezone={timezone} childId={childId} />
            </Tab>
          )}
          <Tab eventKey="customFields" title={t('custom-data-fields.tab-title')} id="child-tabs-tab-custom-field">
            <ChildCustomFieldsTab
              child={child}
              loading={loading || customFieldLoading}
              customFields={customFields}
              values={data?.getCustomFieldValues?.values ?? []}
              refetch={refetch}
              onShowArchivedChange={setShowArchived}
              showArchived={showArchived}
            />
          </Tab>
        </Tabs>
      </div>
    </ProfilePageWrapper>
  );
};

export default Child;
