import React, { useState } from 'react';
import Card from 'shared/components/Card';
import { Row, Col } from 'shared/components/Layout';
import { Form } from 'react-bootstrap';
import TextInput, { PasswordInput } from 'shared/components/TextInput';
import {
  useCheckCurrentCcrmCredentialsLazyQuery,
  useGetCcrmCredentialsQuery,
  useUpsertCcrmCredentialsMutation,
} from 'generated/graphql';
import Button from 'shared/components/Buttons';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { get } from 'lodash';

interface IProps {
  businessId: string;
}

interface IFormData {
  username?: string;
  password?: string;
  foreignTenantId?: string;
}

const CcrmCredentialsForm: React.FC<IProps> = ({ businessId }) => {
  const { t } = useTranslation(['integrations']);

  // Form state
  const [formData, setFormData] = useState<IFormData>({});
  const [isDirty, setIsDirty] = useState(false);
  const {
    loading: loadingExistingCreds,
    data: dataExistingCreds,
    refetch: refetchExistingCreds,
  } = useGetCcrmCredentialsQuery({
    variables: { input: { businessId } },
    onCompleted: (data) => {
      setFormData({ username: data?.getCcrmCredentials?.username ?? undefined });
      setIsDirty(false);
    },
    notifyOnNetworkStatusChange: true,
  });
  const existingCredsArePresent = !!dataExistingCreds?.getCcrmCredentials.username;
  const handleChange = (value: IFormData) => {
    setFormData({ ...formData, ...value });
    setIsDirty(true);
  };
  const getProps = <t extends keyof IFormData>(field: t) => ({
    value: formData[field],
    onChange: (value: IFormData[t]) => handleChange({ [field]: value }),
    autoComplete: 'off',
  });

  // Cancel
  const onCancel = () => {
    setIsDirty(false);
    setFormData({ username: dataExistingCreds?.getCcrmCredentials?.username ?? undefined });
  };

  // Submit
  const [mutateUpsertCcrmCredentials, { loading }] = useUpsertCcrmCredentialsMutation();
  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { username, password, foreignTenantId } = formData;
    mutateUpsertCcrmCredentials({
      variables: {
        input: {
          businessId: businessId,
          username: username ?? '',
          password: password ?? '',
          foreignTenantId: foreignTenantId ?? '',
        },
      },
    })
      .then((result) => {
        showToast(t('integrations:ccrm.update-credentials.success'), 'success');
        refetchExistingCreds();
      })
      .catch((err: ApolloError) => {
        const message = err.graphQLErrors[0].extensions.exception.response.message;
        showToast(message, 'error');
      });
  };

  // Check current
  const [checkCurrentCcrmCredentials] = useCheckCurrentCcrmCredentialsLazyQuery({
    variables: { input: { businessId } },
    onCompleted: () => showToast(t('integrations:ccrm.check-credentials.success'), 'success'),
    onError: (e) => showToast(get(e, 'graphQLErrors[0].extensions.exception.response.message') ?? e.message, 'error'),
  });

  return (
    <Card header={t('integrations:ccrm.card-header')} loading={loadingExistingCreds}>
      <Row>
        <Form onSubmit={handleOnSubmit}>
          <Row>
            <Col sm={12} xl={4}>
              <TextInput required label={'Username'} {...getProps('username')} />
            </Col>
            <Col sm={12} xl={4}>
              <PasswordInput
                required
                label={'Password'}
                {...getProps('password')}
                placeholder={existingCredsArePresent ? '**********' : ''}
              />
            </Col>
            <Col sm={12} xl={4}>
              <TextInput disabled label={'ForeignTenantId'} {...getProps('foreignTenantId')} />
            </Col>
          </Row>
          <Form.Row className="justify-content-end">
            {isDirty && (
              <>
                <Col xs="auto">
                  <Button type="button" variant="light" onClick={() => onCancel()}>
                    Cancel
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button type="submit" loading={loading}>
                    Submit
                  </Button>
                </Col>
              </>
            )}
            {!isDirty && existingCredsArePresent && (
              <Col xs="auto">
                <Button type="button" variant="secondary" onClick={() => checkCurrentCcrmCredentials()}>
                  {t('integrations:ccrm.check-credentials.test-credentials')}
                </Button>
              </Col>
            )}
          </Form.Row>
        </Form>
      </Row>
    </Card>
  );
};

export default CcrmCredentialsForm;
