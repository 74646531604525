import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, get } from 'lodash';
import moment from 'moment';
import CcsEnrolmentCard from 'shared/components/CcsEnrolmentCard/CcsEnrolmentCard';
import React from 'react';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import { ccsEnrollmentStatus, EnrolmentCategoryFormatting } from 'shared/constants/enums/ccssEnums';
import { capitalize, getInitials, stringToHsl } from 'shared/util/string';
import colors from '_colors.module.scss';
import { useGetContactById } from 'pages/Families/subroutes/Contacts/graphql/queries';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useTranslation } from 'react-i18next';
import { accountStatusColorHexes } from 'shared/constants/tagColors';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { ColumnType, DataTableColumnFactory } from 'shared/components/DataTableColumnType/DataTableColumnFactory';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';

interface ITableProps {
  data: ICcsEnrolment[];
  dataSize?: number;
  pageSize?: number;
  activePage?: number;
  showLoadingOverlay?: boolean;
  showPagination?: boolean;
  onSearch?: (searchText: string) => void;
  onPageChange?: (page: number, sizePerPage: number) => void;
  onSizePerPageChange?: (sizePerPage: number) => void;
  onSort?: (field: string, direction: ElasticsearchSortDirection) => void;
  headerClasses?: string;
  filteredStatus?: string[];
  onStatusFilter?: (status: string[]) => void;
}

const dateFormat = 'DD/MM/YYYY';

const CcsTable: React.FC<ITableProps> = ({
  data,
  dataSize,
  pageSize,
  showLoadingOverlay,
  onSizePerPageChange,
  activePage,
  onPageChange,
  onSort,
  showPagination,
  headerClasses,
  onSearch,
  filteredStatus,
  onStatusFilter,
}) => {
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const { t } = useTranslation();
  const statusOptions: ITableFilterOption[] = [
    {
      label: capitalize(t('spelling.active')),
      value: 'Active',
      indicatorColor: accountStatusColorHexes.Active,
    },
    {
      label: capitalize(t('spelling.inactive')),
      value: 'Inactive',
      indicatorColor: accountStatusColorHexes.Inactive,
    },
  ];

  return (
    <DataTable
      noPadding={true}
      data={data}
      dataSize={dataSize}
      pageSize={pageSize}
      activePage={activePage}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      showLoadingOverlay={showLoadingOverlay}
      showSelect={false}
      showPagination={showPagination}
      renderHeader={(paginationProps: any) => (
        <TableHeader className="flex-wrap">
          <SizePerPage paginationProps={paginationProps} />
          {onSearch && (
            <TableSearch
              onChange={(value) => {
                onSearch(value);
              }}
              placeholder="Search"
            />
          )}
          {onStatusFilter && (
            <>
              <label className="ml-4 flex-nowrap my-2">{capitalize(t('spelling.accountStatusFilter'))}</label>
              <DropdownFilter
                title={capitalize(t('spelling.status'))}
                className="ml-2 my-2"
                selectedFilters={filteredStatus}
                options={statusOptions}
                onFilterSelect={(values) => onStatusFilter(values.map((value) => value.value))}
              />
            </>
          )}
        </TableHeader>
      )}
      onSort={onSort}
      defaultSortField={'submitted'}
      expandRow={expandRowDefault}
      headerClasses={headerClasses}
      columns={[
        {
          text: fieldLabels.center,
          dataField: 'centerName',
          sort: true,
          formatter: (cell: string, row: ICcsEnrolment) => row.centerName ?? 'Unknown',
        },
        {
          text: 'Account',
          dataField: 'accountName',
          sort: true,
          formatter: (cell: string, row: ICcsEnrolment) =>
            DataTableColumnFactory.getColumn('accountName', ColumnType.ACCOUNT_CCS).render(row),
        },
        {
          text: 'Child',
          dataField: 'childName',
          sort: true,
          formatter: (cell: string, row: ICcsEnrolment) => (
            <AvatarDataTableCell
              color={stringToHsl(row.child.id)}
              initials={getInitials(row.child)}
              avatar={row.child.avatar?.url ?? ''}
              primaryText={row.child.fullName}
            />
          ),
        },
        {
          text: 'Submitted',
          dataField: 'submitted',
          sort: true,
          formatter: (cell: string, row: ICcsEnrolment) => moment(row.created).format(dateFormat),
        },
        {
          text: 'Enrolment ID',
          dataField: 'enrolmentId',
          sort: true,
          formatter: (cell: string, row: ICcsEnrolment) => row.enrolmentId,
        },
        {
          text: 'Status',
          dataField: 'status',
          sort: true,
          formatter: (cell: string, row: ICcsEnrolment) => (
            <span>
              <FontAwesomeIcon
                className="mr-2"
                icon={faCircle}
                color={get(EnrolmentCategoryFormatting, row.category, { color: colors.purple }).color}
                size="sm"
              />
              {`${find(ccsEnrollmentStatus, (status) => status.value === row.status)?.label ?? 'Unknown'}`}
            </span>
          ),
        },
      ]}
    />
  );
};

const expandRowDefault = (row: ICcsEnrolment) => <ExpandRowDefaultComponent row={row} />;

const ExpandRowDefaultComponent: React.FC<{ row: ICcsEnrolment }> = ({ row }) => {
  const { data } = useGetContactById(row.contactId ?? '', 'cache-first');

  return (
    <div>
      <CcsEnrolmentCard
        enrolment={row}
        child={row.child}
        contact={data?.getContactById}
        showEdit={false}
        renderPreviousEnrolments={() => (
          <CcsTable data={row.previousEnrolments ?? []} showPagination={false} headerClasses={'d-none'} />
        )}
      />
    </div>
  );
};

export default CcsTable;
