import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { payCodeFields } from './fields';

interface IPagedListPayCodes {
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  data: IPayCodeType[];
}

type SortDirection = 'ASCENDING' | 'DESCENDING';

interface ISearchPayCodesInput {
  input: {
    businessId: string;
    sortDirection: SortDirection;
    sortField: string;
    from: number;
    size: number;
    nameCodeContains?: string;
    centerId?: string | null;
    isArchived?: boolean;
    includeBusinessTypes: boolean;
  };
}

export const SEARCH_PAY_CODES = (fields: string = payCodeFields) => gql`
  query ($input: SearchPtoTypesInput!) {
    searchPtoType(input: $input) {
      totalRecords
      pageNumber
      pageSize
      data {
        ${fields}
      }
    }
  }
`;

export const useSearchPayCodes = (
  options?: QueryHookOptions<{ searchPtoType: IPagedListPayCodes }, ISearchPayCodesInput>,
  fields?: string
) =>
  useQuery<{ searchPtoType: IPagedListPayCodes }, ISearchPayCodesInput>(SEARCH_PAY_CODES(fields), {
    fetchPolicy: 'network-only',
    ...options,
  });
