import { capitalize, isEmpty, sortBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import useDatatableState from 'shared/hooks/useDatatableState';
import centerStatusFilterOptions from 'shared/constants/CenterStatusSearchExpressions';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { TagsTableCell } from 'shared/components/Tag';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import { useGetCenterItemizedBillingSettingsByBusiness } from 'gql/center/queries';
import { isBlank } from 'shared/util/string';
import { useUpdateItemizedBillTypeForCenters } from 'gql/center/mutations';
import cast from 'shared/util/cast';
import { omitTypename } from 'shared/util/object';
import { showToast } from 'shared/components/Toast';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IFilterDataShape {
  searchTerm: string | null;
  statuses: string[] | undefined;
  states: string[] | undefined;
  tags: string[] | undefined;
}

interface IProps {
  assignedBillView: string | undefined;
  loading: boolean | undefined;
  onCentersSelected: React.Dispatch<React.SetStateAction<any[]>>;
  onSaving: (value: boolean) => void;
}

const ItemizedBillsTab: React.FC<IProps> = ({ assignedBillView, loading, onCentersSelected, onSaving }) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? user?.entityId ?? '';

  useEffect(() => {
    if (assignedBillView) {
      onSaving(true);
      updateItemizedBillType(assignedBillView);
    }
  }, [assignedBillView]);

  // Table constants
  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'center.name', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('itemizedBills', initalTableSort);
  const getTableColumns = (): any[] => {
    let cols = [
      {
        text: `${capitalize(t('spelling.center'))}`,
        dataField: 'center.name',
        sort: true,
        formatter: (cell: string, row: ICenterBillingSettings) => (
          <AvatarDataTableCell
            initials={row.center.name && row.center.name[0].toUpperCase()}
            avatar={row.center.avatar && row.center.avatar.url}
            primaryText={row.center.name}
          />
        ),
      },
      {
        text: t('itemized-bills.type-table-column'),
        dataField: 'type',
        sort: true,
        formatter: (cell: string, row: ICenterBillingSettings) => {
          if (row.itemizedBillType.toLowerCase() == 'summary' && row.groupSubsidyAndSessionLineItems) {
            return <span>Summary - Grouped Subsidies</span>;
          } else {
            return <span>{capitalize(row.itemizedBillType.toLowerCase())}</span>;
          }
        },
      },
      {
        text: t('itemized-bills.state-table-column'),
        dataField: 'center.address.state',
        sort: true,
        formatter: (cell: string, row: ICenterBillingSettings) => {
          if (row.center.address?.state && row.center.address?.country) {
            return getState(row.center.address.state, row.center.address?.country ?? '');
          }
          return '';
        },
      },
      {
        text: t('itemized-bills.tags-table-column'),
        dataField: 'center.tags',
        sort: false,
        formatter: (cell: string, row: ICenterBillingSettings) =>
          row?.center?.tags && <TagsTableCell tags={row.center.tags} />,
      },
    ];

    return cols;
  };

  const [updateItemizedBillTypeFn] = useUpdateItemizedBillTypeForCenters();
  const updateItemizedBillType = useCallback(
    (assignedBillView: string) => {
      let type = assignedBillView.toUpperCase();
      let group = false;
      if (type == 'SUMMARY-GROUPED') {
        type = 'SUMMARY';
        group = true;
      }

      const input: IUpdateBillingSettingItemizedBillsInput = {
        centerIds: tableState.selectedRows.map((row: ICenterBillingSettings) => row.center.id),
        type: type,
        groupSubsidyAndSessionLineItems: group,
      };

      updateItemizedBillTypeFn({
        variables: {
          input: cast<IUpdateBillingSettingItemizedBillsInput>(omitTypename(input, true)),
        },
      })
        .then((data: any) => {
          showToast(t('itemized-bills.edit-confirmation-toast'), 'success');
          setTableData((prev) =>
            prev?.map((centerBillingSetting) => {
              let foundCenters = data.data.updateItemizedBillType.find(
                (s: ICenterBillingSettings) => s.center.id === centerBillingSetting.center.id
              );
              return {
                ...centerBillingSetting,
                id: centerBillingSetting.center.id,
                itemizedBillType: foundCenters ? foundCenters.itemizedBillType : centerBillingSetting.itemizedBillType,
                groupSubsidyAndSessionLineItems: foundCenters
                  ? foundCenters.groupSubsidyAndSessionLineItems
                  : centerBillingSetting.groupSubsidyAndSessionLineItems,
              };
            })
          );
          onSaving(false);
        })
        .catch((error: any) => {
          showToast(
            `${error.graphQLErrors
              .map((err: any) => {
                return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
              })
              .join(', ')}`,
            'error'
          );
          onSaving(false);
        });
    },
    [tableState, assignedBillView, updateItemizedBillTypeFn]
  );

  const statusOptions = user?.isInternal
    ? Object.values(centerStatusFilterOptions)
    : Object.values(centerStatusFilterOptions).filter((o) => o.value !== 'deleted');

  const getState = useCallback((stateCountryCode: string, country: string): string => {
    return STATE_SELECT_OPTIONS[country].find((option) => option.value === stateCountryCode)?.label ?? '';
  }, []);

  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];

  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name']).map((tag) => ({
    label: tag.name,
    value: tag.id,
  }));

  const [tableData, setTableData] = useState<ICenterBillingSettings[]>();
  const { loading: getCenterListLoading, data: getCenterListData } = useGetCenterItemizedBillingSettingsByBusiness({
    variables: {
      input: {
        businessId,
      },
    },
    onCompleted: (result) => {
      result.getCenterBillingSettingsByBusiness &&
        setTableData(
          result.getCenterBillingSettingsByBusiness?.map((x: ICenterBillingSettings) => ({ ...x, id: x.center.id })) ??
            []
        );
    },
  });

  const [filterData, setFilterData] = useState<IFilterDataShape>({
    searchTerm: '',
    statuses: [],
    states: [],
    tags: [],
  });

  const [filteredTableData, setFilteredTableData] = useState<ICenterBillingSettings[]>(
    tableData?.map((center) => center) ?? []
  );
  const filterTableData = (filterData: any) => {
    let filteredData =
      tableData?.filter((centerSetting) => {
        let searchTermPass: boolean = false;
        let statusPass: boolean = false;
        let statesPass: boolean = false;
        let tagsPass: boolean = false;

        if (!isBlank(filterData.searchTerm)) {
          searchTermPass = centerSetting.center.name.toUpperCase().includes(filterData.searchTerm.toUpperCase());
        } else {
          searchTermPass = true;
        }

        if (!isEmpty(filterData.statuses)) {
          if (
            filterData.statuses.some((status: ITableFilterOption) => status.label === 'Active') &&
            !centerSetting.center.deactivatedAt
          ) {
            statusPass = true;
          }

          if (
            filterData.statuses.some((status: ITableFilterOption) => status.label === 'Deactivated') &&
            centerSetting.center.deactivatedAt
          ) {
            statusPass = true;
          }

          if (
            filterData.statuses.some((status: ITableFilterOption) => status.label === 'Deleted') &&
            centerSetting.center.deletedAt
          ) {
            statusPass = true;
          }
        } else {
          statusPass = true;
        }

        if (!isEmpty(filterData.states)) {
          statesPass = filterData.states.some(
            (state: ITableFilterOption) => state.value === centerSetting.center.address.state
          );
        } else {
          statesPass = true;
        }

        if (!isEmpty(filterData.tags)) {
          tagsPass = filterData.tags.some((tag: ITableFilterOption) =>
            centerSetting.center.tags?.some((centerTag: ITag) => tag.value === centerTag.id)
          );
        } else {
          tagsPass = true;
        }

        return searchTermPass && statusPass && statesPass && tagsPass;
      }) ?? [];

    setFilteredTableData(
      filteredData.sort((a, b) => {
        let sortKey = tableState.sort[0].field;
        let sortDir = tableState.sort[0].direction;

        if (sortKey === 'center.name') {
          if (b.center.name < a.center.name) return sortDir === 'ASCENDING' ? 1 : -1;
          if (b.center.name > a.center.name) return sortDir === 'ASCENDING' ? -1 : 1;
          else return 0;
        }

        if (sortKey === 'type') {
          if (b.itemizedBillType < a.itemizedBillType) return sortDir === 'ASCENDING' ? 1 : -1;
          if (b.itemizedBillType > a.itemizedBillType) return sortDir === 'ASCENDING' ? -1 : 1;
          else return 0;
        }

        if (sortKey === 'center.address.state') {
          if (b.center.address.state < a.center.address.state) return sortDir === 'ASCENDING' ? 1 : -1;
          if (b.center.address.state > a.center.address.state) return sortDir === 'ASCENDING' ? -1 : 1;
          else return 0;
        }

        return 0;
      })
    );
  };

  useEffect(() => {
    filterTableData(filterData);
  }, [filterData]);

  useEffect(() => {
    filterTableData(filterData);
  }, [tableData]);

  useEffect(() => {
    filterTableData(filterData);
  }, [tableState.sort]);

  useEffect(() => {
    onCentersSelected(tableState?.selectedRows);
  }, [tableState.selectedRows]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <HasRoleAreaLevel
        action={{ area: AreaType.Billing, permission: PermissionType.Payments, level: RoleLevelType.Read }}
      >
        {getCenterListLoading || loading ? (
          <DataTableLoadingSkeleton />
        ) : (
          <DataTable
            noPadding={true}
            data={filteredTableData ?? []}
            dataSize={filteredTableData?.length ?? 0}
            pageSize={tableState.pageSize}
            showLoadingOverlay={getCenterListLoading}
            columns={getTableColumns()}
            renderHeader={(paginationProps: any) => (
              <TableHeader className="flex-wrap align-items-center">
                <div
                  className={
                    isMobile
                      ? 'd-flex flex-wrap my-1 align-items-center mr-auto'
                      : 'd-flex flex-wrap mr-auto align-items-center'
                  }
                >
                  <TableSearch
                    placeholder={t('spelling.searchCenters')}
                    onChange={(term) => setFilterData((prev: any) => ({ ...prev, searchTerm: term }))}
                  />
                </div>
                <div className="d-flex flex-wrap align-items-center">
                  <DropdownFilter
                    className={isMobile ? 'my-1 ml-2' : 'my-0 mr-2 ml-auto p-0'}
                    title={t('itemized-bills.status-filter')}
                    selectedFilters={filterData?.statuses ?? []}
                    options={statusOptions}
                    onFilterSelect={(val) => setFilterData((prev: any) => ({ ...prev, statuses: val }))}
                  />
                  <DropdownFilter
                    className={isMobile ? 'my-1 pl-0 ml-2' : 'my-0 mx-2 p-0'}
                    title={t('itemized-bills.states-filter')}
                    selectedFilters={filterData?.states ?? []}
                    options={STATE_SELECT_OPTIONS['US'].concat(STATE_SELECT_OPTIONS['CA'])}
                    onFilterSelect={(val) => setFilterData((prev: any) => ({ ...prev, states: val }))}
                  />
                  <DropdownFilter
                    title={t('itemized-bills.tags-filter')}
                    selectedFilters={filterData?.tags ?? []}
                    className={isMobile ? 'my-1 ml-2' : 'my-0 ml-2 p-0'}
                    options={tagOptions}
                    onFilterSelect={(val) => setFilterData((prev: any) => ({ ...prev, tags: val }))}
                  />
                </div>
              </TableHeader>
            )}
            onPageChange={tableFunctions.changePage}
            onSizePerPageChange={tableFunctions.changeSizePerPage}
            activePage={tableState.activePage}
            selectedRows={tableState.selectedRows}
            updateSelectedRows={tableFunctions.updateSelectedRows}
            onSort={tableFunctions.updateSort}
          />
        )}
      </HasRoleAreaLevel>
    </>
  );
};

export default ItemizedBillsTab;
