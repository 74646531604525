import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
interface IProps {
  formData: IDelayedExitForm;
  handleChange: (value: any, name: string) => void;
}

const EditDelayedExitForm: React.FC<IProps> = ({ formData, handleChange }) => {
  const { t } = useTranslation(['subsidies']);
  const handleFileChange = (files: File[]) => {
    handleChange(files[files.length - 1], 'file');
  };

  const hasAnyFormValues = !isEmpty(formData.notes?.trim()) || formData.file != undefined;

  return (
    <div>
      <Row>
        <Col>
          <TextInput
            required={hasAnyFormValues}
            as="textarea"
            value={formData.notes}
            label={t('subsidies:program-child.create-modal.delayed-exit-info')}
            onChange={handleChange}
            name="notes"
          />
        </Col>
      </Row>
      {formData.linkToFile && (
        <Row className="mb-2">
          <Col>
            <a href={formData.linkToFile}>Current Document: {formData.fileName}</a>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <div className="d-flex flex-row">
            <label>{t('subsidies:program-child.create-modal.delayed-exit-info-document')}</label>
            {hasAnyFormValues && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FileSelectDropbox
            multiple={false}
            className="mb-2"
            value={formData.file ? [formData.file] : []}
            onChange={handleFileChange}
            showFiles
          />
        </Col>
      </Row>
    </div>
  );
};

export default EditDelayedExitForm;
