import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';

interface IChangeEmailViaMagicLinkVariables {
  token: string;
  action: 'ACCEPT' | 'DENY';
}

interface IChangeEmailViaMagicLinkData {
  changeEmailViaMagicLink: IStaff;
}

interface IVerifyEmailViaMagicLinkVariables {
  token: string;
}

interface IVerifyEmailViaMagicLinkData {
  changeEmailViaMagicLink: IStaff;
}

const CHANGE_EMAIL_VIA_MAGIC_LINK = gql`
  mutation ($token: String!, $action: String!) {
    changeEmailViaMagicLink(token: $token, action: $action) {
      id
    }
  }
`;

const VERIFY_EMAIL_VIA_MAGIC_LINK = gql`
  mutation ($token: String!) {
    verifyEmailViaMagicLink(token: $token) {
      id
    }
  }
`;

export const useChangeEmailViaMagicLink = (
  options?: MutationHookOptions<IChangeEmailViaMagicLinkData, IChangeEmailViaMagicLinkVariables> | undefined
) => useMutation<IChangeEmailViaMagicLinkData, IChangeEmailViaMagicLinkVariables>(CHANGE_EMAIL_VIA_MAGIC_LINK, options);

export const useVerifyEmailViaMagicLink = (
  options?: MutationHookOptions<IVerifyEmailViaMagicLinkData, IVerifyEmailViaMagicLinkVariables> | undefined
) => useMutation<IVerifyEmailViaMagicLinkData, IVerifyEmailViaMagicLinkVariables>(VERIFY_EMAIL_VIA_MAGIC_LINK, options);
