import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Container from 'react-bootstrap/Container';
import PageWrapper from 'shared/components/PageWrapper';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { RootState } from 'store/reducers';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import colors from '_colors.module.scss';
import CalendarToolbar from './CalendarToolbar';
import ControlContainer from '../StaffSchedules/components/ControlContainer';
import { useGetShiftsForPersonForDates } from './graphql/queries';
import ShiftDetailsModal from '../StaffSchedules/components/ShiftDetailsModal';
import CalendarEvent from './CalendarEvent';

interface IMyScheduleState {
  startOfWeek: moment.Moment;
  endOfWeek: moment.Moment;
}

interface IProps {}

const MySchedule: React.FC<IProps> = ({ ...props }) => {
  const userId = useSelector((state: RootState) => state.user?.id ?? '');
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);

  const [scheduleState, setScheduleState] = useState<IMyScheduleState>({
    startOfWeek: moment().startOf('week'),
    endOfWeek: moment().endOf('week'),
  });
  const [selectedShift, setSelectedShift] = useState<IShift | null>(null);

  const visibleDates = useMemo((): moment.Moment[] => {
    const dates: moment.Moment[] = [];
    const startOfWeek = scheduleState.startOfWeek.clone();
    const endOfWeek = scheduleState.endOfWeek.clone();
    const current = startOfWeek.clone();

    while (current.isSameOrBefore(endOfWeek)) {
      // we currently don't care about sunday and saturday
      if (current.day() > 0 && current.day() < 6) {
        dates.push(current.clone());
      }

      current.add(1, 'day');
    }

    return dates;
  }, [scheduleState.startOfWeek, scheduleState.endOfWeek]);

  const formattedDatesArr = useCallback(
    (dates: moment.Moment[]): IDateTimeRange[] =>
      dates.map((d) => {
        return {
          startTime: d.clone().startOf('day').toISOString(),
          endTime: d.clone().endOf('day').toISOString(),
        };
      }),
    []
  );

  const { loading, data } = useGetShiftsForPersonForDates(userId, formattedDatesArr(visibleDates));

  return (
    <PageWrapper pageTitle="My Schedule" buttonComponent={null}>
      <Container fluid>
        <SpinnerOverlay show={loading}>
          <ControlContainer className="kt-my-schedule-calendar">
            <Calendar
              date={scheduleState.startOfWeek.toDate()}
              localizer={momentLocalizer(moment)}
              titleAccessor={(shift: IShift) =>
                shift.classId ? shift.class?.name ?? '' : shift.locationId ? shift.location?.name ?? '' : ''
              }
              startAccessor={(shift: IShift) => {
                const mz = momentTz(shift.startTime).tz(timezonesByCenter[shift.centerId] ?? momentTz.tz.guess());
                /**
                 * manually create the startAccessor per this solution: https://github.com/jquense/react-big-calendar/issues/118#issuecomment-281882227
                 * basically if we call moment's toDate function, it will convert the moment to the browser's timezone instead of respecting the set timezone
                 */
                return new Date(mz.year(), mz.month(), mz.date(), mz.hour(), mz.minute(), 0);
              }}
              endAccessor={(shift: IShift) => {
                const mz = momentTz(shift.endTime).tz(timezonesByCenter[shift.centerId] ?? momentTz.tz.guess());

                return new Date(mz.year(), mz.month(), mz.date(), mz.hour(), mz.minute(), 0);
              }}
              defaultView="work_week"
              views={['work_week']}
              events={data?.getShiftsForPersonForDates.filter((s) => s.published) ?? []}
              eventPropGetter={(event: IShift) => ({
                style: {
                  backgroundColor: event.class ? event.class.colorCode : colors.gray,
                  borderColor: '#FFFFFF',
                },
              })}
              formats={{
                dayFormat: (date, culture, localizer) => localizer?.format(date, 'dddd, D', 'en') ?? 'dddd',
              }}
              components={{
                toolbar: (toolbar) => (
                  <CalendarToolbar
                    toolbar={toolbar}
                    startDate={scheduleState.startOfWeek}
                    endDate={scheduleState.endOfWeek}
                    onDateChange={(dates) =>
                      setScheduleState((prev) => ({
                        ...prev,
                        startOfWeek: dates.startDate,
                        endOfWeek: dates.endDate,
                      }))
                    }
                  />
                ),
                event: (props) => (
                  <CalendarEvent
                    {...props}
                    timeFormatter={(time, centerId) =>
                      moment(time)
                        .tz(timezonesByCenter[centerId] ?? momentTz.tz.guess())
                        .format('h:mm A')
                    }
                  />
                ),
              }}
              onSelectEvent={(event: IShift) => setSelectedShift(event)}
            />
          </ControlContainer>
        </SpinnerOverlay>
      </Container>
      {selectedShift !== null && (
        <ShiftDetailsModal
          isOpen={selectedShift !== null}
          shift={selectedShift}
          isLoading={false}
          disableActionButtons
          showActionButtons={false}
          onClose={() => setSelectedShift(null)}
          onCopyShift={() => {}}
          onEditShift={() => {}}
          onDeleteShift={() => {}}
        />
      )}
    </PageWrapper>
  );
};

export default MySchedule;
