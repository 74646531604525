export const GET_CENTER_CLASSES_SUCCESS = 'GET_CENTER_CLASSES_SUCCESS';
export const ADD_CLASS = 'ADD_CLASS';
export const UPDATE_CLASS = 'UPDATE_CLASS';
export const ARCHIVE_CLASS = 'ARCHIVE_CLASS';
export const ADD_CLASS_FEE = 'ADD_CLASS_FEE';

interface IGetCenterClassesSuccessAction {
  type: typeof GET_CENTER_CLASSES_SUCCESS;
  classes: IClass[];
}

interface IUpdateClassAction {
  type: typeof UPDATE_CLASS;
  class: IClass;
}

interface IAddClassAction {
  type: typeof ADD_CLASS;
  class: IClass;
}

interface IArchiveClassAction {
  type: typeof ARCHIVE_CLASS;
  id: string;
}

interface IAddClassFeeAction {
  type: typeof ADD_CLASS_FEE;
  classId: string;
  fee: IFee;
}

export type ClassesActionTypes =
  | IGetCenterClassesSuccessAction
  | IUpdateClassAction
  | IAddClassAction
  | IArchiveClassAction
  | IAddClassFeeAction;
