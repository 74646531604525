import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  accountId: string;
  accountName: string;
  openInNewTab?: boolean;
  accountTab?: string;
}

const AccountLink: React.FC<IProps> = ({ accountId, accountName, openInNewTab, accountTab, ...props }) => {
  return (
    <Link
      to={`/families/accounts/${accountId}/${accountTab ?? 'profile'}`}
      target={openInNewTab ? '_blank' : undefined}
    >
      {accountName}
    </Link>
  );
};

export default AccountLink;
