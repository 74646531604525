import React, { useState, useCallback } from 'react';
import { orderBy } from 'lodash';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import cast from 'shared/util/cast';
import { showToast } from 'shared/components/Toast';
import { useReactivateStaff } from '../../../subroutes/Profile/Tabs/Profile/graphql/mutations';
import errorMessage from 'shared/constants/errorMessages';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useGetRolesForBusinessThatUserCanManage } from 'shared/hooks/useGetRolesForBusiness';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  staff: IStaff;
}

const ReactivateStaffModal: React.FC<IProps> = ({ isOpen, onClose, staff }) => {
  const [startDate, updateStartDate] = useState<string | null>(null);
  const [roleId, updateRoleId] = useState<string | null>(null);
  const [primaryCenterId, updatePrimaryCenterId] = useState(null);
  const user = useSelector((state: RootState) => state.user);
  const { data: roleOptions } = useGetRolesForBusinessThatUserCanManage(staff.entityId, user);
  const [reactivateStaffMutation, { loading }] = useReactivateStaff();
  const centerOptions = useGetActiveCenters().filter((center) => center.entityId === staff.entityId);
  const scopeType = roleOptions?.find((r) => r.id === roleId)?.scopeType ?? null;

  const handleClose = useCallback(() => {
    updateStartDate(null);
    updateRoleId(null);
    updatePrimaryCenterId(null);
    onClose();
  }, [onClose]);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const reactivate = useCallback(async () => {
    await reactivateStaffMutation({
      variables: {
        input: {
          id: staff.id,
          employmentStartDate: startDate,
          roleId: cast<string>(roleId),
          scopeIds: scopeType === 'ENTITY' ? [staff.entityId] : [cast<string>(primaryCenterId)],
          primaryCenterId,
          scopeType: scopeType || 'CENTER',
        },
      },
    }).then(
      () => {
        showToast('Staff reactivated.', 'success');
        handleClose();
      },
      () => {
        showToast(errorMessage.generic, 'error');
        handleClose();
      }
    );
  }, [reactivateStaffMutation, staff.id, staff.entityId, startDate, roleId, scopeType, primaryCenterId, handleClose]);

  const formDisabled = !roleId || (scopeType === 'CENTER' && !primaryCenterId);

  return (
    <ConfirmationModal
      title="Reactivate User?"
      show={isOpen}
      onHide={handleClose}
      hideOnCallback={false}
      primaryChoice="Confirm"
      primaryCallback={reactivate}
      primaryButtonProps={{ variant: 'success', loading, disabled: formDisabled }}
    >
      <p>
        Reactivating this user will activate their role and permissions on the date and in the role, selected below. If
        a start date is unknown, this user will remain as ‘Created’ until a date is selected.
      </p>
      <Select
        required
        label="Role"
        options={orderBy(roleOptions, (role) => role.name, 'asc')}
        value={roleId}
        onChange={(option: IRole) => updateRoleId(option.id)}
        getOptionLabel={(option: IRole) => option.name}
        getOptionValue={(option: IRole) => option.id}
      />
      <Select
        required
        options={centerOptions || []}
        label={`Primary ${fieldLabels.center} Assignment`}
        value={scopeType === 'ENTITY' ? { name: 'All' } : primaryCenterId}
        onChange={(option) => updatePrimaryCenterId(option.id)}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        disabled={scopeType === 'ENTITY'}
        isInvalid={!centerOptions?.length}
        errorText={errorMessage.noCenters}
      />
      <DateInput label="Start Date" date={startDate} onDateSelect={updateStartDate} />
    </ConfirmationModal>
  );
};

export default ReactivateStaffModal;
