import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { useUpdateCenterMerchantId } from 'gql/center/mutations';
import { useDispatch } from 'react-redux';
import Card from 'shared/components/Card';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import TextInput from 'shared/components/TextInput';
import { updateCenter } from 'pages/Centers/duck/actions';
import { showToast } from 'shared/components/Toast';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import Checkbox from 'shared/components/Checkbox';

interface IFormDataShape {
  merchantId: string;
  isSandbox: boolean;
}

interface IProps {
  centerId: string;
  merchant: IMerchant | null;
  hasMerchantId: boolean;
}

/**
 * This assumes that all centers in the US region use Payrix as a payment processor
 * and all centers in the AU region use Fat Zebra as a processor
 */
const PaymentProcessorCard: React.FC<IProps> = ({ centerId, merchant, hasMerchantId, ...props }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IFormDataShape>({
    merchantId: '',
    isSandbox: false,
  });
  const [formDirty, setFormDirty] = useState(false);
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const [updateCenterMerchantIdFn, { loading }] = useUpdateCenterMerchantId({
    onCompleted: (result) => {
      if (result.updateCenterMerchantId) {
        const center: Partial<ICenter> = {
          id: centerId,
          merchant: result.updateCenterMerchantId,
        };

        dispatch(updateCenter(center as ICenter));
        showToast(`Successfully updated the ${fieldLabels.center}'s merchant id.`, 'success');
        resetForm();
      }
    },
    onError: (err) => {
      showToast(
        `There was an error trying to update the ${fieldLabels.center}'s merchant id. Please try again later.`,
        'error'
      );
    },
  });

  const handleChange = useCallback((value: string, key: keyof IFormDataShape) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
    setFormDirty(true);
  }, []);

  const handleSubmit = useCallback(() => {
    updateCenterMerchantIdFn({
      variables: {
        input: {
          id: centerId,
          merchantId: formData.merchantId as string,
          isSandbox: formData.isSandbox,
        },
      },
    });
  }, [formData, centerId, updateCenterMerchantIdFn]);

  const resetForm = useCallback(() => {
    setFormData({ merchantId: '', isSandbox: false });
    setFormDirty(false);
  }, []);

  const formDisabled = useCallback((form: IFormDataShape): boolean => {
    return !form.merchantId.length;
  }, []);

  return (
    <Card header="Payment Processor" loading={false}>
      {hasMerchantId ? (
        <Form.Group>
          <Form.Label>Merchant Id</Form.Label>
          <Form.Control plaintext readOnly defaultValue={merchant?.merchantId as string} />
          {merchant?.isSandbox && <Form.Text>This is a sandbox merchant account</Form.Text>}
        </Form.Group>
      ) : (
        <FormWrapper2
          formIsDirty={formDirty}
          toggleDirty={setFormDirty}
          onSave={handleSubmit}
          onCancel={resetForm}
          loading={loading}
          saveDisabled={hasMerchantId || formDisabled(formData) || loading}
        >
          <TextInput
            label="Merchant Id"
            value={formData.merchantId}
            onChange={(value) => handleChange(value, 'merchantId')}
          />
          <Checkbox
            label="This is a sandbox merchant account"
            value={formData.isSandbox}
            onChange={(checked) => setFormData((prev) => ({ ...prev, isSandbox: checked }))}
          />
        </FormWrapper2>
      )}
    </Card>
  );
};

export default PaymentProcessorCard;
