import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';

const UPDATE_MY_PIN = gql`
  mutation ($pin: String!) {
    updatePinForMe(pin: $pin) {
      id
    }
  }
`;

export const useUpdateMyPin = () => useMutation<IUpdatePinFormMeData, IUpdatePinFoMeVariables>(UPDATE_MY_PIN);
