import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { useDispatch } from 'react-redux';
import { updateChildSuccess } from '../duck/actions';
import { isRegion } from 'shared/util/region';

const ccssAttributeFields = `
  ccssAttributes {
    crn
    isCrnEditable
  }
`;

const UPDATE_CHILD_PERSONAL_INFO = gql`
  mutation ($input: UpdateChildPersonalInfoInput!) {
    updateChildPersonalInfo(input: $input) {
      id
      firstname
      lastname
      nickname
      dob
      indigenousStatus
      gender
      residesWith {
        id
      }
      grade
      ${isRegion('AU') ? ccssAttributeFields : ''}
    }
  }
`;

const UPDATE_CHILD_TAGS = gql`
  mutation ($input: UpdateChildTagsInput!) {
    updateChildTags(input: $input) {
      id
      tags {
        id
        entityId
        name
        category
      }
    }
  }
`;

const UPDATE_CHILD_EMERGENCY_CONTACTS = gql`
  mutation ($input: UpdateChildEmergencyContactsInput!) {
    updateChildEmergencyContacts(input: $input) {
      id
      childId
      contactId
      firstName
      lastName
      phoneNumber {
        number
        type
      }
      relationshipType
    }
  }
`;

export const useUpdateChildPersonalInfo = () => {
  const dispatch = useDispatch();

  return useMutation<{ updateChildPersonalInfo: IChild }, { input: IUpdateChildPersonalInfoInput }>(
    UPDATE_CHILD_PERSONAL_INFO,
    {
      onCompleted: (data) => {
        data.updateChildPersonalInfo && dispatch(updateChildSuccess(data.updateChildPersonalInfo));
      },
    }
  );
};

export const useUpdateChildTags = () => {
  const dispatch = useDispatch();

  return useMutation<{ updateChildTags: IChild }, { input: IUpdateChildTagsInput }>(UPDATE_CHILD_TAGS, {
    onCompleted: (data) => {
      data.updateChildTags && dispatch(updateChildSuccess(data.updateChildTags));
    },
  });
};

export const useUpdateChildEmergencyContacts = (child: IChild) => {
  const dispatch = useDispatch();

  return useMutation<
    { updateChildEmergencyContacts: IChildEmergencyContact[] },
    { input: IUpdateChildEmergencyContactsInput }
  >(UPDATE_CHILD_EMERGENCY_CONTACTS, {
    onCompleted: (data) => {
      data.updateChildEmergencyContacts &&
        dispatch(updateChildSuccess({ ...child, emergencyContacts: data.updateChildEmergencyContacts }));
    },
  });
};

const UPDATE_CHILD_MEAL_STATUS = gql`
  mutation ($input: UpdateChildMealStatusInput!) {
    updateChildMealStatus(input: $input) {
      mealPricing
    }
  }
`;

export const useUpdateChildMealStatus = (child: IChild) => {
  const dispatch = useDispatch();

  return useMutation<{ updateChildMealStatus: IChildMealStatus }, { input: IUpdateChildMealStatusInput }>(
    UPDATE_CHILD_MEAL_STATUS,
    {
      onCompleted: (data) => {
        data.updateChildMealStatus &&
          dispatch(updateChildSuccess({ ...child, mealStatus: data.updateChildMealStatus }));
      },
    }
  );
};
