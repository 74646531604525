import React from 'react';
import Card from 'shared/components/Card';
import BSCard from 'react-bootstrap/Card';
import { Pie, ComputedDatum } from '@nivo/pie';
import { SubsidySummaryColors, SubsidySummaryStatusLabels } from 'shared/constants/enums/subsidyTrackingEnums';
import ChartLabel from './ChartLabel';
import colors from '_colors.module.scss';
import { useGetSubsidyDashboard } from 'gql/subsidyTracking/queries';
import { currencyFormat } from 'shared/util/currency';

interface SubsidyTracking {
  id: string;
  value: number;
  color: string;
  label: string;
}

interface CenterMetricProps {
  dataWithArc: ComputedDatum<SubsidyTracking>[];
  centerX: number;
  centerY: number;
}

const SubsidyTrackingWidget: React.FC<{ businessId: string; centerIds: string[] }> = ({ businessId, centerIds }) => {
  const { loading, data: dashboardData } = useGetSubsidyDashboard({
    variables: {
      input: {
        businessId,
        centerIds,
      },
    },
  });

  const values = dashboardData?.getSubsidyDashboard;
  const data: SubsidyTracking[] = [
    {
      id: SubsidySummaryStatusLabels.PENDING,
      label: SubsidySummaryStatusLabels.PENDING,
      value: values?.PENDING || 0,
      color: SubsidySummaryColors.PENDING.color,
    },
    {
      id: SubsidySummaryStatusLabels.UNSUBMITTED,
      label: SubsidySummaryStatusLabels.UNSUBMITTED,
      value: values?.UNSUBMITTED || 0,
      color: SubsidySummaryColors.UNSUBMITTED.color,
    },
    {
      id: SubsidySummaryStatusLabels.ERRORED,
      label: SubsidySummaryStatusLabels.ERRORED,
      value: values?.ERRORED || 0,
      color: SubsidySummaryColors.ERRORED.color,
    },
  ];

  const commonProperties = {
    valueFormat: (value: number) => currencyFormat(value),
    width: 250,
    height: 250,
    margin: { top: 20, right: 20, bottom: 20, left: 20 },
    data,
    animate: true,
  };

  const CenteredMetric = ({ centerX, centerY, dataWithArc }: CenterMetricProps) => {
    const total = dataWithArc.reduce((acc, curr) => acc + curr.value, 0);
    return (
      <>
        <text
          x={centerX}
          y={centerY - 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '20px',
          }}
        >
          {currencyFormat(total)}
        </text>
        <text x={centerX} y={centerY + 10} textAnchor="middle" fill={colors.gray} dominantBaseline="central">
          Pending
        </text>
        <text x={centerX} y={centerY + 30} textAnchor="middle" dominantBaseline="central" fill={colors.gray}>
          CCS Payment
        </text>
      </>
    );
  };

  return (
    <Card className="col-md-8 col-sm-8 col-lg-6" loading={loading}>
      <BSCard.Title>Subsidy Tracking Overview</BSCard.Title>
      <div className="d-flex align-items-center flex-wrap justify-content-between">
        <Pie
          colors={{ datum: 'data.color' }}
          {...commonProperties}
          innerRadius={0.7}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
        />
        <div style={{ width: '40%' }}>
          {data.map(({ color, label, value }) => (
            <ChartLabel key={label} color={color} label={label} amount={value} />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default SubsidyTrackingWidget;
