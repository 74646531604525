import React, { useEffect, useState } from 'react';
import useDatatableState from 'shared/hooks/useDatatableState2';
import FeesTable from 'shared/components/Fees/FeesTable';
import { useSearchFees } from 'gql/fee/queries';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import { CreateButton } from 'shared/components/Buttons';
import { RootState } from 'store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import EditFeeModal from 'pages/Centers/subroutes/Fees/components/EditFeeModal';
import * as actions from 'pages/Centers/subroutes/Fees/duck/actions';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { gql, useQuery } from '@apollo/client';

interface IProps {
  classId: string;
  centerId: string;
  openNewFeeModal: () => void;
  handleReactivateFee: (fee: IFee) => void;
  handleDeactivateFee: (fee: IFee) => void;
  isReadOnly: boolean;
}

const searchFeesQuery = gql`
  query ($input: SearchFees2Input) {
    searchFees2(input: $input) {
      data {
        id
        name
        centerId
        classIds
        schedules {
          id
          startDate
          startTime
          endTime
          overtimeCost
          overtimeGracePeriod
          overtimeInterval
          rates {
            value
            qualifyingNumber
          }
          archivedAt
        }
        feeType
        feeStructure
        utilization
      }
      totalRecords
    }
  }
`;

interface searchFees2Input {
  input: {
    centerId: string | null;
    name?: string;
    classIds?: string[];
    showActive: boolean;
    showDeactivated: boolean;
    pageSize?: number;
    pageNumber?: number;
  };
}

function pullSearchTermFromElasticSearchFilter(searchExpressions: ISearchExpression[]): string | undefined {
  for (let expression of searchExpressions) {
    if (expression.any && expression.any.length > 0) {
      for (let anyExpression of expression.any) {
        if (anyExpression.term?.field === 'name') {
          return anyExpression.term.value;
        }
      }
    }
  }
  return;
}

const FeesTab: React.FC<IProps> = ({
  classId,
  centerId,
  openNewFeeModal,
  handleReactivateFee,
  handleDeactivateFee,
  isReadOnly,
  ...props
}) => {
  const { k2FeesFromDb } = useFlags();
  const dispatch = useDispatch();
  const {
    searchResults: feesData,
    searchSort,
    searchFilter,
    totalFees,
  } = useSelector((state: RootState) => state.fees);
  const [feeInEdit, setFeeInEdit] = useState<IFee | null>(null);
  const [tableState, tableFunctions] = useDatatableState();
  const { loading: searchFeesLoadingOld } = useSearchFees({
    variables: {
      input: {
        filter: {
          [SEARCH_EXPRESSIONS.ALL]: [
            ...tableState.searchExpressions,
            { [SEARCH_EXPRESSIONS.ANY]: searchFilter.status?.map((filter) => filter.searchExpression) || [] },
            {
              [SEARCH_EXPRESSIONS.TERM]: { field: 'classIds.keyword', predicate: PREDICATES.CONTAINS, value: classId },
            },
          ],
        },
        sort: [searchSort],
        size: tableState.pageSize,
        from: tableState.currentItemOffset,
      },
    },
    skip: !classId || !centerId || k2FeesFromDb,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (k2FeesFromDb) return;
      dispatch(actions.searchFees(data.searchFees?.data ?? [], data.searchFees?.totalResults));
    },
  });

  const { loading: searchFeesLoading } = useQuery<any, searchFees2Input>(searchFeesQuery, {
    variables: {
      input: {
        centerId,
        name: pullSearchTermFromElasticSearchFilter(tableState.searchExpressions),
        classIds: [classId],
        showActive: !!searchFilter.status?.find((statusExpression) => statusExpression.value === 'active'),
        showDeactivated: !!searchFilter.status?.find((statusExression) => statusExression.value === 'deactivated'),
        pageSize: tableState.pageSize,
        pageNumber: tableState.activePage,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !centerId || !k2FeesFromDb,
    onCompleted: (data) => {
      if (!k2FeesFromDb) return;
      dispatch(actions.searchFees(data?.searchFees2?.data ?? [], data?.searchFees2?.totalRecords));
    },
  });

  // when a fee is deactivated or reactivated from within edit modal, we need to update the passed in fee to reflect changes
  useEffect(() => {
    if (feeInEdit) {
      const latestSavedFee = feesData.find((f) => f.id === feeInEdit.id);
      latestSavedFee && setFeeInEdit(latestSavedFee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feesData]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <HasRoleAreaLevel
          action={{ area: AreaType.Center, permission: PermissionType.Fees, level: RoleLevelType.Create }}
        >
          <CreateButton disabled={isReadOnly} onClick={openNewFeeModal} className="btn-secondary mb-4">
            Add Fee
          </CreateButton>
        </HasRoleAreaLevel>
      </div>
      <FeesTable
        displayingSingleClass
        data={feesData ?? []}
        totalFees={totalFees}
        loading={k2FeesFromDb ? searchFeesLoading : searchFeesLoadingOld}
        tableState={tableState}
        tableFunctions={tableFunctions}
        handleRowClick={(e, fee) => setFeeInEdit(fee)}
        handleDeactivateFee={handleDeactivateFee}
        handleReactivateFee={handleReactivateFee}
        noPadding={true}
      />
      {feeInEdit && (
        <EditFeeModal
          fee={feeInEdit}
          isOpen={Boolean(feeInEdit)}
          onClose={() => {
            setFeeInEdit(null);
          }}
          handleDeactivateFee={handleDeactivateFee}
          handleReactivateFee={handleReactivateFee}
        />
      )}
    </>
  );
};

export default FeesTab;
