import React from 'react';
import _ from 'lodash';

import * as Mui from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useHistory } from 'react-router';
import AddRoyaltyException from './RoyaltyExceptions/AddRoyaltyException';
import { showToast } from 'shared/components/Toast';
import { useUpdateRoyaltyFee } from 'pages/Businesses/subroutes/BusinessSettings/gql/mutation';
import { RouteComponentProps } from 'react-router-dom';
import { useGetRoyaltyFeeById } from 'pages/Businesses/subroutes/BusinessSettings/gql/query';
import { faAsterisk } from '@fortawesome/pro-light-svg-icons';

interface IRouteParams {
  feeId: string;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const EditRoyaltyFee: React.FC<IProps> = ({ ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const businessIdRedux = useSelector((state: RootState) => state.context.businessId);
  const businessId = _.isNil(businessIdRedux) ? '' : businessIdRedux;
  const royaltyFeeId = props.match.params.feeId;
  const [addExceptionOpen, setAddExceptionOpen] = React.useState(false);
  // const [selectedCenters, setSelectedCenters] = React.useState<
  //   null | [ICenter]
  // >(null);

  const {
    data: royaltyFee,
    loading: loadingFee,
    refetch: refetchFee,
  } = useGetRoyaltyFeeById({
    skip: _.isEmpty(businessId) || _.isEmpty(royaltyFeeId),
    variables: {
      id: royaltyFeeId,
      businessId: businessId,
    },
  });

  const [ruleName, setRuleName] = React.useState(royaltyFee?.getRoyaltyFeeById.name ?? '');
  const [defaultFee, setDefaultFee] = React.useState(royaltyFee?.getRoyaltyFeeById.percentage.toString() ?? '');

  const alreadySelectedCenters = React.useMemo(() => {
    return _.flatten(royaltyFee?.getRoyaltyFeeById.royaltyExceptions.map((e) => e.participantCenterIds));
  }, [royaltyFee]);

  React.useEffect(() => {
    setRuleName(_.isNil(royaltyFee) ? '' : royaltyFee.getRoyaltyFeeById.name);
    setDefaultFee(_.isNil(royaltyFee) ? '' : royaltyFee.getRoyaltyFeeById.percentage.toString());
  }, [royaltyFee]);

  const [updateRoyaltyFeeFn, { loading: updateRoyaltyFeeLoading }] = useUpdateRoyaltyFee({
    onCompleted: () => {
      showToast('Royalty Fee Updated', 'success');
      history.push(`/business/${businessId}/settings/`);
    },
    onError: () => {
      showToast('Error Updating Royalty Fee', 'error');
    },
  });

  const onSave = () => {
    updateRoyaltyFeeFn({
      variables: {
        input: {
          royaltyFeeId: royaltyFeeId,
          businessId: businessId,
          name: ruleName,
          percentage: parseFloat(defaultFee),
        },
      },
    });
  };

  return (
    <>
      <Mui.Box
        sx={{
          backgroundColor: 'white',
          padding: '12px 12px',
          position: 'sticky',
          zIndex: '900',
          top: '65px',
          borderBottom: '1px solid #E2E7EE',
        }}
      >
        <Mui.Box display="flex" justifyContent="space-between" sx={{ alignItems: 'center' }}>
          {/* this is the page title */}
          <Mui.Typography paddingLeft={'16px'} variant="pageHeader" component="h1">
            Royalty Fee
          </Mui.Typography>
          <Mui.Box>
            <Mui.Button
              onClick={() => history.push(`/business/${businessId}/settings/`)}
              sx={{ marginRight: '24px' }}
              variant="cancel"
            >
              Cancel
            </Mui.Button>
            <Mui.Button variant="contained" color="secondary" onClick={() => onSave()} disabled={addExceptionOpen}>
              Save
            </Mui.Button>
          </Mui.Box>
        </Mui.Box>
      </Mui.Box>
      <Mui.Box padding={2} sx={{ maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto' }}>
        {loadingFee || updateRoyaltyFeeLoading ? (
          <Mui.Stack direction="column" spacing={2} alignItems="center">
            <Mui.Skeleton animation={false} variant="rectangular" width="90%" height={118} />
            <Mui.Skeleton animation={false} variant="rectangular" width="90%" height={118} />
            <Mui.Skeleton animation={false} variant="rectangular" width="90%" height={300} />
          </Mui.Stack>
        ) : (
          <>
            <Mui.Card elevation={0} style={{ marginBottom: '16px' }} variant="outlined">
              <Mui.CardContent style={{ padding: '32px' }}>
                <Mui.Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                  <Mui.FormControlLabel
                    label={
                      <span style={{ fontSize: '.875rem' }}>
                        Fee Name{` `}
                        <FontAwesomeIcon
                          icon={faAsterisk}
                          color={'#FF2C2C'}
                          style={{
                            fontSize: '.5rem',
                            height: '1em',
                            marginBottom: '0.5rem',
                          }}
                        />
                      </span>
                    }
                    labelPlacement="top"
                    control={
                      <Mui.TextField
                        size="small"
                        value={ruleName}
                        required
                        onChange={(evt) => setRuleName(evt.target.value)}
                        fullWidth
                        sx={{
                          minWidth: '500px',
                          '& legend': {
                            width: 0,
                          },
                        }}
                      />
                    }
                    componentsProps={{
                      typography: {
                        alignSelf: 'flex-start',
                        marginBottom: '0.5rem',
                      },
                    }}
                    style={{ marginLeft: 0 }}
                  />
                  <Mui.Box>
                    <Mui.FormControlLabel
                      label={
                        <span style={{ fontSize: '.875rem' }}>
                          Fee Percentage{` `}
                          <FontAwesomeIcon
                            icon={faAsterisk}
                            color={'#FF2C2C'}
                            style={{
                              fontSize: '.5rem',
                              height: '1em',
                              marginBottom: '0.5rem',
                            }}
                          />
                        </span>
                      }
                      labelPlacement="top"
                      control={
                        <Mui.TextField
                          type="number"
                          size="small"
                          value={defaultFee}
                          onChange={(evt) => setDefaultFee(evt.target.value)}
                          InputProps={{
                            endAdornment: (
                              <Mui.InputAdornment position="end">
                                <FontAwesomeIcon icon={faPercent} />
                              </Mui.InputAdornment>
                            ),
                          }}
                          sx={{
                            minWidth: '300px',
                            '& legend': {
                              width: 0,
                            },
                          }}
                        />
                      }
                      componentsProps={{
                        typography: {
                          alignSelf: 'flex-start',
                          marginBottom: '0.5rem',
                        },
                      }}
                      style={{ marginLeft: 0 }}
                    />
                  </Mui.Box>
                </Mui.Stack>
                <Mui.Alert variant="outlined" severity="info" sx={{ marginTop: '16px' }}>
                  This royalty fee will be applied to all centers, with the below exceptions.
                </Mui.Alert>
              </Mui.CardContent>
            </Mui.Card>
            <Mui.Box paddingTop={2} paddingBottom={2} display="flex" justifyContent="space-between">
              <Mui.Typography variant="h2">Royalty Fee Exception Rules</Mui.Typography>
              {isMobile ? (
                <Mui.Button
                  disabled={
                    addExceptionOpen ||
                    royaltyFee?.getRoyaltyFeeById.name !== ruleName ||
                    royaltyFee?.getRoyaltyFeeById.percentage !== parseFloat(defaultFee)
                  }
                  onClick={() => setAddExceptionOpen(true)}
                  variant="contained"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Mui.Button>
              ) : (
                <Mui.Button
                  disabled={
                    addExceptionOpen ||
                    royaltyFee?.getRoyaltyFeeById.name !== ruleName ||
                    royaltyFee?.getRoyaltyFeeById.percentage !== parseFloat(defaultFee)
                  }
                  onClick={() => setAddExceptionOpen(true)}
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  variant="contained"
                >
                  Add Exception Rule
                </Mui.Button>
              )}
            </Mui.Box>
            <>
              {!addExceptionOpen && _.isEmpty(royaltyFee?.getRoyaltyFeeById.royaltyExceptions) ? (
                <Mui.Box display="flex" justifyContent="center" paddingTop={8}>
                  <Mui.Typography>There are no Royalty Fee Exception Rules available to display.</Mui.Typography>
                </Mui.Box>
              ) : (
                <Mui.Stack direction="column" spacing={2}>
                  {addExceptionOpen && (
                    <AddRoyaltyException
                      feeId={royaltyFee?.getRoyaltyFeeById.id}
                      defaultOpen={true}
                      refetch={refetchFee}
                      onCancel={() => setAddExceptionOpen(false)}
                      alreadySelectedCenters={alreadySelectedCenters}
                      businessId={businessId}
                    />
                  )}

                  {royaltyFee?.getRoyaltyFeeById.royaltyExceptions.map((e) => (
                    <AddRoyaltyException
                      feeId={royaltyFee?.getRoyaltyFeeById.id}
                      name={e.name}
                      id={e.id}
                      overridePercentage={e.overridePercentage.toString()}
                      participantCenterIds={e.participantCenterIds}
                      refetch={refetchFee}
                      onCancel={() => {}}
                      key={e.id}
                      alreadySelectedCenters={alreadySelectedCenters}
                      businessId={businessId}
                    />
                  ))}
                </Mui.Stack>
              )}
            </>
          </>
        )}
      </Mui.Box>
    </>
  );
};

export default EditRoyaltyFee;
