import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

const ConfirmDisableBillingModal: React.FC<IProps> = ({ isOpen, onClose, onConfirm, ...props }) => {
  const { t } = useTranslation(['billing']);

  const dimissModal = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  return (
    // @ts-ignore
    <ConfirmationModal
      title={t('billing:centers.disable-center-billig-cycle-modal-title')}
      show={isOpen}
      onHide={dimissModal}
      hideOnCallback={true}
      primaryChoice={'Disable'}
      primaryCallback={onConfirm}
      primaryButtonProps={{
        variant: 'danger',
      }}
      secondaryCallback={dimissModal}
    >
      <p>{t('billing:centers.disable-center-billig-cycle-modal-body')}</p>
    </ConfirmationModal>
  );
};

export default ConfirmDisableBillingModal;
