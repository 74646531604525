import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import SideModalDrawer from 'shared/components/ModalDrawer';
import TextInput from 'shared/components/TextInput';
import { isBlank } from 'shared/util/string';
import { useUpdateAccountContactRelationships } from 'gql/account/mutations';
import { showToast } from 'shared/components/Toast';
import ContactChildrenInputs from './ContactChildrenInputs';
import { updateAccountContactRelationshipsSuccess } from '../../../duck/actions';
import { useGetChildContactPermissions } from 'gql/contact/queries';
import { isEmailValid } from 'shared/util/email';
import { useGetAccountContactPermissions } from '../../../../../../../gql/account/queries';
import AccountPermissionInputs from './AccountPermissionsInputs';
import { useTranslation } from 'react-i18next';
import { useDeletePersonPaymentMethods } from 'gql/paymentMethod/authorizedPerson/mutation';

interface IProps {
  isOpen: boolean;
  account: IAccount;
  onClose: () => void;
  contact: IContact;
  setAsPrimary?: boolean;
  setAsNonPrimary?: boolean;
  paymentMethods?: IPaymentMethod[];
}

const UpdateContactPermissionsModalForm: React.FC<IProps> = ({
  isOpen,
  account,
  onClose,
  contact,
  setAsPrimary,
  setAsNonPrimary,
  paymentMethods,
}) => {
  const { t } = useTranslation(['accounts']);
  const dispatch = useDispatch();

  const [deletePersonPaymentMethods, result] = useDeletePersonPaymentMethods(account?.id ?? '');
  const [updateContactFn, { loading }] = useUpdateAccountContactRelationships(account.id, contact.id);
  const { data: getChildContactPermissionsData } = useGetChildContactPermissions();
  const permissionsOptions = getChildContactPermissionsData?.getChildContactRelationshipPermissions ?? [];
  const { data: getAccountContactPermissionsData } = useGetAccountContactPermissions();
  const setAsPrimaryOrNot = setAsNonPrimary || setAsPrimary;

  const archivedChildren = useMemo(
    () => account?.children?.filter((child) => Boolean(child.archivedAt)).map((child) => child.id) ?? [],
    [account?.children]
  );

  const isPrimary = setAsPrimary ? true : setAsNonPrimary ? false : contact.isPrimary;
  const childrenToAddForPrimary = account.children
    .map((c) => c.id)
    .filter((id) => !contact.children.map((c) => c.childId).includes(id))
    .map((childId) => ({
      childId,
      relationship: '',
      permissions: permissionsOptions.map((permission) => permission.value),
    }));

  const existingRelationshipsAndPermissions = contact.children.map((c) => ({
    childId: c.childId,
    relationship: c.relationshipType,
    permissions: c.accountPermissions.find((ac) => ac.accountId === account.id)?.permissions ?? [],
  }));

  const defaultChildren = [
    ...existingRelationshipsAndPermissions,
    ...(setAsPrimary ? childrenToAddForPrimary : []),
  ].map((c) => (setAsNonPrimary ? { ...c, permissions: [] } : c));

  const [formData, setFormData] = useState<IAccountContactChildInput[]>(defaultChildren);
  const [email, updateEmail] = useState(contact.email);
  const [accountContactPermissions, setAccountContactPermissions] = useState<AccountPermissions[]>(
    initializeAccountContactPermissions
  );

  function initializeAccountContactPermissions() {
    const permissions = [
      ...(contact.contactAccountPermissions.find((cap) => cap.accountId === account.id)?.permissions ?? []),
    ];
    // if demoting from primary contact and they have a payment method, default to can pay bill yes
    if (setAsNonPrimary && (paymentMethods?.length ?? 0) > 0 && !permissions.includes('CanPayBill')) {
      permissions.push('CanPayBill');
    }
    return permissions;
  }

  const handleClose = useCallback(() => {
    setFormData([]);
    updateEmail('');
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    updateContactFn({
      variables: {
        input: {
          accountId: account.id,
          contactId: contact.id,
          children: formData.filter((child) => !archivedChildren.includes(child.childId)),
          isPrimary,
          email,
          permissions: accountContactPermissions.map((acp) => ({ type: acp })),
        },
      },
    })
      .then(async (result) => {
        if (!accountContactPermissions.includes('CanPayBill') && (paymentMethods?.length ?? 0) > 0) {
          await deletePersonPaymentMethods(paymentMethods ?? [], account?.id);
        }

        showToast('Contact updated successfully.', 'success');

        dispatch(
          updateAccountContactRelationshipsSuccess(
            account.id,
            contact.id,
            result.data?.updateAccountContactRelationships ?? [],
            isPrimary,
            email,
            accountContactPermissions
          )
        );
        handleClose();
      })
      .catch((e) => {
        console.log(e);
        showToast('There was an error updating contact.', 'error');
      });
  }, [
    updateContactFn,
    account,
    contact.id,
    formData,
    isPrimary,
    email,
    accountContactPermissions,
    archivedChildren,
    paymentMethods,
    dispatch,
    handleClose,
    deletePersonPaymentMethods,
  ]);

  const validateForm = useCallback(
    (data: IAccountContactChildInput[]) => {
      return (
        data.every(
          (childPermission) =>
            !isBlank(childPermission.relationship) || archivedChildren.includes(childPermission.childId)
        ) &&
        (!setAsPrimary || isEmailValid(email))
      );
    },
    [setAsPrimary, email, archivedChildren]
  );

  return (
    <SideModalDrawer
      title={
        setAsPrimary
          ? 'Set Primary Relationships'
          : setAsNonPrimary
          ? 'Set Permissions'
          : `Edit Relationships ${isPrimary ? '' : ' and Permissions'}`
      }
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice={setAsPrimary ? 'Save as Primary' : setAsNonPrimary ? 'Save as Non Primary' : 'Save'}
      primaryCallback={handleSubmit}
      primaryButtonProps={{ disabled: !validateForm(formData), loading: loading || result.loading }}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
    >
      {setAsPrimary && <div className="font-size-12 mb-4">{t('accounts:contacts.setAsPrimaryInfo')}</div>}
      <Form>
        <div className="mb-4">
          <Row>
            <Column>
              <TextInput disabled label="First Name" value={contact.firstname} onChange={(value) => {}} />
            </Column>
            <Column>
              <TextInput disabled label="Last Name" value={contact.lastname} onChange={(value) => {}} />
            </Column>
          </Row>
          {setAsPrimary && (
            <Row noGutters>
              <TextInput
                required
                label="Email"
                value={email}
                onChange={updateEmail}
                type="email"
                isInvalid={!isEmailValid(email)}
              />
            </Row>
          )}
        </div>
        <h5 className="mb-4">Relationships{isPrimary ? '' : ' and Permissions'}</h5>
        <ContactChildrenInputs
          isPrimaryContact={isPrimary}
          childOptions={account.children.filter((child) => !child.archivedAt)}
          contactChildren={formData}
          updateContactChildren={setFormData}
          setAsPrimaryOrNot={setAsPrimaryOrNot}
        />
        {(!contact.isPrimary || setAsNonPrimary) && (
          <div className="mb-6">
            <h5 className="mb-4">{t('accounts:contacts.accountPermissionsFormTitle')}</h5>
            {getAccountContactPermissionsData?.getAccountContactPermissions.map((p, i) => {
              return (
                <Row key={i}>
                  <AccountPermissionInputs
                    permission={p}
                    accountPermissions={accountContactPermissions}
                    setAccountPermissions={setAccountContactPermissions}
                    setAsPrimary={setAsPrimary}
                    hasPaymentMethod={(paymentMethods?.length ?? 0) > 0}
                  />
                </Row>
              );
            })}
            {setAsNonPrimary && (
              <Row
                style={{
                  background: '#e0f4fe',
                  border: '1.5px solid #7ac4fb',
                  color: '#1486c8',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  fontSize: '14px',
                  marginTop: '10px',
                }}
              >
                {t('accounts:contacts.setAsNonPrimaryInfo')}
              </Row>
            )}
          </div>
        )}
      </Form>
    </SideModalDrawer>
  );
};

export default UpdateContactPermissionsModalForm;
