import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import DataTable from 'shared/components/DataTable';
import { faExclamationCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lowerCase } from 'lodash';
import NotificationDetails from './NotificationDetails';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import cast from 'shared/util/cast';
import { useNotificationGoto } from 'pages/NotificationsV2/util/NotificationV2Class';

const Icons = {
  information: <FontAwesomeIcon icon={faInfoCircle} color="#52A7CC" size="1x" className="mr-2" />,
  warning: <FontAwesomeIcon icon={faExclamationTriangle} color="#DEA81C" size="1x" className="mr-2" />,
  error: <FontAwesomeIcon icon={faExclamationCircle} color="#de1c1c" size="1x" className="mr-2" />,
};

interface IProps {
  notifications: INotificationV2[];
  pagination: Omit<IPagedResult<INotificationV2>, 'data'>;
  loading: boolean;
  timezone: string;
  subscriptions: INotificationSubscription[];
  selectedNotifications: INotificationV2[];
  updateSelectedNotifications: (notifications: INotificationV2[]) => void;
  handleMarkNotification: (notification: INotificationV2, markAs: NotificationV2MarkAs) => Promise<void>;
  handleNotifSubscription: (notification: INotificationV2) => void;
  onExpand: (row: any, isExpand: boolean, rowIndex: number, e: any) => void;
  onPageChange: (page: number, sizePerPage: number) => void;
}

const NotificationTable: React.FC<IProps> = ({
  notifications,
  pagination,
  loading,
  timezone,
  selectedNotifications,
  subscriptions,
  updateSelectedNotifications,
  handleNotifSubscription,
  handleMarkNotification,
  onExpand,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const subscriptionTypes = useMemo(() => {
    return subscriptions?.filter((s) => s.enabled).map((s) => `${s.category}-${s.type}`) ?? [];
  }, [subscriptions]);

  const showMute = useCallback(
    (notification: INotificationV2) => {
      const notificationType = `${notification.category}-${notification.type}`;
      return subscriptionTypes.includes(notificationType);
    },
    [subscriptionTypes]
  );
  const { getDescription } = useNotificationGoto();

  return (
    <DataTable
      showSelect
      showPagination
      className="kt-notifications-v2-table"
      data={notifications}
      dataSize={pagination.totalRecords}
      pageSize={pagination.pageSize}
      onPageChange={onPageChange}
      activePage={pagination.pageNumber}
      selectedRows={selectedNotifications}
      updateSelectedRows={updateSelectedNotifications}
      showLoadingOverlay={loading}
      noDataText={t('notification-v2.dataTable.noDataText')}
      onlyOneExpanding
      expandRow={(row: INotificationV2) => (
        <NotificationDetails
          notification={row}
          showMute={showMute(row)}
          handleMarkNotification={handleMarkNotification}
          handleNotifSubscription={handleNotifSubscription}
        />
      )}
      rowClasses={(row: INotificationV2) => (row.read ? 'read' : 'unread')}
      onExpand={onExpand}
      columns={[
        {
          text: t('notification-v2.dataTable.column-labels.level'),
          dataField: 'level',
          classes: 'md-column',
          formatter: (level: NotificationLevel, row: INotificationV2) => (
            <div className={`d-flex align-items-center pl-2 ${row.read ? 'read-flag' : 'unread-flag'}`}>
              {Icons[lowerCase(level) as keyof typeof Icons]}
              {t(cast<any>(`translation:notification-v2.levels.${lowerCase(level)}`))}
            </div>
          ),
        },
        {
          text: t('notification-v2.dataTable.column-labels.type'),
          dataField: 'type',
          classes: 'md-column',
          formatter: (level: NotificationLevel, row: INotificationV2) =>
            `${row.category} - ${t(cast<any>(`translation:notification-v2.typesList.${row.type}`))}`,
        },
        {
          text: t('notification-v2.dataTable.column-labels.subject'),
          dataField: 'description',
          formatter: (description: string, row: INotificationV2) => getDescription(row),
        },
        {
          text: t('notification-v2.dataTable.column-labels.center'),
          dataField: 'centerName',
          classes: 'md-column',
        },
        {
          text: t('notification-v2.dataTable.column-labels.date'),
          dataField: 'dateTime',
          formatter: (date: string) => <>{moment(date).tz(timezone).format('D MMM')}</>,
          classes: 'xs-column',
        },
        {
          text: '',
          dataField: 'archived',
          formatter: (archived: boolean) =>
            archived ? (
              <Badge pill variant="secondary">
                Archived
              </Badge>
            ) : null,
          classes: 'sm-column',
        },
      ]}
    />
  );
};

export default NotificationTable;
