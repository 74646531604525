import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInput } from 'shared/components/TextInput';
import { Row, Col } from 'shared/components/Layout';
import Checkbox from 'shared/components/Checkbox';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';
import { freeKindySubsidyTypes } from 'pages/Subsidies/KindyFunding/utils';

interface IProps {
  formData: IKindyProgramFundingSchedule;
  selectedDate: string | undefined;
  handleChange: (setting: IKindyProgramFundingSchedule) => void;
}

const FreeKindyFundingSettings: React.FC<IProps> = ({ formData, selectedDate, handleChange }) => {
  const { t } = useTranslation(['subsidies', 'translation']);

  const [settingFormData, setSettingFormData] = useState<IKindyProgramFundingSchedule>(formData);
  const handleFormChange = (value: any, name: string) => {
    setSettingFormData((state) => ({
      ...state,
      startDate: selectedDate,
      [name]: value,
    }));
  };

  const handleSubsidyTypeChange = (checked: boolean, value: SubsidyType) => {
    const subsidyTypes = (formData?.subsidyTypes?.filter((st) => freeKindySubsidyTypes.includes(st)) ??
      []) as SubsidyType[];

    if (checked) {
      subsidyTypes.push(value);
    } else {
      var index = subsidyTypes.indexOf(value);
      if (index !== -1) {
        subsidyTypes.splice(index, 1);
      }
    }
    setSettingFormData((state) => ({
      ...state,
      subsidyTypes: subsidyTypes,
      basePercentage: undefined,
      startDate: selectedDate,
    }));
  };

  useEffect(() => {
    handleChange(settingFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingFormData]);

  const minWeeks = 40;
  const maxWeeks = 52;

  return (
    <div>
      <Row className="mb-2">
        <Col lg={12}>
          <h6 className="font-weight-bold">{t('subsidies:automated-funding.weeks')}</h6>
          <div className="mb-1">{t('subsidies:automated-funding.program-weeks-info')}</div>
        </Col>
        <Col lg={4}>
          <NumberInput
            label={t('subsidies:automated-funding.weeks-range')}
            value={settingFormData.weeks}
            numberFormat={{
              allowNegative: false,
              min: minWeeks,
              max: maxWeeks,
            }}
            onChange={(val) => handleFormChange(val, `weeks`)}
            disabled={selectedDate === undefined}
            required
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <h6 className="font-weight-bold">{t('subsidies:automated-funding.type-of-funding')}</h6>
        <div className="mb-2">{t('subsidies:automated-funding.type-of-funding-info')}</div>
      </Row>
      <Row>
        <Checkbox
          value={formData?.subsidyTypes?.includes('Base')}
          label={t('subsidies:automated-funding.free-kindy-funding')}
          onChange={(val: boolean) => {
            handleSubsidyTypeChange(val, 'Base');
          }}
          disabled={selectedDate === undefined}
        />
        <HelpTooltip text={t('subsidies:automated-funding.free-kindy-funding-tooltip')} />
      </Row>
    </div>
  );
};
export default FreeKindyFundingSettings;
