import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { faPortalExit } from '@fortawesome/pro-light-svg-icons';
import ProfileDropdownMenuItem from './ProfileDropdownMenuItem';
import { useApolloClient } from 'shared/apis/core';
import { logout } from 'pages/Login/duck/actions';

interface IProps {}

const LogoutMenuItem: React.FC<IProps> = ({ ...props }) => {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();

  const logoutUser = useCallback(async () => {
    // clear out cache before logging out
    await apolloClient.clearStore();

    dispatch(logout());
  }, [dispatch, apolloClient]);

  return <ProfileDropdownMenuItem label="Logout" icon={faPortalExit} onClick={logoutUser} />;
};

export default LogoutMenuItem;
