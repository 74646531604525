import React, { useState, useCallback } from 'react';
import useUniqueId from 'shared/hooks/useUniqueId';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import MaskedInput from 'react-text-mask';
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { IBaseInputProps, IInputHelperState } from './Input';
import { isBlank } from 'shared/util/string';

interface IProps extends Omit<IBaseInputProps, 'autocomplete'> {
  value?: any;
  maxLength?: number;
  errorText?: string;
  dateFormat?: string;
}

const defaultMaskFormat = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

const MaskedDateTextInput = ({
  id,
  onChange,
  onFocus,
  onBlur,
  maxLength,
  autoFocus,
  isInvalid,
  requiresValidation,
  helperText,
  required,
  label,
  value = '',
  placeholder = 'MM/YYYY',
  dateFormat = 'MM/YYYY',
  className,
  children,
  numberFormat,
  disabled,
  ...props
}: IProps) => {
  const _requiresValidation = required || requiresValidation;
  const controlId = useUniqueId(id);
  const [touched, setTouched] = useState(false);
  const [isFocused, setFocus] = useState(autoFocus);
  const [inputHelperState, setInputHelperState] = useState<IInputHelperState>({
    borderClass: '',
    icon: null,
    iconColor: null,
  });
  const errorText = props.errorText || 'Date is invalid.';

  const handleChange = useCallback(
    (e) => {
      setTouched(true);
      onChange &&
        onChange(
          e.target.value && !numberFormat?.isNumericString ? Number(e.target.value) : e.target.value,
          e.target.name
        );
    },
    [onChange]
  );

  const handleFocus = useCallback(
    (e) => {
      setFocus(true);
      onFocus && onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      setTouched(true);
      setFocus(false);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  React.useEffect(() => {
    if (isFocused) {
      setInputHelperState({
        borderClass: 'focused',
        icon: null,
        iconColor: null,
      });
    } else if (touched) {
      const invalid =
        isInvalid ||
        (required &&
          (isBlank(value) ||
            value === undefined ||
            value === null ||
            (numberFormat?.min && value < numberFormat?.min) ||
            (numberFormat?.max && value > numberFormat?.max)));

      if (required && invalid) {
        setInputHelperState({
          borderClass: 'error',
          icon: faExclamationTriangle,
          iconColor: colors.danger,
        });
      } else if (_requiresValidation) {
        setInputHelperState({
          borderClass: invalid ? 'error' : 'success',
          icon: invalid ? faExclamationTriangle : faCheckCircle,
          iconColor: invalid ? colors.danger : colors.success,
        });
      } else {
        setInputHelperState({
          borderClass: '',
          icon: null,
          iconColor: null,
        });
      }
    }
  }, [touched, isInvalid, required, value, _requiresValidation, numberFormat, isFocused]);

  const getMaskArray = useCallback((): (string | RegExp)[] => {
    if (!dateFormat || dateFormat === 'MM/YYYY') return defaultMaskFormat;
    const dayjsTokenRegex = /(\[[^\]]+\]|YYYY|YY|MM|M)|[^A-Za-z\[\]]+/g;
    const displayDateSplit = dateFormat.match(dayjsTokenRegex);

    let result: (string | RegExp)[] = [];

    displayDateSplit?.forEach((str) => {
      if (/^(YYYY|YY|MM|M)$/.test(str)) {
        const regexArray = Array.from({ length: str.length }, () => /\d/);
        result = result.concat(regexArray);
      } else {
        result.push(str);
      }
    });

    return result;
  }, [dateFormat]);

  return (
    <Form.Group controlId={controlId} className={`${props.small ? 'small-input' : ''} ${className || ''}`}>
      <div className="d-flex flex-row">
        {label && <Form.Label>{label}</Form.Label>}
        {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
      </div>
      <div className="form-control__container">
        <div
          className={`d-flex flex-row align-items-center form-control__container_inner ${
            inputHelperState.borderClass && 'form-control__container-' + inputHelperState.borderClass
          } ${disabled && 'disabled'}`}
        >
          <div className="form-control__input_container">
            <MaskedInput
              {...props}
              maxLength={maxLength}
              placeholder={placeholder}
              inputMode="numeric"
              mask={getMaskArray()}
              className="form-control"
              guide={true}
              value={value === null ? undefined : value}
              required={required}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              autoFocus={autoFocus}
              autoComplete="off"
            />
          </div>
          {required && inputHelperState.icon && (
            <div className="d-flex input-icon-container">
              <FontAwesomeIcon
                className="input-icon"
                icon={inputHelperState.icon}
                color={`${inputHelperState.iconColor}`}
              />
            </div>
          )}
        </div>
      </div>
      {helperText && (
        <Collapse in={isFocused}>
          <div className="text-muted">{helperText}</div>
        </Collapse>
      )}
      {isInvalid && touched && <div className="error-text">{errorText}</div>}
    </Form.Group>
  );
};

export default MaskedDateTextInput;
