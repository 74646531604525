import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import { faChevronLeft, faChevronRight, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import './_searchAvailability.scss';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import Button from 'shared/components/Buttons';
import { isRegion } from '../../../../../../../shared/util/region';
import classnames from 'classnames';

interface IProps {
  datesInView: moment.Moment[];
  classes: IClass[];
  setFirstDateInView: (date: string) => void;
}

interface IButtonProps {
  direction: string;
  classes: IClass[];
  onClick: () => void;
}

interface ITableCellProps {
  _class: IClass;
  date: moment.Moment;
}

const isWeekend = (date: moment.Moment) => date.day() === 0 || date.day() === 6;

const DateChangeButton: React.FC<IButtonProps> = ({ direction, classes, onClick }) => (
  <td className="p-0 m-0" rowSpan={classes.length + 2}>
    <div style={{ height: `${classes.length * 56 + 66}px` }}>
      <Button
        className={`p-1 h-100 w-100 ${direction === 'forward' ? 'border-rounded-right' : 'rounded-0'}`}
        variant="secondary"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={direction === 'forward' ? faChevronRight : faChevronLeft} size="sm" color="white" />
      </Button>
    </div>
  </td>
);

const AvailabilityCell: React.FC<ITableCellProps> = ({ date, _class }) => {
  const availability = _class.availabilityForTimeframe?.find((a) => moment(a.date).isSame(date, 'd'));
  const clazzName = classnames({
    available: (availability?.totalOpenSpots ?? 0) > 0,
    'over-capacity': (availability?.totalOpenSpots ?? 0) < 0,
    weekend: isWeekend(date),
  });
  return (
    <td className={clazzName}>
      {availability?.totalOpenSpots ? (
        <div>{availability?.totalOpenSpots}</div>
      ) : (
        <FontAwesomeIcon color={colors.slate} size="2x" className="mx-auto" icon={faTimesCircle} />
      )}
      {Boolean(availability?.totalAgeUp) && <div className="age-up-background" />}
      {Boolean(availability?.totalAgeUp) && <div className="age-up">{availability?.totalAgeUp}</div>}
    </td>
  );
};

const dateForTable = isRegion('AU') ? 'DD/MM' : 'MM/DD';

const SearchAvailabilityTable: React.FC<IProps> = ({ datesInView, classes, setFirstDateInView }) => {
  return (
    <table className="search-availability-table w-100">
      <tbody>
        <tr>
          <td></td>
          <DateChangeButton
            onClick={() => setFirstDateInView(datesInView[0].clone().subtract(1, 'd').format())}
            classes={classes}
            direction="backward"
          />
          {datesInView.map((d, i) => (
            <td className={isWeekend(d) ? 'weekend' : ''} key={i}>
              {d.format(dateForTable)}
            </td>
          ))}
          <DateChangeButton
            onClick={() => setFirstDateInView(datesInView[0].clone().add(1, 'd').format())}
            classes={classes}
            direction="forward"
          />
        </tr>
        {classes.map((_class) => (
          <tr key={_class.id}>
            <td>
              <AvatarDataTableCell
                primaryText={_class.name}
                color={_class.colorCode}
                initials={_class.name[0]}
                avatar=""
              />
            </td>
            {datesInView.map((date) => (
              <AvailabilityCell key={date.format()} _class={_class} date={date} />
            ))}
          </tr>
        ))}
        <tr>
          <td>Totals</td>
          {datesInView.map((date) => {
            const availability = classes.map((c) =>
              c.availabilityForTimeframe.find((a) => moment(a.date).isSame(date, 'd'))
            );
            const opensArray = availability.map((a) => a?.totalOpenSpots);

            const number = opensArray.reduce((a, b) => (a ?? 0) + (b ?? 0), 0) ?? 0;
            const clazzNames = classnames({
              'text-success': number >= 0,
              'text-danger': number < 0,
              weekend: isWeekend(date),
            });
            return (
              <td key={date.format()} className={clazzNames}>
                {number}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default SearchAvailabilityTable;
