import React from 'react';
import { CardDeck } from 'react-bootstrap';
import { EnrolmentCategoryFormatting } from 'shared/constants/enums/ccssEnums';
import NumberCard from '../components/NumberCard/NumberCard';

interface ITableProps {
  onSelected: (filter: EnrolmentCategory | null) => void;
  activeFilter: EnrolmentCategory | null;
  counts: Record<EnrolmentCategory, number>;
  loading?: boolean;
}

const CcsCategoryCards: React.FC<ITableProps> = ({ onSelected, activeFilter, counts, loading }) => {
  const toggle = (filter: EnrolmentCategory) => (activeFilter !== filter ? filter : null);

  const getProps = (value: EnrolmentCategory) => ({
    number: counts[value],
    numberColor: EnrolmentCategoryFormatting[value].colorName,
    onClick: () => onSelected(toggle(value)),
    active: activeFilter === value,
    loading: loading,
  });

  const cards = (
    <>
      <NumberCard title="Active" {...getProps('Active')} />
      <NumberCard title="Pending" subTitle="Submitted enrolments not yet approved" {...getProps('Pending')} />
      <NumberCard
        title="Disputed"
        subTitle="Enrolments that have been rejected or disputed by families"
        {...getProps('ActionRequired')}
      />
      <NumberCard title="Inactive" subTitle="Enrolments that have been ended or ceased" {...getProps('Inactive')} />
    </>
  );

  return <CardDeck>{cards}</CardDeck>;
};

export default CcsCategoryCards;
