import moment from 'moment';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IFormDataShape } from '../context/ProgramsContext';
import { isNull, isUndefined } from 'lodash';

const useValidateProgram = (formData: IFormDataShape) => {
  const { t } = useTranslation();
  const [isDateValid, setDateValidity] = useState<boolean>(true);
  const validateDateRange = useCallback((start: string, end: string) => {
    setDateValidity(moment(start).isBefore(moment(end)));
  }, []);

  const formErrors = useMemo<Partial<Record<keyof IFormDataShape, string | null>>>(() => {
    let errors = {};
    const { minEnrolmentDays, maxEnrolmentDays, operatingWeekDays = [] } = formData;

    // min day must be set
    if (isNull(minEnrolmentDays) || isUndefined(minEnrolmentDays)) {
      errors = {
        ...errors,
        minEnrolmentDays: t('enrollment.programs.modal.invalid-min-enrollment-days'),
      };
      return errors;
    }

    // max day must be set
    if (isNull(maxEnrolmentDays) || isUndefined(maxEnrolmentDays)) {
      errors = {
        ...errors,
        maxEnrolmentDays: t('enrollment.programs.modal.invalid-max-enrollment-days'),
      };
      return errors;
    }

    // max day must between 1-5
    if (maxEnrolmentDays < 1 || maxEnrolmentDays > 5) {
      errors = {
        ...errors,
        maxEnrolmentDays: t('enrollment.programs.modal.invalid-enrollment-days-number'),
      };
      return errors;
    }

    // min day must between 1-5
    if (minEnrolmentDays < 1 || minEnrolmentDays > 5) {
      errors = {
        ...errors,
        minEnrolmentDays: t('enrollment.programs.modal.invalid-enrollment-days-number'),
      };
      return errors;
    }

    // min can't greater than max
    if (minEnrolmentDays > maxEnrolmentDays) {
      errors = {
        ...errors,
        minEnrolmentDays: t('enrollment.programs.modal.invalid-min-less-then-max-enrollment-days'),
      };
      return errors;
    }

    // operating days must be greater than or equal to max
    if (operatingWeekDays.length < maxEnrolmentDays) {
      errors = {
        ...errors,
        maxEnrolmentDays: t('enrollment.programs.modal.invalid-enrollment-operating-days'),
      };
      return errors;
    }

    const enrolStart = formData.enrolmentOpenDate;
    const enrolEnd = formData.enrolmentCloseDate;
    const programEndDate = formData.endDate;

    //  Close Date is not prior to Open Date
    if (enrolStart && enrolEnd && moment(enrolStart).isAfter(enrolEnd)) {
      errors = {
        ...errors,
        enrolmentCloseDate: t('enrollment.programs.modal.invalid-enrolment-close-later-than-open'),
      };
      return errors;
    }

    //  Enrollment Close Date is not after the Program End Date.
    if (enrolEnd && programEndDate && moment(enrolEnd).isAfter(programEndDate)) {
      errors = {
        ...errors,
        enrolmentCloseDate: t('enrollment.programs.modal.invalid-enrolment-close-later-than-program-close'),
      };
      return errors;
    }

    // ensure a Close Date is not specified without an Open Date
    if (!enrolStart && enrolEnd) {
      errors = {
        ...errors,
        enrolmentOpenDate: t('enrollment.programs.modal.invalid-set-enrollment-open-date'),
      };
      return errors;
    }

    // return non error object
    return {};
  }, [formData, t]);

  useEffect(() => {
    validateDateRange(formData.startDate, formData.endDate);
  }, [formData.startDate, formData.endDate, validateDateRange]);

  return {
    isDateValid,
    formErrors,
  };
};

export default useValidateProgram;
