import React from 'react';
import Tag from './Tag';
import { Row } from 'shared/components/Layout';

interface IProps {
  tags: ITag[];
}

const TagsContainer: React.FC<IProps> = ({ tags }) => {
  return (
    <Row noGutters className="mx-n1">
      {tags.map((tag, i) => (
        <div key={i} className="px-1 mb-2">
          <Tag tag={tag} />
        </div>
      ))}
    </Row>
  );
};

export default TagsContainer;
