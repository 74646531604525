import React from 'react';
import Card from 'shared/components/Card';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import ClassAvailabilityTable from '../ClassAvailabilityTable';

interface IProps {
  availability: IClassAvailability[];
  loading: boolean;
  centerTimezone: string;
}

const ClassAvailabilityCard: React.FC<IProps> = ({ availability, loading, centerTimezone, ...props }) => {
  return (
    <Card header="Availability" bodyClassName="pt-0">
      <SpinnerOverlay show={loading}>
        <ClassAvailabilityTable
          availability={availability}
          centerTimezone={centerTimezone}
          isFullDayClass={availability.every((a) => a.isFullDayUtilization)}
        />
      </SpinnerOverlay>
    </Card>
  );
};

export default ClassAvailabilityCard;
