import { QueryHookOptions } from '@apollo/client';
import { useLazyQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';

const VALIDATE_NEW_CRN = gql`
  query ($businessId: ID!, $crn: String) {
    validateCrn(businessId: $businessId, crn: $crn) {
      error
      isValid
      details {
        id
        type
        firstName
        lastName
      }
    }
  }
`;

export interface ICrnValidation {
  isValid: boolean;
  error: string;
  details?: ICrnErrorDetails;
}

interface ICrnErrorDetails {
  type: string;
  id: string;
  firstName: string;
  lastName: string;
}

interface IValidateCrnData {
  validateCrn: ICrnValidation;
}

interface IValidateCrnVariables {
  businessId: string;
  crn: string;
}

export const useValidateNewCrnLazy = (
  options?: QueryHookOptions<IValidateCrnData, IValidateCrnVariables>,
  fields?: string
) =>
  useLazyQuery<IValidateCrnData, IValidateCrnVariables>(VALIDATE_NEW_CRN, {
    fetchPolicy: 'network-only',
    ...options,
  });
