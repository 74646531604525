import React, { useState, useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import Collapse from 'react-bootstrap/Collapse';
import Select from 'shared/components/Select';
import { showToast } from 'shared/components/Toast';
import { useDeactivateStaff } from '../../../subroutes/Profile/Tabs/Profile/graphql/mutations';
import { reasonForLeavingEnum } from 'shared/constants/enums/reasonForLeavingEnum';
import _, { sortBy } from 'lodash';
import errorMessage from 'shared/constants/errorMessages';
import TextInput from '../../../../../shared/components/TextInput';
const reasonForLeavingOptions = sortBy(Object.keys(reasonForLeavingEnum));

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  staff: IStaff;
}

const DeactivateStaffModal: React.FC<IProps> = ({ isOpen, onClose, staff }) => {
  const [showSecondaryWarning, setShowSecondaryWarning] = useState<boolean>(false);
  const [reasonForLeaving, updateReasonForLeaving] = useState(null);
  const [deactivateStaffMutation, { loading }] = useDeactivateStaff();
  const [confirmDeactivate, setConfirmDeactivate] = useState<string>('');

  const handleClose = useCallback(() => {
    onClose();
    setShowSecondaryWarning(false);
    updateReasonForLeaving(null);
  }, [onClose]);

  const deactivate = useCallback(async () => {
    await deactivateStaffMutation({
      variables: { input: { id: staff.id, reasonForLeaving } },
    }).then(
      () => {
        showToast('Staff deactivated.', 'success');
        handleClose();
      },
      () => {
        showToast(errorMessage.generic, 'error');
        handleClose();
      }
    );
  }, [deactivateStaffMutation, handleClose, staff.id, reasonForLeaving]);

  return (
    <ConfirmationModal
      title="Deactivate User?"
      show={isOpen}
      onHide={handleClose}
      hideOnCallback={false}
      primaryChoice={'Deactivate'}
      primaryCallback={() => deactivate()}
      primaryButtonProps={{
        variant: 'danger',
        disabled: reasonForLeaving === null || confirmDeactivate !== `Deactivate ${staff.firstname} ${staff.lastname}`,
        loading,
      }}
    >
      <p>
        Are you sure you want to deactivate {staff.firstname} {staff.lastname}? This will terminate all relationships
        that exist for this person and immediately end all permissions.
      </p>
      <Select
        label="Reason for Leaving"
        name="reasonForLeaving"
        required={true}
        options={reasonForLeavingOptions}
        getOptionLabel={(option) => reasonForLeavingEnum[option.value]}
        value={reasonForLeaving}
        onChange={updateReasonForLeaving}
        isClearable
      />
      <div>
        <TextInput
          label={`Please type "Deactivate ${staff.firstname} ${staff.lastname}" below to confirm this action.`}
          value={confirmDeactivate}
          onChange={(value: string) => setConfirmDeactivate(value)}
          required={true}
          isInvalid={confirmDeactivate !== `Deactivate ${staff.firstname} ${staff.lastname}`}
        />
      </div>
    </ConfirmationModal>
  );
};

export default DeactivateStaffModal;
