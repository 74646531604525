import { useState } from 'react';
import DataTable from 'shared/components/DataTable';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { nameof } from 'ts-simple-nameof';
import { showToast } from 'shared/components/Toast';
import {
  ExistingChildrenMapping,
  IntegrationType,
  MatchType,
  useGetExistingEntitiesMappingByChildQuery,
  useSelectCcrmMappingMutation,
} from 'generated/graphql';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'shared/components/Layout';
import useFormatDate from 'shared/hooks/useFormatDate';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';

interface IProps {
  businessId: string;
}

const CcrmUnlinkedChildrenForm = ({ businessId }: IProps) => {
  const [ktCcrmMap, setKtCcrmMap] = useState<{ groupId: string; id: string }[]>([]);
  const formatDate = useFormatDate();

  const { data, loading, refetch } = useGetExistingEntitiesMappingByChildQuery({
    variables: { input: { businessId, integrationType: IntegrationType.ChildcareCrm, matchType: MatchType.Child } },
  });

  const [mutateSelectCcrmMapping, { loading: mutateLoading }] = useSelectCcrmMappingMutation();
  const unlinkedKtChildren = data?.getExistingEntitiesMapping.existingChildrenMapping ?? [];

  const handleCancel = (mappingGroupId: string) => {
    const existingRows = ktCcrmMap.filter((e) => e.groupId !== mappingGroupId);
    setKtCcrmMap(existingRows);
  };

  const handleSave = (mappingGroupId: string) => {
    const match = ktCcrmMap.find((r) => r.groupId === mappingGroupId);

    if (!match) return;

    mutateSelectCcrmMapping({
      variables: {
        input: {
          businessId,
          integrationType: IntegrationType.ChildcareCrm,
          matchType: MatchType.Child,
          mappings: [
            {
              rowId: match.id,
              groupId: match.groupId,
            },
          ],
        },
      },
    })
      .then((result) => {
        setKtCcrmMap([]);
        showToast(t('integrations:ccrm.unlinked.children.toast-success'), 'success');
        refetch();
      })
      .catch((err) => showToast(`${err.toString()}`, 'error'));
  };

  const { t } = useTranslation(['integrations']);
  const groupedKtChildren = groupBy(unlinkedKtChildren, (e) => e.mappingGroupId);
  const tableData: ExistingChildrenMapping[] = Object.values(groupedKtChildren).map(
    (existingChild: ExistingChildrenMapping[]) => existingChild[0]
  );

  return (
    <>
      <DataTable
        showLoadingOverlay={loading || mutateLoading}
        data={tableData}
        dataSize={tableData.length ?? 0}
        showSelect={false}
        showPagination={false}
        noPadding={true}
        keyField={nameof<ExistingChildrenMapping>((uc) => uc.id)}
        columns={[
          {
            text: t('integrations:ccrm.unlinked.children.data-table.kt-child-name'),
            dataField: nameof<ExistingChildrenMapping>((uc) => uc.ktChildFullName),
          },
          {
            text: t('integrations:ccrm.unlinked.children.data-table.kt-dob'),
            dataField: nameof<ExistingChildrenMapping>((uc) => uc.ktChildDateOfBirth),
            formatter: (cell: string) => <>{formatDate(cell)}</>,
          },
          {
            text: t('integrations:ccrm.unlinked.children.data-table.kt-family-account'),
            dataField: nameof<ExistingChildrenMapping>((uc) => uc.ktChildAccountName),
          },
          {
            text: t('integrations:ccrm.unlinked.children.data-table.kt-primary-contact'),
            dataField: nameof<ExistingChildrenMapping>((uc) => uc.ktPrimaryContactName),
          },
        ]}
        expandRow={(ktChild: ExistingChildrenMapping) => {
          const handleToggle = (row: ExistingChildrenMapping) => {
            const existingRows = ktCcrmMap.filter((e) => e.groupId !== row.mappingGroupId);
            setKtCcrmMap(
              existingRows.concat([
                {
                  groupId: row.mappingGroupId,
                  id: row.id,
                },
              ])
            );
          };

          const filteredChildren = unlinkedKtChildren.filter((e) => e.mappingGroupId === ktChild.mappingGroupId);
          return (
            <FormWrapper2
              formIsDirty={true}
              toggleDirty={() => {}}
              className="pl-5"
              onCancel={() => handleCancel(ktChild.mappingGroupId)}
              onSave={() => handleSave(ktChild.mappingGroupId)}
            >
              <DataTable
                data={filteredChildren}
                dataSize={filteredChildren.length ?? 0}
                showSelect={false}
                showPagination={false}
                striped
                columns={[
                  {
                    text: ``,
                    dataField: 'linkToAccount',
                    isDummyField: true,
                    formatter: (cell, row: ExistingChildrenMapping) => (
                      <Container>
                        <Row align="center" justify="center">
                          <Col>
                            <input
                              id={row.id}
                              checked={!!ktCcrmMap.find((r) => r.groupId === row.mappingGroupId && r.id === row.id)}
                              type="radio"
                              className="custom-form-input"
                              onChange={(e) => handleToggle(row)}
                            />
                          </Col>
                        </Row>
                      </Container>
                    ),
                  },
                  {
                    text: t('integrations:ccrm.unlinked.children.data-table.ccrm-child-name'),
                    dataField: nameof<ExistingChildrenMapping>((uc) => uc.ccrmChildFullName),
                  },
                  {
                    text: t('integrations:ccrm.unlinked.children.data-table.ccrm-dob'),
                    dataField: nameof<ExistingChildrenMapping>((uc) => uc.ccrmChildDateOfBirth),
                    formatter: (cell: string) => <>{formatDate(cell)}</>,
                  },
                  {
                    text: t('integrations:ccrm.unlinked.children.data-table.ccrm-family-id'),
                    dataField: nameof<ExistingChildrenMapping>((uc) => uc.ccrmFamilyId),
                  },
                  {
                    text: t('integrations:ccrm.unlinked.children.data-table.ccrm-guardian-name'),
                    dataField: nameof<ExistingChildrenMapping>((uc) => uc.ccrmGuardianFullName),
                  },
                ]}
              />
            </FormWrapper2>
          );
        }}
      />
    </>
  );
};

export default CcrmUnlinkedChildrenForm;
