import i18n from 'i18n';
import { capitalize } from 'lodash';
import { getNumberWithOrdinal } from 'shared/util/string';

export const billingFrequencyOptions: { value: BillingFrequency; label: string }[] = [
  { value: 'WEEKLY', label: 'week' },
  { value: 'BIWEEKLY', label: '2 weeks' },
  { value: 'EVERY_FOUR_WEEKS', label: '4 weeks' },
  { value: 'MONTHLY', label: 'monthly' },
];

export const billingFrequencyFilterOptions: { value: BillingFrequency; label: string }[] = [
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'BIWEEKLY', label: capitalize(i18n.t('spelling.biweekly')) },
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'EVERY_FOUR_WEEKS', label: 'Every four weeks' },
  { value: 'QUARTERLY', label: 'Quarterly' },
];

export const periodBasedBillingFrequencyOptions: { value: BillingFrequency; label: string }[] = [
  { value: 'WEEKLY', label: 'weekly' },
  { value: 'BIWEEKLY', label: 'every 2 weeks' },
  { value: 'MONTHLY', label: 'monthly' },
  { value: 'QUARTERLY', label: 'quarterly' },
];

export const dayOfWeekOptions: { value: DayOfWeekType; label: string }[] = [
  { value: 'MONDAY', label: 'Monday' },
  { value: 'TUESDAY', label: 'Tuesday' },
  { value: 'WEDNESDAY', label: 'Wednesday' },
  { value: 'THURSDAY', label: 'Thursday' },
  { value: 'FRIDAY', label: 'Friday' },
  { value: 'SATURDAY', label: 'Saturday' },
  { value: 'SUNDAY', label: 'Sunday' },
];

// should i add these to Billing Frequency, made value tpye of 'any' for the mean time
export const biweeklyFrequencyPayPeriodOptions: { value: FrequencyPayPeriod; label: string }[] = [
  { value: 'FIRST_WEEK', label: '1st week' },
  { value: 'SECOND_WEEK', label: '2nd week' },
];

export const quarterlyFrequencyPayPeriodOptions: { value: FrequencyPayPeriod; label: string }[] = [
  { value: 'FIRST_MONTH', label: '1st month' },
  { value: 'SECOND_MONTH', label: '2nd month' },
  { value: 'THIRD_MONTH', label: '3rd month' },
];

export const dayOfMonthOptions: { value: number; label: string }[] = [{ value: -1, label: 'last day' }];

for (let x = 1; x < 29; x++) {
  dayOfMonthOptions.push({ value: x, label: getNumberWithOrdinal(x) });
}

export const timeUnitOptions: { value: TimeUnit; label: string }[] = [
  { value: 'DAYS', label: 'days' },
  { value: 'WEEKS', label: 'weeks' },
];

export const billingPeriodOptions: { value: BillingPeriod; label: string }[] = [
  { value: 'CURRENT_WEEK', label: 'current week' },
  { value: 'PREVIOUS_WEEK', label: 'previous week' },
];

export const periodBasedBillingPeriodOptions: { value: BillingPeriod; label: string }[] = [
  { value: 'ADVANCE', label: 'in advance for services not yet rendered' },
  { value: 'ARREARS', label: 'in arrears for services rendered' },
  { value: 'CURRENT', label: 'for the current period of services' },
];

export const additionalBillingPeriodOptions: { value: ITimePeriod; label: string }[] = [
  {
    value: {
      unit: 'WEEKS',
      value: 0,
    },
    label: '0 weeks',
  },
  {
    value: {
      unit: 'WEEKS',
      value: 1,
    },
    label: '1 week',
  },
  {
    value: {
      unit: 'WEEKS',
      value: 2,
    },
    label: '2 weeks',
  },
  {
    value: {
      unit: 'WEEKS',
      value: 3,
    },
    label: '3 weeks',
  },
  {
    value: {
      unit: 'MONTHS',
      value: 1,
    },
    label: '1 month',
  },
];
