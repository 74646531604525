import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import { capitalize } from 'shared/util/string';
import TagsDropdown from './components/TagsDropdown';
import {
  AccountStatusType,
  CustomFieldArea,
  CustomFieldType,
  CustomFieldsReportInput,
  TagCategory,
} from 'generated/graphql';
import { RootState } from 'store/reducers';
import { useGetCustomFields } from 'pages/Businesses/subroutes/BusinessProfile/graphql/queries';
import _ from 'lodash';
import { PREDICATES, SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import Checkbox from 'shared/components/Checkbox';
import { useGetClassesForMultipleCenters } from 'pages/Centers/subroutes/Classes/graphql/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.scss';
import { useSearchCenters } from 'gql/center/queries';
import { SEARCH_MY_CENTERS } from 'pages/Centers/subroutes/Profiles/graphql/fields';
import SelectWithAllOption from './components/SelectWithAllOption';

interface IFormStateShape {
  reportType: CustomFieldArea;
  useCenterTags: boolean;
  centerIds: string[];
  centerTags: ITag[];
  classIds: string[];
  accountTags: ITag[];
  tags: ITag[];
  accountCustomFieldIds: string[];
  customFieldIds: string[];
  includeActiveAccounts: boolean;
  includeInactiveAccounts: boolean;
  includePrimaryContacts: boolean;
  includeSecondaryContacts: boolean;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  onSubmit: (input: CustomFieldsReportInput) => void;
  onClose: () => void;
}

const CustomFieldsReportModal: React.FC<IProps> = ({ isOpen, isLoading, reportName, onSubmit, onClose, ...props }) => {
  const { t } = useTranslation(['translation', 'reporting']);
  const { businessId } = useSelector((state: RootState) => state.context);
  const [formData, setFormData] = useState<IFormStateShape>({
    reportType: CustomFieldArea.Child,
    useCenterTags: false,
    centerIds: [],
    centerTags: [],
    classIds: [],
    accountTags: [],
    tags: [],
    accountCustomFieldIds: [],
    customFieldIds: [],
    includeActiveAccounts: true,
    includeInactiveAccounts: false,
    includePrimaryContacts: true,
    includeSecondaryContacts: false,
  });

  const { data: centersData, loading: loadingCenters } = useSearchCenters(
    {
      variables: {
        input: {
          filter: { term: { field: 'active', predicate: 'ACTIVE' } },
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          size: 10000,
          from: 0,
        },
      },
    },
    SEARCH_MY_CENTERS
  );

  const centerSelectOptions = useMemo(() => {
    return centersData ? centersData?.searchCenters.data : [];
  }, [centersData]);

  const { data: classes, loading: classesLoading } = useGetClassesForMultipleCenters(formData.centerIds ?? []);
  const classesOptions = useMemo(() => {
    if (classes) {
      return classes.getClassesForCenters.map((c) => ({ value: c.id, label: c.name }));
    }
    return [];
  }, [classes]);

  const input = {
    businessId: businessId === null ? '' : businessId,
    allowedCenterIds: formData.centerIds.length === 0 ? centerSelectOptions?.map((c) => c.id) : formData.centerIds,
    searchKey: undefined,
    pageNumber: 1,
    pageSize: 1000, // we're making the assumption there are always less than 1000 custom fields for a business
    sortDirection: 'asc',
    sortBy: 'area',
    area: null,
    type: null,
    isArchived: false,
    matchAnyCenterId: true,
  };

  const {
    data: customFieldsData,
    loading,
    refetch,
  } = useGetCustomFields({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      input: {
        ...input,
      },
    },
  });

  const [customFields, groupedCustomFields, accountCustomFields, groupedAccountCustomFields] = useMemo(() => {
    const filteredData = customFieldsData?.getPaginatedCustomFieldsForBusiness.data.filter(
      (c) =>
        !c.notForConsoleDisplay &&
        ![CustomFieldType.Signature, CustomFieldType.Document, CustomFieldType.HtmlText].includes(
          c.type as CustomFieldType
        ) &&
        (c.centerIds.length === 0 ||
          (c.centerIds.length > 0 && c.centerIds.some((cc) => formData.centerIds.includes(cc))))
    );

    const accountCustomFields = filteredData?.filter(
      (c) => c.area === CustomFieldArea[CustomFieldArea.Account].toString()
    );
    const groupedAccountFieldsData = _.groupBy(accountCustomFields, 'label');
    const groupedAccountField = Object.keys(groupedAccountFieldsData).map((customFieldLabel) => {
      return {
        value: customFieldLabel,
        label: customFieldLabel,
        searchExpression: {
          [SEARCH_EXPRESSIONS.TERM]: {
            field: 'value',
            predicate: PREDICATES.CONTAINS,
            value: customFieldLabel,
          },
        },
      };
    });

    const categoryCustomFields = filteredData?.filter(
      (c) => c.area === CustomFieldArea[formData.reportType].toString()
    );
    const groupedData = _.groupBy(categoryCustomFields, 'label');
    const groupedFields = Object.keys(groupedData).map((customFieldLabel) => {
      return {
        value: customFieldLabel,
        label: customFieldLabel,
        searchExpression: {
          [SEARCH_EXPRESSIONS.TERM]: {
            field: 'value',
            predicate: PREDICATES.CONTAINS,
            value: customFieldLabel,
          },
        },
      };
    });

    return [categoryCustomFields, groupedFields, accountCustomFields, groupedAccountField];
  }, [customFieldsData?.getPaginatedCustomFieldsForBusiness.data, formData.centerIds, formData.reportType]);

  const selectedCustomFields = useMemo(() => {
    const selectedFields = customFields?.filter((c) => formData.customFieldIds.includes(c.id));
    return groupedCustomFields.filter((g) => selectedFields?.some((c) => c.label === g.value));
  }, [customFields, formData.customFieldIds, groupedCustomFields]);

  const selectedAccountCustomFields = useMemo(() => {
    const selectedFields = accountCustomFields?.filter((c) => formData.accountCustomFieldIds.includes(c.id));
    return groupedAccountCustomFields.filter((g) => selectedFields?.some((c) => c.label === g.value));
  }, [accountCustomFields, formData.accountCustomFieldIds, groupedAccountCustomFields]);

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        reportType: CustomFieldArea.Child,
        useCenterTags: false,
        centerIds: [],
        centerTags: [],
        classIds: [],
        accountTags: [],
        tags: [],
        accountCustomFieldIds: [],
        customFieldIds: [],
        includeActiveAccounts: true,
        includeInactiveAccounts: false,
        includePrimaryContacts: true,
        includeSecondaryContacts: false,
      });
    }
  }, [isOpen]);

  const handleSetUseCenterTags = (useCenterTags: boolean) => {
    setFormData((prev) => ({
      ...prev,
      useCenterTags: useCenterTags,
      centerIds: [],
      centerTags: [],
      classIds: [],
      accountTags: [],
      tags: [],
      accountCustomFieldIds: [],
      customFieldIds: [],
    }));
  };

  const handleSetReportType = (type: CustomFieldArea) => {
    setFormData((prev) => ({
      ...prev,
      reportType: type,
      classIds: [],
      accountTags: [],
      tags: [],
      accountCustomFieldIds: [],
      customFieldIds: [],
      includePrimaryContacts: true,
      includeSecondaryContacts: false,
    }));
  };

  const accountStatusType = useMemo(() => {
    if (formData.includeActiveAccounts && formData.includeInactiveAccounts) {
      return undefined;
    }

    if (formData.includeActiveAccounts) {
      return AccountStatusType.Active;
    }

    if (formData.includeInactiveAccounts) {
      return AccountStatusType.Inactive;
    }

    return undefined;
  }, [formData]);

  const includePrimaryContacts = useMemo(() => {
    if (
      (formData.includePrimaryContacts && formData.includeSecondaryContacts) ||
      formData.reportType !== CustomFieldArea.Contact
    ) {
      return undefined;
    }

    if (formData.includePrimaryContacts) {
      return true;
    }

    if (formData.includeSecondaryContacts) {
      return false;
    }

    return undefined;
  }, [formData]);

  const handleClose = useCallback(() => {
    setFormData({
      reportType: CustomFieldArea.Child,
      useCenterTags: false,
      centerIds: [],
      centerTags: [],
      classIds: [],
      accountTags: [],
      tags: [],
      accountCustomFieldIds: [],
      customFieldIds: [],
      includeActiveAccounts: true,
      includeInactiveAccounts: false,
      includePrimaryContacts: true,
      includeSecondaryContacts: false,
    });
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      accountStatusType: accountStatusType,
      businessId: businessId ?? '',
      centerIds: formData.centerIds,
      classIds: formData.classIds.length > 0 ? formData.classIds : undefined,
      customFieldIds: [...formData.customFieldIds, ...formData.accountCustomFieldIds],
      customFieldReportType: formData.reportType,
      inclNotForConsoleDisplay: false,
      showPrimaryContactsOnly: includePrimaryContacts,
      tags: [...formData.tags.map((c) => c.id), ...formData.accountTags.map((c) => c.id)],
    });
  }, [
    accountStatusType,
    businessId,
    formData.accountCustomFieldIds,
    formData.accountTags,
    formData.centerIds,
    formData.classIds,
    formData.customFieldIds,
    formData.reportType,
    formData.tags,
    includePrimaryContacts,
    onSubmit,
  ]);

  const getTagCategoriesForReportType = useCallback(() => {
    switch (formData.reportType) {
      case CustomFieldArea.Contact:
        return [TagCategory.Contact];
      case CustomFieldArea.Child:
      default:
        return [TagCategory.Child];
    }
  }, [formData.reportType]);

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled:
          formData.centerIds.length <= 0 ||
          !(formData.customFieldIds.length > 0 || formData.accountCustomFieldIds.length > 0),
        loading: isLoading,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
    >
      <Row>
        <Col>
          <Form.Group>
            <div className="d-flex flex-row">Report Type</div>
            <Form.Check
              inline
              label={'Child'}
              name="report-type-group"
              type="radio"
              checked={formData.reportType === CustomFieldArea.Child}
              onChange={() => handleSetReportType(CustomFieldArea.Child)}
            />
            <Form.Check
              inline
              label={'Contact'}
              name="report-type-group"
              type="radio"
              checked={formData.reportType === CustomFieldArea.Contact}
              onChange={() => handleSetReportType(CustomFieldArea.Contact)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <div className="d-flex flex-row">{`Filter ${capitalize(t('translation:spelling.center'))}s By`}</div>
            <Form.Check
              inline
              label={`Name(s)`}
              name="center-filter-group"
              type="radio"
              checked={!formData.useCenterTags}
              onChange={() => handleSetUseCenterTags(false)}
            />
            <Form.Check
              inline
              label={`Tag(s)`}
              name="center-filter-group"
              type="radio"
              checked={formData.useCenterTags}
              onChange={() => handleSetUseCenterTags(true)}
            />
          </Form.Group>
        </Col>
      </Row>
      {formData.useCenterTags && (
        <Row>
          <Col>
            <TagsDropdown
              isMultiSelect
              requiredTags={['CENTER']}
              categories={['CENTER']}
              selectedTags={formData.centerTags}
              setSelectedTags={(selectedTags) => {
                const selectedCenters = centerSelectOptions
                  .filter(
                    (c) => c.tags && c.tags.length > 0 && c.tags.some((t) => selectedTags.some((st) => st.id === t.id))
                  )
                  .map((c) => c.id);
                setFormData((prev) => ({
                  ...prev,
                  centerTags: selectedTags,
                  centerIds: selectedCenters,
                  classIds: [],
                  customFieldIds: [],
                  accountCustomFieldIds: [],
                }));
              }}
            />
          </Col>
        </Row>
      )}
      {formData.useCenterTags && formData.centerTags.length > 0 && formData.centerIds.length <= 0 && (
        <Alert variant="warning" className="mb-4">
          <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} color={colors.warning} />
          <span style={{ fontSize: 14 }}>
            No {t('translation:spelling.center')}s are associated with this tag. Select another tag(s).
          </span>
        </Alert>
      )}
      {!formData.useCenterTags && (
        <Row>
          <Col>
            <Select
              required
              id="center-input"
              label={capitalize(t('translation:spelling.center'))}
              aria-label={`Select ${capitalize(t('translation:spelling.center'))}`}
              placeholder={`${capitalize(t('translation:spelling.center'))}`}
              value={formData.centerIds[0]}
              options={centerSelectOptions.map((c) => ({ value: c.id, label: c.name }))}
              onChange={(option: any) =>
                setFormData((prev) => ({
                  ...prev,
                  centerIds: [option.value] as string[],
                  classIds: [],
                  customFieldIds: [],
                  accountCustomFieldIds: [],
                }))
              }
            />
          </Col>
        </Row>
      )}

      {formData.reportType === CustomFieldArea.Contact && (
        <>
          <Row className="pl-2">Contacts</Row>
          <Row className="pl-2">
            <Checkbox
              label={'Primary'}
              value={formData.includePrimaryContacts}
              onChange={(value) => setFormData({ ...formData, includePrimaryContacts: value })}
            />
          </Row>
          <Row className="pl-2 mb-4">
            <Checkbox
              label={'Secondary'}
              value={formData.includeSecondaryContacts}
              onChange={(value) => setFormData({ ...formData, includeSecondaryContacts: value })}
            />
          </Row>
        </>
      )}
      <Row className="pl-2">{t('translation:spelling.accountStatusFilter')}</Row>
      <Row className="pl-2">
        <Checkbox
          label={capitalize(t('translation:spelling.active'))}
          value={formData.includeActiveAccounts}
          onChange={(value) => setFormData({ ...formData, includeActiveAccounts: value })}
        />
      </Row>
      <Row className="pl-2 mb-4">
        <Checkbox
          label={capitalize(t('translation:spelling.inactive'))}
          value={formData.includeInactiveAccounts}
          onChange={(value) => setFormData({ ...formData, includeInactiveAccounts: value })}
        />
      </Row>
      {formData.reportType === CustomFieldArea.Child && formData.centerIds.length === 1 && (
        <Row>
          <Col>
            <SelectWithAllOption
              disabled={formData.centerIds.length <= 0}
              isLoading={loading}
              id="classes-input"
              className={'flex-wrap'}
              label={`Classes`}
              aria-label={`Classes`}
              placeholder={`Select...`}
              options={classesOptions.sort((a, b) => (a.label > b.label ? 1 : -1))}
              selectedIds={formData.classIds}
              onSelect={(selected) =>
                setFormData((prev) => ({
                  ...prev,
                  classIds: selected ?? [],
                }))
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <TagsDropdown
            categories={['ACCOUNT']}
            selectedTags={formData.accountTags}
            setSelectedTags={(selectedTags) => setFormData((prev) => ({ ...prev, accountTags: selectedTags }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectWithAllOption
            isLoading={loading}
            disabled={formData.centerIds.length <= 0}
            id="account-custom-fields-input"
            className="react-select-container flex-wrap"
            helpTooltipText={`Different custom fields that are named the same across multiple ${t(
              'translation:spelling.center'
            )}s will appear only once in this list, but will appear as different columns in the exported report`}
            label={`Account Custom Fields`}
            aria-label={`Account Custom Fields`}
            placeholder={`Select...`}
            options={groupedAccountCustomFields.sort((a, b) => (a.label > b.label ? 1 : -1))}
            selectedIds={selectedAccountCustomFields.map((c) => c.value)}
            onSelect={(selected) =>
              setFormData((prev) => ({
                ...prev,
                accountCustomFieldIds:
                  accountCustomFields?.filter((c) => selected?.some((s) => s === c.label))?.map((c) => c.id) ?? [],
              }))
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TagsDropdown
            categories={getTagCategoriesForReportType()}
            selectedTags={formData.tags}
            setSelectedTags={(selectedTags) => setFormData((prev) => ({ ...prev, tags: selectedTags }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectWithAllOption
            isLoading={loading}
            disabled={formData.centerIds.length <= 0}
            id="custom-fields-input"
            className="react-select-container flex-wrap"
            helpTooltipText={`Different custom fields that are named the same across multiple ${t(
              'translation:spelling.center'
            )}s will appear only once in this list, but will appear as different columns in the exported report`}
            label={`${capitalize(formData.reportType)} Custom Fields`}
            aria-label={`Custom Fields`}
            placeholder={`Select...`}
            options={groupedCustomFields.sort((a, b) => (a.label > b.label ? 1 : -1))}
            selectedIds={selectedCustomFields.map((c) => c.value)}
            onSelect={(selected) =>
              setFormData((prev) => ({
                ...prev,
                customFieldIds:
                  customFields?.filter((c) => selected?.some((s) => s === c.label))?.map((c) => c.id) ?? [],
              }))
            }
          />
        </Col>
      </Row>
      {formData.accountCustomFieldIds.length <= 0 && formData.customFieldIds.length <= 0 && (
        <Alert variant="warning" className="mb-4">
          <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} color={colors.warning} />
          <span style={{ fontSize: 14 }}>Please have at least one selection from Account or Child Custom Fields.</span>
        </Alert>
      )}
    </SideModalDrawer>
  );
};

export default CustomFieldsReportModal;
