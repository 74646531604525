import React from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  allergy: IAllergy | null;
}

const ArchiveAllergyModal: React.FC<IProps> = ({ isOpen, onClose, onSave, allergy, ...props }) => {
  if (!allergy) {
    return null;
  }

  return (
    <ConfirmationModal
      title="Archive Allergy?"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Archive"
      primaryCallback={onSave}
      primaryButtonProps={{ variant: 'danger' }}
    >
      <div className="mb-4">Are you sure you want to archive this person's {allergy.allergen.name} allergy?</div>
      <div>The history for this condition will remain viewable, but not editable, once it's archived.</div>
    </ConfirmationModal>
  );
};

export default ArchiveAllergyModal;
