import { MutationHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { customFieldFields } from './fields';
import {
  ICustomField,
  IDeleteCustomFiledFileInput,
  IUpdateCustomFieldFileUploadInput,
  IUpdateCustomFieldInput,
  IUpdateCustomFieldValuesInput,
} from 'shared/types/customField';

const UPDATE_CUSTOM_FIELD = gql`
  mutation($input: UpdateCustomFieldInput!) {
    updateCustomField(input: $input) {
      ${customFieldFields}
    }

  }
`;
const ARCHIVE_CUSTOM_FIELD = gql`
  mutation($businessId: ID!, $id: ID!) {
    archiveCustomField(businessId: $businessId, id: $id) {
      ${customFieldFields}
    }

  }
`;

const UPDATE_CUSTOM_FIELD_VALUES = gql`
  mutation ($input: UpdateCustomFieldValuesInput!) {
    updateCustomFieldValues(input: $input)
  }
`;

const UPDATE_CUSTOM_FIELD_FILE_UPLOAD = gql`
  mutation ($input: UpdateCustomFieldFileUploadInput!) {
    updateCustomFieldFileUpload(input: $input) {
      entityId
      fileId
    }
  }
`;

interface IUpdateCustomFieldValuesData {
  updateCustomFieldValues: boolean;
}

interface IUpdateCustomFieldValuesVariables {
  input: IUpdateCustomFieldValuesInput;
}

interface ICustomFieldDocumentFileResponse {
  entityId: string;
  fileId: string;
}

interface IUpdateCustomFieldVariables {
  input: IUpdateCustomFieldInput;
}

interface IUpdateCustomFieldData {
  updateCustomField: ICustomField;
}

interface IArchiveCustomFieldData {
  archiveCustomField: ICustomField;
}
interface IArchiveCustomFieldVariables {
  businessId: string;
  id: string;
}
interface IUpdateCustomFieldFileUploadData {
  updateCustomFieldFileUpload: ICustomFieldDocumentFileResponse;
}

interface IUpdateCustomFieldFileUploadVariables {
  input: IUpdateCustomFieldFileUploadInput;
}

export const useUpdateCustomField = (
  options?: MutationHookOptions<IUpdateCustomFieldData, IUpdateCustomFieldVariables>
) => {
  return useMutation<IUpdateCustomFieldData, IUpdateCustomFieldVariables>(UPDATE_CUSTOM_FIELD, options);
};

export const useArchiveCustomField = (
  options?: MutationHookOptions<IArchiveCustomFieldData, IArchiveCustomFieldVariables>
) => {
  return useMutation<IArchiveCustomFieldData, IArchiveCustomFieldVariables>(ARCHIVE_CUSTOM_FIELD, options);
};

export const useUpdateCustomFieldValues = (
  options?: MutationHookOptions<IUpdateCustomFieldValuesData, IUpdateCustomFieldValuesVariables>
) => {
  return useMutation<IUpdateCustomFieldValuesData, IUpdateCustomFieldValuesVariables>(
    UPDATE_CUSTOM_FIELD_VALUES,
    options
  );
};

export const useUpdateCustomFieldFileUpload = (
  options?: MutationHookOptions<IUpdateCustomFieldFileUploadData, IUpdateCustomFieldFileUploadVariables>
) => {
  return useMutation<IUpdateCustomFieldFileUploadData, IUpdateCustomFieldFileUploadVariables>(
    UPDATE_CUSTOM_FIELD_FILE_UPLOAD,
    options
  );
};

const DELETE_CUSTOM_FIELD_FILE = gql`
  mutation ($input: DeleteCustomFieldFileInput!) {
    deleteCustomFieldFile(input: $input)
  }
`;

interface IDeleteCustomFiledFileVariable {
  input: IDeleteCustomFiledFileInput;
}

export const useDeleteCustomFiledFile = (options?: MutationHookOptions<boolean, IDeleteCustomFiledFileVariable>) => {
  return useMutation<boolean, IDeleteCustomFiledFileVariable>(DELETE_CUSTOM_FIELD_FILE, options);
};
