import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { QueryHookOptions, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Checkbox from 'shared/components/Checkbox';
import { useCreateAccount } from 'gql/account/mutations';
import { showToast } from 'shared/components/Toast';
import { Col, Row } from 'shared/components/Layout';
import {
  getFullName,
  getInitials,
  isBlank,
  isValidPhoneNumber,
  parsePhoneNumberWithRegion,
  stringToHsl,
} from 'shared/util/string';
import { isRegion } from 'shared/util/region';
import Select from 'shared/components/Select';
import { ApolloError } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { Collapse } from 'react-bootstrap';
import { useQuery } from 'shared/apis/core';
import debouncePromise from 'debounce-promise';
import { ISearchChildrenData, ISearchChildVariables } from 'pages/Families/subroutes/Children/graphql/queries';
import { ISearchContactsData, ISearchContactsVariables } from 'pages/Families/subroutes/Contacts/graphql/queries';
import Avatar from 'shared/components/Avatar';
import { ICrnValidation, useValidateNewCrnLazy } from 'gql/crn/queries';
import { isValidCrn } from 'shared/components/CrnInput/isValidCrn';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as Mui from '@mui/material';

import { useGetSessionDataForChildSelect } from 'gql/session/queries';
import _ from 'lodash';
import { DIRECTIONS } from 'shared/constants/elastic';
import { useCreateNewMessageInChannel } from 'gql/communications/mutations';
import { ChannelType, ActivityType } from 'generated/graphql';
import User from 'shared/types/user';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  centerOptions: ICenter[];
  currentCenterId: string;
  businessId: string | null;
  channelId?: string | undefined;
}

interface IChildWithAccountId extends IChild {
  accountChildId: string;
}

interface IFormShape {
  classId: string;
  children: IChildWithAccountId[];
  takenOn: string;
  message: string;
}

const newMessageInput = {
  classId: '',
  children: [],
  takenOn: moment().format(),
  message: '',
};
const AddMessageForm: React.FC<IProps> = ({
  isOpen,
  onClose,
  centerOptions,
  currentCenterId,
  businessId,
  channelId = undefined,
  ...props
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = React.useState<IFormShape>(newMessageInput);

  const [filteredChildren, setFilteredChildren] = useState([]);
  const [selectedCenterId, setSelectedCenterId] = useState<string>(currentCenterId);
  const [selectedChild, setSelectedChild] = useState<IAccountChild | null>(null);
  const [message, setMessage] = useState<string>('');

  const isAuRegion = isRegion('AU');

  const [createNewMessageInChannel, { loading: createMessageLoading }] = useCreateNewMessageInChannel();

  const user = useSelector<RootState, User | null>((state: RootState) => state.user);

  const resetForm = useCallback(() => {
    setFormData(newMessageInput);
    setSelectedChild(null);
    setMessage('');
  }, []);

  const dismissModal = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  // SUBMIT MSG

  const handleSubmit = () => {
    submitMessage();
  };

  const submitMessage = () => {
    createNewMessageInChannel({
      variables: {
        businessId: businessId ?? '',
        input: {
          businessId: businessId ?? '',
          channelType: ChannelType.Student,
          // Update channel ID once you get child select working
          channelId: channelId ?? selectedChild?.accountChildId ?? '',
          messageContent: message,
          activityType: ActivityType.MessageSent,
          activityTime: 0,
          personId: user?.id ?? '',
          channelWasRead: false,
        },
      },
    })
      .then((result) => {
        showToast(`Successfully Sent Message.`, 'success');
        onClose();
        resetForm();
        // refetch();
      })
      .catch((error) =>
        error?.networkError?.result?.errors?.forEach(({ message }) => {
          showToast(message, 'error');
        })
      );
  };

  // END SUBMIT MSG

  // ***** CHILDREN LIST

  function useSearchChildren(options: QueryHookOptions<any, any>) {
    const query = gql`
      query ($input: ChildSearchInput!) {
        getSortedChildren(input: $input) {
          pageNumber
          pageSize
          totalRecords
          data {
            firstname
            lastname
            avatar {
              url
            }
            id
            fullName
            accountChildId
            status
            account {
              name
              center {
                name
              }
              status
            }
            contracts {
              class {
                name
              }
            }
          }
        }
      }
    `;
    return useQuery(query, {
      fetchPolicy: 'network-only',
      ...options,
    });
  }

  const { data: childrenPagedResult, loading: childrenLoading } = useSearchChildren({
    variables: {
      input: {
        from: 0,
        size: 10000,
        centerIds: [selectedCenterId ?? ''],
        sort: [{ field: 'name', direction: DIRECTIONS.ASCENDING }],
        searchKey: '',
      },
    },
    skip: !isOpen,
  });

  React.useEffect(() => {
    if (childrenPagedResult && !childrenLoading) {
      setFilteredChildren(childrenPagedResult?.getSortedChildren.data);
    }
  }, [childrenPagedResult, childrenLoading, currentCenterId, selectedCenterId]);

  // ***** END CHILDREN LIST

  React.useEffect(() => {
    setSelectedCenterId(currentCenterId ?? '');
  }, [currentCenterId]);

  return (
    <SideModalDrawer
      title="New Message"
      show={isOpen}
      onHide={dismissModal}
      primaryChoice="Send"
      primaryCallback={handleSubmit}
      primaryButtonProps={{
        disabled: message.trim().length === 0 || (channelId == undefined && selectedChild == null),
        // loading: createAccountLoading,
      }}
      closeOnPrimaryCallback={false}
    >
      <Form>
        {!channelId && (
          <Select
            label={`Select ${t('core.capitalize', { value: t('spelling.center') })}`}
            isLoading={false}
            className="mb-0 flex-grow-0 min-width-200"
            options={centerOptions?.map((c) => ({ value: c.id, label: c.name })) ?? []}
            value={selectedCenterId}
            onChange={(option) => setSelectedCenterId(option.value)}
            // noOptionsMessage={() => `No Active ${fieldLabels.center} Found.`}
          />
        )}
        {!channelId && (
          <Mui.Box sx={{ marginTop: 2 }}>
            <Select
              label="Select Child"
              disabled={childrenLoading}
              isSearchable
              options={filteredChildren ?? []}
              value={selectedChild}
              getOptionValue={(option: IChildWithAccountId) => option.id}
              onChange={(child: IAccountChild) => setSelectedChild(child)}
              getOptionLabel={(option: IChildWithAccountId) => getFullName(option)}
              formatOptionLabel={(option: IChildWithAccountId) => (
                <div className="d-flex align-items-center">
                  <Avatar initials={getInitials(option)} size="sm" image={option.avatar?.url} className="mr-2" />
                  <div>{getFullName(option)}</div>
                </div>
              )}
              className="flex-wrap"
              noOptionsMessage={() => `No children in this ${t('spelling.center')}.`}
            />
          </Mui.Box>
        )}
      </Form>
      <Mui.TextField
        id="standard-multiline-static"
        fullWidth
        multiline
        rows={6}
        // sx={{ marginTop: 1, width: '100%' }}
        label="Message"
        value={message}
        variant="outlined"
        onChange={(e) => setMessage(e?.target?.value ?? '')}
        required
        sx={{
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
        }}
      />
    </SideModalDrawer>
  );
};

export default AddMessageForm;
