import React, { Dispatch, SetStateAction } from 'react';
import { Form, FormLabel } from 'react-bootstrap';
import _uniq from 'lodash/uniq';
import { INewContactWithPermissions } from './AddContactModalForm/AddContactModalForm';

interface IProps {
  permission: IAccountContactPermissionsObject;
  contact?: IContact;
  setContact?: Dispatch<SetStateAction<INewContactWithPermissions>>;
  accountPermissions: AccountPermissions[];
  setAccountPermissions?: Dispatch<SetStateAction<AccountPermissions[]>>;
  setAsPrimary?: boolean;
  hasPaymentMethod?: boolean;
}

const AccountPermissionInputs: React.FC<IProps> = ({
  permission,
  contact,
  setContact,
  accountPermissions,
  setAccountPermissions,
  setAsPrimary,
  hasPaymentMethod,
  ...props
}) => {
  //Multiple account permissions each need a unique radio group name
  const groupName = `billing-permissions-${permission.label}`;
  return (
    <div className="mb-2">
      <FormLabel>{permission.label}</FormLabel>
      <Form.Check>
        <Form.Check.Input
          type="radio"
          id="yes-billing-permissions"
          name={groupName}
          value="yes"
          checked={setAsPrimary ? setAsPrimary : accountPermissions?.includes(permission.value)}
          disabled={setAsPrimary}
          onChange={() => {
            setAccountPermissions &&
              setAccountPermissions((prev) => _uniq<AccountPermissions>([...prev, permission.value]));

            setContact &&
              setContact((prev) => ({
                ...prev,
                accountPermissions: _uniq<AccountPermissions>([...prev.accountPermissions, permission.value]),
              }));
          }}
        />
        <Form.Check.Label>Yes</Form.Check.Label>
      </Form.Check>
      <Form.Check>
        <Form.Check.Input
          type="radio"
          id="no-billing-permissions"
          name={groupName}
          value="no"
          checked={setAsPrimary ? !setAsPrimary : !accountPermissions.includes(permission.value)}
          disabled={setAsPrimary}
          onChange={() => {
            setAccountPermissions && setAccountPermissions((prev) => prev.filter((ap) => ap !== permission.value));

            setContact &&
              setContact((prev) => ({
                ...prev,
                accountPermissions: prev.accountPermissions.filter((ap) => ap !== permission.value),
              }));
          }}
        />
        <Form.Check.Label>
          No
          {permission.value === 'CanPayBill' && hasPaymentMethod && (
            <span>
              <span> - </span>
              <i>Contact payment information will be removed.</i>
            </span>
          )}
        </Form.Check.Label>
      </Form.Check>
    </div>
  );
};

export default AccountPermissionInputs;
