import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { debounce } from 'lodash';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTranslation } from 'react-i18next';
import useDatatableState from 'shared/hooks/useDatatableState';
import useGetActiveCenters, { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  useGetChannelActivity,
  useGetMonthlyMessagesSentStatistics,
  useGetStudentChannels,
} from 'gql/communications/queries';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { SEARCH_EXPRESSIONS, PREDICATES, DIRECTIONS } from 'shared/constants/elastic';
import { useSearchChildren } from 'pages/Families/subroutes/Children/graphql/queries';
import useGetTableMetadata from 'shared/hooks/useGetTableMetadata';
import action from 'shared/constants/enums/actionEnum';
import ChannelsTable from './components/ChannelsTable';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faNote, faNoteSticky, faPlus, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import AddMessageForm from './components/AddMessageForm';
import _ from 'lodash';
import colors from '_colors.scss';
import CenterStatisticsBar from './components/CenterStatisticsBar';

const styles = {
  inputStyles: {
    minWidth: '250px',
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

type CommunicationsTabs = 'announcements' | 'bulletin-board' | 'center-messaging' | 'student-messaging';
interface IUnreadFilterOption {
  label: 'All' | 'Read' | 'Unread';
  value: boolean | undefined;
}

const unreadFilterOptions: IUnreadFilterOption[] = [
  {
    label: 'All',
    value: undefined,
  },
  {
    label: 'Read',
    value: false,
  },
  { label: 'Unread', value: true },
];

const Communications: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const { businessId, centerFilterIds } = useSelector((state: RootState) => state.context);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const centerOptions = useGetActiveCenters().filter((center) => center.entityId === currentBusinessId);
  const center = centerOptions.find((c) => c.id === currentCenterId);
  const [activeTabKey, setActiveTabKey] = useState<CommunicationsTabs>('student-messaging');
  const [showAddMessageModal, setShowAddMessageModal] = useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [from, setFrom] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageRecordSize, setPageRecordSize] = React.useState(100);
  const [hasUnreadMessages, setHasUnreadMessages] = React.useState<boolean | undefined>();
  const [hasUnresolvedFlaggedMessages, setHasUnresolvedFlaggedMessages] = React.useState<boolean>(false);

  // ******* List of Channels Table Stuff
  const {
    data: channelListData,
    loading: channelListDataLoading,
    refetch: refetchChannelListData,
  } = useGetStudentChannels({
    variables: {
      businessId: currentBusinessId ?? '',
      centerId: currentCenterId ?? '',
      searchTerm: searchQuery,
      pageSize: pageRecordSize,
      pageNumber: from,
      hasUnreadMessages: hasUnreadMessages,
      hasUnresolvedFlags: hasUnresolvedFlaggedMessages == true ? true : undefined,
    },
    skip:
      businessId == null ||
      businessId === '' ||
      currentCenterId == null ||
      currentCenterId === '' ||
      currentCenterId.length === 0,
  });

  React.useEffect(() => {
    updateSearchValue(searchInput);
  }, [searchInput]);

  const updateSearchValue = React.useCallback(
    _.debounce((input: string) => {
      setSearchQuery(input);
      setFrom(0);
      setCurrentPage(0);
    }, 500),
    []
  );

  React.useEffect(() => {
    setFrom(0);
    setCurrentPage(0);
  }, [currentCenterId]);

  // ******* End List of Channels Table Stuff

  const resetPage = () => {
    setShowAddMessageModal(false);
    setFrom(0);
    setCurrentPage(0);
    setSearchInput('');
    setHasUnreadMessages(undefined);
    setHasUnresolvedFlaggedMessages(false);
    refetchChannelListData();
  };

  return (
    <PageWrapper
      pageTitle="Student Messaging"
      applyPadding={true}
      mobileButtonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Comms, permission: PermissionType.StudentMessaging, level: RoleLevelType.Create }}
        >
          <CirclePlusButton
            disabled={!center}
            variant="primary"
            className="mt-4 mb-4"
            onClick={() => setShowAddMessageModal(true)}
          />
        </HasRoleAreaLevel>
      }
      buttonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Comms, permission: PermissionType.StudentMessaging, level: RoleLevelType.Create }}
        >
          <CreateButton disabled={!center} onClick={() => setShowAddMessageModal(true)}>
            Add Message
          </CreateButton>
        </HasRoleAreaLevel>
      }
    >
      <CenterSelectBanner pageName="communications" />
      {/* TAB NAVIGATION */}
      {/* <Tabs defaultActiveKey={activeTabKey} id="communications-tabs">
        <Tab eventKey="announcements" title="Announcements"></Tab>
        <Tab eventKey="bulletin-board" title="Bulletin Board"></Tab>
        <Tab eventKey="center-messaging" title="Center Messaging"></Tab>
        <Tab eventKey="student-messaging" title="Student Messaging"></Tab>
      </Tabs> */}
      <CenterStatisticsBar
        currentBusinessId={businessId ?? ''}
        currentCenterId={currentCenterId ?? ''}
        hasUnreadMessages={hasUnreadMessages}
        setHasUnreadMessages={setHasUnreadMessages}
        hasUnresolvedFlaggedMessages={hasUnresolvedFlaggedMessages}
        setHasUnresolvedFlaggedMessages={setHasUnresolvedFlaggedMessages}
      />
      {/* MY TABLE */}
      <Mui.Box>
        {!channelListData && !channelListDataLoading ? (
          <Mui.Stack direction="column" spacing={2} alignItems="center" marginTop={4}>
            <FontAwesomeIcon icon={faNoteSticky} size="4x" />
            <Mui.Typography>There are no Messages available to display.</Mui.Typography>
          </Mui.Stack>
        ) : (
          <>
            <Mui.Card sx={{ marginTop: '24px' }} elevation={0}>
              <Mui.CardContent sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
                <Mui.Stack direction={{ sm: 'column', md: 'row' }} spacing={2} justifyContent="space-between">
                  <Mui.TextField
                    variant="outlined"
                    value={searchInput}
                    onChange={(evt) => setSearchInput(evt.target.value)}
                    InputProps={{
                      startAdornment: (
                        <Mui.InputAdornment position="start">
                          <FontAwesomeIcon size="xs" icon={faSearch} />
                        </Mui.InputAdornment>
                      ),
                      endAdornment: (
                        <Mui.InputAdornment position="end">
                          <Mui.IconButton onClick={() => setSearchInput('')}>
                            <FontAwesomeIcon size="xs" icon={faTimes} />
                          </Mui.IconButton>
                        </Mui.InputAdornment>
                      ),
                    }}
                    inputProps={{
                      'aria-label': 'Search',
                    }}
                    placeholder="Search"
                    size="small"
                    sx={{
                      minWidth: '400px',
                      '& legend': {
                        width: 0,
                      },
                    }}
                  />
                  <Mui.Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="space-between">
                    <Mui.FormControlLabel
                      control={<Mui.Checkbox />}
                      onChange={(evt, value) => setHasUnresolvedFlaggedMessages(value)}
                      checked={hasUnresolvedFlaggedMessages}
                      label="Show Unresolved Flags"
                    />
                    <Mui.Autocomplete
                      disableClearable
                      size="small"
                      aria-label={`unread-filter`}
                      placeholder="Read Status"
                      options={unreadFilterOptions}
                      onChange={(evt, value) => setHasUnreadMessages(value?.value ?? undefined)}
                      renderInput={(params) => <Mui.TextField {...params} label="Read Status" />}
                      sx={styles.inputStyles}
                      value={unreadFilterOptions.find((x) => x.value === hasUnreadMessages) ?? undefined}
                    />
                  </Mui.Stack>
                </Mui.Stack>
              </Mui.CardContent>
            </Mui.Card>
            <ChannelsTable
              channelList={channelListData?.getStudentChannels?.data ?? []}
              channelListLoading={channelListDataLoading}
              centerId={center?.id ?? ''}
              // refetch={refetch}
              from={from}
              setFrom={setFrom}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageRecordSize={pageRecordSize}
              setPageRecordSize={setPageRecordSize}
              totalItems={channelListData?.getStudentChannels?.totalRecords ?? 0}
              // nameSortDirection={nameSortDirection}
              // setNameSortDirection={setNameSortDirection}
            />
          </>
        )}
      </Mui.Box>
      <AddMessageForm
        isOpen={showAddMessageModal}
        onClose={resetPage}
        centerOptions={centerOptions}
        currentCenterId={center?.id ?? ''}
        businessId={businessId}
      />
    </PageWrapper>
  );
};

export default Communications;
