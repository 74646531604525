import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { RootState } from 'store/reducers';
import { uniq, uniqBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import Button from 'shared/components/Buttons';
import Checkbox from 'shared/components/Checkbox';
import DateInput from 'shared/components/DateInput';
import { stringToHsl } from 'shared/util/string';
import { ISCasePaymentTypes } from 'shared/constants/enums/iSCaseClaimEnum';
import { useGetChildAbsences } from 'gql/session/queries';
import { VerticalDivider } from 'shared/components/Dividers';
import StaffSelectInput from 'shared/components/StaffSelectInput/StaffSelectInput';
import Select from 'shared/components/Select';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import CaseDayFields from './CaseDayFields';
import TemporaryEducatorFields from './TemporaryEducatorFields';

import {
  addTempEducatorAction,
  deleteTempEducatorAction,
  resetClaimForm,
  setFieldValueAction,
  setFormValuesAction,
  updateDaysOfCareAction,
  updateDaysOfCareF2FHours,
  updateDaysOfCareNF2FHours,
  updateEducatorNonF2FHoursAction,
  updateEducatorF2FHoursAction,
  updateEducatorsAction,
  updateTempEducatorNonF2FHoursAction,
  updateTempEducatorF2FHoursAction,
} from './duck/actions';

import { formatEducatorsPerDay, getCareDate, getPopulatedCaseDayClaims, isWeekEndingInvalid } from './utils';

import './createISCaseClaimForm.scss';
import { Col, Row } from 'shared/components/Layout';

type TotalProps = {
  caseDayClaims: ICaseDayClaim[];
  educatorHoursPerDay: IDayHoursPerEducator;
  tempEducatorHoursPerDay: IDayHoursPerEducator;
};

const TotalEducatorSection = (props: TotalProps) => {
  const totalData = useMemo(() => {
    const educatorHoursPerDay = Object.values(props.educatorHoursPerDay);
    const tempEducatorHoursPerDay = Object.values(props.tempEducatorHoursPerDay);
    let aDayExceeds = false;
    let aDayUnder = false;
    const content = props.caseDayClaims.map((day) => {
      const hours = educatorHoursPerDay.reduce(
        (acc, educator) =>
          acc + (educator?.[day.careDay]?.nonFaceToFaceHours || 0) + (educator?.[day.careDay]?.faceToFaceHours || 0),
        0
      );
      const tempHours = tempEducatorHoursPerDay.reduce(
        (acc, educator) =>
          acc + (educator?.[day.careDay]?.nonFaceToFaceHours || 0) + (educator?.[day.careDay]?.faceToFaceHours || 0),
        0
      );
      const totalChildHours = (day?.nonFaceToFaceHours || 0) + (day?.faceToFaceHours || 0);
      const totalHours = hours + tempHours;
      const doesExceed = totalChildHours - totalHours < 0;
      if (doesExceed) aDayExceeds = true;
      const isUnder = totalChildHours - totalHours > 0;
      if (isUnder) aDayUnder = true;

      return (
        <div key={`Sum_${day.careDay}`} className="d-flex educatorTotalColumn">
          <div className={'educatorColumnLabel educatorHoursInput'}>
            <label className={doesExceed || isUnder ? 'text-danger' : ''}>
              {day.careDay}
              {(doesExceed || isUnder) && <sup>*</sup>}
            </label>
          </div>
          <div className={`educatorHoursInput ${doesExceed || isUnder ? 'text-danger' : ''}`}>{totalHours}</div>
        </div>
      );
    });

    return { content, aDayExceeds, aDayUnder };
  }, [props.educatorHoursPerDay, props.tempEducatorHoursPerDay, props.caseDayClaims]);

  const getOverUnderText = () => {
    if (totalData.aDayExceeds && !totalData.aDayUnder) {
      return '* Daily total exceeded';
    } else if (totalData.aDayUnder && !totalData.aDayExceeds) {
      return '* Below Daily Total';
    } else if (totalData.aDayUnder && totalData.aDayExceeds) {
      return '* Hours do not match Daily Total';
    }
  };

  return (
    <>
      <hr className="mt-5 mb-5" />
      <Row>
        <h5 className="mb-5">Total Hours</h5>
      </Row>
      <Row>
        <Col>
          <div className="educatorTotals mb-5">{totalData.content}</div>
        </Col>
      </Row>
      {(totalData.aDayUnder || totalData.aDayExceeds) && (
        <Row justify="end">
          <p className="text-danger small">{getOverUnderText()}</p>
        </Row>
      )}
    </>
  );
};

interface IProps {
  iSCase: IISCase;
  educators: IStaff[];
  businessId: string;
  handleCreateClaim: (data: ISCaseClaimCreateInput) => void;
  handleClose: () => void;
  loading: boolean;
}
const CreateISCaseClaimForm: React.FC<IProps> = ({
  iSCase,
  educators,
  loading,
  businessId,
  handleCreateClaim,
  handleClose,
}) => {
  const [showAddTolerance, setAddTolerance] = useState(false);
  const {
    formValues: values,
    educatorHoursPerDay,
    tempEducatorHoursPerDay,
  } = useSelector((state: RootState) => state.isCaseClaim);
  const dispatch = useDispatch();

  useEffect(() => {
    const uniqPaymentTypes = uniqBy(iSCase.daysOfCare, (day) => day.paymentType);
    const paymentType = uniqPaymentTypes.length === 1 ? uniqPaymentTypes[0].paymentType : undefined;
    const initialValues: ISCaseClaimCreate = {
      children: uniqBy(iSCase.children, (c) => c.childId),
      id: iSCase.id,
      caseId: iSCase.caseId,
      daysOfCare: iSCase.daysOfCare,
      educatorDays: [],
      temporaryEducators: [],
      educators: [],
      agreed: false,
      paymentType: uniqPaymentTypes.length === 1 ? uniqPaymentTypes[0].paymentType : undefined,
      weekEnding: moment().day(0).format('YYYY-MM-DD'),
      caseDayClaims: iSCase.daysOfCare
        .filter((day) => day.paymentType === paymentType)
        .map((day) => ({
          careDay: day.dayOfCare,
          faceToFaceHours: 0,
          nonFaceToFaceHours: 0,
        })),
      educatorClaims: [],
      temporaryEducatorClaims: [],
    };
    dispatch(setFormValuesAction(initialValues));

    return () => {
      dispatch(resetClaimForm());
    };
  }, [iSCase, dispatch]);

  const { weekEnding, agreed, paymentType, temporaryEducators } = values;

  const isValid =
    !isWeekEndingInvalid(moment(weekEnding), moment(iSCase.startDate), moment(iSCase.endDate)) &&
    agreed &&
    paymentType &&
    values.educators.length + temporaryEducators.length > 0 &&
    !temporaryEducators.some((temp) => temp.firstName === '' || temp.lastName === '');

  const prefillDaysOfCare = (absences: IAbsence[]) => {
    const newCaseDayClaims = getPopulatedCaseDayClaims(iSCase, absences, values);
    handleChange(newCaseDayClaims, 'caseDayClaims');
  };

  const { data: absences } = useGetChildAbsences({
    variables: {
      input: {
        accountChildren: iSCase.enrolments
          .filter(({ childId }) => values.children?.find((child) => child.childId === childId))
          .map((child) => child.accountChildId),
        startDate: moment(weekEnding).day(-6).format('YYYY-MM-DD'),
        endDate: moment(weekEnding).format('YYYY-MM-DD'),
      },
    },
    skip: weekEnding === '' || !Boolean(values.children) || values.children.length === 0,
    onCompleted: (result) => {
      prefillDaysOfCare(result.getAccountChildrenAbsencesForTimeFrame);
    },
  });

  useEffect(() => {
    prefillDaysOfCare(absences?.getAccountChildrenAbsencesForTimeFrame ?? []);
  }, [values.paymentType]);

  const revertDaysOfCare = () => {
    const originalDays = iSCase.daysOfCare
      .filter((day) => day.paymentType === paymentType)
      .map((day, index) => {
        const caseDayClaim = values.caseDayClaims[index];
        return { ...caseDayClaim, careDay: day.dayOfCare };
      });
    dispatch(setFieldValueAction(originalDays, 'caseDayClaims'));
    setAddTolerance(false);
  };

  const handleDayOfCareHoursChange = (value: number, name: string, index: number) => {
    if (name.match(/^faceToFaceHours/i)) {
      dispatch(updateDaysOfCareF2FHours(index, value));
    } else if (name.match(/^nonFaceToFaceHours/i)) {
      dispatch(updateDaysOfCareNF2FHours(index, value));
    }
  };

  const handleChange = (value: any, name: any) => {
    dispatch(setFieldValueAction(value, name));
  };

  const handleRemoveEducator = (index: number) => {
    dispatch(deleteTempEducatorAction(index));
  };

  const handleAddEducator = () => {
    dispatch(addTempEducatorAction());
  };

  const handleTempEducatorNonF2FHours = (value: number, educatorId: string, day: DayOfCare) =>
    dispatch(updateTempEducatorNonF2FHoursAction(educatorId, day, value));
  const handleTempEducatorF2FHours = (value: number, educatorId: string, day: DayOfCare) =>
    dispatch(updateTempEducatorF2FHoursAction(educatorId, day, value));

  const handleSubmit = () => {
    const { id, centerId, children } = iSCase;
    const { weekEnding, agreed, paymentType, caseDayClaims, educators, temporaryEducators } = values;

    const educatorDays = formatEducatorsPerDay(educators, educatorHoursPerDay, weekEnding, caseDayClaims);
    const tempEducatorDays = formatEducatorsPerDay(
      temporaryEducators,
      tempEducatorHoursPerDay,
      weekEnding,
      caseDayClaims
    );
    const educatorClaims = [...educatorDays, ...tempEducatorDays];

    // validate input
    if (!isValid) {
      return;
    }

    // build input
    const data: ISCaseClaimCreateInput = {
      caseId: id,
      businessId,
      centerId,
      paymentType,
      weekEnding: moment(weekEnding).format('YYYY-MM-DD'),
      isAdditionalEducatorDeclarationGiven: agreed,
      caseDayClaims: caseDayClaims
        .filter((d) => d.faceToFaceHours !== 0 || d.nonFaceToFaceHours !== 0)
        .map((dayClaims) => ({
          careDate: getCareDate(dayClaims.careDay, weekEnding),
          faceToFaceHours: dayClaims.faceToFaceHours,
          nonFaceToFaceHours: dayClaims.nonFaceToFaceHours,
        })),
      educatorClaims,
      children: uniq(children.map(({ childId }) => childId)),
    };
    handleCreateClaim(data);
  };

  const hasNonF2FHours = values.caseDayClaims.filter((item) => item.nonFaceToFaceHours > 0).length > 0;
  const getColumnDays = () => 'auto ' + Array(values.caseDayClaims.length + 1).join('82px ');

  return (
    <div className="create-is-case-container">
      <form>
        <div className="create-is-case-fields-container">
          <p>Complete all fields below to file an IS Case Claim.</p>
          <Row align="start">
            <Col lg={3}>
              <TextInput required name="approval-id" label="Approval ID" disabled value={iSCase.approvalId}></TextInput>
            </Col>
            <Col lg={3}>
              <TextInput required name="case-id" label="Case ID" disabled value={iSCase?.caseId || ''}></TextInput>
            </Col>
            <Col lg={3}>
              <Select
                required
                isInvalid={!Boolean(values.paymentType)}
                showRequiredIcon={false}
                className="payment-type-select"
                label="Payment Type"
                value={values.paymentType}
                options={ISCasePaymentTypes}
                name="paymentType"
                onChange={handleChange}
              />
            </Col>
            <Col lg={3}>
              <div className="d-flex flex-column">
                <label>Week Ending</label>
                <DateInput
                  required={true}
                  isValid={
                    weekEnding !== '' &&
                    !isWeekEndingInvalid(moment(weekEnding), moment(iSCase.startDate), moment(iSCase.endDate))
                  }
                  date={weekEnding}
                  className="week-ending-input"
                  onDateSelect={(date: string) => {
                    handleChange(date != null ? moment(date).format('YYYY-MM-DD') : null, 'weekEnding');
                  }}
                  disabled={false}
                  isOutsideRange={(day: moment.Moment) =>
                    isWeekEndingInvalid(day, moment(iSCase.startDate), moment(iSCase.endDate))
                  }
                />
              </div>
            </Col>
          </Row>
          <hr />
          <Row align="start" className="mt-4">
            <Col sm={12} md={12} lg={4}>
              <h5 className="mb-5">Child Information</h5>
              <div className="mb-5">
                <Select
                  className="mb-1"
                  label="Children"
                  value={values?.children || []}
                  options={uniqBy(iSCase?.children, ({ childId }) => childId) || []}
                  getOptionLabel={({ child }) => (child ? `${child.firstname} ${child.lastname}` : '')}
                  isMulti
                  name="children"
                  showRequiredIcon={false}
                  required
                  isInvalid={!values.children || values.children.length === 0}
                  errorText="Must select at least 1 child"
                  getOptionValue={(c: ISCaseClaimChildDayOfCare) => c.childId}
                  onChange={(value: ISCaseClaimChildDayOfCare[], name) => {
                    handleChange(value, name);
                  }}
                />
              </div>
              <CaseDayFields
                caseDayClaims={values.caseDayClaims}
                handleChangeDay={(index: number, value: DayOfCare) => dispatch(updateDaysOfCareAction(index, value))}
                handleDayOfCareHoursChange={handleDayOfCareHoursChange}
                revertDaysOfCare={revertDaysOfCare}
                setAddTolerance={setAddTolerance}
                showAddTolerance={showAddTolerance}
              />
            </Col>
            <VerticalDivider />
            <Col>
              <Row>
                <h5 className="mb-5">Educator Information</h5>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="mb-12">
                    <StaffSelectInput
                      centerId={iSCase.centerId || ''}
                      label="Add Educator from Centre"
                      value={values.educators}
                      showAvatar={false}
                      onChange={(values: IBasicStaff[]) => {
                        dispatch(updateEducatorsAction(values));
                      }}
                    />
                  </div>
                </Col>
              </Row>

              {values.educators.length > 0 && (
                <div className="educatorCards justify-content-end mb-5">
                  {values.educators.map((educator, index) => {
                    return (
                      <div
                        key={`Educator_${educator.id}_${index}`}
                        className="educatorCard"
                        style={{ gridTemplateColumns: getColumnDays() }}
                      >
                        <AvatarDataTableCell
                          key={educator.id}
                          size="sm"
                          className="educator-avatar"
                          color={stringToHsl(educator.id)}
                          initials={`${educator.firstname[0]}  ${educator.lastname[0]}`}
                          avatar={educator?.avatar?.url || ''}
                          primaryText={`${educator.firstname} ${educator.lastname}`}
                        />
                        {values.caseDayClaims.map((day) => (
                          <div
                            key={`Label_${educator.id}_${day.careDay}`}
                            className="educatorColumnLabel educatorHoursInput"
                          >
                            <label>{day.careDay}</label>
                          </div>
                        ))}
                        <div className="hoursType">
                          <label>F2F Hours</label>
                        </div>
                        {values.caseDayClaims.map((day) => {
                          const faceToFaceHours = educatorHoursPerDay[educator.id]?.[day.careDay]?.faceToFaceHours ?? 0;
                          return (
                            <div key={`f2f_${educator.id}_${day.careDay}`} className="educatorHoursInput">
                              <NumberInput
                                key={educator.id}
                                name="educator-day-f2f-hours"
                                min="0"
                                max="24"
                                value={faceToFaceHours}
                                onChange={(value) => {
                                  dispatch(updateEducatorF2FHoursAction(educator.id, day.careDay, value));
                                }}
                              />
                            </div>
                          );
                        })}
                        {hasNonF2FHours && (
                          <>
                            <div className="hoursType">
                              <label>Non-F2F Hours</label>
                            </div>
                            {values.caseDayClaims.map((day) => {
                              const nonFaceToFaceHours =
                                educatorHoursPerDay[educator.id]?.[day.careDay]?.nonFaceToFaceHours ?? 0;
                              return (
                                <div key={`non_f2f_${educator.id}_${day.careDay}`} className="educatorHoursInput">
                                  <NumberInput
                                    key={educator.id}
                                    name="educator-day-non-f2f-hours"
                                    min="0"
                                    max="24"
                                    value={nonFaceToFaceHours}
                                    onChange={(value) => {
                                      dispatch(updateEducatorNonF2FHoursAction(educator.id, day.careDay, value));
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}

              {temporaryEducators?.length > 0 && (
                <TemporaryEducatorFields
                  temporaryEducators={temporaryEducators}
                  caseDayClaims={values.caseDayClaims}
                  hasNonF2FHours={hasNonF2FHours}
                  handleRemoveEducator={handleRemoveEducator}
                  handleTempEducatorNonF2FHours={handleTempEducatorNonF2FHours}
                  handleTempEducatorF2FHours={handleTempEducatorF2FHours}
                  educatorHoursPerDay={tempEducatorHoursPerDay}
                  handleChange={handleChange}
                />
              )}
              <Row>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleAddEducator();
                  }}
                >
                  Add Temporary Educator
                </Button>
              </Row>

              {(values.educators.length > 0 || temporaryEducators?.length > 0) && (
                <TotalEducatorSection
                  educatorHoursPerDay={educatorHoursPerDay}
                  tempEducatorHoursPerDay={tempEducatorHoursPerDay}
                  caseDayClaims={values.caseDayClaims}
                />
              )}

              <Row className="mt-4 ml-1">
                <Checkbox
                  value={values.agreed}
                  onChange={(value) => handleChange(value, 'agreed')}
                  label="I declare that the Additional Educator/s listed were engaged to increase the staff to child ratio above that required by state or territory licensing and regulatory requirements."
                />
              </Row>
            </Col>
          </Row>
        </div>
      </form>
      <div className="d-flex justify-content-end" style={{ padding: '30px 20px 0' }}>
        <Button variant="light" className="mr-2" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!isValid} loading={loading}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CreateISCaseClaimForm;
