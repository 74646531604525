import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { RootState } from 'store/reducers';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ActionDropdown from 'shared/components/ActionDropdown';
import { CreateButton } from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';
import { RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useGetKindyForAllCenterAgreements } from 'gql/kindyForAll/queries';
import CreateEditKindyForAllCentreAgreementModal from '../CreateEditKindyForAllCentreAgreementModal/CreateEditKindyForAllCentreAgreementModal';
import { IKindyForAllCenterAgreement } from 'shared/types/kindyForAll';

interface IProps {}

interface ISort {
  direction: ElasticsearchSortDirection;
  field: string;
}
const KindyForAllCenterAgreementsTab: React.FC<IProps> = ({ ...props }) => {
  const { businessId } = useSelector((state: RootState) => state.context);
  const [createEditKindyForAllCenterAgreementsModalOpen, setCreateEditKindyForAllCenterAgreementsModalOpen] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCenterAgreement, setSelectedCenterAgreement] = useState<IKindyForAllCenterAgreement | undefined>();
  const [sort, setSort] = useState<ISort>({ field: 'centerName', direction: 'ASCENDING' });
  const { t } = useTranslation(['translation', 'subsidies']);
  const [tableState, tableFunctions] = useDatatableState();

  const shouldSkip = businessId == null || businessId === '';

  const {
    data: data,
    loading,
    refetch,
  } = useGetKindyForAllCenterAgreements({
    variables: {
      input: {
        businessId: businessId ?? '',
        searchKey: searchTerm,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortBy: sort.field,
        sortDirection: sort.direction,
      },
    },
    skip: shouldSkip,
  });

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSort({ field, direction });
  }, []);

  const handleEdit = (centerAgreement: IKindyForAllCenterAgreement) => {
    setSelectedCenterAgreement(centerAgreement);
    setCreateEditKindyForAllCenterAgreementsModalOpen(true);
  };

  const getTableColumns = useCallback((): any[] => {
    const tableColumns: any = [
      {
        dataField: 'centerName',
        text: 'Centre',
        sort: true,
      },
      {
        dataField: 'agreementNumber',
        text: 'Reference Number',
        sort: true,
      },
      {
        dataField: 'beneficiaryAccountNumber',
        text: 'Beneficiary Number',
        sort: true,
      },
    ];

    tableColumns.push({
      text: '',
      dataField: '',
      align: 'center',
      headerAlign: 'center',
      formatter: (cell: any, row: IKindyForAllCenterAgreement) => (
        <div className="d-flex justify-content-center">
          <ActionDropdown
            actions={[
              {
                label: 'Edit',
                onClick: () => handleEdit(row),
              },
            ]}
          />
        </div>
      ),
    });

    return tableColumns;
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <CreateEditKindyForAllCentreAgreementModal
        isOpen={createEditKindyForAllCenterAgreementsModalOpen}
        centerAgreement={selectedCenterAgreement}
        centerAgreements={data?.getKindyForAllCenterAgreements.data ?? []}
        businessId={businessId ?? ''}
        onClose={() => {
          setCreateEditKindyForAllCenterAgreementsModalOpen(false);
          setSelectedCenterAgreement(undefined);
          if (!shouldSkip) refetch();
        }}
      />

      <DataTable
        data={data?.getKindyForAllCenterAgreements.data ?? []}
        columns={getTableColumns()}
        dataSize={data?.getKindyForAllCenterAgreements?.totalRecords ?? 0}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        showLoadingOverlay={loading}
        showSelect={false}
        onSort={handleSort}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        renderHeader={(paginationProps) => (
          <>
            <TableHeader className="flex-wrap align-items-center">
              <div className="d-flex flex-wrap mr-auto align-items-center">
                <SizePerPage paginationProps={paginationProps} />
                <TableSearch placeholder="Search" onChange={setSearchTerm} className={isMobile ? 'mt-2 mb-1' : ''} />
              </div>
              <div className="d-flex flex-wrap align-items-center">
                <div className="d-flex flex-direction-row align-items-center m-0 flex-wrap">
                  <CreateButton
                    onClick={() => setCreateEditKindyForAllCenterAgreementsModalOpen(true)}
                    permission={
                      { permission: 'Base', level: RoleLevelType.Create, area: 'Business' } as IAreaPermissionLevel
                    }
                  >
                    {t('subsidies:add-kindy-funding-numbers')}
                  </CreateButton>
                </div>
              </div>
            </TableHeader>
          </>
        )}
      />
    </>
  );
};

export default KindyForAllCenterAgreementsTab;
