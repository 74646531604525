import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { photoFields } from './fields';

interface IUpdatePhotoNote {
  updatePhotoNote: IPhoto;
}

interface IUpdatePhotoNoteInput {
  input: {
    id: string;
    note: string | undefined;
  };
}

interface IEvaluatePhotoInput {
  input: {
    galleryPhotoIds: string[];
  };
}

interface IAlterChildTag {
  input: {
    photoId: string;
    childId: string;
  };
}

export interface ICreatePhotoInput {
  staffId: string;
  childIds: string[];
  photoKey: string;
  note: string;
  classId: string;
  centerId: string;
  takenOn: string;
  isVideo: boolean;
}

export const UPDATE_PHOTO_NOTE = gql`
  mutation ($input: UpdatePhotoNoteInput!) {
    updatePhotoNote(input: $input) {
      id
      note
    }
  }
`;

export const CREATE_PHOTO = gql`
  mutation($input: CreatePhotoInput!) {
    createPhoto(input: $input){
       ${photoFields}
    }
  }
`;

export const APPROVE_PHOTOS = gql`
  mutation ($input: IEvaluatePhotoInput!) {
    approvePhotos(input: $input) {
      id
    }
  }
`;

export const DENY_PHOTOS = gql`
  mutation ($input: IEvaluatePhotoInput!) {
    denyPhotos(input: $input) {
      id
    }
  }
`;

export const REMOVE_CHILD_TAG = gql`
  mutation($input: AlterChildTag!) {
    removeChildTag(input: $input) {
      ${photoFields}
    }
  }
`;

export const ADD_CHILD_TAG = gql`
  mutation($input: AlterChildTag!) {
    addChildTag(input: $input) {
      ${photoFields}
    }
  }
`;

export const useUpdatePhotoNote = (input?: MutationHookOptions<IUpdatePhotoNote, IUpdatePhotoNoteInput>) =>
  useMutation<IUpdatePhotoNote, IUpdatePhotoNoteInput>(UPDATE_PHOTO_NOTE, input);

export const useCreatePhoto = (
  input?: MutationHookOptions<
    {
      createPhoto: IPhoto;
    },
    { input: ICreatePhotoInput }
  >
) =>
  useMutation<
    {
      createPhoto: IPhoto;
    },
    { input: ICreatePhotoInput }
  >(CREATE_PHOTO, input);

export const useApprovePhotos = (input?: MutationHookOptions<{ approvePhotos: IPhoto[] }, IEvaluatePhotoInput>) =>
  useMutation<{ approvePhotos: IPhoto[] }, IEvaluatePhotoInput>(APPROVE_PHOTOS, input);

export const useDenyPhotos = (input?: MutationHookOptions<{ denyPhotos: IPhoto[] }, IEvaluatePhotoInput>) =>
  useMutation<{ denyPhotos: IPhoto[] }, IEvaluatePhotoInput>(DENY_PHOTOS, input);

export const useRemoveChildTag = (input?: MutationHookOptions<{ removeChildTag: IPhoto }, IAlterChildTag>) =>
  useMutation<{ removeChildTag: IPhoto }, IAlterChildTag>(REMOVE_CHILD_TAG, input);

export const useAddChildTag = (input?: MutationHookOptions<{ addChildTag: IPhoto }, IAlterChildTag>) =>
  useMutation<{ addChildTag: IPhoto }, IAlterChildTag>(ADD_CHILD_TAG, input);
