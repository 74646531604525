import React, { useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useGetAllowedEntities } from 'gql/business/queries';
import { RootState } from 'store/reducers';
import { useCreateBusiness } from './graphql/mutations';
import EntityProfilesTable from './components/EntityProfilesTable';
import PageWrapper from 'shared/components/PageWrapper';
import { CreateButton, CirclePlusButton } from 'shared/components/Buttons';
import { getBusinessesSuccess } from 'pages/Businesses/subroutes/BusinessProfiles/duck/actions';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable';
import NewBusinessModalForm from './components/NewBusinessModalForm';
import { showToast } from 'shared/components/Toast';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import errorMessages from 'shared/constants/errorMessages';
import { GET_ALLOWED_ENTITIES } from './graphql/fields';

interface IRouteProps {
  autoOpenSideModal?: boolean;
  id?: any;
}

interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const BusinessProfiles: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();

  const autoOpenSideModal = props.location.state && props.location.state.autoOpenSideModal;
  const [showNewEntityModal, setShowNewEntityForm] = useState(autoOpenSideModal || false);
  const businesses = useSelector((state: RootState) => state.businesses.all);

  const { loading, error, networkStatus } = useGetAllowedEntities(
    {
      onCompleted: (data) => {
        dispatch(getBusinessesSuccess(data.getAllowedEntities ?? []));
      },
    },
    GET_ALLOWED_ENTITIES
  );
  const [createNewBusinessFn, { loading: createBusinessLoading }] = useCreateBusiness();

  const toggleNewEntityForm = useCallback(() => setShowNewEntityForm(!showNewEntityModal), [showNewEntityModal]);

  const navigateToEntityProfile = useCallback((id) => props.history.push(`/businesses/${id}`), [props.history]);

  return (
    <PageWrapper
      pageTitle="Business Profiles"
      mobileButtonComponent={<CirclePlusButton variant="primary" className="mt-4 mb-4" onClick={toggleNewEntityForm} />}
      buttonComponent={
        <CreateButton className="my-2" onClick={toggleNewEntityForm}>
          Add Business
        </CreateButton>
      }
      applyPadding={false}
    >
      <div>
        {loading && networkStatus === NETWORK_STATUS.LOADING ? (
          <PageWrapperBody>
            <DataTableLoadingSkeleton />
          </PageWrapperBody>
        ) : (
          <EntityProfilesTable
            handleRowClick={(event, rowData) => navigateToEntityProfile(rowData.id)}
            data={businesses}
            error={error}
          />
        )}
        {showNewEntityModal && (
          <NewBusinessModalForm
            isOpen={showNewEntityModal}
            onClose={toggleNewEntityForm}
            loading={createBusinessLoading}
            onSubmit={(business) =>
              createNewBusinessFn({
                variables: {
                  input: {
                    name: business.name,
                    phoneNumber: business.phoneNumber,
                    accountOwnerFirstName: business.accountOwnerFirstName,
                    accountOwnerLastName: business.accountOwnerLastName,
                    accountOwnerEmail: business.accountOwnerEmail,
                    address: business.address,
                    timezone: business.timezone,
                    dataType: business.dataType as DataType,
                  },
                },
              })
                .then((newBusiness) => {
                  if (newBusiness && newBusiness.data) {
                    showToast('Business created successfully.', 'success');
                    setShowNewEntityForm(false);
                  } else {
                    showToast('Something went wrong', 'error');
                  }
                })
                .catch((err) => {
                  showToast(
                    err.message === 'Email in use.' ? errorMessages.changeEmailInUse : 'There was an error.',
                    'error'
                  );
                })
            }
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default BusinessProfiles;
