import { Link } from 'react-router-dom';
import React from 'react';

/*
THIS IS ON GOING WORK. Not finalised
 */

export enum ColumnType {
  ACCOUNT_CCS,
  ACCOUNT,
}

interface DataTableColumn<T> {
  render(data: T): string | JSX.Element | undefined;
}

type AccountRenderable = {
  accountName?: string;
  accountId?: string;
};
export class AccountDataTableColumn implements DataTableColumn<AccountRenderable> {
  private property: string;
  private type: ColumnType;

  private getRoutePath(type: ColumnType, id?: string): string {
    switch (type) {
      case ColumnType.ACCOUNT_CCS:
        return `/families/accounts/${id}/ccs`;
      case ColumnType.ACCOUNT:
        return `/families/accounts/${id}/profile`;
    }

    throw new Error(`no route is found for ${type}`);
  }

  constructor(property: string, type: ColumnType) {
    this.property = property;
    this.type = type;
  }

  render(data: AccountRenderable): string | JSX.Element {
    let cellValue = data.accountName;
    return cellValue ? <Link to={`${this.getRoutePath(this.type, data.accountId)}`}>{cellValue}</Link> : 'Unknown';
  }
}

class StringDataTableColumn implements DataTableColumn<any> {
  private property: string;

  constructor(property: string) {
    this.property = property;
  }

  render(data: any): string | JSX.Element {
    return data[this.property];
  }
}

export class DataTableColumnFactory {
  static getColumn<T>(property: string, type: ColumnType): DataTableColumn<T> {
    switch (type) {
      case ColumnType.ACCOUNT:
      case ColumnType.ACCOUNT_CCS: {
        // @ts-ignore
        return new AccountDataTableColumn(property, type);
        break;
      }
    }

    return new StringDataTableColumn(property);
  }
}
