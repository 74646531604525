import React from 'react';

interface IProps {
  className?: string;
  mx?: number;
  my?: number;
  style?: React.CSSProperties;
}

const VerticalDivider: React.FC<IProps> = ({ className, mx = 4, my = 2, style = {} }) => {
  return (
    <div
      style={{ minWidth: '1.1px', ...style }}
      className={`${className} mx-${mx} my-${my} vertical-divider align-self-stretch`}
    ></div>
  );
};

export default VerticalDivider;
