import {
  TimezoneActionTypes,
  CREATE_CENTER_TIMEZONE_HASH,
  ADD_CENTER_TO_TIMEZONE_HASH,
  CREATE_BUSINESS_TIMEZONE_HASH,
} from './types';

export interface ITimezoneStateShape {
  byCenterId: Record<string, Timezone>; // key center id, value timezone string
  byBusinessId: Record<string, Timezone>;
}

const initialState: ITimezoneStateShape = {
  byCenterId: {},
  byBusinessId: {},
};

export const timezoneReducers = (
  state: ITimezoneStateShape = initialState,
  action: TimezoneActionTypes
): ITimezoneStateShape => {
  switch (action.type) {
    case CREATE_CENTER_TIMEZONE_HASH:
      return {
        ...state,
        byCenterId: {
          ...state.byCenterId,
          ...Object.fromEntries(action.centers.map((c) => [c.id, c.timezone])),
        },
      };
    case CREATE_BUSINESS_TIMEZONE_HASH:
      return {
        ...state,
        byBusinessId: Object.fromEntries(action.businesses.map((b) => [b.id, b.timezone])),
      };
    case ADD_CENTER_TO_TIMEZONE_HASH:
      return {
        ...state,
        byCenterId: {
          ...state.byCenterId,
          [action.center.id]: action.center.timezone,
        },
      };
    default:
      return state;
  }
};
