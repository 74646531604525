import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { classFields, classClosuresFields, activeClassFields } from './fields';

//interfaces
interface ISearchClassesData {
  searchFees: { totalResults: number; data: IClass[] };
}

interface ISearchClassesVariables {
  input: IElasticsearchQuery;
}

interface IGetClassesForCenterData {
  getClassesForCenter: IClass[];
}

interface IGetClassesForCenterVariables {
  centerId: string;
}

interface IGetClassClosureByDateVariables {
  input: {
    classId: string;
    date: string;
  };
}

interface IGetClassClosureByDateData {
  getClassClosureByDate: IClassClosure | null;
}

interface IGetActiveClassesByCenterAndDateData {
  getActiveClassesByCenterAndDate: IClass[];
}

interface IGetActiveClassesByCenterAndDateVariables {
  centerId: string;
  date: string;
}

//queries
export const SEARCH_CLASSES = (fields: string = classFields) =>
  gql` query ($input: SearchInput!) {
    searchClasses(input: $input) {
      totalResults
      data {
        ${fields}
      }
    }
  }`;

export const GET_CLASSES_FOR_CENTER = (fields: string = classFields) =>
  gql` query ($centerId: String!) {
    getClassesForCenter (centerId: $centerId) {
      ${fields}
    }
  }`;

export const GET_CLASS_CLOSURE_BY_DATE = (fields: string = classClosuresFields) =>
  gql` 
  query ($input: GetClassClosureByDateInput!) {
    getClassClosureByDate (input: $input) {
      ${fields}
    }
  }
`;

export const GET_ACTIVE_CLASSES_BY_CENTER_AND_DATE = (fields: string = activeClassFields) =>
  gql` 
  query ($centerId: String!, $date: Date!) {
    getActiveClassesByCenterAndDate (centerId: $centerId, date: $date) {
      ${fields}
    }
  }
  `;

//hooks
export const useSearchClasses = (
  options?: QueryHookOptions<ISearchClassesData, ISearchClassesVariables>,
  fields?: string
) => useQuery<ISearchClassesData, ISearchClassesVariables>(SEARCH_CLASSES(fields), options);

export const useGetClassesForCenter = <TData = IGetClassesForCenterData>(
  options?: QueryHookOptions<TData, IGetClassesForCenterVariables>,
  fields?: string
) => useQuery<TData, IGetClassesForCenterVariables>(GET_CLASSES_FOR_CENTER(fields), options);

export const useGetClassClosureByDate = <TData = IGetClassClosureByDateData>(
  options?: QueryHookOptions<TData, IGetClassClosureByDateVariables>,
  fields?: string
) => useQuery<TData, IGetClassClosureByDateVariables>(GET_CLASS_CLOSURE_BY_DATE(fields), options);

export const useGetActiveClassesByCenterAndDate = <TData = IGetActiveClassesByCenterAndDateData>(
  options?: QueryHookOptions<TData, IGetActiveClassesByCenterAndDateVariables>,
  fields?: string
) => useQuery<TData, IGetActiveClassesByCenterAndDateVariables>(GET_ACTIVE_CLASSES_BY_CENTER_AND_DATE(fields), options);
