import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy, startCase } from 'lodash';
import Select from 'shared/components/Select/Select';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { RootState } from 'store/reducers';
import { setCurrentBusiness, setCurrentBusinessFilters, setCurrentCenterFilters } from 'store/context/actions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from 'shared/components/Alert';
import ActionDropdown from 'shared/components/ActionDropdown';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { isRegion } from 'shared/util/region';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFormatDate from 'shared/hooks/useFormatDate';
import { capitalize } from 'shared/util/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faPauseCircle } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { IStateControls, IDatatableState } from 'shared/hooks/useDatatableState';
import { billingFrequencyFilterOptions, billingFrequencyOptions } from 'shared/constants/enums/billingCycleOptions';
import { accountStatusColorHexes } from 'shared/constants/tagColors';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Currency from 'shared/components/Currency';
import { useGetTagsInUse } from '../../../shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from '../../../shared/constants/enums/tagCategoryEnum';
import { PREDICATES, SEARCH_EXPRESSIONS } from '../../../shared/constants/elastic';

interface IProps {
  data: IBillingAccountGap[] | IAccountBillingOverview[];
  loading: boolean;
  setSearchTerm: (str: string) => void;
  centers: ICenter[] | undefined;
  pageSize: number;
  activePage: number;
  totalResults: number;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  filteredBillingFrequencies: BillingFrequency[];
  filteredStatus: string[];
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  onBillingFrequencyFilter: (frequencies: BillingFrequency[]) => void;
  onStatusFilter: (status: string[]) => void;
}

const BillingAccountTable: React.FC<IProps> = ({
  data,
  pageSize,
  activePage,
  setSearchTerm,
  centers,
  loading,
  totalResults,
  filteredBillingFrequencies,
  tableState,
  tableFunctions,
  filteredStatus,
  onSort,
  onPageChange,
  onSizePerPageChange,
  onBillingFrequencyFilter,
  onStatusFilter,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { k2BillingAccountLimit } = useFlags();
  const formatDate = useFormatDate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { centerFilterIds, businessId } = useSelector((state: RootState) => state.context);
  const { data: businessesData } = useGetAllowedEntities();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const hasViewAccountPermission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  useEffect(() => {
    if (businesses.length > 0 && businessId === '') dispatch(setCurrentBusiness(businesses[0].id));
  }, [businesses, businessId, dispatch]);

  const clearAppliedFilters = useCallback(() => {
    dispatch(setCurrentCenterFilters([]));
    dispatch(setCurrentBusinessFilters([]));
    onBillingFrequencyFilter([]);
  }, [dispatch, onBillingFrequencyFilter]);

  const formatBillingFrequency = useCallback(
    (frequency: BillingFrequency): string => {
      switch (frequency) {
        case 'WEEKLY':
          return 'Weekly';
        case 'BIWEEKLY':
          return capitalize(t('spelling.biweekly'));
        case 'EVERY_FOUR_WEEKS':
          return 'Every Four Weeks';
        case 'MONTHLY':
          return 'Monthly';
        case 'QUARTERLY':
          return 'Quarterly';
        default:
          return '';
      }
    },
    [t]
  );

  const statusOptions: ITableFilterOption[] = [
    {
      label: capitalize(t('spelling.active')),
      value: 'Active',
      indicatorColor: accountStatusColorHexes.Active,
    },
    {
      label: capitalize(t('spelling.inactive')),
      value: 'Inactive',
      indicatorColor: accountStatusColorHexes.Inactive,
    },
  ];

  const handleBusinessSelect = useCallback(
    (option: ITableFilterOption) => {
      dispatch(setCurrentBusiness(option.value));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const handleCenterFilterSelect = useCallback(
    (options: ITableFilterOption[]) => {
      dispatch(setCurrentCenterFilters(options.map((c) => c.value)));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const getTableColumns = useCallback((): any[] => {
    const tableColumns: any = [
      {
        dataField: 'name',
        text: 'Account',
        sort: true,
      },
      {
        dataField: 'center.name',
        text: `${t('spelling.capitalCenter')} Name`,
      },

      {
        text: 'Frequency',
        sort: true,
        dataField: 'billingCycleFrequency',
        formatter: (cell: any, row: IAccountBillingOverview) =>
          row.billingCycleFrequency ? (
            formatBillingFrequency(row.billingCycleFrequency)
          ) : (
            <div>
              <FontAwesomeIcon icon={faExclamationCircle} color={colors.danger} className="mr-2" size="lg" />
              <span className="text-danger">No Billing Cycle</span>
            </div>
          ),
      },
      {
        text: 'Next Payment Date',
        sort: true,
        dataField: 'dueDate',
        formatter: (cell: any, row: IAccountBillingOverview) =>
          row.billingCycleFrequency ? (
            !row.isBillingCycleEnabled ? (
              <div>
                <FontAwesomeIcon icon={faPauseCircle} color={colors.warning} className="mr-2" size="lg" />
                <span>Billing Cycle Paused</span>
              </div>
            ) : row.isItemizedBillsPaused ? (
              <div>
                <FontAwesomeIcon icon={faPauseCircle} color={colors.warning} className="mr-2" size="lg" />
                <span>Billing Cycle Paused</span>
              </div>
            ) : row.dueDate ? (
              formatDate(row.dueDate)
            ) : (
              ''
            )
          ) : (
            ''
          ),
      },
      {
        text: startCase(t('spelling.autopay')),
        sort: true,
        dataField: 'hasAutoPay',
        formatter: (cell: any, row: IAccountBillingOverview) =>
          row.hasAutoPay ? (
            <ColoredBackgroundTag text="ON" color={colors.success} className="ml-2" />
          ) : (
            <ColoredBackgroundTag text="OFF" color={colors.gray} className="ml-2" />
          ),
      },
      k2BillingAccountLimit && {
        dataField: 'transactionLimit',
        text: 'Transaction Limit',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        formatter: (transactionLimit?: number) =>
          !transactionLimit ? (
            '-'
          ) : (
            <b className={classnames('mr-3')}>
              <Currency themed={false} amount={transactionLimit} />
            </b>
          ),
      },
      {
        dataField: 'gap',
        text: 'Gap',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        hidden: isRegion('US'),
        formatter: (amount: number) => (
          <b className="mr-3">
            <Currency aggregate direction="Positive" themed={false} amount={amount} />
          </b>
        ),
      },
      {
        dataField: 'balance',
        text: isRegion('US') ? 'Account Balance' : 'Total Balance',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        formatter: (amount: number) => (
          <b className="mr-3">
            <Currency aggregate themed={false} amount={amount} />
          </b>
        ),
      },
    ];

    if (hasViewAccountPermission) {
      tableColumns.push({
        text: 'Actions',
        dataField: '',
        align: 'center',
        headerAlign: 'center',
        formatter: (cell: any, row: IBillingAccountGap) => (
          <div className="d-flex justify-content-center">
            <ActionDropdown
              actions={[
                {
                  label: 'View Account',
                  onClick: () => history.push(`/families/accounts/${row.accountId}/profile`),
                },
              ]}
            />
          </div>
        ),
      });
    }

    return tableColumns;
  }, [t, k2BillingAccountLimit, hasViewAccountPermission, formatBillingFrequency, formatDate, history]);

  let frequencyOptions = billingFrequencyFilterOptions;
  if (isRegion('AU'))
    frequencyOptions = billingFrequencyFilterOptions.filter(
      (option) => !['MONTHLY', 'QUARTERLY'].includes(option.value)
    );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { TERM } = SEARCH_EXPRESSIONS;
  const { CONTAINS } = PREDICATES;

  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Account)?.data?.getTagsUsedAcrossEntity || [];

  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name'])
    .map((tag) => ({
      label: tag.name,
      value: tag.id,
      searchExpression: { [TERM]: { field: 'tags.keyword', predicate: CONTAINS, value: tag.id } },
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <DataTable
      data={data}
      columns={getTableColumns()}
      dataSize={totalResults}
      pageSize={pageSize}
      activePage={activePage}
      showLoadingOverlay={loading}
      showSelect={false}
      onSort={onSort}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      handleRowClick={(e, row) => history.push(`/families/accounts/${row.accountId}/profile`)}
      renderHeader={(paginationProps, searchProps) => (
        <>
          <TableHeader className="flex-wrap align-items-center">
            <div className="d-flex flex-wrap mr-auto align-items-center">
              <SizePerPage paginationProps={paginationProps} />
              <TableSearch placeholder="Search" onChange={setSearchTerm} className={isMobile ? 'mt-2 mb-1' : ''} />
            </div>
            <div className="d-flex flex-wrap align-items-center">
              <DropdownFilter
                title="Frequency"
                className={isMobile ? 'my-1 mr-3' : 'mr-3'}
                selectedFilters={filteredBillingFrequencies}
                options={frequencyOptions}
                onFilterSelect={(options) =>
                  onBillingFrequencyFilter(options.map((opt) => opt.value as BillingFrequency))
                }
              />
              <div className="d-flex flex-direction-row align-items-center m-0 flex-wrap">
                {centers && centers.length > 1 && (
                  <>
                    {user?.isInternal && (
                      <Select
                        title="Business"
                        className={isMobile ? 'my-1 mr-3' : 'mr-3 mb-0'}
                        options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                        value={businessId}
                        name="business"
                        onChange={handleBusinessSelect}
                      />
                    )}
                    <DropdownFilter
                      title={fieldLabels.center}
                      className={isMobile ? 'my-1 mr-3' : 'mr-3 mb-0'}
                      selectedFilters={centerFilterIds}
                      options={
                        centers
                          ?.filter((c) => c.entityId === businessId)
                          .map((c) => ({ label: c.name, value: c.id }))
                          .sort((a, b) => a.label.localeCompare(b.label)) ?? []
                      }
                      onFilterSelect={handleCenterFilterSelect}
                    />
                  </>
                )}
                <DropdownFilter
                  title={capitalize(t('spelling.status'))}
                  className={isMobile ? ' mt-1 mr-3' : 'mr-3'}
                  selectedFilters={filteredStatus}
                  options={statusOptions}
                  onFilterSelect={(values) => onStatusFilter(values.map((value) => value.value))}
                />
                <DropdownFilter
                  title={capitalize(t('spelling.tags'))}
                  className={isMobile ? ' my-1 mr-4' : 'mr-4'}
                  selectedFilters={tableState.selectedFilters.tags}
                  options={tagOptions}
                  onFilterSelect={(val) =>
                    tableFunctions.updateSelectedFilters({
                      ...tableState.selectedFilters,
                      tags: val,
                    })
                  }
                />
                <IconButtonCircle
                  icon={faTimes}
                  onClick={clearAppliedFilters}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                />
              </div>
            </div>
          </TableHeader>
          {isRegion('AU') && (
            <Alert className="bg-info-10">
              <p className="mb-0">Gap will not show if no attendances for the current week have been charged.</p>
            </Alert>
          )}
        </>
      )}
    />
  );
};

export default BillingAccountTable;
