import React from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import Button from '../../Buttons';
import { useTranslation } from 'react-i18next';
import Alert from '../../Alert';

interface WarningModalProps extends ModalProps {
  title: string;
  warningText: string;
  acknowledgeFnCallback: () => void;
  cancelFnCallback: () => void;
}

export const WarningModal: React.FC<WarningModalProps> = ({
  acknowledgeFnCallback,
  cancelFnCallback,
  title,
  warningText,
  ...props
}) => {
  const { t } = useTranslation(['translation']);
  return (
    <Modal {...props} centered backdrop="static">
      <Modal.Header closeButton onHide={cancelFnCallback} className="border-bottom-0 pb-4">
        <div className="d-flex flex-column">
          <Modal.Title as="h3">{title}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Alert variant={'warning'}>{warningText}</Alert>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div className="ml-auto">
          <Button id="centered-modal-secondary-btn" className="mr-3" variant="light" onClick={acknowledgeFnCallback}>
            {t('translation:core.capitalize', { value: t('translation:spelling.acknowledge') })}
          </Button>
          <Button id="centered-modal-primary-btn" variant="primary" onClick={cancelFnCallback}>
            {t('translation:core.capitalize', { value: t('translation:spelling.cancel') })}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
