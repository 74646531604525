import React from 'react';
import Card from 'shared/components/Card';
import { RowWithMb, Row, Col } from 'shared/components/Layout';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { Circle as LoadingCircle, Line } from 'shared/components/LoadingSkeletons';

interface IProps {
  className?: string;
}

const SpaceCardLoading: React.FC<IProps> = ({ className }) => {
  return (
    <Card className={`text-center h-md ${className || ''}`}>
      <Col>
        <Row justify="end" className="mt-n4 mr-n4">
          <IconButtonCircle icon={faEllipsisV} iconSize="2x" />
        </Row>
        <RowWithMb justify="center">
          <LoadingCircle size="xl" />
        </RowWithMb>
        <Line />
      </Col>
    </Card>
  );
};

export default SpaceCardLoading;
