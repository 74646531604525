import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import ActionDropdown from 'shared/components/ActionDropdown';
import { PersonAvatar } from 'shared/components/Avatar';
import { CreateButton } from 'shared/components/Buttons';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import useFormatDate from 'shared/hooks/useFormatDate';
import { getFullName } from 'shared/util/string';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  data: IAppliedAccountDiscount[];
  getAccountChild: (accountChildId: string) => IAccountChild | null;
  onAddDiscount: () => void;
  onEditDiscount: (discount: IAppliedAccountDiscount) => void;
  onRemoveDiscount: (discount: IAppliedAccountDiscount) => void;
  noPadding: boolean;
}

const AccountDiscountsTable: React.FC<IProps> = ({
  data,
  getAccountChild,
  onAddDiscount,
  onEditDiscount,
  onRemoveDiscount,
  noPadding = false,
  ...props
}) => {
  const hasEditDiscountPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Discount,
    level: RoleLevelType.Edit,
  });
  const hasDeleteDiscountPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Discount,
    level: RoleLevelType.Delete,
  });

  const { t } = useTranslation(['billing']);
  const formatDate = useFormatDate();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filterTableData = useCallback(
    (discounts: IAppliedAccountDiscount[]): IAppliedAccountDiscount[] => {
      const lowercased = searchTerm.toLowerCase();

      if (!searchTerm) return discounts;

      return discounts.filter(
        (discount) =>
          discount.discountName.toLowerCase().includes(lowercased) ||
          discount.startDate.includes(lowercased) ||
          discount.endDate?.includes(lowercased) ||
          getFullName(discount.createdByPerson).toLowerCase().includes(lowercased) ||
          (discount.accountChildId &&
            getAccountChild(discount.accountChildId) &&
            getFullName(getAccountChild(discount.accountChildId)).toLowerCase().includes(lowercased))
      );
    },
    [getAccountChild, searchTerm]
  );

  const handleSearchTerm = debounce(
    useCallback((term: string) => {
      setSearchTerm(term);
    }, []),
    250
  );

  const filteredData = filterTableData(data);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <DataTable
      data={filteredData}
      dataSize={filteredData.length}
      noPadding={noPadding}
      columns={[
        {
          text: t('billing:discounts.account.data-table.name-column'),
          dataField: 'discountName',
        },
        {
          text: t('billing:discounts.account.data-table.start-date-column'),
          dataField: 'start',
          formatter: (cell: string, row: IAppliedAccountDiscount) => formatDate(row.startDate),
        },
        {
          text: t('billing:discounts.account.data-table.end-date-column'),
          dataField: 'end',
          formatter: (cell: string, row: IAppliedAccountDiscount) =>
            row.endDate ? formatDate(row.endDate) : 'Ongoing',
        },
        {
          text: t('billing:discounts.account.data-table.applied-to-column'),
          dataField: 'accountChildIds',
          formatter: (cell: string[] | null, row: IAppliedAccountDiscount) =>
            !row.accountChildId ? (
              'Account'
            ) : (
              <div className="d-flex align-items-center">
                <PersonAvatar
                  size="sm"
                  person={getAccountChild(row.accountChildId) as IAccountChild}
                  className="mr-2"
                />
                <span>{getFullName(getAccountChild(row.accountChildId))}</span>
              </div>
            ),
        },
        {
          text: t('billing:discounts.account.data-table.applied-by-column'),
          dataField: 'createdAt',
          formatter: (cell: any, row: IAppliedAccountDiscount) => (
            <div className="d-flex align-items-center">
              <PersonAvatar size="sm" person={row.createdByPerson} className="mr-2" />
              <span>
                {getFullName(row.createdByPerson)} on {formatDate(row.createdAt)}
              </span>
            </div>
          ),
        },
        {
          text: t('billing:discounts.account.data-table.actions-column'),
          dataField: '',
          align: 'center',
          headerClasses: 'text-center',
          formatter: (cell: any, row: IAppliedAccountDiscount) => (
            <ActionDropdown
              actions={[
                ...(hasEditDiscountPermission ? [{ label: 'Edit Discount', onClick: () => onEditDiscount(row) }] : []),
                ...(hasDeleteDiscountPermission
                  ? [{ label: 'Remove Discount', onClick: () => onRemoveDiscount(row) }]
                  : []),
              ]}
            />
          ),
        },
      ]}
      showSelect={false}
      renderHeader={(paginationProps, searchProps) => (
        <TableHeader className={isMobile ? 'mb-2 flex-wrap' : 'flex-wrap'}>
          <TableSearch placeholder="Search" onChange={handleSearchTerm} className={isMobile ? 'mb-2' : 'mr-auto'} />
          <HasRoleAreaLevel
            action={{ area: AreaType.Billing, permission: PermissionType.Discount, level: RoleLevelType.Create }}
          >
            <CreateButton onClick={() => onAddDiscount()}>Apply Discount</CreateButton>
          </HasRoleAreaLevel>
        </TableHeader>
      )}
      noDataText={t('billing:discounts.account.data-table.no-data-message')}
    />
  );
};

export default AccountDiscountsTable;
