import { useCreateBatch } from 'gql/batch/mutations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { showToast } from 'shared/components/Toast';
import { RootState } from 'store/reducers';
import { createBatchSuccess } from '../duck/actions';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateBatchModal: React.FC<IProps> = ({ isOpen, onClose, ...props }) => {
  const { t } = useTranslation(['billing']);
  const dispatch = useDispatch();
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const paymentsToBatch = useSelector(
    (state: RootState) => state.billing.paymentBatches.paymentTransactionsSelectedForBatching
  );
  const centerIdToBatch = useSelector((state: RootState) => state.billing.paymentBatches.centerIdToBatch);

  const [createBatchFn, { loading: createBatchLoading }] = useCreateBatch({
    onCompleted: (result) => {
      dispatch(createBatchSuccess(result.createBatch));
      showToast(t('billing:transactions.create-batch-modal.success-toast-message'), 'success');
      onClose();
    },
    onError: (err) => {
      showToast(t('billing:transactions.create-batch-modal.error-toast-message'), 'error');
    },
  });

  return (
    <CenteredModal
      title={t('billing:transactions.create-batch-modal.title')}
      show={isOpen}
      onHide={onClose}
      closeOnPrimaryCallback={false}
      primaryCallback={() =>
        createBatchFn({
          variables: {
            input: {
              businessId: currentBusinessId ?? '',
              centerId: centerIdToBatch ?? '',
              paymentIds: paymentsToBatch.map((t) => t.payment!.id),
            },
          },
        })
      }
      primaryButtonProps={{ loading: createBatchLoading }}
      secondaryCallback={onClose}
      enforceFocus={false}
    >
      <p>
        {t('billing:transactions.create-batch-modal.batch-information-text', {
          paymentCount: paymentsToBatch.length,
          total: paymentsToBatch.reduce((acc, curr) => (acc += curr.amount), 0).toFixed(2),
        })}
      </p>
      <p>{t('billing:transactions.create-batch-modal.batch-report-text')}</p>
    </CenteredModal>
  );
};

export default CreateBatchModal;
