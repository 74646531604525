import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from 'config';
import axios from 'axios';
import { useGetUnbilledTransactions } from './useGetUnbilledTransactions';
import { createNewManualItemizedBill } from 'pages/Families/subroutes/Accounts/duck/actions';
import { UnbilledTransaction } from '../models/UnbilledTransaction';

interface ItemizedBillForm {
  billingDate: string;
  includeForwardedBalance: boolean;
  includedTransactions: any[];
  lateFee: number | null;
  paymentDueDate: string;
  paymentLateDate: string;
  selectedTransactions: { id: string }[];
}

function ItemizedBillFormDefaults(): ItemizedBillForm {
  const today = new Date().toISOString().split('T')[0];
  return {
    billingDate: today,
    includeForwardedBalance: false,
    includedTransactions: [],
    lateFee: null,
    paymentDueDate: '',
    paymentLateDate: '',
    selectedTransactions: [],
  };
}

function useGetForwardBalance(accountId) {
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);
  const [forwardBalance, setForwardBalance] = useState<number>(0.0);
  const [loading, setLoading] = useState<boolean>(false);
  const getForwardBalance = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api.billing.uri}/api/v2/bills/balance/account/${accountId}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setForwardBalance(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [accountId, jwtToken]);
  useEffect(() => {
    getForwardBalance();
  }, [getForwardBalance]);
  return { loading, forwardBalance };
}

interface CreateItemizedBillPayload {
  accountId: string;
  dueOnDate: string;
  transactionIds: string[];
  latePaymentSettings?: { lateOnDate: string; lateFeeAmount: number };
}
function useCreateItemizedBill() {
  const dispatch = useDispatch();
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);
  const [loading, setLoading] = useState<boolean>(false);
  const create = useCallback(
    async (payload: CreateItemizedBillPayload) => {
      try {
        setLoading(true);
        const response = await axios.post(`${config.api.billing.uri}/api/v2/itemized-bills`, payload, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });
        dispatch(createNewManualItemizedBill(response.data.accountId, response.data));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, jwtToken]
  );
  return { loading, create };
}

export function useCreateItemizedBillScreen(accountId: string) {
  const [formState, setFormState] = useState<ItemizedBillForm>(ItemizedBillFormDefaults());
  const [selectedItems, setSelectedItems] = useState<UnbilledTransaction[]>([]);

  // Dependant data
  const { loading: loadingUnbilledTransactions, unbilledTransactions } = useGetUnbilledTransactions({
    accountId,
  });

  useEffect(() => {
    setSelectedItems(unbilledTransactions);
  }, [unbilledTransactions]);

  const { loading: loadingForwardBalance, forwardBalance } = useGetForwardBalance(accountId);
  // End Dependant Data

  const handlePaymentDueDateChange = useCallback(
    (paymentDueDate) => setFormState((prev) => ({ ...prev, paymentDueDate })),
    []
  );
  const handlePaymentLateDateChange = useCallback(
    (paymentLateDate) => setFormState((prev) => ({ ...prev, paymentLateDate })),
    []
  );
  const handleLateFeeChange = useCallback((lateFee) => setFormState((prev) => ({ ...prev, lateFee })), []);

  const subtotal = useMemo(() => {
    return selectedItems.reduce((acc, curr) => {
      return acc + curr.amount;
    }, 0);
  }, [selectedItems]);

  const billTotal = useMemo(() => {
    return forwardBalance + subtotal;
  }, [forwardBalance, subtotal]);

  const lateFeeDisabled = useMemo(() => !formState.paymentLateDate, [formState.paymentLateDate]);
  const lateFeeRequired = useMemo(() => !lateFeeDisabled, [lateFeeDisabled]);
  const paymentDateIsRequired = useMemo(() => !!formState.lateFee, [formState.lateFee]);
  const paymentLateDateIsValid = useMemo(() => {
    if (!formState.paymentLateDate) return true;
    const dueDate = new Date(formState.paymentDueDate).getTime();
    const lateDate = new Date(formState.paymentLateDate).getTime();
    return lateDate >= dueDate;
  }, [formState.paymentDueDate, formState.paymentLateDate]);

  const paymentLateDateIsOutOfRange = useCallback(
    (date) => {
      return !(formState.paymentDueDate && date.isAfter(moment(formState.paymentDueDate)));
    },
    [formState.paymentDueDate]
  );

  const { loading: savingItemizedBill, create } = useCreateItemizedBill();

  const saveItemizedBill = useCallback(() => {
    const dateFormat = 'YYYY-MM-DD';
    let latePaymentSettings: CreateItemizedBillPayload['latePaymentSettings'] =
      formState.lateFee && formState.paymentLateDate
        ? { lateOnDate: moment(formState.paymentLateDate).format(dateFormat), lateFeeAmount: formState.lateFee }
        : undefined;
    const transactions = selectedItems.map((t) => t.id);
    return create({
      accountId,
      transactionIds: transactions,
      dueOnDate: moment(formState.paymentDueDate).format(dateFormat),
      latePaymentSettings,
    });
  }, [accountId, create, formState.lateFee, formState.paymentDueDate, formState.paymentLateDate, selectedItems]);

  const isValid = useMemo(() => {
    const lateFeeValid = lateFeeRequired ? !!formState.lateFee : true;
    const paymentSettingsAreValid =
      !!formState.lateFee && !!formState.paymentLateDate ? lateFeeValid && paymentLateDateIsValid : true;
    const hasSelected = selectedItems.length > 0;

    return lateFeeValid && paymentSettingsAreValid && hasSelected && formState.paymentDueDate;
  }, [
    formState.lateFee,
    formState.paymentDueDate,
    formState.paymentLateDate,
    lateFeeRequired,
    paymentLateDateIsValid,
    selectedItems.length,
  ]);

  const canSave = useMemo(() => isValid, [isValid]);

  const reset = useCallback(() => {
    setSelectedItems([]);
    setFormState(ItemizedBillFormDefaults());
  }, []);

  return {
    ...formState,
    billTotal,
    canSave,
    forwardBalance,
    handleLateFeeChange,
    handlePaymentDueDateChange,
    handlePaymentLateDateChange,
    handleSelectionChange: setSelectedItems,
    lateFeeDisabled,
    lateFeeRequired,
    loadingForwardBalance,
    loadingUnbilledTransactions,
    paymentDateDateIsRequired: paymentDateIsRequired,
    paymentLateDateIsOutOfRange,
    paymentLateDateIsValid,
    reset,
    saveItemizedBill,
    savingItemizedBill,
    selectedUnbilledItems: selectedItems,
    unbilledItems: unbilledTransactions,
    subtotal,
  };
}
