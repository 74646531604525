import React, { ChangeEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import './_search.scss';

interface IProps {
  onChange: (value: string, name: string) => void;
  placeholder?: string;
  [key: string]: any;
  className?: string;
  searchString?: string;
}

//@ts-ignore
const SearchInput = React.forwardRef<FormControl<React.ElementType<any>>, IProps>(
  ({ onChange, placeholder = 'Search', className, searchString = '', ...props }, ref) => {
    const [searchValue, setSearchValue] = React.useState<string>('');

    const handleChange = React.useCallback(
      (event: React.FormEvent<HTMLInputElement & FormControlProps>) => {
        // @ts-ignore
        setSearchValue(event.target.value);
        // typescript will complain that value and name aren't found on event.target but since FormControl is rendering an input element, value and name will be present
        // @ts-ignore
        onChange && onChange(event.target.value, event.target.name);
      },
      [onChange]
    );

    React.useEffect(() => {
      if (searchString) {
        setSearchValue(searchString);
      }
    }, [searchString]);

    return (
      <div className={`search ${className}`}>
        <FontAwesomeIcon className="search-icon" color={colors.gray} icon={faSearch} />
        <FormControl
          value={searchValue}
          id="search-input"
          as="input"
          ref={ref}
          placeholder={placeholder}
          // @ts-ignore
          onChange={handleChange}
          type="search"
          autoComplete="off"
          {...props}
        />
      </div>
    );
  }
);

export default SearchInput;
