import React from 'react';
import Badge from 'shared/components/Badge';
import { isUndefined } from 'lodash';

interface IProps {
  title: string;
  count?: number;
  highlightCount?: boolean;
}

export default ({ title, count, highlightCount = false }: IProps) => {
  if (highlightCount && !isUndefined(count) && count > 0) {
    return (
      <div className="d-flex align-items-center">
        {title}
        <Badge count={count} variant="pill" className="ml-2" />
      </div>
    );
  }

  return (
    <div className="d-flex">
      {title}
      {!isUndefined(count) && ` (${count})`}
    </div>
  );
};
