import React from 'react';
import Input from 'shared/components/TextInput/Input';
import { ICustomFieldProps } from '../CustomField';

const Text: React.FC<ICustomFieldProps> = ({ customField, value, disabled, isReadOnly, onChange }) => {
  if (!isReadOnly)
    return (
      <Input
        label={customField.label}
        value={value}
        disabled={disabled}
        onChange={(value, name) => onChange(value, customField.id)}
      />
    );

  return (
    <div>
      <label>{customField.label}</label>
      <p>{Boolean(value) ? value : '-'}</p>
    </div>
  );
};
export default Text;
