import * as types from './types';

export const createProvider = (provider: IProvider): types.ProvidersActionTypes => ({
  type: types.CREATE_PROVIDER,
  provider,
});

/* export const createCustomField = (customField: ICustomField): types.CustomFieldActionTypes => ({
  type: types.CREATE_CUSTOM_FIELD,
  customField
});
 */

export const getPersonnel = (personnel: ICcssPersonnel[]): types.PersonnelActionTypes => ({
  type: types.GET_PERSONNEL,
  personnel,
});

export const getProviders = (providers: IProvider[]): types.ProvidersActionTypes => ({
  type: types.GET_PROVIDERS,
  providers,
});

export const updateProvider = (provider: IProvider): types.ProvidersActionTypes => ({
  type: types.UPDATE_PROVIDER,
  provider,
});
