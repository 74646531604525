import { gql, MutationHookOptions } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useMutation } from 'shared/apis/core';
import { updateBusiness } from 'pages/Businesses/subroutes/BusinessProfiles/duck/actions';

interface IUpdateEntityDataType {
  updateEntityDataType: IBusiness;
}

interface IUpdateEntityDataTypeVariables {
  input: {
    id: string;
    dataType?: DataType;
  };
}

const UPDATE_ENTITY_DATATYPE = gql`
  mutation ($input: UpdateEntityDataTypeInput!) {
    updateEntityDataType(input: $input) {
      id
      dataType
    }
  }
`;

export const useUpdateEntityDataTypeInformation = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateEntityDataType, IUpdateEntityDataTypeVariables>(UPDATE_ENTITY_DATATYPE, {
    onCompleted: (data) => {
      dispatch(updateBusiness(data.updateEntityDataType));
    },
  });
};

const TOGGLE_EDUCATION_BUSINESS_FEATURES = gql`
  mutation ($input: ToggleEducationFeaturesInput!) {
    toggleBusinessFeatures(input: $input) {
      feature
      isEnabled
    }
  }
`;

export const useToggleEducationBusinessFeatures = (
  input?: MutationHookOptions<IToggleEducationFeaturesResponse, IToggleEducationFeaturesInput>
) =>
  useMutation<IToggleEducationFeaturesResponse, IToggleEducationFeaturesInput>(
    TOGGLE_EDUCATION_BUSINESS_FEATURES,
    input
  );
