import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import errorMessage from 'shared/constants/errorMessages';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import { showToast } from 'shared/components/Toast';
import { isRegion } from 'shared/util/region';
import { ApolloError } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { useTranslation } from 'react-i18next';
import * as Mui from '@mui/material';
import { useUpdateChildMealStatus } from '../../../graphql/mutations';

interface IProps {
  child: IChild;
  readOnly: boolean;
}

const MealForm: React.FC<IProps> = ({ child: origChild, readOnly }) => {
  const [mealStatus, setMealStatus] = useState(origChild.mealStatus);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [updateChildMealStatus, { loading }] = useUpdateChildMealStatus(origChild);

  const { t } = useTranslation();

  useEffect(() => {
    !formIsDirty && setMealStatus(origChild.mealStatus);
  }, [formIsDirty, origChild.mealStatus]);

  const handleChange = useCallback(
    (value: any, name: string) => {
      setMealStatus({ ...mealStatus, [name]: value });
      setFormIsDirty(true);
    },
    [mealStatus]
  );

  const save = useCallback(() => {
    if (!mealStatus || !mealStatus.mealPricing) return;
    updateChildMealStatus({
      variables: {
        input: {
          id: origChild.id,
          mealPricing: mealStatus.mealPricing,
        },
      },
    })
      .then(() => {
        showToast('Child meal info updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch((error: Error) => {
        const message = error instanceof ApolloError ? getApolloErrorMessage(error) : error.message;
        showToast(message, 'error');
      });
  }, [updateChildMealStatus, mealStatus, origChild.id]);

  return (
    <FormWrapper2
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onSave={save}
      onCancel={() => {
        setMealStatus(origChild.mealStatus);
      }}
      loading={loading}
      saveDisabled={!mealStatus || !mealStatus.mealPricing}
    >
      <Row align="start">
        <Col>
          <div className="d-flex flex-row">
            <label>{t('spelling.meal-pricing')}</label>
          </div>
          <Mui.RadioGroup
            row
            value={mealStatus?.mealPricing ?? null}
            name="meal-pricing-radio-buttons-group"
            onChange={(evt) => handleChange(evt.target.value, 'mealPricing')}
            style={{ margin: 0 }}
          >
            <Mui.FormControlLabel
              disabled={readOnly}
              value="Paid"
              control={<Mui.Radio size="small" />}
              label={t('child.meal-pricing.paid')}
            />
            <Mui.FormControlLabel
              disabled={readOnly}
              value="Free"
              control={<Mui.Radio size="small" />}
              label={t('child.meal-pricing.free')}
            />
            <Mui.FormControlLabel
              disabled={readOnly}
              value="Reduced"
              control={<Mui.Radio size="small" />}
              label={t('child.meal-pricing.reduced')}
            />
          </Mui.RadioGroup>
        </Col>
      </Row>
    </FormWrapper2>
  );
};

export default MealForm;
