import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shared/components/Layout';
import { useCallback } from 'react';
import Button from 'shared/components/Buttons';
import DateInput from 'shared/components/DateInput';
import moment from 'moment';
import { useGetAccountingPeriod } from 'gql/accountingPeriod/query';
import { useCreateAccountingPeriod, useUpdateAccountingPeriod } from 'gql/accountingPeriod/mutation';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { showToast } from 'shared/components/Toast';
import { Form } from 'react-bootstrap';
import DataTable, { TableHeader } from 'shared/components/DataTable';
import { PersonAvatar } from 'shared/components/Avatar';
import { getFullName } from 'shared/util/string';
import useDatatableState from 'shared/hooks/useDatatableState2';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { ApolloError } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IProps {}

interface IError {
  message: string;
}

const expandRowDefault = (data: IAccountingPeriod) => <ExpandRowDefaultComponent data={data} />;

const ExpandRowDefaultComponent: React.FC<{ data: IAccountingPeriod }> = ({ data }) => {
  const { t } = useTranslation(['translation', 'subsidies']);
  const dateFormat = t('translation:formatters.MM/DD/YYYY');
  const historicalData = [...(data.history || [])].sort((a, b) =>
    moment(a.createdAt) > moment(b.createdAt) ? 1 : moment(b.createdAt) > moment(a.createdAt) ? -1 : 0
  );
  return (
    <DataTable
      noPadding
      showPagination={false}
      showSelect={false}
      showLoadingOverlay={false}
      keyField="id"
      data={historicalData.reverse() || []}
      columns={[
        {
          text: t('translation:billing.accounting-period.user'),
          dataField: 'createdBy',
          formatter: (_: any, row: IAccountingPeriod) => (
            <>
              {!!row.createdByPerson && (
                <span>
                  <PersonAvatar size="xs" person={row.createdByPerson} className="mr-2" />
                  {getFullName(row.createdByPerson)}
                </span>
              )}
            </>
          ),
        },
        {
          text: t('translation:billing.accounting-period.date'),
          dataField: 'createdAt',
          formatter: (_: any, row: IAccountingPeriod) => moment(row.createdAt).format(dateFormat),
        },
        {
          text: t('translation:billing.accounting-period.action'),
          dataField: 'open',
          formatter: (_: any, row: IAccountingPeriod) =>
            row.open
              ? t('translation:billing.accounting-period.reopen')
              : t('translation:billing.accounting-period.close'),
        },
      ]}
    />
  );
};

const AccountingPeriodsTab: React.FC<IProps> = ({ ...props }) => {
  const defaultPageSize = 25;
  const businessId = useSelector((state: RootState) => state.user?.entityId) ?? '';
  const { t } = useTranslation();
  const dateFormat = t('formatters.MM/DD/YYYY');

  const [endDate, setEndDate] = useState<string>(moment(new Date()).format('YYYY-MM-DD'));
  const [tableState, tableFunctions] = useDatatableState();

  const canCreateAccountingPeriods = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });
  const canEditAccountingPeriods = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const sortDtos = [{ field: 'endDate', direction: 'DESCENDING' }];

  const {
    data: lastAccountingPeriod,
    loading: loadingLastAccountingPeriod,
    refetch: refetchLastAccountingPeriod,
  } = useGetAccountingPeriod({
    variables: {
      input: {
        businessId: businessId,
        pageSize: 1,
        pageNumber: 1,
        sortDtos,
      },
    },
  });

  const startDate = useMemo(() => {
    const accountingPeriodData = lastAccountingPeriod?.getAccountingPeriods.data;

    if (!loadingLastAccountingPeriod && accountingPeriodData?.length) {
      return moment(accountingPeriodData[0].endDate).add(1, 'days').format('YYYY-MM-DD');
    }

    return '2000-01-01';
  }, [lastAccountingPeriod]);

  const {
    data,
    loading,
    refetch: refetchAccountingPeriods,
  } = useGetAccountingPeriod({
    variables: {
      input: {
        businessId: businessId,
        pageSize: defaultPageSize,
        pageNumber: tableState.activePage,
        sortDtos,
      },
    },
  });

  function refetch() {
    refetchLastAccountingPeriod();
    refetchAccountingPeriods();
  }

  const isValidEndDate = useMemo(
    () =>
      moment(endDate).endOf('day').isAfter(moment(startDate).endOf('day')) &&
      !moment(endDate).endOf('day').isAfter(moment(new Date()).endOf('day')),
    [endDate, startDate]
  );

  useEffect(() => setEndDate(moment(startDate, 'YYYY-MM-DD').add(6, 'days').format('YYYY-MM-DD')), [startDate]);

  const [createAccountingPeriodFn, { loading: createAccountingPeriodLoading }] = useCreateAccountingPeriod({
    onCompleted: (result) => {
      refetch();
    },
    onError: (error) => {
      const message = error instanceof ApolloError ? getApolloErrorMessage(error) : (error as IError).message;
      showToast(message, 'error');
    },
  });

  const handleSubmit = useCallback(() => {
    if (businessId) {
      createAccountingPeriodFn({
        variables: {
          input: {
            businessId,
            endDate: moment(endDate).format('YYYY-MM-DD'),
          },
        },
      });
    }
  }, [createAccountingPeriodFn, businessId, endDate]);

  const [updateAccountingPeriodFn, { loading: updateAccountingPeriodLoading }] = useUpdateAccountingPeriod({
    onCompleted: (result) => {
      refetch();
    },
    onError: (error) => {
      const message = error instanceof ApolloError ? getApolloErrorMessage(error) : (error as IError).message;
      showToast(message, 'error');
    },
  });

  const handleUpdate = useCallback(
    (id: string, open: boolean) => {
      if (businessId) {
        updateAccountingPeriodFn({
          variables: {
            input: {
              businessId,
              id,
              open,
            },
          },
        });
      }
    },
    [updateAccountingPeriodFn, businessId, endDate]
  );

  return (
    <Row>
      <Col>
        <DataTable
          renderHeader={(paginationProps: any, searchProps: any) => (
            <TableHeader className="mb-4">
              <Form inline className="mt-4">
                <DateInput
                  label={t('billing.accounting-period.period-start-date')}
                  className="mr-4"
                  date={startDate}
                  disabled={true}
                />
                <DateInput
                  label={t('billing.accounting-period.period-end-date')}
                  className="mr-4"
                  date={endDate}
                  onDateSelect={(date: string) => setEndDate(date)}
                  isOutsideRange={(day) =>
                    day.isBefore(moment(startDate).endOf('day')) || day.isAfter(moment(new Date()).endOf('day'))
                  }
                />
              </Form>
              <Button
                className="ml-auto"
                onClick={() => handleSubmit()}
                loading={createAccountingPeriodLoading}
                disabled={createAccountingPeriodLoading || !endDate || !isValidEndDate || !canCreateAccountingPeriods}
              >
                {t('billing.accounting-period.close')}
              </Button>
            </TableHeader>
          )}
          noPadding
          showPagination={true}
          showSelect={false}
          onPageChange={tableFunctions.changePage}
          showLoadingOverlay={loading || updateAccountingPeriodLoading || createAccountingPeriodLoading}
          keyField="id"
          expandHeaderColumnRenderer={(props) => <></>}
          expandRow={expandRowDefault}
          data={data?.getAccountingPeriods.data || []}
          pageSize={data?.getAccountingPeriods.pageSize}
          activePage={data?.getAccountingPeriods.pageNumber}
          dataSize={data?.getAccountingPeriods.totalRecords}
          columns={[
            {
              text: t('billing.accounting-period.start'),
              dataField: 'startDate',
              formatter: (_: any, row: IAccountingPeriod) => moment(row.startDate).format(dateFormat),
            },
            {
              text: t('billing.accounting-period.end'),
              dataField: 'endDate',
              formatter: (_: any, row: IAccountingPeriod) => moment(row.endDate).format(dateFormat),
            },
            {
              text: t('billing.accounting-period.date-close'),
              dataField: '',
              isDummyField: true,
              formatter: (_: any, row: IAccountingPeriod) => {
                const historicalData = [...(row.history || [])].sort((a, b) =>
                  moment(a.createdAt) > moment(b.createdAt) ? 1 : moment(b.createdAt) > moment(a.createdAt) ? -1 : 0
                );
                const latestAction = historicalData[historicalData.length - 1];
                return (
                  <>{!!latestAction && !!latestAction.createdAt && moment(latestAction.createdAt).format(dateFormat)}</>
                );
              },
            },
            {
              text: t('billing.accounting-period.status'),
              dataField: 'open',
              formatter: (_: any, row: IAccountingPeriod) =>
                row.open ? t('billing.accounting-period.open') : t('billing.accounting-period.closed'),
            },
            {
              text: t('billing.accounting-period.close-by'),
              dataField: '',
              isDummyField: true,
              formatter: (_: any, row: IAccountingPeriod) => {
                const historicalData = [...(row.history || [])].sort((a, b) =>
                  moment(a.createdAt) > moment(b.createdAt) ? 1 : moment(b.createdAt) > moment(a.createdAt) ? -1 : 0
                );
                const latestAction = historicalData[historicalData.length - 1];
                return (
                  <>
                    {!!latestAction && !!latestAction.createdByPerson && (
                      <span>
                        <PersonAvatar size="xs" person={latestAction.createdByPerson} className="mr-2" />
                        {getFullName(latestAction.createdByPerson)}
                      </span>
                    )}
                  </>
                );
              },
            },
            {
              text: '',
              dataField: '',
              isDummyField: true,
              formatter: (_: any, row: IAccountingPeriod) => (
                <Button
                  className="ml-auto"
                  onClick={() => handleUpdate(row.id, !row.open)}
                  loading={updateAccountingPeriodLoading}
                  disabled={updateAccountingPeriodLoading || !canEditAccountingPeriods}
                  variant={row.open ? 'outline-secondary' : 'outline-danger'}
                >
                  {row.open ? t('billing.accounting-period.close') : t('billing.accounting-period.reopen')}
                </Button>
              ),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default AccountingPeriodsTab;
