import {
  ApplicationFeeType,
  ApplicationFlowType,
  CenterSettings,
  EnrollmentOptions,
  SortDirection,
  useGetApplicationCenterSettingsPaginatedQuery,
} from 'generated/graphql';
import ApplicationFeeInput from 'pages/Enrollment/subroutes/Programs/components/CreateProgramModal/ApplicationFeeInput';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Row } from 'shared/components/Layout';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Select from 'shared/components/Select';
import { showToast } from 'shared/components/Toast';
import applicationFeeTypes from 'shared/constants/dropdownOptions/applicationFeeTypes';
import { IDatatableState } from 'shared/hooks/useDatatableState';
import { RootState } from 'store/reducers';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { useUpdateMultipleCentersSettings } from 'gql/applicationCenterSettings/mutation';
import { capitalize } from 'lodash';
import { ICenterSettingsInput } from 'gql/applicationCenterSettings/fields';
import { isProgramBasedFlow } from 'pages/Enrollment/subroutes/LeadManagement/utils';

interface IProps {
  flowType: ApplicationFlowType;
  isOpen: boolean;
  allCentersSelected: boolean;
  selectedCenters: number;
  tableState: IDatatableState;
  onCancel: () => void;
  onSave: () => void;
}

const ApplicationCenterSettingsModal: React.FC<IProps> = ({
  flowType,
  isOpen,
  selectedCenters,
  onCancel,
  onSave,
  allCentersSelected,
  tableState,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const [enrollmentOption, setEnrollmentOption] = useState<EnrollmentOptions | undefined>(undefined);
  const [feeType, setFeeType] = useState<ApplicationFeeType | undefined>(undefined);
  const [feeAmount, setFeeAmount] = useState<number | undefined>(undefined);
  const [subsidy, setSubsidy] = useState<boolean | undefined>(undefined);
  const [allCenterIds, setAllCenterIds] = useState<string[]>([]);

  const feeTypeOptions = applicationFeeTypes;
  const enrollmentOptionsFilterOptions: ITableFilterOption[] = useMemo(() => {
    return Object.values(EnrollmentOptions).map((source) => {
      return { label: t(`enrollment:enrollment-options.${source as EnrollmentOptions}`), value: source };
    });
  }, []);

  const subsidyQuestionOptions: { label: string; value: boolean }[] = useMemo(() => {
    return [
      { value: false, label: 'No' },
      { value: true, label: 'Yes' },
    ];
  }, []);

  // this is pretty gross, but if all rows are selected we need all of the centreIds in order to update them
  const { data, loading, error } = useGetApplicationCenterSettingsPaginatedQuery({
    skip: !allCentersSelected || businessId === null || businessId === undefined || businessId === '',
    variables: {
      input: {
        filter: { [SEARCH_EXPRESSIONS.ALL]: [...tableState.searchExpressions, ...tableState.filterExpressions] },
        from: 0,
        size: 1000, // we're making the assumption that a business will (for now) have no more than 1000 centres
        sort: [
          {
            field: 'name.keyword',
            direction: SortDirection.Ascending,
          },
        ],
      },
    },
    onCompleted: (resp) => {
      setAllCenterIds(resp.searchCenters.data.map((c) => c.id));
    },
    onError: (err) => showToast(t('enrollment:reenroll-center-settings.get-error'), 'error'),
  });

  const [updateMultipleCentersSettings, { loading: updateCenterSettingsLoading }] = useUpdateMultipleCentersSettings({
    onCompleted: (result) => {
      showToast(t('enrollment:update-application-settings-modal.success-toast'), 'success');
      onSave();
      handleCloseModal();
    },
    onError: (error) => {
      showToast(capitalize(t('enrollment:update-application-settings-modal.error-toast', { error })), 'error');
    },
  });

  const handleSaveApplicationCenterSettings = () => {
    const centerIds = allCentersSelected ? allCenterIds : tableState.selectedRows.map((c) => c.id);
    const centerSettingsList: ICenterSettingsInput[] = centerIds.map((item) => {
      const centerSettingsInput: ICenterSettingsInput = {
        businessId: businessId ?? '',
        centerId: item,
        feeType: feeType ?? undefined,
        feeAmount: feeAmount ?? 0,
        askFamilyAboutSubsidy: subsidy ?? false,
        enrollmentOptions: enrollmentOption ?? undefined,
      };
      return centerSettingsInput;
    });
    updateMultipleCentersSettings({
      variables: { input: { businessId: businessId ?? '', centerIds, centerSettingsList } },
    });
  };

  const handleCloseModal = useCallback(() => {
    setEnrollmentOption(undefined);
    setFeeType(undefined);
    setFeeAmount(undefined);
    setSubsidy(undefined);
    setAllCenterIds([]);
    onCancel();
  }, [onCancel]);

  return (
    <CenteredModal
      size={flowType === ApplicationFlowType.InquireOfferEnrollment ? undefined : 'lg'}
      title={t('enrollment:update-application-settings-modal.title', { selectedCenters: selectedCenters })}
      show={isOpen}
      onHide={handleCloseModal}
      primaryCallback={() => handleSaveApplicationCenterSettings()}
      secondaryCallback={handleCloseModal}
      primaryChoice="Save"
      primaryButtonProps={{
        // disabled: !isValid,
        loading: updateCenterSettingsLoading,
      }}
      loading={loading}
    >
      <p>{t('enrollment:update-application-settings-modal.info-message')}</p>
      <Row className="justify-content-center align-items-center">
        {flowType === ApplicationFlowType.InquireOfferEnrollment && (
          <Col xs={12}>
            <Select
              options={enrollmentOptionsFilterOptions}
              onChange={(v) => setEnrollmentOption(v.value)}
              value={enrollmentOption}
              isMulti={false}
              className="m-0"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
              closeMenuOnScroll={true}
            />
          </Col>
        )}
        {isProgramBasedFlow(flowType) && (
          <>
            <Col xs={12} sm={4}>
              <Select
                options={feeTypeOptions}
                onChange={(v) => setFeeType(v.value)}
                value={feeType}
                isMulti={false}
                className="m-0"
              />
            </Col>
            <Col xs={12} sm={4}>
              <ApplicationFeeInput
                isRequired={!!feeTypeOptions}
                value={feeAmount}
                setValue={(v) => setFeeAmount(v)}
                disabled={!feeTypeOptions}
              />
            </Col>
            <Col xs={12} sm={4}>
              <Select
                options={subsidyQuestionOptions}
                onChange={(v) => setSubsidy(v.value)}
                value={subsidyQuestionOptions.find((f) => f.value === subsidy)}
                isMulti={false}
                className="m-0"
              />
            </Col>
          </>
        )}
      </Row>
    </CenteredModal>
  );
};

export default ApplicationCenterSettingsModal;
