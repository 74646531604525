import { appliedAccountDiscountFields } from 'gql/discount/fields';
import { paymentMethodFields } from 'gql/paymentMethod/fields';
import { isRegion } from 'shared/util/region';
const isAuRegion = isRegion('AU');

export const ccssAttributes = `
  ccssAttributes {
    crn
    isCrnEditable
  }
`;

export const accountFields = `
  id
  entityId
  centerId
  familyId
  name
  surrogateKey
  tags {
    id
    entityId
    name
    category
  }
`;

export const accountContactChildFields = `
  id
  childId
  firstname
  lastname
  nickname
  avatar {
    url
  }
  relationshipType
  accountPermissions {
    accountId
    permissions
  }
`;

export const accountContactFields = `
  id
  firstname
  lastname
  nickname
  dob
  isPrimary
  avatar {
    url
  }
  email
  primaryPhoneNumber {
    number
    type
  }
  address {
    address1
    address2
    city
    state
    postalCode
    country
  }
  children {
    ${accountContactChildFields}
  }
  ${isAuRegion ? ccssAttributes : ''}
  contactAccountPermissions {
    accountId
    permissions
  }
`;

export const accountPageFields = `
  id
  centerId
  entityId
  familyId
  surrogateKey
  name
  children {
    id
    accountChildId
    firstname
    lastname
    nickname
    fullName
    dob
    avatar {
      url
    }
    archivedAt
    ${isAuRegion ? ccssAttributes : ''}
  }
  contacts {
    ${accountContactFields}
  }
  center {
    name
    id
    entityId
    merchant {
      id
      merchantId
      processor
      isSandbox
    }
    timezone
    address{
      address1
      city
      state
      postalCode
    }
  }
  billingCycle {
    id
    accountId
    startDate
    endDate
    nextBillingDate
    nextPaymentDate
    period
    frequency
    createdAt
    createdBy
    dayOfWeek
    dayOfMonth
    invoiceSchedule {
      unit
      value
    }
    additionalChargePeriod {
      unit
      value
    }
    transactionLimit
    isPaused
    nextBillingPeriod {
      start
      end
    }
    cycleType
    billingCycleTemplateId
  }
  balance
  pendingBalance
  payrixCustomerId
  paymentMethods {
    ${paymentMethodFields}
  }
  discounts {
    ${appliedAccountDiscountFields}
  }
`;

export const accountPaymentMethodFields = `
 id
 paymentMethods {
  ${paymentMethodFields}
}
`;

export const accountsTableFields = `
  id
  entityId
  centerId
  surrogateKey
  center {
    id
    name
  }
  children {
    id
    firstname
    lastname
    nickname
    dob
    archivedAt
    avatar {
      url
    }
    ${isAuRegion ? ccssAttributes : ''}
  }
  tags {
    id
    entityId
    name
    category
  }
  name
  balance
  status
`;

export const centerAccountAndChildrenFields = `
  id
  familyId
  surrogateKey
  name
  children {
    id
    firstname
    lastname
    nickname
    archivedAt
  }
`;

export const accountAndCenterFields = `
  id
  entityId
  centerId
  name
  center {
    id
    name
  }
`;

export const sortedAccountsPagedResultFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${accountsTableFields}
  }
`;
