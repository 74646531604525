import React, { useCallback, useState } from 'react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CreateButton } from 'shared/components/Buttons';
import ProgramChildTable from './ProgramChildTable';
import AddProgramModal from './AddProgramModal';
import { useGetPrograms } from 'gql/program/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useProgramChildCreate } from 'gql/programChild/mutation';
import { showToast } from 'shared/components/Toast';
import { useGetAccountPrograms } from 'gql/programChild/queries';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useGetAccountForNewProgram } from 'gql/programChild/queries';
import { useGetChildFundingSchedulesForAccount } from 'gql/kindyForAll/queries';

interface IProps {
  account: IAccount;
}

const ProgramTab: React.FC<IProps> = ({ account }) => {
  const { t } = useTranslation(['translation', 'subsidies']);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const businessId: string = useSelector((state: RootState) => state.context.businessId) ?? '';

  const hasAgencyBaseCreatePermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });

  const {
    data: accountPrograms,
    loading,
    refetch,
  } = useGetAccountPrograms({
    variables: {
      input: {
        accountId: account?.id,
        businessId: account?.entityId,
      },
    },
    skip: account === null || account === undefined,
  });
  const { data: programs } = useGetPrograms({
    variables: {
      input: {
        businessId,
        centerIds: account?.center?.id ? [account.center.id] : [],
        pageNumber: 1,
        sortDtos: [{ field: 'name', direction: 'ASCENDING' }],
      },
    },
    skip: account?.center?.id === undefined,
  });

  const { data: newProgramData, loading: newProgramLoading } = useGetAccountForNewProgram({
    variables: {
      input: {
        accountId: account?.id,
        businessId: account?.entityId,
      },
    },
    skip: account === null || account === undefined,
  });

  const { data: childFundingSchedules, refetch: refetchFundingSchedules } = useGetChildFundingSchedulesForAccount({
    variables: {
      input: { accountId: account?.id },
    },
    skip: !account,
  });

  const refetchFundingSchedulesCallback = () => {
    if (account) refetchFundingSchedules();
  };

  const [saveProgramChild, { loading: saveLoading }] = useProgramChildCreate({
    onError: () => {
      showToast(t('subsidies:program-child.create-modal.error-saving'), 'error');
    },
    onCompleted: () => {
      setShowCreateModal(false);
      refetch();
      showToast(t('subsidies:program-child.create-modal.success'), 'success');
    },
  });

  const handleSubmit = (values: IProgramChildAddInput) => {
    saveProgramChild({
      variables: {
        input: values,
      },
    });
  };

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <div>
      <div className="d-flex align-items-center mb-4">
        <h3>{t('subsidies:programs')}</h3>
        {hasAgencyBaseCreatePermission && !newProgramLoading && (
          <CreateButton className="ml-auto" variant="primary" onClick={() => setShowCreateModal(true)}>
            {capitalize(t('translation:spelling.create'))}
          </CreateButton>
        )}
      </div>
      {account && !newProgramLoading && (
        <AddProgramModal
          handleClose={() => setShowCreateModal(false)}
          isOpen={showCreateModal}
          account={account}
          handleSubmit={handleSubmit}
          programs={programs?.getProgramsByCenterId.data || []}
          isLoading={saveLoading}
          programChildren={accountPrograms?.getProgramsByAccountId || []}
          initialProgramChildren={newProgramData?.getForNewProgramByAccountId}
        />
      )}
      <ProgramChildTable
        businessId={businessId}
        account={account}
        data={accountPrograms?.getProgramsByAccountId ?? []}
        fundingSchedules={childFundingSchedules?.getKindyChildFundingSchedulesForAccount ?? []}
        loading={loading}
        refetch={handleRefetch}
        refetchFundingSchedules={refetchFundingSchedulesCallback}
      />
    </div>
  );
};

export default ProgramTab;
