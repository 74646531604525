import React from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { useDeactivateFee } from 'gql/fee/mutations';
import * as actions from 'pages/Centers/subroutes/Fees/duck/actions';
import { useDispatch } from 'react-redux';

interface IProps {
  isOpen: boolean;
  fee: IFee;
  onClose: () => void;
}

const DeactivateFeeModal: React.FC<IProps> = ({ isOpen, fee, onClose, ...props }) => {
  const dispatch = useDispatch();
  const [deactivateFeeFn, { loading: deactivateFeeLoading }] = useDeactivateFee({
    onCompleted: (data) => {
      showToast('Successfully deactivated fee.', 'success');
      dispatch(actions.deactivateFee(data.deactivateFee));
      onClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  return (
    <ConfirmationModal
      title="Deactivate Fee?"
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Deactivate"
      primaryCallback={() =>
        deactivateFeeFn({
          variables: {
            id: fee.id,
          },
        })
      }
      primaryButtonProps={{
        variant: 'danger',
        loading: deactivateFeeLoading,
      }}
    >
      <div className="mb-4">Deactivating this fee will make it unavailable to use for contracts.</div>
      <div>Are you sure you want to deactivate this fee?</div>
    </ConfirmationModal>
  );
};

export default DeactivateFeeModal;
