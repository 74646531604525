import React, { useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Button from 'shared/components/Buttons';
import PageWrapper from 'shared/components/PageWrapper';
import './CreateItemizedBill.scss';
import { useCreateItemizedBillScreen } from './hooks/useCreateItemizedBill';
import { showToast } from 'shared/components/Toast';
import Currency from 'shared/components/Currency';
import DataTable from 'shared/components/DataTable';
import { ItemizedBillHeader } from './components/ItemizedBillHeader';

interface IRouteParams {
  accountId: string;
}
interface Props extends RouteComponentProps<IRouteParams, any, {}> {}
export const CreateItemizedBill: React.FC<Props> = (props) => {
  const { accountId } = props.match.params;
  const history = useHistory();
  const handleCancelClick = useCallback(() => {
    history.push(`/families/accounts/${accountId}/billing/itemizedBills`);
  }, [accountId, history]);

  const {
    billingDate,
    billTotal,
    canSave,
    forwardBalance,
    handleLateFeeChange,
    handlePaymentDueDateChange,
    handlePaymentLateDateChange,
    handleSelectionChange,
    includeForwardedBalance,
    lateFee,
    lateFeeDisabled,
    lateFeeRequired,
    loadingUnbilledTransactions,
    paymentDueDate,
    paymentLateDate,
    paymentDateDateIsRequired,
    paymentLateDateIsValid,
    paymentLateDateIsOutOfRange,
    saveItemizedBill,
    savingItemizedBill,
    unbilledItems,
    selectedUnbilledItems,
    subtotal,
  } = useCreateItemizedBillScreen(accountId);

  const handleSendClick = useCallback(async () => {
    try {
      await saveItemizedBill();
      showToast('Successfully created itemized bill', 'success');
      history.push(`/families/accounts/${accountId}/billing/itemizedBills`);
    } catch (e) {
      showToast('Failed to create itemized bill', 'error');
    }
  }, [accountId, history, saveItemizedBill]);

  return (
    <PageWrapper
      pageTitle={'Create Itemized Bill'}
      applyPadding={true}
      buttonComponent={
        <Button disabled={!canSave || savingItemizedBill} onClick={handleSendClick} className="ml-2">
          Send Bill
        </Button>
      }
      secondaryButtonComponent={
        <Button variant="light" onClick={handleCancelClick} disabled={savingItemizedBill}>
          Cancel
        </Button>
      }
    >
      <ItemizedBillHeader
        billingDate={billingDate}
        billTotal={billTotal}
        forwardBalance={forwardBalance}
        onLateFeeChange={handleLateFeeChange}
        onPaymentDueDateChange={handlePaymentDueDateChange}
        onPaymentLateDateChange={handlePaymentLateDateChange}
        includeForwardBalance={includeForwardedBalance}
        lateFee={lateFee}
        lateFeeDisabled={lateFeeDisabled}
        lateFeeIsRequired={lateFeeRequired}
        paymentDueDate={paymentDueDate}
        paymentLateDate={paymentLateDate}
        paymentLateDateIsRequired={paymentDateDateIsRequired}
        paymentLateDateIsValid={paymentLateDateIsValid}
        paymentLateDateIsOutOfRange={paymentLateDateIsOutOfRange}
        subtotal={subtotal}
      ></ItemizedBillHeader>
      <DataTable
        noDataText="No unbilled transactions"
        data={unbilledItems}
        columns={[
          { text: 'Date', dataField: 'date' },
          { text: 'Description', dataField: 'description' },
          { text: 'Amount', dataField: 'amount', formatter: (cell) => <Currency payment={false} amount={cell} /> },
        ]}
        showSelect
        showPagination={false}
        selectedRows={selectedUnbilledItems}
        updateSelectedRows={handleSelectionChange}
        showLoadingOverlay={loadingUnbilledTransactions}
      ></DataTable>
    </PageWrapper>
  );
};
