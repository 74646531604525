// import configDev from './config-dev';
// import configProd from './config-prod';

// export default process.env.NODE_ENV === 'development' ? configDev : configProd;

import config from './config.json';

export default config;

/*if (process.env.NODE_ENV !== 'test')
    return;

// Under test we permit specifying an explicit culture for the localization tests.
const userAgent = (navigator ? navigator.userAgent : undefined) ?? '';

if (userAgent.match(/jsdom/)) {
    const searchParams = new URLSearchParams(location.search);
    const culture = searchParams.get("culture");

    if (culture) {
        // culture is now something like en-AU or en-US
        const [language, region] = culture.split('-');

        config.locale = { language, region };
    }
}
*/
