import { MutationHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  addAccountSuccess,
  addNewContactToAccountSuccess,
  archiveChildOnAccountSuccess,
  removeChildOnAccountSuccess,
  removeContactFromAccountSuccess,
  unarchiveChildOnAccountSuccess,
  updateAccountSuccess,
} from 'pages/Families/subroutes/Accounts/duck/actions';
import { useDispatch } from 'react-redux';
import { useMutation } from 'shared/apis/core';
import { accountContactChildFields, accountContactFields, accountPageFields } from './fields';

interface ICreateAccountVariables {
  input: ICreateAccountInput;
}

interface ICreateAccountData {
  createAccount: IAccount;
}
interface IAddNewContactToAccountVariables {
  input: {
    accountId: string;
    contact: ICreateContactInput | null;
    existingContactId?: string | null;
    children: IAccountContactChildInput[];
    isPrimary: boolean;
    permissions: { type: string }[];
    sendInvite: boolean | null;
  };
}

interface IUpdateAccountContactRelationshipsVariables {
  input: {
    accountId: string;
    contactId: string;
    children: IAccountContactChildInput[];
    isPrimary: boolean;
    email?: string;
    permissions: { type: string }[];
  };
}

interface IAddExistingChildToExistingAccountData {
  addExistingChildToExistingAccount: boolean;
}

interface IAddExistingChildToExistingAccountVariables {
  input: IAddExistingChildToExistingAccountInput;
}

interface IUpdateAccountTagsData {
  updateAccountTags: IAccount;
}

interface IUpdateAccountTagsVariables {
  input: {
    id: string;
    tagIds: string[]; // array of ids
  };
}

export const CREATE_ACCOUNT = gql`
  mutation($input: CreateAccountInput!) {
    createAccount(input: $input){
      ${accountPageFields}
    }
  }
`;

export const ADD_CHILD_TO_ACCOUNT = gql`
  mutation($input: AddChildToAccountInput!) {
    addChildToAccount(input: $input) {
      ${accountPageFields}
    }
  }
`;

export const ADD_NEW_CONTACT_TO_ACCOUNT = gql`
  mutation($input: AddNewContactToAccountInput!) {
    addNewContactToAccount(input: $input) {
      ${accountContactFields}
    }
  }
`;

export const UPDATE_ACCOUNT_CONTACT_RELATIONSHIPS = gql`
  mutation($input: UpdateAccountContactRelationshipsInput!) {
    updateAccountContactRelationships(input: $input) {
      ${accountContactChildFields}
    }
  }
`;

export const REMOVE_CONTACT_FROM_ACCOUNT = gql`
  mutation ($accountId: ID!, $contactId: ID!) {
    removeContactFromAccount(accountId: $accountId, contactId: $contactId) {
      ${accountContactFields}
    }
  }
`;

export const ARCHIVE_CHILD_ON_ACCOUNT = gql`
  mutation ($accountId: ID!, $childId: ID!) {
    archiveChildOnAccount(accountId: $accountId, childId: $childId) {
      id
    }
  }
`;

export const UNARCHIVE_CHILD_ON_ACCOUNT = gql`
  mutation ($accountId: ID!, $childId: ID!) {
    unarchiveChildOnAccount(accountId: $accountId, childId: $childId) {
      id
    }
  }
`;

export const REMOVE_CHILD_ON_ACCOUNT = gql`
  mutation ($accountId: ID!, $childId: ID!) {
    removeChildOnAccount(accountId: $accountId, childId: $childId) {
      id
    }
  }
`;

export const ADD_CENTER_TO_ACCOUNT = gql`
  mutation($input: AddCenterToAccountInput!) {
    addCenterToAccount(input: $input) {
      ${accountPageFields}
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      ${accountPageFields}
    }
  }
`;

export const CREATE_ACCOUNT_WITH_ENROLLMENT = gql`
  mutation($input: CreateAccountInput!) {
    createAccount(input: $input) {
      ${accountPageFields}
    }
  }
`;

export const ADD_EXISTING_CHILD_TO_EXISTING_ACCOUNT = gql`
  mutation ($input: AddExistingChildToExistingAccountInput!) {
    addExistingChildToExistingAccount(input: $input)
  }
`;

export const UPDATE_ACCOUNT_TAGS = gql`
  mutation ($input: UpdateAccountTagsInput!) {
    updateAccountTags(input: $input) {
      id
      tags {
        id
        name
        category
      }
    }
  }
`;

export const useCreateAccount = (
  options?: MutationHookOptions<ICreateAccountData, ICreateAccountVariables> | undefined
) => {
  const dispatch = useDispatch();

  return useMutation<ICreateAccountData, ICreateAccountVariables>(CREATE_ACCOUNT, {
    ...options,
    onCompleted: (data) => {
      dispatch(addAccountSuccess(data.createAccount));
    },
  });
};

export const useAddChildToAccount = () => {
  const dispatch = useDispatch();

  return useMutation<{ addChildToAccount: IAccount }, { input: IAddChildToAccountInput }>(ADD_CHILD_TO_ACCOUNT, {
    onCompleted: (data) => {
      dispatch(updateAccountSuccess(data.addChildToAccount));
    },
  });
};

export const useAddCenterToAccount = () => {
  const dispatch = useDispatch();

  return useMutation<{ addCenterToAccount: IAccount }, { input: IAddCenterToAccountInput }>(ADD_CENTER_TO_ACCOUNT, {
    onCompleted: (data) => {
      dispatch(updateAccountSuccess(data.addCenterToAccount));
    },
  });
};

export const useUpdateAccount = () => {
  const dispatch = useDispatch();

  return useMutation<{ updateAccount: IAccount }, { input: IUpdateAccountInput }>(UPDATE_ACCOUNT, {
    onCompleted: (data) => {
      dispatch(updateAccountSuccess(data.updateAccount));
    },
  });
};

export const useCreateAccountWithEnrollment = () => {
  const dispatch = useDispatch();

  return useMutation<{ createAccount: IAccount }, { input: ICreateAccountWithEnrollmentMutationInput }>(
    CREATE_ACCOUNT_WITH_ENROLLMENT,
    {
      onCompleted: (data) => {
        dispatch(addAccountSuccess(data.createAccount));
      },
    }
  );
};

export const useAddNewContactToAccount = (accountId: string) => {
  const dispatch = useDispatch();

  return useMutation<{ addNewContactToAccount: IContact }, IAddNewContactToAccountVariables>(
    ADD_NEW_CONTACT_TO_ACCOUNT,
    {
      onCompleted: (data) => {
        dispatch(addNewContactToAccountSuccess(accountId, data.addNewContactToAccount));
      },
    }
  );
};

export const useUpdateAccountContactRelationships = (accountId: string, contactId: string) => {
  return useMutation<
    { updateAccountContactRelationships: IContactChild[] },
    IUpdateAccountContactRelationshipsVariables
  >(UPDATE_ACCOUNT_CONTACT_RELATIONSHIPS);
};

export const useRemoveContactFromAccount = (accountId: string, contactId: string) => {
  const dispatch = useDispatch();

  return useMutation<{ removeContactFromAccount: IContact[] }, { accountId: string; contactId: string }>(
    REMOVE_CONTACT_FROM_ACCOUNT,
    {
      variables: { accountId, contactId },
      onCompleted: (data) => {
        dispatch(removeContactFromAccountSuccess(accountId, contactId));
      },
    }
  );
};

export const useArchiveChildOnAccount = (accountId: string, childId: string) => {
  const dispatch = useDispatch();

  return useMutation<{ removeContactFromAccount: IAccountChild[] }, { accountId: string; childId: string }>(
    ARCHIVE_CHILD_ON_ACCOUNT,
    {
      variables: { accountId, childId },
      onCompleted: (data) => {
        dispatch(archiveChildOnAccountSuccess(accountId, childId));
      },
    }
  );
};

export const useAddExistingChildToExistingAccount = (
  options?: MutationHookOptions<IAddExistingChildToExistingAccountData, IAddExistingChildToExistingAccountVariables>,
  fields?: string
) =>
  useMutation<IAddExistingChildToExistingAccountData, IAddExistingChildToExistingAccountVariables>(
    ADD_EXISTING_CHILD_TO_EXISTING_ACCOUNT,
    options
  );

export const useRemoveChildOnAccount = (accountId: string, childId: string) => {
  const dispatch = useDispatch();
  return useMutation<{ removeChildOnAccount: IAccountChild[] }, { accountId: string; childId: string }>(
    REMOVE_CHILD_ON_ACCOUNT,
    {
      variables: { accountId, childId },
      onCompleted: (data) => {
        dispatch(removeChildOnAccountSuccess(accountId, childId));
      },
    }
  );
};

export const useUnarchiveChildOnAccount = (accountId: string, childId: string) => {
  const dispatch = useDispatch();

  return useMutation<{ unarchiveChildOnAccount: IAccountChild[] }, { accountId: string; childId: string }>(
    UNARCHIVE_CHILD_ON_ACCOUNT,
    {
      variables: { accountId, childId },
      onCompleted: (data) => {
        dispatch(unarchiveChildOnAccountSuccess(accountId, childId));
      },
    }
  );
};

export const useUpdateAccountTags = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateAccountTagsData, IUpdateAccountTagsVariables>(UPDATE_ACCOUNT_TAGS, {
    onCompleted: (data) => {
      dispatch(updateAccountSuccess(data.updateAccountTags));
    },
  });
};
