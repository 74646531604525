import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { faDollarSign, faTimes, faUndo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from 'store/reducers';
import DataTable from 'shared/components/DataTable';
import { TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { orderBy } from 'lodash';
import DateInput from 'shared/components/DateInput';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Select from 'shared/components/Select';
import {
  convertTimeRangeObjectToString,
  convertTimeRangeStringToObject,
  timeRangeOptions,
} from 'shared/util/timeUtils';
import colors from '_colors.module.scss';
import useFormatDate from 'shared/hooks/useFormatDate';
import moment from 'moment';
import { IconButtonCircle } from 'shared/components/Buttons';
import { isRegion } from 'shared/util/region';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { setCurrentBusinessFilters, setCurrentCenterFilters } from 'store/context/actions';
import TransactionExpandedRow from 'pages/BillingTransactions/components/TransactionExpandedRow';
import BillingBalanceCard from 'pages/BillingTransactions/components/BillingBalanceCard';
import ReverseTransactionModal from 'pages/BillingTransactions/components/ReverseTransactionModal';
import { useLazyGetTransactionDiscounts, useGetTransactionTypes } from 'gql/transaction/queries';
import ApplyDiscountToTransactionModal from 'pages/BillingTransactions/components/ApplyDiscountToTransactionModal';
import RemoveDiscountFromTransactionModal from 'pages/BillingTransactions/components/RemoveDiscountFromTransactionModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Currency from 'shared/components/Currency';
import { PeriodBasedAccountBalanceTile } from '../components/PeriodBasedAccountBalances';
import { getAppliedDiscountTransactions } from '../../../../../../../BillingTransactions/duck/action';

interface IProps {
  data: ITransactionsWithBalance[];
  loading: boolean;
  dateRange: ITimeRange;
  setDateRange: (tr: ITimeRange) => void;
  approvePaymentLoading?: boolean;
  onApproveFlaggedPayment?: (payment: IPayment) => void;
  onRejectFlaggedPayment?: (payment: IPayment, voidManualCheckPayment: boolean) => void;
  noPadding: boolean;
  accountId?: string;
}

type TransactionCategory = 'manual' | 'automated' | 'attendance';
const automatedTransactionTypeNames = ['Automated CC', 'Automated ACH/DD'];
const attendanceTransactionTypeNames = ['Session Fee', 'Early Fee', 'Late Fee', 'Discount', 'Subsidy'];

interface IModalStateShape {
  open: boolean;
  transaction: ITransaction | null;
}
interface IRemoveDiscountModalStateShape extends IModalStateShape {
  discountTransaction: ITransaction | null;
}

export const getTransactionCategory: (t: ITransaction) => TransactionCategory = (t) => {
  if (!t.transactionType.centerId && !t.transactionType.businessId) {
    if (automatedTransactionTypeNames.includes(t.transactionType.name)) {
      return 'automated';
    } else {
      return attendanceTransactionTypeNames.includes(t.transactionType.name) ? 'attendance' : 'manual';
    }
  } else {
    return 'manual';
  }
};

const isTransactionFlagged: (t: ITransaction) => boolean = (t) =>
  Boolean(t.payment?.flags.length && t.payment.approvedAt === null && t.payment.rejectedAt === null);

const AccountTransactionsTable: React.FC<IProps> = ({
  data,
  loading,
  dateRange,
  setDateRange,
  approvePaymentLoading = false,
  onApproveFlaggedPayment,
  onRejectFlaggedPayment,
  noPadding = false,
  accountId,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const defaultStatus = queryString.parse(location.search).status;
  const user = useSelector((state: RootState) => state.user);

  const { businessFilterIds, centerFilterIds } = useSelector((state: RootState) => state.context);
  const [applyDiscountToTransactionModal, setApplyDiscountToTransactionModal] = useState<IModalStateShape>({
    open: false,
    transaction: null,
  });
  const [removeDiscountModal, setRemoveDiscountModal] = useState<IRemoveDiscountModalStateShape>({
    open: false,
    transaction: null,
    discountTransaction: null,
  });
  const closeRemoveDiscountModal = () =>
    setRemoveDiscountModal({ open: false, transaction: null, discountTransaction: null });
  const [getTransactionDiscountsFn, { loading: appliedDiscountTransactionsLoading }] = useLazyGetTransactionDiscounts();
  const discountRemovalCompleted = (transactionId: string) =>
    getTransactionDiscountsFn({
      fetchPolicy: 'no-cache',
      variables: {
        transactionId,
      },
      onCompleted: (result) => {
        dispatch(getAppliedDiscountTransactions(transactionId, result.getTransactionDiscounts));
      },
    });

  const { data: centers } = useGetActiveCentersWithLoading();
  const { k2TransactionChildName, k2RunningBalance } = useFlags();
  const centerIds = centers?.map((c) => c.id) ?? null;

  const { data: getTransactionTypesData } = useGetTransactionTypes({
    variables: {
      businessId: user?.entityId ?? '',
      centerIds: centerIds,
      manualTransactionTypesOnly: false,
    },
    skip: !user?.entityId,
  });

  const formatDate = useFormatDate();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'date',
    direction: 'desc',
  });
  const [selectedPaymentStatuses, setSelectedPaymentStatuses] = useState<PaymentStatus[]>(
    defaultStatus ? [defaultStatus.toUpperCase()] : []
  );
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState<ITableFilterOption[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<ITableFilterOption[]>([]);
  const [transactionInReverseModal, setTransactionInReverseModal] = useState<ITransaction | null>(null);

  const openingBalance = useSelector((state: RootState) => state.billing.transactions.openingBalance ?? 0);
  let runningBalance = openingBalance;

  const filteredTransactions = orderBy(
    data
      .filter((d: ITransactionsWithBalance) => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        // convert to a positive value since we'll build a negative string
        const amount = (d.amount > 0 ? d.amount : d.amount * -1).toFixed(2);
        const amountAsString = `${d.amount < 0 ? '-' : ''}$${amount.toString()}`;

        let isPartOfActiveFilter = false;
        for (let i = 0; i < selectedPaymentStatuses.length; i++) {
          if (selectedPaymentStatuses[i] === 'FLAGGED' && isTransactionFlagged(d)) {
            isPartOfActiveFilter = true;
            break;
          } else if (!isTransactionFlagged(d) && d.payment?.status === selectedPaymentStatuses[i]) {
            isPartOfActiveFilter = true;
            break;
          }
        }
        return (
          (!businessFilterIds.length || businessFilterIds.includes(d.account.center?.entityId ?? '')) &&
          (!centerFilterIds.length || centerFilterIds.includes(d.account.center?.id ?? '')) &&
          (!selectedCategories.length || selectedCategories.map((c) => c.value).includes(d.transactionTypeId)) &&
          (!selectedPaymentTypes.length ||
            selectedPaymentTypes.map((c) => c.value).includes(getTransactionCategory(d))) &&
          (!selectedPaymentStatuses.length || (d.payment?.status && isPartOfActiveFilter)) &&
          moment(d.date).isSameOrAfter(dateRange.start, 'date') &&
          moment(d.date).isSameOrBefore(dateRange.end, 'date') &&
          (!searchTerm.length ||
            d.transactionNumber?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
            d.description?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
            d.account.name?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
            d.transactionType.name?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
            d.amount.toFixed(2).toString().includes(lowercasedSearchTerm) ||
            amountAsString.includes(lowercasedSearchTerm))
        );
      })
      .sort((a, b) => {
        if (sortField.field === 'transactionNumber') return a.transactionNumber > b.transactionNumber ? 1 : -1;
        else return a.date > b.date ? 1 : -1 && a.transactionNumber > b.transactionNumber ? 1 : -1;
      })
      .map((item) => {
        runningBalance += item.amount;
        return { ...item, balanceSnapshot: runningBalance };
      }),
    sortField.field === 'date' ? ['date', 'transactionNumber'] : sortField.field,
    sortField.field === 'date' ? [sortField.direction, sortField.direction] : sortField.direction
  );

  const instanceOfITransactionsWithBalanceArray = (object: any): object is ITransactionsWithBalance[] => true;
  const closingBalance =
    filteredTransactions.length > 0 && instanceOfITransactionsWithBalanceArray(filteredTransactions)
      ? filteredTransactions
          .map((item) => item.amount)
          .reduce((prev, current) => (prev ?? 0) + (current ?? 0), openingBalance)
      : openingBalance;

  const clearAppliedFilters = useCallback(() => {
    setSelectedPaymentStatuses([]);
    setSelectedPaymentTypes([]);
    dispatch(setCurrentCenterFilters([]));
    dispatch(setCurrentBusinessFilters([]));
    setSelectedCategories([]);
    setDateRange({ start: moment().startOf('month').format(), end: moment().endOf('month').format() });
  }, [dispatch, setDateRange]);

  const getTransactionTypeDisplay = (transactionType: string) => {
    if (transactionType === 'Automated CC' || transactionType === 'Automated ACH/DD') {
      return 'Automated Payment';
    }
    if (transactionType === 'Manual CC' || transactionType === 'Manual ACH/DD') {
      return 'Manual Payment';
    } else return transactionType;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div>
      {isRegion('AU') && k2RunningBalance && (
        <div className="row mb-6">
          <div className="k2-billing-transactions-payment-filters-grid pt-2">
            <BillingBalanceCard balanceType={'Opening'} balance={openingBalance} date={formatDate(dateRange.start)} />
            <BillingBalanceCard balanceType={'Closing'} balance={closingBalance} date={formatDate(dateRange.end)} />
          </div>
        </div>
      )}
      {isRegion('US') && (
        <div>
          <PeriodBasedAccountBalanceTile accountId={accountId ?? ''} className="mb-4"></PeriodBasedAccountBalanceTile>
        </div>
      )}
      <DataTable
        keyField="id"
        noPadding={noPadding}
        data={filteredTransactions}
        dataSize={filteredTransactions.length}
        showPagination={false}
        showLoadingOverlay={loading}
        showSelect={false}
        onSort={(field, direction) => setSortField({ field, direction: direction === 'ASCENDING' ? 'asc' : 'desc' })}
        expandRow={(transaction) => (
          <TransactionExpandedRow
            onAddDiscountToTransaction={(transaction) =>
              setApplyDiscountToTransactionModal({ open: true, transaction })
            }
            onRemoveDiscountFromTransaction={(transaction, discountTransaction) =>
              setRemoveDiscountModal({ open: true, transaction, discountTransaction })
            }
            handleReverse={setTransactionInReverseModal}
            transaction={transaction}
            isPaymentTransactionsOnly={false}
            onApproveFlaggedPayment={onApproveFlaggedPayment}
            onRejectFlaggedPayment={onRejectFlaggedPayment}
            approvePaymentLoading={approvePaymentLoading}
            appliedDiscountTransactionsLoading={appliedDiscountTransactionsLoading}
          />
        )}
        columns={[
          {
            dataField: 'date',
            text: 'Date',
            sort: true,
            formatter: (date: string) => formatDate(date),
          },
          {
            dataField: isRegion('US') ? 'description' : 'transactionNumber',
            text: isRegion('US') ? 'Description' : 'ID',
            sort: true,
          },
          {
            dataField: 'transactionType.name',
            text: 'Type',
            formatter: (transactionType: string) => getTransactionTypeDisplay(transactionType),
            sort: true,
          },
          {
            dataField: 'account.name',
            text: 'Account',
            sort: true,
          },
          k2TransactionChildName && {
            dataField: 'appliedToAccountChild',
            text: 'Child Name',
            sort: false,
            formatter: (accountChild: IAccountChild) =>
              !!accountChild ? `${accountChild.firstname} ${accountChild.lastname}` : '',
          },
          {
            dataField: 'amount',
            text: 'Amount',
            sort: true,
            align: 'right',
            headerAlign: 'right',
            formatter: (total: number, row: ITransaction) => (
              <h6 className={classnames('mr-4')}>
                <Currency payment={!!row.payment} amount={total} />
              </h6>
            ),
          },
          {
            dataField: 'reversedTransactionId',
            text: '',
            formatter: (id: string) => (
              <span className={classnames('fa-layers fa-fw mx-2', { invisible: !id })}>
                <FontAwesomeIcon icon={faUndo} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
            ),
          },
          isRegion('AU') &&
            k2RunningBalance && {
              dataField: 'balanceSnapshot',
              text: 'Balance',
              sort: false,
              align: 'right',
              headerAlign: 'right',
              formatter: (balance: number) => (
                <h6 className={classnames('mr-4')}>
                  <Currency amount={balance} />
                </h6>
              ),
            },
        ].filter((c) => c)}
        renderHeader={() => (
          <div className="align-items-center">
            <TableHeader className="flex-wrap">
              <div className="d-flex flex-wrap align-items-center">
                <TableSearch
                  placeholder={'Search Transactions'}
                  onChange={setSearchTerm}
                  className={isMobile ? 'my-1 mr-4' : 'mr-4 flex-shrink-0 flex-grow-0'}
                />
                <Select
                  options={timeRangeOptions}
                  value={convertTimeRangeObjectToString(dateRange)}
                  onChange={(string) => setDateRange(convertTimeRangeStringToObject(string))}
                  className={isMobile ? 'my-1 mr-auto' : 'mr-4 flex-shrink-3 flex-grow-0 mb-0'}
                />
              </div>
              <div className={isMobile ? 'd-flex flex-nowrap my-1 mr-2' : 'd-flex align-items-center mx-auto'}>
                <DateInput
                  date={dateRange.start}
                  onDateSelect={(start) => setDateRange({ ...dateRange, start })}
                  className="mr-2 flex-shrink-3 flex-grow-0 mb-0"
                />
                <div className={isMobile ? 'mt-2 mr-2' : 'mr-2'}>to</div>
                <DateInput
                  date={dateRange.end}
                  onDateSelect={(end) => setDateRange({ ...dateRange, end })}
                  className="mr-2 flex-shrink-3 flex-grow-0 mb-0"
                />
              </div>
              <div className={isMobile ? 'd-flex align-items-center flex-wrap' : 'd-flex align-items-center'}>
                <DropdownFilter
                  title="Type"
                  className="mr-4"
                  selectedFilters={selectedCategories}
                  options={orderBy(
                    (getTransactionTypesData?.getTransactionTypes ?? []).map((tt) => ({
                      label: tt.name,
                      value: tt.id,
                    })),
                    (tt) => tt.label,
                    'asc'
                  )}
                  onFilterSelect={setSelectedCategories}
                />
                <IconButtonCircle
                  icon={faTimes}
                  onClick={clearAppliedFilters}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                />
              </div>
            </TableHeader>
          </div>
        )}
      />
      {applyDiscountToTransactionModal.transaction && (
        <ApplyDiscountToTransactionModal
          isOpen={applyDiscountToTransactionModal.open}
          transaction={applyDiscountToTransactionModal.transaction}
          onClose={() => setApplyDiscountToTransactionModal({ open: false, transaction: null })}
        />
      )}
      {removeDiscountModal.transaction && removeDiscountModal.discountTransaction && (
        <RemoveDiscountFromTransactionModal
          isOpen={removeDiscountModal.open}
          transaction={removeDiscountModal.transaction}
          discountTransaction={removeDiscountModal.discountTransaction}
          onClose={closeRemoveDiscountModal}
          onComplete={discountRemovalCompleted}
        />
      )}
      {transactionInReverseModal && (
        <ReverseTransactionModal
          isOpen={Boolean(transactionInReverseModal)}
          onClose={() => setTransactionInReverseModal(null)}
          transaction={transactionInReverseModal}
        />
      )}
    </div>
  );
};

export default AccountTransactionsTable;
