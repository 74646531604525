import React from 'react';
import schedulingBanner from './scheduling-visibility.svg';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'shared/util/string';

interface IProps {}

const CreateSchedulingVisibilityInfoCard: React.FC<IProps> = () => {
  const showCartoon = window.matchMedia('(min-width: 1000px)').matches;
  const { t } = useTranslation();

  return (
    <Card bodyClassName="p-8">
      <Row className="flex-nowrap">
        <Row>
          <Col className="pl-8">
            <h3>Don't want certain people scheduled?</h3>
            <br />
            <p>
              If you want to hide business owners and executives from your time log and schedule, please mark them as
              “Do not put on Schedule.”
            </p>
            <p>They will still be able to manage schedules for others if permitted. </p>
          </Col>
        </Row>
        <Row>
          <Col className="pl-8">
            {showCartoon && (
              <img src={schedulingBanner} alt="cartoon helper man" className="align-self-end px-8 d-md-block" />
            )}
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

export default CreateSchedulingVisibilityInfoCard;
