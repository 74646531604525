import React from 'react';
import Card from 'shared/components/Card';
import Button from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

interface IProps {
  disabled?: boolean;
  showChangePinModal: () => void;
  text: string;
}

const ChangePinCard: React.FC<IProps> = ({ disabled = false, text, showChangePinModal, ...props }) => {
  return (
    <Card className="kt-my-profile-change-password-card">
      <div className="d-flex flex-column align-items-center">
        <div className="kt-my-profile-change-pin-circle rounded-circle circle-lg d-flex justify-content-center align-items-center mb-4">
          <FontAwesomeIcon size="2x" icon={faLockAlt} color={colors['dark-gray']} />
        </div>
        <span className="mb-4">{text}</span>
        <Button disabled={disabled} variant="outline-secondary" onClick={showChangePinModal}>
          Change PIN
        </Button>
      </div>
    </Card>
  );
};

export default ChangePinCard;
