import { cloneDeep, nth, take, takeRight } from 'lodash';

export const orderArray = <T = any>(array: T[], sourceIndex: number, destinationIndex: number) => {
  const sourceElement = nth<T>(array, sourceIndex);
  if (!sourceElement) return array;
  const a = take(array, destinationIndex);
  const b = takeRight(array, array.length - destinationIndex);
  if (sourceIndex < destinationIndex) {
    a.splice(sourceIndex, 1);
    b.splice(1, 0, sourceElement);
    return [...a, ...b];
  } else {
    b.splice(sourceIndex - destinationIndex, 1);
    return [...a, sourceElement as T, ...b];
  }
};

export const moveElementBetweenArray = <T = any>(
  sourceArray: T[],
  destinationArray: T[],
  sourceIndex: number,
  destinationIndex: number
) => {
  const mutableSourceArray = cloneDeep(sourceArray);
  const mutableDestinationArray = cloneDeep(destinationArray);
  const source = nth<T>(mutableSourceArray, sourceIndex);
  if (!source) return [sourceArray, destinationArray];
  mutableSourceArray.splice(sourceIndex, 1);
  mutableDestinationArray.splice(destinationIndex, 0, source);

  return [mutableSourceArray, mutableDestinationArray];
};
