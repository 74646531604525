import { Box, Card, Stack, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { DataGrid, GridColDef, GridRowParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { IRowType } from './History';

import './styles.scss';
import { BillingActivityRecord } from './types/AccountOverview';
import useFormatDate from 'shared/hooks/useFormatDate';

interface IProps {
  selectedRow: IRowType | null;
  handleUpdateSelectedRow: (x: IRowType) => void;
  scheduledPayments: BillingActivityRecord[];
  loading?: boolean;
}

const PendingPaymentsTable: React.FC<IProps> = ({
  selectedRow,
  handleUpdateSelectedRow,
  scheduledPayments,
  loading = false,
}) => {
  const theme = useTheme();
  const formatDate = useFormatDate();

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Payment Date',
      description: 'The date the payment is scheduled to go out on.',
      disableReorder: true,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => formatDate(params.value, 'MM/DD/YY'),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      disableReorder: true,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      sortable: false,
    },
    {
      field: 'flagged',
      headerName: 'Flagged',
      description: 'If a payment is flagged it will not be processed',
      disableReorder: true,
      disableColumnMenu: true,
      headerAlign: 'right',
      align: 'right',
      sortable: false,
    },
  ];

  return (
    <>
      <Box>
        <Stack direction="row" spacing={2} justifyContent="space-between" marginBottom={2} alignItems="center">
          <Tooltip title="Payments that are scheduled to be made, but have not yet been made, show here">
            <h2>Scheduled Payments</h2>
          </Tooltip>
        </Stack>
        <Card variant="outlined" sx={{ borderRadius: '4px 4px 4px 4px' }}>
          <DataGrid
            aria-label="Pending Payments Table"
            autoHeight={true}
            columns={columns}
            density="compact"
            getRowClassName={(params) => (params.row.id === selectedRow?.id ? 'selected-table-row' : '')}
            hideFooter
            hideFooterPagination
            loading={loading}
            onRowClick={(params: GridRowParams) =>
              handleUpdateSelectedRow({ id: params.row.id, type: params.row.type })
            }
            rows={scheduledPayments}
            rowSelection={false}
            sx={{
              color: theme.palette.text.primary,
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
              '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
          />
        </Card>
      </Box>
    </>
  );
};

export default PendingPaymentsTable;
