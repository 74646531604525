import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { TimeRange, ClosureResolution, IFormData } from '../types';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import Alert from 'shared/components/Alert';
import Table from 'react-bootstrap/Table';
import TextInput from 'shared/components/TextInput';
import SessionCount from './SessionCount';
import moment from 'moment';

interface IConfirmClosuresProps {
  isOpen: boolean;
  onClose: () => void;
  data: IFormData[];
  handleSubmit: () => void;
  loading: boolean;
}

const CONFIRM_WORD = 'confirm' as const;

const ConfirmClosuresModal = ({ isOpen, onClose, handleSubmit, loading, data }: IConfirmClosuresProps) => {
  const { t } = useTranslation();
  const [confirmInputText, setConfirmInputText] = useState('');

  const centerId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const classes = useSelector((state: RootState) => state.classes.byId);

  return (
    <ConfirmationModal
      size="lg"
      title={t('attendance.manage-closures.add-closure-confirmation-modal.title')}
      show={isOpen}
      onHide={() => {
        onClose();
        setConfirmInputText('');
      }}
      primaryChoice={t('attendance.manage-closures.add-closure-confirmation-modal.button-text')}
      primaryCallback={handleSubmit}
      hideOnCallback={false}
      primaryButtonProps={{
        variant: 'primary',
        loading,
        disabled: confirmInputText.toLowerCase() !== CONFIRM_WORD.toLowerCase(),
      }}
    >
      {data.map((row) => {
        const { classIds, startDate, endDate, closureResolution, timeRange } = row;
        const classesToClose = classIds.map((classId) => classes[classId]?.name ?? '');
        const closedDateRange = `${moment(startDate).format(t('formatters.MM/DD/YYYY'))}${
          endDate ? ` to ${moment(endDate).format(t('formatters.MM/DD/YYYY'))}` : ''
        }`;

        return (
          <>
            <Alert variant="warning">
              <div>
                You are about to close the following class {endDate ? 'between' : 'on the'} <em>{closedDateRange}</em>.
                <br />
                {closureResolution === ClosureResolution.ABSENT && (
                  <>{t('attendance.manage-closures.add-closure-confirmation-modal.absent-description')}</>
                )}
                {closureResolution === ClosureResolution.CLEAR && (
                  <>{t('attendance.manage-closures.add-closure-confirmation-modal.clear-description')}</>
                )}
              </div>
            </Alert>
            <Table borderless className="simple-styled-table my-5">
              <thead>
                <tr>
                  <th>{t('attendance.manage-closures.classes-to-close')}</th>
                  <th>{t('attendance.manage-closures.add-closure-confirmation-modal.affected-bookings')}</th>
                </tr>
              </thead>
              <tbody>
                {classesToClose.map((classToClose, i) => (
                  <tr key={classIds[i]}>
                    <td>{classToClose}</td>
                    <td>
                      <SessionCount
                        classIds={[classIds[i]]}
                        centerId={centerId}
                        startDate={startDate}
                        endDate={timeRange === TimeRange.Single ? startDate : endDate ?? ''}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        );
      })}

      <TextInput
        required
        label={t('attendance.manage-closures.add-closure-confirmation-modal.confirm-input-label', {
          word: CONFIRM_WORD,
        })}
        value={confirmInputText}
        onChange={(v) => setConfirmInputText(v)}
        placeholder={CONFIRM_WORD}
      />
    </ConfirmationModal>
  );
};

export default ConfirmClosuresModal;
