import React from 'react';
import './newBill.scss';
import { faPrint, faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  IconButton as MUIiconButton,
  Box,
  Skeleton,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFormatDate from 'shared/hooks/useFormatDate';
import { Bill } from '../types/Bill';
import { LineItemGroupComponent } from './LineItemGroup';
import { useReactToPrint } from 'react-to-print';
import { parsePhoneNumberWithRegion } from 'shared/util/string';

interface IProps {
  open: boolean;
  onClose: () => void;
  bill: Bill | null;
  loading?: boolean;
}

const NewBill: React.FC<IProps> = ({ open, onClose, bill, loading = false }) => {
  const formatDate = useFormatDate();

  const theme = useTheme();

  const componentRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle component="div" sx={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>
        Bill{' '}
        <MUIiconButton
          size="small"
          onClick={handlePrint}
          title={`PrintBill`}
          sx={{
            position: 'absolute',
            right: 64,
            top: 14,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={faPrint} />
          </Box>
        </MUIiconButton>
        <MUIiconButton
          size="small"
          onClick={onClose}
          title={`Close Bill dialog`}
          sx={{
            position: 'absolute',
            right: 14,
            top: 14,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={faTimes} />
          </Box>
        </MUIiconButton>
      </DialogTitle>
      {loading ? (
        <Skeleton />
      ) : (
        <DialogContent sx={{ marginTop: 2, paddingBottom: '64px' }} ref={componentRef}>
          <div id="header">
            <div id="center-info">
              {bill?.sellerContactInfo?.logo && (
                <div id="center-title">
                  <img id="center-avatar" src={`data:image/png;base64, ${bill?.sellerContactInfo?.logo}`} />
                  <h2>{bill?.sellerContactInfo?.name}</h2>
                </div>
              )}
              <div id="contact-information">
                <div>{bill?.sellerContactInfo?.address?.address1}</div>
                <div>{bill?.sellerContactInfo?.address?.address2}</div>
                <div>
                  {bill?.sellerContactInfo?.address?.city}, {bill?.sellerContactInfo?.address?.state},{' '}
                  {bill?.sellerContactInfo?.address?.postalCode}
                </div>
                <div>
                  {bill?.sellerContactInfo.phoneNumber &&
                    parsePhoneNumberWithRegion(bill?.sellerContactInfo.phoneNumber ?? '').formatNational()}
                </div>
                {bill?.sellerContactInfo.taxId && <div>Tax Id: {bill?.sellerContactInfo.taxId}</div>}
              </div>
            </div>
            <div id="header-info">
              <div className="info">
                <div className="label">Bill No.</div>
                <div className="value">{bill?.billNo}</div>
              </div>
              <div className="info">
                <div className="label">Issue Date</div>
                <div className="value">{formatDate(bill?.billDate ?? '', 'MM/DD/YY')}</div>
              </div>
              <div className="info">
                <div className="label">Account No.</div>
                <div className="value">{bill?.accountNo}</div>
              </div>
            </div>
          </div>

          <div id="summary" className="section">
            <div>
              <div id="total-due" className="info">
                <div className="label">TOTAL DUE</div>
                <div className="value">{bill?.totalDue}</div>
              </div>
              <div id="due-by">
                Your bill is due <b>{formatDate(bill?.dueDate ?? '', 'MM/DD/YY')}</b>
              </div>
            </div>
            <div id="greeting">
              <div>
                <div id="hello">{bill?.greeting?.greeting}</div>
                <span id="message">{bill?.greeting?.body}</span>
              </div>
            </div>
          </div>
          <hr />
          <div id="account-summary" className="section">
            <h3>ACCOUNT SUMMARY</h3>
            <div className="account-summary__content">
              {bill?.accountSummary?.map((summaryItem, index) => (
                <div className="account-summary__content__balances" key={`${summaryItem?.type} ${index}`}>
                  <div>{`${summaryItem.type}${summaryItem.date ? ` on (${summaryItem.date})` : ''}`}:</div>
                  <div>{summaryItem?.amount}</div>
                </div>
              ))}
              <div className="account-summary__content__balances">
                <div>Current Charges:</div>
                <div>{bill?.subTotal}</div>
              </div>
              {(bill?.discounts ?? []).map((d) => (
                <div className="account-summary__content__balances">
                  <div>{d.description}</div>
                  <div>{d.amount}</div>
                </div>
              ))}
              <div className="account-summary__content__balances" id="account-summary-total-due">
                <div>Total Due:</div>
                <div>{bill?.totalDue}</div>
              </div>
              {bill?.latePaymentFee && (
                <div id="late-fee-warning">
                  <em>
                    To avoid a late payment fee of
                    {` `}
                    {bill?.latePaymentFee?.amount},{` `}
                    {bill?.totalDue}
                    {` `}
                    must be received by
                    {` `}
                    {formatDate(bill?.latePaymentFee?.date ?? '', 'MM/DD/YY')}.
                  </em>
                </div>
              )}
            </div>
          </div>

          <hr />

          <div id="bill-details" className="section">
            <h3>CURRENT CHARGES</h3>
            <p>
              Any charges or credits applied to your account after
              {` `}
              {formatDate(bill?.billDate ?? '', 'MM/DD/YY')}
              {` `}
              will be shown on a future bill.
            </p>
          </div>
          <div className="charges">
            {bill?.lineItemGroups?.map((group, index) => (
              <LineItemGroupComponent group={group} key={`${group.name}_${index}`} />
            ))}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default NewBill;
