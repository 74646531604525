import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  icon: IconDefinition;
  title: string;
  url: string;
  instructions?: string;
  iconColor: 'green' | 'red' | 'blue';
}

const TemplateDownload: React.FC<IProps> = ({ icon, title, instructions, iconColor, url, ...props }) => {
  return (
    <a href={url} className="text-decoration-none" download={title}>
      <div className="d-flex flex-row justify-content-between file-display template-download px-4 py-3">
        <div>
          <h6>{title}</h6>
          <div className="description">{instructions || 'Click the icon to download the template.'}</div>
        </div>
        <FontAwesomeIcon className={`med-doc-icon ${iconColor}`} icon={icon} />
      </div>
    </a>
  );
};

export default TemplateDownload;
