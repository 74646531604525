import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileSpreadsheet, faRocket, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import Button from 'shared/components/Buttons';
import { ReportingCategoryEnum } from 'shared/constants/enums/reportingEnums';
import colors from '_colors.module.scss';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable';
import { getReadableReportName, getReadableReportCategory } from 'pages/Reporting/reportingUtils';
import { orderBy } from 'lodash';

interface IProps {
  isLoading: boolean;
  reports: IReport[];
  onRunReportClick: (report: IReport) => void;
}

const ReportsListTable: React.FC<IProps> = ({ isLoading, reports, onRunReportClick, ...props }) => {
  const { t } = useTranslation();
  const getReportExportIcon = useCallback((report: IReport): JSX.Element[] => {
    const getColor = (type: ReportFormatType): string => {
      switch (type) {
        case 'EXCEL':
          return colors.success;
        case 'PDF':
          return colors.danger;
        default:
          return '';
      }
    };

    const getIcon = (type: ReportFormatType): IconDefinition => {
      switch (type) {
        case 'EXCEL':
          return faFileSpreadsheet;
        case 'PDF':
          return faFilePdf;
        default:
          return faRocket;
      }
    };

    return report.formatTypes.map((formatType, idx) => (
      <FontAwesomeIcon
        key={`report-${report.type}-format-type-${formatType}-${idx}`}
        icon={getIcon(formatType)}
        color={getColor(formatType)}
        size="2x"
        className="mr-2"
      />
    ));
  }, []);

  const getReportCategoryColor = useCallback((report: IReport): string => {
    switch (report.categoryType) {
      case ReportingCategoryEnum.FINANCE:
        return colors.teal;
      case ReportingCategoryEnum.FAMILIES:
        return colors.orange;
      case ReportingCategoryEnum.ATTENDANCE:
        return colors.steelBlue;
      default:
        return '';
    }
  }, []);

  if (isLoading) return <DataTableLoadingSkeleton />;

  // Order reports by their display name
  const orderedReports = orderBy(
    reports.map((report) => ({
      name: getReadableReportName(report.type),
      backgroundColor: getReportCategoryColor(report),
      category: getReadableReportCategory(report.categoryType),
      icon: getReportExportIcon(report),
      lastRun: moment(report.lastGeneratedOn).format(t('formatters.date')),
      report,
    })),
    (r) => r.name
  );

  return (
    <Table borderless responsive className="kt-reporting-reports-table">
      <tbody>
        {orderedReports
          .filter(
            (r) =>
              r.report.type !== 'PAYMENT' &&
              r.report.type !== 'TRANSACTION' &&
              r.report.type !== 'CCS_ENROLMENTS' &&
              r.report.type !== 'REENROL_HISTORY'
          )
          .map((r, idx) => (
            <tr key={`report-${idx}`}>
              <td>
                <div className="tag text-white justify-content-center" style={{ backgroundColor: r.backgroundColor }}>
                  {r.category}
                </div>
              </td>
              <td>{r.name}</td>
              <td>{r.icon}</td>
              <td>{r.report.lastGeneratedOn && `Report last run: ${r.lastRun}`}</td>
              <td>
                <div className="d-flex flex-row justify-content-end">
                  <Button variant="secondary" onClick={() => onRunReportClick(r.report)}>
                    Run Export
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default ReportsListTable;
