import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  handleDelete: () => void;
  loading: boolean;
  accountName: string;
}

const DeleteFamilyExclusionsModal: React.FC<IProps> = ({ isOpen, onClose, handleDelete, loading, accountName }) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      title={t('attendance.settings.booking-rules.families.confirmation-modal.title')}
      show={isOpen}
      onHide={onClose}
      primaryChoice={t('attendance.settings.booking-rules.families.confirmation-modal.primary-button-text')}
      primaryCallback={handleDelete}
      hideOnCallback={false}
      primaryButtonProps={{
        variant: 'danger',
        loading: loading,
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: t('attendance.settings.booking-rules.families.confirmation-modal.message', { accountName }),
        }}
      />
    </ConfirmationModal>
  );
};

export default DeleteFamilyExclusionsModal;
