import React from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import Card from 'shared/components/Card';
import { DateRangeInput } from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import { INewSubsidyPaymentFormShape } from './SubsidyPayment';

interface IProps {
  data: INewSubsidyPaymentFormShape;
  readOnly: boolean;
  isLoadingDropdownData: boolean;
  agencyOptions: IAgency[];
  centerOptions: ICenter[];
  onUpdate: React.Dispatch<React.SetStateAction<INewSubsidyPaymentFormShape>>;
}

const SubsidyPaymentInputsCard: React.FC<IProps> = ({
  data,
  readOnly,
  isLoadingDropdownData,
  agencyOptions,
  centerOptions,
  onUpdate,
  ...props
}) => {
  const { t } = useTranslation(['subsidies']);

  const sortedAgencies = orderBy(
    agencyOptions.filter((a) => !a.archivedAt),
    (agency) => agency.name,
    'asc'
  ).map((agency) => ({
    label: agency.name,
    value: agency.id,
  }));
  const sortedCenters = orderBy(centerOptions, (center) => center.name, 'asc').map((center) => ({
    label: center.name,
    value: center.id,
  }));

  return (
    <Card bodyClassName="py-4" className="kt-subsidy-payment-inputs-card">
      <Row>
        <Col>
          <Select
            required
            label={t('subsidies:agency-billing.new-subsidy-payment.agency-label')}
            options={sortedAgencies}
            onChange={(opt) => onUpdate((prev) => ({ ...prev, agencyId: opt.value, centerId: null }))}
            isLoading={isLoadingDropdownData}
            disabled={readOnly}
            value={sortedAgencies.find((agency) => agency.value === data.agencyId)}
          />
        </Col>
        <Col>
          <Select
            required
            label={t('subsidies:agency-billing.new-subsidy-payment.center-label')}
            options={sortedCenters}
            onChange={(opt) => onUpdate((prev) => ({ ...prev, centerId: opt.value }))}
            noOptionsMessage={() =>
              !data.agencyId
                ? t('subsidies:agency-billing.new-subsidy-payment.center-select-agency-message')
                : t('subsidies:agency-billing.new-subsidy-payment.no-centers-message')
            }
            isLoading={isLoadingDropdownData}
            disabled={readOnly}
            value={sortedCenters.find((center) => center.value === data.centerId)}
          />
        </Col>
        <Col> </Col>
      </Row>
      <Row>
        <Col>
          <DateRangeInput
            required
            label={t('subsidies:agency-billing.new-subsidy-payment.period-label')}
            startDate={data.start}
            endDate={data.end}
            onChange={(dates) => onUpdate((prev) => ({ ...prev, start: dates.startDate, end: dates.endDate }))}
            className="kt-date-input-no-max-width"
            disabled={readOnly}
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <CurrencyInput
                required
                className="border-radius-0"
                label={t('subsidies:agency-billing.new-subsidy-payment.amount-label')}
                onChange={(value) => onUpdate((prev) => ({ ...prev, amount: value }))}
                value={data.amount}
                disabled={readOnly}
              />
            </Col>
            <Col>
              <TextInput
                label={t('subsidies:agency-billing.new-subsidy-payment.check-label')}
                onChange={(value) => onUpdate((prev) => ({ ...prev, checkNumber: value }))}
                value={data.checkNumber}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Col>
        <Col> </Col>
      </Row>
    </Card>
  );
};

export default SubsidyPaymentInputsCard;
