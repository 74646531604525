import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageWrapper from 'shared/components/PageWrapper';
import { RootState } from 'store/reducers';
import ChildrenDataTable from './components/ChildrenTable/ChildrenTable';
import { DIRECTIONS } from 'shared/constants/elastic';
import useDatatableState from 'shared/hooks/useDatatableState';
import { useSearchChildren } from './graphql/queries';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import useGetTableMetadata from 'shared/hooks/useGetTableMetadata';
import action from 'shared/constants/enums/actionEnum';

const Children = ({ ...props }) => {
  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'lastName.keyword', direction: DIRECTIONS.ASCENDING }];
  const history = useHistory();
  const [tableState, tableFunctions] = useDatatableState('children', initalTableSort);
  const {
    data: searchChildrenData,
    loading: searchChildrenLoading,
    networkStatus: searchChildrenNetworkStatus,
  } = useSearchChildren(tableFunctions.getElasticQuery());
  const { data: tableMetaData, loading: metaDataLoading } = useGetTableMetadata({ tableName: 'children', fields: [] });
  const children = useSelector((state: RootState) => state.children.all);
  const user = useSelector((state: RootState) => state.user);
  const userCanCreateChild = user?.hasPermission(action.CREATE_CHILD) ?? false;

  const handleRowClick = useCallback(
    (row: IChild) => {
      history.push(`/families/children/${row.id}`);
    },
    [history]
  );

  const handleContactAvatarClick = useCallback(
    (contact: IChildContact) => {
      history.push(`/families/contacts/${contact.id}`);
    },
    [history]
  );

  return (
    <PageWrapper pageTitle="Children" applyPadding={true}>
      <ChildrenDataTable
        data={children}
        dataSize={searchChildrenData?.searchChildren.totalResults ?? 0}
        loading={searchChildrenLoading}
        initialLoading={
          searchChildrenLoading && searchChildrenNetworkStatus !== NETWORK_STATUS.SET_VARIABLES && !children.length
        }
        metaLoading={metaDataLoading}
        // @ts-ignore
        tableMetaData={tableMetaData}
        tableState={tableState}
        tableFunctions={tableFunctions}
        isInternal={user?.isInternal ?? false}
        onRowClick={handleRowClick}
        onContactClick={handleContactAvatarClick}
        navigateToAccountsPage={() => history.push('/families/accounts', { autoOpenSideModal: true })}
        userCanCreateChild={userCanCreateChild}
      />
    </PageWrapper>
  );
};

export default Children;
