import React from 'react';
import './_hexagon.scss';

// svg source: https://codepen.io/wvr/pen/WrNgJp;

const Hexagon = (props) => (
  <svg {...props} className={`hex ${props.className}`} viewBox="0 0 320 277.12812921102034">
    <path
      fill="#513C5E"
      d="M1.5000000000000004 141.16214081686348Q0 138.56406460551017 1.5000000000000004 135.96598839415685L78.5 2.598076211353316Q80 0 83 0L237 0Q240 0 241.5 2.598076211353316L318.5 135.96598839415685Q320 138.56406460551017 318.5 141.16214081686348L241.5 274.530052999667Q240 277.12812921102034 237 277.12812921102034L83 277.12812921102034Q80 277.12812921102034 78.5 274.530052999667Z"
    ></path>
  </svg>
);

export default Hexagon;
