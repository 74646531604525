export const GET_TRANSACTION_TYPES = 'GET_TRANSACTION_TYPES';
export const CREATE_TRANSACTION_TYPES = 'CREATE_TRANSACTION_TYPES';
export const UPDATE_TRANSACTION_TYPE = 'UPDATE_TRANSACTION_TYPE';
export const ARCHIVE_TRANSACTION_TYPE = 'ARCHIVE_TRANSACTION_TYPE';

export const GET_BILLING_CYCLE_TEMPLATES = 'GET_BILLING_CYCLE_TEMPLATES';
export const CREATE_BILLING_CYCLE_TEMPLATE = 'CREATE_BILLING_CYCLE_TEMPLATE';
export const UPDATE_BILLING_CYCLE_TEMPLATE = 'UPDATE_BILLING_CYCLE_TEMPLATE';
export const DELETE_BILLING_CYCLE_TEMPLATE = 'DELETE_BILLING_CYCLE_TEMPLATE';

interface IGetTransactionTypesAction {
  type: typeof GET_TRANSACTION_TYPES;
  transactionTypes: ITransactionType[];
}

interface ICreateTransactionTypesAction {
  type: typeof CREATE_TRANSACTION_TYPES;
  transactionTypes: ITransactionType[];
}

interface IUpdateTransactionTypeAction {
  type: typeof UPDATE_TRANSACTION_TYPE;
  transactionType: ITransactionType;
}

interface IArchiveTransactionTypeAction {
  type: typeof ARCHIVE_TRANSACTION_TYPE;
  transactionType: ITransactionType;
}

interface IGetBillingCycleTemplatesAction {
  type: typeof GET_BILLING_CYCLE_TEMPLATES;
  templates: IBillingCycleTemplate[];
}

interface ICreateBillingCycleTemplateAction {
  type: typeof CREATE_BILLING_CYCLE_TEMPLATE;
  template: IBillingCycleTemplate;
}

interface IUpdateBillingCycleTemplateAction {
  type: typeof UPDATE_BILLING_CYCLE_TEMPLATE;
  template: IBillingCycleTemplate;
}

interface IDeleteBillingCycleTemplateAction {
  type: typeof DELETE_BILLING_CYCLE_TEMPLATE;
  template: IBillingCycleTemplate;
}

export type BillingSettingsActionTypes =
  | IGetTransactionTypesAction
  | ICreateTransactionTypesAction
  | IArchiveTransactionTypeAction
  | IGetBillingCycleTemplatesAction
  | ICreateBillingCycleTemplateAction
  | IUpdateBillingCycleTemplateAction
  | IDeleteBillingCycleTemplateAction
  | IUpdateTransactionTypeAction;
