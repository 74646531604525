import React from 'react';
import { faSchool, faUsersClass, faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Row } from 'shared/components/Layout';
import ClassesBookingTable from './ClassesBookingTable';
import CentersBookingTable from './CentersBookingTable';
import FamilyExclusionsTable from './FamilyExclusionsTable';

interface IProps {
  selectedClasses: IClassBookingSettings[];
  setSelectedClasses: React.Dispatch<React.SetStateAction<IClassBookingSettings[]>>;
  selectedCenters: ICenterClassesCasualBookingSettings[];
  setSelectedCenters: React.Dispatch<React.SetStateAction<ICenterClassesCasualBookingSettings[]>>;
  subTab: AttendanceSettingsSubTab;
  setSubTab: React.Dispatch<React.SetStateAction<AttendanceSettingsSubTab>>;
  classesData: IClassBookingSettings[] | undefined;
  classesLoading: boolean;
  centersData: ICenterClassesCasualBookingSettings[] | undefined;
  centersLoading: boolean;
  handleExclusionDeleteModalOpen: (accountName: string, accountId: string) => void;
}

const BookingRulesTab: React.FC<IProps> = ({
  selectedClasses,
  setSelectedClasses,
  selectedCenters,
  setSelectedCenters,
  subTab,
  setSubTab,
  classesData,
  classesLoading,
  centersData,
  centersLoading,
  handleExclusionDeleteModalOpen,
}) => {
  const { t } = useTranslation();

  return (
    <Tabs
      activeKey={subTab}
      onSelect={(eventKey) => setSubTab(eventKey as AttendanceSettingsSubTab)}
      className="kt-attendance-settings-business-rules-tab"
    >
      <Tab
        eventKey="centers"
        title={
          <Row>
            <FontAwesomeIcon icon={faSchool} size="2x" className="mr-2" />
            {t('attendance.settings.booking-rules.centers.tab-title', 'capitalize')}
          </Row>
        }
      >
        <CentersBookingTable
          selectedCenters={selectedCenters}
          setSelectedCenters={setSelectedCenters}
          data={centersData}
          loading={centersLoading}
          setSubTab={setSubTab}
        />
      </Tab>
      <Tab
        eventKey="classes"
        title={
          <Row>
            <FontAwesomeIcon icon={faUsersClass} size="2x" className="mr-2" />
            {t('attendance.settings.booking-rules.classes.tab-title', 'capitalize')}
          </Row>
        }
      >
        <ClassesBookingTable
          selectedClasses={selectedClasses}
          setSelectedClasses={setSelectedClasses}
          data={classesData}
          loading={classesLoading}
        />
      </Tab>
      <Tab
        eventKey="families"
        title={
          <Row>
            <FontAwesomeIcon icon={faUsers} size="2x" className="mr-2" />
            {t('attendance.settings.booking-rules.families.tab-title', 'capitalize')}
          </Row>
        }
      >
        <FamilyExclusionsTable
          data={centersData}
          loading={centersLoading}
          handleExclusionDeleteModalOpen={handleExclusionDeleteModalOpen}
        />
      </Tab>
    </Tabs>
  );
};

export default BookingRulesTab;
