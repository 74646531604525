import { useUpdateProviderAddress } from 'gql/provider/mutation';
import { useGetProviderAddresses } from 'gql/provider/queries';
import React, { useCallback, useEffect, useState } from 'react';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import NotifiableEventsContainer from '../NotifiableEventsContainer';

interface Props {
  businessId: string;
  providers: IProvider[];
}

const ProviderPhysicalAddress: React.FC<Props> = ({ businessId, providers }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<IProviderAddressInput>({
    businessId,
    startDate: '',
    streetLine1: '',
    streetLine2: '',
    suburb: '',
    state: undefined,
    postcode: '',
    type: undefined,
    providerId: '',
  });
  const [cancelData, setCancelData] = useState<IProviderAddressInput>(formData);

  const stateOptions = STATE_SELECT_OPTIONS['AU'].map(({ label, value }) => ({
    value: value.replace('AU-', ''),
    label,
  }));

  const addressTypeOptions = [
    { value: 'ZPHYSICAL', label: 'Physical' },
    { value: 'ZPOSTAL', label: 'Postal' },
  ];

  const { data: addresses, loading } = useGetProviderAddresses({
    variables: {
      businessId: businessId,
      providerId: formData.providerId,
    },
    skip: formData.providerId === '',
    onCompleted: (res) => {
      const addresses = res.getProviderAddresses;
      const latest = addresses.length > 0 ? addresses[addresses.length - 1] : null;
      if (latest) {
        setFormData((form) => ({ ...form, type: latest.type }));
        setCancelData((form) => ({ ...form, type: latest.type }));
      }
    },
  });

  const [updateAddress, { loading: updateAddressLoading }] = useUpdateProviderAddress({
    onCompleted: () => {
      showToast('Successfully submitted the provider physical address.', 'success');
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  useEffect(() => {
    if (formData.type && addresses) {
      const currentAddress = addresses.getProviderAddresses?.find((address) => address.type === formData.type);
      if (currentAddress) {
        setFormData((form) => ({ ...form, ...currentAddress }));
        setCancelData((form) => ({ ...form, ...currentAddress }));
      }
    }
  }, [formData.type, addresses]);

  useEffect(() => {
    if (providers.length > 0) {
      setFormData((form) => ({
        ...form,
        providerId: providers[0].id,
      }));
      setCancelData((form) => ({
        ...form,
        providerId: providers[0].id,
      }));
    }
  }, [providers]);

  const validate = useCallback(() => {
    const { startDate, streetLine1, suburb, state, postcode, providerId, type } = formData;
    const requiredFields = { startDate, suburb, postcode, streetLine1, providerId };

    return !Object.values(requiredFields).some((val: string | undefined) => val === '') && !!type && !!state;
  }, [formData]);

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }
    const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
    const newAddress = JSON.parse(JSON.stringify(formData), omitTypename);
    updateAddress({
      variables: {
        input: newAddress,
      },
    });
  };

  return (
    <>
      <NotifiableEventsContainer
        title="Provider Address"
        subTitle={undefined}
        notificationMessage="At least 30 days before the event"
        onCancel={() => setFormData(cancelData)}
        onSave={handleSubmit}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!validate()}
        providerOnChange={(option) => setFormData({ ...formData, providerId: option.value })}
        selectedProvider={formData.providerId}
        isLoading={loading}
        isSaving={updateAddressLoading}
        child={
          <>
            <Row>
              <Col className="mb-2">
                <Row>
                  <Col>
                    <DateInput
                      required
                      label="Date of effect"
                      date={formData.startDate}
                      onDateSelect={(date: string) => setFormData({ ...formData, startDate: date })}
                      disabled={false}
                      className="mb-4"
                    />
                  </Col>
                  <Col>
                    <Select
                      required
                      inputId="address-type"
                      label="Type of address"
                      options={addressTypeOptions}
                      value={addressTypeOptions.find((option) => option.value === formData.type)}
                      onChange={(option) => setFormData({ ...formData, type: option.value })}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Address 1"
                  name="address1"
                  value={formData.streetLine1}
                  onChange={(value: string) => setFormData({ ...formData, streetLine1: value })}
                  disabled={false}
                  maxlength="60"
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required={false}
                  label="Address 2"
                  name="address2"
                  value={formData.streetLine2}
                  onChange={(value: string) => setFormData({ ...formData, streetLine2: value })}
                  disabled={false}
                  maxlength="40"
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Suburb"
                  name="suburb"
                  value={formData.suburb}
                  onChange={(value: string) => setFormData({ ...formData, suburb: value })}
                  disabled={false}
                  maxlength="40"
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <Row align="end">
                  <Col md>
                    <Select
                      required
                      inputId="address-state-input"
                      label={'State'}
                      options={stateOptions}
                      value={formData.state}
                      onChange={(option) => setFormData({ ...formData, state: option.value })}
                    />
                  </Col>
                  <Col md>
                    <TextInput
                      required
                      label="Postcode"
                      name="postcode"
                      value={formData.postcode}
                      onChange={(value: string) => setFormData({ ...formData, postcode: value })}
                      disabled={false}
                      maxlength="4"
                    />
                  </Col>
                </Row>
              </Col>
              <Col>{''}</Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default ProviderPhysicalAddress;
