import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { chunk } from 'lodash';
import Carousel from 'react-bootstrap/Carousel';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import ContractCard from './ContractCard';
import colors from '_colors.module.scss';
import { IconButton } from 'shared/components/Buttons';
import useWindowSize from 'shared/hooks/useWindowSize';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {
  contracts: IContract[];
  centerTimezone: Timezone;
  selectContractToUpdate: (contract: IContract) => void;
}

const ContractCarousel: React.FC<IProps> = ({ contracts, centerTimezone, selectContractToUpdate, ...props }) => {
  const windowSize = useWindowSize();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const hasUpdateContractPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.Contracts,
    level: RoleLevelType.Edit,
  });

  useEffect(() => {
    /**
     * if the number of items in the array changes, we're most likely applying/removing a filter
     * if so, go back to the first index since the number of pages most likely changed as well
     */
    if (carouselIndex !== 0) {
      setCarouselIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts.length]);

  const splitContractIntoChunks = useCallback(
    (arr: IContract[]): (IContract | null)[][] => {
      let itemsPerChunk = 1;

      if (windowSize.innerWidth > 1268) {
        itemsPerChunk = 3;
      } else if (windowSize.innerWidth > 768) {
        itemsPerChunk = 2;
      }

      const chunks: (IContract | null)[][] = chunk(arr, itemsPerChunk);

      /**
       * `chunk` will split the array into subarrays where the last chunk is of equal OR less length of the others.
       * since we always want equal length array (so that the contract carousel always renders the same number of columns),
       * we'll fill the last array with nulls until it is the same size as the other arrays. if we encounter a null while rendering,
       * an empty column should be rendered. otherwise the standard ContractCard component should be rendered
       */
      if (chunks.length) {
        const firstChunk = chunks[0];
        const lastChunk = chunks[chunks.length - 1];
        const chunkSize = lastChunk.length !== firstChunk.length ? firstChunk.length : itemsPerChunk;

        while (lastChunk.length < chunkSize) {
          lastChunk.push(null);
        }
      }

      return chunks;
    },
    [windowSize]
  );

  const NUMBER_OF_CAROUSEL_ITEMS = useMemo(
    () => splitContractIntoChunks(contracts).length,
    [splitContractIntoChunks, contracts]
  );

  const next = useCallback(() => {
    let nextIndex = carouselIndex + 1;

    if (nextIndex >= NUMBER_OF_CAROUSEL_ITEMS) {
      nextIndex = 0;
    }

    setCarouselIndex(nextIndex);
  }, [carouselIndex, NUMBER_OF_CAROUSEL_ITEMS]);

  const previous = useCallback(() => {
    let nextIndex = carouselIndex - 1;

    if (nextIndex < 0) {
      nextIndex = NUMBER_OF_CAROUSEL_ITEMS - 1;
    }

    setCarouselIndex(nextIndex);
  }, [carouselIndex, NUMBER_OF_CAROUSEL_ITEMS]);

  if (contracts.length === 0) {
    return (
      <div className="d-flex flex-column h-100">
        <div className="d-flex flex-row">No contracts found.</div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-row">
        <IconButton icon={faAngleLeft} iconSize="2x" onClick={() => previous()} color={colors.secondary} />
        <Carousel
          activeIndex={carouselIndex}
          onSelect={setCarouselIndex}
          controls={false}
          interval={null}
          className="w-100 px-2 kt-account-enrollment-tab-contract-carousel"
        >
          {splitContractIntoChunks(contracts).map((group: (IContract | null)[], idx: number) => (
            <Carousel.Item key={`contract-group-${idx}`}>
              <div className="kt-account-enrollment-tab-contract-carousel-item">
                {group.map((contract: IContract | null, jdx: number) =>
                  contract !== null ? (
                    <ContractCard
                      key={`contract-${1}-${jdx}-group-${idx}`}
                      contract={contract}
                      onUpdateContract={selectContractToUpdate}
                      centerTimezone={centerTimezone}
                      userCanUpdateContract={hasUpdateContractPermission}
                    />
                  ) : (
                    <div className="flex-1 mx-1 h-100" key={`contract-filler-${jdx}-group-${idx}`} />
                  )
                )}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <IconButton icon={faAngleRight} iconSize="2x" onClick={() => next()} color={colors.secondary} />
      </div>
    </div>
  );
};

export default ContractCarousel;
