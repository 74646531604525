import React, { useCallback, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import PageWrapper from 'shared/components/PageWrapper';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import CreateUpdateAgencyModal from './CreateUpdateAgencyModal';
import AgenciesTable from './AgenciesTable';
import ArchiveAgencyModal from './ArchiveAgencyModal';
import { updateAgencyAccountChildrenTableFilters } from '../SubsidyChildren/duck/actions';
import { TABLE_DEFAULTS } from 'shared/components/DataTable';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IAgencyModalStateShape {
  open: boolean;
  agency: IAgency | null;
}

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const Agencies: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['subsidies']);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const [agencyModal, setAgencyModal] = useState<IAgencyModalStateShape>({ open: false, agency: null });
  const [archiveAgencyModal, setArchiveAgencyModal] = useState<IAgencyModalStateShape>({ open: false, agency: null });

  const hasCreateAgencyPermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.AgencyMain,
    level: RoleLevelType.Create,
  });

  const viewChildrenForAgency = useCallback(
    (agency: IAgency) => {
      dispatch(
        updateAgencyAccountChildrenTableFilters({
          pageNumber: TABLE_DEFAULTS.PAGE,
          pageSize: TABLE_DEFAULTS.PAGE_SIZE,
          sortField: 'name',
          sortDirection: 'asc',
          centerIds: [],
          statuses: [],
          term: '',
          agencyIds: [agency.id],
        })
      );
      history.push('/subsidies/children');
    },
    [history, dispatch]
  );

  return (
    <PageWrapper
      pageTitle={t('subsidies:agencies.page-title')}
      mobileButtonComponent={
        <CirclePlusButton
          variant="primary"
          className="my-4"
          onClick={() => setAgencyModal({ open: true, agency: null })}
        />
      }
      buttonComponent={
        hasCreateAgencyPermission && (
          <CreateButton id="create-agency-btn" onClick={() => setAgencyModal({ open: true, agency: null })}>
            {t('subsidies:agencies.create-button-text')}
          </CreateButton>
        )
      }
      applyPadding={true}
    >
      {user?.isInternal && <CenterSelectBanner pageName="agencies" showCenterSelect={false} />}
      <AgenciesTable
        businessId={currentBusinessId ?? ''}
        onEditAgency={(agency) => setAgencyModal({ open: true, agency })}
        onArchiveAgency={(agency) => setArchiveAgencyModal({ open: true, agency })}
        onViewAgencyChildren={viewChildrenForAgency}
      />
      {agencyModal.open && (
        <CreateUpdateAgencyModal
          isOpen={agencyModal.open}
          agencyToUpdate={agencyModal.agency}
          businessId={currentBusinessId ?? ''}
          onClose={() => setAgencyModal({ open: false, agency: null })}
        />
      )}
      {archiveAgencyModal.agency && (
        <ArchiveAgencyModal
          isOpen={archiveAgencyModal.open}
          agency={archiveAgencyModal.agency}
          onClose={() => setArchiveAgencyModal({ open: false, agency: null })}
        />
      )}
    </PageWrapper>
  );
};

export default Agencies;
