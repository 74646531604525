import { glCodeMappingFields } from 'gql/glCode/fields';

export const baseTransactionFields: string = `
  id
  transactionNumber
  accountId
  account {
    id
    name
    centerId
    center {
      id
      name
      entityId
    }
  }
  description
  amount
  date
  transactionTypeId
  transactionType {
    id
    accountType
    name
    isCredit
    isDebit
    isDefaultType
  }
  createdAt
  createdBy
  createdByPerson {
    id
    firstname
    nickname
    lastname
    avatar {
      url
    }
  }
  reversedAt
  reversedBy
  reversedByPerson {
    id
    firstname
    nickname
    lastname
    avatar {
      url
    }
  }
  reversedTransactionId
  reversedByTransactionId
  sessionId
  sessionSource
  session {
    id
    contractId
    date
  }
  payment {
    amount
    approvedAt
    approvedBy
    approvedByPerson {
      id
      firstname
      nickname
      lastname
      avatar {
        url
      }
    }
    depositedAmount
    flags {
      flagType
      id
      paymentId
    }
    id
    paymentMethodUsedLastFour
    paymentMethodUsedType
    processorFailureMessage
    processorTxnId
    rejectedAt
    rejectedBy
    rejectedByPerson {
      id
      firstname
      nickname
      lastname
      avatar {
        url
      }
    }
    serviceFee
    status
    submittedAt
    total
    contactFirstName
    contactLastName
  }
  appliedDiscountTransactions {
    id
    amount
    description
    createdAt
    createdBy
    createdByPerson {
      id
      firstname
      nickname
      lastname
      avatar {
        url
      }
    }
    reversedAt
    reversedBy
    reversedByPerson {
      id
      firstname
      nickname
      lastname
      avatar {
        url
      }
    }
    reversedTransactionId
    reversedByTransactionId
  }
  appliedToAccountChild {
    id
    accountChildId
    firstname
    lastname
    nickname
  }
  flatRates {
    id
    periodStartDate
    periodEndDate
    sessions {
      id
      contractId
      date
    }
  }
`;

export const transactionFields: string = `
  ${baseTransactionFields}
  reversedTransaction {
    ${baseTransactionFields}
  }
  reversedByTransaction {
    ${baseTransactionFields}
  }
  isTransactionTypeReversible
  itemizedBill {
    id
    userFriendlyId
  }
`;

export const transactionsTimeframeFields: string = `
  transactions {
    ${baseTransactionFields}
    reversedTransaction {
      ${baseTransactionFields}
    }
    reversedByTransaction {
      ${baseTransactionFields}
    }
    isTransactionTypeReversible
  }
  openingBalance
  closingBalance
`;

export const statusTransactionsFields: string = `
  status
  value
  numberOfTransactions
`;

export const processingPaymentsFields: string = `
  totalAmountOfPayments
  statusTransactions {
    ${statusTransactionsFields}
  }
`;

export const transactionTypeFields: string = `
  id
  businessId
  centerId
  name
  isDebit
  isCredit
  isDefaultType
  createdAt
  createdBy
  lastModifiedAt
  lastModifiedBy
  archivedAt
  archivedBy
  authorisedRoles
  glCode
  isTuition
  metadata {
    isWritable
  }
  center {
    id
    name
  }
  glCodeMapping {
    ${glCodeMappingFields}
  }
`;

export const paginatedTransactionsInTimeframePagedResults = `
  totalRecords
  pageNumber
  data {
    ${transactionFields}
  }
`;

export const paginatedPaymentsPagedResults = `
  totalRecords
  pageNumber
  data {
    ${transactionFields}
  }
`;

export const sumOfPaymentInTimeFrame = `
  paymentStatus
  totalAmount
  paymentCount
  earliestEntry
  latestEntry
`;
