import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showToast } from 'shared/components/Toast';
import { setAccountAutopayPaymentMethod } from 'pages/Families/subroutes/Accounts/duck/actions';
import PaymentMethodSelectionModal from 'shared/components/PaymentMethodSelectionModal/PaymentMethodSelectionModal';
import { useSetAccountAutopayPaymentMethod } from 'gql/paymentMethod/account/mutations';

interface IProps {
  isOpen: boolean;
  accountId: string;
  paymentMethods: IPaymentMethod[];
  onClose: () => void;
}

const AutoPayModal: React.FC<IProps> = ({ isOpen, accountId, paymentMethods, onClose }) => {
  const { t } = useTranslation(['billing']);
  const dispatch = useDispatch();

  const [isAutoPayEnabled, setEnableAutopay] = useState(paymentMethods.find((pm) => pm.isAutoPay) ? true : false);

  const [autoPayPaymentMethod, setAutoPayPaymentMethod] = useState<IPaymentMethod | null>(
    paymentMethods.find((pm) => pm.isAutoPay) ?? null
  );

  useEffect(() => {
    if (!isAutoPayEnabled) {
      setAutoPayPaymentMethod(null);
    }
  }, [isAutoPayEnabled]);

  const [updateAccountAutopayFn, { loading: updateAccountAutopayLoading }] = useSetAccountAutopayPaymentMethod({
    onCompleted: (result) => {
      dispatch(
        setAccountAutopayPaymentMethod(accountId, result.setPaymentMethodIsAutoPay?.autoPayPaymentMethodId ?? null)
      );
      showToast(t('billing:payment-methods.auto-pay-modal.success-toast-message'), 'success');
      handleDismiss();
    },
    onError: (err) => {
      showToast(t('billing:payment-methods.auto-pay-modal.error-toast-message'), 'error');
    },
  });

  const handleDismiss = useCallback(() => {
    setAutoPayPaymentMethod(paymentMethods.find((pm) => pm.isAutoPay) ?? null);
    onClose();
  }, [paymentMethods, onClose]);

  const handleSubmit = useCallback(() => {
    updateAccountAutopayFn({
      variables: {
        input: {
          accountId,
          newAutopayPaymentId: autoPayPaymentMethod?.id,
          currentPaymentMethodId: paymentMethods.find((pm) => pm.isAutoPay)?.id,
        },
      },
    });
  }, [updateAccountAutopayFn, accountId, autoPayPaymentMethod]);

  const isValid =
    ((isAutoPayEnabled && autoPayPaymentMethod !== null) || !isAutoPayEnabled) && paymentMethods?.length > 0;

  return (
    <PaymentMethodSelectionModal
      selectedMethod={autoPayPaymentMethod}
      paymentMethods={paymentMethods}
      isOpen={isOpen}
      title={t('billing:payment-methods.auto-pay-modal.title')}
      description={t('billing:payment-methods.auto-pay-description')}
      loading={updateAccountAutopayLoading}
      onHide={handleDismiss}
      handleSubmit={handleSubmit}
      collapseText={t('billing:payment-methods.auto-pay-checkbox-label')}
      onChange={(paymentMethod: IPaymentMethod) => setAutoPayPaymentMethod(paymentMethod)}
      selectDisabled={!isAutoPayEnabled}
      disabled={!isValid}
      showToggle
      switchProps={{
        onChange: setEnableAutopay,
        value: isAutoPayEnabled,
        label: t('billing:payment-methods.auto-pay-enabled'),
        labelSide: 'left',
      }}
      termsAndConditionsConfirmed={false}
      setTermsAndConditionsConfirmed={() => {}}
    />
  );
};

export default AutoPayModal;
