import React from 'react';
import { Row } from 'shared/components/Layout';
import ServiceFeeRule from '../ServiceFeeRule';
import Select from 'shared/components/Select';
import ActionDropdown from 'shared/components/ActionDropdown';

interface IProps {
  serviceFee: IServiceFee;
  serviceFeeRuleGroup: IServiceFeeRuleGroup;
  level: number;
  onAdd: (serviceFee: IServiceFee, parentGroup: IServiceFeeRuleGroup | undefined) => void;
  onUpdate: (serviceFee: IServiceFee, updateGroup: IServiceFeeRuleGroup) => void;
  onDelete: (
    serviceFee: IServiceFee,
    deleteGroup: IServiceFeeRuleGroup,
    parentGroup: IServiceFeeRuleGroup | undefined
  ) => void;
  onAddRule: (parentGroup: IServiceFeeRuleGroup) => void;
  onUpdateRule: (serviceFee: IServiceFee, updateRule: IServiceFeeRule, parentGroup: IServiceFeeRuleGroup) => void;
  onDeleteRule: (deleteRule: IServiceFeeRule, parentGroup: IServiceFeeRuleGroup) => void;
}

const ServiceFeeRuleGroup: React.FC<IProps> = ({
  serviceFee,
  serviceFeeRuleGroup,
  level,
  onUpdate,
  onAdd,
  onDelete,
  onAddRule,
  onUpdateRule,
  onDeleteRule,
}) => {
  const matchTypeOptions: { value: MatchType; label: string }[] = [
    { value: 'ALL', label: 'All' },
    { value: 'ANY', label: 'Any' },
  ];

  return (
    <section id="service-fee-rule-groups-section">
      <Row className="mb-4" noGutters>
        <span className="mr-2">Match </span>
        <Select
          className="mb-0 flex-grow-0"
          options={matchTypeOptions}
          getOptionLabel={(g) => g.label}
          getOptionValue={(g) => g.value}
          value={serviceFeeRuleGroup.matchType}
          onChange={(option) => onUpdate(serviceFee, { ...serviceFeeRuleGroup, matchType: option.value })}
        />
        <span className="mr-2 ml-2"> of the following rules:</span>
        <ActionDropdown
          className="ml-auto"
          actions={[
            {
              label: 'Add Rule',
              onClick: () => onAddRule(serviceFeeRuleGroup),
            },
            {
              label: 'Add Rule Group',
              onClick: () => onAdd(serviceFee, serviceFeeRuleGroup),
            },
            {
              label: 'Delete Rule Group',
              onClick: () => onDelete(serviceFee, serviceFeeRuleGroup, serviceFeeRuleGroup.serviceFeeRuleGroupParent),
            },
          ]}
        />
      </Row>
      <div className="ml-6">
        {serviceFeeRuleGroup.serviceFeeRules.map((rule) => (
          <ServiceFeeRule
            key={rule.id}
            serviceFee={serviceFee}
            serviceFeeRule={rule}
            parentGroup={serviceFeeRuleGroup}
            level={level + 1}
            onAdd={onAddRule}
            onUpdate={onUpdateRule}
            onDelete={onDeleteRule}
          />
        ))}
        {serviceFeeRuleGroup.serviceFeeRuleGroups.map((group) => (
          <ServiceFeeRuleGroup
            key={group.id}
            serviceFee={serviceFee}
            serviceFeeRuleGroup={group}
            level={level + 1}
            onUpdate={onUpdate}
            onAdd={onAdd}
            onDelete={onDelete}
            onAddRule={onAddRule}
            onUpdateRule={onUpdateRule}
            onDeleteRule={onDeleteRule}
          />
        ))}
      </div>
    </section>
  );
};

export default ServiceFeeRuleGroup;
