import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '_colors.module.scss';
import { Col, Row } from 'shared/components/Layout';
import _ from 'lodash';
import AccountLink from 'shared/components/AccountLink/AccountLink';
import { Badge } from 'react-bootstrap';

interface IProps {
  sectionTitle: string;
  sectionLinkTitle: string;
  enrolFormUrl: string;
  accountId: string;
  accountName: string;
  showAccountLink?: boolean;
  showLink?: boolean;
  isNewChildOrContact?: boolean;
}

const FormDiffSection: React.FC<IProps> = ({
  enrolFormUrl,
  sectionTitle,
  sectionLinkTitle,
  accountId,
  accountName,
  showAccountLink = false,
  showLink = false,
  isNewChildOrContact = false,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'enrollment']);

  return (
    <>
      <Row className="mb-2">
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              <h4 className="mb-0">{sectionTitle}:</h4>
              {showAccountLink && (
                <div className="ml-2 accountLink">
                  <AccountLink accountId={accountId} accountName={accountName} openInNewTab />
                </div>
              )}
              {!showAccountLink && showLink && (
                <a
                  href={enrolFormUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mr-4 mb-0 d-block text-primary text-primary-hover"
                >
                  <h4 className="ml-2 mb-0 text-primary text-primary-hover">{sectionLinkTitle}</h4>
                </a>
              )}
              {!showAccountLink && !showLink && <h4 className="ml-2 mb-0 text-primary">{sectionLinkTitle}</h4>}
            </div>
            {isNewChildOrContact && <Badge className="new-badge">NEW</Badge>}
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>{props.children}</Col>
      </Row>
    </>
  );
};

export default FormDiffSection;
