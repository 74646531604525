import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { customFieldValuesResponse } from './fields';
import { customFieldDocumentValuesResponse } from './fields';
import {
  ICustomField,
  ICustomFieldValue,
  IGetCustomFieldDocumentValuesInput,
  IGetCustomFieldValuesInput,
} from 'shared/types/customField';

const GET_CUSTOM_FIELD_VALUES = gql`
  query($input: GetCustomFieldValuesInput!) {
    getCustomFieldValues(input: $input) {
      ${customFieldValuesResponse}
    }
  }
`;

const GET_CUSTOM_FIELD_DOCUMENT_VALUES = gql`
  query($input: GetCustomFieldDocumentValuesInput!) {
    getCustomFieldDocumentValues(input: $input) {
      ${customFieldDocumentValuesResponse}
    }
  }
`;

interface IGetCustomFieldValuesResponse {
  area: string;
  centerId: string;
  entityId: string;
  customFields: ICustomField[];
  values: ICustomFieldValue[];
}

interface IGetCustomFieldDocumentValuesResponse {
  id: string;
  businessId: string;
  name: string;
  type: string;
  link: string;
  customFieldArea: string;
  createdBy: string;
  createdAt: string;
}

interface IGetCustomFieldValuesData {
  getCustomFieldValues: IGetCustomFieldValuesResponse;
}

interface IGetCustomFieldValuesVariables {
  input: IGetCustomFieldValuesInput;
}

interface IGetCustomFieldDocumentValuesData {
  getCustomFieldDocumentValues: IGetCustomFieldDocumentValuesResponse[];
}

interface IGetCustomFieldDocumentValuesVariables {
  input: IGetCustomFieldDocumentValuesInput;
}

export const useGetCustomFieldValues = (
  options?: QueryHookOptions<IGetCustomFieldValuesData, IGetCustomFieldValuesVariables>
) =>
  useQuery<IGetCustomFieldValuesData, IGetCustomFieldValuesVariables>(GET_CUSTOM_FIELD_VALUES, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useGetCustomFieldDocumentValues = (
  options?: QueryHookOptions<IGetCustomFieldDocumentValuesData, IGetCustomFieldDocumentValuesVariables>
) =>
  useQuery<IGetCustomFieldDocumentValuesData, IGetCustomFieldDocumentValuesVariables>(
    GET_CUSTOM_FIELD_DOCUMENT_VALUES,
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );
