import { glCodeMappingFields } from '../glCode/fields';

export const feeFields = `
  id
  name
  centerId
  classIds
  schedules {
    id
    startDate
    startTime
    endTime
    overtimeCost
    overtimeGracePeriod
    overtimeInterval
    rates {
      value
      qualifyingNumber
    }
    archivedAt
  }
  feeType
  feeStructure
  utilization
  deactivatedAt
  glCode
  glCodeMapping {
    ${glCodeMappingFields}
  }
`;
