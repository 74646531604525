import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerCheck,
  faMapMarkerMinus,
  faMapMarkerTimes,
  faMapMarkerQuestion,
  faMapMarkerExclamation,
} from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

interface IProps {
  session: ISession;
  timezone: string;
}

const AttendanceStatusIcon: React.FC<IProps> = ({ session, timezone, ...props }) => {
  {
    if (session.absence) {
      return <FontAwesomeIcon icon={faMapMarkerTimes} color={colors.secondaryOrange} size="2x" />;
    }

    if (session.timeEntries.length) {
      return session.timeEntries.every((te) => te.timeOut) ? (
        <FontAwesomeIcon icon={faMapMarkerMinus} color={colors.slate} size="2x" />
      ) : (
        <FontAwesomeIcon icon={faMapMarkerCheck} color={colors.success} size="2x" />
      );
    }
    if (session.dropOffTime) {
      const dropOffTime = moment(session.dropOffTime, 'HH:mm');
      const dropOffDateAndTime = moment(session.date)
        .tz(timezone)
        .set('hours', dropOffTime.hours())
        .set('minutes', dropOffTime.minutes());
      if (moment().tz(timezone).isAfter(dropOffDateAndTime)) {
        return <FontAwesomeIcon icon={faMapMarkerExclamation} color={colors.danger} size="2x" />;
      }
    }
    return <FontAwesomeIcon icon={faMapMarkerQuestion} color={colors.info} size="2x" />;
  }
};

export default AttendanceStatusIcon;
