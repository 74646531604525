import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { agencyAccountChildFields, agencyFields, agencyPaymentFields } from './fields';

interface IGetAgenciesForBusinessData {
  getAgenciesForBusiness: IAgency[];
}

interface IGetAgenciesForBusinessVariables {
  businessId: string;
}

interface IGetPaginatedAgenciesForBusinessData {
  getPaginatedAgenciesForBusiness: IPagedResult<IAgency>;
}

interface IGetPaginatedAgenciesForBusinessVariables {
  input: {
    businessId: string;
    searchKey?: string | null;
    sortBy: string;
    sortDirection: string;
    pageNumber: number;
    pageSize: number;
    centerIds?: string[] | null;
    states?: string[] | null;
    counties?: string[] | null;
    showArchived: boolean;
  };
}

interface IGetPaginatedAgencyAccountChildrenForBusinessData {
  getPaginatedAgencyAccountChildrenForBusiness: IGetPaginatedAgencyAccountChildrenForBusinessQueryData;
}

interface IGetPaginatedAgencyAccountChildrenForBusinessVariables {
  input: {
    businessId: string;
    searchKey: string | null;
    sortBy: string;
    sortDirection: string;
    pageNumber: number;
    pageSize: number;
    agencyIds: string[] | null;
    centerIds: string[] | null;
    statuses: AgencyAccountChildStatus[] | null;
  };
}

interface IGetChildrenForAgencyPaymentAtCenterData {
  getChildrenForAgencyPaymentAtCenter: IChildForAgencyPaymentAtCenter[];
}

interface IGetChildrenForAgencyPaymentAtCenterVariables {
  input: {
    businessId: string;
    agencyId: string;
    centerId: string;
    startDate: string;
    endDate: string;
  };
}

interface IGetPaginatedAgencyPaymentsForBusinessData {
  getPaginatedAgencyPaymentsForBusiness: {
    completed: IPagedResult<IAgencyPayment>;
    draft: IPagedResult<IAgencyPayment>;
  };
}

interface IGetPaginatedAgencyPaymentsForBusinessVariables {
  completedInput: {
    businessId: string;
    searchKey: string | null;
    sortBy: string;
    sortDirection: string;
    pageNumber: number;
    pageSize: number;
    agencyIds: string[] | null;
    centerIds: string[] | null;
    start: string;
    end: string;
  };
  draftInput: {
    businessId: string;
    searchKey: string | null;
    sortBy: string;
    sortDirection: string;
    pageNumber: number;
    pageSize: number;
    agencyIds: string[] | null;
    centerIds: string[] | null;
    start: string;
    end: string;
  };
}

interface IGetAgencyPaymentData {
  getAgencyPayment: IAgencyPayment;
}

interface IGetAgencyPaymentVariables {
  id: string;
}

export const GET_AGENCIES_FOR_BUSINESS = (fields: string = agencyFields) => gql`
  query ($businessId: ID!) {
    getAgenciesForBusiness(businessId: $businessId) {
      ${fields}
    }
  }
`;

export const GET_PAGINATED_AGENCIES_FOR_BUSINESS = (fields: string = agencyFields) => gql`
  query ($input: GetAgenciesInput!) {
    getPaginatedAgenciesForBusiness(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        ${fields}
      }
    }
  }
`;

export const GET_PAGINATED_AGENCY_ACCOUNT_CHILDREN_FOR_BUSINESS = (fields: string = agencyAccountChildFields) => gql`
  query ($input: GetAgencyAccountChildrenInput!) {
    getPaginatedAgencyAccountChildrenForBusiness(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        ${fields}
      }
      metaData {
        numberOfExpired
        numberOfExpiringSoon
        numberOfActive
        numberOfFuture
      }
    }
  }
`;

export const GET_CHILD_FOR_AGENCY_PAYMENT_AT_CENTER = () => gql`
  query ($input: GetChildrenForAgencyPaymentAtCenterInput!) {
    getChildrenForAgencyPaymentAtCenter(input: $input) {
      accountId
      accountChildId
      accountChildEnrollmentId
      childFirstName
      childLastName
      childAgencyId
      accountName
      contractedNumberOfSessions
      expectedSubsidyAmount
    }
  }
`;

export const GET_PAGINATED_AGENCY_PAYMENTS_FOR_BUSINESS = (fields: string = agencyPaymentFields) => gql`
  query($completedInput: GetPaginatedAgencyPaymentsInput!, $draftInput: GetPaginatedAgencyPaymentsInput!) {
    getPaginatedAgencyPaymentsForBusiness(completedInput: $completedInput, draftInput: $draftInput) {
      completed {
        pageNumber
        pageSize
        totalRecords
        data {
          ${fields}
        }
      }
      draft {
        pageNumber
        pageSize
        totalRecords
        data {
          ${fields}
        }
      }
    }
  }
`;

export const IGetAgencyPaymentVariables = (fields: string = agencyPaymentFields) => gql`
  query($id: ID!) {
    getAgencyPayment(id: $id) {
      ${fields}
    }
  }
`;

export const useGetAgenciesForBusiness = (
  options?: QueryHookOptions<IGetAgenciesForBusinessData, IGetAgenciesForBusinessVariables>,
  fields?: string
) =>
  useQuery<IGetAgenciesForBusinessData, IGetAgenciesForBusinessVariables>(GET_AGENCIES_FOR_BUSINESS(fields), options);

export const useGetPaginatedAgenciesForBusiness = (
  options?: QueryHookOptions<IGetPaginatedAgenciesForBusinessData, IGetPaginatedAgenciesForBusinessVariables>,
  fields?: string
) =>
  useQuery<IGetPaginatedAgenciesForBusinessData, IGetPaginatedAgenciesForBusinessVariables>(
    GET_PAGINATED_AGENCIES_FOR_BUSINESS(fields),
    options
  );

export const useGetPaginatedAgencyAccountChildrenForBusiness = (
  options?: QueryHookOptions<
    IGetPaginatedAgencyAccountChildrenForBusinessData,
    IGetPaginatedAgencyAccountChildrenForBusinessVariables
  >,
  fields?: string
) =>
  useQuery<IGetPaginatedAgencyAccountChildrenForBusinessData, IGetPaginatedAgencyAccountChildrenForBusinessVariables>(
    GET_PAGINATED_AGENCY_ACCOUNT_CHILDREN_FOR_BUSINESS(fields),
    options
  );

export const useGetChildrenForAgencyPaymentAtCenter = (
  options?: QueryHookOptions<IGetChildrenForAgencyPaymentAtCenterData, IGetChildrenForAgencyPaymentAtCenterVariables>,
  fields?: string
) =>
  useQuery<IGetChildrenForAgencyPaymentAtCenterData, IGetChildrenForAgencyPaymentAtCenterVariables>(
    GET_CHILD_FOR_AGENCY_PAYMENT_AT_CENTER(),
    options
  );

export const useGetPaginatedAgencyPaymentsForBusiness = (
  options?: QueryHookOptions<
    IGetPaginatedAgencyPaymentsForBusinessData,
    IGetPaginatedAgencyPaymentsForBusinessVariables
  >,
  fields?: string
) =>
  useQuery<IGetPaginatedAgencyPaymentsForBusinessData, IGetPaginatedAgencyPaymentsForBusinessVariables>(
    GET_PAGINATED_AGENCY_PAYMENTS_FOR_BUSINESS(fields),
    options
  );

export const useGetAgencyPayment = (
  options?: QueryHookOptions<IGetAgencyPaymentData, IGetAgencyPaymentVariables>,
  fields?: string
) => useQuery<IGetAgencyPaymentData, IGetAgencyPaymentVariables>(IGetAgencyPaymentVariables(fields), options);
