import React, { useState } from 'react';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { useGetISInnovativeCasesForCenters } from 'gql/iSCase/queries';
import { ISCaseStatusEnum, ISCaseStatusLabelEnum } from 'shared/constants/enums/isCaseEnums';
import ISInnovativeSolutionsTable from 'pages/ISCases/components/ISInnovativeSolutionsTable';

interface ISort {
  field: string;
  direction: ElasticsearchSortDirection;
}
interface IProps {
  selectedBusiness: ITableFilterOption | null;
}

const ISInnovativeSolutionsTab: React.FC<IProps> = ({ selectedBusiness }) => {
  const businessId = selectedBusiness?.value || '';
  const { data: centers } = useGetActiveCentersWithLoading();
  const [tableState, tableFunctions] = useDatatableState();
  const [sort, setSortOptions] = useState<ISort[]>([{ field: 'centerName', direction: 'ASCENDING' }]);
  const [activeFilters, setActiveFilters] = useState<IISCaseFilter>({
    centers: [],
    statuses: [
      {
        label: ISCaseStatusLabelEnum.ACTIVE,
        value: ISCaseStatusEnum.ACTIVE,
      },
    ],
    searchText: '',
  });

  const { data: isInnovativeCasesData, loading: isInnovativeCasesDataLoading } = useGetISInnovativeCasesForCenters({
    variables: {
      input: {
        businessId,
        centerIds: activeFilters?.centers.map((center) => center.value) ?? [],
        includeActive: true,
        includeInActive: false,
        searchText: activeFilters.searchText,
        sortDtos: sort,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
      },
    },
  });

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field, direction }]);
  };

  const handleFilterChange = (newFilters: IISCaseFilter) => {
    setActiveFilters(() => newFilters);
  };

  return (
    <>
      <ISInnovativeSolutionsTable
        data={isInnovativeCasesData?.getIsInnovativeCasesForCenters.data || []}
        dataSize={isInnovativeCasesData?.getIsInnovativeCasesForCenters.totalRecords || 0}
        loading={isInnovativeCasesDataLoading}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        onSort={handleSort}
        activeFilters={activeFilters}
        onActiveFilterChange={handleFilterChange}
        centers={centers || []}
      />
    </>
  );
};

export default ISInnovativeSolutionsTab;
