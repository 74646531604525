import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'shared/components/PageWrapper';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import TabTitleWithCount from 'shared/components/Tabs/TabTitleWithCount';
import { ApplicationFlowType, ApplicationStage } from 'generated/graphql';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import LeadTable from './components/LeadTable';
import { NEW_TAB_FOR_NORMAL_FLOW, NORMAL_FLOW_TABS, PROGRAM_FLOW_TABS } from './types';
import LeadsContextProvider, { useLeadsContext } from './LeadsContext';
import EditApplicationModal from './components/EditApplicationModal';
import SearchAvailabilityModal from './components/SearchAvailabilityModal';
import BulkApproveModal from './components/BulkApproveModal';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { CreateButton } from 'shared/components/Buttons';
import { AddEnquiryModal } from './components/AddEnquiryModal/AddEnquiryModal';
import { EnquiryForm } from './components/AddEnquiryModal/EnquiryForm';
import ApplicationNotesModal from './components/LeadDetails/ApplicationNotes/ApplicationNotesModal';
import EnrollmentFormDiffModal from './components/EnrollmentFormDiffModal';

interface IRouteProps {
  stage?: string;
}

interface IProps extends RouteComponentProps<{ stage?: string }, any, IRouteProps> {}

const Leads: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEnquiryFormOpen, setEnquiryFormOpen] = useState<boolean>(false);
  const [currentCenterId, setCurrentCenterId] = useState<string>('');

  const {
    applicationFlowType,
    activeTab,
    setActiveTab,
    tableFunctions,
    tableState,
    selectedApplication,
    setSelectedApplication,
    setSelectedChild,
    selectedChild,
    isSearchOpen,
    setIsSearchOpen,
    resetFilters,
    flowSettingLoading,
    applicationsAggregateData,
    handleRefetchApplications,
    enrolmentFormDiffData,
    setEnrolmentFormDiffData,
    isEnrolmentFormDiffModalOpen,
    setIsEnrolmentFormDiffModalOpen,
  } = useLeadsContext();

  const TabItems =
    applicationFlowType === ApplicationFlowType.InquireOfferEnrollment ? NORMAL_FLOW_TABS : PROGRAM_FLOW_TABS;

  const getStageTabIsNewTab = (stage: string) =>
    applicationFlowType === ApplicationFlowType.InquireOfferEnrollment ? stage === NEW_TAB_FOR_NORMAL_FLOW : false;

  const handleTabSwitch = useCallback(
    (eventKey: string | null) => {
      resetFilters();
      tableFunctions.changePage(1, tableState.pageSize);
      tableFunctions.updateSelectedRows([]);
      history.push(`/enrollment/lead-management/${eventKey}`);
    },
    [tableFunctions, tableState, resetFilters, history]
  );

  if (flowSettingLoading) {
    return <DataTableLoadingSkeleton />;
  }

  const getAggregateStageItems = (stage: string) => {
    return applicationsAggregateData?.getStageAggregation.filter((item) => {
      return item?.stage === stage;
    });
  };

  const getStageCount = (stage: string) => {
    const stageItems = getAggregateStageItems(stage);
    const readCount = stageItems?.find((item) => item.userFlag !== 'read')?.count ?? 0;

    return getStageTabIsNewTab(stage) && readCount > 0
      ? readCount
      : stageItems?.reduce((count: number, curr) => (count += curr.count), 0) ?? 0;
  };

  const getShouldHighlight = (stage: string) => {
    const stageItems = getAggregateStageItems(stage);
    return getStageTabIsNewTab(stage) ? (stageItems?.find((item) => item.userFlag !== 'read')?.count ?? 0) > 0 : false;
  };

  const isAnyErroredApplications = () => {
    return getStageCount(ApplicationStage.Errored) > 0;
  };

  const { stage } = props.match.params;
  if (!!stage && (Object.keys(NORMAL_FLOW_TABS).includes(stage) || Object.keys(PROGRAM_FLOW_TABS).includes(stage))) {
    setActiveTab(stage);
  }

  const closeEnquiryForm = (resetId: boolean = false) => {
    setEnquiryFormOpen(false);
    setModalOpen(false);
    resetId && setCurrentCenterId('');
    handleRefetchApplications();
  };

  return (
    <PageWrapper
      pageTitle={t('enrollment.lead-management.page-title')}
      applyPadding={false}
      buttonComponent={
        <CreateButton onClick={() => setModalOpen(true)}>{t('enrollment.lead-management.add-enquiry')}</CreateButton>
      }
    >
      <PageWrapperBody>
        <Tabs activeKey={activeTab} onSelect={handleTabSwitch}>
          {Object.keys(TabItems).map((tab) => {
            if (tab === ApplicationStage.Errored && !isAnyErroredApplications()) {
              return null;
            }

            return (
              <Tab
                key={tab}
                eventKey={tab}
                title={
                  <TabTitleWithCount
                    title={TabItems[tab].toUpperCase()}
                    count={getStageCount(tab)}
                    highlightCount={getShouldHighlight(tab)}
                  />
                }
              >
                <LeadTable />
              </Tab>
            );
          })}
        </Tabs>
      </PageWrapperBody>
      <EditApplicationModal />
      <SearchAvailabilityModal
        application={selectedApplication}
        activeChild={selectedChild}
        isOpen={isSearchOpen}
        handleClose={() => setIsSearchOpen(false)}
        setChild={setSelectedChild}
      />
      <BulkApproveModal />
      <AddEnquiryModal
        isOpen={isModalOpen}
        onHide={closeEnquiryForm}
        currentCenterId={currentCenterId}
        setCurrentCenterId={setCurrentCenterId}
        setEnquiryFormOpen={setEnquiryFormOpen}
      />
      <EnquiryForm currentCenterId={currentCenterId} isOpen={isEnquiryFormOpen} onClose={closeEnquiryForm} />
      <ApplicationNotesModal />
      <EnrollmentFormDiffModal
        isOpen={isEnrolmentFormDiffModalOpen}
        formattedEnrolmentDiffData={enrolmentFormDiffData ?? undefined}
        handleClose={() => {
          setEnrolmentFormDiffData(null);
          setIsEnrolmentFormDiffModalOpen(false);
          setSelectedApplication(undefined);
        }}
        accountId={selectedApplication?.accountId ?? ''}
        accountName={selectedApplication?.accountName}
        formLinkCode={selectedApplication?.offers ? selectedApplication?.offers[0]?.linkCode ?? '' : ''}
        centerId={selectedApplication?.centers[0]}
      />
    </PageWrapper>
  );
};

export default (props: IProps) => (
  <LeadsContextProvider>
    <Leads {...props} />
  </LeadsContextProvider>
);
