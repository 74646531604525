import { useCallback, useMemo, useState } from 'react';
import { useGetActiveCenterOptions } from 'shared/hooks/useGetActiveCenters';

export function useCenterSelector() {
  const [selectedCenters, setSelectedCenters] = useState<string[]>([]);
  const { centerOptions } = useGetActiveCenterOptions();

  const selectedOptions = useMemo(
    () => centerOptions.filter((o) => selectedCenters.includes(o.value)),
    [centerOptions, selectedCenters]
  );

  const handleSelectChange = useCallback((selectedOptions: ISelectMenuItem[]) => {
    setSelectedCenters(selectedOptions.map((o) => o.value));
  }, []);

  const handleClear = useCallback(() => setSelectedCenters([]), []);

  return { centerOptions, selectedOptions, handleSelectChange, selectedCenters, handleClear };
}
