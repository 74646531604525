import React, { useCallback, useState, useEffect } from 'react';
import _, { debounce, uniq } from 'lodash';
import momentTz from 'moment-timezone';
import { useSelector } from 'react-redux';
// @ts-ignore - CalendarDay is exported from the react-dates package
import { DayPickerSingleDateController, CalendarDay } from 'react-dates';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Fade from 'react-bootstrap/Fade';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'shared/components/Buttons';
import Spinner from 'shared/components/Spinner';
import { useGetTimeOffForScopeLazy } from 'pages/TimeManagement/subroutes/TimeOff/grapgql/queries';
import ShiftDetailsInformation from '../ShiftDetailsInformation';
import { useGetShiftsForPersonForDatesLazy } from '../../graphql/queries';
import { checkConflictingTimeOffRequests } from '../../utils/checkForConflicts';
import colors from '_colors.module.scss';
import { RootState } from 'store/reducers';
import { setOtherZone } from 'shared/util/timeUtils';
import Select from '../../../../../../shared/components/Select';
import { IShiftFormShape } from '../SideShiftModal/SideShiftModal';

export interface IShiftLocation {
  locationId?: string | null;
  classId?: string | null;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  shift: IShift;
  onClose: () => void;
  onSave: (
    shiftId: string,
    dates: IDateTimeRange[],
    locationId: string | undefined,
    classId: string | undefined
  ) => void;
  classes: IClass[];
  locations: ILocation[];
}

const CopyShiftModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  shift,
  onClose,
  onSave,
  classes,
  locations,
  ...props
}) => {
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const shiftTimezone = timezonesByCenter[shift?.centerId ?? ''] ?? momentTz.tz.guess();
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);
  const [getPersonShiftsForDatesFn, { loading: shiftForPersonForDatesLoading, data: shiftsForPersonForDatesData }] =
    useGetShiftsForPersonForDatesLazy();
  const [getTimeOffRequestsFn, { loading: getTimeOffRequestsLoading, data: getTimeOffRequestsData }] =
    useGetTimeOffForScopeLazy();
  const shiftStartTime = moment(shift.startTime).tz(shiftTimezone);
  const shiftEndTime = moment(shift.endTime).tz(shiftTimezone);
  const newShiftStartAndEndTimes: IDateTimeRange[] = selectedDates.map((day) => ({
    startTime: day.clone().hours(shiftStartTime.hours()).minutes(shiftStartTime.minutes()).toISOString(),
    endTime: day.clone().hours(shiftEndTime.hours()).minutes(shiftEndTime.minutes()).toISOString(),
  }));
  const [shiftLocationData, setShiftLocationData] = useState<IShiftLocation>({
    locationId: shift.locationId,
    classId: shift.classId,
  });
  const debouncedQueryShiftsForDates = useCallback(
    debounce((dates: moment.Moment[]) => {
      // only run query if the shift is non-unassigned
      if (dates.length && shift && shift.personId) {
        const start = moment.min(dates);
        const end = moment.max(dates);

        getPersonShiftsForDatesFn({
          variables: {
            personId: shift.personId,
            dates: dates.map((m) => ({
              startTime: m.clone().startOf('day').toISOString(),
              endTime: m.clone().endOf('day').toISOString(),
            })),
          },
        });

        getTimeOffRequestsFn({
          variables: {
            input: {
              scopeType: 'CENTER',
              scopeId: shift.centerId,
              startTime: start.startOf('day').toISOString(),
              endTime: end.endOf('day').toISOString(),
            },
          },
        });
      }
    }, 500),
    [shift, getPersonShiftsForDatesFn, getTimeOffRequestsFn]
  );

  // watch for changes in the selected dates, run a debounced function that performs a graphql query to see if the user has any shifts on the selected dates
  useEffect(() => {
    debouncedQueryShiftsForDates(selectedDates);
  }, [selectedDates, debouncedQueryShiftsForDates]);

  const handleIsOutsideRange = useCallback(
    (date: moment.Moment): boolean => {
      const shiftDate = moment(shift?.startTime) ?? moment();
      const isWeekend: boolean = date.day() === 0 || date.day() === 6;
      /**
       * disable the following dates for selection:
       * - date of the select shift
       * - weekends
       * - previous week dates
       */
      return isWeekend || date.isSame(shiftDate, 'date') || date < moment().startOf('week');
    },
    [shift]
  );

  const handleDateChange = useCallback(
    (date: moment.Moment | null) => {
      if (date) {
        const copy = [...selectedDates];
        const updated = copy.some((d) => date.isSame(d, 'day'))
          ? selectedDates.filter((d) => !date.isSame(d, 'day'))
          : [...copy, date];

        setSelectedDates(updated);
      }
    },
    [selectedDates]
  );

  const conflictingShifts = useCallback(
    (shift: IShift | null, dates: moment.Moment[]): string[] => {
      const existingShiftsForPerson = shiftsForPersonForDatesData?.getShiftsForPersonForDates ?? [];

      if (existingShiftsForPerson && shift) {
        // based on the selected dates, check if creating this shift will result in any conflicts
        return newShiftStartAndEndTimes
          .map((newShift) => {
            const conflictingShift = existingShiftsForPerson.find(
              (existingShift) =>
                moment(newShift.startTime).isBetween(
                  moment(existingShift.startTime),
                  moment(existingShift.endTime),
                  undefined,
                  '[)'
                ) ||
                moment(newShift.endTime).isBetween(
                  moment(existingShift.startTime),
                  moment(existingShift.endTime),
                  undefined,
                  '(]'
                ) ||
                (moment(newShift.startTime).isSameOrBefore(moment(existingShift.startTime)) &&
                  moment(newShift.endTime).isSameOrAfter(moment(existingShift.endTime)))
            );

            // ignore the conflict if the conflicting shift id is the same as the shift being edited
            if (conflictingShift && conflictingShift.id !== shift.id) {
              return `There is an existing conflict on ${momentTz(conflictingShift.startTime)
                .tz(shiftTimezone)
                .format('MMMM Do')} from ${momentTz(conflictingShift.startTime)
                .tz(shiftTimezone)
                .format('h:mm A')} - ${moment(conflictingShift.endTime).tz(shiftTimezone).format('h:mm A')}.`;
            }

            return '';
          })
          .filter((s) => s !== '');
      }

      return [];
    },
    [shiftsForPersonForDatesData, newShiftStartAndEndTimes, shiftTimezone]
  );

  const conflictingTimeOffRequests = useCallback(
    (shift: IShift, datesToCopyTo: moment.Moment[]): string[] => {
      let conflicts: string[] = [];
      const shiftStartTime = moment(shift.startTime);
      const shiftEndTime = moment(shift.endTime);
      const dates = datesToCopyTo.map((m) => m.clone().startOf('day'));
      const requestsForPerson =
        getTimeOffRequestsData?.getTimeOffRequestsByScope.filter((req) => req.personId === shift.personId) ?? [];

      for (let idx = 0; idx < requestsForPerson.length; idx++) {
        const req = requestsForPerson[idx];
        // convert the start and endtime to match the date being copied to to check
        const startTime = moment(req.startTime).hours(shiftStartTime.hours()).minutes(shiftStartTime.minutes());
        const endTime = moment(req.endTime).hours(shiftEndTime.hours()).minutes(shiftEndTime.minutes());

        conflicts = conflicts.concat(checkConflictingTimeOffRequests(dates, requestsForPerson, startTime, endTime));
      }

      return uniq(conflicts);
    },
    [getTimeOffRequestsData]
  );

  if (!shift) {
    return null;
  }

  const shiftClassAssignment: string = shift.classId
    ? `${shift.class?.name ?? ''}`
    : shift.locationId
    ? `${shift.location?.name ?? ''}`
    : '';

  return (
    <Modal centered show={isOpen} onHide={onClose} size="lg" backdrop="static">
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Copy Shift</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="d-flex flex-row">
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-row align-items-center mb-4">
              <div
                className="kt-staff-schedules-shift-modal-avatar"
                style={{ backgroundColor: shift.class?.colorCode ?? colors.gray }}
              >
                {shiftClassAssignment.charAt(0).toUpperCase()}
              </div>
              <span className="ml-4 kt-staff-schedules-shift-modal-primary-text">{shiftClassAssignment}</span>
            </div>
            <ShiftDetailsInformation shift={shift} />
            <Select
              className="mr-4"
              label="Location"
              required
              options={[
                ...classes.map((c) => ({ value: c.id, label: c.name, isClass: true })),
                ...locations.map((c) => ({ value: c.id, label: c.name, isClass: false })),
              ]}
              value={shiftLocationData.classId || shiftLocationData.locationId}
              onChange={(o) =>
                setShiftLocationData((prev) => ({
                  ...prev,
                  classId: o.isClass ? o.value : null,
                  locationId: o.isClass ? null : o.value,
                }))
              }
            />
            <Fade in={shiftForPersonForDatesLoading || getTimeOffRequestsLoading}>
              <div className="d-flex align-items-center">
                <span className="mr-2">Checking for any conflicts...</span>
                <Spinner small />
              </div>
            </Fade>
            <Collapse in={conflictingTimeOffRequests(shift, selectedDates).length > 0}>
              <div className="text-danger">
                <ul className="pl-0 pr-4">
                  {conflictingTimeOffRequests(shift, selectedDates).map((s: string, idx: number) => (
                    <li key={`time-off-conflict-${idx}`}>{s}</li>
                  ))}
                </ul>
              </div>
            </Collapse>
            <Collapse in={conflictingShifts(shift, selectedDates).length > 0}>
              <div className="text-danger">
                <ul className="pl-0 pr-4">
                  {conflictingShifts(shift, selectedDates).map((s: string, idx: number) => (
                    <li key={`shift-conflict-${idx}`}>{s}</li>
                  ))}
                </ul>
              </div>
            </Collapse>
          </div>
          <div className="border-left pl-4 single-date-picker">
            <small className="d-block mb-2">Select date(s) to copy shift to:</small>
            <DayPickerSingleDateController
              hideKeyboardShortcutsPanel
              date={null}
              onDateChange={(d) => handleDateChange(d ? setOtherZone(d, shiftTimezone) : d)}
              focused
              onFocusChange={() => {}}
              numberOfMonths={1}
              isOutsideRange={(d) => handleIsOutsideRange(d ? setOtherZone(d, shiftTimezone) : d)}
              isDayHighlighted={(day) =>
                selectedDates.some((d) => d.isSame(day ? setOtherZone(day, shiftTimezone) : day, 'day'))
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button variant="light" onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={isLoading}
          disabled={
            isLoading ||
            _.isEmpty(selectedDates) ||
            shiftForPersonForDatesLoading ||
            getTimeOffRequestsLoading ||
            conflictingShifts(shift, selectedDates).length > 0 ||
            conflictingTimeOffRequests(shift, selectedDates).length > 0
          }
          onClick={() =>
            onSave(
              shift.id,
              newShiftStartAndEndTimes,
              shiftLocationData.locationId ? shiftLocationData.locationId : undefined,
              shiftLocationData.classId ? shiftLocationData.classId : undefined
            )
          }
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CopyShiftModal;
