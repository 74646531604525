// @ts-nocheck
import React, { useState } from 'react';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RightModalDrawer from 'shared/components/MUI/RightModalDrawer';
import { useCreatePayCode } from '../graphQL/mutations';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { isEmpty, isNil, capitalize } from 'lodash';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { useTranslation } from 'react-i18next';
import DetailedRadioOption from 'shared/components/DetailedRadioOption';
import FormLabel from 'shared/components/Form/FormLabel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import K2Sidebar from 'shared/components/Navigation/K2Sidebar';

interface IProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

const CENTER_CATEGORIES = [
  { label: 'Closure', value: 'CLOSURE' },
  { label: 'Holiday', value: 'HOLIDAY' },
  { label: 'Out of Business Hours', value: 'OOBH' },
  { label: 'Training', value: 'TRAINING' },
];

const AddPayCodeModal: React.FC<IProps> = ({ open, onClose, refetch }) => {
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const { t } = useTranslation(['translation', 'settings']);
  const ALL_CENTERS_OPTIONS = { value: null, label: `All ${capitalize(t('translation:spelling.center_plural'))}` };
  const centerSelectOptions =
    useGetActiveCenters()
      .map((center) => ({ label: center.name, value: center.id }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [code, setCode] = useState('');
  const [purpose, setPurpose] = useState<'CENTER' | 'PERSONAL' | null>(null);
  const [centerCategory, setCenterCategory] = useState<TrainingHolidayEventType | null>(null);
  const [centerId, setCenterId] = React.useState<{ label: string; value: string | null } | null>(null);
  const [overtime, setOvertime] = useState<boolean | null>(false);
  const { k2WebPtoTypes } = useFlags();

  const adpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;

  const [createPayCodeFn] = useCreatePayCode({
    onCompleted: () => {
      showToast('Pay code created successfully', 'success');
      closeModal();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onSave = () => {
    createPayCodeFn({
      variables: {
        input: {
          businessId: businessId,
          code: code,
          name: name,
          description: description,
          centerId: centerId?.value ?? null,
          trainingTimeEventType: centerCategory,
          overtimeEligible: overtime,
        },
      },
    });
  };

  const closeModal = () => {
    setCode('');
    setDescription('');
    setName('');
    setPurpose(null);
    setCenterCategory(null);
    setCenterId(null);
    setOvertime(null);

    onClose();
    refetch();
  };

  const formDisabled = isEmpty(code) || isEmpty(name) || isNil(centerId);

  return (
    <RightModalDrawer open={open} onClose={closeModal} title="Add Pay Code">
      <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '16px' }}>
        <Mui.Stack direction="column" spacing={2} marginLeft="16px">
          <Mui.FormControlLabel
            label={
              <span>
                Name
                <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
              </span>
            }
            labelPlacement="top"
            control={
              <Mui.TextField
                required
                fullWidth
                size="small"
                value={name}
                onChange={(evt) => setName(evt.target.value)}
                sx={styles.inputStyles}
              />
            }
            componentsProps={{
              typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
            }}
            style={{ margin: 0 }}
          />
          <Mui.FormControlLabel
            label={<span>Description</span>}
            labelPlacement="top"
            control={
              <Mui.TextField
                size="small"
                value={description}
                required
                onChange={(evt) => setDescription(evt.target.value)}
                fullWidth
                multiline
                rows={4}
                sx={styles.inputStyles}
                variant="outlined"
              />
            }
            componentsProps={{
              typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
            }}
          />
          <div>
            <FormLabel required>Purpose</FormLabel>
            <DetailedRadioOption
              id="personal-code-type"
              title={t('settings:operations.pay-code.personal-purpose-label')}
              description={t('settings:operations.pay-code.personal-purpose-description')}
              onSelect={(isSelected) => setPurpose(isSelected ? 'PERSONAL' : 'CENTER')}
              isSelected={purpose === 'PERSONAL'}
              className="mb-2"
            />
            <DetailedRadioOption
              id="center-code-type"
              title={t('settings:operations.pay-code.center-purpose-label')}
              description={t('settings:operations.pay-code.center-purpose-description')}
              onSelect={(isSelected) => setPurpose(isSelected ? 'CENTER' : 'PERSONAL')}
              isSelected={purpose === 'CENTER'}
            />
          </div>
          <div>
            <FormLabel required={purpose === 'CENTER'}>
              {t('settings:operations.pay-code.center-category-label')}
            </FormLabel>
            <Mui.Autocomplete
              id="center-category-dropdown"
              fullWidth
              disabled={purpose !== 'CENTER'}
              options={CENTER_CATEGORIES}
              getOptionLabel={(option) => option.label}
              onChange={(evt, value) => setCenterCategory(value?.value)}
              renderInput={(params) => <Mui.TextField {...params} sx={styles.inputStyles} placeholder="Category" />}
              size="small"
              value={CENTER_CATEGORIES.find((c) => c.value === centerCategory)}
            />
          </div>
          <Mui.FormControlLabel
            label={
              <span>
                Code
                <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
              </span>
            }
            labelPlacement="top"
            control={
              <Mui.TextField
                size="small"
                value={code}
                required
                onChange={(evt) => setCode(evt.target.value)}
                fullWidth
                sx={styles.inputStyles}
              />
            }
            componentsProps={{
              typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
            }}
            style={{ margin: 0, paddingTop: '24px' }}
          />
          <Mui.FormControlLabel
            label={
              <span>
                {capitalize(t('translation:spelling.center'))}{' '}
                <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
              </span>
            }
            labelPlacement="top"
            control={
              <Mui.Autocomplete
                id="center-select"
                fullWidth
                options={[ALL_CENTERS_OPTIONS, ...centerSelectOptions]}
                getOptionLabel={(option) => option.label}
                onChange={(evt, value) => setCenterId(value)}
                renderInput={(params) => <Mui.TextField {...params} sx={styles.inputStyles} placeholder="Center" />}
                size="small"
                value={centerId}
                sx={{ paddingRight: '16px' }}
              />
            }
            componentsProps={{
              typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
            }}
            style={{ margin: 0, paddingTop: '24px' }}
          />

          {k2WebPtoTypes && adpEnabled && (
            <div>
              <FormLabel required>Eligible for Overtime</FormLabel>
              <DetailedRadioOption
                id="personal-overtime-yes"
                title="Yes"
                onSelect={() => setOvertime(true)}
                isSelected={overtime}
                className="kt-detailed-radio-option-no-border mb-0"
              />
              <DetailedRadioOption
                id="personal-overtime-no"
                title="No"
                onSelect={() => setOvertime(false)}
                isSelected={!overtime}
                className="kt-detailed-radio-option-no-border mb-0"
              />
            </div>
          )}
        </Mui.Stack>
      </Mui.DialogContent>
      <Mui.Box style={{ margin: '8px' }}>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => closeModal()}>
            Cancel
          </Mui.Button>
          <Mui.Button
            disabled={formDisabled}
            color="secondary"
            variant="contained"
            onClick={() => onSave()}
            id="modal-drawer-primary-btn"
          >
            Save
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Box>
    </RightModalDrawer>
  );
};

export default AddPayCodeModal;
