import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { capitalize } from 'shared/util/string';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onArchive: () => void;
}
const ArchiveCustomFieldModal: React.FC<IProps> = ({ isOpen, isLoading, onClose, onArchive }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      title={t('custom-data-fields.archive-modal.title')}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice={capitalize(t('spelling.continue'))}
      primaryCallback={onArchive}
      primaryButtonProps={{ loading: isLoading, variant: 'danger' }}
    >
      <p>{t('custom-data-fields.archive-modal.body')}</p>
    </ConfirmationModal>
  );
};

export default ArchiveCustomFieldModal;
