import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { PersonAvatar } from 'shared/components/Avatar';
import Button from 'shared/components/Buttons';
import useFormatDate from 'shared/hooks/useFormatDate';
import { HorizontalDivider } from 'shared/components/Dividers';
import { getFullName, toProperCase } from 'shared/util/string';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTranslation } from 'react-i18next';
import AppliedDiscountTransactionsSection from './AppliedDiscountTransactionsSection';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as Mui from '@mui/material';
import { currencyFormat } from 'shared/util/currency';
import { isRegion } from 'shared/util/region';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';

interface IProps {
  transaction: ITransaction;
  isPaymentTransactionsOnly?: boolean;
  approvePaymentLoading?: boolean;
  appliedDiscountTransactionsLoading?: boolean;
  handleReverse: (t: ITransaction) => void;
  onApproveFlaggedPayment?: (payment: IPayment) => void;
  onRejectFlaggedPayment?: (payment: IPayment, voidManualCheckPayment: boolean) => void;
  onAddDiscountToTransaction?: (transaction: ITransaction) => void;
  onRemoveDiscountFromTransaction?: (transaction: ITransaction, discountTransaction: ITransaction) => void;
}

const TransactionExpandedRow: React.FC<IProps> = ({
  transaction,
  isPaymentTransactionsOnly,
  approvePaymentLoading = false,
  appliedDiscountTransactionsLoading = false,
  handleReverse,
  onApproveFlaggedPayment,
  onRejectFlaggedPayment,
  onAddDiscountToTransaction,
  onRemoveDiscountFromTransaction,
}) => {
  const { t } = useTranslation(['billing', 'translation']);
  const { k2Discounts, showServiceFeeInExpandedPayment, k2CancelPayment, k2SessionSource } = useFlags();

  const reversedTransaction = transaction.reversedTransaction;
  const hasFlaggedPayment = Boolean(transaction.payment?.flags.length);
  const hasPendingFlaggedPayment = Boolean(
    hasFlaggedPayment && !transaction.payment?.approvedBy && !transaction.payment?.rejectedBy
  );
  const isUnsubmitted = Boolean(
    transaction.payment?.status === 'UNSUBMITTED' || (k2CancelPayment && transaction.payment?.status === 'PENDING')
  );
  const [isApproveButtonClicked, setIsApproveButtonClicked] = useState<boolean>(false);
  const isCompletedManualCheckPayment = Boolean(
    transaction.payment?.status === 'COMPLETED' &&
      ['Check', 'Cheque'].includes(transaction.transactionType.name) &&
      transaction.createdByPerson !== null
  );

  const isCompletedManualPayment = Boolean(
    transaction.payment?.status === 'COMPLETED' &&
      ['Cash', 'Money Order', 'EFTPOS', 'Direct Deposit'].includes(transaction.transactionType.name) &&
      transaction.createdByPerson !== null
  );

  const formatDate = useFormatDate();

  const canReverseTransaction = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });
  const canApprovePayment = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Create,
  });
  const canCancelPayment = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Delete,
  });
  const canReadAccounts = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const formatPaymentFlagEnum = useCallback(
    (flagReason: PaymentFlagType): string => {
      switch (flagReason) {
        case 'MULTIPLE_PAYMENTS_ON_SINGLE_DAY':
          return t('billing:transactions.flag-explanations.multiple-payments');
        case 'PERCENTAGE_BREACH':
          return t('billing:transactions.flag-explanations.percentage-breach', { percentage: 30 }); // 30 is a hardcoded value in the backend
        case 'SUBSIDY_REDUCTION':
          return t('billing:transactions.flag-explanations.subsidy-reduction');
        default:
          return '';
      }
    },
    [t]
  );

  return (
    <div className="py-4">
      <section className="k2-billing-transaction-expanded-row-section">
        <div>
          {transaction.payment?.processorFailureMessage && isPaymentTransactionsOnly && (
            <div className="mb-2">
              <h6>{t('billing:transactions.expanded-row.failure-description')}</h6>
              <div>{transaction.payment?.processorFailureMessage}</div>
            </div>
          )}
          {isRegion('AU') ? (
            <>
              <h6>{t('billing:transactions.expanded-row.description-column')}</h6>
              <div>
                {transaction.description || 'None'}
                {k2SessionSource && transaction.sessionSource && (
                  // @ts-ignore
                  <HelpTooltip text={t(`translation:attendance.session-source.${transaction.sessionSource}`)} />
                )}
              </div>
            </>
          ) : (
            <>
              <h6>{t('billing:transactions.expanded-row.id-column')}</h6>
              <div>{transaction.transactionNumber || 'None'}</div>
              {transaction.itemizedBill && (
                <div>
                  <h6>{t('billing:transactions.expanded-row.itemized-id-column')}</h6>
                  <div>{transaction.itemizedBill?.userFriendlyId}</div>
                </div>
              )}
            </>
          )}

          {transaction.reversedByTransactionId && transaction.reversedByTransaction && (
            <div className="text-danger">Reversal ID: {transaction.reversedByTransaction.transactionNumber}</div>
          )}
        </div>
        <div>
          <h6>{t('billing:transactions.expanded-row.account-info-column')}</h6>
          {canReadAccounts ? (
            <Link
              style={{ textDecoration: 'underline' }} // I guess our default styling for anchor tags removes the underline
              to={`/families/accounts/${transaction.account.id}/billing`}
              target="_blank"
            >
              {transaction.account.name}
            </Link>
          ) : (
            <span>{transaction.account.name}</span>
          )}
          <span>, {transaction.account.center?.name}</span>
          {transaction.appliedToAccountChild && isRegion('US') && (
            <div>
              <h6>{t('billing:transactions.expanded-row.account-child-column')}</h6>
              <div>
                {transaction.appliedToAccountChild?.firstname} {transaction.appliedToAccountChild?.lastname}
              </div>
            </div>
          )}
        </div>
        <div>
          <h6>{t('billing:transactions.expanded-row.created-by-column')}</h6>
          <div>
            {transaction.createdByPerson ? (
              <span>
                <PersonAvatar size="xs" person={transaction.createdByPerson} className="mr-2" />
                {getFullName(transaction.createdByPerson)}
              </span>
            ) : (
              <span>System</span>
            )}{' '}
            on {formatDate(transaction.createdAt, 'MM/DD/YYYY')}
          </div>
        </div>

        <div>
          <h6>{t('billing:transactions.expanded-row.submitted-at-column')}</h6>
          <div>
            <span>
              {transaction.payment?.submittedAt &&
                formatDate(transaction.payment.submittedAt ?? ' ', 'timestamp-MM/DD/YYYY')}
            </span>
          </div>
        </div>
        <div>
          {transaction.reversedAt ? (
            <div>
              <h6>{t('billing:transactions.expanded-row.reversed-by-column')}</h6>
              {transaction.reversedByPerson ? (
                <span>
                  <PersonAvatar size="xs" person={transaction.reversedByPerson} className="mr-2" />
                  {getFullName(transaction.reversedByPerson)}
                </span>
              ) : (
                <span>System</span>
              )}{' '}
              on {formatDate(transaction.reversedAt, 'MM/DD/YYYY')}
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              {canReverseTransaction && transaction.isTransactionTypeReversible && (
                <Button onClick={() => handleReverse(transaction)} variant="outline-danger">
                  Reverse
                </Button>
              )}
              {canCancelPayment &&
                (isUnsubmitted || isCompletedManualCheckPayment || isCompletedManualPayment) &&
                !hasPendingFlaggedPayment &&
                onRejectFlaggedPayment && (
                  <Button
                    variant="danger"
                    onClick={() =>
                      onRejectFlaggedPayment(transaction.payment as IPayment, isCompletedManualCheckPayment)
                    }
                  >
                    {isCompletedManualCheckPayment
                      ? t('billing:transactions.expanded-row.void-check-button')
                      : t('billing:transactions.expanded-row.cancel-payment-button')}
                  </Button>
                )}
            </div>
          )}
        </div>
      </section>
      {k2Discounts &&
        transaction.transactionType.accountType !== 'Liability' &&
        transaction.transactionType.isDebit &&
        transaction.reversedByTransactionId === null &&
        transaction.reversedTransactionId === null && (
          <AppliedDiscountTransactionsSection
            transaction={transaction}
            appliedDiscountTransactionsLoading={appliedDiscountTransactionsLoading}
            onAddNewDiscount={(transaction) => onAddDiscountToTransaction && onAddDiscountToTransaction(transaction)}
            onRemoveAppliedDiscount={(discountTransaction) =>
              onRemoveDiscountFromTransaction && onRemoveDiscountFromTransaction(transaction, discountTransaction)
            }
          />
        )}
      {reversedTransaction && (
        <section className="bg-info-10 p-4 w-50 mt-4">
          <h5>Reversed from: {reversedTransaction.transactionNumber}</h5>
          <table className="w-100">
            <thead>
              <tr className="shadow-none">
                <th className="bg-info-10 text-dark pb-0 pl-0">{t('billing:transactions.data-table.date-column')}</th>
                <th className="bg-info-10 text-dark pb-0">{t('billing:transactions.data-table.id-column')}</th>
                <th className="bg-info-10 text-dark pb-0">{t('billing:transactions.data-table.type-column')}</th>
                <th className="bg-info-10 text-dark pb-0">{t('billing:transactions.data-table.amount-column')}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="shadow-none">
                <td className="bg-info-10 text-dark pb-0 pl-0 rounded-0">{formatDate(reversedTransaction.date)}</td>
                <td className="bg-info-10 text-dark pb-0">{reversedTransaction.transactionNumber}</td>
                <td className="bg-info-10 text-dark pb-0">{reversedTransaction.transactionType.name}</td>
                <td className="bg-info-10 text-dark pb-0 rounded-0">
                  {reversedTransaction.amount < 0
                    ? `-$${(reversedTransaction.amount * -1).toFixed(2)}`
                    : `$${reversedTransaction.amount.toFixed(2)}`}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      )}
      {transaction.payment !== null && transaction.payment?.paymentMethodUsedType !== null && (
        <>
          <HorizontalDivider />
          <section>
            <h6>{t('billing:transactions.expanded-row.payment-method-details-header')}</h6>
            <div>
              {transaction.payment?.contactFirstName} {transaction.payment?.contactLastName}
            </div>
            <div>
              {toProperCase(transaction.payment?.paymentMethodUsedType ?? '').replace('_', ' ')} ending in ****
              {transaction.payment?.paymentMethodUsedLastFour}
            </div>
          </section>
        </>
      )}
      <HorizontalDivider />
      {isPaymentTransactionsOnly && showServiceFeeInExpandedPayment && (
        <Mui.Box paddingLeft={0} paddingRight={3} display="flex">
          <Mui.TableContainer sx={{ boxShadow: 0, maxWidth: '40%' }} component={Mui.Paper}>
            <Mui.Table sx={{ minWidth: 250 }} aria-label="simple table">
              <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
                <Mui.Box display="flex" justifyContent="space-around" alignItems="center">
                  <Mui.TableCell sx={{ border: 0, fontWeight: 'bold', justifyContent: 'center' }}>
                    Account Credit
                  </Mui.TableCell>
                  <Mui.TableCell sx={{ border: 0, fontWeight: 'bold', justifyContent: 'center' }}>
                    Service Fee
                  </Mui.TableCell>
                  <Mui.TableCell sx={{ border: 0, fontWeight: 'bold', justifyContent: 'center' }}>
                    Deposited
                  </Mui.TableCell>
                </Mui.Box>
              </Mui.TableHead>

              <Mui.TableBody sx={{ boxShadow: 0 }}>
                <Mui.Box display="flex" justifyContent="space-around" alignItems="center">
                  <Mui.TableCell sx={{ alignItems: 'center', border: 0, fontWeight: 'bold' }}>
                    {currencyFormat(transaction.payment?.amount)}
                  </Mui.TableCell>
                  <Mui.TableCell sx={{ border: 0, fontWeight: 'bold', paddingLeft: '3px' }}>
                    {currencyFormat(transaction.payment?.serviceFee)}
                  </Mui.TableCell>
                  <Mui.TableCell sx={{ border: 0, fontWeight: 'bold' }}>
                    {transaction.payment?.depositedAmount !== null
                      ? currencyFormat(transaction.payment?.depositedAmount)
                      : ''}
                  </Mui.TableCell>
                </Mui.Box>
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        </Mui.Box>
      )}

      {hasFlaggedPayment && (
        <>
          <HorizontalDivider />
          <section>
            <h6>{t('billing:transactions.expanded-row.flagged-payment-details-header')}</h6>
            <div className="k2-billing-transaction-expanded-row-section">
              <div>
                <h6>{t('billing:transactions.expanded-row.reason-column')}</h6>
                <div>{transaction.payment?.flags.map((f) => formatPaymentFlagEnum(f.flagType)).join('; ')}</div>
              </div>
              <div>
                {transaction.payment?.approvedByPerson && (
                  <>
                    <h6>{t('billing:transactions.expanded-row.approved-by-column')}</h6>
                    <div>
                      <span>
                        <PersonAvatar size="xs" person={transaction.payment?.approvedByPerson} className="mr-2" />
                        {getFullName(transaction.payment.approvedByPerson)}
                      </span>
                      <span> on {formatDate(transaction.payment.approvedAt ?? '', 'MM/DD/YYYY')}</span>
                    </div>
                  </>
                )}
                {transaction.payment?.rejectedByPerson && (
                  <>
                    <h6>{t('billing:transactions.expanded-row.cancelled-by-column')}</h6>
                    <div>
                      <span>
                        <PersonAvatar size="xs" person={transaction.payment?.rejectedByPerson} className="mr-2" />
                        {getFullName(transaction.payment.rejectedByPerson)}
                      </span>
                      <span> on {formatDate(transaction.payment.rejectedAt ?? '', 'MM/DD/YYYY')}</span>
                    </div>
                  </>
                )}
              </div>
              <div />
              <div className="d-flex flex-1 justify-content-end">
                {canApprovePayment && hasPendingFlaggedPayment && (
                  <Button
                    variant="success"
                    loading={approvePaymentLoading}
                    disabled={isApproveButtonClicked}
                    onClick={() => {
                      setIsApproveButtonClicked(true);
                      onApproveFlaggedPayment && onApproveFlaggedPayment(transaction.payment as IPayment);
                      setIsApproveButtonClicked(false);
                    }}
                    className="mr-4"
                  >
                    {t('billing:transactions.expanded-row.approve-button')}
                  </Button>
                )}
                {canCancelPayment && hasPendingFlaggedPayment && (
                  <Button
                    variant="danger"
                    onClick={() =>
                      onRejectFlaggedPayment && onRejectFlaggedPayment(transaction.payment as IPayment, false)
                    }
                  >
                    {t('billing:transactions.expanded-row.cancel-payment-button')}
                  </Button>
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default TransactionExpandedRow;
