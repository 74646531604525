import * as types from './types';
import { GET_USER_SUCCESS } from 'store/user/types';
import * as verifyEmailTypes from 'pages/Account/duck/types';
import { sessionStatus } from 'shared/constants/enums/sessionStatus';

const initialSession = {
  status: sessionStatus.LOADING,
};

export const sessionReducer = (state = initialSession, action) => {
  switch (action.type) {
    case types.LOGIN_WITH_EMAIL_AND_PASSWORD:
      return {
        ...state,
        status: sessionStatus.LOADING,
        signupComplete: false,
      };
    case types.AUTHENTICATED:
      return {
        ...state,
        status: sessionStatus.LOADING,
        token: action.payload.token,
        emailVerified: action.payload.emailVerified,
      };
    case types.NOT_AUTHENTICATED:
      return {
        status: sessionStatus.NOT_AUTHENTICATED,
        errorCode:
          action.payload.errorCode === state.errorCode && state.errorCode === 'UserNotFoundException'
            ? 'UserNotFoundExceptionSecondAttempt'
            : action.payload.errorCode,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        status: sessionStatus.READY,
      };
    case types.SIGNUP_COMPLETE:
      return {
        ...state,
        signupComplete: true,
      };
    case verifyEmailTypes.VERIFY_EMAIL_REQUEST: {
      return {
        ...state,
        emailVerificationError: '',
      };
    }
    case verifyEmailTypes.SUBMIT_VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        emailVerified: true,
        emailVerificationError: null,
      };
    }
    case verifyEmailTypes.VERIFY_EMAIL_FAILURE:
    case verifyEmailTypes.SUBMIT_VERIFY_EMAIL_FAILURE: {
      return {
        ...state,
        emailVerificationError: action.error.message,
      };
    }
    case types.UPDATE_SESSION_TOKEN: {
      return {
        ...state,
        token: action.payload.token,
      };
    }
    default:
      return state;
  }
};
