export enum TimeRange {
  Single = 'Single',
  Multiple = 'Multiple',
}

export enum ClosureResolution {
  CLEAR = 'Clear',
  ABSENT = 'Absent',
}

export interface IFormData {
  classIds: string[];
  // addClassClosure mutation require all the classIds explicitly if all the options are selected
  // useGetSessionCountByTimeFrame can take null as classIds if all the options are selected(for BE performance optimization we should pass null if all option is selected)
  // so we have to manually handle how to pass the classIds to the two api, so need a helper property isAllClassesSelected
  isAllClassesSelected: boolean;
  timeRange: TimeRange;
  startDate: string;
  endDate: string | null;
  closureResolution: ClosureResolution;
}
