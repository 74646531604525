import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { Col, Row } from 'react-bootstrap';
import { useGetProviderDebts } from 'gql/ccssDebts/queries';
import { useGetProvidersByBusinessId } from 'gql/business/queries';
import CCSDebtsTable from './components/CCSDebtsTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import CCSDebtAltArrangementModal from './components/CCSDebtAltArrangementModal';
import { useGetServicesByBusiness } from '../CcsAccountManagement/graphql/queries';
import { useCreateAltArrangement } from 'gql/ccssDebts/mutation';
import { showToast } from 'shared/components/Toast';
import moment from 'moment';
interface ISort {
  field: string;
  direction: ElasticsearchSortDirection;
}

const CCSDebts: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const entityId = useSelector((state: RootState) => state.user?.entityId) ?? '';
  const { data: providers, loading: getProvidersLoading } = useGetProvidersByBusinessId({
    variables: {
      businessId: entityId,
    },
    skip: entityId === '',
  });
  const { data: servicesData } = useGetServicesByBusiness(entityId);

  const [tableState, tableFunctions] = useDatatableState();
  const providerList = providers?.getProvidersByBusinessId || [];
  const [activeFilters, setActiveFilters] = useState<ICcsDebtFilter>({
    sources: [],
    searchText: '',
  });

  const handleFilterChange = (newFilters: ICcsDebtFilter) => {
    setActiveFilters(() => newFilters);
  };

  const { data: debts, loading } = useGetProviderDebts({
    variables: {
      providerId: activeFilters.providerId || '',
      businessId: entityId,
    },
    skip: !Boolean(activeFilters.providerId),
  });

  const [submitArrangement, { data, loading: isSubmitting }] = useCreateAltArrangement({
    onCompleted: (res) => {
      showToast('Successfully created an alternate arrangement.', 'success');
      setShowModal(false);
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  useEffect(() => {
    if (providerList.length > 0) {
      setActiveFilters({ ...activeFilters, providerId: providerList[0].id });
    }
  }, [providerList]);

  return (
    <div className="mx-auto">
      <CCSDebtAltArrangementModal
        services={servicesData?.getServicesByBusiness?.data || []}
        show={showModal}
        loading={isSubmitting}
        onClose={() => setShowModal(false)}
        onSubmit={(data) => {
          submitArrangement({
            variables: {
              input: {
                ...data,
                providerId: activeFilters.providerId ?? '',
                businessId: entityId,
              },
            },
          });
        }}
      />
      <Row>
        <Col>
          <CCSDebtsTable
            data={debts?.getProviderDebts || []}
            dataSize={0}
            loading={loading}
            pageSize={tableState.pageSize}
            activePage={tableState.activePage}
            onPageChange={tableFunctions.changePage}
            onSizePerPageChange={tableFunctions.changeSizePerPage}
            activeFilters={activeFilters}
            onActiveFilterChange={handleFilterChange}
            providers={providerList}
            handleMakeArrangement={() => {
              setShowModal(true);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CCSDebts;
