import React, { useState, useEffect } from 'react';
import PageWrapper from 'shared/components/PageWrapper';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { updateVisibleCenterForEnrollment } from 'pages/Enrollment/duck/actions';
import { Tab, Tabs } from 'react-bootstrap';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { capitalize } from 'shared/util/string';
import LinksManagement from './Tabs/LinksManagement/LinksManagement';
import { useTranslation } from 'react-i18next';
import Settings from './Tabs/Settings';
import useHasRoleAreaLevel from '../../../../shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from '../../../../shared/constants/enums/permissionsEnums';
import EnrollmentForms from './Tabs/EnrollmentForms/EnrollmentForms';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import ReEnrolSettings from './Tabs/ReEnrolSettings';
import { useGetReEnrolBusinessSettingsQuery } from 'generated/graphql';
import { showToast } from 'shared/components/Toast';

type EnrollmentSettingsTab = 'links' | 'enrollment-forms' | 'offer-settings' | 'reenrol-settings';

interface IRouteParams {
  activeTab: EnrollmentSettingsTab;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const EnrollmentSettings: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['business', 'translation', 'enrollment']);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const selectedCenterId = useSelector((state: RootState) => state.enrollment.activeCenter.id);
  const centerTimezones = useSelector((state: RootState) => state.timezone.byCenterId);
  const center = useGetActiveCenters().find((c) => c.id === selectedCenterId);

  const hasCenterEditPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const hasCreateEnrollmentLeadSettingsEditPermissions = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Edit,
  });

  const { activeTab } = props.match.params;
  const [activeTabKey, setActiveTabKey] = useState<EnrollmentSettingsTab>(activeTab ?? 'links');
  const history = useHistory();

  const { data: reEnrolBusinessSettingsData } = useGetReEnrolBusinessSettingsQuery({
    skip: currentBusinessId === null || currentBusinessId === undefined || currentBusinessId === '',
    variables: {
      input: {
        businessId: currentBusinessId ?? '',
      },
    },
    onError: (err) => showToast(t('enrollment:reenroll-business-settings.get-error'), 'error'),
  });
  const usesReEnrol = reEnrolBusinessSettingsData?.getReEnrolBusinessSettings.usesReEnrol ?? false;

  useEffect(() => {
    currentBusinessId && dispatch(updateVisibleCenterForEnrollment('', currentBusinessId));
  }, [dispatch, currentBusinessId]);

  useEffect(() => {
    currentCenterId && dispatch(updateVisibleCenterForEnrollment(currentCenterId));
  }, [dispatch, currentCenterId]);

  React.useEffect(() => {
    setActiveTabKey(activeTab ?? 'links');
  }, [activeTab]);

  return (
    <PageWrapper pageTitle={`${capitalize(fieldLabels.enrollment.toLowerCase())} Settings`} applyPadding={false}>
      <PageWrapperBody className="enrollment-management-page-wrapper">
        <Tabs
          id="enrollment-tabs"
          defaultActiveKey={'links'}
          activeKey={activeTabKey}
          onSelect={(tab) => {
            setActiveTabKey(tab as EnrollmentSettingsTab);
            history.push(`/enrollment/settings/${tab}`);
          }}
        >
          <Tab eventKey="links" title={t('business:enrolment.link-management-tab')}>
            <LinksManagement entityId={currentBusinessId || ''} />
          </Tab>
          <Tab eventKey="enrollment-forms" title={t('enrollment:settings.enrollment-form-tab-title')}>
            <EnrollmentForms />
          </Tab>
          <Tab eventKey="offer-settings" title={t('business:enrolment.settings-tab.offer-settings-title')}>
            <Settings businessId={currentBusinessId || ''} />
          </Tab>
          {usesReEnrol && hasCenterEditPermission && hasCreateEnrollmentLeadSettingsEditPermissions && (
            <Tab eventKey="reenrol-settings" title={t('enrollment:reenroll-center-settings.tab-title')}>
              <ReEnrolSettings businessId={currentBusinessId || ''} />
            </Tab>
          )}
        </Tabs>
      </PageWrapperBody>
    </PageWrapper>
  );
};

export default EnrollmentSettings;
