import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const PasswordResetSuccess = () => (
  <div className="d-flex flex-column align-items-center">
    <p className="h1 font-weight-bold mb-8">Success!</p>
    <p className="mb-8 text-center">Your password has changed.</p>
    <Link className="mt-8" to="/login">
      <Button>Back to Sign in</Button>
    </Link>
  </div>
);

export default PasswordResetSuccess;
