import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_SORTED_ACCOUNTS, IGetSortedAccountsData, IGetSortedAccountsVariables } from 'gql/account/queries';
import { capitalize, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { components, OptionProps } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { CoreApolloClient } from 'shared/apis/core';
import Avatar from 'shared/components/Avatar';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { stringToHueDegree } from 'shared/util/string';
import colors from '../../../_colors.module.scss';
import './_select.scss';

const DEFAULT_PAGE_SIZE = 25 as const;
const GQL_FIELDS = `
  pageNumber
  pageSize
  totalRecords
  data {
    id
    centerId
    center {
      id
      name
    }
    name
    status
  }
`;

interface IAccountsSelectProps {
  // `null` represent `All`
  selectedAccountIds: string[] | null;
  /**
   * Provide `null` as the value via `onSelect` if the all option is selected.
   * if isMulti = false, the cb will always be called an array of length 1 or null(null represents `All`)
   */
  onSelect: (accountIds: Object[] | null) => void;
  required?: boolean;
  // if isMulti = false, the 'all option' will not be shown
  disableAllOption?: boolean;
  isMulti?: boolean;
  centerIds?: string[] | null;
  statusType?: 'Inactive' | 'Active' | 'All';
  pageSize?: number;
  // accountIds that should not been shown in the select menu
  excludedAccountIds?: string[];
  appearance?: 'default' | 'detailed';
  /**
   * default: false
   * if true, account status will be shown when the appearance = 'detailed'
   */
  showAccountStatus?: boolean;
}

const AccountSelectWithCenter: React.FC<IAccountsSelectProps> = ({
  selectedAccountIds,
  onSelect,
  required = false,
  disableAllOption = false,
  isMulti = true,
  centerIds = [],
  statusType = 'All',
  pageSize = DEFAULT_PAGE_SIZE,
  excludedAccountIds,
  appearance = 'default',
  showAccountStatus = false,
  ...props
}) => {
  const { t } = useTranslation();
  const ALL_ACCOUNTS_OPTIONS = useMemo(
    () => ({ value: 'All Accounts', label: `${capitalize(t('spelling.all'))} ${capitalize(t('spelling.accounts'))}` }),
    [t]
  );
  const [loading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const initialOptions = useMemo(
    () => (disableAllOption || !isMulti ? [] : [ALL_ACCOUNTS_OPTIONS]),
    [disableAllOption, ALL_ACCOUNTS_OPTIONS, isMulti]
  );

  const loadOptions = useCallback(
    async (inputValue: string, loadedOptions: any[]) => {
      try {
        setIsLoading(true);
        const { data: response } = await CoreApolloClient.query<IGetSortedAccountsData, IGetSortedAccountsVariables>({
          query: GET_SORTED_ACCOUNTS(GQL_FIELDS),
          variables: {
            input: {
              from: Math.floor(loadedOptions.length / pageSize + 1),
              size: pageSize,
              centerIds: centerIds ?? [],
              sort: [{ field: 'name', direction: 'ASCENDING' }],
              searchKey: inputValue,
              statusType: statusType === 'All' ? undefined : statusType,
              statusAtDate: moment().format('YYYY-MM-DD'),
            },
          },
        });
        const { pageNumber, totalRecords, data } = response.getSortedAccounts;
        const hasMore = totalRecords > pageNumber * pageSize;
        setAccounts((prev) => uniqBy([...prev, ...data], 'id'));
        setIsLoading(false);

        return {
          options: data.map((account) => ({
            label: account.name,
            value: account.id,
            centerId: account.centerId,
            isDisabled: excludedAccountIds ? excludedAccountIds.includes(account.id) : false,
          })),
          hasMore,
        };
      } catch (error: any) {
        showToast(getApolloErrorMessage(error), 'error');
      }
    },
    [pageSize, centerIds, statusType, excludedAccountIds]
  );

  const selectedOptions = useMemo(() => {
    if (selectedAccountIds === null) {
      return [ALL_ACCOUNTS_OPTIONS];
    }

    return accounts
      .filter((account) => selectedAccountIds?.includes(account.id))
      .map((account) => ({ label: account.name, value: account.id, centerId: account.centerId }));
  }, [ALL_ACCOUNTS_OPTIONS, accounts, selectedAccountIds]);

  const MenuItem = useCallback(
    (props: OptionProps<ITableFilterOption, false>) => {
      if (appearance === 'default') {
        return <components.Option {...props} />;
      }
      const account = accounts.find((account) => account.id === props.data.value);
      const centerName = account?.center?.name ?? '';
      const status = account?.status ?? '';

      return (
        <components.Option {...props}>
          <div className={`d-flex align-items-center px-2 py-1`}>
            <Avatar
              color={`hsl(${stringToHueDegree(props.label)}, ${
                stringToHueDegree(props.label) < 50 ? '100%' : '40%'
              }, 40%`}
              size={'sm'}
              image={''}
              initials={props.label.charAt(0).toUpperCase()}
            />
            <div className="d-flex flex-column pl-4 text-truncate">
              <span className="text-truncate">{props.label}</span>
              {showAccountStatus && status && <small>{`${capitalize(t('spelling.status'))}: ${status}`}</small>}
              <small>{centerName}</small>
            </div>
          </div>
        </components.Option>
      );
    },
    [accounts, appearance, t, showAccountStatus]
  );

  const handleSelect = useCallback(
    (newValue, actionMeta) => {
      if (!newValue) {
        onSelect([]);
        return;
      }

      const { action, option } = actionMeta;

      const isAllOptionSelected = isMulti
        ? option?.value === ALL_ACCOUNTS_OPTIONS.value
        : newValue?.value === ALL_ACCOUNTS_OPTIONS.value;

      if (isAllOptionSelected) {
        switch (action) {
          case 'select-option':
            onSelect(null);
            return;
          default:
            onSelect([]);
            return;
        }
      }

      onSelect(
        isMulti
          ? newValue.map((option: any) => {
              return { accountId: option.value, centerId: option.centerId };
            })
          : [{ accountId: newValue.value, centerId: newValue.centerId }]
      );
    },
    [onSelect, ALL_ACCOUNTS_OPTIONS, isMulti]
  );

  return (
    <Form.Group>
      <Form.Label>
        Account
        {isMulti && '(s)'}
        {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color={colors.red} />}
      </Form.Label>
      <AsyncPaginate
        /**
         * Workaround for manually triggering the loadOptions function
         * See https://github.com/JedWatson/react-select/issues/1879#issuecomment-316871520
         */
        key={`${JSON.stringify(centerIds)}-${JSON.stringify(statusType)}-${JSON.stringify(excludedAccountIds)}`}
        isMulti={isMulti}
        options={initialOptions}
        value={selectedOptions}
        isLoading={loading}
        // @ts-ignore
        loadOptions={loadOptions}
        onChange={handleSelect}
        debounceTimeout={500}
        closeMenuOnSelect={!isMulti}
        className="react-select-container flex-wrap"
        classNamePrefix="react-select"
        hideSelectedOptions={false}
        noOptionsMessage={() => `${capitalize(t('spelling.no'))} ${t('spelling.accounts')} ${t('spelling.found')}`}
        placeholder={`${capitalize(t('spelling.search'))} ${capitalize(t('spelling.account'))}`}
        components={{
          Option: MenuItem,
        }}
        {...props}
      />
    </Form.Group>
  );
};

export default AccountSelectWithCenter;
