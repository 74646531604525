import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import AsyncSelect from 'react-select/async';
import { Link, useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import SideModalDrawer from 'shared/components/ModalDrawer';
import TextInput, { EmailInput } from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import Checkbox from 'shared/components/Checkbox';
import { isEmailValid } from 'shared/util/email';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import { useCreateAccount } from 'gql/account/mutations';
import { showToast } from 'shared/components/Toast';
import { Col, Row } from 'shared/components/Layout';
import PhoneNumberAndTypeInput from 'shared/components/PhoneNumberAndTypeInput';
import errorMessage from 'shared/constants/errorMessages';
import {
  getFullName,
  getInitials,
  isBlank,
  isValidPhoneNumber,
  parsePhoneNumberWithRegion,
  stringToHsl,
} from 'shared/util/string';
import { somePropsEmpty } from 'shared/util/object';
import BusinessAndCenterSelects from 'shared/components/BusinessAndCenterSelects';
import CrnInput from 'shared/components/CrnInput';
import { isRegion } from 'shared/util/region';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import Select from 'shared/components/Select';
import { ApolloError } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { Collapse } from 'react-bootstrap';
import { useQuery } from 'shared/apis/core';
import debouncePromise from 'debounce-promise';
import { ISearchChildrenData, ISearchChildVariables } from 'pages/Families/subroutes/Children/graphql/queries';
import Avatar from 'shared/components/Avatar';
import { ICrnValidation, useValidateNewCrnLazy } from 'gql/crn/queries';
import { isValidCrn } from 'shared/components/CrnInput/isValidCrn';
import colors from '_colors.module.scss';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SearchContactsV2Document, SearchContactsV2Query, SearchContactsV2QueryVariables } from 'generated/graphql';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface IAccountHolderFormShape {
  firstname: string;
  lastname: string;
  email: string;
  primaryPhoneNumber: IPhoneNumber;
  dob: string;
  id: string | null;
}

export interface IContactCcssAttributesShape {
  crn?: string;
}

export interface IChildCcssAttributesShape {
  crn?: string;
}

interface IFormStateShape {
  entityId: string;
  centerId: string;
  dob: string;
  firstname: string;
  lastname: string;
  relationship: string;
  accountName: string;
  accountHolder: IAccountHolderFormShape;
  sendContactInvites: boolean;
  childCcssAttributes?: IChildCcssAttributesShape;
  contactCcssAttributes?: IContactCcssAttributesShape;
}

const newAccount: IFormStateShape = {
  entityId: '',
  centerId: '',
  dob: '',
  firstname: '',
  lastname: '',
  relationship: '',
  accountName: '',
  accountHolder: {
    firstname: '',
    lastname: '',
    email: '',
    primaryPhoneNumber: {
      number: '',
      type: undefined,
    },
    dob: '',
    id: null,
  },
  childCcssAttributes: {
    crn: undefined,
  },
  contactCcssAttributes: {
    crn: undefined,
  },
  sendContactInvites: true,
};

const AddAccountModalForm: React.FC<IProps> = ({ isOpen, onClose, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const { k2SendAppInvite } = useFlags();
  const [createAccountFn, { loading: createAccountLoading }] = useCreateAccount();
  const [formData, setFormData] = useState<IFormStateShape>(newAccount);
  const [sendInvite, setSendInvite] = useState(k2SendAppInvite ?? false);
  const [isChildCrnValid, setChildCrnValid] = useState<boolean>(true);
  const [isContactCrnValid, setContactCrnValid] = useState<boolean>(true);
  const [selectedChild, setSelectedChild] = useState<IChild | null>(null);
  const [selectedContact, setSelectedContact] = useState<IContact | null>(null);
  const [validateContactCrn, { data: contactCrnResult }] = useValidateNewCrnLazy({
    onCompleted: (data) => {
      if (data?.validateCrn) {
        const { validateCrn: result } = data;
        const crnValue = formData.contactCcssAttributes?.crn ?? '';
        const valid = isValidCrn(crnValue) || crnValue === '';
        if (result?.isValid === false) setContactCrnValid(false);
        else setContactCrnValid(valid);
      }
    },
  });
  const [validateChildCrn, { data: childCrnResult }] = useValidateNewCrnLazy({
    onCompleted: (data) => {
      if (data?.validateCrn) {
        const { validateCrn: result } = data;
        const crnValue = formData.childCcssAttributes?.crn ?? '';
        const valid = isValidCrn(crnValue) || crnValue === '';
        if (result?.isValid === false) setChildCrnValid(false);
        else setChildCrnValid(valid);
      }
    },
  });
  const [showAddExistingChildInput, setShowAddExistingChildInput] = useState<{ show: boolean; term: string }>({
    show: false,
    term: '',
  });
  const [showAddExistingContactInput, setShowAddExistingContactInput] = useState<{ show: boolean; term: string }>({
    show: false,
    term: '',
  });

  const { loading: searchChildrenLoading, refetch: searchChildrenFn } = useQuery<
    ISearchChildrenData,
    ISearchChildVariables
  >(
    gql`
      query ($input: SearchInput!) {
        searchChildren(input: $input) {
          totalResults
          data {
            id
            firstname
            lastname
            nickname
            dob
            avatar {
              url
            }
          }
        }
      }
    `,
    { skip: true }
  ); // skip the query on the initial render

  const { loading: searchContactsLoading, refetch: searchContactsFn } = useQuery<
    SearchContactsV2Query,
    SearchContactsV2QueryVariables
  >(SearchContactsV2Document, { skip: true });

  const isAuRegion = isRegion('AU');

  const resetForm = useCallback(() => {
    setFormData(newAccount);
    setShowAddExistingChildInput({ show: false, term: '' });
    setShowAddExistingContactInput({ show: false, term: '' });
    setSelectedChild(null);
    setSelectedContact(null);
  }, []);

  const dismissModal = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  const isFormValid = useCallback(() => {
    let validChildInformation = selectedChild !== null;
    let validContactInformation = selectedContact !== null;

    const areAuRegionValuesValid = !isAuRegion ? true : isChildCrnValid && isContactCrnValid;

    if (!selectedChild) {
      validChildInformation = !isBlank(formData.firstname) && !isBlank(formData.lastname) && !isBlank(formData.dob);
    }

    if (!selectedContact) {
      validContactInformation =
        (isAuRegion
          ? !somePropsEmpty(formData.accountHolder, ['dob', 'id', 'lastname'])
          : !somePropsEmpty(formData.accountHolder, ['dob', 'id'])) &&
        !somePropsEmpty(formData.accountHolder.primaryPhoneNumber ?? {}) &&
        isValidPhoneNumber(formData.accountHolder.primaryPhoneNumber?.number ?? '');
    }

    return (
      validChildInformation &&
      ((selectedContact && isEmailValid(selectedContact?.email)) || isEmailValid(formData.accountHolder?.email)) &&
      validContactInformation &&
      !isBlank(formData.accountName) &&
      !isBlank(formData.relationship) &&
      areAuRegionValuesValid
    );
  }, [formData, selectedChild, selectedContact, isAuRegion, isChildCrnValid, isContactCrnValid]);

  const handleSubmit = useCallback(() => {
    const { childCcssAttributes, contactCcssAttributes, accountHolder, ...input } = formData;

    createAccountFn({
      variables: {
        input: {
          centerId: input.centerId,
          entityId: input.entityId,
          accountName: input.accountName,
          relationship: input.relationship,
          contacts: [
            {
              ...accountHolder,
              centerId: input.centerId,
              entityId: input.entityId,
              isPrimary: true,
              id: selectedContact ? selectedContact.id : null,
            },
          ],
          child: !selectedChild
            ? {
                firstname: input.firstname,
                lastname: input.lastname,
                dob: input.dob,
                childCcssAttributes: isAuRegion ? childCcssAttributes : undefined,
              }
            : null,
          sendContactInvites: sendInvite,
          contactCcssAttributes: isAuRegion ? contactCcssAttributes : undefined,
          childId: selectedChild?.id ?? null,
          contactId: selectedContact ? selectedContact.id : null,
        },
      },
    })
      .then(({ data }) => {
        resetForm();
        showToast('Successfully created a new account.', 'success');
        history.push(`/families/accounts/${data?.createAccount.id}/profile`);
      })
      .catch((error: Error) => {
        const message = error instanceof ApolloError ? getApolloErrorMessage(error) : error.message;
        showToast(message, 'error');
      });
  }, [formData, history, selectedChild, selectedContact, isAuRegion, resetForm, createAccountFn]);

  /**
   * - See this Github issue: https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
   * as to why we are using a different debounce package instead of the one provided by lodash
   *
   * - The Async component from react-select expects a promise to be returned
   */
  const debounceChildSearch = useCallback(
    debouncePromise((term: string): Promise<IChild[]> => {
      let search: ISearchExpression;
      if (!isBlank(term)) {
        if (/\s/.test(term)) {
          const [first, last] = term.split(' ');
          search = {
            all: [
              { term: { field: 'firstName', value: first.toLowerCase(), predicate: 'CONTAINS' } },
              { term: { field: 'lastName', value: last.toLowerCase(), predicate: 'CONTAINS' } },
            ],
          };
        } else {
          search = {
            any: [
              { term: { field: 'firstName', value: term.toLowerCase(), predicate: 'CONTAINS' } },
              { term: { field: 'lastName', value: term.toLowerCase(), predicate: 'CONTAINS' } },
            ],
          };
        }
        return new Promise((resolve) =>
          searchChildrenFn({
            input: {
              from: 0,
              size: 25,
              filter: {
                all: [search],
              },
              sort: [{ field: 'lastName.keyword', direction: 'ASCENDING' }],
            },
          })
            .then((response) => resolve(response.data?.searchChildren?.data ?? []))
            .catch((err) => resolve([]))
        );
      }
      return Promise.resolve([]);
    }, 500),
    [searchChildrenFn]
  );

  const debounceContactSearch = useCallback(
    debouncePromise((term: string): Promise<IContact[]> => {
      if (!isBlank(term)) {
        return new Promise((resolve) =>
          searchContactsFn({
            input: {
              filters: {
                searchText: term,
                businessIds: [businessId],
              },
              sortLastNameAsc: true,
              pagination: {
                pageNumber: 0,
                pageSize: 25,
              },
            },
          })
            .then((response) => resolve((response.data?.searchContactsV2?.data as IContact[]) ?? []))
            .catch((err) => resolve([]))
        );
      }

      return Promise.resolve([]);
    }, 500),
    [searchContactsFn]
  );

  const handleChildSearchInput = useCallback(
    (term: string) => {
      setShowAddExistingChildInput((prev) => ({ ...prev, term }));

      return debounceChildSearch(term);
    },
    [debounceChildSearch]
  );

  const handleContactSearchInput = useCallback(
    (term: string) => {
      setShowAddExistingContactInput((prev) => ({ ...prev, term }));

      return debounceContactSearch(term);
    },
    [debounceContactSearch]
  );

  const renderCrnError = (result?: ICrnValidation) => {
    if (!result?.isValid && result?.error?.match(/is already associated/)) {
      const { firstName, lastName, type, id } = result?.details ?? {};
      const link = type === 'child' ? `/families/children/${id}` : `/families/contacts/${id}`;
      return (
        <p>
          CRN is already associated with {type}{' '}
          <Link to={link} style={{ color: colors.danger }} target="_blank">
            {firstName} {lastName}
          </Link>
        </p>
      );
    }
    return result?.error;
  };

  return (
    <SideModalDrawer
      title="New Account"
      show={isOpen}
      onHide={dismissModal}
      primaryChoice="Save"
      primaryCallback={handleSubmit}
      primaryButtonProps={{
        disabled: !isFormValid() || createAccountLoading,
        loading: createAccountLoading,
      }}
      closeOnPrimaryCallback={false}
    >
      <Form>
        <BusinessAndCenterSelects
          businessId={formData.entityId}
          centerId={formData.centerId}
          updateBusinessId={(entityId) => setFormData((prev) => ({ ...prev, entityId }))}
          updateCenterId={(centerId) => setFormData((prev) => ({ ...prev, centerId }))}
          required={true}
          className="mb-8"
        />
        <h5 className="mb-4">Child Information</h5>
        <Row className="mb-4">
          <Col>
            <Checkbox
              label="Add Existing Child?"
              value={showAddExistingChildInput.show}
              onChange={(checked) => {
                setShowAddExistingChildInput({ show: checked, term: '' });
                setSelectedChild(null);
              }}
            />
          </Col>
        </Row>
        <Collapse in={showAddExistingChildInput.show}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Child</Form.Label>
                <AsyncSelect
                  isLoading={searchChildrenLoading}
                  value={selectedChild}
                  defaultOptions={[]}
                  placeholder="Search"
                  components={{ IndicatorSeparator: null }}
                  className="react-select-container w-100"
                  classNamePrefix="react-select"
                  loadOptions={(inputValue) => handleChildSearchInput(inputValue)}
                  loadingMessage={() => 'Searching children...'}
                  formatOptionLabel={(option: IChild) => (
                    <div className="d-flex flex-row align-items-center">
                      <Avatar
                        color={stringToHsl(option.id)}
                        size="sm"
                        image={option.avatar?.url ?? ''}
                        initials={getInitials(option)}
                      />
                      <div className="d-flex flex-column ml-2 text-truncate sm">
                        <div>{getFullName(option)}</div>
                        <div>
                          DOB: {moment(option.dob).format(t('formatters.MM/DD/YYYY'))}
                          <small className="ml-4">{getAgeStringFromDateOfBirth(moment(option.dob))}</small>
                        </div>
                      </div>
                    </div>
                  )}
                  onChange={(option) => setSelectedChild(option as IChild)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Collapse>
        <Row>
          <Col>
            <TextInput
              required
              label="First Name"
              value={selectedChild ? selectedChild.firstname : formData.firstname}
              onChange={(firstname) => setFormData((prev) => ({ ...prev, firstname }))}
              disabled={showAddExistingChildInput.show}
            />
          </Col>
          <Col>
            <TextInput
              required
              label="Last Name"
              value={selectedChild ? selectedChild.lastname : formData.lastname}
              onChange={(lastname) => setFormData((prev) => ({ ...prev, lastname }))}
              disabled={showAddExistingChildInput.show}
            />
          </Col>
        </Row>
        {isAuRegion && (
          <Row>
            <Col>
              <CrnInput
                label="CRN"
                value={formData.childCcssAttributes?.crn ?? ''}
                isInvalid={!isChildCrnValid}
                onBlur={() => {
                  validateChildCrn({
                    variables: {
                      businessId: formData.entityId,
                      crn: formData.childCcssAttributes?.crn ?? '',
                    },
                  });
                }}
                errorText={renderCrnError(childCrnResult?.validateCrn) ?? ''}
                onChange={(crn) =>
                  setFormData((prev) => ({ ...prev, childCcssAttributes: { ...prev.childCcssAttributes, crn } }))
                }
                onValidityChanged={(value: boolean) => setChildCrnValid(value)}
              />
            </Col>
            <Col>{''}</Col>
          </Row>
        )}
        <Row className="mb-8">
          <Col>
            <DateInput
              required
              label="Date of Birth"
              date={selectedChild ? selectedChild.dob : formData.dob}
              onDateSelect={(dob) =>
                setFormData((prev) => ({ ...prev, dob: !!dob ? moment(dob).format('MM/DD/YYYY') : dob }))
              }
              disabled={showAddExistingChildInput.show}
              className="kt-date-input-no-max-width"
              dateOnly
            />
          </Col>
          <Col className="d-flex align-items-center">
            {getAgeStringFromDateOfBirth(moment(selectedChild ? selectedChild.dob : formData.dob))}
          </Col>
        </Row>
        {/* enrollment-related inputs here when we circle back */}
        <h5 className="mb-2">Primary Contact</h5>
        <div className="font-size-12 mb-4">
          A primary contact will be given full authority over this account, including full permissions to all children.
          This contact will be an Emergency Contact by default.
        </div>
        <Row className="mb-4">
          <Col>
            <Checkbox
              label="Add Existing Contact?"
              value={showAddExistingContactInput.show}
              onChange={(checked) => {
                setShowAddExistingContactInput({ show: checked, term: '' });
                setSelectedContact(null);
              }}
            />
          </Col>
        </Row>
        <Collapse in={showAddExistingContactInput.show}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Contact</Form.Label>
                <AsyncSelect
                  isLoading={searchContactsLoading}
                  value={selectedContact}
                  defaultOptions={[]}
                  placeholder="Search"
                  components={{ IndicatorSeparator: null }}
                  className="react-select-container w-100"
                  classNamePrefix="react-select"
                  loadOptions={(inputValue) => handleContactSearchInput(inputValue)}
                  loadingMessage={() => 'Searching contacts...'}
                  formatOptionLabel={(option: IContact) => (
                    <div className="d-flex flex-row align-items-center">
                      <Avatar
                        color={stringToHsl(option.id)}
                        size="sm"
                        image={option.avatar?.url ?? ''}
                        initials={getInitials(option)}
                      />
                      <div className="d-flex flex-column ml-2 text-truncate sm">
                        <div>{getFullName(option)}</div>
                        <div>
                          {option.primaryPhoneNumber && option.primaryPhoneNumber.number && (
                            <small>
                              Phone: {parsePhoneNumberWithRegion(option.primaryPhoneNumber.number).formatNational()}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  onChange={(option) => setSelectedContact(option as IContact)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Collapse>
        <Row>
          <Col>
            <TextInput
              required
              name="contact-fn"
              label="First Name"
              value={selectedContact ? selectedContact.firstname : formData.accountHolder.firstname}
              onChange={(firstname) =>
                setFormData((prev) => ({ ...prev, accountHolder: { ...prev.accountHolder, firstname } }))
              }
              disabled={showAddExistingContactInput.show}
            />
          </Col>
          <Col>
            <TextInput
              required={!isAuRegion}
              name="contact-ln"
              label="Last Name"
              value={selectedContact ? selectedContact.lastname : formData.accountHolder.lastname}
              onChange={(lastname) =>
                setFormData((prev) => ({ ...prev, accountHolder: { ...prev.accountHolder, lastname } }))
              }
              disabled={showAddExistingContactInput.show}
            />
          </Col>
        </Row>
        <Row align="start">
          {isAuRegion && (
            <Col>
              <DateInput
                required={!isAuRegion}
                label="Date of Birth"
                date={selectedContact ? selectedContact.dob : formData.accountHolder.dob}
                onDateSelect={(dob) =>
                  setFormData((prev) => ({ ...prev, accountHolder: { ...prev.accountHolder, dob } }))
                }
                disabled={showAddExistingContactInput.show}
                dateOnly
              />
            </Col>
          )}
          {isAuRegion && (
            <Col>
              <CrnInput
                label="CRN"
                value={formData.contactCcssAttributes?.crn ?? ''}
                isInvalid={!isContactCrnValid}
                onBlur={() => {
                  validateContactCrn({
                    variables: {
                      businessId: formData.entityId,
                      crn: formData.contactCcssAttributes?.crn ?? '',
                    },
                  });
                }}
                errorText={renderCrnError(contactCrnResult?.validateCrn) ?? ''}
                onChange={(crn) =>
                  setFormData((prev) => ({ ...prev, contactCcssAttributes: { ...prev.contactCcssAttributes, crn } }))
                }
                onValidityChanged={(value: boolean) => setContactCrnValid(value)}
              />
            </Col>
          )}
        </Row>
        <EmailInput
          required
          name="contact-em"
          label="Email"
          value={
            selectedContact && isEmailValid(selectedContact.email)
              ? selectedContact.email
              : formData.accountHolder.email
          }
          onChange={(email) => setFormData((prev) => ({ ...prev, accountHolder: { ...prev.accountHolder, email } }))}
          disabled={showAddExistingContactInput.show && !!selectedContact?.email}
          isInvalid={
            !((selectedContact && isEmailValid(selectedContact.email)) || isEmailValid(formData.accountHolder.email))
          }
        />
        {k2SendAppInvite && (
          <Row className="mb-4">
            <Col>
              <Checkbox
                label="Send confirmation email"
                value={sendInvite}
                onChange={() => setSendInvite(!sendInvite)}
              />
            </Col>
          </Row>
        )}
        <PhoneNumberAndTypeInput
          required
          label="Primary Phone Number"
          phoneNumber={
            (selectedContact ? selectedContact.primaryPhoneNumber : formData.accountHolder.primaryPhoneNumber) ?? {
              number: '',
              type: undefined,
            }
          }
          updatePhoneNumber={(primaryPhoneNumber) =>
            setFormData((prev) => ({ ...prev, accountHolder: { ...prev.accountHolder, primaryPhoneNumber } }))
          }
          errorText={errorMessage.invalidPhoneNumber}
          isInvalid={Boolean(
            formData.accountHolder.primaryPhoneNumber?.number &&
              !isValidPhoneNumber(formData.accountHolder.primaryPhoneNumber?.number)
          )}
          extraDiv={false}
          disabled={showAddExistingContactInput.show}
        />
        <Row>
          <Col>
            <Select
              label="Relationship to Child"
              required
              value={formData.relationship ?? null}
              options={Object.entries(childContactRelationship).map((r) => ({ value: r[0], label: r[1] }))}
              onChange={(option: any) => setFormData((prev) => ({ ...prev, relationship: option.value }))}
            />
          </Col>
          <Col> </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              label="Account Name"
              value={formData.accountName}
              onChange={(value) => setFormData((prev) => ({ ...prev, accountName: value }))}
            />
          </Col>
        </Row>
      </Form>
    </SideModalDrawer>
  );
};

export default AddAccountModalForm;
