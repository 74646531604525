import React, { useCallback, useState } from 'react';
import Header from '../Header';
import Body from '../Body';
import AvatarUpload from 'shared/components/AvatarUpload';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'; // QuickActionButton uses light-icons so that's why we're importing IconDefinition from that package
import Circle from 'shared/components/LoadingSkeletons/Circle';
import Line from 'shared/components/LoadingSkeletons/Line';
import Avatar from 'shared/components/Avatar';
import { IDataType } from 'shared/constants/enums/dataTypeEnum';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useWindowSize from 'shared/hooks/useWindowSize';
import Tooltip from 'shared/components/Tooltip';
import { Skeleton, Typography } from '@mui/material';

interface IAdditionalProfileAction {
  tooltip: string;
  icon: IconDefinition;
  onClick: () => void;
}

interface IProps {
  title: string;
  subtitle?: string;
  subtitleComponent?: React.ReactNode | null;
  avatarUrl?: string;
  avatarLoading?: boolean;
  onAvatarClick?: (file: File) => void;
  additionalActions?: IAdditionalProfileAction[];
  children: React.ReactNode | null;
  loading: boolean;
  readOnlyAvatar?: boolean;
  buttonComponent?: React.ReactNode;
  mobileButtonComponent?: React.ReactNode;
  showAvatar?: boolean;
  dataTypeLabel?: IDataType | null;
  showCopyIconWithinSubtitle?: boolean;
  onCopyClick?: () => void;
  applyPadding?: boolean;
}

const ProfilePageWrapper: React.FC<IProps> = ({
  title,
  subtitle,
  onAvatarClick = () => {},
  avatarUrl = '',
  loading,
  readOnlyAvatar = false,
  avatarLoading = false,
  buttonComponent,
  mobileButtonComponent,
  showAvatar = true,
  dataTypeLabel,
  showCopyIconWithinSubtitle = false,
  onCopyClick = () => {},
  subtitleComponent,
  applyPadding = true, // apply padding to your wrapped component. if this is false, you are then responsible for adding <Body /> where you deem necessary
  ...props
}) => {
  const [showCopiedTooltip, setShowCopiedTooltip] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user);
  const windowSize = useWindowSize();
  const isMobile = windowSize.innerWidth <= 768;

  const handleCopyClick = useCallback(() => {
    setShowCopiedTooltip(true);
    onCopyClick();
    setTimeout(() => setShowCopiedTooltip(false), 1000);
  }, [onCopyClick]);

  return (
    <>
      <Header>
        <div style={{ flex: 1 }}>
          <div className="d-flex" style={{ width: '100%' }}>
            {showAvatar &&
              (loading ? (
                <Circle size="lg" />
              ) : readOnlyAvatar ? (
                <Avatar
                  image={avatarUrl}
                  size="lg"
                  initials={title.split(/\s/).reduce((r, w) => r + w.slice(0, 1), '')}
                >
                  {' '}
                </Avatar>
              ) : (
                <AvatarUpload
                  loading={avatarLoading}
                  image={avatarUrl}
                  onFileSelect={onAvatarClick}
                  placeholder="Upload"
                />
              ))}
            <div className="ml-3 text-truncate d-flex flex-column justify-content-center" style={{ flex: 1 }}>
              <div className="d-flex" style={{ width: '100%' }}>
                <Typography
                  component="h1"
                  variant="pageHeader"
                  id="profile-page-wrapper-header"
                  className="text-truncate"
                  minWidth="50%"
                >
                  {loading ? <Skeleton /> : title}
                </Typography>
                {dataTypeLabel && user?.isInternal && (
                  <Typography component="h1" variant="pageHeader">
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <span className={`badge badge-${dataTypeLabel.color} text-light`}>{dataTypeLabel.label}</span>
                    )}
                  </Typography>
                )}
              </div>
              {subtitle && (
                <div className="d-flex align-items-center">
                  <Typography variant="small" minWidth="25%" id="profile-page-wrapper-subtitle">
                    {loading ? <Skeleton /> : subtitle}
                  </Typography>
                  {showCopyIconWithinSubtitle && (
                    <Tooltip text="Copied to clipboard!" direction="right" showTooltip={showCopiedTooltip}>
                      <button className="kt-page-wrapper-copy-btn ml-4" onClick={handleCopyClick}>
                        Copy ID
                      </button>
                    </Tooltip>
                  )}
                </div>
              )}
              {subtitleComponent}
            </div>
          </div>
        </div>
        <div className="ml-auto d-flex flex-row flex-wrap">
          {(!isMobile || !mobileButtonComponent) && buttonComponent}
          {isMobile && mobileButtonComponent}
        </div>
      </Header>
      {applyPadding ? <Body>{props.children}</Body> : props.children}
    </>
  );
};

export default ProfilePageWrapper;
