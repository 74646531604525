import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import {
  pagedKindyForAllCredential,
  pagedKindyForAllCenterAgreement,
  kindyForAllAgreement,
  pagedKindyForAllCenterSummary,
  pagedKindyForAllChildSummary,
  pagedKindyForAllAcquittalCenterSummary,
  pagedKindyForAcquittalChildSummary,
  kindyForAllQuarter,
  kindyForAllIneligibility,
  childFundingSchedules,
} from './fields';
import {
  IKindyForAllAgreement,
  IKindyForAllCenterAgreement,
  IKindyForAllCenterSummary,
  IKindyForAllChildSummary,
  IKindyForAllCredential,
  IKindyForAllAcquittalCenterSummary,
  IKindyForAllAcquittalChildSummary,
  IKindyForAllQuarterlyPeriod,
} from 'shared/types/kindyForAll';
import { KindyChildFundingSchedulesResult, KindyForAllIneligibilityDto } from 'generated/graphql';

interface IGetKindyForAllAgreements {
  getKindyForAllAgreements: IKindyForAllAgreement[];
}

interface IGetKindyForAllIneligibility {
  getKindyForAllIneligibility: KindyForAllIneligibilityDto[];
}

interface IGetChildFundingSchedules {
  getChildFundingSchedules: KindyChildFundingSchedulesResult[];
}

interface IGetChildFundingSchedulesForAccount {
  getKindyChildFundingSchedulesForAccount: KindyChildFundingSchedulesResult[];
}

interface IGetPagedKindyForAllCenterAgreements {
  getKindyForAllCenterAgreements: IPagedResult<IKindyForAllCenterAgreement>;
}

interface IGetPagedKindyForAllCenterSummaries {
  getKindyForAllCenterSummaries: IPagedResult<IKindyForAllCenterSummary>;
}

interface IGetPagedKindyForAllChildSummaries {
  getKindyForAllChildSummaries: IPagedResult<IKindyForAllChildSummary>;
}

interface IGetPagedKindyForAllAcquittalCenterSummaries {
  getKindyForAllAcquittalCenterSummaries: IPagedResult<IKindyForAllAcquittalCenterSummary>;
}

interface IGetPagedKindyForAllAcquittalChildSummaries {
  getKindyForAllAcquittalChildSummaries: IPagedResult<IKindyForAllAcquittalChildSummary>;
}

interface IGetPagedKindyForAllCredentials {
  getKindyForAllCredentials: IPagedResult<IKindyForAllCredential>;
}

interface IGetKindyForAllAgreementsVariables {
  input: {
    businessId: string;
  };
}

interface IGetKindyForAllIneligibilityVariables {
  input: {
    centerId: string;
    childId: string;
  };
}

interface IGetKindyChildFundingSchedulesInput {
  input: {
    accountId: string;
    programChildId: string;
  };
}

interface IGetKindyChildFundingSchedulesForAccountInput {
  input: {
    accountId: string;
  };
}

interface IGetPagedKindyForAllResultVariables {
  input: {
    businessId: string;
    searchKey?: string;
    pageNumber: number;
    pageSize: number;
    sortBy?: string;
    sortDirection?: string;
  };
}

interface IGetPagedKindyForAllCenterSummariesResultVariables {
  input: {
    centerIds: string[];
    year: number;
    period: string;
    searchKey?: string;
    pageNumber: number;
    pageSize: number;
    sortBy?: string;
    sortDirection?: string;
  };
}

interface IGetPagedKindyForAllChildSummariesResultVariables {
  input: {
    centerIds: string[];
    year: number;
    period: string;
    searchKey?: string;
    pageNumber: number;
    pageSize: number;
    sortBy?: string;
    sortDirection?: string;
  };
}

interface IGetKindyForAllQuartersVariables {
  input: {
    years: number[];
  };
}

interface IGetKindyForAllQuarters {
  getKindyForAllQuarters: IKindyForAllQuarterlyPeriod[];
}

export const GET_KINDY_FOR_ALL_AGREEMENTS = gql`
  query($input: GetKindyForAllAgreementsInput!) {
    getKindyForAllAgreements(input: $input) {
      ${kindyForAllAgreement}
    }
  }
`;

export const GET_KINDY_FOR_ALL_CENTER_AGREEMENTS = gql`
  query($input: GetPagedKindyForAllResultInput!) {
    getKindyForAllCenterAgreements(input: $input) {
      ${pagedKindyForAllCenterAgreement}
    }
  }
`;

export const GET_KINDY_FOR_ALL_CENTER_SUMMARIES = gql`
  query($input: GetPagedKindyForAllCenterSummariesInput!) {
    getKindyForAllCenterSummaries(input: $input) {
      ${pagedKindyForAllCenterSummary}
    }
  }
`;

export const GET_KINDY_FOR_ALL_CHILD_SUMMARIES = gql`
  query($input: GetPagedKindyForAllChildSummariesInput!) {
    getKindyForAllChildSummaries(input: $input) {
      ${pagedKindyForAllChildSummary}
    }
  }
`;

export const GET_KINDY_FOR_ALL_CREDENTIALS = gql`
  query($input: GetPagedKindyForAllResultInput!) {
    getKindyForAllCredentials(input: $input) {
      ${pagedKindyForAllCredential}
    }
  }
`;

export const GET_KINDY_FOR_ALL_ACQUITTAL_CENTER_SUMMARIES = gql`
  query($input: GetPagedKindyForAllCenterSummariesInput!) {
    getKindyForAllAcquittalCenterSummaries(input: $input) {
      ${pagedKindyForAllAcquittalCenterSummary}
    }
  }
`;

export const GET_KINDY_FOR_ALL_ACQUITTAL_CHILD_SUMMARIES = gql`
  query($input: GetPagedKindyForAllChildSummariesInput!) {
    getKindyForAllAcquittalChildSummaries(input: $input) {
      ${pagedKindyForAcquittalChildSummary}
    }
  }
`;

export const GET_KINDY_FOR_ALL_QUARTERS = gql`
  query($input: GetKindyForAllQuartersInput!) {
    getKindyForAllQuarters(input: $input) {
      ${kindyForAllQuarter}
    }
  }
`;

export const GET_KINDY_FOR_ALL_INELIGIBILITY = gql`
  query($input: GetKindyForAllIneligibilityInput!) {
    getKindyForAllIneligibility(input: $input) {
      ${kindyForAllIneligibility}
    }
  }
`;

export const GET_CHILD_FUNDING_SCHEDULES = gql`
query($input: GetKindyChildFundingSchedulesInput!) {
  getKindyChildFundingSchedules(input: $input) {
    ${childFundingSchedules}
  }
}
`;

export const GET_CHILD_FUNDING_SCHEDULES_FOR_ACCOUNT = gql`
query($input: GetKindyChildFundingSchedulesForAccountInput!) {
  getKindyChildFundingSchedulesForAccount(input: $input) {
    ${childFundingSchedules}
  }
}
`;

export const useGetKindyForAllAgreements = (
  options?: QueryHookOptions<IGetKindyForAllAgreements, IGetKindyForAllAgreementsVariables>
) =>
  useQuery(GET_KINDY_FOR_ALL_AGREEMENTS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetKindyForAllCenterAgreements = (
  options?: QueryHookOptions<IGetPagedKindyForAllCenterAgreements, IGetPagedKindyForAllResultVariables>
) =>
  useQuery(GET_KINDY_FOR_ALL_CENTER_AGREEMENTS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetKindyForAllCenterSummaries = (
  options?: QueryHookOptions<IGetPagedKindyForAllCenterSummaries, IGetPagedKindyForAllCenterSummariesResultVariables>
) =>
  useQuery(GET_KINDY_FOR_ALL_CENTER_SUMMARIES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetKindyForAllChildSummaries = (
  options?: QueryHookOptions<IGetPagedKindyForAllChildSummaries, IGetPagedKindyForAllChildSummariesResultVariables>
) =>
  useQuery(GET_KINDY_FOR_ALL_CHILD_SUMMARIES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetKindyForAllCredentials = (
  options?: QueryHookOptions<IGetPagedKindyForAllCredentials, IGetPagedKindyForAllResultVariables>
) =>
  useQuery(GET_KINDY_FOR_ALL_CREDENTIALS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetKindyForAllAcquittalCenterSummaries = (
  options?: QueryHookOptions<
    IGetPagedKindyForAllAcquittalCenterSummaries,
    IGetPagedKindyForAllCenterSummariesResultVariables
  >
) =>
  useQuery(GET_KINDY_FOR_ALL_ACQUITTAL_CENTER_SUMMARIES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetKindyForAllAcquittalChildSummaries = (
  options?: QueryHookOptions<
    IGetPagedKindyForAllAcquittalChildSummaries,
    IGetPagedKindyForAllChildSummariesResultVariables
  >
) =>
  useQuery(GET_KINDY_FOR_ALL_ACQUITTAL_CHILD_SUMMARIES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetKindyForAllQuarters = (
  options?: QueryHookOptions<IGetKindyForAllQuarters, IGetKindyForAllQuartersVariables>
) =>
  useQuery(GET_KINDY_FOR_ALL_QUARTERS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetKindyForAllIneligibility = (
  options?: QueryHookOptions<IGetKindyForAllIneligibility, IGetKindyForAllIneligibilityVariables>
) =>
  useQuery(GET_KINDY_FOR_ALL_INELIGIBILITY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetChildFundingSchedules = (
  options?: QueryHookOptions<IGetChildFundingSchedules, IGetKindyChildFundingSchedulesInput>
) =>
  useQuery(GET_CHILD_FUNDING_SCHEDULES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetChildFundingSchedulesForAccount = (
  options?: QueryHookOptions<IGetChildFundingSchedulesForAccount, IGetKindyChildFundingSchedulesForAccountInput>
) =>
  useQuery(GET_CHILD_FUNDING_SCHEDULES_FOR_ACCOUNT, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
