import React, { useMemo, useState } from 'react';
import { isRegion } from 'shared/util/region';
import { RouteComponentProps } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PageWrapper from 'shared/components/PageWrapper';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AllPaymentsTab from './AllPaymentsTab';
import UnbatchedPaymentsTab from './UnbatchedPaymentsTab';
import BatchedPaymentsTab from './BatchedPaymentsTab';
import DisbursementsTab from './DisbursementsTab';
import Badge from 'shared/components/Badge';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import MobileButtonComponents from './components/MobileButtonComponents';
import ButtonComponents from './components/ButtonComponents';
import BatchablePaymentsTab from './BatchablePayments';
import { useGetTotalUnbatchedPaymentTransactions } from 'gql/batch/queries';

type PaymentsPageTabs = 'payments' | 'batched' | 'unbatched' | 'disbursements';

export interface ICancelPaymentModalStateShape {
  open: boolean;
  payment: IPayment | null;
}

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const Payments: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['billing']);
  const { k2DisbursementsReport } = useFlags();
  const isUSRegion = isRegion('US');
  const [activeTab, setActiveTab] = useState<PaymentsPageTabs>('payments');
  const centerIdToBatch = useSelector((state: RootState) => state.billing.paymentBatches.centerIdToBatch);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const [showManualPaymentModal, setShowManualPaymentModal] = useState<boolean>(false);
  const [showPaymentReportModal, setShowPaymentReportModal] = useState<boolean>(false);
  const [showCreateBatchModal, setShowCreateBatchModal] = useState<boolean>(false);

  const { data: totalUnbatchedPaymentsData } = useGetTotalUnbatchedPaymentTransactions({
    variables: {
      input: {
        businessId: currentBusinessId ?? '',
        centerIds: centerIdToBatch ? [centerIdToBatch] : undefined,
      },
    },
    skip: !currentBusinessId,
    fetchPolicy: 'cache-and-network',
  });

  const totalUnbatchedPayments = useMemo(() => {
    return totalUnbatchedPaymentsData?.getTotalUnbatchedTransactionPayments.totalUnbatchedTransactions ?? 0;
  }, [totalUnbatchedPaymentsData]);

  return (
    <PageWrapper
      pageTitle={t('billing:transactions.payments.page-title')}
      applyPadding
      mobileButtonComponent={
        <MobileButtonComponents
          activeTab={activeTab}
          onShowCreateBatchModal={() => setShowCreateBatchModal(true)}
          onShowManualPaymentModal={() => setShowManualPaymentModal(true)}
        />
      }
      buttonComponent={
        <ButtonComponents
          activeTab={activeTab}
          onShowCreateBatchModal={() => setShowCreateBatchModal(true)}
          onShowManualPaymentModal={() => setShowManualPaymentModal(true)}
          onShowPaymentReportModal={() => setShowPaymentReportModal(true)}
        />
      }
    >
      <Tabs id="payment-tabs" activeKey={activeTab} onSelect={(tab) => setActiveTab(tab as PaymentsPageTabs)}>
        <Tab eventKey="payments" title={t('billing:transactions.payments.tab-allPayments')}>
          <AllPaymentsTab
            showRunReportModal={showPaymentReportModal}
            showAddPaymentModal={showManualPaymentModal}
            onHideRunReportModal={() => setShowPaymentReportModal(false)}
            onAddPaymentModal={() => setShowManualPaymentModal(false)}
          />
        </Tab>
        {isUSRegion ? (
          <Tab
            eventKey="batchable"
            title={
              <div className="d-flex align-items-center">
                {t('billing:transactions.payments.tab-unbatched')}{' '}
                <Badge count={totalUnbatchedPayments} overflowCount={99} variant="pill" className="ml-2" />
              </div>
            }
          >
            <BatchablePaymentsTab></BatchablePaymentsTab>
          </Tab>
        ) : (
          <Tab
            eventKey="unbatched"
            title={
              <div className="d-flex align-items-center">
                {t('billing:transactions.payments.tab-unbatched')}{' '}
                <Badge count={totalUnbatchedPayments} overflowCount={99} variant="pill" className="ml-2" />
              </div>
            }
          >
            <UnbatchedPaymentsTab
              showCreateBatchModal={showCreateBatchModal}
              onCloseCreateBatchModal={() => setShowCreateBatchModal(false)}
            />
          </Tab>
        )}

        <Tab eventKey="batched" title={t('billing:transactions.payments.tab-batched')}>
          <BatchedPaymentsTab />
        </Tab>
        {isUSRegion && k2DisbursementsReport && (
          <Tab eventKey="disbursements" title={t('billing:transactions.payments.tab-disbursements')}>
            <DisbursementsTab />
          </Tab>
        )}
      </Tabs>
    </PageWrapper>
  );
};

export default Payments;
