import React, { useState, useEffect } from 'react';
import Card from 'shared/components/Card';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import EnrollmentAvailabilityTable from './EnrollmentAvailabilityTable';
import moment from 'moment';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import { useGetClassesForCenter } from '../../graphql/queries';

interface IProps {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  data: IClass[];
  initialStartOfWeek: moment.Moment;
  initialEndOfWeek: moment.Moment;
  currentCenterId: string;
}

const CenterEnrollmentAvailablityCard: React.FC<IProps> = ({
  onClick,
  data,
  initialStartOfWeek,
  initialEndOfWeek,
  currentCenterId,
  ...props
}) => {
  const [timeframe, setTimeframe] = useState({
    startDate: initialStartOfWeek,
    endDate: initialEndOfWeek,
  });

  const { loading } = useGetClassesForCenter(
    currentCenterId,
    timeframe.startDate.toISOString(),
    timeframe.endDate.toISOString()
  );

  // any page the page time changes, update local state to reflect those changes
  useEffect(() => {
    setTimeframe({
      startDate: initialStartOfWeek,
      endDate: initialEndOfWeek,
    });
  }, [initialStartOfWeek, initialEndOfWeek]);

  // API returns all classes, we only want to show active classes
  const activeClasses = data
    .filter((c) => (c.archivedAt ? moment(c.archivedAt).isAfter(timeframe.endDate) : true))
    .filter((c) => (c.endsAt ? moment(c.endsAt).isAfter(timeframe.endDate) : true));

  return (
    <Card header="Full Time Equivalency" bodyClassName="pt-0">
      <div className="d-flex flex-row align-items-center justify-content-center bg-white py-2">
        <WeekPicker
          displayFormat="MMM D"
          reactDatesController="RANGE"
          onChange={(dates) => setTimeframe(dates)}
          className="flex-grow-0 mb-0"
          startDate={timeframe.startDate}
          endDate={timeframe.endDate}
        />
      </div>
      <div onClick={onClick} id="enrollment-dashboard-availability-table-container">
        {loading && !data.length && <LoadingLines number={3} />}
        <SpinnerOverlay show={loading && data.length > 0}>
          <EnrollmentAvailabilityTable data={activeClasses} />
        </SpinnerOverlay>
      </div>
    </Card>
  );
};

export default CenterEnrollmentAvailablityCard;
