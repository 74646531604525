import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { useSelector } from 'react-redux';
import Alert from 'shared/components/Alert';
import BusinessSelect from 'shared/components/BusinessSelect';
import PageWrapper from 'shared/components/PageWrapper';
import { TagCategory } from 'shared/constants/enums';
import tagExample from 'shared/images/tag-banner.svg';
import { RootState } from 'store/reducers';
import DisplayTags from './components/DisplayTags';
import { useGetTags } from './graphql/queries';

interface IProps {}

const ManageTags: React.FC<IProps> = ({ ...props }) => {
  // TODO: We need to build our actual real state object somewhere
  const [businessDetails, setBusinessDetails] = useState();
  const userEntityId = useSelector((state: RootState) => state.user?.entityId);
  const [selectedEntityId, setSelectedEntityId] = useState<string | undefined>();
  const entityId = userEntityId || selectedEntityId;
  // TODO: Change this from type of any
  const { loading, errors, data }: any = useGetTags(entityId || '');
  const tags: ITag[] = data && data.getEntity && data.getEntity.tags;

  useEffect(() => {
    // set state after initial query completes
    if (data && data.getEntity && !businessDetails) {
      setBusinessDetails(data.getEntity);
    }
  }, [data, businessDetails]);

  const TagHeader: React.FC = () => {
    return (
      <Card className="px-8 pt-4 pb-2">
        <div className="d-flex flex-row">
          <div className="col-12 col-lg-6 pt-6 pb-8">
            <h5 className="mt-4 mb-6">Did you know?</h5>
            <p> You can create links within your business to easily reference, report, and sort within our software.</p>
            <ul>
              <li>Identify operational regions</li>
              <li>Customize how you identify and report</li>
              <li>Streamline tracking</li>
            </ul>
            <div className="red-info-block p-4 d-flex flex-row">
              <div className="mr-4">
                <FontAwesomeIcon icon={faInfoCircle} size="2x" />
              </div>
              <div className="mr-6 d-flex align-items-center">
                Tags are visible to those with permissions to view related profiles.
              </div>
            </div>
          </div>
          <div className="col-auto col-lg-6 d-flex align-items-center">
            <img src={tagExample} alt="tag-example" className="tag-example d-none d-lg-block ml-auto w-100" />
          </div>
        </div>
      </Card>
    );
  };

  return (
    <PageWrapper pageTitle="Tags">
      {errors && <Alert variant="danger">{errors.message}</Alert>}
      <TagHeader />
      {!userEntityId && (
        <Card className="p-8">
          <div style={{ maxWidth: '500px' }}>
            <BusinessSelect
              selectedBusinessId={selectedEntityId}
              onSelectBusiness={(entity) => setSelectedEntityId(entity.id)}
              className="m-4"
            />
          </div>
        </Card>
      )}
      {entityId && (
        <div className="p-0 m-0 d-flex flex-wrap">
          <DisplayTags
            loading={loading}
            entityId={entityId}
            tags={tags && tags.filter((t: ITag) => t.category === TagCategory.CENTER.value)}
            type={TagCategory.CENTER}
          />
          <DisplayTags
            loading={loading}
            entityId={entityId}
            tags={tags && tags.filter((t: ITag) => t.category === TagCategory.STAFF.value)}
            type={TagCategory.STAFF}
          />
          <DisplayTags
            loading={loading}
            entityId={entityId}
            tags={tags && tags.filter((t: ITag) => t.category === TagCategory.CHILD.value)}
            type={TagCategory.CHILD}
          />
          <DisplayTags
            loading={loading}
            entityId={entityId}
            tags={tags && tags.filter((t: ITag) => t.category === TagCategory.CONTACT.value)}
            type={TagCategory.CONTACT}
          />
          <DisplayTags
            loading={loading}
            entityId={entityId}
            tags={tags && tags.filter((t: ITag) => t.category === TagCategory.ACCOUNT.value)}
            type={TagCategory.ACCOUNT}
          />
        </div>
      )}
    </PageWrapper>
  );
};

export default ManageTags;
