import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

interface IProps {
  label: string;
  oldValue?: React.ReactNode;
  newValue: React.ReactNode;
}

const FormDiff: React.FC<IProps> = ({ label, oldValue, newValue, ...props }) => {
  const { t } = useTranslation(['translation', 'enrollment']);

  return (
    <div className="d-flex justify-content-start align-items-start form-diff">
      <div className="diff-label">{label}</div>
      <div className="diff-values">
        {oldValue !== null && oldValue !== undefined && <div>{oldValue}</div>}
        <div>{newValue}</div>
      </div>
    </div>
  );
};

export default FormDiff;
