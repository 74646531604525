import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { pagedFields } from './fields';

interface IGetAccountDocumentsData {
  getAccountDocuments: IPagedResult<ICoreDocument>;
}

interface IGetAccountDocumentsVariables {
  input: {
    accountId: string;
    pageSize: number;
    pageNumber: number;
    sort?: ISearchSort[];
    searchText?: string;
    showArchived?: boolean;
    type?: string;
    associatedEntityId?: string;
  };
}

export const GET_ACCOUNT_DOCUMENTS = (fields: string = pagedFields) => gql`
  query ($input: GetAccountDocumentsInput!) {
    getAccountDocuments(input: $input) {
      ${fields}
    }
  }
`;

export const useGetAccountDocuments = (
  options?: QueryHookOptions<IGetAccountDocumentsData, IGetAccountDocumentsVariables>,
  fields?: string
) =>
  useQuery<IGetAccountDocumentsData, IGetAccountDocumentsVariables>(GET_ACCOUNT_DOCUMENTS(fields), {
    fetchPolicy: 'network-only',
    ...options,
  });
