import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import Modal from 'react-bootstrap/Modal'; // can't use <CenteredModal /> here :(
import { orderBy, uniqBy } from 'lodash';
import moment from 'moment';
import Button from 'shared/components/Buttons';
import Avatar from 'shared/components/Avatar';
import Alert from 'shared/components/Alert';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import { getFullName, getInitials, stringToHsl } from 'shared/util/string';
import SessionGroup from './SessionGroup';
import Select from 'shared/components/Select';
import { IGetAttendanceOpenSpotsData, IGetExpectedSessionsData } from 'gql/session/queries';
import classnames from 'classnames';
import Accordion from 'shared/components/Accordion';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  child: IChild | null;
  tableRowSessions: ISession[]; // sessions that are part of the selected table row
  childSessionsForWeek: ISession[]; // all sessions that belong to the child
  viewingWeek: boolean;
  timezone: Timezone;
  onClearSession: (session: ISession) => void;
  onReportAbsent: (sessionId: string | null, contractId: string | null, date: string, accountChildId: string) => void;
  refetchOpenSpotsData: () => Promise<ApolloQueryResult<IGetAttendanceOpenSpotsData>>; // refetch function from `useGetAttendanceOpenSpots`
  refetchExpectedSessions: () => void; // refetch function from `useGetExpectedSessions`
  onDeleteSessionTimeEntry: (timeEntry: IAttendanceTimeEntry) => void;
}

export interface INewTimeEntryShape {
  sessionId: string;
  date: string;
  timeIn: string | null;
  timeOut: string | null;
}

// custom object to allow us to update a session with different states than a normal session (i.e. allow a class or fee to be temporarily null)
export interface ISessionUpdateShape extends Omit<ISession, 'feeId' | 'fee' | 'classId' | 'class'> {
  feeId: string | null;
  fee: IFee | null;
  classId: string | null;
  class: IClass | null;
  newTimeEntries?: INewTimeEntryShape[];
}

// allows one or more sessions to be edited at a time
const EditSessionModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  child,
  tableRowSessions,
  timezone,
  childSessionsForWeek,
  viewingWeek,
  onClearSession,
  onReportAbsent,
  refetchOpenSpotsData,
  refetchExpectedSessions,
  onDeleteSessionTimeEntry,
  ...props
}) => {
  const sortedSessions = useMemo(() => orderBy(tableRowSessions, (s) => s.date, 'asc'), [tableRowSessions]);
  const isSessionToday = useCallback(
    (session: ISession | ISessionUpdateShape) =>
      moment().tz(timezone).isSame(moment(session.date).tz(timezone), 'date'),
    []
  );
  const defaultSessionIndexInView = useMemo(
    () => (sortedSessions.length === 1 ? 0 : sortedSessions.findIndex(isSessionToday)),
    [sortedSessions]
  );
  const classOptions = useMemo(
    () =>
      orderBy(
        uniqBy(childSessionsForWeek, (s) => s.classId).map((s) => s.class),
        (c) => c.name
      ),
    [childSessionsForWeek]
  );
  /**
   * key to control which session group is visible.
   * if the value is set to an empty string, all session groups will be visible.
   * if the value is set to a specific key, the session corresponding to that key will be visible and all others will collapse
   */
  const [activeClassFilterId, setActiveClassFilterId] = useState(tableRowSessions[0]?.classId ?? '');
  const [formData, setFormData] = useState<ISessionUpdateShape[]>(
    orderBy(
      (viewingWeek ? childSessionsForWeek : tableRowSessions).filter((s) => s.classId === activeClassFilterId),
      (s) => s.date,
      'asc'
    )
  );

  useEffect(() => {
    // whenever the underlying datasource is updated, update the state array
    setFormData(
      orderBy(
        (viewingWeek ? childSessionsForWeek : tableRowSessions).filter((s) => s.classId === activeClassFilterId),
        (s) => s.date,
        'asc'
      )
    );
    setActiveClassFilterId((prev) => (prev !== '' ? prev : tableRowSessions[0]?.classId ?? ''));
  }, [tableRowSessions]);

  useEffect(() => {
    if (activeClassFilterId) {
      setFormData(
        orderBy(
          (viewingWeek ? childSessionsForWeek : tableRowSessions).filter((s) => s.classId === activeClassFilterId),
          (s) => s.date,
          'asc'
        )
      );
    }
  }, [activeClassFilterId]);

  const handleClose = useCallback(() => {
    // reset state
    setFormData([]);
    setActiveClassFilterId('');
    onClose();
  }, [onClose]);

  const updateSessionInState = useCallback(
    (session: ISessionUpdateShape, idx: number) => {
      const arr = [...formData];

      arr[idx] = session;

      setFormData(arr);
    },
    [formData]
  );

  const revertChanges = useCallback(
    (idx: number) => {
      // `idx` is the index of the array in state to revert back to its original value based on the `tableRowSessions` prop
      const stateArr = [...formData];

      stateArr[idx] = sortedSessions[idx];

      setFormData(stateArr);
    },
    [sortedSessions, formData]
  );

  const updateDropOffPickUp = useCallback(
    (time: string, direction: 'dropOff' | 'pickUp', idx: number) => {
      const arr = [...formData];

      if (direction === 'dropOff') {
        arr[idx].dropOffTime = time;
      } else {
        arr[idx].pickUpTime = time;
      }

      setFormData(arr);
    },
    [formData]
  );

  const getInitialSession = useCallback(
    (id: string): ISession | null => {
      return childSessionsForWeek.find((s) => s.id === id) ?? null;
    },
    [childSessionsForWeek]
  );

  const createClassString = useCallback((classes: IClass[]): string => {
    const classesString = classes.map((c) => c.name).join(', ');

    // replace the last comma with 'and' source: https://stackoverflow.com/a/25658003
    return classesString.replace(/,(?=[^,]*$)/, ' and');
  }, []);

  if (!child || !isOpen) {
    return null;
  }

  return (
    <Modal centered backdrop="static" show={isOpen} onHide={handleClose} size="xl" className="edit-session-modal">
      <Modal.Header closeButton className="px-4 py-2">
        <div className="d-flex flex-column">
          <Modal.Title as="h5">Edit</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-4 px-4 overflow-auto">
        <div className="d-flex flex-row align-items-center mb-6">
          <Avatar
            color={stringToHsl(child.id)}
            size="md"
            image={child.avatar?.url ?? ''}
            initials={getInitials(child)}
          />
          <div className="d-flex flex-column pl-4 text-truncate">
            <span className="text-truncate">{getFullName(child)}</span>
            <small>{getAgeStringFromDateOfBirth(moment(child.dob), false)}</small>
          </div>
          {classOptions.length > 1 && (
            <div className="ml-auto class-dropdown">
              <Select
                label="Currently Viewing"
                value={classOptions.find((c) => c.id === activeClassFilterId)}
                options={classOptions}
                onChange={(option: IClass) => setActiveClassFilterId(option.id)}
                getOptionLabel={(option: IClass) => option.name}
                getOptionValue={(option: IClass) => option.id}
              />
            </div>
          )}
        </div>
        {formData.length === 0 ? (
          <div>No sessions for the selected class.</div>
        ) : formData.length === 1 ? (
          formData.map((session: ISessionUpdateShape, idx: number) => (
            <div key={idx} className="p-4">
              <SessionGroup
                session={session}
                initialSession={getInitialSession(session.id)}
                timezone={timezone}
                onUpdate={(session) => updateSessionInState(session, idx)}
                onCancel={() => revertChanges(idx)}
                updateDropOffPickUp={(time: string, direction: 'dropOff' | 'pickUp') =>
                  updateDropOffPickUp(time, direction, idx)
                }
                onClearSession={() => onClearSession(session as ISession)}
                onReportAbsent={() =>
                  onReportAbsent(session.id ?? null, session.contractId ?? null, session.date, session.accountChildId)
                }
                refetchOpenSpotsData={refetchOpenSpotsData}
                refetchExpectedSessions={refetchExpectedSessions}
                onDeleteSessionTimeEntry={onDeleteSessionTimeEntry}
              />
            </div>
          ))
        ) : (
          <Accordion.Container className="mb-4" defaultActiveKey={defaultSessionIndexInView.toString()}>
            {formData.map((session: ISessionUpdateShape, idx: number) => (
              <Accordion.Item
                key={`edit-session-group-${session.id ?? '__'}-${idx}`}
                eventKey={idx.toString()}
                title={moment.tz(session.date, timezone).format('dddd, MMMM D')}
                titleClassName="border-0 p-2"
                className={classnames({
                  'border rounded p-2 mb-2': true,
                  'border-secondary': isSessionToday(session),
                  'border-border-color': !isSessionToday(session),
                })}
              >
                <SessionGroup
                  session={session}
                  initialSession={getInitialSession(session.id)}
                  timezone={timezone}
                  onUpdate={(session) => updateSessionInState(session, idx)}
                  onCancel={() => revertChanges(idx)}
                  updateDropOffPickUp={(time: string, direction: 'dropOff' | 'pickUp') =>
                    updateDropOffPickUp(time, direction, idx)
                  }
                  onClearSession={() => onClearSession(session as ISession)}
                  onReportAbsent={() =>
                    onReportAbsent(session.id ?? null, session.contractId ?? null, session.date, session.accountChildId)
                  }
                  refetchOpenSpotsData={refetchOpenSpotsData}
                  refetchExpectedSessions={refetchExpectedSessions}
                  onDeleteSessionTimeEntry={onDeleteSessionTimeEntry}
                />
              </Accordion.Item>
            ))}
          </Accordion.Container>
        )}
        {viewingWeek && classOptions.length > 1 && (
          <Alert variant="info" className="additional-classes-alert">
            {child.nickname ?? child.firstname} has additional sessions in{' '}
            {createClassString(classOptions.filter((c) => c.id !== activeClassFilterId))} this week.
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditSessionModal;
