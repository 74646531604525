import { useQuery } from 'shared/apis/core';

interface IGetStaffData {
  getStaff: IStaff;
}

interface IGetStaffVariables {
  id: string;
}

export const useGetStaff = (personId: string, graphqlQuery: any) =>
  useQuery<IGetStaffData, IGetStaffVariables>(graphqlQuery, {
    skip: personId === '',
    variables: {
      id: personId,
    },
  });
