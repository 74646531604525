import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useDownloadCsv = () => {
  const { t } = useTranslation();

  const download = (rows: string[][], filename: string, extension: string = 'csv', eol: string = '') => {
    const dateFormat: string = t('formatters.YYYY/MM/DD');
    const modifiedDateFormat = dateFormat.replace(/\//g, '.');
    const now = moment().format(`${modifiedDateFormat}_HH.mm.ss`);
    const stringifiedRows = rows.map((row) => row.join(',')).join(eol === 'windows' ? '\r\n' : '\n');
    const file = new Blob([stringifiedRows], {
      type: 'text/csv;charset=utf-8;',
    });
    const downloadLink = document.createElement('a');

    downloadLink.download = `${filename}_${now}.${extension}`;
    downloadLink.href = window.URL.createObjectURL(file);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();
  };
  return download;
};
