import React from 'react';
import { useGetRateFor } from './gql/queries';
import Spinner from 'shared/components/Spinner';
import { omitTypename } from 'shared/util/object';
import './_rateInfoBanner.scss';

const BASE_CLASS_NAME = 'rate-info-banner';

interface RateInfoBannerProps {
  contractTimeslots: ICreateContractTimeslotInput[];
  contractCycleType: ContractCycleType;
  selectedFee: { id: string; feeStructure: FeeStructure };
  dateToUse: string;
}
export default function RateInfoBanner(props: RateInfoBannerProps) {
  const { contractTimeslots, contractCycleType, selectedFee, dateToUse } = props;
  const { data, loading } = useGetRateFor({
    variables: {
      input: omitTypename({ contractTimeslots, contractCycleType, feeId: selectedFee.id, date: dateToUse }),
    },
    onError: (error) => {
      console.error(error);
    },
  });
  const qualifyingRate = data?.calculateQualifyingRate;
  if (!loading && !qualifyingRate) {
    return null;
  }
  return (
    <div className={BASE_CLASS_NAME}>
      {loading && <Spinner small className="ml-2" />}
      {!loading && qualifyingRate && (
        <div>
          <strong>Rate: </strong>${qualifyingRate.value} / Billing Cycle
        </div>
      )}
      {!loading && qualifyingRate && (
        <div className={BASE_CLASS_NAME + '_rate-description'}>
          {selectedFee.feeStructure === 'SINGLE_FLAT_RATE' ? '' : generateRateDescription(selectedFee, qualifyingRate)}
        </div>
      )}
    </div>
  );
}

function generateRateDescription(fee: { feeStructure: FeeStructure }, feeRate: IFeeRate) {
  if (fee.feeStructure === 'SINGLE_FLAT_RATE') {
    return '';
  } else if (fee.feeStructure === 'NUMBER_OF_DAYS') {
    return `${feeRate.qualifyingNumber} Day(s) Rate`;
  }
}
