import React, { useContext } from 'react';
import { ApplicationContext, ApplicationContextShape } from 'shared/contexts/ApplicationContext';

export type ApplicationContextStatusIndicatorProps = {};

export default function ApplicationContextStatusIndicator({}: ApplicationContextStatusIndicatorProps) {
  const applicationContext = useContext<ApplicationContextShape>(ApplicationContext);
  return (
    <div
      className="ApplicationContextStatusIndicator"
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 999,
        backgroundColor: 'red',
        color: 'black',
        padding: '5px',
      }}
    >
      {Object.keys(applicationContext).map((o) => (
        <div>
          <span>{`${o}: ${(applicationContext as any)[o]}`}</span>
        </div>
      ))}
    </div>
  );
}
