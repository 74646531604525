export const integrationApiKeyFields = `
  id
  key
  createdBy
  description
  businessId
  createdAt
  revokedAt
  centerScopes
  isDeleted
  apiScopes {
    business
    centers
    classes
    fees
    sessions
    attendances
    staff
    accounts
    children
    contacts
    transactions
    deposits
    entitlements
    applications
  }
  apiKeyWebHooks{
    id
    apiKeyId    
    url
    webHookType
    webHookApiKey
  }
`;
