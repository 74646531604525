import React, { useCallback, useMemo } from 'react';
import { components, OptionProps } from 'react-select';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { useGetAllActiveProgramsByCenterIdQuery, EnrollmentProgram } from 'generated/graphql';
import useFormatDate from 'shared/hooks/useFormatDate';
import Select from 'shared/components/Select';
import colors from '../../../_colors.module.scss';
import './_select.scss';
import './_enrollProgramSelect.scss';

interface IEnrollProgramSelectProps {
  value: EnrollmentProgram | null;
  onSelect: (enrollProgram: EnrollmentProgram | null) => void;
  centerId: string;
  businessId: string;
  required?: boolean;
  isDisabled?: boolean;
  classNames?: string;
  pinnedProgram?: EnrollmentProgram | null;
}

const EnrollProgramSelect = ({
  value,
  onSelect,
  centerId,
  businessId,
  required = false,
  isDisabled = false,
  classNames = '',
  pinnedProgram,
}: IEnrollProgramSelectProps) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const { data, loading } = useGetAllActiveProgramsByCenterIdQuery({
    variables: {
      businessId,
      centerId,
    },
    skip: !centerId || !businessId,
  });

  const options = useMemo(() => {
    const programs =
      data?.getEnrollmentPrograms && data?.getEnrollmentPrograms.data ? data?.getEnrollmentPrograms.data : [];

    /**
     * The query doesn't return a program if it is no longer active.
     * But the pinnedProgram provide a way to include a non-active program in select menu (and pinned at top of list as well).
     * We use it to include the originally selected program in the select menu.
     */

    if (pinnedProgram) {
      return [pinnedProgram, ...programs.filter((o) => o.id !== pinnedProgram.id)];
    }

    return programs;
  }, [data, pinnedProgram]);

  const MenuItem = useCallback(
    (props: OptionProps<ITableFilterOption, false>) => {
      const program = props.data as EnrollmentProgram;
      const { name, startDate, endDate, programCenters, operatingDays, minEnrolmentDays, maxEnrolmentDays } = program;

      const { feeName = '', className = '' } = programCenters.find((pc) => pc.centerId === centerId) ?? {};
      const isOpenForEnrollmentAdjusted = true;

      return (
        <div style={{ pointerEvents: isOpenForEnrollmentAdjusted ? 'inherit' : 'none' }}>
          <components.Option {...props} isDisabled={!isOpenForEnrollmentAdjusted}>
            <div className="enroll-program-menu-item">
              <span className="text-truncate program-name">{name}</span>
              {!isOpenForEnrollmentAdjusted && <small>Program is not open for {t('spelling.enrollment')}</small>}
              {isOpenForEnrollmentAdjusted && (
                <ul className="list-unstyled">
                  <li>
                    <span>{capitalize(t('spelling.date'))}:</span> {formatDate(startDate)} to {formatDate(endDate)}
                  </li>
                  <li>
                    <span>{capitalize(t('spelling.class'))} :</span> {className}
                  </li>
                  <li>
                    <span>{capitalize(t('spelling.fee'))}:</span> {feeName}
                  </li>
                  <li>
                    <span>{capitalize(t('enrollment.programs.min-enrollment-days'))}:</span>
                    {minEnrolmentDays}
                  </li>
                  <li>
                    <span>{capitalize(t('enrollment.programs.max-enrollment-days'))}:</span>
                    {maxEnrolmentDays}
                  </li>
                  <li>
                    <span>{capitalize(t('enrollment.programs.operating-days'))}:</span>
                    {operatingDays.join(', ')}
                  </li>
                </ul>
              )}
            </div>
          </components.Option>
        </div>
      );
    },
    [t, centerId, formatDate]
  );

  return (
    <Form.Group className={classNames}>
      <Form.Label>
        Programs
        {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color={colors.red} />}
      </Form.Label>
      <Select
        options={options}
        value={value}
        isLoading={loading}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onChange={(v) => onSelect(v)}
        className="react-select-container"
        classNamePrefix="react-select"
        noOptionsMessage={() => `${capitalize(t('spelling.no'))} ${t('spelling.program')} ${t('spelling.found')}`}
        placeholder={`${capitalize(t('spelling.program'))}`}
        disabled={isDisabled}
        components={{
          Option: MenuItem,
        }}
      />
    </Form.Group>
  );
};

export default EnrollProgramSelect;
