import React from 'react';
import { orderBy } from 'lodash';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { useGetHealthTerms } from 'gql/healthTerm/queries';
import { restrictionImportanceEnum } from 'shared/constants/enums/restrictionImportanceEnum';
const restrictionImportanceOptions = Object.keys(restrictionImportanceEnum);

export interface IRestrictionInputGroupFormShape {
  type: IHealthTerm | null;
  name: string;
  importance: Risk | null;
  description: string;
  documentation: IHealthConditionDocumentUploadInput[];
}

interface IProps {
  formData: IRestrictionInputGroupFormShape;
  onUpdate: (formData: IRestrictionInputGroupFormShape) => void;
  readOnlyTypeAndName?: boolean;
}

const RestrictionInputGroup: React.FC<IProps> = ({ formData, onUpdate, readOnlyTypeAndName, ...props }) => {
  const { loading: healthTermsLoading, data: healthTermsData } = useGetHealthTerms();
  const restrictionOptions = orderBy(
    (healthTermsData?.getHealthTerms ?? []).filter((term) => term.type === 'Restriction' && !term.archivedAt),
    (term) => term.name,
    'asc'
  );

  return (
    <>
      <Select
        required
        label="Type"
        options={restrictionOptions}
        value={formData.type}
        isLoading={healthTermsLoading}
        onChange={(opt: IHealthTerm) => onUpdate({ ...formData, type: opt })}
        getOptionLabel={(opt: IHealthTerm) => opt.name}
        getOptionValue={(opt: IHealthTerm) => opt.id}
        disabled={readOnlyTypeAndName}
      />
      <TextInput
        required
        label="Name"
        value={formData.name}
        onChange={(value: string) => onUpdate({ ...formData, name: value })}
        disabled={readOnlyTypeAndName}
      />
      <Select
        required
        label="Importance"
        options={restrictionImportanceOptions}
        getOptionLabel={(option) => restrictionImportanceEnum[option.value]}
        value={formData.importance}
        onChange={(value: string) => onUpdate({ ...formData, importance: value as Risk })}
      />
      <TextInput
        required
        label="Description"
        value={formData.description}
        onChange={(value: string) => onUpdate({ ...formData, description: value })}
        as="textarea"
        rows={3}
      />
    </>
  );
};

export default RestrictionInputGroup;
