import { getAllClasses } from 'pages/Centers/subroutes/Classes/duck/selectors';
import { useGetClassesForCenters } from 'pages/Centers/subroutes/Classes/graphql/queries';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import BaseSelect from 'shared/components/Select/Base';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { capitalize } from 'shared/util/string';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { useSearchStaff } from 'pages/Employees/subroutes/Profiles/graphql/queries';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { useSearchStaffForCenter } from 'pages/Centers/subroutes/Class/graphql/queries';

interface IFormStateShape {
  centerId: string;
  selectedEmployees: Array<{ value: string; label: string }> | null;
  startDate: string | null;
  endDate: string | null;
  format: 'EXCEL' | 'PDF';
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  onSubmit: (
    startDate: string,
    endDate: string | null,
    centerId: string,
    employeeIds: string[],
    format: 'EXCEL' | 'PDF'
  ) => void;
  onClose: () => void;
  showReportFormat?: boolean;
  className?: string;
  isEndDateRequired?: boolean;
}

const TimeframeMultipleEmployeeReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  showReportFormat,
  className,
  isEndDateRequired = true,
  ...props
}) => {
  const { ALL, ANY, TERM } = SEARCH_EXPRESSIONS;
  const { t } = useTranslation();
  const ALL_EMPLOYEE_OPTIONS = { value: 'All', label: `All ${capitalize(t('spelling.employees'))}` };
  const [formData, setFormData] = useState<IFormStateShape>({
    centerId: '',
    selectedEmployees: [],
    startDate: null,
    endDate: null,
    format: 'PDF',
  });
  const centerSelectOptions: ICenter[] = useGetActiveCenters();

  const matchingCenter = centerSelectOptions?.find((center) => center.id === formData?.centerId);

  const entityId = matchingCenter?.entityId ?? '';

  console.log(`Center ID: ${formData?.centerId}, Business ID: ${entityId}`);

  const { loading: isFetchingEmployees, data: employeesData } = useSearchStaffForCenter(formData?.centerId, entityId);
  const employeeSelectOptions = useMemo(
    () => employeesData?.searchStaff.data.map((i) => ({ label: `${i.firstname} ${i.lastname}`, value: i.id })) ?? [],
    [employeesData]
  );

  const isAllOptionSelected = useCallback(
    (): boolean =>
      !!formData.selectedEmployees &&
      formData.selectedEmployees.length > 0 &&
      formData.selectedEmployees.length === employeeSelectOptions.length,
    [formData.selectedEmployees, employeeSelectOptions]
  );

  const getSelectValue = useCallback(() => {
    return isAllOptionSelected()
      ? [ALL_EMPLOYEE_OPTIONS]
      : employeeSelectOptions.filter(
          (opt) => !!formData.selectedEmployees && formData.selectedEmployees.map((i) => i.value).includes(opt.value)
        );
  }, [isAllOptionSelected, employeeSelectOptions, formData.selectedEmployees]);

  useEffect(() => {
    if (!isOpen) {
      setFormData({ centerId: '', selectedEmployees: [], startDate: null, endDate: null, format: 'PDF' });
    }
  }, [isOpen]);

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (formData.startDate && formData.centerId && formData.selectedEmployees) {
      onSubmit(
        formData.startDate,
        formData.endDate,
        formData.centerId,
        formData.selectedEmployees.map((i) => i.value),
        formData.format
      );
    }
  }, [formData, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({ centerId: '', selectedEmployees: [], startDate: null, endDate: null, format: 'PDF' });
    onClose();
  }, [onClose]);

  const handleEmployeeSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_EMPLOYEE_OPTIONS.value) {
        setFormData((prev) => {
          return { ...prev, selectedEmployees: employeeSelectOptions };
        });
      } else if (
        (action === 'deselect-option' && option.value === ALL_EMPLOYEE_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_EMPLOYEE_OPTIONS.value)
      ) {
        setFormData((prev) => {
          return { ...prev, selectedEmployees: [] };
        });
      } else if (actionMeta.action === 'deselect-option' && isAllOptionSelected()) {
        setFormData((prev) => {
          return {
            ...prev,
            selectedEmployees: (formData.selectedEmployees || []).filter((i) => i.value !== option.value),
          };
        });
      } else {
        setFormData((prev) => {
          return { ...prev, selectedEmployees: (options || []).filter((opt) => opt.value !== null) };
        });
      }
    },
    [employeeSelectOptions, formData.selectedEmployees, isAllOptionSelected]
  );

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled:
          !formData.centerId ||
          !formData.selectedEmployees ||
          !formData.selectedEmployees.length ||
          (isEndDateRequired && !formData.endDate) ||
          !formData.startDate,
        loading: isLoading,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      className={className}
    >
      <Row>
        <Col>
          <Select
            required
            id="center-input"
            label={capitalize(t('spelling.center'))}
            aria-label={`Select ${capitalize(t('spelling.center'))}`}
            value={formData.centerId}
            options={centerSelectOptions.map((c) => ({ value: c.id, label: c.name }))}
            onChange={(option: any) => setFormData((prev) => ({ ...prev, centerId: option.value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              {capitalize(t('spelling.employees'))}{' '}
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </Form.Label>
            <BaseSelect
              // @ts-ignore
              isMulti
              required
              id="class-input"
              isLoading={isFetchingEmployees}
              aria-label={`Select ${capitalize(t('spelling.employees'))}`}
              // @ts-ignore
              value={getSelectValue()}
              options={[ALL_EMPLOYEE_OPTIONS, ...employeeSelectOptions]}
              // @ts-ignore
              onChange={(options, action) => handleEmployeeSelect(options as any[], action)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            required
            label={capitalize(t('spelling.start-date'))}
            date={formData.startDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, startDate: date }))}
            className="kt-date-input-no-max-width mb-4"
            isOutsideRange={(date: moment.Moment) =>
              formData.endDate ? date.isSameOrAfter(moment(formData.endDate), 'date') : false
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            required
            label={capitalize(t('spelling.end-date'))}
            date={formData.endDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, endDate: date }))}
            className="kt-date-input-no-max-width  mb-4"
            isOutsideRange={(date: moment.Moment) =>
              formData.startDate ? date.isSameOrBefore(moment(formData.startDate), 'date') : false
            }
          />
        </Col>
      </Row>
      {showReportFormat && (
        <Row>
          <Col>
            <Select
              options={[
                { value: 'EXCEL', label: 'EXCEL' },
                { value: 'PDF', label: 'PDF' },
              ]}
              label={`${capitalize(t('spelling.format'))}(s)`}
              value={formData.format ?? { value: 'PDF', label: 'PDF' }}
              onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
              className="mb-4"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Col>
        </Row>
      )}
    </SideModalDrawer>
  );
};

export default TimeframeMultipleEmployeeReportModal;
