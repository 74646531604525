import React, { useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import NotifiableEventsContainer from '../NotifiableEventsContainer';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { showToast } from 'shared/components/Toast';
import moment from 'moment';
import { useAddProviderNotification } from 'gql/provider/mutation';

interface Props {
  businessId: string;
  providers: IProvider[];
}

interface INotificationOfMattersAffectingApprovalInput {
  date: string;
  text: string;
  providerId: string | null;
}

const NotificationOfMattersAffectingApproval: React.FC<Props> = ({ businessId, providers }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<INotificationOfMattersAffectingApprovalInput>({
    date: '',
    text: '',
    providerId: null,
  });

  useEffect(() => {
    if (providers.length > 0) {
      setFormData((form) => ({
        ...form,
        providerId: providers[0].id,
      }));
    }
  }, [providers]);

  const [setProviderNotification, { loading: addNotificationLoading }] = useAddProviderNotification({
    onCompleted: () => {
      showToast('Successfully submitted notification.', 'success');
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  const validate = useCallback(() => {
    return !Object.values(formData).some((val: string) => val === '') && !!formData.providerId;
  }, [formData]);

  const handleCancel = () => {
    const providerId = providers.length > 0 ? providers[0].id : '';

    setFormData({ date: '', text: '', providerId: providerId });
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    setProviderNotification({
      variables: {
        input: {
          businessId,
          providerId: formData.providerId,
          notificationDate: moment(formData.date).format(),
          notification: formData.text || '',
        },
      },
    });
  };

  return (
    <>
      <NotifiableEventsContainer
        title="Notification of matters affecting approval (Provider level)"
        subTitle={undefined}
        notificationMessage="As soon as practicable"
        onCancel={handleCancel}
        onSave={handleSubmit}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!validate()}
        providerOnChange={(option) => setFormData({ ...formData, providerId: option.value })}
        selectedProvider={formData.providerId}
        isSaving={addNotificationLoading}
        child={
          <>
            <Row>
              <Col className="mb-2">
                <DateInput
                  required
                  label="Date of notification"
                  date={formData.date || null}
                  onDateSelect={(date: string) => setFormData({ ...formData, date: date })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Notification of matters affecting approval"
                  name="text"
                  value={formData.text}
                  onChange={(value: string) => setFormData({ ...formData, text: value })}
                  as="textarea"
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default NotificationOfMattersAffectingApproval;
