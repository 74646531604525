import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useDeletePaymentMethod } from 'gql/paymentMethod/mutations';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { removeAccountPaymentMethod } from 'pages/Families/subroutes/Accounts/duck/actions';
import { deletePaymentMethodForBusiness } from 'pages/Businesses/subroutes/BusinessProfiles/duck/actions';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IProps {
  isOpen: boolean;
  paymentMethod: IPaymentMethod;
  account?: IAccount;
  onClose: () => void;
}

const DeletePaymentMethodModal: React.FC<IProps> = ({ isOpen, paymentMethod, account, onClose, ...props }) => {
  const dispatch = useDispatch();
  const [deletePaymentMethodFn, { loading: deletePaymentMethodLoading }] = useDeletePaymentMethod({
    onCompleted: (result) => {
      if (paymentMethod.accountId || account) {
        dispatch(removeAccountPaymentMethod((paymentMethod.accountId as string) ?? account?.id, paymentMethod.id));
      } else {
        dispatch(deletePaymentMethodForBusiness(paymentMethod.businessId, paymentMethod.id));
      }
      showToast('Removed payment method successfully.', 'success');
      onClose();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const handleConfirm = useCallback(() => {
    deletePaymentMethodFn({
      variables: {
        id: paymentMethod.id,
        accountId: account?.id ?? '',
      },
    });
  }, [paymentMethod, deletePaymentMethodFn, account]);

  return (
    <ConfirmationModal
      show={isOpen}
      title="Delete Payment Method?"
      primaryButtonProps={{
        loading: deletePaymentMethodLoading,
        disabled: deletePaymentMethodLoading,
        variant: 'danger',
      }}
      primaryCallback={handleConfirm}
      primaryChoice="Delete"
      secondaryChoice="Cancel"
      onHide={onClose}
      hideOnCallback={false}
    >
      Are you sure you would like to delete this payment method?
    </ConfirmationModal>
  );
};

export default DeletePaymentMethodModal;
