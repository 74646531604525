import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
interface IProps {
  data: IISCase;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const ISCaseRawBasicColumn: React.FC<IProps> = ({ data }) => {
  const {
    caseId,
    centerName,
    startDate,
    endDate,
    status,
    type,
    agencyName,
    agencyRegion,
    professionalFirstName,
    professionalLastName,
    serviceType,
    careProvision,
    careEnvironmentName,
  } = data;

  return (
    <Col lg={4} xl={4} md={12} sm={12}>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Case ID:{' '}
        </Col>
        <Col md={5}>{caseId}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          {' '}
          {fieldLabels.center} Name:{' '}
        </Col>
        <Col md={5}>{centerName}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Status:{' '}
        </Col>
        <Col md={5}>{status}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Type:{' '}
        </Col>
        <Col md={5}>{type}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Care Provision:{' '}
        </Col>
        <Col md={5}>{careProvision}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Care Environment Name:{' '}
        </Col>
        <Col md={5}>{careEnvironmentName}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Service Type:{' '}
        </Col>
        <Col md={5}>{serviceType}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Start Date:{' '}
        </Col>
        <Col md={5}>{moment(startDate).format('DD/MM/YYYY')}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          End Date:{' '}
        </Col>
        <Col md={5}>{moment(endDate).format('DD/MM/YYYY')}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Professional Firstname{' '}
        </Col>
        <Col md={5}>{professionalFirstName}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Professional Lastname{' '}
        </Col>
        <Col md={5}>{professionalLastName}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Agency Region{' '}
        </Col>
        <Col md={5}>{agencyRegion}</Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Agency Name:{' '}
        </Col>
        <Col md={5}>{agencyName}</Col>
      </Row>
    </Col>
  );
};

export default ISCaseRawBasicColumn;
