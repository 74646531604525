import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import {
  faBell,
  faTimes,
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
} from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import { IconButton, IconButtonCircle, ButtonAsLink } from 'shared/components/Buttons';
import './notificationsDropdownV2.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown, { Menu } from 'shared/components/Dropdown';
import cast from 'shared/util/cast';
import { Row, Col } from 'shared/components/Layout';
import { Container } from 'react-bootstrap';
import { HorizontalDivider } from 'shared/components/Dividers';
import { lowerCase, without, capitalize } from 'lodash';
import moment from 'moment';
import { useGetNotificationsV2 } from 'gql/notificationsV2/queries';
import { useMarkNotificationsTogetherV2 } from 'gql/notificationsV2/mutations';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import customEvents from 'shared/constants/customEvents';

const syncNotificationMainScreen = new Event(customEvents.REFETCH_NOTIFICATION_V2_MAIN_SCREEN);

interface INotificationsProps {
  data: INotificationV2[];
  handleClose: () => void;
  handleMarkNotificationsAsRead: (notificationId: string[]) => void;
}

const Icons = {
  information: <FontAwesomeIcon icon={faInfoCircle} color="#52A7CC" size="lg" />,
  warning: <FontAwesomeIcon icon={faExclamationTriangle} color="#DEA81C" size="lg" />,
  error: <FontAwesomeIcon icon={faExclamationCircle} color="#de1c1c" size="lg" />,
};

const Notifications = ({ data, handleClose, handleMarkNotificationsAsRead }: INotificationsProps) => {
  const todayNotifications = useMemo(
    () => data.filter((notification) => moment(notification.dateTime).isSame(moment(), 'day')),
    [data]
  );

  const previousNotifications = useMemo(() => without(data, ...todayNotifications), [data, todayNotifications]);

  const renderNotificationsByDate = useCallback(
    (
      data: INotificationV2[],
      name: string,
      handleClose: () => void,
      handleMarkNotificationsAsRead: (notificationId: string[]) => void
    ) => {
      const handleNotificationClick = (notificationId: string) => {
        handleMarkNotificationsAsRead([notificationId]);
        handleClose();
      };
      return (
        <>
          <Row className="w-100 m-0">
            <Col className="k2-notification-v2-main--title m-0">{name}</Col>
          </Row>
          {data.map((notification) => (
            <React.Fragment key={notification.id}>
              <Row noGutters align="start" className="py-1 w-100">
                <Col xs={2} className="text-center unread">
                  {Icons[lowerCase(notification.level) as keyof typeof Icons]}
                </Col>
                <Col xs={10} className="font-size-12">
                  <Link
                    to={`/notifications/v2`}
                    onClick={() => handleNotificationClick(notification.id)}
                    className="k2-notification-v2-main--link"
                  >
                    {notification.description}
                  </Link>
                  <div>
                    <span className={`badge badge-${lowerCase(notification.level)} mr-2`}>
                      {capitalize(notification.level)}
                    </span>
                    {moment(notification.dateTime).fromNow()}
                  </div>
                </Col>
              </Row>
              <Row noGutters className="w-100">
                <Col xs={12} className="mx-2">
                  <HorizontalDivider my={1} />
                </Col>
              </Row>
            </React.Fragment>
          ))}
        </>
      );
    },
    []
  );

  return (
    <Container fluid className="k2-notification-v2-main p-0">
      {todayNotifications.length > 0
        ? renderNotificationsByDate(todayNotifications, 'TODAY', handleClose, handleMarkNotificationsAsRead)
        : null}
      {previousNotifications.length > 0
        ? renderNotificationsByDate(previousNotifications, 'PREVIOUS', handleClose, handleMarkNotificationsAsRead)
        : null}
      {data.length === 0 && <div className="k2-notification-v2-main--message">No new notifications</div>}
    </Container>
  );
};

const NotificationsDropdownV2: React.FC = () => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();
  const { data, refetch } = useGetNotificationsV2(
    {
      read: false,
      pageNumber: 1,
      pageSize: 20,
    },
    {
      // because current notification v2 have only proda-api may send notification to client (PRODA Device Expired, PRODA Device Expiring)
      // so set pollInterval to 15 minutes for now to reduce the number of request to server
      pollInterval: 1000 * 60 * 15,
    }
  );

  const [markNotificationInDropdownV2fn] = useMarkNotificationsTogetherV2();

  const handleMarkNotificationsInDropdownAsRead = useCallback(
    (notificationIds: Array<string>) => {
      markNotificationInDropdownV2fn({
        variables: {
          notificationIds,
          read: true,
        },
      })
        .then(() => {
          // refetch mini-notifications screen data
          refetch();
          // trigger a refetch main screen data if main screen is open
          document.dispatchEvent(syncNotificationMainScreen);
        })
        .catch((error) => showToast(error.message, 'error'));
    },
    [markNotificationInDropdownV2fn]
  );

  const notifications = data?.notifications.data || [];
  const unreadNotificationsCount = data?.notifications.totalRecords || 0;

  const wrapperRef = useRef(null);

  const handleClose = () => {
    setDropdownOpen(false);
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const handleOpen = () => {
    setDropdownOpen(true);
    document.addEventListener('mousedown', handleClickOutside);
  };

  const handleClickOutside = (e: any) => {
    if (wrapperRef.current && !cast<any>(wrapperRef.current).contains(e.target)) {
      handleClose();
    }
  };

  const handleRefetch = useCallback(() => refetch(), [refetch]);

  useEffect(() => {
    // to provide a mechanism to refresh data that can be triggered by another components (e.g. main notification screen)
    // a user story is when mark a notification as unread in the main screen , this function should be called to refresh data (because poolInterval is set to 15 minutes atm)
    document.addEventListener(customEvents.REFETCH_NOTIFICATION_V2_MINI_SCREEN, handleRefetch);

    return () => {
      document.removeEventListener(customEvents.REFETCH_NOTIFICATION_V2_MINI_SCREEN, handleRefetch);
    };
  }, [handleRefetch]);

  return (
    <div ref={wrapperRef}>
      <Dropdown as="div" className="k2-notificationsV2" showCarotIcon={false} show={dropdownOpen}>
        <IconButton
          onClick={handleOpen}
          className={unreadNotificationsCount > 0 ? 'notification-dot' : ''}
          icon={faBell}
        />
        <Menu className="notifications-dropdown">
          <header>
            <div className="title">
              {t('notification-v2.title')}
              <IconButtonCircle onClick={handleClose} icon={faTimes} size="xs" />
            </div>
            {unreadNotificationsCount > 0 && (
              <div className="action">
                <ButtonAsLink
                  className="btn-mark-all-read"
                  onClick={() =>
                    handleMarkNotificationsInDropdownAsRead(notifications.map((n: INotificationV2) => n.id))
                  }
                >
                  {t('notification-v2.buttons.mark-number-as-read', { count: notifications.length })}
                </ButtonAsLink>
              </div>
            )}
          </header>
          <main>
            <Notifications
              data={notifications}
              handleClose={handleClose}
              handleMarkNotificationsAsRead={handleMarkNotificationsInDropdownAsRead}
            />
          </main>
          <footer>
            <ButtonAsLink
              className="btn-view-all"
              onClick={() => {
                handleClose();
                history.push('/notifications/v2');
              }}
            >
              {t('notification-v2.buttons.view-all')}
            </ButtonAsLink>
          </footer>
        </Menu>
      </Dropdown>
    </div>
  );
};

export default NotificationsDropdownV2;
