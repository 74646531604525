import React, { useCallback, useMemo, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import moment from 'moment';
import DateInput from 'shared/components/DateInput';
import { useGetClassesForCenter } from 'gql/class/queries';
import { capitalize } from 'shared/util/string';
import { showToast } from 'shared/components/Toast';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import MultipleProgramSelect from 'shared/components/Select/MultipleProgramSelect';
import { useGetQkfsReportLazy } from 'gql/reports/queries';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { SubsidySchemeDto } from 'generated/graphql';

type Props = {
  centerId: string;
  businessId: string;
  subsidySchemes: SubsidySchemeDto[];
  isOpen: boolean;
  refetchPrograms: () => void;
  onClose: () => void;
};

interface IForm {
  subsidyId: string;
  programFromDate: string;
  programToDate: string;
  centerIds: string[];
  programIds: string[];
  groupBy?: 'program' | 'child';
}

const initialState: IForm = {
  subsidyId: '',
  programFromDate: '',
  programToDate: '',
  centerIds: [],
  programIds: [],
  groupBy: undefined,
};

export default function RunProgramReportModal({
  isOpen,
  onClose,
  centerId,
  businessId,
  subsidySchemes,
  refetchPrograms,
  ...props
}: Props) {
  const { t } = useTranslation(['subsidies', 'translation']);
  const [formData, setFormData] = useState<IForm>(initialState);
  const reportDataToFile = useReportDataToFile();

  const [getQkfsReportFn, { loading }] = useGetQkfsReportLazy({
    onCompleted: (result) => {
      console.log(result);
      reportDataToFile.downloadCsvFromString(result.getQkfsReport, ReportTypeEnum.PROGRAMS);
      onClose();
    },
    onError: (error) => {
      // @ts-ignore
      showToast(t('translation:reports.general-failure-message'), 'error');
    },
  });

  const { data, loading: classesLoading } = useGetClassesForCenter({
    variables: { centerId: centerId },
    fetchPolicy: 'network-only',
  });

  const subsidySchemesOptions = useMemo<{ label: string; value: string }[]>(() => {
    return subsidySchemes.map((subsidyScheme) => ({
      value: subsidyScheme.subsidySchemeId,
      label: subsidyScheme.name,
    }));
  }, [subsidySchemes]);

  const handleChangeCallback = useCallback(
    (componentName: string, value: any) => {
      setFormData((prevState) => {
        return { ...prevState, [componentName]: value };
      });
    },
    [formData]
  );

  const isValidFormData = useMemo(
    () =>
      !!formData.programIds &&
      formData.programIds.length > 0 &&
      !!formData.programFromDate &&
      !!formData.programToDate &&
      !!formData.groupBy,
    [formData]
  );

  const handleSubmit = useCallback(() => {
    if (formData.programFromDate && formData.programToDate) {
      getQkfsReportFn({
        variables: {
          input: {
            businessId: businessId ?? '',
            programIds: [...formData.programIds],
            isConsolidated: formData.groupBy === 'program',
            startDate: moment(formData.programFromDate).format('YYYY-MM-DD'),
            endDate: moment(formData.programToDate).format('YYYY-MM-DD'),
          },
        },
      });
    }
  }, [getQkfsReportFn, formData]);

  return (
    <SideModalDrawer
      title={t('subsidies:run-report')}
      show={isOpen}
      primaryChoice={capitalize(t('translation:spelling.create'))}
      primaryCallback={handleSubmit}
      primaryButtonProps={{ disabled: !isValidFormData, loading }}
      onHide={() => {
        setFormData(initialState);
        if (onClose) onClose();
      }}
      closeOnPrimaryCallback={false}
    >
      <Form>
        <Row className="mb-4">
          <Col>
            <Select
              required
              label={t('subsidies:subsidy-name')}
              options={subsidySchemesOptions}
              onChange={(o) => {
                handleChangeCallback('subsidyId', o.value);
              }}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <MultipleCenterSelect
              selectedCenterIds={formData.centerIds}
              onSelect={(ids) => setFormData((prev) => ({ ...prev, centerIds: ids as string[] }))}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <MultipleProgramSelect
              subsidySchemeId={formData.subsidyId}
              centerIds={formData.centerIds}
              businessId={businessId}
              selectedProgramIds={formData.programIds}
              onSelect={(ids) => setFormData((prev) => ({ ...prev, programIds: ids as string[] }))}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DateInput
              required
              label={t('subsidies:program.from-date')}
              date={formData['programFromDate']}
              onDateSelect={(input) =>
                setFormData((prev) => ({
                  ...prev,
                  programFromDate: !!input ? moment(input).format('YYYY-MM-DD') : input,
                }))
              }
              className="kt-date-input-no-max-width mb-4"
              dateOnly
            />
          </Col>
          <Col>
            <DateInput
              required
              label={t('subsidies:program.to-date')}
              date={formData['programToDate']}
              onDateSelect={(input) =>
                setFormData((prev) => ({
                  ...prev,
                  programToDate: !!input ? moment(input).format('YYYY-MM-DD') : input,
                }))
              }
              className="kt-date-input-no-max-width mb-4"
              dateOnly
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="form-label mb-4">{t('subsidies:program.group-by')}</label>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <input
              type="radio"
              id="group-by-program"
              checked={formData.groupBy === 'program'}
              onClick={() => setFormData({ ...formData, groupBy: 'program' })}
            />
            <label htmlFor="group-by-program">{capitalize(t('translation:spelling.program'))}</label>
          </Col>
          <Col>
            <input
              type="radio"
              id="group-by-child"
              checked={formData.groupBy === 'child'}
              onClick={() => setFormData({ ...formData, groupBy: 'child' })}
            />
            <label htmlFor="group-by-child">{capitalize(t('translation:spelling.child'))}</label>
          </Col>
        </Row>
      </Form>
    </SideModalDrawer>
  );
}
