import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import Table from 'react-bootstrap/Table';
import Button from 'shared/components/Buttons';
import TimeSheetHeader from './TimeSheetHeader';
import EditTimeEntriesForDay from './EditTimeEntriesForDay';
import colors from '_colors.module.scss';
import ActualTimeBlock from './ActualTimeBlock';
import ScheduledTimeBlock from './ScheduledTimeBlock';
import { checkForFutureTimeEntries, checkForOverlappingTimeEntries } from '../../utils/timeEntryValidation';

const getTimeEntriesById = (entries: ITimeEntry[]): ITimeEntriesById => {
  return entries.reduce<ITimeEntriesById>((acc: ITimeEntriesById, curr: ITimeEntry) => {
    acc[curr.id] = curr;

    return acc;
  }, {});
};

interface IProps {
  dailyAttendance: IDailyAttendance;
  canPerformEdit?: boolean;
  savingTimeEntryUpdates?: boolean;
  positionOptions?: IStaffPosition[];
  saveTimeEntryUpdates?: (updates: ITimeEntriesById) => Promise<void>;
}

export interface ITimeEntriesById {
  [timeEntryId: string]: ITimeEntry;
}

const TimeSheetDailyAttendance: React.FC<IProps> = ({
  dailyAttendance,
  canPerformEdit = false,
  savingTimeEntryUpdates = false,
  positionOptions = [],
  saveTimeEntryUpdates,
  ...props
}) => {
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const timezone = timezonesByCenter[dailyAttendance.centerId] ?? momentTz.tz.guess();

  const [showEditPanel, setShowEditPanel] = useState(false);
  const [timeEntriesById, setTimeEntriesById] = useState<ITimeEntriesById>(
    getTimeEntriesById(dailyAttendance.timeEntries)
  );
  const positionById = useMemo(() => {
    return positionOptions.reduce<Record<string, string>>((acc, curr) => {
      if (curr.id) {
        acc[curr.id] = curr.positionName;
      }
      return acc;
    }, {});
  }, [positionOptions]);

  // reset state when the component is hidden and no longer visible
  useEffect(() => {
    if (!showEditPanel) {
      setTimeEntriesById(getTimeEntriesById(dailyAttendance.timeEntries));
    }
  }, [dailyAttendance, showEditPanel]);

  const getMinutesScheduled = useCallback((dailyAttendance: IDailyAttendance): number => {
    return dailyAttendance.shifts.reduce((acc, curr) => {
      const shiftLengthInMinutes = moment(curr.endTime).diff(curr.startTime, 'minutes');

      return (acc += shiftLengthInMinutes);
    }, 0);
  }, []);

  const getMinutesWorked = useCallback((dailyAttendance: IDailyAttendance): number => {
    return dailyAttendance.timeEntries.reduce((acc, curr) => {
      if (curr.type !== 'BREAK') {
        const endTime = curr.trackedTimeOut ? moment(curr.trackedTimeOut) : moment();
        const timeEntryLengthInMinutes = endTime.diff(curr.trackedTimeIn, 'minutes');

        return (acc += timeEntryLengthInMinutes);
      }

      return acc;
    }, 0);
  }, []);

  const getScheduledWorkedMinutesDifference = useCallback(
    (dailyAttendance: IDailyAttendance): number => {
      const minutesScheduled = getMinutesScheduled(dailyAttendance);
      const minutesWorked = getMinutesWorked(dailyAttendance);
      return minutesWorked - minutesScheduled;
    },
    [getMinutesScheduled, getMinutesWorked]
  );

  const getTotalDifferenceString = useCallback((dailyAttendance: IDailyAttendance): string => {
    let str = '';

    const secondsScheduled =
      dailyAttendance.totalHoursScheduled.hours * 3600 + dailyAttendance.totalHoursScheduled.minutes * 60;
    const secondsWorked = dailyAttendance.totalHoursWorked.hours * 3600 + dailyAttendance.totalHoursWorked.minutes * 60;

    // 3600 -> 60 seconds * 60 minutes = 1 hours == 3600 seconds
    if (Math.abs(secondsScheduled - secondsWorked) >= 3600) {
      const hours = Math.floor(Math.abs((secondsScheduled - secondsWorked) / 3600));
      const minutes = Math.abs(((secondsScheduled - secondsWorked) % 3600) / 60);

      str = `${(hours + minutes / 60).toFixed(2)} hrs`;
    } else {
      const minutes = Math.abs(((secondsScheduled - secondsWorked) % 3600) / 60);

      str = `${minutes} min`;
    }

    return `${secondsWorked > secondsScheduled ? '+' : '-'}${str}`;
  }, []);

  const validateUpdates = useCallback(
    (updates: ITimeEntriesById): boolean => {
      let isValid: boolean = true;
      const entries: ITimeEntry[] = Object.values(updates);

      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];

        if (!checkForFutureTimeEntries(entry, timezone)) {
          isValid = false;
          // if this comes back invalid there is no reason to continue to iterate over additional items
          break;
        }

        if (!checkForOverlappingTimeEntries(entries, entry, timezone)) {
          isValid = false;
          // if this comes back invalid there is no reason to continue to iterate over additional items
          break;
        }
      }

      return isValid;
    },
    [timezone]
  );

  return (
    <div className="kt-time-sheets-time-entry-day-container mb-2">
      <Table responsive className="kt-time-sheets-time-entry-day-table">
        <colgroup>
          <col style={{ width: 180 }} />
          <col style={{ width: 180 }} />
          <col style={{ width: showEditPanel ? 600 : 500 }} />
          <col style={{ width: 100 }} />
        </colgroup>
        <TimeSheetHeader />
        <tbody>
          <tr>
            {/* date */}
            <td>
              <div className="d-flex flex-column justify-content-center">
                {/* NOTE: we aren't using momentTz here since the daily attendance's date is returned as YYYY-MM-DD */}
                <div className="kt-time-sheets-time-entry-day-text-primary">
                  {dailyAttendance.timeEntries.length > 0 &&
                  !!dailyAttendance.timeEntries[dailyAttendance.timeEntries.length - 1].timeIn ? (
                    <>
                      {momentTz(dailyAttendance.timeEntries[0].timeIn).tz(timezone).format('MMM').toUpperCase()}{' '}
                      {moment(dailyAttendance.timeEntries[0].timeIn).tz(timezone).format('D, ddd')}
                    </>
                  ) : (
                    <>
                      {moment(dailyAttendance.date).format('MMM').toUpperCase()}{' '}
                      {moment(dailyAttendance.date).format('D, ddd')}
                    </>
                  )}
                </div>
                <div className="kt-time-sheets-time-entry-day-text-secondary mb-2">
                  {moment(dailyAttendance.date).format('YYYY')}
                </div>
                {/* add logic for this */}
                {dailyAttendance.timeEntries.length > 0 &&
                  !dailyAttendance.timeEntries[dailyAttendance.timeEntries.length - 1].trackedTimeOut &&
                  !momentTz(dailyAttendance.timeEntries[dailyAttendance.timeEntries.length - 1].trackedTimeIn)
                    .tz(timezone)
                    .isSame(momentTz().tz(timezone), 'date') && (
                    <div>
                      <FontAwesomeIcon icon={faExclamationTriangle} size="lg" color={colors.danger} />
                      <small className="ml-2 text-danger">Missing Timestamp</small>
                    </div>
                  )}
              </div>
            </td>
            {/* scheduled time */}
            <td>
              <ScheduledTimeBlock dailyAttendance={dailyAttendance} dailyAttendanceTimezone={timezone} />
            </td>
            {/* actual time */}
            <td style={{ maxWidth: 'unset', width: 'auto' }}>
              {showEditPanel && Object.keys(timeEntriesById).length > 0 ? (
                <div className="kt-time-sheets edit-time-entries-for-day__container">
                  <div className="edit-time-entries-for-day__items">
                    <EditTimeEntriesForDay
                      timeEntriesById={timeEntriesById}
                      updateTimeEntry={setTimeEntriesById}
                      positionOptions={positionOptions}
                      dailyAttendanceTimezone={timezone}
                    />
                  </div>
                  <div className="edit-time-entries-for-day__control">
                    <div className="edit-time-entries-for-day__control__buttons">
                      <Button className="cancel-btn" onClick={() => setShowEditPanel(false)} variant="light">
                        Cancel
                      </Button>
                      <Button
                        disabled={savingTimeEntryUpdates || !dailyAttendance.timeEntries.length}
                        loading={savingTimeEntryUpdates}
                        onClick={() =>
                          saveTimeEntryUpdates &&
                          saveTimeEntryUpdates(timeEntriesById).then(() => setShowEditPanel(false))
                        }
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <ActualTimeBlock
                  dailyAttendance={dailyAttendance}
                  positionById={positionById}
                  canPerformEdit={canPerformEdit}
                  onEditButtonClick={() => setShowEditPanel(true)}
                  dailyAttendanceTimezone={timezone}
                />
              )}
            </td>
            {/* difference */}
            <td>
              <div
                className={classnames({
                  'kt-time-sheets-time-entry-day-text-primary': true,
                  warning: getScheduledWorkedMinutesDifference(dailyAttendance) > 0,
                })}
              >
                {getTotalDifferenceString(dailyAttendance)}
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TimeSheetDailyAttendance;
