import { ITimelogTableFilters } from './reducers';

export const GET_TRAINING_TIME_EVENTS = 'GET_TRAINING_TIME_EVENTS';
export const CREATE_TRAINING_TIME_EVENT = 'CREATE_TRAINING_TIME_EVENT';
export const UPDATE_TIME_LOG_FILTER = 'UPDATE_TIME_LOG_FILTER';
export const UPDATE_TRAINING_TIME_EVENT = 'UPDATE_TRAINING_TIME_EVENT';
export const DELETE_TRAINING_TIME_EVENT = 'DELETE_TRAINING_TIME_EVENT';
export const SET_CURRENT_EMPLOYEE_TIMECARDS = 'SET_CURRENT_EMPLOYEE_TIMECARDS';
export const REMOVE_TRAINING_TIME_FROM_EMPLOYEE_TIMECARD = 'REMOVE_TRAINING_TIME_FROM_EMPLOYEE_TIMECARD';

export interface IGetTrainingTimeEvents {
  type: typeof GET_TRAINING_TIME_EVENTS;
  timeEvents: ITrainingHolidayEvent[];
}

export interface ICreateTrainingTimeEvent {
  type: typeof CREATE_TRAINING_TIME_EVENT;
  timeEvent: ITrainingHolidayEvent;
}

export interface IUpdateTrainingTimeEvent {
  type: typeof UPDATE_TRAINING_TIME_EVENT;
  timeEvent: ITrainingHolidayEvent;
}

export interface IDeleteTrainingTimeEvent {
  type: typeof DELETE_TRAINING_TIME_EVENT;
  timeEventId: string;
}

export interface IUpdateTimelogsTableFilters {
  type: typeof UPDATE_TIME_LOG_FILTER;
  filters: Partial<ITimelogTableFilters>;
}

export interface ISetCurrentEmployeeTimecards {
  type: typeof SET_CURRENT_EMPLOYEE_TIMECARDS;
  timecards: IAttendance[];
}

export interface IRemoveTrainingTimeFromEmployeeTimecard {
  type: typeof REMOVE_TRAINING_TIME_FROM_EMPLOYEE_TIMECARD;
  employeeId: string;
  trainingTimeEventId: string;
}

export type TimelogActionTypes =
  | IUpdateTimelogsTableFilters
  | ICreateTrainingTimeEvent
  | IGetTrainingTimeEvents
  | IUpdateTrainingTimeEvent
  | IDeleteTrainingTimeEvent
  | ISetCurrentEmployeeTimecards
  | IRemoveTrainingTimeFromEmployeeTimecard;
