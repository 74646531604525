import React, { useState, useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import Collapse from 'react-bootstrap/Collapse';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  selectedStaff: IStaff[];
  staffAreRequests?: boolean;
  deleteSelectedStaff: () => void;
  onClose: () => void;
}
const DeleteStaffModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  onClose,
  selectedStaff,
  deleteSelectedStaff,
  staffAreRequests = false,
  ...props
}) => {
  const singleUser: boolean = selectedStaff.length === 1;
  const [showSecondaryWarning, setShowSecondaryWarning] = useState<boolean>(false);
  const revealSecondaryWarning = useCallback(() => setShowSecondaryWarning(true), []);

  const resetModalState = useCallback(() => {
    // make sure the secondary warning is hidden if visible
    if (showSecondaryWarning) {
      setShowSecondaryWarning(false);
    }
  }, [showSecondaryWarning]);

  const dimissModal = useCallback(() => {
    resetModalState();

    onClose && onClose();
  }, [resetModalState, onClose]);

  return (
    <ConfirmationModal
      title={`Delete ${staffAreRequests ? 'Request' : 'User'}${singleUser ? '' : 's'}?`}
      show={isOpen}
      onHide={dimissModal}
      hideOnCallback={false}
      primaryChoice={showSecondaryWarning ? 'Confirm' : 'Delete'}
      primaryCallback={showSecondaryWarning ? () => deleteSelectedStaff() : revealSecondaryWarning}
      primaryButtonProps={{
        variant: 'danger',
        loading: isLoading,
        disabled: selectedStaff.length === 0,
      }}
      secondaryCallback={dimissModal}
      onExited={resetModalState}
    >
      <span className="d-block mb-4">
        Are you sure you want to delete{' '}
        {singleUser
          ? `this ${staffAreRequests ? 'request' : 'user'}?`
          : `the following ${staffAreRequests ? 'requests' : 'users'}?`}
      </span>
      <ul>
        {selectedStaff.map((staff: IStaff) => (
          <li key={`delete-staff-email-${staff.id}`}>{staff.email}</li>
        ))}
      </ul>
      <Collapse in={showSecondaryWarning}>
        <div className="mt-4">
          Are you postive that you want to delete{' '}
          {singleUser
            ? `this ${staffAreRequests ? 'request' : 'user'}?`
            : `these ${staffAreRequests ? 'requests' : 'users'} ? `}
        </div>
      </Collapse>
    </ConfirmationModal>
  );
};

export default DeleteStaffModal;
