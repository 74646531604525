import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import DateInput from 'shared/components/DateInput';
import { FileUploadDropbox } from 'shared/components/FileOperations';
import FormLabel from 'shared/components/Form/FormLabel';
import { Col, Row } from 'shared/components/Layout';
import useK2Upload from 'shared/hooks/useK2Upload';
import { emptyK2File, fileToK2Upload } from 'shared/util/file';
import ImportInstruction from '../ImportInstruction';
import UploadButton from '../UploadButton';

interface IProps extends IImportStepProps {
  importContracts: (input: IQikKidsBookingPatternsVariables) => void;
}

const ContractsImport: React.FC<IProps> = ({ importContracts, center, loading, shouldReset, completed = false }) => {
  const [isDryRun, setDryRun] = useState(true);
  const [startDate, setStartDate] = useState(moment().startOf('isoWeek').add(1, 'week').format('YYYY-MM-DD'));

  const { t } = useTranslation('ktAdmin');
  const { files: careTypeFiles, setFiles: setCareTypeFiles, k2File: careTypeK2File } = useK2Upload(shouldReset);

  const { files: bookingFiles, setFiles: setBookingFiles, k2File: bookingK2File } = useK2Upload(shouldReset);

  const handleImport = () => {
    importContracts({
      businessId: center?.entityId || '',
      centerId: center?.id || '',
      careTypes: careTypeK2File || emptyK2File,
      bookingPatterns: bookingK2File || emptyK2File,
      startDate: startDate,
      dryRun: isDryRun,
    });
  };

  const selectedFiles = bookingFiles.length + careTypeFiles.length;
  return (
    <Card
      header={t('data-importer.step-number', {
        number: 3,
        title: t('data-importer.contracts'),
      })}
    >
      <ImportInstruction isDryRun={isDryRun} setDryRun={setDryRun} />

      <DateInput
        required
        label={t('data-importer.contracts-start-date')}
        date={startDate}
        dateOnly
        onDateSelect={(date) => setStartDate(date)}
      />

      <Row>
        <Col>
          <FormLabel required>{t('data-importer.care-type')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setCareTypeFiles}
            uploadedFiles={careTypeFiles}
            setUploadedFiles={setCareTypeFiles}
            withProgress={false}
          />
        </Col>
        <Col>
          <FormLabel required>{t('data-importer.booking-patterns')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setBookingFiles}
            uploadedFiles={bookingFiles}
            setUploadedFiles={setBookingFiles}
            withProgress={false}
          />
        </Col>
      </Row>
      <UploadButton
        onClick={handleImport}
        selectedFiles={selectedFiles}
        totalFiles={2}
        disabled={selectedFiles < 2}
        completed={completed}
        loading={loading}
      />
    </Card>
  );
};

export default ContractsImport;
