import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { trainingTimeEventFields } from './fields';

interface IGetPaginatedTrainingTimeEventsForCenterData {
  getPaginatedTrainingTimeEventsForCenter: IPaginatedTrainingTimeEventsResult | null;
}

interface IGetPaginatedTrainingTimeEventsForCenterVariables {
  input: {
    centerId: string;
    startDate: string;
    endDate: string;
    searchKey: string;
    sortBy: string;
    sortDirection: string;
    pageNumber: number;
    pageSize: number;
  };
}

export const GET_TRAINING_TIME_EVENTS_FOR_CENTER = (fields: string = trainingTimeEventFields) => gql`
  query($input: GetPaginatedTrainingTimeEventsForCenterInput!) {
    getPaginatedTrainingTimeEventsForCenter(input: $input) {
      data {
        ${fields}
      }
      pageNumber
      pageSize
      totalRecords
    }
  }
`;

export const useGetPaginatedTrainingTimeEventsForCenter = (
  options?: QueryHookOptions<
    IGetPaginatedTrainingTimeEventsForCenterData,
    IGetPaginatedTrainingTimeEventsForCenterVariables
  >,
  fields?: string
) =>
  useQuery<IGetPaginatedTrainingTimeEventsForCenterData, IGetPaginatedTrainingTimeEventsForCenterVariables>(
    GET_TRAINING_TIME_EVENTS_FOR_CENTER(fields),
    options
  );
