import React from 'react';
import momentTz from 'moment-timezone';

interface IProps {
  dailyAttendance: IDailyAttendance;
  dailyAttendanceTimezone: string;
}

const ScheduledTimeBlock: React.FC<IProps> = ({ dailyAttendance, dailyAttendanceTimezone, ...props }) => {
  const requiresFillerDivs: boolean = dailyAttendance.shifts.length < dailyAttendance.timeEntries.length;
  const numberOfFillerDivs: number = requiresFillerDivs
    ? dailyAttendance.timeEntries.length - dailyAttendance.shifts.length
    : 0;

  return (
    <div className="d-flex flex-row align-items-center">
      <div className="d-flex flex-column flex-grow-1">
        <div className="flex-grow-1 kt-time-sheets-time-entry-day-text-secondary">In</div>
        {dailyAttendance.shifts.map((shift: IShift, idx: number) => (
          <div
            key={`scheduled-in-${shift.id}-${idx}`}
            className="flex-grow-1 kt-time-sheets-time-entry-day-text-regular mb-2"
          >
            {momentTz(shift.startTime).tz(dailyAttendanceTimezone).format('h:mm A')}
          </div>
        ))}
        {Array.from(Array(numberOfFillerDivs).keys()).map((num: number, idx: number) => (
          <div
            key={`scheduled-in-filler-${num}-${idx}`}
            className="flex-grow-1 kt-time-sheets-time-entry-day-text-regular mb-2"
          >
            &nbsp;
          </div>
        ))}
      </div>
      <div className="d-flex flex-column flex-grow-1">
        <div className="flex-grow-1 kt-time-sheets-time-entry-day-text-secondary">Out</div>
        {dailyAttendance.shifts.map((shift: IShift, idx: number) => (
          <div
            key={`scheduled-out-${shift.id}-${idx}`}
            className="flex-grow-1 kt-time-sheets-time-entry-day-text-regular mb-2"
          >
            {momentTz(shift.endTime).tz(dailyAttendanceTimezone).format('h:mm A')}
          </div>
        ))}
        {Array.from(Array(numberOfFillerDivs).keys()).map((num: number, idx: number) => (
          <div
            key={`scheduled-out-filler-${num}-${idx}`}
            className="flex-grow-1 kt-time-sheets-time-entry-day-text-regular mb-2"
          >
            &nbsp;
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduledTimeBlock;
