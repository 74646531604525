import User from '../../shared/types/user';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

interface IGetUserAction {
  type: typeof GET_USER_SUCCESS;
  user: User;
}

export type UserActionTypes = IGetUserAction;
