export const enrollmentProgramCenterFields = `
  id
  programId
  centerId
  classId
  feeId
  centerName
  className
  feeName
  applicationFeeType
  applicationFeeAmount
  enrollmentOptions
  casualFeeId
  casualFeeName
`;

export const enrollmentProgramFields = `
  id
  businessId
  name
  isOpenForEnrollment
  startDate
  endDate
  enrolmentOpenAt
  enrolmentCloseAt
  enrolmentOpenAt
  enrolmentCloseAt 
  minEnrolmentDays
  maxEnrolmentDays
  operatingDays
  description
  includeCasualContractCount
  programGroupId
  programCenters {
    ${enrollmentProgramCenterFields}
  }
  programGroup {
    id
    name
  }
`;

export const pagedEnrollmentProgramFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${enrollmentProgramFields}
  }
`;

export const EnrollmentAvailabilityByDayOfWeekFields = `
    dayOfWeek
    openSpotsAm
    openSpotsPm
    totalOpenSpots
 `;

export const ClassEnrollmentAvailabilityFields = `
  classId
  availabilityByDayOfWeek{
    ${EnrollmentAvailabilityByDayOfWeekFields}
  }
  openSpots
`;
