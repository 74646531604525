import React from 'react';
import RBAlert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationCircle, faCheck, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

interface IProps {
  variant?: 'info' | 'warning' | 'success' | 'danger';
  header?: string;
  className?: string;
  children?: React.ReactNode | null;
  [propName: string]: any;
}

const Alert: React.FC<IProps> = ({ variant, header, children, className = '', ...props }) => {
  let icon;
  switch (variant) {
    case 'info':
      icon = faInfoCircle;
      break;
    case 'warning':
      icon = faExclamationTriangle;
      break;
    case 'success':
      icon = faCheck;
      break;
    case 'danger':
      icon = faExclamationCircle;
      break;
    default:
      icon = faInfoCircle;
      break;
  }

  const classNames = classnames({
    'alert-info': variant === 'info',
    'alert-warning': variant === 'warning',
    'alert-success': variant === 'success',
    'alert-danger': variant === 'danger',
    [className]: true,
  });

  return (
    <RBAlert className={classNames} variant={variant} {...props}>
      {header ? (
        <div>
          <RBAlert.Heading as="h5" className="mb-4">
            <FontAwesomeIcon className="mr-2" icon={icon} />
            {header}
          </RBAlert.Heading>
          {children}
        </div>
      ) : (
        <div className="d-flex">
          <FontAwesomeIcon className="mr-4 mt-1" icon={icon} />
          {children}
        </div>
      )}
    </RBAlert>
  );
};

export default Alert;
