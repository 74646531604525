import { showConfirm } from 'shared/components/ConfirmationContainer';
import { ReenrollmentGetAccountsFilterBy, useSendReEnrollmentMutation } from 'generated/graphql';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faFileCirclePlus } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { showToast } from 'shared/components/Toast';

interface IProps {
  filterBy?: ReenrollmentGetAccountsFilterBy;
  displayNumber: number;
  onClick: () => void;
}

const ReEnrollBulkActions: React.FC<IProps> = ({ filterBy, displayNumber, onClick }) => {
  const { t } = useTranslation(['enrollment', 'translation']);
  const businessId = useSelector((state: RootState) => state.context.businessId);

  const [sendReEnrollmentMutation, { data, loading, error }] = useSendReEnrollmentMutation({
    onError: (err) => showToast(t('translation:enrollment.re-enrollment.send-reenrollments.error'), 'error'),
    onCompleted: (response) => {
      showToast(t('translation:enrollment.re-enrollment.send-reenrollments.success'), 'success');
    },
  });

  const onConfirm = async () => {
    sendReEnrollmentMutation({
      variables: {
        input: {
          businessId: businessId ?? '',
          filter: filterBy,
          centerIds: filterBy?.centerIds ?? [],
        },
      },
    });
  };

  const handleConfirm = () => {
    showConfirm({
      title: 'Bulk Send Requests',
      message: (
        <>
          You have selected <strong>{displayNumber}</strong> accounts. Would you like to send these re-enrolment
          requests to account primary contacts now?
        </>
      ),
      primaryButtonLabel: t('translation:enrollment.re-enrollment.confirmation-modal.primary-button-text'),
      onConfirm: onConfirm,
      primaryButtonVariant: 'primary',
    });
  };

  return (
    <IconButtonCircle
      icon={faFileCirclePlus}
      className="mr-2"
      tooltipText={t('enrollment:request-reenroll')}
      onClick={onClick}
    />
  );
};

export default ReEnrollBulkActions;
