import { getNumberWithOrdinal, snakeCaseToLowerCase, toProperCase } from 'shared/util/string';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

export const convertChargeDayToStringForPreview = (chargeDay?: number | DayOfWeek) =>
  typeof chargeDay === 'number'
    ? 'the ' + (chargeDay === -1 ? 'last' : getNumberWithOrdinal(chargeDay)) + ' day of the month'
    : snakeCaseToLowerCase(chargeDay?.toString() ?? '');

export const generateBillingCycleName = (billingCycle: ICreateBillingCycleTemplateInput) => {
  let lowerCaseFrequency = snakeCaseToLowerCase(billingCycle.frequency ?? '');
  lowerCaseFrequency = lowerCaseFrequency === 'biweekly' ? fieldLabels.biWeekly : lowerCaseFrequency;
  return toProperCase(
    `${convertChargeDayToStringForPreview(billingCycle.dayOfMonth || billingCycle.dayOfWeek)}, ${lowerCaseFrequency}`
  );
};

export const convertTimePeriodToString = (timePeriod: ITimePeriod) => {
  const { value } = timePeriod;
  // remove the s character in the unit string if value is singular
  const unit = timePeriod.unit?.substring(0, timePeriod.unit.length - (timePeriod?.value === 1 ? 1 : 0)) ?? '';

  return `${value} ${unit}`;
};

export const getChargeDayValue = (
  cycle: IBillingCycleTemplate | INewBillingCycleTemplateFormData | IBillingCycleFormData
) => {
  if (cycle.frequency?.includes('WEEK')) {
    // return an object for react-select
    return cycle.dayOfWeek ? { value: cycle.dayOfWeek, label: toProperCase(cycle.dayOfWeek?.toString() ?? '') } : null;
  } else {
    return cycle.dayOfMonth;
  }
};
