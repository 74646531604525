import React from 'react';
import { Row, Col } from 'shared/components/Layout';
import EmploymentStartForm from './EmploymentStartForm';
import EmploymentEndForm from './EmploymentEndForm';
import Card from 'shared/components/Card';

interface IProps {
  staff: IStaff | undefined;
  loading: boolean;
  readOnly: boolean;
}

const EmploymentHistoryCard: React.FC<IProps> = ({ staff, loading, readOnly }) => {
  return (
    <Card header="Employment History" loading={loading} loadingLines={2}>
      {staff && (
        <Row className="mb-n4 mx-n4" align="start">
          <Col md={12} lg={4} className="px-4">
            <EmploymentStartForm staff={staff} readOnly={readOnly} />
          </Col>
          <div className="vertical-divider align-self-stretch"></div>
          <Col className="px-4">
            <EmploymentEndForm staff={staff} readOnly={readOnly} />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default EmploymentHistoryCard;
