import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CircleDeleteButton } from 'shared/components/Buttons';
import TextInput, { NumberInput } from 'shared/components/TextInput';

interface IProps {
  temporaryEducators: ITemporaryEducator[];
  caseDayClaims: ICaseDayClaim[];
  hasNonF2FHours: boolean;
  handleRemoveEducator: (index: number) => void;
  educatorHoursPerDay: IDayHoursPerEducator;
  handleChange: (value: any, name: string) => void;
  handleTempEducatorNonF2FHours: (value: number, educatorId: string, day: DayOfCare) => void;
  handleTempEducatorF2FHours: (value: number, educatorId: string, day: DayOfCare) => void;
}

const TemporaryEducatorFields: React.FC<IProps> = ({
  temporaryEducators,
  hasNonF2FHours,
  caseDayClaims,
  educatorHoursPerDay,
  handleRemoveEducator,
  handleTempEducatorNonF2FHours,
  handleTempEducatorF2FHours,
  handleChange,
}) => {
  const getColumnDays = () => 'auto ' + Array(caseDayClaims.length + 1).join('82px ');

  return (
    <>
      <Row>
        <h5 className="mb-5">Temporary Educator Information</h5>
      </Row>
      <Row>
        <Col>
          <div className="educatorCards mb-5">
            {temporaryEducators.map((educator, index) => {
              return (
                <div key={`Educator_${educator.id}_${index}`} className="d-flex">
                  <div className="removalButton">
                    <CircleDeleteButton
                      onClick={() => {
                        handleRemoveEducator(index);
                      }}
                      size="sm"
                      variant="light"
                    />
                  </div>
                  <div className="educatorCard" style={{ gridTemplateColumns: getColumnDays() }}>
                    <div className="d-flex educatorNameInputs">
                      <TextInput
                        name="educator-day-hours"
                        value={educator.firstName}
                        placeholder="First Name"
                        required
                        isInvalid={educator.firstName === ''}
                        onChange={(value) => {
                          const newEducators = [...temporaryEducators];
                          newEducators[index].firstName = value;
                          handleChange(newEducators, 'temporaryEducators');
                        }}
                      />
                      <TextInput
                        required
                        placeholder="Last Name"
                        name="educator-day-hours"
                        value={educator.lastName}
                        isInvalid={educator.lastName === ''}
                        onChange={(value) => {
                          const newEducators = [...temporaryEducators];
                          newEducators[index].lastName = value;
                          handleChange(newEducators, 'temporaryEducators');
                        }}
                      />
                    </div>
                    {caseDayClaims.map((day) => (
                      <div
                        key={`Label_${educator.id}_${day.careDay}`}
                        className="educatorColumnLabel educatorHoursInput"
                      >
                        <label>{day.careDay}</label>
                      </div>
                    ))}
                    <div className="hoursType">
                      <label>F2F Hours</label>
                    </div>
                    {caseDayClaims.map((day) => {
                      const faceToFaceHours = educatorHoursPerDay[educator.id]?.[day.careDay]?.faceToFaceHours ?? 0;

                      return (
                        <div key={`f2f_${educator.id}_${day.careDay}`} className="educatorHoursInput">
                          <NumberInput
                            key={educator.id}
                            name={`temp-educator-day-f2f-hours[${educator.id}][${index}]`}
                            min="0"
                            max="24"
                            value={faceToFaceHours}
                            onChange={(value) => {
                              handleTempEducatorF2FHours(value, educator.id, day.careDay);
                            }}
                          />
                        </div>
                      );
                    })}
                    {hasNonF2FHours && (
                      <>
                        <div className="hoursType">
                          <label>Non-F2F Hours</label>
                        </div>
                        {caseDayClaims.map((day) => {
                          const nonFaceToFaceHours =
                            educatorHoursPerDay[educator.id]?.[day.careDay]?.nonFaceToFaceHours ?? 0;

                          return (
                            <div key={`non_f2f_${educator.id}_${day.careDay}`} className="educatorHoursInput">
                              <NumberInput
                                key={educator.id}
                                name={`temp-educator-day-non-f2f-hours[${educator.id}][${index}]`}
                                min="0"
                                max="24"
                                value={nonFaceToFaceHours}
                                onChange={(value) => {
                                  handleTempEducatorNonF2FHours(value, educator.id, day.careDay);
                                }}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TemporaryEducatorFields;
