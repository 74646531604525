import React from 'react';
import moment from 'moment';
import { Row, Col } from 'shared/components/Layout';
import { getTotalHours } from '../../utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface IProps {
  timeOff: ITimeOff;
}

const TimeOffReadOnlyDetails: React.FC<IProps> = ({ timeOff }) => {
  const timezone =
    useSelector((state: RootState) =>
      timeOff.centerId
        ? state.timezone.byCenterId[timeOff.centerId]
        : state.timezone.byBusinessId[timeOff.entityId ?? '']
    ) ?? moment.tz.guess();

  const startDate = moment(timeOff.startTime).tz(timezone).format('MMM DD, YYYY');
  const endDate = moment(timeOff.endTime).tz(timezone).format('MMM DD, YYYY');
  const isMultiDay = startDate !== endDate;

  return (
    <>
      <Row className="d-flex mb-4" align="start">
        <Col className="sm" align="start">
          <div className="mr-2">Date: </div>
          <div className="text-uppercase font-weight-semi-bold">
            {isMultiDay ? moment(timeOff.startTime).tz(timezone).format('MMM DD') : startDate}
            {isMultiDay && ' - '} {isMultiDay && endDate}
          </div>
        </Col>
        <Col className="sm" align="start">
          <div className="mr-2">Duration: </div>
          <div className="font-weight-semi-bold">
            {' '}
            {timeOff.allDay
              ? 'All Day'
              : moment(timeOff.startTime).tz(timezone).format('h:mm A') +
                ' - ' +
                moment(timeOff.endTime).tz(timezone).format('h:mm A')}
          </div>
        </Col>
        <Col className="sm" align="start">
          <div>Paid: </div>
          <div className="font-weight-semi-bold">{timeOff.hoursApproved} hrs</div>
        </Col>
        <Col className="sm" align="start">
          <div>Unpaid: </div>
          <div className="font-weight-semi-bold">
            {(getTotalHours(timeOff) - (timeOff.hoursApproved || 0)).toString()} hrs
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        {timeOff.ptoTypeObject && (
          <Col className="sm">
            <div> Request Type: </div>
            <div className="font-weight-semi-bold">
              {timeOff.ptoTypeObject.code} - {timeOff.ptoTypeObject.name}
            </div>
          </Col>
        )}
      </Row>
      <Row className="mb-4">
        <Col className="sm">
          <div>Description: </div>
          <div className="font-weight-semi-bold">{timeOff.description}</div>
        </Col>
      </Row>
    </>
  );
};

export default TimeOffReadOnlyDetails;
