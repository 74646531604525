import React, { useState } from 'react';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';

import DataTable, { TableSearch } from 'shared/components/DataTable';
import { TableHeader } from 'shared/components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import ISCaseClaimsDetailsRow from '../ISCaseClaimsDetailsRow';
import moment from 'moment';
import {
  ISCaseClaimLabelEnum,
  ISClaimServiceProvidedLabelEnum,
  ISCaseClaimStatusFormatting,
} from 'shared/constants/enums/iSCaseClaimEnum';

import './iSCaseClaimsTable.scss';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
interface ITableProps {
  data: IISCaseClaim[];
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  centers: ICenter[];
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  activeFilters: IISCaseClaimFilter;
  onActiveFilterChange: (value: IISCaseClaimFilter) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  businessId: string;
  onMutation?: (data: IISCaseClaim[]) => void;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const ISCaseTable: React.FC<ITableProps> = ({
  data,
  loading,
  dataSize,
  pageSize,
  activePage,
  centers,
  onPageChange,
  onSizePerPageChange,
  activeFilters,
  onActiveFilterChange,
  onSort,
  businessId,
  onMutation,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const handleCenterChange = (values: ITableFilterOption[]) => {
    onActiveFilterChange({ ...activeFilters, centers: values });
  };

  const handleSearch = (value: string) => {
    onActiveFilterChange({ ...activeFilters, searchText: value });
  };

  const onExpand = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    const expanded = isExpand ? [row.id] : [];
    setExpandedRows(expanded);
  };

  return (
    <>
      <TableHeader>
        <div className="d-flex flex-grow-1 my-2">
          <TableSearch onChange={handleSearch} placeholder="Search by IS case Id or child" />
        </div>

        <DropdownFilter
          dropdownId="centerFilter"
          title={fieldLabels.center}
          placeholder={fieldLabels.center}
          options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
          onFilterSelect={handleCenterChange}
          className="mr-4 my-2"
          selectedFilters={activeFilters.centers || []}
        />
      </TableHeader>
      <SpinnerTableOverlay show={loading}>
        <DataTable
          keyField="id"
          noPadding={true}
          data={data}
          dataSize={dataSize}
          handleRowClick={() => {}}
          hasOverlay={false}
          pageSize={pageSize}
          activePage={activePage}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          showPagination={true}
          showSelect={false}
          className="is-case-claim-table"
          expandHeaderColumnRenderer={(props) => <></>}
          onExpand={onExpand}
          expanded={expandedRows}
          expandRow={(row: IISCaseClaim) => (
            <ISCaseClaimsDetailsRow
              data={row}
              businessId={businessId}
              onSuccess={(result) => {
                if (onMutation) onMutation(data.map((x) => (x.id === result.id ? result : x)));
              }}
            />
          )}
          onSort={onSort}
          defaultSortField="created"
          defaultSorting="desc"
          onlyOneExpanding={true}
          columns={[
            {
              text: fieldLabels.center,
              dataField: 'centerName',
              sort: true,
            },

            {
              text: 'IS Case ID',
              dataField: 'isCaseId',
              sort: true,
            },
            {
              text: 'Week Ending',
              dataField: 'weekEnding',
              sort: true,
              formatter: (cell: string) => moment(cell).format('DD/MM/YYYY'),
            },
            {
              text: 'Claim Status',
              dataField: 'claimStatus',
              sort: true,
              formatter: (cell: ISCaseClaimStatus, row: IISCaseClaim) => (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faCircle}
                    style={{ fontSize: 10 }}
                    color={ISCaseClaimStatusFormatting[cell].color}
                  />
                  {ISCaseClaimLabelEnum[cell]}
                </div>
              ),
            },
            {
              text: 'Service Provided',
              align: 'center',
              headerClasses: 'text-center',
              dataField: 'serviceProvided',
              formatter: (cell: ISCaseServiceProvided) => ISClaimServiceProvidedLabelEnum[cell],
            },
            {
              text: 'Claimed Hrs',
              align: 'center',
              headerClasses: 'text-center',
              dataField: 'hoursClaimed',
              formatter: (cell: string) => moment.duration(cell).asHours(),
            },
            {
              text: 'Paid Hrs',
              align: 'center',
              headerClasses: 'text-center',
              dataField: 'hoursPaid',
              formatter: (cell: string) => moment.duration(cell).asHours(),
            },
          ]}
        />
      </SpinnerTableOverlay>
    </>
  );
};

export default ISCaseTable;
