import { Line } from 'shared/components/LoadingSkeletons';
import './LoadingLines.scss';

export function LoadingLines() {
  return (
    <div className="billing__loading-lines">
      <Line></Line>
      <Line></Line>
    </div>
  );
}
