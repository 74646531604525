import React, { useState, useCallback, useEffect } from 'react';
import { capitalize } from 'lodash';
import Button, { CreateButton } from 'shared/components/Buttons';
import PageWrapper from 'shared/components/PageWrapper';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { useTranslation } from 'react-i18next';
import ManageClosuresForm from './components/ManageClosuresForm';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { TimeRange, ClosureResolution, IFormData } from './types';
import moment from 'moment';
import { isFormDataValid } from './helpers';
import { cloneDeep } from 'lodash';
import { useAddClassClosures, IAddClassClosureInput } from 'gql/class/mutations';
import { showToast } from 'shared/components/Toast';
import ConfirmClosuresModal from './components/ConfirmClosuresModal';

const initialFormData: IFormData = {
  classIds: [],
  isAllClassesSelected: false,
  timeRange: TimeRange.Single,
  startDate: moment().format('YYYY-MM-DD'),
  endDate: null,
  closureResolution: ClosureResolution.ABSENT,
};

const ManageClosures = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [addClassClosures, { loading }] = useAddClassClosures({
    onCompleted: () => {
      history.push('/attendance/attendance');
      showToast(t('attendance.manage-closures.add-closure-success-toast'), 'success');
    },
  });

  const centerId = useSelector((state: RootState) => state.context.centerId) ?? '';

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [inputs, setInputs] = useState<IFormData[]>([initialFormData]);

  const handleSubmit = useCallback(() => {
    const requestPayload: IAddClassClosureInput[] = [];

    inputs.forEach((row) => {
      const { classIds, startDate, endDate, timeRange, closureResolution } = row;
      classIds.forEach((classId) => {
        requestPayload.push({
          classId,
          startDate,
          endDate: timeRange === TimeRange.Single ? startDate : endDate ?? '',
          closureResolution,
        });
      });
    });

    addClassClosures({
      variables: {
        input: requestPayload,
      },
    });
  }, [inputs, addClassClosures]);

  const isInputsInvalid = inputs.map((input) => isFormDataValid(input)).includes(false);

  const setFormData = useCallback(
    (index: number, data: IFormData) => {
      const mutableInputs = cloneDeep(inputs);
      mutableInputs[index] = data;
      setInputs(mutableInputs);
    },
    [inputs]
  );

  const removeFormData = useCallback(
    (index: number) => {
      const mutableInputs = cloneDeep(inputs);
      mutableInputs.splice(index, 1);
      setInputs(mutableInputs);
    },
    [inputs]
  );

  useEffect(() => {
    setInputs((prev) => {
      return prev.map((input) => ({
        ...input,
        class: null,
      }));
    });
  }, [centerId]);

  return (
    <>
      <PageWrapper
        pageTitle={t('attendance.manage-closures.title')}
        applyPadding={true}
        buttonComponent={
          <>
            <Button variant="light" onClick={() => history.push('/attendance/attendance')} className="mr-2">
              {capitalize(t('spelling.cancel'))}
            </Button>
            <Button
              variant="primary"
              disabled={isInputsInvalid}
              onClick={() => setIsConfirmationModalOpen(true)}
              loading={loading}
            >
              {capitalize(t('spelling.save'))}
            </Button>
          </>
        }
      >
        <CenterSelectBanner pageName="attendance" />
        <div className="p-8">
          {inputs.map((input, index) => (
            <ManageClosuresForm
              key={index}
              formData={input}
              setFormData={(formData) => setFormData(index, formData)}
              isRemovable={index > 0}
              removeFormData={() => removeFormData(index)}
              centerId={centerId}
            />
          ))}
          <CreateButton
            variant="outline-secondary"
            className="w-100 mt-2"
            onClick={() => setInputs((prev) => [...prev, initialFormData])}
          >
            {t('attendance.manage-closures.add-additional-closure')}
          </CreateButton>
        </div>
      </PageWrapper>
      <ConfirmClosuresModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        handleSubmit={handleSubmit}
        loading={loading}
        data={inputs}
      />
    </>
  );
};

export default ManageClosures;
