import React, { useRef, useState } from 'react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { CreateButton } from 'shared/components/Buttons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import EnrollmentFormsTable, { EnrolmentFormLocationState, ExternalHandler } from './EnrollmentFormsTable';
import CreateEnrollmentFormModal from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/CreateEnrollmentFormModal';
import { useHistory } from 'react-router-dom';

export type EnrollmentFormsProps = {};

const EnrollmentForms = ({}: EnrollmentFormsProps) => {
  const { t } = useTranslation(['translation', 'enrollment', 'business']);

  const refetchRef = useRef<ExternalHandler | null>(null);
  const locationStateHistory = useHistory<EnrolmentFormLocationState>();
  const hasCreateLinkPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Create,
  });

  const [showCreateModal, setShowCreateModal] = useState(false);

  const businessId = useSelector<RootState, string>((state) => state.user?.entityId!);

  const hasCreateEnrollmentLeadSettingsCreatePermissions = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Create,
  });

  const hasCreateEnrollmentLeadSettingsEditPermissions = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Edit,
  });

  return (
    <div>
      <div className="d-flex align-items-center mb-4">
        <h3>{t('enrollment:settings.enrollment-form-tab-title')}</h3>
        {hasCreateLinkPermission && (
          <CreateButton className="ml-auto" variant="primary" onClick={() => setShowCreateModal(true)}>
            {t('enrollment:form.add-form')}
          </CreateButton>
        )}
      </div>

      <EnrollmentFormsTable businessId={businessId} ref={refetchRef} />

      <CreateEnrollmentFormModal
        businessId={businessId}
        isOpen={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
        }}
        onSuccess={(data) => {
          refetchRef.current?.refetch();
          locationStateHistory.push({
            pathname: '/enrollment/settings/enrollment-form/' + data.id,
            state: { enrolmentForm: data },
          });
        }}
      />
    </div>
  );
};

export default EnrollmentForms;
