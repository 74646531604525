import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import Button from 'shared/components/Buttons';
import PageWrapperAutoSave from 'shared/components/PageWrapper/PageWrapperAutoSave';
import AgencyPaymentHeader from './components/AgencyPaymentHeader';
import LineItems from './components/LineItems';
import { useManageAgencyPayment } from './useManageAgencyPayment';
import { useHistory } from 'react-router-dom';

/**
 * extracts line item dates so we can build our columns
 *
 * For now we have chosen to limit ourselves to two columns all the time
 * @param lineItems
 * @returns list of columns
 */

interface AgencyPaymentProps extends RouteComponentProps<{ agencyPaymentId: string }> {}
const AgencyPayment = ({ match }: AgencyPaymentProps) => {
  const { t } = useTranslation(['subsidies']);
  const agencyId: string = useMemo(() => match.params.agencyPaymentId, [match.params.agencyPaymentId]);

  // load the agency payment
  const {
    agencyPayment,
    columns,
    lineItems,
    totalEntered,
    difference,
    handleRemoveLineItem,
    handleDateChange,
    handleAccountSelection,
    handleChildSelection,
    handleAmountChange,
    handleHeaderAmountChange,
    handleHeaderDateChange,
    handleHeaderReferenceIdChange,
    saving,
    lastSave,
    saveAgencySubsidyPayment,
  } = useManageAgencyPayment(agencyId);

  return (
    <PageWrapperAutoSave
      pageTitle={t('subsidies:agency-payment.manage-agency-payment.page-title')}
      buttonComponent={
        <Button variant="primary" onClick={saveAgencySubsidyPayment}>
          {t('subsidies:agency-payment.manage-agency-payment.save-btn')}
        </Button>
      }
      savingComponent={
        <span className="mr-4 align-items-center d-flex save-timestamp">
          {saving ? 'Saving...' : lastSave ? 'Last Saved on ' + lastSave : ''}
        </span>
      }
    >
      <AgencyPaymentHeader
        agencyPayment={agencyPayment}
        amountEntered={totalEntered}
        remainingDifference={difference}
        onDateChange={handleHeaderDateChange}
        onAmountChange={handleHeaderAmountChange}
        onReferenceIdChange={handleHeaderReferenceIdChange}
      ></AgencyPaymentHeader>
      <LineItems
        agencyPayment={agencyPayment}
        dateColumns={columns}
        lineItems={lineItems}
        onAccountSelection={handleAccountSelection}
        onChildSelection={handleChildSelection}
        onAmountChange={handleAmountChange}
        onDateColumnChange={handleDateChange}
        onRemoveLineItem={handleRemoveLineItem}
      ></LineItems>
    </PageWrapperAutoSave>
  );
};

export default AgencyPayment;
