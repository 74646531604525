import React from 'react';
import _, { sortBy } from 'lodash';
import * as Mui from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress } from '@mui/material';
import { useGetIntegrationApiKeyForBusiness } from '../../../gql/integrationApiKey/queries';
import AddApiKeyDialog from './components/AddApiKeyDialog';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import ApiKey from './components/ApiKey';
import useGetAllowedEntities from '../../../shared/hooks/useGetAllowedEntities';

interface ISelectBusiness {
  label: string;
  id: string;
}

interface IProps {}

const ApiKeyManagement: React.FC<IProps> = ({ ...props }) => {
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const [selectBusinessId, setSelectBusinessId] = React.useState<ISelectBusiness | null>(null);
  const [addKeyDialogOpen, setAddKeyDialogOpen] = React.useState(false);
  const { data: businessesData } = useGetAllowedEntities();
  const [apiAccessSwitch, setApiAccessSwitch] = React.useState(true);
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const bId = React.useMemo(() => {
    if (user?.isInternal) {
      return selectBusinessId?.id ?? '';
    } else {
      return businessId ?? '';
    }
  }, [businessId, selectBusinessId]);

  const {
    data,
    loading: loadingKeys,
    refetch,
  } = useGetIntegrationApiKeyForBusiness({
    skip: _.isEmpty(bId),
    variables: {
      input: {
        id: bId,
        showRevoked: !apiAccessSwitch,
      },
    },
  });

  const keys = React.useMemo(
    () => sortBy(data?.getIntegrationApiKeyForBusiness || [], 'description'),
    [data, selectBusinessId]
  );

  const refetchKeys = () => {
    refetch({
      input: {
        id: bId,
        showRevoked: !apiAccessSwitch,
      },
    });
  };

  const renderOptions = (props, option: ISelectBusiness) => {
    return (
      <li {...props} key={option.id}>
        {option.label}
      </li>
    );
  };

  return (
    <>
      <Mui.Box
        sx={{
          backgroundColor: 'white',
          padding: '12px 12px',
          position: 'sticky',
          zIndex: '900',
          top: '65px',
        }}
      >
        <Mui.Box display="flex" justifyContent="space-between" sx={{ alignItems: 'center' }}>
          {/* this is the page title */}
          <Mui.Typography paddingLeft={'16px'} variant="h2" component="h1">
            API Key Manager
          </Mui.Typography>
          <Mui.Button
            color="secondary"
            variant="contained"
            disabled={user?.isInternal && !!_.isNil(selectBusinessId)}
            startIcon={<AddIcon />}
            onClick={() => setAddKeyDialogOpen(!addKeyDialogOpen)}
          >
            Create API Key
          </Mui.Button>
        </Mui.Box>
      </Mui.Box>
      <Mui.Divider />
      <Mui.Box paddingTop={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Mui.FormControlLabel
          value="ApiSwitch"
          control={<Mui.Switch color="success" onChange={(e) => setApiAccessSwitch(e.target.checked)} />}
          label="API Access"
          labelPlacement="top"
          checked={apiAccessSwitch}
        />
        {user?.isInternal && (
          <Mui.Autocomplete<ISelectBusiness>
            disablePortal
            id="combo-box-demo"
            options={businesses.map((b) => ({ label: b.name, id: b.id }))}
            onChange={(e, value) => setSelectBusinessId(_.isNil(value) ? null : value)}
            value={selectBusinessId}
            sx={{ width: 300, marginRight: '12px' }}
            renderInput={(params) => <Mui.TextField {...params} label="Business" />}
            renderOption={renderOptions}
          />
        )}
      </Mui.Box>
      {loadingKeys ? (
        <CircularProgress
          color="primary"
          sx={{
            position: 'absolute',
            justifyContent: 'center',
            left: '50%',
            top: '50%',
          }}
        />
      ) : (
        <Mui.Box paddingLeft={3} paddingRight={3}>
          <Mui.TableContainer sx={{ boxShadow: 0, maxWidth: 4000 }} component={Mui.Paper}>
            <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
              <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
                <Mui.TableCell sx={{ border: 0 }}>Name</Mui.TableCell>
                <Mui.TableCell sx={{ border: 0 }}>Created</Mui.TableCell>
                <Mui.TableCell sx={{ border: 0, width: '32%' }}>API Key</Mui.TableCell>
                <Mui.TableCell sx={{ border: 0, width: '15%' }}>Status</Mui.TableCell>
                <Mui.TableCell sx={{ border: 0, width: '32%' }}>WebHook Url</Mui.TableCell>
                <Mui.TableCell sx={{ border: 0 }}> </Mui.TableCell>
              </Mui.TableHead>
              <Mui.TableBody sx={{ boxShadow: 1 }}>
                {!_.isNil(keys) &&
                  keys.map((apiKey) => <ApiKey apiKey={apiKey} key={apiKey.id} refetchKeys={refetchKeys} />)}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        </Mui.Box>
      )}
      <AddApiKeyDialog
        businessId={bId}
        addKeyDialogOpen={addKeyDialogOpen}
        setAddorEditKeyDialogOpen={setAddKeyDialogOpen}
        refetchKeys={refetchKeys}
      />
    </>
  );
};

export default ApiKeyManagement;
