import { Card, CardContent, Typography } from '@mui/material';

export function UnselectedOverview() {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography>Select something from the Billing History list to see details here.</Typography>
      </CardContent>
    </Card>
  );
}
