import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

export const GET_TAGS = gql`
  query ($id: String!) {
    getEntity(id: $id) {
      name
      address {
        city
        state
      }
      avatar {
        url
      }
      tags {
        id
        entityId
        name
        category
      }
    }
  }
`;

export const useGetTags = (id: string) =>
  useQuery<ITagQueryData, ITagQueryVars>(GET_TAGS, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });
