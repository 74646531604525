import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import { capitalize } from 'shared/util/string';
import imunization from './immunizations-banner.svg';
interface IProps {}

const ImmunizationInformationCard: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Card bodyClassName="pb-0">
      <div className="d-flex flex-row">
        <div
          className="d-none d-md-flex flex-column justify-content-center align-items-center"
          style={{ height: 154, maxWidth: '25%' }}
        >
          <div className="d-flex flex-row justify-content-center mh-100">
            <img src={imunization} alt="Doctor" className="mr-4" />
          </div>
        </div>
        <div className="d-flex flex-column flex-1">
          <b className="text-info mb-4">{capitalize(t('health.immunization.localized'))}s</b>
          <div className="mb-4">
            {capitalize(t('health.immunization.localized'))}s vary based on the age of the child. To learn more about
            which are required for a child based on their age you can visit the {t('health.immunization.institution')}.
          </div>
          <a href={t('health.immunization.website')} target="_blank" rel="noopener noreferrer">
            View {capitalize(t('health.immunization.localized'))} Requirements
          </a>
        </div>
      </div>
    </Card>
  );
};

export default ImmunizationInformationCard;
