import React, { useEffect } from 'react';

import * as Mui from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
};

interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
}

//When using this component if you are going to have a primary action button please assign an id of "modal-drawer-primary-btn" this will keep things consistent in Pendo

const RightModalDrawer: React.FC<IProps> = ({ open, onClose, title, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const chatBubble = document.getElementById('fc_widget');

    if (chatBubble) {
      chatBubble.style.display = open ? 'none' : 'block';
    }
  }, [open]);

  return (
    <>
      <Mui.Dialog
        PaperProps={{
          sx: {
            position: 'absolute',
            height: '100%',
            width: isMobile ? '90%' : '33.3%',
            right: -32,
            overflow: 'visible',
            maxHeight: '100%',
          },
        }}
        open={open}
      >
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography variant="dialogTitle">{title}</Mui.Typography>
          <Mui.IconButton sx={styles.closeIcon} disableRipple onClick={() => onClose()}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        {props.children}
      </Mui.Dialog>
    </>
  );
};

export default RightModalDrawer;
