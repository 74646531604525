import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';

interface Session {}

type ChargeFullFeeEndCareSessionsInput = {
  input: {
    accountChildId: string;
    sessionIds: string[];
    businessId: string;
  };
};

type VoidEndCareSessionsInput = {
  input: {
    accountChildId: string;
    sessionIds: string[];
    businessId: string;
  };
};

type HideEndCareSessionsInput = {
  input: {
    accountChildId: string;
    untilDate: Date;
    businessId: string;
  };
};

type RevealEndCareSessionsInput = {
  input: {
    accountChildId: string;
    businessId: string;
  };
};

export const ChargeFullFeeEndCareSessions = gql`
  mutation ($input: ChargeFullFeeEndCareSessionsInput!) {
    chargeFullFeeEndCareSessions(input: $input) {
      id
    }
  }
`;

export const VoidEndCareSessionsInput = gql`
  mutation ($input: VoidEndCareSessionsInput!) {
    voidEndCareSessions(input: $input) {
      id
    }
  }
`;

export const HideEndCareSessionsInput = gql`
  mutation ($input: HideEndCareSessionsInput!) {
    hideEndCareSessions(input: $input)
  }
`;

export const RevealEndCareSessionsInput = gql`
  mutation ($input: RevealEndCareSessionsInput!) {
    revealEndCareSessions(input: $input)
  }
`;

export const useChargeFullFeeEndCareSessions = (input: ChargeFullFeeEndCareSessionsInput) => {
  return useMutation<Session, ChargeFullFeeEndCareSessionsInput>(ChargeFullFeeEndCareSessions, {
    variables: {
      ...input,
    },
  });
};

export const useVoidEndCareSessions = (input: VoidEndCareSessionsInput) => {
  return useMutation<Session, VoidEndCareSessionsInput>(VoidEndCareSessionsInput, {
    variables: {
      ...input,
    },
  });
};

export const useHideEndCareSessions = (input: HideEndCareSessionsInput) => {
  return useMutation<Session, HideEndCareSessionsInput>(HideEndCareSessionsInput, {
    variables: {
      ...input,
    },
  });
};

export const useRevealEndCareSessions = (input: RevealEndCareSessionsInput) => {
  return useMutation<Session, RevealEndCareSessionsInput>(RevealEndCareSessionsInput, {
    variables: {
      ...input,
    },
  });
};
