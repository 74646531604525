import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';
import { useGetDiscountsForBusiness } from 'gql/discount/queries';
import { useTranslation } from 'react-i18next';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Select from 'shared/components/Select';
import { currencyFormat } from 'shared/util/currency';
import Alert from 'shared/components/Alert';
import { useApplyDiscountToTransaction } from 'gql/transaction/mutations';
import { useDispatch } from 'react-redux';
import { applyDiscountToTransaction } from '../duck/action';
import { showToast } from 'shared/components/Toast';

interface IFormStateShape {
  discount: IDiscount | null;
}

interface IProps {
  isOpen: boolean;
  transaction: ITransaction;
  /**
   * Called when the component wants to close. This could be because someone clicked cancel, or save, or x'd out of the modal
   * @returns
   */
  onClose: () => void;
  /**
   * Called when the component has successfully applied the discount to the transaction
   * @returns
   */
  onCompleted?: () => void;
}

const ApplyDiscountToTransactionModal: React.FC<IProps> = ({
  isOpen,
  transaction,
  onClose,
  onCompleted = () => {},
}) => {
  const { t } = useTranslation(['billing']);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IFormStateShape>({ discount: null });

  const { data: getDiscountsForBusinessData, loading: getDiscountsForBusinessLoading } = useGetDiscountsForBusiness({
    variables: {
      businessId: transaction.account?.center?.entityId ?? '',
    },
    skip: !transaction.account || !transaction.account.center,
  });

  const [applyDiscountFn, { loading: applyDiscountToTransactionLoading }] = useApplyDiscountToTransaction({
    onCompleted: (result) => {
      if (result.applyDiscountToTransaction === null) {
        showToast(t('billing:transactions.add-discount-modal.discount-not-added-toast-message'), 'error');
      } else {
        dispatch(applyDiscountToTransaction(transaction.id, result.applyDiscountToTransaction));
        showToast(t('billing:transactions.add-discount-modal.success-toast-message'), 'success');
      }

      handleClose();
      onCompleted();
    },
    onError: (err) => {
      showToast(t('billing:transactions.add-discount-modal.error-toast-message'), 'error');
    },
  });

  const handleSave = useCallback(() => {
    if (formData.discount) {
      applyDiscountFn({
        variables: {
          input: {
            transactionId: transaction.id,
            discountId: formData.discount.id,
          },
        },
      });
    }
  }, [transaction, formData, applyDiscountFn]);

  const handleClose = useCallback(() => {
    setFormData({ discount: null });
    onClose();
  }, [onClose]);

  const discountOptions = orderBy(
    (getDiscountsForBusinessData?.getDiscountsForBusiness ?? []).filter(
      (discount) =>
        discount.type === 'MANUAL' &&
        discount.schedules.some(
          (schedule) =>
            moment() >= moment(schedule.startDate) && (!schedule.endDate || moment() <= moment(schedule.endDate))
        ) &&
        (transaction.sessionId !== null ? ['SESSION', 'TRANSACTION'] : ['TRANSACTION']).includes(discount.appliesTo)
    ),
    (discount) => discount.name.toLowerCase(),
    'asc'
  );

  const calculateDiscountAmount = useCallback(
    (transaction: ITransaction, discount: IDiscount): JSX.Element => {
      const activeSchedule = discount.schedules.find(
        (schedule) =>
          moment() >= moment(schedule.startDate) && (!schedule.endDate || moment() <= moment(schedule.endDate))
      );

      if (!activeSchedule) return <div>{t('billing:transactions.add-discount-modal.no-active-discount-schedule')}</div>;

      const discountedTotal =
        (transaction.appliedDiscountTransactions ?? []).reduce(
          (acc, curr) => (!curr.reversedByTransactionId ? (acc += curr.amount) : acc),
          0
        ) ?? 0;
      const transactionAmount = transaction.amount + discountedTotal;
      const amount =
        activeSchedule.amountType === 'FLAT'
          ? activeSchedule.amount
          : Math.abs(transactionAmount * activeSchedule.amount);

      const discountedCurrencyTotal =
        transactionAmount + amount < 0 ? currencyFormat(transactionAmount + amount) : currencyFormat(0);

      return (
        <Alert variant="info" className="box-shadow-0">
          {t('billing:transactions.add-discount-modal.discount-amount-preview', {
            discountValue:
              activeSchedule.amountType === 'FLAT'
                ? currencyFormat(activeSchedule.amount)
                : `${activeSchedule.amount * 100}%`,
            discountedTotal: discountedCurrencyTotal,
          })}
        </Alert>
      );
    },
    [t]
  );

  return (
    <CenteredModal
      title={t('billing:transactions.add-discount-modal.title')}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice={t('billing:transactions.add-discount-modal.primary-button')}
      secondaryChoice={t('billing:transactions.add-discount-modal.secondary-button')}
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: !formData.discount, loading: applyDiscountToTransactionLoading }}
      secondaryCallback={handleClose}
    >
      <Select
        label="Discount"
        options={discountOptions}
        onChange={(option: IDiscount) => setFormData({ discount: option })}
        formatOptionLabel={(option: IDiscount) => (
          <div>
            <div>{option.name}</div>
            <div>
              <small>{option.description}</small>
            </div>
          </div>
        )}
        getOptionValue={(option: IDiscount) => option.id}
        isLoading={getDiscountsForBusinessLoading}
      />
      {!formData.discount && <div>{t('billing:transactions.add-discount-modal.no-discount-selected-text')}</div>}
      {formData.discount && calculateDiscountAmount(transaction, formData.discount)}
    </CenteredModal>
  );
};

export default ApplyDiscountToTransactionModal;
