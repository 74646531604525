import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BatchedPaymentsTable from './BatchedPaymentsTable';
import { RootState } from 'store/reducers';
import UnbatchModal from './UnbatchModal';

interface IUnbatchModalStateShape {
  open: boolean;
  batch: IBatch | null;
}

interface IProps {}

const BatchedPaymentsTab: React.FC<IProps> = ({ ...props }) => {
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const [unbatchModal, setUnbatchModal] = useState<IUnbatchModalStateShape>({ open: false, batch: null });

  return (
    <>
      <BatchedPaymentsTable
        businessId={currentBusinessId ?? ''}
        onUnbatch={(batch) => setUnbatchModal({ open: true, batch })}
      />
      {unbatchModal.batch && (
        <UnbatchModal
          isOpen={unbatchModal.open}
          batch={unbatchModal.batch}
          onClose={() => setUnbatchModal({ open: false, batch: null })}
        />
      )}
    </>
  );
};

export default BatchedPaymentsTab;
