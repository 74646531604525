import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateInput from 'shared/components/DateInput';
import { Row, Col } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import Checkbox from 'shared/components/Checkbox';
import EditDelayedExitForm from '../EditProgramModal/EditDelayedExitForm';
import KindyFundingSchedules from './KindyFundingSchedules';
import Card from 'shared/components/Card';
import { kindyForAllSubsidySchemeId } from 'pages/Subsidies/KindyFunding/utils';

interface IProps {
  subsidySchemeId?: string;
  accountChild: IAccountChild;
  programChild?: IProgramChild | undefined;
  initialFormData: IKindyForAllChildInformationForm | undefined;
  handleChange: (returnData: IKindyForAllChildInformationForm) => void;
}

const QGrantsChildInformationForm: React.FC<IProps> = ({
  subsidySchemeId,
  accountChild,
  programChild,
  initialFormData,
  handleChange,
}) => {
  const { t } = useTranslation(['subsidies']);
  const cancellationReasonOptions = [
    { value: 'ChronicNonAttendance', label: t('subsidies:program-child.cancellationReason.chronic-non-attendance') },
    { value: 'NonPaymentOfFees', label: t('subsidies:program-child.cancellationReason.non-payment-of-fees') },
    { value: 'FailureToComply', label: t('subsidies:program-child.cancellationReason.failure-to-comply') },
  ];

  const visaOptions = [
    { value: 'None', label: t('subsidies:program-child.visaType.none') },
    { value: 'RefugeeVisa', label: t('subsidies:program-child.visaType.refugee-visa') },
    {
      value: 'InCountrySpecialHumanitarianVisa',
      label: t('subsidies:program-child.visaType.in-country-special-humanitarian-visa'),
    },
    { value: 'EmergencyRescueVisa', label: t('subsidies:program-child.visaType.emergency-rescue-visa') },
    { value: 'WomanAtRiskVisa', label: t('subsidies:program-child.visaType.woman-at-risk-visa') },
    {
      value: 'GlobalSpecialHumanitarianProgramVisa',
      label: t('subsidies:program-child.visaType.global-special-humanitarian-program-visa'),
    },
    { value: 'TemporaryProtectionVisa', label: t('subsidies:program-child.visaType.temporary-protection-visa') },
    { value: 'SafeHavenEnterpriseVisa', label: t('subsidies:program-child.visaType.safe-haven-enterprise-visa') },
    { value: 'ProtectionVisa', label: t('subsidies:program-child.visaType.protection-visa') },
    { value: 'BridgingVisaE', label: t('subsidies:program-child.visaType.bridging-visa-e') },
    { value: 'BridgingVisaA', label: t('subsidies:program-child.visaType.bridging-visa-a') },
    { value: 'BridgingVisaB', label: t('subsidies:program-child.visaType.bridging-visa-b') },
    { value: 'BridgingVisaC', label: t('subsidies:program-child.visaType.bridging-visa-c') },
    { value: 'TemporaryHumanitarianStay', label: t('subsidies:program-child.visaType.temporary-humanitarian-stay') },
    { value: 'PartnerMigrantVisa', label: t('subsidies:program-child.visaType.partner-migrant-visa') },
    { value: 'Ahmadi', label: t('subsidies:program-child.visaType.ahmadi') },
    { value: 'BurmeseInBurma', label: t('subsidies:program-child.visaType.burmese-in-burma') },
    { value: 'BurmeseInThailand', label: t('subsidies:program-child.visaType.burmese-in-thailand') },
    {
      value: 'CitizensOfTheFormerYugoslavia',
      label: t('subsidies:program-child.visaType.citizens-of-the-former-yugoslavia'),
    },
    { value: 'KosovarSafeHaven', label: t('subsidies:program-child.visaType.kosovar-safe-haven') },
    {
      value: 'SecondaryMovementAndRelocation',
      label: t('subsidies:program-child.visaType.secondary-movement-and-relocation'),
    },
    {
      value: 'SecondaryMovementOffshoreEntry',
      label: t('subsidies:program-child.visaType.secondary-movement-offshore-entry'),
    },
    { value: 'SriLankanSpecialAssistance', label: t('subsidies:program-child.visaType.sriLankan-special-assistance') },
    { value: 'Sudanese', label: t('subsidies:program-child.visaType.sudanese-sudanese') },
    {
      value: 'TemporaryHumanitarianConcern',
      label: t('subsidies:program-child.visaType.temporary-humanitarian-concern'),
    },
    { value: 'TerritorialAsylumResidence', label: t('subsidies:program-child.visaType.territorial-asylum-residence') },
    { value: 'ReturnPendingRepealed', label: t('subsidies:program-child.visaType.return-pending-repealed') },
    {
      value: 'BusinessInnovationInvestment',
      label: t('subsidies:program-child.visaType.business-innovation-investment'),
    },
    { value: 'ProspectiveMarriage', label: t('subsidies:program-child.visaType.prospective-marriage') },
    { value: 'Partner', label: t('subsidies:program-child.visaType.partner') },
    { value: 'TemporaryWorkShortStay', label: t('subsidies:program-child.visaType.temporary-work-short-stay') },
    {
      value: 'TemporaryWorkInternationalRelations',
      label: t('subsidies:program-child.visaType.temporary-work-international-relationships'),
    },
    { value: 'Training', label: t('subsidies:program-child.visaType.training') },
    { value: 'TemporaryActivity', label: t('subsidies:program-child.visaType.temporary-activity') },
    { value: 'SpecialCategoryVisa', label: t('subsidies:program-child.visaType.special-category-visa') },
    { value: 'DependentChild', label: t('subsidies:program-child.visaType.dependent-child') },
    {
      value: 'NewZealandCitizenFamilyResponsibility',
      label: t('subsidies:program-child.visaType.new-zealand-citizen-family-responsibility'),
    },
    { value: 'SkilledRecognisedGraduate', label: t('subsidies:program-child.visaType.skilled-recognised-graduate') },
    { value: 'TemporarySkillShortage', label: t('subsidies:program-child.visaType.temporary-skill-shortage') },
    { value: 'TemporaryGraduate', label: t('subsidies:program-child.visaType.temporary-graduate') },
    { value: 'SkilledRegional', label: t('subsidies:program-child.visaType.skilled-regional') },
    { value: 'SkilledWorkRegional', label: t('subsidies:program-child.visaType.skilled-work-regional') },
    {
      value: 'SkilledEmployerSponsoredRegional',
      label: t('subsidies:program-child.visaType.skilled-employer-sponsored-regional'),
    },
    { value: 'StudentExchange', label: t('subsidies:program-child.visaType.student-exchange') },
    {
      value: 'StudentParentPrimaryVisaHolder',
      label: t('subsidies:program-child.visaType.student-parent-primary-visa-holder'),
    },
    { value: 'Spouse', label: t('subsidies:program-child.visaType.spouse') },
    { value: 'SponsoredParent', label: t('subsidies:program-child.visaType.sponsored-parent') },
    { value: 'ContributoryAgedParent', label: t('subsidies:program-child.visaType.contributory-aged-parent') },
    { value: 'MaritimeCrew', label: t('subsidies:program-child.visaType.maritime-crew') },
    { value: 'Other', label: t('subsidies:program-child.visaType.other') },
  ];

  const [childFormData, setChildFormData] = useState<IKindyForAllChildForm>(
    initialFormData?.kindyForAllChild ?? {
      childId: undefined,
      child: accountChild,
      cancellationDate: undefined,
      cancellationReason: 'None',
      formalProtectionCare: false,
      hasCancellation: false,
      kinship: false,
      visaType: 'None',
      disability: false,
      multiBirth: false,
      nonEnglishSpeakingBackground: false,
      formalKinshipStartDate: undefined,
      multiBirthStartDate: undefined,
      discretionaryStartDate: undefined,
    }
  );

  const [childProgramSchedulesFormData, setChildProgramSchedulesFormData] =
    useState<IKindyProgramChildFundingSchedulesInput>(
      initialFormData?.childProgramFundingSchedules ?? {
        programChildId: programChild?.id ?? '',
        schedules: [],
      }
    );

  const [delayedExitFormData, setDelayedExitFormData] = useState<IDelayedExitForm>(
    initialFormData?.delayedExit ?? {
      id: undefined,
      child: null,
      notes: undefined,
      file: undefined,
    }
  );
  const handleFormChange = (value: any, name: string) => {
    setChildFormData((state) => ({
      ...state,
      [name]: name === 'visaType' || name === 'cancellationReason' ? value.value : value,
    }));
  };

  const handleKinshipChange = (value: any) => {
    setChildFormData((state) => ({
      ...state,
      kinship: value,
      formalKinshipStartDate: value ? childFormData.formalKinshipStartDate : undefined,
    }));
  };

  const handleFormalProtectionCareChange = (value: any) => {
    setChildFormData((state) => ({
      ...state,
      formalProtectionCare: value,
      formalProtectionCareStartDate: value ? childFormData.formalProtectionCareStartDate : undefined,
    }));
  };

  const handleMultiBirthChange = (value: any) => {
    setChildFormData((state) => ({
      ...state,
      multiBirth: value,
      multiBirthStartDate: value ? childFormData.multiBirthStartDate : undefined,
    }));
  };

  const handleHasCancellation = (value: any) => {
    setChildFormData((state) => ({
      ...state,
      hasCancellation: value,
      cancellationDate: value ? childFormData.cancellationDate : undefined,
      cancellationReason: value ? childFormData.cancellationReason : 'None',
    }));
  };

  const handleDelayedExit = (value: any, name: string) => {
    setDelayedExitFormData((state) => ({
      ...state,
      child: accountChild,
      [name]: value,
    }));
  };

  const handleChildSchedules = (value: IKindyProgramChildFundingSchedule[]) => {
    setChildProgramSchedulesFormData((state) => ({
      ...state,
      schedules: value,
    }));
  };

  useEffect(() => {
    handleChange({
      kindyForAllChild: childFormData,
      delayedExit: delayedExitFormData,
      childProgramFundingSchedules: childProgramSchedulesFormData,
    } as IKindyForAllChildInformationForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childFormData, childProgramSchedulesFormData, delayedExitFormData]);

  return (
    <div>
      <Row className="mb-2">
        <Col lg={6}>
          <label>Visa Type</label>
          <Select
            options={visaOptions}
            getOptionLabel={(g) => g.label}
            getOptionValue={(g) => g.value}
            name="visaType"
            value={childFormData?.visaType}
            onChange={(val: VisaType) => {
              handleFormChange(val, 'visaType');
            }}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <EditDelayedExitForm formData={delayedExitFormData} handleChange={handleDelayedExit} />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col justify="start" className="d-flex pb-2 mb-2">
          <Checkbox
            value={childFormData?.nonEnglishSpeakingBackground}
            label={t('subsidies:program-child.create-modal.non-english-speaking-background')}
            onChange={(val: boolean) => {
              handleFormChange(val, 'nonEnglishSpeakingBackground');
            }}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col justify="start" className="d-flex pb-2 mb-2">
          <Checkbox
            value={childFormData?.disability}
            label={t('subsidies:program-child.create-modal.child-lives-with-disability')}
            onChange={(val: boolean) => {
              handleFormChange(val, 'disability');
            }}
          />
        </Col>
      </Row>
      {subsidySchemeId === kindyForAllSubsidySchemeId && (
        <div>
          <Row align="start" className="mb-4">
            <Col lg={6}>
              <Checkbox
                value={childFormData?.kinship}
                label={t('subsidies:program-child.create-modal.child-is-in-formal-kinship')}
                onChange={(val: boolean) => {
                  handleKinshipChange(val);
                }}
              />
            </Col>
            <Col lg={6}>
              <DateInput
                label={t('subsidies:program-child.create-modal.kinship-care-start-date')}
                className="max-width-unset"
                date={childFormData.kinship ? childFormData?.formalKinshipStartDate : null}
                onDateSelect={(value) => {
                  handleFormChange(value, 'formalKinshipStartDate');
                }}
                disabled={!childFormData?.kinship ?? true}
                required={childFormData?.kinship}
              />
            </Col>
          </Row>
          <Row align="start" className="mb-4">
            <Col lg={6}>
              <Checkbox
                value={childFormData?.formalProtectionCare}
                label={t('subsidies:program-child.create-modal.child-is-in-formal-protection-care')}
                onChange={(val: boolean) => {
                  handleFormalProtectionCareChange(val);
                }}
              />
            </Col>
            <Col lg={6}>
              <DateInput
                label={t('subsidies:program-child.create-modal.formal-protection-care-start-date')}
                className="max-width-unset"
                date={childFormData.formalProtectionCare ? childFormData?.formalProtectionCareStartDate : null}
                onDateSelect={(value) => {
                  handleFormChange(value, 'formalProtectionCareStartDate');
                }}
                disabled={!childFormData?.formalProtectionCare ?? true}
                required={childFormData?.formalProtectionCare}
              />
            </Col>
          </Row>
          <Row align="start" className="mb-4">
            <Col lg={6}>
              <Checkbox
                value={childFormData?.multiBirth}
                label={t('subsidies:program-child.create-modal.child-is-a-multi-birth-child')}
                onChange={(val: boolean) => {
                  handleMultiBirthChange(val);
                }}
              />
            </Col>
            <Col>
              <DateInput
                label={t('subsidies:program-child.create-modal.multi-birth-start-date')}
                className="max-width-unset"
                date={childFormData.multiBirth ? childFormData?.multiBirthStartDate : null}
                onDateSelect={(value) => {
                  handleFormChange(value, 'multiBirthStartDate');
                }}
                disabled={!childFormData?.multiBirth ?? true}
                required={childFormData?.multiBirth}
              />
            </Col>
          </Row>
        </div>
      )}
      <Row align="start" className="mb-4">
        <Col lg={6}>
          <Checkbox
            value={childFormData?.hasCancellation}
            label={t('subsidies:program-child.create-modal.has-cancellation')}
            onChange={(val: boolean) => {
              handleHasCancellation(val);
            }}
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <Select
                label={t('subsidies:program-child.create-modal.cancellation-reason')}
                options={cancellationReasonOptions}
                getOptionLabel={(g) => g.label}
                getOptionValue={(g) => g.value}
                name="cancellationReason"
                value={childFormData?.cancellationReason}
                onChange={(val: CancellationReason) => {
                  handleFormChange(val, 'cancellationReason');
                }}
                disabled={!childFormData?.hasCancellation ?? true}
                required={childFormData?.hasCancellation}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <DateInput
              label={t('subsidies:program-child.create-modal.cancellation-date')}
              className="max-width-unset"
              date={childFormData.cancellationDate ? childFormData?.cancellationDate : null}
              onDateSelect={(value) => {
                handleFormChange(value, 'cancellationDate');
              }}
              disabled={!childFormData?.hasCancellation ?? true}
              required={childFormData?.hasCancellation}
            />
          </Row>
        </Col>
      </Row>
      <Card header={t('subsidies:automated-funding.child.title')}>
        <Row>
          <KindyFundingSchedules
            subsidySchemeId={subsidySchemeId}
            formData={childProgramSchedulesFormData.schedules}
            handleChange={handleChildSchedules}
            key={accountChild.id}
          />
        </Row>
      </Card>
    </div>
  );
};

export default QGrantsChildInformationForm;
