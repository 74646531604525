import React from 'react';
import { Circle } from 'shared/components/Shapes';
import { statusColorHexes } from 'shared/constants/tagColors';
import './_tag.scss';

interface IProps {
  className?: string;
  status: 'Active' | 'Inactive';
}

const Tag: React.FC<IProps> = ({ className, status }) => {
  return (
    <div className={`tag px-4 ${className || ''}`}>
      <div className="mr-2 d-flex">
        <Circle className="my-auto" size="xxs" background={statusColorHexes[status]} />
      </div>
      {status}
    </div>
  );
};

export default Tag;
