import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import ActionDropdown from 'shared/components/ActionDropdown';
import DataTable, { TableSearch, TABLE_DEFAULTS } from 'shared/components/DataTable';
import { RootState } from 'store/reducers';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetPaginatedTrainingTimeEventsForCenter } from 'gql/trainingTimeEvent/queries';
import { debounce } from 'lodash';
import { DateRangeInput } from 'shared/components/DateInput';
import { getTrainingTimeEvents } from '../../duck/actions';
import { TrainingHolidayEvent } from '../TrainingTimeEventModal';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  hasEditPermission: boolean;
  hasDeletePermission: boolean;
  onRowUpdate: (row: ITrainingHolidayEvent) => void;
  onRowDelete: (row: ITrainingHolidayEvent) => void;
}

const TrainingHolidayTable: React.FC<IProps> = ({
  hasEditPermission,
  hasDeletePermission,
  onRowUpdate,
  onRowDelete,
  ...props
}) => {
  const dispatch = useDispatch();
  const tableData = useSelector((state: RootState) => state.timelog.trainingTimeEvents);

  const tableFilters = useSelector((state: RootState) => state.timelog.tableFilters);
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const centerTimezone = timezonesByCenter[currentCenterId ?? ''] ?? moment.tz.guess();
  const [timeframe, setTimeframe] = useState({
    start: moment(tableFilters.date).tz(centerTimezone).day(1).startOf('year').format('YYYY-MM-DD'),
    end: moment(tableFilters.date).tz(centerTimezone).day(31).endOf('year').format('YYYY-MM-DD'),
  });
  const [tableState, tableFunctions] = useDatatableState();
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'date',
    direction: 'desc',
  }); // default sort column
  const [searchTerm, setSearchTerm] = useState('');

  const handleChangeSizePerPage = (value: number) => {
    tableFunctions.changeSizePerPage(value);
    // after changing size per page, update the current page to page 1
    // this way the system will not think you are on a page that may no longer 'exist'
    tableFunctions.changePage(1, value);
  };

  const handleSearchTerm = debounce(
    useCallback(
      (term: string) => {
        tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
        setSearchTerm(term);
      },
      [tableFunctions]
    ),
    250
  );

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortField({ field: field, direction: direction === 'ASCENDING' ? 'asc' : 'desc' });
  };

  const { data, loading } = useGetPaginatedTrainingTimeEventsForCenter({
    skip: !currentCenterId,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        centerId: currentCenterId ?? '',
        startDate: timeframe.start,
        endDate: timeframe.end,
        searchKey: searchTerm,
        sortBy: sortField.field,
        sortDirection: sortField.direction,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
      },
    },
    onCompleted: (result) => {
      dispatch(getTrainingTimeEvents(result.getPaginatedTrainingTimeEventsForCenter?.data ?? []));
    },
  });

  const centerData = data?.getPaginatedTrainingTimeEventsForCenter;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <DataTable
      noPadding
      data={tableData}
      dataSize={centerData?.totalRecords ?? 0}
      pageSize={tableState.pageSize}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={(number) => handleChangeSizePerPage(number)}
      activePage={tableState.activePage}
      onSort={handleSort}
      showLoadingOverlay={loading}
      showSelect={false}
      columns={[
        {
          headerAttrs: {
            id: 'training-holiday-name-header-column-cell',
          },
          text: 'Name',
          sort: true,
          dataField: 'name',
        },
        {
          headerAttrs: {
            id: 'training-holiday-type-header-column-cell',
          },
          text: 'Type',
          sort: true,
          dataField: 'type',
          formatter: (cell: any, row: ITrainingHolidayEvent) => {
            const event = new TrainingHolidayEvent(row);

            return event.typeToString();
          },
        },
        {
          headerAttrs: {
            id: 'training-holiday-date-header-column-cell',
          },
          text: 'Date',
          sort: true,
          dataField: 'date',
          formatter: (date: string) => moment(date).format('MMM D, YYYY'),
        },
        {
          headerAttrs: {
            id: 'training-holiday-hours-header-column-cell',
          },
          text: 'Hours',
          sort: true,
          dataField: 'hours',
        },
        {
          headerAttrs: {
            id: 'training-holiday-employees-header-column-cell',
          },
          text: 'Employees',
          sort: true,
          dataField: 'staffIds.length',
        },
        ...[
          hasEditPermission || hasDeletePermission
            ? {
                text: 'Actions',
                align: 'center',
                dataField: '',
                headerClasses: 'text-center',
                formatter: (cell: any, row: ITrainingHolidayEvent) => (
                  <ActionDropdown
                    actions={[
                      ...(hasEditPermission ? [{ label: 'Edit', onClick: () => onRowUpdate(row) }] : []),
                      ...(hasDeletePermission ? [{ label: 'Delete', onClick: () => onRowDelete(row) }] : []),
                    ]}
                  />
                ),
              }
            : {},
        ],
      ]}
      renderHeader={(paginationProps: any) => (
        <div className="d-flex flex-row flex-wrap align-items-center bg-white">
          <Col md={4} sm={12}>
            <div className="d-flex flex-direction-row align-items-center">
              <TableSearch
                className={isMobile ? 'align-items-center mt-2' : 'mr-4 align-items-center flex-grow-0 min-width-200'}
                placeholder="Search"
                onChange={handleSearchTerm}
              />
            </div>
          </Col>
          <Col md={4} sm={12} className="d-flex">
            <div className="d-flex flex-direction-row justify-content-center align-items-center">
              <DateRangeInput
                startDate={timeframe.start}
                endDate={timeframe.end}
                onChange={(dates) => setTimeframe({ start: dates.startDate, end: dates.endDate })}
                className="my-2 kt-date-input-no-max-width align-items-center"
              />
            </div>
          </Col>
        </div>
      )}
    />
  );
};

export default TrainingHolidayTable;
