import React, { useMemo } from 'react';
import { Col, Row } from 'shared/components/Layout';
import Container from 'react-bootstrap/Container';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import Switch from 'shared/components/Switch';
import { useTranslation } from 'react-i18next';
import { IFormDataShape } from '../../context/ProgramsContext';
import DayInput from 'shared/components/DayInput';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import TimeInput from 'shared/components/TimePicker/TimeInput';
import Alert from 'shared/components/Alert';
import Select from 'shared/components/Select';
import { EnrollmentProgramGroup } from 'generated/graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProgramDetailsProps {
  show: boolean;
  formData: IFormDataShape;
  isDateValid: boolean;
  programGroups?: EnrollmentProgramGroup[];
  setFormData: React.Dispatch<React.SetStateAction<IFormDataShape>>;
  formErrors: Partial<Record<keyof IFormDataShape, string | null>>;
}

const ProgramDetails: React.FC<IProgramDetailsProps> = ({
  show,
  formData,
  isDateValid,
  formErrors,
  programGroups = [],
  setFormData,
}) => {
  const { t } = useTranslation();

  const handleEnrolmentOpenDateChange = (v: string) => {
    if (!v) {
      setFormData({
        ...formData,
        enrolmentOpenDate: null,
        enrolmentCloseDate: null,
        enrolmentOpenTime: null,
        enrolmentCloseTime: null,
      });
      return;
    }
    if (moment(v).isAfter(formData.enrolmentCloseDate)) {
      setFormData({ ...formData, enrolmentOpenDate: v, enrolmentCloseDate: null, enrolmentCloseTime: null });
      return;
    }

    setFormData({ ...formData, enrolmentOpenDate: v });
  };

  const handleEnrolmentCloseDateChange = (v: string) => {
    if (!v) {
      setFormData({
        ...formData,
        enrolmentCloseDate: null,
        enrolmentCloseTime: null,
      });
      return;
    }

    setFormData({ ...formData, enrolmentCloseDate: v });
  };

  const showEnrolmentNotOpenAlert = useMemo(() => {
    // open date is empty
    if (!formData.enrolmentOpenDate) {
      return true;
    }

    // open date is set to the future
    if (formData.enrolmentOpenDate && moment().isBefore(formData.enrolmentOpenDate, 'day')) {
      return true;
    }

    // enrollment close date has passed
    if (formData.enrolmentCloseDate && moment().isAfter(formData.enrolmentCloseDate, 'day')) {
      return true;
    }

    return false;
  }, [formData.enrolmentOpenDate, formData.enrolmentCloseDate]);

  const programGroupOptions = programGroups?.map((group) => ({ value: group?.id, label: group?.name })) ?? [];
  return (
    <Container fluid className={`${show ? 'd-block' : 'd-none'}`}>
      {showEnrolmentNotOpenAlert && (
        <Row>
          <Col>
            <Alert variant="warning" className="shadow-none mb-4">
              {t('enrollment.programs.modal.enrollment-not-open-help-msg')}
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        <Col md={12}>
          <TextInput
            required
            label={t('enrollment.programs.modal.name')}
            value={formData.name}
            onChange={(v) => setFormData({ ...formData, name: v })}
            placeholder={t('enrollment.programs.modal.name')}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <TextInput
            required
            as="textarea"
            label={t('enrollment.programs.description')}
            value={formData.description}
            onChange={(value) => setFormData({ ...formData, description: value })}
            rows={3}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <DateInput
            required
            label={`Program ${t('spelling.start-date')}`}
            date={formData.startDate}
            onDateSelect={(v) => setFormData({ ...formData, startDate: v })}
            isValid={isDateValid}
            hasCustomValidation
            className="flex-grow-0"
            dateOnly={true}
          />
        </Col>
        <Col md={6}>
          <DateInput
            required
            label={`Program ${t('spelling.end-date')}`}
            date={formData.endDate}
            onDateSelect={(v) => setFormData({ ...formData, endDate: v })}
            isValid={isDateValid}
            dateOnly={true}
            hasCustomValidation
            className="flex-grow-0"
          />
        </Col>
      </Row>
      <Row align="start" className="mt-2">
        <Col>
          <p className="enrollment-programs-modal-info pb-0 mb-0 mt-1">
            {t('enrollment.programs.modal.open-enrollment-period')}
          </p>
          <small>{t('enrollment.programs.modal.enrollment-period-timezone-help-msg')}</small>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <DateInput
            disabled={isEmpty(formData.endDate) || isEmpty(formData.startDate)}
            label={t('enrollment.programs.enrollment-open-date')}
            date={formData.enrolmentOpenDate}
            onDateSelect={handleEnrolmentOpenDateChange}
            isValid={isEmpty(formErrors.enrolmentOpenDate)}
            hasCustomValidation
            className="flex-grow-0"
            dateOnly={true}
            isOutsideRange={(date) => date.isAfter(moment(formData.endDate).add(1, 'days'))}
          />
          {formErrors.enrolmentOpenDate && (
            <small className="text-danger d-block">{formErrors.enrolmentOpenDate}</small>
          )}
        </Col>
        <Col md={6}>
          <Form.Label>{t('enrollment.programs.enrollment-open-time')}</Form.Label>
          <TimeInput
            key={formData.enrolmentOpenTime}
            isAM
            value={formData.enrolmentOpenTime}
            onChange={(v) => {
              if (/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v)) {
                setFormData({ ...formData, enrolmentOpenTime: v });
              } else {
                setFormData({ ...formData, enrolmentOpenTime: null });
              }
            }}
            disabled={!formData.enrolmentOpenDate}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <DateInput
            disabled={isEmpty(formData.enrolmentOpenDate)}
            label={t('enrollment.programs.enrollment-close-date')}
            date={formData.enrolmentCloseDate}
            onDateSelect={handleEnrolmentCloseDateChange}
            isValid={isEmpty(formErrors.enrolmentCloseDate)}
            dateOnly={true}
            hasCustomValidation
            className="flex-grow-0"
            isOutsideRange={(date) =>
              date.isSameOrBefore(formData.enrolmentOpenDate) || date.isAfter(moment(formData.endDate).add(1, 'days'))
            }
          />
          {formErrors.enrolmentCloseDate && (
            <small className="text-danger d-block">{formErrors.enrolmentCloseDate}</small>
          )}
        </Col>
        <Col md={6}>
          <Form.Label>{t('enrollment.programs.enrollment-close-time')}</Form.Label>
          <TimeInput
            key={formData.enrolmentCloseTime}
            isAM
            value={formData.enrolmentCloseTime}
            onChange={(v) => {
              if (/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v)) {
                setFormData({ ...formData, enrolmentCloseTime: v });
              } else {
                setFormData({ ...formData, enrolmentCloseTime: null });
              }
            }}
            disabled={!formData.enrolmentCloseDate}
          />
        </Col>
      </Row>
      <Row align="start">
        <Col>
          <div>
            <label className="mb-0 mt-2">{t('enrollment.programs.operating-days')}</label>
          </div>
          <DayInput
            value={formData.operatingWeekDays ?? []}
            onDaySelect={(value) => {
              let days = formData.operatingWeekDays ? [...formData.operatingWeekDays] : [];
              if (days.includes(value)) {
                days.splice(days.indexOf(value), 1);
              } else {
                days = [...days, value];
              }
              setFormData({ ...formData, operatingWeekDays: days });
            }}
          />
        </Col>
      </Row>
      <Row align="start">
        <Col>
          <p className="enrollment-programs-modal-info mb-0">{t('enrollment.programs.enrollment-days-meaning-text')}</p>
        </Col>
      </Row>
      <Row align="start">
        <Col lg={6}>
          <NumberInput
            label={t('enrollment.programs.min-enrollment-days')}
            onChange={(m) => {
              let days = formData.operatingWeekDays ? [...formData.operatingWeekDays] : [];
              setFormData({
                ...formData,
                minEnrolmentDays: m,
                operatingWeekDays: days,
              });
            }}
            required
            type="number"
            max="5"
            min="1"
            isInvalid={!isEmpty(formErrors.minEnrolmentDays)}
            errorText={formErrors.minEnrolmentDays ?? ''}
            value={formData.minEnrolmentDays}
          />
        </Col>
        <Col lg={6}>
          <NumberInput
            label={t('enrollment.programs.max-enrollment-days')}
            onChange={(n) => {
              let days = formData.operatingWeekDays ? [...formData.operatingWeekDays] : [];
              setFormData({
                ...formData,
                maxEnrolmentDays: n,
                operatingWeekDays: days,
              });
            }}
            required
            type="number"
            max="5"
            min="1"
            isInvalid={!isEmpty(formErrors.maxEnrolmentDays)}
            errorText={formErrors.maxEnrolmentDays ?? ''}
            value={formData.maxEnrolmentDays}
          />
        </Col>
      </Row>
      <>
        <hr />
        <Row className="mb-4">
          <Col md={6}>
            <p className="enrollment-programs-modal-info pb-0 mb-2 mt-1">
              {t('enrollment.programs.modal.include-in-group')}
            </p>
            <Select
              value={formData.programGroupId}
              onChange={(data) => {
                setFormData({
                  ...formData,
                  programGroupId: data.value,
                });
              }}
              options={programGroupOptions}
            />
          </Col>
          <small>{t('enrollment.programs.modal.include-in-group-help-msg')}</small>
        </Row>
        <hr />{' '}
      </>
      <Row className="mb-4">
        <Col md={6}>
          <Switch
            label={t('enrollment.programs.modal.include-casual-contract-count')}
            labelSide="left"
            value={formData.includeCasualContractCount ?? false}
            onChange={(v) => setFormData({ ...formData, includeCasualContractCount: v })}
            className="ml-auto"
            checkedText="Yes"
            uncheckedText="No"
            height={30}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProgramDetails;
