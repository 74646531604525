export const GET_DISCOUNTS_SUCCESS = 'GET_DISCOUNTS_SUCCESS';
export const CREATE_DISCOUNT_SUCCESS = 'CREATE_DISCOUNT_SUCCESS';
export const UPDATE_DISCOUNT_SUCCESS = 'UPDATE_DISCOUNT_SUCCESS';

interface IGetDiscountsSuccess {
  type: typeof GET_DISCOUNTS_SUCCESS;
  discounts: IDiscount[];
}

interface ICreateDiscountSuccess {
  type: typeof CREATE_DISCOUNT_SUCCESS;
  discount: IDiscount;
}

interface IUpdateDiscountSuccess {
  type: typeof UPDATE_DISCOUNT_SUCCESS;
  discount: IDiscount;
}

export type DiscountActionTypes = IGetDiscountsSuccess | ICreateDiscountSuccess | IUpdateDiscountSuccess;
