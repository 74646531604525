import React, { useContext } from 'react';
import { Field, Page, Section } from 'shared/types/enrollment-form';
import KtField from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/page-section/field-container/kt-field/kt-field';
import { Col, Row } from 'shared/components/Layout';
import { CreateButton } from 'shared/components/Buttons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { EnrolmentFormDetailContext } from '../../enrollment-form-detail';
import classNames from 'classnames';
import { calculateNameSpaceByPage, getConditionalSectionsBySubscribeField } from '../../utils';
import { CustomFieldType } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import PageSection from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/page-section/page-section';
import { v4 as uuidv4 } from 'uuid';
import { Draggable, Droppable } from 'react-beautiful-dnd';

export type FieldContainerProps = {
  fields: Field[];
  onFieldsChanged: (fields: Field[]) => void;
  page: Page;
  onPageChanged: (page: Page) => void;
  allowAddConditionalSectionToField: boolean;
  openAddNewQuestionModel: () => void;
  fieldSetId: string;
};

export default function FieldContainer({
  fields,
  onFieldsChanged,
  page,
  onPageChanged,
  allowAddConditionalSectionToField,
  openAddNewQuestionModel,
  fieldSetId,
}: FieldContainerProps) {
  const { t } = useTranslation(['enrollment']);
  const enrolmentFormDetailContext = useContext(EnrolmentFormDetailContext);
  const fieldState = useSelector(
    (root: RootState) => root.enrolmentFormFieldState[enrolmentFormDetailContext.enrolmentFormId] ?? {}
  );

  const addConditionalSectionToField = (fieldId: string) => {
    const id = uuidv4();
    const newConditionalSection: Section = {
      id,
      label: id,
      hideTitle: true,
      fields: [],
      namespace: calculateNameSpaceByPage(page),
      clientSideBehaviours: {
        clearOnReverse: true,
        subscribeField: fieldId,
        whenValueIs: undefined,
        whenConditionMet: 'show',
      },
    };
    onPageChanged({
      ...page,
      sections: [...page.sections, newConditionalSection],
    });
  };

  const isFieldInSideConditionalSection = !allowAddConditionalSectionToField;

  return (
    <Droppable droppableId={fieldSetId} type={isFieldInSideConditionalSection ? 'conditional-section' : 'section'}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={{}}
          {...provided.droppableProps}
          className={classNames({
            'p-2': true,
            'is-dragging-over': snapshot.isDraggingOver,
          })}
        >
          {' '}
          <div className="field-container">
            {fields.map((value, index, array) => {
              const subscribedSections = getConditionalSectionsBySubscribeField(page, value.name);
              return (
                <Draggable draggableId={value.name} index={index} key={value.name}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={classNames({
                        'p-1': true,
                        'is-dragging': snapshot.isDragging,
                      })}
                    >
                      <div
                        className={classNames({
                          'field-item': true,
                          'field-item--invalid':
                            fieldState[value.name]?.isValid === false || fieldState[value.name]?.isArchived,
                          'field-item--nested': isFieldInSideConditionalSection,
                        })}
                      >
                        <Row>
                          <Col>
                            <KtField
                              draggableProvidedDragHandleProps={provided.dragHandleProps}
                              sectionType={page.sectionType}
                              field={value}
                              onFieldChanged={(field) => {
                                const fields = [...array];
                                if (field) {
                                  fields.splice(index, 1, field);
                                } else {
                                  fields.splice(index, 1);
                                }
                                onFieldsChanged(fields);
                              }}
                            />
                            {subscribedSections.map((s) => (
                              <PageSection
                                removeSectionDialog={{
                                  title: t('enrollment:form.remove-conditional-section-modal.title'),
                                  message: t('enrollment:form.remove-conditional-section-modal.message'),
                                }}
                                page={page}
                                onPageChanged={onPageChanged}
                                sectionIndex={s.index}
                                openAddNewQuestionModel={openAddNewQuestionModel}
                                attachedField={value}
                                className="ml-6 mt-1"
                              />
                            ))}

                            {[CustomFieldType.Checkbox, CustomFieldType.Combo, CustomFieldType.Radio].includes(
                              value.type as CustomFieldType
                            ) &&
                              allowAddConditionalSectionToField && (
                                <Row className="mt-3">
                                  <CreateButton
                                    variant="outline-secondary"
                                    onClick={() => addConditionalSectionToField(value.name)}
                                  >
                                    {t('enrollment:form.add-conditional-section-button')}
                                  </CreateButton>
                                </Row>
                              )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
}
