import { useState, useCallback } from 'react';
import { TABLE_DEFAULTS } from 'shared/components/DataTable';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';

export interface IDatatableState {
  currentItemOffset: number;
  activePage: number;
  pageSize: number;
  selectedRows: any;
  searchExpressions: ISearchExpression[];
}

export interface IStateControls {
  changePage: (page: number, sizePerPage: number) => void;
  changeSizePerPage: (sizePerPage: number) => void;
  updateSelectedRows: (rows: any) => void;
  updateSearchExpressions: (searchExpressions: ISearchExpression[]) => void;
  getElasticQuery: (filter: ITableFiltersMap, sort: IElasticsearchSortFilter) => IElasticsearchQuery;
}

// hook to simply provide a consistent object pattern for keep track of a datatable's state
const useDatatableState = (): [
  IDatatableState,
  IStateControls,
  React.Dispatch<React.SetStateAction<IDatatableState>>
] => {
  const defaultTableState: IDatatableState = {
    currentItemOffset: TABLE_DEFAULTS.ITEM_OFFSET,
    activePage: TABLE_DEFAULTS.PAGE,
    pageSize: TABLE_DEFAULTS.PAGE_SIZE,
    selectedRows: [],
    searchExpressions: [],
  };
  const [datatableState, setDatatableState] = useState<IDatatableState>(defaultTableState);

  // handle table page changes
  const changePage = useCallback((page: number, sizePerPage: number) => {
    const newOffset: number = Math.max(0, (page - 1) * sizePerPage);
    setDatatableState((current) => ({
      ...current,
      activePage: page,
      currentItemOffset: newOffset,
    }));
  }, []);

  // handle change the number of items to show on a page
  const changeSizePerPage = useCallback((sizePerPage: number) => {
    setDatatableState((current) => ({
      ...current,
      pageSize: sizePerPage,
    }));
  }, []);

  const updateSelectedRows = useCallback((rows: any[]) => {
    setDatatableState((current) => ({
      ...current,
      selectedRows: rows,
    }));
  }, []);

  // apply new search term, will resest the page back to 1
  const updateSearchExpressions = useCallback((searchExpressions: ISearchExpression[]) => {
    setDatatableState((current) => ({
      ...current,
      currentItemOffset: TABLE_DEFAULTS.ITEM_OFFSET,
      activePage: TABLE_DEFAULTS.PAGE,
      searchExpressions,
    }));
  }, []);

  const getElasticQuery = useCallback(
    (filter: ITableFiltersMap, sort: IElasticsearchSortFilter) => {
      const filterValues = Object.values(filter);
      const filterExpressions = filterValues.some((f) => f?.length)
        ? filterValues.map((filters) => ({
            [SEARCH_EXPRESSIONS.ANY]: filters?.map((filter) => filter.searchExpression) || [],
          }))
        : [];

      return {
        filter: { [SEARCH_EXPRESSIONS.ALL]: [...datatableState.searchExpressions, ...filterExpressions] },
        sort: [sort],
        size: datatableState.pageSize,
        from: datatableState.currentItemOffset,
      };
    },
    [datatableState]
  );

  return [
    datatableState,
    {
      changePage,
      changeSizePerPage,
      updateSelectedRows,
      updateSearchExpressions,
      getElasticQuery,
    },
    setDatatableState, // can be used directly to update the state if the provided controls don't satisfy your needs
  ];
};

export default useDatatableState;
