import { glCodeMappingFields } from 'gql/glCode/fields';

export const discountScheduleFields: string = `
  id
  startDate
  endDate
  amount
  amountType
  discountId
`;

export const discountFields: string = `
  id
  businessId
  centerIds
  name
  glCode
  description
  type
  appliesTo
  schedules {
    ${discountScheduleFields}
  }
  glCodeMapping {
    ${glCodeMappingFields}
  }
  applySessionGapDiscountIfNoSubsidy
`;

export const appliedAccountDiscountFields: string = `
  id
  discountId
  discountName
  accountId
  accountChildId
  startDate
  endDate
  createdAt
  createdBy
  createdByPerson {
    id
    firstname
    nickname
    lastname
    avatar {
      url
    }
  }
  discountAuth
`;
