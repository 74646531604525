import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';

import NoteTableRow from './NoteTableRow';
import AddNoteThread from './AddNoteThread';
import { RootState } from 'store/reducers';
import { useSearchNotes } from './gql/query';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';

const styles = {
  iconButton: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
};

interface IProps {
  personId: string;
}

const columns = [
  { name: 'Title', field: 'Title', displayLabel: true, alignLeft: true, sortable: true },
  { name: 'Type', field: 'noteType', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'Last Updated', field: 'lastCommentedAt', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'Last Updated By', field: 'lastCommentedAt', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'Row Actions', field: 'id', displayLabel: false, alignLeft: false, sortable: false },
];

interface ISortBy {
  field: string;
  direction: SortDirection;
}
type SortDirection = 'ASCENDING' | 'DESCENDING';

const InternalNotes: React.FC<IProps> = ({ personId }) => {
  const [openAddNote, setOpenAddNote] = React.useState(false);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const [page, setPage] = React.useState(0);
  const [from, setFrom] = React.useState(0);
  const [sort, setSort] = React.useState<ISortBy>({
    field: 'CreatedAt',
    direction: 'DESCENDING',
  });

  const {
    data: notes,
    loading: loadingNotes,
    refetch: refetchNotes,
  } = useSearchNotes({
    skip: isEmpty(businessId),
    variables: {
      input: {
        businessId: businessId,
        areaType: 'Staff',
        attachedToEntityId: personId,
        from: from,
        sortDirection: sort.direction,
        sortField: sort.field,
      },
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setFrom(newPage + 1);
  };

  const changeSort = (field: string) => {
    setSort({
      field: field,
      direction: sort.direction === 'ASCENDING' ? 'DESCENDING' : 'ASCENDING',
    });
  };

  return (
    <div>
      <HasRoleAreaLevel
        action={{ area: AreaType.Staff, permission: PermissionType.Notes, level: RoleLevelType.Create }}
      >
        <Mui.Box display="flex" justifyContent="flex-end">
          <Mui.Button
            color="secondary"
            onClick={() => setOpenAddNote(true)}
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            variant="contained"
          >
            Add Note Thread
          </Mui.Button>
        </Mui.Box>
      </HasRoleAreaLevel>
      <SpinnerOverlay show={loadingNotes}>
        {(notes?.searchNote?.totalRecords ?? 0) > 0 ? (
          <Mui.Box sx={{ width: '100%' }}>
            <Mui.Paper sx={{ mb: 2 }} elevation={0}>
              <Mui.TableContainer>
                <Mui.Table aria-labelledby="account-notes-table" style={{ width: '100%' }}>
                  <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
                    {columns.map((column) => (
                      <Mui.TableCell key={column.name} align={column.alignLeft ? 'left' : 'center'} padding={'normal'}>
                        <Mui.Typography variant="tableHeadCells">
                          {column.displayLabel && column.name}
                          {column.sortable && (
                            <Mui.IconButton
                              sx={styles.iconButton}
                              disableRipple
                              onClick={() => changeSort(column.field)}
                            >
                              <FontAwesomeIcon
                                size="xs"
                                icon={
                                  sort.field === column.field
                                    ? sort.direction === 'ASCENDING'
                                      ? faAngleDown
                                      : faAngleUp
                                    : faAngleDown
                                }
                              />
                            </Mui.IconButton>
                          )}
                        </Mui.Typography>
                      </Mui.TableCell>
                    ))}
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {notes?.searchNote?.data.map((row, index) => (
                      <NoteTableRow key={row.title} row={row} index={index} refetch={refetchNotes} />
                    ))}
                  </Mui.TableBody>
                </Mui.Table>
              </Mui.TableContainer>
              <Mui.TablePagination
                sx={{
                  '.MuiTablePagination-displayedRows': {
                    margin: 0,
                    padding: 0,
                  },
                }}
                rowsPerPageOptions={[10]}
                component="div"
                count={notes?.searchNote.totalRecords ?? 0}
                rowsPerPage={notes?.searchNote.pageSize ?? 0}
                page={page}
                onPageChange={handleChangePage}
              />
            </Mui.Paper>
          </Mui.Box>
        ) : (
          <Mui.Box marginTop={2}>
            <Mui.Stack spacing={2} direction="column" justifyContent="center" alignItems="center">
              <FontAwesomeIcon icon={faPencilAlt} size={'5x'} />
              <Mui.Typography>There are no Notes available to display.</Mui.Typography>
            </Mui.Stack>
          </Mui.Box>
        )}
      </SpinnerOverlay>
      <AddNoteThread open={openAddNote} setOpen={setOpenAddNote} refetch={refetchNotes} personId={personId} />
    </div>
  );
};

export default InternalNotes;
