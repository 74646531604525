import { all } from 'redux-saga/effects';
import { loginSagas } from '../pages/Login/duck/sagas';
import { accountSagas } from '../pages/Account/duck/sagas';
import { recoverPasswordSagas } from '../pages/RecoverPassword/duck/sagas';
import { userSagas } from './user/sagas';
import { entitiesSagas } from './entities/sagas';

export default function* KangarootimeSagas() {
  yield all([...loginSagas, ...recoverPasswordSagas, ...accountSagas, ...userSagas, ...entitiesSagas]);
}
