import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EnrollmentProgramGroup } from 'generated/graphql';
import useGetEnrollmentProgramTable from '../../../hooks/useGetEnrollmentProgramTable';
import ProgramGroupModal from '../ProgramGroupModal/ProgramGroupModal';
import { useEditEnrollmentProgramGroup } from '../../../hooks/useEditEnrollmentProgramGroup';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  programGroup?: EnrollmentProgramGroup;
  refetchProgramGroups: () => void;
}
const initialProgramGroupInput = {
  id: '',
  isActive: false,
  name: '',
  description: '',
  businessId: '',
  programIds: [],
};

const EditProgramGroupModal: React.FC<IProps> = ({
  isModalOpen,
  handleCloseModal,
  programGroup,
  refetchProgramGroups,
}) => {
  const { t } = useTranslation();

  const { tableProps } = useGetEnrollmentProgramTable({
    withNoProgramGroup: true,
    pageSize: 10,
    programGroupId: programGroup?.id,
    onCompleted: (data) => {
      const programs = data.getEnrollmentPrograms?.data;
      if (programs && programGroup) {
        const selected = programs.filter((p) => programGroup.programIds?.includes(p.id)) ?? [];
        tableProps?.tableFunctions.updateSelectedRows([...tableProps.tableState.selectedRows, ...selected]);
      }
    },
  });

  const onCompleted = () => {
    handleClose();
    refetchProgramGroups();
    showToast(t('enrollment.program-groups.edit-program-group-success'), 'success');
  };

  const { loading, values, businessTimeZone, onSubmit, onFieldUpdate, setValues } = useEditEnrollmentProgramGroup({
    programGroup,
    tableProps,
    onCompleted,
  });

  const handleClose = useCallback(() => {
    handleCloseModal();
    tableProps.tableFunctions.changePage(1, 10);
    tableProps.tableFunctions.updateSelectedRows([]);
    setValues(initialProgramGroupInput);
  }, [handleCloseModal, tableProps?.tableFunctions]);

  return (
    <ProgramGroupModal
      tableProps={tableProps}
      loading={loading}
      businessTimeZone={businessTimeZone}
      values={values}
      handleClose={handleClose}
      onFieldUpdate={onFieldUpdate}
      title={t('enrollment.program-groups.edit-program-group')}
      isModalOpen={isModalOpen}
      onSubmit={onSubmit}
    />
  );
};
export default EditProgramGroupModal;
