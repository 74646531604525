export const spaceFields: string = `
  id
  name
  centerId
  center {
    id
    name
  }
  icon
  color
`;

// not sure about  pocState: PocSpaceState
