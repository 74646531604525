import * as types from './types';

export const setEntities = (entities: IEntity[]): types.EntityActionTypes => ({
  type: types.SET_ENTITIES,
  entities,
});

export const fetchAllowedEntities = (): types.EntityActionTypes => ({
  type: types.FETCH_ALLOWED_ENTITIES,
});
