import React from 'react';
import TextInput from 'shared/components/TextInput';

interface IProps {
  value?: string | null;
  onChange: (value: string) => void;
  disabled?: boolean;
  required?: boolean;
}

const GlCodeTextInput = ({ value, onChange, disabled = false, required = false }: IProps) => {
  return (
    <TextInput
      label="GL Code"
      name="glcode"
      maxlength="20"
      value={value}
      onChange={onChange}
      disabled={disabled}
      required={required}
    />
  );
};

export default GlCodeTextInput;
