import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import { useGetActiveCenterOptions } from 'shared/hooks/useGetActiveCenters';
import { useGetAgencyOptions } from 'shared/Agency/hooks/useGetAgencyOptions';
import { Form, FormGroup } from 'react-bootstrap';
import { useGetCurrentJwt } from 'shared/hooks/user-context/useGetCurrentJwt';
import config from 'config';
import axios from 'axios';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

interface CreateAgencyPaymentInput {
  agencyId: string;
  centerIds: string[];
  paymentDate: string;
  referenceId: string;
  paymentAmount: number;
  paymentFormat: string;
}
function useCreateAgencyPayment(options?: {
  onCreateSuccess?: (data: any) => void;
  onCreateFailure?: (msg: string) => void;
}) {
  const jwtToken = useGetCurrentJwt();
  const [savingAgencyPayment, setSavingAgencyPayment] = useState<boolean>(false);
  const saveAgencyPayment = useCallback(
    async (agencyPaymentInput: CreateAgencyPaymentInput) => {
      setSavingAgencyPayment(true);
      try {
        const response = await axios.post(`${config.api.billing.uri}/api/v2/agency-payments`, agencyPaymentInput, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });
        console.log('DEV:', response.data);
        if (options?.onCreateSuccess) options.onCreateSuccess(response.data);
      } catch (e) {
        console.error(e);
        if (e instanceof Error && options?.onCreateFailure) {
          options.onCreateFailure(e.message);
        }
      } finally {
        setSavingAgencyPayment(false);
      }
    },
    [jwtToken, options]
  );
  return { saveAgencyPayment, savingAgencyPayment };
}

interface IAgencyPaymentFormShape {
  agencyId: string | null;
  centers: ISelectMenuItem[];
  date: string | null;
  referenceId: string;
  amount: number | null;
  paymentFormat: 'Account' | 'Child';
}

interface IProps {}
const CreateAgencyPayment: React.FC<IProps> = () => {
  const history = useHistory();
  const { t } = useTranslation(['subsidies']);
  const [formData, setFormData] = useState<IAgencyPaymentFormShape>({
    agencyId: null,
    centers: [],
    date: null,
    referenceId: '',
    amount: null,
    paymentFormat: 'Account',
  });

  const { agencyOptions } = useGetAgencyOptions();
  const { centerOptions } = useGetActiveCenterOptions();

  const { saveAgencyPayment } = useCreateAgencyPayment({
    onCreateSuccess: (data: { id: string }) => {
      history.push(`/subsidies/payments/${data.id}`);
    },
    onCreateFailure: (message) => {
      console.error('DEV:', 'failure creating agency payment', message);
    },
  });

  const formValidation = useMemo<{ validationErrors: string[]; isValid: boolean }>(() => {
    const requiredFields = ['agencyId', 'centers', 'date', 'referenceId', 'amount'];
    let validationErrors: string[] = [];
    for (let requiredField of requiredFields) {
      const formValue = formData[requiredField];
      const hasNonEmptyValue = !!formValue;
      if (!hasNonEmptyValue) validationErrors.push(`${requiredField} is required`);
      if (Array.isArray(formValue) && formValue.length === 0)
        validationErrors.push(`must have at least 1 ${requiredField}`);
    }
    const f = { isValid: validationErrors.length === 0, validationErrors };
    console.log('DEV:', 'formValidation', f);
    return f;
  }, [formData]);

  const handleSave = useCallback(() => {
    const { agencyId, centers, amount, referenceId: referenceId, date, paymentFormat } = formData;
    if (agencyId && centers && amount && referenceId && date) {
      saveAgencyPayment({
        agencyId,
        centerIds: formData.centers.map((c) => c.value),
        paymentAmount: amount,
        referenceId,
        paymentDate: moment(date).format('YYYY-MM-DD'),
        paymentFormat: paymentFormat,
      });
    }
  }, [formData, saveAgencyPayment]);

  return (
    <PageWrapper
      pageTitle={t('subsidies:agency-payment.add-agency-payment.page-title')}
      buttonComponent={
        <Button disabled={!formValidation.isValid} onClick={handleSave}>
          {t('subsidies:agency-billing.new-subsidy-payment.save-btn')}
        </Button>
      }
      secondaryButtonComponent={
        <Button variant="light" onClick={() => history.goBack()} className="mr-4">
          {t('subsidies:agency-billing.new-subsidy-payment.cancel-btn')}
        </Button>
      }
    >
      <Card bodyClassName="py-4" className="kt-subsidy-payment-inputs-card">
        <Row>
          <Col>
            <Select
              required
              label={t('subsidies:agency-billing.new-subsidy-payment.agency-label')}
              options={agencyOptions}
              onChange={(opt) => setFormData((prev) => ({ ...prev, agencyId: opt.value, centers: [] }))}
              isLoading={false}
              disabled={false}
              value={agencyOptions.find((agency) => agency.value === formData.agencyId)}
            />
          </Col>
          <Col>
            <Select
              label={t('subsidies:agency-billing.new-subsidy-payment.center-label')}
              isMulti
              required
              value={formData.centers}
              isLoading={false}
              options={centerOptions}
              onChange={(selectedCenterOptions) => {
                setFormData((prev) => ({ ...prev, centers: selectedCenterOptions }));
              }}
            />
          </Col>
          <Col>
            <FormGroup>
              <DateInput
                required
                label={t('subsidies:agency-billing.new-subsidy-payment.payment-date-label')}
                date={formData.date}
                onDateSelect={(date) => {
                  console.log('DEV:', date);
                  setFormData((prev) => ({ ...prev, date }));
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <TextInput
              label={t('subsidies:agency-billing.new-subsidy-payment.reference-number-label')}
              onChange={(value) => setFormData((prev) => ({ ...prev, referenceId: value }))}
              value={formData.referenceId}
              disabled={false}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CurrencyInput
              required
              className="border-radius-0"
              label={t('subsidies:agency-billing.new-subsidy-payment.payment-amount-label')}
              onChange={(value) => setFormData((prev) => ({ ...prev, amount: value }))}
              value={formData.amount}
              disabled={false}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="d-flex flex-row">
                <Form.Label>Payment Format</Form.Label>
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              </div>
              <Form.Check
                inline
                label={t('subsidies:agency-billing.new-subsidy-payment.payment-format.by-account')}
                name="payment-format"
                type="radio"
                checked={formData.paymentFormat === 'Account'}
                onChange={() => setFormData((prev) => ({ ...prev, paymentFormat: 'Account' }))}
              />
              <Form.Check
                inline
                label={t('subsidies:agency-billing.new-subsidy-payment.payment-format.by-child')}
                name="payment-format"
                type="radio"
                checked={formData.paymentFormat === 'Child'}
                onChange={() => setFormData((prev) => ({ ...prev, paymentFormat: 'Child' }))}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card>
    </PageWrapper>
  );
};

export default CreateAgencyPayment;
