import { AccountOverviewV3 } from '../types/AccountOverview';
import { APIError, useBillApi } from './useBillingApi';

export function useGetBillingAccountOverviewV3(params: { accountId: string; take: number }): {
  data?: AccountOverviewV3;
  error?: APIError;
  loading: boolean;
  refetch: () => void;
} {
  return useBillApi('GET', `api/v2/billing-activity/${params.accountId}?take=${params.take}`);
}
