import config from 'config';
import { mapValues } from 'lodash';

interface FieldLabels {
  subdivision: string;
  postalCode: string;
  city: string;
  address2: string;
  center: string;
  enrollment: string;
  enroll: string;
  biWeekly: string;
  utilization: string;
  color: string;
  account: string;
  serviceFeeGroup: string;
  serviceFee: string;
}

interface Country {
  name: String;
  fieldLabels: FieldLabels;
  subdivisions: Record<string, string>;
  dateSettings: {
    week: 'isoWeek' | 'week';
    weekDayStart: number;
    weekDayEnd: number;
  };
}

const COUNTRY_INFO: Record<string, Country> = {
  US: {
    name: 'United States',
    fieldLabels: {
      subdivision: 'State',
      postalCode: 'Zip Code',
      city: 'City',
      address2: 'Ste, Apt',
      center: 'Center',
      account: 'Account',
      enrollment: 'Enrollment',
      enroll: 'Enroll',
      biWeekly: 'Bi-weekly',
      utilization: 'Utilization',
      color: 'Color',
      serviceFeeGroup: 'Service Fee Group',
      serviceFee: 'Service Fee',
    },
    dateSettings: {
      week: 'week',
      weekDayStart: 0,
      weekDayEnd: 6,
    },
    subdivisions: {
      'US-AL': 'Alabama',
      'US-AK': 'Alaska',
      'US-AZ': 'Arizona',
      'US-AR': 'Arkansas',
      'US-CA': 'California',
      'US-CO': 'Colorado',
      'US-CT': 'Connecticut',
      'US-DE': 'Delaware',
      'US-FL': 'Florida',
      'US-GA': 'Georgia',
      'US-HI': 'Hawaii',
      'US-ID': 'Idaho',
      'US-IL': 'Illinois',
      'US-IN': 'Indiana',
      'US-IA': 'Iowa',
      'US-KS': 'Kansas',
      'US-KY': 'Kentucky',
      'US-LA': 'Louisiana',
      'US-ME': 'Maine',
      'US-MD': 'Maryland',
      'US-MA': 'Massachusetts',
      'US-MI': 'Michigan',
      'US-MN': 'Minnesota',
      'US-MS': 'Mississippi',
      'US-MO': 'Missouri',
      'US-MT': 'Montana',
      'US-NE': 'Nebraska',
      'US-NV': 'Nevada',
      'US-NH': 'New Hampshire',
      'US-NJ': 'New Jersey',
      'US-NM': 'New Mexico',
      'US-NY': 'New York',
      'US-NC': 'North Carolina',
      'US-ND': 'North Dakota',
      'US-OH': 'Ohio',
      'US-OK': 'Oklahoma',
      'US-OR': 'Oregon',
      'US-PA': 'Pennsylvania',
      'US-RI': 'Rhode Island',
      'US-SC': 'South Carolina',
      'US-SD': 'South Dakota',
      'US-TN': 'Tennessee',
      'US-TX': 'Texas',
      'US-UT': 'Utah',
      'US-VT': 'Vermont',
      'US-VA': 'Virginia',
      'US-WA': 'Washington',
      'US-WV': 'West Virginia',
      'US-WI': 'Wisconsin',
      'US-WY': 'Wyoming',
      'US-DC': 'District of Columbia',
      'US-AS': 'American Samoa',
      'US-GU': 'Guam',
      'US-MP': 'Northern Mariana Islands',
      'US-PR': 'Puerto Rico',
      'US-VI': 'U.S. Virgin Islands',
      'US-FM': 'Micronesia',
      'US-MH': 'Marshall Islands',
      'US-PW': 'Palau',
      'US-AA': 'U.S. Armed Forces – Americas',
      'US-AE': 'U.S. Armed Forces – Europe',
      'US-AP': 'U.S. Armed Forces – Pacific',
      'US-CM': 'Northern Mariana Islands',
      'US-CZ': 'Panama Canal Zone',
      'US-PI': 'Philippine Islands',
      'US-TT': 'Trust Territory of the Pacific Islands',
    },
  },
  CA: {
    name: 'Canada',
    fieldLabels: {
      subdivision: 'Province',
      postalCode: 'Postal Code',
      city: 'City',
      address2: 'Ste, Apt',
      center: 'Center',
      enrollment: 'Enrollment',
      enroll: 'Enroll',
      biWeekly: 'Bi-weekly',
      utilization: 'Utilization',
      color: 'Colour',
      account: 'Account',
      serviceFeeGroup: 'Service Fee Group',
      serviceFee: 'Service Fee',
    },
    dateSettings: {
      week: 'week',
      weekDayStart: 0,
      weekDayEnd: 6,
    },
    subdivisions: {
      'CA-AB': 'Alberta',
      'CA-BC': 'British Columbia',
      'CA-MB': 'Manitoba',
      'CA-NB': 'New Brunswick',
      'CA-NL': 'Newfoundland',
      'CA-NS': 'Nova Scotia',
      'CA-ON': 'Ontario',
      'CA-PE': 'Prince Edward Island',
      'CA-QC': 'Quebec',
      'CA-SK': 'Saskatchewan',
      'CA-NT': 'Northwest Territories',
      'CA-NU': 'Nunavut',
      'CA-YT': 'Yukon',
    },
  },
  AU: {
    name: 'Australia',
    fieldLabels: {
      subdivision: 'State/Territory',
      postalCode: 'Postcode',
      city: 'Suburb',
      address2: 'Building/Apt',
      center: 'Centre',
      enrollment: 'Enrolment',
      enroll: 'Enrol',
      biWeekly: 'Fortnightly',
      utilization: 'Utilisation',
      color: 'Colour',
      account: 'Account',
      serviceFeeGroup: 'Service Fee Group',
      serviceFee: 'Service Fee',
    },
    dateSettings: {
      week: 'isoWeek',
      weekDayStart: 1,
      weekDayEnd: 7,
    },
    subdivisions: {
      'AU-NSW': 'New South Wales',
      'AU-QLD': 'Queensland',
      'AU-SA': 'South Australia ',
      'AU-TAS': 'Tasmania',
      'AU-VIC': 'Victoria',
      'AU-WA': 'Western Australia',
      'AU-ACT': 'Australian Capital Territory',
      'AU-NT': 'Northern Territory',
    },
  },
};

export default COUNTRY_INFO;

export const DEFAULT_COUNTRY = config.locale.region;

export const DISABLE_COUNTRY = true;

export const STATES: { [key: string]: string } = Object.values(COUNTRY_INFO).reduce(
  (accumulator, country) => ({ ...accumulator, ...country.subdivisions }),
  {}
);
export const STATE_SELECT_OPTIONS = mapValues(COUNTRY_INFO, (country) =>
  Object.entries(country.subdivisions).map((arr) => ({ value: arr[0], label: arr[1] }))
);
export const US_STATE_SELECT_OPTIONS = STATE_SELECT_OPTIONS[DEFAULT_COUNTRY];
