import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { Row } from 'shared/components/Layout';
import { Container } from 'react-bootstrap';
import { lowerCase, set, cloneDeep, get, capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import Select from 'shared/components/Select';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import careTypes, { US_Care_Types } from 'shared/constants/dropdownOptions/careTypes';
import { isRegion } from 'shared/util/region';
import './styles.scss';

const valueTypeOptions = new Array(60).fill(0).map((_, index) => index + 1);
const unitTypeOptions = [
  {
    label: 'hour(s)',
    value: 'HOUR',
  },
  {
    label: 'day(s)',
    value: 'DAY',
  },
  {
    label: 'week(s)',
    value: 'WEEK',
  },
  {
    label: 'month(s)',
    value: 'MONTH',
  },
] as const;

type IFormStateShape = Omit<ICasualBookingSettingsInput, 'classId'>;

const defaultFormState: IFormStateShape = {
  active: true,
  bookingPolicy: undefined,
  cancellationPolicy: undefined,
};

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (setting: Omit<ICasualBookingSettingsInput, 'classId'>) => Promise<void>;
  onClose: () => void;
  initialValue?: IFormStateShape;
  selectedClasses: IClassBookingSettings[];
  classesOptions: ITableFilterOption[];
  onClassesFilterSelect: (values: ITableFilterOption[]) => void;
  onCareTypesFilterSelect: (values: ITableFilterOption[]) => void;
  serveFor: AttendanceSettingsSubTab;
}

const AddBookingRulesModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  onSubmit,
  onClose,
  initialValue = defaultFormState,
  selectedClasses,
  classesOptions,
  onClassesFilterSelect,
  onCareTypesFilterSelect,
  serveFor,
}) => {
  const { t } = useTranslation();
  const centersById = useSelector((state: RootState) => state.centers.byId);
  const [formData, setFormData] = useState<IFormStateShape>(initialValue);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const careTypeOptions = useMemo(() => (isRegion('US') ? US_Care_Types : careTypes), []);
  const [selectedCareTypes, setSelectedCareTypes] = useState(careTypeOptions);

  const handleClose = () => {
    setFormData(defaultFormState);
    setSelectedCareTypes(careTypeOptions);
    onClose();
  };

  useEffect(() => {
    if (!formIsDirty) {
      setFormData(initialValue);
    }
  }, [initialValue, formIsDirty]);

  const handleFormDataChange = useCallback(
    (value: string | number | boolean | undefined, path: string) => {
      const mutableFormData = cloneDeep(formData);
      setFormData(set(mutableFormData, path, value));
      !formIsDirty && setFormIsDirty(true);
    },
    [formData, formIsDirty]
  );

  const renderBookingWindowQuantitySelect = useCallback(
    (value: number | undefined, path: string) => {
      return (
        <Select
          options={valueTypeOptions}
          value={value}
          onChange={(v) => handleFormDataChange(v, path)}
          className="ml-2 my-0 w-120px flex-grow-0 flex-shrink-0"
        />
      );
    },
    [handleFormDataChange]
  );

  const renderBookingWindowUnitSelect = useCallback(
    (value: string | undefined, path: string) => {
      const selectedOption = unitTypeOptions.find((option) => option.value === value);
      return (
        <Select
          options={unitTypeOptions}
          value={selectedOption}
          onChange={(v) => handleFormDataChange(v.value, path)}
          className="ml-2 mr-2 my-0 w-120px flex-grow-0 flex-shrink-0"
        />
      );
    },
    [handleFormDataChange]
  );

  const isFormDataValid = useMemo(() => {
    const paths = [
      'active',
      'bookingPolicy.atLeastInAdvance.quantity',
      'bookingPolicy.atLeastInAdvance.unit',
      'bookingPolicy.atMostInAdvance.quantity',
      'bookingPolicy.atMostInAdvance.unit',
      'cancellationPolicy.casualBooking.quantity',
      'cancellationPolicy.casualBooking.unit',
      'cancellationPolicy.permanentBooking.quantity',
      'cancellationPolicy.permanentBooking.unit',
    ];

    const values = paths.map((path) => get(formData, path));

    // all values are required
    if (values.some((v) => v === undefined)) {
      return false;
    }

    return true;
  }, [formData]);

  const selectedClassDropdownValues = useMemo(
    () =>
      selectedClasses.map((cls) => ({
        value: cls.id,
        label: `${centersById[cls.centerId]?.name ?? ''} - ${cls.name}`,
      })),
    [selectedClasses, centersById]
  );

  const handleCareTypeFilterSelect = useCallback(
    (values: ITableFilterOption[]) => {
      setSelectedCareTypes(values);
      onCareTypesFilterSelect(values);
    },
    [onCareTypesFilterSelect]
  );

  const handleSubmit = (setting: Omit<ICasualBookingSettingsInput, 'classId'>) => {
    onSubmit(setting).then(() => setSelectedCareTypes(careTypeOptions));
  };

  return (
    <CenteredModal
      title={t('attendance.settings.booking-rules.classes.modal.title')}
      size="lg"
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Apply"
      primaryCallback={() => handleSubmit(formData)}
      secondaryCallback={handleClose}
      primaryButtonProps={{ loading: isLoading, disabled: !isFormDataValid || selectedClasses.length === 0 }}
    >
      <Container fluid>
        <Row>
          <h5>{t('attendance.settings.booking-rules.classes.modal.apply-to')}</h5>
        </Row>
        {serveFor === 'centers' && (
          <Row className="mb-2">
            {t('attendance.settings.booking-rules.classes.modal.apply-to-care-type')}
            <DropdownFilter
              title={t('general.care-types')}
              selectedFilters={selectedCareTypes}
              options={careTypeOptions}
              onFilterSelect={handleCareTypeFilterSelect}
              showToolTip={false}
              className="mx-2"
            />
            {'.'}
          </Row>
        )}
        <Row>
          {t('attendance.settings.booking-rules.classes.modal.apply-to-classes')}
          <DropdownFilter
            title={capitalize(t('spelling.classes'))}
            selectedFilters={selectedClassDropdownValues}
            options={classesOptions}
            onFilterSelect={onClassesFilterSelect}
            showToolTip={false}
            className="mx-2"
            menuClassName="dropdown-select-wrap"
            alignRight={false}
          />
          {'.'}
        </Row>
        <Row className="mt-4">
          <h5>{t('attendance.settings.booking-rules.classes.modal.casual-booking-ability')}</h5>
        </Row>
        <Row>
          {t('attendance.settings.booking-rules.classes.modal.casual-booking-ability-label-1')}
          <Select
            name="active"
            options={['Active', 'Inactive']}
            value={formData.active ? 'Active' : 'Inactive'}
            onChange={(v) => handleFormDataChange(v === 'Active' ? true : false, 'active')}
            className="ml-2 mr-2 my-0 w-100px flex-grow-0 flex-shrink-0"
          />
          {'.'}
        </Row>
        <Row className="mt-4">
          <h5>{t('attendance.settings.booking-rules.classes.modal.booking-window')}</h5>
        </Row>
        <Row>
          {t('attendance.settings.booking-rules.classes.modal.booking-window-label-1')}
          {renderBookingWindowQuantitySelect(
            formData.bookingPolicy?.atLeastInAdvance?.quantity,
            'bookingPolicy.atLeastInAdvance.quantity'
          )}
          {renderBookingWindowUnitSelect(
            formData.bookingPolicy?.atLeastInAdvance?.unit,
            'bookingPolicy.atLeastInAdvance.unit'
          )}
          {t('attendance.settings.booking-rules.classes.modal.in-advance')}
        </Row>
        <Row className="mt-2">
          {t('attendance.settings.booking-rules.classes.modal.booking-window-label-2')}
          {renderBookingWindowQuantitySelect(
            formData.bookingPolicy?.atMostInAdvance?.quantity,
            'bookingPolicy.atMostInAdvance.quantity'
          )}
          {renderBookingWindowUnitSelect(
            formData.bookingPolicy?.atMostInAdvance?.unit,
            'bookingPolicy.atMostInAdvance.unit'
          )}
          {t('attendance.settings.booking-rules.classes.modal.in-advance')}.
        </Row>
        <Row className="mt-4">
          <h5>{t('attendance.settings.booking-rules.classes.modal.cancellations')}</h5>
        </Row>
        <Row>
          <span className="font-weight-semi-bold">
            {t('attendance.settings.booking-rules.classes.modal.casual-booking')}&nbsp;
          </span>
          {lowerCase(t('attendance.settings.booking-rules.classes.modal.cancellations'))} {t('spelling.made')}{' '}
          {t('spelling.within')}{' '}
          {renderBookingWindowQuantitySelect(
            formData.cancellationPolicy?.casualBooking?.quantity,
            'cancellationPolicy.casualBooking.quantity'
          )}
          {renderBookingWindowUnitSelect(
            formData.cancellationPolicy?.casualBooking?.unit,
            'cancellationPolicy.casualBooking.unit'
          )}
          <p className="cancellations-description">
            {t('attendance.settings.booking-rules.classes.modal.cancellations-label-1')}
          </p>
        </Row>
        <Row className="mt-2">
          <span className="font-weight-semi-bold">
            {t('attendance.settings.booking-rules.classes.modal.permanent-booking')}&nbsp;
          </span>
          {lowerCase(t('attendance.settings.booking-rules.classes.modal.cancellations'))} {t('spelling.made')}{' '}
          {t('spelling.within')}{' '}
          {renderBookingWindowQuantitySelect(
            formData.cancellationPolicy?.permanentBooking?.quantity,
            'cancellationPolicy.permanentBooking.quantity'
          )}
          {renderBookingWindowUnitSelect(
            formData.cancellationPolicy?.permanentBooking?.unit,
            'cancellationPolicy.permanentBooking.unit'
          )}
          <p className="cancellations-description">
            {t('attendance.settings.booking-rules.classes.modal.cancellations-label-1')}
          </p>
        </Row>
      </Container>
    </CenteredModal>
  );
};

export default AddBookingRulesModal;
