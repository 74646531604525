import React, { useCallback } from 'react';
import { groupBy } from 'lodash';
import DataTable from 'shared/components/DataTable';
import { getFullName } from 'shared/util/string';
import AttendanceTableFinanceActionDropdown from './AttendanceTableFinanceActionDropdown';
import { isRegion } from 'shared/util/region';
import Spinner from 'shared/components/Spinner';
import cast from '../../../shared/util/cast';
import { PersonAvatar } from '../../../shared/components/Avatar';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RateInfo } from './FinanceTable/RateInfo';
import { getRateUnitsLabelFromSession } from './FinanceTable/helpers';

interface ITableRowShape {
  child: IChild;
  class: IClass;
  accountName: string | undefined;
  accountId: string;
  sessionsForWeek: ISession[];
  existingEnrollmentYearAbsences: { id: string }[];
  amount: number | null;
  cost: {
    total: number;
    sessionCost: number;
    discount: number;
    subsidy: number;
    earlyFee: number;
    lateFee: number;
    gap: number;
    charged: string;
  };
}

interface IProps {
  data: ISession[];
  loading: boolean;
  timezone: string;
  startOfWeek: string; // iso string
  onSort: (field: string, direction: 'asc' | 'desc') => void;
  canEditAttendanceFinance: boolean;
  onRemoveFeeForSessions: (type: 'early' | 'late', sessions: ISession[]) => void;
  onRestoreFeeForSessions: (type: 'early' | 'late', sessions: ISession[]) => void;
  onCharge: (sessions: ISession[]) => void;
  childAndClassIdsInLoadingState: ISessionCharge[];
}

const WeeklyAttendanceFinanceTable: React.FC<IProps> = ({
  data,
  loading,
  timezone,
  startOfWeek,
  onSort,
  canEditAttendanceFinance,
  onRemoveFeeForSessions,
  onRestoreFeeForSessions,
  onCharge,
  childAndClassIdsInLoadingState,
  ...props
}) => {
  const { k2AttendanceAccountName } = useFlags();
  /**
   * our data comes in as an array of sessions for the week. we need to group these by account+child id
   * otherwise we will have a new table row for each session
   */
  const groupByFn = isRegion('US')
    ? (s: ISession) => `${s.accountId}_${s.childId}_${s.classId}_${s.feeId}`
    : (s: ISession) => `${s.accountId}_${s.childId}_${s.classId}`;
  const groupedData = groupBy(data, groupByFn);
  const tableData: ITableRowShape[] = Object.values(groupedData).map((sessions: ISession[]) => {
    const fromFlatRate = !!sessions.find((s) => s.fee.feeType === 'FLAT_RATE');
    return {
      child: sessions[0].child,
      class: sessions[0].class,
      accountId: sessions[0].accountId,
      accountName: sessions[0].accountName,
      sessionsForWeek: sessions,
      existingEnrollmentYearAbsences: sessions[0].existingEnrollmentYearAbsences ?? [],
      rate: { rateValue: getRateForSessions(sessions), unitsLabel: getRateUnitLabelForSessions(sessions) },
      fromFlatRate,
      amount: (() => {
        if (!isRegion('US')) return null;
        const fee = sessions[0].fee;
        let amount;
        if (fee.feeType === 'FLAT_RATE') {
          amount = (sessions[0]?.qualifyingRate?.value ?? 0) - (sessions[0].cost?.discount ?? 0);
        } else {
          amount = sessions.reduce((acc, curr) => acc + (curr.cost?.gap ?? 0), 0);
        }
        return amount ?? null;
      })(),
      cost: {
        total: sessions.reduce((acc, curr) => acc + (curr.cost?.total ?? 0), 0),
        sessionCost: sessions.reduce((acc, curr) => acc + (curr.cost?.sessionCost ?? 0), 0),
        discount: fromFlatRate
          ? sessions[0].cost?.discount ?? 0
          : sessions.reduce((acc, curr) => acc + (curr.cost?.discount ?? 0), 0),
        subsidy: fromFlatRate ? 0 : sessions.reduce((acc, curr) => acc + (curr.subsidy?.amount ?? 0), 0),
        earlyFee: sessions.reduce((acc, curr) => acc + (curr.cost?.earlyFee ?? 0), 0),
        lateFee: sessions.reduce((acc, curr) => acc + (curr.cost?.lateFee ?? 0), 0),
        gap: sessions.reduce((acc, curr) => acc + (curr.cost?.gap ?? 0), 0),
        charged: sessions.every((s) => s.charged) ? 'Y' : sessions.every((s) => !s.charged) ? 'N' : 'Partial',
      },
    };
  });

  const totalDurationForWeek = useCallback((tableRow: ITableRowShape): string => {
    const { sessionsForWeek } = tableRow;
    const totalMinutes = sessionsForWeek.reduce(
      (acc, curr) => (acc += curr.totalTime.hours * 60 + curr.totalTime.minutes),
      0
    );
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return `${totalHours}h ${remainingMinutes}m`;
  }, []);

  return (
    <DataTable
      noPadding
      className="attendance-table"
      data={tableData}
      dataSize={tableData.length}
      showLoadingOverlay={loading}
      showPagination={false}
      showSelect={false}
      onSort={(field, direction) => onSort(field, direction === 'ASCENDING' ? 'asc' : 'desc')}
      columns={[
        ...[
          {
            text: 'Child',
            sort: true,
            dataField: 'child.lastname',
            formatter: (cell: any, row: ITableRowShape) => {
              const { child } = row;
              return (
                <div className="d-flex align-items-center">
                  <PersonAvatar person={cast<IPerson>(row.child)} className="mr-2" />
                  <div>
                    <div>{getFullName(row.child)}</div>
                    {k2AttendanceAccountName && (
                      <Link className="session-account-name" to={`/families/accounts/${row.accountId}/profile`}>
                        {row?.accountName}
                      </Link>
                    )}
                    {isRegion('AU') && <small>Absences: {row.existingEnrollmentYearAbsences?.length ?? 0}</small>}
                  </div>
                </div>
              );
            },
          },
          {
            text: 'Class Name',
            headerClasses: 'text-left',
            dataField: 'class.name',
          },
          {
            text: 'Session',
            align: 'center',
            formatter: (cell: any, row: ITableRowShape) => totalDurationForWeek(row),
          },
          ...(isRegion('US')
            ? [
                {
                  text: 'Rate',
                  dataField: 'rate',
                  align: 'center',
                  formatter: (rate: any) => {
                    return <RateInfo rateValue={rate.rateValue} rateUnitLabel={rate.unitsLabel}></RateInfo>;
                  },
                },
                {
                  text: '# of Sessions',
                  dataField: 'sessionsForWeek',
                  align: 'center',
                  formatter: (row: ISession[]) => row.length,
                },
              ]
            : [
                {
                  text: 'Session Cost',
                  dataField: 'cost.sessionCost',
                  align: 'center',
                  formatter: (cell: number, row: ISession) => `$${cell.toFixed(2)}`,
                },
              ]),
          {
            text: 'Discount',
            dataField: 'cost.discount',
            align: 'center',
            formatter: (cell: number, row: ITableRowShape) => `$${cell.toFixed(2)}`,
          },
          {
            text: 'Subsidy Estimate',
            dataField: 'cost.subsidy',
            align: 'center',
            formatter: (cell: number, row: ITableRowShape) => `$${cell.toFixed(2)}`,
          },
          {
            text: 'Early Fee',
            dataField: 'cost.earlyFee',
            align: 'center',
            formatter: (cell: number, row: ITableRowShape) => `$${cell.toFixed(2)}`,
          },
          {
            text: 'Late Fee',
            dataField: 'cost.lateFee',
            align: 'center',
            formatter: (cell: number, row: ITableRowShape) => `$${cell.toFixed(2)}`,
          },
          {
            text: isRegion('AU') ? 'Parent Gap' : 'Amount',
            dataField: isRegion('US') ? 'amount' : 'cost.gap',
            align: 'center',
            formatter: (cell: number, row: ITableRowShape) => {
              let value = isRegion('US') ? cell + row.cost.earlyFee + row.cost.lateFee : cell;
              return `$${value.toFixed(2)}`;
            },
          },
          {
            text: 'Charged?',
            dataField: 'cost.charged',
            align: 'center',
            formatter: (charged: string, session: ISession) =>
              childAndClassIdsInLoadingState.find(
                (s) =>
                  s.childId === session.childId &&
                  s.sessionId === session.id &&
                  s.date === session.date &&
                  s.contractId === session.contractId &&
                  s.classId === session.classId
              ) ? (
                <Spinner className="text-gray mr-2" />
              ) : (
                charged
              ),
          },
        ],
        ...(canEditAttendanceFinance
          ? [
              {
                text: 'Actions',
                dataField: 'actions',
                isDummyField: true,
                align: 'center',
                formatter: (cell: any, row: ITableRowShape) => (
                  <AttendanceTableFinanceActionDropdown
                    sessionCostData={row.cost}
                    isDisabledAllActions={!row.sessionsForWeek.every((s) => s.metadata.isWritable)}
                    hasEarlyFee={row.sessionsForWeek.every((s) => s.hasEarlyFee)}
                    hasLateFee={row.sessionsForWeek.every((s) => s.hasLateFee)}
                    onRemoveFee={(type) => onRemoveFeeForSessions(type, row.sessionsForWeek)}
                    onRestoreFee={(type) => onRestoreFeeForSessions(type, row.sessionsForWeek)}
                    hasBeenCharged={row.sessionsForWeek.every((s) => s.charged)}
                    onCharge={() => onCharge(row.sessionsForWeek)}
                    sessionFromFlatRateFee={!!row.sessionsForWeek.find((s) => s.fee.feeType === 'FLAT_RATE')}
                    isWeeklyView={true}
                  />
                ),
              },
            ]
          : []),
      ]}
    />
  );
};

function getRateForSessions(sessions: ISession[]) {
  const sessionWithRate = sessions.find((s) => !!s.qualifyingRate);
  let rateValue;
  if (sessionWithRate) {
    rateValue = sessionWithRate.qualifyingRate?.value;
  }
  if (!rateValue) rateValue = sessions[0].cost?.sessionCost ?? 0;
  return rateValue;
}

function getRateUnitLabelForSessions(sessions: ISession[]) {
  return getRateUnitsLabelFromSession(sessions[0]);
}

export default WeeklyAttendanceFinanceTable;
