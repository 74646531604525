import React from 'react';
import momentTz from 'moment-timezone';
import { useSelector } from 'react-redux';
import { faClock, faUtensilsAlt, faIdCardAlt } from '@fortawesome/pro-light-svg-icons';
import Avatar from 'shared/components/Avatar';
import IconWithText from './IconWithText';
import { RootState } from 'store/reducers';
import { getFullName } from 'shared/util/string';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  shift: IShift | null;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const ShiftDetailsInformation: React.FC<IProps> = ({ shift, ...props }) => {
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);

  if (!shift) {
    return null;
  }

  const shiftTimezone = timezonesByCenter[shift.centerId] ?? momentTz.tz.guess();
  const shiftPersonAssignment: string = shift.personId ? getFullName(shift.person) : 'Unassigned';

  const shiftStart = momentTz(shift.startTime).tz(shiftTimezone);
  const shiftEnd = momentTz(shift.endTime).tz(shiftTimezone);
  const paidBreak = shift.paidBreak ? 0 : shift.breakMinutes;

  const shiftDate: string = shiftStart.format('dddd, MMMM D, YYYY');
  const shiftTimeframe = `${shiftStart.format('h:mm A')} - ${shiftEnd.format('h:mm A')}`;

  const shiftDuration: string = `${((shiftEnd.diff(shiftStart, 'minutes') - paidBreak) / 60).toFixed(2)} hours`;
  const breakDuration: string = `${shift.breakMinutes} minutes`;

  return (
    <div className="d-flex flex-row">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center mb-7">
          <Avatar
            initials={shiftPersonAssignment.charAt(0).toUpperCase()}
            image={shift.person?.avatar?.url ?? ''}
            size="sm"
          />
          <div className="ml-4">
            <div className="kt-staff-schedules-shift-modal-primary-text">{shiftPersonAssignment}</div>
            <div>{shiftDate}</div>
          </div>
        </div>
        <div>
          <IconWithText icon={faClock}>
            Shift: {shiftTimeframe} ({shiftDuration})
          </IconWithText>
          <IconWithText icon={faUtensilsAlt}>
            Break: {breakDuration} ({shift.paidBreak ? 'Paid' : 'Unpaid'})
          </IconWithText>
          {shift.position && (
            <IconWithText icon={faIdCardAlt}>
              Position: {shift.position.positionName.replace(/Center|Centre/g, fieldLabels.center)}
            </IconWithText>
          )}
          {shift.note && <IconWithText icon={faClock}>Note: {shift.note}</IconWithText>}
        </div>
      </div>
    </div>
  );
};

export default ShiftDetailsInformation;
