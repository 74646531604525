import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { RadioButtonGroup, IconButtonCircle } from 'shared/components/Buttons';
import { INotificationV2QueryInput, useGetNotificationsV2Types } from 'gql/notificationsV2/queries';
import { capitalize } from 'lodash';
import DateInput from 'shared/components/DateInput';
import moment from 'moment';
import Select from 'shared/components/Select';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { TableHeader } from 'shared/components/DataTable';
import { useTranslation } from 'react-i18next';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
import { isRegion } from 'shared/util/region';
import cast from '../../../shared/util/cast';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {
  filters: INotificationV2QueryInput;
  setFilters: (filters: INotificationV2QueryInput) => void;
}

enum NotificationStatusEnum {
  All = 'all',
  UNREAD = 'unread',
  READ = 'read',
  ARCHIVED = 'archived',
}

enum NotificationLevelEnum {
  INFORMATION = 'INFORMATION',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

type NotificationStatus =
  | NotificationStatusEnum.All
  | NotificationStatusEnum.UNREAD
  | NotificationStatusEnum.READ
  | NotificationStatusEnum.ARCHIVED;

const NotificationV2TableHeader: React.FC<IProps> = ({ filters, setFilters }) => {
  const { t } = useTranslation();

  const handleNotificationStatusFilterChange = useCallback(
    (type: NotificationStatus) => {
      if (type === NotificationStatusEnum.All) {
        setFilters({ ...filters, read: undefined, archived: undefined });
      } else if (type === NotificationStatusEnum.READ) {
        setFilters({ ...filters, read: true, archived: undefined });
      } else if (type === NotificationStatusEnum.UNREAD) {
        setFilters({ ...filters, read: false, archived: undefined });
      } else if (type === NotificationStatusEnum.ARCHIVED) {
        setFilters({ ...filters, read: undefined, archived: true });
      }
    },
    [filters]
  );

  const statusOptions = useMemo(
    () =>
      Object.values(NotificationStatusEnum).map((status) => ({
        label: t(`notification-v2.status-filters.${status}`),
        value: status,
        className: 'status-filter-btn',
        onClick: () => handleNotificationStatusFilterChange(status),
      })),
    [handleNotificationStatusFilterChange]
  );

  const statusFilterValue = useMemo(() => {
    if (filters.archived === undefined && !filters.read === undefined) {
      // return 'all' only when both read and archive flag are undefined
      // if any one of them is defined with a boolean value, it does means some filter is on!
      return NotificationStatusEnum.All;
    } else if (filters.archived) {
      return NotificationStatusEnum.ARCHIVED;
    } else if (filters.read) {
      return NotificationStatusEnum.READ;
    } else if (filters.read === false) {
      return NotificationStatusEnum.UNREAD;
    }

    return NotificationStatusEnum.All;
  }, [filters]);

  const [isDateValid, setDateValidity] = useState<boolean>(true);

  const validateDateRange = (start: string | undefined, end: string | undefined) => {
    if (start !== '' && end !== '' && start !== undefined && end !== undefined) {
      setDateValidity(moment(start).isBefore(moment(end)));
    }
  };

  useEffect(() => {
    validateDateRange(filters.startDate, filters.endDate);
  }, [filters.startDate, filters.endDate]);

  const handleClearFilters = useCallback(() => {
    setFilters({
      ...filters,
      archived: undefined,
      read: undefined,
      level: undefined,
      types: undefined,
      startDate: undefined,
      endDate: undefined,
    });
    setDateValidity(true);
  }, [filters]);

  const { data: dataTypes } = useGetNotificationsV2Types();
  const types =
    dataTypes?.notificationTypes
      .map((i) => {
        return { label: t(cast<any>(`translation:notification-v2.typesList.${i.type}`)), value: i.type };
      })
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  return (
    <TableHeader className="flex-wrap">
      <div className="d-flex flex-row align-items-center px-4 py-2 m-0 flex-wrap justify-content-between">
        <RadioButtonGroup
          name="notificationV2StatusFilter"
          value={statusFilterValue}
          options={statusOptions}
          className="border-0"
        />
        <div className="d-flex align-items-center mr-2">
          <DateInput
            date={filters.startDate}
            onDateSelect={(start) => setFilters({ ...filters, startDate: start })}
            isValid={isDateValid}
            hasCustomValidation
            className="flex-grow-0 m-0"
            dateOnly={true}
          />
          <div className="px-2">to</div>
          <DateInput
            date={filters.endDate}
            isValid={isDateValid}
            hasCustomValidation
            onDateSelect={(end) => setFilters({ ...filters, endDate: end })}
            className="flex-grow-0 m-0"
            dateOnly={true}
          />
        </div>
        <Select
          name="level"
          options={Object.values(NotificationLevelEnum).map((level) => capitalize(level))}
          placeholder={t('notification-v2.dataTable.column-labels.level')}
          value={capitalize(filters.level)}
          onChange={(level) => setFilters({ ...filters, level: level.toUpperCase() })}
          className="w-160px flex-grow-0 my-0 mr-2"
        />
        <SelectMultiple
          onChange={(type) => {
            setFilters({ ...filters, types: type?.map((i) => i.value) });
          }}
          name="types"
          placeholder={t('notification-v2.dataTable.column-labels.type')}
          options={types}
          value={
            filters.types?.map((i) => {
              return { label: t(cast<any>(`translation:notification-v2.typesList.${i}`)), value: i };
            }) ?? null
          }
          className="mt-4 w-260px flex-grow-0 mr-2"
        />
        <IconButtonCircle
          icon={faTimes}
          onClick={handleClearFilters}
          tooltipDirection="bottom"
          tooltipText="Clear Filters"
          className="mr-4 my-2"
        />
      </div>
    </TableHeader>
  );
};

export default NotificationV2TableHeader;
