import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { ccsAbsencesCategoryFields, ccsAbsencesPagedResultFields } from './fields';

interface IGetCcsAbsencesForCentersData {
  getCcsAbsencesForCenter: IPagedResult<ICcsAbsences>;
}

interface IGetCcsAbsencesForCentersVariables {
  input: ICcsAbsencesInput;
}

interface IGetCcsAbsencesCategoriesForCentersData {
  getCcsAbsencesCategoriesForCenter: ICcsAbsencesCategories;
}

interface IGetCcsAbsencesCategoriesForCentersVariables {
  input: ICcsAbsencesCategoriesInput;
}

export const GET_CCS_ABSENCES_FOR_CENTER = (fields: string = ccsAbsencesPagedResultFields) => gql`
  query($input: CcsAbsencesInput!) {
    getCcsAbsencesForCenter(input: $input) {
      ${fields}
    }
  }
`;

export const GET_CCS_ABSENCES_CATEGORIES_FOR_CENTERS = (fields: string = ccsAbsencesCategoryFields) => gql`
  query($input: CcsAbsencesCategoriesInput!) {
    getCcsAbsencesCategoriesForCenter(input: $input) {
      ${fields}
    }
  }
`;

export const useGetCcsAbsencesForCenters = (
  options?: QueryHookOptions<IGetCcsAbsencesForCentersData, IGetCcsAbsencesForCentersVariables>,
  fields?: string
) =>
  useQuery<IGetCcsAbsencesForCentersData, IGetCcsAbsencesForCentersVariables>(GET_CCS_ABSENCES_FOR_CENTER(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetCcsAbsencesCategoriesForCenters = (
  options?: QueryHookOptions<IGetCcsAbsencesCategoriesForCentersData, IGetCcsAbsencesCategoriesForCentersVariables>,
  fields?: string
) =>
  useQuery<IGetCcsAbsencesCategoriesForCentersData, IGetCcsAbsencesCategoriesForCentersVariables>(
    GET_CCS_ABSENCES_CATEGORIES_FOR_CENTERS(fields),
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );
