import React from 'react';
import { PersonAvatar } from 'shared/components/Avatar';
import { HorizontalDivider } from 'shared/components/Dividers';
import { getFullName } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import useFormatDate from 'shared/hooks/useFormatDate';

interface IProps {
  deposit: IAccountDeposit;
}

const DepositExpandedRow: React.FC<IProps> = ({ deposit }) => {
  const { t } = useTranslation(['billing', 'translation']);
  const formatDate = useFormatDate();
  return (
    <div className="py-4">
      <section className="k2-billing-transaction-expanded-row-section">
        <div>
          <h6>{t('billing:transactions.expanded-row.created-by-column')}</h6>
          <div>
            {deposit.createdByPerson ? (
              <span>
                <PersonAvatar size="xs" person={deposit.createdByPerson} className="mr-2" />
                {getFullName(deposit.createdByPerson)}
              </span>
            ) : (
              <span>System</span>
            )}{' '}
            on {formatDate(deposit.createdAt, 'MM/DD/YYYY')}
          </div>
        </div>
      </section>

      <HorizontalDivider />
    </div>
  );
};

export default DepositExpandedRow;
