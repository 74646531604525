import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import moment from 'moment';
import Card from 'shared/components/Card';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import { IconButton } from 'shared/components/Buttons';
import { Circle } from 'shared/components/Shapes';
import { useGetTransactionTotals } from 'gql/reports/queries';
import { RootState } from 'store/reducers';
import { Circle as LoadingCircle, LoadingLines } from 'shared/components/LoadingSkeletons';
import colors from '_colors.module.scss';
import { currencyFormat } from 'shared/util/currency';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IChartFilterShape {
  start: string | null;
  end: string | null;
}

interface IProps {
  /**
   * when empty this will not filter out any centers
   */
  selectedCenterIds: string[];
  onDownloadCsv: (data: ITransactionTotalReport) => void;
}

const TransactionTotalReportDashboardCard: React.FC<IProps> = ({ selectedCenterIds, onDownloadCsv }) => {
  const { week } = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;
  const user = useSelector((state: RootState) => state.user);
  const [chartFilters, setChartFilters] = useState<IChartFilterShape>({
    start: moment().startOf(week).format('YYYY-MM-DD'),
    end: moment().endOf(week).format('YYYY-MM-DD'),
  });

  const { loading: getTransactionTotalsLoading, data: getTransactionTotalsData } = useGetTransactionTotals({
    variables: {
      input: {
        businessId: user?.entityId ?? '',
        centerIds: selectedCenterIds,
        startDate: chartFilters.start as string,
        endDate: chartFilters.end as string,
        interval: 'WEEK',
        transactionDate: 'APPLIES',
      },
    },
  });

  const filteredData = useMemo(() => {
    return (getTransactionTotalsData?.getTransactionTotal.reportData ?? []).filter((datum) =>
      selectedCenterIds.length > 0 ? selectedCenterIds.includes(datum.centerId) : true
    );
  }, [getTransactionTotalsData?.getTransactionTotal.reportData, selectedCenterIds]);

  const totalTransactions = useMemo(
    () =>
      filteredData.reduce(
        (acc, curr) => ({
          count: acc.count + curr.transactionCount,
          charged:
            acc.charged +
            curr.feeAmount +
            curr.debitAmount +
            curr.earlyLateAmount +
            curr.discountAmount +
            curr.subsidyAmount,
          total: acc.total + curr.totalAmount,
        }),
        { count: 0, charged: 0, total: 0 }
      ),
    [filteredData]
  );

  const pieData = useMemo(() => {
    const groupTotals = filteredData.reduce(
      (acc, curr) => ({
        ...acc,
        fee: acc.fee + curr.feeAmount,
        discount: acc.discount + curr.discountAmount,
        earlyLate: acc.earlyLate + curr.earlyLateAmount,
        subsidy: acc.subsidy + curr.subsidyAmount,
        credit: acc.credit + curr.creditAmount,
        debit: acc.debit + curr.debitAmount,
        payment: acc.payment + curr.paymentAmount,
        total: acc.total + curr.totalAmount,
      }),
      { fee: 0, discount: 0, earlyLate: 0, subsidy: 0, credit: 0, debit: 0, payment: 0, total: 0 }
    );

    return [
      { id: 'Fees', label: 'Fees', value: groupTotals.fee, color: colors.teal },
      { id: 'Discounts', label: 'Discounts', value: groupTotals.discount, color: colors.purple },
      { id: 'Early/late fees', label: 'Early/late fees', value: groupTotals.earlyLate, color: colors.yellow },
      { id: 'Subsidy', label: 'Subsidy', value: groupTotals.subsidy, color: colors.babyBlue },
      { id: 'Credits', label: 'Credits', value: groupTotals.credit, color: colors.palePurple },
      { id: 'Debits', label: 'Debits', value: groupTotals.debit, color: colors.secondaryOrange },
      { id: 'Payments', label: 'Payments', value: groupTotals.payment, color: colors.success },
    ];
  }, [filteredData]);

  return (
    <Card
      header={
        <div className="d-flex flex-row align-items-center">
          Transactions per Week
          {getTransactionTotalsData?.getTransactionTotal && (
            <IconButton
              icon={faArrowToBottom}
              onClick={() => onDownloadCsv(getTransactionTotalsData.getTransactionTotal)}
              className="ml-auto"
              tooltipText="Download CSV"
            />
          )}
        </div>
      }
      className="h-100"
    >
      <Row>
        <Column md={6} className="mb-4">
          <WeekPicker
            startDate={chartFilters.start ? moment(chartFilters.start) : null}
            endDate={chartFilters.end ? moment(chartFilters.end) : null}
            onChange={(dates) =>
              setChartFilters((prev) => ({
                ...prev,
                start: dates.startDate.format('YYYY-MM-DD'),
                end: dates.endDate.format('YYYY-MM-DD'),
              }))
            }
            reactDatesController="RANGE"
            rangeType="WEEK"
            className="mb-0"
          />
        </Column>
      </Row>
      {getTransactionTotalsLoading ? (
        <LoadingLines number={1} />
      ) : (
        <div className="mb-4">
          There were {totalTransactions?.count} transactions made this week totaling{' '}
          {currencyFormat(totalTransactions?.charged)} charged.
        </div>
      )}
      {getTransactionTotalsLoading && (
        <div className="d-flex flex-row align-items-center mb-4" style={{ height: 286 }}>
          <LoadingCircle size={286} />
          <div className="flex-1 ml-4">
            <LoadingLines number={6} />
          </div>
        </div>
      )}
      {!getTransactionTotalsLoading && (
        <div className="kt-transaction-report-chart-container">
          <div className="kt-reporting-chart mb-4">
            <ResponsivePie
              data={pieData}
              colors={(datum) => datum.data.color}
              value={(datum) => Math.abs(datum.value)}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              tooltip={(slice) => (
                <div className="text-text-dark bg-white p-2 rounded box-shadow-lg">
                  <b>{slice.datum.label}</b> {currencyFormat(slice.datum.value)}
                </div>
              )}
            />
          </div>
          <div className="d-flex flex-column justify-content-center mb-4 kt-transaction-report-chart-legend-container">
            {pieData.map((datum, idx) => (
              <div key={idx} className="d-flex flex-row align-items-center mb-2">
                <Circle background={datum.color} size={16} className="mr-2" />
                <h6 className="mb-0 mr-8 text-text-color">{datum.label}</h6>
                <h5 className="mb-0 ml-auto font-size-18">{currencyFormat(datum.value)}</h5>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="d-flex flex-row align-items-center justify-content-end">
        <b>Total Outstanding: {currencyFormat(totalTransactions?.total ?? 0)}</b>
      </div>
    </Card>
  );
};

export default TransactionTotalReportDashboardCard;
