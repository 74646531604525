import React from 'react';
import { Menu, MenuItem } from 'shared/components/Dropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import IconButtonCircle from '../Buttons/IconButtonCircle';
import useUniqueId from 'shared/hooks/useUniqueId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './_actionDropdown.scss';
import colors from '_colors.module.scss';
import { usePermissions } from '../../hooks/usePermission';
import { Button as MuiButton, Menu as MuiMenu, MenuItem as MuiMenuItem, PopoverOrigin } from '@mui/material';

const NO_OP = () => {};

interface IDropdownProps {
  actions: IDropdownAction[];
  customIconButton?: JSX.Element;
  className?: string;
  id?: string;
  permission?: IAreaPermissionLevel;
}

interface IIconProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const getIcon = (customIconButton: JSX.Element | undefined) =>
  React.forwardRef<HTMLDivElement, IIconProps>(({ onClick }, ref) => {
    const handleClick = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onClick && onClick(event);
    };

    return (
      <div ref={ref} onClick={handleClick}>
        {customIconButton ? (
          customIconButton
        ) : (
          <IconButtonCircle id="action-dropdown-btn" iconSize="2x" icon={faEllipsisH} onClick={NO_OP} />
        )}
      </div>
    );
  });

const ActionDropdown: React.FC<IDropdownProps> = ({ id, actions, customIconButton, className, ...props }) => {
  const hasPermission = usePermissions(
    actions.reduce((record, iDropdownAction) => {
      if (iDropdownAction.permission == null) {
        record[iDropdownAction.label] = null;
        return record;
      }

      record[iDropdownAction.label] = iDropdownAction.permission;
      return record;
    }, {} as Record<string, IAreaPermissionLevel | null>)
  );

  const _id = useUniqueId(id);
  return (
    <Dropdown alignRight className={className}>
      <Dropdown.Toggle id={_id} as={getIcon(customIconButton)} />
      <Menu>
        {actions.map((action, index) => (
          <MenuItem
            as="div"
            className="cursor-pointer"
            id={action.id}
            key={index}
            onClick={(e: React.MouseEvent<any, MouseEvent>) => {
              e.stopPropagation();
              e.preventDefault();
              action.onClick();
            }}
            disabled={(!hasPermission[action.label] || action.disabled) ?? false}
          >
            {action.icon && (
              <div>
                <FontAwesomeIcon className="mr-4" size="lg" icon={action.icon} color={colors['dark-gray']} />
              </div>
            )}
            {action.labelClassName ? (
              <div>
                <FontAwesomeIcon
                  className="mr-4"
                  size="lg"
                  icon={action.icon}
                  color={colors['dark-gray']}
                  style={{ display: 'none' }}
                />
                <span className={action.labelClassName}>{action.label}</span>
              </div>
            ) : (
              action.label
            )}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};

interface IMuiDropdownProps extends IDropdownProps {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const MuiActionDropdown = ({ id, actions, customIconButton, className, ...props }: IMuiDropdownProps) => {
  const hasPermission = usePermissions(
    actions.reduce((record, iDropdownAction) => {
      if (iDropdownAction.permission == null) {
        record[iDropdownAction.label] = null;
        return record;
      }

      record[iDropdownAction.label] = iDropdownAction.permission;
      return record;
    }, {} as Record<string, IAreaPermissionLevel | null>)
  );

  const _id = useUniqueId(id);
  const menuId = _id + '-menu';
  const buttonId = _id + '-button';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MuiButton
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          minWidth: 20,
        }}
      >
        {customIconButton ? customIconButton : <FontAwesomeIcon size={'2x'} icon={faEllipsisH} />}
      </MuiButton>
      <MuiMenu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          borderRadius: '0.5rem',
          boxShadow: 'none',
          border: '0.1rem solid gray',
        }}
        anchorOrigin={
          props.anchorOrigin ?? {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        disableScrollLock
        transformOrigin={
          props.transformOrigin ?? {
            vertical: 'top',
            horizontal: 'right',
          }
        }
      >
        {actions.map((action, index) => (
          <MuiMenuItem
            id={action.id}
            key={index}
            onClick={(e: React.MouseEvent<any, MouseEvent>) => {
              e.stopPropagation();
              e.preventDefault();
              action.onClick();
              handleClose();
            }}
            sx={{
              minWidth: 160,
            }}
            disabled={(!hasPermission[action.label] || action.disabled) ?? false}
          >
            {action.icon && (
              <FontAwesomeIcon className="mr-4" size="lg" icon={action.icon} color={colors['dark-gray']} />
            )}
            {action.label}
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </div>
  );
};

export default ActionDropdown;
