import { useCallback, useState } from 'react';
import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { omitTypename } from 'shared/util/object';
import { GET_CENTER_SPACES } from 'gql/center/queries';
import { spaceFields } from 'gql/space/fields';
import { showToast } from 'shared/components/Toast';

export const CREATE_SPACE = gql`
  mutation ($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      ${spaceFields}
    }
  }
`;

export const UPDATE_SPACE = gql`
  mutation ($input: UpdateSpaceInput!) {
    updateSpace(input: $input) {
      ${spaceFields}
    }
  }
`;

export const DELETE_SPACE = gql`
  mutation ($input: DeleteSpaceInput!) {
    deleteSpace(input: $input) {
      ${spaceFields}
    }
  }
`;

export const useCreateSpaces: () => [(spaces: INewSpace[]) => Promise<void>, IMutationResponse] = () => {
  const [response, setResponse] = useState<IMutationResponse>({
    loading: false,
    error: null,
    data: null,
  });
  const [createNewSpaceMutation] = useMutation(CREATE_SPACE, {
    update(cache: any, { data: { createSpace } }: any) {
      const { getSpacesForCenter } = cache.readQuery({
        query: GET_CENTER_SPACES(),
        variables: { centerId: createSpace.centerId },
      });
      cache.writeQuery({
        query: GET_CENTER_SPACES(),
        variables: { centerId: createSpace.centerId },
        data: { getSpacesForCenter: [...getSpacesForCenter, createSpace] },
      });
    },
  });

  const createSpacesFn = useCallback(
    async (spaces: INewSpace[]) => {
      setResponse({ ...response, loading: true });
      try {
        const data = await Promise.all(
          spaces.map(async (space) =>
            createNewSpaceMutation({
              variables: { input: space },
            })
          )
        );
        setResponse({ ...response, loading: false, data });
      } catch (error) {
        setResponse({ ...response, loading: false, error });
      }
    },
    [createNewSpaceMutation, response]
  );

  return [createSpacesFn, response];
};

export const useUpdateSpace: () => [(space: ISpace) => Promise<void>, IMutationResponse] = () => {
  const [response, setResponse] = useState<IMutationResponse>({
    loading: false,
    error: null,
    data: null,
  });
  const [updateSpaceMutation] = useMutation(UPDATE_SPACE, {
    update(cache: any, { data: { updateSpace } }: any) {
      const { getSpacesForCenter } = cache.readQuery({
        query: GET_CENTER_SPACES(),
        variables: { centerId: updateSpace.centerId },
      });
      cache.writeQuery({
        query: GET_CENTER_SPACES(),
        variables: { centerId: updateSpace.centerId },
        data: {
          getSpacesForCenter: [...getSpacesForCenter.map((s: ISpace) => (s.id === updateSpace.id ? updateSpace : s))],
        },
      });
    },
  });

  const updateSpaceFn = useCallback(
    async (space: ISpace) => {
      setResponse({ ...response, loading: true });

      try {
        const data = await updateSpaceMutation({
          variables: {
            input: {
              id: space.id,
              name: space.name,
              centerId: space.centerId,
              icon: space.icon,
              color: space.color,
            },
          },
        });
        setResponse({ ...response, loading: false, data });
        showToast('Space updated successfully.', 'success');
      } catch (error) {
        setResponse({ ...response, loading: false, error });
        showToast('There was an error updating this space. Please try again later.', 'success');
      }
    },
    [updateSpaceMutation, response]
  );

  return [updateSpaceFn, response];
};

export const useDeleteSpace: () => [(space: ISpace) => Promise<void>, IMutationResponse] = () => {
  const [response, setResponse] = useState<IMutationResponse>({
    loading: false,
    error: null,
    data: null,
  });
  const [deleteSpaceMutation] = useMutation(DELETE_SPACE, {
    update(cache: any, { data: { deleteSpace } }: any) {
      const { getSpacesForCenter } = cache.readQuery({
        query: GET_CENTER_SPACES(),
        variables: { centerId: deleteSpace.centerId },
      });
      cache.writeQuery({
        query: GET_CENTER_SPACES(),
        variables: { centerId: deleteSpace.centerId },
        data: { getSpacesForCenter: [...getSpacesForCenter.filter((s: ISpace) => s.id !== deleteSpace.id)] },
      });
    },
  });

  const deleteSpaceFn = useCallback(
    async (space: ISpace) => {
      setResponse({ ...response, loading: true });
      try {
        const data = await deleteSpaceMutation({
          variables: { input: { id: space.id } },
        });
        setResponse({ ...response, loading: false, data });
      } catch (error) {
        setResponse({ ...response, loading: false, error });
      }
    },
    [deleteSpaceMutation, response]
  );

  return [deleteSpaceFn, response];
};
