import React, { useMemo, useCallback } from 'react';
import Select from 'shared/components/Select';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useGetGLCodes } from 'gql/glCode/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface IProps {
  glCodeMapping?: IGLCodeMapping | null;
  onChange: (glCodeId: string) => void;
  disabled?: boolean;
  areaType: GLCodeAreaType;
  required?: boolean;
}

const GlCodeDropdown = ({ glCodeMapping, onChange, disabled = false, areaType, required = false }: IProps) => {
  const { t } = useTranslation(['billing', 'translation']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? user?.entityId ?? '';
  const sortDtos = useMemo(() => [{ field: 'name', direction: 'ASCENDING' }], []);

  const { data, loading } = useGetGLCodes({
    variables: {
      input: {
        businessId,
        pageNumber: 1,
        pageSize: 10000,
        sortDtos,
        areaType,
      },
    },
  });

  const handleSelect = useCallback(
    (glCode: IGLCode) => {
      onChange(glCode.id ?? '');
    },
    [onChange]
  );

  const defaultValue = useMemo<IGLCode | null>(() => {
    return glCodeMapping
      ? {
          id: glCodeMapping.glCodeId,
          name: glCodeMapping?.glCodeName ?? '',
          type: areaType,
          glCode: glCodeMapping?.glCodeCode ?? '',
        }
      : null;
  }, [glCodeMapping, areaType]);

  const value = useMemo(() => {
    return (data?.getGLCodes.data ?? []).find((glCode) => glCode.id === glCodeMapping?.glCodeId);
  }, [data, glCodeMapping]);

  return (
    <Form.Group>
      <div className="d-flex flex-row">
        <Form.Label>GL Code</Form.Label>
        {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
      </div>
      <Select
        placeholder={'Select a GL Code'}
        options={data?.getGLCodes.data ?? []}
        getOptionLabel={(option: IGLCode) => `${option.glCode} - ${option.name}`}
        getOptionValue={(option: IGLCode) => option.id!}
        onChange={handleSelect}
        value={value}
        defaultValue={defaultValue}
        isLoading={loading}
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default GlCodeDropdown;
