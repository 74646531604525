import React from 'react';
import { CardDeck } from 'react-bootstrap';
import NumberCard from '../../../../Subsidies/components/NumberCard/NumberCard';

interface IProps {
  onSelected?: (filter: ISClaimStatusFilter | null) => void;
  activeFilter?: ISClaimStatusFilter | null;
  data?: IISCaseClaimCounter;
}

const ISCaseClaimsCards: React.FC<IProps> = ({ onSelected = () => {}, activeFilter, data }) => {
  const toggle = (filter: ISClaimStatusFilter) => (activeFilter !== filter ? filter : null);

  const cards = (
    <>
      <NumberCard
        title="Rejected"
        subTitle="Claims that have been rejected within the last nine weeks"
        number={data?.rejected ?? 0}
        active={activeFilter === 'rejected'}
        numberColor="danger"
        onClick={() => onSelected(toggle('rejected'))}
      />
      <NumberCard
        title="Pending"
        subTitle="Claims sent within the last nine weeks, still awaiting results"
        number={data?.pending ?? 0}
        active={activeFilter === 'pending'}
        numberColor="warning"
        onClick={() => onSelected(toggle('pending'))}
      />
      <NumberCard
        title="Paid"
        subTitle="Claims that have been paid within the last nine weeks"
        number={data?.paid ?? 0}
        numberColor="success"
        active={activeFilter === 'paid'}
        onClick={() => onSelected(toggle('paid'))}
      />
    </>
  );

  return <CardDeck className="mb-5">{cards}</CardDeck>;
};

export default ISCaseClaimsCards;
