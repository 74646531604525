import { Card, CardHeader, CardContent, Divider, Typography, Stack, Tooltip } from '@mui/material';
import Flag from '@mui/icons-material/Flag';
import { ErrorContent } from '../ErrorContent';
import { RowDetail } from './RowDetail';
import { useGetPayment } from '../queries/getPayment';

interface PaymentRowOverviewProps {
  paymentId: string;
}
export const PaymentRowOverview: React.FC<PaymentRowOverviewProps> = ({ paymentId }) => {
  const { data, loading, refetch } = useGetPayment({ paymentId });
  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Stack justifyContent="space-between" direction="row">
            <Typography fontWeight={500} fontSize="1.1244rem" fontFamily={'proxima-soft'}>
              Payment
            </Typography>
            {data?.flaggedReason && (
              <Tooltip title={data.flaggedReason} placement="left">
                <Flag color="error" fontSize="medium" />
              </Tooltip>
            )}
          </Stack>
        }
        sx={{ padding: '8px 6px 0px 16px', borderBottom: 'none' }}
      />
      <CardContent>
        {!loading && data === null ? (
          <ErrorContent onTryAgain={refetch} />
        ) : (
          <Stack>
            <RowDetail loading={loading} name="Amount" value={data?.amount} />
            <RowDetail loading={loading} name="Status" value={data?.status} />
            <RowDetail loading={loading} name="Initiated By" value={data?.initiatedBy?.name}></RowDetail>

            {data?.failureReason && (
              <Stack justifyContent="center">
                <Typography color="red" textAlign="center">
                  {data.failureReason}
                </Typography>
              </Stack>
            )}

            <Divider sx={{ marginY: 2 }} />
            <Typography fontWeight={600} marginBottom="1rem">
              Payment Details
            </Typography>
            <RowDetail loading={loading} name="Method" value={data?.paymentMethod.type} />
            <RowDetail loading={loading} name="Last Four" value={data?.paymentMethod.lastFour} />
            <Divider sx={{ marginY: 2 }} />
            <Typography fontWeight={600} marginBottom="1rem">
              Payment Contact
            </Typography>
            <RowDetail loading={loading} name="Name" value={data?.paymentMethod?.contact?.name} />
            <RowDetail loading={loading} name="Email" value={data?.paymentMethod?.contact?.email} />
            <RowDetail loading={loading} name="Phone" value={data?.paymentMethod?.contact?.phoneNumber} />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
