import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import ActionDropdown from 'shared/components/ActionDropdown';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { IAdjustmentTableFilters } from './AdjustmentsTab';
import colors from '_colors.module.scss';
import { Circle } from 'shared/components/Shapes';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { faPencilAlt, faTrashAlt, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  data: IAdjustment[];
  isLoading: boolean;
  tableFilters: IAdjustmentTableFilters;
  onTableFilterUpdate: (updates: Partial<IAdjustmentTableFilters>) => void;
  onEdit: (adjustment: IAdjustment) => void;
  onArchive: (adjustment: IAdjustment) => void;
  noPadding: boolean;
}

const AdjustmentsTable: React.FC<IProps> = ({
  data,
  isLoading,
  tableFilters,
  onTableFilterUpdate,
  onEdit,
  onArchive,
  noPadding = false,
  ...props
}) => {
  const { k2GlCodeActivationCheck } = useFlags();
  const { t } = useTranslation(['billing']);

  const handleSearchTerm = debounce(
    useCallback(
      (term: string) => {
        onTableFilterUpdate({ term });
      },
      [onTableFilterUpdate]
    ),
    250
  );

  const canEditAdjustments = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Adjustments,
    level: RoleLevelType.Edit,
  });
  const canDeleteAdjustments = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Adjustments,
    level: RoleLevelType.Delete,
  });

  const getDropdownOptionsForAdjustments = useCallback(
    (row: IAdjustment): IDropdownAction[] => {
      const actions: { label: string; onClick: () => void; icon: IconDefinition }[] = [];

      canEditAdjustments && actions.push({ label: 'Edit', onClick: () => onEdit(row), icon: faPencilAlt });
      canDeleteAdjustments && actions.push({ label: 'Archive', onClick: () => onArchive(row), icon: faTrashAlt });

      return actions;
    },
    [canEditAdjustments, canDeleteAdjustments]
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <DataTable
      data={data}
      showLoadingOverlay={isLoading}
      showSelect={false}
      showPagination={false}
      noPadding={noPadding}
      columns={[
        {
          text: t('billing:adjustments.name-column'),
          dataField: 'name',
        },
        {
          text: t('billing:adjustments.status-column'),
          dataField: 'archivedAt',
          formatter: (cell: string | null, row: IAdjustment) => (
            <div className="d-flex">
              <div className="mr-2 d-flex">
                <Circle className="my-auto" size="xxs" background={row.archivedAt ? colors.gray : colors.success} />
              </div>
              <span>{row.archivedAt ? 'Archived' : 'Active'}</span>
            </div>
          ),
        },
        {
          text: t('billing:adjustments.type-column'),
          dataField: 'type',
          formatter: (cell: AdjustmentType, row: IAdjustment) => (row.type === 'FEE' ? 'Fee' : 'Subsidy'),
        },
        {
          text: t('billing:adjustments.gl-label'),
          dataField: 'glCode',
          formatter: (cell: string, row: IAdjustment) => {
            if (k2GlCodeActivationCheck) {
              return row.glCodeMapping ? `${row.glCodeMapping.glCodeCode} - ${row.glCodeMapping?.glCodeName}` : null;
            } else {
              return row.glCode;
            }
          },
        },
        ...(canEditAdjustments || canDeleteAdjustments
          ? [
              {
                text: 'Actions',
                align: 'right',
                headerClasses: 'text-right',
                dataField: '',
                formatter: (cell: any, row: IAdjustment) =>
                  !row.archivedAt ? (
                    canEditAdjustments || canDeleteAdjustments ? (
                      <ActionDropdown actions={getDropdownOptionsForAdjustments(row)} />
                    ) : null
                  ) : null,
              },
            ]
          : []),
      ]}
      renderHeader={(paginationProps, searchProps) => (
        <TableHeader className="flex-wrap align-items-center">
          <div
            className={
              isMobile
                ? 'd-flex flex-wrap my-1 ml-2 align-items-center mr-auto'
                : 'd-flex flex-wrap mr-auto align-items-center'
            }
          >
            <TableSearch onChange={handleSearchTerm} />
          </div>
          <div className="d-flex flex-wrap align-items-center">
            <DropdownFilter
              title="Status"
              className={isMobile ? 'ml-2 my-1' : 'my-0 mx-2 ml-auto p-0'}
              selectedFilters={tableFilters.statuses}
              options={[
                { label: 'Active', value: 'ACTIVE' },
                { label: 'Archived', value: 'ARCHIVED' },
              ]}
              onFilterSelect={(statuses) =>
                onTableFilterUpdate({ statuses: statuses.map((s) => s.value as 'ACTIVE' | 'ARCHIVED') })
              }
            />
            <DropdownFilter
              title="Type"
              className={isMobile ? 'ml-2 my-1' : 'my-0 ml-2 p-0'}
              selectedFilters={tableFilters.types}
              options={[
                { label: 'Fee', value: 'FEE' },
                { label: 'Subsidy', value: 'SUBSIDY' },
              ]}
              onFilterSelect={(types) => onTableFilterUpdate({ types: types.map((t) => t.value as AdjustmentType) })}
            />
          </div>
        </TableHeader>
      )}
    />
  );
};

export default AdjustmentsTable;
