import React, { useCallback, useState } from 'react';
import TextInput, { ITextInputProps } from './TextInput';
import { isEmailValid } from 'shared/util/email';

const EmailInput: React.FC<ITextInputProps> = ({
  onChange,
  autoComplete = 'off',
  isInvalid: isInvalidProp,
  requiresValidation = true,
  ...props
}) => {
  const [isInvalid, setIsInvalid] = useState(false);
  // lower case input onChange
  const handleChange = useCallback(
    (value, name) => {
      if (!props.required && !value) setIsInvalid(false);
      else setIsInvalid(!isEmailValid(value));

      var lowerCaseValue = value.toLowerCase();
      onChange && onChange(lowerCaseValue, name);
    },
    [onChange, props.required]
  );

  return (
    <TextInput
      {...props}
      onChange={handleChange}
      type="email"
      autoComplete={autoComplete}
      isInvalid={isInvalidProp ?? isInvalid}
      requiresValidation={requiresValidation}
    />
  );
};

export default EmailInput;
