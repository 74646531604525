import React, { useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import NotifiableEventsContainer from '../NotifiableEventsContainer';
import { useGetProviderPersonnel } from 'gql/ccssPersonnel/queries';
import { useEffect } from 'react';
import { useUpdateProviderPersonnel } from 'gql/ccssPersonnel/mutation';
import { showToast } from 'shared/components/Toast';
import moment from 'moment';
import { useCallback } from 'react';
import { suitabilityReasonOptions } from 'shared/constants/enums/ccssPersonnelEnums';

interface Props {
  businessId: string;
  providers: IProvider[];
}

interface IPotentiallyAffectingSuitabilityIndividualInput {
  date: string;
  reasonForNotification?: SuitabilityReason;
  relevantInformation: string;
  howThisAffect: string;
  actionTaken: string;
  providerId: any;
  personnel: ICcssPersonnel | null;
}

const PotentiallyAffectingSuitabilityIndividual: React.FC<Props> = ({ businessId, providers }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<IPotentiallyAffectingSuitabilityIndividualInput>({
    date: '',
    reasonForNotification: undefined,
    relevantInformation: '',
    howThisAffect: '',
    actionTaken: '',
    providerId: undefined,
    personnel: null,
  });
  const [cancelData, setCancelData] = useState<IPotentiallyAffectingSuitabilityIndividualInput>(formData);

  useEffect(() => {
    if (providers.length > 0) {
      setFormData((form) => ({
        ...form,
        providerId: providers[0].id,
      }));
      setCancelData((form) => ({
        ...form,
        providerId: providers[0].id,
      }));
    }
  }, [providers]);

  const { data: personnel, loading: getPersonnelLoading } = useGetProviderPersonnel({
    variables: {
      businessId: businessId,
      providerId: formData.providerId,
    },
    skip: businessId === '' || !Boolean(formData.providerId),
  });

  const [updatePersonnel, { loading }] = useUpdateProviderPersonnel({
    onCompleted: () => {
      showToast('Successfully submitted notification for the individual.', 'success');
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  const validate = useCallback(() => {
    return !Object.values(formData).some((val: string) => val === '');
  }, [formData]);

  const handleSubmit = () => {
    if (!validate() && !formData.personnel) {
      return;
    }

    updatePersonnel({
      variables: {
        input: {
          businessId,
          providerId: formData.providerId,
          personnel: {
            id: formData.personnel?.id || '',
            personId: formData.personnel?.personId,
            lastName: formData.personnel?.lastName || '',
            dateOfBirth: formData.personnel?.dateOfBirth || '',
            notificationOfSuitability: {
              reason: formData.reasonForNotification || 'OFFR',
              relevantInfo: formData.relevantInformation,
              dateOfAwareness: moment(formData.date).format(),
              personSuitability: formData.howThisAffect,
              actionTaken: formData.actionTaken,
            },
          },
        },
      },
    });
  };

  return (
    <>
      <NotifiableEventsContainer
        title="Notification of matter potentially affecting suitability - Individual"
        subTitle={undefined}
        notificationMessage="You must submit this notification within 7 days of the Provider becoming aware of the event or circumstance"
        onCancel={() => setFormData(cancelData)}
        onSave={handleSubmit}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!validate()}
        providerOnChange={(option) => setFormData({ ...formData, providerId: option.value })}
        isSaving={loading}
        selectedProvider={formData.providerId}
        isLoading={getPersonnelLoading}
        child={
          <>
            <Row>
              <Col className="mb-2" sm={6}>
                <Select
                  inputId="personnel"
                  required
                  label={'Personnel'}
                  options={personnel?.getPersonnelByProviderId.data || []}
                  getOptionLabel={(option: ICcssPersonnel) => `${option.firstName} ${option.lastName}`}
                  getOptionValue={(option: ICcssPersonnel) => option.id}
                  value={formData.personnel?.id}
                  onChange={(option: ICcssPersonnel) => setFormData({ ...formData, personnel: option })}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" sm={6}>
                <DateInput
                  required
                  label="Date of awareness"
                  date={formData.date}
                  onDateSelect={(date: string) => setFormData({ ...formData, date: date })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2 md-6" sm={6}>
                <Select
                  inputId="address-type"
                  label="Reason for notification"
                  required
                  options={suitabilityReasonOptions}
                  value={formData.reasonForNotification}
                  onChange={(option) =>
                    setFormData({
                      ...formData,
                      reasonForNotification: option.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" sm={6}>
                <TextInput
                  required={formData.reasonForNotification !== 'OTMA'}
                  label="Relevant information for the notification"
                  name="text"
                  value={formData.relevantInformation}
                  onChange={(value: string) => setFormData({ ...formData, relevantInformation: value })}
                  as="textarea"
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" sm={6}>
                <TextInput
                  label="How does this change affect or is likely to affect the persons suitability to be involved in child care?"
                  name="text"
                  required
                  value={formData.howThisAffect}
                  onChange={(value: string) => setFormData({ ...formData, howThisAffect: value })}
                  as="textarea"
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" sm={6}>
                <TextInput
                  required
                  label="What action have you taken or intend to take to deal with the change?"
                  name="text"
                  value={formData.actionTaken}
                  onChange={(value: string) => setFormData({ ...formData, actionTaken: value })}
                  as="textarea"
                  disabled={false}
                />
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default PotentiallyAffectingSuitabilityIndividual;
