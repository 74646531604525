export const serviceContactFields = `
  dateOfEvent
  phoneNumber
  serviceUrl
  mobileNumber
  email
`;

export const serviceLocationFields = `
  dateOfEvent
  serviceId
`;

export const providerLocationOfRecordsFields = `
  dateOfEvent
  addresses {
    streetLine1
    streetLine2
    suburb
    state
    postcode
  }
`;
