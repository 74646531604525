import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import SideModalDrawer from 'shared/components/ModalDrawer';
import DateInput from 'shared/components/DateInput';
import FileDropbox from 'shared/components/FileOperations/FileDropbox';
import SelectedFile from 'shared/components/FileOperations/SelectedFile';
import { useCreateChildImmunizationRecord, useUpdateImmunizationDocument } from 'gql/immunization/mutations';
import { showToast } from 'shared/components/Toast';
import colors from '_colors.module.scss';
import { useDispatch } from 'react-redux';
import { createImmunizationForChild, updateImmunizationForChild } from '../../../duck/actions';
import { NumberInput } from 'shared/components/TextInput';
import { Col, Row } from 'shared/components/Layout';
import useFormatDate from 'shared/hooks/useFormatDate';

interface IFormDataStateShape {
  file: File | null;
  expirationDate: string | null;
  reviewDaysBeforeExpiration: number | null;
}

interface IProps {
  childId: string;
  timezone: string;
  immunization?: IImmunization | null;
  isOpen: boolean;
  onClose: () => void;
}

const AddOrEditImmmunizationModal: React.FC<IProps> = ({
  childId,
  immunization,
  timezone,
  isOpen,
  onClose,
  ...props
}) => {
  const dispatch = useDispatch();
  const formatDate = useFormatDate();
  const [formData, setFormData] = useState<IFormDataStateShape>({
    file: null,
    expirationDate: immunization?.activeDocument?.expirationDate
      ? moment.tz(immunization?.activeDocument?.expirationDate, timezone).format('YYYY/MM/DD')
      : null,
    reviewDaysBeforeExpiration: immunization?.activeDocument?.reviewDaysBeforeExpiration ?? null,
  });
  const [createChildImmunizationRecordFn, { loading: createChildImmunizationRecordLoading }] =
    useCreateChildImmunizationRecord({
      onCompleted: (result) => {
        showToast('Immunization record added successfully.', 'success');
        dispatch(createImmunizationForChild(result.createChildImmunizationRecord));
        dismissModal();
      },
      onError: (error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      },
    });

  const [updateDocumentFn, { loading: updateDocumentLoading }] = useUpdateImmunizationDocument({
    onCompleted: (result) => {
      showToast('Immunization document updated successfully.', 'success');
      dispatch(updateImmunizationForChild(result.updateImmunizationDocument));
      dismissModal();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleFilesAdd = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event?.target?.files ?? []);
    const file = files[0];

    setFormData((prev) => ({ ...prev, file }));
  }, []);

  const handleFileDrop = useCallback((files: File[]) => {
    const file = files[0];

    setFormData((prev) => ({ ...prev, file }));
  }, []);

  const dismissModal = useCallback(() => {
    setFormData({ file: null, expirationDate: null, reviewDaysBeforeExpiration: null });
    onClose();
  }, [onClose]);

  const submitForm = useCallback(() => {
    if (formData.file) {
      createChildImmunizationRecordFn({
        variables: {
          input: {
            childId: childId,
            document: {
              file: formData.file,
              filename: formData.file.name,
              expirationDate: formData.expirationDate,
              reviewDaysBeforeExpiration: formData.reviewDaysBeforeExpiration,
            },
          },
        },
      });
    } else if (immunization) {
      updateDocumentFn({
        variables: {
          input: {
            id: immunization?.activeDocument.id,
            immunizationId: immunization.id,
            expirationDate: formData.expirationDate,
            reviewDaysBeforeExpiration: formData.reviewDaysBeforeExpiration,
          },
        },
      });
    }
  }, [formData, immunization, createChildImmunizationRecordFn, updateDocumentFn]);

  return (
    <SideModalDrawer
      title={`${immunization ? 'Edit' : 'Add'} Record`}
      show={isOpen}
      onHide={dismissModal}
      primaryChoice="Save"
      primaryCallback={submitForm}
      secondaryCallback={dismissModal}
      primaryButtonProps={{
        disabled: (!formData.file && !immunization) || createChildImmunizationRecordLoading || updateDocumentLoading,
        loading: createChildImmunizationRecordLoading || updateDocumentLoading,
      }}
      closeOnPrimaryCallback={false}
    >
      {immunization && (
        <div className="mb-4">
          <Form.Label>Current Document</Form.Label>
          <Row className="flex-nowrap text-overflow-ellipsis bg-very-light-blue px-4 py-2 rounded py-1" noGutters>
            <div className="text-overflow-ellipsis mr-4">
              <a
                key={immunization.activeDocument.id}
                href={immunization.activeDocument.link}
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2"
              >
                {immunization.activeDocument.name}
              </a>
            </div>
            <div className="ml-auto">{formatDate(immunization.activeDocument.createdAt, 'MM/DD/YYYY')}</div>
          </Row>
        </div>
      )}
      <div className="mb-4">
        <Form.Label>Add Immunization record document</Form.Label>
        <FileDropbox multiple={false} onFilesAdded={handleFilesAdd} onDrop={handleFileDrop} />
      </div>
      {formData.file && (
        <div className="mb-4">
          <SelectedFile
            file={formData.file}
            onDeleteClick={() => setFormData((prev) => ({ ...prev, file: null }))}
            updateContentsCb={() => {}}
          />
        </div>
      )}
      <Row>
        <Col>
          <DateInput
            label="Expiration Date"
            date={formData.expirationDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, expirationDate: date }))}
            className="kt-date-input-no-max-width"
          />
        </Col>
        <Col>
          <NumberInput
            label="Review Before Expiration"
            value={formData.reviewDaysBeforeExpiration}
            onChange={(days) => setFormData((prev) => ({ ...prev, reviewDaysBeforeExpiration: days }))}
            numberFormat={{ allowNegative: false }}
            appendText="Days"
            className="mb-0"
          />
        </Col>
      </Row>
      {formData.expirationDate && moment(formData.expirationDate).isBefore(moment.tz()) && (
        <div>
          <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} color={colors.warning} />
          <span>This document has expired</span>
        </div>
      )}
    </SideModalDrawer>
  );
};

export default AddOrEditImmmunizationModal;
