import axios from 'axios';
import config from 'config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import PageWrapper from 'shared/components/PageWrapper';
import Select from 'shared/components/Select';
import './PaymentBreachFlag.scss';
import Button, { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Input from 'shared/components/TextInput/Input';
import { showToast } from 'shared/components/Toast';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import colors from '_colors.module.scss';

export function PaymentBreachFlags() {
  const [showConfirmModalFor, setShowConfirmModalFor] = useState<{ businessId: string; name: string } | null>(null);
  const { page, pageSize, skip, take, changePage, changePageSize } = useSkipTakePaging();
  const { clearFilters, searchString, usingFeatureFilter, setSearchString, setUsingFeatureFilter } = useTableFilters();
  const { businesses, totalBusinesses, loading, refetch } = useGetBusinesses({
    skip,
    take,
    searchString,
    usingFeatureFilter,
  });
  const { turnOffPaymentBreachFlagFeature, loading: turningOffFeature } = useTurnOffPaymentBreachFlagFeature();
  const handleConfirmation = useCallback(async () => {
    if (!showConfirmModalFor) return;
    try {
      await turnOffPaymentBreachFlagFeature(showConfirmModalFor.businessId);
      setShowConfirmModalFor(null);
      showToast('Success', 'success');
      refetch();
    } catch (e) {
      showToast('Something went wrong, please try again later', 'error');
    }
  }, [refetch, showConfirmModalFor, turnOffPaymentBreachFlagFeature]);
  return (
    <PageWrapper pageTitle="Disable 30% Payment Breach Flag Feature">
      <div className="payment-breach-flag__page-info">
        <p>
          Turning the feature OFF will mean the system will no longer try to flag payments for being over 30% of the
          previous two payments. It does not remove flags, or change any UI elements. It only stops the flagging system
          from flagging any more payments for the 30% over last two automated payments reason.
        </p>
      </div>
      <div className="payment-breach-flag__content-container">
        <DataTable
          showLoadingOverlay={loading}
          data={businesses ?? []}
          dataSize={totalBusinesses ?? 0}
          columns={[
            { text: 'Business', dataField: 'name' },
            {
              text: 'Breach Flag Feature',
              dataField: 'status',
              formatter: (cell) => {
                return (
                  <ColoredBackgroundTag
                    color={cell ? colors.seafoamGreen : colors.gray}
                    text={cell ? 'On' : 'Off'}
                  ></ColoredBackgroundTag>
                );
              },
            },
            {
              text: 'Turn It off!',
              dataField: 'status',
              formatter: (cell: boolean, row: { businessId: string; name: string }) => {
                return (
                  <div>
                    {cell && (
                      <Button
                        variant="outline-secondary"
                        onClick={() => setShowConfirmModalFor(row)}
                        disabled={turningOffFeature}
                      >
                        Turn it off!
                      </Button>
                    )}
                  </div>
                );
              },
            },
          ]}
          renderHeader={() => (
            <TableFilters
              onClearFilters={clearFilters}
              onSearchChange={setSearchString}
              onUsingFeatureFilterChange={setUsingFeatureFilter}
              usingFeatureFilterValue={usingFeatureFilter}
            ></TableFilters>
          )}
          activePage={page}
          onPageChange={changePage}
          pageSize={pageSize}
          onSizePerPageChange={changePageSize}
          showSelect={false}
        ></DataTable>
      </div>
      <TurnOffFeatureModal
        business={showConfirmModalFor}
        onCancel={() => setShowConfirmModalFor(null)}
        onConfirm={handleConfirmation}
      ></TurnOffFeatureModal>
    </PageWrapper>
  );
}

interface TurnOffFeatureModalProps {
  business: { name: string } | null;
  onConfirm: () => void;
  onCancel: () => void;
}
function TurnOffFeatureModal({ business, onCancel, onConfirm }: TurnOffFeatureModalProps) {
  const [inputValue, setInputValue] = useState<string | null>(null);
  const businessNameMatches = useMemo(() => {
    if (!business || !inputValue) return false;
    return inputValue.trim() === business.name.trim();
  }, [business, inputValue]);
  const handleCancel = useCallback(() => {
    setInputValue(null);
    onCancel();
  }, [onCancel]);
  const handleConfirmation = useCallback(() => {
    setInputValue(null);
    onConfirm();
  }, [onConfirm]);
  return (
    <CenteredModal
      show={!!business}
      onHide={handleCancel}
      primaryChoice="Disable Breach Payment Feature"
      primaryCallback={handleConfirmation}
      primaryButtonProps={{ disabled: !businessNameMatches }}
      secondaryChoice="Cancel"
      secondaryCallback={handleCancel}
    >
      <div>Are you sure you are ready to turn off the Breach Payments Flag Feature for {business?.name ?? ''}?</div>

      <Input
        label="Type business name here to confirm"
        value={inputValue}
        onChange={setInputValue}
        placeholder="Type business name here to confirm"
      ></Input>
    </CenteredModal>
  );
}

function useTableFilters() {
  const [searchString, setSearchString] = useState<string | null>(null);
  const handleSearchStringChange = useCallback((searchString: string | null) => {
    if (!searchString) return setSearchString(null);
    return setSearchString(searchString);
  }, []);
  const [usingFeatureFilter, setUsingFeatureFilter] = useState<string | null>(null);

  const clearFilters = useCallback(() => {
    setUsingFeatureFilter(null);
  }, []);
  return {
    clearFilters,
    searchString,
    usingFeatureFilter,
    setSearchString: handleSearchStringChange,
    setUsingFeatureFilter,
  };
}

interface TableFiltersProps {
  onClearFilters: () => void;
  onSearchChange: (search: string) => void;
  onUsingFeatureFilterChange: (value: string | null) => void;
  usingFeatureFilterValue: string | null;
}
function TableFilters({
  onClearFilters,
  onSearchChange,
  onUsingFeatureFilterChange,
  usingFeatureFilterValue,
}: TableFiltersProps) {
  return (
    <TableHeader className="payment-breach-flag__table-header">
      <div>
        <TableSearch onChange={onSearchChange}></TableSearch>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div className="payment-breach-flag__using-filter__label">Show businesses with feature</div>
        <Select
          value={usingFeatureFilterValue}
          className="payment-breach-flag__using-filter"
          options={[
            { label: 'On', value: 'ON' },
            { label: 'Off', value: 'OFF' },
          ]}
          onChange={(option) => {
            onUsingFeatureFilterChange(option.value);
          }}
        ></Select>
        <IconButtonCircle
          icon={faTimes}
          onClick={onClearFilters}
          tooltipDirection="bottom"
          tooltipText="Clear Filters"
        />
      </div>
    </TableHeader>
  );
}

function useTurnOffPaymentBreachFlagFeature() {
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);
  const [loading, setLoading] = useState<boolean>(false);
  const turnOffPaymentBreachFlagFeature = useCallback(
    async (businessId: string) => {
      setLoading(true);
      try {
        await axios.post(
          `${config.api.billing.uri}/api/v2/percentage-breach-flag/deactivate`,
          { businessId },
          { headers: { Authorization: `Bearer ${jwtToken}` } }
        );
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [jwtToken]
  );
  return { turnOffPaymentBreachFlagFeature, loading };
}

function useGetBusinesses(params: {
  skip: number;
  take: number;
  searchString?: string | null;
  usingFeatureFilter?: string | null;
}) {
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);
  const [loading, setLoading] = useState<boolean>(false);
  const [businessesResponse, setBusinessesResponse] = useState<{
    data: { id: string; name: string; status: boolean }[];
    total: number;
  }>({ total: 0, data: [] });
  const fetchBusinesses = useCallback(async () => {
    try {
      setLoading(true);
      const status = params.usingFeatureFilter ? (params.usingFeatureFilter === 'ON' ? true : false) : undefined;
      const response = await axios.post(
        `${config.api.billing.uri}/api/v2/percentage-breach-flag/search`,
        {
          skip: params.skip,
          take: params.take,
          searchText: params.searchString ?? undefined,
          status: status,
        },
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );
      setBusinessesResponse({ data: response.data.items, total: response.data.totalItems });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [jwtToken, params.searchString, params.skip, params.take, params.usingFeatureFilter]);

  useEffect(() => {
    fetchBusinesses();
  }, [fetchBusinesses]);
  return {
    businesses: businessesResponse.data,
    totalBusinesses: businessesResponse.total,
    loading: loading,
    refetch: fetchBusinesses,
  };
}

/**
 * This function is designed to help translate between our data tables that speak in pages and pageSize
 * and apis that have implemented a skip/take strategy.
 * @returns
 */
function useSkipTakePaging() {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [take, setTake] = useState<number>(25);
  const skip = useMemo(() => {
    return (pageNumber - 1) * take;
  }, [pageNumber, take]);

  const changePageSize = useCallback((pageSize: number) => {
    setPageNumber(1);
    setTake(pageSize);
  }, []);

  return { page: pageNumber, pageSize: take, changePage: setPageNumber, changePageSize, skip, take };
}
