import React from 'react';
import { SizePerPage, TableHeader } from 'shared/components/DataTable';
import GroupedFilters from 'shared/components/GroupedFilters';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import SearchInput from 'shared/components/SearchInput';
import { useMediaQuery } from '@mui/material';
import theme from 'muiTheme';
import { Col } from 'react-bootstrap';

export interface FiltersAndOptions {
  title: string;
  type: string;
  filter: ITableFilterOption[];
  options: ITableFilterOption[];
}

interface IProps {
  searchText?: string;
  setSearchText: (text: string) => void;
  paginationProps: any;
  filters: FiltersAndOptions[];
  handleFiltersSelect: (values: ITableFilterOption[], target: string) => void;
  handleClearFilters: () => void;
}

const ReEnrollAccountsTableHeader: React.FC<IProps> = ({
  searchText,
  setSearchText,
  paginationProps,
  filters,
  handleFiltersSelect,
  handleClearFilters,
  ...props
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const totalFiltersOn = filters.reduce((a, b) => a + (b.filter.length > 0 ? 1 : 0), 0);

  return (
    <TableHeader className="flex-wrap justify-content-between">
      <div className="d-flex">
        <SizePerPage paginationProps={paginationProps} />
        <SearchInput onChange={setSearchText} className={'max-width-200'} />
      </div>
      <div className="d-flex">
        {filters &&
          filters.map((filter, key) => {
            return (
              <DropdownFilter
                className={isMobile ? 'mt-2 ml-2' : 'my-0 ml-3 p-0'}
                title={filter.title}
                selectedFilters={filter.filter}
                options={filter.options}
                onFilterSelect={(val) => handleFiltersSelect(val, filter.type)}
              />
            );
          })}
      </div>
    </TableHeader>
  );
};

export default ReEnrollAccountsTableHeader;
