import { isRegion } from 'shared/util/region';
import moment from 'moment-timezone';

const AUTimezones: { label: string; value: Timezone }[] = [
  {
    label: `${moment.tz('Australia/Perth').format('z Z')} Western Time - Perth`,
    value: `Australia/Perth`,
  },
  {
    label: `${moment.tz('Australia/Darwin').format('z Z')} Central Time - Darwin`,
    value: `Australia/Darwin`,
  },
  {
    label: `${moment.tz('Australia/Adelaide').format('z Z')} Central Time - Adelaide`,
    value: `Australia/Adelaide`,
  },
  {
    label: `${moment.tz('Australia/Brisbane').format('z Z')} Eastern Time - Brisbane`,
    value: `Australia/Brisbane`,
  },
  {
    label: `${moment.tz('Australia/Sydney').format('z Z')} Eastern Time - Canberra, Melbourne, Sydney`,
    value: `Australia/Sydney`,
  },
];
const USTimezones: { label: string; value: Timezone }[] = [
  {
    label: `${moment.tz('America/New_York').format('z Z')} Eastern Time - US & Canada`,
    value: `America/New_York`,
  },
  {
    label: `${moment.tz('America/Chicago').format('z Z')} Central Time - US & Canada`,
    value: `America/Chicago`,
  },
  {
    label: `${moment.tz('America/Denver').format('z Z')} Mountain Time - US & Canada`,
    value: `America/Denver`,
  },
  {
    label: `${moment.tz('US/Arizona').format('z Z')} Arizona Time`,
    value: `US/Arizona`,
  },
  {
    label: `${moment.tz('America/Los_Angeles').format('z Z')} Pacific Time - US & Canada`,
    value: `America/Los_Angeles`,
  },
  {
    label: `${moment.tz('America/Anchorage').format('z Z')} Alaska Time`,
    value: `America/Anchorage`,
  },
  {
    label: `${moment.tz('Pacific/Honolulu').format('z Z')} Hawaii Time`,
    value: `Pacific/Honolulu`,
  },
];

const timezoneOptions = USTimezones.concat(AUTimezones);

export const getTimezoneOptions = () => {
  if (isRegion('AU')) return AUTimezones;
  return USTimezones;
};

export default timezoneOptions;
