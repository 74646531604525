import TextInput from './TextInput';
import PasswordInput from './PasswordInput';
import NumberInput from './NumberInput';
import CreatePasswordInput from './CreatePasswordInput';
import EmailInput from './EmailInput';
import CodeInputs from './CodeInputs';
import RatioInput from './RatioInput';
import MaskedNumber from './MaskedNumber';
import StatefulNumberInput from './StatefulNumberInput';
import MaskedDateTextInput from './MaskedDateTextInput';

export {
  RatioInput,
  PasswordInput,
  MaskedDateTextInput,
  NumberInput,
  CreatePasswordInput,
  EmailInput,
  CodeInputs,
  MaskedNumber,
  StatefulNumberInput,
};

export default TextInput;
