import React, { useState } from 'react';
import momentTz from 'moment-timezone';
import * as Mui from '@mui/material';
import moment from 'moment';
import { Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../store/reducers';
import EnrollmentPeriodTab from '../EnrollmentPeriodTab/EnrollmentPeriodTab';
import { useTranslation } from 'react-i18next';

interface IProps {
  availability: IClassAvailability[];
  centerTimezone: string;
  isFullDayClass: boolean;
}

const ClassAvailabilityTable: React.FC<IProps> = ({ availability, centerTimezone, isFullDayClass, ...props }) => {
  // new Date(b) - new Date(a) is only valid in JavaScript and not TypeScript when using to sort
  const amOpenSpots =
    availability.length > 0
      ? availability
          .filter((a) => {
            /**
             * the api returns the time as a datetime at utc midnight but passing through momentTz
             * with the trailing +00 is causing the date to go to the previous date so we're going to only use
             * everything before it
             *
             * ex: mz('2020-10-07T00:00:00+00:00').tz('America/New_York', true) would return a date for Oct 06 2020 16:00:00 GMT-0400
             */
            const date: string = a.date.indexOf('+') !== -1 ? a.date.split('+')[0] : a.date;
            const dateMoment = momentTz(date).tz(centerTimezone, true);

            return dateMoment.day() > 0 && dateMoment.day() < 6;
          }) // we are only showing Monday-Friday
          .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
          .map((a) => a.openSpotsAm)
      : [0, 0, 0, 0, 0]; // provide a default empty value array

  const pmOpenSpots =
    availability.length > 0
      ? availability
          .filter((a) => {
            const date: string = a.date.indexOf('+') !== -1 ? a.date.split('+')[0] : a.date;
            const dateMoment = momentTz(date).tz(centerTimezone, true);

            return dateMoment.day() > 0 && dateMoment.day() < 6;
          })
          .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
          .map((a) => a.openSpotsPm)
      : [0, 0, 0, 0, 0];

  const startDate = useSelector((state: RootState) => state.enrollment.activeTimeframe?.start);
  const endDate = useSelector((state: RootState) => state.enrollment.activeTimeframe?.end);

  const amPmCell = [
    ['AM', 'PM'],
    ['AM', 'PM'],
    ['AM', 'PM'],
    ['AM', 'PM'],
    ['AM', 'PM'],
  ];

  const { t } = useTranslation('translation');

  return (
    <>
      <Tabs defaultActiveKey="currentPeriod" className="classAvailbilityTabs pt-0">
        <Tab eventKey="currentPeriod" title={[moment(startDate).format('MMM D'), ' - ', moment(endDate).format(' D')]}>
          <Mui.TableContainer>
            <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
              <Mui.TableHead>
                <Mui.TableRow sx={{ backgroundColor: isFullDayClass ? '#F1F4F8' : 'white' }}>
                  {/* sunday = 0, monday = 1 etc */}
                  <Mui.TableCell sx={{ padding: 0, fontWeight: 'bold' }} align="center">
                    {moment(startDate).tz(centerTimezone).day(1).format('ddd, MMM D')}
                  </Mui.TableCell>
                  <Mui.TableCell sx={{ padding: 0, fontWeight: 'bold' }} align="center">
                    {moment(startDate).tz(centerTimezone).day(2).format('ddd, MMM D')}
                  </Mui.TableCell>
                  <Mui.TableCell sx={{ padding: 0, fontWeight: 'bold' }} align="center">
                    {moment(startDate).tz(centerTimezone).day(3).format('ddd, MMM D')}
                  </Mui.TableCell>
                  <Mui.TableCell sx={{ padding: 0, fontWeight: 'bold' }} align="center">
                    {moment(startDate).tz(centerTimezone).day(4).format('ddd, MMM D')}
                  </Mui.TableCell>
                  <Mui.TableCell sx={{ padding: 0, fontWeight: 'bold' }} align="center">
                    {moment(startDate).tz(centerTimezone).day(5).format('ddd, MMM D')}
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              {!isFullDayClass && (
                <Mui.TableRow sx={{ backgroundColor: '#F1F4F8', height: '5%' }}>
                  {amPmCell?.map((row) => (
                    <Mui.TableCell sx={{ padding: 0 }} align="center">
                      <Mui.Typography
                        color="black"
                        fontWeight="bold"
                        display="flex"
                        marginX="3rem"
                        justifyContent="space-evenly"
                      >
                        <span>{row[0]}</span>
                        {row[1]}
                      </Mui.Typography>
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              )}
              <Mui.TableBody>
                <Mui.TableRow>
                  {amOpenSpots?.map((num, index) => (
                    <Mui.TableCell align="center">
                      {isFullDayClass ? (
                        <Mui.Typography fontWeight="bold" align="center">
                          {num}
                        </Mui.Typography>
                      ) : (
                        <Mui.Typography display="flex" fontWeight="bold" marginX="2rem" justifyContent="space-evenly">
                          <span>{num}</span> {pmOpenSpots[index]}
                        </Mui.Typography>
                      )}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        </Tab>
        <Tab eventKey="enrollment" title={`${t('spelling.enrollment')} Period`}>
          <EnrollmentPeriodTab availability={availability} isFullDayClass={isFullDayClass} />
        </Tab>
      </Tabs>
    </>
  );
};

export default ClassAvailabilityTable;
