import React from 'react';
import { CreateProgramSteps, useProgramsContext } from '../../context/ProgramsContext';
import { useTranslation } from 'react-i18next';

import ProgramModal from '../ProgramModal';

const EditProgramModal: React.FC = () => {
  const {
    programFormData,
    isDateValid,
    isEditLoading,
    step,
    formErrors,
    isEditOpen,
    programGroups,
    handleEditProgram,
    setIsEditOpen,
    resetProgramFormData,
    setStep,
    setProgramFormData,
  } = useProgramsContext();

  const { t } = useTranslation();

  const modalTitle =
    step === CreateProgramSteps.programDetails
      ? t('enrollment.programs.modal.step-1-title', {
          modalTitle: t('enrollment.programs.edit-program'),
        })
      : t('enrollment.programs.modal.step-2-title', {
          modalTitle: t('enrollment.programs.edit-program'),
        });

  return (
    <ProgramModal
      confirmTitle={t('enrollment.programs.edit-program')}
      formData={programFormData}
      isDateValid={isDateValid}
      loading={isEditLoading}
      handleSubmit={handleEditProgram}
      setIsModalOpen={setIsEditOpen}
      step={step}
      setStep={setStep}
      formErrors={formErrors}
      isModalOpen={isEditOpen}
      modalTitle={modalTitle}
      resetForm={resetProgramFormData}
      setFormData={setProgramFormData}
      programGroups={programGroups}
    />
  );
};

export default EditProgramModal;
