import * as types from './types';

export const recoverPasswordRequest = (email) => ({ type: types.RECOVER_PASSWORD_REQUEST, email });
export const recoverPasswordSuccess = (CodeDeliveryDetails) => ({
  type: types.RECOVER_PASSWORD_SUCCESS,
  CodeDeliveryDetails,
});
export const recoverPasswordFailure = (error) => ({ type: types.RECOVER_PASSWORD_FAILURE, error });

export const createNewPasswordRequest = (email, code, newPassword) => ({
  type: types.CREATE_NEW_PASSWORD_REQUEST,
  email,
  code,
  newPassword,
});
export const createNewPasswordSuccess = () => ({ type: types.CREATE_NEW_PASSWORD_SUCCESS });
export const createNewPasswordFailure = (error) => ({ type: types.CREATE_NEW_PASSWORD_FAILURE, error });

export const clearReduxState = () => ({ type: types.CLEAR_REDUX_STATE });
