import React from 'react';
//components
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
//utils
import { useTranslation } from 'react-i18next';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';

interface IProps {
  isOpen: boolean;
  onHide: () => void;
  currentCenterId: string;
  setCurrentCenterId: (p: any) => void;
  setEnquiryFormOpen: (b: boolean) => void;
}

export const AddEnquiryModal: React.FC<IProps> = ({
  isOpen,
  onHide,
  currentCenterId,
  setCurrentCenterId,
  setEnquiryFormOpen,
}) => {
  const { t } = useTranslation();
  const { data: centers, loading: centersLoading } = useGetActiveCentersWithLoading();

  return (
    <div>
      <SideModalDrawer
        title={t('enrollment.lead-management.add-enquiry')}
        show={isOpen}
        primaryChoice={t('enrollment.lead-management.add-enquiry')}
        onHide={onHide}
        primaryCallback={() => setEnquiryFormOpen(true)}
        primaryButtonProps={{ disabled: !currentCenterId }}
      >
        <div className="mt-2 mb-2">
          <Select
            isLoading={centersLoading}
            className="mb-0 mr-4 flex-grow-0 min-width-200"
            options={centers?.map((b) => ({ value: b.id, label: b.name })) ?? []}
            value={currentCenterId}
            onChange={(option) => {
              setCurrentCenterId(option.value ?? '');
            }}
          />
        </div>
      </SideModalDrawer>
    </div>
  );
};
