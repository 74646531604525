import { glCodeMappingFields } from 'gql/glCode/fields';

export const adjustmentFields: string = `
  id
  businessId
  name
  glCode
  type
  archivedAt
  businessId
  glCodeMapping {
    ${glCodeMappingFields}
  }
`;
