import { MutationHookOptions, MutationTuple } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { InputVariable } from '../program/mutation';
import { gql } from '@apollo/client';
import { useMutation } from '../../shared/apis/core';
import { showToast } from '../../shared/components/Toast';

//region UPSERT_TRANSACTION_TYPE_AUTHORIZATION_ROLES
const UPSERT_TRANSACTION_TYPE_AUTHORIZATION_ROLES = () => gql`
  mutation ($input: TransactionTypeAuthorizationRolesInput!) {
    upsertTransactionTypeAuthorizationRoles(input: $input) {
      transactionTypeId
      roleIds
    }
  }
`;

export interface IUpsertTransactionTypeAuthorizationRolesData {
  upsertTransactionTypeAuthorizationRoles: ITransactionTypeAuthorizationRolesUpsertResponseDto;
}

export const useUpsertTransactionTypeAuthorizationRoles = (
  options?: MutationHookOptions<
    IUpsertTransactionTypeAuthorizationRolesData,
    InputVariable<ITransactionTypeAuthorizationRolesInput>
  >
): MutationTuple<
  IUpsertTransactionTypeAuthorizationRolesData,
  InputVariable<ITransactionTypeAuthorizationRolesInput>
> =>
  useMutation<IUpsertTransactionTypeAuthorizationRolesData, InputVariable<ITransactionTypeAuthorizationRolesInput>>(
    UPSERT_TRANSACTION_TYPE_AUTHORIZATION_ROLES(),
    {
      ...options,
      onError: (error: ApolloError) => {
        showToast(error.message, 'error');
      },
    }
  );
//endregion

//region DELETE_TRANSACTION_TYPE_AUTHORIZATION_ROLES
const DELETE_TRANSACTION_TYPE_AUTHORIZATION_ROLES = () => gql`
  mutation ($input: TransactionTypeAuthorizationRolesDeleteInput!) {
    deleteTransactionTypeAuthorizationRoles(input: $input)
  }
`;

export interface IDeleteTransactionTypeAuthorizationRolesData {
  deleteTransactionTypeAuthorizationRoles: boolean;
}

export const useDeleteTransactionTypeAuthorizationRoles = (
  options?: MutationHookOptions<
    IDeleteTransactionTypeAuthorizationRolesData,
    InputVariable<ITransactionTypeAuthorizationRolesDeleteInput>
  >
): MutationTuple<
  IDeleteTransactionTypeAuthorizationRolesData,
  InputVariable<ITransactionTypeAuthorizationRolesDeleteInput>
> =>
  useMutation<
    IDeleteTransactionTypeAuthorizationRolesData,
    InputVariable<ITransactionTypeAuthorizationRolesDeleteInput>
  >(DELETE_TRANSACTION_TYPE_AUTHORIZATION_ROLES(), {
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });
//endregion
