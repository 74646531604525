import React from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  isOpen: boolean;
  serviceFeeGroup: IServiceFeeGroup;
  onClose: () => void;
  onConfirm: (id: string) => void;
}

const DeleteServiceFeeGroupModal: React.FC<IProps> = ({ isOpen, serviceFeeGroup, onClose, onConfirm, ...props }) => {
  return (
    <ConfirmationModal
      title="Delete Service Fee Group?"
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Delete"
      primaryCallback={() => onConfirm(serviceFeeGroup.id)}
      primaryButtonProps={{
        variant: 'danger',
      }}
    >
      <div>
        <p>Are you sure you want to delete this service fee group?</p>
        <p>
          <b>{serviceFeeGroup.serviceFeeGroupCenters.length}</b> centers currently use this service fee group.
        </p>
        <p>Any centers using the group will no longer use it to generate their fees.</p>
      </div>
    </ConfirmationModal>
  );
};

export default DeleteServiceFeeGroupModal;
