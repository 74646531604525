import React, { useState, useMemo } from 'react';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Checkbox from 'shared/components/Checkbox';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import TextInput from 'shared/components/TextInput';
import AccountSelect from 'shared/components/Select/AccountSelect';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (input: ICasualBookingExclusionsInput) => Promise<void>;
  onClose: () => void;
  centersData: ICenterClassesCasualBookingSettings[] | undefined;
}

const AddFamilyExclusionsModal: React.FC<IProps> = ({ isOpen, isLoading, onSubmit, onClose, centersData }) => {
  const { t } = useTranslation('translation');
  const [selectedCenterIds, setSelectedCenterIds] = useState<string[] | null>(null);
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[] | null>([]);
  const [selectedAccountStatus, setSelectedAccountStatus] = useState({
    includeActiveAccounts: false,
    includeInactiveAccounts: false,
  });

  const statusType = useMemo(() => {
    const { includeActiveAccounts, includeInactiveAccounts } = selectedAccountStatus;

    // when both false or both true
    if (includeActiveAccounts === includeInactiveAccounts) return 'All';

    if (includeActiveAccounts) return 'Active';
    if (includeInactiveAccounts) return 'Inactive';

    return 'All';
  }, [selectedAccountStatus]);

  const [reason, setReason] = useState('');

  const handleClose = () => {
    setSelectedAccountIds([]);
    setReason('');
    onClose();
  };

  const handleSubmit = (input: ICasualBookingExclusionsInput) => {
    onSubmit(input).then(() => handleClose());
  };

  const isInputInvalid = !selectedAccountIds?.length;

  const excludedAccountIds = useMemo(() => {
    return (centersData ?? []).reduce<string[]>(
      (acc, center) => [...acc, ...center.casualBookingExclusions.map((exclusion) => exclusion.accountId)],
      []
    );
  }, [centersData]);

  return (
    <SideModalDrawer
      title={t('attendance.settings.booking-rules.families.modal.title')}
      show={isOpen}
      onHide={handleClose}
      primaryChoice={t('attendance.settings.booking-rules.families.modal.primary-button-text')}
      primaryCallback={() => handleSubmit({ accountIds: selectedAccountIds ?? [], reason })}
      secondaryCallback={handleClose}
      primaryButtonProps={{ loading: isLoading, disabled: isInputInvalid }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            useNullForAllOption
            selectedCenterIds={selectedCenterIds}
            onSelect={(centerIds) => setSelectedCenterIds(centerIds)}
          />
        </Col>
      </Row>
      <Row className="pl-2">{t('spelling.accountStatusFilter')}</Row>
      <Row className="pl-2">
        <Checkbox
          label={capitalize(t('spelling.active'))}
          value={selectedAccountStatus.includeActiveAccounts}
          onChange={(value) => setSelectedAccountStatus((prev) => ({ ...prev, includeActiveAccounts: value }))}
        />
      </Row>
      <Row className="pl-2">
        <Checkbox
          label={capitalize(t('spelling.inactive'))}
          value={selectedAccountStatus.includeInactiveAccounts}
          onChange={(value) => setSelectedAccountStatus((prev) => ({ ...prev, includeInactiveAccounts: value }))}
        />
      </Row>
      <Row className="pt-2">
        <Col>
          <AccountSelect
            selectedAccountIds={selectedAccountIds}
            onSelect={(accountIds) => setSelectedAccountIds(accountIds)}
            required={true}
            disableAllOption={true}
            isMulti={true}
            centerIds={selectedCenterIds}
            statusType={statusType}
            excludedAccountIds={excludedAccountIds}
            appearance="detailed"
            showAccountStatus={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            label={t('attendance.settings.booking-rules.families.modal.reason-label')}
            value={reason}
            onChange={(v) => setReason(v)}
            as="textarea"
            placeholder={t('attendance.settings.booking-rules.families.modal.reason-input-placeholder')}
            rows={8}
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default AddFamilyExclusionsModal;
