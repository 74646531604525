import moment from 'moment';
import React, { useCallback, useState } from 'react';
import Form from 'react-bootstrap/Form';
import AddressInput from 'shared/components/AddressInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import PhoneInput from 'shared/components/PhoneInput';
import Select from 'shared/components/Select';
import TextInput, { EmailInput } from 'shared/components/TextInput';
import { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { getTimezoneOptions } from 'shared/constants/dropdownOptions/timezoneOptions';
import { dataType } from 'shared/constants/enums/dataTypeEnum';
import { isEmailValid } from 'shared/util/email';
import { omitFalsy } from 'shared/util/object';
import { isBlank, isValidPhoneNumber } from 'shared/util/string';

interface INewEntityShape {
  name: string;
  phoneNumber: string;
  accountOwnerFirstName: string;
  accountOwnerLastName: string;
  accountOwnerEmail: string;
  address: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  timezone: string;
  dataType: DataType | null;
}

interface IProps {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (business: INewEntityShape) => void;
}

const NewBusinessModalForm: React.FC<IProps> = ({ isOpen, loading, onClose, onSubmit, ...props }) => {
  // `state` in address is an object since it's used with react-select and it expects its value to be an object
  const [newBusinessFormData, setNewBusinessFormData] = useState({
    businessName: '',
    businessPhoneNumber: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      country: DEFAULT_COUNTRY,
    },
    timezone: moment.tz.guess(),
    accountOwnerFirstName: '',
    accountOwnerLastName: '',
    accountOwnerEmail: '',
    dataType: null,
  });

  const handleInput = useCallback(
    (name, value) => {
      setNewBusinessFormData({
        ...newBusinessFormData,
        [name]: value,
      });
    },
    [newBusinessFormData]
  );

  const validateFormData = () => {
    return (
      !isBlank(newBusinessFormData.businessName) &&
      isValidPhoneNumber(newBusinessFormData.businessPhoneNumber) &&
      !isBlank(newBusinessFormData.accountOwnerFirstName) &&
      !isBlank(newBusinessFormData.accountOwnerLastName) &&
      isEmailValid(newBusinessFormData.accountOwnerEmail) &&
      !!newBusinessFormData.dataType
    );
  };

  return (
    <SideModalDrawer
      title="New Business"
      subtitle="All fields are required"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Save"
      primaryCallback={() =>
        onSubmit({
          name: newBusinessFormData.businessName,
          phoneNumber: newBusinessFormData.businessPhoneNumber,
          // @ts-ignore - Ignoring omitFalsy return type
          address: omitFalsy(newBusinessFormData.address),
          accountOwnerFirstName: newBusinessFormData.accountOwnerFirstName,
          accountOwnerLastName: newBusinessFormData.accountOwnerLastName,
          accountOwnerEmail: newBusinessFormData.accountOwnerEmail,
          timezone: newBusinessFormData.timezone,
          dataType: newBusinessFormData.dataType,
        })
      }
      primaryButtonProps={{
        disabled: !validateFormData() || loading,
        loading: loading,
      }}
      secondaryChoice="Save, Add Another"
      secondaryCallback={() => console.log('disaptch action and rest form')}
      secondaryButtonProps={{
        disabled: !validateFormData() || loading,
      }}
      closeOnPrimaryCallback={false}
    >
      <Form>
        <h6 className="text-info mb-4">BUSINESS DETAILS</h6>
        <TextInput
          name="business"
          required
          label="Business Name"
          value={newBusinessFormData.businessName}
          onChange={(value, name) => handleInput('businessName', value)}
        />
        <Row>
          <Col xs={8}>
            <PhoneInput
              required
              name="business-ph"
              label="Business Phone Number"
              value={newBusinessFormData.businessPhoneNumber}
              onChange={(value, name) => handleInput('businessPhoneNumber', value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <AddressInput
              required
              disabled={false}
              addressLabel="Business Address"
              address={newBusinessFormData.address}
              onChange={(value: any) => handleInput('address', value)}
              errorText=""
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              required
              label="Time Zone"
              value={newBusinessFormData.timezone}
              options={getTimezoneOptions()}
              onChange={(option) => handleInput('timezone', option.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              required
              label="Business Data Type"
              value={newBusinessFormData.timezone}
              options={Object.entries(dataType).map(([key, value]) => value)}
              onChange={(option) => handleInput('dataType', option.value)}
            />
          </Col>
        </Row>
        <h6 className="text-info mb-4">ACCOUNT OWNER</h6>
        <Row>
          <Col>
            <TextInput
              required
              name="owner-fn"
              label="First Name"
              value={newBusinessFormData.accountOwnerFirstName}
              onChange={(value, name) => handleInput('accountOwnerFirstName', value)}
            />
          </Col>
          <Col>
            <TextInput
              required
              name="owner-ln"
              label="Last Name"
              value={newBusinessFormData.accountOwnerLastName}
              onChange={(value, name) => handleInput('accountOwnerLastName', value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EmailInput
              required
              name="owner-ph"
              label="Email"
              value={newBusinessFormData.accountOwnerEmail}
              onChange={(value, name) => handleInput('accountOwnerEmail', value)}
            />
          </Col>
        </Row>
      </Form>
    </SideModalDrawer>
  );
};

export default NewBusinessModalForm;
