import React, { useState } from 'react';
import DeleteTrainingTimeEventModal from '../TimeLogs/components/TrainingHolidayTab/DeleteTrainingTimeEventModal';
import DayTimeCard from './DayTimeCard';
import DetailsTimeEntryModal from './DetailsTimeEntryModal';
import { IDailyAttendanceForDate } from './TimeCards';

interface IModalShape<T> {
  isOpen: boolean;
  data: T | null;
}

interface IProps {
  dailyAttendances: IDailyAttendanceForDate[];
  personId: string;
  centerId: string;
  positionOptions: IStaffPosition[];
  isLoading: boolean;
  onSuccessfulTimeEntryAdjustment: (timeEntry: ITimeEntry, clockedOutTimeEntry: ITimeEntry | null) => void;
  onDeleteTrainingTimeEvent: (trainingTimeEvent: ITrainingHolidayEvent) => void;
  isMyTimeCard?: boolean;
}

const DetailedTimeCardView: React.FC<IProps> = ({
  dailyAttendances,
  personId,
  centerId,
  positionOptions,
  isLoading,
  onSuccessfulTimeEntryAdjustment,
  onDeleteTrainingTimeEvent,
  isMyTimeCard,
  ...props
}) => {
  const [showDetailModal, setShowDetailModal] = useState<IModalShape<ITimeEntry>>({ isOpen: false, data: null });
  const [showDeleteTrainingTimeModal, setShowDeleteTrainingTimeModal] = useState<IModalShape<ITrainingHolidayEvent>>({
    isOpen: false,
    data: null,
  });

  return (
    <>
      {dailyAttendances.map((dailyAttendance, idx) => (
        <DayTimeCard
          key={`attendance-card-${idx}`}
          date={dailyAttendance.date}
          dailyAttendance={dailyAttendance.attendance}
          isLoading={isLoading}
          personId={personId}
          centerId={centerId}
          positionOptions={positionOptions}
          onViewTimeEntryDetail={(timeEntry) => setShowDetailModal({ isOpen: true, data: timeEntry })}
          onSuccessfulTimeEntryAdjustment={onSuccessfulTimeEntryAdjustment}
          onDeleteTrainingTimeEvent={(trainingTimeEvent) =>
            setShowDeleteTrainingTimeModal({ isOpen: true, data: trainingTimeEvent })
          }
          isMyTimeCard={isMyTimeCard}
        />
      ))}
      {showDetailModal.isOpen && (
        <DetailsTimeEntryModal
          isOpen={showDetailModal.isOpen}
          timeEntry={showDetailModal.data}
          onClose={() => setShowDetailModal({ isOpen: false, data: null })}
        />
      )}
      {showDeleteTrainingTimeModal.isOpen && (
        <DeleteTrainingTimeEventModal
          isOpen={showDeleteTrainingTimeModal.isOpen}
          trainingTimeEvent={showDeleteTrainingTimeModal.data}
          onClose={() => setShowDeleteTrainingTimeModal({ isOpen: false, data: null })}
          employeeIdToRemoveFromTrainingTime={personId}
        />
      )}
    </>
  );
};

export default DetailedTimeCardView;
