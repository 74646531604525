import React from 'react';
import PropTypes from 'prop-types';
import useUniqueId from 'shared/hooks/useUniqueId';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-light-svg-icons';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const MaskedNumber = React.forwardRef((props, ref) => {
  const {
    id,
    onChange,
    onFocus,
    onBlur,
    mask,
    prefix = '',
    suffix = '',
    maxLength,
    autoFocus,
    isInvalid,
    helperText,
    required,
    label,
    errorText,
    className,
    children,
    ...rest
  } = props;
  const controlId = useUniqueId(id);

  const [isFocused, setFocus] = React.useState(autoFocus);

  const handleChange = React.useCallback(
    (e) => {
      const numbersOnly = e.target.value.replace(/\D/g, '');
      onChange && onChange(numbersOnly, e.target.name);
    },
    [onChange]
  );

  const handleFocus = React.useCallback(
    (e) => {
      setFocus(true);
      onFocus && onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = React.useCallback(
    (e) => {
      setFocus(false);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  return (
    <Form.Group controlId={controlId} className={`${props.small ? 'small-input' : ''} ${props.className || ''}`}>
      <div className="d-flex flex-row">
        {label && <Form.Label>{label}</Form.Label>}
        {required && <FontAwesomeIcon className="ml-1" icon={faAsterisk} color="#FF2C2C" size="xs" />}
      </div>
      <MaskedInput
        {...rest}
        ref={ref}
        maxLength={maxLength && (maxLength + suffix.length + prefix.length).toString()}
        mask={
          mask ||
          createNumberMask({
            prefix,
            suffix,
          })
        }
        className="form-control"
        guide={true}
        required={required}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoFocus={autoFocus}
      />
      {children}
      {helperText && (
        <Collapse in={isFocused}>
          <Form.Text className="text-muted">{helperText}</Form.Text>
        </Collapse>
      )}
      {<Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>}
    </Form.Group>
  );
});

MaskedNumber.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  small: PropTypes.bool,
};

MaskedNumber.defaultProps = {
  type: 'text',
};

export default MaskedNumber;
