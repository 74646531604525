import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { getFullName } from 'shared/util/string';
import { useArchiveChildOnAccount, useRemoveContactFromAccount } from 'gql/account/mutations';

interface IProps {
  isOpen: boolean;
  child: IAccountChild;
  onClose: () => void;
  account: IAccount;
}

const ArchiveChildOnAccountModal: React.FC<IProps> = ({ isOpen, child, onClose, account, ...props }) => {
  const [archiveChildFn, { loading }] = useArchiveChildOnAccount(account.id, child.id);
  const handleSubmit = useCallback(() => {
    archiveChildFn()
      .then(() => {
        showToast('Child archived successfully.', 'success');
        onClose();
      })
      .catch((error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      });
  }, []);

  return (
    <ConfirmationModal
      title="Archive Child on Account?"
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Archive"
      primaryCallback={handleSubmit}
      primaryButtonProps={{ variant: 'danger', loading }}
    >
      <p>
        When {getFullName(child)} is archived on this account all historical data will remain accessible and all related
        contact permissions will be removed from this child.
      </p>
      <p>Are you sure you want to archive {getFullName(child)} from this account?</p>
    </ConfirmationModal>
  );
};

export default ArchiveChildOnAccountModal;
