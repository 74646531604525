import colors from '_colors.module.scss';

export enum ISCaseClaimStatusEnum {
  DRAFT = 'DRAFT',
  READY_FOR_SUBMISSION = 'READY_FOR_SUBMISSION',
  SUBMITTED = 'SUBMITTED',
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  CANCELLATION_REQUIRED = 'CANCELLATION_REQUIRED',
  CANCELLED = 'CANCELLED',
  REJECTED_LATE_SUBMISSION = 'REJECTED_LATE_SUBMISSION',
}

export enum ISCaseClaimLabelEnum {
  DRAFT = 'Draft',
  READY_FOR_SUBMISSION = 'Ready for Submission',
  SUBMITTED = 'Submitted',
  PROCESSED = 'Processed',
  REJECTED = 'Rejected',
  CANCELLATION_REQUIRED = 'Cancellation Required',
  CANCELLED = 'Cancelled',
  REJECTED_LATE_SUBMISSION = 'Rejected Late Submission',
}

export const ISCaseClaimStatusFormatting: Record<ISCaseClaimStatus, { color: string; colorName: string }> = {
  DRAFT: { color: colors.gray, colorName: 'gray' },
  READY_FOR_SUBMISSION: { color: colors.warning, colorName: 'warning' },
  SUBMITTED: { color: colors.warning, colorName: 'warning' },
  PROCESSED: { color: colors.success, colorName: 'success' },
  REJECTED: { color: colors.danger, colorName: 'danger' },
  CANCELLATION_REQUIRED: { color: colors.gray, colorName: 'gray' },
  CANCELLED: { color: colors.gray, colorName: 'gray' },
  REJECTED_LATE_SUBMISSION: { color: colors.danger, colorName: 'danger' },
};

export enum ISClaimServiceProvidedLabelEnum {
  NOT_FACE_TO_FACE = 'Non-F2F',
  FACE_TO_FACE = 'F2F',
}

export enum ISCasePaymentStatusFromFilter {
  pending = 'PENDING',
  rejected = 'REJECTED',
  paid = 'PAID',
}

export const ISCasePaymentTypes = [
  'IDF Subsidy-Centre Based Care',
  'IDF Subsidy-Pupil Free Day',
  'IDF Subsidy-Immediate Support',
];
