import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import BusRosterInfromationCard from 'shared/components/BusRoster/BusRosterInformationCard';
import Button from 'shared/components/Buttons';
import { upperCase } from 'lodash';
import useDatatableState from 'shared/hooks/useDatatableState';
import { useGetBusRosterAvailableChildren, useGetBusRosterById } from 'gql/busRoster/queries';
import BusRosterChildrenTable from 'shared/components/BusRoster/BusRosterChildrenTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import { getBusRosterSuccess } from './duck/actions';
import { isBlank } from 'shared/util/string';
import { useUpdateBusRoster } from 'gql/busRoster/mutations';
import { omitTypename } from 'shared/util/object';
import { showToast } from 'shared/components/Toast';
import cast from 'shared/util/cast';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import CreateBusRosterDestinationModal from 'shared/components/BusRoster/CreateBusRosterDestinationModal';
import BusRosterDestinationCards from 'shared/components/BusRoster/BusRosterDestinationCards';
import { Badge, Tab, Tabs } from 'react-bootstrap';
import useUniqueId from 'shared/hooks/useUniqueId';
import { useGetClassesForCenter } from 'gql/center/queries';

interface IRouteProps {
  id?: string;
}
interface IProps
  extends RouteComponentProps<
    {
      id?: string;
    },
    any,
    IRouteProps
  > {}

const EditBusRoster: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const busRosterId = props.match.params.id ?? '';
  const { loading, error } = useGetBusRosterById({
    variables: {
      id: busRosterId,
    },
    onCompleted: (data: any) => {
      dispatch(getBusRosterSuccess(data.getBusRosterById));
      setFormData((prev) => ({
        ...prev,
        businessId: data.getBusRosterById.businessId,
        centerId: data.getBusRosterById.centerId,
        destinations:
          data.getBusRosterById.destinations.length == 0
            ? []
            : data.getBusRosterById.destinations.map((d: any) => {
                return d.id;
              })!,
        effectiveDate: data.getBusRosterById.effectiveDate,
        endDate: data.getBusRosterById.endDate,
        id: data.getBusRosterById.id,
        name: data.getBusRosterById.name,
        type: data.getBusRosterById.type,
      }));
      setDestinationData(
        data.getBusRosterById.destinations.map((d: any) => {
          return { value: d.id, label: d.name };
        })
      );

      setLoadedChildrenData(data.getBusRosterById.children);
    },
  });
  const [showCreateBusRosterDestinationModal, setShowCreateBusRosterDestinationModal] =
    useState<ICreateBusRosterDestinationShape>({ isOpen: false });
  const [destinationData, setDestinationData] = useState<IDestinationStateShape[]>([]);
  const [loadedChildrenData, setLoadedChildrenData] = useState<IBusRosterAccountChild[]>([]);
  const reduxBusRoster: IBusRoster | null =
    useSelector((state: RootState) => state.busRosters.byId)[busRosterId] ?? null;
  const busRoster = !loading && reduxBusRoster ? reduxBusRoster : undefined;
  const tabId: string = useUniqueId('bus-roster-edit-tab-group');

  const [formData, setFormData] = useState<IBusRosterInformationForm>({
    id: busRoster?.id ?? '',
    businessId: busRoster?.businessId ?? '',
    centerId: busRoster?.centerId ?? '',
    destinations:
      busRoster?.destinations.length == 0
        ? []
        : busRoster?.destinations?.map((d) => {
            return d.name;
          })!,
    effectiveDate: busRoster?.effectiveDate ?? '',
    endDate: busRoster?.endDate ?? '',
    name: busRoster?.name ?? '',
    type: busRoster?.type ?? '',
  });

  const incompleteGraphqlRequestVariables: boolean = !formData.centerId || !formData.effectiveDate || !formData.endDate;
  const incompleteClassesRequestVariables: boolean = !formData.centerId;

  const validBusRosterForm = useCallback(() => {
    const validStartDate: boolean = !!moment(formData.effectiveDate).startOf('day').toISOString();
    const validTimeframe: boolean = formData.endDate
      ? moment(formData.endDate).isSameOrAfter(moment(formData.effectiveDate))
      : true;

    return (
      !isBlank(formData.name) &&
      !isBlank(formData.businessId) &&
      !isBlank(formData.centerId) &&
      !isEmpty(formData.destinations) &&
      !isBlank(formData.effectiveDate) &&
      !isBlank(formData.endDate) &&
      !isBlank(formData.type) &&
      validStartDate &&
      validTimeframe
    );
  }, [formData]);

  useEffect(() => {
    // If the center, effective date, and end date fields all have values, and either of them have a changed value, refetch the available children data
    if (!isBlank(formData?.centerId) && !isBlank(formData?.effectiveDate) && !isBlank(formData?.endDate)) {
      refetchAvailableChildrenData();
    }
  }, [formData.centerId, formData.effectiveDate, formData.endDate]);

  useEffect(() => {
    if (!isBlank(formData?.type)) {
      setUnassignedChildrenData((prev) =>
        prev.map((child) => {
          return {
            ...child,
            input: {
              ...child.input,
              type: formData.type,
            },
          };
        })
      );

      setAssignedChildrenData((prev) =>
        prev.map((child) => {
          return {
            ...child,
            input: {
              ...child.input,
              type: formData.type,
            },
          };
        })
      );
    }
  }, [formData.type]);

  useEffect(() => {
    if (!isBlank(formData?.centerId)) {
      refetchClassesData();
    }
  }, [formData.centerId]);

  const [classData, setClassData] = useState<IClass[]>([]);
  const { loading: classesLoading, refetch: refetchClassesFn } = useGetClassesForCenter({
    variables: { centerId: formData?.centerId },
    fetchPolicy: 'network-only',
    skip: incompleteClassesRequestVariables,
    onCompleted: (result) => {
      setClassData(result.getClassesForCenter.filter((c) => !c.archivedAt) ?? []);
    },
    onError: (err) => {
      showToast(err, 'error');
    },
  });

  const refetchClassesData = () => {
    refetchClassesFn();
  };

  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'lastName', direction: 'ASCENDING' }];
  const [unassignedTableState, unassignedTableFunctions] = useDatatableState('busRosterChildren', initalTableSort);
  const [assignedTableState, assignedTableFunctions] = useDatatableState('busRosterChildren', initalTableSort);

  const [availableChildrenData, setAvailableChildrenData] = useState<IBusRosterAccountChildGridShape[]>([]);
  const [unassignedChildrenData, setUnassignedChildrenData] = useState<IBusRosterAccountChildGridShape[]>([]);
  const [assignedChildrenData, setAssignedChildrenData] = useState<IBusRosterAccountChildGridShape[]>([]);
  const {
    loading: availableChildrenLoading,
    networkStatus,
    refetch: refetchAvailableChildrenFn,
  } = useGetBusRosterAvailableChildren({
    variables: {
      input: {
        centerId: formData.centerId,
        effectiveDate: moment(formData.effectiveDate).format('YYYY-MM-DD'),
        endDate: moment(formData.endDate).format('YYYY-MM-DD'),
      },
    },
    skip: incompleteGraphqlRequestVariables,
    onCompleted: (result) => {
      const formattedChildrenData: IBusRosterAccountChildGridShape[] = result.getAvailableChildrenForBusRoster.map(
        (child) => {
          let scheduleSlots: ICreateBusRosterAccountChildScheduleSlotInput[] = [];
          child.expectedScheduleSlots.forEach((s: IBusRosterAvailableAccountChildScheduleSlot) => {
            s.days.forEach((d: DayOfWeekType) => {
              scheduleSlots.push({
                weekType: s.weekType,
                day: d,
              });
            });
          });
          return cast<IBusRosterAccountChildGridShape>({
            id: child.id,
            data: child,
            input: {
              accountChildId: child.id,
              type: formData.type,
              destination: '',
              scheduleSlots: scheduleSlots,
            },
          });
        }
      );

      setAvailableChildrenData(formattedChildrenData);
    },
    onError: (err) => {
      showToast(err, 'error');
    },
  });

  const refetchAvailableChildrenData = () => {
    refetchAvailableChildrenFn();
  };

  useEffect(() => {
    if (
      availableChildrenData &&
      availableChildrenData.length > 0 &&
      loadedChildrenData &&
      loadedChildrenData.length > 0
    ) {
      setAssignedChildrenData(
        availableChildrenData
          .filter((child) => {
            return loadedChildrenData.some((x) => x.accountChildId === child.data.id);
          })
          .map((child) => {
            let loadedChild = loadedChildrenData.find((x) => x.accountChildId === child.data.id);
            let loadedScheduleSlots =
              loadedChild?.scheduleSlots?.map((slot) => {
                return {
                  weekType: slot.weekType,
                  day: slot.day,
                };
              }) ?? [];
            return {
              ...child,
              input: {
                ...child.input,
                destination: loadedChild?.destination.id ?? '',
                scheduleSlots:
                  loadedScheduleSlots && loadedScheduleSlots.length > 0
                    ? loadedScheduleSlots
                    : child.input.scheduleSlots,
                type: loadedChild?.type ?? '',
              },
            };
          })
      );

      setUnassignedChildrenData(
        availableChildrenData.filter((child) => {
          return !loadedChildrenData.some((x) => x.accountChildId === child.data.id);
        })
      );
    } else if (availableChildrenData && availableChildrenData.length > 0) {
      setUnassignedChildrenData(
        availableChildrenData.filter((child) => {
          return !assignedChildrenData.some((x) => x.id === child.id);
        })
      );
    }
  }, [availableChildrenData, loadedChildrenData]);

  const [updateBusRosterFn, { loading: updateBusRosterLoading }] = useUpdateBusRoster();
  const updateBusRoster = useCallback(
    (formData: IBusRosterInformationForm) => {
      let childrenInput = assignedChildrenData.map((child: IBusRosterAccountChildGridShape) => {
        return {
          ...child.input,
          type: upperCase(child.input.type),
          destination: destinationData.find((d) => d.value === child.input.destination)?.label ?? '',
          scheduleSlots: child.input.scheduleSlots.filter(
            (s) =>
              s.day === 'MONDAY' ||
              s.day === 'TUESDAY' ||
              s.day === 'WEDNESDAY' ||
              s.day === 'THURSDAY' ||
              s.day === 'FRIDAY'
          ),
        };
      });

      const input: IUpdateBusRosterInput = {
        id: formData.id!,
        centerId: formData.centerId,
        destinations: formData.destinations!.map((d) => {
          return { name: destinationData!.find((o) => o.value == d)?.label! };
        }),
        effectiveDate: moment(formData.effectiveDate).format('YYYY-MM-DD'),
        endDate: moment(formData.endDate).format('YYYY-MM-DD'),
        name: formData.name,
        type: formData.type,
        children: childrenInput,
      };

      updateBusRosterFn({
        variables: {
          input: cast<IUpdateBusRosterInput>(omitTypename(input, true)),
        },
      })
        .then((data) => {
          showToast(t('bus-roster.update-bus-roster.success-message'), 'success');
          history.replace('/bus-roster');
        })
        .catch((error) => {
          showToast(
            `${error.graphQLErrors
              .map((err: any) => {
                return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
              })
              .join(', ')}`,
            'error'
          );
        });
    },
    [formData, assignedChildrenData]
  );

  return (
    <PageWrapper
      pageTitle={t('bus-roster.update-bus-roster.page-title')}
      applyPadding={true}
      buttonComponent={
        <Button
          disabled={!validBusRosterForm()}
          onClick={() => updateBusRoster(formData)}
          loading={updateBusRosterLoading}
        >
          Save
        </Button>
      }
      secondaryButtonComponent={
        <Button variant="light" className="mr-4" onClick={() => history.goBack()}>
          Cancel
        </Button>
      }
    >
      <>
        {loading ? (
          <LoadingLines />
        ) : (
          <div>
            <BusRosterInfromationCard
              busRosterFromData={formData}
              assignedChildren={assignedChildrenData}
              destinationOptions={destinationData}
              loading={loading}
              onChange={setFormData}
              onAddDestination={() => setShowCreateBusRosterDestinationModal({ isOpen: true })}
              onRemoveDestination={(destinationId: string) => {
                var childrenToUnassign = assignedChildrenData.filter(
                  (child) => child.input.destination === destinationId
                );
                setUnassignedChildrenData((prev) =>
                  prev.concat(
                    assignedChildrenData
                      .filter((child) => childrenToUnassign.some((x) => x.id === child.id))
                      .map((child) => {
                        return {
                          ...child,
                          input: {
                            ...child.input,
                            destination: destinationId!,
                          },
                        };
                      })
                  )
                );

                setAssignedChildrenData(
                  assignedChildrenData.filter((child) => !childrenToUnassign.some((x) => x.id === child.id))
                );
              }}
            ></BusRosterInfromationCard>
            <Tabs defaultActiveKey="unassigned" id={tabId}>
              <Tab eventKey="unassigned" title="Unassigned">
                <BusRosterChildrenTable
                  title="Unassigned Children"
                  tableState={unassignedTableState}
                  tableFunctions={unassignedTableFunctions}
                  data={unassignedChildrenData}
                  busRunType={formData.type}
                  destinations={destinationData}
                  selectedDestinations={formData.destinations}
                  classes={classData}
                  loading={availableChildrenLoading}
                  assignedMode={false}
                  onChange={setUnassignedChildrenData}
                  onAssignmentChange={setAssignedChildrenData}
                />
              </Tab>
              <Tab
                eventKey="assigned"
                title={
                  <>
                    Assigned
                    <Badge pill variant="primary" className="ml-2">
                      {assignedChildrenData?.length ?? 0}
                    </Badge>
                  </>
                }
              >
                {formData?.destinations?.length > 0 && (
                  <BusRosterDestinationCards
                    destinationOptions={destinationData}
                    selectedDestinations={formData.destinations}
                    childrenData={assignedChildrenData}
                  ></BusRosterDestinationCards>
                )}
                <BusRosterChildrenTable
                  title="Assigned Children"
                  tableState={assignedTableState}
                  tableFunctions={assignedTableFunctions}
                  data={assignedChildrenData}
                  busRunType={formData.type}
                  destinations={destinationData}
                  selectedDestinations={formData.destinations}
                  classes={classData}
                  loading={availableChildrenLoading}
                  assignedMode={true}
                  onChange={setAssignedChildrenData}
                  onAssignmentChange={setUnassignedChildrenData}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </>
      <CreateBusRosterDestinationModal
        isOpen={showCreateBusRosterDestinationModal.isOpen}
        onClose={() => setShowCreateBusRosterDestinationModal({ isOpen: false })}
        onConfirm={(destination) => {
          const destinationValue = uuidv4();
          setDestinationData([...destinationData, { value: destinationValue, label: destination }].sort());
          setFormData((prev) => ({
            ...prev,
            destinations: prev.destinations ? prev.destinations.concat(destinationValue).sort() : [destinationValue],
          }));
        }}
      ></CreateBusRosterDestinationModal>
    </PageWrapper>
  );
};

export default EditBusRoster;
