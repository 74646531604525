import { uniqBy } from 'lodash';

export const documentFileExts = ['.pdf', '.png', '.tiff', '.jpg'];

export const filterFiles = (files: File[], acceptedFileExts = documentFileExts): File[] => {
  // When you select the same file twice it duplicates in the file list, so we only use unique filenames.
  // Also the user can select AllFiles so filter out the files we don't support
  const acceptedFiles = uniqBy(files, (f) => f.name).filter((f) =>
    acceptedFileExts.includes('.' + (f.name.split('.').pop()?.toLowerCase() ?? ''))
  );

  return acceptedFiles;
};

export const fileToK2Upload = async (file: File): Promise<undefined | IK2UploadFile> => {
  if (!file) return;
  return {
    fileName: file.name,
    mimeType: file.type || 'text/csv',
    content: await file.text(), // https://developer.mozilla.org/en-US/docs/Web/API/Blob/text
    encoding: 'utf-8',
  };
};

export const emptyK2File: IK2UploadFile = {
  fileName: '',
  mimeType: '',
  content: '',
  encoding: '',
};
