import React from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import TimeRangePicker from 'shared/components/TimePicker/TimeRangePicker';
import Select from 'shared/components/Select';
import { ITimeEntriesById } from './TimeSheetDailyAttendance';

interface IProps {
  timeEntriesById: ITimeEntriesById;
  positionOptions: IStaffPosition[];
  updateTimeEntry: React.Dispatch<React.SetStateAction<ITimeEntriesById>>;
  // we can pass the timezone directly here isntead of looking up the time entry's centerId in the mpa since they come from the DailyAttendance object that has an associated center
  dailyAttendanceTimezone: string;
}

const EditTimeEntriesForDay: React.FC<IProps> = ({
  timeEntriesById,
  positionOptions,
  dailyAttendanceTimezone,
  updateTimeEntry,
  ...props
}) => {
  return (
    <div>
      {/* <div className='d-flex flex-row'>
        <div className='kt-time-sheets-time-entry-day-text-secondary'>In</div>
        <div className='kt-time-sheets-time-entry-day-text-secondary'>Out</div>
        <div className='kt-time-sheets-time-entry-day-text-secondary'>Position</div>
      </div> */}
      {Object.entries(timeEntriesById).map(([timeEntryId, timeEntry], idx) => (
        <div key={`time-entry-${timeEntryId}-${idx}`} className="d-flex flex-row">
          <div>
            <div className="kt-time-sheets-time-entry-day-text-secondary" style={{ display: 'inline' }}>
              In
            </div>
            <div
              className="kt-time-sheets-time-entry-day-text-secondary"
              style={{ display: 'inline', marginLeft: '130px' }}
            >
              Out
            </div>
            <TimeRangePicker
              start={momentTz(timeEntry.trackedTimeIn).tz(dailyAttendanceTimezone) ?? null}
              end={timeEntry.trackedTimeOut ? momentTz(timeEntry.trackedTimeOut).tz(dailyAttendanceTimezone) : null}
              onChange={(start, end) =>
                updateTimeEntry((prev) => ({
                  ...prev,
                  [timeEntryId]: {
                    ...prev[timeEntryId],
                    trackedTimeIn: start
                      ? momentTz(start).tz(dailyAttendanceTimezone).toISOString()
                      : prev[timeEntryId].trackedTimeIn,
                    // ensure the end time has the same month, date, and year as the start time
                    trackedTimeOut:
                      end
                        ?.month(
                          start?.tz(dailyAttendanceTimezone).month() ?? moment().tz(dailyAttendanceTimezone).month()
                        )
                        .date(start?.tz(dailyAttendanceTimezone).date() ?? moment().tz(dailyAttendanceTimezone).date())
                        .year(start?.tz(dailyAttendanceTimezone).year() ?? moment().tz(dailyAttendanceTimezone).year())
                        .tz(dailyAttendanceTimezone)
                        .toISOString() ?? null,
                  },
                }))
              }
            />
          </div>
          <div style={{ width: 220, paddingTop: '4px' }}>
            <div>
              <div
                className="kt-time-sheets-time-entry-day-text-secondary"
                style={{ paddingLeft: '10px', marginBottom: '2px' }}
              >
                Position
              </div>
            </div>
            {timeEntry.type !== 'BREAK' ? (
              <Select
                className="ml-2"
                value={positionOptions.find((position) => position.id === timeEntry.positionId)}
                options={positionOptions}
                getOptionValue={(option: IStaffPosition) => option.id}
                getOptionLabel={(option: IStaffPosition) => option.positionName}
                onChange={(option: IStaffPosition) =>
                  updateTimeEntry((prev) => ({
                    ...prev,
                    [timeEntryId]: {
                      ...prev[timeEntryId],
                      positionId: option.id,
                    },
                  }))
                }
                // added since the menu was getting cut off from the table's td
                menuPosition="fixed"
              />
            ) : (
              <Select
                disabled
                className="ml-2"
                value={{ label: 'Break', value: null }}
                options={[{ label: 'Break', value: null }]}
                onChange={() => {}}
                // added since the menu was getting cut off from the table's td
                menuPosition="fixed"
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EditTimeEntriesForDay;
