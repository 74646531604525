import moment from 'moment';

/**
 * Check if there are any time off requests that overlap the provided times on the provided dates
 *
 * a conflict would be:
 * - an approved time off requests that overlaps the date and time of shift being created
 *
 * @param datesToCheck
 * @param requests
 * @param startTime
 * @param endTime
 * @returns
 */
export const checkConflictingTimeOffRequests = (
  datesToCheck: moment.Moment[],
  requests: ITimeOff[] | undefined,
  startTime: moment.Moment,
  endTime: moment.Moment
): string[] => {
  if (requests) {
    return datesToCheck
      .map((date: moment.Moment) => {
        let intersectingPartialDayRequest,
          intersectingAllDayRequest,
          intersectingMultipleDayRequest: ITimeOff | null = null;

        // chose to use a regular for loop instead of using 3 separate array.find(...) method calls to reduce the number of iterations
        for (let idx = 0; idx < requests.length; idx++) {
          const req = requests[idx];

          if (
            req.status === 'Approved' &&
            !req.allDay &&
            (moment(req.startTime).isSame(date, 'date') || moment(req.endTime).isSame(date, 'date')) &&
            (startTime.isBetween(moment(req.startTime), moment(req.endTime)) ||
              endTime.isBetween(moment(req.startTime), moment(req.endTime)) ||
              moment(req.startTime).isBetween(startTime, endTime) ||
              moment(req.endTime).isBetween(startTime, endTime))
          ) {
            // partial day request
            intersectingPartialDayRequest = req;
            break;
          } else if (
            req.status === 'Approved' &&
            req.allDay &&
            (moment(req.startTime).isSame(date, 'date') || moment(req.endTime).isSame(date, 'date'))
          ) {
            // single all day request
            intersectingAllDayRequest = req;
            break;
          } else if (req.status === 'Approved' && date.isBetween(moment(req.startTime), moment(req.endTime))) {
            // multiple day request
            intersectingMultipleDayRequest = req;
            break;
          }
        }

        if (intersectingPartialDayRequest) {
          return `Has approved time off on ${date.format('dddd')} from ${moment(
            intersectingPartialDayRequest.startTime
          ).format('h:mm A')} to ${moment(intersectingPartialDayRequest.endTime).format('h:mm A')}.`;
        } else if (intersectingAllDayRequest || intersectingMultipleDayRequest) {
          return `Has all day approved time off on ${date.format('dddd')}.`;
        }

        return '';
      })
      .filter((s) => s !== '');
  }

  return [];
};
