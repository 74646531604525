import React, { useCallback, useState } from 'react';
import Button from 'shared/components/Buttons';
import { showToast } from '../Toast';
import './copyableText.scss';

interface IProps {
  text: string;
  className?: string;
}

export default ({ text, className = '' }: IProps) => {
  const [isHover, setIsHover] = useState(false);
  const handleCopy = useCallback(() => {
    window.navigator.clipboard.writeText(text);
    showToast('Copied to clipboard', 'success');
  }, [text]);

  return (
    <div
      className={`copyable-text text-truncate ${className}`}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {text}
      {isHover && (
        <Button variant="light" size="sm" className="copyable-text__copy" onClick={handleCopy}>
          Copy
        </Button>
      )}
    </div>
  );
};
