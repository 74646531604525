import React, { useState } from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import useDatatableState from 'shared/hooks/useDatatableState2';
import CcsAbsenceCards from '../components/CcsAbsenceCards/CcsAbsenceCards';
import CcsAbsencesTable from './CcsAbsencesTable';
import { useGetCcsAbsencesCategoriesForCenters, useGetCcsAbsencesForCenters } from 'gql/ccssAbsences/queries';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import moment from 'moment';

interface IProps {
  businessId: string;
  centerIds: string[];
}

interface ISort {
  field: string;
  direction: ElasticsearchSortDirection;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const CcsAbsencesTab: React.FC<IProps> = ({ businessId, centerIds, ...props }) => {
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSortOptions] = useState<ISort[]>([{ field: 'accountName', direction: 'ASCENDING' }]);
  const [activeFilter, setActiveFilter] = useState<boolean | null>();
  const [absencesCategoryFilter, setAbsencesCategoryFilter] = useState<CcsAbsencesCategory | null>(null);
  const [filteredStatus, setFilteredStatus] = useState<string[]>(['Active']);

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field: field, direction: direction }]);
  };

  const handleFilteredStatus = (value: string[]) => {
    setFilteredStatus(value);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  const { data: ccsAbsencesPagedResult, loading: ccsAbsencesLoading } = useGetCcsAbsencesForCenters({
    variables: {
      input: {
        businessId: businessId,
        centerIds: centerIds,
        active: activeFilter ?? undefined,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortDtos: sort,
        account: searchTerm ? searchTerm : undefined,
        category: absencesCategoryFilter ?? undefined,
        statusType:
          !!filteredStatus && !!filteredStatus.length
            ? filteredStatus.length > 1
              ? undefined
              : (filteredStatus[0] as AccountStatusType)
            : undefined,
        statusAtDate: moment().format('YYYY-MM-DD'),
      },
    },
  });

  const { data: ccsAbsencesCategories, loading: ccsAbsencesCategoriesLoading } = useGetCcsAbsencesCategoriesForCenters({
    variables: {
      input: {
        businessId: businessId,
        centerIds: centerIds,
      },
    },
  });

  const absences = (ccsAbsencesPagedResult?.getCcsAbsencesForCenter?.data ?? []).map((absence, i) => ({
    ...absence,
    id: i,
  }));

  return (
    <Row noGutters>
      <Col>
        <Row className="mb-4">
          <Col>
            <CcsAbsenceCards
              loading={ccsAbsencesCategoriesLoading}
              data={ccsAbsencesCategories?.getCcsAbsencesCategoriesForCenter}
              onSelected={setAbsencesCategoryFilter}
              activeFilter={absencesCategoryFilter}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert className="bg-info-10 mb-2">
              <p className="mb-0">
                <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
                Absence counts are a sum of the absences returned from CCS plus any unsubmitted absences at the{' '}
                {fieldLabels.center.toLowerCase()}
              </p>
            </Alert>
            <CcsAbsencesTable
              loading={ccsAbsencesLoading}
              data={absences}
              dataSize={ccsAbsencesPagedResult?.getCcsAbsencesForCenter?.totalRecords ?? 0}
              pageSize={tableState.pageSize}
              activePage={tableState.activePage}
              onPageChange={tableFunctions.changePage}
              onSizePerPageChange={tableFunctions.changeSizePerPage}
              onSearchTermChange={setSearchTerm}
              onActiveFilterChange={setActiveFilter}
              onSort={handleSort}
              filteredStatus={filteredStatus}
              onStatusFilter={handleFilteredStatus}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CcsAbsencesTab;
