import React, { useCallback } from 'react';
import momentTz from 'moment-timezone';
import { DayPickerSingleDateController } from 'react-dates';
import ContractScheduleDays from './ContractScheduleDays';
import { setOtherZone } from '../../../../../../../../shared/util/timeUtils';

interface IProps {
  contract: IContract;
  centerTimezone: Timezone;
  timeslots: IContractTimeslot[];
}

const CustomSchedule: React.FC<IProps> = ({ contract, centerTimezone, timeslots, ...props }) => {
  const isDayHighlighted = useCallback(
    (day: momentTz.Moment): boolean => {
      return contract.timeslots.some((timeslot: IContractTimeslot) => {
        return (
          timeslot.date && momentTz.tz(timeslot.date, centerTimezone).isSame(momentTz(day).tz(centerTimezone), 'd')
        );
      });
    },
    [contract, centerTimezone]
  );

  const isOutsideRange = useCallback(
    (day: momentTz.Moment): boolean => {
      const contractStart = momentTz.tz(contract.startDate, centerTimezone);
      const isBeforeContract = setOtherZone(day, centerTimezone).isBefore(contractStart, 'd');
      const isAfterContract = contract.endDate
        ? setOtherZone(day, centerTimezone).isAfter(momentTz.tz(contract.endDate, centerTimezone), 'd')
        : false;

      return isBeforeContract || isAfterContract;
    },
    [contract, centerTimezone]
  );

  return (
    <>
      <DayPickerSingleDateController
        hideKeyboardShortcutsPanel
        focused
        noBorder
        date={null}
        onDateChange={() => {}}
        onFocusChange={() => {}}
        numberOfMonths={1}
        isDayHighlighted={(day) => isDayHighlighted(setOtherZone(day, centerTimezone))}
        isOutsideRange={isOutsideRange}
        initialVisibleMonth={() => momentTz(contract.startDate).tz(centerTimezone)}
      />
      <ContractScheduleDays contract={contract} centerTimezone={centerTimezone} timeslots={timeslots} />
    </>
  );
};

export default CustomSchedule;
