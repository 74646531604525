export const iSMessageFields = `
  id
  messageId
  created
  source
  messageType
  effectiveDate
  comments
  viewedStatus
  centerName
  centerId
`;

export const iSMessagePagedFields = `
  totalRecords
  pageNumber
  data {
    ${iSMessageFields}
  }
`;
