import moment from 'moment';
import { useMemo } from 'react';
import { Calendar, Culture, DateLocalizer, momentLocalizer } from 'react-big-calendar';
import './BillingCycleCalendar.scss';
import { appendClassName } from 'shared/util/classNameHelpers';
import CalendarToolbar from './CalendarToolbar';
import CalendarEvent from './CalendarEvent';
import { iCalendarEvent } from './types';
import Spinner from 'shared/components/Spinner';
import { Row } from 'shared/components/Layout';
import { noop } from 'lodash';

/**
 * localizer for react-big-calendar
 */
const localizer = momentLocalizer(moment);

interface CyclePreviewProps {
  events: iCalendarEvent[];
  calendarDate: Date;
  onCalendarDateChange: (date: Date) => void;
  loading: boolean;
}
function CyclePreview(props: CyclePreviewProps) {
  const { events, calendarDate, onCalendarDateChange, loading } = props;
  const { components, formats } = useMemo(
    () => ({
      components: { event: CalendarEvent, toolbar: CalendarToolbar },
      formats: {
        weekdayFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer): string =>
          localizer?.format(date, 'dd', culture) ?? '',
        dateFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) =>
          localizer?.format(date, 'D', culture) ?? '',
      },
    }),
    []
  );
  return (
    <div style={{ height: '560px', marginTop: '24px' }}>
      {loading ? (
        <Row align="center" justify="center">
          <Spinner large />
        </Row>
      ) : (
        <Calendar
          className="account-billing-cycle-calendar"
          components={components}
          date={calendarDate}
          events={events}
          eventPropGetter={(event) => {
            const className = appendClassName('', event.isPast ? 'is-past' : '', event.isOdd ? 'odd' : 'even');
            return { className };
          }}
          selectable={false}
          onSelectEvent={noop}
          onDoubleClickEvent={noop}
          selected={null}
          formats={formats}
          localizer={localizer}
          onNavigate={onCalendarDateChange}
          views={['month']}
        />
      )}
    </div>
  );
}
export default CyclePreview;
