import React from 'react';
import { CardDeck } from 'react-bootstrap';
import { accsCategoryFormatting } from 'shared/constants/enums/ccssEnums';
import NumberCard from '../components/NumberCard/NumberCard';

interface ITableProps {
  onSelected: (filter: AccsDisplayCategoryEnum | null) => void;
  activeFilter: AccsDisplayCategoryEnum | null;
  counts: Record<AccsDisplayCategory, number>;
  loading?: boolean;
}

// Don't know why there are two enums, they appear to have equality (are transitive, symmetric & reflexive)
// So using this to map them
const enumToDisplay: Record<AccsDisplayCategoryEnum, AccsDisplayCategory> = {
  ACTIVE: 'active',
  PENDING: 'pending',
  EXPIRING: 'expiring',
  EXPIRED: 'expired',
};

const AccsCategoryCards: React.FC<ITableProps> = ({ onSelected, activeFilter, counts, loading }) => {
  const toggle = (filter: AccsDisplayCategoryEnum) => (activeFilter !== filter ? filter : null);

  const getProps = (value: AccsDisplayCategoryEnum) => ({
    number: counts[enumToDisplay[value]],
    numberColor: accsCategoryFormatting[enumToDisplay[value]].colorName,
    onClick: () => onSelected(toggle(value)),
    active: activeFilter === value,
    loading: loading,
  });

  const cards = (
    <>
      <NumberCard
        title="Pending"
        subTitle="ACCS that has been submitted but not yet approved by CCS"
        {...getProps('PENDING')}
      />
      <NumberCard title="Active" subTitle="Active certificates and determinations" {...getProps('ACTIVE')} />
      <NumberCard
        title="Expiring"
        subTitle="Certificates or determinations that are about to expire in the near future"
        {...getProps('EXPIRING')}
      />
      <NumberCard
        title="Expired/Rejected"
        subTitle="Certificates or determinations that have expired or been rejected by CCS"
        {...getProps('EXPIRED')}
      />
    </>
  );

  return <CardDeck>{cards}</CardDeck>;
};

export default AccsCategoryCards;
