import moment from 'moment';
import Currency from 'shared/components/Currency';
import DateInput from 'shared/components/DateInput';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import './ItemizedBillHeader.scss';

interface ItemizedBillHeaderProps {
  billingDate: string;
  billTotal: number;
  forwardBalance: number;
  includeForwardBalance: boolean;
  lateFee: number | null;
  lateFeeDisabled: boolean;
  lateFeeIsRequired: boolean;
  onLateFeeChange: (value: number) => void;
  onPaymentDueDateChange: (date: string) => void;
  onPaymentLateDateChange: (date: string) => void;
  paymentDueDate: string;
  paymentLateDate: string;
  paymentLateDateIsRequired: boolean;
  paymentLateDateIsValid: boolean;
  paymentLateDateIsOutOfRange: (date: string) => boolean;
  subtotal: number;
}
export function ItemizedBillHeader({
  billingDate,
  billTotal,
  forwardBalance,
  lateFee,
  lateFeeDisabled,
  lateFeeIsRequired,
  onLateFeeChange,
  onPaymentDueDateChange,
  onPaymentLateDateChange,
  paymentDueDate,
  paymentLateDate,
  paymentLateDateIsRequired,
  paymentLateDateIsValid,
  paymentLateDateIsOutOfRange,
  subtotal,
}: ItemizedBillHeaderProps) {
  return (
    <div className="itemized-bill__header">
      <div className="row">
        <DateInput date={billingDate} label="Billed Date" disabled></DateInput>
        <DateInput
          required
          date={paymentDueDate}
          onDateSelect={onPaymentDueDateChange}
          isOutsideRange={(date: moment.Moment) => date.isBefore(moment())}
          label="Payment Due Date"
        ></DateInput>
        <DateInput
          date={paymentLateDate}
          isOutsideRange={paymentLateDateIsOutOfRange}
          label="Payment Late Date"
          onDateSelect={onPaymentLateDateChange}
          isValid={paymentLateDateIsValid}
          required={paymentLateDateIsRequired}
          hasCustomValidation={true}
        ></DateInput>
        <CurrencyInput
          className="ib__late-fee"
          disabled={lateFeeDisabled}
          label="Late Fee"
          onChange={onLateFeeChange}
          placeholder="0.00"
          value={lateFee}
          required={lateFeeIsRequired}
        ></CurrencyInput>
      </div>
      <div className="row">
        <div className="bill-part">
          <div>
            <label className="form-label">Forward Balance:</label>
          </div>
          <div className="bill-part__content">
            <Currency amount={forwardBalance} />
          </div>
        </div>
        <div className="bill-part">
          <div>
            <label className="form-label">Subtotal:</label>
          </div>
          <div className="bill-part__content">
            <Currency amount={subtotal} />
          </div>
        </div>
        <div className="bill-part">
          <div>
            <label className="form-label">Bill Total:</label>
          </div>
          <div className="bill-part__content">
            <Currency aggregate amount={billTotal} />
          </div>
        </div>
        <div className="bill-part"></div>
      </div>
    </div>
  );
}
