import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import {
  SearchPredicate,
  SortDirection,
  useGetReEnrolCenterSettingsQuery,
  useResetReEnrolCenterSettingsMutation,
  useSetReEnrolBusinessSettingsMutation,
  useSetReEnrolCenterSettingsMutation,
} from 'generated/graphql';
import _, { capitalize } from 'lodash';
import theme from 'muiTheme';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import Setting from 'shared/components/Settings';
import { showToast } from 'shared/components/Toast';
import useDatatableState from 'shared/hooks/useDatatableState';
import chattingImage from 'shared/images/chatting.svg';
import { RootState } from 'store/reducers';
import COUNTRY_INFO, {
  DEFAULT_COUNTRY,
  STATES,
  US_STATE_SELECT_OPTIONS,
} from 'shared/constants/dropdownOptions/countryInfo';
import Switch from 'shared/components/Switch';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { useGetCenterStatesInUse } from 'shared/hooks/useGetCenterStatesInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import FilterGroup from 'pages/Centers/subroutes/Profiles/components/CenterProfilesTable/FilterGroup';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { orderBy } from 'lodash';

interface IProps {
  businessId: string;
}

const ReEnrolSettings: React.FC<IProps> = ({ businessId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['business', 'enrollment', 'translation']);
  const currentUser = useSelector((state: RootState) => state.user);
  const isInternalUser = currentUser?.isInternal;

  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('center', initalTableSort);

  const { data: getAllowedEntitiesData } = useGetAllowedEntities(!isInternalUser);
  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];
  const tagsOptions: ITableFilterOption[] = orderBy(tags ?? [], (tag) => tag.name.toLocaleLowerCase(), 'asc').map(
    (tag) => ({ label: tag.name, value: tag.id })
  );

  const statesInUse: string[] = useGetCenterStatesInUse()?.data?.getCenterStatesUsedAcrossEntity || [];

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const states =
    !!statesInUse && statesInUse.length > 0
      ? US_STATE_SELECT_OPTIONS.filter((state) => statesInUse.includes(state.value))
      : US_STATE_SELECT_OPTIONS;

  const [setReEnrolBuisnessSettings, { loading: setBusinessSettingsLoading }] = useSetReEnrolBusinessSettingsMutation({
    onError: (err) => showToast(t('enrollment:reenroll-business-settings.set-error'), 'error'),
    onCompleted: (response) => {
      handleRefetch();
    },
  });

  const [setCenterSettings, { loading: setCenterSettingsLoading }] = useSetReEnrolCenterSettingsMutation({
    onError: (err) => showToast(t('enrollment:reenroll-center-settings.set-error'), 'error'),
    onCompleted: (response) => {
      handleRefetch();
    },
  });

  const [resetCenterSettings, { loading: resetBusinessSettingsLoading }] = useResetReEnrolCenterSettingsMutation({
    onError: (err) => showToast(t('enrollment:reenroll-business-settings.set-error'), 'error'),
    onCompleted: (response) => {
      handleRefetch();
    },
  });

  const handleUpdateRow = (
    rowId: string,
    usesDefault: boolean,
    usesParentApplication: boolean,
    usesEnrolmentForm: boolean
  ) => {
    if (usesDefault) {
      resetCenterSettings({
        variables: {
          input: {
            businessId: businessId,
            centerId: rowId,
          },
        },
      });
    } else {
      setCenterSettings({
        variables: {
          input: {
            businessId: businessId,
            centerId: rowId,
            usesParentApplication: usesParentApplication,
            usesEnrolmentForm: usesEnrolmentForm,
          },
        },
      });
    }
  };

  const handleRefetch = () => {
    refetch({
      input: {
        filter: { [SEARCH_EXPRESSIONS.ALL]: [...tableState.searchExpressions, ...tableState.filterExpressions] },
        from: tableState.currentItemOffset,
        size: tableState.pageSize,
        sort: [
          {
            field: 'name.keyword',
            direction: SortDirection.Ascending,
          },
        ],
      },
    });
  };

  const { data, loading, error, refetch } = useGetReEnrolCenterSettingsQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      input: {
        filter: { [SEARCH_EXPRESSIONS.ALL]: [...tableState.searchExpressions, ...tableState.filterExpressions] },
        from: tableState.currentItemOffset,
        size: tableState.pageSize,
        sort: [
          {
            field: 'name.keyword',
            direction: SortDirection.Ascending,
          },
        ],
      },
      input2: { businessId: businessId ?? '' },
    },
    onError: (err) => showToast(t('enrollment:reenroll-center-settings.get-error'), 'error'),
  });

  const reEnrolBusinessSettings = data?.getReEnrolBusinessSettings;

  const formattedTableData = data?.searchCenters.data.map((center) => ({
    ...center,
    address: {
      ...center.address,
      state: { keyword: STATES[center.address?.state ?? ''] },
    },
  }));

  return (
    <>
      <Card className="px-8 pt-4 pb-2">
        <div className="d-flex flex-row">
          <div className="col-12 col-lg-7 pt-6 pb-8">
            <h5 className="mt-4 mb-6">Did you know?</h5>
            <p>{t('enrollment:reenroll-center-settings.info.global-settings')}</p>
            <div className="red-info-block p-4 d-flex flex-row">
              <div className="mr-4">
                <FontAwesomeIcon icon={faInfoCircle} size="2x" />
              </div>
              <div className="mr-6 d-flex align-items-center">
                <span style={{ fontWeight: '600' }}>
                  {t('enrollment:reenroll-center-settings.info.permission-warning')}
                  <a className="alert-link" href="/settings/roles">
                    {t('enrollment:reenroll-center-settings.info.permission-link-text')}
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="col-auto col-lg-5 d-flex justify-content-center align-items-center">
            <img
              src={chattingImage}
              alt="chatting"
              className="tag-example d-none d-lg-block ml-auto w-100"
              style={{ maxHeight: '350px' }}
            />
          </div>
        </div>
      </Card>
      <Setting
        sectionHeader={`Global ${capitalize(t('translation:spelling.center'))} Settings`}
        title={t('enrollment:reenroll-business-settings.title')}
        subtitle={`Allow parents to manage their ${t('translation:spelling.enrollment')}s`}
        toggleValue={reEnrolBusinessSettings?.usesParentApplication}
        handleToggle={(value: boolean) => {
          setReEnrolBuisnessSettings({
            variables: {
              input: {
                businessId: businessId ?? '',
                usesParentApplication: value,
                usesEnrolmentForm: reEnrolBusinessSettings?.usesEnrolmentForm ?? false,
                usesReEnrol: true,
              },
            },
          });
        }}
      />
      <Setting
        title={t('enrollment:reenroll-center-settings.requires-form.title')}
        subtitle={t('enrollment:reenroll-center-settings.requires-form.description')}
        toggleValue={reEnrolBusinessSettings?.usesEnrolmentForm}
        handleToggle={(value: boolean) => {
          setReEnrolBuisnessSettings({
            variables: {
              input: {
                businessId: businessId ?? '',
                usesParentApplication: reEnrolBusinessSettings?.usesParentApplication ?? false,
                usesEnrolmentForm: value,
                usesReEnrol: true,
              },
            },
          });
        }}
      />
      <Box display="flex" justifyContent="space-between" padding={2}>
        <Typography variant="h2">{capitalize(t('translation:spelling.center_plural'))}</Typography>
      </Box>
      <DataTable
        data={formattedTableData ?? []}
        dataSize={data?.searchCenters.totalResults ?? 0}
        pageSize={tableState.pageSize}
        showLoadingOverlay={
          loading || setBusinessSettingsLoading || setCenterSettingsLoading || resetBusinessSettingsLoading
        }
        onPageChange={tableFunctions.changePage}
        activePage={tableState.activePage}
        onSizePerPageChange={(sizePerPage: number) => {
          if (tableState.activePage !== 1) tableFunctions.changePage(1, sizePerPage);
          tableFunctions.changeSizePerPage(sizePerPage);
        }}
        showSelect={false}
        columns={[
          {
            text: `${capitalize(t('translation:spelling.center'))} Name`,
            dataField: 'name',
            sort: true,
          },
          {
            text: 'State',
            dataField: 'address.state.keyword',
            sort: false,
          },
          {
            text: 'Uses Global Settings',
            dataField: 'fromDefault',
            formatter: (cell: any, row: any) => {
              const defaultSetting = row.reEnrolSettings.fromDefault ?? false;
              const usesFamilyAccess = row.reEnrolSettings.usesParentApplication ?? false;
              const usesEnrolmentForm = row.reEnrolSettings.usesEnrolmentForm ?? false;
              return (
                <Switch
                  value={defaultSetting}
                  onChange={(checked: boolean) => {
                    handleUpdateRow(row.id, checked, usesFamilyAccess, usesEnrolmentForm);
                  }}
                />
              );
            },
          },
          {
            text: 'Family Access',
            dataField: 'usesParentApplication',
            formatter: (cell: any, row: any) => {
              const usesFamilyAccess = row.reEnrolSettings.usesParentApplication ?? false;
              const usesEnrolmentForm = row.reEnrolSettings.usesEnrolmentForm ?? false;
              return (
                <Switch
                  value={usesFamilyAccess}
                  onChange={(checked: boolean) => {
                    handleUpdateRow(row.id, row.fromDefault, checked, usesEnrolmentForm);
                  }}
                />
              );
            },
          },
          {
            text: t('enrollment:reenroll-center-settings.requires-form.table-header'),
            dataField: 'usesEnrolmentForm',
            formatter: (cell: any, row: any) => {
              const usesFamilyAccess = row.reEnrolSettings.usesParentApplication ?? false;
              const usesEnrolmentForm = row.reEnrolSettings.usesEnrolmentForm ?? false;
              return (
                <Switch
                  value={usesEnrolmentForm}
                  onChange={(checked: boolean) => {
                    handleUpdateRow(row.id, row.fromDefault, usesFamilyAccess, checked);
                  }}
                />
              );
            },
          },
        ]}
        renderHeader={(paginationProps: any) => (
          <TableHeader className="d-flex flex-row align-items-center flex-wrap flex-grow-1">
            <SizePerPage paginationProps={paginationProps} />
            <FilterGroup
              tableState={tableState}
              tableFunctions={tableFunctions}
              tags={tagsOptions}
              states={states}
              entities={getAllowedEntitiesData?.getAllowedEntities || []}
              autoSelectedBusinessId={businessId}
            />
          </TableHeader>
        )}
      />
    </>
  );
};
export default ReEnrolSettings;
