import { agencyAccountChildFields } from 'gql/agency/fields';

export const contractFields = `
  id
  classId
  centerId
  accountId
  accountChildId
  childId
  permanentFeeId
  originalFeeId
  adjustmentId
  startDate
  endDate
  reasonForEnding
  casualFeeId
  cycleType
  timeslots {
    id
    contractId
    week
    day
    date
    dropOffTime
    pickUpTime
    archivedAt
  }
  status
  class {
    id
    name
  }
  permanentFee {
    id
    name
    feeType
  }
  originalFee {
    id
    name
  }
  adjustment {
    id
    name
  }
  casualFee {
    id
    name
  }
  associatedSubsidies {
    ${agencyAccountChildFields}
  }
  createdAt
  createdBy
  lastModifiedAt
  lastModifiedBy
  createdByAccount {
    firstname
    lastname
  }
  lastModifiedByAccount {
    firstname
    lastname
  }
`;
