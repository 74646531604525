import React from 'react';
import Input, { IBaseInputProps, IInputWithAppendedNodeProps, IInputWithAppendedTextProps } from './Input';

export interface IInputNumberFormat {
  thousandSeparator?: boolean;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  format?: string;
  mask?: string;
  min?: number;
  max?: number;
  isNumericString?: boolean;
}

export interface INumberInputProps extends IBaseInputProps {
  value: number | null | undefined | string;
  numberFormat?: IInputNumberFormat;
  textAreaClass?: string;
  min?: string;
  max?: string;
}

const NumberInput: React.FC<(IInputWithAppendedTextProps | IInputWithAppendedNodeProps) & INumberInputProps> = ({
  onChange,
  numberFormat,
  textAreaClass,
  ...props
}) => {
  return (
    <Input
      onChange={(value, name) =>
        onChange && onChange(value && !numberFormat?.isNumericString ? Number(value) : value, name)
      }
      type="number"
      numberFormat={numberFormat}
      textAreaClass={textAreaClass}
      {...props}
    />
  );
};

export default NumberInput;
