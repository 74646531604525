import { documentFields } from 'gql/document/fields';

export const restrictionFields: string = `
  id
  typeId
  type {
    id
    name
    type
  }
  childId
  name
  importance
  description
  archivedAt
  createdAt
  isRevised
  createdByPerson {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
  }
  archivedByPerson {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
  }
`;

export const restrictionWithDocumentsFields: string = `
  ${restrictionFields}
  documents {
    ${documentFields}
  }
`;
