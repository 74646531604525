import moment from 'moment';
import React from 'react';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { getInitials, stringToHsl } from 'shared/util/string';
import { ICcsEntitlementChangeHistory } from '../../../../../../../../gql/subsidies/queries';

interface ITableProps {
  data: ICcsEntitlementChangeHistory[];
  loading: boolean;
  entitlement: IChild;
}

const dateFormat = 'DD/MM/YYYY';

const EntitlementsHistoryTable: React.FC<ITableProps> = ({ data, entitlement, loading }) => {
  return (
    <DataTable
      noPadding={true}
      data={data ?? []}
      showLoadingOverlay={loading}
      showSelect={false}
      showPagination={false}
      keyField="date"
      columns={[
        {
          text: '',
          dataField: 'date',
          formatter: (cell: any, row: ICcsEntitlementChangeHistory) => moment(row.date).format(dateFormat),
        },
        {
          text: '',
          dataField: 'currentHours',
          formatter: (cell: any, row: ICcsEntitlementChangeHistory) => `${row.totalHoursPerFortnight}hr`,
        },
        {
          text: '',
          dataField: 'currentPercentage',
          formatter: (cell: any, row: ICcsEntitlementChangeHistory) => `${row.percentage}%`,
        },
      ]}
    />
  );
};

export default EntitlementsHistoryTable;
