import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetEndCareSessions } from '../../graphql/queries';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { currencyFormat } from 'shared/util/currency';
import {
  useChargeFullFeeEndCareSessions,
  useHideEndCareSessions,
  useVoidEndCareSessions,
} from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import * as Mui from '@mui/material';
import { endOfCareActiveTableColumns, formatDate, getAbsenceReasonOptions, getCareType } from './common';
import DataTable from 'shared/components/DataTable';
import { IconButtonCircle } from 'shared/components/Buttons';
import EndCareModalWindow from '../../components/ModalWindow';

interface IEndCareEnrollmentExpandRowProps {
  row: {
    accountId: string;
    accountName: string;
    centerId: string;
    centerName: string;
    accountChildId: string;
    childId: string;
    childFirstName: string;
    childLastName: string;
    enrollmentId: string;
    status: CcsEnrollmentStatus | null;
    totalDue: string;
    sessionIds: string[];
  };
  refetch: () => void;
  loadingEndCareEnrollmentPage: boolean;
}

interface ISelectedRow {
  id: number;
}

interface ModalData {
  title: string;
  content: string;
  submit: string;
  isOpen: boolean;
  sessionIds: string[];
  mode: string;
}

export const EndCareEnrollmentExpandRow: React.FC<IEndCareEnrollmentExpandRowProps> = ({
  row,
  refetch,
  loadingEndCareEnrollmentPage,
}: IEndCareEnrollmentExpandRowProps) => {
  const entityId = useSelector((state: RootState) => state?.user?.entityId) ?? '';
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();
  const { t } = useTranslation(['billing', 'subsidies']);
  const [tableExpandRowState, tableExpandRowFunctions] = useDatatableState();
  const { data: getEndCareSessions, loading: loadingEndCareSessions } = useGetEndCareSessions({
    input: {
      businessId: entityId,
      accountChildId: row.accountChildId,
    },
  });
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const [modal, setModal] = useState<ModalData>({
    title: '',
    content: '',
    submit: '',
    isOpen: false,
    sessionIds: [],
    mode: '',
  });

  const hasEndOfCareEdit = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.EndOfCare,
    level: RoleLevelType.Edit,
  });

  const endCareSessions =
    getEndCareSessions?.getEndCareSessions?.map((i) => {
      return {
        id: i.id,
        date: moment(i.date).format('LL'),
        className: i.class.name,
        careType: getCareType(i.class.careType),
        absenceReason: getAbsenceReasonOptions(i?.absence?.type, i?.absence?.reason),
        sessionCost: currencyFormat(parseInt(i?.cost?.sessionCost, 10)),
        subsidyAmount: currencyFormat(i.endOfCareSubsidy),
      };
    }) ?? [];

  const modalHide = () => {
    setModal({
      title: '',
      content: '',
      submit: '',
      isOpen: false,
      sessionIds: [],
      mode: '',
    });
  };

  const [chargeFullFee, { loading: loadingChargeFullFeeEndCareSessions }] = useChargeFullFeeEndCareSessions({
    input: {
      sessionIds: modal.sessionIds ?? [],
      accountChildId: row.accountChildId,
      businessId: entityId,
    },
  });

  const handleChargeFullFeeSubmit = () => {
    chargeFullFee()
      .then(() => {
        showToast('Success', 'success');
        refetch();
        setAnchorEl(null);
      })
      .catch((error: any) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      });
  };

  const [voidEndCareSessions, { loading: loadingVoidEndCareSessions }] = useVoidEndCareSessions({
    input: {
      sessionIds: modal.sessionIds ?? [],
      accountChildId: row.accountChildId,
      businessId: entityId,
    },
  });

  const handleVoidEndCareSessionsSubmit = () => {
    voidEndCareSessions()
      .then(() => {
        showToast('Success', 'success');
        refetch();
        setAnchorEl(null);
      })
      .catch((error: any) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      });
  };

  const date: string = moment().tz(timezone).format();

  const hiddenUntilDate = moment(date).tz(timezone).endOf('week').add(14, 'days');
  const [hideEndCareSessions, { loading: loadingHideEndCareSessions }] = useHideEndCareSessions({
    input: {
      untilDate: hiddenUntilDate.toDate(),
      accountChildId: row.accountChildId,
      businessId: entityId,
    },
  });

  const handleHideEndCareSessions = () => {
    hideEndCareSessions()
      .then(() => {
        showToast('Success', 'success');
        refetch();
        setAnchorEl(null);
      })
      .catch((error: any) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      });
  };

  const submit = () => {
    switch (modal.mode) {
      case 'handleChargeFullFeeSubmit':
        handleChargeFullFeeSubmit();
        break;
      case 'handleHideEndCareSessions':
        handleHideEndCareSessions();
        break;
      case 'handleVoidEndCareSessionsSubmit':
        handleVoidEndCareSessionsSubmit();
        break;
      default:
        break;
    }
    modalHide();
  };

  const showLoader =
    loadingEndCareSessions ||
    loadingChargeFullFeeEndCareSessions ||
    loadingVoidEndCareSessions ||
    loadingHideEndCareSessions;

  return (
    <>
      <EndCareModalWindow modal={modal} hide={modalHide} submit={submit} />
      {!loadingEndCareSessions && (
        <div className={'d-flex justify-content-end align-items-center mb-3'}>
          {hasEndOfCareEdit && !showLoader && (
            <>
              <Button
                variant="secondary"
                className="mr-2"
                onClick={() =>
                  setModal({
                    title: t('subsidies:end-care.active-tab.modal.title.hide'),
                    content: t('subsidies:end-care.active-tab.modal.content.hide', {
                      childFirstName: row.childFirstName,
                      childLastName: row.childLastName,
                      accountName: row.accountName,
                      hiddenUntilDate: hiddenUntilDate.format('LL'),
                    }),
                    submit: t('subsidies:end-care.active-tab.modal.button-name.hide'),
                    isOpen: true,
                    sessionIds: tableExpandRowState.selectedRows.map((i: ISelectedRow) => {
                      return i.id;
                    }),
                    mode: 'handleHideEndCareSessions',
                  })
                }
              >
                {t('subsidies:end-care.active-tab.action.hide')} {hiddenUntilDate.format('LL')}
              </Button>
              {tableExpandRowState?.selectedRows?.length > 0 && (
                <IconButtonCircle
                  id="action-dropdown-btn"
                  iconSize="2x"
                  icon={faEllipsisH}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                  }}
                />
              )}
            </>
          )}

          {tableExpandRowState?.selectedRows?.length > 0 && (
            <Mui.Menu
              PaperProps={{ sx: { boxShadow: '1' } }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <Mui.MenuItem
                onClick={() => {
                  setModal({
                    title: t('subsidies:end-care.active-tab.modal.title.update'),
                    content: t('subsidies:end-care.active-tab.modal.content.update', { accountName: row.accountName }),
                    submit: t('subsidies:end-care.active-tab.modal.button-name.update'),
                    isOpen: true,
                    sessionIds: tableExpandRowState.selectedRows.map((i: ISelectedRow) => {
                      return i.id;
                    }),
                    mode: 'handleChargeFullFeeSubmit',
                  });
                  tableExpandRowFunctions.updateSelectedRows([]);
                  setAnchorEl(null);
                }}
              >
                {t('subsidies:end-care.active-tab.action.update')}
              </Mui.MenuItem>
              <Mui.MenuItem
                onClick={() => {
                  setModal({
                    title: t('subsidies:end-care.active-tab.modal.title.remove'),
                    content: t('subsidies:end-care.active-tab.modal.content.remove'),
                    submit: t('subsidies:end-care.active-tab.modal.button-name.remove'),
                    isOpen: true,
                    sessionIds: tableExpandRowState.selectedRows.map((i: ISelectedRow) => {
                      return i.id;
                    }),
                    mode: 'handleVoidEndCareSessionsSubmit',
                  });
                  tableExpandRowFunctions.updateSelectedRows([]);
                  setAnchorEl(null);
                }}
              >
                {t('subsidies:end-care.active-tab.action.remove')}
              </Mui.MenuItem>
            </Mui.Menu>
          )}
        </div>
      )}
      <DataTable
        headerClasses={'kt-end-care-header'}
        keyField="id"
        data={endCareSessions ?? []}
        showSelect={hasEndOfCareEdit}
        selectedRows={tableExpandRowState.selectedRows}
        updateSelectedRows={tableExpandRowFunctions.updateSelectedRows}
        showPagination={false}
        defaultSorting="asc"
        showLoadingOverlay={showLoader && !loadingEndCareEnrollmentPage}
        columns={endOfCareActiveTableColumns}
      />
    </>
  );
};
