import React from 'react';

interface IProps {
  horizontalOnly?: boolean;
  children: React.ReactNode | null;
  className?: string;
}

const PageBody: React.FC<IProps> = ({ horizontalOnly = false, className = '', ...props }) => {
  // styles for `app-page-content-body` are found in app.scss
  return (
    <div className={`app-page-content-${horizontalOnly ? 'body-horizontal-only' : 'body'} ${className}`}>
      {props.children}
    </div>
  );
};

export default PageBody;
