import React from 'react';
import _ from 'lodash';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faPercent } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { IconButtonProps } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  useCreateRoyaltyFeeException,
  useDeleteRoyaltyFeeException,
  useUpdateRoyaltyFeeException,
} from 'pages/Businesses/subroutes/BusinessSettings/gql/mutation';
import { showToast } from 'shared/components/Toast';
import CentersTable from './CentersTable';
import { useHistory } from 'react-router-dom';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const theme = useTheme();
  const { expand, ...other } = props;
  return <Mui.IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface IProps {
  feeId: string | undefined;
  id?: string;
  name?: string;
  overridePercentage?: string;
  participantCenterIds?: string[];
  defaultOpen?: boolean;
  refetch: () => void;
  onCancel: () => void;
  alreadySelectedCenters: string[];
  businessId: string;
}

const AddRoyaltyException: React.FC<IProps> = ({
  feeId,
  id = null,
  name = null,
  overridePercentage = null,
  participantCenterIds = null,
  defaultOpen = false,
  refetch,
  onCancel,
  alreadySelectedCenters,
  businessId,
}) => {
  const theme = useTheme();
  const isMobile = Mui.useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const [ruleName, setRuleName] = React.useState(name ?? '');
  const [adjustedFee, setAdjustedFee] = React.useState(overridePercentage ?? '');
  const [selectedCenters, setSelectedCenters] = React.useState<string[]>(participantCenterIds ?? []);

  const [expanded, setExpanded] = React.useState(defaultOpen);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [createRoyaltyFeeExceptionFn] = useCreateRoyaltyFeeException({
    onCompleted: () => {
      refetch();
      history.go(0);
      onCancel();
      showToast('Royalty Fee Exception Created', 'success');
    },
    onError: () => {
      showToast('Error creating Royalty Fee Exception', 'error');
    },
  });

  const [deleteRoyaltyFeeExceptionFn] = useDeleteRoyaltyFeeException({
    onCompleted: () => {
      history.go(0);
      showToast('Royalty Fee Exception Deleted', 'success');
    },
    onError: () => {
      showToast('Error deleting Royalty Fee Exception', 'error');
    },
  });

  const [updateRoyaltyFeeExceptionFn] = useUpdateRoyaltyFeeException({
    onCompleted: () => {
      refetch();
      history.go(0);
      setExpanded(false);
      showToast('Royalty Fee Exception Updated', 'success');
    },
    onError: () => {
      showToast('Error updating Royalty Fee Exception', 'error');
    },
  });

  const onSave = () => {
    _.isNil(id)
      ? createRoyaltyFeeExceptionFn({
          variables: {
            input: {
              royaltyFeeId: _.isNil(feeId) ? '' : feeId,
              businessId: businessId ?? '',
              name: ruleName,
              overridePercentage: parseFloat(adjustedFee),
              participantCenterIds: _.isNil(selectedCenters) ? [] : selectedCenters,
            },
          },
        })
      : updateRoyaltyFeeExceptionFn({
          variables: {
            input: {
              exceptionId: id,
              royaltyFeeId: _.isNil(feeId) ? '' : feeId,
              businessId: businessId ?? '',
              name: ruleName,
              overridePercentage: parseFloat(adjustedFee),
              participantCenterIds: _.isNil(selectedCenters) ? [] : selectedCenters,
            },
          },
        });
  };

  const onDelete = () => {
    deleteRoyaltyFeeExceptionFn({
      variables: {
        royaltyFeeId: feeId ?? '',
        businessId: businessId ?? '',
        exceptionId: id ?? '',
      },
    });
  };

  const cancel = () => {
    if (_.isNil(id)) {
      refetch();
      onCancel();
    } else {
      refetch();
      setExpanded(false);
      setRuleName(name ?? '');
      setAdjustedFee(overridePercentage ?? '');
      setSelectedCenters(participantCenterIds ?? []);
    }
  };

  return (
    <>
      <Mui.Card elevation={0} variant="outlined">
        <Mui.CardHeader
          title={
            <Mui.Box display="flex" justifyContent="space-between" alignItems="center">
              <Mui.Typography variant="cardHeader">Exception Rule {!_.isNil(id) && name}</Mui.Typography>
              <ExpandMore
                expand={expanded}
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Mui.Box>
          }
        />
        <Mui.Collapse in={expanded} timeout="auto" unmountOnExit>
          <Mui.CardContent>
            <Mui.Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
              <Mui.FormControlLabel
                label={
                  <span style={{ fontSize: '.875rem' }}>
                    Exception Rule Name{` `}
                    <FontAwesomeIcon
                      icon={faAsterisk}
                      color={'#FF2C2C'}
                      style={{
                        fontSize: '.5rem',
                        height: '1em',
                        marginBottom: '0.5rem',
                      }}
                    />
                  </span>
                }
                labelPlacement="top"
                control={
                  <Mui.TextField
                    size="small"
                    value={ruleName}
                    required
                    onChange={(evt) => setRuleName(evt.target.value)}
                    fullWidth
                    sx={{
                      minWidth: '500px',
                      '& legend': {
                        width: 0,
                      },
                    }}
                  />
                }
                componentsProps={{
                  typography: {
                    alignSelf: 'flex-start',
                    marginBottom: '0.5rem',
                  },
                }}
                style={{ marginLeft: 0 }}
              />
              <Mui.Box>
                <Mui.FormControlLabel
                  label={
                    <span style={{ fontSize: '.875rem' }}>
                      Adjusted Fee Percentage{` `}
                      <FontAwesomeIcon
                        icon={faAsterisk}
                        color={'#FF2C2C'}
                        style={{
                          fontSize: '.5rem',
                          height: '1em',
                          marginBottom: '0.5rem',
                        }}
                      />
                    </span>
                  }
                  labelPlacement="top"
                  control={
                    <Mui.TextField
                      type="number"
                      size="small"
                      value={adjustedFee}
                      onChange={(evt) => setAdjustedFee(evt.target.value)}
                      InputProps={{
                        endAdornment: (
                          <Mui.InputAdornment position="end">
                            <FontAwesomeIcon icon={faPercent} />
                          </Mui.InputAdornment>
                        ),
                      }}
                      sx={{
                        minWidth: '300px',
                        '& legend': {
                          width: 0,
                        },
                      }}
                    />
                  }
                  componentsProps={{
                    typography: {
                      alignSelf: 'flex-start',
                      marginBottom: '0.5rem',
                    },
                  }}
                  style={{ marginLeft: 0 }}
                />
              </Mui.Box>
            </Mui.Stack>
          </Mui.CardContent>
          <Mui.Divider sx={{ marginTop: 2 }} />
          <Mui.CardContent>
            <Mui.Typography>
              <strong>Centers Eligible for Exception</strong>
              {` `}
              {!_.isNil(participantCenterIds) && `(${participantCenterIds?.length} Selected)`}
            </Mui.Typography>
          </Mui.CardContent>
          <Mui.Divider />
          <Mui.Box>
            <CentersTable
              selectedCenters={selectedCenters}
              setSelectedCenters={setSelectedCenters}
              participantCenterIds={participantCenterIds}
              alreadySelectedCenters={alreadySelectedCenters}
            />
          </Mui.Box>
          <Mui.CardActions
            sx={{
              padding: '16px 24px 16px 16px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Mui.Box>
              {!_.isNil(id) && (
                <Mui.Button variant="secondaryDelete" onClick={() => setOpenDeleteDialog(true)}>
                  Delete Rule
                </Mui.Button>
              )}
            </Mui.Box>
            <Mui.Box display="flex" justifyContent="flex-end">
              <Mui.Button variant="cancel" onClick={() => cancel()} sx={{ marginRight: '24px' }}>
                Cancel
              </Mui.Button>
              <Mui.Button
                variant="contained"
                onClick={() => onSave()}
                disabled={_.isEmpty(ruleName) || _.isEmpty(adjustedFee) || _.isEmpty(selectedCenters)}
              >
                Apply Rule
              </Mui.Button>
            </Mui.Box>
          </Mui.CardActions>
        </Mui.Collapse>
      </Mui.Card>
      <Mui.Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <Mui.DialogTitle
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
        >
          <Mui.Typography
            display="flex"
            fontWeight={600}
            fontSize="1.25rem"
            component="span"
            sx={{ alignItems: 'center' }}
          >{`Delete ${ruleName} Exception Rule?  \xa0\xa0\xa0\xa0\xa0\xa0`}</Mui.Typography>
          <Mui.IconButton onClick={() => setOpenDeleteDialog(false)}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.Typography>Are you sure you want to delete the {` ${ruleName}`}?</Mui.Typography>
        </Mui.DialogContent>

        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setOpenDeleteDialog(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button color="secondary" variant="contained" onClick={() => onDelete()}>
            Confirm
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default AddRoyaltyException;
