import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { iSCaseClaimFieldsPagedResult } from './fields';

interface IGetISCaseClaims {
  getIsCaseClaimsForCenters: IPagedResult<IISCaseClaim>;
}
interface IGetISCaseClaimCounter {
  getIsCaseClaimCategoryCountsForCenters: IISCaseClaimCounter;
}

export const GET_IS_CASE_CLAIMS = gql`
  query($input: GetIsCaseClaimsForCentersInput!) {
    getIsCaseClaimsForCenters(input: $input) {
      ${iSCaseClaimFieldsPagedResult}
    }
  }
`;

export const GET_IS_CASE_CLAIMS_COUNTER = gql`
  query ($businessId: ID!, $centers: [ID!]!) {
    getIsCaseClaimCategoryCountsForCenters(businessId: $businessId, centerIds: $centers) {
      rejected
      pending
      paid
    }
  }
`;

export const useGetISCaseClaimsCounter = (
  options?: QueryHookOptions<IGetISCaseClaimCounter, IGetISCaseClaimCounterVariables>
) =>
  useQuery(GET_IS_CASE_CLAIMS_COUNTER, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetISCaseClaims = (options?: QueryHookOptions<IGetISCaseClaims, IGetISCaseClaimVariables>) =>
  useQuery(GET_IS_CASE_CLAIMS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
