import React, { useCallback, useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import DataTable from 'shared/components/DataTable';
import ActionDropdown from 'shared/components/ActionDropdown';
import { PersonAvatar } from 'shared/components/Avatar';
import cast from 'shared/util/cast';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import { faArrowRight, faCheck, faPencilAlt, faTimesCircle, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import { Col, Row } from 'shared/components/Layout';
import Spinner from 'shared/components/Spinner';
import TimeEntriesTableCell from './TimeEntriesTableCell';
import AttendanceStatusIcon from '../AttendanceStatusIcon';
import { isRegion } from 'shared/util/region';
import { getFullName } from 'shared/util/string';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetCenter } from 'gql/center/queries';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';
import { SessionSource } from 'generated/graphql';

interface IProps {
  attendanceSessions: ISession[];
  loading: boolean;
  timezone: string;
  date: string;
  onSingleRowReportAbsence: (
    sessionId: string | null,
    contractId: string | null,
    accountChildId: string | null
  ) => void;
  onEditSession: (session: ISession, child: IChild) => void;
  onClearSession: (session: ISession) => void;
  onRemoveSession: (session: ISession) => void;
  handleCheckIn: (sessions: ISession[]) => void;
  handleCheckOut: (sessions: ISession[]) => void;
  onSort: (field: string, direction: 'asc' | 'desc') => void;
  selectedSessions: ISession[];
  updateSelectedSessions: (sessions: ISession[]) => void;
  childIdsInLoadingState: string[];
  canEditAttendanceFinance: boolean;
  centerId: string;
}

const DailyAttendanceTable: React.FC<IProps> = ({
  attendanceSessions,
  loading,
  timezone,
  date,
  onSingleRowReportAbsence,
  onEditSession,
  onClearSession,
  onRemoveSession,
  handleCheckIn,
  handleCheckOut,
  onSort,
  childIdsInLoadingState,
  selectedSessions,
  updateSelectedSessions,
  canEditAttendanceFinance,
  centerId: selectedCenterId,
  ...props
}) => {
  const { k2AttendanceAccountName, k2SessionSource } = useFlags();
  const isToday = moment().tz(timezone).isSame(moment(date).tz(timezone), 'd');

  const [centerServiceType, setCenterServiceType] = useState<CenterServiceType | undefined>();

  const { t } = useTranslation();

  const hasCheckInPermission = useHasRoleAreaLevel({
    area: AreaType.Attendance,
    permission: PermissionType.CheckInOut,
    level: RoleLevelType.Read,
  });
  const hasEditSessionPermission = useHasRoleAreaLevel({
    area: AreaType.Attendance,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const hasDeleteSessionPermission = useHasRoleAreaLevel({
    area: AreaType.Attendance,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });
  // users that only have read permission should not know that there are additional actions
  const showAdditionalActionsColumn = hasCheckInPermission || hasEditSessionPermission;

  const gradeOptions = [
    { value: 'PREK', label: t('child.grades.prek') },
    { value: 'PREP', label: t('child.grades.prep') },
    { value: 'FIRST', label: '1' },
    { value: 'SECOND', label: '2' },
    { value: 'THIRD', label: '3' },
    { value: 'FOURTH', label: '4' },
    { value: 'FIFTH', label: '5' },
    { value: 'SIXTH', label: '6' },
    { value: 'SEVENTH', label: '7' },
  ];

  const getGradeDisplay = (grade: Grade) => (!!grade ? gradeOptions.find((item) => item.value === grade)?.label : '');

  const centerData = useGetCenter(
    {
      variables: {
        id: selectedCenterId,
      },
      onCompleted: (result) => {
        setCenterServiceType(result.getCenter.serviceType);
      },
    },
    `serviceType`
  );

  const additionalActions = useCallback(
    (session: ISession): IDropdownAction[] => {
      const actions: IDropdownAction[] = [
        {
          label: 'View/Edit',
          onClick: () => onEditSession(session, session.child),
          icon: faPencilAlt,
          disabled: !session.metadata.isWritable,
        },
      ];

      if (
        hasEditSessionPermission &&
        ((session.timeEntries.length > 0 && session.timeEntries.every((te) => te.timeOut)) || session.absence)
      ) {
        actions.push({
          label: session.absence ? 'Clear Absence' : 'Clear Time Entries',
          onClick: () => onClearSession(session),
          icon: faTimesCircle,
          disabled: !session.metadata.isWritable,
        });
      }

      if (session.timeEntries.length === 0 && !session.absence && hasDeleteSessionPermission) {
        actions.push({
          label: 'Remove',
          onClick: () => onRemoveSession(session),
          icon: faTrashAlt,
          disabled: !session.metadata.isWritable,
        });
      }

      return actions;
    },
    [hasEditSessionPermission, hasDeleteSessionPermission, onEditSession, onClearSession, onRemoveSession]
  );

  return (
    <DataTable
      className={classnames('attendance-table day-table', { 'with-select': isToday && hasCheckInPermission })}
      data={attendanceSessions}
      dataSize={attendanceSessions.length}
      showSelect={hasCheckInPermission}
      selectedRows={selectedSessions}
      updateSelectedRows={updateSelectedSessions}
      showLoadingOverlay={loading || centerData.loading}
      showPagination={false}
      noPadding
      onSort={(field, direction) => onSort(field, direction === 'ASCENDING' ? 'asc' : 'desc')}
      noDataText="No Attendance Found."
      columns={[
        {
          text: 'Child',
          dataField: 'child.lastname',
          sort: true,
          formatter: (cell: any, row: ISession) => (
            <div className="d-flex align-items-center">
              <PersonAvatar person={cast<IPerson>(row.child)} className="mr-2" />
              <div>
                <div>{getFullName(row.child)}</div>
                {k2AttendanceAccountName && (
                  <Link className="session-account-name" to={`/families/accounts/${row.accountId}/profile`}>
                    {row?.accountName}
                  </Link>
                )}
                {isRegion('AU') && <small>Absences: {row.existingEnrollmentYearAbsences?.length ?? 0}</small>}
              </div>
            </div>
          ),
        },
        !centerServiceType || centerServiceType === 'LDC' || centerServiceType === 'LDCOSHC'
          ? {
              text: 'Age',
              dataField: 'child.dob',
              sort: true,
              formatter: (dob: string) => getAgeStringFromDateOfBirth(moment(dob), false),
            }
          : null,
        centerServiceType === 'OSHC' || centerServiceType === 'LDCOSHC'
          ? {
              text: 'Grade',
              dataField: 'child.grade',
              align: 'center',
              sort: true,
              formatter: (grade: Grade) => getGradeDisplay(grade),
            }
          : null,
        {
          text: 'Class',
          headerClasses: 'text-left',
          dataField: 'class.name',
          sort: true,
        },
        {
          text: 'Status',
          dataField: 'timeEntries',
          align: 'center',
          formatter: (timeEntries: IAttendanceTimeEntry[], row: ISession) => (
            <AttendanceStatusIcon session={row} timezone={timezone} />
          ),
          classes: 'xs-column',
        },
        {
          text: 'Check In',
          headerFormatter: () => (
            <Row className="flex-nowrap">
              <Col>Check In</Col>
              <Col>Check Out</Col>
            </Row>
          ),
          dataField: 'dropOffTime',
          headerClasses: 'text-left',
          formatter: (time: string, session: ISession) => (
            <TimeEntriesTableCell session={session} timezone={timezone} />
          ),
        },
        {
          text: 'Session',
          dataField: 'totalTime',
          align: 'center',
          formatter: (totalTime: ITotalTime, session: ISession) => (
            <div>
              <small>
                {moment(session.feeStartTime, 'HH:mm').format('h:mm A')} -{' '}
                {moment(session.feeEndTime, 'HH:mm').format('h:mm A')}
              </small>
              <div>
                {`${totalTime.hours}h ${totalTime.minutes}m`}
                {k2SessionSource && session.source !== SessionSource.Default && (
                  // @ts-ignore
                  <HelpTooltip text={t(`attendance.session-source.${session.source}`)} direction="left" />
                )}
              </div>
            </div>
          ),
        },
        ...(showAdditionalActionsColumn
          ? [
              {
                text: 'Actions',
                dataField: '',
                headerClasses: 'text-left',
                formatter: (cell: any, session: ISession) => (
                  <div className="d-flex">
                    {isToday &&
                      !session.absence &&
                      (childIdsInLoadingState.includes(session.childId) ? (
                        <div>
                          <Spinner className="text-gray mr-2" />
                        </div>
                      ) : (
                        <HasRoleAreaLevel
                          action={{
                            area: AreaType.Attendance,
                            permission: PermissionType.CheckInOut,
                            level: RoleLevelType.Read,
                          }}
                        >
                          {session.timeEntries.some((te) => !te.timeOut) ? (
                            <IconButtonCircle
                              icon={faArrowRight}
                              className="mr-2"
                              tooltipText="Check Out"
                              disabled={!session.metadata.isWritable}
                              onClick={() => handleCheckOut([session])}
                            />
                          ) : (
                            <IconButtonCircle
                              icon={faCheck}
                              className="mr-2"
                              tooltipText="Check In"
                              disabled={!session.metadata.isWritable}
                              onClick={() => handleCheckIn([session])}
                            />
                          )}
                        </HasRoleAreaLevel>
                      ))}
                    {!session.timeEntries.length && !session.absence && (
                      <HasRoleAreaLevel
                        action={{
                          area: AreaType.Attendance,
                          permission: PermissionType.CheckInOut,
                          level: RoleLevelType.Read,
                        }}
                      >
                        <IconButtonCircle
                          iconComponent="A"
                          className="mr-2"
                          tooltipText="Mark Absent"
                          disabled={!session.metadata.isWritable}
                          onClick={() =>
                            onSingleRowReportAbsence(
                              session.id ?? null,
                              session.contractId ?? null,
                              session.accountChildId ?? null
                            )
                          }
                        />
                      </HasRoleAreaLevel>
                    )}
                    <HasRoleAreaLevel
                      action={{ area: AreaType.Attendance, permission: PermissionType.Base, level: RoleLevelType.Edit }}
                    >
                      <ActionDropdown actions={additionalActions(session)} />
                    </HasRoleAreaLevel>
                  </div>
                ),
              },
            ]
          : []),
      ].filter((item) => !!item)}
    />
  );
};

export default DailyAttendanceTable;
