import React, { useCallback, useState, useEffect } from 'react';
import FormLabel from 'react-bootstrap/FormLabel';
import Card from 'shared/components/Card';
import Button from 'shared/components/Buttons';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Select from 'shared/components/Select/Base';
import Role from './Role';
import './employmentDetails.scss';
import { staffStatusColorHexes } from 'shared/constants/tagColors';
import { useSendInvitation } from '../../../../../shared/graphql/mutations';
import Alert from 'react-bootstrap/Alert';
import { showToast } from 'shared/components/Toast';

interface IProps {
  reinviteData: IResendInvitation;
  status: EmploymentStatus;
  roleName: string;
  openPermissionsModal: () => void;
  employementStartDate?: string | null | undefined;
  haveAnyPermissions?: boolean | undefined;
}

const EmploymentDetailsCard: React.FC<IProps> = ({
  reinviteData,
  status,
  roleName,
  openPermissionsModal,
  employementStartDate,
  haveAnyPermissions,
  ...props
}) => {
  const [resendInvitationMutation, { error: mutationError }] = useSendInvitation();
  const [employmentStatus, setEmploymentStatus] = useState(status);
  const resendInvitation = useCallback(async () => {
    const resendInvitationInput: IResendInvitation = {
      ...reinviteData,
    };

    await resendInvitationMutation({
      variables: {
        input: resendInvitationInput,
      },
    })
      .then(() => {
        setEmploymentStatus('Invited');
        showToast('Your email sent successfully.', 'success');
      })
      .catch(() => {
        showToast('Uh oh, there was an error when attempting to send your email.', 'error');
      });
  }, [reinviteData, resendInvitationMutation]);

  useEffect(() => {
    // whenever the incoming status prop changes update local state
    setEmploymentStatus(status);
  }, [status]);

  return (
    <Card header="Employment Details">
      {mutationError && <Alert variant="danger">{mutationError.message}</Alert>}
      <Row className="align-content-start">
        <Column lg className="mb-lg-0 mb-4">
          <FormLabel>Role</FormLabel>
          <Role rolename={roleName} viewPermissions={openPermissionsModal} haveAnyPermissions={haveAnyPermissions} />
        </Column>
        <Column lg={4} className="mb-lg-0 mb-4 d-flex flex-column">
          <FormLabel>Status</FormLabel>
          <div className="kt-employment-details-status-container">
            <Select
              isDisabled
              components={{ DropdownIndicator: null }}
              value={{ label: employmentStatus, value: employmentStatus }}
              getOptionLabel={(option) => option.label}
              formatOptionLabel={(option) => (
                <div className="d-flex flex-row align-items-center px-2">
                  <div
                    className="kt-employment-details-status-indicator"
                    style={{ backgroundColor: staffStatusColorHexes[employmentStatus] }}
                  />
                  <div className="flex-grow-1 ml-2">
                    <span>{option.label}</span>
                  </div>
                </div>
              )}
            />
          </div>
        </Column>
        {(employmentStatus === 'Invited' || employmentStatus === 'Created') && (
          <Column className="d-flex align-items-end kt-employment-details-action-button-container">
            <Button
              variant="outline-secondary"
              onClick={() => {
                if (!!employementStartDate) {
                  resendInvitation();
                } else {
                  showToast('No start date has been set for this employee', 'error');
                }
              }}
            >
              {' '}
              {employmentStatus === 'Invited' ? 'Reinvite' : 'Invite'}{' '}
            </Button>
          </Column>
        )}
      </Row>
    </Card>
  );
};

export default EmploymentDetailsCard;
