import React, { useState } from 'react';
import TrainingTimeEventModal from '../TrainingTimeEventModal';
import TrainingHolidayTable from '../TrainingHolidayTable';
import DeleteTrainingTimeEventModal from './DeleteTrainingTimeEventModal';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

import PageWrapperBody from 'shared/components/PageWrapper/Body';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';

interface ITimeEventModalState {
  isOpen: boolean;
  timeEvent: ITrainingHolidayEvent | null;
}

interface IProps {}

const TrainingHolidayTab: React.FC<IProps> = ({ ...props }) => {
  const [editModal, setEditModal] = useState<ITimeEventModalState>({ isOpen: false, timeEvent: null });
  const [deleteModal, setDeleteModal] = useState<ITimeEventModalState>({ isOpen: false, timeEvent: null });

  const hasEditTrainingTimePermission = useHasRoleAreaLevel({
    area: AreaType.Schedule,
    permission: PermissionType.TimeLog,
    level: RoleLevelType.Edit,
  });
  const hasDeleteTrainingTimePermission = useHasRoleAreaLevel({
    area: AreaType.Schedule,
    permission: PermissionType.TimeLog,
    level: RoleLevelType.Delete,
  });

  return (
    <>
      <PageWrapperBody>
        <CenterSelectBanner pageName="the time log" />
        <TrainingHolidayTable
          hasEditPermission={hasEditTrainingTimePermission}
          hasDeletePermission={hasDeleteTrainingTimePermission}
          onRowUpdate={(timeEvent) => setEditModal({ isOpen: true, timeEvent })}
          onRowDelete={(timeEvent) => setDeleteModal({ isOpen: true, timeEvent })}
        />
        <TrainingTimeEventModal
          isOpen={editModal.isOpen}
          trainingTimeEvent={editModal.timeEvent}
          businessId={editModal.timeEvent?.businessId ?? ''}
          centerId={editModal.timeEvent?.centerId ?? ''}
          onClose={() => setEditModal({ isOpen: false, timeEvent: null })}
        />
        <DeleteTrainingTimeEventModal
          isOpen={deleteModal.isOpen}
          trainingTimeEvent={deleteModal.timeEvent}
          onClose={() => setDeleteModal({ isOpen: false, timeEvent: null })}
          employeeIdToRemoveFromTrainingTime={null}
        />
      </PageWrapperBody>
    </>
  );
};

export default TrainingHolidayTab;
