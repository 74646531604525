import { LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import { showToast } from '../../shared/components/Toast';
import { InputVariable } from '../program/mutation';
import {
  occupancyTotalReportFields,
  reportListFields,
  reportMetadataFields,
  sageGlReportFields,
  transactionTotalsReportFields,
} from './fields';
import { DocumentNode } from 'graphql';
import { IKindyForAllSubmissionError } from 'shared/types/kindyForAll';

interface IGetCcsEntitlementsReportInput {
  centerIds: string[];
  accountStatus?: AccountStatusType;
}

interface IGetAccsReportVariables {
  businessId: string;
  input: IGetAccsReportInput;
}

interface IGetAccsReportInput {
  centerIds: string[];
}

interface IGetDepositsHeldReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    startDate: string | null;
    endDate: string | null;
  };
}

interface IGetIsPaymentReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    startDate: string;
    endDate: string;
  };
}

interface IGetCcsSubsidyPaymentReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    startDate: string;
    endDate: string;
    accountIds?: string[];
    childIds?: string[];
  };
}

interface IGetFatZebraTransactionsReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    startDate: string;
    endDate: string;
  };
}

interface IGetBillingSummaryReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    startDate: string;
    endDate: string;
  };
}

interface IGetTransactionTotalsReportVariables {
  input: {
    centerIds: string[];
    startDate: string;
    endDate: string;
    businessId: string;
    interval: ReportInterval;
    transactionDate: TransactionDateType;
  };
}

interface IGetTransactionTotalsByTransactionTypeReportVariables {
  input: {
    centerIds: string[];
    startDate: string;
    endDate: string;
    businessId: string;
    interval: ReportInterval;
    transactionDate: TransactionDateType;
    includeLiabilities: boolean;
  };
}

interface IGetOccupancyTotalReportData {
  getOccupancyTotalReport: IOccupancyTotalReport;
}

interface IGetSageGlReportData {
  getSageGlReport: ISageGlImportReportWrapper;
}

interface IGetSageGlReportVariables {
  input: {
    centerIds: string[];
    startDate: string;
    endDate: string;
  };
}

interface IReportParamsInput {
  input: IReportParams;
}

interface IReportParams {
  centerIds: string[];
  startDate: string;
  endDate: string;
  businessId: string;
}

// GET LIST
export const useGetReportList = (
  options?: QueryHookOptions<IGetReportListData, IGetReportListVariables>,
  fields?: string
) => useQuery<IGetReportListData, IGetReportListVariables>(GET_REPORT_LIST(fields), options);

const GET_REPORT_LIST = (fields = reportListFields): DocumentNode => gql`
  query {
    getReportList {
      ${fields}
    }
  }
`;

interface IGetReportListData {
  getReportList: IReport[];
}

interface IGetReportListVariables {}

// ATTENDANCE - WEEKLY
export const useGetWeeklyAttendancePdfReportLazy = (
  options?: LazyQueryHookOptions<IGetWeeklyAttendancePdfReportData, IGetWeeklyAttendancePdfReportVariables>
) =>
  useLazyQuery<IGetWeeklyAttendancePdfReportData, IGetWeeklyAttendancePdfReportVariables>(GET_ATTENDANCE_PDF_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_ATTENDANCE_PDF_REPORT = (): DocumentNode => gql`
  query ($input: WeeklyAttendanceReportInput!) {
    getWeeklyAttendanceReport(input: $input)
  }
`;

interface IGetWeeklyAttendancePdfReportData {
  getWeeklyAttendanceReport: string;
}

interface IGetWeeklyAttendancePdfReportVariables {
  input: {
    businessId: string;
    centerId: string;
    classIds: string[];
    startOfWeek: string;
  };
}

// ATTENDANCE TOTALS PER WEEK
interface IAttendanceTotalsPerWeekData {
  getAttendanceTotalsPerWeekReport: string;
}

interface IGetAttendanceTotalsPerWeekVariables {
  input: {
    businessId: string;
    centerIds: string[];
    week: string;
    includeNonCcs: boolean;
  };
}

export const useGetAttendanceTotalsPerWeekLazy = (
  options?: LazyQueryHookOptions<IAttendanceTotalsPerWeekData, IGetAttendanceTotalsPerWeekVariables>,
  fields?: string
) =>
  useLazyQuery<IAttendanceTotalsPerWeekData, IGetAttendanceTotalsPerWeekVariables>(GET_ATTENDANCE_TOTALS_PER_WEEK(), {
    ...options,
    fetchPolicy: 'network-only',
  });

export const GET_ATTENDANCE_TOTALS_PER_WEEK = () => gql`
  query ($input: AttendanceTotalsPerWeekInput!) {
    getAttendanceTotalsPerWeekReport(input: $input)
  }
`;

// BALANCE AT DATE
export const useGetBalanceByDateReportLazy = (
  options?: LazyQueryHookOptions<IGetBalanceByDateReportData, IGetBalanceByDateReportVariables>
) => useLazyQuery<IGetBalanceByDateReportData, IGetBalanceByDateReportVariables>(GET_BALANCE_BY_DATE_REPORT(), options);

export const useGetBalanceByDatePdfReportLazy = (
  options?: LazyQueryHookOptions<IGetBalanceByDatePdfReportData, IGetBalanceByDateReportVariables>
) =>
  useLazyQuery<IGetBalanceByDatePdfReportData, IGetBalanceByDateReportVariables>(
    GET_BALANCE_BY_DATE_PDF_REPORT(),
    options
  );

const GET_BALANCE_BY_DATE_REPORT = (): DocumentNode => gql`
  query ($input: GetBalanceByDateInput!) {
    getBalanceByDateReport(input: $input)
  }
`;

const GET_BALANCE_BY_DATE_PDF_REPORT = (): DocumentNode => gql`
  query ($input: GetBalanceByDateInput!) {
    getBalanceByDatePdfReport(input: $input)
  }
`;

interface IGetBalanceByDateReportData {
  getBalanceByDateReport: string;
}

interface IGetBalanceByDatePdfReportData {
  getBalanceByDatePdfReport: string;
}

interface IGetBalanceByDateReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    date: string | null;
    createdOn?: string | null;
    statusType?: AccountStatusType;
    accountTagId: string | null;
  };
}

// DISBURSEMENTS (MONTHLY)
interface IDisbursementsMonthlyData {
  getPayrixDisbursementsMonthlyReport: string;
}

interface IGetDisbursementsMonthlyVariables {
  input: {
    businessId: string;
    centerIds: string[];
    month: string;
  };
}

export const useGetDisbursementsMonthlyLazy = (
  options?: LazyQueryHookOptions<IDisbursementsMonthlyData, IGetDisbursementsMonthlyVariables>,
  fields?: string
) =>
  useLazyQuery<IDisbursementsMonthlyData, IGetDisbursementsMonthlyVariables>(GET_DISBURSEMENTS_MONTHLY(), {
    ...options,
    fetchPolicy: 'network-only',
  });

export const GET_DISBURSEMENTS_MONTHLY = () => gql`
  query ($input: DisbursementsMonthlyInput!) {
    getPayrixDisbursementsMonthlyReport(input: $input)
  }
`;

// Meal Tracking report
export interface IMealTrackingReportInput {
  businessId: string;
  centers: string[];
  month: string;
}

//COVID Tracking Report
export const useGetChildLastAttendanceByDateReportLazy = (
  options?: LazyQueryHookOptions<IGetChildLastAttendanceReportData, IGetChildLastAttendanceByDateReportVariables>
) =>
  useLazyQuery<IGetChildLastAttendanceReportData, IGetChildLastAttendanceByDateReportVariables>(
    GET_CHILD_LAST_ATTENDANCE_BY_DATE_REPORT(),
    options
  );

const GET_CHILD_LAST_ATTENDANCE_BY_DATE_REPORT = (): DocumentNode => gql`
  query ($input: ChildLastAttendanceReportInput!) {
    getChildLastAttendanceReport(input: $input)
  }
`;

interface IGetChildLastAttendanceReportData {
  getChildLastAttendanceReport: string;
}

interface IGetChildLastAttendanceByDateReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    date: string;
    accountStatus?: AccountStatusType;
  };
}

// CHILD CHECK IN
export const useGetChildCheckInReportLazy = (
  options?: LazyQueryHookOptions<IGetChildCheckInReportData, IGetChildCheckInReportVariables>
) =>
  useLazyQuery<IGetChildCheckInReportData, IGetChildCheckInReportVariables>(GET_CHILD_CHECK_IN_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_CHILD_CHECK_IN_REPORT = (): DocumentNode => gql`
  query ($input: GetChildCheckInInput!) {
    getChildCheckInReport(input: $input)
  }
`;

interface IGetChildCheckInReportData {
  getChildCheckInReport: string;
}

interface IGetChildCheckInReportVariables {
  input: {
    centerId: string;
    classIds: string[];
    startDate: string;
    endDate: string;
  };
}

// CHILD HEALTH && WELLNESS (BY CHILD)
export const useGetChildWellnessReportLazy = (
  options?: LazyQueryHookOptions<IGetChildWellnessReportData, IGetChildWellnessReportVariables>
) =>
  useLazyQuery<IGetChildWellnessReportData, IGetChildWellnessReportVariables>(GET_CHILD_WELLNESS_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

export const useGetChildWellnessPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetChildWellnessPdfReportData, IGetChildWellnessReportVariables>
) =>
  useLazyQuery<IGetChildWellnessPdfReportData, IGetChildWellnessReportVariables>(GET_CHILD_WELLNESS_PDF_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_CHILD_WELLNESS_PDF_REPORT = (): DocumentNode => gql`
  query ($input: ChildWellnessConditionReportInput!) {
    getChildWellnessPdfReport(input: $input)
  }
`;

const GET_CHILD_WELLNESS_REPORT = (): DocumentNode => gql`
  query ($input: ChildWellnessConditionReportInput!) {
    getChildWellnessReport(input: $input)
  }
`;

interface IGetChildWellnessReportVariables {
  input: {
    businessId: string;
    centerId: string;
    classIds: string[];
    conditionIds: string[];
    startDate: string;
    endDate?: string;
  };
}

interface IGetChildWellnessReportData {
  getChildWellnessReport: string;
}

interface IGetChildWellnessPdfReportData {
  getChildWellnessPdfReport: string;
}

// CONTRACT
export const useGetContractReportLazy = (
  options?: LazyQueryHookOptions<IGetContractReportData, IGetContractReportVariables>
) => useLazyQuery<IGetContractReportData, IGetContractReportVariables>(GET_CONTRACT_REPORT(), options);

export const useGetNewContractReportLazy = (
  options?: LazyQueryHookOptions<IGetNewContractReportData, IGetContractReportVariables>
) => useLazyQuery<IGetNewContractReportData, IGetContractReportVariables>(GET_NEW_CONTRACT_REPORT(), options);

const GET_CONTRACT_REPORT = (): DocumentNode => gql`
  query ($input: ContractReportInput!) {
    getContractReport(input: $input)
  }
`;

const GET_NEW_CONTRACT_REPORT = (): DocumentNode => gql`
  query ($input: ContractReportInput!) {
    getNewContractReport(input: $input)
  }
`;

interface IGetNewContractReportData {
  getNewContractReport: string;
}

interface IGetContractReportData {
  getContractReport: string;
}

interface IGetContractReportVariables {
  input: {
    centerIds: string[];
    businessId: string;
    runAtDate: string;
    childTagId: string | null;
  };
}

// DEBT
export const useGetDebtExcelReportLazy = (
  options?: LazyQueryHookOptions<IGetExcelDebtReportData, IGetDebtReportVariables>
) => useLazyQuery<IGetExcelDebtReportData, IGetDebtReportVariables>(GET_DEBT_EXCEL_REPORT(), options);

const GET_DEBT_EXCEL_REPORT = (): DocumentNode => gql`
  query ($input: DebtReportInput!) {
    getDebtExcelReport(input: $input)
  }
`;

export const useGetDebtCsvReportLazy = (
  options?: LazyQueryHookOptions<IGetCsvDebtReportData, IGetDebtReportVariables>
) => useLazyQuery<IGetCsvDebtReportData, IGetDebtReportVariables>(GET_DEBT_CSV_REPORT(), options);

const GET_DEBT_CSV_REPORT = (): DocumentNode => gql`
  query ($input: DebtReportInput!) {
    getDebtCsvReport(input: $input)
  }
`;

interface IGetExcelDebtReportData {
  getDebtExcelReport: string;
}

interface IGetCsvDebtReportData {
  getDebtCsvReport: string;
}

interface IGetDebtReportVariables {
  input: {
    centerIds: string[];
    runAtDate: string;
    businessId: string;
    transactionDateType: TransactionDateType;
    statusType?: AccountStatusType;
    excludeCredit: boolean;
    excludeZero: boolean;
  };
}

// EDITS TO CONTRACTS
export const useGetContractEditsPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetContractEditsPdfReportData, IGetContractEditsPdfReportVariables>
) =>
  useLazyQuery<IGetContractEditsPdfReportData, IGetContractEditsPdfReportVariables>(GET_CONTRACT_EDITS_PDF_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_CONTRACT_EDITS_PDF_REPORT = (): DocumentNode => gql`
  query ($input: GetContractEditsReportInput!) {
    getContractEditsPdfReport(input: $input)
  }
`;

interface IGetContractEditsPdfReportData {
  getContractEditsPdfReport: string;
}

interface IGetContractEditsPdfReportVariables {
  input: {
    businessId: string;
    centerId: string;
    accountId?: string | null;
    startDate: string;
    endDate: string;
  };
}

// EMAIL ADDRESS
export const useGetEmailAddressReportLazy = (
  options?: LazyQueryHookOptions<IGetEmailAddressReportData, IGetEmailAddressReportVariables>
) => useLazyQuery<IGetEmailAddressReportData, IGetEmailAddressReportVariables>(GET_EMAIL_ADDRESS_REPORT(), options);

const GET_EMAIL_ADDRESS_REPORT = (): DocumentNode => gql`
  query ($input: EmailAddressReportInput!) {
    getEmailAddressReport(input: $input)
  }
`;

interface IGetEmailAddressReportData {
  getEmailAddressReport: string;
}

interface IGetEmailAddressReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    statusList: AccountStatusType[];
    isPrimary?: boolean;
    contactTagId: string | null;
  };
}

// EMERGENCY CONTACTS
export const useGetEmergencyContactReportLazy = (
  options?: LazyQueryHookOptions<IGetEmergencyContactReportData, IGetEmergencyContactReportVariables>
) =>
  useLazyQuery<IGetEmergencyContactReportData, IGetEmergencyContactReportVariables>(GET_EMERGENCY_CONTACT_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

export const useGetEmergencyContactPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetEmergencyContactPdfReportData, IGetEmergencyContactReportVariables>
) =>
  useLazyQuery<IGetEmergencyContactPdfReportData, IGetEmergencyContactReportVariables>(
    GET_EMERGENCY_CONTACT_PDF_REPORT(),
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

const GET_EMERGENCY_CONTACT_REPORT = (): DocumentNode => gql`
  query ($input: GetEmergencyContactInput!) {
    getEmergencyContactReport(input: $input)
  }
`;

const GET_EMERGENCY_CONTACT_PDF_REPORT = () => gql`
  query ($input: GetEmergencyContactInput!) {
    getEmergencyContactPdfReport(input: $input)
  }
`;

interface IGetEmergencyContactReportData {
  getEmergencyContactReport: string;
}

interface IGetEmergencyContactPdfReportData {
  getEmergencyContactPdfReport: string;
}

interface IGetEmergencyContactReportVariables {
  input: {
    centerIds: string[];
    businessId: string;
    classIds: string[];
    date: string;
  };
}

// EMPLOYEE TIMESHEET
export const useGetEmployeeTimeSheetReportLazy = (
  options?: LazyQueryHookOptions<IGetEmployeeTimeSheetReportData, IGetEmployeeTimeSheetReportVariables>
) =>
  useLazyQuery<IGetEmployeeTimeSheetReportData, IGetEmployeeTimeSheetReportVariables>(
    GET_EMPLOYEE_TIME_SHEET_REPORT(),
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

const GET_EMPLOYEE_TIME_SHEET_REPORT = (): DocumentNode => gql`
  query ($input: EmployeeTimeSheetReportInput!) {
    getEmployeeTimeSheetReport(input: $input)
  }
`;

interface IGetEmployeeTimeSheetReportData {
  getEmployeeTimeSheetReport: string;
}

interface IGetEmployeeTimeSheetReportVariables {
  input: {
    businessId: string;
    centerId: string;
    staffIds: string[];
    startDate: string;
    endDate?: string;
  };
}

// ENDED CONTRACTS
export const useEndedContractsReportLazy = (
  options?: LazyQueryHookOptions<IGetEndedContractsReportData, IGetEndedContractsReportVariables>
) =>
  useLazyQuery<IGetEndedContractsReportData, IGetEndedContractsReportVariables>(GET_ENDED_CONTRACTS_REPORT(), options);

const GET_ENDED_CONTRACTS_REPORT = (): DocumentNode => gql`
  query ($input: EndedContractsReportInput!) {
    getEndedContractsReport(input: $input)
  }
`;

interface IGetEndedContractsReportData {
  getEndedContractsReport: string;
}

interface IGetEndedContractsReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    startDate: string;
    endDate: string;
  };
}

// FAT ZEBRA TRANSACTIONS
export const useGetFatZebraTransactionsReportLazy = (
  options?: LazyQueryHookOptions<IGetFatZebraTransactionsReportData, IGetFatZebraTransactionsReportVariables>
) =>
  useLazyQuery<IGetFatZebraTransactionsReportData, IGetFatZebraTransactionsReportVariables>(
    GET_FAT_ZEBRA_TRANSACTIONS_REPORT(),
    options
  );

const GET_FAT_ZEBRA_TRANSACTIONS_REPORT = (): DocumentNode => gql`
  query ($input: GetFatZebraTransactionsInput!) {
    getFatZebraTransactionsReport(input: $input)
  }
`;

interface IGetFatZebraTransactionsReportData {
  getFatZebraTransactionsReport: string;
}

interface IGetFatZebraTransactionsReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    startDate: string;
    endDate: string;
  };
}

// NAME TO FACE
export const useGetNameToFaceReportLazy = (
  options?: LazyQueryHookOptions<IGetNameToFaceReportData, IGetNameToFaceReportVariables>
) =>
  useLazyQuery<IGetNameToFaceReportData, IGetNameToFaceReportVariables>(GET_NAME_TO_FACE_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

export const useGetNameToFacePdfReportLazy = (
  options?: LazyQueryHookOptions<IGetNameToFacePdfReportData, IGetNameToFaceReportVariables>
) =>
  useLazyQuery<IGetNameToFacePdfReportData, IGetNameToFaceReportVariables>(GET_NAME_TO_FACE_PDF_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_NAME_TO_FACE_PDF_REPORT = (): DocumentNode => gql`
  query ($input: GetNameToFaceInput!) {
    getNameToFacePdfReport(input: $input)
  }
`;

const GET_NAME_TO_FACE_REPORT = (): DocumentNode => gql`
  query ($input: GetNameToFaceInput!) {
    getNameToFaceReport(input: $input)
  }
`;

interface IGetNameToFaceReportData {
  getNameToFaceReport: string;
}

interface IGetNameToFacePdfReportData {
  getNameToFacePdfReport: string;
}

interface IGetNameToFaceReportVariables {
  input: {
    centerId: string;
    classIds: string[];
    startDate: string;
    endDate: string;
  };
}

// OCCUPANCY
export const useGetOccupancyTotalReportLazy = (
  options?: LazyQueryHookOptions<IGetOccupancyTotalReportData, IGetOccupancyTotalReportVariables>
) =>
  useLazyQuery<IGetOccupancyTotalReportData, IGetOccupancyTotalReportVariables>(GET_OCCUPANCY_TOTAL_REPORT(), options);

export const useGetOccupancyWeeklyTotalReportLazy = (
  options?: LazyQueryHookOptions<IGetOccupancyWeeklyTotalReportData, IGetOccupancyTotalReportVariables>
) =>
  useLazyQuery<IGetOccupancyWeeklyTotalReportData, IGetOccupancyTotalReportVariables>(
    GET_OCCUPANCY_WEEKLY_TOTAL_REPORT(),
    options
  );

export const useGetOccupancyTotalReport = (
  options?: QueryHookOptions<IGetOccupancyTotalReportData, IGetOccupancyTotalReportVariables>
) => useQuery<IGetOccupancyTotalReportData, IGetOccupancyTotalReportVariables>(GET_OCCUPANCY_TOTAL_REPORT(), options);

const GET_OCCUPANCY_WEEKLY_TOTAL_REPORT = (): DocumentNode => gql`
  query ($input: GetOccupancyTotalInput!) {
    getOccupancyWeeklyTotalReport(input: $input)
  }
`;

const GET_OCCUPANCY_TOTAL_REPORT = (): DocumentNode => gql`
  query ($input: GetOccupancyTotalInput!) {
    getOccupancyTotalReport(input: $input) {
      reportMetaData {
        ${reportMetadataFields}
      }
      reportData {
        ${occupancyTotalReportFields}
      }
    }
  }
`;

export interface IGetOccupancyTotalReportVariables {
  input: {
    centerIds: string[];
    startDate: string;
    endDate: string;
    businessId: string;
    classIds: string[];
    excludeNonCcsCareType: boolean;
    excludeClassesWithNoAttendance: boolean;
    includeLeadApplications?: boolean;
  };
}

interface IGetOccupancyTotalReportData {
  getOccupancyTotalReport: IOccupancyTotalReport;
}

interface IGetOccupancyWeeklyTotalReportData {
  getOccupancyWeeklyTotalReport: string;
}

// OUTSTANDING CCS
export const useGetOutstandingCcsReportLazy = (
  options?: LazyQueryHookOptions<IGetOutstandingCcsReportData, IGetOutstandingCcsReportVariables>
) =>
  useLazyQuery<IGetOutstandingCcsReportData, IGetOutstandingCcsReportVariables>(GET_OUTSTANDING_CCS_REPORT(), options);

const GET_OUTSTANDING_CCS_REPORT = (): DocumentNode => gql`
  query ($input: CcsOutstandingReportInput!) {
    getCcsOutstandingReport(input: $input)
  }
`;

interface IGetOutstandingCcsReportData {
  getCcsOutstandingReport: string;
}

interface IGetOutstandingCcsReportVariables {
  input: {
    centerIds: string[];
    startDate: string;
    endDate: string;
    businessId: string;
    dateType: TransactionDateType;
    accountStatus?: AccountStatusType;
  };
}

// QLD CENSUS
export const useGetQLDCensusReportLazy = (
  options?: LazyQueryHookOptions<IGetQLDCensusReport, IGetQLDCensusReportVariables>
) =>
  useLazyQuery<IGetQLDCensusReport, IGetQLDCensusReportVariables>(GET_QLD_CENSUS_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

interface IGetQLDCensusReportVariables {
  input: {
    businessId: string;
    centerIds: string[] | null;
    startDate: string;
    endDate: string;
  };
}
interface IGetQLDCensusReport {
  getQLDCensusReport: string;
}

const GET_QLD_CENSUS_REPORT = (): DocumentNode => gql`
  query ($input: GetQLDCensusReportInput!) {
    getQLDCensusReport(input: $input)
  }
`;

// QUARTER HOUR CHECK IN
export const useGetQuarterHourCheckInPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetQuarterHourCheckInPdfReportData, IGetQuarterHourCheckInPdfReportVariables>
) =>
  useLazyQuery<IGetQuarterHourCheckInPdfReportData, IGetQuarterHourCheckInPdfReportVariables>(
    GET_QUARTERHOUR_CHECK_IN_PDF_REPORT(),
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

const GET_QUARTERHOUR_CHECK_IN_PDF_REPORT = (): DocumentNode => gql`
  query ($input: QuarterHourCheckInInput!) {
    getQuarterHourCheckInReport(input: $input)
  }
`;

interface IGetQuarterHourCheckInPdfReportData {
  getQuarterHourCheckInReport: string;
}

interface IGetQuarterHourCheckInPdfReportVariables {
  input: {
    centerId: string;
    date: string;
  };
}

// SESSION FEES AND TIMES
export const useGetSessionFeesAndTimesExcelLazy = (
  options?: LazyQueryHookOptions<IGetSessionFeesAndTimesReportExcelData, IGetSessionFeesAndTimesReportVariables>
) =>
  useLazyQuery<IGetSessionFeesAndTimesReportExcelData, IGetSessionFeesAndTimesReportVariables>(
    GET_SESSION_FEES_AND_TIMES_REPORT_EXCEL(),
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

const GET_SESSION_FEES_AND_TIMES_REPORT_EXCEL = (): DocumentNode => gql`
  query ($input: AttendanceReportDataInput!) {
    getAttendanceReportExcel(input: $input)
  }
`;

export const useGetSessionFeesAndTimesCsvLazy = (
  options?: LazyQueryHookOptions<IGetSessionFeesAndTimesReportCsvData, IGetSessionFeesAndTimesReportVariables>
) =>
  useLazyQuery<IGetSessionFeesAndTimesReportCsvData, IGetSessionFeesAndTimesReportVariables>(
    GET_SESSION_FEES_AND_TIMES_REPORT_CSV(),
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

const GET_SESSION_FEES_AND_TIMES_REPORT_CSV = (): DocumentNode => gql`
  query ($input: AttendanceReportDataInput!) {
    getAttendanceReportCsv(input: $input)
  }
`;

interface IGetSessionFeesAndTimesReportExcelData {
  getAttendanceReportExcel: string;
}

interface IGetSessionFeesAndTimesReportCsvData {
  getAttendanceReportCsv: string;
}

type IGetSessionFeesAndTimesReportVariables = {
  input: {
    centerIds: string[];
    startDate: string;
    endDate: string;
    businessId: string;
    selectedAccountTag: string | null;
    selectedChildTag: string | null;
  };
};

// SIGN IN / OUT
export const useGetSignInSignOutReportLazy = (
  options?: QueryHookOptions<IGetSignInSignOutReportData, IGetSignInSignOutReportVariables>
) =>
  useLazyQuery<IGetSignInSignOutReportData, IGetSignInSignOutReportVariables>(GET_SIGN_IN_SIGN_OUT_REPORT(), options);

const GET_SIGN_IN_SIGN_OUT_REPORT = (): DocumentNode => gql`
  query {
    getSignInSignOutReport
  }
`;

interface IGetSignInSignOutReportData {
  getSignInSignOutReport: string;
}

interface IGetSignInSignOutReportVariables {}

// TRANSACTION TOTALS
export const useGetTransactionTotalsByTransactionTypeReportLazy = (
  options?: LazyQueryHookOptions<
    IGetTransactionTotalsByTransactionTypeReportData,
    IGetTransactionTotalsByTransactionTypeReportVariables
  >
) =>
  useLazyQuery<IGetTransactionTotalsByTransactionTypeReportData, IGetTransactionTotalsByTransactionTypeReportVariables>(
    GET_TRANSACTION_TOTALS_BY_TRANSACTION_TYPES_REPORT(),
    options
  );

export const useGetTransactionTotals = (
  options?: QueryHookOptions<IGetTransactionTotalsData, IGetTransactionTotalsReportVariables>
) => useQuery<IGetTransactionTotalsData, IGetTransactionTotalsReportVariables>(GET_TRANSACTION_TOTALS(), options);

export const useGetTransactionTotalsReportLazy = (
  options?: LazyQueryHookOptions<IGetTransactionTotalsReportData, IGetTransactionTotalsReportVariables>
) =>
  useLazyQuery<IGetTransactionTotalsReportData, IGetTransactionTotalsReportVariables>(
    GET_TRANSACTION_TOTALS_REPORT(),
    options
  );

export const useGetTransactionReportLazy = (
  options?: LazyQueryHookOptions<IGetTransactionReportData, IGetTransactionReportVariables>
) => useLazyQuery<IGetTransactionReportData, IGetTransactionReportVariables>(GET_TRANSACTION_REPORT(), options);

const GET_TRANSACTION_TOTALS_BY_TRANSACTION_TYPES_REPORT = (): DocumentNode => gql`
  query ($input: GetTransactionByTransactionTypeTotalInput!) {
    getTransactionTotalByTransactionTypeReport(input: $input)
  }
`;

const GET_TRANSACTION_TOTALS = (): DocumentNode => gql`
  query ($input: GetTransactionTotalInput!) {
    getTransactionTotal(input: $input) {
      reportMetaData {
        ${reportMetadataFields}
      }
      reportData {
        ${transactionTotalsReportFields}
      }
    }
  }
`;

const GET_TRANSACTION_TOTALS_REPORT = (): DocumentNode => gql`
  query ($input: GetTransactionTotalInput!) {
    getTransactionTotalReport(input: $input)
  }
`;

const GET_TRANSACTION_REPORT = (): DocumentNode => gql`
  query ($input: TransactionReportInput!) {
    getTransactionReport(input: $input)
  }
`;

interface IGetTransactionTotalsReportData {
  getTransactionTotalReport: string;
}

interface IGetTransactionTotalsByTransactionTypeReportData {
  getTransactionTotalByTransactionTypeReport: string;
}

interface IGetTransactionTotalsData {
  getTransactionTotal: ITransactionTotalReport;
}

interface IGetTransactionReportData {
  getTransactionReport: string;
}

interface IGetTransactionReportVariables {
  input: IGetTransactionReportInput;
}

// PAYROLL
export const useGetWeeklyPayrollReportLazy = (
  options?: LazyQueryHookOptions<IGetWeeklyPayrollReportData, IGetWeeklyPayrollReportVariables>
) => useLazyQuery<IGetWeeklyPayrollReportData, IGetWeeklyPayrollReportVariables>(GET_WEEKLY_PAYROLL_REPORT(), options);

export const useGetAdpWeeklyPayrollReportLazy = (
  options?: LazyQueryHookOptions<IGetAdpWeeklyPayrollReportData, IGetWeeklyPayrollReportVariables>
) =>
  useLazyQuery<IGetAdpWeeklyPayrollReportData, IGetWeeklyPayrollReportVariables>(
    GET_ADP_WEEKLY_PAYROLL_REPORT(),
    options
  );

const GET_WEEKLY_PAYROLL_REPORT = (): DocumentNode => gql`
  query ($input: GetWeeklyPayrollInput!) {
    getWeeklyPayrollReport(input: $input)
  }
`;

const GET_ADP_WEEKLY_PAYROLL_REPORT = (): DocumentNode => gql`
  query ($input: GetWeeklyPayrollInput!) {
    getAdpWeeklyPayrollReport(input: $input)
  }
`;

interface IGetWeeklyPayrollReportData {
  getWeeklyPayrollReport: string;
}

interface IGetAdpWeeklyPayrollReportData {
  getAdpWeeklyPayrollReport: string;
}

type IGetWeeklyPayrollReportVariables = IReportParamsInput;

// CONTRACT FEE
export const useGetContractFeePdfReportLazy = (
  options?: LazyQueryHookOptions<IGetContractFeePdfReportData, IGetContractFeeReportVariables>
) => useLazyQuery<IGetContractFeePdfReportData, IGetContractFeeReportVariables>(GET_CONTRACT_FEE_PDF_REPORT(), options);

export const useGetContractFeeDetailedPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetContractFeeDetailedPdfReportData, IGetContractFeeDetailedReportVariables>
) =>
  useLazyQuery<IGetContractFeeDetailedPdfReportData, IGetContractFeeDetailedReportVariables>(
    GET_CONTRACT_FEE_DETAILED_PDF_REPORT(),
    options
  );

const GET_CONTRACT_FEE_PDF_REPORT = (): DocumentNode => gql`
  query ($input: GetContractFeeInput!) {
    getContractFeePdfReport(input: $input)
  }
`;

const GET_CONTRACT_FEE_DETAILED_PDF_REPORT = (): DocumentNode => gql`
  query ($input: GetContractFeeDetailedInput!) {
    getContractFeeDetailedPdfReport(input: $input)
  }
`;

interface IGetContractFeeDetailedPdfReportData {
  getContractFeeDetailedPdfReport: string;
}

interface IGetContractFeePdfReportData {
  getContractFeePdfReport: string;
}

interface IGetContractFeeDetailedReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    date: string;
  };
}

interface IGetContractFeeReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    date: string;
  };
}

// SAGE GL
export const useGetSageGlReportLazy = (
  options?: LazyQueryHookOptions<IGetSageGlReportData, IGetSageGlReportVariables>
) => useLazyQuery<IGetSageGlReportData, IGetSageGlReportVariables>(GET_SAGE_GL_REPORT(), options);

const GET_SAGE_GL_REPORT = (): DocumentNode => gql`
  query ($input: SageGlImportInput!) {
    getSageGlReport(input: $input) {
      ${sageGlReportFields}
    }
  }
`;

interface IGetSageGlReportData {
  getSageGlReport: ISageGlImportReportWrapper;
}

interface IGetSageGlReportVariables {
  input: {
    centerIds: string[];
    startDate: string;
    endDate: string;
  };
}

// ACCOUNT CONTACT INFORMATION
export const useGetAccountContactInformationReportLazy = (
  options?: LazyQueryHookOptions<IGetAccountContactInformationReportData, IGetAccountContactInformationReportVariables>
) =>
  useLazyQuery<IGetAccountContactInformationReportData, IGetAccountContactInformationReportVariables>(
    GET_ACCOUNT_CONTACT_INFORMATION_REPORT(),
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

const GET_ACCOUNT_CONTACT_INFORMATION_REPORT = (): DocumentNode => gql`
  query ($input: GetAccountContactInformationInput!) {
    getAccountContactInformationReport(input: $input)
  }
`;

interface IGetAccountContactInformationReportData {
  getAccountContactInformationReport: string;
}

interface IGetAccountContactInformationReportVariables {
  input: {
    centerIds: string[];
    businessId: string;
    date: string;
    statusType?: AccountStatusType;
  };
}

// BILLING SUMMARY
export const useBillingSummaryPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetBillingSummaryPdfReportData, IGetBillingSummaryReportVariables>
) =>
  useLazyQuery<IGetBillingSummaryPdfReportData, IGetBillingSummaryReportVariables>(GET_BILLING_SUMMARY_PDF_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_BILLING_SUMMARY_PDF_REPORT = (): DocumentNode => gql`
  query ($input: GetBillingSummaryInput!) {
    getBillingSummaryPdfReport(input: $input)
  }
`;

interface IGetBillingSummaryPdfReportData {
  getBillingSummaryPdfReport: string;
}

interface IGetBillingSummaryReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    startDate: string;
    endDate: string;
  };
}

// CLASS ATTENDANCE ROASTER
export const useGetClassAttendanceRosterPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetClassAttendanceRosterPdfReportData, IGetClassAttendanceRosterPdfReportVariables>
) =>
  useLazyQuery<IGetClassAttendanceRosterPdfReportData, IGetClassAttendanceRosterPdfReportVariables>(
    GET_CLASS_ATTENDANCE_ROSTER_PDF_REPORT(),
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

const GET_CLASS_ATTENDANCE_ROSTER_PDF_REPORT = (): DocumentNode => gql`
  query ($input: ClassAttendanceRosterReportInput!) {
    getClassAttendanceRosterReport(input: $input)
  }
`;

interface IGetClassAttendanceRosterPdfReportData {
  getClassAttendanceRosterReport: string;
}

interface IGetClassAttendanceRosterPdfReportVariables {
  input: {
    businessId: string;
    centerId: string;
    classIds: string[];
    date: string;
  };
}

// BATCH PAYMENT
export const useGetBatchPaymentPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetBatchPaymentPdfReportData, IGetBatchPaymentPdfReportVariables>
) =>
  useLazyQuery<IGetBatchPaymentPdfReportData, IGetBatchPaymentPdfReportVariables>(GET_BATCH_PAYMENT_PDF_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_BATCH_PAYMENT_PDF_REPORT = (): DocumentNode => gql`
  query ($batchId: ID!) {
    getBatchPaymentReport(batchId: $batchId)
  }
`;

interface IGetBatchPaymentPdfReportData {
  getBatchPaymentReport: string;
}

interface IDepositsHeldReportData {
  getDepositsHeldReport: string;
}

interface IGetBatchPaymentPdfReportVariables {
  batchId: string;
}

// CHILD ATTENDANCE SUMMARY
interface IChildAttendanceSummaryData {
  getChildAttendanceSummaryReport: string;
}

interface IGetChildAttendanceSummaryVariables {
  input: {
    businessId: string;
    centerId: string;
    classIds: string[] | null;
    startDate: string;
    endDate: string;
    childTagId: string | null;
  };
}

export const GET_CHILD_ATTENDANCE_SUMMARY = () => gql`
  query ($input: ChildAttendanceSummaryInput!) {
    getChildAttendanceSummaryReport(input: $input)
  }
`;

export const useGetChildAttendanceSummaryLazy = (
  options?: LazyQueryHookOptions<IChildAttendanceSummaryData, IGetChildAttendanceSummaryVariables>,
  _fields?: string
) =>
  useLazyQuery<IChildAttendanceSummaryData, IGetChildAttendanceSummaryVariables>(GET_CHILD_ATTENDANCE_SUMMARY(), {
    ...options,
    fetchPolicy: 'network-only',
  });

// QKFS
export const useGetQkfsReportLazy = (options?: LazyQueryHookOptions<IGetQkfsReportData, IGetQkfsReportVariables>) =>
  useLazyQuery<IGetQkfsReportData, IGetQkfsReportVariables>(GET_QKFS_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_QKFS_REPORT = (): DocumentNode => gql`
  query ($input: GetQkfsReportInput!) {
    getQkfsReport(input: $input)
  }
`;

interface IGetQkfsReportData {
  getQkfsReport: string;
}

interface IGetQkfsReportVariables {
  input: {
    businessId: string;
    programIds: string[];
    startDate: string;
    endDate: string;
    isConsolidated: boolean;
  };
}

// InClUsIoN sUpPoRt PaYmEnT
export const useGetIsPaymentReportLazy = (
  options?: LazyQueryHookOptions<IIsPaymentReportData, IGetIsPaymentReportVariables>
) => useLazyQuery<IIsPaymentReportData, IGetIsPaymentReportVariables>(GET_IS_PAYMENT_REPORT(), options);

const GET_IS_PAYMENT_REPORT = (): DocumentNode => gql`
  query ($input: IsPaymentReportInput!) {
    getIsPaymentReport(input: $input)
  }
`;

interface IIsPaymentReportData {
  getIsPaymentReport: string;
}

// CCS SUBSIDY PAYMENT
export const useGetCcsSubsidyPaymentReportLazy = (
  options?: LazyQueryHookOptions<ICcsSubsidyPaymentReportData, IGetCcsSubsidyPaymentReportVariables>
) =>
  useLazyQuery<ICcsSubsidyPaymentReportData, IGetCcsSubsidyPaymentReportVariables>(
    GET_CCS_SUBSIDY_PAYMENT_REPORT(),
    options
  );

const GET_CCS_SUBSIDY_PAYMENT_REPORT = (): DocumentNode => gql`
  query ($input: CcsSubsidyPaymentReportInput!) {
    getCcsSubsidyPaymentReport(input: $input)
  }
`;

interface ICcsSubsidyPaymentReportData {
  getCcsSubsidyPaymentReport: string;
}

interface IGetCcsSubsidyPaymentReportVariables {
  input: {
    businessId: string;
    centerIds: string[];
    startDate: string;
    endDate: string;
    accountIds?: string[];
    childIds?: string[];
  };
}

// CCS SUBSIDY REMITTANCE
export const useLazyGetCcsSubsidyRemittanceReport = (
  options?: QueryHookOptions<ICcsSubsidyRemittanceReportData, InputVariable<ICcsSubsidyRemittanceReportInput>>
) =>
  useLazyQuery<ICcsSubsidyRemittanceReportData, InputVariable<ICcsSubsidyRemittanceReportInput>>(
    CCS_SUBSIDY_REMITTANCE_REPORT(),
    {
      fetchPolicy: 'cache-and-network',
      ...options,
      onError: (error: ApolloError) => {
        showToast(error.message, 'error');
      },
    }
  );

const CCS_SUBSIDY_REMITTANCE_REPORT = (): DocumentNode => gql`
  query ($input: CcsSubsidyRemittanceReportInput!) {
    getCcsSubsidyRemittanceReport(input: $input)
  }
`;

interface ICcsSubsidyRemittanceReportData {
  getCcsSubsidyRemittanceReport: string;
}

interface ICcsSubsidyRemittanceReportInput {
  startDate: string | null;
  endDate: string | null;
  businessId: string;
  centerIds: string[];
  clearingNumber?: string;
}

// ROYALTY
export const useGetRoyaltyPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetRoyaltyReportData, IGetRoyaltyReportVariables>
) =>
  useLazyQuery<IGetRoyaltyReportData, IGetRoyaltyReportVariables>(GET_ROYALTY_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_ROYALTY_REPORT = (): DocumentNode => gql`
  query ($input: RoyaltyReportInput!) {
    getRoyaltyPdfReport(input: $input)
  }
`;

interface IGetRoyaltyReportData {
  getRoyaltyPdfReport: string;
}

interface IGetRoyaltyReportVariables {
  input: {
    businessId: string;
    centerIds: string[] | null;
    startDate: string;
    endDate: string;
    includeSubsidyPayments: boolean;
  };
}

// CHILD TRACKING
export const useGetChildTrackingPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetChildTrackingPdfReportData, IGetChildTrackingPdfReportVariables>
) =>
  useLazyQuery<IGetChildTrackingPdfReportData, IGetChildTrackingPdfReportVariables>(GET_CHILD_TRACKING_PDF_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_CHILD_TRACKING_PDF_REPORT = (): DocumentNode => gql`
  query ($input: GetChildTrackingReportInput!) {
    getChildTrackingPdfReport(input: $input)
  }
`;

interface IGetChildTrackingPdfReportData {
  getChildTrackingPdfReport: string;
}

interface IGetChildTrackingPdfReportVariables {
  input: {
    businessId: string;
    centerId: string;
    startDate: string;
    endDate: string;
    spaceId: string | null;
    childId: string | null;
  };
}

// PAYMENT
export const useGetPaymentReportLazy = (
  options?: LazyQueryHookOptions<IGetPaymentReportData, IGetPaymentReportVariables>
) => useLazyQuery<IGetPaymentReportData, IGetPaymentReportVariables>(GET_PAYMENT_REPORT(), options);

const GET_PAYMENT_REPORT = (): DocumentNode => gql`
  query ($input: PaymentReportInput!) {
    getPaymentReport(input: $input)
  }
`;

interface IGetPaymentReportVariables {
  input: IGetPaymentReportInput;
}

interface IGetPaymentReportData {
  getPaymentReport: string;
}

// CCS ENTITLEMENT
export const useGetCcsEntitlementsReportLazy = (
  options?: LazyQueryHookOptions<ICcsEntitlementsReportData, IGetCcsEntitlementsReportVariables>
) =>
  useLazyQuery<ICcsEntitlementsReportData, IGetCcsEntitlementsReportVariables>(GET_CCS_ENTITLEMENTS_REPORT(), options);

const GET_CCS_ENTITLEMENTS_REPORT = (): DocumentNode => gql`
  query ($businessId: ID!, $input: CcsEntitlementsReportInput!) {
    getCcsEntitlementsReport(businessId: $businessId, input: $input)
  }
`;

interface ICcsEntitlementsReportData {
  getCcsEntitlementsReport: string;
}

interface IGetCcsEntitlementsReportVariables {
  businessId: string;
  input: IGetCcsEntitlementsReportInput;
}

// ACCS
export const useGetAccsReportLazy = (options?: LazyQueryHookOptions<IAccsReportData, IGetAccsReportVariables>) =>
  useLazyQuery<IAccsReportData, IGetAccsReportVariables>(GET_ACCS_REPORT(), options);

const GET_ACCS_REPORT = (): DocumentNode => gql`
  query ($businessId: ID!, $input: AccsReportInput!) {
    getAccsReport(businessId: $businessId, input: $input)
  }
`;

interface IAccsReportData {
  getAccsReport: string;
}

interface IGetAccsReportVariables {
  businessId: string;
  input: IGetAccsReportInput;
}

// DEPOSIT HELD
interface IDepositsHeldReportData {
  getDepositsHeldReport: string;
}

export const GET_DEPOSITS_HELD_REPORT = () => gql`
  query ($input: DepositsHeldReportInput!) {
    getDepositsHeldReport(input: $input)
  }
`;

export const useGetDepositsHeldReportLazy = (
  options?: LazyQueryHookOptions<IDepositsHeldReportData, IGetDepositsHeldReportVariables>,
  _fields?: string
) =>
  useLazyQuery<IDepositsHeldReportData, IGetDepositsHeldReportVariables>(GET_DEPOSITS_HELD_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

// EXCURSION
export const useGetExcursionReportLazy = (
  options?: LazyQueryHookOptions<IGetExcursionReportData, IGetExcursionReportVariables>
) =>
  useLazyQuery<IGetExcursionReportData, IGetExcursionReportVariables>(GET_EXCURSION_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_EXCURSION_REPORT = () => gql`
  query ($input: GetExcursionReportInput!) {
    getExcursionPdfReport(input: $input)
  }
`;

interface IGetExcursionReportData {
  getExcursionPdfReport: string;
}

interface IGetExcursionReportVariables {
  input: {
    businessId: string;
    centerId: string;
    classIds: string[];
    date: string;
    checkpointsCount: number;
  };
}

// KINDY FOR ALL FORECAST
export const useGetKindyForAllForecastReportLazy = (
  options?: LazyQueryHookOptions<IGetKindyForAllForecastReportData, IGetKindyForAllReportVariables>
) =>
  useLazyQuery<IGetKindyForAllForecastReportData, IGetKindyForAllReportVariables>(GET_KINDY_FOR_ALL_FORECAST_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_KINDY_FOR_ALL_FORECAST_REPORT = (): DocumentNode => gql`
  query ($input: GetKindyForAllReportInput!) {
    getKindyForAllForecastReport(input: $input)
  }
`;

interface IGetKindyForAllForecastReportData {
  getKindyForAllForecastReport: string;
}

interface IGetKindyForAllReportVariables {
  input: {
    businessId: string | undefined;
    centerIds: string[];
    year: string;
    period: string;
  };
}

// KINDY FOR ALL FORECAST ERRORS
export const useGetKindyForAllForecastErrorsReportLazy = (
  options?: LazyQueryHookOptions<
    IGetKindyForAllForecastErrorsReportData,
    IGetKindyForAllSubmissionErrorsReportVariables
  >
) =>
  useLazyQuery<IGetKindyForAllForecastErrorsReportData, IGetKindyForAllSubmissionErrorsReportVariables>(
    GET_KINDY_FOR_ALL_FORECAST_ERRORS_REPORT(),
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

const GET_KINDY_FOR_ALL_FORECAST_ERRORS_REPORT = (): DocumentNode => gql`
  query ($input: GetKindyForAllSubmissionErrorsReportInput!) {
    getKindyForAllForecastErrorsReport(input: $input)
  }
`;

interface IGetKindyForAllForecastErrorsReportData {
  getKindyForAllForecastErrorsReport: string;
}

interface IGetKindyForAllSubmissionErrorsReportVariables {
  input: {
    businessId: string | undefined;
    errors: IKindyForAllSubmissionError[];
  };
}

// KINDY FOR ALL ACQUITTAL
export const useGetKindyForAllAcquittalReportLazy = (
  options?: LazyQueryHookOptions<IGetKindyForAllAcquittalReportData, IGetKindyForAllReportVariables>
) =>
  useLazyQuery<IGetKindyForAllAcquittalReportData, IGetKindyForAllReportVariables>(
    GET_KINDY_FOR_ALL_ACQUITTAL_REPORT(),
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

const GET_KINDY_FOR_ALL_ACQUITTAL_REPORT = (): DocumentNode => gql`
  query ($input: GetKindyForAllReportInput!) {
    getKindyForAllAcquittalReport(input: $input)
  }
`;

interface IGetKindyForAllAcquittalReportData {
  getKindyForAllAcquittalReport: string;
}

// KINDY FOR ALL ACQUITTAL ERRORS
export const useGetKindyForAllAcquittalErrorsReportLazy = (
  options?: LazyQueryHookOptions<
    IGetKindyForAllAcquittalErrorsReportData,
    IGetKindyForAllSubmissionErrorsReportVariables
  >
) =>
  useLazyQuery<IGetKindyForAllAcquittalErrorsReportData, IGetKindyForAllSubmissionErrorsReportVariables>(
    GET_KINDY_FOR_ALL_ACQUITTAL_ERRORS_REPORT(),
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

const GET_KINDY_FOR_ALL_ACQUITTAL_ERRORS_REPORT = (): DocumentNode => gql`
  query ($input: GetKindyForAllSubmissionErrorsReportInput!) {
    getKindyForAllAcquittalErrorsReport(input: $input)
  }
`;

interface IGetKindyForAllAcquittalErrorsReportData {
  getKindyForAllAcquittalErrorsReport: string;
}

// FRANCHISE ROYALTY REPORT
interface IGetFranchiseRoyaltyReportData {
  getFranchiseRoyaltyReport: IFranchiseRoyaltyReport;
}

interface IGetFranchiseRoyaltyReportVariables {
  businessId: string;
}
export const useGetFranchiseRoyaltyReport = (
  options?: QueryHookOptions<IGetFranchiseRoyaltyReportData, IGetFranchiseRoyaltyReportVariables>
) => useQuery(GET_FRANCHISE_ROYALTY_REPORT_QUERY(), options);

const GET_FRANCHISE_ROYALTY_REPORT_QUERY = (): DocumentNode => gql`
  query ($businessId: ID) {
    getFranchiseRoyaltyReport(businessId: $businessId) {
      reportId
      workspaceId
      token
    }
  }
`;

// FRANCHISE POWERBI EXPORT : single
interface IPowerBIExportData {
  exportFranchiseRoyaltyReport: string;
}
interface IPowerBiExportVariables {
  input: {
    reportId: string;
    // workspaceId: string;
    // filter: string;
    businessId: string;
  };
}

const GET_FRANCHISE_ROYALTY_REPORT_EXPORT = (): DocumentNode => gql`
  query ($input: PowerBiExportInput!) {
    exportFranchiseRoyaltyReport(input: $input)
  }
`;

export const useGetFranchiseRoyaltyReportExport = (
  options?: QueryHookOptions<IPowerBIExportData, IPowerBiExportVariables>
) =>
  useLazyQuery(GET_FRANCHISE_ROYALTY_REPORT_EXPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

// FRANCHISE POWERBI EXPORT : workflow based
interface IGetPowerBiExportData {
  reportId: string;
  workspaceId: string;
  exportId: string;
  status: string;
}
interface ICreatePowerBiExportVariables {
  input: {
    reportId: string;
    workspaceId: string;
  };
}
interface IGetPowerBiExportVariables {
  input: {
    reportId: string;
    workspaceId: string;
    exportId: string;
  };
}

interface IGetDownloadPowerBiExportData {
  downloadPowerBiExport: string;
}

export const useCreatePowerBiExport = (
  options?: QueryHookOptions<IGetPowerBiExportData, ICreatePowerBiExportVariables>
) => useLazyQuery(CREATE_POWERBI_EXPORT(), options);

const CREATE_POWERBI_EXPORT = (): DocumentNode => gql`
  query ($input: PowerBiExportInput!) {
    createPowerBiExport(input: $input)
  }
`;

export const useGetPowerBiExport = (options?: QueryHookOptions<IGetPowerBiExportData, IGetPowerBiExportVariables>) =>
  useQuery(GET_POWERBI_EXPORT(), options);

const GET_POWERBI_EXPORT = (): DocumentNode => gql`
  query ($input: PowerBiExportInput!) {
    getPowerBiExport(input: $input)
  }
`;

export const useDownloadPowerBiExport = (
  options?: QueryHookOptions<IGetDownloadPowerBiExportData, IGetPowerBiExportVariables>
) => useQuery(DOWNLOAD_POWERBI_EXPORT(), options);

const DOWNLOAD_POWERBI_EXPORT = (): DocumentNode => gql`
  query ($input: PowerBiExportInput!) {
    downloadPowerBiExport(input: $input)
  }
`;

/* pbim based qraphql */
interface IEmbedReportData {
  getEmbedReport: IEmbedReportDto;
}

interface IEmbedReportDto {
  id: string;
  reportId: string;
  tenantId: string;
  environmentId: string;
  region: string;
  productId: string;
  slot: string;
  status: string;
  token: IEmbedReportTokenDto | undefined;
}

interface IEmbedReportTokenDto {
  powerBiReportId: string;
  powerBiDatasetId: string;
  token: string;
  expiresAt: Date | null;
}

interface IEmbedReportVariables {
  input: {
    businessId: string;
    reportName: string | null;
    reportId: string | null;
    embedContextId: string | null;
  };
}

export const useGetEmbedReport = (options?: QueryHookOptions<IEmbedReportData, IEmbedReportVariables>) =>
  useLazyQuery(GET_EMBED_REPORT_TOKEN(), {
    ...options,
    fetchPolicy: 'cache-first',
  });

const GET_EMBED_REPORT_TOKEN = (): DocumentNode => gql`
  query ($input: EmbedReportInput!) {
    getEmbedReport(input: $input) {
      id
      tenantId
      reportId
      status
      token {
        token
        powerBiReportId
        powerBiDatasetId
        expiresAt
      }
    }
  }
`;

interface IEmbedReportExportVariables {
  input: {
    reportId: string;
    businessId: string;
    exportContextId: string | null;
    reportName: string | null;
    pages: string[];
    bookmarkState: string;
  };
}
interface IEmbedReportExportData {
  exportEmbedReport: IEmbedReportExportDto;
}

interface IEmbedReportExportDto {
  id: string;
  reportId: string;
  tenantId: string;
  environmentId: string;
  region: string;
  productId: string;
  slot: string;
  status: string;
  export: IEmbedReportExportDetailsDto | undefined;
}

interface IEmbedReportExportDetailsDto {
  powerBiReportId: string;
  downloadUrl: string;
  expiresAt: Date | undefined;
}

export const useExportEmbedReport = (options?: QueryHookOptions<IEmbedReportExportData, IEmbedReportExportVariables>) =>
  useLazyQuery(EXPORT_EMBED_REPORT(), {
    ...options,
    fetchPolicy: 'cache-first',
  });
const EXPORT_EMBED_REPORT = (): DocumentNode => gql`
  query ($input: ExportReportInput!) {
    exportEmbedReport(input: $input) {
      id
      tenantId
      reportId
      status
      export {
        powerBiExportId
        downloadUrl
        expiresAt
      }
    }
  }
`;

interface IGetBillingEditsPdfReportData {
  getBillingEditsPdfReport: string;
}

interface IGetBillingEditsPdfReportVariables {
  input: {
    businessId: string;
    centerId: string;
    accountId?: string | null;
    startDate: string;
    endDate: string;
  };
}

// EDITS TO Billing
export const useGetBillingEditsPdfReportLazy = (
  options?: LazyQueryHookOptions<IGetBillingEditsPdfReportData, IGetBillingEditsPdfReportVariables>
) =>
  useLazyQuery<IGetBillingEditsPdfReportData, IGetBillingEditsPdfReportVariables>(GET_BILLING_EDITS_PDF_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });

const GET_BILLING_EDITS_PDF_REPORT = (): DocumentNode => gql`
  query ($input: GetBillingEditsReportInput!) {
    getBillingEditsPdfReport(input: $input)
  }
`;
