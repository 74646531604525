import { Typography, Box, Card, CardContent, Chip } from '@mui/material';
import Switch from 'shared/components/Switch';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface IProps {
  sectionHeader?: string;
  title: string;
  subtitle?: string;
  isBeta?: boolean;
  toggleValue?: boolean;
  handleToggle?: (value: boolean) => void;
  additionalInfo?: JSX.Element;
}

const Setting: React.FC<IProps> = ({
  sectionHeader,
  title,
  subtitle,
  isBeta,
  toggleValue,
  handleToggle,
  additionalInfo,
  ...props
}) => {
  const { t } = useTranslation(['comms', 'translation']);
  const businessId = useSelector((state: RootState) => state.context.businessId);

  return (
    <Box style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1920px' }}>
      {sectionHeader && (
        <Box display="flex" justifyContent="space-between" padding={2}>
          <Typography variant="h2">{sectionHeader}</Typography>
        </Box>
      )}
      <Card sx={{ marginTop: '8px' }} elevation={0} variant="outlined">
        <CardContent sx={{ padding: '16px !important' }} className={'setting-container'}>
          <Box display="flex" justifyContent="space-between" alignItems={'center'}>
            <div>
              {title && (
                <h5>
                  {title}
                  {isBeta && (
                    <Chip
                      label="BETA"
                      sx={{ color: '#3ba8ff', borderColor: '#3ba8ff', marginLeft: 1, fontSize: '60%' }}
                      variant="outlined"
                    />
                  )}
                </h5>
              )}
              {subtitle && <h6>{subtitle}</h6>}
            </div>
            {handleToggle && (
              <Switch value={toggleValue ?? false} onChange={(checked: boolean) => handleToggle(checked)} />
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Setting;
