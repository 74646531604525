export const paymentMethodFields = `
  id
  token
  lastFour
  processorId
  expirationDate
  businessId
  accountId
  routingNumber
  type
  isCreditCard
  isPrimary
  isAutoPay
  personId
  createdAt
  createdBy
  createdByName
  hasEvidence
`;

export const accountPaymentMethodSettingsFields = `
  accountId
  primaryPaymentMethodId
  autoPayPaymentMethodId
`;
