import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, MRT_ColumnDef, MRT_Row, MRT_RowData, useMaterialReactTable } from 'material-react-table';
import { SortingState, PaginationState } from '@tanstack/table-core';
import colors from '_colors.module.scss';
import './_datatable.scss';

// TODO: Add these features as required: server side filtering/paging, row selection, row expansion

// Every sorting custom function is expected to return -1, 0, or 1 in ascending order

interface ColumnMRT<TData extends MRT_RowData> extends MRT_ColumnDef<TData> {
  id: string;
}

interface TableProps<TData extends MRT_RowData> {
  data: TData[];
  columns: ColumnMRT<TData>[];
  loading?: boolean;
  columnFilters?: boolean;
  columnActions?: boolean;
  columnHiding?: boolean;
  textFilters?: boolean;
  noDataText?: ReactNode;
  rowClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: MRT_Row<TData>) => void;

  onPageChange?: (pageIndex: number, pageSize: number) => void;

  multiSort?: boolean;
  initialSort?: SortingState;

  showPagination?: boolean;
  pageSize?: number;
  dataSize?: number;
  activePage?: number;
}

const Table = <T extends MRT_RowData>({
  data,
  columns,
  loading = false,
  columnFilters = false,
  columnActions = false,
  columnHiding = false,
  textFilters = false,
  noDataText = "Oops. We don't have any data for that. Try another search.",
  rowClick,

  multiSort = false,
  initialSort,
  onPageChange,

  showPagination = true,
  pageSize = 20,
  dataSize,
  activePage = 0,
}: TableProps<T>) => {
  const [paginationState, setPaginationState] = useState<any>({
    pageIndex: 0,
    pageSize: 25,
  });

  useEffect(() => {
    // Server-side pagination code goes here.
    // console.log('Page Index', paginationState?.pageIndex, 'Page Size', paginationState?.pageSize);
    onPageChange && onPageChange(paginationState?.pageIndex, paginationState?.pageSize);
  }, [paginationState.pageIndex, paginationState.pageSize]);

  const table = useMaterialReactTable<T>({
    muiTablePaperProps: {
      sx: {
        borderRadius: 3,
      },
    },
    muiTableProps: {
      sx: {
        'th[class*="MuiTableCell"]:first-of-type': {
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
        },
        'th[class*="MuiTableCell"]:last-of-type': {
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
        },
        'td[class*="MuiTableCell"]:first-of-type': {
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
        },
        'td[class*="MuiTableCell"]:last-of-type': {
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        fontSize: '0.875rem',
        fontWeight: 600,
        color: colors.textTertiary500,
      },
    },
    muiTableBodyRowProps: rowClick
      ? ({ row }) => ({
          onClick: (event) => rowClick(event, row),
          sx: {
            cursor: 'pointer',
            '&:hover': {
              // backgroundColor: '#F9FAFB',
              backgroundColor: '#FF0000',
            },
          },
        })
      : {
          sx: {
            '&:hover': {
              // backgroundColor: '#F9FAFB',
              backgroundColor: '#FF0000',
            },
          },
        },
    muiTableBodyCellProps: {
      sx: {
        overflow: 'visible',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        background: 'white',
        fontSize: '0.875rem',
        color: colors.textTertiary500,
      },
    },
    muiBottomToolbarProps: {
      sx: {
        background: 'white',
      },
    },
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableMultiSort: multiSort,
    enableColumnFilters: columnFilters,
    enableColumnActions: columnActions,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: columnHiding,
    enableFilters: textFilters,
    enableTopToolbar: columnHiding || textFilters,
    enableRowActions: false,
    renderEmptyRowsFallback: () =>
      typeof noDataText === 'string' ? <div className="text-center">{noDataText}</div> : noDataText,

    state: {
      isLoading: loading,
      pagination: paginationState,
    },
    muiCircularProgressProps: {
      thickness: 5.6,
      size: 64,
    },

    enablePagination: showPagination,
    onPaginationChange: setPaginationState,
    // onSortingChange: setSortingState,
    muiPaginationProps: {
      showRowsPerPage: true,
      shape: 'rounded',
      showFirstButton: false,
      showLastButton: false,
    },
    paginationDisplayMode: 'pages',
    manualPagination: false, // set this to true to start doing server-side pagination.
    pageCount: dataSize !== undefined ? Math.ceil(dataSize / pageSize) : showPagination ? -1 : undefined,
    rowCount: dataSize,
    initialState: {
      pagination: { pageSize, pageIndex: activePage },
      showColumnFilters: true,
      ...(initialSort ? { sorting: initialSort } : {}),
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
    </div>
  );
};

const DataTable = <T extends MRT_RowData>({ data, columns, ...props }: TableProps<T>) => {
  const [tableData, setTableData] = useState(data);
  console.log(colors);
  // if the provided data array changes (ex: after a mutation), we need to update the data array in state which is used in the data table
  useEffect(() => {
    setTableData(data);
  }, [data]);

  const tableColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      enableSorting: column.enableSorting ?? false,
    }));
  }, [columns]);

  return (
    <Table
      data={tableData}
      columns={tableColumns}
      loading={props.loading}
      multiSort={props.multiSort}
      initialSort={props.initialSort}
      rowClick={props.rowClick}
      onPageChange={props.onPageChange}
      columnFilters={props.columnFilters}
      columnActions={props.columnActions}
      columnHiding={props.columnHiding}
      textFilters={props.textFilters}
      noDataText={props.noDataText}
      showPagination={props.showPagination}
      pageSize={props.pageSize}
      activePage={props.activePage}
    />
  );
};

export { DataTable };
