import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Col, Row } from 'shared/components/Layout';
import Form from 'react-bootstrap/Form';
import TextInput from 'shared/components/TextInput';
import { IChildAgencyPaymentTableRowShape } from './ChildPaymentsTable';
import { currencyFormat } from 'shared/util/currency';
import Select from 'shared/components/Select';
import { orderBy } from 'lodash';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isOpen: boolean;
  childAgencyPayment: IChildAgencyPaymentTableRowShape;
  isLoadingAdjustmentOptions: boolean;
  adjustmentOptions: IAdjustment[];
  readOnly: boolean;
  onSave: (updates: IChildAgencyPaymentTableRowShape) => void;
  onClose: () => void;
}

const ManageDifferenceModal: React.FC<IProps> = ({
  isOpen,
  childAgencyPayment,
  isLoadingAdjustmentOptions,
  adjustmentOptions,
  readOnly,
  onSave,
  onClose,
  ...props
}) => {
  const { t } = useTranslation(['subsidies']);
  const [formData, setFormData] = useState<IChildAgencyPaymentTableRowShape>({ ...childAgencyPayment });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    if (formData.differenceAction === 'ADJUST') {
      if (!formData.adjustmentId) {
        showToast(
          t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.adjustment-reason-toast'),
          'error'
        );
        return;
      }
    }

    onSave(formData);
    handleClose();
  }, [formData, handleClose, onSave]);

  const sortedAdjustments = orderBy(adjustmentOptions, (adjustment) => adjustment.name, 'asc').map((adjustment) => ({
    label: adjustment.name,
    value: adjustment.id,
  }));

  return (
    <SideModalDrawer
      title={t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.title')}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      secondaryChoice="Cancel"
      primaryCallback={handleSave}
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="mb-0">
              {t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.child-label')}
            </Form.Label>
            <Form.Control plaintext readOnly defaultValue={`${formData.childFirstName} ${formData.childLastName}`} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="mb-0">
              {t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.child-id-label')}
            </Form.Label>
            <Form.Control
              plaintext
              readOnly
              defaultValue={`${Boolean(formData.childAgencyId) ? formData.childAgencyId : '--'}`}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="mb-0">
              {t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.account-label')}
            </Form.Label>
            <Form.Control plaintext readOnly defaultValue={formData.accountName} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="mb-0">
              {t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.session-label')}
            </Form.Label>
            <Form.Control
              plaintext
              readOnly
              defaultValue={`${formData.contractedNumberOfSessions} ${
                formData.contractedNumberOfSessions === 1 ? 'day' : 'days'
              }`}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="mb-0">
              {t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.difference-label')}
            </Form.Label>
            <Form.Control
              plaintext
              readOnly
              defaultValue={currencyFormat((formData.amountPaidAmount ?? 0) - formData.expectedSubsidyAmount)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              {t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.action-label')}
            </Form.Label>
            <Form.Check
              inline
              label={t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.no-action')}
              name="action-group"
              type="radio"
              checked={formData.differenceAction === 'NONE'}
              onChange={(event) => setFormData((prev) => ({ ...prev, differenceAction: 'NONE' }))}
              disabled={readOnly}
            />
            <Form.Check
              inline
              label={t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.adjust-action')}
              name="action-group"
              type="radio"
              checked={formData.differenceAction === 'ADJUST'}
              onChange={(event) => setFormData((prev) => ({ ...prev, differenceAction: 'ADJUST' }))}
              disabled={readOnly}
            />

            <Form.Check
              inline
              label={t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.transfer-action')}
              type="radio"
              name="action-group"
              checked={formData.differenceAction === 'TRANSFER_TO_ACCOUNT'}
              onChange={(event) => setFormData((prev) => ({ ...prev, differenceAction: 'TRANSFER_TO_ACCOUNT' }))}
              disabled={readOnly}
            />
          </Form.Group>
        </Col>
      </Row>
      {formData.differenceAction === 'ADJUST' && (
        <Row>
          <Col>
            <Select
              required
              label={t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.adjustment-reason-label')}
              options={sortedAdjustments}
              onChange={(opt) => setFormData((prev) => ({ ...prev, adjustmentId: opt.value }))}
              isLoading={isLoadingAdjustmentOptions}
              disabled={readOnly}
              value={sortedAdjustments.find((adjustment) => adjustment.value === formData.adjustmentId)}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {readOnly ? (
            <Form.Group>
              <Form.Label className="mb-0">
                {t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.reason-label')}
              </Form.Label>
              <Form.Control plaintext readOnly defaultValue={formData.differenceActionReason} />
            </Form.Group>
          ) : (
            <TextInput
              label={t('subsidies:agency-billing.new-subsidy-payment.manage-difference-modal.reason-label')}
              value={formData.differenceActionReason}
              onChange={(value) => setFormData((prev) => ({ ...prev, differenceActionReason: value }))}
              as="textarea"
              rows={3}
            />
          )}
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default ManageDifferenceModal;
