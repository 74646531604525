import moment from 'moment';

const buildConcessionData = (concession: IConcessionForm, account: IAccount) => {
  const hasConcessionCard =
    !isEmpty(concession.contact) &&
    (!isEmpty(concession.file) || !isEmpty(concession.fileName)) &&
    !isEmpty(concession.effectiveDate) &&
    !isEmpty(concession.expiryDate) &&
    !isEmpty(concession.type) &&
    !isEmpty(concession.number);

  if (hasConcessionCard)
    return {
      id: concession.id ?? undefined,
      accountId: account.id,
      businessId: account.entityId,
      contactId: concession.contact?.id || '',
      document: concession.file ? { file: concession.file, filename: concession.file.name } : undefined,
      cardNumber: concession?.number || '',
      effectiveDate: concession.effectiveDate || '',
      expiryDate: concession.expiryDate || '',
      type: concession.type || 'HealthCareCard',
      accountChildren: concession?.children?.map((child) => child.accountChildId) || [],
    };
};

const buildDelayedExitData = (delayedExit: IDelayedExitForm, account: IAccount) => {
  const hasDelayedExit = Object.values(delayedExit).some((value) => !(value === undefined || value === null));
  if (hasDelayedExit)
    return {
      id: delayedExit.id ?? undefined,
      childId: delayedExit.child?.id ?? '',
      businessId: account.entityId,
      accountChildId: delayedExit.child?.accountChildId ?? '',
      notes: delayedExit.notes ?? '',
      document: delayedExit.file ? { file: delayedExit.file, filename: delayedExit.file.name } : undefined,
    };
};

const buildFamilyTaxBenefitData = (familyTaxBenefit: IFamilyTaxBenefitForm, account: IAccount) => {
  const hasFamilyTaxBenefit = Object.values(familyTaxBenefit).some((value) => !(value === undefined || value === null));
  if (hasFamilyTaxBenefit)
    return {
      id: familyTaxBenefit.id ?? undefined,
      businessId: account.entityId,
      accountId: familyTaxBenefit.account?.id ?? '',
      part: familyTaxBenefit.part ?? 'None',
      document: familyTaxBenefit.file
        ? { file: familyTaxBenefit.file, filename: familyTaxBenefit.file.name }
        : undefined,
    };
};

export const buildKindyForAllData = (
  kindyForAllChild: IKindyForAllChild | undefined,
  accountChild: IAccountChild
): IKindyForAllChildForm | undefined => {
  if (!kindyForAllChild) {
    return undefined;
  }
  return {
    child: accountChild,
    cancellationDate: kindyForAllChild?.cancellationDate ?? undefined,
    cancellationReason: kindyForAllChild?.cancellationReason ?? 'None',
    hasCancellation: kindyForAllChild?.cancellationDate ? true : false,
    visaType: kindyForAllChild?.visaType ?? 'None',
    kinship: kindyForAllChild?.formalKinshipStartDate ? true : false,
    formalKinshipStartDate: kindyForAllChild?.formalKinshipStartDate ?? undefined,
    formalProtectionCare: kindyForAllChild?.formalProtectionCareStartDate ? true : false,
    formalProtectionCareStartDate: kindyForAllChild?.formalProtectionCareStartDate ?? undefined,
    multiBirth: kindyForAllChild?.multiBirthStartDate ? true : false,
    multiBirthStartDate: kindyForAllChild?.multiBirthStartDate ?? undefined,
    discretionaryStartDate: kindyForAllChild?.discretionaryStartDate ?? undefined,
    disability: kindyForAllChild?.disability ?? false,
    nonEnglishSpeakingBackground: kindyForAllChild?.nonEnglishSpeakingBackground ?? false,
  };
};

export const buildStartStrongData = (
  programChild: IProgramChild | undefined,
  accountId: string,
  accountChild: IAccountChild
): IStartStrongChildForm | undefined => {
  if (!programChild?.startStrongChild) {
    return undefined;
  }

  return {
    child: accountChild,
    childId: accountChild.id,
    nonEnglishSpeakingBackground: programChild?.startStrongChild?.nonEnglishSpeakingBackground ?? false,
    hasSchoolExemptionCertificate: programChild?.startStrongChild?.hasSchoolExemptionCertificate ?? false,
    startStrongConsent: programChild?.startStrongConsent ?? 'None',
    excludeStartStrongFunding: programChild?.excludeStartStrongFunding ?? false,
    declarationForm: programChild?.startStrongDeclarationForm
      ? {
          id: programChild.startStrongDeclarationForm.id,
          accountId: accountId,
          childId: accountChild.id,
          file: undefined,
          linkToFile: programChild.startStrongDeclarationForm.documentUri,
          fileName: programChild.startStrongDeclarationForm.fileName,
        }
      : undefined,
    schoolExemption: programChild?.startStrongSchoolExemption
      ? {
          id: programChild.startStrongSchoolExemption.id,
          accountId: accountId,
          childId: accountChild.id,
          file: undefined,
          linkToFile: programChild.startStrongSchoolExemption.documentUri,
          fileName: programChild.startStrongSchoolExemption.fileName,
        }
      : undefined,
  };
};

export const buildDelayedExitDataForm = (
  delayedExit: IDelayedExit | undefined,
  accountChild: IAccountChild
): IDelayedExitForm | undefined => {
  if (!delayedExit) {
    return undefined;
  }
  return {
    id: delayedExit.id ?? undefined,
    child: accountChild,
    notes: delayedExit.notes ?? undefined,
    file: undefined,
    linkToFile: delayedExit.documentUri ?? undefined,
    fileName: delayedExit.fileName ?? undefined,
  };
};

export const buildProgramChildInput = (formData: IProgramChildForm, account: IAccount): IProgramChildAddInput => {
  return {
    businessId: account.entityId,
    programId: formData.program?.id || '',
    programChildren:
      formData.children?.map((child) => ({
        primaryAccountId: account.id,
        lastFundPaidDate: formData.lastFundPaidDate,
        childReceivesAccs: formData.childReceivesAccs,
        associatedAccountId: account.id,
        childId: child.id,
        startDate: moment(formData.startDate).format('YYYY-MM-DD') || '',
        endDate: formData.endDate ? moment(formData.endDate).format('YYYY-MM-DD') : undefined,
        startStrongConsent: formData.startStrongConsent,
        excludeStartStrongFunding: formData.excludeStartStrongFunding,
      })) || [],
    delayedExits:
      formData.delayedExits
        ?.filter((d) => d.child)
        ?.map((d) => ({
          id: d.id ?? undefined,
          childId: d.child?.id ?? '',
          businessId: account.entityId,
          accountChildId: d.child?.accountChildId ?? '',
          notes: d.notes ?? '',
          document: d.file ? { file: d.file, filename: d.file.name } : undefined,
        })) || [],
    familyTaxBenefit: buildFamilyTaxBenefitData(formData.familyTaxBenefit, account),
    concessionCard: buildConcessionData(formData.concession, account),
    kindyForAllChildren:
      formData.kindyForAllChildren?.map((bc) => ({
        childId: bc.child?.id,
        cancellationDate: bc.cancellationDate ? moment(bc.cancellationDate).format('YYYY-MM-DD') : '',
        cancellationReason: bc.cancellationReason ?? 'None',
        visaType: bc.visaType ?? 'None',
        formalKinshipStartDate: bc.formalKinshipStartDate ? moment(bc.formalKinshipStartDate).format('YYYY-MM-DD') : '',
        formalProtectionCareStartDate: bc.formalProtectionCareStartDate
          ? moment(bc.formalProtectionCareStartDate).format('YYYY-MM-DD')
          : '',
        multiBirthStartDate: bc.multiBirthStartDate ? moment(bc.multiBirthStartDate).format('YYYY-MM-DD') : '',
        discretionaryStartDate: bc.discretionaryStartDate ? moment(bc.discretionaryStartDate).format('YYYY-MM-DD') : '',
        disability: bc.disability ?? false,
        nonEnglishSpeakingBackground: bc.nonEnglishSpeakingBackground ?? false,
      })) || [],
    startStrongChildren:
      formData.startStrongChildren?.map((c) => ({
        childId: c.childId,
        nonEnglishSpeakingBackground: c.nonEnglishSpeakingBackground,
        hasSchoolExemptionCertificate: c.hasSchoolExemptionCertificate,
        declarationForm: c.declarationForm
          ? {
              id: c.declarationForm.id ?? undefined,
              businessId: account.entityId,
              accountId: c.declarationForm.accountId ?? '',
              childId: c.declarationForm.childId ?? '',
              document: c.declarationForm.file
                ? { file: c.declarationForm.file, filename: c.declarationForm.file.name }
                : undefined,
            }
          : undefined,
        schoolExemption: c.schoolExemption
          ? {
              id: c.schoolExemption.id ?? undefined,
              accountId: c.schoolExemption.accountId ?? '',
              childId: c.schoolExemption.childId ?? '',
              businessId: account.entityId,
              document: c.schoolExemption.file
                ? { file: c.schoolExemption.file, filename: c.schoolExemption.file.name }
                : undefined,
            }
          : undefined,
      })) || [],
  };
};

export const buildUpdateChildProgramInput = (
  formData: IEditProgramChildForm,
  programChildId: string,
  account: IAccount,
  primaryAccount: IAccount,
  programId: string,
  childId: string,
  concessionId?: string,
  delayedExitId?: string,
  familyTaxBenefitId?: string
): IProgramChildAndFundingSchedulesUpdateInput => {
  const hasDelayedExit = Object.values(formData.delayedExit as IDelayedExitForm).some(
    (value) => !(value === undefined || value === null)
  );
  const hasFamilyTaxBenefit = Object.values(formData.familyTaxBenefit).some(
    (value) => !(value === undefined || value === null)
  );
  const hasConcessionCard = Object.values(formData.concession).some(
    (value) => !(value === undefined || value === null)
  );

  const kindyForAllChild = (
    formData.kindyForAllChild
      ? {
          childId: formData.kindyForAllChild.child?.id,
          cancellationDate: formData.kindyForAllChild.cancellationDate
            ? moment(formData.kindyForAllChild.cancellationDate).format('YYYY-MM-DD')
            : '',
          cancellationReason: formData.kindyForAllChild.cancellationReason ?? 'None',
          visaType: formData.kindyForAllChild.visaType ?? 'None',
          formalKinshipStartDate: formData.kindyForAllChild.formalKinshipStartDate
            ? moment(formData.kindyForAllChild.formalKinshipStartDate).format('YYYY-MM-DD')
            : '',
          formalProtectionCareStartDate: formData.kindyForAllChild.formalProtectionCareStartDate
            ? moment(formData.kindyForAllChild.formalProtectionCareStartDate).format('YYYY-MM-DD')
            : '',
          multiBirthStartDate: formData.kindyForAllChild.multiBirthStartDate
            ? moment(formData.kindyForAllChild.multiBirthStartDate).format('YYYY-MM-DD')
            : '',
          discretionaryStartDate: formData.kindyForAllChild.discretionaryStartDate
            ? moment(formData.kindyForAllChild.discretionaryStartDate).format('YYYY-MM-DD')
            : '',
          disability: formData.kindyForAllChild.disability ?? false,
          nonEnglishSpeakingBackground: formData.kindyForAllChild.nonEnglishSpeakingBackground ?? false,
        }
      : undefined
  ) as IProgramKindyForAllChildInput;

  const kindyForAllChildren: IProgramKindyForAllChildInput[] = [];

  if (formData.kindyForAllChild) {
    kindyForAllChildren.push(kindyForAllChild);
  }

  const startStrongChild = (
    formData.startStrongChild
      ? {
          childId: formData.startStrongChild.child?.id,
          nonEnglishSpeakingBackground: formData.startStrongChild.nonEnglishSpeakingBackground ?? false,
          hasSchoolExemptionCertificate: formData.startStrongChild.hasSchoolExemptionCertificate ?? false,
          declarationForm: formData.startStrongChild.declarationForm
            ? {
                id: formData.startStrongChild.declarationForm.id ?? undefined,
                businessId: account.entityId,
                accountId: formData.startStrongChild.declarationForm.accountId ?? '',
                childId: formData.startStrongChild.declarationForm.childId ?? '',
                document: formData.startStrongChild.declarationForm.file
                  ? {
                      file: formData.startStrongChild.declarationForm.file,
                      filename: formData.startStrongChild.declarationForm.file.name,
                    }
                  : undefined,
              }
            : undefined,
          schoolExemption: formData.startStrongChild.schoolExemption
            ? {
                id: formData.startStrongChild.schoolExemption.id ?? undefined,
                accountId: formData.startStrongChild.schoolExemption.accountId ?? '',
                childId: formData.startStrongChild.schoolExemption.childId ?? '',
                businessId: account.entityId,
                document: formData.startStrongChild.schoolExemption.file
                  ? {
                      file: formData.startStrongChild.schoolExemption.file,
                      filename: formData.startStrongChild.schoolExemption.file.name,
                    }
                  : undefined,
              }
            : undefined,
        }
      : undefined
  ) as IProgramStartStrongChildInput;

  const startStrongChildren: IProgramStartStrongChildInput[] = [];

  if (formData.startStrongChild) {
    startStrongChildren.push(startStrongChild);
  }

  return {
    businessId: account.entityId,
    programId: programId,
    childReceivesAccs: formData.childReceivesAccs,
    programChild: {
      primaryAccountId: formData.primaryAccountId,
      associatedAccountId: formData.associatedAccountId ?? account.id, // default to the current accountId
      lastFundPaidDate: formData.lastFundPaidDate,
      childReceivesAccs: formData.childReceivesAccs,
      id: programChildId,
      childId: childId,
      startDate: moment(formData.startDate).format('YYYY-MM-DD') || '',
      endDate: formData.endDate ? moment(formData.endDate).format('YYYY-MM-DD') : undefined,
      startStrongConsent: formData.startStrongConsent,
      excludeStartStrongFunding: formData.excludeStartStrongFunding,
    },
    delayedExit: hasDelayedExit
      ? ({ ...buildDelayedExitData(formData.delayedExit as IDelayedExitForm, account), childId } as
          | IProgramChildDelayedExitInput
          | undefined)
      : undefined,
    familyTaxBenefit: hasFamilyTaxBenefit
      ? ({ ...buildFamilyTaxBenefitData(formData.familyTaxBenefit, account), id: familyTaxBenefitId } as
          | IProgramChildFamilyTaxBenefitInput
          | undefined)
      : undefined,
    concessionCard: hasConcessionCard
      ? ({ ...buildConcessionData(formData.concession, account), id: concessionId } as
          | IProgramChildConcessionCardInput
          | undefined)
      : undefined,
    multipleBirth: {
      accountId: account.id,
      childBirthData: [
        {
          childId: childId,
          isMultiBirth: formData.multiBirth,
        },
      ],
    },
    kindyForAllChildren: kindyForAllChildren,
    startStrongChildren: startStrongChildren,
    childFundingSchedules: {
      programChildId: programChildId,
      schedules: formData.childProgramFundingSchedules?.schedules ?? [],
    },
  };
};

// lodash isEmpty does not support Files so created a new one
const isEmpty = (value: any) => {
  if (typeof value === 'string' && value === '') return true;
  if (Array.isArray(value) && value.length === 0) return true;
  return value === undefined || value === null;
};

const isValidKindyForAllChildren = (form: IKindyForAllChildForm[]) => {
  var isValid = true;
  form.map((bc) => {
    isValid = validateKindyForAllChildForm(bc);
    return isValid;
  });
  return isValid;
};

export const validateProgramChildForm = (formData: IProgramChildForm) => {
  if (
    isEmpty(formData.program) ||
    isEmpty(formData.children) ||
    isEmpty(formData.startDate) ||
    isEmpty(formData.primaryAccountId)
  ) {
    return false;
  }

  if (!validateConcessionCardAddForm(formData.concession)) return false;

  if (!validateFamilyTaxBenefitAddForm(formData.familyTaxBenefit)) return false;

  const delayedExitsNotValid = formData.delayedExits?.some((d) => !validateDelayedExitAddForm(d as IDelayedExitForm));
  if (delayedExitsNotValid) return false;

  return formData.kindyForAllChildren ? isValidKindyForAllChildren(formData.kindyForAllChildren) : true;
};

export const validateUpdateChildProgramForm = (
  formData: IEditProgramChildForm,
  concessionId?: string,
  delayedExitId?: string,
  familyTaxBenefitId?: string
) => {
  if (isEmpty(formData.startDate) || isEmpty(formData.primaryAccountId)) {
    return false;
  }

  if (!!concessionId && !validateConcessionCardEditForm(formData, concessionId)) return false;
  if (!concessionId && !validateConcessionCardAddForm(formData.concession)) return false;

  if (!!delayedExitId && !validateDelayedExitEditForm(formData, delayedExitId)) return false;
  if (!delayedExitId && !validateDelayedExitAddForm(formData.delayedExit as IDelayedExitForm)) return false;

  if (!!familyTaxBenefitId && !validateFamilyTaxBenefitEditForm(formData, familyTaxBenefitId)) return false;
  if (!familyTaxBenefitId && !validateFamilyTaxBenefitAddForm(formData.familyTaxBenefit)) return false;

  if (
    formData.childProgramFundingSchedules?.schedules.some(
      (s) => s.basePercentage && (s.basePercentage <= 0 || s.basePercentage > 100)
    )
  )
    return false;

  if (formData.kindyForAllChild && !validateKindyForAllChildForm(formData.kindyForAllChild)) return false;

  return formData.startStrongChild ? validateStartStrongChildForm(formData.startStrongChild) : true;
};

export const isFormEmpty = (form: IDelayedExitForm | IConcessionForm | IFamilyTaxBenefitForm) => {
  return !Object.values(form).some((value) => !isEmpty(value));
};

const validateConcessionCardAddForm = (concessionForm: IConcessionForm) => {
  var isAllEmpty =
    isEmpty(concessionForm.contact) &&
    isEmpty(concessionForm.effectiveDate) &&
    isEmpty(concessionForm.expiryDate) &&
    isEmpty(concessionForm.number) &&
    isEmpty(concessionForm.type) &&
    (isEmpty(concessionForm.file) || isEmpty(concessionForm.fileName));
  if (isAllEmpty) {
    return true;
  } else {
    if (
      isEmpty(concessionForm.contact) ||
      isEmpty(concessionForm.effectiveDate) ||
      isEmpty(concessionForm.expiryDate) ||
      (isEmpty(concessionForm.file) && isEmpty(concessionForm.linkToFile)) ||
      isEmpty(concessionForm.number) ||
      isEmpty(concessionForm.children) ||
      isEmpty(concessionForm.type)
    ) {
      return false;
    }
    return true;
  }
};

const validateConcessionCardEditForm = (formData: IEditProgramChildForm, concessionId?: string) => {
  if (!!concessionId) {
    if (!formData.concession.expiryDate || !formData.concession.number || !formData.concession.effectiveDate) {
      return false;
    }
  }
  return true;
};

const validateDelayedExitAddForm = (delayedExitForm: IDelayedExitForm) => {
  const hasValues =
    !isEmpty(delayedExitForm.notes) || !isEmpty(delayedExitForm.file) || !isEmpty(delayedExitForm.linkToFile);
  if (hasValues) {
    return !isEmpty(delayedExitForm.notes) && (!isEmpty(delayedExitForm.file) || !isEmpty(delayedExitForm.linkToFile));
  }
  return true;
};

const validateDelayedExitEditForm = (formData: IEditProgramChildForm, delayedExitId?: string) => {
  if (!!delayedExitId) {
    if (!formData.delayedExit?.notes) {
      return false;
    }
  }
  return true;
};

const validateFamilyTaxBenefitAddForm = (familyTaxBenefitForm: IFamilyTaxBenefitForm) => {
  const hasValues =
    !isEmpty(familyTaxBenefitForm.part) &&
    (!isEmpty(familyTaxBenefitForm.file) || !isEmpty(familyTaxBenefitForm.linkToFile));
  return hasValues
    ? (familyTaxBenefitForm.account &&
        familyTaxBenefitForm.part &&
        (familyTaxBenefitForm.file || familyTaxBenefitForm.linkToFile)) ||
        familyTaxBenefitForm.part === 'None'
    : true;
};

const validateFamilyTaxBenefitEditForm = (formData: IEditProgramChildForm, familyTaxBenefitId?: string) => {
  if (familyTaxBenefitId) {
    if (!formData.familyTaxBenefit.part) {
      return false;
    }
  }
  return true;
};

const validateKindyForAllChildForm = (kindyForAllChild: IKindyForAllChildForm) => {
  if (kindyForAllChild.formalProtectionCare) {
    if (kindyForAllChild.formalProtectionCareStartDate === undefined) {
      return false;
    }
  }
  if (kindyForAllChild.kinship) {
    if (kindyForAllChild.formalKinshipStartDate === undefined) {
      return false;
    }
  }
  if (kindyForAllChild.multiBirth) {
    if (kindyForAllChild.multiBirthStartDate === undefined) {
      return false;
    }
  }
  if (kindyForAllChild.hasCancellation) {
    if (kindyForAllChild.cancellationDate === undefined || kindyForAllChild.cancellationReason === 'None') {
      return false;
    }
  }
  return true;
};

const validateStartStrongChildForm = (startStrongChild: IStartStrongChildForm) => {
  if (startStrongChild.hasSchoolExemptionCertificate && startStrongChild.schoolExemption === undefined) return false;

  return true;
};
