import moment from 'moment';
import React, { useMemo, useState } from 'react';
import * as Mui from '@mui/material';
import { useSelector } from 'react-redux';
import { stringToHsl } from 'shared/util/string';
import { RootState } from 'store/reducers';
import AvatarContent from '../../Avatar/AvatarContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMessageAttachment } from 'shared/types/channel';
import { faFile } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  messageText: string;
  avatarUrl: string;
  senderName: string;
  createdAt: string;
  isOwn?: boolean;
  attachments?: IMessageAttachment[];
  onDownloadAttachment: (attacment: IMessageAttachment) => void;
}

const MessageBubble: React.FC<IProps> = ({
  messageText,
  avatarUrl,
  senderName,
  createdAt,
  isOwn,
  attachments,
  onDownloadAttachment,
}) => {
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();
  const URL_REGEX = /^(.*?)((?:https?:\/\/|www\.)[^\s/$.?#].[^\s]*)/s;
  const HTTP_REGEX = /^[\r\n]*http[s]*:\/\/[\w]+/i;

  const getInitials = (name: string | null | undefined) => {
    if (name === null || name === undefined) return '';
    const initials = name
      .split(' ')
      .map((word) => (/^[A-Za-z0-9 -]*$/.test(word[0]) ? word[0] : null))
      .join('')
      .toUpperCase();

    if (initials.length <= 2) return initials;
    else return `${initials[0]}${initials[initials.length - 1]}`;
  };
  const renderText = (txt) =>
    txt.split(' ').map((part) =>
      URL_REGEX.test(part) ? (
        <a key={part} href={HTTP_REGEX.test(part) ? part : 'https://' + part} target="blank">
          {part}{' '}
        </a>
      ) : (
        part + ' '
      )
    );

  return (
    <div>
      <div className="pn-msg-bubble">
        <p>{renderText(messageText)}</p>
        <p>
          {attachments
            ? attachments.map((a) => (
                <span className="pn-msg-attachments">
                  <Mui.Link
                    variant="body1"
                    href="#"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDownloadAttachment(a);
                    }}
                    sx={{ textDecoration: 'none', marginLeft: '14px' }}
                  >
                    <FontAwesomeIcon icon={faFile} style={{ marginRight: '4px' }} />
                    {a.fileName}
                  </Mui.Link>
                </span>
              ))
            : null}
        </p>
      </div>
      <div className="pn-msg-info">
        <div className="avatar d-inline-block float-none mr-2 ml-2">
          <AvatarContent
            size="xs"
            image={avatarUrl}
            initials={getInitials(senderName)}
            alt={`${senderName} avatar`}
            color={stringToHsl(senderName ?? '1')}
          />
        </div>
        <div className={`pn-msg-info__details ${isOwn ? 'isOwn' : ''}`}>
          <p>{senderName}</p>
          <p>{moment(createdAt).tz(timezone).format('MMM D[,] YYYY [@] hh:mmA').toString()}</p>
        </div>
      </div>
    </div>
  );
};

export default MessageBubble;
