import React from 'react';
import DataTable from 'shared/components/DataTable';
import { PersonAvatar } from 'shared/components/Avatar';
import cast from 'shared/util/cast';
import AttendanceStatusIcon from '../AttendanceStatusIcon';
import AttendanceTableFinanceActionDropdown from './AttendanceTableFinanceActionDropdown';
import { isRegion } from 'shared/util/region';
import Spinner from 'shared/components/Spinner';
import { getFullName } from 'shared/util/string';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RateInfo } from './FinanceTable/RateInfo';
import { getRateUnitsLabelFromSession } from './FinanceTable/helpers';

interface IProps {
  attendanceSessions: ISession[];
  loading: boolean;
  timezone: string;
  selectedSessions: ISession[];
  onSort: (field: string, direction: 'asc' | 'desc') => void;
  canEditAttendanceFinance: boolean;
  onSingleSessionRemoveFee: (type: 'early' | 'late', session: ISession) => void;
  onSingleSessionRestoreFee: (type: 'early' | 'late', session: ISession) => void;
  onRowSelect: (sessions: ISession[]) => void;
  onCharge: (sessions: ISession[]) => void;
  childAndClassIdsInLoadingState: ISessionCharge[];
}

const DailyAttendanceFinanceTable: React.FC<IProps> = ({
  attendanceSessions,
  loading,
  timezone,
  selectedSessions,
  onSort,
  canEditAttendanceFinance,
  onSingleSessionRemoveFee,
  onSingleSessionRestoreFee,
  onRowSelect,
  onCharge,
  childAndClassIdsInLoadingState,
  ...props
}) => {
  const { k2AttendanceAccountName } = useFlags();

  return (
    <DataTable
      className="attendance-table"
      data={attendanceSessions.map((s) => {
        if (isRegion('US')) {
          let amount;
          if (s.fee.feeType === 'FLAT_RATE') {
            amount = (s.qualifyingRate?.value ?? 0) - (s.cost?.discount ?? 0);
          } else {
            amount = s.cost?.gap ?? 0;
          }

          return {
            ...s,
            amount,
          };
        }
        return s;
      })}
      dataSize={attendanceSessions.length}
      showSelect={canEditAttendanceFinance}
      selectedRows={selectedSessions}
      updateSelectedRows={onRowSelect}
      showLoadingOverlay={loading}
      showPagination={false}
      noPadding
      onSort={(field, direction) => onSort(field, direction === 'ASCENDING' ? 'asc' : 'desc')}
      noDataText="No Attendance Found."
      columns={[
        ...[
          {
            text: 'Child',
            dataField: 'child.lastname',
            sort: true,
            formatter: (cell: any, row: ISession) => (
              <div className="d-flex align-items-center">
                <PersonAvatar person={cast<IPerson>(row.child)} className="mr-2" />
                <div>
                  <div>{getFullName(row.child)}</div>
                  {k2AttendanceAccountName && (
                    <Link className="session-account-name" to={`/families/accounts/${row.accountId}/profile`}>
                      {row?.accountName}
                    </Link>
                  )}
                  {isRegion('AU') && <small>Absences: {row.existingEnrollmentYearAbsences?.length ?? 0}</small>}
                </div>
              </div>
            ),
          },
          {
            text: 'Status',
            dataField: 'timeEntries',
            align: 'center',
            formatter: (timeEntries: IAttendanceTimeEntry[], row: ISession) => (
              <AttendanceStatusIcon session={row} timezone={timezone} />
            ),
            classes: 'xs-column',
          },
          {
            text: 'Class',
            headerClasses: 'text-left',
            dataField: 'class.name',
            sort: true,
            formatter: (_classname: string, row: ISession) => (
              <div>
                <div>{row.class.name}</div>
                <small>{row.fee.name}</small>
              </div>
            ),
          },
          {
            text: 'Session',
            dataField: 'totalTime',
            align: 'center',
            formatter: (totalTime: ITotalTime) => `${totalTime.hours}h ${totalTime.minutes}m`,
          },
          ...(isRegion('US')
            ? [
                {
                  text: 'Rate',
                  dataField: 'cost',
                  align: 'center',
                  formatter: (cost: any, row: ISession) => {
                    return (
                      <RateInfo
                        rateValue={row.qualifyingRate?.value ?? row.cost?.sessionCost ?? 0}
                        rateUnitLabel={getRateUnitsLabelFromSession(row)}
                      ></RateInfo>
                    );
                  },
                },
              ]
            : [
                {
                  text: 'Session Cost',
                  dataField: 'cost.sessionCost',
                  align: 'center',
                  formatter: (cell: number | undefined, row: ISession) => `$${(cell ?? 0).toFixed(2)}`,
                },
              ]),
          {
            text: 'Discount',
            dataField: 'cost.discount',
            align: 'center',
            formatter: (cell: number | undefined, row: ISession) => `$${(cell ?? 0).toFixed(2)}`,
          },
          {
            text: 'Subsidy Estimate',
            dataField: 'subsidy.amount',
            align: 'center',
            formatter: (cell: number | undefined, row: ISession) => (
              <span title={row.subsidy?.reason ?? ''}>{`$${(cell ?? 0).toFixed(2)}`}</span>
            ),
          },
          {
            text: 'Early Fee',
            dataField: 'cost.earlyFee',
            align: 'center',
            formatter: (cell: number | undefined, row: ISession) => `$${(cell ?? 0).toFixed(2)}`,
          },
          {
            text: 'Late Fee',
            dataField: 'cost.lateFee',
            align: 'center',
            formatter: (cell: number | undefined, row: ISession) => `$${(cell ?? 0).toFixed(2)}`,
          },
          {
            text: isRegion('AU') ? 'Parent Gap' : 'Amount',
            dataField: isRegion('US') ? 'amount' : 'cost.gap',
            align: 'center',
            formatter: (cell: number | undefined, row: ISession) => `$${(cell ?? 0).toFixed(2)}`,
          },
          {
            text: 'Charged?',
            dataField: 'charged',
            align: 'center',
            formatter: (charged: any, session: ISession) =>
              childAndClassIdsInLoadingState.find(
                (s) =>
                  s.childId === session.childId &&
                  s.sessionId === session.id &&
                  s.date === session.date &&
                  s.contractId === session.contractId &&
                  s.classId === session.classId
              ) ? (
                <Spinner className="text-gray mr-2" />
              ) : charged ? (
                'Y'
              ) : (
                'N'
              ),
          },
        ],
        ...(canEditAttendanceFinance
          ? [
              {
                text: 'Actions',
                dataField: 'actions',
                isDummyField: true,
                align: 'center',
                formatter: (cell: any, session: ISession) => (
                  <AttendanceTableFinanceActionDropdown
                    sessionCostData={session.cost}
                    isDisabledAllActions={!session.metadata.isWritable}
                    hasEarlyFee={session.hasEarlyFee}
                    hasLateFee={session.hasLateFee}
                    onRemoveFee={(type) => onSingleSessionRemoveFee(type, session)}
                    onRestoreFee={(type) => onSingleSessionRestoreFee(type, session)}
                    hasBeenCharged={session.charged}
                    sessionFromFlatRateFee={session.fee.feeType == 'FLAT_RATE'}
                    onCharge={() => {
                      onCharge([session]);
                    }}
                    isWeeklyView={false}
                  />
                ),
              },
            ]
          : []),
      ]}
    />
  );
};

export default DailyAttendanceFinanceTable;
