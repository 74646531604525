import React from 'react';
import CCSSTypeIcon from '../CCSSIconType';
interface ITdProps {
  ccsMessage: ICcsMessage;
  column: 'type' | 'source' | 'subject' | 'centerName' | 'created';
  text?: string;
  className?: string;
}

const CCSMessagesTd: React.FC<ITdProps> = ({ column, className = '', ccsMessage, text }) => {
  const { viewedStatus, type, sourceId } = ccsMessage;
  const fontWeight = viewedStatus === 'UNREAD' ? 'semi-bold' : 'normal';
  let message = text ?? ccsMessage[column];
  if (column === 'subject' && Boolean(sourceId)) message = `${sourceId} - ${message}`;
  return (
    <div className={`font-weight-${fontWeight} ${className}`}>
      {column === 'type' && <CCSSTypeIcon type={type} />}
      {message}
    </div>
  );
};

export default CCSMessagesTd;
