import { useCreateBusRosters, useUpdateBusRoster } from 'gql/busRoster/mutations';
import { useCallback, useMemo, useState } from 'react';
import { isBlank } from 'shared/util/string';
import React from 'react';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import FormWrapper2 from '../Form/FormWrapper2';
import BusRosterDetailInputs from './BusRosterDetailInputs';

interface IProps {
  busRoster: any | undefined;
  assignedChildren: IBusRosterAccountChildGridShape[] | [];
  destinationOptions: IDestinationStateShape[];
  loading: boolean;
  onChange: React.Dispatch<React.SetStateAction<IBusRosterInformationForm>>;
  onAddDestination: () => void;
  onRemoveDestination: (destinationId: string) => void;
}

const BusRosterInformationForm: React.FC<IProps> = ({
  busRoster,
  assignedChildren,
  destinationOptions,
  loading,
  onChange,
  onAddDestination,
  onRemoveDestination,
}) => {
  const [formIsDirty, setFormIsDirty] = useState(false);
  const resetForm = useCallback(() => onChange(busRoster), []);

  return (
    <>
      {loading ? (
        <LoadingLines />
      ) : (
        <FormWrapper2
          formId="bus-roster-details-form"
          formIsDirty={formIsDirty}
          toggleDirty={setFormIsDirty}
          onCancel={resetForm}
          hideButtons={true}
        >
          <BusRosterDetailInputs
            formData={busRoster}
            assignedChildren={assignedChildren}
            destinationOptions={destinationOptions}
            onChange={onChange}
            onAddDestination={onAddDestination}
            onRemoveDestination={onRemoveDestination}
          ></BusRosterDetailInputs>
        </FormWrapper2>
      )}
    </>
  );
};

export default BusRosterInformationForm;
