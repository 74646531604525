import Card from 'shared/components/Card';
import * as Mui from '@mui/material';
import { useRef, useState } from 'react';
import { useGetHubSpotCustomerIdQuery } from 'generated/graphql';
import { showToast } from 'shared/components/Toast';
import { useUpdateBusinessHubSpotCustomerId } from '../../graphql/mutations';

interface IProps {
  businessId: string;
}

const customerIdName = 'HubSpotCustomerId';

const HubSpotCard = ({ businessId }: IProps) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const { data, loading, refetch } = useGetHubSpotCustomerIdQuery({
    variables: { businessId: businessId },
    notifyOnNetworkStatusChange: true,
  });
  const [updateHubSpotCustomerId, { loading: updateLoading }] = useUpdateBusinessHubSpotCustomerId();

  const handleCancel = () => {
    formRef.current?.reset();
    setDirty(false);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const customerId = new FormData(e.currentTarget).get(customerIdName);
    if (typeof customerId !== 'string') return;
    updateHubSpotCustomerId({ variables: { input: { id: businessId, hubSpotCustomerId: customerId } } })
      .then(() => {
        showToast('Hubspot details updated successfully.', 'success');
        setDirty(false);
        refetch();
      })
      .catch((e: Error) => showToast(e.message, 'error'));
  };

  return (
    <Card
      header={
        <Mui.Stack direction="row" spacing={1} alignItems="center">
          <Mui.Typography>HubSpot</Mui.Typography>
        </Mui.Stack>
      }
      style={{ maxWidth: '275px' }}
      loading={loading}
      loadingLines={1}
    >
      <form onChange={() => setDirty(true)} ref={formRef} onSubmit={handleSubmit}>
        <Mui.Stack spacing={2}>
          <Mui.TextField
            label="HubSpot CustomerId"
            name={customerIdName}
            variant="outlined"
            defaultValue={data?.getEntity?.hubSpotCustomerId}
          />
          {dirty && (
            <Mui.Stack spacing={2} direction={'row'}>
              <Mui.Button variant="cancel" size="small" onClick={handleCancel}>
                Cancel
              </Mui.Button>
              <Mui.Button type="submit" variant="contained" color="secondary" disabled={updateLoading}>
                {updateLoading && <Mui.CircularProgress size={20} sx={{ marginRight: 1 }} />}
                Submit
              </Mui.Button>
            </Mui.Stack>
          )}
        </Mui.Stack>
      </form>
    </Card>
  );
};

export default HubSpotCard;
