import React, { useState, useCallback } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import { isBlank } from 'shared/util/string';
import errorMessage from 'shared/constants/errorMessages';
import { useCreateProvider } from 'pages/Businesses/subroutes/BusinessProfile/graphql/mutations';
import { useDispatch } from 'react-redux';
import { showToast } from 'shared/components/Toast';
import * as actions from 'pages/Businesses/subroutes/BusinessProfile/duck/actions';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  businessId: string;
}

const newProvider: ICreateProviderInput = {
  businessId: '',
  name: '',
  providerId: '',
  organisationId: '',
  communicationsPersonId: '',
  deviceName: '',
  otac: '',
};

const CreateProviderModal: React.FC<IProps> = ({ isOpen, onClose, businessId }) => {
  const dispatch = useDispatch();
  const [provider, setProvider] = useState<ICreateProviderInput>({ ...newProvider, businessId });

  const [createProvider, { loading: loading }] = useCreateProvider({
    onCompleted: (data) => dispatch(actions.createProvider(data.createProvider)),
  });

  const handleUpdateProvider = useCallback((value, name) => setProvider({ ...provider, [name]: value }), [provider]);

  const handleClose = useCallback(() => {
    onClose();
    setProvider({ ...newProvider, businessId });
  }, [businessId, onClose]);

  const handleSave = useCallback(() => {
    createProvider({
      variables: {
        input: provider,
      },
    })
      .then((provider) => {
        if (provider.data?.createProvider) {
          showToast('Provider created successfully.', 'success');
          handleClose();
        }
      })
      .catch((err) => {
        showToast('There was an unknown error.', 'error');
      });
  }, [createProvider, provider, handleClose]);

  const formDisabled: boolean =
    isBlank(provider.name) ||
    isBlank(provider.providerId) ||
    isBlank(provider.organisationId) ||
    isBlank(provider.deviceName) ||
    isBlank(provider.otac);

  return (
    <SideModalDrawer
      title="Create Provider"
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Update"
      primaryCallback={handleSave}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      closeOnPrimaryCallback={false}
      primaryButtonProps={{ disabled: formDisabled, loading: loading }}
      footerHelperText={formDisabled ? errorMessage.formRequirements : ''}
    >
      <form>
        <Row>
          <Col>
            <TextInput
              required
              name="name"
              label="Provider Name"
              onChange={handleUpdateProvider}
              value={provider.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="providerId"
              label="Provider ID"
              onChange={handleUpdateProvider}
              value={provider.providerId}
            ></TextInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="organisationId"
              label="Organisation ID"
              onChange={handleUpdateProvider}
              value={provider.organisationId}
            ></TextInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="communicationsPersonId"
              label="Communications Person ID"
              onChange={handleUpdateProvider}
              value={provider.communicationsPersonId}
            ></TextInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="deviceName"
              label="Device Name"
              onChange={handleUpdateProvider}
              value={provider.deviceName}
              helperText="This field is case sensitive and should be exactly the same as the one used on the PRODA website"
            ></TextInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="otac"
              label="One-time Security Code"
              onChange={handleUpdateProvider}
              value={provider.otac}
              helperText="This is also referred to as OTAC and you get this from the PRODA website"
            ></TextInput>
          </Col>
        </Row>
      </form>
    </SideModalDrawer>
  );
};

export default CreateProviderModal;
