import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Card from 'shared/components/Card';
import PersonalInformationForm from './PersonalInformationForm';
import ChangePasswordCard from './ChangePasswordCard';
// import ChangePinCard from './ChangePinCard';
import ChangePasswordModal from './ChangePasswordModal';
// import ChangePinModal from './ChangePinModal';
import EmergencyContactsForm from './EmergencyContactsForm';
import cast from 'shared/util/cast';
import { ChangeEmailCard, ChangeEmailModal } from 'shared/components/ChangeEmail';
import useGetMe from 'shared/hooks/useGetMe';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import action from 'shared/constants/enums/actionEnum';
import ChangePinCard from 'shared/components/ChangePin/ChangePinCard';
import ChangePinModal from 'shared/components/ChangePin/ChangePinModal';
import { showToast } from 'shared/components/Toast';
import { useUpdateMyPin } from 'store/user/graphql/mutations';

interface IProps {
  data: IStaff | IPerson | null;
  loading: boolean;
}

function isStaff(obj: any): obj is IStaff {
  return 'emailIsManaged' in obj;
}

const ProfileTab: React.FC<IProps> = ({ data, loading, ...props }) => {
  const { data: getMeData, refetch } = useGetMe();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showChangePinModal, setShowChangePinModal] = useState(false);
  const [updateMyPinFn, { loading: updateMyPinLoading }] = useUpdateMyPin();
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;

  const isDeactivated = data?.employmentStatus === 'Deactivated';
  const changeEmailComponent = useMemo(() => {
    if (getMeData && getMeData.getMe && isStaff(getMeData.getMe) && getMeData.getMe?.emailIsManaged) {
      return <></>;
    } else {
      return (
        <>
          <ChangeEmailCard
            disabled={isDeactivated}
            isMyEmail={true}
            showChangeEmailModal={() => setShowChangeEmailModal(true)}
            AdpEnabled={AdpEnabled}
          />
          <ChangePasswordCard
            disabled={isDeactivated || (!cast<IStaff>(getMeData?.getMe ?? {}).isEmailVerified ?? false)}
            showChangePasswordModal={() => setShowChangePasswordModal(true)}
          />
        </>
      );
    }
  }, [getMeData, setShowChangePasswordModal]);

  const user = useSelector((state: RootState) => state.user);
  const hasOnboardingPermission = user?.hasPermission(action.STAFF_ONBOARDING) ?? false;

  const updateMyPin = useCallback(
    (pin: string) => {
      updateMyPinFn({
        variables: {
          pin,
        },
      })
        .then(() => {
          showToast('Pin changed successfully.', 'success');
          setShowChangePinModal(false);
        })
        .catch(() => {
          showToast('There was an error changing your pin. Please try again later.', 'error');
        });
    },
    [updateMyPinFn]
  );

  return (
    <>
      <Row>
        <Column lg={8}>
          <Card header="Personal Information" loading={loading}>
            <PersonalInformationForm data={data} readOnly={!hasOnboardingPermission} />
          </Card>
          <Card header="Emergency Contacts" loading={loading}>
            <EmergencyContactsForm
              primaryEmergencyContact={cast<IStaff>(data)?.primaryEmergencyContact ?? null}
              additionalEmergencyContacts={cast<IStaff>(data)?.additionalEmergencyContacts ?? []}
              readOnly={!hasOnboardingPermission || AdpEnabled}
            />
          </Card>
        </Column>
        <Column lg={4}>
          {changeEmailComponent}
          <ChangePinCard
            disabled={isDeactivated}
            text="Need to change your PIN?"
            showChangePinModal={() => setShowChangePinModal(true)}
          />
        </Column>
      </Row>
      {data && (
        <ChangeEmailModal
          refetchStaff={refetch}
          currentEmail={cast<IStaff>(getMeData?.getMe ?? {}).email ?? ''}
          isOpen={showChangeEmailModal}
          onClose={() => setShowChangeEmailModal(false)}
          personId={data.id}
          isCognitoUserCreated={Boolean(data.cognitoConfiguredAt)}
        />
      )}
      <ChangePasswordModal isOpen={showChangePasswordModal} onClose={() => setShowChangePasswordModal(false)} />
      <ChangePinModal
        isOpen={showChangePinModal}
        isLoading={updateMyPinLoading}
        onSubmit={updateMyPin}
        onClose={() => setShowChangePinModal(false)}
      />
    </>
  );
};

export default ProfileTab;
