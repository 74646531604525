import React from 'react';
import Card from 'shared/components/Card';
import Button from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import Alert from 'shared/components/Alert';
import 'pages/MyProfile/myProfile.scss';

interface IProps {
  disabled?: boolean;
  isMyEmail?: boolean;
  isUserDeactivated?: boolean;
  showChangeEmailModal: () => void;
  AdpEnabled?: boolean;
}

const ChangeEmailCard: React.FC<IProps> = ({
  showChangeEmailModal,
  isMyEmail = false,
  disabled = false,
  isUserDeactivated = false,
  AdpEnabled = false,
  ...props
}) => {
  return (
    <Card className="kt-my-profile-change-password-card">
      <div className="d-flex flex-column align-items-center">
        <div className="kt-my-profile-change-email-circle rounded-circle circle-lg d-flex justify-content-center align-items-center mb-4">
          <FontAwesomeIcon size="2x" icon={faEnvelope} color={colors.info} />
        </div>
        <span className="mb-4">{`Need to change ${isMyEmail ? `your` : `this user's`} email?`}</span>
        <Button
          variant="outline-secondary"
          onClick={showChangeEmailModal}
          disabled={disabled || isUserDeactivated || AdpEnabled}
        >
          Change Email
        </Button>
        {disabled && (
          <Alert className="mt-4" variant="info">
            Your email has not been verified. A verified email is required before it can be changed.
          </Alert>
        )}
        {AdpEnabled && (
          <Alert className="mt-4" variant="info">
            Users email addresses can be updated in ADP
          </Alert>
        )}
      </div>
    </Card>
  );
};

export default ChangeEmailCard;
