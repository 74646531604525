import DataTable from './DataTable';
import TableSearch from './TableSearch';
import SizePerPage from './SizePerPage';
import TableHeader from './TableHeader';
import BulkActions from './BulkActions';

const TABLE_DEFAULTS = {
  PAGE: 1,
  ITEM_OFFSET: 0,
  PAGE_SIZE: 25,
};

export { TableSearch, SizePerPage, TableHeader, BulkActions, TABLE_DEFAULTS };

export default DataTable;
