import JsSha from 'jssha';
import config from 'config';

/**
 * Test if an email includes 1 @ and that the @ is not the first character in the string.
 * @param {String} email
 * @returns {Boolean}
 */
export const isEmailSyntaxValid = (email: string | null): boolean =>
  !!email && !/\s/.test(email) && /\S+@/.test(email) && email.split('@').length === 2;

/**
 * Test if string has valid email syntax including a valid domain.
 * Valid domain:
 * -- Allowed characters: letters, numbers, dashes.
 * -- Does not allow dashes at the start or end of the host name.
 * -- The last portion of the domain must be at least two characters, for example: .com, .org, .cc
 * -- Source: https://stackoverflow.com/questions/10306690/what-is-a-regular-expression-which-will-match-a-valid-domain-name-without-a-subd/26987741#26987741
 * @param {String} email
 * @returns {Boolean}
 */
const validDomainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
export const isEmailValid = (email: string | null): boolean =>
  !!email && isEmailSyntaxValid(email) && validDomainRegex.test(email.split('@')[1]);
