import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Row } from 'shared/components/Layout';
import Avatar from 'shared/components/Avatar';
import { getFullName } from 'shared/util/string';

interface IProps {
  staff: IStaff[];
}

const AssignmentsTableCell: React.FC<IProps> = ({ staff }) => {
  const showMore = staff && staff.length > 2;
  const firstTwoStaff = staff.slice(0, 2);
  return (
    <Row noGutters className="flex-nowrap">
      {firstTwoStaff.map((staff, i) => (
        <div key={i} className="d-flex align-items-center">
          <Avatar
            size="sm"
            image={staff.avatar && staff.avatar.url}
            initials={staff.firstname[0] + staff.lastname[0]}
          />
          <div className="d-flex flex-column pl-1 mr-2 text-truncate">
            <span className="text-truncate">{getFullName(staff)}</span>
          </div>
        </div>
      ))}
      {showMore && (
        <OverlayTrigger
          trigger="hover" // click trigger isn't working when entire row click triggers event
          placement="bottom"
          overlay={
            <Popover id="tags">
              <Popover.Title as="h5">Tags</Popover.Title>
              <Popover.Content>
                {staff.map((staff, i) => (
                  <div key={i} className="d-flex align-items-center">
                    <Avatar
                      size="sm"
                      image={staff.avatar && staff.avatar.url}
                      initials={staff.firstname[0] + staff.lastname[0]}
                    />
                    <div className="d-flex flex-column pl-1 mr-2 text-truncate">
                      <span className="text-truncate">{getFullName(staff)}</span>
                    </div>
                  </div>
                ))}
              </Popover.Content>
            </Popover>
          }
        >
          <button type="button" className="tag">
            ...
          </button>
        </OverlayTrigger>
      )}
    </Row>
  );
};

export default AssignmentsTableCell;
