import React from 'react';
import PropTypes from 'prop-types';
// logos
import normal from './logo-normal.png';
import white from './logo-white.png';
import icon from './logo-icon.png';
import iconWhite from './logo-icon-white.png';
import textWhite from './logo-text-white.png';

const Logo = ({ type, size }) => {
  const getImage = (type) => {
    switch (type) {
      case 'normal':
        return normal;
      case 'white':
        return white;
      case 'icon':
        return icon;
      case 'icon-white':
        return iconWhite;
      case 'text-white':
        return textWhite;
      default:
        return normal;
    }
  };

  const getSize = (size) => {
    switch (size) {
      case 'icon':
        return { width: 24, height: 24 };
      case 'x-small':
        return { width: 34, height: 34 };
      case 'small':
        return { width: 48, height: 48 };
      case 'medium':
        return { width: 64, height: 64 };
      case 'large':
        return { width: 96, height: 96 };
      case 'full':
        return { width: '100%' };
      default:
        return { width: '100%' };
    }
  };

  return <img alt="Kangarootime" src={getImage(type)} style={getSize(size)} />;
};

Logo.propTypes = {
  type: PropTypes.oneOf(['normal', 'white', 'icon', 'icon-white', 'text-white']),
  size: PropTypes.oneOf(['icon', 'x-small', 'small', 'medium', 'large', 'full']),
};

Logo.defaultProps = {
  type: 'normal',
  size: 'full',
};

export default Logo;
