import React, { useState } from 'react';
import moment from 'moment';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';

import DataTable from 'shared/components/DataTable';

import ISMessagesTd from './ISMessagesTd';
import ISCaseMessagesTableHeader from './ISMessagesTableHeader';

import { IconButtonCircle } from 'shared/components/Buttons';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import ActionDropdown from 'shared/components/ActionDropdown';
import { MsgStatusCodes } from 'shared/constants/enums/ccssMessageEnums';

import './isMessages.scss';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface ITableProps {
  activeFilters: IISMessagesFilter;
  activePage: number;
  businessId: string;
  centers: ICenter[];
  data: IISMessage[];
  dataSize: number;
  loading: boolean;
  pageSize: number;
  selectedRows: any;
  updateSelectedRows: any;
  expandRow?: (row: any) => JSX.Element;
  handleUpdateMsg: (message: IISMessage) => void;
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  onActiveFilterChange: (value: IISMessagesFilter) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const ISMessagesTable: React.FC<ITableProps> = ({
  activeFilters,
  activePage,
  centers,
  data,
  loading,
  dataSize,
  pageSize,
  handleUpdateMsg,
  onPageChange,
  onSizePerPageChange,
  onActiveFilterChange,
  onSort,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const { ARCHIVED, UNREAD, READ } = MsgStatusCodes;
  const handleCenterChange = (values: ITableFilterOption[]) => {
    onActiveFilterChange({ centers: values });
  };

  const handleStatusChange = (values: ITableFilterOption[]) => {
    onActiveFilterChange({ statuses: values });
  };

  const handleSearchChange = (str: string) => {
    onActiveFilterChange({ searchText: str });
  };

  const getDateFormat = (date: string) => {
    const today = new Date();
    if (moment(date).isSame(today, 'day')) {
      return 'hh:mm A';
    } else if (moment(date).isSame(today, 'year')) {
      return 'D MMM';
    }
    return 'DD/MM/YYYY';
  };

  const clearFilters = () => {
    onActiveFilterChange({ searchText: '', centers: [], statuses: [] });
  };

  const markAsRead = (row: any, isExpand: boolean) => {
    const expanded = isExpand ? [row.id] : [];
    setExpandedRows(expanded);
    if (row.viewedStatus === UNREAD) {
      handleUpdateMsg({
        ...row,
        viewedStatus: READ,
      });
    }
  };

  const archiveMessage = (iSMessage: IISMessage) => {
    handleUpdateMsg({
      ...iSMessage,
      viewedStatus: iSMessage.viewedStatus === ARCHIVED ? READ : ARCHIVED,
    });
  };

  return (
    <>
      <ISCaseMessagesTableHeader
        handleCenterChange={handleCenterChange}
        handleSearchChange={handleSearchChange}
        handleStatusChange={handleStatusChange}
        activeFilters={activeFilters}
        onActiveFilterChange={onActiveFilterChange}
        clearFilters={clearFilters}
        centers={centers}
      />
      <SpinnerTableOverlay show={loading}>
        <DataTable
          keyField="id"
          noPadding={true}
          data={data}
          dataSize={dataSize}
          handleRowClick={() => {}}
          hasOverlay={false}
          pageSize={pageSize}
          activePage={activePage}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          showPagination={true}
          showSelect={false}
          className="is-message-table"
          expandHeaderColumnRenderer={(props) => <></>}
          expandRow={(row: IISMessage) => (
            <div className="pl-4 pr-4">
              <label className="mr-4 font-weight-bold">Effective Date: </label>
              {moment(row.effectiveDate).format('DD/MM/YYYY')}
              <br />
              <label className="font-weight-bold">Comments:</label>
              <br />
              {row.comments}
            </div>
          )}
          onExpand={markAsRead}
          expanded={expandedRows}
          onSort={onSort}
          defaultSortField="created"
          defaultSorting="desc"
          onlyOneExpanding={true}
          columns={[
            {
              text: fieldLabels.center,
              dataField: 'centerName',
              sort: true,
              formatter: (cell: any, row: IISMessage) => <ISMessagesTd iSMessage={row} column="centerName" />,
            },
            {
              text: 'ID',
              dataField: 'messageId',
              sort: true,
              formatter: (cell: any, row: IISMessage) => <ISMessagesTd iSMessage={row} column="messageId" />,
            },
            {
              text: 'Source',
              dataField: 'source',
              sort: true,
              formatter: (cell: any, row: IISMessage) => <ISMessagesTd iSMessage={row} column="source" />,
            },
            {
              text: 'Subject',
              dataField: 'messageType',
              formatter: (cell: any, row: IISMessage) => <ISMessagesTd iSMessage={row} column="messageType" />,
            },
            {
              text: 'Date',
              dataField: 'created',
              headerClasses: 'text-right',
              sort: true,
              formatter: (cell: any, row: IISMessage) => (
                <ISMessagesTd
                  className="pr-4 text-right"
                  iSMessage={row}
                  column="created"
                  text={moment(row.created).format(getDateFormat(row.created))}
                />
              ),
            },
            {
              align: 'center',
              text: 'More',
              dataField: 'id',
              headerClasses: 'text-center',
              formatter: (cell: any, row: IISMessage) => (
                <ActionDropdown
                  actions={[
                    {
                      label: row.viewedStatus === ARCHIVED ? 'Unarchive Message' : 'Archive Message',
                      onClick: () => {
                        archiveMessage(row);
                      },
                    },
                  ]}
                  customIconButton={<IconButtonCircle icon={faEllipsisV} />}
                />
              ),
            },
          ]}
        />
      </SpinnerTableOverlay>
    </>
  );
};

export default ISMessagesTable;
