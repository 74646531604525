import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faPercent } from '@fortawesome/pro-light-svg-icons';
import React from 'react';

export const FlatOrPercentageAmountTypeSwitch = (props: {
  onDollarClicked: () => void;
  amountType: 'FLAT' | 'PERCENTAGE';
  onPercentageClicked: () => void;
}) => {
  return (
    <div
      className={'px-1'}
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #bcc6d1',
        borderRight: '1px solid #bcc6d1',
        borderBottom: '1px solid #bcc6d1',
        borderTopRightRadius: '0.25rem',
        borderBottomRightRadius: '0.25rem',
        borderLeft: 'none',
        backgroundColor: '#fff',
      }}
    >
      <Button
        onClick={props.onDollarClicked}
        variant={props.amountType === 'FLAT' ? 'secondary' : 'outline-secondary'}
        style={{
          backgroundColor: 'white',
          paddingLeft: '0.1rem',
          paddingRight: '0.1rem',
          borderStyle: 'none',
        }}
        className="px-0 h-100"
      >
        <div
          style={{
            borderRadius: '0.25rem',
            backgroundColor: props.amountType === 'FLAT' ? '#513c5e' : 'white',
            color: props.amountType === 'FLAT' ? 'white' : '#513c5e',
            height: '100%',
            width: '100%',
            paddingLeft: '0.75rem',
            paddingRight: '0.75rem',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon icon={faDollarSign} />
        </div>
      </Button>
      <Button
        onClick={props.onPercentageClicked}
        variant={props.amountType === 'PERCENTAGE' ? 'secondary' : 'outline-secondary'}
        style={{
          backgroundColor: 'white',
          paddingLeft: '0.1rem',
          paddingRight: '0.1rem',
          borderStyle: 'none',
        }}
        className="px-0 h-100"
      >
        <div
          style={{
            borderRadius: '0.25rem',
            backgroundColor: props.amountType === 'PERCENTAGE' ? '#513c5e' : 'white',
            color: props.amountType === 'PERCENTAGE' ? 'white' : '#513c5e',
            height: '100%',
            width: '100%',
            paddingLeft: '0.75rem',
            paddingRight: '0.75rem',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon icon={faPercent} />
        </div>
      </Button>
    </div>
  );
};
