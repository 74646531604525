import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { ldFlags as ItemizedBillFlags } from 'ItemizedBIlls';
import { ldFlags as USModeFlags } from '../../US/Billing/USMode';
import { ldFlags as AccountFlags } from 'pages/Families/subroutes/Accounts/Account/flags';

/**
 * Object of specific LaunchDarkly flags to listen to changes for
 * Key is your launchdarkly flag (as shown in the dashboard, not the camelcase name), value is the default value for your flag
 *
 * When your flag is no longer in use, you are responsible for removing it from this object
 */
const launchdarklyFlags: LDFlagSet = {
  'k2-analytics': false,
  'k2-analytics-accounts-receivable-dashboard-permission': false,
  'k2-analytics-revenue-dashboard': false,
  'k2-analytics-revenue-dashboard-permission': false,
  'k2-analytics-accounts-receivable-dashboard': false,
  'k2-kisi-integration': false,
  'k2-time-log-lock': false,
  'k2-web-pto-types': false,
  'k2-royalty-fee-configuration': false,
  'k2-web-weekend-scheduling': false,
  'k2-web-api': false,
  'k2-us-billing-cycles': false,
  'k2-attendance-control': false,
  'k2-discounts': false,
  'k2-get-invoice-date': false,
  'k2-delete-contract': false,
  'k2-ccss-event-management': false,
  'k2-agency-payment-management': false,
  'k2-attendance-sorting': false,
  'k2-attendance-account-name': false,
  'k2-bus-roster': false,
  'k2-occupancy-report-weekly': false,
  'k2-us-care-types': false,
  'k2-ccs-reports': false,
  'k2-edit-agency-enrollment': false,
  'k2-discounts-backdating': false,
  'k2-training-holiday': false,
  'k2-casual-booking': false,
  'k2-contact-engagment-permission': false,
  'k2-web-api-key-management': false,
  'k2-gl-code-management': false,
  'k2-web-photo-gallery': false,
  'k2-flat-rate-fees': false,
  'k2-gl-code-activation-check': false,
  'k2-class-start-end-time': false,
  'k2-account-tags': false,
  'show-service-fee-in-expanded-payment': false,
  'k2-charge-flat-rates': false,
  'k2-transaction-child-name': false,
  'k2-add-role-types': false,
  'k2-charge-confirmation-modal': false,
  'k2-running-balance': false,
  'k2-automatic-batching': false,
  'k2-billing-account-limit': false,
  'k2-cancel-payment': false,
  'k2-disbursements-report': false,
  'k2-disbursement-pdf': false,
  'k2-agency-reconciliation': false,
  'k2-end-of-care': false,
  'k2-send-app-invite': false,
  'k2-ccrm-integration': false,
  'k2-billing-start-date': false,
  'k2-session-source': false,
  'k2-cwa': false,
  'k2-cwa-actions': false,
  'k2-attendance-refresh': false,
  'k2-bulk-edit-fees': false,
  'k2-fees-from-db': false,
  'bulk-transactions': false,
  'k2-dont-use-payrix-in-fe': false,
  'k2-application-deposit': false,
  'k2-timeoff-request-type': false,
  'waive-late-payment-fee': false,
  'education-navigation': false,
  'k2-program-enrolment-dates': false,
  'k2-krk-royalty-reports': false,
  'k2-analytics-embeded-reports-permissions': false,
  'k2-account-billing-setup-billing-cycle-calendar': false,
  'k2-new-lead-flow': false,
  'express-check-in': false,
  'k2-new-manual-statement-form': false,
  'k2-new-update-center': false,
  'k2-au-discounts': false,
  'us-billing-new-bill-experience': false,
  'k2-enrol-form-difference': false,
  'k2-enrolments-dashboard': false,
  ...ItemizedBillFlags,
  ...USModeFlags,
  ...AccountFlags,
};

export default launchdarklyFlags;
