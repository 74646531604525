import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { glCodeFields, glCodeMappingFields, billingBusinessSettingsFields } from './fields';

interface ICreateGLCodes {
  createGLCodes: IGLCode[];
}

interface ICreateGLCodesVariable {
  input: ICreateGLCodesInput;
}

interface IUpdateGLCode {
  updateGLCode: IGLCode;
}

interface IUpdateGLCodeVariable {
  input: IUpdateGLCodeInput;
}

interface IArchiveGLCode {
  archiveGLCode: IGLCode;
}

interface IArchiveGLCodeVariable {
  input: IArchiveGLCodeInput;
}

export const CREATE_GL_CODES = (fields: string = glCodeFields) =>
  gql` mutation($input: CreateGLCodesInput!){
    createGLCodes(input: $input){
        ${fields}
      }
    }`;

export const UPDATE_GL_CODES = (fields: string = glCodeFields) =>
  gql` mutation($input: UpdateGLCodeInput!){
    updateGLCode(input: $input) {
        ${fields}
      }
    }`;

export const ARCHIVE_GL_CODES = (fields: string = glCodeFields) =>
  gql` mutation ($input: ArchiveGLCodeInput){
      archiveGLCode(input: $input) {
        ${fields}
      }
    }`;

export const useCreateGLCodes = (
  options?: MutationHookOptions<ICreateGLCodes, ICreateGLCodesVariable>,
  fields?: string
) => useMutation<ICreateGLCodes, ICreateGLCodesVariable>(CREATE_GL_CODES(fields), options);

export const useUpdateGLCode = (options?: MutationHookOptions<IUpdateGLCode, IUpdateGLCodeVariable>, fields?: string) =>
  useMutation<IUpdateGLCode, IUpdateGLCodeVariable>(UPDATE_GL_CODES(fields), options);

export const useArchiveGLCode = (
  options?: MutationHookOptions<IArchiveGLCode, IArchiveGLCodeVariable>,
  fields?: string
) => useMutation<IArchiveGLCode, IArchiveGLCodeVariable>(ARCHIVE_GL_CODES(fields), options);

export const UPDATE_GL_ASSIGNMENTS = (fields: string = glCodeMappingFields) =>
  gql`
    mutation($input: UpdateGLCodeAssignmentsInput!) {
      updateGLCodeAssignment(input: $input) {
        ${fields}
      }
    }
  `;

interface GLCodeMappingInput {
  id?: string;
  glCodeId?: string;
  entityGuid?: string;
  surchargeType?: string;
}

interface IUpdateGLCodeAssignmentsVariable {
  input: {
    businessId: string;
    glCodeMappingsInput: GLCodeMappingInput[];
  };
}

export const useUpdateGLCodeAssignments = (
  options?: MutationHookOptions<IGLCodeMapping[], IUpdateGLCodeAssignmentsVariable>,
  fields?: string
) => useMutation<IGLCodeMapping[], IUpdateGLCodeAssignmentsVariable>(UPDATE_GL_ASSIGNMENTS(fields), options);

export const UPDATE_BILLING_BUSINESS_SETTINGS = gql`
  mutation ($input: BillingBusinessSettingsInput!) {
    updateBillingBusinessSettings(input:$input){
      ${billingBusinessSettingsFields}
  	}
  }
  `;

interface IUpdateBillingBusinessSettingsVariable {
  input: {
    businessId: string;
    mandatoryGlCodeMapping?: boolean;
    parentPaymentDirection?: string;
    transactionAmountDisplay?: string;
    transactionAmountThemed?: boolean;
    useGlCodes?: boolean;
  };
}

interface IUpdateBillingBusinessSettingsData {
  updateBillingBusinessSettings: IBillingBusinessSettings;
}

export const useUpdateBillingBusinessSettings = (
  options?: MutationHookOptions<IUpdateBillingBusinessSettingsData, IUpdateBillingBusinessSettingsVariable>
) =>
  useMutation<IUpdateBillingBusinessSettingsData, IUpdateBillingBusinessSettingsVariable>(
    UPDATE_BILLING_BUSINESS_SETTINGS,
    options
  );
