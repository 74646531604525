import React, { useState } from 'react';
import { ApplicationNote, useEditApplicationNoteMutation } from 'generated/graphql';
import TextInput from 'shared/components/TextInput';
import Button from 'shared/components/Buttons';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';

interface IEditNoteProps {
  note: ApplicationNote;
  onEditSuccessful: () => void;
  onCancel: () => void;
}

const EditNote = ({ note, onEditSuccessful, onCancel }: IEditNoteProps) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState(note.comment);
  const [touched, setTouched] = useState(false);

  const onChange = (v) => {
    setComment(v);
    setTouched(true);
  };

  const [editApplicationNoteMutation, { loading }] = useEditApplicationNoteMutation({
    variables: {
      input: {
        noteId: note.id,
        comment,
      },
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
    onCompleted: () => {
      showToast('Edit note successfully', 'success');
    },
    update: (cache, data) => {
      cache.modify({
        id: cache.identify(note),
        fields: {
          comment: () => data.data?.editApplicationNote.comment,
          lastEditedAt: () => data.data?.editApplicationNote.lastEditedAt,
        },
      });
    },
  });

  const onSubmit = async () => {
    await editApplicationNoteMutation();
    onEditSuccessful();
  };

  return (
    <div className="d-flex flex-column justify-content-around">
      <TextInput
        as="textarea"
        name="note"
        label="Note"
        required
        value={comment}
        rows={3}
        onChange={onChange}
        className="add-notes-section__input"
        autoFocus
      />
      <div className="d-flex justify-content-end mb-2">
        <Button variant="light" size="sm" className="mr-4" onClick={onCancel}>
          {t('enrollment.lead-management.notes.cancel')}
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="px-6"
          disabled={!note || !touched}
          onClick={onSubmit}
          loading={loading}
        >
          {t('enrollment.lead-management.notes.update-note')}
        </Button>
      </div>
    </div>
  );
};

export default EditNote;
