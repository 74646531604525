import React, { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import RBTable from 'react-bootstrap/Table';
import { orderBy } from 'lodash';
import TableHeader from './TableHeader';
import SpotsOpenRow from './SpotsOpenRow';
import ChildRow from './ChildRow';
import EmptyTableRow from './EmptyTableRow';
import ClassEnrollmentWeekContext from 'pages/Enrollment/subroutes/Management/Tabs/ClassEnrollment/classEnrollmentWeekProvider';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../store/reducers';

interface IProps {
  enrollments: IEnrollment[];
  availability: IClassAvailability[];
  centerTimezone: string;
}

const ClassEnrollmentsTable: React.FC<IProps> = ({ enrollments, availability, centerTimezone, ...props }) => {
  const dispatch = useDispatch();
  const contextValues = useContext(ClassEnrollmentWeekContext);
  const [sortField, setSortField] = useState<'lastName' | 'startDate' | 'ageUpDate'>('lastName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const capacity = useSelector((state: RootState) => state.enrollment.activeClass?.capacity ?? 0);
  const isFullDayClass = useMemo(() => availability.every((a) => a.isFullDayUtilization), [availability]);

  const handleSort = useCallback((field, direction) => {
    setSortField(field);
    setSortDirection(direction);
  }, []);

  const sortData = useCallback(
    (data: IEnrollment[]): IEnrollment[] => {
      switch (sortField) {
        case 'lastName':
          return orderBy(data, (e) => e.child.lastname, sortDirection);
        case 'ageUpDate':
          return orderBy(data, (e) => new Date(e.expectedAgeUpDate), sortDirection);
        case 'startDate':
          return orderBy(data, (e) => new Date(e.startDate), sortDirection);
        default:
          return data;
      }
    },
    [sortField, sortDirection]
  );
  // sunday -> 0, monday -> 1, etc.
  const getOpenSpotsForDay = useCallback(
    (dayOfWeek: number): { am: number; pm: number } => {
      const dayOfWeekMoment = contextValues.startOfWeek.clone().day(dayOfWeek);
      const availabilityForDate = availability.find((a) => {
        const date: string = a.date.indexOf('+') !== -1 ? a.date.split('+')[0] : a.date;
        const dateMoment = momentTz(date).tz(centerTimezone, true);

        return dateMoment.date() === dayOfWeekMoment.date();
      });

      if (availabilityForDate) {
        return {
          am: availabilityForDate.openSpotsAm,
          pm: availabilityForDate.openSpotsPm,
        };
      }

      return { am: 0, pm: 0 };
    },
    [contextValues.startOfWeek, availability, centerTimezone]
  );

  return (
    <RBTable responsive borderless className="kt-enrollment-class-table">
      <TableHeader onColumnSort={handleSort} isFullDayClass={isFullDayClass} />
      <tbody>
        <SpotsOpenRow
          capacity={capacity}
          isFullDayClass={isFullDayClass}
          monday={getOpenSpotsForDay(1)}
          tuesday={getOpenSpotsForDay(2)}
          wednesday={getOpenSpotsForDay(3)}
          thursday={getOpenSpotsForDay(4)}
          friday={getOpenSpotsForDay(5)}
        />
        {!enrollments.length ? (
          <EmptyTableRow />
        ) : (
          sortData(enrollments).map((enrollment: IEnrollment, idx: number) => (
            <ChildRow
              key={`child-enrollment-${enrollment.id}-${idx}`}
              enrollment={enrollment}
              child={enrollment.child}
              futureEnrollment={
                enrollment.contract.status === 'FUTURE' &&
                moment(enrollment.contract.startDate).tz(centerTimezone).isAfter(contextValues.endOfWeek, 'date')
              }
              centerTimezone={centerTimezone}
              isFullDayClass={isFullDayClass}
            />
          ))
        )}
      </tbody>
    </RBTable>
  );
};

export default ClassEnrollmentsTable;
