import React, { useCallback, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import Card from 'shared/components/Card';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DateInput from 'shared/components/DateInput';
import { cloneDeep } from 'lodash';
import classnames from 'classnames';
import { IconButton, IconButtonCircle } from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPencilAlt, faPlus, faTimes, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import useFormatDate from 'shared/hooks/useFormatDate';
import _ from 'lodash';
import FreeKindyFundingSettings from '../FreeKindyFundingSettings';

interface IProps {
  programId: string;
  fundingSchedules: IKindyProgramFundingSchedule[] | undefined;
  setFundingSchedules: (fundingSchedules: IKindyProgramFundingSchedule[]) => void;
}

const newSettingSchedule: IKindyProgramFundingSchedule = {
  programId: undefined,
  startDate: undefined,
  subsidyTypes: undefined,
  basePercentage: undefined,
  status: undefined,
  createdAt: undefined,
  createdBy: undefined,
  weeks: undefined,
};

const sentinelStartDate = '00/00/0000';

const FreeKindyFundingSchedulesCard: React.FC<IProps> = ({ programId, fundingSchedules, setFundingSchedules }) => {
  const { t } = useTranslation(['subsidies']);

  //Automated Settings
  const formatDate = useFormatDate();
  const [isCreatingNewSchedule, setIsCreatingNewSchedule] = useState(false);
  const [isNewScheduleDateInvalid, setIsNewScheduleDateInvalid] = useState(false);
  const [selectedScheduleStartDate, setSelectedScheduleStartDate] = useState<string | undefined>(
    fundingSchedules?.find((a) => a.startDate)?.startDate
  );

  const isStartDateTaken = useCallback(
    (startDate: string | undefined) =>
      fundingSchedules?.some((s) => moment(startDate).format('MM/DD/YY') === moment(s.startDate).format('MM/DD/YY')) ??
      false,
    [fundingSchedules]
  );

  const handleFundingSettings = useCallback(
    (selected: IKindyProgramFundingSchedule) => {
      if (selected.startDate) {
        const mutableState = cloneDeep(fundingSchedules);
        const fundingSettings = (mutableState ?? []) as IKindyProgramFundingSchedule[];

        const basePercentage = selected.basePercentage ? selected.basePercentage / 100 : undefined;

        const changed = {
          programId: selected.programId,
          startDate: selected.startDate,
          subsidyTypes: selected.subsidyTypes,
          basePercentage: basePercentage,
          weeks: selected.weeks,
          createdAt: selected.createdAt,
          createdBy: selected.createdBy,
          status: selected.status,
        } as IKindyProgramFundingSchedule;

        if (fundingSettings) {
          const index = fundingSettings.findIndex((c) => c.startDate === changed.startDate);

          if (index >= 0) {
            fundingSettings[index] = changed;
          } else {
            fundingSettings.push(changed);
          }
        } else {
          // @ts-ignore
          fundingSettings.push(changed);
        }

        setFundingSchedules(fundingSettings);
      }
    },
    [fundingSchedules, setFundingSchedules]
  );

  const handleUpdateFundingSettings = useCallback(
    (updatedSchedules: IKindyProgramFundingSchedule[]) => {
      setFundingSchedules(updatedSchedules);
    },
    [setFundingSchedules]
  );

  const handleAddNewFundingSchedule = useCallback(
    (newScheduleDate) => {
      if (isStartDateTaken(newScheduleDate)) {
        setIsNewScheduleDateInvalid(true);
      } else {
        setIsNewScheduleDateInvalid(false);
        const scheduleToAdd = {
          ...newSettingSchedule,
          startDate: newScheduleDate,
          status: 'Add',
          weeks: undefined,
          subsidyTypes: undefined,
          programId: programId,
        } as IKindyProgramFundingSchedule;
        handleFundingSettings(scheduleToAdd);
        setSelectedScheduleStartDate(newScheduleDate);
        setIsCreatingNewSchedule(false);
      }
    },
    [handleFundingSettings, isStartDateTaken, programId]
  );

  return (
    <Card header={t('subsidies:automated-funding.title')}>
      <Row noGutters align="stretch" className="h-100">
        <Col xs={3} align="stretch" className="mr-4 h-100 flex-column d-flex">
          <div
            className={
              fundingSchedules?.find((a) => a.startDate != undefined)
                ? 'border rounded flex-grow-1 flex-column d-flex'
                : 'flex-grow-1 flex-column d-flex'
            }
          >
            {isCreatingNewSchedule && (
              <DateInput
                autoFocus={true}
                className="m-2"
                dateOnly
                date={undefined}
                onDateSelect={(date) => {
                  if (date) handleAddNewFundingSchedule(date);
                }}
                isDayBlocked={isStartDateTaken}
                isValid={!isNewScheduleDateInvalid}
                isOutsideRange={(date: moment.Moment) => date.day() !== 1}
              />
            )}
            {isNewScheduleDateInvalid && (
              <div className="text-danger small ml-2 mt-n2 mb-2">A funding schedule already exists for this date.</div>
            )}
            <ul className="list-unstyled option-list w-100">
              {_.orderBy(fundingSchedules, 'startDate', 'desc')
                ?.filter((a) => a.startDate)
                .map((schedule, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      if (!isCreatingNewSchedule) {
                        setSelectedScheduleStartDate(schedule?.startDate);
                      }
                    }}
                    className={classnames({
                      'px-4 py-2': true,
                      'cursor-pointer': !isCreatingNewSchedule,
                      'font-weight-semi-bold': schedule.startDate === selectedScheduleStartDate,
                      'bg-danger-10': schedule.status === 'Delete',
                      'bg-success-10': schedule.status === 'Add',
                      'bg-warning-10': schedule.status === 'Edit',
                      'bg-pale-grey': schedule.startDate === selectedScheduleStartDate && schedule.status === 'Add',
                    })}
                  >
                    <div className="d-flex ">
                      {schedule.startDate === selectedScheduleStartDate ? (
                        <IconButton
                          icon={faTrashAlt}
                          className="mr-2"
                          iconSize="1x"
                          tooltipText="Delete schedule"
                          onClick={() => {
                            if (fundingSchedules) {
                              const updatedSchedules = [...fundingSchedules];
                              const index = updatedSchedules.findIndex((s) => s.startDate === schedule.startDate);
                              if (index !== -1) {
                                updatedSchedules.splice(index, 1);
                              }
                              handleUpdateFundingSettings(updatedSchedules);
                            }
                          }}
                        />
                      ) : (
                        <div className="pr-6"></div>
                      )}
                      {schedule.startDate && formatDate(moment(schedule.startDate), 'MM/DD/YY')}
                      {(schedule.status === 'Delete' || schedule.status === 'Add' || schedule.status === 'Edit') && (
                        <FontAwesomeIcon
                          icon={
                            schedule.status === 'Delete' ? faMinus : schedule.status === 'Add' ? faPlus : faPencilAlt
                          }
                          color={
                            schedule.status === 'Delete'
                              ? colors.danger
                              : schedule.status === 'Add'
                              ? colors.success
                              : colors.warning
                          }
                          size="xs"
                          className="ml-2"
                        />
                      )}
                      {schedule.status === 'Delete' && (
                        <IconButton
                          icon={faTimes}
                          className="ml-auto"
                          iconSize="1x"
                          tooltipText="Undo"
                          onClick={() => {
                            if (fundingSchedules) {
                              const schedules = [...fundingSchedules];
                              const index = schedules.findIndex((s) => s.startDate === selectedScheduleStartDate);
                              if (schedule.status === 'Add') {
                                if (index !== -1) schedules.splice(index, 1);
                              } else {
                                const saved = fundingSchedules?.find((s) => s.startDate === selectedScheduleStartDate);
                                if (index !== -1 && saved) schedules.splice(index, 1, { ...saved });
                              }
                              handleUpdateFundingSettings(schedules);
                              setSelectedScheduleStartDate(undefined);
                            }
                          }}
                        />
                      )}
                    </div>
                  </li>
                ))}
            </ul>

            <IconButtonCircle
              icon={faPlus}
              color="white"
              backgroundColor="secondary"
              className="mt-auto ml-auto mr-4 mb-4"
              onClick={() => {
                setIsCreatingNewSchedule(true);
                setSelectedScheduleStartDate(undefined);
              }}
              tooltipText="Add another schedule"
              disabled={isCreatingNewSchedule}
            />
          </div>
        </Col>
        <Col>
          <FreeKindyFundingSettings
            formData={
              fundingSchedules
                ?.map((fs) => {
                  return {
                    programId: fs.programId,
                    startDate: fs.startDate,
                    subsidyTypes: fs.subsidyTypes,
                    basePercentage: fs.basePercentage ? Math.round(fs.basePercentage * 100) : undefined,
                    weeks: fs.weeks,
                    createdAt: fs.createdAt,
                    createdBy: fs.createdBy,
                    status: fs.status,
                  };
                })
                .find((s) => s.startDate === selectedScheduleStartDate) ?? {
                subsidyTypes: [],
                basePercentage: undefined,
                startDate: undefined,
                createdAt: undefined,
                createdBy: undefined,
                programId: undefined,
                weeks: undefined,
                status: 'None',
              }
            }
            handleChange={handleFundingSettings}
            selectedDate={selectedScheduleStartDate}
            key={selectedScheduleStartDate ? selectedScheduleStartDate : sentinelStartDate}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default FreeKindyFundingSchedulesCard;
