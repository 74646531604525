import { Link } from 'react-router-dom';
import React from 'react';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from './dropdownOptions/countryInfo';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const errors: any = {
  firstName: 'What shall we call you? Enter your First Name to continue.',
  lastName: 'We want to get to know you! You must enter your Last Name to continue.',
  genericFirstName: 'Enter a first name to continue.',
  genericLastName: 'Enter a last name to continue.',
  invalidEmailSyntax: 'An email address must contain a single @.',
  invalidEmailDomain: 'The domain portion of the email address is invalid (the portion after the @).',
  emailTaken: (
    <>
      Another user with this email already exists. Did you mean to{' '}
      <Link className="font-weight-bold" to="/login">
        sign in?
      </Link>
    </>
  ),
  signUpCheckEmail: 'There was a problem signing you up, please check your email for more details.',
  passwordRequirements: 'Your password needs a few tweaks to meet the requirements.',
  confirmPassword:
    "Oops. It looks like your passwords don't match. Click the eye icon to get a sneak peak on what you're typing.",
  formRequirements: 'Complete all the required (*) fields in order to continue.',
  UserNotFoundException: "I'm sorry, username/Password don't match",
  UserNotFoundExceptionSecondAttempt: <>Username/Password still don't match.</>,
  NotAuthorizedException: (
    <>
      Username/Password don't match. Try again, or if you're stuck - we can help you{' '}
      <Link to="/recover-password">recover your password</Link>.
    </>
  ),
  unknownSSODomain: "I'm sorry, login attempt failed.",
  idpIdentifierNotFound: 'Authentication provider not found. Please check with your system administrator.',
  onboardingIncomplete:
    'Onboarding process incomplete. Please check your email and follow the sign up steps to continue.',
  businessName: 'We want to get to know you! You must enter your Business name to continue.',
  centerName: `What should we call your ${fieldLabels.center.toLowerCase()}? Enter your ${fieldLabels.center.toLowerCase()} name.`,
  address: {
    business: {
      address: 'Your business address helps us automate some of our system features for you.',
      city: `Enter the ${fieldLabels.city} your business is located in.`,
      state: `Which ${fieldLabels.subdivision} is your business in?`,
      postalCode: `You must enter the ${fieldLabels.postalCode} for your business.`,
    },
    center: {
      address: `Your ${fieldLabels.center.toLowerCase()} address helps us automate some of our system features for you.`,
      city: `Enter the ${fieldLabels.city} your ${fieldLabels.center.toLowerCase()} is located in.`,
      state: `Which ${fieldLabels.subdivision} is your ${fieldLabels.center.toLowerCase()} in?`,
      postalCode: `You must enter the ${fieldLabels.postalCode} for your ${fieldLabels.center.toLowerCase()}.`,
    },
    staff: {
      address: 'Your personal address helps us automate some of our system features for you.',
      city: `Enter the ${fieldLabels.city} you live in.`,
      state: `Which ${fieldLabels.subdivision} do you live in?`,
      postalCode: `You must enter the ${fieldLabels.postalCode} that you live in.`,
    },
    generic: {
      address: 'Enter a street address.',
      city: `Enter a ${fieldLabels.city}.`,
      state: `Select a ${fieldLabels.subdivision}.`,
      postalCode: `Enter a ${fieldLabels.postalCode}.`,
    },
  },
  invalidPhoneNumber: 'Your phone number is invalid.',
  phone: 'Your phone number should be 10 digits long.',
  genericPhone: 'Phone number should be 10 digits long.',
  googleLogin: {
    idpiframe_initialization_failed:
      "Uh oh, looks like you need to tweak your browser settings if you'd like to continue with Google. Enable third party cookies or input your email and password instead.",
    popup_closed_by_user:
      'Oops! The google login window was closed before we could get your info. Please re-enter your information and try again.',
    generic: 'Uh oh, looks like there is an error connecting with Google. Please try again.',
  },
  signUpGeneric:
    "Uh oh. Something isn't right here. Try submitting your details again. If the problem continues - get in touch.", // TO DO: ADD LINK TO CONTACT US
  generic: "Uh oh. Something isn't right here. Try submitting again.",
  pinRequirements: 'PIN must be 4 digits long.',
  confirmPin: "Oops. It looks like your PINs don't match.",
  codeRequirements: 'Code must be 6 digits long.',
  invalidCode: "Hmm, we can't find a business matching this code.",
  noCenters: `Uh oh. Looks there are no active ${fieldLabels.center.toLowerCase()} for your business.`,
  joinEmailInUse: 'Email already in use.  Please have that account deactivated before attempting to use again.',
  existingRequest: 'A request for this user has already been sent.',
  overlappingTimes: 'Overlapping time ranges cannot be entered.',
  changeEmailInUse: 'Another user with this email already exists.',
  abn: 'Your ABN should be 11 digits long.',
  invalidAbn: 'Your ABN is invalid.',
  acn: 'Your ACN should be 9 digits long.',
  invalidAcn: 'Your ACN is invalid.',
  taxId: 'Your Tax Id should be 9 digits long.',
  isValidTaxId: 'Your Tax Id is invalid.',
};

export default errors;
