import React from 'react';
import { iCalendarEvent } from './types';

const CalendarEvent = (props: { event: iCalendarEvent }) => {
  const { title } = props.event;
  return (
    <div className="event-content-container">
      <div className={'event-title-container'}>
        <div className="event-title">{title?.toUpperCase() ?? ''}</div>
      </div>
    </div>
  );
};

export default CalendarEvent;
