import axios from 'axios';
import config from 'config';
import { useEffect, useState } from 'react';
import { useGetCurrentBusiness } from 'shared/hooks/user-context/useGetCurrentBusiness';
import { useGetCurrentJwt } from 'shared/hooks/user-context/useGetCurrentJwt';

export interface ISearchCriteria {
  agencyIds: string[];
  centerIds: string[];
  endDate: string;
  pageNumber: number;
  pageSize: number;
  searchTerm: string;
  startDate: string;
  status: string[];
}

export interface IAgencySubsidyPayment {
  id: string;
  agencyName: string;
  centers: [{ id: string; name: string; centerId: string }];
  paymentDate: string;
  referenceId: string;
  status: string;
}

export function useGetAgencyPayments(searchCriteria: ISearchCriteria): {
  isLoading: boolean;
  agencyPayments: IAgencySubsidyPayment[];
  loadData: () => {};
} {
  const businessId = useGetCurrentBusiness();
  const jwt = useGetCurrentJwt();
  const [agencyPayments, setAgencyPayments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, [businessId, isLoading, jwt, searchCriteria]);

  const loadData = async function loadData() {
    const response = await axios.post(
      `${config.api.billing.uri}/api/v2/agency-payments/search`,
      {
        ...searchCriteria,
        businessId,
        searchTerm: searchCriteria.searchTerm || null,
        startDate: searchCriteria.startDate || null,
        endDate: searchCriteria.endDate || null,
      },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
    setAgencyPayments(response.data.data);
  };

  return {
    isLoading: false,
    agencyPayments,
    loadData,
  };
}
