import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';

interface IUpdateISMessage {
  updateIsMessageViewedStatus: IISMessage;
}

interface IUpdateISMessageVariables {
  input: IISMessageUpdateInput;
}

export const UPDATE_IS_MESSAGE_STATUS = gql`
  mutation ($input: IsMessageUpdateViewedStatusInput!) {
    updateIsMessageViewedStatus(input: $input) {
      id
      viewedStatus
    }
  }
`;

export const useUpdateISMessage = (options?: MutationHookOptions<IUpdateISMessage, IUpdateISMessageVariables>) => {
  return useMutation<IUpdateISMessage, IUpdateISMessageVariables>(UPDATE_IS_MESSAGE_STATUS, options);
};
