import React from 'react';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import * as Mui from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import videoImage from 'shared/images/video-play-icon.png';

import { useGetSessionDataForChildSelect } from 'gql/session/queries';
import { useGetActiveClassesForCenter } from 'gql/center/queries';

import SideModalDrawer from 'shared/components/ModalDrawer';
import { ButtonAsLink, IconButton } from 'shared/components/Buttons';
import TextInput from 'shared/components/TextInput';
import useUploadGalleryFile from '../hooks/useUploadGalleryFile';
import Select from 'shared/components/Select';
import { RootState } from 'store/reducers';
import { showToast } from 'shared/components/Toast';
import DateInput from 'shared/components/DateInput';
import { getFullName, getInitials } from 'shared/util/string';
import Avatar from 'shared/components/Avatar';
import { useCreatePhoto } from 'pages/Photos/gql/mutations';

interface IProps {
  centerId: string;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  refetchPhotos: () => void;
}

interface IChildWithAccountId extends IChild {
  accountChildId: string;
}

interface IFormShape {
  classId: string;
  children: IChildWithAccountId[];
  takenOn: string;
  note: string;
}

const newPhotoInput = {
  classId: '',
  children: [],
  takenOn: moment().format(),
  note: '',
};

const AddPhotoModal: React.FC<IProps> = ({ centerId, isOpen, setIsOpen, refetchPhotos }) => {
  const user = useSelector((state: RootState) => state.user);
  const [file, setFile] = React.useState<File | undefined>();
  const [formData, setFormData] = React.useState<IFormShape>(newPhotoInput);
  const fileInputRef = React.createRef<HTMLInputElement>();
  const openFileDialog = (): void => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const acceptedFileExts = ['.m4v', '.mp4', '.mov', '.jpg', '.jpeg', '.png', '.svg', '.gif', '.bmp', '.tiff'];

  function getExtension(file: File) {
    var parts = file.name.split('.');
    return parts[parts.length - 1];
  }

  const isVideo = React.useMemo(() => {
    if (file === undefined) return false;
    var ext = getExtension(file);
    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'mp4':
      case 'mov':
        // etc
        return true;
    }
    return false;
  }, [file]);

  const isPhoto = React.useMemo(() => {
    if (file === undefined) return false;
    var ext = getExtension(file);
    switch (ext.toLowerCase()) {
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'svg':
      case 'gif':
      case 'bmp':
      case 'tiff':
        // etc
        return true;
    }
    return false;
  }, [file]);

  const { classes } = useGetActiveClassesForCenter({ variables: { centerId } }, `id name`);
  const { data: sessionData } = useGetSessionDataForChildSelect({
    variables: {
      input: {
        centerId,
        startDate: formData.takenOn,
        endDate: formData.takenOn,
      },
    },
  });
  const childrenOptions =
    _.sortBy(
      sessionData?.getExpectedSessions.map((s) => ({
        ...s.child,
        accountChildId: s.accountChildId,
      })),
      'firstname'
    ) ?? [];

  const [uploadPhotoFn, { loading: uploadLoading, data: uploadData, error }] = useUploadGalleryFile();

  const handleClose = React.useCallback(() => {
    setFile(undefined);
    setFormData(newPhotoInput);
    setIsOpen(false);
  }, [setIsOpen]);

  React.useEffect(() => {
    if (uploadData) {
      refetchPhotos();
      handleClose();
    }
  }, [uploadData, handleClose, refetchPhotos]);

  const handleFileSelect = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setFile(event.target.files[0]);
    event.target.value = ''; // reset value so onChange is triggered if user keeps selecting same file
  }, []);

  const onDrop = React.useCallback((files: File[]) => {
    setFile(files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onSubmit = () => {
    if (file) {
      uploadPhotoFn(file, {
        centerId: centerId,
        classId: formData.classId,
        childIds: formData.children.map((c) => c.accountChildId),
        note: formData.note,
        isVideo: isVideo,
        takenOn: formData.takenOn,
        staffId: user?.id ?? '',
      });
    }
  };

  return (
    <SideModalDrawer
      title="Add Photo / Video"
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Save"
      primaryCallback={() => onSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled: !file || !formData.classId || (!isVideo && !isPhoto),
        loading: uploadLoading,
      }}
      closeOnPrimaryCallback={false}
    >
      {file ? (
        <>
          {isPhoto || isVideo ? (
            <div className="d-flex">
              <img
                style={{ objectFit: 'cover' }}
                alt="uploaded"
                src={isVideo ? videoImage : URL.createObjectURL(file)}
                width="auto"
                height={200}
                className="mb-4 mr-4"
              />
              <IconButton icon={faTrashAlt} onClick={() => setFile(undefined)} />
            </div>
          ) : (
            <>
              <Mui.Box
                display="flex"
                justifyContent="space-between"
                alignContent="baseline"
                paddingBottom={4}
                paddingTop={4}
              >
                <div>A {getExtension(file)} is not a supported file type</div>
                <div>
                  <IconButton icon={faTrashAlt} onClick={() => setFile(undefined)} />
                </div>
              </Mui.Box>
            </>
          )}
        </>
      ) : (
        <div className="mb-4">
          <Mui.Box>
            <div
              className={'photo-file-display photo-dropzone' + (isDragActive ? ' photo-dropzone-active' : '')}
              {...getRootProps()}
            >
              <Mui.Box textAlign="center" padding={2}>
                <CloudUploadOutlinedIcon color="secondary" fontSize="large" />
                <Mui.Typography variant="body1">
                  Drag & Drop or{' '}
                  <ButtonAsLink className="md text-primary" onClick={openFileDialog}>
                    browse
                  </ButtonAsLink>{' '}
                  to upload.
                </Mui.Typography>
                <Mui.Typography variant="body2" style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <strong>Supports: </strong>
                  {acceptedFileExts.join(', ')}
                </Mui.Typography>
                <input
                  {...getInputProps()}
                  ref={fileInputRef}
                  className="fileInput"
                  type="file"
                  multiple={false}
                  onChange={handleFileSelect}
                  accept={acceptedFileExts.join(',')}
                />
              </Mui.Box>
            </div>
          </Mui.Box>
        </div>
      )}
      <Row>
        <Col xs={8}>
          <Select
            label="Classroom"
            options={classes.map((c) => ({ value: c.id, label: c.name }))}
            value={formData.classId}
            onChange={(option) => {
              setFormData({ ...formData, classId: option.value });
            }}
            required={true}
          />
        </Col>
        <Col xs={4}>
          <DateInput
            required
            label="Date"
            date={formData.takenOn}
            onDateSelect={(date) =>
              setFormData((prev) => ({
                ...prev,
                takenOn: moment(date).startOf('day').format(),
              }))
            }
          />
        </Col>
      </Row>
      <Select
        label="Children"
        isMulti
        isSearchable
        options={childrenOptions}
        value={formData.children.length ? formData.children : undefined}
        getOptionValue={(option: IChildWithAccountId) => option.id}
        onChange={(children: IChildWithAccountId[]) => setFormData((prev) => ({ ...prev, children: children ?? [] }))}
        getOptionLabel={(option: IChildWithAccountId) => getFullName(option)}
        formatOptionLabel={(option: IChildWithAccountId) => (
          <div className="d-flex align-items-center">
            <Avatar initials={getInitials(option)} size="sm" image={option.avatar?.url} className="mr-2" />
            <div>{getFullName(option)}</div>
          </div>
        )}
        className="flex-wrap"
        noOptionsMessage={() => `No children attended center on selected date.`}
      />
      <TextInput
        label="Add Note"
        value={formData.note}
        onChange={(value) => setFormData({ ...formData, note: value })}
      />
    </SideModalDrawer>
  );
};

export default AddPhotoModal;
