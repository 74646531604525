import { isDev, isRegion } from 'shared/util/region';
import {
  ApplicationChild,
  ApplicationScheduleDay,
  ApplicationScheduleOffer,
  Application,
  ApplicationScheduleEnquiry,
  ApplicationScheduleOfferState,
  ApplicationStage,
  ApplicationFlowType,
} from 'generated/graphql';
import { uniq, capitalize, groupBy, cloneDeep, intersection } from 'lodash';
import { NEW_TAB_FOR_NORMAL_FLOW } from './types';

enum DayOfWeekType {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export const getCycleType = (days: ApplicationScheduleDay[]) => {
  const secondWeek = days.filter((d) => d.weekType === 'WEEK2');

  return secondWeek.length > 0 ? 'BIWEEKLY' : 'WEEKLY';
};

export const checkPartialOffer = (child: ApplicationChild) => {
  const { enquiries, offers } = child;

  const sentSchedules = offers.filter((o) => o?.applicationOfferId !== null);
  return sentSchedules.length < enquiries.length;
};

// inserts or deletes day in week
export const toggleDayInWeek = (value: WeekDay, weekType: WeekType, days: ApplicationScheduleDay[]) => {
  let week = days.filter((d) => d.weekType === weekType);
  const exists = week.find((day) => day.dayOfWeek === value);
  if (exists) {
    week = week.filter((day) => day.dayOfWeek !== value);
  } else {
    week = [...week, { dayOfWeek: value, weekType, id: value }];
  }
  return week;
};

export const generateOfferScheduleInput = (
  sched: ApplicationScheduleOffer,
  childId: string,
  businessId: string,
  centerId: string,
  application?: Application
) => {
  // make sure to save days based on the weekType
  let firstWeek = sched.days.filter((d) => d.weekType === 'WEEK1');
  let days = sched.cycleType === 'WEEKLY' ? firstWeek : sched.days;

  const value = {
    applicationId: application?.id || '',
    offerCenterId: centerId,
    days: days.map((d) => ({
      weekType: d.weekType,
      dayOfWeek: d.dayOfWeek,
    })) as any,
    cycleType: sched.cycleType as ContractCycleType,
    startDate: sched.startDate,
    endDate: undefined,
    classId: sched.classId ?? '',
    feeId: sched.feeId ?? '',
    careType: sched.careType,
    applicationChildId: childId,
    businessId,
    applicationScheduleOfferId: sched.id,
  };
  return value;
};

export const getHostName = (): string => {
  if (isDev()) return 'enrol.qual.k2.kangarootime.au';
  return 'enrol.kangarootime.com.au';
};

export const getWeeklyOrBiweeklyFromEnquiry = (enquiry: ApplicationScheduleEnquiry) => {
  const { days } = enquiry;
  const weekTypes = uniq(days.map((d) => d.weekType));

  if (weekTypes.length === 2) {
    return 'BIWEEKLY';
  }

  return 'WEEKLY';
};

export const generateScheduleDaysWords = (days: ApplicationScheduleDay[], useShortName?: boolean): [string, string] => {
  const getShortName = (day: ApplicationScheduleDay): string => {
    switch (day.dayOfWeek) {
      case DayOfWeekType.Monday:
        return 'Mon';
      case DayOfWeekType.Tuesday:
        return 'Tues';
      case DayOfWeekType.Wednesday:
        return 'Wed';
      case DayOfWeekType.Thursday:
        return 'Thur';
      case DayOfWeekType.Friday:
        return 'Fri';
      case DayOfWeekType.Saturday:
        return 'Sat';
      default:
        return 'Sun';
    }
  };

  const daysArrayToString = (scheduleDays: ApplicationScheduleDay[]): string =>
    scheduleDays
      ? scheduleDays.map((day) => capitalize(useShortName ? getShortName(day) : day.dayOfWeek)).join(', ')
      : '';

  const daysInOrder = [
    DayOfWeekType.Monday,
    DayOfWeekType.Tuesday,
    DayOfWeekType.Wednesday,
    DayOfWeekType.Thursday,
    DayOfWeekType.Friday,
    DayOfWeekType.Saturday,
    DayOfWeekType.Sunday,
  ];

  const groupedDaysByWeek = groupBy(days, 'weekType');

  Object.keys(groupedDaysByWeek).forEach((key) => {
    groupedDaysByWeek[key].sort(
      (a, b) => daysInOrder.indexOf(a.dayOfWeek as DayOfWeekType) - daysInOrder.indexOf(b.dayOfWeek as DayOfWeekType)
    );
  });

  const week1: string = daysArrayToString(groupedDaysByWeek['WEEK1']);
  const week2: string = daysArrayToString(groupedDaysByWeek['WEEK2']);

  return [week1, week2];
};

export const getProgramNamesString = (offers: ApplicationScheduleOffer[]) => {
  const programNames = offers
    .map((o) => o.program?.name ?? '')
    .filter((s) => s !== '')
    .join(', ');
  return programNames;
};

export const getChildrenMatchAsoState = (
  children: ApplicationChild[],
  validStates: ApplicationScheduleOfferState[],
  // `new` means the applicationStage is `waitlist` for normal flow or `offered` for the program flow
  isNewApplication = false,
  isLostApplicationWithoutOffer = false
) => {
  // for `new` application, every child should been shown the to user
  // if this is a lost application without any offers (legit for normal flow), return all children.
  if (isLostApplicationWithoutOffer) return children;

  // for offers in waitlist (normal flow) - hide children that have already been offered
  if (isNewApplication)
    return children.filter(
      (c) => c.offers.length <= 0 || c.offers.every((o) => o?.state === ApplicationScheduleOfferState.Rejected)
    );

  const matchedChildren = children.filter((c) =>
    c.offers.some((o) => validStates.includes((o?.state as ApplicationScheduleOfferState) ?? ''))
  );

  return matchedChildren;
};

export const checkIsNewApplication = (activeTab: ApplicationStage, applicationFlow: ApplicationFlowType) => {
  if (applicationFlow === ApplicationFlowType.InquireOfferEnrollment) {
    return activeTab === NEW_TAB_FOR_NORMAL_FLOW;
  }
  return false;
};

export const checkIsLostApplicationWithoutOffer = (activeTab: ApplicationStage, application: Application) => {
  // In normally flow, if an application could be marked as lost before any offer was issued
  // So if the current tab is `lost`, and there isn't any offer, it's a lost application without offer
  const applicationScheduleOfferCount = application.children.reduce<number>((totalOffers, child) => {
    // if applicationOfferId is null, this is a draft offer, which shouldn't be counted
    const offerCount = child.offers.filter((o) => o?.applicationOfferId).length;
    return (totalOffers += offerCount);
  }, 0);

  if (activeTab === ApplicationStage.Lost && applicationScheduleOfferCount === 0) {
    return true;
  }
  return false;
};

export const getChildrenNamesMatchAsoState = (
  children: ApplicationChild[],
  validStates: ApplicationScheduleOfferState[],
  isNewApplication = false,
  isLostApplicationWithoutOffer = false
) => {
  const matchedChildren = getChildrenMatchAsoState(
    children,
    validStates,
    isNewApplication,
    isLostApplicationWithoutOffer
  );
  const names = matchedChildren.map((c) => `${capitalize(c.firstName)} ${capitalize(c.lastName)}`);
  const dobs = matchedChildren.map((c) => c.dateOfBirth);

  return [names, dobs];
};

export const getProgramNamesMatchAsoState = (
  children: ApplicationChild[],
  validStates: ApplicationScheduleOfferState[],
  isNewApplication = false
) => {
  // all program names should be shown if the application is new for program flow
  if (isNewApplication) {
    const OfferedProgramNames = children.reduce<string[]>((acc, child) => {
      const programNames = child.offers.map((o) => o?.program?.name ?? '');
      return [...acc, ...programNames];
    }, []);

    return uniq(OfferedProgramNames);
  }

  const matchedChildren = getChildrenMatchAsoState(children, validStates);
  const programNames: string[] = [];
  matchedChildren.forEach((c) => {
    c.offers.forEach((offer) => {
      if (validStates.includes((offer?.state as ApplicationScheduleOfferState) ?? '')) {
        const programName = offer?.program?.name ?? '';
        if (programName) programNames.push(programName);
      }
    });
  });

  return uniq(programNames);
};

export const getCareTypeNamesMatchAsoState = (
  children: ApplicationChild[],
  validStates: ApplicationScheduleOfferState[],
  isNewApplication = false,
  isLostApplicationWithoutOffer = false
) => {
  // new application for normal flow read careTypes from enquires for children who dont already have offers
  // a lost application without any offer should read careTypes from enquires as well (legit for normal flow)
  if (isNewApplication) {
    const childrenWitoutOffers = children.filter(
      (c) => c.offers.length <= 0 || c.offers.every((o) => o?.state === ApplicationScheduleOfferState.Rejected)
    );
    const inquiredCareTypes = childrenWitoutOffers.reduce<string[]>((acc, child) => {
      const careTypesNames = child.enquiries.map((e) => e.careType);
      return [...acc, ...careTypesNames];
    }, []);

    return uniq(inquiredCareTypes);
  }

  if (isLostApplicationWithoutOffer) {
    const inquiredCareTypes = children.reduce<string[]>((acc, child) => {
      const careTypesNames = child.enquiries.map((e) => e.careType);
      return [...acc, ...careTypesNames];
    }, []);

    return uniq(inquiredCareTypes);
  }

  const matchedChildren = getChildrenMatchAsoState(children, validStates);
  const careTypes: string[] = [];
  matchedChildren.forEach((c) => {
    c.offers.forEach((offer) => {
      if (validStates.includes((offer?.state as ApplicationScheduleOfferState) ?? '')) {
        const careType = offer?.careType ?? '';
        if (careType) careTypes.push(careType);
      }
    });
  });

  return uniq(careTypes);
};

export const filterByApplicationScheduleOfferStates = (
  application: Application,
  validApplicationScheduleOfferStates: ApplicationScheduleOfferState[]
) => {
  const children = cloneDeep(application.children);
  const validChildren = children.filter((child) =>
    child.offers.some((offer) => (validApplicationScheduleOfferStates as string[]).includes(offer?.state ?? ''))
  );

  validChildren.forEach((child) => {
    child.offers = child.offers.filter((offer) =>
      (validApplicationScheduleOfferStates as string[]).includes(offer?.state ?? '')
    );
  });

  return validChildren;
};

export const getCenterIdsByTags = (centers?: ICenter[], tagIds?: string[]) => {
  const centerWithTags =
    centers?.filter((c) => {
      const centerTags = c.tags.map((s) => s.id);
      return intersection(tagIds, centerTags).length > 0;
    }) ?? [];
  return centerWithTags.map((c) => c.id) ?? [];
};

export const isProgramBasedFlow = (flowType: ApplicationFlowType | undefined | null | string) =>
  [ApplicationFlowType.InquireProgramEnrollmentApprove, ApplicationFlowType.InquireProgramFormPayApprove].includes(
    flowType as any
  );
