export const currencyFormat = (amount?: number, includeDecimal: boolean = true) => {
  const maximumFractionDigits = includeDecimal ? 2 : 0;
  const minimumFractionDigits = includeDecimal ? 2 : 0;

  if (amount == null) {
    amount = 0;
  }

  return amount < 0
    ? `-$${(amount * -1).toLocaleString(undefined, { maximumFractionDigits, minimumFractionDigits })}`
    : `$${amount.toLocaleString(undefined, { maximumFractionDigits, minimumFractionDigits })}`;
};
