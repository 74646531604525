import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { iSMessagePagedFields } from './fields';

const GET_IS_MESSAGES = gql`
  query($input: GetIsMessagesForCentersInput!) {
    getIsMessagesForCenters(input: $input) {
      ${iSMessagePagedFields}
    }
  }
`;

interface IGetISMessages {
  getIsMessagesForCenters: IPagedResult<IISMessage>;
}

interface IGetISMessagesVariables {
  input: IISMessageInput;
}

export const useGetISMessages = (options?: QueryHookOptions<IGetISMessages, IGetISMessagesVariables>) =>
  useQuery<IGetISMessages, IGetISMessagesVariables>(GET_IS_MESSAGES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
