export const trainingTimeEventFields = `
  id
  businessId
  centerId
  name
  type
  date
  hours
  staffIds
  createdAt
  createdBy
  lastModifiedAt
  lastModifiedBy
  ptoTypeId
`;
