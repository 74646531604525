import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import RadioGroupedDropdownFilter from 'shared/components/Dropdown/DropdownFilter/RadioGroupedDropdownFilter';
import { capitalize, uniq } from 'lodash';
import { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import {
  ApplicationFlowType,
  useGetEnrollmentProgramsQuery,
  SortDirection,
  ApplicationDayFilterType,
  ApplicationStage,
  YesNoType,
  ApplicationState,
  EnrolmentSource,
} from 'generated/graphql';
import careTypes, { US_Care_Types } from 'shared/constants/dropdownOptions/careTypes';
import { isRegion } from 'shared/util/region';
import { useLeadsContext } from '../../LeadsContext';
import Select from 'shared/components/Select';
import GroupedFilters from 'shared/components/GroupedFilters';
import { Col } from 'shared/components/Layout';
import classNames from 'classnames';
import AgeRangeSlider from 'shared/components/AgeRangeSlider/AgeRangeSlider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isProgramBasedFlow } from '../../utils';
import Checkbox from 'shared/components/Checkbox';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';

interface IProps {
  paginationProps: any;
}

const WORKING_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const LeadTableHeader: React.FC<IProps> = ({ paginationProps }) => {
  const { k2EnrolFormDifference } = useFlags();
  const { t } = useTranslation(['translation', 'enrollment']);
  const { businessId } = useSelector((state: RootState) => state.context);
  const sort = useMemo(() => [{ field: 'name', direction: SortDirection.Ascending }], []);
  const careTypeOptions = useMemo(() => (isRegion('AU') ? careTypes : US_Care_Types), []);
  const {
    centerIds,
    applicationFlowType,
    setSearchText,
    searchText,
    programFilters,
    setProgramFilters,
    careTypeFilters,
    setCareTypeFilters,
    daysFilter,
    selectedDaysFilterGroup,
    setSelectedDaysFilterGroup,
    resetFilters,
    handleDaysFilterChange,
    centerOptions,
    centerFilters,
    setCenterFilters,
    tagOptions,
    tagFilters,
    enrollmentSource,
    handleSourceChange,
    setTagFilters,
    resetCenterFilters,
    paidFilter,
    setPaidFilter,
    subsidyEligibilityFilter,
    setSubsidyEligibilityFilter,
    inProgressStatusFilter,
    setInProgressStatusFilter,
    activeTab,
    tableFunctions,
    tableState,
    ageRangeFilters,
    setAgeRangeFilters,
    centers,
    onlyShowUpdatedForms,
    setOnlyShowUpdatedForms,
  } = useLeadsContext();

  const { data: programsData } = useGetEnrollmentProgramsQuery({
    variables: {
      input: {
        businessId: businessId ?? '',
        centerIds,
        pagination: {
          pageNumber: 1,
          pageSize: 10000,
        },
        sort,
      },
    },
    skip: !Boolean(businessId) || centerIds.length === 0,
  });

  const enrollmentSourceOptions: ITableFilterOption[] = useMemo(() => {
    return Object.values(EnrolmentSource).map((source) => {
      return { label: t(`enrollment:enrollmentSourceOptions.${source}`), value: source };
    });
  }, []);

  const daysFilterOptions = useMemo<IRadioGroupedFilterOption[]>(() => {
    return [
      {
        label: 'Schedule includes',
        value: ApplicationDayFilterType.ScheduleIncludes,
        isChecked: selectedDaysFilterGroup === ApplicationDayFilterType.ScheduleIncludes,
        onCheck: setSelectedDaysFilterGroup,
        options: WORKING_DAYS.map((day) => ({
          label: day,
          value: `${ApplicationDayFilterType.ScheduleIncludes}-${day}`,
          isDisabled: selectedDaysFilterGroup !== ApplicationDayFilterType.ScheduleIncludes,
          namespace: ApplicationDayFilterType.ScheduleIncludes,
        })),
      },
      {
        label: 'Schedule is exactly',
        value: ApplicationDayFilterType.ScheduleIsExactly,
        isChecked: selectedDaysFilterGroup === ApplicationDayFilterType.ScheduleIsExactly,
        onCheck: setSelectedDaysFilterGroup,
        options: WORKING_DAYS.map((day) => ({
          label: day,
          value: `${ApplicationDayFilterType.ScheduleIsExactly}-${day}`,
          isDisabled: selectedDaysFilterGroup !== ApplicationDayFilterType.ScheduleIsExactly,
          namespace: ApplicationDayFilterType.ScheduleIsExactly,
        })),
      },
      {
        label: 'Number of days',
        value: ApplicationDayFilterType.NumberOfDays,
        isChecked: selectedDaysFilterGroup === ApplicationDayFilterType.NumberOfDays,
        onCheck: setSelectedDaysFilterGroup,
        options: Array.from({ length: 5 }, (_, i) => i + 1).map((day) => ({
          label: day.toString(),
          value: `${ApplicationDayFilterType.NumberOfDays}-${day.toString()}`,
          type: 'radio',
          isDisabled: selectedDaysFilterGroup !== ApplicationDayFilterType.NumberOfDays,
          namespace: ApplicationDayFilterType.NumberOfDays,
        })),
      },
      ...[
        {
          label: 'Casual Only',
          value: ApplicationDayFilterType.Casual,
          isChecked: selectedDaysFilterGroup === ApplicationDayFilterType.Casual,
          onCheck: setSelectedDaysFilterGroup,
          options: [],
        },
      ],
    ];
  }, [selectedDaysFilterGroup, setSelectedDaysFilterGroup]);

  const paidFilterOptions: ITableFilterOption[] = [
    {
      value: YesNoType.Yes,
      label: 'Paid',
    },
    {
      value: YesNoType.No,
      label: 'Not Paid',
    },
    {
      value: YesNoType.NoneApplicable,
      label: 'Payment Not Applicable',
    },
  ];

  const SubsidyFilterOptions: ITableFilterOption[] = [
    {
      value: YesNoType.Yes,
      label: 'Subsidy Eligible',
    },
    {
      value: YesNoType.No,
      label: 'Subsidy Not Eligible ',
    },
    {
      value: YesNoType.NoneApplicable,
      label: 'Subsidy Not Applicable',
    },
  ];

  const inProgressSatusOptions: ITableFilterOption[] = useMemo(() => {
    if (applicationFlowType === ApplicationFlowType.InquireProgramFormPayApprove) {
      return [
        {
          value: ApplicationState.Offered,
          label: 'Offered, Awaiting Form',
        },
        {
          value: ApplicationState.OfferedPendingAcceptance,
          label: 'Offered, Awaiting Acceptance',
        },
        {
          value: ApplicationState.AcceptedOfferPendingPayment,
          label: 'Accepted, Awaiting Payment',
        },
      ];
    }

    return [
      {
        value: ApplicationState.Offered,
        label: 'Awaiting Acceptance',
      },
      {
        value: ApplicationState.AcceptedOfferPendingPayment,
        label: 'Accepted, Awaiting Payment',
      },
      {
        value: ApplicationState.Accepted,
        label: 'Accepted, Awaiting Form',
      },
    ];
  }, [applicationFlowType]);

  const totalFiltersOn = useMemo(() => {
    return (
      (careTypeFilters?.length ?? 0 > 0 ? 1 : 0) +
      (daysFilter?.length ?? 0 > 0 ? 1 : 0) +
      (programFilters?.length ?? 0 > 0 ? 1 : 0) +
      (enrollmentSource === null || enrollmentSource === undefined ? 0 : 1) +
      (inProgressStatusFilter?.length ?? 0 > 0 ? 1 : 0) +
      (paidFilter === null || paidFilter === undefined ? 0 : 1) +
      (subsidyEligibilityFilter === null || subsidyEligibilityFilter === undefined ? 0 : 1) +
      (ageRangeFilters?.dateAsAt ? 1 : 0) +
      (onlyShowUpdatedForms ?? false ? 1 : 0)
    );
  }, [
    careTypeFilters?.length,
    daysFilter?.length,
    programFilters?.length,
    enrollmentSource,
    inProgressStatusFilter?.length,
    paidFilter,
    subsidyEligibilityFilter,
    ageRangeFilters?.dateAsAt,
    onlyShowUpdatedForms,
  ]);

  const handleClearFilters = () => {
    resetFilters();
    resetCenterFilters();
  };

  const backToPage1 = () => {
    if (tableState.activePage !== 1) {
      tableFunctions.changePage(1, tableState.pageSize);
    }
  };

  const minAndMaxAgeRangeByCenterServiceType = useMemo<[number, number]>(() => {
    let selectedServiceTypes: Array<CenterServiceType | null | undefined> = [];

    if (centerFilters && centerFilters.length > 0) {
      const selectedCenterIds = centerFilters.map((c) => c.value);
      selectedServiceTypes = centers
        .filter((center) => selectedCenterIds.includes(center.id))
        .map((center) => center.serviceType);
    } else {
      selectedServiceTypes = centers.map((center) => center.serviceType);
    }

    const uniqSelectedServiceTypes = uniq(selectedServiceTypes).filter((s) => Boolean(s));

    if (
      // if the center doesn't specify service type, we assume it's LDC + OSHC
      uniqSelectedServiceTypes.length > 0 &&
      uniqSelectedServiceTypes.includes('LDCOSHC')
    ) {
      return [0, 192]; // 0 to 16 years
    }

    if (uniqSelectedServiceTypes.length > 0 && uniqSelectedServiceTypes.every((c) => c === 'LDC')) {
      return [0, 72]; // 0 to 6 years
    }

    if (uniqSelectedServiceTypes.length > 0 && uniqSelectedServiceTypes.every((c) => c === 'OSHC')) {
      return [60, 192]; // 5 to 16 years
    }

    return [0, 192]; // 0 to 16 years
  }, [centers, centerFilters]);

  return (
    <>
      <TableHeader className="flex-wrap justify-content-between">
        <SizePerPage paginationProps={paginationProps} />
        <TableSearch
          className="max-width-200"
          onChange={(v) => {
            backToPage1();
            setSearchText(v);
          }}
          value={searchText}
          placeholder="Search"
          enableDebounce={false}
        />
        <div className="ml-auto d-flex align-items-center">
          <DropdownFilter
            title={capitalize(t('translation:spelling.center'))}
            selectedFilters={centerFilters ?? undefined}
            options={centerOptions}
            onFilterSelect={(v) => {
              backToPage1();
              setCenterFilters(v);
            }}
            className="mr-2 hello world"
          />
          <DropdownFilter
            title={capitalize(t('translation:spelling.tags'))}
            selectedFilters={tagFilters ?? undefined}
            options={tagOptions}
            onFilterSelect={(v) => {
              backToPage1();
              setTagFilters(v);
            }}
            className="mr-2"
          />
          <GroupedFilters totalFilters={totalFiltersOn} handleClearFilters={handleClearFilters}>
            <Col xs={6}>
              <DropdownFilter
                title={`${capitalize(t('translation:spelling.inquiry'))} Type`}
                selectedFilters={enrollmentSource ?? undefined}
                options={enrollmentSourceOptions}
                onFilterSelect={(value) => {
                  backToPage1();
                  handleSourceChange(value);
                }}
              />
            </Col>
            {applicationFlowType === ApplicationFlowType.InquireOfferEnrollment && (
              <Col xs={6}>
                <DropdownFilter
                  title="Care Type"
                  selectedFilters={careTypeFilters ?? undefined}
                  options={careTypeOptions}
                  onFilterSelect={(v) => {
                    backToPage1();
                    setCareTypeFilters(v);
                  }}
                  className="mb-0"
                />
              </Col>
            )}
            <Col xs={6}>
              <RadioGroupedDropdownFilter
                title="Days"
                selectedFilters={daysFilter ?? []}
                options={daysFilterOptions}
                onFilterSelect={(v) => {
                  backToPage1();
                  handleDaysFilterChange(v);
                }}
                className={classNames('mb-0 days-filter-dropdown', {
                  'no-top-margin': applicationFlowType === ApplicationFlowType.InquireOfferEnrollment,
                })}
              />
            </Col>
            {isProgramBasedFlow(applicationFlowType) && (
              <Col xs={6}>
                <DropdownFilter
                  title={capitalize(t('translation:spelling.program_plural'))}
                  selectedFilters={programFilters ?? undefined}
                  options={(programsData?.getEnrollmentPrograms && programsData?.getEnrollmentPrograms.data
                    ? programsData?.getEnrollmentPrograms.data
                    : []
                  ).map((p) => ({
                    label: p.name,
                    value: p.id,
                  }))}
                  onFilterSelect={(v) => {
                    backToPage1();
                    setProgramFilters(v);
                  }}
                />
              </Col>
            )}
            {activeTab === ApplicationStage.InProgress && (
              <>
                <Col xs={6}>
                  <DropdownFilter
                    title="Status"
                    selectedFilters={inProgressStatusFilter ?? undefined}
                    options={inProgressSatusOptions}
                    onFilterSelect={(v) => {
                      backToPage1();
                      setInProgressStatusFilter(v);
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <Select
                    className="mt-0 mb-0 select-filter"
                    placeholder="Application Fee"
                    options={paidFilterOptions}
                    value={paidFilter}
                    onChange={(v) => {
                      backToPage1();
                      setPaidFilter(v.value);
                    }}
                  />
                </Col>
              </>
            )}
            {isProgramBasedFlow(applicationFlowType) && (
              <Col xs={6}>
                <Select
                  className="mt-0 mb-0 select-filter"
                  placeholder="Subsidy"
                  options={SubsidyFilterOptions}
                  value={subsidyEligibilityFilter}
                  onChange={(v) => {
                    backToPage1();
                    setSubsidyEligibilityFilter(v.value);
                  }}
                />
              </Col>
            )}
            <Col xs={6}>
              <AgeRangeSlider
                onChange={(v, date) => {
                  backToPage1();
                  setAgeRangeFilters({
                    min: v[0],
                    max: v[1],
                    dateAsAt: date,
                  });
                }}
                value={ageRangeFilters ? [ageRangeFilters?.min ?? 0, ageRangeFilters?.max ?? 0] : null}
                min={minAndMaxAgeRangeByCenterServiceType[0]}
                max={minAndMaxAgeRangeByCenterServiceType[1]}
              />
            </Col>
            {k2EnrolFormDifference && (
              <Col xs={12}>
                <div className="d-flex justify-content-start align-items-start">
                  <Checkbox
                    label={'Show Forms With Updated Responses Only'}
                    value={onlyShowUpdatedForms ?? false}
                    onChange={(v) => {
                      if (v) {
                        setOnlyShowUpdatedForms(v);
                      } else {
                        setOnlyShowUpdatedForms(null);
                      }
                    }}
                  />
                  <HelpTooltip
                    text={'Turning on this option will show where updated form responses have been detected only.'}
                  />
                </div>
              </Col>
            )}
          </GroupedFilters>
        </div>
      </TableHeader>
    </>
  );
};

export default LeadTableHeader;
