import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';
import { useGetPayrixDisbursementReportPdfLazyQuery, GetPayrixDisbursementReportInput } from 'generated/graphql';

type UseGetPayrixDisbursementReportPdfReturnType = [(input: GetPayrixDisbursementReportInput) => void, boolean];

const useGetPayrixDisbursementReportPdf = (onCompleted?: () => void): UseGetPayrixDisbursementReportPdfReturnType => {
  const { t } = useTranslation(['translation']);

  const [getPayrixDisbursementReportPdf, { loading }] = useGetPayrixDisbursementReportPdfLazyQuery({
    onCompleted: (result) => {
      window.open(result.getPayrixDisbursementReportPdf);

      if (onCompleted) {
        onCompleted();
      }
    },
    onError: (error) => {
      showToast(t('translation:reports.general-failure-message'), 'error');
    },
  });

  const downloadReport = useCallback(
    (input: GetPayrixDisbursementReportInput) => {
      getPayrixDisbursementReportPdf({
        variables: {
          input,
        },
      });
    },
    [getPayrixDisbursementReportPdf]
  );

  return [downloadReport, loading];
};

export default useGetPayrixDisbursementReportPdf;
