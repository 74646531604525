import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import Checkbox from 'shared/components/Checkbox';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { capitalize } from 'shared/util/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import BaseSelect from '../../../shared/components/Select/Base';
import { useGetClassesForCenters } from '../../Centers/subroutes/Classes/graphql/queries';
import { useSelector } from 'react-redux';
import { getAllClasses } from '../../Centers/subroutes/Classes/duck/selectors';
import { useGetActiveClassesByCenterAndDate } from 'gql/class/queries';

interface IFormStateShape {
  centerIds: string[];
  format: 'EXCEL' | 'PDF';
  includeActiveAccounts: boolean;
  includeInactiveAccounts: boolean;
  selectedClasses?: Array<{ value: string; label: string }> | null;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  showFormats?: boolean;
  onSubmit: (centerIds: string[], format: 'EXCEL' | 'PDF', statusType?: AccountStatusType, classIds?: string[]) => void;
  onClose: () => void;
  hasAccountStatusOption?: boolean;
  showClasses?: boolean;
}

const ReportFormatMultipleCenterReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  showFormats = true,
  onSubmit,
  onClose,
  hasAccountStatusOption,
  showClasses,
  ...props
}) => {
  const [formData, setFormData] = useState<IFormStateShape>({
    centerIds: [],
    format: 'EXCEL',
    includeActiveAccounts: true,
    includeInactiveAccounts: false,
    selectedClasses: [],
  });

  const [classSelectOptions, setClassSelectOptions] = useState<Array<{ label: string; value: string }>>([]);
  const { loading: classesLoading } = useGetClassesForCenters(formData.centerIds[0] ?? '');
  const classes = useSelector(getAllClasses);

  const { t } = useTranslation();
  const ALL_CLASS_OPTIONS = { value: 'All', label: `All ${capitalize(t('spelling.class'))}es` };

  useEffect(() => {
    let result = [...classes];
    result.sort((a, b) => a.name.localeCompare(b.name));
    setClassSelectOptions(result.map((item) => ({ label: item.name, value: item.id })));
  }, [classes, setClassSelectOptions]);

  useEffect(() => {
    if (!isOpen) {
      setFormData({ centerIds: [], format: 'EXCEL', includeActiveAccounts: true, includeInactiveAccounts: false });
    }
  }, [isOpen]);

  const accountStatusType = useMemo(() => {
    if (formData.includeActiveAccounts && formData.includeInactiveAccounts) return undefined;
    if (formData.includeActiveAccounts) return 'Active';
    if (formData.includeInactiveAccounts) return 'Inactive';
    return undefined;
  }, [hasAccountStatusOption, formData]);

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (formData.format && formData.centerIds?.length) {
      // @ts-ignore
      onSubmit(
        formData.centerIds,
        formData.format,
        accountStatusType,
        (formData.selectedClasses || []).map((cl) => cl.value)
      );
    }
  }, [formData, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({ centerIds: [], format: 'EXCEL', includeActiveAccounts: true, includeInactiveAccounts: false });
    onClose();
  }, [onClose]);

  const isAllClassesSelected = useCallback(
    (): boolean =>
      !!formData.selectedClasses &&
      formData.selectedClasses.length > 0 &&
      formData.selectedClasses.length === classSelectOptions.length,
    [formData.selectedClasses, classSelectOptions]
  );

  const getSelectedValue = useCallback(() => {
    return isAllClassesSelected()
      ? [ALL_CLASS_OPTIONS]
      : classSelectOptions.filter(
          (o) => !!formData.selectedClasses && formData.selectedClasses.map((i) => i.value).includes(o.value)
        );
  }, [isAllClassesSelected, classSelectOptions, formData.selectedClasses]);

  const handleClassSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_CLASS_OPTIONS.value) {
        setFormData({ ...formData, selectedClasses: classSelectOptions });
      } else if (
        (action === 'deselect-option' && option.value === ALL_CLASS_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_CLASS_OPTIONS.value)
      ) {
        setFormData({ ...formData, selectedClasses: [] });
      } else if (action === 'deselect-option' && isAllClassesSelected()) {
        setFormData({
          ...formData,
          selectedClasses: (formData.selectedClasses || []).filter((i) => i.value !== option.value),
        });
      } else {
        setFormData({ ...formData, selectedClasses: (options || []).filter((opt) => opt.value !== null) });
      }
    },
    [classSelectOptions, formData.selectedClasses, isAllClassesSelected]
  );

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{ disabled: !formData.format || !formData.centerIds?.length, loading: isLoading }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            isRequired
            selectedCenterIds={formData.centerIds}
            onSelect={(ids) => setFormData((prev) => ({ ...prev, centerIds: ids as string[] }))}
          />
        </Col>
      </Row>
      {showFormats && (
        <Row>
          <Col>
            <Form.Label>{capitalize(t('spelling.format'))}(s)</Form.Label>
            <Select
              options={[
                { value: 'EXCEL', label: 'EXCEL' },
                { value: 'PDF', label: 'PDF' },
              ]}
              value={formData.format ?? { value: 'EXCEL', label: 'EXCEL' }}
              onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Col>
        </Row>
      )}
      {showClasses && formData.centerIds.length < 2 && (
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                {capitalize(t('spelling.class'))}{' '}
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              </Form.Label>
              <BaseSelect
                // @ts-ignore
                isMulti
                required
                id="class-input"
                isLoading={classesLoading}
                aria-label={`Select ${capitalize(t('spelling.class'))}`}
                // @ts-ignore
                value={getSelectedValue()}
                options={[ALL_CLASS_OPTIONS, ...classSelectOptions]}
                // @ts-ignore
                onChange={(options, action) => handleClassSelect(options as any[], action)}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {hasAccountStatusOption && (
        <>
          <Row className="pl-2">{t('spelling.accountStatusFilter')}</Row>
          <Row className="pl-2">
            <Checkbox
              label={capitalize(t('spelling.active'))}
              value={formData.includeActiveAccounts}
              onChange={(value) => setFormData({ ...formData, includeActiveAccounts: value })}
            />
          </Row>
          <Row className="pl-2">
            <Checkbox
              label={capitalize(t('spelling.inactive'))}
              value={formData.includeInactiveAccounts}
              onChange={(value) => setFormData({ ...formData, includeInactiveAccounts: value })}
            />
          </Row>
        </>
      )}
    </SideModalDrawer>
  );
};

export default ReportFormatMultipleCenterReportModal;
