import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import weekDayOptions from 'shared/constants/enums/weekDayOptions';
interface IProps {
  contract: ICreateContractInput;
  updateContract: React.Dispatch<React.SetStateAction<ICreateContractInput>>;
  week?: WeekType;
  className?: string;
}

const WeeklyTimeSlotsInputs: React.FC<IProps> = ({ contract, updateContract, week, className }) => {
  const doesTimeSlotMatchDay = useCallback(
    (day: WeekDay, timeSlot: IContractTimeslot | ICreateContractTimeslotInput) =>
      timeSlot.day === day && (!week || week === timeSlot.week),
    [week]
  );

  const isDaySelected = useCallback(
    (day: WeekDay) => contract.timeslots.some((ts) => doesTimeSlotMatchDay(day, ts)),
    [contract.timeslots, doesTimeSlotMatchDay]
  );

  return (
    <div className={className}>
      <ButtonGroup>
        {weekDayOptions.map((day, i) => (
          <Button
            key={i}
            className="px-4"
            variant={isDaySelected(day.value) ? 'secondary' : 'light'}
            onClick={() => {
              isDaySelected(day.value)
                ? updateContract({
                    ...contract,
                    timeslots: contract.timeslots.filter((ts) => !doesTimeSlotMatchDay(day.value, ts)),
                  })
                : updateContract({
                    ...contract,
                    timeslots: [
                      ...contract.timeslots,
                      {
                        day: day.value,
                        dropOffTime: undefined,
                        pickUpTime: undefined,
                        week,
                      },
                    ],
                  });
            }}
          >
            {day.label}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default WeeklyTimeSlotsInputs;
