import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateTransactionTypeBulk } from 'gql/transaction/mutations';
import { showToast } from 'shared/components/Toast';
import { createTransactionTypes } from 'pages/Billing/Settings/duck/actions';
import { useGetRolesForBusinessThatUserCanManage } from 'shared/hooks/useGetRolesForBusiness';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import useIsGlCodeInputValid from 'shared/hooks/useIsGlCodeInputValid';
import RightModalDrawer from 'shared/components/MUI/RightModalDrawer';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GlCodesDropdown from 'shared/components/MUI/GlCodesDropdown';
import { isRegion } from 'shared/util/region';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import LoadingButton from '@mui/lab/LoadingButton';

import i18n from 'i18n';
import { capitalize } from 'lodash';
import { CurrencyTrans } from 'shared/components/Currency';

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

interface IRoleOptions {
  value: string;
  label: string;
}

interface INewTransactionTypeShape {
  name: string;
  isDebit?: boolean;
  glCode: string | null;
  roles: IRoleOptions[] | [] | undefined;
  centers: ICenter[] | null;
  isTuition: boolean;
  glCodeMapping?: IGLCodeMapping | null;
}

interface IProps {
  isOpen: boolean;
  businessId: string | null;
  centers: ICenter[];
  onClose: () => void;
  refetch: () => void;
}

const CreateNewTransaction: React.FC<IProps> = ({ isOpen, businessId, centers, onClose, refetch, ...props }) => {
  const { t } = useTranslation(['translation']);
  const dispatch = useDispatch();
  const { k2GlCodeActivationCheck } = useFlags();
  const currentUser = useSelector((state: RootState) => state.user);
  const { data: roles, loading } = useGetRolesForBusinessThatUserCanManage(businessId ?? '', currentUser);
  const roleOptions = roles.map((role) => ({ value: role.id, label: role.name }));
  const [formData, setFormData] = useState<INewTransactionTypeShape[]>([
    { name: '', glCode: null, roles: [], centers: [], isTuition: false, glCodeMapping: null },
  ]);
  const [selectedCenters, setSelectedCenters] = React.useState<ICenter[] | null>(null);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [createTransactionTypeFn, { loading: createTransactionTypeLoading }] = useCreateTransactionTypeBulk({
    onCompleted: (result) => {
      handleClose();
      refetch();
      showToast(
        t('translation:billing.settings.transactions.transactionTypeCreatedSuccessfully', {
          count: result.createTransactionTypesInBulk.length,
        }),
        'success'
      );
      dispatch(createTransactionTypes(result.createTransactionTypesInBulk));
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const handleUpdate = useCallback((updates: INewTransactionTypeShape, idx: number) => {
    setFormData((prev) => prev.map((current, i) => (i === idx ? updates : current)));
  }, []);

  const handleSubmit = () => {
    if (businessId) {
      createTransactionTypeFn({
        variables: {
          input: {
            businessId,
            centerIds: selectedCenters?.length != centers.length ? selectedCenters?.map((c) => c.id) ?? null : null,
            transactionTypes: formData.map((type) => ({
              name: type.name,
              isDebit: type.isDebit as boolean,
              glCode: type.glCode,
              roleIds:
                type.roles === undefined
                  ? undefined
                  : type.roles.length > 0
                  ? type.roles.map((x) => x.value)
                  : undefined,
              isTuition: type.isTuition,
              glCodeMapping: type.glCodeMapping,
            })),
          },
        },
      });
    }
  };

  const handleClose = () => {
    setFormData([{ name: '', glCode: null, roles: [], centers: [], isTuition: false, glCodeMapping: null }]);
    setSelectedCenters([]);
    onClose();
  };

  // passing (null, null) because we need to use returned validateGlCodeInputFn for gl Code validation purpose for this modal
  // because the formData is an array of INewTransactionTypeShape, so can't use the useIsGlCodeInputValid hook directly
  const { isGlCodeMandatory, validateGlCodeInputFn } = useIsGlCodeInputValid(null, null);

  const formIsValid = useCallback(
    () =>
      formData.every(
        (type) =>
          type.name.trim() !== '' &&
          type.isDebit !== undefined &&
          validateGlCodeInputFn(type.glCode, type.glCodeMapping)
      ),
    [formData, validateGlCodeInputFn]
  );

  const radioButtonControlLabel = (formValue: string) => {
    return (
      <Mui.FormControlLabel
        value={formValue}
        style={{ margin: 0 }}
        control={<Mui.Radio size="small" style={{ margin: 0 }} />}
        label={<CurrencyTrans>{formValue}</CurrencyTrans>}
      />
    );
  };

  return (
    <>
      <RightModalDrawer
        open={isOpen}
        onClose={handleClose}
        title={t('translation:billing.settings.transactions.addTransactionType')}
      >
        {formData.map((type: INewTransactionTypeShape, idx: number) => (
          <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '16px' }}>
            <Mui.Stack direction="column" spacing={2} marginLeft="16px">
              <Mui.FormControlLabel
                label={
                  <Mui.FormLabel id="transaction-type-name" sx={{ marginBottom: 0 }}>
                    <span>
                      {t('translation:billing.settings.transactions.typeName')}
                      <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
                    </span>
                  </Mui.FormLabel>
                }
                labelPlacement="top"
                control={
                  <Mui.TextField
                    size="small"
                    value={type.name}
                    required
                    onChange={(evt) => handleUpdate({ ...type, name: evt.target.value }, idx)}
                    fullWidth
                    sx={styles.inputStyles}
                  />
                }
                componentsProps={{
                  typography: {
                    alignSelf: 'flex-start',
                    marginBottom: '0.5rem',
                    fontSize: '0.875rem',
                    color: '#000000',
                  },
                }}
                style={{ margin: 0 }}
              />

              <Mui.FormLabel id="transaction-type-radio-group">
                <span>
                  {t('translation:billing.settings.transactions.typeOfTransaction')}
                  <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
                </span>
              </Mui.FormLabel>
              <Mui.RadioGroup
                value={type.isDebit}
                aria-labelledby="transaction-type-radio-group"
                name="transaction-type-radio-buttons-group"
                onChange={(evt) => handleUpdate({ ...type, isDebit: evt.target.value === 'Debit' ? true : false }, idx)}
                style={{ margin: 0 }}
              >
                {radioButtonControlLabel('Debit')}
                {radioButtonControlLabel('Credit')}
              </Mui.RadioGroup>

              {isRegion('US') && (
                <>
                  <Mui.FormLabel id="tuition-radio-group">
                    <span>
                      Is this tuition?
                      <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
                    </span>
                  </Mui.FormLabel>
                  <Mui.RadioGroup
                    value={type.isTuition ? 'Yes' : 'No'}
                    aria-labelledby="tuition-radio-group"
                    defaultValue="No"
                    name="tuition-buttons-group"
                    onChange={(evt) =>
                      handleUpdate({ ...type, isTuition: evt.target.value === 'Yes' ? true : false }, idx)
                    }
                    style={{ margin: 0 }}
                  >
                    {radioButtonControlLabel('Yes')}
                    {radioButtonControlLabel('No')}
                  </Mui.RadioGroup>
                </>
              )}
              <SelectMultiple
                required
                label={capitalize(i18n.t('translation:spelling.center'))}
                newLabelStyle
                onChange={(prev) => setSelectedCenters(prev)}
                options={centers}
                value={selectedCenters}
                getOptionLabel={(option: ICenter) => option.name}
                getOptionValue={(option: ICenter) => option.id}
                displayCountInContainerRenderer={(selectedCount) =>
                  selectedCount > 0
                    ? selectedCount +
                      ' ' +
                      (selectedCount === 1
                        ? capitalize(i18n.t('translation:spelling.center'))
                        : capitalize(i18n.t('translation:spelling.center_plural'))) +
                      ' Selected'
                    : `Select a ${capitalize(i18n.t('translation:spelling.center'))}`
                }
              />
              <SelectMultiple
                required
                label={t('translation:billing.settings.transactions.roles')}
                newLabelStyle
                onChange={(value) =>
                  handleUpdate(
                    {
                      ...type,
                      roles: value ?? undefined,
                    },
                    idx
                  )
                }
                options={roleOptions.sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []}
                value={type.roles ?? []}
                getOptionLabel={(option: IRoleOptions) => option.label}
                getOptionValue={(option: IRoleOptions) => option.value}
                displayCountInContainerRenderer={(selectedCount) =>
                  selectedCount > 0
                    ? selectedCount + ' ' + (selectedCount === 1 ? `Role` : `Roles`) + ' Selected'
                    : `All Roles`
                }
              />
              {k2GlCodeActivationCheck ? (
                <GlCodesDropdown
                  required={isGlCodeMandatory}
                  glCodeMapping={type.glCodeMapping}
                  onChange={(glCode) => {
                    handleUpdate(
                      {
                        ...type,
                        glCode: glCode?.glCode ?? null,
                        glCodeMapping: {
                          glCodeId: glCode?.id ?? '',
                        },
                      },
                      idx
                    );
                  }}
                  areaType="CUSTOM_TRANSACTION_TYPE"
                />
              ) : (
                <Mui.FormControlLabel
                  label={t('translation:billing.settings.transactions.glCode')}
                  labelPlacement="top"
                  control={
                    <Mui.TextField
                      size="small"
                      value={type.glCode}
                      required
                      onChange={(evt) => handleUpdate({ ...type, glCode: evt.target.value.replace(/[\W_]/g, '') }, idx)}
                      fullWidth
                      sx={styles.inputStyles}
                    />
                  }
                  componentsProps={{
                    typography: {
                      alignSelf: 'flex-start',
                      marginBottom: '0.5rem',
                      fontSize: '0.875rem',
                      color: '#000000',
                    },
                  }}
                  style={{ margin: 0 }}
                />
              )}
            </Mui.Stack>
          </Mui.DialogContent>
        ))}
        <Mui.Box style={{ margin: '8px' }}>
          <Mui.DialogActions>
            <Mui.Button variant="cancel" onClick={() => handleClose()}>
              Cancel
            </Mui.Button>
            <LoadingButton
              disabled={!formIsValid()}
              loading={createTransactionTypeLoading}
              color="secondary"
              variant="contained"
              onClick={() => handleSubmit()}
              id="modal-drawer-primary-btn"
            >
              Save
            </LoadingButton>
          </Mui.DialogActions>
        </Mui.Box>
      </RightModalDrawer>
    </>
  );
};

export default CreateNewTransaction;
