export const busRosterFields = `
  id
  businessId
  centerId
  name
  effectiveDate
  endDate
  type
  center {
    id
    name
  }
  destinations {
      id
      name
  }
`;

export const busRosterAllFields = `
  id
  businessId
  centerId
  name
  effectiveDate
  endDate
  type
  center {
    id
    name
  }
  destinations {
    id
    name
  }
  children {
    id
    accountChildId
    busRosterDestinationId
    type
    firstName
    lastName
    avatar {
      url
    }
    destination {
      id
      name
    }
    scheduleSlots {
      id
      day
      weekType
    }
  }
`;

export const busRosterAvailableChildrenFields = `
  id
  childName
  classNames
  classIds
  childAvatar {
    url
  },
  expectedScheduleSlots {
    weekType
    days
  }
`;
