import {
  GET_TRANSACTIONS,
  CREATE_TRANSACTION,
  CREATE_REVERSE_TRANSACTION,
  UPDATE_TRANSACTION,
  TransactionActionTypes,
  UPDATE_PAYMENT,
  APPLY_DISCOUNT_TO_TRANSACTION,
  REMOVE_APPLIED_DISCOUNT_TO_TRANSACTION,
  GET_APPLIED_DISCOUNT_TRANSACTIONS,
} from './types';

export const getTransactions = (
  transactions: ITransaction[],
  openingBalance?: number | undefined,
  closingBalance?: number | undefined
): TransactionActionTypes => ({
  type: GET_TRANSACTIONS,
  transactions,
  openingBalance,
  closingBalance,
});

export const createTransaction = (transaction: ITransaction): TransactionActionTypes => ({
  type: CREATE_TRANSACTION,
  transaction,
});

export const updateTransaction = (transaction: ITransaction): TransactionActionTypes => ({
  type: UPDATE_TRANSACTION,
  transaction,
});

export const createReverseTransaction = (transaction: ITransaction): TransactionActionTypes => ({
  type: CREATE_REVERSE_TRANSACTION,
  transaction,
});

export const updatePayment = (newTransaction: ITransaction, oldPaymentId: string): TransactionActionTypes => ({
  type: UPDATE_PAYMENT,
  newTransaction,
  oldPaymentId,
});

export const applyDiscountToTransaction = (
  discountedTransactionId: string,
  discount: ITransaction
): TransactionActionTypes => ({
  type: APPLY_DISCOUNT_TO_TRANSACTION,
  discountedTransactionId,
  discount,
});

export const removeAppliedDiscountToTransaction = (
  discountedTransactionId: string,
  discount: ITransaction
): TransactionActionTypes => ({
  type: REMOVE_APPLIED_DISCOUNT_TO_TRANSACTION,
  discountedTransactionId,
  discount,
});

export const getAppliedDiscountTransactions = (
  transactionId: string,
  appliedDiscounts: ITransaction[]
): TransactionActionTypes => ({
  type: GET_APPLIED_DISCOUNT_TRANSACTIONS,
  transactionId,
  appliedDiscounts,
});
