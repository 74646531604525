import React from 'react';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import CreateBillingFeesInformationCard from './BillingFeesInformation';
import CreateBillingFeesSearchCard from './BillingFeesSearchCard';

interface IProps {}

const BillingFeesTab: React.FC<IProps> = ({ ...props }) => {
  return (
    <>
      <HasRoleAreaLevel
        action={{ area: AreaType.Billing, permission: PermissionType.Surcharges, level: RoleLevelType.Read }}
      >
        <CreateBillingFeesInformationCard />
        <CreateBillingFeesSearchCard />
      </HasRoleAreaLevel>
    </>
  );
};

export default BillingFeesTab;
