import { useGetCustomFieldValues } from 'gql/customField/queries';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { CreateButton } from 'shared/components/Buttons';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { TimezoneContext } from 'shared/contexts/timezoneContext';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { isRegion } from 'shared/util/region';
import { RootState } from 'store/reducers';
import AddCenterToAccountModal from './AddCenterToAccountModal';
import ProfileTab from './ProfileTab';
import AccountCustomFieldsTab from './Tabs/AccountCustomFieldsTab';
import AgencyTab from './Tabs/AgencyTab';
import BillingTab from './Tabs/BillingTab/BillingTab';
import CcsTab from './Tabs/CcsTab';
import ContactsTab from './Tabs/ContactsTab/ContactsTab';
import DocumentsTab from './Tabs/DocumentsTab';
import EnrollmentTab from './Tabs/EnrollmentTab';
import InternalNotes from './Tabs/InternalNotesTab/InternalNotes';
import { showToast } from 'shared/components/Toast';
import MessagingTab from './Tabs/MessagingTab';
import USBillingTab from 'US/Billing/Account/BillingTab/BillingTab';
import { useGetCommsAllCenterSettingsQuery } from 'generated/graphql';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { useGetAccountPageData } from './queries/useGetAccountPageData';

const BROWSER_TIMEZONE = moment.tz.guess() as Timezone;

type AccountProfileTab =
  | 'profile'
  | 'enrollment'
  | 'ccs'
  | 'billing'
  | 'contacts'
  | 'custom-fields'
  | 'internal-notes'
  | 'messaging';

interface IRouteParams {
  id: string;
  activeTab: AccountProfileTab;
  subtab: string;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const Account: React.FC<IProps> = ({ ...props }) => {
  const { id: accountId, activeTab, subtab } = props.match.params;

  const hasViewEnrollmentPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasViewContractPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.Contracts,
    level: RoleLevelType.Read,
  });
  const hasCreateAccountPermission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });
  const hasViewContactsPermission = useHasRoleAreaLevel({
    area: AreaType.Contact,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasReadBaseTransactionPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasReadAccountBillingCyclePermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.AccountBillingCycles,
    level: RoleLevelType.Read,
  });
  const hasReadPaymentsPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Read,
  });
  const hasReadBillingPaymentMethodsPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.PaymentMethods,
    level: RoleLevelType.Read,
  });
  const hasReadDiscountsPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Discount,
    level: RoleLevelType.Read,
  });
  const hasReadProgramsAgencyBasePermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasReadChildAgencyPermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.AgencyChild,
    level: RoleLevelType.Read,
  });
  const hasAccountInternalNotesPermission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.InternalNotes,
    level: RoleLevelType.Read,
  });
  const hasAccountReadPermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.AccountMessaging,
    level: RoleLevelType.Read,
  });
  const hasAccountWritePermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.AccountMessaging,
    level: RoleLevelType.Edit,
  });
  const hasAccountDeletePermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.AccountMessaging,
    level: RoleLevelType.Delete,
  });

  const { t } = useTranslation('accounts');

  const history = useHistory();

  const isAuRegion = isRegion('AU');

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const usingComms = useSelector((state: RootState) => state.comms.usesComms);
  const usingAccountMessagingByBusiness =
    useSelector((state: RootState) => state.comms.all?.usesAccountChannels) ?? false;
  const [usingAccountMessagingByAccount, setUsingAccountMessagingByAccount] = useState(false);
  const centerTimezones = useSelector((state: RootState) => state.timezone.byCenterId);
  const [showArchived, setShowArchived] = useState(false);
  const [showArchivedChildren, setShowArchivedChilden] = useState(false);
  const [accountDataErrors, setAccountErrors] = useState(false);

  const { account, loading } = useGetAccountPageData(accountId, {
    onError: () => {
      setAccountErrors((accountDataErrors) => !accountDataErrors);
      showToast('Something went wrong. Could not load all account data. Please try reloading the page.', 'error');
    },
  });

  const [showAddCenterToAccountModal, setShowAddCenterToAccountModal] = useState(false);

  const {
    data,
    loading: customFieldLoading,
    refetch,
  } = useGetCustomFieldValues({
    variables: {
      input: {
        businessId: account?.entityId,
        centerIds: [account?.centerId],
        entityId: account?.id,
        area: 'Account',
        isArchived: showArchived,
      },
    },
    skip: isEmpty(account),
  });

  const customFields = data?.getCustomFieldValues.customFields ?? [];

  const { data: commsCenterData } = useGetCommsAllCenterSettingsQuery({
    skip: !account || !account?.entityId || !account.centerId,
    variables: {
      businessId: account?.entityId ?? '',
      centerId: [account?.centerId] ?? [''],
    },
  });

  useEffect(() => {
    if (account && account?.entityId && account.centerId && commsCenterData) {
      setUsingAccountMessagingByAccount(
        commsCenterData?.getCommsAllCenterSettings?.data[0]?.usesAccountChannels ?? false
      );
    }
  }, [account, commsCenterData]);

  /**
   * Determine what button to render as part of the page wrapper
   */
  const renderPageWrapperButton = useCallback((): JSX.Element | null => {
    if (hasCreateAccountPermission) {
      return (
        <CreateButton
          variant="outline-secondary"
          onClick={() => setShowAddCenterToAccountModal(true)}
          className="ml-auto"
        >
          Add {fieldLabels.center}
        </CreateButton>
      );
    }
    return null;
  }, [hasCreateAccountPermission, fieldLabels.center]);

  const sortChildren = useCallback((children: IAccountChild[]) => {
    return [...children].sort(
      (a, b) => new Date(b.dob).getTime() - new Date(a.dob).getTime() || a.fullName?.localeCompare(b.fullName)
    );
  }, []);

  const sortedChildren = useMemo(() => {
    return sortChildren(account?.children ?? []);
  }, [account?.children, sortChildren]);

  const handleTabSelect = useCallback(
    (tab: string | null) => {
      history.push(`/families/accounts/${accountId}/${tab}`);
    },
    [accountId, history]
  );

  const handleSubTabSelect = useCallback(
    (subtab: string | null) => {
      let path = `/families/accounts/${accountId}/${activeTab}`;
      if (subtab) path += `/${subtab}`;
      history.push(path);
    },
    [accountId, activeTab, history]
  );

  return (
    <ProfilePageWrapper
      title={`${account?.name} Account`}
      subtitle={`${account?.center?.name ?? ''}, ${account?.center?.address?.city ?? ''} ${
        account?.center?.address?.state ?? ''
      }`}
      subtitleComponent={<AccountIdDisplay account={account} loading={loading} />}
      loading={loading}
      showAvatar={false}
      buttonComponent={renderPageWrapperButton()}
      applyPadding={false}
    >
      <Stack
        direction="row"
        justifyContent={'space-between'}
        sx={{
          paddingLeft: '36px',
          paddingRight: '36px',
          position: 'sticky',
          top: '158px',
          zIndex: 900,
          backgroundColor: 'white',
          borderBottom: '1px solid #e2e7ee',
        }}
      >
        <Button
          disabled={loading || !account?.searchPosition?.previous}
          onClick={() => {
            if (!account?.searchPosition?.previous) return;
            const replacementPath = history.location.pathname.replace(accountId, account.searchPosition.previous);
            history.push(replacementPath);
          }}
          size="small"
          startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        >
          Previous Account
        </Button>
        <Button
          disabled={loading || !account?.searchPosition?.next}
          onClick={() => {
            if (!account?.searchPosition?.next) return;
            const replacementPath = history.location.pathname.replace(accountId, account.searchPosition.next);
            history.push(replacementPath);
          }}
          size="small"
          endIcon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          Next Account
        </Button>
      </Stack>
      <PageWrapperBody>
        <TimezoneContext.Provider
          value={{
            timezone: account ? centerTimezones[account.centerId] ?? BROWSER_TIMEZONE : BROWSER_TIMEZONE,
          }}
        >
          <div className="mx-auto profile-page-width">
            <Tabs id="account-tabs" activeKey={activeTab} onSelect={handleTabSelect}>
              <Tab eventKey="profile" title="Profile">
                <ProfileTab
                  account={account}
                  loading={loading}
                  showArchivedChildren={showArchivedChildren}
                  setShowArchivedChilden={setShowArchivedChilden}
                />
              </Tab>
              {hasViewContactsPermission && (
                <Tab eventKey="contacts" title="Contacts">
                  <ContactsTab account={account} loading={loading} />
                </Tab>
              )}
              {(hasViewEnrollmentPermission || hasViewContractPermission) && (
                <Tab eventKey="enrollment" title={t('enrollment.enrollment-tab.title')}>
                  <EnrollmentTab
                    children={sortedChildren ?? []}
                    centerId={account?.centerId ?? ''}
                    accountId={account?.id ?? ''}
                    showArchivedChildren={showArchivedChildren}
                    setShowArchivedChilden={setShowArchivedChilden}
                  />
                </Tab>
              )}
              {isAuRegion && (
                <Tab eventKey="ccs" title="CCS">
                  <CcsTab account={account} loading={loading} />
                </Tab>
              )}
              {isRegion('US') &&
                (hasReadBaseTransactionPermissions ||
                  hasReadAccountBillingCyclePermissions ||
                  hasReadPaymentsPermissions ||
                  hasReadBillingPaymentMethodsPermission ||
                  hasReadDiscountsPermission) && (
                  <Tab eventKey="billing" title="Billing" mountOnEnter={true}>
                    <USBillingTab
                      activeTab={subtab}
                      onActiveTabChange={handleSubTabSelect}
                      account={account}
                      loading={loading}
                      accountId={accountId}
                      hasReadBaseTransactionPermissions={hasReadBaseTransactionPermissions}
                      hasReadAccountBillingCyclePermissions={hasReadAccountBillingCyclePermissions}
                      hasReadPaymentsPermissions={hasReadPaymentsPermissions}
                      hasReadBillingPaymentMethodsPermission={hasReadBillingPaymentMethodsPermission}
                      hasReadBillingDiscountPermissions={hasReadDiscountsPermission}
                      accountDataErrors={accountDataErrors}
                    />
                  </Tab>
                )}
              {isRegion('AU') &&
                (hasReadBaseTransactionPermissions ||
                  hasReadAccountBillingCyclePermissions ||
                  hasReadPaymentsPermissions ||
                  hasReadBillingPaymentMethodsPermission ||
                  hasReadDiscountsPermission) && (
                  <Tab mountOnEnter eventKey="billing" title="Billing">
                    <BillingTab
                      activeTab={subtab}
                      onActiveTabChange={handleSubTabSelect}
                      account={account}
                      loading={loading}
                      accountId={accountId}
                      hasReadBaseTransactionPermissions={hasReadBaseTransactionPermissions}
                      hasReadAccountBillingCyclePermissions={hasReadAccountBillingCyclePermissions}
                      hasReadPaymentsPermissions={hasReadPaymentsPermissions}
                      hasReadBillingPaymentMethodsPermission={hasReadBillingPaymentMethodsPermission}
                      hasReadBillingDiscountPermissions={hasReadDiscountsPermission}
                      accountDataErrors={accountDataErrors}
                    />
                  </Tab>
                )}
              {(hasReadProgramsAgencyBasePermission || hasReadChildAgencyPermission) && (
                <Tab eventKey="agency" title="Agency">
                  <AgencyTab account={account} loading={loading} />
                </Tab>
              )}
              <Tab eventKey="documents" title={t('documents.documents-tab-title')}>
                {account && <DocumentsTab account={account} loading={loading} />}
              </Tab>

              <Tab eventKey="custom-fields" title="Custom Data Fields">
                {account && (
                  <AccountCustomFieldsTab
                    account={account}
                    loading={loading || customFieldLoading}
                    refetch={refetch}
                    customFields={customFields}
                    values={data?.getCustomFieldValues?.values ?? []}
                    onShowArchivedChange={setShowArchived}
                    showArchived={showArchived}
                  />
                )}
              </Tab>
              {hasAccountInternalNotesPermission && (
                <Tab eventKey="internal-notes" title="Internal Notes">
                  <InternalNotes
                    accountId={accountId}
                    children={account?.children ?? []}
                    contacts={account?.contacts ?? []}
                  />
                </Tab>
              )}
              {hasAccountReadPermission &&
                account &&
                usingComms &&
                (usingAccountMessagingByBusiness || usingAccountMessagingByAccount) && (
                  <Tab eventKey="messaging" title="Messaging">
                    {activeTab === 'messaging' && (
                      <MessagingTab
                        hasAccountReadPermission={hasAccountReadPermission}
                        hasAccountWritePermission={hasAccountWritePermission}
                        hasAccountDeletePermission={hasAccountDeletePermission}
                        accountId={account.id}
                      />
                    )}
                  </Tab>
                )}
            </Tabs>
            {account && !loading && (
              <AddCenterToAccountModal
                isOpen={showAddCenterToAccountModal}
                onClose={() => setShowAddCenterToAccountModal(false)}
                account={account}
              />
            )}
          </div>
        </TimezoneContext.Provider>
      </PageWrapperBody>
    </ProfilePageWrapper>
  );
};

const AccountIdDisplay: React.FC<{ account: IAccount | undefined; loading?: boolean }> = ({
  account,
  loading = false,
}) => {
  return (
    <div className="d-flex align-items-center">
      <Typography variant="small" minWidth="15%" id="profile-page-wrapper-subtitle">
        {loading ? <Skeleton /> : `Account Id: ${account?.surrogateKey}`}
      </Typography>
    </div>
  );
};

export default Account;
