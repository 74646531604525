import React, { useState, useCallback, useEffect } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { isBlank } from 'shared/util/string';
import errorMessage from 'shared/constants/errorMessages';
import { useCreateCustomFields } from 'pages/Businesses/subroutes/BusinessProfile/graphql/mutations';
import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';
import CustomFieldForm, { INewCustomFieldForm } from './CustomFieldForm';
import { trimAttributes } from 'shared/components/CustomField/utils';

//IProps interface
interface IProps {
  isOpen: boolean;
  onClose: () => void;
  businessId: string;
  refetch: () => {};
}

// Initial newCustomfieldObject values
const newCustomFieldObject: INewCustomFieldForm = {
  businessId: '',
  centerIds: [],
  label: '',
  area: '',
  displayOrder: 0,
  notForConsoleDisplay: false,
  type: '',
  attributes: '',
};

const CreateCustomFieldsModal: React.FC<IProps> = ({ isOpen, onClose, businessId, refetch }) => {
  const [newCustomFieldFormData, setNewCustomFieldFormData] = useState<INewCustomFieldForm>({
    ...newCustomFieldObject,
    businessId,
  });

  const [createCustomField, { loading: loading }] = useCreateCustomFields();

  const handleInput = useCallback((name, value) => {
    setNewCustomFieldFormData((newCustomFieldFormData) => ({
      ...newCustomFieldFormData,
      [name]: value,
    }));
  }, []);

  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    createCustomField({
      variables: {
        input: {
          ...newCustomFieldFormData,
          centerIds: newCustomFieldFormData.centerIds ?? [],
          attributes: trimAttributes(newCustomFieldFormData.attributes),
        },
      },
    })
      .then((returnData) => {
        if (returnData.data) {
          showToast(t('custom-data-fields.create-custom-data-field-success'), 'success');
          handleClose();
          refetch();
        }
      })
      .catch((err) => {
        showToast('There was an error.', 'error');
      });
  }, [createCustomField, newCustomFieldFormData, handleClose]);

  const formIsValid: boolean =
    !isBlank(newCustomFieldFormData.label) &&
    (newCustomFieldFormData.centerIds?.length !== 0 || newCustomFieldFormData.centerIds === null) &&
    !isBlank(newCustomFieldFormData.area) &&
    !isBlank(newCustomFieldFormData.type) &&
    (newCustomFieldFormData.type === 'Combo' || newCustomFieldFormData.type === 'Radio'
      ? !isBlank(newCustomFieldFormData.attributes)
      : true);

  return (
    <SideModalDrawer
      title={t('custom-data-fields.create-custom-data-field')}
      show={isOpen}
      onHide={handleClose}
      primaryCallback={handleSave}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      closeOnPrimaryCallback={false}
      primaryButtonProps={{ disabled: !formIsValid, loading: loading }}
      footerHelperText={formIsValid ? errorMessage.formRequirements : ''}
    >
      <CustomFieldForm handleInput={handleInput} formValues={newCustomFieldFormData} />
    </SideModalDrawer>
  );
};

export default CreateCustomFieldsModal;
