import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CodeInputs } from 'shared/components/TextInput';
import Button from 'shared/components/Buttons';
import errorMessage from 'shared/constants/errorMessages';
import { steps } from './Join';
import { Redirect } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { loginWithEmailAndPassword } from 'pages/Login/duck/actions';

interface IProps {
  newUser: INewUser;
  updateNewUser: React.Dispatch<React.SetStateAction<INewUser>>;
  isSso: boolean | undefined;
}

const CreatePin: React.FC<IProps> = ({ newUser, updateNewUser, isSso }) => {
  const pinLength: number = 4;
  const dispatch = useDispatch();
  const [creatingUser, setCreatingUser] = useState(false);
  const [confirmPin, updateConfirmPin] = useState('');
  const [redirectBack, setRedirectBack] = useState(false);
  const [redirectForward, setRedirectForward] = useState(false);

  const handleSave = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setCreatingUser(true);

      if (newUser.email && newUser.code && newUser.pin && newUser.personId) {
        const pw = newUser.password ? newUser.password : btoa(Math.random().toString()).substring(5, 20).concat('!');
        try {
          const cognitoResponse = await Auth.signUp({
            username: newUser.email,
            password: pw,
            attributes: {
              email: newUser.email,
              given_name: newUser.firstName,
              family_name: newUser.lastName,
            },
            clientMetadata: {
              flow: 'STAFF_SIGN_UP_ACCEPT_INVITE',
              code: newUser.code,
              pin: newUser.pin,
            },
          });

          if (cognitoResponse.user) {
            if (isSso) {
              localStorage.setItem('isUserSSO', 'true');
              setRedirectForward(true);
            } else {
              dispatch(
                loginWithEmailAndPassword({
                  email: newUser.email,
                  password: newUser.password,
                })
              );
            }
          }
        } catch (error) {
          // cognito error - should show some sort of error(?)
          // ex: { __type: "UsernameExistsException", message: "User already exists" }
          // NOTE: we'll need to check what errors can be returned when signing someone up in cognito and how to handle accordingly
          setCreatingUser(false);
        }
      }

      setCreatingUser(false);
    },
    [newUser, dispatch, isSso]
  );

  const formDisabled = newUser?.pin?.length !== 4 || newUser.pin !== confirmPin;

  if (redirectBack) {
    if (isSso) return <Redirect to={`/join/?step=${steps.inputName}`} />;
    else {
      return <Redirect to={`/join/?step=${steps.createPassword}`} />;
    }
  }

  if (redirectForward) {
    if (isSso) return <Redirect to="/login" />;
  }

  return (
    <form onSubmit={handleSave} className="login-container p-8 my-auto mx-auto">
      <p className="h1 font-weight-light">Create a PIN</p>
      <p className="mb-8">Once you’re in the app, you will sometimes need to use a PIN. Create a memorable PIN.</p>
      {/* CodeInputs isn't wrapped in Form.Group which adds a margin bottom, add it manually here */}
      <div className="mb-4">
        <CodeInputs
          required
          type="number"
          value={newUser.pin || ''}
          name="pin-input"
          fields={pinLength}
          label="PIN"
          isMaskedInput={true}
          isValid={newUser.pin?.length === pinLength}
          isInvalid={newUser.pin?.length !== pinLength}
          errorText={errorMessage.pinRequirements}
          onChange={(pin: string) => {
            updateNewUser({ ...newUser, pin });
          }}
        />
      </div>
      <CodeInputs
        required
        type="number"
        value={confirmPin || ''}
        name="pin-confirm-input"
        fields={pinLength}
        isMaskedInput={true}
        label="Confirm PIN"
        isValid={newUser.pin?.length === pinLength && newUser.pin === confirmPin}
        isInvalid={newUser.pin !== confirmPin || confirmPin.length !== pinLength}
        errorText={errorMessage.confirmPin}
        onChange={(pin: string) => updateConfirmPin(pin)}
      />
      <Button loading={creatingUser} disabled={formDisabled} className="btn-block my-8" type="submit">
        Continue
      </Button>
      <Button variant="secondary" onClick={() => setRedirectBack(true)}>
        Back
      </Button>
    </form>
  );
};

export default CreatePin;
