import * as types from './types';

export const getBusinessesSuccess = (businesses: IBusiness[]): types.BusinessesActionTypes => ({
  type: types.GET_BUSINESSES_SUCCESS,
  businesses,
});

export const getBusinessSuccess = (business: IBusiness): types.BusinessesActionTypes => ({
  type: types.GET_BUSINESS_SUCCESS,
  business,
});

export const createBusiness = (business: IBusiness): types.BusinessesActionTypes => ({
  type: types.CREATE_BUSINESS,
  business,
});

export const updateBusiness = (business: IBusiness): types.BusinessesActionTypes => ({
  type: types.UPDATE_BUSINESS,
  business,
});

export const createPaymentMethodForBusiness = (
  businessId: string,
  paymentMethod: IPaymentMethod
): types.BusinessesActionTypes => ({
  type: types.CREATE_PAYMENT_METHOD_FOR_BUSINESS,
  businessId,
  paymentMethod,
});

export const updatePaymentMethodForBusiness = (
  businessId: string,
  paymentMethod: IPaymentMethod
): types.BusinessesActionTypes => ({
  type: types.UPDATE_PAYMENT_METHOD_FOR_BUSINESS,
  businessId,
  paymentMethod,
});

export const updateDefaultPaymentMethodForBusiness = (
  businessId: string,
  paymentMethodId: string
): types.BusinessesActionTypes => ({
  type: types.UPDATE_DEFAULT_PAYMENT_METHOD_FOR_BUSINESS,
  businessId,
  paymentMethodId,
});

export const deletePaymentMethodForBusiness = (
  businessId: string,
  paymentMethodId: string
): types.BusinessesActionTypes => ({
  type: types.DELETE_PAYMENT_METHOD_FOR_BUSINESS,
  businessId,
  paymentMethodId,
});

export const destroyBusiness = (id: string): types.BusinessesActionTypes => ({
  type: types.DESTROY_BUSINESS,
  id,
});
