import React from 'react';
interface IProps {
  data: IISInnovativeCasesForCenters;
}
const ISInnovativeSolutionsSecondDetailsTable: React.FC<IProps> = ({ data }) => {
  const { careEnvironmentName, agencyRegion, agencyName } = data;
  return (
    <div>
      <div>
        <table className="table-simple mr-12">
          <colgroup>
            <col span={1} style={{ width: '70%' }} />
            <col span={1} style={{ width: '30%' }} />
          </colgroup>
          <tbody>
            <tr>
              <td>Care Environment Name</td>
              <td>{careEnvironmentName}</td>
            </tr>
            <tr>
              <td>Inclusion Agency Region</td>
              <td>{agencyRegion}</td>
            </tr>
            <tr>
              <td>InclusionAgency Name</td>
              <td>{agencyName}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ISInnovativeSolutionsSecondDetailsTable;
