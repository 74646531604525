import React, { useCallback } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Avatar from 'shared/components/Avatar';
import Select from 'shared/components/Select';
import Checkbox from 'shared/components/Checkbox';
import { stringToHsl, getFullName, getInitials } from 'shared/util/string';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import { useGetChildContactPermissions } from 'gql/contact/queries';
import { Row, Col } from 'shared/components/Layout';
import { orderBy } from 'lodash';

interface IProps {
  contactOptions: IContact[];
  childContacts: IAccountChildContactInput[];
  updateChildContacts: (children: IAccountChildContactInput[]) => void;
}

const ChildContactsInputs: React.FC<IProps> = ({ contactOptions, childContacts, updateChildContacts }) => {
  const { data: getChildContactPermissionsData } = useGetChildContactPermissions();
  const permissionsOptions = getChildContactPermissionsData?.getChildContactRelationshipPermissions ?? [];

  const handleContactSelect = useCallback(
    (checked: boolean, contactId: string) => {
      updateChildContacts(
        checked
          ? [...childContacts, { contactId, relationship: '', permissions: [] }]
          : childContacts.filter((acc) => acc.contactId !== contactId)
      );
    },
    [childContacts]
  );

  const handleRelationshipSelect = useCallback(
    (contactId: string, relationship: string) => {
      updateChildContacts(childContacts.map((acc) => (acc.contactId === contactId ? { ...acc, relationship } : acc)));
    },
    [childContacts]
  );

  const handlePermissionSelect = useCallback(
    (checked: boolean, contactId: string, permission: string) => {
      updateChildContacts(
        childContacts.map((acc) =>
          acc.contactId === contactId
            ? {
                ...acc,
                permissions: checked
                  ? [...acc.permissions, permission]
                  : acc.permissions.filter((p) => p !== permission),
              }
            : acc
        )
      );
    },
    [childContacts]
  );

  return (
    <ul className="unstyled-list ml-3">
      {orderBy(orderBy(contactOptions, 'firstname'), 'isPrimary', 'desc').map((contact: IContact, idx: number) => {
        const isContactChecked = childContacts.some((c) => c.contactId === contact.id);
        return (
          <li className="mb-6" key={`account-contact-child-${contact.id}-${idx}`}>
            <Row>
              <Col>
                <Row>
                  {!contact.isPrimary && (
                    <Checkbox
                      value={isContactChecked}
                      onChange={(checked) => handleContactSelect(checked, contact.id)}
                    />
                  )}
                  <Avatar
                    color={stringToHsl(contact.id)}
                    size="sm"
                    image={contact.avatar?.url ?? ''}
                    initials={getInitials(contact)}
                  />
                  <div className="d-flex flex-column ml-2 text-truncate sm">{getFullName(contact)}</div>
                </Row>
              </Col>
              <Col>
                {
                  <Collapse in={Boolean(isContactChecked)}>
                    <Select
                      className="mb-0"
                      value={childContacts.find((c) => c.contactId === contact.id)?.relationship ?? null}
                      options={Object.entries(childContactRelationship).map((r) => ({ value: r[0], label: r[1] }))}
                      onChange={(option) => handleRelationshipSelect(contact.id, option.value)}
                      required={contact.isPrimary}
                    />
                  </Collapse>
                }
              </Col>
            </Row>
            {
              <Collapse in={Boolean(isContactChecked)}>
                <>
                  {!contact.isPrimary &&
                    permissionsOptions.map((permission, idx) => (
                      <Row key={idx}>
                        <Col>
                          <Checkbox
                            className="ml-auto mt-2"
                            label={permission.label}
                            value={childContacts
                              .find((c) => c.contactId === contact.id)
                              ?.permissions?.includes(permission.value)}
                            onChange={(checked) => handlePermissionSelect(checked, contact.id, permission.value)}
                          />
                        </Col>
                      </Row>
                    ))}
                </>
              </Collapse>
            }
          </li>
        );
      })}
    </ul>
  );
};

export default ChildContactsInputs;
