import React from 'react';
import { isAndroid, isMobile } from 'react-device-detect';

const DownloadKTConnect: React.FC = () => {
  const googlePlay = 'https://play.google.com/store/apps/details?id=au.com.kangarootime.ktconnect';
  const appleStore = 'https://apps.apple.com/au/app/kt-connect/id6477852483';
  const sendToAppStore = () => {
    if (!isMobile) return;
    if (isAndroid) {
      window.location.assign(googlePlay);
    } else {
      window.location.assign(appleStore);
    }
  };

  sendToAppStore();
  return <></>;
};

export default DownloadKTConnect;
