import React, { Fragment } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {
  datesToRender: moment.Moment[];
  calendarType: CalendarViewType;
  className?: string;
}

/**
 * This component is responsible for rendering the visible headers dates.
 * Depending on if a week has a schedule, props.children will either render the schedule or the empty state card.
 */
const ScheduleContainer: React.FC<IProps> = ({ datesToRender, calendarType, className = '', ...props }) => {
  const { k2WebWeekendScheduling } = useFlags();
  const classNames = classnames({
    'kt-schedules-dates-grid': true,
    'kt-schedules-dates-grid-week': calendarType === 'WEEK' && !k2WebWeekendScheduling,
    'kt-schedules-dates-grid-weekend': calendarType === 'WEEK' && k2WebWeekendScheduling,
    'kt-schedules-dates-grid-day': calendarType === 'DAY',
    [className]: true,
  });

  // if no child components are provided, ignore the spinner
  // const ignoreSpinnerOverlay: boolean = React.Children.toArray(props.children).every(child => !child);
  // console.log(React.Children.toArray(props.children));

  return (
    <div id="kt-employee-schedules-container">
      <div className={classNames}>
        {datesToRender.map((date: moment.Moment, idx: number) => (
          <Fragment key={`kt-schedules-dates-header-text-${date.format('dddd_D')}-${idx}`}>
            <div className="kt-schedules-dates-header-text d-none d-md-block">{date.format('dddd, D')}</div>
            <div className="kt-schedules-dates-header-text d-block d-md-none">{date.format('ddd, D')}</div>
          </Fragment>
        ))}
      </div>
      {props.children}
    </div>
  );
};

export default ScheduleContainer;
