import { MutationHookOptions } from '@apollo/client';
import { transactionFields } from 'gql/transaction/fields';
import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { batchFields, updateCenterSettingsFields } from './fields';

interface ICreateBatchData {
  createBatch: IBatch;
}

interface ICreateBatchVariables {
  input: {
    businessId: string;
    centerId: string;
    paymentIds: string[];
  };
}

interface IDeleteBatchData {
  deleteBatch: {
    batch: IBatch;
    unbatchedPaymentTransactions: ITransaction[];
  };
}

interface IDeleteBatchVariables {
  id: string;
}

export const CREATE_BATCH = (fields: string = batchFields) => gql`
  mutation ($input: CreateBatchInput!) {
    createBatch(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_BATCH = (fields: string = batchFields) => gql`
  mutation ($id: ID!) {
    deleteBatch(id: $id) {
      batch {
        ${fields}
      }
      unbatchedPaymentTransactions  {
        ${transactionFields}
      }
    }
  }
`;

export const useCreateBatch = (
  options?: MutationHookOptions<ICreateBatchData, ICreateBatchVariables>,
  fields?: string
) => useMutation<ICreateBatchData, ICreateBatchVariables>(CREATE_BATCH(fields), options);

export const useDeleteBatch = (
  options?: MutationHookOptions<IDeleteBatchData, IDeleteBatchVariables>,
  fields?: string
) => useMutation<IDeleteBatchData, IDeleteBatchVariables>(DELETE_BATCH(fields), options);

interface IUpdateCenterSettingsData {
  updateCenterSettings: {
    id: string;
    entityId: string;
    name: string;
    enableAutomaticBatching: boolean;
  };
}

interface IUpdateCenterSettingsVariables {
  input: {
    businessId: string;
    centerId: string;
    enableAutomaticBatching?: boolean;
  };
}

export const UPDATE_CENTER_SETTINGS = (fields: string = updateCenterSettingsFields) => gql`
  mutation ($input: UpdateCenterSettingsInput!) {
    updateCenterSettings(input: $input) {
      ${fields}
    }
  }
`;

export const useUpdateCenterSettings = (
  options?: MutationHookOptions<IUpdateCenterSettingsData, IUpdateCenterSettingsVariables>,
  fields?: string
) => useMutation<IUpdateCenterSettingsData, IUpdateCenterSettingsVariables>(UPDATE_CENTER_SETTINGS(fields), options);

export const UPDATE_CENTER_SETTINGS_FOR_ALL_BY_BUSINESS_ID = (fields: string = updateCenterSettingsFields) => gql`
  mutation ($input: UpdateCenterSettingsForAllByBusinessIdInput!) {
    updateCenterSettingsForAllByBusinessId(input: $input) {
      results {
        ${fields}
      }
    }
  }
`;

interface IUpdateCenterBatchingSettingsResult {
  id: string;
  entityId: string;
  name: string;
  enableAutomaticBatching: boolean;
}

interface IUpdateCenterSettingsForAllByBusinessIdData {
  updateCenterSettingsForAllByBusinessId: {
    results: IUpdateCenterBatchingSettingsResult[];
  };
}

interface IUpdateCenterSettingsForAllByBusinessIdVariables {
  input: {
    businessId: string;
    enableAutomaticBatching: boolean;
  };
}

export const useUpdateCenterSettingsForAllByBusinessId = (
  options?: MutationHookOptions<
    IUpdateCenterSettingsForAllByBusinessIdData,
    IUpdateCenterSettingsForAllByBusinessIdVariables
  >,
  fields?: string
) =>
  useMutation<IUpdateCenterSettingsForAllByBusinessIdData, IUpdateCenterSettingsForAllByBusinessIdVariables>(
    UPDATE_CENTER_SETTINGS_FOR_ALL_BY_BUSINESS_ID(fields),
    options
  );
