import { isUndefined, isEqual, cloneDeep } from 'lodash';
import { Section, ClientSideBehaviours, Page, Field, CustomEnrolmentForm } from 'shared/types/enrollment-form';
import {
  SecondaryContactRelationship,
  SystemFieldBuilder,
} from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/page-section/field-container/kt-field/system-field-builder';
import { getTranslation } from 'shared/util/region';
import { DropResult } from 'react-beautiful-dnd';
import { moveElementBetweenArray, orderArray } from 'shared/util/dnd';
import { v4 as uuidv4 } from 'uuid';
import { SYSTEM_REQUIRED_FIELDS } from './hooks/useFieldMetadata';
import { isRegion } from 'shared/util/region';
import * as Sentry from '@sentry/react';

const isAuRegion = isRegion('AU');

export type SectionMetaDataErrors = { [key: string]: string | undefined };

export const getNonConditionalSections = (sections: Section[]) =>
  sections.filter((s) => isUndefined(s.clientSideBehaviours));

export const getConditionalSections = (sections: Section[]) => {
  return sections.filter((s) => !isUndefined(s.clientSideBehaviours));
};

export const getConditionalSectionsBySubscribeField = (
  page: Page,
  subscribeFieldId: string
): { section: Section; index: number }[] => {
  const result: { section: Section; index: number }[] = [];
  page.sections.forEach((s, idx, array) => {
    if (s.clientSideBehaviours?.subscribeField === subscribeFieldId) {
      result.push({ section: s, index: idx });
    }
  });

  return result;
};

export const getConditionalSectionsByCondition = (
  sections: Section[],
  clientSideBehaviours: ClientSideBehaviours
): Section[] => sections.filter((s) => isEqual(s.clientSideBehaviours, clientSideBehaviours));

export const calculateNameSpaceByPage = (page: Page) => {
  if (page.sectionType === 'contact') {
    return 'primaryContacts';
  } else if (page.sectionType === 'secondary-contact') {
    return 'contacts';
  } else if (page.sectionType === 'medical-professional') {
    return 'medicalProfessionals';
  }
  return undefined;
};

export const isFieldExist = (page: Page, fieldId: string) => {
  const allFields = page.sections.reduce<Field[]>((acc, s) => acc.concat(s.fields), []);
  return !!allFields.find((f) => f.name === fieldId);
};
export const isConditionalSection = (section: Section) => !isUndefined(section.clientSideBehaviours);

export const pageDecorator = (page: Page): Page => {
  if (['medical-professional'].includes(page.sectionType!)) {
    page.sections[0].namespace = 'medicalProfessionals';
  }

  if (['contact', 'secondary-contact'].includes(page.sectionType!)) {
    page.sections[0].namespace = page.sectionType === 'secondary-contact' ? 'contacts' : 'primaryContacts';
    page.sections[0].fields = [
      SystemFieldBuilder.toField(SystemFieldBuilder.build('firstName')!),
      SystemFieldBuilder.toField(SystemFieldBuilder.build('lastName')!),
      SystemFieldBuilder.toField(SystemFieldBuilder.build('phoneNumbers', page.sectionType)!),
      SystemFieldBuilder.toField(SystemFieldBuilder.build('relationships', page.sectionType)!),
    ];

    if (page.sectionType === 'contact') {
      if (isAuRegion) {
        page.sections[0].fields.push({
          ...SystemFieldBuilder.toField(SystemFieldBuilder.build('dateOfBirth')!),
          isMandatory: true,
        });
      }
      page.sections[0].fields.push({
        ...SystemFieldBuilder.toField(SystemFieldBuilder.build('email')!),
        isMandatory: true,
      });
    }
    page.sections[0].hideTitle = true;
  }

  return page;
};

export const getMinContactMetadataErrors = (
  minContacts?: number,
  minEmergencyContactPerChild?: number,
  minPermissionToPickUpPerChild?: number
): SectionMetaDataErrors => {
  const t = getTranslation('en', 'enrollment');
  const errors: SectionMetaDataErrors = {};

  if (isUndefined(minContacts) || isNaN(minContacts)) {
    errors.minContracts = t('form.errors.min-contacts-required');
  }

  if (isUndefined(minEmergencyContactPerChild) || isNaN(minEmergencyContactPerChild)) {
    errors.minEmergencyContactPerChild = t('form.errors.min-emergency-contact-required');
  }

  if (isUndefined(minPermissionToPickUpPerChild) || isNaN(minPermissionToPickUpPerChild)) {
    errors.minPermissionToPickUpPerChild = t('form.errors.min-emergency-contact-required');
  }

  if (
    !isUndefined(minEmergencyContactPerChild) &&
    !isUndefined(minContacts) &&
    !isUndefined(minPermissionToPickUpPerChild)
  ) {
    if (minEmergencyContactPerChild > minContacts) {
      errors.minEmergencyContactPerChild = t('form.errors.min-emergency-invalid');
    }

    if (minPermissionToPickUpPerChild > minContacts) {
      errors.minPermissionToPickUpPerChild = t('form.errors.min-permission-invalid');
    }
  }

  return errors;
};

export const checkIsSectionMetadataValid = (
  pages: Page[]
): { isValid: boolean; errors?: SectionMetaDataErrors | null } => {
  const result = {
    isValid: true,
    errors: null,
  };
  const secondaryContactPage = pages.find((p) => p.sectionType === 'secondary-contact');

  if (!secondaryContactPage) {
    return result;
  } else {
    const sectionMetaDataProperties = secondaryContactPage.sections.find((s) => s.namespace === 'contacts')
      ?.sectionMetadata?.properties;

    if (!sectionMetaDataProperties) return result;

    const { minContacts, minEmergencyContactPerChild, minPermissionToPickUpPerChild } = sectionMetaDataProperties;
    const errors = getMinContactMetadataErrors(minContacts, minEmergencyContactPerChild, minPermissionToPickUpPerChild);
    const hasErrors = Object.values(errors).some((e) => !!e);

    return {
      isValid: !hasErrors,
      errors,
    };
  }
};

export const getNewPageAndNewPayloadForCrossSectionQuestionDnD = (
  selectedPage: Page<any>,
  payload: CustomEnrolmentForm,
  dropResult: DropResult
): [Page<any>, CustomEnrolmentForm] => {
  const { droppableId: destinationDroppableId, index: destinationDroppableIndex } = dropResult.destination!;
  const { droppableId: sourceDroppableId, index: sourceDroppableIndex } = dropResult.source;

  const sourceSectionIdx = selectedPage.sections.findIndex((s) => s.id === sourceDroppableId);
  const destinationSectionIdx = selectedPage.sections.findIndex((s) => s.id === destinationDroppableId);

  const [newSourceFieldSet, newDestinationFieldSet] = moveElementBetweenArray<Field>(
    selectedPage.sections[sourceSectionIdx].fields,
    selectedPage.sections[destinationSectionIdx].fields,
    sourceDroppableIndex,
    destinationDroppableIndex
  );
  const mutablePage = cloneDeep(selectedPage);
  mutablePage.sections[sourceSectionIdx].fields = newSourceFieldSet;
  mutablePage.sections[destinationSectionIdx].fields = newDestinationFieldSet;

  const idx = payload.steps.findIndex((s) => s.label === mutablePage.label);
  if (idx === -1) {
    return [selectedPage, payload];
  }
  const mutablePayload = cloneDeep(payload);
  mutablePayload.steps[idx] = mutablePage;

  return [mutablePage, mutablePayload];
};

export const getNewPageAndNewPayloadForSameSectionQuestionDnD = (
  selectedPage: Page<any>,
  payload: CustomEnrolmentForm,
  dropResult: DropResult
): [Page<any>, CustomEnrolmentForm] => {
  const { droppableId: sourceDroppableId } = dropResult.source;

  const sourceSectionIdx = selectedPage.sections.findIndex((s) => s.id === sourceDroppableId);
  if (sourceSectionIdx === -1) {
    return [selectedPage, payload];
  }
  const newFieldSet = orderArray<Field>(
    selectedPage.sections[sourceSectionIdx].fields,
    dropResult.source.index,
    dropResult.destination!.index
  );
  const mutablePage = cloneDeep(selectedPage);
  mutablePage.sections[sourceSectionIdx].fields = newFieldSet;

  const idx = payload.steps.findIndex((s) => s.label === mutablePage.label);
  if (idx === -1) {
    return [selectedPage, payload];
  }
  const mutablePayload = cloneDeep(payload);
  mutablePayload.steps[idx] = mutablePage;

  return [mutablePage, mutablePayload];
};

export const getNewPageAndNewPayloadForSectionsDnD = (
  selectedPage: Page<any>,
  payload: CustomEnrolmentForm,
  dropResult: DropResult
): [Page<any>, CustomEnrolmentForm] => {
  const { destination, source } = dropResult;
  if (!destination) {
    return [selectedPage, payload];
  }
  const sections = orderArray<Section>(selectedPage.sections, source.index, destination.index);
  const mutablePage = cloneDeep(selectedPage);
  mutablePage.sections = sections;

  const idx = payload.steps.findIndex((s) => s.label === mutablePage.label);
  if (idx === -1) {
    return [selectedPage, payload];
  }
  const mutablePayload = cloneDeep(payload);
  mutablePayload.steps[idx] = mutablePage;

  return [mutablePage, mutablePayload];
};

const injectSectionId = (forms: CustomEnrolmentForm) => {
  const addSectionId = (section: Section) => {
    if (!section.id) {
      if (section.clientSideBehaviours) {
        return {
          ...section,
          // conditional section was initiated with label=uuid()
          id: section.label ?? uuidv4(),
        };
      } else {
        return {
          ...section,
          id: uuidv4(),
        };
      }
    } else {
      return section;
    }
  };

  const steps = forms.steps.map((page) => {
    return {
      ...page,
      sections: page.sections.map((section) => addSectionId(section)),
    };
  });

  return {
    steps,
  };
};

const injectPermissions = (forms: CustomEnrolmentForm) => {
  // only AU region need to inject new permissions
  if (!isAuRegion) return forms;

  const mutableFrom = cloneDeep(forms);
  const secondaryContactStep = mutableFrom.steps.find((s) => s.sectionType === 'secondary-contact');
  if (!secondaryContactStep) return forms;

  secondaryContactStep.sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (field.type === 'CompositionField' && field.name === 'relationships') {
        field.includes = [...SecondaryContactRelationship.includes];
      }
    });
  });
  return mutableFrom;
};

/**
 * @description This function is used to patch the form data in order to support new features on enrol frontend upon new version published
 */
export const formPatcher = (form: CustomEnrolmentForm, formId: string): CustomEnrolmentForm => {
  try {
    let patchedForm = injectSectionId(form);
    patchedForm = injectPermissions(patchedForm);
    return patchedForm;
  } catch (error) {
    Sentry.captureException(new Error(`attempt to patch form ${formId} failed`), {
      tags: {
        ['formPatcher']: 'FAILED',
      },
    });
    Sentry.captureException(error, {
      tags: {
        ['formPatcher']: 'FAILED',
      },
    });

    return form;
  }
};

export const checkIsSystemRequiredField = (fieldName: string, sectionType: string) => {
  if (SYSTEM_REQUIRED_FIELDS.indexOf(fieldName) > -1) {
    return true;
  }

  if (fieldName === 'dateOfBirth' && sectionType === 'child') {
    return true;
  }

  if (isAuRegion && fieldName === 'dateOfBirth' && sectionType === 'contact') {
    return true;
  }

  if (fieldName === 'email' && sectionType === 'contact') {
    return true;
  }

  return false;
};

export const hasUsedPaymentSystemFieldInAccountPage = (pages: Page[]) => {
  let result = false;

  for (let i = 0; i < pages.length; i++) {
    const { sections } = pages[i];
    for (let j = 0; j < sections.length; j++) {
      const section = sections[j];
      if (section.fields.some((field) => ['PaymentMethod', 'BillingCycle'].includes(field.type))) {
        result = true;
        break;
      }
    }
  }

  return result;
};
