import React from 'react';
import { useTranslation } from 'react-i18next';
import surchargesBanner from 'shared/images/Surcharges-Banner.svg';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import PageWrapper from 'shared/components/PageWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card } from '@mui/material';

const TodayAttendanceNotPermitted = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper pageTitle={t('attendance.today.title')} applyPadding={true}>
      <Card className="px-8 pt-4 pb-2">
        <div className="d-flex flex-row">
          <div className="col-12 col-lg-7 pt-6 pb-8">
            <h5 className="mt-4 mb-6">Did you know?</h5>
            <p>{t('attendance.today.info.global-settings')}</p>
            <div className="red-info-block p-4 d-flex flex-row">
              <div className="mr-4">
                <FontAwesomeIcon icon={faInfoCircle} size="2x" />
              </div>
              <div className="mr-6 d-flex align-items-center">
                <span style={{ fontWeight: '600' }}>
                  {t('attendance.today.info.permission-warning')}
                  <Link to="/attendance/settings/general-settings" className="alert-link">
                    {t('attendance.today.info.permission-link-text')}
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="col-auto col-lg-5 d-flex justify-content-center align-items-center">
            <img
              src={surchargesBanner}
              alt="cartoon helper man"
              className="align-self-end mt-n2 mb-n8 mr-4 d-none d-md-block"
              style={{ width: '235px' }}
            />
          </div>
        </div>
      </Card>
    </PageWrapper>
  );
};

export default TodayAttendanceNotPermitted;
