import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import classnames from 'classnames';
import { orderBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle, faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { getFullName, getInitials, stringToHueDegree } from 'shared/util/string';
import colors from '_colors.module.scss';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import ClassEnrollmentWeekContext from 'pages/Enrollment/subroutes/Management/Tabs/ClassEnrollment/classEnrollmentWeekProvider';
import { isRegion } from '../../../../../../../../shared/util/region';

enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

interface IProps {
  enrollment: IEnrollment;
  child: IChild;
  futureEnrollment?: boolean;
  centerTimezone: string;
  isFullDayClass: boolean;
}

const ChildRow: React.FC<IProps> = ({
  enrollment,
  child,
  futureEnrollment = false,
  centerTimezone,
  isFullDayClass,
  ...props
}) => {
  const contextValues = useContext(ClassEnrollmentWeekContext);
  const childInitials = getInitials(child);
  const childName = getFullName(child);
  /**
   * the api returns the time as a datetime at utc midnight but passing through momentTz
   * with the trailing +00 is causing the date to go to the previous date so we're going to only use
   * everything before it
   *
   * ex: mz('2020-10-07T00:00:00+00:00').tz('America/New_York', true) would return a date for Oct 06 2020 16:00:00 GMT-0400
   */
  const dateForTable = isRegion('AU') ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
  const startDateFormatted = momentTz(enrollment.startDate.split('+')[0]).tz(centerTimezone).format(dateForTable);
  const ageUpDateFormatted = momentTz(enrollment.expectedAgeUpDate.split('+')[0])
    .tz(centerTimezone)
    .format(dateForTable);
  const dobFormatted = getAgeStringFromDateOfBirth(moment(child.dob)).replace(/\(|\)/g, '');
  const endDate = enrollment.endDate ? momentTz(enrollment.endDate.split('+')[0]).tz(centerTimezone) : null;

  // determine whether a date is in week1 or week2 of a biweekly contract
  const determineTimeslotWeek = useCallback((contractStartDate: string, dateToCompare: string): 'WEEK1' | 'WEEK2' => {
    const differenceInDays = Math.abs(moment(dateToCompare).diff(moment(contractStartDate).startOf('week'), 'days'));
    const weekDifference = Math.floor(differenceInDays / 7);
    return weekDifference % 2 === 0 ? 'WEEK1' : 'WEEK2';
  }, []);

  const determineCellTypeToRender = useCallback(
    (dayOfWeek: DayOfWeek, timeOfDay: 'AM' | 'PM'): React.ReactNode => {
      const { timeslots, expectedAgeUpDate, startDate, contract } = enrollment;

      // don't render PM if the class is considered full day
      if (isFullDayClass && timeOfDay === 'PM') {
        return null;
      }

      const colspan = isFullDayClass ? 2 : 1;
      let day = 0;

      switch (dayOfWeek) {
        case DayOfWeek.SUNDAY:
          day = 0;
          break;
        case DayOfWeek.MONDAY:
          day = 1;
          break;
        case DayOfWeek.TUESDAY:
          day = 2;
          break;
        case DayOfWeek.WEDNESDAY:
          day = 3;
          break;
        case DayOfWeek.THURSDAY:
          day = 4;
          break;
        case DayOfWeek.FRIDAY:
          day = 5;
          break;
        case DayOfWeek.SATURDAY:
          day = 6;
          break;
        default:
          break;
      }

      // if a timeslot has a date then it is part of a custom contract
      const dayOfWeekMoment = contextValues.startOfWeek.clone().day(day);
      // determine if the date falls in the first or second week of a biweekly contract
      const weekTypeForDate =
        contract.cycleType === 'BIWEEKLY'
          ? determineTimeslotWeek(contract.startDate, dayOfWeekMoment.toISOString())
          : 'WEEK1';

      const slotForDay =
        timeslots
          .filter((ts) =>
            ts.date !== null ? moment(ts.date).tz(centerTimezone).isSame(dayOfWeekMoment, 'date') : ts.day === dayOfWeek
          )
          .filter((ts) => (ts.week !== null ? ts.week === weekTypeForDate : true)) ?? [];
      const contractFee = contract.permanentFee;
      const activeFeeSchedule = orderBy(contract.permanentFee?.schedules ?? [], (fs) => fs.startDate, 'desc').find(
        (fs) => moment(fs.startDate).tz(centerTimezone) <= moment().tz(centerTimezone)
      );
      const startTime = activeFeeSchedule?.startTime ?? '';
      // we assume here (just like on the backend) that a fee with a utilization of 1 is full day and a fee with a utilization of 0.5 and start time before 12pm is an AM half day spot
      const matchesAm = activeFeeSchedule && timeOfDay === 'AM' && parseInt(startTime.split(':')[0]) < 12;
      const matchesPm = activeFeeSchedule && timeOfDay === 'PM' && parseInt(startTime.split(':')[0]) >= 12;
      const showAgeUpStyling =
        (moment(expectedAgeUpDate).isBetween(contextValues.startOfWeek, contextValues.endOfWeek) &&
          moment(expectedAgeUpDate).date() === dayOfWeekMoment.date()) ||
        dayOfWeekMoment.isAfter(moment(expectedAgeUpDate));

      const dayOfWeekIsAfterEnrollmentStart = dayOfWeekMoment.isSameOrAfter(moment(startDate), 'date');
      const dayOfWeekIsBeforeEnrollmentEnd = endDate
        ? dayOfWeekMoment.utc().isSameOrBefore(endDate.utc(), 'date')
        : true;

      // show future spots with a check mark to help inform how the open spots are calculated
      if (
        slotForDay.length > 0 &&
        ((dayOfWeekIsAfterEnrollmentStart && dayOfWeekIsBeforeEnrollmentEnd) || futureEnrollment)
      ) {
        if (
          contractFee.utilization === 1 ||
          (contractFee.utilization === 0.5 && matchesAm) ||
          (contractFee.utilization === 0.5 && matchesPm)
        ) {
          return (
            <td
              colSpan={colspan}
              className={classnames({
                'open-spot-day': futureEnrollment,
                'age-up-spot': showAgeUpStyling,
                'full-day-spot': true,
              })}
            >
              <FontAwesomeIcon icon={faCheckCircle} color={colors.pink} size="2x" />
            </td>
          );
        }
      }

      return (
        <td
          colSpan={colspan}
          className={classnames({
            'open-spot-day': true,
            'age-up-spot': false,
            'full-day-spot': true,
          })}
        />
      );
    },
    [enrollment, contextValues.startOfWeek, contextValues.endOfWeek, futureEnrollment, isFullDayClass]
  );

  const determineNoteToRender = useCallback(() => {
    const { expectedAgeUpDate, child } = enrollment;
    const ageUpInCurrentWeek =
      moment(expectedAgeUpDate).isBetween(contextValues.startOfWeek, contextValues.endOfWeek) ||
      contextValues.startOfWeek.isAfter(moment(expectedAgeUpDate));

    if (ageUpInCurrentWeek) {
      return (
        <td>
          <div className="d-flex flex-row align-items-center enrollment-note-container">
            <FontAwesomeIcon icon={faExclamationCircle} color={colors.warning} size="lg" />
            <div className="ml-2">{child.nickname ?? child.firstname} is now available to age up.</div>
          </div>
        </td>
      );
    }

    return <td />;
  }, [enrollment, contextValues.startOfWeek, contextValues.endOfWeek]);

  return (
    <tr>
      <td>
        <div className="d-flex flex-column">
          <AvatarDataTableCell
            avatar={child.avatar?.url ?? ''}
            initials={childInitials.toUpperCase()}
            color={`hsl(${stringToHueDegree(child.id)}, ${stringToHueDegree(child.id) < 50 ? '100%' : '40%'}, 40%`}
            primaryText={childName}
            secondaryText={dobFormatted}
          />
          {futureEnrollment && (
            <div className="future-enrollment-badge mt-1 d-flex justify-content-center align-self-center">Future</div>
          )}
        </div>
      </td>
      <td>{startDateFormatted}</td>
      <td>{ageUpDateFormatted}</td>
      {determineCellTypeToRender(DayOfWeek.MONDAY, 'AM')}
      {determineCellTypeToRender(DayOfWeek.MONDAY, 'PM')}

      {determineCellTypeToRender(DayOfWeek.TUESDAY, 'AM')}
      {determineCellTypeToRender(DayOfWeek.TUESDAY, 'PM')}

      {determineCellTypeToRender(DayOfWeek.WEDNESDAY, 'AM')}
      {determineCellTypeToRender(DayOfWeek.WEDNESDAY, 'PM')}

      {determineCellTypeToRender(DayOfWeek.THURSDAY, 'AM')}
      {determineCellTypeToRender(DayOfWeek.THURSDAY, 'PM')}

      {determineCellTypeToRender(DayOfWeek.FRIDAY, 'AM')}
      {determineCellTypeToRender(DayOfWeek.FRIDAY, 'PM')}

      {determineNoteToRender()}
    </tr>
  );
};

export default ChildRow;
