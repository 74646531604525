import React from 'react';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';

interface IProps {
  _serviceFeeGroup: IServiceFeeGroupForm;
  updateGroup: React.Dispatch<React.SetStateAction<IServiceFeeGroupForm>>;
  isEdit: boolean;
}

const ServiceFeeGroupInformation: React.FC<IProps> = ({ _serviceFeeGroup, updateGroup, isEdit }) => {
  return (
    <Card header="Group Information" className="h-100">
      <Row>
        <Col lg>
          <TextInput
            required
            disabled={isEdit}
            id="group-name-input"
            label="Name"
            name="group-name"
            placeholder="Group Name"
            value={_serviceFeeGroup.name}
            onChange={(value: string) => updateGroup((prev) => ({ ...prev, name: value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col lg>
          <TextInput
            id="group-description-input"
            label="Description"
            name="group-description"
            value={_serviceFeeGroup.description}
            onChange={(value: string) => updateGroup((prev) => ({ ...prev, description: value }))}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ServiceFeeGroupInformation;
