import React from 'react';
import { displayDuration } from 'shared/util/timeUtils';
import { ISCaseTypeLabelsEnum } from 'shared/constants/enums/isCaseEnums';
interface IProps {
  data: IISCase;
}

const ISCaseDetailsTable: React.FC<IProps> = ({ data }) => {
  const { type, weeklyHoursAllowed, nonFaceToFaceHoursAllowed, supportHoursAllowed, weeklyTolerance } = data;
  return (
    <div>
      <div className="case-details-table-container">
        <h6 className="font-weight-normal mb-4">Case Details</h6>
        <table id="case-details-table" className="table-simple mr-12">
          <colgroup>
            <col span={1} style={{ width: '70%' }} />
            <col span={1} style={{ width: '30%' }} />
          </colgroup>
          <tbody>
            <tr>
              <td>Case Type</td>
              <td className="text-right">{ISCaseTypeLabelsEnum[type]}</td>
            </tr>
            <tr>
              <td>Total face to face hours</td>
              <td className="text-right">{displayDuration(supportHoursAllowed)}</td>
            </tr>
            <tr>
              <td>Total non-face to face hours</td>
              <td className="text-right">{displayDuration(nonFaceToFaceHoursAllowed)}</td>
            </tr>
            <tr>
              <td>Total allowable hours per week</td>
              <td className="text-right">{displayDuration(weeklyHoursAllowed)}</td>
            </tr>
            <tr>
              <td>Allowable tolerance</td>
              <td className="text-right">{displayDuration(weeklyTolerance)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ISCaseDetailsTable;
