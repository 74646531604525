import React, { useCallback } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/pro-solid-svg-icons';
import Amex from 'shared/components/PaymentMethods/Amex';
import Discover from 'shared/components/PaymentMethods/Discover';
import Visa from 'shared/components/PaymentMethods/Visa';
import Mastercard from 'shared/components/PaymentMethods/Mastercard';
import PlaceholderCard from 'shared/components/PaymentMethods/PlaceholderCard';
import colors from '_colors.module.scss';
import './paymentMethod.scss';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import { getContactById } from 'pages/Families/subroutes/Contacts/duck/selector';
import { getFullName } from 'shared/util/string';
import { isEmpty } from 'lodash';

interface IProps {
  paymentMethod: IPaymentMethod;
  showCardHolder?: boolean;
  className?: string;
  centered?: boolean;
}

const PaymentMethod: React.FC<IProps> = ({
  paymentMethod,
  showCardHolder = false,
  className = '',
  centered = false,
  ...props
}) => {
  const contact = useSelector((state: RootState) => getContactById(state, paymentMethod?.personId ?? ''));
  const getPaymentMethodIcon = useCallback((paymentMethod: IPaymentMethod): JSX.Element => {
    if (paymentMethod.isCreditCard) {
      switch (paymentMethod.type) {
        case 'AMEX':
          return <Amex />;
        case 'DISCOVER':
          return <Discover />;
        case 'MASTERCARD':
          return <Mastercard />;
        case 'VISA':
          return <Visa />;
        default:
          return <PlaceholderCard />;
      }
    }

    return <FontAwesomeIcon icon={faUniversity} color={colors.gray} size="lg" style={{ width: 24 }} />;
  }, []);

  const getDescriptionString = useCallback((paymentMethod: IPaymentMethod): string => {
    const type = paymentMethod.isCreditCard ? 'Card' : 'Account';

    return `${type} ending in ${paymentMethod.lastFour}`;
  }, []);

  /**
   * Check if a payment method is set to expire for the current month
   */
  const expiresSoon = useCallback((paymentMethod: IPaymentMethod): boolean => {
    if (paymentMethod.expirationDate !== null) {
      const today = moment();
      const expiration = moment(paymentMethod.expirationDate, 'YYYY-MM-DD');

      return expiration.isSame(today, 'month') && expiration.isSame(today, 'year');
    }

    return false;
  }, []);

  /**
   * Check if a payment method has expired
   */
  const hasExpired = useCallback((paymentMethod: IPaymentMethod): boolean => {
    if (paymentMethod.expirationDate !== null) {
      const today = moment();
      const expiration = moment(paymentMethod.expirationDate, 'YYYY-MM-DD');

      return expiration.isBefore(today);
    }

    return false;
  }, []);

  const withCardholder = showCardHolder && !isEmpty(contact);
  return (
    <div
      className={classnames('d-flex flex-row', className, {
        'align-items-center': !withCardholder || centered,
        'align-items-start': withCardholder,
      })}
    >
      <div className={classnames('mr-4 payment-method-logo', { 'mt-1': withCardholder && !centered })}>
        {getPaymentMethodIcon(paymentMethod)}
      </div>
      <div className="d-flex flex-1 flex-column mr-4 text-truncate">
        <div className="d-flex flex-row flex-1 align-items-center">
          <div className="mr-4 payment-method-last-four">{getDescriptionString(paymentMethod)}</div>
          {expiresSoon(paymentMethod) && (
            <span className="ml-auto text-dark rounded sm py-1 px-4 alert-warning">Expiring soon</span>
          )}
          {hasExpired(paymentMethod) && (
            <span className="ml-auto text-dark rounded sm py-1 px-4 alert-danger">Expired</span>
          )}
        </div>
        {withCardholder && (
          <div className="payment-cardholder">
            {paymentMethod.isCreditCard ? 'Card Holder' : 'Account Holder'}: {getFullName(contact)}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentMethod;
