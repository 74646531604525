import React from 'react';
import { ApplicationNote, useDeleteApplicationNoteMutation } from 'generated/graphql';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { stringToHsl } from 'shared/util/string';
import Avatar from 'shared/components/Avatar';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import ActionDropdown from 'shared/components/ActionDropdown';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { showConfirm } from 'shared/components/ConfirmationContainer';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { showToast } from 'shared/components/Toast';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IViewNoteProps {
  note: ApplicationNote;
  onEdit: () => void;
}

const ViewNote = ({ note, onEdit }: IViewNoteProps) => {
  const { t } = useTranslation();
  const allEntities = useSelector((state: RootState) => state.entities.allEntities);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const businessTimeZone = allEntities[businessId]?.timezone || moment.tz.guess();

  const [firstName = '', lastName = ''] = note.createdByName?.split(' ') ?? [];
  const initials = `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`;
  const isEdited = Boolean(note.lastEditedAt);

  const hasDeleteNotePermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementMain,
    level: RoleLevelType.Delete,
  });

  const hasEditNotePermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementMain,
    level: RoleLevelType.Edit,
  });

  const [deleteApplicationNoteMutation] = useDeleteApplicationNoteMutation({
    variables: {
      input: {
        noteId: note.id,
      },
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
    onCompleted: () => {
      showToast('Edit note successfully', 'success');
    },
    update: (cache, data) => {
      cache.modify({
        id: cache.identify(note),
        fields: {
          archivedAt: () => data.data?.deleteApplicationNote.archivedAt,
          archivedByName: () => data.data?.deleteApplicationNote.archivedByName,
        },
      });
    },
  });

  const onConfirmDelete = async () => {
    await deleteApplicationNoteMutation();
  };

  const handleDelete = () => {
    showConfirm({
      title: t('enrollment.lead-management.notes.delete-note-modal.title'),
      message: (
        <div>
          <h5 className="mb-3">{t('enrollment.lead-management.notes.delete-note-modal.message1')}</h5>
          {renderNote(false)}
          <p>{t('enrollment.lead-management.notes.delete-note-modal.message2')}</p>
        </div>
      ),
      primaryButtonLabel: t('enrollment.lead-management.notes.delete-note-modal.primary-btn-text'),
      onConfirm: onConfirmDelete,
      primaryButtonVariant: 'primary',
    });
  };

  const renderNote = (showActionDropDown: boolean) => {
    return (
      <div className="view-notes-section__note">
        {!Boolean(note.archivedAt) && (
          <div className="d-flex justify-content-between align-content-center">
            <p className="view-notes-section__note__comment">
              {note.comment}
              {isEdited && (
                <span className="font-weight-bold">{t('enrollment.lead-management.notes.edited-flag')}</span>
              )}
            </p>
            {showActionDropDown && (
              <ActionDropdown
                actions={[
                  ...(hasEditNotePermission
                    ? [
                        {
                          label: t('enrollment.lead-management.notes.edit'),
                          disabled: Boolean(note.archivedAt),
                          onClick: onEdit,
                        },
                      ]
                    : []),
                  ...(hasDeleteNotePermission
                    ? [
                        {
                          label: t('enrollment.lead-management.notes.delete'),
                          disabled: Boolean(note.archivedAt),
                          onClick: handleDelete,
                        },
                      ]
                    : []),
                ]}
                customIconButton={
                  <IconButtonCircle
                    size="sm"
                    id="action-dropdown-btn"
                    iconSize="1x"
                    icon={faEllipsisH}
                    onClick={() => {}}
                  />
                }
              />
            )}
          </div>
        )}

        <div className="d-flex justify-content-between">
          <div className="view-notes-section__note__avatar">
            {Boolean(note.archivedAt) && (
              <span className="font-weight-bolder">
                {t('enrollment.lead-management.notes.note-deleted', { name: note.archivedByName })}
              </span>
            )}
            {!Boolean(note.archivedAt) && (
              <>
                <Avatar
                  color={stringToHsl(note.createdBy)}
                  size="xs"
                  image={note.createdByAvatar?.url ?? ''}
                  initials={initials}
                  className="mr-2"
                />
                {note.createdByName}
              </>
            )}
          </div>
          <div className="view-notes-section__note__createdAt">
            {moment(note.createdAt).tz(businessTimeZone).format(t('formatters.MMM D, YYYY @ LT'))}
          </div>
        </div>
      </div>
    );
  };

  return renderNote(hasDeleteNotePermission || hasEditNotePermission);
};

export default ViewNote;
