import React, { useState } from 'react';
import CcsCategoryCards from './CcsCategoryCards';
import CcsTable from './CcsTable';
import { useGetCcsEnrolmentCategoryCountForCenters, useGetCcsEnrolmentsForCenters } from 'gql/ccssEnrolment/queries';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import moment from 'moment';

interface IProps {
  businessId: string;
  centerIds: string[];
}

const CcsEnrolmentsTab: React.FC<IProps> = ({ businessId, centerIds, ...props }) => {
  // Table state
  const [tableState, tableFunctions, setDatatableState] = useDatatableState();
  const [searchText, setSearchText] = useState<undefined | string>(undefined);
  const [filteredStatus, setFilteredStatus] = useState<string[]>(['Active']);

  // Sort
  const [sort, setSort] = useState<IElasticsearchSortFilter[]>();
  const onSort = (field: string, direction: ElasticsearchSortDirection) => setSort([{ field, direction }]);

  // Enrolment Category Count
  const { data: enrolmentCategoryCounts } = useGetCcsEnrolmentCategoryCountForCenters({
    variables: {
      businessId: businessId,
      centerIds: centerIds,
    },
  });

  // Enrolment Status Category filtering
  const [enrolmentCategoryFilter, setEnrolmentCategoryFilter] = useState<EnrolmentCategory | null>(null);
  const onSelected = (selectedStatus: EnrolmentCategory | null) => {
    setEnrolmentCategoryFilter(selectedStatus);
  };

  const handleFilteredStatus = (value: string[]) => {
    setFilteredStatus(value);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  useEffect(() => {
    tableFunctions.changePage(1, tableState.pageSize);
  }, [enrolmentCategoryFilter]);

  // Data
  const { data: ccsEnrolments, loading: ccsEnrolmentsLoading } = useGetCcsEnrolmentsForCenters({
    variables: {
      businessId: businessId,
      centerIds: centerIds,
      ...(enrolmentCategoryFilter && { category: enrolmentCategoryFilter }),
      searchText: searchText === '' ? undefined : searchText,
      pageSize: tableState.pageSize,
      pageNumber: tableState.activePage,
      sortDtos: sort,
      statusType:
        !!filteredStatus && !!filteredStatus.length
          ? filteredStatus.length > 1
            ? undefined
            : (filteredStatus[0] as AccountStatusType)
          : undefined,
      statusAtDate: moment().format('YYYY-MM-DD'),
    },
  });

  return (
    <Row noGutters>
      <Col>
        <Row className="mb-4">
          <Col>
            <CcsCategoryCards
              onSelected={onSelected}
              activeFilter={enrolmentCategoryFilter}
              counts={enrolmentCategoryCounts?.getCcsEnrolmentCategoryCountForCenters ?? EmptyEnrolmentCategoryCount}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CcsTable
              showLoadingOverlay={ccsEnrolmentsLoading}
              data={ccsEnrolments?.getCcsEnrolmentsForCenters?.data ?? []}
              showPagination={true}
              dataSize={ccsEnrolments?.getCcsEnrolmentsForCenters?.totalRecords ?? 0}
              pageSize={tableState.pageSize}
              activePage={tableState.activePage}
              onSearch={setSearchText}
              onPageChange={tableFunctions.changePage}
              onSizePerPageChange={tableFunctions.changeSizePerPage}
              onSort={onSort}
              filteredStatus={filteredStatus}
              onStatusFilter={handleFilteredStatus}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CcsEnrolmentsTab;

const EmptyEnrolmentCategoryCount: Record<EnrolmentCategory, number> = {
  Active: 0,
  ActionRequired: 0,
  Pending: 0,
  Inactive: 0,
};
