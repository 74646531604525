import React, { useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import useUniqueId from 'shared/hooks/useUniqueId';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import HelpTooltip from '../Tooltip/HelpTooltip';
import './dateInput.scss';
import { DefaultCountry } from './countryCodes';
// react-dates is initialized in the top level index.js file

interface CustomTime {
  hour: number;
  minute: number;
  second: number;
}
export interface IDateInputProps {
  id?: string;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  appendToBody?: boolean;
  date?: string | null;
  maxDate?: moment.Moment | null;
  onDateSelect?: (date: string) => void;
  className?: string;
  placeholder?: string;
  customTime?: CustomTime;
  isOutsideRange?: (day: any) => boolean;
  helpTooltipText?: string;
  helpTooltipDirection?: 'left' | 'right' | 'top' | 'bottom';
  isValid?: boolean;
  hasCustomValidation?: boolean;
  timezone?: string;
  isDayBlocked?: (day: any) => boolean;
  initialVisibleMonth?: () => any | null;
  dateOnly?: boolean;
  firstDayOfWeek?: boolean;
}

export interface IInputHelperState {
  borderClass: 'success' | 'error' | 'focused' | '';
}

const DateInput: React.FC<IDateInputProps> = ({
  id,
  label,
  required = false,
  autoFocus = false,
  disabled = false,
  appendToBody = false,
  date,
  onDateSelect,
  initialVisibleMonth,
  className,
  placeholder = 'Select Date',
  customTime,
  isOutsideRange = () => false,
  maxDate,
  helpTooltipText,
  helpTooltipDirection,
  isValid,
  timezone,
  hasCustomValidation,
  dateOnly = false,
  firstDayOfWeek,
  ...props
}) => {
  const controlId = useUniqueId(id);
  const [focused, setFocused] = useState(autoFocus);
  const [touched, setTouched] = React.useState(autoFocus);

  const [inputHelperState, setInputHelperState] = React.useState<IInputHelperState>({
    borderClass: '',
  });

  const onDateChange = useCallback(
    (_date) => {
      if (_date && customTime) {
        _date.set(customTime);
      }
      const dateAsString = _date ? moment(_date).format(dateOnly ? 'YYYY-MM-DD' : undefined) : _date;
      onDateSelect && onDateSelect(dateAsString);
    },
    [customTime, onDateSelect]
  );

  const onFocusChange = useCallback(
    ({ focused }) => {
      setTouched(true);
      setFocused(focused);
    },
    [setFocused]
  );

  // return an array of <select> components where each value is a year
  const renderYearSelection = useCallback(() => {
    const years: React.ReactNode[] = [];

    const maxYear = maxDate ? maxDate.year() : moment().year() + 10;
    // generate an array of years from 100 years prior to 10 years in the future
    for (let i = moment().year() - 100; i <= maxYear; i++) {
      years.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return years;
  }, []);

  useEffect(() => {
    if (focused) {
      setInputHelperState({ borderClass: 'focused' });
    } else if (touched) {
      // use the isValid if it's provided otherwise only check if the date isn't null
      const valid = isValid ?? date !== null;

      if (required) {
        setInputHelperState({ borderClass: valid ? 'success' : 'error' });
      } else if (hasCustomValidation) {
        setInputHelperState({ borderClass: isValid ? '' : 'error' });
      }
    }
  }, [touched, isValid, required, date, focused]);

  const setDateFromTextInput = (e: KeyboardEvent): void => {
    const evt = e.target as HTMLInputElement;
    if (evt && evt.value) {
      const val = evt.value as string;
      const possibleDate = moment(val, DefaultCountry.dateFormat).tz(timezone ?? moment.tz.guess());
      const strings = val.split('/');
      if (possibleDate.isValid() && strings[2] && strings[2].length === 4) {
        onDateChange(possibleDate);
      }
    }
  };

  useEffect((): (() => void) => {
    const datePicker = document.getElementById(controlId);
    if (datePicker) {
      datePicker.addEventListener('keyup', setDateFromTextInput);
    }
    return (): void => {
      if (datePicker) {
        datePicker.removeEventListener('keyup', setDateFromTextInput);
      }
    };
  });
  const selectedDate = date ? moment(date).tz(timezone ?? moment.tz.guess()) : null;
  const visibleMonth = selectedDate ? () => selectedDate : initialVisibleMonth;

  return (
    <div className={`kt-date-input ${className}`}>
      {label && (
        <div className="d-flex flex-row">
          {label && <Form.Label>{label}</Form.Label>}
          {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
          {helpTooltipText && <HelpTooltip text={helpTooltipText} direction={helpTooltipDirection} />}
        </div>
      )}
      <div
        className={`SingleDatePicker_picker__container ${
          inputHelperState.borderClass && 'SingleDatePicker_picker__container-' + inputHelperState.borderClass
        }`}
      >
        <SingleDatePicker
          enableOutsideDays
          customInputIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
          id={controlId}
          block
          noBorder
          appendToBody={appendToBody} // this prop may have to be set to true when used in a modal, ymmv
          placeholder={placeholder}
          hideKeyboardShortcutsPanel
          date={selectedDate}
          focused={focused}
          numberOfMonths={1}
          onDateChange={onDateChange}
          onFocusChange={onFocusChange}
          disabled={disabled}
          isOutsideRange={isOutsideRange}
          displayFormat={DefaultCountry.dateFormat}
          firstDayOfWeek={DefaultCountry.firstDayOfWeek}
          initialVisibleMonth={visibleMonth}
          renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
            <div className="d-flex justify-content-center">
              <div>
                <select
                  className="kt-date-input-select"
                  value={month.month()}
                  onChange={(event) => {
                    onMonthSelect(month, event.target.value);
                    date && onDateChange(moment(date).set('month', parseInt(event.target.value)));
                  }}
                >
                  {moment.months().map((label, value) => (
                    <option value={value} key={label}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  className="kt-date-input-select"
                  value={month.year()}
                  onChange={(event) => {
                    onYearSelect(month, event.target.value);
                    date && onDateChange(moment(date).set('year', parseInt(event.target.value)));
                  }}
                >
                  {renderYearSelection()}
                </select>
              </div>
            </div>
          )}
          {...props}
        />
      </div>
    </div>
  );
};

export default DateInput;
