import React from 'react';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { Badge } from 'react-bootstrap';
import colors from '_colors.module.scss';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import Tooltip from 'shared/components/Tooltip';

interface IProps {
  status: {
    active: boolean;
    available: boolean;
    availableReason?: string;
  };
}

const CasualBookingStatusCell: React.FC<IProps> = ({ status }) => {
  const { t } = useTranslation();
  const { active, available, availableReason } = status;

  if (available) {
    return (
      <ColoredBackgroundTag
        color={active ? colors.success : colors['dark-gray']}
        text={capitalize(active ? t('spelling.active') : t('spelling.inactive'))}
        tooltipDirection={'top'}
        tooltipText={capitalize(active ? t('spelling.active') : t('spelling.inactive'))}
      />
    );
  }
  return (
    <Tooltip
      text={
        availableReason ??
        capitalize(t('attendance.settings.booking-rules.centers.data-table.status.unavailable-helper'))
      }
      direction="top"
    >
      <Badge className="py-2">
        <>
          <FontAwesomeIcon icon={faExclamationCircle} color={colors.danger} size="1x" className="mr-1" />
          {capitalize(t('spelling.unavailable'))}
        </>
      </Badge>
    </Tooltip>
  );
};

export default CasualBookingStatusCell;
