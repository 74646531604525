import React from 'react';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col, Row } from 'react-bootstrap';
import Button from 'shared/components/Buttons';
import DateInput from 'shared/components/DateInput';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import Tooltip from 'shared/components/Tooltip';
import colors from '_colors.module.scss';
import './ccsDebtRow.scss';

interface IProps {
  data: ICcsDebt;
  handleMakeArrangement: () => void;
}

const CCSDebtRow: React.FC<IProps> = ({ data, handleMakeArrangement }) => {
  const {
    originalAmount,
    reason,
    ccssProviderId,
    legislationReferenceForTheDebt,
    totalInterestCharged,
    alternateArrangement,
  } = data;

  return (
    <div className="mt-5 mb-5 mr-2 ml-2">
      <Row>
        <Col className="debt-detail-col text-wrap" style={{ maxWidth: '14%', flex: '0 0 14%' }}>
          <label>Original Amount</label>
          <p>${originalAmount.toFixed(2)}</p>
        </Col>
        <Col className="debt-detail-col text-wrap" sm={2} md={2}>
          <label>Debt Reason</label>
          <p>{reason}</p>
        </Col>
        <Col className="debt-detail-col text-wrap" sm={2} md={2}>
          <label>Legislation Reference</label>
          <p>{legislationReferenceForTheDebt}</p>
        </Col>
        <Col className="debt-detail-col text-wrap" sm={2} md={2}>
          <label>Total interest for debt</label>
          <p>${totalInterestCharged.toFixed(2)}</p>
        </Col>
        <Col className="text-right">
          <Button variant="secondary" onClick={handleMakeArrangement}>
            Make alternate arrangement
          </Button>
        </Col>
      </Row>
      {alternateArrangement && (
        <>
          <hr className="mt-7 mb-7" />
          <div>
            <h5 className="mb-4">Alternate payment arrangement details</h5>
            <Row>
              <Col lg={2}>
                <TextInput disabled label="Alternate ID" value={alternateArrangement.id} />
              </Col>
              <Col lg={3}>
                <label>
                  Alternate Status{' '}
                  <Tooltip text="[placeholder]" direction="top">
                    <FontAwesomeIcon icon={faInfoCircle} color={colors.steelBlue} />
                  </Tooltip>
                </label>
                <TextInput disabled value={alternateArrangement.status} />
              </Col>
              <Col>
                <DateInput
                  disabled
                  label="Date Submitted"
                  className="date-block"
                  date={alternateArrangement.dateSubmitted}
                />
              </Col>
              <Col>
                <TextInput disabled label="CCS Provider ID" value={ccssProviderId} />
              </Col>
              <Col>
                <TextInput
                  disabled
                  label="Total amount to be paid"
                  value={`$ ${alternateArrangement.totalAmount.toFixed(2)}`}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={2}>
                <label>
                  Requested start date{' '}
                  <Tooltip text="[placeholder]" direction="top">
                    <FontAwesomeIcon icon={faInfoCircle} color={colors.steelBlue} />
                  </Tooltip>
                </label>
                <DateInput disabled className="date-block" date={alternateArrangement.startDate} />
              </Col>

              <Col lg={1}>
                <NumberInput disabled label="Payment period" value={alternateArrangement.paymentPeriod} />
              </Col>
              <Col>
                <p className="mt-9">Months </p>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <TextInput
                  disabled
                  label="Service IDs to be used for Offsetting"
                  value={alternateArrangement.serviceId}
                />
              </Col>
              <Col>
                <p className="mt-9">Service Name, Service Name </p>
              </Col>
              <Col lg={2}>
                <NumberInput disabled label="Offset Percentage" value={alternateArrangement.offsetPercentage} />
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default CCSDebtRow;
