import React, { useState, useCallback } from 'react';
import { Row } from 'shared/components/Layout';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable';
import MedicalTable from './MedicalTable';
import EditMedicalConditionModal from 'shared/components/Medical/EditMedicalConditionModal';
import InformationCard from './InformationCard';
import { showToast } from 'shared/components/Toast';
import { useArchiveAllergyForStaff, useUpdateAllergyForStaff } from 'gql/allergy/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetStaffMedicalData } from 'gql/staff/queries';
import cast from '../../../../../../shared/util/cast';
import ArchiveMedicalConditionModal from '../../../../../../shared/components/Medical/ArchiveMedicalConditionModal';
import UpdateAllergyModal from '../../../../../../shared/components/Modals/UpdateAllergyModal';
import ArchiveAllergyModal from '../../../../../../shared/components/Modals/ArchiveAllergyModal';
import { IAllergyInputGroupFormShape } from '../../../../../../shared/components/InputGroups/AllergyInputGroup';
import Switch from '../../../../../../shared/components/Switch';
import { updateStaffAllergy } from '../../../../../../store/employees/actions';

interface IHealthItemActionModalState<T> {
  open: boolean;
  item: T | null;
}

interface IProps {
  staffId: string;
  readOnly: boolean;
}

const MedicalTab: React.FC<IProps> = ({ staffId, readOnly }) => {
  const { allergies, medicalConditions } = useSelector((state: RootState) => state.employees.byId[staffId]) ?? {
    medicalConditions: [],
    allergies: [],
  };
  const dispatch = useDispatch();
  const { loading } = useGetStaffMedicalData(staffId);
  const [isEditMedicalConditionOpen, setEditMedicalConditionOpen] = useState<IMedicalCondition | null>(null);
  const [medicalConditionInArchiveModal, setMedicalConditionInArchiveModal] = useState<IMedicalCondition | null>(null);
  const [archiveAllergyModalState, setArchiveAllergyModalState] = useState<IHealthItemActionModalState<IAllergy>>({
    open: false,
    item: null,
  });
  const [editAllergyModalState, setEditAllergyModalState] = useState<IHealthItemActionModalState<IAllergy>>({
    open: false,
    item: null,
  });
  const [archiveAllergyForStaffFn] = useArchiveAllergyForStaff({
    onCompleted: (result) => {
      showToast('Allergy removed successfully.', 'success');
      dispatch(updateStaffAllergy(result.archiveAllergyForStaff));
      setArchiveAllergyModalState({ open: false, item: null });
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const [showArchived, setShowArchived] = useState(false);

  const [updateAllergyForStaffFn] = useUpdateAllergyForStaff({
    onCompleted: (result) => {
      showToast('Allergy updated successfully.', 'success');
      setEditAllergyModalState({ open: false, item: null });
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleUpdateStaffAllergy = useCallback(
    (updatedAllergy: IAllergyInputGroupFormShape) => {
      if (editAllergyModalState.item) {
        updateAllergyForStaffFn({
          variables: {
            input: {
              allergyId: editAllergyModalState.item.id,
              staffId: editAllergyModalState.item.personId as string,
              allergenId: updatedAllergy.allergen?.id as string,
              instructions: updatedAllergy.instructions as string,
              severity: updatedAllergy.severity as AllergenSeverity,
              reactions: updatedAllergy.reactions?.map((option) => option.value) ?? [],
            },
          },
        });
      }
    },
    [editAllergyModalState, updateAllergyForStaffFn]
  );

  const handleArchiveStaffAllergy = useCallback(
    (allergy: IAllergy | null) => {
      if (allergy) {
        archiveAllergyForStaffFn({
          variables: {
            allergyId: allergy.id,
            staffId,
          },
        });
      }
    },
    [archiveAllergyForStaffFn, staffId]
  );

  if (loading) return <DataTableLoadingSkeleton />;

  return (
    <div>
      <InformationCard />
      <Row noGutters>
        <Switch
          className="ml-auto"
          value={showArchived}
          onChange={setShowArchived}
          label="Show Archived"
          labelSide="left"
        />
      </Row>
      <MedicalTable
        title="Medical Conditions"
        category="Medical"
        data={medicalConditions.filter((mc) => showArchived || !mc.archivedAt)}
        onEdit={(mc) => setEditMedicalConditionOpen(cast<IMedicalCondition>(mc))}
        onArchive={(mc) => setMedicalConditionInArchiveModal(cast<IMedicalCondition>(mc))}
        loading={loading}
        className="mb-6"
      />
      <MedicalTable
        title="Allergies"
        category="Allergy"
        data={allergies.filter((a) => showArchived || !a.archivedAt)}
        loading={loading}
        onArchive={(a) => setArchiveAllergyModalState({ open: true, item: cast<IAllergy>(a) })}
        onEdit={(a) => setEditAllergyModalState({ open: true, item: cast<IAllergy>(a) })}
        className="mb-6"
      />
      {isEditMedicalConditionOpen && (
        <EditMedicalConditionModal
          isOpen={Boolean(isEditMedicalConditionOpen)}
          onClose={() => setEditMedicalConditionOpen(null)}
          medicalCondition={isEditMedicalConditionOpen}
          readOnly={false}
        />
      )}
      {medicalConditionInArchiveModal && (
        <ArchiveMedicalConditionModal
          isOpen={Boolean(medicalConditionInArchiveModal)}
          onClose={() => setMedicalConditionInArchiveModal(null)}
          medicalCondition={medicalConditionInArchiveModal}
        />
      )}
      {editAllergyModalState.item && (
        <UpdateAllergyModal
          isOpen={editAllergyModalState.open}
          allergy={editAllergyModalState.item}
          onClose={() => setEditAllergyModalState({ open: false, item: null })}
          onSave={handleUpdateStaffAllergy}
        />
      )}
      <ArchiveAllergyModal
        isOpen={archiveAllergyModalState.open}
        allergy={archiveAllergyModalState.item}
        onClose={() => setArchiveAllergyModalState({ open: false, item: null })}
        onSave={() => handleArchiveStaffAllergy(archiveAllergyModalState.item)}
      />
    </div>
  );
};

export default MedicalTab;
