import React, { useState, useCallback } from 'react';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { ButtonAsLink } from 'shared/components/Buttons';
import { somePropsEmpty, omitTypename } from 'shared/util/object';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import EmergencyContactInput from 'shared/components/EmergencyContactInput';
import errorMessage from 'shared/constants/errorMessages';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import { useUpdateStaffEmergencyContacts } from 'gql/staff/mutations';
import Alert from 'shared/components/Alert';
import { showToast } from 'shared/components/Toast';
import { isValidPhoneNumber } from 'shared/util/string';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers';

const newEmergencyContact: IEmergencyContact = {
  firstname: '',
  lastname: '',
  relationship: '',
  phoneNumber: '',
};

interface IProps {
  staff: IStaff;
  readOnly?: boolean;
}

const EmergencyContactsForm: React.FC<IProps> = ({ staff, readOnly = false }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;
  const [primaryEmergencyContact, updateprimaryEmergencyContact] = useState(
    staff.primaryEmergencyContact || newEmergencyContact
  );
  const [additionalEmergencyContacts, updateAdditionalEmergencyContacts] = useState(
    staff.additionalEmergencyContacts || []
  );
  const [updateEmergencyContacts, { loading, error }] = useUpdateStaffEmergencyContacts(staff.id);

  const resetFormData = useCallback(() => {
    updateprimaryEmergencyContact(staff.primaryEmergencyContact || newEmergencyContact);
    updateAdditionalEmergencyContacts(staff.additionalEmergencyContacts || []);
  }, [staff]);

  const handleUpdatePrimaryEmergencyContact = useCallback((updatedEmergencyContact: IEmergencyContact) => {
    updateprimaryEmergencyContact(updatedEmergencyContact);
    setFormIsDirty(true);
  }, []);

  const handleUpdateAdditionalEmergencyContacts = useCallback(
    (updatedEmergencyContact: IEmergencyContact, index: number) => {
      const updatedAdditionalEmergencyContacts = additionalEmergencyContacts.map((ec, i) =>
        i === index ? updatedEmergencyContact : ec
      );
      updateAdditionalEmergencyContacts(updatedAdditionalEmergencyContacts);
      setFormIsDirty(true);
    },
    [additionalEmergencyContacts]
  );

  const addAdditionalEmergencyContact = useCallback(() => {
    updateAdditionalEmergencyContacts([...additionalEmergencyContacts, newEmergencyContact]);
    setFormIsDirty(true);
  }, [additionalEmergencyContacts]);

  const formInvalid =
    somePropsEmpty(primaryEmergencyContact) ||
    !(primaryEmergencyContact.phoneNumber && isValidPhoneNumber(primaryEmergencyContact.phoneNumber)) ||
    additionalEmergencyContacts.some(
      (ec) => somePropsEmpty(ec) || !(ec.phoneNumber && isValidPhoneNumber(ec.phoneNumber))
    );

  const submitForm = useCallback(() => {
    const omittedAdditionalTypenames = additionalEmergencyContacts.map((emergencyContact) =>
      omitTypename(emergencyContact)
    );

    updateEmergencyContacts({
      variables: {
        input: {
          id: staff.id,
          primaryEmergencyContact: omitTypename(primaryEmergencyContact),
          additionalEmergencyContacts: omittedAdditionalTypenames,
        },
      },
    }).then(() => {
      showToast('Updated emergency contacts successfully.', 'success');
      setFormIsDirty(false);
    });
  }, [updateEmergencyContacts, staff.id, primaryEmergencyContact, additionalEmergencyContacts]);

  if (error) {
    return <Alert variant="danger">{errorMessage.generic}</Alert>;
  }
  if (loading) {
    return <LoadingLines number={2} />;
  }

  return (
    <FormWrapper2
      onCancel={resetFormData}
      onSave={submitForm}
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      saveDisabled={formInvalid}
    >
      <EmergencyContactInput
        emergencyContact={primaryEmergencyContact}
        updateEmergencyContact={handleUpdatePrimaryEmergencyContact}
        readOnly={readOnly}
      />
      {additionalEmergencyContacts.map((emergencyContact, index) => (
        <EmergencyContactInput
          key={index}
          emergencyContact={emergencyContact}
          updateEmergencyContact={(emergencyContact) =>
            handleUpdateAdditionalEmergencyContacts(emergencyContact, index)
          }
          readOnly={readOnly}
        />
      ))}
      {!AdpEnabled && (
        <ButtonAsLink
          className="sm mr-auto"
          onClick={addAdditionalEmergencyContact}
          disabled={
            somePropsEmpty(primaryEmergencyContact) ||
            somePropsEmpty(additionalEmergencyContacts[additionalEmergencyContacts.length - 1]) ||
            readOnly
          }
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add another emergency contact
        </ButtonAsLink>
      )}
      {formInvalid && formIsDirty && <small className="mt-4">{errorMessage.formRequirements}</small>}
    </FormWrapper2>
  );
};

export default EmergencyContactsForm;
