import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MessagePayload } from '@pubnub/react-chat-components';
import { stringToHsl } from 'shared/util/string';
import { RootState } from 'store/reducers';
import { BulletinBoardFilters } from '../../../BulletinBoards';
import AvatarContent from '../../../../../../../shared/components/Avatar/AvatarContent';
import { MessageMention, CustomProperty } from '../types';

interface IProps {
  avatarUrl: string;
  messageSender: string;
  createdAt: string;
  messageText: string;
  payload?: MessagePayload;
  parentMessageId?: string | null;
  isOwn?: boolean;
  setThread?: (parentThreadMessageId: string | null) => void;
  replies?: Array<MessagePayload>;
  filterMode?: BulletinBoardFilters;
}

const MessageBubble: React.FC<IProps> = ({
  payload,
  parentMessageId,
  avatarUrl,
  messageText,
  messageSender,
  createdAt,
  isOwn,
  setThread,
  replies,
  filterMode,
}) => {
  const customData = (payload && (payload.custom as CustomProperty)) ?? null;
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();
  const mentions = (customData && customData.directedAt) ?? null;
  const mentionRegex = new RegExp('@\\[__([0-9a-fA-F\\-]+)__\\]\\(__(\\w+)__\\)', 'g');

  const getInitials = (name: string | null | undefined) => {
    if (name === null || name === undefined) return '';
    const initials = name
      .split(' ')
      .map((word) => (/^[A-Za-z0-9 -]*$/.test(word[0]) ? word[0] : null))
      .join('')
      .toUpperCase();

    if (initials.length <= 2) return initials;
    else return `${initials[0]}${initials[initials.length - 1]}`;
  };

  const getMentionedNames = (mentionedEntities: MessageMention[]) => {
    const names = mentionedEntities.map((mentionedUser) => mentionedUser.displayName);
    let result = '';
    if (names.length === 1) {
      result = names[0];
    } else {
      const name = names.pop();
      result = names.join(', ') + ' and ' + name;
    }
    return result;
  };

  const renderMentions = () => {
    if (!mentions || mentions.length === 0) return null;
    const mentionedNames = getMentionedNames(mentions);

    return <p className="pn-msg-mentions">Mentioned {mentionedNames}</p>;
  };

  const handleClickReplies = () => {
    if (!setThread) return null;
    setThread(payload?.id ?? null);
  };

  const renderReplies = () => {
    return (
      <button onClick={handleClickReplies} className="pn-replies-button">
        {replies && replies.length} replies
      </button>
    );
  };

  const handleClickViewInThread = () => {
    if (!setThread) return null;
    setThread(parentMessageId ?? null);
  };

  const renderViewInThread = () => {
    return (
      <button onClick={handleClickViewInThread} className="pn-view-thread-button">
        view in thread
      </button>
    );
  };

  const processMessageText = (messageText) => {
    let mentionMatch;
    while ((mentionMatch = mentionRegex.exec(messageText)) !== null) {
      messageText = messageText.replaceAll(mentionMatch[0], `@${mentionMatch[2]}`);
      mentionRegex.exec(messageText);
    }
    return messageText;
  };

  return (
    <div>
      <div className="pn-msg-bubble">
        <p>{processMessageText(messageText)}</p>
        {renderMentions()}
      </div>
      <div className="pn-msg-info">
        <div className="avatar d-inline-block float-none mr-2 ml-2">
          <AvatarContent
            size="xs"
            image={avatarUrl}
            initials={getInitials(messageSender)}
            alt={`${messageSender} avatar`}
            color={stringToHsl(messageSender ?? '1')}
          />
        </div>
        <div className={`pn-msg-info__details ${isOwn ? 'isOwn' : ''}`}>
          <p>{messageSender}</p>
          <p>{moment(createdAt).tz(timezone).format('MMM D[,] YYYY [@] hh:mmA').toString()}</p>
        </div>
      </div>
      {replies && filterMode === BulletinBoardFilters.None ? renderReplies() : null}
      {parentMessageId && filterMode === BulletinBoardFilters.Mentions ? renderViewInThread() : null}
    </div>
  );
};

export default MessageBubble;
