import React, { useCallback } from 'react';
import { useUpdateCustomFieldValues } from 'gql/customField/mutation';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'shared/components/Card';
import CustomFieldForm from 'shared/components/CustomFieldForm';
import { Col, Row } from 'shared/components/Layout';
import { showToast } from 'shared/components/Toast';
import { RootState } from 'store/reducers';
import { ICustomField, ICustomFieldValue } from 'shared/types/customField';
import CustomDataFieldsHeader from 'shared/components/Card/CardHeaderWithArchivedToggle';

interface IProps {
  child?: IChild;
  loading: boolean;
  customFields: ICustomField[];
  values: ICustomFieldValue[];
  refetch: () => void;
  onShowArchivedChange: (showArchived: boolean) => void;
  showArchived: boolean;
}
const ChildCustomFieldsTab: React.FC<IProps> = ({
  child,
  customFields,
  values,
  loading,
  refetch,
  onShowArchivedChange,
  showArchived,
}) => {
  const { t } = useTranslation();
  const businessId = useSelector((state: RootState) => state.user?.entityId) ?? '';

  const [updateValues, { loading: updateLoading }] = useUpdateCustomFieldValues({
    onCompleted: (res) => {
      refetch();
      showToast(t('child.custom-fields.update-field-values-success'), 'success');
    },
  });

  const handleSave = useCallback(
    (values) => {
      updateValues({
        variables: {
          input: {
            businessId: businessId,
            entityId: child?.id ?? '',
            area: 'Child',
            values,
          },
        },
      });
    },
    [child, businessId, updateValues]
  );

  return (
    <Row align="start">
      <Col md={6}>
        <Card
          header={
            <CustomDataFieldsHeader
              isArchived={showArchived}
              onArchivedToggleChange={onShowArchivedChange}
              title={t('custom-data-fields.custom-fields-card-title')}
            />
          }
          loading={loading}
        >
          {customFields.length > 0 ? (
            <CustomFieldForm
              values={values}
              customFields={customFields}
              isReadOnly={false}
              onSave={handleSave}
              loading={updateLoading}
              entityId={child?.id ?? ''}
            />
          ) : (
            <p>{t('custom-data-fields.no-info-to-display')}</p>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default ChildCustomFieldsTab;
