import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { getNotificationTypeLabel } from 'shared/constants/enums/notificationTypes';

interface IProps {
  isOpen: boolean;
  type: string | null;
  category: NotificationCategoryV2 | null;
  onClose: () => void;
  onConfirm?: () => void;
}

const MuteNotificationsConfirmModal: React.FC<IProps> = ({ isOpen, category, type, onClose, onConfirm, ...props }) => {
  const { t } = useTranslation();
  const dimissModal = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const notificationTypeLabel = `${t(
    `notification-v2.categories.${category ?? 'Enrolment'}`
  )} - ${getNotificationTypeLabel(category ?? undefined, type ?? undefined)}`;

  return (
    // @ts-ignore
    <ConfirmationModal
      title="Are you sure?"
      show={isOpen}
      onHide={dimissModal}
      hideOnCallback={true}
      primaryChoice={'Mute'}
      primaryCallback={onConfirm}
      primaryButtonProps={{
        variant: 'danger',
      }}
      secondaryCallback={dimissModal}
    >
      {t('notification-v2.mute-confirmation-modal.body', { type: notificationTypeLabel })}
    </ConfirmationModal>
  );
};

export default MuteNotificationsConfirmModal;
