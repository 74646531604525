import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';

interface IGallerySettings {
  centerId: string;
  businessId: string;
  autoApprove: boolean;
  releaseOnCheckout: boolean;
}

interface IGallerySettingsInput {
  input: {
    centerId: string;
    businessId: string;
    autoApprove: boolean;
    releaseOnCheckout: boolean;
  };
}

export const UPDATE_GALLERY_SETTINGS = gql`
  mutation ($input: GallerySettingsInput!) {
    upsertGallerySettings(input: $input) {
      centerId
      businessId
      autoApprove
      releaseOnCheckout
    }
  }
`;

export const useUpdateGallerySettings = (
  input?: MutationHookOptions<{ upsertGallerySettings: IGallerySettings[] }, IGallerySettingsInput>
) => useMutation<{ upsertGallerySettings: IGallerySettings[] }, IGallerySettingsInput>(UPDATE_GALLERY_SETTINGS, input);
