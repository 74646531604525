import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { transactionFields, transactionTypeFields } from './fields';

interface ICreateTransactionVariables {
  input: ICreateTransactionInput;
}

interface ICreateTransactionData {
  createTransaction: ITransaction;
}

interface ICreateReverseTransactionVariables {
  transactionId: string;
  reason: string;
}

interface ICreateReverseTransactionData {
  createReverseTransaction: ITransaction;
}
interface ICreateTransactionTypeVariables {
  input: ICreateTransactionTypeInput;
}
interface ICreateTransactionTypeData {
  createTransactionType: ITransactionType[];
}

interface IArchiveTransactionTypeVariables {
  id: string;
}

interface IArchiveTransactionTypeData {
  archiveTransactionType: ITransactionType;
}

interface ICreateTransactionsForSessionsVariables {
  input: ICreateTransactionsForSessionsInput;
}

interface ICreateTransactionsForSessionsData {
  createTransactionsForSessions: ITransaction[];
}

interface ICreateManualPaymentVariables {
  input: {
    accountId: string;
    paymentMethodId: string | null;
    nonDigitalPaymentType: NonDigitalPaymentType | null;
    amount: number;
    date: string;
    description?: string;
  };
}

interface ICreateManualPaymentData {
  createManualPayment: ITransaction;
}

interface IApprovedFlaggedPaymentVariables {
  paymentId: string;
}

interface IApproveFlaggedPaymentData {
  approvePayment: ITransaction | null;
}

interface ICancelFlaggedPaymentVariables {
  paymentId: string;
}

interface ICancelFlaggedPaymentData {
  cancelPayment: ITransaction | null;
}

interface IBouncedCheckVariables {
  paymentId: string;
}

interface IBouncedCheckData {
  bouncedCheck: ITransaction | null;
}

interface IUpdateFlaggedPaymentVariables {
  input: {
    paymentId: string;
    amount: number;
  };
}

interface IUpdateFlaggedPaymentData {
  updatePayment: ITransaction | null;
}

interface IApplyDiscountToTransactionVariables {
  input: {
    transactionId: string;
    discountId: string;
  };
}

interface IApplyDiscountToTransactionData {
  applyDiscountToTransaction: ITransaction | null;
}

interface IRemoveDiscountFromTransactionVariables {
  input: {
    transactionId: string;
    discountTransactionId: string;
  };
}

interface IRemoveDiscountFromTransactionData {
  removeDiscountFromTransaction: ITransaction;
}

export const CREATE_TRANSACTION = (fields: string = transactionFields) => gql`
  mutation($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      ${fields}
    }
  }
`;

export const CREATE_REVERSE_TRANSACTION = (fields: string = transactionFields) => gql`
  mutation($transactionId: ID!, $reason: String!) {
    createReverseTransaction(transactionId: $transactionId, reason: $reason) {
      ${fields}
  }
}
`;

export const CREATE_TRANSACTION_TYPE = (fields: string = transactionTypeFields) => gql`
mutation($input: CreateTransactionTypeInput!) {
  createTransactionType(input: $input) {
    ${fields}
  }
}
`;

export const CREATE_TRANSACTION_TYPE_BULK = (fields: string = transactionTypeFields) => gql`
mutation($input: CreateTransactionTypeInput!) {
  createTransactionTypesInBulk(input: $input) {
    ${fields}
  }
}
`;

const UPDATE_TRANSACTION_TYPE = gql`
  mutation($input: UpdateTransactionTypeInput!) {
    updateTransactionType(input: $input) {
      ${transactionTypeFields}
    }
  }
`;

export const ARCHIVE_TRANSACTION_TYPE = (fields: string = transactionTypeFields) => gql`
  mutation($id: ID!) {
    archiveTransactionType(id: $id) {
      ${fields}
    }
  }
`;

export const CREATE_TRANSACTIONS_FOR_SESSIONS = (fields: string = transactionFields) => gql`
  mutation($input: CreateTransactionsForSessionsInput!) {
    createTransactionsForSessions(input: $input) {
      ${fields}
    }
  }
`;

export const CREATE_MANUAL_PAYMENT = (fields: string = transactionFields) => gql`
  mutation($input: CreateManualPaymentInput!) {
    createManualPayment(input: $input) {
      ${fields}
    }
  }
`;

export const APPROVE_FLAGGED_PAYMENT = (fields: string = transactionFields) => gql`
  mutation($paymentId: ID!) {
    approvePayment(paymentId: $paymentId) {
      ${fields}
    }
  }
`;

export const BOUNCED_FLAGGED_PAYMENT = (fields: string = transactionFields) => gql`
  mutation($paymentId: ID!) {
    bouncedCheck(paymentId: $paymentId) {
      ${fields}
    }
  }
`;

export const CANCEL_FLAGGED_PAYMENT = (fields: string = transactionFields) => gql`
  mutation($paymentId: ID!) {
    cancelPayment(paymentId: $paymentId) {
      ${fields}
    }
  }
`;

export const UPDATE_FLAGGED_PAYMENT = (fields: string = transactionFields) => gql`
  mutation($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      ${fields}
    }
  }
`;

export const APPLY_DISCOUNT_TO_TRANSACTION = (fields: string = transactionFields) => gql`
  mutation($input: ApplyDiscountToTransactionInput!) {
    applyDiscountToTransaction(input: $input) {
      ${fields}
    }
  }
`;

export const REMOVE_DISCOUNT_FROM_TRANSACTIONS = (fields: string = transactionFields) => gql`
  mutation($input: RemoveDiscountFromTransactionInput!) {
    removeDiscountFromTransaction(input: $input) {
      ${fields}
    }
  }
`;

export const useCreateTransaction = (
  options?: MutationHookOptions<ICreateTransactionData, ICreateTransactionVariables>,
  fields?: string
) => useMutation<ICreateTransactionData, ICreateTransactionVariables>(CREATE_TRANSACTION(fields), options);

export const useCreateReverseTransaction = (
  options?: MutationHookOptions<ICreateReverseTransactionData, ICreateReverseTransactionVariables>,
  fields?: string
) =>
  useMutation<ICreateReverseTransactionData, ICreateReverseTransactionVariables>(
    CREATE_REVERSE_TRANSACTION(fields),
    options
  );

export const useCreateTransactionType = (
  options?: MutationHookOptions<ICreateTransactionTypeData, ICreateTransactionTypeVariables>,
  fields?: string
) => useMutation<ICreateTransactionTypeData, ICreateTransactionTypeVariables>(CREATE_TRANSACTION_TYPE(fields), options);

export const useCreateTransactionTypeBulk = (
  options?: MutationHookOptions<{ createTransactionTypesInBulk: ITransactionType[] }, ICreateTransactionTypeVariables>,
  fields?: string
) =>
  useMutation<{ createTransactionTypesInBulk: ITransactionType[] }, ICreateTransactionTypeVariables>(
    CREATE_TRANSACTION_TYPE_BULK(fields),
    options
  );

export const useUpdateTransactionType = (
  options?: MutationHookOptions<IUpdateTransactionTypeData, IUpdateTransactionTypeVariables>
) => useMutation<IUpdateTransactionTypeData, IUpdateTransactionTypeVariables>(UPDATE_TRANSACTION_TYPE, options);

export const useArchiveTransactionType = (
  options?: MutationHookOptions<IArchiveTransactionTypeData, IArchiveTransactionTypeVariables>,
  fields?: string
) =>
  useMutation<IArchiveTransactionTypeData, IArchiveTransactionTypeVariables>(ARCHIVE_TRANSACTION_TYPE(fields), options);

export const useCreateTransactionsForSessions = (
  options?: MutationHookOptions<ICreateTransactionsForSessionsData, ICreateTransactionsForSessionsVariables>,
  fields?: string
) =>
  useMutation<ICreateTransactionsForSessionsData, ICreateTransactionsForSessionsVariables>(
    CREATE_TRANSACTIONS_FOR_SESSIONS(fields),
    options
  );

export const useCreateManualPayment = (
  options?: MutationHookOptions<ICreateManualPaymentData, ICreateManualPaymentVariables>,
  fields?: string
) => useMutation<ICreateManualPaymentData, ICreateManualPaymentVariables>(CREATE_MANUAL_PAYMENT(fields), options);

export const useApproveFlaggedPayment = (
  options?: MutationHookOptions<IApproveFlaggedPaymentData, IApprovedFlaggedPaymentVariables>,
  fields?: string
) =>
  useMutation<IApproveFlaggedPaymentData, IApprovedFlaggedPaymentVariables>(APPROVE_FLAGGED_PAYMENT(fields), options);

export const useCancelFlaggedPayment = (
  options?: MutationHookOptions<ICancelFlaggedPaymentData, ICancelFlaggedPaymentVariables>,
  fields?: string
) => useMutation<ICancelFlaggedPaymentData, ICancelFlaggedPaymentVariables>(CANCEL_FLAGGED_PAYMENT(fields), options);

export const useBouncedCheck = (
  options?: MutationHookOptions<IBouncedCheckData, IBouncedCheckVariables>,
  fields?: string
) => useMutation<IBouncedCheckData, IBouncedCheckVariables>(BOUNCED_FLAGGED_PAYMENT(fields), options);

export const useUpdateFlaggedPayment = (
  options?: MutationHookOptions<IUpdateFlaggedPaymentData, IUpdateFlaggedPaymentVariables>,
  fields?: string
) => useMutation<IUpdateFlaggedPaymentData, IUpdateFlaggedPaymentVariables>(UPDATE_FLAGGED_PAYMENT(fields), options);

export const useApplyDiscountToTransaction = (
  options?: MutationHookOptions<IApplyDiscountToTransactionData, IApplyDiscountToTransactionVariables>,
  fields?: string
) =>
  useMutation<IApplyDiscountToTransactionData, IApplyDiscountToTransactionVariables>(
    APPLY_DISCOUNT_TO_TRANSACTION(fields),
    options
  );

export const useRemoveDiscountFromTransaction = (
  options?: MutationHookOptions<IRemoveDiscountFromTransactionData, IRemoveDiscountFromTransactionVariables>,
  fields?: string
) =>
  useMutation<IRemoveDiscountFromTransactionData, IRemoveDiscountFromTransactionVariables>(
    REMOVE_DISCOUNT_FROM_TRANSACTIONS(fields),
    options
  );
