import React, { Fragment } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import color from '_colors.module.scss';

interface IProps {
  showDescription: boolean;
  steps: IStepperItem[];
  stepsStatus: Array<boolean>;
  disabledStepClickHandler: () => void;
  onItemClick: (route: any) => void;
}
const stepDescClassNames = classnames({
  'd-flex': true,
  'stepper-step-message': true,
  'align-items-center': true,
});

const stepperLineContainerClassNames = classnames({
  'stepper-line-container': true,
});

const Stepper: React.FC<IProps> = ({ steps, stepsStatus, onItemClick, disabledStepClickHandler }) => {
  return (
    <div className="d-flex flex-column stepper">
      {steps &&
        steps.map((step: IStepperItem, index: number) => {
          const isCompleted = stepsStatus[step.id];

          const stepperLineContent = classnames({
            'stepper-line': true,
            completed: isCompleted,
          });

          const previousStep = index >= 1 ? steps[index - 1] : { id: -1 };
          const isEnabled = isCompleted || stepsStatus[previousStep.id] || index === 0 || step.isEnabled;
          const stepperClassNames = classnames({
            'd-flex': true,
            'flex-row': true,
            'stepper-step': true,
            'cursor-pointer': true,
            'step-completed': isCompleted,
          });

          const stepCircleClassNames = classnames({
            'd-flex': true,
            'align-items-center': true,
            'justify-content-center': true,
            'stepper-step-incomplete': true,
            'stepper-active': step.active,
            'step-enabled': isEnabled,
          });
          return (
            <Fragment key={step.id}>
              <div
                className={stepperClassNames}
                onClick={() => {
                  isEnabled ? onItemClick && onItemClick(step) : disabledStepClickHandler();
                }}
              >
                {isCompleted ? (
                  <FontAwesomeIcon color={color.success} size="2x" icon={faCheckCircle} />
                ) : (
                  <div>
                    <div className={stepCircleClassNames}>{index + 1}</div>
                  </div>
                )}

                <div className={stepDescClassNames}>{step.description}</div>
                <div className="stepper-subtext">{step.subText}</div>
              </div>
              {steps.length > step.id && (
                <div className={stepperLineContainerClassNames}>
                  <div className={stepperLineContent}></div>
                </div>
              )}
            </Fragment>
          );
        })}
    </div>
  );
};

export default Stepper;
