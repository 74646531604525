export const customFieldFields = `
  id
  area
  attributesAsString
  businessId
  centerIds
  label
  area
  displayOrder
  notForConsoleDisplay
  type
`;

export const customValuesFields = `
  customFieldId
  value
`;

export const customFieldValuesResponse = `
  entityId
  values {
    ${customValuesFields}
  }
  customFields {
    ${customFieldFields}
  }
`;

export const customFieldDocumentValuesResponse = `
id
businessId
name
type
link
customFieldArea
createdBy
createdAt
`;
