import React from 'react';
import { Row } from 'shared/components/Layout';
import Button from '../Buttons';

/**
 * This component uses the dirty prop to determine whether
 * cancel and save should be shown.
 */

interface IProps {
  formId?: string;
  formIsDirty: boolean;
  toggleDirty: (isDirty: boolean) => void;
  primaryChoice?: string | null;
  secondaryChoice?: string | null;
  onCancel?: () => void;
  onSave?: () => void;
  saveDisabled?: boolean;
  loading?: boolean;
  className?: string;
  blockButtons?: boolean;
  toggleDirtyOnSave?: boolean;
  hasPermission?: boolean;
  deleteButton?: boolean;
  deleteChoise?: string;
  onDelete?: () => void;
  deleteLoading?: boolean;
  hideButtons?: boolean;
}

const FormWrapper2: React.FC<IProps> = ({
  formId = '',
  formIsDirty = false,
  toggleDirty = () => {},
  onCancel = () => {},
  onSave = () => {},
  primaryChoice = 'Save',
  secondaryChoice = 'Cancel',
  deleteChoise = 'Delete',
  saveDisabled = false,
  loading = false,
  className,
  blockButtons = false,
  toggleDirtyOnSave = false,
  hasPermission = true,
  deleteButton,
  onDelete = () => {},
  deleteLoading = false,
  hideButtons = false,
  ...props
}) => {
  const handleCancel = () => {
    toggleDirty(false);
    onCancel();
  };

  const handleSave = (event: any) => {
    event.preventDefault();
    toggleDirtyOnSave && toggleDirty(false);
    onSave();
  };

  return (
    <form id={formId} onSubmit={handleSave} className={`d-flex flex-column ${className || ''}`}>
      {props.children}
      <Row noGutters justify="end" className="mt-4">
        {deleteButton && (
          <Button
            id={`${formId}-info-btn`}
            className={blockButtons ? 'btn-block' : 'mr-4'}
            disabled={saveDisabled || !formIsDirty}
            loading={deleteLoading}
            variant="light"
            onClick={onDelete}
            hidden={hideButtons}
          >
            {deleteChoise}
          </Button>
        )}
        {secondaryChoice && (
          <Button
            id={`${formId}-secondary-btn`}
            className={blockButtons ? 'btn-block mb-2' : 'mr-4'}
            variant="light"
            onClick={handleCancel}
            disabled={!formIsDirty}
            hidden={hideButtons}
          >
            {secondaryChoice}
          </Button>
        )}
        {primaryChoice && (
          <Button
            id={`${formId}-primary-btn`}
            className={blockButtons ? 'btn-block' : ''}
            disabled={saveDisabled || !hasPermission || !formIsDirty}
            loading={loading}
            type="submit"
            hidden={hideButtons}
          >
            {primaryChoice}
          </Button>
        )}
      </Row>
    </form>
  );
};

export default FormWrapper2;
