import React, { useEffect, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import Checkbox from 'shared/components/Checkbox';
import PhoneInput from 'shared/components/PhoneInput';
import { useGetProviderContact } from 'gql/provider/queries';
import { showToast } from 'shared/components/Toast';
import { useUpdateProviderContact } from 'gql/provider/mutation';
import NotifiableEventsContainer from '../NotifiableEventsContainer';
import moment from 'moment';
import { isValidPhoneNumber } from 'shared/util/string';

interface Props {
  businessId: string;
  providers: IProvider[];
}

const ProviderContactDetails: React.FC<Props> = ({ businessId, providers }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<IProviderContactInput>({
    businessId,
    providerId: '',
    dateOfEvent: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    applyEmailToAllServices: false,
  });
  const [cancelFormData, setCancelFormData] = useState<IProviderContactInput>(formData);

  useEffect(() => {
    if (providers.length > 0) {
      setFormData((form) => ({
        ...form,
        providerId: providers[0].id,
      }));
    }
  }, [providers]);

  const { loading: contactLoading } = useGetProviderContact({
    variables: {
      providerId: formData.providerId || '',
      businessId: businessId,
    },
    skip: formData.providerId === '' || !Boolean(providers),
    onCompleted: (res: IGetProviderContact) => {
      setFormData((form) => {
        const { email, dateOfEvent, phoneNumber, mobileNumber } = res.getProviderContact;
        return {
          ...form,
          email,
          phoneNumber,
          mobileNumber,
          dateOfEvent,
        };
      });
      setCancelFormData((form) => {
        const { email, dateOfEvent, phoneNumber, mobileNumber } = res.getProviderContact;
        return {
          ...form,
          email,
          phoneNumber,
          mobileNumber,
          dateOfEvent,
        };
      });
    },
    onError: () => {
      showToast('Something went wrong', 'error');
    },
  });

  const preventToStringPhone = (phoneNumber) => {
    const startFrom13 = phoneNumber.slice(0, 5) === '+6113' && phoneNumber.length === 13;
    const phoneStart = startFrom13 ? '' : '0';

    return phoneNumber.replace('+61', phoneStart).replaceAll(' ', '').replace(/[()]/g, '');
  };

  const [updateContact, { loading: updateContactLoading }] = useUpdateProviderContact({
    onCompleted: () => {
      showToast('Successfully submitted the updated contact details.', 'success');
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  const validate = () => {
    const { mobileNumber, phoneNumber } = formData;
    const phoneExpression =
      /^\({0,1}((0|\+61)(2|3|7|8|13)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;

    if (Object.values(formData).some((val: string) => val === '')) {
      return false;
    }
    return isValidPhoneNumber(mobileNumber) && phoneNumber.match(phoneExpression);
  };

  const onSubmit = () => {
    const { mobileNumber, phoneNumber } = formData;
    if (!validate()) {
      setFormIsDirty(true);
      return;
    }
    updateContact({
      variables: {
        input: {
          ...formData,
          mobileNumber: mobileNumber.replace('+61', '0'),
          phoneNumber: preventToStringPhone(phoneNumber),
          dateOfEvent: moment(formData.dateOfEvent).utc().format(),
          businessId: businessId,
        },
      },
    });
  };

  return (
    <>
      <NotifiableEventsContainer
        title="Provider Contact Details"
        subTitle={undefined}
        notificationMessage="Within 14 days after the change"
        onCancel={() => setFormData(cancelFormData)}
        onSave={onSubmit}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!validate()}
        providerOnChange={(option) => setFormData({ ...formData, providerId: option.value })}
        selectedProvider={formData.providerId}
        isLoading={contactLoading}
        isSaving={updateContactLoading || contactLoading}
        child={
          <>
            <Row>
              <Col className="mb-2">
                <DateInput
                  required
                  label="Date of effect"
                  date={formData.dateOfEvent}
                  isOutsideRange={(day: moment.Moment) => day.isBefore(moment())}
                  onDateSelect={(date: string) => setFormData({ ...formData, dateOfEvent: date })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col>
                <PhoneInput
                  required
                  label="Contact Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(value: string) => setFormData({ ...formData, phoneNumber: value })}
                  disabled={false}
                  isCcsManagement={true}
                  phoneNotMobileNumber={true}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col>
                <PhoneInput
                  required
                  label="Contact Mobile Number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={(value: string) => setFormData({ ...formData, mobileNumber: value })}
                  disabled={false}
                  isCcsManagement={true}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  required
                  label="Contact Email Address"
                  name="email"
                  value={formData.email}
                  onChange={(value: string) => setFormData({ ...formData, email: value })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col>
                <Checkbox
                  label="I want to use this email for all services linked to this provider"
                  value={formData.applyEmailToAllServices}
                  onChange={(value: boolean) => setFormData({ ...formData, applyEmailToAllServices: value })}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default ProviderContactDetails;
