import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CreateEnrollmentProgramGroupInput,
  UpdateEnrollmentProgramGroupInput,
  useCreateEnrollmentProgramGroupMutation,
} from 'generated/graphql';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { IEnrollmentProgramGroupsTableProps } from '../tabs/ProgamGroupsTab/ProgramGroupsTable/ProgramGroupsTable';
import { IEnrollmentProgramsTableProps } from '../components/EnrollmentProgramsTable/EnrollmentProgramsTable';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface ICreateEnrollmentProgramGroup {
  loading: boolean;
  onSubmit: () => {};
  onFieldUpdate: (a: any, b: any) => void;
  values: CreateEnrollmentProgramGroupInput;
  setValues: Dispatch<SetStateAction<CreateEnrollmentProgramGroupInput>>;
  businessTimeZone: Timezone;
}
const initialProgramGroupInput = {
  isActive: false,
  name: '',
  description: '',
  businessId: '',
  programIds: [],
};

interface Params {
  tableProps: IEnrollmentProgramsTableProps;
  onCompleted: () => void;
}

export const useCreateEnrollmentProgramGroup = ({ tableProps, onCompleted }: Params) => {
  const { businessId } = useSelector((state: RootState) => state.context);
  const allEntities = useSelector((state: RootState) => state.entities.allEntities);
  const businessTimeZone = allEntities[businessId ?? '']?.timezone || moment.tz.guess();
  const [values, setValues] = useState<CreateEnrollmentProgramGroupInput>(initialProgramGroupInput);

  useEffect(() => {
    if (tableProps?.tableState?.selectedRows) {
      setValues({ ...values, programIds: tableProps.tableState?.selectedRows?.map((s) => s.id) });
    }
  }, [tableProps?.tableState?.selectedRows]);

  const [createProgramGroup, { loading: createLoading }] = useCreateEnrollmentProgramGroupMutation({
    onCompleted,
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  const handleSubmitForm = async () => {
    await createProgramGroup({
      variables: {
        input: { ...values, businessId: businessId ?? '' },
      },
    });
  };

  const onFieldUpdate = (value: any, name: any) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  return {
    loading: createLoading,
    onSubmit: handleSubmitForm,
    onFieldUpdate,
    values,
    setValues,
    businessTimeZone,
  } as ICreateEnrollmentProgramGroup;
};
