import * as types from './types';

export const getDiscountsSuccess = (discounts: IDiscount[]): types.DiscountActionTypes => ({
  type: types.GET_DISCOUNTS_SUCCESS,
  discounts,
});

export const createDiscountSuccess = (discount: IDiscount): types.DiscountActionTypes => ({
  type: types.CREATE_DISCOUNT_SUCCESS,
  discount,
});

export const updateDiscountSuccess = (discount: IDiscount): types.DiscountActionTypes => ({
  type: types.UPDATE_DISCOUNT_SUCCESS,
  discount,
});
