import React from 'react';
import Card from 'shared/components/Card';
import { Row } from 'shared/components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonProps } from 'react-bootstrap/Button';
import Button from 'shared/components/Buttons';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

interface IProps extends ButtonProps {
  icon: IconDefinition;
  onClick: () => void;
  className?: string;
  label?: string;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
}

const IconButtonCard: React.FC<IProps> = ({
  icon,
  onClick,
  className,
  variant = 'primary',
  label,
  disabled = false,
}) => {
  return (
    <Card onClick={() => !disabled && onClick()} className={`text-center icon-button-card ${className || ''}`}>
      <Button onClick={onClick} className="rounded-circle circle-xl mt-6 mb-4" variant={variant} disabled={disabled}>
        <Row align="center" justify="center" noGutters>
          <FontAwesomeIcon size="2x" icon={icon} color="white" />
        </Row>
      </Button>
      <p className={!disabled ? `text-${variant}` : ''}>{label}</p>
    </Card>
  );
};

export default IconButtonCard;
