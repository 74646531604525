import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateBillingBusinessSettings } from 'gql/glCode/mutations';
import { useGetBillingBusinessSettings } from 'gql/glCode/queries';
import Card from 'shared/components/Card';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Col, Row } from 'shared/components/Layout';
import { showToast } from 'shared/components/Toast';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import Switch from 'shared/components/Switch';
import { fetchAllowedEntities } from 'store/entities/actions';

const initialState: IBillingBusinessSettings = {
  useGlCodes: false,
  mandatoryGlCodeMapping: false,
};
const Settings = () => {
  const { t } = useTranslation(['billing', 'translation']);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? user?.entityId ?? '';
  const [formData, setFormData] = useState(initialState);
  const [isDirty, setDirty] = useState(false);

  const { refetch } = useGetBillingBusinessSettings({
    variables: {
      businessId,
    },
    onCompleted: (res) => {
      const { useGlCodes, mandatoryGlCodeMapping } = res.fetchBillingBusinessSettings ?? {};
      setFormData({
        useGlCodes: useGlCodes ?? false,
        mandatoryGlCodeMapping: mandatoryGlCodeMapping ?? false,
      });
    },
  });

  const hasEditBillingSettingPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.BillingSettings,
    level: RoleLevelType.Edit,
  });

  const [updateSetting, { loading }] = useUpdateBillingBusinessSettings({
    onCompleted: (res) => {
      showToast(t('billing:gl-codes.settings-tab.settings-update-success'), 'success');
      setDirty(false);
      const { useGlCodes, mandatoryGlCodeMapping } = res.updateBillingBusinessSettings ?? {};
      setFormData({
        useGlCodes: useGlCodes ?? false,
        mandatoryGlCodeMapping: mandatoryGlCodeMapping ?? false,
      });
      // to update the isGlCodeMandatory value in the redux store
      dispatch(fetchAllowedEntities());
    },
    onError: () => {
      t('translation:general.error');
    },
  });

  const handleSave = () => {
    updateSetting({
      variables: {
        input: {
          businessId,
          useGlCodes: formData.useGlCodes,
          mandatoryGlCodeMapping: formData.mandatoryGlCodeMapping,
        },
      },
    });
  };

  const handleUseGlCodesChange = useCallback(
    (value: boolean) => {
      setFormData({
        ...formData,
        useGlCodes: value,
      });
      setDirty(true);
    },
    [formData, setDirty]
  );

  const handleMandatoryGlCodeMappingChange = useCallback(
    (value: boolean) => {
      setFormData({
        ...formData,
        mandatoryGlCodeMapping: value,
      });
      setDirty(true);
    },
    [formData, setDirty]
  );

  const handleCancel = useCallback(() => {
    refetch();
    setDirty(false);
  }, [setDirty, refetch]);

  return (
    <Row>
      <Col lg={6}>
        <Card header={t('billing:gl-codes.gl-code-settings')}>
          <FormWrapper2
            formIsDirty={isDirty}
            toggleDirty={setDirty}
            onCancel={handleCancel}
            onSave={handleSave}
            toggleDirtyOnSave={true}
            hasPermission={hasEditBillingSettingPermission}
            loading={loading}
          >
            <Row className="mb-5">
              <Col>
                <Switch
                  label={t('billing:gl-codes.settings-tab.gl-code-stamping-label')}
                  labelSide="top"
                  value={formData.useGlCodes}
                  onChange={handleUseGlCodesChange}
                  className="ml-auto"
                  checkedText="Yes"
                  uncheckedText="No"
                  height={30}
                />
              </Col>
            </Row>
            <Row className="mb-8">
              <Col>
                <Switch
                  label={t('billing:gl-codes.settings-tab.gl-codes-mandatory-label')}
                  labelSide="top"
                  value={formData.mandatoryGlCodeMapping}
                  onChange={handleMandatoryGlCodeMappingChange}
                  className="ml-auto"
                  checkedText="Yes"
                  uncheckedText="No"
                  height={30}
                />
              </Col>
            </Row>
          </FormWrapper2>
        </Card>
      </Col>
    </Row>
  );
};
export default Settings;
