import React from 'react';

interface IProps {
  size?: circleSizes | number;
  className?: string;
}

const Circle: React.FC<IProps> = ({ size = 'md', className }) => {
  return (
    <div
      style={typeof size === 'number' ? { width: size, height: size } : {}}
      className={`loading-skeleton rounded-circle circle-${size} ${className}`}
    />
  );
};

export default Circle;
