import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { feeFields } from './fields';

//interfaces
interface ICreateFeeData {
  createFee: IFee;
}
interface ICreateFeeVariables {
  input: ICreateFeeInput;
}
interface IUpdateFeeData {
  editFee: IFee;
}
interface IUpdateFeeVariables {
  input: IFeeInEdit;
}
interface IDeactivateFeeVariables {
  id: string;
}
interface IDeactivateFeeData {
  deactivateFee: IFee;
}
interface IActivateFeeVariables {
  id: string;
}
interface IActivateFeeData {
  activateFee: IFee;
}

//mutations
export const CREATE_FEE = (fields: string = feeFields) =>
  gql` mutation ($input: CreateFeeInput!) {
    createFee(input: $input) {
      ${fields}
    }
  }`;

export const UPDATE_FEE = (fields: string = feeFields) =>
  gql` mutation ($input: EditFeeInput!) {
    editFee(input: $input) {
      ${fields}
    }
  }`;

export const DEACTIVATE_FEE = (fields: string = feeFields) =>
  gql` mutation ($id: ID!) {
    deactivateFee(id: $id) {
      ${fields}
    }
  }`;

export const ACTIVATE_FEE = (fields: string = feeFields) =>
  gql` mutation ($id: ID!) {
    activateFee(id: $id) {
      ${fields}
    }
  }`;

//hooks
export const useCreateFee = (options?: MutationHookOptions<ICreateFeeData, ICreateFeeVariables>, fields?: string) =>
  useMutation<ICreateFeeData, ICreateFeeVariables>(CREATE_FEE(fields), options);

export const useUpdateFee = (options?: MutationHookOptions<IUpdateFeeData, IUpdateFeeVariables>, fields?: string) =>
  useMutation<IUpdateFeeData, IUpdateFeeVariables>(UPDATE_FEE(fields), options);

export const useDeactivateFee = (
  options?: MutationHookOptions<IDeactivateFeeData, IDeactivateFeeVariables>,
  fields?: string
) => useMutation<IDeactivateFeeData, IDeactivateFeeVariables>(DEACTIVATE_FEE(fields), options);

export const useActivateFee = (
  options?: MutationHookOptions<IActivateFeeData, IActivateFeeVariables>,
  fields?: string
) => useMutation<IActivateFeeData, IActivateFeeVariables>(ACTIVATE_FEE(fields), options);
