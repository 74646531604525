import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import errorMessage from 'shared/constants/errorMessages';
import { useDeleteLinkCode } from '../../graphql/mutations';
import { ILink } from '../../graphql/queries';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccessDelete: () => void;
  linkCode: ILink;
  businessId: string;
}

const DeleteLinkModal: React.FC<IProps> = ({ isOpen, onClose, onSuccessDelete, linkCode, businessId }) => {
  const [deleteLinkCode, { loading: isDeleting }] = useDeleteLinkCode();

  const { t } = useTranslation(['translation', 'business']);
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDelete = useCallback(async () => {
    deleteLinkCode({
      variables: {
        input: {
          businessId,
          linkCode: linkCode.linkCode,
        },
      },
    }).then(
      () => {
        showToast(t('business:enrolment.link-code-deleted-success'), 'success');
        onSuccessDelete();
        handleClose();
      },
      () => {
        showToast(errorMessage.generic, 'error');
        handleClose();
      }
    );
  }, [deleteLinkCode, handleClose, linkCode, businessId]);

  return (
    <ConfirmationModal
      title={t('business:enrolment.link-code-delete')}
      show={isOpen}
      onHide={handleClose}
      hideOnCallback={false}
      primaryChoice={t('business:enrolment.link-code-delete-confirm-button')}
      primaryCallback={handleDelete}
      primaryButtonProps={{ variant: 'danger', disabled: isDeleting, loading: isDeleting }}
    >
      <p>
        {t('business:enrolment.link-code-delete-text')} {linkCode?.name || ''}
        {t('business:enrolment.link-code-delete-text-1')}
      </p>
    </ConfirmationModal>
  );
};

export default DeleteLinkModal;
