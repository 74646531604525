export const kindyForAllSubmissionError = `
  code
  message
  target
  innerError  
`;

export const kindyForAllCenterAgreement = `
id
businessId
centerId
centerName
agreementId
agreementNumber
beneficiaryAccountNumber
`;

export const kindyForAllCenterSummary = `
centerId
centerName
period
year
childCount
kindyFtbCount
kfsPlusCount
fundedAmount
status
errors {
  ${kindyForAllSubmissionError}
}
`;

export const kindyForAllChildSummary = `
centerId
centerName
childId
childName
period
year
kindyFtb
kfsPlus
fundedAmount
status
`;

export const kindyForAllAcquittalCenterSummary = `
centerId
centerName
period
year
childCount
kindyFtbCount
kfsPlusCount
forecastedAmount
acquittedAmount
paidToFamiliesAmount
status
messages {
  ${kindyForAllSubmissionError}
}
`;

export const kindyForAllAcquittalChildSummary = `
centerId
centerName
childId
childName
period
year
kindyFtb
kfsPlus
forecastedAmount
acquittedAmount
paidToFamiliesAmount
status
`;

export const kindyForAllAgreement = `
id
agreementNumber
type
applicantAccountNumber
applicantAccountName
beneficiaryAccountNumber
beneficiaryAccountName
exists
`;

export const kindyForAllIneligibility = `
centerId
childId
weekStart
ineligible
`;

export const pagedKindyForAllCenterAgreement = `
totalRecords
pageNumber
pageSize
data {
  ${kindyForAllCenterAgreement}
}
`;

export const pagedKindyForAllCenterSummary = `
totalRecords
pageNumber
pageSize
data {
  ${kindyForAllCenterSummary}
}
`;

export const pagedKindyForAllChildSummary = `
totalRecords
pageNumber
pageSize
data {
  ${kindyForAllChildSummary}
}
`;

export const pagedKindyForAllAcquittalCenterSummary = `
totalRecords
pageNumber
pageSize
data {
  ${kindyForAllAcquittalCenterSummary}
}
`;

export const pagedKindyForAcquittalChildSummary = `
totalRecords
pageNumber
pageSize
data {
  ${kindyForAllAcquittalChildSummary}
}
`;

export const kindyForAllCredential = `
  id
  businessId
  applicationName
  applicationKey
  applicationSecret
`;

export const pagedKindyForAllCredential = `
  totalRecords
  pageNumber
  pageSize
  data {
    ${kindyForAllCredential}
  }
`;

export const kindyForAllSubmission = `
  centerId
  centerName
  errors {
    ${kindyForAllSubmissionError}
  }
`;

export const kindyForAllAcquittalSubmission = `
  centerId
  centerName
  messages {
    ${kindyForAllSubmissionError}
  }
`;

export const kindyForAllDeletedForecast = `
  id
  agreementId
  forecastId
  period
  year
`;

export const kindyForAllDeletedAcquittal = `
  id
  agreementId
  acquittalId
  period
  year
`;

export const kindyForAllQuarter = `
 year
 period
 startDate
 endDate
 isCurrent
`;

export const childFundingSchedules = `
programChildId
startDate
subsidyTypes
basePercentage
createdAt
createdBy
`;
