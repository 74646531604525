import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';

interface IGetCenterTagOptionsData {
  getEntity: IEntity;
  getCenterTagOptions: ITag[];
}

interface IGetCcssApprovalsDataForCenter {
  getCcssApprovalsForCenter: ICcssApproval[];
}

interface IGetCcssDetailsDataForCenterVariables {
  centerId: string;
}

export const GET_CENTER_TAG_OPTIONS = gql`
  query ($entityId: String!) {
    getEntity(id: $entityId) {
      id
      tags {
        id
        name
        category
      }
    }
  }
`;

const GET_CCSS_DETAILS_FOR_CENTER = gql`
  query ($centerId: ID!) {
    getCcssApprovalsForCenter(centerId: $centerId) {
      id
      careType
      serviceId
      serviceName
      startDate
    }
  }
`;

/**
 * Get the entity tags that can be applied to a center
 * @param {String} entityId
 */

export const useGetCenterTagOptions = (entityId: string) => {
  const response = useQuery<IGetCenterTagOptionsData, { entityId: string }>(GET_CENTER_TAG_OPTIONS, {
    variables: {
      entityId,
    },
  });

  if (response.data) {
    const tags: ITag[] = response.data.getEntity.tags;
    const centerTags = tags.filter((tag) => tag.category === 'CENTER');
    centerTags.sort((a, b) => a.name.localeCompare(b.name));
    return {
      ...response,
      data: {
        getCenterTagOptions: centerTags,
      },
    };
  }

  return response;
};

export const useGetCcssApprovalsForCenter = (
  options: QueryHookOptions<IGetCcssApprovalsDataForCenter, IGetCcssDetailsDataForCenterVariables>
) => {
  return useQuery<IGetCcssApprovalsDataForCenter, IGetCcssDetailsDataForCenterVariables>(GET_CCSS_DETAILS_FOR_CENTER, {
    fetchPolicy: 'cache-and-network',
    skip: !options.variables?.centerId,
    ...options,
  });
};
