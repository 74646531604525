const staticText = {
  employmentStatusHelper: {
    Active: 'Currently employed and has access to full application.',
    Pending:
      'Employment has not started and access is limited to user features only; profile, time log, time off, and schedule.',
    Deactivated: 'Employment has ended and access is limited to historical data.',
    Created:
      'Employee information has been saved, but no invite has been sent to log into the application. Does not require start date.',
    Invited: 'Employee has been invited to log in to the application but has yet to do so.',
    Requested: 'Employee has requested to create a Kangarootime account.',
  },
};

export default staticText;
