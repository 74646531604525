import { useCallback, useEffect, useMemo, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import { showToast } from 'shared/components/Toast';
import errorMessage from 'shared/constants/errorMessages';
import PhoneInput from 'shared/components/PhoneInput';
import { useDeleteHealthProfessional, useUpsertHealthProfessional } from 'gql/healthProfessional/mutations';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { practitionerTypes } from './util';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
import Alert from 'shared/components/Alert';
import AddressInput from 'shared/components/AddressInput';
import { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  childId: string;
  childAccountOptions: { label: string; value: string }[];
  editingProfessional?: IHealthProfessional;
  professionals: IHealthProfessional[];
  update: (healthProfessional: IHealthProfessional) => void;
  create: (healthProfessional: IHealthProfessional) => void;
  delete: (healthProfessional: IHealthProfessional) => void;
}

const initialValues: HealthProfessionalFormData = {
  professionalType: '',
  firstName: '',
  lastName: '',
  practice: '',
  phoneNumber: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: DEFAULT_COUNTRY,
  },
  accountChildIds: [],
};

const mapLinkedChildrenToOptions = (
  linked: HealthProfessionalLinkedChild[],
  options: Props['childAccountOptions']
): HealthProfessionalFormData['accountChildIds'] => {
  const data: HealthProfessionalFormData['accountChildIds'] = [];
  linked.forEach((l) => {
    const foundOption = options.find((o) => o.value === l.accountChildId);
    if (foundOption) {
      data.push(foundOption);
    }
  });
  return data;
};

const formatExistingProfessionalLabel = (data: any) => {
  return (
    <div className="d-flex justify-content-between">
      <span>{data.label}</span>
      <small className="practitioner-type-pill p-1 rounded border">
        <strong>{data.tag}</strong>
      </small>
    </div>
  );
};

const HealthProfessionalModal = ({
  childAccountOptions,
  isOpen,
  onClose,
  childId,
  editingProfessional,
  ...props
}: Props) => {
  const resetFormData = (professional?: IHealthProfessional) => ({
    ...initialValues,
    professionalType: professional?.professionalType ?? '',
    firstName: professional?.firstName ?? '',
    lastName: professional?.lastName ?? '',
    practice: professional?.practice ?? '',
    phoneNumber: professional?.phoneNumber ?? '',
    address: {
      address1: professional?.address.address1 ?? '',
      address2: professional?.address.address2 ?? '',
      city: professional?.address.city ?? '',
      state: professional?.address.state ?? '',
      postalCode: professional?.address.postalCode ?? '',
      country: professional?.address.country ?? '',
    },
    accountChildIds: professional
      ? mapLinkedChildrenToOptions(professional.linkedAccountChildren, childAccountOptions)
      : [],
  });
  const [formData, setFormData] = useState<HealthProfessionalFormData>(resetFormData(editingProfessional));
  const [existingProfessional, setExistingProfessional] = useState('');
  useEffect(() => {
    if (!editingProfessional && existingProfessional && existingProfessional.length > 0) {
      const foundExistingProfessional = props.professionals.find((p) => p.id === existingProfessional);
      if (foundExistingProfessional) {
        setFormData(resetFormData(foundExistingProfessional));
      }
    } else if (!editingProfessional) {
      setFormData(resetFormData());
    }
  }, [existingProfessional]);

  const businessId = useSelector((state: RootState) => state.user?.entityId) ?? '';
  const resetForm = () => setFormData(resetFormData(editingProfessional));
  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose]);

  const [upsertHealthProfessionalFn, { loading: upsertHealthProfessionalLoading }] = useUpsertHealthProfessional({
    onCompleted: (result) => {
      showToast('Health Professional saved successfully.', 'success');
      if (editingProfessional || (existingProfessional && existingProfessional.length > 0)) {
        props.update(result.upsertHealthProfessional);
      } else {
        props.create(result.upsertHealthProfessional);
      }

      handleClose();
    },
  });

  const handlePrimaryCallback = useCallback(() => {
    upsertHealthProfessionalFn({
      variables: {
        input: {
          businessId: businessId,
          ...formData,
          ...(editingProfessional
            ? { id: editingProfessional.id }
            : existingProfessional && existingProfessional.length > 0
            ? { id: existingProfessional }
            : {}),
          accountChildIds: formData.accountChildIds.map((a) => a.value),
        },
      },
    });
  }, [onClose, resetForm, upsertHealthProfessionalFn, childId, businessId, formData]);

  const [deleteHealthProfessionalFn, { loading: deleteHealthProfessionalLoading }] = useDeleteHealthProfessional({
    onCompleted: (result) => {
      showToast('Health Professional removed successfully.', 'success');
      props.delete(result.deleteHealthProfessional);
      handleClose();
    },
  });

  const handleTertiaryCallback = useCallback(() => {
    if (!editingProfessional) return;
    deleteHealthProfessionalFn({
      variables: {
        id: editingProfessional.id,
      },
    });
  }, [onClose, resetForm, deleteHealthProfessionalFn, childId, businessId, formData]);

  const validateForm = useCallback((): boolean => {
    return Boolean(
      formData.professionalType &&
        formData.firstName &&
        formData.lastName &&
        formData.practice &&
        formData.phoneNumber &&
        formData.address &&
        formData.address.address1 &&
        formData.address.city &&
        formData.address.state &&
        formData.address.postalCode &&
        formData.accountChildIds.length > 0
    );
  }, [formData]);

  const showWarning = useMemo(() => {
    if (
      editingProfessional &&
      editingProfessional.linkedAccountChildren &&
      editingProfessional.linkedAccountChildren.length > 1
    ) {
      return true;
    }

    if (existingProfessional && existingProfessional.length > 0) {
      const foundExistingProfessional = props.professionals.find((p) => p.id === existingProfessional);
      if (foundExistingProfessional) {
        return foundExistingProfessional.linkedAccountChildren.length > 0;
      }
    }
    return false;
  }, [editingProfessional, existingProfessional]);

  return (
    <SideModalDrawer
      title={editingProfessional ? 'Edit Health Professional' : 'Add Health Professional'}
      show={isOpen}
      onHide={handleClose}
      primaryChoice={editingProfessional ? 'Update' : 'Save'}
      primaryCallback={handlePrimaryCallback}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled: !validateForm(),
        loading: upsertHealthProfessionalLoading || deleteHealthProfessionalLoading,
      }}
      tertiaryChoice={editingProfessional ? 'Remove' : undefined}
      tertiaryCallback={editingProfessional ? handleTertiaryCallback : undefined}
      tertiaryButtonProps={{
        variant: 'outline-danger',
        loading: upsertHealthProfessionalLoading || deleteHealthProfessionalLoading,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      footerHelperText={!validateForm() ? errorMessage.formRequirements : ''}
    >
      <div>
        {!editingProfessional && props.professionals && props.professionals.length > 0 && (
          <div className="existing-health-professional d-flex flex-column form-control__container">
            <label className="form-label">
              <strong>Select existing health professional</strong>
            </label>
            <div className="small-font mb-2">
              If the health professional already exists on the account, select them from the dropdown. Add any new
              health professionals below.
            </div>
            <Select
              te
              isClearable
              formatOptionLabel={formatExistingProfessionalLabel}
              options={props.professionals.map((p) => {
                const readablePractitionerType = practitionerTypes.find((item) => item.value === p.professionalType);
                return {
                  label: `${p.firstName} ${p.lastName}`,
                  value: p.id,
                  tag: readablePractitionerType?.label ?? p.professionalType,
                };
              })}
              value={existingProfessional}
              onChange={(value) => setExistingProfessional(value?.value ?? '')}
            />
          </div>
        )}
        <Select
          required
          te
          label="Practitioner Type"
          options={practitionerTypes}
          value={formData.professionalType}
          onChange={(value) => {
            setFormData({ ...formData, professionalType: value.value.toString() });
          }}
        />
        <Row>
          <Col sm={6}>
            <TextInput
              required
              label="First Name"
              value={formData.firstName}
              onChange={(value) => {
                setFormData({ ...formData, firstName: value });
              }}
            />
          </Col>
          <Col lg={6}>
            <TextInput
              required
              label="Last Name"
              value={formData.lastName}
              onChange={(value) => {
                setFormData({ ...formData, lastName: value });
              }}
            />
          </Col>
        </Row>
        <TextInput
          required
          label="Practice"
          value={formData.practice}
          onChange={(value) => {
            setFormData({ ...formData, practice: value });
          }}
        />
        <PhoneInput
          label="Phone Number"
          value={formData.phoneNumber}
          onChange={(value: string) => setFormData({ ...formData, phoneNumber: value })}
          required
        />
        <AddressInput
          address={formData.address}
          addressLabel={'Street Address'}
          onChange={(value: any) => setFormData({ ...formData, address: value })}
          required
          hideCountry
          modalFormat
          disabled={upsertHealthProfessionalLoading || deleteHealthProfessionalLoading}
          errorText={errorMessage.address.generic}
          showHelperText={false}
        />

        <hr />
        <SelectMultiple
          required
          te
          noSearch
          className="pt-4 mb-6"
          label="Apply to Accounts"
          options={childAccountOptions}
          value={formData.accountChildIds}
          onChange={(value) => {
            setFormData({ ...formData, accountChildIds: value ?? [] });
          }}
        />
        {showWarning && (
          <Alert className={'health-professional-warning'} variant="warning">
            <div className="alert-message">
              <strong>Please note:</strong> Any changes made to this health professional's details will be updated
              across all linked children.
            </div>
          </Alert>
        )}
      </div>
    </SideModalDrawer>
  );
};

export { HealthProfessionalModal };
