import React from 'react';
import Avatar from 'shared/components/Avatar';

interface IProps {
  primaryContacts: IChildContact[];
  onAvatarClick: (event: React.MouseEvent<HTMLDivElement>, contact: IChildContact) => void;
}

const PrimaryContactsColumn: React.FC<IProps> = ({ primaryContacts, onAvatarClick, ...props }) => {
  return (
    <>
      {primaryContacts.map((contact: IChildContact, idx: number) => {
        const initials = `${contact.firstname.charAt(0)}${contact.lastname.charAt(0)}`;

        return (
          <div key={`contact-${contact.id}-${idx}`} onClick={(event) => onAvatarClick(event, contact)}>
            <Avatar size="md" image={contact.avatar?.url ?? ''} initials={initials} className="mr-2" />
          </div>
        );
      })}
    </>
  );
};

export default PrimaryContactsColumn;
