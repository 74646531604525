import React from 'react';
import { useDispatch } from 'react-redux';
import ReEnrollAccountsTable from './components/ReEnrollAccountsTable/ReEnrollAccountsTable';

interface IProps {}

const ReEnrollAccounts: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();

  return <ReEnrollAccountsTable />;
};

export default ReEnrollAccounts;
