export const accsServiceMetricsFields = `
  id
  centerId
  centerName
  applicationType
  percentageCap
  numberOfChildrenAtRisk
  percentageOfChildrenAtRisk
  lastUpdated
`;
