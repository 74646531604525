import React, { useCallback, useState } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { useArchiveHealthTerm } from 'gql/healthTerm/mutations';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  healthTerm: IHealthTerm;
  onArchiveSuccess: (archivedHealthTerm: IHealthTerm) => void;
}

const ArchiveMedicalTypeModal: React.FC<IProps> = ({ isOpen, onClose, healthTerm, onArchiveSuccess }) => {
  const { t } = useTranslation();

  const [archiveHealthTermFn, { loading }] = useArchiveHealthTerm({
    onCompleted: (result) => {
      onArchiveSuccess(result.archiveHealthTerm);
      onClose();
      showToast(t('settings.configuration.health-and-wellness.toast.archive-success'), 'success');
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleArchive = useCallback((id: string) => {
    archiveHealthTermFn({
      variables: {
        id,
      },
    });
  }, []);

  return (
    <ConfirmationModal
      title={`${capitalize(t('spelling.archive'))} ${t('spelling.this')} ${healthTerm.type} ?`}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice={`${capitalize(t('spelling.archive'))}`}
      primaryCallback={() => handleArchive(healthTerm.id)}
      primaryButtonProps={{ variant: 'danger', loading }}
    >
      <p>
        {t('settings.configuration.health-and-wellness.archive-modal.first-sentence', { healthTerm: healthTerm.name })}
      </p>
      <p>{t('settings.configuration.health-and-wellness.archive-modal.second-sentence')}</p>
    </ConfirmationModal>
  );
};

export default ArchiveMedicalTypeModal;
