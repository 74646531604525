import React, { Fragment } from 'react';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import {
  dayOfWeekOptions,
  dayOfMonthOptions,
  periodBasedBillingFrequencyOptions,
  periodBasedBillingPeriodOptions,
  quarterlyFrequencyPayPeriodOptions,
  biweeklyFrequencyPayPeriodOptions,
} from 'shared/constants/enums/billingCycleOptions';
import { capitalize } from 'shared/util/string';
import Alert from 'shared/components/Alert';
import { useTranslation } from 'react-i18next';
import cast from 'shared/util/cast';
import { getChargeDayValue } from './utils';
import NextCycle from './NextCycle';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NumberInput } from 'shared/components/TextInput';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import DateInput from 'shared/components/DateInput';
import { sortBillingPeriodsByStartDate } from './helpers/billing-periods';

interface IProps {
  cycle: IBillingCycleTemplate | INewBillingCycleTemplateFormData | IBillingCycleFormData;
  onUpdate: (
    update: Partial<IBillingCycleTemplate> | Partial<INewBillingCycleTemplateFormData | IBillingCycleFormData>
  ) => void;
  readOnly?: boolean;
  fieldsRequired?: boolean;
  /**
   * Hides the blue info box describing the next billing cycle
   */
  showNextCycleInformation?: boolean;
}

const PeriodBasedBillingCycleSentence: React.FC<IProps> = ({
  cycle,
  onUpdate,
  readOnly,
  fieldsRequired = false,
  showNextCycleInformation,
}) => {
  const { t } = useTranslation();

  const timePeriodValueDrodown = [1, 2, 3, 4, 5, 6];
  const timePeriodUnitDrodown = [
    { value: 'DAYS', label: cycle.invoiceSchedule?.value === 1 ? t('spelling.day') : t('spelling.days') },
    { value: 'WEEKS', label: cycle.invoiceSchedule?.value === 1 ? t('spelling.week') : t('spelling.weeks') },
    { value: 'MONTHS', label: cycle.invoiceSchedule?.value === 1 ? t('spelling.month') : t('spelling.months') },
  ];

  return (
    <Row align="start" className="mb-4">
      <Col md={12} xl={8}>
        <Row noGutters className="mb-4">
          <span className="mr-2">{t('billing.settings.cycles.payBillingCycleSentenceOne')}</span>
          {cycle.frequency !== 'CUSTOM' ? (
            <Fragment>
              <Select
                className="mb-0 flex-grow-0"
                options={periodBasedBillingPeriodOptions}
                value={periodBasedBillingPeriodOptions.find((option) => option.value === cycle.period) ?? null}
                onChange={(option) =>
                  onUpdate({
                    period: option.value,
                    frequency: undefined,
                    frequencyPayPeriod: undefined,
                  })
                }
                placeholder={t('billing.settings.cycles.timePeriod')}
                disabled={readOnly}
                required={fieldsRequired}
              />
              <span className="mr-2 ml-2">.</span>
            </Fragment>
          ) : (
            <span>according to custom defined billing peirods.</span>
          )}
        </Row>
        {cycle.frequency !== 'CUSTOM' ? (
          <RegularBillingCyclePaymentSentence
            cycle={cycle}
            onUpdate={onUpdate}
            readOnly={readOnly}
            fieldsRequired={fieldsRequired}
          />
        ) : (
          <CustomBillingCyclePaymentSentence cycle={cycle}></CustomBillingCyclePaymentSentence>
        )}
        <Row noGutters className="mb-4">
          <span className="mr-2">{t('billing.settings.cycles.payBillingCycleSentenceTwo')}</span>
          <Select
            className="mb-0 mr-2 flex-grow-0"
            options={timePeriodValueDrodown}
            onChange={(option) =>
              onUpdate({
                invoiceSchedule: cast<ITimePeriod>({
                  ...(cycle.invoiceSchedule ?? {}),
                  value: option,
                }),
              })
            }
            value={timePeriodValueDrodown.find((option) => option === cycle.invoiceSchedule?.value) ?? null}
            placeholder={t('spelling.number')}
            disabled={readOnly}
            required={fieldsRequired}
          />
          <Select
            className="mb-0 mr-2 flex-grow-0"
            options={timePeriodUnitDrodown}
            onChange={(option) =>
              onUpdate({
                invoiceSchedule: cast<ITimePeriod>({
                  ...(cycle.invoiceSchedule ?? {}),
                  unit: option.value,
                }),
              })
            }
            value={timePeriodUnitDrodown.find((option) => option.value === cycle.invoiceSchedule?.unit) ?? null}
            placeholder={t('spelling.period')}
            disabled={readOnly}
            required={fieldsRequired}
          />
          <span>{t('billing.settings.cycles.payBillingCycleSentenceThree')}</span>
        </Row>

        <Row noGutters className="mb-4">
          <span className="mb-4">{t('billing.settings.cycles.payBillingCycleSentenceFourPart1')}</span>
          <NumberInput
            value={cycle.gracePeriodDays ?? ''}
            step="1"
            min="0"
            className="mx-2 flex-grow-0"
            // @ts-ignore
            style={{ width: 72 }}
            onChange={(value) => onUpdate({ gracePeriodDays: value })}
            disabled={readOnly}
          />
          <span className="mb-4">{t('billing.settings.cycles.payBillingCycleSentenceFourPart2')}</span>
          <CurrencyInput
            value={cycle.lateChargeAmount}
            className="border-radius-0 mx-2 flex-grow-0"
            onChange={(value) => onUpdate({ lateChargeAmount: value })}
            disabled={readOnly}
          />
        </Row>
      </Col>
      {showNextCycleInformation && (
        <Col md={12} xl={4}>
          <Alert
            className="box-shadow-0"
            variant="info"
            header={`${capitalize(t('spelling.your'))} ${capitalize(t('spelling.next'))} ${capitalize(
              t('spelling.cycle')
            )}:`}
          >
            <NextCycle cycle={cycle} />
          </Alert>
        </Col>
      )}
    </Row>
  );
};

function CustomBillingCyclePaymentSentence({
  cycle,
}: {
  cycle: IBillingCycleTemplate | INewBillingCycleTemplateFormData | IBillingCycleFormData;
}) {
  const billingPeriods: IBillingPeriodCustom[] = cycle.billingPeriods
    ? sortBillingPeriodsByStartDate(cycle.billingPeriods)
    : [];
  return (
    <Fragment>
      {billingPeriods.map((bp, index) => {
        return (
          <Row noGutters className="mb-4" key={index}>
            <span className="mb-4">Payments will be due on</span>
            <DateInput className="mx-2" disabled date={bp.paymentDueDate}></DateInput>
            <span className="mb-4">for billing period</span>
            <DateInput className="mx-2" disabled date={bp.startDate}></DateInput>
            <span className="mb-4">through</span>
            <DateInput className="mx-2" disabled date={bp.endDate}></DateInput>
            <span className="mb-4">.</span>
          </Row>
        );
      })}
    </Fragment>
  );
}

function RegularBillingCyclePaymentSentence({ readOnly, fieldsRequired, onUpdate, cycle }: IProps) {
  const isWeekly = cycleIsWeekly(cycle);
  const isMonthly = cycleIsMonthly(cycle);
  const { t } = useTranslation();
  return (
    <Row noGutters className="mb-4">
      <span className="mr-2">
        {capitalize(t('spelling.payments'))} {t('spelling.are')} {t('spelling.due')}
      </span>
      <Select
        className="mb-0 flex-grow-0"
        options={periodBasedBillingFrequencyOptions}
        value={periodBasedBillingFrequencyOptions.find((option) => option.value === cycle.frequency) ?? null}
        onChange={(option) =>
          onUpdate({
            frequency: option.value,
            dayOfWeek: null,
            dayOfMonth: null,
            frequencyPayPeriod: undefined,
          })
        }
        placeholder={t('spelling.frequency')}
        disabled={readOnly}
        required={fieldsRequired}
      />
      <span className="mx-2">{`${t('spelling.on')} ${isMonthly ? t('spelling.the') : ''}`}</span>
      <Select
        className="mb-0 flex-grow-0"
        options={cycle.frequency ? (cycle.frequency.includes('WEEK') ? dayOfWeekOptions : dayOfMonthOptions) : []}
        value={getChargeDayValue(cycle) ?? null}
        onChange={(option) =>
          onUpdate({
            dayOfWeek: isWeekly ? option.value : null,
            dayOfMonth: isMonthly ? option.value : null,
          })
        }
        placeholder={t('billing.settings.cycles.chargeDay')}
        noOptionsMessage={() => t('billing.settings.cycles.noOptionFrequency')}
        disabled={readOnly}
        required={fieldsRequired}
      />
      {cycle.period &&
      cycle.frequency &&
      cycle.period.includes('CURRENT') &&
      (cycle.frequency.includes('QUARTERLY') || cycle.frequency.includes('BIWEEKLY')) ? (
        <>
          <span className="mx-2">{`${t('spelling.of')} ${t('spelling.the')}`}</span>
          <Select
            className="mb-0 flex-grow-0"
            options={
              cycle.frequency
                ? cycle.frequency.includes('QUARTERLY')
                  ? quarterlyFrequencyPayPeriodOptions
                  : biweeklyFrequencyPayPeriodOptions
                : []
            }
            value={cycle.frequencyPayPeriod ?? null}
            onChange={(option) =>
              onUpdate({
                frequencyPayPeriod: option.value,
              })
            }
            placeholder={t('billing.settings.cycles.frequencyPayPeriod')}
            noOptionsMessage={() => t('billing.settings.cycles.noOptionFrequency')}
            disabled={readOnly}
            required={fieldsRequired}
          />
          <span className="mr-2 ml-2">.</span>
        </>
      ) : (
        <span className="mr-2 ml-2">.</span>
      )}
    </Row>
  );
}

function cycleIsMonthly(
  cycle: IBillingCycleTemplate | INewBillingCycleTemplateFormData | IBillingCycleFormData
): boolean {
  return cycle.frequency === 'MONTHLY' || cycle.frequency === 'QUARTERLY';
}

function cycleIsWeekly(
  cycle: IBillingCycleTemplate | INewBillingCycleTemplateFormData | IBillingCycleFormData
): boolean {
  return !!cycle.frequency?.includes('WEEK');
}

export default PeriodBasedBillingCycleSentence;
