import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DateInput from 'shared/components/DateInput';
import Col from 'shared/components/Layout/Col';
import Row from 'shared/components/Layout/Row';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Select from 'shared/components/Select';
import TextInput, { EmailInput } from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import { isValidPhoneNumber, parsePhoneNumberFromStringWithRegion } from 'shared/util/string';
import Checkbox from 'shared/components/Checkbox';
import {
  accsDocumentTypeEnum,
  ccsRiskReasons,
  ccsStateTerritoryBodyEnum,
  exceptionalCircumstanceReasons,
  extensionReasons,
} from 'shared/constants/enums/ccssEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { TinyDeleteButton } from 'shared/components/Buttons';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import _, { chain, compact, get, orderBy, uniqBy } from 'lodash';
import PhoneInput from 'shared/components/PhoneInput';
import { getCcsDeterminationForAccountChild } from '../../../../duck/actions';
import { COUNTRY_CODES } from 'shared/components/PhoneInput/countryCodes';
import { isEmailValid } from 'shared/util/email';
import { ApolloError } from '@apollo/client';
import DeclarationAccordion, { DeclarationType } from './DeclarationAccordion';
import { useCreateCcssDetermination } from 'gql/ccssDetermination/mutations';
import { useGetCcsCertificatesForChildren } from 'gql/ccssCertificates/queries';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import colors from '_colors.module.scss';
import Alert from 'shared/components/Alert';
import { RootState } from 'store/reducers';
import { useGetCcsDeterminationsForChildren } from 'gql/ccssDetermination/queries';
import { useTranslation } from 'react-i18next';

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

type CreateCcssDeterminationFormData = RecursivePartial<ICreateCcssDeterminationInput>;

const startDateMinimum = new Date(2018, 7, 2);
const dateFormat = 'DD/MM/YYYY';
const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  account: IAccount;
  childEnrolments: ICcsEnrolment[];
  loadingExternal?: boolean;
  baseDeterminationInput?: CreateCcssDeterminationFormData;
}

const emptyDeterminationInput: CreateCcssDeterminationFormData = {};

function getDeterminationStartDate(certEndDateStr: string): string {
  const certEndDate = moment(certEndDateStr);

  // Certificate end dates should be a monday but just in case it isn't we will
  // get the Monday of the week that it falls in. So if the end date was Thursday, we
  // would get the Monday previous and not the Monday for next week.

  const daysToAdd = 7 - certEndDate.day() - 6;
  const startDate = certEndDate.add(daysToAdd, 'days');

  return startDate.toString();
}

export function createDeterminationInput(certificate: ICcssCertificate) {
  const determinationInput: CreateCcssDeterminationFormData = {
    children: [certificate.childId],
    startDate: getDeterminationStartDate(certificate.endDate),
    weeksAtRisk: undefined,
    indicativeRiskToDate: undefined,
    isDeclarationGiven: false,
    additionalInfo: undefined,
    stateTerritoryBody: certificate.stateTerritoryBody,
    riskReasons: certificate.riskReasons,
    supportingDocuments: [],
  };
  return determinationInput;
}

const CreateDeterminationModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  account,
  childEnrolments,
  loadingExternal,
  baseDeterminationInput = emptyDeterminationInput,
}) => {
  const [isShowExtensionReasonSelect, setShowExtensionReasonSelect] = useState(false);
  const [showExceptionalCircumstances, setShowExceptionalCircumstances] = useState(false);
  const { t } = useTranslation(['ccss']);

  // Doing this badness to strip the __typename from the stateTerritoryBody if the stateTerritoryBody exists.
  // __typename appears to be an apollo thing/fault.
  let baseStateTerritoryBody: RecursivePartial<IStateTerritoryBody> | undefined = undefined;
  if (baseDeterminationInput.stateTerritoryBody) {
    const { __typename, ...rest } = baseDeterminationInput.stateTerritoryBody! as any;
    baseStateTerritoryBody = rest;
  }

  const [determinationInput, setDeterminationInput] = useState<CreateCcssDeterminationFormData>({
    ...baseDeterminationInput,
    businessId: account.entityId,
    accountId: account.id,
    centerId: account.centerId,
    stateTerritoryBody: baseStateTerritoryBody,
  });

  const { data: previousCertificates, loading: previousCertLoading } = useGetCcsCertificatesForChildren(
    determinationInput.businessId!,
    determinationInput.centerId!,
    determinationInput.accountId!,
    compact(determinationInput.children)
  );
  const { data: previousDeterminations, loading: determinationsLoading } = useGetCcsDeterminationsForChildren(
    determinationInput.businessId!,
    determinationInput.centerId!,
    determinationInput.accountId!,
    compact(determinationInput.children)
  );

  const selectedChildren = useMemo(
    () => determinationInput.children?.map((c) => account?.children?.find((ac) => ac.id === c)),
    [account, determinationInput.children]
  );

  const weeksAtRiskOptions = useMemo<number[]>(() => {
    let isCWAEnrolment = false;
    if (selectedChildren && selectedChildren.length > 0) {
      const selectedChildEnrolments = childEnrolments.filter((ce) => ce.childId === selectedChildren[0]!.id);
      if (selectedChildEnrolments) {
        isCWAEnrolment = selectedChildEnrolments[0].arrangementType === 'CWA';
      }
    }
    return _.range(1, isCWAEnrolment ? 53 : 14);
  }, [childEnrolments, selectedChildren]);

  const handleChange = useCallback(
    (value: CreateCcssDeterminationFormData) => {
      setDeterminationInput({
        ...determinationInput,
        ...value,
        stateTerritoryBody: {
          ...determinationInput.stateTerritoryBody,
          ...value.stateTerritoryBody,
        },
      });
    },
    [determinationInput]
  );

  const { isFormValid, formErrors } = useMemo(() => {
    const twentyEightDaysAgo = moment().subtract(28, 'days');
    setShowExceptionalCircumstances(
      determinationInput.startDate ? moment(determinationInput.startDate) < twentyEightDaysAgo : false
    );

    // Reset any entered exceptional circumstance information
    if (
      !showExceptionalCircumstances &&
      (determinationInput.exceptionalCircumstanceReason || determinationInput.exceptionalCircumstanceText)
    ) {
      handleChange({ exceptionalCircumstanceReason: undefined, exceptionalCircumstanceText: undefined });
    }

    // exceptionalCircumstanceText is not required for the BEYOND reason
    if (
      determinationInput.exceptionalCircumstanceReason === 'BEYOND' &&
      determinationInput.exceptionalCircumstanceText
    ) {
      handleChange({ exceptionalCircumstanceText: undefined });
    }

    const result = formValidation(
      {
        weeksAtRiskOptionsSize: weeksAtRiskOptions.length + 1,
      },
      determinationInput,
      previousCertificates?.getCcssCertificatesForChildren ?? [],
      previousDeterminations?.getCcssDeterminationsForChildren ?? []
    );
    console.info('Form Errors: ', result.formErrors);
    return result;
  }, [
    determinationInput,
    handleChange,
    previousCertificates,
    previousDeterminations,
    showExceptionalCircumstances,
    weeksAtRiskOptions.length,
  ]);

  const { loadingCreate, createDetermination } = useCreateDeterminationWrapper();

  const loading = loadingCreate || loadingExternal || previousCertLoading || determinationsLoading;

  const handleClose = () => onClose();

  const handleContinue = () => createDetermination(determinationInput, isFormValid, account, handleClose);

  const onWeeksAtRiskChange = useCallback(
    (value: number) => {
      let newVal: any = { weeksAtRisk: value };
      const isOver13Wks = value > 13;
      setShowExtensionReasonSelect(isOver13Wks);

      if (!isOver13Wks) {
        newVal = { ...newVal, extensionReasons: [] };
      }

      handleChange(newVal);
    },
    [determinationInput]
  );

  let availableCertificates = previousCertificates?.getCcssCertificatesForChildren ?? [];
  availableCertificates = availableCertificates.filter((currCert) => {
    const startDate = determinationInput.startDate ? moment(determinationInput.startDate) : moment();
    return startDate.isBetween(moment(currCert.startDate), moment(currCert.endDate));
  });

  return (
    <CenteredModal
      size="lg"
      show={isOpen}
      onHide={handleClose}
      title="Create Determination"
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryChoice="Submit"
      primaryCallback={handleContinue}
      primaryButtonProps={{ disabled: !isFormValid, loading }}
    >
      <Form>
        <Row align="start">
          <Col md="3">
            <TextInput label={`${fieldLabels.center} Name`} value={account?.center?.name ?? ''} disabled={true} />
          </Col>
          <Col md="3">
            <DateInput
              label="Start Date"
              date={determinationInput.startDate}
              isOutsideRange={(day: moment.Moment) =>
                day.day() !== moment().day('Monday').day() || // Must be a monday
                day.isBefore(moment(startDateMinimum)) || // Day cannot be before minimum
                (previousCertificates?.getCcssCertificatesForChildren
                  .filter((cert) => cert.status === 'APPROV')
                  .some((cert) => moment(cert.endDate).isAfter(moment(day))) ??
                  false)
              }
              onDateSelect={(startDate: string) => handleChange({ startDate: startDate })}
              required
              isValid={!formErrors.startDate}
            />
            {formErrors.startDate && <Form.Text className="text-danger">{formErrors.startDate}</Form.Text>}
          </Col>
          <Col md="3">
            <Select
              required
              label="Weeks at Risk"
              value={determinationInput.weeksAtRisk !== 0 ? determinationInput.weeksAtRisk : null}
              name="weeksAtRisk"
              options={weeksAtRiskOptions}
              onChange={(value, name) => onWeeksAtRiskChange(value)}
              errorText={formErrors.weeksAtRisk}
              isInvalid={!!formErrors.weeksAtRisk}
            />
          </Col>
          <Col md="3">
            <DateInput
              className="w-100 mw-100"
              label="Indicative Risk to Date"
              date={determinationInput.indicativeRiskToDate}
              onDateSelect={(date: string) => handleChange({ indicativeRiskToDate: date })}
              required
              isValid={!formErrors.indicativeRiskToDate}
            />
            {formErrors.indicativeRiskToDate && (
              <Form.Text className="text-danger">{formErrors.indicativeRiskToDate}</Form.Text>
            )}
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <Select
              label="Child"
              className="mb-1"
              required
              value={selectedChildren}
              options={account.children}
              getOptionLabel={(c) => `${c.firstname} ${c.lastname}`}
              name="children"
              getOptionValue={(c: IChild) => c.id}
              onChange={(value: IChild | undefined, name) => handleChange({ [name]: [value?.id] ?? null })}
              errorText={formErrors.children}
              isInvalid={!!formErrors.children}
            />
            {availableCertificates.map((childCert) => (
              <p className="mb-1 font-size-12" style={{ color: colors.steelBlue }}>
                {`${childCert?.child?.firstname} ${childCert?.child?.lastname}`} has {childCert.weeksAtRisk} week/s of
                certificate available
              </p>
            ))}
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <Select
              label="Risk Reasons"
              name="riskReasons"
              value={ccsRiskReasons.filter(({ value }) => determinationInput.riskReasons?.includes(value))}
              isMulti
              required
              options={orderBy(ccsRiskReasons, (r) => r.label)}
              onChange={(value: any[], name) => handleChange({ [name]: value?.map((v) => v.value) ?? null })}
              errorText={formErrors.riskReasons}
              isInvalid={!!formErrors.riskReasons}
            />
          </Col>
        </Row>

        {isShowExtensionReasonSelect && (
          <Row align="start">
            <Col>
              <Select
                label="Extension Reason"
                name="extensionReasons"
                value={extensionReasons.find(({ value }) =>
                  determinationInput.extensionReasons?.find((o) => o === value)
                )}
                required
                options={extensionReasons}
                onChange={(value: { label: string; value: string }, name) => handleChange({ [name]: [value.value] })}
                errorText={formErrors.extensionReasons}
                isInvalid={!!formErrors.extensionReasons}
              />
              {determinationInput.extensionReasons != null && (
                <Alert className="mb-5" variant="warning">
                  You must upload documentation supporting your reason for the extension - please select the type ACC008
                  Evidence of exceptional circumstance
                </Alert>
              )}
            </Col>
          </Row>
        )}

        {showExceptionalCircumstances && (
          <Row align="start">
            <Col>
              <Select
                label={t('ccss:determinations.create-determination-modal.exceptionalCircumstanceReason')}
                name="exceptionalCircumstanceReason"
                value={exceptionalCircumstanceReasons.find(
                  ({ value }) => determinationInput.exceptionalCircumstanceReason === value
                )}
                required={showExceptionalCircumstances}
                options={exceptionalCircumstanceReasons}
                onChange={(value: { label: string; value: string }, name) => handleChange({ [name]: value.value })}
                errorText={formErrors.exceptionalCircumstanceReason}
                isInvalid={!!formErrors.exceptionalCircumstanceReason}
              />
            </Col>
            <Col>
              <TextInput
                label={t('ccss:determinations.create-determination-modal.exceptionalCircumstanceText')}
                name="exceptionalCircumstanceText"
                value={determinationInput.exceptionalCircumstanceText}
                onChange={(value, name) => handleChange({ [name]: value })}
                required={determinationInput.exceptionalCircumstanceReason === 'OTHER'}
                isInvalid={!!formErrors.exceptionalCircumstanceText}
                errorText={formErrors.exceptionalCircumstanceText}
                disabled={determinationInput.exceptionalCircumstanceReason === 'BEYOND'}
              />
            </Col>
          </Row>
        )}

        <Row align="start">
          <Col>
            <h5>State/Territory Body Details</h5>
          </Col>
        </Row>

        <Row align="start">
          <Col md="3">
            <DateInput
              required
              label="Notice Date"
              isOutsideRange={
                (day) =>
                  day.isAfter(moment().endOf('day')) || // Date must be equal to current date or before.
                  day.isBefore(moment(startDateMinimum)) // Day cannot be before minimum
              }
              onDateSelect={(value) => handleChange({ stateTerritoryBody: { noticeGivenDate: value } })}
              date={determinationInput.stateTerritoryBody?.noticeGivenDate}
              isValid={!formErrors.stateTerritoryBody.noticeGivenDate}
            />
            {formErrors.stateTerritoryBody.noticeGivenDate && (
              <Form.Text className="text-danger">{formErrors.stateTerritoryBody.noticeGivenDate}</Form.Text>
            )}
          </Col>
          <Col md="9">
            <Select
              required
              label="Notice"
              value={
                determinationInput.stateTerritoryBody?.noticeToStateTerritory
                  ? 'ToState'
                  : determinationInput.stateTerritoryBody?.notifiedByStateTerritory
                  ? 'ByState'
                  : null
              }
              name="notice"
              options={noticeOptions}
              onChange={(value) => handleChange(get(noticeResults, value.value, {}))}
              isInvalid={!!formErrors.stateTerritoryBody.notice}
              errorText={formErrors.stateTerritoryBody.notice}
            />
          </Col>
        </Row>

        <Row align="start">
          <Col md="3">
            <TextInput
              label="Organistion Name"
              name="organisationName"
              value={determinationInput.stateTerritoryBody?.organisationName}
              onChange={(value, name) => handleChange({ stateTerritoryBody: { [name]: value } })}
              required={determinationInput.stateTerritoryBody?.noticeToStateTerritory}
              isInvalid={!!formErrors.stateTerritoryBody.organisationName}
              errorText={formErrors.stateTerritoryBody.organisationName}
            />
          </Col>
          <Col md="3">
            <TextInput
              label="Reference Number"
              name="stateReferenceNumber"
              onChange={(value, name) => handleChange({ stateTerritoryBody: { [name]: value } })}
              value={determinationInput.stateTerritoryBody?.stateReferenceNumber}
              isInvalid={!!formErrors.stateTerritoryBody.stateReferenceNumber}
              errorText={formErrors.stateTerritoryBody.stateReferenceNumber}
            />
          </Col>
          <Col md="6">
            <Select
              label="Body Type"
              value={determinationInput.stateTerritoryBody?.type}
              name="type"
              required
              options={orderBy(ccsStateTerritoryBodyEnum, (e) => e.label)}
              onChange={(value, name) => handleChange({ stateTerritoryBody: { [name]: value.value } })}
              isInvalid={!!formErrors.stateTerritoryBody.type}
              errorText={formErrors.stateTerritoryBody.type}
            />
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <h6>Notified by:</h6>
          </Col>
        </Row>

        <Row align="start">
          <Col md="6">
            <TextInput
              label="First Name"
              value={determinationInput.stateTerritoryBody?.notifiedByPersonFirstName}
              name="notifiedByPersonFirstName"
              onChange={(value, name) => handleChange({ stateTerritoryBody: { [name]: value } })}
              isInvalid={!!formErrors.stateTerritoryBody.notifiedByPersonFirstName}
              errorText={formErrors.stateTerritoryBody.notifiedByPersonFirstName}
            />
          </Col>
          <Col md="6">
            <TextInput
              label="Last Name"
              name="notifiedByPersonLastName"
              onChange={(value, name) => handleChange({ stateTerritoryBody: { [name]: value } })}
              value={determinationInput.stateTerritoryBody?.notifiedByPersonLastName}
              isInvalid={!!formErrors.stateTerritoryBody.notifiedByPersonLastName}
              errorText={formErrors.stateTerritoryBody.notifiedByPersonLastName}
            />
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <h6>State/Territory Contact</h6>
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <TextInput
              label="Name or Id"
              name="statePersonNameOrId"
              onChange={(value, name) => handleChange({ stateTerritoryBody: { [name]: value } })}
              value={determinationInput.stateTerritoryBody?.statePersonNameOrId}
              isInvalid={!!formErrors.stateTerritoryBody.statePersonNameOrId}
              errorText={formErrors.stateTerritoryBody.statePersonNameOrId}
            />
          </Col>
          <Col>
            <PhoneInput
              label="Phone Number"
              name="statePersonPhoneNumber"
              value={determinationInput.stateTerritoryBody?.statePersonPhoneNumber ?? undefined}
              onChange={(value: string) => {
                const forceLeadingZeroOrOne = (val?: string) =>
                  val && !val.startsWith('0') && !val.startsWith('1') ? `0${val}` : val;
                const formattedValue = forceLeadingZeroOrOne(
                  parsePhoneNumberFromStringWithRegion(value)?.nationalNumber.toString()
                );
                const stripPlaceholder = (val: string) => (val === COUNTRY_CODES.AU.placeholder ? undefined : val);
                handleChange({
                  stateTerritoryBody: { statePersonPhoneNumber: formattedValue ?? stripPlaceholder(value) },
                });
              }}
              isInvalid={
                determinationInput.stateTerritoryBody?.statePersonPhoneNumber
                  ? !isValidPhoneNumber(determinationInput.stateTerritoryBody?.statePersonPhoneNumber ?? '')
                  : false
              }
              errorText={formErrors.stateTerritoryBody.statePersonPhoneNumber}
            />
          </Col>
          <Col>
            <EmailInput
              label="Email"
              name="statePersonEmail"
              onChange={(value, name) => handleChange({ stateTerritoryBody: { [name]: value } })}
              value={determinationInput.stateTerritoryBody?.statePersonEmail}
              isInvalid={!!formErrors.stateTerritoryBody.statePersonEmail}
              errorText={formErrors.stateTerritoryBody.statePersonEmail}
            />
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <TextInput
              label="Further Information"
              name="text"
              value={determinationInput.stateTerritoryBody?.text}
              onChange={(value, name) => handleChange({ stateTerritoryBody: { [name]: value } })}
              as="textarea"
              rows={3}
              isInvalid={!!formErrors.stateTerritoryBody.text}
              errorText={formErrors.stateTerritoryBody.text}
            />
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <div className="d-flex flex-row">
              <h5>Supporting Documents</h5>
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <FileSelectDropbox
              value={determinationInput.supportingDocuments?.map((d) => d?.file as File)}
              onChange={(files) =>
                handleChange({ supportingDocuments: filesToISupportingDocuments(acceptedFiles(files)) })
              }
              showFiles={false}
              acceptedFileExts={acceptedFileExts.join(',')}
            />
          </Col>
        </Row>

        <Row align="start">
          <Col>
            {orderBy(determinationInput.supportingDocuments ?? [], (c) => c?.file?.name).map((doc, i) => {
              return (
                <Row key={doc?.file?.name} className="file-display m-0">
                  <Col md="6">
                    <h6>{doc?.file?.name}</h6>
                  </Col>
                  <Col md="5">
                    <Select
                      className="my-auto"
                      placeholder="Document Type"
                      options={orderBy(accsDocumentTypeEnum, (e) => e.label)}
                      value={doc?.type}
                      onChange={(value) => {
                        const otherFiles = determinationInput.supportingDocuments?.filter((d) => d !== doc) ?? [];
                        const newSupportDocs: RecursivePartial<ISupportingDocument[]> = [
                          ...otherFiles,
                          { file: doc?.file, type: value.value },
                        ];
                        handleChange({ supportingDocuments: newSupportDocs });
                      }}
                      required
                      isInvalid={formErrors.supportingDocuments.some((x) => x.file === doc?.file?.name)}
                      errorText={formErrors.supportingDocuments
                        .filter((x) => x.file === doc?.file?.name)
                        .map((x) => x.error)
                        .join('\n')}
                    />
                  </Col>
                  <Col md="1">
                    <TinyDeleteButton
                      onClick={() => {
                        const newSupportingDocuments = determinationInput.supportingDocuments?.filter(
                          (d) => !(doc?.file?.name === d?.file?.name)
                        );
                        handleChange({ supportingDocuments: newSupportingDocuments });
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
            {formErrors.supportingDocuments
              .filter((x) => x.file === '')
              .map((value, index) => (
                <Form.Text key={index} className="text-danger">
                  {`${value.error}`}
                </Form.Text>
              ))}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <TextInput
              label="Additional Info"
              name="additionalInfo"
              value={determinationInput.additionalInfo}
              onChange={(value, name) => handleChange({ [name]: value })}
              as="textarea"
              rows={3}
              isInvalid={!!formErrors.additionalInfo}
              errorText={formErrors.additionalInfo}
            />
          </Col>
        </Row>

        <Row align="start">
          <DeclarationAccordion declarationType={DeclarationType.Determination} />
        </Row>

        <Row className="mt-4">
          <Col>
            <div className="d-flex flex-row">
              <Checkbox
                label="I declare that the information provided is true and correct"
                value={determinationInput.isDeclarationGiven}
                onChange={(value) => handleChange({ isDeclarationGiven: value })}
              />
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
          </Col>
        </Row>
      </Form>
    </CenteredModal>
  );
};

const noticeOptions = [
  {
    label: 'The provider has notified a State or Territory body',
    value: 'ToState',
  },
  {
    label: 'Provider was notified by a State or Territory body',
    value: 'ByState',
  },
];

const noticeResults: Record<'ToState' | 'ByState', CreateCcssDeterminationFormData> = {
  ToState: { stateTerritoryBody: { noticeToStateTerritory: true, notifiedByStateTerritory: false } },
  ByState: { stateTerritoryBody: { noticeToStateTerritory: false, notifiedByStateTerritory: true } },
};

const acceptedFileExts = ['.pdf', '.png', '.tiff', '.jpg'];

function acceptedFiles(files: File[]) {
  // When you select the same file twice it duplicates in the file list, so we only use unique filenames.
  // Also the user can select AllFiles so filter out the files we don't support
  const acceptedFiles = uniqBy(files, (f) => f.name).filter((f) =>
    acceptedFileExts.includes('.' + (f.name.split('.').pop()?.toLowerCase() ?? ''))
  );
  return acceptedFiles;
}

function filesToISupportingDocuments(files: File[]) {
  const supportingFiles: ISupportingDocument[] = files.map((file) => {
    return {
      file: file,
      type: undefined,
    };
  });
  return supportingFiles;
}

type AsStrings<T> = {
  [P in keyof T]: string;
};

interface ICreateDeterminationFormErrors
  extends Omit<Partial<AsStrings<ICreateCcssDeterminationInput>>, 'stateTerritoryBody' | 'supportingDocuments'> {
  stateTerritoryBody: Partial<AsStrings<IStateTerritoryBody & { notice?: string }>>;
  supportingDocuments: {
    file: string;
    error: string;
  }[];
}

function formValidation(
  validationContext: { weeksAtRiskOptionsSize: number },
  determinationInput: CreateCcssDeterminationFormData,
  previousCertificates: ICcssCertificate[],
  previousDeterminations: ICcssDetermination[]
) {
  let errors: ICreateDeterminationFormErrors = { stateTerritoryBody: {}, supportingDocuments: [] };
  let hasError = false;
  let isSubsequent = false;
  const dateAfterRisk = determinationInput.children && determinationInput.riskReasons;

  if (determinationInput.startDate && Boolean(previousDeterminations) && previousDeterminations.length > 0) {
    isSubsequent = previousDeterminations.some(
      (determination) =>
        moment(determinationInput.startDate)
          .startOf('day')
          .diff(moment(determination.endDate).startOf('day'), 'days') === 1
    );
  }

  if (!determinationInput.startDate && dateAfterRisk) {
    errors.startDate = 'Start date is required';
    hasError = true;
  }

  if (determinationInput.startDate && moment(determinationInput.startDate).isBefore(moment(startDateMinimum))) {
    errors.startDate = `Start date cannot be before ${moment(startDateMinimum).format(dateFormat)}`;
    hasError = true;
  }

  const twentyEightDaysAgo = moment().subtract(28, 'days');

  if (moment(determinationInput.startDate) < twentyEightDaysAgo) {
    if (!determinationInput.exceptionalCircumstanceReason) {
      errors.exceptionalCircumstanceReason = 'An exceptional circumstance reason is required';
      hasError = true;
    } else if (
      determinationInput.exceptionalCircumstanceReason === 'OTHER' &&
      !determinationInput.exceptionalCircumstanceText
    ) {
      errors.exceptionalCircumstanceText = 'Text is required when exceptional circumstance is other';
      hasError = true;
    }
  }

  if (
    previousCertificates
      .filter((cert) => cert.status === 'APPROV')
      .some((cert) => moment(determinationInput.startDate).isBetween(moment(cert.startDate), moment(cert.endDate)))
  ) {
    errors.startDate =
      'At risk period of the initial determination is overlapping with an existing certificate for the same child/service combination.';
    hasError = true;
  }

  if (determinationInput.startDate && !determinationInput.indicativeRiskToDate) {
    errors.indicativeRiskToDate = 'Indicative Risk To Date is required';
    hasError = true;
  }

  if (
    !determinationInput.weeksAtRisk ||
    determinationInput.weeksAtRisk < 1 ||
    determinationInput.weeksAtRisk > validationContext.weeksAtRiskOptionsSize
  ) {
    errors.weeksAtRisk = `Weeks at risk must be between 1 and ${validationContext.weeksAtRiskOptionsSize - 1}`;
    hasError = true;
  }

  if (!determinationInput?.children && determinationInput?.children?.length === 0) {
    errors.children = 'At least 1 child needs to be selected';
    hasError = true;
  }

  if (!determinationInput?.riskReasons && determinationInput?.riskReasons?.length === 0) {
    errors.riskReasons = 'At least 1 reason needs to be selected';
    hasError = true;
  }

  if (!determinationInput.stateTerritoryBody?.noticeGivenDate && dateAfterRisk) {
    errors.stateTerritoryBody.noticeGivenDate = 'Notice date is required';
    hasError = true;
  }

  if (
    determinationInput.stateTerritoryBody?.noticeGivenDate &&
    moment(determinationInput.stateTerritoryBody.noticeGivenDate).isBefore(moment(startDateMinimum))
  ) {
    errors.stateTerritoryBody.noticeGivenDate = `Notice given date cannot be before ${moment(startDateMinimum).format(
      dateFormat
    )}`;
    hasError = true;
  }

  if (
    !determinationInput.stateTerritoryBody?.noticeToStateTerritory &&
    !determinationInput.stateTerritoryBody?.notifiedByStateTerritory
  ) {
    errors.stateTerritoryBody.notice = 'Notice is required';
    hasError = true;
  }

  if (
    determinationInput.stateTerritoryBody?.statePersonEmail &&
    !isEmailValid(determinationInput.stateTerritoryBody.statePersonEmail)
  ) {
    errors.stateTerritoryBody.statePersonEmail = 'Email is invalid';
    hasError = true;
  }

  if (
    determinationInput.stateTerritoryBody?.noticeToStateTerritory &&
    !determinationInput.stateTerritoryBody.organisationName
  ) {
    errors.stateTerritoryBody.organisationName = 'Organisation name is required';
    hasError = true;
  }

  if (!determinationInput.stateTerritoryBody?.type) {
    errors.stateTerritoryBody.type = 'Body type is required';
    hasError = true;
  }

  if (!determinationInput.isDeclarationGiven) {
    errors.isDeclarationGiven = 'You must declare the information provided is true and correct';
    hasError = true;
  }

  for (const doc of determinationInput.supportingDocuments ?? []) {
    if (doc && !doc.type) {
      hasError = true;
      errors.supportingDocuments.push({
        error: 'Document type is required',
        file: doc.file?.name ?? '',
      });
    }
    if (doc && !doc.file?.name) {
      hasError = true;
      errors.supportingDocuments.push({
        error: 'File name is required',
        file: '',
      });
    }
  }

  if (determinationInput.stateTerritoryBody?.notifiedByStateTerritory) {
    const hasACC003 = determinationInput.supportingDocuments?.find((doc) => doc?.type === 'ACC003');
    if (!hasACC003) {
      hasError = true;
      errors.supportingDocuments.push({
        error: '204k notice is required when notified by state territory',
        file: '',
      });
    }
  }

  if (
    !determinationInput.supportingDocuments?.some((doc) => doc?.type === 'ACC002') &&
    !isSubsequent &&
    (determinationInput.weeksAtRisk ?? 0) > 13
  ) {
    hasError = true;
    errors.supportingDocuments.push({
      error: 'Child Well Being Evidence document is required',
      file: '',
    });
  }

  if (
    !determinationInput.supportingDocuments?.some((doc) => doc?.type === 'ACC008') &&
    determinationInput.exceptionalCircumstanceReason
  ) {
    hasError = true;
    errors.supportingDocuments.push({
      error: 'Evidence of exceptional circumstances is required',
      file: '',
    });
  }

  if (
    determinationInput.stateTerritoryBody?.statePersonPhoneNumber &&
    !isValidPhoneNumber(determinationInput.stateTerritoryBody.statePersonPhoneNumber)
  ) {
    errors.stateTerritoryBody.statePersonPhoneNumber = 'Phone number provided is invalid';
    hasError = true;
  }

  return { isFormValid: !hasError, formErrors: errors };
}

function useCreateDeterminationWrapper() {
  const [createCcssDetermination, { loading: loadingCreate, error }] = useCreateCcssDetermination();

  const dispatch = useDispatch();

  function createDetermination(
    determinationInput: CreateCcssDeterminationFormData,
    valid: boolean,
    account: IAccount,
    handleClose: () => void
  ) {
    if (!valid) return;

    const parsedDeterminationInput = parseCreateCcssDeterminationInput(determinationInput);
    const formattedDeterminationInput = formatCreateCcssDeterminationDates(parsedDeterminationInput);

    createCcssDetermination({
      variables: {
        input: { ...formattedDeterminationInput },
      },
    })
      .then((data) => {
        const results = chain(data.data?.createCcssDetermination ?? [])
          .groupBy((e) => e.childId)
          .map((value, key) => ({ childId: key, determinations: value }))
          .value();

        for (const { childId, determinations } of results) {
          dispatch(getCcsDeterminationForAccountChild(account.id, childId, determinations));
        }

        showToast('Determination(s) created', 'success');
        handleClose();
      })
      .catch((error) => {
        if (error instanceof ApolloError) {
          if (error.graphQLErrors?.length > 0) {
            showToast(
              `${error.graphQLErrors
                .map((err) => {
                  // @ts-ignore
                  return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
                })
                .join(', ')}`,
              'error'
            );
          } else {
            showToast(
              'Unable to perform the action. Please check the upload document location (if applicable) and make sure it exists.',
              'error'
            );
          }
        } else {
          showToast(error.toString(), 'error');
        }
      });
  }

  return { loadingCreate: loadingCreate, createDetermination };
}

function parseCreateCcssDeterminationInput(determinationInput: CreateCcssDeterminationFormData) {
  // This currently does no validation beyond this point, it just converts the partial to the unwrapped type
  const { stateTerritoryBody } = determinationInput;
  const result: ICreateCcssDeterminationInput = {
    businessId: determinationInput.businessId ?? 'UNKNOWN',
    accountId: determinationInput.accountId ?? 'UNKNOWN',
    centerId: determinationInput.centerId ?? 'UNKNOWN',
    children: compact(determinationInput.children ?? ['UNKNOWN']),
    startDate: determinationInput.startDate ?? 'UNKNOWN',
    weeksAtRisk: determinationInput.weeksAtRisk ?? -1,
    indicativeRiskToDate: determinationInput.indicativeRiskToDate ?? 'UNKNOWN',
    isDeclarationGiven: determinationInput.isDeclarationGiven ?? false,
    additionalInfo: determinationInput.additionalInfo, //optional
    stateTerritoryBody: !stateTerritoryBody
      ? undefined
      : {
          // optional
          ...stateTerritoryBody,
          noticeGivenDate: stateTerritoryBody.noticeGivenDate ?? 'UNKNOWN',
          noticeToStateTerritory: stateTerritoryBody.noticeToStateTerritory ?? false,
          notifiedByStateTerritory: stateTerritoryBody.notifiedByStateTerritory ?? false,
          organisationName: stateTerritoryBody.organisationName ?? '', // This can be blank if null/undefined
        },
    riskReasons: compact(determinationInput.riskReasons ?? []),
    supportingDocuments:
      compact(
        determinationInput.supportingDocuments?.map((x) => {
          const support: ISupportingDocument = {
            file: x?.file as File,
            type: x?.type ?? undefined, // Causes an error if undefined
          };
          return support;
        }) ?? []
      ) ?? [],
    extensionReasons: compact(determinationInput.extensionReasons ?? []),
    exceptionalCircumstanceReason: determinationInput.exceptionalCircumstanceReason,
    exceptionalCircumstanceText: determinationInput.exceptionalCircumstanceText,
  };
  return result;
}

function formatCreateCcssDeterminationDates(determinationInput: ICreateCcssDeterminationInput) {
  const { stateTerritoryBody } = determinationInput;

  const submitDateFormat = moment.HTML5_FMT.DATE;

  const result: ICreateCcssDeterminationInput = {
    ...determinationInput,
    startDate: moment(determinationInput.startDate).format(submitDateFormat),
    indicativeRiskToDate: moment(determinationInput.indicativeRiskToDate).format(submitDateFormat),
    stateTerritoryBody: !stateTerritoryBody
      ? undefined
      : {
          ...stateTerritoryBody,
          noticeGivenDate: moment(stateTerritoryBody.noticeGivenDate).format(submitDateFormat),
        },
  };
  return result;
}

export default CreateDeterminationModal;
