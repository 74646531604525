import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import { LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { serviceFeeGroupFields } from './fields';

interface IGetPaginatedServiceFeeGroupData {
  getPaginatedServiceFeeGroups: IPagedResult<IServiceFeeGroup>;
}

interface IGetServiceFeeGroupData {
  getServiceFeeGroupById: IServiceFeeGroup;
}

interface IGetPaginatedServiceFeeGroupVariables {
  input: {
    centerIds: string[];
    feeTypes: ServiceFeeType[];
    searchKey?: string;
    sortBy: string;
    sortDirection: string;
    pageNumber: number;
    pageSize: number;
  };
}

interface IGetServiceFeeGroupVariables {
  id: string;
}

export const GET_PAGINATED_SERVICE_FEE_GROUPS = gql`
  query($input: GetPaginatedServiceFeeGroupsInput!) {
    getPaginatedServiceFeeGroups(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        ${serviceFeeGroupFields}
      }
    }
  }
`;

export const GET_SERVICE_FEE_GROUP_BY_ID = gql`
  query($id: ID!) {
    getServiceFeeGroupById(id: $id) {
      ${serviceFeeGroupFields}
    }
  }
`;

export const useGetPaginatedServiceFeeGroups = (
  options?: QueryHookOptions<IGetPaginatedServiceFeeGroupData, IGetPaginatedServiceFeeGroupVariables>
) =>
  useQuery<IGetPaginatedServiceFeeGroupData, IGetPaginatedServiceFeeGroupVariables>(GET_PAGINATED_SERVICE_FEE_GROUPS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetServiceFeeGroupById = (
  options?: QueryHookOptions<IGetServiceFeeGroupData, IGetServiceFeeGroupVariables>
) =>
  useQuery(GET_SERVICE_FEE_GROUP_BY_ID, {
    fetchPolicy: 'cache-and-network',
    skip: !options?.variables?.id ?? false,
    ...options,
  });
