import React from 'react';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import * as Mui from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetLockedUntilForBusiness } from 'gql/timeLogLock/queries';
import { isEmpty } from 'lodash';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Alert from 'shared/components/Alert';
import { showToast } from 'shared/components/Toast';
import { useCreateTimeCardLock } from 'gql/timeLogLock/mutations';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import useFormatDate from 'shared/hooks/useFormatDate';
import moment from 'moment';
import _ from 'lodash';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';

interface IProps {}

const TimeLogLocking: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const [lockedUntil, setLockedUntil] = React.useState<string | null>('');
  const formatDate = useFormatDate();
  const hasEditOperationsPermission = useHasRoleAreaLevel({
    area: AreaType.Operations,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const { data, loading, refetch } = useGetLockedUntilForBusiness({
    skip: isEmpty(businessId) || businessId === '',
    variables: {
      input: {
        businessId,
      },
    },
  });

  React.useEffect(() => {
    setLockedUntil(
      data?.getLockedUntilForBusiness.data === null ? '' : data?.getLockedUntilForBusiness.data.lockedUntil ?? ''
    );
  }, [data]);

  const [upsertTimeLogLock] = useCreateTimeCardLock({
    onCompleted: () => {
      showToast('Time Card Lock Updated', 'success');
      refetch();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onSave = () => {
    upsertTimeLogLock({
      variables: {
        input: {
          businessId: businessId,
          lockedUntil: moment(lockedUntil).startOf('day').format() ?? '',
        },
      },
    });
  };

  const lockCreatedAt = React.useMemo(
    () =>
      formatDate(
        data?.getLockedUntilForBusiness.data === null ? '' : data?.getLockedUntilForBusiness.data.createdAt ?? '',
        'MM/DD/YYYY'
      ),
    [data]
  );

  return (
    <>
      <PageWrapperBody>
        {user?.isInternal && <CenterSelectBanner pageName="operations" showCenterSelect={false} />}
        <br />
        <Mui.Card elevation={0} variant="outlined" sx={{ marginBottom: '16px' }}>
          <Mui.CardHeader style={{ paddingLeft: '16px' }} component="h1" title="Time Log Locking" />
          <Mui.CardContent>
            <Alert variant="info">
              To avoid payroll discrepancies, time logs should be locked prior to running Weekly Payroll reports.
            </Alert>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Mui.Typography sx={{ paddingRight: '4px', display: 'flex', alignItems: 'center', paddingTop: '24px' }}>
                Time logs for all {t('spelling.center')}s are locked on
                <MobileDatePicker
                  views={['month', 'day']}
                  value={lockedUntil}
                  onChange={(v) => setLockedUntil(v)}
                  InputAdornmentProps={{ position: 'start' }}
                  disableFuture
                  disabled={!hasEditOperationsPermission}
                  renderInput={(params) => (
                    <Mui.TextField
                      size="small"
                      sx={{
                        fontFamily: '"Open Sans", sans-serif',
                        '& legend': {
                          width: 0,
                        },
                        marginLeft: '8px',
                      }}
                      error={false}
                      {...params}
                    />
                  )}
                />
                {`\u00A0`} and all dates prior.
              </Mui.Typography>
            </LocalizationProvider>
          </Mui.CardContent>
          <Mui.Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
            <Mui.Typography variant="caption" fontWeight={300}>
              {_.isNil(data?.getLockedUntilForBusiness.data) ? `` : `Locked On: ${lockCreatedAt}`}
            </Mui.Typography>
            <Mui.Button
              variant="contained"
              color="secondary"
              disabled={
                lockedUntil === '' ||
                (lockedUntil ===
                  (data?.getLockedUntilForBusiness.data === null
                    ? ''
                    : data?.getLockedUntilForBusiness.data.lockedUntil) ??
                  '') ||
                loading
              }
              onClick={onSave}
            >
              Save
            </Mui.Button>
          </Mui.Box>
        </Mui.Card>
      </PageWrapperBody>
    </>
  );
};

export default TimeLogLocking;
