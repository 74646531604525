import { useGetCcsEntitlements } from 'gql/subsidies/queries';
import moment from 'moment';
import React, { useState } from 'react';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import CcsEntitlementCard from './EntitlementsCard';
import useDatatableState from 'shared/hooks/useDatatableState2';
import Select from 'shared/components/Select';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import EntitlementChangeIndicator from './EntitlementChangeIndicator';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { capitalize, getInitials, stringToHsl } from 'shared/util/string';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useTranslation } from 'react-i18next';
import { accountStatusColorHexes } from 'shared/constants/tagColors';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { ColumnType, DataTableColumnFactory } from 'shared/components/DataTableColumnType/DataTableColumnFactory';
import { ICcsEntitlement } from 'shared/types/ccsEntitlement';

interface IProps {
  businessId: string;
  selectedCenters: string[];
}

export interface ITimeSinceOption {
  label: string;
  value: string;
}

interface ISort {
  field: string;
  direction: ElasticsearchSortDirection;
}

const EntitlementsTab: React.FC<IProps> = ({ businessId, selectedCenters, ...props }) => {
  const timeRangeOptions: ITimeSinceOption[] = [
    { label: 'Last 1 day', value: '1' },
    { label: 'Last 7 days', value: '7' },
    { label: 'Last 14 days', value: '14' },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSortOptions] = useState<ISort[]>([{ field: 'accountName', direction: 'ASCENDING' }]);
  const [timeSinceFilter, setTimeSinceFilter] = useState<ITimeSinceOption | null>(timeRangeOptions[0]);
  const [tableState, tableFunctions] = useDatatableState();
  const [filteredStatus, setFilteredStatus] = useState<string[]>(['Active']);

  const { t } = useTranslation();

  const statusOptions: ITableFilterOption[] = [
    {
      label: capitalize(t('spelling.active')),
      value: 'Active',
      indicatorColor: accountStatusColorHexes.Active,
    },
    {
      label: capitalize(t('spelling.inactive')),
      value: 'Inactive',
      indicatorColor: accountStatusColorHexes.Inactive,
    },
  ];

  const { data: entitlementsPagedResult, loading: getCcsEntitlementsLoading } = useGetCcsEntitlements({
    variables: {
      input: {
        businessId: businessId,
        centerIds: selectedCenters,
        changedSinceDays: (timeSinceFilter && parseInt(timeSinceFilter.value)) ?? undefined,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortDtos: sort,
        childName: searchTerm,
        statusType:
          !!filteredStatus && !!filteredStatus.length
            ? filteredStatus.length > 1
              ? undefined
              : (filteredStatus[0] as AccountStatusType)
            : undefined,
        statusAtDate: moment().format('YYYY-MM-DD'),
      },
    },
  });

  const expandRow = (row: ICcsEntitlement) => (
    <div>
      <CcsEntitlementCard entitlement={row} />
    </div>
  );

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field: field, direction: direction }]);
  };

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <DataTable
      showSelect={false}
      noPadding={true}
      data={entitlementsPagedResult?.getCcsEntitlements.data ?? []}
      dataSize={entitlementsPagedResult?.getCcsEntitlements.totalRecords ?? 0}
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      expandRow={expandRow}
      showLoadingOverlay={getCcsEntitlementsLoading}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      onSort={handleSort}
      columns={[
        {
          text: fieldLabels.center,
          dataField: 'centerName',
          sort: true,
        },
        {
          text: 'Account',
          dataField: 'accountName',
          sort: true,
          formatter: (cell: any, row: ICcsEntitlement) =>
            DataTableColumnFactory.getColumn<ICcsEntitlement>('accountName', ColumnType.ACCOUNT).render(row),
        },
        {
          text: 'Child',
          dataField: 'childName',
          sort: true,
          formatter: (cell: any, row: ICcsEntitlement) => (
            <AvatarDataTableCell
              color={stringToHsl(row.child.id)}
              initials={getInitials(row.child)}
              primaryText={row.childName}
              avatar={row.child.avatar?.url ?? ''}
            />
          ),
        },
        {
          text: 'Last Updated',
          dataField: 'date',
          sort: true,
          align: 'center',
          headerAlign: 'center',
          formatter: (date: string) => moment(date).format('DD/MM/YYYY'),
        },
        {
          text: 'Previous',
          dataField: 'previous',
          align: 'center',
          headerAlign: 'center',
          formatter: (cell: any, row: ICcsEntitlement) =>
            `${row.previousEntitlement?.apportionedHoursPerFortnight ?? '--'}hr / ${
              row.previousEntitlement?.percentage ?? '--'
            }%`,
        },
        {
          text: 'Current',
          dataField: 'current',
          align: 'center',
          headerAlign: 'center',
          formatter: (cell: any, row: ICcsEntitlement) => `${row.apportionedHoursPerFortnight}hr / ${row.percentage}%`,
        },
        {
          text: 'Change Hours',
          dataField: 'apportionedHoursPerFortnightDelta',
          sort: true,
          align: 'right',
          headerAlign: 'right',
          formatter: (cell: any, row: ICcsEntitlement) => (
            <EntitlementChangeIndicator
              previous={row.previousEntitlement?.apportionedHoursPerFortnight}
              current={row.apportionedHoursPerFortnight}
              suffix="hr"
            />
          ),
        },
        {
          text: 'Change %',
          dataField: 'percentageDelta',
          sort: true,
          align: 'right',
          headerAlign: 'right',
          formatter: (cell: any, row: ICcsEntitlement) => (
            <EntitlementChangeIndicator
              previous={row.previousEntitlement?.percentage}
              current={row.percentage}
              suffix="%"
            />
          ),
        },
      ]}
      renderHeader={(paginationProps, searchProps) => (
        <TableHeader className="flex-wrap">
          <SizePerPage paginationProps={paginationProps} />
          <TableSearch placeholder="Search Children" onChange={(term) => setSearchTerm(term)} className="mr-4 my-2" />
          <label className="mr-2 flex-nowrap my-2">Filter by changes in: </label>
          <Select
            options={timeRangeOptions}
            onChange={(option: ITimeSinceOption) => setTimeSinceFilter(option)}
            className="ml-auto mr-4 my-2"
            getOptionLabel={(option: ITimeSinceOption) => option.label}
            getOptionValue={(option: ITimeSinceOption) => option.value}
            placeholder="Filter by changes in..."
            value={timeSinceFilter}
          />
          <label className="mr-2 flex-nowrap my-2">{capitalize(t('spelling.accountStatusFilter'))}</label>
          <DropdownFilter
            title={capitalize(t('spelling.status'))}
            className="mr-2 my-2"
            selectedFilters={filteredStatus}
            options={statusOptions}
            onFilterSelect={(values) => setFilteredStatus(values.map((value) => value.value))}
          />
          <IconButtonCircle
            icon={faTimes}
            onClick={() => {
              setTimeSinceFilter(null);
              setFilteredStatus(['Active']);
            }}
            tooltipDirection="bottom"
            tooltipText="Clear Filters"
            className="mr-4 my-2"
          />
        </TableHeader>
      )}
    ></DataTable>
  );
};

export default EntitlementsTab;
