import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

const GET_OPERATIONS_DATA = gql`
  query {
    getAllowedEntities {
      id
      name
      payrixCustomerId
      primaryPaymentMethodId
      primaryPaymentMethod {
        id
        token
        lastFour
        expirationDate
      }
      paymentMethods {
        id
        token
        lastFour
        expirationDate
      }
      centers {
        id
        name
        entityId
        phoneNumber
        address {
          address1
          city
          state
          postalCode
          country
        }
        staffOperationHours {
          dayOfWeek
          startTime
          endTime
        }
        familyOperationHours {
          dayOfWeek
          startTime
          endTime
        }
      }
    }
  }
`;

interface IGetOperationsData {
  getAllowedEntities: IEntity[];
}

interface IGetOperationsVariables {}

const useGetOperationsData = (skip = false) =>
  useQuery<IGetOperationsData, IGetOperationsVariables>(GET_OPERATIONS_DATA, {
    skip,
    fetchPolicy: 'cache-and-network',
  });

export default useGetOperationsData;
