import React, { useState, useCallback, useMemo } from 'react';
import FileDropbox from 'shared/components/FileOperations/FileDropbox';
import SelectedFile from 'shared/components/FileOperations/SelectedFile';
import { useGetCustomFieldDocumentValues } from 'gql/customField/queries';
import { useDeleteCustomFiledFile } from 'gql/customField/mutation';
import { isEmpty, capitalize } from 'lodash';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from 'shared/components/Buttons';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import { ICustomField } from 'shared/types/customField';
interface IProps {
  customField: ICustomField;
  value: any;
  onChange: any;
  entityId: string;
}

interface IFormDataStateShape {
  file: File | null;
}

const Document: React.FC<IProps> = ({ customField, value, onChange, entityId }) => {
  const { businessId } = useSelector((state: RootState) => state.context);
  const { t } = useTranslation();

  const input = {
    businessId: businessId ?? '',
    documentId: value,
  };

  // TODO: use loading
  const { data, loading, refetch } = useGetCustomFieldDocumentValues({
    variables: {
      input: {
        ...input,
      },
    },
    skip: isEmpty(businessId) || isEmpty(value),
  });

  const documentValues = useMemo(() => data?.getCustomFieldDocumentValues ?? [], [data]);

  var hasDocument = documentValues.length > 0;
  var activeDocument = documentValues[0] ?? [];

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const [formData, setFormData] = useState<IFormDataStateShape>({
    file: null,
  });

  const [deleteCustomFieldFile, { loading: deleteCustomFieldFileLoading }] = useDeleteCustomFiledFile({
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
    onCompleted: () => {
      showToast(t('custom-data-fields.remove-document-success-msg'), 'success');
      setIsConfirmModalOpen(false);
      setFormData({ file: null });
      refetch();
    },
  });

  const handleFilesAdd = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event?.target?.files ?? []);
    const file = files[0];

    setFormData((prev) => ({ ...prev, file }));
    value = file;
    onChange(file, customField.id);
  }, []);

  const handleFileDrop = useCallback((files: File[]) => {
    const file = files[0];

    setFormData((prev) => ({ ...prev, file }));
    value = file;
    onChange(file, customField.id);
  }, []);

  const handleFileDelete = useCallback(() => {
    deleteCustomFieldFile({
      variables: {
        input: {
          businessId: businessId ?? '',
          entityId,
          customFieldId: customField.id,
          documentId: activeDocument.id,
          area: activeDocument.customFieldArea,
        },
      },
    });
  }, [deleteCustomFieldFile, businessId, activeDocument, customField, entityId]);

  return (
    <div>
      {hasDocument && activeDocument !== null && activeDocument !== undefined ? (
        <div className="mb-4">
          <label>{customField.label}</label>
          <div className="mr-2">
            <a
              key={activeDocument.id}
              href={hasDocument ? activeDocument?.link : ''}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              {hasDocument ? activeDocument?.name : ''}{' '}
            </a>
            <IconButton
              icon={faTrashAlt}
              iconSize="1x"
              tooltipText={capitalize(t('spelling.delete'))}
              tooltipDirection="bottom"
              onClick={() => setIsConfirmModalOpen(true)}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="mb-4">
            <label>{customField.label}</label>
            <FileDropbox multiple={false} onFilesAdded={handleFilesAdd} onDrop={handleFileDrop} />
          </div>

          {formData.file && (
            <div className="mb-4">
              <SelectedFile
                file={formData.file}
                onDeleteClick={() => setFormData((prev) => ({ ...prev, file: null }))}
                updateContentsCb={() => {}}
              />
            </div>
          )}
        </div>
      )}
      <ConfirmationModal
        title={t('custom-data-fields.delete-document-modal-title')}
        show={isConfirmModalOpen}
        onHide={() => setIsConfirmModalOpen(false)}
        primaryChoice={t('custom-data-fields.delete-document-modal-primary-button')}
        hideOnCallback={false}
        primaryCallback={handleFileDelete}
        primaryButtonProps={{
          variant: 'danger',
          loading: deleteCustomFieldFileLoading,
        }}
      >
        {t('custom-data-fields.delete-document-modal-msg')}
      </ConfirmationModal>
    </div>
  );
};
export default Document;
