/* eslint-disable no-restricted-globals */
import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'shared/components/Layout';
import { RootState } from 'store/reducers';
import DashboardPageWrapper from './components/DashboardPageWrapper';
import NewsCarousel from './components/NewsCarousel';
import useGetMe from '../../shared/hooks/useGetMe';
import PaymentProcessing from 'pages/BillingDashboard/PaymentProcessing';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import FlaggedPayments from 'pages/BillingDashboard/FlaggedPayments';
import AttendanceWidget from './components/AttendanceWidget';
import BirthdayWidget from './components/BirthdayWidget';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import EnrolmentLeadsWidget from './components/EnrolmentLeadsWidget';
import { isRegion } from 'shared/util/region';

// most likely temporary since we'll have subroutes
const Dashboard: React.FC = ({ ...props }) => {
  var { t } = useTranslation(['translation', 'enrollment']);
  useGetMe();
  const isUSRegion = isRegion('US');

  const user = useSelector((state: RootState) => state.user);
  const hasBillingBase = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasBillingPayments = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Read,
  });
  const hasBillingSettings = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.BillingSettings,
    level: RoleLevelType.Read,
  });
  const hasProcessingPaymentsDashboardAccess = hasBillingBase || hasBillingPayments || hasBillingSettings;

  const hasEnrollmentDashboardAccess = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const hasAttendanceAccess = useHasRoleAreaLevel({
    area: AreaType.Attendance,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  return (
    <>
      <div className="app-page-content-header d-flex align-items-center">
        <h3>{t('translation:my-dashboard')}</h3>
      </div>
      <DashboardPageWrapper>
        {user && !user.isInternal ? (
          <>
            {hasAttendanceAccess && (
              <Row align="start">
                <Col lg={12}>
                  <AttendanceWidget />
                </Col>
              </Row>
            )}
            <Row align="start">
              <Col lg={12}>
                <BirthdayWidget />
              </Col>
            </Row>

            {hasEnrollmentDashboardAccess && (
              <Row align="start">
                <Col lg={12}>
                  <EnrolmentLeadsWidget />
                </Col>
              </Row>
            )}

            {hasProcessingPaymentsDashboardAccess ? (
              <Row align="start">
                <Col lg={7}>
                  <PaymentProcessing />
                </Col>
                <Col lg={5}>
                  <FlaggedPayments />
                </Col>
              </Row>
            ) : (
              <>{isUSRegion && <NewsCarousel />}</>
            )}
          </>
        ) : (
          <small>Home page for internal staff, coming soon...</small>
        )}
      </DashboardPageWrapper>
    </>
  );
};

export default Dashboard;
