import _, { orderBy } from 'lodash';
import { calculateDifferenceBetweenTimes } from 'shared/util/timeUtils';

export const createHourDecimalTimeString = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const _minutes = minutes % 60;

  return (hours + _minutes / 60).toFixed(2);
};

export const getActualMinutesWorked = (timeEntries: ITimeEntry[]): number => {
  return timeEntries
    .filter((te) => te.timeOut !== null && te.clockedSelfIn && te.clockedSelfOut) // a time entry is considered an actual time entry if the person clocked themself in AND out (todo: confirm with lindsay)
    .reduce<number>((acc, curr) => {
      const minutesDiff = calculateDifferenceBetweenTimes(curr.timeIn, curr.timeOut!, 'minutes');

      return (acc += Number.parseFloat(createHourDecimalTimeString(minutesDiff)));
    }, 0); // all null time out time entries have been filtered
};

/**
 * @returns {number} the number of minutes worked in a formatted hours:minutes. Ex: 90 minutes worked would be returned as 1.50
 */
export const getAdjustedMinutesWorked = (
  timeEntries: ITimeEntry[],
  trainingTimeEvents: ITrainingHolidayEvent[]
): number => {
  const timeEntriesTimeWorked = timeEntries.reduce<number>((acc, curr) => {
    if (curr.trackedTimeOut !== null && curr.adjustedAt !== null && _.isEmpty(curr.archivedAt)) {
      const minutesWorked = calculateDifferenceBetweenTimes(curr.trackedTimeIn, curr.trackedTimeOut!, 'minutes');
      return (acc += Number.parseFloat(createHourDecimalTimeString(minutesWorked)));
    }
    return acc;
  }, 0); // all null time out time entries have been filtered

  if (trainingTimeEvents.length > 0) {
    const trainingHours = trainingTimeEvents.reduce<number>(
      (acc, curr) => (acc += Number.parseFloat(createHourDecimalTimeString(curr.hours * 60))),
      0
    );

    return timeEntriesTimeWorked + trainingHours;
  }

  return timeEntriesTimeWorked;
};

export const getActualTotalTimeString = (timeEntries: ITimeEntry[]): string => {
  return getActualMinutesWorked(timeEntries).toFixed(2);
};

export const getAdjustedTotalTimeString = (
  timeEntries: ITimeEntry[],
  trainingTimeEvents: ITrainingHolidayEvent[]
): string => {
  return getAdjustedMinutesWorked(timeEntries, trainingTimeEvents).toFixed(2);
};

export const getActualScheduledAndOvertimeMinutesForTimeEntry = (
  timeEntry: ITimeEntry,
  dailyAttendance: IDailyAttendance,
  timeType: 'actual' | 'adjusted'
): { scheduled: number | null; overtime: number | null } => {
  const timeInKey: TimeEntryTimeInKeys = timeType === 'actual' ? 'timeIn' : 'trackedTimeIn';
  const timeOutKey: TimeEntryTimeOutKeys = timeType === 'actual' ? 'timeOut' : 'trackedTimeOut';

  if (!dailyAttendance.shifts.length) return { scheduled: null, overtime: null };
  if (timeEntry[timeOutKey] == null) return { scheduled: null, overtime: null };
  if (timeType === 'actual' && (!timeEntry.clockedSelfIn || !timeEntry.clockedSelfOut))
    return { scheduled: null, overtime: null }; // need a to clock yourself in and out for these to be calculated

  const isUnscheduledTimeEntryPosition = dailyAttendance.shifts.every((s) => s.position?.id !== timeEntry.positionId);
  const paidScheduledMinutesForPosition = dailyAttendance.shifts
    .filter((s) => s.position?.id === timeEntry.positionId)
    .reduce((acc, curr) => {
      const shiftLengthInMinutes = calculateDifferenceBetweenTimes(curr.startTime, curr.endTime, 'minutes');
      const unpaidBreakMinutes = curr.paidBreak ? 0 : curr.breakMinutes;

      return acc + shiftLengthInMinutes - unpaidBreakMinutes;
    }, 0);

  const minutesWorkedForTimeEntry = calculateDifferenceBetweenTimes(
    timeEntry[timeInKey],
    timeEntry[timeOutKey]!,
    'minutes'
  );

  // can't have scheduled minutes if the time entry is for an unscheduled position, _taps head_
  if (isUnscheduledTimeEntryPosition) {
    return {
      scheduled: null,
      overtime: minutesWorkedForTimeEntry,
    };
  }

  const sortedTimeEntries = orderBy(dailyAttendance.timeEntries, (te) => te[timeInKey], 'asc');
  const timeEntryListIndex = sortedTimeEntries.map((te) => te.id).indexOf(timeEntry.id);
  const timeEntriesWorkedBeforeGivenTimeEntry = sortedTimeEntries.slice(0, timeEntryListIndex);
  const minutesWorkedBeforeGivenTimeEntry = timeEntriesWorkedBeforeGivenTimeEntry.reduce(
    (acc, curr) => (acc += calculateDifferenceBetweenTimes(curr[timeInKey], curr[timeOutKey]!, 'minutes')),
    0
  );
  const remainingStandardTime =
    minutesWorkedBeforeGivenTimeEntry >= paidScheduledMinutesForPosition
      ? 0
      : paidScheduledMinutesForPosition - minutesWorkedBeforeGivenTimeEntry;

  if (remainingStandardTime - minutesWorkedForTimeEntry < 0) {
    // went over standard (scheduled) time for position
    const minutesUnder = remainingStandardTime - minutesWorkedForTimeEntry; // this is our OT minutes worked!!! <------

    return {
      scheduled: remainingStandardTime,
      overtime: Math.abs(minutesUnder),
    };
  }

  return {
    scheduled: minutesWorkedForTimeEntry,
    overtime: null,
  };
};
