import React from 'react';
import { Circle } from 'shared/components/Shapes';
import { tagColorHexes } from 'shared/constants/tagColors';
import './_tag.scss';

interface IProps {
  className?: string;
  tag: ITag;
  onClick?: () => void;
  editState?: TagEditState;
}

const Tag: React.FC<IProps> = ({ className, tag, onClick, editState = 'NONE' }) => {
  return (
    <div
      onClick={onClick}
      className={`tag
        ${editState === 'SELECTED' ? 'border border-secondary-hover' : 'border-0'}
        ${editState === 'DESELECTED' ? 'text-dark-gray' : ''}
        ${onClick ? 'cursor-pointer' : ''}
        ${className || ''}
      `}
    >
      <div className="mr-2 d-flex">
        <Circle
          className="my-auto"
          size="xxs"
          background={editState === 'DESELECTED' ? tagColorHexes.INACTIVE : tagColorHexes[tag.category]}
        />
      </div>
      {tag.name}
    </div>
  );
};

export default Tag;
