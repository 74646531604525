import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { put, takeLatest, call, delay, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as types from './types';
import * as actions from './actions';
import { CoreApolloClient } from 'shared/apis/core';

function* loginWithEmailAndPassword(action) {
  const { email, password } = action.payload;
  try {
    const user = yield Auth.signIn(email, password);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      throw new Error('New password required.'); // TODO: handle this.
    }
  } catch (error) {
    // Handle via Hub
  }
}

function* loginWithSocialProvider(action) {
  yield Auth.federatedSignIn({ provider: action.payload.provider });
}

export function* logout() {
  /**
   * we have this second apollo client from when we were using apollo hooks and sagas together
   * which resulted in a second cache being created. this second client should DEFINITELY be removed
   */
  yield CoreApolloClient.stop();
  yield CoreApolloClient.clearStore();
  yield Auth.signOut();
}

function* listenForHubEvents() {
  const authChannel = eventChannel((emitter) => {
    const callback = (event) => {
      emitter(event);
    };
    Hub.listen('auth', callback);

    return () => Hub.remove('auth', callback);
  });

  yield takeEvery(authChannel, function* (event) {
    const { event: type, data } = event.payload;

    const handler = hubEventHandlers[type];
    if (handler) {
      yield* handler(data);
    }
  });
}

const hubEventHandlers = {
  *signIn(data) {
    yield delay(200); // WTF? Why is there a race condition in the property access?
    yield put(
      actions.authenticated({
        token: data.signInUserSession.idToken.jwtToken,
        emailVerified: data.attributes ? data.attributes.email_verified : 'no attributes',
      })
    );
  },

  *signIn_failure(data) {
    yield put(
      actions.notAuthenticated({
        errorCode: data.code,
      })
    );
  },

  *signOut() {
    yield put(actions.notAuthenticated());
  },
};

async function getUserSessionData() {
  try {
    // added `bypassCache: true` because `currentAuthenticatedUser` could return cached information
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    return {
      token: user.signInUserSession.idToken.jwtToken,
      emailVerified: user.attributes ? user.attributes.email_verified : 'no attributes',
    };
  } catch (e) {
    console.log(e);
    await Auth.signOut();
    return null;
  }
}

function* initializeSession() {
  if (window.location.search.match(/^\?code=/)) {
    // SAML authentication is in progress.
    // TODO: Find a better way to do this.
  } else {
    const sessionData = yield call(getUserSessionData);
    if (sessionData) {
      yield put(actions.authenticated(sessionData));
    } else {
      yield put(actions.notAuthenticated());
    }
  }
}

export const loginSagas = [
  listenForHubEvents(),
  initializeSession(),
  takeLatest(types.LOGOUT, logout),
  takeLatest(types.LOGIN_WITH_EMAIL_AND_PASSWORD, loginWithEmailAndPassword),
  takeLatest(types.LOGIN_WITH_SOCIAL_PROVIDER, loginWithSocialProvider),
];
