import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';

interface IUpdatePinForPersonData {
  updatePinForPerson: string | null;
}

interface IUpdatePinForPersonVariables {
  personId: string;
  pin: string | null;
}

export const UPDATE_PIN_FOR_PERSON = gql`
  mutation ($personId: ID!, $pin: String) {
    updatePinForPerson(personId: $personId, pin: $pin)
  }
`;

export const useUpdatePinForPerson = (
  options?: MutationHookOptions<IUpdatePinForPersonData, IUpdatePinForPersonVariables>
) => useMutation<IUpdatePinForPersonData, IUpdatePinForPersonVariables>(UPDATE_PIN_FOR_PERSON, options);
