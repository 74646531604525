import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { accountPaymentMethodSettingsFields } from '../fields';

// New endpoints for updating the primary/autopay payment method for the account

interface IAccountPaymentMethodSetting {
  accountId: string;
  primaryPaymentMethodId?: string;
  autoPayPaymentMethodId?: string;
}

interface ISetPrimaryPaymentMethodVariables {
  input: {
    accountId: string;
    paymentMethodId: string;
  };
}

interface ISetPrimaryPaymentMethodData {
  setPrimaryPaymentMethod: IAccountPaymentMethodSetting;
}

interface ISetAutoPayPaymentMethodVariables {
  input: {
    accountId: string;
    currentPaymentMethodId?: string;
    newAutopayPaymentId?: string;
    termsAndConditionsConfirmed?: boolean;
  };
}

interface ISetAutoPayPaymentMethodData {
  setPaymentMethodIsAutoPay: IAccountPaymentMethodSetting;
}

export const SET_ACCOUNT_DEFAULT_PAYMENT_METHOD = (fields: string = accountPaymentMethodSettingsFields) => gql`
  mutation($input: AccountPaymentMethodInput!) {
    setPrimaryPaymentMethod(input: $input) {
      ${fields}
    }
  }
`;

export const SET_ACCOUNT_AUTO_PAY_PAYMENT_METHOD = (fields: string = accountPaymentMethodSettingsFields) => gql`
  mutation($input: AccountAutopayPaymentMethodInput!) {
    setPaymentMethodIsAutoPay(input: $input) {
      ${fields}
    }
  }
`;

export const useSetAccountAutopayPaymentMethod = (
  options?: MutationHookOptions<ISetAutoPayPaymentMethodData, ISetAutoPayPaymentMethodVariables>,
  fields?: string
) =>
  useMutation<ISetAutoPayPaymentMethodData, ISetAutoPayPaymentMethodVariables>(
    SET_ACCOUNT_AUTO_PAY_PAYMENT_METHOD(fields),
    {
      ...options,
    }
  );

export const useSetAccountPrimaryPaymentMethod = (
  options?: MutationHookOptions<ISetPrimaryPaymentMethodData, ISetPrimaryPaymentMethodVariables>,
  fields?: string
) =>
  useMutation<ISetPrimaryPaymentMethodData, ISetPrimaryPaymentMethodVariables>(
    SET_ACCOUNT_DEFAULT_PAYMENT_METHOD(fields),
    {
      ...options,
    }
  );
