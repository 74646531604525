import React, { useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import useUniqueId from 'shared/hooks/useUniqueId';
import useGetMe from 'shared/hooks/useGetMe';
import ProfileTab from './Tabs/Profile';
import EmploymentTab from './Tabs/Employment';
import useUpdateAvatar from 'shared/hooks/useUpdateAvatar';
import { showToast } from 'shared/components/Toast';
import './myProfile.scss';
import { getFullName } from 'shared/util/string';
import NotificationsTab from './Tabs/NotificationsTab';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { RouteComponentProps, useHistory } from 'react-router-dom';

type UserProfileTabs = 'profile' | 'employment' | 'notifications';

interface IRouteParams {
  activeTab: UserProfileTabs;
}
interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const MyProfile: React.FC<IProps> = ({ ...props }) => {
  const { activeTab } = props.match.params;
  const [activeTabKey, setActiveTabKey] = React.useState<UserProfileTabs>(activeTab ?? 'profile');
  const history = useHistory();
  const tabId: string = useUniqueId('my-profile-tab-group');
  const { data, loading: getMeLoading } = useGetMe();
  const [updateAvatarFn, { loading: updateAvatarLoading }] = useUpdateAvatar('staff');
  const person: IStaff | IPerson | null = data?.getMe ?? null;

  const updateAvatar = useCallback(
    (file: File) => {
      if (file && person) {
        const personId: string = person.id;

        updateAvatarFn({ id: personId, avatar: file })
          .then(() => {
            showToast('Profile photo updated successfully.', 'success');
          })
          .catch(() => {
            showToast('There was an error updating your profile photo. Please try again later.', 'error');
          });
      }
    },
    [person, updateAvatarFn]
  );

  return (
    <ProfilePageWrapper
      title={getFullName(person)}
      subtitle=""
      loading={getMeLoading || updateAvatarLoading}
      avatarUrl={person?.avatar?.url ?? ''}
      onAvatarClick={updateAvatar}
      applyPadding={false}
    >
      <Tabs
        activeKey={activeTabKey}
        onSelect={(tab) => {
          setActiveTabKey(tab as UserProfileTabs);
          history.push(`/user/profile/${tab}`);
        }}
        id={tabId}
        style={{
          paddingLeft: '16px',
          position: 'sticky',
          top: '119px',
          zIndex: 900,
        }}
        className="mb-0"
      >
        <Tab eventKey="profile" title="Profile">
          <PageWrapperBody>
            <ProfileTab data={person} loading={getMeLoading} />
          </PageWrapperBody>
        </Tab>
        <Tab eventKey="employment" title="Employment">
          <PageWrapperBody>
            <EmploymentTab data={person} loading={getMeLoading} />
          </PageWrapperBody>
        </Tab>
        <Tab eventKey="notification" title="Notifications">
          <PageWrapperBody>
            <NotificationsTab data={person} loading={getMeLoading} />
          </PageWrapperBody>
        </Tab>
      </Tabs>
    </ProfilePageWrapper>
  );
};

export default MyProfile;
