import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import BusRosterInfromationCard from 'shared/components/BusRoster/BusRosterInformationCard';
import { isEmpty, upperCase } from 'lodash';
import useDatatableState from 'shared/hooks/useDatatableState';
import { useGetBusRosterAvailableChildren } from 'gql/busRoster/queries';
import Button from 'shared/components/Buttons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isBlank } from 'shared/util/string';
import { omitTypename } from 'shared/util/object';
import { showToast } from 'shared/components/Toast';
import { useCreateBusRosters } from 'gql/busRoster/mutations';
import cast from 'shared/util/cast';
import BusRosterChildrenTable from 'shared/components/BusRoster/BusRosterChildrenTable';
import CreateBusRosterDestinationModal from 'shared/components/BusRoster/CreateBusRosterDestinationModal';
import { v4 as uuidv4 } from 'uuid';
import BusRosterDestinationCards from 'shared/components/BusRoster/BusRosterDestinationCards';
import { Badge, Tab, Tabs } from 'react-bootstrap';
import useUniqueId from 'shared/hooks/useUniqueId';
import { useGetClassesForCenter } from 'gql/center/queries';

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const CreateBusRoster: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const currentUser = useSelector((state: RootState) => state.user);
  const [showCreateBusRosterDestinationModal, setShowCreateBusRosterDestinationModal] =
    useState<ICreateBusRosterDestinationShape>({ isOpen: false });
  const [destinationData, setDestinationData] = useState<IDestinationStateShape[]>([]);
  const tabId: string = useUniqueId('bus-roster-create-tab-group');

  const [formData, setFormData] = useState<IBusRosterInformationForm>({
    id: null,
    businessId: currentUser?.entityId ?? '',
    centerId: '',
    destinations: [],
    effectiveDate: '',
    endDate: '',
    name: '',
    type: '',
  });

  const incompleteGraphqlRequestVariables: boolean = !formData.centerId || !formData.effectiveDate || !formData.endDate;
  const incompleteClassesRequestVariables: boolean = !formData.centerId;

  useEffect(() => {
    // If the center, effective date, and end date fields all have values, and either of them have a changed value, refetch the available children data
    if (!isBlank(formData?.centerId) && !isBlank(formData?.effectiveDate) && !isBlank(formData?.endDate)) {
      refetchAvailableChildrenData();
    }
  }, [formData.centerId, formData.effectiveDate, formData.endDate]);

  useEffect(() => {
    if (!isBlank(formData?.type)) {
      setUnassignedChildrenData((prev) =>
        prev.map((child) => {
          return {
            ...child,
            input: {
              ...child.input,
              type: formData.type,
            },
          };
        })
      );

      setAssignedChildrenData((prev) =>
        prev.map((child) => {
          return {
            ...child,
            input: {
              ...child.input,
              type: formData.type,
            },
          };
        })
      );
    }
  }, [formData.type]);

  useEffect(() => {
    if (!isBlank(formData?.centerId)) {
      refetchClassesData();
    }
  }, [formData.centerId]);

  const [classData, setClassData] = useState<IClass[]>([]);
  const { loading: classesLoading, refetch: refetchClassesFn } = useGetClassesForCenter({
    variables: { centerId: formData?.centerId },
    fetchPolicy: 'network-only',
    skip: incompleteClassesRequestVariables,
    onCompleted: (result) => {
      setClassData(result.getClassesForCenter.filter((c) => !c.archivedAt) ?? []);
    },
    onError: (err) => {
      showToast(err, 'error');
    },
  });

  const refetchClassesData = () => {
    refetchClassesFn();
  };

  const validBusRosterForm = useCallback(() => {
    const validStartDate: boolean = !!moment(formData.effectiveDate).startOf('day').toISOString();
    const validTimeframe: boolean = formData.endDate
      ? moment(formData.endDate).isSameOrAfter(moment(formData.effectiveDate))
      : true;

    return (
      !isBlank(formData.name) &&
      !isBlank(formData.businessId) &&
      !isBlank(formData.centerId) &&
      !isEmpty(formData.destinations) &&
      !isBlank(formData.effectiveDate) &&
      !isBlank(formData.endDate) &&
      !isBlank(formData.type) &&
      validStartDate &&
      validTimeframe
    );
  }, [formData]);

  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'childName', direction: 'ASCENDING' }];
  const [unassignedTableState, unassignedTableFunctions] = useDatatableState('busRosterChildren', initalTableSort);
  const [assignedTableState, assignedTableFunctions] = useDatatableState('busRosterChildren', initalTableSort);

  const [unassignedChildrenData, setUnassignedChildrenData] = useState<IBusRosterAccountChildGridShape[]>([]);
  const [assignedChildrenData, setAssignedChildrenData] = useState<IBusRosterAccountChildGridShape[]>([]);
  const {
    loading: availableChildrenLoading,
    networkStatus,
    refetch: refetchAvailableChildrenFn,
  } = useGetBusRosterAvailableChildren({
    variables: {
      input: {
        centerId: formData.centerId,
        effectiveDate: moment(formData.effectiveDate).format('YYYY-MM-DD'),
        endDate: moment(formData.endDate).format('YYYY-MM-DD'),
      },
    },
    skip: incompleteGraphqlRequestVariables,
    onCompleted: (result) => {
      const formattedChildrenData: IBusRosterAccountChildGridShape[] = result.getAvailableChildrenForBusRoster.map(
        (child) => {
          let scheduleSlots: ICreateBusRosterAccountChildScheduleSlotInput[] = [];
          child.expectedScheduleSlots.forEach((s: IBusRosterAvailableAccountChildScheduleSlot) => {
            s.days.forEach((d: DayOfWeekType) => {
              scheduleSlots.push({
                weekType: s.weekType,
                day: d,
              });
            });
          });
          return cast<IBusRosterAccountChildGridShape>({
            id: child.id,
            data: child,
            input: {
              accountChildId: child.id,
              type: null,
              destination: '',
              scheduleSlots: scheduleSlots,
            },
          });
        }
      );

      setUnassignedChildrenData(
        formattedChildrenData.filter((child) => {
          return !assignedChildrenData.some((x) => x.id === child.id);
        })
      );
    },
    onError: (err) => {
      showToast(err, 'error');
    },
  });

  const refetchAvailableChildrenData = () => {
    refetchAvailableChildrenFn();
  };

  const [createBusRosterFn, { loading: createBusRosterLoading }] = useCreateBusRosters();
  const createBusRoster = useCallback(
    (formData: IBusRosterInformationForm) => {
      let childrenInput = assignedChildrenData.map((child: IBusRosterAccountChildGridShape) => {
        return {
          ...child.input,
          type: upperCase(child.input.type),
          destination: destinationData.find((d) => d.value === child.input.destination)?.label ?? '',
          scheduleSlots: child.input.scheduleSlots.filter(
            (s) =>
              s.day === 'MONDAY' ||
              s.day === 'TUESDAY' ||
              s.day === 'WEDNESDAY' ||
              s.day === 'THURSDAY' ||
              s.day === 'FRIDAY'
          ),
        };
      });

      const input: ICreateBusRosterInput = {
        businessId: formData.businessId,
        centerId: formData.centerId,
        destinations: formData.destinations!.map((d) => {
          return { name: destinationData!.find((o) => o.value === d)?.label! };
        }),
        effectiveDate: moment(formData.effectiveDate).format('YYYY-MM-DD'),
        endDate: moment(formData.endDate).format('YYYY-MM-DD'),
        name: formData.name,
        type: formData.type,
        children: childrenInput,
      };

      createBusRosterFn({
        variables: {
          input: cast<ICreateBusRosterInput>(omitTypename(input, true)),
        },
      })
        .then((data: any) => {
          showToast(t('bus-roster.create-bus-roster.success-message'), 'success');
          history.replace('/bus-roster');
        })
        .catch((error: any) => {
          showToast(
            `${error.graphQLErrors
              .map((err: any) => {
                return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
              })
              .join(', ')}`,
            'error'
          );
        });
    },
    [formData, assignedChildrenData]
  );

  return (
    <PageWrapper
      pageTitle={t('bus-roster.create-bus-roster.page-title')}
      applyPadding={true}
      buttonComponent={
        <Button
          disabled={!validBusRosterForm()}
          onClick={() => createBusRoster(formData)}
          loading={createBusRosterLoading}
        >
          Save
        </Button>
      }
      secondaryButtonComponent={
        <Button variant="light" className="mr-4" onClick={() => history.goBack()}>
          Cancel
        </Button>
      }
    >
      <BusRosterInfromationCard
        busRosterFromData={formData}
        assignedChildren={assignedChildrenData}
        destinationOptions={destinationData}
        loading={false}
        onChange={setFormData}
        onAddDestination={() => setShowCreateBusRosterDestinationModal({ isOpen: true })}
        onRemoveDestination={(destinationId: string) => {
          var childrenToUnassign = assignedChildrenData.filter((child) => child.input.destination === destinationId);
          setUnassignedChildrenData((prev) =>
            prev.concat(
              assignedChildrenData
                .filter((child) => childrenToUnassign.some((x) => x.id === child.id))
                .map((child) => {
                  return {
                    ...child,
                    input: {
                      ...child.input,
                      destination: destinationId!,
                    },
                  };
                })
            )
          );

          setAssignedChildrenData(
            assignedChildrenData.filter((child) => !childrenToUnassign.some((x) => x.id === child.id))
          );
        }}
      ></BusRosterInfromationCard>
      <Tabs defaultActiveKey="unassigned" id={tabId}>
        <Tab eventKey="unassigned" title="Unassigned">
          <BusRosterChildrenTable
            title="Unassigned Children"
            tableState={unassignedTableState}
            tableFunctions={unassignedTableFunctions}
            data={unassignedChildrenData}
            busRunType={formData.type}
            destinations={destinationData}
            selectedDestinations={formData.destinations}
            classes={classData}
            loading={availableChildrenLoading}
            assignedMode={false}
            onChange={setUnassignedChildrenData}
            onAssignmentChange={setAssignedChildrenData}
          />
        </Tab>
        <Tab
          eventKey="assigned"
          title={
            <>
              Assigned
              <Badge pill variant="primary" className="ml-2">
                {assignedChildrenData?.length ?? 0}
              </Badge>
            </>
          }
        >
          {formData?.destinations?.length > 0 && (
            <BusRosterDestinationCards
              destinationOptions={destinationData}
              selectedDestinations={formData.destinations}
              childrenData={assignedChildrenData}
            ></BusRosterDestinationCards>
          )}
          <BusRosterChildrenTable
            title="Assigned Children"
            tableState={assignedTableState}
            tableFunctions={assignedTableFunctions}
            data={assignedChildrenData}
            busRunType={formData.type}
            destinations={destinationData}
            selectedDestinations={formData.destinations}
            classes={classData}
            loading={availableChildrenLoading}
            assignedMode={true}
            onChange={setAssignedChildrenData}
            onAssignmentChange={setUnassignedChildrenData}
          />
        </Tab>
      </Tabs>
      <CreateBusRosterDestinationModal
        isOpen={showCreateBusRosterDestinationModal.isOpen}
        onClose={() => setShowCreateBusRosterDestinationModal({ isOpen: false })}
        onConfirm={(destination) => {
          const destinationValue = uuidv4();
          setDestinationData([...destinationData, { value: destinationValue, label: destination }].sort());
          setFormData((prev) => ({
            ...prev,
            destinations: prev.destinations ? prev.destinations.concat(destinationValue).sort() : [destinationValue],
          }));
        }}
      ></CreateBusRosterDestinationModal>
    </PageWrapper>
  );
};

export default CreateBusRoster;
