import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import { CreateButton, CirclePlusButton } from 'shared/components/Buttons';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useDatatableState from 'shared/hooks/useDatatableState';
import { DIRECTIONS } from 'shared/constants/elastic';
import { debounce } from 'lodash';
import ServiceGroupsTable from '../ServiceFeeGroups/components/ServiceGroupsTable';
import { useGetPaginatedServiceFeeGroups } from 'gql/serviceFee/queries';
import DeleteServiceFeeGroupModal from './components/DeleteServiceFeeGroupModal';
import { useDeleteServiceFeeGroup } from 'gql/serviceFee/mutations';
import { showToast } from 'shared/components/Toast';

interface IDeleteModalState {
  open: boolean;
  serviceFeeGroup: IServiceFeeGroup | null;
}

interface IProps {}

const ServiceFeeGroups: React.FC<IProps> = () => {
  const history = useHistory();
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const [tableData, setTableData] = useState<IServiceFeeGroup[]>([]);
  const initialTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: DIRECTIONS.ASCENDING }];
  const [tableState, tableFunctions] = useDatatableState('serviceFeeGroups', initialTableSort);
  const [sort, setSort] = useState<IServiceFeeGroupSort[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const [showDeleteModal, setShowDeleteModal] = useState<IDeleteModalState>({ open: false, serviceFeeGroup: null });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSort([{ field: field, direction: direction }]);
  };

  const handleSearchDebounced = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      tableFunctions.changePage(1, tableState.pageSize);
    }, 250),
    []
  );

  const {
    data: feeGroupsData,
    loading: feeGroupsLoading,
    refetch: refetchFeeGroupsData,
  } = useGetPaginatedServiceFeeGroups({
    variables: {
      input: {
        centerIds: tableState.selectedFilters.center?.map((c) => c.value) ?? [],
        feeTypes: (tableState.selectedFilters.feeType?.map((c) => c.value) as ServiceFeeType[]) ?? [],
        searchKey: searchTerm,
        sortBy: sort[0].field,
        sortDirection: sort[0].direction === 'ASCENDING' ? 'asc' : 'desc',
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
      },
    },
    onCompleted: (result) => {
      setTableData(result.getPaginatedServiceFeeGroups.data);
    },
  });

  const [deleteServiceFeeGroupFn] = useDeleteServiceFeeGroup({
    onCompleted: () => {
      showToast('Successfully deleted service fee group.', 'success');

      setShowDeleteModal({ serviceFeeGroup: null, open: false });

      refetchFeeGroupsData();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  return (
    <PageWrapper
      pageTitle={`${fieldLabels.serviceFee}s`}
      mobileButtonComponent={
        <CirclePlusButton
          variant="primary"
          className="mt-4 mb-4"
          onClick={() => history.push('/settings/service-fees/new')}
        />
      }
      buttonComponent={
        <CreateButton
          className="my-2"
          id="create-center-btn"
          onClick={() => history.push('/settings/service-fees/new')}
        >
          Add {fieldLabels.serviceFeeGroup}
        </CreateButton>
      }
      applyPadding={true}
    >
      <ServiceGroupsTable
        data={tableData}
        totalResults={feeGroupsData?.getPaginatedServiceFeeGroups.totalRecords || 0}
        tableState={tableState}
        tableFunctions={tableFunctions}
        loading={feeGroupsLoading}
        onSort={handleSort}
        onSearch={handleSearchDebounced}
        navigateToFeeGroup={(feeGroup: IServiceFeeGroup) => history.push(`/settings/service-fees/${feeGroup.id}`)}
        deleteFeeGroup={(feeGroup: IServiceFeeGroup) => setShowDeleteModal({ serviceFeeGroup: feeGroup, open: true })}
      />
      {showDeleteModal.serviceFeeGroup && (
        <DeleteServiceFeeGroupModal
          isOpen={showDeleteModal.open}
          serviceFeeGroup={showDeleteModal.serviceFeeGroup}
          onClose={() => {
            setShowDeleteModal({ serviceFeeGroup: null, open: false });
          }}
          onConfirm={(id: string) =>
            deleteServiceFeeGroupFn({
              variables: {
                id: id,
              },
            })
          }
        />
      )}
    </PageWrapper>
  );
};

export default ServiceFeeGroups;
