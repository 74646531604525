import React, { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { Row, Col } from 'shared/components/Layout';
import { DateRangeInput } from 'shared/components/DateInput';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { NumberInput } from 'shared/components/TextInput';
import moment from 'moment';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import './DownloadBusRosterFormModal.scss';

interface IProps {
  isOpen: boolean;
  busRoster: IBusRoster | null;
  formData: IBusRosterPdfDataShape;
  loading: boolean;
  handleDownload: () => void;
  handleClose: () => void;
  onUpdate: React.Dispatch<React.SetStateAction<IBusRosterPdfDataShape>>;
}

const DownloadBusRosterForm: React.FC<IProps> = ({
  isOpen,
  busRoster,
  formData,
  loading,
  handleDownload,
  handleClose,
  onUpdate,
  ...props
}) => {
  const { t } = useTranslation();
  const validDownloadForm = useCallback(() => {
    const validStartDate: boolean = !!moment(formData.startDate).startOf('day').toISOString();
    const validTimeframe: boolean = formData.endDate
      ? moment(formData.endDate).isSameOrAfter(moment(formData.startDate))
      : true;

    return !isUndefined(formData.additionalChildren) && validStartDate && validTimeframe;
  }, [formData]);

  return (
    <SideModalDrawer
      title={t('bus-roster.download-bus-roster-modal-title')}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Download"
      primaryCallback={() => handleDownload()}
      secondaryCallback={handleClose}
      primaryButtonProps={{ disabled: !validDownloadForm(), loading: loading }}
      closeOnSecondaryCallback={true}
      closeOnPrimaryCallback={false}
    >
      <Form>
        <Row>
          <Col>
            <DateRangeInput
              required
              label={t('bus-roster.download-bus-roster-modal-date-label')}
              startDate={formData.startDate}
              endDate={formData.endDate}
              className="kt-date-input-max-width"
              onChange={(dates) =>
                onUpdate((prev) => ({ ...prev, startDate: dates.startDate, endDate: dates.endDate }))
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <NumberInput
              required
              label={t('bus-roster.download-bus-roster-modal-rows-label')}
              value={formData.additionalChildren}
              step="1"
              min="0"
              appendText="rows"
              className="kt-number-input-max-width"
              onChange={(value) => onUpdate((prev) => ({ ...prev, additionalChildren: value }))}
            />
          </Col>
        </Row>
      </Form>
    </SideModalDrawer>
  );
};

export default DownloadBusRosterForm;
