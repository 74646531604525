import { useRemoveSession } from 'gql/session/mutations';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import { LATE_WITHDRAWAL_DAYS } from 'shared/constants/enums/subsidyTrackingEnums';
import { isRegion } from 'shared/util/region';
import { removeSession } from '../duck/actions';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  timezone: string;
  session: ISession;
}

const RemoveSessionModal: React.FC<IProps> = ({ isOpen, onSuccess, onClose, session, timezone }) => {
  const { t } = useTranslation();
  const isAuRegion = isRegion('AU');
  const [reasonForLateChange, setReason] = useState<string | undefined>();
  const dispatch = useDispatch();
  const isLateSubmission = useMemo(
    () => moment().diff(moment(session.date).startOf('week').tz(timezone), 'days') > LATE_WITHDRAWAL_DAYS,
    [session.date, timezone]
  );
  const shouldRequireReason = isAuRegion && isLateSubmission;

  const [removeSessionFn, { loading }] = useRemoveSession({
    onCompleted: (data) => {
      dispatch(removeSession(data.voidSession));
      showToast('Session removed.', 'success');
      onSuccess();
      onClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleRemoveSession = useCallback(() => {
    session.id &&
      removeSessionFn({
        variables: {
          input: {
            sessionId: session.id.includes('contract') ? undefined : session.id, // if the session id is from contract, dont send,
            contractId: session.contractId,
            date: session.date,
            reasonForLateChange,
          },
        },
      });
  }, [session, reasonForLateChange]);

  return (
    <ConfirmationModal
      title="Are you sure?"
      show={isOpen}
      onHide={onClose}
      primaryChoice={'Remove'}
      hideOnCallback={false}
      primaryCallback={handleRemoveSession}
      primaryButtonProps={{
        variant: 'danger',
        loading: loading,
        disabled: shouldRequireReason && isEmpty(reasonForLateChange?.trim()),
      }}
    >
      Once removed, the data related to this session will no longer exist, which may impact fees and other historical
      data.
      {shouldRequireReason && (
        <Row>
          <Col lg={8}>
            <TextInput
              label={t('attendance.void-session.reason-for-late-withdrawal')}
              value={reasonForLateChange}
              onChange={(value) => setReason(value)}
              required
              className="mt-5"
            />
          </Col>
        </Row>
      )}
    </ConfirmationModal>
  );
};

export default RemoveSessionModal;
