import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import signUpSvg from '../../../Login/images/Sign-Up.svg';

const TransitionStatementsCard: React.FC<{}> = () => {
  const showCartoon = window.matchMedia('(min-width: 1000px)').matches;

  return (
    <Card bodyClassName="p-8">
      <Row className="flex-nowrap">
        {showCartoon && (
          <img
            src={signUpSvg}
            alt="cartoon helper man"
            className="align-self-end mt-n2 mb-n8 mr-4 d-none d-md-block"
            style={{ width: '235px' }}
          />
        )}
        <Row>
          <Col className="pl-8">
            <h3>Transition Statements</h3>
            <br />

            <p>
              Each year, Period 4 Acquittal submission requires a total of transition statements sent for each child
              through the QCAA portal.
            </p>

            <p>
              With consent from parents, how many transition statements were sent to Catholic, Independent or State
              schools for each child via the QCAA portal? If no transition statements were sent for a child to a
              specific school type, enter 0.
            </p>
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

export default TransitionStatementsCard;
