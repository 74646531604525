import { CustomFieldType, Gender } from 'generated/graphql';
import i18n from 'i18next';
import { SYSTEM_REQUIRED_FIELDS } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/hooks/useFieldMetadata';
import { gradeStatusOptions } from 'pages/Families/subroutes/Children/Child/components/Profile/PersonalInformationForm';
import { ContactPermissions, Field, Relationship, SectionType, SystemField } from 'shared/types/enrollment-form';
import { isRegion } from 'shared/util/region';

const isAuRegion = isRegion('AU');

const PrimaryContactRelationship: Relationship = {
  includes: ['liveWith'],
};

const AuOnlyPermissions: ContactPermissions[] = [
  'giveMedicalTreatment',
  'giveAmbulanceTransportation',
  'giveConsentRegularOutings',
];

export const SecondaryContactRelationship: Relationship = {
  includes: ['emergencyContact', 'permissionToPickUp', ...(isAuRegion ? AuOnlyPermissions : [])],
};

export class SystemFieldBuilder {
  static toField(sysField: SystemField): Field {
    let field: Field = {
      type: sysField.type,
      name: sysField.id,
      label: sysField.label,
      isMandatory: SYSTEM_REQUIRED_FIELDS.indexOf(sysField.id) !== -1,
      displayDateFormat: sysField.displayDateFormat ?? undefined,
    };

    if (sysField.type === 'CompositionField' && sysField.id === 'relationships') {
      field = { ...field, ...JSON.parse(sysField.attributesAsString!) };
    }

    if (sysField.type === 'PhoneNumber' && sysField.attributesAsString) {
      field = { ...field, ...JSON.parse(sysField.attributesAsString!) };
    }
    return field;
  }

  static build(fieldName: string, sectionType?: SectionType): SystemField | undefined {
    switch (fieldName) {
      case 'firstName': {
        return {
          label: 'First Name',
          type: CustomFieldType.Text,
          id: 'firstName',
        };
      }
      case 'gender': {
        const options = Object.keys(Gender).map((key) => ({ value: key, label: key }));
        return {
          label: 'Gender',
          type: CustomFieldType.Combo,
          id: 'gender',
          attributesAsString: JSON.stringify(options),
        };
      }
      case 'indigenousStatus': {
        const indigenousStatusOptions = [
          { value: 'NotIndicated', label: 'Not Indicated' },
          { value: 'Aboriginal', label: 'Aboriginal' },
          { value: 'TorresStraitIslander', label: 'Torres Strait Islander' },
          { value: 'BothAboriginalTorresStraitIslander', label: 'Aboriginal and Torres Strait Islander' },
          { value: 'NeitherAboriginalTorresStraitIslander', label: 'Neither Aboriginal nor Torres Strait Islander' },
        ];

        return {
          label: 'Indigenous status',
          type: CustomFieldType.Combo,
          id: 'indigenousStatus',
          attributesAsString: JSON.stringify(indigenousStatusOptions),
        };
      }
      case 'address': {
        return {
          label: 'Address',
          type: CustomFieldType.Address,
          id: 'address',
        };
      }
      case 'grade': {
        return {
          label: 'Grade',
          type: CustomFieldType.Combo,
          id: 'grade',
          attributesAsString: JSON.stringify(gradeStatusOptions),
        };
      }

      case 'middleName': {
        return {
          label: 'Middle Name',
          type: CustomFieldType.Text,
          id: 'middleName',
        };
      }

      case 'lastName': {
        return {
          label: 'Last Name',
          type: CustomFieldType.Text,
          id: 'lastName',
        };
      }

      case 'dateOfBirth': {
        return {
          type: CustomFieldType.Date,
          label: 'Date of Birth',
          id: 'dateOfBirth',
        };
      }

      case 'hasMedicalCondition': {
        return {
          type: CustomFieldType.Radio,
          label: 'Does the child have any medical conditions?',
          id: 'hasMedicalCondition',
          attributesAsString: '[{"label":"Yes","value":true},{"label":"No","value":false}]',
        };
      }

      case 'hasImmunisation': {
        return {
          type: CustomFieldType.Radio,
          label: `Is your child ${i18n.t('spelling:immunized')}?`,
          id: 'hasImmunisation',
          attributesAsString: '[{"label":"Yes","value":true},{"label":"No","value":false}]',
        };
      }

      case 'relationships': {
        return {
          type: 'CompositionField',
          attributesAsString:
            sectionType === 'secondary-contact'
              ? JSON.stringify(SecondaryContactRelationship)
              : JSON.stringify(PrimaryContactRelationship),
          label: sectionType === 'secondary-contact' ? 'Relationships and Permissions' : 'Relationships',
          id: 'relationships',
        };
      }

      case 'crn': {
        return {
          type: 'Crn',
          label: 'CRN',
          id: 'crn',
        };
      }

      case 'email': {
        return {
          type: 'Email',
          label: 'Email',
          id: 'email',
        };
      }

      case 'phoneNumbers': {
        return {
          type: 'PhoneNumber',
          label: 'Phone Numbers',
          id: 'phoneNumbers',
          attributesAsString: sectionType === 'contact' ? JSON.stringify({ isMulti: true }) : undefined,
        };
      }
      case 'billingCycle': {
        return {
          type: 'BillingCycle',
          label: 'Select a billing cycle',
          id: 'billingCycle',
        };
      }
      case 'paymentMethod': {
        return {
          type: 'PaymentMethod',
          label: 'Select a payment type',
          id: 'paymentMethod',
        };
      }

      default:
        return undefined;
    }
  }
}
