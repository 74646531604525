import React from 'react';
interface IProps {
  data: IISInnovativeCasesForCenters;
}

const ISInnovativeSolutionsDetailsTable: React.FC<IProps> = ({ data }) => {
  const { executed, serviceType, acquittalStatus, professionalFirstName, professionalLastName } = data;
  return (
    <div>
      <div>
        <table className="table-simple mr-12">
          <colgroup>
            <col span={1} style={{ width: '70%' }} />
            <col span={1} style={{ width: '30%' }} />
          </colgroup>
          <tbody>
            <tr>
              <td>IS Case Service Type</td>
              <td>{serviceType}</td>
            </tr>
            <tr>
              <td>Executed</td>
              <td>{executed ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>Acquittal Status</td>
              <td>{acquittalStatus}</td>
            </tr>
            <tr>
              <td>Inclusion Professional First Name</td>
              <td>{professionalFirstName}</td>
            </tr>
            <tr>
              <td>Inclusion Professional Last Name</td>
              <td>{professionalLastName}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ISInnovativeSolutionsDetailsTable;
