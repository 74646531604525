import React from 'react';
import { useSelector } from 'react-redux';
import Button, { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { RootState } from 'store/reducers';

interface IProps {
  activeTab: string;
  onShowCreateBatchModal: () => void;
  onShowManualPaymentModal: () => void;
  onShowPaymentReportModal: () => void;
}

const readAction: IAreaPermissionLevel = {
  area: AreaType.Billing,
  permission: PermissionType.Payments,
  level: RoleLevelType.Create,
};

const createAction: IAreaPermissionLevel = {
  area: AreaType.Billing,
  permission: PermissionType.Payments,
  level: RoleLevelType.Create,
};

const ButtonComponents: React.FC<IProps> = ({
  activeTab,
  onShowCreateBatchModal,
  onShowManualPaymentModal,
  onShowPaymentReportModal,
}) => {
  const centerIdToBatch = useSelector((state: RootState) => state.billing.paymentBatches.centerIdToBatch);

  const paymentsSelectedForBatching = useSelector(
    (state: RootState) => state.billing.paymentBatches.paymentTransactionsSelectedForBatching
  );

  switch (activeTab) {
    case 'payments':
      return (
        <div>
          <HasRoleAreaLevel action={readAction}>
            <Button className="my-2 mr-2" onClick={() => onShowPaymentReportModal()} variant="secondary">
              Run Report
            </Button>
          </HasRoleAreaLevel>
          <HasRoleAreaLevel action={createAction}>
            <CreateButton className="my-2" onClick={() => onShowManualPaymentModal()}>
              Add Payment
            </CreateButton>
          </HasRoleAreaLevel>
        </div>
      );

    case 'unbatched':
      const disabled = !centerIdToBatch || !paymentsSelectedForBatching.length;

      return (
        <div className="d-flex align-items-center my-2">
          <h6 className="mr-4 mb-0">{paymentsSelectedForBatching.length} payments selected</h6>
          <CreateButton disabled={disabled} onClick={() => onShowCreateBatchModal()}>
            Create Batch
          </CreateButton>
        </div>
      );

    default:
      return null;
  }
};

export default ButtonComponents;
