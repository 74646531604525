import moment from 'moment';
import React from 'react';
import DataTable from 'shared/components/DataTable';
import PaymentMethod from 'shared/components/PaymentMethods/PaymentMethod';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import './paymentMethodTable.scss';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import ActionDropdown from 'shared/components/ActionDropdown';
import colors from '_colors.module.scss';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'shared/util/string';

interface IProps {
  paymentMethods: IPaymentMethod[];
  onEditPaymentMethod: (paymentMethod: IPaymentMethod) => void;
  onDeletePaymentMethod: (paymentMethod: IPaymentMethod) => void;
}
const PaymentMethodTable: React.FC<IProps> = ({ paymentMethods, onEditPaymentMethod, onDeletePaymentMethod }) => {
  const { t } = useTranslation(['translation', 'billing']);
  const canEditPaymentMethodsPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.PaymentMethods,
    level: RoleLevelType.Edit,
  });
  const canDeletePaymentMethodsPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.PaymentMethods,
    level: RoleLevelType.Delete,
  });
  const canEditBillingPermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Billing,
    level: RoleLevelType.Edit,
  });
  const canDeleteBilingPermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Billing,
    level: RoleLevelType.Delete,
  });

  const hasActions =
    canEditBillingPermission ||
    canDeleteBilingPermission ||
    canEditPaymentMethodsPermissions ||
    canDeletePaymentMethodsPermissions;

  const actions = (paymentMethod: IPaymentMethod) => {
    const canEdit = (canEditBillingPermission || canEditPaymentMethodsPermissions) && paymentMethod.isCreditCard;
    const canDelete = canDeleteBilingPermission || canDeletePaymentMethodsPermissions;
    return [
      ...(canEdit
        ? [
            {
              label: capitalize(t('translation:spelling.edit')),
              onClick: () => onEditPaymentMethod(paymentMethod),
              icon: faPencil,
            },
          ]
        : []),
      ...(canDelete
        ? [
            {
              label: t('billing:payment-methods.remove-payment-method'),
              onClick: () => onDeletePaymentMethod(paymentMethod),
              icon: faTrashAlt,
            },
          ]
        : []),
    ];
  };

  return (
    <DataTable
      data={paymentMethods}
      showSelect={false}
      showPagination={false}
      className="payment-method-table"
      noPadding
      columns={[
        {
          text: t('billing:payment-methods.table.account-number'),
          dataField: 'number',
          formatter: (cell: string, paymentMethod: IPaymentMethod) => {
            return <PaymentMethod paymentMethod={paymentMethod} />;
          },
        },
        {
          text: t('billing:payment-methods.table.payment-type'),
          dataField: 'type',
          formatter: (cell: string, paymentMethod: IPaymentMethod) =>
            paymentMethod.isCreditCard ? 'Credit Card' : 'Bank Account',
        },
        {
          text: t('billing:payment-methods.table.expiration-date'),
          dataField: 'expirationDate',
          formatter: (cell: string, paymentMethod: IPaymentMethod) => {
            return paymentMethod?.expirationDate ? moment(paymentMethod?.expirationDate).format('MM/YY') : '';
          },
        },
        {
          text: capitalize(t('translation:spelling.autopay')),
          dataField: 'isAutoPay',
          formatter: (cell: string, paymentMethod: IPaymentMethod) => {
            return paymentMethod.isAutoPay ? (
              <ColoredBackgroundTag text="On" color={colors.success} />
            ) : (
              <ColoredBackgroundTag text="Off" color={colors.gray} />
            );
          },
        },
        {
          text: t('billing:payment-methods.table.default-payment'),
          dataField: 'number',
          formatter: (cell: string, paymentMethod: IPaymentMethod) => {
            return paymentMethod.isPrimary ? 'Yes' : 'No';
          },
        },
        {
          text: capitalize(t('translation:spelling.actions')),
          formatter: (cell: any, paymentMethod: IPaymentMethod) => {
            return <>{hasActions && <ActionDropdown actions={actions(paymentMethod)} />}</>;
          },
        },
      ]}
    />
  );
};

export default PaymentMethodTable;
