import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import DataTable, { TableHeader, TableSearch, TABLE_DEFAULTS } from 'shared/components/DataTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { currencyFormat } from 'shared/util/currency';
import useFormatDate from 'shared/hooks/useFormatDate';
import { IconButtonCircle } from 'shared/components/Buttons';
import Button from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Select from 'shared/components/Select';
import {
  convertTimeRangeObjectToString,
  convertTimeRangeStringToObject,
  timeRangeOptions,
} from 'shared/util/timeUtils';
import DateInput from 'shared/components/DateInput';
import moment from 'moment';
import { setCurrentCenterFilters } from 'store/context/actions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { useGetPayrixDisbursementsQuery, PayrixDisbursement } from 'generated/graphql';
import Tooltip from 'shared/components/Tooltip';
import useGetPayrixDisbursementReport from '../hooks/useGetPayrixDisbursementReport';
import useGetPayrixDisbursementReportPdf from '../hooks/useGetPayrixDisbursementReportPdf';
import CopyableText from 'shared/components/CopyableText';
import RunDisbursementReportModal from '../components/RunDisbursementReportModal';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IDisbursementsTableFilters {
  term: string;
  sortField: string;
  sortDirection: 'asc' | 'desc';
}

const DisbursementsTab = () => {
  const { t } = useTranslation(['translation', 'billing']);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const formatDate = useFormatDate();
  const dispatch = useDispatch();
  const { k2DisbursementPdf } = useFlags();

  const [disbursementReportModalOpen, setDisbursementReportModalOpen] = useState(false);

  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();

  const [disbursementToDownload, setDisbursementToDownload] = useState<PayrixDisbursement | null>(null);
  const [downloadPayrixDisbursementReport, loadingReport] = useGetPayrixDisbursementReport(() =>
    setDisbursementReportModalOpen(false)
  );
  const [downloadPayrixDisbursementReportPdf, loadingReportPdf] = useGetPayrixDisbursementReportPdf(() =>
    setDisbursementReportModalOpen(false)
  );

  const [timeframe, setTimeframe] = useState<ITimeRange>({
    start: moment().tz(timezone).startOf('month').format(),
    end: moment().tz(timezone).endOf('month').format(),
  });

  const [tableFilters, setTableFilters] = useState<IDisbursementsTableFilters>({
    term: '',
    sortField: 'created',
    sortDirection: 'desc',
  });
  const [tableState, tableFunctions] = useDatatableState();

  const handleRunReportButtonClick = useCallback(
    (disbursement: PayrixDisbursement) => {
      if (k2DisbursementPdf) {
        setDisbursementToDownload(disbursement);
        setDisbursementReportModalOpen(true);
      } else {
        setDisbursementToDownload(disbursement);
        downloadPayrixDisbursementReport({
          businessId: currentBusinessId,
          centerId: selectedCenterId,
          documentNumber: disbursement.id,
        });
      }
    },
    [currentBusinessId, downloadPayrixDisbursementReport, k2DisbursementPdf, selectedCenterId]
  );

  const handleRunReport = useCallback(
    (reportFormat: string) => {
      if (!disbursementToDownload) return;

      if (reportFormat === 'EXCEL') {
        downloadPayrixDisbursementReport({
          businessId: currentBusinessId,
          centerId: selectedCenterId,
          documentNumber: disbursementToDownload.id,
        });
      } else {
        downloadPayrixDisbursementReportPdf({
          businessId: currentBusinessId,
          centerId: selectedCenterId,
          documentNumber: disbursementToDownload.id,
        });
      }
    },
    [
      downloadPayrixDisbursementReport,
      downloadPayrixDisbursementReportPdf,
      currentBusinessId,
      selectedCenterId,
      disbursementToDownload,
    ]
  );

  const { data, loading } = useGetPayrixDisbursementsQuery({
    variables: {
      input: {
        businessId: currentBusinessId,
        searchTerm: tableFilters.term,
        sortBy: tableFilters.sortField,
        sortDirection: tableFilters.sortDirection,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        centerId: selectedCenterId,
        startDate: moment(timeframe.start).format('YYYY-MM-DD'),
        endDate: moment(timeframe.end).format('YYYY-MM-DD'),
      },
    },
    skip:
      !currentBusinessId ||
      !selectedCenterId ||
      (timeframe.start !== '' && timeframe.end === '') ||
      (timeframe.start === '' && timeframe.end !== ''),
  });

  const handleSearchTerm = debounce(
    useCallback(
      (term: string) => {
        tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
        setTableFilters((prev) => ({ ...prev, term }));
      },
      [tableFunctions]
    ),
    250
  );

  const clearAppliedFilters = useCallback(() => {
    dispatch(setCurrentCenterFilters([]));
    setTableFilters({
      term: '',
      sortField: 'created',
      sortDirection: 'desc',
    });
    setTimeframe({
      start: moment().tz(timezone).startOf('month').format(),
      end: moment().tz(timezone).endOf('month').format(),
    });
  }, [dispatch, setTimeframe, timezone]);

  /**
   * negative disbursements from Payrix contain nothing
   * @returns {Boolean}
   */
  const canRunReportForDisbursement = useCallback(
    (disbursement: PayrixDisbursement): boolean => disbursement.amount > 0,
    []
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <DataTable
        data={data?.getPayrixDisbursements.data ?? []}
        dataSize={data?.getPayrixDisbursements.totalRecords ?? 0}
        showSelect={false}
        showLoadingOverlay={loading}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        onSort={(field, direction) =>
          setTableFilters((prev) => ({
            ...prev,
            sortField: field,
            sortDirection: direction === 'ASCENDING' ? 'asc' : 'desc',
          }))
        }
        className="kt-disbursements-table"
        renderHeader={() => (
          <div className="align-items-center">
            <CenterSelectBanner pageName={t('billing:transactions.payments.tab-disbursements').toLowerCase()} />
            <TableHeader className="flex-wrap">
              <div className="d-flex flex-wrap align-items-center w-auto">
                <TableSearch
                  placeholder={t('translation:core.capitalize', { value: t('translation:spelling.search') })}
                  onChange={handleSearchTerm}
                  className={isMobile ? 'my-1 mr-4' : 'mr-4 flex-shrink-0 flex-grow-0'}
                />
                <Select
                  options={timeRangeOptions}
                  value={convertTimeRangeObjectToString(timeframe)}
                  onChange={(string) => setTimeframe(convertTimeRangeStringToObject(string))}
                  className={isMobile ? 'my-1 mr-4' : 'mr-2 flex-shrink-0 flex-grow-0 mb-0'}
                />
              </div>
              <div className={isMobile ? 'd-flex flex-nowrap my-1' : 'd-flex align-items-center ml-4 mr-auto'}>
                <DateInput
                  date={timeframe.start}
                  onDateSelect={(start) => setTimeframe({ ...timeframe, start })}
                  className="mb-0"
                />
                <div className={isMobile ? 'mt-2 mx-2' : 'mx-2'}>to</div>
                <DateInput
                  date={timeframe.end}
                  onDateSelect={(end) => setTimeframe({ ...timeframe, end })}
                  className="mb-0"
                />
              </div>
              <div className={isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex align-items-center'}>
                <IconButtonCircle
                  icon={faTimes}
                  onClick={clearAppliedFilters}
                  tooltipDirection="bottom"
                  tooltipText={t('translation:general.clear-filter')}
                />
              </div>
            </TableHeader>
          </div>
        )}
        columns={[
          {
            text: t('billing:transactions.disbursements-table.column-id'),
            dataField: 'id',
            sort: true,
            formatter: (id: string) => <CopyableText text={id} />,
          },
          {
            text: t('billing:transactions.disbursements-table.column-create-date'),
            dataField: 'created',
            sort: true,
            formatter: (createdDate: string) => formatDate(moment(createdDate).tz(timezone)),
          },
          {
            text: t('billing:transactions.disbursements-table.column-process-date'),
            dataField: 'processed',
            sort: true,
            formatter: (processedDate: string) => (processedDate ? formatDate(moment(processedDate).tz(timezone)) : ''),
          },
          {
            text: t('billing:transactions.disbursements-table.column-description'),
            dataField: 'description',
            sort: false,
            formatter: (description: string) => (
              <Tooltip text={description}>
                <div className="text-truncate">{description}</div>
              </Tooltip>
            ),
          },
          {
            text: t('billing:transactions.disbursements-table.column-status'),
            dataField: 'status',
            sort: true,
          },
          {
            text: t('billing:transactions.disbursements-table.column-amount'),
            dataField: 'amount',
            sort: true,
            // amount is in cents
            formatter: (amount: number) => currencyFormat(amount / 100),
          },
          {
            text: '',
            dataField: '',
            formatter: (cell: any, row: PayrixDisbursement) => (
              <Button
                variant={canRunReportForDisbursement(row) ? 'secondary' : 'outline-secondary'}
                onClick={() => handleRunReportButtonClick(row)}
                loading={loadingReport && disbursementToDownload?.id === row.id}
                className="disbursement-table-report-btn"
              >
                {canRunReportForDisbursement(row)
                  ? t('billing:transactions.disbursements-table.button-run-report')
                  : t('billing:transactions.disbursements-table.button-view-details')}
              </Button>
            ),
          },
        ]}
      />
      {k2DisbursementPdf && disbursementToDownload && (
        <RunDisbursementReportModal
          isOpen={disbursementReportModalOpen}
          isLoading={loadingReport || loadingReportPdf}
          disbursement={disbursementToDownload}
          onClose={() => setDisbursementReportModalOpen(false)}
          onSubmit={(type: string) => handleRunReport(type)}
        />
      )}
    </>
  );
};

export default DisbursementsTab;
