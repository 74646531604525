import { SET_NOTIFICATIONS, UPDATE_NOTIFICATION, NotificationV2ActionTypes } from './types';

export const setNotifications = (notifications: INotificationV2[]): NotificationV2ActionTypes => ({
  type: SET_NOTIFICATIONS,
  notifications,
});

export const updateNotifications = (notification: INotificationV2): NotificationV2ActionTypes => ({
  type: UPDATE_NOTIFICATION,
  notification,
});
