import React, { useState } from 'react';
import ISMessagesTable from 'pages/ISCases/components/ISMessagesTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetISMessages } from 'gql/iSMessage/queries';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { useUpdateISMessage } from 'gql/iSMessage/mutations';
import { MsgStatusCodes, MsgStatusLabels } from 'shared/constants/enums/ccssMessageEnums';

interface IProps {
  selectedBusiness: ITableFilterOption | null;
}
interface ISort {
  field: string;
  direction: ElasticsearchSortDirection;
}

const ISCasesMessagesTab: React.FC<IProps> = ({ selectedBusiness }) => {
  const businessId = selectedBusiness?.value || '';
  const { data: centers } = useGetActiveCentersWithLoading();
  const [activeFilters, setActiveFilters] = useState<IISMessagesFilter>({
    searchText: '',
    centers: [],
    statuses: [
      { label: MsgStatusLabels.UNREAD, value: MsgStatusCodes.UNREAD },
      { label: MsgStatusLabels.READ, value: MsgStatusCodes.READ },
    ],
  });
  const [tableState, tableFunctions] = useDatatableState();
  const [sort, setSortOptions] = useState<ISort[]>([{ field: 'created', direction: 'DESCENDING' }]);

  const { UNREAD, READ, ARCHIVED } = MsgStatusCodes;

  const selectedStatuses = activeFilters.statuses?.map((status) => status.value as CcsMsgStatus) ?? [];
  const { data, loading, refetch } = useGetISMessages({
    variables: {
      input: {
        businessId,
        centerIds: activeFilters.centers?.map((center) => center.value) || [],
        viewedStatus: selectedStatuses?.length > 0 ? selectedStatuses : [UNREAD, READ, ARCHIVED],
        searchText: activeFilters.searchText,
        sortDtos: sort,
      },
    },
  });

  const [updateMsgStatus] = useUpdateISMessage({
    onCompleted: (res) => {
      const status = res?.updateIsMessageViewedStatus?.viewedStatus;
      if (status === ARCHIVED || status === READ) {
        refetch();
      }
    },
  });

  const handleUpdateMsg = (msg: IISMessage | null) => {
    if (msg) {
      updateMsgStatus({
        variables: {
          input: {
            messageId: msg.id,
            viewedStatus: msg.viewedStatus,
            businessId: businessId,
            centerId: msg.centerId,
          },
        },
      });
    }
  };

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field, direction }]);
  };

  const handleFilterChange = (value: IISMessagesFilter) => {
    setActiveFilters((state) => ({
      ...state,
      ...value,
    }));
  };

  return (
    <div>
      <ISMessagesTable
        data={data?.getIsMessagesForCenters?.data || []}
        businessId={businessId}
        centers={centers ?? []}
        loading={loading}
        dataSize={data?.getIsMessagesForCenters?.totalRecords || 0}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        activeFilters={activeFilters}
        onActiveFilterChange={handleFilterChange}
        onSort={handleSort}
        selectedRows={tableState.selectedRows}
        updateSelectedRows={tableFunctions.updateSelectedRows}
        handleUpdateMsg={handleUpdateMsg}
      />
    </div>
  );
};

export default ISCasesMessagesTab;
