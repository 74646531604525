import * as types from './types';

export interface IAgencyStateShape {
  tableData: IAgency[];
}

const initialState: IAgencyStateShape = {
  tableData: [],
};

export const agencyReducers = (
  state: IAgencyStateShape = initialState,
  action: types.AgencyActionTypes
): IAgencyStateShape => {
  switch (action.type) {
    case types.GET_AGENCIES_TABLE_DATA:
      return {
        ...state,
        tableData: action.agencies,
      };
    case types.CREATE_AGENCY:
      return {
        ...state,
        tableData: [action.agency, ...state.tableData],
      };
    case types.UPDATE_AGENCY:
      return {
        ...state,
        tableData: state.tableData.map((agency) => (agency.id === action.agency.id ? action.agency : agency)),
      };
    default:
      return state;
  }
};
