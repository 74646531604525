import React from 'react';

// What is this component?
// This helps get rid of the ternary operator floating around in our html like code
// It aims to improve code readability

// How to use
// Use <RenderWhen> to wrap any if/else logic by default only one condition will be rendered you can change this by adding a limit prop and passing a number
// Then use <RenderWhen.If isTrue={condition when to render}> {children to render when that condition is met} </RenderWhen.If>

type WhenProps = {
  children: React.ReactNode;
  limit?: number;
};

const RenderWhen = ({ limit = 0, children }: WhenProps) => {
  const list: React.ReactNode[] = [];

  React.Children.map(children, (child: any) => {
    const { isTrue: isChildTrue } = child?.props || {};
    if (isChildTrue === true && list.length < limit) {
      list.push(child);
    }
  });

  return <>{list}</>;
};

RenderWhen.defaultProps = {
  limit: 1,
};

RenderWhen.If = ({ children, isTrue }: { children: any; isTrue: boolean }) => children;

export default RenderWhen;
