import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { medicareCardFields } from './fields';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IUpsertMedicareCardData {
  upsertMedicareCard: IMedicareCard;
}

interface IDeleteMedicareCardData {
  deleteMedicareCard: IMedicareCard;
}

interface IUpsertMedicareCardVariables {
  input: {
    medicareCardNumber: string;
    position: string;
    expiry: string;
    accountChildId: string;
    businessId: string;
  };
}

interface IDeleteMedicareCardVariables {
  id: string;
}

export const UPSERT_MEDICARE_CARD = (fields: string = medicareCardFields) => gql`
  mutation($input: UpsertMedicareCardInput!) {
    upsertMedicareCard(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_MEDICARE_CARD = (fields: string = medicareCardFields) => gql`
  mutation($id: ID!) {
    deleteMedicareCard(medicareCardId: $id) {
      ${fields}
    }
  }
`;

export const useUpsertMedicareCard = (
  options?: MutationHookOptions<IUpsertMedicareCardData, IUpsertMedicareCardVariables>,
  fields?: string
) => {
  return useMutation<IUpsertMedicareCardData, IUpsertMedicareCardVariables>(UPSERT_MEDICARE_CARD(fields), {
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
    ...options,
  });
};

export const useDeleteMedicareCard = (
  options?: MutationHookOptions<IDeleteMedicareCardData, IDeleteMedicareCardVariables>,
  fields?: string
) => {
  return useMutation<IDeleteMedicareCardData, IDeleteMedicareCardVariables>(DELETE_MEDICARE_CARD(fields), {
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
    ...options,
  });
};
