import React, { useState, useCallback } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import SideModalDrawer from 'shared/components/ModalDrawer';
import errorMessage from 'shared/constants/errorMessages';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import cast from 'shared/util/cast';
import { useCreateChildMedicalCondition } from 'gql/medicalCondition/mutations';
import { showToast } from 'shared/components/Toast';
import MedicalConditionInput from 'shared/components/Medical/MedicalConditionInput';
import Select from 'shared/components/Select';
import AllergyInputGroup, { IAllergyInputGroupFormShape } from 'shared/components/InputGroups/AllergyInputGroup';
import { useCreateAllergyForChild } from 'gql/allergy/mutations';
import { useDispatch } from 'react-redux';
import { createAllergyForChild, createChildRestrictionSuccess } from '../../../duck/actions';
import ChildMedicalDocumentationUploadForm from './ChildMedicalDocumentationUploadForm';
import Checkbox from 'shared/components/Checkbox';
import RestrictionInputGroup, { IRestrictionInputGroupFormShape } from './RestrictionInputGroup';
import { useCreateChildRestriction } from 'gql/restriction/mutations';

enum ConditionType {
  'Medical' = 'Medical Condition',
  'Allergy' = 'Allergy',
  'Restriction' = 'Restriction',
}

const newMedicalCondition = cast<IMedicalConditionInput>({
  typeId: null,
  risk: null,
});
const newRestriction = {
  type: null,
  importance: null,
  name: '',
  description: '',
  documentation: [],
};
const newAllergy = {
  allergen: null,
  instructions: '',
  severity: null,
  allergenType: null,
  reactions: [],
  documentation: [],
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  childId: string;
}

const AddChildHealthConditionModal: React.FC<IProps> = ({ isOpen, onClose, childId, ...props }) => {
  const dispatch = useDispatch();
  const [createMedicalCondition, { loading: createChildMedicalConditionLoading }] = useCreateChildMedicalCondition();
  const [createAllergyForChildFn, { loading: createChildAllergyLoading }] = useCreateAllergyForChild({
    onCompleted: (result) => {
      showToast('New allergy saved successfully.', 'success');
      dispatch(createAllergyForChild(result.createAllergyForChild));
      handleClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });
  const [createRestrictionFn, { loading: createRestrictionLoading }] = useCreateChildRestriction({
    onCompleted: (result) => {
      showToast('New restriction saved successfully.', 'success');
      dispatch(createChildRestrictionSuccess(childId, result.createChildRestriction));
      handleClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });
  const [showDocumentationFormGroup, setShowDocumentationFormGroup] = useState<boolean>(false);
  const [conditionType, setConditionType] = useState<ConditionType | undefined>(undefined);
  const [medicalCondition, setMedicalCondition] = useState(newMedicalCondition);
  const [allergyFormData, setAllergyFormData] = useState<IAllergyInputGroupFormShape>(newAllergy);
  const [restrictionFormData, setRestrictionFormData] = useState<IRestrictionInputGroupFormShape>(newRestriction);
  const [medicalDocumentation, setMedicalDocumentation] = useState<IHealthConditionDocumentUploadInput[]>([]);
  const isLoading = createChildMedicalConditionLoading || createChildAllergyLoading || createRestrictionLoading;

  const resetForm = useCallback(() => {
    setMedicalCondition(newMedicalCondition);
    setConditionType(undefined);
    setAllergyFormData(newAllergy);
    setRestrictionFormData(newRestriction);
    setShowDocumentationFormGroup(false);
    setMedicalDocumentation([]);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  const handleSaveMedicalCondition = useCallback(
    (closeOnComplete: boolean) => {
      createMedicalCondition({
        variables: {
          childId,
          input: {
            ...medicalCondition,
            symptoms: medicalCondition.symptoms ?? [],
            documentation: medicalDocumentation,
          },
        },
      })
        .then(() => {
          showToast('Medical condition saved.', 'success');
          resetForm();
          closeOnComplete && handleClose();
        })
        .catch(() => {
          showToast('There was an error saving medical condition.', 'error');
        });
    },
    [onClose, resetForm, childId, medicalCondition, medicalDocumentation]
  );

  const handleSaveChildAllergy = useCallback(() => {
    createAllergyForChildFn({
      variables: {
        input: {
          childId,
          allergenId: allergyFormData.allergen?.id as string,
          severity: allergyFormData.severity as AllergenSeverity,
          reactions: allergyFormData.reactions?.map((opt) => opt.value) ?? [],
          instructions: allergyFormData.instructions ?? '',
          documentation: medicalDocumentation,
        },
      },
    });
  }, [childId, allergyFormData, medicalDocumentation, createAllergyForChildFn]);

  const handleSaveChildRestriction = useCallback(() => {
    createRestrictionFn({
      variables: {
        childId,
        input: {
          typeId: restrictionFormData.type?.id as string,
          name: restrictionFormData.name,
          importance: restrictionFormData.importance as Risk,
          description: restrictionFormData.description,
          documentation: medicalDocumentation,
        },
      },
    });
  }, [childId, restrictionFormData, medicalDocumentation, createRestrictionFn]);

  const handlePrimaryCallback = useCallback(() => {
    switch (conditionType) {
      case ConditionType.Medical:
        handleSaveMedicalCondition(true);
        break;
      case ConditionType.Allergy:
        handleSaveChildAllergy();
        break;
      case ConditionType.Restriction:
        handleSaveChildRestriction();
        break;
      default:
        break;
    }
  }, [handleSaveMedicalCondition, handleSaveChildAllergy, handleSaveChildRestriction, conditionType]);

  const validateForm = useCallback((): boolean => {
    switch (conditionType) {
      case ConditionType.Medical:
        return Boolean(
          medicalCondition.name &&
            medicalCondition.typeId &&
            medicalCondition.risk &&
            (medicalCondition.risk === 'Low' ||
              medicalCondition.symptoms ||
              medicalCondition.risk === 'None' ||
              medicalCondition.symptoms) &&
            (medicalCondition.risk === 'Low' ||
              medicalCondition.instructions ||
              medicalCondition.risk === 'None' ||
              medicalCondition.instructions)
        );
      case ConditionType.Allergy:
        return Boolean(
          allergyFormData.allergen &&
            allergyFormData.severity &&
            (allergyFormData.severity === 'Mild' || allergyFormData.severity === 'NotApplicable'
              ? true
              : allergyFormData.reactions && allergyFormData.instructions)
        );
      case ConditionType.Restriction:
        return Boolean(
          restrictionFormData.name !== '' &&
            restrictionFormData.importance !== null &&
            restrictionFormData.type !== null
        );
      default:
        return false;
    }
  }, [conditionType, medicalCondition, allergyFormData, restrictionFormData]);

  return (
    <SideModalDrawer
      title="New Condition"
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Save"
      primaryCallback={handlePrimaryCallback}
      secondaryCallback={handleClose}
      primaryButtonProps={{ disabled: !validateForm(), loading: isLoading }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      footerHelperText={!validateForm() ? errorMessage.formRequirements : ''}
    >
      {isLoading ? (
        <LoadingLines />
      ) : (
        <div>
          <Select
            label="Category"
            options={Object.values(ConditionType)}
            value={conditionType}
            onChange={setConditionType}
          />
          {conditionType === ConditionType.Medical && (
            <MedicalConditionInput
              medicalCondition={medicalCondition}
              updateMedicalCondition={setMedicalCondition}
              readOnly={false}
            />
          )}
          {conditionType === ConditionType.Allergy && (
            <AllergyInputGroup formData={allergyFormData} onUpdate={setAllergyFormData} readOnly={false} />
          )}
          {conditionType === ConditionType.Restriction && (
            <RestrictionInputGroup formData={restrictionFormData} onUpdate={setRestrictionFormData} />
          )}
          {Boolean(conditionType) && (
            <Checkbox
              label="Add Documentation"
              value={showDocumentationFormGroup}
              onChange={(checked) => setShowDocumentationFormGroup(checked)}
              className="mb-4"
            />
          )}
          <Collapse in={showDocumentationFormGroup}>
            <div>
              <ChildMedicalDocumentationUploadForm
                documents={medicalDocumentation}
                onFilesAdd={(files) => setMedicalDocumentation((prev) => [...prev, ...files])}
                onFileUpdate={(updatedFile) =>
                  setMedicalDocumentation((prev) =>
                    prev.map((f) => (f.file.name === updatedFile.file.name ? updatedFile : f))
                  )
                }
                onRemoveFile={(filename) =>
                  setMedicalDocumentation((prev) => prev.filter((file) => file.file.name !== filename))
                }
                childId={childId}
              />
            </div>
          </Collapse>
        </div>
      )}
    </SideModalDrawer>
  );
};

export default AddChildHealthConditionModal;
