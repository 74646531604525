import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { useArchiveClass } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import errorMessages from 'shared/constants/errorMessages';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  _class: IClass;
}

const ArchiveClassModal: React.FC<IProps> = ({ isOpen, onClose, _class }) => {
  const [archiveFn, { loading }] = useArchiveClass();

  const handleArchive = useCallback(async () => {
    await archiveFn({
      variables: { input: { id: _class.id } },
    })
      .then(() => {
        showToast('Class archived.', 'success');
        onClose();
      })
      .catch((error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      });
  }, [_class.id, archiveFn, onClose]);

  return (
    <ConfirmationModal
      title={`Archive ${_class.name} class?`}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice={'Archive'}
      primaryCallback={handleArchive}
      primaryButtonProps={{
        variant: 'danger',
        loading,
      }}
    >
      <p>
        Are you sure you want to archive your <b>{_class.name}</b> class?
      </p>
      <p>
        Archiving will:
        <ul>
          <li>Make this class view-only</li>
          <li>Only historical data will be available</li>
          <li>Employee assignments will be removed</li>
          <li>Employee shifts after the current week for this class will be removed</li>
        </ul>
      </p>
    </ConfirmationModal>
  );
};

export default ArchiveClassModal;
