import React, { useState, useCallback, useEffect } from 'react';
import { ButtonAsLink } from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons';
import { UploadedFile } from './index';
import { useDropzone } from 'react-dropzone';
import randomProgressGenerator from 'shared/util/randomProgressGenerator';
import SelectedFile from './SelectedFile';

interface IProps {
  multiple?: boolean;
  onFileAdd: (files: File[]) => void;
  uploadedFiles?: File[];
  setUploadedFiles: (files: File[]) => void;
  setRemovedFileName?: (fileName: string) => void;
  fileIsInvalid?: boolean;
  uploadComplete?: boolean;
  className?: string;
  withProgress?: boolean;
}

const FileUploadDropbox: React.FC<IProps> = ({
  multiple = true,
  onFileAdd,
  uploadedFiles = [],
  setUploadedFiles,
  setRemovedFileName,
  fileIsInvalid = false,
  uploadComplete = false,
  withProgress = true,
  className,
}) => {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [progress, setProgress] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
      onFileAdd(acceptedFiles);

      // TODO: Replace this with actual progress/error handling
      randomProgressGenerator(setProgress);
    },
    [onFileAdd, setUploadedFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const openFileDialog = (): void => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const onFilesAdded = (filesAddedEvent: React.ChangeEvent<HTMLInputElement>) => {
    if (uploadedFiles && filesAddedEvent && filesAddedEvent.target) {
      const files = filesAddedEvent.target.files;
      const filesArray: any = Array.prototype.slice.call(files);
      filesArray.length && setUploadedFiles(filesArray);
      onFileAdd(filesArray);
    }
    // TODO: Replace this with actual progress/error handling
    randomProgressGenerator(setProgress);
  };

  const removeFileFromUploadList = (fileName: string) => {
    const newUploadList = uploadedFiles.filter((f: IFile) => !(f.name === fileName));
    if (setRemovedFileName) {
      setRemovedFileName(fileName);
    }
    setProgress(0);
    setUploadedFiles(newUploadList);
  };

  useEffect(() => {
    if (uploadedFiles.length === 0) {
      setProgress(0);
    }
  }, [uploadedFiles.length]);

  if (uploadedFiles.length > 0) {
    if (!withProgress) {
      return (
        <div className={`d-flex flex-row align-items-center ${className || ''}`}>
          {uploadedFiles.map((f, i) => {
            return (
              <SelectedFile file={f} key={i} updateContentsCb={() => {}} onDeleteClick={removeFileFromUploadList} />
            );
          })}
        </div>
      );
    }
    return (
      <div className={`d-flex flex-row align-items-center ${className || ''}`}>
        {uploadedFiles.map((f, i) => {
          return (
            <UploadedFile
              file={f}
              key={i}
              deleteCb={removeFileFromUploadList}
              updateContentsCb={() => {}}
              progress={uploadComplete ? 100 : progress}
              fileIsInvalid={fileIsInvalid}
              uploadComplete={uploadComplete}
            />
          );
        })}
      </div>
    );
  } else {
    return (
      <div className={className}>
        <div
          className={
            'd-flex flex-row p-2 file-display dropzone justify-content-center align-items-center' +
            (isDragActive ? ' dropzone-active' : '')
          }
          {...getRootProps()}
        >
          <div className="align-items-center">
            <FontAwesomeIcon icon={faCloudUpload} />
            <span className="ml-2 text-center">
              Drag and Drop or{' '}
              <ButtonAsLink className="md text-primary" onClick={openFileDialog}>
                {' '}
                Browse{' '}
              </ButtonAsLink>{' '}
              to upload.
            </span>
            <input
              {...getInputProps()}
              ref={fileInputRef}
              className="fileInput"
              type="file"
              multiple={multiple}
              onChange={onFilesAdded}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default FileUploadDropbox;
