import { sortBy } from 'lodash';

/**
 * sort an array of objects where each object corresponds to a day of the week.
 * this function will sort the objects so that they are in order Sunday -> Saturday
 *
 * @param {Object[]} arr - Array to sort
 * @param {String} key - Key that corresponds to the attribute that contains the day of the week value. Default value is 'dayOfWeek'
 */
export const sortByWeekDay = <T>(arr: T[], key: string = 'dayOfWeek'): T[] => {
  return sortBy(arr, (a) => {
    // @ts-ignore
    const aVal: DayOfWeek = a[key];

    const ranking = {
      SUNDAY: 0,
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6,
    };

    return ranking[aVal] || 7;
  });
};

/**
 * Minimize the number of arrays in a array
 *
 * The goal of this algorithm is to the reduce the number of arrays in an array
 * by combining arrays with empty indicies via pushing lower values into rows higher up with
 * empty spots
 *
 * Example:
 * arr = [
 *  [1, '', ''],
 *  [2, '', ''],
 *  ['', 3, ''],
 *  ['', 4, ''],
 *  ['', '', 5],
 * ]
 *
 * arr = [
 *  [1, 3, 5],
 *  [2, 4, '']
 * ]
 */
export const minimizeArrays = <T>(arrayOfArrys: T[][], emptyValue: any = ''): T[][] => {
  const arr = [...arrayOfArrys];
  const outcome = [];

  for (let row = 0; row < arr.length; row++) {
    if (row === 0) {
      // @ts-ignore
      outcome.push(arr[row]);
      continue;
    }

    let arrayToCheck = arr[row];
    for (let column = 0; column < arrayToCheck.length; column++) {
      const value = arrayToCheck[column];

      if (value !== emptyValue) {
        let rowToInsertTo = 0;
        let pushNewArray = true;

        // navigate up the previous rows until we find an index with an emptyValue and update
        while (rowToInsertTo < outcome.length) {
          if (outcome[rowToInsertTo][column] === emptyValue) {
            // @ts-ignore
            outcome[rowToInsertTo][column] = arrayToCheck[column];
            pushNewArray = false;
            break;
          }

          rowToInsertTo += 1;
        }

        if (pushNewArray) {
          // @ts-ignore
          outcome.push(arrayToCheck);
        }
      }
    }
  }

  return outcome;
};
