import React, { useCallback, useMemo } from 'react';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import Row from 'react-bootstrap/Row';
import { Col } from 'shared/components/Layout';
import { TableSearch } from 'shared/components/DataTable';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import { feeStatusFilterOptions } from 'shared/constants/feeStatusFilterOptions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  showClassFilter?: boolean;
  classOptions: IClass[];
  filter: ITableFiltersMap;
  handleUpdateSearch: (searchExpressions: ISearchExpression[]) => void;
  hanldeUpdateFilter: (filter: ITableFiltersMap) => void;
}

const FilterGroup: React.FC<IProps> = ({
  showClassFilter = true,
  classOptions,
  handleUpdateSearch,
  filter,
  hanldeUpdateFilter,
}) => {
  const classFilterOptions: ITableFilterOption[] = useMemo(
    () =>
      classOptions
        .map((_class: IClass) => ({
          label: _class.name,
          value: _class.id,
          searchExpression: {
            [SEARCH_EXPRESSIONS.TERM]: {
              field: 'classIds.keyword',
              predicate: PREDICATES.CONTAINS,
              value: _class.id,
            },
          },
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [classOptions]
  );

  const getSearchExpression = useCallback((term: string): ISearchExpression[] => {
    const searchableFields: string[] = ['name'];

    if (!term) {
      return [];
    }

    const searchTerm = term.toLowerCase();

    const searchArr: ISearchExpression[] = searchableFields.map((column) => ({
      [SEARCH_EXPRESSIONS.TERM]: {
        field: column,
        predicate: PREDICATES.CONTAINS,
        value: searchTerm,
      },
    }));

    if (!Number.isNaN(Number.parseFloat(searchTerm))) {
      searchArr.push({
        [SEARCH_EXPRESSIONS.TERM]: {
          // this is a number field in ES so we can't use `CONTAINS`
          field: 'schedules.rates.value',
          predicate: PREDICATES.EQUALS,
          value: searchTerm,
        },
      });
    }

    return [{ [SEARCH_EXPRESSIONS.ANY]: searchArr }];
  }, []);

  const updateSelectedStatusFilters = useCallback(
    (values: ITableFilterOption[]) => {
      hanldeUpdateFilter({
        ...filter,
        status: values,
      });
    },
    [filter, hanldeUpdateFilter]
  );

  const updateSelectedClassFilters = useCallback(
    (values: ITableFilterOption[]) => {
      hanldeUpdateFilter({
        ...filter,
        class: values,
      });
    },
    [filter, hanldeUpdateFilter]
  );

  const clearAppliedFilters = useCallback(() => {
    hanldeUpdateFilter({ status: [], class: [] });
  }, [hanldeUpdateFilter]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div className="d-flex flex-row flex-grow-1">
      <Row className="d-flex flex-row align-items-center justify-content-start justify-content-md-end flex-grow-1">
        <div className="d-flex flex-grow-1">
          <TableSearch
            onChange={(term) => handleUpdateSearch(getSearchExpression(term))}
            className={isMobile ? 'mt-2 ml-2' : ''}
            placeholder="Search Fee(s)"
          />
        </div>
        <Col xs="auto" className={isMobile ? 'mt-2' : 'my-0 mx-2 p-0'}>
          <DropdownFilter
            title="Status"
            selectedFilters={filter.status}
            options={feeStatusFilterOptions}
            onFilterSelect={updateSelectedStatusFilters}
            dropdownId="fees-status-dropdown"
          />
        </Col>
        {showClassFilter && (
          <Col xs="auto" className={isMobile ? 'mt-2 pl-0 ml-2' : 'my-0 mx-2 p-0'}>
            <DropdownFilter
              title="Class"
              selectedFilters={filter.class}
              options={classFilterOptions}
              onFilterSelect={updateSelectedClassFilters}
              dropdownId="fees-class-dropdown"
            />
          </Col>
        )}
        <Col xs="auto" className={isMobile ? 'mt-2' : 'my-0 ml-2 p-0'}>
          <IconButtonCircle
            icon={faTimes}
            onClick={clearAppliedFilters}
            disabled={Object.values(filter).every((f) => !f || f.length === 0)}
            tooltipDirection="bottom"
            tooltipText="Clear Filters"
          />
        </Col>
      </Row>
    </div>
  );
};

export default FilterGroup;
