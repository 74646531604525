import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import { FileUploadDropbox } from 'shared/components/FileOperations';
import FormLabel from 'shared/components/Form/FormLabel';
import { Col, Row } from 'shared/components/Layout';
import useK2Upload from 'shared/hooks/useK2Upload';
import { emptyK2File } from 'shared/util/file';
import ImportInstruction from '../ImportInstruction';
import UploadButton from '../UploadButton';
interface IProps extends IImportStepProps {
  importCCSS: (data: IQikKidsCCSSVariables) => void;
}

const CCSImport: React.FC<IProps> = ({ importCCSS, center, loading, completed = false, shouldReset }) => {
  const { t } = useTranslation('ktAdmin');
  const [isDryRun, setDryRun] = useState(true);

  const { files: careTypeFiles, setFiles: setCareTypeFiles, k2File: careTypeK2File } = useK2Upload(shouldReset);
  const {
    files: certificationFiles,
    setFiles: setCertificationFiles,
    k2File: certificationK2File,
  } = useK2Upload(shouldReset);
  const {
    files: determinationFiles,
    setFiles: setDeterminationFiles,
    k2File: determinationK2File,
  } = useK2Upload(shouldReset);
  const { files: enrolmentFiles, setFiles: setEnrolmentFiles, k2File: enrolmentK2File } = useK2Upload(shouldReset);

  const handleUpload = () => {
    importCCSS({
      businessId: center?.entityId || '',
      centerId: center?.id || '',
      careTypes: careTypeK2File || emptyK2File,
      certificates: certificationK2File || emptyK2File,
      determinations: determinationK2File || emptyK2File,
      enrolments: enrolmentK2File || emptyK2File,
      dryRun: isDryRun,
    });
  };

  const selectedFiles =
    careTypeFiles.length + certificationFiles.length + determinationFiles.length + enrolmentFiles.length;
  return (
    <Card
      header={t('data-importer.step-number', {
        number: 5,
        title: t('data-importer.ccs'),
      })}
    >
      <ImportInstruction isDryRun={isDryRun} setDryRun={setDryRun} />
      <Row className="mb-4">
        <Col>
          <FormLabel required>{t('data-importer.care-type')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setCareTypeFiles}
            uploadedFiles={careTypeFiles}
            setUploadedFiles={setCareTypeFiles}
            withProgress={false}
          />
        </Col>
        <Col>
          <FormLabel required>{t('data-importer.certificates')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setCertificationFiles}
            uploadedFiles={certificationFiles}
            setUploadedFiles={setCertificationFiles}
            withProgress={false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormLabel required>{t('data-importer.determinations')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setDeterminationFiles}
            uploadedFiles={determinationFiles}
            setUploadedFiles={setDeterminationFiles}
            withProgress={false}
          />
        </Col>
        <Col>
          <FormLabel required>{t('data-importer.enrolments')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setEnrolmentFiles}
            uploadedFiles={enrolmentFiles}
            setUploadedFiles={setEnrolmentFiles}
            withProgress={false}
          />
        </Col>
      </Row>
      <UploadButton
        onClick={handleUpload}
        selectedFiles={selectedFiles}
        totalFiles={4}
        disabled={selectedFiles < 4}
        completed={completed}
        loading={loading}
      />
    </Card>
  );
};

export default CCSImport;
