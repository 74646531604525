import React from 'react';

interface IProps {
  text?: string;
  className?: string;
}

const BoldCardHeader: React.FC<IProps> = ({ text = '', className = '' }) => {
  return <h5 className={`${className} font-weight-bold`}>{text}</h5>;
};

export default BoldCardHeader;
