import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

interface IGetShiftsForPersonForDatesVariables {
  personId: string;
  dates: IDateTimeRange[];
}

interface IGetShiftsForPersonForDatesData {
  getShiftsForPersonForDates: IShift[];
}

export const GET_SHIFTS_FOR_PERSON_FOR_DATES = gql`
  query ($personId: ID!, $dates: [DateTimeRangeInput!]!) {
    getShiftsForPersonForDates(personId: $personId, dates: $dates) {
      id
      scheduleId
      classId
      locationId
      personId
      positionId
      centerId
      startTime
      endTime
      breakMinutes
      paidBreak
      note
      published
      person {
        id
        nickname
        firstname
        lastname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
          colorCode
        }
      }
      position {
        id
        title
        positionName
      }
      class {
        id
        name
        colorCode
      }
      location {
        id
        name
      }
      center {
        id
        name
      }
    }
  }
`;

export const useGetShiftsForPersonForDates = (personId: string, dates: IDateTimeRange[]) =>
  useQuery<IGetShiftsForPersonForDatesData, IGetShiftsForPersonForDatesVariables>(GET_SHIFTS_FOR_PERSON_FOR_DATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      personId,
      dates,
    },
    onError: (err) => {
      console.log(`There was an error fetching shifts for this person.`);
    },
  });
