import React, { useEffect, useState } from 'react';
import _, { capitalize } from 'lodash';

import * as Mui from '@mui/material';

import DefaultRoyaltySetting from './components/DefaultRoyaltySetting';
import RoyaltyFees from './components/RoyaltyFees/RoyaltyFees';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

import {
  useGetReEnrolBusinessSettingsQuery,
  useSetCommsBusinessSettingsMutation,
  useSetReEnrolBusinessSettingsMutation,
} from 'generated/graphql';
import { useGetBusinessConfigurationOptionsById } from 'gql/businessConfiguration/queries';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';

import Setting from 'shared/components/Settings';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isRegion } from 'shared/util/region';
import { getCommsBusinessSettings, getUsesComms } from 'pages/Engagement/duck/actions';
import BlankPage from 'shared/components/BlankPage/BlankPage';
import { useUpsertBusinessConfiguration } from 'gql/businessConfiguration/mutations';
import CentersTable from './components/ExpressCheckinModal/CentersTable';
import ExpressCheckinModal from './components/ExpressCheckinModal/ExpressCheckinModal';

const BusinessSettings: React.FC = () => {
  const isAuRegion = isRegion('AU');
  const { k2RoyaltyFeeConfiguration } = useFlags();
  const { expressCheckIn } = useFlags();
  const { t } = useTranslation(['comms', 'enrollment', 'translation']);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const messagingSetting = useSelector((state: RootState) => state.comms.usesComms);
  const dispatch = useDispatch();
  const [checkinToggled, setCheckinToggled] = React.useState(false);
  const [openCheckinModal, setOpenCheckinModal] = React.useState(false);

  const [setCommsBusinessSettings] = useSetCommsBusinessSettingsMutation({
    onError: (err) => showToast('Unable set business settings', 'error'),
    onCompleted: (response) => {
      dispatch(getCommsBusinessSettings(response.setCommsBusinessSettings));
      dispatch(
        getUsesComms(response.setCommsBusinessSettings ? response.setCommsBusinessSettings?.usesComms ?? false : false)
      );
    },
  });

  const {
    data: getBusinessConfigData,
    loading,
    refetch: refetchBusinessConfig,
  } = useGetBusinessConfigurationOptionsById({
    variables: {
      id: businessId ?? '',
    },
  });

  const [upsertBusinessConfig, { loading: loadingSave }] = useUpsertBusinessConfiguration({
    onCompleted: (getBusinessConfigData) => {
      refetchBusinessConfig();
    },
  });

  const currentActivityFeedReleaseSchedule =
    getBusinessConfigData?.getBusinessConfigurationByBusinessId?.activityFeedReleaseSchedule ?? null;

  useEffect(() => {
    if (getBusinessConfigData) {
      setCheckinToggled(getBusinessConfigData?.getBusinessConfigurationByBusinessId?.expressCheckinEnabled ?? false);
    }
  }, [getBusinessConfigData]);

  return (
    <>
      <Mui.Box
        sx={{
          backgroundColor: 'white',
          borderBottom: '1px solid #E2E7EE',
          padding: '12px 12px',
          position: 'sticky',
          top: '65px',
          zIndex: '900',
        }}
      >
        <Mui.Box display="flex" justifyContent="space-between" sx={{ alignItems: 'center' }}>
          {/* this is the page title */}
          <Mui.Typography component="h1" paddingLeft={'16px'} variant="pageHeader">
            {t('comms:business-settings.main-title')}
          </Mui.Typography>
        </Mui.Box>
      </Mui.Box>
      {!isAuRegion && k2RoyaltyFeeConfiguration && (
        <Mui.Box padding={4} style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1920px' }}>
          {/* <DefaultRoyaltySetting /> */}
          <RoyaltyFees />
        </Mui.Box>
      )}
      <Mui.Box padding={4}>
        <Setting
          sectionHeader={'Family & Engagement Settings'}
          title={t('comms:business-settings.center-messaging-setting-title')}
          subtitle={t('comms:business-settings.center-messaging-setting-subtitle')}
          toggleValue={messagingSetting}
          handleToggle={(value: boolean) => {
            setCommsBusinessSettings({
              variables: {
                businessId: businessId ?? '',
                input: {
                  businessId: businessId ?? '',
                  useComms: value,
                },
              },
            });
          }}
        />
        {currentActivityFeedReleaseSchedule !== null ? (
          <Setting
            title={t('comms:business-settings.delayed-activity-feed-setting-title')}
            subtitle={t('comms:business-settings.delayed-activity-feed-setting-subtitle')}
            toggleValue={currentActivityFeedReleaseSchedule === 'OnCheckout'}
            handleToggle={(enabled: boolean) => {
              upsertBusinessConfig({
                variables: {
                  input: {
                    businessId: businessId ?? '',
                    activityFeedReleaseSchedule: enabled ? 'OnCheckout' : 'Immediately',
                  },
                },
              });
            }}
          />
        ) : null}
        {expressCheckIn ? (
          <Setting
            title="Express Check-in"
            subtitle={`When in range of their ${t(
              'translation:spelling.center'
            )}, family members are able to check children in via the KT Connect app`}
            isBeta={true}
            toggleValue={checkinToggled}
            handleToggle={(enabled: boolean) => {
              if (enabled) {
                setOpenCheckinModal(true);
              } else {
                setCheckinToggled(false);
                upsertBusinessConfig({
                  variables: {
                    input: {
                      businessId: businessId ?? '',
                      expressCheckinEnabled: false,
                    },
                  },
                });
              }
            }}
          />
        ) : null}
      </Mui.Box>
      <ExpressCheckinModal
        businessId={businessId ?? ''}
        openCheckinModal={openCheckinModal}
        setOpenCheckinModal={setOpenCheckinModal}
        turnOnExpressCheckin={() => {
          setCheckinToggled(true);
          upsertBusinessConfig({
            variables: {
              input: {
                businessId: businessId ?? '',
                expressCheckinEnabled: true,
              },
            },
          });
        }}
      />
    </>
  );
};

export default BusinessSettings;
