import React, { useState, useCallback } from 'react';
import AvatarEditor from 'react-avatar-editor';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { Row, Col } from 'shared/components/Layout';

interface IProps {
  image: string | File | undefined;
  open: boolean;
  saveCallback: (file: File) => void;
  onHide: () => void;
  width?: number;
  height?: number;
}

const ResizeModal: React.FC<IProps> = ({ image, open, saveCallback, onHide, width = 200, height = 200 }) => {
  const editor: React.RefObject<AvatarEditor> = React.createRef();
  const [scale, setScale] = useState(1);

  const save = useCallback(() => {
    if (editor && editor.current) {
      editor.current.getImageScaledToCanvas().toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'newAvatar');
          saveCallback(file);
        }
      });
    }
  }, [editor, saveCallback]);

  return (
    <ConfirmationModal
      title="Crop your photo"
      show={open}
      onHide={onHide}
      secondaryCallback={onHide}
      primaryCallback={save}
    >
      <Col>
        <Row className="mb-4" justify="center">
          <AvatarEditor
            ref={editor}
            image={image}
            width={width}
            height={height}
            border={50}
            borderRadius={100}
            scale={scale}
            rotate={0}
          />
        </Row>
        <Row justify="center">
          <span className="mr-2">Zoom:</span>
          <input
            name="scale"
            type="range"
            onChange={(e) => {
              setScale(e.target.valueAsNumber);
            }}
            min=".25"
            max="1.75"
            step="0.01"
            defaultValue="1"
          />
        </Row>
      </Col>
    </ConfirmationModal>
  );
};

export default ResizeModal;
