import { faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import TextInput from '../TextInput';

const timeOptions: string[] = [];
const loopTime = moment().startOf('d');
const endOfDay = moment().endOf('d');
while (loopTime.isBefore(endOfDay)) {
  timeOptions.push(loopTime.clone().format('h:mma'));
  loopTime.add(15, 'minutes');
}
const charsAllowedInTimeValue = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'a', 'p', 'm', ':'];

interface IProps {
  value: string | null | undefined;
  defaultTime?: string;
  onChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  isInvalid?: boolean;
  showIcon?: boolean;
}

const TimePicker2: React.FC<IProps> = ({ value, onChange, defaultTime = '12:00pm', showIcon = true, ...rest }) => {
  const isInStandardTime = useCallback((val) => val && moment(val, 'h:mma').format('h:mma') === val, []);
  const isInMilitaryTime = useCallback(
    (val) =>
      val && (moment(val, 'HH:mm').format('HH:mm') === val || moment(val, 'HH:mm:ss').format('HH:mm:ss') === val),
    []
  );
  const convertTimeToStandard = useCallback(
    (val) => (isInMilitaryTime(val) ? moment(val, 'HH:mm').format('h:mma') : val),
    []
  );
  const convertTimeToMilitary = useCallback(
    (val) => (isInStandardTime(val) ? moment(val, 'h:mma').format('HH:mm') : val),
    []
  );

  const validatedDefaultTime = timeOptions.includes(convertTimeToStandard(defaultTime))
    ? convertTimeToStandard(defaultTime)
    : '12:00pm';
  const getClosestTimeOption = useCallback((val) => {
    const valueAsTime = moment(val ?? '', 'h:mma');
    return valueAsTime.isValid()
      ? timeOptions.find(
          (t) => t === valueAsTime.format('h:mma') || valueAsTime.diff(moment(t, 'h:mma'), 'minutes') < 15
        ) ?? validatedDefaultTime
      : validatedDefaultTime;
  }, []);

  const [showOptions, setShowOptions] = useState(false);
  const [closestTimeOption, setClosestTimeOption] = useState(getClosestTimeOption(value));
  const refs: any = Object.fromEntries(timeOptions.map((t) => [t, React.createRef()]));

  const handleChange = useCallback(
    (val) => {
      if (val.split('').every((char: any) => charsAllowedInTimeValue.includes(char))) {
        onChange(convertTimeToMilitary(val));
        setClosestTimeOption(getClosestTimeOption(val));
      }
    },
    [onChange]
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onChange(convertTimeToMilitary(closestTimeOption));
      } else if (e.key === 'ArrowDown') {
        setClosestTimeOption(moment(closestTimeOption, 'h:mma').clone().add(15, 'm').format('h:mma'));
      } else if (e.key === 'ArrowUp') {
        setClosestTimeOption(moment(closestTimeOption, 'h:mma').clone().subtract(15, 'm').format('h:mma'));
      }
    },
    [closestTimeOption]
  );

  useEffect(() => {
    if (refs[closestTimeOption]?.current) {
      refs[closestTimeOption].current.scrollIntoView();
    }
  }, [closestTimeOption, refs]);

  return (
    <TextInput
      value={convertTimeToStandard(value) ?? ''}
      onChange={handleChange}
      onFocus={() => setShowOptions(true)}
      onBlur={debounce(() => setShowOptions(false), 250)}
      isFocusedFromParent={showOptions}
      onKeyDown={handleKeyPress}
      placeholder="HH:MM"
      componentLeft={showIcon && <FontAwesomeIcon icon={faClock} />}
      isInvalid={
        !!value &&
        !(moment(value, 'HH:mm').format('HH:mm') === value || moment(value, 'h:mma').format('h:mma') === value)
      }
      {...rest}
    >
      <div className="w-100 position-relative">
        {showOptions && (
          <ul className="time-picker-menu">
            {timeOptions.map((time) => (
              <li
                key={time}
                ref={refs[time]}
                className={time === closestTimeOption ? 'selected' : ''}
                onClick={() => {
                  onChange(convertTimeToMilitary(time));
                  setClosestTimeOption(time);
                  setShowOptions(false);
                }}
              >
                {time}
              </li>
            ))}
          </ul>
        )}
      </div>
    </TextInput>
  );
};

export default TimePicker2;
