import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DateInput from 'shared/components/DateInput';
import { Row, Col } from 'shared/components/Layout';
import ModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import AccountSelect from 'shared/components/Select/AccountSelect';
import { useGetPaymentReportLazy } from 'gql/reports/queries';
import { showToast } from 'shared/components/Toast';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import moment from 'moment';
import Switch from 'shared/components/Switch';
import { PAYMENT_FILTERS } from 'gql/transaction/queries';

interface IFormShape {
  centerIds: string[];
  accountIds: string[] | null;
  start: string | null;
  end: string | null;
  statuses: PaymentStatus[];
  paymentMethodTypes: any[];
  transactionDate: TransactionDateType;
}

interface IProps {
  isOpen: boolean;
  defaultFormValues: {
    start: string;
    end: string;
    centerIds: string[];
    statuses: PaymentStatus[];
  };
  onClose: () => void;
}

const PaymentsReportModal: React.FC<IProps> = ({ isOpen, defaultFormValues, onClose, ...props }) => {
  const { t } = useTranslation();
  const reportDataToFile = useReportDataToFile();
  const user = useSelector((state: RootState) => state.user);
  const [formData, setFormData] = useState<IFormShape>({
    centerIds: defaultFormValues.centerIds ?? [],
    accountIds: [],
    start: defaultFormValues.start ?? null,
    end: defaultFormValues.end ?? null,
    statuses: defaultFormValues.statuses ?? [],
    paymentMethodTypes: [],
    transactionDate: 'APPLIES',
  });

  const [getPaymentReportFn, { loading }] = useGetPaymentReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(result.getPaymentReport, ReportTypeEnum.PAYMENTS);
      handleClose();
    },
    onError: (error) => {
      showToast(t('reports.general-failure-message'), 'error');
    },
  });

  const formDisabled =
    !formData.start ||
    !formData.end ||
    (formData.accountIds !== null && formData.accountIds.length === 0) ||
    !formData.centerIds.length ||
    !formData.statuses ||
    !formData.paymentMethodTypes;

  const statusOptions = [
    { label: 'Completed', value: 'COMPLETED' },
    { label: 'Failed', value: 'FAILED' },
    { label: 'Flagged', value: 'FLAGGED' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Ready', value: 'UNSUBMITTED' },
  ];

  const paymentMethodTypeOptions = [
    { label: 'Credit Card', value: 'cc' },
    { label: 'Bank Account', value: 'ach' },
    { label: 'Cash', value: 'cash' },
    { label: t('billing.types.check'), value: 'check' },
    { label: t('billing.types.eftpos'), value: 'eftpos' },
    { label: t('billing.types.direct-deposit'), value: 'direct_deposit' },
  ];

  const handleClose = useCallback(() => {
    setFormData({
      centerIds: [],
      accountIds: [],
      start: null,
      end: null,
      statuses: [],
      paymentMethodTypes: [],
      transactionDate: 'APPLIES',
    });
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if (formData.start && formData.end) {
      getPaymentReportFn({
        variables: {
          input: {
            businessId: user?.entityId ?? '',
            centerIds: formData.centerIds,
            accountIds: formData.accountIds ?? [],
            paymentMethodTypes: formData.paymentMethodTypes,
            statuses: [
              ...PAYMENT_FILTERS.filter((x) =>
                // @ts-ignore
                (formData.statuses.map((s) => (s === 'READY' ? 'UNSUBMITTED' : s)) as string[]).includes(x)
              ),
            ],
            startDate: moment(formData.start).format('YYYY-MM-DD'),
            endDate: moment(formData.end).format('YYYY-MM-DD'),
            showFlagged: formData.statuses.some((s) => s == 'FLAGGED'),
            transactionDate: formData.transactionDate,
          },
        },
      });
    }
  }, [getPaymentReportFn, formData, user]);

  return (
    <ModalDrawer
      title="Ready to run a report?"
      show={isOpen}
      onHide={handleClose}
      primaryButtonProps={{ disabled: formDisabled, loading }}
      primaryChoice="Run Report"
      primaryCallback={handleSubmit}
      closeOnPrimaryCallback={false}
      secondaryCallback={handleClose}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            selectedCenterIds={formData.centerIds}
            onSelect={(centerIds) => setFormData((prev) => ({ ...prev, centerIds: centerIds as string[] }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AccountSelect
            selectedAccountIds={formData.accountIds}
            onSelect={(accountIds) => setFormData((prev) => ({ ...prev, accountIds }))}
            required
            isMulti
            centerIds={formData.centerIds}
            statusType={'All'}
            appearance="detailed"
            showAccountStatus
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            label="From Date"
            date={formData.start}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, start: date }))}
            className="kt-date-input-no-max-width"
          />
        </Col>
        <Col>
          <DateInput
            label="To Date"
            date={formData.end}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, end: date }))}
            className="kt-date-input-no-max-width"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Switch
            label={t('spelling.periodAccounting')}
            labelSide="top"
            value={formData.transactionDate === 'PERIOD'}
            onChange={(value: boolean) => setFormData({ ...formData, transactionDate: value ? 'PERIOD' : 'APPLIES' })}
            className="mb-4"
            height={30}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            label="Status"
            className="flex-wrap"
            options={statusOptions}
            onChange={(options) =>
              setFormData((prev) => ({ ...prev, statuses: options?.map((opt: any) => opt.value) }))
            }
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            value={statusOptions.filter((status) => formData.statuses?.includes(status.value as PaymentStatus))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            label="Payment Method"
            className="flex-wrap"
            options={paymentMethodTypeOptions}
            onChange={(options) =>
              setFormData((prev) => ({ ...prev, paymentMethodTypes: (options ?? []).map((opt: any) => opt.value) }))
            }
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
          />
        </Col>
      </Row>
    </ModalDrawer>
  );
};

export default PaymentsReportModal;
