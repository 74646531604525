import { PersonAvatar } from 'shared/components/Avatar';
import DataTable, { SizePerPage, TableHeader, TableSearch, TABLE_DEFAULTS } from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { getFullName, getInitials, parsePhoneNumberWithRegion } from 'shared/util/string';
import { nameof } from 'ts-simple-nameof';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import { debounce } from 'lodash';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState2';

interface IFilterFields {
  businessIds: string[];
  centerTagIds: string[];
  centerIds: string[];
  contactTagIds: string[];
  searchText: string;
}

export type FilterData = Partial<IFilterFields>;

interface IHeaderProps {
  paginationProps: any;
  isInternal: boolean;
  filterOptionsRecord: Record<keyof Omit<FilterData, 'searchText'>, ITableFilterOption[]>;
  /**Debounced update that fires when filters are updated */
  onFilterUpdate?: (filters: FilterData) => void;
}

const ContactsTableV2Header = ({ paginationProps, isInternal, filterOptionsRecord, onFilterUpdate }: IHeaderProps) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [filterData, setFilterData] = useState<FilterData>({});
  const onFilterUpdateDebounced = useMemo(() => debounce(onFilterUpdate ?? (() => {}), 500), [onFilterUpdate]);

  const handleChange = (value: FilterData) => {
    setFilterData({ ...filterData, ...value });
    onFilterUpdateDebounced({ ...filterData, ...value });
  };
  const clearFilters = () => {
    setFilterData({});
    onFilterUpdateDebounced({});
  };

  const getProps = <t extends keyof Omit<IFilterFields, 'searchText'>>(field: t) => ({
    onFilterSelect: (values: ITableFilterOption[]) => handleChange({ [field]: values.map((x) => x.value) }),
    selectedFilters: filterData[field],
    options: filterOptionsRecord[field],
  });

  return (
    <TableHeader className="flex-wrap align-items-center">
      <div className={isMobile ? 'd-flex flex-wrap ml-2' : 'd-flex flex-wrap mr-auto'}>
        <SizePerPage paginationProps={paginationProps} />
        <TableSearch
          placeholder="Search Contacts"
          className={isMobile ? 'mt-2' : ''}
          onChange={(searchText) => handleChange({ searchText: searchText })}
        />
      </div>
      <div className="flex-wrap d-flex align-items-center">
        {isInternal && (
          <DropdownFilter
            className={isMobile ? 'mt-2 ml-2' : 'my-0 mr-2 ml-auto p-0'}
            title="Business"
            {...getProps('businessIds')}
          />
        )}
        <DropdownFilter
          className={isMobile ? 'mt-2 pl-0 ml-2' : 'my-0 mx-2 p-0'}
          title={t('families.contacts.center-tags')}
          {...getProps('centerTagIds')}
        />
        <DropdownFilter
          className={isMobile ? 'mt-2 pl-0 ml-2' : isInternal ? 'my-0 mx-2 p-0' : 'my-0 mr-2 ml-auto p-0'}
          title={t('spelling.capitalCenter')}
          {...getProps('centerIds')}
        />
        {!isInternal && (
          <DropdownFilter
            className={isMobile ? 'mt-2 pl-0 ml-2' : 'my-0 mx-2 p-0'}
            title="Contact Tags"
            {...getProps('contactTagIds')}
          />
        )}
        <IconButtonCircle
          icon={faTimes}
          onClick={() => clearFilters()}
          tooltipDirection="bottom"
          tooltipText="Clear Filters"
          className={isMobile ? 'mt-1 ml-4' : 'my-0 ml-2 p-0'}
        />
      </div>
    </TableHeader>
  );
};

interface IProps {
  contacts: IContact[];
  loading?: boolean;
  onRowClick?: (contact: IContact) => void;
  dataSize?: number;
  ktChildren?: {
    show?: boolean;
    onClick?: (childId: string) => void;
  };
  headerProps: Omit<IHeaderProps, 'paginationProps'>;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  onSort?: (field: string, direction: ElasticsearchSortDirection) => void;
}

const ContactsTableV2 = ({
  contacts,
  loading,
  onRowClick,
  ktChildren = { show: false },
  headerProps,
  dataSize,
  tableState,
  tableFunctions,
  onSort,
}: IProps) => {
  const handleRowClick = onRowClick ?? (() => {});
  const handleChildrenRowClick = ktChildren.onClick ?? (() => {});
  const handleOnFilterUpdate = (filters: FilterData) => {
    if (headerProps?.onFilterUpdate) headerProps.onFilterUpdate(filters);
    tableFunctions.changePage(TABLE_DEFAULTS.PAGE, tableState.pageSize);
  };

  return (
    <DataTable
      renderHeader={(paginationProps) => {
        return (
          <ContactsTableV2Header
            paginationProps={paginationProps}
            {...headerProps}
            onFilterUpdate={handleOnFilterUpdate}
          />
        );
      }}
      data={contacts}
      dataSize={dataSize}
      pageSize={tableState.pageSize}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      onSort={onSort}
      showSelect={false}
      activePage={tableState.activePage}
      showLoadingOverlay={loading}
      handleRowClick={(e, row: IContact) => {
        handleRowClick(row);
      }}
      columns={[
        {
          text: 'Name',
          dataField: nameof<IContact>((t) => t.lastname),
          classes: 'lg-column',
          sort: true,
          formatter: (_: any, row: IContact) => (
            <AvatarDataTableCell
              initials={getInitials(row)}
              avatar={row?.avatar?.url ?? ''}
              primaryText={getFullName(row)}
              secondaryText={row.email}
            />
          ),
        },
        {
          text: 'Primary Phone',
          dataField: nameof<IContact>((t) => t.primaryPhoneNumber!.number),
          classes: 'md-column',
          formatter: (pn: string) => (pn ? parsePhoneNumberWithRegion(pn).formatNational() : ''),
        },
        ...(!ktChildren.show
          ? []
          : [
              {
                text: 'Children',
                dataField: nameof<IContact>((t) => t.children),
                formatter: (children: IContactChild[]) =>
                  children.map((child) => (
                    <div
                      key={child.id}
                      /**TODO: This feature has never worked */
                      onClick={() => handleChildrenRowClick(child.id ?? 'ERROR: NO ID FOR GIVEN ROW')}
                    >
                      <PersonAvatar className="mr-2" person={child} size="md" />
                    </div>
                  )),
              },
            ]),
        {
          text: 'KT Connect Access',
          dataField: nameof<IContact>((t) => t.hasKtConnectAccess),
          classes: 'pl-12',
          formatter: (hasKtConnectAccess: boolean) => <div className="mx-auto">{hasKtConnectAccess ? 'Y' : 'N'}</div>,
        },
      ]}
    />
  );
};

export default ContactsTableV2;
