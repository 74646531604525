import { useCallback, useMemo, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Col, Row } from 'shared/components/Layout';
import moment from 'moment';
import DateInput from 'shared/components/DateInput';
import { capitalize } from 'shared/util/string';
import { showToast } from 'shared/components/Toast';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { useGetStartStrongReportLazyQuery } from 'generated/graphql';
import Alert from 'shared/components/Alert/Alert';
import Checkbox from 'shared/components/Checkbox';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

interface IForm {
  programFromDate: string;
  programToDate: string;
  centerIds: string[];
}

const initialState: IForm = {
  programFromDate: '',
  programToDate: '',
  centerIds: [],
};

export default function RunProgramReportModal({ isOpen, onClose }: Props) {
  const { t } = useTranslation(['subsidies', 'translation']);
  const [formData, setFormData] = useState<IForm>(initialState);
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const reportDataToFile = useReportDataToFile();

  const [getStartStrongReportFn, { loading }] = useGetStartStrongReportLazyQuery({
    onCompleted: (result) => {
      if (result.getStartStrongReport.csv)
        reportDataToFile.downloadCsvFromString(result.getStartStrongReport.csv, ReportTypeEnum.PROGRAMS);

      if (result.getStartStrongReport.errors.length > 0) setErrors(result.getStartStrongReport.errors);
    },
    onError: () => {
      showToast(t('translation:reports.general-failure-message'), 'error');
    },
  });

  const isValidFormData = () => {
    return (
      !!formData.centerIds && formData.centerIds.length > 0 && !!formData.programFromDate && !!formData.programToDate
    );
  };

  const startDate = moment(formData.programFromDate);
  const endDate = moment(formData.programToDate);

  const handleSubmit = useCallback(() => {
    if (formData.programFromDate && formData.programToDate) {
      getStartStrongReportFn({
        variables: {
          input: {
            centerIds: formData.centerIds,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
          },
        },
      });
    }
  }, [getStartStrongReportFn, formData, startDate, endDate]);

  return (
    <SideModalDrawer
      title={t('subsidies:run-report-start-strong')}
      show={isOpen}
      primaryChoice={capitalize(t('translation:spelling.create'))}
      primaryCallback={handleSubmit}
      primaryButtonProps={{ disabled: !isValidFormData(), loading }}
      onHide={() => {
        setFormData(initialState);
        if (onClose) {
          setErrors(undefined);
          onClose();
        }
      }}
      closeOnPrimaryCallback={false}
    >
      <Form>
        <Row className="mb-4">
          <Alert variant="info">
            This report utilises the eligible child details captured in the Agency program on their account.
          </Alert>
        </Row>
        <Row className="mb-4">
          <Col>
            <MultipleCenterSelect
              selectedCenterIds={formData.centerIds}
              onSelect={(ids) => setFormData((prev) => ({ ...prev, centerIds: ids as string[] }))}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <DateInput
              required
              label={t('subsidies:program.from-date')}
              date={formData['programFromDate']}
              onDateSelect={(input) =>
                setFormData((prev) => ({
                  ...prev,
                  programFromDate: !!input ? moment(input).format('YYYY-MM-DD') : input,
                }))
              }
              className="kt-date-input-no-max-width mb-4"
              dateOnly
            />
          </Col>
          <Col>
            <DateInput
              required
              label={t('subsidies:program.to-date')}
              date={formData['programToDate']}
              onDateSelect={(input) =>
                setFormData((prev) => ({
                  ...prev,
                  programToDate: !!input ? moment(input).format('YYYY-MM-DD') : input,
                }))
              }
              className="kt-date-input-no-max-width mb-4"
              dateOnly
            />
          </Col>
        </Row>
        {errors && (
          <Alert variant="danger">
            <div>
              <Row className="mb-4">The following errors occured while building the report:</Row>
              <Row>
                <ul>
                  {errors.map((e, idx) => {
                    return <li key={idx}>{e}</li>;
                  })}
                </ul>
              </Row>
            </div>
          </Alert>
        )}
      </Form>
    </SideModalDrawer>
  );
}
