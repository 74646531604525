import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import DataTable from 'shared/components/DataTable';
import { PersonAvatar } from 'shared/components/Avatar';
import { getFullName, parsePhoneNumberWithRegion } from 'shared/util/string';
import { useHistory } from 'react-router';
import AccountContactsTableExpandedRow from './AccountContactsTableExpandedRow';
import { orderBy } from 'lodash';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {
  contacts: IContact[];
  account: IAccount;
  onRemove: (c: IContact) => void;
  onEdit: (c: IContact) => void;
  onSetPrimary: (c: IContact) => void;
}

const AccountContactsTable: React.FC<IProps> = ({ contacts, account, onRemove, onEdit, onSetPrimary, ...props }) => {
  const history = useHistory();
  const hasReadChildPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasEditAccountContactPermission = useHasRoleAreaLevel({
    area: AreaType.Contact,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const hasDeleteAccountContactPermission = useHasRoleAreaLevel({
    area: AreaType.Contact,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });

  return (
    <div>
      <DataTable
        keyField="id"
        data={contacts}
        showPagination={false}
        showSelect={false}
        expandRow={(contact: IContact) => (
          <AccountContactsTableExpandedRow
            contact={contact}
            account={account}
            canEdit={hasEditAccountContactPermission}
            canRemove={hasDeleteAccountContactPermission}
            onEdit={onEdit}
            onRemove={onRemove}
            onSetPrimary={onSetPrimary}
          />
        )}
        columns={[
          {
            dataField: 'firstname',
            text: 'Name',
            sort: true,
            classes: 'lg-column',
            formatter: (name: string, contact: IContact) => (
              <Link to={`/families/contacts/${contact.id}`} className="d-flex align-items-center">
                <PersonAvatar size="md" person={contact} className="mr-4" />
                <div className="text-text-color">{getFullName(contact)}</div>
              </Link>
            ),
          },
          ...(hasReadChildPermission
            ? [
                {
                  dataField: 'children',
                  text: 'Children',
                  classes: 'xl-column',
                  formatter: (children: IContactChild[]) => (
                    <div className="d-flex">
                      {orderBy(children, 'firstname').map((c) => {
                        const accountPermissions = c.accountPermissions.find((ac) => ac.accountId === account.id);
                        const canPickup = accountPermissions?.permissions.includes('Pickup');
                        return accountPermissions ? (
                          <PersonAvatar
                            key={c.id}
                            onClick={() => history.push(`/families/children/${c.childId}`)}
                            size="md"
                            person={c}
                            className={classnames('mr-2', {
                              'blue-outline': canPickup,
                            })}
                          />
                        ) : null;
                      })}
                    </div>
                  ),
                },
              ]
            : []),
          {
            classes: 'md-column',
            dataField: 'primaryPhoneNumber.number',
            text: 'Phone Number',
            formatter: (pn: string) => (pn ? parsePhoneNumberWithRegion(pn).formatNational() : ''),
          },
        ]}
      />
    </div>
  );
};

export default AccountContactsTable;
