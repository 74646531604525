import React from 'react';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import ActionDropdown from 'shared/components/ActionDropdown';
import { IconButtonCircle } from 'shared/components/Buttons';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import Select from 'shared/components/Select';
import { GLCodeLabelPerType, GLCodeTypeOptions } from 'shared/constants/enums/glCodes';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState2';
import { capitalize } from 'shared/util/string';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IGLCodeTableProps {
  data: IGLCode[];
  totalResults: number;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  typeFilter: GLCodeAreaType | null;
  loading: boolean;
  handleSort: (field: string, direction: ElasticsearchSortDirection) => void;
  setTypeFilter: React.Dispatch<React.SetStateAction<GLCodeAreaType | null>>;
  onSearch: (searchText: string) => void;
  handleEdit: (glCode: IGLCode) => void;
  handleArchive: (glCode: IGLCode) => void;
}

const GLCodesTable: React.FC<IGLCodeTableProps> = ({
  data,
  totalResults,
  tableState,
  tableFunctions,
  typeFilter,
  loading,
  handleSort,
  setTypeFilter,
  onSearch,
  handleEdit,
  handleArchive,
}) => {
  const { t } = useTranslation();
  const canEditGLCodes = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Billing,
    level: RoleLevelType.Edit,
  });
  const canDeleteGLCodes = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Billing,
    level: RoleLevelType.Delete,
  });
  const handleClearFilter = () => {
    setTypeFilter(null);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  return (
    <DataTable
      data={data}
      dataSize={totalResults}
      noPadding
      showLoadingOverlay={loading}
      showSelect={false}
      pageSize={tableState.pageSize}
      onSort={handleSort}
      activePage={tableState.activePage}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      renderHeader={(paginationProps: any) => (
        <TableHeader className="d-flex justify-content-between">
          <div className="d-flex flex-direction-row align-items-center ">
            <SizePerPage paginationProps={paginationProps} />
            <TableSearch
              className="mr-4"
              onChange={(value) => {
                onSearch(value);
              }}
              placeholder="Search"
            />
          </div>
          <div className="d-flex">
            <Select
              placeholder={capitalize(t('spelling.type'))}
              options={GLCodeTypeOptions}
              value={typeFilter}
              onChange={(option) => {
                setTypeFilter(option.value);
              }}
              className="mr-4 flex-grow-0 my-2"
            />
            <IconButtonCircle
              icon={faTimes}
              onClick={handleClearFilter}
              disabled={!Boolean(typeFilter)}
              tooltipDirection="bottom"
              tooltipText="Clear Filters"
              className="ml-4"
            />
          </div>
        </TableHeader>
      )}
      columns={[
        {
          text: capitalize(t('spelling.name')),
          dataField: 'name',
          sort: true,
        },
        {
          text: capitalize(t('spelling.type')),
          dataField: 'type',
          sort: true,
          formatter: (cell: GLCodeAreaType) => GLCodeLabelPerType[cell],
        },
        {
          sort: true,
          text: capitalize(t('billing.settings.transactions.glCode')),
          dataField: 'glCode',
        },
        {
          text: capitalize(t('spelling.actions')),
          align: 'center',
          headerClasses: 'text-center',
          dataField: '',
          formatter: (cell: any, row: IGLCode) => (
            <ActionDropdown
              actions={[
                {
                  label: capitalize(t('spelling.edit')),
                  onClick: () => handleEdit(row),
                  disabled: !canEditGLCodes,
                },
                {
                  label: capitalize('Archive'),
                  onClick: () => handleArchive(row),
                  disabled: !canDeleteGLCodes,
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};

export default GLCodesTable;
