import React, { useState } from 'react';
import Card from 'shared/components/Card';
import newKioskApp from './updated-home-banner.svg';
import './_carousel.scss';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

const NewsCarousel: React.FC = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const { t } = useTranslation();

  return (
    <Card bodyClassName="pb-0">
      <Carousel
        className="k2-carousel hide-indicators hide-controls" // currently hidding nav indicators becuase we only have one item
        activeIndex={index}
        onSelect={handleSelect}
        slide={false}
        nextIcon={<FontAwesomeIcon icon={faAngleRight} size="2x" />}
        prevIcon={<FontAwesomeIcon icon={faAngleLeft} size="2x" />}
        interval={null}
      >
        <Carousel.Item>
          <div className="d-flex">
            <div className="pb-4">
              <h2 className="font-weight-light mb-6">Say Hello To Our Kiosk App</h2>
              <p>
                Your staff can easily clock in and out using the Kangarootime Kiosk app, with a simple entry of their
                PIN. Use the link below on any stationary tablet or PC to begin using this feature at your{' '}
                {t('spelling.center')}.
              </p>
              <a className="no-underline" target="_blank" href={t('kiosk.url')}>
                {t('kiosk.urlText')}
              </a>
            </div>
            <div>
              <img src={newKioskApp} width="400" alt="teacher in front of kiosk app" />
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </Card>
  );
};

export default NewsCarousel;
