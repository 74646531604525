import { get, orderBy } from 'lodash';
import React, { useCallback, useState } from 'react';
import Button from 'shared/components/Buttons';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import CentersWithAutomatedBatchesTable from './CentersWithAutomatedBatchesTable';
import Card from 'shared/components/Card';
import { Row } from 'shared/components/Layout';
import { TableSearch } from 'shared/components/DataTable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetCenterBatchingSettingsByBusiness } from 'gql/center/queries';
import { RootState } from 'store/reducers';
import { useUpdateCenterSettings } from 'gql/batch/mutations';
import { showToast } from 'shared/components/Toast';
import { useUpdateCenterSettingsForAllByBusinessId } from '../../../../../gql/batch/mutations';

interface IProps {}

const AutomatedBatchingTab: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'name',
    direction: 'asc',
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [tableData, setTableData] = useState<ICenterBatchingSetting[]>([]);

  const user = useSelector((state: RootState) => state.user);
  const businessId = user?.entityId ?? '';

  const { loading: getCenterListLoading } = useGetCenterBatchingSettingsByBusiness({
    variables: {
      input: {
        businessId,
      },
    },
    onCompleted: (result) => {
      result.getCenterBatchingSettingsByBusiness &&
        setTableData(
          result.getCenterBatchingSettingsByBusiness.map((x) => {
            return {
              centerId: x.centerId,
              center: x.center,
              enableAutomaticBatching: x.enableAutomaticBatching,
            } as ICenterBatchingSetting;
          }) ?? []
        );
    },
  });

  const filterData = useCallback(
    (arr: ICenterBatchingSetting[]): ICenterBatchingSetting[] => {
      if (searchTerm.trim() === '') {
        return orderBy(arr, (row) => get(row, sortField.field), [sortField.direction]);
      }

      return orderBy(
        arr.filter(
          (row) =>
            row.center.name.toLowerCase().includes(searchTerm) ||
            row.center.address?.city.toLowerCase().includes(searchTerm) ||
            row.center.address?.state.toLowerCase().includes(searchTerm)
        ),
        (row) => get(row, sortField.field),
        [sortField.direction]
      );
    },
    [searchTerm, sortField]
  );

  const [updateCenterSettings, { loading: updateCenterSettingsLoading }] = useUpdateCenterSettings({
    onCompleted: (result) => {
      showToast(t('billing.settings.automaticBatching.updateBatchingSettings.successMessage'), 'success');
      setTableData((prev) =>
        prev.map((setting) => {
          return {
            ...setting,
            ...(setting.centerId === result.updateCenterSettings.id && {
              enableAutomaticBatching: result.updateCenterSettings.enableAutomaticBatching,
            }),
          };
        })
      );
    },
    onError: (err) => {
      showToast(t('billing.settings.automaticBatching.updateBatchingSettings.errorMessage'), 'error');
    },
  });

  const handleAutomatedBatchingToggle = (centerId: string, automatedBatching: boolean) => {
    updateCenterSettings({
      variables: { input: { centerId, businessId, enableAutomaticBatching: automatedBatching } },
    });
  };

  const [updateAllCenterSettings, { loading: updateAllCenterSettingsLoading }] =
    useUpdateCenterSettingsForAllByBusinessId({
      onCompleted: (result) => {
        showToast(t('billing.settings.automaticBatching.updateBatchingSettingsForAll.successMessage'), 'success');
        setTableData((prev) =>
          prev.map((settings) => {
            return {
              ...settings,
              enableAutomaticBatching:
                result.updateCenterSettingsForAllByBusinessId.results.find((x) => x.id === settings.centerId)
                  ?.enableAutomaticBatching ?? false,
            };
          })
        );
      },
      onError: (err) => {
        showToast(t('billing.settings.automaticBatching.updateBatchingSettingsForAll.errorMessage'), 'error');
      },
    });

  const handleAutomatedBatchingForAllToggle = (automatedBatching: boolean) => {
    updateAllCenterSettings({
      variables: { input: { businessId, enableAutomaticBatching: automatedBatching } },
    });
  };

  return (
    <>
      <Card bodyClassName="px-8 py-4">
        <Row className="flex-nowrap">
          <TableSearch onChange={setSearchTerm} placeholder={t('spelling.searchCenters')} />
          <HasRoleAreaLevel
            action={{ area: AreaType.Billing, permission: PermissionType.Surcharges, level: RoleLevelType.Edit }}
          >
            <div className="d-flex flex-1 justify-content-end">
              <Button
                variant="outline-primary"
                className="mr-4"
                loading={updateAllCenterSettingsLoading}
                onClick={() => handleAutomatedBatchingForAllToggle(false)}
              >
                {t('billing.settings.automaticBatching.updateBatchingSettingsForAll.offButton')}
              </Button>
              <Button
                loading={updateAllCenterSettingsLoading}
                onClick={() => handleAutomatedBatchingForAllToggle(true)}
              >
                {t('billing.settings.automaticBatching.updateBatchingSettingsForAll.onButton')}
              </Button>
            </div>
          </HasRoleAreaLevel>
        </Row>
      </Card>
      <HasRoleAreaLevel
        action={{ area: AreaType.Billing, permission: PermissionType.Surcharges, level: RoleLevelType.Read }}
      >
        <CentersWithAutomatedBatchesTable
          isLoading={getCenterListLoading}
          data={filterData(tableData)}
          onCenterAutomatedBatchingToggle={handleAutomatedBatchingToggle}
          onSortColumn={(field: string, direction: 'asc' | 'desc') => setSortField({ field, direction })}
          noPadding={true}
        />
      </HasRoleAreaLevel>
    </>
  );
};

export default AutomatedBatchingTab;
