import axios from 'axios';
import config from '../../../../config';
import { useCallback, useState } from 'react';
import { useGetCurrentJwt } from '../../../../shared/hooks/user-context/useGetCurrentJwt';
import { showToast } from '../../../../shared/components/Toast';
import { useTranslation } from 'react-i18next';

interface DeleteAgencyPaymentOutput {
  showDeleteModal: boolean;
  handleDeleteButton: (paymentId: string) => void;
  hideModal: () => void;
  deleteAgencySubsidyPayment: () => void;
}

export function useDeleteAgencyPayment(loadData: () => {}): DeleteAgencyPaymentOutput {
  const authToken = useGetCurrentJwt();
  const { t } = useTranslation(['subsidies']);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paymentId, setPaymentId] = useState<string>('');
  const [reloadData, setReloadData] = useState(false);

  const handleDeleteButton = useCallback((paymentId: string) => {
    setPaymentId(paymentId);
    setReloadData(false);
    setShowDeleteModal(true);
  }, []);

  const hideModal = useCallback(() => {
    setPaymentId('');
    setShowDeleteModal(false);
  }, []);

  const deleteAgencySubsidyPayment = useCallback(() => {
    deleteAgencyPayment({ paymentId, authToken })
      .then(() => {
        showToast(t('subsidies:agency-payments.delete-success'), 'success');

        setPaymentId('');
        setShowDeleteModal(false);
        loadData();
      })
      .catch((error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      });
  }, [paymentId, authToken]);

  return {
    showDeleteModal,
    handleDeleteButton,
    hideModal,
    deleteAgencySubsidyPayment,
  };
}

async function deleteAgencyPayment({ paymentId, authToken }: { paymentId: string; authToken: string }) {
  const response = await axios.delete(`${config.api.billing.uri}/api/v2/agency-payments/${paymentId}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
  return response.data;
}
