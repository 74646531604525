import React, { useCallback, useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import TextInput from 'shared/components/TextInput';
import moment from 'moment';
import TimeControl from './TimeControl';
import cast from 'shared/util/cast';

interface IProps {
  value: moment.Moment | null;
  onChange: (value: moment.Moment) => void;
  label?: string;
  disabled?: boolean;
  className?: string;
  timezone?: string;
  required?: boolean;
}

const TimePicker: React.FC<IProps> = ({
  value,
  label,
  disabled = false,
  onChange,
  className,
  timezone,
  required,
  ...props
}) => {
  const hoursRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const minutesRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const time = value ? value.format('h:mm A') : 'HH:MM';
  const [hoursAndMinutes, AMPM] = time.split(' ');
  const [hours, minutes] = hoursAndMinutes.split(':');
  const isPM: boolean = AMPM === 'PM';
  const defaultTime = moment()
    .tz(timezone ?? moment.tz.guess())
    .hours(12)
    .minutes(0)
    .seconds(0);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // empty string in the input (ex: backspace current value), don't bubble changes
      if (!event.target.value) {
        return;
      }

      // initial value is null so create a new moment object
      const _value: moment.Moment = value || defaultTime;
      const handlingHours = event.currentTarget.name.match(/hours/);
      let inputValue: number = cast<number>(event.target.value);

      if (handlingHours) {
        if (inputValue > 12) {
          // cap at 12
          inputValue = 12;

          // compiler didn't like the optional chaining here
          if (hoursRef.current) {
            // keep the dispaly of hours between 1-12
            hoursRef.current.value = '12';
          }
        } else if (isPM) {
          // moment expects hours to be 0-23, add 12 (PM) and bubble
          // toString -> parseInt because adding 12 directly seemed to treat inputValue as a string
          inputValue = parseInt(inputValue.toString(), 10) + 12;
        }

        onChange(_value.clone().hours(inputValue));
      } else {
        // cap minutes at 59
        if (inputValue > 59) {
          inputValue = 59;

          // compiler didn't like the optional chaining here
          if (minutesRef.current) {
            minutesRef.current.value = '59';
          }
        }

        onChange(_value.clone().minutes(inputValue));
      }
    },
    [value, defaultTime, isPM, onChange]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // only listen for up and down arrow keys
      // 38 is up, 40 is down
      if (event.keyCode !== 38 && event.keyCode !== 40) {
        return;
      }

      // initial value is null so create a new moment object
      const _value: moment.Moment = value || defaultTime;

      const duration: number = event.keyCode === 38 ? 1 : -1;
      const unit: moment.DurationInputArg2 = event.currentTarget.name.match(/hours/) ? 'hour' : 'minute';

      if (event.currentTarget.name.match(/hours/)) {
        if (hoursRef.current) {
          hoursRef.current.value = `${_value.hours() + duration}`;
        }
      } else {
        if (minutesRef.current) {
          minutesRef.current.value = `${_value.minutes() + duration}`;
        }
      }

      onChange(_value.clone().add(duration, unit));
    },
    [value, defaultTime, onChange]
  );

  const changeHours = useCallback(
    (step: number) => {
      const _value: moment.Moment = value || defaultTime;

      // specific case here since there are AM/PM buttons that have a step of 12
      if (hoursRef.current && step === 1) {
        hoursRef.current.value = `${parseInt(hoursRef.current.value, 10) + step}`;
      }

      onChange(_value.clone().add(step, 'hour'));
    },
    [value, defaultTime, onChange]
  );

  const changeMinutes = useCallback(
    (step: number) => {
      const _value: moment.Moment = value || defaultTime;

      if (minutesRef.current) {
        minutesRef.current.value = `${parseInt(minutesRef.current.value, 10) + step}`;
      }

      onChange(_value.clone().add(step, 'minute'));
    },
    [value, defaultTime, onChange]
  );

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="bottom"
      overlay={
        <Popover id="test">
          <Popover.Content>
            <div className="d-flex flex-row justify-content-center align-items-center mb-2">
              <TimeControl
                ref={hoursRef}
                timeControlType="hours"
                placeholder="HH"
                value={hours}
                onButtonClick={changeHours}
                onKeyDown={handleKeyDown}
                onChange={handleOnChange}
              />
              <div className="text-center kt-timepicker-divider">:</div>
              <TimeControl
                ref={minutesRef}
                timeControlType="minutes"
                placeholder="MM"
                value={minutes}
                onButtonClick={changeMinutes}
                onKeyDown={handleKeyDown}
                onChange={handleOnChange}
              />
            </div>
            <ButtonGroup aria-label="AM-PM Toggle">
              <Button onClick={() => isPM && changeHours(-12)} variant={isPM ? 'light' : 'secondary'}>
                AM
              </Button>
              <Button onClick={() => !isPM && changeHours(12)} variant={isPM ? 'secondary' : 'light'}>
                PM
              </Button>
            </ButtonGroup>
          </Popover.Content>
        </Popover>
      }
    >
      <TextInput
        label={label}
        value={value ? value.format('h:mm A') : undefined}
        disabled={disabled}
        className={className}
        required={required}
        placeholder="HH:MM"
      />
    </OverlayTrigger>
  );
};

export default TimePicker;
