import { showConfirm } from 'shared/components/ConfirmationContainer';
import {
  ReenrollmentCloseRequestInput,
  ReEnrollmentCloseResponse,
  ReenrollmentGetAccountsFilterBy,
  ReenrollmentGetHistoryOfActionsFilterBy,
  SimplifiedAccountCorrespondence,
  useCloseReEnrollmentsMutation,
  useSendReEnrollmentMutation,
} from 'generated/graphql';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IProps {
  filterBy?: ReenrollmentGetHistoryOfActionsFilterBy;
  includeAccountCorrespondenceIds?: SimplifiedAccountCorrespondence[];
  includeAllAccountCorrespondenceIds?: boolean | undefined;
  displayNumber: number;
  refetchTable: () => void;
}

const ReEnrollHistoryBulkActions: React.FC<IProps> = ({
  filterBy,
  includeAccountCorrespondenceIds,
  includeAllAccountCorrespondenceIds,
  displayNumber,
  refetchTable,
}) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const businessId = useSelector((state: RootState) => state.context.businessId);

  const [closeReEnrollmentMutation, { data, loading, error }] = useCloseReEnrollmentsMutation({
    onError: (err) => showToast(getApolloErrorMessage(err), 'error'),
    onCompleted: (response) => {
      showToast(t('enrollment:reenroll-bulk-close.success'), 'success');
      refetchTable();
    },
  });

  const onConfirm = async () => {
    if (!filterBy) return;
    closeReEnrollmentMutation({
      variables: {
        businessId: businessId ?? '',
        input: {
          historyFilterBy: filterBy,
          includeAccountCorrespondenceIds: includeAccountCorrespondenceIds,
          includeAllAccountCorrespondenceIds: includeAllAccountCorrespondenceIds,
        },
      },
    });
  };

  const handleConfirm = () => {
    showConfirm({
      title: 'Bulk Close',
      message: (
        <p>
          This will close access to the {t('translation:spelling.enrollment')} request links previously emailed to
          parents who haven't already responded.
          <br />
          <br />
          Do you want to close these outstanding requests?
        </p>
      ),
      primaryButtonLabel: 'Close Requests',
      onConfirm: onConfirm,
    });
  };

  return (
    <div className="bg-white p-4 d-flex border-bottom" style={{ height: '73px' }}>
      <IconButtonCircle icon={faTimesCircle} className="mr-2" tooltipText="Close Requests" onClick={handleConfirm} />
    </div>
  );
};

export default ReEnrollHistoryBulkActions;
