import * as type from './types';
import { unionBy } from 'lodash';

export interface IProfilesStateShape {
  totalCreatedStaff: number;
  createdStaffData: IStaff[];
  totalInvitedStaff: number;
  invitedStaffData: IStaff[];
  totalRequestedStaff: number;
  requestedStaffData: IStaff[];
}

const initialState: IProfilesStateShape = {
  totalCreatedStaff: 0,
  createdStaffData: [],
  totalInvitedStaff: 0,
  invitedStaffData: [],
  totalRequestedStaff: 0,
  requestedStaffData: [],
};

export const profilesReducers = (
  state: IProfilesStateShape = initialState,
  action: type.ProfilesActionTypes
): IProfilesStateShape => {
  switch (action.type) {
    case type.GET_TOTAL_CREATED_STAFF_SUCCESS:
      return {
        ...state,
        totalCreatedStaff: action.totalCreatedStaff,
        createdStaffData: action.createdStaffData,
      };
    case type.GET_TOTAL_INVITED_STAFF_SUCCESS:
      return {
        ...state,
        totalInvitedStaff: action.totalInvitedStaff,
        invitedStaffData: action.invitedStaffData,
      };
    case type.GET_TOTAL_REQUESTED_STAFF_SUCCESS:
      return {
        ...state,
        totalRequestedStaff: action.totalRequestedStaff,
        requestedStaffData: action.requestedStaffData,
      };
    case type.INCREASE_TOTAL_CREATED_STAFF:
      return {
        ...state,
        totalCreatedStaff: state.totalCreatedStaff + 1,
      };
    case type.INCREASE_TOTAL_INVITED_STAFF:
      return {
        ...state,
        totalRequestedStaff: state.totalRequestedStaff + 1,
      };
    default:
      return state;
  }
};
