import React from 'react';

interface IProps {
  // string to allow for setting with a percentage, number to allow for setting with px
  height?: number | string;
  width?: number | string;
  className?: string;
}

const Box: React.FC<IProps> = ({ height = 100, width = '100%', className = '' }) => {
  return (
    <div
      className={`loading-skeleton ${className}`}
      style={{
        height,
        width,
      }}
    />
  );
};

export default Box;
