import React, { useState, useCallback, useEffect } from 'react';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import Tag from 'shared/components/Tag';
import { Row } from 'shared/components/Layout';
import { useGetContactTagOptions } from '../../../graphql/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useUpdateContactTags } from '../../../graphql/mutations';
import { showToast } from 'shared/components/Toast';

interface IProps {
  contact: IContact;
  readOnly?: boolean;
}

const TagsForm: React.FC<IProps> = ({ contact, readOnly = false }) => {
  const user = useSelector((state: RootState) => state.user);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [tags, setTags] = useState(contact.tags || []);
  const resetTags = useCallback(() => setTags(contact.tags || []), [contact.tags]);
  const entityId = user?.entityId || (contact.accounts[0] ? contact.accounts[0].entityId : '');
  const tagOptions = useGetContactTagOptions(entityId) || [];
  const selectedTagIds: string[] = tags.map((tag) => tag.id);
  const unselectedTags: ITag[] = tagOptions.filter((tag) => !selectedTagIds.includes(tag.id));
  const addTag = useCallback((selectedTag) => setTags([...tags, selectedTag]), [tags]);
  const removeTag = useCallback((selectedTag) => setTags(tags.filter((tag) => tag.id !== selectedTag.id)), [tags]);
  const [updateContactTags, { loading }] = useUpdateContactTags();

  useEffect(() => {
    setTags(contact.tags && contact.tags.length > 0 ? contact.tags : []);
  }, [contact]);

  const save = useCallback(() => {
    updateContactTags({
      variables: {
        input: { id: contact.id, tagIds: selectedTagIds },
      },
    })
      .then(() => {
        showToast('Contact tags updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch(() => {
        showToast('There was an error updating contact tags. Please try again later.', 'error');
      });
  }, [updateContactTags, contact.id, selectedTagIds]);

  return (
    <FormWrapper2
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onCancel={resetTags}
      onSave={save}
      loading={loading}
    >
      <small>Select a tag to apply it to this contact. Deselect a tag to remove it.</small>
      {tags && (
        <Row noGutters className="mx-n1 mt-4">
          {tags.map((tag, i) => (
            <div key={i} className="px-1 mb-2">
              <Tag
                editState="SELECTED"
                tag={tag}
                onClick={() => {
                  if (!readOnly) {
                    setFormIsDirty(true);
                    removeTag(tag);
                  }
                }}
              />
            </div>
          ))}
          {unselectedTags.map((tag, i) => (
            <div key={i} className="px-1 mb-2">
              <Tag
                editState="DESELECTED"
                tag={tag}
                onClick={() => {
                  if (!readOnly) {
                    setFormIsDirty(true);
                    addTag(tag);
                  }
                }}
              />
            </div>
          ))}
        </Row>
      )}
    </FormWrapper2>
  );
};

export default TagsForm;
