import { isUndefined } from 'lodash';
import React, { useCallback } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import Button from 'shared/components/Buttons';
import weekDayOptions, { IDayOption } from 'shared/constants/enums/weekDayOptions';
import './dayInput.scss';

export interface IProps {
  value: WeekDay[];
  options?: IDayOption[];
  onDaySelect?: (day: WeekDay) => void;
  classNames?: string;
  allowedDays?: WeekDay[];
  maxEnrolmentDays?: number;
}

const DayInput: React.FC<IProps> = ({
  options,
  value,
  onDaySelect,
  allowedDays,
  classNames = '',
  maxEnrolmentDays,
}) => {
  const dayOptions = options ?? weekDayOptions;

  const handleSelect = (day: WeekDay) => {
    if (onDaySelect) {
      onDaySelect(day);
    }
  };

  const shouldDayDisable = useCallback(
    (day: WeekDay) => {
      let hasSelectedAllRequiredDays = false;
      let isDayNotInWhiteList = false;

      if (!isUndefined(maxEnrolmentDays)) {
        hasSelectedAllRequiredDays = value.length === maxEnrolmentDays && !value.includes(day);
      }

      if (!isUndefined(allowedDays)) {
        isDayNotInWhiteList = !allowedDays.includes(day);
      }

      if (hasSelectedAllRequiredDays) {
        return true;
      }

      if (isDayNotInWhiteList) {
        return true;
      }

      return false;
    },
    [maxEnrolmentDays, allowedDays, value]
  );

  return (
    <ButtonGroup className={`mb-4 day-input ${classNames}`}>
      {dayOptions.map((day, i) => {
        const isSelected = value.includes(day.value);

        return (
          <Button
            key={i}
            className="px-4"
            variant={isSelected ? 'secondary' : 'light'}
            disabled={shouldDayDisable(day.value)}
            onClick={() => {
              handleSelect(day.value);
            }}
          >
            {day.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default DayInput;
