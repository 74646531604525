import DataTable, { TableHeader } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import PageWrapper from 'shared/components/PageWrapper';
import Select from 'shared/components/Select';
import { useMemo } from 'react';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import ActionDropdown from 'shared/components/ActionDropdown';
import { defaultPageSize } from '../hooks/usePagination';
import './ViewAccountsToReconcilePage.scss'; //Styles
import { ReconciliationAccount } from '../models/ReconciliationAccount';
import SearchInput from 'shared/components/SearchInput';

const TableColumns: { dataField: string; text: string; sort: boolean; formatter?: () => {} }[] = [
  {
    dataField: 'accountName',
    text: 'Account',
    sort: false,
  },
  {
    dataField: 'agencyName',
    text: 'Agency',
    sort: false,
  },
  {
    dataField: 'centerName',
    text: 'Center',
    sort: false,
  },
  {
    dataField: '',
    text: '',
    sort: false,
    formatter: () => (
      <ActionDropdown
        actions={[
          {
            label: 'View/Edit',
            onClick: () => {},
          },
        ]}
      />
    ),
  },
];

interface IProps {
  currentPage: number;
  pageSize: number;
  pageSizeOptions: ISelectMenuItem[];
  totalDataSize: number;

  agencyOptions: ISelectMenuItem[];
  selectedAgencies: ISelectMenuItem[];

  centerOptions: ISelectMenuItem[];
  selectedCenters: ISelectMenuItem[];

  statusOptions: ISelectMenuItem[];
  selectedStatus: string[];

  searchString: string;

  accountsToReconcile: ReconciliationAccount[];

  onClearFilters: () => void;
  onPageSizeChange: (numberToShow: number) => void;
  onPageChange: (page: number) => void;
  onSearchChange: (searchString: string) => void;
  onSelectedAgenciesChange: (selectedAgencyIds: ISelectMenuItem[]) => void;
  onSelectedCentersChange: (selectedCenterIds: ISelectMenuItem[]) => void;
  onSelectedStatusesChange: (selectedStatus: string[]) => void;
}

const DefaultProps: IProps = {
  currentPage: 1,
  pageSize: defaultPageSize,
  pageSizeOptions: [],
  totalDataSize: 0,

  agencyOptions: [],
  selectedAgencies: [],
  centerOptions: [],
  selectedCenters: [],
  statusOptions: [],
  selectedStatus: [],
  accountsToReconcile: [],
  onClearFilters: () => {},
  onPageChange: () => {},
  onPageSizeChange: () => {},
  onSearchChange: () => {},
  onSelectedAgenciesChange: () => {},
  onSelectedCentersChange: () => {},
  onSelectedStatusesChange: () => {},
  searchString: '',
};

export const ReconciliationsPage: React.FC<Partial<IProps>> = (props: Partial<IProps>) => {
  const propsWithDefaults: IProps = useMemo(() => ({ ...DefaultProps, ...props }), [props]);
  const {
    pageSize,
    currentPage,
    totalDataSize,
    accountsToReconcile,
    pageSizeOptions,
    onPageSizeChange,
    onPageChange,
    centerOptions,
    selectedCenters,
    onSelectedCentersChange,
    searchString,
    onSearchChange,
    selectedAgencies,
    agencyOptions,
    onSelectedAgenciesChange,
    onClearFilters,
  } = propsWithDefaults;

  return (
    <PageWrapper pageTitle="Agency Reconciliations">
      <DataTable
        renderHeader={() => (
          <ReconciliationsTableHeader
            pageSizeOptions={pageSizeOptions}
            selectedPageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            centerOptions={centerOptions}
            selectedCenters={selectedCenters}
            onSelectedCenterChange={onSelectedCentersChange}
            searchText={searchString}
            onSearchChange={onSearchChange}
            agencyOptions={agencyOptions}
            selectedAgencyOptions={selectedAgencies}
            onAgencySelectChange={onSelectedAgenciesChange}
            onClearClicked={onClearFilters}
          ></ReconciliationsTableHeader>
        )}
        columns={TableColumns}
        data={accountsToReconcile}
        dataSize={totalDataSize}
        showSelect={false}
        pageSize={pageSize}
        onSizePerPageChange={onPageSizeChange}
        activePage={currentPage}
        onPageChange={(page) => onPageChange(page)}
      ></DataTable>
    </PageWrapper>
  );
};

function ReconciliationsTableHeader({
  pageSizeOptions = [],
  selectedPageSize,
  onPageSizeChange = () => {},
  centerOptions = [],
  selectedCenters = [],
  onSelectedCenterChange = () => {},
  searchText = '',
  onSearchChange = () => {},
  selectedAgencyOptions = [],
  agencyOptions = [],
  onAgencySelectChange = () => {},
  onClearClicked = () => {},
}: Partial<{
  pageSizeOptions: IProps['pageSizeOptions'];
  selectedPageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  centerOptions: ISelectMenuItem[];
  selectedCenters: ISelectMenuItem[];
  onSelectedCenterChange: (selectedCenterValues: ISelectMenuItem[]) => void;
  searchText: string;
  onSearchChange: (searchText: string) => void;
  selectedAgencyOptions: ISelectMenuItem[];
  agencyOptions: ISelectMenuItem[];
  onAgencySelectChange: (selections: ISelectMenuItem[]) => void;
  onClearClicked: () => void;
}>) {
  const selectedPageSizeOption = useMemo(
    () => pageSizeOptions.find((o) => o.value === selectedPageSize),
    [pageSizeOptions, selectedPageSize]
  );
  return (
    <TableHeader className="reconciliation-accounts__table-header">
      <Select
        className="reconciliations_amount_selector"
        label="Show"
        options={pageSizeOptions}
        onChange={({ value }) => onPageSizeChange(value)}
        value={selectedPageSizeOption}
      ></Select>
      <SearchInput className={`mb-0`} onChange={onSearchChange} placeholder={'Search Accounts'} value={searchText} />
      <div className="buffer"></div>
      <DropdownFilter
        options={centerOptions}
        title="Center"
        onFilterSelect={onSelectedCenterChange}
        selectedFilters={selectedCenters}
      ></DropdownFilter>
      <DropdownFilter
        options={agencyOptions}
        title="Agency"
        onFilterSelect={onAgencySelectChange}
        selectedFilters={selectedAgencyOptions}
      ></DropdownFilter>
      <IconButtonCircle icon={faTimes} onClick={onClearClicked} tooltipDirection="bottom" tooltipText="Clear Filters" />
    </TableHeader>
  );
}
