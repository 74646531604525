import React from 'react';
import Checkbox from './Checkbox';
import Combo from './Combo';
import Document from './Document';
import Radio from './Radio';
import Text from './Text';
import Address from './Address';
import HtmlText from './HtmlText';
import Signature from './Signature';
import { CustomFieldType, ICustomField } from 'shared/types/customField';

export interface ICustomFieldProps {
  customField: ICustomField;
  value: any;
  isReadOnly?: boolean;
  disabled?: boolean;
  entityId: string;
  onChange: (value: any, id: string) => void;
}

interface IProps extends ICustomFieldProps {
  type: CustomFieldType;
}

const CustomField: React.FC<IProps> = ({
  type,
  customField,
  value,
  onChange,
  isReadOnly = false,
  disabled = false,
  entityId,
}) => {
  const components: Record<CustomFieldType, React.FC<ICustomFieldProps>> = {
    Combo: Combo,
    Text: Text,
    Document: Document,
    Radio: Radio,
    Checkbox: Checkbox,
    Address: Address,
    HtmlText: HtmlText,
    MultiSelectCombo: Combo,
    ContactSelector: Text, // not for k2-console, using Text as placeholder
    Date: Text, // not for k2-console, using Text as placeholder
    ChildrenSelector: Combo, // not for k2-console, using Combo as placeholder
    GroupedFields: Text, //not for k2-console, using Text as placeholder
    Signature: Signature,
    MedicareText: Text, //not for k2-console, using Text as placeholder
    DateText: Text, //not for k2-console, using Text as placeholder
    ChildrenCheckboxSelector: Text, //not for k2-console, using Text as placeholder
    PhoneNumber: Text, //For now this is not for K2-console
  };
  const Component: React.FC<ICustomFieldProps> = components[type];

  return (
    <Component
      customField={customField}
      value={value}
      disabled={disabled}
      isReadOnly={isReadOnly}
      onChange={onChange}
      entityId={entityId}
    />
  );
};
export default CustomField;
