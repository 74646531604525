import React from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'shared/components/Layout';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import Checkbox from 'shared/components/Checkbox';
import Select from 'shared/components/Select';
import { useTranslation } from 'react-i18next';
import { customFieldAreaOptions } from 'shared/constants/enums/customFieldAreaOptions';
import { customFieldTypeOptions } from 'shared/constants/enums/customFieldTypeOptions';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import HtmlText from 'shared/components/CustomField/HtmlText';

export interface INewCustomFieldForm {
  businessId: string;
  centerIds: string[] | null;
  label: string;
  area: string;
  displayOrder: number | undefined;
  notForConsoleDisplay: boolean;
  type: string;
  attributes: string;
}

interface IProps {
  formValues: INewCustomFieldForm;
  isEdit?: boolean;
  handleInput: (field: string, value: any) => void;
  disable?: { [key in keyof Partial<INewCustomFieldForm>]: boolean };
}

const CustomFieldForm: React.FC<IProps> = ({ handleInput, formValues, disable, isEdit = false }) => {
  const { t } = useTranslation();
  return (
    <Form id="new-custom-field-form">
      <Row>
        <Col>
          <MultipleCenterSelect
            disabled={disable?.centerIds ?? false}
            selectedCenterIds={formValues.centerIds}
            onSelect={(value) => handleInput('centerIds', value)}
            useNullForAllOption={true}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <TextInput
            required
            name="label"
            label={t('custom-data-fields.label-label-text')}
            onChange={(value) => handleInput('label', value)}
            value={formValues.label}
          ></TextInput>
        </Col>
      </Row>

      <Row>
        <Col>
          <Select
            required
            options={customFieldAreaOptions}
            placeholder="Area"
            disabled={disable?.area}
            name="area"
            label={t('custom-data-fields.area-label-text')}
            onChange={(option) => handleInput('area', option.value)}
            value={formValues.area}
          ></Select>
        </Col>
      </Row>

      <Row>
        <Col>
          <NumberInput
            type="number"
            name="displayOrder"
            min="0"
            label={t('custom-data-fields.displayorder-label-text')}
            onChange={(value) => handleInput('displayOrder', value)}
            value={formValues.displayOrder ?? 0}
            numberFormat={{ allowNegative: false }}
          ></NumberInput>
        </Col>
      </Row>

      <Row>
        <Col>
          <Select
            menuPlacement="top"
            required
            disabled={disable?.type}
            options={customFieldTypeOptions}
            placeholder="Type"
            name="type"
            label={t('custom-data-fields.type-label-text')}
            onChange={(option) => {
              handleInput('type', option.value);

              if (option.value === 'HtmlText' || option.value === 'Signature') {
                handleInput('notForConsoleDisplay', true);
              }
            }}
            value={formValues.type}
          ></Select>
        </Col>
      </Row>
      {(formValues.type === 'Combo' || formValues.type === 'Radio' || formValues.type === 'MultiSelectCombo') && (
        <Row>
          <Col>
            <TextInput
              required={
                formValues.type === 'Combo' || formValues.type === 'Radio' || formValues.type === 'MultiSelectCombo'
              }
              name="attributes"
              label={t('custom-data-fields.attributes-label-text')}
              onChange={(value) => handleInput('attributes', value)}
              value={formValues.attributes}
              as="textarea"
              rows={4}
              helpTooltipText={t('custom-data-fields.info-options-in-lines')}
              placeholder={t('custom-data-fields.info-options-in-lines-eg')}
            ></TextInput>
          </Col>
        </Row>
      )}
      {formValues.type === 'HtmlText' && (
        <Row>
          <Col>
            <HtmlText
              customField={{
                businessId: formValues.businessId,
                displayOrder: 0,
                notForConsoleDisplay: true,
                label: '',
                centerIds: formValues.centerIds ?? [],
                id: '',
                area: formValues.area,
                type: 'HtmlText',
                attributesAsString: formValues.attributes ?? '',
              }}
              onChange={(value) => handleInput('attributes', value)}
              value={formValues.attributes}
            ></HtmlText>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Checkbox
            label={t('custom-data-fields.notforconsoledisplay-label-text')}
            onChange={(value) => handleInput('notForConsoleDisplay', value)}
            value={formValues.notForConsoleDisplay}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default CustomFieldForm;
