import { useStore } from 'react-redux';
import config from 'config';
import MicroFrontend from 'MicroFrontend';

export const AnalyticsFE = ({ history }) => {
  const store = useStore().getState();
  localStorage.setItem('token', store.session.token);
  localStorage.setItem('user', JSON.stringify(store.user));
  return (
    <MicroFrontend
      host={config['dashboard-fe-url']}
      name="Dashboard"
      history={history}
      key={Date.now()}
      pathname={history?.location?.pathname ?? '/'}
      fetchConfig
    />
  );
};
