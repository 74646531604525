import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Row } from 'shared/components/Layout';
import Alert from 'shared/components/Alert';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { setAccountBillingCycle } from 'pages/Families/subroutes/Accounts/duck/actions';
import { useCreateAccountBillingCycle, useUpdateAccountBillingCycle } from 'gql/billingCycle/mutations';
import { showToast } from 'shared/components/Toast';
import Tooltip from 'shared/components/Tooltip';
import useFormatDate from 'shared/hooks/useFormatDate';
import ConfirmPausePaymentModal from './ConfirmPausePaymentModal';
import errors from 'shared/constants/errorMessages';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import PeriodBasedBillingCycleSentence from 'pages/Billing/Settings/Tabs/Cycles/PeriodBasedBillingCycleSentence';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { billingCycleFields, billingCycleTemplateFields } from 'gql/billingCycle/fields';
import CyclePreview from './CyclePreview/CyclePreview';
import RadioButton from 'shared/components/RadioButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useBillingCyclePreview } from './CyclePreview/UsePreviewCalendar';
import { Card, CardContent, CardHeader } from '@mui/material';

const accountBillingCycleQuery = gql`
  query($accountId: ID!) {
    getAccountBillingCycle(accountId: $accountId) {
      ${billingCycleFields}
    }
  }
`;

const queryForBillingCycleTemplatesForAccount = gql`
  query($accountId: ID!) {
    getBillingCycleTemplatesForAccount(accountId: $accountId) {
      ${billingCycleTemplateFields}
    }
  }
`;

interface IProps {
  account: IAccount;
  accountId: string;
}

const BillingInformationCard: React.FC<IProps> = ({ account, accountId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatDate = useFormatDate();
  const { k2AccountBillingSetupBillingCycleCalendar } = useFlags();

  const [createBillingCycleFn, { loading: createBillingCycleLoading }] = useCreateAccountBillingCycle({
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });
  const [updateBillingCycleFn, { loading: updateBillingCycleLoading }] = useUpdateAccountBillingCycle({
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const newBillingCycle = useMemo(
    () => ({ accountId, isPaused: false, startDate: moment().format('YYYY-MM-DD') }),
    [accountId]
  );

  const hasEditAccountBillingCyclePermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.AccountBillingCycles,
    level: RoleLevelType.Edit,
  });
  const hasCreateAccountBillingCyclePermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.AccountBillingCycles,
    level: RoleLevelType.Create,
  });

  const {
    templates,
    loading: templatesLoading,
    selectedTemplate,
    setSelectedTemplateId,
  } = useAvailableBillingCycleTemplates(accountId);

  // Form State
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [transactionLimit, setTransactionLimit] = useState<number>();
  const [accountDataErrors, setAccountDataErrors] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>();
  const [isFormDirty, setFormDirty] = useState(false);
  const [isConfirmPausePaymentModalOpen, setConfirmPausePaymentModalOpen] = useState(false);
  const [savedBillingCycle, setSavedBillingCycle] = useState<any>();

  const setFormDataFromBillingCycle = useCallback(
    (billingCycle) => {
      setIsPaused(billingCycle.isPaused);
      setSelectedTemplateId(billingCycle.billingCycleTemplateId);
      setTransactionLimit(billingCycle.transactionLimit || undefined);
      setEndDate(billingCycle.endDate);
      setStartDate(billingCycle.startDate);
    },
    [setSelectedTemplateId]
  );

  const { loading } = useQuery(accountBillingCycleQuery, {
    variables: { accountId },
    onCompleted: (data) => {
      if (data.getAccountBillingCycle) {
        setSavedBillingCycle(data.getAccountBillingCycle);
        setFormDataFromBillingCycle(data.getAccountBillingCycle);
      }
    },
    onError: () => {
      setAccountDataErrors(true);
    },
  });

  const handleUpdateTemplate = useCallback(
    (template: IBillingCycleTemplate) => {
      setSelectedTemplateId(template.id);
      setFormDirty(true);
    },
    [setSelectedTemplateId]
  );

  const handleUpdateTransactionLimit = useCallback((newLimit: number | null | undefined) => {
    setTransactionLimit(newLimit || undefined);
    setFormDirty(true);
  }, []);

  const handleUpdatePausePayments = useCallback((newPausePayments: boolean) => {
    setIsPaused(newPausePayments);
    setFormDirty(true);
  }, []);

  const resetForm = useCallback(() => {
    setFormDirty(false);
    setFormDataFromBillingCycle(savedBillingCycle);
  }, [savedBillingCycle, setFormDataFromBillingCycle]);

  const billingCycleToShow = useMemo(() => {
    if (isFormDirty && selectedTemplate) return selectedTemplate;
    return savedBillingCycle ?? newBillingCycle;
  }, [isFormDirty, newBillingCycle, savedBillingCycle, selectedTemplate]);

  const {
    events,
    calendarDate,
    setCalendarDate,
    refetch: refetchCalendar,
    loading: loadingCalendarEvents,
  } = useBillingCyclePreview(accountId, isFormDirty ? billingCycleToShow : undefined);

  const submitForm = useCallback(() => {
    // perform create/update mutation depending if cycle has an id
    // dispatch result to redux via setAccountBillingCycle
    if (!selectedTemplate) return;
    if (!savedBillingCycle || !savedBillingCycle.id) {
      createBillingCycleFn({
        variables: {
          input: {
            accountId: accountId,
            startDate: moment().format('YYYY-MM-DD'),
            period: selectedTemplate.period,
            frequency: selectedTemplate.frequency,
            dayOfWeek: selectedTemplate.dayOfWeek,
            dayOfMonth: selectedTemplate.dayOfMonth,
            invoiceSchedule: {
              unit: selectedTemplate.invoiceSchedule.unit,
              value: selectedTemplate.invoiceSchedule.value,
            },
            additionalChargePeriod: { value: 0, unit: 'DAYS' },
            transactionLimit,
            isPaused,
            billingCycleTemplateId: selectedTemplate.id ?? '',
          },
        },
      }).then((res) => {
        if (res?.data?.createAccountBillingCycle) {
          dispatch(setAccountBillingCycle(res.data.createAccountBillingCycle));
          setFormDataFromBillingCycle(res.data.createAccountBillingCycle);
          showToast('Successfully set billing cycle.', 'success');
          setFormDirty(false);
          refetchCalendar();
        }
      });
    } else {
      updateBillingCycleFn({
        variables: {
          input: {
            id: savedBillingCycle.id,
            startDate: moment().format('YYYY-MM-DD'),
            period: selectedTemplate.period,
            frequency: selectedTemplate.frequency,
            dayOfWeek: selectedTemplate.dayOfWeek,
            dayOfMonth: selectedTemplate.dayOfMonth,
            invoiceSchedule: {
              unit: selectedTemplate.invoiceSchedule.unit,
              value: selectedTemplate.invoiceSchedule.value,
            },
            additionalChargePeriod: { value: 0, unit: 'DAYS' },
            transactionLimit,
            isPaused,
            billingCycleTemplateId: selectedTemplate.id ?? '',
          },
        },
      }).then((res) => {
        if (res?.data?.updateAccountBillingCycle) {
          dispatch(setAccountBillingCycle(res.data.updateAccountBillingCycle));
          setFormDataFromBillingCycle(res.data.updateAccountBillingCycle);
          showToast('Successfully updated billing cycle.', 'success');
          setFormDirty(false);
          refetchCalendar();
        }
      });
    }
  }, [
    selectedTemplate,
    savedBillingCycle,
    createBillingCycleFn,
    accountId,
    transactionLimit,
    isPaused,
    dispatch,
    setFormDataFromBillingCycle,
    refetchCalendar,
    updateBillingCycleFn,
  ]);

  // End Form State

  const canEditCycle = useMemo(() => {
    return savedBillingCycle ? hasEditAccountBillingCyclePermissions : hasCreateAccountBillingCyclePermissions;
  }, [hasCreateAccountBillingCyclePermissions, hasEditAccountBillingCyclePermissions, savedBillingCycle]);

  const isSaveDisabled = useMemo(() => {
    return !selectedTemplate || createBillingCycleLoading || updateBillingCycleLoading;
  }, [createBillingCycleLoading, selectedTemplate, updateBillingCycleLoading]);

  const showFailureToRetrieveDataError = useMemo(() => {
    return (accountDataErrors || !account) && !loading;
  }, [account, accountDataErrors, loading]);

  const showBillingOffWarning = useMemo(() => {
    return !accountDataErrors && account && !account?.center?.enableBillingCycleProcessing;
  }, [account, accountDataErrors]);

  const showIsPausedWarning = useMemo(() => {
    return (
      !accountDataErrors && account && account?.center?.enableBillingCycleProcessing && savedBillingCycle?.isPaused
    );
  }, [account, accountDataErrors, savedBillingCycle?.isPaused]);

  return (
    <>
      {savedBillingCycle && !savedBillingCycle.isPaused && (
        <Alert className="mb-4" variant="info" style={{ borderRadius: '6px' }}>
          <div>
            <div>
              <strong>Current Billing Setup:</strong>
            </div>
            <div>Next bill will go out {formatDate(savedBillingCycle.nextInvoiceDate, 'MMM Do, YYYY')}.</div>
            <div>
              Next payment due on {formatDate(savedBillingCycle.nextPaymentDate, 'MMM Do, YYYY')} for current balance +
              services during {formatDate(savedBillingCycle.nextBillingPeriod.start, 'MMM Do, YYYY')} -{' '}
              {formatDate(savedBillingCycle.nextBillingPeriod.end, 'MMM Do, YYYY')}.
            </div>
          </div>
        </Alert>
      )}
      <Card variant="outlined">
        <CardHeader title="Billing Information" />
        <CardContent>
          <FormWrapper2
            formIsDirty={isFormDirty}
            toggleDirty={setFormDirty}
            onSave={() => {
              if (isPaused) {
                setConfirmPausePaymentModalOpen(true);
              } else {
                submitForm();
              }
            }}
            onCancel={resetForm}
            loading={loading || createBillingCycleLoading || updateBillingCycleLoading}
            saveDisabled={isSaveDisabled}
          >
            {showFailureToRetrieveDataError && (
              <Alert className="box-shadow-0 mb-6 mt-n4" variant="danger">
                Something went wrong. Unable to retrieve this account's data.
              </Alert>
            )}
            {!accountDataErrors && account && !savedBillingCycle && (
              <Alert className="box-shadow-0 mb-6 mt-n4" variant="danger">
                This account does not have a billing cycle assigned.
              </Alert>
            )}
            {showBillingOffWarning && (
              <Alert className="box-shadow-0 mb-6 mt-n4" variant="warning">
                {t('billing.account.billing-cycle.center-disabled-billing-cycle')}
              </Alert>
            )}
            {showIsPausedWarning && (
              <Alert className="box-shadow-0 mb-6 mt-n4" variant="warning">
                {t('billing.account.billing-cycle.itemized-billing-paused')}
              </Alert>
            )}
            <div className="mb-4">
              <Tooltip
                text="Freeze payments collected from an account. Charges to balance will continue to accumulate."
                direction="top"
              >
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                    <label className="form-label mb-0">Pause Payments?</label>
                    <RadioButton
                      className="radio-margin-0 pt-0"
                      label="No"
                      value={Boolean(!isPaused)}
                      onChange={() => handleUpdatePausePayments(false)}
                      disabled={loading || !canEditCycle}
                    />
                    <RadioButton
                      className="radio-margin-0 pt-0"
                      label="Yes"
                      value={Boolean(isPaused)}
                      onChange={() => handleUpdatePausePayments(true)}
                      disabled={loading || !canEditCycle}
                    />
                  </div>
                </div>
              </Tooltip>
            </div>
            <Row noGutters align="end" justify="between">
              <div>
                <Tooltip text="Individual transaction limit, processed to an account." direction="top">
                  <CurrencyInput2
                    small
                    label="Transaction Limit"
                    value={transactionLimit}
                    onChange={handleUpdateTransactionLimit}
                    className="mb-4"
                    disabled={loading || !canEditCycle}
                  />
                </Tooltip>
              </div>
            </Row>
            <div className="mb-4">
              <Row noGutters>
                <DateInput
                  label={t('billing.account.billing-cycle.period-based-start-date-input-label')}
                  date={startDate}
                  disabled={true}
                  className="mr-4 mb-0"
                />
                <DateInput
                  label={t('billing.account.billing-cycle.period-based-end-date-input-label')}
                  date={endDate}
                  disabled={true}
                  className="mb-0"
                />
              </Row>
              {
                <small>
                  * For period based billing cycles the start date aligns with the first active contract on an account
                  and the end date aligns with the last active contract.
                </small>
              }
            </div>
            <Select
              label="Billing Cycle"
              placeholder="Select the billing cycle you want to use"
              options={templates}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.id}
              value={selectedTemplate}
              onChange={handleUpdateTemplate}
              isLoading={templatesLoading || loading}
              required={true}
              disabled={!canEditCycle || templatesLoading || loading}
              className="mb-6"
            />
            <h6 className="mb-4">What does this mean for you... </h6>
            <PeriodBasedBillingCycleSentence
              readOnly
              cycle={billingCycleToShow}
              onUpdate={() => {}}
              showNextCycleInformation={false}
            />
            {k2AccountBillingSetupBillingCycleCalendar && !isPaused && (
              <CyclePreview
                events={events}
                calendarDate={calendarDate}
                onCalendarDateChange={setCalendarDate}
                loading={loadingCalendarEvents}
              />
            )}
            {isSaveDisabled && isFormDirty && <small className="ml-auto">{errors.formRequirements}</small>}
          </FormWrapper2>
        </CardContent>
      </Card>
      <ConfirmPausePaymentModal
        isOpen={isConfirmPausePaymentModalOpen}
        onClose={() => {
          setConfirmPausePaymentModalOpen(false);
        }}
        onConfirm={submitForm}
      />
    </>
  );
};

function useAvailableBillingCycleTemplates(accountId: string) {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const { data: templateData, loading } = useQuery(queryForBillingCycleTemplatesForAccount, {
    variables: { accountId },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const templates = React.useMemo(() => templateData?.getBillingCycleTemplatesForAccount ?? [], [templateData]);
  const selectedTemplate = useMemo(() => {
    return templates.find((t) => t.id === selectedTemplateId) ?? null;
  }, [templates, selectedTemplateId]);
  return { loading, templates: templates, selectedTemplateId, setSelectedTemplateId, selectedTemplate };
}

export default BillingInformationCard;
