import React from 'react';
import { capitalize, isEmpty } from 'lodash';
import * as Mui from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import ChannelMessagesTableRow from './ChannelMessagesTableRow';

interface IProps {
  channelId: string;
  messageList: any[] | [];
  messageListLoading: boolean;
  from: number;
  setFrom: (x: number) => void;
  currentPage: number;
  setCurrentPage: (x: number) => void;
  pageRecordSize: number;
  setPageRecordSize: (x: number) => void;
  totalItems: number;
  refetchChannel: () => void;
  // nameSortDirection: string;
  // setNameSortDirection: (x: string) => void;
}

const columns = [
  { name: 'Date and Time', displayLabel: true, alignLeft: true },
  { name: 'Sender', displayLabel: true, alignLeft: true },
  { name: 'Sender Type', displayLabel: true, alignLeft: true },
  { name: 'Edit', displayLabel: false, alignLeft: false },
];

const ChannelMessagesTable: React.FC<IProps> = ({
  channelId,
  messageList,
  messageListLoading,
  from,
  setFrom,
  currentPage,
  setCurrentPage,
  pageRecordSize,
  setPageRecordSize,
  totalItems,
  refetchChannel,
  ...props
}) => {
  // const [order, setOrder] = React.useState<Order>('asc');
  // const [orderBy, setOrderBy] = React.useState<keyof Data>('name');

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    setFrom(newPage + 1);
  };

  const StyledTableCell = Mui.styled(Mui.TableCell)({
    paddingBottom: 0,
  });

  return (
    <>
      {messageListLoading ? (
        <LoadingLines number={4} />
      ) : (
        <Mui.Box sx={{ width: '100%' }}>
          <Mui.Paper sx={{ mb: 2 }} elevation={0}>
            <Mui.TableContainer>
              <Mui.Table aria-labelledby="royalty-fees-table" style={{ width: '100%' }}>
                <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
                  <Mui.TableRow sx={{ border: 'none' }}>
                    {columns.map((column) => (
                      <Mui.TableCell key={column.name} align={column.alignLeft ? 'left' : 'center'} padding={'normal'}>
                        <Mui.Typography variant="tableHeadCells">{column.displayLabel && column.name}</Mui.Typography>
                      </Mui.TableCell>
                    ))}
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {!messageListLoading &&
                    messageList.map((row, index) => (
                      <ChannelMessagesTableRow
                        key={`ChannelMessagesTableRow-${index}`}
                        channelId={channelId}
                        row={row}
                        index={index}
                        refetchChannel={refetchChannel}
                      />
                    ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
            {isEmpty(messageList) && (
              <Mui.Box
                sx={{ width: '100%', margin: 0 }}
                display="flex"
                bgcolor="white"
                alignItems="center"
                justifyContent="center"
              >
                <Mui.Typography variant="body1" padding={'16px'}>
                  This channel currently has no messages.
                </Mui.Typography>
              </Mui.Box>
            )}
            <Mui.TablePagination
              sx={{
                '.MuiTablePagination-displayedRows': {
                  margin: 0,
                  padding: 0,
                },
              }}
              rowsPerPageOptions={[10]}
              component="div"
              count={totalItems}
              rowsPerPage={pageRecordSize}
              page={currentPage}
              onPageChange={handleChangePage}
            />
          </Mui.Paper>
        </Mui.Box>
      )}
    </>
  );
};

export default ChannelMessagesTable;
