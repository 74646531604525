import COUNTRY_INFO, { DEFAULT_COUNTRY } from '../dropdownOptions/countryInfo';
const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const reasonForContractEndingOptions: { value: ReasonForContractEnding; label: string }[] = [
  { value: 'ROOM_CHANGE', label: 'Changing Rooms' },
  { value: 'CONTRACT_CHANGE', label: 'Change of Contract' },
  { value: 'LEAVE_CENTER', label: `Leaving ${fieldLabels.center.toLowerCase()}` },
  { value: 'TEMPORARY_ENROLLMENT', label: `Temporary ${fieldLabels.enrollment}` },
  { value: 'ACCOUNT_DEBT', label: 'Account Debt' },
  { value: 'ADDING_DAYS', label: 'Adding Days' },
  { value: 'DECREASING_DAYS', label: 'Decreasing days' },
  { value: 'CARE_NOT_REQUIRED', label: 'Care not required' },
  { value: 'ENDING_CARE', label: 'Ending Care' },
  { value: 'MOVING_AWAY', label: 'Moving Away' },
  { value: 'EXTENDED_HOLIDAYS', label: 'Extended Holidays' },
  { value: 'GOING_TO_SCHOOL', label: 'Going to School' },
  { value: 'GOING_TO_KINDERGARTEN', label: 'Going to Kindergarten' },
  { value: 'COVID', label: 'COVID' },
  { value: 'CHANGING_TO_CASUAL', label: 'Changing to Casual' },
  { value: 'CHANGING_TO_PERMANENT', label: 'Changing to Permanent' },
  { value: 'EXTENDED_TIME_OFF', label: 'Extended Time Off' },
  { value: 'EXTENDED_MEDICAL', label: 'Extended Medical' },
  { value: 'CENTER_DISMISSAL', label: 'Center Dismissal' },
  { value: 'RE_ENROLLMENT', label: `Re-${fieldLabels.enrollment.toLowerCase()}` },
];

export default reasonForContractEndingOptions;
