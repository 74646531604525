import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import useUploadFile from 'shared/hooks/useUploadFile';
import { GET_STAFF } from 'gql/staff/queries';
import { updateContactSuccess } from 'pages/Families/subroutes/Contacts/duck/actions';
import { useDispatch } from 'react-redux';
import { updateChildSuccess } from 'pages/Families/subroutes/Children/duck/actions';

interface IAvatarUpdateInput {
  id: string;
  avatar: File;
  useNewUpdate?: boolean;
}

interface IAvatarUpdateResponse {
  loading: boolean;
  data: any; // whatever is returned from the mutation
  error: any;
}

// anything in K2 that can have an avatar set, each needs a corresponding mutation
type AvatarUpdateTypes = 'entity' | 'center' | 'staff' | 'child' | 'contact' | 'logo';
type AvatarUpdateFn = (input: IAvatarUpdateInput) => Promise<void>;

/* MUTAITONS */
const UPDATE_CENTER_AVATAR = gql`
  mutation ($input: UpdateAvatarInput!) {
    updateCenterAvatar(input: $input) {
      id
      avatar {
        url
      }
    }
  }
`;

const UPDATE_CENTER_LOGO = gql`
  mutation ($input: UpdateAvatarInput!) {
    updateCenterLogo(input: $input) {
      id
      avatar {
        url
      }
    }
  }
`;

const UPDATE_STAFF_AVATAR = gql`
  mutation ($input: UpdatePersonAsStaff!) {
    updateStaffProfile(input: $input) {
      id
      avatar {
        url
      }
    }
  }
`;

const UPDATE_ENTITY_AVATAR = gql`
  mutation ($input: UpdateBusinessAvatarInput!) {
    updateBusinessAvatar(input: $input) {
      id
      avatar {
        url
      }
    }
  }
`;

const UPDATE_CONTACT_AVATAR = gql`
  mutation ($input: UpdateContactAvatarInput!) {
    updateContactAvatar(input: $input) {
      id
      avatar {
        url
      }
    }
  }
`;

const UPDATE_CHILD_AVATAR = gql`
  mutation ($input: UpdateChildAvatarInput!) {
    updateChildAvatar(input: $input) {
      id
      avatar {
        url
      }
    }
  }
`;

/**
 * Hooks responsible for updating an avatar (person, child, center, etc.)
 * The provided type determines which mutation to execute
 */
const useUpdateAvatar = (type: AvatarUpdateTypes): [AvatarUpdateFn, IAvatarUpdateResponse] => {
  const [response, setResponse] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const dispatch = useDispatch();

  const [updateStaffAvatar] = useMutation(UPDATE_STAFF_AVATAR, {
    update(cache: any, { data: { updateStaffProfile } }: any) {
      const cachedResponse = cache.readQuery({
        query: GET_STAFF,
        variables: { id: updateStaffProfile.id },
      });

      if (cachedResponse && cachedResponse.getStaff) {
        cache.writeQuery({
          query: GET_STAFF,
          data: { getStaff: { ...cachedResponse.getStaff, avatar: updateStaffProfile.avatar } },
        });
      }
    },
  });

  const [updateCenterAvatar] = useMutation(UPDATE_CENTER_AVATAR);
  const [updateCenterLogo] = useMutation(UPDATE_CENTER_LOGO);
  const [updateEntityAvatar] = useMutation(UPDATE_ENTITY_AVATAR);

  const [updateContactAvatar] = useMutation(UPDATE_CONTACT_AVATAR, {
    onCompleted: (data: { updateContactAvatar: IContact }) => {
      dispatch(updateContactSuccess(data.updateContactAvatar));
    },
  });

  const [updateChildAvatar] = useMutation(UPDATE_CHILD_AVATAR, {
    onCompleted: (data: { updateChildAvatar: IChild }) => {
      dispatch(updateChildSuccess(data.updateChildAvatar));
    },
  });

  let updateAvatarMutation: any;

  // determine which mutation to execute
  switch (type) {
    case 'center':
      updateAvatarMutation = updateCenterAvatar;
      break;
    case 'staff':
      updateAvatarMutation = updateStaffAvatar;
      break;
    case 'entity':
      updateAvatarMutation = updateEntityAvatar;
      break;
    case 'contact':
      updateAvatarMutation = updateContactAvatar;
      break;
    case 'child':
      updateAvatarMutation = updateChildAvatar;
      break;
    case 'logo':
      updateAvatarMutation = updateCenterLogo;
      break;
    default:
      break;
  }

  const [itemId, setItemId] = useState<string | null>(null);
  const [uploadFileFn, s3Key] = useUploadFile();
  const [useNew, setUseNew] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (s3Key && itemId) {
          // temporarily set type to any until we have type definitions for these actions
          const res: any = await updateAvatarMutation({
            variables: {
              input: {
                id: itemId,
                avatar: s3Key,
                useNewUpdate: useNew,
              },
            },
          });

          setResponse({
            loading: false,
            data: res.data,
            error: null,
          });
        }
      } catch (error: any) {
        setResponse({
          loading: false,
          data: null,
          error,
        });
      }
    })();
  }, [s3Key, itemId, updateAvatarMutation]);

  const updateAvatarFn: AvatarUpdateFn = useCallback(
    async (avatarUpdates) => {
      setResponse({
        loading: true,
        data: null,
        error: null,
      });

      try {
        if (!avatarUpdates.avatar || !avatarUpdates.id) {
          throw new Error('Missing id or avatar property');
        }

        // should be true but just incase
        if (avatarUpdates.avatar instanceof File) {
          await uploadFileFn(avatarUpdates.avatar);
          setItemId(avatarUpdates.id);
          setUseNew(avatarUpdates?.useNewUpdate ?? false);
        }
      } catch (error: any) {
        setResponse({
          error,
          loading: false,
          data: null,
        });
      }
    },
    [uploadFileFn]
  );

  return [updateAvatarFn, response];
};

export default useUpdateAvatar;
