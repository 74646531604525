import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { healthProfessionalFields } from './fields';

interface IGetHealthProfessionalByAccountIdData {
  getHealthProfessionalByAccountId: IHealthProfessional[];
}

interface IGetHealthProfessionalByChildIdData {
  getHealthProfessionalByChildId: IHealthProfessional[];
}

interface IGetHealthProfessionalByAccountIdVariables {
  accountId: string;
}

interface IGetHealthProfessionalByChildIdVariables {
  childId: string;
}

export const GET_HEALTH_PROFESSIONAL_BY_CHILD_ID = gql`
  query ($childId: ID!) {
    getHealthProfessionalByChildId(childId: $childId) {
      ${healthProfessionalFields}
    }
  }
`;

export const GET_HEALTH_PROFESSIONAL_BY_ACCOUNT_ID = gql`
  query ($accountId: ID!) {
    getHealthProfessionalByAccountId(accountId: $accountId) {
      ${healthProfessionalFields}
    }
  }
`;

export const useGetHealthProfessionalByChildId = (childId: string) =>
  useQuery<IGetHealthProfessionalByChildIdData, IGetHealthProfessionalByChildIdVariables>(
    GET_HEALTH_PROFESSIONAL_BY_CHILD_ID,
    {
      variables: {
        childId,
      },
    }
  );

export const useGetHealthProfessionalByAccountId = (accountId: string) =>
  useQuery<IGetHealthProfessionalByAccountIdData, IGetHealthProfessionalByAccountIdVariables>(
    GET_HEALTH_PROFESSIONAL_BY_ACCOUNT_ID,
    {
      variables: {
        accountId,
      },
    }
  );
