import { calculateDifferenceBetweenTimes } from 'shared/util/timeUtils';

export const getAdjustedMinutesWorked = (timeEntries: ITimeEntry[]): number => {
  // all null time out time entries have been filtered
  const totalTimeWorked = timeEntries.reduce<number>((acc, curr) => {
    if (curr.trackedTimeOut !== null) {
      return (acc += calculateDifferenceBetweenTimes(curr.trackedTimeIn, curr.trackedTimeOut!, 'minutes'));
    }
    return acc;
  }, 0);
  return totalTimeWorked;
};
