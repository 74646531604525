import React from 'react';
import { MenuItem } from 'shared/components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

interface IProps {
  label: string;
  icon: IconDefinition;
  onClick: () => void;
}

const ProfileDropdownMenuItem: React.FC<IProps> = ({ label, icon, onClick, ...props }) => {
  return (
    <MenuItem className="d-flex flex-row align-items-center" onClick={onClick}>
      <FontAwesomeIcon size="lg" icon={icon} color={colors['dark-gray']} />
      <span className="d-block ml-2">{label}</span>
    </MenuItem>
  );
};

export default ProfileDropdownMenuItem;
