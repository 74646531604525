import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DateInput from 'shared/components/DateInput';
import Col from 'shared/components/Layout/Col';
import Row from 'shared/components/Layout/Row';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import Checkbox from 'shared/components/Checkbox';
import { accsDocumentTypeEnum } from 'shared/constants/enums/ccssEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { TinyDeleteButton } from 'shared/components/Buttons';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { get, orderBy, uniqBy } from 'lodash';
import { useAdviseChildNoLongerAtRisk } from 'gql/ccssCertificates/mutations';
import { ApolloError } from '@apollo/client';
import { updateCertificate, updateDetermination } from 'pages/Families/subroutes/Accounts/duck/actions';
import DeclarationAccordion, { DeclarationType } from './DeclarationAccordion';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  account: IAccount;
  modalControlsProps?: IAdviseChildNoLongerAtRiskInput;
  baseCertificateInput?: IAdviseChildNoLongerAtRiskInput;
  title?: string;
  onValidateForm?: (
    setFormErrors: (error: IFormErrors) => void,
    setIsFormValid: (isValid: boolean) => void,
    certificate: IAdviseChildNoLongerAtRiskInput
  ) => void;
  onSubmit?: (certificate: IAdviseChildNoLongerAtRiskInput, account: IAccount, handleClose: () => void) => void;
  loadingExternal?: boolean;
  certificateId?: string;
  determinationId?: string;
}

const adviseChildNoLongerAtRiskInput: IAdviseChildNoLongerAtRiskInput = {
  businessId: '',
  accountId: '',
  centerId: '',
  dateNoLongerAtRisk: '',
  certificateId: '',
  determinationId: '',
  isDeclarationGiven: false,
  recordToSupportNoLongerAtRisk: false,
  noLongerAtRiskReason: '',
};

const yesNoOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const acceptedFileExts = ['.pdf', '.png', '.tiff', '.jpg'];

export interface IFormErrors {
  businessId: string;
  accountId: string;
  centerId: string;
  certificateId: string;
  determinationId: string;
  isDeclarationGiven: string;
  dateNoLongerAtRisk: string;
  recordToSupportNoLongerAtRisk: string;
  noLongerAtRiskReason: string;
  supportingDocuments: {
    file: string;
    error: string;
  }[];
}

export const getEmptyErrorState = (): IFormErrors => {
  const errors: IFormErrors = {
    businessId: '',
    accountId: '',
    centerId: '',
    certificateId: '',
    determinationId: '',
    isDeclarationGiven: '',
    dateNoLongerAtRisk: '',
    recordToSupportNoLongerAtRisk: '',
    noLongerAtRiskReason: '',
    supportingDocuments: [],
  };

  return errors;
};

const areFormFieldsValid = (certificate: IAdviseChildNoLongerAtRiskInput): [boolean, IFormErrors] => {
  const errors = getEmptyErrorState();
  let hasError = false;

  if (!certificate.dateNoLongerAtRisk) {
    errors.dateNoLongerAtRisk = 'Date no longer at risk is required';
    hasError = true;
  }

  if (!certificate.noLongerAtRiskReason) {
    errors.noLongerAtRiskReason = 'No longer at tisk reason is required';
    hasError = true;
  }

  if (!certificate.isDeclarationGiven) {
    errors.isDeclarationGiven = 'You must declare the information provided is true and correct';
    hasError = true;
  }

  if (!!certificate.recordToSupportNoLongerAtRisk && !certificate.supportingDocuments) {
    errors.recordToSupportNoLongerAtRisk = 'Supporting documents is required';
    hasError = true;
  }

  for (const doc of certificate.supportingDocuments ?? []) {
    if (!doc.type) {
      hasError = true;
      errors.supportingDocuments.push({
        error: 'Document type is required',
        file: doc.file.name,
      });
    }
  }

  return [!hasError, errors];
};

const ChildNotAtRiskModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  account,
  modalControlsProps,
  baseCertificateInput = adviseChildNoLongerAtRiskInput,
  title = 'Child no longer at risk',
  certificateId,
  determinationId,
  onValidateForm,
  onSubmit,
  loadingExternal,
}) => {
  const dispatch = useDispatch();
  const [childNoLongerAtRisk, setChildNoLongerAtRisk] = useState({ ...baseCertificateInput });
  const [isFormValid, setIsFormValid] = useState(true);
  const [formErrors, setFormErrors] = useState<IFormErrors>(getEmptyErrorState());

  const [adviseChildNoLongerAtRisk, { loading: loadingCreate, error }] = useAdviseChildNoLongerAtRisk();

  const loading = loadingExternal ?? loadingCreate;

  const validateForm = useCallback(() => {
    if (onValidateForm) {
      // use suppled onValidateForm if provided.
      onValidateForm(setFormErrors, setIsFormValid, childNoLongerAtRisk);
      return;
    }

    const [isValid, errors] = areFormFieldsValid(childNoLongerAtRisk);
    setFormErrors(errors);
    setIsFormValid(isValid);
    console.info('Form Errors: ', errors);
    console.info('Form Is Valid: ', isValid);
  }, [childNoLongerAtRisk]);

  useEffect(() => {
    validateForm();
  }, [childNoLongerAtRisk, validateForm]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleContinue = () => {
    validateForm();
    if (!isFormValid) {
      return;
    }
    if (onSubmit) {
      // Use onSubmit provided if it exists
      onSubmit(childNoLongerAtRisk, account, handleClose);
      return;
    }

    const adviseChildNoLongerAtRiskInput = {
      ...childNoLongerAtRisk,
      businessId: account.entityId,
      accountId: account.id,
      centerId: account.centerId,
      certificateId,
      determinationId,
    };

    adviseChildNoLongerAtRisk({
      variables: {
        input: { ...adviseChildNoLongerAtRiskInput },
      },
    })
      .then((data) => {
        console.log(data);
        if (!!certificateId) {
          if (!!data.data?.certificate) {
            dispatch(updateCertificate(account.id, data.data?.certificate));
          }
        } else {
          if (!!data.data?.determination) {
            dispatch(updateDetermination(account.id, data.data?.determination));
          }
        }
        showToast('Child no longer at risk', 'success');
        handleClose();
      })
      .catch((error) => {
        if (error instanceof ApolloError) {
          showToast(
            `${error.graphQLErrors
              .map((err) => {
                // @ts-ignore
                return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
              })
              .join(', ')}`,
            'error'
          );
        } else {
          showToast(error, 'error');
        }
      });
  };

  const handleChange = useCallback(
    (value, name) => {
      setChildNoLongerAtRisk({ ...childNoLongerAtRisk, [name]: value });
    },
    [childNoLongerAtRisk]
  );

  const setSelectedFiles = (files: File[]) => {
    // When you select the same file twice it duplicates in the file list, so we only use unique filenames.
    // Also the user can select AllFiles so filter out the files we don't support
    const acceptedFiles = uniqBy(files, (f) => f.name).filter((f) =>
      acceptedFileExts.includes('.' + (f.name.split('.').pop()?.toLowerCase() ?? ''))
    );

    const supportingFiles: ISupportingDocument[] = acceptedFiles.map((file) => {
      return {
        file: file,
        type: undefined,
      };
    });
    setChildNoLongerAtRisk({
      ...childNoLongerAtRisk,

      supportingDocuments: supportingFiles,
    });
  };

  const onFileDeleted = (fileName: string) => {
    const newSupportingDocuments = childNoLongerAtRisk.supportingDocuments?.filter(
      (doc) => !(doc.file.name === fileName)
    );
    setChildNoLongerAtRisk({
      ...childNoLongerAtRisk,
      supportingDocuments: newSupportingDocuments,
    });
  };

  // @ts-ignore
  return (
    <CenteredModal
      size="lg"
      show={isOpen}
      onHide={handleClose}
      title={title}
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryChoice="Submit"
      primaryCallback={handleContinue}
      primaryButtonProps={{ disabled: !isFormValid, loading }}
    >
      <Form>
        <Row>
          <Col>
            <DateInput
              className="kt-account-ccs-tab child-no-longer-at-risk-date"
              required
              label="Date No Longer At Risk"
              isOutsideRange={(day) => day.day() !== moment().day('Sunday').day()}
              onDateSelect={(value) => handleChange(value, 'dateNoLongerAtRisk')}
              date={childNoLongerAtRisk.dateNoLongerAtRisk}
              // @ts-ignore
              {...modalControlsProps?.dateNoLongerAtRisk}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextInput
              label="No longer at risk reason"
              name="no-longer-at-risk-reason"
              value={childNoLongerAtRisk.noLongerAtRiskReason}
              onChange={(value) => handleChange(value, 'noLongerAtRiskReason')}
              required
              as="textarea"
              rows={3}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Select
              required
              name="recordToSupportNoLongerAtRisk"
              label="Do you have records to support that the child is no longer at risk"
              value={
                childNoLongerAtRisk.recordToSupportNoLongerAtRisk === undefined
                  ? undefined
                  : childNoLongerAtRisk.recordToSupportNoLongerAtRisk
                  ? 'true'
                  : 'false'
              }
              options={yesNoOptions}
              onChange={(value) => {
                handleChange(
                  get({ false: false, true: true }, value.value, undefined),
                  'recordToSupportNoLongerAtRisk'
                );
              }}
              // @ts-ignore
              {...modalControlsProps?.recordToSupportNoLongerAtRisk}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <div className="d-flex flex-row">
              <h5>Supporting Documents</h5>
              {childNoLongerAtRisk.recordToSupportNoLongerAtRisk && (
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <FileSelectDropbox
              onChange={setSelectedFiles}
              value={childNoLongerAtRisk.supportingDocuments?.map((d) => d?.file)}
              showFiles={false}
              acceptedFileExts={acceptedFileExts.join(',')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            {orderBy(childNoLongerAtRisk.supportingDocuments ?? [], (c) => c.file.name).map((doc, i) => {
              return (
                <Row key={doc.file.name} className="file-display m-0">
                  <Col md="6">
                    <h6>{doc.file.name}</h6>
                  </Col>
                  <Col md="5">
                    <Select
                      className="my-auto"
                      placeholder="Document Type"
                      options={orderBy(accsDocumentTypeEnum, (e) => e.label)}
                      value={doc.type}
                      onChange={(value) => {
                        const otherFiles = childNoLongerAtRisk.supportingDocuments?.filter((d) => d !== doc) ?? [];
                        const newSupportDocs: ISupportingDocument[] = [
                          ...otherFiles,
                          { file: doc.file, type: value.value },
                        ];
                        setChildNoLongerAtRisk({
                          ...childNoLongerAtRisk,
                          supportingDocuments: newSupportDocs,
                        });
                        //doc.type = value;
                      }}
                      required
                    />
                  </Col>
                  <Col md="1">
                    <TinyDeleteButton onClick={() => onFileDeleted(doc.file.name)} />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <DeclarationAccordion
              declarationType={certificateId ? DeclarationType.Certficate : DeclarationType.Determination}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <div className="d-flex flex-row">
              <Checkbox
                label="I declare that the information provided is true and correct"
                value={childNoLongerAtRisk.isDeclarationGiven}
                onChange={(value) => handleChange(value, 'isDeclarationGiven')}
                // @ts-ignore
                {...modalControlsProps?.isDeclarationGiven}
              />
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
          </Col>
        </Row>
      </Form>
    </CenteredModal>
  );
};

export default ChildNotAtRiskModal;
