import { faTimes } from '@fortawesome/pro-light-svg-icons';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AvatarContent from 'shared/components/Avatar/AvatarContent';
import { IconButtonCircle } from 'shared/components/Buttons';
import ActionDropdown from 'shared/components/ActionDropdown';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import Select from 'shared/components/Select';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import useFormatDate from 'shared/hooks/useFormatDate';
import { getInitials, stringToHsl } from 'shared/util/string';
import colors from '_colors.module.scss';
import { useHistory } from 'react-router-dom';
import { RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useArchiveProgram } from 'gql/programChild/mutation';
import { WarningModal } from 'shared/components/Modals/WarningModal/WarningModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { showToast } from 'shared/components/Toast';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SubsidySchemeDto } from 'generated/graphql';

interface ITableProps {
  loading: boolean;
  data: IProgram[];
  dataSize?: number;
  pageSize?: number;
  activePage?: number;
  classes?: IClass[];
  selectedClasses?: string[];
  subsidySchemes?: SubsidySchemeDto[];
  selectedSubsidyScheme?: string | null;
  statusFilter?: string | null;
  onStatusChange: (status: string) => void;
  clearFilters: () => void;
  onSchemeSelect: (subsidySchemeId: string) => void;
  onClassesSelect: (classes: string[]) => void;
  onSearch: (searchText: string) => void;
  onPageChange?: (page: number, sizePerPage: number) => void;
  onSizePerPageChange?: (sizePerPage: number) => void;
  onSort?: (field: string, direction: ElasticsearchSortDirection) => void;
  refetchPrograms: () => void;
}

const ProgramsTable: React.FC<ITableProps> = ({
  data,
  dataSize,
  pageSize,
  activePage,
  classes,
  loading,
  selectedClasses,
  subsidySchemes,
  selectedSubsidyScheme,
  statusFilter,
  onStatusChange,
  clearFilters,
  onSchemeSelect,
  onClassesSelect,
  onPageChange,
  onSizePerPageChange,
  onSort,
  onSearch,
  refetchPrograms,
}) => {
  const { t } = useTranslation(['translation', 'subsidies']);
  const formatDate = useFormatDate();
  const history = useHistory();
  const businessId = useSelector<RootState, string | null>((state: RootState) => state.context.businessId);
  const [archiveProgramFn, { loading: isArchivingProgram }] = useArchiveProgram();
  const [showWarning, setShowWarning] = useState(false);
  const [warningContext, setWarningContext] = useState<{ count: number; okFn: () => void; cancelFn?: () => void }>({
    count: 0,
    okFn: () => {},
  });

  const actionsForRow = useCallback(
    (program: IProgram): IDropdownAction[] => {
      return [
        {
          label: 'Edit',
          onClick: () => history.push(`/centers/programs/${program.id}`),
          permission: { permission: 'Base', level: RoleLevelType.Edit, area: 'Agency' },
        },
        {
          label: t('translation:core.capitalize', { value: t('translation:spelling.archive') }),
          onClick: () => {
            if (program.programChildren.length > 0) {
              setWarningContext({
                count: program.programChildren.length,
                okFn: () =>
                  archiveProgramFn({
                    variables: {
                      input: {
                        businessId: businessId!,
                        programId: program.id,
                      },
                    },
                  }).then((v) => {
                    showToast(t('translation:general.updateSuccessful'), 'success');
                    setShowWarning(false);
                    setWarningContext({ count: 0, okFn: () => {} });
                    refetchPrograms();
                  }),
              });
              setShowWarning(true);
            } else {
              archiveProgramFn({
                variables: {
                  input: {
                    businessId: businessId!,
                    programId: program.id,
                  },
                },
              }).then((v) => {
                showToast(t('translation:general.updateSuccessful'), 'success');
                refetchPrograms();
              });
            }
          },
          permission: { permission: 'Base', level: RoleLevelType.Delete, area: 'Agency' },
        },
      ];
    },
    [setShowWarning, archiveProgramFn, refetchPrograms]
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <DataTable
        data={data}
        dataSize={dataSize}
        pageSize={pageSize}
        activePage={activePage}
        onPageChange={onPageChange}
        handleRowClick={() => {}}
        onSizePerPageChange={onSizePerPageChange}
        showLoadingOverlay={loading}
        showSelect={false}
        showPagination={true}
        expandHeaderColumnRenderer={(props) => <></>}
        expandRow={() => <div> </div>}
        renderHeader={(paginationProps: any, searchProps: any) => (
          <TableHeader className="flex-wrap align-items-center justify-content-between">
            <div className="d-flex flex-direction-row flex-wrap align-items-center">
              <SizePerPage paginationProps={paginationProps} />
              <TableSearch
                className={isMobile ? 'mt-2 mb-1 mr-4' : 'mr-4'}
                onChange={(value) => {
                  onSearch(value);
                }}
                placeholder="Search"
              />
            </div>
            <div className="d-flex flex-direction-row flex-wrap align-items-center mb-0">
              <DropdownFilter
                title="Class"
                className={isMobile ? 'mr-2 my-1 mb-0' : 'mr-4 mb-0'}
                selectedFilters={selectedClasses}
                options={classes?.map((b) => ({ label: b.name, value: b.id })) || []}
                onFilterSelect={(centerClasses) => onClassesSelect(centerClasses?.map((c) => c.value))}
              />
              <Select
                options={['Active', 'Inactive']}
                placeholder="Status"
                value={statusFilter}
                onChange={(status: string) => onStatusChange(status)}
                className={isMobile ? 'mr-2 my-1 mb-0' : 'mr-4 mb-0'}
              />
              <Select
                options={subsidySchemes || []}
                placeholder="Subsidy Scheme"
                getOptionLabel={(subsidyScheme: ISubsidyScheme) => subsidyScheme.name}
                getOptionValue={(subsidyScheme: ISubsidyScheme) => subsidyScheme.id}
                value={selectedSubsidyScheme}
                onChange={(subsidyScheme: ISubsidyScheme) => onSchemeSelect(subsidyScheme.id)}
                className="mr-4 mb-0 align-items-center"
              />
              <IconButtonCircle
                icon={faTimes}
                onClick={clearFilters}
                tooltipDirection="bottom"
                tooltipText="Clear Filters"
                className="align-items-center"
              />
            </div>
          </TableHeader>
        )}
        onSort={onSort}
        columns={[
          {
            //@ts-ignore
            text: t('subsidies:program.name'),
            dataField: 'name',
            sort: true,
          },
          {
            text: t('subsidies:program.educators'),
            dataField: 'programTeachers',
            formatter: (cell: string, row: IProgram) => (
              <div style={{ height: 48 }}>
                {row.programTeachers.map((teacher: IProgramTeacher, index: number) => {
                  const { staff, staffId, id, firstName, lastName, qualificationStatus } = teacher;
                  return (
                    <div
                      className="avatar d-inline-block float-none"
                      key={staffId}
                      style={{
                        marginLeft: index > 0 ? '-10px' : 0,
                      }}
                    >
                      <AvatarContent
                        color={stringToHsl(id ?? '')}
                        initials={getInitials(staff)}
                        image={staff?.avatar?.url ?? ''}
                        size="md"
                        alt={`${(staff?.nickname ?? staff?.firstname ?? firstName ?? '').toUpperCase().charAt(0)}${(
                          staff?.lastname ??
                          lastName ??
                          ''
                        )
                          .toUpperCase()
                          .charAt(0)}`}
                      />
                    </div>
                  );
                })}
              </div>
            ),
          },
          {
            text: t('subsidies:program.start-date'),
            sort: true,
            dataField: 'startDate',
            formatter: (cell: string, row: IProgram) => formatDate(row.startDate, 'MMM-D-YYYY').toUpperCase(),
          },
          {
            text: t('subsidies:program.end-date'),
            sort: true,
            dataField: 'endDate',
            formatter: (cell: string, row: IProgram) =>
              cell ? formatDate(row.endDate, 'MMM-D-YYYY').toUpperCase() : '-',
          },
          {
            text: t('subsidies:program.classes'),
            headerAlign: 'center',
            dataField: 'totalClasses',
            align: 'center',
            formatter: (cell: string, row: IProgram) => row.programClasses.length,
          },
          {
            text: t('subsidies:program.students'),
            dataField: 'totalStudents',
            headerAlign: 'center',
            align: 'center',
            formatter: (cell: string, row: IProgram) => row.programChildren.length,
          },
          {
            text: t('subsidies:program.status'),
            dataField: 'status',
            formatter: (cell: string, row: IProgram) =>
              row.isActive ? (
                <ColoredBackgroundTag color={colors.success} text="Active" />
              ) : (
                <ColoredBackgroundTag color={colors.gray} text="Inactive" />
              ),
          },
          {
            text: 'Actions',
            dataField: '',
            align: 'center',
            headerClasses: 'text-center',
            classes: 'td-overflow',
            formatter: (cell: any, row: IProgram) => <ActionDropdown actions={actionsForRow(row)} />,
          },
        ]}
      >
        {' '}
      </DataTable>
      {/* too sad the i18n react doesn't support plurals with its t() */}
      <WarningModal
        warningText={t(`subsidies:program.archive-program-description_${warningContext.count < 2 ? 'one' : 'other'}`)}
        acknowledgeFnCallback={warningContext.okFn}
        cancelFnCallback={() => {
          setShowWarning(false);
        }}
        title={t('subsidies:program.delete-program')}
        show={showWarning}
      >
        {' '}
      </WarningModal>
    </>
  );
};

export default ProgramsTable;
