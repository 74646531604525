import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { useDispatch } from 'react-redux';
import { getChildByIdSuccess, getChildrenSuccess } from '../duck/actions';
import { childFields } from 'gql/child/fields';
import { QueryHookOptions } from '@apollo/client';

export interface ISearchChildVariables {
  input: IElasticsearchQuery;
}

export interface ISearchChildrenData {
  searchChildren: {
    totalResults: number;
    data: IChild[];
  };
}

interface IGetChildByIdData {
  getChildById: IChild;
}

interface IGetChildByIdVariables {
  id: string;
}

export const GET_CHILD_BY_ID = gql`
  query($id: ID!) {
    getChildById(id: $id) {
      ${childFields}
    }
  }
`;

export const GET_TAG_OPTIONS = gql`
  query ($entityId: String!) {
    getEntity(id: $entityId) {
      id
      tags {
        id
        name
        category
      }
    }
  }
`;

export const SEARCH_CHILDREN = gql`
  query ($input: SearchInput!) {
    searchChildren(input: $input) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        dob
        indigenousStatus
        gender
        restrictAccessToChildDocuments
        avatar {
          url
        }
        contacts {
          id
          firstname
          lastname
          nickname
          avatar {
            url
          }
        }
      }
    }
  }
`;

export const useGetChildById = (options?: QueryHookOptions<IGetChildByIdData, IGetChildByIdVariables>) => {
  const dispatch = useDispatch();
  return useQuery<IGetChildByIdData, IGetChildByIdVariables>(GET_CHILD_BY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      data.getChildById && dispatch(getChildByIdSuccess(data.getChildById));
    },
    onError: (error) => {
      console.log(error);
    },
    ...options,
  });
};

export const useGetChildTagOptions = (entityId: string) => {
  const response = useQuery<{ getEntity: IEntity }, { entityId: string }>(GET_TAG_OPTIONS, {
    variables: { entityId },
    skip: !entityId,
  });

  return response?.data?.getEntity.tags.filter((tag) => tag.category === 'CHILD');
};

export const useSearchChildren = (input: IElasticsearchQuery) => {
  const distpatch = useDispatch();

  return useQuery<ISearchChildrenData, ISearchChildVariables>(SEARCH_CHILDREN, {
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      distpatch(getChildrenSuccess(data.searchChildren.data));
    },
  });
};
