import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';
import { restrictionFields, restrictionWithDocumentsFields } from './fields';

interface ICreateChildRestrictionData {
  createChildRestriction: IRestriction;
}

interface ICreateChildRestrictionVariables {
  childId: string;
  input: {
    typeId: string;
    name: string;
    importance: Risk;
    description: string;
    documentation: IHealthConditionDocumentUploadInput[];
  };
}

interface IUpdateRestrictionVariables {
  id: string;
  input: IUpdateRestrictionInput;
}

export const CREATE_CHILD_RESTRICTION = (fields: string = restrictionWithDocumentsFields) => gql`
  mutation($childId: ID!, $input: RestrictionInput!) {
    createChildRestriction(childId: $childId, input: $input) {
      ${fields}
    }
  }
`;

export const ARCHIVE_RESTRICTION = (fields: string = restrictionFields) => gql`
  mutation($id: ID!) {
    archiveRestriction(id: $id) {
      ${fields}
    }
  }
`;

export const UPDATE_RESTRICTION = (fields: string = restrictionWithDocumentsFields) => gql`
  mutation($id: ID!, $input: UpdateRestrictionInput!) {
    updateRestriction(id: $id, input: $input) {
      ${fields}
    }
  }
`;

export const useCreateChildRestriction = (
  options?: MutationHookOptions<ICreateChildRestrictionData, ICreateChildRestrictionVariables>,
  fields?: string
) =>
  useMutation<ICreateChildRestrictionData, ICreateChildRestrictionVariables>(CREATE_CHILD_RESTRICTION(fields), {
    ...options,
  });

export const useArchiveRestriction = (
  options?: MutationHookOptions<{ archiveRestriction: IRestriction }, { id: string }>,
  fields?: string
) =>
  useMutation<{ archiveRestriction: IRestriction }, { id: string }>(ARCHIVE_RESTRICTION(fields), {
    ...options,
  });

export const useUpdateRestriction = (
  options?: MutationHookOptions<{ updateRestriction: IRestriction }, IUpdateRestrictionVariables>,
  fields?: string
) =>
  useMutation<{ updateRestriction: IRestriction }, IUpdateRestrictionVariables>(UPDATE_RESTRICTION(fields), {
    ...options,
  });
