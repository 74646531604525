import React from 'react';
import { orderBy } from 'lodash';
import { selectAccountItemizedBill } from 'pages/Families/subroutes/Accounts/duck/actions';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import Select from 'shared/components/Select';
import useFormatDate from 'shared/hooks/useFormatDate';
import { RootState } from 'store/reducers';

interface IProps {
  accountId: string;
  onDownload: () => void;
  loading: boolean;
}

const ItemizedBillsSelectCard: React.FC<IProps> = ({ accountId, onDownload, loading }) => {
  const { t } = useTranslation(['accounts']);
  const dispatch = useDispatch();
  const formatDate = useFormatDate();

  const itemizedBillOptions = useSelector(
    (state: RootState) => state.accounts.itemizedBills.byAccountId[accountId]?.itemizedBills ?? []
  );
  const selectedItemizedBillId = useSelector(
    (state: RootState) => state.accounts.itemizedBills.byAccountId[accountId]?.selectedItemizedBillId ?? null
  );

  const mappedOptions = orderBy(itemizedBillOptions, (ib) => new Date(ib.createdAt), 'desc').map(
    (ib: IItemizedBillWithoutTransactions) => {
      const label = ib.isManuallyCreated
        ? `${formatDate(ib.billingPeriodEndDate, 'MMMM Do, YYYY')} (#${ib.userFriendlyId})`
        : `${formatDate(ib.billingPeriodStartDate!, 'MMMM Do, YYYY')} - ${formatDate(
            ib.billingPeriodEndDate,
            'MMMM Do, YYYY'
          )} (#${ib.userFriendlyId})`;

      return { label, value: ib.id };
    }
  );

  return (
    <Card bodyClassName="p-4 kt-selected-itemized-bill-card">
      <div className="kt-selected-itemized-bill-card-body-container">
        <div className="kt-itemized-bill-select-container">
          <div className="mr-4">{t('accounts:profile.billing-itemized-bills-tab.billing-period-select-label')}</div>
          <Select
            value={mappedOptions.find((opt) => opt.value === selectedItemizedBillId)}
            options={mappedOptions}
            onChange={(opt) => dispatch(selectAccountItemizedBill(accountId, opt.value))}
            className="mb-0"
          />
        </div>
        <div className="kt-itemized-bill-download-container">
          <Button variant="secondary" onClick={onDownload} loading={loading}>
            {t('accounts:profile.billing-itemized-bills-tab.download-pdf-button')}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ItemizedBillsSelectCard;
