import React from 'react';
import PasswordInput from './PasswordInput';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { passwordRequirements } from 'shared/util/passwordRequirements';
import colors from '../../../_colors.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ITextInputProps } from './TextInput';

interface ICreatePasswordInputProps extends ITextInputProps {
  placement?: 'right' | 'left' | 'bottom' | 'top';
}

const CreatePasswordInput: React.FC<ICreatePasswordInputProps> = ({
  className,
  value,
  placement = 'right',
  ...rest
}) => {
  const isDesktop = window.matchMedia('(min-width: 600px)').matches;
  const popover = (
    <Popover id="password-requirements" content={true}>
      <div className="d-flex, flex-column">
        <h6>Password must:</h6>
        {passwordRequirements.map((requirement, index) => (
          <div key={index}>
            {requirement.meetsRequirement(value) ? (
              <FontAwesomeIcon className="mr-2" icon={faCheck} color={colors.success} />
            ) : (
              <FontAwesomeIcon className="mr-2" icon={faTimes} color={colors.danger} />
            )}
            {requirement.label}
          </div>
        ))}
      </div>
    </Popover>
  );
  return (
    <div className={className}>
      {isDesktop ? (
        <OverlayTrigger trigger="focus" placement={placement} overlay={popover}>
          <PasswordInput {...rest} value={value} />
        </OverlayTrigger>
      ) : (
        <PasswordInput {...rest} value={value} />
      )}
    </div>
  );
};

export default CreatePasswordInput;
