export const bookWindowFields = `
  quantity
  unit
`;

export const casualBookingSettingsFields = `
  classId
  active
  available
  availableReason
  bookingPolicy{
    atLeastInAdvance{
      ${bookWindowFields}
    }
    atMostInAdvance{
      ${bookWindowFields}
    }      
  }
  cancellationPolicy{
    casualBooking{
      ${bookWindowFields}
    }
    permanentBooking{
      ${bookWindowFields}
    }
  }
`;

export const casualBookingExclusionFields = `
  centerId
  accountId
  account{
    id
    name
  }
  reason
  createdAt
`;
