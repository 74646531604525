//@ts-check
import React, { useState, useCallback } from 'react';
import { isEmailSyntaxValid, isEmailValid } from 'shared/util/email';
import Auth from '@aws-amplify/auth';
import { EmailInput } from 'shared/components/TextInput';
import Button, { ButtonAsLink } from 'shared/components/Buttons';
import errorMessage from 'shared/constants/errorMessages';
import { Col, Row } from 'shared/components/Layout';
import { useGetAuthenticationIdentityResponseLazyQuery, useGetSsoLoginUrlLazyQuery } from 'generated/graphql';

export default function SSOLogin({ toggleSSO, email, updateEmail }) {
  const [isUnknownDomain, setIsUnknownDomain] = useState(false);
  const [invalidIdpIdentifier, setInvalidIdpIdentifier] = useState(false);
  const [onboardingNotComplete, setOnboardingNotComplete] = useState(false);

  const { userPoolWebClientId, oauth } = Auth.configure();
  const { domain, redirectSignIn } = oauth;
  const clientId = userPoolWebClientId;

  const handleUpdateEmail = (value) => {
    updateEmail(value);
    if (isEmailValid(value)) {
      setIsUnknownDomain(false);
    }
  };

  const [getIdpIdentifierResponseQuery, { loading: isLoading }] = useGetAuthenticationIdentityResponseLazyQuery({
    variables: {
      input: {
        email,
      },
    },
  });

  const [getSsoLoginUrl] = useGetSsoLoginUrlLazyQuery({
    variables: {
      input: {
        clientId,
        domain,
        idpIdentifier: '<IDP>', // a placeholder as all other params are consts here
        redirectSignIn,
      },
    },
  });

  const login = async (event) => {
    if (isUnknownDomain) return false;

    setInvalidIdpIdentifier(false);
    setOnboardingNotComplete(false);

    event.preventDefault();
    // store users sign in method (SSO vs password) and show that same method next time they go to login
    localStorage.setItem('isUserSSO', true);

    console.log('Finding authentication identity provider...');

    try {
      const result = await getIdpIdentifierResponseQuery();
      const idpIdentifier = result?.data?.getAuthenticationIdentityResponse?.idpIdentifier;
      const onboardingCompleted = result?.data?.getAuthenticationIdentityResponse?.onboardingCompleted;

      if (idpIdentifier && onboardingCompleted) {
        console.log('Identifier: ' + idpIdentifier);
        redirect(idpIdentifier);
        return; // avoid race conditions
      }

      setIsUnknownDomain(true);

      if (!idpIdentifier || idpIdentifier.trim() === '') {
        console.log('idpIdentifier: ' + idpIdentifier);
        setInvalidIdpIdentifier(true);
      }
      if (!onboardingCompleted) {
        setOnboardingNotComplete(true);
        console.log('onboardingCompleted: ' + onboardingCompleted);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const redirect = async (idpIdentifier) => {
    // TODO: This string replacement is 'temporary' until we rewrite the login flow
    var url = (await getSsoLoginUrl()).data?.getSSOLoginUrl ?? '';
    url = url.replace('<IDP>', idpIdentifier);
    window.location.assign(url);
  };

  return (
    <form id="login-sso-form" onSubmit={login} className="login-sso-container mx-auto p-8 d-flex flex-column">
      <p className="h1 font-weight-light">Log in using your business domain</p>
      <p className="mb-8">Enter your business email below.</p>
      <Row align="start" className="mb-8">
        <Col sm={8}>
          <EmailInput
            label="Email Address"
            value={email}
            onChange={handleUpdateEmail}
            errorText={
              isUnknownDomain
                ? invalidIdpIdentifier
                  ? errorMessage.idpIdentifierNotFound
                  : onboardingNotComplete
                  ? errorMessage.onboardingIncomplete
                  : errorMessage.unknownSSODomain
                : !isEmailSyntaxValid(email)
                ? errorMessage.invalidEmailSyntax
                : errorMessage.invalidEmailDomain
            }
            isInvalid={!isEmailValid(email) || isUnknownDomain}
            autoComplete="on"
          />
        </Col>
        <Col sm={4}>
          <Button
            id="login-submit-btn"
            loading={isLoading}
            disabled={!isEmailValid(email) || isUnknownDomain}
            className="mt-7 w-100"
            type="submit"
          >
            Log In
          </Button>
        </Col>
      </Row>
      <ButtonAsLink onClick={toggleSSO}>Log in without SSO</ButtonAsLink>
    </form>
  );
}
