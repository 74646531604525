import React from 'react';
import moment from 'moment';
import { Col, Row } from 'shared/components/Layout';
import { useTranslation } from 'react-i18next';

interface IProps {
  session: ISession;
  timezone: string;
}

const TodayTimeEntries: React.FC<IProps> = ({ session, timezone }) => {
  const numberOfTimeEntries = session.timeEntries.length;
  const firstTimeEntry = session.timeEntries[0];
  const lastTimeEntry = session.timeEntries[numberOfTimeEntries - 1];
  const { t } = useTranslation();

  return (
    <div>
      {session.absence && (
        <Row>
          <Col>
            <div className="text-orange">{t('attendance.today.absent')}</div>
          </Col>
        </Row>
      )}
      {firstTimeEntry?.timeIn && <div>In: {moment(firstTimeEntry.timeIn).tz(timezone).format('h:mm A')}</div>}
      {lastTimeEntry?.timeOut && <div>Out: {moment(lastTimeEntry.timeOut).tz(timezone).format('h:mm A')}</div>}
    </div>
  );
};

export default TodayTimeEntries;
