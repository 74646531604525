import React, { useCallback, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import Cards, { Focused } from 'react-credit-cards-2';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import { getCardNumberFormat, getCardValidation } from './utils';
import { PaymentMethodType, PayrixService } from 'shared/services/payrixService';
import AvatarDataTableCell from '../DataTable/AvatarDataTableCell';
import { getFullName, getInitials } from 'shared/util/string';

interface IProps {
  cardInfo: ICreditCardInput;
  updateCardInfo: (c: ICreditCardInput) => void;
  updateIsCardValid: (isValid: boolean) => void;
  contact?: IContact;
}

const CreditCardInputs: React.FC<IProps> = ({ cardInfo, contact, updateCardInfo, updateIsCardValid }) => {
  const payrixService = new PayrixService();

  const [inputFocus, setInputFocus] = useState<Focused>('name');
  const handleInputFocus = useCallback((e) => {
    setInputFocus(e.target.name === 'ccv' ? 'cvc' : e.target.name);
  }, []);

  const { isNumberLengthValid, isCvsValid, isExpirationDateValid } = getCardValidation(cardInfo);

  return (
    <form>
      <div className="my-8">
        <Cards
          cvc={cardInfo.ccv}
          expiry={cardInfo.expiration}
          focused={inputFocus}
          name={cardInfo.name}
          number={cardInfo.number}
          callback={(info, isValid) => updateIsCardValid(isValid)}
        />
      </div>
      {contact ? (
        <div className="mb-4">
          <label>Cardholder Name</label>
          <AvatarDataTableCell
            initials={getInitials(contact)}
            avatar={contact?.avatar?.url}
            primaryText={getFullName(contact)}
          />
        </div>
      ) : (
        <TextInput
          label="Cardholder Name"
          name="name"
          onFocus={handleInputFocus}
          value={cardInfo.name}
          onChange={(name) => updateCardInfo({ ...cardInfo, name })}
          required
        />
      )}
      <NumberInput
        label="Card Number"
        name="number"
        onFocus={handleInputFocus}
        value={cardInfo.number}
        onChange={(number) =>
          updateCardInfo({ ...cardInfo, number, method: payrixService.determinePaymentMethodType(number) })
        }
        numberFormat={{ format: getCardNumberFormat(cardInfo), isNumericString: true }}
        required
        isInvalid={!isNumberLengthValid}
      />
      <Row align="start">
        <Col>
          <NumberInput
            label="Expiry Date"
            name="expiration"
            value={cardInfo.expiration}
            placeholder="MM/YY"
            onFocus={handleInputFocus}
            numberFormat={{ format: '##/##', isNumericString: true }}
            onChange={(expiration) => updateCardInfo({ ...cardInfo, expiration })}
            required
            isInvalid={!isExpirationDateValid}
            errorText={'Date is invalid or has already passed.'}
          />
        </Col>
        <Col>
          <NumberInput
            label="CVC"
            name="ccv"
            value={cardInfo.ccv}
            onFocus={handleInputFocus}
            onChange={(ccv) => updateCardInfo({ ...cardInfo, ccv })}
            numberFormat={{
              format: cardInfo.method === PaymentMethodType.AMEX ? '####' : '###',
              isNumericString: true,
            }}
            required
            isInvalid={!isCvsValid}
          />
        </Col>
      </Row>
    </form>
  );
};

export default CreditCardInputs;
