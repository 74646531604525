export const isCaseFields = `
  id
  approvalId
  caseId
  centerId
  centerName
  startDate
  endDate
  status
  nonFaceToFaceHoursAllowed
  nonFaceToFaceHoursBalance
  supportHoursAllowed
  supportHoursBalance
  weeklyHoursAllowed
  weeklyTolerance
  dateLastWithdrawn
  dateLastInactivated
  dateLastReactivated
  careProvision
  careEnvironmentName
  professionalFirstName
  professionalLastName
  agencyName
  agencyRegion
  serviceType
  unsubmittedWeeks
  excludedWeeks {
    weekNumber
    year
  }
  type
  daysOfCare {
    dayOfCare
    hours
    paymentType
  }
  children{
    childId
    dayOfCare
    child {
      id
      firstname
      fullName
      lastname
      avatar {
        url
      }
    }
  }
  enrolments {
    childId
    accountChildId
  }
`;

export const iSCaseFieldsPagedResult = `
  totalRecords
  pageNumber
  data {
    ${isCaseFields}
  }
`;

const ISCaseClaimPaymentFields = `
  id
  isCaseId
  isClaimId
  paymentType
  weekEnding
  centerName
  hoursClaimed
  hoursPaid
  serviceProvision
  paymentAmount
  calculationDetails
`;

export const ISCaseClaimPaymentPagedResultFields = `
  totalRecords
  pageNumber
  data {
    ${ISCaseClaimPaymentFields}
  }
`;

export const iSInnovativeCasesForCentersFields = `
  id
  caseId
  approvalId
  startDate
  endDate
  type
  serviceType
  status
  executed
  acquittalStatus
  shortDescription
  approvedFundingAmount
  careEnvironmentName
  agencyRegion
  agencyName
  professionalFirstName
  professionalLastName
  centerId
  centerName
  claims{
    id
    claimId
    created
    cancelled
    paymentType
    approvedPaymentAmount
    status
    caseId
  }
`;

export const iSInnovativeCasesForCentersPagedResult = `
  totalRecords
  pageNumber
  data {
    ${iSInnovativeCasesForCentersFields}
  }
`;
