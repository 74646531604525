import React from 'react';
import momentTz from 'moment-timezone';

interface IProps {
  scheduledShifts: IShift[];
  timezonesByCenter: Record<string, Timezone>;
}

const ScheduledShiftsList: React.FC<IProps> = ({ scheduledShifts, timezonesByCenter, ...props }) => {
  return (
    <div className="kt-new-time-entry-modal-scheduled-shifts-container p-2 mb-4">
      <b className="d-block mb-4">Scheduled Shift(s):</b>
      <ul>
        {!scheduledShifts.length && <li>No scheduled shifts found</li>}
        {scheduledShifts.map((shift: IShift, idx: number) => {
          const timezone = timezonesByCenter[shift.center.id] ?? momentTz.tz.guess();
          return (
            <li className="mb-4" key={`shift=${shift.id}-${idx}`}>
              <div className="mr-auto">
                <div>
                  {momentTz(shift.startTime).tz(timezone).format('h:mm A')} -{' '}
                  {momentTz(shift.endTime).tz(timezone).format('h:mm A')}
                </div>
                <small className="d-block text-dark">{shift.position?.positionName ?? ''}&nbsp;</small>
              </div>
              <div>
                <div>&nbsp;</div>
                <small className="d-block text-dark text-right">{shift.center.name}</small>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ScheduledShiftsList;
