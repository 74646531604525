import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { defaultCommentFields } from './fields';

interface IComment {
  id: string;
  businessId: string;
  attachedToEntityId: string;
  parentId: string;
  text: string;
  createdBy: string;
  createdAt: string;
  replyCount: number;
}

type CommentAttachedToObjectType = 'Note';

interface ICreateCommentInput {
  input: {
    businessId: string;
    attachedToId: string;
    text: string;
    parentId?: string;
    attachedToEntityType: CommentAttachedToObjectType;
  };
}

export const CREATE_COMMENT = gql`
  mutation($input: CreateCommentInput!) {
    createComment(input: $input){
       ${defaultCommentFields}
    }
  }
`;

export const useCreateComment = (input?: MutationHookOptions<IComment, ICreateCommentInput>) =>
  useMutation<IComment, ICreateCommentInput>(CREATE_COMMENT, input);
