import moment from 'moment';

export const isWeekEndingInvalid = (weekEnding: moment.Moment, startDate: moment.Moment, endDate: moment.Moment) => {
  const newEndDate = endDate.add(1, 'days').startOf('day');

  return (
    weekEnding.day() !== moment().day('Sunday').day() ||
    weekEnding.isAfter(moment()) ||
    weekEnding.isAfter(newEndDate) ||
    weekEnding.isBefore(startDate)
  );
};

export const getCareDate = (day: DayOfCare, weekEnding: string) => {
  return day === 'Sunday' ? weekEnding : moment(weekEnding).subtract(1, 'week').day(day).format('YYYY-MM-DD');
};

/**
 *
 * @param absences
 * @returns Partial<Record<DayOfCare, string[]>>
 *  {
 *    Monday: [1, 2],
 *    Tuesday: [],
 *  }
 */
const getAbsencesPerDay = (absences: IAbsence[]): Partial<Record<DayOfCare, string[]>> =>
  absences.reduce((acc: Partial<Record<DayOfCare, string[]>>, curr) => {
    const { date, accountChildId } = curr;
    const childIds = new Set<string>(acc[date as DayOfCare] ?? []);
    if (accountChildId) {
      childIds.add(accountChildId);
    }
    acc[moment(date).format('dddd') as DayOfCare] = Array.from(childIds);
    return acc;
  }, {});

/**
 * creates case day claims with prefilled values based from the child absences
 * @param iSCase
 * @param absences
 * @param values
 * @returns {ICaseDayClaim[]}
 */
export const getPopulatedCaseDayClaims = (
  iSCase: IISCase,
  absences: IAbsence[],
  values: ISCaseClaimCreate
): ICaseDayClaim[] => {
  const absencesPerDay = getAbsencesPerDay(absences);
  const daysOfCare = iSCase.daysOfCare
    .filter((day) => day.paymentType === values.paymentType)
    .map((day) => ({
      careDay: day.dayOfCare,
      faceToFaceHours: 0,
      nonFaceToFaceHours: 0,
    }));

  const newCaseDay = daysOfCare.map((dayClaims) => {
    const dayOfCare = iSCase.daysOfCare.find((day) => day.dayOfCare === dayClaims.careDay);
    let f2f = moment.duration(dayOfCare?.hours ?? '').asHours();
    if (absencesPerDay[dayClaims.careDay]?.length === values.children.length) {
      f2f = 0;
    }
    return { careDay: dayClaims.careDay, faceToFaceHours: f2f, nonFaceToFaceHours: 0 };
  });
  return newCaseDay;
};

export const formatEducatorsPerDay = (
  educators: (IBasicStaff | ITemporaryEducator)[],
  educatorHoursPerDay: IDayHoursPerEducator,
  weekEnding: string,
  caseDayClaims: ICaseDayClaim[]
): IEducatorClaim[] => {
  return educators
    .map((educator: IBasicStaff | ITemporaryEducator) => {
      const { id, firstName, lastName, firstname, lastname } = educator as IBasicStaff & ITemporaryEducator;

      return caseDayClaims.map((day) => {
        return {
          educatorId: id,
          firstName: firstName ?? firstname,
          lastName: lastName ?? lastname,
          careDate: getCareDate(day.careDay, weekEnding),
          faceToFaceHours: educatorHoursPerDay[id][day.careDay]?.faceToFaceHours || 0,
          nonFaceToFaceHours: educatorHoursPerDay[id][day.careDay]?.nonFaceToFaceHours || 0,
        };
      });
    })
    .flat()
    .filter((d) => d.faceToFaceHours + d.nonFaceToFaceHours !== 0);
};

export const DAY_SORT = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};
