import moment from 'moment';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { isRegion } from 'shared/util/region';
import { RootState } from 'store/reducers';
import { filterByApplicationScheduleOfferStates, getHostName, isProgramBasedFlow } from '../../../utils';
import {
  Application,
  ApplicationChild,
  ApplicationFlowType,
  ApplicationOffer,
  ApplicationScheduleOffer,
  ApplicationScheduleOfferState,
  ApplicationStage,
  EnrolmentSource,
  Maybe,
  useMarkLostMutation,
  useUpsertApplicationDepositMutation,
} from 'generated/graphql';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';
import { currencyFormat } from 'shared/util/currency';
import { useLeadsContext } from '../../../LeadsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCloudDownload, faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { SingleAccordion } from 'shared/components/Accordion';
import { ApplicationContext } from 'shared/contexts/ApplicationContext';
import colors from '_colors.module.scss';
import { showToast } from 'shared/components/Toast';
import OfferAccordion from '../../OfferCard/OfferAccordion';
import MultiChildEnquiryDetails from '../MultiChildEnquiryDetails';
import DepositInput, { getDepositInValidState } from '../ApplicationDeposit';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { useResendApplicationOffer } from 'gql/application/mutations';
import Button, { ButtonAsLink } from 'shared/components/Buttons';
import MarkAsLostConfirmModal from '../MarkAsLostConfirmModal';
import EditDepositModal from '../EditDepositModal';
import EditOfferModal from '../EditOfferModal';
import OfferSummary from '../OfferSummary';
import FormDiffLink from '../../EnrollmentFormDiffModal/EnrolmentFormDiffLink';

export interface ViewOffersProps {
  application: Application;
  // pass only the applicationScheduleOfferStates that are supposed to be shown in the current tab depends on application flows.
  // check enrollApi > applicationClient > GetApplications to see how the states are mapping to stage(in other word, tab in the UI).
  validApplicationScheduleOfferStates: ApplicationScheduleOfferState[];
  showResendOfferButton?: boolean;
  allowEdit?: boolean;
  showMarkAsLostButton?: boolean;
  allowEditDeposit?: boolean;
}

export type SchedulesByOffers = {
  offer: ApplicationOffer;
  childrenwithSchedules: ApplicationChildWithSchedules[];
};

export type ApplicationChildWithSchedules = {
  childId: string;
  childFullName: string;
  child: ApplicationChild;
  childSchedulesForOffer: ApplicationScheduleOffer[];
};

export interface IDepositEditModalState {
  amount: number;
  collected: boolean;
  appliesDate?: string;
  id?: string | null;
  isOpen: boolean;
}

export interface IEditOfferModalState {
  isOpen: boolean;
  offer: ApplicationOffer;
  childrenForOffer: ApplicationChildWithSchedules[];
  centerIds: string[];
}

const ViewOffersV2: React.FC<ViewOffersProps> = ({
  application,
  validApplicationScheduleOfferStates,
  showResendOfferButton = false,
  showMarkAsLostButton = false,
  allowEdit = false,
  allowEditDeposit = false,
}) => {
  const { k2EnrolFormDifference } = useFlags();
  const { t } = useTranslation();
  const hasEditApplicationPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementMain,
    level: RoleLevelType.Edit,
  });
  const { k2ApplicationDeposit } = useFlags();
  const isAuRegion = isRegion('AU');
  const { isSupportMode } = useContext(ApplicationContext);
  const {
    businessId,
    applicationFlowType,
    activeTab,
    handleRefetchApplications: refetch,
    setEnrolmentFormDiffData,
    setIsEnrolmentFormDiffModalOpen,
    setSelectedApplication,
  } = useLeadsContext();
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);

  const timezoneByCenterId = useSelector((state: RootState) => state.timezone.byCenterId);
  const centerId = application.centers[0];
  const timezone = timezoneByCenterId[centerId] ?? moment.tz.guess();

  const [showMarkAsLostConfirmModal, setShowMarkAsLostConfirmModal] = useState(false);
  const [depositEditModalState, setDepositEditModalState] = useState<IDepositEditModalState | null>(null);
  const [editOfferModalState, setEditOfferModalState] = useState<IEditOfferModalState | null>(null);
  const depositEditModalStateInValid = getDepositInValidState(
    depositEditModalState?.collected,
    depositEditModalState?.appliesDate
  );

  const [upsertApplicationDepositMutation, { loading }] = useUpsertApplicationDepositMutation({
    onError: (err) => showToast(getApolloErrorMessage(err), 'error'),
    onCompleted: () => {
      setDepositEditModalState(null);
      showToast(t('enrollment.lead-management.deposit.update-success-msg'), 'success');
    },
  });

  const handleUpsertApplicationDeposit = async () => {
    const isUpdate = Boolean(depositEditModalState?.id);
    const isCreate = !isUpdate;

    if (isCreate && (!depositEditModalState?.amount || depositEditModalState.amount <= 0)) {
      return;
    }

    await upsertApplicationDepositMutation({
      variables: {
        input: {
          applicationId: application.id,
          businessId: businessId,
          deposit: {
            transactionId: depositEditModalState?.id,
            amount: depositEditModalState?.amount ?? 0,
            collected: depositEditModalState?.collected ?? false,
            appliesDate: depositEditModalState?.collected ? depositEditModalState?.appliesDate : null,
            centerId: application.centers[0],
          },
        },
      },
    });
  };

  const allScheduleOffers = application.children.reduce<Maybe<ApplicationScheduleOffer>[]>((acc, child) => {
    const scheduleOffers = child.offers.filter((offer) =>
      (validApplicationScheduleOfferStates as string[]).includes(offer?.state ?? '')
    );
    return [...acc, ...scheduleOffers];
  }, []);

  const [markLostMutation, { loading: markLostLoading }] = useMarkLostMutation({
    variables: {
      input: {
        businessId: businessId ?? '',
        applicationId: application.id,
        scheduleOfferIds: allScheduleOffers.map((o) => o?.id ?? ''),
      },
    },
    onCompleted: () => {
      setShowMarkAsLostConfirmModal(false);
      refetch();
    },
    onError: (err) => showToast(getApolloErrorMessage(err), 'error'),
  });

  const [resendApplicationOffer, { loading: resendLoading }] = useResendApplicationOffer({
    onError: (err) => showToast(err.message, 'error'),
    onCompleted: () => {
      showToast(t('enrollment.lead-management.send-offer-success'), 'success');
      refetch();
    },
  });

  const handleMarkAsLost = () => markLostMutation();

  const handleResendOffer = () => {
    resendApplicationOffer({
      variables: {
        input: {
          applicationId: application?.id ?? '',
          businessId,
          scheduleOfferIds: [],
        },
      },
    });
  };

  const children = filterByApplicationScheduleOfferStates(application, validApplicationScheduleOfferStates);

  const schedulesByOffer: SchedulesByOffers[] = useMemo(() => {
    const validOffers = application.offers?.filter((o) =>
      children.some((c) =>
        c.offers.some(
          (offer) =>
            (validApplicationScheduleOfferStates as string[]).includes(offer?.state ?? '') &&
            offer?.applicationOfferId === o?.id
        )
      )
    ) as ApplicationOffer[];

    return validOffers.map((o) => {
      const childrenForOffer = children
        .filter((c) =>
          c.offers.some(
            (offer) =>
              (validApplicationScheduleOfferStates as string[]).includes(offer?.state ?? '') &&
              offer?.applicationOfferId === o?.id
          )
        )
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
        .map((c) => {
          return {
            childId: c.id,
            childFullName: `${c.firstName} ${c.lastName}`,
            child: c,
            childSchedulesForOffer: c.offers.filter(
              (offer) =>
                (validApplicationScheduleOfferStates as string[]).includes(offer?.state ?? '') &&
                offer?.applicationOfferId === o?.id
            ),
          };
        });
      return {
        offer: o,
        childrenwithSchedules: childrenForOffer,
      };
    }) as SchedulesByOffers[];
  }, [application.offers, children, validApplicationScheduleOfferStates]);

  const generateSupportOfferLinks = (offer: ApplicationOffer) => {
    return (
      <>
        <a
          className="mr-4 text-primary"
          onClick={async (e) => {
            e.stopPropagation();
            if (navigator?.clipboard) {
              await navigator.clipboard.writeText(offer.linkCode ?? '');
              showToast('Offer LinkCode copied to clipboard', 'info');
            }
          }}
        >
          {t('enrollment.lead-management.copy-linkcode')}
        </a>
        <a
          className="mr-4 text-primary"
          onClick={async (e) => {
            e.stopPropagation();
            if (navigator?.clipboard) {
              await navigator.clipboard.writeText(offer.id ?? '');
              showToast('OfferId copied to clipboard', 'info');
            }
          }}
        >
          Copy Offer Id
        </a>
      </>
    );
  };

  const showPrintMode =
    [ApplicationStage.Accepted, ApplicationStage.Waitlisted].includes(activeTab as ApplicationStage) &&
    isProgramBasedFlow(applicationFlowType);

  const getFormLinkLabel = () => {
    if (showPrintMode) return 'View Form';
    if (activeTab === ApplicationStage.Completed) return 'Download Form';
    return 'Form Link';
  };

  const generateOfferHeaderSummary = (
    childrenForOffer: ApplicationChildWithSchedules[],
    offer: ApplicationOffer,
    enquiryDate: string
  ) => {
    const childrenNames = childrenForOffer.map((c) => c.childFullName).join(', ');
    const offerIndex = application.offers?.length ? application.offers.length - 1 : 0;
    const linkCode = application?.offers?.[offerIndex]?.linkCode;
    const enrolmentFormPdfDocumentLink = application?.offers?.[offerIndex]?.enrolmentFormPdfLink;
    const enrolFormUrl = enrolmentFormPdfDocumentLink
      ? enrolmentFormPdfDocumentLink
      : linkCode
      ? `https://${getHostName()}/offer/${linkCode}?token=${jwtToken}&isPrintMode=${showPrintMode}`
      : null; // use the enrolment form pdf link if available otherwise the linkcode

    return (
      <div className="offer-details-header d-flex justify-content-between align-items-center pr-4">
        <div>
          <h4>{childrenNames}</h4>
        </div>
        <div className="d-flex align-items-center">
          {/** only show edit button for those with permission and in offered(normal flow) or waitlisted(program flow)**/}
          {hasEditApplicationPermission &&
            ((activeTab === ApplicationStage.Offered &&
              applicationFlowType === ApplicationFlowType.InquireOfferEnrollment) ||
              (activeTab === ApplicationStage.Waitlisted && isProgramBasedFlow(applicationFlowType))) && (
              <div className="edit-offer-link d-flex align-items-center">
                <FontAwesomeIcon icon={faPenToSquare} className="mr-2" color={colors.primary} />
                <ButtonAsLink
                  onClick={() =>
                    setEditOfferModalState({
                      isOpen: true,
                      offer: offer,
                      childrenForOffer: childrenForOffer,
                      centerIds: application.centers,
                    })
                  }
                  className="mr-4 text-primary text-primary-hover"
                >
                  {t('enrollment.lead-management.edit-offer')}
                </ButtonAsLink>
              </div>
            )}
          <div className="enrol-form-link d-flex align-items-center">
            <FontAwesomeIcon
              icon={activeTab === ApplicationStage.Completed ? faCloudDownload : faArrowUpRightFromSquare}
              className="mr-2"
              color={colors.primary}
            />
            <a
              href={enrolFormUrl ?? ''}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-4 d-block text-primary text-primary-hover"
            >
              {getFormLinkLabel()}
            </a>
          </div>
          {/* Enrolment difference is only for re-enrolments because all inquiries will have new enrolment forms */}
          {k2EnrolFormDifference &&
            application.enrollmentSource !== EnrolmentSource.Inquiry &&
            offer.enrollmentFormChanged && (
              <div className="mr-2">
                <FormDiffLink
                  enrolmentFormId={offer.enrolmentFormDataRecordId ?? ''}
                  alternativeTitle={'Form Updates'}
                  handleOnClick={(formattedData) => {
                    setEnrolmentFormDiffData(formattedData);
                    setIsEnrolmentFormDiffModalOpen(true);
                    setSelectedApplication(application);
                  }}
                />
              </div>
            )}
          {isSupportMode && generateSupportOfferLinks(offer)}
          <div className="px-4 py-1 lead-label" style={{ border: `1px solid ${colors.gray}` }}>
            <p>
              <strong>{t('enrollment.lead-management.submitted-on')}</strong>: {enquiryDate}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderExpiryDate = (expiryDate) => {
    const offerExpiryDate = moment.tz(expiryDate, timezone).format(t('formatters.MMM D, YYYY-timestamp'));

    if (
      !(
        activeTab === ApplicationStage.InProgress ||
        activeTab === ApplicationStage.Waitlisted ||
        activeTab === ApplicationStage.Offered
      ) ||
      !expiryDate
    ) {
      return <></>;
    }
    const isExpired = moment.tz(expiryDate, timezone) < moment();
    const expiryText = isExpired
      ? t('enrollment.lead-management.offer-expired-on')
      : t('enrollment.lead-management.offer-expires-on');

    return (
      <div
        className="px-4 py-1 lead-label"
        style={{ border: `1px solid ${colors.gray}`, color: isExpired ? colors.danger : colors.textColor }}
      >
        <p>
          <strong>{expiryText}</strong>: {offerExpiryDate}
        </p>
      </div>
    );
  };

  return (
    <>
      {schedulesByOffer.length > 1 && <OfferSummary schedulesByOffers={schedulesByOffer} />}
      <div>
        <div className="view-offer-body" key={application.id}>
          {schedulesByOffer.length > 0 &&
            schedulesByOffer.map((schedulesForOffer, index) => {
              const enquiryDate = moment(application.enquiryDate).tz(timezone).format(t('formatters.MMM D, YYYY'));

              return (
                <div className="offer-details" key={schedulesForOffer.offer.id}>
                  <SingleAccordion
                    title={generateOfferHeaderSummary(
                      schedulesForOffer.childrenwithSchedules,
                      schedulesForOffer.offer as ApplicationOffer,
                      enquiryDate
                    )}
                    loadingLines={2}
                  >
                    <div className="offer-inquiry-details">
                      <MultiChildEnquiryDetails
                        children={schedulesForOffer.childrenwithSchedules.map((c) => c.child)}
                        offerId={schedulesForOffer.offer.id}
                      />
                    </div>
                    <div className="schedule-offers">
                      <div className="d-flex justify-content-between align-items-baseline mb-3">
                        <h5 className="mb-0">{t('enrollment.lead-management.offer-details')}</h5>
                        {renderExpiryDate(schedulesForOffer.offer?.expiryDate)}
                      </div>
                      {schedulesForOffer.childrenwithSchedules.map((c) => {
                        const childrenSchedulues = c.childSchedulesForOffer.sort((a, b) =>
                          moment(a.startDate).tz(timezone).diff(moment(b.startDate).tz(timezone))
                        );

                        return childrenSchedulues.map((sched) => {
                          const subtitle =
                            applicationFlowType === ApplicationFlowType.InquireOfferEnrollment
                              ? sched.careType
                              : sched.program?.name ?? sched.careType;
                          const isDisabledEditing =
                            sched.applicationOfferId !== null &&
                            sched.applicationOfferId !== undefined &&
                            !(sched.state === ApplicationScheduleOfferState.ApprovalWaitlistPending);

                          return (
                            <OfferAccordion
                              key={sched.id}
                              schedule={sched}
                              handleChange={() => {}}
                              handleDayToggle={() => {}}
                              title={c.childFullName}
                              isDisabled={isDisabledEditing}
                              centers={application.centers}
                              subtitle={subtitle}
                              summaryMode={true}
                            />
                          );
                        });
                      })}
                    </div>
                  </SingleAccordion>
                </div>
              );
            })}
          {applicationFlowType === ApplicationFlowType.InquireProgramEnrollmentApprove && (
            <p>
              {/* applications should only ever have one applicationOffer. a $0 fee means that there is either no application fee 
              or the fee hasn't been paid yet*/}
              {t('enrollment.lead-management.application-fee.title', {
                amount: currencyFormat(application.offers![0]?.applicationPaidAmount ?? 0),
                transactionId:
                  !isAuRegion && application.offers![0]?.applicationPaidAmount !== null
                    ? `(${application.offers![0]?.applicationPaymentReference})` ?? ''
                    : '',
              })}
              <span>
                <HelpTooltip text={t(`enrollment.lead-management.application-fee.tooltip`)} direction="top" />
              </span>
            </p>
          )}
          {k2ApplicationDeposit && (
            <>
              {/* list existing application deposits */}
              {application.applicationDeposits.map((deposit) => (
                <DepositInput
                  key={deposit.id}
                  isCollected={deposit.collected}
                  onEditClick={() =>
                    setDepositEditModalState({
                      isOpen: true,
                      amount: deposit.amount,
                      collected: deposit.collected,
                      appliesDate: deposit.appliesDate,
                      id: deposit.id,
                    })
                  }
                  value={deposit.amount}
                  showEdit={allowEditDeposit}
                  isDisabled={true}
                  appliesDate={deposit.appliesDate}
                />
              ))}
              {/* to allow add a deposit to an application if no existing deposit */}
              {application.applicationDeposits.length === 0 && (
                <DepositInput
                  isCollected={false}
                  onEditClick={() =>
                    setDepositEditModalState({
                      isOpen: true,
                      amount: 0,
                      collected: false,
                      id: null,
                    })
                  }
                  value={0}
                  showEdit={allowEditDeposit}
                  isDisabled={true}
                />
              )}
            </>
          )}
          <div>
            {(showResendOfferButton || showMarkAsLostButton) && (
              <div className="d-flex justify-content-end mt-3">
                {showMarkAsLostButton && hasEditApplicationPermission && (
                  <Button onClick={() => setShowMarkAsLostConfirmModal(true)} variant="light" className="mr-2">
                    {t('enrollment.lead-management.mark-as-lost')}
                  </Button>
                )}
                {showResendOfferButton && (
                  <Button onClick={handleResendOffer} loading={resendLoading} variant="primary">
                    {t('enrollment.lead-management.resend-offer')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {showMarkAsLostButton && (
        <MarkAsLostConfirmModal
          show={showMarkAsLostConfirmModal}
          onHide={() => setShowMarkAsLostConfirmModal(false)}
          onConfirm={handleMarkAsLost}
          loading={markLostLoading}
        />
      )}

      <EditDepositModal
        isOpen={depositEditModalState?.isOpen ?? false}
        onHide={() => setDepositEditModalState(null)}
        onSave={handleUpsertApplicationDeposit}
        isSubmitting={loading}
        isDepositCollected={depositEditModalState?.collected ?? false}
        depositAmount={depositEditModalState?.amount ?? 0}
        depositAppliesDate={depositEditModalState?.appliesDate}
        onChange={(amount, isCollected, appliesDate) =>
          setDepositEditModalState({
            amount: amount ?? 0,
            collected: isCollected,
            id: depositEditModalState?.id ?? null,
            appliesDate,
            isOpen: true,
          })
        }
        inValid={depositEditModalStateInValid}
      />

      <EditOfferModal
        isOpen={editOfferModalState?.isOpen ?? false}
        onHide={() => setEditOfferModalState(null)}
        onSave={() => {
          setEditOfferModalState(null);
          refetch();
        }}
        offer={editOfferModalState?.offer ?? ({} as ApplicationOffer)}
        childrenForOffer={editOfferModalState?.childrenForOffer ?? []}
        centerIds={editOfferModalState?.centerIds ?? []}
        applicationId={application.id}
      />
    </>
  );
};

export default ViewOffersV2;
