import React from 'react';
import TextInput from 'shared/components/TextInput';
import Button from 'shared/components/Buttons';

import { Row, Col } from 'react-bootstrap';

import ISCaseClaimsChildrenInfo from './ISCaseClaimsChildrenInfo';
import ISCaseClaimsEducatorInfo from './ISCaseClaimsEducatorInfo';
// import ISCaseClaimsDaysOfCare from './ISCaseClaimsDaysOfCare';

import './iSCaseClaimsDetailsRow.scss';
import { useCancelIsCaseClaim } from 'gql/iSClaim/mutations';
import { showToast } from 'shared/components/Toast';
interface IProps {
  data: IISCaseClaim;
  businessId: string;
  onSuccess?: (result: IISCaseClaim) => void;
}

const ISCaseDetailsRow: React.FC<IProps> = ({ data, businessId, onSuccess }) => {
  const [cancelIsCaseClaim, { loading }] = useCancelIsCaseClaim();

  const onCancelClick = () => {
    cancelIsCaseClaim({
      variables: {
        input: {
          businessId: businessId,
          centerId: data.centerId,
          caseClaimId: data.id,
        },
      },
    })
      .then((success) => {
        if (!success.data?.cancelIsCaseClaim) return; // Guard
        if (onSuccess) onSuccess(success.data.cancelIsCaseClaim);
        showToast('Canceled IS case claim sucessfully.', 'success');
      })
      .catch((err) => showToast(err.toString(), 'error'));
  };

  return (
    <div className="case-claim-row">
      <Row style={{ width: '30%' }}>
        <Col lg={6}>
          <TextInput required name="payment-type" label="Payment Type" disabled value={data.paymentType}></TextInput>
        </Col>
      </Row>
      <hr />
      <Row className="position-relative overflow-auto mt-6 mb-6">
        <Col lg={4} xl={3} className="mb-4">
          <ISCaseClaimsChildrenInfo data={data} />
          {/* temporarily hide days of care until create claim is available */}
          {/* <ISCaseClaimsDaysOfCare data={data} /> */}
        </Col>
        <Col lg={1}>
          <div className="vertical-divider"></div>
        </Col>
        <Col lg={7} xl={6} style={{ marginBottom: 30 }}>
          <ISCaseClaimsEducatorInfo data={data} />
        </Col>
        {data.paymentStatus !== 'CANCELLED' && (
          <Button variant="outline-danger" onClick={onCancelClick} className="cancel-claim-button" loading={loading}>
            {' '}
            Cancel Case Claim{' '}
          </Button>
        )}
      </Row>
    </div>
  );
};

export default ISCaseDetailsRow;
