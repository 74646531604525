import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Column from 'react-bootstrap/Col';
import TextInput from 'shared/components/TextInput';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  role: string;
  email: string;
}

const ContactModal: React.FC<IProps> = ({ isOpen, onClose, name, role, email, ...props }) => {
  return (
    <Modal centered backdrop="static" show={isOpen} onHide={onClose}>
      <Modal.Header closeButton className="border-bottom-0 pb-0">
        <Modal.Title as="h5">{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Column xs={6} className="px-0">
          <TextInput disabled label="Role" value={role} />
        </Column>
        <TextInput disabled label="Email" value={email} />
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
