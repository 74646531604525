import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import moment from 'moment';

interface IGetPeaWeeksRemainingVariables {
  centerId: string;
  accountId: string;
  childIds: string[];
}

interface IGetPeaWeeksRemainingData {
  getPeaWeeksRemaining: { childId: string; weeksRemaining: number }[];
}

const GetPeaWeeksRemainingQuery = () => gql`
  query ($centerId: ID!, $accountId: ID!, $childIds: [ID!]!) {
    getPeaWeeksRemaining(centerId: $centerId, accountId: $accountId, childIds: $childIds) {
      childId
      weeksRemaining
    }
  }
`;

export const useGetPeaWeeksRemaining = ({ accountId, centerId, childIds }: IGetPeaWeeksRemainingVariables) => {
  const [remainingWeek, setRemainingWeek] = useState<number>();

  const { refetch } = useQuery<IGetPeaWeeksRemainingData, IGetPeaWeeksRemainingVariables>(GetPeaWeeksRemainingQuery(), {
    skip: true,
  });

  const fetchData = useCallback(async () => {
    const childId = childIds[0];

    const response = await refetch({ centerId, accountId, childIds: [childId] });
    if (response.errors) return; // Escape if error
    setRemainingWeek(response.data?.getPeaWeeksRemaining?.find((o) => o.childId === childId)?.weeksRemaining);
  }, [accountId, centerId, childIds, refetch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return remainingWeek;
};

export const isEnrolmentEndDateInvalid = (
  startDate: string | undefined,
  endDate: string | undefined,
  fosterKinship: boolean,
  peaWeeksRemaining: number
) => {
  const dayMoment = moment(endDate);

  if (dayMoment.isBefore(moment(startDate), 'day')) {
    return true;
  }

  if (fosterKinship) {
    if (startDate) {
      if (!endDate) return true;
      const peaLastValidDate = moment(startDate).add(peaWeeksRemaining, 'weeks');
      if (dayMoment.isAfter(peaLastValidDate)) return true;
    }
  }

  return false;
};

////---------------------------------------------------/////

interface IIsValidArrangementVariables {
  centerId: string;
  accountId: string;
  childId: string;
  arrangementType: string;
}

interface IGetIsValidArrangementData {
  getIsValidArrangement: { isValidArrangement: boolean };
}

const GetIsValidArrangementQuery = () => gql`
  query ($centerId: ID!, $accountId: ID!, $childId: ID!, $arrangementType: ID!) {
    getIsValidArrangement(
      centerId: $centerId
      accountId: $accountId
      childId: $childId
      arrangementType: $arrangementType
    ) {
      isValidArrangement
    }
  }
`;

export const useGetIsValidArrangement = ({
  accountId,
  centerId,
  childId,
  arrangementType,
}: IIsValidArrangementVariables) => {
  const [isValidArrangement, setIsValidArrangement] = useState<boolean>();

  const { refetch } = useQuery<IGetIsValidArrangementData, IIsValidArrangementVariables>(GetIsValidArrangementQuery(), {
    skip: true,
  });

  const fetchData = useCallback(async () => {
    setIsValidArrangement(false);
    const response = await refetch({ centerId, accountId, childId, arrangementType });
    if (response.errors) return; // Escape if error
    setIsValidArrangement(response.data?.getIsValidArrangement.isValidArrangement);
  }, [accountId, centerId, childId, arrangementType, refetch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return isValidArrangement;
};
