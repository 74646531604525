import { useCreateReverseTransaction } from 'gql/transaction/mutations';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import errors from 'shared/constants/errorMessages';
import { createReverseTransaction } from '../duck/action';

interface IProps {
  isOpen: boolean;
  /**
   * Is called when the modal wishes to close either because cancel is clicked, modal is x'd out, or save is complete.
   * @returns
   */
  onClose: () => void;
  transaction: ITransaction;
  /**
   * Is called when transaction has been successfully reversed
   * @returns
   */
  onComplete?: () => void;
}

const ReverseTransactionModal: React.FC<IProps> = ({ isOpen, onClose, transaction, onComplete = () => {} }) => {
  const [reason, setReason] = useState('');
  const dispatch = useDispatch();

  const [reverseTransaction, { loading }] = useCreateReverseTransaction({
    onCompleted: (result) => {
      if (result?.createReverseTransaction) {
        onClose();
        showToast('Transaction reversed successfully.', 'success');
        onComplete();
        dispatch(createReverseTransaction(result.createReverseTransaction));
      }
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleSave = useCallback(() => {
    reverseTransaction({
      variables: {
        transactionId: transaction.id,
        reason,
      },
    });
  }, [reason, reverseTransaction, transaction.id]);

  return (
    <ConfirmationModal
      title="Reverse Transaction"
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice={'Reverse'}
      primaryCallback={handleSave}
      primaryButtonProps={{
        variant: 'danger',
        loading,
        disabled: !reason,
      }}
      secondaryCallback={onClose}
    >
      <div className="d-flex flex-column w-100 h-100 flex-grow-1">
        <div>Reversing a transaction will void this item.</div>
        <div className="mb-4"> Please provide a reason for reversing this transaction.</div>
        <h5 className="mb-4">Transaction Information</h5>
        <Row>
          <Col xs={2}>
            {' '}
            <h6>Date</h6>
          </Col>
          <Col>{moment(transaction.date).format('MM/DD/YYYY')}</Col>
        </Row>
        <Row>
          <Col xs={2}>
            {' '}
            <h6>Account</h6>
          </Col>
          <Col>{transaction.account.name}</Col>
        </Row>
        <Row>
          <Col xs={2}>
            {' '}
            <h6>Type</h6>
          </Col>
          <Col>{transaction.transactionType.name}</Col>
        </Row>
        <Row className="mb-6">
          <Col xs={2}>
            {' '}
            <h6>Amount</h6>
          </Col>
          <Col>${transaction.amount.toFixed(2)}</Col>
        </Row>
        <TextInput label="Reason for Reversing" value={reason} onChange={setReason} required as="textarea" rows={3} />
        {!reason && <small className="ml-auto mt-auto">{errors.formRequirements}</small>}
      </div>
    </ConfirmationModal>
  );
};

export default ReverseTransactionModal;
