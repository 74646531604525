import React, { useCallback, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import BaseSelect from 'shared/components/Select/Base';
import { useTranslation } from 'react-i18next';
import { useSearchAccounts } from 'gql/account/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  /**
   * `null` can represent `All` when `useNullForAllOption` is provided
   */
  selectedAccountIds: string[] | null;
  onSelect: (accountIds: string[] | null) => void;
  required?: boolean;
  centerIdsFilter?: string[];
  /**
   * Provide `null` as the value via `onSelect` if the all option is selected.
   */
  useNullForAllOption?: boolean;
  /**
   * default: `false`
   * if true, 'All Accounts' option will not be shown
   */
  disableAllOption?: boolean;
  disabled?: boolean;
}

const MultipleAccountSelect: React.FC<IProps> = ({
  selectedAccountIds,
  useNullForAllOption = false,
  onSelect,
  centerIdsFilter = [],
  disableAllOption = false,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation();
  const ALL_ACCOUNTS_OPTIONS = { value: null, label: `All Accounts` };

  const { loading: searchAccountsLoading, data: searchAccountsData } = useSearchAccounts(
    {
      variables: {
        input: {
          filter: { all: [] },
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          from: 0,
          size: 10000,
        },
      },
    },
    `id name centerId`
  );

  const filteredAccountsData = useMemo(
    () =>
      centerIdsFilter.length > 0
        ? searchAccountsData?.searchAccounts.data.filter((account) => centerIdsFilter.includes(account.centerId))
        : searchAccountsData?.searchAccounts.data,
    [searchAccountsData, centerIdsFilter]
  );

  const accountSelectOptions = filteredAccountsData?.map((acc) => ({ label: acc.name, value: acc.id })) ?? [];
  /**
   * Determine if the "all" option has been selected in the dropdown
   */
  const isAllOptionSelected = useCallback((): boolean => {
    if (disableAllOption) {
      return false;
    }

    return (
      (useNullForAllOption && !selectedAccountIds) ||
      (selectedAccountIds !== null &&
        selectedAccountIds.length > 0 &&
        selectedAccountIds.length === accountSelectOptions.length)
    );
  }, [selectedAccountIds, useNullForAllOption, accountSelectOptions, disableAllOption]);

  /**
   * Get the array of selected values. React-select wants an array of all of the objects selected
   */
  const getSelectValue = useCallback(() => {
    return isAllOptionSelected()
      ? [ALL_ACCOUNTS_OPTIONS]
      : accountSelectOptions.filter((opt) => (selectedAccountIds ?? []).includes(opt.value));
  }, [isAllOptionSelected, accountSelectOptions, selectedAccountIds]);
  /**
   * Handle the selection of an option from the dropdown
   */
  const handleSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_ACCOUNTS_OPTIONS.value) {
        onSelect(useNullForAllOption ? null : accountSelectOptions.map((opt) => opt.value as string));
      } else if (
        (action === 'deselect-option' && option.value === ALL_ACCOUNTS_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_ACCOUNTS_OPTIONS.value)
      ) {
        onSelect([]);
      } else if (actionMeta.action === 'deselect-option' && isAllOptionSelected()) {
        onSelect((selectedAccountIds ?? []).filter((id) => id !== option.value));
      } else {
        onSelect((options ?? []).filter((opt) => opt.value !== null).map((opt) => opt.value));
      }
    },
    [accountSelectOptions, selectedAccountIds, useNullForAllOption, isAllOptionSelected, onSelect]
  );

  return (
    <Form.Group>
      <Form.Label>
        Account(s){props.required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
      </Form.Label>
      <BaseSelect
        // @ts-ignore
        isMulti
        // @ts-ignore
        value={searchAccountsLoading ? null : getSelectValue()}
        // @ts-ignore
        options={[...(disableAllOption ? [] : [ALL_ACCOUNTS_OPTIONS]), ...accountSelectOptions]}
        // @ts-ignore
        onChange={(options, action) => handleSelect(options as any[], action)}
        isLoading={searchAccountsLoading}
        className="react-select-container flex-wrap"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        noOptionsMessage={() => `No accounts found`}
        components={{ IndicatorSeparator: null }}
        data-testid="multiple-account-select"
        isDisabled={disabled ?? false}
      />
    </Form.Group>
  );
};

export default MultipleAccountSelect;
