import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { debounce, orderBy } from 'lodash';
import DataTable, { TableHeader, TableSearch, TABLE_DEFAULTS } from 'shared/components/DataTable';
import { useTranslation } from 'react-i18next';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { updateAgencyPaymentTableFilters } from './duck/actions';
import DateInput from 'shared/components/DateInput';
import { RootState } from 'store/reducers';
import { useGetEntity } from 'gql/business/queries';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useGetAgenciesForBusiness } from 'gql/agency/queries';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import ActionDropdown from 'shared/components/ActionDropdown';
import { currencyFormat } from 'shared/util/currency';
import { PersonAvatar } from 'shared/components/Avatar';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  businessId: string;
  pagedTableData: IPagedResult<IAgencyPayment>;
  isLoading: boolean;
  draftPaymentsOnly: boolean;
  onView: (payment: IAgencyPayment) => void;
  onEdit?: (payment: IAgencyPayment) => void;
  onDelete?: (payment: IAgencyPayment) => void;
}

const AgencyPaymentsTable: React.FC<IProps> = ({
  businessId,
  pagedTableData,
  isLoading,
  draftPaymentsOnly,
  onView,
  onEdit = () => {},
  onDelete = () => {},
  ...props
}) => {
  const { t } = useTranslation(['subsidies']);
  const dispatch = useDispatch();
  const tableFilters = useSelector((state: RootState) => state.billing.agencyPayments.tableFilters);
  const [tableState, tableFunctions] = useDatatableState();

  const canEditAgencyPayment = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Edit,
  });
  const canDeleteAgencyPayment = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Delete,
  });

  const { data: getEntityData } = useGetEntity(
    {
      variables: {
        id: businessId,
      },
      skip: !businessId,
    },
    `id centers { id name }`
  );

  const { data: getAgenciesForBusinessData } = useGetAgenciesForBusiness(
    {
      variables: {
        businessId,
      },
      skip: !businessId,
    },
    `id name archivedAt`
  );

  const handleSearchTerm = debounce(
    useCallback(
      (term: string) => {
        tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
        dispatch(
          updateAgencyPaymentTableFilters({
            searchTerm: term,
            completed: {
              pageNumber: TABLE_DEFAULTS.PAGE,
              pageSize: TABLE_DEFAULTS.PAGE_SIZE,
            },
            draft: {
              pageNumber: TABLE_DEFAULTS.PAGE,
              pageSize: TABLE_DEFAULTS.PAGE_SIZE,
            },
          })
        );
      },
      [tableFunctions, dispatch]
    ),
    250
  );

  const handleFilterSelect = useCallback(
    (filters: string[], key: 'centerIds' | 'agencyIds') => {
      tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
      dispatch(
        updateAgencyPaymentTableFilters({
          [key]: filters,
          completed: {
            pageNumber: TABLE_DEFAULTS.PAGE,
            pageSize: TABLE_DEFAULTS.PAGE_SIZE,
          },
          draft: {
            pageNumber: TABLE_DEFAULTS.PAGE,
            pageSize: TABLE_DEFAULTS.PAGE_SIZE,
          },
        })
      );
    },
    [tableFunctions, dispatch]
  );

  const handlePageChange = useCallback(
    (page: number, sizePerPage: number) => {
      tableFunctions.changePage(page, sizePerPage);

      if (draftPaymentsOnly) {
        dispatch(
          updateAgencyPaymentTableFilters({
            draft: {
              pageNumber: page,
              pageSize: sizePerPage,
            },
          })
        );
      } else {
        dispatch(
          updateAgencyPaymentTableFilters({
            completed: {
              pageNumber: page,
              pageSize: sizePerPage,
            },
          })
        );
      }
    },
    [tableFunctions, draftPaymentsOnly, dispatch]
  );

  const getDropdownOptionsForDraftPaymentsOnly = useCallback(
    (row: IAgencyPayment): IDropdownAction[] => {
      const actions: { label: string; onClick: () => void }[] = [];

      canEditAgencyPayment && actions.push({ label: 'Edit', onClick: () => onEdit(row) });
      canDeleteAgencyPayment && actions.push({ label: 'Delete', onClick: () => onDelete(row) });

      return actions;
    },
    [canEditAgencyPayment, canDeleteAgencyPayment]
  );

  const handleSort = useCallback(
    (column: string, direction: ElasticsearchSortDirection) => {
      const sortDirection = direction === 'ASCENDING' ? 'asc' : 'desc';

      dispatch(updateAgencyPaymentTableFilters({ sortColumn: column, sortDirection }));
    },
    [dispatch]
  );

  const centerSelectOptions = orderBy(getEntityData?.getEntity.centers ?? [], (center) => center.name, 'asc');
  const agencySelectOptions = orderBy(
    getAgenciesForBusinessData?.getAgenciesForBusiness ?? [],
    (agency) => agency.name,
    'asc'
  ).filter((a) => !a.archivedAt);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <DataTable
      data={pagedTableData.data}
      dataSize={pagedTableData.totalRecords}
      showSelect={false}
      onSort={handleSort}
      pageSize={pagedTableData.pageSize}
      activePage={pagedTableData.pageNumber}
      onPageChange={handlePageChange}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      showLoadingOverlay={isLoading}
      noPadding={true}
      columns={[
        {
          text: t('subsidies:agency-billing.agency-payments.period-column'),
          dataField: 'startDate',
          sort: true,
          formatter: (cell: string, row: IAgencyPayment) =>
            `${moment(row.startDate, 'YYYY-MM-DD').format('MM/DD/YYYY')} - ${moment(row.endDate, 'YYYY-MM-DD').format(
              'MM/DD/YYYY'
            )}`,
        },
        {
          text: t('subsidies:agency-billing.agency-payments.agency-column'),
          dataField: 'agencyName',
          sort: true,
        },
        {
          text: t('subsidies:agency-billing.agency-payments.center-column'),
          dataField: 'centerName',
          sort: true,
        },
        {
          text: t('subsidies:agency-billing.agency-payments.check-column'),
          dataField: 'checkNumber',
          sort: true,
          formatter: (cell: string, row: IAgencyPayment) => `${Boolean(row.checkNumber) ? row.checkNumber : '--'}`,
        },
        {
          text: t('subsidies:agency-billing.agency-payments.expected-column'),
          dataField: 'amountExpected',
          sort: true,
          formatter: (cell: number, row: IAgencyPayment) => currencyFormat(row.amountExpected),
        },
        {
          text: t('subsidies:agency-billing.agency-payments.paid-column'),
          dataField: 'amountPaid',
          sort: true,
          formatter: (cell: number, row: IAgencyPayment) => currencyFormat(row.amountPaid),
        },
        {
          text: t('subsidies:agency-billing.agency-payments.difference-column'),
          dataField: 'amountDifference',
          sort: true,
          formatter: (cell: number, row: IAgencyPayment) => currencyFormat(row.amountDifference),
        },
        {
          text: t('subsidies:agency-billing.agency-payments.adjusted-column'),
          dataField: 'isAdjusted',
          sort: true,
          formatter: (cell: number, row: IAgencyPayment) => (row.isAdjusted ? 'Yes' : 'No'),
        },
        {
          text: t('subsidies:agency-billing.agency-payments.submitted-column'),
          dataField: 'submittedBy',
          sort: true,
          formatter: (cell: any, row: IAgencyPayment) =>
            row.submittedByPerson && <PersonAvatar person={row.submittedByPerson} size="md" />,
        },
        {
          text: t('subsidies:agency-billing.agency-payments.actions-column'),
          dataField: '',
          formatter: (cell: any, row: IAgencyPayment) =>
            draftPaymentsOnly ? (
              getDropdownOptionsForDraftPaymentsOnly(row).length > 0 ? (
                <ActionDropdown actions={getDropdownOptionsForDraftPaymentsOnly(row)} />
              ) : null
            ) : (
              <ActionDropdown actions={[{ label: 'View', onClick: () => onView(row) }]} />
            ),
        },
      ]}
      renderHeader={(paginationProps, searchProps) => (
        <div className="align-items-center">
          <TableHeader className="flex-wrap">
            <TableSearch
              onChange={handleSearchTerm}
              className={isMobile ? 'my-1 mr-3' : 'mr-3 flex-shrink-0 flex-grow-0'}
            />
            <div className={isMobile ? 'd-flex flex-nowrap my-1' : 'd-flex align-items-center '}>
              <DateInput
                date={tableFilters.startDate}
                onDateSelect={(start) => dispatch(updateAgencyPaymentTableFilters({ startDate: start }))}
                className="mr-2 flex-shrink-3 flex-grow-0 mb-0"
              />
              <div className={isMobile ? 'mt-2 mr-2' : 'mr-2'}>to</div>
              <DateInput
                date={tableFilters.endDate}
                onDateSelect={(end) => dispatch(updateAgencyPaymentTableFilters({ endDate: end }))}
                className="mr-4 flex-grow-0 mb-0"
              />
            </div>
            <div className={isMobile ? 'd-flex align-items-center' : 'd-flex align-items-center ml-auto'}>
              <DropdownFilter
                title="Center"
                className="mr-4"
                selectedFilters={tableFilters.centerIds}
                options={centerSelectOptions
                  .map((c) => ({ label: c.name, value: c.id }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                onFilterSelect={(centers) =>
                  handleFilterSelect(
                    centers.map((c) => c.value),
                    'centerIds'
                  )
                }
              />
              <DropdownFilter
                title="Agency"
                className="mr-4"
                selectedFilters={tableFilters.agencyIds}
                options={agencySelectOptions.map((c) => ({ label: c.name, value: c.id }))}
                onFilterSelect={(agencies) =>
                  handleFilterSelect(
                    agencies.map((c) => c.value),
                    'agencyIds'
                  )
                }
              />
              <IconButtonCircle
                icon={faTimes}
                onClick={() =>
                  dispatch(updateAgencyPaymentTableFilters({ centerIds: [], agencyIds: [], searchTerm: '' }))
                }
                tooltipDirection="bottom"
                tooltipText="Clear Filters"
              />
            </div>
          </TableHeader>
        </div>
      )}
    />
  );
};

export default AgencyPaymentsTable;
