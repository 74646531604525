export const baseDepositFields: string = `
  transactionId
  centerId
  accountId
  childId
  description
  amount
  date
`;

export const depositFields: string = `
  ${baseDepositFields}
`;

export const accountDepositsBalanceFields = `
  accountId
  centerId
  name
  center {
    name
    id
  }
  balance
  childIds
  children {
    accountChildren
    {
      id
      firstname
      lastname
      nickname
      fullName
      dob
      avatar{
        url
      }
      archivedAt
    }
  }
`;

export const accountDepositsBalancePagedResults = `
  totalRecords
  pageNumber
  data {
    ${accountDepositsBalanceFields}
  }
`;

export const accountDepositsFields = `
transactionId
childId
date
description
amount
createdAt
createdBy
createdByPerson {
  id
  firstname
  nickname
  lastname
}
`;

export const accountDepositsSummaryFields = `
childId
amount
`;
