import React from 'react';
import moment from 'moment';

interface IClassEnrollmentWeekShape {
  startOfWeek: moment.Moment;
  endOfWeek: moment.Moment;
}

const ClassEnrollmentWeekContext = React.createContext<IClassEnrollmentWeekShape>({
  startOfWeek: moment().startOf('week'),
  endOfWeek: moment().endOf('week'),
});

ClassEnrollmentWeekContext.displayName = 'ClassEnrollmentWeekProvider';

export default ClassEnrollmentWeekContext;
