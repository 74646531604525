import React from 'react';
import BSButton, { ButtonProps } from 'react-bootstrap/Button';
import Spinner from 'shared/components/Spinner';
import { usePermission } from '../../hooks/usePermission';

export interface IProps extends ButtonProps {
  id?: string;
  children: React.ReactNode | null;
  loading?: boolean;
  className?: string;
  onClick?: () => void;
  permission?: IAreaPermissionLevel;
}

const Button: React.FC<IProps> = ({ id = '', children, loading, disabled, permission, ...props }) => {
  const hasPermission = usePermission(permission);
  return (
    <BSButton {...props} id={id} disabled={!hasPermission || disabled || loading}>
      {loading ? <Spinner small className="text-gray" /> : children}
    </BSButton>
  );
};

export default Button;
