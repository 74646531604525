import { useArchiveRestriction } from 'gql/restriction/mutations';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { updateChildRestrictionSuccess } from '../../../duck/actions';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  restriction: IRestriction;
  childId: string;
}

const ArchiveRestrictionModal: React.FC<IProps> = ({ isOpen, onClose, restriction, childId }) => {
  const dispatch = useDispatch();
  const [archiverestriction, { loading }] = useArchiveRestriction();

  const handleSave = useCallback(() => {
    archiverestriction({
      variables: {
        id: restriction.id,
      },
    })
      .then((data) => {
        showToast('Restriction archived.', 'success');
        data.data?.archiveRestriction &&
          dispatch(updateChildRestrictionSuccess(childId, data.data?.archiveRestriction));
        onClose();
      })
      .catch(() => {
        showToast('There was an error archiving restriction.', 'error');
      });
  }, [onClose, restriction]);

  return (
    <ConfirmationModal
      title="Archive Restriction?"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Archive"
      primaryCallback={handleSave}
      primaryButtonProps={{ loading, variant: 'danger' }}
      hideOnCallback={false}
    >
      <div className="mb-4">Are you sure you want to archive this child's {restriction.name} restriction?</div>
      <div>The history for this restriction will remain viewable, but not editable, once it's archived.</div>
    </ConfirmationModal>
  );
};

export default ArchiveRestrictionModal;
