import React, { useState } from 'react';
import { Row, Col } from 'shared/components/Layout';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  isOpen: boolean;
  removeDestinationName: string;
  removeDestinationId: string;
  newDestinations: any;
  onConfirm: (destinations: any, destinationRemovedId: string) => void;
  onClose: () => void;
}

const ConfirmDestinationRemovalModal: React.FC<IProps> = ({
  isOpen,
  removeDestinationName,
  removeDestinationId,
  newDestinations,
  onConfirm,
  onClose,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      title={t('bus-roster.confirm-destination-removal-modal-title')}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Confirm"
      primaryCallback={() => {
        onConfirm(newDestinations, removeDestinationId);
        onClose();
      }}
    >
      <Row>
        <Col xs={12} className="order-1 w-100%">
          <div>
            <p>{`${t('bus-roster.confirm-destination-removal-modal-body-header', {
              removeDestinationName: removeDestinationName,
            })}`}</p>
            <p>{`${t('bus-roster.confirm-destination-removal-modal-body-text', {
              removeDestinationName: removeDestinationName,
            })}`}</p>
          </div>
        </Col>
      </Row>
    </ConfirmationModal>
  );
};

export default ConfirmDestinationRemovalModal;
