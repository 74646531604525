import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Button from 'shared/components/Buttons';
import PageWrapper from 'shared/components/PageWrapper';
import { showToast } from 'shared/components/Toast';
import BulkTransactionDetails from './components/BulkTransactionsDetails';
import BulkTransactionTargets from './components/BulkTransactionTargets';
import ResolveTransactionDrawer from './components/ResolveTransactionDrawer';
import { useBulkTransactionsDetails } from './hooks/useBulkTransactionDetails';
import { useGetTransactionTypeForBulkTransaction } from './hooks/useGetTransactionTypeForBulkTransaction';

interface IRouteParams {
  bulkTransactionId: string;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const BulkTransactionsDetailsPage: React.FC<IProps> = (props) => {
  const bulkTransactionId = props.match.params.bulkTransactionId;
  const history = useHistory();
  const [resolvingTarget, setResolvingTarget] = useState<{ targetId: string } | undefined>(undefined);
  const { bulkTransaction, resolveTarget } = useBulkTransactionsDetails(bulkTransactionId, {
    onResolutionError: () => showToast('Error resolving transaction', 'error'),
    onResolutionSuccess: () => {
      setResolvingTarget(undefined);
      showToast('Success', 'success');
    },
    onErrorLoadingBulkTransaction: (message: string) => {
      showToast(`Error loading Bulk Transaction: ${message}. Please try again later`, 'error');
    },
  });
  const { transactionType, loading: loadingTransactionTypes } =
    useGetTransactionTypeForBulkTransaction(bulkTransaction);
  const resolutionSummary = useMemo(() => {
    const selectedTarget = bulkTransaction?.targets.find((t) => t.id === resolvingTarget?.targetId);
    return {
      accountOptions: selectedTarget?.accountName ? [{ value: 1, label: selectedTarget.accountName }] : [],
      selectedAccount: 1,
      childrenOptions: selectedTarget?.childName ? [{ value: 1, label: selectedTarget.childName }] : [],
      selectedChild: 1,
      transactionTypeOptions: transactionType ? [{ label: transactionType.name, value: transactionType.id }] : [],
      selectedTransactionType: transactionType?.id || '',
      description: bulkTransaction?.description,
      transactionDate: bulkTransaction?.date,
      amount: bulkTransaction?.amount,
    };
  }, [
    bulkTransaction?.amount,
    bulkTransaction?.date,
    bulkTransaction?.description,
    bulkTransaction?.targets,
    resolvingTarget?.targetId,
    transactionType,
  ]);
  const handleResolutionConfirmation = useCallback(() => {
    if (resolvingTarget) resolveTarget(resolvingTarget.targetId);
  }, [resolveTarget, resolvingTarget]);
  return (
    <PageWrapper
      pageTitle="Bulk Transaction"
      buttonComponent={
        <Button variant="outline-secondary" onClick={() => history.push('/billing/transactions')}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon
              size="2x"
              style={{ maxHeight: '1.5rem', marginRight: '1.5rem' }}
              icon={faAngleLeft}
            ></FontAwesomeIcon>
            Back To Transactions
          </div>
        </Button>
      }
    >
      {bulkTransaction === undefined ? (
        <Spinner animation="border"></Spinner>
      ) : (
        <>
          <BulkTransactionDetails
            bulkTransaction={bulkTransaction}
            transactionTypeLoading={loadingTransactionTypes}
            transactionTypeOptions={transactionType ? [transactionType] : []}
            appliesTo={bulkTransaction.targetType}
            disabled={true}
          ></BulkTransactionDetails>
          <BulkTransactionTargets
            targetType={bulkTransaction.targetType}
            targets={bulkTransaction?.targets}
            onTargetAction={(targetId: string) => {
              setResolvingTarget({ targetId });
            }}
          ></BulkTransactionTargets>
        </>
      )}
      <ResolveTransactionDrawer
        show={!!resolvingTarget}
        accountOptions={resolutionSummary.accountOptions}
        selectedAccount={resolutionSummary.selectedAccount}
        childrenOptions={resolutionSummary.childrenOptions}
        selectedChild={resolutionSummary.selectedChild}
        transactionTypeOptions={resolutionSummary.transactionTypeOptions}
        selectedTransactionType={resolutionSummary.selectedTransactionType}
        description={resolutionSummary.description}
        amount={resolutionSummary.amount}
        transactionDate={resolutionSummary.transactionDate}
        onCancel={() => setResolvingTarget(undefined)}
        onResolve={handleResolutionConfirmation}
      ></ResolveTransactionDrawer>
    </PageWrapper>
  );
};

export default BulkTransactionsDetailsPage;
