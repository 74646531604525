import React from 'react';
import ToggleButton, { ToggleButtonProps } from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup, { ToggleButtonRadioProps } from 'react-bootstrap/ToggleButtonGroup';

interface IRadioButtonProps extends ToggleButtonProps {
  label: string;
}

interface IProps extends ToggleButtonRadioProps<string> {
  name: string;
  value: string;
  options: IRadioButtonProps[];
  onChange?: (value: string, event: any) => void;
}

const RadioButtonGroup = ({ options, name, value, ...props }: IProps) => {
  return (
    <div>
      <ToggleButtonGroup type="radio" name={name} value={value} {...props}>
        {options.map((option, index) => (
          <ToggleButton key={index} {...option}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default RadioButtonGroup;
