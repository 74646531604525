import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { useDeleteSessionTimeEntry } from 'gql/session/mutations';
import { showToast } from 'shared/components/Toast';
import { capitalize } from 'shared/util/string';
import { useDispatch } from 'react-redux';
import { removeSessionTimeEntry } from '../duck/actions';

interface IProps {
  isOpen: boolean;
  timeEntry: IAttendanceTimeEntry;
  timezone: Timezone;
  onClose: () => void;
}

const DeleteSessionTimeEntryModal: React.FC<IProps> = ({ isOpen, timeEntry, timezone, onClose, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteSessionTimeEntryFn, { loading: deleteSessionTimeEntryLoading }] = useDeleteSessionTimeEntry({
    onCompleted: (result) => {
      showToast(t('attendance.delete-time-entry.success-toast'), 'success');
      dispatch(removeSessionTimeEntry(result.deleteSessionTimeEntry));
      onClose();
    },
    onError: (err) => {
      showToast(t('attendance.delete-time-entry.failure-toast'), 'error');
    },
  });
  const handleSave = useCallback(
    (timeEntry: IAttendanceTimeEntry) => {
      deleteSessionTimeEntryFn({
        variables: {
          input: {
            timeEntryId: timeEntry.id,
            sessionId: timeEntry.sessionId,
          },
        },
      });
    },
    [deleteSessionTimeEntryFn]
  );

  return (
    <CenteredModal
      title={t('attendance.delete-time-entry.modal-title')}
      show={isOpen}
      onHide={onClose}
      primaryChoice={capitalize(t('spelling.delete'))}
      primaryCallback={() => handleSave(timeEntry)}
      primaryButtonProps={{ variant: 'danger', loading: deleteSessionTimeEntryLoading }}
    >
      <p>{t('attendance.delete-time-entry.modal-warning')}</p>
      <p className="mb-0">Time In: {moment(timeEntry.timeIn).tz(timezone).format('h:mm A')}</p>
      {timeEntry.timeOut && <p className="mb-0">Time Out: {moment(timeEntry.timeOut).tz(timezone).format('h:mm A')}</p>}
    </CenteredModal>
  );
};

export default DeleteSessionTimeEntryModal;
