export enum ReportingCategoryEnum {
  FINANCE = 'FINANCE',
  FAMILIES = 'FAMILIES',
  ATTENDANCE = 'ATTENDANCE',
}

export enum ReportTypeEnum {
  ATTENDANCE = 'ATTENDANCE',
  WEEKLY_ATTENDANCE = 'WEEKLY_ATTENDANCE',
  BALANCE_AT_DATE = 'BALANCE_AT_DATE',
  CONTRACT = 'CONTRACT',
  DEBT = 'DEBT',
  EMERGENCY_CONTACTS = 'EMERGENCY_CONTACTS',
  FAT_ZEBRA_TRANSACTIONS = 'FAT_ZEBRA_TRANSACTIONS',
  OCCUPANCY_TOTALS = 'OCCUPANCY_TOTALS',
  SESSION_FEES_AND_TIMES = 'SESSION_FEES_AND_TIMES',
  SIGN_IN_OUT = 'SIGN_IN_OUT',
  TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH = 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH',
  TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_WEEK = 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_WEEK',
  TRANSACTION_TOTALS_PER_MONTH = 'TRANSACTION_TOTALS_PER_MONTH',
  TRANSACTION_TOTALS_PER_WEEK = 'TRANSACTION_TOTALS_PER_WEEK',
  WEEKLY_PAYROLL = 'WEEKLY_PAYROLL',
  TRANSACTIONS = 'TRANSACTION',
  PAYMENTS = 'PAYMENT',
  PROGRAMS = 'PROGRAMS',
  NAME_TO_FACE = 'NAME_TO_FACE',
  CHILD_CHECK_IN = 'CHILD_CHECK_IN',
  QUARTER_HOUR_CHECK_IN = 'QUARTER_HOUR_CHECK_IN',
  CHILD_HEALTH_AND_WELLNESS = 'CHILD_HEALTH_AND_WELLNESS',
  CHILD_LAST_ATTENDANCE = 'CHILD_LAST_ATTENDANCE',
  EMPLOYEE_TIMESHEET = 'EMPLOYEE_TIMESHEET',
  ADP_WEEKLY_PAYROLL = 'ADP_WEEKLY_PAYROLL',
  CCS_SUBSIDY = 'CCS_SUBSIDY',
  CCS_SUBSIDY_REMITTANCE = 'CCS_SUBSIDY_REMITTANCE',
  CSS_ENTITLEMENTS = 'CCS_ENTITLEMENTS',
  SAGE_GL = 'SAGE_GL',
  ACCOUNT_CONTACT_INFORMATION = 'ACCOUNT_CONTACT_INFORMATION',
  SAGE_CASH = 'SAGE_CASH',
  CLASS_ATTENDANCE_ROSTER = 'CLASS_ATTENDANCE_ROSTER',
  OUTSTANDING_CCS = 'OUTSTANDING_CCS',
  BILLING_SUMMARY = 'BILLING_SUMMARY',
  CONTRACT_FEE_OVERVIEW = 'CONTRACT_FEE_OVERVIEW',
  CONTRACT_FEE_DETAILED_OVERVIEW = 'CONTRACT_FEE_DETAILED_OVERVIEW',
  ROYALTY = 'ROYALTY',
  NEW_CONTRACTS = 'NEW_CONTRACTS',
  CHILD_TRACKING = 'CHILD_TRACKING',
  ENDED_CONTRACTS = 'ENDED_CONTRACTS',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  CONTRACT_EDITS = 'CONTRACT_EDITS',
  QLD_CENSUS = 'QLD_CENSUS',
  PAYRIX_DISBURSEMENT = 'PAYRIX_DISBURSEMENT',
  ACCS = 'ACCS',
  EXCURSION = 'EXCURSION',
  SAGE_GL_DETAILS = 'SAGE_GL_DETAILS',
  CHILD_ATTENDANCE_SUMMARY = 'CHILD_ATTENDANCE_SUMMARY',
  DEPOSITS_HELD = 'DEPOSITS_HELD',
  // TODO: change this later to KINDY_FOR_ALL_FORECAST
  KINDY_FOR_ALL = 'KINDY_FOR_ALL',
  //KINDY_FOR_ALL_FORECAST = 'KINDY_FOR_ALL_FORECAST',
  KINDY_FOR_ALL_FORECAST_ERRORS = 'KINDY_FOR_ALL_FORECAST_ERRORS',
  KINDY_FOR_ALL_ACQUITTAL = 'KINDY_FOR_ALL_ACQUITTAL',
  KINDY_FOR_ALL_ACQUITTAL_ERRORS = 'KINDY_FOR_ALL_ACQUITTAL_ERRORS',
  ATTENDANCE_TOTALS_PER_WEEK = 'ATTENDANCE_TOTALS_PER_WEEK',
  DISBURSEMENTS_MONTHLY = 'DISBURSEMENTS_MONTHLY',
  MEAL_TRACKING = 'MEAL_TRACKING',
  CCS_ENROLMENTS = 'CCS_ENROLMENTS',
  CUSTOM_FIELD_REPORT = 'CUSTOM_FIELD_REPORT',
  BILLING_EDITS = 'BILLING_EDITS',
  REENROL_HISTORY = 'REENROL_HISTORY',
}

export enum ReportIntervalEnum {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}
