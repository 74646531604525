import { gql } from '@apollo/client';

export const NOTIFICATION_FIELDS = gql`
  fragment NotificationFields on NotificationDto {
    id
    businessId
    businessName
    centerId
    centerName
    dateTime
    description
    level
    metadata
    recurrenceKey
    archived
    read
    type
    category
  }
`;
