import { documentFields } from 'gql/document/fields';

export const immunizationFields = `
  id
  businessId
  childId
  createdAt
  createdBy
  createdByPerson {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
  }
  lastModifiedAt
  lastModifiedBy
  lastModifiedByPerson {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
  }
  activeDocument {
    ${documentFields}
  }
`;
