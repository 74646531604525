export const documentFields: string = `
  id
  businessId
  name
  type
  expirationDate
  link
  reviewDaysBeforeExpiration
  reviewDate
  wellnessItemId
  archivedAt
  archivedBy
  createdAt
  createdByPerson {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
  }
`;

export const documentWithPersonFields = `
  ${documentFields}
  associatedEntityId
`;

export const pagedFields = `
  totalRecords
  data {
    ${documentWithPersonFields}
  }
`;
