import React, { useState, useCallback } from 'react';
import { sortBy } from 'lodash';
import SpaceCard from './SpaceCard';
import { Row, Col } from 'shared/components/Layout';
import AddSpaceModal from './AddSpaceModal';
import EditSpaceModal from './EditSpaceModal';
import DeleteSpaceModal from './DeleteSpaceModal';
import { IconButtonCard } from 'shared/components/Buttons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import {
  useCreateSpaces,
  useUpdateSpace,
  useDeleteSpace,
} from 'pages/Centers/subroutes/Profile/graphql/spacesMutations';
import SpaceCardLoading from './SpaceCardLoading';
import { useGetCenterSpaces, useGetClassesForCenter } from 'gql/center/queries';
import { GET_CENTER_SPACES, GET_CLASSES_FOR_CENTER } from 'pages/Centers/subroutes/Profile/graphql/fields';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IProps {
  center: ICenter;
}

const SpacesTab: React.FC<IProps> = ({ center }) => {
  const hasBaseCenterSpacesEdit = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Spaces,
    level: RoleLevelType.Edit,
  });
  const hasBaseCenterSpacesCreate = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Spaces,
    level: RoleLevelType.Create,
  });
  const hasBaseCenterSpacesDelete = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Spaces,
    level: RoleLevelType.Delete,
  });

  //fetch data
  const { data, loading } = useGetCenterSpaces(
    {
      variables: {
        centerId: center?.id ?? '',
      },
    },
    GET_CENTER_SPACES
  );
  // add space
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const toggleAddModal = useCallback(() => setAddModalOpen(!isAddModalOpen), [isAddModalOpen]);
  const [addSpace, { loading: newSpaceLoading }] = useCreateSpaces();
  // edit space
  const [spaceInEdit, updateSpaceInEdit] = useState<ISpace>();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const toggleEditModal = useCallback(() => setEditModalOpen(!isEditModalOpen), [isEditModalOpen]);
  const [saveSpace, { loading: saveSpaceLoading }] = useUpdateSpace();
  // delete space
  const [spaceToDelete, updateSpaceToDelete] = useState<ISpace>();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const toggleDeleteModal = useCallback(() => setDeleteModalOpen(!isDeleteModalOpen), [isDeleteModalOpen]);
  const [deleteSpace, { loading: deleteSpaceLoading }] = useDeleteSpace();
  const { data: classes, loading: getClassesLoading } = useGetClassesForCenter(
    {
      variables: {
        centerId: center?.id ?? '',
      },
    },
    GET_CLASSES_FOR_CENTER
  );

  const getDropdownOptionsForSpace = useCallback(
    (space: ISpace): IDropdownAction[] => {
      const actions: { label: string; onClick: () => void }[] = [];

      hasBaseCenterSpacesEdit &&
        actions.push({
          label: 'Edit',
          onClick: () => {
            updateSpaceInEdit({ ...space });
            toggleEditModal();
          },
        });
      hasBaseCenterSpacesDelete &&
        actions.push({
          label: 'Delete',
          onClick: () => {
            updateSpaceToDelete(space);
            toggleDeleteModal();
          },
        });

      return actions;
    },
    [hasBaseCenterSpacesEdit, hasBaseCenterSpacesDelete, toggleEditModal, toggleDeleteModal]
  );

  if (loading) {
    return (
      <Row>
        {[...Array(4)].map((curr, index) => (
          <Col lg={2} md={4} key={index}>
            <SpaceCardLoading />
          </Col>
        ))}
      </Row>
    );
  }

  // not loading and we don't have data... something may have gone wrong
  if (!loading && !center) {
    return null;
  }

  const sortedSpaces = sortBy(data?.getSpacesForCenter, (space) => space.name.toLowerCase());

  return (
    <div>
      <Row>
        {hasBaseCenterSpacesCreate && (
          <Col xl={2} md={4}>
            <IconButtonCard
              className="h-md"
              onClick={toggleAddModal}
              icon={faPlus}
              label="Add New Space"
              disabled={!hasBaseCenterSpacesEdit}
            />
          </Col>
        )}
        {sortedSpaces.map((space, index) => (
          <Col xl={2} md={4} key={`space-card-${index}`}>
            {newSpaceLoading || saveSpaceLoading ? (
              <SpaceCardLoading />
            ) : (
              <SpaceCard
                actions={getDropdownOptionsForSpace(space)}
                space={space}
                canPerformEdit={hasBaseCenterSpacesEdit}
                loading={deleteSpaceLoading && getClassesLoading && space.id === spaceToDelete?.id}
              />
            )}
          </Col>
        ))}
        {newSpaceLoading && (
          <Col xl={2} md={4}>
            <SpaceCardLoading />
          </Col>
        )}
      </Row>
      <AddSpaceModal isOpen={isAddModalOpen} onClose={toggleAddModal} centerId={center.id} addSpace={addSpace} />
      {spaceInEdit && (
        <EditSpaceModal
          isOpen={isEditModalOpen}
          onClose={toggleEditModal}
          space={spaceInEdit}
          updateSpace={updateSpaceInEdit}
          saveSpace={() => saveSpace(spaceInEdit)}
        />
      )}
      {spaceToDelete && (
        <DeleteSpaceModal
          isOpen={isDeleteModalOpen}
          onClose={toggleDeleteModal}
          space={spaceToDelete}
          classes={classes?.getClassesForCenter?.filter((c) => c.defaultSpaceId === spaceToDelete.id)}
          deleteSpace={() => {
            deleteSpace(spaceToDelete);
          }}
        />
      )}
    </div>
  );
};

export default SpacesTab;
