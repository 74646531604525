import * as types from './types';

export const getAllUnbatchedPayments = (transactions: ITransaction[]): types.PaymentActionTypes => ({
  type: types.GET_ALL_UNBATCHED_PAYMENT_TRANSACTIONS,
  transactions,
});

export const totalUnbatchedPayments = (total: number) => ({
  type: types.GET_TOTAL_UNBATCHED_PAYMENT_TRANSACTIONS,
  total,
});

export const selectPaymentsForBatching = (paymentTransactions: ITransaction[]): types.PaymentActionTypes => ({
  type: types.SELECT_PAYMENTS_FOR_BATCHING,
  paymentTransactions,
});

export const createBatchSuccess = (batch: IBatch): types.PaymentActionTypes => ({
  type: types.CREATE_BATCH_SUCCESS,
  batch,
});

export const setCenterIdToBatch = (centerId: string): types.PaymentActionTypes => ({
  type: types.SET_CENTER_ID_TO_BATCH,
  centerId,
});

export const getBatchesTableData = (batches: IBatch[]): types.PaymentActionTypes => ({
  type: types.GET_BATCHES_TABLE_DATA,
  batches,
});

export const deleteBatchSuccess = (
  batch: IBatch,
  unbatchedPaymentTransactions: ITransaction[]
): types.PaymentActionTypes => ({
  type: types.DELETE_BATCH_SUCCESS,
  batch,
  unbatchedPaymentTransactions,
});
