import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

const useHasRoleAreaLevel = (action: IAreaPermissionLevel): boolean => {
  const user = useSelector((state: RootState) => state.user);

  return user?.hasAreaPermissionLevel(action) ?? false;
};

export default useHasRoleAreaLevel;
