import { gql } from '@apollo/client';
import { useQuery, useLazyQuery } from 'shared/apis/core';

interface IGetStaffByEmailData {
  getStaffByEmail: IStaff;
}

interface IGetStaffByEmailVariables {
  email: string;
}

export const GET_STAFF_BY_EMAIL = gql`
  query ($email: String!) {
    getStaffByEmail(email: $email) {
      id
      entityId
      email
      roleship {
        scopeType
        scopes {
          ... on Entity {
            id
            name
          }
          ... on Center {
            id
            name
          }
        }
      }
    }
  }
`;

export const useGetStaffByEmail = (email: string) =>
  useQuery<IGetStaffByEmailData, IGetStaffByEmailVariables>(GET_STAFF_BY_EMAIL, {
    variables: {
      email,
    },
  });

export const useLazyGetStaffByEmail = (email: string) =>
  useLazyQuery<IGetStaffByEmailData, IGetStaffByEmailVariables>(GET_STAFF_BY_EMAIL, {
    variables: {
      email,
    },
  });
