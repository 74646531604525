import React from 'react';
import { Col, Row } from 'shared/components/Layout';
import Card from 'shared/components/Card';
import AttendanceHistoryTable from './AttendanceHistoryTable';
import { active, pending } from 'shared/constants/StaffStatusSearchExpressions';
import { useSearchStaff } from 'pages/Employees/subroutes/Profiles/graphql/queries';
import ElasticConstants from 'shared/constants/elastic';
import { useGetSessionAmendments } from 'gql/sessionAmendment/queries';
import { useState } from 'react';
import moment from 'moment';
import useDatatableState from 'shared/hooks/useDatatableState2';
import AttendanceDaysForm from './AttendanceDaysForm';
import AttendanceTempAccessForm from './AttendanceTempAccessForm';
import { useTranslation } from 'react-i18next';

interface IAttendanceControlTabProps {
  businessId?: string;
}

const AttendanceControlTab: React.FC<IAttendanceControlTabProps> = ({ businessId }) => {
  const { t } = useTranslation();
  const [showExpired, setShowExpired] = useState(false);
  const elasticScopeValues = [businessId];
  const [tableState, tableFunctions] = useDatatableState();
  const { data: staffs } = useSearchStaff(
    {
      filter: {
        [ElasticConstants.SEARCH_EXPRESSIONS.ALL]: [
          {
            [ElasticConstants.SEARCH_EXPRESSIONS.ANY]: [active, pending],
          },
          {
            [ElasticConstants.SEARCH_EXPRESSIONS.ANY]: [
              {
                [ElasticConstants.SEARCH_EXPRESSIONS.TERM]: {
                  field: 'entityId.keyword',
                  predicate: ElasticConstants.PREDICATES.ONE_OF,
                  value: elasticScopeValues,
                },
              },
            ],
          },
        ],
      },
      sort: [
        {
          field: 'lastname.keyword',
          direction: ElasticConstants.DIRECTIONS.ASCENDING,
        },
      ],
      size: 1000,
      from: 0,
    },
    { skip: businessId === undefined }
  );
  const {
    data: historyData,
    loading,
    refetch,
  } = useGetSessionAmendments({
    variables: {
      input: {
        businessId: businessId ?? '',
        todayDate: showExpired ? null : moment().format('YYYY-MM-DD'),
        pageSize: tableState.pageSize,
        pageNumber: tableState.activePage,
      },
    },
    skip: businessId === undefined,
  });

  return (
    <div>
      <Row align="start">
        <Col>
          <Card header={t('attendance.history-control.lock-period')}>
            <AttendanceDaysForm businessId={businessId || ''} refreshDataGrid={refetch} />
          </Card>
        </Col>
        <Col>
          <Card header={t('attendance.history-control.temporary-access')}>
            <AttendanceTempAccessForm
              refreshDataGrid={refetch}
              staffs={staffs?.searchStaff.data || []}
              businessId={businessId || ''}
            />
          </Card>
        </Col>
      </Row>
      <AttendanceHistoryTable
        refetch={refetch}
        data={historyData?.getSessionAmendments?.data || []}
        totalRecords={historyData?.getSessionAmendments?.totalRecords ?? 0}
        showExpired={showExpired}
        setShowExpired={setShowExpired}
        loading={loading}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        changePage={tableFunctions.changePage}
      />
    </div>
  );
};

export default AttendanceControlTab;
