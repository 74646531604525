import React, { useCallback, useState } from 'react';
import Card from 'shared/components/Card';
import { useUpdateCenterProfile } from '../../../graphql/mutations';
import { omitFalsy, omitTypename } from 'shared/util/object';
import { Button } from 'react-bootstrap';
import { Row } from 'shared/components/Layout';
import { showToast } from 'shared/components/Toast';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import ConfirmDisableBillingModal from '../ConfirmDisableBillingModal';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { RoleLevelType, AreaType, PermissionType } from 'shared/constants/enums/permissionsEnums';
import Switch from 'shared/components/Switch';
import { useTranslation } from 'react-i18next';
import BillingCycleTemplateSelect from 'shared/components/Select/BillingCycleTemplateSelect';
import Alert from 'shared/components/Alert';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import useFormatDate from 'shared/hooks/useFormatDate';

interface IProps {
  center: ICenter;
  primaryChoice?: string | null;
  secondaryChoice?: string | null;
  blockButtons?: boolean;
}

const EnableBillingCard: React.FC<IProps> = ({
  center,
  primaryChoice = 'Save',
  secondaryChoice = 'Cancel',
  blockButtons = false,
}) => {
  const { t } = useTranslation(['billing', 'translation']);
  const { k2BillingStartDate } = useFlags();
  const [formData, setFormData] = useState({ ...center });
  const [centerData, setCenterData] = useState({ ...center });
  const [formIsDirty, setFormIsDirty] = useState(false);
  const enableBillingCycleProcessing = formData?.billingEnabledSettings?.enableBillingCycleProcessing || false;

  const hasCreateCenterBilling = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.BillingCycle,
    level: RoleLevelType.Create,
  });

  const hasEditCenterBilling = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.BillingCycle,
    level: RoleLevelType.Edit,
  });

  const hasDeleteCenterBilling = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.BillingCycle,
    level: RoleLevelType.Delete,
  });

  const hasEditOrCreate = hasCreateCenterBilling || hasEditCenterBilling;

  // A user that has edit or create can enable billing if it was previously disabled
  // A user that has edit or create can not disable billing
  // A user that has delete can enable or disable billing
  // A user with read can only view the setting
  const disableSwitch = () => {
    if (!hasEditOrCreate && !hasDeleteCenterBilling) {
      return true;
    }

    if (hasEditOrCreate && centerData.enableBillingCycleProcessing && !hasDeleteCenterBilling) {
      return true;
    }

    return false;
  };

  const resetForm = useCallback(() => {
    setFormData(center);
    setCenterData(center);
    setFormIsDirty(false);
  }, [center]);

  const [isConfirmDisableBillingModalOpen, setConfirmDisableBillingModalOpen] = useState(false);

  const [updateCenterProfile, { loading, error }] = useUpdateCenterProfile();

  const handleInput = useCallback(
    (value, name) => {
      if (!formIsDirty) {
        setFormIsDirty(true);
      }

      setFormData({ ...formData, [name]: value });
    },
    [formData, formIsDirty]
  );

  const saveCenter = useCallback(() => {
    const {
      name,
      address,
      phoneNumber,
      timezone,
      openingDate,
      closingDate,
      email,
      website,
      acn,
      abn,
      externalId,
      taxId,
      enableBillingCycleProcessing,
      defaultBillingCycleTemplateId,
      centerEnrollmentBillingCycleTemplates,
      billingEnabledSettings,
    } = formData;

    const updates = {
      ...center,
      name,
      address,
      phoneNumber,
      timezone,
      openingDate,
      closingDate,
      email,
      website,
      acn,
      abn,
      externalId,
      taxId,
      enableBillingCycleProcessing,
      defaultBillingCycleTemplateId,
      centerEnrollmentBillingCycleTemplates,
      billingEnabledSettings,
    };

    const centerInfoWithoutTypes: any = omitTypename(updates, true);
    const addressWithoutFalsy = omitTypename(omitFalsy(address), true);
    const updateCenterInput = {
      id: centerInfoWithoutTypes.id,
      name: centerInfoWithoutTypes.name,
      phoneNumber: centerInfoWithoutTypes.phoneNumber,
      owner: centerInfoWithoutTypes.owner,
      address: addressWithoutFalsy,
      primaryContactPersonId: centerInfoWithoutTypes.primaryContactPersonId,
      secondaryContactPersonId: centerInfoWithoutTypes.secondaryContactPersonId,
      tags: centerInfoWithoutTypes.tags.map((tag: any) => tag.id),
      timezone: centerInfoWithoutTypes.timezone,
      openingDate: centerInfoWithoutTypes.openingDate,
      closingDate: centerInfoWithoutTypes.closingDate,
      email: centerInfoWithoutTypes.email,
      website: centerInfoWithoutTypes.website,
      acn: centerInfoWithoutTypes.acn,
      abn: centerInfoWithoutTypes.abn,
      externalId: centerInfoWithoutTypes.externalId,
      taxId: centerInfoWithoutTypes.taxId,
      enableBillingCycleProcessing: centerInfoWithoutTypes.enableBillingCycleProcessing,
      defaultBillingCycleTemplateId: centerInfoWithoutTypes.defaultBillingCycleTemplateId,
      centerEnrollmentBillingCycleTemplateIds: centerInfoWithoutTypes?.centerEnrollmentBillingCycleTemplates?.map(
        (t: CenterEnrollmentBillingCycleTemplate) => t.billingCycleTemplateId
      ),
      billingEnabledSettings: {
        centerId: centerInfoWithoutTypes.id,
        enableBillingCycleProcessing: centerInfoWithoutTypes.billingEnabledSettings.enableBillingCycleProcessing,
        enableBillingCycleProcessingEffectiveDate:
          centerInfoWithoutTypes.billingEnabledSettings.enableBillingCycleProcessingEffectiveDate,
      },
    };

    updateCenterProfile({
      variables: {
        input: updateCenterInput,
      },
    }).then((res) => {
      setFormData({ ...formData, enableBillingCycleProcessing: centerInfoWithoutTypes.enableBillingCycleProcessing });
      setCenterData({ ...center, enableBillingCycleProcessing: centerInfoWithoutTypes.enableBillingCycleProcessing });
      showToast('Billing Status Updated Successfully.', 'success');
    });

    setFormIsDirty(false);
  }, [center, formData, updateCenterProfile]);

  const handleToggleChange = (val: boolean) => {
    var billingEnabledSettings = formData?.billingEnabledSettings;

    if (!billingEnabledSettings) {
      billingEnabledSettings = {
        centerId: formData?.id,
        enableBillingCycleProcessing: val,
      };
    } else {
      billingEnabledSettings = { ...billingEnabledSettings, enableBillingCycleProcessing: val };
    }

    setFormData({ ...formData, billingEnabledSettings, enableBillingCycleProcessing: val });
    setFormIsDirty(true);
  };

  const checkForConfirmation = useCallback(() => {
    if (!formData.enableBillingCycleProcessing) {
      setConfirmDisableBillingModalOpen(true);
    } else {
      saveCenter();
    }
  }, [formData, saveCenter]);

  const handleEnrollmentBillingCycleTemplatesChange = (v: string[]) => {
    if (v.length > 0) {
      const enrollmentBillingCycleTemplates = v.map<
        Omit<CenterEnrollmentBillingCycleTemplate, 'id' | 'billingCycleTemplate'>
      >((templateId) => ({
        centerId: center.id,
        billingCycleTemplateId: templateId,
      }));
      handleInput(enrollmentBillingCycleTemplates, 'centerEnrollmentBillingCycleTemplates');
    } else {
      handleInput([], 'centerEnrollmentBillingCycleTemplates');
    }
  };

  const handleDefaultBillingCycleTemplatesChange = (v: string[]) => {
    if (v.length > 0) {
      handleInput(v[0], 'defaultBillingCycleTemplateId');
    } else {
      handleInput(null, 'defaultBillingCycleTemplateId');
    }
  };

  const formatDate = useFormatDate();

  return (
    <>
      <form onSubmit={saveCenter} className="d-flex flex-column">
        {loading && <LoadingLines />}
        {!loading && (
          <Card header="Settings">
            {k2BillingStartDate && (
              <>
                <Alert variant="info" className="mb-3">
                  {enableBillingCycleProcessing && (
                    <p className="small my-0">{t('billing:enable-billing-card.enable-billing-cycle-processing')}</p>
                  )}
                  {!enableBillingCycleProcessing && (
                    <p className="small my-0">{t('billing:enable-billing-card.disable-billing-cycle-processing')}</p>
                  )}
                </Alert>
                <div className="mb-3">
                  <Switch
                    value={!!formData?.billingEnabledSettings?.enableBillingCycleProcessing}
                    onChange={handleToggleChange}
                    className="billing-toggle"
                    disabled={disableSwitch()}
                    label={t('billing:centers.enable-billing-title')}
                    labelSide="left"
                    height={30}
                  />
                </div>
                <div className="mb-3">
                  {(formData?.billingEnabledSettings?.billingEnabledAt ||
                    formData?.billingEnabledSettings?.billingEnabledBy) && (
                    <span>
                      Enabled by {formData.billingEnabledSettings.billingEnabledByPerson?.lastname},{' '}
                      {formData.billingEnabledSettings.billingEnabledByPerson?.firstname} on{' '}
                      {formatDate(moment(formData.billingEnabledSettings.billingEnabledAt), 'MM/DD/YYYY')}
                    </span>
                  )}
                </div>
              </>
            )}
            <div>
              <BillingCycleTemplateSelect
                isDisabled={!hasEditCenterBilling}
                label={t('billing:center-billing-settings.billing-cycles-for-enrollment')}
                selectedBillingCycleTemplateIds={formData.centerEnrollmentBillingCycleTemplates?.map(
                  (t) => t.billingCycleTemplateId
                )}
                businessId={center.entityId}
                isMulti
                onSelect={handleEnrollmentBillingCycleTemplatesChange}
                placeholder={t('billing:center-billing-settings.billing-cycles-for-enrollment')}
                centerId={center.id}
              />
            </div>
            <div>
              <BillingCycleTemplateSelect
                isDisabled={!hasEditCenterBilling}
                businessId={center.entityId}
                label={t('billing:center-billing-settings.default-billing-cycle')}
                isMulti={false}
                selectedBillingCycleTemplateIds={
                  formData.defaultBillingCycleTemplateId ? [formData.defaultBillingCycleTemplateId] : null
                }
                onSelect={handleDefaultBillingCycleTemplatesChange}
                placeholder={t('billing:center-billing-settings.default-billing-cycle')}
                centerId={center.id}
              />
            </div>
            {formIsDirty && (
              <Row noGutters justify="end" className="mt-4">
                {secondaryChoice && (
                  <Button className={blockButtons ? 'btn-block mb-2' : 'mr-4'} variant="light" onClick={resetForm}>
                    {secondaryChoice}
                  </Button>
                )}
                {primaryChoice && (
                  <Button className={blockButtons ? 'btn-block' : ''} onClick={checkForConfirmation}>
                    {primaryChoice}
                  </Button>
                )}
              </Row>
            )}
          </Card>
        )}
      </form>
      <ConfirmDisableBillingModal
        isOpen={isConfirmDisableBillingModalOpen}
        onClose={() => {
          resetForm();
          setConfirmDisableBillingModalOpen(false);
        }}
        onConfirm={() => saveCenter()}
      />
    </>
  );
};

export default EnableBillingCard;
