import config from 'config';
import { CountryCode, parsePhoneNumber, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';

/**
 * Parses a string and returns it as a {PhoneNumber} or {undefined}.
 * @param {String} numberToParse Number that we are attempting to parse.
 * @returns {PhoneNumber | undefined}
 */
export const parsePhoneNumberFromStringWithRegion = (numberToParse: string): PhoneNumber | undefined => {
  return parsePhoneNumberFromString(numberToParse, config.locale.region as CountryCode);
};

/**
 * Parses a string and returns it as a {PhoneNumber} or throws.
 * @param {String} numberToParse Number that we are attempting to parse.
 * @returns {PhoneNumber}
 */
export const parsePhoneNumberWithRegion = (numberToParse: string): PhoneNumber => {
  return parsePhoneNumber(numberToParse, config.locale.region as CountryCode);
};

/**
 * Test if a provided string is empty
 * @param {String} str
 * @returns {Boolean}
 */
export const isBlank = (str: string): boolean => !String(str) || /^\s*$/.test(str);

/**
 * Returns the provided string in proper case
 * @param {String} str
 * @returns {String}
 */
export const toProperCase = (str: string): string =>
  str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

/**
 * Test if a value is a string
 * @param {*} str
 * @returns {Boolean}
 */
export const isString = (s: any): boolean => typeof s === 'string';

/**
 * Test if a string is a valid phone number
 * @param number
 * @returns {Boolean}
 */
export const isValidPhoneNumber = (number: string = ''): boolean =>
  parsePhoneNumberFromStringWithRegion(number)?.isValid() ?? false;

/**
 * Test if a string is a valid postal code
 *
 * @param postalCode
 * @returns {Boolean}
 */
export const isValidPostalCode = (postalCode: string | number): boolean => {
  const postalCodeStr = typeof postalCode === 'number' ? postalCode.toString() : postalCode;

  // TODO: Pull this from a database of country info. Right now COUNTRY_INFO lives with the dropdown component.
  if (config.locale.region == 'AU') return /^\d{4}$/.test(postalCodeStr);

  // IMPORTANT NOTE: this currently only tests US zip codes
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postalCodeStr);
};

/**
 * Generate a hex color code from a string
 *
 * Source: https://stackoverflow.com/a/16348977
 *
 * @param String str
 * @returns String
 */
export const stringToColor = (str: string): string => {
  let hash = 0;
  let hexCode = '#';

  for (let i = 0; i < str.length; i++) {
    // convert to lowercase since we want the same string, regardless of casing, to generate the same color
    hash = str.toLowerCase().charCodeAt(i) + ((hash << 5) - hash);
  }

  for (let j = 0; j < 3; j++) {
    const value = (hash >> (j * 8)) & 0xff;
    hexCode += ('00' + value.toString(16)).substr(-2);
  }

  return hexCode;
};

/**
 * Convert string to hue degree
 *
 * Source: https://stackoverflow.com/a/21682946
 *
 * @param String str
 * @returns Nummber
 */
export const stringToHueDegree = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.toLowerCase().charCodeAt(i) + ((hash << 5) - hash);
  }
  // should be positive number
  let degreeFromString = hash % 360;
  if (degreeFromString < 0) {
    degreeFromString *= -1;
  }

  // remove yellow hues for contrast
  if (degreeFromString > 50 && degreeFromString < 80) {
    degreeFromString += 30;
    degreeFromString *= 3;
  }

  return degreeFromString;
};

/**
 * Convert a string to a hsl color code
 * @param id
 * @returns string
 */
export const stringToHsl = (str: string): string =>
  `hsl(${stringToHueDegree(str)}, ${stringToHueDegree(str) < 50 ? '100%' : '40%'}, 40%`;

export const snakeCaseToProperCase = (string: string) => toProperCase(string.replace(/_/g, ' '));
export const snakeCaseToLowerCase = (string: string) => string.replace(/_/g, ' ').toLocaleLowerCase();
export const camelCaseToSpaced = (string: string) => string.replace(/([A-Z])/g, ' $1').trim();

export const getNumberWithOrdinal = (n: number) => {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getFullName = (person?: IPerson | IChild | IContactChild | IAccountChild | IBasicStaff | null) =>
  (person?.nickname ?? person?.firstname ?? '') + ' ' + (person?.lastname ?? '');
export const getInitials = (person?: IPerson | IChild | IContactChild | IAccountChild | IBasicStaff | null) =>
  (person?.nickname ?? person?.firstname ?? '').toUpperCase().charAt(0) +
  (person?.lastname ?? '').toUpperCase().charAt(0);

export const capitalize = (str: string): string => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
