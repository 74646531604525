import React, { useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Button from 'shared/components/Buttons';
import TextInput from 'shared/components/TextInput';
import 'pages/MyProfile/myProfile.scss';
import { showToast } from 'shared/components/Toast';
import { isEmailValid, isEmailSyntaxValid } from 'shared/util/email';
import errorMessage from 'shared/constants/errorMessages';
import { useUpdateContactEmail } from '../../../graphql/mutations';

interface IProps {
  currentEmail: string;
  isOpen: boolean;
  onClose: () => void;
  personId: string;
  isCognitoUserCreated: boolean;
}

const ChangeContactEmailModal: React.FC<IProps> = ({
  currentEmail,
  isOpen,
  onClose,
  personId,
  isCognitoUserCreated,
}) => {
  const [newEmail, updateNewEmail] = useState('');
  const [updateContactEmail, { loading }] = useUpdateContactEmail();
  const handleClose = useCallback(() => {
    onClose();
    updateNewEmail('');
  }, [onClose]);

  const save = useCallback(() => {
    updateContactEmail({
      variables: {
        input: {
          id: personId,
          email: newEmail,
        },
      },
    })
      .then(() => {
        showToast('Contact email updated.', 'success');
        handleClose();
      })
      .catch(() => {
        showToast('There was an error updating the contact email. Please try again later.', 'error');
      });
  }, [updateContactEmail, personId, newEmail, handleClose]);

  return (
    <Modal centered backdrop="static" show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton className="border-bottom-0 pb-0">
        <Modal.Title as="h5">Change Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="d-block mt-2 mb-4">Please enter the new email you'd like to use.</span>
        <Row>
          <Column className="text-right">
            <TextInput label="Current Email" disabled={true} value={currentEmail} type="email" />
            <TextInput
              label="New Email"
              value={newEmail}
              onChange={updateNewEmail}
              isInvalid={!isEmailValid(newEmail)}
              errorText={
                !isEmailSyntaxValid(newEmail) ? errorMessage.invalidEmailSyntax : errorMessage.invalidEmailDomain
              }
              type="email"
            />
            <Button
              onClick={save}
              variant="primary"
              className="w-100"
              loading={loading}
              disabled={!isEmailValid(newEmail)}
            >
              Save Changes
            </Button>
          </Column>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeContactEmailModal;
