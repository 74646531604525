import { useUpdateAccountTags } from 'gql/account/mutations';
import { useGetAccountTagOptions } from 'gql/account/queries';
import React, { useCallback, useMemo, useState } from 'react';
import Alert from 'shared/components/Alert';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Row } from 'shared/components/Layout';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import Tag from 'shared/components/Tag';
import { showToast } from 'shared/components/Toast';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import errorMessage from 'shared/constants/errorMessages';

interface IProps {
  account: IAccount;
  canPerformEdit: boolean;
  className?: string;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const TagsForm: React.FC<IProps> = ({ account, canPerformEdit, className }) => {
  const { data } = useGetAccountTagOptions(account.entityId);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [tags, setTags] = useState(account.tags || []);
  const resetTags = useCallback(() => setTags(account.tags), [account.tags]);
  const tagOptions: ITag[] = (data && data.getAccountTagOptions) || [];
  const selectedTagIds: string[] = tags?.map((tag) => tag.id);
  const unselectedTags: ITag[] = tagOptions.filter((tag) => !selectedTagIds?.includes(tag.id));
  const addTag = useCallback(
    (selectedTag) => {
      if (!formIsDirty) {
        setFormIsDirty(true);
      }

      setTags([...tags, selectedTag]);
    },
    [tags, formIsDirty]
  );
  const removeTag = useCallback(
    (selectedTag) => {
      if (!formIsDirty) {
        setFormIsDirty(true);
      }

      setTags(tags.filter((tag) => tag.id !== selectedTag.id));
    },
    [tags, formIsDirty]
  );
  const [updateAccountTags, { loading, error }] = useUpdateAccountTags();
  const save = useCallback(() => {
    updateAccountTags({
      variables: {
        input: {
          id: account.id,
          tagIds: tags.map((tag: ITag) => tag.id),
        },
      },
    })
      .then(() => {
        showToast('Tags updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch(() => {
        showToast('There was an error updating child tags. Please try again later.', 'error');
      });
  }, [updateAccountTags, account.id, tags]);

  const tagMessage = useMemo(() => {
    if (tags && tags.length <= 0 && !formIsDirty)
      return <p>No tags associated with this {fieldLabels.account.toLowerCase()}</p>;
    return <></>;
  }, [formIsDirty, tags]);

  if (error) {
    return <Alert variant="danger">{errorMessage.generic}</Alert>;
  }

  if (loading) {
    return <LoadingLines number={2} />;
  }

  return (
    <FormWrapper2
      className={className}
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onCancel={resetTags}
      onSave={save}
    >
      {tagMessage}
      <div>
        <small>
          Select a tag to apply it to your {fieldLabels.account.toLowerCase()}. Deselect a tag to remove it.
        </small>
        {tags && (
          <Row noGutters className="mx-n1 mt-4">
            {tags.map((tag, i) => (
              <div key={`selected-tag-${i}`} className="px-1 mb-2">
                <Tag editState="SELECTED" tag={tag} onClick={() => canPerformEdit && removeTag(tag)} />
              </div>
            ))}
            {unselectedTags.map((tag, i) => (
              <div key={`unselected-tags-${i}`} className="px-1 mb-2">
                <Tag editState="DESELECTED" tag={tag} onClick={() => canPerformEdit && addTag(tag)} />
              </div>
            ))}
          </Row>
        )}
      </div>
    </FormWrapper2>
  );
};

export default TagsForm;
