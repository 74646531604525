import cast from 'shared/util/cast';

/**
 * Heavily inspired by react-to-print (https://github.com/gregnb/react-to-print) but not as robust.
 * That package uses refs to access the dom element while this class uses an id.
 */
export class PrintService {
  createIFrame = (): HTMLIFrameElement => {
    const iframe = document.createElement('iframe');

    iframe.style.position = 'absolute';
    iframe.style.top = '-1000px';
    iframe.style.left = '-1000px';
    iframe.id = 'printWindow';
    iframe.title = 'Print Window';

    return iframe;
  };

  applyHeadStyles = (doc: Document) => {
    const styleElements = document.querySelectorAll("style, link[rel='stylesheet']");

    for (let i = 0; i < styleElements.length; i++) {
      const styleNode = styleElements[i];

      // inline style
      // take all styles in the normal document and apply them to the iframe for printing
      if (styleNode.tagName === 'STYLE') {
        const headEl = doc.createElement(styleNode.tagName);
        const styleSheet = cast<CSSStyleSheet>(cast<HTMLStyleElement>(styleNode).sheet);

        if (styleSheet) {
          let css = '';

          for (let i = 0; i < styleSheet.cssRules.length; i++) {
            const rule = styleSheet.cssRules[i];

            if (typeof rule.cssText === 'string') {
              css += `${rule.cssText}\r\n`;
            }
          }

          headEl.appendChild(doc.createTextNode(css));
          doc.head.appendChild(headEl);
        }
      } else if (styleNode.getAttribute('href')) {
        // style references a css file
        const headEl = doc.createElement(styleNode.tagName);

        for (let i = 0; i <= styleNode.attributes.length; i++) {
          if (styleNode.attributes[i]) {
            headEl.setAttribute(styleNode.attributes[i].nodeName, styleNode.attributes[i].nodeValue || '');
          }
        }

        doc.head.appendChild(headEl);
      }
    }
  };

  // grab an element in the dom by id and print it
  printElementById = (id: string) => {
    const printWindow = this.createIFrame();
    const el = document.getElementById(id);

    if (!el) {
      console.warn(`Could not find an element with id ${id}.`);
      return;
    }

    printWindow.onload = () => {
      // ensure onload is only called once
      printWindow.onload = null;

      const domDoc = printWindow.contentDocument || printWindow.contentWindow?.document;

      if (domDoc) {
        domDoc.open('');
        domDoc.write(el.innerHTML);
        domDoc.close();

        // add styles
        this.applyHeadStyles(domDoc);

        // trigger print
        this.triggerPrint(printWindow);
      }
    };

    // remove the iframe once complete
    this.removePrintWindow();

    document.body.appendChild(printWindow);
  };

  triggerPrint = (target: HTMLIFrameElement) => {
    setTimeout(() => {
      if (target.contentWindow) {
        target.contentWindow.focus();

        // NOTE: According to mdn Firefox Android doesn't have this
        target.contentWindow.print();

        this.removePrintWindow();
      }
    }, 500);
  };

  removePrintWindow = () => {
    const printWindowEl = document.getElementById('printWindow');

    if (printWindowEl) {
      document.body.removeChild(printWindowEl);
    }
  };
}
