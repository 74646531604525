import React from 'react';
import './style.scss';
import { currencyFormat } from 'shared/util/currency';

const ChartLabel: React.FC<{ label: string; color: string; amount: number }> = ({ label, color, amount }) => (
  <div className="d-flex justify-content-between mb-4">
    <div className="d-flex align-items-center">
      <div className="pie-legend" style={{ background: color }}></div>
      {label}
    </div>
    <div>{currencyFormat(amount)}</div>
  </div>
);

export default ChartLabel;
