export const statementStatusLabels: Record<string, { value: StatementStatus; label: string }> = {
  ERROR: { value: 'ERROR', label: 'Error' },
  GENERATING: { value: 'GENERATING', label: 'Generating' },
  QUEUED: { value: 'QUEUED', label: 'Queued' },
  SENDING: { value: 'SENDING', label: 'Sending' },
  SUCCESS: { value: 'SUCCESS', label: 'Success' },
};

export const statementRunStatusLabels: Record<string, { value: StatementRunStatus; label: string }> = {
  QUEUED: { value: 'QUEUED', label: 'Queued' },
  PROCESSING: { value: 'PROCESSING', label: 'Processing' },
  COMPLETED_SUCCESS: { value: 'COMPLETED_SUCCESS', label: 'Completed: No errors' },
  COMPLETED_ERROR: { value: 'COMPLETED_ERROR', label: 'Completed: With errors' },
};
