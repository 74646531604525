import React, { useState, useCallback } from 'react';
import DataTable from 'shared/components/DataTable';
import { Row } from 'shared/components/Layout';
import { RiskTag } from 'shared/components/Tag';
import { camelCaseToSpaced } from 'shared/util/string';
import { orderBy, get } from 'lodash';

import cast from 'shared/util/cast';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import ConditionExpandedRow from '../../../../../Families/subroutes/Children/Child/components/HealthWellnessTab/ConditionExpandedRow';
import {
  getLevelLabelByCategory,
  getSymptomsLabelByCategory,
  mapToGeneralCondition,
} from '../../../../../Families/subroutes/Children/Child/components/HealthWellnessTab/util';

const RISK_SORT_MAP: Record<Risk | AllergenSeverity, number> = {
  None: 0,
  High: 1,
  Severe: 1,
  Moderate: 2,
  Low: 3,
  Mild: 3,
  NotApplicable: 4,
  AwarenessOnly: 4,
};

interface ISortState {
  field: string;
  direction: 'asc' | 'desc';
}

interface IProps {
  data: WellnessCondition[];
  title: string;
  category: WellnessCategoryType;
  loading: boolean;
  onEdit: (row: WellnessCondition) => void;
  onArchive: (row: WellnessCondition) => void;
  className?: string;
}

const MedicalTable: React.FC<IProps> = ({ data, title, category, loading, onArchive, onEdit, className, ...props }) => {
  const conditions = data.map((condition) => ({ ...mapToGeneralCondition(category, condition), original: condition }));
  const activeConditions = conditions.filter((d) => !d.archivedAt);
  const archivedConditions = conditions.filter((d) => d.archivedAt);
  const [sort, setSort] = useState<ISortState>({ field: 'risk', direction: 'asc' });
  const sortData = useCallback(
    (data: IWellnessCondition[]): IWellnessCondition[] => {
      return orderBy(
        data,
        (obj) => (sort.field === 'risk' ? RISK_SORT_MAP[obj.level] : get(obj, sort.field)),
        sort.direction
      );
    },
    [sort]
  );

  return (
    <section className={className}>
      <h4>{title}</h4>
      {loading ? (
        <DataTableLoadingSkeleton />
      ) : data.length ? (
        <div className="m-n8">
          <DataTable
            keyField="id"
            data={[...sortData(activeConditions), ...sortData(archivedConditions)]}
            showSelect={false}
            showPagination={false}
            onSort={(field, direction) => setSort({ field, direction: direction === 'ASCENDING' ? 'asc' : 'desc' })}
            rowClasses={(row: IMedicalCondition) => (row.archivedAt ? 'kt-datatable-inactive-row' : '')}
            defaultSortField="risk"
            defaultSorting="asc"
            noPadding={false}
            expandRow={(row: IWellnessCondition) => (
              <ConditionExpandedRow
                category={category}
                condition={row}
                onArchive={() => onArchive(cast<WellnessCondition>(row.original))}
                onEdit={() => onEdit(cast<WellnessCondition>(row.original))}
              />
            )}
            columns={[
              {
                text: getLevelLabelByCategory(category),
                dataField: 'level',
                sort: true,
                formatter: (level: Risk) => (
                  <Row noGutters>
                    <RiskTag risk={level} className="mr-auto" />
                  </Row>
                ),
                classes: 'md-column',
              },
              {
                text: 'Name',
                dataField: 'name',
                sort: true,
                classes: 'md-column',
              },
              {
                text: 'Type',
                dataField: 'typeName',
                sort: true,
                classes: 'md-column',
              },
              category === 'Restriction'
                ? {
                    text: 'Description',
                    dataField: 'freeText',
                    classes: 'lg-column',
                  }
                : {
                    text: getSymptomsLabelByCategory(category),
                    dataField: 'symptoms',
                    formatter: (symptoms: string[]) => symptoms.map((s) => camelCaseToSpaced(s)).join(', '),
                    classes: 'lg-column',
                  },
            ]}
          />
        </div>
      ) : (
        <div>
          There are no reported {category.toLocaleLowerCase()} conditions for this person. Click the Add Condition
          button in the top right to add one.
        </div>
      )}
    </section>
  );
};

export default MedicalTable;
