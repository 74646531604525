import React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Button from 'shared/components/Buttons';
import { useCreateSession, useUpdateSession } from 'gql/session/mutations';
import { IGetAttendanceOpenSpotsData, IGetExpectedSessionsData } from 'gql/session/queries';
import { createSession, removeSessionById, updateSession } from 'pages/Attendance/duck/actions';
import { showToast } from 'shared/components/Toast';
import ViewSessionGroup from './ViewSessionGroup';
import EditSessionGroup from './EditSessionGroup';
import AbsenceGroup from './AbsenceGroup';
import { INewTimeEntryShape, ISessionUpdateShape } from './EditSessionModal';
import EditAbsenceGroup from './EditAbsenceGroup';
import { useEffect } from 'react';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { isRegion } from 'shared/util/region';
import { isValidSessionGroup, validateAllTimeframes } from './helpers/EditSessionGroupValidation';

interface IProps {
  session: ISessionUpdateShape;
  initialSession: ISession | null;
  timezone: Timezone;
  onCancel: () => void;
  onUpdate: (session: ISessionUpdateShape) => void;
  updateDropOffPickUp: (time: string, direction: 'dropOff' | 'pickUp') => void;
  onClearSession: () => void;
  onReportAbsent: () => void;
  onDeleteSessionTimeEntry: (timeEntry: IAttendanceTimeEntry) => void;
  refetchOpenSpotsData: () => Promise<ApolloQueryResult<IGetAttendanceOpenSpotsData>>; // refetch function from `useGetAttendanceOpenSpots`
  refetchExpectedSessions: () => void; // refetch function from `useGetExpectedSessions`
}

const SessionGroup: React.FC<IProps> = ({
  session,
  initialSession,
  timezone,
  onCancel,
  onUpdate,
  updateDropOffPickUp,
  onClearSession,
  onReportAbsent,
  onDeleteSessionTimeEntry,
  refetchOpenSpotsData,
  refetchExpectedSessions,
  ...props
}) => {
  const hasAbsence = Boolean(session.absence);
  const hasCheckIns = session.timeEntries.length > 0;
  const hasOpenCheckIn = hasCheckIns && session.timeEntries[session.timeEntries.length - 1].timeOut === null;
  const isLateSubmission = React.useMemo(
    () => moment().diff(moment(session.date).startOf('week').tz(timezone), 'days') > 28,
    [session.date, timezone]
  );

  const [mode, setMode] = React.useState<'VIEW' | 'EDIT'>('VIEW');

  const dispatch = useDispatch();
  const [createSessionFn, { loading: createSessionLoading }] = useCreateSession({
    onCompleted: (data) => {
      dispatch(createSession(data.createSession));
      refetchOpenSpotsData();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });
  const [updateSessionFn, { loading: updateSessionLoading }] = useUpdateSession({
    onCompleted: (data) => {
      /**
       * Don't explicitly invoke setMode('VIEW') here, it might cause memory leak (not 100% sure)
       * but will monitor if this will fix the attendance screen 'out of memory' issue.
       *
       * dispatch(updateSession(data.updateSession))
       * will cause redux store change,
       * and then props.initialSession will be updated since the parent component is consuming the sessions in the redux store
       * and which will cause below useEffect hook to set mode to 'VIEW',
       * and explicitly setMode('VIEW') here may unmount some component before async call still not finished.
       */
      dispatch(updateSession(data.updateSession));
      refetchOpenSpotsData();
      refetchExpectedSessions();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  // format timeIn/timeOut for all of the time entries into HH:mm:ss strings
  const formatTimeEntriesArrayForAPI = React.useCallback(
    (arr: IAttendanceTimeEntry[]): { id: string; timeIn: string; timeOut?: string | null }[] => {
      return arr.map((timeEntry) => {
        let timeIn = '';
        let timeOut: moment.Moment | null | string = null;

        // string is still iso string, format to HH:mm:ss
        if (moment(timeEntry.timeIn, moment.ISO_8601, true).isValid()) {
          timeIn = moment(timeEntry.timeIn).tz(timezone).toISOString();
        } else {
          // rebuild the datetime using the date of the session since the api expects a date time to be sent. the frontend converted these datetimes to 24 hour format strings to work with TimePicker2
          // string is a 24 hour string (from TimePicker2), create an iso string using the session's date
          const [timeInHours, timeInMinutes] = timeEntry.timeIn.split(':');
          timeIn = moment
            .tz(session.date, timezone)
            .hours(parseInt(timeInHours))
            .minutes(parseInt(timeInMinutes))
            .toISOString();
        }

        if (timeEntry.timeOut && moment(timeEntry.timeOut, moment.ISO_8601, true).isValid()) {
          timeOut = moment(timeEntry.timeOut).tz(timezone).toISOString();
        } else if (timeEntry.timeOut && timeEntry.timeOut.includes(':')) {
          // string is a 24 hour string (from TimePicker2), create an iso string using the session's date
          const [timeOutHours, timeOutMinutes] = timeEntry.timeOut.split(':');
          timeOut = moment
            .tz(session.date, timezone)
            .hours(parseInt(timeOutHours))
            .minutes(parseInt(timeOutMinutes))
            .toISOString();
        }

        return {
          id: timeEntry.id,
          timeIn,
          timeOut,
        };
      });
    },
    [timezone, session.date]
  );

  // format timeIn/timeOut for all of the new time entries into iso strings
  const formatNewTimeEntriesArrayForAPI = React.useCallback(
    (arr: INewTimeEntryShape[]): { timeIn: string; timeOut?: string | null }[] => {
      return arr.map((timeEntry) => {
        let timeIn = '';
        let timeOut: null | moment.Moment | string = null;

        /* build a datetime using the session's date and the 24 hour string */

        // should be non-null if we've reached this point
        if (timeEntry.timeIn) {
          const [timeInHours, timeInMinutes] = timeEntry.timeIn.split(':');
          timeIn = moment
            .tz(session.date, timezone)
            .hours(parseInt(timeInHours))
            .minutes(parseInt(timeInMinutes))
            .toISOString();
        }

        if (timeEntry.timeOut) {
          const [timeOutHours, timeOutMinutes] = timeEntry.timeOut.split(':');
          timeOut = moment
            .tz(session.date, timezone)
            .hours(parseInt(timeOutHours))
            .minutes(parseInt(timeOutMinutes))
            .toISOString();
        }

        return {
          timeIn,
          timeOut,
        };
      });
    },
    [timezone, session.date]
  );

  const displayUpdateToast = React.useCallback(
    (updatedSession: ISession) => {
      let message = 'Updated session successfully. ';

      if (initialSession && initialSession.classId !== updatedSession.classId) {
        message += `The updated session has been moved from ${initialSession.class.name} to ${updatedSession.class.name}.`;
      }

      showToast(message, 'success');
    },
    [initialSession]
  );

  const handleSubmit = React.useCallback(() => {
    // the graphql layer adds the prefiex `contract-[random_uuid]` to expected sessions that were derived from a contract and not an actual session record
    if (!session.id || session.id.match(/contract-/)) {
      // create session from expected session
      createSessionFn({
        variables: {
          input: {
            accountId: session.accountId,
            childId: session.childId,
            classId: session.classId as string,
            contractId: session.contractId ?? null,
            feeId: session.feeId as string,
            date: session.date,
            dropOffTime: session.dropOffTime,
            pickUpTime: session.pickUpTime,
          },
        },
      }).then((result) => {
        const sessionWithTimeEntries = { ...session };
        // remove the derived expected session so the newly created one can be added

        // successfully created, now update with the time entries since you can't create a session record with time entries
        if (result?.data?.createSession.id) {
          // remove the old expected session since the actual session will be inserted when successfully created
          dispatch(removeSessionById(session.id ?? ''));

          updateSessionFn({
            variables: {
              input: {
                id: result.data.createSession.id,
                timeEntries: formatTimeEntriesArrayForAPI(sessionWithTimeEntries.timeEntries),
                newTimeEntries: formatNewTimeEntriesArrayForAPI(sessionWithTimeEntries.newTimeEntries ?? []),
                reasonForLateChange: session.reasonForLateChange,
              },
            },
          }).then((result) => {
            if (result?.data?.updateSession) {
              displayUpdateToast(result.data.updateSession);
            }
          });
        }
      });
    } else {
      // update existing session
      updateSessionFn({
        variables: {
          input: {
            id: session.id,
            classId: session.classId,
            feeId: session.feeId,
            dropOffTime: session.dropOffTime,
            pickUpTime: session.pickUpTime,
            timeEntries: formatTimeEntriesArrayForAPI(session.timeEntries),
            newTimeEntries: formatNewTimeEntriesArrayForAPI(session.newTimeEntries ?? []),
            reasonForLateChange: session.reasonForLateChange,
            absence: session.absence
              ? {
                  id: session.absence.id,
                  reason: session.absence.reason as AbsenceReason,
                }
              : null,
          },
        },
      }).then((result) => {
        if (result?.data?.updateSession) {
          displayUpdateToast(result.data.updateSession);
        }
      });
    }
  }, [
    dispatch,
    session,
    createSessionFn,
    updateSessionFn,
    formatNewTimeEntriesArrayForAPI,
    formatTimeEntriesArrayForAPI,
    displayUpdateToast,
  ]);

  /**
   * dispatch(updateSession(data.updateSession)) above
   * will cause the initialSession changes,
   * therefore after click save button, the mode will be set to 'view',
   * and don't need to explicitly to set the mode to 'view' in the useUpdateSession hook above
   */
  useEffect(() => {
    setMode('VIEW');
  }, [initialSession]);

  return mode === 'VIEW' ? (
    <div>
      <div className="d-flex flex-row">
        {hasAbsence ? (
          <AbsenceGroup session={session} />
        ) : (
          <ViewSessionGroup session={session} timezone={timezone} onDeleteTimeEntry={onDeleteSessionTimeEntry} />
        )}
        <HasRoleAreaLevel
          action={{ area: AreaType.Attendance, permission: PermissionType.Base, level: RoleLevelType.Edit }}
        >
          <Button variant="secondary" className="ml-auto" onClick={() => setMode('EDIT')}>
            Edit
          </Button>
        </HasRoleAreaLevel>
      </div>
    </div>
  ) : (
    <div>
      {hasAbsence ? (
        <EditAbsenceGroup session={session} onUpdate={onUpdate} />
      ) : (
        <EditSessionGroup
          session={session}
          timezone={timezone}
          onUpdate={onUpdate}
          updateDropOffPickUp={updateDropOffPickUp}
          overlappingTimeframes={!validateAllTimeframes(session, timezone)}
        />
      )}
      <div className="d-flex flex-row">
        {!hasAbsence && !hasCheckIns && (
          <HasRoleAreaLevel
            action={{ area: AreaType.Attendance, permission: PermissionType.CheckInOut, level: RoleLevelType.Create }}
          >
            <Button variant="outline-danger" onClick={() => onReportAbsent()} className="mr-4">
              Mark Absent
            </Button>
          </HasRoleAreaLevel>
        )}
        {!hasOpenCheckIn && (session.timeEntries.length > 0 || session.absence !== null) && (
          <HasRoleAreaLevel
            action={{ area: AreaType.Attendance, permission: PermissionType.Base, level: RoleLevelType.Edit }}
          >
            <Button variant="secondary" onClick={() => onClearSession()}>
              Clear Session
            </Button>
          </HasRoleAreaLevel>
        )}
        <Button
          variant="light"
          className="ml-auto"
          onClick={() => {
            setMode('VIEW');
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="ml-2"
          disabled={!isValidSessionGroup(session, timezone) || !validateAllTimeframes(session, timezone)}
          loading={createSessionLoading || updateSessionLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default SessionGroup;
