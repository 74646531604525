import React, { useState, useCallback, useMemo } from 'react';
import DataTable from 'shared/components/DataTable';
import { useTranslation } from 'react-i18next';
import { capitalize, orderBy, sortBy, chunk } from 'lodash';
import { useDispatch } from 'react-redux';
import { setCurrentCenter } from 'store/context/actions';
import { TableHeader, TableSearch } from 'shared/components/DataTable';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import CasualBookingStatusCell from './components/CasualBookingStatusCell';
import { TagsTableCell } from 'shared/components/Tag';
import { CASUAL_BOOKING_STATUS_OPTIONS } from './shared/constant';
import { ButtonAsLink } from 'shared/components/Buttons';
import { STATES, US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetCenterStatesInUse } from 'shared/hooks/useGetCenterStatesInUse';

interface ICenterClassesCasualBookingSettingsExtra extends ICenterClassesCasualBookingSettings {
  casualBookingActive: boolean;
  casualBookingAvailable: boolean;
  numberOfEnabledClass: string;
}
interface IProps {
  selectedCenters: ICenterClassesCasualBookingSettings[];
  setSelectedCenters: React.Dispatch<React.SetStateAction<ICenterClassesCasualBookingSettings[]>>;
  data: ICenterClassesCasualBookingSettings[] | undefined;
  loading: boolean;
  setSubTab: React.Dispatch<React.SetStateAction<AttendanceSettingsSubTab>>;
}

const CentersBookingTable: React.FC<IProps> = ({ selectedCenters, setSelectedCenters, data, loading, setSubTab }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];
  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name'])
    .map((tag) => ({
      label: tag.name,
      value: tag.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const statesInUse: string[] = useGetCenterStatesInUse()?.data?.getCenterStatesUsedAcrossEntity || [];

  const states =
    !!statesInUse && statesInUse.length > 0
      ? US_STATE_SELECT_OPTIONS.filter((state) => statesInUse.includes(state.value))
      : US_STATE_SELECT_OPTIONS;

  const stateOptions: ITableFilterOption[] = sortBy(states, ['label']).map((state) => ({
    label: state.label,
    value: state.value,
  }));

  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilters, setStatusFilters] = useState<ITableFilterOption[]>([]);
  const [tagsFilters, setTagsFilters] = useState<ITableFilterOption[]>([]);
  const [stateFilters, setStateFilters] = useState<ITableFilterOption[]>([]);
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'name',
    direction: 'asc',
  });
  const isCenterMatchesSearchTerm = useCallback(
    (centerClassesCasualBookingSettings: ICenterClassesCasualBookingSettings, term: string) => {
      return centerClassesCasualBookingSettings.name.toLowerCase().includes(term.toLowerCase());
    },
    []
  );

  const onSort = (field: string, direction: 'asc' | 'desc') => setSortField({ field, direction });

  const filteredCenters: ICenterClassesCasualBookingSettingsExtra[] = useMemo(() => {
    let filteredData: ICenterClassesCasualBookingSettingsExtra[] = (data ?? []).map((center) => ({
      ...center,
      casualBookingActive: center.classes.some((cls) => cls.casualBookingSettings.active),
      casualBookingAvailable: center.classes.some((cls) => cls.casualBookingSettings.available),
      numberOfEnabledClass: `${
        center.classes.filter((cls) => cls.casualBookingSettings.active && cls.casualBookingSettings.available).length
      }/${center.classes.length}`,
    }));

    // handle center name search
    if (searchTerm) {
      filteredData =
        filteredData.filter((center) => {
          return isCenterMatchesSearchTerm(center, searchTerm);
        }) ?? [];
    }

    // handle status search
    if (statusFilters.length) {
      filteredData = filteredData.filter((center) => {
        return statusFilters.some((filter) => {
          const { value: selectedStatus } = filter;
          switch (selectedStatus) {
            case 'unavailable':
              return !center.casualBookingAvailable;
            case 'inactive':
              return !center.casualBookingActive && center.casualBookingAvailable;
            case 'active':
              return center.casualBookingActive && center.casualBookingAvailable;
            default:
              return true;
          }
        });
      });
    }

    // handle state search
    if (stateFilters.length) {
      filteredData = filteredData.filter((center) =>
        stateFilters.some((filter) => filter.value === center.address.state)
      );
    }

    // handle tags search
    if (tagsFilters.length) {
      filteredData = filteredData.filter((center) => {
        return tagsFilters.some((filter) => {
          return center.tags.some((tag) => tag.id === filter.value);
        });
      });
    }

    return filteredData;
  }, [data, searchTerm, isCenterMatchesSearchTerm, statusFilters, stateFilters, tagsFilters]);

  const sortedCenters = useMemo(() => {
    return orderBy(filteredCenters, sortField.field, sortField.direction);
  }, [sortField, filteredCenters]);

  const activePageCenters = useMemo(() => {
    return chunk(sortedCenters, tableState.pageSize)[tableState.activePage - 1] ?? [];
  }, [sortedCenters, tableState]);

  const handleClearFilters = useCallback(() => {
    setSearchTerm('');
    setStatusFilters([]);
    setStateFilters([]);
    setTagsFilters([]);
    tableFunctions.changePage(1, tableState.pageSize);
  }, [tableFunctions, tableState.pageSize]);

  const handleNavToClassesView = useCallback(
    (centerId: string) => {
      dispatch(setCurrentCenter(centerId));
      setSubTab('classes');
    },
    [dispatch, setSubTab]
  );

  const handleSearchTermChange = (v: string) => {
    setSearchTerm(v);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  const handleFiltersSelect = (values: ITableFilterOption[], target: 'status' | 'tags' | 'state') => {
    switch (target) {
      case 'status':
        setStatusFilters(values);
        break;
      case 'tags':
        setTagsFilters(values);
        break;
      case 'state':
        setStateFilters(values);
        break;
      default:
        break;
    }
    tableFunctions.changePage(1, tableState.pageSize);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <DataTable
      noPadding
      showSelect
      showPagination
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      onPageChange={tableFunctions.changePage}
      className="kt-attendance-settings-booking-rules-table"
      data={activePageCenters}
      dataSize={sortedCenters.length}
      selectedRows={selectedCenters}
      updateSelectedRows={setSelectedCenters}
      showLoadingOverlay={loading}
      onSort={(field, direction) => onSort(field, direction === 'ASCENDING' ? 'asc' : 'desc')}
      noDataText={t('attendance.settings.booking-rules.centers.data-table.no-data-text')}
      renderHeader={() => (
        <TableHeader className="flex-wrap align-items-center justify-content-between">
          <div className="d-flex flex-wrap align-items-center mr-auto">
            <TableSearch
              className={isMobile ? 'mb-1' : ''}
              onChange={handleSearchTermChange}
              placeholder={`${capitalize(t('spelling.search'))} ${capitalize(t('spelling.center'))}`}
              value={searchTerm}
              enableDebounce={false}
            />
          </div>
          <div className="d-flex flex-direction-row flex-wrap align-items-center">
            <DropdownFilter
              title={t('attendance.settings.booking-rules.centers.data-table.status-filter-title')}
              selectedFilters={statusFilters}
              options={CASUAL_BOOKING_STATUS_OPTIONS}
              onFilterSelect={(v) => handleFiltersSelect(v, 'status')}
              className={isMobile ? 'my-1 mr-4' : 'mr-4'}
            />
            <DropdownFilter
              title={t('attendance.settings.booking-rules.centers.data-table.tags-filter-title')}
              className={isMobile ? 'my-1 mr-4' : 'mr-4'}
              selectedFilters={tagsFilters}
              options={tagOptions}
              onFilterSelect={(v) => handleFiltersSelect(v, 'tags')}
            />
            <DropdownFilter
              title={t('attendance.settings.booking-rules.centers.data-table.state-filter-title')}
              className={isMobile ? 'mt-1 mr-4' : 'mr-4'}
              selectedFilters={stateFilters}
              options={stateOptions}
              onFilterSelect={(v) => handleFiltersSelect(v, 'state')}
            />
            <IconButtonCircle
              icon={faTimes}
              onClick={handleClearFilters}
              tooltipDirection="bottom"
              tooltipText={t('attendance.settings.booking-rules.centers.data-table.clear-filter-icon-tooltip')}
              className={isMobile ? 'mt-1 ml-1' : ''}
            />
          </div>
        </TableHeader>
      )}
      columns={[
        {
          text: capitalize(t('attendance.settings.booking-rules.centers.data-table.column-labels.center')),
          dataField: 'name',
          formatter: (cell: string, row: ICenterClassesCasualBookingSettingsExtra) => (
            <ButtonAsLink className="text-body font-weight-normal" onClick={() => handleNavToClassesView(row.id)}>
              {cell}
            </ButtonAsLink>
          ),
          sort: true,
        },
        { text: 'City', dataField: 'address.city', sort: true },
        { text: 'State', dataField: 'address.state', sort: true, formatter: (cell: string) => STATES[cell] },
        {
          text: t('attendance.settings.booking-rules.centers.data-table.column-labels.casual-bookings'),
          dataField: 'casualBookingActive',
          sort: true,
          formatter: (cell: boolean, row: ICenterClassesCasualBookingSettingsExtra) => (
            <CasualBookingStatusCell
              status={{
                active: row.classes.some((cls) => cls.casualBookingSettings.active),
                available: row.classes.some((cls) => cls.casualBookingSettings.available),
              }}
            />
          ),
        },
        {
          text: t('attendance.settings.booking-rules.centers.data-table.column-labels.number-of-classes'),
          dataField: 'numberOfEnabledClass',
          sort: true,
        },
        {
          text: t('attendance.settings.booking-rules.centers.data-table.column-labels.tags'),
          dataField: 'tags',
          sort: true,
          formatter: (cell: ITag[]) => cell && <TagsTableCell tags={cell} />,
        },
      ]}
    />
  );
};

export default CentersBookingTable;
