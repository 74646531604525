export const UPDATE_FIELD_VALIDATION_STATE = 'UPDATE_FIELD_VALIDATION_STATE';
export const CLEAR_FIELD_VALIDATION_STATE = 'CLEAR_FIELD_VALIDATION_STATE';

type UpdateFieldValidationStateAction = {
  type: typeof UPDATE_FIELD_VALIDATION_STATE;
  enrolmentFormId: string;
  fieldValidationState: { fieldName: string; isValid?: boolean; isArchived?: boolean };
};

type ClearFieldValidationStateAction = {
  type: typeof CLEAR_FIELD_VALIDATION_STATE;
  enrolmentFormId: string;
};

export type EnrolmentFormActionTypes = UpdateFieldValidationStateAction | ClearFieldValidationStateAction;
