import React, { useCallback, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import FormLabel from 'shared/components/Form/FormLabel';
import BaseSelect from 'shared/components/Select/Base';
import { capitalize } from 'shared/util/string';
import CheckboxSelectOption from './CheckboxSelectOption';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import { sortBy } from 'lodash';

interface IProps {
  /**
   * `null` can represent `All` when `useNullForAllOption` is provided
   */
  selectedTagIds: string[] | null;
  /**
   * Provide `null` as the value via `onSelect` if the all option is selected.
   */
  useNullForAllOption?: boolean;
  onSelect: (tagIds: string[] | null) => void;
  showLabel?: boolean;
  className?: string;
  isRequired?: boolean;
  disabled?: boolean;
  checkboxes?: boolean;
}

/**
 * Component is wrapped in Form.Group to match our standard Select component.
 * The regular <Select /> component was not used since the actionMetadata on dropdown selection
 * is needed and our shared component does not expose that information
 */
const MultipleAccountTagSelect: React.FC<IProps> = ({
  selectedTagIds,
  useNullForAllOption = false,
  onSelect,
  showLabel = true,
  className,
  isRequired,
  disabled,
  checkboxes = false,
  ...props
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ALL_TAGS_OPTIONS = {
    value: null,
    label: 'All Tags',
  };

  var allTags = useGetTagsInUse(TagsTypeElasticIndex.Account)?.data?.getTagsUsedAcrossEntity;
  const tagSelectOptions = useMemo(() => {
    const data = allTags ?? [];
    let options = sortBy(allTags, ['name']).map((tag) => ({
      label: tag.name,
      value: tag.id,
    }));
    return options;
  }, [allTags]);

  /**
   * Determine if the "all" option has been selected in the dropdown
   */
  const isAllOptionSelected = useCallback((): boolean => {
    return useNullForAllOption && !selectedTagIds;
  }, [selectedTagIds, useNullForAllOption]);

  /**
   * Get the array of selected values. React-select wants an array of all of the objects selected
   */
  const getSelectValue = useCallback(() => {
    return isAllOptionSelected()
      ? [ALL_TAGS_OPTIONS]
      : tagSelectOptions.filter((opt) => (selectedTagIds ?? []).includes(opt.value));
  }, [isAllOptionSelected, ALL_TAGS_OPTIONS, tagSelectOptions, selectedTagIds]);

  /**
   * Handle the selection of an option from the dropdown
   */
  const handleSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_TAGS_OPTIONS.value) {
        onSelect(useNullForAllOption ? null : tagSelectOptions.map((opt) => opt.value as string));
      } else if (
        (action === 'deselect-option' && option.value === ALL_TAGS_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_TAGS_OPTIONS.value)
      ) {
        onSelect([]);
      } else if (actionMeta.action === 'deselect-option' && isAllOptionSelected()) {
        onSelect((selectedTagIds ?? []).filter((id) => id !== option.value));
      } else {
        onSelect((options ?? []).filter((opt) => opt.value !== null).map((opt) => opt.value));
      }
    },
    [tagSelectOptions, selectedTagIds, useNullForAllOption, ALL_TAGS_OPTIONS, isAllOptionSelected, onSelect]
  );

  return (
    <Form.Group className={className}>
      {showLabel && <FormLabel required={isRequired}>{capitalize('Account Tags')}</FormLabel>}
      <BaseSelect
        // @ts-ignore
        isMulti
        // @ts-ignore
        value={!allTags ? null : getSelectValue()}
        // @ts-ignore
        options={[ALL_TAGS_OPTIONS, ...tagSelectOptions]}
        // @ts-ignore
        onChange={(options, action) => handleSelect(options as any[], action)}
        isLoading={!allTags}
        className="react-select-container flex-wrap"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        noOptionsMessage={() => 'No Tags found'}
        components={
          checkboxes ? { IndicatorSeparator: null, Option: CheckboxSelectOption } : { IndicatorSeparator: null }
        }
        data-testid="multiple-tag-select"
        isDisabled={disabled ?? false}
      />
    </Form.Group>
  );
};

export default MultipleAccountTagSelect;
