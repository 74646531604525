import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { applicationCenterSettingsFields, updateApplicationCenterSettingsFields } from './fields';
import { ICenterSettingsInput } from 'gql/applicationCenterSettings/fields';
import { IApplicationSettings } from 'shared/types/application';

export const UPDATE_APPLICATION_CENTER_SETTINGS = gql`
  mutation ($input: UpdateApplicationCenterSettingsInput!) {
    updateApplicationCenterSettings(input: $input) {
      ${updateApplicationCenterSettingsFields}
    }
  }
`;

type ICenterInformation = {
  feeType?: FeeType | undefined;
  feeAmount?: number;
  askFamilyAboutSubsidy?: boolean | undefined;
};

interface IUpdateApplicationCenterSettingsInput {
  businessId: string;
  centerId: string;
  centerInformation: ICenterInformation;
}

export const useUpdateApplicationCenterSettings = (
  options: MutationHookOptions<
    { updateApplicationSetting: IApplicationSettings },
    { input: IUpdateApplicationCenterSettingsInput }
  >
) => {
  return useMutation<
    { updateApplicationSetting: IApplicationSettings },
    { input: IUpdateApplicationCenterSettingsInput }
  >(UPDATE_APPLICATION_CENTER_SETTINGS, options);
};

export const UPDATE_CENTER_SUBSIDY_QUESTION = (fields: string = applicationCenterSettingsFields) => gql`
  mutation ($input: UpdateApplicationCenterSubsidyInput!) {
    updateSubsidyQuestion(input: $input) {
      ${fields}
    }
  }
`;

interface IUpdateApplicationCenterSubsidyVariables {
  input: {
    businessId: string;
    askFamilyAboutSubsidy: boolean;
  };
}

interface IUpdateApplicationCenterSubsidy {
  updateSubsidyQuestion: {
    businessId: string;
    centerId: string;
    centerName: string;
    feeType?: FeeType | undefined;
    feeAmount?: number;
    askFamilyAboutSubsidy?: boolean | undefined;
  };
}

export const useUpdateApplicationCenterSubsidy = (
  options?: MutationHookOptions<IUpdateApplicationCenterSubsidy, IUpdateApplicationCenterSubsidyVariables>,
  fields?: string
) =>
  useMutation<IUpdateApplicationCenterSubsidy, IUpdateApplicationCenterSubsidyVariables>(
    UPDATE_CENTER_SUBSIDY_QUESTION(fields),
    options
  );

export const UPDATE_MULTIPLE_CENTERS_SETTINGS = (fields: string = applicationCenterSettingsFields) => gql`
  mutation ($input: UpdateApplicationMultipleCentersSettingInput!) {
    updateApplicationMultipleCentersSetting(input: $input) {
      ${fields}
    }
  }
`;

interface IUpdateMultipleCentersSettings {
  updateApplicationMultipleCentersSetting: {
    businessId: string;
    centerId: string;
    centerName: string;
    feeType?: FeeType | undefined;
    feeAmount?: number;
    askFamilyAboutSubsidy?: boolean | undefined;
  };
}

interface IUpdateMultipleCentersSettingsVariables {
  input: {
    businessId: string;
    centerIds: string[];
    centerSettingsList: ICenterSettingsInput[];
  };
}

export const useUpdateMultipleCentersSettings = (
  options?: MutationHookOptions<IUpdateMultipleCentersSettings, IUpdateMultipleCentersSettingsVariables>,
  fields?: string
) =>
  useMutation<IUpdateMultipleCentersSettings, IUpdateMultipleCentersSettingsVariables>(
    UPDATE_MULTIPLE_CENTERS_SETTINGS(fields),
    options
  );
