import React, { useCallback } from 'react';
import { components } from 'react-select';
import Checkbox from 'shared/components/Checkbox';

interface IProps {}

// OptionProps from react-select
const CheckboxSelectOption: React.FC<any> = ({ ...props }) => {
  // there were cases where clicking some parts of the option would close the menu
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      props.selectOption({ ...props.data });
      event.stopPropagation();
      event.preventDefault();
    },
    [props]
  );

  return (
    <components.Option {...props}>
      {!!props.isMulti ? (
        <div onClick={handleClick}>
          <Checkbox value={props.isSelected} onChange={() => {}} label={props.children} />
        </div>
      ) : (
        props.children
      )}
    </components.Option>
  );
};

export default CheckboxSelectOption;
