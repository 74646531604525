import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageWrapper from 'shared/components/PageWrapper';
import { RootState } from 'store/reducers';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import BulletinBoardMessagingContainer from './BulletinBoardMessagingContainer';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { CommsCenterSetting, useGetChatAuthTokenQuery, useGetCommsAllCenterSettingsQuery } from 'generated/graphql';
import { showToast } from 'shared/components/Toast';
import MessagingOff from 'shared/components/Messenger/MessagingOff';
import moment from 'moment';
import { isNil } from 'lodash';

interface IProps extends RouteComponentProps<any, {}> {}

export enum BulletinBoardFilters {
  None = 0,
  Mentions = 1,
}

const BulletinBoards: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['comms', 'translation']);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const businessMessaging = useSelector((state: RootState) => state.comms.usesComms);

  const [authBulletinBoardCenters, setAuthBulletinBoardCenters] = useState<CommsCenterSetting[] | undefined | null>([]);

  const [authToken, setAuthToken] = useState<string | null | undefined>();
  const [authTokenExpiry, setAuthExpiryToken] = useState<string | null | undefined>();
  const [publishKey, setPublishKey] = useState<string | null | undefined>();
  const [subscribeKey, setSubscribeKey] = useState<string | null | undefined>();

  const hasBulletinBoardReadPermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.BulletinBoard,
    level: RoleLevelType.Read,
  });

  const hasBulletinBoardWritePermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.BulletinBoard,
    level: RoleLevelType.Edit,
  });

  const hasBulletinBoardDeletePermission = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.BulletinBoard,
    level: RoleLevelType.Delete,
  });

  const hasCenterEditPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const {
    data: getChatAuthTokenData,
    loading: loadingAuthToken,
    error: getAuthTokenError,
    refetch: refetchAuthToken,
  } = useGetChatAuthTokenQuery({
    skip: isNil(businessId),
    variables: {
      businessId: businessId ?? '',
    },
  });

  const {
    data: getAllCenterSettings,
    loading: loadingAllCenterSettings,
    error: allCenterSettingsError,
  } = useGetCommsAllCenterSettingsQuery({
    skip: isNil(businessId),
    variables: {
      businessId: businessId ?? '',
      pageNumber: 1,
      pageSize: 25, // Fetches 25 by default initially, grabs more with getBulletinBoardChannels if the user changes the filter
    },
  });

  useEffect(() => {
    if (allCenterSettingsError)
      showToast(`Unable to get Messaging ${t('translation:spelling.center')} Settings`, 'error');
    setAuthBulletinBoardCenters(
      getAllCenterSettings?.getCommsAllCenterSettings?.data.filter((c) => c.usesBulletinBoards)
    );
  }, [getAllCenterSettings, allCenterSettingsError]);

  useEffect(() => {
    if (getAuthTokenError) showToast(`Unable to get Messaging ${t('translation:spelling.authorization')}`, 'error');
    setAuthToken(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.authToken : undefined);
    setAuthExpiryToken(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.expiryDateTimeOffset : undefined);
    setPublishKey(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.publishKey : undefined);
    setSubscribeKey(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.subscribeKey : undefined);
  }, [getAuthTokenError, getChatAuthTokenData]);

  useEffect(() => {
    // refresh token 5 minutes before it expires
    const expiryminus5mins = moment(authTokenExpiry).subtract(5, 'minutes');
    const diffInMilisec = expiryminus5mins.diff(moment()).valueOf();
    setTimeout(refetchAuthToken, diffInMilisec);
  }, [authTokenExpiry]);

  return (
    <>
      {businessMessaging ? (
        <PageWrapper pageTitle={t('comms:bulletin-boards-page-title')} applyPadding={true}>
          {(!publishKey || !subscribeKey) &&
            !loadingAllCenterSettings &&
            !loadingAuthToken &&
            hasBulletinBoardReadPermission && (
              <MessagingOff
                hasCenterEditPermission={hasCenterEditPermission}
                title={
                  authBulletinBoardCenters && authBulletinBoardCenters.length > 0
                    ? t('comms:messaging-off.title.other')
                    : t('comms:messaging-off.title.main', { channelType: 'All' })
                }
                reasonText={
                  authBulletinBoardCenters && authBulletinBoardCenters.length > 0
                    ? t('comms:messaging-off.reason.no-channels')
                    : t('comms:messaging-off.reason.adjust-settings')
                }
              />
            )}
          {publishKey && subscribeKey && !loadingAllCenterSettings && !loadingAuthToken && (
            <Tabs>
              <Tab eventKey="all" title="All">
                {!hasBulletinBoardReadPermission && (
                  <MessagingOff
                    hasCenterEditPermission={hasCenterEditPermission}
                    title={t('comms:messaging-off.title.other', { channelType: 'Bulletin Board' })}
                    reasonText={t('comms:messaging-off.reason.no-permission.main', { channelType: 'Bulletin Board' })}
                  />
                )}
                {hasBulletinBoardReadPermission && authBulletinBoardCenters && authBulletinBoardCenters.length <= 0 && (
                  <MessagingOff
                    hasCenterEditPermission={hasCenterEditPermission}
                    title={t('comms:messaging-off.title.main', { channelType: 'Bulletin Board' })}
                    reasonText={t('comms:messaging-off.reason.adjust-settings')}
                  />
                )}
                {hasBulletinBoardReadPermission && authBulletinBoardCenters && authBulletinBoardCenters?.length > 0 && (
                  <BulletinBoardMessagingContainer
                    hasWritePermission={hasBulletinBoardWritePermission}
                    hasDeletePermission={hasBulletinBoardDeletePermission}
                    authToken={authToken ?? ''}
                    authChannels={authBulletinBoardCenters?.map((c) => c.centerId ?? '')}
                    publishKey={publishKey ?? ''}
                    subscribeKey={subscribeKey ?? ''}
                    filterBulletinsBy={BulletinBoardFilters.None}
                  />
                )}
              </Tab>
              <Tab eventKey="mentions" title="Mentioning Me">
                {!hasBulletinBoardReadPermission && (
                  <MessagingOff
                    hasCenterEditPermission={hasCenterEditPermission}
                    title={t('comms:messaging-off.title.other', { channelType: 'Bulletin Board' })}
                    reasonText={t('comms:messaging-off.reason.no-permission.main', { channelType: 'Bulletin Board' })}
                  />
                )}
                {hasBulletinBoardReadPermission && authBulletinBoardCenters && authBulletinBoardCenters.length <= 0 && (
                  <MessagingOff
                    hasCenterEditPermission={hasCenterEditPermission}
                    title={t('comms:messaging-off.title.main', { channelType: 'Bulletin Board' })}
                    reasonText={t('comms:messaging-off.reason.adjust-settings')}
                  />
                )}
                {hasBulletinBoardReadPermission && authBulletinBoardCenters && authBulletinBoardCenters?.length > 0 && (
                  <BulletinBoardMessagingContainer
                    hasWritePermission={hasBulletinBoardWritePermission}
                    hasDeletePermission={hasBulletinBoardDeletePermission}
                    authToken={authToken ?? ''}
                    authChannels={authBulletinBoardCenters?.map((c) => c.centerId ?? '')}
                    publishKey={publishKey ?? ''}
                    subscribeKey={subscribeKey ?? ''}
                    filterBulletinsBy={BulletinBoardFilters.Mentions}
                  />
                )}
              </Tab>
            </Tabs>
          )}
        </PageWrapper>
      ) : null}
    </>
  );
};

export default BulletinBoards;
