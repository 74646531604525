import React, { useState, useCallback, useMemo } from 'react';
import RbModal from 'react-bootstrap/Modal';
import moment from 'moment';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { DateRangeInput, DateRangeInputWithTimezone } from 'shared/components/DateInput';
import { capitalize } from 'shared/util/string';
import RootState from 'store/reducers';
import { useGetExpectedSessionsForAccountChild } from 'gql/session/queries';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import colors from '_colors.module.scss';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import SessionInformationListItem from './SessionInformationListItem';

interface IProps {
  isOpen: boolean;
  agencyAccountChild: IAgencyAccountChild;
  onClose: () => void;
}

const SubsidyChildAttendanceModal: React.FC<IProps> = ({ isOpen, agencyAccountChild, onClose, ...props }) => {
  const { t } = useTranslation(['translation', 'subsidies']);
  // const timezone = useSelector((state: RootState) => state.timezone.byCenterId['__']) ?? moment.tz.guess()
  const [timeframe, setTimeframe] = useState<{ start: string; end: string }>({
    start: moment().startOf('week').toISOString(),
    end: moment().endOf('week').toISOString(),
  });

  const datesInTimeframe = useMemo((): moment.Moment[] => {
    const dates: moment.Moment[] = [];
    const start = moment(timeframe.start);
    const end = moment(timeframe.end);
    const current = start.clone();

    while (current.isSameOrBefore(end)) {
      // per production no customers have weekend care so only show monday through friday
      if (current.day() > 0 && current.day() < 6) {
        dates.push(current.clone());
      }
      current.add(1, 'day');
    }

    return dates;
  }, [timeframe]);

  const { data, loading } = useGetExpectedSessionsForAccountChild(
    {
      variables: {
        input: {
          accountChildId: agencyAccountChild.accountChildId,
          startDate: timeframe.start,
          endDate: timeframe.end,
        },
      },
      skip: !timeframe.start || !timeframe.end,
    },
    `id contractId date timeEntries { id date timeIn timeOut } absence { id date }`
  );

  const handleClose = useCallback(() => {
    setTimeframe({
      start: moment().startOf('week').toISOString(),
      end: moment().endOf('week').toISOString(),
    });
    onClose();
  }, [onClose]);

  const calculateTotalAttendedHours = useCallback((sessions: ISession[]): number => {
    const timeEntries = sessions.flatMap((s) => s.timeEntries);

    const totalMinutesAttended = timeEntries.reduce((acc, curr) => {
      if (curr.timeIn && curr.timeOut) {
        return (acc += moment(curr.timeOut).diff(moment(curr.timeIn), 'minutes'));
      }

      return acc;
    }, 0);

    return totalMinutesAttended / 60;
  }, []);

  const renderStatusTag = useCallback((agencyEnrollmentStatus: AgencyAccountChildStatus): JSX.Element => {
    let text = '';
    let color = '';

    switch (agencyEnrollmentStatus) {
      case 'ACTIVE':
        text = 'Active';
        color = colors.success;
        break;
      case 'EXPIRED':
        text = 'Expired';
        color = colors.danger;
        break;
      case 'EXPIRING_SOON':
        text = 'Expiring Soon';
        color = colors.warning;
        break;
      case 'FUTURE':
        text = 'Future';
        color = colors.skyBlue;
        break;
      default:
        break;
    }

    return <ColoredBackgroundTag color={color} text={text} />;
  }, []);

  const sessions = data?.getExpectedSessionsForAccountChildInTimeframe ?? [];

  return (
    <RbModal
      centered
      backdrop="static"
      size="lg"
      enforceFocus={false}
      show={isOpen}
      onHide={handleClose}
      className="kt-subsidy-children-enrollment-modal"
    >
      <RbModal.Header closeButton className="p-4">
        <AvatarDataTableCell
          initials={`${agencyAccountChild.childFirstName.toUpperCase().charAt(0)}${agencyAccountChild.childLastName
            .toUpperCase()
            .charAt(0)}`}
          avatar={agencyAccountChild.avatar?.url ?? ''}
          primaryText={`${capitalize(agencyAccountChild.childFirstName)} ${capitalize(
            agencyAccountChild.childLastName
          )}`}
          secondaryText={`Account: ${agencyAccountChild.accountName}`}
        />
      </RbModal.Header>
      <RbModal.Body className="p-4">
        <div className="d-flex justify-content-between mb-4">
          <div>
            <p className="mb-1">
              {capitalize(t('translation:spelling.center'))}: {agencyAccountChild.centerName}
            </p>
            <p>
              {agencyAccountChild.agencyName} <span className="ml-2">{renderStatusTag(agencyAccountChild.status)}</span>
            </p>
          </div>
          <div>
            <DateRangeInputWithTimezone
              startDate={timeframe.start}
              endDate={timeframe.end}
              onChange={(dates) => setTimeframe({ start: dates.startDate, end: dates.endDate })}
              className="kt-date-input-no-max-width"
              timezone="America/New_York" // temp
            />
          </div>
        </div>
        <div>
          {loading ? (
            <LoadingLines number={5} />
          ) : (
            <ul className="unstyled-list">
              {datesInTimeframe.map((date: moment.Moment, idx: number) => (
                <SessionInformationListItem
                  key={`li-${idx}`}
                  date={date.toISOString()}
                  session={sessions.find((s) => moment(s.date).isSame(date, 'date')) ?? null}
                />
              ))}
            </ul>
          )}
          <div className="d-flex align-items-center justify-content-between mb-2">
            <small>
              <i>* NS = Not Scheduled * S = Scheduled</i>
            </small>
            <div>
              <b className="d-block">
                Total Days: {sessions.filter((s) => !s.absence && s.timeEntries.length > 0).length}
              </b>
              <b className="d-block">Total Hours: {calculateTotalAttendedHours(sessions).toFixed(2)}</b>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="border py-2 px-6">
              Absent Days Used: {sessions.filter((s) => s.absence !== null).length}
            </div>
          </div>
        </div>
      </RbModal.Body>
    </RbModal>
  );
};

export default SubsidyChildAttendanceModal;
