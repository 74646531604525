import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PageWrapper from 'shared/components/PageWrapper';
import BusinessHoursTab from './BusinessHoursTab';
import PayWeekTab from './PayWeekTab';
import { isRegion } from 'shared/util/region';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PayCodesTab from './PayCodesTab';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TimeLogLocking from './TimeLogLockingTab/TimeLogLocking';
import SchedulingVisibility from './SchedulingVisibility/SchedulingVisibility';
import PositionsTab from './PositionsTab';

type OperationTabs = 'businessHours' | 'payWeek' | 'payCodes' | 'time-log-locking' | 'scheduling-visibility';
interface IRouteParams {
  activeTab: OperationTabs;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const Operations: React.FC<IProps> = ({ ...props }) => {
  const { activeTab } = props.match.params;
  const [activeTabKey, setActiveTabKey] = useState<OperationTabs>(activeTab ?? 'businessHours');

  const history = useHistory();

  const { k2WebPtoTypes, k2TimeLogLock } = useFlags();

  React.useEffect(() => {
    setActiveTabKey(activeTab ?? 'businessHours');
  }, [activeTab]);

  return (
    <PageWrapper pageTitle="Operations" applyPadding={false}>
      <Tabs
        id="setting-operations-tabs"
        activeKey={activeTabKey}
        onSelect={(tab) => {
          setActiveTabKey(tab as OperationTabs);
          history.push(`/settings/center/operations/${tab}`);
        }}
        style={{
          paddingLeft: '16px',
          position: 'sticky',
          top: '119px',
          zIndex: 900,
        }}
        className="mb-0"
      >
        <Tab eventKey="businessHours" title="Business Hours">
          <BusinessHoursTab />
        </Tab>
        {isRegion('US') && (
          <Tab eventKey="payWeek" title="Pay Week">
            <PayWeekTab />
          </Tab>
        )}
        {k2WebPtoTypes && (
          <Tab eventKey="payCodes" title="Pay Codes">
            <PayCodesTab />
          </Tab>
        )}
        {k2TimeLogLock && (
          <Tab eventKey="time-log-locking" title="Time Log Locking">
            <TimeLogLocking />
          </Tab>
        )}
        <Tab eventKey="scheduling-visibility" title="Scheduling Visibility">
          <SchedulingVisibility />
        </Tab>
        <Tab eventKey="positions" title="Positions">
          <PositionsTab />
        </Tab>
      </Tabs>
    </PageWrapper>
  );
};

export default Operations;
