import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { ISearchCentersData, ISearchCentersVariables } from 'gql/center/queries';
import { useMemo } from 'react';
import { orderBy } from 'lodash';

const { TERM } = SEARCH_EXPRESSIONS;
const { ACTIVE } = PREDICATES;

const centerFields = `
  id
  entityId
  name
  timezone
`;

export const GET_ACTIVE_CENTERS = (fields: string = centerFields) => gql`
  query ($input: SearchInput!) {
    searchCenters(input: $input) {
      totalResults
      data {
       ${fields}
      }
    }
  }
`;

export const useGetActiveCentersQuery = (
  fields?: string,
  options?: QueryHookOptions<ISearchCentersData, ISearchCentersVariables> | undefined
) =>
  useQuery<ISearchCentersData, ISearchCentersVariables>(GET_ACTIVE_CENTERS(fields), {
    variables: {
      input: {
        filter: { [TERM]: { field: 'active', predicate: ACTIVE } },
        sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
        size: 10000,
        from: 0,
      },
    },
    fetchPolicy: 'cache-and-network',
    ...options,
  });

const useGetActiveCenters = (): ICenter[] => {
  const { data } = useGetActiveCentersQuery();

  return data?.searchCenters?.data || [];
};

export const GET_CENTERS = gql`
  query ($input: SearchInput!) {
    searchCenters(input: $input) {
      totalResults
      data {
       ${centerFields}
      }
    }
  }
`;

export const useGetCentersQuery = (options?: QueryHookOptions<ISearchCentersData, ISearchCentersVariables>) =>
  useQuery(GET_CENTERS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetActiveCentersWithLoading = (): {
  data: ICenter[] | undefined;
  loading: boolean;
  error?: unknown;
} => {
  const { data, loading, error } = useGetActiveCentersQuery();
  return { data: data?.searchCenters?.data, loading, error };
};

export const useGetActiveCenterOptions = (
  fields?: string
): {
  data: ICenter[] | undefined;
  loading: boolean;
  centerOptions: ITableFilterOption[];
} => {
  const { data, loading } = useGetActiveCentersQuery(fields ?? centerFields);

  const centerOptions: ITableFilterOption[] = useMemo(
    () =>
      orderBy(data?.searchCenters.data ?? [], (center) => center.name.toLocaleLowerCase(), 'asc').map(
        (center: ICenter) => ({
          label: center.name,
          value: center.id,
          searchExpression: {
            [SEARCH_EXPRESSIONS.TERM]: {
              field: 'centerId.keyword',
              predicate: PREDICATES.CONTAINS,
              value: center.id,
            },
          },
        })
      ),
    [data]
  );
  return { data: data?.searchCenters?.data, loading, centerOptions };
};

export default useGetActiveCenters;
