import PageWrapper from 'shared/components/PageWrapper';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import React, { useCallback, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { CirclePlusButton, ReviewButton } from 'shared/components/Buttons';
import TransactionDetails from './TransactionDetails';
import BulkTransactionTargets from './BulkTransactionTargets';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { isBlank } from '../../../shared/util/string';
import { useGetTransactionTypes } from '../../../gql/transaction/queries';
import Card from 'shared/components/Card';
import { Box } from '@mui/material';
import Currency from 'shared/components/Currency';
import axios from 'axios';
import config from 'config';
import { showToast } from 'shared/components/Toast';
import useFormatDate from 'shared/hooks/useFormatDate';
import kindyTransactionTypes from 'shared/constants/kindyTransactionTypes';

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const useCreateBulkTransaction = ({
  targetType,
  onSuccessfulSave,
  onErrorSaving,
}: {
  onSuccessfulSave?: () => void;
  onErrorSaving?: (message: string) => void;
  targetType: string;
}) => {
  // infrastructure
  // load data
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);
  const [saving, setSaving] = useState<boolean>(false);
  const [errorSaving, setErrorSaving] = useState<string | undefined>();
  const saveBulkTransaction = useCallback(
    async (bulkTransactionInput) => {
      setSaving(true);
      try {
        await axios.post(
          `${config.api.billing.uri}/api/v2/bulk-transactions/create`,
          {
            ...bulkTransactionInput,
            accountIds: targetType === 'Account' ? bulkTransactionInput.targets.map((t) => t.id) : undefined,
            accountChildIds:
              targetType === 'Children' ? bulkTransactionInput.targets.map((t) => t.accountChildId) : undefined,
            date: moment(bulkTransactionInput.date).format('YYYY-MM-DD'),
          },
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        if (onSuccessfulSave) onSuccessfulSave();
      } catch (e) {
        let message: string = '';
        if (e instanceof Error) {
          message = e.message;
        }
        setErrorSaving(message);
        if (onErrorSaving) onErrorSaving(message);
      } finally {
        setSaving(false);
      }
    },
    [jwtToken, onErrorSaving, onSuccessfulSave, targetType]
  );
  return { saving, errorSaving, saveBulkTransaction };
};

const CreateBulkTransaction: React.FC<IProps> = () => {
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);

  const [bulkTransaction, setBulkTransactionData] = useState<IBulkTransaction>({
    description: '',
    transactionTypeId: '',
    transactionType: null,
    date: '',
    amount: 0,
    targets: [],
    businessId: currentBusinessId ?? '',
  });

  const handleFormDataChange = useCallback((bulkTransactionUpdates: Partial<IBulkTransaction>) => {
    setBulkTransactionData((prev) => ({ ...prev, ...bulkTransactionUpdates }));
    if (
      bulkTransactionUpdates.transactionTypeId &&
      kindyTransactionTypes.includes(bulkTransactionUpdates.transactionTypeId)
    )
      setAppliesTo('Children');
  }, []);

  const history = useHistory();
  const [display, setDisplay] = useState<string>('Configure');
  const [appliesTo, setAppliesTo] = useState<string>('Account');
  const handleAppliesToChange = useCallback((appliesTo: string) => {
    setBulkTransactionData((prev) => ({ ...prev, targets: [] }));
    setAppliesTo(appliesTo);
  }, []);

  const { data: getTransactionTypesData, loading: getTransactionTypesLoading } = useGetTransactionTypes({
    variables: {
      businessId: currentBusinessId ?? '',
      centerIds: null,
    },
    fetchPolicy: 'network-only',
    skip: Boolean(!currentBusinessId) || Boolean(!currentBusinessId),
  });

  const selectedTransactionTypeIsKindy = useCallback(() => {
    if (!bulkTransaction.transactionTypeId) return false;
    return kindyTransactionTypes.includes(bulkTransaction.transactionTypeId);
  }, [bulkTransaction.transactionTypeId]);

  const validBulkTransaction = useMemo(() => {
    return (
      moment(bulkTransaction.date).isValid() &&
      !isBlank(bulkTransaction.description) &&
      !isBlank(bulkTransaction.transactionTypeId) &&
      !isNaN(bulkTransaction.amount) &&
      bulkTransaction.amount !== 0 &&
      bulkTransaction.targets.length > 0
    );
  }, [bulkTransaction]);

  const summary: IBulkTransactionSummary = useMemo(() => {
    return {
      ...bulkTransaction,
      transactionType: bulkTransaction.transactionType?.name ?? '',
      isCredit: bulkTransaction.transactionType?.isCredit ?? false,
      totalTargets: bulkTransaction.targets.length,
      totalAmount: bulkTransaction.targets.length * bulkTransaction.amount,
    };
  }, [bulkTransaction]);

  const handleSaveSuccess = useCallback(() => {
    showToast('Successfully saved bulk transaction', 'success');
    history.push('/billing/transactions');
  }, [history]);

  const handleSaveError = useCallback((message: string) => {
    if (!message) message = 'Unknown Error while saving bulk transaction.';
    showToast(message, 'error');
  }, []);

  const { saving: savingBulkTransaction, saveBulkTransaction } = useCreateBulkTransaction({
    onErrorSaving: handleSaveError,
    onSuccessfulSave: handleSaveSuccess,
    targetType: appliesTo,
  });
  const handleSave = useCallback(async () => {
    await saveBulkTransaction(bulkTransaction);
  }, [bulkTransaction, saveBulkTransaction]);

  return (
    <PageWrapper
      pageTitle="Add Bulk Transaction"
      applyPadding={true}
      mobileButtonComponent={
        <CirclePlusButton variant="primary" className="my-4" onClick={() => alert('save click!')} />
      }
      buttonComponent={
        display === 'Configure' ? (
          <HasRoleAreaLevel
            action={{ area: AreaType.Billing, permission: PermissionType.Base, level: RoleLevelType.Create }}
          >
            <ReviewButton disabled={!validBulkTransaction} onClick={() => setDisplay('Review')}>
              Review
            </ReviewButton>
          </HasRoleAreaLevel>
        ) : (
          <HasRoleAreaLevel
            action={{ area: AreaType.Billing, permission: PermissionType.Base, level: RoleLevelType.Create }}
          >
            <Button disabled={!validBulkTransaction || savingBulkTransaction} onClick={() => handleSave()}>
              Save
            </Button>
          </HasRoleAreaLevel>
        )
      }
      secondaryButtonComponent={
        display === 'Configure' ? (
          <Button variant="light" className="mr-4" disabled={false} onClick={() => history.goBack()}>
            Cancel
          </Button>
        ) : (
          <Button
            variant="light"
            className="mr-4"
            disabled={savingBulkTransaction}
            onClick={() => setDisplay('Configure')}
          >
            Back
          </Button>
        )
      }
    >
      {display === 'Configure' ? (
        <>
          <TransactionDetails
            bulkTransaction={bulkTransaction}
            transactionTypeOptions={getTransactionTypesData?.getTransactionTypes ?? []}
            transactionTypeLoading={getTransactionTypesLoading}
            appliesTo={appliesTo}
            mustApplyChildren={selectedTransactionTypeIsKindy()}
            onChange={handleFormDataChange}
            onAppliesToChange={handleAppliesToChange}
          />
          <BulkTransactionTargets
            targetType={appliesTo}
            businessId={currentBusinessId}
            display={display}
            selectedTargets={bulkTransaction.targets}
            onSelectedTargetsChange={(targets) => {
              handleFormDataChange({ targets: [...targets] });
            }}
          />
        </>
      ) : (
        <BulkTransactionSummary
          bulkTransactionSummary={summary}
          transactionTargetType={appliesTo}
        ></BulkTransactionSummary>
      )}
    </PageWrapper>
  );
};

interface IBulkTransactionSummary {
  description: string;
  transactionType: string;
  isCredit: boolean;
  date: string;
  amount: number;
  totalTargets: number;
  totalAmount: number;
}

function BulkTransactionSummary({
  bulkTransactionSummary,
  transactionTargetType,
}: {
  bulkTransactionSummary: IBulkTransactionSummary;
  transactionTargetType: string;
}) {
  const formatDate = useFormatDate();
  const { description, transactionType, date, amount, totalTargets, totalAmount, isCredit } = bulkTransactionSummary;
  const debitCreditLabel = isCredit ? 'Credit' : 'Debit';
  const targetsLabel = transactionTargetType === 'Account' ? 'Account(s)' : 'Child(ren)';
  const displayAmount = useMemo(() => (isCredit ? amount : -amount), [amount, isCredit]);
  const displayTotalAmount = useMemo(() => (isCredit ? totalAmount : -totalAmount), [isCredit, totalAmount]);
  return (
    <Card header="Transaction Summary">
      <Box display={'flex'} flexDirection={'row'}>
        <Box flexGrow={1.5} border={'1px solid black'} padding={'1rem'} marginRight={'1rem'}>
          <h6>Name/Description:</h6>
          <p>{description}</p>
          <h6>Type:</h6>
          <p>{transactionType}</p>
          <h6>Apply Date</h6>
          <p>{formatDate(date)}</p>
        </Box>
        <Box flexGrow={1} padding={'1rem'} bgcolor={'#E2E7EE'}>
          <h4 style={{ marginBottom: '1.5rem' }}>{debitCreditLabel}</h4>
          <CreditDetailContainer>
            <p>Amount</p>
            <Currency themed={false} amount={displayAmount} />
          </CreditDetailContainer>
          <CreditDetailContainer>
            <p>{`${targetsLabel} ${debitCreditLabel}ed`}</p>
            <p>{totalTargets}</p>
          </CreditDetailContainer>
          <CreditDetailContainer>
            <b>Total</b>
            <b>
              <Currency themed={false} amount={displayTotalAmount} />
            </b>
          </CreditDetailContainer>
        </Box>
      </Box>
    </Card>
  );
}

function CreditDetailContainer(props: any) {
  return (
    <Box display={'flex'} justifyContent={'space-between'}>
      {props.children}
    </Box>
  );
}

export default CreateBulkTransaction;
