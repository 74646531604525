import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateButton } from 'shared/components/Buttons';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {
  onCreateCardClick: () => void;
  onCreateAchClick: () => void;
  loading: boolean;
  showCreateAchButton?: boolean;
}

const CreatePaymentMethodButtons: React.FC<IProps> = ({
  showCreateAchButton,
  loading,
  onCreateAchClick,
  onCreateCardClick,
}) => {
  const { t } = useTranslation(['billing']);
  const hasCreatePaymentMethodsPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.PaymentMethods,
    level: RoleLevelType.Create,
  });
  const hasCreateBusinessBillingPermissions = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Billing,
    level: RoleLevelType.Create,
  });
  return (
    <div>
      <div className="d-flex align-items-center justify-content-end">
        {(hasCreatePaymentMethodsPermissions || hasCreateBusinessBillingPermissions) && (
          <div className="d-flex flex-row">
            {showCreateAchButton && (
              /**
               * We are hiding the option to add a bank account for the users who will be using
               * the beta environment business (Prime Academy)
               */
              <CreateButton
                variant="outline-primary"
                onClick={onCreateAchClick}
                className="flex-1 mr-4"
                disabled={loading}
              >
                {t('billing:payment-methods.add-bank-account')}
              </CreateButton>
            )}
            <CreateButton variant="primary" onClick={onCreateCardClick} className="mb-4 flex-1" disabled={loading}>
              {t('billing:payment-methods.add-card')}
            </CreateButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreatePaymentMethodButtons;
