import React from 'react';

import * as Mui from '@mui/material';
import Button, { IconButtonCircle } from 'shared/components/Buttons';
import { faEllipsisH, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import EditPosition from './EditPosition';
import { useArchivePosition } from '../graphQL/mutations';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
};

interface IProps {
  row: IPosition;
  refetch: () => void;
}

const PositionTableRow: React.FC<IProps> = ({ row, refetch }) => {
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [archiveModalOpen, setArchiveModalOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const hasEditOperationsPermission = useHasRoleAreaLevel({
    area: AreaType.Operations,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const hasDeleteOperationsPermission = useHasRoleAreaLevel({
    area: AreaType.Operations,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });

  const [archivePositionFn] = useArchivePosition({
    onCompleted: () => {
      showToast('Position Archived', 'success');
      refetch();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onArchive = () => {
    setArchiveModalOpen(false);
    archivePositionFn({
      variables: {
        input: {
          businessId: row.businessId,
          id: row.id,
        },
      },
    });
  };

  return (
    <>
      <Mui.TableRow key={row.id}>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">{row.name}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="center">
          <Mui.Typography variant="body1">{row.assignedStaffCount}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="center">
          <Mui.Typography variant="body1">{row.archivedAt ? 'Yes' : 'No'}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="right">
          <>
            {(hasDeleteOperationsPermission || hasEditOperationsPermission) && (
              <IconButtonCircle
                id="action-dropdown-btn"
                iconSize="2x"
                icon={faEllipsisH}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              />
            )}
            <Mui.Menu
              PaperProps={{ sx: { boxShadow: '1' } }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {hasEditOperationsPermission && (
                <Mui.MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setOpenEdit(true);
                  }}
                >
                  Edit
                </Mui.MenuItem>
              )}
              {hasDeleteOperationsPermission && (
                <Mui.MenuItem
                  disabled={!!row.archivedAt}
                  onClick={() => {
                    setAnchorEl(null);
                    setArchiveModalOpen(true);
                  }}
                >
                  Archive
                </Mui.MenuItem>
              )}
            </Mui.Menu>
          </>
        </Mui.TableCell>
      </Mui.TableRow>
      <EditPosition open={openEdit} onClose={() => setOpenEdit(false)} position={row} />
      <Mui.Dialog open={archiveModalOpen} onClose={() => setArchiveModalOpen(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography variant="dialogTitle">Archive Position</Mui.Typography>
          <Mui.IconButton onClick={() => setArchiveModalOpen(false)} disableRipple sx={styles.closeIcon}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        {row.assignedStaffCount! > 0 && (
          <Mui.DialogContent>
            {`${row.assignedStaffCount} employee(s) assigned to the position "${row.name}".`}
            <br />
            {`In order to archive this position, all employees must be assigned to another position from their Employee Profile screen.`}
          </Mui.DialogContent>
        )}
        {row.assignedStaffCount === 0 && (
          <Mui.DialogContent>{`Are you sure you want to archive ${row.name}?`}</Mui.DialogContent>
        )}
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setArchiveModalOpen(false)}>
            Cancel
          </Mui.Button>

          <div className="d-flex">
            <Button
              className="ml-2"
              variant="outline-danger"
              onClick={() => onArchive()}
              disabled={row.assignedStaffCount > 0}
            >
              Archive
            </Button>
          </div>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default PositionTableRow;
