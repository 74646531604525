import React, { useCallback, useMemo, useState, useEffect } from 'react';
//components
import DateInput from 'shared/components/DateInput';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Col, Row } from 'shared/components/Layout';
import PhoneInput from 'shared/components/PhoneInput';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import Checkbox from 'shared/components/Checkbox';
import { showToast } from 'shared/components/Toast';
import NotifiableEventsContainer from '../NotifiableEventsContainer';
import { AddressInputsForProvider } from './components/AddressInputsForProvider';
//utils
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useCreateCcssProviderFinancialStatus } from '../../graphql/mutations';
import {
  addressTypeOptions,
  emptyAddressInputs,
  emptyInputs,
  emptySaleInputs,
  IProviderAddressNotInput,
  IProviderAdministratorInput,
  IProviderEntersInput,
  statusForSelect,
} from './common';

interface IProps {
  businessId: string;
}

export const ProviderEntersIntoAdmLiqd: React.FC<IProps> = ({ businessId }) => {
  const acceptedFileExts = ['.pdf', '.png', '.tiff', '.jpg'];

  const { t } = useTranslation('business');
  const [createCcssProviderFinancialStatus, { loading: createCcssProviderFnStLoading }] =
    useCreateCcssProviderFinancialStatus();

  const [supportingDocuments, setSupportingDocuments] = useState<Array<File>>([]);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [addPostalAddress, setAddPostalAddress] = useState<boolean>(false);
  const [formData, setFormData] = useState<IProviderEntersInput>(emptyInputs);
  const [formAdminData, setFormAdminData] = useState<IProviderAdministratorInput>(emptySaleInputs);
  const [formAddressData, setFormAddressData] = useState<IProviderAddressNotInput>(emptyAddressInputs);
  const [formPostalAddressData, setFormPostalAddressData] = useState<IProviderAddressNotInput>(emptyAddressInputs);
  const [addressesArray, setAddressesArray] = useState<IProviderAddressNotInput[]>([
    {
      streetLine1: formAddressData.streetLine1,
      streetLine2: formAddressData.streetLine2,
      suburb: formAddressData.suburb,
      state: formAddressData.state,
      postcode: formAddressData.postcode,
      type: formAddressData.type,
    },
  ]);

  useEffect(() => {
    if (addPostalAddress) {
      setAddressesArray([
        {
          streetLine1: formAddressData.streetLine1,
          streetLine2: formAddressData.streetLine2,
          suburb: formAddressData.suburb,
          state: formAddressData.state,
          postcode: formAddressData.postcode,
          type: formAddressData.type,
        },
        {
          streetLine1: formPostalAddressData.streetLine1,
          streetLine2: formPostalAddressData.streetLine2,
          suburb: formPostalAddressData.suburb,
          state: formPostalAddressData.state,
          postcode: formPostalAddressData.postcode,
          type: formPostalAddressData.type,
        },
      ]);
    }
  }, [addPostalAddress, formAddressData, formPostalAddressData]);

  const isFormValid = useMemo(
    () =>
      !!formData.startDate &&
      !!formData.endDate &&
      !!formData.providerId &&
      !!formData.status &&
      supportingDocuments.length > 0,
    [formData, supportingDocuments]
  );

  const isFormWithAdminValid = useMemo(
    () =>
      isFormValid &&
      !!formAdminData.name &&
      !!formAdminData.abn &&
      !!formAdminData.startDate &&
      !!formAdminData.endDate &&
      !!formAdminData.phone &&
      !!formAddressData.type &&
      !!formAddressData.state &&
      !!formAddressData.postcode &&
      !!formAddressData.streetLine1 &&
      !!formAddressData.suburb,
    [formAdminData, formAddressData, supportingDocuments, isFormValid]
  );

  const isFormWithPostalAddressValid = useMemo(
    () =>
      isFormWithAdminValid &&
      !!formPostalAddressData.type &&
      !!formPostalAddressData.state &&
      !!formPostalAddressData.postcode &&
      !!formPostalAddressData.streetLine1 &&
      !!formPostalAddressData.suburb,
    [formPostalAddressData, isFormWithAdminValid]
  );

  const addOrRemovePostalAddress = () => {
    if (addPostalAddress) {
      setAddPostalAddress(!addPostalAddress);
      setFormPostalAddressData(emptyAddressInputs);
    } else {
      setAddPostalAddress(!addPostalAddress);
    }
  };

  const setSelectedFiles = (files: File[]) => {
    const acceptedFiles = uniqBy(files, (f) => f.name).filter((f) =>
      acceptedFileExts.includes('.' + (f.name.split('.').pop()?.toLowerCase() ?? ''))
    );

    setSupportingDocuments(acceptedFiles);
  };

  const handleSave = useCallback(() => {
    const adminData =
      formData.status === 'ADMN'
        ? {
            email: formAdminData.email,
            name: formAdminData?.name ?? '',
            abn: formAdminData.abn,
            phone: formAdminData.phone?.replace('+61', '0'),
            startDate: formAdminData.startDate,
            endDate: formAdminData.endDate,
            addresses: addressesArray,
          }
        : null;

    createCcssProviderFinancialStatus({
      variables: {
        input: {
          businessId,
          providerId: formData.providerId,
          status: formData.status,
          startDate: formData.startDate,
          endDate: formData.endDate,
          supportingDocumentFiles: supportingDocuments,
          administrator: adminData,
        },
      },
    })
      .then(() => {
        showToast(t('ccs-management.provider-enters-into-admn-recp-liqd.message-toast.success'), 'success');
      })
      .catch((err) => {
        err = err.toString().replace('Error: GraphQL error:', '').trim();
        const errorMessage = !!err ? err : t('ccs-management.message-error');
        showToast(errorMessage, 'error');
      });
  }, [
    createCcssProviderFinancialStatus,
    formData,
    formAdminData,
    formAddressData,
    formPostalAddressData,
    supportingDocuments,
  ]);

  return (
    <NotifiableEventsContainer
      title={t('ccs-management.provider-enters-into-admn-recp-liqd.title')}
      notificationMessage={t('ccs-management.provider-enters-into-admn-recp-liqd.notification')}
      onCancel={() => setFormData(emptyInputs)}
      onSave={handleSave}
      formIsDirty={!formIsDirty}
      toggleDirty={setFormIsDirty}
      saveDisabled={
        formData.status !== 'ADMN'
          ? !isFormValid
          : addPostalAddress
          ? !isFormWithPostalAddressValid
          : !isFormWithAdminValid
      }
      isSaving={createCcssProviderFnStLoading}
      providerOnChange={(option) => setFormData({ ...formData, providerId: option.value })}
      selectedProvider={formData.providerId}
      child={
        <>
          <Row>
            <Col className="mb-2">
              <Row>
                <Col>
                  <DateInput
                    required
                    date={formData.startDate}
                    label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.start-date')}
                    isOutsideRange={(day) => formData.endDate && day.isAfter(moment(formData.endDate).endOf('day'))}
                    onDateSelect={(date: string) => setFormData({ ...formData, startDate: date })}
                    disabled={false}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="mb-2">
              <Row>
                <Col>
                  <DateInput
                    required
                    date={formData.endDate}
                    label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.end-date')}
                    isOutsideRange={(day) =>
                      formData.startDate && day.isBefore(moment(formData.startDate).endOf('day'))
                    }
                    onDateSelect={(date: string) => setFormData({ ...formData, endDate: date })}
                    disabled={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <Select
                required
                inputId="address-type"
                label="Reason for closure"
                options={statusForSelect}
                className="mb-0"
                // @ts-ignore
                value={statusForSelect.find((option) => option?.value === formData.status)}
                onChange={(option) => setFormData({ ...formData, status: option.value })}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <div className="d-flex flex-row">
                <label className="form-label">
                  {t('ccs-management.provider-enters-into-admn-recp-liqd.labels.attach-doc')}
                </label>
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              </div>
              <FileSelectDropbox
                value={supportingDocuments}
                onChange={setSelectedFiles}
                showFiles={true}
                acceptedFileExts={acceptedFileExts.join(',')}
              />
            </Col>
          </Row>
          {formData.status === 'ADMN' && (
            <>
              <hr />
              <h6>{t('ccs-management.provider-enters-into-admn-recp-liqd.admin.details')}</h6>
              <Row>
                <Col className="mb-2">
                  <Row>
                    <Col>
                      <DateInput
                        required
                        date={formAdminData.startDate}
                        label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.start-date')}
                        isOutsideRange={(day) =>
                          formAdminData.endDate && day.isAfter(moment(formAdminData.endDate).endOf('day'))
                        }
                        onDateSelect={(date: string) => setFormAdminData({ ...formAdminData, startDate: date })}
                        disabled={false}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="mb-2">
                  <Row>
                    <Col>
                      <DateInput
                        required
                        date={formAdminData.endDate}
                        label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.end-date')}
                        isOutsideRange={(day) =>
                          formAdminData.startDate && day.isBefore(moment(formAdminData.startDate).endOf('day'))
                        }
                        onDateSelect={(date: string) => setFormAdminData({ ...formAdminData, endDate: date })}
                        disabled={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="mb-n2">
                  <TextInput
                    required
                    label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.abn')}
                    name="providerName"
                    value={formAdminData.abn}
                    onChange={(value: string) => setFormAdminData({ ...formAdminData, abn: value })}
                    disabled={false}
                    maxlength="11"
                  />
                </Col>
                <Col>{''}</Col>
              </Row>
              <Row>
                <Col className="mb-n2">
                  <TextInput
                    required
                    label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.contact-name')}
                    name="contactPerson"
                    value={formAdminData.name}
                    onChange={(value: string) => setFormAdminData({ ...formAdminData, name: value })}
                    disabled={false}
                    maxlength="120"
                  />
                </Col>
                <Col>{''}</Col>
              </Row>
              <Row>
                <Col className="mb-n2">
                  <PhoneInput
                    required
                    label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.contact-phone')}
                    name="phoneNumber"
                    value={formAdminData.phone}
                    onChange={(value: string) => setFormAdminData({ ...formAdminData, phone: value })}
                    disabled={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-n2">
                  <TextInput
                    required={false}
                    label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.contact-email')}
                    name="email"
                    value={formAdminData.email}
                    onChange={(value: string) => setFormAdminData({ ...formAdminData, email: value })}
                    disabled={false}
                    maxlength="241"
                  />
                </Col>
                <Col>{''}</Col>
              </Row>
              <hr />
              <h6>{t('ccs-management.provider-enters-into-admn-recp-liqd.admin.address')}</h6>
              <AddressInputsForProvider
                data={formAddressData}
                setData={setFormAddressData}
                disabledTypeSelect={true}
                typeValue={addressTypeOptions.find((option) => option.value === 'ZPHYSICAL')}
              />
              <Row>
                <Col className="mb-2">
                  <Checkbox
                    label={t('ccs-management.provider-enters-into-admn-recp-liqd.admin.add-postal-address')}
                    value={addPostalAddress}
                    onChange={addOrRemovePostalAddress}
                  />
                </Col>
              </Row>
              {addPostalAddress && (
                <AddressInputsForProvider
                  data={formPostalAddressData}
                  setData={setFormPostalAddressData}
                  disabledTypeSelect={true}
                  typeValue={addressTypeOptions.find((option) => option.value === 'ZPOSTAL')}
                />
              )}
            </>
          )}
        </>
      }
    />
  );
};
