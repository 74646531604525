import React from 'react';

interface IProps {
  color: string;
}

const IndicatorColor: React.FC<IProps> = ({ color, ...props }) => {
  return <div className="k2-datatable-dropdown-menu-item-color-indicator" style={{ backgroundColor: color }} />;
};

export default IndicatorColor;
