import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { getFullName } from 'shared/util/string';

interface IProps {
  onSubmit: () => void;
  onCancel: () => void;
  isOpen: boolean;
  loading?: boolean;
  summary?: ISubsidyWeeklySummary;
}
const ConfirmResubmitReportModal: React.FC<IProps> = ({ onSubmit, onCancel, isOpen, summary, loading }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      show={isOpen}
      primaryCallback={onSubmit}
      secondaryCallback={onCancel}
      primaryButtonProps={{ loading }}
      title={t('subsidy-tracking.resubmit-report')}
    >
      <p>
        Are you sure you want to resubmit <strong>{getFullName(summary?.child)}'s</strong> session report for week
        ending <strong>{summary ? moment(summary?.weekEnding).format('DD/MM/YYYY') : ''}</strong>?
      </p>
    </ConfirmationModal>
  );
};

export default ConfirmResubmitReportModal;
