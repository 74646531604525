import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import {
  paginatedTransactionsInTimeframePagedResults,
  paginatedPaymentsPagedResults,
  processingPaymentsFields,
  transactionFields,
  transactionTypeFields,
  sumOfPaymentInTimeFrame,
  transactionsTimeframeFields,
} from './fields';
import { useDispatch } from 'react-redux';
import { getTransactions } from 'pages/BillingTransactions/duck/action';

interface IGetTransactionsInTimeframeData {
  getTransactionsInTimeframe: ITransaction[];
}

interface IGetTransactionsInTimeframeVariables {
  start: string;
  end: string;
  paymentTransactionsOnly?: Boolean;
}

interface IGetProcessingPaymentInTimeframeData {
  getProcessingPaymentInTimeframe: IProcessingPaymentsChart;
}

interface IGetProcessingPaymentInTimeframeVariables {
  start: string;
  end: string;
  paymentTransactionsOnly?: Boolean;
  centerIds?: string[];
}

interface IGetAccountTransactionsInTimeframeData {
  getAccountTransactionsInTimeframe: ITransactionsForAccountInTimeframeDto;
}

interface IGetAccountTransactionsInTimeframeVariables {
  accountId: string;
  start: string;
  end: string;
  paymentTransactionsOnly?: Boolean;
}

interface IGetTransactionTypesData {
  getTransactionTypes: ITransactionType[];
}

interface IGetTransactionTypesVariables {
  businessId: string;
  centerId?: string | null;
  centerIds?: string[] | null;
  manualTransactionTypesOnly?: boolean;
  category?: 'CREDIT' | 'CHARGE' | 'PAYMENT';
}

interface IGetAllowedTransactionTypesData {
  getAllowedTransactionTypes: ITransactionType[];
}

interface IGetUnbilledTransactionsForAccountData {
  getUnbilledTransactionsForAccount: ITransaction[];
}

interface IGetUnbilledTransactionsForAccountVariables {
  accountId: string;
  date: string;
}

export interface IPaymentSummary {
  paymentStatus: string;
  totalAmount: number;
  paymentCount: number;
  earliestEntry: string;
  latestEntry: string;
}

export const GET_SUM_OF_PAYMENTS_IN_TIMEFRAME = (fields: string = sumOfPaymentInTimeFrame) => gql`
  query ($input: GetSumOfPaymentsInTimeFrameInput!) {
    getSumOfPaymentsInTimeFrame(input: $input) {
      ${fields}
    }
  }
`;

export const GET_TRANSACTIONS_IN_TIMEFRAME = (fields: string = transactionFields) => gql`
  query ($start: Date!, $end: Date!, $paymentTransactionsOnly: Boolean) {
    getTransactionsInTimeframe(start: $start, end: $end, paymentTransactionsOnly: $paymentTransactionsOnly) {
      ${fields}
    }
  }
`;

export const GET_PROCESSING_PAYMENT_IN_TIMEFRAME = (fields: string = processingPaymentsFields) => gql`
  query ($start: Date!, $end: Date!, $paymentTransactionsOnly: Boolean, $centerIds: [ID]) {
    getProcessingPaymentInTimeframe(start: $start, end: $end, paymentTransactionsOnly: $paymentTransactionsOnly, centerIds: $centerIds) {
      ${fields}
    }
  }
`;

export const GET_ACCOUNT_TRANSACTIONS_IN_TIMEFRAME = (fields: string = transactionsTimeframeFields) => gql`
  query ($accountId: ID!, $start: Date!, $end: Date!, $paymentTransactionsOnly: Boolean) {
    getAccountTransactionsInTimeframe(accountId: $accountId, start: $start, end: $end, paymentTransactionsOnly: $paymentTransactionsOnly) {
      ${fields}
    }
  }
`;

export const GET_TRANSACTION_TYPES = (fields: string = transactionTypeFields) => gql`
  query($businessId: ID!, $centerId: ID, $manualTransactionTypesOnly: Boolean, $centerIds: [ID], $category: String) {
    getTransactionTypes(businessId: $businessId, centerId: $centerId, manualTransactionTypesOnly: $manualTransactionTypesOnly, centerIds: $centerIds, category: $category) {
      ${fields}
    }
  }
`;

export const GET_PAGINATED_TRANSACTION_TYPES = gql`
  query($input: GetPaginatedTransactionTypesInput) {
    getPaginatedTransactionTypes(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        ${transactionTypeFields}
      }
    }
  }
`;

export const GET_ALLOWED_TRANSACTION_TYPES = (fields: string = transactionTypeFields) => gql`
  query {
    getAllowedTransactionTypes {
      ${fields}
    }
  }
`;

export const GET_FLAGGED_PAYMENTS = (fields: string = transactionFields) => gql`
  query {
    getFlaggedPayments {
      ${fields}
    }
  }
`;

export const GET_UNBILLED_TRANSACTIONS_FOR_ACCOUNT = (fields: string = transactionFields) => gql`
  query($accountId: ID!, $date: Date) {
    getUnbilledTransactionsForAccount(accountId: $accountId, date: $date) {
      ${fields}
    }
  }
`;

export const useGetTransactionsInTimeframe = (
  options?: QueryHookOptions<IGetTransactionsInTimeframeData, IGetTransactionsInTimeframeVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();

  return useQuery<IGetTransactionsInTimeframeData, IGetTransactionsInTimeframeVariables>(
    GET_TRANSACTIONS_IN_TIMEFRAME(fields),
    {
      onCompleted: (data) => {
        if (data.getTransactionsInTimeframe) {
          dispatch(getTransactions(data.getTransactionsInTimeframe ?? []));
        }
      },
      fetchPolicy: 'network-only',
      ...options,
    }
  );
};

export const useGetProcessingPaymentInTimeframe = (
  options?: QueryHookOptions<IGetProcessingPaymentInTimeframeData, IGetProcessingPaymentInTimeframeVariables>,
  fields?: string
) =>
  useQuery<IGetProcessingPaymentInTimeframeData, IGetProcessingPaymentInTimeframeVariables>(
    GET_PROCESSING_PAYMENT_IN_TIMEFRAME(fields),
    { ...options, fetchPolicy: 'network-only' }
  );

export const useGetAccountTransactionsInTimeframe = (
  options?: QueryHookOptions<IGetAccountTransactionsInTimeframeData, IGetAccountTransactionsInTimeframeVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();

  return useQuery<IGetAccountTransactionsInTimeframeData, IGetAccountTransactionsInTimeframeVariables>(
    GET_ACCOUNT_TRANSACTIONS_IN_TIMEFRAME(fields),
    {
      onCompleted: (data) => {
        if (data.getAccountTransactionsInTimeframe) {
          dispatch(
            getTransactions(
              data.getAccountTransactionsInTimeframe.transactions ?? [],
              data.getAccountTransactionsInTimeframe.openingBalance,
              data.getAccountTransactionsInTimeframe.closingBalance
            )
          );
        }
      },
      ...options,
    }
  );
};

export const useGetTransactionTypes = (
  options?: QueryHookOptions<IGetTransactionTypesData, IGetTransactionTypesVariables>,
  fields?: string
) => useQuery<IGetTransactionTypesData, IGetTransactionTypesVariables>(GET_TRANSACTION_TYPES(fields), options);

export const useGetPaginatedTransactionTypes = (
  options?: QueryHookOptions<IPaginatedTransactionTypes, IGetPaginatedTransactionTypesInput>,
  fields?: string
) =>
  useQuery<IPaginatedTransactionTypes, IGetPaginatedTransactionTypesInput>(GET_PAGINATED_TRANSACTION_TYPES, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useGetSumOfPaymentsInTimeFrame = (
  options?: QueryHookOptions<{ getSumOfPaymentsInTimeFrame: IPaymentSummary[] }, IGetSumOfPaymentsInTimeFrameInput>,
  fields?: string
) =>
  useQuery<{ getSumOfPaymentsInTimeFrame: IPaymentSummary[] }, IGetSumOfPaymentsInTimeFrameInput>(
    GET_SUM_OF_PAYMENTS_IN_TIMEFRAME(fields),
    options
  );

export const useGetAllowedTransactionTypes = (
  options?: QueryHookOptions<IGetAllowedTransactionTypesData, {}>,
  fields?: string
) => useQuery<IGetAllowedTransactionTypesData, {}>(GET_ALLOWED_TRANSACTION_TYPES(fields), options);

export const useGetFlaggedPayments = (
  options?: QueryHookOptions<{ getFlaggedPayments: ITransaction[] }, {}>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useQuery<{ getFlaggedPayments: ITransaction[] }, {}>(GET_FLAGGED_PAYMENTS(fields), {
    onCompleted: (data) => {
      if (data.getFlaggedPayments) {
        dispatch(getTransactions(data.getFlaggedPayments ?? []));
      }
    },
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const PAYMENT_FILTERS = [
  'FLAGGED',
  'FAILED',
  'UNSUBMITTED',
  'PENDING',
  'AUTOMATED',
  'MANUAL',
  'COMPLETED',
  'RETURNED',
  'REFUNDED',
] as const;

export type PaymentFilterType = typeof PAYMENT_FILTERS[number];

interface IGetPaginatedTransactionsInTimeframeVariables {
  input: {
    businessId: string;
    centerIds: string[];
    start: string;
    end: string;
    searchKey?: string;
    pageNumber: number;
    pageSize: number;
    sortBy: string;
    sortDirection: string;
    paymentTransactionsOnly?: Boolean;
    transactionTypeIds: string[];
    paymentFilters?: PaymentFilterType[];
  };
}

interface IGetPaginatedTransactionsInTimeframeData {
  getPaginatedTransactionsInTimeframe: IPagedResult<ITransaction>;
}

export const GET_PAGINATEDTRANSACTIONS_IN_TIMEFRAME = (fields: string = transactionFields) => gql`
  query ($input: GetPaginatedTransactionsInTimeframeInput!) {
    getPaginatedTransactionsInTimeframe(input: $input) {
      ${paginatedTransactionsInTimeframePagedResults}
    }
  }
`;

export const useGetPaginatedTransactionsInTimeframe = (
  options?: QueryHookOptions<IGetPaginatedTransactionsInTimeframeData, IGetPaginatedTransactionsInTimeframeVariables>,
  fields?: string
) =>
  useQuery<IGetPaginatedTransactionsInTimeframeData, IGetPaginatedTransactionsInTimeframeVariables>(
    GET_PAGINATEDTRANSACTIONS_IN_TIMEFRAME(fields),
    options
  );

interface IGetTransactionDiscountsVariables {
  transactionId: string;
}

interface IGetTransactionDiscountsData {
  getTransactionDiscounts: ITransaction[];
}

const GET_TRANSACTION_DISCOUNTS = gql`
  query ($transactionId: ID!) {
    getTransactionDiscounts(transactionId: $transactionId) {
      ${transactionFields}
    }
  }
`;

export const useGetTransactionDiscounts = (
  options?: QueryHookOptions<IGetTransactionDiscountsData, IGetTransactionDiscountsVariables>
) => useQuery<IGetTransactionDiscountsData, IGetTransactionDiscountsVariables>(GET_TRANSACTION_DISCOUNTS, options);

export const useLazyGetTransactionDiscounts = (
  options?: QueryHookOptions<IGetTransactionDiscountsData, IGetTransactionDiscountsVariables>
) => useLazyQuery<IGetTransactionDiscountsData, IGetTransactionDiscountsVariables>(GET_TRANSACTION_DISCOUNTS, options);

export const useGetUnbilledTransactionsForAccount = (
  options?: QueryHookOptions<IGetUnbilledTransactionsForAccountData, IGetUnbilledTransactionsForAccountVariables>,
  fields?: string
) =>
  useQuery<IGetUnbilledTransactionsForAccountData, IGetUnbilledTransactionsForAccountVariables>(
    GET_UNBILLED_TRANSACTIONS_FOR_ACCOUNT(fields),
    options
  );

interface IGetPaginatedPaymentsVariables {
  input: {
    businessId: string;
    centerIds: string[];
    startDate: string;
    endDate: string;
    searchKey?: string;
    pageNumber: number;
    pageSize: number;
    sortBy: string;
    sortDirection: string;
    transactionTypeIds: string[];
    paymentFilters?: PaymentFilterType[];
  };
}

interface IGetPaginatedPaymentsData {
  getPaginatedPayments: IPagedResult<ITransaction>;
}

export const GET_PAGINATED_PAYMENTS = (fields: string = transactionFields) => gql`
  query ($input: GetPaginatedPaymentsInput!) {
    getPaginatedPayments(input: $input) {
      ${paginatedPaymentsPagedResults}
    }
  }
`;

export const useGetPaginatedPayments = (
  options?: QueryHookOptions<IGetPaginatedPaymentsData, IGetPaginatedPaymentsVariables>,
  fields?: string
) => useQuery<IGetPaginatedPaymentsData, IGetPaginatedPaymentsVariables>(GET_PAGINATED_PAYMENTS(fields), options);
