import React from 'react';
import Spinner from 'shared/components/Spinner';
import './spinner.scss';

interface IProps {
  show: boolean;
  children: React.ReactNode;
  size?: 'small' | 'large';
}

// wrap a component that you would like to show an overlay containing a spinner when `show` is true
const SpinnerOverlay: React.FC<IProps> = ({ show, children, size = 'large', ...props }) => {
  if (!show) {
    return <> {children} </>;
  }

  return (
    <div className="position-relative">
      <div className="d-flex position-absolute h-100 w-100 top-0 left-0 spinner-overlay-content">
        <div className="d-flex flex-grow-1 justify-content-center align-items-center">
          <Spinner small={size === 'small'} large={size === 'large'} />
        </div>
      </div>
      {children}
    </div>
  );
};

export default SpinnerOverlay;
