import classNames from 'classnames';
import React, { createElement } from 'react';
import colors from '_colors.module.scss';
import Tooltip from '../Tooltip';

interface IProps {
  className?: string;
  text?: string;
  color?: string;
  variant?: 'rounded' | 'pill';
  tooltipText?: string;
  tooltipDirection?: 'left' | 'right' | 'top' | 'bottom';
  onClick?: (evt: any) => void;
  as?: 'span' | 'div';
}

const Tag: React.FC<IProps> = ({
  className,
  text,
  color,
  tooltipText,
  variant = 'rounded',
  tooltipDirection = 'top',
  onClick = () => {},
  as = 'span',
}) => {
  const isRounded = variant === 'rounded';
  const classes = classNames(
    {
      'text-white': color !== colors.yellow,
      rounded: isRounded,
      sm: true,
      'py-2': isRounded,
      'px-3': isRounded,
    },
    className
  );
  const elementProps = {
    style: { backgroundColor: color },
    className: classes,
    onClick,
  };
  const component = createElement(as, elementProps, text);

  return (
    <Tooltip text={tooltipText ?? ''} direction={tooltipDirection} showTooltip={Boolean(tooltipText)}>
      {component}
    </Tooltip>
  );
};

export default Tag;
