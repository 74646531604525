import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import Table from 'react-bootstrap/Table';
import { capitalize } from 'lodash';
import { IFormDataShape } from '../../context/ProgramsContext';
import weekDayOptions from 'shared/constants/enums/weekDayOptions';
import { ApplicationFeeType } from 'shared/constants/enums/applicationFeeType';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';
import './_confirmProgramDetailsModal.scss';

interface IProps {
  isOpen: boolean;
  loading: boolean;
  formData: IFormDataShape;
  title: string;
  setIsConfirmationModalOpen: (value: boolean) => void;
  handleSubmit: () => void;
}

const ConfirmProgramDetailsModal: React.FC<IProps> = ({
  loading,
  isOpen,
  formData,
  title,
  handleSubmit,
  setIsConfirmationModalOpen,
}) => {
  const { t } = useTranslation(['translation', 'enrollment']);

  const operatingDays = weekDayOptions
    .filter((d) => formData?.operatingWeekDays?.includes(d.value) ?? false)
    .map((d) => d.label)
    .join(', ');

  const parseTime = (time: string | null | undefined) => {
    if (!time) {
      return '';
    }

    try {
      const [hours, minutes] = time.split(':');
      if (parseInt(hours) > 12) {
        return `${parseInt(hours) - 12}:${minutes} PM`;
      } else {
        return `${hours}:${minutes} AM`;
      }
    } catch (error) {
      return '';
    }
  };

  return (
    <ConfirmationModal
      title={`${title}?`}
      scrollable={true}
      show={isOpen}
      size="lg"
      onHide={() => setIsConfirmationModalOpen(false)}
      hideOnCallback={false}
      primaryChoice={t('translation:enrollment.programs.modal.confirm')}
      primaryCallback={handleSubmit}
      primaryButtonProps={{ variant: 'primary', loading: loading }}
      className={classNames('program-modal-confirm', {
        'width-1300': true,
      })}
    >
      <h5>{t('translation:enrollment.programs.modal.confirmation-modal-heading-1')}</h5>
      <Table borderless className="simple-styled-table">
        <tbody>
          <tr>
            <td>{capitalize(t('translation:enrollment.programs.modal.name'))}</td>
            <td>{formData.name}</td>
          </tr>
          <tr>
            <td>{capitalize(t('translation:enrollment.programs.description'))}</td>
            <td>{formData.description}</td>
          </tr>

          <tr>
            <td>{`Program ${t('translation:spelling.start-date')}`}</td>
            <td>{formData.startDate}</td>
          </tr>
          <tr>
            <td>{`Program ${t('translation:spelling.end-date')}`}</td>
            <td>{formData.endDate}</td>
          </tr>
          <tr>
            <td>{t('translation:enrollment.programs.enrollment-open-date-time')}</td>
            <td>
              {formData.enrolmentOpenDate} {parseTime(formData.enrolmentOpenTime)}
            </td>
          </tr>
          <tr>
            <td>{t('translation:enrollment.programs.enrollment-close-date-time')}</td>
            <td>
              {formData.enrolmentCloseDate} {parseTime(formData.enrolmentCloseTime)}
            </td>
          </tr>
          <tr>
            <td>{capitalize(t('translation:enrollment.programs.min-enrollment-days'))}</td>
            <td>{formData.minEnrolmentDays}</td>
          </tr>
          <tr>
            <td>{capitalize(t('translation:enrollment.programs.max-enrollment-days'))}</td>
            <td>{formData.maxEnrolmentDays}</td>
          </tr>
          <tr>
            <td>{capitalize(t('translation:enrollment.programs.operating-days'))}</td>
            <td>{operatingDays}</td>
          </tr>
        </tbody>
      </Table>
      <h5>{t('translation:enrollment.programs.modal.confirmation-modal-heading-2')}</h5>
      <Table borderless className="k2-create-enrol-program-confirmation-modal-table">
        <thead>
          <tr>
            <th>{capitalize(t('translation:spelling.center'))}</th>
            <th>{capitalize(t('translation:spelling.class'))}</th>
            {<th>{capitalize(t('translation:enrollment.programs.enrollment-options'))}</th>}
            <th>{capitalize(t('translation:spelling.fee'))}</th>
            {<th>{capitalize(t('translation:enrollment.programs.casual-fee'))}</th>}
            <th>{capitalize(t('translation:enrollment.programs.application-fee-type'))}</th>
            <th>{capitalize(t('translation:enrollment.programs.application-fee'))}</th>
          </tr>
        </thead>
        <tbody>
          {formData.programCenters.map((programCenter) => {
            const feeType = (() => {
              if (programCenter.applicationFeeType === ApplicationFeeType.PerApplication) {
                return t('enrollment:application-fee-type.per-application');
              }
              if (programCenter.applicationFeeType === ApplicationFeeType.PerPlace) {
                return t('enrollment:application-fee-type.per-place');
              }

              return '';
            })();
            return (
              <tr key={programCenter.centerId}>
                <td>{programCenter.centerName}</td>
                <td>{programCenter.className}</td>
                {<td>{t(`enrollment:enrollment-options.${programCenter.enrollmentOptions}`)}</td>}
                <td>{programCenter.feeName}</td>
                {<td>{programCenter.casualFeeName}</td>}
                <td>{feeType}</td>
                <td>{programCenter.applicationFeeAmount ?? ''}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </ConfirmationModal>
  );
};
export default ConfirmProgramDetailsModal;
