import React, { useState } from 'react';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import MappingListItemInput, { MappingOptionType } from '../MappingForm/MappingListItemInput';
import {
  IntegrationMappingType,
  IntegrationType,
  useGetExternalTypesQuery,
  useGetIntegrationMappingsQuery,
  useUpsertIntegrationMappingsMutation,
} from 'generated/graphql';
import Spinner from 'shared/components/Spinner';
import { showToast } from 'shared/components/Toast';
import { toPairs } from 'lodash';
import { useTranslation } from 'react-i18next';

interface IUseGetDisplayDataInput<k extends keyof any> {
  businessId: string;
  integrationType: IntegrationType;
  integrationMappingType: IntegrationMappingType;
  sources: Record<k, { sourceHint?: string; sourceName?: string }>;
}

function useGetDisplayData<k extends keyof any>({
  businessId,
  integrationType,
  integrationMappingType,
  sources,
}: IUseGetDisplayDataInput<k>) {
  const { data: externalTypesData, loading: externalTypesLoading } = useGetExternalTypesQuery({
    variables: { input: { businessId, integrationType, integrationMappingType } },
  });

  const {
    data: integrationMappingData,
    loading: integrationMappingLoading,
    refetch: refetchIntegrationMappings,
  } = useGetIntegrationMappingsQuery({
    variables: { input: { businessId, integrationType, integrationMappingType } },
    notifyOnNetworkStatusChange: true,
  });

  const getValues = (field: k) => ({
    defaultValue: integrationMappingData?.getIntegrationMappings
      .filter((im) => im.internalId === field)
      .map((im) => ({
        value: im.externalId,
        label: externalTypesData?.getExternalTypes.find((et) => et.id === im.externalId)?.name ?? 'NO LABEL FOUND',
      }))[0],
    options: externalTypesData?.getExternalTypes.map((x) => ({
      label: x.name,
      value: x.id,
    })),
  });

  // https://stackoverflow.com/a/60932900
  const createKeys = (keyRecord: typeof sources): k[] => {
    return Object.keys(keyRecord) as any;
  };

  const keys = createKeys(sources);

  return {
    loading: externalTypesLoading || integrationMappingLoading,
    refetch: refetchIntegrationMappings,
    displayData: keys.map((f) => ({ id: f, sourceName: f, ...sources[f], ...getValues(f) })),
  };
}

interface IProps<k extends keyof any> {
  businessId: string;
  integrationType: IntegrationType;
  integrationMappingType: IntegrationMappingType;
  sources: Record<k, { sourceHint?: string; sourceName?: string }>;
}

const IntegrationMappingForm = <k extends keyof any>({
  businessId,
  integrationType,
  integrationMappingType,
  sources,
}: IProps<k>) => {
  const { t } = useTranslation(['integrations']);

  const [formData, setFormData] = useState<Partial<Record<k, MappingOptionType>>>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const {
    displayData,
    loading: displayDataLoading,
    refetch,
  } = useGetDisplayData({
    businessId,
    integrationType,
    integrationMappingType,
    sources,
  });

  const handleChange = (value: typeof formData) => {
    setFormData({ ...formData, ...value });
    setIsDirty(true);
  };

  const handleCancel = () => {
    setFormData({});
    refetch();
  };

  const [mutateUpsertIntegrationMappings, { loading }] = useUpsertIntegrationMappingsMutation();
  const handleSave = () => {
    mutateUpsertIntegrationMappings({
      variables: {
        input: {
          businessId,
          message: {
            integrationType,
            mapping: toPairs<MappingOptionType>(formData).map((fd) => {
              return { internalType: integrationMappingType, internalId: fd[0], externalId: fd[1].value };
            }),
          },
        },
      },
    })
      .then((result) => {
        setIsDirty(false);
        setFormData({});
        showToast(t('integrations:mappings.success'), 'success');
      })
      .catch((err) => showToast(`${err.toString()}`, 'error'));
  };

  const items = displayData.map((x) => (
    <MappingListItemInput
      id={x.id}
      sourceName={x.sourceName}
      sourceHint={x.sourceHint}
      options={x.options}
      defaultValue={x.defaultValue}
      onChange={(key, value) => handleChange({ [key]: value } as Partial<Record<k, MappingOptionType>>)}
    />
  ));

  if (displayDataLoading) return <Spinner />;
  return (
    <FormWrapper2
      formIsDirty={isDirty}
      toggleDirty={setIsDirty}
      className="pl-5"
      onCancel={handleCancel}
      onSave={handleSave}
      loading={loading}
    >
      {items}
    </FormWrapper2>
  );
};

export default IntegrationMappingForm;
