import React, { useState, useCallback, useMemo } from 'react';
import DataTable from 'shared/components/DataTable';
import { useTranslation } from 'react-i18next';
import { capitalize, orderBy, lowerCase, chunk } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { TableHeader, TableSearch } from 'shared/components/DataTable';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import CasualBookingStatusCell from './components/CasualBookingStatusCell';
import CasualBookingWindowCell from './components/CasualBookingWindowCell';
import BookingAbsenceCell from './components/BookingAbsenceCell';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { CASUAL_BOOKING_STATUS_OPTIONS } from './shared/constant';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  selectedClasses: IClassBookingSettings[];
  setSelectedClasses: React.Dispatch<React.SetStateAction<IClassBookingSettings[]>>;
  data: IClassBookingSettings[] | undefined;
  loading: boolean;
}

const ClassesBookingRuleTable: React.FC<IProps> = ({ selectedClasses, setSelectedClasses, data, loading }) => {
  const { t } = useTranslation();
  const centersById = useSelector((state: RootState) => state.centers.byId);
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilters, setStatusFilters] = useState<ITableFilterOption[]>([]);
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'name',
    direction: 'asc',
  });
  const isClassMatchesSearchTerm = useCallback((classBookingSettings: IClassBookingSettings, term: string) => {
    return classBookingSettings.name.toLowerCase().includes(term.toLowerCase());
  }, []);

  const onSort = (field: string, direction: 'asc' | 'desc') => setSortField({ field, direction });

  const filteredClasses = useMemo(() => {
    let filteredData = data ?? [];

    // handle class name search
    if (searchTerm) {
      filteredData =
        filteredData.filter((cls) => {
          return isClassMatchesSearchTerm(cls, searchTerm);
        }) ?? [];
    }

    // handle status filter search
    if (statusFilters.length) {
      filteredData = filteredData.filter((cls) => {
        return statusFilters.some((filter) => {
          const { value: selectedStatus } = filter;
          switch (selectedStatus) {
            case 'unavailable':
              return !cls.casualBookingSettings.available;
            case 'inactive':
              return !cls.casualBookingSettings.active && cls.casualBookingSettings.available;
            case 'active':
              return cls.casualBookingSettings.active && cls.casualBookingSettings.available;
            default:
              return true;
          }
        });
      });
    }

    return filteredData;
  }, [data, searchTerm, isClassMatchesSearchTerm, statusFilters]);

  const sortedClasses = useMemo(() => {
    return orderBy(filteredClasses, sortField.field, sortField.direction);
  }, [sortField, filteredClasses]);

  const activePageCenters = useMemo(() => {
    return chunk(sortedClasses, tableState.pageSize)[tableState.activePage - 1] ?? [];
  }, [sortedClasses, tableState]);

  const handleClearFilters = useCallback(() => {
    setSearchTerm('');
    setStatusFilters([]);
    tableFunctions.changePage(1, tableState.pageSize);
  }, [tableFunctions, tableState.pageSize]);

  const handleSearchTermChange = (v: string) => {
    setSearchTerm(v);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  const handleStatusFilterSelect = (values: ITableFilterOption[]) => {
    setStatusFilters(values);
    tableFunctions.changePage(1, tableState.pageSize);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <DataTable
      noPadding
      showSelect
      showPagination
      className="kt-attendance-settings-booking-rules-table"
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      onPageChange={tableFunctions.changePage}
      data={activePageCenters}
      dataSize={sortedClasses.length}
      selectedRows={selectedClasses}
      updateSelectedRows={setSelectedClasses}
      showLoadingOverlay={loading}
      onSort={(field, direction) => onSort(field, direction === 'ASCENDING' ? 'asc' : 'desc')}
      noDataText={t('attendance.settings.booking-rules.classes.data-table.no-data-text')}
      renderHeader={() => (
        <>
          <CenterSelectBanner pageName={lowerCase(t('attendance.settings.title'))} />
          <TableHeader className="d-flex flex-wrap align-items-center justify-content-between">
            <TableSearch
              className={isMobile ? 'mb-2' : ''}
              onChange={handleSearchTermChange}
              placeholder={`${capitalize(t('spelling.search'))} ${capitalize(t('spelling.class'))}`}
              value={searchTerm}
              enableDebounce={false}
            />
            <div className="d-flex flex-direction-row align-items-center">
              <DropdownFilter
                title={t('attendance.settings.booking-rules.classes.data-table.status-filter-title')}
                selectedFilters={statusFilters}
                options={CASUAL_BOOKING_STATUS_OPTIONS}
                onFilterSelect={handleStatusFilterSelect}
              />
              <IconButtonCircle
                icon={faTimes}
                onClick={handleClearFilters}
                tooltipDirection="bottom"
                tooltipText={t('attendance.settings.booking-rules.classes.data-table.clear-filter-icon-tooltip')}
                className="ml-4"
              />
            </div>
          </TableHeader>
        </>
      )}
      columns={[
        {
          text: capitalize(t('attendance.settings.booking-rules.classes.data-table.column-labels.class')),
          dataField: 'name',
          sort: true,
          classes: 'md-column',
        },
        {
          text: capitalize(t('attendance.settings.booking-rules.classes.data-table.column-labels.center')),
          dataField: 'centerId',
          formatter: (cell: string) => centersById[cell]?.name ?? '',
          classes: 'md-column',
        },
        {
          text: t('attendance.settings.booking-rules.classes.data-table.column-labels.casual-bookings'),
          dataField: 'casualBookingSettings.active',
          sort: true,
          formatter: (cell: boolean, row: IClassBookingSettings) => (
            <CasualBookingStatusCell
              status={{
                active: row.casualBookingSettings.active,
                available: row.casualBookingSettings.available,
                availableReason: row.casualBookingSettings.availableReason,
              }}
            />
          ),
          classes: 'sm-column',
        },
        {
          text: t('attendance.settings.booking-rules.classes.data-table.column-labels.booking-window'),
          dataField: 'casualBookingSettings.bookingPolicy',
          sort: true,
          classes: 'sm-column',
          formatter: (cell: boolean, row: IClassBookingSettings) => (
            <CasualBookingWindowCell classBookingSettings={row} />
          ),
        },
        {
          text: t('attendance.settings.booking-rules.classes.data-table.column-labels.casual-booking-absence'),
          dataField: 'casualBookingSettings.cancellationPolicy.casualBooking',
          sort: true,
          formatter: (cell: IBookingWindowInput | undefined) => <BookingAbsenceCell bookingWindow={cell} />,
          classes: 'sm-column',
        },
        {
          text: t('attendance.settings.booking-rules.classes.data-table.column-labels.permanent-booking-absence'),
          dataField: 'casualBookingSettings.cancellationPolicy.permanentBooking',
          sort: true,
          formatter: (cell: IBookingWindowInput | undefined) => <BookingAbsenceCell bookingWindow={cell} />,
          classes: 'sm-column',
        },
      ]}
    />
  );
};

export default ClassesBookingRuleTable;
