import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'shared/components/Layout';
import Checkbox from 'shared/components/Checkbox';
import { cloneDeep } from 'lodash';

interface IProps {
  formData: IKindyProgramChildFundingSchedule | undefined;
  selectedDate: string | undefined;
  handleChange: (setting: IKindyProgramChildFundingSchedule) => void;
}

const StartStrongFundingSettings: React.FC<IProps> = ({ formData, selectedDate, handleChange }) => {
  const { t } = useTranslation(['subsidies', 'translation']);
  const [settingFormData, setSettingFormData] = useState<IKindyProgramChildFundingSchedule>(
    formData ?? {
      startDate: selectedDate ?? '',
      basePercentage: undefined,
      subsidyTypes: [],
    }
  );

  const handleSubsidyTypeChange = (checked: boolean, value: SubsidyType) => {
    const subsidyTypes = cloneDeep(formData?.subsidyTypes ?? []) as SubsidyType[];

    if (checked) {
      // Exclude from funding
      var index = subsidyTypes.indexOf(value);
      if (index !== -1) {
        subsidyTypes.splice(index, 1);
      }
    } else {
      subsidyTypes.push(value);
    }

    setSettingFormData((state) => ({
      ...state,
      subsidyTypes: subsidyTypes,
      basePercentage: undefined,
    }));
  };

  useEffect(() => {
    const basePercentage = settingFormData.basePercentage ? settingFormData.basePercentage / 100 : undefined;

    const changed = {
      startDate: settingFormData.startDate,
      subsidyTypes: settingFormData.subsidyTypes,
      basePercentage: basePercentage,
    } as IKindyProgramChildFundingSchedule;

    handleChange(changed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingFormData]);

  useEffect(() => {
    // This is a hack to force rerender when we switch to a different date
    setSettingFormData(
      formData ?? {
        startDate: selectedDate ?? '',
        basePercentage: undefined,
        subsidyTypes: [],
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <div>
      <Row className="mb-2">
        <Col>
          <div className="mb-2">{t('subsidies:automated-funding.child.start-strong.exclude-funding-info')}</div>
        </Col>
      </Row>
      <Row align="start">
        <Col lg={6}>
          <Checkbox
            value={!settingFormData.subsidyTypes?.includes('FeeRelief')}
            label={t('subsidies:automated-funding.fee-relief')}
            onChange={(val: boolean) => {
              handleSubsidyTypeChange(val, 'FeeRelief');
            }}
            disabled={selectedDate === undefined}
          />
        </Col>
      </Row>
      {/* TODO: History */}
    </div>
  );
};
export default StartStrongFundingSettings;
