import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { Col, Row } from 'shared/components/Layout';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';

import { sortBy } from 'lodash';
import _ from 'lodash';
import { useGetProvidersByBusinessId } from 'gql/business/queries';
import './ccssPersonnelTab.scss';
import { useGetProviderPersonnel } from 'gql/ccssPersonnel/queries';
import { PersonnelPositionLabels } from 'shared/constants/enums/ccssPersonnelEnums';
import useDatatableState from 'shared/hooks/useDatatableState2';
import Select from 'shared/components/Select';

interface IProps {
  business: IBusiness;
}

interface CcsPersonnelFilter {
  providerId: string;
  searchText: string;
  centers: ITableFilterOption[];
}

const CcssProviderPersonnelTab: React.FC<IProps> = ({ business, ...props }) => {
  const { loading: getProvidersLoading } = useGetProvidersByBusinessId({
    variables: {
      businessId: business.id,
    },
  });
  const [sort, setSortOptions] = useState<IElasticsearchSortFilter[]>([{ field: 'firstName', direction: 'ASCENDING' }]);
  const providers = useSelector((state: RootState) => state.provider.providers) ?? [];
  const [activeFilters, setActiveFilters] = useState<CcsPersonnelFilter>({
    centers: [],
    providerId: '',
    searchText: '',
  });
  const [tableState, tableFunctions] = useDatatableState();

  const { data, loading: getPersonnelLoading } = useGetProviderPersonnel({
    variables: {
      businessId: business.id,
      providerId: activeFilters.providerId,
      pageNumber: tableState.activePage,
      pageSize: tableState.pageSize,
      name: activeFilters.searchText || '',
      sortDtos: sort,
    },
    skip: business === null || activeFilters.providerId === '',
  });

  const personnel = data?.getPersonnelByProviderId.data || [];

  useEffect(() => {
    if (providers.length > 0) {
      setActiveFilters((filters) => ({
        ...filters,
        providerId: providers[0]?.id,
      }));
    }
  }, [providers]);

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field, direction }]);
  };

  return (
    <Row align="start">
      <Col>
        <DataTable
          showLoadingOverlay={getPersonnelLoading || getProvidersLoading}
          className="ccss-personnel-table"
          data={personnel}
          pageSize={tableState.pageSize}
          activePage={tableState.activePage}
          dataSize={data?.getPersonnelByProviderId.totalRecords || 0}
          showSelect={false}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          noPadding
          onSort={handleSort}
          renderHeader={(paginationProps: any) => (
            <TableHeader>
              <div className="d-flex flex-grow-1 my-2">
                <SizePerPage paginationProps={paginationProps} />
                <TableSearch
                  onChange={(value) => {
                    setActiveFilters({ ...activeFilters, searchText: value });
                  }}
                  placeholder="Search"
                />
              </div>

              <Select
                className="mb-0 provider-select"
                placeholder="Provider"
                options={providers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
                value={activeFilters.providerId}
                onChange={(value) => {
                  setActiveFilters({ ...activeFilters, providerId: value.value });
                }}
              />
            </TableHeader>
          )}
          columns={[
            {
              text: 'Name',
              dataField: 'firstName',
              sort: true,
              formatter: (cell: string, row: ICcssPersonnel) => `${row.firstName} ${row.lastName}`,
            },
            {
              text: 'Person ID',
              dataField: 'personId',
            },
            {
              text: 'Role',
              dataField: 'role',
              formatter: () => 'Operations',
            },
            {
              text: 'Position',
              dataField: '',
              formatter: (_cell: string, row: ICcssPersonnel) => {
                const lastProviderRole = _(sortBy(row.providerRoles, (pr) => pr.startDate)).last()!;
                const positionText = PersonnelPositionLabels[lastProviderRole?.position];

                return positionText || '';
              },
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default CcssProviderPersonnelTab;
