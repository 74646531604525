import { BusRostersActionTypes, GET_BUS_ROSTER_SUCCESS } from './types';

interface IBusRostersStateShape {
  byId: Record<string, IBusRoster>;
}

const initialState: IBusRostersStateShape = {
  byId: {},
};

export const busRostersReducers = (
  state: IBusRostersStateShape = initialState,
  action: BusRostersActionTypes
): IBusRostersStateShape => {
  const byId: Record<string, IBusRoster> = { ...state.byId };

  switch (action.type) {
    case GET_BUS_ROSTER_SUCCESS:
      // case UPDATE_CENTER:
      byId[action.busRoster.id] = {
        ...byId[action.busRoster.id],
        ...action.busRoster,
      };

      return {
        ...state,
        byId,
      };
    default:
      return state;
  }
};
