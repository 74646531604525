export const programChildFields = `
  id
  startDate
  endDate
  isActive
  primaryAccountId
  associatedAccountId
  lastFundPaidDate
  childReceivesAccs
  program {
    id
    name
    startDate
    endDate
    isActive
    centerId
    subsidyScheme {
      subsidySchemeId
      name
    }
  }
  childId
  child {
    firstname
    lastname
    nickname
    fullName
    id
    avatar {
      url
    }
    multipleBirths
  }
  concessionCard {
    id
    accountId
    contactId
    contactFirstName
    contactLastName
    type
    cardNumber
    effectiveDate
    expiryDate
    accountChildren
    documentUri
    fileName
  }
  delayedExit {
    id
    accountChildId
    notes
    documentUri
    fileName
  }
  familyTaxBenefit {
    id
    accountId
    part
    documentUri
    fileName
  }
  kindyForAllChild {
    childId
    cancellationDate
    cancellationReason
    visaType
    formalKinshipStartDate
    formalProtectionCareStartDate
    multiBirthStartDate
    discretionaryStartDate
    disability
    nonEnglishSpeakingBackground
  }
  startStrongChild {
    childId
    nonEnglishSpeakingBackground    
    hasSchoolExemptionCertificate
  }
  startStrongDeclarationForm {
    id
    childId
    documentUri
    fileName
  }
  startStrongSchoolExemption {
    id
    childId
    documentUri
    fileName
  }
  startStrongConsent
  excludeStartStrongFunding
`;

export const newProgramChildFields = `   
  childId
  child {
    firstname
    lastname
    nickname
    fullName
    id
    avatar {
      url
    }
    multipleBirths
  }
  concessionCard {
    id
    accountId
    contactId
    contactFirstName
    contactLastName
    type
    cardNumber
    effectiveDate
    expiryDate
    accountChildren
    documentUri
    fileName
  }
  delayedExit {
    id
    accountChildId
    notes
    documentUri
    fileName
  }
  familyTaxBenefit {
    id
    accountId
    part
    documentUri
    fileName
  }
  kindyForAllChild {
    childId
    cancellationDate
    cancellationReason
    visaType
    formalKinshipStartDate
    formalProtectionCareStartDate
    multiBirthStartDate
    discretionaryStartDate
    disability
    nonEnglishSpeakingBackground
  }
  startStrongChild {
    childId
    nonEnglishSpeakingBackground
    hasSchoolExemptionCertificate
  }
  startStrongConsent
  excludeStartStrongFunding
`;

const basicChildrenFields = `
  id
  startDate
  endDate
  childId
  accountName
  child {
    firstname
    lastname
    nickname
    fullName
    id
    avatar {
      url
    }
    dob
  }
`;

export const pagedProgramChildrenFields = `
  totalRecords
  pageNumber
  data {
    ${basicChildrenFields}
  }
`;
