import React, { useState, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import Select, { components, MenuListComponentProps } from 'react-select';
import useUniqueId from 'shared/hooks/useUniqueId';
import Tooltip from 'shared/components/Tooltip';
import MenuItem from './MenuItem';
import colors from '_colors.module.scss';
import 'shared/components/Select/_select.scss';
import RadioButton from 'shared/components/RadioButton';

interface IProps {
  dropdownId?: string;
  title: string;
  selectedFilters: ITableFilterOption[];
  options: IRadioGroupedFilterOption[];
  placeholder?: string;
  onFilterSelect: (values: ITableFilterOption[]) => void;
  className?: string;
  alwaysShowTitle?: boolean;
  showToolTip?: boolean;
}

const GroupedDropdownFilter: React.FC<IProps> = ({
  dropdownId,
  title,
  selectedFilters = [],
  options,
  onFilterSelect,
  placeholder = 'Filter...',
  className,
  alwaysShowTitle = false,
  showToolTip = true,
}) => {
  const _dropdownId = useUniqueId(dropdownId);
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = useCallback((show: boolean) => {
    setShowDropdown(show);
  }, []);

  const toggleText = useMemo(() => {
    if (alwaysShowTitle) {
      return {
        short: title || '',
        full: '',
      };
    }
    if (selectedFilters.length > 1) {
      /**
       * if there is more than 1 filter selected, show the text of the first selected filter and then '(+ N)
       * ex: "Active (+2)"
       */
      const additionalFilterCount = selectedFilters.length - 1;

      return {
        short: `(+${additionalFilterCount}) ${selectedFilters[0].label}`,
        full: selectedFilters.map((filter) => filter.label).join(', '),
      };
    } else if (selectedFilters.length === 1) {
      // if one filter is selected show that option's label
      return {
        short: selectedFilters[0].label,
        full: '',
      };
    } else {
      // if no filters are selected, use the provided title
      return {
        short: title || '',
        full: '',
      };
    }
  }, [title, selectedFilters, alwaysShowTitle]);

  const hasValue = selectedFilters.length >= 1;

  const Control = useCallback(() => null, []);

  const GroupHeading = useCallback((props) => {
    const heading = props.data as IRadioGroupedFilterOption;
    const { label, isChecked, onCheck, value } = heading;

    return (
      <div className="d-flex align-items-center">
        <input
          type="radio"
          id={value}
          name={value}
          value={value}
          checked={isChecked}
          onChange={(e) => onCheck(e.target.value)}
          className="mr-2 d-block"
        />
        <label htmlFor={value} className="d-block m-0">
          {label}
        </label>
      </div>
    );
  }, []);

  const MenuList = (props: MenuListComponentProps<ITableFilterOption, boolean>) => (
    <components.MenuList {...props} maxHeight={800} className="px-4" />
  );

  return (
    <Dropdown id={_dropdownId} show={showDropdown} onToggle={toggleDropdown} className={className}>
      <Dropdown.Toggle id={_dropdownId} className="k2-datatable-dropdown-toggle" as="button">
        <Tooltip text={toggleText.full} direction="bottom" showTooltip={toggleText.full.length > 0 && showToolTip}>
          <div className="d-flex flex-row flex-grow-1 align-items-center">
            <span className={`flex-grow-1 k2-datatable-dropdown-selected-text ${hasValue ? 'with-selected' : ''}`}>
              {toggleText.short}
            </span>
            <FontAwesomeIcon icon={faChevronDown} color={colors.iconColor} className="ml-6" />
          </div>
        </Tooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight className="k2-datatable-dropdown-menu mt-6" style={{ minWidth: 250 }}>
        <Select
          isMulti
          autoFocus
          menuIsOpen
          className="react-select-container"
          classNamePrefix="react-select"
          value={selectedFilters}
          components={{
            DropdownIndicator: null,
            Control,
            Option: MenuItem,
            GroupHeading,
            MenuList,
          }}
          backspaceRemovesValue={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          tabSelectsValue={false}
          options={options}
          placeholder={placeholder}
          // @ts-ignore
          onChange={onFilterSelect}
        />
        {/* options without sub options e.g. Casual CareType */}
        {options
          .filter((o) => o.options.length <= 0)
          .map((option) => {
            return (
              <div className="d-flex justify-content-start align-items-center px-4 mb-4">
                <input
                  type="radio"
                  id={option.value}
                  name={option.label}
                  value={option.value}
                  checked={option.isChecked}
                  onChange={(e) => option.onCheck(option.value)}
                  className="mr-2 d-block"
                />
                <label htmlFor={option.value} className="d-block m-0">
                  {option.label}
                </label>
              </div>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default GroupedDropdownFilter;
