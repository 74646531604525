import { ITimelogTableFilters } from './reducers';
import * as types from './types';

export const createTrainingTimeEvent = (timeEvent: ITrainingHolidayEvent): types.TimelogActionTypes => ({
  type: types.CREATE_TRAINING_TIME_EVENT,
  timeEvent,
});

export const updateTrainingTimeEvent = (timeEvent: ITrainingHolidayEvent): types.TimelogActionTypes => ({
  type: types.UPDATE_TRAINING_TIME_EVENT,
  timeEvent,
});

export const deleteTrainingTimeEvent = (timeEventId: string): types.TimelogActionTypes => ({
  type: types.DELETE_TRAINING_TIME_EVENT,
  timeEventId,
});

export const getTrainingTimeEvents = (timeEvents: ITrainingHolidayEvent[]): types.TimelogActionTypes => ({
  type: types.GET_TRAINING_TIME_EVENTS,
  timeEvents,
});

export const updateTimelogTableFilter = (filters: Partial<ITimelogTableFilters>): types.TimelogActionTypes => ({
  type: types.UPDATE_TIME_LOG_FILTER,
  filters,
});

export const setCurrentEmployeeTimecards = (timecards: IAttendance[]): types.TimelogActionTypes => ({
  type: types.SET_CURRENT_EMPLOYEE_TIMECARDS,
  timecards,
});

export const removeTrainingTimeEventFromEmployeeTimecard = (
  employeeId: string,
  trainingTimeEventId: string
): types.TimelogActionTypes => ({
  type: types.REMOVE_TRAINING_TIME_FROM_EMPLOYEE_TIMECARD,
  employeeId,
  trainingTimeEventId,
});
