import React, { useState, useCallback, useEffect } from 'react';
import { EmailInput, CodeInputs } from 'shared/components/TextInput';
import Button from 'shared/components/Buttons';
import { Redirect, useHistory } from 'react-router-dom';
import { steps } from './Join';
import { isEmailSyntaxValid, isEmailValid } from 'shared/util/email';
import errorMessage from 'shared/constants/errorMessages';
import { useLazyValidateInvite } from './graphql/queries';
import debounce from 'lodash/debounce';
import { Fade } from 'react-bootstrap';

interface IProps {
  newUser: INewUser;
  updateNewUser: React.Dispatch<React.SetStateAction<INewUser>>;
}

const VerifyCodeAndEmail: React.FC<IProps> = ({ newUser, updateNewUser }) => {
  const history = useHistory();
  const codeLength = 6;
  const [redirectForward, setRedirectForward] = useState(false);

  const [validateInvite, { data, called, loading }] = useLazyValidateInvite();
  const formDisabled =
    !isEmailValid(newUser.email) ||
    newUser.code?.length !== 6 ||
    !newUser.codeValid ||
    loading ||
    newUser.existingActiveStaff ||
    newUser.existingRequest;

  useEffect(() => {
    const result = data?.checkInviteCodeEmailCombo;
    if (result && !loading) {
      updateNewUser((prev) => ({
        ...prev,
        firstName: result.personFirstName,
        lastName: result.personLastName,
        codeValid: result.inviteValid,
        emailValid: result.emailValid,
        personId: result.personId,
        existingActiveStaff: result.existingActiveStaff,
        existingRequest: result.existingRequest,
      }));
    }
  }, [data, updateNewUser, called, loading]);

  const debouncedEmailCodeLookup = useCallback(
    debounce((user: INewUser) => {
      if (user.code) {
        validateInvite({
          variables: {
            input: {
              email: user.email || '',
              inviteCode: user.code,
            },
          },
        });
      }
    }, 250),
    [validateInvite]
  );

  const handleTextChange = useCallback(
    (value: string, name: string) => {
      updateNewUser((prev) => ({ ...prev, [name]: value }));

      debouncedEmailCodeLookup({ ...newUser, [name]: value });
    },
    [debouncedEmailCodeLookup, updateNewUser, newUser]
  );

  const handleSave = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setRedirectForward(true);
  }, []);

  if (redirectForward) return <Redirect to={`/join/?step=${steps.inputName}`} />;
  return (
    <form onSubmit={handleSave} className="login-container p-8 my-auto mx-auto">
      <p className="h1 font-weight-light">Join your team on</p>
      <p className="h1 font-weight-bold mb-6">Kangarootime</p>
      <p className="mb-8">Enter your details below.</p>
      <EmailInput
        autoFocus={Boolean(newUser.email)}
        required
        label="Email Address"
        value={newUser.email || ''}
        onChange={(email: string) => handleTextChange(email, 'email')}
        isInvalid={!isEmailValid(newUser.email) || newUser.existingActiveStaff || newUser.existingRequest}
        errorText={
          newUser.existingActiveStaff
            ? errorMessage.joinEmailInUse
            : newUser.existingRequest
            ? errorMessage.existingRequest
            : !isEmailSyntaxValid(newUser.email)
            ? errorMessage.invalidEmailSyntax
            : errorMessage.invalidEmailDomain
        }
      />
      <CodeInputs
        required
        value={newUser.code || ''}
        label="Code"
        fields={codeLength}
        onChange={(code: string) => handleTextChange(code, 'code')}
        isValid={newUser.codeValid}
        isInvalid={!loading && (!newUser.codeValid || newUser.code?.length !== codeLength)}
        errorText={newUser.code?.length !== codeLength ? errorMessage.codeRequirements : errorMessage.invalidCode}
      />
      <Button
        disabled={formDisabled || (newUser.codeValid && !newUser.emailValid)}
        className="btn-block my-8"
        type="submit"
      >
        Join Kangarootime
      </Button>
      {newUser.codeValid && !newUser.emailValid && (
        <Fade in={newUser.codeValid && !newUser.emailValid}>
          <Button disabled={formDisabled} className="btn-block" type="submit">
            Request to Join
          </Button>
        </Fade>
      )}
    </form>
  );
};

export default VerifyCodeAndEmail;
