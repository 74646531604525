import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import Switch from 'shared/components/Switch';
import { capitalize } from 'shared/util/string';
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers';
import { isRegion } from 'shared/util/region';
import Currency from 'shared/components/Currency';
import { SurchargesBillingSetting } from './SurchargesBillingSettings';

interface IProps {
  isLoading: boolean;
  data: SurchargesBillingSetting[];
  onCenterPassThroughAchToggle: (centerId: string, passThroughAch: boolean) => void;
  onCenterPassThroughCreditToggle: (centerId: string, passThroughCredit: boolean) => void;
  onCenterPassThroughDishonorToggle: (centerId: string, passThroughDishonor: boolean) => void;
  onSortColumn: (field: string, direction: 'asc' | 'desc') => void;
  noPadding: boolean;
}

const CentersWithSurchargeTable: React.FC<IProps> = ({
  isLoading,
  data,
  onCenterPassThroughAchToggle,
  onCenterPassThroughCreditToggle,
  onCenterPassThroughDishonorToggle,
  onSortColumn,
  noPadding = false,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);

  const canEditSurcharges = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Surcharges,
    level: RoleLevelType.Edit,
  });

  // update this when there's already a permission for pass through
  const canEditPassThroughDishonor = canEditSurcharges;

  const columns = useMemo((): any[] => {
    const dataTableColumns = [
      {
        text: `${capitalize(t('spelling.center'))} Name`,
        dataField: 'center.name',
        sort: true,
        formatter: (cell: string, row: SurchargesBillingSetting) => (
          <AvatarDataTableCell
            initials={row.center.name && row.center.name[0].toUpperCase()}
            avatar={row.center.avatar && row.center.avatar.url}
            primaryText={row.center.name}
          />
        ),
      },
      { text: 'City', dataField: 'center.address.city', sort: true },
      {
        text: 'State',
        dataField: 'center.address.state',
        sort: true,
        formatter: (cell: string, row: SurchargesBillingSetting) => {
          if (row.center.address?.state && row.center.address?.country) {
            return (
              STATE_SELECT_OPTIONS[row.center.address?.country].find(
                (option) => option.value === row.center.address?.state ?? ''
              )?.label ?? ''
            );
          }

          return '';
        },
      },
    ];

    const achSurchargeColumn: any = {
      text: `Charge Parents ${t('billing.spelling.ach')} Fees?`,
      dataField: 'passThroughAchSurcharge',
      formatter: (cell: string, row: SurchargesBillingSetting) => (
        <Switch
          checkedText="Yes"
          uncheckedText="No"
          value={row.passThroughAchSurcharge}
          onChange={(checked) => onCenterPassThroughAchToggle(row.centerId, checked)}
          disabled={!canEditSurcharges}
        />
      ),
    };

    const creditSurchargeColumn: any = {
      text: 'Charge Parents CC Fees?',
      dataField: 'passThroughCreditSurcharge',
      formatter: (cell: string, row: SurchargesBillingSetting) => (
        <Switch
          checkedText="Yes"
          uncheckedText="No"
          value={row.passThroughCreditSurcharge}
          onChange={(checked) => onCenterPassThroughCreditToggle(row.centerId, checked)}
          disabled={!canEditSurcharges}
        />
      ),
    };

    const passThroughDishonorSwitch: any = {
      text: `Pass Through ${capitalize(t('spelling.dishonor'))}?`,
      dataField: 'passThroughDishonor',
      formatter: (cell: string, row: SurchargesBillingSetting) => (
        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
          <Switch
            checkedText="Yes"
            uncheckedText="No"
            value={row.passThroughDishonor}
            disabled={!canEditPassThroughDishonor}
            onChange={(checked) => onCenterPassThroughDishonorToggle(row.centerId, checked)}
          />
          {row.passThroughDishonor && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '1em' }}>
              <Currency amount={row.dishonorFeeAmount} />
            </div>
          )}
        </div>
      ),
    };

    if (isRegion('AU') || user?.isInternal) {
      dataTableColumns.push(achSurchargeColumn);
    }
    dataTableColumns.push(creditSurchargeColumn);
    dataTableColumns.push(passThroughDishonorSwitch);

    return dataTableColumns;
  }, [
    t,
    user?.isInternal,
    canEditSurcharges,
    onCenterPassThroughAchToggle,
    onCenterPassThroughCreditToggle,
    canEditPassThroughDishonor,
    onCenterPassThroughDishonorToggle,
  ]);

  return (
    <DataTable
      data={data}
      dataSize={data.length ?? 0}
      showSelect={false}
      showPagination={false}
      showLoadingOverlay={isLoading}
      noPadding={noPadding}
      onSort={(field, direction) => onSortColumn(field, direction === 'ASCENDING' ? 'asc' : 'desc')}
      columns={columns}
    />
  );
};

export default CentersWithSurchargeTable;
