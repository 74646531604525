export const batchFields: string = `
  id
  userFriendlyId
  createdAt
  createdBy
  createdByPerson {
    id
    firstname
    nickname
    lastname
  }
  amount
  batchPaymentPaymentIds
  center {
    id
    name
  }
  documentNumber
`;

export const updateCenterSettingsFields: string = `
  enableAutomaticBatching
  id
  entityId
  name
`;
