export interface IDayOption {
  value: WeekDay;
  label: string;
  dayNumber: number;
}
const weekDayOptions: IDayOption[] = [
  { label: 'M', value: 'MONDAY', dayNumber: 1 },
  { label: 'Tu', value: 'TUESDAY', dayNumber: 2 },
  { label: 'W', value: 'WEDNESDAY', dayNumber: 3 },
  { label: 'Th', value: 'THURSDAY', dayNumber: 4 },
  { label: 'F', value: 'FRIDAY', dayNumber: 5 },
];
export default weekDayOptions;
