import React, { useCallback, useEffect, useState } from 'react';
import PubNub from 'pubnub';
import BulletinBoard from 'pages/Engagement/subroutes/BulletinBoards/BulletinBoardMessagingContainer/BulletinBoard/BulletinBoard';
import { useGetBulletinBoardChannelsQuery, Channel, ChannelType } from 'generated/graphql';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import config from 'config';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import { debounce, isNil } from 'lodash';
import { EnumTypeDefinitionNode } from 'graphql';
import { BulletinBoardFilters } from '../BulletinBoards';

interface IProps {
  hasWritePermission: boolean;
  hasDeletePermission: boolean;
  authToken: string;
  authChannels: string[] | undefined;
  publishKey: string;
  subscribeKey: string;
  filterBulletinsBy?: BulletinBoardFilters;
}

const BulletinBoardMessagingContainer: React.FC<IProps> = ({
  hasWritePermission,
  hasDeletePermission,
  authToken,
  authChannels,
  publishKey,
  subscribeKey,
  filterBulletinsBy,
}) => {
  const { t } = useTranslation(['comms']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId);

  const [selectedCenters, setSelectedCenters] = useState<ITableFilterOption[]>([]);
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [pollInterval, setPollInterval] = useState(1000 * 30); // initially poll every 30 seconds

  const {
    data: getBulletinBoardChannelsData,
    loading: loadingBulletinBoardChannels,
    error: getBulletinBoardChannelsError,
    refetch: refetchBulletinBoardChannels,
  } = useGetBulletinBoardChannelsQuery({
    skip: isNil(businessId),
    pollInterval: pollInterval,
    variables: {
      businessId: businessId ?? '',
      pageNumber: tableState.activePage,
      pageSize: tableState.pageSize,
      searchTerm: searchTerm,
      centerId: selectedCenters.length > 0 ? selectedCenters.map((c) => c.value) : authChannels,
    },
  });

  const bulletinBoardChannels = React.useMemo(() => {
    return getBulletinBoardChannelsData?.getBulletinBoardChannels?.data ?? [];
  }, [getBulletinBoardChannelsData]);

  const totalBulletinBoardChannels = React.useMemo(() => {
    return getBulletinBoardChannelsData?.getBulletinBoardChannels?.totalRecords ?? 0;
  }, [getBulletinBoardChannelsData]);

  const onSearchTermChange = useCallback(
    debounce((newSearchTerm: string) => {
      setSearchTerm(newSearchTerm);
      if (tableState.activePage !== 1) tableFunctions.changePage(1, tableState.pageSize);
    }, 500),
    []
  );

  const onCentersSelect = (centerFilter: ITableFilterOption[]) => {
    if (tableState.activePage !== 1) tableFunctions.changePage(1, tableState.pageSize);
    setSelectedCenters(centerFilter);
  };

  const handleRefetch = useCallback(() => {
    refetchBulletinBoardChannels({
      businessId: businessId ?? '',
      pageNumber: 1,
      pageSize: tableState.pageSize,
      searchTerm: searchTerm,
      centerId: selectedCenters.length > 0 ? selectedCenters.map((c) => c.value) : authChannels,
    });
  }, [tableFunctions, refetchBulletinBoardChannels, businessId]);

  const onFocusFunction = () => {
    setPollInterval(1000 * 30); // poll every 30 seconds when looking at page
  };

  const onBlurFunction = () => {
    setPollInterval(1000 * 60 * 10); // poll every 10 minutes when not looking at page
  };

  useEffect(() => {
    onFocusFunction();
    window.addEventListener('focus', onFocusFunction);
    window.addEventListener('blur', onBlurFunction);

    return () => {
      onBlurFunction();
      window.removeEventListener('focus', onFocusFunction);
      window.removeEventListener('blur', onBlurFunction);
    };
  }, []);

  useEffect(() => {
    if (getBulletinBoardChannelsError)
      showToast(t('comms:getChannels.error', { channelType: 'announcement', plural: 's' }), 'error');
  }, [getBulletinBoardChannelsData, getBulletinBoardChannelsError]);

  const pubnub = new PubNub({
    publishKey: publishKey ?? config.pubNubConfig.publishKey,
    subscribeKey: subscribeKey ?? config.pubNubConfig.subscribeKey,
    uuid: user?.id ?? businessId ?? 'DefaultUser',
    restore: true,
    heartbeatInterval: 500,
    logVerbosity:
      businessId === 'aca26847-7862-4f3f-ad1c-f393ed8056d3' || businessId === 'ecee07be-0be7-48a3-a570-4930bec79cd5',
  });
  pubnub.setToken(authToken);
  pubnub.unsubscribeAll();

  return (
    <BulletinBoard
      pubnub={pubnub}
      authCenters={authChannels ?? []}
      initialChannels={bulletinBoardChannels ?? []}
      loadingChannels={loadingBulletinBoardChannels}
      channelsType={ChannelType.BulletinBoard}
      hasWritePermission={hasWritePermission}
      hasDeletePermission={hasDeletePermission}
      dataSize={totalBulletinBoardChannels ?? 0}
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      selectedCenters={selectedCenters}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      onSearchTermChange={onSearchTermChange}
      setSelectedCenters={onCentersSelect}
      refetchChannels={handleRefetch}
      filterBulletinsBy={filterBulletinsBy}
    />
  );
};

export default BulletinBoardMessagingContainer;
