import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uniq } from 'lodash';
import NewCenterModalForm from './components/NewCenterModalForm';
import Container from 'react-bootstrap/Container';
import CenterProfilesTable from './components/CenterProfilesTable';
import PageWrapper from 'shared/components/PageWrapper';
import { CreateButton, CirclePlusButton } from 'shared/components/Buttons';
import DeactivateCenterModal from 'pages/Centers/components/DeactivateCenterModal';
import ReactivateCenterModal from 'pages/Centers/components/ReactivateCenterModal';
import {
  useDeactivateCenter,
  useReactivateCenter,
  useDeleteCenter,
  useUpdateCenterTags,
} from 'pages/Centers/subroutes/Profile/graphql/mutations';
import Alert from 'shared/components/Alert';
import { showToast } from 'shared/components/Toast';
import useDatatableState from 'shared/hooks/useDatatableState';
import { RootState } from 'store/reducers';
import { useSearchCenters } from 'gql/center/queries';
import { SEARCH_MY_CENTERS } from './graphql/fields';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import AddTagsToCenterModal from './components/AddTagsToCenterModal';
import { getCentersSuccess } from 'pages/Centers/duck/actions';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { RoleLevelType, AreaType, PermissionType } from 'shared/constants/enums/permissionsEnums';

const Profiles = ({ ...props }) => {
  const dispatch = useDispatch();
  const autoSelectedBusinessId = props.location.state?.businessId ?? '';
  const autoOpenSideModal = props.location.state && props.location.state.autoOpenSideModal;
  const [showNewCenterModal, setShowNewCenterForm] = useState(autoOpenSideModal || false);
  const [showDeactivateCenterModal, setShowDeactivateCenterModal] = useState(false);
  const [showReactivateCenterModal, setShowReactivateCenterModal] = useState(false);
  const [showDeleteCenterModal, setShowDeleteCenterModal] = useState(false);
  const [showAddTagsModal, setShowAddTagsModal] = useState(false);
  const [selectedCenters, setSelectedCenters] = useState<ICenter[] | null>(null);
  const [deactivateCenterMutationFn, { loading: deactivateCenterLoading, error: deactivateCenterError }] =
    useDeactivateCenter();
  const [reactivateCenterMutationFn, { loading: reactivateCenterLoading, error: reactivateCenterError }] =
    useReactivateCenter();
  const [deleteCenterMutationFn, { loading: deleteCenterLoading, error: deleteCenterError }] = useDeleteCenter();
  const [updateCenterTagsFn, { loading: updateCenterTagsLoading }] = useUpdateCenterTags();

  const mutationError = deactivateCenterError || reactivateCenterError || deleteCenterError || null;

  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('center', initalTableSort);
  const { loading, error, data, refetch, networkStatus } = useSearchCenters(
    {
      variables: {
        input: tableFunctions.getElasticQuery(),
      },
      onCompleted: (data) => {
        dispatch(getCentersSuccess(data.searchCenters.data ?? []));
      },
    },
    SEARCH_MY_CENTERS
  );

  const toggleNewCenterForm = useCallback(() => setShowNewCenterForm(!showNewCenterModal), [showNewCenterModal]);
  const centers = useSelector((state: RootState) => state.centers.all);
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const navigateToCenterProfile = useCallback((id) => props.history.push(`/centers/profiles/${id}`), [props.history]);

  const showModal = useCallback((centers, action) => {
    if (action.toLowerCase() === 'deactivate') {
      setShowDeactivateCenterModal(true);
    } else if (action.toLowerCase() === 'reactivate') {
      setShowReactivateCenterModal(true);
    } else if (action.toLowerCase() === 'delete') {
      setShowDeleteCenterModal(true);
    } else if (action.toLowerCase() === 'addtags') {
      setShowAddTagsModal(true);
    } else {
      // don't do anything if an invalid action is provided
      return;
    }

    setSelectedCenters(centers);
  }, []);

  const dismissModal = useCallback(() => {
    setShowDeactivateCenterModal(false);
    setShowReactivateCenterModal(false);
    setShowDeleteCenterModal(false);
    setShowAddTagsModal(false);
    setSelectedCenters(null);
  }, []);

  // mutation that runs after successfully deactivating / reactivating a center. will also dismiss any open modals
  const onMutationSuccess = useCallback(
    (action) => {
      if (selectedCenters) {
        showToast(
          `Successfully ${action} ${
            selectedCenters.length === 1
              ? selectedCenters[0].name
              : selectedCenters.length + ` ${fieldLabels.center.toLowerCase()}`
          }.`,
          'success'
        );
      }

      dismissModal();
    },
    [dismissModal, selectedCenters]
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const updatedSelectedRowsBasedOnNewData = tableState.selectedRows
      .map((r: ICenter) => data?.searchCenters.data.find((c: ICenter) => c.id === r.id))
      .filter((c: ICenter) => c);
    tableFunctions.updateSelectedRows(updatedSelectedRowsBasedOnNewData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <PageWrapper
      pageTitle={`${fieldLabels.center} Profiles`}
      mobileButtonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Center, permission: PermissionType.Base, level: RoleLevelType.Create }}
        >
          <CirclePlusButton variant="primary" className="mt-4 mb-4" onClick={toggleNewCenterForm} />
        </HasRoleAreaLevel>
      }
      buttonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Center, permission: PermissionType.Base, level: RoleLevelType.Create }}
        >
          <CreateButton className="my-2" id="create-center-btn" onClick={toggleNewCenterForm}>
            Add {fieldLabels.center}
          </CreateButton>
        </HasRoleAreaLevel>
      }
      applyPadding={true}
    >
      <Container fluid>
        {mutationError && <Alert variant="danger">{mutationError.message || 'Something went wrong.'}</Alert>}
        {error && <Alert variant="danger">{error.message || 'Something went wrong.'}</Alert>}
        {loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES ? (
          <PageWrapperBody>
            <DataTableLoadingSkeleton />
          </PageWrapperBody>
        ) : (
          <CenterProfilesTable
            handleRowClick={(event, rowData) => navigateToCenterProfile(rowData.id)}
            handleDeactivate={(centers) => showModal(centers, 'deactivate')}
            handleReactivate={(centers) => showModal(centers, 'reactivate')}
            handleDelete={(centers) => showModal(centers, 'delete')}
            handleAddTags={(centers) => showModal(centers, 'addTags')}
            tableState={tableState}
            tableFunctions={tableFunctions}
            data={centers}
            dataSize={data?.searchCenters.totalResults ?? 0}
            loading={loading}
            autoSelectedBusinessId={autoSelectedBusinessId}
          />
        )}
        <NewCenterModalForm
          isOpen={showNewCenterModal}
          onClose={toggleNewCenterForm}
          navigateToCenterProfile={navigateToCenterProfile}
          refetch={refetch}
        />
        {selectedCenters && (
          <DeactivateCenterModal
            isOpen={showDeactivateCenterModal || showDeleteCenterModal}
            isLoading={deactivateCenterLoading || deleteCenterLoading}
            onClose={dismissModal}
            deactivateCenter={() =>
              Promise.all(
                selectedCenters.map((c) =>
                  deactivateCenterMutationFn({
                    variables: {
                      id: c.id,
                    },
                  })
                )
              ).then(() => {
                onMutationSuccess('deactivated');
              })
            }
            deleteCenter={() =>
              Promise.all(
                selectedCenters.map((c) =>
                  deleteCenterMutationFn({
                    variables: {
                      id: c.id,
                    },
                  })
                )
              ).then(() => {
                onMutationSuccess('deleted');
              })
            }
            action={showDeactivateCenterModal ? 'DEACTIVATE' : 'DELETE'}
            centers={selectedCenters}
          />
        )}
        {selectedCenters && (
          <ReactivateCenterModal
            isOpen={showReactivateCenterModal}
            isLoading={reactivateCenterLoading}
            onClose={dismissModal}
            centers={selectedCenters}
            reactivateCenter={() =>
              Promise.all(
                selectedCenters.map((c) =>
                  reactivateCenterMutationFn({
                    variables: {
                      id: c.id,
                    },
                  })
                )
              ).then(() => {
                onMutationSuccess('reactivated');
              })
            }
          />
        )}
        {selectedCenters && (
          <AddTagsToCenterModal
            isOpen={showAddTagsModal}
            onClose={dismissModal}
            centers={selectedCenters}
            isLoading={updateCenterTagsLoading}
            addTags={(tags: ITag[]) =>
              Promise.all(
                selectedCenters.map((center: ICenter) =>
                  updateCenterTagsFn({
                    variables: {
                      input: {
                        id: center.id,
                        tags: uniq((center.tags ? [...center.tags, ...tags] : tags).map((tag: ITag) => tag.id)),
                      },
                    },
                  })
                )
              ).then(() => onMutationSuccess('added tags to'))
            }
          />
        )}
      </Container>
    </PageWrapper>
  );
};

export default Profiles;
