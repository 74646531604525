import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PageWrapper from 'shared/components/PageWrapper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { CreateButton } from 'shared/components/Buttons';
import { useParams } from 'react-router-dom';
import BookingRulesTab from './Tabs/BookingRules/BookingRulesTab';
import { Row } from 'shared/components/Layout';
import AddBookingRulesModal from './Modals/AddBookingRulesModal';
import {
  useUpdateCasualBookingSettings,
  useAddCasualBookingExclusions,
  useDeleteCasualBookingExclusion,
} from 'gql/casualBooking/mutations';
import { capitalize, every, omit, uniqBy } from 'lodash';
import { useGetClassesForCenter } from 'gql/class/queries';
import { casualBookingSettingsFields } from 'gql/casualBooking/fields';
import { omitTypename } from 'shared/util/object';
import { useSearchCenters } from 'gql/center/queries';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import { centerFieldsWithCasualBookingSettings } from 'gql/center/fields';
import AddFamilyExclusionsModal from './Modals/AddFamilyExclusionsModal';
import DeleteFamilyExclusionsModal from './Modals/DeleteFamilyExclusionsModal';
import './_attendanceSettings.scss';
import GeneralSettingsTab from './Tabs/GeneralSettings/components/GeneralSettingsTab';

const { TERM } = SEARCH_EXPRESSIONS;
const { ACTIVE } = PREDICATES;

type AttendanceSettingsTab = 'booking-rules';
type GeneralSettingsTab = 'general-settings';

interface DeleteFamilyExclusionsModalState {
  isOpen: boolean;
  accountName: string | null;
  accountId: string | null;
}

interface IProps {}

const AttendanceSettings: React.FC<IProps> = () => {
  const { activeTab } = useParams<{ activeTab: AttendanceSettingsTab | undefined }>();
  const [subTab, setSubTab] = useState<AttendanceSettingsSubTab>('centers');
  const centersById = useSelector((state: RootState) => state.centers.byId);
  const [isBookingRuleModalOpen, setIsBookingRuleModalOpen] = useState<boolean>(false);
  const [isAddExclusionsModalOpen, setIsAddExclusionsModalOpen] = useState<boolean>(false);
  const [deleteExclusionModalState, setDeleteExclusionModalState] = useState<DeleteFamilyExclusionsModalState>({
    isOpen: false,
    accountName: null,
    accountId: null,
  });
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedClasses, setSelectedClasses] = useState<IClassBookingSettings[]>([]);
  const [selectedCenters, setSelectedCenters] = useState<ICenterClassesCasualBookingSettings[]>([]);
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';

  const {
    data: classesData,
    loading: classesLoading,
    refetch: classesRefetch,
  } = useGetClassesForCenter<IClassBookingSettingsData>(
    {
      variables: { centerId: selectedCenterId },
      skip: subTab !== 'classes',
    },
    `id,name,centerId,careType,casualBookingSettings{${casualBookingSettingsFields}}`
  );

  const {
    loading: centersLoading,
    data: centersData,
    refetch: centersRefetch,
  } = useSearchCenters<ICenterClassesCasualBookingSettingsData>(
    {
      variables: {
        input: {
          filter: { [TERM]: { field: 'active', predicate: ACTIVE } },
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          size: 10000,
          from: 0,
        },
      },
      skip: !['centers', 'families'].includes(subTab),
    },
    centerFieldsWithCasualBookingSettings
  );

  const [updateCasualBookingSettingsFn, updateCasualBookingSettingsState] = useUpdateCasualBookingSettings({
    onCompleted: () => {
      setIsBookingRuleModalOpen(false);
      classesRefetch();
      centersRefetch();
    },
  });

  const [addCasualBookingExclusions, addCasualBookingExclusionsState] = useAddCasualBookingExclusions({
    onCompleted: () => {
      setIsAddExclusionsModalOpen(false);
      centersRefetch();
    },
  });

  const [deleteCasualBookingExclusion, deleteCasualBookingExclusionState] = useDeleteCasualBookingExclusion({
    onCompleted: () => {
      setDeleteExclusionModalState({ isOpen: false, accountName: null, accountId: null });
      centersRefetch();
    },
  });

  const handleAddBookingExclusions = useCallback(
    async (input: ICasualBookingExclusionsInput) => {
      await addCasualBookingExclusions({
        variables: {
          input,
        },
      });
    },
    [addCasualBookingExclusions]
  );

  const handleUpdateCasualBookingSettings = useCallback(
    async (setting: Omit<ICasualBookingSettingsInput, 'classId'>) => {
      const input = selectedClasses.map((cls) => {
        return {
          classId: cls.id,
          ...setting,
        };
      });
      updateCasualBookingSettingsFn({
        variables: {
          input,
        },
      }).then(() => {
        setSelectedClasses([]);
        setSelectedCenters([]);
      });
    },
    [updateCasualBookingSettingsFn, selectedClasses]
  );

  const handleDeleteCasualBookingExclusion = useCallback(
    async (accountId: string) => {
      await deleteCasualBookingExclusion({
        variables: {
          accountId,
        },
      });
    },
    [deleteCasualBookingExclusion]
  );

  const initialBookingRuleValueForModal = useMemo(() => {
    if (selectedClasses.length > 1 || selectedClasses.length === 0) {
      return undefined;
    }

    // when only one class has been selected, we need to get the default value for the modal
    const [selectedClass] = selectedClasses;
    const casualBookingSettingsWithoutTypeName = omitTypename<ICasualBookingSettings>(
      selectedClass.casualBookingSettings,
      true
    );

    const initialValue = omit(casualBookingSettingsWithoutTypeName, [
      'classId',
      'available',
      'availableReason',
    ]) as Omit<ICasualBookingSettings, 'classId' | 'available' | 'availableReason'>;

    return initialValue;
  }, [selectedClasses]);

  const addRuleButtonLabel = useMemo(() => {
    const hasSelection = selectedClasses.length > 0;
    // if all selected classes have existing booking rules
    if (hasSelection && every(selectedClasses, (cls) => cls.casualBookingSettings?.bookingPolicy)) {
      return t('attendance.settings.primary-buttons.edit-booking-rules');
    }
    // if all selected classes DO NOT have existing booking rules
    if (hasSelection && every(selectedClasses, (cls) => !cls.casualBookingSettings?.bookingPolicy)) {
      return t('attendance.settings.primary-buttons.add-booking-rules');
    }

    return t('attendance.settings.primary-buttons.add-booking-rules');
  }, [selectedClasses, t]);

  const convertCentersToClasses = useCallback(
    (centers: ICenterClassesCasualBookingSettings[]): IClassBookingSettings[] => {
      return centers.reduce<IClassBookingSettings[]>((acc, center) => {
        const classesInCenter: IClassBookingSettings[] = center.classes.map((cls) => ({
          id: cls.id,
          name: cls.name,
          careType: cls.careType,
          centerId: center.id,
          casualBookingSettings: cls.casualBookingSettings,
        }));
        return [...acc, ...classesInCenter];
      }, []);
    },
    []
  );

  useEffect(() => {
    setSelectedClasses([]);
    setSelectedCenters([]);
  }, [subTab, selectedCenterId]);

  useEffect(() => {
    const impliedClasses = convertCentersToClasses(selectedCenters);
    setSelectedClasses(impliedClasses);
  }, [selectedCenters, convertCentersToClasses]);

  const classesOptions: ITableFilterOption[] = useMemo(() => {
    if (subTab === 'classes') {
      return (
        classesData?.getClassesForCenter.map((cls) => ({
          value: cls.id,
          label: `${centersById[cls.centerId]?.name ?? ''} - ${cls.name}`,
        })) ?? []
      );
    }

    if (subTab === 'centers') {
      return selectedCenters.reduce<ITableFilterOption[]>(
        (options, center) => [
          ...options,
          ...center.classes.map((cls) => ({
            value: cls.id,
            label: `${center.name} - ${cls.name}`,
          })),
        ],
        []
      );
    }

    return [];
  }, [subTab, centersById, selectedCenters, classesData]);

  const onClassesFilterSelect = (values: ITableFilterOption[]) => {
    const dataSource = uniqBy(
      [...(classesData?.getClassesForCenter ?? []), ...convertCentersToClasses(centersData?.searchCenters.data ?? [])],
      'id'
    );
    const selectedClassIds = values.map((option) => option.value);
    const classes = dataSource.filter((cls) => selectedClassIds.includes(cls.id));
    setSelectedClasses(classes);
  };

  const handleExclusionDeleteModalOpen = useCallback((accountName: string, accountId: string) => {
    setDeleteExclusionModalState({
      isOpen: true,
      accountName,
      accountId,
    });
  }, []);

  const onCareTypesFilterSelect = useCallback(
    (values: ITableFilterOption[]) => {
      const selectedCareTypes = values.map((option) => option.value);
      const selectedCenterIds = selectedCenters.map((center) => center.id);
      const targetedCenters = (centersData?.searchCenters.data ?? []).filter((center) =>
        selectedCenterIds.includes(center.id)
      );
      const matchedClassesByCareTypes = convertCentersToClasses(targetedCenters).filter((cls) =>
        selectedCareTypes.includes(cls.careType)
      );
      setSelectedClasses(matchedClassesByCareTypes);
    },
    [centersData, convertCentersToClasses, selectedCenters]
  );

  return (
    <PageWrapper
      pageTitle={t('attendance.settings.title')}
      applyPadding={true}
      buttonComponent={
        <Row>
          {activeTab === 'booking-rules' && subTab === 'classes' && (
            <>
              <span className="mr-2">
                {selectedClasses.length}{' '}
                {capitalize(t(selectedClasses.length > 1 ? 'spelling.classes' : 'spelling.class'))}{' '}
                {capitalize(t('spelling.selected'))}{' '}
              </span>
              <CreateButton disabled={selectedClasses.length === 0} onClick={() => setIsBookingRuleModalOpen(true)}>
                {addRuleButtonLabel}
              </CreateButton>
            </>
          )}
          {activeTab === 'booking-rules' && subTab === 'centers' && (
            <>
              <span className="mr-2">
                {selectedCenters.length}{' '}
                {capitalize(t(selectedCenters.length > 1 ? 'spelling.center_plural' : 'spelling.center'))}{' '}
                {capitalize(t('spelling.selected'))}{' '}
              </span>
              <CreateButton disabled={selectedClasses.length === 0} onClick={() => setIsBookingRuleModalOpen(true)}>
                {addRuleButtonLabel}
              </CreateButton>
            </>
          )}
          {activeTab === 'booking-rules' && subTab === 'families' && (
            <CreateButton onClick={() => setIsAddExclusionsModalOpen(true)}>Add Family Exclusion</CreateButton>
          )}
        </Row>
      }
    >
      <Container fluid>
        <Tabs
          defaultActiveKey={activeTab}
          id="attendance-settings-tab-group"
          onSelect={(tab) => history.push(`/attendance/settings/${tab}`)}
        >
          <Tab eventKey="booking-rules" title={t('attendance.settings.booking-rules.tab-title')}>
            <BookingRulesTab
              selectedClasses={selectedClasses}
              setSelectedClasses={setSelectedClasses}
              selectedCenters={selectedCenters}
              setSelectedCenters={setSelectedCenters}
              subTab={subTab}
              setSubTab={setSubTab}
              classesData={classesData?.getClassesForCenter}
              classesLoading={classesLoading}
              centersData={centersData?.searchCenters.data}
              centersLoading={centersLoading}
              handleExclusionDeleteModalOpen={handleExclusionDeleteModalOpen}
            />
          </Tab>
          <Tab eventKey="general-settings" title={t('attendance.settings.general-settings.tab-title')}>
            <GeneralSettingsTab />
          </Tab>
        </Tabs>
        <AddBookingRulesModal
          isOpen={isBookingRuleModalOpen}
          isLoading={updateCasualBookingSettingsState.loading}
          onSubmit={handleUpdateCasualBookingSettings}
          onClose={() => {
            setIsBookingRuleModalOpen(false);
            setSelectedCenters([]);
            setSelectedClasses([]);
          }}
          initialValue={initialBookingRuleValueForModal}
          selectedClasses={selectedClasses}
          classesOptions={classesOptions}
          onClassesFilterSelect={onClassesFilterSelect}
          onCareTypesFilterSelect={onCareTypesFilterSelect}
          serveFor={subTab}
        />
        <AddFamilyExclusionsModal
          isOpen={isAddExclusionsModalOpen}
          isLoading={addCasualBookingExclusionsState.loading}
          onSubmit={handleAddBookingExclusions}
          onClose={() => setIsAddExclusionsModalOpen(false)}
          centersData={centersData?.searchCenters.data}
        />
        <DeleteFamilyExclusionsModal
          isOpen={deleteExclusionModalState.isOpen}
          onClose={() => setDeleteExclusionModalState({ isOpen: false, accountName: null, accountId: null })}
          handleDelete={() => handleDeleteCasualBookingExclusion(deleteExclusionModalState.accountId ?? '')}
          loading={deleteCasualBookingExclusionState.loading}
          accountName={deleteExclusionModalState.accountName ?? ''}
        />
      </Container>
    </PageWrapper>
  );
};

export default AttendanceSettings;
