import React, { useState } from 'react';
import moment from 'moment';
import ISCaseClaimsTable from 'pages/ISCases/components/ISCaseClaims/ISCaseClaimsTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { useGetISCaseClaims, useGetISCaseClaimsCounter } from 'gql/iSClaim/queries';
import ISCaseClaimsCards from 'pages/ISCases/components/ISCaseClaims/ISCaseClaimsCards';
import { ISCasePaymentStatusFromFilter } from 'shared/constants/enums/iSCaseClaimEnum';
import { range } from 'lodash';

interface IProps {
  selectedBusiness: ITableFilterOption | null;
}

const ISCasesClaimsTab: React.FC<IProps> = ({ selectedBusiness }) => {
  const businessId = selectedBusiness?.value || '';
  const { data: centers } = useGetActiveCentersWithLoading();
  const [tableState, tableFunctions] = useDatatableState();
  const [sort, setSortOptions] = useState<ISCaseClaimSort[]>([{ field: 'weekEnding', direction: 'DESCENDING' }]);
  const [activeFilters, setActiveFilters] = useState<IISCaseClaimFilter>({
    centers: [],
    status: null,
    searchText: '',
  });

  const { data: isCaseClaimCounts, refetch: isCaseClaimCountRefetch } = useGetISCaseClaimsCounter({
    variables: {
      businessId,
      centers: activeFilters?.centers.map((center) => center.value) ?? [],
    },
  });

  const weekNumbers = range(0, 53);
  let weeks = weekNumbers.map((week) => moment().subtract(week, 'week').startOf('week')).reverse();
  weeks = [...weeks, moment().startOf('week').add(1, 'week')];

  const {
    data: isCasesData,
    loading,
    refetch: isCasesRefetch,
  } = useGetISCaseClaims({
    variables: {
      input: {
        businessId,
        centerIds: activeFilters?.centers.map((center) => center.value) ?? [],
        includeActive: true,
        includeInActive: true,
        searchText: activeFilters.searchText,
        weeksEnding: weeks.map((week) => week.format('YYYY-MM-DD')),
        sortDtos: sort,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        paymentStatus: activeFilters.status ? ISCasePaymentStatusFromFilter[activeFilters.status] : undefined,
      },
    },
  });

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field, direction }]);
  };

  const handleFilterChange = (newFilters: IISCaseClaimFilter) => {
    setActiveFilters(() => newFilters);
  };

  const handleStatusFilter = (status: ISClaimStatusFilter | null) =>
    setActiveFilters((filters) => ({ ...filters, status }));

  return (
    <>
      <ISCaseClaimsCards
        data={isCaseClaimCounts?.getIsCaseClaimCategoryCountsForCenters}
        onSelected={handleStatusFilter}
        activeFilter={activeFilters.status}
      />
      <ISCaseClaimsTable
        data={isCasesData?.getIsCaseClaimsForCenters?.data || []}
        dataSize={isCasesData?.getIsCaseClaimsForCenters?.totalRecords || 0}
        loading={loading}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        onSort={handleSort}
        activeFilters={activeFilters}
        onActiveFilterChange={handleFilterChange}
        centers={centers || []}
        businessId={businessId}
        onMutation={() => {
          isCaseClaimCountRefetch();
          isCasesRefetch();
        }}
      />
    </>
  );
};

export default ISCasesClaimsTab;
