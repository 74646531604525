import React from 'react';
import { Card as BSCard, Row as BSRow, Container, Col, CardProps } from 'react-bootstrap';
import Box from 'shared/components/LoadingSkeletons/Box';

interface IProps {
  title: string;
  subTitle?: string;
  number: number | undefined;
  numberColor: string;
  titleClassName?: string;
  loading?: boolean;
  active?: boolean;
  onClick?: () => void;
}

const NumberCard: React.FC<IProps> = ({
  title,
  subTitle = '',
  number = 0,
  numberColor,
  loading = false,
  active = false,
  onClick,
}) => {
  const borderColor = !active ? 'white' : numberColor;
  const backgroundColor = 'white';
  const textColorClass = numberColor ? `text-${numberColor}` : '';
  return (
    <BSCard
      className={[
        'number-card', // Style for transition effect
        'border', // For some reason the card border isn't the default of 1px, so this forces it
        onClick ? 'cursor-pointer' : '', // Change cursor to pointer to show this element is interactable
      ].join(' ')}
      onClick={onClick}
      // The need for casting here reveals a weakness in the typechecking.
      border={borderColor as CardProps['border']}
      bg={backgroundColor as CardProps['bg']}
    >
      <BSCard.Body>
        <Container fluid className="h-100">
          <BSRow className="h-100 align-content-between">
            <Col className="align-self-center">
              <h5 className={`sans-serif number-card-text ${textColorClass}`}>{title}</h5>
              <small className={`sans-serif number-card-text `}>{subTitle}</small>
            </Col>
            <Col sm="12" lg="1" style={{ maxWidth: 'none' }}>
              {loading ? (
                <Box height={100} width={80} />
              ) : (
                <p className={`h1 font-weight-light number-card-text ${textColorClass}`} style={{ fontSize: 80 }}>
                  {number === 0 ? 0 : number?.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
                </p>
              )}
            </Col>
          </BSRow>
        </Container>
      </BSCard.Body>
    </BSCard>
  );
};

export default NumberCard;
