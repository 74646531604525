import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import EmploymentDetailsCard from './EmploymentDetailsCard';
import PermissionsModal from './PermissionsModal';
import EmploymentHistoryCard from './EmploymentHistory/EmploymentHistoryCard';
import AffiliationsTable from './Affiliations';
import AvailabilityForm from '../Profile/AvailabilityForm';
import Card from 'shared/components/Card';

interface IProps {
  staff: IStaff | undefined;
  loading: boolean;
  readOnly: boolean;
  haveAnyPermissions?: boolean | undefined;
  editPermission?: boolean | undefined;
}

const EmploymentTab: React.FC<IProps> = ({ staff, loading, readOnly, haveAnyPermissions, editPermission }) => {
  const [permissionsModalOpen, setPermissionsModalOpen] = useState<boolean>(false);
  return (
    <>
      <Row>
        <Column md>
          {staff && (
            <EmploymentDetailsCard
              reinviteData={{
                personId: staff.id,
                entityId: staff.entityId,
                primaryCenterId: staff.primaryCenterId,
              }}
              employementStartDate={staff.employmentStartDate}
              status={staff?.employmentStatus}
              roleName={staff?.roleship.name ?? ''}
              openPermissionsModal={() => setPermissionsModalOpen(true)}
              haveAnyPermissions={haveAnyPermissions}
            />
          )}
        </Column>
        <Column md>
          <EmploymentHistoryCard staff={staff} loading={loading} readOnly={readOnly} />
        </Column>
      </Row>
      {staff && <AffiliationsTable staff={staff}></AffiliationsTable>}
      {staff && (
        <PermissionsModal
          isOpen={permissionsModalOpen}
          onClose={() => setPermissionsModalOpen(false)}
          staff={staff}
          readOnly={readOnly}
          editPermission={editPermission}
        />
      )}

      <Card header="Availability" loading={loading}>
        {staff && <AvailabilityForm staff={staff} readOnly={readOnly} />}
      </Card>
    </>
  );
};

export default EmploymentTab;
