import { gql } from '@apollo/client';
import { useLazyQuery } from 'shared/apis/core';

export const CHECK_IS_EMAIL_TAKEN = gql`
  query ($email: String!) {
    checkIsEmailTaken(email: $email)
  }
`;

const useLazyCheckIsEmailTaken = () =>
  useLazyQuery<{ checkIsEmailTaken: boolean }, { email: string }>(CHECK_IS_EMAIL_TAKEN);

export default useLazyCheckIsEmailTaken;
