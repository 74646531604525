import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import DataTable from 'shared/components/DataTable';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { RootState } from 'store/reducers';
import { setCurrentBusiness } from 'store/context/actions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFormatDate from 'shared/hooks/useFormatDate';
import DepositExpandedRow from './DepositExpandedRow';
import Currency from 'shared/components/Currency';

interface IProps {
  data: IAccountDeposit[];
  account?: IAccount;
  loading: boolean;
}

const AccountDepositsTable: React.FC<IProps> = ({ data, loading, account, ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const dispatch = useDispatch();
  const { businessId } = useSelector((state: RootState) => state.context);
  const { data: businessesData } = useGetAllowedEntities();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');

  useEffect(() => {
    if (businesses.length > 0 && businessId === '') dispatch(setCurrentBusiness(businesses[0].id));
  }, [businesses, businessId, dispatch]);

  const renderAmount = useCallback(
    (amount: number): JSX.Element => (
      <b>
        <Currency amount={amount} />
      </b>
    ),
    []
  );

  const getTableColumns = useCallback((): any[] => {
    const tableColumns: any = [
      {
        dataField: 'date',
        text: 'Date',
        sort: true,
        formatter: (date: string) => formatDate(date),
      },

      {
        dataField: 'childId',
        text: 'Child Name',
        sort: false,
        formatter: (childId: string) => account?.children?.find((s) => s.id === childId)?.fullName ?? '',
      },
      {
        dataField: 'description',
        text: 'Description',
      },
      {
        dataField: 'amount',
        text: 'Amount',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        formatter: renderAmount,
      },
    ];
    return tableColumns;
  }, [t, renderAmount, formatDate, history]);

  return (
    <DataTable
      dataSize={data.length}
      showLoadingOverlay={loading}
      showPagination={false}
      showSelect={false}
      data={data}
      keyField="transactionId"
      columns={getTableColumns()}
      noDataText="This account does not have any deposits yet"
      expandRow={(deposit) => <DepositExpandedRow deposit={deposit} />}
    />
  );
};

export default AccountDepositsTable;
