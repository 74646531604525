import React, { useCallback, useState } from 'react';
import { PersonAvatar } from 'shared/components/Avatar';
import Button from 'shared/components/Buttons';
import useFormatDate from 'shared/hooks/useFormatDate';
import { HorizontalDivider } from 'shared/components/Dividers';
import { getFullName, toProperCase } from 'shared/util/string';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AppliedDiscountTransactionsSection from 'pages/BillingTransactions/components/AppliedDiscountTransactionsSection';
import './TransactionExpandedRow.scss';

interface IProps {
  transaction: ITransaction;
  isPaymentTransactionsOnly?: boolean;
  approvePaymentLoading?: boolean;
  handleReverse: (t: ITransaction) => void;
  onApproveFlaggedPayment?: (payment: IPayment) => void;
  onRejectFlaggedPayment?: (payment: IPayment, voidManualCheckPayment: boolean) => void;
  onAddDiscountToTransaction?: (transaction: ITransaction) => void;
  onRemoveDiscountFromTransaction?: (transaction: ITransaction, discountTransaction: ITransaction) => void;
}

const TransactionExpandedRow: React.FC<IProps> = ({
  transaction,
  approvePaymentLoading = false,
  handleReverse,
  onApproveFlaggedPayment = () => {},
  onRejectFlaggedPayment = () => {},
  onAddDiscountToTransaction = () => {},
  onRemoveDiscountFromTransaction = () => {},
}) => {
  const { t } = useTranslation(['billing', 'translation']);
  const { k2Discounts, k2CancelPayment } = useFlags();

  const reversedTransaction = transaction.reversedTransaction;
  const hasFlaggedPayment = Boolean(transaction.payment?.flags.length);
  const hasPendingFlaggedPayment = Boolean(
    hasFlaggedPayment && !transaction.payment?.approvedBy && !transaction.payment?.rejectedBy
  );
  const isUnsubmitted = Boolean(
    transaction.payment?.status === 'UNSUBMITTED' || (k2CancelPayment && transaction.payment?.status === 'PENDING')
  );
  const [isApproveButtonClicked, setIsApproveButtonClicked] = useState<boolean>(false);
  const isCompletedManualCheckPayment = Boolean(
    transaction.payment?.status === 'COMPLETED' &&
      ['Check', 'Cheque'].includes(transaction.transactionType.name) &&
      transaction.createdByPerson !== null
  );

  const isCompletedManualPayment = Boolean(
    transaction.payment?.status === 'COMPLETED' &&
      ['Cash', 'Money Order', 'EFTPOS', 'Direct Deposit'].includes(transaction.transactionType.name) &&
      transaction.createdByPerson !== null
  );

  const formatDate = useFormatDate();

  const canReverseTransaction = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });
  const canApprovePayment = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Create,
  });
  const canCancelPayment = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Delete,
  });

  const formatPaymentFlagEnum = useCallback(
    (flagReason: PaymentFlagType): string => {
      switch (flagReason) {
        case 'MULTIPLE_PAYMENTS_ON_SINGLE_DAY':
          return t('billing:transactions.flag-explanations.multiple-payments');
        case 'PERCENTAGE_BREACH':
          return t('billing:transactions.flag-explanations.percentage-breach', { percentage: 30 }); // 30 is a hardcoded value in the backend
        case 'SUBSIDY_REDUCTION':
          return t('billing:transactions.flag-explanations.subsidy-reduction');
        default:
          return '';
      }
    },
    [t]
  );

  return (
    <div className="new-transaction-expand-row">
      <div className="info-container">
        <div className="transaction-description-title">
          <h4>{transaction.description}</h4>
        </div>
        <div className="generic-container">
          <Info label="Apply Date" info={formatDate(transaction.date, 'MM/DD/YYYY')} />
          <Info
            label="Created By"
            info={
              transaction.createdByPerson ? (
                <span>
                  <PersonAvatar size="xs" person={transaction.createdByPerson} className="mr-2" />
                  {getFullName(transaction.createdByPerson)}
                </span>
              ) : (
                <span>System</span>
              )
            }
          />
          <Info
            label="Reversed by"
            info={
              transaction.reversedByPerson ? (
                <span>
                  <PersonAvatar size="xs" person={transaction.reversedByPerson} className="mr-2" />
                  {getFullName(transaction.reversedByPerson)}
                </span>
              ) : (
                'N/A'
              )
            }
          />
          <Info
            label="Reversed by Transaction"
            info={
              transaction.reversedByTransactionId && transaction.reversedByTransaction
                ? transaction.reversedByTransaction.transactionNumber
                : 'N/A'
            }
          />
          <Info label="Reverses Transaction" info={reversedTransaction?.transactionNumber ?? 'N/A'} />
          <div></div>
        </div>
        <HorizontalDivider />
        {transaction.payment ? (
          <div className="generic-container">
            <Info
              label="Payment Method:"
              info={
                transaction.payment?.paymentMethodUsedType
                  ? `${toProperCase(transaction.payment?.paymentMethodUsedType ?? '').replace('_', ' ')} ending in ****
                ${transaction.payment?.paymentMethodUsedLastFour}`
                  : transaction.transactionType.name
              }
            />
            <Info label="Payment Status" info={transaction.payment?.status} />
            <Info
              label="Submitted At"
              info={
                transaction.payment?.submittedAt ? formatDate(transaction.payment?.submittedAt, 'MM/DD/YYYY') : 'N/A'
              }
            />
            <Info
              label={t('billing:transactions.expanded-row.failure-description')}
              info={transaction.payment?.processorFailureMessage ?? 'N/A'}
            />
            <Info
              label={'Flags'}
              info={
                hasFlaggedPayment
                  ? transaction.payment?.flags.map((f) => formatPaymentFlagEnum(f.flagType)).join('; ')
                  : 'N/A'
              }
            />
            <Info
              label={'Flag action'}
              info={(() => {
                let action;
                let person;
                let date;
                if (transaction.payment?.rejectedAt) {
                  action = 'Rejected';
                  person = transaction.payment?.rejectedByPerson;
                  date = transaction.payment?.rejectedAt;
                } else if (transaction.payment?.approvedAt) {
                  action = 'Approved';
                  person = transaction.payment?.approvedByPerson;
                  date = transaction.payment?.approvedAt;
                } else {
                  return 'N/A';
                }
                return (
                  <span>
                    {action + ' '} <PersonAvatar size="xs" person={person} /> on {formatDate(date, 'MM/DD/YYYY')}
                  </span>
                );
              })()}
            />
          </div>
        ) : (
          <div className="generic-container">
            <Info
              label="Child"
              info={transaction.appliedToAccountChild ? getFullName(transaction.appliedToAccountChild) : 'N/A'}
            />
            <Info label="Itemized Bill ID" info={transaction.itemizedBill?.userFriendlyId ?? 'Not billed yet'} />
            <div />
            <div />
            <div />
            <div />
          </div>
        )}
      </div>
      <HorizontalDivider />
      {k2Discounts &&
        transaction.transactionType.accountType !== 'Liability' &&
        !(transaction.reversedByTransactionId !== null || transaction.reversedTransactionId !== null) &&
        transaction.transactionType.isDebit && (
          <>
            <AppliedDiscountTransactionsSection
              transaction={transaction}
              onAddNewDiscount={onAddDiscountToTransaction}
              onRemoveAppliedDiscount={(discountTransaction) =>
                onRemoveDiscountFromTransaction(transaction, discountTransaction)
              }
            />
            <HorizontalDivider />
          </>
        )}
      <div className="actions-container">
        {/* generic actions */}
        {!transaction.reversedAt && canReverseTransaction && transaction.isTransactionTypeReversible && (
          <Button onClick={() => handleReverse(transaction)} variant="outline-danger">
            Reverse
          </Button>
        )}
        {/* payment actions */}
        {canCancelPayment &&
          (isUnsubmitted || isCompletedManualCheckPayment || isCompletedManualPayment) &&
          !hasPendingFlaggedPayment &&
          onRejectFlaggedPayment && (
            <Button
              variant="danger"
              onClick={() => onRejectFlaggedPayment(transaction.payment as IPayment, isCompletedManualCheckPayment)}
            >
              {isCompletedManualCheckPayment
                ? t('billing:transactions.expanded-row.void-check-button')
                : t('billing:transactions.expanded-row.cancel-payment-button')}
            </Button>
          )}
        {canApprovePayment && hasPendingFlaggedPayment && (
          <Button
            variant="success"
            loading={approvePaymentLoading}
            disabled={isApproveButtonClicked}
            onClick={() => {
              setIsApproveButtonClicked(true);
              if (onApproveFlaggedPayment) onApproveFlaggedPayment(transaction.payment as IPayment);
              setIsApproveButtonClicked(false);
            }}
            className="mr-4"
          >
            {t('billing:transactions.expanded-row.approve-button')}
          </Button>
        )}
        {canCancelPayment && hasPendingFlaggedPayment && (
          <Button
            variant="danger"
            onClick={() => onRejectFlaggedPayment && onRejectFlaggedPayment(transaction.payment as IPayment, false)}
          >
            {t('billing:transactions.expanded-row.cancel-payment-button')}
          </Button>
        )}
      </div>
    </div>
  );
};

function Info({ label, info }) {
  return (
    <div>
      <h6>{label}</h6>
      <div>{info}</div>
    </div>
  );
}

export default TransactionExpandedRow;
