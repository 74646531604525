import React, { useCallback } from 'react';
import moment from 'moment';
import { groupBy, orderBy } from 'lodash';
import Card from 'shared/components/Card';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import { IPayPeriodDailyAttendanceGrouping } from './TotalsTimeCardView';
import {
  getActualMinutesWorked,
  getAdjustedMinutesWorked,
  createHourDecimalTimeString,
  getActualScheduledAndOvertimeMinutesForTimeEntry,
} from './timeCardUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

interface IScheduledAndOvertimeMinutes {
  scheduled: number;
  overtime: number;
}

interface ITotalsTableRow {
  positionLabel: string;
  actualTotalMinutes: number;
  actualStTotalMinutes: number;
  actualOtTotalMinutes: number | null;
  adjustedTotalMinutes: number;
  adjustedStTotalMinutes: number;
  adjustedOtTotalMinutes: number | null;
}

interface IGroupPaidTime {
  holiday: number;
  training: number;
  closure: number;
  outsideBusinessHours: number;
}

interface IProps {
  payPeriodData: IPayPeriodDailyAttendanceGrouping;
}

const TotalsTimeCard: React.FC<IProps> = ({ payPeriodData, ...props }) => {
  const { t } = useTranslation(['timelog']);
  const { k2WebPtoTypes } = useFlags();

  const groupPayPeriodDataByPosition = useCallback(
    (data: IPayPeriodDailyAttendanceGrouping): ITotalsTableRow[] => {
      let groupedData: ITotalsTableRow[] = [];
      const shiftsGroupedByPosition = groupBy(
        data.dailyAttendances.flatMap((d) => d.shifts),
        (s) => s.positionId
      );
      const timeEntriesGroupedByPosition = groupBy(
        data.dailyAttendances.flatMap((d) => d.timeEntries),
        (te) => te.positionId
      );
      const positionIds = new Set([
        ...Object.keys(shiftsGroupedByPosition),
        ...Object.keys(timeEntriesGroupedByPosition),
      ]);

      positionIds.forEach((positionId) => {
        let positionLabel = '';
        const shiftsForPosition = shiftsGroupedByPosition[positionId] ?? [];
        const timeEntriesForPosition = timeEntriesGroupedByPosition[positionId] ?? [];

        if (timeEntriesForPosition[0] !== undefined) {
          positionLabel = timeEntriesForPosition[0].staffPosition.positionName;
        } else if (shiftsForPosition[0] !== undefined) {
          positionLabel = shiftsForPosition[0].position?.positionName ?? '';
        }

        const totalsForPosition = {
          positionLabel,
          actualTotalMinutes: getActualMinutesWorked(timeEntriesForPosition),
          actualStTotalMinutes: 0,
          actualOtTotalMinutes: 0,
          adjustedTotalMinutes: getAdjustedMinutesWorked(timeEntriesForPosition, []), // an empty array is passed for the training time since they are their own row in the table and shouldn't be included in this calculation
          adjustedStTotalMinutes: 0,
          adjustedOtTotalMinutes: 0,
        };

        data.dailyAttendances.forEach((day) => {
          const timesEntriesForPositionOnDay = day.timeEntries.filter((te) => te.positionId === positionId);

          const actualScheduledAndOvertimeMinutes = timesEntriesForPositionOnDay
            .map((te) => getActualScheduledAndOvertimeMinutesForTimeEntry(te, day, 'actual'))
            .reduce<IScheduledAndOvertimeMinutes>(
              (acc, curr) => ({
                scheduled: (acc.scheduled += curr.scheduled ?? 0),
                overtime: (acc.overtime += curr.overtime ?? 0),
              }),
              { scheduled: 0, overtime: 0 }
            );

          const adjustedScheduledAndOvertimeMinutes = timesEntriesForPositionOnDay
            .map((te) => getActualScheduledAndOvertimeMinutesForTimeEntry(te, day, 'adjusted'))
            .reduce<IScheduledAndOvertimeMinutes>(
              (acc, curr) => ({
                scheduled: (acc.scheduled += curr.scheduled ?? 0),
                overtime: (acc.overtime += curr.overtime ?? 0),
              }),
              { scheduled: 0, overtime: 0 }
            );

          totalsForPosition.actualStTotalMinutes += Number.parseFloat(
            createHourDecimalTimeString(actualScheduledAndOvertimeMinutes.scheduled)
          );
          totalsForPosition.actualOtTotalMinutes += Number.parseFloat(
            createHourDecimalTimeString(actualScheduledAndOvertimeMinutes.overtime)
          );
          totalsForPosition.adjustedStTotalMinutes += Number.parseFloat(
            createHourDecimalTimeString(adjustedScheduledAndOvertimeMinutes.scheduled)
          );
          totalsForPosition.adjustedOtTotalMinutes += Number.parseFloat(
            createHourDecimalTimeString(adjustedScheduledAndOvertimeMinutes.overtime)
          );
        });

        groupedData.push(totalsForPosition);
      });

      groupedData = orderBy(groupedData, (row) => row.positionLabel.toLowerCase(), 'asc');

      const groupPaidTime = data.dailyAttendances.reduce<IGroupPaidTime>(
        (acc, curr) => {
          const list = curr.trainingTimeEvents ?? [];
          for (let index = 0; index < list.length; index++) {
            const timeEvent = list[index];
            let key;

            switch (timeEvent.type) {
              case 'TRAINING':
              case 'HOLIDAY':
              case 'CLOSURE':
                key = timeEvent.type.toLowerCase();
                break;
              case 'OOBH':
                key = 'outsideBusinessHours';
                break;
              default:
                break;
            }

            if (!key) continue;

            acc[key as keyof IGroupPaidTime] += timeEvent.hours;
          }

          return acc;
        },
        { holiday: 0, training: 0, closure: 0, outsideBusinessHours: 0 }
      );

      groupedData.push({
        positionLabel: 'Holiday',
        actualTotalMinutes: 0,
        actualStTotalMinutes: 0,
        actualOtTotalMinutes: null,
        adjustedTotalMinutes: Number.parseFloat(createHourDecimalTimeString(groupPaidTime.holiday * 60)),
        adjustedStTotalMinutes: Number.parseFloat(createHourDecimalTimeString(groupPaidTime.holiday * 60)),
        adjustedOtTotalMinutes: null,
      });

      groupedData.push({
        positionLabel: 'Training',
        actualTotalMinutes: 0,
        actualStTotalMinutes: 0,
        actualOtTotalMinutes: null,
        adjustedTotalMinutes: Number.parseFloat(createHourDecimalTimeString(groupPaidTime.training * 60)),
        adjustedStTotalMinutes: Number.parseFloat(createHourDecimalTimeString(groupPaidTime.training * 60)),
        adjustedOtTotalMinutes: null,
      });

      if (k2WebPtoTypes) {
        groupedData.push({
          positionLabel: 'Closure',
          actualTotalMinutes: 0,
          actualStTotalMinutes: 0,
          actualOtTotalMinutes: null,
          adjustedTotalMinutes: Number.parseFloat(createHourDecimalTimeString(groupPaidTime.closure * 60)),
          adjustedStTotalMinutes: Number.parseFloat(createHourDecimalTimeString(groupPaidTime.closure * 60)),
          adjustedOtTotalMinutes: null,
        });

        groupedData.push({
          positionLabel: 'Out of Business Hours',
          actualTotalMinutes: 0,
          actualStTotalMinutes: 0,
          actualOtTotalMinutes: null,
          adjustedTotalMinutes: Number.parseFloat(createHourDecimalTimeString(groupPaidTime.outsideBusinessHours * 60)),
          adjustedStTotalMinutes: Number.parseFloat(
            createHourDecimalTimeString(groupPaidTime.outsideBusinessHours * 60)
          ),
          adjustedOtTotalMinutes: null,
        });
      }

      groupedData.push({
        positionLabel: 'Time Off',
        actualTotalMinutes: Number.parseFloat(createHourDecimalTimeString(data.timeOffHoursApproved * 60)),
        actualStTotalMinutes: Number.parseFloat(createHourDecimalTimeString(data.timeOffHoursApproved * 60)),
        actualOtTotalMinutes: null,
        adjustedTotalMinutes: Number.parseFloat(createHourDecimalTimeString(data.timeOffHoursApproved * 60)),
        adjustedStTotalMinutes: Number.parseFloat(createHourDecimalTimeString(data.timeOffHoursApproved * 60)),
        adjustedOtTotalMinutes: null,
      });

      return groupedData;
    },
    [k2WebPtoTypes]
  );

  const getTotal = (data: ITotalsTableRow[], propertyName: string) => {
    const total = _.sumBy(data, propertyName);
    return total ? total.toFixed(2) : '-';
  };

  const tableData = groupPayPeriodDataByPosition(payPeriodData);

  return (
    <Card
      className="kt-employee-time-card-single-day"
      bodyClassName="p-4"
      header={`${moment(payPeriodData.payPeriodStartDate).format('MMM D')} - ${moment(
        payPeriodData.payPeriodEndDate
      ).format('MMM D')}`}
    >
      <Table responsive className="kt-employee-time-card-table kt-employee-time-card-totals-table">
        <thead>
          <tr>
            <th colSpan={2} />
            <th>{t('timelog:time-card.actual-column')}</th>
            <th colSpan={2} />
            <th>{t('timelog:time-card.adjusted-column')}</th>
            <th />
          </tr>
          <tr>
            <th>{t('timelog:time-card.position-column')}</th>
            <th>{t('timelog:time-card.total-column')}</th>
            <th>{t('timelog:time-card.st-column')}</th>
            <th>{t('timelog:time-card.ot-column')}</th>
            <th>{t('timelog:time-card.total-column')}</th>
            <th>{t('timelog:time-card.st-column')}</th>
            <th>{t('timelog:time-card.ot-column')}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, idx) => (
            <tr key={idx}>
              <td>{row.positionLabel}</td>
              <td>{row.actualTotalMinutes.toFixed(2)}</td>
              <td>{row.actualStTotalMinutes.toFixed(2)}</td>
              <td>{row.actualOtTotalMinutes ? row.actualOtTotalMinutes.toFixed(2) : '-'}</td>
              <td>{row.adjustedTotalMinutes.toFixed(2)}</td>
              <td>{row.adjustedStTotalMinutes.toFixed(2)}</td>
              <td>{row.adjustedOtTotalMinutes ? row.adjustedOtTotalMinutes.toFixed(2) : '-'}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>{t('timelog:time-card.sub-totals-option')}</td>
            {/* to prevent unexpected rounding issues when summing the totals, we need to convert the minutes into their hour-decimal string (createHourDecimalTimeString) and then convert that to a number to be summed in the reducer */}
            <td>{tableData.reduce<number>((acc, curr) => (acc += curr.actualTotalMinutes), 0).toFixed(2)}</td>
            <td>{tableData.reduce<number>((acc, curr) => (acc += curr.actualStTotalMinutes), 0).toFixed(2)}</td>
            <td>{getTotal(tableData, 'actualOtTotalMinutes')}</td>
            <td>{tableData.reduce<number>((acc, curr) => (acc += curr.adjustedTotalMinutes), 0).toFixed(2)}</td>
            <td>{tableData.reduce<number>((acc, curr) => (acc += curr.adjustedStTotalMinutes), 0).toFixed(2)}</td>
            <td>{getTotal(tableData, 'adjustedOtTotalMinutes')}</td>
          </tr>
        </tfoot>
      </Table>

      <div className="timeTotalsSection">
        <strong>{t('timelog:time-card.total-hours-option')}</strong>
        <strong>
          {(_.sumBy(tableData, 'actualTotalMinutes') + _.sumBy(tableData, 'adjustedTotalMinutes')).toFixed(2)}
        </strong>
      </div>
    </Card>
  );
};

export default TotalsTimeCard;
