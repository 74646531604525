export const contactFields = `
  id
`;

export const childContactFields = `
  childId
  id
  firstname
  lastname
  email
  nickname
  avatar {
    url
  }  
  primaryPhoneNumber {
    number
    type
  }
  relationshipType
  accountPermissions {
    accountId
    permissions
    isPrimary
    account {
      id
      name
      center {
        id
        name
      }
    }
  }
`;
