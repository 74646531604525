import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import _ from 'lodash';

interface IProps {
  approvedFilter: boolean | null;
  photo: IPhoto;
  onGalleryItemClick: (item: IPhoto) => void;
  isSelected: boolean;
}

const Photo: React.FC<IProps> = ({ approvedFilter, photo, onGalleryItemClick, isSelected }) => {
  return (
    <div onClick={() => onGalleryItemClick(photo)}>
      <img
        style={{ objectFit: 'cover' }}
        alt={`${photo.createdAt}-${photo.note}`}
        src={_.isNil(photo.thumbnailKeySignedUrl) ? photo.signedurl : photo.thumbnailKeySignedUrl}
        height={250}
        width={250}
      />
      {approvedFilter == null && isSelected && (
        <div style={{ left: 0, top: 0 }} className="position-absolute h-100 w-100 d-flex align-items-center">
          <div style={{ opacity: 0.5 }} className="bg-success position-absolute h-100 w-100" />
          <FontAwesomeIcon style={{ zIndex: 2 }} className="mx-auto" size="7x" icon={faCheck} color="white" />
        </div>
      )}
    </div>
  );
};

export default Photo;
