import { MutationHookOptions, gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';

export const UPSERT_BUSINESS_CONFIGURATION = gql`
  mutation ($input: IUpsertBusinessConfigurationInput!) {
    upsertBusinessConfiguration(input: $input) {
      id
      businessId
      educationEnabled
      parentContactManagementEnabled
      activityFeedReleaseSchedule
      mealTrackingEnabled
      educationFeatures {
        feature
        isEnabled
      }
      expressCheckinEnabled
    }
  }
`;

export const useUpsertBusinessConfiguration = (
  input?: MutationHookOptions<{ upsertBusinessConfiguration: IBusinessConfiguration }, IUpsertBusinessConfiguration>
) =>
  useMutation<{ upsertBusinessConfiguration: IBusinessConfiguration }, IUpsertBusinessConfiguration>(
    UPSERT_BUSINESS_CONFIGURATION,
    input
  );
