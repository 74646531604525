import React, { useEffect } from 'react';
import { Row } from 'shared/components/Layout';
import moment from 'moment';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import SearchAvailabilityTable from 'pages/Enrollment/subroutes/Management/Tabs/Overview/components/SearchAvailabilityTable';

interface IProps {
  firstDateInView: string;
  classes: IClass[];
  dataDateRange: { startDate: string; endDate: string };
  data?: { getClassesForCenter: IClass[] };
  loading: boolean;
  classesToFilter: IClass[];
  strict?: boolean;
  setFirstDateInView: (date: string) => void;
  setDataDateRange: (range: { startDate: string; endDate: string }) => void;
}

const SearchAvailabilityResults: React.FC<IProps> = ({
  dataDateRange,
  firstDateInView,
  data,
  loading,
  strict = false,
  classesToFilter,
  setDataDateRange,
  setFirstDateInView,
  ...props
}) => {
  const datesInView = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => moment(firstDateInView).clone().add(i, 'd'));

  const classes =
    data?.getClassesForCenter
      .filter((c) => !c.archivedAt)
      .filter((c) => (!strict && !classesToFilter?.length) || classesToFilter?.map((sc) => sc.id).includes(c.id)) ?? [];

  useEffect(() => {
    if (data && (datesInView[0].isBefore(dataDateRange.startDate) || datesInView[10].isAfter(dataDateRange.endDate))) {
      setDataDateRange({
        startDate: datesInView[0].clone().subtract(10, 'd').format(),
        endDate: datesInView[0].clone().add(20, 'd').format(),
      });
    }
  }, [data, dataDateRange.endDate, dataDateRange.startDate, datesInView]);

  const totalOpenSpotsForFirstDay = classes
    .map((c) => c.availabilityForTimeframe.find((a) => moment(a.date).isSame(firstDateInView, 'd')))
    .map((a) => a?.totalOpenSpots)
    .reduce((a, b) => (a ?? 0) + (b ?? 0), 0);

  const totalAgeUpsForFirstDay = classes
    .map((c) => c.availabilityForTimeframe.find((a) => moment(a.date).isSame(firstDateInView, 'd')))
    .map((a) => a?.totalAgeUp)
    .reduce((a, b) => (a ?? 0) + (b ?? 0), 0);

  return (
    <div>
      {data && (
        <div>
          <SpinnerOverlay show={loading}>
            {strict && classes.length === 0 ? (
              <p>No classes available</p>
            ) : (
              <SearchAvailabilityTable
                setFirstDateInView={setFirstDateInView}
                classes={classes}
                datesInView={datesInView}
              />
            )}
          </SpinnerOverlay>
          <div className="my-4"></div>
          <div className="d-flex">
            <Row noGutters className="mr-4 sm">
              <div className="mr-2 available-bg-color border border-success" style={{ height: 20, width: 20 }} />
              <div className="mr-1">Available Spots</div>
            </Row>
            <Row noGutters className="mr-4 sm">
              <div className="mr-2 age-up-bg-color border border-warning" style={{ height: 20, width: 20 }} />
              <div className="mr-1">Ability to Age Up</div>
            </Row>
            <Row noGutters className="mr-4 sm">
              <div className="mr-2 over-capacity-bg-color border border-danger" style={{ height: 20, width: 20 }} />
              <div className="mr-1">Over-capacity</div>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchAvailabilityResults;
