import React from 'react';
import { getInitials, stringToHsl } from 'shared/util/string';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';

interface IProps {
  data: IISCaseClaim;
}

const ISCaseClaimsChildrenInfo: React.FC<IProps> = ({ data }) => {
  return (
    <div>
      <h5 className="mb-4">Children Information</h5>
      {data.children.map(({ childId, child }) => (
        <div className="mb-2">
          <AvatarDataTableCell
            size="sm"
            color={stringToHsl(childId)}
            initials={getInitials(child)}
            avatar={child.avatar?.url ?? ''}
            primaryText={child.fullName}
          />
        </div>
      ))}
    </div>
  );
};

export default ISCaseClaimsChildrenInfo;
