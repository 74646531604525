import { OrderedTransactionsTable } from './OrderedTransactions/OrderedTransactionsTable';
import AccountTransactions from './AccountTransactions';
import './TransactionTab.scss';
import { useUSModeSettings } from 'US/Billing/USMode';
import { useGetCurrentBusiness } from 'shared/hooks/user-context/useGetCurrentBusiness';

interface TransactionTabProps {
  account?: IAccount;
  accountId: string;
}
export default function TransactionTab({ account, accountId }: TransactionTabProps) {
  const businessId = useGetCurrentBusiness();
  const { usMode } = useUSModeSettings(businessId);
  if (usMode) return <OrderedTransactionsTable accountId={accountId}></OrderedTransactionsTable>;
  return <AccountTransactions accountId={accountId} account={account}></AccountTransactions>;
}
