import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import CustomFieldForm from 'shared/components/CustomFieldForm';
import { Col, Row } from 'shared/components/Layout';
import { showToast } from 'shared/components/Toast';
import { useUpdateCustomFieldValues } from 'gql/customField/mutation';
import { ICustomField, ICustomFieldValue } from 'shared/types/customField';
import CustomDataFieldsHeader from 'shared/components/Card/CardHeaderWithArchivedToggle';

interface IProps {
  account: IAccount;
  loading: boolean;
  customFields: ICustomField[];
  values: ICustomFieldValue[];
  refetch: () => void;
  onShowArchivedChange: (showArchived: boolean) => void;
  showArchived: boolean;
}
const AccountCustomFieldsTab: React.FC<IProps> = ({
  account,
  customFields,
  values,
  loading,
  refetch,
  onShowArchivedChange,
  showArchived,
}) => {
  const { t } = useTranslation(['accounts', 'translation']);

  const [updateValues, { loading: updateLoading }] = useUpdateCustomFieldValues({
    onCompleted: (res) => {
      refetch();
      showToast(t('accounts:profile.custom-fields-tab.update-field-values-success'), 'success');
    },
  });

  const handleSave = useCallback(
    (values) => {
      updateValues({
        variables: {
          input: {
            businessId: account?.entityId,
            entityId: account?.id,
            area: 'Account',
            values,
          },
        },
      });
    },
    [account, updateValues]
  );

  return (
    <Row align="start">
      <Col md={6}>
        <Card
          header={
            <CustomDataFieldsHeader
              isArchived={showArchived}
              onArchivedToggleChange={onShowArchivedChange}
              title={t('translation:custom-data-fields.custom-fields-card-title')}
            />
          }
          loading={loading}
        >
          {customFields.length > 0 ? (
            <CustomFieldForm
              values={values}
              customFields={customFields}
              isReadOnly={false}
              onSave={handleSave}
              loading={updateLoading}
              entityId={account?.id ?? ''}
            />
          ) : (
            <p>{t('accounts:profile.custom-fields-tab.no-info-to-display')}</p>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default AccountCustomFieldsTab;
