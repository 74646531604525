import { ApplicationFlowType, ApplicationState } from 'generated/graphql';

const ignoreApplicationStates = [
  ApplicationState.Errored,
  ApplicationState.Contacted,
  ApplicationState.Errored,
  ApplicationState.Lost,
  ApplicationState.None,
];

const ignoreProgramFlowStates = [
  ApplicationState.Offered,
  ApplicationState.OfferedPendingAcceptance,
  ApplicationState.AcceptedOfferPendingPayment,
];

export const isEnrolmentFormCompleted = (
  applicationState?: ApplicationState | null,
  flowType?: ApplicationFlowType | null
) => {
  if (!applicationState || ignoreApplicationStates.includes(applicationState)) return false;

  if (flowType === ApplicationFlowType.InquireOfferEnrollment) {
    return (
      applicationState !== ApplicationState.Offered && applicationState !== ApplicationState.OfferedPendingAcceptance
    );
  }

  console.log('test', applicationState, flowType);

  return !ignoreProgramFlowStates.includes(applicationState);
};
