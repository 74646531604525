import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'shared/components/Buttons';
import Select from 'shared/components/Select';
import TextInput, { NumberInput } from 'shared/components/TextInput';

interface IProps {
  revertDaysOfCare: () => void;
  setAddTolerance: (status: boolean) => void;
  caseDayClaims: ICaseDayClaim[];
  showAddTolerance: boolean;
  handleChangeDay: (index: number, day: DayOfCare) => void;
  handleDayOfCareHoursChange: (value: number, name: string, index: number) => void;
}

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const dayOptions = daysOfWeek.map((day) => ({ label: day, value: day }));

const CaseDayFields: React.FC<IProps> = ({
  caseDayClaims,
  showAddTolerance,
  revertDaysOfCare,
  setAddTolerance,
  handleChangeDay,
  handleDayOfCareHoursChange,
}) => {
  return (
    <>
      <Row>
        <Col sm={5} md={5} lg={6}></Col>
        <Col sm={2} md={2} lg={3}>
          <label>
            <strong>F2F Hours</strong>
          </label>
        </Col>
        <Col sm={2} md={2} lg={3}>
          <label>
            <strong>Non-F2F Hours</strong>
          </label>
        </Col>
      </Row>
      {caseDayClaims.map((day, index) => (
        <Row key={day.careDay}>
          <Col sm={5} md={5} lg={6}>
            {showAddTolerance ? (
              <Select
                className="mb-1"
                value={day.careDay}
                options={dayOptions.filter(
                  (dayOption) =>
                    !caseDayClaims.map(({ careDay }) => careDay).includes(dayOption.value as DayOfCare) ||
                    (dayOption.value as DayOfCare) === day.careDay
                )}
                name={`caseDayClaims[${index}]`}
                onChange={(value: any) => {
                  handleChangeDay(index, value.value);
                }}
              />
            ) : (
              <TextInput name={`care-day[${index}]`} disabled value={day.careDay}></TextInput>
            )}
          </Col>
          <Col sm={2} md={2} lg={3}>
            <NumberInput
              name={`faceToFaceHours[${index}]`}
              value={day.faceToFaceHours}
              min="0"
              max="24"
              onChange={(value, name) => {
                handleDayOfCareHoursChange(value, name, index);
              }}
            />
          </Col>
          <Col sm={2} md={2} lg={3}>
            <NumberInput
              name={`nonFaceToFaceHours[${index}]`}
              value={day.nonFaceToFaceHours}
              min="0"
              max="24"
              onChange={(value, name) => {
                handleDayOfCareHoursChange(value, name, index);
              }}
            />
          </Col>
        </Row>
      ))}

      {showAddTolerance ? (
        <Button variant="light" className="float-right" onClick={revertDaysOfCare}>
          Revert back
        </Button>
      ) : (
        <Button variant="secondary" className="float-right" onClick={() => setAddTolerance(true)}>
          Add Tolerance Day
        </Button>
      )}
    </>
  );
};

export default CaseDayFields;
