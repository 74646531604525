import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import Switch from 'shared/components/Switch';
import {
  billingFrequencyOptions,
  billingPeriodOptions,
  dayOfMonthOptions,
  dayOfWeekOptions,
} from 'shared/constants/enums/billingCycleOptions';
import { getChargeDayValue } from '../Settings/Tabs/Cycles/utils';

interface IProps {
  isOpen: boolean;
  isSaving: boolean;
  onClose: () => void;
  onSave: (formData: ICenterStatementFormData) => void;
  centerStatementSchedule?: ICenterStatementSchedule;
}

const emptyForm: ICenterStatementFormData = {
  isEnabled: false,
  frequency: 'WEEKLY',
  message: '',
  dayOfWeek: 'MONDAY',
  dayOfMonth: undefined,
  periodBasis: 'CURRENT_WEEK',
  periodWeeksPrior: 1,
  periodWeeksAfter: 1,
  startDate: moment(new Date()).format('YYYY-MM-DD'),
};

const statementSchedulePeriodOptions: readonly Readonly<{ value: number; label: string }>[] = [
  { value: 0, label: '0 weeks' },
  { value: 1, label: '1 week' },
  { value: 2, label: '2 weeks' },
  { value: 3, label: '3 weeks' },
  { value: 4, label: '1 month' },
];

const ScheduleStatementRunModalForm: React.FC<IProps> = ({
  isOpen,
  isSaving,
  onClose,
  onSave,
  centerStatementSchedule,
}) => {
  const [formData, setFormData] = useState<ICenterStatementFormData>({ ...emptyForm });

  const isFormValid = useMemo(() => {
    return (
      (!!formData.dayOfMonth || !!formData.dayOfWeek) &&
      !!formData.frequency &&
      formData.periodBasis &&
      statementSchedulePeriodOptions.some(({ value }) => value === formData.periodWeeksAfter) &&
      statementSchedulePeriodOptions.some(({ value }) => value === formData.periodWeeksPrior) &&
      !!formData.startDate
    );
  }, [formData]);

  useEffect(() => {
    if (!!centerStatementSchedule)
      setFormData({
        isEnabled: centerStatementSchedule.isEnabled,
        frequency: centerStatementSchedule.frequency,
        message: centerStatementSchedule.message ?? '',
        dayOfWeek: centerStatementSchedule.dayOfWeek,
        dayOfMonth: centerStatementSchedule.dayOfMonth,
        periodBasis: centerStatementSchedule.periodBasis,
        periodWeeksPrior: centerStatementSchedule.periodWeeksPrior,
        periodWeeksAfter: centerStatementSchedule.periodWeeksAfter,
        startDate: moment(centerStatementSchedule.startDate ?? new Date()).format('YYYY-MM-DD'),
      });
  }, [centerStatementSchedule]);

  const isWeekly = formData.frequency?.includes('WEEK');
  const isMonthly = formData.frequency === 'MONTHLY' || formData.frequency === 'QUARTERLY';

  return (
    <SideModalDrawer
      title="Edit a Schedule"
      show={isOpen}
      onHide={onClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save Changes"
      primaryCallback={() => onSave(formData)}
      primaryButtonProps={{ loading: isSaving, disabled: !isFormValid }}
      secondaryButtonProps={{ disabled: isSaving }}
    >
      <div>
        <Row>
          <Row>
            <Col md={6} className="ml-4">
              <label>Start Date</label>
              <DateInput
                required={false}
                date={formData.startDate}
                onDateSelect={(date: string) => setFormData({ ...formData, startDate: date })}
                disabled={false}
              />
            </Col>
            <Col md={5}>
              <Switch
                label="Enable?"
                labelSide="top"
                value={formData.isEnabled}
                onChange={(value: boolean) => setFormData({ ...formData, isEnabled: value })}
              />
            </Col>
          </Row>

          <Col md={12} className="mb-2">
            <hr />
            <div className="mb-4">I want to send statements on...</div>
            <Row className="mb-4">
              <Select
                className="mb-0 mx-2 flex-grow-0"
                options={
                  formData.frequency ? (formData.frequency.includes('WEEK') ? dayOfWeekOptions : dayOfMonthOptions) : []
                }
                value={getChargeDayValue(formData) ?? null}
                onChange={(option) =>
                  setFormData({
                    ...formData,
                    dayOfWeek: isWeekly ? option.value : null,
                    dayOfMonth: isMonthly ? option.value : null,
                  })
                }
                placeholder="day"
                noOptionsMessage={() => 'Select a frequency to see options'}
              />{' '}
              of, every
              <Select
                className="mb-0 mx-2 flex-grow-0"
                options={billingFrequencyOptions}
                value={formData.frequency ?? null}
                onChange={(option) =>
                  setFormData({
                    ...formData,
                    frequency: option.value,
                    dayOfWeek: undefined,
                    dayOfMonth: undefined,
                  })
                }
                placeholder="frequency"
              />
            </Row>
          </Col>
          <Col md={12} className="mb-6 ml-2">
            <Row>
              including
              <Select
                className="mb-0 mx-2 flex-grow-0"
                options={statementSchedulePeriodOptions}
                onChange={(option) => setFormData({ ...formData, periodWeeksPrior: option.value })}
                value={statementSchedulePeriodOptions.find((opt) => opt.value === formData.periodWeeksPrior) ?? null}
                placeholder="Period Prior"
              />
              prior to the
            </Row>
          </Col>
          <Col md={12} className="mb-2">
            <Row className="mb-2">
              <Select
                className="mb-0 mx-2 flex-grow-0"
                options={billingPeriodOptions}
                value={formData.periodBasis ?? null}
                onChange={(option) => setFormData({ ...formData, periodBasis: option.value })}
                placeholder="Period Basis"
              />{' '}
              and
              <Select
                className="mb-0 mx-2 flex-grow-0"
                options={statementSchedulePeriodOptions}
                onChange={(option) => setFormData({ ...formData, periodWeeksAfter: option.value })}
                value={statementSchedulePeriodOptions.find((opt) => opt.value === formData.periodWeeksAfter) ?? null}
                placeholder="Period After"
              />{' '}
              after
            </Row>
          </Col>
        </Row>
      </div>
    </SideModalDrawer>
  );
};

export default ScheduleStatementRunModalForm;
