import React from 'react';
import FileDropbox from './FileDropbox';
import SelectedFile from './SelectedFile';

interface IProps {
  className?: string;
  onChange?: (files: File[]) => void;
  value?: File[];
  showFiles?: boolean;
  acceptedFileExts?: string;
  multiple?: boolean;
}

const FileSelectDropbox: React.FC<IProps> = ({
  className,
  onChange = () => {},
  value = [],
  showFiles = true,
  multiple = true,
  acceptedFileExts,
}) => {
  const appendChange = (files: File[]) => onChange([...value, ...files.filter((f) => f.size > 0)]);
  const onDrop = (acceptedFiles: File[]) => appendChange(acceptedFiles);
  const onFilesAdded = (e: React.ChangeEvent<HTMLInputElement>) => appendChange(Array.from(e?.target?.files ?? []));

  const onFileRemoved = (fileName: string) => onChange(value.filter((f: IFile) => !(f.name === fileName)));

  return (
    <>
      <FileDropbox
        multiple={multiple}
        className={className}
        onFilesAdded={onFilesAdded}
        onDrop={onDrop}
        acceptedFileExts={acceptedFileExts}
      />
      <div className={`d-flex flex-row align-items-center flex-wrap ${className || ''}`}>
        {showFiles &&
          value.map((f, i) => {
            return <SelectedFile file={f} key={i} onDeleteClick={onFileRemoved} updateContentsCb={() => {}} />;
          })}
      </div>
    </>
  );
};

export default FileSelectDropbox;
