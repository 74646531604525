import React from 'react';
import { Badge } from 'react-bootstrap';

import * as Mui from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faEye, faPlayCircle } from '@fortawesome/pro-solid-svg-icons';

import Card from 'shared/components/Card';
import { HorizontalDivider } from 'shared/components/Dividers';
import { getFullName } from 'shared/util/string';
import _ from 'lodash';
import useFormatDate from 'shared/hooks/useFormatDate';

interface IProps {
  photo: IPhoto;
}

const GalleryItem: React.FC<IProps> = ({ photo }) => {
  const formatDate = useFormatDate();
  const [viewPhotoModal, setViewPhotoModal] = React.useState(false);
  return (
    <>
      <Card bodyClassName={`p-2`} style={{ width: 250 + 16 }}>
        <div className="triangle-top-right">
          <Mui.IconButton onClick={() => setViewPhotoModal(true)} style={{ position: 'absolute', top: 0, right: 0 }}>
            <FontAwesomeIcon icon={photo.isVideo ? faPlayCircle : faEye} size="sm" color={colors['secondary']} />
          </Mui.IconButton>
          {photo.isVideo ? (
            <img
              style={{ objectFit: 'cover' }}
              alt={`${photo.createdAt}-${photo.note}`}
              src={
                _.isNil(photo.thumbnailKeySignedUrl)
                  ? 'https://img.myloview.com/stickers/social-media-video-icon-outline-social-media-video-vector-icon-for-web-design-isolated-on-white-background-700-247279065.jpg'
                  : photo.thumbnailKeySignedUrl
              }
              height={250}
              width={250}
            />
          ) : (
            <img
              style={{ objectFit: 'cover' }}
              alt={`${photo.createdAt}-${photo.note}`}
              src={_.isNil(photo.thumbnailKeySignedUrl) ? photo.signedurl : photo.thumbnailKeySignedUrl}
              height={250}
              width={250}
            />
          )}
        </div>

        <label className="font-weight-semi-bold">Tagged Children:</label>
        {photo.children != null &&
          photo.children.length > 0 &&
          _.sortBy(photo.children, 'firstname').map((child) => <p key={child.childId}>{getFullName(child.child)}</p>)}
        <HorizontalDivider my={3} />
        <div className="d-flex align-items-center mb-1">
          <label className="font-weight-semi-bold mb-0">Note:</label>
        </div>
        <div className="sm mb-4">{photo.note}</div>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <div className="mb-0">
            <Badge variant="info">{formatDate(photo.takenAt)}</Badge>
          </div>
          <div className="mb-0">
            <Badge variant="success">{getFullName(photo.takenBy)}</Badge>
          </div>
        </div>
      </Card>
      <Mui.Dialog open={viewPhotoModal} onClose={() => setViewPhotoModal(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          View {photo.isVideo ? `Video` : `Photo`}
          <Mui.IconButton onClick={() => setViewPhotoModal(false)}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent style={{ marginTop: '20px', textAlign: 'center' }}>
          {photo.isVideo ? (
            <video
              id="my-video"
              className="video-js"
              controls
              width={500}
              poster={
                _.isNil(photo.thumbnailKeySignedUrl)
                  ? 'https://img.myloview.com/stickers/social-media-video-icon-outline-social-media-video-vector-icon-for-web-design-isolated-on-white-background-700-247279065.jpg'
                  : photo.thumbnailKeySignedUrl
              }
              data-setup="{}"
            >
              <source src={photo.signedurl} type="video/mp4" />
              <p className="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank">
                  supports HTML5 video
                </a>
              </p>
            </video>
          ) : (
            <img
              style={{ objectFit: 'cover' }}
              alt={`${photo.createdAt}-${photo.note}`}
              src={photo.signedurl}
              width={500}
            />
          )}
          <div style={{ margin: 'auto' }}>
            <a style={{ float: 'right' }} href={photo.signedDownloadUrl}>
              Download {photo.isVideo ? `Video` : `Photo`}
            </a>
          </div>
        </Mui.DialogContent>
      </Mui.Dialog>
    </>
  );
};

export default GalleryItem;
