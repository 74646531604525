import React from 'react';
interface IProps {
  data: IISInnovativeCasesForCenters;
}

const ISInnovativeSolutionsDetailsDescription: React.FC<IProps> = ({ data }) => {
  const { shortDescription } = data;
  return (
    <div>
      <div>
        <table id="case-details-table" className="table-simple mr-12">
          <colgroup>
            <col span={1} style={{ width: '30%' }} />
            <col span={1} style={{ width: '70%' }} />
          </colgroup>
          <tbody>
            <tr>
              <td>Short Description</td>
              <td className="text-right">{shortDescription}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ISInnovativeSolutionsDetailsDescription;
