import classNames from 'classnames';
import React from 'react';
import Button from 'shared/components/Buttons';
import { TableSearch } from 'shared/components/DataTable';

export const attendanceStatuses: AttendanceStatus[] = [
  'Expected',
  'Unknown',
  'Late',
  'Absent',
  'Checked In',
  'Checked Out',
];
export const attendanceStatusesColors: Record<AttendanceStatus, string> = {
  Expected: 'text-color',
  Unknown: 'info',
  Late: 'danger',
  Absent: 'warning',
  'Checked In': 'success',
  'Checked Out': 'slate',
};

interface IProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  statusFilter?: AttendanceStatus;
  setStatusFilter?: (status: AttendanceStatus) => void;
  currentDayAttendanceByStatus: Record<AttendanceStatus, ISession[]>;
  showStatusFilters: boolean;
}

interface IStatusFilterProps {
  statusFilter?: AttendanceStatus;
  setStatusFilter?: (status: AttendanceStatus) => void;
  currentDayAttendanceByStatus: Record<AttendanceStatus, ISession[]>;
  className?: string;
}

export const StatusFilter = ({
  currentDayAttendanceByStatus,
  statusFilter,
  setStatusFilter,
  className = '',
}: IStatusFilterProps) => {
  return (
    <div className={`d-flex flex-wrap ${className}`}>
      {attendanceStatuses.map((status) => (
        <Button
          key={status}
          className={classNames(`mr-2 mb-2 text-gray border btn-flex-fill flex-grow-0`, {
            [`border-${statusFilter === status ? 'secondary' : 'pale-gray'}`]: Boolean(statusFilter),
          })}
          variant="outline-light"
          onClick={() => setStatusFilter && setStatusFilter(status)}
          style={{ pointerEvents: `${setStatusFilter ? 'auto' : 'none'}` }}
        >
          <div className="d-flex flex-nowrap">
            <div style={{ whiteSpace: 'nowrap' }} className="sm mr-8">
              {status}
            </div>
            <h6 className={`mb-0 text-${attendanceStatusesColors[status]}`}>
              {currentDayAttendanceByStatus[status].length}
            </h6>
          </div>
        </Button>
      ))}
    </div>
  );
};

const AttendanceSearchAndFilters: React.FC<IProps> = ({
  searchTerm,
  setSearchTerm,
  statusFilter,
  setStatusFilter,
  currentDayAttendanceByStatus,
  showStatusFilters,
}) => {
  return (
    <div className="bg-white px-4 pt-4 pb-2 d-flex border-bottom flex-wrap">
      <div className="mr-auto">
        <TableSearch
          className="max-width-200 mr-2 mb-2"
          onChange={setSearchTerm}
          placeholder="Search"
          value={searchTerm}
          enableDebounce={false}
        />
      </div>
      {showStatusFilters && (
        <StatusFilter
          currentDayAttendanceByStatus={currentDayAttendanceByStatus}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
        />
      )}
    </div>
  );
};

export default AttendanceSearchAndFilters;
