import { addressFields } from 'gql/address/fields';
import { operationHoursFields } from 'gql/operationHours/fields';

export const GET_CENTER_PROFILE: string = `
  id
  entityId
  entity {
    id
    dataType
  }
  name
  phoneNumber
  deactivatedAt
  deletedAt
  serviceType
  tags {
    id
    name
    category
  }
  avatar {
    url
  }
  logo {
    url
  }
  address {
    ${addressFields}
  }
  timezone
  primaryContactPersonId
  primaryContact {
    id
    firstname
    lastname
    email
    avatar {
      url
    }
    roleship {
      name
    }
  }
  secondaryContactPersonId
  secondaryContact {
    id
    firstname
    lastname
    email
    avatar {
      url
    }
    roleship {
      name
    }
  }
  spaces {
    id
    name
    color
    icon
    centerId
  }
  staffOperationHours {
    ${operationHoursFields}
  }
  familyOperationHours {
    ${operationHoursFields}
  }
  inviteCode
  merchant {
    id
    processor
    merchantId
    isSandbox
  }
  openingDate
  closingDate
  website
  email
  acn
  abn
  externalId
  taxId
  defaultBillingCycleTemplateId
  centerEnrollmentBillingCycleTemplates {
    id
    centerId
    billingCycleTemplateId
  }
  enableBillingCycleProcessing
  billingEnabledSettings {
    centerId
    enableBillingCycleProcessing
    enableBillingCycleProcessingEffectiveDate
    billingEnabledAt
    billingEnabledBy
    billingEnabledByPerson {
      id
      firstname
      nickname
      lastname
    }
  }
`;

export const GET_CENTER_SPACES: string = `
  id
  name
  color
  icon
  centerId
  center {
    id
    name
  }
`;

export const GET_CLASSES_FOR_CENTER: string = `
  id
  name
  defaultSpaceId
`;
