import Auth from '@aws-amplify/auth';
import { put, takeLatest } from 'redux-saga/effects';
import * as types from './types';
import * as actions from './actions';

function* verifyEmail() {
  try {
    yield Auth.verifyCurrentUserAttribute('email');
    yield put(actions.verifyEmailSuccess());
  } catch (error) {
    yield put(actions.verifyEmailFailure(error));
  }
}

function* submitVerifyEmail({ code }) {
  try {
    yield Auth.verifyCurrentUserAttributeSubmit('email', code);
    yield put(actions.submitVerifyEmailSuccess());
  } catch (error) {
    yield put(actions.submitVerifyEmailFailure(error));
  }
}

export const accountSagas = [
  takeLatest(types.VERIFY_EMAIL_REQUEST, verifyEmail),
  takeLatest(types.SUBMIT_VERIFY_EMAIL_REQUEST, submitVerifyEmail),
];
