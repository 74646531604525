import React, { useCallback, useMemo } from 'react';
import RadioButton from 'shared/components/RadioButton';
import { ICustomFieldProps } from '../CustomField';
import { parseOptions } from '../utils';
import './style.scss';

const Radio: React.FC<ICustomFieldProps> = ({ customField, value, isReadOnly, disabled, onChange }) => {
  const handleSelect = useCallback((value: boolean, optionName: string) => {
    onChange(optionName, customField.id);
  }, []);

  const options = parseOptions(customField.attributesAsString);
  const internalValue = useMemo(() => {
    if (value === 'Y') {
      return 'Yes';
    }

    if (value === 'N') {
      return 'No';
    }

    return value;
  }, [value, options]);

  return (
    <div className="mb-2">
      <label>{customField.label}</label>
      {options.map((option, index) => {
        const label = typeof option === 'string' ? option : option.label;
        return (
          <RadioButton
            className="pt-0 custom-field-radio"
            label={label}
            key={index}
            disabled={isReadOnly || disabled}
            value={option === internalValue}
            onChange={(value) => handleSelect(value, label)}
          />
        );
      })}
    </div>
  );
};
export default Radio;
