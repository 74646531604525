export const getChannelsFields = `
    pageNumber
    pageSize
    totalRecords
    data {
        messageId
        message
        isMessageDeleted
        isMessageUnread
        activity{
            activityId
            createdAt
            person {
                id
                firstname
                lastname
                type
                profileUrl
            }
        }
        userAcknowledgements{
            parentUserActivityId
            userActivityId
            person{
                id
                name
                isStaff
            }
            createdAt
        }
        flaggedMessages{
            parentUserActivityId
            userActivityId
            person {
                id
                name
            }
            createdAt
            isResolved
            resolvedAt
            resolvedPerson
            {
                id
                name
            }
        }
    }
`;

export const getStudentChannelsFields = `
    pageNumber
    pageSize
    totalRecords
    data {
        businessId
        centerGroup
        centerId
        channelType
        channelId
        channelIdentifier
        name
        description
        lastActive
        personId
        lastAccessedByPersonId
        hasUnreadMessages
        account {
            id
            entityId
            centerId
            name
            status
            tags
        }
        accountStatus
        accountChild {
            account{
                name
            }
            avatar{
                url
            }
            firstname
            lastname
            nickname
            id
        }
        statistics
        {
            channelId
            unreadCount
            flaggedCount
            dailyAverage
            totalCount
        }
    }
`;
