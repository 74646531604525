import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import CrnInput from 'shared/components/CrnInput';
import DateInput from 'shared/components/DateInput';
import { VerticalDivider } from 'shared/components/Dividers';
import Col from 'shared/components/Layout/Col';
import Row from 'shared/components/Layout/Row';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import { isBlank } from 'shared/util/string';
import { isDateRangeInvalid } from 'shared/util/timeUtils';
import { useAddChildCcsEnrolment } from 'gql/ccssEnrolment/mutations';
import Checkbox from 'shared/components/Checkbox';
import { ccsTypeEnum2, peaReasonOptions } from 'shared/constants/enums/ccssEnums';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { ApolloError } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { isEnrolmentEndDateInvalid, useGetPeaWeeksRemaining } from './CustomHook';
import { useGetIsValidArrangement } from './CustomHook';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  account: IAccount;
  accountContracts: IContract[];
  selectChildId?: string;
}

const newEnrolment: IAddCcsEnrolmentInput = {
  businessId: '',
  accountId: '',
  centerId: '',
  startDate: '',
  endDate: '',
  contactId: '',
  childId: '',
  signingParty: {
    firstName: '',
    lastName: '',
  },
  ccsType: 'CWA',
  casualSessionReporting: false,
  routineSessionReporting: false,
  lateSubmissionReason: '',
  childInStateCare: false,
};

const CreateCcsEnrolmentModal2: React.FC<IProps> = ({ isOpen, onClose, account, accountContracts, selectChildId }) => {
  const { t } = useTranslation(['accounts']);
  const [enrolment, setEnrolment] = useState({
    ...newEnrolment,
    childId: selectChildId ?? '',
  });
  const [isSubmissionLate, setIsSubmissionLate] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedChildErrorText, setSelectedChildErrorText] = useState('');

  const [addChildCcsEnrolment, { loading }] = useAddChildCcsEnrolment();

  const peaWeeksRemaining = useGetPeaWeeksRemaining({
    accountId: account.id,
    centerId: account.centerId,
    childIds: [enrolment.childId],
  });

  const isValidArrangement = useGetIsValidArrangement({
    accountId: account.id,
    centerId: account.centerId,
    childId: enrolment.childId,
    arrangementType: enrolment.ccsType,
  });

  const isEndDateInvalid = (day: any) => {
    return isEnrolmentEndDateInvalid(
      enrolment.startDate,
      day,
      isPeaCcsType && enrolment.reasonForPea === 'FOSKIN',
      peaWeeksRemaining ?? 0
    );
  };

  const handleClose = useCallback(() => {
    onClose();
    setEnrolment(newEnrolment);
  }, [onClose]);

  const handleContinue = useCallback(() => {
    enrolment.businessId = account.entityId;
    enrolment.accountId = account.id;
    enrolment.centerId = account.centerId;
    addChildCcsEnrolment({
      variables: {
        input: enrolment,
      },
    })
      .then(() => {
        showToast('Child CCS enrolment added successfully', 'success');
        handleClose();
      })
      .catch((err: Error) => {
        const message = err instanceof ApolloError ? getApolloErrorMessage(err) : err.message;
        showToast(message, 'error');
      });
  }, [account.centerId, account.entityId, account.id, addChildCcsEnrolment, enrolment, handleClose]);

  const handleChange = useCallback(
    (value, name) => {
      setEnrolment({ ...enrolment, [name]: value });
    },
    [enrolment]
  );

  const handleContactChange = (value: IContact) => {
    setEnrolment({
      ...enrolment,
      contactId: value.id,
      ...(enrolment.ccsType !== 'PEA' && {
        // No signing party for PEA, so don't populate
        signingParty: {
          ...enrolment.signingParty,
          firstName: value.firstname,
          lastName: value.lastname,
        },
      }),
    });
  };

  const handleChildChange = (child: IChild) => {
    handleChange(child.id, 'childId');
  };

  const handleSigningPartyChange = (value: string, name: string) => {
    setEnrolment({
      ...enrolment,
      signingParty: { ...enrolment.signingParty, [name]: value },
    });
  };

  const handleCcsTypeChange = useCallback(
    (value, name) => {
      const { ccsType } = { ...enrolment };
      // if we are not in OA type and we go to it, we need to clear first and last name
      if (ccsType !== 'OA' && value === 'OA') {
        enrolment.signingParty.firstName = '';
        enrolment.signingParty.lastName = '';
      } else if (ccsType === 'OA' && value !== 'OA') {
        // if we are in OA type and we go to something else, we clear the organisation name
        enrolment.signingParty.organisationName = '';
      } else if (value === 'PEA') {
        // No signing party for PEA
        enrolment.signingParty.firstName = '';
        enrolment.signingParty.lastName = '';
      }

      setEnrolment({ ...enrolment, [name]: value });
    },
    [enrolment]
  );

  const selectedContact = useMemo(
    () => account?.contacts?.find((g) => g.id === enrolment.contactId),
    [account, enrolment.contactId]
  );

  const selectedChild = useMemo(
    () => account?.children?.find((c) => c.id === enrolment.childId),
    [account, enrolment.childId]
  );

  const showOrganistationName = enrolment.ccsType === 'OA';
  const isPeaCcsType = enrolment.ccsType === 'PEA';

  const handleStartDateChanged = (value: string) => {
    handleChange(value, 'startDate');

    // the government prescribes "late submission" as being 7 days after the sunday
    // of the arrangement start date
    var lateSubmissionCutOff = moment(value).endOf('week').add(8, 'days');
    setIsSubmissionLate(moment().isAfter(lateSubmissionCutOff, 'date'));
  };

  const isValidOa = useCallback((): boolean => {
    return !!enrolment.signingParty.organisationName;
  }, [enrolment.signingParty.organisationName]);

  const isValidCWA = useCallback((): boolean => {
    return !!enrolment.signingParty.firstName && !!enrolment.signingParty.lastName;
  }, [enrolment.signingParty.firstName, enrolment.signingParty.lastName]);

  const isValidRA = useCallback((): boolean => {
    return !!enrolment.signingParty.firstName && !!enrolment.signingParty.lastName;
  }, [enrolment.signingParty.firstName, enrolment.signingParty.lastName]);

  const isValidPEA = useCallback((): boolean => {
    if (!enrolment.reasonForPea) return false;

    if (moment(enrolment.endDate).isSameOrBefore(moment('2021-06-30'), 'day')) return false;

    if (enrolment.reasonForPea === 'FOSKIN' && (!enrolment.endDate || isBlank(enrolment?.endDate))) return false;

    const hasExceeded13Weeks =
      enrolment.reasonForPea === 'NOCARE'
        ? false // Do not calculate if reason is NOCARE
        : moment(enrolment.endDate).isAfter(moment(enrolment.startDate).add(peaWeeksRemaining, 'weeks'));
    if (hasExceeded13Weeks) return false;

    return true;
  }, [enrolment, peaWeeksRemaining]);

  const isValidChild = useCallback((): boolean => {
    if (!enrolment.childId) return false;

    const childContracts = accountContracts.filter((c) => c.childId == enrolment.childId);
    if (!childContracts || childContracts.length === 0) {
      setSelectedChildErrorText('Please set up a contract for the selected child');
      return false;
    }

    setSelectedChildErrorText('');
    return true;
  }, [accountContracts, enrolment.childId]);

  const validateFormFields = useCallback((): boolean => {
    if (!isValidChild()) return false;

    // Contact may not be known if type is PEA
    if (!isPeaCcsType && !enrolment.contactId) return false;

    if (isBlank(enrolment.startDate)) return false;

    if (isDateRangeInvalid(enrolment.startDate, enrolment.endDate)) return false;

    if (moment(enrolment.startDate).isAfter(moment().endOf('day'))) return false;

    if (isSubmissionLate && !enrolment.lateSubmissionReason) return false;

    if (selectedChildErrorText) return false;

    if (isValidArrangement) return false;

    let ccsTypeIsValid = false;
    switch (enrolment.ccsType) {
      case 'CWA':
        ccsTypeIsValid = isValidCWA();
        break;

      case 'RA':
        ccsTypeIsValid = isValidRA();
        break;

      case 'OA':
        ccsTypeIsValid = isValidOa();
        break;

      case 'PEA':
        ccsTypeIsValid = isValidPEA();
        break;
    }

    return ccsTypeIsValid;
  }, [
    isValidChild,
    isPeaCcsType,
    enrolment.contactId,
    enrolment.startDate,
    enrolment.endDate,
    enrolment.lateSubmissionReason,
    enrolment.ccsType,
    isSubmissionLate,
    selectedChildErrorText,
    isValidArrangement,
    isValidCWA,
    isValidRA,
    isValidOa,
    isValidPEA,
  ]);

  useEffect(() => {
    setIsFormValid(validateFormFields());
  }, [enrolment, validateFormFields]);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <CenteredModal
      size="lg"
      show={isOpen}
      onHide={handleClose}
      title="Create CCS Enrolment"
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryChoice="Continue"
      primaryCallback={handleContinue}
      primaryButtonProps={{ disabled: !isFormValid, loading }}
    >
      <Form>
        <Row align="start">
          <Col>
            <Row>
              <Col>
                <TextInput label={`${fieldLabels.center} Name`} value={account?.center?.name ?? ''} disabled={true} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DateInput
                  label="Start Date"
                  date={enrolment.startDate}
                  isOutsideRange={(day) => day.isAfter(moment().endOf('day'))}
                  isValid={!moment(enrolment.startDate).isAfter(moment().endOf('day'))}
                  onDateSelect={(startDate: string) => handleStartDateChanged(startDate)}
                  required={true}
                />
              </Col>
              <Col>
                <DateInput
                  label="End Date"
                  date={enrolment.endDate}
                  isOutsideRange={(day) => isEndDateInvalid(day)}
                  onDateSelect={(endDate: string) => handleChange(endDate, 'endDate')}
                  isValid={!isEndDateInvalid(enrolment.endDate)}
                  required={enrolment.ccsType === 'PEA' && enrolment.reasonForPea === 'FOSKIN'}
                />
              </Col>
            </Row>
            {isSubmissionLate && (
              <Row>
                <Col>
                  <TextInput
                    name="lateSubmissionReason"
                    label="Late Reason"
                    required
                    value={enrolment.lateSubmissionReason}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            )}
            <h5>Contact Details</h5>
            <Row>
              <Col>
                <Select
                  label="Contact Name"
                  required={!isPeaCcsType}
                  placeholder="Select Contact..."
                  options={account?.contacts?.filter((i) => i.isPrimary) ?? []}
                  getOptionLabel={(g) =>
                    `${g.firstname} ${g.lastname} (${g.ccssAttributes.crn ?? t('accounts:ccs.ccs-tab.crn-not-found')})`
                  }
                  onChange={(value) => handleContactChange(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-4">
                <DateInput label="D.O.B." placeholder="" date={selectedContact?.dob} disabled={true} />
              </Col>
              <Col>
                <CrnInput label="CRN" value={selectedContact?.ccssAttributes?.crn} disabled={true} />
              </Col>
            </Row>
            <h5>Child Details</h5>
            <Row>
              <Col>
                <Select
                  label="Child Name"
                  required
                  placeholder="Select Child..."
                  options={account?.children}
                  getOptionLabel={(c) => `${c.firstname} ${c.lastname}`}
                  value={selectedChild}
                  onChange={(value) => handleChildChange(value)}
                  showErrorMessage
                  errorText={selectedChildErrorText}
                  isInvalid={!!selectedChildErrorText}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-4">
                <DateInput label="D.O.B." placeholder="" date={selectedChild?.dob} disabled={true} />
              </Col>
              <Col>
                <CrnInput label="CRN" value={selectedChild?.ccssAttributes?.crn} disabled={true} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Checkbox
                  label="Child in State Care?"
                  value={enrolment.childInStateCare}
                  onChange={(checked: boolean) => handleChange(checked, 'childInStateCare')}
                />
              </Col>
            </Row>
          </Col>
          <VerticalDivider />
          <Col>
            <Row>
              <Col>
                <h5>CCS Type</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                {ccsTypeEnum2.map((c) => (
                  <Form.Check key={c.value} className="d-flex align-items-top mb-2">
                    <Form.Check.Input
                      id={c.value}
                      type="radio"
                      name="ccs-type-option"
                      checked={enrolment.ccsType === c.value}
                      onChange={() => handleCcsTypeChange(c.value, 'ccsType')}
                    />
                    <Form.Check.Label htmlFor={c.value}>
                      {c.label}{' '}
                      {isValidArrangement && enrolment.ccsType === c.value && (
                        <div className="text-danger small">
                          This child has an enrolment of type {c.value} already in use
                        </div>
                      )}
                    </Form.Check.Label>
                  </Form.Check>
                ))}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <h5>Signing Party</h5>
              </Col>
            </Row>
            {!showOrganistationName && (
              <Row>
                <Col>
                  <TextInput
                    name="firstName"
                    label="First Name"
                    value={enrolment.signingParty.firstName}
                    onChange={handleSigningPartyChange}
                    disabled={isPeaCcsType}
                  />
                </Col>
                <Col>
                  <TextInput
                    name="lastName"
                    label="Last Name"
                    value={enrolment.signingParty.lastName}
                    onChange={handleSigningPartyChange}
                    disabled={isPeaCcsType}
                  />
                </Col>
              </Row>
            )}
            {showOrganistationName && (
              <Row>
                <TextInput
                  name="organisationName"
                  label="Organisation Name"
                  required
                  value={enrolment.signingParty.organisationName}
                  onChange={handleSigningPartyChange}
                />
              </Row>
            )}
            {isPeaCcsType && (
              <Row>
                <Col>
                  <Select
                    label="Reason for PEA"
                    options={peaReasonOptions}
                    value={enrolment.reasonForPea}
                    onChange={(value) => handleChange(value.value, 'reasonForPea')}
                    required={true}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    </CenteredModal>
  );
};

export default CreateCcsEnrolmentModal2;
