import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { emptyChild } from '../../../duck/reducers';
import { useGetChildImmunizations } from 'gql/child/queries';
import ImmunizationInformationCard from './ImmunizationInformationCard';
import ImmunizationsTable from './ImmunizationsTable';
import DeleteImmunizationModal from './DeleteImmunizationModal';
import AddOrEditImmmunizationModal from './AddOrEditImmmunizationModal';

interface IImmunizationModalState {
  open: boolean;
  immunization: IImmunization | null;
}

interface IProps {
  childId: string;
  timezone: string;
}

const ImmunizationsTab: React.FC<IProps> = ({ childId, timezone, ...props }) => {
  const { immunizations } = useSelector((state: RootState) => state.children.byId[childId]) ?? emptyChild;

  const { loading } = useGetChildImmunizations(childId);
  const [deleteImmunizationModalState, setDeleteImmunizationModalState] = useState<IImmunizationModalState>({
    open: false,
    immunization: null,
  });
  const [immunizationInEdit, setImmunizationInEdit] = useState<IImmunization | null>(null);

  return (
    <>
      <ImmunizationInformationCard />
      <h5>Immunization Records</h5>
      <ImmunizationsTable
        timezone={timezone}
        loading={loading}
        immunizations={immunizations}
        onEdit={setImmunizationInEdit}
        onDelete={(immunization) => setDeleteImmunizationModalState({ open: true, immunization })}
      />
      <DeleteImmunizationModal
        isOpen={deleteImmunizationModalState.open}
        immunization={deleteImmunizationModalState.immunization}
        onClose={() => setDeleteImmunizationModalState({ open: false, immunization: null })}
      />
      {immunizationInEdit && (
        <AddOrEditImmmunizationModal
          timezone={timezone}
          childId={childId}
          immunization={immunizationInEdit}
          onClose={() => setImmunizationInEdit(null)}
          isOpen={Boolean(immunizationInEdit)}
        />
      )}
    </>
  );
};

export default ImmunizationsTab;
