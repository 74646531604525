import moment from 'moment';
import DataTable from 'shared/components/DataTable';

interface IProps {
  programChildren: IProgramChild[];
  startDate: string;
  startStrong: boolean;
}

function ProgramChildTable({ programChildren, startDate, startStrong }: IProps) {
  const columns = [
    {
      text: 'Child',
      dataField: 'child.fullName',
    },
    {
      text: 'Account',
      dataField: 'accountName',
    },
    {
      text: 'Date of Birth',
      dataField: 'child.dob',
      formatter: (dob: string | undefined) => (dob ? moment(dob).format('DD-MM-YY') : ''),
    },
  ];

  if (startStrong) {
    const year = moment(startDate).year();
    const ageEpoch = moment(`${year}-07-31`);

    columns.push({
      text: 'Fee Relief',
      dataField: 'child.dob',
      formatter: (dob: string | undefined) => {
        const yearsOld = Math.trunc(ageEpoch.diff(dob, 'years', true));

        if (year > 2023 && yearsOld === 3) return '3YO';
        if (yearsOld >= 4) return '4YO+';

        return '';
      },
    });
  }

  return <DataTable columns={columns} data={programChildren} showPagination={false} showSelect={false} />;
}

export default ProgramChildTable;
