import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { programChildFields, pagedProgramChildrenFields, newProgramChildFields } from './fields';

const GET_PROGRAM_CHILDREN_BY_ACCOUNT = gql`
  query($input: GetProgramsByAccountIdInput!) {
    getProgramsByAccountId(input: $input) {
      ${programChildFields}
    }
  }
`;

const GET_NEW_PROGRAM_CHILDREN_BY_ACCOUNT = gql`
  query($input: GetProgramsByAccountIdInput!) {
    getForNewProgramByAccountId(input: $input) {
      ${newProgramChildFields}
    }
  }
`;

const GET_PROGRAM_CHILDREN = gql`
  query($input: GetProgramChildrenInput!) {
    getProgramChildren(input: $input) {
      ${pagedProgramChildrenFields}
    }
  }
`;

interface IGetAccountProgram {
  getProgramsByAccountId: IProgramChild[];
}

interface IGetAccountForNewProgram {
  getForNewProgramByAccountId: IProgramChild[];
}

export const useGetAccountForNewProgram = (
  options?: QueryHookOptions<IGetAccountForNewProgram, IGetAccountProgramsVariables>
) =>
  useQuery(GET_NEW_PROGRAM_CHILDREN_BY_ACCOUNT, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

interface IGetAccountProgramsVariables {
  input: IGetAccountProgramsInput;
}

interface IGetProgramChildren {
  getProgramChildren: PagedDto<IProgramChild>;
}

interface IGetProgramsChildrenVariables {
  input: IGetProgramChildrenInput;
}

interface IGetAccountProgramsVariables {
  input: IGetAccountProgramsInput;
}

export const useGetAccountPrograms = (options?: QueryHookOptions<IGetAccountProgram, IGetAccountProgramsVariables>) =>
  useQuery(GET_PROGRAM_CHILDREN_BY_ACCOUNT, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetProgramChildren = (options?: QueryHookOptions<IGetProgramChildren, IGetProgramsChildrenVariables>) =>
  useQuery(GET_PROGRAM_CHILDREN, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
