import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { defaultPositionFields } from './defaultFields';

interface IPositionType {
  id: string;
  businessId: string;
  name: string;
  archivedAt?: Date;
}

interface ICreatePositionInput {
  input: {
    businessId: string;
    name: string;
  };
}

interface IArchivePositionInput {
  input: {
    businessId: string;
    id: string;
  };
}
interface IUpdatePositionInput {
  input: {
    businessId: string;
    id: string;
    name: string;
  };
}

export const CREATE_POSITION = gql`
  mutation($input: CreateBasePositionInput!) {
    createBasePosition(input: $input){
       ${defaultPositionFields}
    }
  }
`;

export const useCreatePosition = (input?: MutationHookOptions<IPositionType, ICreatePositionInput>) =>
  useMutation<IPositionType, ICreatePositionInput>(CREATE_POSITION, input);

export const UPDATE_POSITION = gql`
  mutation ($input: UpdateBasePositionInput!) {
    updateBasePosition(input: $input) {
      ${defaultPositionFields}
    }
  }
`;

export const useUpdatePosition = (input?: MutationHookOptions<IPositionType, IUpdatePositionInput>) =>
  useMutation<IPositionType, IUpdatePositionInput>(UPDATE_POSITION, input);

export const ARCHIVE_POSITION = gql`
  mutation ($input: ArchiveBasePositionInput!) {
    archiveBasePosition(input: $input) {
      id
    }
  }
`;

export const useArchivePosition = (input?: MutationHookOptions<IPositionType, IArchivePositionInput>) =>
  useMutation<IPositionType, IArchivePositionInput>(ARCHIVE_POSITION, input);
