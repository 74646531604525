import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { faDollarSign, faTimes, faUndo } from '@fortawesome/pro-light-svg-icons';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from 'store/reducers';
import DataTable, { TABLE_DEFAULTS } from 'shared/components/DataTable';
import { TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { isEmpty, orderBy, sortBy, uniqBy } from 'lodash';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import {
  convertTimeRangeObjectToString,
  convertTimeRangeStringToObject,
  timeRangeOptions,
} from 'shared/util/timeUtils';
import colors from '_colors.module.scss';
import useFormatDate from 'shared/hooks/useFormatDate';
import moment from 'moment';
import { currencyFormat } from 'shared/util/currency';
import { PaymentStatusTag } from 'shared/components/Tag';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { IconButtonCircle } from 'shared/components/Buttons';
import { isRegion } from 'shared/util/region';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { setCurrentBusinessFilters, setCurrentCenterFilters } from 'store/context/actions';
import TransactionExpandedRow from 'pages/BillingTransactions/components/TransactionExpandedRow';
import PaymentStatusFilterCard from 'pages/BillingTransactions/components/PaymentStatusFilterCard';
import ReverseTransactionModal from 'pages/BillingTransactions/components/ReverseTransactionModal';
import CopyButton from 'shared/components/Buttons/CopyButton';
import { useEffect } from 'react';
import { IPaymentSummary, useGetSumOfPaymentsInTimeFrame } from 'gql/transaction/queries';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const paymentStatusFilters: PaymentStatus[] = ['UNSUBMITTED', 'PENDING', 'FAILED', 'COMPLETED', 'FLAGGED'];
const unsuccessfulStatuses: PaymentStatus[] = isRegion('AU') ? ['FAILED'] : ['FAILED', 'RETURNED'];

const paymentStatusDropdownFilters_US: ITableFilterOption[] = [
  { label: 'Unsubmitted', value: 'UNSUBMITTED' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Deposited', value: 'COMPLETED' },
  { label: 'Flagged', value: 'FLAGGED' },
  { label: 'Returned', value: 'RETURNED' },
  { label: 'Refunded', value: 'REFUNDED' },
];

const paymentStatusDropdownFilters_AU: ITableFilterOption[] = [
  { label: 'Unsubmitted', value: 'UNSUBMITTED' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Flagged', value: 'FLAGGED' },
  { label: 'Cancelled', value: 'CANCELLED' },
];

let paymentStatusDropdownFilters = isRegion('AU') ? paymentStatusDropdownFilters_AU : paymentStatusDropdownFilters_US;

interface IProps {
  data: ITransaction[];
  loading: boolean;
  dateRange: ITimeRange;
  setDateRange: (tr: ITimeRange) => void;
  isAccountView?: boolean;
  isPaymentTransactionsOnly?: boolean;
  isUnbatchedPaymentTransactionsOnly?: boolean;
  showPaymentFilterCards?: boolean;
  approvePaymentLoading?: boolean;
  onApproveFlaggedPayment?: (payment: IPayment) => void;
  onRejectFlaggedPayment?: (payment: IPayment, voidManualCheckPayment: boolean) => void;
  onPaymentStatusFilter: (statues: PaymentStatus[]) => void;
  onStatusTileSelected: (start: string, end: string, status?: PaymentStatus) => void;
  showSelect?: boolean;
  onSelect?: (rows: ITransaction[]) => void;
  selectedRows?: ITransaction[];
  showCenterFilter?: boolean;
  noPadding: boolean;
  pageSize?: number;
  activePage?: number;
  onPageChange?: (page: number, sizePerPage: number) => void;
  onSizePerPageChange?: (sizePerPage: number) => void;
  totalRecords?: number;
  onSort?: (field: any, direction: any) => void;
  onSearchChange?: React.Dispatch<React.SetStateAction<string>>;
  onSelectedTransactionTypeChange?: (categoryTableFilterOptions: ITableFilterOption[]) => void;
  selectedTransactionType?: ITableFilterOption[];
  disableSortingOnStatusAndMethod?: boolean; // TODO: Delete this later when sorting on these columns is added
}

type TransactionCategory = 'manual' | 'automated' | 'attendance';
const automatedTransactionTypeNames = ['Automated CC', 'Automated ACH/DD'];
const attendanceTransactionTypeNames = ['Session Fee', 'Early Fee', 'Late Fee', 'Discount', 'Subsidy'];

export const getTransactionCategory: (t: ITransaction) => TransactionCategory = (t) => {
  if (!t.transactionType.centerId && !t.transactionType.businessId) {
    if (automatedTransactionTypeNames.includes(t.transactionType.name)) {
      return 'automated';
    } else {
      return attendanceTransactionTypeNames.includes(t.transactionType.name) ? 'attendance' : 'manual';
    }
  } else {
    return 'manual';
  }
};

const isTransactionFlagged: (t: ITransaction) => boolean = (t) =>
  Boolean(t.payment?.flags.length && t.payment.approvedAt === null && t.payment.rejectedAt === null);

const PaymentTransactionsTable: React.FC<IProps> = ({
  data,
  loading,
  dateRange,
  setDateRange,
  isAccountView = false,
  isPaymentTransactionsOnly = false,
  isUnbatchedPaymentTransactionsOnly = false,
  showPaymentFilterCards = true,
  approvePaymentLoading = false,
  onApproveFlaggedPayment,
  onRejectFlaggedPayment,
  onPaymentStatusFilter,
  onStatusTileSelected,
  showSelect = false,
  onSelect = () => {},
  selectedRows = [],
  showCenterFilter = true,
  noPadding = false,
  pageSize,
  activePage,
  onPageChange,
  onSizePerPageChange,
  totalRecords,
  onSort,
  onSearchChange,
  onSelectedTransactionTypeChange,
  selectedTransactionType,
  disableSortingOnStatusAndMethod = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const defaultStatus = queryString.parse(location.search).status;
  const user = useSelector((state: RootState) => state.user);
  const { businessId, businessFilterIds, centerFilterIds } = useSelector((state: RootState) => state.context);
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'date',
    direction: 'desc',
  });
  const [selectedTile, setSelectedTile] = useState<PaymentStatus | null>(
    defaultStatus ? defaultStatus.toUpperCase() : null
  );

  const [searchTerm, setSearchTerm] = useState('');
  // only show manual transactions by default
  const [selectedCategories, setSelectedCategories] = useState<ITableFilterOption[]>(
    isPaymentTransactionsOnly
      ? []
      : [
          { label: 'Manual', value: 'manual' },
          { label: 'Automated', value: 'automated' },
        ]
  );

  const { data: businessesData } = useGetAllowedEntities();
  const { data: centers } = useGetActiveCentersWithLoading();
  const transactionCategories: ITableFilterOption[] = [
    { label: 'Manual', value: 'manual' },
    { label: 'Automated', value: 'automated' },
  ];

  if (!isPaymentTransactionsOnly) {
    transactionCategories.push({ label: 'Attendance', value: 'attendance' });
  }

  const formatDate = useFormatDate();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');

  var [selectedPaymentStatuses, setSelectedPaymentStatuses] = useState<PaymentStatus[]>(
    defaultStatus ? [defaultStatus.toUpperCase()] : []
  );
  var [selectedPaymentDropdownStatuses, setSelectedPaymentDropdownStatuses] = useState<ITableFilterOption[]>(
    defaultStatus ? [defaultStatus.toUpperCase()] : []
  );

  const [selectedTransactionTypes, setSelectedTransactionTypes] = useState<ITableFilterOption[]>([]);

  const [transactionInReverseModal, setTransactionInReverseModal] = useState<ITransaction | null>(null);

  const filteredTransactions = orderBy(
    data.filter((d) => {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      // convert to a positive value since we'll build a negative string
      const amount = (d.amount > 0 ? d.amount : d.amount * -1).toFixed(2);
      const amountAsString = `${d.amount < 0 ? '-' : ''}$${amount.toString()}`;

      let isPartOfActiveFilter = false;
      for (let i = 0; i < selectedPaymentStatuses.length; i++) {
        if (selectedPaymentStatuses[i] === 'FLAGGED' && isTransactionFlagged(d)) {
          isPartOfActiveFilter = true;
          break;
        } else if (!isTransactionFlagged(d) && d.payment?.status === selectedPaymentStatuses[i]) {
          isPartOfActiveFilter = true;
          break;
        }
      }

      return (
        (!businessFilterIds.length || businessFilterIds.includes(d.account.center?.entityId ?? '')) &&
        (!centerFilterIds.length || centerFilterIds.includes(d.account.center?.id ?? '')) &&
        (!selectedCategories.length || selectedCategories.map((c) => c.value).includes(getTransactionCategory(d))) &&
        (!selectedPaymentStatuses.length || (d.payment?.status && isPartOfActiveFilter)) &&
        (!selectedPaymentDropdownStatuses.length ||
          selectedPaymentDropdownStatuses.some((x) =>
            x.value === 'FLAGGED' ? isTransactionFlagged(d) : x.value == d.payment?.status
          )) &&
        (!selectedTransactionTypes.length ||
          selectedTransactionTypes.map((t) => t.value).includes(d.transactionTypeId)) &&
        (!searchTerm.length ||
          d.transactionNumber?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
          d.description?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
          d.account.name?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
          d.transactionType.name?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
          d.amount.toFixed(2).toString().includes(lowercasedSearchTerm) ||
          amountAsString.includes(lowercasedSearchTerm))
      );
    }),
    sortField.field,
    sortField.direction
  );

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const clearAppliedFilters = useCallback(() => {
    setSelectedPaymentStatuses([]);
    dispatch(setCurrentCenterFilters([]));
    dispatch(setCurrentBusinessFilters([]));
    setSelectedCategories([]);
    setSelectedTransactionTypes([]);
    setDateRange({ start: moment().startOf('month').format(), end: moment().endOf('month').format() });
    setSelectedPaymentDropdownStatuses([]);

    // by default ALL are shown
    onPaymentStatusFilter(paymentStatusFilters);
  }, [dispatch, onPaymentStatusFilter, setDateRange]);

  const getTransactionMethodDisplay = (transactionType: string) => {
    if (transactionType === 'Automated CC' || transactionType === 'Manual CC') {
      return 'Credit Card';
    }
    if (transactionType === 'Automated ACH/DD' || transactionType === 'Manual ACH/DD') {
      return isRegion('AU') ? 'Direct Debit' : 'ACH';
    } else return transactionType;
  };

  const getTransactionTypeDisplay = (transactionType: string) => {
    if (transactionType === 'Automated CC' || transactionType === 'Automated ACH/DD') {
      return isPaymentTransactionsOnly ? 'Automated' : 'Automated Payment';
    }
    if (transactionType === 'Manual CC' || transactionType === 'Manual ACH/DD') {
      return isPaymentTransactionsOnly ? 'Manual' : 'Manual Payment';
    }

    if (['Cash', 'Check', 'Cheque', 'Money Order', 'EFTPOS', 'Direct Deposit'].includes(transactionType)) {
      return 'Manual';
    } else return transactionType;
  };

  const timeScopedPaymentStatuses = ['FAILED'];

  const renderStatus = useCallback((status: PaymentStatus, transaction: ITransaction): JSX.Element => {
    const isFlagged = Boolean(
      transaction.payment?.flags.length &&
        transaction.payment.approvedAt === null &&
        transaction.payment.rejectedAt === null
    );

    // markup taken from <PaymentStatusTag /> since we needed the custom icon
    if (isFlagged) {
      return (
        <div className="d-flex">
          <div className="tag px-4 bg-orange text-white">
            <div className="mr-2 d-flex">
              <FontAwesomeIcon icon={faFlag} size="sm" />
            </div>
            Flagged
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex">
        <PaymentStatusTag status={status} className="flex-grow-0" />
      </div>
    );
  }, []);

  function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const toDropDownItem = (status: PaymentStatus) => {
    return {
      label: capitalize(status.toString().toLowerCase()),
      value: status,
    };
  };

  const handleStatusTileClick = useCallback(
    (paymentStatus: PaymentStatus) => {
      let tile: PaymentStatus | null = null;
      if (selectedTile === paymentStatus) {
        setSelectedTile(null);
        setSelectedPaymentStatuses([]);
        setSelectedPaymentDropdownStatuses([]);
      } else {
        tile = paymentStatus;
        setSelectedTile(paymentStatus);
        setSelectedPaymentStatuses(paymentStatus === 'FAILED' ? unsuccessfulStatuses : [paymentStatus]);
        setSelectedPaymentDropdownStatuses(
          paymentStatus === 'FAILED'
            ? unsuccessfulStatuses.map((x) => toDropDownItem(x))
            : [toDropDownItem(paymentStatus)]
        );
      }

      const monthStart = moment().startOf('month').format();
      const monthEnd = moment().endOf('month').format();

      if (!tile) {
        onStatusTileSelected(monthStart, monthEnd);
      } else {
        const targetSumOfPayment = sumOfPaymentsData.find((item) => item.paymentStatus === paymentStatus);
        if (!targetSumOfPayment) {
          onStatusTileSelected(monthStart, monthEnd, paymentStatus);
        } else {
          const start = timeScopedPaymentStatuses.includes(paymentStatus)
            ? dateRange.start
            : targetSumOfPayment.earliestEntry;
          const end = timeScopedPaymentStatuses.includes(paymentStatus)
            ? dateRange.end
            : targetSumOfPayment.latestEntry;
          onStatusTileSelected(start, end, paymentStatus);
        }
      }

      // Fix page number persisting on filter (this should not happen or it can result in no data being displayed)
      onPageChange?.(1, pageSize ?? TABLE_DEFAULTS.PAGE_SIZE);
    },
    [selectedPaymentStatuses, selectedPaymentDropdownStatuses, selectedTile, onStatusTileSelected]
  );

  const transactionTypes = uniqBy(
    orderBy(
      data.reduce((acc: ITransactionType[], curr) => [...acc, curr.transactionType], []),
      (t) => t.name,
      'asc'
    ),
    (t) => t.id
  );

  let filterCards = paymentStatusFilters.filter((status) => status !== 'CANCELLED');

  if (isRegion('AU')) {
    filterCards = filterCards.filter((status) => status !== 'COMPLETED');
  }

  useEffect(() => {
    selectedPaymentStatuses = [];
    selectedPaymentDropdownStatuses.forEach((status) => {
      selectedPaymentStatuses.push(status.value as PaymentStatus);
    });
    setSelectedPaymentStatuses(selectedPaymentStatuses);
  }, [selectedPaymentDropdownStatuses]);

  const { data: sumOfPaymentsQueryResult } = useGetSumOfPaymentsInTimeFrame({
    variables: {
      input: {
        start: dateRange.start,
        end: dateRange.end,
        centerIds: centerFilterIds,
        businessId: businesses.length ? businesses[0].id : '',
      },
    },
  });

  const sumOfPaymentsData = sumOfPaymentsQueryResult?.getSumOfPaymentsInTimeFrame || [];
  const handleStatusDropdownChange = useCallback(
    (tableOptions: ITableFilterOption[]) => {
      setSelectedTile(null);
      setSelectedPaymentDropdownStatuses(tableOptions);

      const newStatus = tableOptions.map((x) => x.value) as PaymentStatus[];
      setSelectedPaymentStatuses(newStatus);
      onPaymentStatusFilter(newStatus);

      // Fix page number persisting on filter (this should not happen or it can result in no data being displayed)
      onPageChange?.(1, pageSize ?? TABLE_DEFAULTS.PAGE_SIZE);
    },
    [onPaymentStatusFilter]
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const paymentCount = (data: IPaymentSummary[], status: PaymentStatus) => {
    if (status === 'FAILED') {
      const unsuccessfulStatusStrings = unsuccessfulStatuses.map((s) => s.toString());
      return data
        ?.filter((x) => unsuccessfulStatusStrings.includes(x.paymentStatus))
        .reduce((prev, curr) => prev + curr.paymentCount, 0);
    }

    return data?.find((x) => x.paymentStatus === status)?.paymentCount ?? 0;
  };

  const totalAmount = (data: IPaymentSummary[], status: PaymentStatus) => {
    if (status === 'FAILED') {
      const unsuccessfulStatusStrings = unsuccessfulStatuses.map((s) => s.toString());
      return data
        ?.filter((x) => unsuccessfulStatusStrings.includes(x.paymentStatus))
        .reduce((prev, curr) => prev + curr.totalAmount, 0);
    }

    return data?.find((x) => x.paymentStatus === status)?.totalAmount ?? 0;
  };

  return (
    <div>
      <DataTable
        showPagination={pageSize !== undefined}
        keyField="id"
        data={onSort !== undefined ? data : filteredTransactions}
        dataSize={onSort !== undefined ? totalRecords : filteredTransactions.length}
        showLoadingOverlay={loading}
        showSelect={showSelect}
        updateSelectedRows={onSelect}
        selectedRows={selectedRows}
        noPadding={noPadding}
        pageSize={pageSize}
        activePage={activePage}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        onSort={
          onSort
            ? onSort
            : (field, direction) => setSortField({ field, direction: direction === 'ASCENDING' ? 'asc' : 'desc' })
        }
        expandRow={(transaction) => (
          <TransactionExpandedRow
            handleReverse={setTransactionInReverseModal}
            transaction={transaction}
            isPaymentTransactionsOnly={isPaymentTransactionsOnly}
            onApproveFlaggedPayment={onApproveFlaggedPayment}
            onRejectFlaggedPayment={onRejectFlaggedPayment}
            approvePaymentLoading={approvePaymentLoading}
          />
        )}
        rowClasses={(row: ITransaction, rowIdx: number) => {
          // only apply the faded styling to payment transactions on the payments page
          if (row.payment?.status === 'CANCELLED' && isPaymentTransactionsOnly) {
            return 'k2-billing-transaction-cancelled-payment';
          }

          return '';
        }}
        columns={[
          isPaymentTransactionsOnly &&
            !isUnbatchedPaymentTransactionsOnly && {
              dataField: 'payment.status',
              text: 'Status',
              sort: !disableSortingOnStatusAndMethod,
              formatter: (status: PaymentStatus, row: ITransaction) => renderStatus(status, row),
            },
          {
            dataField: 'date',
            text: 'Date',
            sort: true,
            formatter: (date: string) => formatDate(date),
          },
          {
            dataField: 'transactionNumber',
            text: 'ID',
            sort: true,
          },
          {
            dataField: 'transactionType.name',
            text: 'Type',
            formatter: (transactionType: string) => getTransactionTypeDisplay(transactionType),
            sort: true,
          },
          isPaymentTransactionsOnly && {
            dataField: 'transactionType',
            text: 'Method',
            sort: !disableSortingOnStatusAndMethod,
            formatter: (transactionType: ITransactionType) => getTransactionMethodDisplay(transactionType.name),
          },
          {
            dataField: 'account.name',
            text: 'Account',
            sort: true,
          },
          {
            dataField: 'payment.processorTxnId',
            text: 'Processor ID',
            hidden: isRegion('AU') || !(isPaymentTransactionsOnly && !isUnbatchedPaymentTransactionsOnly),
            formatter: (processorTxnId: string | undefined) =>
              !isEmpty(processorTxnId) ? (
                <div className="d-flex justify-content-end k2-processor-id-column">
                  ...{processorTxnId?.substring(processorTxnId.length - 4)} <CopyButton text={processorTxnId || ''} />
                </div>
              ) : (
                ''
              ),
          },
          {
            dataField: isPaymentTransactionsOnly ? 'payment.total' : 'amount',
            text: 'Amount',
            sort: true,
            align: 'right',
            headerAlign: 'right',
            formatter: (amount: number, row: ITransaction) => (
              <h6 className={classnames('mr-4', { 'text-success': amount > 0, 'text-dark': amount <= 0 })}>
                {currencyFormat(amount)}
              </h6>
            ),
          },
          {
            dataField: 'reversedTransactionId',
            text: '',
            formatter: (id: string) => (
              <span className={classnames('fa-layers fa-fw mx-2', { invisible: !id })}>
                <FontAwesomeIcon icon={faUndo} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
            ),
          },
        ].filter((c) => c)}
        renderHeader={(paginationProps, searchProps) => (
          <div className="align-items-center">
            <TableHeader className="flex-wrap">
              <div className="d-flex flex-wrap align-items-center w-auto">
                <TableSearch
                  placeholder={isPaymentTransactionsOnly ? 'Search Payments' : 'Search Transactions'}
                  onChange={onSearchChange ? onSearchChange : setSearchTerm}
                  className={isMobile ? 'my-1 mr-auto' : 'mr-4 flex-shrink-0 flex-grow-0'}
                />
                <Select
                  options={timeRangeOptions}
                  value={convertTimeRangeObjectToString(dateRange)}
                  onChange={(string) => setDateRange(convertTimeRangeStringToObject(string))}
                  className={isMobile ? 'my-1 mr-4' : 'mr-2 flex-shrink-0 flex-grow-0 mb-0'}
                />
              </div>
              <div className={isMobile ? 'd-flex flex-nowrap my-1' : 'd-flex align-items-center ml-4 mr-auto'}>
                <DateInput
                  date={dateRange.start}
                  onDateSelect={(start) => setDateRange({ ...dateRange, start })}
                  className="mb-0"
                />
                <div className={isMobile ? 'mt-2 mx-2' : 'mx-2'}>to</div>
                <DateInput
                  date={dateRange.end}
                  onDateSelect={(end) => setDateRange({ ...dateRange, end })}
                  className="mb-0"
                />
              </div>
              <div className={isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex align-items-center'}>
                <DropdownFilter
                  title="Status"
                  className={isMobile ? 'mr-4 my-1' : 'mr-4'}
                  selectedFilters={selectedPaymentDropdownStatuses}
                  options={paymentStatusDropdownFilters}
                  onFilterSelect={handleStatusDropdownChange}
                />
                <DropdownFilter
                  title="Type"
                  className="mr-4"
                  selectedFilters={selectedTransactionType ? selectedTransactionType : selectedCategories}
                  options={transactionCategories}
                  onFilterSelect={
                    onSelectedTransactionTypeChange ? onSelectedTransactionTypeChange : setSelectedCategories
                  }
                />
                {isUnbatchedPaymentTransactionsOnly && (
                  <DropdownFilter
                    title="Method"
                    className={isMobile ? 'my-1 mr-4' : 'mr-4'}
                    selectedFilters={selectedTransactionTypes}
                    options={transactionTypes.map((t) => ({ label: getTransactionMethodDisplay(t.name), value: t.id }))}
                    onFilterSelect={setSelectedTransactionTypes}
                  />
                )}
                {centers && centers.length > 1 && !isAccountView && showCenterFilter && (
                  <div className={isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex align-items-center'}>
                    {user?.isInternal && (
                      <DropdownFilter
                        title="Business"
                        className="mr-4"
                        selectedFilters={businessFilterIds}
                        options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                        onFilterSelect={(businesses) =>
                          dispatch(setCurrentBusinessFilters(businesses.map((b) => b.value)))
                        }
                      />
                    )}
                    <DropdownFilter
                      title={fieldLabels.center}
                      className="mr-4"
                      selectedFilters={centerFilterIds}
                      options={
                        centers
                          ?.map((c) => ({ label: c.name, value: c.id }))
                          .sort((a, b) => a.label.localeCompare(b.label)) ?? []
                      }
                      onFilterSelect={(centers) => dispatch(setCurrentCenterFilters(centers.map((c) => c.value)))}
                    />
                  </div>
                )}
                <IconButtonCircle
                  icon={faTimes}
                  onClick={clearAppliedFilters}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                />
              </div>
            </TableHeader>
            {isPaymentTransactionsOnly && showPaymentFilterCards && (
              <div className="row">
                <div className={`k2-billing-transactions-payment-filters-grid payment-grid-${filterCards.length} pt-2`}>
                  {filterCards.map((status, idx) => (
                    <div className="mb-2" key={`payment-filter-card-${status}-${idx}`}>
                      <PaymentStatusFilterCard
                        status={status}
                        count={paymentCount(sumOfPaymentsData, status)}
                        total={totalAmount(sumOfPaymentsData, status)}
                        onClick={() => handleStatusTileClick(status)}
                        className="raised-hover h-100"
                        isSelected={selectedTile === status}
                        loading={loading}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {isUnbatchedPaymentTransactionsOnly && (
              <div className="py-4 border-bottom">
                <b>
                  {filteredTransactions.length} results | Total:{' '}
                  {currencyFormat(filteredTransactions.reduce((acc, curr) => (acc += curr.amount), 0))}
                </b>
              </div>
            )}
          </div>
        )}
      />
      {transactionInReverseModal && (
        <ReverseTransactionModal
          isOpen={Boolean(transactionInReverseModal)}
          onClose={() => setTransactionInReverseModal(null)}
          transaction={transactionInReverseModal}
        />
      )}
    </div>
  );
};

export default PaymentTransactionsTable;
