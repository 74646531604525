import * as types from './types';

export const getAgencyAccountChildrenTableData = (
  tableData: IGetPaginatedAgencyAccountChildrenForBusinessQueryData
): types.AgencyAccountChildrenActionTypes => ({
  type: types.GET_AGENCY_ACCOUNT_CHILDREN_TABLE_DATA,
  tableData,
});

export const updateAgencyAccountChildrenTableFilters = (
  tableFilters: Partial<IAgencyAccountChildrenTableFilters>
): types.AgencyAccountChildrenActionTypes => ({
  type: types.UPDATE_AGENCY_ACCOUNT_CHILDREN_TABLE_FILTERS,
  tableFilters,
});

export const updateAgencyAccountChildren = (
  agencyAccountChild: IAgencyAccountChild
): types.AgencyAccountChildrenActionTypes => ({
  type: types.UPDATE_AGENCY_ACCOUNT_CHILD,
  agencyAccountChild,
});
