import React from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';

interface IProps {
  date: string;
  updateDate: (date: string) => void;
  className?: string;
}

const MonthPicker: React.FC<IProps> = ({ date, updateDate, className }) => {
  return (
    <div className={`d-flex border border-slate rounded ${className}`} style={{ height: 40 }}>
      <InputGroup.Prepend>
        <Button
          variant="light"
          className="bg-white px-4 h-100"
          onClick={() => updateDate(moment(date).subtract(1, 'M').startOf('month').toString())}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="sm" color="gray" />
        </Button>
      </InputGroup.Prepend>
      <div className="my-auto px-4 d-flex align-items-center h-100 sm">
        <FontAwesomeIcon icon={faCalendarAlt} size="lg" className="mr-3" />
        {moment(date).format('MMM YYYY').toLocaleUpperCase()}
      </div>
      <InputGroup.Append>
        <Button
          variant="light"
          className="bg-white px-4 h-100"
          onClick={() => updateDate(moment(date).add(1, 'M').startOf('month').toString())}
        >
          <FontAwesomeIcon icon={faChevronRight} size="sm" color="gray" />
        </Button>
      </InputGroup.Append>
    </div>
  );
};

export default MonthPicker;
