import React from 'react';
import { HorizontalDivider } from 'shared/components/Dividers';
import { Row, Col } from 'shared/components/Layout';
import Button from 'shared/components/Buttons';
import ContractInformation from './ContractInformation';
import ContractSchedule from './ContractSchedule';

interface IProps {
  contract: IContract;
  centerTimezone: Timezone;
  userCanUpdateContract: boolean;
  onUpdateContract: (contract: any) => void;
}

const ContractOverviewTab: React.FC<IProps> = ({
  contract,
  centerTimezone,
  userCanUpdateContract,
  onUpdateContract,
  ...props
}) => {
  return (
    <div className="d-flex flex-column flex-1">
      <div className="d-flex flex-column flex-1">
        <ContractInformation contract={contract} centerTimezone={centerTimezone} />
        <HorizontalDivider className="w-100" />
        <ContractSchedule contract={contract} centerTimezone={centerTimezone} />
        {contract.casualFee && (
          <>
            <HorizontalDivider className="w-100" />
            <Row>
              <Col>
                <div className="font-size-12">Fee for Casual drop off</div>
              </Col>
              <Col>
                <div className="text-dark">{contract.casualFee.name}</div>
              </Col>
            </Row>
          </>
        )}
      </div>
      {contract.status !== 'EXPIRED' && userCanUpdateContract && (
        <div className="mt-auto">
          {contract.casualFee && <HorizontalDivider />}
          <Row justify="center">
            <Button variant="outline-secondary" onClick={() => onUpdateContract(contract)}>
              Update Contract
            </Button>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ContractOverviewTab;
