import React, { useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'shared/components/Select';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { useUpdateRoleship } from '../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import Column from 'react-bootstrap/Col';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetRolesForBusiness } from 'shared/hooks/useGetRolesForBusiness';
import { getIsRoleARankedAboveRoleB, isHighestRole } from 'shared/util/getIsRoleARankedAboveRoleB';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  staff: IStaff;
  readOnly: boolean;
  editPermission?: boolean | undefined;
}

const PermissionsModal: React.FC<IProps> = ({ isOpen, onClose, staff, readOnly, editPermission, ...props }) => {
  const user = useSelector((state: RootState) => state.user);
  const { data: rolesData } = useGetRolesForBusiness(staff.entityId);
  const centers = useGetActiveCenters().filter((c) => c.entityId === staff.entityId);
  const [updateRoleship, { loading: updatingUserRoleship }] = useUpdateRoleship(staff.id);
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;

  const [updatedRoleId, setUpdatedRoleId] = useState(staff.roleship.roleId);
  const [updatedPrimaryCenterId, setUpdatedPrimaryCenterId] = useState(staff.primaryCenterId || null);
  const [updatedCenterIds, setUpdatedCenterIds] = useState<string[]>(
    staff.roleship.scopeType === 'CENTER' ? staff.roleship.scopes.map((c: any) => c.id) : []
  );
  const [formIsDirty, setFormIsDirty] = useState(false);

  const selectedRole = rolesData?.getRolesForBusiness.find((r) => r.id === updatedRoleId);
  const isCenterScoped = selectedRole?.scopeType === 'CENTER';

  const saveChanges = useCallback(() => {
    if (selectedRole) {
      updateRoleship({
        variables: {
          input: {
            id: staff.id,
            roleId: updatedRoleId,
            scopeIds: isCenterScoped ? updatedCenterIds : [staff.entityId],
            primaryCenterId: isCenterScoped ? updatedPrimaryCenterId : null,
          },
        },
      }).then(() => {
        showToast('Role updated successfully.', 'success');
        setFormIsDirty(false);
        onClose();
      });
    }
  }, [staff, updatedRoleId, selectedRole, updatedPrimaryCenterId, updatedCenterIds, onClose]);

  const resetForm = useCallback(() => {
    setUpdatedRoleId(staff.roleship.roleId);
    setUpdatedPrimaryCenterId(staff.primaryCenterId ?? null);
    setUpdatedCenterIds(staff.roleship.scopeType === 'CENTER' ? staff.roleship.scopes.map((c: any) => c.id) : []);
    setFormIsDirty(false);
  }, [staff]);

  const handleRoleSelect = useCallback((id: string) => {
    setUpdatedRoleId(id);
    setFormIsDirty(true);
  }, []);

  const handlePrimaryCenterSelect = useCallback((centerId: string) => {
    setUpdatedPrimaryCenterId(centerId);
    setUpdatedCenterIds((prev) => [...prev.filter((id) => id !== centerId), centerId]);
    setFormIsDirty(true);
  }, []);

  const handleCenterScopeSelect = useCallback((centerIds: string[]) => {
    setUpdatedCenterIds(centerIds);
    setFormIsDirty(true);
  }, []);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const saveDisabled =
    !formIsDirty ||
    updatingUserRoleship ||
    readOnly ||
    (isCenterScoped && !updatedPrimaryCenterId) ||
    (isCenterScoped && !updatedCenterIds.length);

  return (
    <Modal
      className="permissions-modal"
      centered
      dialogClassName="modal-lg"
      show={isOpen}
      onHide={onClose}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Role & Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormWrapper2
          formIsDirty={formIsDirty}
          toggleDirty={() => {}}
          saveDisabled={saveDisabled}
          loading={updatingUserRoleship}
          primaryChoice="Save"
          secondaryChoice="Cancel"
          onSave={saveChanges}
          onCancel={resetForm}
        >
          <Column className="align-items-center">
            <Select
              id="role-select"
              disabled={(!editPermission && readOnly) || staff.employmentStatus === 'Requested'}
              label="Role"
              value={updatedRoleId}
              options={rolesData?.getRolesForBusiness.map((r) => ({ label: r.name, value: r.id, role: r })) ?? []}
              onChange={(option) => handleRoleSelect(option.value)}
              isOptionDisabled={(option: any) =>
                !user?.isInternal &&
                !isHighestRole(user?.role, rolesData?.getRolesForBusiness) &&
                !getIsRoleARankedAboveRoleB(user?.role, option.role)
              }
            />
            {
              /* This is temporary until we get to custom permissions, we need a primary center to prevent erroring */
              isCenterScoped && (
                <Select
                  disabled={!editPermission && readOnly}
                  required
                  id="scope-select"
                  label="Applies to"
                  aria-label={`Select the ${fieldLabels.center.toLowerCase()}(s) to scope the permission access to`}
                  value={centers
                    .filter((cv) => updatedCenterIds.findIndex((v) => v === cv.id) >= 0)
                    .map((v) => {
                      return { label: v.name, value: v.id };
                    })}
                  isMulti
                  options={centers.map((c) => ({ label: c.name, value: c.id }))}
                  onChange={(options: any) => {
                    handleCenterScopeSelect(options ? options.map((o: any) => o.value) : []);
                  }}
                />
              )
            }
            {selectedRole && (
              <Select
                id="center-select"
                required
                disabled={(!editPermission && readOnly) || !isCenterScoped || AdpEnabled}
                label={`Primary ${fieldLabels.center}`}
                aria-label={`Select employee\'s primary ${fieldLabels.center.toLowerCase()}`}
                placeholder={`Primary ${fieldLabels.center}`}
                value={!isCenterScoped ? 'All' : updatedPrimaryCenterId}
                options={!isCenterScoped ? ['All'] : centers.map((c) => ({ label: c.name, value: c.id }))}
                onChange={(o) => handlePrimaryCenterSelect(o.value)}
              />
            )}
          </Column>
        </FormWrapper2>
      </Modal.Body>
    </Modal>
  );
};

export default PermissionsModal;
