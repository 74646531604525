import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import { LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { busRosterFields, busRosterAllFields, busRosterAvailableChildrenFields } from './fields';

interface IGetPaginatedBusRostersData {
  getPaginatedBusRosters: IPagedResult<IBusRoster>;
}

interface IGetBusRosterData {
  getBusRosterById: IBusRoster;
}

interface IGetBusRosterAvailableChildrenData {
  getAvailableChildrenForBusRoster: IBusRosterAvailableAccountChild[];
}

interface IGetBusRosterVariables {
  id: string;
}

interface IGetPaginatedBusRosterVariables {
  input: {
    businessId: string;
    centerIds: string[];
    searchKey?: string;
    sortBy: string;
    sortDirection: string;
    pageNumber: number;
    pageSize: number;
  };
}

interface IGetBusReportPdfData {
  getBusRosterPdf: string[];
}

interface IGetBusReportPdfVariables {
  busRosterId: string;
  startDate: string;
  endDate: string;
  additionalChildren: number;
}

export interface IGetBusRosterAvaiableChildrenVariables {
  input: {
    centerId: string;
    effectiveDate: string;
    endDate: string;
  };
}

export const GET_PAGINATED_BUS_ROSTERS = gql`
    query($input: GetPaginatedBusRostersInput!) {
        getPaginatedBusRosters(input: $input) {
            pageNumber
            pageSize
            totalRecords
            data {
                ${busRosterFields}
            }
        }
    }
`;

export const GET_BUS_ROSTER_BY_ID = gql`
    query($id: ID!) {
        getBusRosterById(id: $id) {
            ${busRosterAllFields}
        }
    }
`;

export const GET_AVAILABLE_CHILDREN_FOR_BUS_ROSTER = gql`
    query($input: GetAvailableChildrenForBusRosterInput!) {
        getAvailableChildrenForBusRoster(input: $input) {
            ${busRosterAvailableChildrenFields}
        }
    }
`;

export const useGetPaginatedBusRosters = (
  options?: QueryHookOptions<IGetPaginatedBusRostersData, IGetPaginatedBusRosterVariables>
) =>
  useQuery(GET_PAGINATED_BUS_ROSTERS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetBusRosterById = (options?: QueryHookOptions<IGetBusRosterData, IGetBusRosterVariables>) =>
  useQuery(GET_BUS_ROSTER_BY_ID, {
    fetchPolicy: 'cache-and-network',
    skip: !options?.variables?.id ?? false,
    ...options,
  });

export const useGetBusRosterAvailableChildren = (
  options?: QueryHookOptions<IGetBusRosterAvailableChildrenData, IGetBusRosterAvaiableChildrenVariables>
) =>
  useQuery(GET_AVAILABLE_CHILDREN_FOR_BUS_ROSTER, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const GET_BUS_ROSTER_PDF_REPORT = () => gql`
  query ($busRosterId: ID!, $startDate: Date!, $endDate: Date!, $additionalChildren: Int!) {
    getBusRosterPdf(id: $busRosterId, startDate: $startDate, endDate: $endDate, additionalChildren: $additionalChildren)
  }
`;

export const useGetBusReportPdfLazy = (
  options?: LazyQueryHookOptions<IGetBusReportPdfData, IGetBusReportPdfVariables>,
  fields?: string
) =>
  useLazyQuery<IGetBusReportPdfData, IGetBusReportPdfVariables>(GET_BUS_ROSTER_PDF_REPORT(), {
    ...options,
    fetchPolicy: 'network-only',
  });
