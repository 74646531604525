import React from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';

interface IProps {
  date: string;
  session: ISession | null;
}

const SessionInformationListItem: React.FC<IProps> = ({ date, session, ...props }) => {
  const isScheduled = session !== null;
  const hasAttended = (session?.timeEntries ?? []).length > 0 ?? false;
  const hasAbsence = (isScheduled && session?.absence !== null) ?? false;
  const sortedTimeEntries = orderBy(session?.timeEntries ?? [], (t) => t.timeIn, 'asc');

  const firstTimeEntry = hasAttended ? sortedTimeEntries[0] : null;
  const lastTimeEntry = hasAttended ? sortedTimeEntries[sortedTimeEntries.length - 1] : null;

  let totalHours = 0;

  if (session && lastTimeEntry && lastTimeEntry.timeOut !== null) {
    const minutesAttended = session.timeEntries.reduce(
      (acc, curr) => (acc += moment(curr.timeOut).diff(moment(curr.timeIn), 'minutes')),
      0
    );
    totalHours = minutesAttended / 60;
  }

  return (
    <li className="border rounded mb-2 d-flex align-items-center justify-content-between">
      <div className="p-2 flex-1">
        <h5 className="mb-1">{moment(date).format('ddd, MMMM D')}</h5>
        {hasAttended && (
          <p className="mb-0" id="session-attendance-status">
            S - <span className="text-success">Attended</span>
          </p>
        )}
        {isScheduled && !hasAttended && !hasAbsence && (
          <p className="mb-0" id="session-attendance-status">
            S - <span className="text-danger">Not Attended</span>
          </p>
        )}
        {isScheduled && hasAbsence && (
          <p className="mb-0" id="session-attendance-status">
            S - <span className="text-danger">Not Attended - Absent</span>
          </p>
        )}
        {!isScheduled && (
          <p className="mb-0" id="session-attendance-status">
            NS - <span className="text-danger">Not Attended</span>
          </p>
        )}
      </div>
      <div className="p-2 d-flex align-items-center flex-1">
        <div className="mr-4">
          <label className="mb-1">Start Time</label>
          <p className="mb-0">{firstTimeEntry ? moment(firstTimeEntry.timeIn).format('h:mm A') : '--'}</p>
        </div>
        <div>
          <label className="mb-1">End Time</label>
          <p className="mb-0">
            {lastTimeEntry && lastTimeEntry.timeOut !== null ? moment(lastTimeEntry.timeOut).format('h:mm A') : '--'}
          </p>
        </div>
      </div>
      <div className="bg-light text-center py-2 px-8">
        <label className="d-block mb-1">Total Hours</label>
        <b className="mb-0">{totalHours.toFixed(2)}</b>
      </div>
    </li>
  );
};

export default SessionInformationListItem;
