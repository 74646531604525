import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import { GET_ACCOUNT_BY_ID } from 'gql/account/queries';
import { useCreateTransaction } from 'gql/transaction/mutations';
import { createTransaction } from 'pages/BillingTransactions/duck/action';
import { showToast } from 'shared/components/Toast';
import { useGetTransactionTypes } from 'gql/transaction/queries';
import moment from 'moment';
import { addTransactionAmountToAccountBalance } from 'pages/Families/subroutes/Accounts/duck/actions';
import { ApolloError, useQuery } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import './_createTransactionModal.scss';
import { RootState } from 'store/reducers';

interface IFormShape {
  date: string | null;
  transactionTypeId: string | null;
  amount: number | null;
  note: string;
  selectedAccountChild: iAccountChild | null;
}

/**
 * Scoped down AccountChild
 */
interface iAccountChild {
  accountChildId: string;
  fullName: string;
}

interface IProps {
  isOpen: boolean;

  /**
   * since we will be showing this modal from the account profile in addition to the transaction page, if
   * this prop is provided then we already have an account id and won't show the account dropdown
   */
  accountId: string;

  /**
   * called whenever this modal wishes to close, i.e. when cancel is clicked, x is clicked, or save complete.
   * @returns
   */
  onClose: () => void;

  /**
   * called whenever modal is finished creating transaction
   * @returns
   */
  onComplete?: () => void;
  /**
   * how we want to filter the transaction types down
   */
  filterBy?: 'isDebit' | 'isCredit';
}

const CreateTransactionModal: React.FC<IProps> = ({ isOpen, accountId, onClose, onComplete = () => {}, filterBy }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IFormShape>({
    date: moment().format(),
    transactionTypeId: null,
    amount: null,
    note: '',
    selectedAccountChild: null,
  });

  const { data: selectedAccountData, loading: loadingSelectedAccount } = useQuery<{
    getAccountById: { centerId: string; entityId: string; children: iAccountChild[] };
  }>(
    GET_ACCOUNT_BY_ID(
      'id name centerId entityId center { id name entityId } status children {accountChildId fullName}'
    ),
    {
      variables: { id: accountId },
      skip: !accountId,
    }
  );

  const { centerId, entityId } = useMemo(() => {
    if (selectedAccountData) {
      return {
        centerId: selectedAccountData.getAccountById.centerId,
        entityId: selectedAccountData.getAccountById.entityId,
      };
    }

    return { centerId: null, entityId: null };
  }, [selectedAccountData]);

  //TODO: Once we attach Timezone to ICenter then use that (selectedAccount.Center.Timezone)
  const timezone = useSelector((state: RootState) =>
    centerId ? state.timezone.byCenterId[centerId] : (moment.tz.guess() as Timezone)
  );

  const { data: getTransactionTypesData, loading: getTransactionTypesLoading } = useGetTransactionTypes({
    variables: {
      businessId: entityId ?? '',
      centerIds: centerId ? [centerId] : null,
      category: filterBy === 'isDebit' ? 'CHARGE' : 'CREDIT',
    },
    fetchPolicy: 'network-only',
    skip: !(entityId && centerId),
  });

  const transactionTypeOptions = React.useMemo(() => {
    const options = (getTransactionTypesData?.getTransactionTypes ?? []).filter(
      (t) =>
        t.archivedAt === null && !t.isDefaultType && t.metadata.isWritable && (filterBy ? t[filterBy] === true : true)
    );
    return orderBy(options, (t) => t.name, 'asc');
  }, [getTransactionTypesData, filterBy]);

  const [createTransactionFn, { loading: createTransactionLoading }] = useCreateTransaction({
    onCompleted: (result) => {
      if (result?.createTransaction) {
        handleClose();
        onComplete();
        showToast('Transaction created successfully.', 'success');
        dispatch(createTransaction(result.createTransaction));
        // alongside inserting the transaction into redux we need to update the balance of the account (if it exists in redux)
        dispatch(
          addTransactionAmountToAccountBalance(result.createTransaction.accountId, result.createTransaction.amount)
        );
      }
    },
    onError: (error: unknown) => {
      let errorMessage: string;
      if (error instanceof ApolloError) {
        errorMessage = getApolloErrorMessage(error);
      } else if (error instanceof Error) {
        errorMessage = error.message;
      } else {
        errorMessage = 'Unknown error occurred during save, please refresh this page.';
      }
      showToast(errorMessage, 'error');
    },
  });

  const formIsInvalid = useCallback((): boolean => {
    return !formData.date || !formData.transactionTypeId || !formData.amount;
  }, [formData]);

  const resetForm = useCallback(() => {
    setFormData({
      date: moment().format(),
      transactionTypeId: null,
      amount: null,
      note: '',
      selectedAccountChild: null,
    });
  }, []);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  const handleSave = useCallback(() => {
    createTransactionFn({
      variables: {
        input: {
          accountId: accountId as string,
          appliesDate: moment(formData.date as string)
            .tz(timezone)
            .format('MM/DD/YYYY'),
          description: formData.note as string,
          amount: formData.amount as number,
          transactionTypeId: formData.transactionTypeId as string,
          accountChildId: formData.selectedAccountChild?.accountChildId,
        },
      },
    });
  }, [
    createTransactionFn,
    accountId,
    formData.date,
    formData.note,
    formData.amount,
    formData.transactionTypeId,
    formData.selectedAccountChild?.accountChildId,
    timezone,
  ]);

  const title = React.useMemo(() => {
    if (filterBy === 'isCredit') {
      return 'Add Credit';
    } else if (filterBy === 'isDebit') {
      return 'Add Charge';
    } else {
      return 'Create Transaction';
    }
  }, [filterBy]);

  return (
    <SideModalDrawer
      title={title}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      secondaryChoice="Cancel"
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: formIsInvalid(), loading: createTransactionLoading }}
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      {/* see comment on styling in _crateTransactionModal.scss */}
      <Row className="create-transaction-modal__apply-date-row">
        <Col>
          <DateInput
            required
            label="Apply Date"
            date={formData.date}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, date }))}
            placeholder={t('formatters.date')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AccountChildSelect
            loading={loadingSelectedAccount}
            children={selectedAccountData?.getAccountById.children ?? []}
            value={formData.selectedAccountChild}
            onChange={(accountChild) => {
              setFormData((prev) => ({ ...prev, selectedAccountChild: accountChild }));
            }}
            hasSelectedAccount={!!accountId}
            required={false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            label="Type"
            value={formData.transactionTypeId}
            options={transactionTypeOptions}
            onChange={(option: ITransactionType) => setFormData((prev) => ({ ...prev, transactionTypeId: option.id }))}
            isLoading={getTransactionTypesLoading}
            getOptionLabel={(option: ITransactionType) => {
              return option.name;
            }}
            getOptionValue={(option: ITransactionType) => option.id}
            noOptionsMessage={() => {
              if (!centerId || !entityId) {
                return 'Please select an account.';
              }
              return 'No transaction types found.';
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CurrencyInput2
            className="border-radius-0"
            required
            label="Amount"
            value={formData.amount}
            onChange={(value) => setFormData((prev) => ({ ...prev, amount: value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            as="textarea"
            label="Description"
            value={formData.note}
            onChange={(value) => setFormData((prev) => ({ ...prev, note: value }))}
            rows={5}
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

function AccountChildSelect({
  children,
  loading,
  value,
  onChange,
  label = 'Child',
  hasSelectedAccount,
  required = false,
}: {
  label?: string;
  children: iAccountChild[];
  value: iAccountChild | null;
  loading: boolean;
  hasSelectedAccount: boolean;
  required: boolean;
  onChange: (accountChild: iAccountChild) => void;
}) {
  return (
    <Select
      label={label}
      isLoading={loading}
      value={value}
      getOptionValue={(option: iAccountChild) => option.accountChildId}
      getOptionLabel={(option: iAccountChild) => option.fullName}
      options={children}
      required={required}
      onChange={onChange}
      isClearable
      noOptionsMessage={() => {
        if (!hasSelectedAccount) {
          return 'Please select an account.';
        }
        return 'No children found on account.';
      }}
    />
  );
}

export default CreateTransactionModal;
