import React from 'react';

interface IProps {
  status?: 'online' | 'busy' | 'offline';
  avatarSize: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const AvatarStatus: React.FC<IProps> = ({ status, avatarSize }) => {
  if (!status) {
    return null;
  }

  return <span className={`avatar-status avatar-status-${avatarSize} avatar-status-${status}`} />;
};

export default AvatarStatus;
