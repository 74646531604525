import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useChangeEmailViaMagicLink } from '../graphql/mutations';
import MagicLinkUI from '../MagicLinkUI';

interface IProps {}

const ChangeEmail: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const [changeEmailViaMagicLink, { called: changeCalled }] = useChangeEmailViaMagicLink({
    onCompleted: () => {
      history.replace('/home');
    },
    onError: () => {
      history.replace('/');
    },
  });
  const token = queryString.parse(location.search).token;
  const action = queryString.parse(location.search).action;

  useEffect(() => {
    // if no code is found redirect back to the base route
    if (!token || !action) {
      history.replace('/');
    } else {
      if (!changeCalled) {
        changeEmailViaMagicLink({
          variables: {
            token,
            action,
          },
        });
      }
    }
  }, [token, action, history, changeCalled, changeEmailViaMagicLink]);

  return <MagicLinkUI />;
};

export default ChangeEmail;
