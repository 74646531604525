import { QueryHookOptions, QueryTuple } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { InputVariable } from '../program/mutation';

//region GetSessionAmendments
const GET_SESSION_AMENDMENTS = () => gql`
  query ($input: SessionAmendmentSearchInput!) {
    getSessionAmendments(input: $input) {
      data {
        businessId
        expiryDate
        startDate
        id
        reasonText
        createdAt
        authorizedStaff {
          firstname
          nickname
          lastname
          id
          avatar {
            url
          }
        }
        authorizedBy {
          firstname
          nickname
          lastname
          id
          avatar {
            url
          }
        }
      }
      pageNumber
      pageSize
      totalRecords
    }
  }
`;

interface IGetSessionAmendmentsPagedData {
  data: ISessionAmendmentDto[];
  totalRecords: number;
}

export interface IGetSessionAmendmentsData {
  getSessionAmendments: IGetSessionAmendmentsPagedData;
}

export const useGetSessionAmendments = (
  options?: QueryHookOptions<IGetSessionAmendmentsData, InputVariable<ISessionAmendmentSearchInput>>
) =>
  useQuery<IGetSessionAmendmentsData, InputVariable<ISessionAmendmentSearchInput>>(GET_SESSION_AMENDMENTS(), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
//endregion

//region GetBusinessConfig
const GET_BUSINESS_CONFIG = () => gql`
  query ($input: SessionAmendmentGetBusinessConfigInput!) {
    getSessionAmendmentGetBusinessConfig(input: $input) {
      editDay
    }
  }
`;

export interface IGetBusinessConfigData {
  getSessionAmendmentGetBusinessConfig: ISessionAmendmentGetBusinessConfigDto;
}

export const useGetBusinessConfig = (
  options?: QueryHookOptions<IGetBusinessConfigData, InputVariable<ISessionAmendmentGetBusinessConfigInput>>
): QueryTuple<IGetBusinessConfigData, InputVariable<ISessionAmendmentGetBusinessConfigInput>> =>
  useLazyQuery<IGetBusinessConfigData, InputVariable<ISessionAmendmentGetBusinessConfigInput>>(GET_BUSINESS_CONFIG(), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
//endregion
