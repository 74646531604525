import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import ActionDropdown from 'shared/components/ActionDropdown';
import { PersonAvatar } from 'shared/components/Avatar';
import { CreateButton } from 'shared/components/Buttons';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import useFormatDate from 'shared/hooks/useFormatDate';
import { getFullName } from 'shared/util/string';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppliedAccountDiscountTableData } from './DiscountTabTypes';

type ActionsCellProps = {
  hasEditDiscountPermission: boolean;
  hasDeleteDiscountPermission: boolean;
  cell: any;
  row: AppliedAccountDiscountTableData;
  edit: (discount: AppliedAccountDiscountTableData) => void;
  remove: (discount: AppliedAccountDiscountTableData) => void;
};

const ActionsCell = (props: ActionsCellProps) => {
  const handleEdit = () => props.edit(props.row);
  const handleRemove = () => props.remove(props.row);

  return (
    <ActionDropdown
      actions={[
        ...(props.hasEditDiscountPermission ? [{ label: 'Edit Discount', onClick: handleEdit }] : []),
        ...(props.hasDeleteDiscountPermission && props.row.discountAuth === 'DELETE'
          ? [{ label: 'Remove Discount', onClick: handleRemove }]
          : []),
      ]}
    />
  );
};

interface IProps {
  data: AppliedAccountDiscountTableData[];
  add: () => void;
  edit: (discount: AppliedAccountDiscountTableData) => void;
  remove: (discount: AppliedAccountDiscountTableData) => void;
}

const AccountDiscountsTable = ({ data, add, edit, remove }: IProps) => {
  const hasEditDiscountPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Discount,
    level: RoleLevelType.Edit,
  });
  const hasDeleteDiscountPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Discount,
    level: RoleLevelType.Delete,
  });

  const { t } = useTranslation(['billing']);
  const formatDate = useFormatDate();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filterTableData = useCallback(
    (discounts: AppliedAccountDiscountTableData[]): AppliedAccountDiscountTableData[] => {
      const lowercased = searchTerm.toLowerCase();

      if (!searchTerm) return discounts;

      return discounts.filter(
        (discount) =>
          discount.discountName.toLowerCase().includes(lowercased) ||
          discount.startDate.includes(lowercased) ||
          discount.endDate?.includes(lowercased) ||
          getFullName(discount.createdByPerson).toLowerCase().includes(lowercased) ||
          (discount.accountChildId &&
            discount.accountChild &&
            getFullName(discount.accountChild).toLowerCase().includes(lowercased))
      );
    },
    [searchTerm]
  );

  const handleSearchTerm = debounce(
    useCallback((term: string) => {
      setSearchTerm(term);
    }, []),
    250
  );

  const filteredData = filterTableData(data);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <DataTable
      data={filteredData}
      dataSize={filteredData.length}
      noPadding
      columns={[
        {
          text: t('billing:discounts.account.data-table.name-column'),
          dataField: 'discountName',
        },
        {
          text: t('billing:discounts.account.data-table.start-date-column'),
          dataField: 'start',
          formatter: (cell: string, row: AppliedAccountDiscountTableData) => formatDate(row.startDate),
        },
        {
          text: t('billing:discounts.account.data-table.end-date-column'),
          dataField: 'end',
          formatter: (cell: string, row: AppliedAccountDiscountTableData) =>
            row.endDate ? formatDate(row.endDate) : 'Ongoing',
        },
        {
          text: t('billing:discounts.account.data-table.applied-to-column'),
          dataField: 'accountChildIds',
          formatter: (cell: string[] | null, row: AppliedAccountDiscountTableData) =>
            row.accountChild ? (
              <div className="d-flex align-items-center">
                <PersonAvatar size="sm" person={row.accountChild} className="mr-2" />
                <span>{getFullName(row.accountChild)}</span>
              </div>
            ) : (
              'Account'
            ),
        },
        {
          text: t('billing:discounts.account.data-table.applied-by-column'),
          dataField: 'createdAt',
          formatter: (cell: any, row: AppliedAccountDiscountTableData) => (
            <div className="d-flex align-items-center">
              <PersonAvatar size="sm" person={row.createdByPerson} className="mr-2" />
              <span>
                {getFullName(row.createdByPerson)} on {formatDate(row.createdAt)}
              </span>
            </div>
          ),
        },
        {
          text: t('billing:discounts.account.data-table.actions-column'),
          dataField: '',
          align: 'center',
          headerClasses: 'text-center',
          formatter: (cell: any, row: AppliedAccountDiscountTableData) => (
            <ActionsCell
              row={row}
              cell={cell}
              hasEditDiscountPermission={hasEditDiscountPermission}
              hasDeleteDiscountPermission={hasDeleteDiscountPermission}
              edit={edit}
              remove={remove}
            />
          ),
        },
      ]}
      showSelect={false}
      renderHeader={(paginationProps, searchProps) => (
        <TableHeader className={isMobile ? 'mb-2 flex-wrap' : 'flex-wrap'}>
          <TableSearch placeholder="Search" onChange={handleSearchTerm} className={isMobile ? 'mb-2' : 'mr-auto'} />
          <HasRoleAreaLevel
            action={{ area: AreaType.Billing, permission: PermissionType.Discount, level: RoleLevelType.Create }}
          >
            <CreateButton onClick={add}>Apply Discount</CreateButton>
          </HasRoleAreaLevel>
        </TableHeader>
      )}
      noDataText={t('billing:discounts.account.data-table.no-data-message')}
    />
  );
};

export default AccountDiscountsTable;
