import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';

interface IProps {
  title: string | React.ReactNode;
  className?: string;
  defaultOpen?: boolean;
}

const DataControlAccordion: React.FC<IProps> = ({ title, className = '', defaultOpen = true, ...props }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const activeKey = defaultOpen ? '0' : '';
  return (
    <Accordion className={className} defaultActiveKey={activeKey}>
      <Accordion.Toggle
        eventKey="0"
        className="kt-schedules-data-control-accordion-toggle"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="d-flex flex-row align-items-center">
          <span className="flex-grow-1">{title}</span>
          <FontAwesomeIcon color={colors.dark} icon={isOpen ? faChevronUp : faChevronDown} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse className="kt-schedules-data-control-accordion-collapse" eventKey="0">
        <div className="kt-schedules-data-control-accordion-body">{props.children}</div>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default DataControlAccordion;
