import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';

const { TERM } = SEARCH_EXPRESSIONS;
const { ACTIVE, DEACTIVATED, DELETED } = PREDICATES;

export const activeSearchExpression = { [TERM]: { field: 'active', predicate: ACTIVE } };
export const deactivatedSearchExpression = { [TERM]: { field: 'deactivatedAt', predicate: DEACTIVATED } };
export const deletedSearchExpression = { [TERM]: { field: 'deletedAt', predicate: DELETED } };

const centerStatusDropdownOptions: Record<string, ITableFilterOption> = {
  active: {
    label: 'Active',
    value: 'active',
    searchExpression: activeSearchExpression,
    indicatorColor: '#09B66D',
  },
  deactivated: {
    label: 'Deactivated',
    value: 'deactivated',
    searchExpression: deactivatedSearchExpression,
    indicatorColor: '#FF3D57',
  },
  deleted: {
    label: 'Deleted',
    value: 'deleted',
    searchExpression: deletedSearchExpression,
    indicatorColor: '#8E9BAC',
  },
};

export const jsFilters: Record<string, (center: ICenter) => Boolean> = {
  active: (center: ICenter) => !center.deletedAt && !center.deactivatedAt,
  deactivated: (center: ICenter) => !!center.deactivatedAt && !center.deletedAt,
  deleted: (center: ICenter) => !!center.deletedAt,
};

export default centerStatusDropdownOptions;
