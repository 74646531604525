// INPUT TYPES

export const addressFields = `
  address1
  address2
  city
  state
  postalCode
  country
`;

export const ownerFields = `
  name
  phoneNumber
  dob
`;

// SHARED PERSON FIELDS
export const sharedPersonFields = `
  id
  firstname
  lastname
  email
  title
  avatar {
    url
  }
  address {
    ${addressFields}
  }
  dob
  phoneNumber
  phoneNumberAlt
  nickname
  tags
  internal
`;

// EMPLOYEE FIELDS (DONT INCLUDE FULL CENTER AND ENTITY DATA)

export const administratorFields = `
  revocation
  permissions
  startsAt
  endsAt
  payRate
`;

export const managerFields = `
  revocation
  permissions
  startsAt
  endsAt
  payRate
`;

export const teacherFields = `
  revocation
  permissions
  startsAt
  endsAt
  payRate
  primaryProgram {
    id
    name
  }
  secondaryProgram {
    id
    name
  }
`;

export const employeeFields = `
  targetId
  center {
    id
    name
  }
  entity {
    id
    name
  }
  person {
    ${sharedPersonFields}
    administrator {
      entity {
        id
        name
      }
      ${administratorFields}
    }
    manager {
      center {
        id
        name
      }
      ${managerFields}
    }
    teacher {
      center {
        id
        name
        classes {
          name
          id
        }
      }
      ${teacherFields}
    }
  }
`;

// THE BIG BOIS - CENTERS AND ENTITIES

export const centerFields = `
  id
  name
  tags
  address {
    ${addressFields}
  }
  phoneNumber
  owner {
    ${ownerFields}
  }
  entity {
    name
    id
  }
  classes {
    id
    name
  }
  employees {
    ${employeeFields}
  }
`;

export const entityFields = `
  id
  name
  tags
  centers {
    ${centerFields}
  }
  employees {
    ${employeeFields}
  }
`;

// THE ULTIMATE - PERSON AS USER - BRING IN ALL THE CENTER AND ENTITY DATA FROM THEIR EMPLOYEE ASSIGNMENTS

export const userFields = `
  ${sharedPersonFields}
  confirmationCode {
    code
  }
  administrator {
    entity {
      ${entityFields}
    }
    ${administratorFields}
  }
  manager {
    center {
      ${centerFields}
    }
    ${managerFields}
  }
  teacher {
    center {
      ${centerFields}
    }
    ${teacherFields}
  }
`;

export const regulationFields = `
  id
  state
  county
  ratioTeachers
  ratioChildren
  capacity
  startAge {
    age
    unit
  }
  endAge {
    age
    unit
  }
`;
