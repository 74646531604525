/* eslint-disable camelcase */
import React from 'react';
import Card from 'shared/components/Card';
import { Row, Col } from 'shared/components/Layout';
import AvailabilityForm from './AvailabilityForm';
import cast from 'shared/util/cast';
import CenterAffiliations from './CenterAffiliations';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import action from 'shared/constants/enums/actionEnum';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  data: IStaff | IPerson | null;
  loading: boolean;
}

const fieldLables = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const EmploymentTab: React.FC<IProps> = ({ data, loading }) => {
  const staff = cast<IStaff>(data);

  const user = useSelector((state: RootState) => state.user);
  const hasOnboardingPermission = user?.hasPermission(action.STAFF_ONBOARDING) ?? false;

  return (
    <>
      <Row align="start">
        <Col lg={4}>
          <Card header={`${fieldLables.center} Affiliations`} loading={loading}>
            <CenterAffiliations staff={staff} />
          </Card>
        </Col>
        <Col lg={8}>
          {data && hasOnboardingPermission && (
            <Card header="Availability" loading={loading}>
              <AvailabilityForm staff={staff} />
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default EmploymentTab;
