import Accordion from './Accordion';
import Item from './Item';
import React from 'react';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';

interface IProps {
  title?: string | React.ReactNode;
  className?: string;
  titleClassName?: string;
  bodyClassName?: string;
  children: React.ReactNode | null;
  loading?: boolean;
  loadingLines?: number;
  defaultOpen?: boolean;
  addMb?: boolean;
}

const SingleAccordion: React.FC<IProps> = ({
  title,
  children,
  className,
  titleClassName,
  bodyClassName,
  loading = false,
  loadingLines = 4,
  defaultOpen = true,
  addMb = false,
}) => {
  return (
    <Accordion defaultActiveKey={defaultOpen ? '0' : undefined} className={className + (addMb ? ' mb-4' : '')}>
      <Item className={bodyClassName} titleClassName={titleClassName} title={title} isOpen={defaultOpen} eventKey="0">
        {loading ? <LoadingLines number={loadingLines} /> : children}
      </Item>
    </Accordion>
  );
};

export default SingleAccordion;
