import React, { useCallback } from 'react';
//components
import Avatar from 'shared/components/Avatar';
import Card from 'shared/components/Card';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
//utils
import moment from 'moment';
import { IClassStateShape } from '../../../Class';
import { getFullName, getInitials } from 'shared/util/string';
import { useTranslation } from 'react-i18next';

interface IProps {
  isViewOnly: boolean;
  fetchingStaff: boolean;
  staffOptions: IStaff[];
  assignedStaff: IStaff[];
  updateClass: React.Dispatch<React.SetStateAction<IClassStateShape>>;
  classStartDate: string;
  classEndDate: string | null;
}

const ClassAssignment: React.FC<IProps> = ({
  isViewOnly,
  fetchingStaff,
  staffOptions,
  assignedStaff,
  classStartDate,
  classEndDate,
  updateClass,
  ...props
}) => {
  const { t } = useTranslation('translation');

  const staffHasAssignedClassInTimeframe = useCallback((start: string, end: string | null, _class: IClass): boolean => {
    // inputted _class has an end date (temporary _class)
    if (end) {
      // _class is temporary, check if the provided start or end date fall between the _class's start and end dates
      if (_class.endsAt) {
        return (
          moment(start).isBetween(moment(_class.startsAt), moment(_class.endsAt)) ||
          moment(end).isBetween(moment(_class.startsAt), moment(_class.endsAt))
        );
      }

      // _class is ongoing, check if the provided start or end date fall after the _class's start date
      return moment(start).isAfter(moment(_class.startsAt)) || moment(end).isAfter(moment(_class.startsAt));
    } else {
      // inputted _class doesn't have an end date (ongoing class)
      if (_class.endsAt) {
        return moment(start).isBetween(moment(_class.startsAt), moment(_class.endsAt));
      }

      return moment(start).isAfter(moment(_class.startsAt));
    }
  }, []);

  return (
    <Card header={t('class.class-assigment.header')} className="h-100">
      <SelectMultiple
        label={t('class.class-assigment.select-label')}
        closeMenuOnSelect
        disabled={isViewOnly}
        isLoading={fetchingStaff}
        options={staffOptions}
        value={assignedStaff ?? null}
        getOptionValue={(option: IStaff) => option.id}
        getOptionLabel={(option: IStaff) => getFullName(option)}
        onChange={(options: any[] | null) =>
          updateClass((prev) => ({
            ...prev,
            staffAssignments: options ?? [],
          }))
        }
        displayCountInContainerRenderer={(selectedCount) =>
          `${selectedCount} ${selectedCount === 1 ? 'Employee' : 'Employees'} Selected`
        }
        formatOptionLabel={(option: IStaff, { context }: { context: 'menu' | 'value' }) => (
          <div key={`class-staff-assignment-${option.id}`} className="d-flex align-items-center">
            <Avatar size="sm" image={option.avatar?.url} initials={getInitials(option)} />
            <div className="d-flex flex-column pl-4 text-truncate">
              <span className="text-truncate">{getFullName(option)}</span>
              {context === 'menu' && (
                <>
                  {option.classAssignments?.some((_class: IClass) => !_class.endsAt) && (
                    <small>{t('class.class-assigment.assign-overlap')}</small>
                  )}
                  {option.classAssignments?.some(
                    (_class: IClass) =>
                      _class.endsAt && staffHasAssignedClassInTimeframe(classStartDate, classEndDate, _class)
                  ) && <small>{t('class.class-assigment.time-overlap')}</small>}
                </>
              )}
            </div>
          </div>
        )}
        isOptionDisabled={(option: IStaff) =>
          option.classAssignments.some(
            (_class: IClass) => !_class.endsAt || staffHasAssignedClassInTimeframe(classStartDate, classEndDate, _class)
          )
        }
      />
      <div className="d-flex flex-row flex-wrap">
        {assignedStaff.length > 0 ? (
          assignedStaff.map((emp) => (
            <div key={emp.id} className="tag mr-2 mb-2 rounded">
              {getFullName(emp)}
            </div>
          ))
        ) : (
          <small>{t('class.class-assigment.no-selected')}</small>
        )}
      </div>
    </Card>
  );
};

export default ClassAssignment;
