import { gql, useQuery } from '@apollo/client';
import config from 'config';
import axios from 'axios';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { mapPeriodsToEvents } from './MapPeriodsToEvents';
import { BillingCycleScheduleInformation, GetBillingSchedulePreviewResponse, iCalendarEvent } from './types';
import moment from 'moment';
import { useSelector } from 'react-redux';

const dateFormat = 'YYYY-MM-DD';

const previewGqlQuery = gql`
  query ($input: BillingCycleSchedulePreviewInput) {
    getBillingCycleSchedulePreviewFor(input: $input) {
      startDate
      endDate
      paymentDueDate
      invoiceDate
      lateOnDate
      hasLateFee
    }
  }
`;

export function useBillingCyclePreview(
  accountId: string,
  billingCycleSchedulePreview?: BillingCycleScheduleInformation
) {
  const previewEffectiveDate = useMemo(() => moment().format(dateFormat), []);

  const [calendarDate, setCalendarDate] = useState<Date>(new Date());

  // start and end date for data calls
  const { startDate, endDate } = useMemo((): { startDate: string; endDate: string } => {
    const startDate = moment(calendarDate).startOf('month');
    const endDate = moment(startDate).endOf('month');
    return { startDate: startDate.format(dateFormat), endDate: endDate.format(dateFormat) };
  }, [calendarDate]);

  // preview stuff
  const previewInput = useMemo(() => {
    if (!billingCycleSchedulePreview) return {};
    // we need to sanitize these before we send it to graphql incase anything like a _typename got stuck onto it
    let { invoiceSchedule, additionalChargePeriod } = billingCycleSchedulePreview;
    invoiceSchedule = invoiceSchedule ? { unit: invoiceSchedule.unit, value: invoiceSchedule.value } : undefined;
    additionalChargePeriod = additionalChargePeriod
      ? { unit: additionalChargePeriod.unit, value: additionalChargePeriod.value }
      : undefined;

    const today = moment(previewEffectiveDate);
    return {
      input: {
        period: billingCycleSchedulePreview.period,
        frequency: billingCycleSchedulePreview.frequency,
        dayOfWeek: billingCycleSchedulePreview.dayOfWeek,
        dayOfMonth: billingCycleSchedulePreview.dayOfMonth,
        invoiceSchedule: invoiceSchedule,
        additionalChargePeriod: additionalChargePeriod,
        cycleType: billingCycleSchedulePreview.cycleType,
        scheduleStartDate: today.format(dateFormat),
        scheduleEndDate: moment(calendarDate).endOf('month').format(dateFormat),
        gracePeriodDays: billingCycleSchedulePreview.gracePeriodDays,
      },
    };
  }, [billingCycleSchedulePreview, calendarDate, previewEffectiveDate]);

  const {
    data: previewPeriodsData,
    refetch: refetchPreviewEvents,
    loading: loadingPreviewEvents,
  } = useQuery<GetBillingSchedulePreviewResponse>(previewGqlQuery, {
    variables: previewInput,
    skip: !billingCycleSchedulePreview,
  });

  const previewEvents = mapPeriodsToEvents(previewPeriodsData?.getBillingCycleSchedulePreviewFor);

  const {
    accountEvents,
    refetch: refetchAccountEvents,
    loading: loadingAccountEvents,
  } = useGetAccountEvents(accountId, startDate, endDate);

  const displayableAccountEvents = useMemo(() => {
    if (billingCycleSchedulePreview) {
      return accountEvents.filter((e) => e.isPast);
    }
    return accountEvents;
  }, [accountEvents, billingCycleSchedulePreview]);

  const refetch = useCallback(() => {
    refetchAccountEvents();
    refetchPreviewEvents();
  }, [refetchAccountEvents, refetchPreviewEvents]);

  const loading = useMemo(() => {
    return loadingAccountEvents || loadingPreviewEvents;
  }, [loadingAccountEvents, loadingPreviewEvents]);

  return {
    events: [...displayableAccountEvents, ...previewEvents],
    calendarDate,
    setCalendarDate,
    refetch,
    loading,
  };
}

async function fetchAccountEvents(
  {
    accountId,
    startDate,
    endDate,
  }: {
    accountId: string;
    startDate: string;
    endDate: string;
  },
  authToken: string
) {
  const url = new URL(`${config.api.billing.uri}/api/v2/billing-cycle-schedule/account/${accountId}/events`);
  url.searchParams.set('startDate', startDate);
  url.searchParams.set('endDate', endDate);
  const response = await axios.get(url.toString(), {
    headers: { authorization: `Bearer ${authToken}` },
  });
  return mapPeriodsToEvents([
    ...response.data.scheduledPeriods.map((p) => ({ ...p.period, index: p.index })),
    ...response.data.invoices.map((i) => ({ ...i.period, index: i.index })),
  ]);
}

function useGetAccountEvents(accountId: string, startDate: string, endDate: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const [accountEvents, setAccountEvents] = useState<iCalendarEvent[]>([]);
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);
  const callFetchAccountEvents = useCallback(() => {
    setLoading(true);
    return fetchAccountEvents({ accountId, startDate, endDate }, jwtToken).finally(() => {
      setLoading(false);
    });
  }, [accountId, endDate, jwtToken, startDate, setLoading]);
  useEffect(() => {
    callFetchAccountEvents().then((events) => setAccountEvents(events));
  }, [callFetchAccountEvents]);

  const refetch = useCallback(
    () => callFetchAccountEvents().then((events) => setAccountEvents(events)),
    [callFetchAccountEvents]
  );

  return { accountEvents, refetch, loading };
}
