import axios from 'axios';
import config from 'config';
import { useEffect, useState } from 'react';
import { useGetCurrentBusiness } from 'shared/hooks/user-context/useGetCurrentBusiness';
import { useGetBearerToken } from 'shared/hooks/user-context/useGetCurrentJwt';
import { ReconciliationAccount } from '../models/ReconciliationAccount';
import { defaultPageSize } from './usePagination';

export function useReconciliationAccounts({
  centerIds = [],
  agencyIds = [],
  search = '',
  currentPage = 1,
  pageSize = defaultPageSize,
}: Partial<{ centerIds: string[]; search: string; agencyIds: string[]; currentPage: number; pageSize: number }>) {
  const bearerToken = useGetBearerToken();
  const businessId = useGetCurrentBusiness();

  const [agencyAccounts, setAgencyAccounts] = useState<ReconciliationAccount[]>([]);
  const [resultCount, setResultCount] = useState<number>(0);

  useEffect(() => {
    fetchAccountsToReconcile(bearerToken, {
      agencyIds,
      businessId,
      centerIds: centerIds,
      page: currentPage,
      pageSize: pageSize,
      searchText: search,
    }).then((accountsResponse) => {
      setResultCount(accountsResponse.resultsCount);
      setAgencyAccounts(accountsResponse.results);
    });
  }, [agencyIds, bearerToken, businessId, centerIds, currentPage, pageSize, search]);
  return {
    reconciliationAccounts: agencyAccounts,
    totalReconciliationAccounts: resultCount,
  };
}

interface FetchAccountsToReconcileParams {
  agencyIds: string[];
  businessId: string | null;
  centerIds: string[];
  page: number;
  pageSize: number;
  searchText: string;
}
interface AccountsToReconcileResponse {
  results: ReconciliationAccount[];
  resultsCount: number;
}
async function fetchAccountsToReconcile(
  bearerToken: string,
  params: FetchAccountsToReconcileParams
): Promise<AccountsToReconcileResponse> {
  const response = await axios.post(`${config.api.billing.uri}/api/v2/agency-reconciliation/accounts/search`, params, {
    headers: { Authorization: bearerToken },
  });
  return response.data;
}
