import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { appliedAccountDiscountFields, discountFields } from './fields';

interface ICreateDiscountData {
  createDiscount: IDiscount;
}

interface ICreateDiscountVariables {
  input: {
    name: string;
    centerIds: string[] | null;
    businessId: string;
    glCode: string | null;
    startDate: string;
    endDate: string | null;
    type: DiscountType;
    amount: number;
    amountType: DiscountAmountType;
    appliesTo: DiscountAppliesToType;
    description: string;
    glCodeMapping?: IGLCodeMapping | null;
    applySessionGapDiscountIfNoSubsidy: boolean;
  };
}

interface IUpdateDiscountData {
  updateDiscount: IDiscount;
}

interface IUpdateDiscountVariables {
  input: {
    id: string;
    name: string;
    glCode: string | null;
    businessId: string;
    centerIds: string[] | null;
    description: string | null;
    schedules: {
      id: string | null;
      startDate: string;
      endDate: string | null;
      amount: number;
      amountType: DiscountAmountType;
      discountId: string;
    }[];
    glCodeMapping?: IGLCodeMapping;
  };
}

interface ICreateAppliedAccountDiscountData {
  createAppliedAccountDiscount: IAppliedAccountDiscount[];
}

interface ICreateAppliedAccountDiscountVariables {
  input: {
    discountId: string;
    accountId: string;
    accountChildIds: string[] | null;
    startDate: string;
    endDate: string | null;
  };
}

interface IUpdateAppliedAccountDiscountData {
  updateAppliedAccountDiscount: IAppliedAccountDiscount;
}

interface IUpdateAppliedAccountDiscountVariables {
  input: {
    id: string;
    startDate: string;
    endDate: string | null;
  };
}

interface IDeleteAppliedAccountDiscountData {
  deleteAppliedAccountDiscount: IAppliedAccountDiscount;
}

interface IDeleteAppliedAccountDiscountVariables {
  id: string;
}

export const CREATE_DISCOUNT = (fields: string = discountFields) => gql`
  mutation($input: CreateDiscountInput!) {
    createDiscount(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_DISCOUNT = (fields: string = discountFields) => gql`
  mutation($input: UpdateDiscountInput!) {
    updateDiscount(input: $input) {
      ${fields}
    }
  }
`;

export const CREATE_APPLIED_ACCOUNT_DISCOUNT = (fields: string = appliedAccountDiscountFields) => gql`
  mutation($input: CreateAppliedAccountDiscountInput!) {
    createAppliedAccountDiscount(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_APPLIED_ACCOUNT_DISCOUNT = (fields: string = appliedAccountDiscountFields) => gql`
  mutation($input: UpdateAppliedAccountDiscountInput!) {
    updateAppliedAccountDiscount(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_APPLIED_ACCOUNT_DISCOUNT = (fields: string = appliedAccountDiscountFields) => gql`
  mutation($id: ID!) {
    deleteAppliedAccountDiscount(id: $id) {
      ${fields}
    }
  }
`;

export const useCreateDiscount = (
  options?: MutationHookOptions<ICreateDiscountData, ICreateDiscountVariables>,
  fields?: string
) => useMutation<ICreateDiscountData, ICreateDiscountVariables>(CREATE_DISCOUNT(fields), options);

export const useUpdateDiscount = (
  options?: MutationHookOptions<IUpdateDiscountData, IUpdateDiscountVariables>,
  fields?: string
) => useMutation<IUpdateDiscountData, IUpdateDiscountVariables>(UPDATE_DISCOUNT(fields), options);

export const useCreateAppliedAccountDiscount = (
  options?: MutationHookOptions<ICreateAppliedAccountDiscountData, ICreateAppliedAccountDiscountVariables>,
  fields?: string
) =>
  useMutation<ICreateAppliedAccountDiscountData, ICreateAppliedAccountDiscountVariables>(
    CREATE_APPLIED_ACCOUNT_DISCOUNT(fields),
    options
  );

export const useUpdateAppliedAccountDiscount = (
  options?: MutationHookOptions<IUpdateAppliedAccountDiscountData, IUpdateAppliedAccountDiscountVariables>,
  fields?: string
) =>
  useMutation<IUpdateAppliedAccountDiscountData, IUpdateAppliedAccountDiscountVariables>(
    UPDATE_APPLIED_ACCOUNT_DISCOUNT(fields),
    options
  );

export const useDeleteAppliedAccountDiscount = (
  options?: MutationHookOptions<IDeleteAppliedAccountDiscountData, IDeleteAppliedAccountDiscountVariables>,
  fields?: string
) =>
  useMutation<IDeleteAppliedAccountDiscountData, IDeleteAppliedAccountDiscountVariables>(
    DELETE_APPLIED_ACCOUNT_DISCOUNT(fields),
    options
  );
