import React from 'react';

import { ApolloApiProvider } from 'shared/util/apolloProvider';
import { createHooks } from 'shared/util/apolloHooks';
import { createClient } from './client';
import config from 'config';

const coreApiContext = React.createContext();

export const K2ApolloClient = createClient(config.api.k2.uri);

export const Provider = ({ token, children }) => (
  <ApolloApiProvider
    contextType={coreApiContext}
    uri={config.api.k2.uri}
    websocketUri={config.api.k2.websocketUri}
    token={token}
  >
    {children}
  </ApolloApiProvider>
);

export const {
  useApolloClient: useK2ApolloClient,
  useQuery: useK2Query,
  useLazyQuery: useK2LazyQuery,
  useMutation: useK2Mutation,
} = createHooks(coreApiContext);
