import React from 'react';

interface IDataFilterShape {
  classIds: string[];
  locationIds: string[];
  employeeIds: string[];
}

// pass the active data filters to child components for employee scheudling
const DataFilterContext = React.createContext<IDataFilterShape>({
  classIds: [],
  locationIds: [],
  employeeIds: [],
});
DataFilterContext.displayName = 'EmployeeScheduleDataFilter';

export default DataFilterContext;
