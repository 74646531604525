import React, { useState, useCallback, useEffect } from 'react';
import PageWrapper from 'shared/components/PageWrapper';
import { RouteComponentProps } from 'react-router-dom';
import useDatatableState from 'shared/hooks/useDatatableState';
import DataTable, { SizePerPage, TableHeader, TableSearch, BulkActions } from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { staffStatusColorHexes } from 'shared/constants/tagColors';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import { useSearchStaff } from '../Profiles/graphql/queries';
import { requested } from 'shared/constants/StaffStatusSearchExpressions';
import moment from 'moment';
import { Row } from 'shared/components/Layout';
import { faTimesCircle, faCheckCircle, faUserMinus } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from 'shared/components/Buttons';
import { useDeletePendingStaff } from 'pages/Employees/shared/graphql/mutations';
import DeleteStaffModal from 'pages/Employees/shared/components/DeleteStaffModal';
import { showToast } from 'shared/components/Toast';
import errorMessages from 'shared/constants/errorMessages';
import ApproveStaffModal from './ApproveStaffModal';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import useGetStaffSearchExpression from 'shared/hooks/useGetStaffSearchExpression';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { setRequestedEmployees } from 'store/employees/actions';
import { getTotalRequestedStaffSuccess } from '../Profiles/duck/actions';
import staticText from 'shared/constants/staticText';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { getFullName, getInitials } from 'shared/util/string';
import useHasRoleAreaLevel from '../../../../shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from '../../../../shared/constants/enums/permissionsEnums';

interface IProps {
  breadcrumbs: string[];
}

const CreatedStaffProfiles: React.FC<IProps & RouteComponentProps> = ({ history, breadcrumbs }) => {
  const dispatch = useDispatch();
  const hasDeleteStaffPermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });
  const hasEditStaffPermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const profiles = useSelector((state: RootState) => state.profiles);
  const [tableState, tableFunctions] = useDatatableState('requestedStaff', [
    { field: 'lastname.keyword', direction: 'ASCENDING' },
  ]);
  const centers = useGetActiveCenters();
  const getSearchExpression = useGetStaffSearchExpression(centers); // converts search term string into query expression
  const requestedStaff = useSelector((state: RootState) => state.employees.requested);
  const { loading, data, networkStatus, stopPolling } = useSearchStaff(
    {
      filter: { [SEARCH_EXPRESSIONS.ALL]: [...tableState.searchExpressions, requested] },
      sort: tableState.sort,
      size: tableState.pageSize,
      from: tableState.currentItemOffset,
    },
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        dispatch(setRequestedEmployees(data.searchStaff.data));
      },
    }
  );

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const [staffToDelete, setStaffToDelete] = useState<IStaff[]>([]);
  const [staffToApprove, setStaffToApprove] = useState<IStaff | null>();

  const [deleteStaffFn, { loading: deleteLoading }] = useDeletePendingStaff({
    update: (proxy, result) => {
      if (result.data?.deletePendingStaff) {
        const data = [
          ...profiles.requestedStaffData.filter((staff: IStaff) => staff.id !== result.data?.deletePendingStaff.id),
        ];
        dispatch(getTotalRequestedStaffSuccess(profiles.totalRequestedStaff - 1, data));
      }
    },
  });

  const deleteStaff = useCallback(
    (staff: IStaff[]) => {
      Promise.all(staff.map((staff) => deleteStaffFn({ variables: { personId: staff.id } })))
        .then((result) => {
          const staffIds = result?.map((i) => i.data?.deletePendingStaff.id);
          const data = requestedStaff?.filter((i: IStaff) => !(staffIds.filter((s) => s === i.id).length > 0));
          dispatch(setRequestedEmployees(data ?? []));
          showToast(`Deleted ${staff.length} ${staff.length === 1 ? 'request' : 'requests'} successfully.`, 'success');
        })
        .catch(() => showToast(errorMessages.generic, 'error'))
        .finally(() => setStaffToDelete([]));
    },
    [deleteStaffFn]
  );

  return (
    <PageWrapper pageTitle="Requested Employees" applyPadding={false}>
      {loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES ? (
        <PageWrapperBody>
          <DataTableLoadingSkeleton />
        </PageWrapperBody>
      ) : (
        <DataTable
          data={requestedStaff ?? []}
          dataSize={data?.searchStaff.totalResults ?? 0}
          pageSize={tableState.pageSize}
          showLoadingOverlay={loading || deleteLoading}
          columns={[
            {
              text: 'Name',
              dataField: 'lastname.keyword',
              sort: true,
              formatter: (cell: string, staff: IStaff) => (
                <AvatarDataTableCell
                  initials={getInitials(staff)}
                  avatar={staff.avatar?.url}
                  primaryText={getFullName(staff)}
                />
              ),
            },
            {
              text: 'Email',
              dataField: 'email.keyword',
              sort: true,
              formatter: (cell: string, staff: IStaff) => staff.email,
            },
            { text: fieldLabels.center, dataField: 'primaryCenter.name', sort: true },
            {
              text: 'Status',
              dataField: 'employmentStatus',
              sort: true,
              formatter: (cell: any, row: IStaff) => (
                <ColoredBackgroundTag
                  color={staffStatusColorHexes[row.employmentStatus]}
                  text={row.employmentStatus}
                  tooltipDirection={'top'}
                  tooltipText={staticText.employmentStatusHelper[row.employmentStatus]}
                />
              ),
            },
            {
              text: 'Date Requested',
              dataField: 'requestedToJoinAt',
              sort: true,
              formatter: (cell: string) => moment(cell).format('MM/DD/YYYY'),
            },
            hasDeleteStaffPermission || hasEditStaffPermission
              ? {
                  text: 'Actions',
                  dataField: '',
                  align: 'center',
                  headerClasses: 'text-center',
                  formatter: (cell: string, row: IStaff) => (
                    <Row justify="center" noGutters>
                      {hasEditStaffPermission && (
                        <IconButton
                          icon={faCheckCircle}
                          className="text-success mr-2"
                          tooltipText="Approve Request"
                          onClick={() => setStaffToApprove(row)}
                        />
                      )}
                      {hasDeleteStaffPermission && (
                        <IconButton
                          icon={faTimesCircle}
                          className="text-danger"
                          tooltipText="Delete Request"
                          onClick={() => setStaffToDelete([row])}
                        />
                      )}
                    </Row>
                  ),
                }
              : null,
          ]}
          renderHeader={(paginationProps: any) => (
            <TableHeader className="flex-wrap">
              {tableState.selectedRows.length && hasDeleteStaffPermission ? (
                <BulkActions
                  bulkActions={[
                    {
                      icon: faUserMinus,
                      tooltip: 'Delete Requests',
                      onClick: () => setStaffToDelete(tableState.selectedRows),
                    },
                  ]}
                />
              ) : (
                <>
                  <SizePerPage paginationProps={paginationProps} />
                  <TableSearch
                    onChange={(term) => tableFunctions.updateSearchExpressions(getSearchExpression(term))}
                    placeholder="Search Staff"
                  />
                </>
              )}
            </TableHeader>
          )}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          activePage={tableState.activePage}
          selectedRows={tableState.selectedRows}
          updateSelectedRows={tableFunctions.updateSelectedRows}
          onSort={tableFunctions.updateSort}
        />
      )}
      <DeleteStaffModal
        staffAreRequests={true}
        isOpen={Boolean(staffToDelete.length)}
        isLoading={deleteLoading}
        onClose={() => setStaffToDelete([])}
        selectedStaff={staffToDelete}
        deleteSelectedStaff={() => deleteStaff(staffToDelete)}
      />
      {staffToApprove && (
        <ApproveStaffModal
          isOpen={Boolean(staffToApprove)}
          onClose={() => {
            setStaffToApprove(null);
          }}
          staff={staffToApprove}
        />
      )}
    </PageWrapper>
  );
};

export default CreatedStaffProfiles;
