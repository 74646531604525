import React, { useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Button from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { PasswordInput } from 'shared/components/TextInput';
import { isBlank } from 'shared/util/string';
import { meetsAllRequirements } from 'shared/util/passwordRequirements';
import Auth from '@aws-amplify/auth';
import { showToast } from 'shared/components/Toast';
import '../../myProfile.scss';

interface IPasswordRequirementProps {
  meetsRequirement: boolean;
  requirementText: string;
}

const PasswordRequirement: React.FC<IPasswordRequirementProps> = ({ meetsRequirement, requirementText }) => (
  <div className="mb-3 kt-my-profile-change-password-requirement d-flex align-items-center">
    <FontAwesomeIcon
      icon={meetsRequirement ? faCheckCircle : faTimesCircle}
      size="lg"
      color={meetsRequirement ? '#18A957' : colors.danger}
    />
    <span className="ml-2">{requirementText}</span>
  </div>
);

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FC<IProps> = ({ isOpen, onClose, ...props }) => {
  const [changingPassword, setChangingPassword] = useState(false);
  const [passwordInputs, setPasswordInputs] = useState({
    current: '',
    new: '',
    confirmNew: '',
  });

  const dismissModal = useCallback(() => {
    setPasswordInputs({
      current: '',
      new: '',
      confirmNew: '',
    });

    onClose();
  }, [onClose]);

  const validatePasswordForm = useCallback(() => {
    return (
      Object.values(passwordInputs).every((value) => !isBlank(value)) &&
      passwordInputs.new === passwordInputs.confirmNew &&
      meetsAllRequirements(passwordInputs.new)
    );
  }, [passwordInputs]);

  const changePassword = useCallback(
    (oldPassword: string, newPassword: string) => {
      setChangingPassword(true);

      Auth.currentAuthenticatedUser()
        .then((user) => Auth.changePassword(user, oldPassword, newPassword))
        .then((data) => {
          setChangingPassword(false);
          showToast('Password changed successfully.', 'success');
          dismissModal();
        })
        .catch((err) => {
          setChangingPassword(false);

          // previous password is incorrect
          if (err.code === 'NotAuthorizedExceptions') {
            showToast('Incorrect password provided.', 'error');
          } else if (err.code === 'LimitExceededException') {
            // attempted too many password changes in a short span
            showToast('Attempt limit has been reached. Please try again later.', 'error');
          } else {
            showToast('There was an error changing your password.', 'error');
          }
        });
    },
    [dismissModal]
  );

  return (
    <Modal
      centered
      backdrop="static"
      show={isOpen}
      onHide={dismissModal}
      dialogClassName="kt-my-profile-change-password-modal"
    >
      <Modal.Header closeButton className="border-bottom-0 pb-0">
        <Modal.Title as="h5">Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="d-block mt-2 mb-4">Make sure the password you choose is memorable.</span>
        <Row>
          <Column className="text-right">
            <PasswordInput
              label="Current Password"
              value={passwordInputs.current}
              onChange={(value: string) => setPasswordInputs((prev) => ({ ...prev, current: value }))}
            />
            <PasswordInput
              label="New Password"
              value={passwordInputs.new}
              onChange={(value: string) => setPasswordInputs((prev) => ({ ...prev, new: value }))}
              autoComplete="new-password"
            />
            <PasswordInput
              className="mb-8"
              label="Re-type New Password"
              value={passwordInputs.confirmNew}
              onChange={(value: string) => setPasswordInputs((prev) => ({ ...prev, confirmNew: value }))}
              autoComplete="new-password"
            />
            <Button
              variant="primary"
              className="w-100"
              disabled={!validatePasswordForm() || changingPassword}
              loading={changingPassword}
              onClick={() => changePassword(passwordInputs.current, passwordInputs.new)}
            >
              Save Changes
            </Button>
          </Column>
          <Column className="kt-my-profile-change-password-requirements">
            <span className="d-block mb-2 mt-7">Password must:</span>
            <PasswordRequirement
              meetsRequirement={/(?=.*[A-Z])(?=.*[a-z])/.test(passwordInputs.new)}
              requirementText="Include upper and lower case letters"
            />
            <PasswordRequirement meetsRequirement={/\d/.test(passwordInputs.new)} requirementText="Include a number" />
            <PasswordRequirement
              meetsRequirement={/[$^*.(){}?"!@#%&/\\,<>':;|_~\][-`]/.test(passwordInputs.new)}
              requirementText="Include a special character (e.g. !@#$)"
            />
            <PasswordRequirement
              meetsRequirement={passwordInputs.new.length >= 8}
              requirementText="Include 8 or more characters"
            />
          </Column>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
