import React, { useCallback, useEffect, useState } from 'react';
import { TableHeader } from 'shared/components/DataTable';
import Button, { IconButtonCircle, ButtonAsLink, IconButton } from 'shared/components/Buttons';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import colors from '_colors.module.scss';
import { useUpdateStaffProfile } from 'gql/staff/mutations';
import { showToast } from 'shared/components/Toast';
import cast from 'shared/util/cast';
import { Card } from 'react-bootstrap';
import Avatar from 'shared/components/Avatar';
import { faTimes, faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useCreatePosition,
  useDeletePosition,
  useDissociateStaffFromCenter,
  useUpdatePosition,
} from '../graphql/mutations';
import RemoveAffiliationModal from './RemoveAffiliationModal';
import errorMessages from 'shared/constants/errorMessages';
import Alert from 'shared/components/Alert';
import EmployeePositionInputs, {
  IPositionFormData,
} from 'shared/components/EmployeePositionInputs/EmployeePositionInputs';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/reducers';
import _ from 'lodash';
import useHasRoleAreaLevel from '../../../../../../../shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from '../../../../../../../shared/constants/enums/permissionsEnums';

interface IProps {
  staff: IStaff;
}

const staffPositionToPositionFormData = (sp: IStaffPosition): IPositionFormData => ({
  id: sp.id,
  positionId: sp.positionId,
  personId: sp.personId,
  name: sp.positionName,
  scopeId: sp.scopeId,
  scopeType: sp.scopeType,
  businessId: sp.businessId,
  payRate: sp.payRate ?? null,
  type: sp.type ?? null,
  isExempt: sp.isExempt,
});

const CenterAffiliationsTable: React.FC<IProps> = ({ staff }) => {
  const isPrimaryCenter = (scope: ICenter | IEntity) => scope.id === staff.primaryCenterId;
  const staffScopes = staff.roleship.scopes.sort((s: ICenter | IEntity) => (isPrimaryCenter(s) ? -1 : 1));
  const [centerToRemove, setCenterToRemove] = useState<ICenter | null>(null);
  const [scopesInEdit, setScopesInEdit] = useState<string[]>([]);
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const [positionsByScopeId, updatePositionsByScopeId] = useState<Record<string, IPositionFormData[]>>(
    Object.fromEntries(
      cast<ICenter[]>(staffScopes).map((s) => [
        s.id,
        staff.positions.filter((p) => p.scopeId === s.id).map((s) => staffPositionToPositionFormData(s)),
      ])
    )
  );

  // if roles or positions are changes, update local state accordingly
  useEffect(() => {
    updatePositionsByScopeId(
      Object.fromEntries(
        cast<ICenter[]>(staffScopes).map((s) => [
          s.id,
          staff.positions.filter((p) => p.scopeId === s.id).map((s) => staffPositionToPositionFormData(s)),
        ])
      )
    );
  }, [staff.positions, staffScopes]);

  const [updateStaffProfile, { loading }] = useUpdateStaffProfile();
  const [dissociateFn, { loading: removalLoading }] = useDissociateStaffFromCenter();
  const [createPositionFn, { loading: createLoading }] = useCreatePosition();
  const [updatePositionFn, { loading: updateLoading }] = useUpdatePosition();
  const [deletePositionFn, { loading: deleteLoading }] = useDeletePosition();

  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;

  const hasEditPayratePermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.PayRate,
    level: RoleLevelType.Edit,
  });
  const hasReadPayratePermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.PayRate,
    level: RoleLevelType.Read,
  });
  const hasCreatePositionPermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Employment,
    level: RoleLevelType.Create,
  });

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const handleMakePrimary = useCallback(
    (center: ICenter) => {
      updateStaffProfile({
        variables: { input: { id: staff.id, primaryCenterId: center.id } },
      })
        .then(() => {
          showToast(`Primary ${fieldLabels.center.toLowerCase()} updated successfully.`, 'success');
        })
        .catch(() => {
          showToast(`There was an error changing the primary ${fieldLabels.center.toLowerCase()}.`, 'error');
        });
    },
    [updateStaffProfile, staff.id]
  );

  const handleRemoveCenter = useCallback(async () => {
    await dissociateFn({ variables: { input: { id: staff.id, centerId: cast<string>(centerToRemove?.id) } } })
      .then(() => {
        showToast('Affiliation Removed.', 'success');
        setCenterToRemove(null);
      })
      .catch(() => {
        showToast(errorMessages.generic, 'error');
        setCenterToRemove(null);
      });
  }, [centerToRemove, dissociateFn, staff.id]);

  const handleUpdatePositionLocally = useCallback(
    (position, scope, index) => {
      setScopesInEdit((prev) => [...prev, scope.id]);
      updatePositionsByScopeId({
        ...positionsByScopeId,
        [scope.id]: positionsByScopeId[scope.id].map((p, i) => (i === index ? position : p)),
      });
    },
    [positionsByScopeId]
  );

  const handleDeletePositionLocally = useCallback(
    (scope, index) => {
      setScopesInEdit((prev) => [...prev, scope.id]);
      updatePositionsByScopeId({
        ...positionsByScopeId,
        [scope.id]: positionsByScopeId[scope.id].filter((p, i) => i !== index),
      });
    },
    [positionsByScopeId]
  );

  const handleAddPositionLocally = useCallback(
    (scope) => {
      setScopesInEdit((prev) => [...prev, scope.id]);
      updatePositionsByScopeId({
        ...positionsByScopeId,
        [scope.id]: [
          ...positionsByScopeId[scope.id],
          {
            positionId: '',
            name: '',
            personId: staff.id,
            // use the graphql type to determine what the scope type is
            scopeType: scope.__typename === 'Entity' ? 'ENTITY' : 'CENTER',
            scopeId: scope.id,
            businessId: staff.entityId,
            payRate: 0,
            type: 'Hourly',
          },
        ],
      });
    },
    [positionsByScopeId, staff.entityId, staff.id]
  );

  const handleSavePositionsAtScope = useCallback(
    (scope: ICenter | IEntity) => {
      const savedPositionsAtScope = staff.positions.filter((p) => p.scopeId === scope.id) ?? [];
      const editedPositionsAtScope = positionsByScopeId[scope.id] ?? [];
      const newPositions = editedPositionsAtScope.filter((p) => !p.id);
      const deletedPositions = savedPositionsAtScope.filter(
        (p) => !editedPositionsAtScope.map((up) => up.id).includes(p.id)
      );
      const updatedPositions = editedPositionsAtScope.filter((p) => {
        if (!p.id) return false;
        const savedPosition = savedPositionsAtScope.find((sp) => sp.id === p.id);
        return savedPosition?.payRate !== p.payRate || savedPosition.type !== p.type;
      });
      // @ts-ignore - ts wants all promise types to be the same in promise all
      Promise.all([
        ...newPositions.map((newPosition) =>
          createPositionFn({
            variables: {
              input: {
                businessId: newPosition.businessId,
                payRate: newPosition.payRate,
                staffId: newPosition.personId,
                positionId: newPosition.positionId,
                type: newPosition.type,
                scopeId: newPosition.scopeId,
                scopeType: newPosition.scopeType,
              },
            },
          })
        ),
        ...deletedPositions.map((deletedPosition) =>
          deletePositionFn({ variables: { positionId: deletedPosition.id } })
        ),
        ...updatedPositions.map((updatedPosition) =>
          updatePositionFn({
            variables: {
              input: {
                id: updatedPosition.id ?? '',
                payRate: updatedPosition.payRate,
                type: updatedPosition.type,
              },
            },
          })
        ),
      ])
        .then(() => {
          setScopesInEdit(scopesInEdit.filter((s) => s !== scope.id));
          showToast('Positions updated successfully.', 'success');
        })
        .catch(() => showToast('There was an error trying to update positions. Please try again later.', 'error'));
    },
    [createPositionFn, deletePositionFn, positionsByScopeId, scopesInEdit, staff.positions, updatePositionFn]
  );

  // @ts-ignore
  return (
    <div>
      <TableHeader className="affiliations-table-header mb-4">
        <div className="header-text my-1">
          {' '}
          {staff.roleship.scopeType === 'CENTER' ? fieldLabels.center : 'Business '} Affiliations
        </div>
      </TableHeader>
      {cast<ICenter[]>(staffScopes).map((scope) => (
        <Card className="mx-0 mt-0 mb-4" key={scope.id}>
          <Card.Header className="font-weight-normal">
            <div className="d-flex align-items-center">
              <Avatar
                size="md"
                image={scope.avatar && scope.avatar.url}
                initials={scope.name && scope.name[0].toUpperCase()}
              />
              <div className="mx-4">{scope.name}</div>
              {isPrimaryCenter(scope) && (
                <ColoredBackgroundTag color={colors.seafoamGreen} text={`Primary ${fieldLabels.center}`} />
              )}
              {!isPrimaryCenter(scope) && staff.roleship.scopeType === 'CENTER' && (
                <IconButtonCircle
                  className="ml-auto"
                  color="gray"
                  size="sm"
                  icon={faTimes}
                  tooltipText="Remove affiliation"
                  onClick={() => setCenterToRemove(scope)}
                />
              )}
            </div>
          </Card.Header>
          <Card.Body>
            {positionsByScopeId[scope.id] && positionsByScopeId[scope.id].length < 1 && (
              <Alert className="mb-4" variant="warning">
                A position is missing for this {fieldLabels.center.toLowerCase()}! Please add one to enable the
                timeclock for this employee.
              </Alert>
            )}
            <div className="d-flex">
              <FormWrapper2
                formIsDirty={scopesInEdit.includes(scope.id)}
                toggleDirty={(bool) =>
                  setScopesInEdit((prev) => (bool ? [...prev, scope.id] : prev.filter((s) => s !== scope.id)))
                }
                onCancel={() =>
                  updatePositionsByScopeId((prev) => ({
                    ...prev,
                    [scope.id]: staff.positions
                      .filter((p) => p.scopeId === scope.id)
                      .map((s) => staffPositionToPositionFormData(s)),
                  }))
                }
                saveDisabled={(positionsByScopeId[scope.id] ?? []).some((p) => !p.positionId)}
                loading={scopesInEdit.includes(scope.id) && (createLoading || updateLoading || deleteLoading)}
                onSave={() => {
                  handleSavePositionsAtScope(scope);
                }}
              >
                <div>
                  {positionsByScopeId[scope.id] &&
                    positionsByScopeId[scope.id].map((position, index) => (
                      <div className="d-flex" key={index}>
                        <EmployeePositionInputs
                          position={position}
                          assignedStaffPositions={positionsByScopeId[scope.id]}
                          updatePosition={(position) => handleUpdatePositionLocally(position, scope, index)}
                          businessId={staff.entityId}
                          // when updating a StaffPosition you can't update the underlying position, only the pay rate and pay rate type
                          disablePositionDropdown={
                            !isPrimaryCenter(scope)
                              ? (position.id !== null && position.id !== undefined) || !hasCreatePositionPermission
                              : (position.id !== null && position.id !== undefined) ||
                                !hasCreatePositionPermission ||
                                AdpEnabled
                          }
                          enableEditPayrate={
                            !isPrimaryCenter(scope) ? hasEditPayratePermission : hasEditPayratePermission && !AdpEnabled
                          }
                          disabled={staff.employmentStatus === 'Deactivated'}
                          enablePayrateView={hasReadPayratePermission}
                          adpEnabled={AdpEnabled}
                        />
                        {(positionsByScopeId[scope.id].filter((p) => Boolean(p.id)).length > 1 || !position.id) && (
                          <IconButton
                            className="mt-2 sm-icon-width"
                            icon={faTrashAlt}
                            onClick={() => handleDeletePositionLocally(scope, index)}
                          />
                        )}
                      </div>
                    ))}
                  {(!isPrimaryCenter(scope)
                    ? staff.employmentStatus !== 'Deactivated'
                    : staff.employmentStatus !== 'Deactivated' && !AdpEnabled) && (
                    <ButtonAsLink onClick={() => handleAddPositionLocally(scope)} className="">
                      <FontAwesomeIcon icon={faPlus} size="sm" className="mr-2" />
                      Add another position
                    </ButtonAsLink>
                  )}
                </div>
              </FormWrapper2>
              {!isPrimaryCenter(scope) && staff.roleship.scopeType === 'CENTER' && (
                <Button
                  className="ml-auto"
                  variant="outline-secondary"
                  onClick={() => handleMakePrimary(scope)}
                  loading={loading}
                >
                  Make Primary
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      ))}
      {centerToRemove && (
        <RemoveAffiliationModal
          staff={staff}
          center={centerToRemove}
          isOpen={Boolean(centerToRemove)}
          onClose={() => setCenterToRemove(null)}
          loading={removalLoading}
          removeFn={handleRemoveCenter}
        />
      )}
    </div>
  );
};

export default CenterAffiliationsTable;
