/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'shared/components/Layout';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import { useCreateKisiShare, useRemoveKisiShare, useUpdateStaffProfile } from 'gql/staff/mutations';
import AddressInput from 'shared/components/AddressInput';
import errorMessage from 'shared/constants/errorMessages';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import PhoneInput from 'shared/components/PhoneInput';
import { showToast } from 'shared/components/Toast';
import { omitTypename, omitFalsy } from 'shared/util/object';
import cast from 'shared/util/cast';
import { isValidPhoneNumber } from 'shared/util/string';
import moment from 'moment';
import Button from 'shared/components/Buttons';
import Card from 'shared/components/Card/Card';
import { ExecutionResult } from 'graphql';

interface IProps {
  kisiShareInfo: IKisiShare | undefined;
  personInfo: IStaff | undefined;
  loading: boolean;
  loadingLines: number;
}

const KisiCard: React.FC<IProps> = ({ kisiShareInfo, personInfo, loading, loadingLines, ...props }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);

  const [createKisiShare, { loading: createKisiShareLoading }] = useCreateKisiShare({
    onCompleted: (data: ICreateShareData) => {
      showToast('Staff updated successfully.', 'success');
      if (data) {
        updatePersonalInformation(data.createKisiShare);
      }

      setFormIsDirty(false);
    },
    onError: () => {
      showToast('There was an error updating staff. Please try again later.', 'error');
    },
  });
  const [removeKisiShare, { loading: removeKisiShareLoading }] = useRemoveKisiShare({
    onCompleted: () => {
      showToast('Staff updated successfully.', 'success');
      updatePersonalInformation({
        businessId: undefined,
        id: undefined,
        kisiEmail: undefined,
        kisiShareId: undefined,
        personId: undefined,
      });
      setFormIsDirty(false);
    },
    onError: () => {
      showToast('There was an error updating staff. Please try again later.', 'error');
    },
  });
  const [kisiShare, updatePersonalInformation] = useState({
    ...kisiShareInfo,
  });
  React.useEffect(() => {
    updatePersonalInformation({ ...kisiShareInfo });
  }, [kisiShareInfo]);

  const handleFormUpdateAndToggleDirty = (value: any, name: string | symbol) => {
    updatePersonalInformation({
      ...kisiShare,
      [name]: value,
    });
    !formIsDirty && setFormIsDirty(true);
  };
  const save = () => {
    if (personInfo == null) {
      return;
    }
    createKisiShare({
      variables: {
        input: {
          businessId: personInfo.entityId,
          kisiEmail: personInfo.email,
          name: (personInfo.nickname ?? personInfo.firstname) + ' ' + personInfo.lastname,
          personId: personInfo.id,
          kisiShareId: kisiShare.kisiShareId ?? 0,
        },
      },
    });
  };

  const removeKisiReference = () => {
    if (personInfo == null) {
      return;
    }
    if (kisiShare == null || kisiShare.id == null) {
      return;
    }
    removeKisiShare({
      variables: {
        businessId: personInfo.entityId,
        shareId: kisiShare.id,
      },
    });
  };

  const formInvalid = (kisiShare.kisiShareId ?? 0) <= 0;

  return (
    <FormWrapper2
      formId="staff-kisi-info"
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onSave={save}
      loading={createKisiShareLoading}
      toggleDirtyOnSave={false}
      saveDisabled={formInvalid}
    >
      <Card header="Kisi Group Id" loading={loading} loadingLines={7}>
        <p>
          By entering the Kisi Group ID you will allow this staff member access to all Kisi doors and locks shared with
          this particular Group ID. <br />
          To view the ID and what access it has, please contact your Kisi adminstrator.
        </p>
        <Row>
          <Col md className="col-md-8 col-sm-12">
            <NumberInput
              label="Kisi Group Id"
              id="kisishareid-input"
              name="kisiShareId"
              value={kisiShare.kisiShareId}
              onChange={handleFormUpdateAndToggleDirty}
              disabled={kisiShare.id != null}
            />
            <div className="d-flex">
              {kisiShare.id != null && (
                <Button variant="outline-secondary" onClick={removeKisiReference} disabled={removeKisiShareLoading}>
                  Remove Kisi Reference
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </FormWrapper2>
  );
};

export default KisiCard;
