import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import ActionDropdown from 'shared/components/ActionDropdown';
import { PersonAvatar } from 'shared/components/Avatar';
import DataTable from 'shared/components/DataTable';
import { Col, Row } from 'shared/components/Layout';
import RemoveContactRelationshipModal from 'shared/components/Modals/RemoveContactRelationshipModal';
import SearchInput from 'shared/components/SearchInput';
import Select from 'shared/components/Select';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import { getFullName } from 'shared/util/string';
import EditContactChildPermissionsModal from './EditContactChildPermissionsModal';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { Container } from 'react-bootstrap';
import { relationshipPermissionType } from 'shared/constants/enums/RelationshipPermissionType';

interface IModalStateShape {
  open: boolean;
  child: IContactChild | null;
}

interface IProps {
  contact: IContact;
  loading: boolean;
}

const ChildrenTab: React.FC<IProps> = ({ contact, loading, ...props }) => {
  const accounts = orderBy(contact?.accounts ?? [], 'name');
  const defaultAccountId = accounts[0]?.id ?? '';
  const [searchTerm, setSearchTerm] = useState('');
  const [accountId, setAccountId] = useState(defaultAccountId);
  useEffect(() => setAccountId(defaultAccountId), [defaultAccountId]);

  const children = orderBy(contact?.children ?? [] ?? [], 'firstname').filter(
    (c) =>
      c.accountPermissions?.map((acc) => acc.accountId).includes(accountId) &&
      (!searchTerm || getFullName(c).toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const [removeRelationshipModalState, setRemoveRelationshipModalState] = useState<IModalStateShape>({
    open: false,
    child: null,
  });
  const [editModalState, setEditModalState] = useState<IModalStateShape>({ open: false, child: null });

  const hasDeleteChildPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });

  return (
    <>
      <Row noGutters className="bg-white p-4 rounded">
        <SearchInput onChange={setSearchTerm} />
        <Select
          isLoading={loading}
          options={accounts.map((a) => ({ value: a.id, label: `${a.center?.name ?? ''} - ${a.name}` }))}
          value={accountId}
          onChange={(o) => setAccountId(o.value)}
          className="mb-0 ml-auto flex-grow-0 w-260px"
        />
      </Row>
      <div className="m-n8">
        <DataTable
          data={children}
          showPagination={false}
          showSelect={false}
          noPadding={false}
          columns={[
            {
              dataField: 'firstname',
              text: 'Name',
              classes: 'lg-column',
              sort: true,
              formatter: (name: string, row: IContactChild) => (
                <Row noGutters>
                  <Link to={`/families/children/${row.childId}`} className="d-flex align-items-center">
                    <PersonAvatar size="md" person={row} className="mr-4" />
                    <div className="text-text-color mr-4">{getFullName(row)}</div>
                  </Link>
                  <div className="ml-auto mr-4">
                    {row.accountPermissions.find((a) => a.accountId === accountId)?.isPrimary ? (
                      <small className="bg-warning px-2 py-1 rounded border sm text-text-color">Primary</small>
                    ) : (
                      ''
                    )}
                  </div>
                </Row>
              ),
            },
            {
              dataField: 'relationshipType',
              text: 'Relationship',
              classes: 'md-column',
              sort: true,
              formatter: (relationshipType: string, row: IContactChild) => (
                <div className="tag" style={{ width: '140px' }}>
                  <div className="mx-auto">{childContactRelationship[row.relationshipType]}</div>
                </div>
              ),
            },

            {
              dataField: 'accountPermissions',
              text: 'Permission',
              formatter: (accountPermissions: IAccountPermissions[]) => (
                <Container>
                  <Row>
                    {accountPermissions
                      .find((a) => a.accountId === accountId)
                      ?.permissions.map((v) => {
                        return (
                          <Col className="m-2">
                            <small className="bg-info-10 px-2 py-1 rounded border sm text-overflow-ellipsis">
                              {relationshipPermissionType[v]}
                            </small>
                          </Col>
                        );
                      })}
                  </Row>
                </Container>
              ),
            },
            {
              dataField: 'actions',

              isDummyField: true,
              headerAlign: 'right',
              align: 'right',
              formatter: (cell: any, row: IContactChild) => (
                <ActionDropdown
                  actions={[
                    ...(hasDeleteChildPermission
                      ? [
                          {
                            label: 'Remove',
                            onClick: () => setRemoveRelationshipModalState({ open: true, child: row }),
                            icon: faTrashAlt,
                          },
                        ]
                      : []),
                    {
                      label: 'Edit',
                      onClick: () => setEditModalState({ open: true, child: row }),
                      icon: faPencilAlt,
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </div>
      {removeRelationshipModalState.child && contact && (
        <RemoveContactRelationshipModal
          isOpen={removeRelationshipModalState.open}
          onClose={() => setRemoveRelationshipModalState({ open: false, child: null })}
          childId={removeRelationshipModalState.child.childId ?? ''}
          childName={getFullName(removeRelationshipModalState.child)}
          contactId={contact.id}
          contactName={getFullName(contact)}
          accountPermissions={removeRelationshipModalState.child.accountPermissions}
        />
      )}
      {editModalState.child && contact && (
        <EditContactChildPermissionsModal
          contact={contact}
          child={editModalState.child}
          isOpen={editModalState.open}
          onClose={() => setEditModalState({ open: false, child: null })}
          accountId={accountId}
        />
      )}
    </>
  );
};

export default ChildrenTab;
