import React from 'react';

import { ApolloApiProvider } from 'shared/util/apolloProvider';
import { createHooks } from 'shared/util/apolloHooks';
import { createClient } from './client';
import config from 'config';

const coreApiContext = React.createContext();

export const CoreApolloClient = createClient(config.api.core.uri);

export const Provider = ({ token, children }) => (
  <ApolloApiProvider
    contextType={coreApiContext}
    uri={config.api.core.uri}
    websocketUri={config.api.core.websocketUri}
    token={token}
    withApolloNativeProvider
  >
    {children}
  </ApolloApiProvider>
);

export const { useApolloClient, useQuery, useLazyQuery, useMutation } = createHooks(coreApiContext);
