import React, { useState } from 'react';
//components
import * as Mui from '@mui/material';
import { DropdownItem, ModalWindowCWA, PreviewModalCwa } from './index';
import { IconButtonCircle } from 'shared/components/Buttons';
//utils
import { useTranslation } from 'react-i18next';
import { capitalize } from 'shared/util/string';
import { showToast } from 'shared/components/Toast';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { useCancelArrangementCWA, useSendResendEmail } from '../graphql/mutations';

interface IProps {
  entityId: string;
  data: {
    id: string;
    accountId: string;
    accountName: string;
    centerId: string;
    centerName: string;
    childId: string;
    childFirstName: string;
    childLastName: string;
    enrolmentId: string;
    status: string;
  };
  refetch: () => void;
}
interface ModalData {
  title: string;
  content: string;
  submit: string;
  isOpen: boolean;
  type: string;
}

interface ModalInfo {
  id: number;
  type: string;
  function: string;
  optionsContent?: any;
}

interface PreviewModalData {
  isOpen: boolean;
  type: string;
}

export const ActionsColumnCWA: React.FC<IProps> = ({ entityId, data, refetch }) => {
  const { t } = useTranslation(['billing', 'subsidies']);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [modal, setModal] = useState<ModalData>({
    title: '',
    content: '',
    submit: '',
    isOpen: false,
    type: '',
  });
  const [previewModal, setPreviewModal] = useState<PreviewModalData>({
    isOpen: false,
    type: '',
  });
  const [cancelArrangement, { loading: loadingCancelArrangement }] = useCancelArrangementCWA({
    businessId: entityId,
    arrangementId: data ? data.id : '',
  });

  const [sendEmail, { loading: loadingSendEmail }] = useSendResendEmail({
    businessId: entityId,
    arrangementId: data ? data.id : '',
  });

  const isReadyToSend: boolean | undefined = data.status === capitalize(t('subsidies:cwa.status.ready-to-be-sent'));

  const modalData: ModalInfo[] | (null | ModalInfo)[] = [
    isReadyToSend
      ? {
          id: 1,
          type: 'CANCEL',
          function: 'cancel-arrangement',
          optionsContent: {
            child: data?.childFirstName,
          },
        }
      : null,
    {
      id: 2,
      type: isReadyToSend ? 'SEND' : 'RESEND',
      function: isReadyToSend ? 'send-email' : 'resend-email',
      optionsContent: {
        child: data?.childFirstName,
      },
    },
  ];

  const hideModal = () => {
    setModal({
      title: '',
      content: '',
      submit: '',
      isOpen: false,
      type: '',
    });
    setPreviewModal({
      isOpen: false,
      type: '',
    });
  };

  const submitModalCwaWindow = () => {
    if (modal.type === 'CANCEL') {
      cancelArrangement()
        .then(() => {
          !loadingCancelArrangement &&
            showToast(t('subsidies:cwa.message-toasts.success.cancel-arrangement'), 'success');
          hideModal();
        })
        .then(() => refetch())
        .catch(() => {
          !loadingCancelArrangement && showToast(t('subsidies:cwa.message-toasts.error.cancel-arrangement'), 'error');
          hideModal();
        });
    }
    if (modal.type === 'SEND') {
      sendEmail()
        .then(() => {
          !loadingSendEmail && showToast(t('subsidies:cwa.message-toasts.success.send-email'), 'success');
          data.status = 'Pending';
          hideModal();
        })
        .then(() => refetch())
        .catch(() => {
          !loadingSendEmail && showToast(t('subsidies:cwa.message-toasts.error.send-email'), 'error');
          hideModal();
        });
    }
    if (modal.type === 'RESEND') {
      sendEmail()
        .then(() => {
          !loadingSendEmail && showToast(t('subsidies:cwa.message-toasts.success.resend-email'), 'success');
          hideModal();
        })
        .then(() => refetch())
        .catch(() => {
          !loadingSendEmail && showToast(t('subsidies:cwa.message-toasts.error.resend-email'), 'error');
          hideModal();
        });
    }
    return '';
  };

  const actionShowCondition: boolean | undefined =
    isReadyToSend || data.status === capitalize(t('subsidies:cwa.status.pending'));

  return (
    <div className="position-relative">
      <IconButtonCircle
        id="action-dropdown-btn"
        iconSize="2x"
        icon={faEllipsisH}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      />
      <Mui.Menu
        PaperProps={{ sx: { boxShadow: '1' } }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {actionShowCondition &&
          modalData.map((item) => {
            if (item === null) return '';
            return <DropdownItem setModal={setModal} setAnchorEl={setAnchorEl} item={item} key={item.id} />;
          })}
        <Mui.MenuItem
          onClick={() => {
            setPreviewModal({
              isOpen: true,
              type: 'PREVIEW',
            });
            setAnchorEl(null);
          }}
        >
          {t('subsidies:cwa.modal.submit.preview-email')}
        </Mui.MenuItem>
      </Mui.Menu>
      <ModalWindowCWA modal={modal} hide={hideModal} submit={submitModalCwaWindow} />
      {previewModal.isOpen && (
        <PreviewModalCwa modal={previewModal} hide={hideModal} entityId={entityId} arrangementId={data.id} />
      )}
    </div>
  );
};
