import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { useDispatch } from 'react-redux';
import { ccssCertDeterCategoryForCenterResultFields, certificateFields, accsCertDertPagedResultFields } from './fields';
import { groupBy } from 'lodash';
import { getCcsCertificateForAccountChild } from 'pages/Families/subroutes/Accounts/duck/actions';
import { QueryHookOptions } from '@apollo/client';

interface IGetCcsCertificatesForChildrenVariables {
  businessId: string;
  centerId: string;
  children: string[];
}

export const GET_CCS_CERTIFICATES_FOR_CHILDREN = gql`
  query($businessId: ID!, $centerId: ID!, $children:[ID!]!) {
    getCcssCertificatesForChildren(businessId: $businessId, centerId: $centerId, children: $children) {
      ${certificateFields}
    }
  }
`;

export const useGetCcsCertificatesForChildren = (
  businessId: string,
  centerId: string,
  accountId: string,
  children: string[]
) => {
  const dispatch = useDispatch();

  return useQuery<{ getCcssCertificatesForChildren: ICcssCertificate[] }, IGetCcsCertificatesForChildrenVariables>(
    GET_CCS_CERTIFICATES_FOR_CHILDREN,
    {
      variables: {
        businessId: businessId,
        centerId: centerId,
        children: children,
      },
      fetchPolicy: 'cache-and-network',
      skip: businessId === '',
      onCompleted: (data) => {
        const childCertificates = groupBy(data.getCcssCertificatesForChildren, (e) => e.childId);

        for (let childId in childCertificates) {
          const certificates = childCertificates[childId];
          dispatch(getCcsCertificateForAccountChild(accountId, childId, certificates));
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

interface IGetCcsCategoriesForCenterVariables {
  input: IAccsDisplayCategoriesInput;
}
interface IGetCcsCategoriesForCenterData {
  getCcsCategoriesForCenter: Record<AccsDisplayCategory, number>;
}

export const useGetCcsCategoriesForCenter = (
  options?: QueryHookOptions<IGetCcsCategoriesForCenterData, IGetCcsCategoriesForCenterVariables>,
  fields?: string
) =>
  useQuery<IGetCcsCategoriesForCenterData, IGetCcsCategoriesForCenterVariables>(
    GET_CCSS_CATEGORIES_FOR_CENTERS(fields),
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

const GET_CCSS_CATEGORIES_FOR_CENTERS = (fields: string = ccssCertDeterCategoryForCenterResultFields) => gql`
  query($input: AccsDisplayCategoriesInput!) {
    getCcsCategoriesForCenter(input: $input) {
      ${fields}
    }
  }
`;
interface IGetAcssCertDertsForCentersData {
  getAcssCertDertsForCenters: IPagedResult<IAccsCertDertForCenters>;
}

const GET_ACCS_CERT_DERT_FOR_CENTERS = (fields: string = accsCertDertPagedResultFields) => gql`
  query($businessId: ID!, $centerIds: [ID!]!, $category: AccsDisplayCategory, $searchText: String, $pageNumber: Int, $pageSize: Int, $sortDtos: [SearchSort], $statusType: AccountStatusType, $statusAtDate: String) {
    getAcssCertDertsForCenters(businessId: $businessId, centerIds: $centerIds, category: $category, searchText: $searchText, pageNumber: $pageNumber, pageSize: $pageSize, sortDtos: $sortDtos, statusType: $statusType, statusAtDate: $statusAtDate) {
      ${fields}
    }
  }
`;

interface IGetAcssCertDertsForCentersVariables {
  businessId: string;
  centerIds: string[];
  category?: AccsDisplayCategoryEnum;
  searchText?: string;
  pageNumber?: number;
  pageSize?: number;
  sortDtos?: IElasticsearchSortFilter[];
  statusType?: AccountStatusType;
  statusAtDate: string;
}

export const useGetAcssCertDertsForCenters = (
  options?: QueryHookOptions<IGetAcssCertDertsForCentersData, IGetAcssCertDertsForCentersVariables>,
  fields?: string
) =>
  useQuery<IGetAcssCertDertsForCentersData, IGetAcssCertDertsForCentersVariables>(
    GET_ACCS_CERT_DERT_FOR_CENTERS(fields),
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );
