export const cpavServiceFields = `
  contactEmail
  contactPhone
  contactMobile
  contactServiceUrl
  hasVacancyMon
  hasVacancyTue
  hasVacancyWed
  hasVacancyThu
  hasVacancyFri
  hasVacancySat
  hasVacancySun
  feeServiceUrl
  lastSubmitted
  lastUpdated
  centerIsOpen
  centerOpening
  centerClosing
  nextAutomatedSubmission
  openingHours {
    careType
    openTime
    closeTime
  }
  fees {
    AFTSCH {
      ageGroup
      inclusions
      usualFeeAmount
    }
    BEFSCH {
      ageGroup
      inclusions
      usualFeeAmount
    }
    FULLDY {
      ageGroup
      inclusions
      usualFeeAmount
    }
    HALFDY {
      ageGroup
      inclusions
      usualFeeAmount
    }
    HOURLY {
      ageGroup
      inclusions
      usualFeeAmount
    }
  }
`;
