import React, { useMemo, useRef } from 'react';
//components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
//utils
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { getHostName } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface IProps {
  currentCenterId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const EnquiryForm: React.FC<IProps> = ({ currentCenterId, isOpen, onClose }) => {
  const { t } = useTranslation();
  const formPreviewRef = useRef<HTMLIFrameElement>(null);
  const user = useSelector((state: RootState) => state.user);

  const url = useMemo(
    () => `https://${getHostName()}/c-inquiry/${currentCenterId}/staff/${user?.id}`,
    [currentCenterId, user?.id]
  );

  return (
    <Modal
      show={isOpen}
      backdrop="static"
      onHide={onClose}
      centered
      size="xl"
      scrollable
      className="form-preview enquire-iframe"
    >
      <Modal.Header>
        {t('enrollment.lead-management.enquiry-form')}
        <FontAwesomeIcon icon={faTimes} onClick={onClose} />
      </Modal.Header>
      <iframe
        id="enquire-iframe"
        ref={formPreviewRef}
        loading="eager"
        title="preview"
        style={{ border: 'none' }}
        src={url}
        width="100%"
        height="100%"
      />
    </Modal>
  );
};
