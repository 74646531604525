import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Col, Row } from 'shared/components/Layout';
import ModalDrawer from 'shared/components/ModalDrawer';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { useGetIsPaymentReportLazy } from 'gql/reports/queries';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { showToast } from 'shared/components/Toast';
import { convertTimeRangeObjectToString, convertTimeRangeStringToObject } from 'shared/util/timeUtils';
import Select from 'shared/components/Select';
import DateInput from 'shared/components/DateInput';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const IsPaymentReportModal: React.FC<IProps> = ({ isOpen, onClose, ...props }) => {
  const { t } = useTranslation(['translation', 'timelog', 'ccss', 'reporting']);
  const reportDataToFile = useReportDataToFile();
  const user = useSelector((state: RootState) => state.user);
  const [centerIds, setCenterIds] = useState<string[] | null>(null);
  const [dateRange, setDateRange] = useState<ITimeRange>(convertTimeRangeStringToObject('Last 2 Weeks'));

  const resetFormData = () => {
    setDateRange(convertTimeRangeStringToObject('Last 2 Weeks'));
  };

  const handleClose = useCallback(() => {
    resetFormData();
    onClose();
  }, [onClose]);

  const [getIsPaymentReportFn, { loading }] = useGetIsPaymentReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(result.getIsPaymentReport, t('reporting:report-file-name.is-payment'));
      handleClose();
    },
    onError: (error) => {
      showToast(t('translation:reports.general-failure-message'), 'error');
    },
  });

  const handleSubmit = useCallback(() => {
    getIsPaymentReportFn({
      variables: {
        input: {
          businessId: user?.entityId ?? '',
          centerIds: centerIds ?? [],
          startDate: moment(dateRange.start).format('YYYY-MM-DD'),
          endDate: moment(dateRange.end).format('YYYY-MM-DD'),
        },
      },
    });
  }, [getIsPaymentReportFn, centerIds, dateRange, user]);

  const dateRangeOptions: TimeRange[] = ['Last 2 Weeks', 'This Week', 'Last Week', 'Last Month', 'Custom'];
  const isDateValid = !!(dateRange.start && dateRange.end && moment(dateRange.start).isBefore(moment(dateRange.end)));
  const formDisabled = !isDateValid;

  return (
    <ModalDrawer
      // @ts-ignore - TypeScript doesn't like the dynamic lookup
      title={t('ccss:is-payment-report')}
      show={isOpen}
      onHide={handleClose}
      primaryButtonProps={{ disabled: formDisabled, loading }}
      primaryChoice={t('translation:run-report')}
      primaryCallback={handleSubmit}
      closeOnPrimaryCallback={false}
      secondaryCallback={handleClose}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            selectedCenterIds={centerIds}
            onSelect={(centerIds) => setCenterIds(centerIds)}
            useNullForAllOption={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="pl-2">{''}</Row>
          <Row className="pl-2">
            <Select
              name="status"
              options={dateRangeOptions}
              label={t('timelog:time-card.date-range-label')}
              value={convertTimeRangeObjectToString(dateRange)}
              onChange={(string) => setDateRange(convertTimeRangeStringToObject(string))}
            />
          </Row>
          <Row className="pl-2" justify="around">
            <DateInput
              date={dateRange.start}
              onDateSelect={(start) => setDateRange({ ...dateRange, start })}
              isValid={isDateValid}
              hasCustomValidation
              required
              className="flex-grow-0 my-2"
            />
            <div className="my-2">to</div>
            <DateInput
              date={dateRange.end}
              isValid={isDateValid}
              hasCustomValidation
              onDateSelect={(end) => setDateRange({ ...dateRange, end })}
              required
              className="flex-grow-0 my-2"
            />
          </Row>
        </Col>
      </Row>
    </ModalDrawer>
  );
};

export default IsPaymentReportModal;
