import React, { useMemo, useState, useCallback } from 'react';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { showToast } from 'shared/components/Toast';
import Alert from 'shared/components/Alert';
import { findIndex, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import ClassFeeGroup from './ClassFeeGroup';
import { useBulkEditFee } from 'gql/session/mutations';
import moment from 'moment';

interface IProps {
  isOpen: boolean;
  onClose: (selectedSessions: ISession[]) => void;
  sessions: ISession[];
  date: string;
  centerId: string;
  refetchExpectedSessions: () => void;
}
interface IFormShape {
  fees: IFeeInBulkEdit[] | null;
}

const BulkEditFeeModal: React.FC<IProps> = ({ isOpen, onClose, refetchExpectedSessions, sessions, date, centerId }) => {
  const { t } = useTranslation();
  const [formSaving, setFormSaving] = useState(false);
  const [formData, setFormData] = useState<IFormShape>({
    fees: null,
  });

  const classes = uniqBy(sessions, 'classId').map((s) => ({ classId: s.classId, className: s.class.name }));

  const handleUpdate = (selected: IFeeInBulkEdit) => {
    const existedFeeInSelectedClass =
      findIndex((formData.fees ?? []).filter((s) => s.classId === selected.classId)) > -1;
    if (!existedFeeInSelectedClass) {
      setFormData({ ...formData, fees: [...(formData.fees ?? []), selected] });
    } else {
      const existedInFees =
        findIndex((formData.fees ?? []).filter((s) => s.classId === selected.classId && s.feeId === selected.feeId)) >
        -1;
      if (!existedInFees) {
        const newSelected = (formData.fees ?? []).filter((s) => s.classId !== selected.classId);
        setFormData({ ...formData, fees: [...(newSelected ?? []), selected] });
      }
    }
  };

  const [bulkEditFeeFn] = useBulkEditFee({
    onCompleted: (result) => {
      if (result?.bulkEditFee != null) {
        handleClose(sessions);
        if (result.bulkEditFee.updatedSessions.length > 0) {
          showToast(
            t('attendance.bulk-edit-fee.success-toast', {
              editedSessionsAmount: result.bulkEditFee.updatedSessions.length ?? '0',
            }),
            'success'
          );
        } else {
          var errorResult = 'Fees in this Classes has not been updated: ';
          result.bulkEditFee.errors.map((item) => (errorResult += item.classId + ','));
          showToast(errorResult, 'error');
        }
      }
      refetchExpectedSessions();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
      refetchExpectedSessions();
    },
  });

  const handleSubmit = () => {
    setFormSaving(true);
    bulkEditFeeFn({
      variables: {
        input: {
          centerId: centerId as string,
          fees: formData.fees ? [...formData.fees] : [],
        },
      },
    });
  };

  const handleClose = useCallback(
    (selectedSessions: ISession[]) => {
      setFormData({
        fees: null,
      });
      setFormSaving(false);
      onClose(selectedSessions);
    },
    [onClose]
  );
  const sessionsHaveFlatRate = sessions?.some((s) => s.fee.feeType === 'FLAT_RATE');
  const selectedSessionsAmount = formData.fees?.reduce((acc, curr) => acc + (curr.sessionIds?.length ?? 0), 0) ?? 0;
  const selectedContractsAmount = formData.fees?.reduce((acc, curr) => acc + (curr.contractIds?.length ?? 0), 0) ?? 0;
  const allSessions = selectedSessionsAmount + selectedContractsAmount;

  const numberOfChildren = uniqBy(sessions, 'childId').length;

  const message = useMemo(() => {
    if (sessionsHaveFlatRate) {
      return (
        <Alert variant="danger">
          <div className="flex-row mb-4">
            {t('attendance.bulk-edit-fee.flat-rate-edit-error')}
            <div className="flex-row mb-4">
              <ul className="unstyled-list">
                {sessions
                  ?.filter((s) => s.fee.feeType === 'FLAT_RATE')
                  .map((a) => (
                    <li>
                      {a.child.firstname + ' ' + a.child.lastname} ({a.class.name})
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </Alert>
      );
    }

    if (formData.fees) {
      return allSessions === 0 ? (
        <Alert variant="warning" header="Please Note:">
          <div>{t('attendance.bulk-edit-fee.session-amount-error')}</div>
        </Alert>
      ) : (
        <Alert variant="warning" header="Please Note:">
          <div>{t('attendance.bulk-edit-fee.session-amount-warning', { selectedSessionsAmount: allSessions })}</div>
        </Alert>
      );
    }

    return (
      <Alert variant="warning" header="Please Note:">
        {t('attendance.bulk-edit-fee.modal-warning', { numberOfChildren: numberOfChildren })}
      </Alert>
    );
  }, [sessions, formData.fees]);

  const isValid = useMemo(() => {
    if (sessionsHaveFlatRate || !formData.fees || allSessions === 0) {
      return false;
    }
    return true;
  }, [formData.fees, sessionsHaveFlatRate, allSessions]);

  return (
    <div>
      <CenteredModal
        size="lg"
        title={t('attendance.bulk-edit-fee.modal-title')}
        show={isOpen}
        onHide={() => handleClose(sessions)}
        primaryCallback={handleSubmit}
        secondaryCallback={() => handleClose(sessions)}
        primaryChoice="Update"
        primaryButtonProps={{
          disabled: !isValid,
          loading: formSaving,
        }}
      >
        <div className="d-flex flex-row mb-4">
          <div className="d-flex flex-column flex-1 mr-4">
            <label className="text-nowrap">Applying Date</label>
            <p className="mb-0">{moment(date).format('DD-MM-YYYY')}</p>
          </div>
          <div className="d-flex flex-column flex-1 mr-4">
            <label className="text-nowrap">Selected Children</label>
            <p className="mb-0">{numberOfChildren}</p>
          </div>
        </div>
        <ul className="unstyled-list mb-4">
          {classes.map((c) => (
            <li key={c.classId}>
              <ClassFeeGroup
                className={c.className}
                classId={c.classId}
                date={date}
                sessions={sessions.filter((s) => s.classId === c.classId)}
                onSelect={handleUpdate}
              />
            </li>
          ))}
        </ul>
        {message}
      </CenteredModal>
    </div>
  );
};

export default BulkEditFeeModal;
