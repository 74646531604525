import i18n from 'i18n';
import { capitalize } from 'shared/util/string';
import { isRegion } from 'shared/util/region';

i18n.loadNamespaces(['billing', 'translation']);
const isAuRegion = isRegion('AU');

export const GLCodeTypeOptions: { value: GLCodeAreaType; label: string }[] = [
  { label: capitalize(i18n.t('translation:spelling.fee')), value: 'FEE' },
  {
    label: i18n.t('billing:gl-codes.types.discount'),
    value: 'DISCOUNT',
  },
  ...(isAuRegion
    ? []
    : [
        {
          label: i18n.t('billing:gl-codes.types.agency'),
          value: 'AGENCY' as GLCodeAreaType,
        },
      ]),
  {
    label: i18n.t('billing:gl-codes.types.payment-surcharge'),
    value: 'PAYMENT_SURCHARGE',
  },
  {
    label: i18n.t('billing:gl-codes.types.system-transaction'),
    value: 'SYSTEM_TRANSACTION_TYPE',
  },
  ...(isAuRegion
    ? []
    : [
        {
          label: i18n.t('billing:gl-codes.types.reason-transaction-type'),
          value: 'REASON_TYPE' as GLCodeAreaType,
        },
      ]),
  {
    label: i18n.t('billing:gl-codes.types.custom-transaction-type'),
    value: 'CUSTOM_TRANSACTION_TYPE',
  },
];

export const GLCodeLabelPerType: Partial<Record<GLCodeAreaType, string>> = GLCodeTypeOptions.reduce(
  (obj, item: { value: GLCodeAreaType; label: string }) => Object.assign(obj, { [item.value]: item.label }),
  {}
);

export const AreaTypeToIdName: Record<GLCodeAreaType, keyof IGLCodeMapping> = {
  NOT_SET: 'id',
  FEE: 'feeId',
  DISCOUNT: 'discountId',
  AGENCY: 'agencyId',
  PAYMENT_SURCHARGE: 'surchargeType',
  SYSTEM_TRANSACTION_TYPE: 'transactionTypeId',
  CUSTOM_TRANSACTION_TYPE: 'transactionTypeId',
  REASON_TYPE: 'adjustmentId',
};
