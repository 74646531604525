import { GET_UNBILLED_TRANSACTIONS_FOR_ACCOUNT } from 'gql/transaction/queries';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { UnbilledTransaction } from '../models/UnbilledTransaction';
import { useQuery } from '@apollo/client';

export function useGetUnbilledTransactions({ accountId }: { accountId: string }) {
  const { data, loading } = useQuery<{
    getUnbilledTransactionsForAccount: {
      id: string;
      description: string;
      amount: number;
      date: string;
      createdBy: string;
      transactionType: { name: string };
    }[];
  }>(GET_UNBILLED_TRANSACTIONS_FOR_ACCOUNT(`id description amount date createdBy transactionType { name }`), {
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      date: '',
    },
  });

  const unbilledTransactions: UnbilledTransaction[] = useMemo(() => {
    return orderBy(data?.getUnbilledTransactionsForAccount ?? [], (t) => t.date, 'desc').map((t) => {
      /**
       * not sure why this rule exists, original comment just said "because product says so"
       */
      t.description = t.createdBy !== '00000000-0000-0000-0000-000000000000' ? t.transactionType.name : t.description;
      return t;
    });
  }, [data?.getUnbilledTransactionsForAccount]);
  return { loading, unbilledTransactions };
}
