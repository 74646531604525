import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { contractFields } from './fields';
import { billingCycleFields } from 'gql/billingCycle/fields';

//interfaces
interface ICreateContractData {
  createContract: IContract;
}
interface ICreateContractVariables {
  input: ICreateContractInput;
}
interface IUpdateContractData {
  updateContract: IContract;
}
interface IUpdateContractVariables {
  input: IUpdateContractInput;
}
interface IEndAndCreateContractData {
  endAndCreateContract: IContract;
}
interface IDeleteContractData {
  deleteContract: boolean;
}
interface IDeleteContractVariables {
  contractId: string;
}
interface IEndAndCreateContractVariables {
  input: IEndAndCreateContractInput;
}
interface ICreateContractAndAccountBillingCycleData {
  createContractAndAccountBillingCycle: {
    contract: IContract;
    billingCycle: IBillingCycle;
  };
}
interface ICreateContractAndAccountBillingCycleVariables {
  input: {
    contract: ICreateContractInput;
    billingCycle: ICreateAccountBillingCycleInput;
  };
}

//mutations
export const CREATE_CONTRACT = (fields: string = contractFields) => gql`
  mutation ($input: CreateContractInput!) {
    createContract(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_CONTRACT = gql`
  mutation ($contractId: ID!) {
    deleteContract(contractId: $contractId)
  }
`;

export const UPDATE_CONTRACT = (fields: string = contractFields) => gql`
  mutation ($input: UpdateContractInput!) {
    updateContract(input: $input) {
      ${fields}
    }
  }
`;

export const END_AND_CREATE_CONTRACT = (fields: string = contractFields) => gql`
  mutation ($input: EndAndCreateContractInput!) {
    endAndCreateContract(input: $input) {
      ${fields}
    }
  }
`;

export const CREATE_CONTRACT_AND_ACCOUNT_BILLING_CYCLE = () => gql`
  mutation($input: CreateContractAndBillingCycleInput!) {
    createContractAndAccountBillingCycle(input: $input) {
      contract {
        ${contractFields}
      }
      billingCycle {
        ${billingCycleFields}
      }
    }
  }
`;

//hooks
export const useCreateContract = (
  options?: MutationHookOptions<ICreateContractData, ICreateContractVariables>,
  fields?: string
) => useMutation<ICreateContractData, ICreateContractVariables>(CREATE_CONTRACT(fields), options);

export const useUpdateContract = (
  options?: MutationHookOptions<IUpdateContractData, IUpdateContractVariables>,
  fields?: string
) => useMutation<IUpdateContractData, IUpdateContractVariables>(UPDATE_CONTRACT(fields), options);

export const useEndAndCreateContract = (
  options?: MutationHookOptions<IEndAndCreateContractData, IEndAndCreateContractVariables>,
  fields?: string
) => useMutation<IEndAndCreateContractData, IEndAndCreateContractVariables>(END_AND_CREATE_CONTRACT(fields), options);

export const useCreateContractAndAccountBillingCycle = (
  options?: MutationHookOptions<
    ICreateContractAndAccountBillingCycleData,
    ICreateContractAndAccountBillingCycleVariables
  >,
  fields?: string
) =>
  useMutation<ICreateContractAndAccountBillingCycleData, ICreateContractAndAccountBillingCycleVariables>(
    CREATE_CONTRACT_AND_ACCOUNT_BILLING_CYCLE(),
    options
  );

export const useDeleteContract = (options?: MutationHookOptions<IDeleteContractData, IDeleteContractVariables>) =>
  useMutation<IDeleteContractData, IDeleteContractVariables>(DELETE_CONTRACT, options);
