import React from 'react';
import classnames from 'classnames';

interface IProps {
  className?: string;
  id?: string;
}

// wrapper component to provide standard styling
const ControlContainer: React.FC<IProps> = ({ className = '', id = '', ...props }) => {
  return (
    <div id={id} className={classnames('kt-schedules-control-container', className)}>
      {props.children}
    </div>
  );
};

export default ControlContainer;
