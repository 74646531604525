import colors from '_colors.module.scss';

export enum CcsDebtStatusEnum {
  DET = 'DET',
  PAJ = 'PAJ',
  PFD = 'PFD',
  DWO = 'DWO',
  DMC = 'DMC',
  PWO = 'PWO',
  PWV = 'PWV',
  PIN = 'PIN',
}

export enum CcsAltArrangementType {
  P2P = 'P2P',
  WHS = 'WHS',
}

export enum CcsAltArrangementTypeLabels {
  P2P = 'Installments',
  WHS = 'Withholdings',
}

export const altArrangementTypeOptions = [
  { label: CcsAltArrangementTypeLabels.P2P, value: CcsAltArrangementType.P2P },
  { label: CcsAltArrangementTypeLabels.WHS, value: CcsAltArrangementType.WHS },
];

export enum CcsDebtStatusLabelEnum {
  DET = 'Determined',
  PAJ = 'Pending Adjustment',
  PFD = 'Pending Finalised No Debt',
  DWO = 'Determined Written-off',
  DMC = 'Referred to Collection Agent',
  PWO = 'Pending Write-off',
  PWV = 'Pending Waiver',
  PIN = 'Pending Interest',
}

export const CcsDebtColors: Record<CcsDebtStatus, { color: string; colorName: string }> = {
  [CcsDebtStatusEnum.DET]: { color: colors.success, colorName: 'success' },
  [CcsDebtStatusEnum.PAJ]: { color: colors.warning, colorName: 'warning' },
  [CcsDebtStatusEnum.PFD]: { color: colors.seafoamGreen, colorName: 'seafoamGreen' },
  [CcsDebtStatusEnum.DWO]: { color: colors.success10, colorName: 'success10' },
  [CcsDebtStatusEnum.DMC]: { color: colors.brown, colorName: 'brown' },
  [CcsDebtStatusEnum.PWO]: { color: colors.yellow, colorName: 'yellow' },
  [CcsDebtStatusEnum.PWV]: { color: colors.yellow10, colorName: 'yellow10' },
  [CcsDebtStatusEnum.PIN]: { color: colors.orange, colorName: 'orange' },
};
