import businessFields from 'gql/business/fields';
import { addressFields } from 'gql/address/fields';
import { s3SignedUrlFields } from 'gql/s3SignedUrl/fields';
import { tagFields } from 'gql/tag/fields';
import { classFields } from 'gql/class/fields';
import { spaceFields } from 'gql/space/fields';
import staffFields from 'gql/staff/fields';
import { operationHoursFields } from 'gql/operationHours/fields';
import { locationFields } from 'gql/location/fields';
import { casualBookingSettingsFields, casualBookingExclusionFields } from 'gql/casualBooking/fields';

let centerFields: string = `
  id
  name
  phoneNumber
  entityId
  primaryContactPersonId
  secondaryContactPersonId
  deactivatedAt
  deactivatedByPersonId
  deletedAt
  deletedByPersonId
  inviteCode
  timezone
  abn
  acn
  taxId
  externalId
  avatar {
    ${s3SignedUrlFields}
  }
  tags {
    ${tagFields}
  }
  address {
    ${addressFields}
  }
  enableBillingCycleProcessing
  defaultBillingCycleTemplateId
  centerEnrollmentBillingCycleTemplates {
    id
    centerId
    billingCycleTemplateId
  }
  billingEnabledSettings {
    centerId
    enableBillingCycleProcessing
    enableBillingCycleProcessingEffectiveDate
    billingEnabledAt
    billingEnabledBy
    billingEnabledByPerson {
      id
      firstname
      nickname
      lastname
    }
`;

centerFields = centerFields.concat(`
  entity {
    ${businessFields}
  }
  classes {
    ${classFields}
  }
  spaces {
    ${spaceFields}
  }
  locations {
    ${locationFields}
  }
  primaryContact {
    ${staffFields}
  }
  secondaryContact {
    ${staffFields}
  }
  staffOperationHours {
    ${operationHoursFields}
  }
  familyOperationHours {
    ${operationHoursFields}
  }
`);

export const centerFieldsWithCasualBookingSettings = `
  id
  name
  tags {
    ${tagFields}
  }
  address {
    ${addressFields}
  }
  classes{
    id
    name
    casualBookingSettings{
      ${casualBookingSettingsFields}
    }
    careType
  }
  casualBookingExclusions{
    ${casualBookingExclusionFields}
  }
`;

export default centerFields;
