import React, { useState } from 'react';
import avatar from './avatar.png';

interface IProps {
  image: string;
  initials: string;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  alt: string;
  color?: string;
}

const AvatarContent: React.FC<IProps> = ({ image, initials, size, alt, color }) => {
  const [imageSrc, setImage] = useState<string>(image);
  // default to the user's initials if an image is not provided (empty string, null, etc.)
  const useInitials: boolean = !image && initials !== '';
  return useInitials ? (
    <div className={`avatar-content avatar-content-${size} avatar-content-initials`} style={{ backgroundColor: color }}>
      <div className={`avatar-text ${size === 'xl' ? 'avatar-xl-text' : ''}`}>{initials}</div>
    </div>
  ) : (
    <img
      alt={alt}
      src={imageSrc}
      className={`avatar-content avatar-content-${size} avatar-content-img`}
      onError={(event) => setImage(avatar)}
    />
  );
};

AvatarContent.defaultProps = {
  image: '',
  initials: '',
  alt: 'kt-avatar',
};

export default AvatarContent;
