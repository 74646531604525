import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import cast from 'shared/util/cast';
import { useUpdateAllergyForChild, useArchiveAllergyForChild } from 'gql/allergy/mutations';
import { showToast } from 'shared/components/Toast';
import UpdateAllergyModal from 'shared/components/Modals/UpdateAllergyModal';
import { updateAllergyForChild } from 'pages/Families/subroutes/Children/duck/actions';
import EditMedicalConditionModal from 'shared/components/Medical/EditMedicalConditionModal';
import ArchiveAllergyModal from 'shared/components/Modals/ArchiveAllergyModal';
import ArchiveMedicalConditionModal from 'shared/components/Medical/ArchiveMedicalConditionModal';
import { IAllergyInputGroupFormShape } from 'shared/components/InputGroups/AllergyInputGroup';
import Switch from 'shared/components/Switch';
import { Row } from 'shared/components/Layout';
import ArchiveRestrictionModal from './ArchiveRestrictionModal';
import EditRestrictionModal from './EditRestrictionModal';
import ChildConditionsTable from './ChildConditionsTable';
import { emptyChild } from '../../../duck/reducers';
import { useGetChildWellnessData } from 'gql/child/queries';
import { CreateButton } from 'shared/components/Buttons';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import HealthProfessionals from './HealthProfessionals';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ImmunizationsTab from '../../components/ImmunizationsTab';
import { useTranslation } from 'react-i18next';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import AddChildHealthConditionModal from './AddMedicalConditionModal';
import AddImmmunizationModal from '../../components/ImmunizationsTab/AddOrEditImmmunizationModal';
import { isRegion } from 'shared/util/region';
import { MedicareCardTab } from './MedicareCardTab';

interface IHealthItemActionModalState<T> {
  open: boolean;
  item: T | null;
}
interface IProps {
  childId: string;
  timezone: Timezone;
  child: IChild | undefined;
}

enum HealthTab {
  HealthConditions = 'healthConditions',
  Immunisations = 'immunisations',
  HealthProfessionals = 'healthProfessionals',
  Medicare = 'medicare',
}

const HealthWellnessTab: React.FC<IProps> = ({ timezone, childId, child }) => {
  const { t } = useTranslation();
  const HealthTabDict: EnumDictionary<HealthTab, string> = {
    [HealthTab.HealthConditions]: 'Health Conditions',
    [HealthTab.Immunisations]: `${t('health.immunization.localized')}s`,
    [HealthTab.HealthProfessionals]: 'Health Professionals',
    [HealthTab.Medicare]: 'Medicare',
  };

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<HealthTab>(HealthTab.HealthConditions);
  const tabChange = (key: string | null) => key && setActiveTab(key as HealthTab);
  const { restrictions, allergies, medicalConditions } =
    useSelector((state: RootState) => state.children.byId[childId]) ?? emptyChild;
  const { loading } = useGetChildWellnessData(childId);

  const [showAddHealthItemModal, setShowAddHealthItemModal] = useState<boolean>(false);
  const [showAddImmunizationModal, setShowImmunizationModal] = useState<boolean>(false);

  const [medicalConditionInEditModal, setMedicalConditionInEditModal] = useState<IMedicalCondition | null>(null);
  const [medicalConditionInArchiveModal, setMedicalConditionInArchiveModal] = useState<IMedicalCondition | null>(null);
  const [archiveAllergyModalState, setArchiveAllergyModalState] = useState<IHealthItemActionModalState<IAllergy>>({
    open: false,
    item: null,
  });
  const [editAllergyModalState, setEditAllergyModalState] = useState<IHealthItemActionModalState<IAllergy>>({
    open: false,
    item: null,
  });
  const [restrictionInArchiveModal, setRestrictionInArchiveModal] = useState<IRestriction | null>(null);
  const [restrictionInEditModal, setRestrictionInEditModal] = useState<IRestriction | null>(null);

  const [showArchived, setShowArchived] = useState(false);

  const [updateAllergyForChildFn] = useUpdateAllergyForChild({
    onCompleted: (result) => {
      showToast('Allergy updated successfully.', 'success');
      dispatch(updateAllergyForChild(result.updateAllergyForChild));
      setEditAllergyModalState({ open: false, item: null });
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleUpdateChildAllergy = useCallback(
    (updatedAllergy: IAllergyInputGroupFormShape) => {
      if (editAllergyModalState.item) {
        updateAllergyForChildFn({
          variables: {
            input: {
              allergyId: editAllergyModalState.item.id,
              childId: editAllergyModalState.item.childId as string,
              allergenId: updatedAllergy.allergen?.id as string,
              instructions: updatedAllergy.instructions as string,
              severity: updatedAllergy.severity as AllergenSeverity,
              reactions: updatedAllergy.reactions?.map((option) => option.value) ?? [],
              documentation: updatedAllergy.documentation,
            },
          },
        });
      }
    },
    [editAllergyModalState, updateAllergyForChildFn]
  );

  const [archiveAllergyForChildFn] = useArchiveAllergyForChild({
    onCompleted: (result) => {
      showToast('Archived allergy successfully.', 'success');
      dispatch(updateAllergyForChild(result.archiveAllergyForChild));
      setArchiveAllergyModalState({ open: false, item: null });
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleArchiveChildAllergy = useCallback(
    (allergy: IAllergy | null) => {
      if (allergy) {
        archiveAllergyForChildFn({
          variables: {
            allergyId: allergy.id,
            childId,
          },
        });
      }
    },
    [archiveAllergyForChildFn, childId]
  );

  const childAccounts = useMemo(() => {
    return child?.accounts.map((account) => {
      return {
        accountChildId: account.children.find((c) => c.id === childId)?.accountChildId ?? '',
        accountId: account.id,
        accountName: account.name,
        centerId: account.center?.id ?? '',
        centerName: account.center?.name ?? '',
      };
    });
  }, [child, childId]);

  const childAccountOptions = useMemo(() => {
    return child?.accounts.map((a) => ({
      value: a.children.find((c) => c.id === childId)?.accountChildId ?? '',
      label: `${a.center?.name ?? ''} - ${a.name}`,
    }));
  }, [child, childId]);

  const isAuRegion = isRegion('AU');

  return (
    <div className="mx-auto profile-page-width">
      {medicalConditionInEditModal && (
        <EditMedicalConditionModal
          isOpen={Boolean(medicalConditionInEditModal)}
          onClose={() => setMedicalConditionInEditModal(null)}
          medicalCondition={medicalConditionInEditModal}
          readOnly={false}
        />
      )}
      {medicalConditionInArchiveModal && (
        <ArchiveMedicalConditionModal
          isOpen={Boolean(medicalConditionInArchiveModal)}
          onClose={() => setMedicalConditionInArchiveModal(null)}
          medicalCondition={medicalConditionInArchiveModal}
        />
      )}
      {editAllergyModalState.item && (
        <UpdateAllergyModal
          isOpen={editAllergyModalState.open}
          allergy={editAllergyModalState.item}
          onClose={() => setEditAllergyModalState({ open: false, item: null })}
          onSave={handleUpdateChildAllergy}
        />
      )}
      <ArchiveAllergyModal
        isOpen={archiveAllergyModalState.open}
        allergy={archiveAllergyModalState.item}
        onClose={() => setArchiveAllergyModalState({ open: false, item: null })}
        onSave={() => handleArchiveChildAllergy(archiveAllergyModalState.item)}
      />
      {restrictionInArchiveModal && (
        <ArchiveRestrictionModal
          isOpen={Boolean(restrictionInArchiveModal)}
          onClose={() => setRestrictionInArchiveModal(null)}
          restriction={restrictionInArchiveModal}
          childId={childId}
        />
      )}
      {restrictionInEditModal && (
        <EditRestrictionModal
          isOpen={Boolean(restrictionInEditModal)}
          onClose={() => setRestrictionInEditModal(null)}
          restriction={restrictionInEditModal}
          childId={childId}
        />
      )}
      <AddChildHealthConditionModal
        isOpen={showAddHealthItemModal}
        onClose={() => setShowAddHealthItemModal(false)}
        childId={childId}
      />
      <AddImmmunizationModal
        timezone={timezone}
        isOpen={showAddImmunizationModal}
        onClose={() => setShowImmunizationModal(false)}
        childId={childId}
      />
      <Tabs
        defaultActiveKey={HealthTab.HealthConditions}
        id="child-welness-tabs"
        activeKey={activeTab}
        onSelect={tabChange}
      >
        <Tab
          eventKey={HealthTab.HealthConditions}
          title={HealthTabDict[HealthTab.HealthConditions]}
          id="child-tabs-tab-health-conditions"
        >
          <div className={'d-flex flex-column'}>
            <HasRoleAreaLevel
              action={{ area: AreaType.Child, permission: PermissionType.Documents, level: RoleLevelType.Create }}
            >
              <CreateButton className={'mb-4 align-self-end'} onClick={() => setShowAddHealthItemModal(true)}>
                Add Condition
              </CreateButton>
            </HasRoleAreaLevel>
            <Row className={'mb-4'} noGutters>
              <Switch
                className="ml-auto"
                value={showArchived}
                onChange={setShowArchived}
                label="Show Archived"
                labelSide="left"
              />
            </Row>
            <ChildConditionsTable
              title="Medical Conditions"
              category="Medical"
              data={medicalConditions.filter((mc) => showArchived || !mc.archivedAt)}
              onEdit={(mc) => setMedicalConditionInEditModal(cast<IMedicalCondition>(mc))}
              onArchive={(mc) => setMedicalConditionInArchiveModal(cast<IMedicalCondition>(mc))}
              loading={loading}
              className="mb-6"
            />
            <ChildConditionsTable
              title="Allergies"
              category="Allergy"
              data={allergies.filter((a) => showArchived || !a.archivedAt)}
              loading={loading}
              onArchive={(a) => setArchiveAllergyModalState({ open: true, item: cast<IAllergy>(a) })}
              onEdit={(a) => setEditAllergyModalState({ open: true, item: cast<IAllergy>(a) })}
              className="mb-6"
            />
            <ChildConditionsTable
              title="Restrictions"
              category="Restriction"
              data={restrictions.filter((r) => showArchived || !r.archivedAt)}
              loading={loading}
              onEdit={(r) => setRestrictionInEditModal(cast<IRestriction>(r))}
              onArchive={(r) => setRestrictionInArchiveModal(cast<IRestriction>(r))}
            />
          </div>
        </Tab>
        <Tab
          eventKey={HealthTab.Immunisations}
          title={HealthTabDict[HealthTab.Immunisations]}
          id="child-tabs-tab-immunisations"
        >
          <div className={'d-flex flex-column'}>
            <HasRoleAreaLevel
              action={{ area: AreaType.Child, permission: PermissionType.Documents, level: RoleLevelType.Create }}
            >
              <CreateButton className={'mb-4 align-self-end'} onClick={() => setShowImmunizationModal(true)}>
                Add Record
              </CreateButton>
            </HasRoleAreaLevel>
            <ImmunizationsTab childId={childId} timezone={timezone} />
          </div>
        </Tab>
        <Tab
          eventKey={HealthTab.HealthProfessionals}
          title={HealthTabDict[HealthTab.HealthProfessionals]}
          id="child-tabs-tab-health-professionals"
        >
          <HealthProfessionals
            childId={childId}
            childAccounts={childAccounts ?? []}
            childAccountOptions={childAccountOptions ?? []}
          />
        </Tab>
        {isAuRegion && child && (
          <Tab eventKey={HealthTab.Medicare} title={HealthTabDict[HealthTab.Medicare]} id="child-tabs-tab-medicare">
            <MedicareCardTab childId={childId} childAccounts={childAccounts ?? []} />
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default HealthWellnessTab;
