import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useCreateEnrollmentProgram } from 'gql/enrollment/mutations';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { convertFormDataToPayload } from './utils';
import { useTranslation } from 'react-i18next';
import useValidateProgram from '../hooks/useValidateProgram';
import useEditEnrollmentProgram, { IUseEditEnrollment } from '../hooks/useEditEnrollmentProgram';
import { showToast } from 'shared/components/Toast';
import { ApplicationFeeType } from 'shared/constants/enums/applicationFeeType';
import {
  EnrollmentOptions,
  EnrollmentProgramGroup,
  SortDirection,
  useGetEnrollmentProgramGroupsQuery,
} from 'generated/graphql';
import { IEnrollmentProgram } from 'shared/types/enrollmentProgram';

export interface IProgramCenter {
  id?: string;
  centerId: string;
  centerName: string;
  classId: string;
  className: string;
  feeId: string;
  feeName: string;
  applicationFeeType?: ApplicationFeeType;
  applicationFeeAmount?: number;
  enrollmentOptions: EnrollmentOptions;
  casualFeeId?: string;
  casualFeeName?: string;
}

export enum CreateProgramSteps {
  programDetails,
  linkToClass,
}

export interface IFormDataShape {
  id?: string;
  name: string;
  startDate: string;
  endDate: string;
  enrolmentOpenDate?: string | null;
  enrolmentCloseDate?: string | null;
  enrolmentOpenTime?: string | null;
  enrolmentCloseTime?: string | null;
  isOpenForEnrollment: boolean;
  programCenters: IProgramCenter[];
  minEnrolmentDays?: number;
  maxEnrolmentDays?: number;
  operatingWeekDays?: WeekDay[];
  operatingDays: string;
  description: string;
  includeCasualContractCount?: boolean;
  programGroupId?: string;
}

export const initialFormData: IFormDataShape = {
  name: '',
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().add(1, 'year').format('YYYY-MM-DD'),
  enrolmentOpenDate: undefined,
  enrolmentCloseDate: undefined,
  enrolmentOpenTime: undefined,
  enrolmentCloseTime: undefined,
  isOpenForEnrollment: true,
  programCenters: [],
  minEnrolmentDays: undefined,
  maxEnrolmentDays: undefined,
  operatingWeekDays: [],
  operatingDays: '',
  description: '',
  includeCasualContractCount: false,
};

export interface IProgramsContext extends IUseEditEnrollment {
  activeProgram?: IEnrollmentProgram;
  setActiveProgram: React.Dispatch<React.SetStateAction<IEnrollmentProgram | undefined>>;
  programFormData: IFormDataShape;
  setProgramFormData: React.Dispatch<React.SetStateAction<IFormDataShape>>;
  isDateValid: boolean;
  createProgramLoading: boolean;
  handleCreateProgram: () => Promise<void>;
  isCreateProgramModalOpen: boolean;
  setIsCreateProgramModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resetProgramFormData: () => void;
  step: CreateProgramSteps;
  setStep: React.Dispatch<React.SetStateAction<CreateProgramSteps>>;
  formErrors: Partial<Record<keyof IFormDataShape, string | null>>;
  isCreateProgramGroupModalOpen: boolean;
  setIsCreateProgramGroupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  programGroups?: EnrollmentProgramGroup[];
}

export const ProgramContext = React.createContext<IProgramsContext | null>(null);

export default ({ children, refetch }: { children: React.ReactNode; refetch: () => void }) => {
  const { t } = useTranslation();
  const [programFormData, setProgramFormData] = useState<IFormDataShape>(initialFormData);

  const [activeProgram, setActiveProgram] = useState<IEnrollmentProgram | undefined>();

  const [step, setStep] = useState<CreateProgramSteps>(CreateProgramSteps.programDetails);

  const [isCreateProgramModalOpen, setIsCreateProgramModalOpen] = React.useState(false);
  const [isCreateProgramGroupModalOpen, setIsCreateProgramGroupModalOpen] = React.useState(false);

  const { isDateValid, formErrors } = useValidateProgram(programFormData);

  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const allEntities = useSelector((state: RootState) => state.entities.allEntities);
  const businessTimeZone = allEntities[businessId]?.timezone || moment.tz.guess();

  const onSuccess = () => {
    resetProgramFormData();
    refetch();
  };

  const { data: programGroupsData, refetch: refetchGroups } = useGetEnrollmentProgramGroupsQuery({
    variables: {
      input: {
        businessId,
        pagination: {
          pageNumber: 1,
          pageSize: 1000,
        },
        sort: [
          {
            direction: SortDirection.Ascending,
            field: 'name',
          },
        ],
      },
    },
  });

  const { isEditOpen, isEditLoading, handleEditProgram, setIsEditOpen } = useEditEnrollmentProgram({
    businessId,
    formData: programFormData,
    onSuccess,
    setFormData: setProgramFormData,
    program: activeProgram,
  });

  const [createProgramFn, { loading: createProgramLoading }] = useCreateEnrollmentProgram({
    variables: {
      input: convertFormDataToPayload(programFormData, businessId, businessTimeZone),
    },
    onCompleted: () => {
      setIsCreateProgramModalOpen(false);
      showToast(t('enrollment.programs.create-program-success'), 'success');
      onSuccess();
    },
  });

  const handleCreateProgram = useCallback(async () => {
    await createProgramFn();
  }, [createProgramFn]);

  const resetProgramFormData = useCallback(() => {
    setProgramFormData(initialFormData);
    setStep(CreateProgramSteps.programDetails);
    setActiveProgram(undefined);
  }, []);

  useEffect(() => {
    if (isEditOpen || isCreateProgramModalOpen) refetchGroups();
  }, [isEditOpen, isCreateProgramModalOpen, refetchGroups]);

  return (
    <ProgramContext.Provider
      value={{
        programFormData,
        step,
        isDateValid,
        formErrors,
        createProgramLoading,
        isCreateProgramModalOpen,
        activeProgram,
        isEditOpen,
        isEditLoading,
        setActiveProgram,
        setProgramFormData,
        handleCreateProgram,
        handleEditProgram,
        setIsCreateProgramModalOpen,
        resetProgramFormData,
        setStep,
        setIsEditOpen,
        isCreateProgramGroupModalOpen,
        setIsCreateProgramGroupModalOpen,
        programGroups: programGroupsData?.getEnrollmentProgramGroups?.data as EnrollmentProgramGroup[],
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
};

export const useProgramsContext = () => useContext(ProgramContext)!;
