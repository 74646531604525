import React from 'react';
import { useSelector } from 'react-redux';
import { debounce, isEmpty, orderBy } from 'lodash';

import * as Mui from '@mui/material';
import { useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faIslandTropical, faPlus, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { RootState } from 'store/reducers';
import { useGetPositionsForBusiness } from 'gql/position/queries';

import PositionTableRow from './components/PositionTableRow';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { IconButtonCircle } from 'shared/components/Buttons';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { defaultPositionFields } from './graphQL/defaultFields';
import AddPositionModal from './components/AddPositionModal';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';

const styles = {
  iconButton: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
  inputStyles: {
    minWidth: '200px',
    '& legend': {
      width: 0,
    },
  },
};

const columns = [
  { name: 'Position Name', field: 'name', displayLabel: true, alignLeft: true, sortable: true },
  { name: 'Active Employees', field: 'activeEmployeeCount', displayLabel: true, alignLeft: false, sortable: false },
  { name: 'Is Archived?', field: 'archivedAt', displayLabel: true, alignLeft: false, sortable: true },
  { name: 'Row Actions', field: 'id', displayLabel: false, alignLeft: false, sortable: false },
];

interface ISortBy {
  field: string;
  direction: string;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const PositionsTab: React.FC = () => {
  const theme = useTheme();
  const isMobile = Mui.useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [showArchivePositions, setShowArchivePositions] = React.useState(false);
  const [sort, setSort] = React.useState<ISortBy>({
    field: 'name',
    direction: 'asc',
  });
  const [searchInput, setSearchInput] = React.useState('');
  const [size, setSize] = React.useState<number>(25);
  const [page, setPage] = React.useState(0);
  let totalRecords = 0;

  const hasEditOperationsPermission = useHasRoleAreaLevel({
    area: AreaType.Operations,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const {
    data: positionsForBusiness,
    loading,
    ...rest
  } = useGetPositionsForBusiness(businessId ?? '', showArchivePositions);

  const changeSort = (field: string) => {
    setSort({
      field: field,
      direction: sort.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  function getPositionsAndPaginate(pageSize: number, pageNumber: number) {
    let positions =
      positionsForBusiness?.getAllPositionsByBusinessId.map((p) => ({
        ...p,
        name: p.name.replace(/Center|Centre/g, fieldLabels.center),
      })) ?? [];
    if (!showArchivePositions) positions = positions.filter((p) => !p.archivedAt);
    if (searchInput) positions = positions.filter((p) => p.name.toLowerCase().includes(searchInput.toLowerCase()));
    positions = orderBy(positions, [sort.field], [sort.direction === 'asc' ? 'asc' : 'desc']);
    totalRecords = positions.length;
    return positions.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize);
  }

  const localisedPositionsForBusiness: IPosition[] = getPositionsAndPaginate(size, page);

  return (
    <PageWrapperBody>
      {user?.isInternal && <CenterSelectBanner pageName="operations" showCenterSelect={false} />}
      <br />
      <Mui.Card elevation={0} variant="outlined">
        <Mui.Box sx={{ padding: '16px' }}>
          <Mui.Stack
            justifyContent={'space-between'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            spacing={2}
            direction={isMobile ? 'column' : 'row'}
          >
            <Mui.Stack
              alignItems={isMobile ? 'flex-start' : 'center'}
              spacing={2}
              direction={isMobile ? 'column' : 'row'}
            >
              <Mui.Typography>Show</Mui.Typography>
              <Mui.Select
                sx={{
                  minWidth: '50px',
                  '& legend': {
                    width: 0,
                  },
                }}
                inputProps={{
                  'aria-label': 'change the amount of items to show',
                }}
                value={size.toString()}
                onChange={(evt) => setSize(parseInt(evt.target.value))}
                size={'small'}
              >
                <Mui.MenuItem value={'10'}>10</Mui.MenuItem>
                <Mui.MenuItem value={'25'}>25</Mui.MenuItem>
                <Mui.MenuItem value={'50'}>50</Mui.MenuItem>
              </Mui.Select>
              <Mui.TextField
                variant="outlined"
                value={searchInput}
                onChange={(evt) => {
                  setSearchInput(evt.target.value);
                  setPage(0);
                }}
                InputProps={{
                  startAdornment: (
                    <Mui.InputAdornment position="start">
                      <FontAwesomeIcon size="xs" icon={faSearch} />
                    </Mui.InputAdornment>
                  ),
                }}
                inputProps={{
                  'aria-label': 'Search Positions',
                }}
                placeholder="Search"
                size="small"
                sx={{
                  minWidth: '200px',
                  '& legend': {
                    width: 0,
                  },
                }}
              />
            </Mui.Stack>
            <Mui.Stack
              alignItems={isMobile ? 'flex-start' : 'center'}
              spacing={2}
              direction={isMobile ? 'column' : 'row'}
            >
              <IconButtonCircle
                icon={faTimes}
                onClick={() => {
                  setSearchInput('');
                }}
                tooltipDirection="bottom"
                tooltipText="Clear Filters"
                className="mx-4 my-2"
              />
            </Mui.Stack>
          </Mui.Stack>
        </Mui.Box>
      </Mui.Card>
      {hasEditOperationsPermission && (
        <Mui.Box display="flex" justifyContent="flex-end" alignItems="center" gap="5px" marginTop="16px">
          Show Archived
          <Mui.Switch
            className="float-right"
            aria-describedby="show archived positions"
            onChange={(e) => setShowArchivePositions(!showArchivePositions)}
            value={showArchivePositions}
          />
          <Mui.Button
            color="secondary"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setOpenAddModal(true)}
          >
            Add Position
          </Mui.Button>
        </Mui.Box>
      )}
      <SpinnerOverlay show={loading}>
        {(totalRecords ?? 0) > 0 ? (
          <Mui.Box sx={{ width: '100%' }}>
            <Mui.Paper sx={{ mb: 2 }} elevation={0}>
              <Mui.TableContainer>
                <Mui.Table aria-labelledby="account-notes-table" style={{ width: '100%' }}>
                  <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
                    {columns.map((column) => (
                      <Mui.TableCell key={column.name} align={column.alignLeft ? 'left' : 'center'} padding={'normal'}>
                        <Mui.Typography variant="tableHeadCells">
                          {column.displayLabel && column.name}
                          {column.sortable && (
                            <Mui.IconButton
                              sx={styles.iconButton}
                              disableRipple
                              onClick={() => changeSort(column.field)}
                            >
                              <FontAwesomeIcon
                                size="xs"
                                icon={
                                  sort.field === column.field
                                    ? sort.direction === 'asc'
                                      ? faAngleDown
                                      : faAngleUp
                                    : faAngleDown
                                }
                              />
                            </Mui.IconButton>
                          )}
                        </Mui.Typography>
                      </Mui.TableCell>
                    ))}
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {localisedPositionsForBusiness.map((row, index) => (
                      <PositionTableRow key={row.id} row={row} refetch={rest.refetch} />
                    ))}
                  </Mui.TableBody>
                </Mui.Table>
              </Mui.TableContainer>
              <Mui.TablePagination
                sx={{
                  '.MuiTablePagination-displayedRows': {
                    margin: 0,
                    padding: 0,
                  },
                }}
                rowsPerPageOptions={[size]}
                component="div"
                count={totalRecords ?? 0}
                rowsPerPage={size ?? 0}
                page={page}
                onPageChange={handleChangePage}
              />
            </Mui.Paper>
          </Mui.Box>
        ) : (
          <Mui.Stack spacing={2} direction="column" alignItems="center" justifyContent="center" marginTop="32px">
            <FontAwesomeIcon icon={faIslandTropical} size="5x" />
            <Mui.Typography>There are no Positions available to display.</Mui.Typography>
          </Mui.Stack>
        )}
      </SpinnerOverlay>
      <AddPositionModal open={openAddModal} onClose={() => setOpenAddModal(false)} refetch={rest.refetch} />
    </PageWrapperBody>
  );
};

export default PositionsTab;
