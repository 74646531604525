import { GetBillingBusinessSettingsQuery, useGetBillingBusinessSettingsQuery } from 'generated/graphql';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { Direction, Display, ICurrencySettings } from './Currency';

const useGetCurrencySettings = (
  onCompleted?: (data: GetBillingBusinessSettingsQuery) => void,
  skip: boolean = false
): {
  currencySettings: ICurrencySettings | null;
  loading: boolean;
  refetch: Function;
} => {
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context)?.businessId ?? user?.entityId;

  const { data, loading, refetch } = useGetBillingBusinessSettingsQuery({
    onCompleted,
    skip,
    variables: { businessId },
  });

  const billingBusinessSettings = data?.fetchBillingBusinessSettings;

  return {
    currencySettings: billingBusinessSettings
      ? {
          direction: billingBusinessSettings.parentPaymentDirection as Direction,
          display: billingBusinessSettings.transactionAmountDisplay as Display,
          themed: billingBusinessSettings.transactionAmountThemed,
        }
      : null,
    loading,
    refetch,
  };
};

export default useGetCurrencySettings;
