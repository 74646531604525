import * as types from './types';

interface IEmployeesState {
  all: IStaff[];
  requested: IStaff[];
  byId: Record<string, IStaff>;
}

const initialState: IEmployeesState = {
  all: [],
  byId: {},
  requested: [],
};

const getStaff = (byId: Record<string, IStaff>, id: string | null): IStaff | null => (id ? byId[id as string] : null);

const employeesReducer = (state = initialState, action: types.StaffActionTypes) => {
  const byId = { ...state.byId };

  switch (action.type) {
    case types.SET_REQUESTED_EMPLOYEES_DATA: {
      return {
        ...state,
        requested: action.staff,
      };
    }
    case types.GET_STAFF_MEDICAL_DATA_SUCCESS:
      return {
        ...state,
        all: [
          ...state.all.filter((s) => s.id !== action.staff.id),
          { ...(state.byId[action.staff.id] ?? {}), ...action.staff },
        ],
        byId: { ...state.byId, [action.staff.id]: { ...(state.byId[action.staff.id] ?? {}), ...action.staff } },
      };

    case types.CREATE_MEDICAL_CONDITION_FOR_STAFF:
    case types.UPDATE_MEDICAL_CONDITION_FOR_STAFF:
      const staffForCondition = getStaff(byId, action.condition.personId ?? '');
      if (staffForCondition) {
        byId[staffForCondition.id] = {
          ...staffForCondition,
          medicalConditions: [
            ...(staffForCondition.medicalConditions ?? []).filter((c) => c.id !== action.condition.id),
            action.condition,
          ],
        };
      }
      return { ...state, byId, all: Object.values(byId) };
    case types.DELETE_MEDICAL_CONDITION_FOR_STAFF:
      const staffForConditionDelete = getStaff(byId, action.condition.personId ?? '');
      if (staffForConditionDelete) {
        byId[staffForConditionDelete.id] = {
          ...staffForConditionDelete,
          medicalConditions: staffForConditionDelete.medicalConditions.filter((c) => c.id !== action.condition.id),
        };
      }
      return { ...state, byId, all: Object.values(byId) };

    case types.CREATE_ALLERGY_FOR_STAFF:
    case types.UPDATE_ALLERGY_FOR_STAFF:
      const staffForAllergy = getStaff(byId, action.allergy.personId ?? '');
      if (staffForAllergy) {
        byId[staffForAllergy.id] = {
          ...staffForAllergy,
          allergies: [...staffForAllergy.allergies.filter((c) => c.id !== action.allergy.id), action.allergy],
        };
      }
      return { ...state, byId, all: Object.values(byId) };
    case types.DELETE_ALLERGY_FOR_STAFF:
      const staffForAllergyDelete = getStaff(byId, action.allergy.personId ?? '');
      if (staffForAllergyDelete) {
        byId[staffForAllergyDelete.id] = {
          ...staffForAllergyDelete,
          allergies: staffForAllergyDelete.allergies.filter((c) => c.id !== action.allergy.id),
        };
      }
      return { ...state, byId, all: Object.values(byId) };

    case types.RESET_EMPLOYEES_DATA: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default employeesReducer;
