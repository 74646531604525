import React from 'react';
import { riskColorHexes } from 'shared/constants/tagColors';
import './_tag.scss';
import colors from '_colors.module.scss';

interface IProps {
  className?: string;
  risk: 'AwarenessOnly' | 'None' | 'NotApplicable' | 'Low' | 'Moderate' | 'High' | 'Mild' | 'Severe';
}

const RISK_NAME_MAP: Record<string, string> = {
  None: 'None',
  High: 'High',
  Severe: 'Severe',
  Moderate: 'Moderate',
  Low: 'Low',
  Mild: 'Mild',
  NotApplicable: 'Not Applicable',
  AwarenessOnly: 'Awareness Only',
};

const Tag: React.FC<IProps> = ({ className, risk }) => {
  return (
    <div
      style={{ background: riskColorHexes[risk], minWidth: '94px' }}
      className={`tag px-4 ${
        riskColorHexes[risk] !== colors.yellow && riskColorHexes[risk] !== colors.silver ? 'text-white' : ''
      }  ${className || ''}`}
    >
      <div className="ml-auto mr-auto">{RISK_NAME_MAP[risk]}</div>
    </div>
  );
};

export default Tag;
