import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import Checkbox from 'shared/components/Checkbox';
import { showToast } from 'shared/components/Toast';
import { useGetChildContactPermissions } from 'gql/contact/queries';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import { useUpdateContactForChild } from 'gql/contact/mutations';
import { updateContactForChild } from '../../Children/duck/actions';
import AccountPermissionsInputs from '../../Children/Child/components/ContactsTab/AccountPermissionsInputs';
import { getFullName } from 'shared/util/string';
import { Link } from 'react-router-dom';
import { LoadingLines } from 'shared/components/LoadingSkeletons';

interface IProps {
  isOpen: boolean;
  contact: IContact;
  child: IContactChild;
  accountId: string;
  onClose: () => void;
}

const EditContactChildPermissionsModal: React.FC<IProps> = ({
  isOpen,
  child,
  contact,
  accountId,
  onClose,
  ...props
}) => {
  const dispatch = useDispatch();
  const accounts = contact.accounts ?? [];
  const [relationship, setRelationship] = useState(child.relationshipType);
  const [accountPermissions, setAccountPermissions] = useState<IAccountPermissionsInput[]>(
    child.accountPermissions.map((a) => ({
      accountId: a.accountId,
      permissions: a.permissions,
      isPrimary: a.isPrimary,
    }))
  );

  const { data: permissionsData, loading } = useGetChildContactPermissions();
  const relationshipOptions = Object.entries(childContactRelationship).map((r) => ({ value: r[0], label: r[1] }));
  const permissionsOptions = permissionsData?.getChildContactRelationshipPermissions ?? [];

  const permissionsForAccount = accountPermissions.find((ap) => ap.accountId === accountId);
  const otherAccountsWithPermissions = child.accountPermissions.filter((a) => a.accountId !== accountId);

  useEffect(() => {
    setRelationship(child.relationshipType);
    setAccountPermissions(
      child.accountPermissions.map((a) => ({
        accountId: a.accountId,
        permissions: a.permissions,
        isPrimary: a.isPrimary,
      }))
    );
  }, [contact]);

  const [updateContactForChildFn, { loading: updateLoading }] = useUpdateContactForChild({
    onCompleted: (result) => {
      showToast('Contact updated successfully.', 'success');
      result.updateChildContact && dispatch(updateContactForChild(result.updateChildContact));
      onClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          ?.map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleSubmit = useCallback(() => {
    updateContactForChildFn({
      variables: {
        input: {
          childId: child.childId,
          contactId: contact.id,
          relationship,
          accounts: accountPermissions.map((a) => ({ accountId: a.accountId, permissions: a.permissions })),
        },
      },
    });
  }, [updateContactForChildFn, child, contact, accountPermissions, relationship]);

  return (
    <SideModalDrawer
      title="Edit Permissions"
      show={isOpen}
      onHide={onClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={handleSubmit}
      primaryButtonProps={{ loading: updateLoading }}
      secondaryChoice="Cancel"
      secondaryCallback={onClose}
      enforceFocus={false}
    >
      <Form>
        <Row>
          <Column>
            <Select
              label="Account"
              options={accounts.map((a) => ({ value: a.id, label: a.name }))}
              onChange={() => {}}
              value={accountId}
              disabled={true}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <TextInput required label="First Name" value={child.firstname} disabled={true} />
          </Column>
          <Column>
            <TextInput required label="Last Name" value={child.lastname} disabled={true} />
          </Column>
        </Row>
        <Row className="mb-6">
          <Column xs={6}>
            <Select
              label="Relationship"
              options={relationshipOptions}
              onChange={(option) => setRelationship(option.value)}
              value={relationship}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <div className="mb-6">
              <h5>Account Permissions</h5>
              {loading && <LoadingLines />}
              {permissionsForAccount &&
                permissionsOptions.map((permission, idx) => (
                  <Checkbox
                    key={`permission-${permission.value}-${idx}`}
                    label={permission.label}
                    disabled={permissionsForAccount?.isPrimary}
                    value={permissionsForAccount?.permissions.includes(permission.value)}
                    onChange={(checked) =>
                      setAccountPermissions(
                        accountPermissions.map((ap) =>
                          ap.accountId === accountId
                            ? {
                                ...ap,
                                permissions: checked
                                  ? [...ap.permissions, permission.value]
                                  : ap.permissions.filter((p) => p != permission.value),
                              }
                            : ap
                        )
                      )
                    }
                  />
                ))}
              {permissionsForAccount?.isPrimary && (
                <small>
                  {getFullName(contact)} is a primary contact on this account.{' '}
                  <Link to={`/families/accounts/${accountId}/contacts`}>Go to the account profile</Link> and make them a
                  non-primary contact in order to edit permissions.
                </small>
              )}
            </div>
            {otherAccountsWithPermissions.length > 0 && (
              <div className="mb-4">
                <h5>Other Account Permissions</h5>
                <AccountPermissionsInputs
                  contact={contact}
                  accountOptions={otherAccountsWithPermissions.map((a) => a.account)}
                  accountPermissions={accountPermissions}
                  setAccountPermissions={setAccountPermissions}
                  canAddAndRemoveAccounts={false}
                />
              </div>
            )}
          </Column>
        </Row>
      </Form>
    </SideModalDrawer>
  );
};

export default EditContactChildPermissionsModal;
