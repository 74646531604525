import React from 'react';
import { CwaTable } from './components/index';

interface IProps {}

export const CwaTab: React.FC<IProps> = () => {
  return (
    <>
      <CwaTable />
    </>
  );
};
