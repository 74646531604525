import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import TextInput from 'shared/components/TextInput';
import NotifiableEventsContainer from '../NotifiableEventsContainer';
import { useGetProviderFinancialDetails } from 'gql/provider/queries';
import { useUpdateProviderFinancialDetails } from 'gql/provider/mutation';
import { showToast } from 'shared/components/Toast';
import moment from 'moment';
import Checkbox from 'shared/components/Checkbox';
import { omitTypename } from 'shared/util/object';
import cast from 'shared/util/cast';

interface IProps {
  businessId: string;
  providers: IProvider[];
}

const ProviderFinancialDetails: React.FC<IProps> = ({ providers }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<IUpdateProviderFinancialDetailsInput>({
    providerId: '',
    dateOfEvent: '',
    bsb: '',
    accountNumber: '',
    accountName: '',
    applyToAllServices: false,
  });
  const [cancelData, setCancelData] = useState<IUpdateProviderFinancialDetailsInput>(formData);

  const { loading: detailsLoading } = useGetProviderFinancialDetails({
    variables: {
      providerId: formData.providerId,
    },
    skip: formData.providerId === '',
    onCompleted: (data) => {
      const financialDetailsData = cast<IProviderFinancialDetails>(omitTypename(data.getProviderFinancialDetails));
      if (!!financialDetailsData) {
        setFormData({
          ...formData,
          ...financialDetailsData,
          providerId: formData.providerId,
        });
        setCancelData({
          ...formData,
          ...financialDetailsData,
          providerId: formData.providerId,
        });
      }
    },
  });

  const [updateProviderFinancialDetails, { loading: updateFinancialDetailsLoading }] =
    useUpdateProviderFinancialDetails({
      onCompleted: () => {
        showToast('Successfully submitted the provider financial details.', 'success');
      },
      onError: (error) => {
        showToast(error.graphQLErrors[0]?.message || error.message, 'error');
      },
    });

  useEffect(() => {
    if (providers.length > 0) {
      setFormData((form) => ({
        ...form,
        providerId: providers[0].id,
      }));
    }
  }, [providers]);

  const validate = useCallback(() => {
    if (Object.values(formData).some((val: string) => val === '')) {
      return false;
    }
    return true;
  }, [formData]);

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }
    updateProviderFinancialDetails({
      variables: {
        input: {
          ...formData,
          dateOfEvent: moment(formData.dateOfEvent).format('YYYY-MM-DD'),
        },
      },
    });
  };

  return (
    <>
      <NotifiableEventsContainer
        title="Provider Financial Details"
        subTitle="Update the provider finanical details by completing the required fields below."
        onCancel={() => setFormData(cancelData)}
        onSave={handleSubmit}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!validate()}
        providerOnChange={(option) => setFormData({ ...formData, providerId: option.value })}
        selectedProvider={formData.providerId}
        isLoading={detailsLoading}
        isSaving={updateFinancialDetailsLoading}
        child={
          <>
            <Row>
              <Col md={12} className="mb-4">
                <DateInput
                  label="Date of effect"
                  required
                  date={formData.dateOfEvent}
                  onDateSelect={(date: string) => setFormData({ ...formData, dateOfEvent: date })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Account Name"
                  name="accountName"
                  value={formData.accountName}
                  onChange={(value: string) => setFormData({ ...formData, accountName: value })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Account Number"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={(value: string) => setFormData({ ...formData, accountNumber: value })}
                  disabled={false}
                  maxlength="9"
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="BSB"
                  name="bsb"
                  value={formData.bsb}
                  maxlength="6"
                  onChange={(value: string) => setFormData({ ...formData, bsb: value })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <Checkbox
                  label="Use this account for all Services linked to Provider?"
                  value={formData.applyToAllServices}
                  onChange={(value: boolean) => setFormData({ ...formData, applyToAllServices: value })}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default ProviderFinancialDetails;
