import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import PageWrapper from 'shared/components/PageWrapper';
import './enrollment-form-detail.scss';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import PageDetail, { PublicApi } from './page-detail/page-detail';
import { CustomEnrolmentForm, EnrolmentFormDetailContextShape, Page } from 'shared/types/enrollment-form';
import { useLocation } from 'react-router';
import { EnrolmentFormLocationState } from '../EnrollmentFormsTable';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import ManagePageModal from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/manage-page-modal/manage-page-modal';
import { useGetCentreIdsHasFormAttachedQuery, useUpdateEnrolmentFormMutation } from 'generated/graphql';
import { useHistory } from 'react-router-dom';
import PreviewFormModal from './preview-form-modal/preview-form-modal';
import PageNav from './page-nav/page-nav';
import Alert from 'shared/components/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { clearFieldValidationState } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/duck/actions';
import SectionSelectorProvider from './section-selector-provider';
import FormStatus from './form-status/form-status';
import { showConfirm } from 'shared/components/ConfirmationContainer';
import DropdownButton from 'shared/components/Buttons/DropdownButton';
import {
  checkIsSectionMetadataValid,
  formPatcher,
  getNewPageAndNewPayloadForCrossSectionQuestionDnD,
  getNewPageAndNewPayloadForSameSectionQuestionDnD,
  getNewPageAndNewPayloadForSectionsDnD,
  isConditionalSection,
  isFieldExist,
} from './utils';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

export type EnrollmentFormProps = {};

export const EnrolmentFormDetailContext = React.createContext<EnrolmentFormDetailContextShape>(
  {} as EnrolmentFormDetailContextShape
);

export default function EnrollmentFormDetail({}: EnrollmentFormProps) {
  const { t } = useTranslation(['translation', 'enrollment', 'business']);
  const modalApi = useRef<PublicApi | null>(null);

  const dispatch = useDispatch();

  const locationStateHistory = useHistory();
  const locationStateLocation = useLocation<EnrolmentFormLocationState>();
  const state = locationStateLocation.state;

  const [payload, setPayload] = useState<CustomEnrolmentForm>(() =>
    formPatcher(state.enrolmentForm.formSchema as any as CustomEnrolmentForm, state.enrolmentForm.id ?? '')
  );

  const [showManagePageModal, setShowManagePageModel] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const [isTouched, setTouched] = useState(false);

  const [enrolmentForm, setEnrolmentForm] = useState<EnrollmentForm>(state.enrolmentForm);
  const fieldState = useSelector((root: RootState) => root.enrolmentFormFieldState[enrolmentForm.id!] ?? {});
  const { isValid: isSectionMetadataValid } = useMemo(() => checkIsSectionMetadataValid(payload.steps), [payload]);

  const updateDataHandler = useCallback(
    (data: CustomEnrolmentForm) => {
      setPayload((prevState) => ({ ...prevState, ...data }));
      setTouched(true);
    },
    [setPayload, setTouched]
  );

  useEffect(() => {
    return () => {
      dispatch(clearFieldValidationState(enrolmentForm.id!));
    };
  }, []);

  const businessId = enrolmentForm.businessId;
  const usedByCenterIds = enrolmentForm.usedByCenterIds;

  const pageOptions = useMemo(() => {
    return payload.steps.map((step) => ({ label: step.label, value: step }));
  }, [payload]);

  const [selectedPage, setSelectedPage] = useState<Page>(payload.steps[0] ?? undefined);

  const [updateEnrolmentFormFn, { loading: isUpdating }] = useUpdateEnrolmentFormMutation();

  const {
    data: excludeCentreIdsData,
    loading,
    refetch,
  } = useGetCentreIdsHasFormAttachedQuery({ variables: { businessId }, fetchPolicy: 'no-cache' });
  const excludeCentreIds = useMemo(() => {
    const ids: Array<string | null> =
      excludeCentreIdsData?.getCentreIdsHasFormAttached?.centreIdsWithForm?.filter(
        (o) => !usedByCenterIds?.includes(o)
      ) ?? [];

    if (!!excludeCentreIdsData?.getCentreIdsHasFormAttached?.allCentreAssigned) {
      ids.push(null);
    }
    return ids;
  }, [excludeCentreIdsData?.getCentreIdsHasFormAttached, usedByCenterIds]);

  const handleSave = useCallback(
    async (isDraft: boolean) => {
      try {
        const executionResult = await updateEnrolmentFormFn({
          variables: {
            input: {
              businessId: businessId,
              formSchema: payload,
              name: enrolmentForm.name,
              usedByCenterIds: enrolmentForm.usedByCenterIds,
              id: enrolmentForm.id!,
              isDraft: isDraft,
            },
          },
        });

        let updatedEnrolmentForm = executionResult.data!.updateEnrollmentForm! as EnrollmentForm;
        refetch();
        setEnrolmentForm(updatedEnrolmentForm);
        setPayload(executionResult.data?.updateEnrollmentForm.formSchema);
        setTouched(false);

        // TODO: Better handling around this
        locationStateLocation.state.enrolmentForm = updatedEnrolmentForm;
        locationStateHistory.replace(locationStateLocation);
      } catch (error: any) {
        showToast(getApolloErrorMessage(error), 'error');
      }
    },
    [
      locationStateHistory,
      setPayload,
      updateEnrolmentFormFn,
      payload,
      enrolmentForm,
      businessId,
      locationStateLocation,
      refetch,
    ]
  );

  const isAllFieldValid = useMemo(() => {
    return Object.keys(fieldState).every((o) => fieldState[o].isValid === true);
  }, [fieldState]);

  const isAllFieldNotArchived = useMemo(() => {
    return Object.keys(fieldState).every((o) => fieldState[o].isArchived === false);
  }, [fieldState]);

  // to reset the current page to 1st page if it has been deleted
  useEffect(() => {
    const currentPageIndex = payload.steps.findIndex((step) => step.label === selectedPage.label);
    if (currentPageIndex === -1) {
      setSelectedPage(payload.steps[0] ?? {});
    }
  }, [payload, selectedPage]);

  const handleCrossSectionDnD = useCallback(
    (dropResult: DropResult) => {
      const [newPage, newPayload] = getNewPageAndNewPayloadForCrossSectionQuestionDnD(
        selectedPage,
        payload,
        dropResult
      );

      setSelectedPage(newPage);

      updateDataHandler(newPayload);
    },
    [selectedPage, payload, updateDataHandler]
  );

  const handleSameSectionDnD = useCallback(
    (dropResult: DropResult) => {
      const [newPage, newPayload] = getNewPageAndNewPayloadForSameSectionQuestionDnD(selectedPage, payload, dropResult);
      setSelectedPage(newPage);
      updateDataHandler(newPayload);
    },
    [payload, selectedPage, updateDataHandler]
  );

  const handleManagePageDnd = useCallback((dropResult: DropResult) => {
    modalApi.current?.setManagePageModalPages && modalApi.current.setManagePageModalPages(dropResult);
  }, []);

  const handleSectionsDnd = useCallback(
    (dropResult: DropResult) => {
      const [newPage, newPayload] = getNewPageAndNewPayloadForSectionsDnD(selectedPage, payload, dropResult);
      setSelectedPage(newPage);
      updateDataHandler(newPayload);
    },
    [payload, selectedPage, updateDataHandler]
  );

  const handleDragEnd = useCallback(
    (dropResult: DropResult) => {
      if (!dropResult.destination) {
        return;
      }
      if (
        dropResult.source.index === dropResult.destination.index &&
        dropResult.source.droppableId === dropResult.destination.droppableId
      ) {
        return;
      }

      if (
        dropResult.destination.droppableId === 'manage-pages-modal' &&
        dropResult.source.droppableId === 'manage-pages-modal'
      ) {
        handleManagePageDnd(dropResult);
        return;
      }

      if (dropResult.destination.droppableId === 'page' && dropResult.source.droppableId === 'page') {
        handleSectionsDnd(dropResult);
        return;
      }

      if (dropResult.destination.droppableId !== dropResult.source.droppableId) {
        handleCrossSectionDnD(dropResult);
        return;
      } else {
        handleSameSectionDnD(dropResult);

        return;
      }
    },
    [handleCrossSectionDnD, handleSameSectionDnD, handleManagePageDnd, handleSectionsDnd]
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="enrollment-form-detail">
        <EnrolmentFormDetailContext.Provider
          value={{
            enrolmentFormId: enrolmentForm.id!,
            businessId: businessId,
            usedByCenterIds: enrolmentForm.usedByCenterIds,
          }}
        >
          <PageWrapper
            pageTitle={t('enrollment:settings.enrollment-form-tab-title')}
            badge={<FormStatus isPublished={!enrolmentForm.isDraft} isChanged={isTouched} />}
            applyPadding={false}
            buttonComponent={
              <Row className="page-btn-group" noGutters justify="end">
                <Button
                  variant="light"
                  className="w-120px my-1"
                  onClick={() => {
                    if (isTouched) {
                      showConfirm({
                        title: t('enrollment:form.discard-changes-modal.title'),
                        message: t('enrollment:form.discard-changes-modal.message'),
                        onConfirm: async () => locationStateHistory.goBack(),
                      });
                    } else {
                      locationStateHistory.goBack();
                    }
                  }}
                >
                  {t('translation:core.capitalize', { value: t('translation:spelling.cancel') })}
                </Button>
                <Button
                  disabled={isTouched}
                  variant="secondary"
                  className="w-120px my-1"
                  onClick={async () => {
                    setShowPreviewModal(true);
                  }}
                >
                  {t('translation:core.capitalize', { value: t('translation:spelling.preview') })}
                </Button>

                <DropdownButton
                  toggleText={t('translation:core.capitalize', { value: t('translation:spelling.save') })}
                  toggleClassName="w-120px"
                  toggleDisabled={!isSectionMetadataValid}
                  dropdownItems={[
                    {
                      disabled: !isTouched,
                      onClick: () => handleSave(true),
                      children: t('enrollment:form.save-draft'),
                    },
                    {
                      disabled: isTouched || !enrolmentForm.isDraft || !isAllFieldValid || !isAllFieldNotArchived,
                      onClick: () => handleSave(false),
                      children: t('enrollment:form.save-and-publish'),
                    },
                  ]}
                />
              </Row>
            }
          >
            <PageWrapperBody className="enrollment-management-page-wrapper">
              <Card header={t('enrollment:form.details')}>
                <Row>
                  <Col>
                    <TextInput
                      label={t('enrollment:form.form-title')}
                      name="formTitle"
                      value={enrolmentForm.name}
                      onChange={(value) => {
                        setEnrolmentForm({ ...enrolmentForm, name: value });
                        setTouched(true);
                      }}
                      required
                    />
                  </Col>
                  <Col>
                    <MultipleCenterSelect
                      excludeCentreIds={excludeCentreIds}
                      useNullForAllOption
                      selectedCenterIds={
                        enrolmentForm.usedByCenterIds?.length === 0 ? null : enrolmentForm.usedByCenterIds ?? null
                      }
                      onSelect={(centerIds) => {
                        if (excludeCentreIds.includes(null) && centerIds?.length === 0) {
                          // ignore - because ALL CENTRES option is not selectable
                        } else {
                          setEnrolmentForm({ ...enrolmentForm, usedByCenterIds: centerIds! });
                          setTouched(true);
                        }
                      }}
                      isRequired
                    />
                  </Col>
                </Row>
              </Card>
              <PageNav
                setShowManagePageModel={setShowManagePageModel}
                selectedPage={selectedPage}
                pageOptions={pageOptions}
                setSelectedPage={setSelectedPage}
                enrolmentFormLocationState={state}
                variant="dropdown"
              />
              <div>
                {!isAllFieldValid && (
                  <Alert variant="warning" className="mb-2">
                    {t('enrollment:form.errors.contain-invalid-custom-fields')}
                  </Alert>
                )}
                {!isAllFieldNotArchived && (
                  <Alert variant="warning" className="mb-2">
                    {t('enrollment:form.errors.contain-archived-custom-fields')}
                  </Alert>
                )}
                {selectedPage && (
                  <SectionSelectorProvider pages={payload.steps} currentPage={selectedPage}>
                    <PageDetail
                      pages={payload.steps}
                      businessId={businessId}
                      centerIds={usedByCenterIds ?? []}
                      page={selectedPage}
                      onPageChanged={(page, switchToPage?: boolean) => {
                        const cleanedPage = {
                          ...page,
                          sections: page.sections.filter((s) => {
                            if (isConditionalSection(s)) {
                              return isFieldExist(page, s.clientSideBehaviours?.subscribeField!);
                            } else {
                              return true;
                            }
                          }),
                        };
                        const find = payload.steps.find((step) => step.label === cleanedPage.label);
                        payload.steps.splice(payload.steps.indexOf(find as any), 1, cleanedPage as any);
                        updateDataHandler({ ...payload });
                        // when a field is deleted from the pae, any conditional section that attached to that field should be removed as well
                        if (typeof switchToPage === 'undefined' || switchToPage) {
                          setSelectedPage((prevState) => ({ ...prevState, ...cleanedPage }));
                        }
                      }}
                    />
                  </SectionSelectorProvider>
                )}
              </div>
              <PageNav
                setShowManagePageModel={setShowManagePageModel}
                selectedPage={selectedPage}
                pageOptions={pageOptions}
                setSelectedPage={setSelectedPage}
                enrolmentFormLocationState={state}
                variant="pagination"
              />
            </PageWrapperBody>
          </PageWrapper>
          {showManagePageModal && (
            <ManagePageModal
              pages={payload.steps}
              onHide={() => setShowManagePageModel(false)}
              onPagesChanged={(pages) => updateDataHandler({ ...payload, steps: pages })}
              ref={modalApi}
            />
          )}

          <PreviewFormModal
            showPreviewModal={showPreviewModal}
            setShowPreviewModal={setShowPreviewModal}
            businessId={businessId}
            enrolmentFormId={enrolmentForm.id ?? ''}
          />
        </EnrolmentFormDetailContext.Provider>
      </div>
    </DragDropContext>
  );
}
