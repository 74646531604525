import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PageWrapper from 'shared/components/PageWrapper';
import TagsTab from './TagsTab';
import HealthWellnessTab from './HealthWellnessTab';

type BusinessConfigurationTabs = 'tags' | 'healthWellness';

interface IProps {}

const BusinessConfiguration: React.FC<IProps> = ({ ...props }) => {
  const user = useSelector((state: RootState) => state.user);
  const [activeTabKey, setActiveTabKey] = useState<BusinessConfigurationTabs>('tags');

  return (
    <PageWrapper pageTitle="Settings">
      <div className="mx-auto profile-page-width">
        <Tabs
          id="business-configuration-tabs"
          activeKey={activeTabKey}
          onSelect={(tab) => setActiveTabKey(tab as BusinessConfigurationTabs)}
        >
          {user?.isInternal && (
            <Tab eventKey="healthWellness" title="Health & Wellness">
              <HealthWellnessTab />
            </Tab>
          )}
        </Tabs>
      </div>
    </PageWrapper>
  );
};

export default BusinessConfiguration;
