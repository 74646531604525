import React, { useState, useCallback } from 'react';
import ReactCodeInput from 'react-code-input';
import colors from '_colors.module.scss';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import KTTooltip from 'shared/components/Tooltip/Tooltip';
import { faEyeSlash, faEye } from '@fortawesome/pro-light-svg-icons';
import { Row } from 'shared/components/Layout';
import { IconButtonCircle } from '../Buttons';

interface IProps {
  fields: number;
  value?: string;
  required?: boolean;
  label: string;
  type?: 'text' | 'number' | 'password' | 'tel';
  isValid?: boolean;
  isInvalid?: boolean;
  errorText?: string;
  name?: string;
  isMaskedInput?: boolean;
  onBlur?: (name: string) => void;
  onChange?: (code: string) => void;
}

const CodeInputs: React.FC<IProps> = ({
  fields,
  value = '',
  required = true,
  label,
  onChange,
  isValid,
  isInvalid,
  isMaskedInput,
  errorText,
  onBlur = () => {},
  name = 'code-input',
  type = 'text',
}) => {
  const [touched, setTouched] = useState(false);

  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const toggleCodeVisible = useCallback(() => setIsCodeVisible(!isCodeVisible), [isCodeVisible]);
  const [hasReachedMaxInput, setHasReachedMaxInput] = useState(false);

  const handleOnChange = useCallback(
    (e) => {
      if (e.length === fields) {
        setHasReachedMaxInput(true);
      }
      if (hasReachedMaxInput || e.length === fields) {
        setTouched(true);
      }
      onChange && onChange(e);
    },
    [onChange, fields, hasReachedMaxInput]
  );

  const handleBlur = useCallback(
    (e) => {
      setTouched(true);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  let borderColor: string;
  if (isValid) {
    borderColor = colors.success;
  } else if (touched && isInvalid) {
    borderColor = colors.danger;
  } else {
    borderColor = colors.slate;
  }

  // Have to use inline due to native component using inline styles
  const codeInputProps = {
    className: 'kt-code-input no-spinner',
    autoFocus: false,
    inputStyle: {
      height: '40px',
      width: '40px',
      margin: '0 4px',
      textAlign: 'center',
      border: `${borderColor} 1px solid`,
      borderRadius: '.25rem',
      boxShadow: 'none',
    },
    inputStyleInvalid: {
      border: `${colors.danger} 1px solid`,
    },
  };

  return (
    <>
      <div className="d-flex flex-row">
        {label && <Form.Label>{label}</Form.Label>}
        {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
      </div>
      <Row noGutters className={!isCodeVisible && isMaskedInput ? 'masked-input' : ''}>
        <ReactCodeInput
          value={value}
          name={name}
          type={type}
          fields={fields}
          onChange={handleOnChange}
          untouch={() => {}}
          touch={handleBlur}
          {...codeInputProps}
        />
        {isMaskedInput && (
          <KTTooltip additionalClass="d-none d-sm-block" text={isCodeVisible ? 'Hide pin input' : 'Show pin input'}>
            <IconButtonCircle className="ml-4" onClick={toggleCodeVisible} icon={isCodeVisible ? faEye : faEyeSlash} />
          </KTTooltip>
        )}
      </Row>
      {isInvalid && touched && <div className="error-text">{errorText}</div>}
    </>
  );
};

export default CodeInputs;
