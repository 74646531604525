export const GET_AGENCIES_TABLE_DATA = 'GET_AGENCIES_TABLE_DATA';
export const CREATE_AGENCY = 'CREATE_AGENCY';
export const UPDATE_AGENCY = 'UPDATE_AGENCY';

interface IGetAgenciesTableDataAction {
  type: typeof GET_AGENCIES_TABLE_DATA;
  agencies: IAgency[];
}

interface ICreateAgencyAction {
  type: typeof CREATE_AGENCY;
  agency: IAgency;
}

interface IUpdateAgencyAction {
  type: typeof UPDATE_AGENCY;
  agency: IAgency;
}

export type AgencyActionTypes = IGetAgenciesTableDataAction | ICreateAgencyAction | IUpdateAgencyAction;
