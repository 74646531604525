import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { useDispatch } from 'react-redux';

interface IGetClassEnrollmentInformationData {
  getClass: IClass;
}

interface IGetClassEnrollmentInformationVariables {
  id: string;
  start: string;
  end: string;
  includeFutureContracts?: boolean;
  includeLtbOfferings?: boolean;
}

const GET_CLASS_ENROLLMENT_INFORMATION = gql`
  query ($id: String!, $start: Date!, $end: Date!, $includeLtbOfferings: Boolean!, $includeFutureContracts: Boolean!) {
    getClass(id: $id) {
      id
      name
      startsAt
      endsAt
      archivedAt
      capacity
      regulation {
        id
        state
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        youngestStartAge {
          age
          unit
        }
        oldestEndAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      enrollmentDataForTimeframe(
        start: $start
        end: $end
        includeLtbOfferings: $includeLtbOfferings
        includeFutureContracts: $includeFutureContracts
      ) {
        enrollments {
          id
          status
          startDate
          endDate
          expectedAgeUpDate
          child {
            id
            firstname
            lastname
            nickname
            avatar {
              url
            }
            dob
          }
          timeslots {
            day
            date
            week
          }
          contract {
            id
            status
            cycleType
            startDate
            permanentFee {
              id
              utilization
              schedules {
                id
                startDate
                startTime
                endTime
              }
            }
          }
        }
        futureEnrollments {
          id
          status
          startDate
          endDate
          expectedAgeUpDate
          child {
            id
            firstname
            lastname
            nickname
            avatar {
              url
            }
            dob
          }
          timeslots {
            day
            date
            week
          }
          contract {
            id
            status
            cycleType
            startDate
            permanentFee {
              id
              utilization
              schedules {
                id
                startDate
                startTime
                endTime
              }
            }
          }
        }
      }
      availabilityForTimeframe(
        startDate: $start
        endDate: $end
        includeLtbOfferings: $includeLtbOfferings
        includeFutureContracts: $includeFutureContracts
      ) {
        date
        openSpotsAm
        openSpotsPm
        totalOpenSpots
        ageUpAm
        ageUpPm
        totalAgeUp
        isFullDayUtilization
      }
    }
  }
`;

export const useGetClassEnrollmentInformation = (
  id: string,
  start: string,
  end: string,
  includeFutureContracts: boolean,
  includeLtbOfferings: boolean
) => {
  const dispatch = useDispatch();

  return useQuery<IGetClassEnrollmentInformationData, IGetClassEnrollmentInformationVariables>(
    GET_CLASS_ENROLLMENT_INFORMATION,
    {
      skip: !id || id === '',
      variables: {
        id,
        start,
        end,
        includeFutureContracts,
        includeLtbOfferings,
      },
      // notifyOnNetworkStatusChange: true,
      // fetchPolicy: 'network-only',
      // onCompleted: data => {
      //   dispatch(updateEnrollmentDashboardClassesData(data.getClassesForCenter ?? []));
      // },
    }
  );
};
