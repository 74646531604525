import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Section, Page, Field } from 'shared/types/enrollment-form';
import FieldContainer from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/page-section/field-container/field-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { Col, Row } from 'shared/components/Layout';
import Button, { CreateButton } from 'shared/components/Buttons';
import { SectionSelectorContext } from '../section-selector-provider';
import UpdateSectionModal from '../section-modal/section-modal';
import { PublicApi } from '../page-detail/page-detail';
import { useTranslation } from 'react-i18next';
import Checkbox from 'shared/components/Checkbox';
import { isUndefined } from 'lodash';
import { getNonConditionalSections } from '../utils';
import classNames from 'classnames';
import { ConfirmationContent } from 'shared/components/ConfirmationContainer/eventManager';
import { ConditionalControlWidget, SectionLabelWidget, RemoveSectionWidget } from './section-header-widgets';
import { faArrowsUpDownLeftRight } from 'shared/constants/customIcons';
import colors from '_colors.module.scss';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Collapse from 'react-bootstrap/Collapse';

export type PageSectionProps = {
  sectionIndex: number;
  page: Page;
  onPageChanged: (page: Page) => void;
  removeSectionDialog?: Pick<ConfirmationContent, 'title' | 'message'>;
  openAddNewQuestionModel: () => void;
  attachedField?: Field;
  className?: string;
  draggableProvidedDragHandleProps?: DraggableProvidedDragHandleProps;
};

export default function PageSection({
  sectionIndex,
  page,
  onPageChanged,
  removeSectionDialog,
  openAddNewQuestionModel,
  attachedField,
  className,
  draggableProvidedDragHandleProps,
}: PageSectionProps) {
  const modalApi = useRef<PublicApi | null>(null);
  const { setSectionsCanBeSelected } = React.useContext(SectionSelectorContext)!;

  const [showDetails, setShowDetails] = useState(true);

  const section = page.sections[sectionIndex];

  const onSectionRemoved = () => {
    const sections = [...page.sections];
    sections.splice(sectionIndex, 1);
    onPageChanged({ ...page, sections: sections });
  };

  const onSectionChanged = useCallback(
    (s: Section) => {
      const sections = [...page.sections];
      sections.splice(sectionIndex, 1, s);
      onPageChanged({ ...page, sections: sections });
    },
    [onPageChanged, page, sectionIndex]
  );

  const onRemoveSectionAsync = useCallback(async () => onSectionRemoved(), []);
  const { t } = useTranslation(['enrollment']);

  const isConditionalSection = !!section.clientSideBehaviours;
  const nonConditionalSections = getNonConditionalSections(page.sections);

  const onEditSectionLabel = () => modalApi.current?.showSectionModel && modalApi.current.showSectionModel(true);

  const conditionalSectionHeader = useMemo(
    () => (
      <>
        {attachedField && (
          <ConditionalControlWidget field={attachedField} section={section} onChange={onSectionChanged} />
        )}
        <RemoveSectionWidget
          onRemoveAsync={onRemoveSectionAsync}
          removeSectionDialog={removeSectionDialog}
          section={section}
        />
      </>
    ),
    [section, attachedField, onSectionChanged, onRemoveSectionAsync, removeSectionDialog]
  );

  const getUnconditionalSectionHeader = useCallback(
    () => (
      <>
        <div className="d-flex align-items-center">
          <div {...(draggableProvidedDragHandleProps ?? {})}>
            <FontAwesomeIcon
              size="1x"
              icon={faArrowsUpDownLeftRight}
              color={colors['dark-gray']}
              title={t('enrollment:form.drag-n-drop-page')}
              className="mr-3"
            />
          </div>
          <SectionLabelWidget onEdit={onEditSectionLabel} sectionLabel={section.label} />
        </div>

        <div className="d-flex align-items-center">
          <Checkbox
            label={t('enrollment:form.show-section-title')}
            value={isUndefined(section.hideTitle) ? true : !section.hideTitle}
            onChange={(v) => onSectionChanged({ ...section, hideTitle: !v })}
          />
          <RemoveSectionWidget
            onRemoveAsync={onRemoveSectionAsync}
            removeSectionDialog={removeSectionDialog}
            section={section}
          />
          <Button variant="default" onClick={() => setShowDetails((prev) => !prev)} className="m-0 p-0">
            <FontAwesomeIcon
              size="2x"
              icon={showDetails ? faAngleUp : faAngleDown}
              className="ml-3"
              title={
                showDetails ? t('enrollment:form.section-hide-questions') : t('enrollment:form.section-show-questions')
              }
            />
          </Button>
        </div>
      </>
    ),
    [
      section,
      onSectionChanged,
      onRemoveSectionAsync,
      removeSectionDialog,
      t,
      showDetails,
      draggableProvidedDragHandleProps,
    ]
  );

  return (
    <div
      className={classNames(className, {
        'page-section': true,
        'page-section--conditional': isConditionalSection,
      })}
    >
      <Row
        className={classNames({
          'conditional-section-header': isConditionalSection,
          'section-title w-100 d-flex justify-content-between align-items-center': true,
        })}
        noGutters
      >
        {isConditionalSection ? conditionalSectionHeader : getUnconditionalSectionHeader()}
      </Row>

      <Collapse in={showDetails}>
        <Row noGutters align="start">
          <Col>
            <fieldset>
              <FieldContainer
                fields={section.fields}
                fieldSetId={section.id}
                onFieldsChanged={(fields) => {
                  onSectionChanged({ ...section, fields: fields });
                }}
                page={page}
                onPageChanged={onPageChanged}
                allowAddConditionalSectionToField={!isConditionalSection}
                openAddNewQuestionModel={openAddNewQuestionModel}
              />
            </fieldset>
          </Col>
        </Row>
      </Collapse>

      {isConditionalSection && (
        <Row className="p-2">
          <CreateButton
            variant="link"
            onClick={() => {
              setSectionsCanBeSelected([section]);
              openAddNewQuestionModel();
            }}
          >
            {t('enrollment:form.add-question')}
          </CreateButton>
        </Row>
      )}
      <UpdateSectionModal
        ref={modalApi}
        existingSections={nonConditionalSections}
        sectionToBeUpdated={section}
        onEditSection={(section) => onSectionChanged(section)}
      />
    </div>
  );
}
