import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateSession, useUpdateSessionEarlyLateFees } from 'gql/session/mutations';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { createSession, removeSessionById, updateSessions } from 'pages/Attendance/duck/actions';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isOpen: boolean;
  feeTypeToPerformAction: 'early' | 'late';
  actionToPerform: 'remove' | 'restore';
  onClose: (selectedSessions: ISession[]) => void;
  sessions: ISession[];
}

const RemoveRestoreFeeModal: React.FC<IProps> = ({
  isOpen,
  feeTypeToPerformAction,
  actionToPerform,
  sessions,
  onClose,
  ...props
}) => {
  const [formSaving, setFormSaving] = useState(false);
  const dispatch = useDispatch();
  const [updateSessionEarlyLateFeesFn] = useUpdateSessionEarlyLateFees({
    onCompleted: (data) => {
      showToast('Successfully updated session fees.', 'success');
      dispatch(updateSessions(data.updateSessionEarlyLateFees ?? []));
      handleClose([]);
    },
  });
  const [createSessionFn] = useCreateSession({
    onCompleted: (data) => {
      dispatch(createSession(data.createSession));
    },
  });

  const handleClose = useCallback(
    (selectedSessions: ISession[]) => {
      onClose(selectedSessions);
    },
    [onClose]
  );

  const createUpdateFeeInput = useCallback(
    (
      session: ISession,
      feeType: 'early' | 'late',
      action: 'remove' | 'restore'
    ): IUpdateSessionEarlyLateFeeSingleInput => {
      if (action === 'remove') {
        return {
          sessionId: session.id,
          hasEarlyFee: feeType === 'early' ? false : session.hasEarlyFee,
          hasLateFee: feeType === 'late' ? false : session.hasLateFee,
        };
      } else {
        return {
          sessionId: session.id,
          hasEarlyFee: feeType === 'early' ? true : session.hasEarlyFee,
          hasLateFee: feeType === 'late' ? true : session.hasLateFee,
        };
      }
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    setFormSaving(true);
    const promiseResults = await Promise.all(
      sessions.map(async (session) => {
        try {
          const isDerivedFromTimeslot = !session.id || session.id.match(/contract-/);
          if (!isDerivedFromTimeslot) {
            return createUpdateFeeInput(session, feeTypeToPerformAction, actionToPerform);
          } else {
            // selected an expected session. create the session first and then map to update input type
            const result = await createSessionFn({
              variables: {
                input: {
                  accountId: session.accountId,
                  childId: session.childId,
                  classId: session.classId,
                  feeId: session.feeId,
                  date: session.date,
                  dropOffTime: session.dropOffTime ?? '',
                  pickUpTime: session.pickUpTime ?? '',
                },
              },
            });

            if (result?.data?.createSession) {
              // remove derived session from redux since the actual session record will be inserted
              dispatch(removeSessionById(session.id ?? ''));

              return createUpdateFeeInput(result.data.createSession, feeTypeToPerformAction, actionToPerform);
            }

            return null;
          }
        } catch (error) {
          return null;
        }
      })
    ).then((promiseResults) => promiseResults.filter((res) => res !== null));

    // added the type cast since we filter null out of the awaited Promises but TypeScript isn't picking it up
    const mappedSessionInput = promiseResults as IUpdateSessionEarlyLateFeeSingleInput[];

    updateSessionEarlyLateFeesFn({
      variables: {
        input: {
          sessions: mappedSessionInput,
        },
      },
    }).then(() => setFormSaving(false));
  }, [
    sessions,
    actionToPerform,
    feeTypeToPerformAction,
    dispatch,
    createUpdateFeeInput,
    createSessionFn,
    updateSessionEarlyLateFeesFn,
  ]);

  return (
    <CenteredModal
      title={`${actionToPerform === 'remove' ? 'Remove' : 'Restore'} Fee${sessions.length > 1 ? 's' : ''}?`}
      show={isOpen}
      onHide={() => handleClose(sessions)}
      primaryCallback={handleSubmit}
      secondaryCallback={() => handleClose(sessions)}
      primaryButtonProps={{ loading: formSaving }}
    >
      Are you sure you want to {actionToPerform} {sessions.length > 1 ? 'these fees' : 'this fee'}?
    </CenteredModal>
  );
};

export default RemoveRestoreFeeModal;
