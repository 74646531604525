import React, { useCallback, useState } from 'react';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import NotifiableEventsHistoryTable from '../../NotifiableEventsHistoryTable/NotifiableEventsHistoryTable';
import NotifiableEventsContainer from '../NotifiableEventsContainer';
import { IUpdateProviderLocationOfRecord, useUpdateProviderLocationOfRecord } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import moment from 'moment';
import { useGetProviderLocationOfRecords } from '../../graphql/queries';

interface Props {}

interface ILocationOfRecordsInput {
  dateOfEvent: string;
  streetLine1: string;
  streetLine2: string;
  suburb: string;
  state: any;
  postcode: string;
  provider: any;
}

const LocationOfRecords: React.FC<Props> = ({}) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<ILocationOfRecordsInput>({
    dateOfEvent: '',
    streetLine1: '',
    streetLine2: '',
    suburb: '',
    state: undefined,
    postcode: '',
    provider: undefined,
  });
  const [cancelData, setCancelData] = useState<ILocationOfRecordsInput>(formData);

  const [updateLocation, { loading: updateLocationLoading }] = useUpdateProviderLocationOfRecord({
    onCompleted: (data: IUpdateProviderLocationOfRecord) => {
      showToast(`Successfully updated location of records.`, 'success');
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  const { data: addresses, loading } = useGetProviderLocationOfRecords({
    variables: {
      providerId: !!formData.provider ? formData.provider?.value : '',
    },
    skip: !formData.provider || formData.provider?.value === '',
    onCompleted: (res) => {
      const { addresses, dateOfEvent } = res.getProviderLocationOfRecord;
      const latest = addresses.length > 0 ? addresses[addresses.length - 1] : null;
      const state = !!latest?.state ? `AU-${latest.state}` : null;
      if (latest) {
        setFormData((form) => ({ ...form, ...latest, state: state, dateOfEvent: dateOfEvent }));
        setCancelData((form) => ({ ...form, ...latest, state: state, dateOfEvent: dateOfEvent }));
      }
    },
  });

  const validate = useCallback(() => {
    const { dateOfEvent, streetLine1, suburb, state, postcode, provider } = formData;
    const requiredFields = { dateOfEvent, suburb, postcode, streetLine1 };

    return !Object.values(requiredFields).some((val: string) => val === '') && !!provider && !!state;
  }, [formData]);

  const onSubmit = () => {
    if (!validate()) {
      setFormIsDirty(true);
      return;
    }

    updateLocation({
      variables: {
        input: {
          providerId: formData.provider.value,
          dateOfEvent: moment(formData.dateOfEvent).format('YYYY-MM-DD'),
          streetLine1: formData.streetLine1,
          streetLine2: formData.streetLine2,
          suburb: formData.suburb,
          state: formData.state.replace('AU-', ''),
          postcode: formData.postcode,
        },
      },
    });
  };

  return (
    <>
      <NotifiableEventsContainer
        title="Location of Records (Provider closure only)"
        subTitle={undefined}
        notificationMessage="Only for a Provider who has closed or their approval status has been cancelled. Within 14 days of event"
        onCancel={() => setFormData(cancelData)}
        onSave={onSubmit}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        isSaving={updateLocationLoading}
        saveDisabled={!validate()}
        providerOnChange={(option) => setFormData({ ...formData, provider: option })}
        selectedProvider={formData.provider}
        isLoading={loading}
        child={
          <>
            <Row>
              <Col className="mb-2">
                <DateInput
                  required
                  label="Date the change of address took place"
                  date={formData.dateOfEvent}
                  onDateSelect={(date: string) => setFormData({ ...formData, dateOfEvent: date })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Address 1"
                  name="address1"
                  value={formData.streetLine1}
                  onChange={(value: string) => setFormData({ ...formData, streetLine1: value })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required={false}
                  label="Address 2"
                  name="address2"
                  value={formData.streetLine2}
                  onChange={(value: string) => setFormData({ ...formData, streetLine2: value })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Suburb"
                  name="suburb"
                  value={formData.suburb}
                  onChange={(value: string) => setFormData({ ...formData, suburb: value })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <Row align="end">
                  <Col md>
                    <Select
                      required
                      inputId="address-state-input"
                      label={'State'}
                      options={STATE_SELECT_OPTIONS['AU']}
                      value={STATE_SELECT_OPTIONS['AU'].find((option) => option.value === formData.state)}
                      onChange={(option) => setFormData({ ...formData, state: option })}
                    />
                  </Col>
                  <Col md>
                    <TextInput
                      required
                      label="Postcode"
                      name="postcode"
                      value={formData.postcode}
                      onChange={(value: string) => setFormData({ ...formData, postcode: value })}
                      disabled={false}
                      maxlength="4"
                    />
                  </Col>
                </Row>
              </Col>
              <Col>{''}</Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default LocationOfRecords;
