import React, { useState } from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import DataTable, { TableHeader } from 'shared/components/DataTable';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import FilterGroup, { jsFilters } from './FilterGroup';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import colors from '_colors.module.scss';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { toProperCase } from 'shared/util/string';
import { IconButtonCircle } from 'shared/components/Buttons';
import AssignmentsTableCell from './AssignmentsTableCell';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import { ageToNumberOfDays } from 'shared/util/ageToNumberOfDays';
import { orderBy } from 'lodash';
import cast from 'shared/util/cast';
import ArchiveClassModal from './ArchiveClassModal';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IProps {
  selectedCenter: ICenter | undefined;
  classes: IClass[];
}

const CenterClassesTable: React.FC<IProps> = ({ selectedCenter, classes }) => {
  const history = useHistory();
  const timezone = selectedCenter?.timezone ?? moment.tz.guess();
  const user = useSelector((state: RootState) => state.user);
  const cachedStatusFilters = localStorage.getItem(`${user?.email}-class-filters`);
  const [archiveClass, setArchiveClass] = useState<IClass | null>(null);
  const hasDeleteClassPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Classes,
    level: RoleLevelType.Delete,
  });

  const [tableFilters, setTableFilters] = useState({
    search: '',
    statuses: cachedStatusFilters ? JSON.parse(cachedStatusFilters) : [],
    sort: { field: 'name', direction: 'asc' },
  });

  const filteredClasses = classes.filter((p) => {
    const passesSeachFilter = tableFilters.search
      ? p.name.toLowerCase().includes(tableFilters.search.toLowerCase())
      : true;
    const passesStatusFilter = tableFilters.statuses.length
      ? tableFilters.statuses.some((status: any) => jsFilters[status.value](p))
      : true;
    return passesSeachFilter && passesStatusFilter;
  });

  const filteredAndSortedClasses =
    tableFilters.sort.field === 'endAge'
      ? orderBy(
          filteredClasses,
          [
            (item: IClass) =>
              ageToNumberOfDays(item.regulationOverride ? item.regulationOverride.endAge : item.regulation.endAge),
          ],
          [cast<'asc' | 'desc'>(tableFilters.sort.direction)]
        )
      : orderBy(filteredClasses, [tableFilters.sort.field], [cast<'asc' | 'desc'>(tableFilters.sort.direction)]);

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    const sortOrder: 'asc' | 'desc' = direction === 'ASCENDING' ? 'asc' : 'desc'; // lodash uses 'asc' and 'desc'
    setTableFilters((prev) => ({ ...prev, sort: { field, direction: sortOrder } }));
  };

  return (
    <div>
      <DataTable
        data={filteredAndSortedClasses}
        dataSize={filteredAndSortedClasses.length ?? 0}
        showSelect={false}
        showPagination={false}
        onSort={handleSort}
        handleRowClick={(e, row: IClass) =>
          history.push(`/centers/classes/${row.id}`, {
            centerId: selectedCenter?.id,
            centerName: selectedCenter?.name,
          })
        }
        renderHeader={() => (
          <TableHeader>
            <FilterGroup
              onSearch={(term) => setTableFilters((prev) => ({ ...prev, search: term }))}
              onStatusSelect={(statuses) => {
                setTableFilters((prev) => ({ ...prev, statuses }));
                localStorage.setItem(`${user?.email}-class-filters`, JSON.stringify(statuses));
              }}
              selectedStatuses={tableFilters.statuses}
            />
          </TableHeader>
        )}
        columns={[
          {
            text: 'Name',
            dataField: 'name',
            sort: true,
            formatter: (cell: any, row: IClass) => (
              <AvatarDataTableCell color={row.colorCode} initials={row.name[0]} primaryText={row.name} avatar="" />
            ),
          },
          {
            text: 'Start / End Age',
            dataField: 'endAge',
            formatter: (cell: any, row: IClass) => {
              const regulation = row.regulationOverride || row.regulation;
              const startAge = regulation.startAge;
              const endAge = regulation.endAge;
              return `${startAge.age} ${toProperCase(startAge.unit)}${startAge.age === 1 ? '' : 's'} - ${
                endAge.age
              } ${toProperCase(endAge.unit)}${endAge.age === 1 ? '' : 's'} `;
            },
            sort: true,
          },
          {
            text: 'Ratio',
            dataField: 'regulationOverride.ratioTeachers',
            formatter: (cell: any, row: IClass) => {
              const regulation = row.regulationOverride || row.regulation;
              return `${regulation.ratioTeachers} : ${regulation.ratioChildren} `;
            },
          },
          {
            text: 'Start / End Date',
            dataField: 'startsAt',
            sort: true,
            formatter: (cell: any, row: IClass) => {
              const endDate = row.endsAt ? moment(row.endsAt).tz(timezone).format('MMM DD, YYYY') : 'Ongoing';
              return `${moment(row.startsAt).tz(timezone).format('MMM DD, YYYY')} - ${endDate} `;
            },
          },
          {
            text: 'Staff Assignment',
            dataField: 'staff',
            formatter: (cell: any, row: IClass) => <AssignmentsTableCell staff={row.staffAssignments} />,
          },
          {
            text: 'Status',
            headerFormatter: () => (
              <div>
                Status
                <HelpTooltip
                  direction="left"
                  text="Active indicates that a class is currently in session. Inactive, means a class is currently outside of its start and end date."
                />
              </div>
            ),
            dataField: 'status',
            formatter: (cell: any, row: any) => {
              const isActive = jsFilters.active(row);
              const isArchived = row.archivedAt;
              return (
                <ColoredBackgroundTag
                  color={isArchived ? colors.orange : isActive ? colors.seafoamGreen : colors.gray}
                  text={isArchived ? 'Archived' : isActive ? 'Active' : 'Inactive'}
                />
              );
            },
          },
          hasDeleteClassPermission && {
            text: '',
            dataField: '',
            align: 'center',
            formatter: (cell: any, row: any) => {
              if (row.archivedAt) return; // Hide archive button if already archived
              return (
                <IconButtonCircle
                  size="sm"
                  icon={faTimes}
                  color="gray"
                  tooltipText="Archive"
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setArchiveClass(row);
                  }}
                />
              );
            },
            headerClasses: 'text-center',
          },
        ]}
      />
      {archiveClass && (
        <ArchiveClassModal
          isOpen={Boolean(archiveClass)}
          _class={archiveClass}
          onClose={() => {
            setArchiveClass(null);
          }}
        />
      )}
    </div>
  );
};

export default CenterClassesTable;
