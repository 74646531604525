import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shared/components/Layout';
import { ButtonAsLink } from 'shared/components/Buttons';
import {
  ApplicationChild,
  ApplicationFlowType,
  ApplicationOffer,
  ApplicationScheduleOffer,
  ApplicationScheduleOfferState,
} from 'generated/graphql';
import useFormatDate from 'shared/hooks/useFormatDate';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import moment from 'moment';
import OfferAccordion from './OfferAccordion';
import colors from '_colors.module.scss';

interface IProps {
  child: ApplicationChild;
  schedules: ApplicationScheduleOffer[];
  centers: string[];
  handleChange: (value: any, field: keyof ApplicationScheduleOffer, childId: string, schedIndex: number) => void;
  checkAvailability?: (c: ApplicationChild) => void | null;
  handleDayToggle: (value: WeekDay, weekType: WeekType, childId: string, schedIndex: number) => void;
  applicationFlowType?: ApplicationFlowType;
  includeChildInfo?: boolean;
  isDisabled?: boolean;
  expiryDate?: string;
  offers?: ApplicationOffer[];
}

const OfferCard: React.FC<IProps> = ({
  child,
  schedules,
  handleDayToggle,
  handleChange,
  checkAvailability,
  applicationFlowType = ApplicationFlowType.InquireOfferEnrollment,
  includeChildInfo = false,
  isDisabled = false,
  centers,
  expiryDate,
}) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const renderExpiryDate = () => {
    if (!expiryDate) return '';
    const isExpired = moment(expiryDate) < moment();
    const expiryText = isExpired
      ? t('enrollment.lead-management.offer-expired-on')
      : t('enrollment.lead-management.offer-expires-on');
    return (
      <div
        className="rounded px-4 py-1"
        style={{ border: `1px solid ${colors.gray}`, color: isExpired ? colors.danger : colors.textColor }}
      >
        {expiryText}: {formatDate(expiryDate ?? '', 'MMM D, YYYY')}
      </div>
    );
  };

  return (
    <Row>
      <Col>
        <div className="d-flex justify-content-between">
          {includeChildInfo ? (
            <>
              <h6>
                {child.firstName} {child.lastName}
              </h6>
              <p>({getAgeStringFromDateOfBirth(moment(child.dateOfBirth)).replace(/\(|\)/g, '')})</p>
            </>
          ) : (
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="mt-5">{'Offer Details'}</h5>
              {renderExpiryDate()}
            </div>
          )}
          {checkAvailability && (
            <ButtonAsLink onClick={() => checkAvailability(child)}>
              {t('enrollment.lead-management.check-availability')}
            </ButtonAsLink>
          )}
        </div>
        {schedules?.map((sched, index) => {
          const title =
            applicationFlowType === ApplicationFlowType.InquireOfferEnrollment
              ? sched.careType
              : sched.program?.name ?? sched.careType;
          const isDisabledEditing =
            (sched.applicationOfferId !== null &&
              sched.applicationOfferId !== undefined &&
              !(sched.state === ApplicationScheduleOfferState.ApprovalWaitlistPending)) ||
            isDisabled;

          return (
            <OfferAccordion
              key={sched.id}
              schedule={sched}
              handleChange={(value, field) => handleChange(value, field, child.id, index)}
              handleDayToggle={(value, weekType) => handleDayToggle(value, weekType, child.id, index)}
              title={title}
              isDisabled={isDisabledEditing}
              centers={centers}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default OfferCard;
