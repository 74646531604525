import { QueryHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { getCcsEntitlementForAccountChild } from 'pages/Families/subroutes/Accounts/duck/actions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'shared/apis/core';
import {
  ccsEntitlementChangeHistoryFields,
  ccsEntitlementForChildrenFields,
  ccsEntitlementPagedResultFields,
} from './fields';
import { ICcsEntitlementInput, ICcsEntitlement } from '../../shared/types/ccsEntitlement';

interface IGetCcsEntitlementsData {
  getCcsEntitlements: {
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: ICcsEntitlement[];
  };
}

export interface ICcsEntitlementChangeHistory {
  date: string;
  percentage: number;
  totalHoursPerFortnight: number;
}

export interface IGetCcsEntitlementsChangeHistoryData {
  getCcsEntitlementChangeHistory: {
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: ICcsEntitlementChangeHistory[];
  };
}

interface IGetCcsEntitlementsVariables {
  input: ICcsEntitlementInput;
}

interface ICcsEntitlementChangeHistoryInput {
  businessId: string;
  enrollmentId: string;
  pageNumber?: number;
  pageSize?: number;
}

interface IGetCcsEntitlementsChangeHistoryVariables {
  input: ICcsEntitlementChangeHistoryInput;
}

interface IGetCcsEntitlementsForChildrenVariables {
  businessId: string;
  centerId: string;
  accountId: string;
  children: string[];
}

const GET_ENTITLEMENT_CHANGE_HISTORY = (fields: string = ccsEntitlementChangeHistoryFields) => gql`
  query ($input: CcsEntitlementChangeHistoryInput!) {
    getCcsEntitlementChangeHistory (input: $input) {
      ${fields}
    }
  }
`;

const GET_CCS_ENTITLEMENTS = (fields: string = ccsEntitlementPagedResultFields) => gql`
  query ($input: CcsEntitlementInput!) {
    getCcsEntitlements (input: $input) {
      ${fields}
    }
  }
`;

export const GET_CCS_ENTITLEMENTS_FOR_CHILDREN = gql`
  query($businessId: ID!, $centerId: ID!, $accountId: ID!, $children:[ID!]!) {
    getCcsEntitlementsForChildren(businessId: $businessId, centerId: $centerId, accountId: $accountId, children: $children) {
      ${ccsEntitlementForChildrenFields}
    }
  }
`;

export const useGetCcsEntitlements = (
  options?: QueryHookOptions<IGetCcsEntitlementsData, IGetCcsEntitlementsVariables>,
  fields?: string
) =>
  useQuery<IGetCcsEntitlementsData, IGetCcsEntitlementsVariables>(GET_CCS_ENTITLEMENTS(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetCcsEntitlementsForChildren = (
  businessId: string,
  centerId: string,
  accountId: string,
  children: string[]
) => {
  const dispatch = useDispatch();

  return useQuery<{ getCcsEntitlementsForChildren: ICcsEntitlement[] }, IGetCcsEntitlementsForChildrenVariables>(
    GET_CCS_ENTITLEMENTS_FOR_CHILDREN,
    {
      variables: {
        businessId: businessId,
        centerId: centerId,
        accountId: accountId,
        children: children,
      },
      fetchPolicy: 'cache-and-network',
      skip: businessId === '' || centerId === '',
      onCompleted: (data) => {
        for (const entitlement of data.getCcsEntitlementsForChildren) {
          dispatch(getCcsEntitlementForAccountChild(accountId, entitlement.childId, entitlement));
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

export const useGetCcsEntitlementChangeHistory = (
  options?: QueryHookOptions<IGetCcsEntitlementsChangeHistoryData, IGetCcsEntitlementsChangeHistoryVariables>,
  fields?: string
) =>
  useQuery<IGetCcsEntitlementsChangeHistoryData, IGetCcsEntitlementsChangeHistoryVariables>(
    GET_ENTITLEMENT_CHANGE_HISTORY(fields),
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

interface IGetSubsidySchemesInput {
  businessId: string;
}

interface IGetSubsidySchemesVariables {
  input: IGetSubsidySchemesInput;
}

interface IGetSubsidySchemesData {
  getSubsidySchemes: ISubsidyScheme[];
}

const GET_SUBSIDY_SCHEMES = gql`
  query ($input: SubsidySchemeInput!) {
    getSubsidySchemes(input: $input) {
      businessId
      id
      name
      region
    }
  }
`;

export const useGetSubsidySchemes = (
  options?: QueryHookOptions<IGetSubsidySchemesData, IGetSubsidySchemesVariables>
) => {
  return useQuery<IGetSubsidySchemesData, IGetSubsidySchemesVariables>(GET_SUBSIDY_SCHEMES, {
    ...options,
  });
};
