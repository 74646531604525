import { useCallback } from 'react';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
const { ANY, TERM } = SEARCH_EXPRESSIONS;
const { CONTAINS, ONE_OF, EQUALS } = PREDICATES;

const tableColumns: string[] = ['name', 'address.city', 'phoneNumber'];

const useGetStaffSearchExpression = (
  tags: ITableFilterOption[],
  states: { label: string; value: string }[]
): ((term: string | undefined | null) => ISearchExpression[]) => {
  return useCallback(
    (term: string | undefined | null): ISearchExpression[] => {
      if (!term) return [];
      const searchTerm = term.toLowerCase();
      // add query to check if search matches any of the table columns
      const tableColumnsSearchArr: ISearchExpression[] = tableColumns.map((column) => ({
        [TERM]: { field: column, predicate: CONTAINS, value: searchTerm },
      }));

      // Have an exact match on externalId
      tableColumnsSearchArr.push({
        [TERM]: { field: 'externalId', predicate: EQUALS, value: searchTerm },
      });

      // if search matches any tag names, add those tag IDs to the query
      const tagIdsToFilter = tags.filter((t) => t.label.toLowerCase().includes(searchTerm)).map((tag) => tag.value);
      if (tagIdsToFilter.length) {
        tableColumnsSearchArr.push({
          [TERM]: { field: 'tags.keyword', predicate: ONE_OF, value: tagIdsToFilter },
        });
      }

      // if search matches any states names, add those state CODES to the query
      const stateCodesToFilter = states.filter((s) => s.label.toLowerCase().includes(searchTerm)).map((s) => s.value);
      if (stateCodesToFilter.length) {
        tableColumnsSearchArr.push({
          [TERM]: { field: 'address.state.keyword', predicate: ONE_OF, value: stateCodesToFilter },
        });
      }

      // update table state to reflect search query
      return [{ [ANY]: tableColumnsSearchArr }];
    },
    [states, tags]
  );
};

export default useGetStaffSearchExpression;
