import React, { useCallback, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Dictionary, groupBy, orderBy } from 'lodash';
import HealthWellnessTypeCard from './HealthWellnessTypeCard';
import colors from '_colors.module.scss';
import { useGetHealthTerms } from 'gql/healthTerm/queries';
import { useCreateHealthTerm } from 'gql/healthTerm/mutations';
import { showToast } from 'shared/components/Toast';
import CreateNewAllergyTypeModal from './CreateNewAllergyTypeModal';
import EditAllergensModal from './EditAllergensModal';
import ArchiveMedicalTypeModal from './ArchiveMedicalTypeModal';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import UnarchriveHealthWellnessTypeModal from './UnarchiveHealthWellnessTypeModal';

interface IProps {}

const HealthWellnessTab: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation();

  const [newAllergyTypeName, setNewAllergyTypeName] = useState<null | string>(null);
  const [allergyTypeInEdit, setAllergyTypeInEdit] = useState<null | IHealthTerm>(null);
  const [healthTermToArchive, setHealthTermToArchive] = useState<IHealthTerm | null>(null);
  const [healthTermToUnarchive, setHealthTermToUnarchive] = useState<null | IHealthTerm>(null);

  // keys are the health term types
  const [healthWellnessTypes, setHealthWellnessTypes] = useState<Dictionary<IHealthTerm[]>>({
    Medical: [],
    Allergy: [],
    Restriction: [],
  });

  const { data: getHealthTermsData, loading } = useGetHealthTerms({
    onCompleted: (result) => {
      const groupedByType = groupBy(result.getHealthTerms ?? [], (term) => term.type);

      const sortedAndGrouped = Object.keys(groupedByType).reduce(
        (acc: Dictionary<IHealthTerm[]>, curr: string) => ({
          ...acc,
          [curr]: sortByNameAndArchiveStatus(groupedByType[curr]),
        }),
        {}
      );

      setHealthWellnessTypes(sortedAndGrouped);
    },
  });

  //const toastText = ${capitalize(t('spelling.created'))};

  const [createHealthTermFn] = useCreateHealthTerm({
    onCompleted: (result) => {
      showToast(
        `${capitalize(t('spelling.created'))} ${t('spelling.new')} ${healthTermTypeToString(
          result.createHealthTerm.type
        )} ${t('spelling.type')} ${t('spelling.new')}.`,
        'success'
      );
      setHealthWellnessTypes((prev) => ({
        ...prev,
        [result.createHealthTerm.type as string]: sortByNameAndArchiveStatus([
          ...(prev[result.createHealthTerm.type as string] ?? []),
          result.createHealthTerm,
        ]),
      }));
    },
  });

  const createHealthTerm = useCallback(
    (name: string, type: WellnessCategoryType) => {
      createHealthTermFn({
        variables: {
          input: {
            name,
            type,
          },
        },
      });
    },
    [createHealthTermFn]
  );

  /**
   * Sort the array alphabetically and then by archived status with non-archived showing before archived
   */
  const sortByNameAndArchiveStatus = useCallback((arr: IHealthTerm[]): IHealthTerm[] => {
    const groupedByArchivedStatus = groupBy(arr, (item) => item.archivedAt !== null);
    const archivedTypes = orderBy(groupedByArchivedStatus['true'], (item) => item.name, 'asc');
    const activeTypes = orderBy(groupedByArchivedStatus['false'], (item) => item.name, 'asc');

    return [...activeTypes, ...archivedTypes];
  }, []);

  const healthTermTypeToString = useCallback((term: WellnessCategoryType): string => {
    switch (term) {
      case 'Allergy':
        return 'allergy';
      case 'Medical':
        return 'medical condition';
      case 'Restriction':
        return 'restriction';
      default:
        return '';
    }
  }, []);

  return (
    <Row>
      <Col md={4} lg={3}>
        <HealthWellnessTypeCard
          title={`${capitalize(t('spelling.medical'))} ${capitalize(t('spelling.condition'))}`}
          color={colors.orange}
          data={healthWellnessTypes.Medical ?? []}
          onSubmit={(name) => createHealthTerm(name, 'Medical')}
          onArchive={setHealthTermToArchive}
          onUnarchive={setHealthTermToUnarchive}
          loading={loading}
        />
      </Col>
      <Col md={4} lg={3}>
        <HealthWellnessTypeCard
          title={`${capitalize(t('spelling.allergy'))}`}
          color={colors.success}
          data={healthWellnessTypes.Allergy ?? []}
          onSubmit={setNewAllergyTypeName}
          onArchive={setHealthTermToArchive}
          onUnarchive={setHealthTermToUnarchive}
          loading={loading}
          onEdit={setAllergyTypeInEdit}
        />
      </Col>
      <Col md={4} lg={3}>
        <HealthWellnessTypeCard
          title={`${capitalize(t('spelling.restrictions'))}`}
          color={colors.secondary}
          data={healthWellnessTypes.Restriction ?? []}
          onSubmit={(name) => createHealthTerm(name, 'Restriction')}
          onArchive={setHealthTermToArchive}
          onUnarchive={setHealthTermToUnarchive}
          loading={loading}
        />
      </Col>
      {newAllergyTypeName && (
        <CreateNewAllergyTypeModal
          isOpen={Boolean(newAllergyTypeName)}
          name={newAllergyTypeName}
          onClose={() => setNewAllergyTypeName(null)}
          onAddAllergyType={(newAllergyType) =>
            setHealthWellnessTypes((prev) => ({
              ...prev,
              [newAllergyType.type as string]: sortByNameAndArchiveStatus([
                ...prev[newAllergyType.type as string],
                newAllergyType,
              ]),
            }))
          }
        />
      )}
      {allergyTypeInEdit && (
        <EditAllergensModal
          isOpen={Boolean(allergyTypeInEdit)}
          allergyType={allergyTypeInEdit}
          onClose={() => setAllergyTypeInEdit(null)}
        />
      )}
      {healthTermToArchive && (
        <ArchiveMedicalTypeModal
          isOpen={Boolean(healthTermToArchive)}
          healthTerm={healthTermToArchive}
          onClose={() => setHealthTermToArchive(null)}
          onArchiveSuccess={(archivedHealthTerm) =>
            setHealthWellnessTypes((prev) => ({
              ...prev,
              [archivedHealthTerm.type as string]: sortByNameAndArchiveStatus(
                prev[archivedHealthTerm.type as string].map((healthTerm) =>
                  healthTerm.id === archivedHealthTerm.id ? archivedHealthTerm : healthTerm
                )
              ),
            }))
          }
        />
      )}
      {healthTermToUnarchive && (
        <UnarchriveHealthWellnessTypeModal
          isOpen={Boolean(healthTermToUnarchive)}
          healthTerm={healthTermToUnarchive}
          onClose={() => setHealthTermToUnarchive(null)}
          onUnarchiveSuccess={(unarchivedHealthTerm) =>
            setHealthWellnessTypes((prev) => ({
              ...prev,
              [unarchivedHealthTerm.type as string]: sortByNameAndArchiveStatus(
                prev[unarchivedHealthTerm.type as string].map((healthTerm) =>
                  healthTerm.id === unarchivedHealthTerm.id ? unarchivedHealthTerm : healthTerm
                )
              ),
            }))
          }
        />
      )}
    </Row>
  );
};

export default HealthWellnessTab;
