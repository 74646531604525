import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { IKindyForAllQuarterlyPeriod } from 'shared/types/kindyForAll';
import moment from 'moment';
import { PeriodContext } from '.';
import { useGetKindyForAllQuarters } from 'gql/kindyForAll/queries';
import { getYearOptions } from './utils';

interface IProps {
  className?: string;
  onChange: (year: number, period: string) => void;
}

const PeriodPicker: React.FC<IProps> = ({ onChange, className }) => {
  const { year, period, setContext } = useContext(PeriodContext);
  const [quarters, setQuarters] = useState<IKindyForAllQuarterlyPeriod[]>([]);

  useGetKindyForAllQuarters({
    variables: {
      input: {
        years: getYearOptions().map((y) => Number(y.value)),
      },
    },
    onCompleted: (d) => {
      if (quarters.length) {
        return;
      } else {
        const quarters = d?.getKindyForAllQuarters ?? [];

        setQuarters(quarters);

        quarters.forEach((q) => {
          if (q.isCurrent) {
            setContext(q);
            onChange(q.year, q.period);
          }
        });
      }
    },
  });

  const selectedQuarter = quarters.find((q) => q.year == year && q.period == period);

  const onPeriodChange = (step: number = 1) => {
    if (selectedQuarter) {
      var current = quarters.findIndex((q) => q.year == selectedQuarter.year && q.period == selectedQuarter.period);
      var next = quarters[current + step];
      if (next) {
        setContext(next);
        onChange(next.year, next.period);
      }
    }
  };

  return (
    <Form.Group className={`kt-week-input ${className}`}>
      <InputGroup className="flex-nowrap" style={{ width: 'unset' }}>
        <InputGroup.Prepend>
          <Button variant="light" onClick={() => onPeriodChange(-1)} disabled={quarters[0] === selectedQuarter}>
            <FontAwesomeIcon icon={faChevronLeft} size="sm" color="gray" />
          </Button>
        </InputGroup.Prepend>
        <div
          style={{ width: 'unset' }}
          className="form-control cursor-pointer d-flex align-items-center flex-grow-0"
          role="button"
          tabIndex={-1}
        >
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" className="mr-3" />
          <span className="pr-2">{selectedQuarter?.period}</span>
          <span className="pr-2">
            ({moment(selectedQuarter?.startDate).format('MMM D')}
            <FontAwesomeIcon icon={faArrowRight} size="xs" className="mr-3 ml-3" />
            {moment(selectedQuarter?.endDate).format('MMM D')})
          </span>
          <span>{selectedQuarter?.year}</span>
        </div>
        <InputGroup.Append>
          <Button
            variant="light"
            onClick={() => onPeriodChange()}
            disabled={quarters[quarters.length - 1] === selectedQuarter}
          >
            <FontAwesomeIcon icon={faChevronRight} size="sm" color="gray" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  );
};

export default PeriodPicker;
