import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
}

const UploadOverlay: React.FC<IProps> = ({ onClick, children, ...props }) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <div
      className="position-relative kt-avatar-upload-size-wrapper"
      onMouseEnter={() => !isHovering && setIsHovering(true)}
      onMouseLeave={() => isHovering && setIsHovering(false)}
      onClick={onClick}
    >
      {isHovering && (
        <div
          className="kt-avatar-upload-instructional-container d-flex justify-content-center align-items-center position-absolute h-100 w-100 top-0 left-0"
          aria-label="Upload Photo"
        >
          <FontAwesomeIcon size="lg" color="#FFFFFF" icon={faCamera} />
        </div>
      )}
      {children}
    </div>
  );
};

export default UploadOverlay;
