import React, { useCallback } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import './_voidOrBouncedModal.scss';

const NO_OP = () => {};

interface IProps extends ModalProps {
  className?: string;
  title?: string;
  subtitle?: string;
  onHide?: () => void;
  voidCallback?: () => void;
  bouncedCallback?: () => void;
  closeCallback?: () => void;
  primaryButtonProps?: any;
  bouncedButtonProps?: any;
  scrollable?: boolean;
  children?: React.ReactNode | null;
  hideOnCallback?: boolean;
}

const VoidOrBouncedModal: React.FC<IProps> = ({
  className,
  title = '',
  subtitle = '',
  onHide = NO_OP,
  voidCallback = NO_OP,
  bouncedCallback = NO_OP,
  closeCallback = NO_OP,
  voidButtonProps = {},
  bouncedButtonProps = {},
  scrollable,
  children,
  ...rest
}) => {
  const { t } = useTranslation(['billing']);
  const onVoidCallback = useCallback(() => {
    if (voidCallback) voidCallback();
  }, [voidCallback]);

  const onBouncedCallback = useCallback(() => {
    if (bouncedCallback) bouncedCallback();
  }, [bouncedCallback]);

  const onCloseCallbackFunction = useCallback(() => {
    if (onHide) onHide();
    if (closeCallback) closeCallback();
  }, [onHide, closeCallback]);

  return (
    <Modal
      backdrop="static"
      size="lg"
      centered
      dialogClassName={`void-modal ${className || ''}`}
      onHide={onHide}
      scrollable={scrollable}
      {...rest}
    >
      <Modal.Header closeButton className="border-bottom-0">
        <div className="modal-header-title-container d-flex flex-column">
          <Modal.Title as="h3">{title}</Modal.Title>
          {subtitle && <p>{subtitle}</p>}
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0">{children}</Modal.Body>
      <Modal.Footer className={`border-top-${scrollable ? 1 : 0}`}>
        <Button variant="light" onClick={onCloseCallbackFunction}>
          Cancel
        </Button>
        <Button className="ml-auto mr-2" {...bouncedButtonProps} onClick={onBouncedCallback}>
          Mark As Bounced
        </Button>
        <Button {...voidButtonProps} onClick={onVoidCallback}>
          {t('billing:transactions.payments.void-check-modal.void-check')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VoidOrBouncedModal;
