import React from 'react';
import { useSelector } from 'react-redux';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '../../../_colors.module.scss';
import { RootState } from '../../../store/reducers';
import moment from 'moment';

interface IProps {
  sessionCostData: ISessionCost | undefined | null;
  chargeFeeOpen: boolean;
  isWeeklyView?: boolean;
  onProceedCharge: () => void;
  onCancel: () => void;
}

const ChargeFeeModal: React.FC<IProps> = ({
  sessionCostData,
  chargeFeeOpen,
  isWeeklyView,
  onProceedCharge,
  onCancel,
  ...props
}) => {
  const start = moment(useSelector((state: RootState) => state.enrollment.activeTimeframe.start)).format('MM-DD-YYYY');
  const end = moment(useSelector((state: RootState) => state.enrollment.activeTimeframe.end)).format('MM-DD-YYYY');
  const currentDate = moment().format('MM-DD-YYYY');
  const accountName = useSelector((state: RootState) => state.attendance.sessions[0].accountName);

  const styles = {
    closeIcon: {
      '& svg': {
        opacity: 0.6,
      },
      '& svg:hover': {
        opacity: 1,
      },
    },
  };
  return (
    <>
      <Mui.Dialog open={chargeFeeOpen} onClose={onCancel}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography
            variant="dialogTitle"
            display="flex"
            fontWeight={700}
            fontSize="1.75rem"
            component="span"
            sx={{ alignItems: 'center' }}
          >
            Process Charge?
          </Mui.Typography>
          <Mui.IconButton sx={styles.closeIcon} disableRipple onClick={onCancel}>
            <FontAwesomeIcon icon={faTimes} size="xs" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        {isWeeklyView ? (
          <Mui.DialogContent>
            <Mui.DialogContentText id="alert-dialog-description">
              The Account {accountName} is going to be charged ${(sessionCostData?.gap ?? 0).toFixed(2)} for the Billing
              Period of {start} to {end}.
            </Mui.DialogContentText>
          </Mui.DialogContent>
        ) : (
          <Mui.DialogContent>
            <Mui.DialogContentText id="alert-dialog-description">
              The Account {accountName} is going to be charged ${(sessionCostData?.gap ?? 0).toFixed(2)} for the Session
              date of {currentDate}.
            </Mui.DialogContentText>
          </Mui.DialogContent>
        )}
        <Mui.DialogActions>
          <Mui.Button onClick={onCancel}>Cancel</Mui.Button>
          <Mui.Button color="secondary" variant="contained" onClick={onProceedCharge} autoFocus>
            Proceed
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};
export default ChargeFeeModal;
