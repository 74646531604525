import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ContractScheduleDays from './ContractScheduleDays';

interface IProps {
  contract: IContract;
  centerTimezone: Timezone;
  week1Timeslots: IContractTimeslot[];
  week2Timeslots: IContractTimeslot[];
}

const BiweeklySchedule: React.FC<IProps> = ({ contract, centerTimezone, week1Timeslots, week2Timeslots, ...props }) => {
  return (
    <Tabs defaultActiveKey="week1" id="contract-biweekly-weeks" transition={false}>
      <Tab eventKey="week1" title="Week 1">
        <ContractScheduleDays contract={contract} centerTimezone={centerTimezone} timeslots={week1Timeslots} />
      </Tab>
      <Tab eventKey="week2" title="Week 2">
        <ContractScheduleDays contract={contract} centerTimezone={centerTimezone} timeslots={week2Timeslots} />
      </Tab>
    </Tabs>
  );
};

export default BiweeklySchedule;
