import React, { useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Row, Col } from 'shared/components/Layout';
import TextInput, { EmailInput } from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import DateInput from 'shared/components/DateInput';
import Checkbox from 'shared/components/Checkbox';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import User from 'shared/types/user';
import { sortBy } from 'lodash';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import { useGetPositionsForBusiness } from '../../graphql/queries';
import { Dropdown } from 'react-bootstrap';
import Alert from 'shared/components/Alert';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useGetRolesForBusinessThatUserCanManage } from 'shared/hooks/useGetRolesForBusiness';
import PhoneInput from 'shared/components/PhoneInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export interface INewEmployeeForm {
  firstName: string;
  lastName: string;
  email: string;
  positionId: string | null;
  startDate: string | null;
  roleId: string;
  primaryCenterId: string | null;
  entityId: string | null;
  sendInvite: boolean;
  payRate: number | undefined;
  payRateType: 'Hourly' | 'Salary';
  phoneNumber?: string;
}

interface IProps {
  currentUser: User;
  formData: INewEmployeeForm;
  onChange: React.Dispatch<React.SetStateAction<INewEmployeeForm>>;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const NewEmployeeForm: React.FC<IProps> = ({ currentUser, formData, onChange, ...props }) => {
  const { t } = useTranslation();
  const {
    data: positionsForBusiness,
    loading: loadingPositionsForBusiness,
    ...rest
  } = useGetPositionsForBusiness(formData.entityId ?? '');
  const { data: roleOptions, loading } = useGetRolesForBusinessThatUserCanManage(formData.entityId ?? '', currentUser);

  // for an internal user
  const { data: getAllowedEntitiesData, loading: getAllowedEntitiesLoading } = useGetAllowedEntities(
    !currentUser.isInternal ?? true
  );
  const businessSelectOptions: IEntity[] = useMemo(
    () => sortBy(getAllowedEntitiesData?.getAllowedEntities, (entity) => entity.name) ?? [],
    [getAllowedEntitiesData]
  );
  const centerSelectOptions: ICenter[] = useGetActiveCenters().filter((c) => c.entityId === formData.entityId);
  const [enableSendInvite, setEnableSendInvite] = useState(false);

  const selectedRole = roleOptions.find((r) => r.id === formData.roleId);
  const scopeType = selectedRole?.scopeType;

  const formCompleteWithOutStartDate = useMemo(() => {
    return (
      !!formData.firstName &&
      !!formData.lastName &&
      !!formData.roleId &&
      !!formData.positionId &&
      (!!formData.primaryCenterId || scopeType === 'ENTITY') &&
      !!formData.email &&
      !formData.startDate
    );
  }, [formData]);

  const localisedPositionsForBusiness =
    positionsForBusiness?.getPositionsByBusinessId.map((p) => ({
      ...p,
      name: p.name.replace(/Center|Centre/g, fieldLabels.center),
    })) ?? [];

  return (
    <Form id="new-staff-form">
      <Row>
        <Col>
          <TextInput
            id="firstname-input"
            required
            name="new-staff-fn"
            label="First Name"
            value={formData.firstName}
            onChange={(value: string) => onChange((prev) => ({ ...prev, firstName: value }))}
          />
        </Col>
        <Col>
          <TextInput
            id="lastname-input"
            required
            name="new-staff-ln"
            label="Last Name"
            value={formData.lastName}
            onChange={(value: string) => onChange((prev) => ({ ...prev, lastName: value }))}
          />
        </Col>
      </Row>
      {currentUser.isInternal && (
        <Row>
          <Col>
            <Select
              required
              key={formData.entityId}
              label="Business"
              aria-label={"Select employee's business"}
              placeholder="Select Business"
              loading={loading}
              options={businessSelectOptions.map((b) => ({ value: b.id, label: b.name }))}
              onChange={(option: any) => {
                onChange((prev) => ({
                  ...prev,
                  entityId: option.value,
                  roleId: '',
                  positionId: null,
                  primaryCenterId: '',
                }));
              }}
              value={formData.entityId}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Select
            required
            id="center-input"
            key={formData.primaryCenterId}
            label={`Primary ${fieldLabels.center}`}
            aria-label={`Select employee\'s primary ${fieldLabels.center.toLowerCase()}`}
            placeholder={`Primary ${fieldLabels.center}`}
            value={scopeType === 'ENTITY' ? 'All' : formData.primaryCenterId}
            options={
              scopeType === 'ENTITY' ? ['All'] : centerSelectOptions.map((c) => ({ value: c.id, label: c.name }))
            }
            onChange={(option: any) => {
              onChange((prev) => ({
                ...prev,
                primaryCenterId: option.value,
              }));
            }}
            isLoading={getAllowedEntitiesLoading}
            disabled={scopeType === 'ENTITY' || getAllowedEntitiesLoading}
            helpTooltipText="The location this employee will predominantly work or need access to. This will become their default location as a user."
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            id="role-input"
            label="Role"
            isLoading={loading}
            aria-label={"Select employee's role"}
            placeholder="Select Role"
            value={formData.roleId}
            key={formData.roleId}
            options={sortBy(
              roleOptions.map((r) => ({ value: r.id, label: r.name, role: r })),
              'label'
            )}
            onChange={(option) =>
              onChange((prev) => ({
                ...prev,
                roleId: option.value ?? '',
                primaryCenterId: option.role.scopeType === 'ENTITY' ? null : prev.primaryCenterId,
              }))
            }
            isOptionSelected={(option: any) => option.value === formData.roleId}
          />
        </Col>
        <Col>
          <DateInput
            label="Start Date"
            className="mb-4"
            required={false}
            date={formData.startDate}
            onDateSelect={(date: string) => {
              setEnableSendInvite(!!date);
              onChange((prev) => ({
                ...prev,
                startDate: moment(date).startOf('day').format(),
                sendInvite: !!date ? prev.sendInvite : false,
              }));
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            id="position-dropdown-input"
            label="Position"
            helpTooltipText="Title or scope of responsibilities."
            value={formData.positionId}
            key={formData.positionId}
            options={sortBy(localisedPositionsForBusiness, (p) => p.name).map((p) => ({ value: p.id, label: p.name }))}
            isLoading={loadingPositionsForBusiness}
            onChange={(option) => onChange((prev) => ({ ...prev, positionId: option.value ?? null }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CurrencyInput
            className="border-radius-0"
            onChange={(value) => onChange((prev) => ({ ...prev, payRate: value }))}
            value={formData.payRate}
            label="Pay Rate"
            appendNode={
              <DropdownButton
                alignRight
                id="pay-rate-type-dropdown"
                variant="outline-secondary"
                as={InputGroup.Append}
                title={
                  <span className="pl-1">
                    {`${formData.payRateType}`} <FontAwesomeIcon transform={'right-5'} icon={faAngleDown} />
                  </span>
                }
              >
                <Dropdown.Item onClick={() => onChange((prev) => ({ ...prev, payRateType: 'Hourly' }))}>
                  Hourly
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onChange((prev) => ({ ...prev, payRateType: 'Salary' }))}>
                  Salary
                </Dropdown.Item>
              </DropdownButton>
            }
          />
        </Col>
      </Row>
      <PhoneInput
        id="phone-input"
        label="Phone Number"
        name="staff-ph"
        value={formData.phoneNumber}
        onChange={(phoneNumber) => onChange((prev) => ({ ...prev, phoneNumber }))}
      />
      <Row>
        <Col>
          <EmailInput
            id="email-input"
            required
            name="new-staff-em"
            label="Email"
            value={formData.email}
            onChange={(value) => onChange((prev) => ({ ...prev, email: value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            label="Send Invite?"
            value={formData.sendInvite}
            disabled={!enableSendInvite}
            onChange={(value: boolean) => onChange((prev) => ({ ...prev, sendInvite: value }))}
          />
        </Col>
      </Row>
      {formCompleteWithOutStartDate && (
        <Row>
          <Col>
            <Alert className="mt-4" variant="info">
              A start date must be included to invite a new employee into the system. If you are not ready to invite an
              employee hit save, otherwise add a start date.
            </Alert>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default NewEmployeeForm;
