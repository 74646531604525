import React, { useState } from 'react';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import MappingListItemInput, { MappingOptionType } from '../MappingForm/MappingListItemInput';
import {
  AccountStatusType,
  IntegrationMappingType,
  IntegrationType,
  useGetExternalTypesQuery,
  useGetIntegrationMappingsQuery,
  useUpsertIntegrationMappingsMutation,
} from 'generated/graphql';
import Spinner from 'shared/components/Spinner';
import { showToast } from 'shared/components/Toast';
import { toPairs } from 'lodash';
import { useTranslation } from 'react-i18next';

interface IUseGetDisplayDataInput {
  businessId: string;
  integrationType: IntegrationType;
  integrationMappingType: IntegrationMappingType;
}

function useGetDisplayData({ businessId, integrationType, integrationMappingType }: IUseGetDisplayDataInput) {
  const { t } = useTranslation(['integrations']);

  const { data: externalTypesData, loading: externalTypesLoading } = useGetExternalTypesQuery({
    variables: { input: { businessId, integrationType, integrationMappingType } },
  });

  const {
    data: integrationMappingData,
    loading: integrationMappingLoading,
    refetch: refetchIntegrationMappings,
  } = useGetIntegrationMappingsQuery({
    variables: { input: { businessId, integrationType, integrationMappingType } },
    notifyOnNetworkStatusChange: true,
  });

  const sources: Record<AccountStatusType, { sourceHint?: string; sourceName?: string }> = {
    Future: {
      sourceHint: t('integrations:mappings.account-status.future'),
    },
    Active: {
      sourceHint: t('integrations:mappings.account-status.active'),
    },
    Inactive: {
      sourceHint: t('integrations:mappings.account-status.inactive'),
    },
  };

  const getValues = (field: keyof typeof sources) => ({
    defaultValue: integrationMappingData?.getIntegrationMappings
      .filter((im) => im.internalId === field)
      .map((im) => ({
        value: im.externalId,
        label: externalTypesData?.getExternalTypes.find((et) => et.id === im.externalId)?.name ?? 'NO LABEL FOUND',
      }))[0],
    options: externalTypesData?.getExternalTypes.map((x) => ({
      label: x.name,
      value: x.id,
    })),
  });

  // https://stackoverflow.com/a/60932900
  const createKeys = (keyRecord: typeof sources): (keyof typeof sources)[] => {
    return Object.keys(keyRecord) as any;
  };

  const keys = createKeys(sources);

  return {
    loading: externalTypesLoading || integrationMappingLoading,
    refetch: refetchIntegrationMappings,
    displayData: keys.map((k) => ({ id: k, sourceName: k, ...sources[k], ...getValues(k) })),
  };
}

interface IProps {
  businessId: string;
}

const CcrmAccountStatusMapForm = ({ businessId }: IProps) => {
  const { t } = useTranslation(['integrations']);

  const [formData, setFormData] = useState<Partial<Record<AccountStatusType, MappingOptionType>>>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const {
    displayData,
    loading: displayDataLoading,
    refetch,
  } = useGetDisplayData({
    businessId,
    integrationType: IntegrationType.ChildcareCrm,
    integrationMappingType: IntegrationMappingType.AccountStatus,
  });

  const handleChange = (value: typeof formData) => {
    setFormData({ ...formData, ...value });
    setIsDirty(true);
  };

  const handleCancel = () => {
    setFormData({});
    refetch();
  };

  const [mutateUpsertIntegrationMappings, { loading }] = useUpsertIntegrationMappingsMutation();
  const handleSave = () => {
    mutateUpsertIntegrationMappings({
      variables: {
        input: {
          businessId,
          message: {
            integrationType: IntegrationType.ChildcareCrm,
            mapping: toPairs(formData).map((fd) => {
              return { internalType: IntegrationMappingType.AccountStatus, internalId: fd[0], externalId: fd[1].value };
            }),
          },
        },
      },
    })
      .then((result) => {
        setIsDirty(false);
        setFormData({});
        showToast(t('integrations:mappings.success'), 'success');
      })
      .catch((err) => showToast(`${err.toString()}`, 'error'));
  };

  const items = displayData.map((x) => (
    <MappingListItemInput
      id={x.id}
      sourceName={x.sourceName}
      sourceHint={x.sourceHint}
      options={x.options}
      defaultValue={x.defaultValue}
      onChange={(key, value) => handleChange({ [key]: value })}
    />
  ));

  if (displayDataLoading) return <Spinner />;
  return (
    <FormWrapper2
      formIsDirty={isDirty}
      toggleDirty={setIsDirty}
      className="pl-5"
      onCancel={handleCancel}
      onSave={handleSave}
      loading={loading}
    >
      {items}
    </FormWrapper2>
  );
};

export default CcrmAccountStatusMapForm;
