import React from 'react';

interface IProps {}

const TimeSheetHeader: React.FC<IProps> = ({ ...props }) => {
  return (
    <thead className="px-4">
      <tr>
        <th className="kt-time-sheets-time-entry-day-text-secondary">Date</th>
        <th className="kt-time-sheets-time-entry-day-text-secondary">Scheduled Time</th>
        <th className="kt-time-sheets-time-entry-day-text-secondary">Actual Time</th>
        <th className="kt-time-sheets-time-entry-day-text-secondary">Difference</th>
      </tr>
    </thead>
  );
};

export default TimeSheetHeader;
