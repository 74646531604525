import { Button, Card, CardContent, CardHeader, Divider, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { ErrorContent } from '../ErrorContent';
import { useState } from 'react';
import NewBill from '../BillModal';
import { useGetBill } from '../queries/useGetBill';
import useFormatDate from 'shared/hooks/useFormatDate';
import RenderWhen from 'US/Utilities/Components/RenderWhen';
import { Bill } from '../types/Bill';
import { RowDetail } from './RowDetail';

interface BillingRowOverviewProps {
  billId: string;
}

export const BillRowOverview: React.FC<BillingRowOverviewProps> = (props) => {
  const { data, loading, error, refetch } = useGetBill({ billId: props.billId });
  const [showDetails, setShowDetails] = useState(false);
  const handleShowDetailsClick = () => setShowDetails(true);
  const handleShowDetailsClose = () => setShowDetails(false);
  return (
    <>
      <Card variant="outlined">
        <CardHeader title="Bill" sx={{ padding: '8px 6px 0px 8px', borderBottom: 'none' }} />
        <CardContent>
          <RenderWhen>
            <RenderWhen.If isTrue={!loading && !!error}>
              <ErrorContent onTryAgain={refetch} error={error} />
            </RenderWhen.If>
            <RenderWhen.If isTrue={loading || !!data}>
              <BillContent data={data} loading={loading} onShowDetailsClick={handleShowDetailsClick}></BillContent>
            </RenderWhen.If>
          </RenderWhen>
        </CardContent>
      </Card>
      <NewBill open={showDetails} onClose={handleShowDetailsClose} bill={data ?? null}></NewBill>
    </>
  );
};

function BillContent({
  data,
  loading,
  onShowDetailsClick,
}: {
  data: Bill | undefined;
  loading: boolean;
  onShowDetailsClick: () => void;
}) {
  const formatDate = useFormatDate();
  return (
    <>
      <Stack sx={{ marginBottom: '1rem' }}>
        <RowDetail name="Bill No" value={data?.billNo ?? undefined} loading={loading} />
        <RowDetail name="Sent On" value={formatDate(data?.billDate ?? '', 'MM/DD/YY')} loading={loading} />
        <RowDetail name="Due On" value={formatDate(data?.dueDate ?? '', 'MM/DD/YY')} loading={loading} />
        <RowDetail
          loading={loading}
          name="Billing Period"
          value={
            data?.billingPeriod?.start
              ? `${formatDate(data?.billingPeriod.start ?? '', 'MM/DD/YY')} - ${formatDate(
                  data?.billingPeriod.end ?? '',
                  'MM/DD/YY'
                )}`
              : 'N/A'
          }
        />
      </Stack>
      <Stack direction="column">
        <Typography fontWeight={600} marginBottom="1rem">
          Summary
        </Typography>
        <br />
        <RowDetail name="Forward Balance" value={data?.forwardAmount} loading={loading}></RowDetail>
        <RowDetail name="Charges" value={data?.subTotal} loading={loading}></RowDetail>
        <Divider />
        <RowDetail name="Bill Total" value={data?.totalDue} loading={loading}></RowDetail>
      </Stack>

      <br />
      <Button
        fullWidth
        variant="contained"
        color="info"
        sx={{ marginTop: 2 }}
        disabled={loading}
        onClick={onShowDetailsClick}
      >
        See Details
      </Button>
    </>
  );
}
