import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEncryptEnrollmentFormHashMutation } from 'generated/graphql';
import { Modal } from 'react-bootstrap';
import { LoadingLines, Box, Header } from 'shared/components/LoadingSkeletons';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { getHostName } from 'pages/Enrollment/subroutes/LeadManagement/utils';
import Checkbox from 'shared/components/Checkbox';

export type IPreviewFormModalProps = {
  showPreviewModal: boolean;
  setShowPreviewModal: (showPreviewModal: boolean) => void;
  businessId: string;
  enrolmentFormId: string;
};

export default function PreviewFormModal({
  showPreviewModal,
  setShowPreviewModal,
  businessId,
  enrolmentFormId,
}: IPreviewFormModalProps) {
  const { t } = useTranslation(['translation', 'enrollment', 'business']);
  const formPreviewRef = useRef<HTMLIFrameElement>(null);
  const [isIFrameLoading, setIsIFrameLoading] = useState(true);

  const [encryptEnrollmentFormHashFn, { loading: isEncrypting, data: encryptedHashResponse }] =
    useEncryptEnrollmentFormHashMutation({
      variables: { enrollmentFormId: enrolmentFormId, businessId },
      onError: (error) => showToast(getApolloErrorMessage(error), 'error'),
    });

  const onIframeLoaded = useCallback(() => {
    setIsIFrameLoading(false);
  }, []);

  // handle iframe loading
  useEffect(() => {
    const iframeNode = formPreviewRef.current;
    iframeNode?.addEventListener('load', onIframeLoaded);
    return () => {
      iframeNode?.removeEventListener('load', onIframeLoaded);
    };
  }, [onIframeLoaded, isEncrypting, showPreviewModal]);

  const [isSkipValidation, setSkipValidation] = useState(true);

  // handle get formHash
  useEffect(() => {
    if (showPreviewModal) {
      encryptEnrollmentFormHashFn();
    }
  }, [showPreviewModal, encryptEnrollmentFormHashFn]);

  if (showPreviewModal) {
    return (
      <Modal
        show={showPreviewModal}
        backdrop="static"
        onHide={() => {
          setIsIFrameLoading(true);
          setShowPreviewModal(false);
        }}
        centered
        size="xl"
        scrollable
        className="form-preview"
      >
        <Modal.Header closeButton>
          <div className="grid">
            <div className="row">
              <Modal.Title>
                {t('translation:core.capitalize', { value: t('translation:spelling.preview') })}
              </Modal.Title>
            </div>
            <div className="row">
              <span>Note: No form validation is enforced during the preview.</span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0 text-center bg-body">
          {isIFrameLoading && (
            <>
              <Header />
              <LoadingLines />
              <Box />
              <LoadingLines />
              <Box />
              <LoadingLines />
              <Box />
              <LoadingLines />
            </>
          )}
          {!isEncrypting && (
            <iframe
              ref={formPreviewRef}
              loading="eager"
              title="preview"
              style={{ border: 'none' }}
              src={`https://${getHostName()}/preview/${encodeURIComponent(
                encryptedHashResponse?.encryptEnrollmentFormHash ?? ''
              )}${isSkipValidation ? '?isSkipValidation=true' : ''}`}
              width="1024"
              height="1680"
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  return null;
}
