import moment from 'moment';
import { getAllClasses } from 'pages/Centers/subroutes/Classes/duck/selectors';
import { useGetClassesForCenters } from 'pages/Centers/subroutes/Classes/graphql/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { optionCSS } from 'react-select/src/components/Option';
import DateInput from 'shared/components/DateInput';
import FormLabel from 'shared/components/Form/FormLabel';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import BaseSelect from 'shared/components/Select/Base';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { capitalize } from 'shared/util/string';

interface IFormStateShape {
  centerId: string;
  selectedClasses: Array<{ value: string; label: string }> | null;
  date: string | null;
  format: 'EXCEL' | 'PDF';
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  showReportFormat?: boolean;
  hideArchivedClasses?: boolean;
  hideInactiveClasses?: boolean;
  onSubmit: (date: string, centerId: string, classIds: string[], format: 'EXCEL' | 'PDF') => void;
  onClose: () => void;
}

const SingleDateMultipleClassReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  showReportFormat = false,
  hideArchivedClasses = false,
  hideInactiveClasses = false,
  ...props
}) => {
  const { t } = useTranslation();
  const ALL_CLASS_OPTIONS = { value: 'All', label: `All ${capitalize(t('spelling.class'))}es` };
  const [formData, setFormData] = useState<IFormStateShape>({
    centerId: '',
    selectedClasses: [],
    date: null,
    format: 'EXCEL',
  });
  const centerSelectOptions: ICenter[] = useGetActiveCenters();

  const { loading: classesLoading } = useGetClassesForCenters(formData.centerId ?? '');
  const classes = useSelector(getAllClasses);
  const [classSelectOptions, setClassSelectOptions] = useState<Array<{ label: string; value: string }>>([]);

  const isAllOptionSelected = useCallback(
    (): boolean =>
      !!formData.selectedClasses &&
      formData.selectedClasses.length > 0 &&
      formData.selectedClasses.length === classSelectOptions.length,
    [formData.selectedClasses, classSelectOptions]
  );

  const getSelectValue = useCallback(() => {
    return isAllOptionSelected()
      ? [ALL_CLASS_OPTIONS]
      : classSelectOptions.filter(
          (opt) => !!formData.selectedClasses && formData.selectedClasses.map((i) => i.value).includes(opt.value)
        );
  }, [isAllOptionSelected, classSelectOptions, formData.selectedClasses]);

  useEffect(() => {
    let result = [...classes];
    result.sort((a, b) => a.name.localeCompare(b.name));

    if (hideInactiveClasses) {
      result = result.filter((item) => {
        if (item.archivedAt) {
          return false;
        }

        const startsAtMoment = moment(item.startsAt);
        const endsAtMoment = moment(item.endsAt);
        const now = moment();
        const benchmark = formData.date ? moment(formData.date) : now;
        return (
          startsAtMoment.isValid() &&
          startsAtMoment.isBefore(benchmark) &&
          (!endsAtMoment.isValid() || endsAtMoment.isAfter(benchmark))
        );
      });
    } else if (hideArchivedClasses) {
      result = result.filter((option) => !option.archivedAt);
    }

    setClassSelectOptions(result.map((item) => ({ label: item.name, value: item.id })));
  }, [classes, hideArchivedClasses, hideInactiveClasses, setClassSelectOptions, formData]);

  useEffect(() => {
    if (!isOpen) {
      setFormData({ centerId: '', selectedClasses: [], date: null, format: 'EXCEL' });
    }
  }, [isOpen]);

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (formData.date && formData.centerId && formData.selectedClasses) {
      onSubmit(
        formData.date,
        formData.centerId,
        formData.selectedClasses.map((i) => i.value),
        formData.format
      );
    }
  }, [formData, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({ centerId: '', selectedClasses: [], date: null, format: 'EXCEL' });
    onClose();
  }, [onClose]);

  const handleClassSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_CLASS_OPTIONS.value) {
        setFormData({ ...formData, selectedClasses: classSelectOptions });
      } else if (
        (action === 'deselect-option' && option.value === ALL_CLASS_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_CLASS_OPTIONS.value)
      ) {
        setFormData({ ...formData, selectedClasses: [] });
      } else if (actionMeta.action === 'deselect-option' && isAllOptionSelected()) {
        setFormData({
          ...formData,
          selectedClasses: (formData.selectedClasses || []).filter((i) => i.value !== option.value),
        });
      } else {
        setFormData({ ...formData, selectedClasses: (options || []).filter((opt) => opt.value !== null) });
      }
    },
    [classSelectOptions, formData.selectedClasses, isAllOptionSelected]
  );

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled: !formData.date || !formData.centerId || !formData.selectedClasses || !formData.selectedClasses.length,
        loading: isLoading,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
    >
      <Row>
        <Col>
          <Select
            required
            id="center-input"
            label={capitalize(t('spelling.center'))}
            aria-label={`Select ${capitalize(t('spelling.center'))}`}
            placeholder={`${capitalize(t('spelling.center'))}`}
            value={formData.centerId}
            options={centerSelectOptions.map((c) => ({ value: c.id, label: c.name }))}
            onChange={(option: any) => setFormData((prev) => ({ ...prev, centerId: option.value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            label={`${capitalize(t('spelling.date'))}`}
            date={formData.date}
            required
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, date, selectedClasses: [] }))}
            className="kt-date-input-no-max-width mb-4"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <FormLabel required>{capitalize(t('spelling.class'))}</FormLabel>
            <BaseSelect
              // @ts-ignore
              isMulti
              required
              id="class-input"
              isLoading={classesLoading}
              label={`${capitalize(t('spelling.class'))}`}
              aria-label={`Select ${capitalize(t('spelling.class'))}`}
              placeholder={capitalize(t('spelling.class'))}
              // @ts-ignore
              value={getSelectValue()}
              options={[ALL_CLASS_OPTIONS, ...classSelectOptions]}
              // @ts-ignore
              onChange={(options, action) => handleClassSelect(options as any[], action)}
            />
          </Form.Group>
        </Col>
      </Row>
      {showReportFormat && (
        <Row>
          <Col>
            <Form.Label>{capitalize(t('spelling.format'))}(s)</Form.Label>
            <Select
              options={[
                { value: 'EXCEL', label: 'EXCEL' },
                { value: 'PDF', label: 'PDF' },
              ]}
              value={formData.format ?? { value: 'EXCEL', label: 'EXCEL' }}
              onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
              className="mb-0"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Col>
        </Row>
      )}
    </SideModalDrawer>
  );
};

export default SingleDateMultipleClassReportModal;
