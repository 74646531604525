import moment from 'moment';

export const getTotalDays = (timeOff: ITimeOff | ITimeOffInput) => {
  let countingDate = moment(timeOff.startTime).startOf('day');
  let numberOfWeekdays = 1;
  while (countingDate.isBefore(moment(timeOff.endTime).startOf('day'))) {
    countingDate.add(1, 'days');
    if (countingDate.day() !== 0 && countingDate.day() !== 6) {
      numberOfWeekdays += 1;
    }
  }
  return numberOfWeekdays;
};

export const getTotalHours = (timeOff: ITimeOff | ITimeOffInput) => {
  const startDate = moment(timeOff.startTime);
  const endDate = moment(timeOff.endTime);
  const hours = timeOff.allDay
    ? getTotalDays(timeOff) * 8
    : Math.round(endDate.diff(startDate, 'hours', true) * 100) / 100;
  return hours || 0;
};
