import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import ControlContainer from '../ControlContainer';

interface IProps {
  tableView: ScheduleTableView;
  changeTableView: (view: ScheduleTableView) => void;
}

/**
 * This component houses components that control which center's schedule to show
 * and whether the table should show shifts or breaks
 */
const ViewControls: React.FC<IProps> = ({ tableView, changeTableView, ...props }) => {
  return (
    <ControlContainer className="mb-2">
      <div className="kt-schedules-view-control-grid">
        <div className="kt-schedules-view-control-center-data"></div>
        <div className="kt-schedules-view-control-table-data">
          <ButtonGroup aria-label="Schedule table view toggle">
            <Button onClick={() => changeTableView('SHIFTS')} variant={tableView === 'SHIFTS' ? 'secondary' : 'light'}>
              Shifts
            </Button>
            <Button onClick={() => changeTableView('BREAKS')} variant={tableView === 'BREAKS' ? 'secondary' : 'light'}>
              Breaks
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </ControlContainer>
  );
};

export default ViewControls;
