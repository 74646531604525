import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './EnrollmentFormDiffModal.scss';
import { EnrolmentFormAreaEnum, FormattedEnrolmentDiffAreas } from './useEnrolmentFormDiffValueFormatter';
import FormDiffSection from './FromDiffSection';
import FormDiffArea from './FormDiffArea';
import FormDiff from './FormDiff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { getHostName } from '../../utils';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  enrolmentFormDataRecordId?: string;
  accountName?: string;
  accountId?: string;
  lastModified?: string;
  formattedEnrolmentDiffData?: FormattedEnrolmentDiffAreas | undefined;
  applicationOrUpdateFormRequestCompleted?: boolean;
  formLinkCode?: string;
  isUpdateFormRequest?: boolean;
  centerId?: string;
}

const EnrollmentFormDiffModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  enrolmentFormDataRecordId,
  accountName,
  accountId,
  lastModified,
  formattedEnrolmentDiffData,
  applicationOrUpdateFormRequestCompleted = false,
  formLinkCode,
  isUpdateFormRequest = false,
  centerId,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const { businessId } = useSelector((state: RootState) => state.context);
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);

  const viewFormUrl = useMemo(() => {
    if (isUpdateFormRequest)
      return `https://${getHostName()}/parent-portal/updateEnrolmentForm/business/${businessId}/center/${centerId}/correlation/${formLinkCode}?token=${jwtToken}&isPrintMode=true`;
    return `https://${getHostName()}/offer/${formLinkCode}?token=${jwtToken}&isPrintMode=true`;
  }, [businessId, centerId, formLinkCode, isUpdateFormRequest, jwtToken]);

  return (
    <Modal centered show={isOpen} onHide={handleClose} size="lg" className="enrolment-form-diff-modal" scrollable>
      <Modal.Header closeButton className="enrolment-form-diff-modal--header">
        <Modal.Title as="div">
          <h2 className="mb-0">{t('enrollment:enrolment-form-diff-modal.modal-title')}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="application-note-modal--body">
        <div className="d-flex justify-content-end align-items-center">
          <div className="enrol-form-link d-flex align-items-center">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mr-2" color={colors.primary} />
            <a
              href={viewFormUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-4 mb-0 d-block text-primary text-primary-hover"
            >
              View Form
            </a>
          </div>
          <div className="px-4 py-1 lead-label ml-2" style={{ border: `1px solid ${colors.gray}` }}>
            <p>
              <strong>{t('enrollment:enrolment-form-diff-modal.date-of-change')}</strong>:
              <span className="ml-2">{moment(lastModified?.split('T')[0]).format('DD MMM YYYY')}</span>
            </p>
          </div>
        </div>
        {formattedEnrolmentDiffData?.diffSections.map((diffSection) => {
          const showLink =
            !(
              diffSection.areaLinkUrl === undefined ||
              diffSection.areaLinkUrl === null ||
              diffSection.areaLinkUrl === ''
            ) ||
            (!applicationOrUpdateFormRequestCompleted && !diffSection.isNew);

          return (
            <FormDiffSection
              key={uuidv4()}
              sectionTitle={diffSection.areaTitle ?? ''}
              sectionLinkTitle={diffSection.areaLinkTitle ?? accountName ?? ''}
              enrolFormUrl={diffSection.areaLinkUrl ?? ''}
              accountId={accountId ?? ''}
              accountName={accountName ?? ''}
              showLink={showLink}
              showAccountLink={diffSection.area === EnrolmentFormAreaEnum.Account || !showLink}
              isNewChildOrContact={diffSection.isNew}
            >
              {diffSection.diffAreas.map((diffArea) => {
                return (
                  <FormDiffArea areaTitle={diffArea.sectionTitle} key={uuidv4()}>
                    {diffArea.diffList.map((diff) => {
                      return (
                        <FormDiff
                          key={uuidv4()}
                          label={diff.label}
                          oldValue={diff.oldValue}
                          newValue={diff.newValue}
                        ></FormDiff>
                      );
                    })}
                  </FormDiffArea>
                );
              })}
            </FormDiffSection>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};

export default EnrollmentFormDiffModal;
