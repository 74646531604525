import { faInfoCircle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { useMediaQuery, useTheme } from '@mui/material';
import { useGetKindyForAllAcquittalCenterSummaries } from 'gql/kindyForAll/queries';
import { forwardRef, useCallback, useContext, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButtonCircle from 'shared/components/Buttons/IconButtonCircle';
import DataTable, { SizePerPage, TableHeader, TableSearch, TABLE_DEFAULTS } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { setCurrentCenterFilters } from 'store/context/actions';
import { RootState } from 'store/reducers';
import ActionDropdown from 'shared/components/ActionDropdown';
import { RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import DeleteAcquittalModal from './DeleteAcquittalModal';
import { useGetKindyForAllAcquittalErrorsReportLazy } from 'gql/reports/queries';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import { IKindyForAllAcquittalCenterSummary, IKindyForAllSubmissionError } from 'shared/types/kindyForAll';
import Currency from 'shared/components/Currency';
import PeriodPicker from '../../PeriodPicker';
import { PeriodContext } from '../..';
import {
  FreeKindyAcquittalCenterSummaryDto,
  Maybe,
  SortDirection,
  useGetFreeKindyAcquittalCenterSummariesQuery,
} from 'generated/graphql';
import AcquittalCenterSummaryExpandRow from './AcquittalCenterSummaryExpandRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'shared/components/Tooltip/Tooltip';
import colors from '_colors.module.scss';
import Chip from 'shared/components/Chip';

interface IProps {}

interface ISort {
  direction: ElasticsearchSortDirection;
  field: string;
}

export type RefetchCentreAcquittalsHandler = {
  refetch: () => void;
};

const CenterAcquittalsTab = forwardRef<RefetchCentreAcquittalsHandler, IProps>(({}, ref) => {
  const { businessId, centerFilterIds } = useSelector((state: RootState) => state.context);
  const { data: centers } = useGetActiveCentersWithLoading();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { year, period } = useContext(PeriodContext);
  const [sort, setSort] = useState<ISort>({ field: 'centerName', direction: 'ASCENDING' });
  const [tableState, tableFunctions] = useDatatableState();
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [acquittalToDelete, setAcquittalToDelete] = useState<IKindyForAllAcquittalCenterSummary | undefined>(undefined);

  const skipKindyForAllQuery = businessId == null || businessId === '' || year !== 2023;
  const skipFreeKindyQuery = businessId == null || businessId === '' || year < 2024;

  const {
    data: data,
    loading,
    refetch: refetchKindyForAllCenterSummaries,
  } = useGetKindyForAllAcquittalCenterSummaries({
    variables: {
      input: {
        centerIds: centerFilterIds ?? [],
        year,
        period,
        searchKey: searchTerm,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortBy: sort.field,
        sortDirection: sort.direction,
      },
    },
    skip: skipKindyForAllQuery,
  });

  const {
    data: freeKindyData,
    loading: freeKindyDataLoading,
    refetch: refetchFreeKindyCenterSummaries,
  } = useGetFreeKindyAcquittalCenterSummariesQuery({
    variables: {
      input: {
        centerIds: centerFilterIds ?? [],
        year,
        period,
        searchKey: searchTerm,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortBy: sort.field,
        sortDirection: sort.direction == 'ASCENDING' ? SortDirection.Ascending : SortDirection.Descending,
      },
    },
    skip: skipFreeKindyQuery,
  });

  const refetchSummaries = () => {
    if (!skipKindyForAllQuery) refetchKindyForAllCenterSummaries();
    if (!skipFreeKindyQuery) refetchFreeKindyCenterSummaries();
  };

  useImperativeHandle(ref, () => ({
    refetch: () => {
      refetchSummaries();
    },
  }));

  const clearAppliedFilters = useCallback(() => {
    dispatch(setCurrentCenterFilters([]));
    // Reset paging when changing filters
    tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.PAGE_SIZE);
  }, [dispatch, tableFunctions]);
  const reportDataToFile = useReportDataToFile();

  const [kindyForAllAcquittalErrorsReportFn] = useGetKindyForAllAcquittalErrorsReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(
        result.getKindyForAllAcquittalErrorsReport,
        ReportTypeEnum.KINDY_FOR_ALL_ACQUITTAL_ERRORS
      );
    },
    onError: () => {
      showToast(t('reports.general-failure-message'), 'error');
    },
  });

  const getStatus = (hasErrorsOrWarnings: boolean, status: Maybe<string> | undefined) => {
    switch (status) {
      case 'Errored':
        return <Chip text={status} type="danger" />;

      case 'Submitted':
        return hasErrorsOrWarnings ? (
          <Chip text={`${status} (with warnings)`} type="success" />
        ) : (
          <Chip text={status} type="success" />
        );

      case 'Unsubmitted':
        return hasErrorsOrWarnings ? (
          <Chip text={`${status} (with warnings)`} type="info" />
        ) : (
          <Chip text={status} type="info" />
        );

      case 'Processing':
        return <Chip text={status} type="warning" />;

      default:
        return status;
    }
  };

  const actionsForRow = useCallback(
    (cell: any, row: IKindyForAllAcquittalCenterSummary): IDropdownAction[] => {
      return [
        {
          label: 'Delete',
          permission: { permission: 'Base', area: 'Agency', level: RoleLevelType.Delete },
          onClick: () => {
            setAcquittalToDelete(row);
            setShowDeleteModal(true);
          },
        },
        {
          label: 'Download',
          permission: { permission: 'Base', area: 'Agency', level: RoleLevelType.Read },
          disabled: row?.messages?.length === 0,
          onClick: () => {
            if (businessId) {
              const errors =
                row?.messages?.map(
                  (e) =>
                    ({
                      centerId: row?.centerId,
                      centerName: row?.centerName,
                      year: row.year.toString(),
                      period: row.period,
                      code: e?.code,
                      message: e?.message,
                      target: e?.target,
                      innerError: e?.innerError,
                    } as IKindyForAllSubmissionError)
                ) ?? [];

              kindyForAllAcquittalErrorsReportFn({
                variables: {
                  input: {
                    businessId: businessId,
                    errors: errors,
                  },
                },
              });
            }
          },
        },
      ];
    },
    [businessId, kindyForAllAcquittalErrorsReportFn]
  );

  const getTableColumns = useCallback((): any[] => {
    const tableColumns: any = [
      {
        dataField: 'centerName',
        text: 'Centre', // Do we care about translations here? This is an AU only page
        sort: true,
      },
      {
        dataField: 'year',
        text: 'Year',
        sort: true,
      },
      {
        dataField: 'period',
        text: 'Term',
        sort: true,
      },
      {
        dataField: 'childCount',
        text: 'Children',
        sort: false,
      },
    ];

    if (year < 2024) {
      tableColumns.push(
        ...[
          {
            dataField: 'kindyFtbCount',
            text: 'Kindy FTB',
            sort: false,
          },
          {
            dataField: 'kfsPlusCount',
            text: 'Kindy Plus',
            sort: false,
          },
          {
            dataField: 'status',
            text: 'Status',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalCenterSummary) =>
              row?.messages?.length > 0 ? `${row.status} (with warnings)` : row.status,
          },
          {
            dataField: 'forecastedAmount',
            text: 'Forecast ($)',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalCenterSummary) =>
              row.forecastedAmount ? <Currency direction="Positive" amount={row.forecastedAmount} /> : '-',
          },
          {
            dataField: 'acquittedAmount',
            text: 'Acquitted ($)',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalCenterSummary) =>
              row.acquittedAmount ? <Currency direction="Positive" amount={row.acquittedAmount} /> : '-',
          },
          {
            text: 'Difference ($)',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalCenterSummary) =>
              row.acquittedAmount && row.forecastedAmount ? (
                <Currency amount={row.acquittedAmount - row.forecastedAmount} />
              ) : (
                '-'
              ),
          },
          {
            dataField: 'paidToFamiliesAmount',
            text: 'Paid to Families ($)',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalCenterSummary) =>
              row.paidToFamiliesAmount ? <Currency direction="Positive" amount={row.paidToFamiliesAmount} /> : '-',
          },
        ]
      );
    }

    if (year >= 2024) {
      tableColumns.push(
        ...[
          {
            dataField: 'status',
            text: 'Status',
            sort: false,
            formatter: (cell: any, row: FreeKindyAcquittalCenterSummaryDto) => {
              return getStatus(row?.messages.length > 0, row.status);
            },
          },
          {
            dataField: 'totalPayment',
            text: 'Total Payment',
            sort: false,
            formatter: (cell: any, row: FreeKindyAcquittalCenterSummaryDto) =>
              row.paidToFamiliesAmount ? <Currency direction="Positive" amount={row.totalPayment} /> : '-',
          },
          {
            dataField: 'totalOwed',
            text: (
              <>
                Total Owed{' '}
                <Tooltip text="Adjustments may occur to these totals during subsequent acquittal periods if child data has changed.">
                  <FontAwesomeIcon icon={faInfoCircle} color={colors.steelBlue} />
                </Tooltip>
              </>
            ),
            sort: false,
            formatter: (cell: any, row: FreeKindyAcquittalCenterSummaryDto) =>
              row.paidToFamiliesAmount ? <Currency direction="Positive" amount={row.totalOwed} /> : '-',
          },
        ]
      );
    }

    tableColumns.push(
      ...[
        {
          text: 'Actions',
          dataField: '',
          align: 'center',
          headerClasses: 'text-center',
          classes: 'td-overflow',
          formatter: (cell: any, row: IKindyForAllAcquittalCenterSummary) => (
            <ActionDropdown actions={actionsForRow(cell, row)} />
          ),
        },
      ]
    );
    return tableColumns;
  }, [actionsForRow, year]);

  const handleCenterFilter = useCallback(
    (centers: ITableFilterOption[]) => dispatch(setCurrentCenterFilters(centers.map((c) => c.value))),
    [dispatch]
  );

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSort({ field, direction });
  }, []);

  const dataTableProps =
    year < 2024
      ? {
          data: data?.getKindyForAllAcquittalCenterSummaries.data ?? [],
          dataSize: data?.getKindyForAllAcquittalCenterSummaries?.totalRecords ?? 0,
          expandRow: () => <></>,
        }
      : {
          data: freeKindyData?.getFreeKindyAcquittalCenterSummaries.data ?? [],
          dataSize: freeKindyData?.getFreeKindyAcquittalCenterSummaries?.totalRecords ?? 0,
          expandRow: (summary) => <AcquittalCenterSummaryExpandRow year={year} summary={summary} />,
        };

  return (
    <div>
      <DataTable
        {...dataTableProps}
        keyField="centerId"
        columns={getTableColumns()}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        showLoadingOverlay={loading || freeKindyDataLoading}
        showSelect={false}
        onSort={handleSort}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        renderHeader={(paginationProps) => (
          <>
            <TableHeader className="flex-wrap align-items-center">
              <div className="d-flex flex-wrap mr-auto align-items-center">
                <SizePerPage paginationProps={paginationProps} />
                <TableSearch placeholder="Search" onChange={setSearchTerm} className={isMobile ? 'mt-2 mb-1' : ''} />
              </div>
              <div className="d-flex flex-wrap mr-auto align-items-center">
                <PeriodPicker
                  onChange={() => {
                    // Reset paging when changing filters
                    tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.PAGE_SIZE);
                  }}
                />
              </div>
              <div className={isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex align-items-center'}>
                <DropdownFilter
                  title="Centre"
                  className="mr-4"
                  selectedFilters={centerFilterIds}
                  options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
                  onFilterSelect={handleCenterFilter}
                />

                <IconButtonCircle
                  icon={faTimes}
                  onClick={clearAppliedFilters}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                />
              </div>
            </TableHeader>
          </>
        )}
      />
      {acquittalToDelete && (
        <DeleteAcquittalModal
          onClose={() => setShowDeleteModal(false)}
          isOpen={showDeleteModal}
          acquittal={acquittalToDelete}
          refetchCenterSummaries={refetchSummaries}
        />
      )}
    </div>
  );
});

export default CenterAcquittalsTab;
