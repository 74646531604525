import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { useTranslation } from 'react-i18next';
import CenterAcquittalsTab, { RefetchCentreAcquittalsHandler } from './CentersTab/CentersAcquittalList';
import ChildAcquittalsTab from './ChildrenTab/ChildrenAcquittalList';
import { IKindyForAllQuarterlyPeriod } from 'shared/types/kindyForAll';

type AcquittalTabs = 'center' | 'children';

interface IProps {}

export type ExternalHandler = {
  refetchCenterAcquittals: () => void;
};

const AcquittalTab = forwardRef<ExternalHandler, IProps>(({}, ref) => {
  const { t } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState<AcquittalTabs>('center');
  const refetchCentreAcquittalsRef = useRef<RefetchCentreAcquittalsHandler | null>(null);

  useImperativeHandle(ref, () => ({
    refetchCenterAcquittals: () => {
      refetchCentreAcquittalsRef.current?.refetch();
    },
  }));

  return (
    <PageWrapperBody>
      <Tabs
        id="time-log-tabs"
        activeKey={activeTabKey}
        onSelect={(tab) => {
          setActiveTabKey(tab as AcquittalTabs);
        }}
        className="mb-0 pl-4"
      >
        <Tab eventKey="center" title={t('spelling.center')}>
          <CenterAcquittalsTab ref={refetchCentreAcquittalsRef} />
        </Tab>
        <Tab eventKey="children" title="Children">
          <ChildAcquittalsTab />
        </Tab>
      </Tabs>
    </PageWrapperBody>
  );
});

export default AcquittalTab;
