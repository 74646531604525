import { useSearchCenters } from 'gql/center/queries';
import React, { useCallback, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import FormLabel from 'shared/components/Form/FormLabel';
import BaseSelect from 'shared/components/Select/Base';
import { capitalize } from 'shared/util/string';
import CheckboxSelectOption from './CheckboxSelectOption';

interface IProps {
  /**
   * `null` can represent `All` when `useNullForAllOption` is provided
   */
  selectedCenterIds: string[] | null;
  /**
   * Provide `null` as the value via `onSelect` if the all option is selected.
   */
  useNullForAllOption?: boolean;
  onSelect: (centerIds: string[] | null) => void;
  showLabel?: boolean;
  className?: string;
  isRequired?: boolean;
  excludeCentreIds?: (string | null)[];
  /**
   * only show the centres that belong to these states
   */
  states?: string[];
  disabled?: boolean;
  checkboxes?: boolean;
}

/**
 * Component is wrapped in Form.Group to match our standard Select component.
 * The regular <Select /> component was not used since the actionMetadata on dropdown selection
 * is needed and our shared component does not expose that information
 */
const MultipleCenterSelect: React.FC<IProps> = ({
  selectedCenterIds,
  useNullForAllOption = false,
  onSelect,
  showLabel = true,
  className,
  isRequired,
  states,
  disabled,
  excludeCentreIds,
  checkboxes = false,
  ...props
}) => {
  const { t } = useTranslation();
  const ALL_CENTERS_OPTIONS = {
    value: null,
    label: `All ${capitalize(t('spelling.center'))}s`,
    isDisabled: excludeCentreIds?.includes(null) ?? false,
  };

  const { loading: searchCentersLoading, data: searchCentersData } = useSearchCenters(
    {
      variables: {
        input: {
          filter: { all: [] },
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          from: 0,
          size: 10000,
        },
      },
    },
    `id name address{state}`
  );

  const centerSelectOptions = useMemo(() => {
    const data = searchCentersData?.searchCenters.data ?? [];
    let options =
      !states || !states.length
        ? data
            .map((center) => ({ label: center.name, value: center.id }))
            .sort((a, b) => a.label.localeCompare(b.label))
        : data!
            .filter((c) => states.includes(c.address.state))
            .map((center) => ({ label: center.name, value: center.id }))
            .sort((a, b) => a.label.localeCompare(b.label));

    if (excludeCentreIds && excludeCentreIds.length > 0) {
      options = options.map((o) => ({ ...o, isDisabled: excludeCentreIds.includes(o.value) }));
    }
    return options;
  }, [searchCentersData, states, excludeCentreIds]);

  const suppressAllCentreOption = states != null && states.length > 0;
  /**
   * Determine if the "all" option has been selected in the dropdown
   */
  const isAllOptionSelected = useCallback((): boolean => {
    if (suppressAllCentreOption) {
      return false;
    }

    return useNullForAllOption && !selectedCenterIds;
  }, [selectedCenterIds, useNullForAllOption, centerSelectOptions, suppressAllCentreOption]);

  /**
   * Get the array of selected values. React-select wants an array of all of the objects selected
   */

  const getSelectValue = useCallback(() => {
    return isAllOptionSelected()
      ? [ALL_CENTERS_OPTIONS]
      : centerSelectOptions.filter((opt) => (selectedCenterIds ?? []).includes(opt.value));
  }, [isAllOptionSelected, ALL_CENTERS_OPTIONS, centerSelectOptions, selectedCenterIds]);

  /**
   * Handle the selection of an option from the dropdown
   */
  const handleSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_CENTERS_OPTIONS.value) {
        onSelect(useNullForAllOption ? null : centerSelectOptions.map((opt) => opt.value as string));
      } else if (
        (action === 'deselect-option' && option.value === ALL_CENTERS_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_CENTERS_OPTIONS.value)
      ) {
        onSelect([]);
      } else if (actionMeta.action === 'deselect-option' && isAllOptionSelected()) {
        onSelect((selectedCenterIds ?? []).filter((id) => id !== option.value));
      } else {
        onSelect((options ?? []).filter((opt) => opt.value !== null).map((opt) => opt.value));
      }
    },
    [centerSelectOptions, selectedCenterIds, useNullForAllOption, ALL_CENTERS_OPTIONS, isAllOptionSelected, onSelect]
  );

  return (
    <Form.Group className={className}>
      {showLabel && <FormLabel required={isRequired}>{capitalize(t('spelling.center')) + '(s)'}</FormLabel>}
      <BaseSelect
        // @ts-ignore
        isMulti
        // @ts-ignore
        value={searchCentersLoading ? null : getSelectValue()}
        // @ts-ignore
        options={suppressAllCentreOption ? [...centerSelectOptions] : [ALL_CENTERS_OPTIONS, ...centerSelectOptions]}
        // @ts-ignore
        onChange={(options, action) => handleSelect(options as any[], action)}
        isLoading={searchCentersLoading}
        className="react-select-container flex-wrap"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        noOptionsMessage={() => `No ${t('spelling.center_plural')} found`}
        components={
          checkboxes ? { IndicatorSeparator: null, Option: CheckboxSelectOption } : { IndicatorSeparator: null }
        }
        data-testid="multiple-center-select"
        isDisabled={disabled ?? false}
      />
      {/* <Dropdown.Divider className="mb-0" />
      <div className="d-flex justify-content-end align-items-center p-2">
        <Button variant="light" disabled={false} onClick={() => ()}>
        <FontAwesomeIcon icon={faTimes} className="flex-grow-1" color={colors.iconColor} />
        <span className="ml-2">Clear</span>
      </Button> 
    </div>*/}
    </Form.Group>
  );
};

export default MultipleCenterSelect;
