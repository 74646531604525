import { gql } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'shared/apis/core';
import { RootState } from 'store/reducers';
import { getTotalCreatedStaffSuccess } from 'pages/Employees/subroutes/Profiles/duck/actions';
import { MutationHookOptions } from '@apollo/client';

interface IResendInvitationVariables {
  input: IResendInvitation;
}

interface IInvitationResponseData {
  sendInvitation: IEmailResponseData;
}

interface IDeletePendingStaffVariables {
  personId: string;
}

interface IDeletePendingStaffData {
  deletePendingStaff: IStaff;
}

export const SEND_INVITATION = gql`
  mutation ($input: SendInvitationInput!) {
    sendInvitation(input: $input) {
      messageId
      success
      personId
    }
  }
`;

export const DELETE_PENDING_STAFF = gql`
  mutation ($personId: ID!) {
    deletePendingStaff(personId: $personId) {
      id
    }
  }
`;

export const useSendInvitation = () => {
  const profiles = useSelector((state: RootState) => state.profiles);
  const dispatch = useDispatch();

  return useMutation<IInvitationResponseData, IResendInvitationVariables>(SEND_INVITATION, {
    update: (proxy, result) => {
      if (result.data?.sendInvitation) {
        const data = [
          ...profiles.createdStaffData.filter((staff: IStaff) => staff.id !== result.data?.sendInvitation.personId),
        ];
        dispatch(getTotalCreatedStaffSuccess(profiles.totalCreatedStaff - 1, data));
      }
    },
  });
};

export const useDeletePendingStaff = (
  options?: MutationHookOptions<IDeletePendingStaffData, IDeletePendingStaffVariables>
) => {
  return useMutation<IDeletePendingStaffData, IDeletePendingStaffVariables>(DELETE_PENDING_STAFF, {
    ...options,
  });
};
