import { uniqBy } from 'lodash';
import cast from 'shared/util/cast';
import * as types from './types';

export interface ITransactionStateShape {
  all: ITransaction[];
  tableData: ITransaction[];
  openingBalance: number | undefined;
  closingBalance: number | undefined;
}

const initialState: ITransactionStateShape = {
  all: [],
  tableData: [],
  openingBalance: 0,
  closingBalance: 0,
};

export const transactionReducers = (
  state: ITransactionStateShape = initialState,
  action: types.TransactionActionTypes
): ITransactionStateShape => {
  switch (action.type) {
    case types.GET_TRANSACTIONS:
      return {
        ...state,
        // `all` will include everything that is passed via this action while `tableData` will only contain the most recent value
        all: uniqBy([...state.all, ...action.transactions], 'id'),
        tableData: action.transactions,
        openingBalance: action.openingBalance,
        closingBalance: action.closingBalance,
      };
    case types.CREATE_TRANSACTION:
      return {
        ...state,
        all: [...state.all, action.transaction],
        tableData: [...state.tableData, action.transaction],
      };
    case types.UPDATE_TRANSACTION: {
      return {
        ...state,
        all: state.all.map((transaction) =>
          transaction.id === action.transaction.id ? { ...transaction, ...action.transaction } : transaction
        ),
        tableData: state.tableData.map((transaction) =>
          transaction.id === action.transaction.id ? { ...transaction, ...action.transaction } : transaction
        ),
      };
    }
    case types.CREATE_REVERSE_TRANSACTION:
      return {
        ...state,
        all: [
          ...state.all.map((t) =>
            t.id === action.transaction.reversedTransactionId
              ? {
                  ...t,
                  reversedAt: action.transaction.createdAt,
                  reversedBy: action.transaction.createdBy,
                  reversedByPerson: action.transaction.createdByPerson,
                  reversedByTransaction: action.transaction,
                }
              : t
          ),
          action.transaction,
        ],
        tableData: [
          ...state.tableData.map((t) =>
            t.id === action.transaction.reversedTransactionId
              ? {
                  ...t,
                  reversedAt: action.transaction.createdAt,
                  reversedBy: action.transaction.createdBy,
                  reversedByPerson: action.transaction.createdByPerson,
                  reversedByTransaction: action.transaction,
                }
              : t
          ),
          action.transaction,
        ],
      };
    case types.UPDATE_PAYMENT:
      return {
        ...state,
        all: [
          ...state.all.map((t) =>
            t.payment?.id === action.oldPaymentId
              ? {
                  ...t,
                  reversedAt: action.newTransaction.createdAt,
                  reversedBy: action.newTransaction.createdBy,
                  reversedByPerson: action.newTransaction.createdByPerson,
                  payment: cast<IPayment>({
                    ...t.payment,
                    status: 'CANCELLED',
                    rejectedBy: action.newTransaction.createdBy,
                    rejectedByPerson: action.newTransaction.createdByPerson,
                    rejectedAt: action.newTransaction.createdAt,
                  }),
                }
              : t
          ),
          action.newTransaction,
        ],
        tableData: [
          ...state.tableData.map((t) =>
            t.payment?.id === action.oldPaymentId
              ? {
                  ...t,
                  reversedAt: action.newTransaction.createdAt,
                  reversedBy: action.newTransaction.createdBy,
                  reversedByPerson: action.newTransaction.createdByPerson,
                  payment: cast<IPayment>({
                    ...t.payment,
                    status: 'CANCELLED',
                    rejectedBy: action.newTransaction.createdBy,
                    rejectedByPerson: action.newTransaction.createdByPerson,
                    rejectedAt: action.newTransaction.createdAt,
                  }),
                }
              : t
          ),
          action.newTransaction,
        ],
      };
    case types.APPLY_DISCOUNT_TO_TRANSACTION:
      return {
        ...state,
        all: [
          ...state.all.map((t) => ({
            ...t,
            appliedDiscountTransactions:
              t.id === action.discountedTransactionId
                ? [...(t.appliedDiscountTransactions ?? []), action.discount]
                : t.appliedDiscountTransactions,
          })),
          action.discount,
        ],
        tableData: [
          ...state.tableData.map((t) => ({
            ...t,
            appliedDiscountTransactions:
              t.id === action.discountedTransactionId
                ? [...(t.appliedDiscountTransactions ?? []), action.discount]
                : t.appliedDiscountTransactions,
          })),
          action.discount,
        ],
      };
    case types.REMOVE_APPLIED_DISCOUNT_TO_TRANSACTION:
      return {
        ...state,
        all: [
          ...state.all.map((t) => ({
            ...t,
            appliedDiscountTransactions:
              t.id === action.discountedTransactionId
                ? (t.appliedDiscountTransactions ?? []).filter((td) => td.id !== action.discount.reversedTransactionId)
                : t.appliedDiscountTransactions,
          })),
          action.discount,
        ],
        tableData: [
          ...state.tableData.map((t) => ({
            ...t,
            appliedDiscountTransactions:
              t.id === action.discountedTransactionId
                ? (t.appliedDiscountTransactions ?? []).filter((td) => td.id !== action.discount.reversedTransactionId)
                : t.appliedDiscountTransactions,
          })),
          action.discount,
        ],
      };
    case types.GET_APPLIED_DISCOUNT_TRANSACTIONS: {
      return {
        ...state,
        all: [
          ...state.all.map((t) => {
            if (t.id === action.transactionId) {
              return { ...t, appliedDiscountTransactions: action.appliedDiscounts };
            }

            return { ...t };
          }),
        ],
        tableData: [
          ...state.tableData.map((t) => {
            if (t.id === action.transactionId) {
              return { ...t, appliedDiscountTransactions: action.appliedDiscounts };
            }

            return { ...t };
          }),
        ],
      };
    }
    default:
      return state;
  }
};
