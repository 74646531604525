export const GET_AGENCY_ACCOUNT_CHILDREN_TABLE_DATA = 'GET_AGENCY_ACCOUNT_CHILDREN_TABLE_DATA';
export const UPDATE_AGENCY_ACCOUNT_CHILDREN_TABLE_FILTERS = 'UPDATE_AGENCY_ACCOUNT_CHILDREN_TABLE_FILTERS';
export const UPDATE_AGENCY_ACCOUNT_CHILD = 'UPDATE_AGENCY_ACCOUNT_CHILD';

interface IGetAgencyAccountChildrenTableDataAction {
  type: typeof GET_AGENCY_ACCOUNT_CHILDREN_TABLE_DATA;
  tableData: IGetPaginatedAgencyAccountChildrenForBusinessQueryData;
}

interface IUpdateAgencyAccountChildrenTableFiltersAction {
  type: typeof UPDATE_AGENCY_ACCOUNT_CHILDREN_TABLE_FILTERS;
  tableFilters: Partial<IAgencyAccountChildrenTableFilters>;
}

interface IUpdateAgencyAccountChildAction {
  type: typeof UPDATE_AGENCY_ACCOUNT_CHILD;
  agencyAccountChild: IAgencyAccountChild;
}

export type AgencyAccountChildrenActionTypes =
  | IGetAgencyAccountChildrenTableDataAction
  | IUpdateAgencyAccountChildrenTableFiltersAction
  | IUpdateAgencyAccountChildAction;
