import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  deleteTag?: () => void;
  tag: ITag;
}

const DeleteTagModal: React.FC<IProps> = ({ isOpen, isLoading, onClose, deleteTag, tag, ...props }) => {
  const dimissModal = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  return (
    // @ts-ignore
    <ConfirmationModal
      title={`Delete Tag ${tag.name}?`}
      show={isOpen}
      onHide={dimissModal}
      hideOnCallback={false}
      primaryChoice={'Confirm'}
      primaryCallback={deleteTag}
      primaryButtonProps={{
        variant: 'danger',
        loading: isLoading,
      }}
      secondaryCallback={dimissModal}
    >
      Are you sure you want to deactivate this tag? This will remove the tag from all of its current associations. This
      is an irreversible operation.
    </ConfirmationModal>
  );
};

export default DeleteTagModal;
