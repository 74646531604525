import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'shared/components/Checkbox';
import type { IGapWaiveEligibilityMap } from 'shared/hooks/useGapWaiveEligibleChecker';

interface ISessionAbsenceCellProps {
  dayOfWeek: number;
  sessionsForWeek: ISession[];
  gapWaiveEligibilityMap: IGapWaiveEligibilityMap;
  timezone: string;
  startOfWeek: string;
  checkboxValues: { [key: string]: boolean };
  handleCheckboxChange: (session: ISession | undefined, value: boolean) => void;
}

const SessionAbsenceCell: React.FC<ISessionAbsenceCellProps> = ({
  dayOfWeek,
  sessionsForWeek,
  gapWaiveEligibilityMap,
  timezone,
  startOfWeek,
  checkboxValues,
  handleCheckboxChange,
}) => {
  const { t } = useTranslation();
  const sessionForDay = useMemo(() => {
    return sessionsForWeek.find((s: ISession) => {
      const sessionDate = moment(s.date, 'YYYY-MM-DD');
      const zonedSessionDate = moment().tz(timezone).set({
        year: sessionDate.year(),
        month: sessionDate.month(),
        date: sessionDate.date(),
      });
      return zonedSessionDate.day() === dayOfWeek;
    });
  }, [timezone, sessionsForWeek]);

  const hasWaived = sessionForDay?.absence?.waiveGapFee ?? false;
  const isAbsent = !!sessionForDay?.absence;
  const isEligibleToWaive = gapWaiveEligibilityMap[sessionForDay?.id || '']?.isWaivable;
  const isNonSubsidizedSession = gapWaiveEligibilityMap[sessionForDay?.id || '']?.isNonSubsidized;

  const shouldCheckboxDisabled = hasWaived || !isAbsent || !isEligibleToWaive ? true : false;
  const sessionId = sessionForDay?.id || '';
  const checkboxValue = checkboxValues[sessionId];

  return (
    <div className="absence-cell">
      <Checkbox
        className={`gap-waiver-table--cb ${sessionForDay?.id || ''}`}
        disabled={shouldCheckboxDisabled}
        label={`${moment(startOfWeek).day(dayOfWeek).format('ddd, D')}`}
        value={checkboxValue}
        onChange={(v) => handleCheckboxChange(sessionForDay, v)}
      />
      {!isNonSubsidizedSession && <div className="badge badge-info">{sessionForDay?.absence?.type || ''}</div>}
      {hasWaived && <div className="badge badge-danger d-block">{t('gap-waiver.badges.gap-waived')}</div>}
      {isNonSubsidizedSession && <div className="badge badge-info">{t('gap-waiver.badges.eoc-session')}</div>}
    </div>
  );
};

export default SessionAbsenceCell;
