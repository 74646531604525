import * as types from './types';

export const setRequestedEmployees = (employees: IStaff[]) => ({
  type: types.SET_REQUESTED_EMPLOYEES_DATA,
  staff: employees,
});

export const resetEmployeesData = () => ({
  type: types.RESET_EMPLOYEES_DATA,
});

export const getStaffMedicalDataSuccess = (staff: IStaff) => ({ type: types.GET_STAFF_MEDICAL_DATA_SUCCESS, staff });
export const createStaffMedicalCondition = (condition: IMedicalCondition) => ({
  type: types.CREATE_MEDICAL_CONDITION_FOR_STAFF,
  condition,
});
export const updateStaffMedicalCondition = (condition: IMedicalCondition) => ({
  type: types.UPDATE_MEDICAL_CONDITION_FOR_STAFF,
  condition,
});
export const deleteStaffMedicalCondition = (condition: IMedicalCondition) => ({
  type: types.DELETE_MEDICAL_CONDITION_FOR_STAFF,
  condition,
});
export const createStaffAllergy = (allergy: IAllergy) => ({ type: types.CREATE_ALLERGY_FOR_STAFF, allergy });
export const updateStaffAllergy = (allergy: IAllergy) => ({ type: types.UPDATE_ALLERGY_FOR_STAFF, allergy });
export const deleteStaffAllergy = (allergy: IAllergy) => ({ type: types.DELETE_ALLERGY_FOR_STAFF, allergy });
