import { useGetExpectedSessions } from 'gql/session/queries';
import moment from 'moment';
import { getExpectedSessions } from 'pages/Attendance/duck/actions';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/components/Buttons';
import { showToast } from 'shared/components/Toast';
import { RootState } from 'store/reducers';
import errors from 'shared/constants/errorMessages';
import { useGetClassesForCenter } from 'gql/center/queries';
import BusinessAndCenterSelects from 'shared/components/BusinessAndCenterSelects';
import Card from 'shared/components/Card';
import { Row } from 'shared/components/Layout';
import { useHistory } from 'react-router-dom';
import { HorizontalDivider } from 'shared/components/Dividers';

const attendanceStatuses: AttendanceStatus[] = ['Expected', 'Unknown', 'Late', 'Absent', 'Checked In', 'Checked Out'];
const attendanceStatusesColors: Record<AttendanceStatus, string> = {
  Expected: 'text-color',
  Unknown: 'info',
  Late: 'danger',
  Absent: 'warning',
  'Checked In': 'success',
  'Checked Out': 'slate',
};
interface IEditSessionModalState {
  open: boolean;
  sessions: ISession[];
  child: IChild | null;
}

const AttendanceWidget: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const sessions = useSelector((state: RootState) => state.attendance.sessions) ?? [];
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();

  const [date, setDate] = React.useState(moment().tz(timezone).format());

  // TODO: Australia does not start/end on 1/6
  // day(Number): This method can be used to set the day of the week, with Sunday as 0 and Saturday as 6.
  // AU would be 1,0 or 1,7
  const startOfWeek = moment(date).tz(timezone).day(1).startOf('d').format();
  const endOfWeek = moment(date).tz(timezone).day(6).endOf('d').format();
  const julyCurrentYear = moment().tz(timezone).set('month', 6).startOf('month').startOf('day');
  const enrollmentYearStart = moment(date).tz(timezone).isBefore(julyCurrentYear)
    ? julyCurrentYear.clone().subtract(1, 'year')
    : julyCurrentYear;
  const enrollmentYearEnd = enrollmentYearStart.clone().add(1, 'year').subtract(1, 'minute');
  const { data } = useGetClassesForCenter({
    variables: { centerId: selectedCenterId },
  });

  useEffect(() => {
    setDate(moment().tz(timezone).format());
  }, [timezone]);

  // FETCH ATTENDANCE
  const {
    loading: sessionsLoading,
    networkStatus,
    refetch: refetchExpectedSessionsFn,
  } = useGetExpectedSessions({
    variables: {
      input: {
        centerId: selectedCenterId,
        startDate: startOfWeek,
        endDate: endOfWeek,
      },
      enrollmentYearStart: enrollmentYearStart.format('YYYY-MM-DD'),
      enrollmentYearEnd: enrollmentYearEnd.format('YYYY-MM-DD'),
    },
    onError: (err) => {
      dispatch(getExpectedSessions([]));
      showToast(errors.generic, 'error');
    },
  });

  const filterData = useCallback(
    (arr: ISession[]): ISession[] => {
      return arr.filter(
        (session) =>
          moment(session.date).isSameOrAfter(moment(startOfWeek), 'date') &&
          moment(session.date).isSameOrBefore(moment(endOfWeek), 'date') &&
          !session.void
      );
    },
    [startOfWeek, endOfWeek]
  );

  const currentDayAttendance = filterData(
    sessions.filter((s) => {
      const sessionDate = moment(s.date, 'YYYY-MM-DD');
      // use a moment object with the session date and current time in the requested timezone for comparison
      const zonedSessionDate = moment().tz(timezone).set({
        year: sessionDate.year(),
        month: sessionDate.month(),
        date: sessionDate.date(),
      });

      return zonedSessionDate.isSame(date, 'd');
    })
  );

  const currentDayAttendanceByStatus: Record<AttendanceStatus, ISession[]> = {
    Expected: currentDayAttendance,
    Unknown: currentDayAttendance.filter((a) => a.timeEntries.length === 0 && !a.absence),
    Late: currentDayAttendance.filter((a) => {
      if (!a.dropOffTime) {
        return false;
      }
      const dropOffTime = moment(a.dropOffTime, 'HH:mm').tz(timezone);
      const dropOffDateAndTime = moment(a.date)
        .tz(timezone)
        .set('hours', dropOffTime.hours())
        .set('minutes', dropOffTime.minutes());
      return a.timeEntries.length === 0 && !a.absence && moment().tz(timezone).isAfter(dropOffDateAndTime);
    }),
    Absent: currentDayAttendance.filter((a) => a.absence),
    'Checked In': currentDayAttendance.filter(
      (a) => a.timeEntries.length > 0 && a.timeEntries.some((te) => !te.timeOut)
    ),
    'Checked Out': currentDayAttendance.filter(
      (a) => a.timeEntries.length > 0 && a.timeEntries.every((te) => te.timeIn && te.timeOut)
    ),
  };

  return (
    <Card className="px-0" bodyClassName="p-0">
      <Row className="pl-1 pr-3 pt-4">
        <h5 className="px-6">{`Today's Attendance`}</h5>
        <BusinessAndCenterSelects
          persistSelection
          className="mr-4 ml-auto mb-auto flex-grow-0"
          showLabels={false}
          businessSelectClassName="mb-0 mr-4 flex-grow-0 min-width-200"
          centerSelectClassName="mb-0 flex-grow-0 min-width-200"
        />
      </Row>
      <HorizontalDivider />
      <div className="d-flex justify-content-between px-3 pb-2">
        {attendanceStatuses.map((status) => (
          <Button
            key={status}
            className={`mr-2 mb-2 text-gray border btn-flex-fill flex-grow-0 border-'pale-gray`}
            variant="outline-light"
            onClick={() => history.push('/attendance/attendance')}
          >
            <div className="d-flex flex-nowrap">
              <div style={{ whiteSpace: 'nowrap' }} className="sm mr-8">
                {status}
              </div>
              <h6 className={`mb-0 text-${attendanceStatusesColors[status]}`}>
                {currentDayAttendanceByStatus[status].length}
              </h6>
            </div>
          </Button>
        ))}
      </div>
    </Card>
  );
};

export default AttendanceWidget;
