import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import ButtonAsLink from 'shared/components/Buttons/ButtonAsLink';
import './employmentDetails.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  rolename: string;
  viewPermissions: () => void;
  haveAnyPermissions?: boolean | undefined;
}

const Role: React.FC<IProps> = ({ rolename, viewPermissions, haveAnyPermissions, ...props }) => {
  const { t } = useTranslation('accounts');
  return (
    <div className="d-flex align-items-center kt-employment-details-avatar-container">
      <div className="kt-employment-details-avatar d-flex justify-content-center align-items-center">
        <FontAwesomeIcon icon={faUser} color="#FFFFFF" size="lg" />
      </div>
      <div className="d-flex flex-column pl-4 text-truncate mt-n2">
        <span className="text-truncate">{rolename}</span>
        {!haveAnyPermissions && (
          <ButtonAsLink className="sm" onClick={viewPermissions}>
            {t('profile.employment.view-permissions')}
          </ButtonAsLink>
        )}
      </div>
    </div>
  );
};

export default Role;
