export const GET_TOTAL_CREATED_STAFF_SUCCESS = 'GET_TOTAL_CREATED_STAFF_SUCCESS';
export const GET_TOTAL_INVITED_STAFF_SUCCESS = 'GET_TOTAL_INVITED_STAFF_SUCCESS';
export const GET_TOTAL_REQUESTED_STAFF_SUCCESS = 'GET_TOTAL_REQUESTED_STAFF_SUCCESS';
export const INCREASE_TOTAL_CREATED_STAFF = 'INCREASE_TOTAL_CREATED_STAFF';
export const INCREASE_TOTAL_INVITED_STAFF = 'INCREASE_TOTAL_INVITED_STAFF';

interface IGetTotalCreatedStaffSuccessAction {
  type: typeof GET_TOTAL_CREATED_STAFF_SUCCESS;
  totalCreatedStaff: number;
  createdStaffData: IStaff[];
}

interface IGetTotalInvitedStaffSuccessAction {
  type: typeof GET_TOTAL_INVITED_STAFF_SUCCESS;
  totalInvitedStaff: number;
  invitedStaffData: IStaff[];
}

interface IGetTotalRequestedStaffSuccessAction {
  type: typeof GET_TOTAL_REQUESTED_STAFF_SUCCESS;
  totalRequestedStaff: number;
  requestedStaffData: IStaff[];
}

interface IIncreaseTotalCreatedStaffAction {
  type: typeof INCREASE_TOTAL_CREATED_STAFF;
}

interface IIncreaseTotalInvitedStaffAction {
  type: typeof INCREASE_TOTAL_INVITED_STAFF;
}

export type ProfilesActionTypes =
  | IGetTotalCreatedStaffSuccessAction
  | IGetTotalInvitedStaffSuccessAction
  | IGetTotalRequestedStaffSuccessAction
  | IIncreaseTotalCreatedStaffAction
  | IIncreaseTotalInvitedStaffAction;
