import React from 'react';
import { CardDeck } from 'react-bootstrap';
import { AbsenseCategoryFormatting } from 'shared/constants/enums/ccssEnums';
import NumberCard from '../NumberCard/NumberCard';
import { useTranslation } from 'react-i18next';

interface ITableProps {
  onSelected?: (filter: CcsAbsencesCategory | null) => void;
  activeFilter?: CcsAbsencesCategory | null;
  loading?: boolean;
  data?: ICcsAbsencesCategories;
}

const CcsAbsenceCards: React.FC<ITableProps> = ({ onSelected = () => {}, activeFilter, loading, data }) => {
  const toggle = (filter: CcsAbsencesCategory) => (activeFilter !== filter ? filter : null);
  const { t } = useTranslation('subsidies');

  const getProps = (value: CcsAbsencesCategory) => ({
    numberColor: AbsenseCategoryFormatting[value].colorName,
    onClick: () => onSelected(toggle(value)),
    active: activeFilter === value,
    loading: loading,
  });

  const cards = (
    <>
      <NumberCard
        title={t('absences.title.limit-reached')}
        subTitle={t('absences.subtitle.limit-reached')}
        number={data?.atRisk}
        {...getProps('AtRisk')}
      />
      <NumberCard
        title={t('absences.title.nearing-limit')}
        subTitle={t('absences.subtitle.nearing-limit')}
        number={data?.nearingLimit}
        {...getProps('NearingLimit')}
      />
      <NumberCard
        title={t('absences.title.in-the-clear')}
        subTitle={t('absences.subtitle.in-the-clear')}
        number={data?.inTheClear}
        {...getProps('InTheClear')}
      />
    </>
  );

  return <CardDeck>{cards}</CardDeck>;
};

export default CcsAbsenceCards;
