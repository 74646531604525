import React, { useCallback } from 'react';
import TextInput, { ITextInputProps } from './TextInput';
import KTTooltip from '../Tooltip/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';

const PasswordInput: React.FC<ITextInputProps> = ({ className, label, ...rest }) => {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const toggleIsPasswordVisible = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);

  return (
    <TextInput
      className={`position-relative input-icon-right ${className}`}
      {...rest}
      type={isPasswordVisible ? 'text' : 'password'}
      label={label}
      componentRight={
        <KTTooltip
          direction="left"
          additionalClass="d-none d-sm-block"
          text={isPasswordVisible ? 'Hide password input' : 'Show password input'}
        >
          <FontAwesomeIcon
            onClick={toggleIsPasswordVisible}
            className={`input-icon-password ${label ? 'input-icon-password-with-label' : ''}`}
            icon={isPasswordVisible ? faEyeSlash : faEye}
          />
        </KTTooltip>
      }
    ></TextInput>
  );
};

export default PasswordInput;
