import React, { useState, useCallback } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { somePropsEmpty } from 'shared/util/object';
import spaceIcons from 'shared/constants/SpaceIcons';
import colors from '_colors.module.scss';
import SpaceInputs from '../../../../components/SpaceInputs';
import { CirclePlusButton } from 'shared/components/Buttons';
import errorMessage from 'shared/constants/errorMessages';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  centerId: string;
  isOpen: boolean;
  onClose: () => void;
  addSpace: (spaces: INewSpace[]) => Promise<void>;
}

const AddSpace: React.FC<IProps> = ({ isOpen, onClose, centerId, addSpace }) => {
  const newSpaceObject: INewSpace = {
    centerId,
    name: '',
    color: colors.paleGrey,
    icon: Object.keys(spaceIcons)[0],
  };

  const [spaces, updateSpaces] = useState([{ ...newSpaceObject }]);

  const addAdditionalSpace = useCallback(() => {
    updateSpaces([...spaces, { ...newSpaceObject }]);
  }, [newSpaceObject, spaces]);

  const updateSpace = useCallback(
    (updatedSpace, spaceIndex) => {
      spaces[spaceIndex] = updatedSpace;
      updateSpaces([...spaces]);
    },
    [spaces]
  );

  const removeSpace = useCallback((index) => {
    updateSpaces((prev) => [...prev.filter((s, i) => i !== index)]);
  }, []);

  const resetForm = useCallback(() => {
    updateSpaces([{ ...newSpaceObject }]);
  }, [newSpaceObject]);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <SideModalDrawer
      title="New Space"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Add"
      primaryCallback={() => {
        addSpace(spaces);
        resetForm();
      }}
      secondaryCallback={resetForm}
      primaryButtonProps={{
        disabled: spaces.some((k) => somePropsEmpty(k)),
      }}
      footerHelperText={spaces.some((k) => somePropsEmpty(k)) ? errorMessage.formRequirements : ''}
    >
      <h6 className="text-info mb-4 text-uppercase">Spaces</h6>
      <p className="small">
        Space: Any physical location where a child and/or class may go within your {fieldLabels.center.toLowerCase()}.
      </p>
      <p className="small">This may include: classrooms, playgrounds, cafeteria, etc...</p>
      {spaces.map((space, index) => (
        <SpaceInputs
          key={index}
          space={space}
          autoFocus
          updateSpace={(updatedSpace: INewSpace) => {
            updateSpace(updatedSpace, index);
          }}
          removeSpace={
            index > 0
              ? () => {
                  removeSpace(index);
                }
              : undefined
          }
        />
      ))}
      <CirclePlusButton
        className="mt-4"
        onClick={addAdditionalSpace}
        disabled={somePropsEmpty(spaces[spaces.length - 1])}
        label="Add Another Space"
      />
    </SideModalDrawer>
  );
};

export default AddSpace;
