import React from 'react';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import Checkbox from 'shared/components/Checkbox';
import Button from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './applicationDeposit.scss';
import DateInput from 'shared/components/DateInput';

export interface IDepositInputInvalid {
  appliesDate?: boolean;
}

export interface IDeposit {
  value?: number | null;
  isCollected: boolean;
  appliesDate?: string;
}

interface IProps {
  value?: number | undefined | null;
  isCollected: boolean;
  onChange?: (value: number | null | undefined, isCollected: boolean, appliesDate?: string) => void;
  showEdit?: boolean;
  isDisabled?: boolean;
  onEditClick?: () => void;
  appliesDate?: string;
  showDatePicker?: boolean;
  inValid?: IDepositInputInvalid;
}

const DepositInput = ({
  value,
  isCollected,
  onChange,
  showEdit = false,
  isDisabled = false,
  onEditClick,
  appliesDate,
  showDatePicker = false,
  inValid,
}: IProps) => {
  const isCheckboxDisabled = isDisabled || !value;
  const { t } = useTranslation();

  const checkboxLabel = t('enrollment.lead-management.deposit.is-paid-label', {
    date:
      appliesDate && isCollected && !showDatePicker
        ? ` on ${moment(appliesDate).format(t('formatters.MM/DD/YYYY'))}`
        : null,
    interpolation: { escapeValue: false },
  });

  return (
    <div className="application-deposit">
      <div className="d-flex">
        <CurrencyInput2
          className="mb-2"
          label={t('enrollment.lead-management.deposit.label')}
          value={value}
          onChange={(v) => onChange && onChange(v, isCollected, appliesDate)}
          placeholder={t('enrollment.lead-management.deposit.placeholder')}
          helpTooltipText={t('enrollment.lead-management.deposit.tooltip')}
          helpTooltipTextDirection="right"
          disabled={isDisabled}
        />
        {showEdit && (
          <Button variant="outline-secondary" onClick={onEditClick} className="ml-2 my-1 edit-button">
            {t('enrollment.lead-management.deposit.edit-btn-text')}
          </Button>
        )}
      </div>
      <Checkbox
        className="pl-6"
        disabled={isCheckboxDisabled}
        label={checkboxLabel}
        value={isCollected}
        onChange={(v) => onChange && onChange(value, v, appliesDate)}
      />
      {showDatePicker && (
        <DateInput
          required
          helpTooltipText={t('enrollment.lead-management.deposit.paid-date-tooltip')}
          label={t('enrollment.lead-management.deposit.paid-date-label')}
          date={appliesDate}
          isValid={!Boolean(inValid && inValid.appliesDate)}
          onDateSelect={(input) => onChange && onChange(value, isCollected, input)}
          className="kt-date-input-no-max-width mt-4"
          dateOnly
        />
      )}
    </div>
  );
};

const getDepositInValidState = (
  isCollected: boolean | undefined | null,
  appliesDate: string | undefined | null
): IDepositInputInvalid | undefined => {
  if (isCollected && !appliesDate) {
    return {
      appliesDate: true,
    };
  }

  if (isCollected && !moment(appliesDate).isValid()) {
    return {
      appliesDate: true,
    };
  }
};

export default DepositInput;

export { getDepositInValidState };
