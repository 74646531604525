import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import VoidOrBouncedModal from 'shared/components/VoidOrBouncedModal';
import { useCancelFlaggedPayment, useBouncedCheck } from 'gql/transaction/mutations';
import { showToast } from 'shared/components/Toast';
import { updateTransaction } from 'pages/BillingTransactions/duck/action';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  payment: IPayment | null;
  onClose: () => void;
}

const VoidManualCheckModal: React.FC<IProps> = ({ isOpen, payment, onClose, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['billing']);

  const [cancelFlaggedPaymentFn, { loading: voidLoading }] = useCancelFlaggedPayment({
    onCompleted: (result) => {
      if (result.cancelPayment) {
        dispatch(updateTransaction(result.cancelPayment));
        showToast(t('billing:transactions.payments.void-check-modal.void-success-toast-message'), 'success');
        onClose();
      }
    },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const [bouncedCheckFn, { loading: bouncedLoading }] = useBouncedCheck({
    onCompleted: (result) => {
      console.log(result);
      if (result.bouncedCheck) {
        console.log('success');
        dispatch(updateTransaction(result.bouncedCheck));
        showToast(t('billing:transactions.payments.void-check-modal.bounced-success-toast-message'), 'success');
        onClose();
      }
    },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleVoid = useCallback(() => {
    if (payment) {
      cancelFlaggedPaymentFn({ variables: { paymentId: payment.id } });
    }
  }, [cancelFlaggedPaymentFn, payment]);

  const handleBounced = useCallback(() => {
    if (payment) {
      bouncedCheckFn({ variables: { paymentId: payment.id } });
    }
  }, [bouncedCheckFn, payment]);

  return (
    <VoidOrBouncedModal
      show={isOpen}
      onHide={onClose}
      title={t('billing:transactions.payments.void-check-modal.title')}
      hideOnCallback={false}
      voidCallback={handleVoid}
      bouncedCallback={handleBounced}
      CloseCallback={onClose}
      voidButtonProps={{ loading: voidLoading, disabled: bouncedLoading }}
      bouncedButtonProps={{ loading: bouncedLoading, disabled: voidLoading, variant: 'outline-danger' }}
    >
      <p>
        <b>{t('billing:transactions.payments.void-check-modal.question')}</b>
      </p>
      <h5>Void Payment</h5>
      <p>{t('billing:transactions.payments.void-check-modal.void-description')}</p>
      <h5>Mark As Bounced</h5>
      <p>{t('billing:transactions.payments.void-check-modal.bounced-description')}</p>
    </VoidOrBouncedModal>
  );
};

export default VoidManualCheckModal;
