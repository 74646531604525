import { useDeleteAgencyPayment } from 'gql/agency/mutations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { deleteAgencyPayment } from './duck/actions';

interface IProps {
  isOpen: boolean;
  agencyPayment: IAgencyPayment;
  onClose: () => void;
}

const DeleteAgencyPaymentModal: React.FC<IProps> = ({ isOpen, agencyPayment, onClose, ...props }) => {
  const { t } = useTranslation(['subsidies']);
  const dispatch = useDispatch();

  const [deleteAgencyPaymentFn, { loading }] = useDeleteAgencyPayment({
    onCompleted: (result) => {
      dispatch(deleteAgencyPayment(result.deleteAgencyPayment));
      showToast(t('subsidies:agency-billing.delete-payment-modal.success-toast-message'), 'success');
      onClose();
    },
    onError: (err) => {
      showToast(t('subsidies:agency-billing.delete-payment-modal.error-toast-message'), 'success');
    },
  });

  return (
    <ConfirmationModal
      title={t('subsidies:agency-billing.delete-payment-modal.title')}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Delete"
      primaryCallback={() =>
        deleteAgencyPaymentFn({
          variables: {
            id: agencyPayment.id,
          },
        })
      }
      primaryButtonProps={{ variant: 'danger', loading }}
    >
      <p>{t('subsidies:agency-billing.delete-payment-modal.body')}</p>
    </ConfirmationModal>
  );
};

export default DeleteAgencyPaymentModal;
