import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { IRoute } from 'shared/util/routes';
import { CreateItemizedBill } from './CreateItemizedBIll/CreateItemizedBill';

export const routes: { [key: string]: IRoute } = {
  createItemizedBill: {
    name: 'Create Itemized Bill',
    route: '/families/accounts/:accountId/billing/itemizedBills/create',
    component: CreateItemizedBill,
    doNotShowInSidebar: true,
    requiredAreaLevel: [
      {
        area: AreaType.Billing,
        permission: PermissionType.Payments, // payments also means itemized bills at the moment
        level: RoleLevelType.Create,
      },
    ],
  },
};
