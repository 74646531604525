export const serviceFeeGroupCenterFields = `
  center {
    id
    name
    entity {
      id
      name
    }
  }
`;

export const serviceFeeRuleFields = `
  id
  serviceFeeRuleGroupId
  condition
  value
  isNew
`;

export const serviceFeeRuleGroupFields = `
  id
  matchType
  serviceFeeRuleGroupParentId
  serviceFeeRuleGroups {
    id
    matchType
  }
  serviceFeeRules {
    ${serviceFeeRuleFields}
  }
  isNew
`;

export const serviceFeeFields = `
  id
  name
  serviceFeeType
  amount
  calculationType
  startDate
  endDate
  active
  serviceFeeRuleGroups {
    ${serviceFeeRuleGroupFields}
  }
  isNew
`;

export const serviceFeeGroupFields = `
  id
  name
  description
  centerCount
  serviceFeeGroupCenters {
    ${serviceFeeGroupCenterFields}
  }
  serviceFees {
    ${serviceFeeFields}
  }
`;
