import React from 'react';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import { useSearchCenters } from 'gql/center/queries';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import OccupancyTotalReportDashboardCard from './OccupancyTotalReportDashboardCard';

interface IProps {
  onDownloadCsv: (rows: string[][], filename: string) => void;
}

const ReportingDashboardView: React.FC<IProps> = ({ onDownloadCsv, ...props }) => {
  const reportDataToCsv = useReportDataToFile();
  const { loading: searchCentersLoading, data: searchCentersData } = useSearchCenters(
    {
      variables: {
        input: {
          filter: { all: [] },
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          from: 0,
          size: 10000,
        },
      },
    },
    `id name`
  );

  return (
    <div>
      <Row>
        <Column xl={12} className="mb-4">
          <OccupancyTotalReportDashboardCard
            loadingCenters={searchCentersLoading}
            centerOptions={searchCentersData?.searchCenters.data ?? []}
            onDownloadCsv={(data) =>
              onDownloadCsv(reportDataToCsv.occupancyTotals(data), ReportTypeEnum.OCCUPANCY_TOTALS)
            }
          />
        </Column>
      </Row>
    </div>
  );
};

export default ReportingDashboardView;
