import { IntegrationType, IntegrationMappingType } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import IntegrationMappingForm from '../MappingForm/IntegrationMappingForm';

enum AccountChildStatus {
  Prospective = 'Prospective',
  Active = 'Active',
  Inactive = 'Inactive',
}

interface IProps {
  businessId: string;
}

const CcrmChildStatusMapForm = ({ businessId }: IProps) => {
  const { t } = useTranslation(['integrations']);

  const sources: Record<AccountChildStatus, { sourceHint?: string; sourceName?: string }> = {
    Prospective: {
      sourceHint: t('integrations:mappings.child-status.prospective'),
    },
    Active: {
      sourceHint: t('integrations:mappings.child-status.active'),
    },
    Inactive: {
      sourceHint: t('integrations:mappings.child-status.inactive'),
    },
  };

  return (
    <IntegrationMappingForm
      businessId={businessId}
      integrationType={IntegrationType.ChildcareCrm}
      integrationMappingType={IntegrationMappingType.ChildStatus}
      sources={sources}
    />
  );
};

export default CcrmChildStatusMapForm;
