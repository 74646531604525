interface IOption {
  value: number | string;
  label: string;
}

export const getClosureTypeLabel = (closureType: string) => {
  switch (closureType) {
    case `UNPLANNED`:
      return 'Unplanned Closure';
    case `PLANNED`:
      return 'Planned Closure';
    case `PublicHoliday`:
      return 'Public Holiday Closure';
    case `PupilFree`:
      return 'Pupil Free / Planning Closure';
    case `Emergency`:
      return 'Emergency / Safety Closure';
  }

  return 'Unknown Closure';
};

export const getYearOptionsFromYear = (year: number) => {
  const startYear = year;
  const currentYearPlusOne = new Date().getFullYear() + 1;

  const options: IOption[] = [{ value: year, label: year.toString() }];

  if (year === 2023) return options;

  for (let yr = startYear + 1; yr <= currentYearPlusOne; yr++) {
    options.push({ value: yr, label: yr.toString() });
  }

  return options;
};

export const getYearOptions = () => {
  const startYear = 2023;
  const currentYearPlusOne = new Date().getFullYear() + 1;

  const options: IOption[] = [];

  for (let year = startYear; year <= currentYearPlusOne; year++) {
    options.push({ value: year, label: year.toString() });
  }

  return options;
};

export const getTermOptions = () => {
  const terms = 4;
  const options: IOption[] = [];
  for (let term = 1; term <= terms; term++) {
    options.push({ value: term.toString(), label: 'Term ' + term.toString() });
  }
  return options;
};

export const getDefaultTransitionStatementYear = () => {
  const firstYear = 2023;
  const date = new Date();
  const marchIndex = 2;

  let year = date.getFullYear();
  if (date.getMonth() <= marchIndex && year > firstYear) {
    year -= 1;
  }

  return year;
};

export const getTransitionStatementYears = () => {
  const firstYear = 2023;
  const currentYear = new Date().getFullYear();
  const minYear = Math.max(firstYear, currentYear - 1);

  return [minYear, minYear + 1];
};

const dateWithinYearRange = (dateString: string, bounds: number): boolean => {
  const currentYear = new Date().getFullYear();
  const dateYear = new Date(dateString).getFullYear();

  const minYear = currentYear - bounds;
  const maxYear = currentYear + bounds;

  return dateYear >= minYear && dateYear <= maxYear;
};

export const getLinkablePrograms = (programs: IProgram[]) => {
  return programs.filter((p) => dateWithinYearRange(p.endDate, 1));
};

export const kindyForAllSubsidySchemeId = '00000000-0000-0000-0000-000000000001';
export const startStrongSubsidySchemeId = '00000000-0000-0000-0000-000000000002';
export const freeKindySubsidySchemeId = '00000000-0000-0000-0000-000000000003';

export const freeKindyStartDate = '2024-01-01';

export const kindyForAllSubsidyTypes: SubsidyType[] = ['Base', 'Affordability'];
export const freeKindySubsidyTypes: SubsidyType[] = ['Base'];
export const startStrongSubsidyTypes: SubsidyType[] = ['FeeRelief'];
