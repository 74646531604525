import React, { useCallback } from 'react';
import momentTz from 'moment-timezone';
import { useSelector } from 'react-redux';
import ScheduleViewContext from '../../providers/scheduleViewProvider';
import { RootState } from 'store/reducers';

interface IProps {
  shift: IShift;
}

const ShiftCell: React.FC<IProps> = ({ shift, ...props }) => {
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const shiftTimezone = timezonesByCenter[shift.centerId] ?? momentTz.tz.guess();

  const timeframe = `${momentTz(shift.startTime).tz(shiftTimezone).format('h:mm A')} - ${momentTz(shift.endTime)
    .tz(shiftTimezone)
    .format('h:mm A')}`;
  const name = shift.classId ? `${shift.class?.name ?? ''}` : `${shift.location?.name ?? ''}`;

  const shiftLengthString = useCallback((amount: number): string => {
    switch (amount) {
      case 0:
        return 'No break';
      case 60:
        return '1 hour break';
      default:
        return `${amount} minute break`;
    }
  }, []);

  return (
    <ScheduleViewContext.Consumer>
      {(value) => (
        <div className="kt-schedules-employee-schedule-table-shift-cell" title={`${timeframe} (${name})`}>
          {value === 'BREAKS' ? shiftLengthString(shift.breakMinutes) : timeframe}
          <small className="d-block">{value === 'BREAKS' ? `${shift.paidBreak ? 'Paid' : 'Unpaid'}` : name}</small>
        </div>
      )}
    </ScheduleViewContext.Consumer>
  );
};

export default ShiftCell;
