import React from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import SpaceInputs from '../../../../components/SpaceInputs';
import errorMessage from 'shared/constants/errorMessages';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { isBlank } from 'shared/util/string';

interface IProps {
  space: ISpace;
  updateSpace: (space: ISpace) => void;
  isOpen: boolean;
  onClose: () => void;
  saveSpace: any;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const EditSpaceModal: React.FC<IProps> = ({ space, updateSpace, isOpen, onClose, saveSpace }) => {
  const isInvalid = isBlank(space.name) || !space.icon;

  return (
    <SideModalDrawer
      title="Edit Space"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Save"
      primaryCallback={() => {
        saveSpace && saveSpace(space);
      }}
      primaryButtonProps={{ disabled: isInvalid }}
      footerHelperText={isInvalid ? errorMessage.formRequirements : ''}
    >
      <h6 className="text-info mb-4 text-uppercase">Spaces (classrooms)</h6>
      <p className="small">
        Space: Any physical location where a child and/or class may go within your {fieldLabels.center.toLowerCase()}.
      </p>
      <p className="small">This may include: classrooms, playgrounds, cafeteria, etc...</p>
      <SpaceInputs space={space} updateSpace={updateSpace} />
    </SideModalDrawer>
  );
};

export default EditSpaceModal;
