import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Col, Row } from 'shared/components/Layout';
interface IProps {
  formData?: IStartStrongDeclarationForm;
  handleChange: (value: any, name: string) => void;
}

const EditDeclarationFormForm: React.FC<IProps> = ({ formData, handleChange }) => {
  const { t } = useTranslation(['subsidies']);
  const handleFileChange = (files: File[]) => {
    handleChange(files[files.length - 1], 'file');
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Label>{t('subsidies:program-child.create-modal.start-strong.declaration-form-document')}</Form.Label>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <FileSelectDropbox
            multiple={false}
            value={formData?.file ? [formData?.file] : []}
            onChange={handleFileChange}
            showFiles
          />
          {formData?.linkToFile && (
            <Row className="mt-2">
              <Col>
                <a href={formData?.linkToFile}>Current Declaration Form Document: {formData?.fileName}</a>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EditDeclarationFormForm;
