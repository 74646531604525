import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { EnrollmentProgramGroup, SearchSort, useGetEnrollmentProgramGroupsQuery } from 'generated/graphql';
import { IEnrollmentProgramGroupsTableProps } from '../tabs/ProgamGroupsTab/ProgramGroupsTable/ProgramGroupsTable';

type ProgramGroupAction = 'edit' | 'delete';

const useGetEnrollmentGroupsTable = () => {
  const { data: centers } = useGetActiveCentersWithLoading();
  const { businessId, centerFilterIds } = useSelector((state: RootState) => state.context);
  const [selectedProgramGroup, setSelectedProgramGroup] = useState<EnrollmentProgramGroup | undefined>();
  const [action, setAction] = useState<ProgramGroupAction | undefined>();
  const [tableState, tableFn] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState<boolean | undefined>(undefined);

  const [sort, setSort] = useState<ISortDto[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSort([{ field, direction }]);
  }, []);

  const { data, loading, refetch } = useGetEnrollmentProgramGroupsQuery({
    variables: {
      input: {
        businessId: businessId ?? '',
        centerIds: centerFilterIds.length > 0 ? centerFilterIds : undefined ?? undefined,
        pagination: {
          pageNumber: tableState.activePage,
          pageSize: tableState.pageSize,
        },
        searchText: searchTerm !== '' ? searchTerm : undefined,
        sort: sort as SearchSort[],
        activeOnly: activeFilter,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !Boolean(businessId) || centers?.length === 0,
  });

  const handleSearchDebounced = useCallback((value: string) => {
    setSearchTerm(value);
    tableFn.changePage(1, tableState.pageSize);
  }, []);

  const tableProps: IEnrollmentProgramGroupsTableProps = {
    centers: centers ?? [],
    totalResults: data?.getEnrollmentProgramGroups?.totalRecords ?? 0,
    tableFunctions: tableFn,
    tableState: tableState,
    businessId: businessId ?? '',
    loading: loading,
    centerFilterIds: centerFilterIds,
    data: (data?.getEnrollmentProgramGroups.data ?? []) as EnrollmentProgramGroup[],
    setSearchTerm: handleSearchDebounced,
    searchTerm: searchTerm,
    onSort: handleSort,
    handleEdit: (group) => {
      setSelectedProgramGroup(group);
      setAction('edit');
    },
    handleDelete: (group) => {
      setSelectedProgramGroup(group);
      setAction('delete');
    },
    setIsActiveFilter: setActiveFilter,
  };

  return {
    tableProps,
    refetch,
    selectedProgramGroup,
    setSelectedProgramGroup,
    action,
  };
};

export default useGetEnrollmentGroupsTable;
