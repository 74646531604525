import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { faDollarSign, faTimes, faUndo } from '@fortawesome/pro-light-svg-icons';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from 'store/reducers';
import DataTable from 'shared/components/DataTable';
import { TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { orderBy, sortBy } from 'lodash';
import DateInput from 'shared/components/DateInput';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Select from 'shared/components/Select';
import {
  convertTimeRangeObjectToString,
  convertTimeRangeStringToObject,
  timeRangeOptions,
} from 'shared/util/timeUtils';
import colors from '_colors.module.scss';
import useFormatDate from 'shared/hooks/useFormatDate';
import moment from 'moment';
import { PaymentStatusTag } from 'shared/components/Tag';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { IconButtonCircle } from 'shared/components/Buttons';
import { isRegion } from 'shared/util/region';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { setCurrentBusinessFilters, setCurrentCenterFilters } from 'store/context/actions';
import TransactionExpandedRow from 'pages/BillingTransactions/components/TransactionExpandedRow';
import PaymentStatusFilterCard from 'pages/BillingTransactions/components/PaymentStatusFilterCard';
import BillingBalanceCard from 'pages/BillingTransactions/components/BillingBalanceCard';
import ReverseTransactionModal from 'pages/BillingTransactions/components/ReverseTransactionModal';
import { useGetTransactionTypes } from 'gql/transaction/queries';
import ApplyDiscountToTransactionModal from 'pages/BillingTransactions/components/ApplyDiscountToTransactionModal';
import RemoveDiscountFromTransactionModal from 'pages/BillingTransactions/components/RemoveDiscountFromTransactionModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PeriodBasedAccountBalanceTile } from '../components/PeriodBasedAccountBalances';
import Currency from 'shared/components/Currency';

const paymentStatusFilters: PaymentStatus[] = ['UNSUBMITTED', 'PENDING', 'FAILED', 'COMPLETED', 'FLAGGED'];

const paymentStatusDropdownFilters_US: ITableFilterOption[] = [
  { label: 'Unsubmitted', value: 'UNSUBMITTED' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Deposited', value: 'COMPLETED' },
  { label: 'Flagged', value: 'FLAGGED' },
  { label: 'Returned', value: 'RETURNED' },
  { label: 'Refunded', value: 'REFUNDED' },
];

const paymentStatusDropdownFilters_AU: ITableFilterOption[] = [
  { label: 'Unsubmitted', value: 'UNSUBMITTED' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Flagged', value: 'FLAGGED' },
  { label: 'Cancelled', value: 'CANCELLED' },
];

const paymentStatusDropdownFilters = isRegion('AU') ? paymentStatusDropdownFilters_AU : paymentStatusDropdownFilters_US;

interface IProps {
  data: ITransactionsWithBalance[];
  loading: boolean;
  dateRange: ITimeRange;
  setDateRange: (tr: ITimeRange) => void;
  isAccountView?: boolean;
  isPaymentTransactionsOnly?: boolean;
  approvePaymentLoading?: boolean;
  onApproveFlaggedPayment?: (payment: IPayment) => void;
  onRejectFlaggedPayment?: (payment: IPayment, voidManualCheckPayment: boolean) => void;
  noPadding: boolean;
  accountId?: string;
}

type TransactionCategory = 'manual' | 'automated' | 'attendance';
const automatedTransactionTypeNames = ['Automated CC', 'Automated ACH/DD'];
const attendanceTransactionTypeNames = ['Session Fee', 'Early Fee', 'Late Fee', 'Discount', 'Subsidy'];

interface IModalStateShape {
  open: boolean;
  transaction: ITransaction | null;
}
interface IRemoveDiscountModalStateShape extends IModalStateShape {
  discountTransaction: ITransaction | null;
}

export const getTransactionCategory: (t: ITransaction) => TransactionCategory = (t) => {
  if (!t.transactionType.centerId && !t.transactionType.businessId) {
    if (automatedTransactionTypeNames.includes(t.transactionType.name)) {
      return 'automated';
    } else {
      return attendanceTransactionTypeNames.includes(t.transactionType.name) ? 'attendance' : 'manual';
    }
  } else {
    return 'manual';
  }
};

const isTransactionFlagged: (t: ITransaction) => boolean = (t) =>
  Boolean(t.payment?.flags.length && t.payment.approvedAt === null && t.payment.rejectedAt === null);

const AccountPaymentsTable: React.FC<IProps> = ({
  data,
  loading,
  dateRange,
  setDateRange,
  isAccountView = false,
  isPaymentTransactionsOnly = false,
  approvePaymentLoading = false,
  onApproveFlaggedPayment,
  onRejectFlaggedPayment,
  noPadding = false,
  accountId,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const defaultStatus = queryString.parse(location.search).status;
  const user = useSelector((state: RootState) => state.user);
  if (accountId) {
    isAccountView = true;
  }
  const account: IAccount | undefined = useSelector((state: RootState) => state.accounts.byId)[accountId ?? ''];

  const { businessFilterIds, centerFilterIds } = useSelector((state: RootState) => state.context);
  const [applyDiscountToTransactionModal, setApplyDiscountToTransactionModal] = useState<IModalStateShape>({
    open: false,
    transaction: null,
  });
  const [removeDiscountModal, setRemoveDiscountModal] = useState<IRemoveDiscountModalStateShape>({
    open: false,
    transaction: null,
    discountTransaction: null,
  });

  const { data: businessesData } = useGetAllowedEntities();
  const { data: centers } = useGetActiveCentersWithLoading();
  const { k2TransactionChildName, k2RunningBalance } = useFlags();
  const centerIds = centers?.map((c) => c.id) ?? null;

  const { data: getTransactionTypesData } = useGetTransactionTypes({
    variables: {
      businessId: user?.entityId ?? '',
      centerIds: centerIds,
      manualTransactionTypesOnly: false,
    },
    skip: !user?.entityId,
  });

  const formatDate = useFormatDate();
  const businesses = sortBy(businessesData?.getAllowedEntities ?? [], 'name');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'date',
    direction: 'desc',
  });
  const [selectedPaymentStatuses, setSelectedPaymentStatuses] = useState<PaymentStatus[]>(
    defaultStatus ? [defaultStatus.toUpperCase()] : []
  );
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState<ITableFilterOption[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<ITableFilterOption[]>([]);
  const [transactionInReverseModal, setTransactionInReverseModal] = useState<ITransaction | null>(null);

  const openingBalance = useSelector((state: RootState) => state.billing.transactions.openingBalance ?? 0);
  let runningBalance = openingBalance;

  const transactionCategories: ITableFilterOption[] = [
    { label: 'Manual', value: 'manual' },
    { label: 'Automated', value: 'automated' },
  ];

  const filteredTransactions = orderBy(
    data
      .filter((d: ITransactionsWithBalance) => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        // convert to a positive value since we'll build a negative string
        const amount = (d.amount > 0 ? d.amount : d.amount * -1).toFixed(2);
        const amountAsString = `${d.amount < 0 ? '-' : ''}$${amount.toString()}`;

        let isPartOfActiveFilter = false;
        for (let i = 0; i < selectedPaymentStatuses.length; i++) {
          if (selectedPaymentStatuses[i] === 'FLAGGED' && isTransactionFlagged(d)) {
            isPartOfActiveFilter = true;
            break;
          } else if (!isTransactionFlagged(d) && d.payment?.status === selectedPaymentStatuses[i]) {
            isPartOfActiveFilter = true;
            break;
          }
        }
        return (
          (!businessFilterIds.length || businessFilterIds.includes(d.account.center?.entityId ?? '')) &&
          (!centerFilterIds.length || centerFilterIds.includes(d.account.center?.id ?? '')) &&
          (!selectedCategories.length || selectedCategories.map((c) => c.value).includes(d.transactionTypeId)) &&
          (!selectedPaymentTypes.length ||
            selectedPaymentTypes.map((c) => c.value).includes(getTransactionCategory(d))) &&
          (!selectedPaymentStatuses.length || (d.payment?.status && isPartOfActiveFilter)) &&
          moment(d.date).isSameOrAfter(dateRange.start, 'date') &&
          moment(d.date).isSameOrBefore(dateRange.end, 'date') &&
          (!searchTerm.length ||
            d.transactionNumber?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
            d.description?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
            d.account.name?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
            d.transactionType.name?.toLocaleLowerCase()?.includes(lowercasedSearchTerm) ||
            d.amount.toFixed(2).toString().includes(lowercasedSearchTerm) ||
            amountAsString.includes(lowercasedSearchTerm))
        );
      })
      .sort((a, b) => {
        if (sortField.field === 'transactionNumber') return a.transactionNumber > b.transactionNumber ? 1 : -1;
        else return a.date > b.date ? 1 : -1 && a.transactionNumber > b.transactionNumber ? 1 : -1;
      })
      .map((item) => {
        runningBalance += item.amount;
        return { ...item, balanceSnapshot: runningBalance };
      }),
    sortField.field === 'date' ? ['date', 'transactionNumber'] : sortField.field,
    sortField.field === 'date' ? [sortField.direction, sortField.direction] : sortField.direction
  );

  const instanceOfITransactionsWithBalanceArray = (object: any): object is ITransactionsWithBalance[] => true;
  const closingBalance =
    filteredTransactions.length > 0 && instanceOfITransactionsWithBalanceArray(filteredTransactions)
      ? filteredTransactions
          .map((item) => item.amount)
          .reduce((prev, current) => (prev ?? 0) + (current ?? 0), openingBalance)
      : openingBalance;

  const transactionsGroupedByPaymentStatus = Object.fromEntries(
    paymentStatusFilters.map((status) => {
      const filtered =
        status === 'FLAGGED'
          ? data.filter((t) => isTransactionFlagged(t))
          : data.filter((t) => !isTransactionFlagged(t) && t.payment?.status === status);

      return [
        status,
        filtered.filter(
          (d) =>
            (!businessFilterIds.length || businessFilterIds.includes(d.account.center?.entityId ?? '')) &&
            (!centerFilterIds.length || centerFilterIds.includes(d.account.center?.id ?? '')) &&
            moment(d.date).isSameOrAfter(dateRange.start, 'date') &&
            moment(d.date).isSameOrBefore(dateRange.end, 'date')
        ),
      ];
    })
  );

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const handleStatusDropdownChange = (tableOptions: ITableFilterOption[]) => {
    const newStatus = tableOptions.map((x) => x.value) as PaymentStatus[];
    setSelectedPaymentStatuses(newStatus);
  };

  const clearAppliedFilters = useCallback(() => {
    setSelectedPaymentStatuses([]);
    setSelectedPaymentTypes([]);
    dispatch(setCurrentCenterFilters([]));
    dispatch(setCurrentBusinessFilters([]));
    setSelectedCategories([]);
    setDateRange({ start: moment().startOf('month').format(), end: moment().endOf('month').format() });
  }, [dispatch, setDateRange]);

  const getTransactionMethodDisplay = (transactionType: string) => {
    if (transactionType === 'Automated CC' || transactionType === 'Manual CC') {
      return 'Credit Card';
    }
    if (transactionType === 'Automated ACH/DD' || transactionType === 'Manual ACH/DD') {
      return isRegion('AU') ? 'Direct Debit' : 'ACH';
    } else return transactionType;
  };

  const getTransactionTypeDisplay = (transactionType: string) => {
    if (transactionType === 'Automated CC' || transactionType === 'Automated ACH/DD') {
      return isPaymentTransactionsOnly ? 'Automated' : 'Automated Payment';
    }
    if (transactionType === 'Manual CC' || transactionType === 'Manual ACH/DD') {
      return isPaymentTransactionsOnly ? 'Manual' : 'Manual Payment';
    } else return transactionType;
  };

  const renderStatus = useCallback((status: PaymentStatus, transaction: ITransaction): JSX.Element => {
    const isFlagged = Boolean(
      transaction.payment?.flags.length &&
        transaction.payment.approvedAt === null &&
        transaction.payment.rejectedAt === null
    );

    // markup taken from <PaymentStatusTag /> since we needed the custom icon
    if (isFlagged) {
      return (
        <div className="d-flex">
          <div className="tag px-4 bg-orange text-white">
            <div className="mr-2 d-flex">
              <FontAwesomeIcon icon={faFlag} size="sm" />
            </div>
            Flagged
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex">
        <PaymentStatusTag status={status} className="flex-grow-0" />
      </div>
    );
  }, []);

  let filterCards = paymentStatusFilters.filter((status) => status !== 'CANCELLED');

  if (isRegion('AU')) {
    filterCards = filterCards.filter((status) => status !== 'COMPLETED');
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div>
      {!isPaymentTransactionsOnly && isRegion('US') && account ? (
        <PeriodBasedAccountBalanceTile accountId={account.id} className="mb-4"></PeriodBasedAccountBalanceTile>
      ) : (
        k2RunningBalance &&
        !isPaymentTransactionsOnly && (
          <div className="row mb-6">
            <div className="k2-billing-transactions-payment-filters-grid pt-2">
              <BillingBalanceCard balanceType={'Opening'} balance={openingBalance} date={formatDate(dateRange.start)} />
              <BillingBalanceCard balanceType={'Closing'} balance={closingBalance} date={formatDate(dateRange.end)} />
            </div>
          </div>
        )
      )}
      <DataTable
        keyField="id"
        noPadding={noPadding}
        data={filteredTransactions}
        dataSize={filteredTransactions.length}
        showPagination={false}
        showLoadingOverlay={loading}
        showSelect={false}
        onSort={(field, direction) => setSortField({ field, direction: direction === 'ASCENDING' ? 'asc' : 'desc' })}
        expandRow={(transaction) => (
          <TransactionExpandedRow
            onAddDiscountToTransaction={(transaction) =>
              setApplyDiscountToTransactionModal({ open: true, transaction })
            }
            onRemoveDiscountFromTransaction={(transaction, discountTransaction) =>
              setRemoveDiscountModal({ open: true, transaction, discountTransaction })
            }
            handleReverse={setTransactionInReverseModal}
            transaction={transaction}
            isPaymentTransactionsOnly={isPaymentTransactionsOnly}
            onApproveFlaggedPayment={onApproveFlaggedPayment}
            onRejectFlaggedPayment={onRejectFlaggedPayment}
            approvePaymentLoading={approvePaymentLoading}
          />
        )}
        rowClasses={(row: ITransaction) => {
          // only apply the faded styling to payment transactions on the payments page
          if (row.payment?.status === 'CANCELLED' && isPaymentTransactionsOnly) {
            return 'k2-billing-transaction-cancelled-payment';
          }

          return '';
        }}
        columns={[
          isPaymentTransactionsOnly && {
            dataField: 'payment.status',
            text: 'Status',
            sort: true,
            formatter: (status: PaymentStatus, row: ITransaction) => renderStatus(status, row),
          },
          {
            dataField: 'date',
            text: 'Date',
            sort: true,
            formatter: (date: string) => formatDate(date),
          },
          {
            dataField: isRegion('US') ? 'description' : 'transactionNumber',
            text: isRegion('US') ? 'Description' : 'ID',
            sort: true,
          },
          {
            dataField: 'transactionType.name',
            text: 'Type',
            formatter: (transactionType: string) => getTransactionTypeDisplay(transactionType),
            sort: true,
          },
          isPaymentTransactionsOnly && {
            dataField: 'transactionType',
            text: 'Method',
            sort: true,
            formatter: (transactionType: ITransactionType) => getTransactionMethodDisplay(transactionType.name),
          },
          {
            dataField: 'account.name',
            text: 'Account',
            sort: true,
          },
          k2TransactionChildName &&
            !isPaymentTransactionsOnly && {
              dataField: 'appliedToAccountChild',
              text: 'Child Name',
              sort: false,
              formatter: (accountChild: IAccountChild) =>
                !!accountChild ? `${accountChild.firstname} ${accountChild.lastname}` : '',
            },
          {
            dataField: isPaymentTransactionsOnly ? 'payment.total' : 'amount',
            text: 'Amount',
            sort: true,
            align: 'right',
            headerAlign: 'right',
            formatter: (total: number, row: ITransaction) => (
              <h6 className={classnames('mr-4')}>
                {isPaymentTransactionsOnly ? (
                  <Currency payment themed display="DirectedNumbers" direction="Positive" amount={total} />
                ) : (
                  <Currency payment={!!row.payment} amount={total} />
                )}
              </h6>
            ),
          },
          {
            dataField: 'reversedTransactionId',
            text: '',
            formatter: (id: string) => (
              <span className={classnames('fa-layers fa-fw mx-2', { invisible: !id })}>
                <FontAwesomeIcon icon={faUndo} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
            ),
          },
          isRegion('AU') &&
            k2RunningBalance &&
            !isPaymentTransactionsOnly && {
              dataField: 'balanceSnapshot',
              text: 'Balance',
              sort: false,
              align: 'right',
              headerAlign: 'right',
              formatter: (balance: number) => (
                <h6 className={classnames('mr-4')}>
                  <Currency amount={balance} />
                </h6>
              ),
            },
        ].filter((c) => c)}
        renderHeader={() => (
          <div className="align-items-center">
            <TableHeader className="flex-wrap">
              <div className="d-flex flex-wrap align-items-center">
                <TableSearch
                  placeholder={isPaymentTransactionsOnly ? 'Search Payments' : 'Search Transactions'}
                  onChange={setSearchTerm}
                  className={isMobile ? 'my-1 mr-4' : 'mr-4 flex-shrink-0 flex-grow-0'}
                />
                <Select
                  options={timeRangeOptions}
                  value={convertTimeRangeObjectToString(dateRange)}
                  onChange={(string) => setDateRange(convertTimeRangeStringToObject(string))}
                  className={isMobile ? 'my-1 mr-auto' : 'mr-4 flex-shrink-3 flex-grow-0 mb-0'}
                />
              </div>
              <div className={isMobile ? 'd-flex flex-nowrap my-1 mr-2' : 'd-flex align-items-center mx-auto'}>
                <DateInput
                  date={dateRange.start}
                  onDateSelect={(start) => setDateRange({ ...dateRange, start })}
                  className="mr-2 flex-shrink-3 flex-grow-0 mb-0"
                />
                <div className={isMobile ? 'mt-2 mr-2' : 'mr-2'}>to</div>
                <DateInput
                  date={dateRange.end}
                  onDateSelect={(end) => setDateRange({ ...dateRange, end })}
                  className="mr-2 flex-shrink-3 flex-grow-0 mb-0"
                />
              </div>
              <div className={isMobile ? 'd-flex align-items-center flex-wrap' : 'd-flex align-items-center'}>
                {!isPaymentTransactionsOnly ? (
                  <DropdownFilter
                    title="Type"
                    className="mr-4"
                    selectedFilters={selectedCategories}
                    options={orderBy(
                      (getTransactionTypesData?.getTransactionTypes ?? []).map((tt) => ({
                        label: tt.name,
                        value: tt.id,
                      })),
                      (tt) => tt.label,
                      'asc'
                    )}
                    onFilterSelect={setSelectedCategories}
                  />
                ) : (
                  <DropdownFilter
                    title="Type"
                    className="mr-4"
                    selectedFilters={selectedPaymentTypes}
                    options={transactionCategories}
                    onFilterSelect={(categoryTableFilterOptions) => {
                      setSelectedPaymentTypes(categoryTableFilterOptions);
                    }}
                  />
                )}
                {isPaymentTransactionsOnly && (
                  <DropdownFilter
                    title="Status"
                    className="mr-4 my-2"
                    selectedFilters={selectedPaymentStatuses}
                    options={paymentStatusDropdownFilters}
                    onFilterSelect={handleStatusDropdownChange}
                  />
                )}
                {centers && centers.length > 1 && !isAccountView && (
                  <div className={isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex align-items-center'}>
                    {user?.isInternal && (
                      <DropdownFilter
                        title="Business"
                        className="mr-4"
                        selectedFilters={businessFilterIds}
                        options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                        onFilterSelect={(businesses) =>
                          dispatch(setCurrentBusinessFilters(businesses.map((b) => b.value)))
                        }
                      />
                    )}
                    {
                      <DropdownFilter
                        title={fieldLabels.center}
                        className="mr-4"
                        selectedFilters={centerFilterIds}
                        options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
                        onFilterSelect={(centers) => dispatch(setCurrentCenterFilters(centers.map((c) => c.value)))}
                      />
                    }
                  </div>
                )}
                <IconButtonCircle
                  icon={faTimes}
                  onClick={clearAppliedFilters}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                />
              </div>
            </TableHeader>
            {isPaymentTransactionsOnly && (
              <div className="row">
                <div className="k2-billing-transactions-payment-filters-grid pt-2">
                  {filterCards.map((status, idx) => (
                    <div className="mb-2" key={`payment-filter-card-${status}-${idx}`}>
                      <PaymentStatusFilterCard
                        status={status}
                        count={transactionsGroupedByPaymentStatus[status].length}
                        total={transactionsGroupedByPaymentStatus[status].reduce((sum, t) => sum + t.amount, 0)}
                        // always filter out completed since there is no other way to remove since we dont have a card for it
                        onClick={() =>
                          selectedPaymentStatuses.includes(status)
                            ? setSelectedPaymentStatuses(
                                selectedPaymentStatuses.filter((s) => s !== status && s !== 'COMPLETED')
                              )
                            : setSelectedPaymentStatuses([
                                ...selectedPaymentStatuses.filter((s) => s !== 'COMPLETED'),
                                status,
                              ])
                        }
                        className="raised-hover h-100"
                        isSelected={selectedPaymentStatuses.includes(status)}
                        loading={loading}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      />
      {applyDiscountToTransactionModal.transaction && (
        <ApplyDiscountToTransactionModal
          isOpen={applyDiscountToTransactionModal.open}
          transaction={applyDiscountToTransactionModal.transaction}
          onClose={() => setApplyDiscountToTransactionModal({ open: false, transaction: null })}
        />
      )}
      {removeDiscountModal.transaction && removeDiscountModal.discountTransaction && (
        <RemoveDiscountFromTransactionModal
          isOpen={removeDiscountModal.open}
          transaction={removeDiscountModal.transaction}
          discountTransaction={removeDiscountModal.discountTransaction}
          onClose={() => setRemoveDiscountModal({ open: false, transaction: null, discountTransaction: null })}
        />
      )}
      {transactionInReverseModal && (
        <ReverseTransactionModal
          isOpen={Boolean(transactionInReverseModal)}
          onClose={() => setTransactionInReverseModal(null)}
          transaction={transactionInReverseModal}
        />
      )}
    </div>
  );
};

export default AccountPaymentsTable;
