import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {}

const SingleDayTimeCardTableHeader: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['timelog']);

  return (
    <thead>
      <tr>
        <th colSpan={3} />
        <th>{t('timelog:time-card.actual-column')}</th>
        <th colSpan={4} />
        <th>{t('timelog:time-card.adjusted-column')}</th>
        <th colSpan={3} />
      </tr>
      <tr>
        <th>{t('timelog:time-card.position-column')}</th>
        <th>{t('timelog:time-card.in-column')}</th>
        <th>{t('timelog:time-card.out-column')}</th>
        <th>{t('timelog:time-card.total-column')}</th>
        <th>{t('timelog:time-card.st-column')}</th>
        <th>{t('timelog:time-card.ot-column')}</th>
        <th>{t('timelog:time-card.in-column')}</th>
        <th>{t('timelog:time-card.out-column')}</th>
        <th>{t('timelog:time-card.total-column')}</th>
        <th>{t('timelog:time-card.st-column')}</th>
        <th>{t('timelog:time-card.ot-column')}</th>
        <th />
      </tr>
    </thead>
  );
};

export default SingleDayTimeCardTableHeader;
