import * as type from './types';
import cast from 'shared/util/cast';

export const emptyChild: IChild = {
  id: '',
  firstname: '',
  lastname: '',
  fullName: '',
  avatar: { url: '' },
  dob: '',
  tags: [],
  accounts: [],
  contacts: [],
  allergies: [],
  restrictions: [],
  medicalConditions: [],
  immunizations: [],
  emergencyContacts: [],
  multipleBirths: false,
  mealStatus: { mealPricing: undefined },
  restrictAccessToChildDocuments: false,
};

interface IChildrenStateShape {
  all: IChild[];
  byId: Record<string, IChild>;
}

const initialState: IChildrenStateShape = {
  all: [],
  byId: {},
};

const getChild = (byId: Record<string, IChild>, id: string | null): IChild | null => (id ? byId[id as string] : null);

export const childrenReducers = (
  state: IChildrenStateShape = initialState,
  action: type.ChildActionTypes
): IChildrenStateShape => {
  const byId = { ...state.byId };

  switch (action.type) {
    case type.GET_CHILDREN_SUCCESS:
      return {
        ...state,
        all: action.children,
        byId: Object.fromEntries(action.children.map((c: IChild) => [c.id, { ...emptyChild, ...c }])),
      };
    case type.ADD_CHILD_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.child],
        byId: { ...state.byId, [action.child.id]: action.child },
      };
    case type.GET_CHILD_BY_ID_SUCCESS:
    case type.UPDATE_CHILD_SUCCESS:
    case type.GET_CHILD_WELLNESS_DATA_SUCCESS:
    case type.GET_CHILD_IMMUNIZATION_DATA_SUCCESS:
      return {
        ...state,
        all: [
          ...state.all.filter((c) => c.id !== action.child.id),
          { ...emptyChild, ...state.byId[action.child.id], ...action.child },
        ],
        byId: { ...state.byId, [action.child.id]: { ...emptyChild, ...state.byId[action.child.id], ...action.child } },
      };
    case type.ADD_CONTACT_FOR_CHILD:
      byId[action.contact.childId] = {
        ...byId[action.contact.childId],
        contacts: [...(byId[action.contact.childId].contacts ?? []), action.contact],
      };
      return {
        ...state,
        all: Object.values(byId),
        byId: {
          ...byId,
        },
      };
    case type.REMOVE_CHILD_CONTACT_RELATIONSHIP:
      if (byId[action.childId]) {
        byId[action.childId] = {
          ...byId[action.childId],
          contacts: (byId[action.childId].contacts ?? []).filter((c) => c.id !== action.contactId),
        };
      }

      return {
        ...state,
        all: Object.values(byId),
        byId: {
          ...byId,
        },
      };
    case type.UPDATE_CONTACT_FOR_CHILD:
      const childToUpdate = state.byId[action.contact.childId];
      const updatedChild = childToUpdate
        ? {
            ...childToUpdate,
            contacts: childToUpdate.contacts.map((c) => (c.id !== action.contact.id ? c : { ...c, ...action.contact })),
          }
        : emptyChild;
      return {
        ...state,
        all: state.all.map((c) => (c.id !== action.contact.childId ? c : updatedChild)),
        byId: { ...state.byId, [action.contact.childId]: updatedChild },
      };

    case type.CREATE_MEDICAL_CONDITION_FOR_CHILD:
    case type.UPDATE_MEDICAL_CONDITION_FOR_CHILD:
      const childForCondition = getChild(byId, action.condition.childId ?? '');
      if (childForCondition) {
        byId[childForCondition.id] = {
          ...childForCondition,
          medicalConditions: [
            ...(childForCondition.medicalConditions ?? []).filter((c) => c.id !== action.condition.id),
            action.condition,
          ],
        };
      }
      return { ...state, byId, all: Object.values(byId) };

    case type.CREATE_ALLERGY_FOR_CHILD:
      const childForCreateAllergy = getChild(byId, action.allergy.childId);

      if (childForCreateAllergy) {
        byId[action.allergy.childId as string] = {
          ...childForCreateAllergy,
          allergies: [...(childForCreateAllergy.allergies ?? []), action.allergy],
        };
      }

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case type.UPDATE_ALLERGY_FOR_CHILD:
      const childForUpdateAllergy = getChild(byId, action.allergy.childId);

      if (childForUpdateAllergy) {
        byId[action.allergy.childId as string] = {
          ...childForUpdateAllergy,
          allergies: (childForUpdateAllergy.allergies ?? []).map((a) =>
            a.id === action.allergy.id ? { ...action.allergy, isRevised: true } : a
          ),
        };
      }

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case type.CREATE_CHILD_RESTRICTION:
      const childForCreateRestriction = getChild(byId, action.childId);

      if (childForCreateRestriction) {
        byId[action.childId as string] = {
          ...childForCreateRestriction,
          restrictions: [...(childForCreateRestriction.restrictions ?? []), action.restriction],
        };
      }

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };

    case type.UPDATE_CHILD_RESTRICTION:
      const childForUpdateRestriction = getChild(byId, action.childId);

      if (childForUpdateRestriction) {
        byId[action.childId as string] = {
          ...childForUpdateRestriction,
          restrictions: childForUpdateRestriction.restrictions.map((r) =>
            r.id === action.restriction.id ? action.restriction : r
          ),
        };
      }

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case type.CREATE_CHILD_IMMUNIZATION:
      const childForImmunization = getChild(byId, action.immunizations[0] ? action.immunizations[0].childId : '' ?? '');
      if (childForImmunization) {
        byId[childForImmunization.id] = {
          ...childForImmunization,
          immunizations: [
            ...(childForImmunization.immunizations ?? []).filter(
              (i) => !action.immunizations.map((im) => im.id).includes(i.id)
            ),
            ...action.immunizations,
          ],
        };
      }
      return { ...state, byId, all: Object.values(byId) };
    case type.UPDATE_IMMUNIZATION:
      const childForImmunizationUpdate = getChild(byId, action.immunization.childId ?? '');
      if (childForImmunizationUpdate) {
        byId[childForImmunizationUpdate.id] = {
          ...childForImmunizationUpdate,
          immunizations: [
            ...(childForImmunizationUpdate.immunizations ?? []).filter((i) => action.immunization.id !== i.id),
            action.immunization,
          ],
        };
      }
      return { ...state, byId, all: Object.values(byId) };
    case type.UPDATE_CHILD_DOCUMENTS:
      const childUpdated = getChild(byId, action.childId);
      const wellnessCondititionType = action.documents[0] && action.documents[0].type;
      const itemId = action.documents[0] && action.documents[0].wellnessItemId;
      const documentIds = action.documents.map((d) => d.id);
      const updateConditionDocuments = (condition: IMedicalCondition | IAllergy | IRestriction) =>
        condition.id === itemId
          ? {
              ...condition,
              documents: [...condition.documents.filter((d) => !documentIds.includes(d.id)), ...action.documents],
            }
          : condition;

      if (childUpdated && wellnessCondititionType) {
        if (wellnessCondititionType === 'Allergy') {
          childUpdated.allergies = cast<IAllergy[]>(childUpdated.allergies.map(updateConditionDocuments));
          childUpdated.medicalConditions = cast<IMedicalCondition[]>(
            childUpdated.medicalConditions.map(updateConditionDocuments)
          );
          childUpdated.restrictions = cast<IRestriction[]>(childUpdated.restrictions.map(updateConditionDocuments));
        } else if (wellnessCondititionType === 'Medical') {
          childUpdated.medicalConditions = cast<IMedicalCondition[]>(
            childUpdated.medicalConditions.map(updateConditionDocuments)
          );
          childUpdated.restrictions = cast<IRestriction[]>(childUpdated.restrictions.map(updateConditionDocuments));
        } else if (wellnessCondititionType === 'Restriction') {
          childUpdated.restrictions = cast<IRestriction[]>(childUpdated.restrictions.map(updateConditionDocuments));
        }
      }

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case type.DELETE_CHILD_DOCUMENTS:
      const childForDeleteDocuments = getChild(byId, action.childId);

      if (childForDeleteDocuments) {
        byId[action.childId] = {
          ...childForDeleteDocuments,
          allergies: childForDeleteDocuments.allergies.map((allergy) => ({
            ...allergy,
            documents: allergy.documents.filter((doc) => {
              let shouldBeReturned = true;

              for (let index = 0; index < action.documentIds.length; index++) {
                const idToDelete = action.documentIds[index];

                if (idToDelete === doc.id) {
                  shouldBeReturned = false;
                  break;
                }
              }

              return shouldBeReturned;
            }),
          })),
          restrictions: childForDeleteDocuments.restrictions.map((restriction) => ({
            ...restriction,
            documents: restriction.documents.filter((doc) => {
              let shouldFilter = true;

              for (let index = 0; index < action.documentIds.length; index++) {
                const idToDelete = action.documentIds[index];

                if (idToDelete === doc.id) {
                  shouldFilter = false;
                  break;
                }
              }

              return shouldFilter;
            }),
          })),
        };
      }

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case type.DELETE_CHILD_IMMUNIZATION:
      const childForDeleteImmunization = getChild(byId, action.immunization.childId);

      if (childForDeleteImmunization) {
        byId[action.immunization.childId] = {
          ...childForDeleteImmunization,
          immunizations: childForDeleteImmunization.immunizations.filter(
            (immunization) => immunization.id !== action.immunization.id
          ),
        };
      }

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case type.UPDATE_CONTACT_CHILD_RELATIONSHIP:
      const childForUpdateRelationship = getChild(byId, action.childId);

      if (childForUpdateRelationship) {
        byId[action.childId] = {
          ...childForUpdateRelationship,
          contacts: childForUpdateRelationship.contacts.map((contact) =>
            contact.id === action.contactId ? { ...contact, relationshipType: action.relationship } : contact
          ),
        };
      }

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    default:
      return state;
  }
};
