import React from 'react';
import classnames from 'classnames';
import Avatar from 'shared/components/Avatar';
import { getFullName, getInitials, stringToHueDegree } from 'shared/util/string';
import colors from '_colors.module.scss';
import cast from 'shared/util/cast';

interface IProps {
  children: IChild[] | IAccountChild[];
  selectedChildId?: string;
  onChildSelect: (childId: string) => void;
}

const ChildSelectList: React.FC<IProps> = ({ children, selectedChildId, onChildSelect, ...props }) => {
  return (
    <ul className="mb-0 pl-1">
      <li key="view-all" className="d-inline-block mr-4 cursor-pointer" onClick={() => onChildSelect('')}>
        <ChildAvatar
          image={''}
          selected={selectedChildId === ''}
          color={colors.secondary}
          initials="ALL"
          name="View All"
        />
      </li>
      {cast<IChild[]>(children).map((child: IChild, idx: number) => (
        <li key={child.id} className="d-inline-block mr-4 cursor-pointer" onClick={() => onChildSelect(child.id)}>
          <ChildAvatar
            image={child.avatar?.url ?? ''}
            selected={child.id === selectedChildId}
            color={`hsl(${stringToHueDegree(child.id)}, ${stringToHueDegree(child.id) < 50 ? '100%' : '40%'}, 40%`}
            initials={getInitials(child)}
            name={getFullName(child)}
          />
        </li>
      ))}
    </ul>
  );
};

const ChildAvatar = (props: { image: string; color: string; selected: boolean; initials: string; name: string }) => {
  return (
    <div>
      <div className="d-flex justify-content-center">
        <Avatar
          size="md"
          image={props.image}
          color={props.color}
          initials={props.initials}
          className={classnames({ 'selected-child': props.selected })}
        />
        <span className="clearfix"></span>
      </div>
      <div>
        <small className={classnames({ 'text-dark': props.selected })}>{props.name}</small>
      </div>
    </div>
  );
};

export default ChildSelectList;
