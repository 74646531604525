import colors from '_colors.module.scss';

const classColors = [
  colors.slate,
  colors.prominent,
  colors.purple,
  colors.pink,
  colors.skyBlue,
  colors.yellow,
  colors.olive,
  colors.seafoamGreen,
  colors.steelBlue,
  colors.orange,
  colors.teal,
  colors.salmon,
  colors.brown,
  colors.secondaryHover,
];

export default classColors;
