export const ccsEntitlementFields = `
  id
  accountId
  accountName
  centerId
  centerName
  childId
  childName
  child {
    id
    firstname
    nickname
    lastname
    avatar {
      url
    }
  }
  enrolmentId
  date
  absenceCount
  accsHourlyRateCapIncreasePercentage
  apportionedHoursPerFortnight
  percentage
  preschoolExemption
  totalHoursPerFortnight
  withholdingPercentage
  absencesAvailableNoEvidence
  paidAbsences
  unpaidAbsences
`;

export const ccsEntitlementPagedResultFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${ccsEntitlementFields}
    previousEntitlement {
      ${ccsEntitlementFields}
    }
  }
`;

export const ccsEntitlementForChildrenFields = `
  id
  childId
  child {
    id
    firstname
    nickname
    lastname
    fullName
    avatar {
      url
    }
  }
  enrolmentId
  date
  absenceCount
  accsHourlyRateCapIncreasePercentage
  apportionedHoursPerFortnight
  percentage
  preschoolExemption
  totalHoursPerFortnight
  withholdingPercentage
  paidAbsences
  unpaidAbsences
  absencesAvailableNoEvidence
`;

export const ccsEntitlementChangeHistoryFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    date
    percentage
    totalHoursPerFortnight
  }
`;
