import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { DateInputWithTimezone } from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import contractCycleTypeOptions from 'shared/constants/enums/contractCycleTypeOptions';
import { RootState } from 'store/reducers';
import WeeklyTimeSlotsInputs from './WeeklyTimeSlotsInputs';
import { Tab, Tabs } from 'react-bootstrap';
import cast from 'shared/util/cast';
import CustomTimeSlotsInputs from './CustomTimeSlotsInputs';
import reasonForContractEndingOptions from 'shared/constants/enums/reasonForContractEndingOptions';
import { isEmpty, isNull, orderBy, sortBy } from 'lodash';
import Alert from 'shared/components/Alert/Alert';
import { isBlank } from 'shared/util/string';
import CasualTimeSlotsInputs from './CasualTimeSlotsInputs';
import Checkbox from 'shared/components/Checkbox';
import { isRegion } from 'shared/util/region';
import { useGetAdjustmentsForBusiness } from 'gql/adjustment/queries';
import classnames from 'classnames';
import { PickupDropoffInputs } from './PickupDropoffInputs';
import weekDayOptions, { IDayOption } from 'shared/constants/enums/weekDayOptions';
import { isTimeslotOnWeekday } from '../helpers/TimeslotHelpers';
import RateInfoBanner from '../components/RateInfoBanner/RateInfo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ContractValidationError, ValidationResults } from '../helpers/ContractValidation';

interface IProps {
  contract: ICreateContractInput;
  updateContract: React.Dispatch<React.SetStateAction<ICreateContractInput>>;
  previousContract?: IContract | null;
  existingContracts: IContract[];
  isLoadingClasses: boolean;
  availableClasses: IClass[];
  selectedClass: IClass | undefined;
  selectedFee: IFee | undefined;
  availbleFees: IFee[];
  isContractStartDateFeeScheduleValid: boolean;
  getStandardFeeState: (arg: boolean) => void;
  contractValidationResults: ValidationResults;
}

const ContractInputs: React.FC<IProps> = ({
  contract,
  updateContract,
  previousContract,
  existingContracts,
  isLoadingClasses,
  availableClasses,
  availbleFees,
  getStandardFeeState,
  contractValidationResults,
  selectedFee,
}) => {
  const { k2FlatRateFees } = useFlags();
  const { t } = useTranslation();
  const [activeBiweeklyTab, setActiveBiWeeklyTab] = useState<string>('WEEK1');
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);

  // redux values
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const timezone = timezonesByCenter[contract.centerId] ?? moment.tz.guess();

  const classSelectOptions: IModelBackedMenuItem<IClass>[] = orderBy(
    availableClasses.map((c) => ({ value: c.id, label: c.name, model: c })),
    (option: IModelBackedMenuItem<IClass>) => option.label,
    'asc'
  );

  const feeSelectOptions: ISelectMenuItem[] = orderBy(
    availbleFees.map((f) => ({ value: f.id, label: f.name })),
    (option: ISelectMenuItem) => option.label,
    'asc'
  );

  const { data: getAdjustmentsData, loading: adjustmentsLoading } = useGetAdjustmentsForBusiness({
    variables: {
      input: {
        businessId: currentBusinessId ?? '',
        type: 'FEE',
        showArchived: false,
      },
    },
    skip: !currentBusinessId,
  });
  const adjustmentOptions = getAdjustmentsData?.getAdjustmentsForBusiness;
  const possibleFeeSchedulesForContract = contract.startDate
    ? selectedFee?.schedules.filter((s) => moment(s.startDate).tz(timezone).isSameOrBefore(contract.startDate, 'd'))
    : null;
  const feeScheduleForContract = possibleFeeSchedulesForContract?.length
    ? sortBy(possibleFeeSchedulesForContract, 'startDate').reverse()[0]
    : null;

  const invalidDateRange = contractValidationResults.hasValidationError(
    ContractValidationError.EndDateIsBeforeStartDate
  );
  const dropOffPickUpOutsideFeeSchedule =
    feeScheduleForContract &&
    contract.timeslots.some(
      (ts) =>
        (ts.dropOffTime &&
          moment(ts.dropOffTime, 'HH:mm').isBefore(moment(feeScheduleForContract?.startTime, 'HH:mm'))) ||
        (ts.pickUpTime && moment(ts.pickUpTime, 'HH:mm').isAfter(moment(feeScheduleForContract?.endTime, 'HH:mm')))
    );
  const overlappingContract =
    contract.startDate &&
    Boolean(
      existingContracts.find(
        (ec) =>
          (!ec.endDate || moment(ec.endDate).isAfter(contract.startDate)) &&
          (!contract.endDate || moment(ec.startDate).isBefore(contract.endDate ?? ''))
      )
    );
  const [isUsingStandardFee, setIsUsingStandardFee] = useState<boolean>(!isNull(contract.originalFeeId));

  const classValue = availableClasses.length > 0 ? contract.classId : null;
  const feeValue = availableClasses.length > 0 && !isBlank(contract.permanentFeeId) ? contract.permanentFeeId : null;
  const standardFeeValue =
    availableClasses.length > 0 && !isNull(contract.originalFeeId) ? contract.originalFeeId : null;
  const adjustmentValue =
    adjustmentOptions == null
      ? null
      : adjustmentOptions!.length > 0 && !isNull(contract.adjustmentId)
      ? contract.adjustmentId
      : null;
  const standardFeesClassName = classnames('border', { 'border-secondary': isUsingStandardFee });

  const isInactiveFee = useMemo(() => {
    const isValidDataInputs: boolean =
      !isEmpty(feeValue) && (!isEmpty(contract.startDate) || !isEmpty(contract.endDate));
    const fee: IFee | undefined = availbleFees.find((i) => i.id === feeValue);

    const currentFeeSchedule: IFeeSchedule | undefined = orderBy(
      fee?.schedules ?? [],
      (fs) => fs.startDate,
      'desc'
    ).find((fs) => moment(fs.startDate).isBefore(moment(contract.startDate).endOf('day')));

    const startDate: string | undefined = currentFeeSchedule?.startDate;
    const isValidStartDate = contract.startDate && moment(startDate).isAfter(moment(contract.startDate).endOf('day'));
    const isValidEndDate = contract.endDate && moment(startDate).isAfter(moment(contract.endDate).endOf('day'));

    return isValidDataInputs && (isValidStartDate || isValidEndDate);
  }, [feeValue, contract]);

  return (
    <div>
      <Row>
        <Col>
          <Select
            required
            label="Class"
            value={classValue}
            options={classSelectOptions}
            onChange={(option: IModelBackedMenuItem<IClass>) => {
              const _class = option.model;
              updateContract({
                ...contract,
                classId: _class.id,
                permanentFeeId: _class.defaultPermanentFeeId ?? '',
                casualFeeId: _class.defaultCasualFeeId ?? '',
                originalFeeId: null,
                adjustmentId: null,
              });
            }}
            isLoading={isLoadingClasses}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInputWithTimezone
            required
            label="Start Date"
            timezone={timezone}
            date={contract.startDate}
            onDateSelect={(startDate) => {
              updateContract({
                ...contract,
                startDate: startDate ? moment(startDate).tz(timezone).format('YYYY-MM-DD') : startDate,
              });
            }}
            isValid={!invalidDateRange && Boolean(contract.startDate)}
            isOutsideRange={(date) => {
              const timezonedDate = moment()
                .tz(timezone)
                .month(date.month())
                .date(date.date())
                .year(date.year())
                .startOf('day');

              return Boolean(previousContract?.endDate) && !timezonedDate.isAfter(previousContract?.endDate ?? '', 'd');
            }}
            initialVisibleMonth={() =>
              Boolean(previousContract?.endDate) ? moment(previousContract?.endDate ?? '') : moment()
            }
          />
        </Col>
        <Col>
          <DateInputWithTimezone
            required={contract.cycleType === 'CUSTOM'}
            label="End Date"
            timezone={timezone}
            date={contract.endDate}
            onDateSelect={(endDate) =>
              updateContract({
                ...contract,
                endDate: endDate ? moment(endDate).tz(timezone).format('YYYY-MM-DD') : endDate,
                timeslots: endDate ? contract.timeslots.filter((ts) => ts.date! < endDate) : contract.timeslots,
                reasonForEnding: endDate ? 'TEMPORARY_ENROLLMENT' : null,
              })
            }
            isOutsideRange={(date) =>
              Boolean(contract.startDate) &&
              moment(date).tz(timezone).isSameOrBefore(moment(contract.startDate).tz(timezone))
            }
            initialVisibleMonth={() => (Boolean(contract.startDate) ? moment(contract.startDate) : moment())}
          />
        </Col>
      </Row>
      {invalidDateRange && (
        <div className="text-danger small mb-2">{t('enrollment.contracts.create-modal.date-range-error')}</div>
      )}
      {overlappingContract && (
        <Alert variant="warning">{t('enrollment.contracts.create-modal.overlapping-contract-error')}</Alert>
      )}
      {contract.endDate && (
        <Row>
          <Col xs={7}>
            <Select
              label="Reason for Ending"
              value={contract.reasonForEnding}
              options={reasonForContractEndingOptions}
              onChange={(o) => updateContract({ ...contract, reasonForEnding: o.value })}
            />
          </Col>
        </Row>
      )}
      <hr className="mx-auto mt-2 mb-4 horizontal-divider" />
      <Row>
        <Col>
          <Select
            required
            label={isRegion('US') ? 'Billed Fee' : 'Fee'}
            value={feeValue}
            options={feeSelectOptions}
            onChange={(option: ISelectMenuItem) =>
              updateContract({
                ...contract,
                permanentFeeId: option.value,
              })
            }
            isLoading={isLoadingClasses}
            noOptionsMessage={() =>
              !contract.classId ? 'Class must be selected before a fee can be applied.' : 'Selected class has no fees.'
            }
          />
        </Col>
        <Col>
          <Select
            required
            label="Frequency"
            value={contract.cycleType}
            options={contractCycleTypeOptions}
            onChange={(o) =>
              updateContract({
                ...contract,
                cycleType: o.value,
                timeslots: o.value === 'CASUAL' ? [{ pickUpTime: undefined, dropOffTime: undefined }] : [],
              })
            }
          />
        </Col>
      </Row>
      {contractValidationResults.hasValidationError(ContractValidationError.FlatRateFeeDuringSubsidy) && (
        <p className="text-danger">{t('billing.invalid-flat-rate-subsidy')}</p>
      )}
      {isInactiveFee && <p className="text-danger small">{t('billing.inactive-flat-fee')}</p>}
      {contract.cycleType === 'WEEKLY' && (
        <WeeklyTimeSlotsInputs contract={contract} updateContract={updateContract} className="mb-4" />
      )}
      {contract.cycleType === 'BIWEEKLY' && (
        <Tabs
          defaultActiveKey="WEEK1"
          id="contract-weeks"
          onSelect={(eventKey) => eventKey && setActiveBiWeeklyTab(eventKey)}
          activeKey={activeBiweeklyTab}
        >
          {['WEEK1', 'WEEK2'].map((week, idx) => (
            <Tab eventKey={week} title={`Week ${week === 'WEEK1' ? '1' : '2'}`} key={idx}>
              <WeeklyTimeSlotsInputs
                contract={contract}
                updateContract={updateContract}
                week={cast<WeekType>(week)}
                className="mb-4"
              />
            </Tab>
          ))}
        </Tabs>
      )}
      {contract.cycleType === 'CUSTOM' && (
        <CustomTimeSlotsInputs
          contract={contract}
          updateContract={updateContract}
          timezone={timezone}
          className="mb-4"
        />
      )}
      {k2FlatRateFees &&
        contract.startDate &&
        contract.cycleType &&
        selectedFee &&
        selectedFee.feeType === 'FLAT_RATE' && (
          <Row className="mb-4">
            <Col>
              <RateInfoBanner
                contractTimeslots={contract.timeslots}
                contractCycleType={contract.cycleType}
                selectedFee={selectedFee}
                dateToUse={contract.startDate}
              />
            </Col>
          </Row>
        )}
      {contract.cycleType !== 'CASUAL' && (
        <PickupDropoffInputs
          weekDayOptions={weekDayOptions}
          timeslots={contract.timeslots.filter((ts) =>
            contract.cycleType === 'BIWEEKLY' ? ts.week === activeBiweeklyTab : true
          )}
          onTimeSlotUpdate={(time: string, key: 'pickUpTime' | 'dropOffTime', day: IDayOption) => {
            updateContract((prevContractState) => {
              return {
                ...contract,
                timeslots: prevContractState.timeslots.map((ts) =>
                  isTimeslotOnWeekday(day, ts) ? { ...ts, [key]: time } : ts
                ),
              };
            });
          }}
        />
      )}
      {contract.cycleType === 'CASUAL' && contract.timeslots[0] && (
        <CasualTimeSlotsInputs contract={contract} updateContract={updateContract} />
      )}
      {contractValidationResults.hasValidationError(ContractValidationError.HasNoTimeslots) && !!contract.cycleType && (
        <div className="small mb-4">{t('enrollment.contracts.create-modal.select-one-day-error')}</div>
      )}
      {dropOffPickUpOutsideFeeSchedule && (
        <Alert variant="warning">
          {t('enrollment.contracts.create-modal.drop-off-times-outside-fee-schedule-error')}
        </Alert>
      )}
      <hr className="mx-auto mt-2 mb-4 horizontal-divider" />
      {isRegion('US') && (
        <Row className="mb-4">
          <Col>
            <Col className={standardFeesClassName}>
              <Checkbox
                label="Include a Standard Fee"
                value={isUsingStandardFee}
                className="pt-2 pb-2"
                onChange={(checked) => {
                  setIsUsingStandardFee(checked);
                  getStandardFeeState(checked);
                  if (!checked) {
                    updateContract({
                      ...contract,
                      originalFeeId: null,
                      adjustmentId: null,
                    });
                  }
                }}
              />
              {isUsingStandardFee && (
                <Row>
                  <Col xs={6}>
                    <Select
                      required
                      label="Standard Fee"
                      value={standardFeeValue}
                      options={feeSelectOptions}
                      onChange={(option: any) =>
                        updateContract({
                          ...contract,
                          originalFeeId: option.value,
                        })
                      }
                      isLoading={isLoadingClasses}
                      noOptionsMessage={() =>
                        !contract.classId
                          ? 'Class must be selected before a fee can be applied.'
                          : 'Selected class has no fees.'
                      }
                    />
                  </Col>
                  <Col xs={6}>
                    <Select
                      required
                      label="Adjustment Reason"
                      value={adjustmentValue}
                      options={orderBy(
                        adjustmentOptions?.map((c) => ({ ...c, value: c.id, label: c.name })),
                        (opt) => opt.name,
                        'asc'
                      )}
                      onChange={(option: any) =>
                        updateContract({
                          ...contract,
                          adjustmentId: option.value,
                        })
                      }
                      isLoading={adjustmentsLoading}
                      noOptionsMessage={() =>
                        !contract.originalFeeId
                          ? 'Standard Fee must be selected before an Adjustment Reason can be applied.'
                          : 'No adjustments found.'
                      }
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Col>
        </Row>
      )}
      {contract.cycleType !== 'CASUAL' && (
        <Row>
          <Col xs={6}>
            <Select
              label="Fee for Casual drop off"
              value={!isBlank(contract.casualFeeId ?? '') ? contract.casualFeeId : null}
              options={feeSelectOptions}
              onChange={(option: any) =>
                updateContract({
                  ...contract,
                  casualFeeId: option.value,
                })
              }
              isLoading={isLoadingClasses}
              noOptionsMessage={() =>
                !contract.classId
                  ? 'Class must be selected before a fee can be applied.'
                  : 'Selected class has no fees.'
              }
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ContractInputs;
