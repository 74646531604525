import React, { useCallback, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { orderBy } from 'lodash';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import PageWrapper from 'shared/components/PageWrapper';
import { useTranslation } from 'react-i18next';
import SubsidyPaymentInputsCard from './SubsidyPaymentInputsCard';
import ChildPaymentsTable, {
  ChildAgencyPaymentTableColumnsType,
  IChildAgencyPaymentTableRowShape,
} from './ChildPaymentsTable';
import Button from 'shared/components/Buttons';
import colors from '_colors.module.scss';
import {
  useGetAgenciesForBusiness,
  useGetAgencyPayment,
  useGetChildrenForAgencyPaymentAtCenter,
} from 'gql/agency/queries';
import { useGetEntity } from 'gql/business/queries';
import ManageDifferenceModal from './ManageDifferenceModal';
import { useCreateAgencyPayment, useUpdateAgencyPayment } from 'gql/agency/mutations';
import { showToast } from 'shared/components/Toast';
import { createAgencyPayment, updateAgencyPayment } from '../AgencyBilling/duck/actions';
import SubmitAgencyPaymentConfirmationModal from './SubmitAgencyPaymentConfirmationModal';
import { useGetAdjustmentsForBusiness } from 'gql/adjustment/queries';

interface IRouteProps {}
interface IProps extends RouteComponentProps<{ id?: string }, any, IRouteProps> {}

export interface INewSubsidyPaymentFormShape {
  agencyId: string | null;
  centerId: string | null;
  start: string | null;
  end: string | null;
  amount: number | null;
  checkNumber: string | null;
}

export interface ISubsidyChildPaymentTableStateShape {
  term: string;
  activeSortColumn: ChildAgencyPaymentTableColumnsType;
  columnSorts: Record<ChildAgencyPaymentTableColumnsType, 'asc' | 'desc'>;
}

interface IManageDifferenceModalShape {
  open: boolean;
  selectedTableRow: IChildAgencyPaymentTableRowShape | null;
}

const SubsidyPayment: React.FC<IProps> = ({ ...props }) => {
  const agencyPaymentId = props.match.params.id;

  const { t } = useTranslation(['subsidies']);
  const history = useHistory();
  const dispatch = useDispatch();

  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const [formData, setFormData] = useState<INewSubsidyPaymentFormShape>({
    agencyId: null,
    centerId: null,
    start: null,
    end: null,
    amount: null,
    checkNumber: null,
  });
  const [tableState, setTableState] = useState<ISubsidyChildPaymentTableStateShape>({
    term: '',
    activeSortColumn: 'childName',
    columnSorts: {
      accountName: 'asc',
      childName: 'asc',
      differenceAmount: 'asc',
      expectedAmount: 'asc',
      paidAmount: 'asc',
      sessionCount: 'asc',
    },
  });
  const [manageDifferenceModal, setManageDifferenceModal] = useState<IManageDifferenceModalShape>({
    open: false,
    selectedTableRow: null,
  });
  const [tableData, setTableData] = useState<IChildAgencyPaymentTableRowShape[]>([]);
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
  const [allowEditing, setAllowEditing] = useState<boolean>(agencyPaymentId === undefined);

  const incompleteGraphqlRequestVariables: boolean =
    !formData.agencyId || !formData.centerId || !formData.start || !formData.end;
  const formIncomplete: boolean = incompleteGraphqlRequestVariables || !formData.amount;

  const { data: agenciesForBusinessData, loading: getAgenciesForBusinessLoading } = useGetAgenciesForBusiness({
    variables: {
      businessId: currentBusinessId ?? '',
    },
    skip: !currentBusinessId,
  });

  const { data: adjustmentsForBusinessData, loading: getAdjustmentsForBusinessLoading } = useGetAdjustmentsForBusiness({
    variables: {
      input: {
        businessId: currentBusinessId ?? '',
        type: 'SUBSIDY',
        showArchived: false,
      },
    },
    skip: !currentBusinessId,
  });

  const { data: getEntityData, loading: getEntityLoading } = useGetEntity(
    {
      variables: {
        id: currentBusinessId ?? '',
      },
      skip: !currentBusinessId,
    },
    `id centers { id name }`
  );

  // query to fetch data for a new agency subsidy payment
  const { loading: getChildrenForAgencyPaymentAtCenterLoading } = useGetChildrenForAgencyPaymentAtCenter({
    variables: {
      input: {
        businessId: currentBusinessId ?? '',
        agencyId: formData.agencyId ?? '',
        centerId: formData.centerId ?? '',
        startDate: formData.start ?? '',
        endDate: formData.end ?? '',
      },
    },
    skip: incompleteGraphqlRequestVariables || !currentBusinessId || Boolean(agencyPaymentId),
    onCompleted: (result) => {
      setTableData(
        result.getChildrenForAgencyPaymentAtCenter.map((i, index) => ({
          id: null,
          initialListIndex: index,
          accountId: i.accountId,
          accountChildId: i.accountChildId,
          accountChildEnrollmentId: i.accountChildEnrollmentId,
          accountName: i.accountName,
          childAgencyId: i.childAgencyId,
          childFirstName: i.childFirstName,
          childLastName: i.childLastName,
          contractedNumberOfSessions: i.contractedNumberOfSessions,
          expectedSubsidyAmount: i.expectedSubsidyAmount,
          amountPaidAmount: i.expectedSubsidyAmount, // by default the amount paid should be the same as the expected amount and changes can be made if necessary
          differenceAction: 'NONE', // default value when creating a new payment
          adjustmentId: null, // default value when creating a new payment
          differenceActionReason: '', // default value when creating a new payment
        }))
      );
    },
  });

  // execute the existing payment query only if we have an id
  const { loading: getAgencyPaymentLoading } = useGetAgencyPayment({
    variables: {
      id: agencyPaymentId ?? '',
    },
    skip: !agencyPaymentId,
    onCompleted: (result) => {
      setFormData({
        agencyId: result.getAgencyPayment.agencyId,
        centerId: result.getAgencyPayment.centerId,
        start: result.getAgencyPayment.startDate,
        end: result.getAgencyPayment.endDate,
        amount: result.getAgencyPayment.amountPaid,
        checkNumber: result.getAgencyPayment.checkNumber ?? '',
      });
      setTableData(
        result.getAgencyPayment.childAgencyPayments.map((i, index) => ({
          id: i.id,
          initialListIndex: index,
          accountId: i.accountId,
          accountChildId: '',
          accountChildEnrollmentId: i.accountChildEnrollmentId,
          accountName: i.accountName,
          childAgencyId: i.childSubsidyEnrollmentId,
          childFirstName: i.childFirstName,
          childLastName: i.childLastName,
          contractedNumberOfSessions: i.numberOfSessions,
          expectedSubsidyAmount: i.amountExpected,
          amountPaidAmount: i.amountPaid,
          differenceAction: i.differenceAction,
          adjustmentId: i.adjustmentId,
          differenceActionReason: i.differenceActionReason ?? '',
        }))
      );
      setAllowEditing(result.getAgencyPayment.submittedBy === null);
    },
  });

  const [createAgencyPaymentFn, { loading: createAgencyPaymentLoading }] = useCreateAgencyPayment({
    onCompleted: (result) => {
      dispatch(createAgencyPayment(result.createAgencyPayment));
      showToast(t('subsidies:agency-billing.new-subsidy-payment.success-toast-message'), 'success');
      history.push('/subsidies/billing');
    },
    onError: (err) => {
      showToast(t('subsidies:agency-billing.new-subsidy-payment.error-toast-message'), 'error');
    },
  });

  const [updateAgencyPaymentFn, { loading: updateAgencyPaymentLoading }] = useUpdateAgencyPayment({
    onCompleted: (result) => {
      dispatch(updateAgencyPayment(result.updateAgencyPayment));
      showToast(t('subsidies:agency-billing.new-subsidy-payment.success-update-toast-message'), 'success');
      history.push('/subsidies/billing');
    },
    onError: (err) => {
      showToast(t('subsidies:agency-billing.new-subsidy-payment.error-update-toast-message'), 'error');
    },
  });

  const getCenterOptionsForAgency = useCallback((): ICenter[] => {
    const centers = getEntityData?.getEntity.centers ?? [];
    const selectedAgency = (agenciesForBusinessData?.getAgenciesForBusiness ?? []).find(
      (agency) => agency.id === formData.agencyId
    );

    if (!selectedAgency) return [];

    const agencyAppliesToAllCenters = !selectedAgency.centerIds;

    return centers.filter(
      (center) => agencyAppliesToAllCenters || (selectedAgency.centerIds ?? []).includes(center.id)
    );
  }, [getEntityData, agenciesForBusinessData, formData.agencyId]);

  const getAdjustmentOptions = useCallback((): IAdjustment[] => {
    return adjustmentsForBusinessData?.getAdjustmentsForBusiness ?? [];
  }, [adjustmentsForBusinessData]);

  const handleSortTableColum = useCallback(
    (column: ChildAgencyPaymentTableColumnsType, direction: 'asc' | 'desc') => {
      setTableState((prev) => ({
        ...prev,
        activeSortColumn: column,
        columnSorts: {
          ...prev.columnSorts,
          [column]: direction,
        },
      }));
    },
    [setTableState]
  );

  const filterData = useCallback(
    (data: IChildAgencyPaymentTableRowShape[]): IChildAgencyPaymentTableRowShape[] => {
      const { activeSortColumn, columnSorts } = tableState;
      const term = tableState.term.toLowerCase();

      if (tableState.term) {
        data = data.filter(
          (datum) =>
            `${datum.childFirstName} ${datum.childLastName}`.toLowerCase().includes(term) ||
            datum.accountName.toLowerCase().includes(term) ||
            `${datum.childAgencyId ?? ''}`.toLowerCase().includes(term) ||
            `${datum.amountPaidAmount}`.includes(term) ||
            `${datum.contractedNumberOfSessions}`.includes(term) ||
            `${datum.expectedSubsidyAmount}`.includes(term)
        );
      }

      return orderBy(
        data,
        (datum) => {
          switch (activeSortColumn) {
            case 'accountName':
              return datum.accountName;
            case 'childName':
              return datum.childLastName;
            case 'expectedAmount':
              return datum.expectedSubsidyAmount;
            case 'sessionCount':
              return datum.contractedNumberOfSessions;
            case 'paidAmount':
              return datum.amountPaidAmount;
            case 'differenceAmount':
              return datum.expectedSubsidyAmount - datum.amountPaidAmount;
            default:
              return datum.childLastName;
          }
        },
        columnSorts[activeSortColumn]
      );
    },
    [tableState]
  );

  const handleSubmit = useCallback(
    (saveAsDraft: boolean) => {
      if (Boolean(agencyPaymentId)) {
        updateAgencyPaymentFn({
          variables: {
            input: {
              id: agencyPaymentId as string,
              isSubmitted: !saveAsDraft,
              updateChildAgencyPaymentMessages: tableData.map((row) => ({
                id: row.id as string,
                amountPaid: row.amountPaidAmount,
                differenceAction: row.differenceAction,
                adjustmentId: row.differenceAction === 'ADJUST' ? (row.adjustmentId as string) : null,
                differenceActionReason: row.differenceActionReason,
              })),
            },
          },
        });
      } else {
        createAgencyPaymentFn({
          variables: {
            input: {
              agencyId: formData.agencyId as string,
              centerId: formData.centerId as string,
              businessId: currentBusinessId as string,
              amountPaid: formData.amount as number,
              startDate: formData.start as string,
              endDate: formData.end as string,
              checkNumber: formData.checkNumber,
              isSubmitted: !saveAsDraft,
              createChildAgencyPaymentMessages: tableData.map((row) => ({
                accountId: row.accountId,
                accountChildId: row.accountChildId,
                agencyAccountChildEnrollmentId: row.accountChildEnrollmentId,
                numberOfSessions: row.contractedNumberOfSessions,
                amountExpected: row.expectedSubsidyAmount,
                amountPaid: row.amountPaidAmount,
                differenceAction: row.differenceAction,
                adjustmentId: row.differenceAction === 'ADJUST' ? (row.adjustmentId as string) : null,
                differenceActionReason: row.differenceActionReason,
              })),
            },
          },
        });
      }
    },
    [createAgencyPaymentFn, updateAgencyPaymentFn, formData, tableData, currentBusinessId, agencyPaymentId]
  );

  return (
    <PageWrapper
      pageTitle={t('subsidies:agency-billing.new-subsidy-payment.page-title')}
      buttonComponent={
        allowEditing && (
          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              className="kt-subsidy-payment-submit-btn"
              disabled={formIncomplete || createAgencyPaymentLoading || updateAgencyPaymentLoading}
            >
              <span className="mr-4">{t('subsidies:agency-billing.new-subsidy-payment.save-btn')}</span>
              <FontAwesomeIcon icon={faAngleDown} color={colors.white} />
            </Dropdown.Toggle>
            <Dropdown.Menu align="right">
              <Dropdown.Item as="button" onClick={() => handleSubmit(true)}>
                {t('subsidies:agency-billing.new-subsidy-payment.draft-btn')}
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setShowSubmitModal(true)}>
                {t('subsidies:agency-billing.new-subsidy-payment.submit-btn')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )
      }
      secondaryButtonComponent={
        <Button variant="light" onClick={() => history.goBack()} className="mr-4">
          {t('subsidies:agency-billing.new-subsidy-payment.cancel-btn')}
        </Button>
      }
    >
      <SubsidyPaymentInputsCard
        data={formData}
        readOnly={!allowEditing}
        agencyOptions={agenciesForBusinessData?.getAgenciesForBusiness ?? []}
        centerOptions={getCenterOptionsForAgency()}
        onUpdate={setFormData}
        isLoadingDropdownData={getAgenciesForBusinessLoading || getEntityLoading || getAgencyPaymentLoading}
      />
      <div>
        <h3>{t('subsidies:agency-billing.new-subsidy-payment.children-payments-table-label')}</h3>
        {incompleteGraphqlRequestVariables ? (
          <p>{t('subsidies:agency-billing.new-subsidy-payment.missing-selection-text')}</p>
        ) : (
          <ChildPaymentsTable
            data={filterData(tableData)}
            agencyPaymentCheckTotal={formData.amount ?? 0}
            isLoading={getChildrenForAgencyPaymentAtCenterLoading}
            readOnly={!allowEditing}
            tableState={tableState}
            onManageChildAgencyPayment={(selectedTableRow) =>
              setManageDifferenceModal({ open: true, selectedTableRow })
            }
            onSearch={(term) => setTableState((prev) => ({ ...prev, term }))}
            onSort={handleSortTableColum}
            onAmountPaidRowChange={(value, rowNumber) =>
              setTableData((prev) =>
                prev.map((row) => (row.initialListIndex === rowNumber ? { ...row, amountPaidAmount: value } : row))
              )
            }
          />
        )}
      </div>
      {manageDifferenceModal.open && (
        <ManageDifferenceModal
          isOpen={manageDifferenceModal.open}
          readOnly={!allowEditing}
          childAgencyPayment={manageDifferenceModal.selectedTableRow as IChildAgencyPaymentTableRowShape}
          isLoadingAdjustmentOptions={getAdjustmentsForBusinessLoading}
          adjustmentOptions={getAdjustmentOptions()}
          onSave={(updates) =>
            setTableData((prev) =>
              prev.map((row) => (row.initialListIndex === updates.initialListIndex ? { ...updates } : row))
            )
          }
          onClose={() => setManageDifferenceModal({ open: false, selectedTableRow: null })}
        />
      )}
      <SubmitAgencyPaymentConfirmationModal
        isOpen={showSubmitModal}
        onConfirm={() => {
          setShowSubmitModal(false);
          handleSubmit(false);
        }}
        onClose={() => setShowSubmitModal(false)}
      />
    </PageWrapper>
  );
};

export default SubsidyPayment;
