import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import { useGetCustomFields } from 'pages/Businesses/subroutes/BusinessProfile/graphql/queries';
import useDatatableState, { IDatatableState } from 'shared/hooks/useDatatableState2';
import { useTranslation } from 'react-i18next';
import { IconButtonCircle } from 'shared/components/Buttons';
import { Area, ICustomField } from 'shared/types/customField';
import { Page } from 'shared/types/enrollment-form';
import { ExternalHandler } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/EnrollmentFormsTable';

export type CustomFieldDatagridProps = {
  centerIds: string[];
  businessId: string;
  area: Area;
  onSelectedRows: (rows: any) => void;
  nonSelectableIds?: string[];
  page: Page;
};

const CustomFieldDatagrid = React.forwardRef<ExternalHandler, CustomFieldDatagridProps>(
  ({ page, businessId, centerIds, area, onSelectedRows, nonSelectableIds = [] }, ref) => {
    const { t } = useTranslation(['translation', 'enrollment', 'business']);
    const [tableState, tableFunctions, setTableState] = useDatatableState();

    useEffect(() => {
      const defaultTableState: IDatatableState = {
        currentItemOffset: 0,
        activePage: 1,
        pageSize: 10,
        selectedRows: [],
        searchExpressions: [],
      };
      setTableState(defaultTableState);
    }, [setTableState]);

    const [searchText, setSearchText] = useState<undefined | string>();
    const [selectedCustomFields, setSelectedCustomFields] = useState();

    const input = useMemo(() => {
      return {
        businessId: businessId,
        allowedCenterIds: centerIds,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortDirection: 'asc',
        area: area,
        searchKey: searchText,
        sortBy: 'label',
        type: null,
      };
    }, [businessId, centerIds, tableState.activePage, tableState.pageSize, area, searchText]);

    const { data, loading, refetch } = useGetCustomFields({
      variables: {
        input: {
          ...input,
        },
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        refetch: () => {
          refetch();
        },
      }),
      [refetch]
    );

    const dataWithKeys =
      data?.getPaginatedCustomFieldsForBusiness.data?.map((row, index) => ({
        ...row,
        indexId: `${row.id}-${index}`,
      })) ?? [];

    const nonSelectable = dataWithKeys
      .filter((row) => {
        return (
          nonSelectableIds.includes(`${page.sectionType ?? ''}_${row.id}`) ||
          ((page.sectionType === 'payment' || page.sectionType === 'cwa') && nonSelectableIds.includes(`_${row.id}`)) // filter out account custom fields on payment and cwa pages
        );
      })
      .map((r) => r.indexId);

    return (
      <SpinnerTableOverlay show={loading}>
        <DataTable
          keyField="indexId"
          nonSelectable={nonSelectable}
          noPadding={true}
          data={dataWithKeys}
          dataSize={data?.getPaginatedCustomFieldsForBusiness?.totalRecords ?? 0}
          pageSize={tableState.pageSize}
          activePage={tableState.activePage}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          showLoadingOverlay={false}
          showSelect={true}
          showPagination={true}
          selectedRows={selectedCustomFields}
          updateSelectedRows={(o) => {
            setSelectedCustomFields(o);
            onSelectedRows(o);
          }}
          renderHeader={(paginationProps: any, searchProps: any) => (
            <TableHeader className="flex-wrap justify-content-between">
              <div className="d-flex flex-direction-row align-items-center">
                <SizePerPage paginationProps={paginationProps} />
                <TableSearch
                  className="mr-4"
                  onChange={(value) => {
                    setSearchText(value);
                  }}
                  placeholder="Search Label"
                />

                {/* <Select
                  placeholder={capitalize(t('translation:spelling.types'))}
                  options={customFieldTypeOptions}
                  value={filteredType}
                  onChange={(selectedOption) => onTypeFilter(selectedOption.value)}
                  className="mr-4 flex-grow-0 my-2"
                />*/}
              </div>
              <IconButtonCircle
                icon={faTimes}
                tooltipDirection="bottom"
                tooltipText="Clear Filters"
                className="mr-4 my-2"
              />
            </TableHeader>
          )}
          columns={[
            {
              text: 'Label',
              dataField: 'label',
              sort: true,
            },
            {
              text: 'Type',
              dataField: 'type',
              sort: true,
              formatter: (cell: any, row: ICustomField) => (row.type === 'HtmlText' ? 'HTML Text' : row.type),
            },
          ]}
        ></DataTable>
      </SpinnerTableOverlay>
    );
  }
);
export default CustomFieldDatagrid;
