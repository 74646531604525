import React, { useEffect, useState } from 'react';
import { faComments } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { Col } from 'shared/components/Layout';
import Button from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface IProps {
  hasCenterEditPermission: boolean;
  title: string;
  reasonText?: string;
}

const MessagingOff: React.FC<IProps> = ({ hasCenterEditPermission, title, reasonText }) => {
  const history = useHistory();
  const { t } = useTranslation(['comms', 'translation']);

  return (
    <Box>
      <Col align="center" justify="center" sm={12} className={'no-messages-container text-center'}>
        <div className={'no-messages'}>
          <FontAwesomeIcon icon={faComments} size={'10x'} />
          <h4>{title}</h4>
          {reasonText && <div>{reasonText}</div>}
          {hasCenterEditPermission && (
            <Button
              onClick={() => {
                history.push('/engagement/settings');
              }}
            >
              {t('comms:messaging-off.update-btn')}
            </Button>
          )}
        </div>
      </Col>
    </Box>
  );
};

export default MessagingOff;
