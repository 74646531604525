import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { depositFields } from './fields';

interface ICreateDepositVariables {
  input: ICreateDepositInput;
}

interface ICreateDepositData {
  createDeposit: IDeposit[];
}

export const CREATE_DEPOSIT = (fields: string = depositFields) => gql`
  mutation($input: CreateDepositInput!) {
    createDeposit(input: $input) {
      ${fields}
    }
  }
`;

export const useCreateDeposit = (
  options?: MutationHookOptions<ICreateDepositData, ICreateDepositVariables>,
  fields?: string
) => useMutation<ICreateDepositData, ICreateDepositVariables>(CREATE_DEPOSIT(fields), options);

interface IReturnDepositVariables {
  input: IReturnDepositInput;
}

interface IReturnDepositData {
  returnDeposit: IDeposit[];
}

export const RETURN_DEPOSIT = (fields: string = depositFields) => gql`
  mutation($input: ReturnDepositInput!) {
    returnDeposit(input: $input) {
      ${fields}
    }
  }
`;

export const useReturnDeposit = (
  options?: MutationHookOptions<IReturnDepositData, IReturnDepositVariables>,
  fields?: string
) => useMutation<IReturnDepositData, IReturnDepositVariables>(RETURN_DEPOSIT(fields), options);
