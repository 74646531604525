import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Row, Col } from 'shared/components/Layout';
import { HorizontalDivider } from 'shared/components/Dividers';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import PhoneInput from 'shared/components/PhoneInput';
import CreateAgencyFormStepOne from './CreateAgencyFormStepOne';
import CreateAgencyFormStepTwo from './CreateAgencyFormStepTwo';
import { US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { useGetEntity } from 'gql/business/queries';
import { useCreateAgency, useUpdateAgency } from 'gql/agency/mutations';
import { showToast } from 'shared/components/Toast';
import { createAgency, updateAgency } from './duck/actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import GlCodeDropdown from 'shared/components/GlCodeInput/GlCodeDropdown';
import useIsGlCodeInputValid from 'shared/hooks/useIsGlCodeInputValid';

export interface ICreateAgencyFormShape {
  name: string;
  state: string;
  county?: string;
  phoneNumber?: string;
  absencesPolicy: string;
  attendancePolicy: string;
  reimbursementPolicy: string;
  links: (string | IAgencyLink)[];
  centerIds: string[] | null;
  subsidyCalculationBasis: AgencyBillingCalculation | null;
  subsidyPeriod: SubsidyPaymentPeriodType | null;
  glCodeMapping?: IGLCodeMapping | null;
}

interface IProps {
  isOpen: boolean;
  businessId: string;
  agencyToUpdate: IAgency | null;
  onClose: () => void;
}

const CreateUpdateAgencyModal: React.FC<IProps> = ({ isOpen, businessId, agencyToUpdate, onClose, ...props }) => {
  const { t } = useTranslation(['subsidies']);
  const { k2GlCodeActivationCheck } = useFlags();
  const dispatch = useDispatch();
  const { data: getEntityData } = useGetEntity(
    {
      variables: {
        id: businessId,
      },
    },
    `id centers { id name address { state } deactivatedAt }`
  );

  const [createAgencyFn, { loading: createAgencyLoading }] = useCreateAgency({
    onCompleted: (result) => {
      showToast(t('subsidies:agencies.create-modal.success-create-toast-message'), 'success');
      dispatch(createAgency(result.createAgency));
      handleClose();
    },
    onError: (err) => {
      showToast(t('subsidies:agencies.create-modal.error-create-toast-message'), 'error');
    },
  });

  const [updateAgencyFn, { loading: updateAgencyLoading }] = useUpdateAgency({
    onCompleted: (result) => {
      showToast(t('subsidies:agencies.create-modal.success-update-toast-message'), 'success');
      dispatch(updateAgency(result.updateAgency));
      handleClose();
    },
    onError: (err) => {
      showToast(t('subsidies:agencies.create-modal.error-update-toast-message'), 'error');
    },
  });

  const [formStep, setFormStep] = useState<number>(0);
  const [formData, setFormData] = useState<ICreateAgencyFormShape>({
    name: agencyToUpdate?.name ?? '',
    state: agencyToUpdate?.state ?? '',
    county: agencyToUpdate?.county ?? '',
    phoneNumber: agencyToUpdate?.phoneNumber ?? '',
    absencesPolicy: agencyToUpdate?.absentDay ?? '',
    attendancePolicy: agencyToUpdate?.attendanceRequirement ?? '',
    reimbursementPolicy: agencyToUpdate?.reimbursementSchedule ?? '',
    links: agencyToUpdate?.agencyLinks ?? [''],
    centerIds: agencyToUpdate?.centerIds ?? [],
    subsidyCalculationBasis: agencyToUpdate?.billingCalculationType ?? null,
    subsidyPeriod: agencyToUpdate?.subsidyPeriod ?? null,
    glCodeMapping: agencyToUpdate?.glCodeMapping ?? null,
  });

  const handleClose = useCallback(() => {
    setFormStep(0);
    setFormData({
      name: '',
      state: '',
      county: '',
      phoneNumber: '',
      absencesPolicy: '',
      attendancePolicy: '',
      reimbursementPolicy: '',
      links: [''],
      centerIds: [],
      subsidyCalculationBasis: null,
      subsidyPeriod: null,
      glCodeMapping: null,
    });
    onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    if (agencyToUpdate) {
      if (formData.glCodeMapping != null) {
        (formData.glCodeMapping as any).__typename = undefined;
      }
      updateAgencyFn({
        variables: {
          input: {
            businessId,
            id: agencyToUpdate.id,
            name: formData.name,
            state: formData.state,
            county: formData.county,
            phoneNumber: formData.phoneNumber || null,
            centerIds: formData.centerIds,
            absentDay: formData.absencesPolicy,
            attendanceRequirement: formData.attendancePolicy,
            reimbursementSchedule: formData.reimbursementPolicy,
            agencyLinks: formData.links.map((link) => ({
              id: typeof link === 'string' ? null : link.id,
              link: typeof link === 'string' ? link : link.link,
            })),
            subsidyPeriod: formData.subsidyPeriod as SubsidyPaymentPeriodType,
            billingCalculationType: formData.subsidyCalculationBasis as AgencyBillingCalculation,
            glCodeMapping: formData.glCodeMapping,
          },
        },
      });
    } else {
      createAgencyFn({
        variables: {
          input: {
            businessId,
            name: formData.name,
            state: formData.state,
            county: formData.county,
            phoneNumber: formData.phoneNumber || null,
            centerIds: formData.centerIds,
            absentDay: formData.absencesPolicy,
            attendanceRequirement: formData.attendancePolicy,
            reimbursementSchedule: formData.reimbursementPolicy,
            links: formData.links as string[],
            subsidyPeriod: formData.subsidyPeriod as SubsidyPaymentPeriodType,
            billingCalculationType: formData.subsidyCalculationBasis as AgencyBillingCalculation,
            glCodeMapping: formData.glCodeMapping,
          },
        },
      });
    }
  }, [createAgencyFn, updateAgencyFn, formData, businessId, agencyToUpdate]);

  const { isGlCodeMandatory, isGlCodeInputValid } = useIsGlCodeInputValid(null, formData.glCodeMapping, true);

  const formDisabled =
    !formData.name ||
    !formData.state ||
    !formData.attendancePolicy ||
    !formData.subsidyCalculationBasis ||
    !formData.subsidyPeriod ||
    !isGlCodeInputValid;

  return (
    <SideModalDrawer
      title={
        agencyToUpdate ? t('subsidies:agencies.create-modal.edit-title') : t('subsidies:agencies.create-modal.title')
      }
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      closeOnSecondaryCallback={false}
      // @ts-ignore
      primaryChoice={t(`subsidies:agencies.create-modal.primary-button-step-${formStep}-text`)}
      // @ts-ignore
      secondaryChoice={t(`subsidies:agencies.create-modal.secondary-button-step-${formStep}-text`)}
      primaryCallback={() => (formStep === 0 ? setFormStep(1) : handleSave())}
      primaryButtonProps={{
        disabled: formStep === 1 && formDisabled,
        loading: createAgencyLoading || updateAgencyLoading,
        variant: formStep === 0 ? 'outline-primary' : 'primary',
      }}
      secondaryCallback={() => (formStep === 1 ? setFormStep(0) : handleClose())}
      enforceFocus={false}
    >
      <Row>
        <Col>
          <TextInput
            required
            label={t('subsidies:agencies.create-modal.name-input-label')}
            value={formData.name}
            onChange={(value) => setFormData((prev) => ({ ...prev, name: value }))}
            disabled={formStep !== 0}
            name="agency-name"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            label={t('subsidies:agencies.create-modal.state-input-label')}
            value={formData.state}
            options={US_STATE_SELECT_OPTIONS} // US agency billing only requires us to show US states
            onChange={(option) => setFormData((prev) => ({ ...prev, state: option.value }))}
            disabled={formStep !== 0}
            name="agency-state"
          />
        </Col>
        <Col>
          <TextInput
            label={t('subsidies:agencies.create-modal.county-input-label')}
            value={formData.county}
            onChange={(value) => setFormData((prev) => ({ ...prev, county: value }))}
            disabled={formStep !== 0}
            name="agency-county"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PhoneInput
            value={formData.phoneNumber}
            onChange={(value) => setFormData((prev) => ({ ...prev, phoneNumber: value }))}
            disabled={formStep !== 0}
            label={t('subsidies:agencies.create-modal.phone-input-label')}
            name="agency-phone"
          />
        </Col>
      </Row>
      {k2GlCodeActivationCheck && (
        <Row>
          <Col>
            <GlCodeDropdown
              required={isGlCodeMandatory}
              glCodeMapping={formData.glCodeMapping}
              onChange={(glCodeId) =>
                setFormData((prev) => ({
                  ...prev,
                  glCodeMapping: {
                    id: prev.glCodeMapping?.id,
                    glCodeId,
                    agencyId: agencyToUpdate?.id,
                  },
                }))
              }
              areaType="AGENCY"
            />
          </Col>
        </Row>
      )}
      <HorizontalDivider />
      {formStep === 0 ? (
        <CreateAgencyFormStepOne data={formData} onUpdate={setFormData} />
      ) : (
        <CreateAgencyFormStepTwo
          data={formData}
          onUpdate={setFormData}
          centerOptionsForSelectedState={(getEntityData?.getEntity.centers ?? []).filter(
            (center) => center.address.state === formData.state && !center.deactivatedAt
          )}
        />
      )}
    </SideModalDrawer>
  );
};

export default CreateUpdateAgencyModal;
