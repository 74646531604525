import React, { useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import moment from 'moment';
import _, { sortBy } from 'lodash';
import Card from 'shared/components/Card';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import Checkbox from 'shared/components/Checkbox';
import ColorSelect from 'shared/components/Select/ColorSelect';
import { IClassStateShape } from '../../../Class';
import careTypes, { US_Care_Types } from 'shared/constants/dropdownOptions/careTypes';
import { isRegion } from 'shared/util/region';
import NoCCSSubsidyModal from './NoCCSSubsidyModal';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isTimeRangeInvalid } from 'shared/util/timeUtils';
import TimeInput from '../../../../../../../shared/components/TimePicker/TimeInput';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  _class: IClassStateShape;
  updateClass: React.Dispatch<React.SetStateAction<IClassStateShape>>;
  fetchingSpaces: boolean;
  spaceOptions: ISpace[];
  openNewSpaceModal: () => void;
  isReadOnly: boolean;
  timezone: string;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const ClassInformation: React.FC<IProps> = ({
  _class,
  updateClass,
  fetchingSpaces,
  spaceOptions,
  openNewSpaceModal,
  isReadOnly,
  timezone,
  ...props
}) => {
  const sortedSpaces = sortBy(spaceOptions, (space) => space.name.toLowerCase());
  const [showNoCCSModal, setShowNoCCSModal] = useState(false);
  const [selectedCareType, setSelectedCareType] = useState<string | undefined>(undefined);
  const hasCreateSpacePermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Spaces,
    level: RoleLevelType.Create,
  });

  const { k2UsCareTypes, k2ClassStartEndTime } = useFlags();

  const showCareType = useMemo((): boolean => {
    return isRegion('AU') || k2UsCareTypes;
  }, [k2UsCareTypes]);

  const careTypeOptions = useMemo(() => {
    return isRegion('US') ? US_Care_Types : careTypes;
  }, []);

  const invalidTimeframe = Boolean(isTimeRangeInvalid(_class.startTime, _class.endTime));

  return (
    <>
      <Card header="Class Information" className="h-100">
        <Row>
          <Column lg>
            <TextInput
              required
              disabled={isReadOnly}
              id="class-name-input"
              label="Name"
              name="class-name"
              placeholder="Class Name"
              value={_class.name}
              onChange={(value: string) => updateClass((prev) => ({ ...prev, name: value }))}
            />
          </Column>
          {isRegion('US') && (
            <Column sm={2}>
              <TextInput
                disabled={isReadOnly}
                name="class-glCode"
                label="GL Code"
                placeholder="GL Code"
                value={_class.glCode}
                onChange={(value: string) => updateClass((prev) => ({ ...prev, glCode: value }))}
              />
            </Column>
          )}
          <Column lg>
            <Select
              required
              disabled={isReadOnly}
              id="class-space-select"
              label="Homeroom (Space)"
              placeholder="Space Name"
              helpTooltipText="Licensed classroom or space, this class belongs to."
              value={spaceOptions.find((space: ISpace) => space.id === _class.defaultSpaceId)}
              isLoading={fetchingSpaces}
              options={
                hasCreateSpacePermission
                  ? // we use an object with an id of null to indicate the option that will trigger the 'new space' modal
                    [{ id: null }, ...sortedSpaces]
                  : sortedSpaces
              }
              getOptionValue={(option: ISpace) => option.id}
              getOptionLabel={(option: ISpace) => {
                if (!option.id) {
                  return '-- Add New Space --';
                }
                return option.name;
              }}
              onChange={(option: ISpace) => {
                // selected the 'add space' option
                if (!option.id) {
                  openNewSpaceModal();
                } else {
                  updateClass((prev) => ({ ...prev, defaultSpaceId: option.id }));
                }
              }}
            />
          </Column>
          <Column className="flex-grow-0">
            <ColorSelect
              required
              disabled={isReadOnly}
              id="class-color-select"
              label={fieldLabels.color}
              value={_class.colorCode}
              onChange={(hex: string) => updateClass((prev) => ({ ...prev, colorCode: hex }))}
              helpTooltipText={`Select a ${fieldLabels.color.toLowerCase()} to uniquely identify this class throughout Kangarootime.`}
            />
          </Column>
          {showCareType && (
            <Column xs={6} sm={6} lg={3}>
              <Select
                required
                disabled={isReadOnly}
                id="class-care-type-select"
                label="Care Type"
                options={careTypeOptions}
                value={_class.careType}
                placeholder="Care Type"
                onChange={(careType: ISelectMenuItem) => {
                  if (careType.value === selectedCareType) return;
                  setSelectedCareType(careType.value);
                  if (careType.value === 'No CCS') {
                    setShowNoCCSModal(true);
                  } else {
                    updateClass((prev) => ({
                      ...prev,
                      careType: careType.value,
                      isPreSchoolProgram: careType.value === 'LDC' ? _class.isPreSchoolProgram : false,
                    }));
                  }
                }}
              />
            </Column>
          )}
        </Row>
        <Row>
          <Column xs={6} sm={6} lg={3}>
            <DateInput
              required
              disabled={isReadOnly}
              id="class-start-date-input"
              label="Start Date"
              date={_class.startsAt}
              timezone={timezone}
              // @ts-ignore - can remove once the prop type for DateInput is updated to include passing react-dates props in its definition
              displayFormat="MMM D, YYYY"
              onDateSelect={(isoDate: string) =>
                updateClass((prev) => ({ ...prev, startsAt: moment.tz(isoDate, timezone).endOf('day').toISOString() }))
              }
            />
          </Column>
          <Column xs={6} sm={6} lg={3}>
            <DateInput
              id="class-end-date-input"
              label="End Date"
              placeholder=""
              disabled={!_class.endsAt || isReadOnly}
              date={_class.endsAt}
              timezone={timezone}
              // @ts-ignore
              displayFormat="MMM D, YYYY"
              onDateSelect={(isoDate: string) => {
                const newDate = moment.tz(isoDate, timezone).endOf('day').toISOString();
                if (!newDate) {
                  updateClass((prev) => ({ ...prev, endsAt: _class.endsAt }));
                } else {
                  updateClass((prev) => ({ ...prev, endsAt: newDate }));
                }
              }}
              initialVisibleMonth={() => moment()}
              helpTooltipText="Set an end date when a class is temporary or operates for only part of the year. Otherwise, select 'Ongoing' if a class runs continuously (all year)."
            />
          </Column>
          <Column lg className="d-flex align-items-end">
            <div className="mb-4">
              <Checkbox
                disabled={isReadOnly}
                label="Ongoing?"
                value={!_class.endsAt}
                onChange={(checked: boolean) =>
                  updateClass((prev) => ({
                    ...prev,
                    endsAt: checked
                      ? ''
                      : moment().isSameOrBefore(moment(prev.startsAt), 'date')
                      ? moment(prev.startsAt).add(1, 'day').format()
                      : moment().format(),
                  }))
                }
              />
            </div>
          </Column>
          {isRegion('AU') && (
            <Column lg className="d-flex align-items-end">
              <div className="mb-4">
                <Checkbox
                  disabled={isReadOnly || _class.careType !== 'LDC'}
                  label="Is Pre-School Program"
                  value={_class.isPreSchoolProgram}
                  onChange={(checked: boolean) => updateClass((prev) => ({ ...prev, isPreSchoolProgram: checked }))}
                />
              </div>
            </Column>
          )}
        </Row>
        {k2ClassStartEndTime && (
          <Row>
            <Column xs={6} sm={6} lg={3}>
              <div className="d-flex flex-row pt-4">
                <Form.Label>Start Time</Form.Label>
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              </div>
              <TimeInput
                isAM={true}
                value={_.isUndefined(_class.startTime) ? null : _class.startTime}
                onChange={(v) => updateClass((prev) => ({ ...prev, startTime: v }))}
                disabled={isReadOnly}
              />
            </Column>
            <Column xs={6} sm={6} lg={3}>
              <div className="d-flex flex-row pt-4">
                <Form.Label>End Time</Form.Label>
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              </div>
              <TimeInput
                isAM={false}
                value={_.isUndefined(_class.endTime) ? null : _class.endTime}
                onChange={(v) => updateClass((prev) => ({ ...prev, endTime: v }))}
                disabled={isReadOnly}
              />
            </Column>
          </Row>
        )}
      </Card>
      <NoCCSSubsidyModal
        show={showNoCCSModal}
        primaryCallback={() => {
          updateClass((prev) => ({ ...prev, careType: selectedCareType }));
          setShowNoCCSModal(false);
        }}
        secondaryCallback={() => setShowNoCCSModal(false)}
      />
    </>
  );
};

export default ClassInformation;
