import React from 'react';
import BSCard from 'react-bootstrap/Card';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';

interface IProps {
  id?: string;
  header?: string | React.ReactNode | null;
  onClick?: () => void;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  children?: React.ReactNode | null;
  loading?: boolean;
  loadingLines?: number;
  style?: React.CSSProperties;
}

const Card: React.FC<IProps> = ({
  header,
  onClick,
  children,
  className,
  headerClassName = '',
  bodyClassName = '',
  loading = false,
  loadingLines = 4,
  style,
}) => {
  return (
    <BSCard
      className={`mx-0 mt-0 ${className?.includes('mb') ? '' : 'mb-4'} ${onClick ? 'cursor-pointer' : ''} ${
        className || ''
      }`}
      onClick={onClick}
      style={style}
    >
      {header && <BSCard.Header className={`${headerClassName} font-weight-bold`}>{header}</BSCard.Header>}
      <BSCard.Body className={bodyClassName}>{loading ? <LoadingLines number={loadingLines} /> : children}</BSCard.Body>
    </BSCard>
  );
};

export default Card;
