import React from 'react';
import BootstrapRow, { RowProps } from 'react-bootstrap/Row';
import { ILayoutProps } from './LayoutProps';

const Row: React.FC<ILayoutProps> = ({ justify = 'start', align = 'center', className, children, ...props }) => {
  return (
    <BootstrapRow
      {...(props as RowProps)}
      className={`justify-content-${justify} align-items-${align} ${className || ''}`}
    >
      {children}
    </BootstrapRow>
  );
};

export default Row;
