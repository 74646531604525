import React, { useState, useCallback } from 'react';
import TextInput from 'shared/components/TextInput';
import Button from 'shared/components/Buttons';
import errorMessage from 'shared/constants/errorMessages';
import { steps } from './Join';
import { Redirect, useHistory } from 'react-router-dom';
import { useRequestToJoinCenterViaCode } from './graphql/mutations';
import RequestToJoinSuccessModal from './RequestToJoinSuccessModal';
import { useApolloClient } from 'shared/apis/core';

interface IProps {
  newUser: INewUser;
  updateNewUser: React.Dispatch<React.SetStateAction<INewUser>>;
  isSso: boolean | undefined;
}

const InputName: React.FC<IProps> = ({ newUser, updateNewUser, isSso }) => {
  const history = useHistory();
  const apolloClient = useApolloClient();
  const [showRequestSuccessModal, setShowRequestSuccessModal] = useState(false);
  const [redirectBack, setRedirectBack] = useState(false);
  const [redirectForward, setRedirectForward] = useState(false);

  const [requestToJoinFn, { loading, data }] = useRequestToJoinCenterViaCode();

  const handleSave = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (newUser.emailValid) {
        setRedirectForward(true);
      } else if (newUser.code && newUser.email && newUser.firstName && newUser.lastName) {
        requestToJoinFn({
          variables: {
            input: {
              code: newUser.code,
              email: newUser.email,
              firstname: newUser.firstName,
              lastname: newUser.lastName,
            },
          },
        })
          .then(() => {
            setShowRequestSuccessModal(true);
            apolloClient.clearStore();
          })
          .catch((err) => console.log(err));
      }
    },
    [
      apolloClient,
      newUser.code,
      newUser.email,
      newUser.emailValid,
      newUser.firstName,
      newUser.lastName,
      requestToJoinFn,
    ]
  );

  const dismissModal = useCallback(() => {
    setShowRequestSuccessModal(false);
    history.push('/login');
  }, [history]);

  const formDisabled = !newUser?.firstName || !newUser?.lastName;

  if (redirectBack) return <Redirect to={`/join/?step=${steps.verifyCode}`} />;

  if (redirectForward) {
    if (isSso) {
      return <Redirect to={`/join/?step=${steps.createPin}`} />;
    } else {
      return <Redirect to={`/join/?step=${steps.createPassword}`} />;
    }
  }

  return (
    <>
      <form onSubmit={handleSave} className="login-container p-8 my-auto mx-auto">
        <p className="h1 font-weight-light">Join your team on</p>
        <p className="h1 font-weight-bold mb-6">Kangarootime</p>
        <p className="mb-8">Enter your details below.</p>
        <TextInput
          label="First Name"
          value={newUser.firstName || ''}
          onChange={(firstName: string) => updateNewUser({ ...newUser, firstName })}
          isInvalid={!newUser.firstName}
          errorText={errorMessage.firstName}
          required={true}
        />
        <TextInput
          label="Last Name"
          value={newUser.lastName || ''}
          onChange={(lastName: string) => updateNewUser({ ...newUser, lastName })}
          isInvalid={!newUser.lastName}
          errorText={errorMessage.lastName}
          required={true}
        />
        <Button
          loading={loading}
          disabled={formDisabled || showRequestSuccessModal}
          className="btn-block my-8"
          type="submit"
        >
          Continue
        </Button>
        <Button variant="secondary" onClick={() => setRedirectBack(true)}>
          Back
        </Button>
      </form>
      <RequestToJoinSuccessModal
        isOpen={showRequestSuccessModal}
        onClose={dismissModal}
        centerName={data?.requestToJoinCenterViaCode.primaryCenter?.name ?? ''}
      />
    </>
  );
};

export default InputName;
