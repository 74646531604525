import moment from 'moment';
import React from 'react';
import { setOtherZone } from 'shared/util/timeUtils';
import DateInput, { IDateInputProps } from './DateInput';

// react-dates package bases dates on the browser time
// Convert the date value received from parent component to browser time to use in our Date Input,
// Then when we send it back to the parent component, well convert it to the actual timezone we want

interface IProps extends IDateInputProps {
  timezone: string;
}

const DateInputWithTimezone: React.FC<IProps> = ({ onDateSelect, date, timezone, ...rest }) => {
  const browserTimezone = moment.tz.guess();

  return (
    <DateInput
      {...rest}
      date={date ? setOtherZone(moment.tz(date, timezone), browserTimezone).format() : date}
      onDateSelect={(date) => {
        onDateSelect &&
          onDateSelect(date && moment(date).isValid() ? setOtherZone(moment(date), timezone).format() : '');
      }}
      timezone={browserTimezone}
    />
  );
};

export default DateInputWithTimezone;
