export const kisiPairedCenters = `
  kisiPlace{
    id 
    name
    description
    locks_Count
    members_Count
  }
  id
  kisiApiKey
  staleInKisi
  kisiPlaceId
  kisiShareId
  name
  syncedLockCount
`;
