import React, { useState } from 'react';
import moment from 'moment';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import { TableHeader } from 'shared/components/DataTable';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import { ccsEventTypesOptions } from 'shared/constants/enums/ccssEnums';

interface ITHeadProps {
  activeFilters: IEventFilter;
  onActiveFilterChange: (value: IEventFilter) => void;
  dateRange: ITimeRange;
  setDateRange: (tr: ITimeRange) => void;
  clearFilters: () => void;
  handleTypeChange: (option: any) => void;
}

const CCSEventsTableHeader: React.FC<ITHeadProps> = ({
  dateRange,
  activeFilters,
  setDateRange,
  handleTypeChange,
  clearFilters,
}) => {
  const [isDateValid, setDateValidity] = useState<boolean>(true);

  const validateDateRange = (start: string, end: string) => {
    if (start !== '' && end !== '') {
      setDateValidity(moment(start).isBefore(moment(end)));
    }
  };
  const handleStartDate = (start: string) => {
    setDateRange({ ...dateRange, start });
    validateDateRange(start, dateRange.end);
  };

  const handleEndDate = (end: string) => {
    setDateRange({ ...dateRange, end });
    validateDateRange(dateRange.start, end);
  };

  const handleCLearFilters = () => {
    setDateValidity(true);
    clearFilters();
  };

  return (
    <TableHeader className="flex-wrap messages-table-header">
      <DateInput
        date={dateRange.start}
        isValid={isDateValid}
        hasCustomValidation
        onDateSelect={handleStartDate}
        className="mr-2 flex-grow-0 my-2"
      />
      <div className="mr-2 my-2">to</div>
      <DateInput
        date={dateRange.end}
        isValid={isDateValid}
        hasCustomValidation
        onDateSelect={handleEndDate}
        className="mr-4 flex-grow-0 my-2"
      />
      <div className="d-flex justify-content-between align-items-center right-filter">
        <Select
          name="typeFilter"
          options={ccsEventTypesOptions}
          placeholder="Type"
          onChange={handleTypeChange}
          className="mr-4 my-2 type-filter"
          value={activeFilters.type}
        />
        <IconButtonCircle
          icon={faTimes}
          onClick={handleCLearFilters}
          tooltipDirection="bottom"
          tooltipText="Clear Filters"
          className="mr-4 my-2"
        />
      </div>
    </TableHeader>
  );
};

export default CCSEventsTableHeader;
