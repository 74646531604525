import React from 'react';
import Avatar from 'shared/components/Avatar';
import { stringToHueDegree } from 'shared/util/string';

interface IProps {
  center: ICenter;
  size?: avatarSizes;
  className?: string;
}

const CenterAvatar: React.FC<IProps> = ({ center, size = 'sm', className }) => {
  return (
    <Avatar
      className={className}
      color={`hsl(${stringToHueDegree(center.id)}, ${stringToHueDegree(center.id) < 50 ? '100%' : '40%'}, 40%`}
      size={size}
      image={center.avatar && center.avatar.url}
      initials={center.name.charAt(0).toUpperCase()}
    ></Avatar>
  );
};

export default CenterAvatar;
