import { useCallback, useMemo, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Row } from 'shared/components/Layout';
import { capitalize } from 'shared/util/string';
import TextInput, { PasswordInput } from 'shared/components/TextInput';
import { useCreateEditKindyForAllCredential } from 'gql/kindyForAll/mutations';
import { showToast } from 'shared/components/Toast';
import { IKindyForAllCredential } from 'shared/types/kindyForAll';

type Props = {
  businessId: string;
  credential: IKindyForAllCredential | undefined;
  isOpen: boolean;
  onClose: () => void;
};

interface IFormData {
  applicationName?: string;
  applicationKey?: string;
  applicationSecret?: string;
}

export default function CreateEditCredentialModal({ businessId, credential, isOpen, onClose }: Props) {
  const [formData, setFormData] = useState<IFormData>({});
  const handleChange = (value: IFormData) => setFormData({ ...formData, ...value });
  const { t } = useTranslation(['translation', 'subsidies']);

  const getProps = <t extends keyof IFormData>(field: t) => ({
    value: formData[field],
    onChange: (value: IFormData[t]) => handleChange({ [field]: value }),
    autoComplete: 'off',
  });

  const isValidFormData = useMemo(
    () => !!formData.applicationName && !!formData.applicationKey && !!formData.applicationSecret,
    [formData]
  );

  useMemo(() => {
    if (credential) {
      setFormData({
        applicationName: credential.applicationName,
        applicationKey: credential.applicationKey,
        applicationSecret: '', // Do not populate this as we will not have the full value
      });
    }
  }, [credential]);

  const [createEditKindyCredentialFn, { loading: createEditKindyCredentialLoading }] =
    useCreateEditKindyForAllCredential({
      onCompleted: (result) => {
        if (result?.createEditKindyForAllCredential) {
          setFormData({});
          onClose();
          showToast(`API Key successfully ${credential === undefined ? 'created' : 'edited'}.`, 'success');
        }
      },
      onError: (error) => {
        showToast(
          `There was an error  ${credential === undefined ? 'creating' : 'editing'} API Key : ${error.message}`,
          'error'
        );
      },
    });

  const handleSubmit = useCallback(() => {
    if (isValidFormData) {
      if (credential) {
        createEditKindyCredentialFn({
          variables: {
            input: {
              id: credential?.id ?? '',
              businessId: businessId ?? '',
              applicationName: formData.applicationName ?? '',
              applicationKey: formData.applicationKey,
              applicationSecret: formData.applicationSecret,
            },
          },
        });
      } else {
        createEditKindyCredentialFn({
          variables: {
            input: {
              businessId: businessId ?? '',
              applicationName: formData.applicationName ?? '',
              applicationKey: formData.applicationKey,
              applicationSecret: formData.applicationSecret,
            },
          },
        });
      }
    }
  }, [isValidFormData, createEditKindyCredentialFn, credential, businessId, formData]);

  return (
    <SideModalDrawer
      title={`${credential === undefined ? 'Create' : 'Edit'} API Key`}
      show={isOpen}
      primaryChoice={capitalize(t('translation:spelling.save'))}
      primaryCallback={handleSubmit}
      primaryButtonProps={{ disabled: !isValidFormData, loading: createEditKindyCredentialLoading }}
      onHide={() => {
        if (onClose) {
          setFormData({});
          onClose();
        }
      }}
      closeOnPrimaryCallback={false}
    >
      <Form>
        <Row className="mb-4 mt-4">
          <TextInput
            required
            label={'Application Name'}
            placeholder={'Input your Application Name'}
            {...getProps('applicationName')}
          />
        </Row>
        <Row className="mb-4">
          <TextInput
            required
            label={'Application Key'}
            placeholder={'Input your Application Key'}
            {...getProps('applicationKey')}
          />
        </Row>
        <Row className="mb-4">
          <PasswordInput
            required
            label={'Application Secret'}
            placeholder={'Input your Application Secret'}
            {...getProps('applicationSecret')}
          />
        </Row>
      </Form>
    </SideModalDrawer>
  );
}
