import moment from 'moment';

export function sortBillingPeriodsByStartDate(billingPeriods: IBillingPeriodCustom[]): IBillingPeriodCustom[] {
  return billingPeriods.sort((a, b) => {
    const aStartDate = moment(a.startDate);
    if (aStartDate.isBefore(b.startDate)) return -1;
    if (aStartDate.isSame(b.startDate, 'day')) return 0;
    return 1;
  });
}
