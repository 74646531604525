import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Row, Col } from 'shared/components/Layout';
import { capitalize } from 'shared/util/string';
import { showToast } from 'shared/components/Toast';
import { RootState } from 'store/reducers';
import AlertContainer from 'shared/components/AlertContainer';
import Select from 'shared/components/Select';
import { useTranslation } from 'react-i18next';
import { useGenerateLinkCode, useUpdateLinkCode } from 'pages/Enrollment/subroutes/Settings/graphql/mutations';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import _mapValues from 'lodash/mapValues';
import TextInput from 'shared/components/TextInput';

interface IProps {
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
  businessId: string;
  linkCode?: string;
  associatedCenters?: ICenter[];
  name?: string;
}

const CreateLinkCodeModal: React.FC<IProps> = ({
  businessId,
  isOpen,
  onSuccess,
  onClose,
  linkCode = '',
  associatedCenters = [],
  name = '',
}) => {
  const { t } = useTranslation(['translation', 'business']);
  const centers = useSelector<RootState, ICenter[]>((state) => state.centers.all);
  const [generateLinkCode, { loading: useGenerateLinkCodeFuncLoading }] = useGenerateLinkCode();
  const [updateLinkCode, { loading: useUpdateLinkCodeFuncLoading }] = useUpdateLinkCode();
  const [selectedCenters, setSelectedCenters] = useState<ICenter[]>([]);
  const [selectedStates, setSelectedStates] = useState<{ value: string; label: string }[]>([]);
  const [linkCodeName, setLinkCodeName] = useState<string>('');
  const [error, setError] = useState(null);

  useEffect(() => {
    setSelectedCenters(associatedCenters);
    setLinkCodeName(name);
  }, [associatedCenters, name]);

  const countryCode = DEFAULT_COUNTRY;
  const stateOptions = _mapValues(COUNTRY_INFO, (country) =>
    Object.entries(country.subdivisions).map((arr) => ({ value: arr[0], label: arr[1] }))
  );

  const centerOptions = useMemo(
    () =>
      !selectedStates || !selectedStates.length
        ? centers
        : centers.filter((c) => selectedStates.map((s) => s.value).includes(c.address.state)),
    [centers, selectedStates]
  );

  const handleSubmit = useCallback(() => {
    if (!linkCode) {
      generateLinkCode({
        variables: {
          input: { businessId: businessId, name: linkCodeName, centerIds: selectedCenters.map((value) => value.id) },
        },
      })
        .then((res) => {
          showToast(t('business:enrolment.link-code-creation-success'), 'success');
          onSuccess();
          setError(null);
          setLinkCodeName('');
          setSelectedCenters([]);
          onClose();
        })
        .catch((err) => {
          showToast(err, 'error');
          setError(err);
        });
    } else {
      updateLinkCode({
        variables: {
          input: {
            businessId: businessId,
            name: linkCodeName,
            linkCode: linkCode,
            centerIds: selectedCenters.map((value) => value.id),
          },
        },
      })
        .then((res) => {
          showToast(t('business:enrolment.link-code-update-success'), 'success');
          onSuccess();
          setError(null);
          setLinkCodeName('');
          setSelectedCenters([]);
          onClose();
        })
        .catch((err) => {
          showToast(err, 'error');
          setError(err);
        });
    }
  }, [generateLinkCode, businessId, selectedCenters, linkCodeName]);

  return (
    <SideModalDrawer
      show={isOpen}
      onHide={onClose}
      title={!linkCode ? t('business:enrolment.create-new-link') : t('business:enrolment.edit-link')}
      secondaryChoice={capitalize(t('translation:spelling.cancel'))}
      secondaryCallback={onClose}
      primaryChoice={!linkCode ? t('business:enrolment.create-new-link-btn') : t('business:enrolment.edit-link-btn')}
      primaryCallback={handleSubmit}
      primaryButtonProps={{
        disabled: useGenerateLinkCodeFuncLoading || !linkCodeName,
        loading: useGenerateLinkCodeFuncLoading || useUpdateLinkCodeFuncLoading,
      }}
    >
      {error && <AlertContainer errorApolloError={error!} />}
      <Row>
        <Col>
          <TextInput
            id="name-input"
            name="new-link-code-name"
            label={t('business:enrolment.link-name')}
            value={linkCodeName || ''}
            onChange={(value: string) => setLinkCodeName(value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            label={t('business:enrolment.state-selection')}
            options={stateOptions[countryCode]}
            onChange={(selectedStateValues: { value: string; label: string }[]) => {
              setSelectedStates(selectedStateValues || []);
            }}
            isLoading={false}
          />
          <Select
            isMulti
            label={capitalize(t('business:enrolment.center-selection'))}
            options={centerOptions.map((c) => ({ ...c, value: c.id, label: c.name }))}
            onChange={(selectedCenterValues: ICenter[]) => {
              setSelectedCenters(selectedCenterValues || []);
            }}
            value={selectedCenters}
            isLoading={false}
            getOptionValue={(option: ICenter) => option.id}
            getOptionLabel={(option: ICenter) => option.name}
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default CreateLinkCodeModal;
