import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export function useSearch() {
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText, setDebouncedSearchText] = useState<string>('');
  const debouncedHandler = useMemo(
    () =>
      debounce((searchString: string) => {
        console.log('DEV:', 'debounce search', searchString);
        setDebouncedSearchText(searchString);
      }, 300),
    []
  );
  const handleSearchChange = useCallback(
    (searchString: string) => {
      setSearchText(searchString);
      debouncedHandler(searchString);
    },
    [debouncedHandler]
  );

  const handleClear = useCallback(() => {
    setSearchText('');
    setDebouncedSearchText('');
  }, []);

  return { searchText, handleSearchChange, debouncedSearchText, handleClear };
}
