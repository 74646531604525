import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import React, { useContext, useMemo } from 'react';
import Button from 'shared/components/Buttons';
import Select from 'shared/components/Select/Base';
import { Field, Section } from 'shared/types/enrollment-form';
import { EnrolmentFormDetailContext } from '../enrollment-form-detail';
import { faPen, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { UNTITLED_SECTION } from '../section-selector-provider';
import { ConfirmationContent } from 'shared/components/ConfirmationContainer/eventManager';
import { showConfirm } from 'shared/components/ConfirmationContainer';
import { useTranslation } from 'react-i18next';
import { useFieldMetadata } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/hooks/useFieldMetadata';
import { parseOptions } from 'shared/components/CustomField/utils';
import { Option } from 'react-select/src/filters';
import { checkIsSystemRequiredField } from '../utils';

interface ConditionalControlWidgetProps {
  field: Field;
  section: Section;
  onChange: (section: Section) => void;
}

export const ConditionalControlWidget = ({ field, section, onChange }: ConditionalControlWidgetProps) => {
  const enrolmentFormDetailContextShape = useContext(EnrolmentFormDetailContext);
  const { fieldMetadata, loading } = useFieldMetadata(enrolmentFormDetailContextShape.businessId, field?.name);

  const options = useMemo<string[] | Pick<Option, 'label' | 'value'>[] | { label: string; value: boolean }[]>(() => {
    if (fieldMetadata == null) {
      return [];
    }

    if (['Combo', 'Radio'].includes(fieldMetadata!.type)) {
      if (fieldMetadata!.attributesAsString) {
        const values = fieldMetadata!.valuesAsString ? JSON.parse(fieldMetadata!.valuesAsString).split('\n') : [];
        const parseOptions1 = parseOptions(fieldMetadata!.attributesAsString);
        if (parseOptions1.length > 0 && typeof parseOptions1[0] === 'string')
          return (parseOptions1 as string[]).map((o, idx) => ({ label: o, value: values?.[idx] ?? o }));
        return parseOptions1;
      } else {
        return [];
      }
    }

    if (fieldMetadata!.type === 'Checkbox') {
      return [
        {
          label: 'Checked',
          value: true,
        },
        {
          label: 'Unchecked',
          value: false,
        },
      ];
    }

    return [];
  }, [fieldMetadata]);

  const value =
    field.type === 'Checkbox'
      ? {
          label: section.clientSideBehaviours?.whenValueIs ? 'Checked' : 'Unchecked',
          value: section.clientSideBehaviours?.whenValueIs,
        }
      : {
          label: (options as any[]).find((o) => {
            if (typeof o[0] === 'string') {
              // if it is a string[]
              return o === section.clientSideBehaviours?.whenValueIs;
            }
            return o.value === section.clientSideBehaviours?.whenValueIs;
          })?.label,
          value: section.clientSideBehaviours?.whenValueIs,
        };

  return (
    <div className="d-flex align-items-center">
      If
      <Select
        className="react-select-container mx-2 mb-0 min-width-150"
        classNamePrefix="react-select"
        isLoading={loading}
        options={options as Option[]}
        getOptionLabel={(o) => o.label}
        value={value}
        onChange={(v) => {
          onChange({
            ...section,
            clientSideBehaviours: {
              ...section.clientSideBehaviours!,
              whenValueIs: v?.value,
            },
          });
        }}
      />
      is selected
      <Select
        className="react-select-container mx-2 mb-0"
        classNamePrefix="react-select"
        options={[
          { label: 'Show', value: 'show' },
          { label: 'Hide', value: 'hide' },
        ]}
        value={
          {
            label: capitalize(section.clientSideBehaviours?.whenConditionMet),
            value: section.clientSideBehaviours?.whenConditionMet,
          } as any
        }
        onChange={(v) => {
          onChange({
            ...section,
            clientSideBehaviours: {
              ...section.clientSideBehaviours!,
              whenConditionMet: v?.value,
            } as any,
          });
        }}
      />
      questions(s)
    </div>
  );
};

interface SectionLabelWidgetProps {
  onEdit: () => void;
  sectionLabel: string | undefined;
}
export const SectionLabelWidget = ({ onEdit, sectionLabel }: SectionLabelWidgetProps) => {
  return (
    <div>
      {sectionLabel ?? UNTITLED_SECTION}
      <Button variant="link" title="Edit section title" onClick={onEdit}>
        <FontAwesomeIcon size="1x" icon={faPen} color={colors.dark} />
      </Button>
    </div>
  );
};

interface RemoveSectionWidgetProps {
  onRemoveAsync: () => Promise<void>;
  removeSectionDialog?: Pick<ConfirmationContent, 'title' | 'message'>;
  section: Section;
}
export const RemoveSectionWidget = ({ onRemoveAsync, removeSectionDialog, section }: RemoveSectionWidgetProps) => {
  const { t } = useTranslation(['enrollment']);
  const isRemovable = !section.fields.some((field) =>
    checkIsSystemRequiredField(field.name, section.sectionMetadata?.type ?? '')
  );

  if (!isRemovable) {
    return null;
  }

  return (
    <Button
      variant="link"
      title="Remove Section"
      onClick={() =>
        showConfirm({
          onConfirm: onRemoveAsync,
          title: removeSectionDialog?.title ?? t('enrollment:form.remove-section-modal.title'),
          message: removeSectionDialog?.message ?? t('enrollment:form.remove-section-modal.message'),
        })
      }
    >
      <FontAwesomeIcon size="1x" icon={faTrashAlt} color={colors.dark} />
    </Button>
  );
};
