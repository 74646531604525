import AccountTransactions from './AccountTransactions';
import './TransactionTab.scss';

interface TransactionTabProps {
  account?: IAccount;
  accountId: string;
}
export default function TransactionTab({ account, accountId }: TransactionTabProps) {
  return <AccountTransactions accountId={accountId} account={account}></AccountTransactions>;
}
