import { gql } from '@apollo/client';
import { FetchPolicy } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { useDispatch } from 'react-redux';
import { getContactsSuccess, getContactByIdSuccess } from '../duck/actions';
import { isRegion } from 'shared/util/region';

const isAuRegion = isRegion('AU');

const ccssAttributes = `
  ccssAttributes {
    crn
    isCrnEditable
  }
`;

export const contactFields = `
  id
  firstname
  lastname
  email
  nickname
  cognitoConfiguredAt
  dob
  hasKtConnectAccess
  avatar {
    url
  }
  address {
    address1
    address2
    city
    state
    postalCode
    country
  }
  primaryPhoneNumber {
    number
    type
  }
  additionalPhoneNumbers {
    number
    type
  }
  children {
    id
    childId
    firstname
    lastname
    nickname
    avatar {
      url
    }
    dob
    relationshipType
    accountPermissions {
      accountId
      account {
        id
        name
        center {
          id
          name
        }
      }
      permissions
      isPrimary
    }
  }
  tags {
    id
    entityId
    name
    category
  }
  accounts {
    id
    name
    entityId
    centerId
    center {
      id
      name
    }
  }
  ${isAuRegion ? ccssAttributes : ''}
`;

export interface ISearchContactsVariables {
  input: IElasticsearchQuery;
}

export interface ISearchContactsData {
  searchContacts: IContact[]; // this ES query doesn't follow the response pattern of the others
}

export const SEARCH_CONTACTS = gql`
  query($input: SearchInput!) {
    searchContacts(input: $input) {
      id
      firstname
      lastname
      email
      nickname
      hasKtConnectAccess
      avatar {
        url
      }
      primaryPhoneNumber {
        number
        type
      }
      children {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
        dob
        relationshipType
      }
      accounts {
        id
      }
      ${isAuRegion ? ccssAttributes : ''}
    }
  }
`;

export const GET_CONTACT_BY_ID = gql`
  query($id: ID!) {
    getContactById(id: $id) {
      ${contactFields}
    }
  }
`;

export const GET_TAG_OPTIONS = gql`
  query ($entityId: String!) {
    getEntity(id: $entityId) {
      id
      tags {
        id
        name
        category
      }
    }
  }
`;

export const GET_TAGS_IN_USE = gql`
  query ($entityId: ID!) {
    getContactTagsInUseAtEntity(entityId: $entityId) {
      id
      name
    }
  }
`;

export const useSearchContacts = (input: IElasticsearchQuery) => {
  const dispatch = useDispatch();

  return useQuery<{ searchContacts: IContact[] }, ISearchContactsVariables>(SEARCH_CONTACTS, {
    variables: { input },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      dispatch(getContactsSuccess(data.searchContacts));
    },
  });
};

export const useGetContactByIdRedux = (id: string) => {
  const dispatch = useDispatch();

  return useQuery<{ getContactById: IContact }, { id: string }>(GET_CONTACT_BY_ID, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    skip: id === '',
    onCompleted: (data) => {
      data.getContactById && dispatch(getContactByIdSuccess(data.getContactById));
    },
  });
};

export const useGetContactById = (id: string, fetchPolicy?: FetchPolicy) => {
  return useQuery<{ getContactById: IContact }, { id: string }>(GET_CONTACT_BY_ID, {
    variables: { id },
    fetchPolicy: fetchPolicy ?? 'cache-and-network',
    skip: id === '',
  });
};

export const useGetContactTagOptions = (entityId: string) => {
  const response = useQuery<{ getEntity: IEntity }, { entityId: string }>(GET_TAG_OPTIONS, {
    variables: { entityId },
    skip: !entityId,
  });

  return response?.data?.getEntity.tags.filter((tag) => tag.category === 'CONTACT');
};

export const useGetContactTagsInUseAtEntity = (entityId: string) => {
  return useQuery<{ getContactTagsInUseAtEntity: ITag[] }, { entityId: string }>(GET_TAGS_IN_USE, {
    variables: { entityId },
    skip: entityId === '',
    fetchPolicy: 'cache-and-network',
  });
};
