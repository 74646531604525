export interface IAbsenceTypeOption {
  label: string;
  value: AbsenceType;
}

const absenceTypes: IAbsenceTypeOption[] = [
  { label: 'Standard', value: 'STANDARD' },
  { label: 'Additional', value: 'ADDITIONAL' },
  { label: 'Holiday', value: 'HOLIDAY' },
];

export default absenceTypes;
