import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { orderBy, sortBy } from 'lodash';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import DataTable, { TableSearch, SizePerPage, TableHeader } from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable';
import { IconButtonCircle } from 'shared/components/Buttons';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState';
import { useGetActiveCentersQuery } from 'shared/hooks/useGetActiveCenters';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import PrimaryContactsColumn from './PrimaryContactsColumn';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import InitialEmptyChildrenTable from '../InitialEmptyChildrenTable';
import { IGetTableMetaData } from 'shared/hooks/useGetTableMetadata';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { getFullName, getInitials } from 'shared/util/string';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetCenterTagOptions } from '../../../../../Centers/subroutes/Profile/graphql/queries';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers';

interface IProps {
  data: IChild[];
  dataSize: number;
  initialLoading: boolean;
  metaLoading: boolean;
  loading: boolean;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  tableMetaData?: IGetTableMetaData;
  isInternal: boolean;
  navigateToAccountsPage: () => void;
  onRowClick: (child: IChild) => void;
  onContactClick: (contact: IChildContact) => void;
  userCanCreateChild: boolean;
}

const ChildrenTable: React.FC<IProps> = ({
  data,
  dataSize,
  initialLoading,
  loading,
  metaLoading,
  tableState,
  tableFunctions,
  tableMetaData,
  isInternal,
  onRowClick,
  onContactClick,
  navigateToAccountsPage,
  userCanCreateChild,
  ...props
}) => {
  const { data: activeCentersData } = useGetActiveCentersQuery();
  const { data: getAllowedEntitiesData } = useGetAllowedEntities(!isInternal);
  const { data: tagsInUseData } = useGetTagsInUse(TagsTypeElasticIndex.Children);

  const { t } = useTranslation('translation');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const user = useSelector((state: RootState) => state.user);
  const { data: centerTags } = useGetCenterTagOptions(user?.entityId || '');

  const hasViewContactsPermission = useHasRoleAreaLevel({
    area: AreaType.Contact,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const centerOptions: ITableFilterOption[] = useMemo(
    () =>
      orderBy(activeCentersData?.searchCenters.data ?? [], (center) => center.name.toLocaleLowerCase(), 'asc').map(
        (center: ICenter) => ({
          label: center.name,
          value: center.id,
          searchExpression: {
            [SEARCH_EXPRESSIONS.TERM]: {
              field: 'centerIds.keyword',
              predicate: PREDICATES.CONTAINS,
              value: center.id,
            },
          },
        })
      ),
    [activeCentersData]
  );

  const businessOptions = useMemo(
    () =>
      orderBy(getAllowedEntitiesData?.getAllowedEntities ?? [], (business) => business.name, 'asc').map(
        (business: IBusiness) => ({
          label: business.name,
          value: business.id,
          searchExpression: {
            [SEARCH_EXPRESSIONS.TERM]: {
              field: 'businessIds.keyword',
              predicate: PREDICATES.CONTAINS,
              value: business.id,
            },
          },
        })
      ),
    [getAllowedEntitiesData]
  );

  const tagOptions = useMemo(
    () =>
      orderBy(tagsInUseData?.getTagsUsedAcrossEntity ?? [], (tag) => tag.name.toLocaleLowerCase(), 'asc').map(
        (tag) => ({
          label: tag.name,
          value: tag.id,
          searchExpression: {
            [SEARCH_EXPRESSIONS.TERM]: {
              field: 'tagIds.keyword',
              predicate: PREDICATES.CONTAINS,
              value: tag.id,
            },
          },
        })
      ),
    [tagsInUseData]
  );

  const centerTagOptions: ITableFilterOption[] = orderBy(
    centerTags?.getCenterTagOptions ?? [],
    (tag) => tag.name.toLocaleLowerCase(),
    'asc'
  ).map((tag) => ({
    label: tag.name,
    value: tag.id,
    searchExpression: {
      [SEARCH_EXPRESSIONS.TERM]: { field: 'centerTagIds.keyword', predicate: PREDICATES.CONTAINS, value: tag.id },
    },
  }));

  const clearActiveFilters = useCallback(() => {
    tableFunctions.updateSelectedFilters({});
  }, [tableFunctions]);

  const handleCenterFilterSelect = useCallback(
    (values: ITableFilterOption[]) => {
      tableFunctions.updateSelectedFilters({
        ...tableState.selectedFilters,
        center: values,
      });
    },
    [tableFunctions, tableState]
  );

  const handleBusinessFilterSelect = useCallback(
    (values: ITableFilterOption[]) => {
      tableFunctions.updateSelectedFilters({
        ...tableState.selectedFilters,
        entity: values,
      });
    },
    [tableFunctions, tableState]
  );

  const handleTagsFilterSelect = useCallback(
    (values: ITableFilterOption[]) => {
      tableFunctions.updateSelectedFilters({
        ...tableState.selectedFilters,
        tags: values,
      });
    },
    [tableFunctions, tableState]
  );

  const handleSearchTerm = useCallback(
    (term: string) => {
      const lowerCasedTerm = term.toLowerCase();
      const searchableFields = ['firstName', 'lastName'];

      if (isInternal) {
        searchableFields.push('id.keyword');
      }

      // adapted from useGetContactsSearchExpress. didn't make it a shared hook since this specific logic is for the children table
      // maybe we make a generic hook that takes fields and terms and constructs this instead of having table specific ones
      const fullSearchArr: ISearchExpression[] = [];
      const allWordsSearchArr: ISearchExpression[] = [];
      const individualWords = lowerCasedTerm.split(' ');

      individualWords.forEach((word) => {
        const individualWordSearchArr: ISearchExpression[] = [];

        searchableFields.forEach((column) =>
          individualWordSearchArr.push({
            [SEARCH_EXPRESSIONS.TERM]: {
              field: column,
              predicate: PREDICATES.CONTAINS,
              value: word,
            },
          })
        );

        allWordsSearchArr.push({ [SEARCH_EXPRESSIONS.ANY]: individualWordSearchArr });
      });

      fullSearchArr.push({ [SEARCH_EXPRESSIONS.ALL]: allWordsSearchArr });

      tableFunctions.updateSearchExpressions([
        {
          [SEARCH_EXPRESSIONS.ANY]: fullSearchArr,
        },
      ]);
    },
    [tableFunctions, isInternal]
  );

  const handleContactClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, contact: IChildContact) => {
      // stop propagation otherwise the onRowClick listener will fire
      event.stopPropagation();

      onContactClick(contact);
    },
    [onContactClick]
  );

  // show skeleton on initial load
  if (loading && initialLoading) {
    return (
      <PageWrapperBody>
        <DataTableLoadingSkeleton />
      </PageWrapperBody>
    );
  }

  // if we're not attempting to load any meta data and either no data is found (i.e. non-existing index) or there is nothing in the index for this user, show the initial empty state
  if (!metaLoading && (!tableMetaData || tableMetaData?.totalResults === 0)) {
    return <InitialEmptyChildrenTable userCanCreateChild={userCanCreateChild} onButtonClick={navigateToAccountsPage} />;
  }

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  return (
    <DataTable
      data={data}
      dataSize={dataSize}
      pageSize={tableState.pageSize}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      activePage={tableState.activePage}
      onSort={tableFunctions.updateSort}
      showLoadingOverlay={loading}
      showSelect={false}
      handleRowClick={(event, row: IChild) => onRowClick(row)}
      columns={[
        {
          text: 'Name',
          dataField: 'lastName.keyword',
          classes: 'lg-column',
          sort: true,
          formatter: (cell: string, row: IChild) => (
            <AvatarDataTableCell
              initials={getInitials(row)}
              avatar={row.avatar?.url ?? ''}
              primaryText={getFullName(row)}
            />
          ),
        },
        {
          text: 'Age',
          classes: 'md-column',
          dataField: 'doB', // not sure what's with the casing but that's how elasticsearch saved the DoB field
          sort: true,
          formatter: (cell: string, row: IChild) => getAgeStringFromDateOfBirth(moment(row.dob)).replace(/\(|\)/g, ''),
        },
        ...(hasViewContactsPermission
          ? [
              {
                text: 'Contacts',
                dataField: 'contacts',
                formatter: (contacts: IChildContact[], row: IChild) => (
                  <PrimaryContactsColumn primaryContacts={contacts} onAvatarClick={handleContactClick} />
                ),
              },
            ]
          : []),
        // class column - future story
        // status column - future story
      ]}
      renderHeader={(paginationProps: any) => (
        <TableHeader className="flex-wrap align-items-center">
          <div className={isMobile ? 'd-flex flex-wrap ml-2 mr-auto' : 'd-flex flex-wrap mr-auto'}>
            <SizePerPage paginationProps={paginationProps} className={isMobile ? 'my-1' : ''} />
            <TableSearch placeholder="Search Children" className={isMobile ? 'my-1' : ''} onChange={handleSearchTerm} />
          </div>
          <div className="d-flex flex-wrap align-items-center">
            {/* status filter - future story */}
            {/* filters */}
            {isInternal && (
              <DropdownFilter
                title="Business"
                className={isMobile ? 'my-1 ml-2' : 'my-0 mr-2 ml-auto p-0'}
                selectedFilters={tableState.selectedFilters.entity}
                options={businessOptions}
                onFilterSelect={handleBusinessFilterSelect}
              />
            )}
            <DropdownFilter
              className={isMobile ? 'mt-2 pl-0 ml-2' : 'my-0 mx-2 p-0'}
              title={t('families.contacts.center-tags')}
              selectedFilters={tableState.selectedFilters.centerTags}
              options={centerTagOptions}
              onFilterSelect={(val) =>
                tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, centerTags: val })
              }
            />
            <DropdownFilter
              title={fieldLabels.center}
              className={isMobile ? 'my-1 pl-0 ml-2' : isInternal ? 'my-0 mr-2 ml-auto p-0' : 'my-0 mx-2 p-0'}
              selectedFilters={tableState.selectedFilters.center}
              options={centerOptions}
              onFilterSelect={handleCenterFilterSelect}
            />
            {!isInternal && (
              <DropdownFilter
                title="Child Tags"
                className={isMobile ? 'my-1 pl-0 ml-2' : 'my-0 mx-2 p-0'}
                selectedFilters={tableState.selectedFilters.tags}
                options={tagOptions}
                onFilterSelect={handleTagsFilterSelect}
              />
            )}
            <IconButtonCircle
              icon={faTimes}
              onClick={clearActiveFilters}
              tooltipDirection="bottom"
              tooltipText="Clear Filters"
              className={isMobile ? 'mt-1 mx-auto' : 'my-0 ml-2 p-0'}
            />
          </div>
        </TableHeader>
      )}
    />
  );
};

export default ChildrenTable;
