import { SET_IMPORTED_STAFF_DATA, RESET_IMPORTED_STAFF_DATA } from './types';

interface IImportedDataState {
  staff: IImportedStaffData;
}

const initialState: IImportedDataState = {
  staff: {
    created: [],
    invited: [],
  },
};

const importedDataReducer = (state = initialState, action: { type: string; staff: IStaff[] }) => {
  switch (action.type) {
    case SET_IMPORTED_STAFF_DATA: {
      return {
        ...state,
        staff: {
          created: action.staff
            ?.filter((s) => !s.invitedAt)
            .map((c) => {
              return { ...c, employmentStatus: 'Created' } as IStaff;
            }),
          invited: action.staff
            ?.filter((s) => s.invitedAt)
            .map((i) => {
              return { ...i, employmentStatus: 'Invited' } as IStaff;
            }),
        },
      };
    }
    case RESET_IMPORTED_STAFF_DATA: {
      return {
        ...state,
        staff: {},
      };
    }
    default:
      return state;
  }
};

export default importedDataReducer;
