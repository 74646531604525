import React, { useEffect } from 'react';
import useUniqueId from 'shared/hooks/useUniqueId';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle, faExclamationTriangle, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import HelpTooltip from '../Tooltip/HelpTooltip';
import colors from '_colors.module.scss';
import { isBlank } from 'shared/util/string';
import NumberFormat from 'react-number-format';
import { IInputNumberFormat } from './NumberInput';
import { InputGroup } from 'react-bootstrap';

export interface IBaseInputProps {
  id?: string;
  onChange?: (value: any, name: string) => void;
  onFocus?: any;
  onBlur?: any;
  autoFocus?: boolean;
  isInvalid?: boolean;
  validateIfNotTouched?: boolean;
  helperText?: string;
  required?: boolean;
  label?: string;
  name?: string;
  errorText?: object | string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode | null;
  value?: any;
  small?: boolean;
  placeholder?: string;
  as?: 'input' | 'textarea' | 'select';
  rows?: number;
  type?: string;
  helpTooltipText?: string;
  helpTooltipTextDirection?: 'left' | 'right' | 'top' | 'bottom';
  autoComplete?: string;
  componentLeft?: any;
  componentRight?: any;
  requiresValidation?: boolean; // per Liz the validation styling should only be applied to required fields or fields that require validation
  prepend?: string;
  step?: string;
  min?: string;
  maxlength?: string;
  pattern?: string;
  numberFormat?: IInputNumberFormat | null;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isFocusedFromParent?: boolean; // make input look focused based on state of parent component
  textAreaClass?: string;
}

/**
 * It is purposeful to have both appendText and appendNode as props. They both require different sets of styling that clash
 * with eachother. It is cleaner to just have two different properties than to have one and use conditionals repeatedly.
 * IMPORTANT: THIS COMPONENT IS MEANT TO BE PASSED WITH ONE OR THE OTHER HERE, NEVER BOTH.
 */

// Use this when you're looking to append static text to the end of the input box
export interface IInputWithAppendedTextProps extends IBaseInputProps {
  appendText?: string;
  appendNode?: never;
}

/**
 * Use this when passing a React Node.
 * NEVER use this to pass a string.
 */
export interface IInputWithAppendedNodeProps extends IBaseInputProps {
  appendText?: never;
  appendNode?: React.ReactNode;
}

export interface IInputHelperState {
  borderClass: 'success' | 'error' | 'focused' | '';
  icon: IconDefinition | null;
  iconColor: string | null;
}

const Input: React.FC<IInputWithAppendedNodeProps | IInputWithAppendedTextProps> = ({
  id,
  onChange,
  onFocus,
  onBlur,
  autoFocus,
  isInvalid,
  validateIfNotTouched,
  helperText,
  required,
  label,
  name = '',
  errorText,
  className,
  disabled,
  children,
  small,
  value = '',
  helpTooltipText,
  helpTooltipTextDirection = 'right',
  autoComplete = 'off',
  componentLeft = null,
  componentRight = null,
  requiresValidation,
  appendNode,
  appendText,
  prepend,
  numberFormat,
  type,
  isFocusedFromParent = false,
  textAreaClass,
  ...props
}) => {
  // controls whether to show the validation icons in the input. per Liz, the icon should be visible for required fields or fields that require validation
  const _requiresValidation = required || requiresValidation;
  const controlId = id ?? useUniqueId(id); // What is the point of this?

  const [isFocused, setFocus] = React.useState(autoFocus);
  const [touched, setTouched] = React.useState(autoFocus);
  const [inputHelperState, setInputHelperState] = React.useState<IInputHelperState>({
    borderClass: '',
    icon: null,
    iconColor: null,
  });

  const applyInputGroupClass = appendText || appendNode || prepend ? 'input-group' : '';
  let roundedCornersClass;
  let roundedCornersOverrideStyle = {};
  if ((appendText || appendNode) && prepend) {
    roundedCornersClass = '';
  } else if (appendText || appendNode) {
    roundedCornersClass = 'rounded-left';
    roundedCornersOverrideStyle = { borderTopRightRadius: 0, borderBottomRightRadius: 0 };
  } else if (prepend) {
    roundedCornersClass = 'rounded-right';
  } else {
    roundedCornersClass = 'rounded';
  }

  const handleChange = React.useCallback(
    (e) => {
      setTouched(true);
      onChange && onChange(e.target.value, e.target.name);
    },
    [onChange]
  );

  const handleFocus = React.useCallback(
    (e) => {
      /**
       * Added for AB#3078
       * Prevent the SyntheticEvent from losing its event context when it's wrapped with setTimeout
       * https://reactjs.org/docs/legacy-event-pooling.html
       */
      e.persist();

      // Schedule this to be run by the JS event loop next, to fix race-condition with handleChange
      // See work item defect-3059 and associated PR for more details
      setTimeout(() => {
        setFocus(true);
        onFocus && onFocus(e);
      });
    },
    [onFocus]
  );

  const handleBlur = React.useCallback(
    (e) => {
      setTouched(true);
      setFocus(false);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  useEffect(() => {
    if (isFocused || isFocusedFromParent) {
      setInputHelperState({
        borderClass: 'focused',
        icon: null,
        iconColor: null,
      });
    } else if (validateIfNotTouched || touched) {
      // if isInvalid is provided use that logic otherwise fallback to a checking if the string is blank
      const invalid =
        isInvalid ||
        (required &&
          (isBlank(value) ||
            value === undefined ||
            value === null ||
            (numberFormat?.min && value < numberFormat?.min) ||
            (numberFormat?.max && value > numberFormat?.max)));

      if (required && invalid) {
        setInputHelperState({
          borderClass: 'error',
          icon: faExclamationTriangle,
          iconColor: colors.danger,
        });
      } else if (_requiresValidation) {
        setInputHelperState({
          borderClass: invalid ? 'error' : 'success',
          icon: invalid ? faExclamationTriangle : faCheckCircle,
          iconColor: invalid ? colors.danger : colors.success,
        });
      } else {
        setInputHelperState({
          borderClass: '',
          icon: null,
          iconColor: null,
        });
      }
    }
  }, [touched, isInvalid, required, value, isFocused, _requiresValidation, isFocusedFromParent, numberFormat]);

  return (
    <Form.Group controlId={controlId} className={`${small ? 'small-input' : ''} ${className || ''}`}>
      <div className="d-flex flex-row">
        {label && <Form.Label>{label}</Form.Label>}
        {required && !disabled && label && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
        {helpTooltipText && <HelpTooltip text={helpTooltipText} direction={helpTooltipTextDirection} />}
      </div>
      <div className={`${applyInputGroupClass} flex-nowrap`}>
        {prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text>{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <div className="form-control__container flex-grow-1">
          <div
            className={` ${roundedCornersClass} d-flex flex-row form-control__container_inner ${
              inputHelperState.borderClass && 'form-control__container-' + inputHelperState.borderClass
            } ${disabled && 'disabled'} ${textAreaClass}`}
            style={roundedCornersOverrideStyle}
          >
            {componentLeft && <div className="d-flex ml-2 mr-n1 input-icon-container">{componentLeft}</div>}
            <div className="form-control__input_container">
              {numberFormat ? (
                <NumberFormat
                  customInput={Form.Control}
                  onValueChange={(obj, sourceInfo) => {
                    if (sourceInfo.source === 'prop') return;
                    setTouched(true);
                    if (onChange) onChange(numberFormat.isNumericString ? obj.value : obj.floatValue, name);
                  }}
                  thousandSeparator={numberFormat.thousandSeparator}
                  decimalScale={numberFormat.decimalScale}
                  fixedDecimalScale={numberFormat.fixedDecimalScale}
                  allowNegative={numberFormat.allowNegative ?? false}
                  mask={numberFormat.mask}
                  format={numberFormat.format}
                  isNumericString={numberFormat.isNumericString}
                  name={name}
                  value={value}
                  disabled={disabled}
                  required={required}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  autoFocus={autoFocus}
                  autoComplete={autoComplete}
                  className="border-0"
                  allowLeadingZeros={true}
                  {...props}
                />
              ) : (
                <Form.Control
                  name={name}
                  value={value}
                  disabled={disabled}
                  required={required}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  autoFocus={autoFocus}
                  autoComplete={autoComplete}
                  type={type}
                  className="border-0"
                  {...props}
                />
              )}
            </div>
            {!componentRight && _requiresValidation && inputHelperState.icon && (
              <div className="d-flex input-icon-container">
                <FontAwesomeIcon
                  className="input-icon"
                  icon={inputHelperState.icon}
                  color={`${inputHelperState.iconColor}`}
                />
              </div>
            )}
            {componentRight && <div className="d-flex input-icon-container">{componentRight}</div>}
          </div>
        </div>
        {(appendNode || appendText) && (
          <InputGroup.Append className="kt-input-group-append-btn">
            {appendNode ? appendNode : <InputGroup.Text>{appendText}</InputGroup.Text>}
          </InputGroup.Append>
        )}
      </div>
      {children}
      {helperText && (
        <Collapse in={isFocused}>
          <Form.Text className="text-muted">{helperText}</Form.Text>
        </Collapse>
      )}
      {isInvalid && touched && <div className="text-danger form-text small">{errorText}</div>}
    </Form.Group>
  );
};

export default Input;
