import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';

interface ICreateAltArrangement {
  createAlternateArrangement: IDebtArrangementResponse;
}

interface ICreateAltArrangementVariables {
  input: IAltArrangementInput;
}

export const CREATE_ALT_ARRANGEMENT = gql`
  mutation ($input: CreateAltArrangementInput!) {
    createAlternateArrangement(input: $input) {
      id
      status
    }
  }
`;

export const useCreateAltArrangement = (
  options?: MutationHookOptions<ICreateAltArrangement, ICreateAltArrangementVariables>
) => {
  return useMutation<ICreateAltArrangement, ICreateAltArrangementVariables>(CREATE_ALT_ARRANGEMENT, options);
};
