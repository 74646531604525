import { find } from 'lodash';
import moment from 'moment';
import React from 'react';
import ActionDropdown from 'shared/components/ActionDropdown';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { ccsEnrollmentStatus } from 'shared/constants/enums/ccssEnums';
import { getInitials, stringToHsl } from 'shared/util/string';

export interface CcsEnrolmentDetails {
  id: string;
  child: IAccountChild;
  enrolment: ICcsEnrolment;
  previousEnrolments: CcsEnrolmentDetails[];
}

interface ITableProps {
  data: CcsEnrolmentDetails[];
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
}

const dateFormat = 'DD/MM/YYYY';

const CcsTable: React.FC<ITableProps> = ({ data, expandRow, loading }) => {
  return (
    <DataTable
      noPadding={true}
      data={data}
      expandRow={expandRow}
      showLoadingOverlay={loading}
      showSelect={false}
      showPagination={false}
      expandHeaderColumnRenderer={(props) => <></>} // We don't have a table header so hide it
      columns={[
        {
          headerAttrs: {
            hidden: true,
          },
          text: '',
          dataField: 'avatar',
          sort: true,
          formatter: (cell: string, row: CcsEnrolmentDetails) => (
            <AvatarDataTableCell
              color={stringToHsl(row.child.id)}
              initials={getInitials(row.child)}
              avatar={row.child.avatar?.url ?? ''}
              primaryText={row.child.fullName}
            />
          ),
        },
        {
          text: '',
          dataField: 'arrangementType',
          formatter: (cell: string, row: CcsEnrolmentDetails) => `${row.enrolment.arrangementType}`,
        },
        {
          text: '',
          dataField: 'enrolmentId',
          formatter: (cell: string, row: CcsEnrolmentDetails) => `${row.enrolment.enrolmentId}`,
        },
        {
          text: '',
          dataField: 'arrangementEndDate',
          formatter: (cell: string, row: CcsEnrolmentDetails) => {
            const endDateLabel = row.enrolment.endDate ? moment(row.enrolment.endDate).format(dateFormat) : 'Ongoing';
            return `${moment(row.enrolment.arrangementStartDate).format(dateFormat)} - ${endDateLabel}`;
          },
        },
        {
          text: '',
          dataField: 'status',
          formatter: (cell: string, row: CcsEnrolmentDetails) =>
            `${find(ccsEnrollmentStatus, (status) => status.value === row.enrolment.status)?.label ?? 'Unknown'}`,
        },
      ]}
    />
  );
};

export default CcsTable;
