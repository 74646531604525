import React, { useCallback, useMemo, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import AddContactDemographicForm from './AddContactDemographicForm';
import AddContactConfigurations from './AddContactConfigurations';
import { somePropsEmpty } from 'shared/util/object';
import { isBlank, isValidPhoneNumber } from 'shared/util/string';
import { isEmailValid } from 'shared/util/email';
import { isRegion } from 'shared/util/region';
import { useAddNewContactToAccount } from 'gql/account/mutations';
import { useGetChildContactPermissions } from 'gql/contact/queries';
import showToast from 'shared/components/Toast/showToast';

import { useFlags } from 'launchdarkly-react-client-sdk';

export interface INewContactWithPermissions {
  firstname: string;
  lastname: string;
  email: string;
  primaryPhoneNumber: IPhoneNumber;
  childPermissions: IAccountContactChildInput[];
  accountPermissions: AccountPermissions[];
  isPrimary: boolean;
  existingContact: boolean;
  existingContactId: string | null;
}

const newContact: INewContactWithPermissions = {
  firstname: '',
  lastname: '',
  email: '',
  primaryPhoneNumber: {
    number: '',
    type: undefined,
  },
  existingContact: false,
  existingContactId: null,
  childPermissions: [],
  accountPermissions: [],
  isPrimary: false,
};

interface IProps {
  isOpen: boolean;
  account: IAccount;
  onClose: () => void;
  setRemovedContact: (p: null) => void;
}

export enum AddContactStep {
  TypeRelationshipsPermissions,
  Demographics,
}

const AddContactModalForm: React.FC<IProps> = ({ isOpen, account, onClose, setRemovedContact, ...props }) => {
  const { k2SendAppInvite } = useFlags();
  const isAuRegion = isRegion('AU');
  const [addContactStep, setAddContactStep] = useState<AddContactStep>(AddContactStep.Demographics);
  const [sendInvite, setSendInvite] = useState(k2SendAppInvite ?? false);
  const [addNewContactFnc, { loading }] = useAddNewContactToAccount(account.id);
  const { data: getChildContactPermissionsData } = useGetChildContactPermissions();
  const permissionsOptions = useMemo(
    () => getChildContactPermissionsData?.getChildContactRelationshipPermissions ?? [],
    [getChildContactPermissionsData?.getChildContactRelationshipPermissions]
  );
  const defaultChildPermissions = useMemo(
    () =>
      account.children?.map((c) => ({
        childId: c.id,
        relationship: '',
        permissions: permissionsOptions.map((permission) => permission.value),
      })) ?? [],
    [account.children, permissionsOptions]
  );
  const archivedChildren =
    account?.children?.filter((child) => Boolean(child.archivedAt)).map((child) => child.id) ?? [];

  const [contact, setContact] = useState<INewContactWithPermissions>({
    ...newContact,
    childPermissions: defaultChildPermissions.filter((c) => archivedChildren.includes(c.childId)),
  });

  const validateDemographicForm = useCallback(
    (data: INewContactWithPermissions): boolean | string => {
      const excludeInCheck = isAuRegion
        ? ['email', 'lastname', 'childPermissions', 'accountPermissions', 'existingContactId']
        : ['email', 'childPermissions', 'accountPermissions', 'existingContactId'];

      if (data.existingContact) {
        return !!data.existingContactId;
      }

      return (
        !somePropsEmpty(data, excludeInCheck) &&
        !somePropsEmpty(data.primaryPhoneNumber ?? {}) &&
        isValidPhoneNumber(data.primaryPhoneNumber?.number ?? '') &&
        (!data.isPrimary || data.email) &&
        (!data.email || isEmailValid(data.email))
      );
    },
    [isAuRegion]
  );

  const validateRelationships = useCallback((data: INewContactWithPermissions) => {
    return data.childPermissions.every((p) => !isBlank(p.relationship));
  }, []);

  const handleClose = useCallback(() => {
    setContact((prev) => newContact);
    setAddContactStep(AddContactStep.Demographics);
    onClose();
  }, [onClose]);

  const goBack = useCallback(() => {
    setAddContactStep(AddContactStep.Demographics);
  }, []);

  const handleSubmit = useCallback(() => {
    const {
      firstname,
      lastname,
      email,
      primaryPhoneNumber,
      childPermissions,
      isPrimary,
      accountPermissions,
      existingContactId = null,
    } = contact;
    addNewContactFnc({
      variables: {
        input: {
          accountId: account.id,
          contact: existingContactId
            ? null
            : {
                firstname,
                lastname,
                email,
                primaryPhoneNumber,
                entityId: account.entityId,
              },
          existingContactId,
          children: childPermissions,
          isPrimary,
          permissions: accountPermissions.map((p) => ({ type: p })),
          sendInvite: sendInvite,
        },
      },
    })
      .then(() => {
        showToast('Contact added successfully.', 'success');
        existingContactId && setRemovedContact(null);
        handleClose();
      })
      .catch(() => {
        showToast('There was an error adding contact.', 'error');
      });
  }, [contact, addNewContactFnc, account.id, account.entityId, handleClose]);

  const renderFormStep = (): React.ReactNode => {
    switch (addContactStep) {
      case AddContactStep.TypeRelationshipsPermissions:
        return <AddContactConfigurations contact={contact} setContact={setContact} account={account} />;
      default:
        return (
          <AddContactDemographicForm
            contact={contact}
            setContact={setContact}
            account={account}
            sendInvite={sendInvite}
            sendInviteToggle={() => setSendInvite(!sendInvite)}
          />
        );
    }
  };

  return (
    <SideModalDrawer
      title={`Add Contact`}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={addContactStep === AddContactStep.TypeRelationshipsPermissions}
      closeOnSecondaryCallback={addContactStep === AddContactStep.Demographics}
      primaryChoice={addContactStep === AddContactStep.Demographics ? 'Continue' : 'Save'}
      primaryCallback={
        addContactStep === AddContactStep.Demographics
          ? () => setAddContactStep(AddContactStep.TypeRelationshipsPermissions)
          : handleSubmit
      }
      primaryButtonProps={
        addContactStep === AddContactStep.Demographics
          ? { disabled: !validateDemographicForm(contact) || (!isEmailValid(contact.email) && contact.isPrimary) }
          : { disabled: !validateDemographicForm(contact) || !validateRelationships(contact) }
      }
      secondaryChoice={addContactStep === AddContactStep.Demographics ? 'Cancel' : 'Back'}
      secondaryCallback={addContactStep === AddContactStep.Demographics ? handleClose : goBack}
      className="wide-side-modal"
    >
      {renderFormStep()}
    </SideModalDrawer>
  );
};

export default AddContactModalForm;
