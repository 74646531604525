import React, { Fragment } from 'react';
import Line from '../Line';
import './datatable.scss';

const DataTableLoadingSkeleton = ({ ...props }) => {
  return (
    <Fragment>
      {[0, 1, 2].map((i) => (
        <div key={i} className="d-flex align-items-center datatable-loading-skeleton-row">
          <Line />
        </div>
      ))}
    </Fragment>
  );
};

export default DataTableLoadingSkeleton;
