import { gql } from '@apollo/client';
import { NOTIFICATION_FIELDS } from './fields';
import { useK2Mutation } from '../../shared/apis/k2Api';
import { MutationHookOptions } from '@apollo/client';

export const MARK_NOTIFICATIONS = gql`
  mutation ($notificationId: UUID!, $archived: Boolean, $read: Boolean) {
    markNotification(notificationId: $notificationId, archived: $archived, read: $read) {
      ...NotificationFields
    }
  }
  ${NOTIFICATION_FIELDS}
`;

export const MarkNotificationsTogether = gql`
  mutation ($notificationIds: [UUID!]!, $archived: Boolean, $read: Boolean) {
    bulkMarkNotifications(notificationIds: $notificationIds, archived: $archived, read: $read) {
      ...NotificationFields
    }
  }
  ${NOTIFICATION_FIELDS}
`;

export interface IMarkNotificationData {
  markNotification: INotificationV2;
}

export interface IMarkNotificationVariables {
  notificationId: string;
  archived?: boolean;
  read?: boolean;
}

export interface IMarkNotificationsTogetherVariables {
  notificationIds: string[];
  archived?: boolean;
  read?: boolean;
}

export const useMarkNotificationV2 = (
  options?: MutationHookOptions<IMarkNotificationData, IMarkNotificationVariables>
) => {
  return useK2Mutation<IMarkNotificationData, IMarkNotificationVariables>(MARK_NOTIFICATIONS, options);
};

export const useMarkNotificationsTogetherV2 = (
  options?: MutationHookOptions<any, IMarkNotificationsTogetherVariables>
) => {
  return useK2Mutation<any, IMarkNotificationsTogetherVariables>(MarkNotificationsTogether, options);
};
