import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

interface IWindowSizes {
  innerWidth: number;
  innerHeight: number;
  outerWidth: number;
  outerHeight: number;
}

const windowSizes = (): IWindowSizes => ({
  innerWidth: window.innerWidth,
  innerHeight: window.innerHeight,
  outerWidth: window.outerWidth,
  outerHeight: window.outerHeight,
});

const useWindowSize = (): IWindowSizes => {
  const [windowSize, setWindowSize] = useState<IWindowSizes>(windowSizes());

  useEffect(() => {
    window.addEventListener(
      'resize',
      debounce(() => setWindowSize(windowSizes()), 150)
    );

    return () => {
      window.removeEventListener('resize', () => setWindowSize(windowSizes()));
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
