import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import classnames from 'classnames';
import moment from 'moment';
import Switch from 'shared/components/Switch/Switch';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TimeRangePicker2 from 'shared/components/TimePicker/TimeRangePicker2';
import { IconButtonCircle, IconButton } from 'shared/components/Buttons';
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import errorMessages from 'shared/constants/errorMessages';
import { ITimeRange, IAvailabilityObject } from './AvailabilityInputs';
import { DayOfWeek } from '../../constants/enums/dayOfWeekEnum';

interface IProps {
  availability: IAvailabilityObject;
  updateAvailability: React.Dispatch<React.SetStateAction<IAvailabilityObject>>;
  addTime: (day: DayOfWeek) => void;
  removeTime: (day: DayOfWeek, idx: number) => void;
  updateTime: (
    day: DayOfWeek,
    idx: number,
    time: {
      start: moment.Moment | null;
      end: moment.Moment | null;
      startTimeString: string;
      endTimeString: string;
    }
  ) => void;
  timesOverlap: (t1: ITimeRange, t2: ITimeRange) => boolean;
  updateDayEnabled: (day: DayOfWeek, value: boolean) => void;
}

const MobileAvailabilityInputs: React.FC<IProps> = ({
  availability,
  addTime,
  removeTime,
  updateTime,
  timesOverlap,
  updateDayEnabled,
}) => {
  // @ts-ignore
  const [selectedDay, setSelectedDay] = useState<DayOfWeek>('MONDAY');

  return (
    <div className="kt-availability-inputs">
      <div className="d-flex d-lg-none flex-column mb-4">
        <ButtonGroup className="kt-availability-inputs-day-toggles mb-4" aria-label="Weekday selection">
          {[
            { label: 'M', value: DayOfWeek.MONDAY },
            { label: 'Tu', value: DayOfWeek.TUESDAY },
            { label: 'W', value: DayOfWeek.WEDNESDAY },
            { label: 'Th', value: DayOfWeek.THURSDAY },
            { label: 'F', value: DayOfWeek.FRIDAY },
          ].map((day, idx) => {
            return (
              <Button
                key={`availability-input-${day.value}-${idx}`}
                id={`availability-input-${day.value.toLowerCase()}-btn`}
                className={classnames({
                  'has-data': selectedDay !== day.value && Boolean(availability[day.value].dayEnabled),
                })}
                variant={selectedDay === day.value ? 'secondary' : 'light'}
                onClick={() => setSelectedDay(day.value)}
              >
                {day.label}
              </Button>
            );
          })}
        </ButtonGroup>
        <div className="kt-availability-inputs-single-day-container">
          <div className="d-flex flex-row align-items-center mb-4 w-100">
            <span className="d-block mr-auto">Available</span>
            <Switch
              height={32}
              value={availability[selectedDay].dayEnabled}
              onChange={(value: boolean) => updateDayEnabled(selectedDay, value)}
              className="mb-2"
            />
          </div>
          <Row>
            <Col>
              {availability[selectedDay].dayEnabled && (
                <div>
                  {availability[selectedDay].times.map((time, index) => (
                    <div key={index} className="d-flex flex-grow-1 mb-2 kt-availability-inputs-time-range-container ">
                      <TimeRangePicker2
                        start={time.startTimeString ?? null}
                        end={time.endTimeString ?? null}
                        onChange={(start: string | null, end: string | null) => {
                          const [startHours, startMinutes] = (start ?? '').split(':');
                          const [endHours, endMinutes] = (end ?? '').split(':');

                          updateTime(selectedDay, index, {
                            start: start
                              ? time.start.clone().set({ h: parseInt(startHours, 10), m: parseInt(startMinutes, 10) })
                              : null,
                            end: end
                              ? time.end.clone().set({ h: parseInt(endHours, 10), m: parseInt(endMinutes, 10) })
                              : null,
                            startTimeString: start ?? '',
                            endTimeString: end ?? '',
                          });
                        }}
                      />
                      {index === availability[selectedDay].times.length - 1 ? (
                        <IconButtonCircle
                          size="sm"
                          className="ml-4 my-1"
                          backgroundColor="secondary"
                          color="white"
                          icon={faPlus}
                          tooltipText="Add New Split Shift"
                          onClick={() => addTime(selectedDay)}
                        />
                      ) : (
                        <IconButton
                          className="ml-4 sm-icon-width my-1"
                          icon={faTrashAlt}
                          tooltipText="Remove Split Shift"
                          onClick={() => removeTime(selectedDay, index)}
                        />
                      )}
                    </div>
                  ))}
                  {availability[selectedDay].times.some((t1, i1) =>
                    availability[selectedDay].times.some((t2, i2) => i1 !== i2 && timesOverlap(t1, t2))
                  ) && <small className="text-danger">{errorMessages.overlappingTimes}</small>}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MobileAvailabilityInputs;
