import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

export const GET_CENTER_CLASSES_WITH_AVAILABILITY = gql`
  query (
    $centerId: String!
    $startDate: Date!
    $endDate: Date!
    $includeLtbOfferings: Boolean!
    $includeFutureContracts: Boolean!
  ) {
    getClassesForCenter(centerId: $centerId, startDate: $startDate) {
      id
      centerId
      name
      startsAt
      endsAt
      archivedAt
      colorCode
      capacity
      regulation {
        id
        state
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        capacity
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        capacity
      }
      availabilityForTimeframe(
        startDate: $startDate
        endDate: $endDate
        includeLtbOfferings: $includeLtbOfferings
        includeFutureContracts: $includeFutureContracts
      ) {
        date
        openSpotsAm
        openSpotsPm
        totalOpenSpots
        ageUpAm
        ageUpPm
        totalAgeUp
        isFullDayUtilization
      }
    }
  }
`;

export const useGetCenterAvailability = (
  centerId: string,
  startDate: string,
  endDate: string,
  includeLtbOffers: boolean,
  includeFutureContracts: boolean
) => {
  return useQuery<
    { getClassesForCenter: IClass[] },
    {
      centerId: string;
      startDate: string;
      endDate: string;
      includeLtbOfferings: boolean;
      includeFutureContracts: boolean;
    }
  >(GET_CENTER_CLASSES_WITH_AVAILABILITY, {
    variables: {
      centerId,
      startDate,
      endDate,
      includeFutureContracts,
      includeLtbOfferings: includeLtbOffers,
    },
    skip: !centerId || !startDate || !endDate,
    fetchPolicy: 'network-only',
  });
};
