import React from 'react';
import _, { isEmpty, orderBy } from 'lodash';

import * as Mui from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { useSearchComments } from 'gql/comments/query';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFormatDate from 'shared/hooks/useFormatDate';
import { getFullName } from 'shared/util/string';
import { ButtonAsLink } from 'shared/components/Buttons';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTranslation } from 'react-i18next';
import CreateCommentInModal from 'shared/components/Comments/CreateCommentInModal';
import RightModalDrawer from 'shared/components/MUI/RightModalDrawer';

interface IComment {
  id: string;
  businessId: string;
  attachedToEntityId: string;
  parentId: string;
  text: string;
  createdBy: string;
  createdAt: string;
  replyCount: number;
  createdByAccount: IStaff;
}

interface IProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  reloadNotes: () => void;
  note: INote;
}

interface INote {
  id: string;
  businessId: string;
  areaType: string;
  attachedToEntityId: string;
  title: string;
  noteType: string;
  comment: string;
  createdBy: string;
  createdAt: string;
  associatedWith: INoteAssociation[];
  createdByAccount: IStaff;
}

type NoteAssociationObjectType = 'Account' | 'AccountChild' | 'AccountContact' | 'Staff';

interface INoteAssociation {
  objectId: string;
  objectType: NoteAssociationObjectType;
  display?: {
    displayValue: string;
    displayAvatar?: string;
  };
}

const ViewNoteThread: React.FC<IProps> = ({ open, setOpen, note, reloadNotes }) => {
  const { t } = useTranslation();
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const formatDate = useFormatDate();
  const [from, setFrom] = React.useState(1);
  const [comments, setComments] = React.useState<IComment[] | []>([]);

  const {
    data: commentsData,
    loading: loadingComments,
    refetch: refetchComments,
  } = useSearchComments({
    skip: isEmpty(businessId),
    variables: {
      input: {
        businessId: businessId,
        attachedToEntityId: note.id,
        from: from,
        size: 5,
        sortField: 'CreatedAt',
        sortDirection: 'DESCENDING',
      },
    },
  });

  React.useEffect(() => {
    setComments(_.uniqBy([...comments, ...(commentsData?.searchComment.data ?? [])], 'id'));
  }, [commentsData]);

  return (
    <>
      <RightModalDrawer open={open} onClose={() => setOpen(false)} title={note.title}>
        <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '16px' }}>
          <Mui.Stack direction="column" spacing={2} marginLeft="16px">
            <Mui.Box>
              <Mui.Typography variant="body1" fontSize="18px" fontWeight={300}>
                Title:
              </Mui.Typography>
              <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                <Mui.Typography variant="body1" fontSize="18px">
                  {note.title}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
            <Mui.Box>
              <Mui.Typography variant="body1" fontSize="18px" fontWeight={300}>
                Type:
              </Mui.Typography>
              <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                <Mui.Typography variant="body1" fontSize="18px">
                  {note.noteType === 'Behavior' ? t('internal-notes.capital-behavior') : note.noteType}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
            {(note.associatedWith?.length ?? 0) > 0 && (
              <Mui.Box>
                <Mui.Typography variant="body1" fontSize="18px" fontWeight={300}>
                  Contact / Child:
                </Mui.Typography>
                <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                  {(note.associatedWith?.length ?? 0) == 0 && (
                    <Mui.Typography variant="body1" fontSize="18px">
                      &nbsp;
                    </Mui.Typography>
                  )}
                  {note.associatedWith.map((option) => (
                    <Mui.Box key={option.objectId} paddingBottom={note.associatedWith.length > 1 ? '4px' : '0px'}>
                      <AvatarDataTableCell
                        initials={option.display?.displayValue.substring(0, 1) ?? ''}
                        avatar={option.display?.displayAvatar ?? ''}
                        primaryText={option.display?.displayValue}
                        size="sm"
                      />
                    </Mui.Box>
                  ))}
                </Mui.Box>
              </Mui.Box>
            )}

            <Mui.Box>
              <Mui.Typography variant="body1" fontSize="18px" fontWeight={300}>
                Note:
              </Mui.Typography>
              <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                <Mui.Typography variant="body1" fontSize="18px">
                  {note.comment}
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop="4px"
                paddingBottom={2}
              >
                <Mui.Typography paddingTop="4px">
                  <AvatarDataTableCell
                    initials={note.createdByAccount.firstname.substring(0, 1) ?? ''}
                    avatar={note.createdByAccount.avatar?.url ?? ''}
                    size="sm"
                    primaryText={getFullName(note.createdByAccount)}
                  />
                </Mui.Typography>

                <Mui.Typography color="#8E9BAC">{formatDate(note.createdAt, 'MM/DD/YYYY')}</Mui.Typography>
              </Mui.Box>
            </Mui.Box>
          </Mui.Stack>
          <Mui.Box paddingLeft="24px" paddingTop="24px">
            <Mui.Box borderLeft="4px solid #C5D0DE" paddingLeft="16px">
              {orderBy(comments, ['createdAt'], ['desc']).map((comment, index) => (
                <Mui.Box key={comment.id} paddingBottom="16px">
                  <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                    <Mui.Typography>{comment.text}</Mui.Typography>
                  </Mui.Box>
                  <Mui.Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingTop="4px"
                    paddingBottom={2}
                  >
                    <Mui.Typography display="flex" alignItems="center">
                      <AvatarDataTableCell
                        initials={comment.createdByAccount.firstname.substring(0, 1) ?? ''}
                        avatar={comment.createdByAccount.avatar?.url ?? ''}
                        size="sm"
                      />
                      {getFullName(comment.createdByAccount)}
                    </Mui.Typography>
                    <Mui.Typography color="#8E9BAC">{formatDate(comment.createdAt, 'MM/DD/YYYY')}</Mui.Typography>
                  </Mui.Box>
                </Mui.Box>
              ))}
            </Mui.Box>
            {comments.length < (commentsData?.searchComment.totalRecords ?? 0) && (
              <Mui.Box display="flex" justifyContent="center">
                <ButtonAsLink className="text-secondary" onClick={() => setFrom(from + 1)}>
                  <strong>Load More Comments</strong>
                </ButtonAsLink>
              </Mui.Box>
            )}
          </Mui.Box>
        </Mui.DialogContent>

        <HasRoleAreaLevel
          action={{ area: AreaType.Account, permission: PermissionType.InternalNotes, level: RoleLevelType.Create }}
        >
          <CreateCommentInModal refetchComments={refetchComments} attachedToId={note.id} />
        </HasRoleAreaLevel>
      </RightModalDrawer>
    </>
  );
};

export default ViewNoteThread;
