import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { determinationFieldsWithPrevious } from './fields';

export const CREATE_CCSS_DETERMINATION = gql`
  mutation($input: CreateCcssDeterminationInput!) {
    createCcssDetermination(input: $input) {
      ${determinationFieldsWithPrevious}
    }
  }
`;

export const useCreateCcssDetermination = () => {
  return useMutation<{ createCcssDetermination: ICcssDetermination[] }, { input: ICreateCcssDeterminationInput }>(
    CREATE_CCSS_DETERMINATION,
    {}
  );
};
