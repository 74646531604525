export const GET_CHILDREN_SUCCESS = 'GET_CHILDREN_SUCCESS';
export const GET_CHILD_BY_ID_SUCCESS = 'GET_CHILD_BY_ID_SUCCESS';

export const ADD_CHILD_SUCCESS = 'ADD_CHILD_SUCCESS';
export const UPDATE_CHILD_SUCCESS = 'UPDATE_CHILD_SUCCESS';

//CONTACTS
export const ADD_CONTACT_FOR_CHILD = 'ADD_CONTACT_FOR_CHILD';
export const REMOVE_CHILD_CONTACT_RELATIONSHIP = 'REMOVE_CHILD_CONTACT_RELATIONSHIP';
export const UPDATE_CONTACT_FOR_CHILD = 'UPDATE_CONTACT_FOR_CHILD';

// WELLNESS
export const GET_CHILD_WELLNESS_DATA_SUCCESS = 'GET_CHILD_WELLNESS_DATA_SUCCESS';
export const GET_CHILD_IMMUNIZATION_DATA_SUCCESS = 'GET_CHILD_IMMUNIZATION_DATA_SUCCESS';

export const CREATE_MEDICAL_CONDITION_FOR_CHILD = 'CREATE_MEDICAL_CONDITION_FOR_CHILD';
export const UPDATE_MEDICAL_CONDITION_FOR_CHILD = 'UPDATE_MEDICAL_CONDITION_FOR_CHILD';

export const CREATE_ALLERGY_FOR_CHILD = 'CREATE_ALLERGY_FOR_CHILD';
export const UPDATE_ALLERGY_FOR_CHILD = 'UPDATE_ALLERGY_FOR_CHILD';

export const CREATE_CHILD_RESTRICTION = 'CREATE_CHILD_RESTRICTION';
export const UPDATE_CHILD_RESTRICTION = 'UPDATE_CHILD_RESTRICTION';

export const CREATE_CHILD_IMMUNIZATION = 'CREATE_CHILD_IMMUNIZATION';
export const UPDATE_IMMUNIZATION = 'UPDATE_IMMUNIZATION';

export const UPDATE_CHILD_DOCUMENTS = 'UPDATE_CHILD_DOCUMENTS';
export const DELETE_CHILD_DOCUMENTS = 'DELETE_CHILD_DOCUMENTS';

export const DELETE_CHILD_IMMUNIZATION = 'DELETE_CHILD_IMMUNIZATION';

export const UPDATE_CONTACT_CHILD_RELATIONSHIP = 'UPDATE_CONTACT_CHILD_RELATIONSHIP';

interface IRemoveChildContactRelationshipAction {
  type: typeof REMOVE_CHILD_CONTACT_RELATIONSHIP;
  childId: string;
  contactId: string;
}

interface ICreateMedicalConditionForChildAction {
  type: typeof CREATE_MEDICAL_CONDITION_FOR_CHILD;
  condition: IMedicalCondition;
}

interface IUpdateMedicalConditionForChildAction {
  type: typeof UPDATE_MEDICAL_CONDITION_FOR_CHILD;
  condition: IMedicalCondition;
}

interface ICreateAllergyForChildAction {
  type: typeof CREATE_ALLERGY_FOR_CHILD;
  allergy: IAllergy;
}

interface IUpdateAllergyForChildAction {
  type: typeof UPDATE_ALLERGY_FOR_CHILD;
  allergy: IAllergy;
}

interface ICreateChildRestrictionAction {
  type: typeof CREATE_CHILD_RESTRICTION;
  childId: string;
  restriction: IRestriction;
}

interface IUpdateChildRestrictionAction {
  type: typeof UPDATE_CHILD_RESTRICTION;
  childId: string;
  restriction: IRestriction;
}

interface IUpdateChildDocumentsAction {
  type: typeof UPDATE_CHILD_DOCUMENTS;
  childId: string;
  documents: IWellnessDocument[];
}

interface IRemoveChildDocumentsAction {
  type: typeof DELETE_CHILD_DOCUMENTS;
  childId: string;
  documentIds: string[];
}

interface ICreateImmunizationAction {
  type: typeof CREATE_CHILD_IMMUNIZATION;
  immunizations: IImmunization[];
}

interface IDeleteChildImmunizationAction {
  type: typeof DELETE_CHILD_IMMUNIZATION;
  immunization: IImmunization;
}

interface IUpdateImmunizationAction {
  type: typeof UPDATE_IMMUNIZATION;
  immunization: IImmunization;
}

interface IUpdateContactChildRelationshipAction {
  type: typeof UPDATE_CONTACT_CHILD_RELATIONSHIP;
  contactId: string;
  childId: string;
  relationship: string;
}

export type ChildActionTypes =
  | { type: typeof GET_CHILDREN_SUCCESS; children: IChild[] }
  | { type: typeof GET_CHILD_BY_ID_SUCCESS; child: IChild }
  | { type: typeof GET_CHILD_WELLNESS_DATA_SUCCESS; child: IChild }
  | { type: typeof GET_CHILD_IMMUNIZATION_DATA_SUCCESS; child: IChild }
  | { type: typeof ADD_CHILD_SUCCESS; child: IChild }
  | { type: typeof UPDATE_CHILD_SUCCESS; child: IChild }
  | IRemoveChildContactRelationshipAction
  | { type: typeof ADD_CONTACT_FOR_CHILD; contact: IChildContact }
  | { type: typeof UPDATE_CONTACT_FOR_CHILD; contact: IChildContact }
  | ICreateMedicalConditionForChildAction
  | IUpdateMedicalConditionForChildAction
  | ICreateAllergyForChildAction
  | IUpdateAllergyForChildAction
  | ICreateChildRestrictionAction
  | IUpdateChildRestrictionAction
  | IUpdateChildDocumentsAction
  | IRemoveChildDocumentsAction
  | ICreateImmunizationAction
  | IDeleteChildImmunizationAction
  | IUpdateImmunizationAction
  | IUpdateContactChildRelationshipAction;
