import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import _ from 'lodash';

interface IProps {
  approvedFilter: boolean | null;
  photo: IPhoto;
  onGalleryItemClick: (item: IPhoto) => void;
  isSelected: boolean;
}

const Video: React.FC<IProps> = ({ approvedFilter, photo, onGalleryItemClick, isSelected }) => {
  return (
    <div onClick={() => onGalleryItemClick(photo)}>
      <img
        style={{ objectFit: 'cover' }}
        alt={`${photo.createdAt}-${photo.note}`}
        src={
          _.isNil(photo.thumbnailKeySignedUrl)
            ? 'https://img.myloview.com/stickers/social-media-video-icon-outline-social-media-video-vector-icon-for-web-design-isolated-on-white-background-700-247279065.jpg'
            : photo.thumbnailKeySignedUrl
        }
        height={250}
        width={250}
      />
      {/* <ReactPlayer playsinline={true} light={true} url={photo.signedurl} /> */}
      {approvedFilter == null && isSelected && (
        <div style={{ left: 0, top: 0 }} className="position-absolute h-100 w-100 d-flex align-items-center">
          <div style={{ opacity: 0.5 }} className="bg-success position-absolute h-100 w-100" />
          <FontAwesomeIcon style={{ zIndex: 2 }} className="mx-auto" size="7x" icon={faCheck} color="white" />
        </div>
      )}
    </div>
  );
};

export default Video;
