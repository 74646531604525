import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import momentTz from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons';
import Avatar from 'shared/components/Avatar';
import { RootState } from 'store/reducers';
import colors from '_colors.module.scss';
import ScheduleViewContext from '../../providers/scheduleViewProvider';
import { getFullName, getInitials } from 'shared/util/string';

interface IProps {
  colorCode: string | null;
  shift: IShift;
  className?: string;
  onClick: (shift: IShift) => void;
}

// TODO: change border color to match staff's class assignment color, not the shift's class color

/**
 * This component provides information about a single shift. It should be rendered as a child of <ClassScheduleDay />
 */
const ClassShift: React.FC<IProps> = ({ colorCode, shift, onClick, className = '', ...props }) => {
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const shiftTimezone = timezonesByCenter[shift.centerId] ?? momentTz.tz.guess();
  const unassignedShift: boolean = !shift.personId;
  const title = unassignedShift ? 'Unassigned' : getFullName(shift.person);
  const initials = unassignedShift ? 'U' : getInitials(shift.person);
  const timeframe = `${momentTz(shift.startTime).tz(shiftTimezone).format('h:mm A')} - ${momentTz(shift.endTime)
    .tz(shiftTimezone)
    .format('h:mm A')}`;

  const shiftLengthString = useCallback((amount: number): string => {
    switch (amount) {
      case 0:
        return 'No break';
      case 60:
        return '1 hour break';
      default:
        return `${amount} minute break`;
    }
  }, []);

  return (
    <div
      className={classnames('kt-schedules-class-shift', className)}
      style={{ borderLeft: colorCode ? `4px solid ${colorCode}` : '' }}
      title={`${title} ${timeframe}`}
      onClick={() => onClick(shift)}
    >
      {unassignedShift ? (
        <FontAwesomeIcon
          className="d-print-none"
          icon={faUserCircle}
          color={colors.dark}
          style={{ height: 32, width: 32 }}
        /> // inline styles to force the icon to be the same size as a regular avatar
      ) : (
        <Avatar
          size="sm"
          initials={initials}
          image={shift.person?.avatar?.url ?? ''}
          className="d-block d-print-none"
        />
      )}
      <ScheduleViewContext.Consumer>
        {(value) => (
          <div className="ml-1">
            <span>{title}</span>
            <small className="d-block">{value === 'SHIFTS' ? timeframe : shiftLengthString(shift.breakMinutes)}</small>
          </div>
        )}
      </ScheduleViewContext.Consumer>
    </div>
  );
};

export default ClassShift;
