import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import colors from '_colors.module.scss';
import Button from '../Buttons';

interface TimeLogNoticeModalProps extends ModalProps {
  proceedFnCallback: () => void;
  cancelFnCallback: () => void;
}

export const TimeLogNoticeModal: React.FC<TimeLogNoticeModalProps> = ({
  proceedFnCallback,
  cancelFnCallback,
  ...props
}) => {
  const history = useHistory();
  return (
    <Modal {...props} centered backdrop="static">
      <Modal.Header closeButton onHide={cancelFnCallback} className="border-bottom-0 pb-4">
        <div className="d-flex flex-column">
          <Modal.Title as="h3">
            <FontAwesomeIcon icon={faExclamationCircle} color={colors.danger} size="1x" />
            <span className="ml-2">Notice</span>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p>Time logs should be locked to prevent payroll discrepancies.</p>

        <a
          href="#"
          className="text-decoration-secondary-underline"
          onClick={() => history.push(`/settings/center/operations/time-log-locking`)}
        >
          Manage time log locks
        </a>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div className="ml-auto">
          <Button id="centered-modal-secondary-btn" className="mr-3" variant="light" onClick={cancelFnCallback}>
            Cancel
          </Button>
          <Button id="centered-modal-primary-btn" variant="primary" onClick={proceedFnCallback}>
            Proceed to Report
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
