import React from 'react';
import Spinner from 'shared/components/Spinner';

class MicroFrontend extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingBundle: false,
    };
  }

  componentDidMount() {
    const { document } = this.props;
    const scriptId = this.getScriptId();

    if (document.getElementById(scriptId)) {
      this.renderMicroFrontend();
      return;
    }

    this.fetchMicrofrontend();
  }

  componentWillUnmount() {
    const { name, window } = this.props;
    if (!!window[`unmount${name}`]) window[`unmount${name}`](`${name}-container`);
  }

  getScriptId = () => `micro-frontend-script-${this.props.name}`;

  fetchMicrofrontend = () => {
    const { host, document } = this.props;
    const scriptId = this.getScriptId();
    const etag = `${new Date().toISOString().slice(0, 15)}0`;

    // a script tag with the microservice bundle has already been added to the dom, don't refetch
    if (document.getElementById(scriptId)) return;

    this.setState((prev) => ({ ...prev, loadingBundle: true }));

    /**
     * config files for microfrontends are defined as separate js file that needs to be included.
     * These files are currently included in the head tag of the microfrontend's index.html and
     * not part of the generated js bundle
     */
    if (this.props.fetchConfig) {
      const configScript = document.createElement('script');
      configScript.id = `${this.getScriptId()}-config-script`;
      configScript.crossOrigin = '';
      configScript.src = `${host}/config.js?v=${etag}`;
      document.head.appendChild(configScript);
    }

    fetch(`${host}/asset-manifest.json?v=${etag}`)
      .then((res) => res.json())
      .then((manifest) => {
        const script = document.createElement('script');
        script.id = scriptId;
        script.crossOrigin = '';
        script.src = `${host}${manifest.files['main.js']}?v=${etag}`;
        script.onload = this.renderMicroFrontend;
        document.head.appendChild(script);
      });
  };

  renderMicroFrontend = () => {
    const { name, window } = this.props;

    if (window[`render${name}`]) {
      if (this.state.loadingBundle) {
        this.setState((prev) => ({ ...prev, loadingBundle: false }));
      }

      window[`render${name}`](`${name}-container`);
    } else {
      this.fetchMicrofrontend();
    }
  };

  render() {
    return (
      <>
        <div id={`${this.props.name}-container`} />
        {this.state.loadingBundle && (
          <div className="d-flex justify-content-center align-items-center p-8 vh-100">
            <Spinner large />
          </div>
        )}
      </>
    );
  }
}

MicroFrontend.defaultProps = {
  document,
  window,
};

export default MicroFrontend;
