import useGetCurrencySettings from './useGetCurrencySettings';
import { useMemo } from 'react';
import { isRegion } from 'shared/util/region';
import { useUSModeSettings } from 'US/Billing/USMode';
import { useGetCurrentBusiness } from 'shared/hooks/user-context/useGetCurrentBusiness';

/**
 * Somewhat inspired by {@link https://react.i18next.com/latest/trans-component}
 */
const CurrencyTrans: React.FC<{}> = ({ children }) => {
  const { currencySettings, loading } = useGetCurrencySettings();
  const businessId = useGetCurrentBusiness();
  const { usMode } = useUSModeSettings(businessId);

  const direction = currencySettings?.direction ?? 'Negative';
  const currencyTranslations = useMemo(() => {
    if (usMode && !isRegion('AU')) {
      return {
        credit: 'credit',
        Credit: 'Credit',
        debit: 'charge',
        Debit: 'Charge',
      };
    } else {
      if (direction === 'Positive')
        return {
          credit: 'credit',
          Credit: 'Credit',
          debit: 'debit',
          Debit: 'Debit',
        };
      return {
        credit: 'debit',
        Credit: 'Debit',
        debit: 'credit',
        Debit: 'Credit',
      };
    }
  }, [direction, usMode]);
  const childrenString = useMemo(() => {
    if (loading) return '';
    return (children?.toString() ?? '').replace(/credit|debit/gi, (match) => currencyTranslations[match]);
  }, [children, currencyTranslations, loading]);

  return <>{childrenString}</>;
};

export default CurrencyTrans;
