import { gql } from '@apollo/client';
import { NOTIFICATION_SUBSCRIPTION_FIELDS } from './fields';
import { useK2Mutation } from '../../shared/apis/k2Api';
import { MutationHookOptions } from '@apollo/client';

export const UPDATE_NOTIFICATION_SUBSCRIPTIONS = gql`
  mutation($personId: UUID!, $subscriptions: [UserNotificationSubscriptionDtoInput!]!) {
    updateNotificationSubscriptions(personId: $personId, subscriptions: $subscriptions){
      ${NOTIFICATION_SUBSCRIPTION_FIELDS}
    }
  }
`;

export interface IUpdateNotificationSubscriptionData {
  updateNotificationSubscription: INotificationSubscription[];
}

export interface IUpdateNotificationSubscriptionVariables {
  personId: string;
  subscriptions: INotificationSubscription[];
}

export const useUpdateNotificationSubscriptions = (
  options?: MutationHookOptions<IUpdateNotificationSubscriptionData, IUpdateNotificationSubscriptionVariables>
) => {
  return useK2Mutation<IUpdateNotificationSubscriptionData, IUpdateNotificationSubscriptionVariables>(
    UPDATE_NOTIFICATION_SUBSCRIPTIONS,
    options
  );
};
