import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PageWrapper from 'shared/components/PageWrapper';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetAttendance } from './graphql/queries';
import { CreateButton, CirclePlusButton } from 'shared/components/Buttons';
import NewTimeEntryModal from '../TimeSheets/components/NewTimeEntryModal';
import { showTimezoneToast } from 'shared/components/Toast';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { updateTimelogTableFilter } from './duck/actions';
import { TimezoneContext } from 'shared/contexts/timezoneContext';
import TrainingHolidayTab from './components/TrainingHolidayTab';
import TimelogsTab from './components/TimelogsTab';
import TrainingTimeEventModal from './components/TrainingTimeEventModal';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { isTheSameTimezone } from '../helpers';

type EmployeeTimelogsTab = 'time-log' | 'group-paid-time';

interface IRouteParams {
  activeTab: EmployeeTimelogsTab;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const EmployeeTimeLogs: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { activeTab } = props.match.params;
  const tableFilters = useSelector((state: RootState) => state.timelog.tableFilters);
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const user = useSelector((state: RootState) => state.user);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const centerTimezone = timezonesByCenter[currentCenterId ?? ''] ?? moment.tz.guess();
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpSubFeature =
    Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.subFeatures ?? undefined;
  const AdpTimeEnabled = AdpSubFeature?.find((x) => x.type === 'TimeAndAttendance')?.enabled ?? false;

  const { k2TrainingHoliday } = useFlags();

  const [activeTabKey, setActiveTabKey] = useState<EmployeeTimelogsTab>(activeTab ?? 'time-log');
  const [showNewTimeEntryModal, setShowTimeEntryModal] = useState<boolean>(false);
  const [showNewTrainingTimeModal, setShowNewTrainingTimeModal] = useState<boolean>(false);

  // for AU region, removing feature flag in favor of regionalization per product
  const { refetch: refetchAttendance } = useGetAttendance({
    centerId: currentCenterId ?? '',
    startDate: moment(tableFilters.date).tz(centerTimezone).day(1).toISOString(),
    endDate: moment(tableFilters.date).tz(centerTimezone).day(5).toISOString(),
  });

  useEffect(() => {
    if (!isTheSameTimezone(centerTimezone)) {
      showTimezoneToast(centerTimezone);
      dispatch(updateTimelogTableFilter({ date: moment().tz(centerTimezone).toISOString() }));
    }
  }, [centerTimezone, currentCenterId, dispatch]);

  const activeCentersData: ICenter[] = useGetActiveCenters();

  const renderPageWrapperButton = useCallback((): JSX.Element | null => {
    if (activeTabKey === 'group-paid-time') {
      return <CreateButton onClick={() => setShowNewTrainingTimeModal(true)}>Add Group Paid Time</CreateButton>;
    }
    return null;
  }, [activeTabKey]);

  return (
    <PageWrapper
      pageTitle="Time Log"
      mobileButtonComponent={
        (user?.hasAreaPermissionLevel({
          area: AreaType.Schedule,
          permission: PermissionType.TimeLog,
          level: RoleLevelType.Create,
        }) ??
          false) &&
        currentCenterId && (
          <CirclePlusButton variant="primary" className="mt-4 mb-4" onClick={() => setShowTimeEntryModal(true)} />
        )
      }
      buttonComponent={
        (user?.hasAreaPermissionLevel({
          area: AreaType.Schedule,
          permission: PermissionType.TimeLog,
          level: RoleLevelType.Create,
        }) ??
          false) &&
        currentCenterId &&
        !AdpTimeEnabled &&
        renderPageWrapperButton()
      }
      applyPadding={false}
    >
      <TimezoneContext.Provider value={{ timezone: centerTimezone }}>
        {k2TrainingHoliday ? (
          <Tabs
            id="time-log-tabs"
            activeKey={activeTabKey}
            onSelect={(tab) => {
              setActiveTabKey(tab as EmployeeTimelogsTab);
              history.push(`/employees/time-logs/${tab}`);
            }}
            style={{
              paddingLeft: '16px',
              position: 'sticky',
              top: '119px',
              zIndex: 900,
            }}
            className="mb-0"
          >
            <Tab eventKey="time-log" title="Time Log">
              <TimelogsTab />
            </Tab>
            <Tab eventKey="group-paid-time" title="Group Paid Time">
              <TrainingHolidayTab />
            </Tab>
          </Tabs>
        ) : (
          <TimelogsTab />
        )}
      </TimezoneContext.Provider>
      <NewTimeEntryModal
        isOpen={showNewTimeEntryModal}
        onClose={() => setShowTimeEntryModal(false)}
        centerId={currentCenterId ?? ''}
        entityId={currentBusinessId ?? ''}
        selectedCenter={activeCentersData.find((c) => c.id === currentCenterId) ?? null}
        refetchAttendance={refetchAttendance}
      />
      <TrainingTimeEventModal
        isOpen={showNewTrainingTimeModal}
        businessId={currentBusinessId ?? ''}
        centerId={currentCenterId ?? ''}
        onClose={() => setShowNewTrainingTimeModal(false)}
      />
    </PageWrapper>
  );
};

export default EmployeeTimeLogs;
