export const GET_TIME_OFF_SUCCESS = 'GET_TIME_OFF_SUCCESS';
export const ADD_TIME_OFF_SUCCESS = 'ADD_TIME_OFF_SUCCESS';
export const UPDATE_TIME_OFF_SUCCESS = 'UPDATE_TIME_OFF_SUCCESS';
export const CANCEL_TIME_OFF_SUCCESS = 'CANCEL_TIME_OFF_SUCCESS';

export type TimeOffActionTypes =
  | { type: typeof GET_TIME_OFF_SUCCESS; timeOff: ITimeOff[] }
  | { type: typeof ADD_TIME_OFF_SUCCESS; timeOff: ITimeOff }
  | { type: typeof UPDATE_TIME_OFF_SUCCESS; timeOff: ITimeOff }
  | { type: typeof CANCEL_TIME_OFF_SUCCESS; timeOff: ITimeOff };
