import {
  ClassesActionTypes,
  GET_CENTER_CLASSES_SUCCESS,
  UPDATE_CLASS,
  ADD_CLASS,
  ARCHIVE_CLASS,
  ADD_CLASS_FEE,
} from './types';

export const getCenterClassesSuccess = (classes: IClass[]): ClassesActionTypes => ({
  type: GET_CENTER_CLASSES_SUCCESS,
  classes,
});

export const updateClass = (updates: IClass): ClassesActionTypes => ({
  type: UPDATE_CLASS,
  class: updates,
});

export const addClass = (created: IClass): ClassesActionTypes => ({
  type: ADD_CLASS,
  class: created,
});

export const archiveClass = (id: string): ClassesActionTypes => ({
  type: ARCHIVE_CLASS,
  id,
});

export const addClassFee = (classId: string, fee: IFee): ClassesActionTypes => ({
  type: ADD_CLASS_FEE,
  classId,
  fee,
});
