import React, { useCallback, useMemo, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import momentTz from 'moment-timezone';
import Collapse from 'react-bootstrap/Collapse';
import { orderBy } from 'lodash';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import TimeInput from 'shared/components/TimePicker/TimeInput';
import { useGetClassClosureByDate, useGetClassesForCenter } from 'gql/class/queries';
import { useGetFeesByClassId } from 'gql/fee/queries';
import { useGetCenterAccountsWithChildren } from 'gql/account/queries';
import { useCreateSession } from 'gql/session/mutations';
import { showToast } from 'shared/components/Toast';
import { createSession } from 'pages/Attendance/duck/actions';
import { IGetAttendanceOpenSpotsData, useGetExpectedSessions } from 'gql/session/queries';
import Alert from 'shared/components/Alert';
import { isTimeRangeInvalid } from 'shared/util/timeUtils';
import moment from 'moment';
import { getFullName } from 'shared/util/string';
import cast from 'shared/util/cast';
import { useGetChildIdsWithContractsOnDate } from 'gql/contract/queries';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

interface IChildDropdownOptionShape {
  id: string;
  firstname: string;
  lastname: string;
  nickname: string | null;
  accountId: string;
  accountName: string;
  archivedAt: string | null;
}

interface IFormStateShape {
  date: string;
  classId: string;
  accountId: string;
  childId: string;
  dropOffTime: string | null;
  pickupTime: string | null;
  feeId: string;
}

interface IProps {
  isOpen: boolean;
  centerId: string | null;
  centerTimezone: Timezone;
  enrollmentYearStart: string;
  enrollmentYearEnd: string;
  onClose: () => void;
  refetchOpenSpotsData: () => Promise<ApolloQueryResult<IGetAttendanceOpenSpotsData>>; // refetch function from `useGetAttendanceOpenSpots`
  refetchExpectedSessions: () => void; // refetch function from `useGetExpectedSessions`
}

const CreateSessionModal: React.FC<IProps> = ({
  isOpen,
  centerId,
  centerTimezone,
  onClose,
  enrollmentYearStart,
  enrollmentYearEnd,
  refetchOpenSpotsData,
  refetchExpectedSessions,
  ...props
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IFormStateShape>({
    date: '',
    classId: '',
    accountId: '',
    childId: '',
    dropOffTime: null,
    pickupTime: null,
    feeId: '',
  });

  const { t } = useTranslation();

  const activeBusinessId = useSelector((state: RootState) => state.context.businessId);

  const { loading: classesForCenterLoading, data: classesForCenterData } = useGetClassesForCenter({
    variables: {
      centerId: centerId ?? '',
    },
    skip: !centerId,
  });
  const { loading: feesByClassLoading, data: feesForClassData } = useGetFeesByClassId({
    variables: {
      classId: formData.classId,
    },
    skip: !formData.classId,
  });
  const { loading: getSessionsLoading, data: getSessionsData } = useGetExpectedSessions({
    variables: {
      input: {
        centerId: centerId ?? '',
        startDate: moment(formData.date).format('YYYY-MM-DD'),
        endDate: moment(formData.date).format('YYYY-MM-DD'),
      },
      enrollmentYearStart,
      enrollmentYearEnd,
    },
    onCompleted: () => {},
  });
  const { loading: accountsForCenterLoading, data: accountsForCenterData } = useGetCenterAccountsWithChildren({
    fetchPolicy: 'no-cache',
    variables: {
      centerId: centerId ?? '',
    },
    skip: !centerId,
  });

  const { loading: getChildIdsWithContractsOnDateLoading, data: getChildIdsWithContractsOnDateData } =
    useGetChildIdsWithContractsOnDate({
      fetchPolicy: 'no-cache',
      skip: !activeBusinessId,
      variables: {
        input: {
          businessId: activeBusinessId as string,
          date: moment(formData.date).format('YYYY-MM-DD'),
        },
      },
    });

  const [createSessionFn, { loading: createSessionLoading }] = useCreateSession({
    onCompleted: (data) => {
      dispatch(createSession(data.createSession));
      refetchOpenSpotsData();
      refetchExpectedSessions();
      showToast('Session created successfully.', 'success');
      handleClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const {
    loading: getClassClosureByDateLoading,
    data: getClassClosureByDateData,
    called,
  } = useGetClassClosureByDate({
    variables: {
      input: {
        classId: formData.classId,
        date: formData.date,
      },
    },
    fetchPolicy: 'network-only',
    skip: !formData.classId || !formData.date,
  });

  const shouldShowClassClosedAlert =
    !getClassClosureByDateLoading &&
    Boolean(getClassClosureByDateData?.getClassClosureByDate) &&
    called &&
    Boolean(formData.classId && formData.date);

  const sessionsForChild = useMemo(
    () => (getSessionsData?.getExpectedSessions ?? []).filter((s) => s.childId === formData.childId),
    [getSessionsData, formData.childId]
  );

  const isClassActive = useCallback(
    (_class: IClass) =>
      !_class.archivedAt &&
      (!formData.date ||
        (momentTz(formData.date).tz(centerTimezone).isSameOrAfter(_class.startsAt, 'd') &&
          (!_class.endsAt || momentTz(formData.date).tz(centerTimezone).isSameOrBefore(_class.endsAt, 'd')))),
    [formData.date]
  );

  const classOptions = useMemo(() => {
    const filteredOptions = (classesForCenterData?.getClassesForCenter ?? []).filter((c) => isClassActive(c));
    return orderBy(filteredOptions, (c) => c.name, 'asc');
  }, [classesForCenterData, formData.date]);

  const isSelectedClassActive = classOptions.map((c) => c.id).includes(formData.classId);

  const feeOptions = useMemo(
    () =>
      orderBy(
        (feesForClassData?.getFeesByClassId ?? []).filter((f) => !f.deactivatedAt && f.feeType !== 'FLAT_RATE'),
        (f) => f.name,
        'asc'
      ),
    [feesForClassData]
  );
  const childOptions = useMemo(() => {
    let arr: IChildDropdownOptionShape[] = [];

    (accountsForCenterData?.getAccountsForCenter ?? []).forEach((account: IAccount) => {
      const accountChildrenMapped: IChildDropdownOptionShape[] = account.children
        .map((child) => ({
          ...child,
          nickname: child.nickname ?? null,
          accountId: account.id,
          accountName: account.name,
          archivedAt: child.archivedAt,
        }))
        .filter((c) => !c.archivedAt);

      arr = arr.concat(accountChildrenMapped);
    });

    const orderedData = orderBy(arr, (c) => c.lastname, 'asc');

    const childrenWithContracts = getChildIdsWithContractsOnDateData?.getChildIdsWithContractsOnDate || [];
    return orderedData.filter((o) => childrenWithContracts.some((c) => c === o.id));
  }, [accountsForCenterData, getChildIdsWithContractsOnDateData]);

  const resetForm = useCallback(() => {
    setFormData({
      date: '',
      classId: '',
      accountId: '',
      childId: '',
      dropOffTime: null,
      pickupTime: null,
      feeId: '',
    });
  }, []);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const handleSave = useCallback(() => {
    createSessionFn({
      variables: {
        input: {
          accountId: formData.accountId,
          childId: formData.childId,
          classId: formData.classId,
          feeId: formData.feeId,
          date: moment(formData.date).format('YYYY-MM-DD'),
          dropOffTime: formData.dropOffTime ?? '',
          pickUpTime: formData.pickupTime ?? '',
        },
      },
    });
  }, [formData, createSessionFn]);

  const invalidTimeframe = Boolean(isTimeRangeInvalid(formData.dropOffTime, formData.pickupTime) ?? false);
  const formInvalid = Boolean(
    !formData.accountId ||
      !formData.childId ||
      !formData.classId ||
      !formData.date ||
      !formData.feeId ||
      invalidTimeframe ||
      !isSelectedClassActive ||
      getSessionsLoading ||
      shouldShowClassClosedAlert
  );

  return (
    <SideModalDrawer
      title="Add Session"
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: formInvalid, loading: createSessionLoading }}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <Row>
        <Col lg={6}>
          <DateInput
            required
            label="Date"
            date={formData.date}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, date }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Collapse in={!getSessionsLoading && sessionsForChild.length > 0}>
            <div>
              <Alert variant="warning" className="shadow-none mb-4">
                Child has an existing schedule for this day.
              </Alert>
            </div>
          </Collapse>
        </Col>
      </Row>
      <Row>
        <Col>
          <Collapse in={shouldShowClassClosedAlert}>
            <div>
              <Alert variant="warning" className="shadow-none mb-4">
                {t('attendance.create-session.class-closed-message')}
              </Alert>
            </div>
          </Collapse>
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            label="Class"
            value={formData.classId}
            options={classOptions}
            onChange={(option: IClass) => setFormData((prev) => ({ ...prev, classId: option.id }))}
            isLoading={classesForCenterLoading}
            getOptionLabel={(option: IClass) => option.name}
            getOptionValue={(option: IClass) => option.id}
          />
        </Col>
      </Row>
      {formData.classId && !isSelectedClassActive && (
        <Form.Control.Feedback type="invalid">Class is not active for selected date.</Form.Control.Feedback>
      )}
      <Row>
        <Col>
          <Select
            required
            label="Child"
            options={childOptions}
            value={formData.childId ? `${formData.accountId}_${formData.childId}` : null} // custom value to account for children with multiple accounts
            onChange={(option: IChildDropdownOptionShape) =>
              setFormData((prev) => ({ ...prev, accountId: option.accountId, childId: option.id }))
            }
            isLoading={accountsForCenterLoading || getChildIdsWithContractsOnDateLoading}
            getOptionLabel={(option: IChildDropdownOptionShape) => getFullName(cast<IChild>(option))}
            getOptionValue={(option: IChildDropdownOptionShape) => `${option.accountId}_${option.id}`}
            formatOptionLabel={(option: IChildDropdownOptionShape) => (
              <div>
                <div>{getFullName(cast<IChild>(option))}</div>
                <small>Account: {option.accountName}</small>
              </div>
            )}
          />
        </Col>
      </Row>
      {!!formData.date &&
        !getChildIdsWithContractsOnDateLoading &&
        !(getChildIdsWithContractsOnDateData?.getChildIdsWithContractsOnDate || []).length && (
          <Form.Control.Feedback type="invalid">
            {t('attendance.create-session.no-active-child-for-selected-date')}
          </Form.Control.Feedback>
        )}
      <Row>
        <Col>
          <Form.Label>Drop Off</Form.Label>
          <TimeInput
            isAM={true}
            value={formData.dropOffTime}
            onChange={(time) => setFormData((prev) => ({ ...prev, dropOffTime: time }))}
          />
        </Col>
        <Col>
          <Form.Label>Pick Up</Form.Label>
          <TimeInput
            isAM={false}
            value={formData.pickupTime}
            onChange={(time) => setFormData((prev) => ({ ...prev, pickupTime: time }))}
          />
        </Col>
      </Row>
      {invalidTimeframe && <Form.Control.Feedback type="invalid">Invalid timeframe.</Form.Control.Feedback>}
      <Row>
        <Col>
          <Select
            required
            label="Fee Name"
            options={feeOptions}
            onChange={(option: IFee) => setFormData((prev) => ({ ...prev, feeId: option.id }))}
            getOptionLabel={(option: IFee) => option.name}
            getOptionValue={(option: IFee) => option.id}
            isLoading={feesByClassLoading}
            disabled={!formData.childId}
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default CreateSessionModal;
