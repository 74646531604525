import React, { useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent, faDollarSign } from '@fortawesome/pro-light-svg-icons';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Row, Col } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import Checkbox from 'shared/components/Checkbox';
import Select from 'shared/components/Select';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { HorizontalDivider } from 'shared/components/Dividers';
import { useCreateDiscount } from 'gql/discount/mutations';
import { showToast } from 'shared/components/Toast';
import { useDispatch } from 'react-redux';
import { createDiscountSuccess } from './duck/actions';
import { useTranslation } from 'react-i18next';
import { isRegion } from 'shared/util/region';
import GlCodeInput from 'shared/components/GlCodeInput';
import useIsGlCodeInputValid from 'shared/hooks/useIsGlCodeInputValid';
import { DiscountAppliesToType } from 'generated/graphql';
import Alert from 'shared/components/Alert';

interface ICreateDiscountFormShape {
  name: string;
  glCode: string;
  centerIds: string[] | null;
  start: string;
  end: string | null; // null represent an ongoing discount
  type: DiscountType | null;
  amountType: DiscountAmountType;
  amount: number | null; // null for initial empty state, cannot submit null
  description: string;
  appliesTo: DiscountAppliesToType | null;
  glCodeMapping?: IGLCodeMapping | null;
  applySessionGapDiscountIfNoSubsidy: boolean;
}

interface IProps {
  isOpen: boolean;
  businessId: string;
  onClose: () => void;
}

const CreateDiscountModalOld: React.FC<IProps> = ({ isOpen, businessId, onClose, ...props }) => {
  const { t } = useTranslation(['billing']);
  const isAuRegion = isRegion('AU');
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<ICreateDiscountFormShape>({
    name: '',
    glCode: '',
    centerIds: null,
    start: '',
    end: null,
    type: isAuRegion ? 'RECURRING' : null,
    amountType: isAuRegion ? 'PERCENTAGE' : 'FLAT',
    amount: null,
    description: '',
    appliesTo: null,
    glCodeMapping: null,
    applySessionGapDiscountIfNoSubsidy: false,
  });

  const [isOngoingDiscount, setIsOnGoingDiscount] = useState<boolean>(false);
  const [createDiscountFn, { loading: createDiscountLoading }] = useCreateDiscount({
    onCompleted: (result) => {
      showToast(t('billing:discounts.create-modal.success-toast-message'), 'success');
      dispatch(createDiscountSuccess(result.createDiscount));
      handleClose();
    },
    onError: (err) => {
      showToast(t('billing:discounts.create-modal.error-toast-message'), 'error');
    },
  });

  const handleSave = useCallback(() => {
    if (formData.type && formData.appliesTo && formData.amount) {
      createDiscountFn({
        variables: {
          input: {
            name: formData.name,
            centerIds: formData.centerIds,
            businessId,
            glCode: formData.glCode,
            startDate: formData.start,
            endDate: formData.end,
            type: formData.type,
            amount: formData.amountType === 'PERCENTAGE' ? formData.amount / 100 : formData.amount,
            amountType: formData.amountType,
            description: formData.description,
            appliesTo: formData.appliesTo,
            glCodeMapping: formData.glCodeMapping,
            applySessionGapDiscountIfNoSubsidy: formData.applySessionGapDiscountIfNoSubsidy,
          },
        },
      });
    }
  }, [createDiscountFn, formData, businessId]);

  const handleClose = useCallback(() => {
    setFormData({
      name: '',
      glCode: '',
      centerIds: null,
      start: '',
      end: null,
      type: isAuRegion ? 'RECURRING' : null,
      amountType: isAuRegion ? 'PERCENTAGE' : 'FLAT',
      amount: null,
      description: '',
      appliesTo: null,
      applySessionGapDiscountIfNoSubsidy: false,
    });
    setIsOnGoingDiscount(false);
    onClose();
  }, [isAuRegion, onClose]);

  const getAppliesToOptions = useCallback(
    (discountType: DiscountType | null): any[] => {
      if (!discountType) return [];

      if (isRegion('AU'))
        // We only support session gap discounts down under
        return [{ label: t('billing:discounts.modal-inputs.session-gap-applied-to'), value: 'SESSION_GAP' }];

      return discountType === 'RECURRING'
        ? [
            // hiding temporarily
            // { label: t('billing:discounts.modal-inputs.billing-cycle-applied-to'), value: 'BILLING_CYCLE' },
            { label: t('billing:discounts.modal-inputs.fee-applied-to'), value: 'SESSION' },
          ]
        : [
            { label: t('billing:discounts.modal-inputs.fee-applied-to'), value: 'SESSION' },
            { label: t('billing:discounts.modal-inputs.transaction-applied-to'), value: 'TRANSACTION' },
          ];
    },
    [t]
  );

  const { isGlCodeMandatory, isGlCodeInputValid } = useIsGlCodeInputValid(formData.glCode, formData.glCodeMapping);

  var isAmountValid = useMemo(() => {
    if (formData.amount) {
      if (formData.amountType === 'PERCENTAGE') {
        if (formData.amount > (isAuRegion ? (formData.type === 'RECURRING' ? 95 : 100) : 100)) {
          return false;
        }
        return true;
      }
      return true;
    } else {
      return false;
    }
  }, [formData.amount, formData.amountType, formData.type, isAuRegion]);

  const formDisabled: boolean =
    !formData.name ||
    !formData.amountType ||
    !formData.type ||
    !formData.appliesTo ||
    !formData.start ||
    !isAmountValid ||
    !isGlCodeInputValid;

  return (
    <SideModalDrawer
      title={t('billing:discounts.create-modal.title')}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice={t('billing:discounts.modal-inputs.primary-action-button')}
      secondaryChoice={t('billing:discounts.modal-inputs.secondary-action-button')}
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: formDisabled, loading: createDiscountLoading }}
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            useNullForAllOption
            selectedCenterIds={formData.centerIds}
            onSelect={(centerIds) => setFormData((prev) => ({ ...prev, centerIds }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            required
            label={t('billing:discounts.modal-inputs.name-input-label')}
            value={formData.name}
            onChange={(value) => setFormData((prev) => ({ ...prev, name: value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <GlCodeInput<ICreateDiscountFormShape>
            areaType="DISCOUNT"
            legacyValue={formData.glCode}
            glCodeMapping={formData.glCodeMapping}
            updateFn={setFormData}
            required={isGlCodeMandatory}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            required
            label={t('billing:discounts.modal-inputs.start-date-input-label')}
            date={formData.start}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, start: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(day) => (formData.end ? moment(day).isSameOrAfter(formData.end) : false)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            label={t('billing:discounts.modal-inputs.ongoing-label')}
            value={isOngoingDiscount}
            onChange={(checked) => {
              setIsOnGoingDiscount(checked);
              setFormData((prev) => ({ ...prev, end: checked ? null : prev.end }));
            }}
            className="mb-4"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            label={t('billing:discounts.modal-inputs.end-date-input-label')}
            date={formData.end}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, end: date }))}
            disabled={isOngoingDiscount}
            className="kt-date-input-no-max-width"
            isOutsideRange={(day) => (formData.start ? moment(day).isSameOrBefore(formData.start) : false)}
          />
        </Col>
      </Row>
      <HorizontalDivider />
      <Row>
        <Col>
          <Select
            required
            label={t('billing:discounts.modal-inputs.discount-type-input-label')}
            options={[
              !isAuRegion && { label: t('billing:discounts.modal-inputs.manual-discount-type'), value: 'MANUAL' },
              { label: t('billing:discounts.modal-inputs.recurring-discount-type'), value: 'RECURRING' },
            ]}
            value={
              isAuRegion
                ? { label: t('billing:discounts.modal-inputs.recurring-discount-type'), value: 'RECURRING' }
                : formData.type
            }
            onChange={(option) => setFormData((prev) => ({ ...prev, type: option.value }))}
            disabled={isAuRegion}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberInput
            required
            label={t('billing:discounts.modal-inputs.amount-input-label')}
            value={formData.amount}
            onChange={(value) => setFormData((prev) => ({ ...prev, amount: value }))}
            step="0.01"
            numberFormat={{
              thousandSeparator: true,
              decimalScale: 2,
              fixedDecimalScale: formData.amountType === 'FLAT',
              max:
                formData.amountType === 'PERCENTAGE'
                  ? isAuRegion
                    ? formData.type === 'RECURRING'
                      ? 95
                      : 100
                    : 100
                  : undefined,
            }}
            appendNode={
              <>
                {!isAuRegion && (
                  <Button
                    onClick={() => setFormData((prev) => ({ ...prev, amountType: 'FLAT' }))}
                    variant={formData.amountType === 'FLAT' ? 'secondary' : 'outline-secondary'}
                    className="px-3 h-100"
                  >
                    <FontAwesomeIcon icon={faDollarSign} />
                  </Button>
                )}
                <Button
                  onClick={() => setFormData((prev) => ({ ...prev, amountType: 'PERCENTAGE' }))}
                  variant={formData.amountType === 'PERCENTAGE' ? 'secondary' : 'outline-secondary'}
                  className="px-3 h-100"
                >
                  <FontAwesomeIcon icon={faPercent} />
                </Button>
              </>
            }
          />
        </Col>
        <Col>
          <Select
            required
            label={t('billing:discounts.modal-inputs.applies-to-input-label')}
            options={formData.type ? getAppliesToOptions(formData.type) : []}
            onChange={(option) => setFormData((prev) => ({ ...prev, appliesTo: option.value }))}
            noOptionsMessage={() => t('billing:discounts.create-modal.no-options-message')}
          />
        </Col>
      </Row>
      {formData.appliesTo == DiscountAppliesToType.SessionGap && (
        <Row>
          <Alert variant="warning" className="mb-4 ml-2">
            <div>This discount type should only be applied to staff members.</div>
          </Alert>
        </Row>
      )}
      {isRegion('AU') && (
        <Row>
          <Checkbox
            label={t('billing:discounts.modal-inputs.include-zero-subsidy')}
            value={formData.applySessionGapDiscountIfNoSubsidy}
            onChange={(val) => {
              setFormData((prev) => ({ ...prev, applySessionGapDiscountIfNoSubsidy: val }));
            }}
            className="mb-4"
          />
        </Row>
      )}
      <Row>
        <Col>
          <TextInput
            as="textarea"
            label={t('billing:discounts.modal-inputs.description-input-label')}
            value={formData.description}
            onChange={(value) => setFormData((prev) => ({ ...prev, description: value }))}
            rows={5}
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default CreateDiscountModalOld;
