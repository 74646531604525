import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';
import FamiliesImport from './FamiliesImport';
import ImportSource from './ImportSource';
import {
  useMigrateQikKidsBookingPatterns,
  useMigrateQikKidsCCSS,
  useMigrateQikKidsFamilies,
  useMigrateQikKidsHistory,
  useAdjustZeroBalance,
  useAdjustSubsidyZeroBalance,
  useFinalizeMigration,
  useMigrateQikKidsBalances,
  useMigrateProcareFamilies,
} from 'gql/qikKidsMigration/mutation';
import './importTool.scss';

interface IProps {
  center?: ICenter;
}

const STEPS = {
  center: 1,
  families: 2,
  contracts: 3,
  history: 4,
  ccss: 5,
  actions: 6,
  balances: 7,
  finalize: 8,
};

const ImportTool: React.FC<IProps> = ({ center }) => {
  const { t } = useTranslation('ktAdmin');
  const [stepsCompleted, setStepsCompleted] = useState(STEPS.center);

  const [importFamilies, { loading: familiesLoading }] = useMigrateProcareFamilies();

  const handleImportFamilies = async (familiesInput: IProcareFamiliesVariables) => {
    const data = await importFamilies({
      variables: familiesInput,
    });

    if (data?.data?.migrateProcareFamilies) {
      if (!familiesInput.dryRun) setStepsCompleted(STEPS.families);
      else showToast(t('data-importer.dryrun-completed'), 'success');
    }
  };

  return (
    <div className="au-import-tool">
      <h4>Data Importer</h4>
      <ImportSource />
      <FamiliesImport
        importFamilies={handleImportFamilies}
        center={center}
        completed={stepsCompleted >= STEPS.families}
        loading={familiesLoading}
        shouldReset={stepsCompleted === STEPS.center}
      />

      {t('data-importer.finalize-import')}
      <p className="mt-2">
        <strong>{t('data-importer.steps-completed', { stepsCompleted, total: 1 })}</strong>
      </p>
    </div>
  );
};

export default ImportTool;
