import colors from '_colors.module.scss';

const tagColorHexes = {
  CENTER: colors.secondaryHover,
  STAFF: colors.steelBlue,
  CHILD: colors.teal,
  CONTACT: colors.salmon,
  INACTIVE: colors.gray,
  ACCOUNT: colors.olive,
};

const tagColorNames = {
  CENTER: 'secondary-hover',
  STAFF: 'steel-blue',
  CHILD: 'teal',
  CONTACT: 'salmon',
  INACTIVE: 'gray',
  ACCOUNT: 'olive',
};

const riskColorHexes = {
  AwarenessOnly: colors.silver,
  None: colors.silver,
  NotApplicable: colors.silver,
  Low: colors.yellow,
  Mild: colors.yellow,
  Moderate: colors.orange,
  High: colors.danger,
  Severe: colors.danger,
};

const statusColorHexes = {
  Active: colors.success,
  Inactive: colors['dark-gray'],
};

const staffStatusColorHexes = {
  Invited: colors['dark-gray'],
  Created: colors.skyBlue,
  Pending: colors.yellow,
  Active: colors.success,
  Deactivated: colors.danger,
  Requested: colors.orange,
};

const paymentStatusColorHexes: Record<string, string> = {
  COMPLETED: colors.success,
  PENDING: colors.gray,
  FAILED: colors.danger,
  UNSUBMITTED: colors.info,
  FLAGGED: colors.orange,
  CANCELLED: colors.secondary,
  RETURNED: colors.danger,
};

const paymentStatusColors: Record<string, string> = {
  COMPLETED: 'success',
  PENDING: 'gray',
  FAILED: 'danger',
  UNSUBMITTED: 'info',
  FLAGGED: 'orange',
  CANCELLED: 'secondary',
  RETURNED: 'danger',
};

const accountStatusColorHexes = {
  Inactive: colors['dark-gray'],
  Active: colors.success,
  Future: colors.primary,
};

const cwaStatusColors = {
  COMPLETED: 'green',
  PENDING: 'yellow',
  FAILED: 'red',
  READY_TO_BE_SENT: 'gray',
  CANCELLED: 'orange',
};

const cwaStatusCardsColors = {
  COMPLETED: 'success',
  PENDING: 'yellow',
  FAILED: 'danger',
  READY_TO_BE_SENT: 'gray',
  CANCELLED: 'orange',
};

export {
  tagColorHexes,
  tagColorNames,
  riskColorHexes,
  statusColorHexes,
  staffStatusColorHexes,
  paymentStatusColorHexes,
  paymentStatusColors,
  accountStatusColorHexes,
  cwaStatusColors,
  cwaStatusCardsColors,
};
