import React, { useState, useMemo } from 'react';
import { orderBy } from 'lodash';
import Select from 'shared/components/Select';
import { useGetFeesByClassId } from 'gql/fee/queries';
import TextInput from 'shared/components/TextInput';

interface IProps {
  className: string;
  sessions: ISession[];
  classId: string;
  date: string;
  onSelect: (selected: IFeeInBulkEdit) => void;
}

const ClassFeeGroup: React.FC<IProps> = ({ className = '', sessions, classId = '', date = '', onSelect, ...props }) => {
  const [feeOption] = useState<IFeeInBulkEdit>();
  const { loading: feesByClassLoading, data: feesForClassData } = useGetFeesByClassId({
    variables: {
      classId: classId ?? '',
    },
    skip: !classId,
  });

  const feeOptions = useMemo(
    () => orderBy(feesForClassData?.getFeesByClassId ?? [], (f) => f.name, 'asc'),
    [feesForClassData]
  ).filter((s) => s.feeType != 'FLAT_RATE');

  const sessionIds = sessions.filter((s) => !s.id?.match(/contract-/)).map((s) => s.id);
  const contractIds = sessions?.filter((s) => !s.id || s.id.match(/contract-/)).map((s) => s.contractId ?? '');

  return (
    feeOptions && (
      <>
        <div className="d-flex flex-row mb-2">
          <div className="d-flex flex-column flex-1 mr-4">
            <TextInput name="lateSubmissionReason" label="Class" disabled={true} value={className} />
          </div>
          <div className="d-flex flex-column flex-1 mr-4">
            <Select
              label="Fee"
              options={feeOptions}
              onChange={(option: IFee) =>
                onSelect({
                  ...feeOption,
                  feeId: option.id,
                  sessionIds: sessionIds,
                  classId: classId,
                  contractIds: contractIds,
                  date: date,
                })
              }
              value={feeOption?.feeId}
              isLoading={feesByClassLoading}
              getOptionLabel={(option: IFee) => option.name}
              getOptionValue={(option: IFee) => option.id}
              maxMenuHeight={160}
            />
          </div>
        </div>
      </>
    )
  );
};

export default ClassFeeGroup;
