import React, { useState, useCallback } from 'react';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';

interface IFormStateShape {
  centerIds: string[];
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  onSubmit: (centerIds: string[]) => void;
  onClose: () => void;
}

const MultipleCenterReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  ...props
}) => {
  const [formData, setFormData] = useState<IFormStateShape>({ centerIds: [] });

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    onSubmit(formData.centerIds);
  }, [formData, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({ centerIds: [] });
    onClose();
  }, [onClose]);

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{ loading: isLoading }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            selectedCenterIds={formData.centerIds}
            onSelect={(ids) => setFormData((prev) => ({ ...prev, centerIds: ids as string[] }))}
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default MultipleCenterReportModal;
