import React, { useState, useCallback, useEffect } from 'react';
import useUniqueId from 'shared/hooks/useUniqueId';
import Form from 'react-bootstrap/Form';
import ReactSelect, { components, GroupedOptionsType, OptionsType, Props as SelectProps } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import HelpTooltip from '../Tooltip/HelpTooltip';
import './_select.scss';
import cast from 'shared/util/cast';
import CheckboxSelectOption from './CheckboxSelectOption';
import Button from '../Buttons';

// NOTE: if using this component in a modal or card, you MAY have to provide `menuPortalTarget={document.body}` so that the menu doesn't get cut off
export interface IProps {
  id?: string;
  name?: string;
  label?: string;
  /**
   * You should not pass undefined into here. The typing allows it but you will not be able to clear the form if you do.
   */
  value?: any | undefined;
  options: OptionsType<any> | GroupedOptionsType<any> | any[];
  onChange?: (value: any, name: string) => void;
  required?: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
  errorText?: string | object;
  autoFocus?: boolean;
  isSearchable?: boolean;
  search?: boolean;
  onFocus?: (event: any) => void;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  className?: string;
  isLoading?: boolean;
  isMulti?: boolean;
  helpTooltipText?: string;
  placeholder?: string;
  showErrorMessage?: boolean;
  showRequiredIcon?: boolean;
  onAddNew?: () => void;
}

// using `Omit<SelectProps, keyof IProps>` resulted in a build error
export type OmittedSelectProps = Omit<
  SelectProps,
  'options' | 'onChange' | 'isSearchable' | 'getOptionLabel' | 'getOptionValue' | 'isLoading' | 'isMulti' | 'onAddNew'
>;

const Select: React.FC<IProps & OmittedSelectProps> = ({
  id,
  name = '',
  label,
  value,
  options,
  onChange,
  required,
  disabled,
  isInvalid,
  errorText,
  autoFocus,
  className,
  helpTooltipText,
  showRequiredIcon = true,
  placeholder = 'Select...',
  showErrorMessage = true,
  hideSelectedOptions = false,
  onAddNew,
  defaultValue,
  isClearable,
  formatOptionLabel,
  ...rest
}) => {
  const controlId = useUniqueId(id);

  const [touched, setTouched] = useState(autoFocus || value);

  const Control = useCallback(
    (props) => (
      <div className={touched && isInvalid ? 'invalid' : ''}>
        <components.Control {...props} />
      </div>
    ),
    [touched, isInvalid]
  );

  const SelectMenuAddButton = (props: any) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <div className="justify-content-center d-flex flex-row pt-2 pb-2" style={{ overflow: 'hidden' }}>
          <Button variant="outline-secondary" size="sm" onClick={onAddNew}>
            + Add New
          </Button>
        </div>
      </components.MenuList>
    );
  };

  // if value is reset to null, reset touched.  Need this for scenario when forms are cleared and should be marked as untouched even though they previously had a value.
  useEffect(() => {
    if (value === null) {
      setTouched(autoFocus || value);
    }
  }, [value]);

  const getComponentOverrides = useCallback(() => {
    let components;

    if (onAddNew) {
      components = {
        Control,
        IndicatorSeparator: null,
        Option: CheckboxSelectOption,
        MenuList: SelectMenuAddButton,
        ...(rest.components ?? {}),
      };
    } else {
      components = {
        Control,
        IndicatorSeparator: null,
        Option: CheckboxSelectOption,
        ...(rest.components ?? {}),
      };
    }

    return components;
  }, [Control]);

  return (
    <Form.Group className={className} controlId={controlId}>
      {label && (
        <div className="d-flex flex-row">
          {label && <Form.Label className="text-nowrap">{label}</Form.Label>}
          {required && !disabled && showRequiredIcon && (
            <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
          )}
          {helpTooltipText && <HelpTooltip text={helpTooltipText} />}
        </div>
      )}
      <div className="d-flex">
        {options.every((option) => typeof option === 'string' || typeof option === 'number') ? (
          // for string options
          <ReactSelect
            {...rest}
            className="react-select-container w-100"
            classNamePrefix="react-select"
            id={controlId}
            components={getComponentOverrides()}
            onBlur={(_) => setTouched(true)}
            autoFocus={autoFocus}
            name={name}
            isDisabled={disabled}
            isClearable={isClearable}
            formatOptionLabel={formatOptionLabel}
            options={cast<any[]>(options).map((option) => ({ label: option, value: option }))}
            value={value ? { label: value, value } : value}
            onChange={(option: any) => {
              setTouched(true);
              onChange?.(option ? option.value : option, name);
            }}
            placeholder={placeholder}
            hideSelectedOptions={hideSelectedOptions}
            closeMenuOnSelect={rest.isMulti !== undefined ? !rest.isMulti : true}
          />
        ) : (
          // for object options
          <ReactSelect
            {...rest}
            id={controlId}
            components={getComponentOverrides()}
            className="react-select-container w-100"
            classNamePrefix="react-select"
            onBlur={(_) => setTouched(true)}
            autoFocus={autoFocus}
            isDisabled={disabled}
            isClearable={isClearable}
            name={name}
            formatOptionLabel={formatOptionLabel}
            options={options}
            value={
              typeof value === 'string' || typeof value === 'number'
                ? options.find((option) => option.value === value)
                : value
            }
            onChange={(option) => {
              setTouched(true);
              onChange?.(option, name);
            }}
            placeholder={placeholder}
            hideSelectedOptions={hideSelectedOptions}
            closeMenuOnSelect={rest.isMulti !== undefined ? !rest.isMulti : true}
            defaultValue={defaultValue}
          />
        )}
        {required && !disabled && showRequiredIcon && !label && (
          <FontAwesomeIcon className="ml-1 xxs" icon={faAsterisk} color="#FF2C2C" />
        )}
      </div>
      {showErrorMessage && touched && isInvalid && <Form.Text className="text-danger">{errorText}</Form.Text>}
    </Form.Group>
  );
};

export default Select;
