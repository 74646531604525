import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DateInput from 'shared/components/DateInput';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Row, Col } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { getFullName } from 'shared/util/string';

interface IProps {
  formData: IConcessionForm;
  concessionCard: IConcessionCard;
  children: IChild[];
  handleChange: (value: any, name: any) => void;
}

const EditConcessionCardForm: React.FC<IProps> = ({ formData, concessionCard, children, handleChange }) => {
  const { t } = useTranslation(['subsidies']);
  const handleFileChange = (files: File[]) => {
    handleChange(files[files.length - 1], 'file');
  };

  const hasAnyFormValues = Object.values(formData).some((value) => !isEmpty(value));

  const displayName = (concessionCard?: IConcessionCard | null) =>
    (concessionCard?.contactFirstName ?? '') + ' ' + (concessionCard?.contactLastName ?? '');
  return (
    <div>
      <Row>
        <Col>
          <Select
            required={hasAnyFormValues}
            label={t('subsidies:program-child.create-modal.concession-contact-label')}
            options={[concessionCard]}
            name="contact"
            getOptionLabel={(concessionCard: IConcessionCard) => displayName(concessionCard)}
            getOptionValue={(concessionCard: IConcessionCard) => concessionCard.id}
            onChange={() => {}}
            disabled={true}
            value={concessionCard}
          />
        </Col>
        <Col>
          <Select
            required={hasAnyFormValues}
            label={t('subsidies:program-child.create-modal.concession-type-label')}
            options={[concessionCard.type]}
            value={concessionCard.type}
            disabled={true}
            onChange={(option) => {}}
          />
        </Col>
        <Col>
          <TextInput
            required={hasAnyFormValues}
            label={t('subsidies:program-child.create-modal.concession-number-label')}
            value={formData.number}
            onChange={handleChange}
            name="number"
            disabled={true}
          />
        </Col>
      </Row>
      {formData.linkToFile && (
        <Row className="mb-2">
          <Col>
            <a href={formData.linkToFile}>Current Document: {formData.fileName}</a>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FileSelectDropbox
            multiple={false}
            className="mb-2"
            value={formData.file ? [formData.file] : []}
            onChange={handleFileChange}
            showFiles={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            required={hasAnyFormValues}
            label={t('subsidies:program-child.create-modal.concession-effective-date-label')}
            className="max-width-200"
            date={formData.effectiveDate}
            onDateSelect={(value) => {
              handleChange(value, 'effectiveDate');
            }}
          />
        </Col>
        <Col>
          <DateInput
            required={hasAnyFormValues}
            label={t('subsidies:program-child.create-modal.concession-expiry-label')}
            className="max-width-200"
            date={formData.expiryDate}
            onDateSelect={(value) => {
              handleChange(value, 'expiryDate');
            }}
          />
        </Col>
        <Col>
          <Select
            required={hasAnyFormValues}
            options={children}
            getOptionLabel={(child: IChild) => getFullName(child)}
            getOptionValue={(child: IChild) => child.id}
            label={t('subsidies:program-child.create-modal.concession-applies-to')}
            name="children"
            value={children}
            onChange={() => {}}
            disabled={true}
            isMulti
          />
        </Col>
      </Row>
    </div>
  );
};

export default EditConcessionCardForm;
