import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'shared/components/DataTable';
import { orderBy } from 'lodash';
import useFormatDate from 'shared/hooks/useFormatDate';
import { Row } from 'react-bootstrap';
import Switch from 'shared/components/Switch';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Currency from 'shared/components/Currency';

interface IProps {
  data: IItemizedBillGridForm[];
  isLoading: boolean;
  showSummaryView: boolean;
  showSummaryViewChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const ItemizedBillTransactionsTable: React.FC<IProps> = ({
  data,
  isLoading,
  showSummaryView,
  showSummaryViewChanged,
}) => {
  const { t } = useTranslation(['accounts', 'translation']);
  const formatDate = useFormatDate();

  const tableColumns = useMemo(() => {
    const tableColumns: any = [
      {
        text: t('accounts:profile.billing-itemized-bills-tab.description-table-column'),
        dataField: 'description',
        formatter: (cell: any, row: IItemizedBillGridForm) => row.description,
      },
      {
        text: t('accounts:profile.billing-itemized-bills-tab.child-table-column'),
        formatter: (cell: any, row: IItemizedBillGridForm) => row.childName,
      },
      {
        text: t('accounts:profile.billing-itemized-bills-tab.amount-table-column'),
        dataField: 'amount',
        formatter: (amount: number) => (
          <h6>
            <Currency amount={amount} />
          </h6>
        ),
      },
    ];

    if (showSummaryView === false) {
      tableColumns.unshift({
        text: t('accounts:profile.billing-itemized-bills-tab.date-table-column'),
        dataField: 'date',
        formatter: (cell: string, row: IItemizedBillGridForm) => formatDate(row.date ?? ''),
      });
    }

    return tableColumns;
  }, [formatDate, showSummaryView, t]);

  return (
    <div>
      <Row className="mb-4" noGutters>
        <Switch
          label={t('translation:itemized-bills.show-summary-view-label')}
          labelSide="left"
          value={showSummaryView}
          checkedText={'ON'}
          uncheckedText={'OFF'}
          onChange={(value: boolean) => showSummaryViewChanged(value)}
          className="ml-auto"
        />
      </Row>

      <DataTable
        noPadding
        data={showSummaryView === true ? data : orderBy(data, (t) => t.date, 'desc')}
        columns={tableColumns}
        showSelect={false}
        showPagination={false}
        noDataText={t('accounts:profile.billing-itemized-bills-tab.empty-table-message')}
        showLoadingOverlay={isLoading}
      />
    </div>
  );
};

export default ItemizedBillTransactionsTable;
