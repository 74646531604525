import React, { useState, useCallback } from 'react';
import { orderBy } from 'lodash';
import moment from 'moment';
import { useGetAllowedTransactionTypes } from 'gql/transaction/queries';
import { useTranslation } from 'react-i18next';
import DateInput from 'shared/components/DateInput';
import { Row, Col } from 'shared/components/Layout';
import ModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import AccountSelect from 'shared/components/Select/AccountSelect';
import { useGetTransactionReportLazy } from 'gql/reports/queries';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { showToast } from 'shared/components/Toast';
import Switch from 'shared/components/Switch';

interface IFormShape {
  centerIds: string[];
  accountIds: string[] | null;
  start: string | null;
  end: string | null;
  transactionTypeIds: string[];
  transactionDate: TransactionDateType;
}

interface IProps {
  isOpen: boolean;
  defaultFormValues: {
    start: string;
    end: string;
    centerIds: string[];
    transactionTypeIds: string[];
  };
  onClose: () => void;
}

const TransactionsReportModal: React.FC<IProps> = ({ isOpen, defaultFormValues, onClose, ...props }) => {
  const { t } = useTranslation();
  const reportDataToFile = useReportDataToFile();
  const user = useSelector((state: RootState) => state.user);
  const [formData, setFormData] = useState<IFormShape>({
    centerIds: defaultFormValues.centerIds ?? [],
    accountIds: [],
    start: defaultFormValues.start ?? null,
    end: defaultFormValues.end ?? null,
    transactionTypeIds: defaultFormValues.transactionTypeIds ?? [],
    transactionDate: 'PERIOD',
  });

  const { data: getAllowedTransactionTypesData, loading: getAllowedTransactionTypesLoading } =
    useGetAllowedTransactionTypes();

  const [getTransactionReportFn, { loading }] = useGetTransactionReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(result.getTransactionReport, ReportTypeEnum.TRANSACTIONS);
      handleClose();
    },
    onError: (error) => {
      showToast(t('reports.general-failure-message'), 'error');
    },
  });

  const transactionTypeOptions = orderBy(
    getAllowedTransactionTypesData?.getAllowedTransactionTypes.map((tt) => ({ label: tt.name, value: tt.id })) ?? [],
    (tt) => tt.label,
    'asc'
  );

  const formDisabled =
    !formData.start ||
    !formData.end ||
    (formData.accountIds !== null && formData.accountIds.length === 0) ||
    !formData.centerIds.length ||
    !formData.transactionTypeIds.length;

  const handleClose = useCallback(() => {
    setFormData({
      centerIds: [],
      accountIds: [],
      start: null,
      end: null,
      transactionTypeIds: [],
      transactionDate: 'PERIOD',
    });
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if (formData.start && formData.end) {
      getTransactionReportFn({
        variables: {
          input: {
            businessId: user?.entityId ?? '',
            centerIds: formData.centerIds,
            accountIds: formData.accountIds ?? [],
            transactionTypeIds: formData.transactionTypeIds,
            startDate: moment(formData.start).format('YYYY-MM-DD'),
            endDate: moment(formData.end).format('YYYY-MM-DD'),
            transactionDate: formData.transactionDate,
          },
        },
      });
    }
  }, [getTransactionReportFn, formData, user]);

  return (
    <ModalDrawer
      title="Ready to run a report?"
      show={isOpen}
      onHide={handleClose}
      primaryButtonProps={{ disabled: formDisabled, loading }}
      primaryChoice="Run Report"
      primaryCallback={handleSubmit}
      closeOnPrimaryCallback={false}
      secondaryCallback={handleClose}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            selectedCenterIds={formData.centerIds}
            onSelect={(centerIds) => setFormData((prev) => ({ ...prev, centerIds: centerIds as string[] }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AccountSelect
            selectedAccountIds={formData.accountIds}
            onSelect={(accountIds) => setFormData((prev) => ({ ...prev, accountIds }))}
            required
            isMulti
            centerIds={formData.centerIds}
            statusType={'All'}
            appearance="detailed"
            showAccountStatus
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            label="From Date"
            date={formData.start}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, start: date }))}
            className="kt-date-input-no-max-width"
          />
        </Col>
        <Col>
          <DateInput
            label="To Date"
            date={formData.end}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, end: date }))}
            className="kt-date-input-no-max-width"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Switch
            label={t('spelling.periodAccounting')}
            labelSide="top"
            value={formData.transactionDate === 'PERIOD'}
            onChange={(value: boolean) => setFormData({ ...formData, transactionDate: value ? 'PERIOD' : 'APPLIES' })}
            className="mb-4"
            height={30}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            label="Transaction Type(s)"
            className="flex-wrap"
            options={transactionTypeOptions}
            onChange={(options) =>
              setFormData((prev) => ({ ...prev, transactionTypeIds: (options ?? []).map((tt: any) => tt.value) }))
            }
            isLoading={getAllowedTransactionTypesLoading}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            value={transactionTypeOptions.filter((opt) => formData.transactionTypeIds.includes(opt.value))}
          />
        </Col>
      </Row>
    </ModalDrawer>
  );
};

export default TransactionsReportModal;
