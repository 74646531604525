import { useCreateRole, useUpdateRole } from 'gql/role/mutations';
import React, { useState, useCallback } from 'react';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useFlags } from 'launchdarkly-react-client-sdk';

import * as Mui from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';

interface IProps {
  isOpen: boolean;
  businessId: string;
  levelToCreateRoleAt: number;
  /**
   * If a role has been provided via props it can be assumed that we are editing an existing role
   */
  role: IRole | null;
  onRoleCreate: (role: IRole) => void;
  onRoleEdit: (role: IRole) => void;
  onClose: () => void;
}

type RoleScopeType = 'CENTER' | 'ENTITY' | 'UNASSIGNED';

const CreateUpdateRoleModal: React.FC<IProps> = ({
  isOpen,
  businessId,
  levelToCreateRoleAt,
  role,
  onRoleCreate,
  onRoleEdit,
  onClose,
  ...props
}) => {
  const { k2AddRoleTypes } = useFlags();
  const { t } = useTranslation('translation');
  const [roleName, setRoleName] = useState<string>(role?.name ?? '');
  const [roleType, setRoleType] = useState<RoleScopeType>(role?.scopeType ?? 'UNASSIGNED');
  const currentUser = useSelector((state: RootState) => state.user);
  const currentUserIsCenterLevel = currentUser?.isInternal ? false : currentUser?.role.scopeType === 'CENTER';
  const isDisable = role?.scopeType === 'ENTITY' || currentUserIsCenterLevel;

  const [createRoleFn, { data: returnData, loading: createRoleLoading }] = useCreateRole({
    onCompleted: (result) => {
      showToast('Created new role successfully.', 'success');
      onRoleCreate(result.createRole);
      handleClose();
    },
    onError: (err) => {
      showToast('There was an error trying to create this role. Please try again later.', 'error');
    },
  });

  const [updateRoleFn, { loading: updateRoleLoading }] = useUpdateRole({
    onCompleted: (result) => {
      showToast('Role updated successfully.', 'success');
      onRoleEdit(result.updateRole);
      handleClose();
    },
    onError: (err) => {
      showToast('There was an error trying to update this role. Please try again later.', 'error');
    },
  });

  const handleSubmit = useCallback(() => {
    if (role) {
      updateRoleFn({
        variables: {
          input: {
            roleId: role.id,
            name: roleName.trim(),
            //only change Center role to business
            scopeType: roleType === 'ENTITY' ? 'ENTITY' : 'CENTER',
            hierarchyLevel: role.hierarchyLevel, // can't edit level from this modal
            areaLevels: role.areaLevels.map((al) => ({
              roleId: al.roleId,
              area: al.area,
              permission: al.permission,
              level: al.level,
            })),
          },
        },
      });
    } else {
      createRoleFn({
        variables: {
          input: {
            businessId,
            name: roleName.trim(),
            hierarchyLevel: levelToCreateRoleAt,
            scopeType: roleType === 'ENTITY' ? 'ENTITY' : 'CENTER',
          },
        },
      });
    }
  }, [role, businessId, levelToCreateRoleAt, roleName, roleType, createRoleFn, updateRoleFn]);

  const handleClose = useCallback(() => {
    setRoleName('');
    onClose();
  }, [onClose]);

  return (
    <CenteredModal
      title={role ? 'Edit Role' : 'Create New Role'}
      show={isOpen}
      onHide={handleClose}
      primaryCallback={handleSubmit}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled:
          roleName.trim() === '' ||
          createRoleLoading ||
          updateRoleLoading ||
          (roleType === 'UNASSIGNED' && !role && k2AddRoleTypes),
        loading: createRoleLoading || updateRoleLoading,
      }}
    >
      <Mui.FormLabel id="transaction-type-radio-group">
        <span>Role Name</span>
      </Mui.FormLabel>
      <TextInput value={roleName} onChange={(value: string) => setRoleName(value)} />
      {k2AddRoleTypes && (
        <div className="d-flex flex-column">
          <Mui.RadioGroup
            aria-labelledby="transaction-type-radio-group"
            name="transaction-type-radio-buttons-group"
            onChange={(evt) => {
              // console.log(evt.target.value);
              if (evt.target.value === 'ENTITY') {
                setRoleType('ENTITY');
              } else if (evt.target.value === 'CENTER') {
                setRoleType('CENTER');
              } else {
                setRoleType('UNASSIGNED');
              }
            }}
            style={{ margin: 0 }}
            value={roleType}
          >
            <Mui.FormLabel id="transaction-type-radio-group">
              <span>Role Type</span>
            </Mui.FormLabel>
            {/* {role && (
              <Mui.Typography fontSize="0.75rem" color="rgba(0, 0, 0, 0.38)">
                (Cannot be modified when users are assigned)
              </Mui.Typography>
            )} */}
            <Mui.FormControlLabel
              value={'ENTITY'}
              style={{ margin: 0 }}
              control={<Mui.Radio size="small" style={{ margin: 0 }} />}
              label={'Business'}
              disabled={isDisable}
            />
            <Mui.FormControlLabel
              value={'CENTER'}
              style={{ margin: 0 }}
              control={<Mui.Radio size="small" style={{ margin: 0 }} />}
              label={_.capitalize(t('spelling.center'))}
              disabled={isDisable}
            />
          </Mui.RadioGroup>
        </div>
      )}
    </CenteredModal>
  );
};

export default CreateUpdateRoleModal;
