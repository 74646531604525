import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import TextInput from 'shared/components/TextInput';
import { useDestroyBusiness } from 'gql/business/mutations';
import { showToast } from 'shared/components/Toast';
import { destroyBusiness } from 'pages/Businesses/subroutes/BusinessProfiles/duck/actions';
import { useHistory } from 'react-router';

interface IProps {
  isOpen: boolean;
  businessId: string;
  businessName: string;
  onClose: () => void;
}

const DeleteBusinessModal: React.FC<IProps> = ({ isOpen, businessId, businessName, onClose, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [confirmBusinessName, setConfirmBusinessName] = useState<string>('');
  const [destroyBusinessFn, { loading }] = useDestroyBusiness({
    onCompleted: (result) => {
      dispatch(destroyBusiness(result.destroyBusiness));
      showToast('Deleted business successfully.', 'success');
      history.replace('/businesses/profiles');
    },
  });

  const handleClose = useCallback(() => {
    setConfirmBusinessName('');
    onClose();
  }, [onClose]);

  return (
    <ConfirmationModal
      show={isOpen}
      onHide={handleClose}
      title="Delete Business?"
      primaryChoice="I understand the consequences, delete this business"
      primaryCallback={() => destroyBusinessFn({ variables: { id: businessId } })}
      primaryButtonProps={{
        variant: 'danger',
        disabled: confirmBusinessName !== businessName || loading,
        className: 'w-100',
        loading,
      }}
      hideOnCallback={false}
      noSecondaryChoice
    >
      <div className="mb-4">
        This action <b>cannot</b> be undone, once taken. This will permanently delete the business <b>{businessName}</b>{' '}
        including but not limited to all associated data, people, and history.
      </div>
      <div>
        <TextInput
          label="Please type the name of this business to confirm."
          value={confirmBusinessName}
          onChange={(value: string) => setConfirmBusinessName(value)}
        />
      </div>
    </ConfirmationModal>
  );
};

export default DeleteBusinessModal;
