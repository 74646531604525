import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import PageWrapper from 'shared/components/PageWrapper';
import { CreateButton, CirclePlusButton } from 'shared/components/Buttons';
import useDatatableState from 'shared/hooks/useDatatableState2';
import FeesTable from 'shared/components/Fees/FeesTable';
import { useSearchFees } from 'gql/fee/queries';
import { RootState } from 'store/reducers';
import CreateFeeModal from './components/CreateFeeModal';
import EditFeeModal from './components/EditFeeModal';
import ActivateFeeModal from 'shared/components/Fees/ActivateFeeModal';
import DeactivateFeeModal from 'shared/components/Fees/DeactivateFeeModal';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import * as actions from './duck/actions';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { gql, useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IFeeModalState {
  show: boolean;
  fee: IFee | null;
}

interface IRouteProps {}

interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const searchFeesQuery = gql`
  query ($input: SearchFees2Input) {
    searchFees2(input: $input) {
      data {
        id
        name
        centerId
        classIds
        schedules {
          id
          startDate
          startTime
          endTime
          overtimeCost
          overtimeGracePeriod
          overtimeInterval
          rates {
            value
            qualifyingNumber
          }
          archivedAt
        }
        glCode
        glCodeMapping {
          id
          businessId
          glCodeId
          glCodeName
          glCodeCode
          feeId
          transactionTypeId
          agencyId
          adjustmentId
          discountId
          surchargeType
        }
        feeType
        feeStructure
        utilization
      }
      totalRecords
    }
  }
`;

interface searchFees2Input {
  input: {
    centerId: string | null;
    name?: string;
    classIds?: string[];
    showActive: boolean;
    showDeactivated: boolean;
    pageSize?: number;
    pageNumber?: number;
  };
}

function pullSearchTermFromElasticSearchFilter(searchExpressions: ISearchExpression[]): string | undefined {
  for (let expression of searchExpressions) {
    if (expression.any && expression.any.length > 0) {
      for (let anyExpression of expression.any) {
        if (anyExpression.term?.field === 'name') {
          return anyExpression.term.value;
        }
      }
    }
  }
  return;
}

const Fees: React.FC<IProps> = ({ ...props }) => {
  const { k2FeesFromDb } = useFlags();
  const dispatch = useDispatch();
  const {
    fees: { searchResults: feesData, searchSort, searchFilter, totalFees },
  } = useSelector((state: RootState) => state);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);

  const [tableState, tableFunctions] = useDatatableState();
  const [isCreateFeeOpen, setIsCreateFeeOpen] = useState(false);
  const [activateFeeModalState, setActivateFeeModalState] = useState<IFeeModalState>({ show: false, fee: null });
  const [deactivateFeeModalState, setDeactivateFeeModalState] = useState<IFeeModalState>({ show: false, fee: null });
  const [feeInEdit, setFeeInEdit] = useState<IFee | null>(null);

  const elasticQuery = tableFunctions.getElasticQuery(searchFilter, searchSort);
  const { loading: searchFeesLoading } = useQuery<any, searchFees2Input>(searchFeesQuery, {
    variables: {
      input: {
        centerId: currentCenterId,
        name: pullSearchTermFromElasticSearchFilter(tableState.searchExpressions),
        classIds: searchFilter.class?.map((classFilter) => classFilter.value) ?? [],
        showActive: !!searchFilter.status?.find((statusExpression) => statusExpression.value === 'active'),
        showDeactivated: !!searchFilter.status?.find((statusExression) => statusExression.value === 'deactivated'),
        pageSize: tableState.pageSize,
        pageNumber: tableState.activePage,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !currentCenterId || !k2FeesFromDb,
    onCompleted: (data) => {
      if (!k2FeesFromDb) return;
      dispatch(actions.searchFees(data?.searchFees2?.data ?? [], data?.searchFees2?.totalRecords));
    },
  });
  const { loading: searchFeesLoadingOld } = useSearchFees({
    variables: {
      input: {
        ...elasticQuery,
        filter: {
          all: [
            ...(elasticQuery.filter.all ?? []),
            {
              [SEARCH_EXPRESSIONS.TERM]: {
                field: 'centerId.keyword',
                predicate: PREDICATES.EQUALS,
                value: currentCenterId ?? '',
              },
            },
          ],
        },
      },
    },
    skip: !currentCenterId || k2FeesFromDb,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (k2FeesFromDb) return;
      dispatch(actions.searchFees(data?.searchFees?.data ?? [], data?.searchFees?.totalResults));
    },
  });

  const displayDeactivateFeeModal = useCallback((fee: IFee) => {
    setActivateFeeModalState({ show: false, fee: null });
    setDeactivateFeeModalState({ show: true, fee });
  }, []);

  const displayActivateFeeModal = useCallback((fee: IFee) => {
    setDeactivateFeeModalState({ show: false, fee: null });
    setActivateFeeModalState({ show: true, fee });
  }, []);

  // when a fee is deactivated or reactivated from within edit modal, we need to update the passed in fee to reflect changes
  useEffect(() => {
    if (feeInEdit) {
      const latestSavedFee = feesData.find((f) => f.id === feeInEdit.id);
      latestSavedFee && setFeeInEdit(latestSavedFee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feesData]);

  return (
    <PageWrapper
      pageTitle="Fees"
      mobileButtonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Center, permission: PermissionType.Fees, level: RoleLevelType.Create }}
        >
          <CirclePlusButton variant="primary" className="my-4" onClick={() => setIsCreateFeeOpen(true)} />
        </HasRoleAreaLevel>
      }
      buttonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Center, permission: PermissionType.Fees, level: RoleLevelType.Create }}
        >
          <CreateButton id="create-fee-btn" onClick={() => setIsCreateFeeOpen(true)}>
            Add Fee
          </CreateButton>
        </HasRoleAreaLevel>
      }
      applyPadding={true}
    >
      <CenterSelectBanner pageName="fees" />
      <Container fluid>
        <FeesTable
          data={currentCenterId && feesData ? feesData : []}
          loading={k2FeesFromDb ? searchFeesLoading : searchFeesLoadingOld}
          totalFees={totalFees}
          tableState={tableState}
          tableFunctions={tableFunctions}
          handleRowClick={(e, fee) => setFeeInEdit(fee)}
          handleDeactivateFee={displayDeactivateFeeModal}
          handleReactivateFee={displayActivateFeeModal}
          activeCenterId={currentCenterId}
          noPadding={true}
        />
      </Container>
      {currentCenterId && isCreateFeeOpen && (
        <CreateFeeModal
          centerId={currentCenterId}
          isOpen={isCreateFeeOpen}
          onClose={() => {
            setIsCreateFeeOpen(false);
          }}
        />
      )}
      {activateFeeModalState.fee && (
        <ActivateFeeModal
          isOpen={activateFeeModalState.show}
          fee={activateFeeModalState.fee}
          onClose={() => setActivateFeeModalState({ show: false, fee: null })}
        />
      )}
      {deactivateFeeModalState.fee && (
        <DeactivateFeeModal
          isOpen={deactivateFeeModalState.show}
          fee={deactivateFeeModalState.fee}
          onClose={() => {
            setDeactivateFeeModalState({ show: false, fee: null });
          }}
        />
      )}
      {feeInEdit && (
        <EditFeeModal
          fee={feeInEdit}
          isOpen={Boolean(feeInEdit)}
          onClose={() => {
            setFeeInEdit(null);
          }}
          handleDeactivateFee={displayDeactivateFeeModal}
          handleReactivateFee={displayActivateFeeModal}
        />
      )}
    </PageWrapper>
  );
};

export default Fees;
