import {
  AddressDiff,
  BillingCycleDiff,
  ChildRelationshipDiff,
  ContactDiff,
  CustomFieldValueDataDiff,
  DiffDto,
  DiffState,
  DocumentUploadDiff,
  EnrollmentDiff,
  Maybe,
  MedicalInfoDto,
  PaymentDiff,
  PhoneNumberDiff,
} from 'generated/graphql';
import { capitalize } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnrollmentFormDiffDocument from './EnrollmentFormDiffDocument';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import _ from 'lodash';
import moment from 'moment';

type ContactPermissions =
  | 'EmergencyContact'
  | 'PermissionToPickUp'
  | 'LiveWith'
  | 'GiveMedicalTreatment'
  | 'GiveAmbulanceTransportation'
  | 'GiveConsentRegularOutings';

export enum EnrolmentFormAreaEnum {
  Account = 'Account',
  Child = 'Child',
  SecondaryContact = 'SecondaryContact',
  PrimaryContacts = 'PrimaryContact',
}

export interface childAndContactDetailsSimplified {
  id: string;
  fullName: string;
  isNew: boolean;
}

export interface FormattedEnrolmentDiffAreas {
  totalDiffs: number;
  diffState: DiffState;
  allChildren: childAndContactDetailsSimplified[];
  allSecondaryContacts: childAndContactDetailsSimplified[];
  allPrimaryContacts: childAndContactDetailsSimplified[];
  diffSections: DiffSection[];
}

export interface DiffSection {
  area: EnrolmentFormAreaEnum;
  areaTitle: string;
  areaLinkTitle?: string | undefined;
  diffAreas: DiffArea[];
  areaLinkUrl?: string | undefined | null;
  isNew?: boolean;
}

export interface DiffArea {
  sectionTitle: string;
  diffList: Diff[];
}

export interface Diff {
  label: string;
  oldValue?: React.ReactNode;
  newValue: React.ReactNode;
}

//returning null for accountLink because it is handled by a separate component
const getSectionLink = (
  section: EnrolmentFormAreaEnum,
  id?: string | null,
  dataHasBeenMigrated?: boolean | null,
  isNewChildOrContact?: boolean | null
) => {
  if (!id || id === '' || (!dataHasBeenMigrated && isNewChildOrContact)) {
    return null;
  }

  switch (section) {
    case EnrolmentFormAreaEnum.Child:
      return `/families/children/${id}`;
    case EnrolmentFormAreaEnum.PrimaryContacts:
    case EnrolmentFormAreaEnum.SecondaryContact:
      return `/families/contacts/${id}`;
    default:
      return null;
  }
};

const getChangedSystemFields = (systemFields: DiffDto[], isNewChildOrContact: boolean) => {
  return systemFields.filter(
    (diff) =>
      diff.diffState !== DiffState.Unchanged &&
      diff.oldValue !== diff.newValue &&
      // ignore "added" properties that dont have any values
      !(isNewChildOrContact && (diff.newValue === '' || !diff.newValue))
  );
};

const getChangedThridPartySystemFields = (customFields: CustomFieldValueDataDiff[]) => {
  return customFields.filter(
    (diff) => diff.diffState !== DiffState.Unchanged && diff.thirdParty && diff.oldValue !== diff.newValue
  );
};

const getChangedCustomFields = (customFields: CustomFieldValueDataDiff[]) => {
  return customFields.filter(
    (diff) => diff.diffState !== DiffState.Unchanged && !diff.thirdParty && diff.oldValue !== diff.newValue
  );
};

const getRelationshipFields = (relationships: ChildRelationshipDiff[]) => {
  return relationships.filter((diff) => diff.diffState !== DiffState.Unchanged);
};

export const useEnrolmentFormDiffValueFormatter = (enrolmentDiff: EnrollmentDiff | undefined) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const [formattedDiffData, setFormattedDiffData] = useState<FormattedEnrolmentDiffAreas | undefined>();

  const formatLabelString = (label: string, isSystemField: boolean) => {
    if (!isSystemField) return label;
    const result = label.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const formatValueString = useCallback(
    (label: string, isNewValue: boolean, value?: string | null) => {
      if ((value === '' || !value) && !isNewValue) return 'None';

      if (label === 'DateOfBirth') {
        return moment(value?.split('T')[0] ?? '').format(t('translation:formatters.date'));
      }

      // standard boolean value
      const valueLowercase = value?.toLowerCase();
      if (valueLowercase === 'true' || valueLowercase === 'false') return capitalize(value ?? '');

      // document type value
      if (value?.includes('FileId:') && isNewValue) {
        const fileId = value.substring(value.indexOf('FileId:') + 7, value.lastIndexOf(':FileName:'));
        const fileName = value.substring(value.indexOf(':FileName:') + 11, value.length);
        return <EnrollmentFormDiffDocument documentId={fileId ?? ''} documentName={fileName} />;
      }

      // special case for indigenousStatus
      if (label === 'indigenousStatus') {
        const valueAsIndigenousStatus = value as IndigenousStatus;
        return t(`enrollment:enrolment-form-diffs.indigenousStatus.${valueAsIndigenousStatus}`);
      }

      // if there are no spaces and its not an email we assume that the value is camel case and we convert it to title case
      if (value && !value.includes(' ') && !value.includes('@')) {
        const result = value.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
      }

      return value;
    },
    [t]
  );

  const formatRelationshipValue = useCallback(
    (fieldName: string, value?: string | null) => {
      if (value === undefined || value == null || value === '' || value.toLowerCase() == 'false') return 'None';
      return t(`enrollment:enrolment-form-diffs.permissions.${fieldName as ContactPermissions}`);
    },
    [t]
  );

  const formatSimpleDiff = useCallback(
    (label: string, isSystemField: boolean, oldValue?: string | null, newValue?: string | null) => {
      return {
        label: formatLabelString(label, isSystemField),
        oldValue: <div className="oldVal">{formatValueString(label, false, oldValue)}</div>,
        newValue: <div className="newVal">{formatValueString(label, true, newValue)}</div>,
      };
    },
    [formatValueString]
  );

  // payment will always be new if there is a change
  const formatPaymentDiff = useCallback(
    (paymentDiff: PaymentDiff) => {
      return [
        {
          label: t('enrollment:enrolment-form-diffs.payment-diff.title'),
          oldValue: <div className="oldVal">{paymentDiff.diffState === DiffState.Added ? 'None' : ''}</div>,
          newValue: (
            <div className="newVal">
              {t('enrollment:enrolment-form-diffs.payment-diff.details', { diffState: paymentDiff.diffState })}
            </div>
          ),
        },
      ];
    },
    [t]
  );

  const formatBillingCycleDiff = useCallback(
    (billingCycleDiff: BillingCycleDiff) => {
      const billingCycleId = billingCycleDiff.systemFields.find((diff) => diff.name === 'BillingCycleTemplateId');
      return [
        {
          label: t('enrollment:enrolment-form-diffs.billingCycle-diff.title'),
          oldValue: <>{billingCycleId?.newValue ?? billingCycleId?.oldValue ?? ''}</>,
          newValue: (
            <div className="newVal">
              {t('enrollment:enrolment-form-diffs.billingCycle-diff.details', {
                diffState: billingCycleDiff.diffState,
              })}
            </div>
          ),
        },
      ];
    },
    [t]
  );

  const formatMedicalInfoDiff = (medicalInfoList: MedicalInfoDto[]) => {
    return medicalInfoList.map((medicalInfo) => {
      const category = medicalInfo.category?.value ?? 'Medical';
      return {
        label: category,
        newValue: (
          <div className="newVal">
            <span>{category === 'Medical' ? medicalInfo.medicalName : medicalInfo.type?.label}</span>
            <ul className="medicalInfo-vals">
              {category !== 'Medical' && medicalInfo.type?.label && <li>Type - {medicalInfo.type.label}</li>}
              {medicalInfo.risk?.label && <li>Risk - {medicalInfo.risk.label}</li>}
              {medicalInfo.symptoms?.label && <li>Syptoms - {medicalInfo.symptoms.label}</li>}
              {medicalInfo.note && <li>Care Instructions - {medicalInfo.note}</li>}
              {medicalInfo.documents &&
                medicalInfo.documents.length > 0 &&
                medicalInfo.documents.map((document) => {
                  return (
                    <li>
                      File -{' '}
                      <EnrollmentFormDiffDocument
                        documentId={document.id ?? document.file ?? ''}
                        documentName={document.fileName}
                        expiry={document.expiryDate}
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
        ),
      };
    });
  };

  const formatDocumentDiff = (label: string, documentUploadDiff?: DocumentUploadDiff) => {
    const fileId = documentUploadDiff?.systemFields?.find((field) => field.name.toLowerCase() === 'File');
    const fileName = documentUploadDiff?.systemFields?.find((field) => field.name.toLowerCase() === 'File');
    const expiry = documentUploadDiff?.systemFields?.find((field) => field.name.toLowerCase() === 'File');

    return [
      {
        label: label,
        oldValue: <div className="oldVal">{fileName?.oldValue ?? 'None'}</div>,
        newValue: (
          <div className="newVal">
            <EnrollmentFormDiffDocument
              documentId={fileId?.newValue ?? ''}
              documentName={fileName?.newValue}
              expiry={expiry?.newValue}
            />
          </div>
        ),
      },
    ];
  };

  const formatRelationshipDiff = useCallback(
    (fields: DiffDto[]) => {
      const childId = fields.find((f) => f.name === 'ChildId');
      const child = enrolmentDiff?.children.find(
        // the new/old value could be an empty guid depending on if the relationship was removed/added
        (c) => c.childId === childId?.newValue || c.childId === childId?.oldValue
      );
      const childFullName = `${child?.firstName} ${child?.lastName}`;

      const relationship = fields.find((f) => f.name === 'Relationship');
      const relationshipValue = relationship?.newValue ?? relationship?.oldValue;
      const permissions = fields.filter(
        (f) =>
          f.name !== 'ChildId' &&
          f.name !== 'Relationship' &&
          f.diffState !== DiffState.Unchanged &&
          f.oldValue !== f.newValue
      );

      return permissions.map((diff) => {
        return {
          label: `${childFullName} - ${
            childContactRelationship[_.startCase(_.camelCase(relationshipValue ?? 'Relative'))]
          }`,
          oldValue: <div className="oldVal">{formatRelationshipValue(diff.name, diff.oldValue)}</div>,
          newValue: <div className="newVal">{formatRelationshipValue(diff.name, diff.newValue)}</div>,
        };
      });
    },
    [enrolmentDiff?.children, formatRelationshipValue]
  );

  const formatAddressDiff = useCallback((addressDiffs: Maybe<AddressDiff> | undefined) => {
    const oldValue = addressDiffs?.systemFields
      .filter((diff) => diff.oldValue !== '' && diff.oldValue !== null && diff.oldValue !== undefined)
      .map((diff) => {
        if (diff.name == 'State') {
          return diff.oldValue?.substring(diff.oldValue?.indexOf('-') + 1);
        }
        return diff.oldValue;
      })
      .join(', ');

    const newValue = addressDiffs?.systemFields
      .filter((diff) => diff.newValue !== '' && diff.newValue !== null && diff.newValue !== undefined)
      .map((diff) => {
        if (diff.name == 'State') {
          return diff.newValue?.substring(diff.newValue?.indexOf('-') + 1);
        }
        return diff.newValue;
      })
      .join(', ');

    return [
      {
        label: 'Address',
        oldValue: <div className="oldVal">{oldValue === '' ? 'None' : oldValue}</div>,
        newValue: <div className="newVal">{newValue}</div>,
      },
    ];
  }, []);

  // phone numbers can only ever be added or removed (never updated)
  const formatPhoneNumberDiff = useCallback((phoneNumberDiffs: PhoneNumberDiff[]) => {
    const removedPhoneNumbers = phoneNumberDiffs
      .filter((diff) => diff.diffState === DiffState.Removed)
      .map((c) => c.systemFields.find((diff) => diff.name === 'Number')?.oldValue);

    const addedPhoneNumbers = phoneNumberDiffs
      .filter((diff) => diff.diffState === DiffState.Added)
      .map((c) => c.systemFields.find((diff) => diff.name === 'Number')?.newValue);

    return [
      {
        label: 'Added Phone Numbers',
        oldValue: (
          <div className="oldVal">
            {removedPhoneNumbers.length > 0 ? removedPhoneNumbers.map((number) => <div>{number}</div>) : 'None'}
          </div>
        ),
        newValue: (
          <div className="newVal">
            {addedPhoneNumbers.map((number) => (
              <div>{number}</div>
            ))}
          </div>
        ),
      },
    ];
  }, []);

  const formatSystemFields = useCallback(
    (
      thirdPartyFields: CustomFieldValueDataDiff[],
      systemFields: DiffDto[],
      addressDiff?: Maybe<AddressDiff> | undefined,
      phoneNumberDiff?: Maybe<PhoneNumberDiff[]> | undefined
    ) => {
      return [
        {
          sectionTitle: 'General',
          diffList: [
            ...(systemFields.length > 0
              ? systemFields.map((diff) => formatSimpleDiff(diff.name, true, diff.oldValue, diff.newValue))
              : []),
            ...(thirdPartyFields.length > 0
              ? thirdPartyFields.map((diff) => formatSimpleDiff(diff.name, false, diff.oldValue, diff.newValue))
              : []),
            ...(addressDiff && addressDiff.diffState !== DiffState.Unchanged ? formatAddressDiff(addressDiff) : []),
            ...(phoneNumberDiff && phoneNumberDiff.length > 0 ? formatPhoneNumberDiff(phoneNumberDiff) : []),
          ],
        },
      ];
    },
    [formatAddressDiff, formatPhoneNumberDiff, formatSimpleDiff]
  );

  const formatCustomFields = useCallback(
    (customFields: CustomFieldValueDataDiff[]) => {
      return [
        {
          sectionTitle: 'Custom',
          diffList: customFields.map((diff) => formatSimpleDiff(diff.name, true, diff.oldValue, diff.newValue)),
        },
      ];
    },
    [formatSimpleDiff]
  );

  const formatMedicalInfoAndImmunization = useCallback(
    (medicalInfo: MedicalInfoDto[], immunization?: DocumentUploadDiff | null) => {
      return [
        {
          sectionTitle: `Medical Information & ${capitalize(t('translation:spelling.immunization'))}`,
          diffList: [
            ...(medicalInfo.length > 0 ? formatMedicalInfoDiff(medicalInfo) : []),
            ...(immunization
              ? formatDocumentDiff(capitalize(t('translation:spelling.immunization')), immunization)
              : []),
          ],
        },
      ];
    },
    [t]
  );

  const formatRelationships = useCallback(
    (relationships: ChildRelationshipDiff[]) => {
      const updatedAndAddedRelationships = relationships.filter(
        (r) => r.diffState === DiffState.Updated || r.diffState == DiffState.Added
      );
      const removedRelationships = relationships.filter((r) => r.diffState === DiffState.Removed);
      return [
        {
          sectionTitle: 'Relationships',
          diffList: [
            ...(removedRelationships.length > 0
              ? removedRelationships.map((relationship) => {
                  const relationshipType = relationship.systemFields.find((f) => f.name === 'Relationship');
                  const relationshipTypeValue = relationshipType?.newValue ?? relationshipType?.oldValue;
                  return {
                    label: 'Removed Relationship',
                    newValue: (
                      <div className="newVal">
                        {childContactRelationship[_.startCase(_.camelCase(relationshipTypeValue ?? 'Relative'))]}
                      </div>
                    ),
                  };
                })
              : []),
            ...updatedAndAddedRelationships.flatMap((diff) => {
              return formatRelationshipDiff(diff.systemFields);
            }),
          ],
        },
      ];
    },
    [formatRelationshipDiff]
  );

  const formatAccountTypeSection = useCallback(
    (enrolmentDiff: EnrollmentDiff) => {
      const systemFields = getChangedSystemFields(enrolmentDiff.systemFields, false);
      const thirdPartySystemFields = getChangedThridPartySystemFields(enrolmentDiff.customFields);
      const customFields = getChangedCustomFields(enrolmentDiff.customFields);
      const changeInBillingCycle = enrolmentDiff.billingCycle?.diffState !== DiffState.Unchanged;
      const changeInPayment = enrolmentDiff.payment?.diffState !== DiffState.Unchanged;

      if (
        !(
          systemFields.length > 0 ||
          thirdPartySystemFields.length > 0 ||
          customFields.length > 0 ||
          changeInBillingCycle ||
          changeInPayment
        )
      ) {
        return null;
      }

      return [
        {
          area: EnrolmentFormAreaEnum.Account,
          areaTitle: 'Account',
          diffAreas: [
            ...(changeInBillingCycle || changeInPayment
              ? [
                  {
                    sectionTitle: 'Payment',
                    diffList: [
                      ...(enrolmentDiff.payment.diffState !== DiffState.Unchanged
                        ? formatPaymentDiff(enrolmentDiff.payment)
                        : []),
                      ...(enrolmentDiff.billingCycle && enrolmentDiff.billingCycle?.diffState !== DiffState.Unchanged
                        ? formatBillingCycleDiff(enrolmentDiff.billingCycle)
                        : []),
                    ],
                  },
                ]
              : []),
            ...(thirdPartySystemFields.length > 0 || systemFields.length > 0
              ? formatSystemFields(thirdPartySystemFields, systemFields)
              : []),
            ...(customFields.length > 0 ? formatCustomFields(customFields) : []),
          ],
        },
      ];
    },
    [formatBillingCycleDiff, formatCustomFields, formatPaymentDiff, formatSystemFields]
  );

  const formatChildTypeSection = useCallback(
    (enrolmentDiff: EnrollmentDiff) => {
      const childrenWithChanges = enrolmentDiff.children.filter((child) => child.diffState !== DiffState.Unchanged);
      if (childrenWithChanges.length <= 0) {
        return null;
      }

      return childrenWithChanges.map((child) => {
        const childFullName = `${child.firstName} ${child.lastName}`;
        const systemFields = getChangedSystemFields(child.systemFields, child.isNewChild);
        const thirdPartySystemFields = getChangedThridPartySystemFields(child.customFields);
        const customFields = getChangedCustomFields(child.customFields);
        const medicalInfoUpdated = child.medicalInfo.length > 0;
        const immunizationUpdated =
          child.immunization !== null && child.immunization?.diffState !== DiffState.Unchanged;

        return {
          area: EnrolmentFormAreaEnum.Child,
          areaTitle: 'Child',
          isNew: child.isNewChild,
          areaLinkTitle: childFullName,
          areaLinkUrl: getSectionLink(EnrolmentFormAreaEnum.Child, child.childId),
          diffAreas: [
            ...(thirdPartySystemFields.length > 0 || systemFields.length > 0
              ? formatSystemFields(thirdPartySystemFields, systemFields)
              : []),
            ...(medicalInfoUpdated || immunizationUpdated
              ? formatMedicalInfoAndImmunization(child.medicalInfo, child.immunization)
              : []),
            ...(customFields.length > 0 ? formatCustomFields(customFields) : []),
          ],
        };
      });
    },
    [formatCustomFields, formatMedicalInfoAndImmunization, formatSystemFields]
  );

  const formatContactsTypeSection = useCallback(
    (changedContacts: ContactDiff[], enrolmentFormAreaEnum: EnrolmentFormAreaEnum) => {
      if (changedContacts.length <= 0) {
        return [];
      }

      return changedContacts.map((contact) => {
        const areaTitle =
          enrolmentFormAreaEnum === EnrolmentFormAreaEnum.PrimaryContacts ? 'Primary Contact' : 'Contact';
        const isNewContact = contact.diffState === DiffState.Added;
        const contactFullName = `${contact.firstName} ${contact.lastName}`;

        const systemFields = getChangedSystemFields(contact.systemFields ?? [], isNewContact);
        const thirdPartySystemFields = getChangedThridPartySystemFields(contact.customFields ?? []);
        const customFields = getChangedCustomFields(contact.customFields ?? []);
        const relationships = getRelationshipFields(contact.relationships ?? []);

        const changedPhoneNumbers = contact.phoneNumbers?.filter(
          (phoneDiff) => phoneDiff.diffState !== DiffState.Unchanged
        );

        return {
          area: enrolmentFormAreaEnum,
          areaTitle: areaTitle,
          isNew: isNewContact,
          areaLinkTitle: contactFullName,
          areaLinkUrl: getSectionLink(enrolmentFormAreaEnum, contact.id),
          diffAreas: [
            ...(thirdPartySystemFields.length > 0 ||
            systemFields.length > 0 ||
            contact.address?.diffState !== DiffState.Unchanged ||
            (changedPhoneNumbers?.length ?? 0) > 0
              ? formatSystemFields(thirdPartySystemFields, systemFields, contact.address, changedPhoneNumbers)
              : []),
            ...(customFields.length > 0 ? formatCustomFields(customFields) : []),
            ...(relationships.length > 0 ? formatRelationships(relationships) : []),
          ],
        };
      });
    },
    [formatCustomFields, formatRelationships, formatSystemFields]
  );

  useEffect(() => {
    if (enrolmentDiff) {
      const initialData = enrolmentDiff;

      setFormattedDiffData({
        totalDiffs: initialData.diffCount,
        diffState: initialData.diffState,
        allChildren: initialData.children.map((child) => ({
          id: child.childId,
          fullName: `${child.firstName} ${child.lastName}`,
          isNew: child.isNewChild,
        })),
        allSecondaryContacts: initialData.contacts.map((contact) => ({
          id: contact.id ?? '',
          fullName: `${contact.firstName} ${contact.lastName}`,
          isNew: contact.diffState == DiffState.Added,
        })),
        allPrimaryContacts: initialData.primaryContacts.map((primary) => ({
          id: primary.id ?? '',
          fullName: `${primary.firstName} ${primary.lastName}`,
          isNew: primary.diffState == DiffState.Added,
        })),
        diffSections: [
          ...(formatAccountTypeSection(enrolmentDiff) ?? []),
          ...(formatChildTypeSection(enrolmentDiff) ?? []),
          ...(formatContactsTypeSection(
            enrolmentDiff.primaryContacts.filter((c) => c.diffState !== DiffState.Unchanged),
            EnrolmentFormAreaEnum.PrimaryContacts
          ) ?? []),
          ...(formatContactsTypeSection(
            enrolmentDiff.contacts.filter((c) => c.diffState !== DiffState.Unchanged),
            EnrolmentFormAreaEnum.SecondaryContact
          ) ?? []),
        ],
      });
    }
  }, [enrolmentDiff, formatAccountTypeSection, formatChildTypeSection, formatContactsTypeSection]);

  return formattedDiffData;
};
