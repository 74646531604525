import { gql } from '@apollo/client';

// TO DO: fix heuristic fragment matching error from union of scope types

export const GET_USER = gql`
  query {
    getMe {
      __typename
      id
      firstname
      lastname
      email
      ... on Staff {
        entityId
        employmentStatus
        primaryCenterId
        pin
        roleship {
          roleId
          name
          permissions {
            action
          }
          scopeType
          scopes {
            ... on Center {
              id
              name
              entityId
            }
            ... on Entity {
              name
              id
              primaryPaymentMethodId
              payrixCustomerId
              hubSpotCustomerId
              timezone
              centers {
                id
                name
                entityId
              }
            }
          }
        }
      }
    }
  }
`;
