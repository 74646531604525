import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PREDICATES, SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { useSelector } from 'react-redux';
import DataTable from 'shared/components/DataTable';
import { capitalize, debounce, orderBy, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import ReEnrollAccountsTableHeader from '../ReEnrollAccountsTableHeader/ReEnrollAccountsTableHeader';
import SelectedItemsTotal from '../SelectedItemsTotal';
import {
  AccountStatusType,
  ReEnrollmentAccount,
  SearchSort,
  SortDirection,
  useGetReEnrolCenterSettingsQuery,
  useGetReenrollmentAccountsQuery,
  useSendReEnrollmentMutation,
} from 'generated/graphql';
import { RootState } from 'store/reducers';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { showToast } from 'shared/components/Toast';
import moment from 'moment';
import ReEnrollBulkActions from '../ReEnrollBulkActions';
import ChildrenTable from '../ChildrenTable';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import { useSearchCenters } from 'gql/center/queries';
import { SEARCH_MY_CENTERS } from 'pages/Centers/subroutes/Profiles/graphql/fields';
import RequestUpdateEnrolmentFormAction from 'pages/Enrollment/subroutes/Management/Tabs/ReEnrollment/Tabs/ReEnrollAccounts/components/RequestUpdateEnrolmentFormAction/RequestUpdateEnrolmentFormAction';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReEnrollSendRequestsModal from '../ReEnrollSendRequestsModal';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import AccountLink from 'shared/components/AccountLink/AccountLink';

const { TERM } = SEARCH_EXPRESSIONS;
const { CONTAINS } = PREDICATES;

interface IProps {}

const ReEnrollAccountsTable: React.FC<IProps> = ({ ...props }) => {
  const [showSendRequests, setSendRequestsOpen] = useState(false);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const { t } = useTranslation(['translation', 'enrollment']);
  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];

  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [centerFilters, setCenterFilters] = useState<ITableFilterOption[]>([]);
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [tagFilters, setTagFilters] = useState<ITableFilterOption[]>([]);
  const hasSendRequestPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.FamilyEnrollmentRequests,
    level: RoleLevelType.Edit,
  });
  const hasUpdateDetailsPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.FamilyUpdateDetailsRequests,
    level: RoleLevelType.Edit,
  });
  const [
    sendReEnrollmentMutation,
    { data: sendReenrolmentData, loading: sendReenrolmentLoading, error: sendReenrolmentError },
  ] = useSendReEnrollmentMutation({
    onError: (err) => showToast(t('translation:enrollment.re-enrollment.send-reenrollments.error'), 'error'),
    onCompleted: (response) => {
      setSendRequestsOpen(false);
      showToast(t('translation:enrollment.re-enrollment.send-reenrollments.success'), 'success');
    },
  });
  const [statusFilters, setStatusFilters] = useState<ITableFilterOption[]>([
    { label: AccountStatusType.Active, value: AccountStatusType.Active },
  ]);

  const { data: centersData, loading: loadingCenters } = useSearchCenters(
    {
      variables: {
        input: {
          filter: { term: { field: 'active', predicate: 'ACTIVE' } },
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          size: 10000,
          from: 0,
        },
      },
    },
    SEARCH_MY_CENTERS
  );

  const [centers, setCenters] = useState<ICenter[]>([]);
  useEffect(() => {
    setCenters(centersData?.searchCenters.data ?? []);
  }, [centersData]);

  const handleSendAccountRequest = (row: ReEnrollmentAccount) => {
    setSelectedRows([row]);
    setSendRequestsOpen(true);
  };

  const centerOptions: ITableFilterOption[] = useMemo(
    () =>
      orderBy(centers, (center) => center?.name, 'asc').map((center) => ({
        label: center?.name ?? '',
        value: center?.id ?? '',
        searchExpression: {
          [SEARCH_EXPRESSIONS.TERM]: {
            field: 'centerId.keyword',
            predicate: PREDICATES.CONTAINS,
            value: center?.id,
          },
        },
      })),
    [centers]
  );

  const statusOptions: ITableFilterOption[] = useMemo(() => {
    return Object.values(AccountStatusType)
      .filter((accountStatus) => AccountStatusType.Future !== accountStatus)
      .map((accountStatus) => {
        return { label: accountStatus, value: accountStatus };
      });
  }, []);

  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name']).map((tag) => ({
    label: tag.name,
    value: tag.id,
    searchExpression: { [TERM]: { field: 'tags.keyword', predicate: CONTAINS, value: tag.id } },
  }));

  const [sortDto, setSortDto] = useState<SearchSort>({
    direction: SortDirection.Ascending,
    field: 'accountName',
  });

  const [finalCenterFilters, setFinalCenterFilters] = useState<string[]>([]);
  useEffect(() => {
    const selectedTagsIds = tagFilters.map((t) => t.value);
    const selectedCenterIds = centerFilters.map((c) => c.value);

    if (centerFilters.length > 0 && tagFilters.length > 0) {
      setFinalCenterFilters(
        centers
          .filter((c) => selectedCenterIds.includes(c.id))
          .filter((c) => c.tags.some((t) => selectedTagsIds.includes(t.id)))
          .map((c) => c.id)
      );
    } else if (centerFilters.length > 0) {
      setFinalCenterFilters(centerFilters.map((c) => c.value));
    } else if (tagFilters.length > 0) {
      setFinalCenterFilters(centers.filter((c) => c.tags.some((t) => selectedTagsIds.includes(t.id))).map((c) => c.id));
    } else {
      setFinalCenterFilters(centers.map((c) => c.id));
    }
  }, [centers, centerFilters, tagFilters]);

  const { data, loading, error } = useGetReenrollmentAccountsQuery({
    skip: businessId === null || businessId === undefined || businessId === '' || centers.length === 0,
    variables: {
      input: {
        businessId: businessId ?? '',
        filter: {
          includeAllAccountIds: allRowsSelected,
          centerIds: finalCenterFilters,
          accountStatuses:
            statusFilters.length > 0
              ? statusFilters.map((s) => s.value as AccountStatusType)
              : [AccountStatusType.Active, AccountStatusType.Inactive],
          asOfDate: moment().format('YYYY-MM-DD'),
          accountNameSearchTerm: searchTerm,
        },
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortDtos: [sortDto],
      },
    },
  });

  const [mainData, setMainData] = useState<ReEnrollmentAccount[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  useEffect(() => {
    setMainData(
      data?.getReenrollmentAccounts?.data?.map((c) => {
        return { ...c, id: c.accountId };
      }) ?? []
    );
    setTotalRecords(data?.getReenrollmentAccounts?.totalRecords ?? 0);
    if (error) showToast('Unable to get accounts', 'error');
  }, [data, error]);

  const expandRow = useCallback(
    (row: ReEnrollmentAccount) => {
      return (
        <ChildrenTable
          accountId={row.accountId}
          accountCenterId={row.centerId}
          handleSendRequest={() => handleSendAccountRequest(row)}
          isSubmitting={sendReenrolmentLoading}
        />
      );
    },
    [sendReenrolmentLoading]
  );

  const handleSearchDebounced = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      tableFunctions.changePage(1, tableState.pageSize);
      setAllRowsSelected(false);
      setSelectedRows([]);
    }, 250),
    []
  );

  const handleFiltersSelect = (values: ITableFilterOption[], target: string) => {
    switch (target) {
      case 'center':
        setCenterFilters(values);
        break;
      case 'tag':
        setTagFilters(values);
        break;
      case 'status':
        setStatusFilters(values);
        break;
      default:
        break;
    }
    tableFunctions.changePage(1, tableState.pageSize);
    setAllRowsSelected(false);
    setSelectedRows([]);
  };

  const handleClearFilters = useCallback(() => {
    setCenterFilters([]);
    setTagFilters([]);
    setStatusFilters([]);
    setAllRowsSelected(false);
    setSelectedRows([]);
  }, []);

  const filterBy = {
    includeAllAccountIds: allRowsSelected,
    centerIds: finalCenterFilters,
    accountStatuses:
      statusFilters.length > 0
        ? statusFilters.map((s) => s.value as AccountStatusType)
        : [AccountStatusType.Active, AccountStatusType.Inactive],
    asOfDate: moment().format('YYYY-MM-DD'),
    accountNameSearchTerm: searchTerm,
    includeAccountIds: selectedRows.map((c) => c.id),
  };

  const onConfirm = (requireForm: boolean | null) => {
    sendReEnrollmentMutation({
      variables: {
        input: {
          businessId: businessId ?? '',
          filter: filterBy,
          centerIds: selectedCenters?.map((c: any) => c.id) ?? null,
          usesEnrolmentFormOverride: requireForm,
        },
      },
    });
  };

  const getTableHeader = useCallback(
    (paginationProps: any) => {
      return (
        <>
          <ReEnrollAccountsTableHeader
            searchText={searchTerm}
            setSearchText={handleSearchDebounced}
            paginationProps={paginationProps}
            handleFiltersSelect={handleFiltersSelect}
            handleClearFilters={handleClearFilters}
            filters={[
              {
                title: capitalize(t('translation:spelling.center')),
                type: 'center',
                filter: centerFilters,
                options: centerOptions,
              },
              {
                title: `${capitalize(t('translation:spelling.center'))} Tags`,
                type: 'tag',
                filter: tagFilters,
                options: tagOptions,
              },
              {
                title: 'Status',
                type: 'status',
                filter: statusFilters,
                options: statusOptions,
              },
            ]}
          />
          <ReEnrollSendRequestsModal
            show={showSendRequests}
            onCancel={() => setSendRequestsOpen(false)}
            setSelectedCenters={(rows) => setSelectedCenters(rows)}
            selectedCenters={selectedCenters}
            onSubmit={onConfirm}
            loading={sendReenrolmentLoading}
            accountsTotal={allRowsSelected ? totalRecords : selectedRows.length}
          />
          {(selectedRows.length || allRowsSelected) > 0 && (
            <>
              <div className="bg-white p-4 d-flex border-bottom" style={{ height: '73px' }}>
                {hasUpdateDetailsPermission && (
                  <RequestUpdateEnrolmentFormAction
                    filterBy={{
                      includeAllAccountIds: allRowsSelected,
                      centerIds: finalCenterFilters,
                      accountStatuses:
                        statusFilters.length > 0
                          ? statusFilters.map((s) => s.value as AccountStatusType)
                          : [AccountStatusType.Active, AccountStatusType.Inactive],
                      asOfDate: moment().format('YYYY-MM-DD'),
                      accountNameSearchTerm: searchTerm,
                      includeAccountIds: selectedRows.map((c) => c.id),
                    }}
                    displayNumber={allRowsSelected ? totalRecords : selectedRows.length}
                  />
                )}
                {hasSendRequestPermission && (
                  <ReEnrollBulkActions
                    filterBy={{
                      includeAllAccountIds: allRowsSelected,
                      centerIds: finalCenterFilters,
                      accountStatuses:
                        statusFilters.length > 0
                          ? statusFilters.map((s) => s.value as AccountStatusType)
                          : [AccountStatusType.Active, AccountStatusType.Inactive],
                      asOfDate: moment().format('YYYY-MM-DD'),
                      accountNameSearchTerm: searchTerm,
                      includeAccountIds: selectedRows.map((c) => c.id),
                    }}
                    displayNumber={allRowsSelected ? totalRecords : selectedRows.length}
                    onClick={() => {
                      setSendRequestsOpen(true);
                    }}
                  />
                )}
              </div>
              <SelectedItemsTotal
                totalRecords={data?.getReenrollmentAccounts?.totalRecords ?? 0}
                selectedRows={selectedRows.length}
                allSelected={allRowsSelected}
                handleClearSelectedRows={() => {
                  setAllRowsSelected(false);
                  setSelectedRows([]);
                }}
                handleSelectAllRows={() => {
                  setAllRowsSelected(true);
                  setSelectedRows([]);
                }}
              />
            </>
          )}
        </>
      );
    },
    [allRowsSelected, selectedRows, centerFilters, centerOptions, tagFilters, tagOptions, statusFilters, statusOptions]
  );

  const handleSelectRow = useCallback(
    (rows: any) => {
      if (allRowsSelected) setAllRowsSelected(false);
      setSelectedRows((prev) => {
        if (rows.length === 0) {
          const prevWithoutCurentPage = prev.filter((c) => !mainData.map((d) => d.accountId).includes(c.accountId));
          return prevWithoutCurentPage;
        }
        if (rows.length === tableState.pageSize) {
          return [...prev, ...rows];
        }
        return rows;
      });
    },
    [allRowsSelected, selectedRows, mainData]
  );

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSortDto({
      field: field,
      direction: direction === 'ASCENDING' ? SortDirection.Ascending : SortDirection.Descending,
    });
  }, []);

  return (
    <DataTable
      data={finalCenterFilters.length === 0 ? [] : mainData}
      dataSize={totalRecords}
      showLoadingOverlay={loading || loadingCenters}
      noPadding
      className="reenroll-table"
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      showSelect={hasSendRequestPermission || hasUpdateDetailsPermission}
      selectedRows={allRowsSelected ? mainData : selectedRows}
      updateSelectedRows={handleSelectRow}
      onlyOneExpanding
      expandRow={expandRow}
      renderHeader={getTableHeader}
      onSort={handleSort}
      columns={[
        {
          text: 'Account Name',
          sort: true,
          dataField: 'accountName',
          formatter: (cell: any, row: ReEnrollmentAccount) => {
            return <AccountLink accountId={row.accountId} accountName={row.accountName} openInNewTab />;
          },
        },
        {
          text: capitalize(t('translation:spelling.center')),
          dataField: 'centerId',
          formatter: (cell: any, reEnrollmentAccount: ReEnrollmentAccount) => {
            return centers.find((c) => c.id === reEnrollmentAccount.centerId)?.name;
          },
        },
        {
          text: 'Status',
          dataField: 'accountStatus',
        },
        {
          text: 'Last Request Sent',
          sort: true,
          dataField: 'lastContactedDate',
          formatter: (cell: any, row: ReEnrollmentAccount) => {
            return row.lastContactedDate
              ? moment(row.lastContactedDate).format(t('translation:formatters.MM/DD/YYYY'))
              : '';
          },
        },
      ]}
    />
  );
};

export default ReEnrollAccountsTable;
