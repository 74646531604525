import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface IReturnShape {
  formatDate: (date: string) => string;
}

/**
 * Hook that provides a function to format a date string.
 * If a format has been provided then that will be used. Otherwise the default format
 * for the given region will be used.
 */
const useFormatDate = (): ((date: string | moment.Moment, format?: string) => string) => {
  const { t } = useTranslation();

  return (date: string | moment.Moment, format: string = '') => {
    // @ts-ignore
    const formatString = format !== '' ? t(`formatters.${format}`) : t('formatters.date');

    // @ts-ignore
    return moment(date).format(formatString);
  };
};

export default useFormatDate;
