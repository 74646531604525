export const GET_ALL_UNBATCHED_PAYMENT_TRANSACTIONS = 'GET_ALL_UNBATCHED_PAYMENT_TRANSACTIONS';
export const SELECT_PAYMENTS_FOR_BATCHING = 'SELECT_PAYMENTS_FOR_BATCHING';
export const CREATE_BATCH_SUCCESS = 'CREATE_BATCH_SUCCESS';
export const SET_CENTER_ID_TO_BATCH = 'SET_CENTER_ID_TO_BATCH';
export const GET_BATCHES_TABLE_DATA = 'GET_BATCHES_TABLE_DATA';
export const DELETE_BATCH_SUCCESS = 'DELETE_BATCH_SUCCESS';
export const GET_TOTAL_UNBATCHED_PAYMENT_TRANSACTIONS = 'GET_TOTAL_UNBATCHED_PAYMENT_TRANSACTIONS';

interface IGetAllUnbatchedPaymentTransactionsAction {
  type: typeof GET_ALL_UNBATCHED_PAYMENT_TRANSACTIONS;
  transactions: ITransaction[];
}

interface IGetTotalUnbatchedTransactions {
  type: typeof GET_TOTAL_UNBATCHED_PAYMENT_TRANSACTIONS;
  total: number;
}

interface ISelectPaymentsForBatchingAction {
  type: typeof SELECT_PAYMENTS_FOR_BATCHING;
  paymentTransactions: ITransaction[];
}

interface ICreateBatchSuccessAction {
  type: typeof CREATE_BATCH_SUCCESS;
  batch: IBatch;
}

interface ISetCenterIdToBatchAction {
  type: typeof SET_CENTER_ID_TO_BATCH;
  centerId: string;
}

interface IGetBatchesTableDataAction {
  type: typeof GET_BATCHES_TABLE_DATA;
  batches: IBatch[];
}

interface IDeleteBatchSuccessAction {
  type: typeof DELETE_BATCH_SUCCESS;
  batch: IBatch;
  unbatchedPaymentTransactions: ITransaction[];
}

export type PaymentActionTypes =
  | IGetAllUnbatchedPaymentTransactionsAction
  | ISelectPaymentsForBatchingAction
  | ICreateBatchSuccessAction
  | ISetCenterIdToBatchAction
  | IGetBatchesTableDataAction
  | IDeleteBatchSuccessAction
  | IGetTotalUnbatchedTransactions;
