import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { Area, ICustomField, Type } from 'shared/types/customField';

export interface IGetReturnFeeReductionsVariables {
  businessId: string;
}

export interface IGetReturnFeeReductionsData {
  totalRecords: number;
  getReturnFeeReductions: {
    data: IReturnFeeReduction[];
  };
}

export const GET_RETURN_FEE_REDUCTIONS = gql`
  query ($businessId: ID!) {
    getReturnFeeReductions(businessId: $businessId) {
      totalRecords
      data {
        enrolmentId
        sessionReportStartDate
        amountNotPassedOnToIndividual
        amountPassedOnToIndividual
        totalFeeReductionAmountForWeek
        returnFeeReductionReason
        returnFeeReductionId
        isCancelled
      }
    }
  }
`;

export const useGetReturnFeeReductions = (businessId: string) =>
  useQuery<IGetReturnFeeReductionsData, IGetReturnFeeReductionsVariables>(GET_RETURN_FEE_REDUCTIONS, {
    variables: { businessId },
  });

// Custom Fields
export const GET_CUSTOM_FIELDS = gql`
  query ($input: GetCustomFieldsInput!) {
    getPaginatedCustomFieldsForBusiness(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        id
        businessId
        label
        area
        type
        displayOrder
        notForConsoleDisplay
        attributesAsString
        centerIds
      }
    }
  }
`;

interface IGetCustomFieldsVariables {
  input: IGetCustomFieldInputVariables;
}

interface IGetCustomFieldInputVariables {
  allowedCenterIds: string[] | null;
  area: Area | null;
  businessId: string;
  pageNumber: number;
  pageSize: number;
  searchKey: string | undefined;
  sortBy: string;
  sortDirection: string;
  type: Type | null;
  isArchived?: Boolean;
}

interface IGetCustomFieldsData {
  getPaginatedCustomFieldsForBusiness: IPagedResult<ICustomField>;
}

export const useGetCustomFields = (options?: QueryHookOptions<IGetCustomFieldsData, IGetCustomFieldsVariables>) =>
  useQuery(GET_CUSTOM_FIELDS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
