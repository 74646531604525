import React, { useMemo, useCallback } from 'react';
import { orderBy } from 'lodash';
import Card from 'shared/components/Card';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import COUNTRY_INFO, { DEFAULT_COUNTRY, STATES } from 'shared/constants/dropdownOptions/countryInfo';
import { toProperCase } from 'shared/util/string';

interface IProps {
  isViewOnly: boolean;
  selectedRegulationId: string;
  centerAddressState: string;
  regulationOptions: IRegulation[];
  fetchingRegulations: boolean;
  classCapacity: number | null;
  onChange: (regulation: IRegulation) => void;
  onCapacityChange: (value: number) => void;
}

const ClassRegulation: React.FC<IProps> = ({
  isViewOnly,
  selectedRegulationId,
  centerAddressState,
  regulationOptions,
  fetchingRegulations,
  classCapacity,
  onChange,
  onCapacityChange,
  ...props
}) => {
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const regulationsForState = useMemo(() => {
    return orderBy(
      regulationOptions.filter((reg: IRegulation) => reg.state === centerAddressState),
      [
        (item: IRegulation) => {
          const { age, unit } = item.startAge;

          switch (unit) {
            case 'YEAR':
              return age * 365;
            case 'MONTH':
              return age * 30;
            case 'WEEK':
              return age * 7;
            default:
              return age;
          }
        },
        (item: IRegulation) => item.ratioTeachers / item.ratioChildren,
      ],
      ['asc', 'asc']
    );
  }, [centerAddressState, regulationOptions]);

  const formatRegulationAge = useCallback((regulationAge: IRegulationAge): string => {
    const str = `${regulationAge.age} ${toProperCase(regulationAge.unit)}`;

    return regulationAge.age === 1 ? str : `${str}s`;
  }, []);

  return (
    <Card header="Regulation" className="h-100">
      <span className="d-block mb-4">
        Please select an age range to populate the desired regulation. Note: The state pre-selected below is based on
        the information provided in your {fieldLabels.center.toLowerCase()} profile.
      </span>
      <TextInput disabled id="class-regulation-state-input" label="State" value={STATES[centerAddressState] ?? ''} />
      <Select
        required
        disabled={isViewOnly}
        label="Age Range"
        value={regulationsForState.find((option: IRegulation) => option.id === selectedRegulationId)}
        isLoading={fetchingRegulations}
        options={regulationsForState}
        getOptionValue={(option: IRegulation) => option.id}
        formatOptionLabel={(option: IRegulation) =>
          `${formatRegulationAge(option.startAge)} - ${formatRegulationAge(option.endAge)} (Ratio ${
            option.ratioTeachers
          }:${option.ratioChildren})`
        }
        onChange={onChange}
        helpTooltipText="For combined age groups, select the range which closely matches your needs. Then override the entries to meet your needs."
        isOptionSelected={(option: IRegulation) => option.id === selectedRegulationId}
      />
      <div className="d-flex flex-row max-width-216">
        <div className="d-flex flex-1 mr-4">
          <NumberInput
            disabled
            label="Capacity"
            value={regulationsForState.find((option: IRegulation) => option.id === selectedRegulationId)?.capacity}
            helpTooltipText="This number demonstrates the capacity as it is set by the selected regulation information"
          />
        </div>
        <div className="d-flex flex-1 mr-4">
          <NumberInput
            required
            disabled={isViewOnly}
            label="Override"
            value={classCapacity}
            onChange={onCapacityChange}
            errorText="Value must be greater than 0"
            min="1"
            isInvalid={classCapacity !== null && classCapacity <= 0}
          />
        </div>
      </div>
    </Card>
  );
};

export default ClassRegulation;
