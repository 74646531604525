import React, { useMemo, useState } from 'react';
import { CreateButton } from 'shared/components/Buttons';
import RBCard from 'react-bootstrap/Card';
import { Col, Row } from 'shared/components/Layout';
import CcsEnrolmentsTable from './Enrolments/CcsEnrolmentsTable';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetCcsEnrolmentsForChildren, useGetUnlinkedEnrolmentsForAccount } from 'gql/ccssEnrolment/queries';
import { useGetCcsCertificatesForChildren } from 'gql/ccssCertificates/queries';
import CreateCertificateModal from './Accs/CreateCertificateModal';
import EditCertificateModal from './Accs/EditCertificateModal';
import CcsEntitlementsTable from './Entitlements/CcsEntitlementsTable';
import { useGetCcsEntitlementsForChildren } from 'gql/subsidies/queries';
import AccsSelectTypeModal from './Accs/AccsSelectTypeModal';
import CreateDeterminationModal, { createDeterminationInput } from './Accs/CreateDeterminationModal';
import AccsTable, { createAccsTableData, sortDefaultAccsTableData } from './Accs/AccsTable';
import { compose } from 'redux';
import { useGetCcsDeterminationsForChildren } from 'gql/ccssDetermination/queries';
import { compact } from 'lodash';
import CancelCertificateModal from './Accs/CancelCertificateModal';
import ChildNotAtRiskModal from './Accs/ChildNotAtRiskModal';
import { IAccsTableData } from './Accs/types';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import CreateCcsEnrolmentModal2 from './Enrolments/CreateCcsEnrolmentModal2';
import EditCcsEnrolmentModal2 from './Enrolments/EditCcsEnrolmentModal2';
import ChildSelectList from '../ChildSelectList';
import { useTranslation } from 'react-i18next';
import UnlinkCcsEnrolmentModal from './Enrolments/UnlinkCcsEnrolmentModal';
import LinkCcsEnrolmentModal from './Enrolments/LinkCcsEnrolmentModal';
import { useGetContractsForAccount } from 'gql/contract/queries';
import Tooltip from 'shared/components/Tooltip/Tooltip';

interface IProps {
  account: IAccount;
  loading: boolean;
}

const CcsTab: React.FC<IProps> = ({ account, loading }) => {
  const { t } = useTranslation(['accounts']);
  const [createCcsEnrolmentOpen, setCreateCcsEnrolmentOpen] = useState(false);
  const [linkCcsEnrolmentOpen, setLinkCcsEnrolmentOpen] = useState(false);
  const [editCcsEnrolmentOpen, setEditCcsEnrolmentOpen] = useState(false);
  const [unlinkCcsEnrolmentOpen, setUnlinkCcsEnrolmentOpen] = useState(false);
  const [createCertificateOpen, setCreateCertificateOpen] = useState(false);
  const [editCertificateOpen, setEditCertificateOpen] = useState(false);
  const [accsSelectTypeOpen, setAccsSelectTypeOpen] = useState(false);
  const [createDeterminationOpen, setCreateDeterminationOpen] = useState(false);
  const [cancelCertificateOpen, setCancelCertificateOpen] = useState(false);
  const [childNotAtRiskOpen, setChildNotAtRiskOpen] = useState(false);

  const [enrolmentInEdit, setEnrolmentInEdit] = useState<ICcsEnrolment | null>(null);
  const [enrolmentToUnlink, setEnrolmentToUnlink] = useState<ICcsEnrolment | null>(null);
  const [selectedChildId, setSelectedChildId] = useState('');

  const [certificateInEdit, setCertificateInEdit] = useState<ICcssCertificate | null>(null);
  const [certificateToCancel, setCertificateToCancel] = useState<ICcssCertificate | null>(null);
  const [childNotAtRisk, setChildNotAtRisk] = useState<IAccsTableData | null>(null);

  const onCloseCreateCcsEnrolment = () => {
    setCreateCcsEnrolmentOpen(false);
    refetchEnrolments();
  };

  const onCloseEditCcsEnrolment = () => {
    setEditCcsEnrolmentOpen(false);
    refetchEnrolments();
  };

  const onCloseLinkCcsEnrolment = () => {
    setLinkCcsEnrolmentOpen(false);
    refetchEnrolments();
    refetchUnlinkedEnrolments();
  };

  const onCloseUnlinkCcsEnrolment = () => {
    setUnlinkCcsEnrolmentOpen(false);
    setEnrolmentToUnlink(null);
    refetchEnrolments();
    refetchUnlinkedEnrolments();
  };

  const children = account?.children?.filter((item) => !item.archivedAt) ?? [];
  const childrenIds = children.map((c) => c.id);
  const businessId = account?.entityId ?? '';
  const centerId = account?.center?.id ?? '';
  const accountId = account?.id ?? '';

  const {
    data: childEnrolments,
    loading: ccsEnrolmentsLoading,
    refetch: refetchEnrolments,
  } = useGetCcsEnrolmentsForChildren({
    variables: { businessId: businessId, centerId: centerId, accountId: accountId, children: childrenIds },
    skip: !businessId || !centerId || !accountId,
  });

  const { loading: certificatesLoading } = useGetCcsCertificatesForChildren(
    businessId,
    centerId,
    accountId,
    childrenIds
  );

  const { loading: entitlementsLoading } = useGetCcsEntitlementsForChildren(
    businessId,
    centerId,
    accountId,
    childrenIds
  );

  const { loading: determinationsLoading } = useGetCcsDeterminationsForChildren(
    businessId,
    centerId,
    accountId,
    childrenIds
  );

  const {
    data: unlinkedEnrolments,
    loading: unlinkedEnrolmentsLoading,
    refetch: refetchUnlinkedEnrolments,
  } = useGetUnlinkedEnrolmentsForAccount({
    variables: {
      centerId: centerId,
      accountId: accountId,
    },
    skip: !centerId || !accountId,
  });

  const childCertificates = useSelector(
    (state: RootState) => state.accounts.ccsCertificates.byAccountId[accountId] ?? {}
  );

  const childEntitlements = useSelector(
    (state: RootState) => state.accounts.ccsEntitlements.byAccountId[accountId] ?? {}
  );

  const childDeterminations = useSelector(
    (state: RootState) => state.accounts.ccsDeterminations.byAccountId[accountId] ?? {}
  );

  const {
    data: accountContracts,
    loading: loadingAccountContracts,
    refetch: refetchAccountContracts,
  } = useGetContractsForAccount({
    variables: {
      centerId: centerId,
      accountId: accountId,
    },
    skip: !centerId || !accountId,
  });

  const handleEnrolmentEditClick = (enrolment: ICcsEnrolment) => {
    setEditCcsEnrolmentOpen(true);
    setEnrolmentInEdit(enrolment);
  };

  const handleUnlinkClick = (enrolment: ICcsEnrolment) => {
    setUnlinkCcsEnrolmentOpen(true);
    setEnrolmentToUnlink(enrolment);
  };

  const handleCertificateEditClick = (certificate: ICcssCertificate) => {
    setEditCertificateOpen(true);
    setCertificateInEdit(certificate);
  };

  const handleCertificateCancelClick = (certificate: ICcssCertificate) => {
    setCancelCertificateOpen(true);
    setCertificateToCancel(certificate);
  };

  const handleNotAtRiskClick = (certificate: IAccsTableData) => {
    setChildNotAtRiskOpen(true);
    setChildNotAtRisk(certificate);
  };

  const hasAnyContracts = (accountContracts?.getContractsForAccount ?? []).length > 0;

  const hasUnlinkedEnrolments = useMemo(() => {
    return (unlinkedEnrolments?.getUnlinkedEnrolmentsForAccount ?? []).length > 0;
  }, [unlinkedEnrolments]);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <div className="kt-account-tab kt-account-ccs-tab">
      {(hasAnyContracts || childEnrolments?.getCcsEnrolmentsForChildren?.length) ?? 0 > 0 ? (
        <>
          <RBCard className="mb-4 mx-0">
            <RBCard.Body className="px-4 py-3">
              <Row>
                <Col>
                  <ChildSelectList
                    children={children}
                    selectedChildId={selectedChildId}
                    onChildSelect={(child) => setSelectedChildId(child)}
                  />
                </Col>
              </Row>
            </RBCard.Body>
          </RBCard>
          <h5>CCS Entitlements</h5>
          <CcsEntitlementsTable
            loading={loading || entitlementsLoading}
            entitlements={childEntitlements ?? []}
            account={account}
            selectedChildId={selectedChildId}
          />
          <Row>
            <Col>
              <h5>CCS {fieldLabels.enrollment}</h5>
            </Col>
            <Col>
              <div className="float-right kt-account-ccs-tab add-button">
                <Tooltip
                  showTooltip={!hasAnyContracts}
                  text={'Please set up at least one contract for this account'}
                  direction="top"
                >
                  <CreateButton
                    variant="primary"
                    disabled={!hasAnyContracts}
                    loading={loadingAccountContracts}
                    onClick={() => setCreateCcsEnrolmentOpen(true)}
                  >
                    Add CCS {fieldLabels.enrollment}
                  </CreateButton>
                </Tooltip>
              </div>

              {hasUnlinkedEnrolments && (
                <CreateButton
                  className="float-right kt-account-ccs-tab add-button mr-2"
                  variant="outline-secondary"
                  disabled={unlinkedEnrolmentsLoading}
                  onClick={() => setLinkCcsEnrolmentOpen(true)}
                >
                  Link {fieldLabels.enrollment}
                </CreateButton>
              )}
            </Col>
          </Row>
          <CcsEnrolmentsTable
            loading={loading || ccsEnrolmentsLoading}
            enrolments={childEnrolments?.getCcsEnrolmentsForChildren ?? []}
            account={account}
            selectedChildId={selectedChildId}
            onEditClick={(enrolment) => handleEnrolmentEditClick(enrolment)}
            onUnlinkClick={(enrolment) => handleUnlinkClick(enrolment)}
          />
          <Row>
            <Col>
              <h5>ACCS</h5>
            </Col>
            <Col>
              <div className="float-right kt-account-ccs-tab add-button">
                <Tooltip
                  showTooltip={!hasAnyContracts}
                  text={'Please set up at least one contract for this account'}
                  direction="top"
                >
                  <CreateButton
                    variant="primary"
                    disabled={!hasAnyContracts}
                    loading={loadingAccountContracts}
                    onClick={() => setAccsSelectTypeOpen(true)}
                  >
                    Add Child at Risk
                  </CreateButton>
                </Tooltip>
              </div>
            </Col>
          </Row>
          <AccsTable
            loading={loading || certificatesLoading || determinationsLoading}
            data={compose(
              sortDefaultAccsTableData,
              (data) => data.filter((value) => (selectedChildId !== '' ? value.child.id === selectedChildId : true)),
              createAccsTableData
            )(
              account,
              Object.entries(childCertificates).flatMap(([child, cert]) => cert),
              Object.entries(childDeterminations).flatMap(([child, dert]) => dert)
            )}
            onEditClick={(certificate) => handleCertificateEditClick(certificate)}
            onCreateClick={(certificate) => {
              setCertificateInEdit(certificate);
              setCreateDeterminationOpen(true);
            }}
            onCancelClick={(certificate) => handleCertificateCancelClick(certificate)}
            onNotAtRiskClick={(certificate) => handleNotAtRiskClick(certificate)}
          />
          {createCcsEnrolmentOpen && (
            <CreateCcsEnrolmentModal2
              isOpen={createCcsEnrolmentOpen}
              onClose={onCloseCreateCcsEnrolment}
              account={account}
              accountContracts={accountContracts?.getContractsForAccount ?? []}
              selectChildId={selectedChildId}
            />
          )}
          {editCcsEnrolmentOpen && enrolmentInEdit != null && (
            <EditCcsEnrolmentModal2
              isOpen={editCcsEnrolmentOpen && enrolmentInEdit != null}
              onClose={onCloseEditCcsEnrolment}
              account={account}
              enrolmentInEdit={enrolmentInEdit!}
            />
          )}
          <UnlinkCcsEnrolmentModal
            accountId={account.id}
            centerId={account.centerId}
            enrolmentToUnlink={enrolmentToUnlink}
            isOpen={unlinkCcsEnrolmentOpen && enrolmentToUnlink != null}
            onClose={onCloseUnlinkCcsEnrolment}
          />
          <LinkCcsEnrolmentModal
            accountId={accountId}
            centerId={centerId}
            unlinkedEnrolments={unlinkedEnrolments?.getUnlinkedEnrolmentsForAccount ?? []}
            isOpen={linkCcsEnrolmentOpen}
            onClose={onCloseLinkCcsEnrolment}
          />
          {accsSelectTypeOpen && (
            <AccsSelectTypeModal
              isOpen={accsSelectTypeOpen}
              onClose={(value) => {
                setAccsSelectTypeOpen(false);

                if (value === 'Certificate') setCreateCertificateOpen(true);
                else if (value === 'Determination') setCreateDeterminationOpen(true);
              }}
            />
          )}
          {createCertificateOpen && (
            <CreateCertificateModal
              isOpen={createCertificateOpen}
              onClose={() => setCreateCertificateOpen(false)}
              account={account}
              selectedChildId={selectedChildId}
            />
          )}
          {editCertificateOpen && (
            <EditCertificateModal
              isOpen={editCertificateOpen}
              onClose={() => {
                setEditCertificateOpen(false);
                setCertificateInEdit(null);
              }}
              account={account}
              certificateInEdit={certificateInEdit!}
            />
          )}

          {createDeterminationOpen && (
            <CreateDeterminationModal
              isOpen={createDeterminationOpen}
              onClose={() => {
                setCreateDeterminationOpen(false);
                setCertificateInEdit(null);
              }}
              account={account}
              childEnrolments={childEnrolments?.getCcsEnrolmentsForChildren ?? []}
              baseDeterminationInput={
                certificateInEdit
                  ? createDeterminationInput(certificateInEdit)
                  : { children: compact([selectedChildId]) }
              }
            />
          )}
          {cancelCertificateOpen && (
            <CancelCertificateModal
              isOpen={cancelCertificateOpen}
              onClose={() => setCancelCertificateOpen(false)}
              certificateId={!!certificateToCancel ? certificateToCancel?.id : ''}
              account={account}
            />
          )}
          {childNotAtRiskOpen && (
            <ChildNotAtRiskModal
              isOpen={childNotAtRiskOpen}
              onClose={() => setChildNotAtRiskOpen(false)}
              account={account}
              certificateId={!!childNotAtRisk && childNotAtRisk.baseType === 'CcssCertificate' ? childNotAtRisk.id : ''}
              determinationId={
                !!childNotAtRisk && childNotAtRisk.baseType === 'CcssDetermination' ? childNotAtRisk.id : ''
              }
            />
          )}
        </>
      ) : (
        <RBCard className="mb-4 mx-0">
          <RBCard.Body>
            <span>{t('accounts:ccs.ccs-tab.no-contracts-message')}</span>
          </RBCard.Body>
        </RBCard>
      )}
    </div>
  );
};

export default CcsTab;
