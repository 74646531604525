import moment from 'moment';
import { useGetCenterAvailability } from 'pages/Enrollment/subroutes/Management/Tabs/Overview/components/graphql/queries';
import { SearchAvailabilityInput } from 'pages/Enrollment/subroutes/Management/Tabs/Overview/components/SearchAvailabilityInputs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';

interface IProps {
  selectedCenterId: string;
  searchInput: SearchAvailabilityInput;
}

const useSearchAvailability = ({ selectedCenterId, searchInput }: IProps) => {
  const [dataDateRange, setDataDateRange] = useState({
    startDate: '',
    endDate: '',
  });

  const includeFutureContracts = useSelector((state: RootState) => state.enrollment.includeFutureContracts);
  const includeLtbOffers = useSelector((state: RootState) => state.enrollment.includeLtbOffers);

  const [firstDateInView, setFirstDateInView] = useState('');
  const [classesToFilter, setClassesToFilter] = useState<IClass[]>([]);
  const { data, loading, networkStatus } = useGetCenterAvailability(
    selectedCenterId,
    dataDateRange.startDate,
    dataDateRange.endDate,
    includeLtbOffers,
    includeFutureContracts
  );

  const datesInView = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => moment(firstDateInView).clone().add(i, 'd'));

  const fetchClasses = (startDate: string) => {
    setFirstDateInView(startDate);
    setDataDateRange({
      startDate: moment(startDate).clone().subtract(10, 'd').format(),
      endDate: moment(startDate).clone().add(20, 'd').format(),
    });
  };

  useEffect(() => {
    if (data && (datesInView[0].isBefore(dataDateRange.startDate) || datesInView[10].isAfter(dataDateRange.endDate))) {
      setDataDateRange({
        startDate: datesInView[0].clone().subtract(10, 'd').format(),
        endDate: datesInView[0].clone().add(20, 'd').format(),
      });
    }
  }, [data, dataDateRange.endDate, dataDateRange.startDate, datesInView]);

  const classes =
    data?.getClassesForCenter
      .filter((c) => !c.archivedAt)
      .filter((c) => !classesToFilter?.length || classesToFilter?.map((sc) => sc.id).includes(c.id)) ?? [];

  return {
    state: {
      dataDateRange,
      data,
      loading,
      networkStatus,
      firstDateInView,
      classesToFilter,
      classes,
    },
    fn: {
      fetchClasses,
      setDataDateRange,
      setFirstDateInView,
      setClassesToFilter,
    },
  };
};

export default useSearchAvailability;
