import { useDeleteAppliedAccountDiscount } from 'gql/discount/mutations';
import { removeAppliedAccountDiscountSuccess } from 'pages/Families/subroutes/Accounts/duck/actions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isOpen: boolean;
  discount: IAppliedAccountDiscount;
  onClose: () => void;
}

const RemoveDiscountModal: React.FC<IProps> = ({ isOpen, discount, onClose, ...props }) => {
  const { t } = useTranslation(['billing']);
  const dispatch = useDispatch();
  const [deleteFn, { loading: deleteLoading }] = useDeleteAppliedAccountDiscount({
    onCompleted: (result) => {
      const ended = discount.endDate === null && result.deleteAppliedAccountDiscount.endDate !== null;
      showToast(
        ended
          ? t('billing:discounts.account.delete-modal.success-end-toast-message')
          : t('billing:discounts.account.delete-modal.success-delete-toast-message'),
        'success'
      );
      dispatch(removeAppliedAccountDiscountSuccess(result.deleteAppliedAccountDiscount));
      onClose();
    },
    onError: (err) => {
      showToast(t('billing:discounts.account.delete-modal.error-toast-message'), 'error');
    },
  });

  return (
    <ConfirmationModal
      title={t('billing:discounts.account.delete-modal.title')}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Delete"
      primaryCallback={() => deleteFn({ variables: { id: discount.id } })}
      primaryButtonProps={{ variant: 'danger', loading: deleteLoading }}
    >
      <p>{t('billing:discounts.account.delete-modal.body')}</p>
      <p>{t('billing:discounts.account.delete-modal.confirmation-text')}</p>
    </ConfirmationModal>
  );
};

export default RemoveDiscountModal;
