import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import {
  ForecastDto,
  DeletedForecastDto,
  AcquittalDto,
  DeletedAcquittalDto,
  KindyFundingScheduleInput,
  CreateKindyChildFundingSchedulesInput,
} from 'generated/graphql';
import { useMutation } from 'shared/apis/core';
import {
  IKindyForAllAcquittalSubmission,
  IKindyForAllCenterAgreement,
  IKindyForAllCredential,
  IKindyForAllIneligibilities,
  IKindyForAllSubmission,
} from 'shared/types/kindyForAll';
import {
  kindyForAllCenterAgreement,
  kindyForAllCredential,
  kindyForAllSubmission,
  kindyForAllDeletedForecast,
  kindyForAllAcquittalSubmission,
  kindyForAllDeletedAcquittal,
  kindyForAllIneligibility,
  childFundingSchedules,
} from './fields';

interface ICreateEditKindyForAllCenterAgreementVariables {
  input: IKindyForAllCenterAgreement;
}

interface ICreateEditKindyForAllCenterAgreementData {
  createEditKindyForAllCenterAgreement: IKindyForAllCenterAgreement;
}

interface ICreateEditKindyForAllCredentialData {
  createEditKindyForAllCredential: IKindyForAllCredential;
}

interface ICreateEditKindyForAllCredentialVariables {
  input: IKindyForAllCredential;
}

interface ICreateEditKindyForAllIneligibilitiesData {
  createEditKindyForAllIneligibilities: IKindyForAllIneligibilities;
}

interface ICreateEditKindyForAllIneligibilitiesVariables {
  input: IKindyForAllIneligibilities;
}

interface IPostKindyForAllSubmissionVariables {
  input: IKindyForAllSubmission;
}

interface IPostKindyForAllAcquittalSubmissionVariables {
  input: IKindyForAllAcquittalSubmission;
}

interface IPostKindyForAllForecastData {
  postKindyForAllForecast: ForecastDto[];
}

interface IPostFreeKindyForecastData {
  postFreeKindyForecast: ForecastDto[];
}

interface IDeleteForecastData {
  deleteForecast: DeletedForecastDto;
}

interface IDeleteSubmissionVariables {
  input: {
    centerId: string;
    year: number;
    period: string;
  };
}

interface IPostKindyForAllAcquittalData {
  postKindyForAllAcquittal: AcquittalDto[];
}

interface IPostFreeKindyAcquittalData {
  postFreeKindyAcquittal: AcquittalDto[];
}

interface IDeleteAcquittalData {
  deleteAcquittal: DeletedAcquittalDto;
}

interface IChildFundingSchedulesData {
  data: {
    programChildId: string;
    schedules: KindyFundingScheduleInput[];
  };
}

interface IUpdateChildFundingSchedulesVariables {
  input: {
    programChildId: string;
    schedules: KindyFundingScheduleInput[];
  };
}

export const CREATE_EDIT_KINDY_FOR_ALL_CENTER_AGREEMENT = (fields: string = kindyForAllCenterAgreement) => gql`
  mutation($input: CreateEditKindyForAllCenterAgreementInput!) {
    createEditKindyForAllCenterAgreement(input: $input) {
      ${fields}
    }
  }
`;

export const CREATE_EDIT_KINDY_FOR_ALL_CREDENTIAL = (fields: string = kindyForAllCredential) => gql`
  mutation($input: CreateEditKindyForAllCredentialInput!) {
    createEditKindyForAllCredential(input: $input) {
      ${fields}
    }
  }
`;

export const CREATE_EDIT_KINDY_FOR_ALL_INELIGIBILITIES = (fields: string = kindyForAllIneligibility) => gql`
  mutation($input: CreateEditKindyForAllIneligibilitiesInput!) {
    createEditKindyForAllIneligibilities(input: $input) {
      ${fields}
    }
  }
`;

export const POST_KINDY_FOR_ALL_FORECAST = (fields: string = kindyForAllSubmission) => gql`
  mutation($input: KindyForAllSubmissionInput!) {
    postKindyForAllForecast(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_KINDY_FOR_ALL_FORECAST = (fields: string = kindyForAllDeletedForecast) => gql`
mutation($input: KindyForAllDeleteSubmissionInput!) {
  deleteKindyForAllForecast(input: $input) {
    ${fields}
  }
}
`;

export const POST_KINDY_FOR_ALL_ACQUITTAL = (fields: string = kindyForAllAcquittalSubmission) => gql`
  mutation($input: KindyForAllAcquittalSubmissionInput!) {
    postKindyForAllAcquittal(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_KINDY_FOR_ALL_ACQUITTAL = (fields: string = kindyForAllDeletedAcquittal) => gql`
mutation($input: KindyForAllDeleteSubmissionInput!) {
  deleteKindyForAllAcquittal(input: $input) {
    ${fields}
  }
}
`;

export const POST_FREE_KINDY_FORECAST = (fields: string = kindyForAllSubmission) => gql`
  mutation($input: KindyForAllSubmissionInput!) {
    postFreeKindyForecast(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_FREE_KINDY_FORECAST = (fields: string = kindyForAllDeletedForecast) => gql`
mutation($input: KindyForAllDeleteSubmissionInput!) {
  deleteFreeKindyForecast(input: $input) {
    ${fields}
  }
}
`;

export const POST_FREE_KINDY_ACQUITTAL = (fields: string = kindyForAllAcquittalSubmission) => gql`
  mutation($input: KindyForAllAcquittalSubmissionInput!) {
    postFreeKindyAcquittal(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_FREE_KINDY_ACQUITTAL = (fields: string = kindyForAllDeletedAcquittal) => gql`
mutation($input: KindyForAllDeleteSubmissionInput!) {
  deleteFreeKindyAcquittal(input: $input) {
    ${fields}
  }
}
`;

export const UPDATE_CHILD_FUNDING_SCHEDULES = (fields: string = childFundingSchedules) => gql`
mutation($input: CreateKindyChildFundingSchedulesInput!) {
  createKindyChildFundingSchedules(input: $input) {
    ${fields}
  }
}
`;

export const useCreateEditKindyForAllCenterAgreement = (
  options?: MutationHookOptions<
    ICreateEditKindyForAllCenterAgreementData,
    ICreateEditKindyForAllCenterAgreementVariables
  >,
  fields?: string
) =>
  useMutation<ICreateEditKindyForAllCenterAgreementData, ICreateEditKindyForAllCenterAgreementVariables>(
    CREATE_EDIT_KINDY_FOR_ALL_CENTER_AGREEMENT(fields),
    options
  );

export const useCreateEditKindyForAllCredential = (
  options?: MutationHookOptions<ICreateEditKindyForAllCredentialData, ICreateEditKindyForAllCredentialVariables>,
  fields?: string
) =>
  useMutation<ICreateEditKindyForAllCredentialData, ICreateEditKindyForAllCredentialVariables>(
    CREATE_EDIT_KINDY_FOR_ALL_CREDENTIAL(fields),
    options
  );

export const useCreateEditKindyForAllIneligibilities = (
  options?: MutationHookOptions<
    ICreateEditKindyForAllIneligibilitiesData,
    ICreateEditKindyForAllIneligibilitiesVariables
  >,
  fields?: string
) =>
  useMutation<ICreateEditKindyForAllIneligibilitiesData, ICreateEditKindyForAllIneligibilitiesVariables>(
    CREATE_EDIT_KINDY_FOR_ALL_INELIGIBILITIES(fields),
    options
  );
export const usePostKindyForAllForecast = (
  options?: MutationHookOptions<IPostKindyForAllForecastData, IPostKindyForAllSubmissionVariables>,
  fields?: string
) =>
  useMutation<IPostKindyForAllForecastData, IPostKindyForAllSubmissionVariables>(
    POST_KINDY_FOR_ALL_FORECAST(fields),
    options
  );

export const useDeleteKindyForAllForecast = (
  options?: MutationHookOptions<IDeleteForecastData, IDeleteSubmissionVariables>,
  fields?: string
) => useMutation<IDeleteForecastData, IDeleteSubmissionVariables>(DELETE_KINDY_FOR_ALL_FORECAST(fields), options);

export const usePostKindyForAllAcquittal = (
  options?: MutationHookOptions<IPostKindyForAllAcquittalData, IPostKindyForAllAcquittalSubmissionVariables>,
  fields?: string
) =>
  useMutation<IPostKindyForAllAcquittalData, IPostKindyForAllAcquittalSubmissionVariables>(
    POST_KINDY_FOR_ALL_ACQUITTAL(fields),
    options
  );

export const useDeleteKindyForAllAcquittal = (
  options?: MutationHookOptions<IDeleteAcquittalData, IDeleteSubmissionVariables>,
  fields?: string
) => useMutation<IDeleteAcquittalData, IDeleteSubmissionVariables>(DELETE_KINDY_FOR_ALL_ACQUITTAL(fields), options);

export const usePostFreeKindyForecast = (
  options?: MutationHookOptions<IPostFreeKindyForecastData, IPostKindyForAllSubmissionVariables>,
  fields?: string
) =>
  useMutation<IPostFreeKindyForecastData, IPostKindyForAllSubmissionVariables>(
    POST_FREE_KINDY_FORECAST(fields),
    options
  );

export const useDeleteFreeKindyForecast = (
  options?: MutationHookOptions<IDeleteForecastData, IDeleteSubmissionVariables>,
  fields?: string
) => useMutation<IDeleteForecastData, IDeleteSubmissionVariables>(DELETE_FREE_KINDY_FORECAST(fields), options);

export const usePostFreeKindyAcquittal = (
  options?: MutationHookOptions<IPostFreeKindyAcquittalData, IPostKindyForAllAcquittalSubmissionVariables>,
  fields?: string
) =>
  useMutation<IPostFreeKindyAcquittalData, IPostKindyForAllAcquittalSubmissionVariables>(
    POST_FREE_KINDY_ACQUITTAL(fields),
    options
  );

export const useDeleteFreeKindyAcquittal = (
  options?: MutationHookOptions<IDeleteAcquittalData, IDeleteSubmissionVariables>,
  fields?: string
) => useMutation<IDeleteAcquittalData, IDeleteSubmissionVariables>(DELETE_FREE_KINDY_ACQUITTAL(fields), options);

export const useUpdateChildFundingSchedules = (
  options?: MutationHookOptions<IChildFundingSchedulesData, IUpdateChildFundingSchedulesVariables>,
  fields?: string
) =>
  useMutation<IChildFundingSchedulesData, IUpdateChildFundingSchedulesVariables>(
    UPDATE_CHILD_FUNDING_SCHEDULES(fields),
    options
  );
