export const statementFields: string = `
  centerId
  isEnabled
  frequency
  dayOfWeek
  dayOfMonth
  periodBasis
  periodWeeksPrior
  periodWeeksAfter
  message
  startDate
`;

export const centerStatementRunsFields = `
  id
  createdAt
  isScheduledRun
  status
  periodStartFrom
  periodEndOn
  message
  statements{
    id
    accountId
    status
    customerDownloadedStatementAt
    accountFirstName
    accountLastName
    email
  }
`;

export const centerStatementRunsPagedResult = `
  totalRecords
  pageNumber
  data {
    ${centerStatementRunsFields}
  }
`;

export const centerStatementFields = `
  id
  accountId
  status
  customerDownloadedStatementAt
  accountFirstName
  accountLastName
  email
  dateRun
`;

export const centerStatementsPagedResult = `
  totalRecords
  pageNumber
  data {
    ${centerStatementFields}
  }
`;

export default statementFields;
