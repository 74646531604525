import { orderBy } from 'lodash';
import React, { useCallback } from 'react';
import CcsEntitlementsCard from './CcsEntitlementsCard';
import EntitlementsTable, { ICcsEntitlementDetails } from './EntitlementsTable';
import { ICcsEntitlement } from 'shared/types/ccsEntitlement';

interface IProps {
  loading: boolean;
  entitlements: Record<string, ICcsEntitlement>;
  account: IAccount;
  selectedChildId: string;
}

const CcsEntitlementsTable: React.FC<IProps> = ({ loading, entitlements, account, selectedChildId }) => {
  const expandRow = (row: ICcsEntitlementDetails) => (
    <div>
      <CcsEntitlementsCard entitlement={row.entitlement} account={account} />
    </div>
  );

  const filterEntitlementsToSelectedChild = useCallback((): ICcsEntitlement[] => {
    const allEntitlements: ICcsEntitlement[] = [];

    for (const childId in entitlements) {
      allEntitlements.push(entitlements[childId]);
    }

    if (selectedChildId !== '') {
      return allEntitlements.filter((e) => e.childId === selectedChildId);
    }

    return allEntitlements;
  }, [selectedChildId, entitlements]);

  const selectedEntitlements = filterEntitlementsToSelectedChild();

  let rows: ICcsEntitlementDetails[] = [];

  for (const entitlement of selectedEntitlements) {
    const child = account.children.find((c) => c.id === entitlement.childId);

    if (!child) continue;

    rows.push({
      id: `${entitlement.id}`,
      child,
      entitlement: entitlement,
    });
  }

  const orderedRows = orderBy(rows, (r) => r.child.fullName);

  return <EntitlementsTable data={orderedRows} expandRow={expandRow} loading={loading} />;
};

export default CcsEntitlementsTable;
