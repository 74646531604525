import { MutationHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { removeCenterFromStore, updateCenter } from 'pages/Centers/duck/actions';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'shared/apis/core';
import useUpdateAvatar from 'shared/hooks/useUpdateAvatar';
import { RootState } from 'store/reducers';
import { addCenterToTimezoneHash } from 'store/timezones/actions';

interface IUpdateCenterProfileData {
  updateCenter: ICenter;
}

interface IUpdateCenterProfileVariables {
  input: any;
}

interface IUpdateCenterInviteCodeData {
  updateCenterInviteCode: ICenter;
}

interface IUpdateCenterInviteCodeVariables {
  id: string;
}

interface IUpdateCenterTagsData {
  updateCenterTags: ICenter;
}

interface IUpdateCenterTagsVariables {
  input: {
    id: string;
    tags: string[]; // array of ids
  };
}

interface IDeactivateCenterData {
  deactivateCenter: ICenter;
}

interface IDeactivateCenterVariables {
  id: string;
}

interface IReactivateCenterData {
  reactivateCenter: ICenter;
}

interface IReactivateCenterVariables {
  id: string;
}

interface IDeleteCenterData {
  deleteCenter: ICenter;
}

interface IDeleteCenterVariables {
  id: string;
}

interface IAddCcssApprovalData {
  addCcssApproval: ICcssApproval;
}

interface IAddCcssApprovalVariables {
  input: IAddCcssApprovalInput;
}

interface IEditCcssApprovalData {
  editCcssApproval: ICcssApproval;
}

interface IEditCcssApprovalVariables {
  input: IEditCcssApprovalInput;
}

interface IDeleteCcssApprovalData {
  deleteCcssApproval: ICcssApproval;
}

interface IDeleteCcssApprovalVariables {
  id: string;
  centerId: string;
}

// return properties that might update so we can use them to update center profile cache
export const UPDATE_CENTER_PROFILE = gql`
  mutation ($input: UpdateCenterInput!) {
    updateCenter(input: $input) {
      id
      name
      phoneNumber
      tags {
        id
        name
        category
      }
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }
      timezone
      primaryContactPersonId
      primaryContact {
        id
        firstname
        lastname
        email
        avatar {
          url
        }
      }
      secondaryContactPersonId
      secondaryContact {
        id
        firstname
        lastname
        email
        avatar {
          url
        }
      }
      openingDate
      closingDate
      website
      email
      abn
      acn
      externalId
      taxId
      serviceType
      billingEnabledSettings {
        centerId
        enableBillingCycleProcessing
        enableBillingCycleProcessingEffectiveDate
        billingEnabledAt
        billingEnabledBy
        billingEnabledByPerson {
          id
          firstname
          nickname
          lastname
        }
      }
    }
  }
`;

export const UPDATE_CENTER_AVATAR = gql`
  mutation ($input: UpdateAvatarInput!) {
    updateCenterAvatar(input: $input) {
      id
      avatar {
        url
      }
    }
  }
`;

export const DEACTIVATE_CENTER = gql`
  mutation ($id: ID!) {
    deactivateCenter(id: $id) {
      id
      name
      tags {
        name
        category
      }
      phoneNumber
      deactivatedAt
      deletedAt
      avatar {
        url
      }
      address {
        city
        state
      }
    }
  }
`;

export const REACTIVATE_CENTER = gql`
  mutation ($id: ID!) {
    reactivateCenter(id: $id) {
      id
      name
      tags {
        name
        category
      }
      phoneNumber
      deactivatedAt
      deletedAt
      avatar {
        url
      }
      address {
        city
        state
      }
    }
  }
`;

export const DELETE_CENTER = gql`
  mutation ($id: ID!) {
    deleteCenter(id: $id) {
      id
      name
      tags {
        name
        category
      }
      phoneNumber
      deactivatedAt
      deletedAt
      avatar {
        url
      }
      address {
        city
        state
      }
    }
  }
`;

export const UPDATE_CENTER_TAGS = gql`
  mutation ($input: UpdateCenterTagsInput!) {
    updateCenterTags(input: $input) {
      id
      tags {
        id
        name
        category
      }
    }
  }
`;

const UPDATE_CENTER_INVITE_CODE = gql`
  mutation ($id: ID!) {
    updateCenterInviteCode(id: $id) {
      id
      inviteCode
    }
  }
`;

const ADD_CCS_APPROVAL = gql`
  mutation ($input: AddCcssApprovalInput!) {
    addCcssApproval(input: $input) {
      id
      careType
      serviceId
      serviceName
      startDate
    }
  }
`;

const EDIT_CCS_APPROVAL = gql`
  mutation ($input: EditCcssApprovalInput!) {
    editCcssApproval(input: $input) {
      id
      careType
      serviceId
      startDate
      serviceName
    }
  }
`;

const DELETE_CCS_APPROVAL = gql`
  mutation ($id: ID!, $centerId: ID!) {
    deleteCcssApproval(id: $id, centerId: $centerId) {
      id
    }
  }
`;

export const useUpdateCenterProfile = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateCenterProfileData, IUpdateCenterProfileVariables>(UPDATE_CENTER_PROFILE, {
    onCompleted: (data) => {
      dispatch(updateCenter(data.updateCenter));
      dispatch(addCenterToTimezoneHash(data.updateCenter));
    },
  });
};

export const useUpdateCenterInviteCode = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateCenterInviteCodeData, IUpdateCenterInviteCodeVariables>(UPDATE_CENTER_INVITE_CODE, {
    onCompleted: (data) => {
      dispatch(updateCenter(data.updateCenterInviteCode));
    },
  });
};

export const useUpdateCenterTags = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateCenterTagsData, IUpdateCenterTagsVariables>(UPDATE_CENTER_TAGS, {
    onCompleted: (data) => {
      dispatch(updateCenter(data.updateCenterTags));
    },
  });
};

// hooks responsible for updating a center's avatar
export const useUpdateCenterAvatar = () => {
  const [updateCenterAvatarFn, responseFromUpdate] = useUpdateAvatar('center');
  const updateFn = useCallback(
    async (profileUpdates) => {
      try {
        await updateCenterAvatarFn({
          id: profileUpdates.id,
          avatar: profileUpdates.avatar,
          useNewUpdate: profileUpdates.useNewUpdate,
        });
      } catch (error) {}
    },
    [updateCenterAvatarFn]
  );

  return [updateFn, responseFromUpdate];
};

// hooks responsible for updating a center's logo
export const useUpdateCenterLogo = () => {
  const [updateCenterLogoFn, responseFromUpdate] = useUpdateAvatar('logo');
  const updateFn = useCallback(
    async (profileUpdates) => {
      try {
        await updateCenterLogoFn({
          id: profileUpdates.id,
          avatar: profileUpdates.avatar,
          useNewUpdate: profileUpdates.useNewUpdate,
        });
      } catch (error) {}
    },
    [updateCenterLogoFn]
  );

  return [updateFn, responseFromUpdate];
};

export const useDeactivateCenter = () => {
  const dispatch = useDispatch();
  const activeTableFilters = useSelector((state: RootState) => state.centers.meta.activeFilters);

  return useMutation<IDeactivateCenterData, IDeactivateCenterVariables>(DEACTIVATE_CENTER, {
    onCompleted: (data) => {
      // if no filters are selected then we are showing all data
      if (activeTableFilters.status.deactivated || Object.values(activeTableFilters.status).every((v) => v === false)) {
        dispatch(updateCenter(data.deactivateCenter));
      } else {
        dispatch(removeCenterFromStore(data.deactivateCenter.id));
      }
    },
  });
};

export const useReactivateCenter = () => {
  const dispatch = useDispatch();
  const activeTableFilters = useSelector((state: RootState) => state.centers.meta.activeFilters);

  return useMutation<IReactivateCenterData, IReactivateCenterVariables>(REACTIVATE_CENTER, {
    onCompleted: (data) => {
      if (activeTableFilters.status.active || Object.values(activeTableFilters.status).every((v) => v === false)) {
        dispatch(updateCenter(data.reactivateCenter));
      } else {
        dispatch(removeCenterFromStore(data.reactivateCenter.id));
      }
    },
  });
};

export const useDeleteCenter = () => {
  const dispatch = useDispatch();
  const activeTableFilters = useSelector((state: RootState) => state.centers.meta.activeFilters);
  const isInternal = useSelector((state: RootState) => state.user?.isInternal);

  return useMutation<IDeleteCenterData, IDeleteCenterVariables>(DELETE_CENTER, {
    onCompleted: (data) => {
      if (
        activeTableFilters.status.deleted ||
        (Object.values(activeTableFilters.status).every((v) => v === false) && isInternal)
      ) {
        dispatch(updateCenter(data.deleteCenter));
      } else {
        dispatch(removeCenterFromStore(data.deleteCenter.id));
      }
    },
  });
};

export const useAddCcssApproval = (options?: MutationHookOptions<IAddCcssApprovalData, IAddCcssApprovalVariables>) =>
  useMutation<IAddCcssApprovalData, IAddCcssApprovalVariables>(ADD_CCS_APPROVAL, options);

export const useEditCcssApproval = (options?: MutationHookOptions<IEditCcssApprovalData, IEditCcssApprovalVariables>) =>
  useMutation<IEditCcssApprovalData, IEditCcssApprovalVariables>(EDIT_CCS_APPROVAL, options);

export const useDeleteCcssApproval = (
  options?: MutationHookOptions<IDeleteCcssApprovalData, IDeleteCcssApprovalVariables>
) => useMutation<IDeleteCcssApprovalData, IDeleteCcssApprovalVariables>(DELETE_CCS_APPROVAL, options);
