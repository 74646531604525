import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import Button from 'shared/components/Buttons';

interface IProps {
  startOfCurrentWeek: string;
  scheduleForCurrentWeek: ISchedule | null;
  canCreateSchedule: boolean;
  loading: boolean;
  disabled?: boolean;
  onPublishSchedule: () => void;
  onUnpublishSchedule: () => void;
}

/**
 * This component is responsible for rendering the button in the top right of the schedules page.
 * It will either:
 * - create a schedule
 * - publish a schedule
 * - unpublish a schedule
 */
const ScheduleStatusButton: React.FC<IProps> = ({
  startOfCurrentWeek,
  canCreateSchedule,
  scheduleForCurrentWeek,
  loading,
  disabled = false,
  onPublishSchedule,
  onUnpublishSchedule,
  ...props
}) => {
  const historicalWeek = moment(scheduleForCurrentWeek?.endTime ?? startOfCurrentWeek)
    .startOf('week')
    .isBefore(moment().startOf('week'));

  // TODO: permissions
  if (!canCreateSchedule) {
    return null;
  }

  if (!scheduleForCurrentWeek) {
    return <></>;
  }

  const classes = classnames({
    'd-inline-block': true,
    'mr-4': true,
    'kt-staff-schedules-publish-status': true,
    'kt-staff-schedules-publish-status-published': scheduleForCurrentWeek.publishStatus === 'PUBLISHED',
    'kt-staff-schedules-publish-status-unpublished': scheduleForCurrentWeek.publishStatus === 'DRAFT',
  });

  return (
    <div>
      <div className={classes}>
        <small>Status: </small>
        {scheduleForCurrentWeek.publishStatus === 'PUBLISHED' ? 'PUBLISHED' : 'UNPUBLISHED'}
      </div>
      <Button
        loading={loading}
        disabled={disabled || loading || historicalWeek}
        onClick={scheduleForCurrentWeek.publishStatus === 'PUBLISHED' ? onUnpublishSchedule : onPublishSchedule}
      >
        <FontAwesomeIcon icon={faPlus} />
        <span className="pl-2">{scheduleForCurrentWeek.publishStatus === 'PUBLISHED' ? 'Unpublish' : 'Publish'}</span>
      </Button>
    </div>
  );
};

export default ScheduleStatusButton;
