import React, { useCallback } from 'react';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import Row from 'react-bootstrap/Row';
import { Col } from 'shared/components/Layout';
import { TableSearch } from 'shared/components/DataTable';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import { orderBy, sortBy } from 'lodash';
import { statusOptions } from 'shared/constants/StaffStatusSearchExpressions';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import useGetStaffSearchExpression from 'shared/hooks/useGetStaffSearchExpression';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const { TERM } = SEARCH_EXPRESSIONS;
const { CONTAINS } = PREDICATES;

interface IProps {
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  tags: ITableFilterOption[];
  centers: ICenter[];
}

const FilterGroup: React.FC<IProps> = ({ tableState, tableFunctions, tags, centers }) => {
  const getSearchExpression = useGetStaffSearchExpression(centers, tags); // converts search term string into query expression
  const centerOptions: ITableFilterOption[] = orderBy(
    centers ?? [],
    (center) => center.name.toLocaleLowerCase(),
    'asc'
  ).map((center) => ({
    label: center.name,
    value: center.id,
    searchExpression: { [TERM]: { field: 'roleship.scopeIds.keyword', predicate: CONTAINS, value: center.id } },
  }));
  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name']).map((tag) => ({
    label: tag.label,
    value: tag.value,
    searchExpression: { [TERM]: { field: 'tags.keyword', predicate: CONTAINS, value: tag.value } },
  }));

  const clearAppliedFilters = useCallback(() => {
    tableFunctions.updateSelectedFilters({});
  }, [tableFunctions]);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className="d-flex flex-row flex-grow-1">
      <Row className="d-flex flex-row align-items-center justify-content-start justify-content-md-end flex-grow-1">
        <div className="d-flex flex-grow-1">
          <TableSearch
            onChange={(term) => tableFunctions.updateSearchExpressions(getSearchExpression(term))}
            placeholder="Search Staff"
            className={isMobile ? 'mt-3 ml-2' : ''}
          />
        </div>
        <Col xs="auto" className={isMobile ? 'mt-3' : 'my-0 mx-2 p-0'}>
          <DropdownFilter
            title="Status"
            selectedFilters={tableState.selectedFilters.status}
            options={statusOptions}
            onFilterSelect={(val) =>
              tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, status: val })
            }
          />
        </Col>
        <Col xs="auto" className={isMobile ? 'mt-2 pl-0 ml-2' : 'my-0 mx-2 p-0'}>
          <DropdownFilter
            title={fieldLabels.center}
            selectedFilters={tableState.selectedFilters.center}
            options={centerOptions}
            onFilterSelect={(val) =>
              tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, center: val })
            }
          />
        </Col>
        <Col xs="auto" className={isMobile ? 'mt-2 px-0' : 'my-0 mx-2 p-0'}>
          <DropdownFilter
            title="Tags"
            selectedFilters={tableState.selectedFilters.tags}
            options={tagOptions}
            onFilterSelect={(val) => tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, tags: val })}
          />
        </Col>
        <Col xs="auto" className={isMobile ? 'mt-2' : 'my-0 ml-2 p-0'}>
          <IconButtonCircle
            icon={faTimes}
            onClick={clearAppliedFilters}
            disabled={Object.values(tableState.selectedFilters).every((filter) => !filter || filter.length === 0)}
            tooltipDirection="bottom"
            tooltipText="Clear Filters"
          />
        </Col>
      </Row>
    </div>
  );
};

export default FilterGroup;
