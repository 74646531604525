import React, { useState } from 'react';
import DataTable from 'shared/components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';
import moment from 'moment';
import StatementRunsTableRowDetail from './StatementRunsTableRowDetail';
import { statementRunStatusLabels } from 'shared/constants/enums/statementEnums';
import { useTranslation } from 'react-i18next';

interface ITableProps {
  data: IStatementRun[];
  centerId?: string;
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
}

const StatementRunsTable: React.FC<ITableProps> = ({
  data,
  centerId,
  dataSize,
  pageSize,
  activePage,
  onPageChange,
  onSizePerPageChange,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const { t } = useTranslation(['translation']);
  const dateFormat = t('translation:formatters.MM/DD/YYYY');

  const onExpand = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    const expanded = isExpand ? [row.id] : [];
    setExpandedRows(expanded);
  };

  const getDescription = (statementRun: IStatementRun) => {
    return `Statement Period: ${moment(statementRun.periodStartFrom).format(dateFormat)} - ${moment(
      statementRun.periodEndOn
    ).format(dateFormat)}`;
  };

  const getStatusLabel = (status: StatementRunStatus) => {
    switch (status) {
      case 'COMPLETED_ERROR':
        return (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="mr-2" icon={faCircle} style={{ fontSize: 10 }} color={colors.danger} />
            <span>{statementRunStatusLabels[status].label}</span>
          </div>
        );
      case 'COMPLETED_SUCCESS':
        return (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="mr-2" icon={faCircle} style={{ fontSize: 10 }} color={colors.success} />
            <span>{statementRunStatusLabels[status].label}</span>
          </div>
        );
      default:
        return (
          <div className="d-flex align-items-center">
            <span>{statementRunStatusLabels[status]?.label ?? status}</span>
          </div>
        );
    }
  };

  return (
    <>
      <DataTable
        keyField="id"
        noPadding={true}
        data={data}
        dataSize={dataSize}
        handleRowClick={() => {}}
        hasOverlay={false}
        pageSize={pageSize}
        activePage={activePage}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        showPagination={true}
        showSelect={false}
        className="ccs-table"
        expandHeaderColumnRenderer={(props) => <></>}
        onExpand={onExpand}
        expanded={expandedRows}
        expandRow={(row: IStatementRun) => <StatementRunsTableRowDetail data={row.statements} centerId={centerId} />}
        defaultSortField="created"
        defaultSorting="desc"
        onlyOneExpanding={true}
        columns={[
          {
            text: 'Run Date',
            dataField: 'date',
            formatter: (cell: string, row: IStatementRun) => moment(row.createdAt).format(dateFormat),
          },
          {
            text: 'Description',
            formatter: (cell: string, row: IStatementRun) => getDescription(row),
          },
          {
            text: 'Count',
            dataField: 'statements',
            formatter: (cell: string, row: IStatementRun) => row.statements.length,
          },
          {
            text: 'Status',
            dataField: 'status',
            formatter: (cell: string, row: IStatementRun) => getStatusLabel(row.status),
          },
        ]}
      />
    </>
  );
};

export default StatementRunsTable;
