import { isRegion } from 'shared/util/region';
import AU from './flags/AU.svg';
import CA from './flags/CA.svg';
import PR from './flags/PR.svg';
import US from './flags/US.svg';

interface ICountryCodeInfo {
  label: string;
  value: string;
  code: string;
  mask: (string | RegExp)[] | ((value: string) => (string | RegExp)[]);
  placeholder: string;
}

const australiaMask = (value: string): (string | RegExp)[] => {
  // https://www.acma.gov.au/choose-your-phone-number
  const freeRateMask = [/1/, /8/, /0/, /0/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
  const landlineMask = ['(', /0/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  const localRateMask1 = [/1/, /3/, /0/, /0/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
  const localRateMask2 = [/1/, /3/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
  const mobileMask = [/0/, /4/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];

  if (/^[^\d]*04/.test(value)) return mobileMask;
  if (/^[^\d]*1300/.test(value)) return localRateMask1;
  if (/^[^\d]*18/.test(value)) return freeRateMask;
  if (/^[^\d]*1/.test(value)) return localRateMask2;

  return landlineMask;
};

const northernAmericaCodeInfo = {
  mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  placeholder: '(___) ___ - ____',
};

// the react-text-mask expect a valid `mask` otherwise it will throw an error
// there might be a typo on the phone number on migration
// for example +6412345677(australia number missed 1 after +1) will be treated as NZ number
// and we don't have NZ country code values
// failoverMask is to handle such senario
const failoverMask = isRegion('US') ? northernAmericaCodeInfo.mask : australiaMask;

// key is a two letter country code
export const COUNTRY_CODES: Record<string, ICountryCodeInfo> = {
  AU: {
    label: 'Australia',
    value: '+61',
    code: 'AU',
    mask: australiaMask,
    placeholder: '(__) ____ ____',
  },
  CA: { label: 'Canada', value: '+1', code: 'CA', ...northernAmericaCodeInfo },
  US: {
    label: 'United States',
    value: '+1',
    code: 'US',
    ...northernAmericaCodeInfo,
  },
  PR: {
    label: 'Puerto Rico',
    value: '+1',
    code: 'PR',
    ...northernAmericaCodeInfo,
  },
};

/** Convert a country code to its concomitant mask. */
export const countryCodeToMask = (code: string): (string | RegExp)[] | ((value: string) => (string | RegExp)[]) =>
  COUNTRY_CODES[code]?.mask ?? failoverMask;

/** Convert a country code to its concomitant placeholder. */
export const countryCodeToPlaceholder = (code: string): string | undefined => COUNTRY_CODES[code]?.placeholder;

/**
 * Convert a country code to its corresponding flag emoji
 * @param {String} code
 * @returns {String}
 */
export const countryCodeToFlag = (code: string): string => {
  // regex to test that the provided string is two letters
  const countryCodeRegex = /^[a-z]{2}$/i;

  if (!countryCodeRegex.test(code)) {
    return '';
  }

  const flags = {
    AU,
    CA,
    PR,
    US,
  };

  // if a svg file doesn't exist return an empty string
  try {
    const flag = flags[code];
    return flag ?? '';
  } catch (error) {
    return '';
  }
};
