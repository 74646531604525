import { useGeneratePdfStatement, useManualRunStatement } from 'gql/statement/mutations';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { ToastType } from 'react-toastify';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import { showToast } from 'shared/components/Toast';
import { billingPeriodOptions } from 'shared/constants/enums/billingCycleOptions';
import Form from 'react-bootstrap/Form';
import { useGetDateCalculate } from '../../../gql/statement/queries';
import MultipleAccountSelect from 'shared/components/Select/MultipleAccountSelect';
import MultipleAccountTagSelect from 'shared/components/Select/MultipleAccountTagSelect';
import { useTranslation } from 'react-i18next';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onAfterRun: () => void;
  centerId: string;
  centerStatementSchedule?: ICenterStatementSchedule;
  message?: string;
  accountId?: string;
  startDate?: string;
  endDate?: string;
}

const statementSchedulePeriodOptions: readonly Readonly<{ value: number; label: string }>[] = [
  { value: 0, label: '0 weeks' },
  { value: 1, label: '1 week' },
  { value: 2, label: '2 weeks' },
  { value: 3, label: '3 weeks' },
  { value: 4, label: '1 month' },
];

/**
 * Custom hook used by this component
 * @param centerId
 * @param formData
 * @param setFormData
 */
const useGetCalculateDate = (centerId: string, formData: ICenterManualRunStatementFormData, setFormData: Function) => {
  const [result, setResult] = useState<Array<string>>([]);

  const { data } = useGetDateCalculate({
    variables: {
      input: {
        centerId: centerId,
        periodBasis: formData.periodBasis,
        periodWeeksPrior: formData.periodWeeksPrior,
        periodWeeksAfter: formData.periodWeeksAfter,
      },
    },
    skip: !centerId,
  });

  useEffect(() => {
    if (data) {
      setResult([data.getDateCalculate.fromDate, data.getDateCalculate.toDate]);
      setFormData({
        ...formData,
        startDate: moment(data.getDateCalculate.fromDate),
        endDate: moment(data.getDateCalculate.toDate),
      });
    }
  }, [data]);

  return result;
};

const ManualStatementRunModalForm: React.FC<IProps> = ({
  isOpen,
  onClose,
  onAfterRun,
  centerId,
  message,
  accountId,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const singleAccountMode = !!accountId;

  const emptyForm: ICenterManualRunStatementFormData = {
    message: '',
    dayOfWeek: 'MONDAY',
    dayOfMonth: undefined,
    periodBasis: 'CURRENT_WEEK',
    periodWeeksPrior: 1,
    periodWeeksAfter: 1,
    startDate: startDate ? startDate : moment(new Date()).format('YYYY-MM-DD'),
    endDate: endDate ? endDate : moment(new Date()).add(1, 'day').format('YYYY-MM-DD'),
  };

  const [isSaving, setIsSaving] = useState(false);
  const [manualRunType, setManualRunType] = useState('cycle-type');
  const [filterType, setFilterType] = useState('account');
  const [formData, setFormData] = useState<ICenterManualRunStatementFormData>({ ...emptyForm });

  const [selectedAccountIds, setSelectedAccountIds] = useState<string[] | null>(accountId ? [accountId] : null);
  const [selectedAccountTagIds, setSelectedAccountTagIds] = useState<string[] | null>(null);
  const [sendEmail, setSendEmail] = useState<boolean>(false);

  const isFormValid = useMemo(() => {
    return (
      (!!formData.dayOfMonth || !!formData.dayOfWeek) &&
      formData.periodBasis &&
      statementSchedulePeriodOptions.some(({ value }) => value === formData.periodWeeksAfter) &&
      statementSchedulePeriodOptions.some(({ value }) => value === formData.periodWeeksPrior) &&
      !!formData.startDate
    );
  }, [formData]);

  const [generatePdfStatement] = useGeneratePdfStatement();
  const [manualRunStatement] = useManualRunStatement();

  const handleRunAdhoc = () => {
    generatePdfStatement({
      variables: {
        input: {
          accountId: accountId!,
          periodStartFrom: moment(formData.startDate).format('YYYY-MM-DD'),
          periodEndOn: moment(formData.endDate).format('YYYY-MM-DD'),
        },
      },
    })
      .then((data) => {
        const pdf = data.data;
        if (pdf) {
          window.open(pdf.generatePdfStatement);
          onClose();
        }
        setSendEmail(false);
        setIsSaving(false);
      })
      .catch(() => {
        showToast(t('billing.statements.adhocRun.errorMessage'), ToastType.ERROR);
        setSendEmail(false);
        setIsSaving(false);
      });
  };

  const createManualStatementRun = () => {
    manualRunStatement({
      variables: {
        input: {
          centerId: centerId,
          message: message ?? '',
          periodStartFrom: moment(formData.startDate).format('YYYY-MM-DD'),
          periodEndOn: moment(formData.endDate).format('YYYY-MM-DD'),
          accountIds: filterType == 'account' ? selectedAccountIds : null,
          accountTagIds: filterType == 'account-tags' ? selectedAccountTagIds : null,
        },
      },
    })
      .then(() => {
        showToast('Statement was generated successfully', ToastType.SUCCESS);
        onAfterRun();
        setIsSaving(false);
        onClose();
      })
      .catch((err) => {
        console.log(getApolloErrorMessage(err));
        setIsSaving(false);
        showToast('There was an error updating the statement generation for this center.', ToastType.ERROR);
        onClose();
      });
  };

  const handleSave = () => {
    setIsSaving(true);

    if (singleAccountMode) {
      if (sendEmail) {
        createManualStatementRun();
      } else {
        handleRunAdhoc();
      }
    } else {
      const dateSpread = moment(formData.endDate).diff(moment(formData.startDate), 'days');
      if (dateSpread > 92) {
        setIsSaving(false);
        showToast(
          'Unable to generate and send statement. The maximum statement date range is 92 days.',
          ToastType.ERROR
        );
        return;
      }

      createManualStatementRun();
    }
  };

  let [fromDate, toDate] = useGetCalculateDate(centerId, formData, setFormData);

  return (
    <SideModalDrawer
      title="Manual run statement"
      show={isOpen}
      onHide={onClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Run"
      primaryCallback={() => handleSave()}
      primaryButtonProps={{ loading: isSaving, disabled: !isFormValid }}
      secondaryButtonProps={{ loading: isSaving }}
    >
      <div>
        {!singleAccountMode && (
          <div>
            <Row>
              <Col>
                <Row>
                  <Col md={12}>
                    <h4>Filter Statement</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col md={12}>
                    <Form.Check className="d-flex align-items-center mb-2">
                      <Form.Check.Input
                        type="radio"
                        name="filter-type"
                        value="account"
                        id="account-input"
                        checked={filterType == 'account'}
                        onChange={(e: any) => {
                          setFilterType(e.target.value ?? '');
                        }}
                      />
                      <Form.Check.Label htmlFor="account-input">By account</Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col md={12}>
                        <MultipleAccountSelect
                          centerIdsFilter={centerId ? [centerId] : undefined}
                          selectedAccountIds={selectedAccountIds}
                          useNullForAllOption={true}
                          onSelect={(ids) => setSelectedAccountIds(!ids?.length ? null : ids)}
                          disabled={filterType != 'account' || singleAccountMode}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col md={12}>
                    <Form.Check className="d-flex align-items-center mb-2">
                      <Form.Check.Input
                        type="radio"
                        name="filter-type"
                        value="account-tags"
                        id="account-tags-input"
                        checked={filterType == 'account-tags'}
                        onChange={(e: any) => {
                          setFilterType(e.target.value ?? '');
                        }}
                      />
                      <Form.Check.Label htmlFor="account-tags-input">By tag</Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col md={12}>
                        <MultipleAccountTagSelect
                          selectedTagIds={selectedAccountTagIds}
                          useNullForAllOption={true}
                          onSelect={(ids) => setSelectedAccountTagIds(!ids?.length ? null : ids)}
                          disabled={filterType != 'account-tags'}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        <Row>
          <Col>
            <Row>
              <Col md={12}>
                <h4>Select Statement Period</h4>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col md={12}>
                <Form.Check className="d-flex align-items-center mb-2">
                  <Form.Check.Input
                    type="radio"
                    name="manual-run-type"
                    value="cycle-type"
                    id="cycle-type-input"
                    checked={manualRunType == 'cycle-type'}
                    onChange={(e: any) => {
                      setManualRunType(e.target.value ?? '');
                    }}
                  />
                  <Form.Check.Label htmlFor="cycle-type-input">By cycle type</Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-2">
                <Row>
                  <Select
                    disabled={manualRunType != 'cycle-type'}
                    className="mb-0 mx-2 flex-grow-0"
                    options={statementSchedulePeriodOptions}
                    onChange={(option) => {
                      setFormData({ ...formData, periodWeeksPrior: option.value });
                    }}
                    value={
                      statementSchedulePeriodOptions.find((opt) => opt.value === formData.periodWeeksPrior) ?? null
                    }
                    placeholder="Period Prior"
                  />
                  prior to the
                </Row>
              </Col>
              <Col md={12} className="mb-2">
                <Row className="mb-2">
                  <Select
                    disabled={manualRunType != 'cycle-type'}
                    className="mb-0 mx-2 flex-grow-0"
                    options={billingPeriodOptions}
                    value={formData.periodBasis ?? null}
                    onChange={(option) => {
                      setFormData({ ...formData, periodBasis: option.value });
                    }}
                    placeholder="Period Basis"
                  />{' '}
                  and
                  <Select
                    disabled={manualRunType != 'cycle-type'}
                    className="mb-0 mx-2 flex-grow-0"
                    options={statementSchedulePeriodOptions}
                    onChange={(option) => {
                      setFormData({ ...formData, periodWeeksAfter: option.value });
                    }}
                    value={
                      statementSchedulePeriodOptions.find((opt) => opt.value === formData.periodWeeksAfter) ?? null
                    }
                    placeholder="Period After"
                  />{' '}
                  after.
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>The statement will be generated for the period from</label>
                <br />
                <label>
                  {moment(fromDate).format('DD/MM/YYYY')} to {moment(toDate).format('DD/MM/YYYY')}.
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <Form.Check className="d-flex align-items-center mb-2">
                  <Form.Check.Input
                    type="radio"
                    name="manual-run-type"
                    value="by-date-range"
                    id="by-date-range-input"
                    checked={manualRunType == 'by-date-range'}
                    onChange={(e: any) => {
                      setManualRunType(e.target.value ?? '');
                    }}
                  />
                  <Form.Check.Label htmlFor="by-date-range-input">By date range</Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <DateInput
                  disabled={manualRunType != 'by-date-range'}
                  label="Start Date"
                  date={formData.startDate}
                  onDateSelect={(date: string) => {
                    setFormData({ ...formData, startDate: date });
                  }}
                  required={manualRunType == 'by-date-range'}
                />
              </Col>
              <Col md={6}>
                <DateInput
                  disabled={manualRunType != 'by-date-range'}
                  label="End Date"
                  date={formData.endDate}
                  onDateSelect={(date: string) => {
                    setFormData({ ...formData, endDate: date });
                  }}
                  isOutsideRange={
                    (endDay) =>
                      endDay.isBefore(moment(formData.startDate).endOf('day')) || singleAccountMode
                        ? false
                        : endDay.diff(moment(formData.startDate), 'days') > 92 // Covers the max length of a quarter
                  }
                  required={manualRunType == 'by-date-range'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        {singleAccountMode && (
          <>
            <Row>
              <Col>
                <Row>
                  <Col md={12}>
                    <h4>Email Contacts</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col md={12}>
                    <Form.Check className="d-flex align-items-center mb-2">
                      <Form.Check.Input
                        type="radio"
                        name="send-email-type-no"
                        value="send-email-no"
                        id="send-email-input-no"
                        checked={!sendEmail}
                        onChange={() => setSendEmail(!sendEmail)}
                      />
                      <Form.Check.Label htmlFor="send-email-input-no">
                        Do not send email to statement contacts
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col md={12}>
                    <Form.Check className="d-flex align-items-center mb-2">
                      <Form.Check.Input
                        type="radio"
                        name="send-email-type-yes"
                        value="send-email-yes"
                        id="send-email-input-yes"
                        checked={sendEmail}
                        onChange={() => setSendEmail(!sendEmail)}
                      />
                      <Form.Check.Label htmlFor="send-email-input-yes">
                        Send email to statement contacts
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </div>
    </SideModalDrawer>
  );
};

export default ManualStatementRunModalForm;
