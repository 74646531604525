import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import { FileUploadDropbox } from 'shared/components/FileOperations';
import FormLabel from 'shared/components/Form/FormLabel';
import { Col, Row } from 'shared/components/Layout';
import useK2Upload from 'shared/hooks/useK2Upload';
import { emptyK2File, fileToK2Upload } from 'shared/util/file';
import ImportInstruction from '../ImportInstruction';
import UploadButton from '../UploadButton';
import DateInput from 'shared/components/DateInput';
import moment from 'moment';

interface IProps extends IImportStepProps {
  importBalances: (input: IQikKidsBalancesVariables) => void;
}

const BalancesImport: React.FC<IProps> = ({ importBalances, center, loading, shouldReset, completed = false }) => {
  const [isDryRun, setDryRun] = useState(true);
  const [date, setDate] = useState<string | undefined>();

  const { t } = useTranslation('ktAdmin');
  const { files: careTypeFiles, setFiles: setCareTypeFiles, k2File: careTypeK2File } = useK2Upload(shouldReset);

  const { files: balanceFiles, setFiles: setBalanceFiles, k2File: balanceK2File } = useK2Upload(shouldReset);

  const handleImport = () => {
    importBalances({
      businessId: center?.entityId || '',
      centerId: center?.id || '',
      careTypes: careTypeK2File || emptyK2File,
      balances: balanceK2File || emptyK2File,
      date: moment(date).format('YYYY-MM-DD') ?? '',
      dryRun: isDryRun,
    });
  };

  const selectedFiles = balanceFiles.length + careTypeFiles.length;
  return (
    <Card
      header={t('data-importer.step-number', {
        number: 7,
        title: t('data-importer.balances'),
      })}
    >
      <ImportInstruction isDryRun={isDryRun} setDryRun={setDryRun} />
      <Row>
        <Col>
          <DateInput required label="Date" date={date} onDateSelect={setDate} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormLabel required>{t('data-importer.care-type')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setCareTypeFiles}
            uploadedFiles={careTypeFiles}
            setUploadedFiles={setCareTypeFiles}
            withProgress={false}
          />
        </Col>
        <Col>
          <FormLabel required>{t('data-importer.balances')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setBalanceFiles}
            uploadedFiles={balanceFiles}
            setUploadedFiles={setBalanceFiles}
            withProgress={false}
          />
        </Col>
      </Row>
      <UploadButton
        onClick={handleImport}
        selectedFiles={selectedFiles}
        totalFiles={2}
        disabled={selectedFiles < 2}
        completed={completed}
        loading={loading}
      />
    </Card>
  );
};

export default BalancesImport;
