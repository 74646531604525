import React, { useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import RBDropdown from 'react-bootstrap/Dropdown';
import { faUserCircle, faHistory, faCalendarAlt, faCalendarTimes } from '@fortawesome/pro-light-svg-icons';
import Avatar from 'shared/components/Avatar';
import Dropdown, { Menu } from 'shared/components/Dropdown';
import ProfileDropdownMenuItem from './ProfileDropdownMenuItem';
import useGetMe from 'shared/hooks/useGetMe';
import LogoutMenuItem from './LogoutMenuItem';
import { getFullName } from 'shared/util/string';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';

interface IMyName {
  full: string;
  initials: string;
}

interface IProps {
  closeDrawer: () => void;
}

const ProfileMenu: React.FC<IProps> = ({ closeDrawer, ...props }) => {
  const history = useHistory();
  const { data, refetch } = useGetMe();
  const isNonInternal: boolean = data?.getMe.__typename !== 'InternalUser' ?? false;
  const user = useSelector((state: RootState) => state.user);

  const scheduleVisibility = user?.role?.scheduleVisibility;

  useEffect(() => {
    refetch();
  }, []);

  const name = useMemo((): IMyName => {
    if (data?.getMe) {
      const me = data.getMe;

      if (!me.firstname && !me.lastname) {
        return {
          full: me.email,
          initials: '',
        };
      }

      return {
        full: getFullName(me),
        initials: `${me.firstname.charAt(0).toUpperCase()}${me.lastname.charAt(0).toUpperCase()}`,
      };
    }

    return {
      full: '',
      initials: '',
    };
  }, [data]);

  const navigateTo = useCallback(
    (route: string, state?: any) => {
      closeDrawer();
      history.push(route, state);
    },
    [closeDrawer, history]
  );

  return (
    <Dropdown alignRight title="" className="kt-profile-menu-dropdown">
      <Avatar size="md" image={data?.getMe.avatar?.url || ''} initials={name.initials} alt={name.full} />
      <span className="d-none d-md-block ml-4">{name.full}</span>
      <Menu className="kt-profile-menu-dropdown-menu">
        {isNonInternal && (
          <>
            <ProfileDropdownMenuItem
              label="View Profile"
              icon={faUserCircle}
              onClick={() => navigateTo('/user/profile/profile')}
            />
            <RBDropdown.Divider />
            {scheduleVisibility && (
              <>
                <ProfileDropdownMenuItem
                  label="My Time Cards"
                  icon={faHistory}
                  onClick={() => navigateTo('/user/time-cards')}
                />
                <RBDropdown.Divider />
              </>
            )}
            {scheduleVisibility && (
              <>
                <ProfileDropdownMenuItem
                  label="My Schedule"
                  icon={faCalendarAlt}
                  onClick={() => navigateTo('/user/schedule')}
                />
                <RBDropdown.Divider />
              </>
            )}
            {scheduleVisibility && (
              <>
                <ProfileDropdownMenuItem
                  label="My Time Off"
                  icon={faCalendarTimes}
                  onClick={() => navigateTo('/user/time-off')}
                />
                <RBDropdown.Divider />
              </>
            )}
          </>
        )}
        <LogoutMenuItem />
      </Menu>
    </Dropdown>
  );
};

export default ProfileMenu;
