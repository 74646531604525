import { cpavServiceFields } from './fields';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';

const SET_CURRENT_CPAV_FOR_CENTRE_SERVICE = (fields: string = cpavServiceFields) => gql`
  mutation($input: SetCurrentCpavForCentreServiceInput!) {
    setCurrentCpavForCentreService(input: $input) {
      ${fields}
    }
  }
`;

interface ISetCurrentCpavForCentreServiceData {
  setCurrentCpavForCentreService: IServiceCpav;
}
interface ISetCurrentCpavForCentreServiceVariables {
  input: ISetCurrentCpavForCentreServiceInput;
}

export const useSetCurrentCpavForCentreService = (
  fields?: string,
  options?: MutationHookOptions<ISetCurrentCpavForCentreServiceData, ISetCurrentCpavForCentreServiceVariables>
) => {
  return useMutation<ISetCurrentCpavForCentreServiceData, ISetCurrentCpavForCentreServiceVariables>(
    SET_CURRENT_CPAV_FOR_CENTRE_SERVICE(fields),
    options
  );
};
