import { EnrollmentProgramGroup } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'shared/components/DataTable';
import { capitalize } from 'shared/util/string';

interface IProps {
  programGroup: EnrollmentProgramGroup;
}

const ProgramGroupRow: React.FC<IProps> = ({ programGroup }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <label>Description:</label>
        <p>{programGroup.description}</p>

        <DataTable
          data={programGroup?.programs ?? []}
          showPagination={false}
          showSelect={false}
          columns={[
            {
              text: capitalize(t('spelling.program')),
              dataField: 'name',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ProgramGroupRow;
