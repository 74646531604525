import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { programDetailsFields, programPagedFields, programFundingSchedulesFields } from './fields';

const GET_PROGRAMS = gql`
  query($input: GetCenterProgramsInput!) {
    getProgramsByCenterId(input: $input) {
      ${programPagedFields}
    }
  }
`;

const GET_SUBSIDY_SCHEME_PROGRAMS_COUNT = gql`
  query ($input: GetSubsidySchemeProgramsCountByCenterInput!) {
    getSubsidySchemeProgramsCountByCenterId(input: $input) {
      count
    }
  }
`;

const GET_PROGRAM_BY_ID = gql`
  query($input: GetProgramByIdInput!) {
    getProgramById(input: $input) {
      ${programDetailsFields}
    }
  }
`;

const GET_KINDY_PROGRAM_FUNDING_SCHEDULES = gql`
  query($input: GetKindyProgramFundingSchedulesInput!) {
    getKindyProgramFundingSchedules(input: $input) {
      ${programFundingSchedulesFields}
    }
  }
`;

interface IGetProgram {
  getProgramsByCenterId: IPagedResult<IProgram>;
}

interface IGetSubsidyProgramsCount {
  getSubsidySchemeProgramsCountByCenterId: ISubsidyProgramsCount;
}

interface IGetProgramById {
  getProgramById: IProgramDetails;
}

interface IGetKindyProgramFundingSchedules {
  getKindyProgramFundingSchedules: IKindyProgramFundingSchedule[];
}

interface IGetProgramsVariables {
  input: IGetProgramInput;
}

interface IGetSubsidyProgramsCountVariables {
  input: IGetSubsidyProgramCountInput;
}

interface IGetProgramByIdVariables {
  input: IGetProgramByIdInput;
}

interface IGetKindyProgramFundingSchedulesVariables {
  input: IGetKindyProgramFundingSchedulesInput;
}

export const useGetPrograms = (options?: QueryHookOptions<IGetProgram, IGetProgramsVariables>) =>
  useQuery(GET_PROGRAMS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetSubsidyProgramsCount = (
  options?: QueryHookOptions<IGetSubsidyProgramsCount, IGetSubsidyProgramsCountVariables>
) =>
  useQuery(GET_SUBSIDY_SCHEME_PROGRAMS_COUNT, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetProgramById = (options?: QueryHookOptions<IGetProgramById, IGetProgramByIdVariables>) =>
  useQuery(GET_PROGRAM_BY_ID, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetKindyProgramFundingSchedules = (
  options?: QueryHookOptions<IGetKindyProgramFundingSchedules, IGetKindyProgramFundingSchedulesVariables>
) =>
  useQuery(GET_KINDY_PROGRAM_FUNDING_SCHEDULES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
