import React, { useCallback, useMemo, useState } from 'react';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import TextInput from 'shared/components/TextInput';
import { uniqBy } from 'lodash';
import { IUpdateServiceNameInput, useUpdateServiceName } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import NotifiableServiceEventsContainer from '../NotifiableServiceEventsContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

interface IProps {}

interface IServiceNameChangeInput {
  date: string;
  providerName: string;
  fullName: string;
  service: any;
}

const ServiceNameChange: React.FC<IProps> = ({}) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<IServiceNameChangeInput>({
    date: '',
    providerName: '',
    fullName: '',
    service: undefined,
  });
  const [supportingDocument, setSupportingDocument] = useState<Array<IServiceNameChangeEvidence>>([]);
  const acceptedFileExts = ['.pdf', '.png', '.tiff', '.jpg'];

  const [updateServiceName, { loading: updateLoading }] = useUpdateServiceName();

  const isFormValid = useMemo(
    () =>
      !!formData.date &&
      !!formData.fullName &&
      !!formData.service &&
      !!supportingDocument &&
      supportingDocument.length > 0,
    [formData, supportingDocument]
  );

  const setSelectedFiles = (files: File[]) => {
    const acceptedFiles = uniqBy(files, (f) => f.name).filter((f) =>
      acceptedFileExts.includes('.' + (f.name.split('.').pop()?.toLowerCase() ?? ''))
    );

    const supportingFiles: IServiceNameChangeEvidence[] = acceptedFiles.map((file) => {
      return {
        file: file,
      };
    });

    setSupportingDocument(supportingFiles);
  };

  const handleCancel = () => {
    setFormData({ date: '', providerName: '', fullName: '', service: undefined });
    setSupportingDocument([]);
  };

  const handleSave = useCallback(() => {
    updateServiceName({
      variables: {
        input: {
          serviceId: formData.service.value,
          endDate: formData.date,
          startDate: formData.date,
          name: formData.fullName,
          supportingDocumentFiles: supportingDocument,
        } as IUpdateServiceNameInput,
      },
    })
      .then((response) => {
        showToast('Service name was updated successfully.', 'success');
      })
      .catch((err) => {
        err = err.toString().replace('Error: GraphQL error:', '').trim();
        const errorMessage = !!err ? err : 'There was an error.';
        showToast(errorMessage, 'error');
      });
  }, [updateServiceName, formData, supportingDocument]);

  return (
    <>
      <NotifiableServiceEventsContainer
        title="Service Name Change"
        subTitle="Update the service name by completing the required fields below."
        notificationMessage="Update due within 14 days of name change"
        onCancel={handleCancel}
        onSave={handleSave}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!isFormValid}
        isSaving={updateLoading}
        serviceOnChange={(option) => setFormData({ ...formData, service: option })}
        selectedService={formData.service?.value}
        child={
          <>
            <Row>
              <Col md={12} className="mb-2">
                <Row align="end">
                  <Col md>
                    <DateInput
                      label="Date of change"
                      required
                      date={formData.date}
                      onDateSelect={(date: string) => setFormData({ ...formData, date: date })}
                      disabled={false}
                      className="mb-4"
                    />
                  </Col>
                  <Col md>
                    <TextInput
                      required
                      label="Name"
                      value={formData.fullName}
                      onChange={(value: string) => setFormData({ ...formData, fullName: value })}
                      disabled={false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col className="mb-4">
                <div className="d-flex flex-row">
                  <label className="form-label">Attach Evidence of Service name change</label>
                  <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
                </div>
                <FileSelectDropbox
                  value={supportingDocument?.map((d) => d?.file)}
                  onChange={setSelectedFiles}
                  showFiles={true}
                  acceptedFileExts={acceptedFileExts.join(',')}
                />
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default ServiceNameChange;
