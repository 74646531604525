import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { accountBillingOverviewFields } from './fields';

interface IGetAccountsBillingOverviewData {
  getAccountsBillingOverview: IPagedResult<IAccountBillingOverview>;
}

interface IGetAccountsBillingOverviewVariables {
  input: {
    businessId: string;
    centerIds: string[];
    start: string;
    end: string;
    statusAtDate: string;
    statusType?: AccountStatusType;
    searchKey?: string;
    sortBy: string;
    sortDirection: string;
    billingFrequencies: BillingFrequency[];
    tagIds?: string[];
    pagination: {
      pageNumber: number;
      pageSize: number;
    };
  };
}

function accountsBillingOverviewQuery(fields: string) {
  return gql`
  query($input: GetAccountsBillingOverviewInput!) {
    getAccountsBillingOverview(input: $input) {
      totalRecords
      pageNumber
      data {
        ${fields}
      }
    }
  }
`;
}

export const GET_ACCOUNTS_BILLING_OVERVIEW = accountsBillingOverviewQuery(accountBillingOverviewFields);

export const useGetAccountsBillingOverview = (
  options?: QueryHookOptions<IGetAccountsBillingOverviewData, IGetAccountsBillingOverviewVariables>,
  fields: string = accountBillingOverviewFields
) =>
  useQuery(accountsBillingOverviewQuery(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
