import React, { useState } from 'react';
import { Tabs, Tab, ButtonGroup } from 'react-bootstrap';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import EntitlementsTab from './EntitlementsTab/EntitlementsTab';
import CcsEnrolmentsTab from './CcsEnrolmentsTab/CcsEnrolmentsTab';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { sortBy } from 'lodash';
import CcsAbsencesTab from './AbsencesTab/CcsAbsencesTab';
import OverviewTab from './OverviewTab/OverviewTab';
import SubsidiesTrackingTab from './SubsidiesTrackingTab';
import AccsTab from './AccsTab/AccsTab';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import HasRoleAreaLevel from '../../shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from '../../shared/constants/enums/permissionsEnums';
import Button from '../../shared/components/Buttons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CcsEntitlementReportModal from './EntitlementsTab/CcsEntitlementReportModal';
import AccsReportModal from './AccsTab/AccsReportModal';
import CcsSubsidyPaymentReportModal from './SubsidiesTrackingTab/CcsSubsidyPaymentReportModal';
import CcsSubsidyRemittanceReportModal from './SubsidiesTrackingTab/CcsSubsidyRemittanceReportModal';
import { useTranslation } from 'react-i18next';
import { CwaTab } from './CwaTab/CwaTab';
import CcsEnrolmentsReportModal from './CcsEnrolmentsTab/CcsEnrolmentsReportModal';

interface IProps {}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const Subsidies: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['translation', 'reporting']);
  const user = useSelector((state: RootState) => state.user);
  const { data: businessesData } = useGetAllowedEntities();
  const { data: centers } = useGetActiveCentersWithLoading();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const [selectedCenters, setSelectedCenters] = useState<ITableFilterOption[]>([]);
  const [showCssEntitlementReportModal, setShowCssEntitlementReportModal] = useState<boolean>(false);
  const [showAcssReportModal, setShowAcssReportModal] = useState<boolean>(false);
  const [showCcsSubsidyPaymentReportModal, setShowCcsSubsidyPaymentReportModal] = useState<boolean>(false);
  const [showCcsSubsidyRemittanceReportModal, setShowCcsSubsidyRemittanceReportModal] = useState<boolean>(false);
  const [selectedBusinesses, setSelectedBusinesses] = useState<ITableFilterOption[]>([]);
  const [activeTab, setActiveTab] = useState('overview');
  const { k2CcsReports, k2Cwa } = useFlags();
  const { centerFilterIds } = useSelector((state: RootState) => state.context);
  const [showCcsEnrolmentsReportModal, setShowCcsEnrolmentsReportModal] = useState<boolean>(false);

  const showReportModal = () => {
    switch (activeTab) {
      case 'entitlements':
        setShowCssEntitlementReportModal(true);
        break;
      case 'accs':
        setShowAcssReportModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <ProfilePageWrapper
      title="Subsidies"
      loading={false}
      showAvatar={false}
      buttonComponent={
        k2CcsReports && (
          <div>
            {(activeTab === 'entitlements' || activeTab === 'accs') && (
              <HasRoleAreaLevel
                action={{ area: AreaType.Report, permission: PermissionType.Base, level: RoleLevelType.Read }}
              >
                <Button variant="secondary" className="my-2 mr-2" onClick={() => showReportModal()}>
                  Run Report
                </Button>
              </HasRoleAreaLevel>
            )}
            {activeTab === 'subsidiesTracking' && (
              <HasRoleAreaLevel
                action={{ area: AreaType.Report, permission: PermissionType.Base, level: RoleLevelType.Read }}
              >
                <ButtonGroup className="border-0">
                  <Button
                    variant="secondary"
                    className="my-2 mr-2"
                    onClick={() => setShowCcsSubsidyPaymentReportModal(true)}
                  >
                    {t('translation:run-named-report', {
                      name: t('reporting:readable-report-name.ccs-session-entitlement'),
                    })}
                  </Button>
                  <Button
                    variant="secondary"
                    className="my-2 mr-2"
                    onClick={() => setShowCcsSubsidyRemittanceReportModal(true)}
                  >
                    {t('translation:run-named-report', { name: t('reporting:readable-report-name.css-payment') })}
                  </Button>
                </ButtonGroup>
              </HasRoleAreaLevel>
            )}
            {activeTab == 'ccs' && (
              <HasRoleAreaLevel
                action={{ area: AreaType.Report, permission: PermissionType.Base, level: RoleLevelType.Read }}
              >
                <Button variant="secondary" className="my-2 mr-2" onClick={() => setShowCcsEnrolmentsReportModal(true)}>
                  {t('translation:run-named-report', {
                    name: t('reporting:readable-report-name.ccs-enrolments'),
                  })}
                </Button>
              </HasRoleAreaLevel>
            )}
          </div>
        )
      }
    >
      <div className="mx-auto">
        {centers && centers.length > 1 && (
          <div className="d-flex">
            {user?.isInternal && (
              <>
                <h6 className="my-auto mr-2">Selected Businesses</h6>
                <DropdownFilter
                  title="Filter..."
                  className="mr-4 my-2"
                  selectedFilters={selectedBusinesses}
                  options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                  onFilterSelect={setSelectedBusinesses}
                />
              </>
            )}

            {
              <>
                <h6 className="my-auto mr-2">Selected {fieldLabels.center}s</h6>
                <DropdownFilter
                  title="Filter..."
                  className="mr-4 my-2"
                  selectedFilters={selectedCenters}
                  options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
                  onFilterSelect={setSelectedCenters}
                />
              </>
            }
          </div>
        )}
        {/* todo: (dk) for all of these where business id is passed in, we need to potentially make it singular (i.e only allow single business to be selected) */}
        <Tabs
          id="subsidies-tab-group"
          activeKey={activeTab ?? 'overview'}
          onSelect={(eventKey) => setActiveTab(eventKey!)}
        >
          <Tab eventKey="overview" title="overview">
            {businesses && businesses.length > 0 && (
              <OverviewTab businessId={businesses[0].id} centerIds={selectedCenters.map((c) => c.value)} />
            )}
          </Tab>
          <Tab eventKey="ccs" title="CCS Enrolments">
            {businesses && businesses.length > 0 && (
              <CcsEnrolmentsTab businessId={businesses[0].id} centerIds={selectedCenters.map((c) => c.value)} />
            )}
            {showCcsEnrolmentsReportModal && businesses && businesses.length > 0 && (
              <CcsEnrolmentsReportModal
                businessId={businesses[0].id}
                isOpen={showCcsEnrolmentsReportModal}
                defaultFormValues={{
                  centerIds: selectedCenters.map((c) => c.value),
                }}
                onClose={() => setShowCcsEnrolmentsReportModal(false)}
              />
            )}
          </Tab>
          <Tab eventKey="absences" title="absences">
            {businesses && businesses.length > 0 && (
              <CcsAbsencesTab businessId={businesses[0].id} centerIds={selectedCenters.map((c) => c.value)} />
            )}
          </Tab>
          <Tab eventKey="subsidiesTracking" title="Subsidies Tracking">
            {businesses && businesses.length > 0 && (
              <SubsidiesTrackingTab businessId={businesses[0].id} centerIds={selectedCenters.map((c) => c.value)} />
            )}
            {showCcsSubsidyPaymentReportModal && (
              <CcsSubsidyPaymentReportModal
                isOpen={showCcsSubsidyPaymentReportModal}
                defaultFormValues={{
                  centerIds: selectedCenters.map((c) => c.value),
                }}
                onClose={() => setShowCcsSubsidyPaymentReportModal(false)}
              />
            )}
            {showCcsSubsidyRemittanceReportModal && (
              <CcsSubsidyRemittanceReportModal
                isOpen={showCcsSubsidyRemittanceReportModal}
                defaultFormValues={{
                  centerIds: selectedCenters.map((c) => c.value),
                }}
                onClose={() => setShowCcsSubsidyRemittanceReportModal(false)}
              />
            )}
          </Tab>
          <Tab eventKey="entitlements" title="entitlements">
            {businesses && businesses.length > 0 && (
              <EntitlementsTab businessId={businesses[0].id} selectedCenters={selectedCenters.map((c) => c.value)} />
            )}
            {showCssEntitlementReportModal && (
              <CcsEntitlementReportModal
                isOpen={showCssEntitlementReportModal}
                defaultFormValues={{
                  centerIds: centerFilterIds,
                }}
                onClose={() => setShowCssEntitlementReportModal(false)}
              />
            )}
            {showAcssReportModal && (
              <AccsReportModal
                isOpen={showAcssReportModal}
                defaultFormValues={{
                  centerIds: centerFilterIds,
                }}
                onClose={() => setShowAcssReportModal(false)}
              />
            )}
          </Tab>
          <Tab eventKey="accs" title="ACCS">
            {businesses && businesses.length > 0 && (
              <AccsTab businessId={businesses[0].id} centerIds={selectedCenters.map((c) => c.value)} />
            )}
          </Tab>
          {k2Cwa && (
            <Tab eventKey="cwa" title="CWA">
              {activeTab === 'cwa' && businesses && businesses.length > 0 && <CwaTab />}
            </Tab>
          )}
        </Tabs>
      </div>
    </ProfilePageWrapper>
  );
};

export default Subsidies;
