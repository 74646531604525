import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { useDispatch } from 'react-redux';
import { getUserSuccess } from 'store/user/actions';
import User from 'shared/types/user';

interface IGetMeVariables {}
interface IGetMeData {
  getMe: (IPerson | IStaff) & {
    __typename: 'Staff' | 'InternalUser';
  };
}

export const GET_ME = gql`
  query {
    getMe {
      __typename
      id
      nickname
      firstname
      lastname
      email
      dob
      phoneNumber
      onboardingStatus
      restoreId
      avatar {
        url
      }
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }

      ... on Staff {
        entityId
        pin
        isEmailVerified
        emailIsManaged
        verifiedEmail
        cognitoConfiguredAt
        employmentStatus
        primaryCenterId
        primaryEmergencyContact {
          firstname
          lastname
          phoneNumber
          phoneNumberType
          relationship
        }
        additionalEmergencyContacts {
          firstname
          lastname
          phoneNumber
          phoneNumberType
          relationship
        }
        primaryCenter {
          id
        }
        role {
          id
          name
          businessId
          scopeType
          hierarchyLevel
          areaLevels {
            roleId
            area
            level
            permission
          }
          scheduleVisibility
        }
        roleship {
          roleId
          name
          permissions {
            action
          }
          scopeType
          scopes {
            ... on Center {
              id
              name
              entityId
              avatar {
                url
              }
            }
            ... on Entity {
              id
              name
              timezone
              payrixCustomerId
              primaryPaymentMethodId
              dataType
              createdAt
              avatar {
                url
              }
              centers {
                id
                name
                entityId
              }
            }
          }
        }
        availability {
          approved {
            dayOfWeek
            times {
              start
              end
            }
          }
        }
      }
    }
  }
`;

const useGetMe = () => {
  const dispatch = useDispatch();

  return useQuery<IGetMeData, IGetMeVariables>(GET_ME, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (getMeData) => {
      getMeData && dispatch(getUserSuccess(new User(getMeData.getMe)));
    },
  });
};

export default useGetMe;
