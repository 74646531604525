import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { getAllClasses } from 'pages/Centers/subroutes/Classes/duck/selectors';
import { useGetClassesForCenters } from 'pages/Centers/subroutes/Classes/graphql/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import BaseSelect from 'shared/components/Select/Base';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import Alert from 'shared/components/Alert';
import { capitalize } from 'shared/util/string';
import { MedicalConditions } from 'shared/constants/enums/medicalEnum';
import { camelCaseToSpaced } from 'shared/util/string';
import { isRegion } from 'shared/util/region';

interface IFormStateShape {
  centerId: string;
  selectedClasses: Array<{ value: string; label: string }> | null;
  selectedConditions: Array<{ value: string; label: string }>;
  startDate: string | null;
  endDate: string | null;
  format: 'EXCEL' | 'PDF';
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  onSubmit: (
    startDate: string,
    endDate: string | null,
    centerId: string,
    classIds: string[],
    conditionIds: string[],
    format: 'EXCEL' | 'PDF'
  ) => void;
  onClose: () => void;
  failedReportDownload: string | null;
  showReportFormat?: boolean;
  showConditions?: boolean;
  className?: string;
  resetFailedDownload: () => void;
  isEndDateRequired?: boolean;
  pastOnly?: boolean;
  hideArchivedClasses?: boolean;
  hideInactiveClasses?: boolean;
}

const TimeframeMultipleClassReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  showReportFormat,
  showConditions,
  failedReportDownload,
  resetFailedDownload,
  className,
  isEndDateRequired = true,
  pastOnly = false,
  hideArchivedClasses = false,
  hideInactiveClasses = false,
  ...props
}) => {
  const { t } = useTranslation();
  const ALL_CLASS_OPTIONS = { value: 'All', label: `All ${capitalize(t('spelling.class'))}es` };
  const ALL_CONDITION_OPTIONS = { value: 'All', label: `All ${capitalize(t('spelling.condition'))}s` };
  const [formData, setFormData] = useState<IFormStateShape>({
    centerId: '',
    selectedClasses: [],
    selectedConditions: [],
    startDate: null,
    endDate: null,
    format: 'EXCEL',
  });

  const centerSelectOptions: ICenter[] = useGetActiveCenters();
  const { loading: classesLoading } = useGetClassesForCenters(formData.centerId ?? '');
  const [classSelectOptions, setClassSelectOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [conditionSelectOptions] = useState<Array<{ label: string; value: string }>>(
    isRegion('AU')
      ? Object.keys(MedicalConditions).map((item) => ({
          label: camelCaseToSpaced(item.replace('Immunization', 'Immunisation')),
          value: item,
        }))
      : Object.keys(MedicalConditions).map((item) => ({ label: camelCaseToSpaced(item), value: item }))
  );
  const classes = useSelector(getAllClasses);

  const isAllOptionSelected = useCallback(
    (): boolean => !!formData.selectedClasses && formData.selectedClasses.filter((i) => i.value == 'All').length === 1,
    [formData.selectedClasses, classSelectOptions]
  );

  const isAllConditonOptionSelected = useCallback(
    (): boolean =>
      !!formData.selectedConditions &&
      formData.selectedConditions.length > 0 &&
      formData.selectedConditions.length === conditionSelectOptions.length,
    [formData.selectedConditions, conditionSelectOptions]
  );

  useEffect(() => {
    if (failedReportDownload) resetFailedDownload();
  }, [formData]);

  useEffect(() => {
    let result = [...classes];
    result.sort((a, b) => a.name.localeCompare(b.name));

    if (hideInactiveClasses) {
      result = result.filter((item) => {
        if (item.archivedAt) {
          return false;
        }

        const startsAtMoment = moment(item.startsAt);
        const endsAtMoment = moment(item.endsAt);
        const now = moment();
        return (
          startsAtMoment.isValid() &&
          startsAtMoment.isBefore(now) &&
          (!endsAtMoment.isValid() || endsAtMoment.isAfter(now))
        );
      });
    } else if (hideArchivedClasses) {
      result = result.filter((option) => !option.archivedAt);
    }

    setClassSelectOptions(result.map((item) => ({ label: item.name, value: item.id })));
  }, [classes, hideArchivedClasses, hideInactiveClasses, setClassSelectOptions]);

  const getSelectValue = useCallback(() => {
    return isAllOptionSelected()
      ? [ALL_CLASS_OPTIONS]
      : classSelectOptions.filter(
          (opt) => !!formData.selectedClasses && formData.selectedClasses.map((i) => i.value).includes(opt.value)
        );
  }, [isAllOptionSelected, classSelectOptions, formData.selectedClasses]);

  const getConditionSelectValue = useCallback(() => {
    return isAllConditonOptionSelected()
      ? [ALL_CONDITION_OPTIONS]
      : conditionSelectOptions.filter(
          (opt) => !!formData.selectedConditions && formData.selectedConditions.map((i) => i.value).includes(opt.value)
        );
  }, [isAllConditonOptionSelected, conditionSelectOptions, formData.selectedConditions]);

  useEffect(() => {
    if (!isOpen) {
      clearTimeout(submitTimer);
      setFormData({
        centerId: '',
        selectedClasses: [],
        selectedConditions: [],
        startDate: null,
        endDate: null,
        format: 'EXCEL',
      });
    }
  }, [isOpen]);

  let submitTimer;

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (formData.startDate && formData.centerId && formData.selectedClasses) {
      onSubmit(
        formData.startDate,
        formData.endDate,
        formData.centerId,
        formData.selectedClasses.map((i) => i.value),
        formData.selectedConditions.map((i) => i.value),
        formData.format
      );
      if (!!submitTimer) {
        clearTimeout(submitTimer);
      }
      submitTimer = setTimeout(() => {
        handleSubmit();
      }, 3 * 55 * 1000);
    }
  }, [formData, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({
      centerId: '',
      selectedClasses: [],
      selectedConditions: [],
      startDate: null,
      endDate: null,
      format: 'EXCEL',
    });
    onClose();
  }, [onClose]);

  const handleClassSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_CLASS_OPTIONS.value) {
        setFormData({ ...formData, selectedClasses: classSelectOptions });
      } else if (
        (action === 'deselect-option' && option.value === ALL_CLASS_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_CLASS_OPTIONS.value)
      ) {
        setFormData({ ...formData, selectedClasses: [] });
      } else if (actionMeta.action === 'deselect-option' && isAllOptionSelected()) {
        setFormData({
          ...formData,
          selectedClasses: (formData.selectedClasses || []).filter((i) => i.value !== option.value),
        });
      } else {
        setFormData({ ...formData, selectedClasses: (options || []).filter((opt) => opt.value !== null) });
      }
    },
    [classSelectOptions, formData.selectedClasses, isAllOptionSelected]
  );

  const handleConditionSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_CONDITION_OPTIONS.value) {
        setFormData({ ...formData, selectedConditions: conditionSelectOptions });
      } else if (
        (action === 'deselect-option' && option.value === ALL_CONDITION_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_CONDITION_OPTIONS.value)
      ) {
        setFormData({ ...formData, selectedConditions: [] });
      } else if (actionMeta.action === 'deselect-option' && isAllConditonOptionSelected()) {
        setFormData({
          ...formData,
          selectedConditions: (formData.selectedConditions || []).filter((i) => i.value !== option.value),
        });
      } else {
        setFormData({ ...formData, selectedConditions: (options || []).filter((opt) => opt.value !== null) });
      }
    },
    [conditionSelectOptions, setFormData, formData, isAllConditonOptionSelected]
  );

  const downloadReport = () => {
    if (failedReportDownload) {
      window.open(failedReportDownload);
      handleClose();
    }
  };

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice={failedReportDownload ? 'Download Report' : 'Run Export'}
      primaryCallback={() => {
        failedReportDownload ? window.open(failedReportDownload) : handleSubmit();
      }}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled:
          !formData.startDate ||
          !formData.centerId ||
          !formData.selectedClasses ||
          !formData.selectedClasses.length ||
          (isEndDateRequired && !formData.endDate),
        loading: isLoading,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      className={className}
    >
      <Row>
        <Col>
          <Select
            required
            id="center-input"
            label={capitalize(t('spelling.center'))}
            aria-label={`Select ${capitalize(t('spelling.center'))}`}
            value={formData.centerId}
            options={centerSelectOptions.map((c) => ({ value: c.id, label: c.name }))}
            onChange={(option: any) => setFormData((prev) => ({ ...prev, centerId: option.value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              {capitalize(t('spelling.class'))}{' '}
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </Form.Label>
            <BaseSelect
              // @ts-ignore
              isMulti
              required
              id="class-input"
              isLoading={classesLoading}
              aria-label={`Select ${capitalize(t('spelling.class'))}`}
              // @ts-ignore
              value={getSelectValue()}
              options={[ALL_CLASS_OPTIONS, ...classSelectOptions]}
              // @ts-ignore
              onChange={(options, action) => handleClassSelect(options as any[], action)}
            />
          </Form.Group>
        </Col>
      </Row>
      {showConditions && (
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                {capitalize(t('spelling.condition'))}{' '}
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              </Form.Label>
              <BaseSelect
                // @ts-ignore
                isMulti
                required
                id="condition-input"
                isLoading={false}
                aria-label={`Select ${capitalize(t('spelling.condition'))}`}
                // @ts-ignore
                value={getConditionSelectValue()}
                options={[ALL_CONDITION_OPTIONS, ...conditionSelectOptions]}
                // @ts-ignore
                onChange={(options, action) => handleConditionSelect(options as any[], action)}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="mb-4">
          <DateInput
            required
            label={capitalize(t('spelling.start-date'))}
            date={formData.startDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, startDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(date: moment.Moment) =>
              (pastOnly && moment(date).isAfter(moment(), 'date')) ||
              (moment(formData.endDate).isValid() && date.isSameOrAfter(moment(formData.endDate), 'date'))
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <DateInput
            label={capitalize(t('spelling.end-date'))}
            date={formData.endDate}
            required={isEndDateRequired}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, endDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(date: moment.Moment) =>
              (pastOnly && moment(date).isAfter(moment(), 'date')) ||
              (moment(formData.startDate).isValid() && date.isBefore(moment(formData.startDate), 'date'))
            }
          />
        </Col>
      </Row>
      {showReportFormat && (
        <Row>
          <Col>
            <Select
              options={[
                { value: 'EXCEL', label: 'EXCEL' },
                { value: 'PDF', label: 'PDF' },
              ]}
              label={`${capitalize(t('spelling.format'))}(s)`}
              value={formData.format ?? { value: 'EXCEL', label: 'EXCEL' }}
              onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
              className="mb-0"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Col>
        </Row>
      )}
      {!!failedReportDownload && (
        <Alert className="mt-4" variant="success">
          <p className="mb-0">
            Your report was succesfully generated and can now be{' '}
            <a onClick={downloadReport} className="font-size-18 font-weight-normal cursor-pointer">
              downloaded
            </a>
            .
          </p>
        </Alert>
      )}
    </SideModalDrawer>
  );
};

export default TimeframeMultipleClassReportModal;
