import * as types from './types';

export type FieldValidationState = Record<
  string,
  {
    isValid: boolean;
    isArchived: boolean;
  }
>;
type EnrolmentFormDuckShape = Record<string, FieldValidationState>;
const initialState: EnrolmentFormDuckShape = {};

export const enrolmentFieldValidationStateReducers = (
  state: EnrolmentFormDuckShape = initialState,
  action: types.EnrolmentFormActionTypes
): EnrolmentFormDuckShape => {
  switch (action.type) {
    case 'CLEAR_FIELD_VALIDATION_STATE': {
      let newState = {
        ...state,
      };

      delete newState[action.enrolmentFormId];
      return newState;
    }
    case types.UPDATE_FIELD_VALIDATION_STATE:
      const fieldValidationState = action.fieldValidationState;

      let newState = {
        ...state,
      };
      newState[action.enrolmentFormId] = {
        ...newState[action.enrolmentFormId],
        [fieldValidationState.fieldName]: {
          isValid: fieldValidationState.isValid ?? true,
          isArchived: fieldValidationState.isArchived ?? false,
        },
      };
      return newState;
    default:
      return state;
  }
};
