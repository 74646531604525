import React, { useCallback } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import TimeOffForWeekAccordion from './TimeOffForWeekAccordion';
import ControlContainer from '../ControlContainer';
import TimeOffCell from './TimeOffCell';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getFullName } from 'shared/util/string';

interface IProps {
  loading: boolean;
  timeOffRequests: ITimeOff[];
  calendarType: CalendarViewType;
  startOfWeek: moment.Moment;
}

interface ISplitTimeOffShape {
  [key: number]: ITimeOff[]; // key is a number representing the day of the week (0 - sunday, 6 - saturday)
}

const TimeOffForWeek: React.FC<IProps> = ({ calendarType, loading, startOfWeek, timeOffRequests = [], ...props }) => {
  const { k2WebWeekendScheduling } = useFlags();
  const gridClassNames = classnames({
    'kt-schedules-time-off-overview-grid': true,
    'kt-schedules-time-off-overview-grid-week': calendarType === 'WEEK' && !k2WebWeekendScheduling,
    'kt-schedules-time-off-overview-grid-weekend': calendarType === 'WEEK' && k2WebWeekendScheduling,
    'kt-schedules-time-off-overview-grid-day': calendarType === 'DAY',
  });

  const columnClassNames = classnames({
    'p-1': true,
    'd-flex': true,
    'flex-column': calendarType === 'WEEK',
    'flex-row': calendarType === 'DAY',
    'align-items-center': true,
  });

  const splitTimeOffRequestsByDay = useCallback(
    (requests: ITimeOff[]): ISplitTimeOffShape => {
      /**
       * normally I would use lodash's groupby but if a request spans multiple days,
       * it needs to appear as a cell under each day
       */
      // starting at 1 since we only care about week days at this time
      const splits: ISplitTimeOffShape = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      };

      requests.forEach((req: ITimeOff) => {
        const day = moment(req.startTime).day();
        const daysSpanned = moment(req.endTime).diff(moment(req.startTime), 'days') + 1;

        if (daysSpanned === 1 && splits[day]) {
          splits[day].push(req);
        } else {
          const start = moment(req.startTime);
          const end = moment(req.endTime);
          const current = start.clone();

          while (current.isSameOrBefore(end)) {
            const day = current.day();
            const isSameWeek = current.week() === startOfWeek.week();
            const isWeekday = day > 0 && day < 6;

            if (isWeekday && isSameWeek) {
              splits[day].push(req);
            }

            current.add(1, 'day');
          }
        }
      });

      return splits;
    },
    [startOfWeek]
  );

  if (calendarType === 'DAY') {
    return null;
  }

  return (
    <ControlContainer className="mb-2 d-print-none">
      <TimeOffForWeekAccordion>
        {loading ? (
          <LoadingLines number={1} />
        ) : (
          <div className={gridClassNames}>
            {Object.values(splitTimeOffRequestsByDay(timeOffRequests)).map((requests: ITimeOff[], idx: number) => (
              <div key={`time-off-overview-day-${idx}`} className={columnClassNames}>
                {requests.map((req: ITimeOff, jdx: number) => (
                  <TimeOffCell
                    key={`time-off-overfiew-request-${req.id}-${jdx}`}
                    name={getFullName(req.person)}
                    timeframe={
                      req.allDay
                        ? 'All Day'
                        : `${moment(req.startTime).format('h:mm A')}-${moment(req.endTime).format('h:mm A')}`
                    }
                    indicatorColor={req.person.classAssignments[0]?.colorCode ?? null}
                    status={req.status}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
      </TimeOffForWeekAccordion>
    </ControlContainer>
  );
};

export default TimeOffForWeek;
