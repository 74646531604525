import { takeLatest, put, select } from 'redux-saga/effects';
import { setEntities } from './actions';
import { SIGNUP_COMPLETE } from 'pages/Login/duck/types';
import { CoreApolloClient } from 'shared/apis/core';
import { FETCH_ALLOWED_ENTITIES } from './types';
import { GET_ALLOWED_ENTITIES } from './queries';
import { createCenterTimezoneHash, createBusinessTimezoneHash } from '../timezones/actions';
import { getCentersSuccess } from '../../pages/Centers/duck/actions';
import { DefaultRootState } from 'react-redux';

function* getAllowedEntities() {
  try {
    // ignoring this for now because these files need to be converte
    // @ts-ignore
    const getUser = (state: DefaultRootState) => state.user.isInternal;
    // @ts-ignore
    const isInternal = yield select(getUser);

    const { data } = yield CoreApolloClient.query({
      query: GET_ALLOWED_ENTITIES(isInternal),
      fetchPolicy: 'network-only',
    });
    const entities: IEntity[] = data.getAllowedEntities;
    yield put(setEntities(entities));

    const allCenters =
      data?.getAllowedEntities.reduce((acc: ICenter[], curr: IEntity) => [...acc, ...curr.centers], []) ?? [];
    yield put(createBusinessTimezoneHash(entities));
    yield put(createCenterTimezoneHash(allCenters));
    yield put(getCentersSuccess(allCenters));
  } catch (error) {
    console.log(error);
  }
}

export const entitiesSagas = [
  takeLatest(SIGNUP_COMPLETE, getAllowedEntities),
  takeLatest(FETCH_ALLOWED_ENTITIES, getAllowedEntities),
];
