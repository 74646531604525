/**
 * Fee Structure Option for Create/Edit Fee Modals
 */
type FeeOption = {
  /**
   * Drop down value
   */
  value: FeeStructure;
  /**
   * Drop down label
   */
  label: string;
};

/**
 * Fee Structure Options for Create/Edit Fee Modals
 * @see /Pages/Centers/subroutes/Fees/components
 */
const feeStructureOptions: FeeOption[] = [
  { value: 'SESSION', label: 'Single Session' },
  { value: 'NUMBER_OF_DAYS', label: 'Number of Days' },
  { value: 'NUMBER_OF_SIBLINGS', label: 'Number of Siblings' },
  { value: 'SINGLE_FLAT_RATE', label: 'Single Flat Rate' },
];

export default feeStructureOptions;
