import { gql, useQuery } from '@apollo/client';
import { IGetSortedAccountsVariables } from 'gql/account/queries';
import {
  getAccountBillingCycleSuccess,
  getAccountByIdSuccess,
  getAccountItemizedBillsSuccess,
} from '../../duck/actions';
import { getContactsSuccess } from 'pages/Families/subroutes/Contacts/duck/actions';
import { useDispatch, useSelector } from 'react-redux';
import { agencyAccountChildFields } from 'gql/agency/fields';
import { billingCycleFields } from 'gql/billingCycle/fields';
import { itemizedBillFields } from 'gql/itemizedBill/fields';
import { isRegion } from 'shared/util/region';
import { accountContactFields, ccssAttributes } from 'gql/account/fields';
import { paymentMethodFields } from 'gql/paymentMethod/fields';
import { RootState } from 'store/reducers';
import useDatatableState from 'shared/hooks/useDatatableState';
import { DIRECTIONS } from 'shared/constants/elastic';
import moment from 'moment';

const isAuRegion = isRegion('AU');

interface IGetAccountPageData {
  getAccountById: IAccount;
  getAgencyAccountChildrenForAccount: IAgencyAccountChild[];
  getItemizedBillsByAccountId: IItemizedBillWithoutTransactions[];
  getAccountBillingCycle: IBillingCycle;
}

interface IGetAccountPageVariables {
  id: string;
  searchFilters?: IGetSortedAccountsVariables['input'];
}

export function useGetAccountPageData(accountId: string, options: { onError?: (e: Error) => void } = {}) {
  const dispatch = useDispatch();
  const [tableState] = useDatatableState('account', [{ field: 'name.keyword', direction: DIRECTIONS.ASCENDING }]);
  const account: IAccount | undefined = useSelector((state: RootState) => state.accounts.byId)[accountId];
  const { loading } = useQuery<IGetAccountPageData, IGetAccountPageVariables>(GetAccountPageDataQuery(), {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      dispatch(
        getAccountByIdSuccess({
          ...data.getAccountById,
          agencyAccountChildren: data.getAgencyAccountChildrenForAccount,
        })
      );

      dispatch(getAccountItemizedBillsSuccess(data.getAccountById.id, data.getItemizedBillsByAccountId ?? []));
      dispatch(getContactsSuccess(data.getAccountById?.contacts ?? []));

      dispatch(getAccountBillingCycleSuccess(data.getAccountById.id, data.getAccountBillingCycle ?? null));
    },
    onError: options.onError,
    variables: {
      id: accountId,
      searchFilters: {
        from: tableState.activePage,
        size: tableState.pageSize,
        centerIds: tableState.selectedFilters.center?.map((c) => c.value) ?? [],
        sort: tableState.sort,
        searchKey: tableState.searchExpressions.reduce((acc, e) => (acc + ' ' + e.term?.value).trim(), ''),
        tagIds: tableState.selectedFilters.tags?.map((tag) => tag.value) ?? [],
        centreTagIds: tableState.selectedFilters.centerTags?.map((centreTag) => centreTag.value) ?? [],
        statusType:
          !!tableState.selectedFilters.status && !!tableState.selectedFilters.status.length
            ? tableState.selectedFilters.status.length > 1
              ? undefined
              : (tableState.selectedFilters.status.map((s) => s.value)[0] as AccountStatusType)
            : undefined,
        statusAtDate: moment().format('YYYY-MM-DD'),
      },
    },
  });
  return { account, loading };
}

function GetAccountPageDataQuery() {
  return gql`
  query GetAccountPageDataQuery ($id: ID!, $searchFilters: AccountSearchInput!) {
    getAccountById(id: $id) {
      ${getFamiliesAccountPageFields()}
    }

    getAgencyAccountChildrenForAccount(accountId: $id) {
      ${agencyAccountChildFields}
    }

    getItemizedBillsByAccountId(accountId: $id) {
      ${itemizedBillFields}
    }

    getAccountBillingCycle(accountId: $id) {
      ${billingCycleFields}
    }
  }
`;
}

function getFamiliesAccountPageFields() {
  return `
id
centerId
entityId
familyId
surrogateKey
name
searchPosition(search: $searchFilters) {
  next
  previous
}
children {
  id
  accountChildId
  firstname
  lastname
  nickname
  fullName
  dob
  avatar {
    url
  }
  archivedAt
  ${isAuRegion ? ccssAttributes : ''}
}
contacts {
  ${accountContactFields}
}
center {
  name
  id
  entityId
  enableBillingCycleProcessing
  merchant {
    id
    merchantId
    processor
    isSandbox
  }
  timezone
  address{
    address1
    city
    state
    postalCode
  }
}
balance
pendingBalance
payrixCustomerId
paymentMethods {
  ${paymentMethodFields}
}
tags {
  id
  entityId
  name
  category
}
periodBalances {
  balance
  unbilledBalance
  pendingPayments
  amountDue
}
`;
}
