const action = {
  STAFF_ONBOARDING: 'STAFF_ONBOARDING',
  BASE_STAFF_ACCESS: 'BASE_STAFF_ACCESS',
  FULL_BUSINESS_PROFILE: 'FULL_BUSINESS_PROFILE',
  BUSINESS_TAGS: 'BUSINESS_TAGS',
  CREATE_CENTER: 'CREATE_CENTER',
  VIEW_CENTER: 'VIEW_CENTER',
  DELETE_CENTER: 'DELETE_CENTER',
  UPDATE_CENTER: 'UPDATE_CENTER',
  VIEW_CENTER_BILLING: 'VIEW_CENTER_BILLING',
  SEARCH_CENTER: 'SEARCH_CENTER',
  VIEW_PERSON: 'VIEW_PERSON',
  UPDATE_PERSON: 'UPDATE_PERSON',
  VIEW_STAFF_NOTES: 'VIEW_STAFF_NOTES',
  FULL_STAFF_NOTES: 'FULL_STAFF_NOTES',
  VIEW_STAFF_EMPLOYMENT: 'VIEW_STAFF_EMPLOYMENT',
  FULL_STAFF_EMPLOYMENT: 'FULL_STAFF_EMPLOYMENT',
  CREATE_STAFF: 'CREATE_STAFF',
  INTERNAL_ONLY: 'INTERNAL_ONLY',
  FULL_SCHEDULES: 'FULL_SCHEDULES',
  VIEW_TIMEOFFREQUESTS: 'VIEW_TIMEOFFREQUESTS',
  EDIT_TIMEOFFREQUESTS: 'EDIT_TIMEOFFREQUESTS',
  VIEW_TIMEENTRIES: 'VIEW_TIMEENTRIES',
  EDIT_TIMEENTRIES: 'EDIT_TIMEENTRIES',
  CREATE_GUARDIAN: 'CREATE_GUARDIAN',
  VIEW_GUARDIAN: 'VIEW_GUARDIAN',
  FULL_GUARDIAN: 'FULL_GUARDIAN',
  CREATE_CHILD: 'CREATE_CHILD',
  VIEW_CHILD: 'VIEW_CHILD',
  FULL_CHILD: 'FULL_CHILD',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  VIEW_ACCOUNT: 'VIEW_ACCOUNT',
  FULL_ACCOUNT: 'FULL_ACCOUNT',
  CREATE_ENROLLMENT: 'CREATE_ENROLLMENT',
  VIEW_ENROLLMENT: 'VIEW_ENROLLMENT',
  EDIT_ENROLLMENT: 'EDIT_ENROLLMENT',
  VIEW_FEE: 'VIEW_FEE',
  CREATE_FEE: 'CREATE_FEE',
  EDIT_FEE: 'EDIT_FEE',
  VIEW_CONTRACTS: 'VIEW_CONTRACTS',
  FULL_CONTRACTS: 'FULL_CONTRACTS',
  FULL_CHILD_ATTENDANCE_TIMES: 'FULL_CHILD_ATTENDANCE_TIMES',
  FULL_CHILD_ATTENDANCE_FINANCE: 'FULL_CHILD_ATTENDANCE_FINANCE',
  VIEW_CHILD_ATTENDANCE_FINANCE: 'VIEW_CHILD_ATTENDANCE_FINANCE',
  FULL_CCSS: 'FULL_CCSS',
  VIEW_CCSS: 'VIEW_CCSS',
  VIEW_BILLING_CYCLE_TEMPLATES: 'VIEW_BILLING_CYCLE_TEMPLATES',
  CREATE_BILLING_CYCLE_TEMPLATES: 'CREATE_BILLING_CYCLE_TEMPLATES',
  EDIT_BILLING_CYCLE_TEMPLATES: 'EDIT_BILLING_CYCLE_TEMPLATES',
  VIEW_ACCOUNT_BILLING_CYCLE: 'VIEW_ACCOUNT_BILLING_CYCLE',
  CREATE_ACCOUNT_BILLING_CYCLE: 'CREATE_ACCOUNT_BILLING_CYCLE',
  EDIT_ACCOUNT_BILLING_CYCLE: 'EDIT_ACCOUNT_BILLING_CYCLE',
  VIEW_TRANSACTION_TYPES: 'VIEW_TRANSACTION_TYPES',
  FULL_TRANSACTION_TYPES: 'FULL_TRANSACTION_TYPES',
  VIEW_TRANSACTIONS: 'VIEW_TRANSACTIONS',
  CREATE_TRANSACTIONS: 'CREATE_TRANSACTIONS',
  REVERSE_TRANSACTIONS: 'REVERSE_TRANSACTIONS',
  VIEW_STATEMENTS: 'VIEW_STATEMENTS',
  CREATE_STATEMENTS: 'CREATE_STATEMENTS',
  RECORD_ATTENDANCE: 'RECORD_ATTENDANCE',
  VIEW_REPORT: 'VIEW_REPORT',
};

export default action;
