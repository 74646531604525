import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { altArrangementFields, debtFields } from './fields';

interface IGetProviderDebts {
  getProviderDebts: ICcsDebt[];
}

interface IGetAlternateArrangements {
  getAlternateArrangements: ICcsAltArrangement[];
}

export const GET_PROVIDER_DEBTS = gql`
  query($providerId: ID!, $businessId: ID!) {
    getProviderDebts(providerId: $providerId, businessId: $businessId) {
      ${debtFields}
    }
  }
`;

export const GET_ALTERNATE_ARRANGEMENTS = gql`
  query($providerId: ID!, $businessId: ID!) {
    getAlternateArrangements(providerId: $providerId, businessId: $businessId) {
      ${altArrangementFields}
    }
  }
`;

export const useGetProviderDebts = (options?: QueryHookOptions<IGetProviderDebts, IGetProviderDebtVariables>) =>
  useQuery(GET_PROVIDER_DEBTS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetAltArrangements = (
  options?: QueryHookOptions<IGetAlternateArrangements, IGetProviderDebtVariables>
) =>
  useQuery(GET_ALTERNATE_ARRANGEMENTS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
