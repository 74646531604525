import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { RootState } from 'store/reducers';
import DeleteLinkModal from './DeleteLinkModal';
import Button, { CreateButton } from 'shared/components/Buttons';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { ILink, useGetLinkCodes } from 'pages/Enrollment/subroutes/Settings/graphql/queries';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { useTranslation } from 'react-i18next';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import DataTable from 'shared/components/DataTable';
import CreateLinkCodeModal from './CreateLinkCodeModal';
import { getHostName } from '../../../LeadManagement/utils';
import './LinksManagement.scss';
import { showToast } from '../../../../../../shared/components/Toast';

interface IProps {
  entityId: string;
}

const expandRowDefault = (
  linkCode: ILink,
  centers: ICenter[],
  onDelete: (linkCode: ILink) => void,
  hasDeleteLinkPermission: boolean,
  onUpdateButtonClick: (linkCode: ILink) => void,
  hasEditLinkPermission: boolean
) => (
  <ExpandRowDefaultComponent
    centers={centers}
    linkCode={linkCode}
    onDelete={onDelete}
    hasDeleteLinkPermission={hasDeleteLinkPermission}
    onUpdateButtonClick={onUpdateButtonClick}
    hasEditLinkPermission={hasEditLinkPermission}
  />
);

const ExpandRowDefaultComponent: React.FC<{
  linkCode: ILink;
  centers: ICenter[];
  onDelete: (linkCode: ILink) => void;
  hasDeleteLinkPermission: boolean;
  onUpdateButtonClick: (linkCode: ILink) => void;
  hasEditLinkPermission: boolean;
}> = ({ linkCode, centers, onDelete, hasDeleteLinkPermission, onUpdateButtonClick, hasEditLinkPermission }) => {
  const { t } = useTranslation(['translation', 'business']);

  return (
    <div>
      <DataTable
        className="link-code__attached-centers-table"
        data={centers}
        dataSize={centers.length}
        showSelect={false}
        showPagination={false}
        noPadding
        keyField="id"
        columns={[
          {
            text: t('business:enrolment.link-centers-attached'),
            dataField: 'name',
          },
        ]}
      />

      <div className="d-flex align-items-center mb-4">
        <div className="ml-auto">
          {hasDeleteLinkPermission && (
            <Button
              id="link-code-delete-btn"
              className="link-code__delete-btn"
              variant="outline-danger"
              onClick={() => onDelete(linkCode)}
            >
              {t('business:enrolment.link-code-delete-button')}
            </Button>
          )}
          {hasEditLinkPermission && (
            <Button id="link-code-delete-btn" variant="secondary" onClick={() => onUpdateButtonClick(linkCode)}>
              {t('business:enrolment.link-code-update-button')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const LinksManagement: React.FC<IProps> = ({ entityId, ...props }) => {
  const { data: centers } = useGetActiveCentersWithLoading();
  const { t } = useTranslation(['translation', 'business']);
  const businessId = useSelector<RootState, string>((state) => state.user?.entityId!);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [linkCodeToDelete, setLinkCodeToDelete] = useState<ILink | undefined>(undefined);
  const [linkCodeToUpdate, setLinkCodeToUpdate] = useState<ILink | undefined>(undefined);

  const { data, loading: isFetching, refetch } = useGetLinkCodes(entityId);

  const hasCreateLinkPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Create,
  });
  const hasDeleteLinkPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Delete,
  });
  const hasEditLinkPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Edit,
  });

  const onDeleteButtonClick = (linkCode: ILink) => {
    setLinkCodeToDelete(linkCode);
    setShowDeleteModal(true);
  };

  const onUpdateButtonClick = (linkCode: ILink) => {
    setLinkCodeToUpdate(linkCode);
    setShowCreateModal(true);
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-4">
        <h3>{t('business:enrolment.link-management')}</h3>
        {hasCreateLinkPermission && (
          <CreateButton className="ml-auto" variant="primary" onClick={() => setShowCreateModal(true)}>
            {capitalize(t('translation:spelling.create'))}
          </CreateButton>
        )}
      </div>

      {!data?.getLinkCodes || isFetching ? (
        <DataTableLoadingSkeleton />
      ) : (
        <DataTable
          className="link-code__table"
          data={data.getLinkCodes}
          dataSize={data.getLinkCodes.length}
          showSelect={false}
          showPagination={false}
          noPadding
          showLoadingOverlay={isFetching}
          keyField="linkCode"
          expandRow={(row: ILink) =>
            expandRowDefault(
              row,
              centers?.filter((c) => row.associatedCenterIds?.includes(c.id)) || [],
              onDeleteButtonClick,
              hasDeleteLinkPermission,
              onUpdateButtonClick,
              hasEditLinkPermission
            )
          }
          columns={[
            {
              text: t('business:enrolment.link-name'),
              dataField: 'name',
              sort: true,
            },
            {
              text: t('business:enrolment.link-centers-attached'),
              dataField: 'associatedCenterIds',
              sort: true,
              formatter: (cell: string, row: ILink) => (
                <>
                  <span>{row.associatedCenterIds?.length}</span>
                </>
              ),
            },
            {
              text: t('business:enrolment.link-code'),
              dataField: 'linkCode',
              sort: true,
              formatter: (cell: string, row: ILink) => (
                <>
                  <span className="link-code__table__link">
                    https://{getHostName()}/inquiry/{row.linkCode}
                  </span>
                </>
              ),
            },
            {
              text: '',
              dataField: '',
              sort: false,
              formatter: (cell: string, row: ILink) => (
                <>
                  <Button
                    children={'Copy Link'}
                    className="btn-secondary"
                    onClick={() => {
                      navigator.clipboard.writeText(`https://${getHostName()}/inquiry/${row.linkCode}`);
                      showToast('Link was copied successfully', 'success');
                    }}
                  />
                </>
              ),
            },
          ]}
        />
      )}

      <CreateLinkCodeModal
        businessId={businessId}
        isOpen={showCreateModal}
        onClose={() => {
          setLinkCodeToUpdate(undefined);
          setShowCreateModal(false);
        }}
        onSuccess={() => refetch()}
        linkCode={!!linkCodeToUpdate ? linkCodeToUpdate.linkCode : ''}
        name={!!linkCodeToUpdate ? linkCodeToUpdate.name : ''}
        associatedCenters={
          !!linkCodeToUpdate ? centers?.filter((c) => linkCodeToUpdate.associatedCenterIds?.includes(c.id)) || [] : []
        }
      />

      <DeleteLinkModal
        businessId={businessId}
        linkCode={linkCodeToDelete as ILink}
        isOpen={showDeleteModal}
        onSuccessDelete={() => refetch()}
        onClose={() => {
          setShowDeleteModal(false);
          setLinkCodeToDelete(undefined);
        }}
      />
    </div>
  );
};

export default LinksManagement;
