import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataControlAccordion from 'pages/TimeManagement/subroutes/StaffSchedules/components/DataControls/DataControlAccordion';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import colors from '_colors.module.scss';

import ConcessionCardForm from '../AddProgramModal/ConcessionCardForm';
import { getFullName } from 'shared/util/string';
import {
  buildUpdateChildProgramInput,
  validateUpdateChildProgramForm,
  buildKindyForAllData,
  buildDelayedExitDataForm,
  buildStartStrongData,
} from '../AddProgramModal/utils';
import '../AddProgramModal/addProgramModal.scss';
import DateInput from 'shared/components/DateInput';
import EditConcessionCardForm from './EditConcessionCardForm';
import EditFamilyTaxBenefitForm from './EditFamilyTaxBenefitForm';
import FamilyTaxBenefitForm from '../AddProgramModal/FamilyTaxBenefitForm';
import { useGetAccountsForChild } from 'gql/account/queries';
import QGrantsChildInformationForm from '../AddProgramModal/QGrantsChildInformationForm';
import StartStrongChildInformation from '../AddProgramModal/StartStrongChildInformation';
import { kindyForAllSubsidySchemeId, startStrongSubsidySchemeId } from 'pages/Subsidies/KindyFunding/utils';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  isLoading: boolean;
  programChild: IProgramChild;
  childFundingSchedules: IKindyProgramChildFundingSchedulesInput;
  handleSubmit: (values: IProgramChildAndFundingSchedulesUpdateInput) => void;
  account: IAccount;
}

const emptyConcessionCard = {
  contact: null,
  type: undefined,
  number: undefined,
  children: null,
  effectiveDate: undefined,
  expiryDate: undefined,
  file: undefined,
  linkToFile: undefined,
};

const emptyDelayedExit = {
  child: null,
  notes: undefined,
  file: undefined,
  linkToFile: undefined,
};

const emptyFamilyTaxBenefit = {
  account: null,
  part: undefined,
  file: undefined,
  linkToFile: undefined,
};

const initialForm: IEditProgramChildForm = {
  startDate: undefined,
  endDate: undefined,
  primaryAccountId: undefined,
  associatedAccountId: undefined,
  concession: { ...emptyConcessionCard },
  delayedExit: { ...emptyDelayedExit },
  familyTaxBenefit: { ...emptyFamilyTaxBenefit },
  multiBirth: false,
  childReceivesAccs: false,
  kindyForAllChild: undefined,
  startStrongChild: undefined,
  startStrongConsent: 'None',
  excludeStartStrongFunding: false,
  childProgramFundingSchedules: undefined,
};

const EditProgramModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  programChild,
  childFundingSchedules,
  account,
  handleClose,
  handleSubmit,
}) => {
  const { t } = useTranslation(['translation', 'subsidies']);
  const [formData, setFormData] = useState<IEditProgramChildForm>(initialForm);
  const [primaryAccountOptions, setPrimaryAccountOptions] = useState<IAccount[]>([]);
  const { data: childAccountsData, loading: childAccountLoading } = useGetAccountsForChild(programChild.childId);

  useEffect(() => {
    setPrimaryAccountOptions(
      childAccountsData?.getAccountsForChild.filter((o) => o.centerId === programChild.program?.centerId) ?? []
    );
  }, [childAccountsData, programChild.program]);

  const icon = <FontAwesomeIcon className="mr-2" color={colors.success} icon={faPlus} />;
  const accountChild = account.children.find((child) => child.archivedAt === null && child.id === programChild.childId);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
      return;
    }

    if (!!programChild)
      setFormData({
        startDate: programChild.startDate,
        endDate: programChild.endDate,
        primaryAccountId: programChild.primaryAccountId,
        associatedAccountId: programChild.associatedAccountId,
        lastFundPaidDate: programChild.lastFundPaidDate,
        childReceivesAccs: programChild.childReceivesAccs,
        concession: !!programChild.concessionCard
          ? {
              id: programChild.concessionCard.id,
              contact: account.contacts?.find((c) => c.id === programChild.concessionCard.contactId) ?? null,
              type: programChild.concessionCard.type,
              children: accountChild ? [accountChild] : null,
              number: programChild.concessionCard.cardNumber ?? undefined,
              effectiveDate: programChild.concessionCard.effectiveDate ?? undefined,
              expiryDate: programChild.concessionCard.expiryDate ?? undefined,
              file: undefined,
              linkToFile: programChild.concessionCard.documentUri ?? undefined,
              fileName: programChild?.concessionCard?.fileName ?? undefined,
            }
          : { ...emptyConcessionCard },
        delayedExit: !!programChild.delayedExit
          ? {
              id: programChild.delayedExit.id,
              child: null,
              notes: !!programChild.delayedExit ? programChild.delayedExit.notes : undefined,
              file: undefined,
              linkToFile: !!programChild.delayedExit ? programChild.delayedExit.documentUri : undefined,
              fileName: programChild?.delayedExit?.fileName,
            }
          : { ...emptyDelayedExit },
        familyTaxBenefit: !!programChild.familyTaxBenefit
          ? {
              id: programChild.familyTaxBenefit.id,
              account: account,
              part: programChild.familyTaxBenefit.part ?? 'None',
              file: undefined,
              linkToFile: !!programChild.familyTaxBenefit ? programChild.familyTaxBenefit.documentUri : undefined,
              fileName: programChild?.familyTaxBenefit?.fileName,
            }
          : { ...emptyFamilyTaxBenefit },
        multiBirth: !!programChild.child.multipleBirths,
        kindyForAllChild: buildKindyForAllData(programChild.kindyForAllChild, accountChild as IAccountChild),
        startStrongChild: buildStartStrongData(programChild, account.id, accountChild as IAccountChild),
        startStrongConsent: programChild.startStrongConsent,
        excludeStartStrongFunding: programChild.excludeStartStrongFunding,
        childProgramFundingSchedules: childFundingSchedules,
      });
  }, [programChild, isOpen, account, childFundingSchedules, accountChild]);

  const handleFormChange = (value: any, name: string) => {
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleConcession = (value: any, name: string) => {
    setFormData((state) => ({
      ...state,
      concession: { ...state.concession, [name]: value },
    }));
  };

  const handleChildInformation = (selected: IKindyForAllChildInformationForm) => {
    setFormData((state) => ({
      ...state,
      kindyForAllChild: selected.kindyForAllChild,
      delayedExit: selected.delayedExit,
      childProgramFundingSchedules: {
        programChildId: selected.childProgramFundingSchedules?.programChildId,
        schedules: selected.childProgramFundingSchedules?.schedules.filter((s) => s.startDate),
      } as IKindyProgramChildFundingSchedulesInput,
    }));
  };

  const handleStartStrongChildInformation = (selected: IStartStrongChildInformationForm) => {
    setFormData({
      ...formData,
      startStrongChild: selected.startStrongChild,
      startStrongConsent: selected.startStrongChild?.startStrongConsent ?? 'None',
      excludeStartStrongFunding: selected.startStrongChild?.excludeStartStrongFunding ?? false,
      childProgramFundingSchedules: {
        programChildId: selected.childProgramFundingSchedules?.programChildId,
        schedules: selected.childProgramFundingSchedules?.schedules.filter((s) => s.startDate),
      } as IKindyProgramChildFundingSchedulesInput,
    });
  };

  const handleFamilyTaxBenefit = (value: any, name: string) => {
    if (value.value === 'None') {
      setFormData((state) => ({
        ...state,
        familyTaxBenefit: { ...emptyFamilyTaxBenefit },
      }));
      return;
    }

    // eslint-disable-next-line no-unused-expressions
    name === 'part'
      ? setFormData((state) => ({
          ...state,
          familyTaxBenefit: { ...state.familyTaxBenefit, [name]: value.value, account: account },
        }))
      : setFormData((state) => ({
          ...state,
          familyTaxBenefit: { ...state.familyTaxBenefit, [name]: value, account: account },
        }));
  };

  const resetForm = () => {
    setFormData(initialForm);
  };

  const onSave = useCallback(() => {
    const isValid = validateUpdateChildProgramForm(
      formData,
      programChild.concessionCard?.id,
      programChild.delayedExit?.id,
      programChild.familyTaxBenefit?.id
    );
    if (isValid)
      handleSubmit(
        buildUpdateChildProgramInput(
          formData,
          programChild.id,
          account,
          account,
          programChild.program?.id || '',
          programChild.childId,
          programChild.concessionCard?.id,
          programChild.delayedExit?.id,
          programChild.familyTaxBenefit?.id
        )
      );
  }, [
    handleSubmit,
    account,
    formData,
    programChild.childId,
    programChild.concessionCard,
    programChild.delayedExit,
    programChild.familyTaxBenefit,
    programChild.id,
    programChild.program,
  ]);

  const getChildrenOptions = useCallback(() => {
    const activeChildren = account.children.filter((child) => child.archivedAt === null);
    const selectedIds = [programChild.childId];
    if (selectedIds.length === 0) return activeChildren;
    const filteredChildren = activeChildren.filter((child) => selectedIds.includes(child.id));
    return filteredChildren;
  }, [programChild, isOpen]);

  return (
    <Modal centered backdrop="static" show={isOpen} onHide={handleClose} size="lg">
      <Modal.Header closeButton className="px-4 py-2">
        <div className="d-flex flex-column">
          {/* @ts-ignore Type instantiation is excessively deep and possibly infinite. */}
          <Modal.Title as="h5"> {t('subsidies:program-child.update-modal.update-program')}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-4 px-5">
        <FormWrapper2
          formIsDirty
          toggleDirty={(value) => {}}
          onCancel={handleClose}
          onSave={onSave}
          loading={isLoading || childAccountLoading}
          saveDisabled={
            !validateUpdateChildProgramForm(
              formData,
              programChild.concessionCard?.id,
              programChild.delayedExit?.id,
              programChild.familyTaxBenefit?.id
            )
          }
        >
          <h5 className="mb-4">{t('subsidies:program-child.create-modal.program-details')}</h5>
          <Row>
            <Col lg={6}>
              <Select
                required
                options={[programChild.program]}
                value={programChild.program}
                disabled={true}
                name="program"
                label={t('subsidies:program-child.create-modal.select-program')}
                getOptionLabel={(program: IProgram) => program.name}
                getOptionValue={(program: IProgram) => program.id}
                onChange={() => {}}
              />
            </Col>
            <Col lg={6}>
              <Select
                required
                options={[programChild.child]}
                getOptionLabel={(child: IChild) => getFullName(child)}
                getOptionValue={(child: IChild) => child.id}
                label={t('subsidies:program-child.create-modal.children')}
                name="children"
                value={programChild.child}
                onChange={() => {}}
                disabled={true}
                isMulti
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="pt-2">
              <Select
                required
                options={primaryAccountOptions}
                getOptionLabel={(account: IAccount) => account.name}
                getOptionValue={(account: IAccount) => account.id}
                label={t('subsidies:program-child.create-modal.account-to-be-paid')}
                name="primaryAccountId"
                value={primaryAccountOptions.find((o) => o.id === formData.primaryAccountId) ?? null}
                onChange={(val: IAccount) => {
                  handleFormChange(val.id, 'primaryAccountId');
                }}
              />
            </Col>
            <Col lg={6} className="pt-2 mb-4">
              <DateInput
                label={t('subsidies:program-child.create-modal.est-last-paid-date')}
                helpTooltipText={t('subsidies:program-child.create-modal.est-last-paid-info')}
                className="max-width-unset"
                date={formData.lastFundPaidDate}
                onDateSelect={(value) => {
                  handleFormChange(value, 'lastFundPaidDate');
                }}
              />
            </Col>
          </Row>

          <Row className="pt-2 mb-4">
            <Col>
              <DateInput
                label={t('subsidies:program.start-date')}
                className="max-width-unset"
                date={formData.startDate}
                required
                onDateSelect={(value) => {
                  handleFormChange(value, 'startDate');
                }}
              />
            </Col>
            <Col>
              <DateInput
                label={t('subsidies:program.end-date')}
                className="max-width-unset"
                date={formData.endDate}
                onDateSelect={(value) => {
                  handleFormChange(value, 'endDate');
                }}
              />
            </Col>
          </Row>

          {programChild.program?.subsidyScheme?.subsidySchemeId === kindyForAllSubsidySchemeId && (
            <Row className="mb-2">
              <Col>
                {!!programChild.familyTaxBenefit && !!programChild.familyTaxBenefit.id ? (
                  <EditFamilyTaxBenefitForm
                    programChild={programChild}
                    formData={formData.familyTaxBenefit}
                    handleChange={handleFamilyTaxBenefit}
                    showDocumentSelect={!!formData.familyTaxBenefit?.part && formData.familyTaxBenefit?.part != 'None'}
                  />
                ) : (
                  <FamilyTaxBenefitForm
                    formData={formData.familyTaxBenefit}
                    handleChange={handleFamilyTaxBenefit}
                    showDocumentSelect={!!formData.familyTaxBenefit?.part && formData.familyTaxBenefit?.part != 'None'}
                  />
                )}
              </Col>
            </Row>
          )}

          <Row className="mb-2">
            <Col>
              <DataControlAccordion
                defaultOpen={false}
                title={
                  <div>
                    {icon}
                    {t('subsidies:program-child.create-modal.concession-card')}
                  </div>
                }
                className="px-4 py-2 program-accordion"
              >
                {!!programChild.concessionCard && !!programChild.concessionCard.id ? (
                  <EditConcessionCardForm
                    concessionCard={programChild.concessionCard}
                    children={[programChild.child]}
                    handleChange={handleConcession}
                    formData={formData.concession}
                  />
                ) : (
                  <ConcessionCardForm
                    contacts={account.contacts}
                    accountChildren={getChildrenOptions()}
                    handleChange={handleConcession}
                    isPrePopulated={false}
                    formData={formData.concession}
                  />
                )}
              </DataControlAccordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <DataControlAccordion
                defaultOpen={false}
                title={
                  <div>
                    {icon}
                    Child Information
                  </div>
                }
                className="px-4 py-2 program-accordion"
              >
                {programChild.program?.subsidyScheme?.subsidySchemeId === startStrongSubsidySchemeId ? (
                  <StartStrongChildInformation
                    accountChild={account.children.find((child) => child.id === programChild.childId)!}
                    accountId={account.id}
                    programStartDate={programChild.program.startDate}
                    handleChange={handleStartStrongChildInformation}
                    initialFormData={{
                      startStrongChild: buildStartStrongData(programChild, account.id, accountChild as IAccountChild),
                      childProgramFundingSchedules: childFundingSchedules,
                    }}
                  />
                ) : (
                  <QGrantsChildInformationForm
                    subsidySchemeId={programChild.program?.subsidyScheme?.subsidySchemeId}
                    accountChild={account.children.find((child) => child.id === programChild.childId)!}
                    programChild={programChild}
                    handleChange={handleChildInformation}
                    initialFormData={{
                      kindyForAllChild: buildKindyForAllData(
                        programChild.kindyForAllChild,
                        accountChild as IAccountChild
                      ),
                      delayedExit: buildDelayedExitDataForm(
                        programChild.delayedExit,
                        accountChild as IAccountChild
                      ) as IDelayedExitForm,
                      childProgramFundingSchedules: childFundingSchedules,
                    }}
                  />
                )}
              </DataControlAccordion>
            </Col>
          </Row>
        </FormWrapper2>
      </Modal.Body>
    </Modal>
  );
};

export default EditProgramModal;
