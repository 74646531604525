import React from 'react';
import Card from 'shared/components/Card';
import { Col, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  destinationOptions: IDestinationStateShape[];
  selectedDestinations: string[];
  childrenData: IBusRosterAccountChildGridShape[];
}

const BusRosterDestinationCards: React.FC<IProps> = ({ destinationOptions, selectedDestinations, childrenData }) => {
  return (
    <Row>
      {selectedDestinations.map((destinationId) => {
        let assignedChildren = childrenData.filter((child) => child.input.destination === destinationId);
        return (
          <Col xs="12" lg="4" key={uuidv4()}>
            <Card>
              <h6>{destinationOptions.find((d) => d.value === destinationId)?.label}</h6>
              <p>{`${assignedChildren.length} ${assignedChildren.length === 1 ? 'Child' : 'Children'}`}</p>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default BusRosterDestinationCards;
