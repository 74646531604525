import React from 'react';
import { Link } from 'react-router-dom';
import RBAlert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

const NO_OP = () => {};

interface IProps {
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const ErrorBoundaryBannerAndLink: React.FC<IProps> = ({ onLinkClick = NO_OP, ...props }) => {
  return (
    <>
      <RBAlert variant="danger" className="mb-4">
        <RBAlert.Heading as="h3">
          <FontAwesomeIcon className="mr-2" icon={faExclamationCircle} />
          Something went wrong
        </RBAlert.Heading>
      </RBAlert>
      <Link
        to="/home"
        className="d-block text-center mb-8"
        style={{ fontSize: 24, fontWeight: 'bold' }}
        onClick={onLinkClick}
      >
        Back to Kangarootime
      </Link>
    </>
  );
};

export default ErrorBoundaryBannerAndLink;
