import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
// import { itemizedBillWithTransactionFields } from './fields';

export const UPDATE_KISI_API_KEY = gql`
  mutation ($input: UpdateBusinessKisiApiKeyInput!) {
    updateBusinessKisiApiKey(input: $input) {
      id
    }
  }
`;

export const useUpdateKisiApiKey = (options?: MutationHookOptions<IBusiness, IUpdateBusinessKisiApiKey>) =>
  useMutation<IBusiness, IUpdateBusinessKisiApiKey>(UPDATE_KISI_API_KEY, {
    ...options,
  });

export const UPDATE_CENTER_KISI_PLACE_ID = gql`
  mutation ($input: UpdateCenterKisiPlaceIdInput!) {
    updateCenterKisiPlaceId(input: $input) {
      id
    }
  }
`;

export const useUpdateCenterKisiPlaceId = (options?: MutationHookOptions<ICenter, IUpdateCenterKisiPlaceId>) =>
  useMutation<ICenter, IUpdateCenterKisiPlaceId>(UPDATE_CENTER_KISI_PLACE_ID, {
    ...options,
  });

export const UNLOCK_KISI_LOCK = gql`
  mutation ($lockId: Int!, $input: UnlockLockInput!) {
    unlockLock(lockId: $lockId, input: $input) {
      isUnlock
      error
    }
  }
`;

export const useUnlockLock = (options?: MutationHookOptions<IUnlockResponse, IUnlockLockInput>) =>
  useMutation<IUnlockResponse, IUnlockLockInput>(UNLOCK_KISI_LOCK, {
    ...options,
  });
