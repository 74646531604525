import React from 'react';
import { Placement } from 'react-bootstrap/esm/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useUniqueId from 'shared/hooks/useUniqueId';

interface IProps {
  text: string;
  direction?: Placement;
  delayShow?: number;
  delayHide?: number;
  id?: string;
  additionalClass?: string;
  showTooltip?: boolean;
  popperConfig?: any;
  children: React.ReactNode | null;
}

const KTTooltip: React.FC<IProps> = ({
  text,
  id,
  direction = 'right',
  delayShow = 0,
  delayHide = 0,
  additionalClass = '',
  showTooltip = true,
  popperConfig = {},
  children,
  ...props
}) => {
  const _id = useUniqueId(id);

  if (!showTooltip) {
    return <> {children} </>;
  }

  return (
    <OverlayTrigger
      placement={direction}
      delay={{ show: delayShow, hide: delayHide }}
      // @ts-ignore
      overlay={renderTooltip(text, additionalClass, _id)}
      popperConfig={popperConfig}
      {...props}
    >
      {children as React.ReactElement}
    </OverlayTrigger>
  );
};

const renderTooltip = (text: string, additionalClass: string = '', id: string = '') => {
  return (
    text && (
      <Tooltip id={id} className={'kt-tooltip ' + (additionalClass || '')}>
        {text}
      </Tooltip>
    )
  );
};

export default KTTooltip;
