import React from 'react';
import TagsForm from '../Forms/TagsForm';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import Card from 'shared/components/Card';

interface IProps {
  center: ICenter | undefined;
  canPerformEdit: boolean;
  loading: boolean;
}

const TagsCard: React.FC<IProps> = ({ center, canPerformEdit, loading }) => (
  <Card header="Tags">
    {loading || !center ? <LoadingLines /> : <TagsForm center={center} canPerformEdit={canPerformEdit} />}
  </Card>
);

export default TagsCard;
