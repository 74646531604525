import React from 'react';
import { Circle } from 'shared/components/Shapes';
import colors from '_colors.module.scss';
import Avatar from 'shared/components/Avatar';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { getFullName, getInitials } from 'shared/util/string';
import { getTotalHours } from '../../utils';

interface IProps {
  timeOff: ITimeOff;
}

const WeekViewTimeOffEvent: React.FC<IProps> = ({ timeOff }) => {
  const timezone =
    useSelector((state: RootState) =>
      timeOff.centerId ? state.timezone.byCenterId[timeOff.centerId] : state.timezone.byBusinessId[timeOff.entityId]
    ) ?? moment.tz.guess();

  const startDate = moment(timeOff.startTime).tz(timezone).format('MMM DD, YYYY');
  const endDate = moment(timeOff.endTime).tz(timezone).format('MMM DD, YYYY');
  const isMultiDay = startDate !== endDate;
  const isTwoWeeksOld = moment(timeOff.createdAt).add(2, 'weeks').isBefore(moment()) && timeOff.status === 'Pending';

  return (
    <div className={`d-flex p-2 rounded align-items-center mb-2 time-off-${timeOff.status?.toLowerCase()}`}>
      <Circle className="mr-2" size="xxs" background={timeOff.person.classAssignments[0]?.colorCode || colors.gray} />
      <Avatar size="md" image={timeOff.person.avatar?.url} initials={getInitials(timeOff.person)} className="mr-2" />
      <div>
        <div>{getFullName(timeOff.person)}</div>
        <div className="sm text-uppercase">
          {isMultiDay ? moment(timeOff.startTime).format('MMM DD') : startDate}
          {isMultiDay && ' - '} {isMultiDay && endDate}
        </div>
      </div>
      <div className="ml-auto align-items-end d-flex flex-column text-right">
        <small>
          <span className={isTwoWeeksOld ? 'text-danger' : ''}>
            Request: {moment(timeOff.createdAt).format('MM/DD/YY')}
          </span>
        </small>
        <div className="sm">
          Unpaid: {(getTotalHours(timeOff) - (timeOff.hoursApproved || 0)).toString()} / Paid: {timeOff.hoursApproved}
        </div>
      </div>
    </div>
  );
};

export default WeekViewTimeOffEvent;
