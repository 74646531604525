import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useArchiveAgency } from 'gql/agency/mutations';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { updateAgency } from './duck/actions';

interface IProps {
  isOpen: boolean;
  agency: IAgency;
  onClose: () => void;
}

const ArchiveAgencyModal: React.FC<IProps> = ({ isOpen, agency, onClose, ...props }) => {
  const { t } = useTranslation(['subsidies']);
  const dispatch = useDispatch();
  const [archiveFn, { loading: archiveLoading }] = useArchiveAgency({
    onCompleted: (result) => {
      showToast(t('subsidies:agencies.archive-modal.success-toast-message'), 'success');
      dispatch(updateAgency(result.archiveAgency));
      onClose();
    },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string'
              ? err.message
              : // @ts-ignore - TypeScript doesn't like the dynamic lookup
                err.message?.message?.toString() ?? t('subsidies:agencies.archive-modal.error-toast-message');
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  return (
    <ConfirmationModal
      title={t('subsidies:agencies.archive-modal.title', { name: agency.name })}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice={t('subsidies:agencies.archive-modal.primary-button-text')}
      primaryCallback={() => archiveFn({ variables: { id: agency.id } })}
      primaryButtonProps={{ variant: 'danger', loading: archiveLoading }}
    >
      <p>{t('subsidies:agencies.archive-modal.body')}</p>
      <p>{t('subsidies:agencies.archive-modal.confirmation-text')}</p>
    </ConfirmationModal>
  );
};

export default ArchiveAgencyModal;
