import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import { FileUploadDropbox } from 'shared/components/FileOperations';
import FormLabel from 'shared/components/Form/FormLabel';
import { Col, Row } from 'shared/components/Layout';
import useK2Upload from 'shared/hooks/useK2Upload';
import { emptyK2File, fileToK2Upload } from 'shared/util/file';
import ImportInstruction from '../ImportInstruction';
import UploadButton from '../UploadButton';

interface IProps extends IImportStepProps {
  importFamilies: (data: IProcareFamiliesVariables) => void;
}

const FamiliesImport: React.FC<IProps> = ({ importFamilies, center, loading, shouldReset, completed = false }) => {
  const { t } = useTranslation('ktAdmin');
  const [isDryRun, setDryRun] = useState(true);
  const { files: careTypeFiles, setFiles: setCareTypeFiles, k2File: careTypeK2File } = useK2Upload(shouldReset);
  const { files: childrenFiles, setFiles: setChildrenFiles, k2File: childrenK2File } = useK2Upload(shouldReset);
  const { files: contactFiles, setFiles: setContactFiles, k2File: ccontactK2File } = useK2Upload(shouldReset);
  const { files: authFiles, setFiles: setAuthFiles, k2File: authK2File } = useK2Upload(shouldReset);

  const handleUpload = () => {
    importFamilies({
      businessId: center?.entityId || '',
      centerId: center?.id || '',
      authorizations: authK2File || emptyK2File,
      children: childrenK2File || emptyK2File,
      contacts: ccontactK2File || emptyK2File,
      dryRun: isDryRun,
    });
  };

  const selectedFiles = careTypeFiles.length + childrenFiles.length + contactFiles.length + authFiles.length;

  return (
    <Card header="Import">
      <ImportInstruction isDryRun={isDryRun} setDryRun={setDryRun} />

      <Row>
        <Col>
          <FormLabel required>{t('data-importer.contacts')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setContactFiles}
            uploadedFiles={contactFiles}
            setUploadedFiles={setContactFiles}
            withProgress={false}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <FormLabel required>{t('data-importer.children')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setChildrenFiles}
            uploadedFiles={childrenFiles}
            setUploadedFiles={setChildrenFiles}
            withProgress={false}
          />
        </Col>
        <Col>
          <FormLabel required>{t('data-importer.authorizations')}</FormLabel>
          <FileUploadDropbox
            multiple={false}
            onFileAdd={setAuthFiles}
            uploadedFiles={authFiles}
            setUploadedFiles={setAuthFiles}
            withProgress={false}
          />
        </Col>
      </Row>
      <UploadButton
        onClick={handleUpload}
        selectedFiles={selectedFiles}
        totalFiles={3}
        disabled={selectedFiles < 3}
        completed={completed}
        loading={loading}
      />
    </Card>
  );
};

export default FamiliesImport;
