import React from 'react';
import CenteredModal from 'shared/components/Modals/CenteredModal';

interface IProps {
  show: boolean;
  primaryCallback?: () => void;
  secondaryCallback?: () => void;
}

const NoCCSSubsidyModal: React.FC<IProps> = ({ show, primaryCallback, secondaryCallback }) => {
  return (
    <CenteredModal
      title="No CCS Subsidy class?"
      show={show}
      primaryCallback={primaryCallback}
      secondaryCallback={secondaryCallback}
      primaryButtonProps={{ loading: false }}
      primaryChoice="Confirm"
    >
      Selecting this option means that no subsidy will be claimed for sessions in this class.
    </CenteredModal>
  );
};

export default NoCCSSubsidyModal;
