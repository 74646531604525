import React, { useState, useCallback, useContext } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import errorMessage from 'shared/constants/errorMessages';
import { useDeleteTimeEntry } from '../TimeSheets/graphql/mutations';
import TextInput from '../../../../shared/components/TextInput';
import moment from 'moment/moment';
import { TimezoneContext } from '../../../../shared/contexts/timezoneContext';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  staff: IStaff | undefined;
  timeEntry: ITimeEntry | null;
}

const DeleteTimeEntryModal: React.FC<IProps> = ({ isOpen, onClose, staff, timeEntry }) => {
  const [showSecondaryWarning, setShowSecondaryWarning] = useState<boolean>(false);
  const [reasonForDeleting, updateReasonForDeleting] = useState('');
  const [deleteTimeEntryMutation, { loading }] = useDeleteTimeEntry();
  const centerTimezoneContext = useContext(TimezoneContext);

  const [deleteTimeEntryFn, { loading: deleteTimeEntryLoading }] = useDeleteTimeEntry({
    onCompleted: (result) => {
      showToast('Time Entry deleted.', 'success');
      onClose();
    },
    onError: (err) => {
      showToast(errorMessage.generic, 'error');
      onClose();
    },
  });

  const handleClose = useCallback(() => {
    setShowSecondaryWarning(false);
    updateReasonForDeleting('');
    onClose();
  }, [onClose]);
  //console.log(timeEntry.id)

  const deleteTimeEntry = useCallback(async () => {
    if (timeEntry) {
      await deleteTimeEntryFn({
        variables: {
          input: {
            id: timeEntry?.id ?? null,
            personId: timeEntry?.personId,
            centerId: timeEntry?.centerId,
            positionId: timeEntry?.positionId,
            note: reasonForDeleting,
          },
        },
      });
    }
  }, [deleteTimeEntryMutation, handleClose, reasonForDeleting]);

  return (
    <ConfirmationModal
      title="Delete Time Entry?"
      show={isOpen}
      onHide={handleClose}
      hideOnCallback={false}
      primaryChoice={'Deactivate'}
      primaryCallback={() => deleteTimeEntry()}
      primaryButtonProps={{
        variant: 'danger',
        disabled: reasonForDeleting === '',
        loading,
      }}
    >
      <p>
        Are you sure you want to delete the time entry for {staff?.firstname} {staff?.lastname} on{' '}
        {moment(timeEntry?.trackedTimeIn).tz(centerTimezoneContext.timezone).format('MM/DD/YYYY')} for{' '}
        {moment(timeEntry?.trackedTimeIn).tz(centerTimezoneContext.timezone).format('h:mm A')}
        {timeEntry?.trackedTimeOut
          ? ` - ${moment(timeEntry?.trackedTimeOut).tz(centerTimezoneContext.timezone).format('h:mm A')}.`
          : '.'}
      </p>
      <div>
        <TextInput
          label={`Reason`}
          value={reasonForDeleting}
          onChange={(value: string) => updateReasonForDeleting(value)}
          required={true}
          isInvalid={reasonForDeleting === ''}
        />
      </div>
    </ConfirmationModal>
  );
};

export default DeleteTimeEntryModal;
