import React, { useState } from 'react';
//components
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import MenuTab from './components/MenuTab';
import { RouteComponentProps } from 'react-router-dom';

export type EndOfCareTimelogsTab = 'active' | 'history';

interface IRouteParams {
  activeTab: EndOfCareTimelogsTab;
}
interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

export const EndOfCareTab: React.FC<IProps> = ({ ...props }) => {
  const { activeTab } = props.match.params;
  const [activeTabKey, setActiveTabKey] = useState<EndOfCareTimelogsTab>(activeTab ?? 'active');

  return (
    <ProfilePageWrapper title={'End of Care'} loading={false} showAvatar={false}>
      <MenuTab activeTab={activeTabKey} setActiveTab={setActiveTabKey} />
    </ProfilePageWrapper>
  );
};
