import React, { useCallback, useState } from 'react';
import ContactsDropdownForm from 'shared/components/ContactDisplayComponents';
import { useUpdateBusinessAccountOwners } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';

interface IProps {
  canPerformEdit?: boolean;
  business: IEntity;
}

const BusinessAccountOwner: React.FC<IProps> = ({ business, canPerformEdit, ...props }) => {
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [saveAccountOwners, { loading, error }] = useUpdateBusinessAccountOwners();

  const save = useCallback(
    (primaryContactPersonId: string, secondaryContactPersonId?: string) => {
      saveAccountOwners({
        variables: {
          input: {
            id: business.id,
            primaryContactPersonId,
            secondaryContactPersonId,
          },
        },
      })
        .then(() => {
          showToast('Business information updated successfully.', 'success');
          setFormIsDirty(false);
        })
        .catch(() => {
          showToast('There was an error updating your business information. Please try again later.', 'error');
        });
    },
    [business.id, saveAccountOwners]
  );

  const saveContacts = useCallback(
    (primaryContactPersonId: string, secondaryContactPersonId?: string) => {
      save(primaryContactPersonId, secondaryContactPersonId);
    },
    [save]
  );

  return (
    <>
      <p>
        Please assign your preferred contacts below. The employees selected will be designated for communication and
        notifications related to your business.
      </p>
      <ContactsDropdownForm
        businessId={business.id}
        item={business}
        canPerformEdit={true}
        loading={loading}
        error={error}
        updateItem={saveContacts}
        roleNamesToExclude={['Administrator']}
        isDirty={formIsDirty}
        toggleFormIsDirty={setFormIsDirty}
      />
    </>
  );
};

export default BusinessAccountOwner;
