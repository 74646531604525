import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import Checkbox from 'shared/components/Checkbox';
import { isBlank, isValidPhoneNumber } from 'shared/util/string';
import { isEmailSyntaxValid } from 'shared/util/email';
import { useCreateContactForChild } from 'gql/contact/mutations';
import PhoneNumberAndTypeInput from 'shared/components/PhoneNumberAndTypeInput';
import { showToast } from 'shared/components/Toast';
import { addContactForChild } from 'pages/Families/subroutes/Children/duck/actions';
import { useGetChildContactPermissions } from 'gql/contact/queries';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import AccountPermissionsInputs from './AccountPermissionsInputs';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface INewChildContact {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  phoneNumberType: string;
  relationship: string;
}

const newChildContact: INewChildContact = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  phoneNumberType: '',
  email: '',
  relationship: '',
};

interface IProps {
  isOpen: boolean;
  child: IChild;
  onClose: () => void;
  accountId: string;
}

const AddContactModal: React.FC<IProps> = ({ isOpen, child, onClose, accountId, ...props }) => {
  const dispatch = useDispatch();
  const { k2SendAppInvite } = useFlags();
  const [sendInvite, setSendInvite] = useState(k2SendAppInvite ?? false);
  const [contact, updateContact] = useState(newChildContact);
  const [accountPermissions, setAccountPermissions] = useState<IAccountPermissionsInput[]>([
    { accountId, permissions: [] },
  ]);

  const { data: getChildContactPermissionsData } = useGetChildContactPermissions();
  const permissionsOptions = getChildContactPermissionsData?.getChildContactRelationshipPermissions ?? [];
  const permissionsForAccount = accountPermissions.find((ap) => ap.accountId === accountId);
  const otherAccounts = child.accounts.filter((a) => a.id !== accountId);

  const [createContactForChildFn, { loading: createContactForChildLoading }] = useCreateContactForChild({
    onCompleted: (result) => {
      dispatch(addContactForChild(result.createContactForChild));
      showToast('Contact created successfully.', 'success');
      handleClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleSubmit = useCallback(() => {
    createContactForChildFn({
      variables: {
        input: {
          childId: child.id,
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email ?? null,
          phoneNumber: {
            number: contact.phoneNumber,
            type: contact.phoneNumberType,
          },
          relationship: contact.relationship,
          accounts: accountPermissions,
          sendContactInvites: sendInvite,
        },
      },
    });
  }, [createContactForChildFn, child, contact, accountPermissions]);

  const handleClose = useCallback(() => {
    updateContact(newChildContact);
    onClose();
  }, [onClose]);

  useEffect(() => {
    setAccountPermissions([{ accountId, permissions: [] }]);
  }, [accountId]);

  const validateForm = useCallback((data: INewChildContact, permissions: IAccountPermissionsInput[]) => {
    return (
      !isBlank(data.firstName) &&
      !isBlank(data.lastName) &&
      !isBlank(data.relationship) &&
      (!isBlank(data.email) ? isEmailSyntaxValid(data.email) : true) &&
      data.phoneNumber &&
      isValidPhoneNumber(data.phoneNumber) &&
      !isBlank(data.phoneNumberType) &&
      Boolean(permissions.length)
    );
  }, []);

  const allAccountPermissions: IAccountPermissionsInput[] = child.accounts.map((a) => ({
    accountId: a.id,
    permissions: permissionsOptions.map((o) => o.value),
  }));
  const allPermissionsSelected = child.accounts.every(
    (account) =>
      accountPermissions.map((a) => a.accountId).includes(account.id) &&
      accountPermissions
        .map((ap) => ap.permissions)
        .every((permissionArray) => permissionsOptions.every((p) => permissionArray.includes(p.value)))
  );

  return (
    <SideModalDrawer
      title="Add Contact"
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={handleSubmit}
      primaryButtonProps={{
        disabled: !validateForm(contact, accountPermissions) || createContactForChildLoading,
        loading: createContactForChildLoading,
      }}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      footerHelperText={
        !validateForm(contact, accountPermissions)
          ? 'Complete all required fields and select at least one account to save.'
          : ''
      }
    >
      <Form>
        <Row>
          <Column>
            <Select
              label="Account"
              options={child.accounts?.map((a) => ({ value: a.id, label: a.name }))}
              onChange={() => {}}
              value={accountId}
              disabled={true}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <TextInput
              required
              label="First Name"
              value={contact.firstName}
              onChange={(value) => updateContact((prev) => ({ ...prev, firstName: value }))}
            />
          </Column>
          <Column>
            <TextInput
              required
              label="Last Name"
              value={contact.lastName}
              onChange={(value) => updateContact((prev) => ({ ...prev, lastName: value }))}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <TextInput
              label="Email"
              value={contact.email}
              onChange={(value) => updateContact((prev) => ({ ...prev, email: value }))}
              type="email"
            />
          </Column>
        </Row>
        {k2SendAppInvite && (
          <Row className="mb-4">
            <Column>
              <Checkbox
                label="Send confirmation email"
                value={sendInvite}
                onChange={() => setSendInvite(!sendInvite)}
              />
            </Column>
          </Row>
        )}
        <Row>
          <Column>
            <PhoneNumberAndTypeInput
              required
              extraDiv={false}
              label="Phone Number"
              phoneNumber={{
                number: contact.phoneNumber,
                type: contact.phoneNumberType as PhoneNumberType,
              }}
              updatePhoneNumber={(phone) =>
                updateContact((prev) => ({
                  ...prev,
                  phoneNumber: phone.number,
                  phoneNumberType: phone.type as string,
                }))
              }
            />
          </Column>
        </Row>
        <Row>
          <Column xs={6}>
            <Select
              required
              label="Relationship"
              options={Object.entries(childContactRelationship).map((r) => ({ value: r[0], label: r[1] }))}
              onChange={(option) => updateContact((prev) => ({ ...prev, relationship: option.value }))}
            />
          </Column>
        </Row>
        <div className="mb-6">
          <h5>Account Permissions</h5>
          {permissionsOptions.map((permission, idx) => (
            <Checkbox
              key={`permission-${permission.value}-${idx}`}
              label={permission.label}
              value={permissionsForAccount?.permissions.includes(permission.value)}
              onChange={(checked) =>
                setAccountPermissions(
                  accountPermissions.map((ap) =>
                    ap.accountId === accountId
                      ? {
                          ...ap,
                          permissions: checked
                            ? [...ap.permissions, permission.value]
                            : ap.permissions.filter((p) => p != permission.value),
                        }
                      : ap
                  )
                )
              }
            />
          ))}
        </div>
        {otherAccounts.length > 0 && (
          <div className="mb-4">
            <h5>Other Permissions</h5>
            <p>
              Select any additional accounts you would like to add this contact to - for this child. Then select what
              permissions you would like to assign them.
            </p>
            <div className="d-flex align-items-center mb-2">
              <Checkbox
                value={Boolean(allPermissionsSelected)}
                onChange={(checked) =>
                  setAccountPermissions(checked ? allAccountPermissions : [{ accountId, permissions: [] }])
                }
              />
              All
            </div>
            <AccountPermissionsInputs
              accountOptions={otherAccounts}
              accountPermissions={accountPermissions}
              setAccountPermissions={setAccountPermissions}
              canAddAndRemoveAccounts={true}
            />
          </div>
        )}
      </Form>
    </SideModalDrawer>
  );
};

export default AddContactModal;
