import React from 'react';
import { useGLCodeContext } from '../../Context/GLCodeContext';
import Select from 'shared/components/Select';
import Button from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';

const LinkGLCodeWidget = () => {
  const { t } = useTranslation(['billing', 'translation']);
  const {
    GLCodeAssignmentTableState: { selectedRows },
    updateGLCodeAssignmentsLoading,
    handleUpdateGLCodeAssignments,
    selectedGLCodeId,
    setSelectedGLCodeId,
    glCodeListDataLoading,
    glCodeListData,
    activeSubTab,
  } = useGLCodeContext();

  return (
    <div className="d-flex align-items-center link-gl-code-widget">
      {t('billing:gl-codes.items-selected', { count: selectedRows.length })}
      <Select
        // See https://github.com/JedWatson/react-select/issues/1879#issuecomment-316871520
        // to workaround react-select bug
        key={activeSubTab}
        placeholder={t('billing:gl-codes.select-gl-code-to-apply')}
        ion
        options={glCodeListData ?? []}
        getOptionLabel={(option: IGLCode) => `${option.glCode} - ${option.name}`}
        getOptionValue={(option: IGLCode) => option.id!}
        onChange={(v: IGLCode) => setSelectedGLCodeId(v.id!)}
        className="mx-3 my-0 w-260px"
        isLoading={glCodeListDataLoading}
      />
      <Button
        id="primary-gl-code-apply-button"
        variant="primary"
        disabled={selectedRows.length === 0 || !selectedGLCodeId}
        loading={updateGLCodeAssignmentsLoading}
        onClick={handleUpdateGLCodeAssignments}
      >
        {t('billing:gl-codes.apply-gl-code')}
      </Button>
    </div>
  );
};

export default LinkGLCodeWidget;
