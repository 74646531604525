import { faPlus, faTimes, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { IconButton, IconButtonCircle } from 'shared/components/Buttons';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { Row } from 'shared/components/Layout';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import { useGetAllergens } from 'gql/allergen/queries';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import { useUpdateAllergens } from 'gql/allergen/mutations';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  allergyType: IHealthTerm;
}

const EditAllergensModal: React.FC<IProps> = ({ isOpen, onClose, allergyType }) => {
  const [updateAllergens, { loading: saveLoading }] = useUpdateAllergens();
  const { data, loading } = useGetAllergens();
  const existingAllergens = data?.getAllergens.filter((a) => a.typeId === allergyType.id && !a.archivedAt) ?? [];

  const [newAllergenNames, updateNewAllergenNames] = useState(['']);
  const [allergenIdsToArchive, updateAllergenIdsToArchive] = useState<string[]>([]);

  const save = useCallback(() => {
    updateAllergens({
      variables: {
        input: {
          typeId: allergyType.id,
          toArchive: allergenIdsToArchive,
          toCreate: newAllergenNames.filter((n) => n),
        },
      },
    }).then((result) => {
      showToast(`Updated allergens successfully.`, 'success');
      onClose();
    });
  }, [newAllergenNames, allergenIdsToArchive, updateAllergens]);

  return (
    <ConfirmationModal
      title={`Edit ${allergyType.name} Allergens`}
      show={isOpen}
      onHide={onClose}
      primaryCallback={save}
      hideOnCallback={false}
      primaryButtonProps={{ loading: saveLoading }}
    >
      <p>Here are the current allergens for this allergen type.</p>
      {loading ? (
        <LoadingLines number={2} />
      ) : (
        <Row className="mb-4" noGutters>
          {existingAllergens.map((a) => {
            const isArchived = allergenIdsToArchive.includes(a.id);
            return (
              <Row
                noGutters
                onClick={() =>
                  updateAllergenIdsToArchive((prev) => (isArchived ? prev.filter((i) => i !== a.id) : [...prev, a.id]))
                }
                className={`tag mr-2 mb-2 cursor-pointer ${isArchived ? 'bg-danger text-white' : ''}`}
              >
                {a.name}
                {!isArchived && <FontAwesomeIcon className="ml-2" icon={faTimes} />}
              </Row>
            );
          })}
        </Row>
      )}
      <p>Don’t see what you need above? Add new allergens here.</p>
      <Form.Label>Allergen name</Form.Label>
      {newAllergenNames.map((name, index) => (
        <div key={index} className="d-flex mb-2">
          <TextInput
            className="mb-0"
            onChange={(name) =>
              updateNewAllergenNames((prev) => newAllergenNames.map((n, i) => (i === index ? name : n)))
            }
            value={name}
          />
          {index === newAllergenNames.length - 1 ? (
            <IconButtonCircle
              size="sm"
              className="ml-4 my-1"
              backgroundColor="secondary"
              color="white"
              icon={faPlus}
              tooltipText="Add New Allergen"
              onClick={() => updateNewAllergenNames((prev) => [...newAllergenNames, ''])}
            />
          ) : (
            <IconButton
              className="ml-4 sm-icon-width my-1"
              icon={faTrashAlt}
              tooltipText="Remove Allergen"
              onClick={() => updateNewAllergenNames((prev) => newAllergenNames.filter((n, i) => i !== index))}
            />
          )}
        </div>
      ))}
    </ConfirmationModal>
  );
};

export default EditAllergensModal;
