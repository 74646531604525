import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/pro-light-svg-icons';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { TinyDeleteButton } from 'shared/components/Buttons';

// TODO: Make the display of this much more flexible when we do the doc manager
interface IProps {
  file: IFile;
  onDeleteClick: (id: string) => void;
  updateContentsCb: (id: string | ArrayBuffer | null) => void;
}

const SelectedFile: React.FC<IProps> = ({ file, updateContentsCb, onDeleteClick, ...props }) => {
  useEffect(() => {
    const reader: FileReader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = (event: ProgressEvent<FileReader>) => {
      event && event.target && updateContentsCb(event.target.result);
    };
  }, [file, updateContentsCb]);

  return (
    <div className="d-flex flex-row w-100 align-items-center justify-content-between file-display px-4 py-3">
      <FontAwesomeIcon size="1x" icon={faFileImport} />
      <div className="d-flex w-100 flex-column ml-4 my-1 justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <h6>{file.name}</h6>
          <TinyDeleteButton onClick={() => onDeleteClick(file.name)} />
        </div>
      </div>
    </div>
  );
};

SelectedFile.defaultProps = {
  onDeleteClick: () => {},
  updateContentsCb: () => {},
};

export default SelectedFile;
