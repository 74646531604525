import React from 'react';
import moment from 'moment';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faUser, faTimes } from '@fortawesome/pro-light-svg-icons';
import ControlContainer from '../ControlContainer';
import Tooltip from 'shared/components/Tooltip';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import DataControlAccordion from './DataControlAccordion';
import Checkbox from 'shared/components/Checkbox';
import CustomLocationInput from './CustomLocation';
import colors from '_colors.module.scss';
import { IconButtonCircle } from 'shared/components/Buttons';
import { getFullName } from 'shared/util/string';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IProps {
  loading: boolean;
  dataViewType: ScheduleDataListType;
  activeClassesInTimeframe: IClass[];
  activeStaff: IStaff[];
  activeLocations: ILocation[];
  selectedClassIds: string[];
  selectedStaffIds: string[];
  selectedLocationIds: string[];
  locationButtonLoading: boolean;
  onClassSelect: (classes: IClass[], selected: boolean) => void;
  onStaffSelect: (staff: IStaff[], selected: boolean) => void;
  onLocationSelect: (locations: ILocation[], selected: boolean) => void;
  onAddCustomLocation: (name: string) => void;
  onRemoveLocation: (id: string) => void;
  onChangeDataView: (view: ScheduleDataListType) => void;
}

const DataControls: React.FC<IProps> = ({
  loading,
  dataViewType,
  activeClassesInTimeframe,
  activeStaff,
  activeLocations,
  selectedClassIds,
  selectedStaffIds,
  selectedLocationIds,
  locationButtonLoading,
  onClassSelect,
  onStaffSelect,
  onLocationSelect,
  onAddCustomLocation,
  onRemoveLocation,
  onChangeDataView,
  ...props
}) => {
  return (
    <ControlContainer>
      <div className="kt-schedules-data-control">
        <div className="kt-schedules-data-control-list-type mb-6">
          <span>View By:</span>
          <ButtonGroup className="ml-4" aria-label="Schedule table data view toggle">
            <Tooltip direction="bottom" text="View by class">
              <Button
                onClick={() => onChangeDataView('CLASS')}
                variant={dataViewType === 'CLASS' ? 'secondary' : 'light'}
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </Button>
            </Tooltip>
            <Tooltip direction="bottom" text="View by staff">
              <Button
                onClick={() => onChangeDataView('EMPLOYEE')}
                variant={dataViewType === 'EMPLOYEE' ? 'secondary' : 'light'}
              >
                <FontAwesomeIcon icon={faUser} />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </div>
        <DataControlAccordion className="mb-4" title="Classes">
          {loading ? (
            <LoadingLines />
          ) : (
            <ul>
              <li className="d-flex flex-row align-items-center mb-2" title="Select all classes">
                <Checkbox
                  value={selectedClassIds.length === activeClassesInTimeframe.length}
                  onChange={(checked: boolean) => onClassSelect(activeClassesInTimeframe, checked)}
                />
                <span className="d-block ml-1">Check All</span>
              </li>
              {!activeClassesInTimeframe.length && <small>No active classes found.</small>}
              {activeClassesInTimeframe.map((_class: IClass, idx: number) => (
                <li
                  key={`data-control-class-${_class.id}-${idx}`}
                  className="d-flex flex-row align-items-center mb-2"
                  title={`${_class.name} (${moment(_class.startsAt).format('MMM D')} - ${
                    _class.endsAt ? moment(_class.endsAt).format('MMM D') : 'Ongoing'
                  })`}
                >
                  <Checkbox
                    value={selectedClassIds.includes(_class.id)}
                    onChange={(checked: boolean) => onClassSelect([_class], checked)}
                  />
                  <div style={{ backgroundColor: _class.colorCode }} />
                  <span className="d-block ml-1">{_class.name}</span>
                </li>
              ))}
            </ul>
          )}
        </DataControlAccordion>
        <DataControlAccordion className="mb-4 text-lg-center" title="Locations">
          {loading ? (
            <LoadingLines number={2} />
          ) : (
            <ul>
              <li className="d-flex flex-row align-items-center mb-2" title="Select all locations">
                <Checkbox
                  value={selectedLocationIds.length === activeLocations.length}
                  onChange={(checked: boolean) => onLocationSelect(activeLocations, checked)}
                />
                <span className="d-block ml-1">Check All</span>
              </li>
              {!activeLocations.length && <small>No locations found.</small>}
              {activeLocations.map((location: ILocation, idx: number) => (
                <li
                  key={`data-control-class-${location.id}-${idx}`}
                  className="d-flex flex-row align-items-center mb-2"
                  title={`${location.name} ${location.archivedAt ? '(Archived)' : ''}`}
                >
                  <Checkbox
                    value={selectedLocationIds.includes(location.id)}
                    onChange={(checked: boolean) => onLocationSelect([location], checked)}
                  />
                  <div style={{ backgroundColor: colors.gray }} />
                  <span className="d-block ml-1">{location.name}</span>
                  {!location.archivedAt && (
                    <HasRoleAreaLevel
                      action={{ area: AreaType.Schedule, permission: PermissionType.Base, level: RoleLevelType.Delete }}
                    >
                      <Tooltip text="Remove location" direction="bottom">
                        {/* NOTE: reveist this when we get to creating shifts. this should not be shown if there are shifts currently scheduled in this location */}
                        <IconButtonCircle
                          className="rounded-circle circle-xxs bg-pale-grey ml-auto"
                          size="xs"
                          icon={faTimes}
                          backgroundColor="transparent"
                          onClick={() => onRemoveLocation(location.id)}
                        />
                      </Tooltip>
                    </HasRoleAreaLevel>
                  )}
                </li>
              ))}
            </ul>
          )}
          <HasRoleAreaLevel
            action={{ area: AreaType.Schedule, permission: PermissionType.Base, level: RoleLevelType.Create }}
          >
            <CustomLocationInput loading={locationButtonLoading} addLocation={onAddCustomLocation} />
          </HasRoleAreaLevel>
        </DataControlAccordion>
        <DataControlAccordion title="Employees">
          {loading ? (
            <LoadingLines number={8} />
          ) : (
            <ul>
              <li className="d-flex flex-row align-items-center mb-2" title="Select all staff">
                <Checkbox
                  value={selectedStaffIds.length === activeStaff.length}
                  onChange={(checked: boolean) => onStaffSelect(activeStaff, checked)}
                />
                <span className="d-block ml-1">Check All</span>
              </li>
              {!activeStaff.length && <small>No active staff found.</small>}
              {activeStaff
                .filter((s) => s.role.scheduleVisibility)
                .map((staff: IStaff, idx: number) => (
                  <li
                    key={`data-control-staff-${staff.id}-${idx}`}
                    className="d-flex flex-row align-items-center mb-2"
                    title={getFullName(staff)}
                  >
                    <Checkbox
                      value={selectedStaffIds.includes(staff.id)}
                      onChange={(checked: boolean) => onStaffSelect([staff], checked)}
                    />
                    <span className="d-block ml-1">{getFullName(staff)}</span>
                  </li>
                ))}
            </ul>
          )}
        </DataControlAccordion>
      </div>
    </ControlContainer>
  );
};

export default DataControls;
