const educatorFields = `
  educatorId
  firstName
  lastName
  careDate
  hours
`;
export const isCaseClaimFields = `
  id
  isCaseId
  isClaimId
  approvalId
  centerName
  centerId
  weekEnding
  daysOfCare {
    dayOfCare
    hours
  }
  paymentType
  paymentStatus
  hoursClaimed
  hoursPaid
  claimStatus
  serviceProvided
  educators {
    ${educatorFields}
  }

  children {
    childId
    child {
      id
      firstname
      fullName
      lastname
      avatar {
        url
      }
    }
  }

`;

export const iSCaseClaimFieldsPagedResult = `
  totalRecords
  pageNumber
  data {
    ${isCaseClaimFields}
  }
`;
