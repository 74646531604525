import * as types from './types';
import { unionBy } from 'lodash';

interface IBusinessesStateShape {
  all: IBusiness[];
  byId: Record<string, IBusiness>;
}

const initialState: IBusinessesStateShape = {
  all: [],
  byId: {},
};

export const businessesReducers = (
  state: IBusinessesStateShape = initialState,
  action: types.BusinessesActionTypes
): IBusinessesStateShape => {
  const byId: Record<string, IBusiness> = { ...state.byId };

  switch (action.type) {
    case types.GET_BUSINESSES_SUCCESS:
      // use unionBy instead of just setting so data that is already fetched isn't rewritten with an object that may have less information
      const arr = unionBy(state.all, action.businesses, (b) => b.id);

      return {
        ...state,
        all: arr,
        byId: arr.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
      };
    case types.CREATE_BUSINESS:
      byId[action.business.id] = action.business;

      return {
        ...state,
        all: Object.values(byId),
        byId,
      };
    case types.GET_BUSINESS_SUCCESS:
    case types.UPDATE_BUSINESS:
      if (action.business) {
        // merge with an existing data
        byId[action.business.id] = {
          ...byId[action.business.id],
          ...action.business,
        };
      }

      return {
        ...state,
        all: Object.values(byId),
        byId,
      };
    case types.CREATE_PAYMENT_METHOD_FOR_BUSINESS:
      byId[action.businessId] = {
        ...byId[action.businessId],
        paymentMethods: [...(byId[action.businessId].paymentMethods ?? []), action.paymentMethod],
      };

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case types.UPDATE_PAYMENT_METHOD_FOR_BUSINESS:
      byId[action.businessId] = {
        ...byId[action.businessId],
        paymentMethods: (byId[action.businessId].paymentMethods ?? []).map((pm) =>
          pm.id === action.paymentMethod.id ? action.paymentMethod : pm
        ),
      };

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case types.UPDATE_DEFAULT_PAYMENT_METHOD_FOR_BUSINESS:
      byId[action.businessId] = {
        ...byId[action.businessId],
        paymentMethods: (byId[action.businessId].paymentMethods ?? []).map((pm) => ({
          ...pm,
          isPrimary: pm.id === action.paymentMethodId,
        })),
      };

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case types.DELETE_PAYMENT_METHOD_FOR_BUSINESS:
      byId[action.businessId] = {
        ...byId[action.businessId],
        paymentMethods: (byId[action.businessId].paymentMethods ?? []).filter((pm) => pm.id !== action.paymentMethodId),
      };

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    case types.DESTROY_BUSINESS:
      delete byId[action.id];

      return {
        ...state,
        byId,
        all: Object.values(byId),
      };
    default:
      return state;
  }
};
