import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Button from 'shared/components/Buttons';
import TextInput from 'shared/components/TextInput';
import 'pages/MyProfile/myProfile.scss';
import { useUpdateStaffProfile } from 'gql/staff/mutations';
import { showToast } from 'shared/components/Toast';
import { isEmailValid, isEmailSyntaxValid } from 'shared/util/email';
import errorMessage from 'shared/constants/errorMessages';
import useLazyCheckIsEmailTaken from 'shared/hooks/useLazyCheckIsEmailTaken';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';

interface IProps {
  currentEmail: string;
  isOpen: boolean;
  onClose: () => void;
  personId: string;
  isCognitoUserCreated: boolean;
  refetchStaff: () => void;
}

const ChangeEmailModal: React.FC<IProps> = ({
  currentEmail,
  isOpen,
  onClose,
  personId,
  isCognitoUserCreated,
  refetchStaff,
}) => {
  const [newEmail, updateNewEmail] = useState('');
  const [saveEmail, { loading }] = useUpdateStaffProfile();
  const [checkIsEmailTaken, { data }] = useLazyCheckIsEmailTaken();
  const handleClose = useCallback(() => {
    onClose();
    updateNewEmail('');
    refetchStaff();
  }, [onClose]);

  const isInternal = useSelector((state: RootState) => state.user?.isInternal);

  const debouncedCheckIsEmailTaken = useCallback(
    debounce((value: string) => {
      checkIsEmailTaken({
        variables: { email: value },
      });
    }, 250),
    []
  );

  const handleEmailChange = useCallback(
    (value) => {
      updateNewEmail(value);
      debouncedCheckIsEmailTaken(value);
    },
    [debouncedCheckIsEmailTaken]
  );

  const save = useCallback(() => {
    saveEmail({
      variables: {
        input: {
          id: personId,
          email: newEmail,
        },
      },
    })
      .then((data) => {
        if (data.data?.updateStaffProfile?.email) {
          if (!isCognitoUserCreated) {
            showToast('The email has been successfully updated', 'success');
          } else if (isInternal) {
            showToast('The email has been successfully updated', 'success');
          } else {
            showToast('A request to change the email was sent.', 'success');
          }
          handleClose();
        } else {
          showToast('There was an error changing your Email. Please try again later.', 'error');
        }
      })
      .catch(() => {
        showToast('There was an error changing your Email. Please try again later.', 'error');
      });
  }, [saveEmail, personId, newEmail, isCognitoUserCreated, handleClose]);

  return (
    <Modal
      centered
      backdrop="static"
      show={isOpen}
      onHide={handleClose}
      dialogClassName="kt-my-profile-change-email-modal"
    >
      <Modal.Header closeButton className="border-bottom-0 pb-0">
        <Modal.Title as="h5">Change Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="d-block mt-2 mb-4">Please enter the new email you'd like to use.</span>
        <Row>
          <Column className="text-right">
            <TextInput label="Current Email" disabled={true} value={currentEmail} type="email" />
            <TextInput
              label="New Email"
              value={newEmail}
              onChange={handleEmailChange}
              isInvalid={!isEmailValid(newEmail) || data?.checkIsEmailTaken}
              errorText={
                data?.checkIsEmailTaken
                  ? errorMessage.changeEmailInUse
                  : !isEmailSyntaxValid(newEmail)
                  ? errorMessage.invalidEmailSyntax
                  : errorMessage.invalidEmailDomain
              }
              type="email"
            />
            <Button
              onClick={save}
              variant="primary"
              className="w-100"
              loading={loading}
              disabled={!isEmailValid(newEmail) || data?.checkIsEmailTaken}
            >
              Save Changes
            </Button>
          </Column>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeEmailModal;
