import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col } from 'shared/components/Layout';
import Alert from 'shared/components/Alert';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import Select from 'shared/components/Select';
import ElasticConstants from 'shared/constants/elastic';
import { useSearchStaff } from 'pages/Employees/subroutes/Profiles/graphql/queries';
import { active, pending } from 'shared/constants/StaffStatusSearchExpressions';
import { ApolloError } from '@apollo/client';
import { LoadingLines } from '../LoadingSkeletons';
import { getFullName } from 'shared/util/string';

interface IProps {
  businessId: string;
  centerId?: string;
  canPerformEdit?: boolean;
  error?: ApolloError;
  loading?: boolean;
  item: ICenter | IEntity;
  updateItem: (primaryContactPersonId: string, secondaryContactPersonId: string | undefined) => void;
  roleNamesToExclude?: string[];
  isDirty: boolean;
  toggleFormIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactsDrowpdownForm: React.FC<IProps> = ({
  businessId,
  centerId,
  canPerformEdit,
  error,
  loading,
  updateItem,
  item,
  roleNamesToExclude = [],
  isDirty = false,
  toggleFormIsDirty = () => {},
}) => {
  const elasticScopeValues = [businessId];
  centerId && elasticScopeValues.push(centerId);
  const [primaryContact, setPrimaryContact] = useState(item.primaryContact);
  const [secondaryContact, setSecondaryContact] = useState(item.secondaryContact);
  const { loading: elasticsearchLoading, data } = useSearchStaff({
    filter: {
      [ElasticConstants.SEARCH_EXPRESSIONS.ALL]: [
        {
          [ElasticConstants.SEARCH_EXPRESSIONS.ANY]: [active, pending],
        },
        {
          [ElasticConstants.SEARCH_EXPRESSIONS.ANY]: [
            {
              [ElasticConstants.SEARCH_EXPRESSIONS.TERM]: {
                field: 'roleship.scopeIds.keyword',
                predicate: ElasticConstants.PREDICATES.ONE_OF,
                value: elasticScopeValues,
              },
            },
          ],
        },
      ],
    },
    sort: [
      {
        field: 'lastname.keyword',
        direction: ElasticConstants.DIRECTIONS.ASCENDING,
      },
    ],
    size: 1000,
    from: 0,
  });

  useEffect(() => {
    // if the item prop changes update the state values if the component has already rendered
    if (item) {
      setPrimaryContact(item.primaryContact);
      setSecondaryContact(item.secondaryContact);
    }
  }, [item]);

  const staffData = data?.searchStaff.data ?? [];
  const handlePrimaryContactUpdate = useCallback(
    (contact) => {
      if (!isDirty) {
        toggleFormIsDirty(true);
      }

      setPrimaryContact(contact);
    },
    [setPrimaryContact, isDirty, toggleFormIsDirty]
  );

  const handleSecondaryContactUpdate = useCallback(
    (contact) => {
      if (!isDirty) {
        toggleFormIsDirty(true);
      }

      setSecondaryContact(contact);
    },
    [setSecondaryContact, isDirty, toggleFormIsDirty]
  );

  const resetForm = useCallback(() => {
    setPrimaryContact(item.primaryContact);
    setSecondaryContact(item.secondaryContact);
  }, [item, setSecondaryContact, setPrimaryContact]);

  return (
    <>
      {error && <Alert variant="danger">{error.message || 'Something went wrong.'}</Alert>}
      <FormWrapper2
        formIsDirty={isDirty}
        toggleDirty={toggleFormIsDirty}
        saveDisabled={!primaryContact}
        onSave={() => updateItem(primaryContact.id, secondaryContact?.id)}
        onCancel={resetForm}
        loading={loading || elasticsearchLoading}
      >
        {elasticsearchLoading ? (
          <div className="mx-4">
            <LoadingLines number={1} />
          </div>
        ) : (
          <Row align="start">
            <Col sm={5}>
              <Select
                disabled={!canPerformEdit}
                required={true}
                name="primaryContact"
                label="Primary Contact"
                options={staffData.filter((staff) => !roleNamesToExclude.includes(staff.roleship.name))}
                value={primaryContact}
                getOptionLabel={(staff: IStaff) => getFullName(staff)}
                getOptionValue={(staff) => staff.id}
                onChange={handlePrimaryContactUpdate}
              />
            </Col>
            <Col sm={5}>
              <Select
                disabled={!canPerformEdit}
                name="secondaryContact"
                label="Secondary Contact"
                options={[
                  { id: '' },
                  ...staffData.filter((staff) => !roleNamesToExclude.includes(staff.roleship.name)),
                ]}
                value={secondaryContact ?? { id: '' }}
                getOptionLabel={(staff: IStaff) => {
                  if (!staff.id) {
                    return '-- None --';
                  }

                  return getFullName(staff);
                }}
                getOptionValue={(staff) => staff.id}
                onChange={handleSecondaryContactUpdate}
              />
            </Col>
          </Row>
        )}
      </FormWrapper2>
    </>
  );
};

export default ContactsDrowpdownForm;
