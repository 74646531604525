import React from 'react';
import DepositInput, { IDepositInputInvalid } from './ApplicationDeposit';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { useTranslation } from 'react-i18next';

interface IEditDepositModalProps {
  isOpen: boolean;
  onHide: () => void;
  onSave: () => void;
  isSubmitting: boolean;
  isDepositCollected: boolean;
  depositAmount: number | null | undefined;
  depositAppliesDate?: string;
  onChange: (amount: number | null | undefined, isCollected: boolean, appliesDate?: string) => void;
  inValid?: IDepositInputInvalid;
}

const EditDepositModal: React.FC<IEditDepositModalProps> = ({
  isOpen,
  onHide,
  onSave,
  isSubmitting,
  isDepositCollected,
  onChange,
  depositAmount,
  depositAppliesDate,
  inValid,
}) => {
  const { t } = useTranslation();
  return (
    <CenteredModal
      title={t('enrollment.lead-management.deposit.edit-deposit-modal-title')}
      scrollable={false}
      show={isOpen}
      onHide={onHide}
      primaryCallback={onSave}
      secondaryCallback={onHide}
      primaryButtonProps={{ loading: isSubmitting, disabled: Boolean(inValid) }}
    >
      <DepositInput
        isCollected={isDepositCollected}
        onChange={onChange}
        value={depositAmount}
        appliesDate={depositAppliesDate}
        showDatePicker={isDepositCollected}
        inValid={inValid}
      />
    </CenteredModal>
  );
};

export default EditDepositModal;
