import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import MagicLinkUI from '../MagicLinkUI';
import { showToast } from 'shared/components/Toast';
import { useVerifyEmailViaMagicLink } from '../graphql/mutations';

interface IProps {}

const VerifyEmail: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const token = queryString.parse(location.search).token;
  const [verifyEmailViaMagicLink, { called: verifyCalled }] = useVerifyEmailViaMagicLink({
    onCompleted: () => {
      showToast('Email verified successfully.', 'success');
      history.replace('/home');
    },
    onError: () => {
      history.replace('/');
    },
  });

  useEffect(() => {
    // if no code is found redirect back to the base route
    if (!token) {
      history.replace('/');
    } else if (!verifyCalled) {
      verifyEmailViaMagicLink({
        variables: {
          token,
        },
      });
    }
  }, [token, history, verifyCalled, verifyEmailViaMagicLink]);

  return <MagicLinkUI />;
};

export default VerifyEmail;
