import React, { useCallback, useState, useEffect } from 'react';
import TextInput from 'shared/components/TextInput';
import { Row, Col } from 'shared/components/Layout';
import AddressInput from 'shared/components/AddressInput';
import PhoneNumberAndTypeInput from 'shared/components/PhoneNumberAndTypeInput';
import { ButtonAsLink } from 'shared/components/Buttons';
import errorMessage from 'shared/constants/errorMessages';
import { isValidPhoneNumber } from 'shared/util/string';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useUpdateContactPersonalInfo } from '../../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import DateInput from 'shared/components/DateInput';
import CrnInput from 'shared/components/CrnInput';
import { isRegion } from 'shared/util/region';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { ApolloError } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import moment from 'moment';

const ADDRESS_OBJECT = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: 'US',
  postalCode: '',
};

interface IProps {
  contact: IContact;
  readOnly: boolean;
}

const PersonalInformation: React.FC<IProps> = ({ contact: savedContact, readOnly }) => {
  const user = useSelector((state: RootState) => state.user);
  const [contact, updateContact] = useState({
    ...savedContact,
    address: savedContact.address ?? { ...ADDRESS_OBJECT },
  });
  const [formIsDirty, setFormIsDirty] = useState(false);

  useEffect(() => {
    !formIsDirty &&
      updateContact({
        ...savedContact,
        address: savedContact.address ?? { ...ADDRESS_OBJECT },
      });
  }, [formIsDirty, savedContact]);

  const handleChange = useCallback(
    (value: any, name: string) => {
      updateContact({ ...contact, [name]: value });
      setFormIsDirty(true);
    },
    [contact]
  );

  const handleCrnChange = useCallback(
    (crn: any) => {
      updateContact((prev) => ({ ...prev, ccssAttributes: { ...prev.ccssAttributes, crn, isCrnEditable: true } }));
      setFormIsDirty(true);
    },
    [contact]
  );

  const addAdditionalPhoneNumber = useCallback(() => {
    handleChange([...(contact.additionalPhoneNumbers || []), { number: '', type: '' }], 'additionalPhoneNumbers');
  }, [contact.additionalPhoneNumbers, handleChange]);

  const updateAdditionalPhoneNumber = useCallback(
    (phoneNumber, index) => {
      contact.additionalPhoneNumbers &&
        handleChange(
          [...contact.additionalPhoneNumbers.map((n, i) => (i === index ? phoneNumber : n))],
          'additionalPhoneNumbers'
        );
    },
    [contact.additionalPhoneNumbers, handleChange]
  );

  const removeAdditionalPhoneNumber = useCallback(
    (index) => {
      contact.additionalPhoneNumbers &&
        handleChange([...contact.additionalPhoneNumbers.filter((n, i) => i !== index)], 'additionalPhoneNumbers');
    },
    [contact.additionalPhoneNumbers, handleChange]
  );

  const [updateContactPersonalInfo, { loading }] = useUpdateContactPersonalInfo();
  const [isContactCrnValid, setContactCrnValid] = useState(true);

  const isAuRegion = isRegion('AU');

  const save = useCallback(() => {
    const ccssAttributes: IContactCcssAttributesInput = {
      crn: contact.ccssAttributes?.crn,
    };

    updateContactPersonalInfo({
      variables: {
        input: {
          id: contact.id,
          businessId: user?.entityId ?? '',
          firstname: contact.firstname,
          lastname: contact.lastname,
          nickname: contact.nickname,
          dob: !!contact.dob ? moment(contact.dob).format('YYYY/MM/DD') : undefined,
          address: {
            address1: contact.address?.address1 ?? '',
            address2: contact.address?.address2 ?? '',
            city: contact.address?.city ?? '',
            state: contact.address?.state ?? '',
            country: contact.address?.country ?? 'US',
            postalCode: contact.address?.postalCode ?? '',
          },
          primaryPhoneNumber: {
            number: contact.primaryPhoneNumber?.number || '',
            type: contact.primaryPhoneNumber?.type,
          },
          additionalPhoneNumbers: contact.additionalPhoneNumbers?.map((p) => ({ number: p.number, type: p.type })),
          ccssAttributes: isAuRegion ? ccssAttributes : undefined,
        },
      },
    })
      .then(() => {
        showToast('Contact updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch((error) => {
        const message = error instanceof ApolloError ? getApolloErrorMessage(error) : error.message;
        showToast(message, 'error');
      });
  }, [contact, updateContactPersonalInfo]);

  const isPhoneInputValid = useCallback((phoneInput: IPhoneNumber | undefined) => {
    if (!phoneInput || !phoneInput.number) return true;
    if (isValidPhoneNumber(phoneInput.number) && phoneInput.type) return true;
    return false;
  }, []);

  return (
    <FormWrapper2
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onSave={save}
      onCancel={() => {
        updateContact({ ...savedContact, address: savedContact.address ?? { ...ADDRESS_OBJECT } });
      }}
      loading={loading}
      saveDisabled={
        !contact.firstname ||
        !contact.lastname ||
        contact.additionalPhoneNumbers?.some((p) => !isPhoneInputValid(p)) ||
        !isPhoneInputValid(contact.primaryPhoneNumber) ||
        !isContactCrnValid ||
        (!contact.ccssAttributes?.crn ? false : !contact.dob)
      }
    >
      <Row align="start">
        <Col>
          <TextInput
            required
            label="First Name"
            value={contact.firstname}
            onChange={(value) => handleChange(value, 'firstname')}
            errorText={errorMessage.genericFirstName}
            disabled={readOnly}
          />
        </Col>
        <Col>
          <TextInput
            required
            label="Last Name"
            value={contact.lastname}
            onChange={(value) => handleChange(value, 'lastname')}
            errorText={errorMessage.genericLastName}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            label="Preferred Name"
            value={contact.nickname || ''}
            onChange={(value) => handleChange(value, 'nickname')}
            disabled={readOnly}
            helpTooltipText="First name displayed throughout Kangarootime"
          />
        </Col>
        <Col>
          <TextInput disabled label="Email" value={savedContact.email} />
        </Col>
      </Row>
      <Row>
        {isAuRegion && (
          <Col>
            <DateInput
              required={!!contact.ccssAttributes?.crn}
              label="Date of Birth"
              date={contact.dob}
              onDateSelect={(value) => handleChange(value, 'dob')}
              dateOnly
            />
          </Col>
        )}
        {isAuRegion && (
          <Col>
            <CrnInput
              label="CRN"
              value={contact.ccssAttributes?.crn ?? ''}
              onChange={(crn) => handleCrnChange(crn)}
              disabled={readOnly || !(contact.ccssAttributes?.isCrnEditable ?? true)}
              onValidityChanged={(value: boolean) => setContactCrnValid(value)}
            />
          </Col>
        )}
      </Row>
      <AddressInput
        required={false}
        address={contact.address}
        onChange={(value: IAddress) => handleChange(value, 'address')}
        disabled={readOnly}
        errorText={errorMessage.address.generic}
        showHelperText={false}
      />
      <PhoneNumberAndTypeInput
        label="Primary Phone Number"
        phoneNumber={contact.primaryPhoneNumber ?? { number: '', type: undefined }}
        updatePhoneNumber={(value: IPhoneNumber) => handleChange(value, 'primaryPhoneNumber')}
        errorText={errorMessage.phone}
        isInvalid={Boolean(
          contact.primaryPhoneNumber?.number && !isValidPhoneNumber(contact.primaryPhoneNumber?.number)
        )}
        disabled={readOnly}
      />
      {contact.additionalPhoneNumbers &&
        contact.additionalPhoneNumbers.map((additionalNumber, index) => (
          <PhoneNumberAndTypeInput
            key={index}
            label="Additional Phone Number"
            phoneNumber={additionalNumber}
            updatePhoneNumber={(value: IPhoneNumber) => updateAdditionalPhoneNumber(value, index)}
            errorText={errorMessage.genericPhone}
            isInvalid={!!additionalNumber.number && !isValidPhoneNumber(additionalNumber.number)}
            handleRemove={() => removeAdditionalPhoneNumber(index)}
            disabled={readOnly}
          />
        ))}
      {!readOnly && (
        <Row noGutters>
          <ButtonAsLink className="" onClick={addAdditionalPhoneNumber}>
            <FontAwesomeIcon icon={faPlus} color={colors.secondary} className="mr-1" />
            Add another phone
          </ButtonAsLink>
        </Row>
      )}
    </FormWrapper2>
  );
};

export default PersonalInformation;
