import React, { useState, useCallback } from 'react';
import { orderBy, debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import DataTable, { TableHeader, TableSearch, TABLE_DEFAULTS } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useGetEntity } from 'gql/business/queries';
import { useGetPaginatedBatchesForBusiness } from 'gql/batch/queries';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { currencyFormat } from 'shared/util/currency';
import { getBatchesTableData } from '../duck/actions';
import useFormatDate from 'shared/hooks/useFormatDate';
import classnames from 'classnames';
import { ButtonAsLink, IconButtonCircle } from 'shared/components/Buttons';
import Button from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Select from 'shared/components/Select';
import {
  convertTimeRangeObjectToString,
  convertTimeRangeStringToObject,
  timeRangeOptions,
} from 'shared/util/timeUtils';
import DateInput from 'shared/components/DateInput';
import moment from 'moment';
import { setCurrentCenterFilters } from 'store/context/actions';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useGetBatchPaymentPdfReportLazy } from '../../../gql/reports/queries';
import { showToast } from '../../../shared/components/Toast';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useGetPayrixDisbursementReport from '../hooks/useGetPayrixDisbursementReport';
import Spinner from 'shared/components/Spinner';
import { isRegion } from 'shared/util/region';

interface IBatchedPaymentsTableFilters {
  term: string;
  centerIds: string[];
  sortField: string;
  sortDirection: 'asc' | 'desc';
  start: '';
  end: '';
}

interface IProps {
  businessId: string;
  onUnbatch: (batch: IBatch) => void;
}

const BatchedPaymentsTable: React.FC<IProps> = ({ businessId, onUnbatch, ...props }) => {
  const { t } = useTranslation(['billing']);
  const formatDate = useFormatDate();
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const [downloadPayrixDisbursementReport, loading] = useGetPayrixDisbursementReport();

  const [getBatchPaymentReportFn, { loading: getBatchPaymentReportLoading }] = useGetBatchPaymentPdfReportLazy({
    onCompleted: (result) => {
      window.open(result.getBatchPaymentReport);
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const downloadPdf = useCallback(
    (batchId: string) => {
      setBatchIdToDownload(batchId);
      getBatchPaymentReportFn({ variables: { batchId } });
    },
    [getBatchPaymentReportFn]
  );

  const dispatch = useDispatch();
  const tableData = useSelector((state: RootState) => state.billing.paymentBatches.batchesTableData);

  const [timeframe, setTimeframe] = useState<ITimeRange>({
    start: moment().startOf('month').format(),
    end: moment().endOf('month').format(),
  }); // we show all batches by default
  const [batchIdToDownload, setBatchIdToDownload] = useState<string>('');
  const [documentNumberToDownload, setDocumentNumberToDownload] = useState<string>('');
  const [tableFilters, setTableFilters] = useState<IBatchedPaymentsTableFilters>({
    term: '',
    centerIds: [],
    sortField: 'name',
    sortDirection: 'asc',
    start: '',
    end: '',
  });
  const [tableState, tableFunctions] = useDatatableState();

  const { data: getEntityData } = useGetEntity(
    {
      variables: {
        id: businessId,
      },
      skip: !businessId,
    },
    `id centers { id name address { state } }`
  );

  const { data: paginatedBatchesData, loading: paginatedBatchesLoading } = useGetPaginatedBatchesForBusiness({
    variables: {
      input: {
        businessId,
        searchKey: tableFilters.term,
        sortBy: tableFilters.sortField,
        sortDirection: tableFilters.sortDirection,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        centerIds: tableFilters.centerIds,
        start: moment(timeframe.start).format('YYYY-MM-DD'),
        end: moment(timeframe.end).format('YYYY-MM-DD'),
        omitAutomatedBatches: isRegion('US'),
      },
    },
    fetchPolicy: 'network-only',
    skip:
      !businessId ||
      tableFilters.centerIds.length === 0 ||
      (timeframe.start !== '' && timeframe.end === '') ||
      (timeframe.start === '' && timeframe.end !== ''),
    onCompleted: (result) => {
      dispatch(getBatchesTableData(result.getPaginatedBatchesForBusiness.data));
    },
  });

  const centerSelectOptions = orderBy(getEntityData?.getEntity.centers ?? [], (center) => center.name, 'asc');

  const handleSearchTerm = debounce(
    useCallback(
      (term: string) => {
        tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
        setTableFilters((prev) => ({ ...prev, term }));
      },
      [tableFunctions]
    ),
    250
  );

  const handleFilterSelect = useCallback(
    (filters: string[], key: 'centerIds' | 'states') => {
      tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
      setTableFilters((prev) => ({ ...prev, [key]: filters }));
    },
    [tableFunctions]
  );

  const clearAppliedFilters = useCallback(() => {
    dispatch(setCurrentCenterFilters([]));
    setTableFilters({
      term: '',
      centerIds: [],
      sortField: 'name',
      sortDirection: 'asc',
      start: '',
      end: '',
    });
    setTimeframe({
      start: moment().startOf('month').format(),
      end: moment().endOf('month').format(),
    });
  }, [dispatch, setTimeframe]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDownloadPayrixDisbursementReport = useCallback(
    (documentNumber: string, centerId: string) => {
      setDocumentNumberToDownload(documentNumber);
      downloadPayrixDisbursementReport({ businessId, centerId, documentNumber });
    },
    [downloadPayrixDisbursementReport, businessId]
  );

  return (
    <DataTable
      data={tableData}
      dataSize={paginatedBatchesData?.getPaginatedBatchesForBusiness.totalRecords ?? 0}
      showSelect={false}
      showLoadingOverlay={paginatedBatchesLoading}
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      onSort={(field, direction) =>
        setTableFilters((prev) => ({
          ...prev,
          sortField: field,
          sortDirection: direction === 'ASCENDING' ? 'asc' : 'desc',
        }))
      }
      className="kt-batches-table"
      columns={[
        {
          text: t('billing:transactions.batch-table.column-id'),
          dataField: 'userFriendlyId',
          sort: true,
        },
        {
          text: t('billing:transactions.batch-table.column-batchDate'),
          dataField: 'createdAt',
          sort: true,
          formatter: (date: string) => formatDate(date),
        },
        {
          text: t('billing:transactions.batch-table.column-createdBy'),
          dataField: 'createdByPerson.lastname',
          sort: true,
          formatter: (cell: string, row: IBatch) => `${row.createdByPerson.lastname}, ${row.createdByPerson.firstname}`,
        },
        {
          text: t('billing:transactions.batch-table.column-amount'),
          dataField: 'amount',
          sort: true,
          formatter: (amount: number, row: IBatch) => (
            <h6
              className={classnames('mr-4', {
                'text-success': row.amount > 0,
                'text-dark': row.amount <= 0,
              })}
            >
              {currencyFormat(row.amount)}
            </h6>
          ),
        },
        {
          text: t('billing:transactions.batch-table.column-document-number'),
          dataField: 'documentNumber',
          sort: false,
          formatter: (documentNumber: string, row: IBatch) => {
            if (documentNumber && loading && documentNumberToDownload === documentNumber) {
              return <Spinner small />;
            }

            if (documentNumber) {
              return (
                <ButtonAsLink onClick={() => handleDownloadPayrixDisbursementReport(documentNumber, row.center.id)}>
                  {documentNumber}
                </ButtonAsLink>
              );
            }

            return null;
          },
        },
        {
          text: '',
          dataField: '',
          formatter: (cell: any, row: IBatch) => (
            <Button
              variant="secondary"
              onClick={() => downloadPdf(row.id)}
              loading={getBatchPaymentReportLoading && batchIdToDownload === row.id}
            >
              {t('billing:transactions.batch-table.button-run-report')}
            </Button>
          ),
        },
        {
          text: '',
          dataField: '',
          align: 'center',
          formatter: (cell: any, row: any) => {
            return (
              <IconButtonCircle
                size="sm"
                icon={faTimes}
                color="gray"
                tooltipText={t('billing:transactions.batch-table.unbatch-tool-tip')}
                onClick={() => onUnbatch(row)}
              />
            );
          },
          headerClasses: 'text-center',
        },
      ]}
      renderHeader={(paginationProps, searchProps) => (
        <div className="align-items-center">
          <TableHeader className="flex-wrap">
            <div className="d-flex flex-wrap align-items-center w-auto">
              <TableSearch
                placeholder={t('billing:transactions.batch-table.search-placeholder')}
                onChange={handleSearchTerm}
                className={isMobile ? 'my-1 mr-4' : 'mr-4 flex-shrink-0 flex-grow-0'}
              />
              <Select
                options={timeRangeOptions}
                value={convertTimeRangeObjectToString(timeframe)}
                onChange={(string) => setTimeframe(convertTimeRangeStringToObject(string))}
                className={isMobile ? 'my-1 mr-4' : 'mr-2 flex-shrink-0 flex-grow-0 mb-0'}
              />
            </div>
            <div className={isMobile ? 'd-flex flex-nowrap my-1' : 'd-flex align-items-center ml-4 mr-auto'}>
              <DateInput
                date={timeframe.start}
                onDateSelect={(start) => setTimeframe({ ...timeframe, start })}
                className="mb-0"
              />
              <div className={isMobile ? 'mt-2 mx-2' : 'mx-2'}>to</div>
              <DateInput
                date={timeframe.end}
                onDateSelect={(end) => setTimeframe({ ...timeframe, end })}
                className="mb-0"
              />
            </div>
            <div className={isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex align-items-center'}>
              <DropdownFilter
                title={fieldLabels.center}
                className={isMobile ? 'mr-4 my-1' : 'mr-4'}
                selectedFilters={tableFilters.centerIds}
                options={centerSelectOptions
                  .map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                onFilterSelect={(centers) =>
                  handleFilterSelect(
                    centers.map((c) => c.value),
                    'centerIds'
                  )
                }
              />
              <IconButtonCircle
                icon={faTimes}
                onClick={clearAppliedFilters}
                tooltipDirection="bottom"
                tooltipText={t('billing:transactions.batch-table.clear-filter-tool-tip')}
              />
            </div>
          </TableHeader>
        </div>
      )}
    />
  );
};

export default BatchedPaymentsTable;
