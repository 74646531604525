import { useCreateProviderConflictOfInterests } from 'gql/provider/mutation';
import React, { useCallback, useEffect, useState } from 'react';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import NotifiableEventsContainer from '../NotifiableEventsContainer';

interface Props {
  businessId: string;
  providers: IProvider[];
}

interface INotificationOfConfictOfInterest {
  date: string | null;
  details: string;
  awarenessDate: string | null;
  abn: string;
  rto: string;
  businessName: string;
  providerId: string | null;
}

const ProviderManagementOrControl: React.FC<Props> = ({ businessId, providers }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const defaultFormValues = {
    date: null,
    awarenessDate: null,
    details: '',
    rto: '',
    abn: '',
    businessName: '',
    providerId: null,
  };

  const [formData, setFormData] = useState<INotificationOfConfictOfInterest>({ ...defaultFormValues });

  const [createProviderConflictOfInterests, { loading: createConflictOfInterestsLoading }] =
    useCreateProviderConflictOfInterests({
      onCompleted: () => {
        showToast('Successfully submitted the provider conflict of interests.', 'success');
      },
      onError: (error) => {
        showToast(error.graphQLErrors[0]?.message || error.message, 'error');
      },
    });

  const validate = useCallback(() => {
    return !Object.values(formData).some((val: string) => val === '') && !!formData.date && !!formData.awarenessDate;
  }, [formData]);

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }
    createProviderConflictOfInterests({
      variables: {
        input: {
          ...formData,
          businessId: businessId,
        },
      },
    });
  };

  useEffect(() => {
    if (providers.length > 0) {
      setFormData((form) => ({
        ...form,
        providerId: providers[0].id,
      }));
    }
  }, [providers]);

  const handleCancel = () => {
    const providerId = providers.length > 0 ? providers[0].id : '';

    setFormData({ ...defaultFormValues, providerId: providerId });
  };

  return (
    <>
      <NotifiableEventsContainer
        title="Notification of Provider or Personnel with Provider Management or Control having possible conflict of interest"
        subTitle={undefined}
        notificationMessage="Within 7 days of the Provider becoming aware of the possible conflict of interest"
        onCancel={handleCancel}
        onSave={handleSubmit}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!validate()}
        providerOnChange={(option) => setFormData({ ...formData, providerId: option.value })}
        selectedProvider={formData.providerId}
        isSaving={createConflictOfInterestsLoading}
        child={
          <>
            <Row>
              <Col className="mb-2">
                <DateInput
                  label="Date of notification of the conflict of interest"
                  required
                  date={formData.date || null}
                  onDateSelect={(date: string) => setFormData({ ...formData, date: date })}
                  disabled={false}
                  className="mb-4"
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2" md={6}>
                <TextInput
                  required={true}
                  label="ABN of business with potential conflict of interest"
                  name="text"
                  value={formData.abn}
                  onChange={(value: string) => setFormData({ ...formData, abn: value })}
                  disabled={false}
                  maxlength="11"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" md={6}>
                <TextInput
                  required={true}
                  label="Name of business with the potential conflict of interest"
                  name="text"
                  value={formData.businessName}
                  onChange={(value: string) => setFormData({ ...formData, businessName: value })}
                  disabled={false}
                  maxlength="120"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" md={6}>
                <TextInput
                  required={true}
                  label="RTO registration code/number of business with potential conflict of interest"
                  name="text"
                  value={formData.rto}
                  onChange={(value: string) => setFormData({ ...formData, rto: value })}
                  disabled={false}
                  maxlength="20"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" md={6}>
                <TextInput
                  required={true}
                  label="What is the potential conflict of interest?"
                  name="text"
                  value={formData.details}
                  onChange={(value: string) => setFormData({ ...formData, details: value })}
                  as="textarea"
                  disabled={false}
                  maxlength="50"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" md={6}>
                <DateInput
                  label="What was the date of awareness of potential conflict of interest?"
                  required
                  date={formData.awarenessDate || null}
                  onDateSelect={(date: string) => setFormData({ ...formData, awarenessDate: date })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default ProviderManagementOrControl;
