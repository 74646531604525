import { SortDirection, Stage, useGetReEnrolHistoryReportLazyQuery } from 'generated/graphql';
import moment from 'moment';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import ModalDrawer from 'shared/components/ModalDrawer';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import Select from 'shared/components/Select/Select';
import { showToast } from 'shared/components/Toast';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { RootState } from 'store/reducers';

interface IProps {
  isOpen: boolean;
  onHide: () => void;
}

interface IFormShape {
  centerIds: string[];
  statuses: Stage[];
  startDate: string;
  endDate: string;
}

const ReEnrollRunHistoryReportModal: React.FC<IProps> = ({ isOpen, onHide }) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const reportDataToFile = useReportDataToFile();
  const [formData, setFormData] = useState<IFormShape>({
    centerIds: [],
    statuses: [],
    startDate: '',
    endDate: '',
  });

  const statusOptions: ITableFilterOption[] = useMemo(() => {
    return Object.values(Stage).map((reEnrollStatus) => {
      return { label: t(`enrollment:reenroll-status.${reEnrollStatus}`), value: reEnrollStatus };
    });
  }, []);

  const [getReEnrolHistoryReportFn, { loading, data }] = useGetReEnrolHistoryReportLazyQuery({
    onCompleted: (result) => {
      const reportFileName = 'FamilyRequestHistory';
      reportDataToFile.downloadXlsxFromBase64(result.getReEnrolHistoryReport, reportFileName);
      handleClose();
    },
    onError: (error) => {
      showToast(t('translation:reports.general-failure-message'), 'error');
    },
  });

  const primaryBuittonDisabled =
    formData.centerIds.length <= 0 ||
    formData.statuses.length <= 0 ||
    formData.startDate === '' ||
    formData.endDate === '';

  const handleSubmit = useCallback(() => {
    if (!primaryBuittonDisabled) {
      getReEnrolHistoryReportFn({
        variables: {
          businessId: businessId ?? '',
          input: {
            pageNumber: 1, // the report ignores any pagination so it doesnt matter what this is set to
            pageSize: 25,
            sortDtos: [
              {
                direction: SortDirection.Descending,
                field: 'createdAt',
              },
            ],
            historyFilterBy: {
              centerIds: formData.centerIds,
              searchTerm: undefined,
              startDate: formData.startDate,
              endDate: formData.endDate,
              stages: formData.statuses,
              correspondenceTypes: undefined,
            },
          },
        },
      });
    }
  }, [primaryBuittonDisabled, businessId, formData]);

  const handleClose = useCallback(() => {
    setFormData({
      centerIds: [],
      statuses: [],
      startDate: '',
      endDate: '',
    });
    onHide();
  }, []);

  return (
    <ModalDrawer
      title="Family History Report"
      show={isOpen}
      onHide={handleClose}
      primaryButtonProps={{ disabled: primaryBuittonDisabled, loading }}
      primaryChoice="Run Report"
      primaryCallback={handleSubmit}
      closeOnPrimaryCallback={false}
      secondaryCallback={handleClose}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            isRequired
            selectedCenterIds={formData.centerIds}
            onSelect={(centerIds) => setFormData((prev) => ({ ...prev, centerIds: centerIds as string[] }))}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <DateInput
            label="From Date"
            date={formData.startDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, startDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(day) => (formData.endDate ? moment(day).isSameOrAfter(formData.endDate) : false)}
            required
          />
        </Col>
        <Col>
          <DateInput
            label="To Date"
            date={formData.endDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, endDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(day) => (formData.startDate ? moment(day).isSameOrBefore(formData.startDate) : false)}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            label="Status"
            className="flex-wrap"
            options={statusOptions}
            onChange={(options) =>
              setFormData((prev) => ({ ...prev, statuses: options?.map((opt: any) => opt.value) }))
            }
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            value={statusOptions.filter((status) => formData.statuses?.includes(status.value as Stage))}
            required
          />
        </Col>
      </Row>
    </ModalDrawer>
  );
};

export default ReEnrollRunHistoryReportModal;
