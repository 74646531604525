import * as types from './types';
import * as transactionTypes from 'pages/BillingTransactions/duck/types';

export interface IPaymentsStateShape {
  totalUnbatched: number;
  centerIdToBatch: string | null;
  paymentTransactionsSelectedForBatching: ITransaction[];
  unbatchedPaymentTransactions: ITransaction[]; // table data, can be a different number than `totalUnbatched`
  batchesTableData: IBatch[];
}

const initialState: IPaymentsStateShape = {
  totalUnbatched: 0,
  centerIdToBatch: null,
  paymentTransactionsSelectedForBatching: [],
  unbatchedPaymentTransactions: [],
  batchesTableData: [],
};

export const paymentBatchesReducers = (
  state: IPaymentsStateShape = initialState,
  action: types.PaymentActionTypes | transactionTypes.TransactionActionTypes
): IPaymentsStateShape => {
  switch (action.type) {
    case types.DELETE_BATCH_SUCCESS:
      return {
        ...state,
        totalUnbatched: state.totalUnbatched + action.batch.batchPaymentPaymentIds.length,
        batchesTableData: state.batchesTableData.filter((batch) => batch.id !== action.batch.id),
        unbatchedPaymentTransactions: [...state.unbatchedPaymentTransactions, ...action.unbatchedPaymentTransactions],
      };
    case types.GET_BATCHES_TABLE_DATA:
      return {
        ...state,
        batchesTableData: action.batches,
      };
    case types.GET_ALL_UNBATCHED_PAYMENT_TRANSACTIONS:
      return {
        ...state,
        unbatchedPaymentTransactions: action.transactions,
      };
    case types.GET_TOTAL_UNBATCHED_PAYMENT_TRANSACTIONS:
      return { ...state, totalUnbatched: action.total };
    case types.SELECT_PAYMENTS_FOR_BATCHING:
      return {
        ...state,
        paymentTransactionsSelectedForBatching: action.paymentTransactions,
      };
    case transactionTypes.CREATE_TRANSACTION:
      if (action.transaction.payment?.status !== 'COMPLETED') return { ...state };

      return {
        ...state,
        unbatchedPaymentTransactions: [...state.unbatchedPaymentTransactions, action.transaction],
        totalUnbatched: state.totalUnbatched + 1,
      };
    case transactionTypes.UPDATE_TRANSACTION:
      if (action.transaction.payment?.status !== 'COMPLETED') {
        return {
          ...state,
          // don't assume that the payment is in the list so don't automatically subtract 1
          totalUnbatched:
            state.totalUnbatched -
            state.unbatchedPaymentTransactions.filter((t) => t.id === action.transaction.id).length,
          unbatchedPaymentTransactions: state.unbatchedPaymentTransactions.filter(
            (t) => t.id !== action.transaction.id
          ),
        };
      }

      return {
        ...state,
      };
    case types.SET_CENTER_ID_TO_BATCH:
      return {
        ...state,
        centerIdToBatch: action.centerId,
      };
    case types.CREATE_BATCH_SUCCESS:
      return {
        ...state,
        totalUnbatched: state.totalUnbatched - state.paymentTransactionsSelectedForBatching.length,
        batchesTableData: [...state.batchesTableData, action.batch],
        paymentTransactionsSelectedForBatching: [],
        unbatchedPaymentTransactions: state.unbatchedPaymentTransactions.filter(
          (t) => !state.paymentTransactionsSelectedForBatching.map((tt) => tt.payment!.id).includes(t.payment!.id)
        ),
        centerIdToBatch: null,
      };
    default:
      return state;
  }
};
