import * as types from './types';
import moment from 'moment';

export interface ITimelogTableFilters {
  date: string;
  activeView: 'DAY' | 'WEEK';
  roleIds: string[];
  employeeStatus: string[]; // 'active' | 'pending' | 'deactivated', from statusOptions in src/shared/constants/StaffStatusSearchExpression
}

interface ITimeLogTimecardsShape {
  currentEmployeeTimecards: IAttendance[];
}

export interface ITimelogsStateShape {
  tableFilters: ITimelogTableFilters;
  trainingTimeEvents: ITrainingHolidayEvent[];
  timecards: ITimeLogTimecardsShape;
}

const initialState: ITimelogsStateShape = {
  tableFilters: {
    date: moment().format(),
    activeView: 'DAY',
    roleIds: [],
    employeeStatus: ['active'],
  },
  trainingTimeEvents: [],
  timecards: {
    currentEmployeeTimecards: [],
  },
};

export const timelogReducers = (
  state: ITimelogsStateShape = initialState,
  action: types.TimelogActionTypes
): ITimelogsStateShape => {
  switch (action.type) {
    case types.GET_TRAINING_TIME_EVENTS:
      return {
        ...state,
        trainingTimeEvents: action.timeEvents,
      };
    case types.CREATE_TRAINING_TIME_EVENT:
      return {
        ...state,
        trainingTimeEvents: [...state.trainingTimeEvents, action.timeEvent],
      };
    case types.UPDATE_TRAINING_TIME_EVENT:
      return {
        ...state,
        trainingTimeEvents: state.trainingTimeEvents.map((te) =>
          te.id === action.timeEvent.id ? { ...action.timeEvent } : te
        ),
      };
    case types.DELETE_TRAINING_TIME_EVENT:
      return {
        ...state,
        trainingTimeEvents: state.trainingTimeEvents.filter((te) => te.id !== action.timeEventId),
      };
    case types.UPDATE_TIME_LOG_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.filters,
        },
      };
    case types.SET_CURRENT_EMPLOYEE_TIMECARDS:
      return {
        ...state,
        timecards: {
          ...state.timecards,
          currentEmployeeTimecards: action.timecards,
        },
      };
    case types.REMOVE_TRAINING_TIME_FROM_EMPLOYEE_TIMECARD:
      return {
        ...state,
        timecards: {
          ...state,
          currentEmployeeTimecards: state.timecards.currentEmployeeTimecards.map((att) => ({
            ...att,
            days: att.days.map((day) => ({
              ...day,
              trainingTimeEvents: day.trainingTimeEvents.filter((tte) => tte.id !== action.trainingTimeEventId),
            })),
          })),
        },
        trainingTimeEvents: state.trainingTimeEvents.map((tte) => ({
          ...tte,
          staffIds: tte.staffIds.filter((id) => id !== action.employeeId),
        })),
      };
    default:
      return state;
  }
};
