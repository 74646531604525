export const positionFields: string = `
  id
  businessId
  name
`;

export const staffPositionFields: string = `
  id
  personId
  positionId
  positionName
  scopeId
  scopeType
  businessId
  type
  payRate
  isExempt
`;
