import React, { useCallback, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { capitalize } from 'shared/util/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import DateInput from 'shared/components/DateInput';
import MultipleActiveClassSelect from 'shared/components/Select/MultipleActiveClassSelect';
import moment from 'moment';

interface IFormStateShape {
  centerIds: string[];
  format: 'EXCEL' | 'PDF';
  selectedClasses: string[] | null;
  date: string;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  onSubmit: (centerIds: string[], format: 'EXCEL' | 'PDF', date: string, classIds?: string[]) => void;
  onClose: () => void;
}

const EmergencyContactReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  ...props
}) => {
  const [formData, setFormData] = useState<IFormStateShape>({
    centerIds: [],
    format: 'EXCEL',
    selectedClasses: [],
    date: moment().format('YYYY-MM-DD'),
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpen) {
      setFormData({ centerIds: [], format: 'EXCEL', date: moment().format('YYYY-MM-DD'), selectedClasses: [] });
    }
  }, [isOpen]);

  const handleSubmit = useCallback(() => {
    if (formData.format && formData.centerIds?.length) {
      // @ts-ignore
      onSubmit(formData.centerIds, formData.format, formData.date, formData.selectedClasses || []);
    }
  }, [formData.centerIds, formData.date, formData.format, formData.selectedClasses, onSubmit]);

  const handleClose = useCallback(() => {
    setFormData({ centerIds: [], format: 'EXCEL', date: moment().format('YYYY-MM-DD'), selectedClasses: [] });
    onClose();
  }, [onClose]);

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{ disabled: !formData.format || !formData.centerIds?.length, loading: isLoading }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            isRequired
            selectedCenterIds={formData.centerIds}
            onSelect={(ids) => {
              if (ids && ids.length > 1) {
                setFormData((prev) => ({ ...prev, centerIds: ids as string[], selectedClasses: [] }));
              } else {
                setFormData((prev) => ({ ...prev, centerIds: ids as string[] }));
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            label={`${capitalize(t('spelling.date'))}`}
            date={formData.date}
            dateOnly={true}
            required
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, date, selectedClasses: [] }))}
            className="kt-date-input-no-max-width mb-4"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>{capitalize(t('spelling.format'))}(s)</Form.Label>
          <Select
            options={[
              { value: 'EXCEL', label: 'EXCEL' },
              { value: 'PDF', label: 'PDF' },
            ]}
            value={formData.format ?? { value: 'EXCEL', label: 'EXCEL' }}
            onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </Col>
      </Row>
      {formData.centerIds.length < 2 && (
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>{capitalize(t('spelling.class'))} </Form.Label>
              <MultipleActiveClassSelect
                selectedClassIds={formData.selectedClasses}
                useNullForAllOption={false}
                onSelect={(classIds) => {
                  setFormData({ ...formData, selectedClasses: classIds });
                }}
                centerId={formData.centerIds[0]}
                date={formData.date}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </SideModalDrawer>
  );
};

export default EmergencyContactReportModal;
