import { uniqBy } from 'lodash';
import * as types from './types';

export interface INotificationStateShape {
  all: INotificationV2[];
  tableData: INotificationV2[];
}

const initialState: INotificationStateShape = {
  all: [],
  tableData: [],
};

export const notificationReducers = (
  state: INotificationStateShape = initialState,
  action: types.NotificationV2ActionTypes
): INotificationStateShape => {
  switch (action.type) {
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        all: uniqBy([...state.all, ...action.notifications], 'id'),
        tableData: action.notifications,
      };
    case types.UPDATE_NOTIFICATION: {
      return {
        ...state,
        all: state.all.map((notification) =>
          notification.id === action.notification.id ? { ...notification, ...action.notification } : notification
        ),
        tableData: state.tableData.map((notification) =>
          notification.id === action.notification.id ? { ...notification, ...action.notification } : notification
        ),
      };
    }
    default:
      return state;
  }
};
