import React, { useEffect, useMemo, useState } from 'react';
import Card from 'shared/components/Card';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { stringToHueDegree } from 'shared/util/string';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { IconButtonCircle } from 'shared/components/Buttons';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DIRECTIONS } from 'shared/constants/elastic';
import moment from 'moment';
import { useGetSortedAccounts } from 'gql/account/queries';
import { AccountStatusType } from 'generated/graphql';
import TagCategory from 'shared/constants/enums/tagCategoryEnum';
import { useGetTags } from 'pages/Businesses/subroutes/ManageTags/graphql/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { TargetType } from './interfaces/TargetType';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

interface IProps {
  selectedTargets: any[];
  onSelectedTargetsChange: (targets: any[]) => void;
  targetType: string;
  businessId: string | null;
  display: string;
}

interface IFilterDataShape {
  searchTerm: string | null;
  centers: string[] | undefined;
  classes: string[] | undefined;
  statuses: AccountStatusType[];
  tags: string[] | undefined;
}

const accountStatusForFilter = [AccountStatusType.Active, AccountStatusType.Inactive];

const BulkTransactionTargets: React.FC<IProps> = ({
  targetType,
  businessId,
  onSelectedTargetsChange,
  selectedTargets,
}) => {
  const [t] = useTranslation();
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [targetTableState, targetTableFunctions] = useDatatableState();
  const [filterData, setFilterData] = useState<IFilterDataShape>({
    searchTerm: '',
    centers: [],
    classes: [],
    statuses: [AccountStatusType.Active],
    tags: [],
  });
  const clearActiveFilters = () => {
    setFilterData({
      searchTerm: '',
      centers: [],
      classes: [],
      statuses: [],
      tags: [],
    });
  };

  useEffect(() => clearActiveFilters(), [targetType]);

  // Filter data
  // Centers
  const { data: centers } = useGetActiveCentersWithLoading();
  const filteredCenters = centers?.filter((c) => !businessId || c.entityId === businessId) || [];

  //Tags
  const { data: getTagsInUse } = useGetTags(currentBusinessId ?? '');
  const tags = useMemo(
    () =>
      getTagsInUse?.getEntity.tags.filter((t) => {
        if (targetType === TargetType.Account) {
          return t.category === TagCategory.ACCOUNT.value;
        }
        return t.category === TagCategory.CHILD.value;
      }) ?? [],
    [getTagsInUse?.getEntity.tags, targetType]
  );

  //classes
  const { filterOptions: classFilterOptions } = useClassFilter(filterData.centers);

  const getTableColumns = (): any[] => {
    let cols = [
      {
        text: 'Name',
        dataField: 'name',
        sort: false,
        formatter: (cell: any, row: IBulkTransactionTarget) => (
          <AvatarDataTableCell
            initials={
              targetType === TargetType.Account
                ? (row.name.split(' ')[0] ?? '').charAt(0).toUpperCase() +
                  (row.name.split(' ')[1] ?? '').charAt(0).toUpperCase()
                : row.name.charAt(0).toUpperCase()
            }
            primaryText={row.name}
            color={`hsl(${stringToHueDegree(row.id)}, ${stringToHueDegree(row.id) < 50 ? '100%' : '40%'}, 40%`}
            avatar={row.avatar?.url ?? ''}
          />
        ),
      },
      {
        text: capitalize(t('spelling.center')),
        dataField: targetType === TargetType.Account ? 'center.name' : 'account.center.name',
        sort: false,
      },
      {
        text: 'Status',
        dataField: 'status',
        sort: false,
        formatter: (cell: any, row: IBulkTransactionTarget) => row.status,
      },
    ];

    if (targetType === 'Children') {
      cols.splice(2, 0, {
        text: 'Class',
        dataField: 'classes',
        sort: true,
        formatter: (_: any, row: IBulkTransactionTarget) => {
          return row?.classes
            ?.sort()
            .map((c) => c)
            .join(', ');
        },
      });
      cols.splice(1, 0, {
        text: 'Account',
        dataField: 'account.name',
        sort: false,
      });
    }

    return cols;
  };
  const statusToSend = (() => {
    if (filterData.statuses.length > 1) return undefined;
    return filterData.statuses[0];
  })();
  const { data: accountsPagedResult, loading: accountsLoading } = useGetSortedAccounts({
    variables: {
      input: {
        from: targetTableState.activePage,
        size: targetTableState.pageSize,
        centerIds: filterData.centers ?? [],
        sort: [{ field: 'name', direction: DIRECTIONS.ASCENDING }],
        searchKey: filterData.searchTerm ?? '',
        tagIds: filterData.tags,
        statusType: statusToSend,
        statusAtDate: moment().format('YYYY-MM-DD'),
      },
    },
    skip: targetType !== TargetType.Account,
  });
  const { data: childrenPagedResult, loading: childrenLoading } = useSearchChildren({
    variables: {
      input: {
        from: targetTableState.activePage,
        size: targetTableState.pageSize,
        centerIds: filterData.centers ?? [],
        classIds: filterData.classes ?? [],
        sort: [{ field: 'name', direction: DIRECTIONS.ASCENDING }],
        searchKey: filterData.searchTerm ?? '',
        tagIds: filterData.tags,
        statusType: statusToSend,
        statusAtDate: moment().format('YYYY-MM-DD'),
        includeArchived: false,
      },
    },
    skip: targetType !== 'Children',
    fetchPolicy: 'no-cache', // there is an issue with the cache that causes children to show up with incorrect account and center data
  });
  const sortedSelectedTargets = useMemo(() => {
    return [...selectedTargets].sort((a, b) => {
      const aValue: string = targetType === TargetType.Account ? a.name : a.fullName;
      let bValue: string = targetType === TargetType.Account ? b.name : b.fullName;
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    });
  }, [selectedTargets, targetType]);
  const tableData = useMemo(() => {
    // deduplicate selected from those that might still show up in search
    const selectedDictionary: { [key: string]: any } = {};
    for (let selected of sortedSelectedTargets) {
      selectedDictionary[selected.id] = selected;
    }
    const searchData =
      targetType === TargetType.Account
        ? accountsPagedResult?.getSortedAccounts.data
        : childrenPagedResult?.getSortedChildren.data.map((c) => ({
            ...c,
            id: c.accountChildId, // cannot use childId as it is not unique in this case
            name: c.fullName,
            classes: c.contracts?.map((c) => c.class.name) ?? [],
            status: c.status,
          }));
    const fromSearch = (searchData ?? []).filter((a) => !selectedDictionary[a.id]);
    return [...sortedSelectedTargets, ...fromSearch];
  }, [
    accountsPagedResult?.getSortedAccounts,
    childrenPagedResult?.getSortedChildren,
    sortedSelectedTargets,
    targetType,
  ]);

  const paginationInfo: { totalRecords: number } = useMemo(() => {
    if (targetType === TargetType.Account) {
      return { totalRecords: accountsPagedResult?.getSortedAccounts.totalRecords };
    } else if (targetType === TargetType.Children) {
      return { totalRecords: childrenPagedResult?.getSortedChildren.totalRecords };
    } else {
      return { totalRecords: tableData.length };
    }
  }, [
    accountsPagedResult?.getSortedAccounts.totalRecords,
    childrenPagedResult?.getSortedChildren.totalRecords,
    tableData.length,
    targetType,
  ]);

  return (
    <Card
      className="bulk-transactions-children"
      header={
        <div className="d-flex flex-row align-items-center">
          {targetType}
          <p className="ml-auto mb-0">
            {`${selectedTargets.length}
            ${targetType === TargetType.Account ? 'Account(s)' : 'Child(ren)'} selected`}
          </p>
        </div>
      }
    >
      <DataTable
        noPadding={true}
        data={tableData}
        dataSize={paginationInfo.totalRecords}
        pageSize={targetTableState.pageSize}
        showLoadingOverlay={accountsLoading || childrenLoading}
        columns={getTableColumns()}
        renderHeader={() => (
          <TableHeader className="flex-wrap align-items-center">
            <div className="d-flex flex-wrap mr-auto">
              <TableSearch
                placeholder="Search"
                onChange={(term) => setFilterData((prev: any) => ({ ...prev, searchTerm: term }))}
              />
            </div>
            <div className="d-flex flex-wrap align-items-center">
              <DropdownFilter
                title="Tags"
                className={isMobile ? 'my-1 mr-4' : 'mr-4'}
                selectedFilters={filterData?.tags ?? []}
                options={
                  tags.map((tag) => {
                    return { value: tag.id, label: tag.name };
                  }) ?? []
                }
                onFilterSelect={(options) =>
                  setFilterData((prev: any) => ({ ...prev, tags: options.map((option) => option.value) }))
                }
              />
              <DropdownFilter
                title="Centers"
                className={isMobile ? 'my-1 mr-4' : 'mr-4'}
                selectedFilters={filterData?.centers ?? []}
                options={filteredCenters?.map((center) => {
                  return { value: center.id, label: center.name };
                })}
                onFilterSelect={(options) =>
                  setFilterData((prev: any) => ({
                    ...prev,
                    centers: options.map((option) => option.value),
                    classes: [],
                  }))
                }
              />
              {targetType === 'Children' && (
                <DropdownFilter
                  title="Classes"
                  className={isMobile ? 'my-1 mr-4' : 'mr-4'}
                  selectedFilters={filterData?.classes ?? []}
                  options={classFilterOptions}
                  onFilterSelect={(options) =>
                    setFilterData((prev: any) => ({ ...prev, classes: options.map((option) => option.value) }))
                  }
                />
              )}
              <DropdownFilter
                title="Status"
                className={isMobile ? 'my-1 mr-4' : 'mr-4'}
                selectedFilters={filterData.statuses.map(statusFilterOptionMap)}
                options={accountStatusForFilter.map(statusFilterOptionMap)}
                onFilterSelect={(options) =>
                  setFilterData((prev: any) => ({ ...prev, statuses: options.map((option) => option.value) }))
                }
              />
              <IconButtonCircle
                icon={faTimes}
                onClick={clearActiveFilters}
                tooltipDirection="bottom"
                tooltipText="Clear Filters"
                className={isMobile ? 'mx-auto' : ''}
              />
            </div>
          </TableHeader>
        )}
        onPageChange={targetTableFunctions.changePage}
        onSizePerPageChange={targetTableFunctions.changeSizePerPage}
        activePage={targetTableState.activePage}
        selectedRows={selectedTargets}
        updateSelectedRows={onSelectedTargetsChange}
        showPagination={true}
      />
    </Card>
  );
};

function statusFilterOptionMap(status: AccountStatusType) {
  return { value: status, label: status };
}

function useClassFilter(centerIds: string[] = []) {
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const classQuery = gql`
    query ($id: String!) {
      getClassesForBusiness(id: $id) {
        id
        name
        centerId
      }
    }
  `;
  const { data } = useQuery<{ getClassesForBusiness: { id: string; name: string; centerId: string }[] }>(classQuery, {
    variables: { id: currentBusinessId },
  });
  const filterOptions = useMemo(() => {
    let classes = data?.getClassesForBusiness ?? [];
    if (centerIds.length > 0) {
      classes = classes.filter((c) => centerIds.includes(c.centerId));
    }
    return classes.map((c) => ({ value: c.id, label: c.name }));
  }, [centerIds, data?.getClassesForBusiness]);
  return { filterOptions };
}

function useSearchChildren(options: QueryHookOptions<any, any>) {
  const query = gql`
    query ($input: ChildSearchInput!) {
      getSortedChildren(input: $input) {
        pageNumber
        pageSize
        totalRecords
        data {
          id
          fullName
          accountChildId
          status
          account {
            name
            center {
              name
            }
            status
          }
          contracts {
            class {
              name
            }
          }
        }
      }
    }
  `;
  return useQuery(query, {
    fetchPolicy: 'network-only',
    ...options,
  });
}

export default BulkTransactionTargets;
