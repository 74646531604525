import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { timeEntryFields } from './fields';

// interfaces
interface IAdminClockOutData {
  adminClockOut: ITimeEntry;
}
interface IAdminClockOutVariables {
  id: string;
  trackedTimeOut: string;
}

// mutations
export const ADMIN_CLOCK_OUT = (fields: string = timeEntryFields) =>
  gql` mutation ($id: ID!, $trackedTimeOut: String!) {
    adminClockOut(id: $id, trackedTimeOut: $trackedTimeOut) {
      ${fields}
    }
  }`;

// hooks
export const useAdminClockOut = (
  options?: MutationHookOptions<IAdminClockOutData, IAdminClockOutVariables>,
  fields?: string
) => useMutation<IAdminClockOutData, IAdminClockOutVariables>(ADMIN_CLOCK_OUT(fields), options);
