import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/reducers';
import BaseSelect from '../../../../../../../shared/components/Select/Base';
import Form from 'react-bootstrap/Form';
import { Option } from 'react-select/src/filters';
import { updateIncludeFutureContracts, updateIncludeLtbOffers } from '../../../../../duck/actions';
import FormLabel from '../../../../../../../shared/components/Form/FormLabel';

export type AvailabilityfilterProps = {
  showLabel?: boolean;
};

export default function Availabilityfilter({ showLabel }: AvailabilityfilterProps) {
  const dispatch = useDispatch();
  const includeFutureContracts = useSelector((state: RootState) => state.enrollment.includeFutureContracts);
  const includeLtbOffers = useSelector((state: RootState) => state.enrollment.includeLtbOffers);

  const options = useMemo(() => {
    return [
      { label: 'Include future contracts', value: 'inclContracts' },
      { label: 'Include lead to booking offers', value: 'inclLtbOffers' },
    ] as Option[];
  }, []);

  const getSelectValue = useCallback(() => {
    const values = [] as string[];
    if (includeFutureContracts) {
      values.push('inclContracts');
    }

    if (includeLtbOffers) {
      values.push('inclLtbOffers');
    }

    return options.filter((opt) => values.includes(opt.value));
  }, [options, includeFutureContracts, includeLtbOffers]);

  const handleSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option') {
        if (option.value === 'inclContracts') {
          dispatch(updateIncludeFutureContracts(true));
        }

        if (option.value === 'inclLtbOffers') {
          dispatch(updateIncludeLtbOffers(true));
        }
      } else if (action === 'deselect-option' || action === 'remove-value') {
        if (removedValue?.value === 'inclContracts' || option?.value === 'inclContracts') {
          dispatch(updateIncludeFutureContracts(false));
        }

        if (removedValue?.value === 'inclLtbOffers' || option?.value === 'inclLtbOffers') {
          dispatch(updateIncludeLtbOffers(false));
        }
      } else if (action === 'clear') {
        dispatch(updateIncludeFutureContracts(false));
        dispatch(updateIncludeLtbOffers(false));
      }
    },
    [dispatch]
  );

  return (
    <Form.Group className={'form-group mb-0 mr-4'}>
      {showLabel && <FormLabel>Filters</FormLabel>}
      <BaseSelect
        label="Availability Filter"
        // @ts-ignore
        isMulti
        // @ts-ignore
        value={getSelectValue()}
        // @ts-ignore
        options={options}
        // @ts-ignore
        onChange={(options, action) => handleSelect(options as any[], action)}
        className="react-select-container flex-wrap"
        hideSelectedOptions={false}
        isClearable
        closeMenuOnSelect={false}
        components={{ IndicatorSeparator: null }}
      />
    </Form.Group>
  );
}
