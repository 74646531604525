import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Form from 'react-bootstrap/Form';
import TextInput from 'shared/components/TextInput';
import AvatarUpload from 'shared/components/AvatarUpload';
import { isBlank } from 'shared/util/string';
import PhoneInput from 'shared/components/PhoneInput';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import AddressInput from 'shared/components/AddressInput';
import { Row, Col } from 'shared/components/Layout';
import errorMessage from 'shared/constants/errorMessages';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import Circle from 'shared/components/LoadingSkeletons/Circle';
import { useSelector, useDispatch } from 'react-redux';
import BusinessSelect from 'shared/components/BusinessSelect';
import { useCreateNewCenterAtEntity } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import useUploadFile from 'shared/hooks/useUploadFile';
import { isValidPhoneNumber } from 'shared/util/string';
import { completeStep } from 'store/onBoarding/actions';
import { ONBOARDING_STEPS } from 'shared/constants/Onboarding';
import { omitFalsy } from 'shared/util/object';
import Select from 'shared/components/Select';
import { getTimezoneOptions } from 'shared/constants/dropdownOptions/timezoneOptions';
import moment from 'moment-timezone';
import { useSearchStaff } from 'pages/Employees/subroutes/Profiles/graphql/queries';
import ElasticConstants from 'shared/constants/elastic';
import { active, pending } from 'shared/constants/StaffStatusSearchExpressions';
import { getFullName } from 'shared/util/string';

const newCenterObject = {
  avatar: null,
  centerName: '',
  phoneNumber: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    state: null,
    postalCode: '',
    country: DEFAULT_COUNTRY,
  },
  timezone: '',
  serviceType: null,
  primaryContactPersonId: null,
};

const NewCenterModalForm = ({ isOpen, onClose, navigateToCenterProfile, refetch, ...props }) => {
  // `state` in address is an object since it's used with react-select and it expects its value to be an object
  const [newCenterFormData, setNewCenterFormData] = useState({ ...newCenterObject, timezone: moment.tz.guess() });
  const userEntityId = useSelector((state) => state.user?.entityId);
  const [selectedEntityId, setSelectedEntityId] = useState();
  const entityId = userEntityId || selectedEntityId;
  const [createNewCenterAtEntity, { loading }] = useCreateNewCenterAtEntity();

  const elasticScopeValues = [entityId];
  const { loading: elasticsearchLoading, data } = useSearchStaff({
    filter: {
      [ElasticConstants.SEARCH_EXPRESSIONS.ALL]: [
        {
          [ElasticConstants.SEARCH_EXPRESSIONS.ANY]: [active, pending],
        },
        {
          [ElasticConstants.SEARCH_EXPRESSIONS.ANY]: [
            {
              [ElasticConstants.SEARCH_EXPRESSIONS.TERM]: {
                field: 'roleship.scopeIds.keyword',
                predicate: ElasticConstants.PREDICATES.ONE_OF,
                value: elasticScopeValues,
              },
            },
          ],
        },
      ],
    },
    sort: [
      {
        field: 'lastname.keyword',
        direction: ElasticConstants.DIRECTIONS.ASCENDING,
      },
    ],
    size: 1000,
    from: 0,
  });

  const staffData = data?.searchStaff.data ?? [];

  const onBoarding = useSelector((state) => state.onBoarding);
  const { completedSteps } = onBoarding;

  const dispatch = useDispatch();

  const [uploadFileFn, s3Key] = useUploadFile();

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const uploadFile = useCallback(
    (file) => {
      uploadFileFn(file);
    },
    [uploadFileFn]
  );

  const handleInput = useCallback((name, value) => {
    setNewCenterFormData((newCenterFormData) => ({
      ...newCenterFormData,
      [name]: value,
    }));
  }, []);

  const formIsValid =
    !isBlank(newCenterFormData.centerName) &&
    isValidPhoneNumber(newCenterFormData.phoneNumber || '') &&
    !isBlank(newCenterFormData.address.address1) &&
    !isBlank(newCenterFormData.address.city) &&
    !isBlank(newCenterFormData.address.state) &&
    newCenterFormData.address.state &&
    !isBlank(newCenterFormData.address.postalCode) &&
    !isBlank(newCenterFormData.address.country) &&
    !isBlank(newCenterFormData.timezone);
  !isBlank(newCenterFormData.primaryContactPersonId);

  const handleClose = useCallback(
    (data, entityId) => {
      onClose();
      setNewCenterFormData(newCenterObject);
    },
    [onClose]
  );

  const submitFormPrimary = useCallback(
    (data, entityId) => {
      // taken from the previous implementation of create center at entity hook
      const centerDetailsWithoutFalsy = omitFalsy(data);
      // ensure empty strings are removed from the address object
      const centerAddress = omitFalsy(data.address);

      createNewCenterAtEntity({
        variables: {
          input: {
            entityId,
            name: centerDetailsWithoutFalsy.centerName,
            address: centerAddress,
            phoneNumber: centerDetailsWithoutFalsy.phoneNumber,
            avatar: data.avatar,
            timezone: data.timezone,
            primaryContactPersonId: data.primaryContactPersonId,
          },
        },
      })
        .then((result) => {
          const newCenter = result.data.createCenter;

          showToast(`Successfully created ${newCenter?.name}.`, 'success');
          navigateToCenterProfile(newCenter.id);
          handleClose();
          dispatch(completeStep(ONBOARDING_STEPS.addCenter));
          refetch();
        })
        .catch((error) =>
          error?.networkError?.result?.errors?.forEach(({ message }) => {
            showToast(message, 'error');
          })
        );
    },
    [createNewCenterAtEntity, dispatch, handleClose, navigateToCenterProfile, refetch]
  );

  const submitFormSecondary = useCallback(
    (data, entityId) => {
      const centerDetailsWithoutFalsy = omitFalsy(data);
      // ensure empty strings are removed from the address object
      const centerAddress = omitFalsy(data.address);

      createNewCenterAtEntity({
        variables: {
          input: {
            entityId,
            name: centerDetailsWithoutFalsy.centerName,
            address: centerAddress,
            phoneNumber: centerDetailsWithoutFalsy.phoneNumber,
            avatar: data.avatar,
            timezone: data.timezone,
          },
        },
      })
        .then((newCenter) => {
          showToast(`Successfully created ${newCenter?.name}.`, 'success');
          dispatch(completeStep(ONBOARDING_STEPS.addCenter));
          setNewCenterFormData(newCenterObject);
          refetch();
        })
        .catch((error) =>
          error?.networkError?.result?.errors?.forEach(({ message }) => {
            showToast(message, 'error');
          })
        );
    },
    [createNewCenterAtEntity, dispatch, refetch]
  );

  return (
    <SideModalDrawer
      title={`New ${fieldLabels.center}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Save"
      closeOnPrimaryCallback={false}
      primaryCallback={() => submitFormPrimary({ ...newCenterFormData, avatar: s3Key }, entityId)}
      primaryButtonProps={{
        disabled: !formIsValid,
      }}
      secondaryChoice="Save, Add Another"
      closeOnSecondaryCallback={false}
      secondaryCallback={() => submitFormSecondary(newCenterFormData, entityId)}
      secondaryButtonProps={{
        disabled: !formIsValid,
      }}
      footerHelperText={!formIsValid ? errorMessage.formRequirements : ''}
    >
      {loading ? (
        <>
          <Circle size="lg" className="mb-8" />
          <LoadingLines />
        </>
      ) : (
        <>
          <Form id="new-center-form">
            <AvatarUpload placeholder="Add Logo" className="mb-4" onFileSelect={uploadFile} />
            <h6 className="text-info mb-4">{fieldLabels.center.toUpperCase()} INFORMATION</h6>
            {!userEntityId && (
              <BusinessSelect
                selectedBusinessId={selectedEntityId}
                onSelectBusiness={(entity) => setSelectedEntityId(entity.id)}
              />
            )}
            <TextInput
              id="center-name-input"
              name="centerName"
              label={`${fieldLabels.center} Name`}
              value={newCenterFormData.centerName}
              onChange={(value, name) => handleInput(name, value)}
              required
              errorText={errorMessage.centerName}
            />
            <AddressInput
              address={newCenterFormData.address}
              onChange={(value) => handleInput('address', value)}
              required
              errorText={errorMessage.address.center}
            />
            <Row>
              <Col xs={8}>
                <Select
                  required={true}
                  name="primaryContact"
                  label="Primary Contact"
                  options={staffData}
                  value={newCenterFormData.primaryContactPersonId}
                  getOptionLabel={(staff) => getFullName(staff)}
                  getOptionValue={(staff) => staff.id}
                  onChange={(staff) => handleInput('primaryContactPersonId', staff.id)}
                />
              </Col>
              <Col xs={8}>
                <PhoneInput
                  id="center-phone-input"
                  name="new-center-ph"
                  label={`${fieldLabels.center} Phone Number`}
                  onChange={(value) => handleInput('phoneNumber', value)}
                  value={newCenterFormData.phoneNumber}
                  required
                  isInvalid={!isValidPhoneNumber(newCenterFormData.phoneNumber || '')}
                  errorText={errorMessage.invalidPhoneNumber}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  required
                  label="Time Zone"
                  value={newCenterFormData.timezone}
                  options={getTimezoneOptions()}
                  onChange={(option) => handleInput('timezone', option.value)}
                />
              </Col>
            </Row>
          </Form>
        </>
      )}
    </SideModalDrawer>
  );
};

NewCenterModalForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  navigateToCenterProfile: PropTypes.func,
  refetch: PropTypes.func,
};

NewCenterModalForm.defaultProps = {
  isOpen: false,
  onClose: () => {},
  navigateToCenterProfile: () => {},
};

export default NewCenterModalForm;
