import React, { useState, useCallback, useEffect, useMemo } from 'react';
import momentTz from 'moment-timezone';
import Card from 'shared/components/Card';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import WeekOperationHours from './WeekOperationHours';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Checkbox from 'shared/components/Checkbox';
import moment from 'moment';
import { sortBy } from 'lodash';
import { OperationHoursType, ICenterOperationsAvailabilityTimes, ICenterOperationsDayAvailability } from './types';
import useGetOperationsData from 'shared/hooks/useGetOperationsData';
import { UPDATE_CENTER_PROFILE } from 'pages/Centers/subroutes/Profile/graphql/mutations';
import { sortByWeekDay } from 'shared/util/array';
import { omitTypename } from 'shared/util/object';
import { GET_CENTER } from 'gql/center/queries';
import { GET_CENTER_PROFILE } from 'pages/Centers/subroutes/Profile/graphql/fields';
import { useMutation } from 'shared/apis/core';
import { showToast } from 'shared/components/Toast';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { isValid24HourString } from 'shared/util/timeUtils';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

const DEFAULT_OPERATION_HOURS: ICenterOperationsAvailabilityTimes = {
  SUNDAY: {
    dayEnabled: false,
    startTime: null,
    endTime: null,
    startTimeString: '',
    endTimeString: '',
  },
  MONDAY: {
    dayEnabled: false,
    startTime: null,
    endTime: null,
    startTimeString: '',
    endTimeString: '',
  },
  TUESDAY: {
    dayEnabled: false,
    startTime: null,
    endTime: null,
    startTimeString: '',
    endTimeString: '',
  },
  WEDNESDAY: {
    dayEnabled: false,
    startTime: null,
    endTime: null,
    startTimeString: '',
    endTimeString: '',
  },
  THURSDAY: {
    dayEnabled: false,
    startTime: null,
    endTime: null,
    startTimeString: '',
    endTimeString: '',
  },
  FRIDAY: {
    dayEnabled: false,
    startTime: null,
    endTime: null,
    startTimeString: '',
    endTimeString: '',
  },
  SATURDAY: {
    dayEnabled: false,
    startTime: null,
    endTime: null,
    startTimeString: '',
    endTimeString: '',
  },
};

interface IProps {}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const BusinessHoursTab: React.FC<IProps> = ({ ...props }) => {
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const { data: allowedEntitiesData } = useGetOperationsData();
  const [updateCenter, { loading: updatingCenterProfile }] = useMutation(UPDATE_CENTER_PROFILE);
  const [selectedCenter, setSelectedCenter] = useState<ICenter | null>(null);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [staffOperationHours, setStaffOperationHours] =
    useState<ICenterOperationsAvailabilityTimes>(DEFAULT_OPERATION_HOURS); // hours that staff are able to be present at the center
  const [familyOperationHours, setFamilyOperationHours] =
    useState<ICenterOperationsAvailabilityTimes>(DEFAULT_OPERATION_HOURS); // hours that gaurdians and children are able to be present at the center
  const timezones = useSelector((state: RootState) => state.timezone.byCenterId);
  const timezone: string = timezones[selectedCenter?.id || ''] ?? momentTz.tz.guess();
  const hasCenter: boolean = Boolean(Object.keys(timezones).length);
  const hasEditOperationsPermission = useHasRoleAreaLevel({
    area: AreaType.Operations,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  // create an array of centers for all of my entities
  const myCenters: ICenter[] = useMemo(() => {
    const entities: IEntity[] = allowedEntitiesData?.getAllowedEntities ?? [];

    return sortBy(
      entities.flatMap((entity) => entity.centers ?? []),
      (center: ICenter) => center.name
    );
  }, [allowedEntitiesData]);

  useEffect(() => {
    const selectedCenter = myCenters.find((c) => c.id === currentCenterId) ?? null;
    setSelectedCenter(selectedCenter);
  }, [currentCenterId, myCenters]);

  // ensure the operation hours array is sorted and has the correct expected object structure
  const sortOperationHours = useCallback((operationHours: IOperationHours[]): ICenterOperationsAvailabilityTimes => {
    const reducerObject: ICenterOperationsAvailabilityTimes = { ...DEFAULT_OPERATION_HOURS };

    if (operationHours && operationHours.length) {
      return sortByWeekDay(operationHours).reduce(
        (acc: ICenterOperationsAvailabilityTimes, current: IOperationHours) => {
          // times are strings in the format of 'hh:mm'
          const [startHours, startMinutes]: string[] = current.startTime.split(':');
          const [endHours, endMinutes]: string[] = current.endTime.split(':');

          acc[current.dayOfWeek] = {
            ...current,
            dayEnabled: true,
            startTime: moment().hours(parseInt(startHours, 10)).minutes(parseInt(startMinutes, 10)).seconds(0), // create moment object from time string
            endTime: moment().hours(parseInt(endHours, 10)).minutes(parseInt(endMinutes, 10)).seconds(0), // create moment object from time string
            startTimeString: current.startTime,
            endTimeString: current.endTime,
          };

          return acc;
        },
        reducerObject
      );
    }

    return reducerObject;
  }, []);

  useEffect(() => {
    if (selectedCenter) {
      setStaffOperationHours(sortOperationHours(selectedCenter?.staffOperationHours ?? []));
      setFamilyOperationHours(sortOperationHours(selectedCenter?.familyOperationHours ?? []));
    }
  }, [selectedCenter, sortOperationHours]);

  const toggledDayEnabled = useCallback(
    (operationType: OperationHoursType, day: DayOfWeek, enabled: boolean) => {
      if (!formIsDirty) {
        setFormIsDirty(true);
      }

      if (operationType === 'staff') {
        setStaffOperationHours((prev) => ({
          ...prev,
          [day]: {
            ...prev[day],
            dayEnabled: enabled,
            startTime: enabled ? moment().hours(6).minutes(0) : null,
            endTime: enabled ? moment().hours(18).minutes(0) : null,
            startTimeString: enabled ? moment().hours(6).minutes(0).format('HH:mm') : '',
            endTimeString: enabled ? moment().hours(18).minutes(0).format('HH:mm') : '',
          },
        }));
      } else {
        setFamilyOperationHours((prev) => ({
          ...prev,
          [day]: {
            ...prev[day],
            dayEnabled: enabled,
            startTime: enabled ? moment().hours(6).minutes(0) : null,
            endTime: enabled ? moment().hours(18).minutes(0) : null,
            startTimeString: enabled ? moment().hours(6).minutes(0).format('HH:mm') : '',
            endTimeString: enabled ? moment().hours(18).minutes(0).format('HH:mm') : '',
          },
        }));
      }
    },
    [formIsDirty]
  );

  const updateTimes = useCallback(
    (operationType: OperationHoursType, day: DayOfWeek, startTime: string | null, endTime: string | null) => {
      if (!formIsDirty) {
        setFormIsDirty(true);
      }

      const _startTime = startTime !== null && isValid24HourString(startTime) ? startTime : '';
      const _endtime = endTime !== null && isValid24HourString(endTime) ? endTime : '';
      const [startHours, startMinutes] = _startTime.split(':');
      const [endHours, endMinutes] = _endtime.split(':');

      if (operationType === 'staff') {
        setStaffOperationHours((prev) => ({
          ...prev,
          [day]: {
            ...prev[day],
            startTime:
              prev[day].startTime?.clone().set({ h: parseInt(startHours, 10), m: parseInt(startMinutes, 10) }) ?? null,
            endTime: prev[day].endTime?.clone().set({ h: parseInt(endHours, 10), m: parseInt(endMinutes, 10) }) ?? null,
            startTimeString: startTime ?? '',
            endTimeString: endTime ?? '',
          },
        }));
      } else {
        setFamilyOperationHours((prev) => ({
          ...prev,
          [day]: {
            ...prev[day],
            startTime:
              prev[day].startTime?.clone().set({ h: parseInt(startHours, 10), m: parseInt(startMinutes, 10) }) ?? null,
            endTime: prev[day].endTime?.clone().set({ h: parseInt(endHours, 10), m: parseInt(endMinutes, 10) }) ?? null,
            startTimeString: startTime ?? '',
            endTimeString: endTime ?? '',
          },
        }));
      }
    },
    [formIsDirty]
  );

  // control whether the family hours of operation should match the staff's hours of operation
  const handleSameDaySelect = useCallback(
    (day: DayOfWeek, checked: boolean) => {
      if (!formIsDirty) {
        setFormIsDirty(true);
      }

      setFamilyOperationHours((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          dayEnabled: true,
          isSameAsStaff: checked,
          startTime: checked ? staffOperationHours[day].startTime : prev[day].startTime,
          endTime: checked ? staffOperationHours[day].endTime : prev[day].endTime,
          startTimeString: checked ? staffOperationHours[day].startTimeString : prev[day].startTimeString,
          endTimeString: checked ? staffOperationHours[day].endTimeString : prev[day].endTimeString,
        },
      }));
    },
    [staffOperationHours, formIsDirty]
  );

  const saveChanges = useCallback(async () => {
    if (selectedCenter) {
      // @ts-ignore
      const staffOperationHoursFormatted = Object.keys(staffOperationHours).reduce(
        // @ts-ignore
        (acc: IOperationHours[], current: DayOfWeek) => {
          const value: ICenterOperationsDayAvailability = staffOperationHours[current];

          if (value.dayEnabled && value.startTime && value.endTime) {
            acc.push({
              dayOfWeek: current,
              startTime: moment(value.startTime).format('HH:mm'),
              endTime: moment(value.endTime).format('HH:mm'),
            });
          }

          return acc;
        },
        []
      );

      // @ts-ignore
      const familyOperationHoursFormatted = Object.keys(familyOperationHours).reduce(
        // @ts-ignore
        (acc: IOperationHours[], current: DayOfWeek) => {
          const value: ICenterOperationsDayAvailability = familyOperationHours[current];

          if (value.dayEnabled && value.startTime && value.endTime) {
            acc.push({
              dayOfWeek: current,
              startTime: moment(value.startTime).format('HH:mm'),
              endTime: moment(value.endTime).format('HH:mm'),
            });
          }

          return acc;
        },
        []
      );

      await updateCenter({
        variables: {
          input: {
            id: selectedCenter.id,
            name: selectedCenter.name,
            address: omitTypename(selectedCenter.address),
            phoneNumber: selectedCenter.phoneNumber,
            staffOperationHours: staffOperationHoursFormatted,
            familyOperationHours: familyOperationHoursFormatted,
            timezone,
          },
        },
        refetchQueries: [
          {
            query: GET_CENTER(GET_CENTER_PROFILE),
            variables: {
              id: selectedCenter.id,
            },
          },
        ],
      }).then(() => {
        setFormIsDirty(false);
        showToast('Changes saved successfully.', 'success');
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
    }
  }, [selectedCenter, updateCenter, staffOperationHours, familyOperationHours, timezone]);

  const dismissChanges = useCallback(() => {
    if (selectedCenter?.staffOperationHours && selectedCenter?.familyOperationHours) {
      setStaffOperationHours(sortOperationHours(selectedCenter.staffOperationHours));
      setFamilyOperationHours(sortOperationHours(selectedCenter.familyOperationHours));
      setFormIsDirty(false);
    }
  }, [sortOperationHours, selectedCenter]);

  // ensure all selected days have a valid timeframe
  const validateSelectedDayTimeframes = useCallback((selectedDays: ICenterOperationsAvailabilityTimes) => {
    return Object.values(selectedDays)
      .filter((day: ICenterOperationsDayAvailability) => day.dayEnabled)
      .every(
        (day: ICenterOperationsDayAvailability) =>
          day.startTime?.isValid() &&
          day.endTime?.isValid() &&
          day.startTime.isBefore(day.endTime) &&
          isValid24HourString(day.startTimeString) &&
          isValid24HourString(day.endTimeString)
      );
  }, []);

  // ensure all selected times are a vaild timeframe
  const formIsValid = useCallback(() => {
    const validStaffOperationTimeframes = validateSelectedDayTimeframes(staffOperationHours);
    const validFamilyOperationTimeframes = validateSelectedDayTimeframes(familyOperationHours);

    return validStaffOperationTimeframes && validFamilyOperationTimeframes;
  }, [staffOperationHours, familyOperationHours, validateSelectedDayTimeframes]);

  return (
    <>
      <PageWrapperBody>
        {allowedEntitiesData && <CenterSelectBanner pageName="operations" />}
        <br />
        <Card header="Business Hours">
          <FormWrapper2
            formIsDirty={formIsDirty}
            toggleDirty={setFormIsDirty}
            onCancel={dismissChanges}
            onSave={saveChanges}
            saveDisabled={!formIsValid()}
            loading={updatingCenterProfile}
          >
            <Row className="kt-center-operations-container">
              <Column md>
                <WeekOperationHours
                  title={`Staff: Select the days and times Staff can be scheduled or present at your ${fieldLabels.center.toLowerCase()}.`}
                  operationsType="staff"
                  onDaySelect={toggledDayEnabled}
                  onTimeChange={updateTimes}
                  data={staffOperationHours}
                  disabled={!hasCenter || !hasEditOperationsPermission}
                />
              </Column>
              <Column className="d-none d-md-block px-8 flex-grow-0 kt-center-operations-same-day-container">
                {
                  // @ts-ignore - ignoring Type 'string' is not assignable to type 'DayOfWeek'.ts(2345)
                  Object.keys(familyOperationHours).map((key: DayOfWeek, idx: number) => {
                    return (
                      <Row key={`same-day-control-${key}-${idx}`} className="kt-center-operations-same-day-single">
                        <Column className="text-sm-center">
                          <label>Same?</label>
                          <Checkbox
                            // provide a fallback value to keep the input controlled
                            value={familyOperationHours[key].isSameAsStaff || false}
                            disabled={!hasEditOperationsPermission || !staffOperationHours[key].dayEnabled}
                            onChange={(isChecked: boolean) => handleSameDaySelect(key, isChecked)}
                          />
                        </Column>
                      </Row>
                    );
                  })
                }
              </Column>
              <Column md>
                <WeekOperationHours
                  title={`Families: Select the days and times Children can be scheduled or present at your ${fieldLabels.center.toLowerCase()}.`}
                  operationsType="family"
                  onDaySelect={toggledDayEnabled}
                  onTimeChange={updateTimes}
                  data={familyOperationHours}
                  disabled={!hasCenter || !hasEditOperationsPermission}
                />
              </Column>
            </Row>
          </FormWrapper2>
        </Card>
      </PageWrapperBody>
    </>
  );
};

export default BusinessHoursTab;
