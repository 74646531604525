import React from 'react';
import AvatarContent from './AvatarContent';
import AvatarStatus from './AvatarStatus';

interface IProps {
  image: string;
  size: avatarSizes;
  initials: string;
  status?: 'online' | 'busy' | 'offline';
  alt?: string;
  className?: string;
  color?: string;
  onClick?: () => void;
}

const Avatar: React.FC<IProps> = ({ image, size, initials, status, alt, className, color, onClick }) => {
  // xs and xxl avatars do not support status indicators
  const ignoreStatus: boolean = Boolean(size.match(/xs|xxl/i));

  return (
    <span className={`avatar ${className || ''}`} onClick={onClick}>
      <AvatarContent color={color} key={image} size={size} image={image} initials={initials} alt={alt || ''} />
      {!ignoreStatus && <AvatarStatus status={status} avatarSize={size} />}
    </span>
  );
};

Avatar.defaultProps = {
  size: 'sm',
  image: '',
  initials: '',
};

export default Avatar;
