import { useMemo } from 'react';
import { Circle } from 'shared/components/Shapes';
import colors from '_colors.module.scss';

const statusMap = {
  ACTIVE: { color: colors.success, text: 'Active' },
  EXPIRED: { color: colors.danger, text: 'Expired' },
  EXPIRING_SOON: { color: colors.warning, text: 'Expiring Soon' },
  FUTURE: { color: colors.skyBlue, text: 'Future' },
  ARCHIVED: { color: colors['dark-gray'], text: 'Archived' },
};

const defaultConfig = { color: colors.skyBlue, text: '' };

interface IProps {
  status: string;
}
const AgencyEnrollmentStatus: React.FC<IProps> = ({ status }: IProps) => {
  const statusConfig = useMemo(() => {
    const config = statusMap[status];
    if (!config) {
      return defaultConfig;
    }
    return config;
  }, [status]);
  return (
    <div className="d-flex align-items-center">
      <Circle background={statusConfig.color} size={12} className="mr-2" />
      <span>{statusConfig.text}</span>
    </div>
  );
};

export default AgencyEnrollmentStatus;
