import React, { useCallback, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import useUniqueId from 'shared/hooks/useUniqueId';
import Tooltip from 'shared/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';
import { NumberInput } from '../TextInput';
import _, { cloneDeep } from 'lodash';
import Button from '../Buttons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DateInput from 'shared/components/DateInput';
import KtRangeSlider from '../Slider/RangeSlider';
import './ageRangeSlider.scss';

const minDistance = 1 as const;

function valuetext(value: number) {
  return `${value} months`;
}

function getLabelText(value: number) {
  const years = Math.floor(value / 12);
  const months = value % 12;

  if (years && months) {
    return `${years}y ${months}m`;
  }

  if (years) {
    return `${years}y`;
  }
  return `${months}m`;
}

interface IAgeRangeSliderProps {
  id?: string;
  value?: [number, number] | null;
  className?: string;
  disabled?: boolean;
  onChange: (v: [number, number], dateAsAt: string) => void;
  min?: number;
  max?: number;
}

const AgeRangeSlider = ({
  id,
  className = '',
  disabled = false,
  value,
  onChange,
  min = 0,
  max = 180, // if max is not provided, it will assumed LDC + OSHC max age so would be 180 months
}: IAgeRangeSliderProps) => {
  const { t } = useTranslation();
  const _dropdownId = useUniqueId(id);
  const [isOpen, setIsOpen] = useState(false);
  const [dateAsAt, setDateAsAt] = useState<moment.Moment | null>(moment());

  const getInitialValue = useCallback((): [number, number] => {
    if (!value) {
      return [min, max];
    }
    return value;
  }, [value, min, max]);

  const [minMaxValue, setMinMaxValue] = useState(getInitialValue());

  const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], max - minDistance);
        setMinMaxValue([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setMinMaxValue([clamped - minDistance, clamped]);
      }
    } else {
      setMinMaxValue(newValue as [number, number]);
    }
  };

  const handleManualInputChange = (v: number, type: 'year' | 'month', minOrMax: 'min' | 'max') => {
    const pointer = minOrMax === 'min' ? 0 : 1;
    const currentValue = minMaxValue[pointer];
    const years = Math.floor(currentValue / 12);
    const months = currentValue % 12;
    let newValue = 0;

    if (type === 'month') {
      newValue = v + years * 12;
    } else {
      newValue = months + v * 12;
    }

    const mutableValue = cloneDeep(minMaxValue);
    mutableValue[pointer] = newValue;
    setMinMaxValue(mutableValue);
  };

  const query = (minOrMax: 'min' | 'max', type: 'year' | 'month') => {
    const pointer = minOrMax === 'min' ? 0 : 1;
    const v = minMaxValue[pointer];
    if (type === 'year') {
      return Math.floor(v / 12);
    } else {
      return v % 12;
    }
  };

  const getDropDownLabel = () => {
    if (value?.[0] || value?.[1]) {
      return `${getLabelText(value[0])} - ${getLabelText(value[1])} as at ${dateAsAt?.format('YYYY-MM-DD')}`;
    }

    return t('age-range.label');
  };

  useEffect(() => {
    setMinMaxValue(getInitialValue());
  }, [getInitialValue]);

  return (
    <Dropdown
      id={_dropdownId}
      show={isOpen}
      onToggle={() => setIsOpen((prev) => !prev)}
      className={`kt-age-range-slider ${className}`}
    >
      <Dropdown.Toggle id={_dropdownId} className="k2-datatable-dropdown-toggle" as="button" disabled={disabled}>
        <Tooltip text="Age Range" direction="bottom" showTooltip>
          <div className="d-flex flex-row flex-grow-1 align-items-center">
            <span className={`flex-grow-1 k2-datatable-dropdown-selected-text`}>{getDropDownLabel()}</span>
            <FontAwesomeIcon icon={faChevronDown} color={colors.iconColor} className="ml-6" />
          </div>
        </Tooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight className={`k2-datatable-dropdown-menu mt-1 bg-white`} style={{ width: '370px' }}>
        <div className="age-slider-container">
          <h6>{t('age-range.heading')}</h6>
          <div>
            <div className="pb-8 px-4">
              <KtRangeSlider
                value={minMaxValue}
                onChange={handleChange}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
                min={min}
                max={max}
                valueLabelFormat={(v) => <div>{getLabelText(v)}</div>}
                disableSwap
              />
            </div>
            <h6 className="my-5">{t('age-range.sub-heading')}</h6>
            <div className="d-flex align-items-center mb-2 flex-wrap">
              <div className="year-input">
                <NumberInput
                  value={query('min', 'year')}
                  step="1"
                  min="0"
                  className="mb-0 mx-2 flex-grow-0"
                  onChange={(v) => handleManualInputChange(v, 'year', 'min')}
                />
              </div>{' '}
              {t('age-range.years')}
              <div className="year-input">
                <NumberInput
                  value={query('min', 'month')}
                  step="1"
                  min="0"
                  className="mb-0 mx-2 flex-grow-0"
                  max="12"
                  onChange={(v) => handleManualInputChange(v, 'month', 'min')}
                />
              </div>
              {t('age-range.months')}
            </div>
            <div className="d-flex align-items-center mb-2 flex-wrap">
              <span className="mx-2 font-weight-bold">{t('age-range.to')}</span>
              <div className="year-input">
                <NumberInput
                  value={query('max', 'year')}
                  step="1"
                  min="0"
                  className="mb-0 mx-2 flex-grow-0"
                  onChange={(v) => handleManualInputChange(v, 'year', 'max')}
                />
              </div>
              {t('age-range.years')}
              <div className="year-input">
                <NumberInput
                  value={query('max', 'month')}
                  step="1"
                  min="0"
                  max="12"
                  className="mb-0 mx-2 flex-grow-0"
                  onChange={(v) => handleManualInputChange(v, 'month', 'max')}
                />
              </div>
              {t('age-range.months')}
            </div>
            <div className="d-flex align-items-center mb-2 flex-wrap">
              <span className="mx-2 font-weight-bold">{t('age-range.as-of')}</span>
              <DateInput
                required
                date={dateAsAt?.format('YYYY-MM-DD')}
                onDateSelect={(v) => setDateAsAt(moment.utc(v))}
                dateOnly
                className="mb-0 mx-2 my-2"
              />
            </div>
          </div>
          <Button
            onClick={() => {
              onChange(minMaxValue, dateAsAt?.format('YYYY-MM-DD') ?? '');
              setIsOpen(false);
            }}
            variant="primary"
            className="apply-btn"
          >
            {t('age-range.btn-label')}
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AgeRangeSlider;
