import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { useUpdateAllergens } from 'gql/allergen/mutations';
import { useCreateHealthTerm } from 'gql/healthTerm/mutations';
import React, { useCallback, useState } from 'react';
import { IconButton, IconButtonCircle } from 'shared/components/Buttons';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  onAddAllergyType: (type: IHealthTerm) => void;
}

const CreateNewAllergyTypeModal: React.FC<IProps> = ({ isOpen, onClose, name, onAddAllergyType }) => {
  const [newAllergenNames, updateNewAllergenNames] = useState(['']);

  const [updateAllergens, { loading: updateAllergensLoading }] = useUpdateAllergens();
  const [createHealthTermFn, { loading: createHealthTermLoading }] = useCreateHealthTerm();

  const save = useCallback(() => {
    createHealthTermFn({
      variables: { input: { name, type: 'Allergy' } },
    }).then((data) => {
      const newType = data.data?.createHealthTerm;
      if (newType) {
        onAddAllergyType(newType);
        updateAllergens({
          variables: {
            input: { typeId: newType.id, toArchive: [], toCreate: newAllergenNames.filter((n) => n) },
          },
        }).then((result) => {
          showToast(`Created new allergy type and allergens successfully.`, 'success');
          onClose();
        });
      }
    });
  }, [createHealthTermFn, newAllergenNames]);

  return (
    <ConfirmationModal
      title={`Create ${name} Allegens`}
      show={isOpen}
      onHide={onClose}
      primaryChoice="Save Type"
      primaryCallback={save}
      hideOnCallback={false}
      primaryButtonProps={{ loading: createHealthTermLoading || updateAllergensLoading }}
    >
      <p>Create a new allergy type by entering the information below.</p>
      <TextInput label="Allergen Type" value={name} disabled />
      <p>Create the allergens you would like to be included for this allergen type.</p>
      {newAllergenNames.map((name, index) => (
        <div key={index} className="d-flex mb-2">
          <TextInput
            className="mb-0"
            onChange={(name) =>
              updateNewAllergenNames((prev) => newAllergenNames.map((n, i) => (i === index ? name : n)))
            }
            value={name}
          />
          {index === newAllergenNames.length - 1 ? (
            <IconButtonCircle
              size="sm"
              className="ml-4 my-1"
              backgroundColor="secondary"
              color="white"
              icon={faPlus}
              tooltipText="Add New Allergen"
              onClick={() => updateNewAllergenNames((prev) => [...newAllergenNames, ''])}
            />
          ) : (
            <IconButton
              className="ml-4 sm-icon-width my-1"
              icon={faTrashAlt}
              tooltipText="Remove Allergen"
              onClick={() => updateNewAllergenNames((prev) => newAllergenNames.filter((n, i) => i !== index))}
            />
          )}
        </div>
      ))}
    </ConfirmationModal>
  );
};

export default CreateNewAllergyTypeModal;
