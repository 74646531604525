import i18next from 'i18next';

const reasonsForClosure = [
  {
    value: 'ASRE',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.ASRE'),
  },
  {
    value: 'CLHO',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.CLHO'),
  },
  {
    value: 'CYCL',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.CYCL'),
  },
  {
    value: 'FIRE',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.FIRE'),
  },
  {
    value: 'FLOO',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.FLOO'),
  },
  {
    value: 'INFE',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.INFE'),
  },
  {
    value: 'LACH',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.LACH'),
  },
  {
    value: 'LAST',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.LAST'),
  },
  {
    value: 'OTHE',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.OTHE'),
  },
  {
    value: 'UOTH',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.UOTH'),
  },
  {
    value: 'QUAK',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.QUAK'),
  },
  {
    value: 'PTDY',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.PTDY'),
  },
  {
    value: 'RCDY',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.RCDY'),
  },
  {
    value: 'RENO',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.RENO'),
  },
  {
    value: 'STDA',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.STDA'),
  },
  {
    value: 'TOFU',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.TOFU'),
  },
  {
    value: 'UTIN',
    label: i18next.t('business:ccs-management.service-temporarily-ceasing-operations.reason-for-closure.UTIN'),
  },
];

export { reasonsForClosure };
