import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import SideModalDrawer from 'shared/components/ModalDrawer';
import TextInput from 'shared/components/TextInput';
import { getFullName, isBlank } from 'shared/util/string';
import { useUpdateAccountContactRelationships } from 'gql/account/mutations';
import { showToast } from 'shared/components/Toast';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import Select from 'shared/components/Select';
import { useUpdateContactChildRelationship } from 'gql/child/mutations';
import { updateContactChildRelationship } from '../../../duck/actions';

interface IProps {
  isOpen: boolean;
  contact: IChildContact;
  child: IChild;
  onClose: () => void;
}

const UpdatePrimaryContactRelationshipModal: React.FC<IProps> = ({ isOpen, contact, child, onClose, ...props }) => {
  const dispatch = useDispatch();
  const [relationship, setRelationship] = useState(contact.relationshipType ?? null);
  const [updateFn, { loading }] = useUpdateContactChildRelationship({
    onCompleted: (result) => {
      dispatch(updateContactChildRelationship(child.id, contact.id, relationship));
      showToast('Relationship updated successfully.', 'success');
      handleClose();
    },
    onError: (err) => {
      showToast('There was an error updating this relationship.', 'error');
    },
  });

  const handleSubmit = useCallback(() => {
    updateFn({
      variables: {
        input: {
          contactId: contact.id,
          childId: child.id,
          relationship,
        },
      },
    });
  }, [contact, child, relationship, updateFn]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <SideModalDrawer
      title="Update Relationship"
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={handleSubmit}
      primaryButtonProps={{ disabled: relationship === contact.relationshipType, loading }}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
    >
      <Form>
        <div className="mb-4">
          <Row>
            <Column>
              <TextInput disabled label="First Name" value={contact.firstname} onChange={(value) => {}} />
            </Column>
            <Column>
              <TextInput disabled label="Last Name" value={contact.lastname} onChange={(value) => {}} />
            </Column>
          </Row>
          <Row noGutters>
            <TextInput required disabled label="Email" value={contact.email} onChange={() => {}} type="email" />
          </Row>
        </div>
        <Select
          label={`Relationship to ${getFullName(child)}`}
          value={relationship}
          options={Object.entries(childContactRelationship).map((r) => ({ value: r[0], label: r[1] }))}
          onChange={(option) => setRelationship(option.value)}
        />
      </Form>
    </SideModalDrawer>
  );
};

export default UpdatePrimaryContactRelationshipModal;
