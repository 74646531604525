import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { isCaseClaimFields } from './fields';

interface ISubmitISCaseClaim {
  updateCcssServiceMessage: IISCaseClaim;
}

interface ISubmitISCaseClaimVariables {
  input: ISCaseClaimCreateInput;
}

export const SUBMIT_IS_CASE_CLAIM = gql`
  mutation ($input: IsCaseClaimCreateInput!) {
    submitIsCaseClaim(input: $input) {
      approvalId
      centerName
    }
  }
`;

export const useSubmitCaseClaim = (options?: MutationHookOptions<ISubmitISCaseClaim, ISubmitISCaseClaimVariables>) => {
  return useMutation<ISubmitISCaseClaim, ISubmitISCaseClaimVariables>(SUBMIT_IS_CASE_CLAIM, options);
};

interface ICancelIsCaseClaimData {
  cancelIsCaseClaim: IISCaseClaim;
}

interface ICancelIsCaseClaimVariables {
  input: IIsCaseClaimCancelInput;
}

const CANCEL_IS_CASE_CLAIM = gql`
  mutation($input: IsCaseClaimCancelInput!){
    cancelIsCaseClaim(input: $input) {
      ${isCaseClaimFields}
    }
  }
`;

export const useCancelIsCaseClaim = (
  options?: MutationHookOptions<ICancelIsCaseClaimData, ICancelIsCaseClaimVariables>
) => useMutation<ICancelIsCaseClaimData, ICancelIsCaseClaimVariables>(CANCEL_IS_CASE_CLAIM, options);

interface IToggleISCaseClaimWeekStatus {
  toggleIsCaseClaimWeekStatus: IISCaseExcludedWeek[];
}

interface IToggleISCaseClaimWeekStatusVariables {
  input: IsCaseClaimToggleWeekStatusInput;
}

export const TOGGLE_IS_CASE_CLAIM_WEEK_STATUS = gql`
  mutation ($input: IsCaseClaimToggleWeekStatusInput!) {
    toggleIsCaseClaimWeekStatus(input: $input) {
      weekNumber
      year
    }
  }
`;

export const useToggleCaseClaimWeekStatus = (
  options?: MutationHookOptions<IToggleISCaseClaimWeekStatus, IToggleISCaseClaimWeekStatusVariables>
) => {
  return useMutation<IToggleISCaseClaimWeekStatus, IToggleISCaseClaimWeekStatusVariables>(
    TOGGLE_IS_CASE_CLAIM_WEEK_STATUS,
    options
  );
};
