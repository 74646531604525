import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  Typography,
  Button,
  CardActions,
  Skeleton,
  Tooltip,
} from '@mui/material';
import React from 'react';
import NewUpcomingBill from './BillModal/NewUpcomingBill';
import { ErrorContent } from './ErrorContent';
import moment from 'moment';
import { useGetDraftBill } from './queries/useGetDraftBill';
import useFormatDate from 'shared/hooks/useFormatDate';
import RenderWhen from 'US/Utilities/Components/RenderWhen';
import _ from 'lodash';
import { Bill } from './types/Bill';

interface IProps {
  accountId: string;
}

const NextBillCard: React.FC<IProps> = ({ accountId }) => {
  const [openUpcomingBill, setOpenUpcomingBill] = React.useState<boolean>(false);

  const { data, error, loading, refetch } = useGetDraftBill({ accountId });

  const handleCloseUpcomingBill = React.useCallback(() => {
    setOpenUpcomingBill(false);
  }, []);

  const handleOpenUpcomingBill = React.useCallback(() => {
    setOpenUpcomingBill(true);
  }, []);

  return (
    <>
      <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', minWidth: '350px' }}>
        <Tooltip title="Next Bill is a preview of the bill that will get sent next. It's values may change.">
          {/* this div is here to get around a bug with the tooltip issue is here https://github.com/mui/material-ui/issues/20014 */}
          <div>
            <CardHeader
              title="Next Bill"
              sx={{ padding: '8px 6px 0px 16px', borderBottom: 'none' }}
              titleTypographyProps={{ fontSize: '1rem', fontWeight: 'normal' }}
            />
          </div>
        </Tooltip>
        <CardContent sx={{ flex: 1 }}>
          <RenderWhen>
            <RenderWhen.If isTrue={!_.isNil(error)}>
              <ErrorContent onTryAgain={refetch} error={error} />
            </RenderWhen.If>
            <RenderWhen.If isTrue={loading || !_.isNil(data)}>
              <NextBillContent data={data} loading={loading}></NextBillContent>
            </RenderWhen.If>
          </RenderWhen>
        </CardContent>

        <CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
          <RenderWhen>
            <RenderWhen.If isTrue={loading || !_.isNil(data) || _.isNil(error)}>
              <Button onClick={handleOpenUpcomingBill} disabled={loading}>
                See Details
              </Button>
            </RenderWhen.If>
          </RenderWhen>
        </CardActions>
      </Card>
      <NewUpcomingBill open={openUpcomingBill} onClose={handleCloseUpcomingBill} accountId={accountId} bill={data} />
    </>
  );
};

function NextBillContent({ data, loading }: { data?: Bill; loading: boolean }) {
  const formatDate = useFormatDate();
  return (
    <Stack direction="row" spacing={4} alignItems="center" justifyContent="space-between">
      <div>
        <Typography fontSize=".9rem">Charges as of {moment().format('MM/DD/YY')}</Typography>
        {loading ? (
          <Skeleton height="2rem" />
        ) : (
          <Typography variant="h3" fontWeight={600}>
            {data?.subTotal}
          </Typography>
        )}
      </div>
      <div>
        <Typography>Will Be Sent On:</Typography>
        {loading ? (
          <Skeleton />
        ) : (
          <Typography fontSize="1rem" fontWeight={600}>
            {formatDate(data?.billDate ?? '', 'MM/DD/YY')}
          </Typography>
        )}
        <Typography>Will Be Due On:</Typography>
        {loading ? (
          <Skeleton />
        ) : (
          <Typography fontSize="1rem" fontWeight={600}>
            {formatDate(data?.dueDate ?? '', 'MM/DD/YY')}
          </Typography>
        )}
      </div>
    </Stack>
  );
}

export default NextBillCard;
