import React from 'react';
import { sortBy } from 'lodash';
import Select from 'shared/components/Select';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface IProps {
  selectedBusinessId: string | undefined | null;
  onSelectBusiness: (option: IEntity) => void;
  className?: string;
  noLabel?: boolean;
}

const BusinessSelect: React.FC<IProps> = ({ selectedBusinessId, onSelectBusiness, className, noLabel = false }) => {
  const entitiesMap = useSelector((state: RootState) => state.entities.allEntities);
  const options = sortBy(Object.values(entitiesMap), 'name').map((entity) => ({
    label: entity.name,
    value: entity.id,
  }));

  return (
    <Select
      className={className}
      label={noLabel ? undefined : 'Select Business'}
      aria-label="Select Business"
      options={options}
      value={selectedBusinessId}
      onChange={(option) => onSelectBusiness(entitiesMap[option.value])}
    />
  );
};

export default BusinessSelect;
