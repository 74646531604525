import React, { useEffect, useState, useCallback, useMemo } from 'react';
import OnboardingModal from '../OnboardingModalDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import { useHistory } from 'react-router';
import KangarootimeRoutes, { IRoute } from 'shared/util/routes';
import { completeStep } from '../../../../store/onBoarding/actions';
import cast from 'shared/util/cast';
import { useSearchStaff } from 'pages/Employees/subroutes/Profiles/graphql/queries';
import { allInvited } from 'shared/constants/StaffStatusSearchExpressions';
import { useUpdateOnboardingStatus } from 'pages/MyProfile/graphql/mutations';
import { ONBOARDING_STEPS, ONBOARDING_STEPS_PER_ROLE } from 'shared/constants/Onboarding';
import { useGetClassesForCenters } from 'pages/Centers/subroutes/Classes/graphql/queries';
import useGetMe from 'shared/hooks/useGetMe';
import OnboardingFab from '../OnboardingMobile';
import { showToast } from '../../Toast';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { isRegion } from 'shared/util/region';

interface IProps {
  expanded: boolean;
  closeToggleOnClick?: () => void;
}

const OnboardingMain: React.FC<IProps> = ({ expanded, closeToggleOnClick }) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [stepperItems, setStepperItems] = useState<IStepperItem[]>([]);

  const isAU = isRegion('AU');
  const { data, refetch, loading } = useGetMe();
  const person: IStaff | IPerson | null = data?.getMe ?? null;
  const [updateOnboardingStatusFn] = useUpdateOnboardingStatus();

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);
  const onBoarding = useSelector((state: RootState) => state.onBoarding);
  const classes = useSelector((state: RootState) => state.classes);

  const centerData = useGetActiveCenters();
  const centerId = centerData.length ? centerData[0].id : '';

  useGetClassesForCenters(centerId, {
    skip: centerData.length === 0,
  });

  const centers = user?.myCenters;

  const invitedStaff = useSearchStaff({
    filter: allInvited,
    sort: [],
    size: 0,
    from: 1,
  });
  let hasInvited = invitedStaff.data?.searchStaff?.totalResults || 0;
  hasInvited = user?.roleship?.name === 'Account Owner' ? hasInvited : 1;

  const { stepsStatus } = onBoarding;

  const checkProfile = useCallback(() => {
    if (person) {
      const isCompleted = onBoarding.stepsStatus[ONBOARDING_STEPS.completeProfile];
      const { firstname, lastname, address } = cast<IStaff>(person);

      if (firstname && lastname && address && !isCompleted) {
        dispatch(completeStep(ONBOARDING_STEPS.completeProfile));
      }
    }
  }, [person, onBoarding, dispatch]);

  const checkCenter = useCallback(() => {
    const isCompleted = onBoarding.stepsStatus[ONBOARDING_STEPS.addCenter];
    if (Boolean(centers?.length) && !isCompleted) {
      dispatch(completeStep(ONBOARDING_STEPS.addCenter));
    }
  }, [centers, onBoarding, dispatch]);

  const checkStaff = useCallback(() => {
    const isCompleted = onBoarding.stepsStatus[ONBOARDING_STEPS.inviteEmployee];
    if (hasInvited && !isCompleted) {
      dispatch(completeStep(ONBOARDING_STEPS.inviteEmployee));
    }
  }, [hasInvited, onBoarding, dispatch]);

  const checkClasses = useCallback(() => {
    const isCompleted = onBoarding.stepsStatus[ONBOARDING_STEPS.setupClass];
    if (!!classes.all && classes.all.length > 0 && !isCompleted) {
      dispatch(completeStep(ONBOARDING_STEPS.setupClass));
    }
  }, [classes, onBoarding, dispatch]);

  const roleSteps = useMemo(() => {
    if (!user) return [];
    return ONBOARDING_STEPS_PER_ROLE[user.role.name as 'Account Owner' | 'Administrator'] || [];
  }, [user]);

  useEffect(() => {
    if (roleSteps.length > 0) {
      const hasUnComplete = roleSteps.filter((i) => !onBoarding.stepsStatus[i]).length > 0;
      if (!loading && !hasUnComplete && person?.onboardingStatus === 'Incomplete') {
        updateOnboardingStatusFn({
          variables: {
            input: { onboardingStatus: 'Completed' },
          },
        })
          .then(() => {
            showToast('Successfully completed all tasks!', 'success');
            refetch();
          })
          .catch(() => showToast('There was an error saving your changes.', 'error'));
      }
    }
  }, [onBoarding.stepsStatus, person?.onboardingStatus, updateOnboardingStatusFn, roleSteps]);

  useEffect(() => {
    const steps: IStepperItem[] = [
      {
        id: ONBOARDING_STEPS.completeProfile,
        description: 'Complete your user profile',
        route: KangarootimeRoutes['user'].subRoutes?.profileTabs.route.replace(':activeTab', 'profile'),
        subText: 'Add personal details and an emergency contact',
        active: true,
      },
      {
        id: ONBOARDING_STEPS.addCenter,
        description: `Add your first ${fieldLabels.center}`,
        subText: `Complete ${fieldLabels.center.toLowerCase()} details`,
        route: KangarootimeRoutes['centers'].subRoutes?.profiles,
        routeState: onBoarding.stepsStatus[ONBOARDING_STEPS.addCenter] ? {} : { autoOpenSideModal: true },
      },
      {
        id: ONBOARDING_STEPS.setupClass,
        description: 'Set up your Classes',
        subText: 'Name, assign a homeroom and add a regulation',
        route: KangarootimeRoutes['centers'].subRoutes?.classes,
      },
      {
        id: ONBOARDING_STEPS.inviteEmployee,
        description: 'Invite your first Employee',
        subText: 'Assign a role and position',
        route: KangarootimeRoutes['employees'].subRoutes?.profiles,
        routeState: hasInvited ? {} : { autoOpenSideModal: true },
        isEnabled: onBoarding.stepsStatus[ONBOARDING_STEPS.addCenter],
      },
      {
        id: ONBOARDING_STEPS.addFamily,
        description: 'Add your first Family',
        subText: '',
        route: KangarootimeRoutes['families'].subRoutes?.contacts,
      },
    ];

    const filteredSteps = steps.filter((step) => roleSteps.includes(step.id));
    setStepperItems(filteredSteps);
    checkProfile();
    checkCenter();
    checkClasses();
    checkStaff();
  }, [user, person, classes, hasInvited, roleSteps, centers, onBoarding.stepsStatus]);

  const navigateTo = useCallback(
    (route: IRoute, routeState: any) => {
      history.push({
        pathname: route.route,
        ...(routeState && { state: routeState }),
      });
    },
    [history]
  );

  useEffect(() => {
    history.push(KangarootimeRoutes['user'].subRoutes?.profileTabs.route.replace(':activeTab', 'profile') || '');
  }, []);

  const stepItemOnClickHandler = (clickedStepperItem: IStepperItem) => {
    const updatedStepperItem: IStepperItem[] = [...stepperItems].map((i: IStepperItem) => {
      i.active = false;
      if (i.id === clickedStepperItem.id) {
        i.active = true;
      }
      return i;
    });
    setStepperItems(updatedStepperItem);
    setActiveStep(clickedStepperItem.id);
    closeToggleOnClick && closeToggleOnClick();
    navigateTo && clickedStepperItem.route && navigateTo(clickedStepperItem.route, clickedStepperItem.routeState);
  };

  const validateProfile = () => {
    if (user) {
      const { firstname, lastname, address, primaryEmergencyContact } = cast<IStaff>(person);
      if (!(firstname && lastname && address)) {
        showToast('Please complete your personal information first.', 'error');
      }
    }
  };

  const disabledStepClickHandler = () => {
    // get the least incomplete step
    const leastStep = stepperItems.find((step) => !onBoarding.stepsStatus[step.id]);
    switch (leastStep?.id) {
      case ONBOARDING_STEPS.completeProfile:
        validateProfile();
        break;
      case ONBOARDING_STEPS.addCenter:
        showToast(`Please create your ${fieldLabels.center.toLowerCase()} first.`, 'error');
        break;
    }
  };

  const activeStepTitle = stepperItems.find((step) => step.id === activeStep)?.description || '';

  return (
    <>
      {!expanded && person?.onboardingStatus === 'Incomplete' && (
        <OnboardingFab
          expanded={expanded}
          onClick={closeToggleOnClick}
          description={activeStepTitle}
          isCompleted={stepsStatus[activeStep]}
        />
      )}
      <OnboardingModal
        expanded={expanded}
        closeToggleOnClick={closeToggleOnClick}
        stepperItems={stepperItems}
        disabledStepClickHandler={disabledStepClickHandler}
        onItemClick={stepItemOnClickHandler}
        stepsStatus={stepsStatus}
      />
    </>
  );
};

export default OnboardingMain;
