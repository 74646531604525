import moment from 'moment';
import { useGetClassesForCenters } from 'pages/Centers/subroutes/Classes/graphql/queries';
import React from 'react';
import { useSelector } from 'react-redux';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import { getIsBirthdateWithinClassRange } from 'shared/util/getIsChildWithinClassAgeRange';
import { RootState } from 'store/reducers';
import Availabilityfilter from '../../components/AvailabilityFilter/AvailabilityFilter';

export interface SearchAvailabilityInput {
  startDate: string;
  birthDate: string;
  classes: IClass[];
}

interface IProps {
  input: SearchAvailabilityInput;
  updateInput: (input: SearchAvailabilityInput) => void;
}

const SearchAvailabilityInputs: React.FC<IProps> = ({ input, updateInput }) => {
  const selectedCenterId = useSelector((state: RootState) => state.enrollment.activeCenter.id);
  const { loading } = useGetClassesForCenters(selectedCenterId, {}, input.startDate);
  const classes = useSelector((state: RootState) => state.classes.all).filter((c) => !c.archivedAt);

  return (
    <div className="d-flex align-items-center">
      <DateInput
        date={input.startDate}
        onDateSelect={(startDate) => updateInput({ ...input, startDate })}
        label="Expected Start"
        required={true}
        className="mr-4"
      />
      <DateInput
        date={input.birthDate}
        onDateSelect={(birthDate) =>
          updateInput({
            ...input,
            birthDate,
            classes: classes.filter((c) => getIsBirthdateWithinClassRange(birthDate, c)),
          })
        }
        label="Birth Date"
        required={true}
        className="mr-4"
      />
      {input.birthDate && <div className="mr-4">{getAgeStringFromDateOfBirth(moment(input.birthDate))}</div>}
      <Select
        label="Classes"
        options={classes}
        isMulti
        value={input.classes?.length ? input.classes : undefined}
        onChange={(c) => updateInput({ ...input, classes: c })}
        getOptionLabel={(o) => o.name}
        getOptionValue={(o) => o}
        isLoading={loading}
        className="mr-4 w-260px flex-grow-0 mb-0"
      />
      <Availabilityfilter showLabel />
    </div>
  );
};

export default SearchAvailabilityInputs;
