import { QueryHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { providerLocationOfRecordsFields, serviceContactFields } from './fields';

export interface IGetServiceNameChangeLogsVariables {
  serviceId: string;
}

export interface IGetServiceNameChangeLogsData {
  getServiceNameChangeLogs: {
    data: IServiceNameChangeLog[];
  };
}

export const GET_SERVICE_NAME_CHANGE_LOGS = gql`
  query ($serviceId: ID!) {
    getServiceNameChangeLogs(serviceId: $serviceId) {
      totalRecords
      data {
        endDate
        startDate
        name
      }
    }
  }
`;

export const useGetServiceNameChangeLogs = (serviceId: string) =>
  useQuery<IGetServiceNameChangeLogsData, IGetServiceNameChangeLogsVariables>(GET_SERVICE_NAME_CHANGE_LOGS, {
    variables: { serviceId },
  });

export interface IGetServicesByBusinessVariables {
  businessId: string;
}

export interface IGetServicesByBusinessData {
  getServicesByBusiness: {
    data: IServicesByBusiness[];
  };
}

export const GET_SERVICE_BY_BUSINESS = gql`
  query ($businessId: ID!) {
    getServicesByBusiness(businessId: $businessId) {
      totalRecords
      data {
        id
        serviceId
        name
      }
    }
  }
`;

export const GET_SERVICES_FINANCIAL_DETAILS = gql`
  query ($serviceId: ID!) {
    getServiceFinancialDetails(serviceId: $serviceId) {
      dateOfEvent
      bsb
      accountNumber
      accountName
    }
  }
`;

export const useGetServicesByBusiness = (businessId: string) =>
  useQuery<IGetServicesByBusinessData, IGetServicesByBusinessVariables>(GET_SERVICE_BY_BUSINESS, {
    variables: { businessId },
  });

export const GET_SERVICE_CONTACT = gql`
  query($serviceId: ID!) {
    getServiceContact(serviceId: $serviceId) {
      ${serviceContactFields}
    }
  }
`;

export const GET_PROVIDER_LOCATION_OF_RECORDS = gql`
  query($providerId: ID!) {
    getProviderLocationOfRecord(providerId: $providerId) {
      ${providerLocationOfRecordsFields}
    }
  }
`;

interface IGetServiceContact {
  getServiceContact: IServiceContact;
}

interface IGetServiceContactVariables {
  serviceId: string;
}

export const useGetServiceContact = (serviceId: string) =>
  useQuery<IGetServiceContact, IGetServiceContactVariables>(GET_SERVICE_CONTACT, {
    variables: { serviceId },
    skip: serviceId === '',
    fetchPolicy: 'cache-and-network',
  });

interface IGetServiceFinancialDetails {
  getServiceFinancialDetails: IServiceFinancialDetails;
}

interface IGetServiceFinancialDetailsVariable {
  serviceId: string;
}

export const useGetServiceFinancialDetails = (
  options?: QueryHookOptions<IGetServiceFinancialDetails, IGetServiceFinancialDetailsVariable>
) =>
  useQuery<IGetServiceFinancialDetails, IGetServiceFinancialDetailsVariable>(GET_SERVICES_FINANCIAL_DETAILS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

interface IGetProviderLocationOfRecords {
  getProviderLocationOfRecord: IProviderLocationOfRecords;
}

interface IGetProviderLocationOfRecordsVariable {
  providerId: string;
}

export const useGetProviderLocationOfRecords = (
  options?: QueryHookOptions<IGetProviderLocationOfRecords, IGetProviderLocationOfRecordsVariable>
) =>
  useQuery(GET_PROVIDER_LOCATION_OF_RECORDS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

interface IServiceTemporarilyCeasingInp {
  startDate: Date;
  endDate: Date;
  reasonText: string;
  reason: string;
}
interface IServiceTemporarilyCeasing {
  getServiceTemporarityCeasing: IServiceTemporarilyCeasingInp;
}
interface IGetServiceTemporarilyCeasingInput {
  serviceId: string;
  businessId: string;
}

export const getServiceTemporarilyCeasing = gql`
  query ($serviceId: ID!, $businessId: ID!) {
    getServiceTemporarityCeasing(serviceId: $serviceId, businessId: $businessId) {
      endDate
      startDate
      reasonText
      reason
    }
  }
`;

export const useGetServiceTemporarilyCeasing = (input: IGetServiceTemporarilyCeasingInput) =>
  useQuery<IServiceTemporarilyCeasing, IGetServiceTemporarilyCeasingInput>(getServiceTemporarilyCeasing, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      ...input,
    },
  });
