import React, { useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';
import { ITextInputProps } from './TextInput';
import colors from '_colors.module.scss';
import { IInputHelperState } from './Input';

interface IProps {
  onChange: (value: string) => void;
  value: number | string | null;
  appendNode?: React.ReactNode;
  className?: string;
}

type OmittedTextInputProps = Omit<ITextInputProps, 'value' | 'onChange'>;

const CurrencyInput: React.FC<IProps & OmittedTextInputProps> = ({
  onChange,
  onFocus,
  onBlur,
  value,
  label,
  required,
  disabled,
  isInvalid,
  requiresValidation,
  helpTooltipText,
  helpTooltipTextDirection = 'right',
  appendNode,
  className,
  ...props
}) => {
  const _requiresValidation = required || requiresValidation;
  const [focused, setFocused] = useState<boolean>(props.autoFocus ?? false);
  const [touched, setTouched] = useState<boolean>(props.autoFocus ?? false);
  const [inputHelperState, setInputHelperState] = useState<IInputHelperState>({
    borderClass: '',
    icon: null,
    iconColor: null,
  });

  useEffect(() => {
    if (focused) {
      setInputHelperState({
        borderClass: 'focused',
        icon: null,
        iconColor: null,
      });
    } else if (touched) {
      const invalid = isInvalid || Number.isNaN(Number.parseFloat(value as string));

      if (required && invalid) {
        setInputHelperState({
          borderClass: 'error',
          icon: faExclamationTriangle,
          iconColor: colors.danger,
        });
      } else if (_requiresValidation) {
        setInputHelperState({
          borderClass: invalid ? 'error' : 'success',
          icon: invalid ? faExclamationTriangle : faCheckCircle,
          iconColor: invalid ? colors.danger : colors.success,
        });
      } else {
        setInputHelperState({
          borderClass: '',
          icon: null,
          iconColor: null,
        });
      }
    }
  }, [touched, required, focused, isInvalid, value, _requiresValidation, onChange]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTouched(true);
      onChange && onChange(event.target.value);
    },
    [onChange]
  );

  const handleFocus = useCallback(
    (e) => {
      setFocused(true);
      onFocus && onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      setTouched(true);
      setFocused(false);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  return (
    <Form.Group className={className}>
      <div className="d-flex flex-row">
        {label && <Form.Label>{label}</Form.Label>}
        {required && !disabled && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
        {helpTooltipText && <HelpTooltip text={helpTooltipText} direction={helpTooltipTextDirection} />}
      </div>
      <InputGroup>
        <InputGroup.Prepend>
          {/* we'll need to deal with localization of this later */}
          <InputGroup.Text>$</InputGroup.Text>
        </InputGroup.Prepend>
        <MaskedInput
          inputMode="numeric"
          className={classnames({
            'form-control': true,
            [`form-control__container-${inputHelperState.borderClass}`]: Boolean(inputHelperState.borderClass),
          })}
          mask={createNumberMask({
            prefix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2,
            allowNegative: false,
            allowLeadingZeros: false,
          })}
          // value={value ?? undefined}
          /**
           * use the value provided as the initial value (`defaultValue`) and allow the input to be an uncontrolled input
           * if `value` is used, the MaskedInput component removes trailing zeros (ex: 135.50 would be shown as 135.5)
           */
          defaultValue={value ? Number.parseFloat(value.toString()).toFixed(2) : undefined}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          {...props}
        />
        {appendNode && <InputGroup.Append className="kt-input-group-append-btn">{appendNode}</InputGroup.Append>}
      </InputGroup>
    </Form.Group>
  );
};

export default CurrencyInput;
