import i18n from 'i18n';

export interface IDocumentTypeOption {
  label: string;
  value: CoreDocumentType;
}

export function getDocumentTypeLabel(key: string): string {
  return i18n.t(`accounts:documents.types.${key}`);
}

export const documentTypeOptions: IDocumentTypeOption[] = [
  { label: getDocumentTypeLabel('Medical'), value: 'Medical' },
  { label: getDocumentTypeLabel('Allergy'), value: 'Allergy' },
  { label: getDocumentTypeLabel('Restriction'), value: 'Restriction' },
  { label: getDocumentTypeLabel('ConcessionCard'), value: 'ConcessionCard' },
  { label: getDocumentTypeLabel('DelayedExit'), value: 'DelayedExit' },
  { label: getDocumentTypeLabel('FamilyTaxBenefit'), value: 'FamilyTaxBenefit' },
  { label: getDocumentTypeLabel('CustomField'), value: 'CustomField' },
  { label: getDocumentTypeLabel('Immunization'), value: 'Immunization' },
  { label: getDocumentTypeLabel('EnrolmentForm'), value: 'EnrolmentForm' },
  { label: getDocumentTypeLabel('StartStrongDeclarationForm'), value: 'StartStrongDeclarationForm' },
  { label: getDocumentTypeLabel('StartStrongSchoolExemption'), value: 'StartStrongSchoolExemption' },
];
