import React, { useState } from 'react';
import Card from 'shared/components/Card';
import Button from 'shared/components/Buttons';
import ReactivateStaffModal from '../../../../shared/components/ReactivateStaffModal/ReactivateStaffModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers';

interface IProps {
  staff: IStaff;
}

const ReactivateCard: React.FC<IProps> = ({ staff }) => {
  const [modalOpen, setModelOpen] = useState(false);
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;
  return (
    <div>
      <Card>
        <p>
          <strong>Reactivate this user?</strong>
        </p>
        {AdpEnabled ? (
          <p>Users can be reactivated through ADP by updating their employment status.</p>
        ) : (
          <p>
            Reactivating a user will allow a business to regain access to this past employee’s previous profile and
            medical information. Note: All other employment requirements including role assignment, permissions, class
            assignments, etc., will need to be completed again.
          </p>
        )}
        {!AdpEnabled && (
          <div className="d-flex">
            <Button className="ml-auto" variant="outline-success" onClick={() => setModelOpen(true)}>
              Reactivate
            </Button>
          </div>
        )}
      </Card>
      <ReactivateStaffModal staff={staff} isOpen={modalOpen} onClose={() => setModelOpen(false)} />
    </div>
  );
};

export default ReactivateCard;
