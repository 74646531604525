import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Col, Row } from 'shared/components/Layout';
import ModalDrawer from 'shared/components/ModalDrawer';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { useGetCcsSubsidyPaymentReportLazy } from 'gql/reports/queries';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { showToast } from 'shared/components/Toast';
import { convertTimeRangeObjectToString, convertTimeRangeStringToObject } from 'shared/util/timeUtils';
import Select from 'shared/components/Select';
import DateInput from 'shared/components/DateInput';
import AccountSelect from 'shared/components/Select/AccountSelect';

interface IProps {
  isOpen: boolean;
  defaultFormValues: {
    centerIds: string[];
  };
  onClose: () => void;
}

const CcsSubsidyPaymentReportModal: React.FC<IProps> = ({ isOpen, defaultFormValues, onClose, ...props }) => {
  const { t } = useTranslation(['translation', 'timelog', 'ccss', 'reporting']);
  const reportDataToFile = useReportDataToFile();
  const user = useSelector((state: RootState) => state.user);
  const [centerIds, setCenterIds] = useState<string[] | null>(defaultFormValues.centerIds);
  const [accountIds, setAccountIds] = useState<string[] | null>([]);
  const [accounts, setAccounts] = useState<IAccount[] | null>([]);
  const [dateRange, setDateRange] = useState<ITimeRange>(convertTimeRangeStringToObject('Last 2 Weeks'));

  const resetFormData = () => {
    setAccountIds([]);
    setDateRange(convertTimeRangeStringToObject('Last 2 Weeks'));
  };

  const handleClose = useCallback(() => {
    resetFormData();
    onClose();
  }, [onClose]);

  const [getCcsSubsidyPaymentReportFn, { loading }] = useGetCcsSubsidyPaymentReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(
        result.getCcsSubsidyPaymentReport,
        t('reporting:report-file-name.ccs-session-entitlement')
      );
      handleClose();
    },
    onError: (error) => {
      showToast(t('translation:reports.general-failure-message'), 'error');
    },
  });

  useEffect(() => {
    setCenterIds(defaultFormValues.centerIds);
  }, [defaultFormValues]);

  const handleSubmit = useCallback(() => {
    getCcsSubsidyPaymentReportFn({
      variables: {
        input: {
          businessId: user?.entityId ?? '',
          centerIds: centerIds ?? [],
          accountIds: accountIds ?? [],
          startDate: moment(dateRange.start).format('YYYY-MM-DD'),
          endDate: moment(dateRange.end).format('YYYY-MM-DD'),
        },
      },
    });
  }, [getCcsSubsidyPaymentReportFn, centerIds, accountIds, dateRange, user]);

  const dateRangeOptions: TimeRange[] = ['Last 2 Weeks', 'This Week', 'Last Week', 'Last Month', 'Custom'];

  const [isDateValid, setDateValidity] = useState<boolean>(true);

  const validateDateRange = (start: string, end: string) => {
    if (start !== '' && end !== '') {
      setDateValidity(moment(start).isBefore(moment(end)));
    }
  };

  useEffect(() => {
    validateDateRange(dateRange.start, dateRange.end);
  }, [dateRange]);

  const formDisabled = !isDateValid;

  return (
    <ModalDrawer
      // @ts-ignore Type instantiation is excessively deep and possibly infinite.  TS2589
      title={t('ccss:session-entitlement-report')}
      show={isOpen}
      onHide={handleClose}
      primaryButtonProps={{ disabled: formDisabled, loading }}
      primaryChoice="Run Report"
      primaryCallback={handleSubmit}
      closeOnPrimaryCallback={false}
      secondaryCallback={handleClose}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            selectedCenterIds={centerIds}
            onSelect={(centerIds) => setCenterIds(centerIds)}
            useNullForAllOption={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AccountSelect
            selectedAccountIds={accountIds}
            onSelect={(accountIds) => setAccountIds(accountIds)}
            required
            isMulti
            centerIds={centerIds}
            statusType={'All'}
            appearance="detailed"
            showAccountStatus
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="pl-2">{''}</Row>
          <Row className="pl-2">
            <Select
              name="status"
              options={dateRangeOptions}
              label={t('timelog:time-card.date-range-label')}
              value={convertTimeRangeObjectToString(dateRange)}
              onChange={(string) => setDateRange(convertTimeRangeStringToObject(string))}
            />
          </Row>
          <Row className="pl-2" justify="around">
            <DateInput
              date={dateRange.start}
              onDateSelect={(start) => setDateRange({ ...dateRange, start })}
              isValid={isDateValid}
              hasCustomValidation
              className="flex-grow-0 my-2"
            />
            <div className="my-2">to</div>
            <DateInput
              date={dateRange.end}
              isValid={isDateValid}
              hasCustomValidation
              onDateSelect={(end) => setDateRange({ ...dateRange, end })}
              className="flex-grow-0 my-2"
            />
          </Row>
        </Col>
      </Row>
    </ModalDrawer>
  );
};

export default CcsSubsidyPaymentReportModal;
