import React, { useCallback, useState } from 'react';
import { CreateButton } from 'shared/components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import moment from 'moment';
import { useGetAccountTransactionsInTimeframe, useGetFlaggedPayments } from 'gql/transaction/queries';
import AddManualPaymentModal from 'shared/components/AddManualPaymentModal';
import { useApproveFlaggedPayment } from 'gql/transaction/mutations';
import { updateTransaction } from 'pages/BillingTransactions/duck/action';
import { showToast } from 'shared/components/Toast';
import CancelPaymentModal from 'pages/BillingPayments/components/CancelPaymentModal';
import VoidManualCheckModal from 'pages/BillingPayments/components/VoidManualCheckModal';
import { ICancelPaymentModalStateShape } from 'pages/BillingPayments/Payments';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import AccountPaymentsTable from './AccountPaymentsTable';

interface IProps {
  accountId: string;
}

const AccountPayments: React.FC<IProps> = ({ accountId }) => {
  const dispatch = useDispatch();
  const hasCreatePaymentsPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Create,
  });
  const { week } = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;
  const [showAddManualPaymentModal, setShowAddManualPaymentModal] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf(week).subtract(1, 'week').format(),
    end: moment().endOf(week).format(),
  });
  const [cancelPaymentModalState, setCancelPaymentModalState] = useState<ICancelPaymentModalStateShape>({
    open: false,
    payment: null,
  });

  const [voidManualCheckPaymentModal, setVoidManualCheckPaymentModal] = useState<ICancelPaymentModalStateShape>({
    open: false,
    payment: null,
  });

  const { loading: loadingFlaggedPayments } = useGetFlaggedPayments();
  const transactions = useSelector((state: RootState) => state.billing.transactions.all).filter(
    (t) => t.account.id === accountId && t.payment
  );
  const { loading, networkStatus } = useGetAccountTransactionsInTimeframe({
    variables: { accountId, ...dateRange, paymentTransactionsOnly: true },
  });

  const [approveFlaggedPaymentFn] = useApproveFlaggedPayment({
    onCompleted: (result) => {
      if (result.approvePayment) {
        dispatch(updateTransaction(result.approvePayment));
        showToast('Approved payment successfully.', 'success');
      }
    },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleApprovePayment = useCallback(
    (payment: IPayment) => {
      if (payment) {
        approveFlaggedPaymentFn({
          variables: {
            paymentId: payment.id,
          },
        });
      }
    },
    [approveFlaggedPaymentFn]
  );

  return (
    <div>
      <div className="d-flex align-items-center mb-4">
        <h3>Payments</h3>
        {hasCreatePaymentsPermissions && (
          <CreateButton className="ml-auto" onClick={() => setShowAddManualPaymentModal(true)}>
            Add Payment
          </CreateButton>
        )}
      </div>
      {!transactions ||
      (loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES && networkStatus !== NETWORK_STATUS.REFETCH) ? (
        <DataTableLoadingSkeleton />
      ) : (
        <AccountPaymentsTable
          noPadding
          data={transactions}
          loading={loading}
          dateRange={dateRange}
          setDateRange={setDateRange}
          isAccountView
          isPaymentTransactionsOnly
          onApproveFlaggedPayment={handleApprovePayment}
          onRejectFlaggedPayment={(payment: IPayment, voidManualCheckPayment: boolean) =>
            voidManualCheckPayment
              ? setVoidManualCheckPaymentModal({ open: true, payment })
              : setCancelPaymentModalState({ open: true, payment })
          }
        />
      )}
      {showAddManualPaymentModal && (
        <AddManualPaymentModal
          isOpen={showAddManualPaymentModal}
          onClose={() => setShowAddManualPaymentModal(false)}
          accountId={accountId}
        />
      )}
      <VoidManualCheckModal
        isOpen={voidManualCheckPaymentModal.open}
        payment={voidManualCheckPaymentModal.payment}
        onClose={() => setVoidManualCheckPaymentModal({ open: false, payment: null })}
      />

      <CancelPaymentModal
        isOpen={cancelPaymentModalState.open}
        payment={cancelPaymentModalState.payment}
        onClose={() => setCancelPaymentModalState({ open: false, payment: null })}
      />
    </div>
  );
};

export default AccountPayments;
