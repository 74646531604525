import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import RBCard from 'react-bootstrap/Card';
import RBTable from 'react-bootstrap/Table';
import Button from 'shared/components/Buttons';
import RadioButton from 'shared/components/RadioButton';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { useGetRolesForBusiness } from 'shared/hooks/useGetRolesForBusiness';
import { RootState } from 'store/reducers';
import { roleFields } from 'gql/role/fields';
import { orderBy } from 'lodash';
import { showToast } from 'shared/components/Toast';
import { useUpdateRole } from 'gql/role/mutations';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import Alert from 'shared/components/Alert';
import CreateSchedulingVisibilityInfoCard from './SchedulingVisibilityInformation';

interface IProps {}

const SchedulingVisibility: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['translation', 'settings']);
  const [roles, setRoles] = useState<IRole[]>([]);
  const user = useSelector((state: RootState) => state.user);
  const [updateRoleLoading, setUpdateRoleLoading] = useState(false);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const { data: getRolesForBusinessData, loading: getRolesForBusinessLoading } = useGetRolesForBusiness(
    currentBusinessId ?? '',
    roleFields
  );

  const hasEditOperationsPermission = useHasRoleAreaLevel({
    area: AreaType.Operations,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const [updateRoleFn] = useUpdateRole();

  const onSave = useCallback(async () => {
    setUpdateRoleLoading(true);
    try {
      await Promise.all(
        roles.map(async (role: IRole) => {
          return await updateRoleFn(roleVariables(role));
        })
      );
      showToast('Roles updated successfully.', 'success');
    } catch (error) {
      showToast('There was an error trying to update roles. Please try again later.', 'error');
    }
    setUpdateRoleLoading(false);
  }, [updateRoleFn, roles]);

  const roleVariables = (role: IRole) => {
    return {
      variables: {
        input: {
          roleId: role.id,
          name: role.name,
          hierarchyLevel: role.hierarchyLevel,
          areaLevels: role.areaLevels.map((al) => ({
            roleId: al.roleId,
            area: al.area,
            permission: al.permission,
            level: al.level,
          })),
          scheduleVisibility: role.scheduleVisibility,
        },
      },
    };
  };

  const revertChanges = useCallback(() => {
    fetchRoles();
  }, [roles]);

  const fetchRoles = () => {
    if (!getRolesForBusinessLoading) {
      setRoles(orderBy(getRolesForBusinessData?.getRolesForBusiness, (role: IRole) => role.hierarchyLevel, 'asc'));
    }
  };
  useEffect(() => {
    fetchRoles();
  }, [getRolesForBusinessData, getRolesForBusinessLoading, currentBusinessId]);

  const handleChange = (role: IRole, checked: boolean) => {
    const updatedRole = { ...role, scheduleVisibility: checked };
    const newRoles = [...roles].map((role) => (role.id === updatedRole.id ? updatedRole : role));
    setRoles(newRoles);
  };

  return (
    <PageWrapperBody>
      {user?.isInternal && <CenterSelectBanner pageName="operations" showCenterSelect={false} />}
      <br />
      <CreateSchedulingVisibilityInfoCard />
      <RBCard className="m-0">
        <RBCard.Body className="p-3 border-bottom">
          <RBTable responsive className="kt-role-permission-group-table">
            <thead>
              <tr style={{ background: '#f1f4f8' }}>
                <th>{capitalize('Roles')}</th>
                <th style={{ paddingLeft: '13px' }}>{capitalize('Put on Schedule')}</th>
                <th style={{ paddingLeft: '13px' }}>{capitalize('Do not put on Schedule')}</th>
                <th style={{ width: '60%' }}></th>
              </tr>
            </thead>
            <tbody>
              {roles.map((role: IRole, idx: number) => (
                <tr key={`role-${idx}`}>
                  <td>
                    <div className="kt-role-permission-label">{role.name}</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center mt-n3">
                      <RadioButton
                        value={role.scheduleVisibility}
                        onChange={(checked: boolean) => {
                          handleChange(role, true);
                        }}
                        disabled={!hasEditOperationsPermission}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center mt-n3">
                      <RadioButton
                        value={!role.scheduleVisibility}
                        onChange={(checked: boolean) => {
                          handleChange(role, false);
                        }}
                        disabled={!hasEditOperationsPermission}
                      />
                    </div>
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </RBTable>
        </RBCard.Body>
      </RBCard>
      <RBCard className="m-0">
        <RBCard.Body className="p-2 d-flex justify-content-end">
          <Button className="mr-4" variant="light" onClick={revertChanges} disabled={updateRoleLoading}>
            {capitalize(t('translation:spelling.cancel'))}
          </Button>
          <Button disabled={updateRoleLoading} loading={updateRoleLoading} onClick={() => onSave()}>
            {capitalize(t('translation:spelling.save'))}
          </Button>
        </RBCard.Body>
      </RBCard>
    </PageWrapperBody>
  );
};

export default SchedulingVisibility;
