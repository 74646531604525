import { gql } from '@apollo/client';
import { defaultPositionFields } from 'pages/Settings/Operations/PositionsTab/graphQL/defaultFields';
import { useQuery } from 'shared/apis/core';

interface IGetPositionsForBusinessVariables {
  businessId: string;
  includeArchived: boolean;
}

interface IGetPositionsForBusinessData {
  getAllPositionsByBusinessId: IPosition[];
}

export const GET_POSITIONS_FOR_BUSINESS = gql`
  query ($businessId: ID!, $includeArchived: Boolean) {
    getAllPositionsByBusinessId(businessId: $businessId, includeArchived: $includeArchived) {
     ${defaultPositionFields}
    }
  }
`;

export const useGetPositionsForBusiness = (businessId: string, includeArchived: boolean) =>
  useQuery<IGetPositionsForBusinessData, IGetPositionsForBusinessVariables>(GET_POSITIONS_FOR_BUSINESS, {
    variables: {
      businessId: businessId,
      includeArchived: includeArchived,
    },
    skip: !businessId || businessId === '',
  });
