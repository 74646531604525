import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const contractCycleTypeOptions: { value: ContractCycleType; label: string }[] = [
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'BIWEEKLY', label: fieldLabels.biWeekly },
  { value: 'CUSTOM', label: 'Custom' },
  { value: 'CASUAL', label: 'Casual' },
];

export default contractCycleTypeOptions;
