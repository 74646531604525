import classnames from 'classnames';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { getRegion } from 'shared/util/region';
import { RootState } from 'store/reducers';
import { useUSModeSettings } from 'US/Billing/USMode';
import useGetCurrencySettings from './useGetCurrencySettings';

export type Direction = 'Positive' | 'Negative';

export type Display = 'DirectedNumbers' | 'ParenthesizedNumbers';

export type SignDisplay = 'auto' | 'always' | 'exceptZero' | 'negative' | 'never';

export interface ICurrencySettings {
  direction: Direction;
  display: Display;
  themed: boolean;
}

interface IProps {
  /**
   * The Number the component is actually trying to display
   */
  amount: number | null | undefined;
  /**
   * Whether {@link amount} represents an aggregated amount, in which case we will use red.
   * We want to use red sparingly so limit it to totals, not every place we display currency.
   * this only works if you are using directed numbers
   */
  aggregate?: boolean;
  /**
   * Positive or Negative
   */
  direction?: Direction;
  /**
   * Directed Numbers or Parenthesized Numbers display
   */
  display?: Display;
  /**
   * Used to display a text-success class if payment is true
   */
  payment?: boolean;
  /**
   * When we want to force green for parenthesised that are not payments, themed also has to be sent in order for this to work
   */
  forceColor?: boolean;
  /**
   * Represents transaction color display, this has to be true in order for force color to work
   */
  themed?: boolean;
  /**
   * If display === DirectedNumbers and this is sent it will use the SignDisplay type in the number format
   */
  displaySign?: boolean;
}

const Currency: React.FC<IProps> = (props) => {
  const businessId = useSelector((state: RootState) => state.context?.businessId) ?? '';
  const { usMode } = useUSModeSettings(businessId);

  const { amount, aggregate = false, payment = false, forceColor = false, displaySign = false } = props;
  const isAmountInvalid = !isNumber(amount);

  //if you send all of these three properties which make up the ICurrencySettings it will skip making the request
  const skip = isAmountInvalid || (props.direction && props.display && props.themed);
  const { currencySettings, loading } = useGetCurrencySettings(undefined, skip);

  //if one of these is not set it will try to use the one from currencySettings otherwise it has something to default back to
  const {
    direction = currencySettings?.direction ?? 'Negative',
    display = currencySettings?.display ?? 'ParenthesizedNumbers',
    themed = currencySettings?.themed ?? true,
  } = props;

  const number = Number(amount);
  const amountWithDirection = direction == 'Positive' || number === 0 ? number : -number;
  const region = getRegion();
  const locale = region == 'AU' ? 'en-AU' : 'en-US';
  const currency = region == 'AU' ? 'AUD' : 'USD';
  const directedNumbers = display == 'DirectedNumbers';
  const currencySign = usMode ? 'standard' : directedNumbers ? 'standard' : 'accounting';
  const signDisplay: SignDisplay = usMode ? 'auto' : displaySign && display == 'DirectedNumbers' ? 'always' : 'auto';

  const numberFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencySign,
    signDisplay: signDisplay,
  });

  // If we've given something that's not a number and it's false-y we'll assume it's akin to null.
  // If its loading we should display nothing until the preferences come back
  const formattedAmount = isAmountInvalid || loading ? '' : numberFormat.format(amountWithDirection);

  const classNames = classnames({
    // Only use red for directed numbers; with parenthesized numbers it's not clear yet if we want to
    // color, say, an account's balance which in total owes the business money, in red. An accountant
    // ought to like to see lots of accounts owing money, they have sold lots of child care!
    'text-danger': aggregate && directedNumbers && number < 0,

    // Only use green for directed numbers or payments; an accountant probably likes receiving money.
    // ForceColor is for when we want to force green for parenthesised that are not payments
    'text-success': themed && (directedNumbers || payment || forceColor) && number > 0,
  });

  return (
    <span className={usMode ? '' : classNames} style={{ fontVariantNumeric: 'tabular-nums' }}>
      {formattedAmount}
    </span>
  );
};

export default Currency;
