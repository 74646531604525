import { map, orderBy } from 'lodash';
import React, { useCallback } from 'react';
import CcsEnrolmentCard from 'shared/components/CcsEnrolmentCard/CcsEnrolmentCard';
import CcsTable, { CcsEnrolmentDetails } from './CcsTable';

interface IProps {
  loading: boolean;
  enrolments: ICcsEnrolment[];
  selectedChildId: string;
  account: IAccount;
  onEditClick: (enrolment: ICcsEnrolment) => void;
  onUnlinkClick: (enrolment: ICcsEnrolment) => void;
}

const CcsEnrolmentsTable: React.FC<IProps> = ({
  loading,
  enrolments,
  selectedChildId,
  account,
  onEditClick,
  onUnlinkClick,
}) => {
  const expandRow = (row: CcsEnrolmentDetails) => {
    const orderedPreviousEnrolments = orderBy(row.previousEnrolments, (p) => p.enrolment.occurrenceNumber);

    return (
      <div>
        <CcsEnrolmentCard
          enrolment={row.enrolment}
          child={row.child}
          contact={row.enrolment.contactOfChild}
          onEditClick={() => onEditClick(row.enrolment)}
          onUnlinkClick={() => onUnlinkClick(row.enrolment)}
          showEdit
          renderPreviousEnrolments={() => <CcsTable data={orderedPreviousEnrolments} loading={false} />}
        />
      </div>
    );
  };

  const filterEnrolments = useCallback((): ICcsEnrolment[] => {
    if (selectedChildId !== '') {
      return enrolments.filter((ce) => ce.childId == selectedChildId) ?? [];
    } else {
      return enrolments;
    }
  }, [selectedChildId, enrolments]);

  const filteredRows = filterEnrolments();
  let rows: CcsEnrolmentDetails[] = [];
  for (const enrolment of filteredRows) {
    const child = account.children.find((c) => c.id === enrolment.childId);

    if (!child) continue;

    const previousEnrolments = map(enrolment.previousEnrolments, (e) => {
      const enrolment: CcsEnrolmentDetails = {
        id: `${e.enrolmentId}-${e.occurrenceNumber}`,
        child: child,
        enrolment: e,
        previousEnrolments: [],
      };

      return enrolment;
    });

    rows.push({
      id: `${enrolment.enrolmentId}-${enrolment.occurrenceNumber}`,
      child,
      enrolment: enrolment,
      previousEnrolments: previousEnrolments,
    });
  }

  const orderedRows = orderBy(rows, (r) => r.child.fullName);
  return <CcsTable data={orderedRows} expandRow={expandRow} loading={loading} />;
};

export default CcsEnrolmentsTable;
