import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { GLCodeTypeOptions } from 'shared/constants/enums/glCodes';
import GLCodesAdjustmentsTable from './GLCodesAssignmentsTable';
import { useGLCodeContext } from '../../Context/GLCodeContext';
import './_glCodeAssignmentsTab.scss';

const GLCodesAdjustmentsTab = () => {
  const {
    GLCodeAssignmentTableState: tableState,
    GLCodeAssignmentTableFunction: tableFn,
    data,
    activeSubTab,
    setActiveSubTab,
    getGLCodeAssignmentsLoading,
    handleSort,
    setSearchText,
    searchText,
    totalRecords,
  } = useGLCodeContext();

  const formattedData = (data ?? []).map((item) => ({
    ...item,
    id: `${item.entityId}-${item.surchargeType}`,
  }));

  return (
    <Tabs
      activeKey={activeSubTab}
      onSelect={(eventKey) => {
        setActiveSubTab(eventKey as typeof activeSubTab);
      }}
      className="kt-billing-tab"
    >
      {GLCodeTypeOptions.map((type) => (
        <Tab eventKey={type.value} title={type.label}>
          <GLCodesAdjustmentsTable
            data={formattedData}
            totalResults={totalRecords}
            tableState={tableState}
            tableFunctions={tableFn}
            loading={getGLCodeAssignmentsLoading}
            handleSort={handleSort}
            onSearch={setSearchText}
            searchText={searchText}
          />
        </Tab>
      ))}
    </Tabs>
  );
};

export default GLCodesAdjustmentsTab;
