import React, { useState } from 'react';
import Card from 'shared/components/Card';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { HorizontalDivider } from 'shared/components/Dividers';
import './_birthday-table.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { ApplicationFlowType } from '../../../generated/graphql';
import { NORMAL_FLOW_TABS, PROGRAM_FLOW_TABS } from '../../Enrollment/subroutes/LeadManagement/types';
import { useGetApplicationCategories } from '../../../gql/application/queries';
import Spinner from 'shared/components/Spinner';
import { ApplicationStateColors } from 'shared/constants/enums/applicationEnum';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ButtonAsLink } from 'shared/components/Buttons';
import { setCurrentCenter } from '../../../store/context/actions';
import { isProgramBasedFlow } from 'pages/Enrollment/subroutes/LeadManagement/utils';

type getItemApplicationCategories = {
  stage: string;
  count: number;
};

const EnrolmentLeadsWidget: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const entities = useSelector((state: RootState) => state.entities.allEntities);
  const { t } = useTranslation(['enrollment']);

  const businessId = useSelector((state: RootState) => state.context.businessId);
  const applicationFlowType = entities[businessId ?? '']?.applicationFlowType;

  const TabItems =
    applicationFlowType === ApplicationFlowType.InquireOfferEnrollment ? NORMAL_FLOW_TABS : PROGRAM_FLOW_TABS;

  const [centerIds, setCenterIds] = useState<string[] | null>(null);

  const { data, loading: loadingStageAggregation } = useGetApplicationCategories({
    variables: {
      input: {
        centerIds: centerIds ? centerIds : [],
      },
      businessId: businessId ?? '',
    },
    skip: !Boolean(businessId),
  });

  function getStageTitle(item: getItemApplicationCategories) {
    return TabItems[item.stage];
  }

  function applicationStateColor(item: string) {
    switch (item) {
      case 'Accepted':
        return ApplicationStateColors?.ACCEPTED.colorName;
      case 'Completed':
        return ApplicationStateColors?.COMPLETED.colorName;
      case 'Lost':
        return ApplicationStateColors?.LOST.colorName;
      case 'Offered':
        return ApplicationStateColors?.OFFERED.colorName;
      case 'Waitlisted':
        return ApplicationStateColors?.ON_WAITLIST.colorName;
      default:
        return ApplicationStateColors.NONE.colorName;
    }
  }

  const goLeadManagementAction = (stage: string) => {
    if (centerIds && centerIds?.length > 0) {
      dispatch(setCurrentCenter(centerIds[0]));
      localStorage.setItem(`${user?.email}-last-selected-centerId`, centerIds[0]);
    }
    history.push(`/enrollment/lead-management/${stage}`);
  };

  const combineWaitlistedApplications = (items: getItemApplicationCategories[]) => {
    const waitlistedApplications = items
      .filter((item) => item.stage === 'Waitlisted')
      .reduce((acc, curr) => acc + curr.count, 0);
    const combined = [{ stage: 'Waitlisted', count: waitlistedApplications }];
    return combined.concat(items.filter((item) => item.stage !== 'Waitlisted'));
  };

  return (
    <Card bodyClassName="px-6 py-0">
      <div className="d-flex align-items-center mt-4 mb-n2">
        <h5 className="mr-auto mb-2 mr-2">{t('enrollment:enrollment-leads')}</h5>
        <div className="ml-auto d-flex align-items-center flex-wrap justify-content-end ">
          <MultipleCenterSelect
            useNullForAllOption={true}
            selectedCenterIds={centerIds}
            onSelect={(ids) => setCenterIds(!ids?.length ? null : ids)}
            showLabel={false}
            className="flex-grow-0 w-240px mb-2"
          />
        </div>
      </div>
      <HorizontalDivider />
      <div className={'d-flex justify-content-between'}>
        {loadingStageAggregation && <Spinner large className="mx-auto my-3" />}
        {!loadingStageAggregation &&
          data?.getStageAggregation &&
          combineWaitlistedApplications(data.getStageAggregation).map((item) => {
            return item.stage !== 'Errored' &&
              !(isProgramBasedFlow(applicationFlowType) && item.stage === 'Offered') ? (
              <div className="p-4 text-center" key={item.stage}>
                <h5 className={'sans-serif text-' + applicationStateColor(item.stage)}>{getStageTitle(item)}</h5>
                <ButtonAsLink onClick={() => goLeadManagementAction(item.stage)}>
                  <div className={'h1 font-weight-light mb-0 text-break text-' + applicationStateColor(item.stage)}>
                    {item.count}
                  </div>
                </ButtonAsLink>
              </div>
            ) : null;
          })}
      </div>
    </Card>
  );
};

export default EnrolmentLeadsWidget;
