import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'shared/components/Layout';
import Checkbox from 'shared/components/Checkbox';
import KindyFundingSchedules from './KindyFundingSchedules';
import Card from 'shared/components/Card';
import EditDeclarationFormForm from '../EditProgramModal/EditDeclarationFormForm';
import EditSchoolExemptionForm from '../EditProgramModal/EditSchoolExemptionForm';
import Select from 'shared/components/Select';
import { startStrongSubsidySchemeId } from 'pages/Subsidies/KindyFunding/utils';

interface IProps {
  accountChild: IAccountChild;
  accountId: string;
  programChild?: IProgramChild | undefined;
  initialFormData: IStartStrongChildInformationForm | undefined;
  programStartDate: string;
  handleChange: (returnData: IStartStrongChildInformationForm) => void;
}

const StartStrongChildInformation: React.FC<IProps> = ({
  accountChild,
  accountId,
  programChild,
  initialFormData,
  programStartDate,
  handleChange,
}) => {
  const { t } = useTranslation(['subsidies']);

  const [childFormData, setChildFormData] = useState<IStartStrongChildForm>(
    initialFormData?.startStrongChild ?? {
      childId: accountChild.id,
      child: accountChild,
      nonEnglishSpeakingBackground: false,
      startStrongConsent: 'None',
      excludeStartStrongFunding: false,
      hasSchoolExemptionCertificate: false,
      declarationForm: undefined,
      schoolExemption: undefined,
    }
  );

  const [childProgramSchedulesFormData, setChildProgramSchedulesFormData] =
    useState<IKindyProgramChildFundingSchedulesInput>(
      initialFormData?.childProgramFundingSchedules ?? {
        programChildId: programChild?.id ?? '',
        schedules: [],
      }
    );

  const handleFormChange = (value: any, name: string) => {
    setChildFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const [declarationFormData, setDeclarationFormData] = useState<IStartStrongDeclarationForm | undefined>(
    initialFormData?.startStrongChild?.declarationForm
  );

  const [schoolExemptionData, setSchoolExemptionData] = useState<IStartStrongSchoolExemption | undefined>(
    initialFormData?.startStrongChild?.schoolExemption
  );

  const handleDeclarationForm = (value: any, name: string) => {
    setDeclarationFormData((state) => ({
      ...state,
      accountId: accountId,
      childId: accountChild.id,
      child: accountChild,
      [name]: value,
    }));
  };

  const handleSchoolExemption = (value: any, name: string) => {
    setSchoolExemptionData((state) => ({
      ...state,
      accountId: accountId,
      childId: accountChild.id,
      child: accountChild,
      [name]: value,
    }));
  };

  const handleChildSchedules = (value: IKindyProgramChildFundingSchedule[]) => {
    setChildProgramSchedulesFormData((state) => ({
      ...state,
      schedules: value,
    }));
  };

  useEffect(() => {
    handleChange({
      startStrongChild: {
        ...childFormData,
        declarationForm: declarationFormData,
        schoolExemption: schoolExemptionData,
      },
      childProgramFundingSchedules: childProgramSchedulesFormData,
    } as IStartStrongChildInformationForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childFormData, childProgramSchedulesFormData, declarationFormData, schoolExemptionData]);

  const startStrongConsentOptions = [
    {
      value: 'None',
      label: 'None',
    },
    {
      value: 'Partial',
      label: 'Collected, without consent for additional information (partially consent)',
    },
    {
      value: 'Full',
      label: 'Collected, with consent for additional information (full consent)',
    },
  ];

  /* TODO: Remove old enum / select options (Debt: https://dev.azure.com/kangarootime/K2.1/_workitems/edit/22223) */
  const handleConsentChange = (value: boolean) => {
    const consent = value ? 'Full' : 'None';

    setChildFormData((state) => ({
      ...state,
      startStrongConsent: consent,
    }));
  };

  return (
    <div>
      <Row className="mt-2">
        <Col justify="start" className="d-flex pb-2">
          <Checkbox
            value={childFormData.nonEnglishSpeakingBackground}
            label={t('subsidies:program-child.create-modal.non-english-speaking-background')}
            onChange={(val: boolean) => {
              handleFormChange(val, 'nonEnglishSpeakingBackground');
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col justify="start" className="d-flex pb-2">
          {programStartDate < '2024-01-01' ? (
            <Select
              label={t('subsidies:program-child.create-modal.start-strong.declaration-form')}
              options={startStrongConsentOptions}
              value={childFormData.startStrongConsent}
              onChange={(val) => {
                handleFormChange(val.value, 'startStrongConsent');
              }}
            />
          ) : (
            <Checkbox
              value={childFormData.startStrongConsent === 'Full'}
              label={t('subsidies:program-child.create-modal.start-strong.declaration-form')}
              onChange={(val: boolean) => {
                handleConsentChange(val);
              }}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <EditDeclarationFormForm formData={declarationFormData} handleChange={handleDeclarationForm} />
        </Col>
      </Row>
      <Row>
        <Col justify="start" className="d-flex pb-2">
          <Checkbox
            value={childFormData.excludeStartStrongFunding}
            label={t('subsidies:program-child.create-modal.start-strong.alternate-service-funding')}
            onChange={(val: boolean) => {
              handleFormChange(val, 'excludeStartStrongFunding');
            }}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <EditSchoolExemptionForm
            childFormData={childFormData}
            formData={schoolExemptionData}
            handleChildChange={handleFormChange}
            handleChange={handleSchoolExemption}
          />
        </Col>
      </Row>
      <Card header={t('subsidies:automated-funding.child.title')}>
        {' '}
        <Row>
          <KindyFundingSchedules
            subsidySchemeId={startStrongSubsidySchemeId}
            formData={childProgramSchedulesFormData.schedules}
            handleChange={handleChildSchedules}
            key={accountChild.id}
          />
        </Row>
      </Card>
    </div>
  );
};

export default StartStrongChildInformation;
