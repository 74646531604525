import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { paymentMethodFields } from './fields';

//interfaces
interface ICreatePaymentMethodData {
  createPaymentMethod: IPaymentMethod;
}

interface ICreatePaymentMethodVariables {
  input: ICreatePaymentMethodInput;
}

interface ICreatePayrixCustomerForAccountData {
  createPayrixCustomerForAccount: string;
}

interface ICreatePayrixCustomerForAccountVariables {
  id: string;
}

interface IUpdatePaymentMethodData {
  updatePaymentMethod: IPaymentMethod;
}

interface IUpdatePaymentMethodVariables {
  input: IUpdatePaymentMethodInput;
}

interface IUpdateAccountDefaultPaymentMethodData {
  updateAccountDefaultPaymentMethod: IPaymentMethod;
}

interface IUpdateAccountDefaultPaymentMethodVariables {
  accountId: string;
  paymentMethodId: string;
}

interface IUpdateBusinessDefaultPaymentMethodData {
  updateBusinessDefaultPaymentMethod: IPaymentMethod;
}

interface IUpdateBusinessDefaultPaymentMethodVariables {
  businessId: string;
  paymentMethodId: string;
}

interface IDeletePaymentMethodData {
  deletePaymentMethod: IPaymentMethod;
}

interface IDeletePaymentMethodVariables {
  id: string;
  accountId: string;
}

interface IUpdateAccountAutopayPaymentMethodData {
  updateAccountAutoPayPaymentMethod: IPaymentMethod | null;
}

interface IUpdateAccountAutopayPaymentMethodVariables {
  accountId: string;
  paymentMethodId: string | null;
  termsAndConditionsConfirmed: boolean | null;
}

//mutations
export const CREATE_PAYMENT_METHOD = (fields: string = paymentMethodFields) =>
  gql` mutation ($input: CreatePaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      ${fields}
    }
  }`;

export const CREATE_PAYRIX_CUSTOMER_FOR_ACCOUNT = () => gql`
  mutation ($id: ID!) {
    createPayrixCustomerForAccount(id: $id)
  }
`;

export const UPDATE_PAYMENT_METHOD = (fields: string = paymentMethodFields) => gql`
  mutation ($input: UpdatePayMethodInput!) {
    updatePayMethod(input: $input) {
      ${fields}
    }
  }
`;

export const SET_ACCOUNT_DEFAULT_PAYMENT_METHOD = (fields: string = paymentMethodFields) => gql`
  mutation($accountId: ID!, $paymentMethodId: ID!) {
    updateAccountDefaultPaymentMethod(accountId: $accountId, paymentMethodId: $paymentMethodId) {
      ${fields}
    }
  }
`;

export const SET_BUSINESS_DEFAULT_PAYMENT_METHOD = (fields: string = paymentMethodFields) => gql`
  mutation($businessId: ID!, $paymentMethodId: ID!) {
    updateBusinessDefaultPaymentMethod(businessId: $businessId, paymentMethodId: $paymentMethodId) {
      ${fields}
    }
  }
`;

export const DELETE_PAYMENT_METHOD = (fields: string = paymentMethodFields) => gql`
  mutation($id: ID!, $accountId: ID!) {
    deletePaymentMethod(id: $id, accountId: $accountId) {
      ${fields}
    }
  }
`;

export const SET_ACCOUNT_AUTOPAY_PAYMENT_METHOD = (fields: string = paymentMethodFields) => gql`
  mutation($accountId: ID!, $paymentMethodId: ID, $termsAndConditionsConfirmed: Boolean) {
    updateAccountAutoPayPaymentMethod(accountId: $accountId, paymentMethodId: $paymentMethodId, termsAndConditionsConfirmed: $termsAndConditionsConfirmed) {
      ${fields}
    }
  }
`;

//hooks
export const useCreatePaymentMethod = (
  options?: MutationHookOptions<ICreatePaymentMethodData, ICreatePaymentMethodVariables>,
  fields?: string
) =>
  useMutation<ICreatePaymentMethodData, ICreatePaymentMethodVariables>(CREATE_PAYMENT_METHOD(fields), {
    ...options,
  });

export const useCreatePayrixCustomerForAccount = (
  options?: MutationHookOptions<ICreatePayrixCustomerForAccountData, ICreatePayrixCustomerForAccountVariables>
) =>
  useMutation<ICreatePayrixCustomerForAccountData, ICreatePayrixCustomerForAccountVariables>(
    CREATE_PAYRIX_CUSTOMER_FOR_ACCOUNT(),
    {
      ...options,
    }
  );

export const useUpdatePaymentMethod = (
  options?: MutationHookOptions<IUpdatePaymentMethodData, IUpdatePaymentMethodVariables>,
  fields?: string
) =>
  useMutation<IUpdatePaymentMethodData, IUpdatePaymentMethodVariables>(UPDATE_PAYMENT_METHOD(fields), {
    ...options,
  });

export const useSetAccountPrimaryPaymentMethod = (
  options?: MutationHookOptions<IUpdateAccountDefaultPaymentMethodData, IUpdateAccountDefaultPaymentMethodVariables>,
  fields?: string
) =>
  useMutation<IUpdateAccountDefaultPaymentMethodData, IUpdateAccountDefaultPaymentMethodVariables>(
    SET_ACCOUNT_DEFAULT_PAYMENT_METHOD(fields),
    {
      ...options,
    }
  );

export const useSetBusinessPrimaryPaymentMethod = (
  options?: MutationHookOptions<IUpdateBusinessDefaultPaymentMethodData, IUpdateBusinessDefaultPaymentMethodVariables>,
  fields?: string
) =>
  useMutation<IUpdateBusinessDefaultPaymentMethodData, IUpdateBusinessDefaultPaymentMethodVariables>(
    SET_BUSINESS_DEFAULT_PAYMENT_METHOD(fields),
    {
      ...options,
    }
  );

export const useDeletePaymentMethod = (
  options?: MutationHookOptions<IDeletePaymentMethodData, IDeletePaymentMethodVariables>,
  fields?: string
) =>
  useMutation<IDeletePaymentMethodData, IDeletePaymentMethodVariables>(DELETE_PAYMENT_METHOD(fields), {
    ...options,
  });

export const useUpdateAccountAutopayPaymentMethod = (
  options?: MutationHookOptions<IUpdateAccountAutopayPaymentMethodData, IUpdateAccountAutopayPaymentMethodVariables>,
  fields?: string
) =>
  useMutation<IUpdateAccountAutopayPaymentMethodData, IUpdateAccountAutopayPaymentMethodVariables>(
    SET_ACCOUNT_AUTOPAY_PAYMENT_METHOD(fields),
    {
      ...options,
    }
  );
