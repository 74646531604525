import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '_colors.module.scss';
import _ from 'lodash';
import { ButtonAsLink } from 'shared/components/Buttons';
import { FormattedEnrolmentDiffAreas, useEnrolmentFormDiffValueFormatter } from './useEnrolmentFormDiffValueFormatter';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { showToast } from 'shared/components/Toast';
import { DiffState, EnrollmentDiff, useGetEnrolmentFormDiffQuery } from 'generated/graphql';
import Spinner from 'shared/components/Spinner';

interface IProps {
  enrolmentFormId: string;
  showNoChangesText?: boolean;
  handleOnClick: (formattedData: FormattedEnrolmentDiffAreas) => void;
  alternativeTitle?: string;
}

const FormDiffLink: React.FC<IProps> = ({
  enrolmentFormId,
  handleOnClick,
  showNoChangesText = false,
  alternativeTitle,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const [enrolmentDiffData, setErolmentDiffData] = useState<EnrollmentDiff | undefined>();
  const formattedData = useEnrolmentFormDiffValueFormatter(enrolmentDiffData);

  const { data, loading, error } = useGetEnrolmentFormDiffQuery({
    skip:
      businessId === null ||
      businessId === undefined ||
      businessId === '' ||
      enrolmentFormId == '' ||
      enrolmentFormId == null ||
      enrolmentFormId == undefined,
    variables: {
      businessId: businessId ?? '',
      enrolmentFormId: enrolmentFormId,
    },
    onError: () => {
      showToast('Unable to get form difference data', 'error');
    },
  });

  useEffect(() => {
    if (data?.getEnrolmentFormDiff) {
      setErolmentDiffData(data?.getEnrolmentFormDiff);
    }
  }, [data?.getEnrolmentFormDiff]);

  return (
    <>
      {loading && <Spinner small />}
      {!loading && !error && formattedData !== undefined && formattedData?.diffState !== DiffState.Unchanged && (
        <ButtonAsLink
          className="mr-4 mb-0 d-block text-primary text-primary-hover"
          onClick={() => {
            handleOnClick(formattedData);
          }}
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mr-2" color={colors.primary} />
          {alternativeTitle ?? t('enrollment:enrolment-form-diffs.view-form')}
        </ButtonAsLink>
      )}
      {showNoChangesText &&
        !loading &&
        !error &&
        ((formattedData !== undefined && formattedData?.diffState === DiffState.Unchanged) ||
          enrolmentFormId === '') && <span>{t('enrollment:enrolment-form-diffs.no-changes')}</span>}
    </>
  );
};

export default FormDiffLink;
