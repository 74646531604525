import React, { useCallback, useState } from 'react';
import Button from 'shared/components/Buttons';
import Checkbox from 'shared/components/Checkbox';

import ISCaseChildrenTable from './ISCaseChildrenTable';
import ISCaseDetailsTable from './ISCaseDetailsTable';
import ISCaseMaxHoursTable from './ISCaseMaxHoursTable';

import { Row, Col } from 'react-bootstrap';
import ISCaseRawDetails from '../ISCaseRawDetails';
import moment from 'moment';
import { useToggleCaseClaimWeekStatus } from 'gql/iSClaim/mutations';
import { showToast } from 'shared/components/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from 'shared/components/Tooltip';
import './../../isCases.scss';

interface IProps {
  data: IISCase;
  businessId: string;
  handleCreateClaim: (data: IISCase) => void;
  refetchCases: () => void;
}

const ISCaseDetailsRow: React.FC<IProps> = ({ data, businessId, handleCreateClaim, refetchCases }) => {
  const [open, setOpen] = useState(false);
  const [excludedWeeks, setExcludedWeeks] = useState(data.excludedWeeks);

  const [toggleIsCaseClaimWeekStatus, { loading }] = useToggleCaseClaimWeekStatus();

  const checkUnsubmittedWeekArray = (week: string, momentDate: moment.Moment) => {
    const equalWeek = excludedWeeks.find(
      (item) => item.weekNumber === momentDate.week() && item.year === momentDate.year()
    );
    if (!!equalWeek) {
      setExcludedWeeks(excludedWeeks.filter((item) => item !== equalWeek));
    } else {
      setExcludedWeeks([...excludedWeeks, { weekNumber: momentDate.week(), year: momentDate.year() }]);
    }
  };

  const toggleUnsubmittedWeek = useCallback(
    (week: string, momentDate: moment.Moment) => {
      toggleIsCaseClaimWeekStatus({
        variables: {
          input: {
            businessId,
            centerId: data.centerId,
            caseId: data.id,
            weekNumber: momentDate.week(),
            year: momentDate.year(),
          },
        },
      })
        .then((data) => setExcludedWeeks(data.data?.toggleIsCaseClaimWeekStatus || []))
        .catch((err) => showToast(err.toString(), 'error'))
        .finally(() => refetchCases());
    },
    [data]
  );

  const isWeekExcluded = useCallback(
    (week: string) => {
      return excludedWeeks.some((item) => moment(week).week() === item.weekNumber && moment(week).year() === item.year);
    },
    [excludedWeeks]
  );

  return (
    <>
      <Row className="is-case-details-row">
        <Col lg={4} md={6} xs={12} className="case-details-table-col">
          <ISCaseDetailsTable data={data} />
        </Col>
        <Col lg={3} md={6} xs={12} className="children-table-col">
          <ISCaseChildrenTable data={data} />
        </Col>
        <div className="d-none d-md-block">
          <div className="vertical-rule "></div>
        </div>
        <Col lg={2} md={6} xs={12} className="maximum-hours-container mt-md-4 mt-sm-4 mt-lg-0">
          <ISCaseMaxHoursTable data={data} />
        </Col>
        <Col lg={2} md={6} xs={12} className="text-center" style={{ paddingRight: 0 }}>
          <h6 className="font-weight-normal mb-4 mt-1 ">
            Unsubmitted Weeks
            <Tooltip text="Check the box(es) to indicate not claiming IS for the week(s)" direction="left">
              <FontAwesomeIcon icon={faExclamationCircle} className="ml-2" />
            </Tooltip>
          </h6>

          {data.unsubmittedWeeks.length > 0 ? (
            data.unsubmittedWeeks.map((week) => (
              <div key={week} className="d-flex flex-row justify-content-center">
                <Checkbox
                  label=""
                  value={isWeekExcluded(week)}
                  onChange={() => {
                    checkUnsubmittedWeekArray(week, moment(week));
                    toggleUnsubmittedWeek(week, moment(week));
                  }}
                  disabled={loading}
                />

                <span className={isWeekExcluded(week) ? 'excluded-case-claim' : ''}>
                  {moment(week).format('DD/MM/YYYY')}
                </span>
              </div>
            ))
          ) : (
            <div>None</div>
          )}
        </Col>
      </Row>
      <hr />
      <div className="position-relative more-details-container font-weight-semi-bold">
        {
          <p className="more-details-link" onClick={() => setOpen(!open)}>
            {open ? 'Show Less' : 'More details'}
          </p>
        }
        <Button variant="secondary" onClick={() => handleCreateClaim(data)} className="create-claim-button">
          {' '}
          Create Claim{' '}
        </Button>
      </div>
      <ISCaseRawDetails data={data} open={open} />
    </>
  );
};

export default ISCaseDetailsRow;
