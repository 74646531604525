import React, { useCallback, useEffect, useState } from 'react';
import useDatatableState from 'shared/hooks/useDatatableState2';
import DocumentsTable from './components/DocumentsTable';
import { useGetAccountDocuments } from 'gql/document/queries';
import cast from 'shared/util/cast';
import customEvents from 'shared/constants/customEvents';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface IProps {
  account: IAccount;
  loading: boolean;
}

export interface ITableFilters {
  searchText: string;
  showArchived: boolean;
  type: string | null;
  person: string | null;
}

const DocumentsTab: React.FC<IProps> = ({ account }) => {
  const [tableState, tableFn] = useDatatableState();
  const [sort, setSort] = useState<ISearchSort[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const timezone =
    useSelector((state: RootState) => state.timezone?.byCenterId[account?.centerId]) ?? moment.tz.guess();
  const [filters, setFilters] = useState<ITableFilters>({
    searchText: '',
    showArchived: false,
    type: null,
    person: null,
  });

  const { data, loading, refetch } = useGetAccountDocuments({
    variables: {
      input: {
        accountId: account?.id,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        showArchived: filters.showArchived,
        searchText: filters.searchText,
        associatedEntityId: filters.person ?? undefined,
        type: filters.type ?? undefined,
        sort,
      },
    },
  });
  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSort([{ field, direction }]);
  };
  const personOptions = [
    ...((account?.contacts as IPerson[]) ?? []),
    ...(account?.children?.filter((c) => c.archivedAt === null).map((c) => cast<IPerson>(c)) ?? []),
    ...[{ id: account?.id, firstname: `${account?.name} (Account)` } as IPerson],
  ];

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    document.addEventListener(customEvents.REFETCH_ACCOUNT_DOCUMENTS, handleRefetch);
    return () => {
      document.removeEventListener(customEvents.REFETCH_ACCOUNT_DOCUMENTS, handleRefetch);
    };
  }, [handleRefetch]);

  return (
    <DocumentsTable
      tableFn={tableFn}
      tableState={tableState}
      totalRecords={data?.getAccountDocuments.totalRecords ?? 0}
      persons={personOptions || []}
      filters={filters}
      data={data?.getAccountDocuments?.data ?? []}
      setFilters={setFilters}
      loading={loading}
      onSort={handleSort}
      timezone={timezone}
    />
  );
};

export default DocumentsTab;
