import React, { useMemo } from 'react';
import { IconButton } from 'shared/components/Buttons';
import { DateRangeInputWithArrows } from 'shared/components/DateInput';
import AccountSelect from 'shared/components/Select/AccountSelect';
import ChildSelect from 'shared/components/Select/ChildSelect';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import { appendClassName } from 'shared/util/classNameHelpers';

// Styles
import './LineItems.scss';
import { Column, IDisplayableAgencyPayment } from '../useManageAgencyPayment';
import Avatar from '../../../../shared/components/Avatar';
import { stringToHueDegree } from '../../../../shared/util/string';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

export interface DisplayLineItem {
  id: string | null; // Account Id or Child Id depending on payment format

  name: string;
  initials: string;
  secondaryText: string | null;
  avatarUrl: string | null;

  /**
   * amounts should be keyed on the date they are being applied to
   * the value is the amount for that time period
   */
  amounts: { [key: number]: { id: string | null; amount: number; isDirty: boolean } };
  isDeleted: boolean;
}

interface LineItemsProps {
  agencyPayment: IDisplayableAgencyPayment | undefined;
  dateColumns: Column[];
  lineItems: DisplayLineItem[];
  /**
   * fires when an account selection has been changed
   * @param previousAccountId the previous accountId for the row
   * @param newAccountId the updated accountId for the row
   * @returns
   */
  onAccountSelection?: (account: IAccount | null) => void;
  onChildSelection?: (child: IAccountChild | null) => void;
  onAmountChange?: (id: string, dateKey: string, amount: number | undefined) => void;
  onDateColumnChange?: (key: string, startDate: string | null, endDate: string | null) => void;
  onRemoveLineItem?: (id: string) => void;
}
export default function LineItems({
  agencyPayment,
  dateColumns,
  lineItems,
  onAccountSelection = () => {},
  onChildSelection = () => {},
  onAmountChange = () => {},
  onDateColumnChange = () => {},
  onRemoveLineItem = () => {},
}: LineItemsProps) {
  const accountIdsToExcludeFromSelect = useMemo(() => {
    const toExclude: string[] = [];
    for (let li of lineItems) {
      if (li.id !== null && !li.isDeleted) toExclude.push(li.id);
    }
    return toExclude;
  }, [lineItems]);
  const childIdsToExcludeFromSelect = useMemo(() => {
    const toExclude: string[] = [];
    for (let li of lineItems) {
      if (li.id !== null && !li.isDeleted) toExclude.push(li.id);
    }
    return toExclude;
  }, [lineItems]);

  return (
    <div>
      <LineItemRow className="line-items-header">
        <LineItemCell>
          <div className="account-label">{agencyPayment?.paymentFormat === 'Child' ? 'Child' : 'Account'} Name</div>
        </LineItemCell>
        {dateColumns.map((c, index) => (
          <LineItemCell key={index} index={index}>
            <DateRangeInputWithArrows
              startDate={c.startDate}
              endDate={c.endDate}
              dateOnly={true}
              onChange={(dates) => onDateColumnChange(c.id, dates.startDate, dates.endDate)}
            />
          </LineItemCell>
        ))}
        <LineItemCell className="spacer">
          <div></div>
        </LineItemCell>
      </LineItemRow>
      {lineItems
        .filter((li) => !li.isDeleted)
        .map((li, index) => {
          return (
            <LineItemRow key={index}>
              <LineItemCell className="line-item-cell-account">
                <div className={`d-flex align-items-center px-2 py-1`}>
                  <Avatar
                    color={`hsl(${stringToHueDegree(li.name)}, ${
                      stringToHueDegree(li.name) < 50 ? '100%' : '40%'
                    }, 40%`}
                    size={'sm'}
                    initials={li.initials}
                    image={li.avatarUrl ?? ''}
                  />
                  <div className="d-flex flex-column pl-4 text-truncate">
                    <span className="text-truncate">{li.name}</span>
                    <small>{li.secondaryText}</small>
                  </div>
                </div>
              </LineItemCell>
              {dateColumns.map((d, index) => {
                const amount = li.amounts[d.id] ? li.amounts[d.id].amount ?? null : null;
                return (
                  <LineItemCell key={index} index={index}>
                    <CurrencyInput
                      value={amount}
                      className="line-item-amount"
                      disabled={!li.id || !d.startDate || !d.endDate}
                      onChange={(amount) => onAmountChange(li.id ?? '', d.id, amount)}
                    ></CurrencyInput>
                  </LineItemCell>
                );
              })}
              <LineItemCell className="row-action">
                <IconButton
                  className="mr-2"
                  icon={faTrashAlt}
                  onClick={() => onRemoveLineItem(li.id ?? '')}
                  disabled={!li.id}
                />
              </LineItemCell>
            </LineItemRow>
          );
        })}
      <LineItemRow key="new">
        <LineItemCell>
          {agencyPayment?.paymentFormat === 'Account' && (
            <AccountSelect
              isMulti={false}
              centerIds={agencyPayment?.centers ? agencyPayment.centers.map((c) => c.centerId) : []}
              excludedAccountIds={accountIdsToExcludeFromSelect}
              selectedAccountIds={[]}
              /*onSelect={(accountIds) => onAccountSelection(accountIds ? accountIds[0] : accountIds)}*/
              onSelect={() => {}}
              onSelectAccounts={(accounts) => onAccountSelection(accounts ? accounts[0] : accounts)}
              appearance="detailed"
              showAllAccountsOption={false}
              hideLabel
            ></AccountSelect>
          )}
          {agencyPayment?.paymentFormat === 'Child' && (
            <ChildSelect
              isMulti={false}
              centerIds={agencyPayment?.centers ? agencyPayment.centers.map((c) => c.centerId) : []}
              excludedChildIds={childIdsToExcludeFromSelect}
              selectedChildIds={[]}
              onSelect={() => {}}
              onSelectChildren={(children) => {
                onChildSelection(children ? children[0] : children);
              }}
              appearance="detailed"
              showAllChildrenOption={false}
              showChildStatus={true}
              hideLabel
            ></ChildSelect>
          )}
        </LineItemCell>
        {dateColumns.map((d, index) => {
          const amount = null;
          return (
            <LineItemCell key={index} index={index}>
              <CurrencyInput
                value={amount}
                className="line-item-amount"
                disabled={true}
                onChange={() => {}}
              ></CurrencyInput>
            </LineItemCell>
          );
        })}
        <LineItemCell className="row-action">
          <IconButton className="mr-2" icon={faTrashAlt} onClick={() => {}} disabled={true} />
        </LineItemCell>
      </LineItemRow>
    </div>
  );
}

function LineItemCell({
  children,
  index,
  className,
}: {
  children?: any | any[];
  /**
   * index used for determing if it is odd vs even for color differentiation
   */
  index?: number;
  className?: string;
}) {
  const additionalClassName = index !== undefined ? (isEven(index) ? 'even' : 'odd') : '';
  const classNameFinal = appendClassName('line-item-cell', additionalClassName, className ?? '');
  return <div className={classNameFinal}>{children}</div>;
}

function LineItemRow({ children, className }: { className?: string; children: any }) {
  return <div className={appendClassName('line-item-row', className ?? '')}>{children}</div>;
}

function isEven(index: number) {
  return index % 2 === 0;
}
