import React, { useEffect, useMemo, useState } from 'react';
import { isRegion } from 'shared/util/region';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Select from 'shared/components/Select';
import absenceTypes, { IAbsenceTypeOption } from 'shared/constants/dropdownOptions/absenceTypes';
import absenceReasons, { additionalAbsenceReasons } from 'shared/constants/dropdownOptions/absenceReasons';
import { useReportAbsence, useReportAdditionalAbsence, useReportBulkAdditionalAbsence } from 'gql/session/mutations';
import { reportAbsence } from '../duck/actions';
import { showToast } from 'shared/components/Toast';
import { useDispatch } from 'react-redux';
import { ApolloError } from '@apollo/client';
import Checkbox from 'shared/components/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-light-svg-icons';
import { Row } from 'shared/components/Layout';
import { uniq } from 'lodash';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import {
  useReportAdditionalAbsenceNewMutation,
  AbsenceReason as AbsenceReasonGql,
  useReportBulkAdditionalAbsenceNewMutation,
} from 'generated/graphql';

/**
 * Function used to get the absence reasons to display.
 * @param absenceType This value determines if the additional absence reasons should be used.
 * @returns List of `OptionsType<t>` shaped objects, where t is a string literal type.
 */
function getAbsenceReasonOptions(absenceType?: AbsenceType) {
  if (absenceType === 'ADDITIONAL') return additionalAbsenceReasons;
  return absenceReasons;
}

interface ISendAbsenceReportInput {
  absenceType?: AbsenceType;
  reason: string;
  date: string;
  sessionId?: string;
  contractId?: string;
  accountChildId?: string;
  absenceDocumentHeld?: boolean;
  isMultiple?: boolean;
  multipleData?: IReportAbsenceItem[];
}

const useSendAbsenceReport = (absenceType?: AbsenceType) => {
  const [reportAbsenceFn, { loading: reportAbsenceLoading }] = useReportAbsence();
  const [reportAdditionalAbsenceNewMutation, { loading: reportAdditionalAbsenceNewLoading }] =
    useReportAdditionalAbsenceNewMutation();
  const [reportBulkAdditionalAbsenceNewMutation, { loading: reportBulkAdditionalAbsenceNewLoading }] =
    useReportBulkAdditionalAbsenceNewMutation();

  return [
    async (input: ISendAbsenceReportInput) => {
      if (absenceType === 'ADDITIONAL')
        if (input.isMultiple) {
          return reportBulkAdditionalAbsenceNewMutation({
            variables: {
              input: {
                absenceInfo: {
                  sessionIds: uniq(
                    input.multipleData
                      ?.filter((item) => !item.sessionId?.match(/contract-/))
                      .map((item) => item.sessionId ?? '') || []
                  ),
                  contractsForDates: {
                    absentContractIds: uniq(input.multipleData?.map((item) => item.contractId ?? '') || []),
                    absentDates: [input.date],
                  },
                },
                reason: input.reason as AbsenceReasonGql,
                absenceDocumentHeld: input.absenceDocumentHeld,
              },
            },
          }).then((data) => {
            return data.data?.reportBulkAdditionalAbsenceNew.map((c) => {
              return {
                id: c.id ?? '',
                sessionId: c.sessionId ?? '',
                accountChildId: c.accountChildId ?? '',
                contractId: c.contractId ?? '',
                type: c.type as AbsenceType,
                reason: c.reason as AbsenceReason,
                date: c.date ?? '',
                waiveGapFee: c.waiveGapFee ?? false,
              };
            });
          });
        } else {
          return reportAdditionalAbsenceNewMutation({
            variables: {
              input: {
                absenceInfo: {
                  sessionId: input.sessionId,
                  contractForDates: input.contractId
                    ? {
                        absentContractId: input.contractId,
                        absentDates: [input.date],
                      }
                    : undefined,
                },
                reason: input.reason as AbsenceReasonGql,
                absenceDocumentHeld: input.absenceDocumentHeld,
              },
            },
          }).then((data) => {
            return data.data?.reportAdditionalAbsenceNew.map((c) => {
              return {
                id: c.id ?? '',
                sessionId: c.sessionId ?? '',
                accountChildId: c.accountChildId ?? '',
                contractId: c.contractId ?? '',
                type: c.type as AbsenceType,
                reason: c.reason as AbsenceReason,
                date: c.date ?? '',
                waiveGapFee: c.waiveGapFee ?? false,
              };
            });
          });
        }

      // Default case
      return reportAbsenceFn({
        variables: {
          input: {
            children: input.isMultiple
              ? input.multipleData?.map((item) => ({
                  absenceType: input.absenceType,
                  reason: input.reason as AbsenceReason,
                  date: input.date,
                  sessionId: !item.sessionId || item.sessionId.match(/contract-/) ? null : item.sessionId,
                  contractId: item.contractId,
                })) || []
              : [
                  {
                    absenceType: input.absenceType,
                    reason: input.reason as AbsenceReason,
                    date: input.date,
                    sessionId: input.sessionId,
                    contractId: input.contractId,
                  },
                ],
          },
        },
      }).then((data) => {
        // We throw the error so we can catch it in the component.
        if ((data.data?.reportAbsence.failed.length ?? 0) > 0)
          // since this modal only allows a single child we can assume there will only be one object
          throw new Error(`Failed to mark child absent: ${data.data?.reportAbsence.failed[0].reason!}`);
        return data.data?.reportAbsence.successful;
      });
    },
    { loading: reportAdditionalAbsenceNewLoading || reportAbsenceLoading || reportBulkAdditionalAbsenceNewLoading },
  ] as const;
};

interface IFormShape {
  absenceType?: AbsenceType;
  reason?: string;
  absenceDocumentHeld?: boolean;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  sessionId?: string | null;
  contractId?: string | null;
  accountChildId?: string | null;
  absenceDate: string;
  isMultiple: boolean;
  multipleData: IReportAbsenceItem[];
}

const ReportAbsentModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  onSuccess,
  sessionId,
  contractId,
  absenceDate,
  accountChildId,
  isMultiple,
  multipleData,
}) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<IFormShape>({});
  const handleChange = (value: IFormShape) => setFormData({ ...formData, ...value });

  // Clear all fields on close of the modal.
  useEffect(() => void (!isOpen && setFormData({})), [isOpen]);

  const absenceReasonOptions = getAbsenceReasonOptions(formData.absenceType);
  const [sendAbsenceReport, { loading }] = useSendAbsenceReport(formData.absenceType);

  const handleSubmit = () => {
    sendAbsenceReport({
      absenceType: formData.absenceType,
      reason: formData.reason! ?? 'SICK',
      date: absenceDate,
      absenceDocumentHeld: formData.absenceDocumentHeld,
      // Additional Absences
      accountChildId: accountChildId as string,
      // Default Absences
      sessionId: (sessionId // don't provide the fake id the gql layer adds for an expected session
        ? sessionId.match(/contract-/)
          ? null
          : sessionId
        : null) as string,
      contractId: contractId as string,
      isMultiple,
      multipleData,
    })
      .then((data) => {
        dispatch(reportAbsence(data!));
        onSuccess();
        onClose();
        showToast('Absence reported successfully.', 'success');
      })
      .catch((error: Error) => {
        const message = error instanceof ApolloError ? getApolloErrorMessage(error) : error.toString();
        showToast(message, 'error');
      });
  };

  const requiresEvidence = useMemo(() => {
    if (isRegion('AU') && formData.absenceType === 'ADDITIONAL') return true;
    return false;
  }, [formData.absenceType]);

  const showReasonSelection = isRegion('US') || requiresEvidence;

  const isValid = useMemo(() => {
    if (!formData.reason && showReasonSelection) return false;
    if (requiresEvidence && !formData.absenceDocumentHeld) return false;
    return true;
  }, [formData.absenceDocumentHeld, formData.reason, requiresEvidence]);

  return (
    <CenteredModal
      title="Mark Absent"
      show={isOpen}
      onHide={onClose}
      primaryCallback={handleSubmit}
      secondaryCallback={onClose}
      primaryButtonProps={{
        disabled: !isValid,
        loading: loading,
      }}
    >
      {isRegion('AU') && (
        <Select
          label="Absence Type"
          value={formData.absenceType}
          onChange={(option: IAbsenceTypeOption) => handleChange({ absenceType: option.value })}
          options={absenceTypes}
        />
      )}

      {showReasonSelection && (
        <Select
          label="Reason for Absence"
          // reason is only required for US
          required={showReasonSelection}
          value={formData.reason}
          onChange={(option: any) => handleChange({ reason: option.value })}
          options={absenceReasonOptions}
        />
      )}

      {requiresEvidence && (
        <Row className="pl-2">
          <Checkbox
            label="Is evidence held?"
            value={formData.absenceDocumentHeld}
            onChange={(value) => handleChange({ absenceDocumentHeld: value })}
          />
          <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
        </Row>
      )}
    </CenteredModal>
  );
};

export default ReportAbsentModal;
