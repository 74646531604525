import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { orderBy } from 'lodash';
import { PersonAvatar } from 'shared/components/Avatar';
import TimeEntryBlock from './TimeEntryBlock';
import classnames from 'classnames';
import { getFullName } from 'shared/util/string';
import Tooltip from 'shared/components/Tooltip';
import TimeOffBlock from './TimeOffBlock';
import { DailyAttendanceWithPerson } from '../TimelogsTab/TimelogsTab';
import { isRegion } from 'shared/util/region';
import { TrainingHolidayEvent } from '../TrainingTimeEventModal';

const HOUR_TABLE_WIDTH = 72;

interface IProps {
  data: DailyAttendanceWithPerson;
  totalHoursInView: number;
  openTime: moment.Moment;
  closeTime: moment.Moment;
  centerTimezone: string;
  centerId: string;
}

const TimeEntryRow: React.FC<IProps> = ({ data, totalHoursInView, openTime, closeTime, centerId, centerTimezone }) => {
  const hasTimeEntries = (data.attendance?.timeEntries ?? []).length > 0;
  const hasTrainingTimeEvents = (data.attendance?.trainingTimeEvents ?? []).length > 0;
  const hasExtraTableRowHeight = hasTimeEntries && hasTrainingTimeEvents; // used to determine if the table row needs to be a greater height and to apply a top offset to any time entry blocks
  const { attendance, timeOffRequest } = data;

  const getHourDifferenceBetweenTimes = useCallback(
    (startTime: string, endTime: string, timezone: Timezone): number => {
      const formattedStartTime = momentTz(startTime).tz(timezone); // important to format times so both are using same time zone
      const formattedEndTimeIn = momentTz(endTime).tz(timezone);
      return formattedEndTimeIn.diff(formattedStartTime, 'minutes') / 60;
    },
    []
  );

  const getHoursDuration = useCallback(
    (startTime: string, endTime: string | null): number => {
      const formattedTimeIn = momentTz(startTime).tz(centerTimezone).format();
      const formattedTimeOut = endTime
        ? momentTz(endTime).tz(centerTimezone).format()
        : momentTz().tz(centerTimezone).format();

      if (momentTz(formattedTimeIn).tz(centerTimezone).isAfter(formattedTimeOut)) {
        return 0;
      }

      return momentTz(formattedTimeOut).tz(centerTimezone).diff(formattedTimeIn, 'minutes') / 60;
    },
    [centerTimezone]
  );

  const getTimeEntryHoursFromStartTime = useCallback(
    (timeEntry: ITimeEntry) => {
      const formattedOpenTime = momentTz(openTime).tz(centerTimezone); // important to format times so both are using same time zone
      const formattedTimeIn = momentTz(timeEntry.trackedTimeIn).tz(centerTimezone);
      return formattedTimeIn.diff(formattedOpenTime, 'minutes') / 60;
    },
    [openTime, centerTimezone]
  );

  const getTimeOffHoursFromStartTime = useCallback(
    (timeOff: ITimeOff) => {
      const formattedOpenTime = momentTz(openTime).tz(centerTimezone); // important to format times so both are using same time zone
      const formattedTimeIn = timeOff.allDay
        ? momentTz(openTime).tz(centerTimezone)
        : momentTz(timeOff.startTime).tz(centerTimezone);

      return formattedTimeIn.diff(formattedOpenTime, 'minutes') / 60;
    },
    [openTime, centerTimezone]
  );

  const lastClockOut =
    attendance && attendance.timeEntries.length ? attendance.timeEntries[attendance.timeEntries.length - 1] : null;
  const hasForceClockOut =
    lastClockOut && !lastClockOut.trackedTimeOut && momentTz().tz(centerTimezone).isAfter(closeTime);

  const workedLongerThanExpected =
    attendance !== null &&
    attendance.totalHoursWorked.hours * 60 + attendance.totalHoursWorked.minutes >
      attendance.totalHoursScheduled.hours * 60 + attendance.totalHoursScheduled.minutes;

  const timeWorkedTextClasses = classnames({
    primary: true,
    danger: hasForceClockOut,
    warning: !hasForceClockOut && workedLongerThanExpected,
  });

  const renderTimeEntryBlockComponent = useCallback(
    (timeEntry: ITimeEntry, key: string | number, subsequentTimeEntry: ITimeEntry | null): JSX.Element => {
      const timeEntryBlockComponent = (
        <TimeEntryBlock
          key={key}
          timeEntry={timeEntry}
          associatedShift={attendance?.shifts.find((s) => s.id === timeEntry.shiftId) ?? null}
          isForceClockOut={!timeEntry.trackedTimeOut && moment().isAfter(closeTime)}
          // if time entry is before start time, we want to give the inside text a margin so it is visible
          textLeftMargin={
            getTimeEntryHoursFromStartTime(timeEntry) < 0
              ? Math.abs(getTimeEntryHoursFromStartTime(timeEntry)) * HOUR_TABLE_WIDTH
              : undefined
          }
          style={{
            // each hour on the table has a width of 72
            left: `${(getTimeEntryHoursFromStartTime(timeEntry) / totalHoursInView) * 100}%`,
            width: `${(getHoursDuration(timeEntry.trackedTimeIn, timeEntry.trackedTimeOut) / totalHoursInView) * 100}%`,
            ...(hasExtraTableRowHeight && { top: '55%' }),
          }}
          centerTimezone={centerTimezone}
        />
      );

      let differenceBetweenTimeEntriesBlockComponent: JSX.Element | null = null;

      if (subsequentTimeEntry) {
        // since there is a following time entry we can assume the first one will have a time out
        const differenceInMinutesBetweenTimeEntries = momentTz(subsequentTimeEntry.trackedTimeIn)
          .tz(centerTimezone)
          .diff(momentTz(timeEntry.trackedTimeOut!).tz(centerTimezone), 'minutes');
        const timeDifferenceBlockLeftOffset = getHourDifferenceBetweenTimes(
          openTime.tz(centerTimezone).toISOString(),
          timeEntry.trackedTimeOut!,
          centerTimezone as Timezone
        );

        differenceBetweenTimeEntriesBlockComponent = (
          <Tooltip direction="top" text={`${differenceInMinutesBetweenTimeEntries} minutes`}>
            <div
              className="time-entry break"
              style={{
                left: `${(timeDifferenceBlockLeftOffset / totalHoursInView) * 100}%`,
                width: `${(differenceInMinutesBetweenTimeEntries / 60 / totalHoursInView) * 100}%`,
                ...(hasExtraTableRowHeight && { top: '55%' }),
              }}
            />
          </Tooltip>
        );
      }

      return (
        <>
          {timeEntryBlockComponent}
          {differenceBetweenTimeEntriesBlockComponent}
        </>
      );
    },
    [
      attendance,
      closeTime,
      getTimeEntryHoursFromStartTime,
      totalHoursInView,
      getHoursDuration,
      centerTimezone,
      getHourDifferenceBetweenTimes,
      openTime,
      hasExtraTableRowHeight,
    ]
  );

  const renderTrainingTimeEventsBlockComponent = useCallback(
    (trainingTimeEvents: ITrainingHolidayEvent[]): JSX.Element => {
      const widthPerBlock = 100 / trainingTimeEvents.length;

      // left percentage should be the offset of the previous items' widths combined
      return (
        <>
          {trainingTimeEvents.map((te, idx) => {
            const event = new TrainingHolidayEvent(te);

            return (
              <div
                key={`training-time-event-${te.id}-${idx}`}
                className="time-entry training-time"
                style={{ left: `${widthPerBlock * idx}%`, width: `${widthPerBlock}%` }}
              >
                {`${event.hours} ${event.hours === 1 ? 'Hour' : 'Hours'} (${event.typeToString()})`}
              </div>
            );
          })}
        </>
      );
    },
    []
  );

  return (
    <tr>
      <td>
        <Link to={`/employees/time-log/time-card?personId=${data.person.id}&centerId=${centerId}`}>
          <div className="d-flex align-items-center pr-4">
            <PersonAvatar person={data.person} className="mr-2 " />
            <div className="text-overflow-ellipsis">{getFullName(data.person)}</div>
          </div>
        </Link>
      </td>
      <td
        colSpan={totalHoursInView * 40}
        className={classnames('position-relative', { 'double-row-height': hasExtraTableRowHeight })}
      >
        {(attendance?.trainingTimeEvents ?? []).length > 0 &&
          renderTrainingTimeEventsBlockComponent(attendance?.trainingTimeEvents ?? [])}
        {timeOffRequest && timeOffRequest.status === 'Approved' ? (
          <TimeOffBlock
            timeOff={timeOffRequest}
            centerTimezone={centerTimezone}
            style={{
              left: `${(getTimeOffHoursFromStartTime(timeOffRequest) / totalHoursInView) * 100}%`,
              width: `${
                (getHoursDuration(
                  timeOffRequest.allDay ? openTime.toISOString() : timeOffRequest.startTime,
                  timeOffRequest.allDay ? closeTime.toISOString() : timeOffRequest.endTime
                ) /
                  totalHoursInView) *
                100
              }%`,
            }}
          />
        ) : attendance ? (
          orderBy(attendance.timeEntries, (te) => te.trackedTimeIn, 'asc').map((timeEntry: ITimeEntry, i: number) =>
            timeEntry.archivedAt === null || timeEntry.clockedSelfIn
              ? renderTimeEntryBlockComponent(timeEntry, i, attendance.timeEntries[i + 1] ?? null)
              : null
          )
        ) : null}
      </td>
      <td colSpan={60} className="totals">
        <div className={timeWorkedTextClasses}>
          {(attendance?.trainingTimeEvents.reduce((total, trainingEvent) => (total = total + trainingEvent.hours), 0) ??
            0) + (attendance?.totalHoursWorked.hours ?? 0)}
          h {attendance?.totalHoursWorked.minutes ?? 0}m
        </div>
        <div className="secondary">
          {attendance?.totalHoursScheduled.hours ?? 0}h {attendance?.totalHoursScheduled.minutes ?? 0}m
        </div>
      </td>
    </tr>
  );
};

export default TimeEntryRow;
