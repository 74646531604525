import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { faEnvelope, faMinusCircle, faPencil, faPhoneAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import Card from 'shared/components/Card';
import { PersonAvatar } from 'shared/components/Avatar';
import ActionDropdown from 'shared/components/ActionDropdown';
import { Row } from 'shared/components/Layout';
import { getFullName, parsePhoneNumberWithRegion } from 'shared/util/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../Tooltip';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import cast from 'shared/util/cast';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {
  contact: IContact | IChildContact;
  className?: string;
  canRemove: boolean;
  showAdditionalActions?: boolean;
  relationshipsOnAccount?: [string, IContactChild[]][]; // this will only be populated on the Account Contacts tab
  onRemoveRelationship: () => void;
  onClickEdit: () => void;
  onRemovePrimary: () => void;
}

const PrimaryContactCard: React.FC<IProps> = ({
  contact,
  className,
  canRemove,
  showAdditionalActions = true,
  relationshipsOnAccount = [],
  onRemoveRelationship,
  onClickEdit,
  onRemovePrimary,
  ...props
}) => {
  const relationshipType = cast<IChildContact>(contact).relationshipType;

  const hasEditContactPermission = useHasRoleAreaLevel({
    area: AreaType.Contact,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const hasEditAccountPermission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  return (
    <Card
      className={`${className || ''}`}
      bodyClassName={'p-6'}
      header={
        <div className={classnames('d-flex font-weight-normal', { 'ml-10': showAdditionalActions })}>
          <div className="mx-auto mb-2">
            <div className="d-flex flex-column mx-auto">
              <PersonAvatar person={contact} size="xl" className="mb-4 mx-auto" />
              <Link to={`/families/contacts/${contact.id}`} className="mb-2 flex-nowrap text-center">
                <h5>{getFullName(contact)}</h5>
              </Link>
            </div>
            <Row noGutters className="flex-nowrap">
              <small className="bg-info-10 px-2 py-1 rounded border sm mx-auto mb-2 text-overflow-ellipsis">
                Permitted to Pick Up
              </small>
            </Row>
            <Row noGutters justify="center">
              {relationshipType ? (
                <div className="tag mx-1">{childContactRelationship[relationshipType]}</div>
              ) : (
                relationshipsOnAccount.map((r, i) => (
                  <Tooltip showTooltip key={i} text={r[1].map((cc) => getFullName(cc)).join(', ')} direction="top">
                    <div className="tag mx-1 mb-2">{childContactRelationship[r[0]]}</div>
                  </Tooltip>
                ))
              )}
            </Row>
          </div>
          {showAdditionalActions && (
            <ActionDropdown
              actions={[
                ...(hasEditContactPermission
                  ? [
                      {
                        label: `Edit ${relationshipType ? 'Relationship' : 'Relationships'}`,
                        onClick: onClickEdit,
                        icon: faPencil,
                      },
                    ]
                  : []),
                ...(canRemove && hasEditAccountPermission
                  ? [
                      {
                        label: 'Set as Non Primary',
                        onClick: () => onRemovePrimary(),
                        icon: faMinusCircle,
                      },
                      {
                        label: 'Remove Contact',
                        onClick: () => onRemoveRelationship(),
                        icon: faTrashAlt,
                      },
                    ]
                  : []),
              ]}
            />
          )}
        </div>
      }
    >
      <Row className="mb-2 flex-nowrap" noGutters>
        <FontAwesomeIcon icon={faPhoneAlt} className="mr-4 icon-lowered my-auto" />
        <small className="sm ">
          {contact.primaryPhoneNumber?.number
            ? parsePhoneNumberWithRegion(contact.primaryPhoneNumber?.number).formatNational()
            : ''}
        </small>
      </Row>
      <Row className="flex-nowrap" noGutters>
        <FontAwesomeIcon icon={faEnvelope} className="mr-4 icon-lowered my-auto " />
        <small className="sm text-overflow-ellipsis">{contact.email ?? ''}</small>
      </Row>
    </Card>
  );
};

export default PrimaryContactCard;
