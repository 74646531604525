import React from 'react';
import CenterInformationForm from '../Forms/CenterInformationForm';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import Card from 'shared/components/Card';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  center: ICenter | undefined;
  canPerformEdit: boolean;
  loading: boolean;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const CenterInformationCard: React.FC<IProps> = ({ center, canPerformEdit, loading }) => (
  <Card header={`${fieldLabels.center} Information`}>
    {loading || !center ? <LoadingLines /> : <CenterInformationForm center={center} canPerformEdit={canPerformEdit} />}
  </Card>
);

export default CenterInformationCard;
