import axios from 'axios';
import config from 'config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetBearerToken } from 'shared/hooks/user-context/useGetCurrentJwt';

interface SelectOption {
  value: string;
  label: string;
}

interface EnrollmentStatus {
  id: string;
  name: string;
}

interface useEnrollmentStatusMultiSelectFilterOutput {
  /**
   * options to be passed into our select component
   */
  options: SelectOption[];
  /**
   * Meant to be passed into our select component
   */
  selectedOptions: SelectOption[];
  /**
   * Basic handler a multi select onChange
   * @param options
   * @returns
   */
  handleSelectChange: (options) => void;
  /**
   * selectedStatusIds - useful for passing into api call for filtering.
   */
  selectedStatusIds: string[];
}

const DefaultSelections = ['FUTURE', 'ACTIVE', 'EXPIRED', 'EXPIRING_SOON'];

/**
 * Basic logic for dealing with an enrollment status filter. Use in conjunction a basic filter with no extra frills.
 * @note The selectedOptions returns full objects since most of our selectors are expecting full objects to be passed in.
 * @returns
 */
export function useEnrollmentStatusMultiSelectFilter(): useEnrollmentStatusMultiSelectFilterOutput {
  const { options } = useGetEnrollmentStatusOptions();
  const [selectedStatusIds, setSelectedStatuses] = useState<string[]>(DefaultSelections);
  const handleSelectChange = useCallback((options) => {
    setSelectedStatuses(options.map((o) => o.value));
  }, []);
  const selectedOptions = useMemo(
    () => options.filter((o) => selectedStatusIds.includes(o.value)),
    [options, selectedStatusIds]
  );
  return { options, selectedOptions, handleSelectChange, selectedStatusIds };
}

/**
 * Used to get a list of available status options for Agency Enrollments
 * Use this in conjunction with filters, or other select components
 */
export function useGetEnrollmentStatusOptions(): { options: SelectOption[]; fetchOptionsError: string | undefined } {
  const bearerToken = useGetBearerToken();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [fetchOptionsError, setFetchOptionsError] = useState<string | undefined>();
  useEffect(() => {
    loadEnrollmentStatuses(bearerToken)
      .then((statuses) => {
        setOptions(statuses.map(enrollmentStatusToSelectOption));
      })
      .catch((e) => setFetchOptionsError(e.message));
  }, [bearerToken]);
  return { options, fetchOptionsError };
}

async function loadEnrollmentStatuses(bearerToken: string): Promise<EnrollmentStatus[]> {
  const response = await axios.get(`${config.api.billing.uri}/api/v1/agency/enrollments/statuses`, {
    headers: { authorization: bearerToken },
  });
  return response.data.data;
}

function enrollmentStatusToSelectOption(status: EnrollmentStatus): SelectOption {
  return { value: status.id, label: status.name };
}
