import React, { useCallback, useEffect, useState } from 'react';
import PageWrapper from 'shared/components/PageWrapper';
import Button, { CreateButton, CirclePlusButton } from 'shared/components/Buttons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import SearchAndFilterBar from './components/SearchAndFilterBar';
import ReviewTimeOffModal from './components/Modals/ReviewTimeOffModal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Row, Col } from 'shared/components/Layout';
import WeekView from './components/WeeklySection/WeekView';
import MonthView from './components/MonthlySection/MonthView';
import cast from 'shared/util/cast';
import AddTimeOffModal from './components/Modals/AddTimeOffModal';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import useWindowSize from 'shared/hooks/useWindowSize';
import { useGetTimeOffForScope } from './grapgql/queries';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import Spinner from 'shared/components/Spinner';
import { showTimezoneToast } from 'shared/components/Toast';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { isTheSameTimezone } from '../helpers';

interface IProps {}

const fieldLables = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const EmployeeTimeOff: React.FC<IProps> = ({ ...props }) => {
  const user = useSelector((state: RootState) => state.user);
  const [scopeType, updateScopeType] = useState<ScopeType>(
    cast<ScopeType>(localStorage.getItem(`${user?.email}-timeOff-scopeType`)) || user?.roleship?.scopeType || 'ENTITY'
  );
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const [date, updateDate] = useState<string>(moment().toISOString());
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpFeature = Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.subFeatures ?? undefined;
  const AdpTimeEnabled = AdpFeature?.find((x) => x.type === 'TimeAndAttendance')?.enabled ?? false;
  const centerTimezone =
    useSelector((state: RootState) => state.timezone.byCenterId)[currentCenterId ?? ''] ?? moment.tz.guess();
  const businessTimezone =
    useSelector((state: RootState) => state.timezone.byBusinessId)[currentBusinessId ?? user?.entityId ?? ''] ??
    moment.tz.guess();

  const [isAddTimeOffModalOpen, setAddTimeOffModalOpen] = useState(false);
  const [selectedTimeOff, setSelectedTimeOff] = useState<ITimeOff | null>(null);
  const [filter, updateFilter] = useState({ search: '', status: 'All' });

  const userCanEditTimeOff = useHasRoleAreaLevel({
    area: AreaType.Schedule,
    permission: PermissionType.TimeOff,
    level: RoleLevelType.Edit,
  });
  // if user is reviewing from a center that is not the primary center, they cannot edit.
  const userCanEditSelectedTimeOff =
    userCanEditTimeOff && (scopeType !== 'CENTER' || currentCenterId === selectedTimeOff?.centerId);

  const windowSize = useWindowSize();
  const isMobile = useCallback(() => windowSize.innerWidth <= 768, [windowSize]);

  const { loading, networkStatus } = useGetTimeOffForScope({
    scopeType: scopeType,
    scopeId: scopeType === 'CENTER' ? currentCenterId ?? '' : currentBusinessId ?? '',
    startTime: moment(date).startOf('M').startOf('W').toISOString(),
    endTime: moment(date).endOf('M').endOf('W').toISOString(),
  });

  const timeOffRequests =
    useSelector((state: RootState) => state.timeOff.all).filter((t) =>
      scopeType === 'CENTER'
        ? t.centerId === currentCenterId &&
          (filter.status === 'All' || filter.status === t.status) &&
          (!filter.search.length ||
            `${t.person.firstname} ${t.person.lastname}`.toLowerCase().includes(filter.search.toLowerCase()) ||
            `${t.person.nickname} ${t.person.lastname}`.toLowerCase().includes(filter.search.toLowerCase()))
        : !t.centerId &&
          (filter.status === 'All' || filter.status === t.status) &&
          (!filter.search.length ||
            `${t.person.firstname} ${t.person.lastname}`.toLowerCase().includes(filter.search.toLowerCase()) ||
            `${t.person.nickname} ${t.person.lastname}`.toLowerCase().includes(filter.search.toLowerCase()))
    ) || [];

  useEffect(() => {
    const timezone = scopeType === 'CENTER' ? centerTimezone : businessTimezone;
    if (!isTheSameTimezone(timezone)) {
      showTimezoneToast(timezone);
    }
  }, [centerTimezone, currentCenterId, scopeType, businessTimezone]);

  return (
    <PageWrapper
      pageTitle="Employee Time Off"
      mobileButtonComponent={
        userCanEditTimeOff && (
          <CirclePlusButton
            variant="primary"
            className="mt-4 mb-4"
            onClick={() => {
              setAddTimeOffModalOpen(true);
            }}
          />
        )
      }
      buttonComponent={
        userCanEditTimeOff &&
        !AdpTimeEnabled && (
          <CreateButton
            onClick={() => {
              setAddTimeOffModalOpen(true);
            }}
          >
            Add Time Off
          </CreateButton>
        )
      }
    >
      {(scopeType === 'CENTER' || user?.isInternal) && (
        <CenterSelectBanner className={!isMobile() ? 'mx-n8 mt-n8 mb-8' : undefined} pageName="time off" />
      )}
      <div
        className={`d-flex align-items-center bg-white py-2 px-8 box-shadow-lg ${
          !isMobile() ? 'mx-n8 mt-n8 mb-8' : ''
        }`}
      >
        {user?.roleship?.scopeType !== 'CENTER' && (
          <ButtonGroup className="mr-4">
            <Button
              onClick={() => {
                updateScopeType('ENTITY');
                localStorage.setItem(`${user?.email}-timeOff-scopeType`, 'ENTITY');
              }}
              variant={scopeType === 'ENTITY' ? 'secondary' : 'light'}
            >
              Business
            </Button>
            <Button
              onClick={() => {
                updateScopeType('CENTER');
                localStorage.setItem(`${user?.email}-timeOff-scopeType`, 'CENTER');
              }}
              variant={scopeType === 'CENTER' ? 'secondary' : 'light'}
            >
              {fieldLables.center}
            </Button>
          </ButtonGroup>
        )}
      </div>
      <SearchAndFilterBar filter={filter} updateFilter={updateFilter} />
      {loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES ? (
        AdpTimeEnabled ? (
          <>
            {' '}
            <div className="text-center p-lg-5">
              <h4 className="font-weight-bold">Syncing data from ADP</h4>
            </div>
            <div className="d-flex h-25 align-items-center">
              <Spinner large className="mx-auto my-auto" />
            </div>
          </>
        ) : (
          <div className="d-flex h-100">
            <Spinner large className="mx-auto my-auto" />
          </div>
        )
      ) : (
        <Row align="stretch">
          <Col sm={12} md={4} className="mb-2">
            <WeekView
              date={date}
              updateDate={updateDate}
              timeOffRequests={timeOffRequests}
              setSelectedTimeOff={setSelectedTimeOff}
            />
          </Col>
          <Col sm={12} md={8} className="mb-2">
            <MonthView
              date={date}
              updateDate={updateDate}
              timeOffRequests={timeOffRequests}
              setSelectedTimeOff={setSelectedTimeOff}
            />
          </Col>
        </Row>
      )}
      {user && isAddTimeOffModalOpen && (
        <AddTimeOffModal
          isOpen={isAddTimeOffModalOpen}
          onClose={() => setAddTimeOffModalOpen(false)}
          centerId={scopeType === 'CENTER' ? currentCenterId ?? undefined : undefined}
          entityId={currentBusinessId ?? user.entityId ?? ''}
          isRequesting={false}
          user={user}
        />
      )}
      {selectedTimeOff && userCanEditSelectedTimeOff && (
        <ReviewTimeOffModal
          isOpen={Boolean(selectedTimeOff)}
          onClose={() => setSelectedTimeOff(null)}
          timeOff={selectedTimeOff}
          hasEditPermission={userCanEditSelectedTimeOff}
        />
      )}
    </PageWrapper>
  );
};

export default EmployeeTimeOff;
