import { QueryHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useQuery } from 'shared/apis/core';
import { AGE_UNIT } from 'shared/constants/enums';
import { getCenterClassesSuccess } from '../duck/actions';

interface IClassesForCenterVariables {
  centerId: string;
  startDate: string | null;
}

interface IClassesForCentersVariables {
  centerIds: string[];
}

interface IClassesForCenterData {
  getClassesForCenter: IClass[];
}

interface IClassesForCentersData {
  getClassesForCenters: IClass[];
}

interface IAllRegulationVariables {}

interface IAllRegulationData {
  getAllRegulations: {
    id: string;
    state: string;
    county: string | null;
    ratioTeachers: number;
    ratioChildren: number;
    capacity: number;
    startAge: IRegulationAge;
    endAge: IRegulationAge;
  }[];
}

interface IGetCentersForEntityVariables {
  id: string;
}

interface IGetCentersForEntityData {
  getEntity: {
    id: string;
    name: string;
    centers: ICenter[];
  };
}

export const GET_CENTERS_FOR_ENTITY = gql`
  query ($id: String!) {
    getEntity(id: $id) {
      id
      name
      centers {
        id
        name
      }
    }
  }
`;

export const GET_CLASSES_FOR_CENTER = gql`
  query ($centerId: String!, $startDate: Date) {
    getClassesForCenter(centerId: $centerId, startDate: $startDate) {
      id
      centerId
      name
      startsAt
      endsAt
      startTime
      endTime
      regulationId
      defaultSpaceId
      colorCode
      careType
      isPreSchoolProgram
      defaultSpace {
        id
        name
      }
      currentSpace {
        id
        name
      }
      regulation {
        id
        state
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        youngestStartAge {
          age
          unit
        }
        oldestEndAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      colorCode
      staffAssignments {
        id
        nickname
        firstname
        lastname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
        }
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
      }
      archivedAt
      defaultCasualFeeId
      defaultPermanentFeeId
      fees {
        id
        name
        deactivatedAt
      }
      capacity
      glCode
    }
  }
`;

export const GET_CLASSES_FOR_CENTERS = gql`
  query ($centerIds: [String!]!) {
    getClassesForCenters(centerIds: $centerIds) {
      id
      centerId
      name
      startsAt
      endsAt
      regulationId
      defaultSpaceId
      colorCode
      careType
      isPreSchoolProgram
      defaultSpace {
        id
        name
      }
      currentSpace {
        id
        name
      }
      regulation {
        id
        state
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        youngestStartAge {
          age
          unit
        }
        oldestEndAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      colorCode
      staffAssignments {
        id
        nickname
        firstname
        lastname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
        }
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
      }
      archivedAt
      defaultCasualFeeId
      defaultPermanentFeeId
      fees {
        id
        name
        deactivatedAt
      }
      capacity
      glCode
    }
  }
`;

export const GET_REGULATIONS = gql`
  query {
    getAllRegulations {
      id
      state
      county
      ratioTeachers
      ratioChildren
      capacity
      startAge {
        age
        unit
      }
      endAge {
        age
        unit
      }
    }
  }
`;

export function useGetRegulations() {
  const response = useQuery<IAllRegulationData, IAllRegulationVariables>(GET_REGULATIONS);

  if (response.data && response.data.getAllRegulations) {
    const regulations =
      response.data.getAllRegulations.map((r: IRegulation) => {
        const ageRange = {
          value: { startAge: r.startAge, endAge: r.endAge },
          label: `${r.startAge.age} ${AGE_UNIT[r.startAge.unit].label} to ${r.endAge.age} ${
            AGE_UNIT[r.endAge.unit].label
          }`,
        };
        return { ...r, ageRange };
      }) || [];

    return {
      ...response,
      data: { regulations },
    };
  }

  return response;
}

export function useGetClassesForCenters(
  centerId: string,
  options: QueryHookOptions<IClassesForCenterData, IClassesForCenterVariables> = {},
  startDate: string | null = null
) {
  const dispatch = useDispatch();

  return useQuery<IClassesForCenterData, IClassesForCenterVariables>(GET_CLASSES_FOR_CENTER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      centerId,
      startDate,
    },
    onCompleted: (data) => {
      dispatch(getCenterClassesSuccess(data.getClassesForCenter));
    },
    ...options,
  });
}

export function useGetClassesForMultipleCenters(
  centerIds: string[],
  options: QueryHookOptions<IClassesForCentersData, IClassesForCentersVariables> = {}
) {
  const dispatch = useDispatch();

  return useQuery<IClassesForCentersData, IClassesForCentersVariables>(GET_CLASSES_FOR_CENTERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      centerIds: centerIds,
    },
    onCompleted: (data) => {
      dispatch(getCenterClassesSuccess(data.getClassesForCenters));
    },
    ...options,
  });
}

export function useGetCentersForEntity(entityId: string) {
  const response = useQuery<IGetCentersForEntityData, IGetCentersForEntityVariables>(GET_CENTERS_FOR_ENTITY, {
    variables: { id: entityId },
  });

  if (response.data) {
    const centers = response.data.getEntity.centers || [];
    return {
      ...response,
      data: {
        getEntity: {
          ...response.data,
          centers: centers,
        },
      },
    };
  }

  return response;
}
