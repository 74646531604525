import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Section } from 'shared/types/enrollment-form';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import TextInput from 'shared/components/TextInput';
import { PublicApi } from '../page-detail/page-detail';
import { SectionSelectorContext } from '../section-selector-provider';
import { v4 as uuidv4 } from 'uuid';

export type SectionModalProps = {
  onAddNewSection?: (section: Section) => void;
  onEditSection?: (section: Section) => void;
  existingSections: Section[];
  sectionToBeUpdated?: Section;
};

export default React.forwardRef<PublicApi, SectionModalProps>(
  ({ onAddNewSection, existingSections, sectionToBeUpdated, onEditSection }, ref) => {
    const { t } = useTranslation(['translation', 'enrollment', 'business']);
    const [showSectionModal, setShowSectionModal] = useState(false);
    const [sectionName, setSectionName] = useState(sectionToBeUpdated?.label ?? '');
    const { setLastSelectedSection } = React.useContext(SectionSelectorContext)!;

    const isEdit = !!sectionToBeUpdated;
    const isAdd = !isEdit;
    const title = isEdit
      ? t('enrollment:form.edit-section-modal.title')
      : t('enrollment:form.add-new-section-modal.title');
    const label = isEdit
      ? t('enrollment:form.edit-section-modal.section-name')
      : t('enrollment:form.add-new-section-modal.section-name');
    const primaryBtnText = isEdit
      ? t('enrollment:form.edit-section-modal.save-section')
      : t('enrollment:form.add-new-section-modal.add-section');

    useImperativeHandle(
      ref,
      () => ({
        ...((ref as React.MutableRefObject<PublicApi | null>)?.current ?? {}),
        showSectionModel: (show: boolean) => {
          setShowSectionModal(show);
        },
      }),
      [setShowSectionModal, ref]
    );

    const handlePrimaryAction = () => {
      if (isEdit && onEditSection && sectionToBeUpdated) {
        onEditSection({
          ...sectionToBeUpdated,
          label: sectionName,
        });
      }

      if (isAdd && onAddNewSection) {
        onAddNewSection &&
          onAddNewSection({
            id: uuidv4(),
            label: sectionName,
            fields: [],
          });
      }

      setLastSelectedSection(sectionName);
      setSectionName('');
      setShowSectionModal(false);
    };

    // we need to make sure that the section name is unique on each page
    const errorText = (isAdd
      ? existingSections
      : existingSections.filter((s) => s.label !== sectionToBeUpdated?.label)
    ).some((s: Section) => s.label === sectionName)
      ? 'Section name already exists'
      : '';

    useEffect(() => {
      setSectionName(sectionToBeUpdated?.label ?? '');
    }, [sectionToBeUpdated]);

    return (
      <div className="add-new-question-modal">
        <Modal
          show={showSectionModal}
          backdrop="static"
          onHide={() => {
            setShowSectionModal(false);
            setSectionName('');
          }}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextInput
              required
              label={label}
              errorText={errorText}
              isInvalid={!!errorText}
              value={sectionName}
              onChange={(v) => setSectionName(v)}
              placeholder={label}
            />
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                variant="light"
                onClick={() => {
                  setShowSectionModal(false);
                  isAdd && setSectionName('');
                }}
                className="mr-2"
              >
                {t('translation:core.capitalize', { value: t('translation:spelling.cancel') })}
              </Button>
              <Button variant="primary" disabled={!sectionName || !!errorText} onClick={handlePrimaryAction}>
                {primaryBtnText}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
);
