import * as type from './types';
import { ICcsEntitlement } from 'shared/types/ccsEntitlement';

export const getAccountsSuccess = (accounts: IAccount[], totalResults: number) => ({
  type: type.GET_ACCOUNTS_SUCCESS,
  accounts,
  totalResults,
});
export const getAccountByIdSuccess = (account: IAccount) => ({
  type: type.GET_ACCOUNT_BY_ID_SUCCESS,
  account,
});
export const addAccountSuccess = (account: IAccount) => ({
  type: type.ADD_ACCOUNT_SUCCESS,
  account,
});
export const updateAccountSuccess = (account: IAccount) => ({
  type: type.UPDATE_ACCOUNT_SUCCESS,
  account,
});
export const getContractsForAccountChild = (
  accountId: string,
  childId: string,
  contracts: IContract[]
): type.AccountActionTypes => ({
  type: type.GET_CONTRACTS_FOR_ACCOUNT_CHILD,
  accountId,
  childId,
  contracts,
});
export const createContract = (contract: IContract): type.AccountActionTypes => ({
  type: type.CREATE_CONTRACT,
  contract,
});
export const updateContract = (contract: IContract, newContract?: IContract): type.AccountActionTypes => ({
  type: type.UPDATE_CONTRACT,
  contract,
  newContract,
});

export const deleteContract = (contract: IContract): type.AccountActionTypes => ({
  type: type.DELETE_CONTRACT,
  contract,
});

export const setAccountBillingCycle = (cycle: IBillingCycle): type.AccountActionTypes => ({
  type: type.SET_ACCOUNT_BILLING_CYCLE,
  cycle,
});

export const getCcsCertificateForAccountChild = (
  accountId: string,
  childId: string,
  certificate: ICcssCertificate[]
): type.AccountActionTypes => ({
  type: type.GET_CCS_CERTIFICATE_FOR_ACCOUNT_CHILD,
  accountId,
  childId,
  certificate,
});

export const getCcsEntitlementForAccountChild = (
  accountId: string,
  childId: string,
  entitlement: ICcsEntitlement
): type.AccountActionTypes => ({
  type: type.GET_CCS_ENTITLEMENTS_FOR_ACCOUNT_CHILD,
  accountId,
  childId,
  entitlement,
});

export const getCcsDeterminationForAccountChild = (
  accountId: string,
  childId: string,
  determination: ICcssDetermination[]
): type.AccountActionTypes => ({
  type: type.GET_CCS_DETERMINATION_FOR_ACCOUNT_CHILD,
  accountId,
  childId,
  determination,
});

export const getPaymentMethodsForAccountSuccess = (
  accountId: string,
  paymentMethods: IPaymentMethod[]
): type.AccountActionTypes => ({
  type: type.GET_PAYMENT_METHODS_FOR_ACCOUNT_SUCCESS,
  accountId,
  paymentMethods,
});

export const createPaymentMethodForAccountSuccess = (paymentMethod: IPaymentMethod): type.AccountActionTypes => ({
  type: type.CREATE_PAYMENT_METHOD_FOR_ACCOUNT_SUCCESS,
  paymentMethod,
});

export const updatePaymentMethodForAccountSuccess = (paymentMethod: IPaymentMethod): type.AccountActionTypes => ({
  type: type.UPDATE_PAYMENT_METHOD_FOR_ACCOUNT_SUCCESS,
  paymentMethod,
});

export const setAccountDefaultPaymentMethod = (
  accountId: string,
  paymentMethodId: string
): type.AccountActionTypes => ({
  type: type.SET_ACCOUNT_DEFAULT_PAYMENT_METHOD,
  accountId,
  paymentMethodId,
});

export const setAccountAutopayPaymentMethod = (
  accountId: string,
  paymentMethodId: string | null
): type.AccountActionTypes => ({
  type: type.SET_ACCOUNT_AUTOPAY_PAYMENT_METHOD,
  accountId,
  paymentMethodId,
});

export const removeAccountPaymentMethod = (accountId: string, paymentMethodId: string): type.AccountActionTypes => ({
  type: type.REMOVE_ACCOUNT_PAYMENT_METHOD,
  accountId,
  paymentMethodId,
});

/**
 * Add an amount to an account's balance
 * @param accountId
 * @param amount
 */
export const addTransactionAmountToAccountBalance = (accountId: string, amount: number): type.AccountActionTypes => ({
  type: type.ADD_TRANSACTION_AMOUNT_TO_ACCOUNT_BALANCE,
  accountId,
  amount,
});

export const updateCertificate = (accountId: string, certificate: ICcssCertificate): type.AccountActionTypes => ({
  type: type.UPDATE_CERTIFICATE,
  accountId,
  certificate,
});

export const updateDetermination = (accountId: string, determination: ICcssDetermination): type.AccountActionTypes => ({
  type: type.UPDATE_DETERMINATION,
  accountId,
  determination,
});

export const addNewContactToAccountSuccess = (accountId: string, contact: IContact): type.AccountActionTypes => ({
  type: type.ADD_NEW_CONTACT_TO_ACCOUNT_SUCCESS,
  accountId,
  contact,
});

export const updateAccountContactRelationshipsSuccess = (
  accountId: string,
  contactId: string,
  children: IContactChild[],
  isPrimary: boolean,
  email?: string,
  permissions?: AccountPermissions[]
): type.AccountActionTypes => ({
  type: type.UPDATE_CONTACT_RELATIONSHIPS_SUCCESS,
  accountId,
  contactId,
  children,
  isPrimary,
  email,
  permissions,
});

export const removeContactFromAccountSuccess = (accountId: string, contactId: string): type.AccountActionTypes => ({
  type: type.REMOVE_CONTACT_FROM_ACCOUNT_SUCCESS,
  accountId,
  contactId,
});

export const archiveChildOnAccountSuccess = (accountId: string, childId: string): type.AccountActionTypes => ({
  type: type.ARCHIVE_CHILD_ON_ACCOUNT_SUCCESS,
  accountId,
  childId,
});

export const removeChildOnAccountSuccess = (accountId: string, childId: string): type.AccountActionTypes => ({
  type: type.REMOVE_CHILD_ON_ACCOUNT_SUCCESS,
  accountId,
  childId,
});

export const unarchiveChildOnAccountSuccess = (accountId: string, childId: string): type.AccountActionTypes => ({
  type: type.UNARCHIVE_CHILD_ON_ACCOUNT_SUCCESS,
  accountId,
  childId,
});

export const applyDiscountToAccountSuccess = (
  accountId: string,
  appliedDiscounts: IAppliedAccountDiscount[]
): type.AccountActionTypes => ({
  type: type.APPLY_DISCOUNT_TO_ACCOUNT_SUCCESS,
  accountId,
  appliedDiscounts,
});

export const updateAppliedAccountDiscountSuccess = (
  accountId: string,
  appliedDiscount: IAppliedAccountDiscount
): type.AccountActionTypes => ({
  type: type.UPDATE_APPLIED_ACCOUNT_DISCOUNT_SUCCESS,
  accountId,
  appliedDiscount,
});

export const removeAppliedAccountDiscountSuccess = (
  appliedDiscount: IAppliedAccountDiscount
): type.AccountActionTypes => ({
  type: type.REMOVE_APPLIED_ACCOUNT_DISCOUNT_SUCCESS,
  appliedDiscount,
});

export const getAccountItemizedBillsSuccess = (
  accountId: string,
  itemizedBills: IItemizedBillWithoutTransactions[]
): type.AccountActionTypes => ({
  type: type.GET_ACCOUNT_ITEMIZED_BILLS_SUCCESS,
  accountId,
  itemizedBills,
});

export const getAccountBillingCycleSuccess = (
  accountId: string,
  billingCycle: IBillingCycle
): type.AccountActionTypes => ({
  type: type.GET_ACCOUNT_BILLING_CYCLE_SUCCESS,
  accountId,
  billingCycle,
});

export const selectAccountItemizedBill = (accountId: string, itemizedBillId: string): type.AccountActionTypes => ({
  type: type.SET_SELECTED_ACCOUNT_ITEMIZED_BILL,
  accountId,
  itemizedBillId,
});

export const getSelectedAccountItemizedBill = (
  accountId: string,
  itemizedBill: IItemizedBill
): type.AccountActionTypes => ({
  type: type.GET_SELECTED_ACCOUNT_ITEMIZED_BILL,
  accountId,
  itemizedBill,
});

export const createNewManualItemizedBill = (
  accountId: string,
  itemizedBill: IItemizedBill
): type.AccountActionTypes => ({
  type: type.CREATE_NEW_MANUAL_ITEMIZED_BILL,
  accountId,
  itemizedBill,
});
