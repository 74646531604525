import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

export interface IGetTableMetaData {
  getTableMetaData: ITableMetaDataRaw;
  fieldSets: Record<string, string[]>;
  totalResults: number;
}

interface IGetTableMetaDataInput {
  input: ITableMetaDataInput;
}

export const GET_TABLE_METADATA = gql`
  query ($input: TableMetaDataInput!) {
    getTableMetaData(input: $input) {
      totalResults
      fieldSets {
        fieldName
        dataSet
      }
    }
  }
`;

const useGetTableMetaData = (input: ITableMetaDataInput) => {
  const response = useQuery<IGetTableMetaData, IGetTableMetaDataInput>(GET_TABLE_METADATA, {
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (response.data && response.data.getTableMetaData.fieldSets) {
    // transform fieldSets into an object where each key is the fieldName and its value is the dataSet
    const fieldSets = response.data.getTableMetaData.fieldSets;
    const fieldSetObject = Object.fromEntries(fieldSets.map((fs) => [fs.fieldName, fs.dataSet]));
    return {
      ...response,
      data: {
        fieldSets: fieldSetObject,
        totalResults: response.data.getTableMetaData.totalResults,
      },
    };
  }

  return response;
};

export default useGetTableMetaData;
