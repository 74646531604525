import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';
import { casualBookingSettingsFields, casualBookingExclusionFields } from './fields';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { showToast } from 'shared/components/Toast';

interface IUpdateCasualBookingSettingsVariables {
  input: ICasualBookingSettingsInput[];
}

interface IUpdateCasualBookingSettingsData {
  updateCasualBookingSettings: ICasualBookingSettings;
}

export const UPDATE_CASUAL_BOOKING_SETTINGS = (fields: string) => gql`
  mutation($input:[CasualBookingSettingsInput!]!){
  updateCasualBookingSettings(input:$input){
    ${fields}
  }  
}`;

export const useUpdateCasualBookingSettings = (
  options?: MutationHookOptions<IUpdateCasualBookingSettingsData, IUpdateCasualBookingSettingsVariables> | undefined,
  fields = casualBookingSettingsFields
) =>
  useMutation<IUpdateCasualBookingSettingsData, IUpdateCasualBookingSettingsVariables>(
    UPDATE_CASUAL_BOOKING_SETTINGS(fields),
    {
      onError: (err) => {
        showToast(getApolloErrorMessage(err), 'error');
      },
      ...options,
    }
  );

interface IAddCasualBookingExclusionsVariables {
  input: ICasualBookingExclusionsInput;
}

interface IAddCasualBookingExclusionsData {
  addCasualBookingExclusions: ICasualBookingExclusion[];
}

export const ADD_CASUAL_BOOKING_EXCLUSIONS = (fields: string) => gql`
  mutation($input:AddCasualBookingExclusionsInput!){
  addCasualBookingExclusions(input:$input){
    ${fields}
  }    
}`;

export const useAddCasualBookingExclusions = (
  options?: MutationHookOptions<IAddCasualBookingExclusionsData, IAddCasualBookingExclusionsVariables> | undefined,
  fields = casualBookingExclusionFields
) =>
  useMutation<IAddCasualBookingExclusionsData, IAddCasualBookingExclusionsVariables>(
    ADD_CASUAL_BOOKING_EXCLUSIONS(fields),
    {
      onError: (err) => {
        showToast(getApolloErrorMessage(err), 'error');
      },
      ...options,
    }
  );

interface IDeleteCasualBookingExclusionVariables {
  accountId: string;
}

interface IDeleteCasualBookingExclusionData {
  deleteCasualBookingExclusion: ICasualBookingExclusion;
}

export const DELETE_CASUAL_BOOKING_EXCLUSION = (fields: string) => gql`
  mutation($accountId:String!){
    deleteCasualBookingExclusion(accountId:$accountId) {
      ${fields}    
    }
}`;

export const useDeleteCasualBookingExclusion = (
  options?: MutationHookOptions<IDeleteCasualBookingExclusionData, IDeleteCasualBookingExclusionVariables> | undefined,
  fields = casualBookingExclusionFields
) =>
  useMutation<IDeleteCasualBookingExclusionData, IDeleteCasualBookingExclusionVariables>(
    DELETE_CASUAL_BOOKING_EXCLUSION(fields),
    {
      onError: (err) => {
        showToast(getApolloErrorMessage(err), 'error');
      },
      ...options,
    }
  );
