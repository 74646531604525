import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import TextInput from 'shared/components/TextInput';
import AlertContainer from 'shared/components/AlertContainer';
import { capitalize } from 'lodash';
import Select from 'shared/components/Select';
import { useTranslation } from 'react-i18next';
import _mapValues from 'lodash/mapValues';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useCreateEnrollmentForm } from './graphql/mutations';
import { useLazySearchEnrollmentForm } from './graphql/queries';
import { CustomEnrolmentForm, Page, Section } from 'shared/types/enrollment-form';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import Alert from 'shared/components/Alert';
import { pageDecorator } from './enrollment-form-detail/utils';
import { v4 as uuidv4 } from 'uuid';
import { useGetCentreIdsHasFormAttachedQuery } from 'generated/graphql';

type CreateEnrollmentFormModalProps = {
  isOpen: boolean;
  onSuccess?: (data: EnrollmentForm) => void;
  onClose: () => void;
  businessId: string;
  enrollmentFormId?: string;
};

const CreateEnrollmentFormModal: React.FC<CreateEnrollmentFormModalProps> = ({
  onClose,
  isOpen,
  enrollmentFormId,
  businessId,
  onSuccess,
}) => {
  const { t } = useTranslation(['translation', 'enrollment', 'business']);

  const [centerErrorMessage, setErrorMessage] = useState<string | null>();
  const [getCenterEnrollmentForm, { loading }] = useLazySearchEnrollmentForm({
    onCompleted: (res) => {
      let enrollmentForms = res.searchEnrollmentForm?.data ?? [];
      for (let i = 0; i < enrollmentForms.length; i++) {
        const enrollmentForm = enrollmentForms[i];
        const allCentreScope = (enrollmentForm.usedByCenterIds ?? []).length === 0;

        if (allCentreScope && selectedCenters == null) {
          setErrorMessage(t('enrollment:settings.enrollment-form.all-center-has-enrollment-form', { count: 2 }));
          break;
        }

        if (
          enrollmentForm.usedByCenterIds?.find((centerId) => selectedCenters?.find((o) => o.id === centerId) != null)
        ) {
          setErrorMessage(t('enrollment:settings.enrollment-form.center-has-enrollment-form'));
          break;
        }
      }
    },
  });

  const [selectedCenters, setSelectedCenters] = useState<ICenter[]>();
  const [selectedStates, setSelectedStates] = useState<{ value: string; label: string }[]>([]);
  const [enrollmentFormName, setEnrollmentFormName] = useState<string | null>('');
  const [error, setError] = useState(null);

  const centers = useSelector<RootState, ICenter[]>((state) => state.centers.all);
  const centerOptions = useMemo(
    () =>
      !selectedStates || !selectedStates.length
        ? centers
        : centers.filter((c) => selectedStates.map((s) => s.value).includes(c.address.state)),
    [centers, selectedStates]
  );
  const countryCode = DEFAULT_COUNTRY;
  const stateOptions = _mapValues(COUNTRY_INFO, (country) =>
    Object.entries(country.subdivisions).map((arr) => ({ value: arr[0], label: arr[1] }))
  );

  const [createEnrollmentFormFn, { data, loading: isLoading }] = useCreateEnrollmentForm({
    onCompleted: (data) => {
      closeHandler();
      onSuccess?.apply(this, [data.createEnrollmentForm]);
    },
  });

  const closeHandler = useCallback(() => {
    setError(null);
    setSelectedStates([]);
    setEnrollmentFormName(null);
    setSelectedCenters([]);
    onClose();
  }, [onClose, setError, setSelectedStates, setEnrollmentFormName]);

  const handleSubmit = useCallback(async () => {
    let steps = [
      {
        label: t('enrollment:form.children'),
        sectionType: 'child',
        sections: [
          {
            id: uuidv4(),
            fields: [
              {
                name: 'firstName',
                type: 'Text',
                label: 'First Name',
                isMandatory: true,
              },
              {
                name: 'lastName',
                type: 'Text',
                label: 'Last Name',
                isMandatory: true,
              },
              {
                name: 'dateOfBirth',
                type: 'Date',
                label: 'Date of Birth',
                isMandatory: true,
              },
            ],
            label: 'Child Information',
            hideTitle: false,
          },
        ] as Section[],
      },
      pageDecorator({
        label: t('enrollment:form.primary-contact'),
        sectionType: 'contact',
        sections: [
          {
            id: uuidv4(),
            fields: [],
            namespace: 'primaryContacts',
            hideTitle: false,
            label: 'Primary Contacts',
          },
        ] as Section[],
      }),
    ] as Page[];

    createEnrollmentFormFn({
      variables: {
        input: {
          businessId,
          name: enrollmentFormName!,
          formSchema: {
            steps: steps,
          } as CustomEnrolmentForm,
          usedByCenterIds: selectedCenters?.map((c) => c.id),
        },
      },
    });
  }, [createEnrollmentFormFn, enrollmentFormName, selectedCenters]);

  const isValid =
    enrollmentFormName &&
    enrollmentFormName!.length > 0 &&
    (selectedCenters == null || selectedCenters.length > 0) &&
    !loading &&
    centerErrorMessage === null;

  const handleCenterSelect = (selectedCenterValues?: ICenter[]) => {
    const selectedCenters = selectedCenterValues?.map((c) => c.id);
    getCenterEnrollmentForm({
      variables: {
        businessId,
        input: {
          centerIds: selectedCenters,
          includeNoCenter: true,
          pageNumber: 1,
          pageSize: 20,
        },
      },
    });
  };

  useEffect(() => {
    const c = selectedCenters?.map((c) => c.id) ?? [];
    getCenterEnrollmentForm({
      variables: {
        businessId,
        input: {
          centerIds: c,
          includeNoCenter: true,
          pageNumber: 1,
          pageSize: 20,
        },
      },
    });
  }, []);

  const { data: excludeCentreIdsData } = useGetCentreIdsHasFormAttachedQuery({
    variables: { businessId },
    fetchPolicy: 'no-cache',
  });
  const excludeCentreIds = useMemo(() => {
    const ids: Array<string | null> = excludeCentreIdsData?.getCentreIdsHasFormAttached?.centreIdsWithForm ?? [];

    if (!!excludeCentreIdsData?.getCentreIdsHasFormAttached?.allCentreAssigned) {
      ids.push(null);
    }
    return ids;
  }, [excludeCentreIdsData?.getCentreIdsHasFormAttached]);

  useEffect(() => {
    setErrorMessage(null);
  }, [selectedCenters, selectedStates]);

  return (
    <SideModalDrawer
      show={isOpen}
      onHide={closeHandler}
      title={t('enrollment:settings.enrollment-form.enrollment-form-model-title', { action: 'Create' })}
      secondaryChoice={capitalize(t('translation:spelling.cancel'))}
      secondaryCallback={closeHandler}
      closeOnPrimaryCallback={false}
      primaryChoice={
        !enrollmentFormId
          ? t('enrollment:settings.enrollment-form.create')
          : t('enrollment:settings.enrollment-form.update')
      }
      primaryCallback={handleSubmit}
      primaryButtonProps={{ loading: isLoading, disabled: !isValid }}
    >
      {error && <AlertContainer errorApolloError={error!} />}
      <Row>
        <Col>
          <TextInput
            id="name-input"
            name="enrollment-form-name"
            label={t('enrollment:settings.enrollment-form.name')}
            value={enrollmentFormName}
            onChange={(value: string) => setEnrollmentFormName(value)}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            label={t('business:enrolment.state-selection')}
            options={stateOptions[countryCode]}
            onChange={(selectedStateValues: { value: string; label: string }[]) => {
              setSelectedStates(selectedStateValues || []);
              setSelectedCenters([]);
            }}
            isLoading={false}
          />
          <MultipleCenterSelect
            excludeCentreIds={excludeCentreIds}
            isRequired
            useNullForAllOption
            states={selectedStates.map((o) => o.value)}
            selectedCenterIds={selectedCenters?.map((o) => o.id) ?? null}
            onSelect={(centerIds) => {
              if (excludeCentreIds.includes(null) && centerIds?.length === 0) {
                // ignore - because ALL CENTRES option is not selectable
              } else {
                const selectedCenterValues = centerIds?.map((o) => centerOptions.find((co) => co.id === o)!);
                setSelectedCenters(selectedCenterValues);
                handleCenterSelect(selectedCenterValues);
              }
            }}
          />
          {centerErrorMessage && <Alert variant="danger">{centerErrorMessage}</Alert>}
          {/*<Select
            isMulti
            label={capitalize(t('business:enrolment.center-selection'))}
            options={centerOptions.map((c) => ({ ...c, value: c.id, label: c.name }))}
            onChange={(selectedCenterValues: ICenter[]) => {
              setSelectedCenters(selectedCenterValues || []);
              handleCenterSelect(selectedCenterValues);
            }}
            value={selectedCenters}
            getOptionValue={(option: ICenter) => option.id}
            getOptionLabel={(option: ICenter) => option.name}
            isLoading={loading}
            isInvalid={centerErrorMessage !== null}
            showErrorMessage
            errorText={loading ? undefined : centerErrorMessage ?? undefined}
          />*/}
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default CreateEnrollmentFormModal;
