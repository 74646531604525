import * as types from './types';

export const searchFees = (fees: IFee[], totalFees: number): types.FeesActionTypes => ({
  type: types.SEARCH_FEES,
  fees,
  totalFees,
});
export const createFee = (fee: IFee): types.FeesActionTypes => ({ type: types.CREATE_FEE, fee });
export const updateFee = (fee: IFee): types.FeesActionTypes => ({ type: types.UPDATE_FEE, fee });
export const deactivateFee = (fee: IFee): types.FeesActionTypes => ({ type: types.DEACTIVATE_FEE, fee });
export const reactivateFee = (fee: IFee): types.FeesActionTypes => ({ type: types.REACTIVATE_FEE, fee });
export const updateSearchFilter = (filter: ITableFiltersMap): types.FeesActionTypes => ({
  type: types.UPDATE_SEARCH_FILTER,
  filter,
});
export const updateSearchSort = (field: string, direction: ElasticsearchSortDirection): types.FeesActionTypes => ({
  type: types.UPDATE_SEARCH_SORT,
  field,
  direction,
});
