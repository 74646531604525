import React, { useState, useCallback } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Alert from 'shared/components/Alert';
import TimeOffInputs from './TimeOffInputs';
import { useCreateTimeOffForMe, useCreateTimeOff } from '../../grapgql/mutations';
import moment from 'moment';
import { showToast } from 'shared/components/Toast';
import errorMessages from 'shared/constants/errorMessages';
import { useGetCenterScheduleForWeek } from 'pages/TimeManagement/subroutes/StaffSchedules/graphql/queries';
import { useGetTimeOffForScope, GET_ACTIVE_STAFF_OPTIONS } from '../../grapgql/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTotalDays } from '../../utils';
import { faCheckCircle, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import cast from 'shared/util/cast';
import {
  useSearchActiveStaffScopedToEntity,
  useSearchActiveStaffForCenter,
} from 'shared/hooks/useSearchActiveStaffForCenter';
import Select from 'shared/components/Select';
import User from 'shared/types/user';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { getFullName } from 'shared/util/string';
import { getIsRoleARankedAboveRoleB, isHighestRole } from 'shared/util/getIsRoleARankedAboveRoleB';
import { useGetRolesForBusiness } from 'shared/hooks/useGetRolesForBusiness';
import { useSearchPayCodes } from 'gql/payCode/queries';
import { isEmpty } from 'lodash';

interface IProps {
  entityId: string;
  centerId?: string;
  isOpen: boolean;
  onClose: () => void;
  isRequesting: boolean;
  user: User;
}

const AddTimeOffModal: React.FC<IProps> = ({ isOpen, onClose, entityId, centerId, isRequesting, user }) => {
  const newTimeOff: ITimeOffInput = {
    personId: isRequesting ? user?.id : '',
    startTime: '',
    endTime: '',
    allDay: true,
    description: '',
    centerId: centerId,
    ptoType: null,
  };
  const timezone =
    useSelector((state: RootState) =>
      centerId ? state.timezone.byCenterId[centerId] : state.timezone.byBusinessId[entityId]
    ) ?? moment.tz.guess();

  const [timeOff, updateTimeOff] = useState(newTimeOff);
  const resetForm = useCallback(() => {
    updateTimeOff(newTimeOff);
  }, [newTimeOff]);
  const [createTimeOffForMeFn, { loading: createTimeOffForMeLoading }] = useCreateTimeOffForMe();
  const [createTimeOffFn, { loading: createTimeOffLoading }] = useCreateTimeOff();
  const { data: ptoTypesData } = useSearchPayCodes({
    skip: isEmpty(entityId),
    variables: {
      input: {
        businessId: entityId,
        centerId,
        from: 0,
        size: 10000, // if they have more than this ¯\_(ツ)_/¯
        sortDirection: 'ASCENDING',
        sortField: 'name',
        includeBusinessTypes: true,
      },
    },
  });

  //get staff options
  const { data: activeStaffAtEntityData } = useSearchActiveStaffScopedToEntity(
    entityId,
    GET_ACTIVE_STAFF_OPTIONS,
    true
  );
  const { data: activeStaffAtCenterData } = useSearchActiveStaffForCenter(
    cast<string>(centerId),
    GET_ACTIVE_STAFF_OPTIONS,
    true
  );
  const { data: businessRoles } = useGetRolesForBusiness(entityId);

  const staffOptions = centerId
    ? activeStaffAtCenterData?.searchStaff?.data?.filter((s) => s.primaryCenterId === centerId) || []
    : activeStaffAtEntityData?.searchStaff?.data || [];
  const filteredStaffOptions = staffOptions.filter(
    (s) =>
      user?.isInternal ||
      isHighestRole(user?.role, businessRoles?.getRolesForBusiness) ||
      getIsRoleARankedAboveRoleB(user.role, s.role)
  );

  // checks the person's primary center for a published schedule since that is the center responsible for approving their time off
  const { loading: loadingCenterScheduleForWeek, data: dataCenterScheduleForWeek } = useGetCenterScheduleForWeek(
    centerId ?? '',
    timeOff.startTime ? moment(timeOff.startTime).tz(timezone).startOf('week').toISOString() : ''
  );

  // get existing time off for selected dates
  const { data: existingTimeOffData, loading: existingTimeOffLoading } = useGetTimeOffForScope({
    scopeType: centerId ? 'CENTER' : 'ENTITY',
    scopeId: centerId ?? entityId,
    startTime: timeOff.startTime ? cast<string>(moment(timeOff.startTime).tz(timezone).startOf('w').toISOString()) : '',
    endTime: timeOff.endTime ? cast<string>(moment(timeOff.endTime).tz(timezone).endOf('w').toISOString()) : '',
  });

  const timeOffForSelectedDays: { date: string; total: number; totalApproved: number; totalPending: number }[] = [];
  var i;
  for (i = 0; i < getTotalDays(timeOff); i++) {
    const date = moment(timeOff.startTime).tz(timezone).add(i, 'days');
    const timeOffOnDate =
      existingTimeOffData?.getTimeOffRequestsByScope.filter(
        (t) =>
          date.isBetween(moment(t.startTime).tz(timezone), moment(t.endTime).tz(timezone), 'days', '[]') &&
          (!centerId || t.centerId === centerId)
      ) ?? [];
    timeOffForSelectedDays.push({
      date: date.tz(timezone).format('MMM DD, YYYY'),
      total: timeOffOnDate.length,
      totalApproved: timeOffOnDate.filter((t) => t.status === 'Approved').length,
      totalPending: timeOffOnDate.filter((t) => t.status === 'Pending').length,
    });
  }

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const save = useCallback(() => {
    const input = {
      ...timeOff,
      entityId,
      centerId,
      startTime: timeOff.allDay ? moment(timeOff.startTime).tz(timezone).startOf('d').toISOString() : timeOff.startTime,
      endTime: timeOff.allDay ? moment(timeOff.endTime).tz(timezone).startOf('d').toISOString() : timeOff.endTime,
    };
    if (isRequesting) {
      createTimeOffForMeFn({ variables: { input } }).then(
        () => {
          showToast('Time off requested.', 'success');
          handleClose();
        },
        () => {
          showToast(errorMessages.generic, 'error');
          handleClose();
        }
      );
    } else {
      createTimeOffFn({ variables: { input } }).then(
        () => {
          showToast('Time off created.', 'success');
          handleClose();
        },
        () => {
          showToast(errorMessages.generic, 'error');
          handleClose();
        }
      );
    }
  }, [centerId, createTimeOffFn, createTimeOffForMeFn, entityId, handleClose, isRequesting, timeOff, timezone]);

  const filterPtoTypes = useCallback((types?: IPayCodeType[]) => {
    if (!types) {
      return [];
    }
    return types.filter((type) => !type.trainingTimeEventType) ?? [];
  }, []);

  return (
    <SideModalDrawer
      title={`${isRequesting ? 'Request' : 'Add'} Time Off`}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={() => {
        save();
      }}
      secondaryCallback={resetForm}
      primaryButtonProps={{
        disabled:
          !timeOff.personId || !timeOff.startTime || !timeOff.endTime || !timeOff.description || !timeOff.hoursApproved,
        loading: createTimeOffForMeLoading || createTimeOffLoading,
      }}
      enforceFocus={false}
    >
      <div className="mb-4">
        <small>Complete all fields below to submit.</small>
      </div>
      {!isRequesting && (
        <Select
          required
          label="Employee"
          name="personId"
          options={filteredStaffOptions || []}
          value={timeOff.personId}
          getOptionLabel={(s: IStaff) => getFullName(s)}
          getOptionValue={(s: IStaff) => s.id}
          onChange={(option: IStaff) => {
            updateTimeOff((prev) => ({ ...prev, personId: option.id }));
          }}
        />
      )}
      <TimeOffInputs
        timeOff={timeOff}
        updateTimeOff={updateTimeOff}
        timezone={timezone}
        isRequesting={isRequesting}
        // @ts-ignore
        ptoTypeOptions={filterPtoTypes(ptoTypesData?.searchPtoType.data)}
      />
      {!isRequesting && (
        <Alert className="bg-info-10 sm mb-4">
          When Time Off is submitted on behalf of an employee and saved, they will be notified once it's saved.
        </Alert>
      )}
      <Collapse in={Boolean(timeOffForSelectedDays.some((t) => t.total > 0)) && !existingTimeOffLoading}>
        <div>
          <h6 className="mb-4">Existing requests made for selected date(s):</h6>
          {timeOffForSelectedDays?.map((t, i) => (
            <div className="sm d-flex align-items-center mb-2" key={i}>
              <div className="mr-2">{t.date}:</div>
              <div className="mr-2">
                <FontAwesomeIcon icon={faCheckCircle} color={colors.success} /> Approved ({t.totalApproved})
              </div>
              <div className="mr-2">
                <FontAwesomeIcon icon={faQuestionCircle} color={colors.orange} /> Pending ({t.totalPending})
              </div>
              <div className="mr-2">
                <b>Total: {t.total}</b>
              </div>
            </div>
          ))}
        </div>
      </Collapse>
      <Collapse
        in={
          !loadingCenterScheduleForWeek &&
          dataCenterScheduleForWeek?.getCenterScheduleForWeek?.publishStatus === 'PUBLISHED'
        }
      >
        <div>
          <Alert variant="warning">A schedule has already been published for this week.</Alert>
        </div>
      </Collapse>
    </SideModalDrawer>
  );
};

export default AddTimeOffModal;
