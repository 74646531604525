import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { sortBy } from 'lodash';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { Col, Row } from 'shared/components/Layout';
import PaymentProcessing from './PaymentProcessing';
import FlaggedPayments from './FlaggedPayments';
import TransactionTotalReportDashboardCard from './TransactionTotalReportDashboardCard';
import { useDownloadCsv } from 'shared/hooks/useDownloadCsv';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { setCurrentBusinessFilters, setCurrentCenterFilters } from 'store/context/actions';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import './BillingDashboard.scss';

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const BillingDashboard: React.FC<IProps> = () => {
  const reportDataToFile = useReportDataToFile();
  const downloadCsv = useDownloadCsv();

  const user = useSelector((state: RootState) => state.user);
  const { businessFilterIds, centerFilterIds } = useSelector((state: RootState) => state.context);
  const { data: businessesData } = useGetAllowedEntities();
  const { data: centers } = useGetActiveCentersWithLoading();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const dispatch = useDispatch();

  return (
    <PageWrapper pageTitle="Billing Dashboard" applyPadding={true}>
      {centers && centers.length > 1 && (
        <div className="billing_dashboard__center_business_select_banner">
          <div className="billing_dashboard__center_business_select_banner__text">
            You are currently viewing billing data for:
          </div>
          {user?.isInternal && (
            <DropdownFilter
              title="All Businesses"
              selectedFilters={businessFilterIds}
              options={businesses.map((b) => ({ label: b.name, value: b.id }))}
              onFilterSelect={(businesses) => dispatch(setCurrentBusinessFilters(businesses.map((b) => b.value)))}
            />
          )}
          <DropdownFilter
            title={`All ${COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels.center}s`}
            selectedFilters={centerFilterIds}
            options={
              centers?.map((c) => ({ label: c.name, value: c.id })).sort((a, b) => a.label.localeCompare(b.label)) ?? []
            }
            onFilterSelect={(centers) => dispatch(setCurrentCenterFilters(centers.map((c) => c.value)))}
          />
        </div>
      )}

      <Row align="start">
        <Col lg={6}>
          <PaymentProcessing centerIds={centerFilterIds} />
        </Col>
        <Col xs={6} md={6} lg={6} xl={3}>
          <FlaggedPayments />
        </Col>
      </Row>
      <Row>
        <Col xl={6} lg={12} className="mb-4">
          <TransactionTotalReportDashboardCard
            selectedCenterIds={centerFilterIds}
            onDownloadCsv={(data) =>
              downloadCsv(reportDataToFile.transactionTotals(data), ReportTypeEnum.TRANSACTION_TOTALS_PER_WEEK)
            }
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default BillingDashboard;
