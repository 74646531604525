import moment from 'moment';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { Moment } from 'moment/moment';

export const timeRangeOptions: TimeRange[] = [
  'This Week',
  'Last 2 Weeks',
  'This Month',
  'This Quarter',
  'Last Week',
  'Last Month',
  'Last Quarter',
  'Custom',
];

export const setOtherZone = (date: moment.Moment, timezone: string) => {
  const _date = date.clone();
  const dateWithoutZone = moment(_date).format('YYYY-MM-DDTHH:mm:ss.SSS');
  const otherZone = moment.tz(_date, timezone).format('Z');
  const dateWithOtherZone = [dateWithoutZone, otherZone].join('');
  return moment(dateWithOtherZone).tz(timezone);
};

export const convert24HourTimeToFormatted = (time: string, locale = 'US'): string => {
  // may come with seconds attached, 18:00:00
  const [hours, minutes, ...rest] = time.split(':');

  // US likes their AM/PM
  if (locale === 'US') {
    const hoursInt = parseInt(hours);
    const suffix = hoursInt >= 12 ? 'PM' : 'AM';
    const formattedHours = hoursInt % 12 || 12;

    return `${formattedHours}:${minutes} ${suffix}`;
  }

  return `${hours}:${minutes}`;
};

export const isTimeRangeInvalid = (startTime?: string | null, endTime?: string | null) =>
  startTime && endTime && !moment(endTime, 'HH:mm').isAfter(moment(startTime, 'HH:mm'));

export const isDateRangeInvalid = (startDate?: string | null, endDate?: string | null) =>
  startDate && endDate && !moment(endDate).isAfter(moment(startDate));

/**
 *  get total minutes of a moment object
 */
export const minutesOfDay = (m: moment.Moment) => m.minutes() + m.hours() * 60;

/**
 * Creates a moment object representing a date and time in a certain timezone
 * @param time
 * @param date - expected to be an iso string
 * @param timezone
 * @returns moment.Moment object for the date and time in the given timezone
 */
export const addDateToTime = (time: string | null, date: string | undefined, timezone: string): moment.Moment => {
  return moment
    .utc(date ?? '', 'YYYY-MM-DDTHH:mm:ss')
    .tz(timezone)
    .hours(Number(time?.split(':')[0]))
    .minutes(Number(time?.split(':')[1]))
    .seconds(0)
    .milliseconds(0);
};

export const convertTimeRangeObjectToString: (tr: ITimeRange) => TimeRange = (tr) => {
  const { week } = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;
  let startOfWeek = moment().startOf(week);
  let endOfWeek = moment().endOf(week);
  if (moment(tr.start).isSame(startOfWeek, 'date') && moment(tr.end).isSame(endOfWeek, 'date')) {
    return 'This Week';
  }
  if (
    moment(tr.start).isSame(moment(startOfWeek).subtract(1, 'week'), 'date') &&
    moment(tr.end).isSame(moment(endOfWeek).subtract(1, 'week'), 'date')
  ) {
    return 'Last Week';
  }
  if (
    moment(tr.start).isSame(moment().startOf('month'), 'date') &&
    moment(tr.end).isSame(moment().endOf('month'), 'date')
  ) {
    return 'This Month';
  }
  if (
    moment(tr.start).isSame(moment().startOf('month').subtract(1, 'month'), 'date') &&
    moment(tr.end).isSame(moment().subtract(1, 'month').endOf('month'), 'date')
  ) {
    return 'Last Month';
  }
  if (
    moment(tr.start).isSame(moment().startOf('quarter'), 'date') &&
    moment(tr.end).isSame(moment().endOf('quarter'), 'date')
  ) {
    return 'This Quarter';
  }
  if (
    moment(tr.start).isSame(moment().startOf('quarter').subtract(1, 'quarter'), 'date') &&
    moment(tr.end).isSame(moment().endOf('quarter').subtract(1, 'quarter'), 'date')
  ) {
    return 'Last Quarter';
  }
  if (moment(tr.start).isSame(startOfWeek.subtract(1, 'week'), 'date') && moment(tr.end).isSame(endOfWeek, 'date')) {
    return 'Last 2 Weeks';
  }
  return 'Custom';
};

export const convertTimeRangeStringToObject: (tr: TimeRange) => ITimeRange = (tr) => {
  const { week } = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;
  let startOfWeek = moment().startOf(week);
  let endOfWeek = moment().endOf(week);

  switch (tr) {
    case 'This Week': {
      return {
        start: startOfWeek.format(),
        end: endOfWeek.format(),
      };
    }
    case 'Last Week': {
      return {
        start: startOfWeek.subtract(1, 'week').format(),
        end: endOfWeek.subtract(1, 'week').format(),
      };
    }
    case 'This Month': {
      return {
        start: moment().startOf('month').format(),
        end: moment().endOf('month').format(),
      };
    }
    case 'Last Month': {
      return {
        start: moment().startOf('month').subtract(1, 'month').format(),
        end: moment().subtract(1, 'month').endOf('month').format(),
      };
    }
    case 'This Quarter': {
      return {
        start: moment().startOf('quarter').format(),
        end: moment().endOf('quarter').format(),
      };
    }
    case 'Last Quarter': {
      return {
        start: moment().startOf('quarter').subtract(1, 'quarter').format(),
        end: moment().endOf('quarter').subtract(1, 'quarter').format(),
      };
    }
    case 'Last 2 Weeks': {
      return {
        start: startOfWeek.subtract(1, 'week').format(),
        end: endOfWeek.format(),
      };
    }
    default: {
      return {
        start: '',
        end: '',
      };
    }
  }
};

export const isValid12HourString = (str: string): boolean => {
  /**
   *  - 1[012]|[1-9] represents time start with 10, 11, 12 or 1, 2, …. 9
   *  - : represents time must be followed by colon(:).
   *  - [0-5][0-9] represents the time followed by 00 to 59.
   *  - (\\s)? represents white space, time followed by a white space which is optional.
   *  - (am|pm) represents time should end with ‘am’, ‘pm’ or ‘AM’, ‘PM’ (case insensitive regex)
   */
  const TWELVE_HOUR_TIME_STRING_REGEX = /^(1[012]|[1-9]):[0-5][0-9](\\s)?(am|pm)$/gim;

  return TWELVE_HOUR_TIME_STRING_REGEX.test(str);
};

export const isValid24HourString = (str: string): boolean => {
  const TWENTY_FOUR_HOUR_TIME_STRING_REGEX = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;

  return TWENTY_FOUR_HOUR_TIME_STRING_REGEX.test(str);
};

export const displayDuration = (duration: string) => {
  const mDuration = moment.duration(duration);
  const minutes = mDuration.minutes();
  const hours = Math.floor(mDuration.asHours());

  let durationstr = `${hours} hr${hours === 1 ? '' : 's'}`;
  if (minutes > 0 && hours === 0) {
    return `${minutes} min`;
  } else if (minutes > 0) {
    durationstr += ` ${minutes} min`;
  }
  return durationstr;
};

export const calculateDifferenceBetweenTimes = (
  t1: string,
  t2: string,
  unit: 'hours' | 'minutes' | 'seconds',
  returnFloating: boolean = true
): number => {
  return moment(t2).diff(moment(t1), unit, returnFloating);
};

/**
 *
 * @param d1
 * @param d2
 * @param timezone
 * @returns a list of ISO strings of dates between the two given dates
 */
export const generateListOfDatesBetweenDates = (d1: string, d2: string, timezone?: string): string[] => {
  const dates: string[] = [];
  const _timezone = timezone ?? moment.tz.guess();

  const start = moment.tz(d1, 'YYYY-MM-DD', _timezone);
  const end = moment.tz(d2, 'YYYY-MM-DD', _timezone);

  const current = start.clone().tz(_timezone);

  while (current.isSameOrBefore(end)) {
    dates.push(current.clone().toISOString());
    current.add(1, 'day').tz(_timezone);
  }

  return dates;
};

export const isDateWithinTimeframe = (date: string, start: string, end: string): boolean => {
  const dateMoment = moment(date);
  const startMoment = moment(start);
  const endMoment = moment(end);

  return dateMoment.isBetween(startMoment, endMoment, 'date', '[]');
};

export const getDateRangesOverlap = (
  dateRange1: { start: Moment; end: Moment },
  dateRange2: { start: Moment; end: Moment }
) => {
  return (
    (dateRange1.start <= dateRange2.end && dateRange2.end <= dateRange1.end) ||
    (dateRange2.start <= dateRange1.end && dateRange1.end <= dateRange2.end)
  );
};
