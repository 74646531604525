import React, { useEffect } from 'react';
import * as Mui from '@mui/material';
import PageWrapper from 'shared/components/PageWrapper';
import * as PowerBI from 'powerbi-client';
import * as PowerBIReact from 'powerbi-client-react';
import { useGetEmbedReport, useExportEmbedReport } from 'gql/reports/queries';
import * as PowerReport from './Report';
import useWindowSize from 'shared/hooks/useWindowSize';
import Spinner from 'shared/components/Spinner';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { Skeleton } from './Skeleton';
import { downloadFile } from 'shared/util/downloadFile';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

// props
interface IPowerBIProps {
  reportId: string;
  token: string;
  datasetId: string;
  onLoad: any;
}

const getWindow = () => window as any;

const Embed = ({ reportId, token, datasetId, onLoad }: IPowerBIProps) => {
  const config = { ...PowerReport.defaultConfig(token, reportId), datasetBinding: { datasetId } };

  // report handlers
  const handleRender = () => {
    onLoad(getWindow()._franchisereport);
  };

  const eventHandlers = new Map([
    //    ['pageChanged', handlePageChange],
    ['rendered', handleRender],
    ['error', PowerReport.handleError],
  ]);

  const windowSize = useWindowSize();
  React.useEffect(() => {
    const report = getWindow()._franchisereport;
    if (report && report.loaded) {
      PowerReport.handleWindowResize(getWindow()._franchisereport);
    }
  }, [windowSize]);

  return (
    <PowerBIReact.PowerBIEmbed
      key="powerbi"
      eventHandlers={eventHandlers}
      embedConfig={config}
      cssClassName="powerbi"
      getEmbeddedComponent={(embedObject: PowerBI.Embed) => {
        getWindow()._franchisereport = embedObject as PowerBI.Report;
        getWindow()._franchisereport.loaded = false;
      }}
    />
  );
};

export const FranchiseRoyaltyReport = ({}) => {
  const [isRendering, setRendering] = React.useState<boolean>(true);
  const [childReportRef, setChildReportRef] = React.useState<PowerBI.Report>();
  const [isPrinting, setPrinting] = React.useState<boolean>(false);

  const user = useSelector((state: RootState) => state.user);
  const context = useSelector((state: RootState) => state.context);
  const businessId = user?.isInternal && context.businessId != null ? context.businessId : user?.entityId ?? '';
  const [_, { loading: dataLoading, data, startPolling, refetch, stopPolling }] = useGetEmbedReport({
    variables: {
      input: {
        businessId: businessId,
        reportName: 'Royalty Report',
        reportId: null,
        embedContextId: null,
      },
    },
    onError: () => {
      setRendering(false);
      stopPolling();
      showToast('Royalty Report is not setup for this Business. Please contact your administrator', 'error');
    },
  });

  /* poll for token */
  useEffect(() => {
    startPolling(3000);
    if (!data || !data.getEmbedReport) return;
    const { reportId, id: embedContextId, status } = data?.getEmbedReport!;
    const continuePolling = status === 'Creating';
    if (continuePolling) {
      refetch({
        input: {
          businessId: businessId,
          reportId,
          embedContextId,
          reportName: '',
        },
      });
      return;
    }
    stopPolling();
    return () => {
      stopPolling();
    };
  }, [businessId, data, refetch, startPolling, stopPolling]);

  const onLoad = (report: PowerBI.Report) => {
    setChildReportRef(report);
    setRendering(false);
    PowerReport.handleReportLoaded(report);
  };

  const [exportPdf, { startPolling: startPollingExport, refetch: refetchExport, stopPolling: stopPollingExport }] =
    useExportEmbedReport({
      onCompleted: async (exportResponse) => {
        if (!isPrinting) return;

        if (!childReportRef) {
          showToast('Cannot download pdf at the moment. Please reload the page and try again.', 'error');
          return;
        }

        const finalStatus = ['Succeeded', 'Failed', 'NotFound'];
        const completedExport =
          exportResponse.exportEmbedReport &&
          finalStatus.indexOf(exportResponse.exportEmbedReport.status) > -1 &&
          exportResponse.exportEmbedReport.export;

        if (completedExport) {
          downloadFile(exportResponse.exportEmbedReport.export!.downloadUrl, 'download.pdf');
          stopPollingExport();
          setPrinting(false);
          return;
        }

        if (!data || !data.getEmbedReport) return;
        const exportVariables = JSON.parse(getWindow().localStorage.getItem('embedReportExportInput'));
        refetchExport({
          input: {
            ...exportVariables,
            exportContextId: exportResponse.exportEmbedReport.id,
          },
        });
      },
      onError: (error: any) => {
        setPrinting(false);
        stopPollingExport();
        showToast(getApolloErrorMessage(error), 'error');
      },
    });

  const printReport = async () => {
    if (!childReportRef || !data) return;
    setPrinting(true);
    const capture = await childReportRef.bookmarksManager.capture();
    const pages = await childReportRef.getPages();
    const pageNames = pages.filter((p) => p.displayName.toLowerCase().indexOf('print') > -1).map((p) => p.name);
    const exportVariables = {
      reportId: data.getEmbedReport.reportId,
      businessId: businessId,
      exportContextId: null,
      reportName: null,
      bookmarkState: capture.state ?? '',
      pages: pageNames,
    };
    getWindow().localStorage.setItem('embedReportExportInput', JSON.stringify(exportVariables));
    await exportPdf({
      variables: {
        input: exportVariables,
      },
    });
    startPollingExport(3000);
  };

  return (
    <>
      <PageWrapper
        pageTitle="Royalties"
        buttonComponent={
          <Mui.Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => printReport()}
            disabled={isPrinting}
          >
            {isPrinting ? <Spinner small className="text-gray" /> : null}
            Print
          </Mui.Button>
        }
        mobileButtonComponent={
          <Mui.Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => printReport()}
            disabled={isPrinting}
          >
            {isPrinting ? <Spinner small className="text-gray" /> : null}
            Print
          </Mui.Button>
        }
      >
        {dataLoading || isRendering ? <Skeleton /> : null}
        {data && data.getEmbedReport.token ? (
          <div style={isRendering ? { visibility: 'hidden' } : { visibility: 'visible' }}>
            {
              <Embed
                reportId={data.getEmbedReport.token.powerBiReportId}
                token={data.getEmbedReport.token.token}
                datasetId={data.getEmbedReport.token.powerBiDatasetId}
                onLoad={onLoad}
              />
            }
          </div>
        ) : null}
      </PageWrapper>
    </>
  );
};
