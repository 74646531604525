import { faMinus, faPencilAlt, faPlus, faTimes, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { uniq } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Button, { IconButton, IconButtonCircle } from 'shared/components/Buttons';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import feeStructureOptions from 'shared/constants/enums/feeStructureEnum';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import cast from 'shared/util/cast';
import { omitTypename } from 'shared/util/object';
import colors from '_colors.module.scss';
import { useUpdateFee } from 'gql/fee/mutations';
import { useGetClassesForCenter } from 'gql/class/queries';
import FeeScheduleInputs from './FeeScheduleInputs';
import Alert from 'shared/components/Alert';
import * as actions from '../duck/actions';
import useFormatDate from 'shared/hooks/useFormatDate';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { feeTypeOptions } from '../constants/feeTypeOptions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import GlCodeInput from 'shared/components/GlCodeInput';
import useIsGlCodeInputValid from 'shared/hooks/useIsGlCodeInputValid';
import { v4 } from 'uuid';
import { getActiveSchedule, sortSchedules } from '../helpers/FeeHelpers';

const newSchedule: IFeeScheduleInEdit = {
  startDate: '',
  startTime: '',
  endTime: '',
  overtimeCost: undefined,
  overtimeGracePeriod: undefined,
  overtimeInterval: undefined,
  rates: [
    {
      value: 0,
      qualifyingNumber: 1,
    },
  ],
  isNew: true,
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  fee: IFee;
  classId?: string;
  handleDeactivateFee: (fee: IFee) => void;
  handleReactivateFee: (fee: IFee) => void;
}

const EditFeeModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  fee: savedFee,
  handleDeactivateFee,
  handleReactivateFee,
}) => {
  const dispatch = useDispatch();
  // state values and functions
  const [fee, updateFee] = useState<IFeeInEdit>(savedFee);
  const [selectedScheduleId, setSelectedScheduleId] = useState<string | undefined>(
    getActiveSchedule<IFeeScheduleInEdit>(fee.schedules)?.id
  );
  const [isCreatingNewSchedule, setIsCreatingNewSchedule] = useState(false);
  const [isNewScheduleDateInvalid, setIsNewScheduleDateInvalid] = useState(false);

  // queries + data
  const { data: centers, loading: centersLoading } = useGetActiveCentersWithLoading();
  const { data, loading: classesLoading } = useGetClassesForCenter(
    {
      variables: { centerId: fee?.centerId ?? '' },
      skip: !fee?.centerId,
    },
    `id
    centerId
    name
    archivedAt
    defaultCasualFeeId
    defaultPermanentFeeId`
  );
  const classes = data?.getClassesForCenter.filter((c) => !c.archivedAt) ?? [];
  const classIdsUsingFeeAsDefault = classes
    .filter((c) => c.defaultCasualFeeId === savedFee.id || c.defaultPermanentFeeId === savedFee.id)
    .map((c) => c.id);

  // hooks
  const formatDate = useFormatDate();
  const { k2FlatRateFees } = useFlags();

  //mutations
  const [editFee, { loading: editFeeLoading }] = useUpdateFee({
    onCompleted: (data) => dispatch(actions.updateFee(data.editFee)),
  });

  //effects
  useEffect(() => {
    updateFee(savedFee);
  }, [savedFee]);

  // helper functions + data
  const showFeeType = k2FlatRateFees;
  const selectedSchedule = useMemo(() => {
    return fee.schedules.find((s) => s.id === selectedScheduleId);
  }, [fee.schedules, selectedScheduleId]);
  const activeSchedule = useMemo(() => {
    return getActiveSchedule<IFeeScheduleInEdit>(fee.schedules);
  }, [fee.schedules]);
  const isStartDateTaken = useCallback(
    (startDate: string) =>
      fee.schedules.some((s) => moment(startDate).format('MM/DD/YY') === moment(s.startDate).format('MM/DD/YY')),
    [fee.schedules]
  );

  const hasDuplicateRates = (schedule: IFeeSchedule | IFeeScheduleInEdit) =>
    schedule.rates.filter((r) => r.qualifyingNumber > 0).length !==
    uniq(schedule.rates.map((r) => r.qualifyingNumber).filter((r) => r > 0)).length;

  const hasValidOverTimeCostDetails = (schedule: IFeeSchedule | IFeeScheduleInEdit) => {
    const overTimeCostDetails = [schedule.overtimeCost, schedule.overtimeInterval, schedule.overtimeGracePeriod];
    return overTimeCostDetails.every((ovt) => Boolean(ovt)) || overTimeCostDetails.every((ovt) => !Boolean(ovt));
  };

  const { isGlCodeMandatory, isGlCodeInputValid } = useIsGlCodeInputValid(fee.glCode, fee.glCodeMapping);

  const isFormValid = Boolean(
    fee.name &&
      activeSchedule &&
      fee.schedules.every(
        (s) => s.startTime && s.endTime && moment(s.endTime, 'HH:mm').isAfter(moment(s.startTime, 'HH:mm'))
      ) &&
      fee.schedules.every((s) => s.rates.every((r) => r.qualifyingNumber)) &&
      fee.schedules.every((s) => !hasDuplicateRates(s)) &&
      fee.schedules.every((s) => hasValidOverTimeCostDetails(s)) &&
      isGlCodeInputValid
  );

  const handleUpdateFee = useCallback((value, name) => updateFee({ ...fee, [name]: value }), [fee]);

  const handleUpdateSchedule = useCallback(
    (schedule) => {
      handleUpdateFee(
        fee.schedules.map((s) => (s.id === selectedScheduleId ? { ...schedule, isDirty: true } : s)),
        'schedules'
      );
    },
    [fee.schedules, handleUpdateFee, selectedScheduleId]
  );

  const handleAddNewSchedule = useCallback(
    (newScheduleDate) => {
      if (isStartDateTaken(newScheduleDate)) {
        setIsNewScheduleDateInvalid(true);
      } else {
        setIsNewScheduleDateInvalid(false);
        const tempId = v4();
        const scheduleToAdd = {
          ...(activeSchedule ?? newSchedule),
          startDate: newScheduleDate,
          id: tempId,
          deleted: false,
          isDirty: false,
          isNew: true,
        };
        handleUpdateFee([...fee.schedules, scheduleToAdd], 'schedules');
        setSelectedScheduleId(tempId);
        setIsCreatingNewSchedule(false);
      }
    },
    [activeSchedule, fee.schedules, handleUpdateFee, isStartDateTaken]
  );

  const handleClose = useCallback(() => {
    onClose();
    updateFee(savedFee);
  }, [onClose, savedFee]);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const handleSave = useCallback(() => {
    editFee({
      variables: {
        input: cast<IFeeInEdit>(
          omitTypename(
            {
              ...fee,
              schedules: fee.schedules
                .filter((s) => !s.deleted)
                .map((s) => {
                  const result: IFeeScheduleInEdit = {
                    rates: s.rates.map((r) => ({ ...r, value: r.value ?? 0 })),
                    overtimeCost: s.overtimeCost ?? 0,
                    overtimeGracePeriod: s.overtimeGracePeriod ?? 0,
                    overtimeInterval: s.overtimeInterval ?? 0,
                    endTime: s.endTime,
                    startDate: s.startDate,
                    startTime: s.startTime,
                    id: s.isNew ? undefined : s.id,
                  };

                  return result;
                }),
            },
            true
          )
        ),
      },
    })
      .then((updatedFee) => {
        if (updatedFee.data?.editFee) {
          showToast('Fee saved successfully.', 'success');
          handleClose();
        }
      })
      .catch((err) => {
        showToast('There was an error.', 'error');
      });
  }, [editFee, fee, handleClose]);

  const schedules = useMemo(() => sortSchedules<IFeeScheduleInEdit>(fee.schedules, 'desc'), [fee.schedules]);

  return (
    <Modal centered size="xl" show={isOpen} onHide={handleClose} backdrop="static" enforceFocus={false} scrollable>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Edit Fee</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-6">
        <div className="pb-6">
          <Alert variant="info">
            <span>
              Modifying this fee will <b> only </b> affect billing for any applicable contracts <b> going forward</b>.
              Historical data for these contracts will not be adjusted.
            </span>
          </Alert>
        </div>
        <form>
          <Row align="stretch" noGutters>
            <Col md={12} lg={3} className="mr-6">
              <TextInput
                label="Fee Name"
                name="name"
                value={fee.name}
                onChange={handleUpdateFee}
                disabled={Boolean(fee.deactivatedAt)}
              />
              <GlCodeInput<IFeeInEdit>
                areaType="FEE"
                legacyValue={fee.glCode}
                glCodeMapping={fee.glCodeMapping}
                updateFn={updateFee}
                disabled={Boolean(fee.deactivatedAt)}
                required={isGlCodeMandatory}
              />
              <Select
                label={`${fieldLabels.center} Name`}
                options={centers ?? []}
                value={centers?.find((c) => c.id === fee.centerId)}
                onChange={() => {}}
                getOptionLabel={(c) => c.name}
                disabled
                isLoading={centersLoading}
              />
              <Select
                label="Class Assignment"
                isMulti
                value={classes.filter((c) => fee.classIds?.includes(c.id))}
                options={classes}
                onChange={(classes) => {
                  if (classIdsUsingFeeAsDefault.some((id) => !classes?.map((c: IClass) => c.id).includes(id))) {
                    showToast(
                      'The class you are unlinking uses this fee as a default. Please select a new default fee before unlinking',
                      'error'
                    );
                  } else {
                    handleUpdateFee(classes?.map((c: IClass) => c.id) ?? [], 'classIds');
                  }
                }}
                getOptionLabel={(c: IClass) => c.name}
                getOptionValue={(c: IClass) => c.id}
                isLoading={classesLoading}
                className="flex-wrap"
                disabled={Boolean(fee.deactivatedAt)}
              />
              {!fee.classIds?.length && (
                <Alert className="my-4" variant="warning">
                  Fee will not be available to use with contracts until a class is set.
                </Alert>
              )}
              {showFeeType && (
                <Row>
                  <Col>
                    <Select
                      label="Fee Type"
                      value={fee.feeType}
                      options={feeTypeOptions}
                      onChange={() => {}}
                      disabled
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Select
                    label="Fee Structure"
                    value={fee.feeStructure}
                    options={feeStructureOptions}
                    onChange={() => {}}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Select
                    label={fieldLabels.utilization}
                    value={fee.utilization}
                    options={[
                      { value: 0, label: '0%' },
                      { value: 0.5, label: '50%' },
                      { value: 1, label: '100%' },
                    ]}
                    onChange={() => {}}
                    disabled
                  />
                </Col>
              </Row>
            </Col>
            <Col className="border rounded p-4">
              <Row noGutters align="stretch" className="h-100">
                <Col xs={3} align="stretch" className="mr-4 h-100 flex-column d-flex">
                  <label className="d-flex">Schedule</label>
                  <div className="border rounded flex-grow-1 flex-column d-flex">
                    {isCreatingNewSchedule && (
                      <DateInput
                        autoFocus={true}
                        className="m-2"
                        dateOnly
                        date={undefined}
                        onDateSelect={(date) => {
                          if (date) handleAddNewSchedule(date);
                        }}
                        isDayBlocked={isStartDateTaken}
                        isValid={!isNewScheduleDateInvalid}
                      />
                    )}
                    {isNewScheduleDateInvalid && (
                      <div className="text-danger small ml-2 mt-n2 mb-2">
                        A fee schedule already exists for this date.
                      </div>
                    )}
                    <ul className="list-unstyled option-list w-100">
                      {schedules.map((schedule, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            if (!isCreatingNewSchedule) {
                              setSelectedScheduleId(schedule.id);
                            }
                          }}
                          className={classnames({
                            'px-4 py-2': true,
                            'cursor-pointer': !isCreatingNewSchedule,
                            'font-weight-semi-bold': schedule.id === selectedScheduleId,
                            'bg-danger-10': schedule.deleted === true,
                            'bg-success-10': schedule.isNew,
                            'bg-warning-10': schedule.isDirty,
                            'bg-pale-grey':
                              schedule.id === selectedScheduleId && !schedule.deleted && !schedule.isDirty,
                          })}
                        >
                          <div className="d-flex align-items-center ">
                            {schedule.id === selectedScheduleId && !schedule.isNew && !schedule.deleted ? (
                              <IconButton
                                icon={faTrashAlt}
                                className="mr-2"
                                iconSize="1x"
                                tooltipText="Delete schedule"
                                onClick={() => {
                                  const updatedSchedules = [...fee.schedules];
                                  const index = updatedSchedules.findIndex((s) => s.id === schedule.id);
                                  if (index !== -1) {
                                    updatedSchedules.splice(index, 1, { ...fee.schedules[index], deleted: true });
                                  }
                                  handleUpdateFee(updatedSchedules, 'schedules');
                                }}
                                disabled={Boolean(fee.deactivatedAt)}
                              />
                            ) : (
                              <div className="pr-6"></div>
                            )}
                            {schedule.startDate && formatDate(moment(schedule.startDate), 'MM/DD/YY')}
                            {(schedule.deleted || schedule.isNew || schedule.isDirty) && (
                              <FontAwesomeIcon
                                icon={schedule.deleted ? faMinus : schedule.isNew ? faPlus : faPencilAlt}
                                color={
                                  schedule.deleted ? colors.danger : schedule.isNew ? colors.success : colors.warning
                                }
                                size="xs"
                                className="ml-2"
                              />
                            )}
                            {(schedule.deleted || schedule.isNew || schedule.isDirty) && (
                              <IconButton
                                icon={faTimes}
                                className="ml-auto"
                                iconSize="1x"
                                tooltipText="Undo"
                                onClick={() => {
                                  const schedules = [...fee.schedules];
                                  const index = schedules.findIndex((s) => s.id === selectedScheduleId);
                                  if (schedule.isNew) {
                                    if (index !== -1) schedules.splice(index, 1);
                                  } else {
                                    const saved = savedFee.schedules.find((s) => s.id === selectedScheduleId);
                                    if (index !== -1 && saved) schedules.splice(index, 1, { ...saved });
                                  }
                                  handleUpdateFee(schedules, 'schedules');
                                }}
                                disabled={Boolean(fee.deactivatedAt)}
                              />
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                    {!activeSchedule && (
                      <div className="text-danger small ml-2 mt-n2 mb-2">
                        Fee must have at least one active schedule. Add an active schedule to save.
                      </div>
                    )}
                    <IconButtonCircle
                      icon={faPlus}
                      color="white"
                      backgroundColor="secondary"
                      className="mt-auto ml-auto mr-4 mb-4"
                      onClick={() => {
                        setIsCreatingNewSchedule(true);
                        setSelectedScheduleId(undefined);
                      }}
                      tooltipText="Add another schedule"
                      disabled={Boolean(fee.deactivatedAt) || isCreatingNewSchedule}
                    />
                  </div>
                </Col>
                <Col className="m-4">
                  {selectedSchedule && !isCreatingNewSchedule && (
                    <FeeScheduleInputs
                      feeSchedule={selectedSchedule}
                      updateFeeSchedule={handleUpdateSchedule}
                      feeStructure={fee.feeStructure}
                      isReadOnly={Boolean(fee.deactivatedAt)}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer className="pt-2 pb-6 px-6 border-0">
        <HasRoleAreaLevel
          action={{ area: AreaType.Center, permission: PermissionType.Fees, level: RoleLevelType.Delete }}
        >
          {fee.deactivatedAt ? (
            <Button
              variant="outline-success"
              className="mr-2"
              onClick={() => {
                handleReactivateFee(savedFee);
              }}
            >
              Reactivate
            </Button>
          ) : (
            <Button
              disabled={isCreatingNewSchedule}
              variant="outline-danger"
              className="mr-auto"
              onClick={() => {
                handleDeactivateFee(savedFee);
              }}
            >
              Deactivate
            </Button>
          )}
        </HasRoleAreaLevel>
        <Button variant="light" className="mr-2" onClick={handleClose}>
          Cancel
        </Button>
        {!fee.deactivatedAt && (
          <Button disabled={!isFormValid || isCreatingNewSchedule} loading={editFeeLoading} onClick={handleSave}>
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditFeeModal;
