import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import Alert from 'shared/components/Alert';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Select from 'shared/components/Select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { useDeleteContract, useUpdateContract } from 'gql/contract/mutations';
import * as actions from '../../../duck/actions';
import cast from 'shared/util/cast';
import { showToast } from 'shared/components/Toast';
import { omitTypename } from 'shared/util/object';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useTranslation } from 'react-i18next';
import DeleteContractConfirmationModal from './DeleteContractConfirmationModal';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useGetExpectedSessionsForAccountChild } from 'gql/session/queries';
import { isEmpty, maxBy } from 'lodash';
import DateInput from 'shared/components/DateInput';
import { isRegion } from 'shared/util/region';
import { removeTimeSlotsPostEndDateForCustomContractEndInput } from './helpers/TimeslotHelpers';

type MethodOfUpdatingContract = 'UPDATE_EXISTING' | 'END_AND_CREATE_NEW' | 'END' | 'DELETE';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const endAndCreateNewContractOptions = [
  { value: 'ROOM_CHANGE', label: 'Changing Rooms' },
  { value: 'CONTRACT_CHANGE', label: 'Change of Contract' },
  { value: 'ADDING_DAYS', label: 'Adding Days' },
  { value: 'DECREASING_DAYS', label: 'Decreasing days' },
  { value: 'CHANGING_TO_CASUAL', label: 'Changing to Casual' },
  { value: 'CHANGING_TO_PERMANENT', label: 'Changing to Permanent' },
];

const additionalReasonsForCountry: { value: string; label: string }[] = [];
if (isRegion('AU')) {
  additionalReasonsForCountry.push({ value: 'COVID', label: 'COVID' });
  additionalReasonsForCountry.push({ value: 'EXTENDED_HOLIDAYS', label: 'Extended Holidays' });
} else if (isRegion('US')) {
  additionalReasonsForCountry.push({ value: 'EXTENDED_TIME_OFF', label: 'Extended Time Off' });
  additionalReasonsForCountry.push({ value: 'EXTENDED_MEDICAL', label: 'Extended Medical' });
  additionalReasonsForCountry.push({ value: 'CENTER_DISMISSAL', label: 'Center Dismissal' });
}

const endContractOptions = [
  { value: 'LEAVE_CENTER', label: `Leaving ${fieldLabels.center}` },
  {
    value: 'TEMPORARY_ENROLLMENT',
    label: `Temporary ${fieldLabels.enrollment}`,
  },
  { value: 'ACCOUNT_DEBT', label: 'Account Debt' },
  { value: 'CARE_NOT_REQUIRED', label: 'Care not required' },
  { value: 'ENDING_CARE', label: 'Ending Care' },
  { value: 'MOVING_AWAY', label: 'Moving Away' },
  { value: 'GOING_TO_SCHOOL', label: 'Going to School' },
  { value: 'GOING_TO_KINDERGARTEN', label: 'Going to Kindergarten' },
  ...additionalReasonsForCountry,
];

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: (contract: IContract, updateMethod: MethodOfUpdatingContract) => void;
  contract: IContract;
}

const UpdateContractWithReasonModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  onContinue,
  contract: contractToUpdate,
  ...props
}) => {
  const { t } = useTranslation();
  const { k2DeleteContract } = useFlags();
  const hasDeleteContractPemission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.Contracts,
    level: RoleLevelType.Delete,
  });
  const dispatch = useDispatch();
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const timezone = timezonesByCenter[contractToUpdate.centerId] ?? moment.tz.guess();

  const [updateMethod, setUpdateMethod] = useState<MethodOfUpdatingContract | undefined>(undefined);
  const [contract, updateContract] = useState(contractToUpdate);
  const [maxContractEndDate, updateMaxContractEndDate] = useState(contractToUpdate.startDate);

  const isContractUpdatable = useMemo(() => {
    return contract.metadata?.isWritable ?? true;
  }, [contract.metadata]);
  useGetExpectedSessionsForAccountChild(
    {
      onCompleted: (result) => {
        if (result?.getExpectedSessionsForAccountChildInTimeframe.filter((s) => !isEmpty(s.timeEntries))) {
          const maxSessionDateWithTimeEntry = maxBy(
            result?.getExpectedSessionsForAccountChildInTimeframe.filter((s) => !isEmpty(s.timeEntries)),
            (s: ISession) => {
              return s.date!;
            }
          );
          if (maxSessionDateWithTimeEntry) {
            updateMaxContractEndDate(maxSessionDateWithTimeEntry.date);
          }
        }
      },
      variables: {
        input: {
          accountChildId: contract.accountChildId,
          startDate: contract.startDate,
          endDate: contract.endDate!,
        },
      },
      skip: !contract.endDate,
    },
    'id accountChildId date timeEntries { id }'
  );

  const handleClose = useCallback(() => {
    setUpdateMethod(undefined);
    onClose();
  }, [onClose]);

  const [updateContractFn, { loading }] = useUpdateContract({
    onCompleted: (data) => {
      dispatch(actions.updateContract(data.updateContract));
      showToast('Contract ended successfully.', 'success');
      handleClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const [deleteContractFn, { loading: deleteLoading }] = useDeleteContract({
    onCompleted: () => {
      showToast('Contract deleted successfully.', 'success');
      handleClose();
      setDeleteOpen(false);
      dispatch(actions.deleteContract(contractToUpdate));
    },
    onError: (error) => {
      const errorMessage = getApolloErrorMessage(error);
      setDeleteOpen(false);
      showToast(errorMessage, 'error');
    },
  });

  const handleEndContract = useCallback(() => {
    // If you change this please change UpdateContractModal.tsx
    const contractInput: IUpdateContractInput = {
      accountId: contract.accountId,
      adjustmentId: contract.adjustmentId,
      casualFeeId: contract.casualFeeId,
      centerId: contract.centerId,
      classId: contract.classId,
      cycleType: contract.cycleType,
      endDate: contract.endDate,
      id: contract.id,
      originalFeeId: contract.originalFeeId,
      permanentFeeId: contract.permanentFeeId,
      reasonForEnding: contract.reasonForEnding,
      startDate: contract.startDate,
      timeslots: contract.timeslots,
      updateType: 'END',
    };
    const contractInputWithTimeSlotsAdjustedByEndDate =
      removeTimeSlotsPostEndDateForCustomContractEndInput(contractInput);

    updateContractFn({
      variables: {
        input: cast<IContract>(omitTypename(contractInputWithTimeSlotsAdjustedByEndDate, true)),
      },
    });
  }, [contract, updateContractFn]);

  const handleDeleteContract = () => {
    setDeleteOpen(true);
  };

  const handleContinue = useCallback(() => {
    if (updateMethod === 'END') {
      handleEndContract();
    }
    if (updateMethod === 'DELETE') {
      handleDeleteContract();
    } else {
      updateMethod && onContinue(contract, updateMethod);
      handleClose();
    }
  }, [updateMethod, handleEndContract, onContinue, contract, handleClose]);

  const formValid = useMemo((): boolean => {
    let b =
      Boolean(updateMethod) &&
      ((updateMethod === 'UPDATE_EXISTING' && isContractUpdatable) ||
        updateMethod === 'DELETE' ||
        ((updateMethod === 'END_AND_CREATE_NEW' || updateMethod === 'END') &&
          Boolean(contract.endDate) &&
          Boolean(contract.reasonForEnding))); // end date and reason are only used by those two options
    return b;
  }, [updateMethod, contract.endDate, contract.reasonForEnding, isContractUpdatable]);

  useEffect(() => {
    updateContract(contractToUpdate);
  }, [contractToUpdate]);

  return (
    <>
      <DeleteContractConfirmationModal
        isOpen={isDeleteOpen}
        loading={deleteLoading}
        onClose={() => setDeleteOpen(false)}
        onSuccess={() => {}}
        handleDelete={() => {
          deleteContractFn({
            variables: {
              contractId: contractToUpdate.id,
            },
          });
          console.log(contractToUpdate.id);
        }}
      />
      <CenteredModal
        show={isOpen}
        onHide={handleClose}
        title="What would you like to do?"
        primaryChoice="Continue"
        primaryCallback={handleContinue}
        primaryButtonProps={{
          disabled: !formValid,
          loading,
        }}
        secondaryChoice="Cancel"
        secondaryCallback={handleClose}
      >
        <Form>
          <div className="d-flex flex-column">
            <div className="mb-4">
              <Form.Check className="d-flex align-items-center mb-2">
                <Form.Check.Input
                  id="end-and-create-contract-radio"
                  type="radio"
                  name="end-contract-option"
                  onClick={() => setUpdateMethod('END_AND_CREATE_NEW')}
                />
                <Form.Check.Label>
                  I am <b>creating a new </b>contract and ending an existing one.
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="d-flex align-items-center mb-2">
                <Form.Check.Input
                  id="end-contract-radio"
                  type="radio"
                  name="end-contract-option"
                  onClick={() => setUpdateMethod('END')}
                />
                <Form.Check.Label>
                  I am <b>ending the existing contract</b> with <b>no replacements</b>.
                </Form.Check.Label>
              </Form.Check>
              {hasDeleteContractPemission && (
                <Form.Check className="d-flex align-items-center mb-2">
                  <Form.Check.Input
                    id="edit-existing-radio"
                    type="radio"
                    name="end-contract-option"
                    onClick={() => setUpdateMethod('UPDATE_EXISTING')}
                  />
                  <Form.Check.Label>
                    I am <b>editing</b> the existing contract.
                  </Form.Check.Label>
                </Form.Check>
              )}

              {k2DeleteContract && hasDeleteContractPemission && (
                <Form.Check className="d-flex align-items-center mb-2">
                  <Form.Check.Input
                    id="delete-existing-radio"
                    type="radio"
                    name="end-contract-option"
                    onClick={() => setUpdateMethod('DELETE')}
                  />
                  <Form.Check.Label>
                    I am <b>deleting</b> the existing contract.
                  </Form.Check.Label>
                </Form.Check>
              )}
            </div>
            <Collapse in={updateMethod === 'END_AND_CREATE_NEW' || updateMethod === 'END'}>
              <div>
                <small className="d-block text-info font-weight-bold mb-2">
                  End date and reason for ending contract
                </small>
                <div className="d-flex flex-row w-100">
                  <div className="mr-2">
                    <DateInput
                      label="End Date"
                      date={contract.endDate}
                      onDateSelect={(newDate: string | null) => {
                        const endDate = moment(newDate).tz(timezone).startOf('day').format('MM/DD/YYYY');
                        updateContract({ ...contract, endDate });
                      }}
                      isOutsideRange={(date) => {
                        const timezonedDate = moment(date).tz(timezone).startOf('day');
                        return !timezonedDate.isAfter(maxContractEndDate, 'date');
                      }}
                      initialVisibleMonth={() => moment()}
                    />
                  </div>
                  <div className="flex-grow-1">
                    <Select
                      label="Reason"
                      options={updateMethod === 'END' ? endContractOptions : endAndCreateNewContractOptions}
                      onChange={(option) =>
                        updateContract({
                          ...contract,
                          reasonForEnding: option.value,
                        })
                      }
                    />
                  </div>
                </div>

                {updateMethod === 'END_AND_CREATE_NEW' && (
                  <div>
                    <Alert variant="danger" className="shadow-none">
                      {t('enrollment.contracts.end-and-create-new-warning')}
                    </Alert>
                  </div>
                )}

                {updateMethod === 'END' && (
                  <div>
                    <Alert variant="danger" className="shadow-none">
                      {t('enrollment.contracts.end-warning')}
                    </Alert>
                  </div>
                )}
              </div>
            </Collapse>
            <Collapse in={updateMethod === 'UPDATE_EXISTING' && !isContractUpdatable}>
              <div>
                <Alert variant="danger" className="shadow-none">
                  {t('enrollment.contracts.update-warning')}
                </Alert>
              </div>
            </Collapse>
            <Collapse in={updateMethod === 'DELETE'}>
              <div>
                <Alert variant="danger" className="shadow-none">
                  {t('enrollment.contracts.delete-warning')}
                </Alert>
              </div>
            </Collapse>
          </div>
        </Form>
      </CenteredModal>
    </>
  );
};

export default UpdateContractWithReasonModal;
