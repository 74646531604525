import React from 'react';
import './overlays.scss';

interface IProps {
  show: boolean;
  children: React.ReactNode;
}

const DisabledOverlay: React.FC<IProps> = ({ show, children, ...props }) => {
  if (!show) {
    return <> {children} </>;
  }

  return (
    <div className="position-relative kt-overlay-disabled">
      <div className="d-flex position-absolute h-100 w-100 top-0 left-0 kt-overlay" />
      {children}
    </div>
  );
};

export default DisabledOverlay;
