import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';
import Actions from './Actions';
import CCSImport from './CCSImport';
import CenterDetailsImport from './CenterDetailsImport';
import ContractsImport from './ContractsImport';
import FamiliesImport from './FamiliesImport';
import HistoryImport from './HistoryImport';
import ImportSource from './ImportSource';
import {
  useMigrateQikKidsBookingPatterns,
  useMigrateQikKidsCCSS,
  useMigrateQikKidsFamilies,
  useMigrateQikKidsHistory,
  useAdjustZeroBalance,
  useAdjustSubsidyZeroBalance,
  useFinalizeMigration,
  useMigrateQikKidsBalances,
} from 'gql/qikKidsMigration/mutation';
import './importTool.scss';
import Finalize from './Finalize';
import BalancesImport from './BalancesImport';

interface IProps {
  center?: ICenter;
}

const STEPS = {
  center: 1,
  families: 2,
  contracts: 3,
  history: 4,
  ccss: 5,
  actions: 6,
  balances: 7,
  finalize: 8,
};

const ImportTool: React.FC<IProps> = ({ center }) => {
  const { t } = useTranslation('ktAdmin');
  const [stepsCompleted, setStepsCompleted] = useState(STEPS.center);

  const [importFamilies, { loading: familiesLoading }] = useMigrateQikKidsFamilies();

  const [finalizeMigration, { loading: finalizeMigrationLoading }] = useFinalizeMigration();

  const [importContracts, { loading: contractsLoading }] = useMigrateQikKidsBookingPatterns();

  const [importBalances, { loading: balancesLoading }] = useMigrateQikKidsBalances();

  const [importHistory, { loading: historyLoading }] = useMigrateQikKidsHistory();

  const [importCCSS, { loading: CCSSLoading }] = useMigrateQikKidsCCSS();

  const [adjustZeroBalance, { loading: zeroBalanceLoading }] = useAdjustZeroBalance({
    onCompleted: () => setStepsCompleted(STEPS.actions),
  });
  const [adjustSubsidyZeroBalance, { loading: subsidyZeroBalanceLoading }] = useAdjustSubsidyZeroBalance({
    onCompleted: () => setStepsCompleted(STEPS.actions),
  });

  const handleFinalizeMigration = async (finalizeMigrationInput: IFinalizeMigrationVariables) => {
    const data = await finalizeMigration({
      variables: finalizeMigrationInput,
    });

    if (data?.data?.finalizeMigration == 1) {
      showToast(t('data-importer.import-finalized'), 'success');
      setStepsCompleted(STEPS.center);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  };

  const handleImportContracts = async (contractsInput: IQikKidsBookingPatternsVariables) => {
    const data = await importContracts({
      variables: contractsInput,
    });

    if (data?.data?.migrateQikKidsBookingPatterns) {
      if (!contractsInput.dryRun) setStepsCompleted(STEPS.contracts);
      else showToast(t('data-importer.dryrun-completed'), 'success');
    }
  };

  const handleImportBalances = async (balancesInput: IQikKidsBalancesVariables) => {
    const data = await importBalances({
      variables: balancesInput,
    });

    if (data?.data?.migrateQikKidsBalances) {
      if (!balancesInput.dryRun) setStepsCompleted(STEPS.balances);
      else showToast(t('data-importer.dryrun-completed'), 'success');
    }
  };

  const handleImportFamilies = async (familiesInput: IQikKidsFamiliesVariables) => {
    const data = await importFamilies({
      variables: familiesInput,
    });

    if (data?.data?.migrateQikKidsFamilies) {
      if (!familiesInput.dryRun) setStepsCompleted(STEPS.families);
      else showToast(t('data-importer.dryrun-completed'), 'success');
    }
  };

  const handleImportCcss = async (ccssInput: IQikKidsCCSSVariables) => {
    const data = await importCCSS({
      variables: ccssInput,
    });
    if (data?.data?.migrateQikKidsCcss) {
      if (!ccssInput.dryRun) setStepsCompleted(STEPS.ccss);
      else showToast(t('data-importer.dryrun-completed'), 'success');
    }
  };

  const handleImportHistory = async (historyInput: IQikKidsHistoryVariables) => {
    const data = await importHistory({
      variables: historyInput,
    });
    if (data?.data?.migrateQikKidsHistory) {
      if (!historyInput.dryRun) setStepsCompleted(STEPS.history);
      else showToast(t('data-importer.dryrun-completed'), 'success');
    }
  };

  const handleZeroBalance = (zeroBalanceInput: IAdjustZeroBalanceVariables) => {
    adjustZeroBalance({
      variables: zeroBalanceInput,
    });
  };
  const handleSubsidyZeroBalance = (subsidyZeroBalanceInput: IAdjustSubsidyZeroBalanceVariables) => {
    adjustSubsidyZeroBalance({
      variables: subsidyZeroBalanceInput,
    });
  };

  const finalizeImport = () => {
    showToast(t('data-importer.import-finalized'), 'success');
    // reset form
    setStepsCompleted(STEPS.center);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <div className="au-import-tool">
      <h4>Data Importer</h4>
      <ImportSource />
      <CenterDetailsImport />
      <FamiliesImport
        importFamilies={handleImportFamilies}
        center={center}
        completed={stepsCompleted >= STEPS.families}
        loading={familiesLoading}
        shouldReset={stepsCompleted === STEPS.center}
      />
      <ContractsImport
        importContracts={handleImportContracts}
        center={center}
        completed={stepsCompleted >= STEPS.contracts}
        loading={contractsLoading}
        shouldReset={stepsCompleted === STEPS.center}
      />
      <HistoryImport
        importHistory={handleImportHistory}
        center={center}
        completed={stepsCompleted >= STEPS.history}
        loading={historyLoading}
        shouldReset={stepsCompleted === STEPS.center}
      />
      <CCSImport
        importCCSS={handleImportCcss}
        center={center}
        completed={stepsCompleted >= STEPS.ccss}
        loading={CCSSLoading}
        shouldReset={stepsCompleted === STEPS.center}
      />
      <Actions
        center={center}
        adjustZeroBalance={handleZeroBalance}
        adjustSubsidyZeroBalance={handleSubsidyZeroBalance}
        zeroBalanceLoading={zeroBalanceLoading}
        subsidyZeroBalanceLoading={subsidyZeroBalanceLoading}
        shouldReset={stepsCompleted === STEPS.center}
      />
      <BalancesImport
        importBalances={handleImportBalances}
        center={center}
        completed={stepsCompleted >= STEPS.balances}
        loading={balancesLoading}
        shouldReset={stepsCompleted === STEPS.balances}
      />
      <Finalize
        center={center}
        finalizeMigration={handleFinalizeMigration}
        finalizeMigrationLoading={finalizeMigrationLoading}
        completed={stepsCompleted >= STEPS.finalize}
        shouldReset={stepsCompleted === STEPS.finalize}
      />
      {t('data-importer.finalize-import')}
      <p className="mt-2">
        <strong>{t('data-importer.steps-completed', { stepsCompleted, total: 8 })}</strong>
      </p>
    </div>
  );
};

export default ImportTool;
