import React, { useState, useCallback } from 'react';
import useUniqueId from 'shared/hooks/useUniqueId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Mui from '@mui/material';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import Form from 'react-bootstrap/Form';
import ReactSelect, { components, Props as SelectProps } from 'react-select';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import CheckboxSelectOption from './CheckboxSelectOption';
import MultiOptionMenu from './MultiOptionMenu';
import './_select.scss';

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

// NOTE: if using this component in a modal or card, you MAY have to provide `menuPortalTarget={document.body}` so that the menu doesn't get cut off
export interface IProps {
  id?: string;
  label?: string;
  required?: boolean;
  className?: string;
  options: any[];
  value: any[] | null;
  maxItemsToShow?: number;
  newLabelStyle?: boolean;
  noSearch?: boolean;
  onChange: (options: any[] | null) => void;
  placeholder?: string | null;
  /**
   * standardized way of rendering a string showing how many items have been selected in the dropdown
   */
  displayCountInContainerRenderer?: (count: number) => string;
}

const SelectMultiple: React.FC<IProps & Omit<SelectProps, 'value' | 'options' | 'onChange'>> = ({
  required = false,
  className = '',
  maxItemsToShow = 3,
  id,
  label,
  options,
  value,
  onChange,
  displayCountInContainerRenderer,
  newLabelStyle = false,
  placeholder = 'Select...',
  ...selectProps
}) => {
  const controlId = useUniqueId(id);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchInputTerm, setSearchInputTerm] = useState<string>('');

  const handleSelectAll = useCallback(
    (allSelected: boolean) => {
      onChange(allSelected ? options : []);
      setIsFocused(true); // keep menu open
    },
    [options, onChange]
  );

  // defining this inside of getComponentOverrides led to rendering issues
  const MenuList = useCallback(
    (props) => {
      return <MultiOptionMenu {...props} onSelectAll={handleSelectAll} selectedOptions={value} />;
    },
    [value, handleSelectAll]
  );

  const MultiValue = useCallback(
    (props) => {
      const { index, getValue } = props;
      const currentValue = getValue(); // our selected items
      const overflowCount = currentValue.slice(maxItemsToShow).length ?? 0;

      if (index < maxItemsToShow) {
        return <components.MultiValue {...props} />;
      }

      return index === maxItemsToShow ? (
        <components.MultiValue {...props}>+{overflowCount}</components.MultiValue>
      ) : null;
    },
    [maxItemsToShow]
  );

  const ValueContainer = useCallback((props) => {
    let [values, input] = props.children;
    const selectedValues = props.getValue() ?? [];
    const selectedCount = selectedValues.length;

    values = displayCountInContainerRenderer ? displayCountInContainerRenderer(selectedCount) : values;

    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    );
    // including `displayCountInContainerRenderer` in the dependency array would result in the menu not dismissing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComponentOverrides = useCallback(() => {
    return {
      ...(selectProps.components ?? {}),
      IndicatorSeparator: null,
      ClearIndicator: null,
      Option: CheckboxSelectOption,
      MenuList: MenuList,
      MultiValue: MultiValue,
      ...(displayCountInContainerRenderer && { ValueContainer }),
    };
  }, [MenuList, MultiValue, ValueContainer, displayCountInContainerRenderer, selectProps.components]);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      event.preventDefault();
      const { relatedTarget, target } = event;

      if (relatedTarget !== null) {
        const relatedTargetElement = relatedTarget as HTMLElement;

        // select all checkbox selected, do not unfocus the menu
        if (relatedTargetElement.getAttribute('type') === 'checkbox') {
          const isCheckedString: string = relatedTargetElement.getAttribute('data-checked') ?? ''; // custom data attribute since `checked` isn't provided
          const isChecked = isCheckedString === 'true';

          // keep focus on the react-select component so the menu doesn't remain open and becomes stuck
          event.target.focus();
          // `isChecked` will be whatever the current value of the checkbox is so negate it to reference the click
          handleSelectAll(!isChecked);
          return;
        }
      }

      if (target !== null) {
        if (target.getAttribute('data-type') === 'react-select-filter-input') {
          // there is a quirk where typing in the filter and clicking option dismissed the menu
          return;
        }
      }

      setIsFocused(false);
    },
    [handleSelectAll]
  );

  return (
    <Form.Group className={className} controlId={controlId} onBlur={handleBlur}>
      {label && newLabelStyle && (
        <Mui.FormLabel id="transaction-type-radio-group">
          <label>
            {label}
            <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
          </label>
        </Mui.FormLabel>
      )}
      {label && !newLabelStyle && (
        <div className="d-flex flex-row">
          {label && <Form.Label className="text-nowrap">{label}</Form.Label>}
          {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
        </div>
      )}
      <div className="d-flex kt-react-select-multi-select">
        <ReactSelect
          {...selectProps}
          // @ts-ignore
          placeholder={placeholder}
          isMulti
          options={options}
          isSearchable={false} // we are providing our own search input so disable the search in the dropdown
          id={controlId}
          components={getComponentOverrides()}
          className="react-select-container w-100"
          classNamePrefix="react-select"
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          onMenuInputFocus={() => setIsFocused(true)}
          menuIsOpen={isFocused || undefined}
          isFocused={isFocused || undefined}
          inputValue={searchInputTerm}
          onInputChange={setSearchInputTerm}
          value={value}
          onChange={(options, action) => onChange(options as any[])}
        />
        {required && !label && <FontAwesomeIcon className="ml-1 xxs" icon={faAsterisk} color="#FF2C2C" />}
      </div>
    </Form.Group>
  );
};

export default SelectMultiple;
