import { MutationHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import {
  IApplicationOffer,
  IApplicationScheduleOffer,
  ICreateOfferSchedule,
  ISendApplicationOffer,
  IUpdateOfferSchedule,
} from 'shared/types/application';
import { offerFields, offerScheduleFields } from './fields';

export const UPDATE_OFFER_SCHEDULE = gql`
  mutation($input: UpdateOfferScheduleInput!) {
    updateOfferSchedule(input: $input) {
      ${offerScheduleFields}
    }
  }
`;

export const CREATE_OFFER_SCHEDULE = gql`
  mutation($input: CreateOfferScheduleInput!) {
    createOfferSchedule(input: $input) {
      ${offerScheduleFields}
    }
  }
`;

export const SEND_APPLICATION_OFFER = gql`
  mutation($input: SendApplicationOfferInput!) {
    sendApplicationOffer(input: $input) {
      ${offerFields}
    }
  }
`;

export const RESEND_APPLICATION_OFFER = gql`
  mutation ($input: SendApplicationOfferInput!) {
    resendApplicationOffer(input: $input)
  }
`;

export const useCreateOfferSchedule = (
  options: MutationHookOptions<{ createOfferSchedule: IApplicationScheduleOffer }, { input: ICreateOfferSchedule }>
) => {
  return useMutation<{ createOfferSchedule: IApplicationScheduleOffer }, { input: ICreateOfferSchedule }>(
    CREATE_OFFER_SCHEDULE,
    options
  );
};

export const useUpdateOfferSchedule = (
  options: MutationHookOptions<{ updateOfferSchedule: IApplicationScheduleOffer }, { input: IUpdateOfferSchedule }>
) => {
  return useMutation<{ updateOfferSchedule: IApplicationScheduleOffer }, { input: IUpdateOfferSchedule }>(
    UPDATE_OFFER_SCHEDULE,
    options
  );
};

export const useSendApplicationOffer = (
  options: MutationHookOptions<{ sendApplicationOffer: IApplicationOffer }, { input: ISendApplicationOffer }>
) => {
  return useMutation<{ sendApplicationOffer: IApplicationOffer }, { input: ISendApplicationOffer }>(
    SEND_APPLICATION_OFFER,
    options
  );
};

export const useResendApplicationOffer = (
  options: MutationHookOptions<{ resendApplicationOffer: boolean }, { input: ISendApplicationOffer }>
) => {
  return useMutation<{ resendApplicationOffer: boolean }, { input: ISendApplicationOffer }>(
    RESEND_APPLICATION_OFFER,
    options
  );
};
