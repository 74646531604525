import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { currencyFormat } from 'shared/util/currency';

interface UnbatchedPayment {
  id: string;
  date: string;
  amount: number;
  accountName: string;
  paymentMethodType: string;
  userFriendlyId: string;
  centerName: string;
}

interface IUnbatchedPaymentsTableProps {
  canSelectPayments: boolean;
  payments: UnbatchedPayment[];
  onSelectedPaymentsChange: (selectedPayments: string[]) => void;
  selectedPayments: string[];
  loading: boolean;
}

const columns: { name: string; attribute: keyof UnbatchedPayment; formatter?: (v: any) => any }[] = [
  { name: 'Date', attribute: 'date' },
  { name: 'ID', attribute: 'userFriendlyId' },
  { name: 'Center', attribute: 'centerName' },
  { name: 'Payment Method', attribute: 'paymentMethodType' },
  { name: 'Account', attribute: 'accountName' },
  { name: 'Amount', attribute: 'amount', formatter: currencyFormat },
];
export function UnbatchedPaymentsTable(props: IUnbatchedPaymentsTableProps) {
  const { canSelectPayments, payments, onSelectedPaymentsChange, selectedPayments, loading } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={false}
              disabled={!canSelectPayments}
              onChange={(event) => {
                const checked = event.target.checked;
                if (checked) {
                  onSelectedPaymentsChange(payments.map((p) => p.id));
                } else {
                  onSelectedPaymentsChange([]);
                }
              }}
              inputProps={{
                'aria-label': 'select all payments',
              }}
            />
          </TableCell>
          {columns.map((column) => (
            <TableCell key={column.name} padding={'normal'}>
              <Typography variant="tableHeadCells">{column.name}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <LoadingRow></LoadingRow>
        ) : (
          <TableRows
            canSelectPayments={canSelectPayments}
            payments={payments}
            onSelectedPaymentsChange={onSelectedPaymentsChange}
            selectedPayments={selectedPayments}
          ></TableRows>
        )}
      </TableBody>
    </Table>
  );
}

function LoadingRow() {
  return (
    <TableRow>
      <TableCell colSpan={columns.length + 1} align="center">
        <CircularProgress></CircularProgress>
      </TableCell>
    </TableRow>
  );
}

function TableRows(props: {
  canSelectPayments: boolean;
  payments: UnbatchedPayment[];
  onSelectedPaymentsChange: (selectedPayments: string[]) => void;
  selectedPayments: string[];
}) {
  const { payments, canSelectPayments, selectedPayments, onSelectedPaymentsChange } = props;
  if (payments.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={columns.length + 1} align="center">
          No batchable payments to show
        </TableCell>
      </TableRow>
    );
  }
  return (
    <>
      {payments.map((d: UnbatchedPayment) => (
        <TableRow key={d.id}>
          <TableCell padding="checkbox">
            <Checkbox
              disabled={!canSelectPayments}
              color="primary"
              checked={!!selectedPayments.find((pId) => pId === d.id)}
              onChange={(event) => {
                const checked = event.target.checked;
                if (checked) {
                  onSelectedPaymentsChange([...selectedPayments, d.id]);
                } else {
                  onSelectedPaymentsChange(selectedPayments.filter((pId) => pId !== d.id));
                }
              }}
            />
          </TableCell>
          {columns.map((c) => (
            <TableCell>
              <Typography variant="body1">{c.formatter ? c.formatter(d[c.attribute]) : d[c.attribute]}</Typography>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}
