import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col } from 'shared/components/Layout';
import TextInput, { EmailInput } from 'shared/components/TextInput';
import AddressInput from 'shared/components/AddressInput';
import errorMessage from 'shared/constants/errorMessages';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import PhoneInput from 'shared/components/PhoneInput';
import { useUpdateBusinessProfileInfo } from '../../graphql/mutations';
import { omitTypename } from 'shared/util/object';
import { showToast } from 'shared/components/Toast';
import cast from 'shared/util/cast';
import { isBlank, isValidPhoneNumber, isValidPostalCode } from 'shared/util/string';
import Select from 'shared/components/Select/Select';
import { getTimezoneOptions } from 'shared/constants/dropdownOptions/timezoneOptions';
import { isEmailSyntaxValid, isEmailValid } from 'shared/util/email';

interface IProps {
  business: IEntity;
  readOnly?: boolean;
}

const BusinessInformationForm: React.FC<IProps> = ({ business: savedBusiness, readOnly = false }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [business, updateBusiness] = useState(savedBusiness);
  const [saveProfileInfo, { loading }] = useUpdateBusinessProfileInfo();

  useEffect(() => {
    // watch for changes in the business prop and update the state value if the prop is updated
    // ex: data is partially fetched (table data) and we're waiting for the getById query to finish with the remaining data
    if (savedBusiness) {
      updateBusiness(savedBusiness);
    }
  }, [savedBusiness]);

  const handleChange = useCallback(
    (value, name) => {
      updateBusiness({ ...business, [name]: value });
      setFormIsDirty(true);
    },
    [business]
  );

  const save = useCallback(() => {
    saveProfileInfo({
      variables: {
        input: {
          id: business.id,
          name: business.name,
          address: cast<IAddress>(omitTypename(business.address)),
          phoneNumber: business.phoneNumber,
          timezone: business.timezone,
          website: business.website,
          email: business.email,
        },
      },
    })
      .then(() => {
        showToast('Business information updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch(() => {
        showToast('There was an error updating your business information. Please try again later.', 'error');
      });
  }, [
    business.address,
    business.email,
    business.id,
    business.name,
    business.phoneNumber,
    business.timezone,
    business.website,
    saveProfileInfo,
  ]);

  const formValid =
    !isBlank(business.name) &&
    !isBlank(business.address.address1) &&
    !isBlank(business.address.city) &&
    !isBlank(business.address.state) &&
    isValidPostalCode(business.address.postalCode) &&
    isValidPhoneNumber(business.phoneNumber ?? '') &&
    (business.email ? isEmailValid(business.email) : true);

  return (
    <FormWrapper2
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onSave={save}
      onCancel={() => updateBusiness(savedBusiness)}
      loading={loading}
      saveDisabled={!formValid}
    >
      <Row>
        <Col>
          <TextInput
            label="Business Name"
            name="name"
            value={business.name}
            onChange={handleChange}
            isInvalid={isBlank(business.name)}
            errorText={errorMessage.businessName}
            required={true}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <AddressInput
            address={business.address}
            onChange={(value: IAddress) => handleChange(value, 'address')}
            errorText={errorMessage.address.business}
            addressLabel="Business Address"
            required={true}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <PhoneInput
            label="Phone Number"
            name="business-ph"
            value={business.phoneNumber || ''}
            onChange={(value: string) => handleChange(value, 'phoneNumber')}
            isInvalid={!isValidPhoneNumber(business.phoneNumber || '')}
            errorText={errorMessage.genericPhone}
            required={true}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row justify="between" align="start">
        <Col sm={12} xl={6} justify="between" className="h-100">
          <EmailInput
            label="Business Email"
            name="email"
            value={business.email}
            onChange={handleChange}
            errorText={
              !isEmailSyntaxValid(business.email ?? null)
                ? errorMessage.invalidEmailSyntax
                : errorMessage.invalidEmailDomain
            }
            isInvalid={!!business.email && !isEmailValid(business.email)}
            disabled={readOnly}
          />
        </Col>
        <Col sm={12} xl={6} justify="between" className="h-100">
          <TextInput
            label="Business URL"
            name="website"
            value={business.website}
            onChange={handleChange}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            label="Time Zone"
            value={business.timezone}
            options={getTimezoneOptions()}
            onChange={(option) => handleChange(option.value, 'timezone')}
            disabled={readOnly}
          />
        </Col>
      </Row>
      {!formValid && <small className="ml-auto">{errorMessage.formRequirements}</small>}
    </FormWrapper2>
  );
};

export default BusinessInformationForm;
