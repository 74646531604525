import React, { useCallback, useEffect, useState } from 'react';
import { useCreatePhoto } from 'pages/Photos/gql/mutations';
import { showToast } from 'shared/components/Toast';
import { useGetGalleryPresignedURL } from 'pages/Photos/gql/query';

interface IResponse {
  loading: boolean;
  data: { photoKey: string; url: string } | null;
  error: any;
}

type PhotoMetaData = {
  centerId: string;
  classId: string;
  childIds: string[];
  note: string;
  isVideo: boolean;
  takenOn: string;
  staffId: string;
};

type UploadPhotoFn = (file: File, photoData: PhotoMetaData) => Promise<void>;

const useUploadGalleryFile = (): [UploadPhotoFn, IResponse] => {
  const [response, setResponse] = useState<IResponse>({
    loading: false,
    data: null,
    error: null,
  });
  const [file, setFile] = useState<File | null>(null);
  const [fileUploadedToS3, setFileUploadedToS3] = useState<Boolean>(false);
  const [photoMetaData, setPhotoMetaData] = useState<PhotoMetaData | null>();
  const [createPhotoFn, { loading: createLoading }] = useCreatePhoto({
    onCompleted: () => {
      showToast('Photo/Video added successfully', 'success');
    },
    onError: () => {
      showToast('Error adding photo', 'error');
    },
  });

  const [presignedUrlFn, presignedUrlResponse] = useGetGalleryPresignedURL();

  const requestPresignedUrlFn = useCallback(
    async (file, photoData) => {
      setResponse({ ...response, loading: true });
      try {
        setFile(file);
        setPhotoMetaData(photoData);
        await presignedUrlFn();
      } catch (error) {
        console.log(error);
      }
    },
    [presignedUrlFn]
  );

  const presignedUrl = React.useMemo(() => presignedUrlResponse.data, [presignedUrlResponse, file]);

  useEffect(() => {
    (async () => {
      try {
        if (file && presignedUrl) {
          if (!fileUploadedToS3) {
            const formData = new FormData();
            formData.append('Content-Type', (file as File).type);
            const { fields } = presignedUrl.getPutGallerySignedUrl;
            Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
            formData.append('file', file as File);
            await fetch(presignedUrl.getPutGallerySignedUrl.url, {
              method: 'POST',
              body: formData,
            });
            setFileUploadedToS3(true);
          } else {
            if (photoMetaData) {
              const photoResponse = await createPhotoFn({
                variables: {
                  input: {
                    photoKey: presignedUrl.getPutGallerySignedUrl.fields.Key,
                    centerId: photoMetaData?.centerId,
                    classId: photoMetaData?.classId,
                    childIds: photoMetaData?.childIds,
                    note: photoMetaData?.note,
                    isVideo: photoMetaData?.isVideo,
                    takenOn: photoMetaData?.takenOn,
                    staffId: photoMetaData?.staffId,
                  },
                },
              });
              if (photoResponse) {
                setResponse({
                  error: photoResponse.errors,
                  data: {
                    photoKey: presignedUrl.getPutGallerySignedUrl.fields.Key,
                    url: presignedUrl.getPutGallerySignedUrl.url,
                  },
                  loading: false,
                });
              }
            }
          }
        }
      } catch (error) {
        setFile(null);
        setPhotoMetaData(null);
        setResponse({ error, data: null, loading: false });
      }
    })();
  }, [createPhotoFn, photoMetaData, presignedUrl, file, fileUploadedToS3]);

  return [requestPresignedUrlFn, response];
};

export default useUploadGalleryFile;
