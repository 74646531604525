import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import { useCreateDeposit } from 'gql/deposit/mutations';
import { showToast } from 'shared/components/Toast';
import './_createDepositModal.scss';
import moment from 'moment';
import DateInput from 'shared/components/DateInput';
import { useTranslation } from 'react-i18next';
import TextInput from 'shared/components/TextInput';
import Alert from 'shared/components/Alert';
import { createDeposit } from 'pages/BillingDeposits/duck/action';
import AccountSelect from 'shared/components/Select/AccountSelect';

interface IFormShape {
  date: string;
  accountIds: string[] | null;
  accounts: IAccount[] | null;
  children: IAccountChild[] | null;
  childId: string | null;
  amount: number | null;
  note: string;
}

interface IProps {
  isOpen: boolean;
  accountIds?: string[] | null;
  accounts?: IAccount[] | null;
  children?: IAccountChild[] | null;
  child?: IAccountChild | null;
  onClose: () => void;
  refetchDeposits?: () => void;
}

const CreateDepositModal: React.FC<IProps> = ({
  isOpen,
  accountIds = null,
  accounts = null,
  children = null,
  child = null,
  onClose,
  refetchDeposits = null,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IFormShape>({
    date: moment().format(),
    accountIds: accountIds ?? null,
    accounts: accounts ?? null,
    children: children ?? null,
    childId: child?.id ?? null,
    amount: null,
    note: '',
  });

  const [createDepositFn, { loading: createDepositLoading }] = useCreateDeposit({
    onCompleted: (result) => {
      if (result?.createDeposit) {
        handleClose();
        refetchDeposits && refetchDeposits();
        showToast('Deposit created successfully.', 'success');
        dispatch(createDeposit(result.createDeposit));
        // alongside inserting the deposit a transaction is been created
      }
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const formIsInvalid = useCallback((): boolean => {
    return !formData.accountIds || !formData.date || !formData.amount || formData.amount <= 0;
  }, [formData]);
  const childOptions: { value: string; label: string }[] =
    formData.accounts?.length === 1
      ? formData.accounts?.at(0)?.children?.map((tt) => ({ label: tt.firstname + ' ' + tt.lastname, value: tt.id })) ??
        []
      : [];

  const resetForm = useCallback(() => {
    setFormData({
      accountIds: null,
      accounts: null,
      children: null,
      childId: null,
      amount: null,
      note: '',
      date: moment().format(),
    });
  }, []);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  const handleSave = useCallback(() => {
    createDepositFn({
      variables: {
        input: {
          accountIds: formData.accountIds as [string],
          childId: formData.childId as string,
          amount: formData.amount as number,
          appliesDate: moment(formData.date as string).format('MM/DD/YYYY'),
          description: formData.note as string,
        },
      },
    });
  }, [formData, createDepositFn]);

  return (
    <SideModalDrawer
      title="Create Deposit"
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      secondaryChoice="Cancel"
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: formIsInvalid(), loading: createDepositLoading }}
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <Row className="mb-4">
        <Col>
          <DateInput
            required
            label="Apply Date"
            date={formData.date}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, date }))}
            placeholder={t('formatters.date')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CurrencyInput2
            className="border-radius-0"
            required
            label="Deposit Amount"
            value={formData.amount}
            onChange={(value) => setFormData((prev) => ({ ...prev, amount: value }))}
          />
        </Col>
      </Row>
      {!accounts && (
        <Row>
          <Col>
            <AccountSelect
              selectedAccountIds={formData.accountIds}
              selectedAccounts={formData.accounts}
              onSelect={(accountIds) => setFormData((prev) => ({ ...prev, accountIds }))}
              onSelectAccounts={(accounts) =>
                setFormData((prev) => ({ ...prev, children: null, childId: null, accounts }))
              }
              required
              appearance="detailed"
              showAccountStatus
              showAllAccountsOption={false}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Select
            label="Child"
            options={childOptions}
            value={formData.childId ? `${formData.childId}` : null}
            getOptionValue={(option: any) => option.id}
            disabled={formData.accountIds?.length !== 1}
            onChange={(option: any) => {
              setFormData((prev) => ({ ...prev, childId: option.value }));
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            label="Description"
            value={formData.note}
            onChange={(value) => setFormData((prev) => ({ ...prev, note: value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert variant="info">
            This account will not be billed for this deposit, it will only be recorded that the deposit was collected.
          </Alert>
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default CreateDepositModal;
