import React from 'react';
import * as Mui from '@mui/material';
import { IMessageAttachment } from 'shared/types/channel';
interface IProps {
  file: IMessageAttachment;
  onDeleteClick: (id: string) => void;
  updateContentsCb: (id: string | ArrayBuffer | null) => void;
}

const MessageAttachment: React.FC<IProps> = ({ file, onDeleteClick }) => {
  return (
    <Mui.Chip label={file.fileName} onDelete={() => onDeleteClick(file.fileName)} style={{ margin: '0 10px 10px 0' }} />
  );
};

export default MessageAttachment;
