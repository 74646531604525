import React from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  reactivateCenter: () => void;
  centers: ICenter[];
}

const ReactivateCenterModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  onClose,
  reactivateCenter,
  centers,
  ...props
}) => {
  const centerNamesArray = centers?.map((c) => c.name);
  const stringOfCenterNames =
    centers &&
    [centerNamesArray.slice(0, -1).join(', '), centerNamesArray.slice(-1)[0]].join(
      centerNamesArray.length < 2 ? '' : ' and '
    );
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  return (
    // @ts-ignore
    <ConfirmationModal
      title={`Reactivate Center${centers?.length > 1 ? 's' : ''}?`}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Reactivate"
      primaryCallback={reactivateCenter}
      primaryButtonProps={{
        variant: 'success',
        loading: isLoading,
      }}
      secondaryCallback={onClose}
    >
      <div>
        Are you sure you want to reactivate <b>{stringOfCenterNames}</b>? This will allow you to add new staff,
        children, {fieldLabels.enrollment}s, etc.
      </div>
    </ConfirmationModal>
  );
};

export default ReactivateCenterModal;
