import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniq } from 'lodash';
import moment from 'moment';
import React, { useState, useCallback } from 'react';
import Alert from 'shared/components/Alert';
import { ButtonAsLink, IconButton } from 'shared/components/Buttons';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import feeStructureOptions from 'shared/constants/enums/feeStructureEnum';
import { feeTypeOptions } from '../constants/feeTypeOptions';
import { useGetClassesForCenters } from '../../Classes/graphql/queries';
import { useCreateFee } from 'gql/fee/mutations';
import { useDispatch } from 'react-redux';
import * as actions from '../duck/actions';
import { addClassFee } from '../../Classes/duck/actions';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import {
  getRateOptionsForFeeStructure,
  feeStructureCanHaveVariableRates,
  getQualifyingNumberLabelForFeeStructure,
} from '../helpers/FeeInputHelpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { showToast } from 'shared/components/Toast';
import GlCodeInput from 'shared/components/GlCodeInput';
import useIsGlCodeInputValid from 'shared/hooks/useIsGlCodeInputValid';
import { isRegion } from 'shared/util/region';
import TimeInput from '../../../../../shared/components/TimePicker/TimeInput';
import Form from 'react-bootstrap/Form';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
const isAuRegion = isRegion('AU');

/**
 * Maps for allowed feeStructures based on feeType.
 */
const AllowedFeeStructuresForTypeMap = {
  SESSION: {
    SESSION: true,
    NUMBER_OF_DAYS: true,
    NUMBER_OF_SIBLINGS: true,
    SINGLE_FLAT_RATE: false,
  },
  FLAT_RATE: {
    SESSION: false,
    NUMBER_OF_DAYS: true,
    NUMBER_OF_SIBLINGS: false,
    SINGLE_FLAT_RATE: true,
  },
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  centerId: string;
  classId?: string;
}

const newRate: IFeeRateInput = {
  value: undefined,
  qualifyingNumber: 1,
};

const newSchedule: ICreateFeeScheduleInput = {
  startTime: '',
  endTime: '',
  overtimeCost: undefined,
  overtimeGracePeriod: undefined,
  overtimeInterval: undefined,
  rates: [newRate],
};

const newFee: ICreateFeeInput = {
  name: '',
  centerId: '',
  classIds: [],
  feeType: 'SESSION',
  feeStructure: 'SESSION',
  schedule: newSchedule,
  utilization: 1,
  glCode: '',
};

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const CreateFeeModal: React.FC<IProps> = ({ isOpen, onClose, centerId, classId }) => {
  const { k2FlatRateFees } = useFlags();
  const dispatch = useDispatch();
  const [fee, updateFee] = useState<ICreateFeeInput>({ ...newFee, centerId, classIds: classId ? [classId] : [] });
  const { data, loading: classesLoading } = useGetClassesForCenters(centerId);
  const [createFee, { loading: createFeeLoading }] = useCreateFee({
    onCompleted: (data) => {
      dispatch(actions.createFee(data.createFee));
      data.createFee.classIds.forEach((classId) => {
        dispatch(addClassFee(classId, data.createFee));
      });
    },
  });
  const classes = data?.getClassesForCenter.filter((c) => !c.archivedAt) ?? [];

  const allowedFeeTypeOptions = feeTypeOptions.filter((o) => {
    if (o.value === 'FLAT_RATE' && isAuRegion) {
      return false;
    } else if (o.value === 'FLAT_RATE') {
      return k2FlatRateFees;
    } else {
      return true;
    }
  });

  const handleUpdateFee = (value: any, name: string) => updateFee({ ...fee, [name]: value });

  const handleUpdateSchedule = (value: any, name: string) => {
    updateFee((prev) => ({ ...prev, schedule: { ...prev.schedule, [name]: value } }));
  };

  const handleUpdateRate = (rate: IFeeRateInput, index: number) => {
    handleUpdateSchedule(
      fee.schedule.rates.map((r, i) => (index === i ? rate : r)),
      'rates'
    );
  };

  const handleRemoveRate = (index: number) => {
    handleUpdateSchedule(
      fee.schedule.rates.filter((r, i) => index !== i),
      'rates'
    );
  };

  const handleAddRate = () => {
    handleUpdateSchedule([...fee.schedule.rates, { value: 0, qualifyingNumber: undefined }], 'rates');
  };

  const handleClose = () => {
    onClose();
    updateFee({ ...newFee, centerId, classIds: classId ? [classId] : [] });
  };

  const handleSave = () => {
    createFee({
      variables: {
        input: {
          ...fee,
          schedule: {
            ...fee.schedule,
            rates: fee.schedule.rates.map((r) => ({ ...r, value: r.value ?? 0 })),
            overtimeCost: fee.schedule.overtimeCost ?? 0,
            overtimeGracePeriod: fee.schedule.overtimeGracePeriod ?? 0,
            overtimeInterval: fee.schedule.overtimeInterval ?? 0,
          },
        },
      },
    })
      .then((newFee) => {
        if (newFee.data?.createFee) {
          showToast('Fee created successfully.', 'success');
          handleClose();
        }
      })
      .catch((err) => {
        showToast('There was an error.', 'error');
      });
  };

  const canHaveVariableRates = feeStructureCanHaveVariableRates(fee.feeStructure);
  const hasDuplicateRates =
    canHaveVariableRates &&
    fee.schedule.rates.filter((r) => r.qualifyingNumber > 0).length !==
      uniq(fee.schedule.rates.map((r) => r.qualifyingNumber).filter((r) => r > 0)).length;
  const quantifyingNumberLabel = getQualifyingNumberLabelForFeeStructure(fee.feeStructure);
  const invalidTime =
    fee.schedule.endTime &&
    fee.schedule.startTime &&
    !moment(fee.schedule.endTime, 'HH:mm').isAfter(moment(fee.schedule.startTime, 'HH:mm'));
  const overTimeCostDetails = [
    fee.schedule.overtimeCost,
    fee.schedule.overtimeInterval,
    fee.schedule.overtimeGracePeriod,
  ];
  const validOverTimeCostDetails =
    overTimeCostDetails.every((ovt) => Boolean(ovt)) || overTimeCostDetails.every((ovt) => !Boolean(ovt));

  const { isGlCodeMandatory, isGlCodeInputValid } = useIsGlCodeInputValid(fee.glCode, fee.glCodeMapping);

  const isFormValid =
    !hasDuplicateRates &&
    !invalidTime &&
    fee.name &&
    fee.feeStructure &&
    fee.centerId &&
    fee.schedule.startTime &&
    fee.schedule.endTime &&
    fee.schedule.rates.every((r) => r.qualifyingNumber) &&
    validOverTimeCostDetails &&
    isGlCodeInputValid;

  return (
    <SideModalDrawer
      title="New Fee"
      show={isOpen}
      onHide={handleClose}
      dialogClassName=""
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: !isFormValid, loading: createFeeLoading }}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <form>
        <TextInput label="Name" name="name" value={fee.name} onChange={handleUpdateFee} required />
        {!classId && (
          <Select
            label="Class Assignment"
            isMulti
            value={classes?.filter((c) => fee.classIds?.includes(c.id))}
            options={classes}
            onChange={(classes) => handleUpdateFee(classes?.map((c: IClass) => c.id) ?? [], 'classIds')}
            getOptionLabel={(c: IClass) => c.name}
            getOptionValue={(c: IClass) => c.id}
            isLoading={classesLoading}
            className="flex-wrap"
          />
        )}
        <Row>
          {k2FlatRateFees && (
            <Col>
              <Select
                label="Fee Type"
                value={fee.feeType}
                options={allowedFeeTypeOptions}
                onChange={(o: ISelectMenuItem) => {
                  const feeType = o.value;
                  const feeStructure = defaultFeeStructureForFeeType(feeType);
                  const rates = defaultFeeRatesForFeeStructure(feeStructure);
                  updateFee({ ...fee, feeType, feeStructure, schedule: { ...fee.schedule, rates } });
                }}
              ></Select>
            </Col>
          )}
          <Col>
            <Select
              label="Fee Structure"
              value={fee.feeStructure}
              options={feeStructureOptions.filter((o) => isFeeStructureAllowedForFeeType(fee.feeType, o.value))}
              onChange={(o: ISelectMenuItem) => {
                const feeStructure = o.value;
                const rates = defaultFeeRatesForFeeStructure(feeStructure);
                updateFee({
                  ...fee,
                  feeStructure,
                  schedule: { ...fee.schedule, rates },
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <GlCodeInput<ICreateFeeInput>
              areaType="FEE"
              legacyValue={fee.glCode}
              glCodeMapping={fee.glCodeMapping}
              updateFn={updateFee}
              required={isGlCodeMandatory}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              label={fieldLabels.utilization}
              value={fee.utilization}
              options={[
                { value: 0.5, label: '50%' },
                { value: 1, label: '100%' },
              ]}
              onChange={(option) => handleUpdateFee(option.value, 'utilization')}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="d-flex flex-row">
                <Form.Label>Start Time</Form.Label>
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              </div>
              <TimeInput
                isAM={true}
                value={fee.schedule.startTime ?? null}
                onChange={(time) => handleUpdateSchedule(time, 'startTime')}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <div className="d-flex flex-row">
                <Form.Label>End Time</Form.Label>
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              </div>
              <TimeInput
                isAM={false}
                value={fee.schedule.endTime ?? null}
                onChange={(time) => handleUpdateSchedule(time, 'endTime')}
              />
            </Form.Group>
          </Col>
        </Row>
        {invalidTime && (
          <Form.Control.Feedback style={{ display: 'inline-block' }} type="invalid">
            End time must be after start time.
          </Form.Control.Feedback>
        )}
        {fee.schedule.rates.map((rate, i) => (
          <Row key={i}>
            <Col xs={4}>
              <CurrencyInput
                label={i < 1 ? 'Cost' : undefined}
                value={rate.value ?? null}
                onChange={(value) => handleUpdateRate({ ...rate, value }, i)}
                className="mb-2 mt-3 border-radius-0"
              />
            </Col>
            {feeStructureCanHaveVariableRates(fee.feeStructure) && (
              <Col xs={3}>
                <Select
                  label={i < 1 ? quantifyingNumberLabel + 's' : undefined} //only add a label to the first rate
                  value={rate.qualifyingNumber}
                  options={getRateOptionsForFeeStructure(fee.feeStructure)}
                  onChange={(qualifyingNumber) => handleUpdateRate({ ...rate, qualifyingNumber }, i)}
                  className="mb-2"
                  placeholder="-"
                />
              </Col>
            )}
            {i > 0 && <IconButton className="mb-2 ml-2" icon={faTrashAlt} onClick={() => handleRemoveRate(i)} />}
          </Row>
        ))}
        {hasDuplicateRates && (
          <div className="text-danger sm mt-n1 mb-2">
            Multiple rates cannot exist for the same number of {quantifyingNumberLabel.toLocaleLowerCase() + 's'}.{' '}
          </div>
        )}
        {((fee.feeStructure === 'NUMBER_OF_DAYS' && fee.schedule.rates.length < 7) ||
          (fee.feeStructure === 'NUMBER_OF_SIBLINGS' && fee.schedule.rates.length < 10)) && (
          <ButtonAsLink onClick={handleAddRate}>
            {' '}
            <FontAwesomeIcon icon={faPlus} size="sm" className="mr-2" />
            Add another {quantifyingNumberLabel}
          </ButtonAsLink>
        )}
        <p className="sm font-weight-semi-bold mt-4 mb-2">Late pick-up / Early drop-off Fees</p>
        <Row align="end">
          <Col>
            <CurrencyInput
              className="border-radius-0"
              onChange={(value) => handleUpdateSchedule(value, 'overtimeCost')}
              value={fee.schedule.overtimeCost ?? null}
              label="Cost"
            />
          </Col>
          <div className="mb-6"> / </div>
          <Col>
            <NumberInput
              onChange={(value) => handleUpdateSchedule(value, 'overtimeInterval')}
              value={fee.schedule.overtimeInterval}
              appendText="min"
              numberFormat={{ allowNegative: false }}
            />
          </Col>
          <Col>
            <NumberInput
              label="Grace Period"
              onChange={(value) => handleUpdateSchedule(value, 'overtimeGracePeriod')}
              value={fee.schedule.overtimeGracePeriod}
              appendText="min"
              numberFormat={{ allowNegative: false }}
            />
          </Col>
        </Row>
        {!validOverTimeCostDetails && (
          <div className="text-danger sm mt-n1 mb-2">
            Complete all 3 late pick-up / early drop-off fees fields to continue.
          </div>
        )}
      </form>
      {fee.classIds.length < 1 && (
        <Alert className="mt-4" variant="warning">
          Fee will not be available to use with contracts until a class is set.
        </Alert>
      )}
    </SideModalDrawer>
  );
};

/**
 * Because only certain fee structures can be used with a given fee type we need to reset the fee structure
 * in case it was set to an incompatible version
 * @param feeType
 * @returns
 */
function defaultFeeStructureForFeeType(feeType: FeeType): FeeStructure {
  if (feeType === 'FLAT_RATE') {
    return 'SINGLE_FLAT_RATE';
  }
  return 'SESSION';
}

/**
 * When we change the fee structure we want to reset the fee rates to a good state based on whether or not it can have multiple rates
 * @param feeStructure
 * @returns
 */
function defaultFeeRatesForFeeStructure(feeStructure: FeeStructure) {
  const canHaveVariableRates = feeStructureCanHaveVariableRates(feeStructure);
  if (canHaveVariableRates) {
    return [
      { value: undefined, qualifyingNumber: 1 },
      { value: undefined, qualifyingNumber: 2 },
    ];
  }
  return [{ value: undefined, qualifyingNumber: 1 }];
}

/**
 * Checks if a given feeStructure is allowed for a given feeType
 * This defaults to false, in case a new feeType or feeStructure is added but not added to the maps.
 * This way the UI will give some indication that something is not setup correctly.
 * @param feeType
 * @param feeStructure
 * @returns
 */
function isFeeStructureAllowedForFeeType(feeType: FeeType, feeStructure: FeeStructure): boolean {
  const allowedFeeStructures = AllowedFeeStructuresForTypeMap[feeType];
  if (!allowedFeeStructures) {
    return false;
  }
  if (!allowedFeeStructures.hasOwnProperty(feeStructure)) return false;
  return allowedFeeStructures[feeStructure];
}

export default CreateFeeModal;
