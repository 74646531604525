import { gql } from '@apollo/client';

export const ArchiveAgencyEnrollmentMutation = gql`
  mutation ($input: ArchiveAgencyEnrollmentInput) {
    archiveAgencyEnrollment(input: $input) {
      id
      startDate
      endDate
      subsidyPaymentPortion
      notes
      childSubsidyEnrollmentId
      ignoreCopay
      excludedContractIds
      status
    }
  }
`;
