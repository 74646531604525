import React, { useCallback } from 'react';
import RBTable from 'react-bootstrap/Table';
import TotalsRow from './TotalsRow';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { stringToHueDegree } from 'shared/util/string';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  data: IClass[]; // bad
}

const EnrollmentAvailabilityTable: React.FC<IProps> = ({ data, ...props }) => {
  const calculateFteCount = useCallback(() => {
    return data.reduce((acc: number, curr: IClass) => {
      const metricsByWeek = curr.enrollmentDataForTimeframe.metricsByWeek ?? [];
      const metrics = metricsByWeek[0] ?? null;

      return metrics ? (acc += metrics.fte) : acc;
    }, 0);
  }, [data]);

  const calculateCapacityCount = useCallback(() => {
    return data.reduce((acc: number, curr: IClass) => {
      const capacity = curr.capacity ?? curr.regulation.capacity ?? 0;

      return (acc += capacity);
    }, 0);
  }, [data]);

  const calculateTotalEnrollmentsCount = useCallback(() => {
    return data.reduce((acc: number, curr: IClass) => {
      const metricsByWeek = curr.enrollmentDataForTimeframe.metricsByWeek ?? [];
      const metrics = metricsByWeek[0] ?? null;

      return metrics ? (acc += metrics.totalEnrollments) : acc;
    }, 0);
  }, [data]);

  const calculateTotalEnrollmentPercentage = (fteCount: number, capacityCount: number) =>
    capacityCount === 0 ? 0 : (fteCount / capacityCount) * 100;

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <RBTable responsive borderless className="kt-enrollment-availability-table">
      <thead>
        <tr>
          <th colSpan={2} title="Class">
            Class
          </th>
          <th title="Capacity">Capacity</th>
          <th title={`${fieldLabels.enrollment}s`}>{fieldLabels.enrollment}s</th>
          <th title="FTE Enrolled">FTE Enrolled</th>
          <th title={`${fieldLabels.enrollment} %`}>{fieldLabels.enrollment} %</th>
        </tr>
      </thead>
      <tbody>
        {data.map((c: IClass, idx: number) => {
          const metricsByWeek = c.enrollmentDataForTimeframe.metricsByWeek ?? [];
          const metrics = metricsByWeek[0] ?? null;
          const capacity = c.capacity ?? c.regulation.capacity ?? 0;

          return (
            <tr key={`class-enrollment-table-${c.id}-${idx}`}>
              <td colSpan={2}>
                <AvatarDataTableCell
                  avatar=""
                  initials={c.name.charAt(0).toUpperCase()}
                  primaryText={c.name}
                  color={`hsl(${stringToHueDegree(c.id)}, ${stringToHueDegree(c.id) < 50 ? '100%' : '40%'}, 40%`}
                />
              </td>
              <td>{capacity ?? 'N/A'}</td>
              <td>{metrics?.totalEnrollments ?? '-'}</td>
              <td>{metrics?.fte ?? '-'}</td>
              <td>{metrics?.ftePercentage.toFixed(2) ?? '-'}</td>
            </tr>
          );
        })}
        <TotalsRow
          fteCount={calculateFteCount()}
          totalEnrollmentsCount={calculateTotalEnrollmentsCount()}
          capcityCount={calculateCapacityCount()}
          totalEnrollmentPercentage={calculateTotalEnrollmentPercentage(calculateFteCount(), calculateCapacityCount())}
        />
      </tbody>
    </RBTable>
  );
};

export default EnrollmentAvailabilityTable;
