import React, { useCallback } from 'react';
import { DropdownCard } from 'shared/components/Dropdown';
import spaceIcons from 'shared/constants/SpaceIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import { IconButtonCircle, IconButton } from 'shared/components/Buttons';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

interface IProps {
  required?: boolean;
  space: INewSpace | ISpace;
  updateSpace: (space: any) => void;
  removeSpace?: () => void;
  autoFocus?: boolean;
}

const SpaceInputs: React.FC<IProps> = ({ space, updateSpace, required = true, removeSpace, autoFocus }) => {
  const handleChange = useCallback(
    (value, name) => {
      updateSpace({ ...space, [name]: value });
    },
    [space, updateSpace]
  );

  return (
    <Row align="center">
      <Col xs={7}>
        <TextInput
          name="space"
          label="Space Name"
          value={space.name}
          onChange={(value: string) => handleChange(value, 'name')}
          required={required}
          autoComplete="off"
          autoFocus
        />
      </Col>
      <Col xs={3}>
        <DropdownCard
          label="Icon"
          menuClass="w-250"
          className="w-80"
          control={
            <Row>
              <Col>
                <FontAwesomeIcon size="lg" icon={spaceIcons[space.icon]} />
              </Col>
            </Row>
          }
        >
          <p>Select an icon to represent this space:</p>
          <Row>
            {Object.keys(spaceIcons).map((iconKey, i) => (
              <Col key={i}>
                <IconButtonCircle
                  icon={spaceIcons[iconKey]}
                  iconSize="lg"
                  onClick={() => {
                    handleChange(iconKey, 'icon');
                  }}
                  className={`mb-2 ${iconKey === space.icon ? 'border border-gray' : ''}`}
                />
              </Col>
            ))}
          </Row>
        </DropdownCard>
      </Col>
      {removeSpace && (
        <Col xs={1}>
          <IconButton className="mt-2 sm-icon-width" icon={faTrashAlt} onClick={removeSpace} />
        </Col>
      )}
    </Row>
  );
};

export default SpaceInputs;
