import { gql } from '@apollo/client';
import { useLazyQuery } from 'shared/apis/core';

export const GET_MEDICAL_CONDITION_HISTORY = gql`
  query ($id: ID!) {
    getMedicalConditionHistory(id: $id) {
      id
      risk
      instructions
      documentsChanged
      symptomsChanged
      revisedAt
      revisedByPerson {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
      }
      conditionVersion {
        risk
        symptoms
        instructions
      }
    }
  }
`;

export const useGetMedicalConditionHistoryLazy = (id: string) => {
  return useLazyQuery<{ getMedicalConditionHistory: IMedicalConditionRevision[] }, { id: string }>(
    GET_MEDICAL_CONDITION_HISTORY,
    {
      variables: {
        id,
      },
      fetchPolicy: 'cache-and-network',
    }
  );
};
