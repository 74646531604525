import cast from 'shared/util/cast';

export const practitionerTypes = [
  { value: 'GeneralPractitionerDoctor', label: 'General Practitioner Doctor' },
  { value: 'Paediatrician', label: 'Paediatrician' },
  { value: 'Dentist', label: 'Dentist' },
  { value: 'Physiotherapist', label: 'Physiotherapist' },
  { value: 'Psychologist', label: 'Psychologist' },
  { value: 'Other', label: 'Other' },
];

export const getLevelLabelByCategory = (category: WellnessCategoryType) => {
  switch (category) {
    case 'Medical':
      return 'Risk';
    case 'Allergy':
      return 'Severity';
    case 'Restriction':
      return 'Importance';
  }
};

export const getSymptomsLabelByCategory = (category: WellnessCategoryType) => {
  switch (category) {
    case 'Medical':
      return 'Symptoms';
    case 'Allergy':
      return 'Reactions';
    case 'Restriction':
      return null;
  }
};

export const mapToGeneralCondition: (
  category: WellnessCategoryType,
  condition: IAllergy | IMedicalCondition | IRestriction
) => IWellnessCondition = (category, condition) => {
  const { id, childId, archivedAt, archivedByPerson, createdAt, createdByPerson, isRevised, documents } = condition;
  const base = {
    id,
    archivedAt,
    archivedByPerson,
    createdAt,
    createdByPerson,
    isRevised,
    documents,
    childId: childId ?? '',
    original: condition,
  };
  switch (category) {
    case 'Medical': {
      const medicalCondition = cast<IMedicalCondition>(condition);
      return {
        ...base,
        level: medicalCondition.risk,
        levelLabel: 'Risk',
        symptoms: medicalCondition.symptoms,
        symptomsLabel: 'Symptoms',
        freeText: medicalCondition.instructions,
        freeTextLabel: 'Care Instructions',
        name: medicalCondition.name,
        typeName: medicalCondition.type?.name ?? '',
      };
    }
    case 'Allergy': {
      const allergy = cast<IAllergy>(condition);
      return {
        ...base,
        level: allergy.severity,
        levelLabel: 'Severity',
        symptoms: allergy.reactions,
        symptomsLabel: 'Reactions',
        freeText: allergy.instructions,
        freeTextLabel: 'Care Instructions',
        name: allergy.allergen?.name ?? '',
        typeName: allergy.allergen?.type.name ?? '',
      };
    }
    case 'Restriction': {
      const restriction = cast<IRestriction>(condition);
      return {
        ...base,
        level: restriction.importance,
        levelLabel: 'Importance',
        freeText: restriction.description,
        freeTextLabel: 'Description',
        name: restriction.name,
        typeName: restriction.type?.name ?? '',
      };
    }
  }
};

export const mapToGeneralConditionRevision: (
  category: WellnessCategoryType,
  revision: WellnessConditionRevision
) => IWellnessConditionRevision = (category, revision) => {
  const { id, revisedAt, revisedByPerson, documentsChanged } = revision;
  const base = { id, revisedAt, revisedByPerson, documentsChanged };
  switch (category) {
    case 'Medical': {
      const medicalConditionRevision = cast<IMedicalConditionRevision>(revision);
      return {
        ...base,
        levelChanged: Boolean(medicalConditionRevision.risk),
        freeTextChanged: Boolean(medicalConditionRevision.instructions),
        symptomsChanged: medicalConditionRevision.symptomsChanged,
        version: mapToGeneralCondition(category, medicalConditionRevision.conditionVersion),
      };
    }
    case 'Allergy': {
      const allergyRevision = cast<IAllergyRevision>(revision);
      return {
        ...base,
        levelChanged: Boolean(allergyRevision.severity),
        freeTextChanged: Boolean(allergyRevision.instructions),
        symptomsChanged: allergyRevision.reactionsChanged,
        version: mapToGeneralCondition(category, allergyRevision.allergyVersion),
      };
    }
    case 'Restriction': {
      const restrictionRevision = cast<IRestrictionRevision>(revision);
      return {
        ...base,
        levelChanged: Boolean(restrictionRevision.importance),
        freeTextChanged: Boolean(restrictionRevision.description),
        symptomsChanged: false,
        version: mapToGeneralCondition(category, restrictionRevision.restrictionVersion),
      };
    }
  }
};
