import React, { useState } from 'react';
import MainInfoCard from '../../components/MainInfoCard';
import { CardDeck } from 'react-bootstrap';
import TableSearch from 'shared/components/DataTable/TableSearch';
import { currencyFormat } from 'shared/util/currency';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import DataTable, { TableHeader } from 'shared/components/DataTable';
import { IconButtonCircle } from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';
import '../../EndOfCareTab.scss';
import { EndCareEnrollmentExpandRow } from './EndCareEnrollmentExpandRow';
import { useGetEndCareEnrollmentMetricsInput, useGetEndCareEnrollmentPage } from '../../graphql/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { eoCareCardsData, getEnrollmentStatus, HeadersForEndOfCareActiveTable } from './common';
import { SizePerPage } from 'shared/components/DataTable';
import useDatatableState from 'shared/hooks/useDatatableState2';

type dataCardsTypes = { id: number; name: string; description: string; amount: string | number; finances: boolean };

interface IProps {}
interface Sorting {
  field: string;
  direction: string;
}

export const EndOfCareActive: React.FC<IProps> = () => {
  const entityId = useSelector((state: RootState) => state?.user?.entityId) ?? '';
  const [centerIds, setCenterIds] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const { t } = useTranslation(['billing', 'subsidies']);
  const [sort, setSort] = useState<Sorting>({
    field: '',
    direction: '',
  });
  const [tableState, tableFunctions] = useDatatableState();

  const EndCareEnrollmentPageInput = {
    input: {
      businessId: entityId,
      centerIds,
      pageNumber: tableState.activePage,
      pageSize: tableState.pageSize,
      sortDtos: sort.field && sort.direction ? sort : [],
      searchText: searchValue,
    },
  };

  const {
    loading: loadingEndCareEnrollmentPage,
    data: getEndCareEnrollmentPage,
    refetch: refetchEndCareEnrollmentPage,
  } = useGetEndCareEnrollmentPage(EndCareEnrollmentPageInput);

  const {
    data: getEndCareEnrollmentMetricsInput,
    loading: loadingGetEndCareEnrollmentMetricsInput,
    refetch: refetchEndCareEnrollmentMetricsInput,
  } = useGetEndCareEnrollmentMetricsInput({
    input: {
      businessId: entityId,
      centerIds,
    },
  });

  // @ts-ignore
  const cardsData = eoCareCardsData(getEndCareEnrollmentMetricsInput);

  const dataTable = getEndCareEnrollmentPage?.getEndCareEnrollmentPage?.data.map((item) => {
    return {
      accountChildId: item.accountChildId,
      accountId: item.accountId,
      accountName: item.accountName,
      centerId: item.centerId,
      centerName: item.centerName,
      childFirstName: `${item.childFirstName} ${item.childLastName}`,
      childLastName: item.childLastName,
      enrollmentId: item.enrollmentId,
      status: getEnrollmentStatus(item.status),
      totalDue: typeof item.totalDue === 'number' ? currencyFormat(item.totalDue) : item.totalDue,
    };
  });

  return (
    <DataTable
      activePage={tableState.activePage}
      pageSize={tableState.pageSize}
      onPageChange={tableFunctions.changePage}
      keyField="accountChildId"
      dataSize={getEndCareEnrollmentPage?.getEndCareEnrollmentPage.totalRecords ?? 0}
      data={dataTable ?? []}
      showSelect={false}
      showPagination={!loadingEndCareEnrollmentPage}
      onSort={(field, direction) => setSort({ field, direction: direction })}
      rowClasses={(row: IMedicalCondition) => (row.archivedAt ? 'kt-datatable-inactive-row' : '')}
      defaultSorting="asc"
      renderHeader={(paginationProps: any) => (
        <>
          <TableHeader className="px-1 mt-2 flex-wrap justify-content-between">
            <div className="d-flex">
              <div className={'d-flex align-items-center mr-4'}>
                <SizePerPage paginationProps={paginationProps} />
              </div>
              <TableSearch
                placeholder="Search"
                onChange={(i) => {
                  setSearchValue(i);
                  return searchValue;
                }}
                className="mr-4 my-2"
              />
            </div>

            <div className="d-flex flex-direction-row align-items-center">
              <MultipleCenterSelect
                useNullForAllOption={true}
                selectedCenterIds={centerIds}
                onSelect={(ids) => setCenterIds(!ids?.length ? [] : ids)}
                showLabel={false}
                className="flex-grow-0 w-240px mb-0"
              />
              <IconButtonCircle
                icon={faTimes}
                onClick={() => {
                  setCenterIds([]);
                  setSearchValue('');
                }}
                tooltipDirection="bottom"
                tooltipText={t('billing:transactions.batch-table.clear-filter-tool-tip')}
                className="mx-4 my-2"
              />
            </div>
          </TableHeader>

          {!loadingGetEndCareEnrollmentMetricsInput && (
            <CardDeck className={'mt-8'}>
              {cardsData.length > 0 &&
                cardsData.map((item: dataCardsTypes) => {
                  return <MainInfoCard item={item} />;
                })}
            </CardDeck>
          )}
        </>
      )}
      showLoadingOverlay={loadingEndCareEnrollmentPage}
      expandRow={(row) => (
        <EndCareEnrollmentExpandRow
          row={row}
          refetch={() => {
            refetchEndCareEnrollmentPage();
            refetchEndCareEnrollmentMetricsInput();
          }}
          loadingEndCareEnrollmentPage={loadingEndCareEnrollmentPage}
        />
      )}
      columns={HeadersForEndOfCareActiveTable}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
    />
  );
};
