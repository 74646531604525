import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { useDispatch } from 'react-redux';
import { updateContactSuccess } from '../duck/actions';
import { isRegion } from 'shared/util/region';

const isAuRegion = isRegion('AU');

const ccssAttributes = `
  ccssAttributes {
    crn
    isCrnEditable
  }
`;

const UPDATE_CONTACT_PERSONAL_INFO = gql`
  mutation ($input: UpdateContactPersonalInfoInput!) {
    updateContactPersonalInfo(input: $input) {
      id
      firstname
      lastname
      nickname
      dob
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }
      primaryPhoneNumber {
        number
        type
      }
      additionalPhoneNumbers {
        number
        type
      }
      ${isAuRegion ? ccssAttributes : ''}
    }
  }
`;

const UPDATE_CONTACT_TAGS = gql`
  mutation ($input: UpdateContactTagsInput!) {
    updateContactTags(input: $input) {
      id
      tags {
        id
        entityId
        name
        category
      }
    }
  }
`;

const UPDATE_CONTACT_EMAIL = gql`
  mutation ($input: UpdateContactEmailInput!) {
    updateContactEmail(input: $input) {
      id
      email
    }
  }
`;

export const useUpdateContactPersonalInfo = () => {
  const dispatch = useDispatch();

  return useMutation<{ updateContactPersonalInfo: IContact }, { input: IUpdateContactPersonalInfoInput }>(
    UPDATE_CONTACT_PERSONAL_INFO,
    {
      onCompleted: (data) => {
        data.updateContactPersonalInfo && dispatch(updateContactSuccess(data.updateContactPersonalInfo));
      },
    }
  );
};

export const useUpdateContactTags = () => {
  const dispatch = useDispatch();

  return useMutation<{ updateContactTags: IContact }, { input: IUpdateContactTagsInput }>(UPDATE_CONTACT_TAGS, {
    onCompleted: (data) => {
      data.updateContactTags && dispatch(updateContactSuccess(data.updateContactTags));
    },
  });
};

export const useUpdateContactEmail = () => {
  const dispatch = useDispatch();

  return useMutation<{ updateContactEmail: IContact }, { input: IUpdateContactEmailInput }>(UPDATE_CONTACT_EMAIL, {
    onCompleted: (data) => {
      data.updateContactEmail && dispatch(updateContactSuccess(data.updateContactEmail));
    },
  });
};
