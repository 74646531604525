import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import TextInput from 'shared/components/TextInput';
import { useUpdateServiceFinancialDetails } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import NotifiableServiceEventsContainer from '../NotifiableServiceEventsContainer';
import { useGetServiceFinancialDetails } from '../../graphql/queries';
import moment from 'moment';

interface IProps {}

interface IServiceFinancialDetailsInput {
  dateOfEvent: string;
  bsb: string;
  accountNumber: string;
  accountName: string;
  service: any;
}

const ServiceFinancialDetails: React.FC<IProps> = ({}) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<IServiceFinancialDetailsInput>({
    dateOfEvent: '',
    bsb: '',
    accountNumber: '',
    accountName: '',
    service: undefined,
  });
  const [cancelFormData, setCancelFormData] = useState<IServiceFinancialDetailsInput>(formData);

  const { data, loading: detailsLoading } = useGetServiceFinancialDetails({
    variables: {
      serviceId: formData.service?.value,
    },
  });

  const [updateServiceFinancialDetails, { loading: updateFinancialDetailsLoading }] = useUpdateServiceFinancialDetails({
    onCompleted: () => {
      showToast('Successfully submitted the service financial details.', 'success');
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  useEffect(() => {
    if (data?.getServiceFinancialDetails) {
      setFormData({
        ...formData,
        ...data?.getServiceFinancialDetails,
      });
      setCancelFormData({
        ...formData,
        ...data?.getServiceFinancialDetails,
      });
    }
  }, [data]);

  const validate = useCallback(() => {
    if (Object.values(formData).some((val: string) => val === '')) {
      return false;
    }
    return true;
  }, [formData]);

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }
    const { dateOfEvent, bsb, accountNumber, accountName, service } = formData;
    updateServiceFinancialDetails({
      variables: {
        input: {
          bsb,
          accountNumber,
          accountName,
          dateOfEvent: moment(dateOfEvent).format('YYYY-MM-DD'),
          serviceId: service?.value,
        },
      },
    });
  };

  return (
    <>
      <NotifiableServiceEventsContainer
        title="Service financial details"
        subTitle="Update the provider finanical details by completing the required fields below."
        onCancel={() => setFormData(cancelFormData)}
        onSave={handleSubmit}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!validate()}
        isLoading={detailsLoading}
        isSaving={updateFinancialDetailsLoading}
        serviceOnChange={(option) => setFormData({ ...formData, service: option })}
        selectedService={formData.service}
        child={
          <>
            <Row>
              <Col md={12} className="mb-2">
                <DateInput
                  label="Date of effect"
                  required
                  date={formData.dateOfEvent}
                  onDateSelect={(date: string) => setFormData({ ...formData, dateOfEvent: date })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Account Name"
                  name="accountName"
                  value={formData.accountName}
                  onChange={(value: string) => setFormData({ ...formData, accountName: value })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Account Number"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={(value: string) => setFormData({ ...formData, accountNumber: value })}
                  disabled={false}
                  maxlength="9"
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="BSB"
                  name="bsb"
                  value={formData.bsb}
                  onChange={(value: string) => setFormData({ ...formData, bsb: value })}
                  disabled={false}
                  maxlength="6"
                />
              </Col>
              <Col>{''}</Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default ServiceFinancialDetails;
