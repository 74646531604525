interface IElasticsearchSortConstants {
  [key: string]: ElasticsearchSortDirection;
}

interface IElasticsearchPredicateConstants {
  [key: string]: ElasticsearchPredicate;
}

export const DIRECTIONS: IElasticsearchSortConstants = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};

export const PREDICATES: IElasticsearchPredicateConstants = {
  EQUALS: 'EQUALS',
  CONTAINS: 'CONTAINS',
  EXISTS: 'EXISTS',
  DOES_NOT_EXIST: 'DOES_NOT_EXIST',
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED',
  DELETED: 'DELETED',
  ONE_OF: 'ONE_OF',
  STARTS_WITH: 'STARTS_WITH',
  RANGE: 'RANGE',
  BEFORE_NOW: 'BEFORE_NOW',
  AFTER_NOW: 'AFTER_NOW',
};

export const SEARCH_EXPRESSIONS = {
  ALL: 'all',
  ANY: 'any',
  TERM: 'term',
};

export default {
  DIRECTIONS,
  PREDICATES,
  SEARCH_EXPRESSIONS,
};
