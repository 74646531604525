import React from 'react';
import { Badge } from 'react-bootstrap';
import _ from 'lodash';

import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faEye, faPlayCircle } from '@fortawesome/pro-solid-svg-icons';

import { ButtonAsLink } from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import { HorizontalDivider, VerticalDivider } from 'shared/components/Dividers';
import TextInput from 'shared/components/TextInput';
import { getFullName } from 'shared/util/string';
import { showToast } from 'shared/components/Toast';
import Photo from './Photo';
import Video from './Video';
import TaggedChildren from './TaggedChildren';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useUpdatePhotoNote } from 'pages/Photos/gql/mutations';
import useFormatDate from 'shared/hooks/useFormatDate';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface IProps {
  approvedFilter: boolean | null;
  photo: IPhoto;
  onGalleryItemClick: (item: IPhoto) => void;
  isSelected: boolean;
  className?: string;
}

const GalleryItem: React.FC<IProps> = ({ approvedFilter, photo, onGalleryItemClick, isSelected, className }) => {
  const formatDate = useFormatDate();
  const hasGalleryEdit = useHasRoleAreaLevel({
    area: AreaType.Activities,
    permission: PermissionType.Photo,
    level: RoleLevelType.Edit,
  });
  const [isEditingNote, setIsEditingNote] = React.useState(false);
  const [note, setNote] = React.useState(photo.note);
  const [editedNote, setEditedNote] = React.useState(photo.note);
  const [viewPhotoModal, setViewPhotoModal] = React.useState(false);
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();

  const [updatePhotoNote] = useUpdatePhotoNote({
    onCompleted: (data) => {
      setNote(data.updatePhotoNote?.note);
      showToast('Photo Note Updated Successfully', 'success');
    },
    onError: () => {
      showToast('Error Updating Photo Note', 'error');
    },
  });

  const saveNote = () => {
    const input = {
      id: photo.id,
      note: editedNote,
    };
    updatePhotoNote({
      variables: { input: input },
    });
  };
  return (
    <>
      <Card bodyClassName={`p-2 ${className}`} style={{ width: 250 + 16 }}>
        <div className="triangle-top-right">
          <Mui.IconButton onClick={() => setViewPhotoModal(true)} style={{ position: 'absolute', top: 0, right: 0 }}>
            <FontAwesomeIcon icon={photo.isVideo ? faPlayCircle : faEye} size="sm" color={colors['secondary']} />
          </Mui.IconButton>
          {photo.isVideo ? (
            <Video
              approvedFilter={approvedFilter}
              photo={photo}
              onGalleryItemClick={onGalleryItemClick}
              isSelected={isSelected}
            />
          ) : (
            <Photo
              approvedFilter={approvedFilter}
              photo={photo}
              onGalleryItemClick={onGalleryItemClick}
              isSelected={isSelected}
            />
          )}
        </div>
        <TaggedChildren photo={photo} />
        <HorizontalDivider my={3} />
        <div className="d-flex align-items-center mb-1">
          <label className="font-weight-semi-bold mb-0">Note:</label>
          {hasGalleryEdit && (
            <div className="ml-auto">
              {isEditingNote ? (
                <div>
                  <ButtonAsLink
                    className="sm"
                    onClick={() => {
                      setIsEditingNote(false);
                    }}
                  >
                    Cancel
                  </ButtonAsLink>
                  <VerticalDivider mx={2} />
                  <ButtonAsLink
                    className="sm"
                    onClick={() => {
                      setIsEditingNote(false);
                      saveNote();
                    }}
                  >
                    Save
                  </ButtonAsLink>
                </div>
              ) : (
                <ButtonAsLink className="sm" onClick={() => setIsEditingNote(true)}>
                  Edit
                </ButtonAsLink>
              )}
            </div>
          )}
        </div>
        {isEditingNote ? (
          <TextInput className="mb-0" value={editedNote} onChange={setEditedNote} as="textarea" />
        ) : (
          <div className="sm mb-4">{note}</div>
        )}
        <div className="d-flex align-items-center justify-content-between mb-1">
          <div className="mb-0">
            <Badge variant="info">{formatDate(moment(photo.takenAt).tz(timezone), 'MM/DD/YYYY')}</Badge>
          </div>
          <div className="mb-0">
            <Badge variant="success">{getFullName(photo.takenBy)}</Badge>
          </div>
        </div>
      </Card>
      <Mui.Dialog open={viewPhotoModal} onClose={() => setViewPhotoModal(false)}>
        <Mui.DialogTitle
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          View {photo.isVideo ? `Video` : `Photo`}
          <Mui.IconButton onClick={() => setViewPhotoModal(false)}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent style={{ marginTop: '20px', textAlign: 'center' }}>
          {photo.isVideo ? (
            <video
              id="my-video"
              className="video-js"
              controls
              width={500}
              poster={
                _.isNil(photo.thumbnailKeySignedUrl)
                  ? 'https://img.myloview.com/stickers/social-media-video-icon-outline-social-media-video-vector-icon-for-web-design-isolated-on-white-background-700-247279065.jpg'
                  : photo.thumbnailKeySignedUrl
              }
              data-setup="{}"
            >
              <source src={photo.signedurl} type="video/mp4" />
              <p className="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank">
                  supports HTML5 video
                </a>
              </p>
            </video>
          ) : (
            <img
              style={{ objectFit: 'cover' }}
              alt={`${photo.createdAt}-${photo.note}`}
              src={photo.signedurl}
              width={500}
            />
          )}
          <div style={{ margin: 'auto' }}>
            <a style={{ float: 'right' }} href={photo.signedDownloadUrl}>
              Download {photo.isVideo ? `Video` : `Photo`}
            </a>
          </div>
        </Mui.DialogContent>
      </Mui.Dialog>
    </>
  );
};

export default GalleryItem;
