import React, { useContext } from 'react';
import moment from 'moment';
import { groupBy, sortBy } from 'lodash';
import classnames from 'classnames';
import ClassScheduleAccordion from './ClassScheduleAccordion';
import ControlContainer from '../ControlContainer';
import ClassScheduleDay from './ClassScheduleDay';
import colors from '_colors.module.scss';
import ScheduleDataFilterContext from '../../providers/scheduleDataFilterProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';

interface IProps {
  title: string;
  colorCode?: string;
  ratioChildren?: number;
  ratioTeacher?: number;
  idToAssociateShiftTo: string;
  shifts: IShift[];
  datesForWeek: moment.Moment[];
  calendarType: CalendarViewType;
  canAddShifts: boolean; // shifts can only be added when a schedule is in a DRAFT state and the week is the current week or in the future
  onCreateNewShift: (date: moment.Moment, idToAssociateShiftTo: string) => void; // arg is named `classId` but can also be a location id
  onShiftSelect: (shift: IShift) => void;
  showProjectedAttendance: boolean;
  loadingSchedule: boolean;
  className?: string;
  sessions?: ISession[];
  sessionsLoading?: boolean;
}

/**
 * This component houses the schedule for a single class / custom location
 */
const ClassSchedule: React.FC<IProps> = ({
  title,
  colorCode = colors.gray,
  idToAssociateShiftTo,
  ratioChildren,
  ratioTeacher,
  shifts,
  datesForWeek,
  calendarType,
  canAddShifts,
  onCreateNewShift,
  onShiftSelect,
  showProjectedAttendance = true,
  loadingSchedule,
  className = '',
  sessions = [],
  sessionsLoading,
  ...props
}) => {
  const { k2WebWeekendScheduling } = useFlags();
  const activeDataFiltersContextValue = useContext(ScheduleDataFilterContext);
  const timezonesByCenterId = useSelector((state: RootState) => state.timezone.byCenterId);
  const gridClassNames = classnames({
    'kt-schedules-class-schedule-grid': true,
    'kt-schedules-class-schedule-grid-week': calendarType === 'WEEK' && !k2WebWeekendScheduling,
    'kt-schedules-class-schedule-grid-weekend': calendarType === 'WEEK' && k2WebWeekendScheduling,
    'kt-schedules-class-schedule-grid-day': calendarType === 'DAY',
  });

  if (!idToAssociateShiftTo) {
    return null;
  }

  // filter the shilfts based on selected employees
  const filteredShifts = sortBy(
    shifts.filter((s) => !s.personId || (s.personId && activeDataFiltersContextValue.employeeIds.includes(s.personId))),
    (s) => s.startTime
  );
  /**
   * shifts are passed in as an array, it will be transformed into an object
   * keys are the day of the week (0 - sunday, 6 - saturday), value is an array of shifts for that day sorted in asecnding start time
   */
  const shiftsGroupedByDay = groupBy(filteredShifts, (item: IShift) =>
    moment(item.startTime)
      .tz(timezonesByCenterId[item.centerId ?? ''] ?? moment.tz.guess())
      .day()
  );

  /**
   * each day component needs to know the highest number of shifts created for its class for that schedule.
   * this will allow each day to render "invisible" shifts so the create shift buttons line up together
   *
   * double Math.max so we don't have -Infitinty returned
   */
  const highestNumberOfShiftsForWeek: number = Math.max(
    Math.max(...Object.values(shiftsGroupedByDay).map((arr) => arr.length)),
    0
  );
  const sessionsGroupedByDate = groupBy(sessions, (s) => s.date);
  return (
    <ControlContainer className={className}>
      <ClassScheduleAccordion
        name={title}
        classColorCode={colorCode}
        ratioTeacher={ratioTeacher}
        ratioChildren={ratioChildren}
      >
        <div className={gridClassNames}>
          {calendarType === 'WEEK' ? (
            datesForWeek.map((date: moment.Moment, idx) => (
              <ClassScheduleDay
                key={`class-schedule-date-${date.format('YYYY_MM_DD')}-${idx}`}
                showProjectedAttendance={showProjectedAttendance}
                shifts={shiftsGroupedByDay[date.day()] ?? []}
                canAddShifts={canAddShifts}
                loadingSchedule={loadingSchedule}
                onCreateNewShift={() => onCreateNewShift(date, idToAssociateShiftTo)}
                onShiftSelect={onShiftSelect}
                highestDayShiftCount={highestNumberOfShiftsForWeek}
                calendarType={calendarType}
                sessions={sessionsGroupedByDate[date.format('YYYY-MM-DD')]}
                sessionsLoading={sessionsLoading}
              />
            ))
          ) : (
            <ClassScheduleDay
              showProjectedAttendance={showProjectedAttendance}
              shifts={filteredShifts.filter((s) => moment(s.startTime).isSame(datesForWeek[0], 'date'))}
              canAddShifts={canAddShifts}
              loadingSchedule={loadingSchedule}
              onCreateNewShift={() => onCreateNewShift(datesForWeek[0], idToAssociateShiftTo)}
              onShiftSelect={onShiftSelect}
              highestDayShiftCount={highestNumberOfShiftsForWeek}
              calendarType={calendarType}
            />
          )}
        </div>
      </ClassScheduleAccordion>
    </ControlContainer>
  );
};

export default ClassSchedule;
