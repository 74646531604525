import React, { useState } from 'react';
//components
import { Col, Row } from 'react-bootstrap';
import Alert from 'shared/components/Alert';
import Card from 'shared/components/Card';
import * as Mui from '@mui/material';
import Switch from 'shared/components/Switch';
import Currency from 'shared/components/Currency';
import { Direction, Display, ICurrencySettings } from 'shared/components/Currency/Currency';
import { showToast } from 'shared/components/Toast';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Box } from '@mui/material';
//utils
import { useUpdateBillingBusinessSettings } from 'gql/glCode/mutations';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import useGetCurrencySettings from 'shared/components/Currency/useGetCurrencySettings';

interface IFormData extends ICurrencySettings {}

const DisplaySettingsTab: React.FC = () => {
  const { t } = useTranslation('billing');

  const defaultForm = {
    direction: 'Negative' as Direction,
    display: 'ParenthesizedNumbers' as Display,
    themed: true,
  };
  const [formData, setFormData] = useState<IFormData>(defaultForm);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);

  const {
    currencySettings,
    loading: currentySettingsLoading,
    refetch,
  } = useGetCurrencySettings((data) => {
    const billingSettings = data?.fetchBillingBusinessSettings;

    if (billingSettings) {
      setFormData({
        ...formData,
        direction: billingSettings.parentPaymentDirection as Direction,
        display: billingSettings.transactionAmountDisplay as Display,
        themed: billingSettings.transactionAmountThemed,
      });
    }
  });

  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? user?.entityId;
  const [updateBillingBusinessSettings, { loading: saving }] = useUpdateBillingBusinessSettings();

  const updateCurrencySettings = () => {
    const input = {
      // TODO: updateBillingBusinessSettings should NOT need a BusinessId
      businessId: businessId ?? '',
      parentPaymentDirection: formData.direction,
      transactionAmountDisplay: formData.display,
      transactionAmountThemed: formData.themed,
    };

    updateBillingBusinessSettings({
      onError: (error: ApolloError) => {
        showToast(error.message, 'error');
      },
      onCompleted: () => {
        showToast(t('settings.success-toast'), 'success');
        refetch();
      },
      variables: { input },
    });
  };

  const getCurrencyComponent = (amount: number, payment: boolean = false) => {
    return (
      <strong>
        <Currency
          direction={formData.direction}
          display={formData.display}
          payment={payment}
          themed={formData.themed}
          amount={amount}
        />
      </strong>
    );
  };

  const getExampleRow = (title: string, amount: number, payment: boolean = false) => {
    return (
      <Row>
        <Col>
          <Row>
            <Col>
              <Box className="px-3 mb-0" component="p" fontSize={18}>
                {title}
              </Box>
            </Col>
            <Col>
              <Box className="px-3 mb-0" component="p" fontSize={18}>
                {getCurrencyComponent(amount, payment)}
              </Box>
            </Col>
          </Row>
          <hr />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Alert variant="info" className="mb-3">
            <div>
              <p className="my-0">{t('settings.alert-message')}</p>
            </div>
          </Alert>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card loading={currentySettingsLoading}>
            <FormWrapper2
              formIsDirty={!formIsDirty}
              toggleDirty={setFormIsDirty}
              onSave={() => updateCurrencySettings()}
              onCancel={() => setFormData(currencySettings ?? defaultForm)}
              loading={saving}
              saveDisabled={_.isEqual(formData, currencySettings)}
            >
              <h5 className="px-2 mb-4">{t('settings.transition-title')}</h5>
              <Mui.RadioGroup
                value={formData.display}
                onChange={(_, value) => {
                  const display = value as Display;

                  setFormData({
                    ...formData,
                    direction: display == 'DirectedNumbers' ? 'Positive' : 'Negative',
                    display,
                  });
                }}
                style={{ margin: 0 }}
                className="mb-3"
              >
                <Mui.FormControlLabel
                  value={'ParenthesizedNumbers'}
                  style={{ margin: 0 }}
                  control={<Mui.Radio size="small" style={{ margin: 0 }} />}
                  label={
                    <Box component="div" fontSize={18}>
                      {t('settings.parenthesized-numbers')}
                    </Box>
                  }
                />
                <p className="mb-3 px-6 text-muted">{t('settings.parenthesized-alert')}</p>
                <Mui.FormControlLabel
                  value="DirectedNumbers"
                  style={{ margin: 0 }}
                  control={<Mui.Radio size="small" style={{ margin: 0 }} />}
                  label={
                    <Box component="div" fontSize={18}>
                      {t('settings.directed-numbers')}
                    </Box>
                  }
                />
                <p className="mb-3 px-6 text-muted">{t('settings.directed-alert')}</p>
              </Mui.RadioGroup>

              <h5 className="px-2 mb-4">{t('settings.color-title')}</h5>
              <Switch
                value={formData.themed}
                checkedText="YES"
                uncheckedText="NO"
                onChange={(value) => {
                  setFormData({ ...formData, themed: value });
                }}
                className="mb-6"
              />

              <Row>
                <Col>
                  <h5 className="px-3 mb-4">{t('settings.example')}</h5>
                  <hr />
                </Col>
              </Row>
              {getExampleRow(t('settings.session-fee'), -123.45)}
              {getExampleRow(t('settings.discount'), 12.34)}
              {getExampleRow(t('settings.payment'), 1234.56, true)}
            </FormWrapper2>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export { DisplaySettingsTab };
