import React from 'react';

interface IRateInfoProps {
  rateValue: number;
  rateUnitLabel: string;
}
export function RateInfo(props: IRateInfoProps) {
  return (
    <div>
      <div>${props.rateValue}</div>
      <small>Per {props.rateUnitLabel}</small>
    </div>
  );
}
