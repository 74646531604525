import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInput } from 'shared/components/TextInput';
import { Page, SecondContactSectionMetadata, SectionMetadata } from 'shared/types/enrollment-form';
import { getMinContactMetadataErrors } from '../../utils';
import { cloneDeep } from 'lodash';
import './min-required-contact.scss';

export type MinRequiredContactProps = {
  page: Page;
  onSectionMetadataChange: (sectionMetadata: SectionMetadata) => void;
};

export default function MinRequiredContact({ page, onSectionMetadataChange }: MinRequiredContactProps) {
  const { t } = useTranslation(['enrollment']);
  const sectionMetadata = useMemo((): SectionMetadata<SecondContactSectionMetadata | undefined> => {
    return page.sections.find((section) => section.namespace === 'contacts')!
      .sectionMetadata as SectionMetadata<SecondContactSectionMetadata>;
  }, [page.sections]);

  const { minContacts, minEmergencyContactPerChild, minPermissionToPickUpPerChild } = useMemo((): Omit<
    SecondContactSectionMetadata,
    'type'
  > => {
    const metadata = sectionMetadata;
    return {
      minContacts: metadata?.properties?.minContacts,
      minEmergencyContactPerChild: metadata?.properties?.minEmergencyContactPerChild,
      minPermissionToPickUpPerChild: metadata?.properties?.minPermissionToPickUpPerChild,
    };
  }, [sectionMetadata]);

  const errors = useMemo<{ [key: string]: string | undefined }>(
    () => getMinContactMetadataErrors(minContacts, minEmergencyContactPerChild, minPermissionToPickUpPerChild),
    [minContacts, minEmergencyContactPerChild, minPermissionToPickUpPerChild]
  );

  const updateValue = useCallback(
    (name: keyof Omit<SecondContactSectionMetadata, 'type'>, value) => {
      let metadata = cloneDeep(sectionMetadata);

      if (!metadata) {
        metadata = {
          type: 'SecondContactSectionMetadata',
          properties: {},
        } as SectionMetadata<SecondContactSectionMetadata>;
      }

      metadata!.properties![name] = Number(value);

      onSectionMetadataChange({ ...metadata });
    },
    [sectionMetadata, onSectionMetadataChange]
  );

  return (
    <div className="min-required-contact">
      <h6>{t('enrollment:form.min-required-contact-widget.title')}</h6>
      <div className="d-flex">
        <NumberInput
          label={t('enrollment:form.min-required-contact-widget.min-contacts')}
          value={minContacts}
          onChange={(value) => updateValue('minContacts', value)}
          className="min-required-contact-input"
          required
          numberFormat={{
            allowNegative: false,
            decimalScale: 0,
          }}
          isInvalid={Boolean(errors.minContracts)}
        />
        <NumberInput
          label={t('enrollment:form.min-required-contact-widget.min-emergency')}
          value={minEmergencyContactPerChild}
          onChange={(value) => updateValue('minEmergencyContactPerChild', value)}
          className="min-required-contact-input"
          required
          numberFormat={{
            allowNegative: false,
            decimalScale: 0,
          }}
          isInvalid={Boolean(errors.minEmergencyContactPerChild)}
        />
        <NumberInput
          label={t('enrollment:form.min-required-contact-widget.min-pick-up')}
          value={minPermissionToPickUpPerChild}
          onChange={(value) => updateValue('minPermissionToPickUpPerChild', value)}
          className="min-required-contact-input"
          required
          numberFormat={{
            allowNegative: false,
            decimalScale: 0,
          }}
          isInvalid={Boolean(errors.minPermissionToPickUpPerChild)}
        />
      </div>
      {Object.values(errors).map((error) => (
        <small className="text-danger d-block">{error}</small>
      ))}
    </div>
  );
}
