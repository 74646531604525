import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PageWrapper from 'shared/components/PageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import AdpTab from './AdpTab/AdpTab';
import CcrmIntegrationCard from '../../Businesses/subroutes/BusinessProfile/components/IntegrationsTab/Ccrm/CcrmIntegrationCard';
import KisiTab from '../../Businesses/subroutes/BusinessProfile/components/KisiTab/KisiTab';
import { Col, Row } from 'react-bootstrap';
import useHasRoleAreaLevel from '../../../shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from '../../../shared/constants/enums/permissionsEnums';
import { useGetEntity } from '../../../gql/business/queries';
import { getBusinessSuccess } from '../../Businesses/subroutes/BusinessProfiles/duck/actions';
import { GET_ENTITY } from '../../Businesses/subroutes/BusinessProfile/graphql/fields';
import { isRegion } from '../../../shared/util/region';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Migrations from '../Migrations/Migrations';

type IntegrationsTabs = 'ADP' | 'CCRM' | 'KISI';
interface IRouteParams {
  activeTab: IntegrationsTabs;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const Integrations: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { activeTab } = props.match.params;
  const [activeTabKey, setActiveTabKey] = useState<IntegrationsTabs>(activeTab ?? 'ADP');
  const { k2KisiIntegration, k2CcrmIntegration } = useFlags();
  const [kisiApiKey, setKisiApiKey] = useState('');
  const user = useSelector((state: RootState) => state.user);

  const entityId = useSelector((state: RootState) => state.context.businessId) ?? '';

  const { loading, error } = useGetEntity(
    {
      variables: {
        id: entityId,
      },
      onCompleted: (data) => {
        dispatch(getBusinessSuccess(data.getEntity));
        setKisiApiKey(data.getEntity.kisiApiKey ?? '');
      },
    },
    GET_ENTITY
  );

  const history = useHistory();

  const hasBusinessLevelPermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  React.useEffect(() => {
    setActiveTabKey(activeTab ?? 'ADP');
  }, [activeTab]);

  return (
    <PageWrapper pageTitle="Integrations" applyPadding={false}>
      <Tabs
        id="setting-integrations-tabs"
        activeKey={activeTabKey}
        onSelect={(tab) => {
          setActiveTabKey(tab as IntegrationsTabs);
          history.push(`/settings/integrations/${tab}`);
        }}
        style={{
          paddingLeft: '16px',
          position: 'sticky',
          top: '119px',
          zIndex: 900,
        }}
        className="mb-0"
      >
        <Tab eventKey="ADP" title="ADP">
          <AdpTab />
        </Tab>

        {k2CcrmIntegration && (
          <Tab eventKey="CCRM" title="CRM">
            <Row style={{ padding: '32px' }}>
              <Col>
                <CcrmIntegrationCard businessId={entityId} />
              </Col>
            </Row>
          </Tab>
        )}

        {user?.isInternal && (
          <Tab eventKey="Migrations" title="Migrations">
            <Row style={{ padding: '32px' }}>
              <Col>
                <Migrations businessId={entityId} />
              </Col>
            </Row>
          </Tab>
        )}

        {isRegion('US') && k2KisiIntegration && hasBusinessLevelPermission && !user?.isInternal && (
          <Tab eventKey="KISI" title="KISI">
            <Row style={{ padding: '32px' }}>
              <Col>
                <KisiTab entityId={entityId} kisiApiKey={kisiApiKey} />
              </Col>
            </Row>
          </Tab>
        )}
      </Tabs>
    </PageWrapper>
  );
};

export default Integrations;
