import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { RootState } from 'store/reducers';
import { useHistory } from 'react-router-dom';
import { useGetAccountDepositsBalance } from 'gql/deposit/queries';
import { convertTimeRangeStringToObject } from 'shared/util/timeUtils';
import useDatatableState from 'shared/hooks/useDatatableState2';
import DepositsAccountTable from './components/DepositsAccountTable';

interface IProps {}

const DepositAccounts: React.FC<IProps> = ({ ...props }) => {
  const { businessId, centerFilterIds } = useSelector((state: RootState) => state.context);
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState<IBillingAccountSort[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const [filteredStatus, setFilteredStatus] = useState<string[]>(['Active']);

  const [dateRange] = useState(convertTimeRangeStringToObject('This Week'));
  const { data: centers } = useGetActiveCentersWithLoading();

  const { data: accountDeposits, loading } = useGetAccountDepositsBalance({
    variables: {
      input: {
        businessId: businessId ?? '',
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        centerIds: centerFilterIds,
        start: moment.utc(dateRange.start).format('YYYY-MM-DD'),
        end: moment.utc(dateRange.end).format('YYYY-MM-DD'),
        sortBy: sort[0].field,
        sortDirection: sort[0].direction === 'ASCENDING' ? 'asc' : 'desc',
        searchKey: searchTerm,
        statusType:
          !!filteredStatus && !!filteredStatus.length
            ? filteredStatus.length > 1
              ? undefined
              : (filteredStatus[0] as AccountStatusType)
            : undefined,
      },
    },
    skip: businessId == null || businessId === '',
  });

  const history = useHistory();
  const navigateTo = useCallback(
    (route: string, params: Record<string, string> = {}) => {
      history.push(route, params);
    },
    [history]
  );

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSort([{ field, direction }]);
  }, []);

  const handleSearchDebounced = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      tableFunctions.changePage(1, tableState.pageSize);
    }, 250),
    []
  );

  const handleFilteredStatus = (value: string[]) => {
    setFilteredStatus(value);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  return (
    <DepositsAccountTable
      totalResults={accountDeposits?.getAccountDepositsBalance?.totalRecords ?? 0}
      tableState={tableState}
      tableFunctions={tableFunctions}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      loading={loading}
      data={accountDeposits?.getAccountDepositsBalance?.data ?? []}
      onSort={handleSort}
      centers={centers}
      filteredStatus={filteredStatus}
      setSearchTerm={handleSearchDebounced}
      onStatusFilter={handleFilteredStatus}
      navigateToChildProfile={(child: IAccountChild) => navigateTo(`/families/children/${child.id}`)}
    />
  );
};

export default DepositAccounts;
