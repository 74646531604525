import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './_selectedItemsTotal.scss';

interface IProps {
  totalRecords: number;
  selectedRows: number;
  allSelected: boolean;
  handleClearSelectedRows: () => void;
  handleSelectAllRows: () => void;
  centerSelect?: boolean;
  className?: string;
}

const SelectedItemsTotal: React.FC<IProps> = ({
  totalRecords,
  selectedRows,
  allSelected,
  handleClearSelectedRows,
  handleSelectAllRows,
  centerSelect,
  className,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'enrollment']);

  return (
    <>
      <Alert variant="info" className={`selected-items ${className}`}>
        {allSelected && (
          <p>
            <b>{totalRecords}</b>
            <span> are selected.</span>
            <span onClick={handleClearSelectedRows} className="clickable">
              Clear all selected
            </span>
          </p>
        )}
        {!allSelected && (
          <p>
            <span>
              <b>{selectedRows}</b> selected.
            </span>
            <span onClick={handleSelectAllRows} className="clickable">
              Select all {totalRecords} {centerSelect ? t('translation:spelling.center_plural') : 'accounts'}
            </span>
          </p>
        )}
      </Alert>
    </>
  );
};

export default SelectedItemsTotal;
