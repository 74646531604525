import { ResponsiveBar } from '@nivo/bar';
import * as Mui from '@mui/material';
import {
  useGetCenterSummaryStatistics,
  useGetHighestDailyMessagesPerChannelStatistics,
  useGetMonthlyMessagesSentStatistics,
} from 'gql/communications/queries';
import moment from 'moment';
import React from 'react';
import { Box, Header } from 'shared/components/LoadingSkeletons';
import AvatarContent from 'shared/components/Avatar/AvatarContent';
import { getFullName, getInitials, stringToHueDegree } from 'shared/util/string';
import { ChannelType, PersonType } from 'generated/graphql';
import { StatisticChannel } from 'shared/types/channel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceThinking } from '@fortawesome/pro-light-svg-icons';
import Image from 'react-bootstrap/Image';
import noDataMale from '../assets/no_data_male.png';
import noDataFemale from '../assets/no_data_female.png';
import { useMediaQuery, useTheme } from '@mui/material';

interface IProps {
  currentBusinessId: string;
  currentCenterId: string;
  currentChannelId: string;
  channel?: StatisticChannel;
  channelLoading: boolean;
}
const ChannelStatisticsBar: React.FC<IProps> = ({
  currentBusinessId,
  currentCenterId,
  currentChannelId,
  channelLoading,
  channel,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: monthlyData, loading: monthlyDataLoading } = useGetMonthlyMessagesSentStatistics({
    variables: {
      businessId: currentBusinessId ?? '',
      input: {
        businessId: currentBusinessId ?? '',

        splitByPersonType: true,
        channelIds: [currentChannelId],
      },
    },
    skip: currentBusinessId == null || currentBusinessId === '' || currentChannelId == null || currentChannelId === '',
  });

  const chartData = monthlyData?.getMonthlyMessagesSentStatistics.map((rec) => {
    var date = moment(rec.month, 'DD-MM-YYYY');
    return {
      month: date.format('MMM'),
      type: rec.personType,
      sent: rec.messagesSent ?? 0,
    };
  });

  var newChartData: { month: string; ['Sent by Staff']: number; ['Sent by Family']: number }[] = [];
  chartData?.forEach((element) => {
    var alreadyExisting = newChartData.find((p) => p.month == element.month);
    if (alreadyExisting) {
      if (element.type == PersonType.Guardian) {
        alreadyExisting['Sent by Family'] = element.sent;
      } else {
        alreadyExisting['Sent by Staff'] = element.sent;
      }
    } else {
      if (element.type == PersonType.Guardian) {
        newChartData.push({
          month: element.month,
          ['Sent by Family']: element.sent,
          ['Sent by Staff']: 0,
        });
      } else {
        newChartData.push({
          month: element.month,
          ['Sent by Family']: 0,
          ['Sent by Staff']: element.sent,
        });
      }
    }
  });

  return (
    <Mui.Grid container spacing={2}>
      <Mui.Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
        {channelLoading ? (
          <Box width={'100%'} height={'100%'} />
        ) : (
          <Mui.Grid container spacing={3} marginTop={1}>
            <Mui.Grid item xs={12} sm={12} md={4} lg={4} xl={12}>
              <Mui.Card>
                <Mui.CardContent>
                  <Mui.Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5>Unread by Staff</h5>
                    <Mui.Typography fontSize={36}>{channel?.statistics?.unreadCount}</Mui.Typography>
                  </Mui.Box>
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={12} md={4} lg={4} xl={12}>
              <Mui.Card>
                <Mui.CardContent>
                  <Mui.Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5>Flagged Messages</h5>
                    <Mui.Typography fontSize={36}>{channel?.statistics?.flaggedCount}</Mui.Typography>
                  </Mui.Box>
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={12} md={4} lg={4} xl={12}>
              <Mui.Card>
                <Mui.CardContent>
                  <Mui.Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5>Daily Average Messages</h5>
                    <Mui.Typography fontSize={36}>{channel?.statistics?.dailyAverage}</Mui.Typography>
                  </Mui.Box>
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
          </Mui.Grid>
        )}
      </Mui.Grid>
      {!isMobile && (
        <Mui.Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Mui.Card sx={{ marginTop: '24px' }} elevation={0}>
            <Mui.CardContent>
              <Mui.Box>
                {monthlyDataLoading ? (
                  <Box height={300} width={700} />
                ) : newChartData?.length == 0 ||
                  newChartData?.every((cd) => cd['Sent by Family'] + cd['Sent by Staff'] == 0) ? (
                  emptyChart(
                    'Messages Sent Overall',
                    "Looks like we don't have any messages for this student yet.",
                    noDataFemale
                  )
                ) : (
                  <div style={{ width: '700px', height: '300px', margin: 'auto' }}>
                    <h6 style={{ textAlign: 'center' }}>Messages Sent Overall</h6>
                    <ResponsiveBar
                      data={newChartData ?? []}
                      keys={['Sent by Family', 'Sent by Staff']}
                      indexBy="month"
                      margin={{ top: 50, right: 60, bottom: 70, left: 60 }}
                      padding={0.4}
                      valueScale={{ type: 'linear' }}
                      animate={false}
                      enableLabel={false}
                      axisTop={null}
                      axisRight={null}
                      groupMode="grouped"
                      legends={[
                        {
                          dataFrom: 'keys',
                          anchor: 'bottom-right',
                          direction: 'column',
                          justify: false,
                          translateX: -300,
                          translateY: 60,
                          itemWidth: 100,
                          itemHeight: 15,
                          itemsSpacing: 2,
                          symbolSize: 10,
                          itemDirection: 'left-to-right',
                        },
                      ]}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Message Count',
                        legendPosition: 'middle',
                        legendOffset: -40,
                        format: (e) => Math.floor(e) === e && e,
                      }}
                      layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations']}
                    />
                  </div>
                )}
              </Mui.Box>
            </Mui.CardContent>
          </Mui.Card>
        </Mui.Grid>
      )}
    </Mui.Grid>
  );
};
export default ChannelStatisticsBar;
function emptyChart(chartTitle: string, chartBody: string, img: string): React.ReactNode {
  return (
    <Mui.Stack alignItems="center" justifyContent="space-around" height={300}>
      <h6 style={{ textAlign: 'center' }}>{chartTitle} </h6>
      <Mui.Box display="flex" height={150} alignItems="center" justifyContent="center">
        <Image fluid src={img} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Mui.Box>
      <p style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>{chartBody}</p>
    </Mui.Stack>
  );
}
