import { gql, QueryHookOptions } from '@apollo/client';
import { useQuery, useLazyQuery } from 'shared/apis/core';
import { childFields, childImmunizationFields, childWellnessDataFields } from './fields';
import { useDispatch } from 'react-redux';
import {
  getChildImmunizationDataSuccess,
  getChildWellnessDataSuccess,
} from 'pages/Families/subroutes/Children/duck/actions';
import { LazyQueryHookOptions } from '@apollo/client';

interface IGetChildByIdData {
  getChildById: IChild;
}

interface IGetChildByIdVariables {
  id: string;
}

export const GET_CHILD_BY_ID = (fields: string = childFields) => gql`
  query ($id: ID!) {
    getChildById(id: $id) {
      ${fields}
    }
  }
`;

export const GET_CHILDREN_BY_BIRTHDAY_WEEK = gql`
  query ($currentWeekOffset: Int!) {
    getChildrenByBirthdayWeek(currentWeekOffset: $currentWeekOffset) {
      id
      firstname
      lastname
      nickname
      dob
      avatar {
        url
      }
      centerIds
      isAccountActive
    }
  }
`;

export const useGetChildByIdLazy = (
  options?: LazyQueryHookOptions<IGetChildByIdData, IGetChildByIdVariables>,
  fields?: string
) => useLazyQuery<IGetChildByIdData, IGetChildByIdVariables>(GET_CHILD_BY_ID(fields), options);

export const useGetChildWellnessData = (id: string) => {
  const dispatch = useDispatch();
  return useQuery<IGetChildByIdData, IGetChildByIdVariables>(GET_CHILD_BY_ID(childWellnessDataFields), {
    variables: { id },
    fetchPolicy: 'no-cache',
    skip: id === '',
    onCompleted: (data) => {
      data.getChildById && dispatch(getChildWellnessDataSuccess(data.getChildById));
    },
  });
};

export const useGetChildImmunizations = (id: string) => {
  const dispatch = useDispatch();
  return useQuery<IGetChildByIdData, IGetChildByIdVariables>(GET_CHILD_BY_ID(childImmunizationFields), {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    skip: id === '',
    onCompleted: (data) => {
      data.getChildById && dispatch(getChildImmunizationDataSuccess(data.getChildById));
    },
  });
};

export const useGetChildrenByBirthdayWeek = (currentWeekOffset: number, skip: boolean = false) =>
  useQuery<{ getChildrenByBirthdayWeek: IChildBirthdayStatus[] }, { currentWeekOffset?: number }>(
    GET_CHILDREN_BY_BIRTHDAY_WEEK,
    {
      variables: { currentWeekOffset },
      skip,
    }
  );

export interface IGetSortedChildrenData {
  getSortedChildren: IPagedResult<IAccountChild>;
}

export interface IGetSortedChildrenVariables {
  input: {
    from: number;
    size: number;
    centerIds: string[];
    sort: ISearchSort[];
    searchKey: string;
    statusType?: string;
    statusAtDate?: string;
    tagIds?: string[];
  };
}

export const GET_SORTED_CHILDREN = () => gql`
  query ($input: ChildSearchInput!) {
    getSortedChildren(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        id
        fullName
        firstname
        lastname
        status
        avatar {
          url
        }
      }
    }
  }
`;

export const useGetSortedChildren = (
  options?: QueryHookOptions<IGetSortedChildrenData, IGetSortedChildrenVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useQuery<IGetSortedChildrenData, IGetSortedChildrenVariables>(GET_SORTED_CHILDREN(), {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      //dispatch(getChildrenSuccess(data.useGetSortedChildren.data, data.useGetSortedChildren.totalRecords));
    },
    ...options,
  });
};
