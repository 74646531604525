import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Checkbox from 'shared/components/Checkbox/Checkbox';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Col, Row } from 'shared/components/Layout';
interface IProps {
  childFormData: IStartStrongChildForm;
  formData?: IStartStrongSchoolExemption;
  handleChildChange: (value: any, name: string) => void;
  handleChange: (value: any, name: string) => void;
}

const EditSchoolExemptionForm: React.FC<IProps> = ({ childFormData, formData, handleChildChange, handleChange }) => {
  const { t } = useTranslation(['subsidies']);
  const handleFileChange = (files: File[]) => {
    handleChange(files[files.length - 1], 'file');
  };

  return (
    <div>
      <Row className="mb-4">
        <Col>
          <Checkbox
            value={childFormData.hasSchoolExemptionCertificate}
            label={t('subsidies:program-child.create-modal.start-strong.school-exemption')}
            onChange={(val: boolean) => {
              handleChildChange(val, 'hasSchoolExemptionCertificate');
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex flex-row">
            <Form.Label>{t('subsidies:program-child.create-modal.start-strong.school-exemption-document')}</Form.Label>
            {childFormData.hasSchoolExemptionCertificate && (
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            )}
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <FileSelectDropbox
            multiple={false}
            value={formData?.file ? [formData?.file] : []}
            onChange={handleFileChange}
            showFiles
          />
          {formData?.linkToFile && (
            <Row className="mt-2">
              <Col>
                <a href={formData?.linkToFile}>Current Certificate of Exemption: {formData?.fileName}</a>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EditSchoolExemptionForm;
