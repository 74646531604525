import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { sortBy } from 'lodash';
import ISCasesSummaryTab from './Tabs/ISCasesSummaryTab';
import ISCasesClaimsTab from './Tabs/ISCasesClaimsTab';
import Select from 'shared/components/Select';
import ISCasesMessagesTab from './Tabs/ISMessagesTab';
import ISCasesPaymentsTab from './Tabs/ISCasesPaymentsTab/ISCasesPaymentsTab';
import './isCases.scss';
import ISInnovativeSolutionsTab from './Tabs/ISInnovativeSolutionsTab';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import Button from 'shared/components/Buttons';
import IsPaymentReportModal from './Tabs/ISCasesPaymentsTab/IsPaymentReportModal';

type ISCaseTabs = 'summary' | 'case-claims' | 'payments' | 'messages';

interface IRouteParams {
  activeTab: ISCaseTabs;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const ISCases: React.FC<IProps> = ({ ...props }) => {
  const { activeTab } = props.match.params;
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const [activeTabKey, setActiveTabKey] = useState<ISCaseTabs>(activeTab ?? 'summary');
  const { data: businessesData } = useGetAllowedEntities();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const [selectedBusiness, setSelectedBusiness] = useState<ITableFilterOption | null>(null);
  const [showIsPaymentReportModal, setShowIsPaymentReportModal] = useState<boolean>(false);

  const business = selectedBusiness || { label: businesses?.[0]?.name, value: businesses[0]?.id };

  return (
    <ProfilePageWrapper
      title="IS Cases"
      loading={false}
      showAvatar={false}
      buttonComponent={
        <div>
          {activeTab === 'payments' && (
            <HasRoleAreaLevel
              action={{ area: AreaType.Report, permission: PermissionType.Base, level: RoleLevelType.Read }}
            >
              <Button variant="secondary" className="my-2 mr-2" onClick={() => setShowIsPaymentReportModal(true)}>
                Run Report
              </Button>
            </HasRoleAreaLevel>
          )}
        </div>
      }
    >
      <div className="mx-auto">
        {businesses && businesses.length > 1 && (
          <div className="d-flex">
            {user?.isInternal && (
              <>
                <h6 className="my-auto mr-2">Selected Businesses</h6>
                <Select
                  className="ccs-msg-business-select"
                  options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                  onChange={setSelectedBusiness}
                  placeholder="Filter..."
                />
              </>
            )}
          </div>
        )}
        <Tabs
          id="iscase-tab-group"
          activeKey={activeTabKey}
          unmountOnExit
          onSelect={(tab) => {
            setActiveTabKey(tab as ISCaseTabs);
            history.push(`/is-cases/${tab}`);
          }}
        >
          <Tab eventKey="summary" title="Summary">
            {businesses && businesses.length > 0 && <ISCasesSummaryTab selectedBusiness={business} />}
          </Tab>
          <Tab eventKey="case-claims" title="Case Claims">
            {businesses && businesses.length > 0 && <ISCasesClaimsTab selectedBusiness={business} />}
          </Tab>
          <Tab eventKey="payments" title="Payments">
            {businesses && businesses.length > 0 && <ISCasesPaymentsTab />}
            <IsPaymentReportModal
              isOpen={showIsPaymentReportModal}
              onClose={() => setShowIsPaymentReportModal(false)}
            />
          </Tab>
          <Tab eventKey="messages" title="Messages">
            {businesses && businesses.length > 0 && <ISCasesMessagesTab selectedBusiness={business} />}
          </Tab>
          <Tab eventKey="isis" title="IS Innovative Solutions">
            {businesses && businesses.length > 0 && <ISInnovativeSolutionsTab selectedBusiness={business} />}
          </Tab>
        </Tabs>
      </div>
    </ProfilePageWrapper>
  );
};

export default ISCases;
