import React from 'react';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';

interface IProps {
  children: string;
  required?: boolean;
}

const FormLabel: React.FC<IProps> = ({ required, children }) => {
  return (
    <div className="d-flex">
      <Form.Label>{children}</Form.Label>
      {required && <FontAwesomeIcon className="ml-2 mt-1 xxs" icon={faAsterisk} color="#FF2C2C" />}
    </div>
  );
};
export default FormLabel;
