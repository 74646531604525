import React, { useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './_kindyFunding.scss';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import PageWrapper from 'shared/components/PageWrapper';
import Button from 'shared/components/Buttons';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Forecast, { ExternalHandler as ForecastExternalHandler } from './Forecast/ForecastTab';
import Acquittal, { ExternalHandler as AcquittalExternalHandler } from './Acquittal/AcquittalTab';
import ForecastReportModal from './Forecast/ForecastReportModal';
import FreeKindyForecastSubmitModal from './Forecast/FreeKindyForecastSubmitModal';
import FreeKindyAcquittalSubmitModal from './Acquittal/FreeKindyAcquittalSubmitModal';
import KindyForAllForecastSubmitModal from './Forecast/KindyForAllForecastSubmitModal';
import KindyForAllAcquittalSubmitModal from './Acquittal/KindyForAllAcquittalSubmitModal';
import AcquittalReportModal from './Acquittal/AcquittalReportModal';
import { TransitionStatementsTab } from './TransitionStatements/TransitionStatements';
import { PeriodContext } from '.';
import { IPeriod } from 'shared/types/kindyForAll';

type KindyForAllTabs = 'forecast' | 'acquittal' | 'transitionStatements';

interface IProps extends RouteComponentProps<any, {}> {}

const KindyFunding: React.FC<IProps> = ({ ...props }) => {
  const [showForecastReportModal, setShowForecastReportModal] = useState<boolean>(false);
  const [showSubmitKindyForAllForecastModal, setShowSubmitKindyForAllForecastModal] = useState<boolean>(false);
  const [showSubmitFreeKindyForecastModal, setShowSubmitFreeKindyForecastModal] = useState<boolean>(false);
  const [showAcquittalReportModal, setShowAcquittalReportModal] = useState<boolean>(false);
  const [showSubmitKindyForAllAcquittalModal, setShowSubmitKindyForAllAcquittalModal] = useState<boolean>(false);
  const [showSubmitFreeKindyAcquittalModal, setShowSubmitFreeKindyAcquittalModal] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<KindyForAllTabs>('forecast');
  const refetchForecastRef = useRef<ForecastExternalHandler | null>(null);
  const refetchAcquittalRef = useRef<AcquittalExternalHandler | null>(null);

  const hasRunReportsPermission = useHasRoleAreaLevel({
    area: AreaType.Report,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const hasReadReportDataPermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const hasSubmitDataPermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });

  const handleKindyForAllForecastModalClose = () => {
    setShowSubmitKindyForAllForecastModal(false);
    refetchForecastRef?.current?.refetchCenterForecasts();
  };

  const handleFreeKindyForecastModalClose = () => {
    setShowSubmitFreeKindyForecastModal(false);
    refetchForecastRef?.current?.refetchCenterForecasts();
  };

  const handleForecastReportModalClose = () => {
    setShowForecastReportModal(false);
    refetchForecastRef?.current?.refetchCenterForecasts();
  };

  const handleAcquittalReportModalClose = () => {
    setShowAcquittalReportModal(false);
    //TODO: refetch
  };

  const handleKindyForAllAcquittalModalClose = () => {
    setShowSubmitKindyForAllAcquittalModal(false);
    //TODO: refetch
  };

  const handleFreeKindyAcquittalModalClose = () => {
    setShowSubmitFreeKindyAcquittalModal(false);
    //TODO: refetch
  };

  const [year, setYear] = useState<IPeriod['year']>(new Date().getFullYear());
  const [period, setPeriod] = useState<IPeriod['period']>('P1');
  const setContext = (newPeriod: IPeriod) => {
    setYear(newPeriod.year);
    setPeriod(newPeriod.period);
  };

  return (
    <PeriodContext.Provider value={{ year, period, setContext }}>
      <PageWrapper
        pageTitle={year < 2024 ? 'Kindy For All' : 'Free Kindy'}
        buttonComponent={
          <div className="justify-content-end align-items-center page-btn-group row no-gutters">
            {activeTabKey === 'forecast' && (
              <>
                {hasRunReportsPermission && hasReadReportDataPermission && (
                  <Button variant="secondary" className="mr-2" onClick={() => setShowForecastReportModal(true)}>
                    Run Forecast Report
                  </Button>
                )}
                {hasSubmitDataPermission &&
                  (year < 2024 ? (
                    <Button variant="primary" onClick={() => setShowSubmitKindyForAllForecastModal(true)}>
                      Prepare and Submit
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={() => setShowSubmitFreeKindyForecastModal(true)}>
                      Prepare and Submit
                    </Button>
                  ))}
              </>
            )}
            {activeTabKey === 'acquittal' && (
              <>
                {hasRunReportsPermission && hasReadReportDataPermission && (
                  <Button variant="secondary" className="mr-2" onClick={() => setShowAcquittalReportModal(true)}>
                    Run Acquittal Report
                  </Button>
                )}
                {hasSubmitDataPermission &&
                  (year < 2024 ? (
                    <Button variant="primary" onClick={() => setShowSubmitKindyForAllAcquittalModal(true)}>
                      Prepare and Submit
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={() => setShowSubmitFreeKindyAcquittalModal(true)}>
                      Prepare and Submit
                    </Button>
                  ))}
              </>
            )}
          </div>
        }
        applyPadding={false}
      >
        <Tabs
          id="kindy-funding-tabs"
          activeKey={activeTabKey}
          onSelect={(tab) => {
            setActiveTabKey(tab as KindyForAllTabs);
          }}
          className="mb-0 pl-4"
        >
          <Tab eventKey="forecast" title="Forecast">
            <Forecast ref={refetchForecastRef} />
          </Tab>
          <Tab eventKey="acquittal" title="Acquittal">
            <Acquittal ref={refetchAcquittalRef} />
          </Tab>
          <Tab eventKey="transitionStatements" title="Transition Statements">
            <TransitionStatementsTab />
          </Tab>
        </Tabs>

        <ForecastReportModal
          isOpen={showForecastReportModal}
          isLoading={false}
          year={year}
          onClose={handleForecastReportModalClose}
        />

        <KindyForAllForecastSubmitModal
          isOpen={showSubmitKindyForAllForecastModal}
          isLoading={false}
          year={year}
          onClose={handleKindyForAllForecastModalClose}
        />

        <FreeKindyForecastSubmitModal
          isOpen={showSubmitFreeKindyForecastModal}
          isLoading={false}
          year={year}
          onClose={handleFreeKindyForecastModalClose}
        />

        <AcquittalReportModal
          isOpen={showAcquittalReportModal}
          isLoading={false}
          year={year}
          onClose={handleAcquittalReportModalClose}
        />

        <KindyForAllAcquittalSubmitModal
          isOpen={showSubmitKindyForAllAcquittalModal}
          isLoading={false}
          year={year}
          onClose={handleKindyForAllAcquittalModalClose}
        />

        <FreeKindyAcquittalSubmitModal
          isOpen={showSubmitFreeKindyAcquittalModal}
          isLoading={false}
          year={year}
          onClose={handleFreeKindyAcquittalModalClose}
        />
      </PageWrapper>
    </PeriodContext.Provider>
  );
};

export default KindyFunding;
