import { gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useMutation } from 'shared/apis/core';
import { updateBusiness } from 'pages/Businesses/subroutes/BusinessProfiles/duck/actions';
import { MutationHookOptions } from '@apollo/client';
import {
  createCustomFieldDataFields,
  createCustomFieldFields,
  createReturnFeeReductionFields,
  providerFields,
} from './fields';
import { ICustomField } from 'shared/types/customField';

interface IUpdateEntityData {
  updateEntity: IBusiness;
}

interface IUpdateEntityVariables {
  input: {
    id: string;
    name?: string;
    address?: IAddress;
    phoneNumber?: string;
    primaryContactPersonId?: string;
    secondaryContactPersonId?: string;
    timezone?: string;
    email?: string;
    website?: string;
  };
}

interface IUpdateBusinessAccountOwnersData {
  input: IUpdateBusinessAccountOwners;
}

interface IUpdateBusinessAccountOwners {
  id: string;
  primaryContactPersonId: string;
  secondaryContactPersonId?: string;
}

interface IUpdateEntityInviteCodeData {
  updateEntityInviteCode: IBusiness;
}

interface IUpdateEntityInviteCodeVariables {
  id: string;
}

interface ICreateProviderData {
  createProvider: IProvider;
}

interface ICreateProviderVariables {
  input: ICreateProviderInput;
}

interface IUpdateProviderData {
  updateProvider: IProvider;
}

interface IUpdateProviderDataVariables {
  input: IUpdateProviderInput;
}

const UPDATE_ENTITY = gql`
  mutation ($input: UpdateEntityInput!) {
    updateEntity(input: $input) {
      id
      name
      email
      website
      avatar {
        url
      }
      inviteCode
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }
      timezone
      phoneNumber
      primaryContact {
        id
        firstname
        lastname
        email
        avatar {
          url
        }
        email
        roleship {
          name
        }
      }
      secondaryContact {
        id
        firstname
        lastname
        email
        avatar {
          url
        }
        email
        roleship {
          name
        }
      }
    }
  }
`;

const UPDATE_BUSINESS_ACCOUNT_OWNERS = gql`
  mutation ($input: UpdateBusinessAccountOwnersInput!) {
    updateBusinessAccountOwners(input: $input) {
      primaryContactPersonId
      secondaryContactPersonId
    }
  }
`;

const UPDATE_ENTITY_INVITE_CODE = gql`
  mutation ($id: ID!) {
    updateEntityInviteCode(id: $id) {
      id
      inviteCode
    }
  }
`;

const CREATE_PROVIDER = (fields: string = providerFields) => gql`
  mutation($input: CreateProviderInput!) {
    createProvider(input: $input) {
      ${fields}
    }
  }
`;

const UPDATE_PROVIDER = (fields: string = providerFields) => gql`
  mutation($input: UpdateProviderInput!) {
    updateProvider(input: $input) {
      ${fields}
    }
  }
`;

export const useUpdateEntityInformation = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateEntityData, IUpdateEntityVariables>(UPDATE_ENTITY, {
    onCompleted: (data) => {
      dispatch(updateBusiness(data.updateEntity));
    },
  });
};

const UPDATE_BUSINESS_PROFILE_INFO = gql`
  mutation ($input: UpdateBusinessProfileInfoInput!) {
    updateBusinessProfileInfo(input: $input) {
      id
      name
      email
      website
      avatar {
        url
      }
      inviteCode
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }
      timezone
      phoneNumber
    }
  }
`;

interface IUpdateBusinessProfileInfoData {
  input: IUpdateBusinessProfileInfo;
}

interface IUpdateBusinessProfileInfo {
  id: string;
  name: string;
  email?: string;
  website?: string;
  timezone: string;
  phoneNumber: string;
  address: IAddress;
}

export const useUpdateBusinessProfileInfo = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateEntityData, IUpdateBusinessProfileInfoData>(UPDATE_BUSINESS_PROFILE_INFO, {
    onCompleted: (data) => {
      dispatch(updateBusiness(data.updateEntity));
    },
  });
};

const UPDATE_BUSINESS_HUB_SPOT_CUSTOMER_ID = gql`
  mutation ($input: UpdateBusinessHubSpotCustomerIdInput!) {
    updateBusinessHubSpotCustomerId(input: $input) {
      id
      name
      email
      website
      avatar {
        url
      }
      inviteCode
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }
      timezone
      phoneNumber
    }
  }
`;

interface IUpdateBusinessHubSpotCustomerIdData {
  input: IUpdateBusinessHubSpotCustomerId;
}

interface IUpdateBusinessHubSpotCustomerId {
  id: string;
  hubSpotCustomerId: string;
}

export const useUpdateBusinessHubSpotCustomerId = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateEntityData, IUpdateBusinessHubSpotCustomerIdData>(UPDATE_BUSINESS_HUB_SPOT_CUSTOMER_ID, {
    onCompleted: (data) => {
      dispatch(updateBusiness(data.updateEntity));
    },
  });
};

export const useUpdateBusinessAccountOwners = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateEntityData, IUpdateBusinessAccountOwnersData>(UPDATE_BUSINESS_ACCOUNT_OWNERS, {
    onCompleted: (data) => {
      dispatch(updateBusiness(data.updateEntity));
    },
  });
};

export const useUpdateEntityInviteCode = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateEntityInviteCodeData, IUpdateEntityInviteCodeVariables>(UPDATE_ENTITY_INVITE_CODE, {
    onCompleted: (data) => {
      dispatch(updateBusiness(data.updateEntityInviteCode));
    },
  });
};

// Update Provider
export const useCreateProvider = (
  options?: MutationHookOptions<ICreateProviderData, ICreateProviderVariables>,
  fields?: string
) => useMutation<ICreateProviderData, ICreateProviderVariables>(CREATE_PROVIDER(fields), options);

export const useUpdateProvider = (
  options?: MutationHookOptions<IUpdateProviderData, IUpdateProviderDataVariables>,
  fields?: string
) => useMutation<IUpdateProviderData, IUpdateProviderDataVariables>(UPDATE_PROVIDER(fields), options);

// Return Fee Reduction
const CREATE_RETURN_FEE_REDUCTION = (fields: string = createReturnFeeReductionFields) => gql`
  mutation ($input: ReturnFeeReductionInput!) {
    createReturnFeeReduction(input: $input) {
      ${fields}
    }
  }
`;

interface ICreateReturnFeeReductionData {
  returnFeeReduction: IReturnFeeReduction;
}

export interface ICreateReturnFeeReductionInput {
  enrolmentId: string;
  sessionReportStartDate: string;
  amountNotPassedOnToIndividual: string;
  amountPassedOnToIndividual: string;
  totalFeeReductionAmountForWeek: string;
  returnFeeReductionReason: string;
}

interface ICreateReturnFeeReductionVariables {
  input: ICreateReturnFeeReductionInput;
}

export const useCreateReturnFeeReduction = (
  options?: MutationHookOptions<ICreateReturnFeeReductionData, ICreateReturnFeeReductionVariables>,
  fields?: string
) =>
  useMutation<ICreateReturnFeeReductionData, ICreateReturnFeeReductionVariables>(
    CREATE_RETURN_FEE_REDUCTION(fields),
    options
  );

// Cancel Return Fee Reduction
export const CANCEL_RETURN_FEE_REDUCTION = gql`
  mutation ($input: CancelReturnFeeReductionInput!) {
    cancelReturnFeeReduction(input: $input)
  }
`;

interface ICancelReturnFeeDataVariables {
  input: {
    returnFeeReductionId: string;
  };
}

interface ICancelReturnFeeReductionData {
  returnFeeReductionId: string;
}

export const useCancelReturnFeeReduction = (
  options?: MutationHookOptions<ICancelReturnFeeReductionData, ICancelReturnFeeDataVariables> | undefined
) => useMutation<ICancelReturnFeeReductionData, ICancelReturnFeeDataVariables>(CANCEL_RETURN_FEE_REDUCTION, options);

////// Custom Data Fields
const CREATE_CUSTOM_DATA_FIELDS = (fields: string = createCustomFieldDataFields) => gql`
  mutation ($input: CustomFieldDataInput!) {
    createCustomFieldData(input: $input) {
      ${fields}
    }
  }
`;

interface ICreateCustomFieldDataData {
  returnFeeReduction: ICustomFieldData;
}

export interface ICreateCustomFieldDataInput {
  enrolmentId: string;
  sessionReportStartDate: string;
  amountNotPassedOnToIndividual: string;
  amountPassedOnToIndividual: string;
  totalFeeReductionAmountForWeek: string;
  returnFeeReductionReason: string;
}

interface ICreateCustomFieldDataVariables {
  input: ICreateCustomFieldDataInput;
}

export const useCreateCustomDataFields = (
  options?: MutationHookOptions<ICreateCustomFieldDataData, ICreateCustomFieldDataVariables>,
  fields?: string
) =>
  useMutation<ICreateCustomFieldDataData, ICreateCustomFieldDataVariables>(CREATE_CUSTOM_DATA_FIELDS(fields), options);

const CREATE_CUSTOM_FIELD_FIELDS = (fields: string = createCustomFieldFields) => gql`
  mutation ($input: CreateCustomFieldInput!) {
    createCustomField(input: $input) {
      ${fields}
    }
  }
`;

export interface ICreateCustomFieldInput {
  // id: string;
  businessId: string;
  centerIds: string[];
  label: string;
  area: string;
  displayOrder: number | undefined;
  notForConsoleDisplay: boolean;
  type: string;
  attributes: string | null;
}

interface ICreateCustomFieldData {
  createCustomField: ICustomField;
}

interface ICreateCustomFieldDataVars {
  input: ICreateCustomFieldInput;
}

export const useCreateCustomFields = (
  options?: MutationHookOptions<ICreateCustomFieldData, ICreateCustomFieldDataVars>,
  fields?: string
) => useMutation<ICreateCustomFieldData, ICreateCustomFieldDataVars>(CREATE_CUSTOM_FIELD_FIELDS(fields), options);
