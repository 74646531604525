import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { useGetUnbatchedPaymentTransactions } from 'gql/batch/queries';
import { RootState } from 'store/reducers';
import { useCreateBatch } from 'gql/batch/mutations';
import { showToast } from 'shared/components/Toast';
import { CenterSelector } from './components/CenterSelector';
import { ConfirmBatchModal } from './components/ConfirmBatchModal';
import { UnbatchedPaymentsTable } from './components/UnbatchedPaymentsTable';
import { useDispatch, useSelector } from 'react-redux';
import { ControllBar } from './components/ControllerBar';
import { totalUnbatchedPayments } from '../duck/actions';

const BatchablePaymentsTab: React.FC = () => {
  const dispatch = useDispatch();
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation(['billing']);
  const [selectedCenterId, setSelectedCenterId] = useState<string | undefined>();
  const { data: centers, loading: centersLoading } = useGetActiveCentersWithLoading();
  const [selectedPayments, setSelectedPayments] = useState<any[]>([]);
  const [dateRangeStart, setDateRangeStart] = useState<string | null>(null);
  const [dateRangeEnd, setDateRangeEnd] = useState<string | null>(null);
  const {
    data,
    loading: loadingTransactions,
    refetch: loadUnbatchedTransactions,
  } = useGetUnbatchedPaymentTransactions({
    variables: {
      input: {
        businessId: currentBusinessId ?? '',
        centerIds: selectedCenterId ? [selectedCenterId] : undefined,
        start: dateRangeStart,
        end: dateRangeEnd,
        paymentMedium: 'PHYSICAL',
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => {
      dispatch(totalUnbatchedPayments(result.getUnbatchedTransactionPayments.totalUnbatchedTransactions));
    },
  });
  const unbatchTransactions = data?.getUnbatchedTransactionPayments.transactions ?? [];
  const [createBatchFn, { loading: creatingBatch }] = useCreateBatch({
    onCompleted: () => {
      setShowConfirmationModal(false);
      showToast(t('billing:transactions.create-batch-modal.success-toast-message'), 'success');
      loadUnbatchedTransactions();
    },
    onError: () => {
      showToast(t('billing:transactions.create-batch-modal.error-toast-message'), 'error');
    },
  });
  const canSelectPayments = !!selectedCenterId && !creatingBatch && !loadingTransactions;
  const canCreateBatch = !!selectedCenterId && !loadingTransactions && selectedPayments.length > 0;

  return (
    <>
      <CenterSelector
        loadingCenters={centersLoading}
        centers={centers || []}
        selectedCenter={selectedCenterId}
        onSelectedCenterChange={setSelectedCenterId}
      ></CenterSelector>
      <ControllBar
        dateRangeStart={dateRangeStart}
        dateRangeEnd={dateRangeEnd}
        onDateRangeStartChange={setDateRangeStart}
        onDateRangeEndChange={setDateRangeEnd}
        onClear={() => {
          setDateRangeStart(null);
          setDateRangeEnd(null);
        }}
        canCreateBatch={canCreateBatch}
        onCreateBatchClick={() => setShowConfirmationModal(true)}
      ></ControllBar>
      <UnbatchedPaymentsTable
        payments={unbatchTransactions.map((t) => ({
          id: t.payment!.id,
          userFriendlyId: t.transactionNumber,
          amount: t.payment!.depositedAmount!,
          accountName: t.account.name,
          paymentMethodType: t.transactionType.name,
          date: t.date,
          centerName: t.account.center?.name ?? '',
        }))}
        canSelectPayments={canSelectPayments}
        selectedPayments={selectedPayments}
        onSelectedPaymentsChange={setSelectedPayments}
        loading={loadingTransactions}
      ></UnbatchedPaymentsTable>
      <ConfirmBatchModal
        show={showConfirmationModal}
        paymentsTotal={unbatchTransactions
          .filter((t) => !!selectedPayments.find((pId) => pId === t.payment!.id))
          .reduce((acc, curr) => (acc += curr.payment?.depositedAmount || 0), 0)}
        paymentCount={selectedPayments.length}
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() =>
          createBatchFn({
            variables: {
              input: {
                businessId: currentBusinessId ?? '',
                centerId: selectedCenterId ?? '',
                paymentIds: selectedPayments,
              },
            },
          })
        }
      ></ConfirmBatchModal>
    </>
  );
};

export default BatchablePaymentsTab;
