import React, { useState, useCallback, useContext } from 'react';
import moment from 'moment';
import { orderBy, groupBy } from 'lodash';
import DailyTimeLogTable from '../DailyTimeLogTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import WeeklyTimeLog from '../WeeklyTimeLog/WeeklyTimeLog';
import DateHeader from '../DateHeader';
import DateAndFiltersControl from '../DateAndFiltersControl';
import { updateTimelogTableFilter } from '../../duck/actions';
import { useSearchStaff } from 'gql/staff/queries';
import { SEARCH_EXPRESSIONS, PREDICATES, DIRECTIONS } from 'shared/constants/elastic';
import {
  active,
  pending,
  created,
  deactivated,
  invited,
  statusOptionsTimeLog,
} from 'shared/constants/StaffStatusSearchExpressions';
import { useGetTimeOffForScope } from 'pages/TimeManagement/subroutes/TimeOff/grapgql/queries';
import { useGetRolesForBusinessThatUserCanManage } from 'shared/hooks/useGetRolesForBusiness';
import { useGetAttendance, useGetCenterBusinessOperationHours } from '../../graphql/queries';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import NewTimeEntryModal from 'pages/TimeManagement/subroutes/TimeSheets/components/NewTimeEntryModal';
import AddEmployeeTimeModal from 'pages/TimeManagement/subroutes/TimeSheets/components/NewTimeEntryModal/AddEmployeeTimeModal';
import { TimezoneContext } from 'shared/contexts/timezoneContext';

import PageWrapperBody from 'shared/components/PageWrapper/Body';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';

export type DailyAttendanceWithPerson = {
  person: IStaff;
  attendance: IDailyAttendance | null;
  timeOffRequest: ITimeOff | null;
};

export interface ITimeLogTableRowData {
  staff: IStaff;
  attendance: IAttendance | null;
  timeOffRequests: ITimeOff[];
}

interface IProps {}

const getEmployeeRoleElasticSearchExpressions = (employeeStatusOptions: string[]): ISearchExpression[] => {
  const searchExpressions: ISearchExpression[] = [];

  employeeStatusOptions.forEach((status) => {
    const statusOption = Object.values(statusOptionsTimeLog).find((opt) => opt.value === status);
    if (statusOption?.searchExpression) {
      searchExpressions.push(statusOption.searchExpression);
    }
  });

  return searchExpressions;
};

const TimelogsTab: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const centerTimezoneContext = useContext(TimezoneContext);
  const tableFilters = useSelector((state: RootState) => state.timelog.tableFilters);
  const user = useSelector((state: RootState) => state.user);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const centerTimezone = centerTimezoneContext.timezone;

  const startDate = moment(tableFilters.date).tz(centerTimezone).day(1).format('YYYY-MM-DD');
  const endDate = moment(tableFilters.date).tz(centerTimezone).day(5).format('YYYY-MM-DD');

  const [filterTerm, setFilterTerm] = useState('');
  const [showNewTimeEntryModal, setShowTimeEntryModal] = useState(false);
  const [showAddEmployeeTimeModal, setShowAddEmployeeTimeModal] = useState(false);
  const { loading: searchStaffLoading, data: searchStaffData } = useSearchStaff(
    {
      skip: !currentCenterId || !currentBusinessId,
      variables: {
        input: {
          from: 0,
          size: 1000,
          sort: [{ field: 'lastname.keyword', direction: DIRECTIONS.ASCENDING }],
          filter: {
            [SEARCH_EXPRESSIONS.ALL]: [
              {
                [SEARCH_EXPRESSIONS.ANY]: tableFilters.employeeStatus.length
                  ? getEmployeeRoleElasticSearchExpressions(tableFilters.employeeStatus)
                  : [created, active, pending, deactivated, invited],
              },
              {
                [SEARCH_EXPRESSIONS.TERM]: {
                  field: 'roleship.scopeIds.keyword',
                  predicate: PREDICATES.ONE_OF,
                  value: [currentCenterId, currentBusinessId],
                },
              },
            ],
          },
        },
      },
    },
    `id firstname lastname nickname avatar { url } role { id name scheduleVisibility}`
  );

  const { data: timeOffData } = useGetTimeOffForScope({
    scopeType: 'CENTER',
    scopeId: currentCenterId ?? '',
    startTime: startDate,
    endTime: endDate,
  });
  const { data: rolesData } = useGetRolesForBusinessThatUserCanManage(currentBusinessId ?? '', user);
  const { loading: centerOperationHoursLoading, data: centerOperationHoursData } = useGetCenterBusinessOperationHours(
    currentCenterId ?? ''
  );
  const {
    data: getAttendanceData,
    loading: getAttendanceLoading,
    refetch: refetchAttendance,
  } = useGetAttendance({
    centerId: currentCenterId ?? '',
    startDate: moment(tableFilters.date).tz(centerTimezone).day(1).toISOString(),
    endDate: moment(tableFilters.date).tz(centerTimezone).day(5).toISOString(),
  });
  const activeCentersData: ICenter[] = useGetActiveCenters();

  const getOperationHoursForDate = useCallback(
    (date: moment.Moment): IOperationHours | null => {
      if (centerOperationHoursData?.getCenter.staffOperationHours) {
        const dayOfWeek = date.format('dddd').toUpperCase();

        return centerOperationHoursData.getCenter.staffOperationHours.find((d) => d.dayOfWeek === dayOfWeek) ?? null;
      }

      return null;
    },
    [centerOperationHoursData]
  );

  const filterStaffList = useCallback(
    (staff: IStaff[]): IStaff[] => {
      let filtered = [...staff];

      if (tableFilters.roleIds.length) {
        filtered = filtered.filter((staff) => tableFilters.roleIds.includes(staff.role.id));
      }

      // note: employee status is done via an api call
      return filtered;
    },
    [tableFilters]
  );

  /**
   * @param {date} - visible date in table. this will be zoned to the center's timezone
   */
  const constructSingleDayTableData = useCallback(
    (
      staff: IStaff[],
      employeeAttendance: IAttendance[],
      timeOffRequests: ITimeOff[],
      date: moment.Moment
    ): DailyAttendanceWithPerson[] => {
      const dailyAttendanceRecords: DailyAttendanceWithPerson[] = [];
      const employeeAttendanceByStaffId = groupBy(employeeAttendance, (att) => att.personId);
      const timeOffRequestsByStaffId = groupBy(timeOffRequests, (att) => att.personId);

      filterStaffList(staff)
        .filter((s) => s.role.scheduleVisibility)
        .forEach((staff) => {
          const attendanceRecordsForStaff: IAttendance[] | null = employeeAttendanceByStaffId[staff.id];
          const timeOffRequestsForStaff: ITimeOff[] | null = timeOffRequestsByStaffId[staff.id];
          let attendanceForStaff: IAttendance | null = Boolean(attendanceRecordsForStaff)
            ? attendanceRecordsForStaff[0]
            : null;
          const timeOffForStaff: ITimeOff | null =
            timeOffRequestsForStaff?.find((to) => {
              const startMoment = moment(to.startTime).tz(centerTimezone);
              const endMoment = moment(to.endTime).tz(centerTimezone);

              return (
                (date.isBetween(startMoment, endMoment, 'date', '[]') || date.isSame(startMoment, 'date')) &&
                to.centerId === currentCenterId
              );
            }) ?? null;
          let dailyRecord: IDailyAttendance | null = null;

          if (attendanceForStaff) {
            dailyRecord =
              attendanceForStaff.days.find((day) => {
                const attendanceDate = moment(day.date, 'YYYY-MM-DD');

                return date.isSame(
                  moment().tz(centerTimezone).set({
                    year: attendanceDate.year(),
                    month: attendanceDate.month(),
                    date: attendanceDate.date(),
                  }),
                  'd'
                );
              }) ?? null;
          }

          dailyAttendanceRecords.push({
            person: staff,
            attendance: dailyRecord,
            timeOffRequest: timeOffForStaff,
          });
        });

      return orderBy(dailyAttendanceRecords, (a) => a.person.lastname, 'asc');
    },
    [filterStaffList, centerTimezone, currentCenterId]
  );

  const constructWeekTableData = useCallback(
    (staff: IStaff[], employeeAttendance: IAttendance[], timeOffRequests: ITimeOff[]): ITimeLogTableRowData[] => {
      let data: ITimeLogTableRowData[] = [];
      const employeeAttendanceByStaffId = groupBy(employeeAttendance, (att) => att.personId);
      const timeOffRequestsByStaffId = groupBy(timeOffRequests, (att) => att.personId);

      filterStaffList(staff)
        .filter((s) => s.role.scheduleVisibility)
        .forEach((staff) => {
          const attendanceRecordsForStaff: IAttendance[] | null = employeeAttendanceByStaffId[staff.id];
          const timeOffRequestsForStaff: ITimeOff[] | null = timeOffRequestsByStaffId[staff.id];
          let attendanceForStaff: IAttendance | null = Boolean(attendanceRecordsForStaff)
            ? attendanceRecordsForStaff[0]
            : null;
          const timeOffForStaff: ITimeOff[] =
            timeOffRequestsForStaff?.filter((to) => {
              const timeOffStartMoment = moment(to.startTime).tz(centerTimezone);
              const timeOffEndMoment = moment(to.endTime).tz(centerTimezone);

              return (
                (timeOffStartMoment.isBetween(startDate, endDate, 'date', '[]') ||
                  timeOffEndMoment.isBetween(startDate, endDate, 'date', '[]') ||
                  timeOffStartMoment.isSame(startDate, 'date') ||
                  timeOffEndMoment.isSame(endDate, 'date')) &&
                to.centerId === currentCenterId
              );
            }) ?? [];

          data.push({
            staff,
            attendance: attendanceForStaff,
            timeOffRequests: timeOffForStaff,
          });
        });

      return orderBy(data, (row) => row.staff.lastname, 'asc');
    },
    [filterStaffList, centerTimezone, currentCenterId, startDate, endDate]
  );

  return (
    <>
      <PageWrapperBody>
        <CenterSelectBanner pageName="the time log" />
        {
          //AU Here
          <DateAndFiltersControl
            activeFilters={tableFilters}
            roleOptions={rolesData ?? []}
            onFilterUpdate={(updates) => dispatch(updateTimelogTableFilter(updates))}
          />
        }
        {tableFilters.activeView === 'DAY' ? (
          <DailyTimeLogTable
            loading={centerOperationHoursLoading || getAttendanceLoading}
            attendanceForDay={constructSingleDayTableData(
              searchStaffData?.searchStaff.data ?? [],
              getAttendanceData?.getAttendance ?? [],
              timeOffData?.getTimeOffRequestsByScope ?? [],
              moment(tableFilters.date).tz(centerTimezone)
            )}
            operationHoursForDate={
              centerOperationHoursData?.getCenter
                ? getOperationHoursForDate(moment(tableFilters.date).tz(centerTimezone))
                : null
            }
            date={moment(tableFilters.date).tz(centerTimezone)}
            centerId={currentCenterId ?? ''}
          />
        ) : (
          <WeeklyTimeLog
            loading={getAttendanceLoading}
            date={moment(tableFilters.date).tz(centerTimezone).toISOString()}
            onFilter={setFilterTerm}
            tableData={constructWeekTableData(
              searchStaffData?.searchStaff.data ?? [],
              getAttendanceData?.getAttendance ?? [],
              timeOffData?.getTimeOffRequestsByScope ?? []
            )}
            centerOperationHours={centerOperationHoursData?.getCenter.staffOperationHours || []}
            centerId={currentCenterId ?? ''}
            startDate={startDate}
            endDate={endDate}
            showSearch={false}
          />
        )}
        <NewTimeEntryModal
          isOpen={showNewTimeEntryModal}
          onClose={() => setShowTimeEntryModal(false)}
          centerId={currentCenterId ?? ''}
          entityId={currentBusinessId ?? ''}
          selectedCenter={activeCentersData.find((c) => c.id === currentCenterId) ?? null}
          refetchAttendance={refetchAttendance}
        />
        <AddEmployeeTimeModal
          isOpen={showAddEmployeeTimeModal}
          onClose={() => setShowAddEmployeeTimeModal(false)}
          centerId={currentCenterId ?? ''}
          entityId={currentBusinessId ?? ''}
          date={tableFilters.date}
        />
      </PageWrapperBody>
    </>
  );
};

export default TimelogsTab;
