import React from 'react';
import AllergenSelect from 'shared/components/Modals/NewAllergyModal/AllergenSelect';
import { Row, Col as Column } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { allergenReactions } from 'shared/constants/enums/allergenReactions';
import { allergenSeverityEnum } from 'shared/constants/enums/allergenSeverityEnum';
import { sortBy } from 'lodash';
const allergenSeverityOptions = Object.keys(allergenSeverityEnum);

const ALLERGEN_REACTION_ARRAY = sortBy(
  Object.keys(allergenReactions).map((reaction) => ({ label: allergenReactions[reaction], value: reaction })),
  (obj) => obj.label
);

export interface IAllergyInputGroupFormShape {
  allergenType: IHealthTerm | null;
  allergen: IAllergen | null;
  severity: AllergenSeverity | null;
  reactions: { label: string; value: string }[] | null;
  instructions: string;
  documentation: IHealthConditionDocumentUploadInput[];
}

interface IProps {
  formData: IAllergyInputGroupFormShape;
  readOnly?: boolean;
  onUpdate: (formData: IAllergyInputGroupFormShape) => void;
}

const AllergyInputGroup: React.FC<IProps> = ({ formData, readOnly = false, onUpdate, ...props }) => {
  return (
    <>
      <AllergenSelect
        selectedAllergenType={formData.allergenType}
        selectedAllergen={formData.allergen}
        onSelectAllergenType={(type) => onUpdate({ ...formData, allergenType: type, allergen: null })}
        onSelectAllegen={(allergen) => onUpdate({ ...formData, allergen })}
        disableSelects={readOnly}
      />
      <Row>
        <Column md={6}>
          <Select
            required
            disabled={readOnly}
            label="Severity"
            options={allergenSeverityOptions}
            getOptionLabel={(option) => allergenSeverityEnum[option.value]}
            value={formData.severity}
            onChange={(option) => onUpdate({ ...formData, severity: option })}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Select
            isMulti
            closeMenuOnSelect
            disabled={readOnly}
            label="Reaction"
            value={formData.reactions}
            required={formData.severity !== 'Mild' && formData.severity !== 'NotApplicable'}
            options={ALLERGEN_REACTION_ARRAY}
            onChange={(options) => onUpdate({ ...formData, reactions: options })}
            className="flex-wrap"
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <TextInput
            required={formData.severity !== 'Mild' && formData.severity !== 'NotApplicable'}
            label="Care Instructions"
            as="textarea"
            rows={3}
            value={formData.instructions}
            onChange={(value: string, name: string) => onUpdate({ ...formData, instructions: value })}
            disabled={readOnly}
          />
        </Column>
      </Row>
    </>
  );
};

export default AllergyInputGroup;
