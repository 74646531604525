import { s3SignedUrlFields } from 'gql/s3SignedUrl/fields';
import { addressFields } from 'gql/address/fields';
import staffFields from 'gql/staff/fields';
import { tagFields } from 'gql/tag/fields';
import { paymentMethodFields } from '../paymentMethod/fields';
import { isRegion } from 'shared/util/region';

let businessFields: string = `
  id
  name
  inviteCode
  timezone
  phoneNumber
  dataType
  accountOwnerPersonId
  primaryContactPersonId
  secondaryContactPersonId
  avatar {
    ${s3SignedUrlFields}
  }
  address {
    ${addressFields}
  }
  paymentMethods {
    ${paymentMethodFields}
  }
  primaryPaymentMethod {
    ${paymentMethodFields}
  }
  primaryPaymentMethodId
  payrixCustomerId
  email
  website
`;

/**
 * If we try to import and use the other fields directly in the businessFields string we will get the following error
 * > ReferenceError: can't access lexical declaration '__' before initialization
 *
 * I think this is caused by `staffFields` having references to `businessFields`
 */
businessFields = businessFields.concat(`
  primaryContact {
    ${staffFields}
  }
  primaryContact {
    ${staffFields}
  }
  secondaryContact {
    ${staffFields}
  }
  accountOwner {
    ${staffFields}
  }
  accountOwners {
    ${staffFields}
  }
  tags {
    ${tagFields}
  }
`);

if (isRegion('US')) {
  businessFields = businessFields.concat(`
  kisiApiKey
  `);
}

export default businessFields;
