import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';
import { accountingPeriodFields } from './fields';

interface ICreateAccountingPeriodVariables {
  input: ICreateAccountingPeriodInput;
}

interface ICreateAccountingPeriodData {
  createAccountingPeriod: boolean;
}

interface IUpdateAccountingPeriodVariables {
  input: IUpdateAccountingPeriodInput;
}

interface IUpdateAccountingPeriodData {
  updateAccountingPeriod: boolean;
}

export const CREATE_ACCOUNTING_PERIOD = gql`
  mutation ($input: CreateAccountingPeriodInput!) {
    createAccountingPeriod(input: $input)
  }
`;

export const UPDATE_ACCOUNTING_PERIOD = gql`
  mutation ($input: UpdateAccountingPeriodInput!) {
    updateAccountingPeriod(input: $input)
  }
`;

export const useCreateAccountingPeriod = (
  options?: MutationHookOptions<ICreateAccountingPeriodData, ICreateAccountingPeriodVariables>,
  fields?: string
) => useMutation<ICreateAccountingPeriodData, ICreateAccountingPeriodVariables>(CREATE_ACCOUNTING_PERIOD, options);

export const useUpdateAccountingPeriod = (
  options?: MutationHookOptions<IUpdateAccountingPeriodData, IUpdateAccountingPeriodVariables>,
  fields?: string
) => useMutation<IUpdateAccountingPeriodData, IUpdateAccountingPeriodVariables>(UPDATE_ACCOUNTING_PERIOD, options);
