import React from 'react';
import {
  faReceipt,
  faThLarge,
  faMoneyBillWaveAlt,
  faCogs,
  faFileInvoiceDollar,
  faBadgePercent,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleDollar } from 'shared/constants/customIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Row } from 'shared/components/Layout';
import { RootState } from 'store/reducers';
import AccountPayments from './PaymentsTab/AccountPayments';
import AUBillingInformationCard from './AUSetupTab/BillingInformationCard';
import StatementsTab from './StatementsTab/StatementsTab';
import TransactionTab from './TransactionsTab/TransactionTab';
import AccountDeposits from './DepositsTab/AccountDeposits';
import DiscountsTab from './DiscountsTab';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTranslation } from 'react-i18next';
import OverviewTabV2 from './OverviewTabV2';

interface IProps {
  account: IAccount;
  loading: boolean;
  accountId: string;
  hasReadBaseTransactionPermissions: boolean;
  hasReadAccountBillingCyclePermissions: boolean;
  hasReadPaymentsPermissions: boolean;
  hasReadBillingPaymentMethodsPermission: boolean;
  hasReadBillingDiscountPermissions: boolean;
  accountDataErrors: boolean;
  activeTab?: string;
  onActiveTabChange?: (subtab: string | null) => void;
}

const BillingTab: React.FC<IProps> = ({
  account,
  loading,
  accountId,
  hasReadBaseTransactionPermissions,
  hasReadAccountBillingCyclePermissions,
  hasReadPaymentsPermissions,
  hasReadBillingPaymentMethodsPermission,
  hasReadBillingDiscountPermissions,
  accountDataErrors,
  activeTab,
  onActiveTabChange = () => {},
}) => {
  const { t } = useTranslation(['accounts']);
  const user = useSelector((state: RootState) => state.user);
  const canViewStatements =
    user?.hasAreaPermissionLevel({
      area: AreaType.Statement,
      permission: PermissionType.Base,
      level: RoleLevelType.Read,
    }) ?? false;
  const canCreateStatements =
    user?.hasAreaPermissionLevel({
      area: AreaType.Statement,
      permission: PermissionType.Base,
      level: RoleLevelType.Create,
    }) ?? false;

  const { k2Discounts } = useFlags();

  return (
    <Tabs
      id="account-billing-tabs"
      activeKey={activeTab ?? 'overview'}
      onSelect={onActiveTabChange}
      className="kt-billing-tab.title"
    >
      {(hasReadBaseTransactionPermissions || hasReadBillingPaymentMethodsPermission) && (
        <Tab
          eventKey="overview"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faThLarge} size="2x" className="mr-2" />
              {t('accounts:profile.billing-overview-tab.title')}
            </Row>
          }
        >
          <OverviewTabV2 loading={loading} account={account} accountId={accountId} />
        </Tab>
      )}
      {hasReadBaseTransactionPermissions && (
        <Tab
          eventKey="transactions"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faReceipt} size="2x" className="mr-2" />
              {t('accounts:profile.billing-transactions-tab.title')}
            </Row>
          }
        >
          {activeTab === 'transactions' && <TransactionTab account={account} accountId={accountId} />}
        </Tab>
      )}
      {hasReadBaseTransactionPermissions && (
        <Tab
          eventKey="deposits"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faCircleDollar} size="2x" className="mr-2" />
              {t('accounts:profile.billing-deposits-tab.title')}
            </Row>
          }
        >
          {activeTab === 'deposits' && <AccountDeposits account={account} accountId={accountId} />}
        </Tab>
      )}
      {hasReadPaymentsPermissions && (
        <Tab
          eventKey="payments"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faMoneyBillWaveAlt} size="2x" className="mr-2" />
              {t('accounts:profile.billing-payments-tab.title')}
            </Row>
          }
        >
          <AccountPayments accountId={accountId} />
        </Tab>
      )}
      {canViewStatements && (
        <Tab
          eventKey="statements"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" className="mr-2" />
              {t('accounts:profile.billing-statements-tab.title')}
            </Row>
          }
        >
          <StatementsTab account={account} accountId={accountId} canCreateStatements={canCreateStatements} />
        </Tab>
      )}
      {k2Discounts && hasReadBillingDiscountPermissions && (
        <Tab
          eventKey="discounts"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faBadgePercent} size="2x" className="mr-2" />
              {t('accounts:profile.billing-discounts-tab.title')}
            </Row>
          }
        >
          <DiscountsTab
            accountId={accountId}
            children={account?.children ?? []}
            accountCenterId={account?.centerId ?? ''}
            accountEntityId={account?.entityId ?? ''}
            accountName={account?.name ?? ''}
          />
        </Tab>
      )}
      {hasReadAccountBillingCyclePermissions && (
        <Tab
          eventKey="settings"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faCogs} size="2x" className="mr-2" />
              {t('accounts:profile.billing-setup-tab.title')}
            </Row>
          }
        >
          <AUBillingInformationCard
            loading={loading}
            account={account}
            accountId={accountId}
            accountDataErrors={accountDataErrors}
          ></AUBillingInformationCard>
        </Tab>
      )}
    </Tabs>
  );
};

export default BillingTab;
