import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import './ResolveTransactionDrawer.scss';

interface SelectOption {
  value: string | number;
  label: string;
}

interface IProps {
  show: boolean;
  onCancel: () => void;
  onResolve: () => void;
  accountOptions: SelectOption[];
  selectedAccount?: SelectOption['value'];
  childrenOptions: SelectOption[];
  selectedChild?: SelectOption['value'];
  transactionTypeOptions: SelectOption[];
  selectedTransactionType?: SelectOption['value'];
  amount?: number;
  description?: string;
  transactionDate?: string;
}

const ResolveTransactionDrawer: React.FC<IProps> = ({
  show,
  onCancel,
  onResolve,
  accountOptions,
  selectedAccount,
  childrenOptions,
  selectedChild,
  transactionTypeOptions,
  selectedTransactionType,
  amount,
  description,
  transactionDate,
}) => {
  return (
    <SideModalDrawer title="Add Transaction" show={show} onHide={onCancel} primaryCallback={onResolve}>
      <Row className="resolve-bulk-transaction-drawer__apply-date-row">
        <Col>
          <DateInput label="Apply Date" date={transactionDate} disabled></DateInput>
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            label="Account"
            value={selectedAccount}
            options={accountOptions}
            disabled
            onChange={() => {}}
          ></Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <Select label="Child" value={selectedChild} options={childrenOptions} disabled onChange={() => {}}></Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            label="Transaction Type"
            value={selectedTransactionType}
            options={transactionTypeOptions}
            disabled
            onChange={() => {}}
          ></Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <CurrencyInput2 required label="Amount" value={amount || 0.0} disabled onChange={() => {}}></CurrencyInput2>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput label="Name/Description" value={description || ''} disabled onChange={() => {}}></TextInput>
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default ResolveTransactionDrawer;
