import React from 'react';
import {
  faReceipt,
  faThLarge,
  faMoneyBillWaveAlt,
  faCogs,
  faBadgePercent,
  faFileInvoice,
  faHistory,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleDollar } from 'shared/constants/customIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tabs, Tab } from 'react-bootstrap';
import { Row } from 'shared/components/Layout';
import AccountPayments from './PaymentsTab/AccountPayments';
import USBillingInformationCard from './USSetupTab/BillingInformationCard';
import TransactionTab from './TransactionsTab/TransactionTab';
import AccountDeposits from './DepositsTab/AccountDeposits';
import DiscountsTab from './DiscountsTab';
import ItemizedBillsTab from './ItemizedBillsTab';
import { useTranslation } from 'react-i18next';
import OverviewTabV2 from './OverviewTabV2';
import { useUSModeSettings } from 'US/Billing/USMode';
import { useGetCurrentBusiness } from 'shared/hooks/user-context/useGetCurrentBusiness';
import BillingHistory from './History';

interface IProps {
  account: IAccount;
  loading: boolean;
  accountId: string;
  hasReadBaseTransactionPermissions: boolean;
  hasReadAccountBillingCyclePermissions: boolean;
  hasReadPaymentsPermissions: boolean;
  hasReadBillingPaymentMethodsPermission: boolean;
  hasReadBillingDiscountPermissions: boolean;
  accountDataErrors: boolean;
  activeTab?: string;
  onActiveTabChange?: (subtab: string | null) => void;
}

const BillingTab: React.FC<IProps> = ({
  account,
  loading,
  accountId,
  hasReadBaseTransactionPermissions,
  hasReadAccountBillingCyclePermissions,
  hasReadPaymentsPermissions,
  hasReadBillingPaymentMethodsPermission,
  hasReadBillingDiscountPermissions,
  activeTab,
  onActiveTabChange = () => {},
}) => {
  const { t } = useTranslation(['accounts']);
  const { k2Discounts } = useFlags();
  const businessId = useGetCurrentBusiness();
  const { usMode, loading: loadingUSMode } = useUSModeSettings(businessId);

  return (
    <Tabs
      id="account-billing-tabs"
      activeKey={activeTab ?? 'overview'}
      onSelect={onActiveTabChange}
      className="kt-billing-tab.title"
    >
      {(hasReadBaseTransactionPermissions || hasReadBillingPaymentMethodsPermission) && (
        <Tab
          eventKey="overview"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faThLarge} size="2x" className="mr-2" />
              {t('accounts:profile.billing-overview-tab.title')}
            </Row>
          }
        >
          <OverviewTabV2 loading={loading} account={account} accountId={accountId} />
        </Tab>
      )}
      {hasReadBaseTransactionPermissions && (
        <Tab
          eventKey="transactions"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faReceipt} size="2x" className="mr-2" />
              {loadingUSMode ? '' : usMode ? 'Activity' : 'Transactions'}
            </Row>
          }
        >
          {activeTab === 'transactions' && <TransactionTab account={account} accountId={accountId} />}
        </Tab>
      )}
      {hasReadBaseTransactionPermissions && (
        <Tab
          eventKey="deposits"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faCircleDollar} size="2x" className="mr-2" />
              {t('accounts:profile.billing-deposits-tab.title')}
            </Row>
          }
        >
          {activeTab === 'deposits' && <AccountDeposits account={account} accountId={accountId} />}
        </Tab>
      )}
      {hasReadPaymentsPermissions && (
        <Tab
          eventKey="payments"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faMoneyBillWaveAlt} size="2x" className="mr-2" />
              {t('accounts:profile.billing-payments-tab.title')}
            </Row>
          }
        >
          <AccountPayments accountId={accountId} />
        </Tab>
      )}
      {hasReadPaymentsPermissions && (
        <Tab
          eventKey="itemizedBills"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faFileInvoice} size="2x" className="mr-2" />
              {t('accounts:profile.billing-itemized-bills-tab.title')}
            </Row>
          }
        >
          <ItemizedBillsTab account={account} />
        </Tab>
      )}
      {k2Discounts && hasReadBillingDiscountPermissions && (
        <Tab
          eventKey="discounts"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faBadgePercent} size="2x" className="mr-2" />
              {t('accounts:profile.billing-discounts-tab.title')}
            </Row>
          }
        >
          <DiscountsTab account={account} />
        </Tab>
      )}
      {hasReadAccountBillingCyclePermissions && (
        <Tab
          eventKey="settings"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faCogs} size="2x" className="mr-2" />
              {t('accounts:profile.billing-setup-tab.title')}
            </Row>
          }
        >
          <USBillingInformationCard account={account} accountId={accountId}></USBillingInformationCard>
        </Tab>
      )}
      <Tab
        eventKey="History"
        title={
          <Row noGutters>
            <FontAwesomeIcon icon={faHistory} size="2x" className="mr-2" />
            History
          </Row>
        }
      >
        <BillingHistory accountId={accountId} />
      </Tab>
    </Tabs>
  );
};

export default BillingTab;
