import { glCodeMappingFields } from 'gql/glCode/fields';
export const agencyFields: string = `
  id
  name
  state
  county
  phoneNumber
  businessId
  centerIds
  absentDay
  attendanceRequirement
  reimbursementSchedule
  archivedAt
  assignedAccountChildIds
  agencyLinks {
    id
    link
  }
  billingCalculationType
  subsidyPeriod
  glCodeMapping {
    ${glCodeMappingFields}
  }
`;

export const agencyAccountChildFields: string = `
  id
  agencyId
  accountChildId
  accountId
  status
  childFirstName
  childLastName
  accountName
  centerName
  agencyName
  subsidyPeriod
  archivedAt
  billingCalculationType
  agencyAccountChildEnrollments {
    id
    startDate
    endDate
    subsidyPaymentPortion
    notes
    childSubsidyEnrollmentId
    ignoreCopay
    excludedContractIds
    status
  }
  avatar {
    url
  }
`;

export const agencyPaymentFields: string = `
  id
  agencyId
  agencyName
  centerId
  centerName
  startDate
  endDate
  amountPaid
  amountExpected
  amountDifference
  isAdjusted
  checkNumber
  submittedBy
  submittedByPerson {
    id
    userType
    firstname
    lastname
    avatar {
      url
    }
  }
  childAgencyPayments {
    id
    agencyPaymentId
    childFirstName
    childLastName
    childSubsidyEnrollmentId
    accountName
    accountId
    accountChildEnrollmentId
    amountPaid
    differenceAction
    adjustmentId
    differenceActionReason
    numberOfSessions
    amountExpected
  }
`;
