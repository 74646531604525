import React, { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import BaseSelect from 'shared/components/Select/Base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { useGetActiveClassesForCenter, useGetClassesForCenter } from 'gql/center/queries';
import { sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { getCenterClassesSuccess } from 'pages/Centers/subroutes/Classes/duck/actions';
import { useGetActiveClassesByCenterAndDate } from 'gql/class/queries';

interface IProps {
  /**
   * `null` can represent `All` when `useNullForAllOption` is provided
   */
  selectedClassIds: string[] | null;
  onSelect: (classIds: string[] | null, isAllOptionSelected: boolean) => void;
  required?: boolean;
  centerId: string;
  /**
   * Provide `null` as the value via `onSelect` if the all option is selected.
   */
  useNullForAllOption?: boolean;
  /**
   * default: `false`
   * if true, 'All Classes' option will not be shown
   */
  disableAllOption?: boolean;
  label?: string;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  className?: string;
  date: string;
}

const MultipleActiveClassSelect: React.FC<IProps> = ({
  selectedClassIds,
  useNullForAllOption = false,
  onSelect,
  centerId,
  disableAllOption = false,
  label,
  onMenuOpen,
  onMenuClose,
  className = '',
  date,
  ...props
}) => {
  const dispatch = useDispatch();

  const ALL_CLASSES_OPTIONS = { value: null, label: `All Classes` };

  const { data: centerActiveClassData, loading: activeClassesLoading } = useGetActiveClassesByCenterAndDate({
    variables: {
      centerId: centerId,
      date: date,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch(getCenterClassesSuccess(data.getActiveClassesByCenterAndDate));
    },
  });

  const availableClasses = sortBy(
    centerActiveClassData?.getActiveClassesByCenterAndDate.filter((c) => !c.archivedAt) ?? [],
    'name'
  );

  const classSelectOptions = availableClasses?.map((acc) => ({ label: acc.name, value: acc.id })) ?? [];

  const isAllOptionSelected = useCallback((): boolean => {
    if (disableAllOption) {
      return false;
    }

    return (
      (useNullForAllOption && !selectedClassIds) ||
      (selectedClassIds !== null &&
        selectedClassIds.length > 0 &&
        selectedClassIds.length === classSelectOptions.length)
    );
  }, [useNullForAllOption, disableAllOption, selectedClassIds, classSelectOptions]);

  /**
   * Get the array of selected values. React-select wants an array of all of the objects selected
   */
  const getSelectValue = useCallback(() => {
    return isAllOptionSelected()
      ? [ALL_CLASSES_OPTIONS]
      : classSelectOptions.filter((opt) => (selectedClassIds ?? []).includes(opt.value));
  }, [isAllOptionSelected, ALL_CLASSES_OPTIONS, classSelectOptions, selectedClassIds]);
  /**
   * Handle the selection of an option from the dropdown
   */
  const handleSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_CLASSES_OPTIONS.value) {
        onSelect(useNullForAllOption ? null : classSelectOptions.map((opt) => opt.value as string), true);
      } else if (
        (action === 'deselect-option' && option.value === ALL_CLASSES_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_CLASSES_OPTIONS.value)
      ) {
        onSelect([], false);
      } else if (actionMeta.action === 'deselect-option' && isAllOptionSelected()) {
        onSelect(
          (selectedClassIds ?? []).filter((id) => id !== option.value),
          false
        );
      } else {
        onSelect(
          (options ?? []).filter((opt) => opt.value !== null).map((opt) => opt.value),
          options?.length === classSelectOptions.length
        );
      }
    },
    [onSelect, useNullForAllOption, classSelectOptions, isAllOptionSelected, selectedClassIds, ALL_CLASSES_OPTIONS]
  );

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label>
          {label}
          {props.required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
        </Form.Label>
      )}

      <BaseSelect
        // @ts-ignore
        isMulti
        // @ts-ignore
        value={activeClassesLoading ? null : getSelectValue()}
        // @ts-ignore
        options={[...(disableAllOption ? [] : [ALL_CLASSES_OPTIONS]), ...classSelectOptions]}
        // @ts-ignore
        onChange={(options, action) => handleSelect(options as any[], action)}
        isLoading={activeClassesLoading}
        className="react-select-container"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        noOptionsMessage={() => `No classes found`}
        components={{
          IndicatorSeparator: null,
        }}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
      />
    </Form.Group>
  );
};

export default MultipleActiveClassSelect;
