import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import { kisiPairedCenters } from './fields';
import { LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';

interface IGetCenterLocksData {
  getCenter: ICenter;
}

interface IGetCenterLocksVariables {
  id: string;
}

export const GET_PAIRED_PLACES = gql`
  query ($businessId: ID!) {
    getKisiPairedPlaces(businessId: $businessId) {
      ${kisiPairedCenters}
    }
  }
`;

export const GET_CENTER_LOCKS = () => gql`
  query ($id: String!) {
    getCenter(id: $id) {
      id
      name
      locks {
        id
        lockId
        name
        description
        allowParentUnlock
        allowStaffUnlock
      }
    }
  }
`;

export const useGetKisiPairedPlaces = (
  options?: QueryHookOptions<{ getKisiPairedPlaces: IKisiPairedCenter[] }, IGetPairedPlacesInput>
) => useQuery(GET_PAIRED_PLACES, { fetchPolicy: 'no-cache', ...options });

export const useGetCenterLocks = (options?: QueryHookOptions<IGetCenterLocksData, IGetCenterLocksVariables>) =>
  useQuery<IGetCenterLocksData, IGetCenterLocksVariables>(GET_CENTER_LOCKS(), {
    fetchPolicy: 'no-cache',
    skip: false,
    ...options,
  });
