import React, { useCallback, useMemo, useState } from 'react';
import { useGetItemizedBill, useGetItemizedBillSummary } from 'gql/itemizedBill/queries';
import { getSelectedAccountItemizedBill } from 'pages/Families/subroutes/Accounts/duck/actions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CreateButton } from 'shared/components/Buttons';
import { RootState } from 'store/reducers';
import ItemizedBillsSelectCard from './ItemizedBillsSelectCard';
import ItemizedBillTransactionsTable from './ItemizedBillTransactionsTable';
import SelectedItemizedBillOverviewCard from './SelectedItemizedBillOverviewCard';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import DownloadItemizedBillModal from './DownloadItemizedBillModal';
import { showToast } from 'shared/components/Toast';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getFullName } from 'shared/util/string';
import { v4 as uuidv4 } from 'uuid';
import { itemizedBillWithTransactionFields } from 'gql/itemizedBill/fields';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { formatDate } from 'pages/Subsidies/EndOfCareTab/Tabs/EndOfCareActive/common';
import { gql, useMutation } from '@apollo/client';
import { useItemizedBillPdf } from './hooks/useItemizedBillPdf';
import { useHistory } from 'react-router-dom';

interface IProps {
  account: IAccount;
}

interface IDownloadItemizedBillModalShape {
  isOpen: boolean;
}

const itemizedBillFields = `${itemizedBillWithTransactionFields}
latePaymentFee {
  amount
  waived
  waivedAt
  waivedBy {
    firstname
    lastname
  }
}
`;

export const WAIVE_LATE_PAYMENT_FEE_MUTATION = gql`
  mutation ($input: WaiveLatePaymentFeeInput!) {
    waiveLatePaymentFee(input: $input) {
      ${itemizedBillFields}
    }
  }
`;

const ItemizedBillsTab: React.FC<IProps> = ({ account }) => {
  const history = useHistory();
  const { t } = useTranslation(['accounts', 'translation']);
  const dispatch = useDispatch();

  const hasCreatePaymentsPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Create,
  });

  const selectedItemizedBill = useSelector(
    (state: RootState) => state.accounts.itemizedBills.byAccountId[account?.id]?.selectedItemizedBill ?? null
  );
  const selectedItemizedBillId = useSelector(
    (state: RootState) => state.accounts.itemizedBills.byAccountId[account?.id]?.selectedItemizedBillId ?? ''
  );
  const itemizedBillOptions = useSelector(
    (state: RootState) => state.accounts.itemizedBills.byAccountId[account?.id]?.itemizedBills ?? []
  );

  const [showWaiveLatePaymentFeeModal, setShowWaiveLatePaymentFeeModal] = useState<boolean>(false);
  const handleWaiveLatePaymentFeeClick = useCallback(() => setShowWaiveLatePaymentFeeModal(true), []);
  const handleCloseWaiveLatePaymentFeeModal = useCallback(() => setShowWaiveLatePaymentFeeModal(false), []);
  const waiveLatePaymentFeeModalProps = useMemo(() => {
    if (!(selectedItemizedBill && account))
      return {
        accountName: '',
        latePaymentFeeAmount: 0,
        billName: '',
      };
    const label = selectedItemizedBill.isManuallyCreated
      ? `${formatDate(selectedItemizedBill.billingPeriodEndDate, 'MMMM Do, YYYY')} (#${
          selectedItemizedBill.userFriendlyId
        })`
      : `${formatDate(selectedItemizedBill.billingPeriodStartDate!, 'MMMM Do, YYYY')} - ${formatDate(
          selectedItemizedBill.billingPeriodEndDate,
          'MMMM Do, YYYY'
        )} (#${selectedItemizedBill.userFriendlyId})`;
    return {
      accountName: account.name,
      latePaymentFeeAmount: selectedItemizedBill?.latePaymentFee?.amount,
      billName: label,
    };
  }, [account, selectedItemizedBill]);
  const [waiveLatePaymentFeeFn, { loading: waivingLatePaymentFee }] = useMutation(WAIVE_LATE_PAYMENT_FEE_MUTATION, {
    onCompleted: (result) => {
      //need to dispatch the getItemizedBill action again in order to update our state.
      dispatch(getSelectedAccountItemizedBill(account.id, result.waiveLatePaymentFee));

      let tempData = result.waiveLatePaymentFee.displayableTransactions.map((transaction: ITransaction) => {
        return {
          id: transaction.id,
          date: transaction.date ?? '',
          description:
            transaction.createdBy !== '00000000-0000-0000-0000-000000000000'
              ? transaction.transactionType.name
              : transaction.description, // Product wants manually created transactions to show the type instead of the description
          childName: transaction.appliedToAccountChild != null ? getFullName(transaction.appliedToAccountChild) : 'N/A',
          amount: transaction.amount,
        };
      });

      setDetailViewData(tempData);
      setShowWaiveLatePaymentFeeModal(false);
      showToast('Waived late payment fee', 'success');
    },
    onError: (error) => {
      console.error('error', error);
      showToast(`Error waiving late payment fee: ${error.message}`, 'error');
    },
  });
  const handleConfirmWaiveLateFee = useCallback(
    () => waiveLatePaymentFeeFn({ variables: { input: { itemizedBillId: selectedItemizedBillId } } }),
    [selectedItemizedBillId, waiveLatePaymentFeeFn]
  );

  const [detailViewData, setDetailViewData] = useState<IItemizedBillGridForm[]>([]);
  const [summaryViewData, setSummaryViewData] = useState<IItemizedBillGridForm[]>([]);
  const [showSummaryView, setShowSummaryView] = useState<boolean>(true);

  const { loading: itemizedBillLoading } = useGetItemizedBill(
    {
      variables: {
        id: selectedItemizedBillId,
      },
      skip: !selectedItemizedBillId,
      onCompleted: (result) => {
        dispatch(getSelectedAccountItemizedBill(account.id, result.getItemizedBill));

        let tempData = result.getItemizedBill.displayableTransactions.map((transaction: ITransaction) => {
          return {
            id: transaction.id,
            date: transaction.date ?? '',
            description:
              transaction.createdBy !== '00000000-0000-0000-0000-000000000000'
                ? transaction.transactionType.name
                : transaction.description, // Product wants manually created transactions to show the type instead of the description
            childName:
              transaction.appliedToAccountChild != null ? getFullName(transaction.appliedToAccountChild) : 'N/A',
            amount: transaction.amount,
          };
        });

        setDetailViewData(tempData);
      },
    },
    itemizedBillFields
  );

  const buildSessionGroupTableRow = (
    childGroup: IItemizedBillSummaryChildGroup,
    sessionGroup: IItemizedBillSummarySessionGroup
  ) => {
    return {
      id: uuidv4(),
      date: '',
      description: sessionGroup.feeName,
      childName: `${childGroup.firstName} ${childGroup.lastName}`,
    };
  };

  const { loading: itemizedBillSummaryLoading } = useGetItemizedBillSummary({
    variables: {
      id: selectedItemizedBillId,
    },
    skip: !selectedItemizedBillId,
    onCompleted: (result) => {
      let tempData: IItemizedBillGridForm[] = [];

      // Loop through child summary data
      result.getItemizedBillSummary.childGroups.forEach((childGroup: IItemizedBillSummaryChildGroup) => {
        // Create child session group rows
        childGroup.sessionGroups.forEach((sessionGroup: IItemizedBillSummarySessionGroup) => {
          tempData.push({
            ...buildSessionGroupTableRow(childGroup, sessionGroup),
            amount: sessionGroup.baseFeeTotal,
          });
          if (sessionGroup.feeAdjustment) {
            tempData.push({
              ...buildSessionGroupTableRow(childGroup, sessionGroup),
              description: sessionGroup.adjustmentName,
              amount: sessionGroup.feeAdjustment,
            });
          }
          if (sessionGroup.estimatedSubsidyCredit) {
            tempData.push({
              ...buildSessionGroupTableRow(childGroup, sessionGroup),
              description: t('translation:itemized-bills.estimated-subsidy-credit'),
              amount: sessionGroup.estimatedSubsidyCredit,
            });
          }
          if (sessionGroup.earlyFee) {
            tempData.push({
              ...buildSessionGroupTableRow(childGroup, sessionGroup),
              description: t('translation:itemized-bills.early-fee'),
              amount: sessionGroup.earlyFee,
            });
          }
          if (sessionGroup.lateFee) {
            tempData.push({
              ...buildSessionGroupTableRow(childGroup, sessionGroup),
              description: t('translation:itemized-bills.late-fee'),
              amount: sessionGroup.lateFee,
            });
          }

          sessionGroup.discounts.forEach((discount: IItemizedBillSummaryDiscount) => {
            tempData.push({
              id: uuidv4(),
              date: '',
              description: discount.name,
              childName: `${childGroup.firstName} ${childGroup.lastName}`,
              amount: discount.amount,
            });
          });
        });

        // Create child ungrouped transactions
        childGroup.ungroupedTransactions.forEach((transaction: ITransaction) => {
          tempData.push({
            id: transaction.id,
            date: transaction.date ?? '',
            description:
              transaction.createdBy !== '00000000-0000-0000-0000-000000000000'
                ? transaction.transactionType.name
                : transaction.description, // Production wants manually created transactions to show the type instead of the description
            childName:
              transaction.appliedToAccountChild != null ? getFullName(transaction.appliedToAccountChild) : 'N/A',
            amount: transaction.amount,
          });
        });

        // Create child ungrouped discounts
        childGroup.ungroupedDiscounts.forEach((discount: IItemizedBillSummaryDiscount) => {
          tempData.push({
            id: uuidv4(),
            date: '',
            description: discount.name,
            childName: 'N/A',
            amount: discount.amount,
          });
        });
      });

      // Loop through ungrouped transactions
      result.getItemizedBillSummary.ungroupedTransactions.forEach((transaction: ITransaction) => {
        tempData.push({
          id: transaction.id,
          date: transaction.date ?? '',
          description:
            transaction.createdBy !== '00000000-0000-0000-0000-000000000000'
              ? transaction.transactionType.name
              : transaction.description, // Production wants manually created transactions to show the type instead of the description
          childName: transaction.appliedToAccountChild != null ? getFullName(transaction.appliedToAccountChild) : 'N/A',
          amount: transaction.amount,
        });
      });

      // Loop through ungrouped discounts
      result.getItemizedBillSummary.ungroupedDiscounts.forEach((discount: IItemizedBillSummaryDiscount) => {
        tempData.push({
          id: uuidv4(),
          date: '',
          description: discount.name,
          childName: 'N/A',
          amount: discount.amount,
        });
      });

      setSummaryViewData(tempData);
    },
  });

  const tableData = useMemo(
    () => (showSummaryView ? summaryViewData : detailViewData),
    [detailViewData, showSummaryView, summaryViewData]
  );

  const [showDownloadItemizedBillModal, setShowDownloadItemizedBillModal] = useState<IDownloadItemizedBillModalShape>({
    isOpen: false,
  });

  const { downloadingPdf, downloadPdf } = useItemizedBillPdf({
    onComplete: (url) => window.open(url, '_blank'),
    onError: () => showToast(t('accounts:profile.billing-itemized-bills-tab.download-bill-pdf-error'), 'error'),
  });

  const handleDownloadButtonClick = useCallback(() => {
    setShowDownloadItemizedBillModal({ isOpen: true });
  }, []);

  const handleDownloadPdf = useCallback(
    (itemizedBillType: string) => {
      downloadPdf(selectedItemizedBillId, itemizedBillType);
    },
    [downloadPdf, selectedItemizedBillId]
  );

  const handleCreateItemizedBillClick = useCallback(() => {
    if (account) {
      history.push(`/families/accounts/${account.id}/billing/itemizedBills/create`);
    }
  }, [account, history]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h3>{t('accounts:profile.billing-itemized-bills-tab.title')}</h3>
        <div>
          {hasCreatePaymentsPermissions && (
            <CreateButton disabled={!account} onClick={handleCreateItemizedBillClick}>
              {t('accounts:profile.billing-itemized-bills-tab.manual-bill-button')}
            </CreateButton>
          )}
        </div>
      </div>
      {!itemizedBillOptions.length ? (
        <div>{t('accounts:profile.billing-itemized-bills-tab.no-bills-for-account')}</div>
      ) : (
        <div>
          <ItemizedBillsSelectCard
            accountId={account.id}
            onDownload={handleDownloadButtonClick}
            loading={downloadingPdf}
          />
          <SelectedItemizedBillOverviewCard
            accountId={account.id}
            loading={itemizedBillLoading}
            onWaiveLatePaymentFeeClicked={handleWaiveLatePaymentFeeClick}
            waivingLatePaymentFee={waivingLatePaymentFee}
          />
          <ItemizedBillTransactionsTable
            data={tableData}
            isLoading={itemizedBillLoading || itemizedBillSummaryLoading}
            showSummaryView={showSummaryView}
            showSummaryViewChanged={setShowSummaryView}
          />
        </div>
      )}

      <DownloadItemizedBillModal
        isOpen={showDownloadItemizedBillModal.isOpen}
        handleClose={() => {
          setShowDownloadItemizedBillModal({ isOpen: false });
        }}
        handleDownload={handleDownloadPdf}
        loading={downloadingPdf}
      />

      <CenteredModal
        show={showWaiveLatePaymentFeeModal}
        onHide={handleCloseWaiveLatePaymentFeeModal}
        primaryCallback={handleConfirmWaiveLateFee}
        primaryButtonProps={{ variant: 'danger', disabled: waivingLatePaymentFee }}
        primaryChoice="Waive Late Fee"
        secondaryCallback={handleCloseWaiveLatePaymentFeeModal}
        secondaryButtonProps={{ disabled: waivingLatePaymentFee }}
        title={'Waive Late Payment Fee'}
      >
        <p>
          Are you sure you want to{' '}
          <b>waive the ${waiveLatePaymentFeeModalProps.latePaymentFeeAmount} late payment fee </b>for bill{' '}
          {waiveLatePaymentFeeModalProps.billName} on the {waiveLatePaymentFeeModalProps.accountName} Account?
        </p>
        <p>
          <b>Please Note: </b>This will not impact any other late fees that appear on this bill from previous bills.
        </p>
      </CenteredModal>
    </>
  );
};

export default ItemizedBillsTab;
