import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { useResubmitSessionReportSyncMutation } from 'generated/graphql';
import useDatatableState from 'shared/hooks/useDatatableState2';
import SubsidiesTrackingCards from './SubsidiesTrackingCards';
import SubsidiesTrackingTable from './SubsidiesTrackingTable';
import { convertTimeRangeStringToObject } from 'shared/util/timeUtils';
import { useGetSubsidyCategories, useGetWeeklySubsidySummaries } from 'gql/subsidyTracking/queries';
import { useEffect } from 'react';
import AddReasonForLateChangeModal from './AddReasonForLateChangeModal';
import { useUpdateSessionReportSync } from 'gql/sessionReportSynchronisation/mutation';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import ConfirmResubmitReportModal from './ConfirmResubmitReportModal';

const SubsidiesTrackingTab: React.FC<{
  businessId: string;
  centerIds: string[];
}> = ({ businessId, centerIds }) => {
  const { t } = useTranslation();
  const [activeStatus, setActiveStatus] = useState<ISubsidySummaryStatus | null>(null);
  const [tableState, tableFunctions] = useDatatableState();
  const [searchText, setSearchText] = useState<undefined | string>();
  const [dateRange, setDateRange] = useState(convertTimeRangeStringToObject('Last 2 Weeks'));
  const [sort, setSort] = useState<IElasticsearchSortFilter[]>();
  const onSort = (field: string, direction: ElasticsearchSortDirection) => setSort([{ field, direction }]);
  const [isReasonModalOpen, setReasonModalOpen] = useState(false);
  const [isResubmitModalOpen, setResubmitModalOpen] = useState(false);
  const [activeSummary, setActiveSummary] = useState<ISubsidyWeeklySummary | undefined>();
  const [resubmitReport, { loading: resubmitReportLoading }] = useResubmitSessionReportSyncMutation({
    onCompleted: () => {
      setResubmitModalOpen(false);
      showToast(t('subsidy-tracking.resubmit-report-success'), 'success');
      refetch();
      refetchCategories();
    },
    onError: () => {
      showToast(t('general.error'), 'error');
      setResubmitModalOpen(false);
    },
  });
  const isRangeValid = () => {
    if (dateRange.start !== '' && dateRange.end !== '') {
      return moment(dateRange.start).isAfter(moment(dateRange.end));
    }
    return true;
  };

  const getCategoryDateRange = (
    category: ISubsidySummaryStatus
  ): { start: Date | undefined; end: Date | undefined } => {
    switch (category) {
      case 'PENDING':
        return { start: categoriesCount?.pendingEarliest, end: categoriesCount?.pendingLatest };
      case 'UNSUBMITTED':
        return { start: categoriesCount?.unsubmittedEarliest, end: categoriesCount?.unsubmittedLatest };
      case 'FINALISED':
        return { start: categoriesCount?.finalisedEarliest, end: categoriesCount?.finalisedLatest };
      case 'ERRORED':
        return { start: categoriesCount?.erroredEarliest, end: categoriesCount?.erroredLatest };
      default:
        return { start: undefined, end: undefined };
    }
  };

  useEffect(() => {
    tableFunctions.changePage(1, tableState.pageSize);
  }, [activeStatus, dateRange]);

  const { data: categoryData, refetch: refetchCategories } = useGetSubsidyCategories({
    variables: {
      input: {
        businessId,
        centerIds,
      },
    },
  });

  const input = {
    businessId,
    centerIds,
    startDate: moment(dateRange.start).hour(0).utc().format(),
    endDate: moment(dateRange.end).hour(0).utc().format(),
    searchText: searchText === '' ? undefined : searchText,
    pageNumber: tableState.activePage,
    pageSize: tableState.pageSize,
    sortDtos: sort,
  };

  const { data, loading, refetch } = useGetWeeklySubsidySummaries({
    variables: {
      input: {
        ...input,
        status: activeStatus ?? undefined,
      },
    },
    skip: isRangeValid(),
  });
  const [updateReason, { loading: updateLoading }] = useUpdateSessionReportSync({
    onCompleted: () => {
      showToast(t('subsidy-tracking.add-reason-success'), 'success');
      setActiveSummary(undefined);
      setReasonModalOpen(false);
      refetch();
    },
    onError: () => showToast(t('general.error'), 'error'),
  });

  const categoriesCount = categoryData?.getSubsidyCategories;
  const dashboardCount: Record<ISubsidySummaryStatus, number> = {
    PENDING: categoriesCount?.PENDING ?? 0,
    ERRORED: categoriesCount?.ERRORED ?? 0,
    UNSUBMITTED: categoriesCount?.UNSUBMITTED ?? 0,
    FINALISED: categoriesCount?.FINALISED ?? 0,
  };

  const selectStatus = (status: ISubsidySummaryStatus | null) => {
    if (!status) {
      setDateRange(convertTimeRangeStringToObject('Last 2 Weeks'));
      setActiveStatus(status);
      return;
    }

    const dateBountries = getCategoryDateRange(status);
    setDateRange({
      start: moment(dateBountries?.start).isValid() ? moment(dateBountries.start).add(-1, 'day').format() : '',
      end: moment(dateBountries?.end).isValid() ? moment(dateBountries.end).add(1, 'day').format() : '',
    });
    setActiveStatus(status);
  };

  const handleOpenReasonModal = (summary?: ISubsidyWeeklySummary) => {
    setReasonModalOpen(true);
    setActiveSummary(summary);
  };

  const handleResubmitReport = useCallback(() => {
    if (activeSummary) {
      resubmitReport({
        variables: {
          input: {
            businessId,
            id: activeSummary?.sessionReportSynchronisationId,
          },
        },
      });
    }
  }, [resubmitReport, activeSummary]);

  const handleResubmitReportModal = (summary?: ISubsidyWeeklySummary) => {
    setResubmitModalOpen(true);
    setActiveSummary(summary);
  };

  const handleCloseReasonModal = () => {
    setReasonModalOpen(false);
    setActiveSummary(undefined);
  };

  const handleSubmitReason = (reason?: string) => {
    updateReason({
      variables: {
        input: {
          reasonForLateChange: reason ?? null,
          businessId,
          id: activeSummary?.sessionReportSynchronisationId ?? '',
        },
      },
    });
  };

  return (
    <div>
      <SubsidiesTrackingCards activeFilter={activeStatus} onSelected={selectStatus} counts={dashboardCount} />
      <h4 className="mt-4 mb-4">Payment</h4>
      <SubsidiesTrackingTable
        loading={loading}
        showLoadingOverlay={false}
        data={data?.getWeeklySubsidySummaries?.data || []}
        dateRange={dateRange}
        setDateRange={setDateRange}
        dataSize={data?.getWeeklySubsidySummaries.totalRecords ?? 0}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        onSort={onSort}
        onSearch={setSearchText}
        onUpdateReason={handleOpenReasonModal}
        onResubmitReport={handleResubmitReportModal}
      />
      <ConfirmResubmitReportModal
        isOpen={isResubmitModalOpen}
        onCancel={() => setResubmitModalOpen(false)}
        onSubmit={handleResubmitReport}
        loading={resubmitReportLoading}
        summary={activeSummary}
      />
      <AddReasonForLateChangeModal
        isOpen={isReasonModalOpen}
        loading={updateLoading}
        handleClose={handleCloseReasonModal}
        handleSubmit={handleSubmitReason}
        summary={activeSummary}
      />
    </div>
  );
};

export default SubsidiesTrackingTab;
