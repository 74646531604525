import React, { useCallback } from 'react';
import { Menu, MenuItem } from 'shared/components/Dropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import IconButtonCircle from '../Buttons/IconButtonCircle';

interface IDropdownAction {
  id?: string;
  label: string;
  onClick: () => void;
}

interface IDropdownProps {
  actions: IDropdownAction[];
  id?: string;
  disabled?: boolean;
}

interface IEllipsisIconProps {
  id: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
}

const EllipsisIcon = React.forwardRef<HTMLDivElement, IEllipsisIconProps>(({ id, onClick, disabled }, ref) => {
  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      !disabled && onClick && onClick(event);
    },
    [onClick, disabled]
  );

  return (
    <div id={id} ref={ref} onClick={handleClick}>
      <IconButtonCircle iconSize="2x" icon={faEllipsisV} disabled={disabled} />
    </div>
  );
});

const ActionDropdown: React.FC<IDropdownProps> = ({ id = '', actions, disabled, ...props }) => {
  return (
    <Dropdown drop="right">
      <Dropdown.Toggle id={id} as={EllipsisIcon} disabled={disabled} />
      <Menu className="no-min-width">
        {actions.map((action, index) => (
          // @ts-ignore
          <MenuItem id={action.id || index} key={index} onClick={action.onClick}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default ActionDropdown;
