import { faArrowRight, faCheck } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { IconButtonCircle } from 'shared/components/Buttons';

interface IProps {
  selectedSessions: ISession[];
  isCheckInAllowed?: boolean;
  handleCheckIn: (sessions: ISession[]) => void;
  handlingCheckIn: boolean;
  handleCheckOut: (sessions: ISession[]) => void;
  handlingCheckOut: boolean;
  handleReportAbsence: (sessions: ISession[]) => void;
}

const BulkActions: React.FC<IProps> = ({
  selectedSessions,
  isCheckInAllowed = true,
  handleCheckIn,
  handlingCheckIn,
  handleCheckOut,
  handlingCheckOut,
  handleReportAbsence,
}) => {
  const attendanceButton = selectedSessions.every(
    (s) => !s.absence && (s.timeEntries.length === 0 || s.timeEntries.every((te) => te.timeOut))
  ) ? (
    <IconButtonCircle
      icon={faCheck}
      className="mr-2"
      tooltipText="Check In"
      disabled={handlingCheckIn}
      onClick={() => handleCheckIn(selectedSessions)}
    />
  ) : selectedSessions.every((s) => !s.absence && s.timeEntries.some((te) => !te.timeOut)) ? (
    <IconButtonCircle
      icon={faArrowRight}
      className="mr-2"
      tooltipText="Check Out"
      disabled={handlingCheckOut}
      onClick={() => handleCheckOut(selectedSessions)}
    />
  ) : (
    <small className="my-auto">
      No bulk actions can be performed because selected children are not in the same state or one or more child is
      marked as absent.
    </small>
  );
  return (
    <div className="bg-white p-4 d-flex border-bottom" style={{ height: '73px' }}>
      {isCheckInAllowed && attendanceButton}

      {selectedSessions.every((session) => !session.absence && !session.timeEntries.length) && (
        <IconButtonCircle
          iconComponent="A"
          className="mr-2"
          tooltipText="Mark Absent"
          onClick={() => handleReportAbsence(selectedSessions)}
        />
      )}
    </div>
  );
};

export default BulkActions;
