import React from 'react';
import GalleryDashboardCard from 'shared/components/Card';
import { Row } from 'shared/components/Layout';
import { Box } from 'shared/components/LoadingSkeletons';
import { start } from 'repl';

interface IProps {
  title: string;
  color: string;
  description?: string;
  number?: number;
  onClick?: () => void;
  loading?: boolean;
  className?: string;
}

const NumberCard: React.FC<IProps> = ({ title, description, number, color, onClick, className, loading = false }) => {
  return (
    <GalleryDashboardCard
      className="m-0"
      bodyClassName="align-items-center"
      loading={loading}
      onClick={onClick}
      style={{
        display: 'inline-flex',
        width: '300px',
        height: '82%',
        border: '1px solid #e2e7ee',
        padding: 0,
      }}
    >
      <div
        style={{
          flexDirection: 'row',
          height: '100%',
          display: 'flex',
          margin: 0,
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <div
          style={{
            margin: 0,
            width: '75%',
          }}
        >
          <h4
            className={`proxima-soft text-${color} font-weight-bold`}
            style={{ color, flex: 'wrap', whiteSpace: 'normal', margin: 0 }}
          >
            {title}
          </h4>
          {description && <p style={{ padding: 0, margin: 0 }}>{description}</p>}
        </div>
        <div style={{ width: '25%', margin: 0 }}>
          {loading ? (
            <Box height={100} width={250} />
          ) : (
            <h3
              className={`text-${color}`}
              style={{ fontSize: 50, color, fontFamily: 'proxima-soft', margin: 0, textAlign: 'right' }}
            >
              {number === 0 ? 0 : number?.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
            </h3>
          )}
        </div>
      </div>
    </GalleryDashboardCard>
  );
};

export default NumberCard;
