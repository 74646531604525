import {
  ARCHIVE_TRANSACTION_TYPE,
  BillingSettingsActionTypes,
  CREATE_BILLING_CYCLE_TEMPLATE,
  CREATE_TRANSACTION_TYPES,
  DELETE_BILLING_CYCLE_TEMPLATE,
  GET_BILLING_CYCLE_TEMPLATES,
  GET_TRANSACTION_TYPES,
  UPDATE_BILLING_CYCLE_TEMPLATE,
  UPDATE_TRANSACTION_TYPE,
} from './types';

export const getTransactionTypes = (transactionTypes: ITransactionType[]): BillingSettingsActionTypes => ({
  type: GET_TRANSACTION_TYPES,
  transactionTypes,
});

export const createTransactionTypes = (transactionTypes: ITransactionType[]): BillingSettingsActionTypes => ({
  type: CREATE_TRANSACTION_TYPES,
  transactionTypes,
});

export const updateTransactionType = (transactionType: ITransactionType): BillingSettingsActionTypes => ({
  type: UPDATE_TRANSACTION_TYPE,
  transactionType,
});

export const archiveTransactionTypes = (transactionType: ITransactionType): BillingSettingsActionTypes => ({
  type: ARCHIVE_TRANSACTION_TYPE,
  transactionType,
});

export const getBillingCycleTemplates = (templates: IBillingCycleTemplate[]): BillingSettingsActionTypes => ({
  type: GET_BILLING_CYCLE_TEMPLATES,
  templates,
});

export const createBillingCycleTemplate = (template: IBillingCycleTemplate): BillingSettingsActionTypes => ({
  type: CREATE_BILLING_CYCLE_TEMPLATE,
  template,
});

export const updateBillingCycleTemplate = (template: IBillingCycleTemplate): BillingSettingsActionTypes => ({
  type: UPDATE_BILLING_CYCLE_TEMPLATE,
  template,
});

export const deleteBillingCycleTemplate = (template: IBillingCycleTemplate): BillingSettingsActionTypes => ({
  type: DELETE_BILLING_CYCLE_TEMPLATE,
  template,
});
