import config from 'config';
import { getI18n } from 'react-i18next';

export type Region = 'AU' | 'US';

export const isRegion = (region: Region) => {
  return config.locale.region === region;
};

export function getRegion() {
  return config.locale.region;
}

export const isDev = () => {
  return config.env.indexOf('qual') >= 0;
};

export function getTranslation(lng: string, ns: string): (key: string) => string {
  const i18n = getI18n();
  return (key) => i18n.getResource(lng, ns, key);
}
