import React, { useMemo, useState } from 'react';
import _, { intersection } from 'lodash';
import moment from 'moment';
import { ButtonAsLink } from 'shared/components/Buttons';
import DataTable from 'shared/components/DataTable';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import { useGetStaffByBirthdayWeek } from 'gql/staff/queries';
import { useGetChildrenByBirthdayWeek } from 'gql/child/queries';
import Card from 'shared/components/Card';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { HorizontalDivider } from 'shared/components/Dividers';
import './_birthday-table.scss';
import BirthdayDetailsModal from './BirthdayDetailsModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

const statusOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
];

const BirthdayWidget: React.FC = () => {
  const entityId = useSelector((state: RootState) => state.user?.entityId);
  const { t } = useTranslation();

  const timezone =
    useSelector((state: RootState) => state.timezone.byBusinessId[_.isNil(entityId) ? 1 : entityId]) ??
    moment.tz.guess();

  const startOfCurrentWeek = moment().tz(timezone).startOf('w');
  const [weekOffset, setWeekOffset] = useState(0);
  const [centerIds, setCenterIds] = useState<string[] | null>(null);
  const [statusFilters, setStatusFilters] = useState([statusOptions[0]]);
  const [detailsModal, setDetailsModal] = useState({
    type: 'CHILD',
    selectedWeekDayNum: 0,
    isOpen: false,
  });

  const hasStaffReadPermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const hasChildReadPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const { data: staffBirthdays, loading: staffLoading } = useGetStaffByBirthdayWeek(
    weekOffset,
    !hasStaffReadPermission
  );
  const { data: childrenBirthdays, loading: childrenLoading } = useGetChildrenByBirthdayWeek(
    weekOffset,
    !hasChildReadPermission
  );

  const staff = useMemo(
    () =>
      staffBirthdays?.getStaffByBirthdayWeek
        .filter(
          (s) =>
            (!centerIds ||
              s.roleship.scopeType === 'ENTITY' ||
              s.roleship.scopes.some((s) => centerIds.includes(s.id))) &&
            (!statusFilters.length ||
              (statusFilters.some((f) => f.value === 'ACTIVE') && s.employmentStatus === 'Active') ||
              (statusFilters.some((f) => f.value === 'INACTIVE') && s.employmentStatus !== 'Active'))
        )
        .map((s) => {
          var end = s.dob?.toUpperCase().lastIndexOf('T');
          if (!_.isNil(end) && end > 0) {
            return Object.assign({}, s, { dob: s.dob?.substring(0, end) });
          }
          return s;
        }) ?? [],
    [centerIds, staffBirthdays?.getStaffByBirthdayWeek, statusFilters]
  );

  const children = useMemo(
    () =>
      childrenBirthdays?.getChildrenByBirthdayWeek
        .filter(
          (c) =>
            (!centerIds || !!intersection(c.centerIds, centerIds).length) &&
            (!statusFilters.length ||
              (statusFilters.some((f) => f.value === 'ACTIVE') && c.isAccountActive) ||
              (statusFilters.some((f) => f.value === 'INACTIVE') && !c.isAccountActive))
        )
        .map((c) => {
          var end = c.dob.toUpperCase().lastIndexOf('T');
          if (end > 0) {
            return Object.assign({}, c, { dob: c.dob.substring(0, end) });
          }
          return c;
        }) ?? [],
    [centerIds, childrenBirthdays?.getChildrenByBirthdayWeek, statusFilters]
  );

  const dobCounts = useMemo(() => {
    function getCount(weekDayNum: number, type: string): number {
      const date = startOfCurrentWeek.clone().add(weekOffset, 'w').add(weekDayNum, 'd');
      const sourceData = (type === 'children' ? children : staff) as IPerson[];

      return sourceData.filter(
        (person) => date.month() === moment(person.dob).month() && date.date() === moment(person.dob).date()
      ).length;
    }
    return [
      {
        id: 'Children',
        day0: getCount(0, 'children'),
        day1: getCount(1, 'children'),
        day2: getCount(2, 'children'),
        day3: getCount(3, 'children'),
        day4: getCount(4, 'children'),
        day5: getCount(5, 'children'),
        day6: getCount(6, 'children'),
      },
      {
        id: 'Staff',
        day0: getCount(0, 'staff'),
        day1: getCount(1, 'staff'),
        day2: getCount(2, 'staff'),
        day3: getCount(3, 'staff'),
        day4: getCount(4, 'staff'),
        day5: getCount(5, 'staff'),
        day6: getCount(6, 'staff'),
      },
    ];
  }, [children, staff, startOfCurrentWeek, weekOffset]);

  return (
    <Card bodyClassName="px-6 py-0">
      <div className="d-flex align-items-center mt-4 mb-n2">
        <h5 className="mr-auto mb-2 mr-2">Birthdays</h5>
        <div className="ml-auto d-flex align-items-center flex-wrap justify-content-end ">
          <WeekPicker
            className="flex-grow-0 mr-6 mb-2"
            displayFormat="MMM D"
            startDate={startOfCurrentWeek.clone().add(weekOffset, 'w')}
            endDate={startOfCurrentWeek.clone().endOf('w').add(weekOffset, 'w')}
            rangeComponent={<span className="mx-1">-</span>}
            onChange={(dates) => setWeekOffset(dates.startDate.diff(startOfCurrentWeek, 'week'))}
            reactDatesController="RANGE"
          />
          <DropdownFilter
            className="mb-2 mr-2"
            title="Status"
            selectedFilters={statusFilters}
            options={statusOptions}
            onFilterSelect={setStatusFilters}
            alwaysShowTitle
          />
          <MultipleCenterSelect
            useNullForAllOption={true}
            selectedCenterIds={centerIds}
            onSelect={(ids) => setCenterIds(!ids?.length ? null : ids)}
            showLabel={false}
            className="flex-grow-0 w-240px mb-2"
          />
        </div>
      </div>
      <HorizontalDivider />
      <DataTable
        noPadding
        className="birthday-table mb-4"
        data={dobCounts}
        showPagination={false}
        showSelect={false}
        showLoadingOverlay={staffLoading || childrenLoading}
        noDataText="No birthdays for selected week."
        columns={[
          {
            dataField: 'id',
            text: '',
          },
          ...[0, 1, 2, 3, 4, 5, 6].map((weekDayNum) => {
            const date = startOfCurrentWeek.clone().add(weekOffset, 'w').add(weekDayNum, 'd');
            return {
              dataField: `day${weekDayNum}`,
              text: `${date.format('ddd')} ${date.format(t('formatters.day-month-short'))}`,
              align: 'center',
              headerAlign: 'center',
              formatter: (cell: number, row: any, index: number) => {
                return cell > 0 ? (
                  (index === 1 && hasStaffReadPermission) || (index === 0 && hasChildReadPermission) ? (
                    <ButtonAsLink
                      onClick={() =>
                        setDetailsModal({
                          isOpen: true,
                          type: index === 0 ? 'CHILD' : 'STAFF',
                          selectedWeekDayNum: weekDayNum,
                        })
                      }
                    >
                      {cell}{' '}
                    </ButtonAsLink>
                  ) : (
                    <span>{cell}</span>
                  )
                ) : (
                  cell
                );
              },
            };
          }),
        ]}
      />
      {detailsModal.isOpen && (
        <BirthdayDetailsModal
          isOpen={detailsModal.isOpen}
          onClose={() => setDetailsModal((prev) => ({ ...prev, isOpen: false }))}
          selectedWeekDayNum={detailsModal.selectedWeekDayNum}
          type={detailsModal.type as 'CHILD' | 'STAFF'}
          children={children}
          staff={staff}
          weekOffset={weekOffset}
        />
      )}
      <br />
      <p className="'sm d-flex mt-0 justify-content-end">
        <em>*Showing Birthdays in {moment.tz(timezone)?.format('z')}</em>
      </p>
    </Card>
  );
};

export default BirthdayWidget;
