import { gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useMutation } from 'shared/apis/core';
import { archiveClass } from '../duck/actions';

interface IArchiveClassData {
  archiveClass: IClass;
}

interface IArchiveClassVariables {
  input: {
    id: string;
  };
}

export const ARCHIVE_CLASS = gql`
  mutation ($input: ArchiveClassInput!) {
    archiveClass(input: $input) {
      id
      centerId
    }
  }
`;

export const useArchiveClass = () => {
  const dispatch = useDispatch();

  return useMutation<IArchiveClassData, IArchiveClassVariables>(ARCHIVE_CLASS, {
    onCompleted: (data) => {
      dispatch(archiveClass(data.archiveClass.id));
    },
  });
};
