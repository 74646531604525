import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';

export const GET_ALLERGY_HISTORY = gql`
  query ($id: ID!) {
    getAllergyHistory(id: $id) {
      id
      severity
      instructions
      documentsChanged
      reactionsChanged
      revisedAt
      revisedByPerson {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
      }
      allergyVersion {
        severity
        reactions
        instructions
      }
    }
  }
`;

export const useGetAllergyHistoryLazy = (id: string) => {
  return useLazyQuery<{ getAllergyHistory: IAllergyRevision[] }, { id: string }>(GET_ALLERGY_HISTORY, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });
};
