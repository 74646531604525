import moment from 'moment';
import { useGetClassesForCenters } from 'pages/Centers/subroutes/Classes/graphql/queries';
import { updateVisibleClassForEnrollment } from 'pages/Enrollment/duck/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import { RootState } from 'store/reducers';
import Availabilityfilter from '../../../components/AvailabilityFilter/AvailabilityFilter';

interface IProps {
  startOfWeek: moment.Moment;
  endOfWeek: moment.Moment;
  onWeekChange: (start: moment.Moment, end: moment.Moment) => void;
  includeLtbOffers: boolean;
  includeFutureContracts: boolean;
}

const TimeframeAndClassControls: React.FC<IProps> = ({
  startOfWeek,
  endOfWeek,
  onWeekChange,
  includeFutureContracts,
  includeLtbOffers,
  ...props
}) => {
  const dispatch = useDispatch();
  const classes = useSelector((state: RootState) => state.classes.all).filter((c) => !c.archivedAt);
  const activeClass = useSelector((state: RootState) => state.enrollment.activeClass);
  const selectedCenterId = useSelector((state: RootState) => state.enrollment.activeCenter.id);

  const { loading } = useGetClassesForCenters(selectedCenterId, {}, moment(startOfWeek).format('YYYY-MM-DD'));

  return (
    <div className="d-flex flex-row align-items-center bg-white py-2 px-4">
      <Col md={4} sm={12}>
        <Row>
          <Select
            options={classes}
            value={activeClass || null}
            onChange={(c) => dispatch(updateVisibleClassForEnrollment(c))}
            getOptionLabel={(c) => c.name}
            getOptionValue={(c) => c.id}
            isLoading={loading}
            className="mb-0 mr-4 flex-grow-0 min-width-200"
            placeholder="Select a class"
          />
        </Row>
      </Col>
      <Col md={4} sm={12}>
        <Row justify="center">
          <WeekPicker
            displayFormat="MMM D"
            reactDatesController="RANGE"
            onChange={(dates) => onWeekChange(dates.startDate, dates.endDate)}
            className="flex-grow-0 mb-0"
            startDate={startOfWeek}
            endDate={endOfWeek}
          />
        </Row>
      </Col>
      <Availabilityfilter />
    </div>
  );
};

export default TimeframeAndClassControls;
