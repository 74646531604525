import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { timeOffFields } from './queries';
import { useDispatch } from 'react-redux';
import { addTimeOffSuccess, updateTimeOffSuccess, cancelTimeOffSuccess } from '../duck/actions';
import { MutationHookOptions } from '@apollo/client';

interface IUpdateTimeOffData {
  updateTimeOffRequest: ITimeOff;
}

interface IUpdateTimeOffVariables {
  input: ITimeOffInput;
}

export const CREATE_TIME_OFF = gql`
  mutation($input: CreateTimeOffRequestInput!) {
    createTimeOffRequest(input: $input) {
      ${timeOffFields}
    }
  }
`;

export const CREATE_TIME_OFF_FOR_ME = gql`
  mutation($input: CreateTimeOffRequestInput!) {
    createTimeOffRequestForMe(input: $input) {
      ${timeOffFields}
    }
  }
`;

export const UPDATE_TIME_OFF = gql`
  mutation($input: UpdateTimeOffRequestInput!) {
    updateTimeOffRequest(input: $input) {
      ${timeOffFields}
    }
  }
`;

export const CANCEL_TIME_OFF = gql`
  mutation ($input: CancelTimeOffRequestInput!) {
    cancelTimeOffRequest(input: $input) {
      id
      centerId
      entityId
      startTime
      endTime
    }
  }
`;

export const useCreateTimeOff = () => {
  const dispatch = useDispatch();
  return useMutation<{ createTimeOffRequest: ITimeOff }, { input: ITimeOffInput }>(CREATE_TIME_OFF, {
    onCompleted: (data) => {
      dispatch(addTimeOffSuccess(data.createTimeOffRequest));
    },
  });
};

export const useCreateTimeOffForMe = () => {
  const dispatch = useDispatch();
  return useMutation<{ createTimeOffRequestForMe: ITimeOff }, { input: ITimeOffInput }>(CREATE_TIME_OFF_FOR_ME, {
    onCompleted: (data) => {
      dispatch(addTimeOffSuccess(data.createTimeOffRequestForMe));
    },
  });
};

export const useUpdateTimeOff = (options?: MutationHookOptions<IUpdateTimeOffData, IUpdateTimeOffVariables>) => {
  const dispatch = useDispatch();

  return useMutation<IUpdateTimeOffData, IUpdateTimeOffVariables>(UPDATE_TIME_OFF, {
    ...options,
    onCompleted: (data) => {
      dispatch(updateTimeOffSuccess(data.updateTimeOffRequest));
      options?.onCompleted && options.onCompleted(data);
    },
  });
};

export const useCancelTimeOff = () => {
  const dispatch = useDispatch();

  return useMutation<{ cancelTimeOffRequest: ITimeOff }, { input: { id: string } }>(CANCEL_TIME_OFF, {
    onCompleted: (data) => {
      dispatch(cancelTimeOffSuccess(data.cancelTimeOffRequest));
    },
  });
};
