import React, { useCallback } from 'react';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import { useTranslation } from 'react-i18next';

interface IProps {
  formData: IFamilyTaxBenefitForm;
  programChild: IProgramChild;
  handleChange: (value: any, name: string) => void;
  showDocumentSelect: boolean;
}

const EditFamilyTaxBenefitForm: React.FC<IProps> = ({ formData, showDocumentSelect, handleChange }) => {
  const { t } = useTranslation(['subsidies']);
  const handleFileChange = (files: File[]) => {
    handleChange(files[files.length - 1], 'file');
  };
  const partOptions = [
    { value: 'None', label: t('subsidies:program-child.familyTaxBenefit.none') },
    { value: 'FamilyTaxBenefitA', label: t('subsidies:program-child.familyTaxBenefit.familyTaxBenefitA') },
    { value: 'FamilyTaxBenefitAAndB', label: t('subsidies:program-child.familyTaxBenefit.familyTaxBenefitAAndB') },
  ];

  return (
    <div>
      <Row>
        <Col lg={6}>
          <label>Does the family receive Family Tax Benefits?</label>
          <Select
            options={partOptions}
            getOptionLabel={(g) => g.label}
            getOptionValue={(g) => g.value}
            onChange={handleChange}
            name="part"
            value={formData.part}
            placeholder="None"
          />
        </Col>
      </Row>
      {formData.linkToFile && (
        <Row className="mb-2">
          <Col>
            <a href={formData.linkToFile}>Current Document: {formData.fileName}</a>
          </Col>
        </Row>
      )}
      {showDocumentSelect && (
        <Row>
          <Col>
            <FileSelectDropbox
              multiple={false}
              className="mb-2"
              value={formData.file ? [formData.file] : []}
              onChange={handleFileChange}
              showFiles
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EditFamilyTaxBenefitForm;
