import React, { useCallback } from 'react';
import momentTz from 'moment-timezone';
import Tooltip from 'shared/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';
import classnames from 'classnames';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  timeEntry: ITimeEntry;
  associatedShift: IShift | null;
  isForceClockOut: boolean;
  textLeftMargin?: number;
  centerTimezone: string;
}

const TimeEntryBlock: React.FC<IProps> = ({
  timeEntry,
  associatedShift,
  isForceClockOut,
  textLeftMargin = 0,
  centerTimezone,
  ...props
}) => {
  const getTooltipText = useCallback(
    (timeEntry: ITimeEntry, shift: IShift | null): string => {
      switch (timeEntry.type) {
        case 'SHIFT': {
          const actualTimeWorked = `${momentTz(timeEntry.trackedTimeIn).tz(centerTimezone).format('h:mm A')} - ${
            timeEntry.trackedTimeOut ? momentTz(timeEntry.trackedTimeOut).tz(centerTimezone).format('h:mm A') : ''
          }`;
          const scheduledTimeWorked = shift
            ? `${momentTz(shift.startTime).tz(centerTimezone).format('h:mm A')} - ${momentTz(shift.endTime)
                .tz(centerTimezone)
                .format('h:mm A')}`
            : '';
          return scheduledTimeWorked
            ? `Scheduled: ${scheduledTimeWorked} \n Actual: ${actualTimeWorked}`
            : `${actualTimeWorked}`;
        }
        case 'UNSCHEDULED':
        case 'TRAINING': {
          if (timeEntry.trackedTimeOut) {
            return `${momentTz(timeEntry.trackedTimeIn).tz(centerTimezone).format('h:mma')} - ${momentTz(
              timeEntry.trackedTimeOut
            )
              .tz(centerTimezone)
              .format('h:mma')}`;
          }
          return `${momentTz(timeEntry.trackedTimeIn).tz(centerTimezone).format('h:mma')} - `;
        }
        case 'BREAK':
          if (timeEntry.trackedTimeOut) {
            return `${momentTz(timeEntry.trackedTimeOut)
              .tz(centerTimezone)
              .diff(momentTz(timeEntry.trackedTimeIn).tz(centerTimezone), 'minutes')} minutes`;
          }
          return `${momentTz()
            .tz(centerTimezone)
            .diff(momentTz(timeEntry.trackedTimeIn).tz(centerTimezone), 'minutes')} minutes`;
        default:
          return '';
      }
    },
    [centerTimezone]
  );

  const unexpectedTimeOut =
    associatedShift && timeEntry.trackedTimeOut
      ? momentTz(timeEntry.trackedTimeOut)
          .tz(centerTimezone)
          .isAfter(momentTz(associatedShift.endTime).tz(centerTimezone))
      : false;
  const classes = classnames({
    'time-entry': true,
    break: timeEntry.type === 'BREAK',
    'unexpected-out': unexpectedTimeOut,
    'force-clock-out': isForceClockOut,
  });

  return (
    <Tooltip direction="top" text={getTooltipText(timeEntry, associatedShift)}>
      <div {...props} className={classes}>
        {timeEntry.type !== 'BREAK' && (
          <span style={{ marginLeft: `${textLeftMargin + 8}px` }}>
            {`${momentTz(timeEntry.trackedTimeIn).tz(centerTimezone).format('h:mma')} - ${
              timeEntry.trackedTimeOut ? momentTz(timeEntry.trackedTimeOut).tz(centerTimezone).format('h:mma') : ''
            }`}
          </span>
        )}
        {isForceClockOut && <FontAwesomeIcon icon={faExclamationTriangle} color={colors.white} className="ml-2" />}
        {timeEntry.type === 'SHIFT' && (
          <span className="ml-auto mr-2">{associatedShift?.position?.positionName ?? ''}</span>
        )}
      </div>
    </Tooltip>
  );
};

export default TimeEntryBlock;
