import React, { useCallback, useState } from 'react';
import CreateProgramGroupModal from './CreateProgramGroupModal';
import useGetEnrollmentGroupsTable from '../../hooks/useGetEnrollmentGroupsTable';
import EnrollmentProgramGroupsTable from './ProgramGroupsTable/ProgramGroupsTable';
import EditProgramGroupModal from './EditProgramGroupModal/EditProgramGroupModal';
import { ApplicationFlowType, useDeleteEnrollmentProgramGroupMutation } from 'generated/graphql';
import ProgramGroupSettingBanner from './ProgramGroupSettingBanner/ProgramGroupSettingBanner';
import { useGetApplicationSettings } from 'gql/applicationSetting/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useUpdateApplicationSettings } from 'gql/applicationSetting/mutation';
import DeleteProgramGroupConfirmModal from './DeleteProgramGroupConfirmModal/DeleteProgramGroupConfirmModal';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

export enum ApplicationSettingType {
  isUseProgramGroup = 'isUseProgramGroup',
  isUsePerProgramGroupFees = 'isUsePerProgramGroupFees',
}

interface IProps {
  setIsCreateProgramGroupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCreateProgramGroupModalOpen: boolean;
}
const ProgramGroupsTabs: React.FC<IProps> = ({ setIsCreateProgramGroupModalOpen, isCreateProgramGroupModalOpen }) => {
  const { t } = useTranslation();
  const { tableProps, setSelectedProgramGroup, selectedProgramGroup, refetch, action } = useGetEnrollmentGroupsTable();
  const { businessId } = useSelector((state: RootState) => state.context);
  const [isUseProgramGroup, setUseProgramGroup] = useState(false);
  const [isUsePerProgramGroupFees, setUsePerProgramGroupFees] = useState(false);

  const { data } = useGetApplicationSettings({
    variables: {
      input: {
        businessId: businessId ?? '',
      },
    },
    skip: !businessId,
    onCompleted: (data) => {
      setUseProgramGroup(data?.getApplicationSetting?.isUseProgramGroup ?? false);
      setUsePerProgramGroupFees(data?.getApplicationSetting?.isUsePerProgramGroupFees ?? false);
    },
  });

  const [updateSetting] = useUpdateApplicationSettings({
    onCompleted: (data) => {
      setUseProgramGroup(data?.updateApplicationSetting?.isUseProgramGroup ?? false);
      setUsePerProgramGroupFees(data?.updateApplicationSetting?.isUsePerProgramGroupFees ?? false);
      showToast(t('enrollment.program-groups.update-setting-success'), 'success');
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  const [deleteProgramGroup, { loading: deleteLoading }] = useDeleteEnrollmentProgramGroupMutation({
    onCompleted: () => {
      refetch();
      setSelectedProgramGroup(undefined);
      showToast(t('enrollment.program-groups.delete-program-group-success'), 'success');
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  const handleDeleteProgramGroup = useCallback(() => {
    if (selectedProgramGroup) {
      deleteProgramGroup({
        variables: {
          businessId: selectedProgramGroup?.businessId,
          programGroupId: selectedProgramGroup?.id,
        },
      });
    }
  }, [selectedProgramGroup]);

  const handleSettingToggle = useCallback(
    (value: boolean, valueType: ApplicationSettingType) => {
      const businessSetting = data?.getApplicationSetting ?? {};
      if (businessSetting) {
        updateSetting({
          variables: {
            input: {
              businessId: businessId ?? '',
              offersExpireInDays: businessSetting.offersExpireInDays ?? 0,
              flowType: businessSetting.flowType ?? ApplicationFlowType.InquireProgramEnrollmentApprove,
              isUseProgramGroup: valueType == ApplicationSettingType.isUseProgramGroup ? value : isUseProgramGroup,
              isUsePerProgramGroupFees:
                valueType == ApplicationSettingType.isUsePerProgramGroupFees ? value : isUsePerProgramGroupFees,
            },
          },
        });
      }
    },
    [data?.getApplicationSetting, updateSetting, businessId, isUseProgramGroup, isUsePerProgramGroupFees]
  );

  return (
    <div>
      <CreateProgramGroupModal
        refetchProgramGroups={refetch}
        isModalOpen={isCreateProgramGroupModalOpen}
        setIsModalOpen={setIsCreateProgramGroupModalOpen}
      />
      <EditProgramGroupModal
        refetchProgramGroups={refetch}
        isModalOpen={!!selectedProgramGroup && action === 'edit'}
        programGroup={selectedProgramGroup}
        handleCloseModal={() => setSelectedProgramGroup(undefined)}
      />
      <DeleteProgramGroupConfirmModal
        loading={deleteLoading}
        onHide={() => setSelectedProgramGroup(undefined)}
        onSubmit={handleDeleteProgramGroup}
        show={!!selectedProgramGroup && action === 'delete'}
      />
      <ProgramGroupSettingBanner
        groupSettingValue={isUseProgramGroup}
        onChangeGroupSetting={handleSettingToggle}
        feeSettingValue={isUsePerProgramGroupFees}
      />
      <EnrollmentProgramGroupsTable {...tableProps} />
    </div>
  );
};

export default ProgramGroupsTabs;
