import React from 'react';
import Col from './Col';
import { ILayoutProps } from './LayoutProps';

const ColWithMb: React.FC<ILayoutProps> = ({ justify, align, className, children, ...props }) => {
  return (
    <Col {...props} justify={justify} align={align} className={`mb-4 ${className || ''}`}>
      {children}
    </Col>
  );
};

export default ColWithMb;
