import React from 'react';
import { isRegion } from 'shared/util/region';

import * as Mui from '@mui/material';

import { IApiScopesType, ApiScopeAuthType } from 'gql/integrationApiKey/queries';
import i18n from 'i18n';
import { capitalize } from 'lodash';
import { styled } from '@mui/material';

const CustomRadio = styled(Mui.Radio)({
  paddingTop: '4px',
  paddingBottom: '4px',
});

interface IPropsScope {
  apiScopeLabel: string;
  apiScopeKey: keyof IApiScopesType;
  apiScopeValue: ApiScopeAuthType | undefined;
  setApiScopeValue: (apiScopeName: keyof IApiScopesType, apiScopeAuthValue: ApiScopeAuthType) => void;
}

const ApiScopesOption: React.FC<IPropsScope> = ({
  apiScopeLabel,
  apiScopeKey,
  apiScopeValue,
  setApiScopeValue,
  ...props
}) => {
  const [apiScopeLocalValue, setApiScopeLocalValue] = React.useState(apiScopeValue);
  return (
    <Mui.Card variant="outlined">
      <Mui.CardContent>
        <Mui.FormControl>
          <Mui.FormLabel style={{ fontWeight: 'bold' }}>{apiScopeLabel}</Mui.FormLabel>
          <Mui.RadioGroup
            aria-labelledby="scope-rg"
            name="scope-rg"
            value={apiScopeLocalValue}
            onChange={(e) => {
              var newValue =
                e.target.value === 'Write'
                  ? ApiScopeAuthType.Write
                  : e.target.value === 'Read'
                  ? ApiScopeAuthType.Read
                  : ApiScopeAuthType.None;

              setApiScopeValue(apiScopeKey, newValue);
              setApiScopeLocalValue(newValue);
            }}
          >
            <Mui.FormControlLabel value="None" control={<CustomRadio />} label="None" />
            <Mui.FormControlLabel value="Read" control={<CustomRadio />} label="Read" />
            <Mui.FormControlLabel value="Write" control={<CustomRadio />} label="Read & Write" />
          </Mui.RadioGroup>
        </Mui.FormControl>
      </Mui.CardContent>
    </Mui.Card>
  );
};

interface IProps {
  fullAccess: Boolean;
  apiScopes: IApiScopesType | null;
  setApiScopeValue: (ApiScopeName: keyof IApiScopesType, ApiScopeAuthValue: ApiScopeAuthType) => void;
}

const ApiScopesOptions: React.FC<IProps> = ({ apiScopes, fullAccess, setApiScopeValue, ...props }) => {
  if (fullAccess) return <></>;

  return (
    <>
      <Mui.FormLabel>General</Mui.FormLabel>
      <ApiScopesOption
        apiScopeLabel="Business"
        apiScopeKey="business"
        apiScopeValue={apiScopes?.business}
        setApiScopeValue={setApiScopeValue}
      />
      <ApiScopesOption
        apiScopeLabel={capitalize(i18n.t('translation:spelling.center'))}
        apiScopeKey="centers"
        apiScopeValue={apiScopes?.centers}
        setApiScopeValue={setApiScopeValue}
      />

      <ApiScopesOption
        apiScopeLabel="Classes"
        apiScopeKey="classes"
        apiScopeValue={apiScopes?.classes}
        setApiScopeValue={setApiScopeValue}
      />

      <ApiScopesOption
        apiScopeLabel="Fees"
        apiScopeKey="fees"
        apiScopeValue={apiScopes?.fees}
        setApiScopeValue={setApiScopeValue}
      />

      <Mui.FormLabel>Record Keeping</Mui.FormLabel>
      <ApiScopesOption
        apiScopeLabel="Sessions"
        apiScopeKey="sessions"
        apiScopeValue={apiScopes?.sessions}
        setApiScopeValue={setApiScopeValue}
      />
      <ApiScopesOption
        apiScopeLabel="Applications"
        apiScopeKey="applications"
        apiScopeValue={apiScopes?.applications}
        setApiScopeValue={setApiScopeValue}
      />
      <ApiScopesOption
        apiScopeLabel="Staff"
        apiScopeKey="staff"
        apiScopeValue={apiScopes?.staff}
        setApiScopeValue={setApiScopeValue}
      />

      <Mui.FormLabel>People</Mui.FormLabel>
      <ApiScopesOption
        apiScopeLabel="Accounts"
        apiScopeKey="accounts"
        apiScopeValue={apiScopes?.accounts}
        setApiScopeValue={setApiScopeValue}
      />
      <ApiScopesOption
        apiScopeLabel="Children"
        apiScopeKey="children"
        apiScopeValue={apiScopes?.children}
        setApiScopeValue={setApiScopeValue}
      />
      <ApiScopesOption
        apiScopeLabel="Contacts"
        apiScopeKey="contacts"
        apiScopeValue={apiScopes?.contacts}
        setApiScopeValue={setApiScopeValue}
      />

      <Mui.FormLabel>Money</Mui.FormLabel>
      <ApiScopesOption
        apiScopeLabel="Transactions"
        apiScopeKey="transactions"
        apiScopeValue={apiScopes?.transactions}
        setApiScopeValue={setApiScopeValue}
      />
      <ApiScopesOption
        apiScopeLabel="Deposits"
        apiScopeKey="deposits"
        apiScopeValue={apiScopes?.deposits}
        setApiScopeValue={setApiScopeValue}
      />

      <Mui.FormLabel>Other</Mui.FormLabel>
      {isRegion('AU') && (
        <ApiScopesOption
          apiScopeLabel="CCS Entitlements"
          apiScopeKey="entitlements"
          apiScopeValue={apiScopes?.entitlements}
          setApiScopeValue={setApiScopeValue}
        />
      )}
      <ApiScopesOption
        apiScopeLabel="Attendances"
        apiScopeKey="attendances"
        apiScopeValue={apiScopes?.attendances}
        setApiScopeValue={setApiScopeValue}
      />
    </>
  );
};

export default ApiScopesOptions;
