import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import { Row, Col } from 'shared/components/Layout';

const ImportSource: React.FC = () => {
  const { t } = useTranslation('ktAdmin');
  return (
    <Card header={t('data-importer.import-source')}>
      <Row>
        <Col>
          <Form.Check className="d-flex align-items-center mb-2">
            <Form.Check.Input id="import-source-procare" type="radio" name="import-source" checked={true} />
            <Form.Check.Label>Procare</Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
    </Card>
  );
};

export default ImportSource;
