import { uniqBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

interface IProps {
  data: IISCase;
}

const ISCaseRawChildrenColumn: React.FC<IProps> = ({ data }) => {
  const { daysOfCare, children } = data;

  return (
    <Col lg={3} xl={3} md={12} sm={12}>
      <Row>
        <Col className="col-md-6 font-weight-semi-bold">Days of Care: </Col>
      </Row>

      {daysOfCare.map((day) => (
        <Row key={day.dayOfCare}>
          <Col lg={5} md={6} className="font-weight-semi-bold">
            {day.dayOfCare}
          </Col>
          <Col md={5}>{moment.duration(day.hours).hours()}</Col>
        </Row>
      ))}

      <Row className="mt-4">
        <Col md={5} className="font-weight-semi-bold">
          Children:{' '}
        </Col>
      </Row>

      {uniqBy(children, ({ childId }) => childId).map(({ child }) => (
        <Row key={child.id}>
          <Col lg={5} md={6} className="font-weight-semi-bold">
            {child.fullName}
          </Col>
        </Row>
      ))}
    </Col>
  );
};

export default ISCaseRawChildrenColumn;
