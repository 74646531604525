import moment from 'moment';
import React from 'react';
import { setOtherZone } from 'shared/util/timeUtils';
import DateRangeInput, { DateRangeIProps } from './DateRangeInput';

// react-dates package bases dates on the browser time
// Convert the date value received from parent component to browser time to use in our Date Input,
// Then when we send it back to the parent component, well convert it to the actual timezone we want

interface IProps extends DateRangeIProps {
  timezone: string;
}

const DateRangeInputWithTimezone: React.FC<IProps> = ({ startDate, endDate, onChange, timezone, ...rest }) => {
  const browserTimezone = moment.tz.guess();
  return (
    <DateRangeInput
      {...rest}
      startDate={startDate ? setOtherZone(moment(startDate).tz(timezone), browserTimezone).format() : startDate}
      endDate={endDate ? setOtherZone(moment(endDate).tz(timezone), browserTimezone).format() : endDate}
      onChange={(dates) => {
        dates.startDate = dates.startDate ? setOtherZone(moment(dates.startDate), timezone).format() : dates.startDate;
        dates.endDate = dates.endDate ? setOtherZone(moment(dates.endDate), timezone).format() : dates.endDate;
        onChange && onChange(dates);
      }}
      timezone={browserTimezone}
    />
  );
};

export default DateRangeInputWithTimezone;
