import React, { MouseEventHandler } from 'react';
//components
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

interface IProps {
  modal: {
    title: string;
    content: string;
    submit: string;
    isOpen: boolean;
    type?: string;
  };
  hide: MouseEventHandler<HTMLElement>;
  submit: MouseEventHandler<HTMLElement>;
}

export const ModalWindowCWA: React.FC<IProps> = ({ modal, hide, submit }) => {
  return (
    // @ts-ignore
    <Modal centered backdrop="static" show={modal.isOpen} onHide={hide} size="lg">
      <Modal.Header closeButton className="border-bottom-0 pb-4">
        <div className="d-flex flex-column">
          <Modal.Title as="h3">{modal.title}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0">{modal.content}</Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button id="edit-cycle-modal-secondary-btn" className="mr-3" variant="light" onClick={hide}>
          Cancel
        </Button>
        <Button id="edit-cycle-modal-primary-btn" variant="primary" onClick={submit}>
          {modal.submit}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
