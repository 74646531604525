import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import Auth from '@aws-amplify/auth';

const cache = new InMemoryCache();

/**
 * Create an apollo client object that can be used in sagas
 * @param {String} uri - graphql uri
 */
export const createClient = (uri) => {
  const httpLink = createUploadLink({ uri });

  const authLink = setContext(async (_, { headers }) => {
    let jwt = '';
    try {
      const session = await Auth.currentSession();
      jwt = session.idToken.jwtToken;
    } catch (error) {
      console.log(error);
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${jwt}`,
      },
    };
  });

  return new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
  });
};
