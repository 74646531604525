import React from 'react';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import BillingTransactionTableRow from './BillingTransactionTableRow';
import i18n from 'i18n';
import { capitalize, isEmpty } from 'lodash';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import { isRegion } from 'shared/util/region';

interface INewTransactionTypeModalState {
  open: boolean;
  businessId: string | null;
  centerId: string | null;
}

interface IProps {
  transactionTypes: ITransactionType[] | [];
  canViewBusinessLevelTransactionTypes: boolean;
  loading: boolean;
  page: number;
  setPage: (x: number) => void;
  size: number;
  totalCount: number;
  refetch: () => void;
  sort: ISortBy;
  setSort: (x: ISortBy) => void;
}

interface ISortBy {
  field: string;
  direction: SortDirection;
}

type SortDirection = 'ASCENDING' | 'DESCENDING';

const columns = [
  { name: 'Name', field: 'Name', displayLabel: true, alignLeft: true, sortable: true },
  {
    name: capitalize(i18n.t('translation:spelling.center_plural')),
    field: 'centers',
    displayLabel: true,
    alignLeft: true,
    sortable: false,
  },
  { name: 'Status', field: 'status', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'Type', field: 'type', displayLabel: true, alignLeft: true, sortable: false },
  ...(isRegion('US')
    ? [{ name: 'Tuition', field: 'tuition', displayLabel: true, alignLeft: true, sortable: false }]
    : []),
  { name: 'Assigned To', field: 'assignedTo', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'GL Code', field: 'glCode', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'Row Actions', field: 'id', displayLabel: false, alignLeft: false, sortable: false },
];

const BillingTransactionsTable: React.FC<IProps> = ({
  transactionTypes,
  canViewBusinessLevelTransactionTypes,
  loading,
  page,
  setPage,
  size,
  totalCount,
  refetch,
  sort,
  setSort,
  ...props
}) => {
  const [pageNumber, setPageNumber] = React.useState(0);

  const changeSort = (field: string) => {
    setSort({
      field: field,
      direction: sort.direction === 'ASCENDING' ? 'DESCENDING' : 'ASCENDING',
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
    setPageNumber(newPage);
  };

  React.useEffect(() => {
    setPageNumber(page - 1);
  }, [page]);

  return (
    <>
      {loading ? (
        <LoadingLines number={4} />
      ) : (
        <Mui.Box sx={{ width: '100%' }}>
          <Mui.Paper sx={{ mb: 2 }} elevation={0}>
            <Mui.TableContainer>
              <Mui.Table aria-labelledby="billing-transactions-table" style={{ width: '100%' }}>
                <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
                  {columns.map((column) => (
                    <Mui.TableCell key={column.name} align={column.alignLeft ? 'left' : 'center'} padding={'normal'}>
                      <Mui.Typography variant="tableHeadCells">
                        {column.displayLabel && column.name}
                        {column.sortable && (
                          <Mui.IconButton onClick={() => changeSort(column.field)}>
                            <FontAwesomeIcon
                              size="xs"
                              icon={
                                sort.field === column.field
                                  ? sort.direction === 'ASCENDING'
                                    ? faAngleDown
                                    : faAngleUp
                                  : faAngleDown
                              }
                            />
                          </Mui.IconButton>
                        )}
                      </Mui.Typography>
                    </Mui.TableCell>
                  ))}
                </Mui.TableHead>
                <Mui.TableBody>
                  {!loading &&
                    transactionTypes.map((row, index) => (
                      <BillingTransactionTableRow
                        canViewBusinessLevelTransactionTypes={canViewBusinessLevelTransactionTypes}
                        key={row.id}
                        index={index}
                        refetch={() => refetch()}
                        row={row}
                      />
                    ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
            {isEmpty(transactionTypes) && (
              <Mui.Box
                sx={{ width: '100%', margin: 0 }}
                display="flex"
                bgcolor="white"
                alignItems="center"
                justifyContent="center"
              >
                <Mui.Typography variant="body1" padding={'16px'}>
                  Oops. We don’t have any data for that. Try another search.
                </Mui.Typography>
              </Mui.Box>
            )}
            <Mui.TablePagination
              sx={{
                '.MuiTablePagination-displayedRows': {
                  margin: 0,
                  padding: 0,
                },
              }}
              rowsPerPageOptions={[10]}
              component="div"
              count={totalCount}
              rowsPerPage={size}
              page={pageNumber}
              onPageChange={handleChangePage}
            />
          </Mui.Paper>
        </Mui.Box>
      )}
    </>
  );
};

export default BillingTransactionsTable;
