import React from 'react';
import classnames from 'classnames';
import Card, { BoldCardHeader } from 'shared/components/Card';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import PaymentMethod from 'shared/components/PaymentMethods/PaymentMethod';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IProps {
  hasAutoPay: boolean;
  loading: boolean;
  disabled?: boolean;
  onClick: () => void;
  paymentMethod?: IPaymentMethod;
}

const DefaultPaymentMethodCard: React.FC<IProps> = ({
  hasAutoPay,
  loading,
  paymentMethod,
  disabled = false,
  onClick,
  ...props
}) => {
  const { t } = useTranslation(['billing', 'translation']);
  const cardBodyClassNames = classnames('p-4');
  const canEditPaymentMethodsPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.PaymentMethods,
    level: RoleLevelType.Edit,
  });

  return (
    <div>
      <Card
        bodyClassName={cardBodyClassNames}
        onClick={onClick}
        loading={loading}
        loadingLines={2}
        style={{ minHeight: 140 }}
      >
        <div className="d-flex justify-content-between">
          <div>
            <div className="mb-2">
              <BoldCardHeader className="d-inline" text={t('billing:payment-methods.default-payment-method')} />
            </div>
            {paymentMethod ? (
              <PaymentMethod showCardHolder paymentMethod={paymentMethod} className="payment-summary-card" />
            ) : (
              <div>
                <small>{t('billing:payment-methods.default-payment-no-setup')}</small>
              </div>
            )}
          </div>
          {canEditPaymentMethodsPermissions && (
            <Button variant="outline-primary" disabled={disabled}>
              {t('translation:spelling.manage')}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default DefaultPaymentMethodCard;
