import React, { useState, useEffect, useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import DataTable, { TableSearch, SizePerPage, TableHeader } from 'shared/components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import Alert from 'react-bootstrap/Alert';
import { sortBy } from 'lodash';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { STATES } from 'shared/constants/dropdownOptions/countryInfo';
import useDatatableState from 'shared/hooks/useDatatableState';
import { parsePhoneNumberWithRegion } from 'shared/util/string';

interface IProps {
  handleRowClick: (event: React.SyntheticEvent<Element, Event>, business: IBusiness) => void;
  data: IBusiness[];
  error?: ApolloError;
}

const EntityProfilesTable: React.FC<IProps> = ({ handleRowClick, data, error, ...props }) => {
  const [filterTerm, setFilterTerm] = useState('');
  const [tableData, setTableData] = useState<IBusiness[]>(sortBy(data, ['name']));
  const [tableState, tableFunctions] = useDatatableState('business');

  const filterData = useCallback((data: IBusiness[], term: string): IBusiness[] => {
    return term
      ? data.filter(
          (b) =>
            b.name.toLowerCase().includes(term.toLowerCase()) ||
            b.address?.city.toLowerCase().includes(term.toLowerCase()) ||
            (STATES[b.address?.state] || '').toLowerCase().includes(term.toLowerCase())
        )
      : data;
  }, []);

  // react on active page, size per page, and search filter changes
  useEffect(() => {
    const filteredArr = filterData(data, filterTerm);

    const startIdx = (tableState.activePage - 1) * tableState.pageSize;
    const endIdx = tableState.activePage * tableState.pageSize;

    setTableData(sortBy(filteredArr, ['name']).slice(startIdx, endIdx));
  }, [tableState.activePage, tableState.pageSize, filterTerm, data, filterData]);

  if (error) {
    return <Alert variant="danger">{error.message || 'Something went wrong.'}</Alert>;
  }

  return (
    <DataTable
      data={tableData}
      dataSize={filterData(data, filterTerm).length}
      pageSize={tableState.pageSize}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      activePage={tableState.activePage}
      handleRowClick={handleRowClick}
      columns={[
        {
          text: 'Business Name',
          dataField: 'name',
          sort: true,
          formatter: (cell: any, row: IEntity) => (
            <AvatarDataTableCell
              initials={row.name && row.name.charAt(0).toUpperCase()}
              avatar={row.avatar && row.avatar.url}
              primaryText={row.name}
            />
          ),
        },
        {
          text: 'Phone Number',
          dataField: 'phoneNumber',
          sort: true,
          formatter: (cell: any, row: IEntity) => {
            let str = '';

            try {
              const phoneNumber = parsePhoneNumberWithRegion(row.phoneNumber);
              str = phoneNumber.formatNational();
            } catch (error) {
              // could throw ParseError
            }

            return str;
          },
        },
        { text: 'City', dataField: 'address.city', sort: true },
        {
          text: 'State',
          dataField: 'address.state',
          sort: true,
          formatter: (cell: any, row: IEntity) => (row.address ? STATES[row.address.state] || '' : ''),
        },
        {
          text: 'Actions',
          dataField: '',
          align: 'center',
          formatter: (cell: any, row: IEntity) => <FontAwesomeIcon size="2x" icon={faEllipsisH} />,
          headerClasses: 'text-center',
        },
      ]}
      renderHeader={(paginationProps, searchProps) => (
        <TableHeader>
          <SizePerPage paginationProps={paginationProps} />
          <TableSearch placeholder="Search Businesses" className="mr-4" onChange={setFilterTerm} />
        </TableHeader>
      )}
    />
  );
};

export default EntityProfilesTable;
