import React, { useState, useCallback, useEffect, SetStateAction, Dispatch } from 'react';
import Alert from 'shared/components/Alert';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import { useUpdateCenterProfile } from 'pages/Centers/subroutes/Profile/graphql/mutations';
import ContactsDropdownForm from 'shared/components/ContactDisplayComponents';
import { showToast } from 'shared/components/Toast';
import { omitFalsy, omitTypename } from 'shared/util/object';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  canPerformEdit?: boolean;
  center: ICenter;
  setMutationLoading: Dispatch<SetStateAction<boolean>>;
}
const ContactInformationForm: React.FC<IProps> = ({ center, canPerformEdit = false, setMutationLoading }) => {
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [updateCenterProfile, { loading, error }] = useUpdateCenterProfile();

  const save = useCallback(
    (primaryContactPersonId: string, secondaryContactPersonId?: string) => {
      const updates = {
        ...center,
        primaryContactPersonId,
        secondaryContactPersonId,
      };

      // taken from the previous implementation of th eupdate hook
      const centerInfoWithoutTypes = omitTypename<ICenter>(updates, true);
      const addressWithoutFalsy = omitFalsy(centerInfoWithoutTypes.address);

      const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

      updateCenterProfile({
        variables: {
          input: {
            id: center.id,
            primaryContactPersonId,
            secondaryContactPersonId,
            // graphql schema currently requires these fields on every update
            name: center.name,
            phoneNumber: center.phoneNumber,
            address: addressWithoutFalsy,
            timezone: center.timezone,
          },
        },
      })
        .then(() => {
          showToast(`${fieldLabels.center} contact information updated successfully.`, 'success');
          setFormIsDirty(false);
        })
        .catch(() => {
          showToast(
            `There was an error updating your ${fieldLabels.center.toLowerCase()} contact information. Please try again later.`,
            'error'
          );
        });
    },
    [center, updateCenterProfile]
  );

  const saveContacts = useCallback(
    (primaryContactPersonId: string, secondaryContactPersonId?: string) => {
      save(primaryContactPersonId, secondaryContactPersonId);
    },
    [save]
  );

  useEffect(() => {
    setMutationLoading(loading);
  }, [loading, setMutationLoading]);

  return (
    <>
      {error && <Alert variant="danger">{error.message || 'Something went wrong.'}</Alert>}
      {loading ? (
        <LoadingLines number={2} />
      ) : (
        <ContactsDropdownForm
          businessId={center.entityId}
          item={center}
          centerId={center.id}
          canPerformEdit={canPerformEdit}
          loading={loading}
          error={error}
          updateItem={saveContacts}
          isDirty={formIsDirty}
          toggleFormIsDirty={setFormIsDirty}
        />
      )}
    </>
  );
};

export default ContactInformationForm;
