import React from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { ButtonProps } from 'react-bootstrap/Button';
import Button from 'shared/components/Buttons';

const NO_OP = () => {};

interface IProps extends ModalProps {
  title?: string;
  primaryChoice?: string;
  secondaryChoice?: string;
  primaryButtonProps?: ButtonProps & { loading?: boolean };
  secondaryButtonProps?: ButtonProps & { loading?: boolean };
  hasFooter?: boolean;
  hasSecondaryChoice?: boolean;
  primaryCallback?: () => void;
  secondaryCallback?: () => void;
  primaryButtonVariant?: string;
}

const CenteredModal: React.FC<IProps> = ({
  title,
  primaryChoice = 'Save',
  primaryCallback = NO_OP,
  primaryButtonProps = {},
  secondaryChoice = 'Cancel',
  secondaryCallback = NO_OP,
  secondaryButtonProps = {},
  hasFooter = true,
  hasSecondaryChoice = true,
  primaryButtonVariant = 'primary',
  ...props
}) => {
  return (
    <Modal {...props} centered backdrop="static">
      <Modal.Header closeButton className="border-bottom-0 pb-4">
        <div className="d-flex flex-column">{title && <Modal.Title as="h3">{title}</Modal.Title>}</div>
      </Modal.Header>
      <Modal.Body className="pt-0">{props.children}</Modal.Body>
      {hasFooter && (
        <Modal.Footer className="border-top-0">
          <div className="ml-auto">
            {hasSecondaryChoice && (
              <Button
                id="centered-modal-secondary-btn"
                className="mr-3"
                variant="light"
                {...secondaryButtonProps}
                onClick={secondaryCallback}
              >
                {secondaryChoice}
              </Button>
            )}
            <Button
              id="centered-modal-primary-btn"
              variant={primaryButtonVariant}
              {...primaryButtonProps}
              onClick={primaryCallback}
            >
              {primaryChoice}
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CenteredModal;
