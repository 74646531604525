import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { cloneDeep } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataControlAccordion from 'pages/TimeManagement/subroutes/StaffSchedules/components/DataControls/DataControlAccordion';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import colors from '_colors.module.scss';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';
import ConcessionCardForm from './ConcessionCardForm';
import FamilyTaxBenefitForm from './FamilyTaxBenefitForm';
import QGrantsChildInformationForm from './QGrantsChildInformationForm';
import StartStrongChildInformation from './StartStrongChildInformation';
import {
  buildProgramChildInput,
  validateProgramChildForm,
  buildKindyForAllData,
  buildDelayedExitDataForm,
  buildStartStrongData,
} from './utils';
import { getFullName } from 'shared/util/string';
import Checkbox from 'shared/components/Checkbox';
import './addProgramModal.scss';
import DateInput from 'shared/components/DateInput';
import {
  getLinkablePrograms,
  kindyForAllSubsidySchemeId,
  startStrongSubsidySchemeId,
} from 'pages/Subsidies/KindyFunding/utils';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  account: IAccount;
  programs: IProgram[];
  isLoading: boolean;
  programChildren: IProgramChild[];
  initialProgramChildren: IProgramChild[] | undefined;
  handleSubmit: (values: IProgramChildAddInput) => void;
}

const AddProgramModal: React.FC<IProps> = ({
  isOpen,
  account,
  programs,
  isLoading,
  programChildren,
  initialProgramChildren,
  handleClose,
  handleSubmit,
}) => {
  const { t } = useTranslation(['translation', 'subsidies']);

  const initialConcessionCard = initialProgramChildren?.find(
    (a) => a.concessionCard && a.concessionCard.type
  )?.concessionCard;
  const childrenIds = initialProgramChildren?.flatMap((p) => p.childId);
  const ccCardChildren = account.children?.filter((a) => childrenIds?.includes(a.id));

  const initialFTBCard = initialProgramChildren?.find((a) => a.familyTaxBenefit)?.familyTaxBenefit;
  const initialForm: IProgramChildForm = {
    program: null,
    children: null,
    startDate: null,
    endDate: null,
    lastFundPaidDate: undefined,
    childReceivesAccs: false,
    primaryAccountId: undefined,
    concession: {
      id: initialConcessionCard?.id,
      contact: account.contacts?.find((c) => c.id === initialConcessionCard?.contactId) ?? null,
      type: initialConcessionCard?.type,
      number: initialConcessionCard?.cardNumber,
      children: initialConcessionCard ? ccCardChildren : null,
      effectiveDate: initialConcessionCard?.effectiveDate,
      expiryDate: initialConcessionCard?.expiryDate,
      file: undefined,
      linkToFile: initialConcessionCard?.documentUri,
      fileName: initialConcessionCard?.fileName,
    },
    delayedExits: null,
    familyTaxBenefit: {
      id: initialFTBCard?.id,
      account: account,
      part: initialFTBCard?.part,
      fileName: initialFTBCard?.fileName,
      linkToFile: initialFTBCard?.documentUri,
      file: undefined,
    },
    multiBirth: {
      childIds: [],
    },
    kindyForAllChildren: null,
    startStrongChildren: null,
    startStrongConsent: 'None',
    excludeStartStrongFunding: false,
    programChildFundingSchedules: null,
  };

  const [formData, setFormData] = useState<IProgramChildForm>(initialForm);

  const icon = <FontAwesomeIcon className="mr-2" color={colors.success} icon={faPlus} />;

  useEffect(() => {
    if (!isOpen) resetForm();
  }, [isOpen]);

  const handleFormChange = (value: any, name: string) => {
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleChildrenChange = (value: IAccountChild[], name: string) => {
    setFormData((state) => ({
      ...state,
      concession: { ...state.concession, children: value },
      [name]: value,
    }));
  };

  const handleConcession = (value: any, name: string) => {
    setFormData((state) => ({
      ...state,
      concession: { ...state.concession, [name]: value },
    }));
  };

  const getKindyForAllChildren = (
    mutableState: IProgramChildForm,
    selected: IKindyForAllChildInformationForm
  ): IKindyForAllChildForm[] => {
    const kindyForAllChild = selected.kindyForAllChild as IKindyForAllChildForm | undefined;
    const kindyForAllChildren = (mutableState.kindyForAllChildren ?? []) as IKindyForAllChildForm[];

    if (kindyForAllChild) {
      const index = kindyForAllChildren.findIndex((c) => c.child?.id === kindyForAllChild?.child?.id);

      if (index >= 0) {
        kindyForAllChildren[index] = kindyForAllChild;
      } else {
        kindyForAllChildren.push(kindyForAllChild);
      }
    }
    return kindyForAllChildren;
  };

  const getStartStrongChildren = (
    mutableState: IProgramChildForm,
    selected: IStartStrongChildInformationForm
  ): IStartStrongChildForm[] => {
    const startStrongChild = selected.startStrongChild as IStartStrongChildForm | undefined;
    const startStrongChildren = (mutableState.startStrongChildren ?? []) as IStartStrongChildForm[];

    if (startStrongChild) {
      const index = startStrongChildren.findIndex((c) => c.child?.id === startStrongChild?.childId);

      if (index >= 0) {
        startStrongChildren[index] = startStrongChild;
      } else {
        startStrongChildren.push(startStrongChild);
      }
    }
    return startStrongChildren;
  };

  const getMultiBirthChildIds = (
    mutableState: IProgramChildForm,
    selected: IKindyForAllChildInformationForm
  ): string[] => {
    const kindyForAllChild = selected.kindyForAllChild as IKindyForAllChildForm | undefined;
    let multiBirthChildIds = (mutableState.multiBirth.childIds ?? []) as string[];

    if (kindyForAllChild) {
      const multiBirthIndex = multiBirthChildIds.findIndex((c) => c === kindyForAllChild.child?.id);
      if (multiBirthIndex >= 0) {
        if (!kindyForAllChild.multiBirth) {
          multiBirthChildIds = multiBirthChildIds.filter((c) => c !== kindyForAllChild.child?.id);
        }
      } else {
        if (kindyForAllChild.multiBirth) {
          if (kindyForAllChild.child?.id) multiBirthChildIds.push(kindyForAllChild.child?.id);
        }
      }
    }

    return multiBirthChildIds;
  };

  const getDelayedExits = (
    mutableState: IProgramChildForm,
    selected: IKindyForAllChildInformationForm
  ): IDelayedExitForm[] => {
    const delayedExit = selected.delayedExit as IDelayedExitForm;
    const delayedExits = (mutableState.delayedExits ?? []) as IDelayedExitForm[];

    if (delayedExit) {
      const index = delayedExits.findIndex((c) => c.child?.id === delayedExit?.child?.id);

      if (index >= 0) {
        delayedExits[index] = delayedExit;
      } else {
        delayedExits.push(delayedExit);
      }
    }
    return delayedExits;
  };

  const handleStartStrongChildInformation = (selected: IStartStrongChildInformationForm) => {
    const mutableState = cloneDeep(formData);
    const startStrongChildren = getStartStrongChildren(mutableState, selected);

    setFormData({
      ...formData,
      startStrongConsent: selected.startStrongChild?.startStrongConsent ?? 'None',
      excludeStartStrongFunding: selected.startStrongChild?.excludeStartStrongFunding ?? false,
      startStrongChildren: startStrongChildren,
    });
  };

  const handleKindyForAllChildInformation = (selected: IKindyForAllChildInformationForm) => {
    const mutableState = cloneDeep(formData);

    const kindyForAllChildren = getKindyForAllChildren(mutableState, selected);
    const multiBirthChildIds = getMultiBirthChildIds(mutableState, selected);
    const delayedExits = getDelayedExits(mutableState, selected);

    setFormData({
      ...formData,
      kindyForAllChildren: kindyForAllChildren,
      delayedExits: delayedExits,
      multiBirth: { childIds: multiBirthChildIds },
    });
  };

  const handleFamilyTaxBenefit = (value: any, name: string) => {
    if (name === 'part') {
      if (value.value != 'None') {
        setFormData((state) => ({
          ...state,
          familyTaxBenefit: { ...state.familyTaxBenefit, [name]: value.value, account: account },
        }));
      } else {
        setFormData((state) => ({
          ...state,
          familyTaxBenefit: {
            account: null,
            part: undefined,
            file: undefined,
          },
        }));
      }
    } else {
      setFormData((state) => ({
        ...state,
        familyTaxBenefit: { ...state.familyTaxBenefit, [name]: value, account: account },
      }));
    }
  };

  const resetForm = () => {
    setFormData(initialForm);
  };

  const onSave = useCallback(() => {
    const isValid = validateProgramChildForm(formData);
    if (isValid) handleSubmit(buildProgramChildInput(formData, account));
  }, [formData, account, handleSubmit]);

  const activeChildren = useMemo(() => {
    return account?.children?.filter((child) => child.archivedAt === null) ?? [];
  }, [account.children]);

  const getChildrenOptions = useCallback(() => {
    const selectedIds = formData.children?.map((child) => child.id) ?? [];
    if (selectedIds.length === 0) return activeChildren;
    const filteredChildren = activeChildren.filter((child) => selectedIds.includes(child.id));
    return filteredChildren;
  }, [activeChildren, formData.children]);

  return (
    <Modal centered backdrop="static" show={isOpen} onHide={handleClose} size="lg">
      <Modal.Header closeButton className="px-4 py-2">
        <div className="d-flex flex-column">
          <Modal.Title as="h5">
            {' '}
            {t('subsidies:program-child.create-modal.add-program-to', {
              accountName: account.name,
            })}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-4 px-5">
        <FormWrapper2
          formIsDirty
          toggleDirty={() => {}}
          onCancel={handleClose}
          onSave={onSave}
          loading={isLoading}
          saveDisabled={!validateProgramChildForm(formData)}
        >
          <h5 className="mb-4">{t('subsidies:program-child.create-modal.program-details')}</h5>
          <Row>
            <Col lg={6}>
              <Select
                required
                options={getLinkablePrograms(programs)}
                value={formData.program?.id}
                name="program"
                label={t('subsidies:program-child.create-modal.select-program')}
                getOptionLabel={(program: IProgram) => program.name}
                getOptionValue={(program: IProgram) => program.id}
                onChange={handleFormChange}
              />
            </Col>
            <Col lg={6}>
              <Select
                required
                options={activeChildren}
                getOptionLabel={(child: IAccountChild) => getFullName(child)}
                getOptionValue={(child: IAccountChild) => child.id}
                label={t('subsidies:program-child.create-modal.children')}
                name="children"
                value={formData.children}
                disabled={formData.program == null}
                onChange={handleChildrenChange}
                isMulti
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="pt-2">
              <Select
                required
                options={[account]}
                getOptionLabel={(account: IAccount) => account.name}
                getOptionValue={(account: IAccount) => account.id}
                label={t('subsidies:program-child.create-modal.account-to-be-paid')}
                name="primaryAccountId"
                value={formData.primaryAccountId}
                disabled={formData.program == null}
                onChange={(val: IAccount) => {
                  handleFormChange(val.id, 'primaryAccountId');
                }}
              />
            </Col>
            <Col lg={6} className="pt-2 mb-4">
              <DateInput
                label={t('subsidies:program-child.create-modal.est-last-paid-date')}
                helpTooltipText={t('subsidies:program-child.create-modal.est-last-paid-info')}
                className="max-width-unset"
                date={formData.lastFundPaidDate}
                disabled={formData.program == null}
                onDateSelect={(value) => {
                  handleFormChange(value, 'lastFundPaidDate');
                }}
              />
            </Col>
          </Row>

          <Row className="pt-2 mb-4">
            <Col>
              <DateInput
                label={t('subsidies:program.start-date')}
                className="max-width-unset"
                date={formData.startDate}
                required={formData.program != null}
                disabled={formData.program == null}
                onDateSelect={(value) => {
                  handleFormChange(value, 'startDate');
                }}
              />
            </Col>
            <Col>
              <DateInput
                label={t('subsidies:program.end-date')}
                className="max-width-unset"
                date={formData.endDate}
                disabled={formData.program == null}
                onDateSelect={(value) => {
                  handleFormChange(value, 'endDate');
                }}
              />
            </Col>
          </Row>

          {formData.program?.subsidyScheme?.subsidySchemeId === kindyForAllSubsidySchemeId && (
            <Row className="mb-2">
              <Col>
                <FamilyTaxBenefitForm
                  formData={formData.familyTaxBenefit}
                  handleChange={handleFamilyTaxBenefit}
                  showDocumentSelect={!!formData.familyTaxBenefit?.part && formData.familyTaxBenefit?.part != 'None'}
                />
              </Col>
            </Row>
          )}

          <Row className="mb-4">
            <Col>
              <DataControlAccordion
                defaultOpen={false}
                title={
                  <div>
                    {icon}
                    {t('subsidies:program-child.create-modal.concession-card')}
                  </div>
                }
                className="px-4 py-2 program-accordion"
              >
                <ConcessionCardForm
                  contacts={account.contacts}
                  accountChildren={getChildrenOptions()}
                  handleChange={handleConcession}
                  formData={formData.concession}
                  isPrePopulated={!!initialConcessionCard}
                />
              </DataControlAccordion>
            </Col>
          </Row>

          {formData.children && formData.children?.length != 0 && (
            <>
              <h5 className="mb-4 mt-4">{t('subsidies:program-child.create-modal.information-per-child')}</h5>
              {formData.children?.map((childItem: IAccountChild) => (
                <Row className="mb-4">
                  <Col>
                    <DataControlAccordion
                      defaultOpen={false}
                      key={`child-${childItem.id}`}
                      title={
                        <div>
                          {icon}
                          {childItem.fullName}
                        </div>
                      }
                      className="px-4 py-2 program-accordion"
                    >
                      {formData.program?.subsidyScheme?.subsidySchemeId === startStrongSubsidySchemeId ? (
                        <StartStrongChildInformation
                          accountChild={childItem}
                          accountId={account.id}
                          programStartDate={formData.program.startDate}
                          handleChange={handleStartStrongChildInformation}
                          initialFormData={{
                            startStrongChild: programChildren
                              ? (buildStartStrongData(
                                  programChildren?.find((c) => c.childId === childItem.id),
                                  account.id,
                                  childItem
                                ) as IStartStrongChildForm)
                              : formData.startStrongChildren?.find((c) => c.childId == childItem.id),
                          }}
                        />
                      ) : (
                        <QGrantsChildInformationForm
                          subsidySchemeId={formData.program?.subsidyScheme?.subsidySchemeId}
                          accountChild={childItem}
                          handleChange={handleKindyForAllChildInformation}
                          initialFormData={{
                            kindyForAllChild: programChildren
                              ? (buildKindyForAllData(
                                  programChildren?.find((c) => c.childId === childItem.id)?.kindyForAllChild,
                                  childItem
                                ) as IKindyForAllChildForm)
                              : formData.kindyForAllChildren?.find((c) => c.child?.id == childItem.id),
                            delayedExit: formData.delayedExits
                              ? formData.delayedExits?.find((c) => c.child?.id == childItem.id)
                              : (buildDelayedExitDataForm(
                                  programChildren?.find((c) => c.childId === childItem.id)?.delayedExit,
                                  childItem
                                ) as IDelayedExitForm),
                          }}
                        />
                      )}
                    </DataControlAccordion>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </FormWrapper2>
      </Modal.Body>
    </Modal>
  );
};

export default AddProgramModal;
