import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { GetStageAggregationInput } from 'shared/types/application';

export const GET_APPLICATION_CATEGORIES = gql`
  query ($businessId: String!, $input: GetStageAggregationInput!) {
    getStageAggregation(businessId: $businessId, input: $input) {
      stage
      count
      userFlag
    }
  }
`;

export type GetApplicationStageAggregationItem = {
  getStageAggregation: {
    stage: string;
    count: number;
    userFlag: null | string;
  }[];
};

interface IGetApplicationStageAggregationInput {
  businessId: string;
  input: GetStageAggregationInput;
}

export const useGetApplicationCategories = (
  options?: QueryHookOptions<GetApplicationStageAggregationItem, IGetApplicationStageAggregationInput>
) =>
  useQuery(GET_APPLICATION_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
