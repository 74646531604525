import React, { useState, useCallback, useEffect } from 'react';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Row, Col } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import errorMessage from 'shared/constants/errorMessages';
import { reasonForLeavingEnum } from 'shared/constants/enums/reasonForLeavingEnum';
import { sortBy } from 'lodash';
import moment from 'moment';
import { useUpdateEmploymentHistory } from '../graphql/mutations';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import { showToast } from 'shared/components/Toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/reducers';
const reasonForLeavingOptions = sortBy(Object.keys(reasonForLeavingEnum));

interface IProps {
  staff: IStaff;
  readOnly: boolean;
}

const EmploymentEndForm: React.FC<IProps> = ({ staff, readOnly }) => {
  const [endDate, updateEndDate] = useState(staff.employmentEndDate);
  const [reasonForLeaving, updateReasonForLeaving] = useState(staff.reasonForLeaving);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;
  const resetFormData = useCallback(() => {
    updateEndDate(staff.employmentEndDate);
    updateReasonForLeaving(staff.reasonForLeaving);
  }, [staff]);
  const [updateEmploymentHistoryMutation, { loading }] = useUpdateEmploymentHistory();

  const onEndDateSelect = (val: string | null) => {
    updateEndDate(val);
    setFormIsDirty(true);
    if (!val) {
      updateReasonForLeaving(null);
    }
  };

  const saveEmploymentHistory = useCallback(async () => {
    await updateEmploymentHistoryMutation({
      variables: { input: { id: staff.id, employmentEndDate: endDate, reasonForLeaving } },
    }).then(
      () => showToast('Employment history updated.', 'success'),
      () => showToast(errorMessage.generic, 'error')
    );
  }, [endDate, reasonForLeaving, staff.id, updateEmploymentHistoryMutation]);

  // if user inputs an end date, a reason for leaving is required, and visa versa
  const formDisabled = (Boolean(endDate) && !reasonForLeaving) || (!endDate && Boolean(reasonForLeaving)) || readOnly;

  // reset values if staff is deactivated from profile
  useEffect(() => {
    resetFormData();
  }, [staff.employmentEndDate, staff.reasonForLeaving, resetFormData]);

  if (loading) {
    return <LoadingLines number={2} />;
  }
  return (
    <div>
      <FormWrapper2
        onCancel={resetFormData}
        onSave={saveEmploymentHistory}
        formIsDirty={formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={formDisabled}
      >
        <Row align={'start'}>
          <Col md={12} lg={5}>
            <DateInput
              label="End Date"
              date={endDate}
              onDateSelect={onEndDateSelect}
              customTime={{ hour: 23, minute: 59, second: 59 }}
              isOutsideRange={(day) => staff.employmentStartDate && day.isBefore(moment(staff.employmentStartDate))}
              disabled={!staff.employmentStartDate || readOnly || AdpEnabled}
            />
          </Col>
          <Col md={12} lg={7}>
            <Select
              label="Reason for Leaving"
              name="reasonForLeaving"
              required={Boolean(endDate)}
              options={reasonForLeavingOptions}
              getOptionLabel={(option) => reasonForLeavingEnum[option.value]}
              value={reasonForLeaving}
              onChange={(val) => {
                updateReasonForLeaving(val);
                setFormIsDirty(true);
              }}
              disabled={!staff.employmentStartDate || !endDate || readOnly || AdpEnabled}
              isClearable
            />
          </Col>
        </Row>
        {formDisabled && formIsDirty && <small>{errorMessage.formRequirements}</small>}
      </FormWrapper2>
    </div>
  );
};

export default EmploymentEndForm;
