import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

const ConfirmPausePaymentModal: React.FC<IProps> = ({ isOpen, onClose, onConfirm, ...props }) => {
  const dismissModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <ConfirmationModal
      title="Pause Payments?"
      show={isOpen}
      onHide={dismissModal}
      hideOnCallback={true}
      primaryChoice={'Pause'}
      primaryCallback={onConfirm}
      primaryButtonProps={{
        variant: 'danger',
      }}
      secondaryCallback={dismissModal}
    >
      This account’s payments will be temporarily paused. While payments will no longer be collected, a balance will
      continue to accumulate for this account.
    </ConfirmationModal>
  );
};

export default ConfirmPausePaymentModal;
