import React, { useMemo } from 'react';
import Currency from 'shared/components/Currency';
import useFormatDate from 'shared/hooks/useFormatDate';
import { appendClassName } from 'shared/util/classNameHelpers';
import './PeriodBasedAccountBalances.scss';
import useAccountBalances from '../../hooks/useAccountBalances';
import { LoadingLines } from 'US/Billing/UIComponents/LoadingLines';

export function PeriodBasedAccountBalanceTile({
  accountId,
  className = '',
  showLoading = false,
}: {
  accountId: string;
  className?: string;
  showLoading?: boolean;
}) {
  const { data: balances, loading: loadingBalances } = useAccountBalances(accountId);
  const loading = useMemo(() => showLoading || loadingBalances, [loadingBalances, showLoading]);
  const formatDate = useFormatDate();

  return (
    <div className={appendClassName(className, 'period-based-account-balances')}>
      <GridCard loading={loading}>
        <GridCardHeader>
          <h5>Amount Due</h5>
          <h5>
            <Currency display="DirectedNumbers" amount={balances.amountDue} />
          </h5>
        </GridCardHeader>
        <GridCardFooter>
          <GridCardFooterPane>
            <div>
              Includes{' '}
              <b>
                [<Currency display="DirectedNumbers" amount={balances.pendingPayments} />]
              </b>{' '}
              in payments since last bill.
            </div>
          </GridCardFooterPane>
        </GridCardFooter>
      </GridCard>
      <GridCard loading={loading}>
        <GridCardHeader>
          <h5>Unbilled Balance</h5>
          <h5>
            <Currency display="DirectedNumbers" amount={balances.unbilledBalance} />
          </h5>
        </GridCardHeader>
        <GridCardFooter>
          <GridCardFooterPane>Billed: {formatDate(balances.nextInvoiceDate ?? '')}</GridCardFooterPane>
          <GridCardFooterPane>Due: {formatDate(balances.nextBillingDate ?? '')}</GridCardFooterPane>
        </GridCardFooter>
      </GridCard>
      <GridCard loading={loading}>
        <GridCardHeader>
          <h5>Account Balance</h5>
          <h5>
            <Currency display="DirectedNumbers" amount={balances.balance} />
          </h5>
        </GridCardHeader>
        <GridCardFooter>
          <GridCardFooterPane>
            <div>Sum of all transactions on the account</div>
          </GridCardFooterPane>
        </GridCardFooter>
      </GridCard>
    </div>
  );
}

function GridCard(props: { children: React.ReactNode; loading?: boolean }) {
  return <div className="grid-card">{props.loading ? <LoadingLines /> : props.children}</div>;
}

function GridCardHeader(props: { children: React.ReactNode }) {
  return <div className="grid-card__header">{props.children}</div>;
}

function GridCardFooter(props: { children: React.ReactNode }) {
  return <div className="grid-card__footer">{props.children}</div>;
}

function GridCardFooterPane(props: { children: React.ReactNode }) {
  return <div className="grid-card__footer-pane">{props.children}</div>;
}
