import { s3SignedUrlFields } from 'gql/s3SignedUrl/fields';
import { addressFields } from 'gql/address/fields';

export const personFields: string = `
  id
  userType
  firstname
  lastname
  email
  avatar {
    ${s3SignedUrlFields}
  }
  address {
    ${addressFields}
  }
  dob
  phoneNumber
  phoneNumberAlt
  nickname
  cognitoConfiguredAt
  onboardingStatus
`;
