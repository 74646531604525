import {
  CommsCenterSetting,
  useGetCommsAllCenterSettingsQuery,
  useResetCenterSettingsMutation,
  useSetCommsBusinessSettingsMutation,
  useSetCommsCenterSettingsMutation,
} from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import chattingImage from 'shared/images/chattingMsg.svg';
import { IProps } from 'shared/components/Checkbox/Checkbox';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import PageWrapper from 'shared/components/PageWrapper';
import { RootState } from 'store/reducers';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import { capitalize, sortBy } from 'lodash';
import { Box, Typography, useMediaQuery } from '@mui/material';
import theme from 'muiTheme';
import Setting from 'shared/components/Settings';
import { showToast } from 'shared/components/Toast';
import Switch from 'shared/components/Switch';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { getCommsBusinessSettings, getUsesComms } from 'pages/Engagement/duck/actions';
import { STATES, US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import { useGetCenterStatesInUse } from 'shared/hooks/useGetCenterStatesInUse';

const { TERM } = SEARCH_EXPRESSIONS;
const { CONTAINS, EQUALS } = PREDICATES;

const MessagingSettings: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['comms', 'translation']);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const businessMessaging = useSelector((state: RootState) => state.comms.usesComms);
  const accountMessaging = useSelector((state: RootState) => state.comms.all?.usesAccountChannels) ?? false;
  const centerMessaging = useSelector((state: RootState) => state.comms.all?.usesBroadcastChannels) ?? false;
  const bulletinMessaging = useSelector((state: RootState) => state.comms.all?.usesBulletinBoards) ?? false;
  const studentMessagingEnabled = useSelector((state: RootState) => state.comms.all?.usesStudentChannels) ?? false;
  const totalCenters = useSelector((state: RootState) => state.comms.all?.centerCount) ?? 0;
  const allowedCenters = useSelector((state: RootState) => state.centers.all.map((c) => c.id)) ?? '';
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const allTags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];
  const [centerData, setCenterData] = useState<CommsCenterSetting[]>([]);
  const [selectedStates, setSelectedStates] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  const hasReadCenterTagsPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Tags,
    level: RoleLevelType.Read,
  });

  const statesInUse: string[] = useGetCenterStatesInUse()?.data?.getCenterStatesUsedAcrossEntity || [];
  const states =
    !!statesInUse && statesInUse.length > 0
      ? US_STATE_SELECT_OPTIONS.filter((state) => statesInUse.includes(state.value))
      : US_STATE_SELECT_OPTIONS;

  const stateOptions: ITableFilterOption[] = sortBy(states, ['label']).map((state) => ({
    label: state.label,
    value: state.value,
    searchExpression: { [TERM]: { field: 'address.state.keyword', predicate: EQUALS, value: state.value } },
  }));

  const tagOptions: ITableFilterOption[] = sortBy(allTags, ['name']).map((tag) => ({
    label: tag.name,
    value: tag.id,
    searchExpression: { [TERM]: { field: 'tags.keyword', predicate: CONTAINS, value: tag.id } },
  }));

  const hasCenterEditPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const [
    setCommsBusinessSettings,
    {
      data: setCommsBusinessSettingsData,
      loading: setCommsBusinessSettingsDataLoading,
      error: setCommsBusinessSettingsDataError,
    },
  ] = useSetCommsBusinessSettingsMutation({
    onError: (err) => showToast('Unable set business settings', 'error'),
    onCompleted: (response) => {
      dispatch(getCommsBusinessSettings(response.setCommsBusinessSettings));
      dispatch(
        getUsesComms(response.setCommsBusinessSettings ? response.setCommsBusinessSettings?.usesComms ?? false : false)
      );
      refetchGetCommsCenterSettings({
        businessId: businessId ?? '',
        searchTerm: searchTerm,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
      });
    },
  });

  const {
    data: commsCenterData,
    loading,
    refetch: refetchGetCommsCenterSettings,
  } = useGetCommsAllCenterSettingsQuery({
    skip:
      businessId === null ||
      businessId === undefined ||
      businessId === '' ||
      allowedCenters === null ||
      allowedCenters.length === 0,
    variables: {
      businessId: businessId ?? '',
      searchTerm: searchTerm,
      centerId: allowedCenters,
      pageNumber: tableState.activePage,
      pageSize: tableState.pageSize,
      state: selectedStates,
      tag: selectedTag,
    },
  });

  const [
    setCommsCenterSettingsMutation,
    { data: setCenterSettingsData, loading: setCenterSettingsLoading, error: setCenterSettingsError },
  ] = useSetCommsCenterSettingsMutation({
    onError: (err) => showToast('Unable set centre settings', 'error'),
    onCompleted: (response) => {
      setCenterData((prev) =>
        prev.map((c) => {
          if (c.centerId === response.setCommsCenterSettings?.centerId) {
            return {
              ...response.setCommsCenterSettings,
              id: c.centerId,
            };
          } else return c;
        })
      );
    },
  });

  const [
    resetCenterSettingsMutation,
    { data: resetCenterSettingsData, loading: resetCenterSettingsLoading, error: resetCenterSettingsError },
  ] = useResetCenterSettingsMutation({
    onError: (err) => showToast('Unable set centre settings', 'error'),
    onCompleted: (response) => {
      setCenterData((prev) =>
        prev.map((c) => {
          if (c.centerId === response.resetCenterSettings?.centerId) {
            return {
              ...response.resetCenterSettings,
              id: c.centerId,
            };
          } else return c;
        })
      );
    },
  });

  useEffect(() => {
    setCenterData(
      commsCenterData
        ? commsCenterData?.getCommsAllCenterSettings?.data.map((c) => {
            return {
              ...c,
              id: c.centerId,
            };
          }) ?? []
        : []
    );
  }, [commsCenterData, loading]);

  useEffect(() => {
    refetchGetCommsCenterSettings();
  }, []);

  const handleUpdateSetting = (
    center: CommsCenterSetting,
    defaultSetting: boolean,
    accountSetting: boolean,
    announcementSetting: boolean,
    bulletinSetting: boolean,
    studentSetting?: boolean
  ) => {
    if (defaultSetting) {
      resetCenterSettingsMutation({
        variables: {
          businessId: businessId ?? '',
          centerId: center.centerId ?? '',
        },
      });
    } else {
      const usesDefault = center.fromDefault ?? false;
      setCommsCenterSettingsMutation({
        variables: {
          businessId: businessId ?? '',
          centerId: center.centerId ?? '',
          input: {
            businessId: businessId ?? '',
            centerId: center.centerId ?? '',
            usesAccountChannels: accountSetting,
            useBroadcastChannels: announcementSetting,
            usesBulletinBoards: bulletinSetting,
            usesStudentChannels: studentSetting,
          },
        },
      });
    }
  };

  return (
    <>
      {businessMessaging && hasCenterEditPermission && (
        <PageWrapper pageTitle={'Messaging Settings'} applyPadding={true}>
          <Card className="px-8 pt-4 pb-2">
            <div className="d-flex flex-row">
              <div className="col-12 col-lg-7 pt-6 pb-8">
                <h5 className="mt-4 mb-6">Did you know?</h5>
                <p>
                  You can enable messaging features globally and optionally override global settings for individual
                  centers below.
                </p>
                <div className="red-info-block p-4 d-flex flex-row">
                  <div className="mr-4">
                    <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                  </div>
                  <div className="mr-6 d-flex align-items-center">
                    <span style={{ fontWeight: '600' }}>
                      Messaging features are only visible to roles with{' '}
                      <a className="alert-link" href="/settings/roles">
                        {' '}
                        messaging/view permissions.
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-auto col-lg-5 d-flex justify-content-center align-items-center">
                <img
                  src={chattingImage}
                  alt="chatting"
                  className="tag-example d-none d-lg-block ml-auto w-100"
                  style={{ maxHeight: '350px' }}
                />
              </div>
            </div>
          </Card>
          {!(allowedCenters.length < totalCenters) && (
            <>
              <Setting
                sectionHeader={`Global ${capitalize(t('translation:spelling.center'))} Settings`}
                title={'Account Messaging'}
                subtitle={`Turn account messaging on/off for all ${t('translation:spelling.center_plural')}`}
                toggleValue={accountMessaging}
                handleToggle={(value: boolean) => {
                  setCommsBusinessSettings({
                    variables: {
                      businessId: businessId ?? '',
                      input: {
                        businessId: businessId ?? '',
                        useComms: true,
                        usesAccountChannels: value,
                        usesBroadcastChannels: centerMessaging,
                        usesBulletinBoards: bulletinMessaging,
                        usesStudentChannels: studentMessagingEnabled,
                      },
                    },
                  });
                }}
              />
              <Setting
                title={'Announcements'}
                subtitle={`Turn announcements on/off for all ${t('translation:spelling.center_plural')}`}
                toggleValue={centerMessaging}
                handleToggle={(value: boolean) => {
                  setCommsBusinessSettings({
                    variables: {
                      businessId: businessId ?? '',
                      input: {
                        businessId: businessId ?? '',
                        useComms: true,
                        usesAccountChannels: accountMessaging,
                        usesBroadcastChannels: value,
                        usesBulletinBoards: bulletinMessaging,
                        usesStudentChannels: studentMessagingEnabled,
                      },
                    },
                  });
                }}
              />
              <Setting
                title={'Center Bulletin Boards'}
                subtitle={`Turn internal employee bulletin board messaging on/off for all ${t(
                  'translation:spelling.center_plural'
                )}`}
                toggleValue={bulletinMessaging}
                handleToggle={(value: boolean) => {
                  setCommsBusinessSettings({
                    variables: {
                      businessId: businessId ?? '',
                      input: {
                        businessId: businessId ?? '',
                        useComms: true,
                        usesAccountChannels: accountMessaging,
                        usesBroadcastChannels: centerMessaging,
                        usesBulletinBoards: value,
                        usesStudentChannels: studentMessagingEnabled,
                      },
                    },
                  });
                }}
              />
              <Setting
                title={'Student Messages'}
                subtitle={`Turn Messaging on between Parent and the classroom`}
                toggleValue={studentMessagingEnabled}
                handleToggle={(value: boolean) => {
                  setCommsBusinessSettings({
                    variables: {
                      businessId: businessId ?? '',
                      input: {
                        businessId: businessId ?? '',
                        useComms: true,
                        usesAccountChannels: accountMessaging,
                        usesBroadcastChannels: centerMessaging,
                        usesBulletinBoards: bulletinMessaging,
                        usesStudentChannels: value,
                      },
                    },
                  });
                }}
              />
            </>
          )}
          <Box display="flex" justifyContent="space-between" padding={2}>
            <Typography variant="h2">{capitalize(t('translation:spelling.center_plural'))}</Typography>
          </Box>
          <DataTable
            data={centerData}
            dataSize={commsCenterData?.getCommsAllCenterSettings?.totalRecords ?? 0}
            pageSize={tableState.pageSize}
            showLoadingOverlay={loading || setCommsBusinessSettingsDataLoading || allowedCenters.length <= 0}
            onPageChange={tableFunctions.changePage}
            activePage={tableState.activePage}
            onSizePerPageChange={(sizePerPage: number) => {
              if (tableState.activePage !== 1) tableFunctions.changePage(1, sizePerPage);
              tableFunctions.changeSizePerPage(sizePerPage);
            }}
            showSelect={false}
            columns={[
              {
                text: `${capitalize(t('translation:spelling.center'))} Name`,
                dataField: 'centerName',
                sort: true,
              },
              {
                text: 'State',
                dataField: 'state',
                sort: true,
                formatter: (cell: any, row: CommsCenterSetting) => {
                  return <>{STATES[row.state ?? '']}</>;
                },
              },
              {
                text: 'Uses Global Settings',
                dataField: 'fromDefault',
                formatter: (cell: any, row: CommsCenterSetting) => {
                  const defaultSetting = row.fromDefault ?? false;
                  return (
                    <Switch
                      value={defaultSetting}
                      onChange={(checked: boolean) => {
                        handleUpdateSetting(
                          row,
                          checked,
                          accountMessaging,
                          centerMessaging,
                          bulletinMessaging,
                          studentMessagingEnabled
                        );
                      }}
                    />
                  );
                },
              },
              {
                text: 'Account Messaging',
                dataField: 'usesAccountChannels',
                formatter: (cell: any, row: CommsCenterSetting) => {
                  const accountSettings = row.usesAccountChannels ?? false;
                  const announcementSettings = row.usesBroadcastChannels ?? false;
                  const bulletinSettings = row.usesBulletinBoards ?? false;
                  return (
                    <Switch
                      value={accountSettings}
                      onChange={(checked: boolean) => {
                        handleUpdateSetting(
                          row,
                          false,
                          checked,
                          announcementSettings,
                          bulletinSettings,
                          studentMessagingEnabled
                        );
                      }}
                    />
                  );
                },
              },
              {
                text: 'Announcement',
                dataField: 'usesBroadcastChannels',
                formatter: (cell: any, row: CommsCenterSetting) => {
                  const accountSettings = row.usesAccountChannels ?? false;
                  const announcementSettings = row.usesBroadcastChannels ?? false;
                  const bulletinSettings = row.usesBulletinBoards ?? false;
                  return (
                    <Switch
                      value={announcementSettings}
                      onChange={(checked: boolean) => {
                        handleUpdateSetting(
                          row,
                          false,
                          accountSettings,
                          checked,
                          bulletinSettings,
                          studentMessagingEnabled
                        );
                      }}
                    />
                  );
                },
              },
              {
                text: 'Center Bulletin Boards',
                dataField: 'usesBulletinBoards',
                formatter: (cell: any, row: CommsCenterSetting) => {
                  const accountSettings = row.usesAccountChannels ?? false;
                  const announcementSettings = row.usesBroadcastChannels ?? false;
                  const bulletinSettings = row.usesBulletinBoards ?? false;
                  return (
                    <Switch
                      value={bulletinSettings}
                      onChange={(checked: boolean) => {
                        handleUpdateSetting(
                          row,
                          false,
                          accountSettings,
                          announcementSettings,
                          checked,
                          studentMessagingEnabled
                        );
                      }}
                    />
                  );
                },
              },
              {
                text: 'Student Messaging',
                dataField: 'usesStudentChannels',
                formatter: (cell: any, row: CommsCenterSetting) => {
                  const accountSettings = row.usesAccountChannels ?? false;
                  const announcementSettings = row.usesBroadcastChannels ?? false;
                  const bulletinSettings = row.usesBulletinBoards ?? false;
                  const studentSetting = row.usesStudentChannels ?? false;
                  return (
                    <Switch
                      value={studentSetting}
                      onChange={(checked: boolean) => {
                        handleUpdateSetting(
                          row,
                          false,
                          accountSettings,
                          announcementSettings,
                          bulletinSettings,
                          checked
                        );
                      }}
                    />
                  );
                },
              },
            ]}
            renderHeader={(paginationProps: any) => (
              <TableHeader className="d-flex flex-row align-items-center flex-wrap flex-grow-1">
                <div className="d-flex flex-wrap mr-auto">
                  <SizePerPage paginationProps={paginationProps} />
                  <TableSearch
                    placeholder="Search"
                    className={isMobile ? 'my-2 mb-1' : ''}
                    onChange={(value) => {
                      setSearchTerm(value);
                    }}
                  />
                </div>
                <div className={isMobile ? 'my-1 pl-0 ml-2 flex-wrap' : 'my-0 mr-2 p-0'}>
                  <DropdownFilter
                    title="State"
                    selectedFilters={[selectedStates]}
                    options={stateOptions}
                    onFilterSelect={(val) => {
                      if (val.length <= 0) setSelectedStates('');
                      else if (val.length === 1) setSelectedStates(val[0].value);
                      else setSelectedStates(val[0].value === selectedStates ? val[1].value : val[0].value);
                    }}
                  />
                </div>
                {hasReadCenterTagsPermission && (
                  <div className={isMobile ? 'my-1 pl-0 ml-2 flex-wrap' : 'my-0 mr-2 p-0'}>
                    <DropdownFilter
                      title="Tags"
                      selectedFilters={[selectedTag]}
                      options={tagOptions}
                      onFilterSelect={(val) => {
                        if (val.length <= 0) setSelectedTag('');
                        else if (val.length === 1) setSelectedTag(val[0].value);
                        else setSelectedTag(val[0].value === selectedTag ? val[1].value : val[0].value);
                      }}
                    />
                  </div>
                )}
              </TableHeader>
            )}
          />
        </PageWrapper>
      )}
    </>
  );
};

export default MessagingSettings;
