import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shared/components/Layout';
import errorMessage from 'shared/constants/errorMessages';
import { showToast } from 'shared/components/Toast';
import TextInput, { EmailInput } from 'shared/components/TextInput';
import { capitalize, get, set, cloneDeep, remove, isEqual } from 'lodash';
import PhoneInput from 'shared/components/PhoneInput';
import { isValidPhoneNumber } from 'shared/util/string';
import { isEmailSyntaxValid, isEmailValid } from 'shared/util/email';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { useLeadsContext } from '../../LeadsContext';
import moment from 'moment';
import DateInput from 'shared/components/DateInput';
import {
  EnrolmentSource,
  useEditApplicationMutation,
  ContractCycleType,
  DayOfWeek as DayOfWeekGql,
  WeekType as WeekTypeGql,
} from 'generated/graphql';
import Select from 'shared/components/Select';
import DayInput from 'shared/components/DayInput';
import DayBiweeklyInput from 'shared/components/DayBiweeklyInput';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IFormData {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  isInitialEnquiry: boolean;
  children: {
    applicationChildId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: any;
    startDate: any;
    isNew: boolean;
    enquiries: {
      id: string;
      careType: string;
      cycleType: string;
      days: {
        weekType: string;
        dayOfWeek: string;
      }[];
    }[];
  }[];
}

type IEnquiry = IFormData['children'][number]['enquiries'][number];

const EditApplicationModal = () => {
  const { t } = useTranslation();
  const {
    editApplicationModalState,
    businessId,
    selectedApplication: application,
    setEditApplicationModalState,
    handleRefetchApplications: refetch,
  } = useLeadsContext();

  const getInitialState = useCallback((): IFormData => {
    return {
      email: application?.email ?? '',
      phoneNumber: application?.phoneNumber ?? '',
      firstName: application?.firstName ?? '',
      lastName: application?.lastName ?? '',
      isInitialEnquiry:
        application?.enrollmentSource === EnrolmentSource.Inquiry || application?.enrollmentSource === null,
      children:
        application?.children
          .filter((c) => c.offers.length <= 0)
          .map((c) => ({
            applicationChildId: c.id,
            firstName: c.firstName,
            lastName: c.lastName,
            dateOfBirth: c.dateOfBirth,
            startDate: c.enquiries?.[0].startDate ?? '',
            isNew: c.isNew ?? true,
            enquiries: c.enquiries.map((e) => ({
              id: e.id,
              careType: e.careType,
              cycleType: e.cycleType,
              days: e.days.map((d) => ({
                weekType: d.weekType,
                dayOfWeek: d.dayOfWeek,
              })),
            })),
          })) ?? [],
    };
  }, [application]);

  const handleClose = () => {
    setEditApplicationModalState({
      isOpen: false,
      editLevel: 'basic',
    });

    setFormData(getInitialState());
  };

  const [formData, setFormData] = useState<IFormData>(getInitialState());

  const [editApplicationFn, { loading: editApplicationLoading }] = useEditApplicationMutation({
    onError: (err) => showToast(err.message, 'error'),
    onCompleted: () => {
      refetch();
      handleClose();
      showToast(t('enrollment.lead-management.update-application-success'), 'success');
    },
  });

  const isCycleTypeOrDaysChanged = useCallback(
    (enquiry: IEnquiry) => {
      const allOriginalEnquires: IEnquiry[] = [];
      getInitialState().children.forEach((child) => {
        child.enquiries.forEach((enquiry) => allOriginalEnquires.push(enquiry));
      });
      const originalEnquiry = allOriginalEnquires.find((e) => e.id === enquiry.id);
      if (!originalEnquiry) {
        return false;
      }

      const isCycleTypeChanged = enquiry.cycleType !== originalEnquiry.cycleType;
      const isDaysChanged = !isEqual(enquiry.days, originalEnquiry.days);

      return isCycleTypeChanged || isDaysChanged;
    },
    [getInitialState]
  );

  const handleSave = useCallback(() => {
    editApplicationFn({
      variables: {
        input: {
          businessId,
          applicationId: application?.id ?? '',
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: formData.phoneNumber,
          email: formData.email,
          editApplicationChildMessages: formData.children.map((c) => ({
            applicationChildId: c.applicationChildId,
            firstName: c.firstName,
            lastName: c.lastName,
            dateOfBirth: c.dateOfBirth,
            startDate: c.startDate,
            editApplicationScheduleEnquiryMessages: c.enquiries
              .map((e) => {
                const isChanged = isCycleTypeOrDaysChanged(e);
                if (isChanged) {
                  return {
                    applicationScheduleEnquiryId: e.id,
                    cycleType: e.cycleType as ContractCycleType,
                    editApplicationScheduleEnquiryDaysMessages:
                      (e.cycleType as ContractCycleType) === ContractCycleType.Casual
                        ? []
                        : e.days.map((d) => ({
                            weekType: d.weekType as WeekTypeGql,
                            dayOfWeek: d.dayOfWeek as DayOfWeekGql,
                          })),
                  };
                } else {
                  return null;
                }
              })
              .filter((e) => e !== null),
          })),
        },
      },
    });
  }, [editApplicationFn, businessId, application, formData, isCycleTypeOrDaysChanged]);

  const formDataErrors = useMemo(() => {
    let errors: Record<string, string> = {};

    if (!(formData.email && isEmailValid(formData.email))) {
      errors = {
        email: !isEmailSyntaxValid(formData.email ?? null)
          ? errorMessage.invalidEmailSyntax
          : errorMessage.invalidEmailDomain,
      };
    }

    if (!(formData.phoneNumber && isValidPhoneNumber(formData.phoneNumber))) {
      errors = {
        phoneNumber: errorMessage.genericPhone,
      };
    }

    if (formData.firstName.trim().length === 0) {
      errors = {
        firstName: t('core.field-required', { fieldName: 'First Name' }),
      };
    }

    if (formData.lastName.trim().length === 0) {
      errors = {
        lastName: t('core.field-required', { fieldName: 'Last Name' }),
      };
    }

    formData.children.forEach((c, index) => {
      if (c.firstName.trim().length === 0) {
        set(errors, `children[${index}].firstName`, t('core.field-required', { fieldName: 'First Name' }));
      }
      if (c.lastName.trim().length === 0) {
        set(errors, `children[${index}].lastName`, t('core.field-required', { fieldName: 'Last Name' }));
      }
      if (!moment(c.dateOfBirth).isValid()) {
        set(errors, `children[${index}].dateOfBirth`, 'Invalid date');
      }
      if (!moment(c.startDate).isValid()) {
        set(errors, `children[${index}].startDate`, 'Invalid date');
      }

      c.enquiries.forEach((e, enquiryIdx) => {
        if (e.days.length === 0 && (e.cycleType as ContractCycleType) !== ContractCycleType.Casual) {
          set(errors, `children[${index}].enquiries[${enquiryIdx}].days`, 'Please select at least one day');
        }
      });
    });

    return errors;
  }, [formData, t]);

  useEffect(() => {
    setFormData(getInitialState());
  }, [application, getInitialState]);

  const handleOnChange = (path: string, value: any) => {
    const mutableFormData = cloneDeep(formData);
    set(mutableFormData, path, value);
    setFormData(mutableFormData);
  };

  const handleFrequencyChange = (enquiryPath: string, newCycleType: string) => {
    const enquiry: IEnquiry = get(formData, enquiryPath);
    if (enquiry) {
      if (newCycleType === 'WEEKLY') {
        const days = cloneDeep(enquiry.days.filter((d) => d.weekType === 'WEEK1'));
        const updatedEnquiry = {
          ...enquiry,
          days,
          cycleType: newCycleType,
        };

        handleOnChange(enquiryPath, updatedEnquiry);
      } else {
        handleOnChange(`${enquiryPath}.cycleType`, newCycleType);
      }
    }
  };

  const toggleDayOfWeek = (day: WeekDay, week: WeekType, enquiryPath: string) => {
    const enquiry: IFormData['children'][number]['enquiries'][number] = get(formData, enquiryPath);
    if (enquiry?.days) {
      const days = cloneDeep(enquiry.days);
      if (days.some((d) => d.weekType === week && d.dayOfWeek === day)) {
        remove(days, (d) => d.weekType === week && d.dayOfWeek === day);
      } else {
        days.push({
          weekType: week,
          dayOfWeek: day,
        });
      }

      handleOnChange(`${enquiryPath}.days`, days);
    }
  };

  const isAdvancedEdit = editApplicationModalState.editLevel === 'advanced';

  const frequencyOptions = useMemo(
    () => [
      { value: 'WEEKLY', label: 'Weekly' },
      { value: 'BIWEEKLY', label: capitalize(t('spelling.biweekly')) },
      ...[{ value: 'CASUAL', label: 'Casual' }],
    ],
    [t]
  );

  return (
    <CenteredModal
      title={t('enrollment.lead-management.edit-application-for', {
        accountName: application?.accountName ?? '',
      })}
      size={isAdvancedEdit ? 'lg' : undefined}
      show={editApplicationModalState.isOpen}
      onHide={handleClose}
      className="edit-application-modal"
      dialogClassName=""
      closeOnPrimaryCallback={false}
      primaryChoice={capitalize(t('spelling.save'))}
      primaryCallback={handleSave}
      primaryButtonProps={{
        disabled: Object.keys(formDataErrors).length > 0,
        loading: editApplicationLoading,
        className: 'slim-button',
      }}
      secondaryButtonProps={{
        className: 'slim-button mr-4',
      }}
      secondaryChoice={capitalize(t('spelling.cancel'))}
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <div>
        <div className="make-offer-modal-body">
          {isAdvancedEdit && (
            <>
              <Row>
                <Col>
                  <h5 className="my-2">{t('enrollment.lead-management.applicant-details')}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={t('enrollment.lead-management.first-name')}
                    required
                    value={formData.firstName}
                    onChange={(v) => handleOnChange('firstName', v)}
                    errorText={formDataErrors?.firstName ?? ''}
                    isInvalid={Boolean(formDataErrors?.firstName)}
                    disabled={!formData.isInitialEnquiry}
                  />
                </Col>
                <Col>
                  <TextInput
                    label={t('enrollment.lead-management.last-name')}
                    required
                    value={formData.lastName}
                    onChange={(v) => handleOnChange('lastName', v)}
                    errorText={formDataErrors?.lastName ?? ''}
                    isInvalid={Boolean(formDataErrors?.lastName)}
                    disabled={!formData.isInitialEnquiry}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col md={isAdvancedEdit ? 6 : 12}>
              <EmailInput
                label={capitalize(t('spelling.email'))}
                name="email"
                required
                value={formData.email}
                onChange={(v) => handleOnChange('email', v)}
                errorText={formDataErrors?.email ?? ''}
                isInvalid={Boolean(formDataErrors?.email)}
              />
            </Col>
            <Col md={isAdvancedEdit ? 6 : 12}>
              <PhoneInput
                required
                label={t('enrollment.lead-management.phone-number')}
                name="phoneNumber"
                value={formData.phoneNumber || ''}
                onChange={(v) => handleOnChange('phoneNumber', v)}
                errorText={formDataErrors?.phoneNumber ?? ''}
                isInvalid={Boolean(formDataErrors?.phoneNumber)}
              />
            </Col>
          </Row>
          {isAdvancedEdit &&
            formData.children.map((child, index, array) => {
              return (
                <div
                  className={classNames('mb-4', {
                    'border-bottom': index !== array.length - 1,
                  })}
                >
                  <Row>
                    <Col>
                      <h5>{t('enrollment.lead-management.child-details', { count: index + 1 })}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput
                        label={t('enrollment.lead-management.first-name')}
                        required
                        value={child.firstName}
                        onChange={(v) => handleOnChange(`children[${index}].firstName`, v)}
                        errorText={get(formDataErrors, `children[${index}].firstName`)}
                        isInvalid={Boolean(get(formDataErrors, `children[${index}].firstName`))}
                        disabled={child.isNew == false}
                      />
                    </Col>
                    <Col>
                      <TextInput
                        label={t('enrollment.lead-management.last-name')}
                        required
                        value={child.lastName}
                        onChange={(v) => handleOnChange(`children[${index}].lastName`, v)}
                        errorText={get(formDataErrors, `children[${index}].lastName`)}
                        isInvalid={Boolean(get(formDataErrors, `children[${index}].lastName`))}
                        disabled={child.isNew == false}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-6">
                    <Col>
                      <DateInput
                        required
                        label={t('enrollment.lead-management.date-of-birth')}
                        date={child.dateOfBirth}
                        isValid={!Boolean(get(formDataErrors, `children[${index}].dateOfBirth`))}
                        onDateSelect={(v) => handleOnChange(`children[${index}].dateOfBirth`, v)}
                        className="kt-date-input-no-max-width"
                        dateOnly
                        disabled={child.isNew == false}
                      />
                    </Col>
                    <Col>
                      <DateInput
                        required
                        label={t('enrollment.lead-management.start-date')}
                        date={child.startDate}
                        isValid={!Boolean(get(formDataErrors, `children[${index}].startDate`))}
                        onDateSelect={(v) => handleOnChange(`children[${index}].startDate`, v)}
                        className="kt-date-input-no-max-width"
                        dateOnly
                      />
                    </Col>
                  </Row>
                  {child.enquiries.map((enquiry, enquiryIdx) => {
                    return (
                      <>
                        <Row>
                          <Col>
                            <h5>{enquiry.careType}</h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} className="align-self-start">
                            <label>{capitalize(t('spelling.frequency'))}</label>
                            <Select
                              options={frequencyOptions}
                              value={enquiry.cycleType}
                              onChange={(option) =>
                                handleFrequencyChange(`children[${index}].enquiries[${enquiryIdx}]`, option.value)
                              }
                            />
                          </Col>
                          {(enquiry.cycleType as ContractCycleType) !== ContractCycleType.Casual && (
                            <Col md={6}>
                              <label>{capitalize(t('spelling.days'))}</label>
                              {enquiry.cycleType === 'WEEKLY' ? (
                                <div>
                                  <DayInput
                                    value={
                                      enquiry.days
                                        .filter((d) => d.weekType === 'WEEK1')
                                        .map((d) => d.dayOfWeek) as WeekDay[]
                                    }
                                    onDaySelect={(value) =>
                                      toggleDayOfWeek(value, 'WEEK1', `children[${index}].enquiries[${enquiryIdx}]`)
                                    }
                                  />
                                  {get(formDataErrors, `children[${index}].enquiries[${enquiryIdx}].days`) && (
                                    <small className="text-danger d-block">
                                      {get(formDataErrors, `children[${index}].enquiries[${enquiryIdx}].days`)}
                                    </small>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <DayBiweeklyInput
                                    firstWeekDays={
                                      enquiry.days
                                        .filter((d) => d.weekType === 'WEEK1')
                                        .map((d) => d.dayOfWeek) as WeekDay[]
                                    }
                                    secondWeekDays={
                                      enquiry.days
                                        .filter((d) => d.weekType === 'WEEK2')
                                        .map((d) => d.dayOfWeek) as WeekDay[]
                                    }
                                    onDaySelect={(value: WeekDay, week: WeekType) =>
                                      toggleDayOfWeek(value, week, `children[${index}].enquiries[${enquiryIdx}]`)
                                    }
                                  />
                                  {get(formDataErrors, `children[${index}].enquiries[${enquiryIdx}].days`) && (
                                    <small className="text-danger">
                                      {get(formDataErrors, `children[${index}].enquiries[${enquiryIdx}].days`)}
                                    </small>
                                  )}
                                </div>
                              )}
                            </Col>
                          )}
                        </Row>
                      </>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    </CenteredModal>
  );
};

export default EditApplicationModal;
