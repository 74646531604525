import _, { isEmpty } from 'lodash';
import { Option } from 'react-select/src/filters';

export const parseOptions = (attributes: string | null): string[] | Option[] => {
  if (isEmpty(attributes) || attributes === null) return [];
  try {
    let jsonAttributes: string = JSON.parse(attributes);

    if (typeof jsonAttributes === 'object' && Array.isArray(jsonAttributes)) {
      return jsonAttributes;
    } else {
      const strings = jsonAttributes?.split('\n');
      for (let i = 0; i < strings.length; i++) {
        strings[i] = _.trim(strings[i]);
      }
      return strings.filter((item) => item !== ''); // remove empty line;
    }
  } catch {
    return [];
  }
};

export const trimAttributes = (attributes: string): string => {
  try {
    return parseOptions(JSON.stringify(attributes)).join('\n');
  } catch (error) {
    return attributes;
  }
};
