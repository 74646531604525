import React, { useCallback } from 'react';
import RBCard from 'react-bootstrap/Card';
import { LoadingLines, Box } from 'shared/components/LoadingSkeletons';

interface IProps {}

const WeekTimeSheetLoader: React.FC<IProps> = ({ ...props }) => {
  return (
    <RBCard className="mx-0 mt-0 mb-4">
      <RBCard.Header className="d-flex flex-row align-items-center">
        <Box height={20} width="20%" />
      </RBCard.Header>
      <RBCard.Body className="px-8 py-4">
        <LoadingLines number={2} />
      </RBCard.Body>
    </RBCard>
  );
};

export default WeekTimeSheetLoader;
