import React from 'react';
import Card from 'shared/components/Card';
import { Row } from 'shared/components/Layout';
import { Box } from 'shared/components/LoadingSkeletons';
import { start } from 'repl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import * as Mui from '@mui/material';

interface IProps {
  title: string;
  color: string;
  description?: string;
  number?: number;
  onClick?: () => void;
  loading?: boolean;
  className?: string;
}

const ColoredNumberCard: React.FC<IProps> = ({
  title,
  description,
  number,
  color,
  onClick,
  className,
  loading = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Mui.Card
      sx={{
        display: 'flex',
        alignContent: 'center',
        width: isMobile ? '100%' : '32%',
        border: '1px solid #e2e7ee',
        alignItems: 'center',
        marginY: isMobile ? 1 : '',
        justifyContent: 'spaceBetween',
      }}
    >
      <Mui.Box display="flex" alignItems="center" alignContent="center" width="100%">
        <Mui.Grid container direction="row" alignItems="center" sx={{ marginX: 2 }}>
          <Mui.Grid item xs={9} sm={9} md={9} lg={9}>
            <Mui.Stack justifyContent="start" sx={{ marginTop: 2, marginBottom: 0 }}>
              <h4 className={`proxima-soft text-${color} font-weight-bold`} style={{ color }}>
                {title}
              </h4>
              <div
                style={{
                  display: 'flex',
                  color: '#8e9bac',
                  justifyContent: 'start',
                }}
              >
                {description && <p style={{}}>{description}</p>}
              </div>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid item xs={3} sm={3} md={3} lg={3}>
            <div style={{ display: 'flex', justifyContent: 'end', alignContent: 'center' }}>
              <h3
                className={`text-${color}`}
                style={{
                  display: 'flex',
                  margin: 0,
                  fontSize: '3rem',
                  color,
                  fontFamily: 'proxima-soft',
                  alignItems: 'center',
                }}
              >
                {number === 0 ? 0 : number?.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
              </h3>
            </div>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Box>
    </Mui.Card>
  );
};

export default ColoredNumberCard;
