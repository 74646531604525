import React from 'react';
import Form from 'react-bootstrap/Form';
import { Row, Col } from 'shared/components/Layout';
import { ICreateAgencyFormShape } from './CreateUpdateAgencyModal';
import TextInput from 'shared/components/TextInput';
import { useTranslation } from 'react-i18next';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import DetailedRadioOption from 'shared/components/DetailedRadioOption';
import { HorizontalDivider } from 'shared/components/Dividers';
import Select from 'shared/components/Select';

interface IProps {
  data: ICreateAgencyFormShape;
  onUpdate: React.Dispatch<React.SetStateAction<ICreateAgencyFormShape>>;
}

const CreateAgencyFormStepOne: React.FC<IProps> = ({ data, onUpdate, ...props }) => {
  const { t } = useTranslation(['subsidies']);

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex flex-row">
            <Form.Label>{t('subsidies:agencies.create-modal.billing-calculation-label')}</Form.Label>
            <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
          </div>
          <DetailedRadioOption
            title={t('subsidies:agencies.create-modal.copay-amount-calculation-title')}
            description={t('subsidies:agencies.create-modal.copay-amount-calculation-paragraph')}
            onSelect={(selected) =>
              onUpdate((prev) => ({ ...prev, subsidyCalculationBasis: selected ? 'COPAY_AMOUNT' : null }))
            }
            isSelected={data.subsidyCalculationBasis === 'COPAY_AMOUNT'}
            className="mb-2"
          />
          <DetailedRadioOption
            title={t('subsidies:agencies.create-modal.subsidy-amount-calculation-title')}
            description={t('subsidies:agencies.create-modal.subsidy-amount-calculation-paragraph')}
            onSelect={(selected) =>
              onUpdate((prev) => ({ ...prev, subsidyCalculationBasis: selected ? 'SUBSIDY_AMOUNT' : null }))
            }
            isSelected={data.subsidyCalculationBasis === 'SUBSIDY_AMOUNT'}
            className="mb-4"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            label={t('subsidies:agencies.create-modal.subsidy-period-label')}
            value={data.subsidyPeriod}
            options={[
              { label: 'Session', value: 'SESSION' },
              { label: 'Weekly', value: 'WEEKLY' },
              { label: 'Every two weeks', value: 'EVERY_TWO_WEEKS' },
              { label: 'Monthly', value: 'MONTHLY' },
            ]}
            onChange={(opt) => onUpdate((prev) => ({ ...prev, subsidyPeriod: opt.value }))}
          />
        </Col>
      </Row>
      <HorizontalDivider />
      <Row>
        <Col>
          <TextInput
            label={t('subsidies:agencies.create-modal.absent-days-input-label')}
            value={data.absencesPolicy}
            onChange={(value) => onUpdate((prev) => ({ ...prev, absencesPolicy: value }))}
            name="agency-absences"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            required
            label={t('subsidies:agencies.create-modal.attendance-requirement-label')}
            value={data.attendancePolicy}
            onChange={(value) => onUpdate((prev) => ({ ...prev, attendancePolicy: value }))}
            as="textarea"
            rows={3}
            name="agency-attendance"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            label={t('subsidies:agencies.create-modal.reimbursement-schedule-label')}
            value={data.reimbursementPolicy}
            onChange={(value) => onUpdate((prev) => ({ ...prev, reimbursementPolicy: value }))}
            as="textarea"
            rows={3}
            name="agency-reimbursement"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>{t('subsidies:agencies.create-modal.link-label')}</Form.Label>
          {data.links.map((link: string | IAgencyLink, idx: number) => (
            <div className="d-flex align-items-center" key={`link-${idx}`}>
              <TextInput
                label=""
                value={typeof link === 'string' ? link : link.link}
                onChange={(value) =>
                  onUpdate((prev) => ({ ...prev, links: prev.links.map((l, index) => (index === idx ? value : l)) }))
                }
                name="agency-link"
              />
              <IconButtonCircle
                icon={idx === data.links.length - 1 ? faPlus : faTrashAlt}
                onClick={() =>
                  idx === data.links.length - 1
                    ? onUpdate((prev) => ({ ...prev, links: [...prev.links, ''] }))
                    : onUpdate((prev) => ({ ...prev, links: prev.links.filter((_, index) => index !== idx) }))
                }
                className="ml-4 mb-4"
                size="md"
              />
            </div>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default CreateAgencyFormStepOne;
