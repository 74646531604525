import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import IconButton from '@mui/material/IconButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import MessageBubble from './MessageBubble';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MessagePayload } from '@pubnub/react-chat-components';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
interface IProps {
  openingMessage: MessagePayload;
  threadChildren: Array<MessagePayload>;
  setThread: (parentThreadMessageId: string | null) => void;
}

const ThreadedMessageList: React.FC<IProps> = ({ openingMessage, threadChildren, setThread }) => {
  const user = useSelector((state: RootState) => state.user);
  const avatarUrl = openingMessage.sender?.profileUrl ?? '';

  return (
    <div className="pn-msg-list">
      <div className="pn-thread-list-header">
        <IconButton aria-label="back" onClick={() => setThread(null)} size="medium">
          <FontAwesomeIcon icon={faArrowLeft as IconProp} size="1x"></FontAwesomeIcon>
        </IconButton>
      </div>
      <div className="pn-msg pn-msg--opening-message">
        <MessageBubble
          avatarUrl={avatarUrl}
          payload={openingMessage}
          messageText={openingMessage.text ?? ''}
          messageSender={openingMessage.sender?.name ?? ''}
          createdAt={openingMessage.createdAt ?? ''}
        />
        <div className="pn-msg__actions">{/* Future Message Actions Go Here */}</div>
      </div>
      {threadChildren.map((childMessage) => {
        const isOwn = childMessage.sender?.id === (user && user.id);
        return (
          <div className={`pn-msg ${isOwn ? 'pn-msg--own' : ''}`}>
            <MessageBubble
              avatarUrl={childMessage.sender?.profileUrl ?? ''}
              payload={childMessage}
              messageText={childMessage.text ?? ''}
              messageSender={childMessage.sender?.name ?? ''}
              createdAt={childMessage.createdAt ?? ''}
            />
            <div className="pn-msg__actions">{/* Future Message Actions Go Here */}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ThreadedMessageList;
