import React, { useMemo, useState } from 'react';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPlus } from '@fortawesome/pro-light-svg-icons';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { billingFrequencyOptions } from 'shared/constants/enums/billingCycleOptions';
import { getChargeDayValue } from '../Settings/Tabs/Cycles/utils';
import PageWrapper from 'shared/components/PageWrapper';
import TextInput from 'shared/components/TextInput';
import { useSelector } from 'react-redux';
import StatementRunsTable from './StatementRunsTable';
import ScheduleStatementRunModalForm from './ScheduleStatementRunModalForm';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { RootState } from 'store/reducers';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import {
  useGetCenterStatementMessage,
  useGetCenterStatementRuns,
  useGetCenterStatementSchedule,
} from 'gql/statement/queries';
import { CreateButton } from 'shared/components/Buttons';
import { useUpdateCenterStatementMessage, useUpdateCenterStatementSchedule } from 'gql/statement/mutations';
import { showToast } from 'shared/components/Toast';
import { ToastType } from 'react-toastify';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import useDatatableState from 'shared/hooks/useDatatableState2';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';
import moment from 'moment';
import ManualStatementRunModalForm from './ManualStatementRunModalForm';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface Props {}

interface IStatementScheduleFrequency {
  day?: string;
  frequency?: string;
}

const Statement: React.FC<Props> = ({}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isStatementScheduleSaving, setIsStatementScheduleSaving] = useState(false);

  const [statementMessageFormData, setStatementMessageFormData] = useState<string>('');
  const [statementMessage, setStatementMessage] = useState<string>('');

  const [formIsDirty, setFormIsDirty] = useState(false);
  const [isStatementScheduleOpen, setIsStatementScheduleOpen] = useState(false);
  const [isManualStatementScheduleOpen, setIsManualStatementScheduleOpen] = useState(false);

  const [tableState, tableFunctions] = useDatatableState();
  const [sort, setSortOptions] = useState<ICenterStatementRunsSort[]>([
    { field: 'centerName', direction: 'ASCENDING' },
  ]);

  const [centerStatementSchedule, setCenterStatementSchedule] = useState<ICenterStatementSchedule | undefined>(
    undefined
  );

  useGetActiveCenters();
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const user = useSelector((state: RootState) => state.user);

  const { data: statementScheduleData, loading: statementScheduleDataLoading } = useGetCenterStatementSchedule({
    variables: {
      centerId: currentCenterId ?? '',
    },
    onCompleted: (data) => {
      setCenterStatementSchedule(data.getCenterStatementSchedule);
    },
  });

  const { data: centerStatementMessageData, loading: centerStatementMessageLoading } = useGetCenterStatementMessage({
    variables: {
      centerId: currentCenterId ?? '',
    },
    onCompleted: (data) => {
      setStatementMessage(data.getCenterStatementMessage ?? '');
      setStatementMessageFormData(data.getCenterStatementMessage ?? '');
    },
  });

  const {
    data: centerStatementRunsData,
    loading: centerStatementRunsDataLoading,
    refetch: refetchCenterStatementRunsData,
  } = useGetCenterStatementRuns({
    variables: {
      input: {
        centerId: currentCenterId ?? '',
        sortDtos: sort,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
      },
    },
  });

  const [updateCenterStatementMessage] = useUpdateCenterStatementMessage();
  const [updateCenterStatementSchedule] = useUpdateCenterStatementSchedule();

  const handleStatementScheduleSave = (formData: ICenterStatementFormData) => {
    setIsStatementScheduleSaving(true);
    updateCenterStatementSchedule({
      variables: {
        input: {
          centerId: currentCenterId ?? '',
          isEnabled: formData.isEnabled,
          frequency: formData.frequency,
          dayOfWeek: formData.dayOfWeek,
          dayOfMonth: formData.dayOfMonth,
          periodBasis: formData.periodBasis,
          periodWeeksPrior: formData.periodWeeksPrior,
          periodWeeksAfter: formData.periodWeeksAfter,
          message: statementMessage ?? '',
          startDate: moment(formData.startDate).format('YYYY-MM-DD'),
        },
      },
    })
      .then((data) => {
        showToast('Statement schedule was updated successfully', ToastType.SUCCESS);
        setCenterStatementSchedule(data.data?.updateCenterStatementSchedule);
        setIsStatementScheduleSaving(false);
        setIsStatementScheduleOpen(false);
      })
      .catch(() => {
        setIsStatementScheduleSaving(false);
        showToast('There was an error updating the statement schedule for this center.', ToastType.ERROR);
        setIsStatementScheduleSaving(false);
      });
  };

  const handleSave = () => {
    setIsSaving(true);
    updateCenterStatementMessage({
      variables: {
        input: {
          centerId: currentCenterId ?? '',
          message: statementMessageFormData ?? '',
        },
      },
    })
      .then((data: any) => {
        showToast('Statement message was updated successfully', ToastType.SUCCESS);
        setStatementMessage(statementMessageFormData);
        setFormIsDirty(false);
        setIsSaving(false);
      })
      .catch(() => {
        setIsSaving(false);
        showToast('There was an error updating the statement schedule for this center.', ToastType.ERROR);
      });
  };

  const frequencyText: IStatementScheduleFrequency = useMemo(() => {
    if (!!centerStatementSchedule) {
      const dayText = getChargeDayValue(centerStatementSchedule);
      const frequencyOptions = billingFrequencyOptions.filter((i) => i.value === centerStatementSchedule.frequency);
      const frequencyText = frequencyOptions.length > 0 ? frequencyOptions[0].label : '';
      return { day: dayText == -1 ? 'last day' : dayText?.label, frequency: frequencyText };
    }
    return { day: '', frequency: '' };
  }, [centerStatementSchedule]);

  const canCreateStatements =
    user?.hasAreaPermissionLevel({
      area: AreaType.Statement,
      permission: PermissionType.Base,
      level: RoleLevelType.Create,
    }) ?? false;

  return (
    <PageWrapper
      pageTitle="Statement Processing"
      buttonComponent={
        canCreateStatements && (
          <>
            <CreateButton
              icon={faPlus}
              className="mr-4"
              disabled={!currentCenterId || statementScheduleDataLoading}
              onClick={() => {
                setIsManualStatementScheduleOpen(true);
              }}
            >
              Manual Run
            </CreateButton>
            <CreateButton
              disabled={!currentCenterId || statementScheduleDataLoading}
              onClick={() => setIsStatementScheduleOpen(true)}
            >
              Schedule Statements
            </CreateButton>
          </>
        )
      }
    >
      <CenterSelectBanner pageName="statements" />

      <SpinnerOverlay show={!currentCenterId || statementScheduleDataLoading}>
        <>
          {!!centerStatementSchedule && (
            <Row>
              <Col md={12}>
                <div className="ccs-account-management blue-info-block p-4 d-flex flex-row mt-6">
                  <div className="mr-4">
                    <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                  </div>
                  <div className="mr-6 d-flex align-items-center">
                    {centerStatementSchedule.isEnabled && (
                      <>
                        A statement will be sent on {frequencyText.day} of every {frequencyText.frequency}.
                      </>
                    )}
                    {!centerStatementSchedule.isEnabled && <>No scheduled statement run.</>}
                  </div>
                </div>
              </Col>
            </Row>
          )}

          <Card
            header="Message"
            loading={statementScheduleDataLoading || centerStatementMessageLoading || centerStatementRunsDataLoading}
            loadingLines={2}
            className="mt-6"
          >
            <FormWrapper2
              onCancel={() => {
                setStatementMessageFormData(statementMessage);
                setFormIsDirty(false);
              }}
              onSave={() => handleSave()}
              loading={isSaving}
              formIsDirty={formIsDirty}
              toggleDirty={() => setFormIsDirty(!formIsDirty)}
            >
              <div>
                <Row>
                  <Col md={12}>
                    <div className="mb-6 font-weight-bold">
                      I want to add the following message to the statements...
                    </div>
                    <TextInput
                      required={false}
                      label="Description of statements being sent"
                      name="description"
                      value={statementMessageFormData}
                      onChange={(value: string) => {
                        setStatementMessageFormData(value);
                        setFormIsDirty(true);
                      }}
                      as="textarea"
                      disabled={false}
                      placeholder="Message here..."
                      rows={8}
                    />
                  </Col>
                  <Col>{''}</Col>
                </Row>
              </div>
            </FormWrapper2>
          </Card>
          {!statementScheduleDataLoading && (
            <SpinnerTableOverlay show={centerStatementRunsDataLoading}>
              <StatementRunsTable
                data={centerStatementRunsData?.getCenterStatementRuns.data || []}
                centerId={currentCenterId ?? ''}
                dataSize={centerStatementRunsData?.getCenterStatementRuns.totalRecords || 0}
                loading={centerStatementRunsDataLoading}
                pageSize={tableState.pageSize}
                activePage={tableState.activePage}
                onPageChange={tableFunctions.changePage}
                onSizePerPageChange={tableFunctions.changeSizePerPage}
              />
            </SpinnerTableOverlay>
          )}
        </>
      </SpinnerOverlay>

      <ScheduleStatementRunModalForm
        isOpen={isStatementScheduleOpen}
        isSaving={isStatementScheduleSaving}
        onSave={(formData: ICenterStatementFormData) => handleStatementScheduleSave(formData)}
        onClose={() => setIsStatementScheduleOpen(false)}
        centerStatementSchedule={statementScheduleData?.getCenterStatementSchedule}
      />
      <ManualStatementRunModalForm
        isOpen={isManualStatementScheduleOpen}
        onAfterRun={() => refetchCenterStatementRunsData()}
        onClose={() => setIsManualStatementScheduleOpen(false)}
        centerId={`${currentCenterId}`}
        message={statementMessage}
      />
    </PageWrapper>
  );
};

export default Statement;
