export const debtFields = `
  debtId
  issuedDate
  dueDate
  status
  originalAmount
  outstandingBalance
  reason
  legislationReferenceForTheDebt
  totalInterestCharged
  ccssProviderId
  ccssServiceId
  serviceName
  providerName
`;

export const altArrangementFields = `
  id
  status
  dateSubmitted
  providerId
  serviceId
  totalAmount
  type
  startDate
  paymentPeriod
  offsetPercentage
`;
