import React, { useCallback } from 'react';
import i18n from 'i18n';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentCenter } from 'store/context/actions';
import AccountLink from 'shared/components/AccountLink/AccountLink';
import { ApplicationStage } from 'generated/graphql';

export const useNotificationGoto = () => {
  const dispatch = useDispatch();

  const callback = useCallback(
    (notificationData: INotificationV2) => {
      let builder = NotificationV2Factory.getBuilder(notificationData);
      const centreId = builder.centreId();

      return builder.description(() => {
        if (centreId) {
          // this is necessary to push setting the current centre to the next tick
          const timeout = setTimeout(() => {
            dispatch(setCurrentCenter(centreId!));
            clearTimeout(timeout);
          }, 100);
        }
      });
    },
    [dispatch]
  );

  return {
    getDescription: callback,
  };
};
export interface NotificationV2DescriptionBuilder {
  description(onclick?: () => void): string | JSX.Element;
  centreId(): string | undefined;
}

export class EnrolmentLTBNotification implements NotificationV2DescriptionBuilder {
  private data: INotificationV2;

  constructor(data: INotificationV2) {
    this.data = data;
  }

  description(onclick?: () => void) {
    return this.buildDescriptionLink(onclick);
  }

  private buildDescriptionLink(onclick?: () => void) {
    // get all the meta data
    const centreId = this.data.centerId;
    const firstName = this.data.metadata?.find((o) => o.key === 'ApplicantFirstName')?.value;
    const lastName = this.data.metadata?.find((o) => o.key === 'ApplicantLastName')?.value;
    const accountName = this.data.metadata?.find((o) => o.key === 'AccountName')?.value;
    const accountId = this.data.metadata?.find((o) => o.key === 'AccountId')?.value;
    const accountTab = this.data.metadata?.find((o) => o.key === 'AccountTab')?.value;
    const activeTab = this.data.metadata?.find((o) => o.key === 'LeadTab')?.value;

    // perference linking to the account
    if (accountName && accountId) {
      const descriptionParts = this.data.description.split(accountName);
      const element = (
        <div>
          {descriptionParts[0]}{' '}
          <AccountLink accountName={accountName} accountId={accountId} accountTab={accountTab} openInNewTab />{' '}
          {descriptionParts[1]}
        </div>
      );
      return element;
    }
    // if there is no account link to the lead management screens
    if (firstName) {
      const findName =
        firstName && lastName && this.data.description.includes(firstName) && this.data.description.includes(lastName)
          ? `${firstName} ${lastName}`
          : firstName;
      const descriptionParts = this.data.description.split(findName);
      const element = (
        <div>
          {descriptionParts[0]}{' '}
          <Link
            style={{ textDecoration: 'underline' }}
            to={{ pathname: `/enrollment/lead-management/${activeTab ?? ''}`, state: { firstName, centreId } }}
            onClick={onclick}
          >
            {findName}
          </Link>{' '}
          {descriptionParts[1]}
        </div>
      );
      return element;
    }
    return this.data.description;
  }

  centreId(): string | undefined {
    return this.data.centerId;
  }
}

export class CommsNotification implements NotificationV2DescriptionBuilder {
  private data: INotificationV2;

  constructor(data: INotificationV2) {
    this.data = data;
  }

  description(onclick?: () => void) {
    return this.buildDescriptionLink(onclick);
  }

  private buildDescriptionLink(onclick?: () => void) {
    console.log(this.data.metadata);

    const channelId = this.data.metadata?.find((o) => o.key === 'ChannelId')?.value;
    if (channelId) {
      const element = (
        <div>
          {this.data.description}
          {'. '}
          <Link
            style={{ textDecoration: 'underline' }}
            to={{ pathname: `/families/accounts/${channelId}/messaging`, state: {} }}
            onClick={onclick}
          >
            {'View message.'}
          </Link>
        </div>
      );
      return element;
    }

    return this.data.description;
  }

  centreId(): string | undefined {
    return this.data.centerId;
  }
}

export class NonTypedNotification implements NotificationV2DescriptionBuilder {
  private data: INotificationV2;

  constructor(data: INotificationV2) {
    this.data = data;
  }

  description(): string {
    return this.data.description;
  }

  centreId(): string | undefined {
    return undefined;
  }
}

export class ImmunisationNotification implements NotificationV2DescriptionBuilder {
  private data: INotificationV2;

  constructor(data: INotificationV2) {
    this.data = data;
  }

  description() {
    const status = this.data.metadata?.find((d) => d.key === 'Status')?.value;
    const reviewDays = this.data.metadata?.find((d) => d.key === 'ReviewDays')?.value;
    const firstName = this.data.metadata?.find((d) => d.key === 'ChildFirstName')?.value;
    const lastName = this.data.metadata?.find((d) => d.key === 'ChildLastName')?.value;
    const id = this.data.metadata?.find((d) => d.key === 'ChildId')?.value;
    switch (status) {
      case 'expired':
        return (
          <>
            <Link style={{ textDecoration: 'underline' }} to={{ pathname: `/families/children/${id}` }}>
              {firstName} {lastName}'s
            </Link>{' '}
            {i18n.t('spelling.immunization')} document is now expired.
          </>
        );
      case 'expiring':
        return (
          <>
            <Link style={{ textDecoration: 'underline' }} to={{ pathname: `/families/children/${id}` }}>
              {firstName} {lastName}'s
            </Link>{' '}
            {i18n.t('spelling.immunization')} document will expire in {reviewDays} day/s.
          </>
        );
      default:
        break;
    }
    return this.data.description;
  }

  centreId(): string | undefined {
    return undefined;
  }
}

export class NotificationV2Factory {
  static getBuilder(data: INotificationV2): NotificationV2DescriptionBuilder {
    if (data.category === 'Medical' && data.type === 'Immunisation') {
      return new ImmunisationNotification(data);
    }
    if (!data.metadata || !data.metadata.some((o) => o.key === 'NotificationType')) {
      return new NonTypedNotification(data);
    }

    if (data.metadata.some((o) => o.key === 'NotificationType' && o.value === 'Enrolment_LTB')) {
      return new EnrolmentLTBNotification(data);
    }

    if (data.metadata.some((o) => o.key === 'ChannelId')) {
      return new CommsNotification(data);
    }

    return new NonTypedNotification(data);
  }
}
