import { addressFields } from 'gql/address/fields';

export const SEARCH_MY_CENTERS: string = `
  id
  entityId
  entity {
    id
    name
  }
  name
  tags {
    id
    name
    category
  }
  phoneNumber
  deactivatedAt
  deletedAt
  avatar {
    url
  }
  address {
    ${addressFields}
  }
  timezone
  openingDate
  closingDate
  website
  email
  acn
  abn
  externalId
  enableBillingCycleProcessing
  serviceType
  defaultBillingCycleTemplateId
  centerEnrollmentBillingCycleTemplates {
    id
    centerId
    billingCycleTemplateId
  }
  billingEnabledSettings {
    centerId
    enableBillingCycleProcessing
    enableBillingCycleProcessingEffectiveDate
  }
`;
