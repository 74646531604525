import {
  ContextActionTypes,
  SET_CURRENT_BUSINESS,
  SET_CURRENT_CENTER,
  SET_CURRENT_BUSINESS_FILTERS,
  SET_CURRENT_CENTER_FILTERS,
  SET_CURRENT_BUSINESS_CONFIGURATION,
  SET_CURRENT_BUSINESS_FEATURE,
} from './types';
import _ from 'lodash';

export interface IContextStateShape {
  businessId: string | null;
  centerId: string | null;
  businessFilterIds: string[];
  centerFilterIds: string[];
  businessConfiguration: IBusinessConfiguration | null;
  businessFeature: IBusinessFeature[];
  educationFeatures: Record<string, boolean>;
}

const initialState: IContextStateShape = {
  businessId: null,
  centerId: null,
  businessFilterIds: [],
  centerFilterIds: [],
  businessConfiguration: null,
  businessFeature: [],
  educationFeatures: {},
};

export const contextReducers = (
  state: IContextStateShape = initialState,
  action: ContextActionTypes
): IContextStateShape => {
  switch (action.type) {
    case SET_CURRENT_BUSINESS:
      return {
        ...state,
        businessId: action.businessId,
        centerId: null,
      };
    case SET_CURRENT_CENTER:
      return {
        ...state,
        centerId: action.centerId,
      };
    case SET_CURRENT_BUSINESS_FILTERS:
      return {
        ...state,
        businessFilterIds: action.businessIds,
      };
    case SET_CURRENT_CENTER_FILTERS:
      return {
        ...state,
        centerFilterIds: action.centerIds,
      };
    case SET_CURRENT_BUSINESS_CONFIGURATION:
      return {
        ...state,
        businessConfiguration: action.businessConfig,
        educationFeatures:
          action.businessConfig?.educationFeatures.reduce(
            (acc, curr) => ({ ...acc, [curr.feature]: curr.isEnabled }),
            {}
          ) ?? {},
      };
    case SET_CURRENT_BUSINESS_FEATURE:
      return {
        ...state,
        businessFeature: { ...state.businessFeature, ..._.mapKeys(action.businessFeature, 'type') },
      };
    default:
      return state;
  }
};
