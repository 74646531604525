import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';

interface IProps {
  formData: IFamilyTaxBenefitForm;
  showDocumentSelect: boolean;
  handleChange: (value: any, name: string) => void;
}

const FamilyTaxBenefitForm: React.FC<IProps> = ({ showDocumentSelect, formData, handleChange }) => {
  const { t } = useTranslation(['subsidies']);

  const handleFileChange = (files: File[]) => {
    handleChange(files[files.length - 1], 'file');
  };
  const partOptions = [
    { value: 'None', label: t('subsidies:program-child.familyTaxBenefit.none') },
    { value: 'FamilyTaxBenefitA', label: t('subsidies:program-child.familyTaxBenefit.familyTaxBenefitA') },
    { value: 'FamilyTaxBenefitAAndB', label: t('subsidies:program-child.familyTaxBenefit.familyTaxBenefitAAndB') },
  ];

  return (
    <div>
      <Row>
        <Col lg={6}>
          <label>Does the family receive Family Tax Benefits?</label>
          <Select
            options={partOptions}
            getOptionLabel={(g) => g.label}
            getOptionValue={(g) => g.value}
            onChange={handleChange}
            name="part"
            value={formData.part}
            placeholder="None"
          />
        </Col>
      </Row>
      {formData.linkToFile && (
        <Row className="mb-2">
          <Col>
            <a href={formData.linkToFile}>Current Document: {formData.fileName}</a>
          </Col>
        </Row>
      )}
      {showDocumentSelect && (
        <>
          <Row>
            <Col>
              <label>Family Tax Benefit Document</label>
            </Col>
          </Row>
          <FileSelectDropbox
            multiple={false}
            className="mb-2"
            value={formData.file ? [formData.file] : []}
            onChange={handleFileChange}
            showFiles
          />
        </>
      )}
    </div>
  );
};

export default FamilyTaxBenefitForm;
