import { Payment } from '../types/Payment';
import { APIError, useBillApi } from './useBillingApi';

export function useGetPayment(params: { paymentId: string }): {
  data?: Payment;
  error?: APIError;
  loading: boolean;
  refetch: () => void;
} {
  return useBillApi('GET', `api/v2/billing-activity/payment/${params.paymentId}`);
}
