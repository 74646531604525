import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { immunizationFields } from './fields';

interface ICreateChildImmunizationRecordData {
  createChildImmunizationRecord: IImmunization[];
}

interface ICreateChildImmunizationRecordVariables {
  input: {
    childId: string;
    document: IHealthConditionDocumentUploadInput;
  };
}

interface IDeleteChildImmunizationRecordData {
  deleteChildImmunizationRecord: IImmunization; // temp, AB#3654
}

interface IDeleteChildImmunizationRecordVariables {
  childId: string;
  immunizationId: string;
}

interface IUpdateImmunizationDocumentData {
  updateImmunizationDocument: IImmunization;
}

interface IUpdateImmunizationDocumentVariables {
  input: {
    id: string;
    immunizationId: string;
    expirationDate: string | null;
    reviewDaysBeforeExpiration?: number | null;
  };
}

export const CREATE_CHILD_IMMUNIZATION_RECORD = (fields: string = immunizationFields) => gql`
  mutation ($input: CreateChildImmunizationInput!) {
    createChildImmunizationRecord(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_CHILD_IMMUNIZATION_RECORD = (fields: string = immunizationFields) => gql`
  mutation ($childId: ID!, $immunizationId: ID!) {
    deleteChildImmunizationRecord(childId: $childId, immunizationId: $immunizationId) {
      id
      childId
    }
  }
`;

export const UPDATE_IMMUNIZATION_DOCUMENT = (fields: string = immunizationFields) => gql`
  mutation ($input: UpdateImmunizationDocumentInput!) {
    updateImmunizationDocument(input: $input) {
      ${fields}
    }
  }
`;

export const useCreateChildImmunizationRecord = (
  options?: MutationHookOptions<ICreateChildImmunizationRecordData, ICreateChildImmunizationRecordVariables>,
  fields?: string
) =>
  useMutation<ICreateChildImmunizationRecordData, ICreateChildImmunizationRecordVariables>(
    CREATE_CHILD_IMMUNIZATION_RECORD(fields),
    options
  );

export const useDeleteChildImmunizationRecord = (
  options?: MutationHookOptions<IDeleteChildImmunizationRecordData, IDeleteChildImmunizationRecordVariables>,
  fields?: string
) =>
  useMutation<IDeleteChildImmunizationRecordData, IDeleteChildImmunizationRecordVariables>(
    DELETE_CHILD_IMMUNIZATION_RECORD(fields),
    options
  );

export const useUpdateImmunizationDocument = (
  options?: MutationHookOptions<IUpdateImmunizationDocumentData, IUpdateImmunizationDocumentVariables>,
  fields?: string
) =>
  useMutation<IUpdateImmunizationDocumentData, IUpdateImmunizationDocumentVariables>(
    UPDATE_IMMUNIZATION_DOCUMENT(fields),
    options
  );
