import { compact, noop } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import Alert from 'shared/components/Alert';
import Card from 'shared/components/Card';
import Checkbox from 'shared/components/Checkbox';
import { HorizontalDivider } from 'shared/components/Dividers';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Col, Row } from 'shared/components/Layout';
import PhoneInput from 'shared/components/PhoneInput';
import TextInput, { EmailInput } from 'shared/components/TextInput';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import CpavDetailedFee from '../CpavDetailedFee/CpavDetailedFee';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
const dateFormat = 'DD/MM/YYYY';

type CpavFormSubmit = IServiceCpavRequest;
type CpavFormData = Partial<CpavFormSubmit>;

interface IProps {
  data?: IServiceCpav;
  centerName?: string;
  isLoading: boolean;
  onSubmit?: (data: CpavFormSubmit) => void;
}

const CpavForm: React.FC<IProps> = ({ data, centerName, isLoading, onSubmit = noop }) => {
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [formData, setFormData] = useState<CpavFormData>({});

  const reloadLocalChanges = () => {
    setFormData({
      hasVacancyMon: data?.hasVacancyMon,
      hasVacancyTue: data?.hasVacancyTue,
      hasVacancyWed: data?.hasVacancyWed,
      hasVacancyThu: data?.hasVacancyThu,
      hasVacancyFri: data?.hasVacancyFri,
      hasVacancySat: data?.hasVacancySat,
      hasVacancySun: data?.hasVacancySun,
      feeServiceUrl: data?.feeServiceUrl,
      fees: data?.fees,
    });
    setFormIsDirty(false);
  };

  // Reload local changes whenever source data updates
  useEffect(reloadLocalChanges, [data]);

  const handleChange = (value: CpavFormData) => {
    setFormData({ ...formData, ...value });
    setFormIsDirty(true);
  };

  const handleFeeInclusionChange = (sessionType: CpavSessionType, ageGroup: ICpavAgeGroup) => {
    const tempFormData = { ...formData };
    (tempFormData.fees as Record<CpavSessionType, ICpavAgeGroup[]>)[sessionType] = (
      tempFormData.fees as Record<CpavSessionType, ICpavAgeGroup[]>
    )[sessionType].map((i) => {
      if (i.ageGroup === ageGroup.ageGroup) i.inclusions = ageGroup.inclusions;
      return i;
    });
    setFormData({ ...tempFormData });
    setFormIsDirty(true);
  };

  const getProps = <K extends keyof CpavFormData>(field: K) => ({
    value: formData[field],
    onChange: (value: any) => handleChange({ [field]: value }),
  });

  return (
    <FormWrapper2
      formId="cpav-details-form"
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onCancel={reloadLocalChanges} // Reload local changes on cancel
      onSave={() => onSubmit(formData as CpavFormSubmit)}
      toggleDirtyOnSave={true}
      loading={isLoading}
    >
      <Row className="mb-4" align="baseline">
        <Col xs={12}>
          <Alert variant="info" className="mb-2">
            Care provided and vacancy will next update on{' '}
            {`${moment(data?.nextAutomatedSubmission).format(dateFormat)}`}
          </Alert>
          {data && statusBadge(data?.lastUpdated, data?.lastSubmitted)}
        </Col>
      </Row>

      <InputLock lock={true}>
        <Row align="end">
          <Col xs={12} sm={6}>
            <TextInput id="cpav-center-name-input" label={`${fieldLabels.center} Name`} value={centerName} />
          </Col>
          <Col xs={12} sm={3}>
            <PhoneInput label="Phone Number" onChange={() => {}} value={data?.contactPhone ?? ''} />
          </Col>
          <Col xs={12} sm={3}>
            <PhoneInput label="Mobile Number" onChange={() => {}} value={data?.contactMobile ?? ''} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <EmailInput label="Email Address" value={data?.contactEmail} />
          </Col>
          <Col xs={12} sm={6}>
            <TextInput label="Website" value={data?.contactServiceUrl} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={3}>
            <TextInput label="Is service open?" value={data?.centerIsOpen ? 'Yes' : 'No'} />
          </Col>
        </Row>
      </InputLock>

      <Row>
        <Col>
          <HorizontalDivider />
        </Col>
      </Row>
      <Row align="stretch">
        <Col xs={12} sm={9}>
          <ServiceOperationalDayCard data={data?.openingHours} />
        </Col>
        <Col xs={12} sm={3}>
          <h6>Vacancies</h6>
          {vacancyListFields.map(({ day, field }, i) => (
            <Checkbox key={i} label={day} {...getProps(field)} />
          ))}
        </Col>
      </Row>

      <Row>
        <Col>
          <HorizontalDivider />
        </Col>
      </Row>

      <Row>
        <Col>
          <h6 className="mb-2">Fees</h6>
          <CpavDetailedFee
            cpavService={data}
            handleChange={(sessionType: CpavSessionType, ageGroup: ICpavAgeGroup) =>
              handleFeeInclusionChange(sessionType, ageGroup)
            }
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <HorizontalDivider />
        </Col>
      </Row>

      <Row align="end">
        <Col xs={12} sm={3}>
          <TextInput className="mb-0" label="Fee URL" {...getProps('feeServiceUrl')} />
        </Col>
        <Col align="center">
          Last submitted on {`${data?.lastSubmitted ? moment(data.lastSubmitted).format(dateFormat) : 'N/A'}`}
        </Col>
      </Row>
    </FormWrapper2>
  );
};

export default CpavForm;

const InputLock: React.FC<{ lock: boolean }> = ({ children, lock }) => {
  const elements = compact(React.Children.toArray(children));
  return (
    <>
      {elements.map((child) =>
        React.isValidElement(child) && (child.props.disabled ?? true) // Allow children to overide disabled
          ? React.cloneElement(
              child,
              // @ts-ignore
              { disabled: lock },
              <InputLock lock={lock}>{child.props.children}</InputLock>
            )
          : child
      )}
    </>
  );
};

const ServiceOperationalDayCard: React.FC<{
  data?: IServiceOperationalDay[];
}> = ({ data }) => {
  return (
    <Card className="bg-pale-grey">
      <Row align="start">
        <Table>
          <thead>
            <tr>
              <th className="border-0">Care Types Offered</th>
              <th className="border-0">Open Hours</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((x, i) => (
              <tr key={i}>
                <td>{x.careType}</td>
                <td>
                  {durationToFormattedTimestamp(x.openTime) ?? 'N/A'} -{' '}
                  {durationToFormattedTimestamp(x.closeTime) ?? 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Card>
  );
};

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;
type dayOfWeek = typeof daysOfWeek[number];

const vacancyListFields: Readonly<
  {
    day: dayOfWeek;
    field: keyof Omit<CpavFormData, 'feeServiceUrl' | 'fees'>;
  }[]
> = [
  { day: 'Monday', field: 'hasVacancyMon' },
  { day: 'Tuesday', field: 'hasVacancyTue' },
  { day: 'Wednesday', field: 'hasVacancyWed' },
  { day: 'Thursday', field: 'hasVacancyThu' },
  { day: 'Friday', field: 'hasVacancyFri' },
  { day: 'Saturday', field: 'hasVacancySat' },
  { day: 'Sunday', field: 'hasVacancySun' },
];

const statusBadge = (updatedDate: string, submittedDate?: string) => {
  const isProcessing = moment(updatedDate).isAfter(moment(submittedDate)) || !submittedDate;
  if (isProcessing) {
    return <Badge variant={'secondary'}>Queued for submission</Badge>;
  }
};

// These should really be timestamps in backend
function durationToFormattedTimestamp(durationString?: string) {
  if (!durationString) return;
  const duration = moment.duration(durationString);
  const hours = duration.hours();
  const minutes = duration.minutes();
  return moment().hours(hours).minutes(minutes).format('h:mma');
}
