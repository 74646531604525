export const reasonForLeavingEnum: Record<string, string> = {
  newJob: 'New Job',
  goingBackToSchool: 'Going Back To School',
  stayingHome: 'Staying Home',
  moving: 'Moving',
  terminated: 'Terminated',
  dissatisfied: 'Dissatisfied',
  noCallNoShow: 'No Call No Show',
  walkOut: 'Walk Out',
  temporary: 'Temporary',
  leaveOfAbsence: 'Leave Of Absence',
  resigned: 'Resigned',
};
