import {
  SET_FORM_VALUES,
  SET_FIELD_VALUE,
  UPDATE_EDUCATORS,
  UPDATE_EDUCATOR_F2F_HOURS,
  UPDATE_EDUCATOR_NONF2F_HOURS,
  ADD_TEMPORARY_EDUCATOR,
  DELETE_TEMPORARY_EDUCATOR,
  UPDATE_TEMPORARY_EDUCATOR_F2F_HOURS,
  UPDATE_TEMPORARY_EDUCATOR_NONF2F_HOURS,
  UPDATE_DAYS_OF_CARE_F2F_HOURS,
  UPDATE_DAYS_OF_CARE_NF2F_HOURS,
  UPDATE_DAYS_OF_CARE,
  RESET_CLAIM_FORM,
} from './types';

export const setFormValuesAction = (formValues: ISCaseClaimCreate) => ({
  type: SET_FORM_VALUES,
  formValues,
});

export const setFieldValueAction = (value: any, name: keyof ISCaseClaimCreate) => ({
  type: SET_FIELD_VALUE,
  value,
  name,
});

export const updateDaysOfCareAction = (index: number, day: DayOfCare) => ({
  type: UPDATE_DAYS_OF_CARE,
  index,
  day,
});

export const updateDaysOfCareF2FHours = (index: number, f2f: number) => ({
  type: UPDATE_DAYS_OF_CARE_F2F_HOURS,
  index,
  f2f,
});

export const updateDaysOfCareNF2FHours = (index: number, nf2f: number) => ({
  type: UPDATE_DAYS_OF_CARE_NF2F_HOURS,
  index,
  nf2f,
});

export const updateEducatorsAction = (educators: IBasicStaff[]) => ({
  type: UPDATE_EDUCATORS,
  educators,
});

export const updateEducatorNonF2FHoursAction = (educator: string, day: DayOfCare, nonFaceToFaceHours: number) => ({
  type: UPDATE_EDUCATOR_NONF2F_HOURS,
  educator,
  day,
  nonFaceToFaceHours,
});

export const updateEducatorF2FHoursAction = (educator: string, day: DayOfCare, faceToFaceHours: number) => ({
  type: UPDATE_EDUCATOR_F2F_HOURS,
  educator,
  day,
  faceToFaceHours,
});

export const updateTempEducatorNonF2FHoursAction = (educator: string, day: DayOfCare, nonFaceToFaceHours: number) => ({
  type: UPDATE_TEMPORARY_EDUCATOR_NONF2F_HOURS,
  educator,
  day,
  nonFaceToFaceHours,
});

export const updateTempEducatorF2FHoursAction = (educator: string, day: DayOfCare, faceToFaceHours: number) => ({
  type: UPDATE_TEMPORARY_EDUCATOR_F2F_HOURS,
  educator,
  day,
  faceToFaceHours,
});

export const addTempEducatorAction = () => ({
  type: ADD_TEMPORARY_EDUCATOR,
});

export const deleteTempEducatorAction = (index: number) => ({
  type: DELETE_TEMPORARY_EDUCATOR,
  index,
});

export const resetClaimForm = () => ({
  type: RESET_CLAIM_FORM,
});
