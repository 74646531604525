import { useGetArrangementTypeForChildrenInTimeFrameLazy } from 'gql/ccssEnrolment/queries';
import { uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export interface IGapWaiveEligibilityMap {
  [sessionId: string]: {
    isWaivable: boolean;
    isNonSubsidized: boolean;
    arrangementType: ArrangementTypeDto | null | undefined; // can be null because US doesn't have CCS enrolment
  };
}

export interface IChildrenEnrolmentTypeMap {
  [childId: string]: ArrangementTypeDto | null | undefined;
}

const useGapWaiveEligibleChecker = (
  sessions: ISession[],
  centerId: string,
  startDate: string,
  endDate: string,
  region: string
) => {
  const [gapWaiveEligibilityMap, setGapWaiveEligibilityMap] = useState<IGapWaiveEligibilityMap>({});
  const [childrenEnrolmentTypeMap, setChildrenEnrolmentTypeMap] = useState<IChildrenEnrolmentTypeMap>({});
  const childIds = useMemo(() => uniq(sessions.map((s) => s.childId)), [sessions]);
  const [getArrangementTypeForChildrenInTimeFrameFn, { data: arrangementTypeData, loading, error, called }] =
    useGetArrangementTypeForChildrenInTimeFrameLazy({
      variables: {
        centerId,
        childIds,
        startDate,
        endDate,
      },
    });

  useEffect(() => {
    if (region === 'AU' && childIds.length > 0) {
      getArrangementTypeForChildrenInTimeFrameFn();
    }

    if (region !== 'AU') {
      setGapWaiveEligibilityMap(
        sessions.reduce(
          (acc, session) => ({
            ...acc,
            [session.id]: { isWaivable: true, arrangementType: null, isNonSubsidized: false },
          }),
          {}
        )
      );
    }
  }, [region, getArrangementTypeForChildrenInTimeFrameFn, sessions]);

  useEffect(() => {
    // for AU, only CWA and non-subsidized sessions are eligible for waive
    if (called && !loading && arrangementTypeData && !error) {
      const arrangementTypeDtos = arrangementTypeData.getArrangementTypeForChildrenInTimeFrame;
      const sessionsCanBeWaived = sessions.filter((s) => {
        return (
          !s.nonSubsidized && arrangementTypeDtos.some((at) => at.childId === s.childId && at.arrangementType === 'CWA')
        );
      });

      setGapWaiveEligibilityMap(
        sessionsCanBeWaived.reduce((acc, session) => {
          const arrangementTypeDto = arrangementTypeDtos.find((at) => at.childId === session.childId);

          return {
            ...acc,
            [session.id]: {
              isWaivable: !session.nonSubsidized && arrangementTypeDto?.arrangementType === 'CWA',
              isNonSubsidized: session.nonSubsidized,
              arrangementType: arrangementTypeDto,
            },
          };
        }, {})
      );

      setChildrenEnrolmentTypeMap(arrangementTypeDtos.reduce((acc, at) => ({ ...acc, [at.childId]: at }), {}));
    }
  }, [called, arrangementTypeData, loading, error, sessions]);

  return {
    loading,
    gapWaiveEligibilityMap,
    childrenEnrolmentTypeMap,
  };
};

export default useGapWaiveEligibleChecker;
