import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { apiKeyWebHookFields } from './fields';

interface IApiKeyWebHook {
  id: string;
  apiKeyId: string;
  createdBy: string;
  businessId: string;
  createdAt: string;
  url: string;
  webHookType: number;
  webHookApiKey: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
}

interface ICreateApiKeyWebHookInput {
  apiKeyId: string;
  businessId: string;
  url: string;
  webHookType: string;
  webHookApiKey: string;
}

interface IUpdateApiKeyWebHookInput {
  id: string;
  apiKeyId: string;
  businessId: string;
  url: string;
  webHookType: string;
  webHookApiKey: string;
}

export const CREATE_API_KEY_WEB_HOOK = gql`
  mutation($input: CreateApiKeyWebHookInput ) {
    createApiKeyWebHook(input: $input) {
      ${apiKeyWebHookFields}
    }
  }
`;

export const UPDATE_API_KEY_WEB_HOOK = gql`
  mutation($input: UpdateApiKeyWebHookInput ) {
    updateApiKeyWebHook(input: $input) {
      ${apiKeyWebHookFields}
    }
  }
`;

export const useCreateApiKeyWebHook = (
  input?: MutationHookOptions<
    {
      createApiKeyWebHook: IApiKeyWebHook;
    },
    { input: ICreateApiKeyWebHookInput }
  >
) =>
  useMutation<
    {
      createApiKeyWebHook: IApiKeyWebHook;
    },
    { input: ICreateApiKeyWebHookInput }
  >(CREATE_API_KEY_WEB_HOOK, input);

export const useUpdateApiKeyWebHook = (
  input?: MutationHookOptions<
    {
      updateApiKeyWebHook: IApiKeyWebHook;
    },
    { input: IUpdateApiKeyWebHookInput }
  >
) =>
  useMutation<
    {
      updateApiKeyWebHook: IApiKeyWebHook;
    },
    { input: IUpdateApiKeyWebHookInput }
  >(UPDATE_API_KEY_WEB_HOOK, input);
