import React, { useState, useEffect, useCallback, useMemo, useRef, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Select, { components, MenuListComponentProps } from 'react-select';
import useUniqueId from 'shared/hooks/useUniqueId';
import Tooltip from 'shared/components/Tooltip';
import MenuItem from './MenuItem';
import colors from '_colors.module.scss';
import 'shared/components/Select/_select.scss';
import cast from 'shared/util/cast';

// only show a search input if there are more than 10 options to select from
const NUMBER_OF_ITEMS_REQUIRED_FOR_SEARCH = 10;

interface IProps {
  dropdownId?: string;
  title: string;
  selectedFilters?: ITableFilterOption[] | string[];
  options: ITableFilterOption[];
  placeholder?: string;
  onFilterSelect: (values: ITableFilterOption[]) => void;
  className?: string;
  alwaysShowTitle?: boolean;
  showToolTip?: boolean;
  menuClassName?: string;
  alignRight?: boolean;
  disabled?: boolean;
}

// NOTE: look at the async documentation for react-select, we may need it

const DropdownFilter: React.FC<IProps> = ({
  dropdownId,
  title,
  selectedFilters: sfs = [],
  options,
  onFilterSelect,
  placeholder = 'Filter...',
  className,
  menuClassName = '',
  alwaysShowTitle = false,
  showToolTip = true,
  alignRight = true,
  disabled = false,
  ...props
}) => {
  const selectedFilters: ITableFilterOption[] = sfs.some((sf: any) => typeof sf === 'string')
    ? options.filter((o) => cast<string>(sfs).includes(o.value))
    : cast<ITableFilterOption[]>(sfs);

  const _dropdownId = useUniqueId(dropdownId);
  const [showDropdown, setShowDropdown] = useState(false);
  /**
   * Since the react-select menu has an absolute position, it's taken out of the dropdown menu's context
   * and doesn't cause it to expand if the menu width expands. To get around this we are passing a ref to
   * the react select menu to get its width. Whenever the dropdown menu is toggled to open, we get the width
   * of the ref and set it in state, allowing the dropdown menu to have the same width as the menu
   */
  const [dropdownMenuWidth, setDropdownMenuWidth] = useState<number | undefined>(undefined);
  const selectMenuRef = useRef<HTMLDivElement | null>();

  useEffect(() => {
    if (showDropdown) {
      setDropdownMenuWidth(selectMenuRef.current?.clientWidth);
    }
  }, [showDropdown]);

  const toggleDropdown = useCallback((show: boolean) => {
    setShowDropdown(show);
  }, []);

  const toggleText = useMemo(() => {
    if (alwaysShowTitle) {
      return {
        short: title || '',
        full: '',
      };
    }
    if (selectedFilters.length > 1) {
      /**
       * if there is more than 1 filter selected, show the text of the first selected filter and then '(+ N)
       * ex: "Active (+2)"
       */
      const additionalFilterCount = selectedFilters.length - 1;

      return {
        short: `(+${additionalFilterCount}) ${selectedFilters[0].label}`,
        full: selectedFilters.map((filter) => filter.label).join(', '),
      };
    } else if (selectedFilters.length === 1) {
      // if one filter is selected show that option's label
      return {
        short: selectedFilters[0].label,
        full: '',
      };
    } else {
      // if no filters are selected, use the provided title
      return {
        short: title || '',
        full: '',
      };
    }
  }, [title, selectedFilters]);

  const Control = useCallback(
    (props) => (options.length >= NUMBER_OF_ITEMS_REQUIRED_FOR_SEARCH ? <components.Control {...props} /> : null),
    [options.length]
  );

  const memoizedMenuListScrollTop = useRef<number>(0);

  const MenuList = (props: MenuListComponentProps<ITableFilterOption, boolean>) => {
    const menuListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const onScroll = () => {
        memoizedMenuListScrollTop.current = menuListRef.current?.scrollTop ?? 0;
      };

      if (menuListRef.current) {
        menuListRef.current.addEventListener('scroll', onScroll);
      }

      return () => {
        if (menuListRef.current) {
          menuListRef.current.removeEventListener('scroll', onScroll);
        }
      };
    }, []);

    // need to use useLayoutEffect to ensure the menu list is scrolled to the previous position before re-rendering to prevent jumpy scrolling
    useLayoutEffect(() => {
      if (menuListRef.current) {
        menuListRef.current.scrollTop = memoizedMenuListScrollTop.current;
      }
    }, [selectedFilters]);

    return (
      <>
        <components.MenuList {...props} innerRef={menuListRef} />
        <Dropdown.Divider className="mb-0" />
        <div className="d-flex justify-content-end align-items-center p-2">
          <Button variant="light" disabled={selectedFilters.length === 0} onClick={() => onFilterSelect([])}>
            <FontAwesomeIcon icon={faTimes} className="flex-grow-1" color={colors.iconColor} />
            <span className="ml-2">Clear</span>
          </Button>
        </div>
      </>
    );
  };

  const Menu = useCallback((props) => <components.Menu {...props} innerRef={selectMenuRef} />, []);
  const hasValue = selectedFilters.length >= 1;
  return (
    <Dropdown id={_dropdownId} show={showDropdown} onToggle={toggleDropdown} className={className}>
      <Dropdown.Toggle id={_dropdownId} className="k2-datatable-dropdown-toggle" as="button" disabled={disabled}>
        <Tooltip text={toggleText.full} direction="bottom" showTooltip={toggleText.full.length > 0 && showToolTip}>
          <div className="d-flex flex-row flex-grow-1 align-items-center">
            <span className={`flex-grow-1 k2-datatable-dropdown-selected-text ${hasValue ? 'with-selected' : ''}`}>
              {toggleText.short}
            </span>
            <FontAwesomeIcon icon={faChevronDown} color={colors.iconColor} className="ml-6" />
          </div>
        </Tooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu
        alignRight={alignRight}
        className={`k2-datatable-dropdown-menu mt-1 ${menuClassName ?? ''}`}
        // override the default min-width provided with .dropdown-menu
        style={{ minWidth: dropdownMenuWidth }}
      >
        <Select
          isMulti
          autoFocus
          menuIsOpen
          className="react-select-container"
          classNamePrefix="react-select"
          value={selectedFilters}
          components={{
            DropdownIndicator: null,
            Control,
            Menu,
            MenuList,
            Option: MenuItem,
          }}
          backspaceRemovesValue={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={true}
          tabSelectsValue={false}
          options={options}
          placeholder={placeholder}
          //@ts-ignore
          onChange={(values: ITableFilterOption[] | null) => {
            if (values === null) {
              onFilterSelect([]);
            } else {
              onFilterSelect(values);
            }
            //onFilterSelect
          }} // bubble up select values to parent
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownFilter;
