import React, { useState } from 'react';
import { useGetUnbatchedPaymentTransactions } from 'gql/batch/queries';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import PaymentTransactionsTable from '../components/PaymentTransactionsTable';
import { getAllUnbatchedPayments, selectPaymentsForBatching, setCenterIdToBatch } from '../duck/actions';
import CreateBatchModal from './CreateBatchModal';
import Select from 'shared/components/Select';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface IProps {
  showCreateBatchModal: boolean;
  onCloseCreateBatchModal: () => void;
}

const UnbatchedPaymentsTab: React.FC<IProps> = ({ showCreateBatchModal, onCloseCreateBatchModal, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['billing']);
  const [centerDropdownUntouched, setCenterDropdownUntouch] = useState(true);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const centerIdToBatch = useSelector((state: RootState) => state.billing.paymentBatches.centerIdToBatch);
  const unbatchedPaymentTransactionsTable = useSelector(
    (state: RootState) => state.billing.paymentBatches.unbatchedPaymentTransactions
  );

  const selectedPaymentsToBatch = useSelector(
    (state: RootState) => state.billing.paymentBatches.paymentTransactionsSelectedForBatching
  );

  const [timeframe, setTimeframe] = useState<ITimeRange>({
    start: moment().startOf('month').format(),
    end: moment().endOf('month').format(),
  }); // we show all unbatched payments by default
  const { data: centers, loading: centersLoading } = useGetActiveCentersWithLoading();

  const { loading } = useGetUnbatchedPaymentTransactions({
    variables: {
      input: {
        businessId: currentBusinessId ?? '',
        centerIds: centerIdToBatch ? [centerIdToBatch] : undefined,
        start: timeframe.start,
        end: timeframe.end,
      },
    },
    skip:
      !currentBusinessId ||
      centerDropdownUntouched ||
      (timeframe.start !== '' && timeframe.end === '') ||
      (timeframe.start === '' && timeframe.end !== ''),
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => {
      dispatch(getAllUnbatchedPayments(result.getUnbatchedTransactionPayments.transactions));
    },
  });

  return (
    <>
      <div className="d-flex align-items-center bg-baby-blue py-2 px-4 rounded">
        <div>{t('billing:transactions.create-batch-modal.select-center-to-create')}</div>
        <div className="ml-auto" style={{ width: 240 }}>
          <Select
            options={[
              { label: 'All', value: 'all' },
              ...(centers
                ?.map((c) => ({ value: c.id, label: c.name }))
                .sort((a, b) => a.label.localeCompare(b.label)) ?? []),
            ]}
            onChange={(option) => {
              dispatch(selectPaymentsForBatching([]));
              if (centerDropdownUntouched) setCenterDropdownUntouch(false);
              if (option.value === 'all') {
                dispatch(setCenterIdToBatch(null!)); // it means ALL centres
              } else {
                dispatch(setCenterIdToBatch(option.value));
              }
            }}
            value={
              [{ label: 'All', value: 'all' }, ...(centers ?? []).map((c) => ({ value: c.id, label: c.name }))].filter(
                (opt) => opt.value === centerIdToBatch
              )[0]
            }
            className="mb-0 flex-grow-0"
            isLoading={centersLoading}
          />
        </div>
      </div>
      <PaymentTransactionsTable
        isPaymentTransactionsOnly
        isUnbatchedPaymentTransactionsOnly
        showSelect={centerIdToBatch !== null}
        data={unbatchedPaymentTransactionsTable.filter((t) =>
          centerIdToBatch !== null ? t.account.centerId === centerIdToBatch : true
        )}
        loading={loading || centersLoading}
        dateRange={timeframe}
        setDateRange={setTimeframe}
        onPaymentStatusFilter={() => {}}
        onStatusTileSelected={() => {}}
        onSelect={(paymentTransactions) => dispatch(selectPaymentsForBatching(paymentTransactions))}
        showPaymentFilterCards={false}
        selectedRows={selectedPaymentsToBatch}
        showCenterFilter={false}
        noPadding
      />
      <CreateBatchModal isOpen={showCreateBatchModal} onClose={onCloseCreateBatchModal} />
    </>
  );
};

export default UnbatchedPaymentsTab;
