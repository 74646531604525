export const ONBOARDING_STEPS = {
  completeProfile: 0,
  addCenter: 1,
  setupClass: 2,
  inviteEmployee: 3,
  addFamily: 4,
};

export const ONBOARDING_STEPS_PER_ROLE = {
  'Account Owner': [
    ONBOARDING_STEPS.completeProfile,
    ONBOARDING_STEPS.addCenter,
    ONBOARDING_STEPS.setupClass,
    ONBOARDING_STEPS.inviteEmployee,
  ],
  Administrator: [
    ONBOARDING_STEPS.completeProfile,
    ONBOARDING_STEPS.addCenter,
    ONBOARDING_STEPS.setupClass,
    ONBOARDING_STEPS.inviteEmployee,
  ],
  Manager: [ONBOARDING_STEPS.completeProfile, ONBOARDING_STEPS.setupClass, ONBOARDING_STEPS.inviteEmployee],
  'Assistant Manager': [ONBOARDING_STEPS.completeProfile, ONBOARDING_STEPS.inviteEmployee],
  Staff: [ONBOARDING_STEPS.completeProfile],
};
