import { useSelector } from 'react-redux';
import { useMemo } from 'react';

/**
 * Convenience wrapper for obtaining a jwtToken
 * @returns
 */
export function useGetCurrentJwt() {
  return useSelector((state: { session: { token: string } }) => state.session.token);
}

/**
 * Convenience wrapper for obtaining a well formatted BearerToken that can be passed into the http authorization header
 */
export function useGetBearerToken(): string {
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);
  const bearerToken = useMemo(() => {
    return `Bearer ${jwtToken}`;
  }, [jwtToken]);
  return bearerToken;
}
