import React, { useMemo } from 'react';
import Avatar from 'shared/components/Avatar';
import { getInitials, stringToHueDegree } from 'shared/util/string';

interface IProps {
  person: IPerson | IChild | IContactChild | IAccountChild;
  size?: avatarSizes;
  className?: string;
  onClick?: () => void;
}

const PersonAvatar: React.FC<IProps> = ({ person, size = 'sm', className, onClick }) => {
  const personString = useMemo(() => {
    if (!person) return '';
    return person.id ?? '';
  }, [person]);
  const image = useMemo(() => {
    if (!person) return '';
    return person.avatar?.url ?? '';
  }, [person]);
  return (
    <Avatar
      onClick={onClick}
      className={`${onClick ? 'cursor-pointer' : ''} ${className}`}
      color={`hsl(${stringToHueDegree(personString)}, ${stringToHueDegree(personString) < 50 ? '100%' : '40%'}, 40%`}
      size={size}
      image={image}
      initials={getInitials(person)}
    ></Avatar>
  );
};

export default PersonAvatar;
