import colors from '_colors.module.scss';

export const ccsEnrollmentStatus: Record<string, { value: CcsEnrollmentStatus; label: string }> = {
  APPROV: { value: 'APPROV', label: 'Approved' },
  CEASED: { value: 'CEASED', label: 'Ceased' },
  CONFIR: { value: 'CONFIR', label: 'Confirmed' },
  DISPUT: { value: 'DISPUT', label: 'Disputed' },
  MANUAL: { value: 'MANUAL', label: 'Manual Review' },
  NOTAPP: { value: 'NOTAPP', label: 'Not Approved' },
  PENDEL: { value: 'PENDEL', label: 'Pending Eligibility' },
  PENDIN: { value: 'PENDIN', label: 'Pending Confirmation' },
  RECEIV: { value: 'RECEIV', label: 'Received' },
  REJECT: { value: 'REJECT', label: 'Rejected' },
  WITHDR: { value: 'WITHDR', label: 'Withdrawn' },
};

export const ccsTypeEnum: { value: string; label: string }[] = [
  { value: 'CWA', label: 'Complying Written Arrangement (CCS)' },
  { value: 'RA', label: 'Relevant Arrangement (Do not want CCS)' },
  { value: 'ACCS', label: 'Child Well-being (ACCS)' },
  { value: 'OA', label: 'Arrangement with Organisation' },
];

export const ccsTypeEnum2: { value: string; label: string }[] = [
  { value: 'CWA', label: 'Complying Written Arrangement (CCS)' },
  { value: 'RA', label: 'Relevant Arrangement (Do not want CCS)' },
  { value: 'PEA', label: 'Provider Eligible Arrangement (PEA)' },
  { value: 'OA', label: 'Arrangement with Organisation' },
];

export const EnrolmentCategoryFormatting: Record<EnrolmentCategory, { color: string; colorName: string }> = {
  ActionRequired: { color: colors.danger, colorName: 'danger' },
  Active: { color: colors.success, colorName: 'success' },
  Inactive: { color: colors['dark-gray'], colorName: 'dark-gray' },
  Pending: { color: colors.warning, colorName: 'warning' },
};

export const AbsenseCategoryFormatting: Record<CcsAbsencesCategory, { color: string; colorName: string }> = {
  AtRisk: { color: colors.danger, colorName: 'danger' },
  NearingLimit: { color: colors.warning, colorName: 'warning' },
  InTheClear: { color: colors.success, colorName: 'success' },
};

export const ccsCertificateStatusEnum: {
  value: CcsCertificateStatus;
  label: string;
}[] = [
  { value: 'APPROV', label: 'Approved' },
  { value: 'CANCEL', label: 'Cancelled' },
  { value: 'INEFF', label: 'Ineffective' },
  { value: 'REJECT', label: 'Rejected' },
];

export const ccsAbsenceCategoryEnum: {
  value: CcsAbsencesCategory;
  label: string;
}[] = [
  { value: 'AtRisk', label: 'At Risk' },
  { value: 'NearingLimit', label: 'Nearing Limit' },
  { value: 'InTheClear', label: 'In the Clear' },
];

export const ccsStateTerritoryBodyEnum: {
  value: StateTerritoryBodyType;
  label: string;
}[] = [
  { value: 'CODE01', label: 'Parenting Assist, Family Support Program' },
  { value: 'CODE02', label: 'Conflict/Separation/Mediation Services' },
  { value: 'CODE03', label: 'Child and Maternal Health Services' },
  { value: 'CODE04', label: 'Drug/Substance Abuse Services' },
  { value: 'CODE05', label: 'Community Health Services' },
  { value: 'CODE06', label: 'Domestic Violence/Rape Victim Support' },
  { value: 'CODE07', label: 'Homelessness, Crisis or Public Housing' },
  { value: 'CODE08', label: 'Financial/Gambling Counselling Services' },
  { value: 'CODE09', label: 'Aboriginal Torres Strait Services' },
  { value: 'CODE10', label: 'School Education Related Services' },
  { value: 'CODE11', label: 'Other Early Intervention Services' },
  { value: 'CODE12', label: 'Child Protection Agency' },
];

export const exceptionalCircumstanceReasons: { value: string; label: string }[] = [
  { value: 'BEYOND', label: 'Delay in evidence beyond Provider’s control' },
  { value: 'OTHER', label: 'Other' },
];

export const extensionReasons: { label: string; value: string }[] = [
  { label: 'The child is on long term protection order', value: 'LONGPO' },
  { label: 'The child is in formal foster care/kinship care', value: 'FOSKIN' },
];
export const ReportStatusToDisplayName: Record<string, string> = {
  C: 'Confirmed',
  P: 'Pending',
};

export const DecisionMakingAgencyToDisplayName: Record<string, string> = {
  SA: 'Services Australia',
  DESE: 'Department of Education, Skills and Employment',
};

export const ccsRiskReasons: {
  value: RiskReason;
  label: string;
}[] = [
  { value: 'COSTB', label: 'Under care and protection of a State/Territory child protection legislation' },
  { value: 'ABUSED', label: 'Suffering harm due to experiencing physical, emotional or psychological abuse' },
  { value: 'SABUSE', label: 'Is being subjected to sexual abuse' },
  { value: 'DOMVIO', label: 'Suffering as a result of exposure to domestic or family violence' },
  { value: 'NEGLEC', label: 'Suffering, or is at risk of suffering harm caused by neglect' },
  { value: 'ABPAST', label: 'Suffered harm due to experiencing physical, emotional or psychological abuse' },
  { value: 'SAPAST', label: 'Suffered harm in the past due to being subjected to sexual abuse' },
  { value: 'VIPAST', label: 'Suffered harm in the past due to exposure to domestic or family violence' },
  { value: 'NEGPST', label: 'Suffered harm in the past due to experiencing neglect' },
];

export const accsDocumentTypeEnum: {
  value: AccsDocumentType;
  label: string;
}[] = [
  { value: 'ACC002', label: 'Child Well Being Evidence' },
  { value: 'ACC003', label: 'State/Territory notice (204K notice)' },
  { value: 'ACC004', label: 'Child Well Being other supporting documents' },
  { value: 'ACC005', label: 'Child no longer at risk (67FC)' },
  { value: 'ACC006', label: 'Provider related document' },
  { value: 'ACC007', label: 'Service related document' },
  { value: 'ACC008', label: 'Evidence of exceptional circumstance' },
];

export const ccsDeterminationCategoryEnum: {
  value: CcssDeterminationCategory | null;
  label: string;
}[] = [
  { value: 'REJECT', label: 'Rejected' },
  { value: 'APPROV', label: 'Approved' },
  { value: 'REVOKE', label: 'Revocation' },
  { value: null, label: 'Pending' },
];

export const accsCategoryFormatting: Record<AccsDisplayCategory, { color: string; colorName: string }> = {
  pending: { color: colors.warning, colorName: 'warning' },
  active: { color: colors.success, colorName: 'success' },
  expiring: { color: colors.info, colorName: 'info' },
  expired: { color: colors.danger, colorName: 'danger' },
};

export const accsCategoryLabel: Record<string, { value: AccsDisplayCategory; label: string }> = {
  PENDING: { value: 'pending', label: 'Pending' },
  ACTIVE: { value: 'active', label: 'Active' },
  EXPIRING: { value: 'expiring', label: 'Expiring' },
  EXPIRED: { value: 'expired', label: 'Expired' },
};

export const accsApplicationTypeEnum: {
  value: string;
  label: string;
}[] = [
  { value: 'CERT', label: 'Certificate' },
  { value: 'APPL', label: 'Application' },
  { value: 'NOACTN', label: 'No action required' },
];

export const peaReasonOptions: readonly {
  readonly value: PeaReason;
  readonly label: string;
}[] = [
  {
    label: 'Service not able to identify a CCS eligible carer',
    value: 'NOCARE',
  },
  { label: 'Child is in formal foster/kinship care', value: 'FOSKIN' },
];

export const ccsEventTypesOptions: { label: string; value: string }[] = [
  { label: 'Local Emergency', value: 'LE' },
  { label: "Minister's Rules", value: 'MR' },
];
