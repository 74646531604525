import { roleFieldsWithStaff } from 'gql/role/fields';
import { useUpdateRole } from 'gql/role/mutations';
import { orderBy } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import RBButton from 'react-bootstrap/Button';
import RBButtonGroup from 'react-bootstrap/ButtonGroup';
import RBCard from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Button from 'shared/components/Buttons';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import PageWrapper from 'shared/components/PageWrapper';
import Select from 'shared/components/Select';
import { showToast } from 'shared/components/Toast';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useGetRolesForBusiness } from 'shared/hooks/useGetRolesForBusiness';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { isRegion } from 'shared/util/region';
import { capitalize, getFullName } from 'shared/util/string';
import { RootState } from 'store/reducers';
import RoleAssignedStaffTable from './RoleAssignedStaffTable';
import RolePermissionGroup from './RolePermissionGroup';
import { useFlags } from 'launchdarkly-react-client-sdk';

enum PermissionCategory {
  General,
  RecordKeeping,
  People,
  Money,
  Activities,
  Comms,
  Enrollment,
}

type RoleViewType = 'permissions' | 'staff';

interface IRouteProps {
  role?: IRole;
  view?: RoleViewType;
}

interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const RolePermissions: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const { t } = useTranslation(['translation', 'permissions']);
  const [role, setRole] = useState<IRole | undefined>(props.location.state.role);
  const [rolePermissions, setRolePermissions] = useState<IRoleAreaLevel[]>(props.location.state.role?.areaLevels ?? []);
  const [viewType, setViewType] = useState<RoleViewType>(props.location.state.view ?? 'permissions');
  const hasEditRolePermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Roles,
    level: RoleLevelType.Edit,
  });

  const isAuRegion = isRegion('AU');
  const auOnlyPermissions: PermissionType[] = [PermissionType.EndOfCare];
  const businessUsesComms = useSelector((state: RootState) => state.comms.usesComms);

  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const { data: getRolesForBusinessData } = useGetRolesForBusiness(currentBusinessId ?? '', roleFieldsWithStaff, {
    onCompleted: (result) => {
      const updatedCacheRole = result.getRolesForBusiness.filter(
        (role) => role.id === props.location?.state?.role?.id
      )[0];

      if (updatedCacheRole) {
        setRole(updatedCacheRole);
        setRolePermissions(updatedCacheRole.areaLevels);
      }
    },
  });

  const [updateRoleFn, { loading: updateRoleLoading }] = useUpdateRole({
    onCompleted: (result) => {
      setRolePermissions(result.updateRole.areaLevels);
      showToast(t('permissions:successful-role-permission-update-toast'), 'success');
      // document.location.reload();
    },
    onError: (err) => {
      showToast(t('permissions:unsuccessful-role-permission-update-toast'), 'error');
    },
  });

  useEffect(() => {
    // if there is not a role in the router state, redirect them to the roles screen
    if (!props.location.state.role) {
      history.replace('/settings/roles');
    } else {
      setRole(props.location.state.role);
      setRolePermissions(props.location.state.role.areaLevels);
    }
  }, [history, props.location.state.role]);

  const handlePermissionLevelChange = useCallback((permission: IRoleAreaLevel, level: RoleLevelType) => {
    let allowedPermissionLevels: RoleLevelType[];

    switch (level) {
      case RoleLevelType.None:
        allowedPermissionLevels = [RoleLevelType.None];
        break;
      case RoleLevelType.Read:
        allowedPermissionLevels = [RoleLevelType.Read];
        break;
      case RoleLevelType.Edit:
        allowedPermissionLevels = [RoleLevelType.Read, RoleLevelType.Edit];
        break;
      case RoleLevelType.Create:
        allowedPermissionLevels = [RoleLevelType.Read, RoleLevelType.Edit, RoleLevelType.Create];
        break;
      case RoleLevelType.Delete:
        allowedPermissionLevels = [RoleLevelType.Read, RoleLevelType.Edit, RoleLevelType.Create, RoleLevelType.Delete];
        break;
      default:
        allowedPermissionLevels = [RoleLevelType.None];
        break;
    }

    setRolePermissions((prev) =>
      prev.map((al) => ({
        ...al,
        level:
          al.area === permission.area && al.permission === permission.permission
            ? allowedPermissionLevels.reduce((acc, curr) => (acc |= curr), 0)
            : al.level,
      }))
    );
  }, []);

  const savePermissionChanges = useCallback(
    (role: IRole, areaLevels: IRoleAreaLevel[]) => {
      updateRoleFn({
        variables: {
          input: {
            roleId: role.id,
            name: role.name.trim(),
            hierarchyLevel: role.hierarchyLevel,
            areaLevels: areaLevels.map((al) => ({
              roleId: al.roleId,
              area: al.area,
              permission: al.permission,
              level: al.level,
            })),
          },
        },
      });
    },
    [updateRoleFn]
  );

  const revertPermissionLevelChanges = useCallback(() => {
    setRolePermissions(role?.areaLevels ?? []);
  }, [role]);

  const groupedPermissionsByCategory = useCallback(
    (permissions: IRoleAreaLevel[]): Record<PermissionCategory, IRoleAreaLevel[]> => {
      const groups: Record<PermissionCategory, IRoleAreaLevel[]> = {
        [PermissionCategory.General]: [],
        [PermissionCategory.People]: [],
        [PermissionCategory.RecordKeeping]: [],
        [PermissionCategory.Money]: [],
        [PermissionCategory.Activities]: [],
        [PermissionCategory.Comms]: [],
        [PermissionCategory.Enrollment]: [],
      };

      permissions.forEach((areaLevel: IRoleAreaLevel) => {
        switch (areaLevel.area) {
          case AreaType.Business:
          case AreaType.Center:
          case AreaType.Report:
          case AreaType.Operations:
            groups[PermissionCategory.General].push(areaLevel);
            break;
          case AreaType.Attendance:
          // case AreaType.Enrollment:
          case AreaType.Kiosk:
          case AreaType.Schedule:
            groups[PermissionCategory.RecordKeeping].push(areaLevel);
            break;
          case AreaType.Enrollment:
            if (['EndOfCare', 'Contracts'].includes(areaLevel.permission))
              groups[PermissionCategory.RecordKeeping].push(areaLevel);
            else groups[PermissionCategory.Enrollment].push(areaLevel);
            break;
          case AreaType.Child:
            if (areaLevel.permission === PermissionType.Medical) {
              break;
            } else {
              groups[PermissionCategory.People].push(areaLevel);
            }
            break;
          case AreaType.Account:
          case AreaType.Contact:
          case AreaType.Staff:
            groups[PermissionCategory.People].push(areaLevel);
            break;
          case AreaType.Agency:
            if (areaLevel.permission === PermissionType.AgencyMain) {
              groups[PermissionCategory.General].push(areaLevel);
            } else {
              groups[PermissionCategory.People].push(areaLevel);
            }
            break;
          case AreaType.Billing:
          case AreaType.Statement:
            groups[PermissionCategory.Money].push(areaLevel);
            break;
          case AreaType.Activities:
            groups[PermissionCategory.Activities].push(areaLevel);
            break;
          case AreaType.Comms:
            groups[PermissionCategory.Comms].push(areaLevel);
            break;
          default:
            break;
        }
      });

      Object.keys(groups).forEach((key: string) => {
        const _key = parseInt(key, 10) as PermissionCategory;
        const group = groups[_key].filter(
          (item) => isAuRegion || auOnlyPermissions.every((permission) => permission !== item.permission)
        );

        groups[_key] = orderBy(
          group,
          [(areaLevel) => areaLevel.area, (areaLevel) => areaLevel.permission],
          ['asc', 'asc']
        );
      });

      return groups;
    },
    []
  );

  const handleRoleSelect = useCallback(
    (role: IRole) => {
      // .replace instead of .push because the user would expect the back button to take them back to the hierarchy screen
      history.replace({
        pathname: `/settings/roles/${role.id}`,
        state: { role, view: 'permissions' },
      });
    },
    [history]
  );

  return (
    <PageWrapper pageTitle={`${role?.name ?? ''} ${capitalize(t('translation:spelling.permissions'))}`}>
      <div className="mb-4 kt-role-permission-view-control-container">
        <RBButtonGroup>
          <RBButton
            onClick={() => setViewType('permissions')}
            variant={viewType === 'permissions' ? 'secondary' : 'outline-secondary'}
          >
            {capitalize(t('translation:spelling.view'))} {capitalize(t('translation:spelling.permissions'))}
          </RBButton>
          <RBButton
            onClick={() => setViewType('staff')}
            variant={viewType === 'staff' ? 'secondary' : 'outline-secondary'}
          >
            {capitalize(t('translation:spelling.view'))} {capitalize(t('translation:spelling.staff'))}
          </RBButton>
        </RBButtonGroup>
        <div className="kt-role-permission-role-select-container">
          <Select
            value={(getRolesForBusinessData?.getRolesForBusiness ?? []).find((r) => r.id === role?.id ?? '')}
            options={orderBy(getRolesForBusinessData?.getRolesForBusiness ?? [], (role) => role.name, 'asc')}
            onChange={handleRoleSelect}
            getOptionValue={(option: IRole) => option.id}
            getOptionLabel={(option: IRole) => option.name}
            className="mb-0"
          />
        </div>
      </div>
      {viewType === 'permissions' && (
        <Fragment>
          {Object.entries(groupedPermissionsByCategory(rolePermissions)).map(
            ([key, value], idx) =>
              ((key === '5' && businessUsesComms) || key !== '5') && (
                <RolePermissionGroup
                  key={`permission-group-${key}-${idx}`}
                  // @ts-ignore - Typescript does not like the dynamic lookup here
                  permissionGroupLabel={t(`permissions:group-labels.${PermissionCategory[key].toLowerCase()}`)}
                  canEditRole={hasEditRolePermission}
                  permissions={value}
                  onPermissionLevelChange={handlePermissionLevelChange}
                />
              )
          )}
          <HasRoleAreaLevel
            action={{
              area: AreaType.Business,
              permission: PermissionType.Roles,
              level: RoleLevelType.Edit,
            }}
          >
            <RBCard className="m-0">
              <RBCard.Body className="p-2 d-flex justify-content-end">
                <Button
                  className="mr-4"
                  variant="light"
                  onClick={revertPermissionLevelChanges}
                  disabled={updateRoleLoading}
                >
                  {capitalize(t('translation:spelling.cancel'))}
                </Button>
                <Button
                  disabled={updateRoleLoading}
                  loading={updateRoleLoading}
                  onClick={() => savePermissionChanges(role as IRole, rolePermissions)}
                >
                  {capitalize(t('translation:spelling.save'))}
                </Button>
              </RBCard.Body>
            </RBCard>
          </HasRoleAreaLevel>
        </Fragment>
      )}
      {viewType === 'staff' && (
        <RoleAssignedStaffTable
          data={role?.staff ?? []}
          onRowClick={(staff) =>
            history.push({
              pathname: `/employees/profiles/${staff.id}`,
              state: { name: getFullName(staff) },
            })
          }
        />
      )}
    </PageWrapper>
  );
};

export default RolePermissions;
