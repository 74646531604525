import React, { useCallback, useState } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { Button } from 'react-bootstrap';
import { showToast } from 'shared/components/Toast';
import { useAdminClockOut } from '../../../../../../gql/timeEntry/mutations';
import TimePicker2 from 'shared/components/TimePicker/TimePicker2';
import { addDateToTime, isTimeRangeInvalid } from 'shared/util/timeUtils';

interface IProps {
  timeEntry: ITimeEntry;
  timezone: any;
  selectedStaff: IStaff;
  loadOpenTimeEntriesForSelectedPerson: (options: IStaff) => void;
}

const ClockOutOpenTimeEntry: React.FC<IProps> = ({
  timeEntry,
  timezone,
  selectedStaff,
  loadOpenTimeEntriesForSelectedPerson,
}) => {
  const fields = `
    id
    personId
    centerId
    shiftId
    scheduleId
    timeIn
    trackedTimeIn
    timeOut
    trackedTimeOut
  `;

  const [firstOpenTimeEntry, setFirstOpenTimeEntry] = useState<ITimeEntry>(timeEntry);
  const trackedTimeIn = moment(firstOpenTimeEntry?.trackedTimeIn).tz(timezone);
  const trackedTimeInFormattedDate = trackedTimeIn.format('MM/DD/YYYY');
  const trackedTimeInFormattedTime = trackedTimeIn.format('HH:MM A');
  const [adminClockOutFn, { loading: adminClockOutLoading }] = useAdminClockOut(
    {
      onCompleted: () => {
        loadOpenTimeEntriesForSelectedPerson(selectedStaff);
        showToast('Clock Out was successful.', 'success');
      },
      onError: (error) => {
        console.log(error);
        showToast(`There was an error when attempting to clock out.`, 'error');
      },
    },
    fields
  );
  const invalidTimeframe = Boolean(
    isTimeRangeInvalid(trackedTimeIn.toISOString(), firstOpenTimeEntry.trackedTimeOut) ?? false
  );

  const adminClockOut = useCallback(() => {
    const trackedTimeOut = momentTz(
      addDateToTime(firstOpenTimeEntry.trackedTimeOut, trackedTimeInFormattedDate, timezone)
    )
      .tz(timezone)
      .toISOString();
    trackedTimeOut &&
      adminClockOutFn({
        variables: {
          id: timeEntry.id,
          trackedTimeOut,
        },
      });
  }, [adminClockOutFn, firstOpenTimeEntry, trackedTimeInFormattedDate, timezone, timeEntry.id]);

  return (
    <div className="border-secondary p-6 border">
      <div className="mb-4">You currently have an open shift. To create a new shift you must close this one:</div>
      <div className="mb-4">{`${trackedTimeInFormattedDate}: Clock In - ${trackedTimeInFormattedTime}`}</div>
      <TimePicker2
        label="Clock Out"
        value={firstOpenTimeEntry?.trackedTimeOut}
        onChange={(time) => setFirstOpenTimeEntry((prev) => ({ ...prev, trackedTimeOut: time }))}
        isInvalid={invalidTimeframe}
      />
      <Button
        className="mr-4 my-2"
        variant="secondary"
        onClick={adminClockOut}
        disabled={!firstOpenTimeEntry.trackedTimeOut || adminClockOutLoading}
      >
        Clock Out
      </Button>
    </div>
  );
};

export default ClockOutOpenTimeEntry;
