import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import colors from '../../../_colors.module.scss';
import { AsyncPaginate } from 'react-select-async-paginate';
import { capitalize, uniqBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { components, OptionProps } from 'react-select';
import Avatar from '../Avatar';
import { stringToHueDegree } from '../../util/string';
import { CoreApolloClient } from '../../apis/core';
import { GET_SORTED_ACCOUNTS, IGetSortedAccountsData, IGetSortedAccountsVariables } from '../../../gql/account/queries';
import moment from 'moment/moment';
import { showToast } from '../Toast';
import getApolloErrorMessage from '../../util/getApolloErrorMessage';
import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { DIRECTIONS } from 'shared/constants/elastic';
import { GET_SORTED_CHILDREN, IGetSortedChildrenData, IGetSortedChildrenVariables } from '../../../gql/child/queries';

const DEFAULT_PAGE_SIZE = 25 as const;

interface IChildSelectProps {
  selectedChildIds: string[] | null;
  selectedChildren?: IAccountChild[] | null;
  onSelect: (childIds: string[] | null) => void;
  onSelectChildren?: (children: IAccountChild[] | null) => void;
  required?: boolean;
  disableAllOption?: boolean;
  showAllChildrenOption?: boolean;
  isMulti?: boolean;
  centerIds?: string[] | null;
  statusType?: 'Inactive' | 'Active' | 'Prospective' | 'All';
  pageSize?: number;
  excludedChildIds?: string[];
  appearance?: 'default' | 'detailed';
  showChildStatus?: boolean;
  hideLabel?: boolean;
}

const ChildSelect: React.FC<IChildSelectProps> = ({
  selectedChildIds,
  onSelect,
  onSelectChildren,
  required = false,
  disableAllOption = false,
  showAllChildrenOption = true,
  isMulti = true,
  centerIds = [],
  statusType = 'All',
  pageSize = DEFAULT_PAGE_SIZE,
  excludedChildIds,
  appearance = 'default',
  showChildStatus = false,
  hideLabel = false,
  ...props
}) => {
  const { t } = useTranslation();
  const ALL_CHILD_OPTIONS = useMemo(
    () => ({ value: 'All Children', label: `${capitalize(t('spelling.all'))} ${capitalize(t('spelling.children'))}` }),
    [t]
  );
  const [loading, setIsLoading] = useState(false);
  const [children, setChildren] = useState<any[]>([]);
  const initialOptions = useMemo(
    () => (disableAllOption || !isMulti ? [] : showAllChildrenOption ? [ALL_CHILD_OPTIONS] : []),
    [disableAllOption, ALL_CHILD_OPTIONS, isMulti]
  );

  const loadOptions = useCallback(
    async (inputValue: string, loadedOptions: any[]) => {
      try {
        setIsLoading(true);
        const { data: response } = await CoreApolloClient.query<IGetSortedChildrenData, IGetSortedChildrenVariables>({
          query: GET_SORTED_CHILDREN(),
          variables: {
            input: {
              from: Math.floor(loadedOptions.length / pageSize + 1),
              size: pageSize,
              centerIds: centerIds ?? [],
              sort: [{ field: 'name', direction: DIRECTIONS.ASCENDING }],
              searchKey: inputValue,
              tagIds: [],
              statusType: statusType === 'All' ? undefined : statusType,
              statusAtDate: moment().format('YYYY-MM-DD'),
            },
          },
        });
        const { pageNumber, totalRecords, data } = response.getSortedChildren;
        const hasMore = totalRecords > pageNumber * pageSize;
        setChildren((prev) => uniqBy([...prev, ...data], 'id'));
        setIsLoading(false);

        return {
          options: data.map((child) => ({
            label: child.fullName,
            value: child.id,
            isDisabled: excludedChildIds ? excludedChildIds.includes(child.id) : false,
          })),
          hasMore,
        };
      } catch (error: any) {
        showToast(getApolloErrorMessage(error), 'error');
        console.log(getApolloErrorMessage(error));
      }
    },
    [pageSize, centerIds, statusType, excludedChildIds]
  );

  const selectedOptions = useMemo(() => {
    if (selectedChildIds === null && showAllChildrenOption) {
      return [ALL_CHILD_OPTIONS];
    }

    return children
      .filter((child) => selectedChildIds?.includes(child.id))
      .map((child) => ({ label: child.fullName, value: child.id }));
  }, [ALL_CHILD_OPTIONS, children, selectedChildIds]);

  const MenuItem = useCallback(
    (props: OptionProps<ITableFilterOption, false>) => {
      if (appearance === 'default') {
        return <components.Option {...props} />;
      }
      const child = children.find((child) => child.id === props.data.value);
      const status = child?.status ?? '';

      return (
        <components.Option {...props}>
          <div className={`d-flex align-items-center px-2 py-1`}>
            <Avatar
              color={`hsl(${stringToHueDegree(props.label)}, ${
                stringToHueDegree(props.label) < 50 ? '100%' : '40%'
              }, 40%`}
              size={'sm'}
              image={child?.avatar && child?.avatar.url}
              initials={
                (child?.firstname ?? '').charAt(0).toUpperCase() + (child?.lastname ?? '').charAt(0).toUpperCase()
              }
            />
            <div className="d-flex flex-column pl-4 text-truncate">
              <span className="text-truncate">{props.label}</span>
              {showChildStatus && status && <small>{status}</small>}
            </div>
          </div>
        </components.Option>
      );
    },
    [children, appearance, t, showChildStatus]
  );

  const handleSelect = useCallback(
    (newValue, actionMeta) => {
      if (!newValue) {
        onSelect([]);
        return;
      }

      const { action, option } = actionMeta;

      const isAllOptionSelected = isMulti
        ? option?.value === ALL_CHILD_OPTIONS.value
        : newValue?.value === ALL_CHILD_OPTIONS.value;

      if (isAllOptionSelected) {
        switch (action) {
          case 'select-option':
            onSelect(null);
            return;
          default:
            onSelect([]);
            return;
        }
      }

      onSelect(isMulti ? newValue.map((option: any) => option.value) : [newValue.value]);
      onSelectChildren &&
        onSelectChildren(
          isMulti
            ? newValue.map((option: any) => children.find((c) => c.id == option.value))
            : [children.find((c) => c.id == newValue.value)]
        );
    },
    [onSelect, onSelectChildren, ALL_CHILD_OPTIONS, isMulti, children]
  );

  return (
    <Form.Group>
      {!hideLabel && (
        <Form.Label>
          Account(s)
          {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color={colors.red} />}
        </Form.Label>
      )}
      <AsyncPaginate
        /**
         * Workaround for manually triggering the loadOptions function
         * See https://github.com/JedWatson/react-select/issues/1879#issuecomment-316871520
         */
        key={`${JSON.stringify(centerIds)}-${JSON.stringify(statusType)}-${JSON.stringify(excludedChildIds)}`}
        isMulti={isMulti}
        options={initialOptions}
        value={selectedOptions}
        isLoading={loading}
        // @ts-ignore
        loadOptions={loadOptions}
        onChange={handleSelect}
        debounceTimeout={500}
        closeMenuOnSelect={!isMulti}
        className="react-select-container flex-wrap"
        classNamePrefix="react-select"
        hideSelectedOptions={false}
        noOptionsMessage={() => `${capitalize(t('spelling.no'))} ${t('spelling.children')} ${t('spelling.found')}`}
        placeholder={`${capitalize(t('spelling.search'))} ${capitalize(t('spelling.children'))}`}
        components={{
          Option: MenuItem,
        }}
        {...props}
      />
    </Form.Group>
  );
};

export default ChildSelect;
