import config from 'config';
import _find from 'lodash/find';
import React, { useCallback, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import ReactSelect from 'react-select';
import _mapValues from 'lodash/mapValues';
import COUNTRY_INFO, { DEFAULT_COUNTRY, DISABLE_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { isBlank, isValidPostalCode } from 'shared/util/string';
import 'shared/components/Select/_select.scss';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelpTooltip from '../Tooltip/HelpTooltip';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
declare var google: any;

const countryOptions = Object.keys(COUNTRY_INFO);
const stateOptions = _mapValues(COUNTRY_INFO, (country) =>
  Object.entries(country.subdivisions).map((arr) => ({ value: arr[0], label: arr[1] }))
);
const newAddress: IAddress = {
  address1: '',
  city: '',
  state: '',
  postalCode: '',
  country: DEFAULT_COUNTRY,
};

interface IProps {
  address?: IAddress | undefined;
  onChange: (address: IAddress) => void;
  addressLabel?: string;
  disabled?: boolean;
  required?: boolean;
  errorText?: any;
  showHelperText?: boolean;
  hideCountry?: boolean;
  modalFormat?: boolean;
}

const AddressInput: React.FC<IProps> = ({
  address = newAddress,
  onChange,
  addressLabel = 'Address',
  disabled,
  required,
  errorText,
  showHelperText = true,
  ...props
}) => {
  const [predictions, setPredictions] = useState([]);
  const [address1Touched, setAddress1Touched] = useState(false);
  const autocomplete: any = useRef();
  var places = new google.maps.places.PlacesService(document.createElement('div'));

  if (!autocomplete.current) {
    autocomplete.current = new google.maps.places.AutocompleteService();
  }

  const handleChange = useCallback(
    (value, name) => {
      onChange({ ...address, [name]: value });
    },
    [address, onChange]
  );

  const handleCountryChange = useCallback(
    (country) => {
      if (country !== address.country) {
        onChange({ ...address, country, state: '' });
      }
    },
    [address, onChange]
  );

  const getPlacePredictions = useCallback(
    debounce((input: string) => {
      setPredictions([]);
      if (input) {
        autocomplete.current &&
          autocomplete.current.getPlacePredictions(
            { input: input, componentRestrictions: { country: config.locale.region }, types: ['address'] },
            (predictions: any) => {
              if (predictions) {
                setPredictions(
                  predictions.filter((prediction: any) => {
                    const types: string[] = prediction.types;
                    // Filter out prediction types that don't resolve to roads with street numbers in getDetails
                    return !types.every((value) => ['route', 'geocode'].includes(value));
                  })
                );
              }
            }
          );
      }
    }, 500),
    []
  );

  const handleAddress1InputChange = useCallback(
    (address1, { action }) => {
      if (action === 'input-change') {
        handleChange(address1, 'address1');
      }
      getPlacePredictions(address1);
    },
    [getPlacePredictions, handleChange]
  );

  const stylesDisable = {
    singleValue: (provided) => ({
      ...provided,
      visibility: 'visible',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      visibility: 'visible',
    }),
  };

  const handleAddress1SelectPrediction = useCallback(
    (address1) => {
      setPredictions([]);
      const placeId = address1.value.place_id;

      interface IAddressComponent {
        long_name: string;
        short_name: string;
        types: string[];
      }

      places.getDetails({ placeId, fields: ['address_components', 'formatted_address'] }, (data: any) => {
        const addressComponents: IAddressComponent[] = data?.address_components;

        if (addressComponents) {
          const getComponent = (typeName: string): IAddressComponent | undefined => {
            return _find(addressComponents, (a) => a.types.includes(typeName));
          };

          // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
          // "Do not parse the formatted address programmatically."
          const formattedAddressStreetAddress = data?.formatted_address.split(',')[0];

          if (formattedAddressStreetAddress) {
            const city = getComponent('locality')?.short_name; // e.g. Woolloongabba, ??? for US
            const state = getComponent('administrative_area_level_1')?.short_name; // e.g. NY, QLD
            const postalCode = getComponent('postal_code')?.long_name; // e.g. 4102, ??? for US
            const country = getComponent('country')?.short_name; // eg AU, US

            onChange({
              ...address,
              address1: address1.value.structured_formatting.main_text,
              city: city ?? '',
              state: `${country}-${state}`,
              postalCode: postalCode ?? '',
              country,
            });
          }
        }
      });
    },
    [address, onChange, places]
  );

  const validatePostalCode = useCallback((postalCode) => {
    // only tests US postal codes
    return isValidPostalCode(postalCode);
  }, []);

  const countryCode = DEFAULT_COUNTRY;
  const fieldLabels = COUNTRY_INFO[countryCode].fieldLabels;

  const address1Invalid = address1Touched && required && !address.address1;

  const renderAddress1 = () => (
    <Form.Group>
      {addressLabel && (
        <div className="d-flex flex-row">
          {addressLabel && <Form.Label className="text-nowrap">{addressLabel}</Form.Label>}
          {required && !disabled && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
          {showHelperText && (
            <HelpTooltip text="For internal use and automation, only. This will not be accessible to families." />
          )}
        </div>
      )}
      <ReactSelect
        className={`react-select-container ${address1Invalid ? 'invalid' : ''}`}
        classNamePrefix="react-select"
        isSearchable={true}
        components={{ DropdownIndicator: null, IndicatorSeparator: null }}
        id="address1-input"
        label={addressLabel}
        name="address1"
        inputValue={address.address1 ?? ''}
        value={{ label: address.address1, value: address.address1 }}
        onInputChange={handleAddress1InputChange}
        onChange={handleAddress1SelectPrediction}
        isDisabled={disabled}
        options={predictions.map((p: any) => ({ label: p.description, value: p }))}
        styles={stylesDisable}
        menuIsOpen={Boolean(predictions.length)}
        onBlur={() => {
          setAddress1Touched(true);
        }}
        closeMenuOnSelect={false}
      />
      {address1Invalid && <Form.Text className="text-danger">{errorText && errorText.address}</Form.Text>}
    </Form.Group>
  );
  const renderAddress2 = () => (
    <TextInput
      id="address2-input"
      label={fieldLabels.address2}
      name="address2"
      value={address.address2 || ''}
      onChange={handleChange}
      disabled={disabled}
      requiresValidation={false}
    />
  );
  const renderCity = () => (
    <TextInput
      id="address-city-input"
      label={fieldLabels.city}
      name="city"
      value={address.city || ''}
      onChange={handleChange}
      disabled={disabled}
      required={required}
      errorText={errorText && errorText.city}
      isInvalid={required && isBlank(address.city)}
    />
  );
  const renderState = () => (
    <Select
      inputId="address-state-input"
      label={fieldLabels.subdivision}
      options={stateOptions[countryCode]}
      value={stateOptions[countryCode].find((option) => option.value === address.state) ?? null}
      onChange={(option) => {
        handleChange(option.value, 'state');
      }}
      disabled={disabled}
      required={required}
      errorText={errorText && errorText.state}
      isInvalid={required && isBlank(address.state)}
    />
  );
  const renderCountry = () => (
    <Select
      id="address-country-input"
      label="Country"
      name="country"
      className={props.hideCountry ? 'd-none' : ''}
      options={countryOptions}
      formatOptionLabel={(option: any) => COUNTRY_INFO[option.value].name}
      value={DEFAULT_COUNTRY}
      onChange={DISABLE_COUNTRY ? () => {} : handleCountryChange}
      disabled={DISABLE_COUNTRY || disabled}
      required={required}
    />
  );
  const renderPostcode = () => (
    <TextInput
      id="address-postal-code-input"
      label={fieldLabels.postalCode}
      name="postalCode"
      value={address.postalCode || ''}
      onChange={handleChange}
      disabled={disabled}
      required={required}
      errorText={errorText && errorText.postalCode}
      // this regex currently only tests US zip codes
      isInvalid={!validatePostalCode(address.postalCode)}
      requiresValidation
    />
  );

  if (props.modalFormat) {
    return (
      <div {...props}>
        <Row align="start">
          <Col sm={12}>{renderAddress1()}</Col>
        </Row>
        <Row align="start">
          <Col sm={12}>{renderAddress2()}</Col>
        </Row>
        <Row align="start">
          <Col sm={12}>{renderCity()}</Col>
        </Row>
        <Row align="start">
          <Col sm={6}>{renderState()}</Col>
          <Col sm={6}>{renderPostcode()}</Col>
        </Row>
        <Row align="start">
          <Col sm={12}>{renderCountry()}</Col>
        </Row>
      </div>
    );
  }

  return (
    <div {...props}>
      <Row align="start">
        <Col sm={8}>{renderAddress1()}</Col>
        <Col sm={4}>{renderAddress2()}</Col>
      </Row>
      <Row align="start">
        <Col sm={6}>{renderCity()}</Col>
        <Col sm={6}>{renderState()}</Col>
      </Row>
      <Row align="start">
        <Col sm={6}>{renderCountry()}</Col>
        <Col sm={6}>{renderPostcode()}</Col>
      </Row>
    </div>
  );
};

export default AddressInput;
