import * as type from './types';

export const getTotalCreatedStaffSuccess = (totalCreatedStaff: number, createdStaffData: IStaff[]) => ({
  type: type.GET_TOTAL_CREATED_STAFF_SUCCESS,
  totalCreatedStaff,
  createdStaffData,
});

export const getTotalInvitedStaffSuccess = (totalInvitedStaff: number, invitedStaffData: IStaff[]) => ({
  type: type.GET_TOTAL_INVITED_STAFF_SUCCESS,
  totalInvitedStaff,
  invitedStaffData,
});

export const getTotalRequestedStaffSuccess = (totalRequestedStaff: number, requestedStaffData: IStaff[]) => ({
  type: type.GET_TOTAL_REQUESTED_STAFF_SUCCESS,
  totalRequestedStaff,
  requestedStaffData,
});

export const increaseTotalCreatedStaff = () => ({ type: type.INCREASE_TOTAL_CREATED_STAFF });
export const increaseTotalInvitedStaff = () => ({ type: type.INCREASE_TOTAL_INVITED_STAFF });
