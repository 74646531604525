import React, { useCallback } from 'react';
import SearchInput from 'shared/components/SearchInput';
import { debounce } from 'lodash';

const DEBOUNCE_DELAY = 500;

interface IProps {
  placeholder?: string;
  className?: string;
  onChange: (value: string) => void;
  enableDebounce?: boolean;
  [key: string]: any;
}

const TableSearch: React.FC<IProps> = ({
  onChange,
  placeholder = 'Search',
  className = '',
  enableDebounce = true,
  ...props
}) => {
  const debouncedHandleInput = useCallback(
    debounce((value: string) => {
      onChange && onChange(value);
    }, DEBOUNCE_DELAY),
    [onChange]
  );

  return (
    <SearchInput
      className={`mb-0 ${className}`}
      onChange={enableDebounce ? debouncedHandleInput : onChange}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default TableSearch;
