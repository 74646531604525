import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'shared/components/Layout';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import TextInput from '../TextInput';

const DEFAULT_TIME = '12:00pm';
const timeOptions: string[] = [];
const loopTime = moment().startOf('d');
const endOfDay = moment().endOf('d');
while (loopTime.isBefore(endOfDay)) {
  timeOptions.push(loopTime.clone().format('h:mma'));
  loopTime.add(15, 'minutes');
}
const charsAllowedInTimeValue = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'a', 'p', 'm', ':'];

interface IProps {
  start: string | null;
  end: string | null;
  onChange: (start: string | null, end: string | null) => void;
  className?: string;
  disabled?: boolean;
}

/**
 * Time range component with a lot of logic borrowed from TimePicker2
 */
const TimeRangePicker2: React.FC<IProps> = ({ disabled = false, start, end, onChange, className, ...props }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [closestTimeOption, setClosestTimeOption] = useState('');
  const [focusedInput, setFocusedInput] = useState<'start' | 'end' | null>(null);
  const refs = Object.fromEntries(timeOptions.map((t) => [t, React.createRef<HTMLLIElement>()]));

  const isInStandardTime = useCallback((val) => val && moment(val, 'h:mma').format('h:mma') === val, []);
  const isInMilitaryTime = useCallback(
    (val) =>
      val && (moment(val, 'HH:mm').format('HH:mm') === val || moment(val, 'HH:mm:ss').format('HH:mm:ss') === val),
    []
  );
  const convertTimeToStandard = useCallback(
    (val) => (isInMilitaryTime(val) ? moment(val, 'HH:mm').format('h:mma') : val),
    []
  );
  const convertTimeToMilitary = useCallback(
    (val) => (isInStandardTime(val) ? moment(val, 'h:mma').format('HH:mm') : val),
    []
  );

  useEffect(() => {
    if (refs[closestTimeOption] !== null && refs[closestTimeOption]?.current) {
      const currentRef = refs[closestTimeOption].current as HTMLLIElement;
      currentRef.scrollIntoView();
    }
  }, [closestTimeOption, refs]);

  const validatedDefaultTime = timeOptions.includes(convertTimeToStandard(DEFAULT_TIME))
    ? convertTimeToStandard(DEFAULT_TIME)
    : '12:00pm';
  const getClosestTimeOption = useCallback(
    (value: string) => {
      const valueAsTime = moment(value ?? '', 'h:mma');
      return valueAsTime.isValid()
        ? timeOptions.find(
            (t) => t === valueAsTime.format('h:mma') || valueAsTime.diff(moment(t, 'h:mma'), 'minutes') < 15
          ) ?? validatedDefaultTime
        : validatedDefaultTime;
    },
    [validatedDefaultTime]
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      setShowOptions(true);

      const target = event.target as HTMLInputElement;
      const targetName = target?.name;
      const startInputFocused = targetName?.includes('start');

      const startTime = start ?? DEFAULT_TIME;
      const endTime = end ?? DEFAULT_TIME;

      setClosestTimeOption(getClosestTimeOption(startInputFocused ? startTime : endTime));
      setFocusedInput(startInputFocused ? 'start' : 'end');
    },
    [start, end, getClosestTimeOption]
  );

  const handleChange = useCallback(
    (value: string) => {
      if (value.split('').every((char: string) => charsAllowedInTimeValue.includes(char))) {
        // split the string on the first instance of am/pm and use that as the time string
        // if a user enters 7:34am39573845 then we will only bubble up 7:34am
        const limitedString: string | undefined = value.split(/(.+[a|p]m)/)[1];
        const str = limitedString ?? value;
        const time = convertTimeToMilitary(str);
        const _start = focusedInput === 'start' ? time : start;
        const _end = focusedInput === 'end' ? time : end;

        onChange(_start, _end);
        setClosestTimeOption(getClosestTimeOption(value));
      }
    },
    [focusedInput, start, end, convertTimeToMilitary, getClosestTimeOption, onChange]
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const startInputFocused = target.name.includes('start');

      if (event.key === 'Enter') {
        const time = convertTimeToMilitary(closestTimeOption);
        const _start = startInputFocused ? time : start;
        const _end = !startInputFocused ? time : end;

        onChange(_start, _end);
      } else if (event.key === 'ArrowDown') {
        setClosestTimeOption(moment(closestTimeOption, 'h:mma').clone().add(15, 'm').format('h:mma'));
      } else if (event.key === 'ArrowUp') {
        setClosestTimeOption(moment(closestTimeOption, 'h:mma').clone().subtract(15, 'm').format('h:mma'));
      }
    },
    [closestTimeOption, onChange, start, end, convertTimeToMilitary]
  );

  const handleBlur = useCallback((event: React.FocusEvent<HTMLDivElement>) => {
    event.preventDefault();
    const relatedTarget = event.relatedTarget as Element;
    const isLiElement = relatedTarget !== null && relatedTarget.nodeName === 'LI';
    const isInputElement = relatedTarget !== null && relatedTarget.nodeName === 'INPUT';

    if (relatedTarget === null || (!isLiElement && !isInputElement)) {
      setShowOptions(false);
    }
  }, []);

  const onTimeSelect = useCallback(
    (time: string) => {
      const formattedTime = convertTimeToMilitary(time);
      const _start = focusedInput === 'start' ? formattedTime : start;
      const _end = focusedInput === 'end' ? formattedTime : end;

      onChange(_start, _end);
      setShowOptions(false);
      setFocusedInput(null);
    },
    [convertTimeToMilitary, end, focusedInput, onChange, start]
  );

  return (
    <div className="d-flex flex-column" onBlur={handleBlur}>
      <Row noGutters justify="between" className={`flex-noWrap time-range-container ${className}`}>
        <Col>
          <TextInput
            disabled={disabled}
            name="time-range-start"
            value={convertTimeToStandard(start) ?? ''}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            onFocus={handleFocus}
            placeholder="HH:MM"
            isInvalid={
              !!start &&
              !(moment(start, 'HH:mm').format('HH:mm') === start || moment(start, 'h:mma').format('h:mma') === start)
            }
          />
        </Col>
        <div className="mx-2">
          <FontAwesomeIcon icon={faChevronRight} size="sm" />
        </div>
        <Col>
          <TextInput
            disabled={disabled}
            name="time-range-end"
            value={convertTimeToStandard(end) ?? ''}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            onFocus={handleFocus}
            placeholder="HH:MM"
            isInvalid={
              !!end && !(moment(end, 'HH:mm').format('HH:mm') === end || moment(end, 'h:mma').format('h:mma') === end)
            }
          />
        </Col>
      </Row>
      <div className="w-100 position-relative">
        {showOptions && (
          <ul className="time-picker-menu">
            {timeOptions.map((time) => (
              <li
                tabIndex={-1}
                key={time}
                ref={refs[time]}
                className={time === closestTimeOption ? 'selected' : ''}
                value={time}
                onClick={() => onTimeSelect(time)}
              >
                {time}
              </li>
            ))}
          </ul>
        )}
      </div>
      {start && end && moment(start, 'HH:mm').isAfter(moment(end, 'HH:mm')) && (
        <Row className="mt-2" noGutters>
          <small className="text-danger">Invalid timeframe.</small>
        </Row>
      )}
    </div>
  );
};

export default TimeRangePicker2;
