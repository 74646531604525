import React, { useState } from 'react';
import NumberInput, { INumberInputProps } from './NumberInput';

type IProps = Omit<INumberInputProps, 'value'> & {
  defaultValue: number | null | undefined | string;
};

const StatefulNumberInput: React.FC<IProps> = ({ defaultValue, onChange, ...props }) => {
  const [value, setValue] = useState(defaultValue);
  const handleOnChange: INumberInputProps['onChange'] = (v, name) => {
    setValue(v);
    onChange && onChange(v, name);
  };

  return <NumberInput value={value} onChange={handleOnChange} {...props} />;
};

export default StatefulNumberInput;
