import React from 'react';
import { IconButton } from 'shared/components/Buttons';
import Tooltip from 'shared/components/Tooltip';

interface IProps {
  bulkActions: IDatatableBulkAction[];
}

const BulkActions: React.FC<IProps> = ({ bulkActions, ...props }) => {
  return (
    <div className="d-flex flex-row">
      {bulkActions.map((action, index) => (
        <Tooltip key={`${action.tooltip}-${index}`} text={action.tooltip} direction="bottom">
          <IconButton
            icon={action.icon}
            className="mr-2 rounded-circle circle-md bulk-action-button bg-pale-grey"
            onClick={action.onClick}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default BulkActions;
