import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { requested } from 'shared/constants/StaffStatusSearchExpressions';
import { ISearchStaffData, SEARCH_STAFF } from '../../Profiles/graphql/queries';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { IDatatableState } from 'shared/hooks/useDatatableState';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { getTotalRequestedStaffSuccess } from '../../Profiles/duck/actions';

export interface IApproveStaffInput {
  staff: {
    id: string;
    firstname: string;
    lastname: string;
    roleship: {
      roleId: string;
      scopeType: string;
      scopeIds: string[];
    };
    employmentStartDate?: string | null;
    position: ICreateStaffPositionInput;
  };
  sendInvitation?: boolean;
}

interface IApproveStaffVariables {
  input: IApproveStaffInput;
}

interface IApproveStaffData {
  approveStaff: IStaff;
}

export const APPROVE_STAFF = gql`
  mutation ($input: ApproveStaffInput!) {
    approveStaff(input: $input) {
      id
      firstname
      lastname
      email
      avatar {
        url
      }
      address {
        state
        city
      }
      roleship {
        roleId
        name
        scopeType
        scopes {
          ... on Center {
            name
          }
          ... on Entity {
            name
          }
        }
      }
      invitedAt
      createdAt
    }
  }
`;

export const useApproveStaff = () => {
  const profiles = useSelector((state: RootState) => state.profiles);
  const dispatch = useDispatch();
  return useMutation<IApproveStaffData, IApproveStaffVariables>(APPROVE_STAFF, {
    update: (proxy, result) => {
      if (result.data?.approveStaff) {
        const data = [
          ...profiles.requestedStaffData.filter((staff: IStaff) => staff.id !== result.data?.approveStaff.id),
        ];
        dispatch(getTotalRequestedStaffSuccess(profiles.totalRequestedStaff - 1, data));
      }
    },
  });
};
