import React from 'react';
import Card from 'shared/components/Card';
import { currencyFormat } from 'shared/util/currency';
import { Row } from 'shared/components/Layout';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetFlaggedPayments } from 'gql/transaction/queries';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import { orderBy } from 'lodash';
import { HorizontalDivider } from 'shared/components/Dividers';

interface IProps {}

const FlaggedPayments: React.FC<IProps> = ({}) => {
  const { businessFilterIds, centerFilterIds } = useSelector((state: RootState) => state.context);

  const transactions = useSelector((state: RootState) => state.billing.transactions.all).filter(
    (d) =>
      (!businessFilterIds.length || businessFilterIds.includes(d.account.center?.entityId ?? '')) &&
      (!centerFilterIds.length || centerFilterIds.includes(d.account.center?.id ?? ''))
  );

  const { loading } = useGetFlaggedPayments();
  const flaggedPayments = transactions.filter((t) =>
    Boolean(t.payment?.flags.length && !t.payment.approvedAt && !t.payment.rejectedAt)
  );
  const totalAmountOfFlaggedPayments = currencyFormat(flaggedPayments.reduce((sum, t) => sum + t.amount, 0));
  const history = useHistory();

  const firstFlaggedPayment = orderBy(flaggedPayments, 'date', 'asc')[0];

  return (
    <Card bodyClassName="p-0">
      <h5 className="m-4"> Flagged Payments</h5>
      <HorizontalDivider />
      {loading ? (
        <LoadingLines number={3} />
      ) : (
        <div>
          <p className="m-4">
            {' '}
            {`You have ${flaggedPayments.length} flagged payment${flaggedPayments.length !== 1 ? 's' : ''}.`}
          </p>
          <Row noGutters className="m-4">
            <h5 className="text-text-color mr-2">Value:</h5>
            <h4 className="text-orange m-4">{totalAmountOfFlaggedPayments}</h4>
            <Button
              className="text-overflow-ellipsis"
              block
              onClick={() =>
                history.push(
                  `/billing/payments?status=flagged${firstFlaggedPayment ? '&start=' + firstFlaggedPayment?.date : ''}`
                )
              }
              variant="outline-primary"
            >
              View Flagged Payments
            </Button>
          </Row>
        </div>
      )}
    </Card>
  );
};

export default FlaggedPayments;
