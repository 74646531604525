import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataTable, { SizePerPage, TableHeader, BulkActions } from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { ColoredBackgroundTag, TagsTableCell } from 'shared/components/Tag';
import COUNTRY_INFO, {
  DEFAULT_COUNTRY,
  STATES,
  US_STATE_SELECT_OPTIONS,
} from 'shared/constants/dropdownOptions/countryInfo';
import FilterGroup from './FilterGroup';
import { faTag, faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState';
import ActionDropdown from 'shared/components/ActionDropdown';
import { RootState } from 'store/reducers';
import { parsePhoneNumberWithRegion } from 'shared/util/string';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import colors from '_colors.module.scss';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetCenterStatesInUse } from 'shared/hooks/useGetCenterStatesInUse';
import { orderBy } from 'lodash';

interface IProps {
  handleRowClick: (event: React.SyntheticEvent, rowData: ICenter) => void;
  handleDeactivate: (centers: ICenter[]) => void;
  handleReactivate: (centers: ICenter[]) => void;
  handleDelete: (centers: ICenter[]) => void;
  handleAddTags: (centers: ICenter[]) => void;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  data: ICenter[];
  dataSize: number;
  loading: boolean;
  autoSelectedBusinessId: string;
}

const CenterProfilesTable: React.FC<IProps> = ({
  handleRowClick,
  handleDeactivate,
  handleReactivate,
  handleDelete,
  handleAddTags,
  tableState,
  tableFunctions,
  data,
  dataSize,
  loading,
  autoSelectedBusinessId,
}) => {
  const { k2BillingStartDate } = useFlags();
  const currentUser = useSelector((state: RootState) => state.user);
  const isInternalUser = currentUser?.isInternal;
  const hasDeleteCenterPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });
  const hasReadCenterTagsPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Tags,
    level: RoleLevelType.Read,
  });
  const hasEditCenterTagsPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Tags,
    level: RoleLevelType.Edit,
  });

  const { data: getAllowedEntitiesData } = useGetAllowedEntities(!isInternalUser);
  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];

  const tagsOptions: ITableFilterOption[] = orderBy(tags ?? [], (tag) => tag.name.toLocaleLowerCase(), 'asc').map(
    (tag) => ({ label: tag.name, value: tag.id })
  );

  const statesInUse: string[] = useGetCenterStatesInUse()?.data?.getCenterStatesUsedAcrossEntity || [];

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const states =
    !!statesInUse && statesInUse.length > 0
      ? US_STATE_SELECT_OPTIONS.filter((state) => statesInUse.includes(state.value))
      : US_STATE_SELECT_OPTIONS;

  // const dataSize = metaData ? metaData.totalResults : 0;
  // determine what actions can take place on a row. this sohuld also be expanded to check a user's permissions when it's time
  const tableActionsForRow = useCallback(
    (cell: any, row: ICenter) => {
      const actions: IDropdownAction[] = [];

      actions.push({ label: 'Edit', onClick: () => handleRowClick(cell, row) });

      // FUTURE TODO: permissions check
      if (row.deactivatedAt && !row.deletedAt && hasDeleteCenterPermission) {
        actions.push({ label: 'Reactivate', onClick: () => handleReactivate([row]) });
        actions.push({ label: 'Delete', onClick: () => handleDelete([row]) });
      } else if (!row.deletedAt && hasDeleteCenterPermission) {
        actions.push({ label: 'Deactivate', onClick: () => handleDeactivate([row]) });
      }

      return actions;
    },
    [hasDeleteCenterPermission, handleDeactivate, handleDelete, handleReactivate, handleRowClick]
  );

  const addTagsAction = {
    icon: faTag,
    tooltip: 'Add Tags',
    onClick: () => {
      handleAddTags(tableState.selectedRows);
    },
  };
  const deactivateAction = {
    icon: faMinusCircle,
    tooltip: 'Deactivate',
    onClick: () => {
      handleDeactivate(tableState.selectedRows);
    },
  };
  const reactivateAction = {
    icon: faPlusCircle,
    tooltip: 'Reactivate',
    onClick: () => {
      handleReactivate(tableState.selectedRows);
    },
  };
  const bulkActions = useMemo(() => {
    const actions: typeof addTagsAction[] = [];

    if (tableState.selectedRows.every((c: ICenter) => !c.deactivatedAt && !c.deletedAt)) {
      hasEditCenterTagsPermission && actions.push(addTagsAction);
      hasDeleteCenterPermission && actions.push(deactivateAction);
    } else if (tableState.selectedRows.every((c: ICenter) => c.deactivatedAt && !c.deletedAt)) {
      return [reactivateAction];
    }

    return actions;
  }, [
    addTagsAction,
    deactivateAction,
    reactivateAction,
    tableState.selectedRows,
    hasEditCenterTagsPermission,
    hasDeleteCenterPermission,
  ]);

  // const tableData = (data && data.searchCenters && data.searchCenters.data) ? data.searchCenters.data : [];
  const formattedTableData = data.map((center) => ({
    ...center,
    address: {
      ...center.address,
      state: { keyword: STATES[center.address?.state] },
      city: { keyword: center.address?.city },
    },
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <DataTable
      data={formattedTableData}
      dataSize={dataSize ?? 0}
      pageSize={tableState.pageSize}
      handleRowClick={handleRowClick}
      showLoadingOverlay={loading}
      columns={[
        {
          text: `${fieldLabels.center} Name`,
          dataField: 'name.keyword',
          sort: true,
          formatter: (cell: string, row: ICenter) => (
            <AvatarDataTableCell
              initials={row.name && row.name[0].toUpperCase()}
              avatar={row.avatar && row.avatar.url}
              primaryText={row.name}
            />
          ),
        },
        { text: 'City', dataField: 'address.city.keyword', sort: true },
        { text: 'State', dataField: 'address.state.keyword', sort: true },
        {
          text: 'Phone Number',
          dataField: 'phoneNumber',
          formatter: (cell: string, row: ICenter) => {
            let str = '';
            try {
              const phoneNumber = parsePhoneNumberWithRegion(row.phoneNumber);
              str = phoneNumber.formatNational();
            } catch (error) {
              // could throw ParseError
            }

            return str;
          },
        },
        hasReadCenterTagsPermission
          ? {
              text: 'Tags',
              dataField: 'tags',
              formatter: (cell: any, row: ICenter) => row.tags && <TagsTableCell tags={row.tags} />, // cell will become Tags[]
            }
          : null,
        k2BillingStartDate
          ? {
              text: 'Billing Enabled',
              dataField: 'billingEnabledSettings.enableBillingCycleProcessing',
              formatter: (cell: any, row: any) => {
                return (
                  <ColoredBackgroundTag
                    color={
                      row.billingEnabledSettings?.enableBillingCycleProcessing ? colors.seafoamGreen : colors.danger
                    }
                    text={row.billingEnabledSettings?.enableBillingCycleProcessing ? 'True' : 'False'}
                  />
                );
              },
            }
          : null,
        isInternalUser ? { text: 'Business', dataField: 'entity.name', sort: false } : null,
        {
          text: 'Actions',
          dataField: '',
          align: 'center',
          headerClasses: 'text-center',
          formatter: (cell: any, row: ICenter) => <ActionDropdown actions={tableActionsForRow(cell, row)} />,
        },
      ].filter((column) => column)}
      renderHeader={(paginationProps: any, searchProps: any) => (
        // change types of paginationProps and searchProps when DataTable.js is converted to typescript
        <TableHeader className="flex-wrap">
          {tableState.selectedRows.length ? (
            <BulkActions bulkActions={bulkActions} />
          ) : (
            <>
              <SizePerPage className={isMobile ? 'my-1 mb-2 ml-2' : ''} paginationProps={paginationProps} />
              <FilterGroup
                tableState={tableState}
                tableFunctions={tableFunctions}
                tags={tagsOptions}
                states={states}
                entities={getAllowedEntitiesData?.getAllowedEntities || []}
                autoSelectedBusinessId={autoSelectedBusinessId}
              />
            </>
          )}
        </TableHeader>
      )}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      activePage={tableState.activePage}
      selectedRows={tableState.selectedRows}
      updateSelectedRows={tableFunctions.updateSelectedRows}
      onSort={tableFunctions.updateSort}
    />
  );
};

export default CenterProfilesTable;
