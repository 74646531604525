import React, { useState, useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetEntity } from 'gql/business/queries';
import { GET_ENTITY } from 'pages/Businesses/subroutes/BusinessProfile/graphql/fields';
import { getBusinessSuccess } from 'pages/Businesses/subroutes/BusinessProfiles/duck/actions';
import { RootState } from 'store/reducers';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import useUpdateAvatar from 'shared/hooks/useUpdateAvatar';
import BusinessSelect from 'shared/components/BusinessSelect';
import { dataType } from 'shared/constants/enums/dataTypeEnum';
import Alert from 'react-bootstrap/Alert';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProfileTab from './components/ProfileTab/ProfileTab';
import KTAdminTab from './components/KTAdminTab/KTAdminTab';
import './businessProfile.scss';
import CcssProviderTab from './components/CcssProviderTab/CcssProviderTab';
import { isRegion } from 'shared/util/region';
import PermissionActions from 'shared/constants/enums/actionEnum';
import ReturnFeeReductionTab from './components/ReturnFeeReductionTab/ReturnFeeReductionTab';
import CustomDataFieldsTab from './components/CustomDataFieldsTab/CustomDataFieldsTab';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import AttendanceControlTab from './components/AttendanceControlTab';
import { useTranslation } from 'react-i18next';
import PageWrapperBody from 'shared/components/PageWrapper/Body';

interface IRouteParams {
  id: string;
  activeTab: string;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {
  entityId: string;
}

type BusinessProfileTab = 'profile' | 'ktAdmin' | 'billing' | 'ccsProvider' | 'ccsPersonnel' | 'customDataFields';

const BusinessProfile: React.FC<{ entityId: string; activeTab: string }> = ({ entityId, activeTab, ...props }) => {
  const [activeTabKey, setActiveTabKey] = useState<BusinessProfileTab>((activeTab ?? 'profile') as BusinessProfileTab);
  const history = useHistory();
  const { t } = useTranslation();
  const { k2AttendanceControl } = useFlags();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const reduxBusiness = useSelector((state: RootState) => state.businesses.byId)[entityId] ?? null;
  const { k2NewUpdateCenter } = useFlags();

  // use the user's entity id OR the entity id stored in the router's state if found (internal user navigating to the profile via the profiles table)
  const { loading, error } = useGetEntity(
    {
      variables: {
        id: entityId,
      },
      onCompleted: (data) => {
        dispatch(getBusinessSuccess(data.getEntity));
      },
    },
    GET_ENTITY
  );

  const [updateAvatarFn, { loading: avatarLoading }] = useUpdateAvatar('entity');
  // if a query is loading all data may not be laoded so render loaders (previous behavior)
  const business: IBusiness | undefined = !loading && reduxBusiness ? reduxBusiness : undefined;
  const hasCcssAccess = user?.hasPermission(PermissionActions.FULL_CCSS) ?? false;
  const hasEditBasePermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  // NB: Setting to use Business permissions until we have the FULL_CUSTOM_FIELDS permission set up (as below)
  const hasCustomFieldAccess = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });

  React.useEffect(() => {
    setActiveTabKey((activeTab ?? 'profile') as BusinessProfileTab);
    history.push(`/businesses/${reduxBusiness?.id ?? entityId}/${activeTabKey}`);
  }, [activeTab]);

  const getSubtitle = useCallback(() => {
    if (business) {
      if (user?.isInternal) {
        return business.id;
      } else if (business.address && business.address.state) {
        return `${business.address.city}, ${business.address.state.split('-')[1]}`;
      }
    }
    return '';
  }, [business, user]);

  return (
    <ProfilePageWrapper
      title={business?.name || 'Business Profile'}
      subtitle={getSubtitle()}
      loading={loading}
      dataTypeLabel={business?.dataType ? dataType[business.dataType] : dataType.PROD}
      avatarUrl={business?.avatar?.url}
      onAvatarClick={(file: File) => {
        updateAvatarFn({ id: business?.id ?? '', avatar: file, useNewUpdate: k2NewUpdateCenter ? true : false });
      }}
      avatarLoading={avatarLoading}
      readOnlyAvatar={!hasEditBasePermission}
      applyPadding={false}
    >
      {error && <Alert variant="danger">{error.message}</Alert>}
      <Tabs
        id="business-profile-tab-group"
        defaultActiveKey="profile"
        activeKey={activeTabKey}
        onSelect={(key) => {
          setActiveTabKey(key as BusinessProfileTab);
          history.push(`/businesses/${reduxBusiness?.id ?? ':id'}/${key}`);
        }}
        style={{
          paddingLeft: '16px',
          position: 'sticky',
          top: '119px',
          zIndex: 900,
        }}
        className="mb-0"
      >
        <Tab eventKey="profile" title="Profile">
          {business && (
            <PageWrapperBody>
              <ProfileTab business={business} loading={loading} />{' '}
            </PageWrapperBody>
          )}
        </Tab>
        {user?.isInternal && (
          <Tab eventKey="ktAdmin" title="KT Admin">
            <PageWrapperBody>
              {business && (
                <KTAdminTab
                  entityId={business.id}
                  businessName={business.name}
                  loading={loading}
                  dataType={business?.dataType ? business.dataType : dataType.PROD.value}
                />
              )}
            </PageWrapperBody>
          </Tab>
        )}
        {isRegion('AU') && hasCcssAccess && (
          <Tab eventKey="ccsProvider" title="CCS Provider">
            <PageWrapperBody>{business && <CcssProviderTab business={business} />}</PageWrapperBody>
          </Tab>
        )}

        {isRegion('AU') && hasCcssAccess && (
          <Tab eventKey="returnFeeReduction" title="Return Fee Reduction">
            <PageWrapperBody>
              <ReturnFeeReductionTab entityId={entityId} />
            </PageWrapperBody>
          </Tab>
        )}

        {k2AttendanceControl && (
          <Tab eventKey="attendance-history" title={t('attendance.history-control.tab-title')}>
            <PageWrapperBody>
              <AttendanceControlTab businessId={business?.id} />
            </PageWrapperBody>
          </Tab>
        )}

        {hasCustomFieldAccess && (
          <Tab eventKey="customDataFields" title={t('custom-data-fields.tab-title')}>
            <PageWrapperBody>
              <CustomDataFieldsTab entityId={entityId} />
            </PageWrapperBody>
          </Tab>
        )}
      </Tabs>
    </ProfilePageWrapper>
  );
};

const BusinessProfilePage: React.FC<IProps> = ({ ...props }) => {
  const userEntityId = useSelector((state: RootState) => state.user?.entityId);
  const [selectedEntityId, setSelectedEntityId] = useState<string | undefined>(
    props.match.params.id !== ':id' ? props.match.params.id : ''
  );
  const entityId = userEntityId || selectedEntityId;
  const { activeTab } = props.match.params;

  return (
    <div>
      {!userEntityId && (
        <div style={{ maxWidth: '500px' }}>
          <BusinessSelect
            selectedBusinessId={selectedEntityId}
            onSelectBusiness={(entity) => setSelectedEntityId(entity.id)}
            className="p-4"
          />
        </div>
      )}
      {entityId && <BusinessProfile entityId={entityId} activeTab={activeTab} />}
    </div>
  );
};

export default BusinessProfilePage;
