import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { COUNTRY_CODES, countryCodeToFlag } from './countryCodes';
import Select from 'shared/components/Select';

/**
 * Note: if you ever find yourself using this component in react-bootstrap's <Card />,
 * you may have to provide `menuPortalTarget={document.body}` as a prop to ensure the menu portal displays outside of the card's boundaries
 */
const CountrySelect = ({ onSelect, countryCode, className, ...props }) => {
  const handleSelect = useCallback(
    (option) => {
      onSelect(option.code);
    },
    [onSelect]
  );

  const countryFlag = useCallback((code, fallback) => {
    const src = countryCodeToFlag(code);

    return src ? <img className="d-flex" src={src} alt={code} /> : <span>{fallback}</span>;
  }, []);

  return (
    <Select
      {...props}
      className={`${className} mb-0`}
      aria-label="Select the country code of your phone number"
      value={COUNTRY_CODES[countryCode]}
      options={Object.values(COUNTRY_CODES)}
      formatOptionLabel={({ value, label, code }, { context }) =>
        context === 'value' ? (
          countryFlag(code, value)
        ) : (
          <div className="d-flex d-flex-row align-items-center">
            <span className="flex-grow-1 text-nowrap">{label}</span>
            <span className="align-self-end text-nowrap">{value}</span>
          </div>
        )
      }
      onChange={handleSelect}
      isOptionSelected={(option, selectValue) => selectValue.some((i) => i === option)}
    />
  );
};

CountrySelect.propTypes = {
  onSelect: PropTypes.func,
  countryCode: PropTypes.string,
};

export default CountrySelect;
