import React from 'react';
import moment from 'moment';

export interface ITimezoneContext {
  timezone: Timezone;
}

export const TimezoneContext = React.createContext<ITimezoneContext>({
  timezone: moment.tz.guess() as Timezone, // default value will be the timezone of the browser
});
