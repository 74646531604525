import React from 'react';
import {
  LazyQueryHookOptions,
  MutationHookOptions,
  QueryHookOptions,
  SubscriptionHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
  ApolloClient,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { NormalizedCacheObject } from '@apollo/client/cache';

export const createHooks = (contextType: React.Context<ApolloClient<NormalizedCacheObject>>) => {
  const useApolloClient = () => React.useContext(contextType);

  return {
    useApolloClient,

    useQuery<TData, TVariables>(query: DocumentNode, options?: QueryHookOptions<TData, TVariables>) {
      const client = useApolloClient();

      return useQuery<TData, TVariables>(query, { ...options, client });
    },

    useLazyQuery<TData, TVariables>(query: DocumentNode, options?: LazyQueryHookOptions<TData, TVariables>) {
      const client = useApolloClient();

      return useLazyQuery<TData, TVariables>(query, { ...options, client });
    },

    useMutation<TData, TVariables>(mutation: DocumentNode, options?: MutationHookOptions<TData, TVariables>) {
      const client = useApolloClient();

      return useMutation<TData, TVariables>(mutation, { ...options, client });
    },

    useSubscription<TData, TVariables>(
      subscription: DocumentNode,
      options?: SubscriptionHookOptions<TData, TVariables>
    ) {
      const client = useApolloClient();

      return useSubscription<TData, TVariables>(subscription, { ...options, client });
    },
  };
};
