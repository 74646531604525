import React from 'react';
import { RowWithMb } from 'shared/components/Layout';
import Line from 'shared/components/LoadingSkeletons/Line';

interface IProps {
  number?: number;
}

const LoadingLines: React.FC<IProps> = ({ number = 4 }) => {
  return (
    <>
      {[...Array(number)].map((curr, i) => (
        <RowWithMb key={i}>
          <Line />
        </RowWithMb>
      ))}
    </>
  );
};

export default LoadingLines;
