import React from 'react';
import moment from 'moment';
import Card from 'shared/components/Card';
import { currencyFormat } from 'shared/util/currency';
import { DefaultCountry } from 'shared/components/DateInput/countryCodes';
import { Row } from 'shared/components/Layout';
import { isRegion } from 'shared/util/region';
import { appendClassName } from 'shared/util/classNameHelpers';
import Currency from 'shared/components/Currency';

interface IProps {
  balance: number;
  pendingBalance: number;
  nextDueDate: string;
  loading: boolean;
  className?: string;
}

const BalanceCard: React.FC<IProps> = ({ balance, pendingBalance, nextDueDate, loading, className = '' }) => {
  return (
    <Card
      bodyClassName="p-4"
      loading={loading}
      loadingLines={2}
      style={{ minWidth: '300px' }}
      className={appendClassName(className, 'account-balance-card')}
    >
      <Row noGutters className="mb-2">
        <h5 className="mr-auto">Total Balance</h5>
        <h3>
          <Currency aggregate displaySign forceColor amount={balance} />
        </h3>
      </Row>
      <div className="sm mb-2">
        Includes{' '}
        <b>
          [{pendingBalance > 0 && '+'}
          {currencyFormat(pendingBalance)}]
        </b>{' '}
        pending payment.
      </div>
      {nextDueDate && (
        <small className="next-billing-date">
          {isRegion('US') ? 'Next payment due on: ' : 'Next billed on: '}
          {moment(nextDueDate).format(DefaultCountry.dateFormat)}
        </small>
      )}
    </Card>
  );
};

export default BalanceCard;
