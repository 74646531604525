import React from 'react';
import { Link, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Logo from 'shared/components/Logo/Logo';

interface IProps {
  isAuthed: boolean;
  redirect?: boolean;
}

const LoggedOutLayout: React.FC<IProps & RouteComponentProps> = ({
  children,
  isAuthed,
  location,
  redirect = true,
  ...props
}) => {
  if (isAuthed && redirect) return <Redirect to="/" />;
  return (
    <div className="bg-white h-100">
      <header className="position-sticky p-8 d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex align-items-center">
          <a className="d-flex no-underline" href="https://kangarootime.com/">
            <Logo type="icon" size="x-small" />
            <h4 className="font-logo pl-2 d-none d-sm-block">Kangarootime </h4>
          </a>
        </div>
        {!location.pathname.startsWith('/login') && (
          <span>
            Already have an account?
            <Link to="/login">
              <Button className="ml-4" variant="outline-secondary">
                Log In
              </Button>
            </Link>
          </span>
        )}
      </header>
      <main className="logged-out-body">{children}</main>
    </div>
  );
};

export default withRouter(LoggedOutLayout);
