import React from 'react';
import Tag from './Tag';
import TagsContainer from './TagsContainer';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Row } from 'shared/components/Layout';
import './_tag.scss';

interface IProps {
  tags: ITag[];
}

const TagsTableCell: React.FC<IProps> = ({ tags }) => {
  const showMore = tags && tags.length > 2;
  const firstTwoTags = tags.slice(0, 2);
  return (
    <Row noGutters className="flex-nowrap">
      {firstTwoTags.map((tag, i) => (
        <Tag tag={tag} key={i} className="mr-2" />
      ))}
      {showMore && (
        <OverlayTrigger
          trigger="hover" // click trigger isn't working when entire row click triggers event
          placement="bottom"
          overlay={
            <Popover id="tags">
              <Popover.Title as="h5">Tags</Popover.Title>
              <Popover.Content>
                <TagsContainer tags={tags} />
              </Popover.Content>
            </Popover>
          }
        >
          <button type="button" className="tag">
            ...
          </button>
        </OverlayTrigger>
      )}
    </Row>
  );
};

export default TagsTableCell;
