import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import Alert from 'shared/components/Alert';
import { Col, Row } from 'shared/components/Layout';
import { capitalize } from 'lodash';
import { Form } from 'react-bootstrap';
import { orderBy } from 'lodash';
import { showToast } from 'shared/components/Toast';
import { useSearchCenters } from 'gql/center/queries';
import { SEARCH_MY_CENTERS } from 'pages/Centers/subroutes/Profiles/graphql/fields';
import { getCentersSuccess } from 'pages/Centers/duck/actions';
import { useTranslation } from 'react-i18next';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select/Select';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
import useDatatableState from 'shared/hooks/useDatatableState';
import { usePostKindyForAllForecast } from 'gql/kindyForAll/mutations';
import { useGetKindyForAllForecastErrorsReportLazy } from 'gql/reports/queries';
import { useGetKindyForAllCenterSummaries } from 'gql/kindyForAll/queries';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { getYearOptionsFromYear, getTermOptions } from '../utils';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons';
import Spinner from 'shared/components/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { IKindyForAllSubmissionError } from 'shared/types/kindyForAll';

type ModalMode = 'Prepare Forecast' | 'Submit Forecast' | 'Download Error Report';
type AlertMode = 'None' | 'Info' | 'Warning' | 'Error';

interface IFormStateShape {
  centerIds: string[];
  year: number;
  period: string | null;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  year: number;
  onClose: () => void;
}

const KindyForAllForecastSubmitModal: React.FC<IProps> = ({ isOpen, isLoading, year, onClose, ...props }) => {
  const [formData, setFormData] = useState<IFormStateShape>({
    centerIds: [],
    year: year,
    period: null,
  });
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const initialTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('center', initialTableSort);

  // TODO: Don't query for all centers via ... datatable??
  // We have useGetActiveCentersWithLoading
  if (tableState.pageSize != 640) {
    tableFunctions.changeSizePerPage(640);
  }

  useSearchCenters(
    {
      variables: {
        input: tableFunctions.getElasticQuery(),
      },
      onCompleted: (data) => {
        dispatch(getCentersSuccess(data.searchCenters.data ?? []));
      },
    },
    SEARCH_MY_CENTERS
  );

  const selectedBusinessId = useSelector((state: RootState) => state.context.businessId);
  const centers = useSelector((state: RootState) => state.centers.all)?.filter((c) => c.address.state === 'AU-QLD');
  const centersForBusiness = React.useMemo(
    () => orderBy(centers?.filter((c) => c.entityId === selectedBusinessId) || [], (c) => c.name, 'asc'),
    [selectedBusinessId, centers]
  );

  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context)?.businessId ?? user?.entityId;
  const [selectedCenters, setSelectedCenters] = React.useState<ICenter[] | null>(null);
  const [modalMode, setModalMode] = useState<ModalMode>('Prepare Forecast');
  const [alertMode, setAlertMode] = useState<AlertMode>('None');
  const [errors, setErrors] = useState<IKindyForAllSubmissionError[]>();
  const [reportLoading, setReportLoading] = useState<boolean>();
  const [submissionExist, setSubmissionExist] = useState<boolean>(false);
  const reportDataToFile = useReportDataToFile();

  const { data: data, loading: submissionLoading } = useGetKindyForAllCenterSummaries({
    variables: {
      input: {
        centerIds: formData.centerIds ?? [],
        year: Number(formData.year) ?? 2023,
        period: formData.period ?? 'P1',
        searchKey: '',
        pageNumber: 1,
        pageSize: 1000,
        sortBy: 'centerName',
        sortDirection: 'ASCENDING',
      },
    },
    skip: businessId == null || businessId === '' || !formData.year || !formData.period || !formData.centerIds,
  });

  const handleCentersChange = (centers) => {
    setSelectedCenters(centers);
    const centerIds = centers.map((c) => c.id);
    setFormData((state) => ({
      ...state,
      centerIds: centerIds,
    }));
  };
  useEffect(() => {
    setSubmissionExist(false);
    var validSubmission = data?.getKindyForAllCenterSummaries.data?.find(
      (d) =>
        d.year === formData.year &&
        d.period === 'P' + formData.period &&
        formData.centerIds.includes(d.centerId) &&
        d.status === 'Submitted'
    );
    if (validSubmission) {
      setSubmissionExist(true);
    }
  }, [data?.getKindyForAllCenterSummaries.data, formData.centerIds, formData.period, formData.year]);

  const [postKindyForAllForecastFn, { loading }] = usePostKindyForAllForecast({
    onCompleted: (result) => {
      if (result) {
        if (result.postKindyForAllForecast.find((a) => a.errors.length)) {
          var errorList = result.postKindyForAllForecast.flatMap((f) =>
            f?.errors?.map(
              (e) =>
                ({
                  centerId: f.centerId,
                  centerName: f.centerName,
                  year: formData.year.toString(),
                  period: formData.period,
                  code: e?.code,
                  message: e?.message,
                  target: e?.target,
                  innerError: e?.innerError,
                } as IKindyForAllSubmissionError)
            )
          );
          setErrors(errorList as IKindyForAllSubmissionError[]);
          //Temporarily setting this as warning mode ..
          // TODO: come back to discriminate warnings vs errors
          if (modalMode === 'Prepare Forecast') {
            setModalMode('Submit Forecast');
            setAlertMode('Warning');
          } else if (modalMode === 'Submit Forecast') {
            showToast('Forecast submitted successfully (with warnings).', 'success');
            handleClose();
          }
          //setModalMode('Download Report Error');
          //setAlertMode('Error');
        } else {
          if (alertMode === 'Info') {
            // Forecast submitted successfully
            showToast('Forecast submitted successfully.', 'success');
            handleClose();
          } else {
            // if dryRun is ok
            setModalMode('Submit Forecast');
            setAlertMode('Info');
          }
        }
        //To Do
        // if dryRun is with warning
        //if (result.postKindyForAllForecast.find((a) => a.warnings)) {
        //   setModalMode('Submit Forecast');
        //   setAlertMode('Warning');
        // }
      }
    },
    onError: (error) => {
      showToast(`There was an error submitting Forecast : ${error.message}`, 'error');
    },
  });

  const [kindyForAllForecastErrorsReportFn] = useGetKindyForAllForecastErrorsReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(
        result.getKindyForAllForecastErrorsReport,
        ReportTypeEnum.KINDY_FOR_ALL_FORECAST_ERRORS
      );
      onClose();
    },
    onError: () => {
      showToast(t('reports.general-failure-message'), 'error');
    },
  });

  const [kindyForAllForecastWarningsReportFn] = useGetKindyForAllForecastErrorsReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(
        result.getKindyForAllForecastErrorsReport,
        ReportTypeEnum.KINDY_FOR_ALL_FORECAST_ERRORS
      );
      setReportLoading(false);
    },
    onError: () => {
      showToast(t('reports.general-failure-message'), 'error');
    },
  });

  const downloadErrorReport = () => {
    if (errors) {
      setReportLoading(true);
      kindyForAllForecastWarningsReportFn({
        variables: {
          input: {
            businessId: businessId,
            errors: errors,
          },
        },
      });
    }
  };

  const handleSubmit = useCallback(() => {
    if (formData.centerIds && formData.year && formData.period) {
      switch (modalMode) {
        case 'Submit Forecast':
          postKindyForAllForecastFn({
            variables: {
              input: {
                businessId: businessId,
                centerIds: formData.centerIds,
                year: formData.year.toString(),
                period: formData.period,
                dryRun: false,
              },
            },
          });

          break;
        case 'Prepare Forecast':
          postKindyForAllForecastFn({
            variables: {
              input: {
                businessId: businessId,
                centerIds: formData.centerIds,
                year: formData.year.toString(),
                period: formData.period,
                dryRun: true,
              },
            },
          });

          break;
        case 'Download Error Report':
          if (errors) {
            kindyForAllForecastErrorsReportFn({
              variables: {
                input: {
                  businessId: businessId,
                  errors: errors,
                },
              },
            });
          }
          break;
      }
    }
  }, [
    businessId,
    errors,
    formData.centerIds,
    formData.period,
    formData.year,
    kindyForAllForecastErrorsReportFn,
    modalMode,
    postKindyForAllForecastFn,
  ]);

  const handleClose = useCallback(() => {
    setSelectedCenters(null);
    setModalMode('Prepare Forecast');
    setAlertMode('None');
    setErrors(undefined);
    setSubmissionExist(false);
    setFormData({
      centerIds: [],
      year: year,
      period: null,
    });
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!isOpen) {
      handleClose();
    }
  }, [handleClose, isOpen]);

  return (
    <SideModalDrawer
      title={modalMode}
      show={isOpen}
      onHide={handleClose}
      primaryChoice={modalMode}
      primaryCallback={() => handleSubmit()}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      primaryButtonProps={{
        loading: loading || submissionLoading,
        disabled: !formData.centerIds.length || !formData.period || !formData.year || submissionExist,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      hasPrimaryChoice
    >
      <div>
        {modalMode != 'Prepare Forecast' && (
          <div>
            <Row className="mb-4">
              <Col>
                <Row>
                  <label>{capitalize(t('spelling.center_plural'))}</label>
                </Row>
                <Row>
                  <ul>
                    {selectedCenters?.length === centers.length ? (
                      <li>
                        <h6>All Centers</h6>
                      </li>
                    ) : (
                      selectedCenters?.map((c) => (
                        <li>
                          <h6>{`${c?.name}`}</h6>
                        </li>
                      ))
                    )}
                  </ul>
                </Row>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg={6}>
                <Row>
                  <label>Year</label>
                </Row>
                <Row>
                  <h6>{formData.year}</h6>
                </Row>
              </Col>
              <Col lg={6}>
                <Row>
                  <label>Term</label>
                </Row>
                <Row>
                  <h6>Term {formData.period}</h6>
                </Row>
              </Col>
            </Row>
            {alertMode == 'Info' && (
              <Row>
                <Col>
                  <Alert variant="info" className="mt-4">
                    <div>Validation successful! Submit your forecast below.</div>
                  </Alert>
                </Col>
              </Row>
            )}
            {alertMode == 'Warning' && (
              <Row>
                <Col>
                  <Alert variant="warning" className="mt-4">
                    {/* TODO: Update this back to just warnings when we discriminate between warnings and errors */}
                    <div className="ml-2">
                      <Row>
                        Warnings / Errors found. Click "Submit Forecast" to continue, or download the warning report for
                        guidance to correct any records.
                      </Row>
                      <br />
                      <Row>
                        <Link
                          to="#"
                          className="text-gray pointer"
                          style={{ textDecoration: 'none' }}
                          onClick={() => downloadErrorReport()}
                        >
                          <div>
                            <FontAwesomeIcon className="text-gray mr-2" icon={faArrowToBottom} />
                            Download warning / error report
                            {reportLoading && <Spinner className="text-gray ml-2" small />}
                          </div>
                        </Link>
                      </Row>
                    </div>
                  </Alert>
                </Col>
              </Row>
            )}
            {alertMode == 'Error' && (
              <Row>
                <Col>
                  <Alert variant="danger" className="mt-4">
                    Errors found. Download the error report below for more information. Correct the errors, then try
                    again.
                  </Alert>
                </Col>
              </Row>
            )}
          </div>
        )}
        {modalMode == 'Prepare Forecast' && (
          <Form>
            <Row className="mb-2">
              <Col>
                <SelectMultiple
                  required
                  label={capitalize(t('spelling.center_plural'))}
                  newLabelStyle
                  onChange={(prev) => {
                    handleCentersChange(prev);
                  }}
                  options={centersForBusiness}
                  value={selectedCenters}
                  getOptionLabel={(option: ICenter) => option.name}
                  getOptionValue={(option: ICenter) => option.id}
                  displayCountInContainerRenderer={(selectedCount) =>
                    selectedCount > 0
                      ? selectedCount +
                        ' ' +
                        (selectedCount === 1
                          ? capitalize(t('spelling.center'))
                          : capitalize(t('spelling.center_plural'))) +
                        ' Selected'
                      : `Select a ${capitalize(t('spelling.center'))}`
                  }
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={6}>
                <Select
                  disabled={year <= 2023}
                  options={getYearOptionsFromYear(year)}
                  label="Year"
                  required
                  getOptionLabel={(g) => g.label}
                  getOptionValue={(g) => g.value}
                  name="year"
                  value={formData?.year}
                  onChange={(val) =>
                    setFormData((prev) => ({
                      ...prev,
                      year: val.value,
                    }))
                  }
                />
              </Col>
              <Col lg={6}>
                <Select
                  options={getTermOptions()}
                  label="Term"
                  required
                  getOptionLabel={(g) => g.label}
                  getOptionValue={(g) => g.value}
                  name="period"
                  value={formData?.period}
                  onChange={(val) =>
                    setFormData((prev) => ({
                      ...prev,
                      period: val.value,
                    }))
                  }
                />
              </Col>
            </Row>
          </Form>
        )}
        {submissionExist && (
          <Row>
            <Col>
              <Alert variant="danger" className="mt-4">
                A forecast has already been submitted for
                {data?.getKindyForAllCenterSummaries.data
                  ?.filter((c) => formData.centerIds.includes(c.centerId))
                  .map((cn) => ' ' + cn.centerName + ', ')}
                year {formData.year}, term {formData.period}. To re-submit, please delete existing forecast and try
                again.
              </Alert>
            </Col>
          </Row>
        )}
      </div>
    </SideModalDrawer>
  );
};

export default KindyForAllForecastSubmitModal;
