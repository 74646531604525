import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Auth from '@aws-amplify/auth';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { updateSessionToken } from 'pages/Login/duck/actions';

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const refreshToken = useCallback(async () => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const refreshToken = session.getRefreshToken();

      user.refreshSession(refreshToken, (err, session: CognitoUserSession) => {
        if (err) {
          console.log('Error refreshing token: ', err);
        }

        if (session) {
          const idToken = session.getIdToken();
          const jwtToken = idToken.getJwtToken();

          dispatch(updateSessionToken(jwtToken));
        }
      });
    } catch (error) {
      console.log('Unable to refresh Token: ', error);
    }
  }, [dispatch]);

  return { refreshToken };
};

export default useRefreshToken;
