import React, { useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'shared/components/Buttons';
import Tooltip from 'shared/components/Tooltip';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import { TableHeader, TableSearch } from 'shared/components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';
import { useTranslation } from 'react-i18next';
import { ISubsidyChildPaymentTableStateShape } from './SubsidyPayment';
import { currencyFormat } from 'shared/util/currency';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';

export type ChildAgencyPaymentTableColumnsType =
  | 'childName'
  | 'accountName'
  | 'sessionCount'
  | 'expectedAmount'
  | 'paidAmount'
  | 'differenceAmount';

export interface IChildAgencyPaymentTableRowShape {
  /**
   * the index of this item in the list when first put into state.
   * filtering and sorting is read from this list so we need to know
   * what index to update in the original list
   */
  initialListIndex: number;
  id: string | null;
  accountId: string;
  accountChildId: string;
  accountChildEnrollmentId: string;
  childFirstName: string;
  childLastName: string;
  childAgencyId: string | null;
  accountName: string;
  contractedNumberOfSessions: number;
  expectedSubsidyAmount: number;
  amountPaidAmount: number;
  differenceAction: ChildAgencyPaymentDifferenceAction;
  adjustmentId: string | null;
  differenceActionReason: string;
}

interface IProps {
  data: IChildAgencyPaymentTableRowShape[];
  agencyPaymentCheckTotal: number;
  isLoading: boolean;
  readOnly: boolean;
  tableState: ISubsidyChildPaymentTableStateShape;
  onManageChildAgencyPayment: (tableRow: IChildAgencyPaymentTableRowShape) => void;
  onSearch: (term: string) => void;
  onSort: (field: ChildAgencyPaymentTableColumnsType, direction: 'asc' | 'desc') => void;
  onAmountPaidRowChange: (amount: number, rowNumber: number) => void;
}

const ChildPaymentsTable: React.FC<IProps> = ({
  data,
  agencyPaymentCheckTotal,
  isLoading,
  readOnly,
  tableState,
  onManageChildAgencyPayment,
  onSearch,
  onSort,
  onAmountPaidRowChange,
  ...props
}) => {
  const { t } = useTranslation(['subsidies']);

  const handleColumnSortClick = useCallback(
    (event: React.MouseEvent<HTMLTableHeaderCellElement>, column: ChildAgencyPaymentTableColumnsType) => {
      event.preventDefault();
      onSort(column, tableState.columnSorts[column] === 'desc' ? 'asc' : 'desc');
    },
    [tableState, onSort]
  );

  const getTooltipText = useCallback(
    (paidAmount: number): string => {
      return paidAmount > agencyPaymentCheckTotal
        ? t('subsidies:agency-billing.new-subsidy-payment.more-than-total-tooltip', {
            amount: currencyFormat(Math.abs(agencyPaymentCheckTotal - paidAmount)),
          })
        : t('subsidies:agency-billing.new-subsidy-payment.less-than-total-tooltip', {
            amount: currencyFormat(Math.abs(agencyPaymentCheckTotal - paidAmount)),
          });
    },
    [t, agencyPaymentCheckTotal]
  );

  const calculateDifferenceForAgencyPayment = useCallback((tableRow: IChildAgencyPaymentTableRowShape): number => {
    return (tableRow.amountPaidAmount ?? 0) - tableRow.expectedSubsidyAmount;
  }, []);

  if (isLoading) {
    return <DataTableLoadingSkeleton />;
  }

  return (
    <div>
      <TableHeader>
        <TableSearch placeholder="Search" onChange={onSearch} />
      </TableHeader>
      <Table responsive className="kt-subsidy-payment-children-table">
        <thead>
          <tr>
            <th onClick={(event) => handleColumnSortClick(event, 'childName')}>
              {t('subsidies:agency-billing.new-subsidy-payment.child-table-column')}
              <FontAwesomeIcon
                icon={tableState.columnSorts['childName'] === 'desc' ? faAngleDown : faAngleUp}
                className="ml-4"
                color={colors.dark}
              />
            </th>
            <th>{t('subsidies:agency-billing.new-subsidy-payment.child-id-table-column')}</th>
            <th onClick={(event) => handleColumnSortClick(event, 'accountName')}>
              {t('subsidies:agency-billing.new-subsidy-payment.account-table-column')}
              <FontAwesomeIcon
                icon={tableState.columnSorts['accountName'] === 'desc' ? faAngleDown : faAngleUp}
                className="ml-4"
                color={colors.dark}
              />
            </th>
            <th onClick={(event) => handleColumnSortClick(event, 'sessionCount')}>
              {t('subsidies:agency-billing.new-subsidy-payment.session-count-table-column')}
              <FontAwesomeIcon
                icon={tableState.columnSorts['sessionCount'] === 'desc' ? faAngleDown : faAngleUp}
                className="ml-4"
                color={colors.dark}
              />
            </th>
            <th onClick={(event) => handleColumnSortClick(event, 'expectedAmount')}>
              {t('subsidies:agency-billing.new-subsidy-payment.expected-amount-table-column')}
              <FontAwesomeIcon
                icon={tableState.columnSorts['expectedAmount'] === 'desc' ? faAngleDown : faAngleUp}
                className="ml-4"
                color={colors.dark}
              />
            </th>
            <th
              onClick={(event) => {
                if (readOnly) handleColumnSortClick(event, 'paidAmount');
              }}
            >
              {t('subsidies:agency-billing.new-subsidy-payment.paid-amount-table-column')}
              {readOnly && (
                <FontAwesomeIcon
                  icon={tableState.columnSorts['paidAmount'] === 'desc' ? faAngleDown : faAngleUp}
                  className="ml-4"
                  color={colors.dark}
                />
              )}
            </th>
            <th onClick={(event) => handleColumnSortClick(event, 'differenceAmount')}>
              {t('subsidies:agency-billing.new-subsidy-payment.difference-amount-table-column')}
              <FontAwesomeIcon
                icon={tableState.columnSorts['differenceAmount'] === 'desc' ? faAngleDown : faAngleUp}
                className="ml-4"
                color={colors.dark}
              />
            </th>
            <th />
          </tr>
          <tr className="bg-secondary">
            <th className="text-white" colSpan={4}>
              TOTALS
            </th>
            <th className="text-white">
              {currencyFormat(data.reduce((acc, curr) => (acc += curr.expectedSubsidyAmount ?? 0), 0))}
            </th>
            <th className="text-white">
              {currencyFormat(data.reduce((acc, curr) => (acc += curr.amountPaidAmount ?? 0), 0))}
              <span className="ml-4">
                <Tooltip
                  text={getTooltipText(data.reduce((acc, curr) => (acc += curr.amountPaidAmount ?? 0), 0))}
                  direction="top"
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </th>
            <th className="text-white">
              {currencyFormat(data.reduce((acc, curr) => (acc += calculateDifferenceForAgencyPayment(curr)), 0))}
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.map((datum, idx) => (
            <tr key={idx}>
              <td>
                {datum.childFirstName} {datum.childLastName}
              </td>
              <td>{Boolean(datum.childAgencyId) ? datum.childAgencyId : '--'}</td>
              <td>{datum.accountName}</td>
              <td>{`${datum.contractedNumberOfSessions} ${
                datum.contractedNumberOfSessions === 1 ? 'day' : 'days'
              }`}</td>
              <td>{currencyFormat(datum.expectedSubsidyAmount)}</td>
              <td>
                {readOnly ? (
                  currencyFormat(datum.amountPaidAmount)
                ) : (
                  <CurrencyInput
                    value={datum.amountPaidAmount}
                    onChange={(value) => onAmountPaidRowChange(value, datum.initialListIndex)}
                    // never allow the input to be blank, revert back to the expected amount if an input is ever empty and not focused
                    onBlur={() =>
                      datum.amountPaidAmount === null || datum.amountPaidAmount === undefined
                        ? onAmountPaidRowChange(datum.expectedSubsidyAmount, datum.initialListIndex)
                        : {}
                    }
                  />
                )}
              </td>
              <td>
                {currencyFormat(calculateDifferenceForAgencyPayment(datum))}
                <span className="ml-2">
                  {datum.differenceAction === 'ADJUST'
                    ? '(adjust)'
                    : datum.differenceAction === 'TRANSFER_TO_ACCOUNT'
                    ? '(transfer)'
                    : ''}
                </span>
              </td>
              <td>
                <Button
                  variant={calculateDifferenceForAgencyPayment(datum) === 0 ? 'secondary' : 'outline-secondary'}
                  onClick={() => onManageChildAgencyPayment(datum)}
                  disabled={calculateDifferenceForAgencyPayment(datum) === 0}
                >
                  Manage
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ChildPaymentsTable;
