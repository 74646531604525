import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import PageWrapper from 'shared/components/PageWrapper';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import AddCenterToAccountModal from './Account/AddCenterToAccountModal';
import AccountsTable from './components/AccountsTable';
import AddAccountModalForm from './components/AddAccountModalForm';

interface IRouteProps {
  autoOpenSideModal?: boolean;
}

interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

interface IAccountModalState {
  show: boolean;
  account: IAccount | null;
}

const Accounts: React.FC<IProps> = ({ ...props }) => {
  const [showAddAccountModal, setShowAccountModal] = useState(props.location.state?.autoOpenSideModal ?? false);
  const [addCenterToAccountModal, setAddCenterToAccountModalState] = useState<IAccountModalState>({
    show: false,
    account: null,
  });

  return (
    <PageWrapper
      pageTitle="Accounts"
      mobileButtonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Account, permission: PermissionType.Base, level: RoleLevelType.Create }}
        >
          <CirclePlusButton variant="primary" className="my-4" onClick={() => setShowAccountModal(true)} />
        </HasRoleAreaLevel>
      }
      buttonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Account, permission: PermissionType.Base, level: RoleLevelType.Create }}
        >
          <CreateButton onClick={() => setShowAccountModal(true)}>Add Account</CreateButton>
        </HasRoleAreaLevel>
      }
    >
      <AccountsTable
        showAddCenterToAccountModal={(account: IAccount) =>
          setAddCenterToAccountModalState((prev) => ({ ...prev, show: true, account }))
        }
      />
      <AddAccountModalForm isOpen={showAddAccountModal} onClose={() => setShowAccountModal(false)} />
      {addCenterToAccountModal.account && (
        <AddCenterToAccountModal
          isOpen={addCenterToAccountModal.show}
          account={addCenterToAccountModal.account}
          onClose={() => setAddCenterToAccountModalState((prev) => ({ ...prev, show: false, account: null }))}
        />
      )}
    </PageWrapper>
  );
};

export default Accounts;
