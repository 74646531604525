import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'shared/components/Checkbox';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import Switch from 'shared/components/Switch';
import Form from 'react-bootstrap/Form';
import Select from 'shared/components/Select';
import { capitalize } from 'shared/util/string';

interface IFormStateShape {
  centerIds: string[];
  date: string | null;
  transactionDate: TransactionDateType;
  includeActiveAccounts: boolean;
  includeInactiveAccounts: boolean;
  statusList: AccountStatusType[];
  excludeCredit: boolean;
  excludeZero: boolean;
  format: 'CSV' | 'EXCEL';
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  onSubmit: (
    date: string | null,
    centerIds: string[],
    transactionDate: TransactionDateType,
    excludeCredit: boolean,
    excludeZero: boolean,
    format: 'CSV' | 'EXCEL',
    statusType?: AccountStatusType
  ) => void;
  onClose: () => void;
}

const DebtReportModal: React.FC<IProps> = ({ isOpen, isLoading, reportName, onSubmit, onClose, ...props }) => {
  const [formData, setFormData] = useState<IFormStateShape>({
    centerIds: [],
    date: null,
    format: 'CSV',
    transactionDate: 'APPLIES',
    includeActiveAccounts: true,
    includeInactiveAccounts: false,
    statusList: ['Active', 'Inactive', 'Future'],
    excludeCredit: false,
    excludeZero: false,
  });

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        centerIds: [],
        date: null,
        format: 'CSV',
        transactionDate: 'APPLIES',
        includeActiveAccounts: true,
        includeInactiveAccounts: false,
        statusList: ['Active', 'Inactive', 'Future'],
        excludeCredit: false,
        excludeZero: false,
      });
    }
  }, [isOpen]);

  const isValidAccountStatusOptions = useMemo(
    () => formData.includeActiveAccounts || formData.includeInactiveAccounts,
    [formData]
  );

  const isValidDateOption = useMemo(() => formData.date != null, [formData]);

  const isValidCenterOption = useMemo(() => formData.centerIds.length >= 1, [formData]);

  const isDisabled = useMemo(() => isValidDateOption && isValidCenterOption, [isValidDateOption, isValidCenterOption]);

  const accountStatusType = useMemo(() => {
    if (formData.includeActiveAccounts && formData.includeInactiveAccounts) {
      return undefined;
    }

    if (formData.includeActiveAccounts) {
      return 'Active';
    }

    if (formData.includeInactiveAccounts) {
      return 'Inactive';
    }

    return undefined;
  }, [formData]);

  const { t } = useTranslation();

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (isValidDateOption && isValidAccountStatusOptions) {
      onSubmit(
        formData.date,
        formData.centerIds,
        formData.transactionDate,
        formData.excludeCredit,
        formData.excludeZero,
        formData.format,
        accountStatusType
      );
    }
  }, [
    accountStatusType,
    formData.centerIds,
    formData.date,
    formData.transactionDate,
    formData.statusList,
    formData.excludeCredit,
    formData.excludeZero,
    formData.format,
    isValidAccountStatusOptions,
    isValidDateOption,
    onSubmit,
  ]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({
      centerIds: [],
      date: null,
      format: 'CSV',
      transactionDate: 'APPLIES',
      includeActiveAccounts: true,
      includeInactiveAccounts: false,
      statusList: ['Active', 'Inactive', 'Future'],
      excludeCredit: false,
      excludeZero: false,
    });
    onClose();
  }, [onClose]);

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{ disabled: !isDisabled, loading: isLoading }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            isRequired
            selectedCenterIds={formData.centerIds}
            onSelect={(ids) => setFormData((prev) => ({ ...prev, centerIds: ids as string[] }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            label="Date"
            required
            date={formData.date}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, date }))}
            className="kt-date-input-no-max-width"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Switch
            label={t('spelling.periodAccounting')}
            labelSide="top"
            value={formData.transactionDate === 'PERIOD'}
            onChange={(value: boolean) => setFormData({ ...formData, transactionDate: value ? 'PERIOD' : 'APPLIES' })}
            className="mb-4"
            height={30}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Checkbox
            label={capitalize(t('spelling.exclude-credit-balance'))}
            value={formData.excludeCredit}
            onChange={(value) => setFormData({ ...formData, excludeCredit: value })}
          />

          <Checkbox
            label={capitalize(t('spelling.exclude-zero-balance'))}
            value={formData.excludeZero}
            onChange={(value) => setFormData({ ...formData, excludeZero: value })}
          />
        </Col>
      </Row>

      <>
        <Row className="pl-2 mt-2">{t('spelling.accountStatusFilter')}</Row>
        <Row className="pl-2">
          <Checkbox
            label={capitalize(t('spelling.active'))}
            value={formData.includeActiveAccounts}
            onChange={(value) => setFormData({ ...formData, includeActiveAccounts: value })}
          />
        </Row>
        <Row className="pl-2">
          <Checkbox
            label={capitalize(t('spelling.inactive'))}
            value={formData.includeInactiveAccounts}
            onChange={(value) => setFormData({ ...formData, includeInactiveAccounts: value })}
          />
        </Row>
      </>

      <>
        <Row>
          <Col>
            <Form.Label className="mt-4">{capitalize(t('spelling.format'))}</Form.Label>
            <Select
              options={[
                { value: 'CSV', label: 'CSV' },
                { value: 'EXCEL', label: 'EXCEL' },
              ]}
              value={formData.format ?? { value: 'CSV', label: 'CSV' }}
              onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Col>
        </Row>
      </>
    </SideModalDrawer>
  );
};

export default DebtReportModal;
