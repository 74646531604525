export const CREATE_DEPOSIT = 'CREATE_DEPOSIT';
export const RETURN_DEPOSIT = 'CREATE_DEPOSIT';

export interface ICreateDepositAction {
  type: typeof CREATE_DEPOSIT;
  deposits: IDeposit[];
}

export interface IReturnDepositAction {
  type: typeof RETURN_DEPOSIT;
  deposits: IDeposit[];
}

export type DepositActionTypes = ICreateDepositAction | IReturnDepositAction;
