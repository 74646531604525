import * as types from './types';
import moment from 'moment';
import { min, max, sortBy } from 'lodash';

export interface IChildAttendanceStateShape {
  sessions: ISession[];
}

const initialState: IChildAttendanceStateShape = {
  sessions: [],
};

export const childAttendanceReducers = (
  state: IChildAttendanceStateShape = initialState,
  action: types.SessionsActionTypes
): IChildAttendanceStateShape => {
  switch (action.type) {
    case types.GET_EXPECTED_SESSIONS:
      return {
        ...state,
        sessions: action.sessions,
      };
    case types.GET_TIME_ENTRIES:
      const updatedSessions = state.sessions.map((s) => {
        const sessionTimeEntry = action.timeEntries.find(
          (te) =>
            te.sessionId === s.id ||
            (te.contractId &&
              te.contractId === s.contractId &&
              moment(te.date).toISOString() === moment(s.date).toISOString())
        );
        return sessionTimeEntry
          ? {
              ...s,
              id: sessionTimeEntry.sessionId,
              timeEntries: [...s.timeEntries.filter((ste) => ste.id !== sessionTimeEntry.id), sessionTimeEntry],
              charged: true, // kafka will trigger transaction creation upon addition of absence
            }
          : s;
      });

      return {
        ...state,
        sessions: updatedSessions,
      };
    case types.CREATE_SESSION:
      return {
        ...state,
        sessions: sortBy([...state.sessions, action.session], (s) => s.child.lastname),
      };
    case types.UPDATE_SESSION:
      return {
        ...state,
        sessions: state.sessions.map((session) => {
          if (session.id !== action.session.id) {
            return session;
          }

          //filter out previous session absence / then add it back if its in the update
          const existingEnrollmentYearAbsences =
            session.existingEnrollmentYearAbsences?.filter((a) => a.id !== session.absence?.id) ?? [];
          if (action.session.absence) {
            existingEnrollmentYearAbsences.push(action.session.absence);
          }

          return {
            ...session,
            ...action.session,
            existingEnrollmentYearAbsences,
          };
        }),
      };
    case types.UPDATE_SESSIONS:
      return {
        ...state,
        sessions: state.sessions.map((session) => {
          const updates = action.sessions.find((el) => el.id === session.id) || session;

          /**
           * merge the objects instead of just returning `action.sessions.find(el => el.id === session.id) || session`
           * incase the session in the action doesn't have some properties that may exist in redux
           */
          return {
            ...session,
            ...updates,
          };
        }),
      };
    case types.UPDATE_SESSION_AT_ID:
      return {
        ...state,
        sessions: state.sessions.map((s) => (s.id === action.id ? action.session : s)),
      };
    case types.REMOVE_SESSION_BY_ID:
      return {
        ...state,
        sessions: state.sessions.filter((s) => s.id !== action.id),
      };
    case types.REMOVE_SESSION:
      return {
        ...state,
        sessions: state.sessions.filter(
          (s) =>
            !(
              s.id === action.session.id ||
              (s.contractId &&
                s.contractId === action.session.contractId &&
                moment(s.date).toISOString() === moment(action.session.date).toISOString())
            )
        ),
      };
    case types.REPORT_ABSENCE:
      const updatedSessionsWithAbences = state.sessions.map((s) => {
        const absence = action.absences.find(
          (a) =>
            a.sessionId === s.id ||
            (a.contractId &&
              a.contractId === s.contractId &&
              moment(a.date).toISOString() === moment(s.date).toISOString())
        );
        return absence
          ? {
              ...s,
              id: absence.sessionId,
              absence: absence,
              existingEnrollmentYearAbsences: s.existingEnrollmentYearAbsences
                ? [...s.existingEnrollmentYearAbsences, absence]
                : [absence],
              charged: true, // kafka will trigger transaction creation upon addition of absence
            }
          : s;
      });

      return {
        ...state,
        sessions: updatedSessionsWithAbences,
      };

    case types.GET_SESSION_TRANSACTIONS:
      return {
        ...state,
        sessions: state.sessions.map((s) => {
          const sessionTransaction = action.transactions.find(
            (t) =>
              t.sessionId === s.id ||
              (t.session &&
                t.session.contractId &&
                t.session.contractId === s.contractId &&
                moment(t.session.date).toISOString() === moment(s.date).toISOString()) ||
              t.flatRates.some((fr) => fr.sessions.some((frs) => frs.id === s.id)) ||
              t.flatRates.some((fr) =>
                fr.sessions.some(
                  (frs) =>
                    frs.contractId === s.contractId && moment(s.date).toISOString() === moment(frs.date).toISOString()
                )
              )
          );
          return sessionTransaction ? { ...s, id: sessionTransaction.sessionId ?? s.id, charged: true } : s;
        }),
      };
    case types.REMOVE_SESSION_TIME_ENTRY:
      return {
        ...state,
        sessions: state.sessions.map((s) => ({
          ...s,
          timeEntries:
            s.id === action.timeEntry.sessionId
              ? s.timeEntries.filter((te) => te.id !== action.timeEntry.id)
              : s.timeEntries,
        })),
      };
    default:
      return state;
  }
};
