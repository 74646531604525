import React from 'react';
import Card, { CardActionDropdown } from 'shared/components/Card';
import { RowWithMb, Row, Col } from 'shared/components/Layout';
import spaceIcons from 'shared/constants/SpaceIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Circle } from 'shared/components/Shapes';
import SpaceCardLoading from './SpaceCardLoading';
import colors from '_colors.module.scss';

interface IDropdownAction {
  label: string;
  onClick: () => void;
}

interface IProps {
  space: ISpace;
  canPerformEdit: boolean;
  actions: IDropdownAction[];
  loading?: boolean;
  className?: string;
}

const SpaceCard: React.FC<IProps> = ({ space, canPerformEdit, actions, loading, className }) => {
  if (loading) {
    return <SpaceCardLoading className={className} />;
  }
  return (
    <Card className={`text-center h-md ${className || ''}`}>
      <Col>
        <Row justify="end" className="mt-n4 mr-n4">
          <CardActionDropdown disabled={!canPerformEdit} actions={actions} />
        </Row>
        <RowWithMb justify="center">
          <Circle size="xl" background={colors.paleGrey}>
            <FontAwesomeIcon color="#516173" size="3x" icon={spaceIcons[space.icon]} className="m-auto" />
          </Circle>
        </RowWithMb>
        <p className="text-uppercase">{space.name}</p>
      </Col>
    </Card>
  );
};

export default SpaceCard;
