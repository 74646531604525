import React from 'react';
import _ from 'lodash';
import * as Mui from '@mui/material';

import RoyaltyFeeTableRow from './RoyaltyFeeTableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';

interface IRoyaltyException {
  id: string;
  name: string;
  overridePercentage: number;
  royaltyFeeId: string;
  participantCenterIds: string[];
}

interface IRoyaltyFee {
  id: string;
  businessId: string;
  name: string;
  percentage: number;
  royaltyExceptions: IRoyaltyException[];
}

interface IProps {
  feeData: IRoyaltyFee[];
  refetch: () => void;
  from: number;
  setFrom: (x: number) => void;
  size: number;
  setSize: (x: number) => void;
  totalItems: number;
  nameSortDirection: string;
  setNameSortDirection: (x: string) => void;
}

const columns = [
  { name: 'Fee Name', displayLabel: true, alignLeft: true },
  { name: 'Fee', displayLabel: true, alignLeft: false },
  { name: '# Of Exception Rules', displayLabel: true, alignLeft: false },
  { name: 'Row Actions', displayLabel: false, alignLeft: false },
];

const RoyaltyFeesTable: React.FC<IProps> = ({
  feeData,
  refetch,
  from,
  setFrom,
  size,
  setSize,
  totalItems,
  nameSortDirection,
  setNameSortDirection,
  ...props
}) => {
  // const [order, setOrder] = React.useState<Order>('asc');
  // const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setFrom(newPage + 1);
  };

  React.useEffect(() => {
    setPage(from - 1);
  }, [from]);

  const onChangeNameSort = () => {
    nameSortDirection === 'ASCENDING' ? setNameSortDirection('DESCENDING') : setNameSortDirection('ASCENDING');
  };

  return (
    <Mui.Box sx={{ width: '100%', marginTop: '16px' }}>
      <Mui.Paper sx={{ mb: 2 }} elevation={0}>
        <Mui.TableContainer>
          <Mui.Table aria-labelledby="royalty-fees-table" style={{ width: '100%' }}>
            <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
              {columns.map((column) => (
                <Mui.TableCell key={column.name} align={column.alignLeft ? 'left' : 'center'} padding={'normal'}>
                  <Mui.Typography variant="tableHeadCells">
                    {column.displayLabel && column.name}
                    {column.name === 'Fee Name' && (
                      <Mui.IconButton onClick={() => onChangeNameSort()}>
                        <FontAwesomeIcon size="xs" icon={nameSortDirection === 'ASCENDING' ? faAngleDown : faAngleUp} />
                      </Mui.IconButton>
                    )}
                  </Mui.Typography>
                </Mui.TableCell>
              ))}
            </Mui.TableHead>
            <Mui.TableBody>
              {feeData.map((row, index) => (
                <RoyaltyFeeTableRow key={row.id} index={index} row={row} refetch={refetch} />
              ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
        <Mui.TablePagination
          sx={{
            '.MuiTablePagination-displayedRows': {
              margin: 0,
              padding: 0,
            },
          }}
          rowsPerPageOptions={[10]}
          component="div"
          count={totalItems}
          rowsPerPage={size}
          page={page}
          onPageChange={handleChangePage}
        />
      </Mui.Paper>
    </Mui.Box>
  );
};

export default RoyaltyFeesTable;
