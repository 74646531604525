import React, { useState, useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { Row } from 'shared/components/Layout';
import Tag from 'shared/components/Tag';
import { useGetCenterTagOptions } from 'pages/Centers/subroutes/Profile/graphql/queries';
import { LoadingLines } from 'shared/components/LoadingSkeletons';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  centers: ICenter[];
  isLoading: boolean;
  addTags: (tagsIds: ITag[]) => void;
}

const AddTagsToCenterModal: React.FC<IProps> = ({ isOpen, onClose, centers, isLoading, addTags }) => {
  const centerNamesArray = centers?.map((c) => c.name);
  const stringOfCenterNames =
    centers &&
    [centerNamesArray.slice(0, -1).join(', '), centerNamesArray.slice(-1)[0]].join(
      centerNamesArray.length < 2 ? '' : ' and '
    );
  const entityId =
    centers?.length && centers.every((c) => c.entityId === centers[0].entityId) ? centers[0].entityId : null;
  const { data, loading } = useGetCenterTagOptions(entityId || '');
  const tagOptions: ITag[] = (data && data.getCenterTagOptions) || [];
  const [tags, setTags] = useState<ITag[]>([]);
  const selectedTagIds: string[] = tags.map((tag) => tag.id);
  const unselectedTags: ITag[] = tagOptions.filter((tag) => !selectedTagIds.includes(tag.id));
  const addTag = useCallback(
    (selectedTag) => {
      setTags([...tags, selectedTag]);
    },
    [tags]
  );
  const removeTag = useCallback(
    (selectedTag) => {
      setTags(tags.filter((tag) => tag.id !== selectedTag.id));
    },
    [tags]
  );
  return (
    <ConfirmationModal
      title="Add Tags"
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Add Tags"
      primaryCallback={() => {
        addTags(tags);
      }}
      primaryButtonProps={{ loading: isLoading }}
    >
      <div>
        <p className="mb-8">
          Select the tags your would like to add to <b>{stringOfCenterNames}</b>.
        </p>
        {loading ? (
          <LoadingLines number={2} />
        ) : (
          tags && (
            <Row noGutters className="mx-n1 mt-4">
              {tags.map((tag, i) => (
                <div key={`selected-tag-${i}`} className="px-1 mb-2">
                  <Tag editState="SELECTED" tag={tag} onClick={() => removeTag(tag)} />
                </div>
              ))}
              {unselectedTags.map((tag, i) => (
                <div key={`unselected-tags-${i}`} className="px-1 mb-2">
                  <Tag editState="DESELECTED" tag={tag} onClick={() => addTag(tag)} />
                </div>
              ))}
            </Row>
          )
        )}
      </div>
    </ConfirmationModal>
  );
};

export default AddTagsToCenterModal;
