import React, { useCallback } from 'react';
import { components } from 'react-select';
import Checkbox from 'shared/components/Checkbox';

interface IProps {
  selectedOptions: any[] | null;
  onSelectAll: (allSelected: boolean) => void;
  noSearch?: boolean;
}

// MenuList props from react-select
const MultiOptionMenu: React.FC<any> = ({ selectProps, onSelectAll, ...props }) => {
  const { onInputChange, inputValue, onMenuInputFocus, noSearch } = selectProps;
  const selectedOptions = props.getValue();
  const allSelected = selectedOptions.length === selectProps.options.length;
  const numberSelected = selectedOptions?.length ?? 0;

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onInputChange(event.currentTarget.value, { action: 'input-change' });
    },
    [onInputChange]
  );

  const handleMouseDown = useCallback((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    event.target.focus();
  }, []);

  const handleOnTouchEnd = useCallback((event: React.TouchEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    event.target.focus();
  }, []);

  return (
    <>
      <div>
        <div className="p-4 bg-light">
          <div>{numberSelected} selected</div>
          <Checkbox
            label="Select / Deselect All"
            value={allSelected}
            onChange={onSelectAll}
            className="multi-select-checkbox"
          />
        </div>
        {!noSearch && (
          <div className="p-4 border-top border-bottom">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={inputValue}
              onChange={handleOnChange} // shared component doesn't provide the whole event
              onMouseDown={handleMouseDown}
              onTouchEnd={handleOnTouchEnd}
              onFocus={onMenuInputFocus}
              data-type="react-select-filter-input"
            />
          </div>
        )}
      </div>
      <components.MenuList {...props} selectProps={selectProps} />
    </>
  );
};

export default MultiOptionMenu;
