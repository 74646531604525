import React, { useState } from 'react';
import DataTable from 'shared/components/DataTable';
import { useSelector } from 'react-redux';
import { TagsTableCell } from 'shared/components/Tag';
import { STATES } from 'shared/constants/dropdownOptions/countryInfo';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { parsePhoneNumberWithRegion } from 'shared/util/string';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { useTranslation } from 'react-i18next';
import { ICustomField } from 'shared/types/customField';
import { RootState } from 'store/reducers';

interface IProps {
  customFieldData: ICustomField;
}

const CustomDataFieldsRow: React.FC<IProps> = ({ customFieldData, ...props }) => {
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const centers = useSelector((state: RootState) => state.centers.all);

  let connectedCenters = centers;
  if (customFieldData.centerIds?.length > 0) {
    connectedCenters = centers?.filter((x) => customFieldData.centerIds.includes(x.id));
  }

  const hasReadCenterTagsPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Tags,
    level: RoleLevelType.Read,
  });

  const formattedTableData =
    connectedCenters === undefined
      ? []
      : connectedCenters.map((center) => ({
          ...center,
          address: {
            ...center.address,
            state: { keyword: STATES[center.address?.state] },
            city: { keyword: center.address?.city },
          },
        }));

  return (
    <DataTable
      data={formattedTableData}
      showSelect={false}
      showPagination={false}
      showLoadingOverlay={isLoading}
      striped
      columns={[
        {
          text: t('custom-data-fields.center-name'),
          dataField: 'name',
          sort: true,
          formatter: (cell: string, row: ICenter) => (
            <AvatarDataTableCell
              initials={row.name && row.name[0].toUpperCase()}
              avatar={row.avatar && row.avatar.url}
              primaryText={row.name}
            />
          ),
        },
        {
          text: 'City',
          dataField: 'address.city.keyword',
          sort: true,
        },
        { text: 'State', dataField: 'address.state.keyword', sort: true },
        {
          text: 'Phone Number',
          dataField: 'phoneNumber',
          formatter: (cell: string, row: ICenter) => {
            let str = '';
            try {
              const phoneNumber = parsePhoneNumberWithRegion(row.phoneNumber);
              str = phoneNumber.formatNational();
            } catch (error) {
              // could throw ParseError
            }

            return str;
          },
        },
        hasReadCenterTagsPermission
          ? {
              text: 'Tags',
              dataField: 'tags',
              formatter: (cell: any, row: ICenter) => row.tags && <TagsTableCell tags={row.tags} />,
            }
          : null,
      ]}
    />
  );
};
export default CustomDataFieldsRow;
