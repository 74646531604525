import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { Row } from 'shared/components/Layout';
import { faBook, faLandmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgramTab from './ProgramTab';
import { useTranslation } from 'react-i18next';
import { isRegion } from 'shared/util/region';
import SubsidyTab from './SubsidyTab';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

type AgencyTabKeys = 'program' | 'subsidy';

interface IProps {
  account: IAccount;
  loading: boolean;
}

const AgencyTab: React.FC<IProps> = ({ account, loading, ...props }) => {
  const isAuRegion = isRegion('AU');
  const isUsRegion = isRegion('US');
  const [activeTab, setActiveTab] = useState<AgencyTabKeys>(isUsRegion ? 'subsidy' : 'program');
  const { t } = useTranslation(['subsidies']);

  const hasReadProgramsAgencyBasePermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasReadChildAgencyPermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.AgencyChild,
    level: RoleLevelType.Read,
  });

  return (
    <Tabs
      id="account-agency-tabs"
      activeKey={activeTab ?? 'program'}
      onSelect={(eventKey) => setActiveTab(eventKey! as AgencyTabKeys)}
      className="kt-billing-tab"
    >
      {isUsRegion && hasReadChildAgencyPermission && (
        <Tab
          eventKey="subsidy"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faLandmark} size="2x" className="mr-2" />
              {t('subsidies:subsidies')}
            </Row>
          }
        >
          <SubsidyTab account={account} loading={loading} />
        </Tab>
      )}
      {isAuRegion && hasReadProgramsAgencyBasePermission && (
        <Tab
          eventKey="program"
          title={
            <Row noGutters>
              <FontAwesomeIcon icon={faBook} size="2x" className="mr-2" />
              {t('subsidies:programs')}
            </Row>
          }
        >
          <ProgramTab account={account} />
        </Tab>
      )}
    </Tabs>
  );
};

export default AgencyTab;
