import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { roleFields } from './fields';

interface ICreateRoleData {
  createRole: IRole;
}

interface ICreateRoleVariables {
  input: ICreateRoleInput;
}

interface IUpdateRoleData {
  updateRole: IRole;
}

interface IUpdateRoleVariables {
  input: IUpdateRoleInput;
}

interface IDeleteRoleData {
  deleteRole: IRole;
}

interface IDeleteRoleVariables {
  input: IDeleteRoleInput;
}

export const CREATE_ROLE = (fields: string = roleFields) => gql`
  mutation ($input: CreateRoleInput!) {
    createRole(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_ROLE = (fields: string = roleFields) => gql`
  mutation ($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_ROLE = (fields: string = roleFields) => gql`
  mutation ($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      ${fields}
    }
  }
`;

export const useCreateRole = (options?: MutationHookOptions<ICreateRoleData, ICreateRoleVariables>, fields?: string) =>
  useMutation<ICreateRoleData, ICreateRoleVariables>(CREATE_ROLE(fields), {
    ...options,
  });

export const useUpdateRole = (options?: MutationHookOptions<IUpdateRoleData, IUpdateRoleVariables>, fields?: string) =>
  useMutation<IUpdateRoleData, IUpdateRoleVariables>(UPDATE_ROLE(fields), {
    ...options,
  });

export const useDeleteRole = (options?: MutationHookOptions<IDeleteRoleData, IDeleteRoleVariables>, fields?: string) =>
  useMutation<IDeleteRoleData, IDeleteRoleVariables>(DELETE_ROLE(fields), {
    ...options,
  });
