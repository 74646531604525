import React, { useState, useCallback } from 'react';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { PayrixDisbursement } from 'generated/graphql';

interface IFormStateShape {
  format: 'CSV' | 'PDF';
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  disbursement: PayrixDisbursement;
  onSubmit: (reportFormat: string) => void;
  onClose: () => void;
}

const RunDisbursementReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  disbursement,
  onSubmit,
  onClose,
  ...props
}) => {
  const [formData, setFormData] = useState<IFormStateShape>({
    format: 'CSV',
  });
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    onSubmit(formData.format === 'CSV' ? 'EXCEL' : 'PDF');
  }, [formData, onSubmit]);

  const handleClose = useCallback(() => {
    setFormData({ format: 'CSV' });
    onClose();
  }, [onClose]);

  // negative disbursements are empty and have nothing to download
  const canDownloadDisbursementReport = disbursement.amount > 0;

  return (
    <SideModalDrawer
      title={`Disbursement Report`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Generate Report"
      primaryCallback={() => handleSubmit()}
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryButtonProps={{ loading: isLoading, disabled: !canDownloadDisbursementReport }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      hasPrimaryChoice={canDownloadDisbursementReport}
    >
      <Row>
        <Col>
          {canDownloadDisbursementReport ? (
            <Select
              options={[
                { value: 'CSV', label: 'CSV' },
                { value: 'PDF', label: 'PDF' },
              ]}
              label={`${capitalize(t('spelling.format'))}(s)`}
              value={formData.format ?? { value: 'CSV', label: 'CSV' }}
              onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
              className="mb-0"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          ) : (
            <div>
              There were not enough available funds in the processor merchant account, these funds were withdrawn from
              the bank account on file.
            </div>
          )}
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default RunDisbursementReportModal;
