import { QueryHookOptions } from '@apollo/client';
import { staffPositionFields } from 'gql/position/fields';
import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';

interface IGetPersonTimeSheetsInput {
  startDate: string;
  endDate: string;
  centerId?: string | null;
  personId?: string | null;
}

interface IGetPersonTimeSheetsVariables {
  input: IGetPersonTimeSheetsInput;
}

interface IGetPersonTimeSheetsData {
  getAttendance: IAttendance[];
}

interface IGetMyTimeSheetsData {
  getMyAttendance: IAttendance[];
}

interface IGetShiftsForPersonForDateVariables {
  personId: string;
  dates: IDateTimeRange[];
}

interface IGetOpenTimeEntryForPersonAtCenterVariables {
  input: {
    personId: string;
    centerId: string;
  };
}

interface IGetOpenTimeEntryForPersonAtCenterData {
  getOpenTimeEntryForPersonAtCenter: ITimeEntry;
}

interface IGetShiftsForPersonForDateData {
  getShiftsForPersonForDates: IShift[];
}

interface IGetMyTimeOffData {
  getMyTimeOffRequests: ITimeOff[];
}

interface IGetMyTimeOffVariables {
  input: {
    startTime: string;
    endTime: string;
  };
}

interface IGetTimeOffRequestsData {
  getTimeOffRequestsByScope: ITimeOff[];
}

interface IGetTimeOffRequestVariables {
  input: {
    scopeType: ScopeType;
    scopeId: string;
    startTime: string;
    endTime: string;
  };
}

export const GET_STAFF = gql`
  query ($id: String!) {
    getStaff(id: $id) {
      id
      entityId
      primaryCenterId
      firstname
      lastname
      nickname
      avatar {
        url
      }
      positions {
        id
        positionName
        scopeId
        scopeType
      }
      roleship {
        roleId
        name
        scopeType
        scopes {
          ... on Center {
            id
            name
          }
          ... on Entity {
            id
            name
            centers {
              id
              name
              entityId
            }
          }
        }
      }
    }
  }
`;

export const GET_PERSON_TIME_SHEETS = gql`
  query($input: GetAttendanceInput!){
    getAttendance(input: $input){
      centerId
      center {
        id
        name
      }
      personId
      person {
        id
        firstname
        lastname
        nickname
        avatar{
          url
        }
      }
      days {
        centerId
        date
        timeEntries {
          id
          shiftId
          centerId
          positionId
          timeIn
          trackedTimeIn
          timeOut
          trackedTimeOut
          type
          note
          createdAt
          updatedAt
          updatedBy
          adjustedAt
          adjustedBy
          archivedAt
          archivedBy
          staffPosition {
            ${staffPositionFields}
          }
          adjustedByPerson {
            id
            firstname
            lastname
          }
          note
          clockedSelfIn
          clockedSelfOut
        }
        shifts {
          id
          startTime
          endTime
          breakMinutes
          paidBreak
          positionId
          position {
            id
            title
            positionName
          }
        }
        trainingTimeEvents {
          id
          name
          type
          hours
        }
        totalHoursScheduled {
          hours
          minutes
        }
        totalHoursWorked{
          hours
          minutes
        }
      }
      totalHoursScheduled{
        hours
        minutes
      }
      totalHoursWorked {
        hours
        minutes
      }
    }
  }
`;

export const GET_OPEN_TIME_ENTRY_FOR_PERSON_AT_CENTER = gql`
  query ($input: GetOpenTimeEntryForPersonAtCenterInput!) {
    getOpenTimeEntryForPersonAtCenter(input: $input) {
      id
      timeIn
      trackedTimeIn
      timeOut
      trackedTimeOut
      type
      shiftId
      scheduleId
      centerId
      positionId
    }
  }
`;

export const GET_MY_TIME_CARDS = gql`
  query($input: GetAttendanceInput!){
    getMyAttendance(input: $input){
      centerId
      center {
        id
        name
      }
      personId
      person {
        id
        firstname
        lastname
        nickname
        avatar{
          url
        }
      }
      days {
        centerId
        date
        timeEntries {
          id
          shiftId
          centerId
          positionId
          timeIn
          trackedTimeIn
          timeOut
          trackedTimeOut
          type
          note
          createdAt
          updatedAt
          updatedBy
          adjustedAt
          adjustedBy
          staffPosition {
            ${staffPositionFields}
          }
          adjustedByPerson {
            id
            firstname
            lastname
          }
          note
          clockedSelfIn
          clockedSelfOut
        }
        shifts {
          id
          startTime
          endTime
          breakMinutes
          paidBreak
          positionId
          position {
            id
            title
            positionName
          }
        }
        trainingTimeEvents {
          id
          name
          type
          hours
        }
        totalHoursScheduled {
          hours
          minutes
        }
        totalHoursWorked{
          hours
          minutes
        }
      }
      totalHoursScheduled{
        hours
        minutes
      }
      totalHoursWorked {
        hours
        minutes
      }
    }
  }
`;

export const GET_MY_TIME_SHEETS = gql`
  query ($input: GetAttendanceInput!) {
    getMyAttendance(input: $input) {
      centerId
      center {
        id
        name
      }
      personId
      person {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
      }
      days {
        date
        timeEntries {
          id
          shiftId
          centerId
          positionId
          timeIn
          trackedTimeIn
          timeOut
          trackedTimeOut
          type
        }
        shifts {
          id
          startTime
          endTime
          breakMinutes
          paidBreak
          positionId
          position {
            id
            title
            positionName
          }
        }
        totalHoursScheduled {
          hours
          minutes
        }
        totalHoursWorked {
          hours
          minutes
        }
      }
      totalHoursScheduled {
        hours
        minutes
      }
      totalHoursWorked {
        hours
        minutes
      }
    }
  }
`;

export const GET_ACTIVE_STAFF_FOR_NEW_TIME_ENTRY = gql`
  query ($input: SearchInput!) {
    searchStaff(input: $input) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        positions {
          id
          title
          positionName
          scopeId
          scopeType
        }
      }
    }
  }
`;

export const GET_SHIFTS_FOR_PERSON_FOR_DATE = gql`
  query ($personId: ID!, $dates: [DateTimeRangeInput!]!) {
    getShiftsForPersonForDates(personId: $personId, dates: $dates) {
      id
      startTime
      endTime
      position {
        id
        title
      }
      center {
        id
        name
      }
    }
  }
`;

export const GET_TIME_OFF_REQUESTS_FOR_DATES = gql`
  query ($input: GetTimeOffRequestByScopeInput!) {
    getTimeOffRequestsByScope(input: $input) {
      id
      entityId
      centerId
      personId
      startTime
      endTime
      allDay
      timeOffType
      hoursApproved
      status
    }
  }
`;

export const GET_MY_TIME_OFF = gql`
  query ($input: GetMyTimeOffRequestInput!) {
    getMyTimeOffRequests(input: $input) {
      id
      personId
      startTime
      endTime
      allDay
      timeOffType
      hoursApproved
      status
    }
  }
`;

export const useGetTimeSheetsForPerson = (input: IGetPersonTimeSheetsInput) =>
  useQuery<IGetPersonTimeSheetsData, IGetPersonTimeSheetsVariables>(GET_PERSON_TIME_SHEETS, {
    variables: {
      input: {
        startDate: input.startDate,
        endDate: input.endDate,
        centerId: input.centerId ?? null,
        personId: input.personId ?? null,
      },
    },
  });

export const useGetTimeMyCards = (options?: QueryHookOptions<IGetMyTimeSheetsData, IGetPersonTimeSheetsVariables>) =>
  useQuery<IGetMyTimeSheetsData, IGetPersonTimeSheetsVariables>(GET_MY_TIME_CARDS, options);

export const useGetTimeCardsForPerson = (
  options?: QueryHookOptions<IGetPersonTimeSheetsData, IGetPersonTimeSheetsVariables>
) => useQuery<IGetPersonTimeSheetsData, IGetPersonTimeSheetsVariables>(GET_PERSON_TIME_SHEETS, options);

export const useGetMyTimeSheets = (input: IGetPersonTimeSheetsInput) =>
  useQuery<IGetMyTimeSheetsData, IGetPersonTimeSheetsVariables>(GET_MY_TIME_SHEETS, {
    variables: {
      input: {
        startDate: input.startDate,
        endDate: input.endDate,
        centerId: input.centerId ?? null,
        personId: input.personId ?? null,
      },
    },
  });

export const useGetMyTimeOff = (startTime: string, endTime: string) =>
  useQuery<IGetMyTimeOffData, IGetMyTimeOffVariables>(GET_MY_TIME_OFF, {
    variables: {
      input: {
        startTime,
        endTime,
      },
    },
  });

export const useGetTimeOffForPerson = (startTime: string, endTime: string, centerId: string, scopeType: ScopeType) =>
  useQuery<IGetTimeOffRequestsData, IGetTimeOffRequestVariables>(GET_TIME_OFF_REQUESTS_FOR_DATES, {
    variables: {
      input: {
        scopeType: scopeType,
        scopeId: centerId,
        startTime,
        endTime,
      },
    },
  });

export const useGetShiftsForPerson = (personId: string, startTime: string, endTime: string) =>
  useQuery<IGetShiftsForPersonForDateData, IGetShiftsForPersonForDateVariables>(GET_SHIFTS_FOR_PERSON_FOR_DATE, {
    skip: personId === '',
    variables: {
      personId,
      dates: [
        {
          startTime,
          endTime,
        },
      ],
    },
  });

export const useLazyGetOpenTimeEntryForPersonAtCenter = () =>
  useLazyQuery<IGetOpenTimeEntryForPersonAtCenterData, IGetOpenTimeEntryForPersonAtCenterVariables>(
    GET_OPEN_TIME_ENTRY_FOR_PERSON_AT_CENTER,
    {
      fetchPolicy: 'network-only',
      onError: (err) => {
        console.log(err);
        console.log(`There was an error fetching time entries for this person.`);
      },
    }
  );
