import React from 'react';
import colors from '_colors.module.scss';
import Tooltip from '../Tooltip';

interface IProps {
  className?: string;
  text?: string;
  color?: string;
  tooltipText?: string;
  tooltipDirection?: 'left' | 'right' | 'top' | 'bottom';
  onClick?: (evt: any) => void;
  borderColor?: string;
}

const Tag: React.FC<IProps> = ({
  text,
  color,
  tooltipText,
  tooltipDirection = 'top',
  onClick = () => {},
  borderColor,
}) => {
  return (
    <Tooltip
      text={tooltipText ?? ''}
      direction={tooltipDirection}
      showTooltip={Boolean(tooltipText)}
      additionalClass="border-2px #273143"
    >
      <span
        style={{
          backgroundColor: '#EAEDF0',
          borderColor: borderColor,
          border: '2px solid #EAEDF0',
          fontWeight: 'normal',
        }}
        className={`text-${color} rounded sm py-2 px-5`}
        onClick={onClick}
      >
        {text}
      </span>
    </Tooltip>
  );
};

export default Tag;
