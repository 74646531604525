import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import * as Mui from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent, faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

import { useCreateRoyaltyFee } from 'pages/Businesses/subroutes/BusinessSettings/gql/mutation';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import RightModalDrawer from 'shared/components/MUI/RightModalDrawer';

interface IProps {
  openAddFee: boolean;
  setOpenAddFee: (v: boolean) => void;
  refetch: () => void;
}

const AddRoyaltyFee: React.FC<IProps> = ({ openAddFee, setOpenAddFee, refetch }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const businessIdRedux = useSelector((state: RootState) => state.context.businessId);
  const businessId = _.isNil(businessIdRedux) ? '' : businessIdRedux;
  const [ruleName, setRuleName] = React.useState('');
  const [defaultFee, setDefaultFee] = React.useState('');

  const [createRoyaltyFeeFn] = useCreateRoyaltyFee({
    onCompleted: () => {
      refetch();
      showToast('Royalty Fee Created', 'success');
      onClose();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onSave = () => {
    createRoyaltyFeeFn({
      variables: {
        input: {
          businessId: businessId,
          name: ruleName,
          percentage: parseFloat(defaultFee),
        },
      },
    });
  };

  const onClose = () => {
    setOpenAddFee(false);
    setRuleName('');
    setDefaultFee('');
  };

  return (
    <>
      <RightModalDrawer open={openAddFee} onClose={() => onClose()} title="Create Royalty Fee">
        <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '16px' }}>
          <Mui.Stack direction="column" spacing={2}>
            <Mui.FormControlLabel
              label={
                <span>
                  Rule Name{` `}
                  <FontAwesomeIcon
                    icon={faAsterisk}
                    color={'#FF2C2C'}
                    style={{
                      fontSize: '.5rem',
                      height: '1em',
                      marginBottom: '0.5rem',
                    }}
                  />
                </span>
              }
              labelPlacement="top"
              control={
                <Mui.TextField
                  size="small"
                  value={ruleName}
                  required
                  onChange={(evt) => setRuleName(evt.target.value)}
                  fullWidth
                  sx={{
                    minWidth: '300px',
                    '& legend': {
                      width: 0,
                    },
                  }}
                />
              }
              componentsProps={{
                typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
              }}
            />
            <Mui.Box>
              <Mui.FormControlLabel
                label={
                  <span>
                    Fee Percentage{` `}
                    <FontAwesomeIcon
                      icon={faAsterisk}
                      color={'#FF2C2C'}
                      style={{
                        fontSize: '.5rem',
                        height: '1em',
                        marginBottom: '0.5rem',
                      }}
                    />
                  </span>
                }
                labelPlacement="top"
                control={
                  <Mui.TextField
                    type="number"
                    size="small"
                    value={defaultFee}
                    onChange={(evt) => setDefaultFee(evt.target.value)}
                    InputProps={{
                      endAdornment: (
                        <Mui.InputAdornment position="end">
                          <FontAwesomeIcon icon={faPercent} />
                        </Mui.InputAdornment>
                      ),
                    }}
                    sx={{
                      minWidth: '300px',
                      '& legend': {
                        width: 0,
                      },
                    }}
                  />
                }
                componentsProps={{
                  typography: {
                    alignSelf: 'flex-start',
                    marginBottom: '0.5rem',
                  },
                }}
              />
            </Mui.Box>
          </Mui.Stack>
        </Mui.DialogContent>
        <Mui.Box style={{ margin: '8px' }}>
          <Mui.DialogActions>
            <Mui.Button variant="cancel" onClick={() => onClose()}>
              Cancel
            </Mui.Button>
            <Mui.Button
              color="secondary"
              variant="contained"
              onClick={() => onSave()}
              disabled={_.isEmpty(ruleName) || _.isEmpty(defaultFee)}
              id="modal-drawer-primary-btn"
            >
              Save
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Box>
      </RightModalDrawer>
    </>
  );
};

export default AddRoyaltyFee;
