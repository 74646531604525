import React, { useState, useCallback, useEffect } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import AllergenSelect from 'shared/components/Modals/NewAllergyModal/AllergenSelect';
import { Row, Col as Column } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { allergenReactions } from 'shared/constants/enums/allergenReactions';
import errorMessage from 'shared/constants/errorMessages';
import { sortBy } from 'lodash';
import { IAllergyInputGroupFormShape } from 'shared/components/InputGroups/AllergyInputGroup';
import Checkbox from 'shared/components/Checkbox';
import { Collapse } from 'react-bootstrap';
import ChildMedicalDocumentationUploadForm from 'pages/Families/subroutes/Children/Child/components/HealthWellnessTab/ChildMedicalDocumentationUploadForm';
import ActiveDocumentsSummary from 'pages/Families/subroutes/Children/Child/components/HealthWellnessTab/ActiveDocumentsSummary';
import _ from 'lodash';

const ALLERGEN_REACTION_ARRAY = sortBy(
  Object.keys(allergenReactions).map((reaction) => ({ label: allergenReactions[reaction], value: reaction })),
  (obj) => obj.label
);

interface IProps {
  isOpen: boolean;
  allergy: IAllergy;
  onSave: (updates: IAllergyInputGroupFormShape) => void;
  onClose: () => void;
}

const UpdateAllergyModal: React.FC<IProps> = ({ isOpen, allergy, onClose, onSave, ...props }) => {
  const [showDocumentationFormGroup, setShowDocumentationFormGroup] = useState<boolean>(false);
  const [formData, setFormData] = useState<IAllergyInputGroupFormShape>({
    allergen: allergy.allergen,
    allergenType: allergy.allergen.type,
    instructions: allergy.instructions,
    reactions: allergy.reactions.map((reaction) => ({ label: reaction, value: reaction })),
    severity: allergy.severity,
    documentation: [],
  });

  const formDisabled: boolean = Boolean(
    formData.allergen &&
      formData.severity &&
      (formData.severity === 'Mild' ||
        formData.severity === 'NotApplicable' ||
        (!_.isNil(formData.reactions) && formData.reactions?.length != 0 && formData.instructions))
  );

  const resetForm = useCallback(() => {
    setFormData({
      allergen: null,
      allergenType: null,
      instructions: '',
      reactions: null,
      severity: null,
      documentation: [],
    });
  }, [formData]);

  const dismissModal = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  return (
    <SideModalDrawer
      title="Edit Allergy"
      show={isOpen}
      onHide={dismissModal}
      primaryChoice="Save"
      primaryCallback={() => onSave(formData)}
      secondaryCallback={onClose}
      primaryButtonProps={{ disabled: !formDisabled }}
      footerHelperText={!formDisabled ? errorMessage.formRequirements : ''}
    >
      <AllergenSelect
        selectedAllergenType={formData.allergenType}
        selectedAllergen={formData.allergen}
        onSelectAllergenType={(allergenType: IHealthTerm) =>
          setFormData((prev) => ({ ...prev, allergenType, allergen: null }))
        }
        onSelectAllegen={(allergen: IAllergen) => setFormData((prev) => ({ ...prev, allergen }))}
        disableSelects={true}
      />
      <Row>
        <Column md={6}>
          <Select
            required
            label="Severity"
            options={['NotApplicable', 'Mild', 'Moderate', 'Severe']}
            value={formData.severity}
            onChange={(option) => setFormData((prev) => ({ ...prev, severity: option }))}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Select
            isMulti
            closeMenuOnSelect
            label="Reaction"
            value={formData.reactions}
            required={formData.severity !== 'Mild' && formData.severity !== 'NotApplicable'}
            options={ALLERGEN_REACTION_ARRAY}
            onChange={(options) => setFormData((prev) => ({ ...prev, reactions: options }))}
            className="flex-wrap"
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <TextInput
            required={formData.severity !== 'Mild' && formData.severity !== 'NotApplicable'}
            label="Care Instructions"
            as="textarea"
            rows={3}
            value={formData.instructions}
            onChange={(value: string, name: string) => setFormData((prev) => ({ ...prev, instructions: value }))}
          />
        </Column>
      </Row>
      <ActiveDocumentsSummary condition={allergy} />
      {Boolean(allergy.childId) && (
        <Checkbox
          label="Add Documentation"
          value={showDocumentationFormGroup}
          onChange={(checked) => setShowDocumentationFormGroup(checked)}
          className="mb-4"
        />
      )}
      <Collapse in={showDocumentationFormGroup}>
        <div>
          <ChildMedicalDocumentationUploadForm
            documents={formData.documentation}
            onFilesAdd={(files) =>
              setFormData((prev) => ({ ...prev, documentation: [...prev.documentation, ...files] }))
            }
            onFileUpdate={(updatedFile) =>
              setFormData((prev) => ({
                ...prev,
                documentation: prev.documentation.map((f) => (f.file.name === updatedFile.file.name ? updatedFile : f)),
              }))
            }
            onRemoveFile={(filename) =>
              setFormData((prev) => ({
                ...prev,
                documentation: prev.documentation.filter((file) => file.file.name !== filename),
              }))
            }
            childId={allergy.childId ?? ''}
          />
        </div>
      </Collapse>
    </SideModalDrawer>
  );
};

export default UpdateAllergyModal;
