import {
  Chat,
  MessageEnvelope,
  MessageInput,
  MessageList,
  MessagePayload,
  MessageRendererProps,
  UserEntity,
} from '@pubnub/react-chat-components';
import { AccountStatusType, ActivityType, ChannelType, useCreateChatActivityMutation } from 'generated/graphql';
import React, { useState, useEffect } from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Spinner from 'shared/components/Spinner';
import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { Channel } from 'pages/Engagement/subroutes/Messaging/Tabs/AccountMessagingTab/AccountMessagingTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faThumbtack } from '@fortawesome/pro-light-svg-icons';
import MessageBubble from './MessageBubble';
import ActionDropdown from 'shared/components/ActionDropdown';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { IconButtonCircle } from 'shared/components/Buttons';
import PubNub from 'pubnub';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useHistory } from 'react-router-dom';
import Alert from 'shared/components/Alert';
import { BulletinBoardFilters } from '../../../BulletinBoards';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import ThreadedMessageList from './ThreadedMessageList';
import { faMessageLines } from '@fortawesome/pro-thin-svg-icons';
import { CustomProperty, ThreadedMessages } from '../types';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

interface IProps {
  pubnub: PubNub;
  authCenters: string[];
  currentChannel: string;
  subscribedChannels: string[];
  users: UserEntity[];
  getUsersLoading?: boolean;
  hasWritePermission: boolean;
  hasDeletePermission: boolean;
  channelsType: ChannelType;
  currentChannelInfo?: Channel;
  centerId: string;
  filterBulletinsBy?: BulletinBoardFilters;
  mentionableEntities: Array<ITableFilterOption>;
  threadedMessages: Array<ThreadedMessages>;
}

const PubnubChatComponent: React.FC<IProps> = ({
  pubnub,
  authCenters,
  currentChannel,
  subscribedChannels,
  users,
  getUsersLoading,
  hasWritePermission,
  hasDeletePermission,
  channelsType,
  currentChannelInfo,
  centerId,
  filterBulletinsBy,
  mentionableEntities,
  threadedMessages,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['comms', 'translation']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const [mentionedEntities, setMentionedEntities] = useState<ITableFilterOption[]>([]);
  const [selectedThreadOpeningMessage, setSelectedThreadOpeningMessage] = useState<MessagePayload | null>(null);
  const [selectedThreadChildren, setSelectedThreadChildren] = useState<Array<MessagePayload>>([]);

  const findThread = (parentThreadMessageId: string | null) => {
    if (parentThreadMessageId === null) return null;
    const thread = threadedMessages.find((threadMsg) => {
      return threadMsg.rootPubNubMessageId === parentThreadMessageId;
    });
    return thread || null;
  };

  const setThread = (parentThreadMessageId: string | null, parentThreadMessage?: MessagePayload) => {
    if (parentThreadMessageId) {
      const thread = findThread(parentThreadMessageId);
      setSelectedThreadOpeningMessage(thread?.parentMessage ?? null);
      setSelectedThreadChildren(thread?.relatedMessages ?? []);
      if (!thread && parentThreadMessage) setSelectedThreadOpeningMessage(parentThreadMessage);
    } else if (parentThreadMessageId === null) {
      setSelectedThreadOpeningMessage(null);
      setSelectedThreadChildren([]);
    }
  };

  // Updating thread data with new live messages
  useEffect(() => {
    if (selectedThreadOpeningMessage) setThread(selectedThreadOpeningMessage.id);
  }, [threadedMessages]);

  // Clearing thread data on channel switch
  useEffect(() => {
    setThread(null);
  }, [currentChannel]);

  const hasCenterEditPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const [createChatActivity, { data, loading: createActivityLoading, error: createChatActivityError }] =
    useCreateChatActivityMutation({
      onError: (err) => showToast('Unable to send user data', 'error'),
      onCompleted: (response) => {},
    });

  const appInsightsContext = useAppInsightsContext();

  const handleChatClientError = (error) => {
    if (error.name !== 'TypeError') {
      if (error.status.category === 'PNAccessDeniedCategory') showToast(t('comms:pubnub.error.auth'), 'error');
      else {
        showToast(t('comms:pubnub.error.refresh'), 'error');
        appInsightsContext.trackException(error);
      }
    }
  };

  const handleDeleteMessage = async (message: MessageEnvelope) => {
    const payload = message.message as MessagePayload;
    pubnub
      .addMessageAction({
        channel: message.channel ?? currentChannel,
        messageTimetoken: message.timetoken.toString(),
        action: {
          type: 'deleted',
          value: '.',
        },
      })
      .then((result) => {
        createChatActivity({
          variables: {
            businessId: businessId ?? '',
            input: {
              businessId: businessId ?? '',
              channelType: currentChannelInfo?.channelType === 'Account' ? ChannelType.Account : ChannelType.Center,
              channelId: currentChannelInfo?.channelId,
              personId: payload?.sender?.id ?? user?.id,
              activityType: ActivityType.MessageDeleted,
              activityTime: parseInt(message.timetoken.toString()),
              pubNubMessageId: payload.id,
              messageContent: payload.text,
              channelWasRead: true,
            },
          },
        });
        showToast(t('comms:delete-message-btn.success-toast-message', { message: payload.text }), 'success');
      })
      .catch((error) => {
        showToast(t('comms:delete-message-btn.error-toast-message'), 'error');
      });
  };

  const renderMessage = (props: MessageRendererProps) => {
    if (messagePassesFilter(props.message) === false) return <></>;
    const payload = props.message.message as MessagePayload;
    const customDataProperty = payload.custom as CustomProperty;
    const avatarUrl = props.user?.profileUrl ?? payload.sender?.profileUrl ?? '';
    const thread = findThread((customDataProperty && customDataProperty.parentThreadMessageId) || payload.id || null);
    const replies = (thread && thread.relatedMessages) ?? undefined;

    return (
      <MessageBubble
        messageText={payload.text ?? ''}
        messageSender={payload.sender?.name ?? ''}
        createdAt={payload.createdAt ?? ''}
        setThread={setThread}
        avatarUrl={avatarUrl}
        payload={payload}
        parentMessageId={thread?.parentMessage.id}
        replies={replies}
        isOwn={props.isOwn}
        filterMode={filterBulletinsBy}
      />
    );
  };

  const renderMessageActions = (message: MessageEnvelope) => {
    const payload = message.message as MessagePayload;
    const customProperty = payload.custom as CustomProperty;
    return !(customProperty && customProperty.parentThreadMessageId) ? (
      <ActionDropdown
        actions={[
          {
            label: t('comms:thread-message.action'),
            icon: faMessageLines,
            onClick: () => {
              setThread(payload.id, payload);
            },
          },
        ]}
        customIconButton={
          <IconButtonCircle className="rounded-circle circle-md bg-pale-grey ml-2" icon={faEllipsisH} iconSize="2x" />
        }
      />
    ) : (
      <></>
    );
  };

  const messagePassesFilter = (message: MessageEnvelope) => {
    const payload = message.message as MessagePayload;
    let customProperty;
    if (payload.custom) customProperty = payload.custom as CustomProperty;

    switch (filterBulletinsBy) {
      case BulletinBoardFilters.Mentions:
        if (user && customProperty && customProperty.directedAt) {
          // if my id is found in the list of mentioned users return true
          if (customProperty.directedAt.find((mentionedUser) => user.id === mentionedUser.id)) return true;
        }
        return false;
      default:
        if (customProperty && customProperty.parentThreadMessageId) return false;
        return true;
    }
  };

  const renderMentionSelector = () => {
    return (
      <DropdownFilter
        title={'Mention'}
        className={'mr-4'}
        options={mentionableEntities}
        onFilterSelect={(options) => setMentionedEntities(options)}
        selectedFilters={mentionedEntities}
      />
    );
  };

  const handleBeforeMessageSend = (message: MessagePayload) => {
    handleMentions(message);
    handleThreading(message);
    return message;
  };

  const handleMentions = (message: MessagePayload) => {
    if (mentionedEntities.length > 0) {
      const directedAt = mentionedEntities.map((mentionedEntity) => {
        return mentionedEntity.meta;
      });
      const existingCustomPropertyData = message.custom as CustomProperty;
      const updatedCustomProperty = {
        ...existingCustomPropertyData,
        directedAt,
      };
      message.custom = updatedCustomProperty;
      return message;
    } else {
      return message;
    }
  };

  const handleThreading = (message: MessagePayload) => {
    if (selectedThreadOpeningMessage) {
      const existingCustomPropertyData = message.custom as CustomProperty;
      const updatedCustomProperty = {
        ...existingCustomPropertyData,
        parentThreadMessageId: selectedThreadOpeningMessage.id,
      };
      message.custom = updatedCustomProperty;
      return message;
    } else {
      return message;
    }
  };

  return (
    <>
      <Chat
        enablePresence={false}
        currentChannel={currentChannel ?? 'Default Channel'}
        channels={subscribedChannels}
        users={users}
        onError={handleChatClientError}
        retryOptions={{
          maxRetries: 5,
          timeout: 1000,
          exponentialFactor: 2,
        }}
      >
        <Col md={12} lg={6} xl={8} className={`messages ${!currentChannel ? 'chat-disabled' : ''}`}>
          {selectedThreadOpeningMessage ? (
            <ThreadedMessageList
              openingMessage={selectedThreadOpeningMessage}
              threadChildren={selectedThreadChildren}
              setThread={setThread}
            />
          ) : (
            <MessageList
              fetchMessages={100}
              messageRenderer={renderMessage}
              extraActionsRenderer={(message) => renderMessageActions(message)}
            />
          )}

          {currentChannelInfo?.accountStatus && currentChannelInfo.accountStatus === AccountStatusType.Inactive && (
            <Alert variant="info">
              {'Contacts within this channel may no longer have access KT Connect to receive this message'}
            </Alert>
          )}
          <div className={!hasWritePermission ? 'disabled' : ''}>
            {authCenters.some((c) => c === currentChannelInfo?.centerId) && (
              <MessageInput
                disabled={!currentChannel || !hasWritePermission}
                emojiPicker={<Picker data={emojiData} theme={'light'} />}
                placeholder={
                  !hasWritePermission
                    ? t('comms:messageInputPlaceholder.no-permission')
                    : t('comms:messageInputPlaceholder.default')
                }
                extraActionsRenderer={() => renderMentionSelector()}
                onBeforeSend={handleBeforeMessageSend}
                onSend={() => setMentionedEntities([])}
                senderInfo={true}
                sendButton={
                  <div className={!hasWritePermission ? 'disabled' : ''}>
                    <FontAwesomeIcon icon={faThumbtack} fontSize="10px" />
                    <span>{t('comms:post-bulletin')}</span>
                  </div>
                }
              />
            )}
            {!authCenters.some((c) => c === currentChannelInfo?.centerId) && (
              <div className="inactive-center">
                <span>{t('comms:inactive-channel.main-text')}</span>
                {!hasCenterEditPermission && <span>{t('comms:inactive-channel.additonal-text')}</span>}
                {hasCenterEditPermission && (
                  <span>
                    go to your <a onClick={() => history.push('/engagement/settings')}>Engagement Settings</a>
                  </span>
                )}
              </div>
            )}
          </div>
        </Col>
      </Chat>
    </>
  );
};

export default PubnubChatComponent;
