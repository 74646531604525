import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import AddressInput from 'shared/components/AddressInput';
import errorMessage from 'shared/constants/errorMessages';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import PhoneInput from 'shared/components/PhoneInput';
import { useUpdateMyProfile } from 'pages/MyProfile/graphql/mutations';
import { showToast } from 'shared/components/Toast';
import { omitTypename, omitFalsy } from 'shared/util/object';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import { isBlank, parsePhoneNumberFromStringWithRegion } from 'shared/util/string';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import useGetMe from 'shared/hooks/useGetMe';

interface IProps {
  data: IStaff | IPerson | null;
  readOnly?: boolean;
}

const ADDRESS_OBJECT = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: 'US',
  postalCode: '',
};

const PersonalInformationForm: React.FC<IProps> = ({ data, readOnly = false, ...props }) => {
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [personalInformation, updatePersonalInformation] = useState({
    id: data?.id ?? null,
    firstname: data?.firstname ?? '',
    lastname: data?.lastname ?? '',
    nickname: data?.nickname ?? '',
    dob: data?.dob ?? '',
    phoneNumber: data?.phoneNumber ?? '',
    address: {
      ...ADDRESS_OBJECT,
      ...data?.address,
    },
  });
  const [updateMyProfileFn, { loading: updateMyProfileLoading }] = useUpdateMyProfile();
  const { refetch: refetchPersonalInfo } = useGetMe();

  const validForm = useCallback((form: Record<string, any>) => {
    return (
      !isBlank(form.firstname) &&
      !isBlank(form.lastname) &&
      Boolean(form.dob) &&
      !isBlank(form.email) &&
      !isBlank(form.address.address1) &&
      !isBlank(form.address.city) &&
      !isBlank(form.address.state) &&
      !isBlank(form.address.postalCode) &&
      !isBlank(form.address.country) &&
      parsePhoneNumberFromStringWithRegion(form.phoneNumber)?.isValid()
    );
  }, []);

  const handleResetForm = useCallback(() => {
    updatePersonalInformation({
      id: data?.id ?? null,
      firstname: data?.firstname ?? '',
      lastname: data?.lastname ?? '',
      nickname: data?.nickname ?? '',
      dob: data?.dob ?? '',
      phoneNumber: data?.phoneNumber ?? '',
      address: {
        ...ADDRESS_OBJECT,
        ...data?.address,
      },
    });
  }, [updatePersonalInformation, data]);

  const handleFormUpdateAndToggleDirty = useCallback(
    (value: any, name: string | symbol) => {
      updatePersonalInformation({
        ...personalInformation,
        [name]: value,
      });

      !formIsDirty && setFormIsDirty(true);
    },
    [formIsDirty, updatePersonalInformation, personalInformation]
  );

  const saveChanges = useCallback(() => {
    const { id, ...rest } = personalInformation;
    const input: IPerson = omitTypename(rest);
    input.address = omitTypename(omitFalsy(rest.address));

    // @ts-ignore - Ignorning type errors from using omitTypename. that function should be rewritten to probably use generics
    updateMyProfileFn({
      variables: {
        // @ts-ignore
        input,
      },
    })
      .then(() => {
        showToast('Profile updated successfully.', 'success');
        setFormIsDirty(false);
        refetchPersonalInfo();
      })
      .catch(() => showToast('There was an error saving your changes.', 'error'));
  }, [personalInformation, updateMyProfileFn]);

  if (updateMyProfileLoading) {
    return <LoadingLines number={7} />;
  }
  return (
    <FormWrapper2
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onSave={saveChanges}
      onCancel={handleResetForm}
      saveDisabled={!validForm(personalInformation)}
    >
      <Row>
        <Col md={6} sm={12}>
          <TextInput
            label="Preferred First Name"
            name="nickname"
            value={personalInformation?.nickname ?? ''}
            onChange={handleFormUpdateAndToggleDirty}
            helpTooltipText="First name displayed throughout Kangarootime"
            disabled={readOnly}
          />
        </Col>
        <Col md={{ span: 4, offset: 2 }}>
          <DateInput
            required
            disabled={readOnly || AdpEnabled}
            label="Date of Birth"
            maxDate={moment().subtract(15, 'years')}
            date={personalInformation?.dob ?? ''}
            onDateSelect={(date) => handleFormUpdateAndToggleDirty(date, 'dob')}
            placeholder="Date of Birth"
            isValid={Boolean(personalInformation?.dob)}
            initialVisibleMonth={() => moment().subtract(15, 'years').month(0)}
            isOutsideRange={(day) => moment().diff(day, 'year') < 15}
            dateOnly
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TextInput
            required
            disabled={readOnly || AdpEnabled}
            label="First Name"
            name="firstname"
            value={personalInformation?.firstname ?? ''}
            onChange={handleFormUpdateAndToggleDirty}
            errorText={errorMessage.firstName}
          />
        </Col>
        <Col md={6}>
          <TextInput
            required
            disabled={readOnly || AdpEnabled}
            label="Last Name"
            name="lastname"
            value={personalInformation?.lastname ?? ''}
            onChange={handleFormUpdateAndToggleDirty}
            errorText={errorMessage.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <AddressInput
            required
            disabled={readOnly || AdpEnabled}
            address={personalInformation.address}
            onChange={(address: any) => handleFormUpdateAndToggleDirty(address, 'address')}
            errorText={errorMessage.address.staff}
            addressLabel="Street Address"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <PhoneInput
            required
            disabled={readOnly || AdpEnabled}
            label="Phone Number"
            name="personal-ph"
            value={personalInformation?.phoneNumber ?? ''}
            onChange={(value: string) => handleFormUpdateAndToggleDirty(value, 'phoneNumber')}
            errorText={errorMessage.phone}
            isInvalid={!parsePhoneNumberFromStringWithRegion(personalInformation?.phoneNumber)?.isValid()}
          />
        </Col>
      </Row>
    </FormWrapper2>
  );
};

export default PersonalInformationForm;
