export const personnelFields = `
  id
  personId
  firstName
  lastName
  dateOfBirth
  conflictsOfInterest {
    dateOfAwareness
    qualification
    rtoRegistrationCode
    trainingOrganisationName
  }
  employedInServices {
    serviceName
    position
    positionText
    streetLine1
    streetLine2
    state
    postCode
    periodFrom
    periodTo
  }
  operationOfServices {
    serviceName
    position
    positionText
    streetLine1
    streetLine2
    state
    postCode
    periodFrom
    periodTo
  }
  otherEnterprises {
    businessType
    businessTypeText
    businessName
    abn
    rtoRegistrationCode
    typeOfInvolvement
    dateOfAwareness
  }
  providerRoles {
    id
    endDate
    position
    positionText
    startDate
  }
  serviceRoles {
    id
    endDate
    position
    positionText
    startDate
    serviceId
  }
  workingWithChildrenChecks {
    action
    cardNumber
    expiryDate
    issuingState
  }
`;

export const pagedPersonnel = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${personnelFields}
  }
`;
