import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import centerFields from 'gql/center/fields';
import { spaceFields } from 'gql/space/fields';
import { classFields } from 'gql/class/fields';
import moment from 'moment';
import { sortBy } from 'lodash';

export interface ISearchCentersData {
  searchCenters: {
    totalResults: number;
    data: ICenter[];
  };
}

export interface ISearchCentersVariables {
  input: IElasticsearchQuery;
}

interface IGetCenterData {
  getCenter: ICenter;
}

interface IGetCenterVariables {
  id: string;
}

interface IGetCenterSpacesData {
  getSpacesForCenter: ISpace[];
}

interface IGetCenterSpacesVariables {
  centerId: string;
}

interface IGetClassesForCenterData {
  getClassesForCenter: IClass[];
}

interface IGetClassesForCenterVariables {
  centerId: string;
}

interface IGetCenterBillingSettingsByBusinessData {
  getCenterBillingSettingsByBusiness: ICenterBillingSettings[];
}

interface IGetCenterBillingSettingsByBusinessVariables {
  input: {
    businessId: string;
  };
}

interface IGetPaginatedCentersForBusinessVariables {
  input: {
    businessId: string;
    searchKey: string | null;
    sortBy: string | null;
    sortDirection: string | null;
    pageNumber: number;
    pageSize: number;
    statuses?: string[] | null;
    states?: string[] | null;
    tagIds?: string[] | null;
  };
}

interface IGetPaginatedCentersForBusinessData {
  getPaginatedCentersForBusiness: IPagedResult<ICenter>;
}

export const SEARCH_CENTERS = (fields: string = centerFields) => gql`
  query($input: SearchInput!) {
    searchCenters(input: $input) {
      totalResults
      data {
        ${fields}
      }
    }
  }
`;

export const GET_CENTER = (fields: string = centerFields) => gql`
  query ($id: String!) {
    getCenter(id: $id) {
      ${fields}
    }
  }
`;

export const GET_CENTER_SPACES = (fields: string = spaceFields) => gql`
  query ($centerId: String!) {
    getSpacesForCenter(centerId: $centerId) {
      ${fields}
    }
  }
`;

const GET_CLASSES_FOR_CENTER = (fields: string = classFields) => gql`
  query ($centerId: String!) {
    getClassesForCenter (centerId: $centerId) {
      ${fields}
    }
  }
`;

const GET_CENTER_BILLING_SETTINGS_BY_BUSINESS = () => gql`
  query ($input: GetCenterBillingSettingsByBusinessInput!) {
    getCenterBillingSettingsByBusiness(input: $input) {
      centerId
      center {
        id
        name
        deactivatedAt
        avatar {
          url
        }
        address {
          city
          state
          country
        }
      }
      passThroughSurcharge
      passThroughAchSurcharge
      passThroughCreditSurcharge
      passThroughDishonor
      dishonorFeeAmount
    }
  }
`;

const GET_CENTER_ITEMIZED_BILLING_SETTINGS_BY_BUSINESS = () => gql`
  query ($input: GetCenterBillingSettingsByBusinessInput!) {
    getCenterBillingSettingsByBusiness(input: $input) {
      center {
        id
        name
        deactivatedAt
        avatar {
          url
        }
        address {
          city
          state
          country
        }
        tags {
          id
          category
          name
        }
      }
      itemizedBillType
      groupSubsidyAndSessionLineItems
    }
  }
`;

export const GET_PAGINATED_CENTERS_FOR_BUSINESS = (fields: string = centerFields) => gql`
  query($input: GetCentersInput!) {
    getPaginatedCentersForBusiness(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        ${fields}
      }
    }
  }
`;

export const GET_PAGINATED_CENTERS_WITH_ADDRESS = (fields: string = centerFields) => gql`
  query ($input: GetCentersInput!) {
    getPaginatedCentersForBusiness(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        avatar {
          url
          fields
          provisionalGetUrl
          __typename
        }
        name
        address {
          address1
          address2
          city
          state
          postalCode
        }
        id
      }
    }
  }
`;

const GET_CENTER_BATCHING_SETTINGS_BY_BUSINESS = () => gql`
  query ($input: GetCenterBatchingSettingsByBusinessInput!) {
    getCenterBatchingSettingsByBusiness(input: $input) {
      centerId
      center {
        id
        name
        avatar {
          url
        }
        address {
          city
          state
          country
        }
      }
      enableAutomaticBatching
    }
  }
`;

export const useSearchCenters = <TData = ISearchCentersData>(
  options?: QueryHookOptions<TData, ISearchCentersVariables>,
  fields?: string
) =>
  useQuery<TData, ISearchCentersVariables>(SEARCH_CENTERS(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetCenter = (options?: QueryHookOptions<IGetCenterData, IGetCenterVariables>, fields?: string) =>
  useQuery<IGetCenterData, IGetCenterVariables>(GET_CENTER(fields), {
    fetchPolicy: 'network-only',
    skip: !options?.variables?.id ?? false,
    ...options,
  });

export const useGetCenterSpaces = (
  options?: QueryHookOptions<IGetCenterSpacesData, IGetCenterSpacesVariables>,
  fields?: string
) =>
  useQuery<IGetCenterSpacesData, IGetCenterSpacesVariables>(GET_CENTER_SPACES(fields), {
    fetchPolicy: 'network-only',
    skip: !options?.variables?.centerId ?? false,
    ...options,
  });

export const useGetClassesForCenter = (
  options?: QueryHookOptions<IGetClassesForCenterData, IGetClassesForCenterVariables>,
  fields?: string
) =>
  useQuery<IGetClassesForCenterData, IGetClassesForCenterVariables>(GET_CLASSES_FOR_CENTER(fields), {
    fetchPolicy: 'cache-and-network',
    skip: !options?.variables?.centerId ?? false,
    ...options,
  });

export const useGetCenterBillingSettingsByBusiness = (
  options?: QueryHookOptions<IGetCenterBillingSettingsByBusinessData, IGetCenterBillingSettingsByBusinessVariables>
) =>
  useQuery<IGetCenterBillingSettingsByBusinessData, IGetCenterBillingSettingsByBusinessVariables>(
    GET_CENTER_BILLING_SETTINGS_BY_BUSINESS(),
    options
  );

export const useGetCenterItemizedBillingSettingsByBusiness = (
  options?: QueryHookOptions<IGetCenterBillingSettingsByBusinessData, IGetCenterBillingSettingsByBusinessVariables>,
  fields?: string
) =>
  useQuery<IGetCenterBillingSettingsByBusinessData, IGetCenterBillingSettingsByBusinessVariables>(
    GET_CENTER_ITEMIZED_BILLING_SETTINGS_BY_BUSINESS(),
    options
  );

export const useGetActiveClassesForCenter = (
  options?: QueryHookOptions<IGetClassesForCenterData, IGetClassesForCenterVariables>,
  fields?: string
) => {
  const { data, loading, error } = useQuery<IGetClassesForCenterData, IGetClassesForCenterVariables>(
    GET_CLASSES_FOR_CENTER(fields ? fields + ' archivedAt' + ' startsAt' + ' endsAt' : fields),
    {
      fetchPolicy: 'cache-and-network',
      skip: !options?.variables?.centerId ?? false,
      ...options,
    }
  );

  const today = moment();
  const classes =
    sortBy(
      data?.getClassesForCenter.filter(
        (c) =>
          !c.archivedAt &&
          moment(c.startsAt).isSameOrBefore(today) &&
          (!c.endsAt || moment(c.endsAt).isSameOrAfter(today))
      ),
      'name'
    ) ?? [];

  return { loading, classes, error };
};

export const useGetPaginatedCentersForBusiness = (
  options?: QueryHookOptions<IGetPaginatedCentersForBusinessData, IGetPaginatedCentersForBusinessVariables>,
  fields?: string
) =>
  useQuery<IGetPaginatedCentersForBusinessData, IGetPaginatedCentersForBusinessVariables>(
    GET_PAGINATED_CENTERS_FOR_BUSINESS(fields),
    options
  );

export const useGetPaginatedCentersWithAddress = (
  options?: QueryHookOptions<IGetPaginatedCentersForBusinessData, IGetPaginatedCentersForBusinessVariables>,
  fields?: string
) =>
  useQuery<IGetPaginatedCentersForBusinessData, IGetPaginatedCentersForBusinessVariables>(
    GET_PAGINATED_CENTERS_WITH_ADDRESS(fields),
    options
  );

interface IGetCenterBatchingSettingsByBusinessData {
  getCenterBatchingSettingsByBusiness: ICenterBatchingSetting[];
}

interface IGetCenterBatchingSettingsByBusinessVariables {
  input: {
    businessId: string;
  };
}

export const useGetCenterBatchingSettingsByBusiness = (
  options?: QueryHookOptions<IGetCenterBatchingSettingsByBusinessData, IGetCenterBatchingSettingsByBusinessVariables>,
  fields?: string
) =>
  useQuery<IGetCenterBatchingSettingsByBusinessData, IGetCenterBatchingSettingsByBusinessVariables>(
    GET_CENTER_BATCHING_SETTINGS_BY_BUSINESS(),
    options
  );
