import React, { useState, useCallback } from 'react';
import Button from 'shared/components/Buttons';
import TextInput from 'shared/components/TextInput';

interface IProps {
  loading: boolean;
  addLocation: (name: string) => void;
}

const CustomLocationInput: React.FC<IProps> = ({ loading, addLocation, ...props }) => {
  const [locationNameInput, setLocationNameInput] = useState({
    show: false,
    value: '',
  });

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // listen for the enter key
      if (event.keyCode === 13) {
        addLocation(locationNameInput.value);

        setLocationNameInput({ show: false, value: '' });
      }
    },
    [locationNameInput, addLocation]
  );

  return (
    <>
      {locationNameInput.show && (
        <TextInput
          label=""
          aria-label="Custom location name"
          value={locationNameInput.value}
          onChange={(value: string) => setLocationNameInput((prev) => ({ ...prev, value }))}
          // @ts-ignore - exists on an input
          onKeyDown={handleKeyDown}
        />
      )}
      {locationNameInput.show ? (
        <Button
          aria-label="Cancel custom location"
          variant="light"
          onClick={() => setLocationNameInput({ show: false, value: '' })}
        >
          Cancel
        </Button>
      ) : (
        <Button
          id="add_new_location"
          loading={loading}
          disabled={loading}
          aria-label="Add new custom location"
          variant="outline-primary"
          onClick={() => setLocationNameInput({ show: true, value: '' })}
        >
          Add New
        </Button>
      )}
    </>
  );
};

export default CustomLocationInput;
