import COUNTRY_INFO, { DEFAULT_COUNTRY } from '../dropdownOptions/countryInfo';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
const TagCategory = {
  CENTER: { value: 'CENTER', label: fieldLabels.center },
  BUSINESS: { value: 'BUSINESS', label: 'Business' },
  STAFF: { value: 'STAFF', label: 'Employee' },
  CONTACT: { value: 'CONTACT', label: 'Contact' },
  CHILD: { value: 'CHILD', label: 'Child' },
  ACCOUNT: { value: 'ACCOUNT', label: 'Account' },
};

export enum TagsTypeElasticIndex {
  Center = 'center',
  Person = 'person',
  Children = 'children',
  Account = 'account',
  Guardian = 'guardian',
}

export default TagCategory;
