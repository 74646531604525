import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosError } from 'axios';
import appConfig from 'config';

export class APIError extends Error {
  constructor(msg, code) {
    super(msg);
    this.code = code;
  }

  publicMessage() {
    if (this.code && this.code >= 400 && this.code < 500) {
      return this.message;
    }
    return 'Whoops we could not find that right now. Please try again later.';
  }

  isRetryable() {
    return !(this.code && this.code >= 400 && this.code < 500);
  }
}

/**
 * Helper method to quickly call billing api routes directly. It handles getting the base url and authentication.
 * @param {'GET'|'POST'|'PUT'|'PATCH'|'DELETE'} method HTTP method
 * @param {string} route api route after base url, please do not include beginning forward slash. This should be fully formed and include any query params
 * @param {any} [body] optional body to send as part of the request
 * @returns {{data: any, error: APIError | undefined, loading: boolean, refetch: () => void}}
 */
export function useBillApi(method, route, body) {
  const jwtToken = useSelector((state) => state.session.token);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const fetch = async () => {
    const config = {
      method,
      url: `${appConfig.api.billing.uri}/${route}`,
      headers: { Authorization: `Bearer ${jwtToken}` },
    };
    if (body) config.data = body;
    try {
      const response = await axios(config);
      return response.data;
    } catch (e) {
      if (typeof e === 'string') {
        throw new APIError(e);
      } else if (e instanceof AxiosError) {
        throw new APIError(e.response.data?.message ?? e.response.data, e.response.status);
      } else if (e instanceof Error) {
        throw new APIError(e.message);
      }
    }
  };

  const refetch = () => {
    setLoading(true);
    setError(undefined);
    fetch()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    setData(undefined);
    setError(undefined);
    fetch()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
    // we do not necessary want to re-run this hook everytime the jwt changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method, route, body]);

  return { data, error, loading, refetch };
}
