import React, { useState, useCallback, useEffect } from 'react';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import DateInput from 'shared/components/DateInput';
import errorMessage from 'shared/constants/errorMessages';
import moment from 'moment';
import { useUpdateEmploymentHistory } from '../graphql/mutations';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import { showToast } from 'shared/components/Toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/reducers';

interface IProps {
  staff: IStaff;
  readOnly: boolean;
}

const EmploymentStartForm: React.FC<IProps> = ({ staff, readOnly }) => {
  const [startDate, updateStartDate] = useState(staff.employmentStartDate);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;
  const resetFormData = useCallback(() => {
    updateStartDate(staff.employmentStartDate);
  }, [staff.employmentStartDate]);
  const [updateEmploymentHistoryMutation, { loading }] = useUpdateEmploymentHistory();

  const handleChange = useCallback(
    (value) => {
      updateStartDate(value);
      setFormIsDirty(true);
    },
    [updateStartDate]
  );

  const saveStartDate = useCallback(async () => {
    await updateEmploymentHistoryMutation({
      variables: { input: { id: staff.id, employmentStartDate: startDate } },
    }).then(
      () => showToast('Employment start date updated.', 'success'),
      () => showToast(errorMessage.generic, 'error')
    );
  }, [startDate, staff.id, updateEmploymentHistoryMutation]);

  // reset value if staff is reactivated from profile
  useEffect(() => {
    resetFormData();
  }, [staff.employmentStartDate, resetFormData]);

  if (loading) {
    return <LoadingLines number={2} />;
  }
  return (
    <FormWrapper2
      onCancel={resetFormData}
      onSave={saveStartDate}
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      saveDisabled={readOnly}
      blockButtons={true}
    >
      <DateInput
        label="Start Date"
        date={startDate}
        onDateSelect={handleChange}
        isOutsideRange={(day) => staff.employmentEndDate && day.isAfter(moment(staff.employmentEndDate))}
        // employment start date cannot be updated once end date is saved
        disabled={Boolean(staff.employmentEndDate) || readOnly || AdpEnabled}
      />
    </FormWrapper2>
  );
};

export default EmploymentStartForm;
