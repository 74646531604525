import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { useGetAccountDepositsSummary } from 'gql/deposit/queries';
import { returnDeposit } from '../duck/action';
import { useReturnDeposit } from 'gql/deposit/mutations';
import { showToast } from 'shared/components/Toast';
import moment from 'moment';
import DateInput from 'shared/components/DateInput';
import { useTranslation } from 'react-i18next';
import TextInput from 'shared/components/TextInput';
import { Col, Row } from 'shared/components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import Alert from 'shared/components/Alert';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import DepositCheckOption from './DepositCheckOption';
import { findIndex } from 'lodash';
import { currencyFormat } from 'shared/util/currency';

export type ActionType = 'toggle' | 'update';
interface IFormShape {
  date: string;
  accountId: string | null;
  selectedDepositBalances: IAccountDepositSummary[] | null;
  note: string;
}

interface IProps {
  isOpen: boolean;
  account?: IAccount | null;
  accountId: string;
  selectedDepositBalances: IAccountDepositSummary | null;
  onClose: () => void;
  refetchDeposits: () => void;
}

const ReturnDepositModal: React.FC<IProps> = ({
  isOpen,
  account,
  accountId,
  selectedDepositBalances,
  onClose,
  refetchDeposits,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IFormShape>({
    date: moment().format(),
    accountId: accountId,
    selectedDepositBalances: null,
    note: '',
  });

  const { data: depositBalances, loading } = useGetAccountDepositsSummary({
    variables: {
      input: {
        accountId: accountId,
      },
    },
    skip: accountId == null || accountId === '',
  });

  const [returnDepositFn, { loading: returnDepositLoading }] = useReturnDeposit({
    onCompleted: (result) => {
      if (result?.returnDeposit) {
        handleClose();
        refetchDeposits();
        showToast('Deposit returned successfully.', 'success');
        dispatch(returnDeposit(result.returnDeposit));
        // alongside returning the deposit a transaction is been created
      }
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const resetForm = useCallback(() => {
    setFormData({
      accountId: accountId,
      selectedDepositBalances: null,
      note: '',
      date: moment().format(),
    });
  }, []);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  const handleSave = useCallback(() => {
    returnDepositFn({
      variables: {
        input: {
          accountId: formData.accountId as string,

          returnDeposits: formData.selectedDepositBalances ? [...formData.selectedDepositBalances] : [],
          appliesDate: moment(formData.date as string).format('MM/DD/YYYY'),
          description: formData.note as string,
        },
      },
    });
  }, [formData, returnDepositFn]);

  function stripTypeNames(value) {
    if (Array.isArray(value)) {
      return value.map(stripTypeNames);
    } else if (value !== null && typeof value === 'object') {
      const newObject = {};
      for (const property in value) {
        if (property !== '__typename') {
          newObject[property] = stripTypeNames(value[property]);
        }
      }
      return newObject;
    } else {
      return value;
    }
  }

  const handleDepositCheckOption = (selected: IAccountDepositSummary, actionType: ActionType = 'toggle') => {
    if (actionType === 'toggle') {
      const hasSelected = findIndex(formData.selectedDepositBalances ?? [], (s) => s.childId === selected.childId) > -1;
      if (hasSelected) {
        // remove it
        const newSelected = (formData.selectedDepositBalances ?? []).filter((s) => s.childId !== selected.childId);
        setFormData({ ...formData, selectedDepositBalances: newSelected });
      } else {
        // concat it
        setFormData({ ...formData, selectedDepositBalances: [...(formData.selectedDepositBalances ?? []), selected] });
      }
    }

    if (actionType === 'update') {
      const newSelected = (formData.selectedDepositBalances ?? []).map((s) => {
        if (s.childId === selected.childId) {
          return selected;
        }
        return s;
      });
      setFormData({ ...formData, selectedDepositBalances: newSelected });
    }
  };

  const isInputValid = useMemo(() => {
    let isValid = true;

    const { selectedDepositBalances } = formData;
    if ((selectedDepositBalances ?? []).length === 0) {
      isValid = false;
    } else {
      for (let index = 0; index < selectedDepositBalances!.length; index++) {
        const modified = selectedDepositBalances![index];
        const { childId } = modified;
        const original = (depositBalances?.getAccountDepositsSummary ?? []).find((b) => b.childId === childId);
        if (!original) {
          isValid = false;
          break;
        }

        if (modified.amount > original.amount) {
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  }, [formData, depositBalances]);

  return (
    <SideModalDrawer
      title="Return Deposit"
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      secondaryChoice="Cancel"
      primaryCallback={handleSave}
      primaryButtonProps={{
        variant: 'primary',
        disabled: !isInputValid || !formData.date,
        loading: returnDepositLoading,
      }}
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <Row className="mb-4 d-flex">
        <Col>
          <DateInput
            required
            label="Apply Date"
            date={formData.date}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, date }))}
            placeholder={t('formatters.date')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex flex-row mt-4">
            <Form.Label className="text-nowrap">Amount to return</Form.Label>
            <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
          </div>

          <ul className="unstyled-list mb-0">
            {loading ? (
              <LoadingLines />
            ) : (
              stripTypeNames(depositBalances?.getAccountDepositsSummary ?? []).map(
                (depositBalanceItem: IAccountDepositSummary, idx: number) => (
                  <li className="mb-2" key={`deposit-balance-${depositBalanceItem.amount}-${idx}`}>
                    <DepositCheckOption
                      childId={depositBalanceItem.childId}
                      availableBalance={depositBalanceItem.amount}
                      title={
                        account?.children?.find((s) => s.id === depositBalanceItem.childId)?.fullName ??
                        'Unlinked Deposit'
                      }
                      selectedAccountDepositSummary={formData.selectedDepositBalances ?? []}
                      accountDepositSummary={depositBalanceItem}
                      onSelect={handleDepositCheckOption}
                    />
                  </li>
                )
              )
            )}
          </ul>

          <div className="small mb-2 mt-2">
            The deposit balance of{' '}
            <b>{currencyFormat(formData.selectedDepositBalances?.reduce((sum, t) => sum + t.amount, 0))}</b> will be
            returned to the {`${account?.name} Account`} - {account?.center?.name ?? ''}
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TextInput
            label="Description"
            value={formData.note}
            onChange={(value) => setFormData((prev) => ({ ...prev, note: value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert variant="info">
            A deposit was not collected through this system and these funds must be paid out through alternate means.
          </Alert>
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default ReturnDepositModal;
