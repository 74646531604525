export const roleFields: string = `
  id
  name
  businessId
  scopeType
  hierarchyLevel
  hasAllPermissions
  assignedStaffIds
  areaLevels {
    roleId
    area
    level
    permission
  }
  isAccountOwnerRole
  scheduleVisibility
  isIntegrationRole
  `;

export const roleFieldsWithStaff: string = `
  ${roleFields}
  staff {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
  }
`;
