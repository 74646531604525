import React, { useState } from 'react';
import TextInput, { EmailInput } from 'shared/components/TextInput';
import AddressInput from 'shared/components/AddressInput';
import { Row, Col } from 'shared/components/Layout';
import errorMessage from 'shared/constants/errorMessages';
import PhoneInput from 'shared/components/PhoneInput';
import Select from 'shared/components/Select';
import { getTimezoneOptions } from 'shared/constants/dropdownOptions/timezoneOptions';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { isEmailSyntaxValid, isEmailValid } from 'shared/util/email';
import DateInput from 'shared/components/DateInput';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { isRegion } from 'shared/util/region';
import { useTranslation } from 'react-i18next';
import { capitalize, isValidPhoneNumber } from 'shared/util/string';
import TaxInput from 'shared/components/TaxInput/TaxInput';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {
  name: string;
  address: any;
  phoneNumber: string;
  timezone: any;
  openingDate: any;
  closingDate: any;
  email: string;
  website?: string;
  handleInput: any;
  formEditable?: boolean;
  acn: string;
  abn: string;
  isValidAcn: boolean;
  isValidAbn: boolean;
  externalId?: string;
  isValidTaxId: boolean;
  taxId: string;
  serviceType?: CenterServiceType;
}

const CenterDetailInputs: React.FC<IProps> = ({
  name,
  address,
  phoneNumber,
  timezone,
  openingDate,
  closingDate,
  email,
  website,
  handleInput,
  formEditable,
  acn,
  abn,
  isValidAcn,
  isValidAbn,
  externalId,
  isValidTaxId,
  taxId,
  serviceType,
  ...props
}) => {
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const { isValid: isClosedValid, errors: closedErrors } = closingDateValidation(closingDate, openingDate);
  const { t } = useTranslation();

  const serviceTypeOptions = [
    { label: t('center.service-type.ldc'), value: 'LDC' },
    { label: t('center.service-type.oshc'), value: 'OSHC' },
    { label: t('center.service-type.ldcoshc'), value: 'LDCOSHC' },
  ];

  return (
    <>
      <Row align="start">
        <Col xs={12} sm={6} className="order-1">
          <TextInput
            id="center-name-input"
            name="name"
            label={`${fieldLabels.center} Name`}
            value={name}
            onChange={handleInput}
            disabled={!formEditable}
            required
            errorText={errorMessage.centerName}
          />
        </Col>
        <Col xs={12} sm={6} className="order-2 order-sm-6">
          <></>
        </Col>
        <Col xs={12} sm={3} className="order-3">
          <DateInput
            label="Opening Date"
            date={openingDate}
            onDateSelect={(date) => handleInput(date, 'openingDate')}
            disabled={!formEditable}
          />
        </Col>
        <Col xs={12} sm={3} className="order-4 order-sm-7">
          <></>
        </Col>
        <Col xs={12} sm={3} className="order-5">
          <DateInput
            label="Closing Date"
            date={closingDate}
            onDateSelect={(date) => handleInput(date, 'closingDate')}
            isOutsideRange={(date) => !closingDateValidation(date, openingDate).isValid}
            isValid={isClosedValid}
            disabled={!formEditable}
          />
        </Col>
        <Col xs={12} sm={3} className="order-6 order-sm-8">
          {!isClosedValid && <Form.Text className="text-danger">{closedErrors[0]}</Form.Text>}
        </Col>
      </Row>
      <Row>
        <Col>
          <AddressInput
            address={address}
            onChange={(value: any) => handleInput(value, 'address')}
            disabled={!formEditable}
            required
            errorText={errorMessage.address.center}
          />
        </Col>
      </Row>
      <Row>
        {/* todo: this should become a phone input */}
        <Col xs={6}>
          <PhoneInput
            id="center-phone-input"
            name="phoneNumber"
            label={`${fieldLabels.center} Phone Number`}
            value={phoneNumber}
            onChange={handleInput}
            disabled={!formEditable}
            isInvalid={!isValidPhoneNumber(phoneNumber)}
            errorText={errorMessage.phone}
            required
          />
        </Col>
        <Col xs={6}>
          <TextInput
            label={t('center.external-id')}
            name="externalId"
            onChange={(value) => handleInput(value, 'externalId')}
            disabled={!formEditable}
            value={externalId}
          />
        </Col>
      </Row>
      {isRegion('US') && (
        <>
          <Row justify="between" align="start">
            <Col sm={12} xl={6} justify="between" className="h-100">
              <TaxInput
                label={t('center.tax-id')}
                name="tax-id"
                isInvalid={!isValidTaxId}
                value={taxId}
                onChange={(value) => handleInput(value, 'taxId')}
                errorText={!isValidTaxId && abn.length === 11 ? errorMessage.isValidTaxId : errorMessage.taxId}
                disabled={!formEditable}
                isValidTaxId={isValidTaxId}
              />
            </Col>
            <Col sm={12} xl={6} justify="between" className="h-100">
              {''}
            </Col>
          </Row>
        </>
      )}
      <Row justify="between" align="start">
        <Col sm={12} xl={6} justify="between" className="h-100">
          <EmailInput
            label={`${fieldLabels.center} Email`}
            name="email"
            value={email}
            onChange={(value) => handleInput(value, 'email')}
            errorText={
              !isEmailSyntaxValid(email ?? null) ? errorMessage.invalidEmailSyntax : errorMessage.invalidEmailDomain
            }
            isInvalid={!!email && !isEmailValid(email)}
            disabled={!formEditable}
          />
        </Col>
        <Col sm={12} xl={6} justify="between" className="h-100">
          <TextInput
            label={`${fieldLabels.center} URL`}
            name="website"
            value={website}
            onChange={(value) => handleInput(value, 'website')}
            disabled={!formEditable}
          />
        </Col>
      </Row>
      {isRegion('AU') && (
        <>
          <Row justify="between" align="start">
            <Col sm={12} xl={6} justify="between" className="h-100">
              <TextInput
                label={`ABN`}
                name="abn"
                isInvalid={!isValidAbn}
                value={abn}
                onChange={(value) => handleInput(value, 'abn')}
                errorText={!isValidAbn && abn.length === 11 ? errorMessage.invalidAbn : errorMessage.abn}
                disabled={!formEditable}
              />
            </Col>
            <Col sm={12} xl={6} justify="between" className="h-100">
              <TextInput
                label={`ACN`}
                name="acn"
                isInvalid={!isValidAcn}
                value={acn}
                onChange={(value) => handleInput(value, 'acn')}
                errorText={acn.length === 9 ? errorMessage.invalidAcn : errorMessage.acn}
                disabled={!formEditable}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col sm={6}>
          <Select
            required
            label="Time Zone"
            value={timezone}
            options={getTimezoneOptions()}
            onChange={(option) => handleInput(option.value, 'timezone')}
            disabled={!formEditable}
          />
        </Col>
        <Col sm={6}>
          <Select
            required
            label={capitalize(t('spelling.serviceType'))}
            value={serviceType}
            options={serviceTypeOptions}
            onChange={(option) => handleInput(option.value, 'serviceType')}
            disabled={!formEditable}
          />
        </Col>
      </Row>
    </>
  );
};

export default CenterDetailInputs;

export function closingDateValidation(closingDate?: string, openingDate?: string) {
  if (!closingDate) return { isValid: true, errors: [] }; // Only validate if closingDate exists

  const validationRules = {
    openingDateRequired: () => (!openingDate ? ['Opening date is required if closing date is provided.'] : []),
    closingDateCannotBeBeforeOpeningDate: () =>
      moment(closingDate).isBefore(moment(openingDate)) ? ['Closing date cannot be before opening date.'] : [],
  };

  const errors = Object.values(validationRules).flatMap((validation) => validation());
  const isValid = errors.length === 0;

  return { isValid, errors };
}
