import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { serviceFeeGroupFields } from './fields';
import { Mutation } from '../../generated/graphql';

interface ISaveServiceFeeGroupData {
  saveServiceFeeGroup: IServiceFeeGroup;
}

interface IDeleteServiceFeeGroupData {
  success: boolean;
}

interface ISaveServiceFeeGroupVariables {
  input: {
    id: string | null;
    name: string;
    description?: string;
    assignedCenters: string[];
    serviceFees: IServiceFee[];
  };
}

interface IDeleteServiceFeeGroupVariables {
  id: string;
}

export const SAVE_SERVICE_FEE_GROUP = gql`
    mutation ($input: SaveServiceFeeGroupInput!) {
        saveServiceFeeGroup(input: $input) {
            ${serviceFeeGroupFields}
        }
    }
`;

export const DELETE_SERVICE_FEE_GROUP = gql`
  mutation ($id: ID!) {
    deleteServiceFeeGroup(id: $id)
  }
`;

export const useSaveServiceFeeGroup = (
  options?: MutationHookOptions<ISaveServiceFeeGroupData, ISaveServiceFeeGroupVariables>
) => useMutation<ISaveServiceFeeGroupData, ISaveServiceFeeGroupVariables>(SAVE_SERVICE_FEE_GROUP, options);

export const useDeleteServiceFeeGroup = (
  options?: MutationHookOptions<IDeleteServiceFeeGroupData, IDeleteServiceFeeGroupVariables>
) => useMutation<IDeleteServiceFeeGroupData, IDeleteServiceFeeGroupVariables>(DELETE_SERVICE_FEE_GROUP, options);
