import React from 'react';
import { EventProps } from 'react-big-calendar';

interface IProps {
  timeFormatter: (time: string, centerId: string) => string;
}

const CalendarEvent: React.FC<IProps & EventProps<IShift>> = ({ event, timeFormatter }) => {
  return (
    <div>
      <div className="my-2">{event.center.name}</div>
      <div className="mb-2">
        {event.classId ? event.class?.name ?? '' : event.locationId ? event.location?.name ?? '' : ''}
      </div>
      <div className="rbc-event-label">
        {timeFormatter(event.startTime, event.centerId)} - {timeFormatter(event.endTime, event.centerId)}
      </div>
    </div>
  );
};

export default CalendarEvent;
