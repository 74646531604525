import { faCheckCircle, faPlus, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetCenterStatementsForAccount, useGetStatementLink } from 'gql/statement/queries';
import moment from 'moment';
import React, { useState } from 'react';
import {
  convertTimeRangeObjectToString,
  convertTimeRangeStringToObject,
  timeRangeOptions,
} from 'shared/util/timeUtils';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';
import Button, { CreateButton } from 'shared/components/Buttons';
import DataTable, { TableHeader } from 'shared/components/DataTable';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import { showToast } from 'shared/components/Toast';
import { statementStatusLabels } from 'shared/constants/enums/statementEnums';
import colors from '_colors.module.scss';
import ManualStatementRunModalForm from 'pages/Billing/Statements/ManualStatementRunModalForm';
import { useGeneratePdfStatement } from 'gql/statement/mutations';
import { useMediaQuery, useTheme } from '@mui/material';
import Select from 'shared/components/Select';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

/**@todo pagination */
const defaultPageSize = 25;

const seenFormatter = (hasSeen: boolean) =>
  hasSeen ? (
    <FontAwesomeIcon icon={faCheckCircle} color={colors.success} size={'2x'} />
  ) : (
    <FontAwesomeIcon icon={faTimesCircle} color={colors.secondary} size={'2x'} />
  );

interface IProps {
  account: IAccount;
  accountId: string;
  canCreateStatements: boolean;
}

const StatementsTab: React.FC<IProps> = ({ account, accountId, canCreateStatements }) => {
  const { t } = useTranslation();

  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { week } = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;

  const [dateRange, setDateRange] = useState({
    start: moment().startOf(week).subtract(1, 'week').format(),
    end: moment().endOf(week).format(),
  });

  const [isManualStatementScheduleOpen, setIsManualStatementScheduleOpen] = useState(false);

  const [getStatementLink, { loading: statementLinkLoading }] = useGetStatementLink();

  const onClickViewStatement = (statementToView: string) => {
    getStatementLink({
      variables: {
        input: {
          centerId: account?.centerId ?? '',
          statementId: statementToView,
        },
      },
    });
  };

  const { data, loading, refetch } = useGetCenterStatementsForAccount({
    variables: {
      input: {
        centerId: account?.centerId ?? '',
        accountId: account?.id ?? '',
        startDate: moment(dateRange.start).format('YYYY-MM-DD'),
        endDate: moment(dateRange.end).format('YYYY-MM-DD'),
        pageSize: defaultPageSize,
        pageNumber: 1,
      },
    },
  });
  const statements = data?.getCenterStatementsForAccount.data ?? [];

  const getStatusLabel = (status: StatementStatus) => {
    switch (status) {
      case 'ERROR':
        return (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="mr-2" icon={faCircle} style={{ fontSize: 10 }} color={colors.danger} />
            <span>{statementStatusLabels[status].label}</span>
          </div>
        );
      case 'SUCCESS':
        return (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="mr-2" icon={faCircle} style={{ fontSize: 10 }} color={colors.success} />
            <span>{statementStatusLabels[status].label}</span>
          </div>
        );
      default:
        return (
          <div className="d-flex align-items-center">
            <span>{statementStatusLabels[status]?.label ?? status}</span>
          </div>
        );
    }
  };

  return (
    <>
      <Row>
        <Col>
          <DataTable
            renderHeader={() => (
              <TableHeader className="mb-4">
                <div className="d-flex flex-wrap align-items-center">
                  <Select
                    options={timeRangeOptions}
                    value={convertTimeRangeObjectToString(dateRange)}
                    onChange={(string) => setDateRange(convertTimeRangeStringToObject(string))}
                    className={isMobile ? 'my-1 mr-auto' : 'mr-4 flex-shrink-3 flex-grow-0 mb-0'}
                  />
                </div>
                <div className={isMobile ? 'd-flex flex-nowrap my-1 mr-2' : 'd-flex align-items-center mx-auto'}>
                  <DateInput
                    date={dateRange.start}
                    onDateSelect={(start) => setDateRange({ ...dateRange, start })}
                    className="mr-2 flex-shrink-3 flex-grow-0 mb-0"
                  />
                  <div className={isMobile ? 'mt-2 mr-2' : 'mr-2'}>to</div>
                  <DateInput
                    date={dateRange.end}
                    onDateSelect={(end) => setDateRange({ ...dateRange, end })}
                    className="mr-2 flex-shrink-3 flex-grow-0 mb-0"
                  />
                </div>
                {canCreateStatements && (
                  <CreateButton
                    icon={faPlus}
                    className="ml-auto"
                    disabled={!accountId}
                    onClick={() => setIsManualStatementScheduleOpen(true)}
                  >
                    Manual Run
                  </CreateButton>
                )}
              </TableHeader>
            )}
            noPadding
            showPagination={false}
            showSelect={false}
            showLoadingOverlay={loading || statementLinkLoading}
            keyField="id"
            data={statements}
            columns={[
              {
                text: 'Date',
                dataField: 'dateRun',
                sort: true,
                formatter: (cell: string) => moment(cell).format('DD/MM/YYYY'),
              },
              {
                text: 'Recipient',
                dataField: '_recipient',
                sort: true,
                formatter: (_: any, row: IStatement) => `${row.accountFirstName} ${row.accountLastName}`,
              },
              {
                text: 'Email',
                dataField: 'email',
              },
              {
                text: 'Status',
                dataField: 'status',
                formatter: (cell: string, row: IStatement) => getStatusLabel(row.status),
                sort: true,
              },
              {
                text: 'Seen?',
                dataField: 'hasSeen',
                formatter: (cell: string, row: IStatement) => seenFormatter(!!row.customerDownloadedStatementAt),
              },
              {
                text: '',
                dataField: '',
                isDummyField: true,
                formatter: (_: any, row: IStatement) => (
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item text-primary">
                      <a onClick={() => onClickViewStatement(row.id)} style={{ cursor: 'pointer' }}>
                        View Statement
                      </a>
                    </li>
                  </ul>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <ManualStatementRunModalForm
        isOpen={isManualStatementScheduleOpen}
        onAfterRun={() => refetch()}
        onClose={() => setIsManualStatementScheduleOpen(false)}
        centerId={account?.centerId ?? ''}
        accountId={accountId ?? ''}
        startDate={dateRange.start}
        endDate={dateRange.end}
      />
    </>
  );
};

export default StatementsTab;
