import React from 'react';
import Input, { IBaseInputProps } from './Input';

export interface ITextInputProps extends IBaseInputProps {
  value: string | null | undefined;
  numberFormat?: null;
}

const TextInput: React.FC<ITextInputProps> = ({ type = 'text', ...props }) => {
  return <Input {...props} type={type} />;
};

export default TextInput;
