import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from 'shared/components/TextInput';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { getFullName, getInitials, isBlank } from 'shared/util/string';
import { PaymentMethodType, PayrixService } from 'shared/services/payrixService';
import { useCreatePaymentMethod } from 'gql/paymentMethod/mutations';
import config from 'config';
import Checkbox from 'shared/components/Checkbox';
import { showToast } from 'shared/components/Toast';
import { createPaymentMethodForAccountSuccess } from 'pages/Families/subroutes/Accounts/duck/actions';
import { TokenizerService } from 'shared/services/tokenizerService';
import getLastFourOfPaymentNumber from 'shared/util/getLastFourOfPaymentNumber';
import { useTranslation } from 'react-i18next';
import AddressInput from '../../../../../../../../shared/components/AddressInput/AddressInputWithAutoComplete';
import { useCreateAuthorizedPersonPaymentMethod } from 'gql/paymentMethod/authorizedPerson/mutation';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';

const US_ROUTING_NUMBER_REGEX = /^\d{9}$/;

const newAddress: IAddress = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: 'US',
  postalCode: '',
};

interface IFormShape {
  firstName: string;
  lastName: string;
  routingNumber: string;
  confirmRoutingNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  accountType: PaymentMethodType.CHECKING_ACCOUNT | PaymentMethodType.SAVINGS_ACCOUNT;
}

interface IProps {
  isOpen: boolean;
  account: IAccount;
  contact?: IContact;
  onClose: () => void;
}

const AddBankAccountModal: React.FC<IProps> = ({ isOpen, account, contact, onClose }) => {
  const { t } = useTranslation(['billing', 'translation']);
  const dispatch = useDispatch();
  // TODO: Fix this so that it is typed appropriately
  // @ts-ignore
  const jwtToken = useSelector((state) => state.session.token);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [address, setAddress] = useState({ ...newAddress });
  const [formData, setFormData] = useState<IFormShape>({
    firstName: '',
    lastName: '',
    routingNumber: '',
    accountNumber: '',
    confirmRoutingNumber: '',
    confirmAccountNumber: '',
    accountType: PaymentMethodType.CHECKING_ACCOUNT,
  });
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [isAutoPay, setIsAutoPay] = useState<boolean>(false);
  const [termsAndConditionsConfirmed, setTermsAndConditionsConfirmed] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    if (contact) {
      setFormData({
        ...formData,
        firstName: contact.firstname,
        lastName: contact.lastname,
      });
    }
  }, [contact]);

  const [createAuthorizedPersonPaymentMethod] = useCreateAuthorizedPersonPaymentMethod({
    onCompleted: (result) => {
      const paymentMethod = result.createAuthorizedPersonPaymentMethod;
      dispatch(
        createPaymentMethodForAccountSuccess({
          ...paymentMethod,
          accountId: account?.id,
        })
      );
      showToast(t('billing:payment-methods.add-bank-account-success'), 'success');
      handleDismiss();
    },
    onError: () => {
      showToast(t('billing:payment-methods.add-bank-account-error'), 'error');
    },
  });

  const validateForm = useCallback(() => {
    const validRoutingNumber =
      !isBlank(formData.routingNumber) &&
      !isBlank(formData.confirmRoutingNumber) &&
      US_ROUTING_NUMBER_REGEX.test(formData.routingNumber) &&
      formData.routingNumber === formData.confirmRoutingNumber;
    const validAccountNumber =
      !isBlank(formData.accountNumber) &&
      !isBlank(formData.confirmAccountNumber) &&
      formData.accountNumber === formData.confirmAccountNumber;

    return validRoutingNumber && validAccountNumber;
  }, [formData]);

  const tokenizeInput = useCallback(
    async (input: IFormShape): Promise<IPayrixToken | IFatZebraAch | null> => {
      try {
        const isUS = config.locale.region === 'US';
        const unencryptedTokenizerRequestBody: ITokenizerRequestBody = {
          merchantId: isUS ? account.center?.merchant?.merchantId : null,
          centerId: !isUS ? account.centerId : null,
          secretKeyId: !isUS ? account.entityId : null,
          paymentNumber: input.accountNumber,
          routing: input.routingNumber,
          address1: address.address1 ?? null,
          city: address.city ?? null,
          state: address.state.split('-')[1] ?? null,
          zip: address.postalCode ?? null,
          first: input.firstName,
          last: input.lastName,
          isSandbox: `${account.center?.merchant?.isSandbox ?? 'false'}`,
        };

        const tokenizerService = new TokenizerService();
        return await tokenizerService.encryptAndSendTokenizeRequest<IPayrixToken | IFatZebraAch>(
          unencryptedTokenizerRequestBody,
          jwtToken
        );
      } catch (e) {
        showToast(t('translation:general.error'), 'error');
      }
      return null;
    },
    [
      account.center?.merchant?.isSandbox,
      account.center?.merchant?.merchantId,
      account.centerId,
      account.entityId,
      address.address1,
      address.city,
      address.postalCode,
      address.state,
      jwtToken,
      t,
    ]
  );

  const handleSubmit = useCallback(async () => {
    setSubmittingForm(true);
    const token = await tokenizeInput(formData);

    if (token) {
      const lastFour: string =
        'payment' in token ? token.payment.number : getLastFourOfPaymentNumber(token.account_number);
      const responseToken: string = 'account_number' in token ? token.id : token.token;
      const payrixService = new PayrixService();

      const input = {
        businessId: account.entityId,
        lastFour,
        processorId: token.id,
        token: responseToken,
        // TODO: type this with a union or enum, instead of any
        // also fix so this doesn't need to fire up payrixService to grab this value
        type: payrixService.enumValueToString(formData.accountType),
        accountId: account.id,
        routingNumber: parseInt(formData.routingNumber, 10),
        isPrimary: isDefault,
        isAutoPay,
        termsAndConditionsConfirmed,
      };
      if (token) {
        createAuthorizedPersonPaymentMethod({
          variables: {
            input: {
              ...input,
              personId: contact?.id,
              centerId: account?.centerId,
            },
          },
        });
      }

      setSubmittingForm(false);
    }

    setSubmittingForm(false);
  }, [
    account?.centerId,
    account.entityId,
    account.id,
    contact?.id,
    createAuthorizedPersonPaymentMethod,
    formData,
    isAutoPay,
    isDefault,
    termsAndConditionsConfirmed,
    tokenizeInput,
  ]);

  const handleDismiss = useCallback(() => {
    setFormData({
      firstName: '',
      lastName: '',
      routingNumber: '',
      accountNumber: '',
      confirmRoutingNumber: '',
      confirmAccountNumber: '',
      accountType: PaymentMethodType.CHECKING_ACCOUNT,
    });
    setIsDefault(false);
    setIsAutoPay(false);
    setTermsAndConditionsConfirmed(false);
    onClose();
  }, [onClose]);

  return (
    <SideModalDrawer
      show={isOpen}
      title={step === 1 ? 'Step 1: Input Bank Details' : 'Step 2: Input Billing Address'}
      primaryChoice={step === 1 ? 'Continue' : 'Save'}
      primaryCallback={() => (step === 1 ? setStep(2) : handleSubmit())}
      primaryButtonProps={{
        disabled:
          !validateForm() ||
          (step === 2 &&
            [address.address1, address.city, address.state, address.country, address.postalCode].some((x) => !x)),
        loading: submittingForm,
      }}
      secondaryChoice={step === 1 ? 'Cancel' : 'Back'}
      closeOnPrimaryCallback={false}
      onHide={handleDismiss}
      secondaryCallback={() => (step === 1 ? handleDismiss() : setStep(1))}
    >
      {step === 1 ? (
        <div className="d-flex flex-column">
          {contact ? (
            <div className="mb-4">
              <label>Bank Account holder</label>
              <AvatarDataTableCell
                initials={getInitials(contact)}
                avatar={contact?.avatar?.url}
                primaryText={getFullName(contact)}
              />
            </div>
          ) : (
            <div className="d-flex">
              <TextInput
                className="mr-1"
                required
                label="First Name"
                value={formData.firstName}
                onChange={(value) => setFormData((prev) => ({ ...prev, firstName: value }))}
                name="kt-first-name"
                type="text"
                // @ts-ignore
                inputMode="text"
                placeholder="First Name"
                errorText="First name is required"
              />
              <TextInput
                className="ml-1"
                required
                label="Last Name"
                value={formData.lastName}
                onChange={(value) => setFormData((prev) => ({ ...prev, lastName: value }))}
                name="kt-last-name"
                type="text"
                // @ts-ignore
                inputMode="text"
                placeholder="Last Name"
                errorText="Last name is required"
              />
            </div>
          )}
          <TextInput
            required
            label="Routing Number"
            value={formData.routingNumber}
            onChange={(value) => setFormData((prev) => ({ ...prev, routingNumber: value }))}
            name="kt-routing-number"
            type="number"
            // @ts-ignore
            inputMode="numeric"
            placeholder="9 digit routing number"
            errorText="Routing number must be 9 digits"
            isInvalid={!US_ROUTING_NUMBER_REGEX.test(formData.routingNumber)}
          />
          <TextInput
            required
            label="Confirm Routing Number"
            value={formData.confirmRoutingNumber}
            onChange={(value) => setFormData((prev) => ({ ...prev, confirmRoutingNumber: value }))}
            name="kt-confirm-routing-number"
            type="number"
            // @ts-ignore
            inputMode="numeric"
            placeholder="Confirm 9 digit routing number"
            isInvalid={formData.routingNumber !== formData.confirmRoutingNumber}
          />
          <div className="mb-4">
            <TextInput
              required
              label="Account Number"
              value={formData.accountNumber}
              onChange={(value) => setFormData((prev) => ({ ...prev, accountNumber: value }))}
              name="kt-account-number"
              className="mb-0"
              type="number"
              // @ts-ignore
              inputMode="numeric"
              placeholder="Account number"
            />
            <small>This is your checking information. We can't accept prepaid cards or debit cards here.</small>
          </div>
          <TextInput
            required
            label="Confirm Account Number"
            value={formData.confirmAccountNumber}
            onChange={(value) => setFormData((prev) => ({ ...prev, confirmAccountNumber: value }))}
            name="kt-confirm-account-number"
            type="number"
            // @ts-ignore
            inputMode="numeric"
            placeholder="Confirm your account number"
            isInvalid={formData.accountNumber !== formData.confirmAccountNumber}
          />
          <Checkbox
            value={formData.accountType === PaymentMethodType.SAVINGS_ACCOUNT}
            label="This is a savings account"
            onChange={(checked) =>
              setFormData((prev) => ({
                ...prev,
                accountType: checked ? PaymentMethodType.SAVINGS_ACCOUNT : PaymentMethodType.CHECKING_ACCOUNT,
              }))
            }
            className="mb-4"
          />
          <Checkbox
            value={isDefault}
            onChange={setIsDefault}
            label={t('billing:payment-methods.mark-as-default-checkbox-label')}
            className="mb-4"
          />
          <Checkbox
            value={isAutoPay}
            onChange={setIsAutoPay}
            label={t('billing:payment-methods.auto-pay-checkbox-label')}
            className="mb-4"
          />
        </div>
      ) : (
        <div>
          <AddressInput required address={address} onChange={setAddress} showHelperText={false} />
        </div>
      )}
    </SideModalDrawer>
  );
};

export default AddBankAccountModal;
