import moment from 'moment';

export const UPDATE_ENROLLMENT_TABLE_WEEK = 'UPDATE_ENROLLMENT_TABLE_WEEK';
export const UPDATE_VISIBLE_CENTER_FOR_ENROLLMENT = 'UPDATE_VISIBLE_CENTER_FOR_ENROLLMENT';
export const UPDATE_ENROLLMENT_DASHBOARD_CLASSES_DATA = 'UPDATE_ENROLLMENT_DASHBOARD_CLASSES_DATA';
export const UPDATE_VISIBLE_CLASS_FOR_ENROLLMENT = 'UPDATE_VISIBLE_CLASS_FOR_ENROLLMENT';
export const UPDATE_INCLUDE_FUTURE_CONTRACTS = 'UPDATE_INCLUDE_FUTURE_CONTRACTS';
export const UPDATE_INCLUDE_LTB_OFFERS = 'UPDATE_INCLUDE_LTB_OFFERS';

interface IUpdateEnrollmentTableWeekAction {
  type: typeof UPDATE_ENROLLMENT_TABLE_WEEK;
  startOfWeek: moment.Moment;
  endOfWeek: moment.Moment;
}

interface IUpdateVisibleCenterForEnrollment {
  type: typeof UPDATE_VISIBLE_CENTER_FOR_ENROLLMENT;
  centerId: string;
  businessId?: string; // optional for internal users
}

interface IUpdateEnrollmentDashboardClassesData {
  type: typeof UPDATE_ENROLLMENT_DASHBOARD_CLASSES_DATA;
  classes: IClass[];
}

interface IUpdateVisibleClassForEnrollment {
  type: typeof UPDATE_VISIBLE_CLASS_FOR_ENROLLMENT;
  _class: IClass;
}

interface IUpdateIncludeFutureContracts {
  type: typeof UPDATE_INCLUDE_FUTURE_CONTRACTS;
  includeFutureContracts: boolean;
}

interface IUpdateIncludeLtbOffers {
  type: typeof UPDATE_INCLUDE_LTB_OFFERS;
  includeLtbOffers: boolean;
}

export type EnrollmentActionTypes =
  | IUpdateEnrollmentTableWeekAction
  | IUpdateVisibleCenterForEnrollment
  | IUpdateEnrollmentDashboardClassesData
  | IUpdateVisibleClassForEnrollment
  | IUpdateIncludeFutureContracts
  | IUpdateIncludeLtbOffers;
