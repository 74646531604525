import { useState, useCallback } from 'react';
import { useGetAgencyOptions } from 'shared/Agency/hooks/useGetAgencyOptions';
import { TABLE_DEFAULTS } from 'shared/components/DataTable';
import { useGetActiveCenterOptions } from 'shared/hooks/useGetActiveCenters';
import { ISearchCriteria } from './query';

const AGENCY_PAYMENT_STATUS_FILTER_OPTIONS = [
  { label: 'Complete', value: 'COMPLETE' },
  { label: 'Incomplete', value: 'INCOMPLETE' },
];

interface AgencyTableFilters {
  agencyOptions: ISelectMenuItem[];
  centerOptions: ISelectMenuItem[];
  /**
   * Clears the current search critieria
   * @returns
   */
  handleClearSearchCritieria: () => void;
  handleChangeSearchCriteria: (criteria: string, value: any) => void;
  handlePageChange: (pageNumber: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  searchCriteria: ISearchCriteria;
  statusOptions: ISelectMenuItem[];
}
export function useAgencyTableFilters(): AgencyTableFilters {
  // Data Sources
  const { agencyOptions } = useGetAgencyOptions(true);

  const { centerOptions } = useGetActiveCenterOptions();

  const statusOptions = AGENCY_PAYMENT_STATUS_FILTER_OPTIONS;

  // State
  const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>(DefaultSearchCritieria());

  //Handlers
  const handleChangeSearchCriteria = useCallback((attribute: string, value: any) => {
    setSearchCriteria((prev) => ({ ...prev, [attribute]: value, pageNumber: TABLE_DEFAULTS.PAGE }));
  }, []);

  const handlePageChange = useCallback((pageNumber) => {
    setSearchCriteria((prev) => ({ ...prev, pageNumber }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setSearchCriteria((prev) => ({ ...prev, pageSize, pageNumber: TABLE_DEFAULTS.PAGE }));
  }, []);

  const handleClearSearchCritieria = useCallback(() => setSearchCriteria(DefaultSearchCritieria()), []);

  return {
    agencyOptions,
    centerOptions,
    handleClearSearchCritieria,
    handleChangeSearchCriteria,
    handlePageChange,
    handlePageSizeChange,
    searchCriteria,
    statusOptions,
  };
}

function DefaultSearchCritieria() {
  return {
    agencyIds: [],
    centerIds: [],
    searchTerm: '',
    startDate: '',
    endDate: '',
    status: [],
    pageSize: TABLE_DEFAULTS.PAGE_SIZE,
    pageNumber: TABLE_DEFAULTS.PAGE,
  };
}
