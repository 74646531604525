import React from 'react';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import moment from 'moment';
import Button from 'shared/components/Buttons';
import { Row, Col } from 'shared/components/Layout';
import WeekViewTimeOffEvent from './WeekViewTimeOffEvent';
import { sortBy } from 'lodash';

interface IProps {
  date: string;
  updateDate: (date: string) => void;
  timeOffRequests: ITimeOff[];
  setSelectedTimeOff: (t: ITimeOff) => void;
}

const WeekView: React.FC<IProps> = ({ date, updateDate, timeOffRequests, setSelectedTimeOff }) => {
  const isDaySaturday = moment(date).get('day') === 6;
  if (isDaySaturday) {
    date = moment(date).add(1, 'd').toISOString();
  }
  const startOfWeek = moment(date).day(0);
  const endOfWeek = moment(date).day(6);
  const currentWeekTimeOffRequests = sortBy(
    timeOffRequests.filter(
      (t) =>
        moment(t.startTime).isBetween(startOfWeek, endOfWeek, 'days', '[]') ||
        moment(t.endTime).isBetween(startOfWeek, endOfWeek, 'days', '[]') ||
        (moment(t.startTime).isSameOrBefore(startOfWeek) && moment(t.endTime).isSameOrAfter(endOfWeek))
    ),
    'startTime'
  );
  return (
    <div className="bg-white rounded p-2 box-shadow-lg h-100 d-flex">
      <Col>
        <Row align="start" justify="center" className="mb-4">
          <Button
            variant="outline-light"
            className="px-4 text-text-color today-button mr-4 mb-2"
            onClick={() => updateDate(moment().toISOString())}
          >
            {' '}
            Today
          </Button>
          <WeekPicker
            className="flex-grow-0 mb-2"
            displayFormat="MMM D"
            startDate={moment(date).startOf('week')}
            endDate={moment(date).endOf('week')}
            rangeComponent={<span className="mx-1">-</span>}
            onChange={(dates) => updateDate(dates.startDate.toISOString())}
            reactDatesController="RANGE"
          />
        </Row>
        {currentWeekTimeOffRequests.map((t, i) => (
          <div className="cursor-pointer" key={i} onClick={() => setSelectedTimeOff(t)}>
            <WeekViewTimeOffEvent timeOff={t} />
          </div>
        ))}
        {currentWeekTimeOffRequests.length < 1 && (
          <div className="text-center">No time off requests for this week.</div>
        )}
      </Col>
    </div>
  );
};

export default WeekView;
