import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import {
  providerContactFields,
  providerNameFields,
  providerAddressFields,
  providerFinancialDetailsFields,
} from './fields';

export const GET_PROVIDER_CONTACT = gql`
  query($providerId: ID!, $businessId: ID!) {
    getProviderContact(providerId: $providerId, businessId: $businessId) {
      ${providerContactFields}
    }
  }
`;

export const GET_PROVIDER_NAMES = gql`
  query($providerId: ID!, $businessId: ID!) {
    getProviderNames(providerId: $providerId, businessId: $businessId) {
      ${providerNameFields}
    }
  }
`;

export const GET_PROVIDER_FINANCIAL_DETAILS = gql`
  query($providerId: ID!) {
    getProviderFinancialDetails(providerId: $providerId) {
      ${providerFinancialDetailsFields}
    }
  }
`;

export const GET_PROVIDER_ADDRESSES = gql`
  query($providerId: ID!, $businessId: ID!) {
    getProviderAddresses(providerId: $providerId, businessId: $businessId) {
      ${providerAddressFields}
    }
  }
`;

export const useGetProviderContact = (options?: QueryHookOptions<IGetProviderContact, IGetProviderContactVariables>) =>
  useQuery(GET_PROVIDER_CONTACT, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetProviderNames = (options?: QueryHookOptions<IGetProviderNames, IGetProviderNameVariable>) =>
  useQuery(GET_PROVIDER_NAMES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetProviderAddresses = (
  options?: QueryHookOptions<IGetProviderAddresses, IGetProviderAddressesVariables>
) =>
  useQuery(GET_PROVIDER_ADDRESSES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetProviderFinancialDetails = (
  options?: QueryHookOptions<IGetProviderFinancialDetails, IGetProviderFinancialDetailsVariable>
) =>
  useQuery(GET_PROVIDER_FINANCIAL_DETAILS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
