import { ICustomField } from 'shared/types/customField';

export const CREATE_PROVIDER = 'CREATE_PROVIDER';
export const GET_PERSONNEL = 'GET_PERSONNEL';
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD';
export const GET_CUSTOM_FIELDS = 'GET_CUSTOM_FIELDS';
export const UPDATE_CUSTOM_FIELD = 'UPDATE_CUSTOM _FIELD';

interface ICreateProviderAction {
  type: typeof CREATE_PROVIDER;
  provider: IProvider;
}

interface IGetPersonnelAction {
  type: typeof GET_PERSONNEL;
  personnel: ICcssPersonnel[];
}

interface IGetProvidersAction {
  type: typeof GET_PROVIDERS;
  providers: IProvider[];
}

interface IUpdateProviderAction {
  type: typeof UPDATE_PROVIDER;
  provider: IProvider;
}

interface ICreateCustomFieldAction {
  type: typeof CREATE_CUSTOM_FIELD;
  customField: ICustomField;
}

interface IGetCustomFieldsAction {
  type: typeof GET_CUSTOM_FIELDS;
  providers: ICustomField[];
}

interface IUpdateCustomFieldAction {
  type: typeof UPDATE_CUSTOM_FIELD;
  provider: ICustomField;
}

export type PersonnelActionTypes = IGetPersonnelAction;

export type ProvidersActionTypes = ICreateProviderAction | IUpdateProviderAction | IGetProvidersAction;

export type CustomFieldActionTypes = ICreateCustomFieldAction | IUpdateCustomFieldAction | IGetCustomFieldsAction;
