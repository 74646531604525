import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { Col, Row } from 'shared/components/Layout';
import { Page } from 'shared/types/enrollment-form';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import colors from '_colors.module.scss';
import { showConfirm } from 'shared/components/ConfirmationContainer';
import { pageDecorator } from '../utils';
import { faArrowsUpDownLeftRight } from 'shared/constants/customIcons';
import { PageEx } from './manage-page-modal';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

const MANDATORY_PAGES: string[] = ['child', 'contact'];

interface IProps {
  page: PageEx;
  updatePage: (page: Page, idx: any) => void;
  onRemovePage: (pageIdx: number) => Promise<void>;
  error?: string;
  sectionTypeOptions: {
    id: string;
    label: string;
    value: string;
    isDisabled?: boolean | undefined;
    namespace?: string | undefined;
  }[];
  draggableIndex: number;
}

export default ({ page, updatePage, onRemovePage, sectionTypeOptions, error, draggableIndex }: IProps) => {
  const { t } = useTranslation(['translation', 'enrollment', 'business']);

  return (
    <Draggable draggableId={page.label} index={draggableIndex} key={page.label}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={classNames({
            'p-1': true,
            'is-dragging': snapshot.isDragging,
          })}
        >
          <Row>
            <Col md={5} className="d-flex">
              <div {...provided.dragHandleProps}>
                <FontAwesomeIcon
                  size="1x"
                  icon={faArrowsUpDownLeftRight}
                  color={colors['dark-gray']}
                  className="mt-3 mr-3"
                  title={t('enrollment:form.drag-n-drop-page')}
                />
              </div>
              <Select
                disabled={page.immutable}
                options={sectionTypeOptions}
                value={sectionTypeOptions.find((o) => o.id === page.sectionType) ?? sectionTypeOptions[0]}
                label={t('enrollment:form.page-type')}
                onChange={(o) => {
                  const _page = { ...page, sectionType: o.value } as Page;
                  pageDecorator(_page);
                  updatePage(_page, draggableIndex);
                }}
                className="mr-2"
              />
            </Col>
            <Col md={5}>
              <TextInput
                label={t('enrollment:form.page-title')}
                value={page.label}
                errorText={error}
                isInvalid={!!error}
                required
                autoFocus
                onChange={(value) => {
                  updatePage({ ...page, label: value }, draggableIndex);
                }}
                className="mr-2"
              />
            </Col>
            <Col>
              {!MANDATORY_PAGES.includes(page?.sectionType ?? '') && (
                <Button
                  variant="link"
                  onClick={() => {
                    showConfirm({
                      title: t('enrollment:form.remove-page-modal.title'),
                      message: t('enrollment:form.remove-page-modal.message'),
                      onConfirm: () => onRemovePage(draggableIndex),
                    });
                  }}
                >
                  <FontAwesomeIcon size="2x" icon={faTrashAlt} color={colors['dark-gray']} className="mt-3" />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      )}
    </Draggable>
  );
};
