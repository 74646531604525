import React from 'react';
import Card from 'shared/components/Card';
import Select from 'shared/components/Select';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import { ITimeframeShape } from '../../EmployeeTimeSheets';

interface IProps {
  timeframe: ITimeframeShape;
  centerSelectOptions: ICenter[];
  selectedCenterId: string | null;
  onCenterSelect: React.Dispatch<React.SetStateAction<ICenter | null>>;
  updateTimeframe: React.Dispatch<React.SetStateAction<ITimeframeShape>>;
}

const ControlHeader: React.FC<IProps> = ({
  timeframe,
  updateTimeframe,
  onCenterSelect,
  selectedCenterId,
  centerSelectOptions,
  ...props
}) => {
  return (
    <div>
      {Boolean(centerSelectOptions.length) && (
        <div className="d-flex align-items-center bg-baby-blue py-2 px-4 mx-n8 mt-n8 mb-8 rounded flex-wrap">
          <div className="mr-4 mt-2 mb-2">You are currently viewing time sheets for: </div>
          <div className="mt-2 mb-2">
            <Select
              className="mb-0 max-width-200"
              // only the show the 'all' option to people that belong to multiple centers and default to the single center if they only belong to one
              value={
                centerSelectOptions.length && centerSelectOptions.length === 1
                  ? centerSelectOptions[0]
                  : centerSelectOptions.find((center) => center.id === selectedCenterId) ?? { id: null }
              }
              options={
                centerSelectOptions.length && centerSelectOptions.length > 1
                  ? [{ id: null }, ...centerSelectOptions]
                  : centerSelectOptions
              }
              getOptionLabel={(option: ICenter) => {
                if (!option.id) {
                  return 'All';
                }

                return option.name;
              }}
              getOptionValue={(option: ICenter) => option.id}
              onChange={(option) => onCenterSelect(option.id ? option : null)}
            />
          </div>
        </div>
      )}
      <Card bodyClassName="px-4 py-1">
        <div className="d-flex flex-row align-items-center flex-wrap">
          <div className="d-flex flex-grow-1 justify-content-center flex-wrap">
            <WeekPicker
              className="mb-0 flex-grow-0"
              onChange={(dates) =>
                updateTimeframe((prev) => ({
                  ...prev,
                  start: dates.startDate,
                  end: dates.endDate,
                }))
              }
              onChangeWithNulls={(dates) =>
                updateTimeframe((prev) => ({
                  ...prev,
                  start: dates.startDate,
                  end: dates.endDate,
                }))
              }
              reactDatesController="RANGE"
              rangeType={timeframe.viewType === 'CUSTOM' ? 'CUSTOM' : 'WEEK'}
              startDate={timeframe.start}
              endDate={timeframe.end}
            />
            <Select
              className="mb-0 flex-grow-0 ml-2"
              value={timeframe.viewType}
              options={[
                { label: 'Week', value: 'WEEK' },
                { label: 'Custom', value: 'CUSTOM' },
              ]}
              onChange={(option) =>
                updateTimeframe((prev) => ({
                  ...prev,
                  viewType: option.value,
                }))
              }
            />
          </div>
          {/* <div className='d-flex flex-grow-1'> */}
          {/* placeholder for now. designs show a more actions here but that will come at a later time */}
          {/* &nbsp; */}
          {/* </div> */}
        </div>
      </Card>
    </div>
  );
};

export default ControlHeader;
