export const GET_BUSINESSES_SUCCESS = 'GET_BUSINESSES_SUCCESS';
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS';
export const CREATE_BUSINESS = 'CREATE_BUSINESS';
export const UPDATE_BUSINESS = 'UPDATE_BUSINESS';
export const CREATE_PAYMENT_METHOD_FOR_BUSINESS = 'CREATE_PAYMENT_METHOD_FOR_BUSINESS';
export const UPDATE_PAYMENT_METHOD_FOR_BUSINESS = 'UPDATE_PAYMENT_METHOD_FOR_BUSINESS';
export const UPDATE_DEFAULT_PAYMENT_METHOD_FOR_BUSINESS = 'UPDATE_DEFAULT_PAYMENT_METHOD_FOR_BUSINESS';
export const DELETE_PAYMENT_METHOD_FOR_BUSINESS = 'DELETE_PAYMENT_METHOD_FOR_BUSINESS';
export const DESTROY_BUSINESS = 'DESTROY_BUSINESS';

interface IGetBusinessesSuccessAction {
  type: typeof GET_BUSINESSES_SUCCESS;
  businesses: IBusiness[];
}

interface IGetBusinessSuccessAction {
  type: typeof GET_BUSINESS_SUCCESS;
  business: IBusiness;
}

interface ICreateBusinessAction {
  type: typeof CREATE_BUSINESS;
  business: IBusiness;
}

interface IUpdateBusinessAction {
  type: typeof UPDATE_BUSINESS;
  business: IBusiness;
}

interface ICreatePaymentMethodForBusinessAction {
  type: typeof CREATE_PAYMENT_METHOD_FOR_BUSINESS;
  businessId: string;
  paymentMethod: IPaymentMethod;
}

interface IUpdatePaymentMethodForBusinessAction {
  type: typeof UPDATE_PAYMENT_METHOD_FOR_BUSINESS;
  businessId: string;
  paymentMethod: IPaymentMethod;
}

interface IUpdateDefaultPaymentMethodForBusinessAction {
  type: typeof UPDATE_DEFAULT_PAYMENT_METHOD_FOR_BUSINESS;
  businessId: string;
  paymentMethodId: string;
}

interface IDeletePaymentMethodForBusinessAction {
  type: typeof DELETE_PAYMENT_METHOD_FOR_BUSINESS;
  businessId: string;
  paymentMethodId: string;
}

interface IDestroyBusinessAction {
  type: typeof DESTROY_BUSINESS;
  id: string;
}

export type BusinessesActionTypes =
  | IGetBusinessesSuccessAction
  | IGetBusinessSuccessAction
  | ICreateBusinessAction
  | IUpdateBusinessAction
  | ICreatePaymentMethodForBusinessAction
  | IUpdatePaymentMethodForBusinessAction
  | IUpdateDefaultPaymentMethodForBusinessAction
  | IDeletePaymentMethodForBusinessAction
  | IDestroyBusinessAction;
