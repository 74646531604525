// @ts-nocheck
import React, { useState } from 'react';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RightModalDrawer from 'shared/components/MUI/RightModalDrawer';
import { useCreatePosition } from '../graphQL/mutations';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { isEmpty, isNil, capitalize } from 'lodash';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

interface IProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

const AddPositionModal: React.FC<IProps> = ({ open, onClose, refetch }) => {
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const { t } = useTranslation(['translation', 'settings']);

  const [name, setName] = useState('');

  const [createPositionFn] = useCreatePosition({
    onCompleted: () => {
      showToast('Position created successfully', 'success');
      closeModal();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onSave = () => {
    createPositionFn({
      variables: {
        input: {
          businessId: businessId,
          name: name,
        },
      },
    });
  };

  const closeModal = () => {
    setName('');
    onClose();
    refetch();
  };

  return (
    <RightModalDrawer open={open} onClose={closeModal} title="Add Position">
      <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '16px' }}>
        <Mui.Stack direction="column" spacing={2} marginLeft="16px">
          <Mui.FormControlLabel
            label={
              <span>
                Name
                <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
              </span>
            }
            labelPlacement="top"
            control={
              <Mui.TextField
                required
                fullWidth
                size="small"
                value={name}
                onChange={(evt) => setName(evt.target.value)}
                sx={styles.inputStyles}
              />
            }
            componentsProps={{
              typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
            }}
            style={{ margin: 0 }}
          />
        </Mui.Stack>
      </Mui.DialogContent>
      <Mui.Box style={{ margin: '8px' }}>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => closeModal()}>
            Cancel
          </Mui.Button>
          <Mui.Button
            disabled={_.isEmpty(name)}
            color="secondary"
            variant="contained"
            onClick={() => onSave()}
            id="modal-drawer-primary-btn"
          >
            Save
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Box>
    </RightModalDrawer>
  );
};

export default AddPositionModal;
