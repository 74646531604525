import React from 'react';
//utils
import moment from 'moment';
import _ from 'lodash';
//components
import * as Mui from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isRegion } from 'shared/util/region';

type DatePickerView = 'year' | 'day' | 'month';
type RegionForDate = 'AU' | 'US';
type StartOrEnd = 'start' | 'end';
interface IProps {
  label: string;
  setTaken: React.Dispatch<string>;
  shouldDisableDates: (date: string, region: RegionForDate, startOrEnd: StartOrEnd) => boolean;
  shouldDisableDatesValue: StartOrEnd;
  modifiedTakenValue: string | moment.Moment;
  takenDate: string | null;
  views: DatePickerView[];
}

export const GalleryDatePicker: React.FC<IProps> = ({
  label,
  takenDate,
  views,
  setTaken,
  shouldDisableDates,
  shouldDisableDatesValue,
  modifiedTakenValue,
}) => {
  return isRegion('AU') ? (
    <DatePicker
      label={label}
      views={views}
      // @ts-ignore
      value={modifiedTakenValue}
      onChange={(newValue) => {
        setTaken(moment(newValue)?.format('DD/MM/YYYY'));
      }}
      shouldDisableDate={(date: string) => !_.isNil(date) && shouldDisableDates(date, 'AU', shouldDisableDatesValue)}
      mask="__/__/____"
      inputFormat="dd/MM/yyyy"
      InputAdornmentProps={{ position: 'start' }}
      renderInput={(params: Mui.TextFieldProps) => <Mui.TextField size="small" {...params} />}
    />
  ) : (
    <DatePicker
      label={label}
      views={views}
      value={takenDate}
      onChange={(v) => setTaken(v as string)}
      shouldDisableDate={(date: string) => !_.isNil(date) && shouldDisableDates(date, 'US', shouldDisableDatesValue)}
      InputAdornmentProps={{ position: 'start' }}
      renderInput={(params: Mui.TextFieldProps) => <Mui.TextField size="small" {...params} />}
    />
  );
};
