import { ApolloError } from '@apollo/client';

// This string typeof check is needed because,
// for some reason, it's possible to break the ApolloError
// class's contract and have err.message be filled with objects.
const getErrorMessage = (err: any) => (typeof err.message === 'string' ? err.message : err.message?.message ?? '');

/**
 * Use this function to extract the string to display to the user
 * from an ApolloError object. It also uses the network error results
 * when the graphqlErrors are empty due to this issue https://github.com/apollographql/@apollo/client/issues/2810
 *
 * Note: This is not standardized so may not work in all cases.
 *
 * @param error The error you want the message from
 * @returns error message as a string you can display to the user.
 */
export default function getApolloErrorMessage(error: ApolloError) {
  const errorArray =
    error.graphQLErrors.length > 0
      ? error.graphQLErrors
      : // @ts-ignore
        error.networkError?.result?.errors;

  return errorArray?.map((err: any) => getErrorMessage(err))?.join(', ');
}
