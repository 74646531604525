import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'shared/components/Layout';
import Card from 'shared/components/Card';
import { CreateButton } from 'shared/components/Buttons';
import { RootState } from 'store/reducers';
import CreateCustomFieldsModal from './CreateCustomFieldsModal';
import CustomDataFieldsTable from './CustomDataFieldsTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetCustomFields } from '../../graphql/queries';
import UpdateCustomFieldModal from './UpdateCustomFieldsModal';
import { useArchiveCustomField } from 'gql/customField/mutation';
import { showToast } from 'shared/components/Toast';
import ArchiveCustomFieldModal from './ArchiveCustomFieldModal';
import '../../businessProfile.scss';
import { Area, ICustomField, ICustomFieldSort, Type } from 'shared/types/customField';
import CardHeader from 'shared/components/Card/CardHeaderWithArchivedToggle';
import { useSearchCenters } from 'gql/center/queries';
import { SEARCH_MY_CENTERS } from 'pages/Centers/subroutes/Profiles/graphql/fields';
import { getCentersSuccess } from 'pages/Centers/duck/actions';
import { PREDICATES, SEARCH_EXPRESSIONS } from 'shared/constants/elastic';

interface IProps {
  entityId: string;
}

const { TERM } = SEARCH_EXPRESSIONS;
const { ACTIVE } = PREDICATES;

const CustomDataFieldsTab: React.FC<IProps> = ({ entityId }) => {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [activeCustomField, setActiveCustomField] = useState<ICustomField | null>(null);
  const dispatch = useDispatch();

  // load the centers for the grid on expanded row
  const _ = useSearchCenters(
    {
      variables: {
        input: {
          filter: { [TERM]: { field: 'active', predicate: ACTIVE } },
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          size: 10000,
          from: 0,
        },
      },
      onCompleted: (data) => {
        dispatch(getCentersSuccess(data.searchCenters.data ?? []));
      },
    },
    SEARCH_MY_CENTERS
  );

  const dismissEditCustomFieldModal = useCallback(() => {
    setShowEditModal(false);
    setActiveCustomField(null);
  }, []);
  const dismissArchiveCustomFieldModal = useCallback(() => {
    setShowArchiveModal(false);
    setActiveCustomField(null);
  }, []);
  const dismissCreateCustomFieldModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);

  const [tableState, tableFunctions] = useDatatableState();
  const [searchText, setSearchText] = useState<undefined | string>();

  const { businessId } = useSelector((state: RootState) => state.context);

  const [sort, setSort] = useState<ICustomFieldSort[]>([{ field: 'area', direction: 'ASCENDING' }]);
  const onSort = (field: string, direction: ElasticsearchSortDirection) => setSort([{ field, direction }]);

  const [filteredArea, setFilteredArea] = useState<Area>(null);
  const [filteredType, setFilteredType] = useState<Type>(null);
  const [filteredCenter, setFilteredCenter] = useState<string[]>([]);
  const [showArchived, setShowArchived] = useState(false);

  const input = {
    businessId: businessId === null ? '' : businessId,
    allowedCenterIds: filteredCenter.length === 0 ? null : filteredCenter,
    searchKey: searchText === '' ? undefined : searchText,
    pageNumber: tableState.activePage,
    pageSize: tableState.pageSize,
    sortDirection: sort[0].direction === 'ASCENDING' ? 'asc' : 'desc',
    sortBy: sort[0].field,
    area: filteredArea ?? filteredArea,
    type: filteredType ?? filteredType,
    isArchived: showArchived,
  };

  const { data, loading, refetch } = useGetCustomFields({
    variables: {
      input: {
        ...input,
      },
    },
  });
  const [archiveFn, { loading: archiveLoading }] = useArchiveCustomField({
    onCompleted: () => {
      refetch();
      showToast(t('custom-data-fields.archive-custom-data-field-success'), 'success');
      setShowArchiveModal(false);
    },
    onError: () => {
      showToast(t('general.error'), 'error');
    },
  });

  const onArchive = useCallback(() => {
    if (activeCustomField) {
      archiveFn({
        variables: {
          businessId: businessId ?? '',
          id: activeCustomField?.id ?? '',
        },
      });
    }
  }, [activeCustomField]);

  const handleFilteredArea = useCallback(
    (value: Area) => {
      setFilteredArea(value);
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [tableFunctions, tableState.pageSize]
  );

  const handleFilteredType = (value: Type) => {
    setFilteredType(value);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  const handleFilteredCenter = (value: string[]) => {
    setFilteredCenter(value);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  const handleActionEdit = (customField: ICustomField) => {
    setActiveCustomField(customField);
    setShowEditModal(true);
  };

  const handleActionArchive = (customField: ICustomField) => {
    setActiveCustomField(customField);
    setShowArchiveModal(true);
  };

  const handleRowClick = () => {};

  return (
    <>
      <Row className="mb-4">
        <Col>
          <CreateButton
            className="float-right kt-account-ccs-tab add-button"
            variant="primary"
            onClick={() => setShowCreateModal(true)}
          >
            Add
          </CreateButton>
        </Col>
      </Row>

      <Row align="start">
        <Col>
          <Card
            header={
              <CardHeader
                title={t('custom-data-fields.tab-title')}
                isArchived={showArchived}
                onArchivedToggleChange={setShowArchived}
              />
            }
          >
            <CustomDataFieldsTable
              loading={loading}
              showLoadingOverlay={false}
              data={data?.getPaginatedCustomFieldsForBusiness?.data || []}
              tableState={tableState}
              tableFunctions={tableFunctions}
              dataSize={data?.getPaginatedCustomFieldsForBusiness?.totalRecords ?? 0}
              pageSize={tableState.pageSize}
              activePage={tableState.activePage}
              onPageChange={tableFunctions.changePage}
              onSizePerPageChange={tableFunctions.changeSizePerPage}
              onSort={onSort}
              onSearch={setSearchText}
              filteredArea={filteredArea}
              filteredType={filteredType}
              filteredCenter={filteredCenter}
              onAreaFilter={handleFilteredArea}
              onTypeFilter={handleFilteredType}
              onCenterFilter={handleFilteredCenter}
              handleActionEdit={handleActionEdit}
              handleActionArchive={handleActionArchive}
              handleRowClick={handleRowClick}
              showArchived={showArchived}
            />
          </Card>
        </Col>
      </Row>

      <CreateCustomFieldsModal
        isOpen={showCreateModal}
        onClose={dismissCreateCustomFieldModal}
        businessId={entityId}
        refetch={() => refetch()}
      />

      <ArchiveCustomFieldModal
        isOpen={showArchiveModal}
        isLoading={archiveLoading}
        onArchive={onArchive}
        onClose={dismissArchiveCustomFieldModal}
      />

      <UpdateCustomFieldModal
        isOpen={showEditModal}
        onClose={dismissEditCustomFieldModal}
        refetch={() => refetch()}
        businessId={entityId}
        activeCustomField={activeCustomField}
      ></UpdateCustomFieldModal>
    </>
  );
};

export default React.memo(CustomDataFieldsTab);
