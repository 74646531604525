import React, { useCallback, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Fade from 'react-bootstrap/Fade';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import Alert from 'react-bootstrap/Alert';
import KTAlert from 'shared/components/Alert';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import User from 'shared/types/user';
import { isBlank } from 'shared/util/string';
import { isEmailValid } from 'shared/util/email';
import { sortBy, debounce } from 'lodash';
import { useLazyGetStaffByEmail } from 'shared/hooks/useGetStaffByEmail';
import { useGetPositionsForBusiness } from '../../graphql/queries';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

export interface IExistingEmployeeForm {
  email: string;
  personId: string | null;
  centerId: string | null;
  positionId: string;
}

interface IProps {
  currentUser: User;
  formData: IExistingEmployeeForm;
  onChange: React.Dispatch<React.SetStateAction<IExistingEmployeeForm>>;
  startNewStaffFormWithEmail: (email: string) => void;
}

const ExistingEmployeeForm: React.FC<IProps> = ({
  currentUser,
  formData,
  onChange,
  startNewStaffFormWithEmail,
  ...props
}) => {
  const [getStaffByEmailFn, { loading, data, error, called }] = useLazyGetStaffByEmail(formData.email);
  const { data: positionsForBusiness, loading: loadingPositionsForBusiness } = useGetPositionsForBusiness(
    data?.getStaffByEmail?.entityId ?? ''
  );
  const centerSelectOptions: ICenter[] = useGetActiveCenters();
  const validEmailString = useCallback((email: string): boolean => !isBlank(email) && isEmailValid(email), []);
  const canAddUserToCenter = useMemo(
    (): boolean => Boolean(data?.getStaffByEmail && data.getStaffByEmail.roleship) ?? false,
    [data]
  );

  const debouncedEmailSearch = useCallback(
    debounce((value: string) => {
      if (validEmailString(value)) {
        getStaffByEmailFn({
          variables: {
            email: value,
          },
        });
      }
    }, 250),
    [getStaffByEmailFn]
  );

  const handleEmailInput = useCallback(
    (value: string) => {
      // bubble up
      onChange((prev) => ({ ...prev, email: value }));

      // debounce query search
      debouncedEmailSearch(value);
    },
    [onChange, debouncedEmailSearch]
  );

  const determineCenterSelectOptions = useCallback(() => {
    if (canAddUserToCenter) {
      // @ts-ignore
      const centersPersonAlreadyBelongsTo: string[] = data.getStaffByEmail.roleship.scopes.map(
        (scope: any) => scope.id
      );
      const centers: ICenter[] = centerSelectOptions.filter(
        (center: ICenter) => !centersPersonAlreadyBelongsTo.includes(center.id)
      );

      return sortBy(centers, (center) => center.name);
    }

    return [];
  }, [centerSelectOptions, data, canAddUserToCenter]);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <Form>
      <Row>
        <Col>
          <TextInput
            required
            name="existing-staff-em"
            label="Email"
            value={formData.email}
            onChange={handleEmailInput}
          />
        </Col>
      </Row>
      {loading && <LoadingLines number={2} />}
      {validEmailString(formData.email) &&
        ((data?.getStaffByEmail === undefined && error) ||
          (data?.getStaffByEmail && !data.getStaffByEmail.roleship)) && (
          <KTAlert variant="danger">This email doesn’t belong to your business and cannot be added.</KTAlert>
        )}
      {called && !loading && validEmailString(formData.email) && data?.getStaffByEmail === null && !error && (
        <KTAlert variant="info" className={'d-flex flex-column'}>
          <p>
            We couldn't find this email in Kangarootime. Would you like to{' '}
            <Alert.Link onClick={() => startNewStaffFormWithEmail(formData.email)} className={'inline-block'}>
              add them
            </Alert.Link>{' '}
            instead?
          </p>
        </KTAlert>
      )}
      <Fade in={canAddUserToCenter}>
        <div>
          <Row>
            <Col>
              <Select
                required
                id="position-dropdown-input"
                label="Position"
                helpTooltipText="Title or scope of responsibilities."
                options={sortBy(positionsForBusiness?.getPositionsByBusinessId ?? [], (p) => p.name)}
                isLoading={loadingPositionsForBusiness}
                onChange={(option: IPosition) => onChange((prev) => ({ ...prev, positionId: option.id }))}
                getOptionLabel={(option: IPosition) => option.name}
                getOptionValue={(option: IPosition) => option.id}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                required
                label={fieldLabels.center}
                aria-label={`Select employee\'s ${fieldLabels.center.toLowerCase()}`}
                placeholder={fieldLabels.center}
                value={formData.centerId}
                options={determineCenterSelectOptions()}
                getOptionLabel={(option: ICenter) => option.name}
                getOptionValue={(option: ICenter) => option.id}
                onChange={(option: ICenter) =>
                  onChange((prev) => ({
                    ...prev,
                    centerId: option.id,
                    personId: data?.getStaffByEmail.id ?? null,
                  }))
                }
                isLoading={loading}
                isOptionSelected={(option: ICenter) => option.id === formData.centerId}
              />
            </Col>
          </Row>
        </div>
      </Fade>
    </Form>
  );
};

export default ExistingEmployeeForm;
