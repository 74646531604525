import React, { useCallback } from 'react';
import classnames from 'classnames';
import colors from '_colors.module.scss';

interface IProps {
  indicatorColor: string | null;
  name: string;
  timeframe: string;
  status: timeOffStatus;
  className?: string;
}

const TimeOffCell: React.FC<IProps> = ({ indicatorColor, name, timeframe, status, className = '', ...props }) => {
  const statusBorderColor = useCallback((status: timeOffStatus): string => {
    switch (status) {
      case 'Approved':
        return colors.success;
      case 'Denied':
        return colors.danger;
      case 'Pending':
        return colors.orange;
      default:
        return colors.gray;
    }
  }, []);

  return (
    <div
      title={`${name}: ${timeframe}`}
      className={classnames('p-2 kt-schedules-time-off-overview-cell', className)}
      style={{ borderColor: statusBorderColor(status) }}
    >
      <div className="d-flex flex-row align-items-center">
        {indicatorColor && <div className="circle-xxs rounded-circle" style={{ backgroundColor: indicatorColor }} />}
        <div className="ml-2 text-overflow-ellipsis kt-schedules-time-off-overview-cell-primary-text">{name}</div>
        <div className="ml-auto kt-schedules-time-off-overview-cell-secondary-text">{timeframe}</div>
      </div>
    </div>
  );
};

export default TimeOffCell;
