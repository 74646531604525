import * as types from './types';

export const authenticated = (payload) => ({
  type: types.AUTHENTICATED,
  payload,
});

export const notAuthenticated = ({ errorCode } = {}) => ({
  type: types.NOT_AUTHENTICATED,
  payload: { errorCode },
});

export const signupComplete = (payload) => ({
  type: types.SIGNUP_COMPLETE,
  payload,
});

export const logout = () => ({
  type: types.LOGOUT,
});

export const loginWithEmailAndPassword = ({ email, password }) => ({
  type: types.LOGIN_WITH_EMAIL_AND_PASSWORD,
  payload: { email, password },
});

export const loginWithSaml = (email) => ({
  type: types.LOGIN_WITH_SAML,
  payload: { email },
});

export const loginWithSocialProvider = (provider) => ({
  type: types.LOGIN_WITH_SOCIAL_PROVIDER,
  payload: { provider },
});

export const updateSessionToken = (token) => ({
  type: types.UPDATE_SESSION_TOKEN,
  payload: { token },
});
