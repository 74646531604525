import Tag from './Tag';
import TagsContainer from './TagsContainer';
import TagsTableCell from './TagsTableCell';
import RiskTag from './RiskTag';
import ColoredBackgroundTag from './ColoredBackgroundTag';
import PaymentStatusTag from './PaymentStatusTag';

export default Tag;

export { TagsContainer, TagsTableCell, RiskTag, ColoredBackgroundTag, PaymentStatusTag };
