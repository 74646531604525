import React, { useCallback, useState } from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import SideModalDrawer from 'shared/components/ModalDrawer';
import TextInput from 'shared/components/TextInput';
import Alert from 'shared/components/Alert';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import { useGetPaymentMethodsForAccount, useSearchAccounts } from 'gql/account/queries';
import { capitalize, isBlank, stringToHsl } from 'shared/util/string';
import { useCreateManualPayment } from 'gql/transaction/mutations';
import { showToast } from 'shared/components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { createTransaction } from 'pages/BillingTransactions/duck/action';
import PaymentMethod from 'shared/components/PaymentMethods/PaymentMethod';
import SelectOptionWithAvatar from 'shared/components/Select/SelectOptionWithAvatar';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DetailedRadioOption from '../DetailedRadioOption';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { ApolloError } from '@apollo/client';
import { isRegion } from 'shared/util/region';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { useGetTransactionTypes } from 'gql/transaction/queries';
import { RootState } from 'store/reducers';
import { LoadingLines } from 'shared/components/LoadingSkeletons';

interface IFormShape {
  accountId: string;
  paymentMethodId: string | null;
  nonDigitalPaymentType: NonDigitalPaymentType | null;
  date: string; // defaults to today
  amount: number | null;
  description: string;
}

interface IProps {
  accountId?: string | null;
  isOpen: boolean;
  /**
   * Is called whenever the modal wishes to close (i.e. cancel button, x button, or completed save)
   * @returns
   */
  onClose: () => void;
  /**
   * Is called when the modal successfully completes Adding a payment
   * @returns
   */
  onComplete?: () => void;
}

const AddManualPaymentModal: React.FC<IProps> = ({ isOpen, accountId = null, onClose, onComplete }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IFormShape>({
    accountId: accountId ?? '',
    paymentMethodId: '',
    nonDigitalPaymentType: null,
    date: moment().format(),
    amount: null,
    description: '',
  });
  const [selectedAccountCenterId, setSelectedAccountCenterId] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { t } = useTranslation(['translation', 'billing']);
  const { loading: searchAccountsLoading, data: searchAccountsData } = useSearchAccounts(
    {
      variables: {
        input: {
          from: 0,
          size: 10000,
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          filter: { all: [] },
        },
      },
      skip: !isOpen,
    },
    `id name center { id name }`
  );

  const { loading: getAccountPaymentMethodsLoading, data: getAccountPaymentMethodsData } =
    useGetPaymentMethodsForAccount(formData.accountId);

  const isAutoPay = getAccountPaymentMethodsData?.getAccountById.paymentMethods?.some((pm) => pm.isAutoPay);

  const [createManualPaymentFn, { loading: createManualPaymentLoading }] = useCreateManualPayment({
    onCompleted: (result) => {
      dispatch(createTransaction(result.createManualPayment));
      showToast('Payment submitted successfully.', 'success');
      handleClose();
      if (onComplete) onComplete();
    },
    onError: (err: unknown) => {
      let message;
      if (err instanceof ApolloError) {
        message = getApolloErrorMessage(err);
      } else if (err instanceof Error) {
        message = err.toString();
      } else {
        message = 'Something went wrong';
      }
      showToast(message, 'error');
    },
  });

  const handleClose = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      accountId: '',
      paymentMethodId: '',
      date: moment().format(),
      amount: null,
      description: '',
      nonDigitalPaymentType: null,
    }));
    onClose();
  }, [onClose]);

  const handleSubmit = () => {
    if (isAutoPay) {
      setIsConfirmationModalOpen(true);
    } else {
      submitManualPayment();
    }
  };

  //TODO: Once we attach Timezone to ICenter then use that (selectedAccount.Center.Timezone)
  const timezone =
    useSelector((state: RootState) => state.timezone.byCenterId[selectedAccountCenterId]) ??
    (moment.tz.guess() as Timezone);

  const submitManualPayment = useCallback(() => {
    createManualPaymentFn({
      variables: {
        input: {
          accountId: formData.accountId,
          paymentMethodId: formData.paymentMethodId,
          nonDigitalPaymentType: formData.nonDigitalPaymentType,
          amount: formData.amount as number,
          date: moment(formData.date as string)
            .tz(timezone)
            .format('MM/DD/YYYY'),
          description: formData.description ?? '',
        },
      },
    });
  }, [createManualPaymentFn, formData]);

  const validateForm = useCallback((data: IFormShape): boolean => {
    const validAmount = (data.amount !== null && data.amount > 0) ?? false;
    const hasPaymentMethodSelect = Boolean(data.paymentMethodId) || Boolean(data.nonDigitalPaymentType);
    return !isBlank(data.accountId) && hasPaymentMethodSelect && validAmount;
  }, []);

  const cashPaymentMethod = {
    label: capitalize(t('translation:spelling.cash')),
    value: 'CASH' as NonDigitalPaymentType,
    transactionTypeId: '00000000-0000-0000-0000-000000000003',
  };

  const checkPaymentMethod = {
    label: capitalize(t('translation:billing.types.check')),
    value: 'CHECK' as NonDigitalPaymentType,
    transactionTypeId: '00000000-0000-0000-0000-000000000004',
  };

  const nonDigitalPaymentMethods: { label: string; value: NonDigitalPaymentType; transactionTypeId: string }[] =
    isRegion('AU')
      ? [
          cashPaymentMethod,
          checkPaymentMethod,
          {
            label: capitalize(t('translation:billing.types.eftpos')),
            value: 'EFTPOS',
            transactionTypeId: '00000000-0000-0000-0000-000000000023',
          },
          {
            label: capitalize(t('translation:billing.types.direct-deposit')),
            value: 'DIRECT_DEPOSIT',
            transactionTypeId: '00000000-0000-0000-0000-000000000024',
          },
        ]
      : [
          cashPaymentMethod,
          checkPaymentMethod,
          {
            label: capitalize(t('translation:spelling.money-order')),
            value: 'MONEY_ORDER',
            transactionTypeId: '00000000-0000-0000-0000-000000000013',
          },
        ];

  const { businessId } = useSelector((state: RootState) => state.context);

  const { data: getTransactionTypesData } = useGetTransactionTypes({
    variables: {
      businessId: businessId!,
    },
    fetchPolicy: 'network-only',
    skip: !businessId,
  });

  const setAccount = (option: IAccount) => {
    setSelectedAccountCenterId(option?.center?.id ?? '');
    setFormData((prev) => ({ ...prev, accountId: option.id }));
  };

  return (
    <>
      <SideModalDrawer
        title="Add Manual Payment"
        show={isOpen}
        onHide={handleClose}
        primaryChoice="Save"
        primaryCallback={() => handleSubmit()}
        primaryButtonProps={{
          disabled: !validateForm(formData) || createManualPaymentLoading,
          loading: createManualPaymentLoading,
        }}
        closeOnPrimaryCallback={false}
        secondaryChoice="Cancel"
        secondaryCallback={handleClose}
        enforceFocus={false}
      >
        <Form>
          {!accountId && (
            <Row>
              <Column>
                <Select
                  required
                  label="Account"
                  options={searchAccountsData?.searchAccounts?.data ?? []}
                  onChange={(option: IAccount) => setAccount(option)}
                  getOptionLabel={(option: IAccount) => option.name}
                  getOptionValue={(option: IAccount) => option.id}
                  isLoading={searchAccountsLoading}
                  formatOptionLabel={(option: IAccount) => (
                    <SelectOptionWithAvatar
                      color={stringToHsl(option.id)}
                      initials={option.name.charAt(0).toUpperCase()}
                      primaryText={option.name}
                      secondaryText={option.center?.name ?? ''}
                    />
                  )}
                  isSearchable={true}
                />
              </Column>
            </Row>
          )}
          <Row>
            <Column className="mb-4">
              <DateInput
                required
                label={t('translation:billing.spelling.appliesDate')}
                date={formData.date}
                onDateSelect={(date) => setFormData((prev) => ({ ...prev, date }))}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <div className="mb-4">
                <div className="d-flex flex-row">
                  <Form.Label className="text-nowrap">Payment Method</Form.Label>
                  <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
                </div>
                {!formData.accountId && (
                  <small>
                    <i>{t('billing:transactions.manual-payment-modal.missing-account-text')}</i>
                  </small>
                )}
                {getAccountPaymentMethodsLoading || !getTransactionTypesData ? (
                  <LoadingLines />
                ) : (
                  <>
                    <ul className="unstyled-list mb-0">
                      {(getAccountPaymentMethodsData?.getAccountById.paymentMethods ?? []).map(
                        (paymentMethod: IPaymentMethod, idx: number) => (
                          <li className="mb-2" key={`payment-method-${paymentMethod.id}-${idx}`}>
                            <DetailedRadioOption
                              title={
                                <PaymentMethod
                                  paymentMethod={paymentMethod}
                                  showCardHolder
                                  className="payment-summary-card"
                                  centered
                                />
                              }
                              onSelect={(selected) =>
                                setFormData((prev) => ({
                                  ...prev,
                                  paymentMethodId: selected ? paymentMethod.id : null,
                                  nonDigitalPaymentType: null,
                                }))
                              }
                              isSelected={formData.paymentMethodId === paymentMethod.id}
                            />
                          </li>
                        )
                      )}
                    </ul>
                    <ul className="unstyled-list mb-0">
                      {nonDigitalPaymentMethods
                        .filter((x) =>
                          getTransactionTypesData?.getTransactionTypes
                            .filter((tt) => tt.metadata.isWritable)
                            .some((tt) => tt.id == x.transactionTypeId)
                        )
                        .map((type, idx) => (
                          <li className="mb-2" key={`payment-method-${type.value}-${idx}`}>
                            <DetailedRadioOption
                              title={type.label}
                              onSelect={(selected) =>
                                setFormData((prev) => ({
                                  ...prev,
                                  nonDigitalPaymentType: selected ? type.value : null,
                                  paymentMethodId: null,
                                }))
                              }
                              isSelected={formData.nonDigitalPaymentType === type.value}
                            />
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </div>
            </Column>
          </Row>
          <Row>
            <Column>
              <CurrencyInput2
                required
                className="border-radius-0"
                label="Amount"
                value={formData.amount}
                onChange={(amount) => setFormData((prev) => ({ ...prev, amount }))}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Form.Label>Note</Form.Label>
              <Alert variant="info" className="mb-4">
                {t('billing:transactions.manual-payment-modal.sensitive-info-alert')}
              </Alert>
              <TextInput
                label=""
                value={formData.description}
                onChange={(value: string) => setFormData((prev) => ({ ...prev, description: value }))}
                as="textarea"
                rows={5}
                placeholder="Description for this payment"
              />
            </Column>
          </Row>
        </Form>
      </SideModalDrawer>
      <ConfirmationModal
        title={`${capitalize(t('translation:spelling.autopay'))} currently enabled`}
        show={isConfirmationModalOpen}
        onHide={() => {
          setIsConfirmationModalOpen(false);
          handleClose();
        }}
        primaryChoice="Pay Now"
        primaryCallback={submitManualPayment}
        hideOnCallback={true}
        primaryButtonProps={{
          variant: 'primary',
          loading: false,
        }}
      >
        Are you sure you want to make a separate payment?
      </ConfirmationModal>
    </>
  );
};

export default AddManualPaymentModal;
