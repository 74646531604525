import React, { useCallback } from 'react';
import moment from 'moment';
import Checkbox from 'shared/components/Checkbox';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import useFormatDate from 'shared/hooks/useFormatDate';
import { capitalize, getFullName, getInitials } from 'shared/util/string';

import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { IconButtonCircle } from 'shared/components/Buttons';
import { useDeleteSessionAmendment } from 'gql/sessionAmendment/mutations';
import { showToast } from 'shared/components/Toast';
import i18n from 'i18n';

interface IProps {
  data: ISessionAmendmentDto[];
  setShowExpired: (value: boolean) => void;
  showExpired: boolean;
  loading: boolean;
  activePage: number;
  pageSize: number;
  totalRecords: number;
  refetch: () => void;
  changePage: (page: number, sizePerPage: number) => void;
}

const AttendanceHistoryTable: React.FC<IProps> = ({
  loading,
  data,
  totalRecords,
  showExpired,
  activePage,
  pageSize,
  changePage,
  setShowExpired,
  refetch,
}) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const [deleteSessionAmendment] = useDeleteSessionAmendment({
    onCompleted: (result) => {
      if (result?.deleteSessionAmendment) {
        showToast(i18n.t('translation:general.updateSuccessful'), 'success');
        refetch();
      }
    },
  });

  const revoke = useCallback(async (id: string) => {
    await deleteSessionAmendment({
      variables: {
        id,
      },
    });
  }, []);

  return (
    <div>
      <DataTable
        data={data}
        dataSize={totalRecords}
        showSelect={false}
        noPadding
        showLoadingOverlay={loading}
        showPagination
        keyField="id"
        activePage={activePage}
        pageSize={pageSize}
        onPageChange={changePage}
        renderHeader={() => (
          <div className="d-flex justify-content-end">
            <Checkbox
              label={t('attendance.history-control.show-expired')}
              className="mr-8"
              value={showExpired}
              onChange={setShowExpired}
            />
          </div>
        )}
        columns={[
          {
            text: capitalize(t('spelling.staff')),
            dataField: 'authorizedStaff',
            formatter: (cell: IStaff) => (
              <AvatarDataTableCell
                avatar={cell.avatar?.url}
                primaryText={getFullName(cell)}
                initials={getInitials(cell)}
              />
            ),
          },
          {
            text: t('attendance.history-control.start-date'),
            dataField: 'startDate',
            formatter: (row: string) => formatDate(row, 'MM/DD/YYYY'),
          },
          {
            text: t('attendance.history-control.end-date'),
            dataField: 'expiryDate',
            formatter: (cell: string, row: ISessionAmendmentDto) => formatDate(row.expiryDate, 'MM/DD/YYYY'),
          },
          {
            text: capitalize(t('spelling.approver')),
            dataField: 'authorizedBy',
            formatter: (cell: IStaff) => (
              <AvatarDataTableCell
                avatar={cell.avatar?.url}
                primaryText={getFullName(cell)}
                initials={getInitials(cell)}
              />
            ),
          },
          {
            text: t('attendance.history-control.timestamp'),
            dataField: 'createdAt',
            formatter: (cell: string) => formatDate(cell, 'timestamp-MM/DD/YYYY'),
          },
          {
            text: '',
            dataField: 'id',
            sort: false,
            formatter: (cell: string, row: ISessionAmendmentDto) =>
              moment().isAfter(moment(row.expiryDate)) ? null : (
                <>
                  <IconButtonCircle
                    size="sm"
                    icon={faTimes}
                    color="gray"
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                      e.preventDefault();
                      e.stopPropagation();
                      revoke(row.id);
                    }}
                  />
                </>
              ),
          },
        ]}
      />
    </div>
  );
};

export default AttendanceHistoryTable;
