import React, { useState } from 'react';
import moment from 'moment';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { IconButtonCircle } from 'shared/components/Buttons';
import { TableHeader } from 'shared/components/DataTable';
import DateInput from 'shared/components/DateInput';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import Select from 'shared/components/Select';
import { ccsMessageSources, ccsMessageTypes, messageStatusFilters } from 'shared/constants/enums/ccssMessageEnums';

interface ITHeadProps {
  handleStatusChange: (option: any) => void;
  activeFilters: IMessagesFilter;
  onActiveFilterChange: (value: IMessagesFilter) => void;
  dateRange: ITimeRange;
  setDateRange: (tr: ITimeRange) => void;
  clearFilters: () => void;
  handleTypeChange: (option: any) => void;
  handleSourceChange: (option: any) => void;
}

const CCSMessagesTableHeader: React.FC<ITHeadProps> = ({
  handleStatusChange,
  dateRange,
  activeFilters,
  setDateRange,
  handleTypeChange,
  clearFilters,
  handleSourceChange,
}) => {
  const [isDateValid, setDateValidity] = useState<boolean>(true);

  const validateDateRange = (start: string, end: string) => {
    if (start !== '' && end !== '') {
      setDateValidity(moment(start).isBefore(moment(end)));
    }
  };
  const handleStartDate = (start: string) => {
    setDateRange({ ...dateRange, start });
    validateDateRange(start, dateRange.end);
  };

  const handleEndDate = (end: string) => {
    setDateRange({ ...dateRange, end });
    validateDateRange(dateRange.start, end);
  };

  const handleCLearFilters = () => {
    setDateValidity(true);
    clearFilters();
  };

  return (
    <TableHeader className="flex-wrap messages-table-header">
      <Select
        name="status"
        options={messageStatusFilters}
        placeholder="Status"
        onChange={handleStatusChange}
        className="mr-4 my-2 status-filter"
        value={activeFilters.status}
      />
      <DateInput
        date={dateRange.start}
        isValid={isDateValid}
        hasCustomValidation
        onDateSelect={handleStartDate}
        className="mr-2 flex-grow-0 my-2"
      />
      <div className="mr-2 my-2">to</div>
      <DateInput
        date={dateRange.end}
        isValid={isDateValid}
        hasCustomValidation
        onDateSelect={handleEndDate}
        className="mr-4 flex-grow-0 my-2"
      />
      <div className="d-flex justify-content-between align-items-center right-filter">
        <Select
          name="typeFilter"
          options={ccsMessageTypes}
          placeholder="Type"
          onChange={handleTypeChange}
          className="mr-4 my-2 type-filter"
          value={activeFilters.type}
        />
        <DropdownFilter
          dropdownId="sourceFilter"
          title="Source"
          placeholder="Source"
          options={ccsMessageSources}
          onFilterSelect={(option: any) => handleSourceChange(option)}
          className="mr-4 my-2 sourceFilter"
          selectedFilters={activeFilters.source || []}
        />
        <IconButtonCircle
          icon={faTimes}
          onClick={handleCLearFilters}
          tooltipDirection="bottom"
          tooltipText="Clear Filters"
          className="mr-4 my-2"
        />
      </div>
    </TableHeader>
  );
};

export default CCSMessagesTableHeader;
