import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import Select from 'shared/components/Select';

// SORT: adds custom sort carets to columns.

export const getCustomizedColumns = (columns) =>
  columns.map((column) => ({
    sortCaret: (order) => {
      if (!order) return <FontAwesomeIcon className="ml-4 icon-lowered" icon={faAngleDown} size="1x" />;
      else if (order === 'asc') return <FontAwesomeIcon className="ml-4 icon-lowered" icon={faAngleDown} size="1x" />;
      else if (order === 'desc') return <FontAwesomeIcon className="ml-4 icon-lowered" icon={faAngleUp} size="1x" />;
      return null;
    },
    ...column,
  }));

// PAGINATION

// custom buttons to select page to view
export const pageButtonRenderer = ({ active, page, title, onPageChange }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };

  return (
    <li className={`page-item ${active ? 'active' : ''}`} key={title}>
      <a className="page-link" href="/#" onClick={handleClick}>
        {page}
      </a>
    </li>
  );
};

// custom dropdown to select items per page
const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange, className }) => {
  const selectedOption = options.find((option) => `${option.page}` === currSizePerPage);
  return (
    <div className="d-flex align-items-center mr-4">
      <span className="mr-4">Show</span>
      <Select
        className="size-per-page-select mb-0"
        options={options}
        value={selectedOption}
        onChange={(option) => onSizePerPageChange(option.page)}
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option}
      />
    </div>
  );
};

export const getPageOptionsFromDataLength = ({
  dataSize,
  pageSize = 5,
  currentPage = 1,
  onPageChange,
  onSizePerPageChange,
}) => ({
  custom: true,
  totalSize: dataSize,
  sizePerPage: pageSize,
  page: currentPage,
  pageStartIndex: 1,
  alwaysShowAllBtns: true, // Always show next and previous button
  withFirstAndLast: false, // Hide the going to First and Last page button
  hideSizePerPage: false, // Hide the sizePerPage dropdown always
  hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
  prePageText: <FontAwesomeIcon icon={faAngleLeft} />,
  nextPageText: <FontAwesomeIcon icon={faAngleRight} />,
  showTotal: true,
  paginationTotalRenderer: (from, to, size) => (
    <span>
      {' '}
      Showing {from} to {to} of {size} items
    </span>
  ),
  sizePerPageList: [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '50', value: 50 },
  ],
  pageButtonRenderer,
  sizePerPageRenderer,
  onPageChange,
  onSizePerPageChange,
});

// custom select. only show if 'select' prop is passed to DataTable
export const getCustomSelect = ({
  showSelect,
  onSelect,
  onSelectAll,
  selectedRows,
  nonSelectable,
  keyField,
  singleSelect = false,
}) => ({
  mode: singleSelect ? 'radio' : 'checkbox',
  clickToSelect: false,
  classes: 'kt-datatable-selected-row',
  hideSelectColumn: !showSelect,
  hideSelectAll: singleSelect,
  nonSelectable,
  selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
    <div className="custom-control custom-checkbox text-center">
      <input
        type="checkbox"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
        readOnly
        className="custom-control-input"
      />
      <label className="custom-control-label"></label>
    </div>
  ),
  selectionRenderer: ({ mode, checked, disabled }) => (
    <div className="custom-control custom-checkbox text-center">
      <input type={'checkbox'} checked={checked} disabled={disabled} readOnly className="custom-control-input" />
      <label className="custom-control-label"></label>
    </div>
  ),
  onSelect,
  onSelectAll,
  selected: selectedRows?.map((r) => r[keyField]),
});
