import { useCallback, useMemo, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Row } from 'shared/components/Layout';
import { capitalize } from 'shared/util/string';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { useGetKindyForAllAgreements } from 'gql/kindyForAll/queries';
import { useCreateEditKindyForAllCenterAgreement } from 'gql/kindyForAll/mutations';
import showToast from 'shared/components/Toast/showToast';
import { IKindyForAllAgreement, IKindyForAllCenterAgreement } from 'shared/types/kindyForAll';

type Props = {
  businessId: string;
  centerAgreement: IKindyForAllCenterAgreement | undefined;
  centerAgreements: IKindyForAllCenterAgreement[] | undefined;
  isOpen: boolean;
  onClose: () => void;
};

interface IFormData {
  id?: string;
  centerAgreement?: IKindyForAllCenterAgreement;
  center?: ICenter;
}

export default function CreateEditKindyForAllCentreAgreementModal({
  businessId,
  centerAgreement,
  centerAgreements,
  isOpen,
  onClose,
}: Props) {
  const [formData, setFormData] = useState<IFormData>({});
  const { data: centers, loading: centersLoading } = useGetActiveCentersWithLoading();

  const filteredCenters = useMemo(() => {
    const businessCenters = centers?.filter((c) => !businessId || c.entityId === businessId);
    const excludeCenters = centerAgreements?.filter((c) => c.id !== centerAgreement?.id);
    return businessCenters?.filter((c) => !(excludeCenters?.map((ca) => ca.centerId) ?? []).includes(c.id));
  }, [businessId, centerAgreement, centerAgreements, centers]);

  const { t } = useTranslation(['translation', 'subsidies']);

  const handleAgreementChange = useCallback(
    (value: IKindyForAllAgreement | undefined) => {
      const newCenterAgreement: IKindyForAllCenterAgreement = {
        businessId: businessId,
        centerId: formData.center?.id,
        centerName: formData.center?.name,
        agreementId: value?.id ?? '',
        agreementNumber: value?.agreementNumber,
        beneficiaryAccountNumber: value?.beneficiaryAccountNumber,
      };

      setFormData((prevState) => {
        return { ...prevState, centerAgreement: newCenterAgreement };
      });
    },
    [businessId, formData.center]
  );

  const handleCenterChange = useCallback((value: ICenter | undefined) => {
    if (value) {
      setFormData((prevState) => {
        return { ...prevState, center: value };
      });
    }
  }, []);

  useMemo(() => {
    if (centerAgreement) {
      setFormData({
        id: centerAgreement.id,
        centerAgreement: centerAgreement,
        center: filteredCenters?.find((c) => c.id === centerAgreement.centerId),
      });
    }
  }, [centerAgreement, filteredCenters]);

  const { data: data, loading: agreementsLoading } = useGetKindyForAllAgreements({
    variables: {
      input: {
        businessId: businessId ?? '',
      },
    },
    skip: businessId == null || businessId === '',
  });

  const availableAgreements = useMemo(() => {
    const usedAgreementIds =
      centerAgreements?.filter((ca) => ca.id !== centerAgreement?.id)?.map((ca) => ca.agreementId) ?? [];

    return (data?.getKindyForAllAgreements ?? []).filter((a) => !usedAgreementIds?.includes(a.id));
  }, [centerAgreement, centerAgreements, data]);

  const [createEditKindyForAllCenterAgreementFn, { loading: createEditCenterAgreementLoading }] =
    useCreateEditKindyForAllCenterAgreement({
      onCompleted: (result) => {
        if (result?.createEditKindyForAllCenterAgreement) {
          setFormData({});
          onClose();
          showToast(
            `Centre Agreement successfully ${centerAgreement === undefined ? 'created' : 'edited'}.`,
            'success'
          );
        }
      },
      onError: (error) => {
        showToast(
          `There was an error  ${centerAgreement === undefined ? 'creating' : 'editing'} Centre Agreement : ${
            error.message
          }`,
          'error'
        );
      },
    });

  const isValidFormData = useMemo(() => !!formData.center && !!formData.centerAgreement, [formData]);

  const handleSubmit = useCallback(() => {
    if (isValidFormData) {
      if (centerAgreement) {
        createEditKindyForAllCenterAgreementFn({
          variables: {
            input: {
              id: centerAgreement?.id ?? '',
              businessId: businessId ?? '',
              centerId: formData?.center?.id,
              centerName: formData?.center?.name,
              agreementId: formData?.centerAgreement?.agreementId ?? '',
              agreementNumber: formData?.centerAgreement?.agreementNumber,
              beneficiaryAccountNumber: formData?.centerAgreement?.beneficiaryAccountNumber,
            },
          },
        });
      } else {
        createEditKindyForAllCenterAgreementFn({
          variables: {
            input: {
              businessId: businessId ?? '',
              centerId: formData?.center?.id,
              centerName: formData?.center?.name,
              agreementId: formData?.centerAgreement?.agreementId ?? '',
              agreementNumber: formData?.centerAgreement?.agreementNumber,
              beneficiaryAccountNumber: formData?.centerAgreement?.beneficiaryAccountNumber,
            },
          },
        });
      }
    }
  }, [businessId, centerAgreement, createEditKindyForAllCenterAgreementFn, formData, isValidFormData]);

  const centerOptions = useMemo(() => {
    return filteredCenters?.map((c) => ({ value: c.id, label: c.name })) ?? [];
  }, [filteredCenters]);

  const agreementOptions = useMemo(() => {
    return availableAgreements?.map((a) => ({ value: a.id, label: a.agreementNumber })) ?? [];
  }, [availableAgreements]);

  return (
    <SideModalDrawer
      title={`Kindy For All Configuration`}
      show={isOpen}
      primaryChoice={capitalize(t('translation:spelling.save'))}
      primaryCallback={handleSubmit}
      primaryButtonProps={{ disabled: !isValidFormData, loading: createEditCenterAgreementLoading }}
      onHide={() => {
        if (onClose) {
          setFormData({});
          onClose();
        }
      }}
      closeOnPrimaryCallback={false}
    >
      <Form>
        <Row className="mb-4 mt-4">
          <Select
            name="centreName"
            label="Centre"
            required
            isLoading={centersLoading}
            options={centerOptions}
            value={centerOptions.find((option) => option.value === formData?.center?.id) ?? null}
            onChange={(o: ISelectMenuItem) => {
              handleCenterChange(centers?.find((c) => c.id == o.value));
            }}
          />
        </Row>
        <Row className="mb-4">
          <Select
            name="centerAgreementNumber"
            label="Kindy Funding Reference Number"
            required
            isLoading={agreementsLoading}
            options={agreementOptions}
            value={agreementOptions.find((option) => option.value === formData?.centerAgreement?.agreementId) ?? null}
            onChange={(o: ISelectMenuItem) => {
              handleAgreementChange(data?.getKindyForAllAgreements.find((a) => a.id == o.value));
            }}
          />
        </Row>
        <Row className="mb-4">
          <TextInput
            disabled
            label={'Beneficiary Number'}
            placeholder={'Select Kindy Funding Reference Number'}
            value={formData?.centerAgreement?.beneficiaryAccountNumber}
          />
        </Row>
      </Form>
    </SideModalDrawer>
  );
}
