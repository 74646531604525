export const allergenFields: string = `
  id
  typeId
  type {
    id
    name
    type
  }
  name
  archivedAt
`;
