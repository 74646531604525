import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import {
  attendanceOpenSpotsFields,
  sessionFieldsWithEnrollmentYearAbsences,
  childAbsenceFields,
  sessionFields,
  sessionFieldsForStaffAttendance,
  sessionFieldsForChildDropDown,
} from './fields';
import { getExpectedSessions } from 'pages/Attendance/duck/actions';
import { useDispatch } from 'react-redux';

//interfaces
export interface IGetExpectedSessionsData {
  getExpectedSessions: ISession[];
}
interface IGetExpectedSessionsVariables {
  input: IGetExpectedSessionsInput;
  enrollmentYearStart?: string;
  enrollmentYearEnd?: string;
}

export interface IGetAttendanceOpenSpotsData {
  getAttendanceOpenSpots: IAttendanceOpenSpots;
}

export interface IGetChildAbsencesData {
  getAccountChildrenAbsencesForTimeFrame: IAbsence[];
}

interface IGetAttendanceOpenSpotsVariables {
  input: GetAttendanceOpenSpotsInput;
}

interface IGetChildAbsencesVariables {
  input: GetChildAbsencesInput;
}

interface IGetExpectedSessionsForAccountChildData {
  getExpectedSessionsForAccountChildInTimeframe: ISession[];
}

interface IGetExpectedSessionsForAccountChildVariables {
  input: {
    accountChildId: string;
    startDate: string;
    endDate: string;
  };
}

//queries
export const GET_EXPECTED_SESSIONS = (
  fields: string = sessionFieldsWithEnrollmentYearAbsences,
  withYearAbsences: boolean = true
) => {
  if (withYearAbsences) {
    return gql`
      query ($input: GetExpectedSessionsInput!, $enrollmentYearStart: Date, $enrollmentYearEnd: Date) {
        getExpectedSessions(input: $input, ) {
          ${fields}
        }
      }
    `;
  } else {
    return gql`
      query ($input: GetExpectedSessionsInput!) {
        getExpectedSessions(input: $input, ) {
          ${fields}
        }
      }
    `;
  }
};

export const GET_SESSIONS_DATA_FOR_STAFF_SCHEDULE = (fields: string = sessionFieldsForStaffAttendance) => gql`
  query ($input: GetExpectedSessionsInput!) {
    getExpectedSessions(input: $input) {
      ${fields}
    }
  }
`;

export const GET_SESSIONS_DATA_FOR_CHILD_SELECT = (fields: string = sessionFieldsForChildDropDown) => gql`
  query ($input: GetExpectedSessionsInput!) {
    getExpectedSessions(input: $input) {
      ${fields}
    }
  }
`;

export const GET_ATTENDANCE_OPEN_SPOTS = (fields: string = attendanceOpenSpotsFields) => gql`
  query($input: GetAttendanceOpenSpotsInput!) {
    getAttendanceOpenSpots(input: $input) {
      ${fields}
    }
  }
`;

export const GET_CHILDREN_ABSENCES = gql`
  query($input: GetAccountChildrenAbsencesForTimeFrameInput!) {
    getAccountChildrenAbsencesForTimeFrame(input: $input) {
      ${childAbsenceFields}
    }
  }
`;

export const GET_EXPECTED_SESSIONS_FOR_ACCOUNT_CHILD = (fields: string = sessionFields) => gql`
  query ($input: GetExpectedSessionsForAccountChildInTimeframeInput) {
    getExpectedSessionsForAccountChildInTimeframe(input: $input) {
      ${fields}
    }
  }
`;

//hooks
export const useGetExpectedSessions = (
  options?: QueryHookOptions<IGetExpectedSessionsData, IGetExpectedSessionsVariables>,
  fields?: string,
  withYearAbsences?: boolean
) => {
  const dispatch = useDispatch();
  return useQuery<IGetExpectedSessionsData, IGetExpectedSessionsVariables>(
    GET_EXPECTED_SESSIONS(fields, withYearAbsences),
    {
      skip:
        !options?.variables?.input.centerId || !options?.variables.input.startDate || !options?.variables.input.endDate,
      // There are three cache policies that might fit here, we have settled on "no-cache" for the moment.
      //
      // Originally this was "cache-and-network" which appeared to have the effect of completing twice:
      // once when data was available in the cache, and once when the server returned updated data.
      //
      // The attendances screen consumes this data. It is complex with lots of components and the cost
      // if redrawing it twice was quite high. Also we only ever refetched this data when something had
      // changed so the data returned from the server would always differ from the cached copy and cause
      // a redraw.
      //
      // "network-only" is a sensible step towards better behavior, but this is the only place we call
      // getExpectedSessions so "network-only" would not complete with cached data, although the Apollo
      // documentation says the data gets cached (in case a future query uses a different fetch policy.)
      //
      // Since we do not use a different fetch policy we conclude that "no-cache" is the best candidate.
      //
      // https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        dispatch(getExpectedSessions(data?.getExpectedSessions ?? []));
      },
      ...options,
    }
  );
};

export const useGetSessionDataForStaffSchedule = (
  options?: QueryHookOptions<IGetExpectedSessionsData, { input: IGetExpectedSessionsInput }>,
  fields?: string
) => {
  return useQuery<IGetExpectedSessionsData, { input: IGetExpectedSessionsInput }>(
    GET_SESSIONS_DATA_FOR_STAFF_SCHEDULE(fields),
    {
      skip:
        !options?.variables?.input.centerId || !options?.variables.input.startDate || !options?.variables.input.endDate,
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );
};

export const useGetSessionDataForChildSelect = (
  options?: QueryHookOptions<IGetExpectedSessionsData, { input: IGetExpectedSessionsInput }>,
  fields?: string
) => {
  return useQuery<IGetExpectedSessionsData, { input: IGetExpectedSessionsInput }>(
    GET_SESSIONS_DATA_FOR_CHILD_SELECT(fields),
    {
      skip:
        !options?.variables?.input.centerId || !options?.variables.input.startDate || !options?.variables.input.endDate,
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );
};

export const useGetAttendanceOpenSpots = (
  options?: QueryHookOptions<IGetAttendanceOpenSpotsData, IGetAttendanceOpenSpotsVariables>,
  fields?: string
) =>
  useQuery<IGetAttendanceOpenSpotsData, IGetAttendanceOpenSpotsVariables>(GET_ATTENDANCE_OPEN_SPOTS(fields), {
    skip: !options?.variables?.input.centerId,
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetChildAbsences = (
  options?: QueryHookOptions<IGetChildAbsencesData, IGetChildAbsencesVariables>,
  fields?: string
) =>
  useQuery<IGetChildAbsencesData, IGetChildAbsencesVariables>(GET_CHILDREN_ABSENCES, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetExpectedSessionsForAccountChild = (
  options?: QueryHookOptions<IGetExpectedSessionsForAccountChildData, IGetExpectedSessionsForAccountChildVariables>,
  fields?: string
) =>
  useQuery<IGetExpectedSessionsForAccountChildData, IGetExpectedSessionsForAccountChildVariables>(
    GET_EXPECTED_SESSIONS_FOR_ACCOUNT_CHILD(fields),
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

export const useGetExpectedSessionsForAccountChildLazy = (
  options?: QueryHookOptions<IGetExpectedSessionsForAccountChildData, IGetExpectedSessionsForAccountChildVariables>,
  fields?: string
) =>
  useLazyQuery<IGetExpectedSessionsForAccountChildData, IGetExpectedSessionsForAccountChildVariables>(
    GET_EXPECTED_SESSIONS_FOR_ACCOUNT_CHILD(fields),
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );
