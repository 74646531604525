import React, { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Card from 'shared/components/Card';
import { Box } from 'shared/components/LoadingSkeletons';
import DataCard from './DataCard';
import colors from '_colors.module.scss';

interface IProps {
  loading: boolean;
  trainingHours?: string;
  paidTimeOffHours?: string;
  unpaidTimeOffHours?: string;
}

const DataCardsRow: React.FC<IProps> = ({
  loading,
  trainingHours = '0.0',
  paidTimeOffHours = '0.0',
  unpaidTimeOffHours = '0.0',
  ...props
}) => {
  return (
    <Row>
      <Column md className="d-flex">
        <DataCard title="Training" number={trainingHours} color={colors.info} loading={loading}>
          Hours of training paid out.
        </DataCard>
      </Column>
      <Column md className="d-flex">
        {/* bit different from the regular data card component so that component can't be used. rt if you cried */}
        <Card className="w-100" bodyClassName="d-flex align-items-center">
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-row flex-wrap">
              <div>
                <b>Time Off</b>
                <div>
                  <small>Hours of time off taken.</small>
                </div>
              </div>
              <div className="d-flex flex-grow-1 justify-content-end">
                {loading ? (
                  <Box height={56} width={56} />
                ) : (
                  <Fragment>
                    <div className="d-flex flex-column flex-grow-1 align-items-center">
                      Paid
                      <p className="h1" style={{ color: colors.success }}>
                        {paidTimeOffHours}
                      </p>
                    </div>
                    <div className="d-flex flex-column flex-grow-0 align-items-center">
                      Unpaid
                      <p className="h1" style={{ color: colors.danger }}>
                        {unpaidTimeOffHours}
                      </p>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Column>
    </Row>
  );
};

export default DataCardsRow;
