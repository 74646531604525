import React from 'react';
import _ from 'lodash';
import * as Mui from '@mui/material';

import { useHistory } from 'react-router-dom';
import { showToast } from 'shared/components/Toast';
import { useDeleteRoyaltyFee } from 'pages/Businesses/subroutes/BusinessSettings/gql/mutation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import IconButtonCircle from 'shared/components/Buttons/IconButtonCircle';

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
};
interface IRoyaltyException {
  id: string;
  name: string;
  overridePercentage: number;
  royaltyFeeId: string;
  participantCenterIds: string[];
}

interface IRoyaltyFee {
  id: string;
  businessId: string;
  name: string;
  percentage: number;
  royaltyExceptions: IRoyaltyException[];
}

interface IRowProps {
  index: number;
  row: IRoyaltyFee;
  refetch: () => void;
}

const RoyaltyFeeTableRow: React.FC<IRowProps> = ({ index, row, refetch, ...props }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [openArchiveModal, setOpenArchiveModal] = React.useState(false);
  const labelId = `enhanced-table-checkbox-${index}`;
  const [deleteFeeFn, { loading: deleteLoading }] = useDeleteRoyaltyFee({
    onCompleted: () => {
      showToast('Royalty Fee Deleted Successfully', 'success');
      refetch();
    },
    onError: () => {
      showToast('Error Deleting Royalty Fee', 'error');
    },
  });
  const onDeleteRequest = (royaltyFeeId: string, businessId: string) => {
    deleteFeeFn({
      variables: { businessId: businessId, royaltyFeeId: royaltyFeeId },
    });
  };
  return (
    <>
      <Mui.TableRow key={row.name}>
        <Mui.TableCell align="left" id={labelId}>
          <Mui.Typography variant="body1">{row.name}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="center">
          <Mui.Typography variant="body1">{`${row.percentage}%`}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="center">
          <Mui.Typography variant="body1">{row.royaltyExceptions.length}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="right">
          <>
            <IconButtonCircle
              id="action-dropdown-btn"
              iconSize="2x"
              icon={faEllipsisH}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <Mui.Menu
              PaperProps={{ sx: { boxShadow: '1' } }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <Mui.MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  history.push(`/business/${row.businessId}/settings/royaltyFee/${row.id}`);
                }}
              >
                Edit
              </Mui.MenuItem>
              <Mui.MenuItem
                onClick={() => {
                  setOpenArchiveModal(true);
                  // onDeleteRequest(row.id, row.businessId);
                  setAnchorEl(null);
                }}
              >
                Delete
              </Mui.MenuItem>
            </Mui.Menu>
          </>
        </Mui.TableCell>
      </Mui.TableRow>
      <Mui.Dialog open={openArchiveModal} onClose={() => setOpenArchiveModal(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography variant="dialogTitle">Archive Royalty Fee</Mui.Typography>
          <Mui.IconButton onClick={() => setOpenArchiveModal(false)} disableRipple sx={styles.closeIcon}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>{`Are you sure you would like to archive ${row.name}?`}</Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setOpenArchiveModal(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button variant="contained" color="secondary" onClick={() => onDeleteRequest(row.id, row.businessId)}>
            Confirm
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default RoyaltyFeeTableRow;
