import { useGetAgenciesForBusiness } from 'gql/agency/queries';
import { useGetEntity } from 'gql/business/queries';
import { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TABLE_DEFAULTS } from 'shared/components/DataTable';
import { RootState } from 'store/reducers';

const defaultFilterState = {
  searchKey: '',
  sortField: 'name',
  sortDirection: 'asc',
  pageNumber: TABLE_DEFAULTS.PAGE,
  pageSize: TABLE_DEFAULTS.PAGE_SIZE,
  agencyIds: [],
  centerIds: [],
  statuses: [],
};

const EnrollmentStatusOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Expired', value: 'EXPIRED' },
  { label: 'Expiring Soon', value: 'EXPIRING_SOON' },
  { label: 'Future', value: 'FUTURE' },
];

/**
 * Supports the filters for the AgencyChildrenTable. Houses queries, state changes, etc
 * and the outputis meant to be passed into AgencyChildrenTable props.
 * @returns
 */
export function useAgencyChildrenTableFilters() {
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const { data: getEntityData } = useGetEntity(
    {
      variables: {
        id: currentBusinessId ?? '',
      },
    },
    `id centers { id name }`
  );
  const centerFilterOptions = useMemo(() => {
    return (
      getEntityData?.getEntity.centers
        .map((c) => ({ label: c.name, value: c.id }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [getEntityData?.getEntity.centers]);

  const { data: getAgenciesData } = useGetAgenciesForBusiness(
    {
      variables: {
        businessId: currentBusinessId ?? '',
      },
    },
    `id name   billingCalculationType subsidyPeriod`
  );

  const agencyFilterOptions = useMemo(() => {
    return getAgenciesData?.getAgenciesForBusiness.map((a) => ({ label: a.name, value: a.id })) ?? [];
  }, [getAgenciesData?.getAgenciesForBusiness]);

  const [filterState, setFilterState] = useState<{
    searchKey: string;
    sortField: string;
    sortDirection: string;
    pageNumber: number;
    pageSize: number;
    agencyIds: string[];
    centerIds: string[];
    statuses: AgencyAccountChildStatus[];
  }>({ ...defaultFilterState });

  const handleSearchTermChange = useCallback((searchTerm: string) => {
    setFilterState((prev) => ({ ...prev, searchKey: searchTerm, pageNumber: TABLE_DEFAULTS.PAGE }));
  }, []);

  const handleFilterUpdate = useCallback((selectedIds, filterKey) => {
    setFilterState((prev) => ({ ...prev, [filterKey]: selectedIds }));
  }, []);

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    //Note our tables only understand how to speak sorting in terms of Elasticsearch so we must translate it here.
    const sortDirection = direction === 'ASCENDING' ? 'asc' : 'desc';
    setFilterState((prev) => ({ ...prev, sortField: field, sortDirection, pageNumber: TABLE_DEFAULTS.PAGE }));
  }, []);

  const handlePageChange = useCallback((page: number) => setFilterState((prev) => ({ ...prev, pageNumber: page })), []);
  const handlePageSizeChange = useCallback(
    (pageSize: number) => setFilterState((prev) => ({ ...prev, pageNumber: TABLE_DEFAULTS.PAGE, pageSize })),
    []
  );

  const handleClearFilter = useCallback(() => {
    setFilterState({ ...defaultFilterState });
  }, []);

  return {
    agencyFilterOptions,
    centerFilterOptions,
    enrollmwentStatusOptions: EnrollmentStatusOptions,
    filterState,
    handleClearFilter,
    handleFilterUpdate,
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
    handleSort,
  };
}
