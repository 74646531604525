import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import Select from 'shared/components/Select';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { sortBy } from 'lodash';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import { snakeCaseToLowerCase } from 'shared/util/string';
import { convertChargeDayToStringForPreview, convertTimePeriodToString, generateBillingCycleName } from './utils';
import { useCreateBillingCycleTemplate } from 'gql/billingCycle/mutations';
import { showToast } from 'shared/components/Toast';
import { createBillingCycleTemplate } from '../../duck/actions';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  billingCycle: ICreateBillingCycleTemplateInput;
  clearForm: () => void;
}

const PreviewBillingCycleModal: React.FC<IProps> = ({ isOpen, onClose, billingCycle, clearForm }) => {
  const user = useSelector((state: RootState) => state.user);
  const isUserBusinessScoped = user?.roleship?.scopeType === 'ENTITY';
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const dispatch = useDispatch();

  const [name, updateName] = useState(generateBillingCycleName(billingCycle));
  const [businessId, updateBusinessId] = useState(currentBusinessId);
  const [scopeId, updateScopeId] = useState('');

  const { data: businessesData, loading: businessesLoading } = useGetAllowedEntities();
  const { data: centers, loading: centersLoading } = useGetActiveCentersWithLoading();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const filteredCenters = centers?.filter((c) => !businessId || c.entityId === businessId) || [];
  const selectedBusiness = businesses.find((b) => b.id === businessId);

  const [createBillingCycleTemplateFn, { loading }] = useCreateBillingCycleTemplate({
    onCompleted: (data) => {
      if (data.createBillingCycleTemplate) {
        dispatch(createBillingCycleTemplate(data.createBillingCycleTemplate));
        onClose();
        clearForm();
        showToast('Billing cycle created successfully.', 'success');
      }
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  useEffect(() => {
    updateBusinessId(currentBusinessId);
  }, [currentBusinessId]);

  useEffect(() => {
    if (user && !isUserBusinessScoped && centers && centers.length === 1) {
      updateScopeId(centers[0].id);
    }
  }, [centers, user, isUserBusinessScoped]);

  const formDisabled = !name || !scopeId || !businessId;
  const handleSave = useCallback(() => {
    const input = { ...billingCycle, name, businessId };
    if (scopeId !== businessId) {
      input.centerId = scopeId;
    }
    createBillingCycleTemplateFn({
      variables: {
        input,
      },
    });
  }, [billingCycle, createBillingCycleTemplateFn, businessId, scopeId, name]);

  const { frequency, invoiceSchedule, period, additionalChargePeriod, dayOfMonth, dayOfWeek } = billingCycle;
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <ConfirmationModal
      title="Billing Cycle Summary"
      className="billing-summary"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Save"
      primaryCallback={handleSave}
      hideOnCallback={false}
      primaryButtonProps={{ disabled: formDisabled, loading }}
    >
      <BalancedBasedBillingCycleMessage
        dayOfMonth={dayOfMonth}
        dayOfWeek={dayOfWeek}
        invoiceSchedule={invoiceSchedule}
        period={period}
        additionalChargePeriod={additionalChargePeriod}
        frequency={frequency}
        className="mb-8"
      />

      <TextInput required label="Billing Cycle Name" onChange={updateName} value={name} />
      {user?.isInternal && (
        <Select
          label="Business"
          isLoading={businessesLoading}
          options={businesses.map((b) => ({ value: b.id, label: b.name }))}
          value={businessId}
          onChange={(option) => {
            updateBusinessId(option.value);
            updateScopeId('');
          }}
          required
        />
      )}
      {(isUserBusinessScoped || (centers && centers.length > 1)) && (
        <Select
          label="Save Billing Cycle To"
          isLoading={centersLoading}
          options={
            isUserBusinessScoped || user?.isInternal
              ? [
                  {
                    label: `Business Level (Accessible to all ${fieldLabels.center}s)`,
                    options: [{ label: selectedBusiness?.name ?? 'Business', value: businessId }],
                  },
                  {
                    label: `${fieldLabels.center} Level`,
                    options: filteredCenters.map((c) => ({ value: c.id, label: c.name })),
                  },
                ]
              : filteredCenters.map((c) => ({ value: c.id, label: c.name }))
          }
          value={scopeId || null}
          onChange={(option) => updateScopeId(option.value)}
          noOptionsMessage={() => `No Active ${fieldLabels.center}s Found.`}
          required
        />
      )}
    </ConfirmationModal>
  );
};

interface IBalancedBasedBillingCycleMessageProps {
  dayOfMonth?: number | null;
  dayOfWeek?: DayOfWeek;
  invoiceSchedule: ITimePeriod;
  period: BillingPeriod;
  additionalChargePeriod?: ITimePeriod;
  frequency: BillingFrequency;
  className?: string;
}

/**
 * This component is also used by BillingCycleTemplateSelect
 * So check the UI for that component as well if you are making changes here.
 */
export function BalancedBasedBillingCycleMessage({
  dayOfMonth,
  dayOfWeek,
  invoiceSchedule,
  period,
  additionalChargePeriod,
  frequency,
  className = '',
}: IBalancedBasedBillingCycleMessageProps) {
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const frequencyText = useMemo(() => {
    let lowerCaseFrequency = snakeCaseToLowerCase(frequency ?? '');
    lowerCaseFrequency = lowerCaseFrequency === 'biweekly' ? fieldLabels.biWeekly.toLowerCase() : lowerCaseFrequency;
    return lowerCaseFrequency;
  }, [frequency, fieldLabels.biWeekly]);

  return (
    <div className={className}>
      <Row noGutters className="mb-2">
        <h6 className="mr-2 mb-0">Charged: </h6>
        {`${frequencyText} on ${convertChargeDayToStringForPreview(dayOfMonth || dayOfWeek)}.`}
      </Row>
      <Row noGutters className="mb-2">
        <h6 className="mr-2 mb-0">Invoice created: </h6>
        {`${convertTimePeriodToString(invoiceSchedule)} before payments will be processed.`}
      </Row>
      <Row noGutters className="mb-0">
        <h6 className="mr-2 mb-0">The bill includes: </h6>
        {`${snakeCaseToLowerCase(period ?? '')} of care and ${
          additionalChargePeriod ? convertTimePeriodToString(additionalChargePeriod) : '0'
        } of future care.`}
      </Row>
    </div>
  );
}

export default PreviewBillingCycleModal;
