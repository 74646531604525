import { SET_ENTITIES } from './types';

interface IEntitiesState {
  allEntities: { [key: string]: IEntity };
}

const initialState: IEntitiesState = {
  allEntities: {},
};

const entitiesReducer = (state = initialState, action: { type: string; entities: IEntity[]; entityId: string }) => {
  switch (action.type) {
    case SET_ENTITIES: {
      return {
        ...state,
        allEntities: Object.fromEntries(action.entities.map((e) => [e.id, e])),
      };
    }
    default:
      return state;
  }
};

export default entitiesReducer;
