import { IconDefinition, IconName } from '@fortawesome/pro-light-svg-icons';

/**
 * this icon is extracted from @fortawesome/pro-light-svg-icons version 6
 * we have license to use it.
 * we are not upgrading to version 6 because there are breaking changes, so we can't upgrade it yet.
 * // TODO upgrade all fortawesome tools set to least version
 */

export const faArrowsUpDownLeftRight: IconDefinition = {
  prefix: 'fal',
  iconName: 'arrows-up-down-left-right' as IconName,
  icon: [
    512,
    512,
    ['arrows'],
    'f047',
    'M512 255.1c0 4.094-1.562 8.222-4.688 11.35l-80 80C424.2 350.4 420.1 352 416 352c-9.139 0-16-7.473-16-16c0-4.094 1.562-8.188 4.688-11.31L457.4 272H272v185.4l52.69-52.69C327.8 401.6 331.9 400 336 400c9.139 0 16 7.473 16 16c0 4.094-1.562 8.188-4.688 11.31l-80 80C264.2 510.4 260.1 512 256 512s-8.188-1.562-11.31-4.688l-80-80C161.6 424.2 160 420.1 160 416c0-8.527 6.865-16 16-16c4.094 0 8.188 1.562 11.31 4.688L240 457.4V272H54.63l52.69 52.69C110.4 327.8 112 331.9 112 336C112 344.5 105.1 352 96 352c-4.094 0-8.188-1.562-11.31-4.688l-80-80C1.563 264.2 0 260.1 0 255.1s1.562-8.153 4.688-11.28l80-80C87.81 161.6 91.91 160 96 160c9.139 0 16 7.473 16 16c0 4.094-1.562 8.188-4.688 11.31L54.63 240H240V54.63L187.3 107.3C184.2 110.4 180.1 112 176 112C166.9 112 160 104.5 160 96c0-4.094 1.562-8.188 4.688-11.31l80-80C247.8 1.562 251.9 0 256 0s8.188 1.562 11.31 4.688l80 80C350.4 87.81 352 91.91 352 96c0 8.527-6.865 16-16 16c-4.094 0-8.188-1.562-11.31-4.688L272 54.63V240h185.4l-52.69-52.69C401.6 184.2 400 180.1 400 176C400 167.5 406.9 160 416 160c4.094 0 8.188 1.562 11.31 4.688l80 80C510.4 247.8 512 251.9 512 255.1z',
  ],
};

export const faCircleDollar: IconDefinition = {
  prefix: 'fal',
  iconName: 'circle-dollar' as IconName,
  icon: [
    512,
    512,
    ['dollar-circle', 'usd-circle'],
    'f2e8',
    'M272 143C285.5 144 298.6 147.7 311.6 151.3C313.2 151.7 314.7 152.1 316.2 152.6C324.8 154.9 329.8 163.8 327.4 172.3C325.1 180.8 316.2 185.8 307.7 183.4C305.3 182.8 302.9 182.1 300.5 181.4C292.1 179.2 285.4 176.1 277.7 175.8C258.5 173 241 175.5 228.4 180.9C215.5 186.5 209.8 194 208.6 200.4C206.8 210.1 210.7 216.9 218.8 222.1C229.5 228.9 245.3 233.4 264.7 238.9L264.9 238.1C282.7 244 303.8 250 319.3 260.6C338.3 273.7 347 294.5 342.9 317.2C338.9 338.8 323.9 353.2 305 360.8C295.1 364.8 283.9 367.1 272 367.8L272 392C272 400.8 264.8 408 256 408C247.2 408 240 400.8 240 392L240 366.1C231.9 364.9 218.2 360.9 207.6 357.7C200.7 355.6 193.8 353.4 186.1 351.2C178.6 348.4 174 339.4 176.8 330.1C179.6 322.6 188.6 318 197 320.8C203.6 322.1 210.3 325.1 216.9 327.1C228.2 330.5 239.6 333.7 245 334.5C264.8 337.5 281.5 335.8 293.1 331.2C304.2 326.7 309.8 319.9 311.4 311.4C313.3 300.9 309.9 293 301.2 287C289.2 278.8 274.4 274.8 260.2 270.9C257.9 270.2 255.6 269.6 253.3 268.1C236.2 264.1 216.3 258.5 201.6 249C193.5 243.9 185.9 236.9 181.1 227.4C176.2 217.6 174.9 206.6 177.1 194.6C180.9 173.9 197.2 159.5 215.7 151.5C223.2 148.3 231.3 145.9 239.1 144.5V120C239.1 111.2 247.2 104 255.1 104C264.8 104 272 111.2 272 120V143zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z',
  ],
};

export const faArrowsRotate: IconDefinition = {
  prefix: 'fal',
  iconName: 'arrows-rotate' as IconName,
  icon: [
    512,
    512,
    ['arrows-rotate'],
    'f2e8',
    'M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z',
  ],
};
