import React from 'react';

import * as Mui from '@mui/material';
import NumberCard from 'shared/components/Card/NumberCard';
import colors from '../../../../../_colors.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers';

export type PhotoStatisticType = 'total' | 'percent';

interface IProps {
  selectedStatisticType: PhotoStatisticType;
  minimumTaggedPhotosPerChild: number;
  classPhotoCount?: IClassPhotoCount;
  loading: boolean;
  _class?: IClass;
  className?: string;
}
const ClassPhotoStatsCard: React.FC<IProps> = ({
  selectedStatisticType,
  minimumTaggedPhotosPerChild,
  classPhotoCount,
  loading,
  _class,
}) => {
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const currentCenter = useSelector((state: RootState) => state.centers.byId[currentCenterId ?? '']);
  const dailyCounts = classPhotoCount?.dailyPhotoCounts ?? [];
  const { t } = useTranslation(['translation', 'subsidies']);
  const totalPhotoCount = dailyCounts.reduce((sum, c) => sum + c.photoCount, 0);
  const avgChildCount = dailyCounts.reduce((sum, c) => sum + c.childCount, 0) / dailyCounts.length || 0;
  const avgChildrenWithMinPhotos =
    dailyCounts.reduce(
      (sum, c) => sum + c.childPhotoCounts.filter((cpc) => cpc.photoCount >= minimumTaggedPhotosPerChild).length,
      0
    ) / dailyCounts.length || 0;
  const percentage = avgChildrenWithMinPhotos / avgChildCount || 0;

  return (
    <>
      <NumberCard
        //@ts-ignore
        title={t(classPhotoCount?.class?.name)}
        description={currentCenter?.name ?? ''}
        color={colors.secondary}
        number={totalPhotoCount}
        loading={loading}
      />
    </>
  );
};

export default ClassPhotoStatsCard;
