import { useCallback, useEffect, useState } from 'react';
import { faCheckCircle, faExclamationTriangle, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { isValidPhoneNumber } from '../../util/string';
import { isRegion } from '../../util/region';
import colors from '../../../_colors.module.scss';

interface IInputHelperState {
  borderClass: 'success' | 'error' | 'focused' | '';
  icon: IconDefinition | null;
  iconColor: string | null;
}

const useValidPhoneInputForm = (
  required: boolean | undefined,
  phoneNotMobileNumber: boolean,
  value: string,
  isFocused: boolean,
  touched: boolean,
  isInvalid: boolean | undefined
) => {
  const isAURegion: boolean = isRegion('AU');
  const valid: boolean = isValidPhoneNumber(value);
  const [inputHelperState, setInputHelperState] = useState<IInputHelperState>({
    borderClass: '',
    icon: null,
    iconColor: null,
  });

  const errorInput = () => {
    setInputHelperState({
      borderClass: 'error',
      icon: faExclamationTriangle,
      iconColor: colors.danger,
    });
  };

  const checkValidInput = useCallback(() => {
    setInputHelperState({
      borderClass: !valid ? 'error' : 'success',
      icon: !valid ? faExclamationTriangle : faCheckCircle,
      iconColor: !valid ? colors.danger : colors.success,
    });
  }, [valid]);

  const AuValidation = useCallback(() => {
    const phoneExpression: RegExp =
      /^\({0,1}((0|\+61)(2|3|7|8|13)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;

    if (required && !valid && !phoneNotMobileNumber) {
      errorInput();
    } else if (phoneNotMobileNumber) {
      if (!value?.match(phoneExpression)) {
        errorInput();
      } else {
        setInputHelperState({
          borderClass: 'success',
          icon: faCheckCircle,
          iconColor: colors.success,
        });
      }
    } else {
      checkValidInput();
    }
  }, [required, valid, phoneNotMobileNumber]);

  useEffect(() => {
    if (isFocused) {
      setInputHelperState({
        borderClass: 'focused',
        icon: null,
        iconColor: null,
      });
    } else if (!required && touched && value === '') {
      setInputHelperState({
        borderClass: '',
        icon: null,
        iconColor: null,
      });
    } else if (touched) {
      if (isAURegion) {
        AuValidation();
      } else {
        if (required && !valid) {
          errorInput();
        } else {
          checkValidInput();
        }
      }
    }
  }, [touched, isInvalid, required, value, isFocused]);

  return { inputHelperState };
};

export default useValidPhoneInputForm;
