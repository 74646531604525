import React, { useCallback, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCreateContract,
  useCreateContractAndAccountBillingCycle,
  useEndAndCreateContract,
} from 'gql/contract/mutations';
import { useDispatch, useSelector } from 'react-redux';
import SideModalDrawer from 'shared/components/ModalDrawer';
import ContractInputs from './ContractInputs';
import * as actions from '../../../duck/actions';
import { showToast } from 'shared/components/Toast';
import cast from 'shared/util/cast';
import { omitTypename } from 'shared/util/object';
import { isNil, orderBy } from 'lodash';
import Select from 'shared/components/Select';
import Alert from 'shared/components/Alert';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useGetAccountAndBillingCycle } from 'gql/billingCycle/queries';
import { isRegion } from 'shared/util/region';
import { useGetClassesForCenter } from 'gql/class/queries';
import { isFeeScheduleInvalidWithStartDate, isValidContract } from './helpers/ContractValidation';
import { isBlank } from 'shared/util/string';
import { RootState } from 'store/reducers';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

const emptyContract: ICreateContractInput = {
  centerId: '',
  accountId: '',
  childId: '',
  classId: '',
  permanentFeeId: '',
  casualFeeId: '',
  originalFeeId: null,
  adjustmentId: null,
  startDate: '',
  endDate: '',
  cycleType: undefined,
  timeslots: [],
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  centerId: string;
  accountId: string;
  childId: string;
  previousContract?: IContract | null;
  existingContracts: IContract[];
  activeChildSubsidyEnrollments?: IAgencyAccountChildEnrollment[];
}

const CreateContractModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  centerId,
  accountId,
  childId,
  previousContract,
  existingContracts,
  activeChildSubsidyEnrollments,
}) => {
  const accountData: IAccount = useSelector((state: RootState) => state.accounts.byId[accountId]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasReadBillingCycleTemplatesPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.BillingCycleTemplates,
    level: RoleLevelType.Read,
  });
  const hasCreateAccountBillingCycleTemplate = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.AccountBillingCycles,
    level: RoleLevelType.Create,
  });

  const initialContractState = previousContract
    ? {
        ...emptyContract,
        classId: previousContract.reasonForEnding !== 'ROOM_CHANGE' ? previousContract.classId : '',
        permanentFeeId: previousContract.reasonForEnding !== 'ROOM_CHANGE' ? previousContract.permanentFeeId : '',
        cycleType: previousContract.cycleType,
        // Contracts set with the CUSTOM cycleType have timeslots and that are specific to itself
        // and should not be carried over to the new contract.
        timeslots:
          previousContract.cycleType == 'CUSTOM'
            ? []
            : cast<ICreateContractTimeslotInput[]>(previousContract.timeslots.map((ts) => omitTypename(ts, true))),

        centerId,
        accountId,
        childId,
      }
    : { ...emptyContract, centerId, accountId, childId };

  const [contract, setContract] = useState<ICreateContractInput>(initialContractState);

  // queries and data
  const { data: getClassesForCenterData, loading: classesLoading } = useGetClassesForCenter({
    variables: { centerId: contract.centerId },
    fetchPolicy: 'network-only',
  });

  const availableClasses = getClassesForCenterData?.getClassesForCenter.filter((c) => !c.archivedAt) ?? [];
  const selectedClass = availableClasses.find((c) => c.id === contract.classId);
  const availableFees = selectedClass ? selectedClass.fees.filter((f) => !f.deactivatedAt) : [];

  const [createContract, { loading: createLoading }] = useCreateContract({
    onCompleted: (data) => {
      dispatch(actions.createContract(data.createContract));
      showToast(t('enrollment.contracts.create-modal.create-contract-success-toast'), 'success');
      handleClose();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const [endAndCreateContract, { loading: endAndCreateLoading }] = useEndAndCreateContract({
    onCompleted: (data) => {
      dispatch(actions.createContract(data.endAndCreateContract));
      previousContract && dispatch(actions.updateContract(previousContract, data.endAndCreateContract));
      showToast(t('enrollment.contracts.create-modal.end-and-create-contract-success-toast'), 'success');
      handleClose();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const [createContractAndAccountBillingCycleFn, { loading: createContractAndAccountBillingCycleLoading }] =
    useCreateContractAndAccountBillingCycle({
      onCompleted: (data) => {
        dispatch(actions.createContract(data.createContractAndAccountBillingCycle.contract));
        dispatch(actions.setAccountBillingCycle(data.createContractAndAccountBillingCycle.billingCycle));
        showToast(t('enrollment.contracts.create-modal.create-contract-and-billing-cycle-success-toast'), 'success');
        handleClose();
      },
      onError: (error) => {
        showToast(getApolloErrorMessage(error), 'error');
      },
    });

  const { data: accountBillingData } = useGetAccountAndBillingCycle({
    variables: {
      accountId,
    },
  });

  const accountHasBillingCycle = accountBillingData?.getAccountById.billingCycle !== null;
  const selectedFee = selectedClass?.fees.find((f) => f.id === contract.permanentFeeId);
  const isContractStartDateFeeScheduleValid =
    !isBlank(contract?.startDate) &&
    !isNil(selectedFee) &&
    !isFeeScheduleInvalidWithStartDate(contract.startDate, selectedFee);

  const [isUsingStandardFee, setIsUsingStandardFee] = useState<boolean>(contract.originalFeeId ? true : false);
  const validationResults = isValidContract(
    contract,
    accountData,
    activeChildSubsidyEnrollments,
    selectedFee,
    isUsingStandardFee
  );

  const handleClose = useCallback(() => {
    onClose();
    setContract({ ...emptyContract, centerId, accountId, childId });
  }, [accountId, centerId, childId, onClose]);

  const handleSave = useCallback(() => {
    if (previousContract && previousContract.endDate && previousContract.reasonForEnding) {
      endAndCreateContract({
        variables: {
          input: {
            endedId: previousContract.id,
            endDate: previousContract.endDate,
            reasonForEnding: previousContract.reasonForEnding,
            newContract: contract as ICreateContractInput,
          },
        },
      });
    } else if (Boolean((contract as ICreateContractInput).billingCycle)) {
      const { billingCycle, ...createContractInput } = contract as ICreateContractInput;

      createContractAndAccountBillingCycleFn({
        variables: {
          input: {
            billingCycle: {
              ...((contract as ICreateContractInput).billingCycle as ICreateAccountBillingCycleInput),
              accountId: contract.accountId,
              startDate: contract.startDate,
              endDate: contract.endDate ?? null,
            },
            contract: createContractInput,
          },
        },
      });
    } else {
      createContract({
        variables: {
          input: contract as ICreateContractInput,
        },
      });
    }
  }, [contract, createContract, createContractAndAccountBillingCycleFn, endAndCreateContract, previousContract]);

  const getStandardFeeState = (checked: boolean): void => {
    setIsUsingStandardFee(checked);
  };

  return (
    <SideModalDrawer
      title={t('enrollment.contracts.create-modal.modal-title')}
      show={isOpen}
      onHide={handleClose}
      dialogClassName=""
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={handleSave}
      primaryButtonProps={{
        disabled: !validationResults.isValid,
        loading: createLoading || endAndCreateLoading || createContractAndAccountBillingCycleLoading,
      }}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      {isRegion('US') && !accountHasBillingCycle && (
        <Fragment>
          <Alert variant="warning" className="box-shadow-0 mb-4">
            <div>
              {t('enrollment.contracts.create-modal.require-billing-cycle')}
              {!hasCreateAccountBillingCycleTemplate && (
                <b> {t('enrollment.contracts.create-modal.missing-create-account-billing-cycle-permission')}</b>
              )}
            </div>
          </Alert>
          {hasCreateAccountBillingCycleTemplate && (
            <Select
              required
              label="Billing Cycle"
              options={orderBy(
                (accountBillingData?.getBillingCycleTemplates ?? []).filter((template) => !template.deactivatedAt),
                (template) => template.name.toLowerCase(),
                'asc'
              )}
              onChange={(option: IBillingCycleTemplate) =>
                setContract((prev) => ({
                  ...prev,
                  billingCycle: {
                    accountId: contract.accountId,
                    startDate: contract.startDate,
                    endDate: contract.endDate ?? null,
                    period: option.period,
                    frequency: option.frequency,
                    dayOfWeek: option.dayOfWeek ?? null,
                    dayOfMonth: option.dayOfMonth ?? null,
                    invoiceSchedule: { unit: option.invoiceSchedule.unit, value: option.invoiceSchedule.value },
                    additionalChargePeriod:
                      option.cycleType === 'PERIOD_BASED'
                        ? { unit: 'DAYS', value: 0 }
                        : {
                            unit: option.additionalChargePeriod?.unit ?? 'DAYS',
                            value: option.additionalChargePeriod?.value ?? 0,
                          },
                    transactionLimit: null,
                    isPaused: false,
                    billingCycleTemplateId: option.cycleType === 'PERIOD_BASED' ? option.id : null,
                  },
                }))
              }
              getOptionLabel={(option: IBillingCycleTemplate) => option.name}
              getOptionValue={(option: IBillingCycleTemplate) => option.id}
              noOptionsMessage={() =>
                !hasReadBillingCycleTemplatesPermission
                  ? 'You do not have permission to view billing cycle templates.'
                  : 'No billing cycle templates found.'
              }
            />
          )}
          <hr className="mx-auto mt-2 mb-4 horizontal-divider" />
        </Fragment>
      )}
      <ContractInputs
        contract={contract}
        updateContract={setContract}
        previousContract={previousContract}
        existingContracts={existingContracts}
        isLoadingClasses={classesLoading}
        availableClasses={availableClasses}
        selectedClass={selectedClass}
        availbleFees={availableFees}
        isContractStartDateFeeScheduleValid={isContractStartDateFeeScheduleValid}
        getStandardFeeState={getStandardFeeState}
        selectedFee={selectedFee}
        contractValidationResults={validationResults}
      />
    </SideModalDrawer>
  );
};

export default CreateContractModal;
