import { faCloudUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { ButtonAsLink } from '../Buttons';

interface IProps {
  multiple?: boolean;
  className?: string;
  onFilesAdded: (filesAddedEvent: React.ChangeEvent<HTMLInputElement>) => void;
  onDrop: (acceptedFiles: any) => void;
  acceptedFileExts?: string;
}

const FileDropbox: React.FC<IProps> = ({ multiple = true, className, onFilesAdded, onDrop, acceptedFileExts }) => {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, minSize: 1 });
  const openFileDialog = (): void => {
    fileInputRef.current && fileInputRef.current.click();
  };

  return (
    <div className={className}>
      <div
        className={
          'd-flex flex-row p-2 file-display dropzone justify-content-center align-items-center' +
          (isDragActive ? ' dropzone-active' : '')
        }
        {...getRootProps()}
      >
        <div className="align-items-center">
          <FontAwesomeIcon icon={faCloudUpload} />
          <span className="ml-2 text-center">
            Drag and Drop or{' '}
            <ButtonAsLink className="md text-primary" onClick={openFileDialog}>
              {' '}
              Browse{' '}
            </ButtonAsLink>{' '}
            to upload.
          </span>
          <input
            {...getInputProps()}
            ref={fileInputRef}
            className="fileInput"
            type="file"
            multiple={multiple}
            onChange={onFilesAdded}
            accept={acceptedFileExts}
          />
        </div>
      </div>
    </div>
  );
};

export default FileDropbox;
