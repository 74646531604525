type IFeeStructureRules = {
  /**
   * whether or not a feeStructure can have variable/multiple rate
   */
  canHaveVariableRates: boolean;

  /**
   * if a fee structure can have variable rates use this to limit how many rates they can setup. I am still not sure why this is, but that seemed to be the rule based on the code I found.
   */
  maxNumberOfRates?: number;

  /**
   * label for the rates qualifying number. Only matters if canHaveVariableRates is true
   * Keep these singular, component will pluralize if needed
   */
  qualifyingNumberLabel?: string;

  /**
   * allowed qualifying number options for a given feeStructure
   *
   * again why I do not know
   */
  availableQualifyingNumbersForRate?: number[];
};

interface IFeeTypeConfig {
  label: string;
}

export const FeeTypeConfigMap = new Map<FeeType, IFeeTypeConfig>([
  ['SESSION', { label: 'Session' }],
  ['FLAT_RATE', { label: 'Flat Rate' }],
]);

/**
 * Returns the label for a given feeType
 * defaults to the feeType enum string if not configured
 * @param feeType
 * @returns
 */
export function getLabelForFeeType(feeType: FeeType) {
  const feeTypeConfig = FeeTypeConfigMap.get(feeType);
  return feeTypeConfig?.label || feeType;
}

/**
 * Holds rules for known feeStructures
 */
const FeeStructureRuleMap = new Map<FeeStructure, IFeeStructureRules>();
FeeStructureRuleMap.set('SESSION', { canHaveVariableRates: false });
FeeStructureRuleMap.set('SINGLE_FLAT_RATE', { canHaveVariableRates: false });
FeeStructureRuleMap.set('NUMBER_OF_DAYS', {
  canHaveVariableRates: true,
  maxNumberOfRates: 7,
  qualifyingNumberLabel: 'Day',
  availableQualifyingNumbersForRate: [1, 2, 3, 4, 5, 6, 7],
}); //why these have limits I am not sure
FeeStructureRuleMap.set('NUMBER_OF_SIBLINGS', {
  canHaveVariableRates: true,
  maxNumberOfRates: 10,
  qualifyingNumberLabel: 'Sibling',
  availableQualifyingNumbersForRate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
}); //why these have limits I am not sure

/**
 * Because only certain fee structures can be used with a given fee type we need to reset the fee structure
 * in case it was set to an incompatible version
 * @param feeType
 * @returns
 */
export function defaultFeeStructureForFeeType(feeType: FeeType): FeeStructure {
  if (feeType === 'FLAT_RATE') {
    return 'SINGLE_FLAT_RATE';
  }
  return 'SESSION';
}

/**
 * Returns true if given feeStructure can have mulitple rates, false otherwise
 * @param feeStructure
 */
export function feeStructureCanHaveVariableRates(feeStructure: FeeStructure): boolean {
  const feeStructureRules = FeeStructureRuleMap.get(feeStructure);
  return !!feeStructureRules && feeStructureRules.canHaveVariableRates;
}

/**
 * Returns the available options for a rate as determined by the feeStructure.
 *
 * I am not entirely sure why these are limited in this way, but they are.
 * @param feeStructure
 * @returns
 */
export function getRateOptionsForFeeStructure(feeStructure: FeeStructure): number[] {
  const feeStructureRules = FeeStructureRuleMap.get(feeStructure);
  const rateOptions = feeStructureRules?.availableQualifyingNumbersForRate || [];
  return rateOptions;
}

/**
 * Returns the label for qualifying numbers on a rate given a feeStructure
 *
 * Defaults to ??? if no label was setup. This is to make it obvious a dev missed setting up the label
 * Previously there was just code like feeStructure === NUMBER_OF_DAYS ? "Days" : "Siblings"
 * Which could be easier to miss if we needed to add a new feeStructure
 * @param feeStructure
 * @returns
 */
export function getQualifyingNumberLabelForFeeStructure(feeStructure: FeeStructure): string {
  const structureRules = FeeStructureRuleMap.get(feeStructure);
  if (structureRules && structureRules.qualifyingNumberLabel) {
    return structureRules.qualifyingNumberLabel;
  }
  //returning this so hopefully it is obvious a label wasn't setup for this
  return '???';
}
