import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';
import { classCapacityOverrideFields, classClosuresFields } from './fields';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { showToast } from 'shared/components/Toast';

export interface IBulkUpsertClassCapacityOverrideVariables {
  input: {
    classId: string;
    capacity: number;
    date: string;
  }[];
}

export interface IBulkUpsertClassCapacityOverrideData {
  bulkUpsertClassCapacityOverride: IClassCapacityOverride[];
}

export const BULK_UPSERT_CLASS_CAPACITY_OVERRIDE = (fields: string) => gql`
  mutation($input:[UpsertClassCapacityOverrideInput!]!){
    bulkUpsertClassCapacityOverride(input:$input){
    ${fields}
  }  
}`;

export const useBulkUpsertCapacityOverride = (
  options?:
    | MutationHookOptions<IBulkUpsertClassCapacityOverrideData, IBulkUpsertClassCapacityOverrideVariables>
    | undefined,
  fields = classCapacityOverrideFields
) =>
  useMutation<IBulkUpsertClassCapacityOverrideData, IBulkUpsertClassCapacityOverrideVariables>(
    BULK_UPSERT_CLASS_CAPACITY_OVERRIDE(fields),
    {
      onError: (err) => {
        showToast(getApolloErrorMessage(err), 'error');
      },
      ...options,
    }
  );

export type IAddClassClosureInput = Omit<IClassClosure, 'id'> & {
  closureResolution: ClosureResolution;
};

interface IAddClassClosuresVariables {
  input: IAddClassClosureInput[];
}

interface IAddClassClosuresData {
  addClassClosures: IClassClosure[];
}

export const ADD_CLASS_CLOSURES = (fields: string) => gql`
    mutation($input:[ClassClosure!]!){
      addClassClosures(input:$input){
      ${fields}
    }  
  }`;

export const useAddClassClosures = (
  options?: MutationHookOptions<IAddClassClosuresData, IAddClassClosuresVariables> | undefined,
  fields = classClosuresFields
) =>
  useMutation<IAddClassClosuresData, IAddClassClosuresVariables>(ADD_CLASS_CLOSURES(fields), {
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
    ...options,
  });

interface IOpenClassOnDateVariables {
  input: {
    closureId: string;
    date: string;
  };
}

interface IOpenClassOnDateData {
  openClassOnDate?: boolean;
}

export const OPEN_CLASS_ON_DATE = gql`
  mutation ($input: OpenClassOnDateInput!) {
    openClassOnDate(input: $input)
  }
`;

export const useOpenClassOnDate = (
  options?: MutationHookOptions<IOpenClassOnDateData, IOpenClassOnDateVariables> | undefined
) =>
  useMutation<IOpenClassOnDateData, IOpenClassOnDateVariables>(OPEN_CLASS_ON_DATE, {
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
    ...options,
  });
