import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { absenceFields, removeSessionFields, sessionFields, timeEntryFields, editBulkFeeFields } from './fields';
import { useDispatch } from 'react-redux';
import { getTimeEntries } from 'pages/Attendance/duck/actions';

//interfaces
interface ICreateSessionData {
  createSession: ISession;
}

interface ICreateSessionVariables {
  input: ICreateSessionInput;
}

interface IUpdateSessionData {
  updateSession: ISession;
}

interface IUpdateSessionVariables {
  input: IUpdateSessionInput;
}

interface ICheckInData {
  checkIn: ICheckInOutData;
}

interface ICheckInVariables {
  input: ICheckInInput;
}

interface ICheckOutData {
  checkOut: ICheckInOutData;
}

interface ICheckOutVariables {
  input: ICheckOutInput;
}

interface IReportAbsenceVariables {
  input: {
    children: IReportAbsenceItem[];
  };
}

interface IReportAbsenceData {
  reportAbsence: {
    successful: IAbsence[];
    failed: {
      accountChildId: string;
      reason: string;
    }[];
  };
}

interface IReportAdditionalAbsenceVariables {
  input: {
    accountChildId: string | undefined;
    dates: string[];
    reason: AdditionalAbsenceReason;
    absenceDocumentHeld?: boolean;
  };
}

interface IReportBulkAdditionalAbsenceVariables {
  input: {
    accountChildIds: (string | undefined)[];
    dates: string[];
    reason: AdditionalAbsenceReason;
    absenceDocumentHeld?: boolean;
  };
}

interface IReportAdditionalAbsenceData {
  reportAdditionalAbsence: IAbsence[];
}

interface IReportBulkAdditionalAbsenceData {
  reportBulkAdditionalAbsence: IAbsence[];
}

interface IRemoveSessionVariables {
  input: {
    sessionId?: string;
    contractId?: string;
    date: string;
    reasonForLateChange?: string;
  };
}

interface IRemoveSessionData {
  voidSession: ISession;
}

interface IClearSessionData {
  clearSession: ISession;
}

interface IUpdateSessionEarlyLateFeesData {
  updateSessionEarlyLateFees: ISession[];
}

interface IUpdateSessionEarlyLateFeesVariables {
  input: {
    sessions: IUpdateSessionEarlyLateFeeSingleInput[];
  };
}

interface IDeleteSessionTimeEntryData {
  deleteSessionTimeEntry: IAttendanceTimeEntry;
}

interface IDeleteSessionTimeEntryVariables {
  input: {
    sessionId: string;
    timeEntryId: string;
  };
}

interface IWaiveAbsenceGapFeeInput {
  centerId: string;
  sessionId: string;
}

interface IWaiveAbsenceGapFeeVariables {
  input: IWaiveAbsenceGapFeeInput[];
}

interface IWaiveAbsenceGapFeeData {
  waiveAbsenceGapFee: IAbsence[];
}

//mutations
export const CHECK_IN = (fields: string = timeEntryFields) => gql`
  mutation ($input: CheckInInput!) {
    checkIn(input: $input) {
      successful {
        ${fields}
      }
      failed{
        accountChildId
        reason
      }
    }
  }
`;

export const CHECK_OUT = (fields: string = timeEntryFields) => gql`
  mutation ($input: CheckOutInput!) {
    checkOut(input: $input) {
      successful {
        ${fields}
      }
      failed{
        accountChildId
        reason
      }
    }
  }
`;

export const CREATE_SESSION = (fields: string = sessionFields) => gql`
  mutation($input: CreateSessionInput!) {
    createSession(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_SESSION = (fields: string = sessionFields) => gql`
  mutation($input: UpdateSessionInput!) {
    updateSession(input: $input) {
      ${fields}
    }
  }
`;

export const REPORT_ABSENCE = (fields: string = absenceFields) => gql`
  mutation($input: ReportAbsenceInput!) {
    reportAbsence(input: $input) {
      successful {
        ${fields}
      }
      failed {
        accountChildId
        reason
      }
    }
  }
`;

export const REPORT_ADDITIONAL_ABSENCE = (fields: string = absenceFields) => gql`
  mutation($input: ReportAdditionalAbsenceInput!) {
    reportAdditionalAbsence(input: $input) {
      ${fields}
    }
  }
`;

export const REPORT_BULK_ADDITIONAL_ABSENCE = (fields: string = absenceFields) => gql`
  mutation($input: ReportBulkAdditionalAbsenceInput!) {
    reportBulkAdditionalAbsence(input: $input) {
      ${fields}
    }
  }
`;

export const REMOVE_SESSION = (fields: string = removeSessionFields) => gql`
  mutation ($input: VoidSessionInput!) {
    voidSession(input: $input) {
      ${fields}
    }
  }
`;

export const CLEAR_SESSION = (fields: string = sessionFields) => gql`
  mutation ($id: ID!) {
    clearSession(id: $id) {
      ${fields}
    }
  }
`;

export const UPDATE_SESSION_EARLY_LATE_FEES = (fields: string = sessionFields) => gql`
  mutation($input: UpdateSessionsEarlyLateFeesInput!) {
    updateSessionEarlyLateFees(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_SESSION_TIME_ENTRY = (fields: string = timeEntryFields) => gql`
  mutation($input: DeleteSessionTimeEntryInput!) {
    deleteSessionTimeEntry(input: $input) {
      ${fields}
    }
  }
`;

export const WAIVE_ABSENCE_GAP_FEE = (fields: string = absenceFields) => gql`
  mutation($input:[WaiveAbsenceGapFeeInput!]!) {
    waiveAbsenceGapFee(input:$input){
      ${fields}
    }
  }
`;

//hooks
export const useCheckIn = (options?: MutationHookOptions<ICheckInData, ICheckInVariables>, fields?: string) => {
  const dispatch = useDispatch();

  return useMutation<ICheckInData, ICheckInVariables>(CHECK_IN(fields), {
    onCompleted: (data) => {
      dispatch(getTimeEntries(data.checkIn.successful ?? []));
    },
    ...options,
  });
};

export const useCreateSession = (
  options?: MutationHookOptions<ICreateSessionData, ICreateSessionVariables>,
  fields?: string
) =>
  useMutation<ICreateSessionData, ICreateSessionVariables>(CREATE_SESSION(fields), {
    ...options,
  });

export const useUpdateSession = (
  options?: MutationHookOptions<IUpdateSessionData, IUpdateSessionVariables>,
  fields?: string
) =>
  useMutation<IUpdateSessionData, IUpdateSessionVariables>(UPDATE_SESSION(fields), {
    ...options,
  });

export const useCheckOut = (options?: MutationHookOptions<ICheckOutData, ICheckOutVariables>, fields?: string) => {
  const dispatch = useDispatch();

  return useMutation<ICheckOutData, ICheckOutVariables>(CHECK_OUT(fields), {
    onCompleted: (data) => {
      dispatch(getTimeEntries(data.checkOut.successful ?? []));
    },
    ...options,
  });
};

export const useReportAbsence = (
  options?: MutationHookOptions<IReportAbsenceData, IReportAbsenceVariables>,
  fields?: string
) =>
  useMutation<IReportAbsenceData, IReportAbsenceVariables>(REPORT_ABSENCE(fields), {
    ...options,
  });

export const useReportAdditionalAbsence = (
  options?: MutationHookOptions<IReportAdditionalAbsenceData, IReportAdditionalAbsenceVariables>,
  fields?: string
) =>
  useMutation<IReportAdditionalAbsenceData, IReportAdditionalAbsenceVariables>(REPORT_ADDITIONAL_ABSENCE(fields), {
    ...options,
  });

export const useReportBulkAdditionalAbsence = (
  options?: MutationHookOptions<IReportBulkAdditionalAbsenceData, IReportBulkAdditionalAbsenceVariables>,
  fields?: string
) =>
  useMutation<IReportBulkAdditionalAbsenceData, IReportBulkAdditionalAbsenceVariables>(
    REPORT_BULK_ADDITIONAL_ABSENCE(fields),
    {
      ...options,
    }
  );

export const useRemoveSession = (
  options?: MutationHookOptions<IRemoveSessionData, IRemoveSessionVariables>,
  fields?: string
) =>
  useMutation<IRemoveSessionData, IRemoveSessionVariables>(REMOVE_SESSION(fields), {
    ...options,
  });

export const useClearSession = (options?: MutationHookOptions<IClearSessionData, { id: string }>, fields?: string) =>
  useMutation<IClearSessionData, { id: string }>(CLEAR_SESSION(fields), {
    ...options,
  });

export const useUpdateSessionEarlyLateFees = (
  options?: MutationHookOptions<IUpdateSessionEarlyLateFeesData, IUpdateSessionEarlyLateFeesVariables>,
  fields?: string
) =>
  useMutation<IUpdateSessionEarlyLateFeesData, IUpdateSessionEarlyLateFeesVariables>(
    UPDATE_SESSION_EARLY_LATE_FEES(fields),
    {
      ...options,
    }
  );

export const useDeleteSessionTimeEntry = (
  options?: MutationHookOptions<IDeleteSessionTimeEntryData, IDeleteSessionTimeEntryVariables>,
  fields?: string
) =>
  useMutation<IDeleteSessionTimeEntryData, IDeleteSessionTimeEntryVariables>(DELETE_SESSION_TIME_ENTRY(fields), {
    ...options,
  });

export const useWaiveAbsenceGapFee = (
  options?: MutationHookOptions<IWaiveAbsenceGapFeeData, IWaiveAbsenceGapFeeVariables>,
  fields?: string
) =>
  useMutation<IWaiveAbsenceGapFeeData, IWaiveAbsenceGapFeeVariables>(WAIVE_ABSENCE_GAP_FEE(fields), {
    ...options,
  });

interface IEditFeesVariables {
  input: IFeesInBulkEdit;
}

interface IEditFeesData {
  bulkEditFee: IEditFeeResult;
}

export const BULK_EDIT_FEE = (fields: string = editBulkFeeFields) => gql`
  mutation($input: EditFeesInput!) {
    bulkEditFee(input: $input) {
      ${fields}
    }
  }
`;

export const useBulkEditFee = (options?: MutationHookOptions<IEditFeesData, IEditFeesVariables>, fields?: string) =>
  useMutation<IEditFeesData, IEditFeesVariables>(BULK_EDIT_FEE(fields), options);
