import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isRegion } from 'shared/util/region';

export const useIsGlCodeMandatory = () => {
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const allEntities = useSelector((state: RootState) => state.entities.allEntities);
  const { k2GlCodeActivationCheck } = useFlags();
  const isAU = isRegion('AU');
  // AU will not see the GL CODE TextInput when k2GlCodeActivationCheck = false
  // So disable the mandatory validation for AU if k2GlCodeActivationCheck = false and isAU = true
  if (isAU && !k2GlCodeActivationCheck) return false;

  const isGlCodeMandatory = Boolean(allEntities?.[businessId ?? '']?.isGlCodeMandatory);
  return isGlCodeMandatory;
};

const useIsGlCodeInputValid = (
  legacyGlCodeValue: string | null,
  glCodeValue: IGLCodeMapping | null | undefined,
  // the hook will skip the validation of legacyGlValue if ignoreLegacyGlCodeValue is true
  ignoreLegacyGlCodeValue = false
) => {
  const { k2GlCodeActivationCheck } = useFlags();
  const isGlCodeMandatory = useIsGlCodeMandatory();

  const validateGlCodeInputFn = useCallback(
    (
      legacyGlValue: string | null,
      glCodeValue: IGLCodeMapping | null | undefined,
      ignoreLegacyGlCodeValue: boolean = false
    ) => {
      if (isGlCodeMandatory) {
        if (k2GlCodeActivationCheck) {
          return Boolean(glCodeValue);
        } else {
          return ignoreLegacyGlCodeValue ? true : Boolean(legacyGlValue);
        }
      } else {
        return true;
      }
    },
    [k2GlCodeActivationCheck, isGlCodeMandatory]
  );

  const isGlCodeInputValid = useMemo(
    () => validateGlCodeInputFn(legacyGlCodeValue, glCodeValue, ignoreLegacyGlCodeValue),
    [legacyGlCodeValue, glCodeValue, ignoreLegacyGlCodeValue, validateGlCodeInputFn]
  );

  return { isGlCodeMandatory, isGlCodeInputValid, validateGlCodeInputFn };
};

export default useIsGlCodeInputValid;
