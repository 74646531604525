import React, { useState, useCallback, useEffect, useMemo } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';

import { isBlank, capitalize } from 'shared/util/string';
import errorMessage from 'shared/constants/errorMessages';

import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';

import CustomFieldForm, { INewCustomFieldForm } from './CustomFieldForm';
import { useUpdateCustomField } from 'gql/customField/mutation';
import { parseOptions, trimAttributes } from 'shared/components/CustomField/utils';
import { ICustomField } from 'shared/types/customField';
import { useIsCustomFieldInUseByFormQuery } from 'generated/graphql';
import Alert from 'shared/components/Alert';

//IProps interface
interface IProps {
  isOpen: boolean;
  onClose: () => void;
  businessId: string;
  activeCustomField: ICustomField | null;
  refetch: () => void;
}

// Initial newCustomfieldObject values
const initialFormData: INewCustomFieldForm = {
  businessId: '',
  centerIds: [],
  label: '',
  area: '',
  displayOrder: 0,
  notForConsoleDisplay: false,
  type: '',
  attributes: '',
};

const UpdateCustomFieldModal: React.FC<IProps> = ({ isOpen, onClose, businessId, activeCustomField, refetch }) => {
  const [customFieldData, setCustomFieldData] = useState<INewCustomFieldForm>({
    ...initialFormData,
    businessId,
  });

  const { t } = useTranslation();

  const [updateCustomField, { loading: loading }] = useUpdateCustomField();
  const { data: useIsCustomFieldInUseByFormQueryData, loading: isCustomFieldInUseQueryLoading } =
    useIsCustomFieldInUseByFormQuery({
      fetchPolicy: 'no-cache',
      variables: {
        businessId,
        customFieldId: activeCustomField?.id!,
      },
      skip: typeof activeCustomField?.id === 'undefined',
    });

  const canMutateAreaOrType = useMemo(() => {
    return (useIsCustomFieldInUseByFormQueryData?.isCustomFieldInUseByForm?.length ?? 0) === 0;
  }, [useIsCustomFieldInUseByFormQueryData]);

  const handleInput = useCallback((name, value) => {
    setCustomFieldData((customFieldData) => ({
      ...customFieldData,
      [name]: value,
    }));
  }, []);

  useEffect(() => {}, [customFieldData]);

  const handleClose = useCallback(() => {
    onClose();
    setCustomFieldData({ ...initialFormData, businessId });
  }, [businessId, onClose]);

  const handleSave = useCallback(() => {
    updateCustomField({
      variables: {
        input: {
          id: activeCustomField?.id ?? '',
          businessId,
          centerIds: customFieldData.centerIds ?? [],
          displayOrder: customFieldData.displayOrder,
          label: customFieldData.label,
          notForConsoleDisplay: customFieldData.notForConsoleDisplay,
          attributes: trimAttributes(customFieldData.attributes),
          area: activeCustomField?.area !== customFieldData.area ? customFieldData.area : undefined,
          type: activeCustomField?.type !== customFieldData.type ? customFieldData.type : undefined,
        },
      },
    })
      .then((returnData) => {
        if (returnData.data) {
          showToast(t('custom-data-fields.edit-custom-data-field-success'), 'success');
          refetch();
          handleClose();
        }
      })
      .catch((err) => {
        showToast('There was an error.', 'error');
      });
  }, [updateCustomField, customFieldData, activeCustomField, handleClose]);

  const formIsValid: boolean =
    !isBlank(customFieldData.label) &&
    (customFieldData.centerIds?.length !== 0 || customFieldData.centerIds === null) &&
    !isBlank(customFieldData.area) &&
    !isBlank(customFieldData.type) &&
    (customFieldData.type === 'Combo' || customFieldData.type === 'Radio'
      ? !isBlank(customFieldData.attributes)
      : true);

  useEffect(() => {
    if (activeCustomField !== null)
      setCustomFieldData({
        businessId,
        label: activeCustomField.label,
        centerIds: activeCustomField.centerIds.length === 0 ? null : activeCustomField.centerIds,
        displayOrder: activeCustomField.displayOrder,
        attributes: parseOptions(activeCustomField?.attributesAsString ?? null).join('\n') ?? '',
        notForConsoleDisplay: activeCustomField.notForConsoleDisplay,
        type: activeCustomField.type,
        area: activeCustomField.area,
      });
    else setCustomFieldData(initialFormData);
  }, [activeCustomField]);

  return (
    <SideModalDrawer
      title={t('custom-data-fields.edit-custom-data-field')}
      show={isOpen}
      onHide={handleClose}
      primaryChoice={capitalize(t('spelling.update'))}
      primaryCallback={handleSave}
      secondaryCallback={handleClose}
      closeOnPrimaryCallback={false}
      primaryButtonProps={{ disabled: !formIsValid, loading: loading }}
      footerHelperText={formIsValid ? errorMessage.formRequirements : ''}
    >
      <CustomFieldForm
        handleInput={handleInput}
        formValues={customFieldData}
        isEdit
        disable={{ type: !canMutateAreaOrType, area: !canMutateAreaOrType }}
      />

      {!canMutateAreaOrType && (
        <Alert variant="info">
          <div>
            <span>{t('custom-data-fields.unable-to-change')}</span>
            <ul>
              {useIsCustomFieldInUseByFormQueryData?.isCustomFieldInUseByForm?.map((o) => (
                <li>{`${o?.formName}`}</li>
              ))}
            </ul>
          </div>
        </Alert>
      )}
    </SideModalDrawer>
  );
};

export default UpdateCustomFieldModal;
