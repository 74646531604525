import _ from 'lodash';
import { useGetTags } from 'pages/Businesses/subroutes/ManageTags/graphql/queries';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'shared/components/Select';
import { PREDICATES, SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { RootState } from 'store/reducers';

interface IProps {
  categories: TagCategory[];
  selectedTags: ITag[];
  setSelectedTags: (tags: ITag[]) => void;
  requiredTags?: TagCategory[];
  isMultiSelect?: boolean;
}

const TagsDropdown: React.FC<IProps> = ({
  categories,
  selectedTags,
  setSelectedTags,
  requiredTags,
  isMultiSelect = false,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'reporting']);
  const activeBusinessId = useSelector((state: RootState) => state.context.businessId);

  const { data: getTagsInUse, loading: loadingTags } = useGetTags(activeBusinessId ?? '');
  const groupedTags = useMemo(() => {
    if (getTagsInUse?.getEntity.tags) {
      return _.groupBy(getTagsInUse?.getEntity.tags, 'category');
    }
    return undefined;
  }, [getTagsInUse]);

  return (
    <>
      {groupedTags &&
        Object.keys(groupedTags)
          .filter((tagCategory) => categories.includes(tagCategory as TagCategory))
          .map((tagCategory) => {
            const category = tagCategory as TagCategory;
            const selectedTagsInCategory = selectedTags
              ?.filter((tag) => tag.category === category)
              .map((c) => ({ value: c.id, label: c.name }));
            const tagsInCategory = groupedTags[tagCategory];
            const tagOptions: ITableFilterOption[] = tagsInCategory.map((c) => ({
              value: c.id,
              label: c.name,
              searchExpression: {
                [SEARCH_EXPRESSIONS.TERM]: {
                  field: 'tagIds.keyword',
                  predicate: PREDICATES.CONTAINS,
                  value: c.id,
                },
              },
            }));
            return (
              <div key={tagCategory}>
                <Select
                  isClearable
                  className="flex-wrap"
                  required={requiredTags && requiredTags.includes(category)}
                  isMulti={isMultiSelect}
                  id={`${tagCategory}-tag-input`}
                  label={t(`reporting:readable-tag-category.${category}`)}
                  aria-label={`Select ${t(`reporting:readable-tag-category.${category}`)}`}
                  placeholder={'Select'}
                  value={selectedTagsInCategory}
                  options={tagOptions.sort((a, b) => (a.label > b.label ? 1 : -1))}
                  onChange={(option: any) =>
                    isMultiSelect
                      ? setSelectedTags(tagsInCategory.filter((t) => option.map((o) => o.value).includes(t.id)))
                      : setSelectedTags(
                          !(option === null || option === undefined)
                            ? tagsInCategory.filter((t) => t.id === option.value)
                            : []
                        )
                  }
                />
              </div>
            );
          })}
    </>
  );
};

export default TagsDropdown;
