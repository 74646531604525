import React, { useState, useCallback } from 'react';
import useUniqueId from 'shared/hooks/useUniqueId';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { ITextInputProps } from './TextInput';
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { IInputHelperState } from './Input';

interface IProps extends ITextInputProps {
  maxLength?: number;
}

const RatioInput: React.FC<IProps> = ({
  id,
  onChange,
  onFocus,
  onBlur,
  maxLength,
  autoFocus,
  isInvalid,
  helperText,
  required,
  label,
  className,
  children,
  value,
  disabled,
  ...props
}) => {
  const controlId = useUniqueId(id);
  const [touched, setTouched] = useState(false);
  const [isFocused, setFocus] = useState(autoFocus);
  const [isRatioValid, setIsRatioValid] = useState(false);
  const [inputHelperState, setInputHelperState] = useState<IInputHelperState>({
    borderClass: '',
    icon: null,
    iconColor: null,
  });

  const errorText = 'Ratios should follow the format of teachers to children (2:7).';

  const validateRatio = useCallback((teachers: string, children: string) => {
    setIsRatioValid(teachers.length > 0 && children.length > 0);
  }, []);

  const handleChange = useCallback(
    (e) => {
      const ratios = e.target.value.split(':');
      validateRatio(ratios[0], ratios[1]);
      onChange && onChange(e.target.value, e.target.name);
      setTouched(true);
    },
    [onChange, validateRatio]
  );

  const handleFocus = useCallback(
    (e) => {
      setFocus(true);
      onFocus && onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      setTouched(true);
      setFocus(false);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  React.useEffect(() => {
    if (touched && !isRatioValid && isFocused) {
      setInputHelperState({
        borderClass: 'error',
        icon: faExclamationTriangle,
        iconColor: colors.danger,
      });
    } else if (touched && isRatioValid) {
      setInputHelperState({
        borderClass: isFocused ? 'success' : '',
        icon: isFocused ? faCheckCircle : null,
        iconColor: isFocused ? colors.success : null,
      });
    }
  }, [touched, isRatioValid, required, value, isFocused]);

  return (
    <Form.Group controlId={controlId} className={`${props.small ? 'small-input' : ''} ${className || ''}`}>
      <div className="d-flex flex-row">
        {label && <Form.Label>{label}</Form.Label>}
        {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
      </div>
      <div className="form-control__container">
        <div
          className={`d-flex flex-row align-items-center form-control__container_inner ${
            inputHelperState.borderClass && 'form-control__container-' + inputHelperState.borderClass
          } ${disabled && 'disabled'}`}
        >
          <div className="form-control__input_container">
            <MaskedInput
              {...props}
              maxLength={maxLength}
              placeholder="1:5"
              mask={createNumberMask({
                decimalSymbol: ':',
                includeThousandsSeparator: false,
                prefix: '',
                allowDecimal: true,
                requireDecimal: true,
                decimalLimit: 2,
                integerLimit: 1,
              })}
              className="form-control"
              guide={true}
              value={value === null ? undefined : value}
              required={required}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              autoFocus={autoFocus}
              autoComplete="off"
            />
          </div>
          {required && inputHelperState.icon && (
            <div className="d-flex input-icon-container">
              <FontAwesomeIcon
                className="input-icon"
                icon={inputHelperState.icon}
                color={`${inputHelperState.iconColor}`}
              />
            </div>
          )}
        </div>
      </div>
      {helperText && (
        <Collapse in={isFocused}>
          <div className="text-muted">{helperText}</div>
        </Collapse>
      )}
      {!isRatioValid && touched && <div className="error-text">{errorText}</div>}
    </Form.Group>
  );
};

export default RatioInput;
