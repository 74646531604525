import React from 'react';
import Card from 'shared/components/Card';
import { Row } from 'shared/components/Layout';
import { Box } from 'shared/components/LoadingSkeletons';

interface IProps {
  title: string;
  description: string;
  number?: number;
  color: string;
  onClick: () => void;
  loading?: boolean;
}

const ManageStaffCard: React.FC<IProps> = ({ title, description, number, color, onClick, loading = false }) => {
  return (
    <Card bodyClassName="py-4 px-6" onClick={onClick}>
      <Row justify="between" noGutters>
        <div>
          <h2 className={`sans-serif text-${color}`}>{title}</h2>
          <small>{description}</small>
        </div>
        {loading ? (
          <Box height={100} width={80} />
        ) : (
          <div className={`h1 font-weight-light text-${color}`} style={{ fontSize: 80 }}>
            {number === 0 ? 0 : number?.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
          </div>
        )}
      </Row>
    </Card>
  );
};

export default ManageStaffCard;
