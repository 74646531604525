import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import businessFields from './fields';
import { getPersonnel, getProviders } from 'pages/Businesses/subroutes/BusinessProfile/duck/actions';
import { useDispatch } from 'react-redux';

interface IGetEntityData {
  getEntity: IBusiness;
}

interface IGetEntityVariables {
  id: string;
}

interface IGetAllowedEntitiesData {
  getAllowedEntities: IBusiness[];
}

interface IGetAllowedEntitiesVariables {}

interface IGetPersonnelForBusinessData {
  getPersonnelByBusinessId: ICcssPersonnel[];
}

interface IGetPersonnelForBusinessVariables {
  businessId: string;
  role?: string;
}

interface IGetProvidersForBusinessData {
  getProvidersByBusinessId: IProvider[];
}

interface IGetProvidersForBusinessVariables {
  businessId: string;
}

interface IGetLinkedCentersData {
  getLinkedCenters: {
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: ILinkedCenterDetail[];
  };
}

interface IGetLinkedCentersVariables {
  providerId: string;
  businessId: string;
  input: IPaginateInput;
}

export const GET_ENTITY = (fields: string = businessFields) => gql`
  query ($id: String!) {
    getEntity(id: $id) {
      ${fields}
    }
  }
`;

export const GET_ALLOWED_ENTITIES = (fields: string = businessFields) => gql`
  query {
    getAllowedEntities {
      ${fields}
    }
  }
`;

export const GET_PERSONNEL_FOR_BUSINESS = gql`
  query ($businessId: ID!, $role: String) {
    getPersonnelByBusinessId(businessId: $businessId, role: $role) {
      id
      firstName
      lastName
      providerRoles {
        id
        endDate
        position
        positionText
        startDate
      }
      serviceRoles {
        id
        endDate
        position
        positionText
        startDate
        serviceId
      }
    }
  }
`;

export const GET_PROVIDERS_FOR_BUSINESS = gql`
  query ($businessId: ID!) {
    getProvidersByBusinessId(businessId: $businessId) {
      id
      businessId
      name
      providerId
      organisationId
      communicationsPersonId
      deviceName
      otac
      deviceExpiry
      commencementDate
    }
  }
`;

export const GET_LINKED_CENTER_DETAILS = gql`
  query ($providerId: ID!, $businessId: ID!, $input: PaginateInput!) {
    getLinkedCenters(providerId: $providerId, businessId: $businessId, input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        ccssServiceId
        ccssServiceName
        centerName
      }
    }
  }
`;

export const useGetEntity = (options?: QueryHookOptions<IGetEntityData, IGetEntityVariables>, fields?: string) =>
  useQuery<IGetEntityData, IGetEntityVariables>(GET_ENTITY(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetAllowedEntities = (
  options?: QueryHookOptions<IGetAllowedEntitiesData, IGetAllowedEntitiesVariables>,
  fields?: string
) =>
  useQuery<IGetAllowedEntitiesData, IGetAllowedEntitiesVariables>(GET_ALLOWED_ENTITIES(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetPersonnelByBusinessId = (
  options: QueryHookOptions<IGetPersonnelForBusinessData, IGetPersonnelForBusinessVariables>
) => {
  const dispatch = useDispatch();

  return useQuery<IGetPersonnelForBusinessData, IGetPersonnelForBusinessVariables>(GET_PERSONNEL_FOR_BUSINESS, {
    skip: !options?.variables?.businessId,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      dispatch(getPersonnel(data.getPersonnelByBusinessId));
    },
    ...options,
  });
};

export const useGetProvidersByBusinessId = (
  options?: QueryHookOptions<IGetProvidersForBusinessData, IGetProvidersForBusinessVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();

  return useQuery<IGetProvidersForBusinessData, IGetProvidersForBusinessVariables>(GET_PROVIDERS_FOR_BUSINESS, {
    skip: !options?.variables?.businessId,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      dispatch(getProviders(data.getProvidersByBusinessId));
    },
    ...options,
  });
};

export const useGetLinkedCenters = (options?: QueryHookOptions<IGetLinkedCentersData, IGetLinkedCentersVariables>) => {
  return useQuery<IGetLinkedCentersData, IGetLinkedCentersVariables>(GET_LINKED_CENTER_DETAILS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
};
