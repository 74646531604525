import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { timeEntryFields } from 'gql/timeEntry/fields';

interface IGetCenterOperationHoursData {
  getCenter: ICenter;
}

interface IGetAttendanceVariables {
  startDate: string;
  endDate: string;
  centerId?: string;
  personId?: string;
}

export const GET_ACTIVE_EMPLOYEES = gql`
  query ($input: SearchInput!) {
    searchStaff(input: $input) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
      }
    }
  }
`;

export const GET_ACTIVE_STAFF_OPTIONS = gql`
  query ($input: SearchInput!) {
    searchStaff(input: $input) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        roleship {
          name
        }
        primaryCenterId
      }
    }
  }
`;

export const GET_CENTER_BUSINESS_OPERATION_HOURS = gql`
  query ($id: String!) {
    getCenter(id: $id) {
      id
      staffOperationHours {
        dayOfWeek
        startTime
        endTime
      }
    }
  }
`;

export const GET_ATTENDANCE = gql`
  query($input: GetAttendanceInput!){
    getAttendance(input: $input){
      centerId
      personId
      person {
        id
        firstname
        lastname
        nickname
        avatar{
          url
        }
      }
      days {
        date
        timeEntries {
          ${timeEntryFields}
        }
        shifts {
          id
          startTime
          endTime
          breakMinutes
          position {
            id
            title
          }
        }
        totalHoursScheduled {
          hours
          minutes
        }
        totalHoursWorked {
          hours
          minutes
        }
        trainingTimeEvents {
          id
          type
          date
          hours
        }
      }
      totalHoursScheduled{
        hours
        minutes
      }
      totalHoursWorked {
        hours
        minutes
      }
    }
  }
`;

export const GET_ATTENDANCE_WITH_ARCHIVED_ENTRIES = gql`
  query($input: GetAttendanceInput!){
    getAttendanceWithArchivedEntries(input: $input){
      centerId
      personId
      person {
        id
        firstname
        lastname
        nickname
        avatar{
          url
        }
      }
      days {
        date
        timeEntries {
          ${timeEntryFields}
        }
        shifts {
          id
          startTime
          endTime
          breakMinutes
          position {
            id
            title
          }
        }
        totalHoursScheduled {
          hours
          minutes
        }
        totalHoursWorked {
          hours
          minutes
        }
        trainingTimeEvents {
          id
          type
          date
          hours
        }
      }
      totalHoursScheduled{
        hours
        minutes
      }
      totalHoursWorked {
        hours
        minutes
      }
    }
  }
`;

export const useGetAttendance = (input: IGetAttendanceVariables) =>
  useQuery<{ getAttendance: IAttendance[] }, { input: IGetAttendanceVariables }>(GET_ATTENDANCE, {
    variables: { input },
  });

export const useGetAttendanceWithArchivedEntries = (input: IGetAttendanceVariables) =>
  useQuery<{ getAttendanceWithArchivedEntries: IAttendance[] }, { input: IGetAttendanceVariables }>(
    GET_ATTENDANCE_WITH_ARCHIVED_ENTRIES,
    {
      variables: { input },
    }
  );

export const useGetCenterBusinessOperationHours = (id: string) =>
  useQuery<IGetCenterOperationHoursData, { id: string }>(GET_CENTER_BUSINESS_OPERATION_HOURS, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    skip: id === '',
  });
