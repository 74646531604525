import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import { useCreateAdjustment, useUpdateAdjustment } from 'gql/adjustment/mutations';
import { showToast } from 'shared/components/Toast';
import GlCodeInput from 'shared/components/GlCodeInput';
import { omitTypename } from 'shared/util/object';
import useIsGlCodeInputValid from 'shared/hooks/useIsGlCodeInputValid';

interface IAdjustmentFormShape {
  adjustmentType: AdjustmentType | null;
  name: string;
  glCode: string;
  glCodeMapping?: IGLCodeMapping | null;
}

interface IProps {
  isOpen: boolean;
  adjustment: IAdjustment | null;
  businessId: string;
  onClose: () => void;
  onCreateAdjustment: (adjustment: IAdjustment) => void;
  onEditAdjustment: (adjustment: IAdjustment) => void;
}

const AdjustmentModal: React.FC<IProps> = ({
  isOpen,
  adjustment,
  businessId,
  onClose,
  onCreateAdjustment,
  onEditAdjustment,
  ...props
}) => {
  const { t } = useTranslation(['billing']);
  const [formData, setFormData] = useState<IAdjustmentFormShape>({
    adjustmentType: adjustment?.type ?? null,
    name: adjustment?.name ?? '',
    glCode: adjustment?.glCode ?? '',
    glCodeMapping: adjustment?.glCodeMapping ?? null,
  });

  const [createAdjustmentFn, { loading: createAdjustmentLoading }] = useCreateAdjustment({
    onCompleted: (result) => {
      handleClose();
      onCreateAdjustment(result.createAdjustment);
      showToast(t('billing:adjustments.create-success-toast'), 'success');
    },
    onError: (err) => {
      showToast(t('billing:adjustments.create-error-toast'), 'error');
    },
  });

  const [updateAdjustmentFn, { loading: updateAdjustmentLoading }] = useUpdateAdjustment({
    onCompleted: (result) => {
      handleClose();
      onEditAdjustment(result.updateAdjustment);
      showToast(t('billing:adjustments.edit-success-toast'), 'success');
    },
    onError: (err) => {
      showToast(t('billing:adjustments.edit-error-toast'), 'error');
    },
  });

  const handleClose = useCallback(() => {
    setFormData({
      adjustmentType: null,
      name: '',
      glCode: '',
      glCodeMapping: null,
    });
    onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    if (adjustment) {
      updateAdjustmentFn({
        variables: {
          input: {
            id: adjustment.id,
            name: formData.name,
            glCode: formData.glCode,
            glCodeMapping: formData.glCodeMapping && omitTypename(formData.glCodeMapping),
          },
        },
      });
    } else {
      createAdjustmentFn({
        variables: {
          input: {
            businessId,
            type: formData.adjustmentType as AdjustmentType,
            name: formData.name,
            glCode: formData.glCode,
            glCodeMapping: formData.glCodeMapping,
          },
        },
      });
    }
  }, [formData, adjustment, businessId, createAdjustmentFn, updateAdjustmentFn]);

  const { isGlCodeMandatory, isGlCodeInputValid } = useIsGlCodeInputValid(formData.glCode, formData.glCodeMapping);

  const isFormDisabled = !formData.adjustmentType || !formData.name || !isGlCodeInputValid;

  return (
    <SideModalDrawer
      title={adjustment !== null ? t('billing:adjustments.edit-title') : t('billing:adjustments.new-title')}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      secondaryChoice="Cancel"
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: isFormDisabled, loading: createAdjustmentLoading || updateAdjustmentLoading }}
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <Row>
        <Col>
          <Form.Group>
            <div className="d-flex flex-row">
              <Form.Label>{t('billing:adjustments.type-label')}</Form.Label>
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
            <Form.Check
              inline
              label={t('billing:adjustments.fee-type')}
              name="action-group"
              type="radio"
              checked={formData.adjustmentType === 'FEE'}
              onChange={(event) => setFormData((prev) => ({ ...prev, adjustmentType: 'FEE' }))}
              disabled={adjustment !== null}
            />
            <Form.Check
              inline
              label={t('billing:adjustments.subsidy-type')}
              name="action-group"
              type="radio"
              checked={formData.adjustmentType === 'SUBSIDY'}
              onChange={(event) => setFormData((prev) => ({ ...prev, adjustmentType: 'SUBSIDY' }))}
              disabled={adjustment !== null}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            required
            label={t('billing:adjustments.name-label')}
            value={formData.name}
            onChange={(value) => setFormData((prev) => ({ ...prev, name: value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <GlCodeInput<IAdjustmentFormShape>
            required={isGlCodeMandatory}
            areaType="REASON_TYPE"
            legacyValue={formData.glCode}
            glCodeMapping={formData.glCodeMapping}
            updateFn={setFormData}
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default AdjustmentModal;
