import React, { ElementType } from 'react';
import BootrapDropdown, { DropdownProps } from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import useUniqueId from 'shared/hooks/useUniqueId';

const Menu = BootrapDropdown.Menu;
const MenuItem = BootrapDropdown.Item;

interface IProps extends DropdownProps {
  title?: string;
  id?: string;
  showCarotIcon?: boolean;
  className?: string;
  // TODO: Fix this
  as?: any;
}

const Dropdown: React.FC<IProps> = ({
  title,
  id = 'dropdown',
  showCarotIcon = true,
  className,
  children,
  as,
  ...rest
}) => {
  const dropdownId = useUniqueId(id);
  return (
    <BootrapDropdown as={as} className={className} {...rest}>
      <BootrapDropdown.Toggle as={as} id={dropdownId} className="d-flex align-items-center k2-dropdown">
        {title}
        {children}
        {showCarotIcon && (
          <span className="pl-4">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </BootrapDropdown.Toggle>
    </BootrapDropdown>
  );
};

export default Dropdown;
export { Menu, MenuItem };
