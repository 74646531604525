import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { healthProfessionalFields } from './fields';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IUpsertHealthProfessionalData {
  upsertHealthProfessional: IHealthProfessional;
}

interface IDeleteHealthProfessionalData {
  deleteHealthProfessional: IHealthProfessional;
}

interface IUpsertHealthProfessionalVariables {
  input: {
    professionalType: string;
    firstName: string;
    lastName: string;
    practice: string;
    phoneNumber: string;
    address: IAddress;
    accountChildIds: string[];
    businessId: string;
  };
}

interface IDeleteHealthProfessionalVariables {
  id: string;
}

export const UPSERT_HEALTH_PROFESSIONAL = (fields: string = healthProfessionalFields) => gql`
  mutation($input: UpsertHealthProfessionalInput!) {
    upsertHealthProfessional(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_HEALTH_PROFESSIONAL = (fields: string = healthProfessionalFields) => gql`
  mutation($id: ID!) {
    deleteHealthProfessional(healthProfessionalId: $id) {
      ${fields}
    }
  }
`;

export const useUpsertHealthProfessional = (
  options?: MutationHookOptions<IUpsertHealthProfessionalData, IUpsertHealthProfessionalVariables>,
  fields?: string
) => {
  return useMutation<IUpsertHealthProfessionalData, IUpsertHealthProfessionalVariables>(
    UPSERT_HEALTH_PROFESSIONAL(fields),
    {
      onError: (err) => {
        showToast(getApolloErrorMessage(err), 'error');
      },
      ...options,
    }
  );
};

export const useDeleteHealthProfessional = (
  options?: MutationHookOptions<IDeleteHealthProfessionalData, IDeleteHealthProfessionalVariables>,
  fields?: string
) => {
  return useMutation<IDeleteHealthProfessionalData, IDeleteHealthProfessionalVariables>(
    DELETE_HEALTH_PROFESSIONAL(fields),
    {
      onError: (err) => {
        showToast(getApolloErrorMessage(err), 'error');
      },
      ...options,
    }
  );
};
