import Auth from '@aws-amplify/auth';
import { put, takeLatest } from 'redux-saga/effects';
import * as types from './types';
import * as actions from './actions';

function* recoverPassword({ email }) {
  try {
    const data = yield Auth.forgotPassword(email);
    yield put(actions.recoverPasswordSuccess(data.CodeDeliveryDetails));
  } catch (error) {
    yield put(actions.recoverPasswordFailure(error));
  }
}

function* createNewPassword({ email, code, newPassword }) {
  try {
    yield Auth.forgotPasswordSubmit(email, code, newPassword);
    yield put(actions.createNewPasswordSuccess());
  } catch (error) {
    yield put(actions.createNewPasswordFailure(error));
  }
}

export const recoverPasswordSagas = [
  takeLatest(types.RECOVER_PASSWORD_REQUEST, recoverPassword),
  takeLatest(types.CREATE_NEW_PASSWORD_REQUEST, createNewPassword),
];
