import { capitalize, useMediaQuery } from '@mui/material';
import theme from 'muiTheme';
import React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SizePerPage, TableHeader } from 'shared/components/DataTable';
import DateInput from 'shared/components/DateInput';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import GroupedFilters from 'shared/components/GroupedFilters';
import SearchInput from 'shared/components/SearchInput';
import { FiltersAndOptions } from '../../../ReEnrollAccounts/components/ReEnrollAccountsTableHeader/ReEnrollAccountsTableHeader';

interface IProps {
  searchText?: string;
  setSearchText: (text: string) => void;
  paginationProps: any;
  handleFiltersSelect: (values: ITableFilterOption[], target: string) => void;
  handleClearFilters: () => void;
  centerFilters: ITableFilterOption[];
  centerOptions: ITableFilterOption[];
  tagFilters: ITableFilterOption[];
  tagOptions: ITableFilterOption[];
  statusFilters: ITableFilterOption[];
  statusOptions: ITableFilterOption[];
  correspondenceTypeFilter: ITableFilterOption[];
  correspondenceTypeOptions: ITableFilterOption[];
  fromDate: string | undefined;
  setFromDate: (date: string) => void;
  toDate: string | undefined;
  setToDate: (date: string) => void;
}

const ReEnrollHistoryTableHeader: React.FC<IProps> = ({
  searchText,
  setSearchText,
  paginationProps,
  handleFiltersSelect,
  handleClearFilters,
  centerFilters,
  centerOptions,
  tagFilters,
  tagOptions,
  statusFilters,
  statusOptions,
  correspondenceTypeFilter,
  correspondenceTypeOptions,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const totalFiltersOn =
    (statusFilters.length > 0 ? 1 : 0) +
    (correspondenceTypeFilter.length > 0 ? 1 : 0) +
    (fromDate === undefined ? 0 : 1) +
    (toDate === undefined ? 0 : 1);

  return (
    <TableHeader className="flex-wrap justify-content-between">
      <div className="d-flex">
        <SizePerPage paginationProps={paginationProps} />
        <SearchInput onChange={setSearchText} className={'max-width-200'} />
      </div>
      <div className="d-flex">
        <DropdownFilter
          className={isMobile ? 'mt-2 ml-3' : 'my-0 ml-2 p-0'}
          title={capitalize(t('translation:spelling.center'))}
          selectedFilters={centerFilters}
          options={centerOptions}
          onFilterSelect={(val) => handleFiltersSelect(val, 'center')}
        />
        <DropdownFilter
          className={isMobile ? 'mt-2 ml-3' : 'my-0 ml-2 p-0'}
          title={`${capitalize(t('translation:spelling.center'))} Tags`}
          selectedFilters={tagFilters}
          options={tagOptions}
          onFilterSelect={(val) => handleFiltersSelect(val, 'tag')}
        />
        <GroupedFilters totalFilters={totalFiltersOn} handleClearFilters={handleClearFilters}>
          <Col xs={6}>
            <DateInput date={fromDate} onDateSelect={setFromDate} placeholder="Requests From" required={false} />
          </Col>
          <Col xs={6}>
            <DateInput date={toDate} onDateSelect={setToDate} placeholder="Requests To" required={false} />
          </Col>
          <Col xs={6}>
            <DropdownFilter
              className={isMobile ? 'mt-2 ml-2' : 'my-0 ml-auto p-0'}
              title="Status"
              selectedFilters={statusFilters}
              options={statusOptions}
              onFilterSelect={(val) => handleFiltersSelect(val, 'status')}
            />
          </Col>
          <Col xs={6}>
            <DropdownFilter
              className={isMobile ? 'mt-2 ml-2' : 'my-0 ml-auto p-0'}
              title={t('enrollment:reenroll-correspondence-type-filter')}
              selectedFilters={correspondenceTypeFilter}
              options={correspondenceTypeOptions}
              onFilterSelect={(val) => handleFiltersSelect(val, 'correspondenceType')}
            />
          </Col>
        </GroupedFilters>
      </div>
    </TableHeader>
  );
};

export default ReEnrollHistoryTableHeader;
