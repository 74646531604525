import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { faDollarSign, faTimes, faUndo, faBadgePercent } from '@fortawesome/pro-light-svg-icons';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from 'store/reducers';
import DataTable, { TABLE_DEFAULTS } from 'shared/components/DataTable';
import TransactionExpandedRow from './TransactionExpandedRow';
import { TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { debounce, orderBy, sortBy } from 'lodash';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import {
  convertTimeRangeObjectToString,
  convertTimeRangeStringToObject,
  timeRangeOptions,
} from 'shared/util/timeUtils';
import ReverseTransactionModal from './ReverseTransactionModal';
import colors from '_colors.module.scss';
import useFormatDate from 'shared/hooks/useFormatDate';
import moment from 'moment';
import { PaymentStatusTag } from 'shared/components/Tag';
import PaymentStatusFilterCard from './PaymentStatusFilterCard';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import Button, { IconButtonCircle } from 'shared/components/Buttons';
import { isRegion } from 'shared/util/region';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { setCurrentBusinessFilters, setCurrentCenterFilters } from 'store/context/actions';
import { useGetPaginatedTransactionsInTimeframe, useGetAllowedTransactionTypes } from 'gql/transaction/queries';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getTransactions } from '../duck/action';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Currency from 'shared/components/Currency';
import { Box, CircularProgress, CircularProgressProps, Icon, Paper } from '@mui/material';
import Alert from 'shared/components/Alert';
import axios from 'axios';
import config from 'config';

const paymentStatusFilters: PaymentStatus[] = ['UNSUBMITTED', 'PENDING', 'FAILED', 'COMPLETED', 'FLAGGED'];

interface IProps {
  dateRange: ITimeRange;
  setDateRange: (tr: ITimeRange) => void;
  isAccountView?: boolean;
  isPaymentTransactionsOnly?: boolean;
  approvePaymentLoading?: boolean;
  onApproveFlaggedPayment?: (payment: IPayment) => void;
  onRejectFlaggedPayment?: (payment: IPayment) => void;
  /**
   * bubble filter changes back up to the parent so that it can be passed to the generate report modal
   */
  onTransactionTypeFilter: (transactionTypeIds: string[]) => void;
  onAddDiscountToTransaction?: (transaction: ITransaction) => void;
  onRemoveDiscountFromTransaction?: (transaction: ITransaction, discountTransaction: ITransaction) => void;
}

type TransactionCategory = 'manual' | 'automated' | 'attendance';
const automatedTransactionTypeNames = ['Automated CC', 'Automated ACH/DD'];
const attendanceTransactionTypeNames = ['Session Fee', 'Early Fee', 'Late Fee', 'Discount', 'Subsidy'];

export const getTransactionCategory: (t: ITransaction) => TransactionCategory = (t) => {
  if (!t.transactionType.centerId && !t.transactionType.businessId) {
    if (automatedTransactionTypeNames.includes(t.transactionType.name)) {
      return 'automated';
    } else {
      return attendanceTransactionTypeNames.includes(t.transactionType.name) ? 'attendance' : 'manual';
    }
  } else {
    return 'manual';
  }
};

const isTransactionFlagged: (t: ITransaction) => boolean = (t) =>
  Boolean(t.payment?.flags.length && t.payment.approvedAt === null && t.payment.rejectedAt === null);

const TransactionsTable: React.FC<IProps> = ({
  dateRange,
  setDateRange,
  isAccountView = false,
  isPaymentTransactionsOnly = false,
  approvePaymentLoading = false,
  onApproveFlaggedPayment,
  onRejectFlaggedPayment,
  onTransactionTypeFilter,
  onAddDiscountToTransaction,
  onRemoveDiscountFromTransaction,
  ...props
}) => {
  const { bulkTransactions: canUseBulkTransactions } = useFlags();
  const dispatch = useDispatch();
  const location = useLocation();
  const defaultStatus = queryString.parse(location.search).status;
  const user = useSelector((state: RootState) => state.user);
  const { businessId, businessFilterIds, centerFilterIds } = useSelector((state: RootState) => state.context);
  const tableData = useSelector((state: RootState) => state.billing.transactions.tableData);

  const { data: businessesData } = useGetAllowedEntities();
  const { data: centers } = useGetActiveCentersWithLoading();
  const { data: getTransactionTypesData } = useGetAllowedTransactionTypes({
    onCompleted: (result) => {
      // by default ALL are applied, bubble up for report modal
      onTransactionTypeFilter(result.getAllowedTransactionTypes.map((tt) => tt.id));
    },
  });

  const [tableState, tableFunctions, setDatatableState] = useDatatableState();

  const formatDate = useFormatDate();
  const { k2Discounts, k2TransactionChildName } = useFlags();

  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'date',
    direction: 'desc',
  });
  const [selectedPaymentStatuses, setSelectedPaymentStatuses] = useState<PaymentStatus[]>(
    defaultStatus ? [defaultStatus.toUpperCase()] : []
  );
  const [selectedCategories, setSelectedCategories] = useState<ITableFilterOption[]>([]);

  const [transactionInReverseModal, setTransactionInReverseModal] = useState<ITransaction | null>(null);

  const { loading, data: transactionsDataResult } = useGetPaginatedTransactionsInTimeframe({
    variables: {
      input: {
        businessId: businessId ?? '',
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        centerIds: centerFilterIds,
        start: moment.utc(dateRange.start).format('YYYY-MM-DD'),
        end: moment.utc(dateRange.end).format('YYYY-MM-DD'),
        sortBy: sortField.field,
        sortDirection: sortField.direction === 'asc' ? 'asc' : 'desc',
        searchKey: searchTerm,
        transactionTypeIds:
          !!selectedCategories && selectedCategories.length > 0 ? selectedCategories.map((i) => i.value) : [],
      },
    },
    fetchPolicy: 'network-only',
    skip: businessId === null || businessId === '',
    onCompleted: (result) => {
      dispatch(getTransactions(result.getPaginatedTransactionsInTimeframe.data));
    },
  });

  const transactionsGroupedByPaymentStatus = Object.fromEntries(
    paymentStatusFilters.map((status) => {
      if (!transactionsDataResult) return [status, []];

      const data = transactionsDataResult.getPaginatedTransactionsInTimeframe.data;

      const filtered =
        status === 'FLAGGED'
          ? data.filter((t) => isTransactionFlagged(t))
          : data.filter((t) => !isTransactionFlagged(t) && t.payment?.status === status);

      return [
        status,
        filtered.filter(
          (d) =>
            (!businessFilterIds.length || businessFilterIds.includes(d.account.center?.entityId ?? '')) &&
            (!centerFilterIds.length || centerFilterIds.includes(d.account.center?.id ?? '')) &&
            moment(d.date).isSameOrAfter(dateRange.start, 'date') &&
            moment(d.date).isSameOrBefore(dateRange.end, 'date')
        ),
      ];
    })
  );

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const clearAppliedFilters = useCallback(() => {
    setSelectedPaymentStatuses([]);
    dispatch(setCurrentCenterFilters([]));
    dispatch(setCurrentBusinessFilters([]));
    setSelectedCategories([]);
    setDateRange({ start: moment().startOf('month').format(), end: moment().endOf('month').format() });

    // an empty filter list implies we are showing ALL
    onTransactionTypeFilter((getTransactionTypesData?.getAllowedTransactionTypes ?? []).map((tt) => tt.id));
  }, [dispatch, setDateRange, onTransactionTypeFilter, getTransactionTypesData]);

  const getTransactionMethodDisplay = (transactionType: string) => {
    if (transactionType === 'Automated CC' || transactionType === 'Manual CC') {
      return 'Credit Card';
    }
    if (transactionType === 'Automated ACH/DD' || transactionType === 'Manual ACH/DD') {
      return isRegion('AU') ? 'Direct Debit' : 'ACH';
    } else return transactionType;
  };

  const getTransactionTypeDisplay = (transactionType: string) => {
    if (transactionType === 'Automated CC' || transactionType === 'Automated ACH/DD') {
      return isPaymentTransactionsOnly ? 'Automated' : 'Automated Payment';
    }
    if (transactionType === 'Manual CC' || transactionType === 'Manual ACH/DD') {
      return isPaymentTransactionsOnly ? 'Manual' : 'Manual Payment';
    } else return transactionType;
  };

  const renderStatus = useCallback((status: PaymentStatus, transaction: ITransaction): JSX.Element => {
    const isFlagged = Boolean(
      transaction.payment?.flags.length &&
        transaction.payment.approvedAt === null &&
        transaction.payment.rejectedAt === null
    );

    // markup taken from <PaymentStatusTag /> since we needed the custom icon
    if (isFlagged) {
      return (
        <div className="d-flex">
          <div className="tag px-4 bg-orange text-white">
            <div className="mr-2 d-flex">
              <FontAwesomeIcon icon={faFlag} size="sm" />
            </div>
            Flagged
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex">
        <PaymentStatusTag status={status} className="flex-grow-0" />
      </div>
    );
  }, []);

  const handleTransactionTypeFilter = useCallback(
    (transactionTypes: ITableFilterOption[]) => {
      // reset paging when changing filters
      tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
      setSelectedCategories(transactionTypes);
      onTransactionTypeFilter(!!transactionTypes ? transactionTypes.map((tt) => tt.value) : []);
    },
    [tableFunctions.changePage, onTransactionTypeFilter]
  );

  const handleBusinessFilter = useCallback(
    (businesses: ITableFilterOption[]) => dispatch(setCurrentBusinessFilters(businesses.map((b) => b.value))),
    []
  );
  const handleCenterFilter = useCallback(
    (centers: ITableFilterOption[]) => dispatch(setCurrentCenterFilters(centers.map((c) => c.value))),
    []
  );

  const handleSearchTerm = debounce(
    useCallback(
      (term: string) => {
        tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
        setSearchTerm(term);
      },
      [tableFunctions]
    ),
    250
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div>
      <DataTable
        showPagination
        keyField="id"
        data={tableData ?? []}
        dataSize={transactionsDataResult?.getPaginatedTransactionsInTimeframe.totalRecords ?? 0}
        showLoadingOverlay={loading}
        showSelect={false}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        onSort={(field, direction) => setSortField({ field, direction: direction === 'ASCENDING' ? 'asc' : 'desc' })}
        expandRow={(transaction) => (
          <TransactionExpandedRow
            handleReverse={setTransactionInReverseModal}
            transaction={transaction}
            isPaymentTransactionsOnly={isPaymentTransactionsOnly}
            onApproveFlaggedPayment={onApproveFlaggedPayment}
            onRejectFlaggedPayment={onRejectFlaggedPayment}
            approvePaymentLoading={approvePaymentLoading}
            onAddDiscountToTransaction={onAddDiscountToTransaction}
            onRemoveDiscountFromTransaction={onRemoveDiscountFromTransaction}
          />
        )}
        rowClasses={(row: ITransaction, rowIdx: number) => {
          // only apply the faded styling to payment transactions on the payments page
          if (row.payment?.status === 'CANCELLED' && isPaymentTransactionsOnly) {
            return 'k2-billing-transaction-cancelled-payment';
          }

          return '';
        }}
        columns={[
          isPaymentTransactionsOnly && {
            dataField: 'payment.status',
            text: 'Status',
            sort: true,
            formatter: (status: PaymentStatus, row: ITransaction) => renderStatus(status, row),
          },
          {
            dataField: 'date',
            text: 'Date',
            sort: true,
            formatter: (date: string) => formatDate(date),
          },
          {
            dataField: isRegion('US') ? 'description' : 'transactionNumber',
            text: isRegion('US') ? 'Description' : 'ID',
            sort: true,
          },
          {
            dataField: 'transactionType.name',
            text: 'Type',
            sort: true,
            formatter: (transactionType: string) => getTransactionTypeDisplay(transactionType),
          },
          isPaymentTransactionsOnly && {
            dataField: 'transactionType',
            text: 'Method',
            sort: true,
            formatter: (transactionType: ITransactionType) => getTransactionMethodDisplay(transactionType.name),
          },
          {
            dataField: 'account.name',
            text: 'Account',
            sort: true,
          },
          k2TransactionChildName && {
            dataField: 'appliedToAccountChild',
            text: 'Child Name',
            sort: false,
            formatter: (accountChild: IAccountChild) =>
              !!accountChild ? `${accountChild.firstname} ${accountChild.lastname}` : '',
          },
          {
            dataField: 'amount',
            text: 'Amount',
            sort: true,
            align: 'right',
            headerAlign: 'right',
            formatter: (amount: number, row: ITransaction) => (
              <h6 className={classnames('mr-4')}>
                <Currency payment={!!row.payment} amount={amount} />
              </h6>
            ),
          },
          {
            dataField: '',
            text: '',
            formatter: (cell: any, row: ITransaction) => (
              <div className="d-flex justify-content-center">
                {row.reversedByTransactionId && (
                  <span className={classnames('fa-layers fa-fw mx-2', { invisible: false })}>
                    <FontAwesomeIcon icon={faUndo} size="lg" color={colors['dark-gray']} />
                    <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
                  </span>
                )}
                {k2Discounts &&
                  row.appliedDiscountTransactions &&
                  row.appliedDiscountTransactions.length > 0 &&
                  row.appliedDiscountTransactions.some(
                    (discountTransaction) => !discountTransaction.reversedByTransactionId
                  ) && (
                    <span className="fa-layers">
                      <FontAwesomeIcon icon={faBadgePercent} size="lg" color={colors['dark-gray']} />
                    </span>
                  )}
              </div>
            ),
          },
        ].filter((c) => c)}
        renderHeader={(paginationProps, searchProps) => (
          <div className="align-items-center">
            <TableHeader className="flex-wrap">
              <div className="d-flex flex-wrap align-items-center w-auto">
                <TableSearch
                  placeholder={isPaymentTransactionsOnly ? 'Search Payments' : 'Search Transactions'}
                  onChange={handleSearchTerm}
                  className={isMobile ? 'my-1 mr-4' : 'mr-4 flex-shrink-0 flex-grow-0'}
                />
                <Select
                  options={timeRangeOptions}
                  value={convertTimeRangeObjectToString(dateRange)}
                  onChange={(string) => setDateRange(convertTimeRangeStringToObject(string))}
                  className={isMobile ? 'my-1 mr-4' : 'mr-2 flex-shrink-0 flex-grow-0 mb-0'}
                />
              </div>
              <div className={isMobile ? 'd-flex flex-nowrap my-1' : 'd-flex align-items-center ml-4 mr-auto'}>
                <DateInput
                  date={dateRange.start}
                  onDateSelect={(start) => setDateRange({ ...dateRange, start })}
                  className="flex-shrink-3 flex-grow-0 mb-0"
                />
                <div className={isMobile ? 'mt-2 mx-2' : 'mx-2'}>to</div>
                <DateInput
                  date={dateRange.end}
                  onDateSelect={(end) => setDateRange({ ...dateRange, end })}
                  className="mr-2 flex-shrink-3 flex-grow-0 mb-0"
                />
              </div>
              <div className={isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex align-items-center'}>
                <DropdownFilter
                  title="Type"
                  className={isMobile ? 'mr-4 my-1' : 'mr-4'}
                  selectedFilters={selectedCategories || []}
                  options={orderBy(
                    (getTransactionTypesData?.getAllowedTransactionTypes ?? []).map((tt) => ({
                      label: tt.name,
                      value: tt.id,
                    })),
                    (tt) => tt.label,
                    'asc'
                  )}
                  onFilterSelect={handleTransactionTypeFilter}
                />
                {centers && centers.length > 1 && !isAccountView && (
                  <div className={isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex align-items-center'}>
                    {user?.isInternal && (
                      <DropdownFilter
                        title="Business"
                        className="mr-4"
                        selectedFilters={businessFilterIds}
                        options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                        onFilterSelect={handleBusinessFilter}
                      />
                    )}
                    <DropdownFilter
                      title={fieldLabels.center}
                      className="mr-4"
                      selectedFilters={centerFilterIds}
                      options={
                        centers
                          ?.map((c) => ({ label: c.name, value: c.id }))
                          .sort((a, b) => a.label.localeCompare(b.label)) ?? []
                      }
                      onFilterSelect={handleCenterFilter}
                    />
                  </div>
                )}
                <IconButtonCircle
                  icon={faTimes}
                  onClick={clearAppliedFilters}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                />
              </div>
            </TableHeader>
            {!isPaymentTransactionsOnly && canUseBulkTransactions && (
              <BulkTransactionsProgress></BulkTransactionsProgress>
            )}
            {isPaymentTransactionsOnly && (
              <div className="k2-billing-transactions-payment-filters-grid px-8 pt-2">
                {paymentStatusFilters
                  .filter((status) => status !== 'COMPLETED' && status !== 'CANCELLED')
                  .map((status, idx) => (
                    <div className="mb-2" key={`payment-filter-card-${status}-${idx}`}>
                      <PaymentStatusFilterCard
                        status={status}
                        count={transactionsGroupedByPaymentStatus[status].length}
                        total={transactionsGroupedByPaymentStatus[status].reduce((sum, t) => sum + t.amount, 0)}
                        // always filter out completed since there is no other way to remove since we dont have a card for it
                        onClick={() =>
                          selectedPaymentStatuses.includes(status)
                            ? setSelectedPaymentStatuses(
                                selectedPaymentStatuses.filter((s) => s !== status && s !== 'COMPLETED')
                              )
                            : setSelectedPaymentStatuses([
                                ...selectedPaymentStatuses.filter((s) => s !== 'COMPLETED'),
                                status,
                              ])
                        }
                        className="raised-hover h-100"
                        isSelected={selectedPaymentStatuses.includes(status)}
                        loading={loading}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
      />
      {transactionInReverseModal && (
        <ReverseTransactionModal
          isOpen={Boolean(transactionInReverseModal)}
          onClose={() => setTransactionInReverseModal(null)}
          transaction={transactionInReverseModal}
        />
      )}
    </div>
  );
};

interface iBulkTransaction {
  bulkTransactionId: string;
  total: number;
  succeeded: number;
  failed: number;
  status: 'running' | 'failed';
}

function useBulkTransactionProgress(businessId: string | null) {
  async function fetchBulkTransactions(businessId: string, jwtToken: string) {
    const result = await axios.get(`${config.api.billing.uri}/api/v2/bulk-transactions/statuses/${businessId}`, {
      headers: { authorization: `Bearer ${jwtToken}` },
    });
    if (result.data) {
      return result.data.map((d) => ({
        ...d,
        status: d.status === 'FAILED' ? 'failed' : 'running',
      }));
    }
  }

  // infrastructure
  // load data
  const jwtToken = useSelector((state: { session: { token: string } }) => state.session.token);

  // bulk transactions
  const [bulkTransactions, setBulkTransactions] = useState<iBulkTransaction[]>([]);

  useEffect(() => {
    async function loadData(businessId: string) {
      try {
        const bulkTransactions = await fetchBulkTransactions(businessId, jwtToken);
        setBulkTransactions(bulkTransactions);
      } catch (e) {
        console.log(e);
      }
    }
    if (businessId) {
      loadData(businessId);
      const interval = setInterval(() => {
        loadData(businessId);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [businessId, jwtToken]);
  return { bulkTransactions };
}

function BulkTransactionsProgress() {
  //infrastructure
  const { businessId } = useSelector((state: RootState) => state.context);
  const { bulkTransactions } = useBulkTransactionProgress(businessId);
  return <BulkTransactionsProgressComponent bulkTransactions={bulkTransactions}></BulkTransactionsProgressComponent>;
}

function BulkTransactionsProgressComponent(props: { bulkTransactions: iBulkTransaction[] }) {
  const history = useHistory();
  return (
    <Box>
      {props.bulkTransactions.map((b) => (
        <BulkTransactionProgressItem
          total={b.total}
          completed={b.succeeded}
          failed={b.failed}
          status={b.status}
          onResolve={() => {
            history.push(`/billing/bulk-transactions/${b.bulkTransactionId}/details`);
          }}
        ></BulkTransactionProgressItem>
      ))}
    </Box>
  );
}

function BulkTransactionProgressItem(props: {
  total: number;
  completed: number;
  failed: number;
  status: string;
  onResolve: () => void;
}) {
  return (
    <Box marginTop=".5rem" marginBottom=".5rem">
      {props.status === 'running' && (
        <Paper style={{ width: '100%' }}>
          <Box padding=".5rem 1rem" display={'flex'} alignItems={'center'}>
            <CircularProgressWithIcon color="money">
              <FontAwesomeIcon icon={faDollarSign} color="green"></FontAwesomeIcon>
            </CircularProgressWithIcon>
            <Box
              marginLeft={'.5rem'}
            >{`${props.completed} / ${props.total} transactions added to the Transactions list.`}</Box>
          </Box>
        </Paper>
      )}
      {props.status === 'failed' && (
        <Paper style={{ width: '100%', backgroundColor: '#fdf0cc', border: '1px solid #e1c06a' }}>
          <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end" alignItems={'center'}>
            <Alert className="box-shadow-0" variant="warning" style={{ marginRight: '.5rem', flexGrow: 1 }}>
              {`${props.failed} / ${props.total} NOT added to the Transactions list.`}
            </Alert>
            <Box padding={'.5rem 1rem'}>
              <div style={{ backgroundColor: 'white', borderRadius: '4px' }}>
                <Button onClick={props.onResolve} variant="outline-secondary">
                  Resolve
                </Button>
              </div>
            </Box>
          </Box>
        </Paper>
      )}
    </Box>
  );
}

function CircularProgressWithIcon(props: { children: React.ReactNode; color?: CircularProgressProps['color'] }) {
  return (
    <Box position={'relative'} display="inline-flex">
      <CircularProgress color={props.color ?? 'primary'}></CircularProgress>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default TransactionsTable;
