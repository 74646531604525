import { useContext } from 'react';
import { PermissionContext } from '../contexts/permissionContext';

export const usePermission = (areaPermissionLevel?: IAreaPermissionLevel): boolean => {
  const permissionDiscriminator = useContext(PermissionContext);

  if (areaPermissionLevel == null) {
    // default to allow
    return true;
  }
  return permissionDiscriminator.hasAreaPermissionLevel(areaPermissionLevel!);
};

/**
 * Given a Record of <string, IAreaPermissionLevel>, it returns the permission check (true or false) for each of the IAreaPermissionLevel in a map
 * @param areaPermissionLevels
 */
export const usePermissions = (
  areaPermissionLevels?: Record<string, IAreaPermissionLevel | null>
): Record<string, boolean> => {
  const permissionDiscriminator = useContext(PermissionContext);

  const result = {} as Record<string, boolean>;
  if (areaPermissionLevels == null) {
    return result;
  }

  Object.keys(areaPermissionLevels).forEach((key) => {
    const areaPermissionLevel = areaPermissionLevels[key];
    if (areaPermissionLevel == null) {
      // default to allow
      result[key] = true;
    } else {
      result[key] = permissionDiscriminator.hasAreaPermissionLevel(areaPermissionLevel);
    }
  });

  return result;
};
