import React from 'react';
import BSDropdown, { DropdownProps } from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import useUniqueId from 'shared/hooks/useUniqueId';
import { Row } from 'shared/components/Layout';
import colors from '_colors.module.scss';

interface IProps extends DropdownProps {
  control: React.ReactNode;
  children: React.ReactNode | null;
  className?: string;
  menuClass?: string;
  label?: string;
}

const Dropdown: React.FC<IProps> = ({ children, className, control, menuClass, label, ...props }) => {
  return (
    <div className="form-group">
      <div className="d-flex flex-row">{label && <label className="form-label">{label}</label>}</div>
      <BSDropdown {...props} alignRight={true} className={`dropdown-card ${className || ''}`}>
        <BSDropdown.Toggle as="div" id={useUniqueId('dropdown')}>
          <Row className="h-100" noGutters>
            {control}
            <FontAwesomeIcon className="ml-auto" size="1x" color={colors.slate} icon={faAngleDown} />
          </Row>
        </BSDropdown.Toggle>
        <BSDropdown.Menu className={menuClass || ''}>
          <BSDropdown.Item className="dropdown-close">
            <FontAwesomeIcon className="m-auto" size="1x" color={colors.slate} icon={faTimes} />
          </BSDropdown.Item>
          {children}
        </BSDropdown.Menu>
      </BSDropdown>
    </div>
  );
};

export default Dropdown;
