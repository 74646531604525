import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';

//Styles
import './AgencyPaymentHeader.scss';
import { IDisplayableAgencyPayment } from '../useManageAgencyPayment';
import CurrencyInputNegatives from '../../../../shared/components/TextInput/CurrencyInputNegativeAllowed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';

interface AgencyPaymentHeaderProps {
  // input
  agencyPayment: IDisplayableAgencyPayment | undefined;
  amountEntered?: number;
  remainingDifference?: number;
  // events
  onDateChange?: (date: string) => void;
  onAmountChange?: (amount: number) => void;
  onReferenceIdChange?: (date: string) => void;
}
/**
 * Holds main payment information
 * @returns
 */
export default function AgencyPaymentHeader({
  agencyPayment,
  amountEntered,
  remainingDifference,
  onDateChange = () => {},
  onAmountChange = () => {},
  onReferenceIdChange = () => {},
}: AgencyPaymentHeaderProps) {
  const { t } = useTranslation(['subsidies']);
  const noop = () => {};
  /**
   * mapping this to an array because the select component requires one, and that is how we are choosing to display this.
   */
  const agencyOptions = [{ label: agencyPayment?.agencyName, value: agencyPayment?.agencyName }];

  /**
   * mapping this to an array because the select component requires one, and that is how we are choosing to display this.
   */
  const centerOptions = agencyPayment?.centers?.map((c) => ({ label: c.name, value: c.id })) ?? [];

  let differenceClass = '';
  if (remainingDifference !== 0) {
    differenceClass = 'unequal';
  }

  return (
    <Card bodyClassName="py-4" className="agency-payments__payment-header mb0">
      <Row>
        <Col>
          <Select
            label={t('subsidies:agency-billing.new-subsidy-payment.agency-label')}
            options={agencyOptions}
            onChange={noop}
            isLoading={false}
            value={agencyOptions[0]}
            disabled
          />
        </Col>
        <Col>
          <Select
            label={t('subsidies:agency-billing.new-subsidy-payment.center-label')}
            isMulti
            value={centerOptions}
            isLoading={false}
            options={centerOptions}
            onChange={noop}
            disabled
          />
        </Col>
        <Col>
          <FormGroup>
            <DateInput
              required
              label={t('subsidies:agency-billing.new-subsidy-payment.payment-date-label')}
              date={agencyPayment?.paymentDate}
              onDateSelect={onDateChange}
              dateOnly={true}
            />
          </FormGroup>
        </Col>
        <Col>
          <TextInput
            label={t('subsidies:agency-billing.new-subsidy-payment.reference-number-label')}
            onChange={onReferenceIdChange}
            value={agencyPayment?.referenceId}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CurrencyInput
            required
            className="border-radius-0"
            label={t('subsidies:agency-billing.new-subsidy-payment.payment-amount-label')}
            onChange={onAmountChange}
            value={agencyPayment?.amount}
          />
        </Col>
        <Col>
          <CurrencyInputNegatives
            disabled
            label={t('subsidies:agency-billing.new-subsidy-payment.amount-entered-label')}
            value={amountEntered}
            isInvalid={remainingDifference !== 0}
            validateIfNotTouched={true}
            requiresValidation={true}
          />
        </Col>
        <Col>
          <></>
        </Col>
        <Col>
          <></>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <div className="d-flex flex-row">
              <Form.Label>Payment Format</Form.Label>
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
            <Form.Check
              inline
              disabled
              label={t('subsidies:agency-billing.new-subsidy-payment.payment-format.by-account')}
              name="payment-format"
              type="radio"
              checked={agencyPayment?.paymentFormat === 'Account'}
            />
            <Form.Check
              inline
              disabled
              label={t('subsidies:agency-billing.new-subsidy-payment.payment-format.by-child')}
              name="payment-format"
              type="radio"
              checked={agencyPayment?.paymentFormat === 'Child'}
            />
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
}
