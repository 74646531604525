import React from 'react';

interface IProps {
  newShift: boolean;
}

const Subtext: React.FC<IProps> = ({ newShift, ...props }) => {
  if (newShift) {
    return (
      <>
        <div className="mb-2">
          Create and copy shifts below. Start by selecting which day(s) the shift applies to, then assign an employee
          (or select unassigned if coverage is to-be-determined) and which position the shift you're creating is for.
        </div>
        <div className="mb-4">
          Complete your scheduling by indicating a start and end time, then add break minutes if needed. Once saved,
          these will be drafted to your schedule
        </div>
      </>
    );
  } else {
    return (
      <div className="mb-4">
        Edit the employee, position, shift, and breaks as desired, below. Once saved, these changes will be drafted to
        your schedule.
      </div>
    );
  }
};

export default Subtext;
