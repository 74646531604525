import React from 'react';
import cast from 'shared/util/cast';
import Avatar from 'shared/components/Avatar';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import colors from '_colors.module.scss';

interface IProps {
  staff: IStaff;
}

const CenterAffiliations: React.FC<IProps> = ({ staff }) => {
  const scopes = cast<any[]>(staff?.roleship?.scopes);
  return (
    <div>
      {scopes?.map((scope, index) => (
        <div key={index} className="d-flex align-items-center mb-6">
          <Avatar size="lg" image={scope?.avatar?.url} initials={scope?.name[0]} className="mr-4" />
          <div className="d-inline">
            <div className="mb-2">{scope.name}</div>
            {scope.id === staff.primaryCenter?.id && (
              <ColoredBackgroundTag className="sm" color={colors.teal} text="Primary" />
            )}
            {staff.roleship.scopeType === 'ENTITY' && (
              <ColoredBackgroundTag className="sm" color={colors.teal} text="All" />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CenterAffiliations;
