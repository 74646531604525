import i18n, { InterpolationOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import config from 'config';
import translationEN_AU from 'locales/en-AU/translation.json';
import translationEN from 'locales/en/translation.json';
import subsidiesEN from 'locales/en/subsidies.json';
import businessEN from 'locales/en/business.json';
import businessEN_AU from 'locales/en-AU/business.json';
import billingEN from 'locales/en/billing.json';
import billingAU from 'locales/en-AU/billing.json';
import permissionsEN from 'locales/en/permissions.json';
import ktAdminEN from 'locales/en/ktAdmin.json';
import ktAdminEN_AU from 'locales/en-AU/ktAdmin.json';
import ccssEN from 'locales/en/ccss.json';
import accountsEN from 'locales/en/accounts.json';
import accountsEN_AU from 'locales/en-AU/accounts.json';
import timelogEN from 'locales/en/timelog.json';
import settingsEN from 'locales/en/settings.json';
import reportingEN from 'locales/en/reporting.json';
import reportingEN_AU from 'locales/en-AU/reporting.json';
import enrollmentEN from 'locales/en/enrollment.json';
import enrollmentEN_AU from 'locales/en-AU/enrollment.json';
import commsEN from 'locales/en/comms.json';
import integrationsEN from 'locales/en/integrations.json';
import { capitalize } from 'shared/util/string';

export const resources = {
  en: {
    translation: translationEN,
    subsidies: subsidiesEN,
    billing: billingEN,
    business: businessEN,
    permissions: permissionsEN,
    ccss: ccssEN,
    accounts: accountsEN,
    ktAdmin: ktAdminEN,
    timelog: timelogEN,
    settings: settingsEN,
    reporting: reportingEN,
    enrollment: enrollmentEN,
    comms: commsEN,
    integrations: integrationsEN,
  },
  'en-AU': {
    translation: translationEN_AU,
    business: businessEN_AU,
    billing: billingAU,
    accounts: accountsEN_AU,
    ktAdmin: ktAdminEN_AU,
    reporting: reportingEN_AU,
    enrollment: enrollmentEN_AU,
  },
} as const;

export const defaultNS = 'translation';

i18n.use(initReactI18next).init({
  lng: `${config.locale.language}-${config.locale.region}`,
  defaultNS: defaultNS,
  ns: [
    defaultNS,
    'subsidies',
    'billing',
    'business',
    'permissions',
    'accounts',
    'timelog',
    'settings',
    'reporting',
    'comms',
    'operations',
  ],
  fallbackLng: 'en',
  resources,
  interpolation: {
    format: i18Formatting,
  },
});

function i18Formatting(
  value: any,
  format?: string,
  lng?: string,
  options?: InterpolationOptions & { [key: string]: any }
): string {
  if (format === 'capitalize' && value) return capitalize(value);

  return value;
}

export default i18n;
