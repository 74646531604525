// https://github.com/apollographql/@apollo/client/blob/master/packages/@apollo/client/src/core/networkStatus.ts
export const NETWORK_STATUS = {
  LOADING: 1,
  SET_VARIABLES: 2,
  FETCH_MORE: 3,
  REFETCH: 4,
  POLL: 6,
  READY: 7,
  ERROR: 8,
};
