import { useGetAgenciesForBusiness } from 'gql/agency/queries';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useGetCurrentBusiness } from 'shared/hooks/user-context/useGetCurrentBusiness';

/**
 * Convenience Hook to get Agency Options to populate a select
 * @param includeArchivedAgencies if true will not filter out archived agencies
 * @returns a list of ISelectMenuItems for agencies
 */
export function useGetAgencyOptions(includeArchivedAgencies: boolean = false): {
  agencyOptions: ISelectMenuItem[];
  loading: boolean;
} {
  const businessId = useGetCurrentBusiness();
  const { data: getAgenciesForBusinessData, loading } = useGetAgenciesForBusiness(
    {
      variables: {
        businessId: businessId ?? '',
      },
      skip: !businessId,
    },
    `id name archivedAt`
  );
  const agencyOptions: ISelectMenuItem[] = useMemo(() => {
    let options: ISelectMenuItem[] = [];
    for (let agency of getAgenciesForBusinessData?.getAgenciesForBusiness ?? []) {
      if (!includeArchivedAgencies && agency.archivedAt) continue;
      options.push({ label: agency.name, value: agency.id });
    }
    return orderBy(options, (o) => o.label, 'asc');
  }, [getAgenciesForBusinessData?.getAgenciesForBusiness, includeArchivedAgencies]);

  return { agencyOptions, loading };
}
