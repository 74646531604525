import { documentFields } from 'gql/document/fields';

export const medicalConditionFields: string = `
  id
  personId
  childId
  typeId
  type {
    id
    name
    type
  }
  otherMedicalConditionType
  name
  risk
  instructions
  symptoms
  archivedAt
  createdAt
  createdByPerson {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
  }
  archivedByPerson {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
  }
  isRevised
`;

export const medicalConditionWithDocumentsFields: string = `
  ${medicalConditionFields}
  documents {
    ${documentFields}
  }
`;
