import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import Select from 'shared/components/Select';
import {
  useSearchActiveStaffForCenter,
  useSearchActiveStaffScopedToEntity,
} from 'shared/hooks/useSearchActiveStaffForCenter';
import { GET_ACTIVE_STAFF_FOR_NEW_TIME_ENTRY } from '../../graphql/queries';
import { getFullName } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { isRegion } from 'shared/util/region';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  centerId: string;
  entityId: string;
  date: any;
}

const AddEmployeeTimeModal: React.FC<IProps> = ({ isOpen, onClose, centerId, entityId, date }) => {
  const history = useHistory();
  const { t } = useTranslation(['timelog']);

  const { loading: searchActiveStaffLoading, data: searchActiveStaffData } = useSearchActiveStaffForCenter(
    centerId,
    GET_ACTIVE_STAFF_FOR_NEW_TIME_ENTRY
  );

  const { loading: searchActiveEntityStaffLoading, data: entityStaffData } = useSearchActiveStaffScopedToEntity(
    entityId,
    GET_ACTIVE_STAFF_FOR_NEW_TIME_ENTRY
  );

  const centerStaff = searchActiveStaffData?.searchStaff.data ?? [];
  const entityStaff = entityStaffData?.searchStaff.data ?? [];

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const navigateToPersonTimeSheets = useCallback(
    (option: IStaff) => {
      const startDate = date.clone().day(1).format('YYYY-MM-DD');
      const endDate = date.clone().day(5).format('YYYY-MM-DD');
      history.push(
        `/employees/time-log/time-card?personId=${option.id}&centerId=${centerId}&start=${startDate}&end=${endDate}`
      );
    },
    [history, date, centerId]
  );

  return (
    <ConfirmationModal
      title={t('timelog:time-log.add-employee-time-modal-title')}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Close"
      primaryCallback={handleClose}
      noSecondaryChoice={true}
    >
      <Select
        required
        label={t('timelog:time-log.add-employee-time-modal-row')}
        options={[...centerStaff, ...entityStaff]}
        onChange={(option: IStaff) => navigateToPersonTimeSheets(option)}
        getOptionValue={(option: IStaff) => option.id}
        getOptionLabel={(option: IStaff) => getFullName(option)}
        isLoading={searchActiveStaffLoading || searchActiveEntityStaffLoading}
      />
    </ConfirmationModal>
  );
};

export default AddEmployeeTimeModal;
