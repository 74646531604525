import { useGetProvidersByBusinessId } from 'gql/business/queries';
import ControlContainer from 'pages/TimeManagement/subroutes/StaffSchedules/components/ControlContainer';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'shared/components/Layout';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import { RootState } from 'store/reducers';
import CcsAccountManagementMenu from './CcsAccountManagementMenu/CcsAccountManagementMenu';
import LocationOfRecords from './NotifiableEvents/LocationOfRecords/LocationOfRecords';
import NotificationOfMattersAffectingApproval from './NotifiableEvents/NotificationOfMattersAffectingApproval/NotificationOfMattersAffectingApproval';
import PotentiallyAffectingSuitabilityIndividual from './NotifiableEvents/PotentiallyAffectingSuitabilityIndividual/PotentiallyAffectingSuitabilityIndividual';
import ProviderManagementOrControl from './NotifiableEvents/ProviderConflictOfInterest';
import ProviderContactDetails from './NotifiableEvents/ProviderContactDetails/ProviderContactDetails';
import ProviderNameChange from './NotifiableEvents/ProviderNameChange/ProviderNameChange';
import ProviderPhysicalAddress from './NotifiableEvents/ProviderPhysicalAddress/ProviderPhysicalAddress';
import ServiceContactDetails from './NotifiableEvents/ServiceContactDetails';
import ServiceLocationOfRecords from './NotifiableEvents/ServiceLocationOfRecords';
import ServiceNameChange from './NotifiableEvents/ServiceNameChange/ServiceNameChange';
import './_ccsAccountManagement.scss';
import ServiceAddressChange from './NotifiableEvents/ServiceAddressChange/ServiceAddressChange';
import ProviderFinancialDetails from './NotifiableEvents/ProviderFinancialDetails/ProviderFinancialDetails';
import ServiceFinancialDetails from './NotifiableEvents/ServiceFinancialDetails/ServiceFinancialDetails';
import { useTranslation } from 'react-i18next';
import { ProviderEntersIntoAdmLiqd } from './NotifiableEvents/ProviderEntersIntoAdmLiqd/ProviderEntersIntoAdmLiqd';
import { ProviderStopOperatingService } from './NotifiableEvents/ProviderStopOperatingService/ProviderStopOperatingService';
import { ServiceTemporarilyCeasingOperations } from './NotifiableEvents/ServiceTemporarilyCeasingOperations/ServiceTemporarilyCeasingOperations';

interface IProps {}

export interface IMenu {
  key: string;
  name: string;
  isSelected: boolean;
}

const CcsAccountManagement: React.FC<IProps> = ({}) => {
  const entityId = useSelector((state: RootState) => state.user?.entityId) ?? '';
  const { t } = useTranslation('business');

  const { data: providers, loading: getProvidersLoading } = useGetProvidersByBusinessId({
    variables: {
      businessId: entityId,
    },
    skip: entityId === '',
  });

  const [menu, setMenu] = useState<Array<IMenu>>([
    { key: 'provider-name-change', name: 'Provider Name Change', isSelected: true },
    { key: 'provider-contact-details', name: 'Provider Contact Details', isSelected: false },
    { key: 'provider-physical-address', name: 'Provider Physical Address', isSelected: false },
    { key: 'provider-financial-details', name: 'Provider Financial Details', isSelected: false },
    {
      key: 'provider-stop-operating-services',
      name: t('ccs-management.provider-stops-operating-service.title'),
      isSelected: false,
    },
    {
      key: 'provider-enters-into-admn-recp-liqd',
      name: t('ccs-management.provider-enters-into-admn-recp-liqd.title'),
      isSelected: false,
    },
    {
      key: 'notification-of-matters-affecting-approval',
      name: 'Notification of matters affecting approval',
      isSelected: false,
    },
    { key: 'location-of-records', name: 'Location of records (Provider closure only)', isSelected: false },
    {
      key: 'location-of-records-service-closure',
      name: 'Location of records (Service closure only)',
      isSelected: false,
    },
    { key: 'provider-management-or-control', name: 'Control having possible conflict of interest', isSelected: false },
    {
      key: 'potentially-affecting-suitability-individual',
      name: 'Notification of matter potentially affecting suitability - Individual',
      isSelected: false,
    },
    { key: 'service-name-change', name: 'Service Name Change', isSelected: false },
    { key: 'service-contact-details', name: 'Service Contact Details', isSelected: false },
    { key: 'service-address-change', name: 'Service Address Change', isSelected: false },
    { key: 'service-financial-details', name: 'Service Financial Details', isSelected: false },
    {
      key: 'service-temporarily-ceasing-operations',
      name: t('ccs-management.service-temporarily-ceasing-operations.title'),
      isSelected: false,
    },
  ]);

  const selecetedMenu = useMemo(() => {
    const activeMenu = menu.filter((i) => i.isSelected);
    return !!activeMenu ? activeMenu[0].key : '';
  }, menu);

  const providerList = providers?.getProvidersByBusinessId || [];

  return (
    <SpinnerOverlay show={getProvidersLoading}>
      <Row align="start">
        <Col md className="col-md-3">
          <ControlContainer>
            <CcsAccountManagementMenu menu={menu} updateMenu={(items: Array<IMenu>) => setMenu(items)} />
          </ControlContainer>
        </Col>

        <Col md className="col-md-9">
          {selecetedMenu === 'provider-name-change' && (
            <ProviderNameChange businessId={entityId} providers={providers?.getProvidersByBusinessId || []} />
          )}
          {selecetedMenu === 'provider-contact-details' && (
            <ProviderContactDetails businessId={entityId} providers={providers?.getProvidersByBusinessId || []} />
          )}
          {selecetedMenu === 'provider-physical-address' && (
            <ProviderPhysicalAddress businessId={entityId} providers={providerList} />
          )}
          {selecetedMenu === 'provider-financial-details' && (
            <ProviderFinancialDetails businessId={entityId} providers={providers?.getProvidersByBusinessId || []} />
          )}
          {selecetedMenu === 'provider-stop-operating-services' && (
            <ProviderStopOperatingService businessId={entityId} />
          )}
          {selecetedMenu === 'provider-enters-into-admn-recp-liqd' && (
            <ProviderEntersIntoAdmLiqd businessId={entityId} />
          )}
          {selecetedMenu === 'notification-of-matters-affecting-approval' && (
            <NotificationOfMattersAffectingApproval
              businessId={entityId}
              providers={providers?.getProvidersByBusinessId || []}
            />
          )}
          {selecetedMenu === 'location-of-records' && <LocationOfRecords />}
          {selecetedMenu === 'location-of-records-service-closure' && <ServiceLocationOfRecords />}
          {selecetedMenu === 'provider-management-or-control' && (
            <ProviderManagementOrControl businessId={entityId} providers={providerList} />
          )}
          {selecetedMenu === 'potentially-affecting-suitability-individual' && (
            <PotentiallyAffectingSuitabilityIndividual
              businessId={entityId}
              providers={providers?.getProvidersByBusinessId || []}
            />
          )}
          {selecetedMenu === 'service-contact-details' && <ServiceContactDetails />}
          {selecetedMenu === 'service-name-change' && <ServiceNameChange />}
          {selecetedMenu === 'service-address-change' && <ServiceAddressChange />}
          {selecetedMenu === 'service-financial-details' && <ServiceFinancialDetails />}
          {selecetedMenu === 'service-temporarily-ceasing-operations' && (
            <ServiceTemporarilyCeasingOperations businessId={entityId} />
          )}
        </Col>
      </Row>
    </SpinnerOverlay>
  );
};

export default CcsAccountManagement;
