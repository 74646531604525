import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { ccmsApprovalFields } from './fields';

const CREATE_CCMS_APPROVAL = gql`
  mutation($input: CcmsApprovalCreateInput!) {
    createCcmsApproval(input: $input) {
      ${ccmsApprovalFields}
    }
  }
`;

export const useCreateCcmsApproval = () =>
  useMutation<{ createCcmsApproval: ICcmsApproval }, { input: ICcmsApprovalCreateInput }>(CREATE_CCMS_APPROVAL, {});

const DELETE_CCMS_APPROVAL = gql`
  mutation ($input: CcmsApprovalDeleteInput!) {
    deleteCcmsApproval(input: $input)
  }
`;

export const useDeleteCcmsApproval = () =>
  useMutation<{ deleteCcmsApproval: boolean }, { input: ICcmsApprovalDeleteInput }>(DELETE_CCMS_APPROVAL, {});

const EDIT_CCMS_APPROVAL = gql`
    mutation($input: CcmsApprovalEditInput!) {
      editCcmsApproval(input: $input) {
        ${ccmsApprovalFields}
      }
    }
  `;

export const useEditCcmsApproval = () =>
  useMutation<{ editCcmsApproval: ICcmsApproval }, { input: ICcmsApprovalEditInput }>(EDIT_CCMS_APPROVAL, {});
