import React, { useState } from 'react';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';

interface IProps {
  isRequired: boolean;
  value: number | undefined;
  setValue: (value: number | undefined) => void;
  disabled?: boolean;
}
export default ({ isRequired, value, setValue, disabled }: IProps) => {
  const [localValue, setLocalValue] = useState<number | undefined>(value);
  return (
    <CurrencyInput
      className="my-0 border-radius-0"
      value={localValue}
      onBlur={() => setValue(localValue)}
      required={isRequired}
      onChange={(v) => setLocalValue(v)}
      disabled={disabled}
    />
  );
};
