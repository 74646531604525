import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';

interface IUpdateProviderPersonnelMessage {
  updateProviderPersonnel: Boolean;
}

interface IUpdateProviderPersonnelVariables {
  input: IUpdateProviderPersonnelInput;
}

export const UPDATE_PROVIDER_PERSONNEL = gql`
  mutation ($input: UpdateProviderPersonnelInput!) {
    updateProviderPersonnel(input: $input)
  }
`;

export const useUpdateProviderPersonnel = (
  options?: MutationHookOptions<IUpdateProviderPersonnelMessage, IUpdateProviderPersonnelVariables>
) => {
  return useMutation<IUpdateProviderPersonnelMessage, IUpdateProviderPersonnelVariables>(
    UPDATE_PROVIDER_PERSONNEL,
    options
  );
};
