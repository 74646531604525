import * as types from './types';

export const getExpectedSessions = (sessions: ISession[]): types.SessionsActionTypes => ({
  type: types.GET_EXPECTED_SESSIONS,
  sessions,
});

export const getTimeEntries = (timeEntries: IAttendanceTimeEntry[]): types.SessionsActionTypes => ({
  type: types.GET_TIME_ENTRIES,
  timeEntries,
});

export const createSession = (session: ISession): types.SessionsActionTypes => ({
  type: types.CREATE_SESSION,
  session,
});

export const updateSession = (session: ISession): types.SessionsActionTypes => ({
  type: types.UPDATE_SESSION,
  session,
});

export const getSessionTransactions = (transactions: ITransaction[]): types.SessionsActionTypes => ({
  type: types.GET_SESSION_TRANSACTIONS,
  transactions,
});

/**
 * Replace a session with `id` with a new `session` object
 * @param id
 * @param session
 */
export const updateSessionAtId = (id: string, session: ISession): types.SessionsActionTypes => ({
  type: types.UPDATE_SESSION_AT_ID,
  id,
  session,
});

export const removeSession = (session: ISession): types.SessionsActionTypes => ({
  type: types.REMOVE_SESSION,
  session,
});

export const removeSessionById = (id: string): types.SessionsActionTypes => ({
  type: types.REMOVE_SESSION_BY_ID,
  id,
});

export const reportAbsence = (absences: IAbsence[]): types.SessionsActionTypes => ({
  type: types.REPORT_ABSENCE,
  absences,
});

export const updateSessions = (sessions: ISession[]): types.SessionsActionTypes => ({
  type: types.UPDATE_SESSIONS,
  sessions,
});

export const removeSessionTimeEntry = (timeEntry: IAttendanceTimeEntry): types.SessionsActionTypes => ({
  type: types.REMOVE_SESSION_TIME_ENTRY,
  timeEntry,
});
