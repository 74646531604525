import { Bill } from '../types/Bill';
import { APIError, useBillApi } from './useBillingApi';

export function useGetDraftBill(params: { accountId: string }): {
  data?: Bill;
  error?: APIError;
  loading: boolean;
  refetch: () => void;
} {
  return useBillApi('GET', `api/v2/billing-activity/draft/${params.accountId}`);
}
