import React, { useState } from 'react';
//utils
import {
  endOfCareActiveTableColumns,
  formatDate,
  getAbsenceReasonOptions,
  getCareType,
  IEndCareEnrollmentExpandRowProps,
} from '../EndOfCareActive/common';
import { currencyFormat } from '../../../../../shared/util/currency';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
//store
import { useGetEndCareHistorySessions } from '../../graphql/queries';
import { useRevealEndCareSessions } from '../../graphql/mutations';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers';
//components
import { showToast } from '../../../../../shared/components/Toast';
import { Button, Row } from 'react-bootstrap';
import { EndCareModalWindow } from '../../components/ModalWindow/ModalWindow';
import DataTable from '../../../../../shared/components/DataTable';
import { HorizontalDivider } from '../../../../../shared/components/Dividers';
import { ButtonAsLink } from '../../../../../shared/components/Buttons';
import ModalHistoryWindow from '../../components/ModalHistoryWindow';

interface ModalData {
  title: string;
  content: string;
  submit: string;
  isOpen: boolean;
}

export const EndOfCareHistoryExpandRow: React.FC<IEndCareEnrollmentExpandRowProps> = ({
  row,
  actionType,
  refetch,
}: IEndCareEnrollmentExpandRowProps) => {
  const entityId = useSelector((state: RootState) => state?.user?.entityId) ?? '';
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();
  const { t } = useTranslation(['billing', 'subsidies']);
  const [modalHistory, setModalHistory] = useState<boolean>(false);
  const [modal, setModal] = useState<ModalData>({
    title: '',
    content: '',
    submit: '',
    isOpen: false,
  });

  const { data: getEndCareHistorySessions, loading: loadingEndCareSessions } = useGetEndCareHistorySessions({
    input: {
      businessId: entityId,
      transactionId: row.transactionId,
    },
  });

  const [revealEndCareSessions, { loading: loadingRevealEndCareSessions }] = useRevealEndCareSessions({
    input: {
      accountChildId: row.accountChildId,
      businessId: entityId,
    },
  });

  const hideModal = () => {
    setModal({
      title: '',
      content: '',
      submit: '',
      isOpen: false,
    });
  };

  const handleRevealEndCareSessions = () => {
    revealEndCareSessions()
      .then(() => {
        showToast(t('subsidies:end-care.history-tab.message-toasts.success'), 'success');
        hideModal();
      })
      .then(() => refetch())
      .catch(() => {
        showToast(t('subsidies:end-care.history-tab.message-toasts.error'), 'error');
      });
  };

  const endCareSessions =
    getEndCareHistorySessions?.getEndCareHistorySessions?.map((i) => {
      return {
        id: i.id,
        date: formatDate(i.date, timezone),
        className: i.class.name,
        careType: getCareType(i.class.careType),
        // @ts-ignore
        absenceReason: getAbsenceReasonOptions(i?.absence?.type, i?.absence?.reason),
        sessionCost: currencyFormat(parseInt(i.cost.sessionCost, 10)),
        subsidyAmount: currencyFormat(i.endOfCareSubsidy),
      };
    }) ?? [];

  return (
    <>
      {actionType === 'Hide' && !loadingEndCareSessions && (
        <div className="d-flex justify-content-end position-relative">
          <Button
            variant="secondary"
            className="mr-2"
            onClick={() => {
              setModal({
                title: t('subsidies:end-care.history-tab.modal.title.show-sessions-again'),
                content: t('subsidies:end-care.history-tab.modal.text', {
                  child: row.childFirstName,
                  account: row.accountName,
                }),
                submit: t('subsidies:end-care.history-tab.modal.submit.show-session'),
                isOpen: true,
              });
            }}
          >
            {t('subsidies:end-care.history-tab.modal.submit.show-session')}
          </Button>
          <EndCareModalWindow modal={modal} hide={hideModal} submit={handleRevealEndCareSessions} />
        </div>
      )}
      <DataTable
        headerClasses={'kt-end-care-header'}
        keyField="id"
        data={endCareSessions ?? []}
        showSelect={false}
        showPagination={false}
        defaultSorting="asc"
        showLoadingOverlay={loadingEndCareSessions || loadingRevealEndCareSessions}
        columns={endOfCareActiveTableColumns}
      />
      {(actionType === 'Hide' || actionType === 'Show') && !loadingEndCareSessions && (
        <div>
          <HorizontalDivider />
          <Row>
            <ButtonAsLink className="mr-auto text-center ml-auto" onClick={() => setModalHistory(true)}>
              {t('subsidies:end-care.history-tab.view-history.title')}
            </ButtonAsLink>
          </Row>
        </div>
      )}
      {modalHistory && (
        <ModalHistoryWindow
          isOpen={modalHistory}
          onHide={() => setModalHistory(false)}
          accountID={row.accountChildId}
          timezone={timezone}
        />
      )}
    </>
  );
};
