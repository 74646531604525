import { Col, Row } from 'shared/components/Layout';
import { IDayOption } from 'shared/constants/enums/weekDayOptions';
import { toProperCase } from 'shared/util/string';
import { isTimeRangeInvalid } from 'shared/util/timeUtils';
import { isTimeslotOnWeekday } from '../helpers/TimeslotHelpers';
import TimeInput from '../../../../../../../../shared/components/TimePicker/TimeInput';

interface PickupDropoffInputsProps {
  weekDayOptions: IDayOption[];
  timeslots: ICreateContractTimeslotInput[];
  onTimeSlotUpdate: (time: string, key: 'pickUpTime' | 'dropOffTime', day: IDayOption) => void;
}

export function PickupDropoffInputs(props: PickupDropoffInputsProps) {
  const { timeslots, onTimeSlotUpdate, weekDayOptions } = props;
  return (
    <div className="pickup-dropoff-inputs">
      {timeslots.length > 0 && (
        <Row className="mb-2">
          <Col xs={4}>
            <label className="form-label">Scheduled Days</label>
          </Col>
        </Row>
      )}
      {weekDayOptions.map((day, i) => {
        const timeslot = timeslots.find((ts) => isTimeslotOnWeekday(day, ts));
        return (
          timeslot && (
            <div key={i} className="mb-2">
              <ul className="unstyled-list ">
                <Row>
                  <li
                    style={{
                      listStylePosition: 'inside',
                      border: '1px solid #c5d0de',
                      borderRadius: '3px',
                      width: '100%',
                    }}
                  >
                    <Col className="mt-1">
                      <div>{toProperCase(day.value)}</div>
                    </Col>
                    <Row>
                      <Col className="m-2">
                        <div className="font-size-12"> Drop Off </div>
                        <TimeInput
                          isAM={true}
                          value={timeslot?.dropOffTime ?? null}
                          onChange={(time) => onTimeSlotUpdate(time, 'dropOffTime', day)}
                        />
                      </Col>
                      <Col className="m-2">
                        <div className="font-size-12"> Pick Up </div>
                        <TimeInput
                          isAM={false}
                          value={timeslot?.pickUpTime ?? null}
                          onChange={(time) => onTimeSlotUpdate(time, 'pickUpTime', day)}
                        />
                      </Col>
                    </Row>
                  </li>
                </Row>
                {isTimeRangeInvalid(timeslot?.dropOffTime, timeslot?.pickUpTime) && (
                  <div className="text-danger small my-2">The pickup time must be after the drop off time.</div>
                )}
              </ul>
            </div>
          )
        );
      })}
    </div>
  );
}
