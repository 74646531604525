import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, get } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButtonCircle } from 'shared/components/Buttons';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import Select from 'shared/components/Select';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { AbsenseCategoryFormatting, ccsAbsenceCategoryEnum } from 'shared/constants/enums/ccssEnums';
import { accountStatusColorHexes } from 'shared/constants/tagColors';
import { capitalize, getInitials, stringToHsl } from 'shared/util/string';
import colors from '_colors.module.scss';
import { ColumnType, DataTableColumnFactory } from 'shared/components/DataTableColumnType/DataTableColumnFactory';

export interface IChildStatusOption {
  label: string;
  value: boolean;
}

interface ITableProps {
  data: ICcsAbsences[];
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  onSearchTermChange: (term: string) => void;
  onActiveFilterChange: (value: boolean | null) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  filteredStatus?: string[];
  onStatusFilter?: (status: string[]) => void;
}

const CcsAbsencesTable: React.FC<ITableProps> = ({
  data,
  loading,
  dataSize,
  pageSize,
  activePage,
  onPageChange,
  onSizePerPageChange,
  onSearchTermChange,
  onActiveFilterChange,
  onSort,
  filteredStatus,
  onStatusFilter,
}) => {
  const { t } = useTranslation();
  const childStatusOptions: { label: string; value: boolean }[] = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];

  const statusOptions: ITableFilterOption[] = [
    {
      label: capitalize(t('spelling.active')),
      value: 'Active',
      indicatorColor: accountStatusColorHexes.Active,
    },
    {
      label: capitalize(t('spelling.inactive')),
      value: 'Inactive',
      indicatorColor: accountStatusColorHexes.Inactive,
    },
  ];

  const [selectedChildStatus, setSelectedChildStatus] = useState<IChildStatusOption | null>(null);

  const handleChildStatusChanged = (option: IChildStatusOption | null) => {
    setSelectedChildStatus(option);
    onActiveFilterChange(option?.value ?? null);
  };

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <DataTable
      noPadding={true}
      data={data}
      dataSize={dataSize}
      showLoadingOverlay={loading}
      pageSize={pageSize}
      activePage={activePage}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      showSelect={false}
      showPagination={true}
      onSort={onSort}
      renderHeader={(paginationProps: any, searchProps: any) => (
        <TableHeader className="flex-wrap">
          <SizePerPage paginationProps={paginationProps} />
          <TableSearch placeholder="Search Accounts" onChange={onSearchTermChange} className="mr-4 my-2" />
          {onStatusFilter && (
            <>
              <label className="ml-4 flex-nowrap my-2">{capitalize(t('spelling.accountStatusFilter'))}</label>
              <DropdownFilter
                title={capitalize(t('spelling.status'))}
                className="ml-2 my-2"
                selectedFilters={filteredStatus}
                options={statusOptions}
                onFilterSelect={(values) => onStatusFilter(values.map((value) => value.value))}
              />
            </>
          )}
          <label className="ml-4 flex-nowrap my-2">{capitalize(t('spelling.childStatusFilter'))}</label>
          <Select
            name="childStatusFilter"
            options={childStatusOptions}
            onChange={(option: any) => handleChildStatusChanged(option)}
            className="ml-2 mr-4 my-2"
            value={selectedChildStatus}
          />
          <IconButtonCircle
            icon={faTimes}
            onClick={() => {
              handleChildStatusChanged(null);
              onStatusFilter && onStatusFilter(['Active']);
            }}
            tooltipDirection="bottom"
            tooltipText="Clear Filters"
            className="mr-4 my-2"
          />
        </TableHeader>
      )}
      columns={[
        {
          text: fieldLabels.center,
          dataField: 'centerName',
          sort: true,
        },
        {
          text: 'Account',
          dataField: 'accountName',
          sort: true,
          formatter: (cell: any, row: ICcsAbsences) =>
            DataTableColumnFactory.getColumn('accountName', ColumnType.ACCOUNT).render(row),
        },
        {
          text: 'Child',
          dataField: 'childName',
          sort: true,
          formatter: (cell: any, row: ICcsAbsences) => (
            <AvatarDataTableCell
              color={stringToHsl(row.child.id)}
              initials={getInitials(row.child)}
              primaryText={row.childName}
              avatar={row.child.avatar?.url ?? ''}
            />
          ),
        },
        {
          text: 'Days Used',
          dataField: 'absences',
          sort: true,
        },
        {
          text: 'Remaining',
          dataField: 'remaining',
          formatter: (cell: any, row: ICcsAbsences) => row.allowableAbsences - row.absences,
        },
        {
          text: 'Additional',
          dataField: 'additionalAbsences',
        },
        {
          text: 'Status',
          dataField: 'category',
          formatter: (cell: string, row: ICcsAbsences) => (
            <span>
              <FontAwesomeIcon
                className="mr-2"
                icon={faCircle}
                color={get(AbsenseCategoryFormatting, row.category, { color: colors.purple }).color}
                size="sm"
              />
              {find(ccsAbsenceCategoryEnum, (status) => status.value === row.category)?.label ?? 'Unknown'}
            </span>
          ),
        },
        {
          text: 'Active',
          dataField: 'active',
          sort: true,
          formatter: (cell: string, row: ICcsAbsences) => `${row.active ? 'Yes' : 'No'}`,
        },
      ]}
    />
  );
};

export default CcsAbsencesTable;
