import React, { useState } from 'react';
import moment from 'moment';
import CCSSMessagesTable from '../components/CCSSMessagesTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetCcsProviderMessages } from 'gql/ccssMessages/queries';
import { MsgStatusCodes } from 'shared/constants/enums/ccssMessageEnums';
import { useUpdateProviderMessage } from 'gql/ccssMessages/mutations';
import CCSSArchiveModal from '../components/CCSSArchiveModal';

interface IProps {
  selectedBusiness: ITableFilterOption | null;
}

interface ISort {
  field: string;
  direction: ElasticsearchSortDirection;
}

const ProviderMessagesTab: React.FC<IProps> = ({ selectedBusiness }) => {
  const businessId = selectedBusiness?.value || '';
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [openArchive, setOpenArchive] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState<ICcsMessage | null>(null);

  const [activeFilters, setActiveFilters] = useState<IMessagesFilter>({ status: MsgStatusCodes.UNREAD });
  const [tableState, tableFunctions] = useDatatableState();
  const [sort, setSortOptions] = useState<ISort[]>([{ field: 'created', direction: 'DESCENDING' }]);

  const isRangeValid = () => {
    if (dateRange.start !== '' && dateRange.end !== '') {
      return moment(dateRange.start).isAfter(moment(dateRange.end));
    }
    return true;
  };

  const { data, loading, refetch } = useGetCcsProviderMessages({
    variables: {
      input: {
        businessId,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        viewedStatus: activeFilters.status === MsgStatusCodes.ALL ? undefined : activeFilters.status,
        type: activeFilters.type ?? undefined,
        startDate:
          dateRange.start !== '' && isRangeValid() ? moment(dateRange.start).hour(0).utc().format() : undefined,
        endDate: dateRange.end !== '' && isRangeValid() ? moment(dateRange.end).hour(0).utc().format() : undefined,
        sources: activeFilters.source?.map((option) => option.value) || undefined,
        sortDtos: sort,
      },
    },
  });

  const [updateProviderMsg, { loading: updateProviderMsgLoading }] = useUpdateProviderMessage({
    onCompleted: (res) => {
      if (openArchive) {
        setOpenArchive(false);
        setSelectedMsg(null);
        refetch();
      }
    },
  });

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field, direction }]);
  };

  const handleFilterChange = (value: IMessagesFilter) => {
    setActiveFilters((state) => ({
      ...state,
      ...value,
    }));
  };

  const handleUpdateMsg = (msg: ICcsMessage | null) => {
    if (msg) {
      updateProviderMsg({
        variables: {
          input: {
            messageId: msg.id,
            viewedStatus: msg.viewedStatus,
            businessId: msg.businessId,
            messageType: msg.messageType,
          },
        },
      });
    }
  };

  const openModal = (msg: ICcsMessage) => {
    setOpenArchive(true);
    setSelectedMsg(msg);
  };

  const messages = data?.getCcssProviderMessages?.data || [];
  return (
    <>
      {selectedBusiness && (
        <CCSSMessagesTable
          businessId={businessId}
          setDateRange={setDateRange}
          dateRange={dateRange}
          loading={loading}
          data={messages}
          dataSize={data?.getCcssProviderMessages?.totalRecords || 0}
          pageSize={tableState.pageSize}
          activePage={tableState.activePage}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          activeFilters={activeFilters}
          onActiveFilterChange={handleFilterChange}
          onSort={handleSort}
          selectedRows={tableState.selectedRows}
          updateSelectedRows={tableFunctions.updateSelectedRows}
          archiveMsg={openModal}
          updateMsgStatus={handleUpdateMsg}
        />
      )}
      <CCSSArchiveModal
        isOpen={openArchive}
        onClose={() => setOpenArchive(false)}
        isLoading={updateProviderMsgLoading}
        msg={selectedMsg}
        archiveMsg={handleUpdateMsg}
      />
    </>
  );
};

export default ProviderMessagesTab;
