import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'shared/components/Layout';
import Card from 'shared/components/Card';
import { groupBy, orderBy } from 'lodash';
import ProfileLoadingCard from 'shared/components/LoadingSkeletons/ProfileCard';
import PrimaryContactCard from 'shared/components/PrimaryContactCard';
import DataTable from 'shared/components/DataTable';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import { PersonAvatar } from 'shared/components/Avatar';
import { getFullName, parsePhoneNumberWithRegion } from 'shared/util/string';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import SearchInput from 'shared/components/SearchInput';
import Select from 'shared/components/Select';
import { CreateButton } from 'shared/components/Buttons';
import { useHistory } from 'react-router';
import UpdatePrimaryContactRelationshipModal from './UpdatePrimaryContactRelationshipModal';
import EditContactPermissionsModal from './EditContactPermissionsModal';
import RemoveContactRelationshipModal from 'shared/components/Modals/RemoveContactRelationshipModal';
import ActionDropdown from 'shared/components/ActionDropdown';
import { faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import AddContactModal from './AddContactModal';
import { Link } from 'react-router-dom';
import Alert from 'shared/components/Alert';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IModalStateShape {
  open: boolean;
  contact: IChildContact | null;
}

interface IProps {
  child: IChild;
  loading: boolean;
}

const ContactsTab: React.FC<IProps> = ({ child, loading }) => {
  const history = useHistory();
  const accounts = orderBy(child?.accounts ?? [], 'name');
  const defaultAccountId = accounts[0]?.id ?? '';
  const [searchTerm, setSearchTerm] = useState('');
  const [accountId, setAccountId] = useState(defaultAccountId);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [updatePrimaryContactRelationshipModal, setUpdatePrimaryContactRelationshipModal] = useState<IModalStateShape>({
    open: false,
    contact: null,
  });
  const [contactInEdit, setContactInEdit] = useState<IChildContact | null>(null);
  const [contactToRemove, setContactToRemove] = useState<IChildContact | null>(null);

  const hasEditAccountContactPermission = useHasRoleAreaLevel({
    area: AreaType.Contact,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const hasDeleteAccountContactPermission = useHasRoleAreaLevel({
    area: AreaType.Contact,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });

  const contacts = orderBy(child?.contacts ?? [], 'firstname').filter(
    (c) =>
      c.accountPermissions?.map((acc) => acc.accountId).includes(accountId) &&
      (!searchTerm || `${getFullName(c)} ${c.email}`.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const { primaryContacts, nonPrimaryContacts } = groupBy(contacts, (c) =>
    c.accountPermissions.find((a) => a.accountId === accountId)?.isPrimary ? 'primaryContacts' : 'nonPrimaryContacts'
  );

  useEffect(() => setAccountId(defaultAccountId), [defaultAccountId]);

  const viewingArchivedAccount = useCallback((): boolean => {
    const visibleAccount = accounts.find((acc) => acc.id === accountId);
    const accountChild = visibleAccount?.children.find((ac) => ac.id === child.id);

    return accountChild?.archivedAt !== null ?? false;
  }, [accounts, accountId, child]);

  return (
    <>
      <div>
        <Card className="mb-4 kt-child-profile-contact-tab-filter-container" bodyClassName="p-4">
          <SearchInput onChange={setSearchTerm} />
          <Select
            isLoading={loading}
            options={accounts.map((a) => ({ value: a.id, label: `${a.center?.name ?? ''} - ${a.name}` }))}
            value={accountId}
            onChange={(o) => setAccountId(o.value)}
          />
        </Card>
        {viewingArchivedAccount() && <Alert variant="info">Child has been archived on this account</Alert>}
        <section>
          <Row noGutters>
            <h4 className="my-4 mr-4">Primary Contacts</h4>
            <HasRoleAreaLevel
              action={{ area: AreaType.Account, permission: PermissionType.Base, level: RoleLevelType.Read }}
            >
              <Link to={`/families/accounts/${accountId}/contacts`} className="small">
                Need to add a Primary Contact? Go to the {accounts.find((a) => a.id === accountId)?.name} account page.
              </Link>
            </HasRoleAreaLevel>
          </Row>
          {loading ? (
            <Row noGutters>
              {[1, 2].map((i) => (
                <ProfileLoadingCard className="mb-4 mr-4" key={i} />
              ))}
            </Row>
          ) : (
            <Row className="flex-wrap  mb-4">
              {primaryContacts?.map((contact, i) => (
                <Col lg={6} xl={4} key={i}>
                  <PrimaryContactCard
                    canRemove={false}
                    contact={contact}
                    showAdditionalActions={!viewingArchivedAccount()}
                    className="h-100 mb-4"
                    onClickEdit={() => setUpdatePrimaryContactRelationshipModal({ open: true, contact })}
                    onRemoveRelationship={() => {}}
                    onRemovePrimary={() => {}}
                  />
                </Col>
              ))}
            </Row>
          )}
        </section>
        <section>
          <Row noGutters className="mb-4">
            <h4 className="my-4">Contacts</h4>
            <HasRoleAreaLevel
              action={{ area: AreaType.Contact, permission: PermissionType.Base, level: RoleLevelType.Create }}
            >
              {!viewingArchivedAccount() && (
                <CreateButton className="ml-auto" onClick={() => setShowAddContactModal(true)}>
                  Add Contact
                </CreateButton>
              )}
            </HasRoleAreaLevel>
          </Row>
          {loading ? (
            <DataTableLoadingSkeleton />
          ) : (
            <div className="p-32px">
              <DataTable
                data={nonPrimaryContacts ?? []}
                showPagination={false}
                showSelect={false}
                columns={[
                  {
                    dataField: 'firstname',
                    text: 'Name',
                    classes: 'lg-column',
                    sort: true,
                    formatter: (name: string, contact: IChildContact) => (
                      <Link to={`/families/contacts/${contact.id}`} className="d-flex align-items-center">
                        <PersonAvatar size="md" person={contact} className="mr-4" />
                        <div className="text-text-color">{getFullName(contact)}</div>
                      </Link>
                    ),
                  },
                  {
                    dataField: 'relationshipType',
                    text: 'Relationship',
                    classes: 'lg-column',
                    sort: true,
                    formatter: (relationshipType: string) => (
                      <div className="tag" style={{ width: '140px' }}>
                        <div className="mx-auto">{childContactRelationship[relationshipType]}</div>
                      </div>
                    ),
                  },
                  {
                    dataField: 'accountPermissions',
                    text: 'Permission',
                    classes: 'lg-column',
                    formatter: (accountPermisions: IAccountPermissions[]) =>
                      accountPermisions.find((a) => a.accountId === accountId)?.permissions.includes('Pickup') ? (
                        <small className="bg-info-10 px-2 py-1 rounded border sm text-overflow-ellipsis">
                          Permitted to Pick Up
                        </small>
                      ) : (
                        ''
                      ),
                  },
                  {
                    classes: 'md-column',
                    dataField: 'primaryPhoneNumber.number',
                    text: 'Phone Number',
                    formatter: (pn: string) => (pn ? parsePhoneNumberWithRegion(pn).formatNational() : ''),
                  },
                  ...(!viewingArchivedAccount()
                    ? [
                        {
                          dataField: 'actions',
                          isDummyField: true,
                          align: 'center',
                          formatter: (cell: any, contact: IChildContact) =>
                            !hasEditAccountContactPermission ? (
                              ''
                            ) : (
                              <ActionDropdown
                                actions={[
                                  ...(hasEditAccountContactPermission
                                    ? [
                                        {
                                          label: 'Edit Permissions',
                                          onClick: () => setContactInEdit(contact),
                                          icon: faPencil,
                                        },
                                      ]
                                    : []),
                                  ...(hasDeleteAccountContactPermission
                                    ? [
                                        {
                                          label: 'Remove Contact',
                                          onClick: () => setContactToRemove(contact),
                                          icon: faTrashAlt,
                                        },
                                      ]
                                    : []),
                                ]}
                              />
                            ),
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          )}
        </section>
      </div>
      {updatePrimaryContactRelationshipModal.contact && (
        <UpdatePrimaryContactRelationshipModal
          isOpen={updatePrimaryContactRelationshipModal.open}
          contact={updatePrimaryContactRelationshipModal.contact}
          child={child}
          onClose={() => setUpdatePrimaryContactRelationshipModal({ open: false, contact: null })}
        />
      )}
      {child && (
        <AddContactModal
          isOpen={showAddContactModal}
          onClose={() => setShowAddContactModal(false)}
          child={child}
          accountId={accountId}
        />
      )}
      {contactInEdit && child && (
        <EditContactPermissionsModal
          child={child}
          contact={contactInEdit}
          isOpen={Boolean(contactInEdit)}
          onClose={() => setContactInEdit(null)}
          accountId={accountId}
        />
      )}
      {contactToRemove && child && (
        <RemoveContactRelationshipModal
          childId={child.id}
          childName={getFullName(child)}
          contactId={contactToRemove.id}
          contactName={getFullName(contactToRemove)}
          accountPermissions={contactToRemove.accountPermissions}
          isOpen={Boolean(contactToRemove)}
          onClose={() => setContactToRemove(null)}
          defaultAccountIdToRemove={accountId}
        />
      )}
    </>
  );
};

export default ContactsTab;
