import * as types from './types';
import moment from 'moment';
import { TABLE_DEFAULTS } from 'shared/components/DataTable';

export interface IAgencyPaymentsTableFilters {
  searchTerm: string;
  sortColumn: string;
  sortDirection: 'asc' | 'desc';
  startDate: string;
  endDate: string;
  centerIds: string[];
  agencyIds: string[];
  completed: {
    pageNumber: number;
    pageSize: number;
  };
  draft: {
    pageNumber: number;
    pageSize: number;
  };
}

export interface IAgencyPaymentsStateShape {
  tableData: {
    completed: IPagedResult<IAgencyPayment>;
    draft: IPagedResult<IAgencyPayment>;
  };
  tableFilters: IAgencyPaymentsTableFilters;
}

const initialState: IAgencyPaymentsStateShape = {
  // response objects from api
  tableData: {
    completed: {
      data: [],
      pageNumber: TABLE_DEFAULTS.PAGE,
      pageSize: TABLE_DEFAULTS.PAGE_SIZE,
      totalRecords: 0,
    },
    draft: {
      data: [],
      pageNumber: TABLE_DEFAULTS.PAGE,
      pageSize: TABLE_DEFAULTS.PAGE_SIZE,
      totalRecords: 0,
    },
  },
  tableFilters: {
    searchTerm: '',
    sortColumn: 'payPeriod',
    sortDirection: 'desc',
    // default timeframe should be the current quarter
    startDate: moment().startOf('quarter').format(),
    endDate: moment().endOf('quarter').format(),
    centerIds: [],
    agencyIds: [],
    completed: {
      pageNumber: TABLE_DEFAULTS.PAGE,
      pageSize: TABLE_DEFAULTS.PAGE_SIZE,
    },
    draft: {
      pageNumber: TABLE_DEFAULTS.PAGE,
      pageSize: TABLE_DEFAULTS.PAGE_SIZE,
    },
  },
};

export const agencyPaymentReducers = (
  state: IAgencyPaymentsStateShape = initialState,
  action: types.AgencyPaymentActionTypes
): IAgencyPaymentsStateShape => {
  switch (action.type) {
    case types.GET_AGENCY_PAYMENTS:
      return {
        ...state,
        tableData: {
          completed: action.completedPayments,
          draft: action.draftPayments,
        },
      };
    case types.CREATE_AGENCY_PAYMENT:
      const tableDataToInsert = { ...state.tableData };

      if (!action.payment.submittedBy) {
        tableDataToInsert.draft = {
          ...tableDataToInsert.draft,
          data: [...tableDataToInsert.draft.data, action.payment],
          totalRecords: tableDataToInsert.draft.totalRecords + 1,
        };
      } else {
        tableDataToInsert.completed = {
          ...tableDataToInsert.completed,
          data: [...tableDataToInsert.completed.data, action.payment],
          totalRecords: tableDataToInsert.completed.totalRecords + 1,
        };
      }

      return {
        ...state,
        tableData: { ...tableDataToInsert },
      };
    case types.UPDATE_AGENCY_PAYMENT:
      // only UNSUBMITTED payments can be edited. we are assuming that this payment couldn't be in `completed`
      return {
        ...state,
        tableData: {
          ...state.tableData,
          draft: {
            ...state.tableData.draft,
            data: state.tableData.draft.data.map((payment) =>
              payment.id === action.payment.id ? { ...action.payment } : payment
            ),
          },
        },
      };
    case types.DELETE_AGENCY_PAYMENT:
      // only UNSUBMITTED payments can be deleted. we are assuming that this payment couldn't be in `completed`
      return {
        ...state,
        tableData: {
          ...state.tableData,
          draft: {
            ...state.tableData.draft,
            data: state.tableData.draft.data.filter((payment) => payment.id !== action.payment.id),
            totalRecords: state.tableData.draft.data.map((payment) => payment.id).includes(action.payment.id)
              ? state.tableData.draft.totalRecords - 1
              : state.tableData.draft.totalRecords,
          },
        },
      };
    case types.UPDATE_AGENCY_PAYMENT_TABLE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.filters,
        },
      };
    default:
      return state;
  }
};
