import React from 'react';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { Card } from 'react-bootstrap';
import emptyState from '../assets/contactsEmptyState.png';
import { Col, Row } from 'shared/components/Layout';

interface IProps {}

const ContactsEmptyState: React.FC<IProps> = ({}) => {
  return (
    <PageWrapperBody className="text-center">
      <h2 className="my-8">
        <strong>Contacts</strong>
      </h2>
      <h4 className="text-dark-gray mb-8">It sure is empty in here. Add an account to get started.</h4>
      <Card className="mx-auto p-10" style={{ maxWidth: '625px' }}>
        <Row>
          <Col>
            <img src={emptyState} alt="contacts with child illustration" className="img-fluid mx-auto" />
          </Col>
        </Row>
      </Card>
    </PageWrapperBody>
  );
};

export default ContactsEmptyState;
