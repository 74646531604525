import React, { useState, useCallback } from 'react';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import moment from 'moment';
import AvailabilityInputs from 'shared/components/AvailabilityInputs';
import {
  IAvailabilityObject,
  emptyWeek,
  areTimesOverlapped,
} from 'shared/components/AvailabilityInputs/AvailabilityInputs';
import { useUpdateAvailability } from 'gql/staff/mutations';
import { showToast } from 'shared/components/Toast';
import { isValid24HourString } from 'shared/util/timeUtils';

interface IProps {
  staff: IStaff;
  readOnly?: boolean;
}

const AvailabilityForm: React.FC<IProps> = ({ staff, readOnly = false }) => {
  const currentStaffAvailabilityObject: IAvailabilityObject = staff.availability?.approved.reduce(
    (prevVal, dayAvailability) => ({
      ...prevVal,
      [dayAvailability.dayOfWeek]: {
        ...dayAvailability,
        dayEnabled: true,
        times: dayAvailability.times.map((time) => ({
          start: moment()
            .hours(parseInt(time.start.split(':')[0]))
            .minutes(parseInt(time.start.split(':')[1]))
            .seconds(0),
          end: moment()
            .hours(parseInt(time.end.split(':')[0]))
            .minutes(parseInt(time.end.split(':')[1]))
            .seconds(0),
          startTimeString: time.start,
          endTimeString: time.end,
        })),
      },
    }),
    {}
  );

  const [availability, updateAvailability] = useState<IAvailabilityObject>({
    ...emptyWeek,
    ...currentStaffAvailabilityObject,
  });
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [saveAvailability, { loading }] = useUpdateAvailability();

  const save = useCallback(() => {
    saveAvailability({
      variables: {
        input: {
          personId: staff.id,
          days: Object.values(availability)
            .filter((a) => a.dayEnabled)
            .map((a) => ({
              dayOfWeek: a.dayOfWeek,
              times: a.times.map((t) => ({ start: t.start.format('HH:mm'), end: t.end.format('HH:mm') })),
            })),
        },
      },
    })
      .then(() => {
        showToast('Availability updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch(() => {
        showToast('There was an error updating availability. Please try again later.', 'error');
      });
  }, [availability, saveAvailability, staff.id]);

  const overlappingTimeRange = Object.values(availability).some((a) =>
    a.times.some((t1, i1) => a.times.some((t2, i2) => i1 !== i2 && areTimesOverlapped(t1, t2)))
  );
  const invalidTimeRange = Object.values(availability).some((a) => a.times.some((t) => t.start?.isAfter(t.end)));
  const invalidTimeStrings = Object.values(availability).some((a) =>
    a.times.some((t) => !isValid24HourString(t.startTimeString) || !isValid24HourString(t.endTimeString))
  );
  const formInvalid = overlappingTimeRange || invalidTimeRange || invalidTimeStrings;

  return (
    <FormWrapper2
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onSave={save}
      onCancel={() => {
        updateAvailability({ ...emptyWeek, ...currentStaffAvailabilityObject });
      }}
      saveDisabled={formInvalid}
      loading={loading}
      toggleDirtyOnSave={false}
    >
      <AvailabilityInputs
        readOnly={readOnly}
        availability={availability}
        updateAvailability={updateAvailability}
        onChange={() => setFormIsDirty(true)}
      />
    </FormWrapper2>
  );
};

export default AvailabilityForm;
