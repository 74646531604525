import { useArchiveMedicalCondition } from 'gql/medicalCondition/mutations';
import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  medicalCondition: IMedicalCondition;
}

const ArchiveMedicalConditionModal: React.FC<IProps> = ({ isOpen, onClose, medicalCondition }) => {
  const [archiveMedicalCondition, { loading }] = useArchiveMedicalCondition();

  const handleSave = useCallback(() => {
    archiveMedicalCondition({
      variables: {
        id: medicalCondition.id,
      },
    })
      .then(() => {
        showToast('Medical condition archived.', 'success');
        onClose();
      })
      .catch(() => {
        showToast('There was an error archiving medical condition.', 'error');
      });
  }, [onClose, medicalCondition]);

  return (
    <ConfirmationModal
      title="Archive Medical Condition?"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Archive"
      primaryCallback={handleSave}
      primaryButtonProps={{ loading, variant: 'danger' }}
      hideOnCallback={false}
    >
      <div className="mb-4">
        Are you sure you want to archive this person's {medicalCondition.name} medical condition?
      </div>
      <div>The history for this condition will remain viewable, but not editable, once it's archived.</div>
    </ConfirmationModal>
  );
};

export default ArchiveMedicalConditionModal;
