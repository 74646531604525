import React, { useState } from 'react';
import Column from 'react-bootstrap/Col';
import { Row } from 'shared/components/Layout';
import ReEnrollAccounts from './Tabs/ReEnrollAccounts';
import { Tab, Tabs } from 'react-bootstrap';
import ReEnrollHistoryOfActions from './Tabs/ReEnrollHistoryOfActions';

interface IProps {}

type ReEnrollementTab = 'accounts' | 'history';

const ReEnrollment: React.FC<IProps> = ({ ...props }) => {
  const [tabKey, setTabKey] = useState<ReEnrollementTab>('accounts');

  return (
    <Column className="p-0">
      <Row>
        <Column>
          <Tabs
            id="enrollment-tabs"
            defaultActiveKey="overview"
            activeKey={tabKey}
            onSelect={(key) => setTabKey(key as ReEnrollementTab)}
          >
            <Tab eventKey="accounts" title="Send Requests">
              {tabKey === 'accounts' && <ReEnrollAccounts />}
            </Tab>
            <Tab eventKey="history" title="History">
              {tabKey === 'history' && <ReEnrollHistoryOfActions />}
            </Tab>
          </Tabs>
        </Column>
      </Row>
    </Column>
  );
};

export default ReEnrollment;
