import React, { useState, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { toProperCase } from 'shared/util/string';

interface IProps {
  timeControlType: 'hours' | 'minutes';
  placeholder: string;
  value: string;
  onButtonClick: (step: number) => void; // controls the arrow up/down arrows. since this component is used to represent hours or minutes, clicking an arrow should increment or decrement the time type by 1
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ref: React.Ref<HTMLInputElement>;
}

const TimeControl: React.FC<IProps> = React.forwardRef<HTMLInputElement, IProps>(
  ({ timeControlType, placeholder, value, onButtonClick, onKeyDown, onChange, ...props }, ref) => {
    // copy value to state so we can hold it as undefined here without updating parent value
    const [inputValue, updateInputValue] = useState(value);

    const handleChange = useCallback(
      (event) => {
        if (/^[0-9]*$/.test(event.target.value)) {
          onChange(event);
          updateInputValue(event.target.value);
        }
      },
      [onChange]
    );

    useEffect(() => {
      value && updateInputValue(value); // need to update our state value if parent value changes due to key or click events
    }, [value]);

    return (
      <div className="d-flex flex-column kt-timepicker-control-container">
        <button className="kt-timepicker-btn" onClick={() => onButtonClick(1)}>
          <FontAwesomeIcon icon={faChevronUp} />
          <span className="sr-only">Increment {toProperCase(timeControlType)}</span>
        </button>
        <input
          ref={ref}
          type="text"
          name={timeControlType}
          inputMode="numeric"
          className="text-center kt-timepicker-time-input"
          aria-label={toProperCase(timeControlType)}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          autoComplete="off"
          value={inputValue}
        />
        <button className="kt-timepicker-btn" onClick={() => onButtonClick(-1)}>
          <FontAwesomeIcon icon={faChevronDown} />
          <span className="sr-only">Decrement {toProperCase(timeControlType)}</span>
        </button>
      </div>
    );
  }
);

export default TimeControl;
