import Currency from 'shared/components/Currency';
import { IKindyForAllCenterSummary } from 'shared/types/kindyForAll';

interface IProps {
  year: number;
  summary?: IKindyForAllCenterSummary;
}

const ForecastCenterSummaryExpandRow: React.FC<IProps> = ({ year, summary }) => {
  return (
    <div className="py-4">
      {year >= 2023 && (
        <section className="k2-subsidy-forecast-center-summary-expanded-row-section">
          <div>
            <>
              <h6>Base Subsidy</h6>
              <Currency direction="Positive" amount={summary?.baseAmount ?? 0} />
            </>
          </div>

          <div>
            <>
              <h6>Service Location</h6>
              <Currency direction="Positive" amount={summary?.ariaTeacherAmount ?? 0} />
            </>
          </div>

          <div>
            <>
              <h6>Free Kindy</h6>
              <Currency direction="Positive" amount={summary?.freeKindyAmount ?? 0} />
            </>
          </div>

          <div>
            <>
              <h6>Inclusion Ready</h6>
              <Currency direction="Positive" amount={summary?.universalInclusionAmount ?? 0} />
            </>
          </div>
        </section>
      )}
    </div>
  );
};

export default ForecastCenterSummaryExpandRow;
