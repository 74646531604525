import React, { useEffect, useMemo, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Card from 'shared/components/Card';
import DataTable, { SizePerPage, TableHeader } from 'shared/components/DataTable';
import { RootState } from 'store/reducers';
import { capitalize, isEmpty } from 'lodash';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import { STATES, US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useGetCenterStatesInUse } from 'shared/hooks/useGetCenterStatesInUse';
import './styles.scss';
import useDatatableState from 'shared/hooks/useDatatableState';
import { SortDirection, useGetReEnrolCenterSettingsQuery } from 'generated/graphql';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { TagsTableCell } from 'shared/components/Tag';
import { useTranslation } from 'react-i18next';
import FilterGroup from 'pages/Centers/subroutes/Profiles/components/CenterProfilesTable/FilterGroup';
import { showToast } from 'shared/components/Toast';
import Button from 'shared/components/Buttons';
import RadioButton from 'shared/components/RadioButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';
import { orderBy } from 'lodash';

interface IProps {
  show: boolean;
  setSelectedCenters: (centers: any) => void;
  selectedCenters: any[];
  onCancel: () => void;
  onSubmit: (requireEnrollmentForm: boolean | null) => void;
  loading: boolean;
  accountsTotal: number;
}
const ReEnrollSendRequestsModal: React.FC<IProps> = ({
  show,
  selectedCenters,
  accountsTotal,
  onCancel,
  setSelectedCenters,
  onSubmit,
  loading: submitLoading,
}) => {
  const [custom, setCustom] = useState(false);
  const [requireEnrollmentForm, setRequireEnrollmentForm] = useState<null | boolean>(true);
  const { t } = useTranslation(['translation', 'enrollment']);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];
  const tagsOptions: ITableFilterOption[] = orderBy(tags ?? [], (tag) => tag.name.toLocaleLowerCase(), 'asc').map(
    (tag) => ({ label: tag.name, value: tag.id })
  );

  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('center', initalTableSort);
  const currentUser = useSelector((state: RootState) => state.user);
  const isInternalUser = currentUser?.isInternal;
  const { data: getAllowedEntitiesData } = useGetAllowedEntities(!isInternalUser);

  const { data: activeCenters, refetch } = useGetReEnrolCenterSettingsQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      input: {
        filter: { all: [] },
        from: 0,
        size: 2000,
        sort: [
          {
            field: 'name.keyword',
            direction: SortDirection.Ascending,
          },
        ],
      },
      input2: { businessId: businessId ?? '' },
    },
    onError: (err) => showToast(t('enrollment:reenroll-center-settings.get-error'), 'error'),
  });

  const hasReadCenterTagsPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Tags,
    level: RoleLevelType.Read,
  });

  const statesInUse: string[] = useGetCenterStatesInUse()?.data?.getCenterStatesUsedAcrossEntity || [];
  const states =
    !!statesInUse && statesInUse.length > 0
      ? US_STATE_SELECT_OPTIONS.filter((state) => statesInUse.includes(state.value))
      : US_STATE_SELECT_OPTIONS;

  const handleClose = () => {
    tableFunctions.updateSelectedFilters({ status: [], state: [], tags: [], entity: [] });
    onCancel();
  };

  const displayCenters = useMemo(() => {
    let centersData = activeCenters?.searchCenters.data;
    const searchText = tableState.searchExpressions?.[0]?.any?.[0]?.term?.value;
    const { tags, state, status } = tableState.selectedFilters;
    if (!isEmpty(searchText)) {
      centersData = centersData?.filter((c) => c.name?.toLowerCase().includes(searchText?.toLowerCase() ?? ''));
    }
    if (!isEmpty(tags) && (tags?.length ?? 0) > 0) {
      const tagIds = tags?.map((t) => t.value);
      centersData = centersData?.filter((c) => c.tags.some((t) => tagIds?.includes(t.id)));
    }

    if (!isEmpty(state)) {
      const stateValues = state?.map((t) => t.value);
      centersData = centersData?.filter((c) => stateValues?.includes(c.address?.state ?? ''));
    }

    const formattedTableData = centersData?.map((center) => ({
      ...center,
      address: {
        ...center.address,
        state: { keyword: STATES[center.address?.state ?? ''] },
      },
    }));
    return { data: formattedTableData, totalRecords: formattedTableData?.length ?? 0 };
  }, [activeCenters, tableState?.selectedFilters, tableState?.searchExpressions]);

  useEffect(() => {
    if (!show) {
      setCustom(false);
      setSelectedCenters(undefined);
    }
  }, [show, setCustom, setSelectedCenters]);
  return (
    <Modal
      className="send-requests-modal"
      centered
      backdrop="static"
      show={show}
      onHide={handleClose}
      scrollable={true}
      size="xl"
      fullscreen={true}
    >
      <Modal.Header closeButton className="px-6 py-4">
        <Modal.Title as="h5">
          {t('enrollment:reenroll-send-request.modal-title', { totalAccounts: accountsTotal })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-6 px-8">
        <p className="mb-1 title">
          {t('enrollment:reenroll-send-request.require-enrolment-form.title')}
          <HelpTooltip
            text={t('enrollment:reenroll-send-request.require-enrolment-form.tooltip')}
            direction="right"
            size="lg"
          />
        </p>
        <div className="mb-5">
          <RadioButton
            className="radio-margin-0 mr-2 pt-0"
            label={t('enrollment:reenroll-send-request.require-enrolment-form.yes-text')}
            value={requireEnrollmentForm === true}
            onChange={() => setRequireEnrollmentForm(true)}
          />
          <RadioButton
            className="radio-margin-0 mr-2 pt-0"
            label={t('enrollment:reenroll-send-request.require-enrolment-form.no-text')}
            value={requireEnrollmentForm === false}
            onChange={() => setRequireEnrollmentForm(false)}
          />
          <RadioButton
            className="radio-margin-0 mr-2 pt-0"
            label={t('enrollment:reenroll-send-request.require-enrolment-form.center-default-text')}
            value={requireEnrollmentForm === null}
            onChange={() => setRequireEnrollmentForm(null)}
          />
        </div>
        <p className="mb-1 title">{t('enrollment:reenroll-send-request.select-centers')}</p>
        <Card
          className="reenroll-send-request-type-card"
          onClick={() => {
            setCustom(false);
            setSelectedCenters(undefined);
          }}
        >
          <Form.Check
            inline
            label={<p className="mb-0">{t('enrollment:reenroll-send-request.one-center-option')}</p>}
            name="send-request-type"
            id="center-accounts-only"
            type="radio"
            className="mb-0"
            checked={!custom}
            onChange={() => setCustom(false)}
          />
          <ul className="mb-0">
            <li>{t('enrollment:reenroll-send-request.one-center-option-text')}</li>
          </ul>
        </Card>
        <Card className="reenroll-send-request-type-card" onClick={() => setCustom(true)}>
          <Form.Check
            inline
            label={<p className="mb-0">{t('enrollment:reenroll-send-request.multi-center-option')}</p>}
            name="send-request-type"
            id="custom-centers"
            type="radio"
            checked={custom}
            className="mb-0"
            onChange={() => setCustom(true)}
          />
          <ul className="mb-0">
            <li>{t('enrollment:reenroll-send-request.multi-center-option-text')}</li>
          </ul>
        </Card>
        {custom && (
          <DataTable
            data={displayCenters?.data ?? []}
            dataSize={displayCenters?.totalRecords}
            showLoadingOverlay={false}
            showSelect={true}
            className="standard-table"
            remote={{ pagination: false, sort: false }}
            selectedRows={selectedCenters}
            multiPageSelect
            updateSelectedRows={setSelectedCenters}
            onPageChange={tableFunctions.changePage}
            activePage={tableState.activePage}
            pageSize={tableState.pageSize}
            onSizePerPageChange={(sizePerPage: number) => {
              if (tableState.activePage !== 1) tableFunctions.changePage(1, sizePerPage);
              tableFunctions.changeSizePerPage(sizePerPage);
            }}
            columns={[
              {
                text: `${capitalize(t('translation:spelling.center'))} Name`,
                dataField: 'name',
              },
              {
                text: 'State',
                dataField: 'address.state.keyword',
                sort: true,
              },
              hasReadCenterTagsPermission && {
                text: 'Tags',
                dataField: 'tags',
                formatter: (cell: ITag[]) => {
                  const centerTags: ITag[] | undefined = cell?.map((t) => {
                    return {
                      id: t?.id ?? '',
                      name: t?.name ?? '',
                      entityId: businessId ?? '',
                      category: 'CENTER',
                    };
                  });
                  return centerTags && <TagsTableCell tags={centerTags ?? []} />;
                },
              },
            ]}
            renderHeader={(paginationProps: any) => (
              <TableHeader className="d-flex flex-row align-items-center flex-wrap flex-grow-1">
                <SizePerPage paginationProps={paginationProps} />
                <FilterGroup
                  hideStatus
                  tableState={tableState}
                  tableFunctions={tableFunctions}
                  tags={tagsOptions}
                  states={states}
                  entities={getAllowedEntitiesData?.getAllowedEntities || []}
                  autoSelectedBusinessId={businessId ?? undefined}
                />
              </TableHeader>
            )}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="px-4 py-2">
        <Button variant="light" className="mr-3" onClick={handleClose}>
          {capitalize(t('translation:spelling.cancel'))}
        </Button>
        <Button loading={submitLoading} variant="primary" onClick={() => onSubmit(requireEnrollmentForm)}>
          Send Requests
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReEnrollSendRequestsModal;
