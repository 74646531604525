import React, { useCallback, useState } from 'react';
import { CreateButton } from 'shared/components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import moment from 'moment';
import CreateTransactionModal from 'shared/components/CreateTransactionModal';
import { useGetAccountTransactionsInTimeframe } from 'gql/transaction/queries';
import CancelPaymentModal from 'pages/BillingPayments/components/CancelPaymentModal';
import { useApproveFlaggedPayment } from 'gql/transaction/mutations';
import { updateTransaction } from 'pages/BillingTransactions/duck/action';
import { showToast } from 'shared/components/Toast';
import { ICancelPaymentModalStateShape } from 'pages/BillingPayments/Payments';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import AccountTransactionsTable from './AccountTransactionsTable';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  account?: IAccount;
  accountId: string;
}

const AccountTransactions: React.FC<IProps> = ({ accountId }) => {
  const dispatch = useDispatch();

  const hasCreatedBaseTransactionPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });

  const { week } = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;
  const [createTransactionModalIsOpen, setCreateTransactionModalIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf(week).subtract(1, 'week').format(),
    end: moment().endOf(week).format(),
  });

  const transactions: ITransactionsWithBalance[] = useSelector((state: RootState) => state.billing.transactions.all)
    .map((t) => ({ ...t, balanceSnapshot: null }))
    .filter((t) => {
      return t.account.id === accountId;
    })
    .sort((a, b) => (a.date > b.date ? 1 : -1 && a.transactionNumber > b.transactionNumber ? 1 : -1));

  const { loading, networkStatus } = useGetAccountTransactionsInTimeframe({
    variables: {
      accountId,
      start: moment(dateRange.start).format('YYYY-MM-DD'),
      end: moment(dateRange.end).format('YYYY-MM-DD'),
    },
  });

  const [cancelFlaggedPaymentModalState, setCancelFlaggedPaymentModalState] = useState<ICancelPaymentModalStateShape>({
    open: false,
    payment: null,
  });

  const [approveFlaggedPaymentFn] = useApproveFlaggedPayment({
    onCompleted: (result) => {
      if (result.approvePayment) {
        dispatch(updateTransaction(result.approvePayment));
        showToast('Approved payment successfully.', 'success');
      }
    },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleApprovePayment = useCallback(
    (payment: IPayment) => {
      if (payment) {
        approveFlaggedPaymentFn({
          variables: {
            paymentId: payment.id,
          },
        });
      }
    },
    [approveFlaggedPaymentFn]
  );

  return (
    <div className="mx-auto">
      <div>
        <div className="d-flex align-items-center mb-4">
          <h3>Transactions</h3>
          {hasCreatedBaseTransactionPermissions && (
            <CreateButton className="ml-auto" onClick={() => setCreateTransactionModalIsOpen(true)}>
              Add Transaction
            </CreateButton>
          )}
        </div>
        {!transactions ||
        (loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES && networkStatus !== NETWORK_STATUS.REFETCH) ? (
          <DataTableLoadingSkeleton />
        ) : (
          <AccountTransactionsTable
            data={transactions}
            loading={loading}
            dateRange={dateRange}
            setDateRange={setDateRange}
            onApproveFlaggedPayment={handleApprovePayment}
            onRejectFlaggedPayment={(payment: IPayment) => setCancelFlaggedPaymentModalState({ open: true, payment })}
            noPadding={true}
            accountId={accountId}
          />
        )}
        {createTransactionModalIsOpen && (
          <CreateTransactionModal
            isOpen={createTransactionModalIsOpen}
            onClose={() => setCreateTransactionModalIsOpen(false)}
            accountId={accountId}
          />
        )}
        <CancelPaymentModal
          isOpen={cancelFlaggedPaymentModalState.open}
          payment={cancelFlaggedPaymentModalState.payment}
          onClose={() => setCancelFlaggedPaymentModalState({ open: false, payment: null })}
        />
      </div>
    </div>
  );
};

export default AccountTransactions;
