import React, { useCallback, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { ToggleButton } from '../Buttons';

import PaymentMethodSelect from '../PaymentMethodSelect';
import Switch from '../Switch';
import { IProps as ISwitchProps } from '../Switch/Switch';
import Checkbox from '../Checkbox';
import { useTranslation } from 'react-i18next';
interface IProps {
  isOpen: boolean;
  title: string;
  description: string;
  loading: boolean;
  paymentMethods: IPaymentMethod[];
  selectedMethod: IPaymentMethod | null;
  collapseText: string;
  handleSubmit: () => void;
  onHide: () => void;
  onChange: (paymentMethod: IPaymentMethod) => void;
  showToggle?: boolean;
  switchProps?: ISwitchProps;
  disabled?: boolean;
  selectDisabled?: boolean;
  setTermsAndConditionsConfirmed: (value: boolean) => void;
  termsAndConditionsConfirmed: boolean;
}

const PaymentMethodSelectionModal: React.FC<IProps> = ({
  isOpen,
  title,
  description,
  collapseText,
  loading,
  selectedMethod,
  paymentMethods,
  showToggle,
  switchProps,
  disabled = false,
  selectDisabled = false,
  termsAndConditionsConfirmed,
  handleSubmit,
  onChange,
  onHide,
  setTermsAndConditionsConfirmed,
}) => {
  const { t } = useTranslation(['billing']);
  return (
    <SideModalDrawer
      show={isOpen}
      title={title}
      primaryChoice="Save"
      primaryCallback={handleSubmit}
      primaryButtonProps={{ disabled, loading }}
      closeOnPrimaryCallback={false}
      onHide={onHide}
      secondaryCallback={onHide}
    >
      <p>{description}</p>
      {showToggle && <Switch value={false} onChange={() => {}} {...switchProps} className="mb-6" />}
      <ul className="unstyled-list">
        {paymentMethods.map((paymentMethod: IPaymentMethod, idx: number) => (
          <>
            <PaymentMethodSelect
              paymentMethod={paymentMethod}
              key={paymentMethod.id}
              isSelected={selectedMethod?.id === paymentMethod.id}
              collapseText={collapseText}
              disabled={selectDisabled}
              onChange={() => onChange(paymentMethod)}
            />
          </>
        ))}
      </ul>
    </SideModalDrawer>
  );
};

export default PaymentMethodSelectionModal;
