import React from 'react';
import { capitalize, isEmpty } from 'lodash';
import * as Mui from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import ChannelsTableRow from './ChannelsTableRow';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import { StatisticChannel } from 'shared/types/channel';

interface IProps {
  channelList: StatisticChannel[] | [];
  channelListLoading: boolean;
  centerId: string;
  // refetch: () => void;
  from: number;
  setFrom: (x: number) => void;
  currentPage: number;
  setCurrentPage: (x: number) => void;
  pageRecordSize: number;
  setPageRecordSize: (x: number) => void;
  totalItems: number;
  // nameSortDirection: string;
  // setNameSortDirection: (x: string) => void;
}

const columns = [
  { name: 'Child', displayLabel: true, alignLeft: true },
  { name: 'Date and Time', displayLabel: true, alignLeft: true },
  { name: 'Unread', displayLabel: true, alignLeft: false },
  { name: 'Flagged', displayLabel: true, alignLeft: false },
  { name: 'Daily Avg', displayLabel: true, alignLeft: false },
  { name: 'Total', displayLabel: true, alignLeft: false },
];

const ChannelsTable: React.FC<IProps> = ({
  channelList,
  channelListLoading,
  centerId,
  // refetch,
  from,
  setFrom,
  currentPage,
  setCurrentPage,
  // resetCurrentPage,
  pageRecordSize,
  setPageRecordSize,
  totalItems,
  // nameSortDirection,
  // setNameSortDirection,
  // ...props
}) => {
  // const [order, setOrder] = React.useState<Order>('asc');
  // const [orderBy, setOrderBy] = React.useState<keyof Data>('name');

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    setFrom(newPage + 1);
  };

  const StyledTableCell = Mui.styled(Mui.TableCell)({
    paddingBottom: 0,
  });

  return (
    <>
      {channelListLoading ? (
        <LoadingLines number={4} />
      ) : (
        <Mui.Box sx={{ width: '100%' }}>
          <Mui.Paper sx={{ mb: 2 }} elevation={0}>
            <Mui.TableContainer>
              <Mui.Table aria-labelledby="royalty-fees-table" style={{ width: '100%' }}>
                <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
                  <Mui.TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.name}
                        align={column.alignLeft ? 'left' : 'center'}
                        padding={'normal'}
                      >
                        <Mui.Typography variant="tableHeadCells">{column.displayLabel && column.name}</Mui.Typography>
                      </StyledTableCell>
                    ))}
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {!channelListLoading &&
                    channelList.map((row: StatisticChannel, index) => (
                      <ChannelsTableRow row={row} index={index} centerId={centerId} key={`channelTable-${index}`} />
                    ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
            {isEmpty(channelList) && (
              <Mui.Box
                sx={{ width: '100%', margin: 0 }}
                display="flex"
                bgcolor="white"
                alignItems="center"
                justifyContent="center"
              >
                <Mui.Typography variant="body1" padding={'16px'}>
                  Oops. We don’t have any data for that. Try another search.
                </Mui.Typography>
              </Mui.Box>
            )}
            <Mui.TablePagination
              sx={{
                '.MuiTablePagination-displayedRows': {
                  margin: 0,
                  padding: 0,
                },
              }}
              rowsPerPageOptions={[10]}
              component="div"
              count={totalItems}
              rowsPerPage={pageRecordSize}
              page={currentPage}
              onPageChange={handleChangePage}
            />
          </Mui.Paper>
        </Mui.Box>
      )}
    </>
  );
};

export default ChannelsTable;
