import React from 'react';
import classnames from 'classnames';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  expanded: boolean;
  onClick?: () => void;
  isCompleted: boolean;
  description: string;
}

const OnboardingFab: React.FC<IProps> = ({ onClick, isCompleted, description }) => {
  const fabClass = classnames({
    fabStep: true,
    completed: isCompleted,
  });
  return (
    <div className="fabContainer">
      <div className={fabClass} onClick={onClick}>
        <FontAwesomeIcon size="2x" icon={faCheck} />
      </div>
      <div className="stepHelper">{description}</div>
    </div>
  );
};
export default OnboardingFab;
