export const GET_EXPECTED_SESSIONS = 'GET_EXPECTED_SESSIONS';
export const GET_TIME_ENTRIES = 'GET_TIME_ENTRIES';
export const CREATE_SESSION = 'CREATE_SESSION';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_SESSIONS = 'UPDATE_SESSIONS';
export const UPDATE_SESSION_AT_ID = 'UPDATE_SESSION_AT_ID';
export const REMOVE_SESSION = 'REMOVE_SESSION';
export const REMOVE_SESSION_BY_ID = 'REMOVE_SESSION_BY_ID';
export const REPORT_ABSENCE = 'REPORT_ABSENCE';
export const GET_SESSION_TRANSACTIONS = 'GET_SESSION_TRANSACTIONS';
export const REMOVE_SESSION_TIME_ENTRY = 'REMOVE_SESSION_TIME_ENTRY';

interface IGetExpectedSessionsAction {
  type: typeof GET_EXPECTED_SESSIONS;
  sessions: ISession[];
}

interface IGetTimeEntriesAction {
  type: typeof GET_TIME_ENTRIES;
  timeEntries: IAttendanceTimeEntry[];
}

interface ICreateSessionAction {
  type: typeof CREATE_SESSION;
  session: ISession;
}

interface IUpdateSessionAction {
  type: typeof UPDATE_SESSION;
  session: ISession;
}

interface IRemoveSessionAction {
  type: typeof REMOVE_SESSION;
  session: ISession;
}

interface IReportAbsenceAction {
  type: typeof REPORT_ABSENCE;
  absences: IAbsence[];
}

interface IUpdateSessionAction {
  type: typeof UPDATE_SESSION;
  session: ISession;
}

interface IUpdateSessionAtIdAction {
  type: typeof UPDATE_SESSION_AT_ID;
  id: string;
  session: ISession;
}

interface IRemoveSessionByIdAction {
  type: typeof REMOVE_SESSION_BY_ID;
  id: string;
}

interface IUpdateSessionsAction {
  type: typeof UPDATE_SESSIONS;
  sessions: ISession[];
}

interface IGetSessionTransactionsAction {
  type: typeof GET_SESSION_TRANSACTIONS;
  transactions: ITransaction[];
}

interface IRemoveSessionTimeEntryAction {
  type: typeof REMOVE_SESSION_TIME_ENTRY;
  timeEntry: IAttendanceTimeEntry;
}

export type SessionsActionTypes =
  | IGetExpectedSessionsAction
  | IGetTimeEntriesAction
  | ICreateSessionAction
  | IReportAbsenceAction
  | IUpdateSessionAction
  | IRemoveSessionAction
  | IUpdateSessionAtIdAction
  | IRemoveSessionByIdAction
  | IUpdateSessionsAction
  | IGetSessionTransactionsAction
  | IRemoveSessionTimeEntryAction;
