import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { GET_CENTER_DETAILS } from './queries';
import { updateClass, addClass } from '../../Classes/duck/actions';
import { useDispatch } from 'react-redux';

interface ICreateClassVariables {
  input: ICreateClassInput;
}

interface ICreateClassData {
  createClass: IClass;
}

interface ICreateSpaceVariables {
  input: INewSpace;
}

interface ICreateSpaceData {
  createSpace: ISpace;
}

interface IUpdateClassVariables {
  input: IUpdateClassInput;
}

interface IUpdateClassData {
  updateClass: IClass;
}

export const CREATE_CLASS = gql`
  mutation ($input: CreateClassInput!) {
    createClass(input: $input) {
      id
      centerId
      name
      startsAt
      endsAt
      isPreSchoolProgram
      regulationId
      defaultSpaceId
      colorCode
      careType
      defaultSpace {
        id
        name
      }
      currentSpace {
        id
        name
      }
      regulation {
        id
        state
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        youngestStartAge {
          age
          unit
        }
        oldestEndAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      colorCode
      staffAssignments {
        id
        nickname
        firstname
        lastname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
        }
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
      }
      staffAssignments {
        id
        firstname
        lastname
        avatar {
          url
        }
      }
      defaultCasualFeeId
      defaultPermanentFeeId
      fees {
        id
        name
        deactivatedAt
      }
      glCode
    }
  }
`;

export const UPDATE_CLASS = gql`
  mutation ($input: UpdateClassInput!) {
    updateClass(input: $input) {
      id
      centerId
      name
      startsAt
      endsAt
      isPreSchoolProgram
      regulationId
      defaultSpaceId
      defaultSpace {
        id
        name
      }
      currentSpace {
        id
        name
      }
      regulation {
        id
        state
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        youngestStartAge {
          age
          unit
        }
        oldestEndAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      colorCode
      careType
      staffAssignments {
        id
        nickname
        firstname
        lastname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
        }
      }
      defaultCasualFeeId
      defaultPermanentFeeId
      fees {
        id
        name
        deactivatedAt
      }
      glCode
    }
  }
`;

export const CREATE_SPACE = gql`
  mutation ($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      id
      name
      centerId
    }
  }
`;

export const useCreateNewClass = () => {
  const dispatch = useDispatch();

  return useMutation<ICreateClassData, ICreateClassVariables>(CREATE_CLASS, {
    onCompleted: (data) => {
      dispatch(addClass(data.createClass));
    },
  });
};

export const useUpdateClass = () => {
  const dispatch = useDispatch();

  return useMutation<IUpdateClassData, IUpdateClassVariables>(UPDATE_CLASS, {
    onCompleted: (result) => {
      dispatch(updateClass(result.updateClass));
    },
  });
};

export const useCreateNewSpace = () =>
  useMutation<ICreateSpaceData, ICreateSpaceVariables>(CREATE_SPACE, {
    update(cache: any, { data: { createSpace } }: any) {
      const { getCenter } = cache.readQuery({
        query: GET_CENTER_DETAILS,
        variables: { id: createSpace.centerId },
      });
      cache.writeQuery({
        query: GET_CENTER_DETAILS,
        data: { getCenter: { ...getCenter, spaces: [...getCenter.spaces, createSpace] } },
      });
    },
  });
