import COUNTRY_INFO, { DEFAULT_COUNTRY } from '../dropdownOptions/countryInfo';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

export enum CcsMsgTypesEnum {
  IMMED = 'IMMED',
  ERROR = 'ERROR',
  WARNIG = 'WARNIG',
  INFO = 'INFO',
  CORRESPONDENCE = 'CORRESPONDENCE',
}

export enum CcsMsgTypeLabelEnum {
  IMMED = 'Immediate Action Required',
  ERROR = 'An error has occured',
  WARNIG = 'Warning Only',
  INFO = 'Information Only',
  CORRESPONDENCE = 'Correspondence',
}

export enum CcsMsgSourcesEnum {
  ENRCRT = 'ENRCRT',
  ENRUPD = 'ENRUPD',
  SSRCRT = 'SSRCRT',
  SSRUPD = 'SSRUPD',
  FDCEXM = 'FDCEXM',
  ACCTMG = 'ACCTMG',
  ACCSCW = 'ACCSCW',
  PAYMNT = 'PAYMNT',
  RTNFEE = 'RTNFEE',
  ENTTLM = 'ENTTLM',
  CAREPV = 'CAREPV',
  DEBTMG = 'DEBTMG',
}

export const CcsMsgSourcesLabelEnum = {
  ENRCRT: `${fieldLabels.enrollment} - create`,
  ENRUPD: `${fieldLabels.enrollment} - update`,
  SSRCRT: 'Session report - create',
  SSRUPD: 'Session report - update',
  FDCEXM: 'FDC Exemption',
  ACCTMG: 'Account Management',
  ACCSCW: 'ACCS Child Wellbeing',
  PAYMNT: 'Payment',
  RTNFEE: 'Return Fee Reduction',
  ENTTLM: 'Entitlement',
  CAREPV: 'Care Provided and Vaca..',
  DEBTMG: 'Debt Management',
} as const;

export enum MsgStatusCodes {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
  READ = 'READ',
  ARCHIVED = 'ARCHIVED',
}

export enum MsgStatusLabels {
  ALL = 'All',
  UNREAD = 'Unread',
  READ = 'Read',
  ARCHIVED = 'Archived',
}

export const messageStatusFilters: { label: string; value: string }[] = [
  { label: MsgStatusLabels.ALL, value: MsgStatusCodes.ALL },
  { label: MsgStatusLabels.UNREAD, value: MsgStatusCodes.UNREAD },
  { label: MsgStatusLabels.READ, value: MsgStatusCodes.READ },
  { label: MsgStatusLabels.ARCHIVED, value: MsgStatusCodes.ARCHIVED },
];

export const ccsMessageTypes: { label: string; value: string }[] = [
  { label: CcsMsgTypeLabelEnum.IMMED, value: CcsMsgTypesEnum.IMMED },
  { label: CcsMsgTypeLabelEnum.ERROR, value: CcsMsgTypesEnum.ERROR },
  { label: CcsMsgTypeLabelEnum.WARNIG, value: CcsMsgTypesEnum.WARNIG },
  { label: CcsMsgTypeLabelEnum.INFO, value: CcsMsgTypesEnum.INFO },
  { label: CcsMsgTypeLabelEnum.CORRESPONDENCE, value: CcsMsgTypesEnum.CORRESPONDENCE },
];

export const ccsMessageSources: { label: string; value: CcsMsgSourceCodes }[] = [
  { label: CcsMsgSourcesLabelEnum.ACCTMG, value: CcsMsgSourcesEnum.ACCTMG },
  { label: CcsMsgSourcesLabelEnum.ACCSCW, value: CcsMsgSourcesEnum.ACCSCW },
  { label: CcsMsgSourcesLabelEnum.CAREPV, value: CcsMsgSourcesEnum.CAREPV },
  { label: CcsMsgSourcesLabelEnum.DEBTMG, value: CcsMsgSourcesEnum.DEBTMG },
  { label: CcsMsgSourcesLabelEnum.ENRCRT, value: CcsMsgSourcesEnum.ENRCRT },
  { label: CcsMsgSourcesLabelEnum.ENRUPD, value: CcsMsgSourcesEnum.ENRUPD },
  { label: CcsMsgSourcesLabelEnum.ENTTLM, value: CcsMsgSourcesEnum.ENTTLM },
  { label: CcsMsgSourcesLabelEnum.FDCEXM, value: CcsMsgSourcesEnum.FDCEXM },
  { label: CcsMsgSourcesLabelEnum.PAYMNT, value: CcsMsgSourcesEnum.PAYMNT },
  { label: CcsMsgSourcesLabelEnum.RTNFEE, value: CcsMsgSourcesEnum.RTNFEE },
  { label: CcsMsgSourcesLabelEnum.SSRCRT, value: CcsMsgSourcesEnum.SSRCRT },
  { label: CcsMsgSourcesLabelEnum.SSRUPD, value: CcsMsgSourcesEnum.SSRUPD },
];
