import { useCallback } from 'react';
import { US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
const { ANY, TERM, ALL } = SEARCH_EXPRESSIONS;
const { CONTAINS, ONE_OF } = PREDICATES;

const tableColumns: string[] = ['firstname', 'lastname', 'email.keyword', 'address.state'];

const useGetStaffSearchExpression = (
  centers?: ICenter[],
  tags?: ITableFilterOption[]
): ((term: string | undefined | null) => ISearchExpression[]) => {
  return useCallback(
    (term: string | undefined | null): ISearchExpression[] => {
      if (!term) return [];
      const searchTerm = term.toLowerCase();
      const fullSearchArr: ISearchExpression[] = [];
      const individualWords = searchTerm.split(' ');

      const allWordsSearchArr: ISearchExpression[] = [];
      individualWords.forEach((word) => {
        // add query to check if any word of the search matches any of the table columns
        const individualWordSearchArr: ISearchExpression[] = [];
        tableColumns.forEach((column) =>
          individualWordSearchArr.push({
            [TERM]: { field: column, predicate: CONTAINS, value: word },
          })
        );
        allWordsSearchArr.push({ [ANY]: individualWordSearchArr });
      });
      // all the words in the search term must match a column
      fullSearchArr.push({ [ALL]: allWordsSearchArr });

      // if search matches any tag names, add those tag IDs to the query
      const tagIdsToFilter = tags?.filter((t) => t.label.toLowerCase().includes(searchTerm)).map((tag) => tag.value);
      if (tagIdsToFilter?.length) {
        fullSearchArr.push({
          [TERM]: { field: 'tags.keyword', predicate: ONE_OF, value: tagIdsToFilter },
        });
      }
      // if search matches any center names, add those center IDs to the query
      const centerIdsToFilter = centers?.filter((c) => c.name.toLowerCase().includes(searchTerm)).map((c) => c.id);
      if (centerIdsToFilter?.length) {
        fullSearchArr.push({
          [TERM]: { field: 'roleship.scopeIds.keyword', predicate: ONE_OF, value: centerIdsToFilter },
        });
      }
      // if search matches any states names, add those state CODES to the query
      const stateCodesToFilter = US_STATE_SELECT_OPTIONS.filter((s) => s.label.toLowerCase().includes(searchTerm)).map(
        (s) => s.value
      );
      if (stateCodesToFilter.length) {
        fullSearchArr.push({
          [TERM]: { field: 'address.state.keyword', predicate: ONE_OF, value: stateCodesToFilter },
        });
      }
      // update table state to reflect search query
      return [{ [ANY]: fullSearchArr }];
    },
    [centers, tags]
  );
};

export default useGetStaffSearchExpression;
