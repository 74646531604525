import React from 'react';

interface IProps {}

const EmptyTableRow: React.FC<IProps> = ({ ...props }) => {
  return (
    <tr>
      <td colSpan={14}>No data found.</td>
    </tr>
  );
};

export default EmptyTableRow;
