import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { defaultPayCodeFields } from './defaultFields';

interface IPayCodeType {
  id: string;
  businessId: string;
  centerId: string;
  description: string;
  code: string;
  name: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  archivedAt?: string;
  archivedBy?: string;
  trainingTimeEventType: TrainingHolidayEventType | null;
  overtimeEligible: boolean;
}

interface ICreatePayCodeInput {
  input: {
    businessId: string;
    centerId?: string | null;
    code: string;
    name: string;
    description: string;
    trainingTimeEventType: TrainingHolidayEventType | null;
    overtimeEligible: boolean;
  };
}

interface IArchivePayCodeInput {
  input: {
    businessId: string;
    id: string;
  };
}
interface IUpdatePayCodesInput {
  input: {
    businessId: string;
    id: string;
    description: string;
  };
}

export const CREATE_PAY_CODE = gql`
  mutation($input: CreatePtoTypesInput!) {
    createPtoType(input: $input){
       ${defaultPayCodeFields}
    }
  }
`;

export const useCreatePayCode = (input?: MutationHookOptions<IPayCodeType, ICreatePayCodeInput>) =>
  useMutation<IPayCodeType, ICreatePayCodeInput>(CREATE_PAY_CODE, input);

export const ARCHIVE_PAY_CODE = gql`
  mutation ($input: ArchivePtoTypesInput!) {
    archivePtoType(input: $input) {
      id
    }
  }
`;

export const useArchivePayCode = (input?: MutationHookOptions<IPayCodeType, IArchivePayCodeInput>) =>
  useMutation<IPayCodeType, IArchivePayCodeInput>(ARCHIVE_PAY_CODE, input);

export const UPDATE_PAY_CODE = gql`
  mutation ($input: UpdatePtoTypesInput!) {
    updatePtoType(input: $input) {
      ${defaultPayCodeFields}
    }
  }
`;

export const useUpdatePayCode = (input?: MutationHookOptions<IPayCodeType, IUpdatePayCodesInput>) =>
  useMutation<IPayCodeType, IUpdatePayCodesInput>(UPDATE_PAY_CODE, input);
