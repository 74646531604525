import { QueryHookOptions } from '@apollo/client';
import { useQuery, useLazyQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { restrictionFields } from './fields';

export interface IGetChildRestrictionsData {
  getChildRestrictions: IRestriction[];
}

interface IGetChildRestrictionsVariables {
  id: string;
}

export interface IGetRestrictionHistoryData {
  getRestrictionHistory: IRestrictionRevision[];
}

interface IGetRestrictionHistoryVariables {
  id: string;
}

export const GET_CHILD_RESTRICTIONS = (fields: string = restrictionFields) => gql`
  query ($id: ID!) {
    getChildRestrictions(id: $id) {
      ${fields}
    }
  }
`;

export const GET_RESTRICTION_HISTORY = gql`
  query ($id: ID!) {
    getRestrictionHistory(id: $id) {
      id
      importance
      description
      documentsChanged
      revisedAt
      revisedByPerson {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
      }
      restrictionVersion {
        importance
        description
      }
    }
  }
`;

export const useGetChildRestrictions = (
  options?: QueryHookOptions<IGetChildRestrictionsData, IGetChildRestrictionsVariables>,
  fields?: string
) =>
  useQuery<IGetChildRestrictionsData, IGetChildRestrictionsVariables>(GET_CHILD_RESTRICTIONS(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const useGetRestrictionHistoryLazy = (id: string) =>
  useLazyQuery<IGetRestrictionHistoryData, IGetRestrictionHistoryVariables>(GET_RESTRICTION_HISTORY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });
