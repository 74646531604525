import React from 'react';
import PropTypes from 'prop-types';
import AddressInputWithAutoComplete from './AddressInputWithAutoComplete';

const AddressInput = ({
  address = {},
  onChange,
  addressLabel = 'Address',
  disabled,
  required,
  errorText,
  showHelperText = true,
  ...props
}) => {
  return (
    <AddressInputWithAutoComplete
      address={address}
      onChange={onChange}
      addressLabel={addressLabel}
      disabled={disabled}
      required={required}
      errorText={errorText}
      hideCountry={props.hideCountry}
      modalFormat={props.modalFormat}
      showHelperText={showHelperText}
    />
  );
};

AddressInput.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  errorText: PropTypes.object,
};

AddressInput.defaultProps = {
  disabled: false,
};

export default AddressInput;
