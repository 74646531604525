import React from 'react';
import * as Mui from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { RootState } from 'store/reducers';
import { showToast } from 'shared/components/Toast';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';

// import EditTransactionType from './EditTransactionType';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import Button from 'shared/components/Buttons';
import colors from '_colors.module.scss';

import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import ManageLocksModal from './ManageLocksModal';
import ConfirmationModal from 'shared/components/ConfirmationModal';

import { useUpdateCenterKisiPlaceId } from 'gql/kisiIntegration/mutations';

interface ITransactionType {
  id?: string;
  archivedAt: string | null;
  areaType?: string;
  attachedToEntityId?: string;
  name?: string;
  noteType?: string;
  center?: ICenter | null;
  glCode: string | null;
  glCodeMapping?: IGLCodeMapping | null;
  comment?: string;
  createdBy?: string;
  createdAt?: string;
  createdByAccount?: IStaff;
  isCredit?: boolean;
  isDebit?: boolean;
  isTuition?: boolean;
  authorisedRoles?: string[];
  isDefaultType: boolean;
}

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
  iconButton: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
  inputStyles: {
    minWidth: '200px',
    '& legend': {
      width: 0,
    },
  },
};

interface IRowProps {
  index: number;
  row: IKisiPairedCenter;
  refetch: () => void;
  canViewBusinessLevelTransactionTypes: boolean;
}

const KisiPlacesTableRow: React.FC<IRowProps> = ({
  index,
  row,
  refetch,
  canViewBusinessLevelTransactionTypes,
  ...props
}) => {
  const { t } = useTranslation();
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';

  const [selectedCenter, setSelectedCenter] = React.useState<{ value: null | string; label: string } | null>(null);
  const [openManageLocksDialogue, setOpenManageLocksDialogue] = React.useState(false);
  const [openDeletePairingDialogue, setOpenDeletePairingDialogue] = React.useState(false);
  const centerSelectOptions = useGetActiveCenters().map((center) => ({ label: center.name, value: center.id })) ?? [];
  const [shareId, setShareId] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);

  React.useEffect(() => {}, [selectedCenter]);

  React.useEffect(() => {
    console.log('row-> ', row);
  }, [row]);

  const [updateCenterKisiPlaceId, { loading: loadingUpdateCenterKisiPlaceId }] = useUpdateCenterKisiPlaceId({
    onCompleted: (response) => {
      showToast('Success.', 'success');
      if (response) {
        refetch();
      }
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  const onSave = () => {
    updateCenterKisiPlaceId({
      variables: {
        input: {
          id: selectedCenter?.value ?? row.id,
          kisiPlaceId: +row?.kisiPlace?.id ?? 0,
          // if its 0 make it 0null
          kisiShareId: +shareId == 0 ? null : +shareId,
        },
      },
    });
  };

  const onDeletePairingCallback = React.useCallback(async () => {
    updateCenterKisiPlaceId({
      variables: {
        input: {
          id: selectedCenter?.value ?? row.id,
          kisiPlaceId: null,
          kisiShareId: null,
        },
      },
    });
    setSelectedCenter(null);
  }, [updateCenterKisiPlaceId, selectedCenter, row]);

  return (
    <>
      <Mui.TableRow key={row?.kisiPlace?.id ?? ''}>
        <Mui.TableCell align="left" sx={{ overflow: 'visible' }}>
          {row?.kisiPlace ? (
            <Mui.Autocomplete
              id="center-select"
              options={centerSelectOptions}
              getOptionLabel={(option) => option.label}
              onChange={(evt, value) => setSelectedCenter(value)}
              renderInput={(params) => <Mui.TextField {...params} sx={styles.inputStyles} placeholder="Center" />}
              size="small"
              value={row.name !== null ? { value: row.id, label: row.name } : selectedCenter}
              sx={{ paddingRight: '16px' }}
              disabled={row.name !== null}
            />
          ) : (
            <Mui.Typography variant="body1" sx={{ color: colors.gray }}>
              {row?.name ?? ''}
            </Mui.Typography>
          )}
        </Mui.TableCell>
        <Mui.TableCell align="left">
          {row?.kisiPlace ? (
            <Mui.Typography variant="body1">{row?.kisiPlace?.name ?? ''}</Mui.Typography>
          ) : (
            <Mui.Typography variant="body1" sx={{ color: colors.red }}>
              Kisi Place No Longer Valid
            </Mui.Typography>
          )}
        </Mui.TableCell>
        <Mui.TableCell align="left">
          {row?.kisiPlace ? (
            <Mui.Typography variant="body1">{row?.kisiPlace?.id ?? ''}</Mui.Typography>
          ) : (
            <Mui.Typography variant="body1" sx={{ color: colors.red }}>
              Please Remove
            </Mui.Typography>
          )}
        </Mui.TableCell>
        <Mui.TableCell align="left">
          {row?.kisiPlace ? (
            <Mui.TextField
              size="small"
              value={shareId}
              onChange={(evt) => setShareId(evt.target.value)}
              sx={{
                mr: 1,
                mb: 1,
                width: '60%',
                '& legend': {
                  width: 0,
                },
              }}
              inputProps={{
                sx: {
                  '&::placeholder': {
                    color: '#516173 !important',
                  },
                },
              }}
              placeholder={row?.kisiShareId ?? ''}
              type="number"
            />
          ) : (
            <Mui.Typography variant="body1" sx={{ color: colors.gray }}>
              {row?.kisiShareId ?? ''}
            </Mui.Typography>
          )}
        </Mui.TableCell>
        <Mui.TableCell align="right">
          {row?.kisiPlace && (
            <Button
              onClick={onSave}
              variant="primary"
              disabled={
                (selectedCenter === null && row?.name === null) ||
                (row?.name !== null && shareId === '') ||
                loadingUpdateCenterKisiPlaceId
              }
              className="mb-2 mr-2"
            >
              Save
            </Button>
          )}
          <IconButtonCircle
            id="action-dropdown-btn"
            iconSize="2x"
            icon={faEllipsisH}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            className="mb-2"
          />
          <Mui.Menu
            PaperProps={{ sx: { boxShadow: '1' } }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {row?.kisiPlace && (
              <Mui.MenuItem onClick={() => setOpenManageLocksDialogue(true)} disabled={row?.name === null}>
                Manage Locks
              </Mui.MenuItem>
            )}
            <Mui.MenuItem onClick={(e) => setOpenDeletePairingDialogue(true)} disabled={row?.name === null}>
              Delete
            </Mui.MenuItem>
          </Mui.Menu>
        </Mui.TableCell>
      </Mui.TableRow>

      <ManageLocksModal
        centerId={row.id}
        centerName={row.name}
        openManageLocksDialogue={openManageLocksDialogue}
        setOpenManageLocksDialogue={(x) => setOpenManageLocksDialogue(x)}
      />
      <ConfirmationModal
        title="Are you sure you want to delete Kisi lock pairing?"
        show={openDeletePairingDialogue}
        onHide={() => setOpenDeletePairingDialogue(false)}
        primaryChoice="Delete"
        primaryCallback={onDeletePairingCallback}
        hideOnCallback={true}
        primaryButtonProps={{
          variant: 'danger',
          loading: false,
        }}
      ></ConfirmationModal>
    </>
  );
};

export default KisiPlacesTableRow;
