import { ResponsiveBar } from '@nivo/bar';
import * as Mui from '@mui/material';
import {
  useGetCenterSummaryStatistics,
  useGetHighestDailyMessagesPerChannelStatistics,
  useGetMonthlyMessagesSentStatistics,
} from 'gql/communications/queries';
import moment from 'moment';
import React from 'react';
import { Box, Header } from 'shared/components/LoadingSkeletons';
import AvatarContent from 'shared/components/Avatar/AvatarContent';
import { getFullName, getInitials, stringToHueDegree } from 'shared/util/string';
import { ChannelType } from 'generated/graphql';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceThinking } from '@fortawesome/pro-light-svg-icons';
import Image from 'react-bootstrap/Image';
import noDataMale from '../assets/no_data_male.png';
import noDataFemale from '../assets/no_data_female.png';
import { useMediaQuery, useTheme } from '@mui/material';

interface IProps {
  currentBusinessId: string;
  currentCenterId: string;
  hasUnreadMessages: boolean | undefined;
  setHasUnreadMessages: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  hasUnresolvedFlaggedMessages: boolean;
  setHasUnresolvedFlaggedMessages: React.Dispatch<React.SetStateAction<boolean>>;
}
const CenterStatisticsBar: React.FC<IProps> = ({
  currentBusinessId,
  currentCenterId,
  hasUnreadMessages,
  setHasUnreadMessages,
  hasUnresolvedFlaggedMessages,
  setHasUnresolvedFlaggedMessages,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: monthlyData, loading: monthlyDataLoading } = useGetMonthlyMessagesSentStatistics({
    variables: {
      businessId: currentBusinessId ?? '',
      input: {
        businessId: currentBusinessId ?? '',
        centerId: currentCenterId ?? '',
        splitByPersonType: false,
      },
    },
    skip: currentBusinessId == null || currentBusinessId === '' || currentCenterId == null || currentCenterId === '',
  });

  const { data: summaryStatsData, loading: summaryStatsLoading } = useGetCenterSummaryStatistics({
    variables: {
      businessId: currentBusinessId ?? '',
      input: {
        businessId: currentBusinessId ?? '',
        centerId: currentCenterId ?? '',
        channelType: ChannelType.Student,
      },
    },
    skip: currentBusinessId == null || currentBusinessId === '' || currentCenterId == null || currentCenterId === '',
  });

  const chartData = monthlyData?.getMonthlyMessagesSentStatistics.map((rec) => {
    var date = moment(rec.month, 'DD-MM-YYYY');
    return {
      month: date.format('MMM'),
      sent: rec.messagesSent ?? 0,
    };
  });

  const { data: highestData, loading: highestDataLoading } = useGetHighestDailyMessagesPerChannelStatistics({
    variables: {
      businessId: currentBusinessId ?? '',
      input: {
        businessId: currentBusinessId ?? '',
        centerId: currentCenterId ?? '',
      },
    },
    skip: currentBusinessId == null || currentBusinessId === '' || currentCenterId == null || currentCenterId === '',
  });

  const highestChartData = highestData?.getHighestDailyMessagesPerChannelStatistics.slice(0, 5).map((rec) => {
    return {
      date: rec.date ?? '',
      sent: rec.messageCount ?? 0,
      id: rec.channelId + '.' + rec.accountChild.lastname.replace(/[^a-zA-Z ]/g, '') + '.' + (rec.date ?? ''),
      name: rec.accountChild.firstname + ' ' + rec.accountChild.lastname,
      channelUrl: `/communications/student-messaging/center/${currentCenterId}/channel/${rec.channelId}`,
      avatar: rec.accountChild.avatar?.url ?? '',
    };
  });
  const Image = ({ bars }) => {
    let size = 22;
    let images = bars.map(({ key, x, y, width, height }) => {
      var image = key.split('.')[1];
      var accChild = highestData?.getHighestDailyMessagesPerChannelStatistics.find((hcd) => hcd.channelId == image)
        ?.accountChild!;
      var url = accChild.avatar?.url ?? '';
      return url != '' ? (
        <g key={key}>
          <defs>
            <pattern id={key} height="100%" width="100%" viewBox={'0 0 ' + size + ' ' + size}>
              <image x="0%" y="0%" height={size} width={size} xlinkHref={accChild.avatar?.url ?? ''}></image>
            </pattern>
          </defs>
          <circle
            cx={x + width / 2 - size / 2 + 11}
            cy={y + height / 2 - size / 2}
            r="4%"
            fill={'url(#' + key + ')'}
            stroke="lightblue"
            strokeWidth="0.5%"
          />
        </g>
      ) : (
        <g key={key}>
          <circle r="20" fill="#4682b4" cx={x + width / 2 - size / 2 + 11} cy={y + height / 2 - size / 2} />
          <text
            x={x + width / 2 - size / 2 + 11}
            y={y + height / 2 - size / 2 + 5}
            radius={10}
            fill="white"
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            {getInitials(accChild)}
          </text>
        </g>
      );

      /*
       */
    });
    return <g>{images}</g>;
  };

  return (
    <Mui.Grid container spacing={2}>
      <Mui.Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
        {summaryStatsLoading ? (
          <Box width={'100%'} height={'100%'} />
        ) : (
          <Mui.Grid container spacing={3} marginTop={1}>
            <Mui.Grid item xs={12} sm={12} md={4} lg={4} xl={12}>
              <Mui.Card>
                <Mui.CardActionArea
                  onClick={() => (hasUnreadMessages ? setHasUnreadMessages(undefined) : setHasUnreadMessages(true))}
                  sx={{ paddingBottom: '7px' }}
                >
                  <Mui.CardContent>
                    <Mui.Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h5>Unread by Staff</h5>
                      <Mui.Typography fontSize={36}>
                        {summaryStatsData?.getCenterSummaryStatistics.unreadCount}
                      </Mui.Typography>
                    </Mui.Box>
                  </Mui.CardContent>
                </Mui.CardActionArea>
              </Mui.Card>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={12} md={4} lg={4} xl={12}>
              <Mui.Card>
                <Mui.CardActionArea
                  onClick={() => setHasUnresolvedFlaggedMessages(hasUnresolvedFlaggedMessages ? false : true)}
                  sx={{ paddingBottom: '7px' }}
                >
                  <Mui.CardContent>
                    <Mui.Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h5>Flagged Messages</h5>
                      <Mui.Typography fontSize={36}>
                        {summaryStatsData?.getCenterSummaryStatistics.flaggedCount}
                      </Mui.Typography>
                    </Mui.Box>
                  </Mui.CardContent>
                </Mui.CardActionArea>
              </Mui.Card>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={12} md={4} lg={4} xl={12}>
              <Mui.Card>
                <Mui.CardContent>
                  <Mui.Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5>Daily Average Messages</h5>
                    <Mui.Typography fontSize={36}>
                      {summaryStatsData?.getCenterSummaryStatistics.dailyAverage}
                    </Mui.Typography>
                  </Mui.Box>
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
          </Mui.Grid>
        )}
      </Mui.Grid>
      {!isMobile && (
        <>
          <Mui.Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Mui.Card sx={{ marginTop: '24px' }} elevation={0}>
              <Mui.CardContent>
                <Mui.Box>
                  {monthlyDataLoading ? (
                    <Box height={300} width={500} />
                  ) : chartData?.length == 0 || chartData?.every((cd) => cd.sent == 0) ? (
                    emptyChart(
                      'Messages Sent Overall',
                      "Looks like we don't have any messages for this center yet.",
                      noDataFemale
                    )
                  ) : (
                    <div style={{ width: '500px', height: '300px', margin: 'auto' }}>
                      <h6 style={{ textAlign: 'center' }}>Messages Sent Overall</h6>
                      <ResponsiveBar
                        data={chartData ?? []}
                        keys={['sent']}
                        indexBy="month"
                        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                        padding={0.4}
                        valueScale={{ type: 'linear' }}
                        colors="#3182CE"
                        animate={false}
                        enableLabel={false}
                        axisTop={null}
                        axisRight={null}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: 'Message Count',
                          legendPosition: 'middle',
                          legendOffset: -40,
                          format: (e) => Math.floor(e) === e && e,
                        }}
                        layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations']}
                      />
                    </div>
                  )}
                </Mui.Box>
              </Mui.CardContent>
            </Mui.Card>
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Mui.Card sx={{ marginTop: '24px' }} elevation={0}>
              <Mui.CardContent>
                <Mui.Box>
                  {highestDataLoading ? (
                    <Box height={300} width={500} />
                  ) : highestChartData?.length == 0 || highestChartData?.every((cd) => cd.sent == 0) ? (
                    emptyChart(
                      'Most Messages Sent Per Day By Student',
                      "Looks like we don't have any messages for these students yet.",
                      noDataMale
                    )
                  ) : (
                    <div style={{ width: '500px', height: '300px', margin: 'auto' }}>
                      <h6 style={{ textAlign: 'center' }}>Most Messages Sent Per Day By Student </h6>
                      <ResponsiveBar
                        data={highestChartData ?? []}
                        keys={['sent']}
                        indexBy="id"
                        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                        padding={0.4}
                        valueScale={{ type: 'linear' }}
                        colors="#3182CE"
                        animate={false}
                        enableLabel={false}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (val) => {
                            return val.split('.')[2];
                          },
                        }}
                        tooltip={(p) => {
                          return (
                            <Mui.Paper elevation={3}>
                              <span style={{ padding: '5px' }}>
                                <span style={{ fontWeight: 'bold' }}>{p.data.name}</span>: {p.data.sent} Messages
                              </span>
                            </Mui.Paper>
                          );
                        }}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: 'Message Count',
                          legendPosition: 'middle',
                          legendOffset: -40,
                          format: (e) => Math.floor(e) === e && e,
                        }}
                        layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations', Image]}
                        onClick={(data) => {
                          history.push(data.data['channelUrl']);
                        }}
                      />
                    </div>
                  )}
                </Mui.Box>
              </Mui.CardContent>
            </Mui.Card>
          </Mui.Grid>
        </>
      )}
    </Mui.Grid>
  );
};
export default CenterStatisticsBar;
function emptyChart(chartTitle: string, chartBody: string, img: string): React.ReactNode {
  return (
    <Mui.Stack alignItems="center" justifyContent="space-around" height={300}>
      <h6 style={{ textAlign: 'center' }}>{chartTitle} </h6>
      <Mui.Box display="flex" height={150} alignItems="center" justifyContent="center">
        <Image fluid src={img} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Mui.Box>
      <p style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>{chartBody}</p>
    </Mui.Stack>
  );
}
