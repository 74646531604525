import React from 'react';
import classnames from 'classnames';
import RBCard from 'react-bootstrap/Card';
import Avatar from 'shared/components/Avatar';
import Tooltip from 'shared/components/Tooltip';
import { getFullName, getInitials, stringToHueDegree } from 'shared/util/string';

interface IProps {
  childrenList: IAccountChild[];
  selectedChildId: string;
  selectedFilters: ITableFilterOption[];
  onChildSelect: (child: IAccountChild) => void;
  onContractStatusSelect: (filters: ITableFilterOption[]) => void;
}

const ChildSelect: React.FC<IProps> = ({ childrenList, selectedChildId, onChildSelect, ...props }) => {
  return (
    <RBCard className="mb-4 mx-0">
      <RBCard.Body className="px-4 py-3 d-flex flex-row align-items-center">
        <div className="mr-2 font-weight-semi-bold">View for:</div>
        <ul className="mb-0 pl-1">
          {childrenList.map((child, idx) => (
            <Tooltip key={`child-select-${child.id}-${idx}`} text={getFullName(child)} direction="bottom">
              <li className="d-inline-block mr-2 cursor-pointer" onClick={() => onChildSelect(child)}>
                <Avatar
                  size="md"
                  image={child.avatar?.url ?? ''}
                  color={`hsl(${stringToHueDegree(child.id)}, ${
                    stringToHueDegree(child.id) < 50 ? '100%' : '40%'
                  }, 40%`}
                  initials={getInitials(child)}
                  className={classnames({ 'selected-child': child.id === selectedChildId })}
                />
              </li>
            </Tooltip>
          ))}
        </ul>
      </RBCard.Body>
    </RBCard>
  );
};

export default ChildSelect;
