import React from 'react';
import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useCreateIntegrationApiKey, useUpdateIntegrationApiKey } from '../../../../gql/integrationApiKey/mutations';
import { IApiScopesType, ApiScopeAuthType } from '../../../../gql/integrationApiKey/queries';
import _, { result } from 'lodash';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import FormLabel from 'react-bootstrap/FormLabel';
import { IIntegrationApiKey } from './ApiKey';
import ApiScopesOptions from './ApiScopesOptions';
import i18n from 'i18n';
import { capitalize } from 'lodash';
import { showToast } from 'shared/components/Toast';
import { omitTypename } from 'shared/util/object';

const fullApiScope: string = 'full';
const customApiScope: string = 'custom';

const getAuthType = (full: boolean) => {
  return full ? ApiScopeAuthType.Write : ApiScopeAuthType.None;
};

const hasFullAccess = (apiScopes: IApiScopesType | null) => {
  return apiScopes === null || apiScopes === undefined;
};

interface IProps {
  addKeyDialogOpen: boolean;
  setAddorEditKeyDialogOpen: (val: boolean) => void;
  refetchKeys: () => void;
  apiKey?: IIntegrationApiKey;
  businessId?: string;
}

const AddApiKeyDialog: React.FC<IProps> = ({
  addKeyDialogOpen,
  setAddorEditKeyDialogOpen,
  refetchKeys,
  apiKey,
  businessId,
  ...props
}) => {
  const [apiKeyName, setApiKeyName] = React.useState('');
  const [centerIds, setCenterIds] = React.useState<string[] | null>(null);
  const [apiLocalScopes, setApiScopesType] = React.useState<IApiScopesType | null>(apiKey?.apiScopes ?? null);
  const [apiUseCustomScopes, setUseCustomScopes] = React.useState(!hasFullAccess(apiLocalScopes));

  const setApiScopeValue = (apiScopeName: keyof IApiScopesType, apiScopeAuthValue: ApiScopeAuthType) => {
    setApiScopesType({ ...apiLocalScopes, [apiScopeName]: apiScopeAuthValue });
  };

  const getApiRadioValue = () => {
    return apiUseCustomScopes ? customApiScope : fullApiScope;
  };

  const [createApiKeyfn, { loading }] = useCreateIntegrationApiKey({
    onCompleted: () => {
      setAddorEditKeyDialogOpen(false);
      refetchKeys();
      setApiKeyName('');
      setApiScopesType(apiLocalScopes); // all values
      setUseCustomScopes(hasFullAccess(apiLocalScopes));
      showToast('API Key created', 'success');
    },
    onError: (e) => {
      showToast(e.message, 'error');
    },
  });

  const [updateApiKeyfn, { loading: loadingUpdate }] = useUpdateIntegrationApiKey({
    onCompleted: () => {
      setAddorEditKeyDialogOpen(false);
      refetchKeys();
      setApiKeyName(apiKeyName);
      setApiScopesType(apiLocalScopes);
      setUseCustomScopes(hasFullAccess(apiLocalScopes));
      showToast('API Key updated', 'success');
    },
    onError: (e) => {
      showToast(e.message, 'error');
    },
  });

  const onSubmit = () => {
    if (apiKey?.id) {
      updateApiKeyfn({
        variables: {
          input: {
            id: apiKey?.id,
            businessId: apiKey?.businessId,
            description: apiKeyName ?? apiKey?.description,
            revoked: apiKey?.isDeleted!,
            centerScopes: centerIds,
            apiScopes: apiUseCustomScopes ? omitTypename(apiLocalScopes!) : null, // full scopes
          },
        },
      });
    } else {
      createApiKeyfn({
        variables: {
          input: {
            businessId: businessId!,
            description: apiKeyName,
            centerScopes: centerIds,
            apiScopes: apiUseCustomScopes ? omitTypename(apiLocalScopes!) : null, // full scopes
          },
        },
      });
    }
  };

  React.useEffect(() => {
    if (apiKey) {
      setApiKeyName(apiKey.description ?? '');
      setCenterIds(apiKey.centerScopes.length > 0 ? apiKey.centerScopes : null); //null is for all centers
      setApiScopesType(apiLocalScopes);
    }
  }, [apiKey]);

  return (
    <>
      <Mui.Dialog
        PaperProps={{
          sx: {
            position: 'absolute',
            height: '100%',
            width: '33.3%',
            right: -32,
            overflow: 'visible',
            maxHeight: '100%',
          },
        }}
        open={addKeyDialogOpen}
        onClose={() => setAddorEditKeyDialogOpen(false)}
      >
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }} variant={'h4'}>
          <span style={{ alignItems: 'center', display: 'flex' }}>
            {apiKey?.id ? 'Edit API Key' : 'Create API Key'}
          </span>
          <Mui.IconButton onClick={(e) => setAddorEditKeyDialogOpen(false)}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%' }}>
          <Mui.Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
            <Mui.TextField
              fullWidth
              value={apiKeyName}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              onChange={(e) => setApiKeyName(e.target.value)}
              required
              sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
            />
            <br />
            <FormLabel>Select {capitalize(i18n.t('translation:spelling.center'))} Scopes</FormLabel>
            <div style={{ width: '100%' }}>
              <MultipleCenterSelect
                useNullForAllOption={true}
                selectedCenterIds={centerIds}
                onSelect={(ids) => setCenterIds(!ids?.length ? null : ids)}
                showLabel={false}
                checkboxes={true}
                className="flex-grow-0 mb-2"
              />
            </div>
            <br />
            <div style={{ width: '100%' }}>
              <Mui.FormLabel
                id="scopes-label"
                required
                sx={{
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }}
              >
                Scopes
              </Mui.FormLabel>
              <Mui.FormHelperText id="scopes-label-help">
                Authorise the scope of access associated with this API Key
              </Mui.FormHelperText>
              <Mui.RadioGroup
                aria-labelledby="scopes-rg"
                value={getApiRadioValue()}
                name="scopes-rg"
                onChange={(e) => {
                  setUseCustomScopes(e.target.value === customApiScope);
                }}
              >
                <Mui.FormControlLabel value={fullApiScope} control={<Mui.Radio />} label="Full access" />
                <Mui.FormControlLabel value={customApiScope} control={<Mui.Radio />} label="Custom defined" />
              </Mui.RadioGroup>
              <Mui.Stack spacing={1}>
                <ApiScopesOptions
                  fullAccess={!apiUseCustomScopes}
                  apiScopes={apiLocalScopes}
                  setApiScopeValue={setApiScopeValue}
                />
              </Mui.Stack>
            </div>
          </Mui.Stack>
        </Mui.DialogContent>
        <Mui.Box style={{ margin: '8px' }}>
          <Mui.DialogActions>
            <Mui.Button onClick={(e) => setAddorEditKeyDialogOpen(false)}>Cancel</Mui.Button>
            <Mui.Button color="secondary" variant="contained" onClick={() => onSubmit()} disabled={apiKeyName === ''}>
              Save
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Box>
      </Mui.Dialog>
    </>
  );
};

export default AddApiKeyDialog;
