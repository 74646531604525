import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import DateInput from 'shared/components/DateInput';

interface IProps extends IImportStepProps {
  finalizeMigration: (data: IFinalizeMigrationVariables) => void;
  finalizeMigrationLoading: boolean;
}

const Finalize: React.FC<IProps> = ({ finalizeMigration, finalizeMigrationLoading, center, shouldReset }) => {
  const { t } = useTranslation('ktAdmin');
  const [date, setDate] = useState<string | undefined>();

  useEffect(() => {
    if (shouldReset) setDate(undefined);
  }, [shouldReset]);

  const handleFinalizeMigration = () => {
    finalizeMigration({
      centerId: center?.id ?? '',
      businessId: center?.entityId ?? '',
      date: moment(date).format('YYYY-MM-DD') ?? '',
    });
  };

  return (
    <Card
      header={t('data-importer.step-number', {
        number: 8,
        title: t('data-importer.finalize-import'),
      })}
    >
      <DateInput required label="Date" date={date} onDateSelect={setDate} />
      <p>{t('data-importer.adjust-balances-on-import')}</p>
      <Button
        variant="secondary"
        className="mr-2"
        disabled={date === undefined}
        onClick={handleFinalizeMigration}
        loading={finalizeMigrationLoading}
      >
        {t('data-importer.finalize-import')}
      </Button>
    </Card>
  );
};

export default Finalize;
