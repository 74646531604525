import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import {
  ICreateActivityData,
  ICreateActivityVariables,
  IEditMessageVariables,
  IMessageAttachment,
  InsertAttachmentInputVariables,
} from 'shared/types/channel';

export const CREATE_CHANNEL_MESSAGE = gql`
  mutation ($businessId: ID!, $input: CreateStudentActivityInput!) {
    createStudentChatActivity(businessId: $businessId, input: $input) {
      messageContent
    }
  }
`;

export const EDIT_MESSAGE_STATE = gql`
  mutation ($businessId: ID!, $input: CreateActivityInput!) {
    createChatActivity(businessId: $businessId, input: $input) {
      personId
      channelId
      channelType
    }
  }
`;
export const CREATE_MESSAGE_ATTACHMENT = gql`
  mutation ($businessId: ID!, $input: InsertAttachmentInput!) {
    createMessageAttachment(businessId: $businessId, input: $input) {
      id
      businessId
      centerId
      userActivityMessageId
      fileName
      mimetype
      presignedUploadUrl
      presignedDownloadUrl
      uploadState
    }
  }
`;
export const MARK_MESSAGE_ATTACHMENT = gql`
  mutation ($businessId: ID!, $centerId: ID!, $attachmentId: ID!) {
    markMessageAttachmentUploaded(businessId: $businessId, centerId: $centerId, attachmentId: $attachmentId) {
      id
      businessId
      centerId
      userActivityMessageId
      fileName
      mimetype
      presignedUploadUrl
      presignedDownloadUrl
      uploadState
    }
  }
`;

export const useCreateNewMessageInChannel = (
  options?: MutationHookOptions<ICreateActivityData, ICreateActivityVariables>
) => useMutation<ICreateActivityData, ICreateActivityVariables>(CREATE_CHANNEL_MESSAGE, options);

export const useEditMessageState = (input?: MutationHookOptions<ICreateActivityData, IEditMessageVariables>) =>
  useMutation<ICreateActivityData, IEditMessageVariables>(EDIT_MESSAGE_STATE, input);

export const useCreateMessageAttachment = (
  options?: MutationHookOptions<{ createMessageAttachment: IMessageAttachment }, InsertAttachmentInputVariables>
) =>
  useMutation<{ createMessageAttachment: IMessageAttachment }, InsertAttachmentInputVariables>(
    CREATE_MESSAGE_ATTACHMENT,
    options
  );

export const useMarkMessageAttachment = (
  options?: MutationHookOptions<
    { markMessageAttachmentUploaded: IMessageAttachment },
    { businessId: string; centerId: string; attachmentId: string }
  >
) =>
  useMutation<
    { markMessageAttachmentUploaded: IMessageAttachment },
    { businessId: string; centerId: string; attachmentId: string }
  >(MARK_MESSAGE_ATTACHMENT, options);
