import { useMediaQuery } from '@mui/material';
import {
  AccountStatusType,
  CorrespondenceType,
  ReEnrollmentHistory,
  SearchSort,
  SortDirection,
  Stage,
  useGetReEnrolHistoryOfActionsQuery,
} from 'generated/graphql';
import { useSearchCenters } from 'gql/center/queries';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { capitalize, debounce, orderBy, sortBy } from 'lodash';
import moment from 'moment';
import theme from 'muiTheme';
import { SEARCH_MY_CENTERS } from 'pages/Centers/subroutes/Profiles/graphql/fields';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DataTable, { SizePerPage, TableHeader } from 'shared/components/DataTable';
import { PREDICATES, SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { RootState } from 'store/reducers';
import SelectedItemsTotal from '../ReEnrollAccounts/components/SelectedItemsTotal';
import ReEnrollHistoryBulkActions from './components/ReEnrollHistoryBulkActions';
import ReEnrollHistoryExpandedRow from './components/ReEnrollHistoryExpandedRow';
import ReEnrollHistoryTableHeader from './components/ReEnrollHistoryTableHeader';
import AccountLink from 'shared/components/AccountLink/AccountLink';

interface IProps {}

const { TERM } = SEARCH_EXPRESSIONS;
const { CONTAINS } = PREDICATES;

const ReEnrollHistoryOfActions: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const hasReadEnrollmentPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.FamilyEnrollmentRequests,
    level: RoleLevelType.Read,
  });
  const hasReadUpdateDetailsPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.FamilyUpdateDetailsRequests,
    level: RoleLevelType.Read,
  });

  const hasEditEnrollmentRequestPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.FamilyEnrollmentRequests,
    level: RoleLevelType.Edit,
  });
  const hasEditUpdateDetailsPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.FamilyUpdateDetailsRequests,
    level: RoleLevelType.Edit,
  });

  const businessId = useSelector((state: RootState) => state.context.businessId);
  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];

  const [reenrolHistory, setReenrolHistory] = useState<ReEnrollmentHistory[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [centers, setCenters] = useState<ICenter[]>([]);
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [centerFilters, setCenterFilters] = useState<ITableFilterOption[]>([]);
  const [finalCenterFilters, setFinalCenterFilters] = useState<string[]>([]);
  const [tagFilters, setTagFilters] = useState<ITableFilterOption[]>([]);
  const [statusFilters, setStatusFilters] = useState<ITableFilterOption[]>([]);
  const [correspondenceTypeFilter, setCorrespondenceTypeFilter] = useState<ITableFilterOption[]>([]);
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();

  const [sortDto, setSortDto] = useState<SearchSort>({
    direction: SortDirection.Descending,
    field: 'createdAt',
  });

  const centerOptions: ITableFilterOption[] = useMemo(
    () =>
      orderBy(centers, (center) => center?.name, 'asc').map((center) => ({
        label: center?.name ?? '',
        value: center?.id ?? '',
        searchExpression: {
          [SEARCH_EXPRESSIONS.TERM]: {
            field: 'centerId.keyword',
            predicate: PREDICATES.CONTAINS,
            value: center?.id,
          },
        },
      })),
    [centers]
  );

  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name']).map((tag) => ({
    label: tag.name,
    value: tag.id,
    searchExpression: { [TERM]: { field: 'tags.keyword', predicate: CONTAINS, value: tag.id } },
  }));

  const statusOptions: ITableFilterOption[] = useMemo(() => {
    return Object.values(Stage).map((reEnrollStatus) => {
      return { label: t(`enrollment:reenroll-status.${reEnrollStatus}`), value: reEnrollStatus };
    });
  }, []);

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSortDto({
      field: field,
      direction: direction === 'ASCENDING' ? SortDirection.Ascending : SortDirection.Descending,
    });
  }, []);

  const handleFiltersSelect = useCallback(
    (values: ITableFilterOption[], target: string) => {
      switch (target) {
        case 'center':
          setCenterFilters(values);
          break;
        case 'tag':
          setTagFilters(values);
          break;
        case 'status':
          if (values.length > 1) {
            setStatusFilters((prev) => values.filter((s) => !prev.includes(s)));
          } else {
            setStatusFilters(values);
          }
          break;
        case 'correspondenceType':
          if (values.length > 1) {
            setCorrespondenceTypeFilter((prev) => values.filter((s) => !prev.includes(s)));
          } else {
            setCorrespondenceTypeFilter(values);
          }
          break;
        default:
          break;
      }
      tableFunctions.changePage(1, tableState.pageSize);
      setAllRowsSelected(false);
      setSelectedRows([]);
    },
    [tableFunctions, tableState.pageSize]
  );

  const handleSelectRow = useCallback(
    (rows: any) => {
      if (allRowsSelected) setAllRowsSelected(false);
      setSelectedRows((prev) => {
        if (rows.length === 0) {
          const prevWithoutCurentPage = prev.filter(
            (c) => !reenrolHistory.map((d) => d.accountId).includes(c.accountId)
          );
          return prevWithoutCurentPage;
        }
        if (rows.length === tableState.pageSize) {
          return [...prev, ...rows];
        }
        return rows;
      });
    },
    [allRowsSelected, tableState.pageSize, reenrolHistory]
  );

  const handleSearchDebounced = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      tableFunctions.changePage(1, tableState.pageSize);
      setAllRowsSelected(false);
      setSelectedRows([]);
    }, 250),
    [tableFunctions, tableState.pageSize]
  );

  const handleClearFilters = useCallback(() => {
    setCenterFilters([]);
    setTagFilters([]);
    setStatusFilters([]);
    setCorrespondenceTypeFilter([]);
    setFromDate(undefined);
    setToDate(undefined);
    setAllRowsSelected(false);
    setSelectedRows([]);
  }, []);

  const { data: centersData, loading: loadingCenters } = useSearchCenters(
    {
      variables: {
        input: {
          filter: { term: { field: 'active', predicate: 'ACTIVE' } },
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          size: 10000,
          from: 0,
        },
      },
    },
    SEARCH_MY_CENTERS
  );
  const validCorrespondenceTypes = useMemo(() => {
    const types: CorrespondenceType[] = [];
    if (hasReadEnrollmentPermission) types.push(CorrespondenceType.ReenrollmentRequest);
    if (hasReadUpdateDetailsPermission) types.push(CorrespondenceType.UpdateEnrollmentForm);
    return types;
  }, [hasReadEnrollmentPermission, hasReadUpdateDetailsPermission]);

  const correspondenceTypeOptions: ITableFilterOption[] = useMemo(() => {
    return validCorrespondenceTypes.map((correspondenceType) => {
      return { label: t(`enrollment:reenroll-correspondence-type.${correspondenceType}`), value: correspondenceType };
    });
  }, [validCorrespondenceTypes]);

  const { data, loading, error, refetch } = useGetReEnrolHistoryOfActionsQuery({
    skip: businessId === null || businessId === undefined || businessId === '' || finalCenterFilters.length <= 0,
    variables: {
      businessId: businessId ?? '',
      input: {
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortDtos: [sortDto],
        historyFilterBy: {
          centerIds: finalCenterFilters,
          searchTerm: searchTerm,
          startDate: fromDate,
          endDate: toDate,
          stages: statusFilters.length > 0 ? [statusFilters[0].value as Stage] : undefined,
          correspondenceTypes:
            correspondenceTypeFilter.length > 0
              ? [correspondenceTypeFilter[0].value as CorrespondenceType]
              : validCorrespondenceTypes,
        },
      },
    },
  });

  const getTableHeader = useCallback(
    (paginationProps: any) => {
      return (
        <>
          <ReEnrollHistoryTableHeader
            searchText={searchTerm}
            setSearchText={handleSearchDebounced}
            paginationProps={paginationProps}
            handleFiltersSelect={handleFiltersSelect}
            handleClearFilters={handleClearFilters}
            centerFilters={centerFilters}
            centerOptions={centerOptions}
            tagFilters={tagFilters}
            tagOptions={tagOptions}
            statusFilters={statusFilters}
            statusOptions={statusOptions}
            correspondenceTypeFilter={correspondenceTypeFilter}
            correspondenceTypeOptions={correspondenceTypeOptions}
            fromDate={fromDate}
            setFromDate={(date: string) => setFromDate(date)}
            toDate={toDate}
            setToDate={(date: string) => setToDate(date)}
          />
          {(selectedRows.length || allRowsSelected) > 0 && (
            <>
              <ReEnrollHistoryBulkActions
                filterBy={{
                  centerIds: finalCenterFilters,
                  searchTerm: searchTerm,
                  startDate: fromDate,
                  endDate: toDate,
                  stages: statusFilters.length > 0 ? [statusFilters[0].value as Stage] : undefined,
                }}
                includeAccountCorrespondenceIds={selectedRows.map((row) => {
                  return { accountId: row.accountId, reEnrollmentBatchId: row.batchId };
                })}
                includeAllAccountCorrespondenceIds={allRowsSelected}
                displayNumber={allRowsSelected ? totalRecords : selectedRows.length}
                refetchTable={() => {
                  setAllRowsSelected(false);
                  setSelectedRows([]);
                  refetch();
                }}
              />
              <SelectedItemsTotal
                totalRecords={totalRecords}
                selectedRows={selectedRows.length}
                allSelected={allRowsSelected}
                handleClearSelectedRows={() => {
                  setAllRowsSelected(false);
                  setSelectedRows([]);
                }}
                handleSelectAllRows={() => {
                  setAllRowsSelected(true);
                  setSelectedRows([]);
                }}
              />
            </>
          )}
        </>
      );
    },
    [
      searchTerm,
      handleSearchDebounced,
      handleFiltersSelect,
      handleClearFilters,
      centerFilters,
      centerOptions,
      tagFilters,
      tagOptions,
      statusFilters,
      statusOptions,
      fromDate,
      toDate,
      selectedRows,
      allRowsSelected,
      finalCenterFilters,
      totalRecords,
      refetch,
    ]
  );

  useEffect(() => {
    setCenters(centersData?.searchCenters.data ?? []);
  }, [centersData]);

  useEffect(() => {
    const selectedTagsIds = tagFilters.map((t) => t.value);
    const selectedCenterIds = centerFilters.map((c) => c.value);

    if (centerFilters.length > 0 && tagFilters.length > 0) {
      setFinalCenterFilters(
        centers
          .filter((c) => selectedCenterIds.includes(c.id))
          .filter((c) => c.tags.some((t) => selectedTagsIds.includes(t.id)))
          .map((c) => c.id)
      );
    } else if (centerFilters.length > 0) {
      setFinalCenterFilters(centerFilters.map((c) => c.value));
    } else if (tagFilters.length > 0) {
      setFinalCenterFilters(centers.filter((c) => c.tags.some((t) => selectedTagsIds.includes(t.id))).map((c) => c.id));
    } else {
      setFinalCenterFilters(centers.map((c) => c.id));
    }
  }, [centers, centerFilters, tagFilters]);

  useEffect(() => {
    setReenrolHistory(data?.getReEnrolHistoryOfActions?.data ?? []);
    setTotalRecords(data?.getReEnrolHistoryOfActions?.totalRecords ?? 0);
  }, [data]);

  return (
    <>
      <DataTable
        data={reenrolHistory.map((c) => {
          return { ...c, id: c.batchId + c.accountId };
        })}
        dataSize={totalRecords}
        showLoadingOverlay={loading || loadingCenters}
        noPadding
        className="reenroll-table"
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        showSelect={hasEditEnrollmentRequestPermission || hasEditUpdateDetailsPermission}
        selectedRows={allRowsSelected ? reenrolHistory : selectedRows}
        updateSelectedRows={handleSelectRow}
        renderHeader={getTableHeader}
        onSort={handleSort}
        expandRow={(row: ReEnrollmentHistory) => <ReEnrollHistoryExpandedRow row={row} />}
        onlyOneExpanding
        columns={[
          {
            text: 'Batch ID',
            sort: true,
            dataField: 'userFriendlyBatchId',
          },
          {
            text: 'Sent On',
            sort: true,
            dataField: 'createdAt',
            formatter: (cell: any, row: ReEnrollmentHistory) => {
              return moment(row.createdAt).format(`${t('translation:formatters.MM/DD/YYYY')} HH:mm`);
            },
          },
          {
            text: capitalize(t('translation:spelling.center')),
            sort: true,
            dataField: 'centerName',
          },
          {
            text: 'Account name',
            sort: true,
            dataField: 'accountName',
            formatter: (cell: any, row: ReEnrollmentHistory) => {
              return <AccountLink accountId={row.accountId} accountName={row.accountName} openInNewTab />;
            },
          },
          {
            text: capitalize(t('enrollment:reenroll-correspondence-type-title')),
            dataField: 'correspondenceType',
            formatter: (cell: any, row: ReEnrollmentHistory) => {
              return t(
                `enrollment:reenroll-correspondence-type.${
                  row.correspondenceType ?? CorrespondenceType.ReenrollmentRequest
                }`
              );
            },
          },
          {
            text: 'Status',
            dataField: 'stage',
            formatter: (cell: any, row: ReEnrollmentHistory) => {
              return row.stage ? t(`enrollment:reenroll-status.${row.stage}`) : null;
            },
          },
          {
            text: 'Last Status Update',
            sort: true,
            dataField: 'stageLastModifiedAt',
            formatter: (cell: any, row: ReEnrollmentHistory) => {
              return row.stageLastModifiedAt
                ? moment(row.stageLastModifiedAt).format(`${t('translation:formatters.MM/DD/YYYY')} HH:mm`)
                : '';
            },
          },
        ]}
      />
    </>
  );
};

export default ReEnrollHistoryOfActions;
