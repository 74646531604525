import { useDeleteBusRoster } from 'gql/busRoster/mutations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isOpen: boolean;
  busRosterId: string;
  onConfirm: (busRoster: IBusRoster) => void;
  onClose: () => void;
}

const DeleteBusRosterConfirmationModal: React.FC<IProps> = ({ isOpen, busRosterId, onConfirm, onClose, ...props }) => {
  const { t } = useTranslation();

  const [deleteBusRosterFn, { loading: deleteBusRosterLoading }] = useDeleteBusRoster({
    onCompleted: (result) => {
      onConfirm(result.deleteBusRoster);
      onClose();
      showToast(t('bus-roster.delete-success-toast'), 'success');
    },
    onError: (err) => {
      console.log(err);
      showToast(t('bus-roster.delete-error-toast'), 'error');
    },
  });

  return (
    <ConfirmationModal
      title={t('bus-roster.delete-confirmation-modal-title')}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Continue"
      primaryCallback={() => deleteBusRosterFn({ variables: { id: busRosterId } })}
      primaryButtonProps={{ loading: deleteBusRosterLoading, variant: 'danger' }}
    >
      <p>{t('bus-roster.delete-confirmation-modal-body')}</p>
    </ConfirmationModal>
  );
};

export default DeleteBusRosterConfirmationModal;
