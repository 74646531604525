import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import Alert from 'shared/components/Alert';
import { Col, Row } from 'shared/components/Layout';
import { capitalize } from 'lodash';
import { Form } from 'react-bootstrap';
import { orderBy } from 'lodash';
import { showToast } from 'shared/components/Toast';
import { useSearchCenters } from 'gql/center/queries';
import { SEARCH_MY_CENTERS } from 'pages/Centers/subroutes/Profiles/graphql/fields';
import { getCentersSuccess } from 'pages/Centers/duck/actions';
import { useTranslation } from 'react-i18next';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select/Select';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
import useDatatableState from 'shared/hooks/useDatatableState';
import { usePostFreeKindyAcquittal, usePostKindyForAllAcquittal } from 'gql/kindyForAll/mutations';
import { useGetKindyForAllAcquittalErrorsReportLazy } from 'gql/reports/queries';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { getYearOptionsFromYear, getTermOptions } from '../utils';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons';
import Spinner from 'shared/components/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { IKindyForAllSubmissionError } from 'shared/types/kindyForAll';
import Checkbox from 'shared/components/Checkbox';
import DateInput from 'shared/components/DateInput';
import { HorizontalDivider } from 'shared/components/Dividers';
import { SortDirection, useGetFreeKindyCenterSummariesQuery } from 'generated/graphql';

type ModalMode = 'Prepare Acquittal' | 'Submit Acquittal' | 'Download Error Report';
type AlertMode = 'None' | 'Info' | 'Warning' | 'Error';

interface IFormStateShape {
  centerIds: string[];
  year: number;
  period: string | null;
  ownershipTransferred?: boolean | undefined;
  softwareChange?: boolean | undefined;
  softwareChangeDate?: string | undefined;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  year: number;
  onClose: () => void;
}

const FreeKindyAcquittalSubmitModal: React.FC<IProps> = ({ isOpen, isLoading, year, onClose, ...props }) => {
  const [formData, setFormData] = useState<IFormStateShape>({
    centerIds: [],
    year: year,
    period: null,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('center', initialTableSort);

  // TODO: Don't query for all centers via ... datatable??
  // We have useGetActiveCentersWithLoading
  if (tableState.pageSize != 640) {
    tableFunctions.changeSizePerPage(640);
  }

  useSearchCenters(
    {
      variables: {
        input: tableFunctions.getElasticQuery(),
      },
      onCompleted: (data) => {
        dispatch(getCentersSuccess(data.searchCenters.data ?? []));
      },
    },
    SEARCH_MY_CENTERS
  );

  const selectedBusinessId = useSelector((state: RootState) => state.context.businessId);
  const centers = useSelector((state: RootState) => state.centers.all)?.filter((c) => c.address.state === 'AU-QLD');
  const centersForBusiness = React.useMemo(
    () => orderBy(centers?.filter((c) => c.entityId === selectedBusinessId) || [], (c) => c.name, 'asc'),
    [selectedBusinessId, centers]
  );

  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context)?.businessId ?? user?.entityId;
  const [selectedCenters, setSelectedCenters] = React.useState<ICenter[] | null>(null);
  const [modalMode, setModalMode] = useState<ModalMode>('Prepare Acquittal');
  const [alertMode, setAlertMode] = useState<AlertMode>('None');
  const [errors, setErrors] = useState<IKindyForAllSubmissionError[]>();
  const [reportLoading, setReportLoading] = useState<boolean>();
  const [submissionExist, setSubmissionExist] = useState<boolean>(false);
  const reportDataToFile = useReportDataToFile();

  const { data: data, loading: submissionLoading } = useGetFreeKindyCenterSummariesQuery({
    variables: {
      input: {
        centerIds: formData.centerIds ?? [],
        year: Number(formData.year),
        period: formData.period ?? '',
        searchKey: '',
        pageNumber: 1,
        pageSize: 1000,
        sortBy: 'centerName',
        sortDirection: SortDirection.Ascending,
      },
    },
    skip: businessId == null || businessId === '' || !formData.year || !formData.period || !formData.centerIds,
  });

  const handleCentersChange = (centers) => {
    setSelectedCenters(centers);
    const centerIds = centers.map((c) => c.id);
    setFormData((state) => ({
      ...state,
      centerIds: centerIds,
    }));
  };

  const [postFreeKindyAcquittalFn, { loading }] = usePostFreeKindyAcquittal({
    onCompleted: (result) => {
      if (result) {
        if (result.postFreeKindyAcquittal.find((a) => a.messages.length)) {
          var errorList = result.postFreeKindyAcquittal.flatMap((f) =>
            f?.messages?.map(
              (e) =>
                ({
                  centerId: f.centerId,
                  centerName: f.centerName,
                  year: formData.year.toString(),
                  period: formData.period,
                  code: e?.code,
                  message: e?.message,
                  target: e?.target,
                  innerError: e?.innerError,
                } as IKindyForAllSubmissionError)
            )
          );
          setErrors(errorList as IKindyForAllSubmissionError[]);
          if (modalMode === 'Prepare Acquittal') {
            setModalMode('Submit Acquittal');
            setAlertMode('Warning');
          } else if (modalMode === 'Submit Acquittal') {
            showToast('Acquittal processing.', 'success');
            handleClose();
          }
        } else {
          if (modalMode === 'Submit Acquittal') {
            // Acquittal submitted successfully
            showToast('Acquittal processing.', 'success');
            handleClose();
          } else {
            // if dryRun is ok
            setModalMode('Submit Acquittal');
            setAlertMode('Info');
          }
        }
      }
    },
    onError: (error) => {
      showToast(`There was an error submitting Acquittal : ${error.message}`, 'error');
    },
  });

  const [kindyForAllAcquittalErrorsReportFn] = useGetKindyForAllAcquittalErrorsReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(
        result.getKindyForAllAcquittalErrorsReport,
        ReportTypeEnum.KINDY_FOR_ALL_ACQUITTAL_ERRORS
      );
      onClose();
    },
    onError: () => {
      showToast(t('reports.general-failure-message'), 'error');
    },
  });

  const [kindyForAllAcquittalWarningsReportFn] = useGetKindyForAllAcquittalErrorsReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(
        result.getKindyForAllAcquittalErrorsReport,
        ReportTypeEnum.KINDY_FOR_ALL_ACQUITTAL_ERRORS
      );
      setReportLoading(false);
    },
    onError: () => {
      showToast(t('reports.general-failure-message'), 'error');
    },
  });

  const downloadErrorReport = () => {
    if (errors) {
      setReportLoading(true);
      kindyForAllAcquittalWarningsReportFn({
        variables: {
          input: {
            businessId: businessId,
            errors: errors,
          },
        },
      });
    }
  };

  const handleSubmit = useCallback(() => {
    if (formData.centerIds && formData.year && formData.period) {
      switch (modalMode) {
        case 'Submit Acquittal':
          postFreeKindyAcquittalFn({
            variables: {
              input: {
                businessId: businessId,
                centerIds: formData.centerIds,
                year: formData.year?.toString(),
                period: formData.period,
                dryRun: false,
                ownershipTransferred: formData.ownershipTransferred,
                softwareChangedDate: formData.softwareChangeDate,
              },
            },
          });

          break;
        case 'Prepare Acquittal':
          postFreeKindyAcquittalFn({
            variables: {
              input: {
                businessId: businessId,
                centerIds: formData.centerIds,
                year: formData.year?.toString(),
                period: formData.period,
                dryRun: true,
                ownershipTransferred: formData.ownershipTransferred,
                softwareChangedDate: formData.softwareChangeDate,
              },
            },
          });

          break;
        case 'Download Error Report':
          if (errors) {
            kindyForAllAcquittalErrorsReportFn({
              variables: {
                input: {
                  businessId: businessId,
                  errors: errors,
                },
              },
            });
          }
          break;
      }
    }
  }, [businessId, errors, formData, kindyForAllAcquittalErrorsReportFn, modalMode, postFreeKindyAcquittalFn]);

  const handleClose = useCallback(() => {
    setSelectedCenters(null);
    setModalMode('Prepare Acquittal');
    setAlertMode('None');
    setErrors(undefined);
    setSubmissionExist(false);
    setFormData({
      centerIds: [],
      year: year,
      period: null,
    });
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!isOpen) {
      handleClose();
    }
  }, [handleClose, isOpen]);

  const handleOwnershipTransferred = (value: boolean) => {
    setFormData((state) => ({
      ...state,
      ownershipTransferred: value,
    }));
  };

  const handleSoftwareChange = (value: boolean) => {
    setFormData((state) => ({
      ...state,
      softwareChange: value,
    }));

    if (!value) {
      onSoftwareChangedDateChanged(undefined);
    }
  };

  const onSoftwareChangedDateChanged = (date: string | undefined) => {
    setFormData((state) => ({
      ...state,
      softwareChangeDate: date,
    }));
  };

  const isFormValid = useCallback(() => {
    if (formData.softwareChange && formData.softwareChangeDate === undefined) return false;

    return formData.centerIds.length && formData.period && formData.year && !submissionExist;
  }, [formData, submissionExist]);

  return (
    <SideModalDrawer
      title={modalMode}
      show={isOpen}
      onHide={handleClose}
      primaryChoice={modalMode}
      primaryCallback={() => handleSubmit()}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      primaryButtonProps={{
        loading: loading || submissionLoading,
        disabled: !isFormValid(),
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      hasPrimaryChoice
    >
      <div>
        {modalMode != 'Prepare Acquittal' && (
          <div>
            <Row>
              <Col>
                <Row>
                  <label>{capitalize(t('spelling.center_plural'))}</label>
                </Row>
                <Row>
                  <ul>
                    {selectedCenters?.length === centers.length ? (
                      <li>
                        <h6>All Centers</h6>
                      </li>
                    ) : (
                      selectedCenters?.map((c) => (
                        <li>
                          <h6>{`${c?.name}`}</h6>
                        </li>
                      ))
                    )}
                  </ul>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Row>
                  <label>Year</label>
                </Row>
                <Row>
                  <h6>{formData.year}</h6>
                </Row>
              </Col>
              <Col lg={6}>
                <Row>
                  <label>Term</label>
                </Row>
                <Row>
                  <h6>Term {formData.period}</h6>
                </Row>
              </Col>
            </Row>
            {alertMode == 'Info' && (
              <Row>
                <Col>
                  <Alert variant="info" className="mt-2">
                    <div>Validation successful! Submit your Acquittal below.</div>
                  </Alert>
                </Col>
              </Row>
            )}
            {alertMode == 'Warning' && (
              <Row>
                <Col>
                  <Alert variant="warning" className="mt-2">
                    <div className="ml-2">
                      <Row>
                        Warnings / Errors found. Click "Submit Acquittal" to continue, or download the warning report
                        for guidance to correct any records.
                      </Row>
                      <br />
                      <Row>
                        <Link
                          to="#"
                          className="text-gray pointer"
                          style={{ textDecoration: 'none' }}
                          onClick={() => downloadErrorReport()}
                        >
                          <div>
                            <FontAwesomeIcon className="text-gray mr-2" icon={faArrowToBottom} />
                            Download warning / error report
                            {reportLoading && <Spinner className="text-gray ml-2" small />}
                          </div>
                        </Link>
                      </Row>
                    </div>
                  </Alert>
                </Col>
              </Row>
            )}
            {alertMode == 'Error' && (
              <Row>
                <Col>
                  <Alert variant="danger" className="mt-2">
                    Errors found. Download the error report below for more information. Correct the errors, then try
                    again.
                  </Alert>
                </Col>
              </Row>
            )}
          </div>
        )}
        {modalMode == 'Prepare Acquittal' && (
          <Form>
            <Row className="mb-2">
              <Col>
                <SelectMultiple
                  required
                  label={capitalize(t('spelling.center_plural'))}
                  newLabelStyle
                  onChange={(prev) => {
                    handleCentersChange(prev);
                  }}
                  options={centersForBusiness}
                  value={selectedCenters}
                  getOptionLabel={(option: ICenter) => option.name}
                  getOptionValue={(option: ICenter) => option.id}
                  displayCountInContainerRenderer={(selectedCount) =>
                    selectedCount > 0
                      ? selectedCount +
                        ' ' +
                        (selectedCount === 1
                          ? capitalize(t('spelling.center'))
                          : capitalize(t('spelling.center_plural'))) +
                        ' Selected'
                      : `Select a ${capitalize(t('spelling.center'))}`
                  }
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={6}>
                <Select
                  disabled={year <= 2023}
                  options={getYearOptionsFromYear(year)}
                  label="Year"
                  required
                  getOptionLabel={(g) => g.label}
                  getOptionValue={(g) => g.value}
                  name="year"
                  value={formData?.year}
                  onChange={(val) =>
                    setFormData((prev) => ({
                      ...prev,
                      year: val.value,
                    }))
                  }
                />
              </Col>
              <Col lg={6}>
                <Select
                  options={getTermOptions()}
                  label="Term"
                  required
                  getOptionLabel={(g) => g.label}
                  getOptionValue={(g) => g.value}
                  name="period"
                  value={formData?.period}
                  onChange={(val) =>
                    setFormData((prev) => ({
                      ...prev,
                      period: val.value,
                    }))
                  }
                />
              </Col>
            </Row>
          </Form>
        )}
        {submissionExist && (
          <Row>
            <Col>
              <Alert variant="danger" className="mt-4">
                An Acquittal has already been submitted for
                {data?.getFreeKindyCenterSummaries.data
                  ?.filter((c) => formData.centerIds.includes(c.centerId))
                  .map((cn) => ' ' + cn.centerName + ', ')}
                year {formData.year}, term {formData.period}. To re-submit, please delete existing acquittal and try
                again.
              </Alert>
            </Col>
          </Row>
        )}
        {/* TODO: Translations */}
        <HorizontalDivider />
        <Form className="mt-4">
          <h6>Reason for early Acquittal (if applicable)</h6>
          <Row align="start" className=" mt-2">
            <Col>
              <Checkbox
                label="Change of Ownership"
                onChange={(val) => handleOwnershipTransferred(val)}
                value={formData.ownershipTransferred}
              />
            </Col>
          </Row>
          <Row align="start" className=" mt-2">
            <Col>
              <Checkbox
                label="Change of Software"
                onChange={(val) => handleSoftwareChange(val)}
                value={formData.softwareChange}
              />
            </Col>
          </Row>
          <Row align="start" className=" mt-2">
            <Col>
              <DateInput
                className="kt-date-input-no-max-width mt-1"
                required={formData.softwareChange}
                disabled={!formData.softwareChange}
                helpTooltipText={`The date that the software change will be taking effect`}
                helpTooltipDirection="top"
                label={`Software Change Date`}
                date={formData.softwareChangeDate}
                onDateSelect={(val) => onSoftwareChangedDateChanged(val)}
                dateOnly
              />
            </Col>
          </Row>
        </Form>
        <HorizontalDivider />
        {modalMode == 'Submit Acquittal' && (
          <Row>
            <Col>
              <Alert variant="info">
                <div className="ml-2">
                  <Row>
                    Up to 4 previous acquittals will now be submitted for adjustment review in line with Queensland
                    Government rules. Any changes that have been made to a child's data during that time will be
                    submitted and gap fee subsidy totals may be adjusted.
                  </Row>
                </div>
              </Alert>
            </Col>
          </Row>
        )}
      </div>
    </SideModalDrawer>
  );
};

export default FreeKindyAcquittalSubmitModal;
