import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

interface IProps {
  icon: IconDefinition;
}

const IconWithText: React.FC<IProps> = ({ icon, ...props }) => {
  return (
    <div className="kt-staff-schedules-shift-modal-secondary-text mb-2">
      <FontAwesomeIcon className="mr-4" icon={icon} color={colors.gray} size="lg" />
      {props.children}
    </div>
  );
};

export default IconWithText;
