import React, { useCallback, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { components, OptionProps } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { Col, Row } from 'shared/components/Layout';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { useGetBillingCycleTemplates } from 'gql/billingCycle/queries';
import colors from '../../../_colors.module.scss';
import Select from 'react-select';
import './_select.scss';
import { Line } from 'shared/components/LoadingSkeletons';
import Checkbox from 'shared/components/Checkbox';
import { BalancedBasedBillingCycleMessage } from 'pages/Billing/Settings/Tabs/Cycles/PreviewBillingCycleModal';
import { PeriodBasedBillingCycleMessage } from 'pages/Billing/Settings/Tabs/Cycles/PreviewPeriodBasedBillingCycleModal';

interface IBillingCycleTemplateSelectProps {
  selectedBillingCycleTemplateIds?: string[] | null;
  onSelect: (billingCycleIdTemplates: string[]) => void;
  required?: boolean;
  // if isMulti = false, the 'all option' will not be shown
  disableAllOption?: boolean;
  isMulti?: boolean;
  centerId?: string;
  businessId: string;
  label?: string;
  placeholder?: string;
  [key: string]: any;
}

const BillingCycleTemplateSelect: React.FC<IBillingCycleTemplateSelectProps> = ({
  selectedBillingCycleTemplateIds,
  onSelect,
  required = false,
  disableAllOption = false,
  isMulti = true,
  centerId,
  businessId,
  label = 'Billing Cycle Templates',
  placeholder = 'Select Billing Cycle Template',
  ...props
}) => {
  const ALL_OPTIONS = useMemo(
    () => ({ value: 'All Billing Cycle Templates', label: 'All Billing Cycle Templates' }),
    []
  );

  const { data, loading } = useGetBillingCycleTemplates({
    variables: {
      centerIds: centerId ? [centerId] : undefined,
    },
    onError: (error) => showToast(getApolloErrorMessage(error), 'error'),
  });

  const options = useMemo(() => {
    const formattedOptions = (data?.getBillingCycleTemplates ?? []).map((billingCycleTemplate) => ({
      value: billingCycleTemplate.id,
      label: billingCycleTemplate.name,
    }));

    if (disableAllOption || !isMulti) {
      return formattedOptions;
    }

    return [ALL_OPTIONS, ...formattedOptions];
  }, [disableAllOption, ALL_OPTIONS, isMulti, data]);

  const selectedOptions = useMemo(() => {
    if (
      selectedBillingCycleTemplateIds?.length &&
      selectedBillingCycleTemplateIds.length === (data?.getBillingCycleTemplates ?? []).length &&
      isMulti
    ) {
      return [ALL_OPTIONS];
    }

    return (data?.getBillingCycleTemplates ?? [])
      .filter((template) => selectedBillingCycleTemplateIds?.includes(template.id))
      .map((template) => ({ label: template.name, value: template.id }));
  }, [ALL_OPTIONS, data, selectedBillingCycleTemplateIds, isMulti]);

  const MenuItem = useCallback(
    (props: OptionProps<ITableFilterOption, false>) => {
      const template = (data?.getBillingCycleTemplates ?? []).find((template) => template.id === props.data.value);

      if (!template) {
        return (
          <components.Option {...props} className="border-bottom">
            <Row>
              {isMulti && (
                <Col xs="1">
                  <Checkbox value={props.isSelected} />
                </Col>
              )}
              <Col>
                <h6>{props.data.label}</h6>
              </Col>
            </Row>
          </components.Option>
        );
      }

      const { frequency, invoiceSchedule, period, additionalChargePeriod, dayOfMonth, dayOfWeek, name, cycleType } =
        template;

      // balanced based billing cycle
      if (cycleType === 'BALANCE_BASED') {
        return (
          <components.Option {...props} className="border-bottom">
            <Row>
              {isMulti && (
                <Col xs="1">
                  <Checkbox value={props.isSelected} />
                </Col>
              )}
              <Col>
                <h6 className="mb-2">{name}</h6>
                <BalancedBasedBillingCycleMessage
                  dayOfMonth={dayOfMonth}
                  dayOfWeek={dayOfWeek ?? ('' as DayOfWeek)}
                  invoiceSchedule={invoiceSchedule}
                  period={period}
                  additionalChargePeriod={additionalChargePeriod}
                  frequency={frequency}
                  className="mb-2"
                />
              </Col>
            </Row>
          </components.Option>
        );
      }

      // period based billing cycle
      return (
        <components.Option {...props} className="border-bottom">
          <Row>
            {isMulti && (
              <Col xs="1">
                <Checkbox value={props.isSelected} />
              </Col>
            )}
            <Col>
              <h6 className="mb-2">{name}</h6>
              <PeriodBasedBillingCycleMessage
                dayOfMonth={dayOfMonth}
                dayOfWeek={dayOfWeek ?? ('' as DayOfWeek)}
                invoiceSchedule={invoiceSchedule}
                frequency={frequency}
                period={period}
                className="mb-2"
              />
            </Col>
          </Row>
        </components.Option>
      );
    },
    [data, isMulti]
  );

  const handleSelect = useCallback(
    (newValue, actionMeta) => {
      if (!newValue) {
        onSelect([]);
        return;
      }

      const { action, option } = actionMeta;

      const isAllOptionSelected = isMulti ? option?.value === ALL_OPTIONS.value : newValue?.value === ALL_OPTIONS.value;

      if (isAllOptionSelected) {
        switch (action) {
          case 'select-option':
            onSelect((data?.getBillingCycleTemplates ?? []).map((template) => template.id));
            return;
          default:
            onSelect([]);
            return;
        }
      }

      onSelect(isMulti ? newValue.map((option: any) => option.value) : [newValue.value]);
    },
    [onSelect, ALL_OPTIONS, isMulti, data]
  );

  return (
    <Form.Group>
      <Form.Label>
        {label}
        {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color={colors.red} />}
      </Form.Label>
      {loading ? (
        <Line />
      ) : (
        <Select
          isMulti={isMulti}
          options={options}
          value={selectedOptions}
          isLoading={loading}
          onChange={handleSelect}
          closeMenuOnSelect={!isMulti}
          className="react-select-container flex-wrap"
          classNamePrefix="react-select"
          hideSelectedOptions={false}
          noOptionsMessage={() => 'No billing cycle templates found'}
          placeholder={placeholder}
          components={{
            Option: MenuItem,
          }}
          {...props}
        />
      )}
    </Form.Group>
  );
};

export default BillingCycleTemplateSelect;
