const passwordRequirements = [
  {
    label: 'Include upper and lower case letters (e.g. Aa)',
    meetsRequirement: (password) =>
      password && password.toUpperCase() !== password && password.toLowerCase() !== password,
  },
  {
    label: 'Include a number (e.g. 1234)',
    meetsRequirement: (password) => password && /\d/.test(password),
  },
  {
    label: 'Include a special character (e.g. !@#$)',
    meetsRequirement: (password) => password && /[$^*.(){}?"!@#%&/\\,<>':;|_~\][-`]/g.test(password),
    // cognito allows: ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ `
  },
  {
    label: 'Include 8 or more characters',
    meetsRequirement: (password) => password && password.length >= 8,
  },
];

const meetsAllRequirements = (password) =>
  password && passwordRequirements.every((requirement) => requirement.meetsRequirement(password));

export { passwordRequirements, meetsAllRequirements };
