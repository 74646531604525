import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGetOccupancyTotalReportVariables } from 'gql/reports/queries';
import moment from 'moment';
import { getAllClasses } from 'pages/Centers/subroutes/Classes/duck/selectors';
import { useGetClassesForMultipleCenters } from 'pages/Centers/subroutes/Classes/graphql/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import BaseSelect from 'shared/components/Select/Base';
import Switch from 'shared/components/Switch';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { isRegion } from 'shared/util/region';
import { capitalize } from 'shared/util/string';
import MultipleCenterSelect from '../../../shared/components/Select/MultipleCenterSelect';

interface IFormStateShape {
  centerIds: string[] | null;
  selectedClasses: Array<{ value: string; label: string }> | null;
  startDate: string | null;
  endDate: string | null;
  excludeClassesWithNoAttendance: boolean;
  excludeNonCcsCareType: boolean;
  includeLeadApplications: boolean;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  onSubmit: (input: IGetOccupancyTotalReportVariables) => void;
  onClose: () => void;
  showReportFormat?: boolean;
  className?: string;
  isEndDateRequired?: boolean;
}

const WeeklyOccupancyReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  showReportFormat,
  className,
  isEndDateRequired = true,
  ...props
}) => {
  const { t } = useTranslation();
  const ALL_CLASS_OPTIONS = {
    value: 'All',
    label: `All ${capitalize(t('spelling.class'))}es`,
  };
  const [formData, setFormData] = useState<IFormStateShape>({
    centerIds: [],
    selectedClasses: [],
    startDate: null,
    endDate: null,
    excludeNonCcsCareType: true,
    excludeClassesWithNoAttendance: true,
    includeLeadApplications: false,
  });
  const centerSelectOptions: ICenter[] = useGetActiveCenters();

  const { loading: classesLoading } = useGetClassesForMultipleCenters(formData.centerIds ? formData.centerIds : []);
  const classes = useSelector(getAllClasses);
  const classSelectOptions = classes.map((i) => ({ label: i.name, value: i.id })) ?? [];

  const isAllOptionSelected = useCallback(
    (): boolean =>
      !!formData.selectedClasses &&
      formData.selectedClasses.length > 0 &&
      formData.selectedClasses.length === classSelectOptions.length,
    [formData.selectedClasses, classSelectOptions]
  );

  const getSelectValue = useCallback(() => {
    return isAllOptionSelected()
      ? [ALL_CLASS_OPTIONS]
      : classSelectOptions.filter(
          (opt) => !!formData.selectedClasses && formData.selectedClasses.map((i) => i.value).includes(opt.value)
        );
  }, [isAllOptionSelected, ALL_CLASS_OPTIONS, classSelectOptions, formData.selectedClasses]);

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        centerIds: [],
        selectedClasses: [],
        startDate: null,
        endDate: null,
        excludeNonCcsCareType: true,
        excludeClassesWithNoAttendance: true,
        includeLeadApplications: false,
      });
    }
  }, [isOpen]);

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (formData.startDate && formData.endDate && formData.centerIds && formData.selectedClasses) {
      onSubmit({
        input: {
          startDate: formData.startDate,
          endDate: formData.endDate,
          centerIds: formData.centerIds,
          classIds: formData.selectedClasses.map((i) => i.value),
          excludeClassesWithNoAttendance: formData.excludeClassesWithNoAttendance,
          excludeNonCcsCareType: formData.excludeNonCcsCareType,
          includeLeadApplications: formData.includeLeadApplications,
          businessId: '',
        },
      });
    }
  }, [formData, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({
      centerIds: [],
      selectedClasses: [],
      startDate: null,
      endDate: null,
      excludeNonCcsCareType: true,
      excludeClassesWithNoAttendance: true,
      includeLeadApplications: false,
    });
    onClose();
  }, [onClose]);

  const handleClassSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_CLASS_OPTIONS.value) {
        setFormData({ ...formData, selectedClasses: classSelectOptions });
      } else if (
        (action === 'deselect-option' && option.value === ALL_CLASS_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_CLASS_OPTIONS.value)
      ) {
        setFormData({ ...formData, selectedClasses: [] });
      } else if (actionMeta.action === 'deselect-option' && isAllOptionSelected()) {
        setFormData({
          ...formData,
          selectedClasses: (formData.selectedClasses || []).filter((i) => i.value !== option.value),
        });
      } else {
        setFormData({
          ...formData,
          selectedClasses: (options || []).filter((opt) => opt.value !== null),
        });
      }
    },
    [ALL_CLASS_OPTIONS.value, classSelectOptions, formData, isAllOptionSelected]
  );

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled:
          !formData.startDate ||
          !formData.centerIds ||
          !formData.selectedClasses ||
          !formData.selectedClasses.length ||
          (isEndDateRequired && !formData.endDate),
        loading: isLoading,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      className={className}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            isRequired
            selectedCenterIds={formData.centerIds}
            onSelect={(ids) => setFormData((prev) => ({ ...prev, centerIds: ids as string[] }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              {capitalize(t('spelling.class'))}{' '}
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </Form.Label>
            <BaseSelect
              // @ts-ignore
              isMulti
              required
              id="class-input"
              isLoading={classesLoading}
              aria-label={`Select ${capitalize(t('spelling.class'))}`}
              // @ts-ignore
              value={getSelectValue()}
              options={[ALL_CLASS_OPTIONS, ...classSelectOptions]}
              // @ts-ignore
              onChange={(options, action) => handleClassSelect(options as unknown as any[], action)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            required
            label={capitalize(t('spelling.start-date'))}
            date={formData.startDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, startDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(date: moment.Moment) =>
              formData.endDate ? date.isSameOrAfter(moment(formData.endDate), 'date') : false
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            required
            label={capitalize(t('spelling.end-date'))}
            date={formData.endDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, endDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(date: moment.Moment) =>
              formData.startDate ? date.isSameOrBefore(moment(formData.startDate), 'date') : false
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Switch
            label={'Exclude Classes With No Attendance'}
            labelSide="top"
            value={formData.excludeClassesWithNoAttendance}
            onChange={(value: boolean) =>
              setFormData({
                ...formData,
                excludeClassesWithNoAttendance: value,
              })
            }
            className="mb-4"
            height={30}
          />
        </Col>
      </Row>
      {isRegion('AU') && (
        <Row>
          <Col>
            <Switch
              label={'Exclude Non CCS Care Type'}
              labelSide="top"
              value={formData.excludeNonCcsCareType}
              onChange={(value: boolean) =>
                setFormData({
                  ...formData,
                  excludeNonCcsCareType: value,
                })
              }
              className="mb-4"
              height={30}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Switch
            label={'Include Lead Applications'}
            labelSide="top"
            value={formData.includeLeadApplications}
            onChange={(value: boolean) =>
              setFormData({
                ...formData,
                includeLeadApplications: value,
              })
            }
            className="mb-4"
            height={30}
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default WeeklyOccupancyReportModal;
