import React, { useEffect, useMemo } from 'react';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import Select from 'shared/components/Select';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetServicesByBusiness } from '../graphql/queries';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import NotifiableEventsHistoryTable from '../NotifiableEventsHistoryTable/NotifiableEventsHistoryTable';

type NotifiableEventsHistoryTableProps = typeof NotifiableEventsHistoryTable extends React.FC<infer T> ? T : never;
interface Props {
  title: string;
  subTitle?: string;
  notificationMessage?: string;
  child: any;
  onCancel: () => void;
  onSave: () => void;
  formIsDirty: boolean;
  toggleDirty: (isDirty: boolean) => void;
  saveDisabled: boolean;
  primaryChoice?: string;
  serviceOnChange: (option: any) => void;
  selectedService: any;
  isLoading?: boolean;
  isSaving?: boolean;
  isMultiSelect?: boolean;
  notifiableEventsHistoryTableProps?: NotifiableEventsHistoryTableProps;
  deleteButton?: boolean;
  onDelete?: () => void;
  deleteLoading?: boolean;
}

const NotifiableServiceEventsContainer: React.FC<Props> = ({
  title,
  child,
  subTitle,
  notificationMessage,
  onCancel,
  onSave,
  formIsDirty,
  toggleDirty,
  saveDisabled,
  primaryChoice = 'Update',
  serviceOnChange,
  selectedService,
  isLoading = false,
  isSaving = false,
  isMultiSelect = false,
  notifiableEventsHistoryTableProps = {},
  deleteButton = false,
  onDelete = () => {},
  deleteLoading = false,
}) => {
  const entityId = useSelector((state: RootState) => state.user?.entityId) ?? '';
  const { data, loading } = useGetServicesByBusiness(entityId);

  const serviceOptions = useMemo(
    () =>
      data?.getServicesByBusiness.data.map((s) => {
        return { value: s.id, label: `${s.serviceId} - ${s.name}` };
      }),
    [data]
  );

  useEffect(() => {
    if (serviceOptions && !isMultiSelect && serviceOptions?.length > 0) {
      serviceOnChange(serviceOptions[0]);
    }
  }, [serviceOptions]);

  return (
    <SpinnerOverlay show={loading || isLoading}>
      <Card header={title} loadingLines={2}>
        {subTitle && <div className="mb-6">{subTitle}</div>}
        {notificationMessage && (
          <div className="ccs-account-management blue-info-block p-4 d-flex flex-row mb-6">
            <div className="mr-4">
              <FontAwesomeIcon icon={faInfoCircle} size="2x" />
            </div>
            <div className="mr-6 d-flex align-items-center">{notificationMessage}</div>
          </div>
        )}
        <FormWrapper2
          onCancel={onCancel}
          onSave={onSave}
          loading={isSaving}
          formIsDirty={formIsDirty}
          toggleDirty={toggleDirty}
          saveDisabled={saveDisabled}
          primaryChoice={primaryChoice}
          deleteButton={deleteButton}
          onDelete={onDelete}
          deleteLoading={deleteLoading}
        >
          <div>
            {
              <Row>
                <Col className="mb-2" sm={6}>
                  <Row>
                    <Col>
                      {isMultiSelect ? (
                        <Select
                          label={'Select Services'}
                          isMulti
                          value={selectedService}
                          options={serviceOptions ?? []}
                          onChange={serviceOnChange}
                          className="flex-wrap"
                        />
                      ) : (
                        <Select
                          inputId="select-service"
                          label={'Select Service'}
                          options={serviceOptions ?? []}
                          value={
                            !selectedService
                              ? undefined
                              : serviceOptions?.find((option) => option.value === selectedService?.value)
                          }
                          onChange={serviceOnChange}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
            {child}
          </div>
        </FormWrapper2>

        {/** @todo Add this when backend supports history */
        /*
            <Card header='Update History' loading={false} loadingLines={2} bodyClassName="notifiable-events">
              <NotifiableEventsHistoryTable {...notifiableEventsHistoryTableProps} />
            </Card>
        */}
      </Card>
    </SpinnerOverlay>
  );
};

export default NotifiableServiceEventsContainer;
