import React, { useCallback } from 'react';
import { Col, Row } from 'shared/components/Layout';
import moment, { Moment } from 'moment';
import { DayPickerSingleDateController } from 'react-dates';
import { setOtherZone } from 'shared/util/timeUtils';
interface IProps {
  contract: ICreateContractInput;
  updateContract: React.Dispatch<React.SetStateAction<ICreateContractInput>>;
  className?: string;
  timezone: string;
}

const CustomTimeSlotsInputs: React.FC<IProps> = ({ contract, updateContract, className, timezone }) => {
  const doesTimeSlotMatchDate = useCallback(
    (date: Moment, timeSlot: ICreateContractTimeslotInput) =>
      timeSlot.date && moment.tz(timeSlot.date, timezone).isSame(date.tz(timezone), 'd'),
    [timezone]
  );
  const doesTimeSlotMatchDay = useCallback(
    (day: number, timeSlot: ICreateContractTimeslotInput) =>
      timeSlot.date && moment(timeSlot.date).tz(timezone).day() === day,
    [timezone]
  );
  const isDateSelected = useCallback(
    (date: Moment) => contract.timeslots.some((ts) => doesTimeSlotMatchDate(date, ts)),
    [contract.timeslots, doesTimeSlotMatchDate]
  );

  const getSampleTimeSlotForDay = (day: number) => contract.timeslots.find((ts) => doesTimeSlotMatchDay(day, ts));

  const handleDateChange = useCallback(
    (date: Moment | null) => {
      if (date) {
        const updatedTimeSlots = isDateSelected(date)
          ? contract.timeslots.filter((ts) => !doesTimeSlotMatchDate(date, ts))
          : [
              ...contract.timeslots,
              {
                date: date.toISOString(),
                dropOffTime: getSampleTimeSlotForDay(date.day())?.dropOffTime,
                pickUpTime: getSampleTimeSlotForDay(date.day())?.pickUpTime,
              },
            ];
        updateContract({ ...contract, timeslots: updatedTimeSlots });
      }
    },
    [contract, doesTimeSlotMatchDate, getSampleTimeSlotForDay, isDateSelected, updateContract]
  );

  return (
    <div className={className}>
      {!contract.endDate && <div className="text-danger small mt-n2 mb-2">Custom contract requires an end date.</div>}
      {contract.endDate && (
        <DayPickerSingleDateController
          hideKeyboardShortcutsPanel
          date={null}
          onDateChange={(d) => handleDateChange(d ? setOtherZone(d, timezone) : d)}
          focused
          onFocusChange={() => {}}
          numberOfMonths={1}
          isDayHighlighted={(day) => isDateSelected(setOtherZone(day, timezone))}
          isDayBlocked={(day) => day.day() === 0 || day.day() === 6}
          isOutsideRange={(day) =>
            Boolean(
              setOtherZone(day, timezone).isBefore(moment.tz(contract.startDate, timezone), 'day') ||
                (contract.endDate && setOtherZone(day, timezone).isAfter(moment.tz(contract.endDate, timezone), 'day'))
            )
          }
        />
      )}
    </div>
  );
};

export default CustomTimeSlotsInputs;
