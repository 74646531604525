import React, { useState, useEffect } from 'react';
import { withRouter, Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import LoggedOutPage from 'shared/components/SignUpPage';
import VerifyCodeAndEmail from './VerifyCodeAndEmail';
import CreatePassword from './CreatePassword';
import CreatePin from './CreatePin';
import InputName from './InputName';
import queryString from 'query-string';
import { useValidateInvite } from './graphql/queries';
import { useGetAuthenticationIdentityResponseLazyQuery } from 'generated/graphql';
import Spinner from 'shared/components/Spinner/Spinner';

export const steps = {
  verifyCode: 'verifyCode',
  createPassword: 'createPassword',
  createPin: 'createPin',
  inputName: 'inputName',
};

interface IProps {
  isAuthed: boolean;
}

const Join: React.FC<IProps & RouteComponentProps> = ({ isAuthed, location }) => {
  const history = useHistory();
  const step = queryString.parse(location.search).step || steps.verifyCode;
  const code = queryString.parse(location.search).joinCode;
  const email = queryString.parse(location.search).email;
  const { data, loading } = useValidateInvite({ email: email || '', inviteCode: code || '' });
  const [idpIdentifier, setIdpIdentifier] = useState<string | null | undefined>();
  const [isSso, setIsSso] = useState<boolean | undefined>();

  useEffect(() => {
    const result = data?.checkInviteCodeEmailCombo;
    if (result) {
      updateNewUser((prev) => ({
        ...prev,
        firstName: result.personFirstName,
        lastName: result.personLastName,
        codeValid: result.inviteValid,
        emailValid: result.emailValid,
        personId: result.personId,
        existingActiveStaff: result.existingActiveStaff,
        existingRequest: result.existingRequest,
      }));
      if (result.inviteValid && result.emailValid && !result.existingActiveStaff && !result.existingRequest) {
        history.push(`/join/?step=${steps.createPassword}`);
      }
    }
  }, [data, history]);

  const [getIdpIdentifierQuery, { loading: isLoading }] = useGetAuthenticationIdentityResponseLazyQuery({
    variables: {
      input: {
        email,
      },
    },
  });

  useEffect(() => {
    getIdpIdentifierQuery().then((res) => {
      setIdpIdentifier(res?.data?.getAuthenticationIdentityResponse?.idpIdentifier);
    });
    setIsSso(!!idpIdentifier);
  }, [getIdpIdentifierQuery, idpIdentifier]);

  const [newUser, updateNewUser] = useState<INewUser>({
    email: email,
    code: code,
    emailValid: false,
    codeValid: false,
    password: null,
    pin: null,
    firstName: '',
    lastName: '',
    personId: null,
    existingActiveStaff: false,
    existingRequest: false,
  });

  const renderStep = () => {
    switch (step) {
      case steps.verifyCode:
        return <VerifyCodeAndEmail newUser={newUser} updateNewUser={updateNewUser} />;
      case steps.createPassword:
        if (isSso) {
          return <CreatePin newUser={newUser} updateNewUser={updateNewUser} isSso={isSso} />;
        } else {
          return <CreatePassword newUser={newUser} updateNewUser={updateNewUser} />;
        }
      case steps.createPin:
        return <CreatePin newUser={newUser} updateNewUser={updateNewUser} isSso={isSso} />;
      case steps.inputName:
        return <InputName newUser={newUser} updateNewUser={updateNewUser} isSso={isSso} />;
      default:
        return <VerifyCodeAndEmail newUser={newUser} updateNewUser={updateNewUser} />;
    }
  };

  return (
    <LoggedOutPage redirect={true} isAuthed={isAuthed}>
      {(!newUser.email || !newUser.code) && step !== steps.verifyCode && <Redirect to={`/join/?step=${step}`} />}
      {loading || isLoading ? (
        <div className="d-flex h-100">
          <Spinner large className="mx-auto my-auto" />
        </div>
      ) : (
        renderStep()
      )}
    </LoggedOutPage>
  );
};

export default withRouter(Join);
