import React, { useCallback } from 'react';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import Switch from 'shared/components/Switch';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { nameof } from 'ts-simple-nameof';

interface IProps {
  isLoading: boolean;
  data: ICenterBatchingSetting[];
  onCenterAutomatedBatchingToggle: (centerId: string, automatedBatching: boolean) => void;
  onSortColumn: (field: string, direction: 'asc' | 'desc') => void;
  noPadding: boolean;
}

const CentersWithAutomatedBatchesTable: React.FC<IProps> = ({
  data,
  isLoading,
  noPadding,
  onSortColumn,
  onCenterAutomatedBatchingToggle,
}) => {
  const canEditAutomatedBatching = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Surcharges,
    level: RoleLevelType.Edit,
  });

  const { t } = useTranslation();

  const getState = useCallback((stateCountryCode: string, country: string): string => {
    return STATE_SELECT_OPTIONS[country].find((option) => option.value === stateCountryCode)?.label ?? '';
  }, []);

  const getColumnsForDataTable = useCallback((): any[] => {
    const dataTableColumns = [
      {
        text: `${capitalize(t('spelling.center'))} Name`,
        dataField: nameof<ICenterBatchingSetting>((s) => s.center.name),
        sort: true,
        formatter: (cell: string, row: ICenterBatchingSetting) => (
          <AvatarDataTableCell
            initials={row.center.name && row.center.name[0].toUpperCase()}
            avatar={row.center.avatar && row.center.avatar.url}
            primaryText={row.center.name}
          />
        ),
      },
      {
        text: t('billing.settings.automaticBatching.datatable.city'),
        dataField: nameof<ICenterBatchingSetting>((s) => s.center.address.city),
        sort: true,
      },
      {
        text: t('billing.settings.automaticBatching.datatable.state'),
        dataField: nameof<ICenterBatchingSetting>((s) => s.center.address.state),
        sort: true,
        formatter: (cell: string, row: ICenterBatchingSetting) => {
          if (row.center.address?.state && row.center.address?.country) {
            return getState(row.center.address.state, row.center.address?.country ?? '');
          }

          return '';
        },
      },
      {
        text: t('billing.settings.automaticBatching.datatable.enableAutomaticBatching'),
        dataField: nameof<ICenterBatchingSetting>((s) => s.enableAutomaticBatching),
        formatter: (cell: string, row: ICenterBatchingSetting) => (
          <Switch
            checkedText="Yes"
            uncheckedText="No"
            value={row.enableAutomaticBatching}
            onChange={(checked) => onCenterAutomatedBatchingToggle(row.centerId, checked)}
            disabled={!canEditAutomatedBatching}
          />
        ),
      },
    ];
    return dataTableColumns;
  }, [canEditAutomatedBatching, getState, onCenterAutomatedBatchingToggle, t]);

  return (
    <DataTable
      data={data}
      dataSize={data.length ?? 0}
      showSelect={false}
      showPagination={false}
      showLoadingOverlay={isLoading}
      noPadding={noPadding}
      onSort={(field, direction) => onSortColumn(field, direction === 'ASCENDING' ? 'asc' : 'desc')}
      columns={getColumnsForDataTable()}
    />
  );
};

export default CentersWithAutomatedBatchesTable;
