import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from 'shared/components/TextInput';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { getFullName, getInitials, isBlank } from 'shared/util/string';
import { PaymentMethodType, PayrixService } from 'shared/services/payrixService';
import Checkbox from 'shared/components/Checkbox';
import { showToast } from 'shared/components/Toast';
import { createPaymentMethodForAccountSuccess } from 'pages/Families/subroutes/Accounts/duck/actions';
import { TokenizerService } from 'shared/services/tokenizerService';
import getLastFourOfPaymentNumber from 'shared/util/getLastFourOfPaymentNumber';
import { useTranslation } from 'react-i18next';
import { useCreateAuthorizedPersonPaymentMethod } from 'gql/paymentMethod/authorizedPerson/mutation';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';

const AU_ROUTING_NUMBER_REGEX = /^\d{6}$/;

interface IFormShape {
  name: string;
  routingNumber: string;
  confirmRoutingNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  accountType: PaymentMethodType.CHECKING_ACCOUNT | PaymentMethodType.SAVINGS_ACCOUNT;
}

interface IProps {
  isOpen: boolean;
  account: IAccount;
  contact?: IContact;
  onClose: () => void;
}

const AddAUBankAccountModal: React.FC<IProps> = ({ isOpen, account, contact, onClose }) => {
  const { t } = useTranslation(['billing', 'translation']);
  const dispatch = useDispatch();
  // TODO: Fix this so that it is typed appropriately
  // @ts-ignore
  const jwtToken = useSelector((state) => state.session.token);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [formData, setFormData] = useState<IFormShape>({
    name: '',
    routingNumber: '',
    accountNumber: '',
    confirmRoutingNumber: '',
    confirmAccountNumber: '',
    accountType: PaymentMethodType.CHECKING_ACCOUNT,
  });
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [isAutoPay, setIsAutoPay] = useState<boolean>(false);
  const [termsAndConditionsConfirmed, setTermsAndConditionsConfirmed] = useState<boolean>(false);

  const [createAuthorizedPersonPaymentMethod] = useCreateAuthorizedPersonPaymentMethod({
    onCompleted: (result) => {
      const paymentMethod = result.createAuthorizedPersonPaymentMethod;
      dispatch(
        createPaymentMethodForAccountSuccess({
          ...paymentMethod,
          accountId: account?.id,
        })
      );
      showToast(t('billing:payment-methods.add-bank-account-success'), 'success');
      handleDismiss();
    },
  });

  useEffect(() => {
    if (contact) {
      setFormData({ ...formData, name: getFullName(contact) });
    }
  }, [contact]);

  const validateForm = useCallback(() => {
    const validRoutingNumber = !isBlank(formData.routingNumber) && AU_ROUTING_NUMBER_REGEX.test(formData.routingNumber);
    const validAccountNumber = !isBlank(formData.accountNumber);

    return !isBlank(formData.name) && validRoutingNumber && validAccountNumber;
  }, [formData]);

  const tokenizeInput = useCallback(
    async (input: IFormShape): Promise<IPayrixToken | IFatZebraAch | null> => {
      try {
        const name = input.name.split(' ');
        const firstName = name.shift();
        const lastName = name.join(' '); // Bank complains if we miss the all the name e.g. Ulrich von Liechtenstein

        const unencryptedTokenizerRequestBody: ITokenizerRequestBody = {
          merchantId: null,
          centerId: account.centerId,
          secretKeyId: account.entityId,
          paymentNumber: input.accountNumber,
          routing: input.routingNumber.slice(0, 3) + '-' + input.routingNumber.slice(3),
          first: firstName ?? '',
          last: lastName ?? '',
          isSandbox: 'false',
        };

        const tokenizerService = new TokenizerService();
        return await tokenizerService.encryptAndSendTokenizeRequest<IPayrixToken | IFatZebraAch>(
          unencryptedTokenizerRequestBody,
          jwtToken
        );
      } catch (e) {
        showToast(t('translation:general.error'), 'error');
      }
      return null;
    },
    [account.centerId, account.entityId, jwtToken, t]
  );

  const handleSubmit = useCallback(async () => {
    setSubmittingForm(true);
    const token = await tokenizeInput(formData);

    if (token) {
      const lastFour: string =
        'payment' in token ? token.payment.number : getLastFourOfPaymentNumber(token.account_number);
      const responseToken: string = 'account_number' in token ? token.id : token.token;
      const payrixService = new PayrixService();

      const input = {
        businessId: account.entityId,
        lastFour,
        processorId: token.id,
        token: responseToken,
        // TODO: type this with a union or enum, instead of any
        // also fix so this doesn't need to fire up payrixService to grab this value
        type: payrixService.enumValueToString(formData.accountType),
        accountId: account.id,
        routingNumber: parseInt(formData.routingNumber, 10),
        accountName: formData.name,
        isPrimary: isDefault,
        isAutoPay,
        termsAndConditionsConfirmed,
      };
      if (token) {
        createAuthorizedPersonPaymentMethod({
          variables: {
            input: {
              ...input,
              personId: contact?.id,
              centerId: account?.centerId,
            },
          },
        });
      }

      setSubmittingForm(false);
    }

    setSubmittingForm(false);
  }, [
    tokenizeInput,
    formData,
    account.entityId,
    account.id,
    account?.centerId,
    isDefault,
    isAutoPay,
    termsAndConditionsConfirmed,
    createAuthorizedPersonPaymentMethod,
    contact?.id,
  ]);

  const handleDismiss = useCallback(() => {
    setFormData({
      name: '',
      routingNumber: '',
      accountNumber: '',
      confirmRoutingNumber: '',
      confirmAccountNumber: '',
      accountType: PaymentMethodType.CHECKING_ACCOUNT,
    });
    setIsDefault(false);
    setIsAutoPay(false);
    setTermsAndConditionsConfirmed(false);
    onClose();
  }, [onClose]);

  return (
    <SideModalDrawer
      show={isOpen}
      title="Add a banking account"
      primaryChoice="Save"
      primaryCallback={() => handleSubmit()}
      primaryButtonProps={{
        disabled: !validateForm(),
        loading: submittingForm,
      }}
      closeOnPrimaryCallback={false}
      onHide={handleDismiss}
      secondaryCallback={handleDismiss}
    >
      <div className="d-flex flex-column">
        {contact ? (
          <div className="mb-4">
            <label>Bank Account holder</label>
            <AvatarDataTableCell
              initials={getInitials(contact)}
              avatar={contact?.avatar?.url}
              primaryText={getFullName(contact)}
            />
          </div>
        ) : (
          <TextInput
            required
            label="Account Name"
            value={formData.name}
            onChange={(value) => setFormData((prev) => ({ ...prev, name: value }))}
            name="kt-confirm-routing-number"
            type="text"
            // @ts-ignore
            inputMode="text"
            placeholder="Bank Account Name"
          />
        )}
        <TextInput
          required
          label="BSB"
          value={formData.routingNumber}
          onChange={(value) => setFormData((prev) => ({ ...prev, routingNumber: value.substr(0, 6) }))}
          name="kt-routing-number"
          type="number"
          // @ts-ignore
          inputMode="numeric"
          placeholder="6 Digit BSB Number"
          errorText="BSB number must be 6 digits"
          isInvalid={!AU_ROUTING_NUMBER_REGEX.test(formData.routingNumber)}
        />
        <div className="mb-4">
          <TextInput
            required
            label="Account Number"
            value={formData.accountNumber}
            onChange={(value) => setFormData((prev) => ({ ...prev, accountNumber: value.substr(0, 9) }))}
            name="kt-account-number"
            className="mb-0"
            type="number"
            // @ts-ignore
            inputMode="numeric"
            placeholder="Account number"
          />
        </div>
        <Checkbox
          value={isDefault}
          onChange={setIsDefault}
          label={t('billing:payment-methods.mark-as-default-checkbox-label')}
          className="mb-4"
        />
        <Checkbox
          value={isAutoPay}
          onChange={setIsAutoPay}
          label={t('billing:payment-methods.auto-pay-checkbox-label')}
          className="mb-4"
        />
      </div>
    </SideModalDrawer>
  );
};

export default AddAUBankAccountModal;
