import React, { useState } from 'react';
import moment from 'moment';
import Collapse from 'react-bootstrap/Collapse';
import { Col, Row } from 'shared/components/Layout';
import { ButtonAsLink } from 'shared/components/Buttons';
import { PersonAvatar } from 'shared/components/Avatar';
import { getFullName } from 'shared/util/string';
import { isRegion } from 'shared/util/region';

interface IProps {
  session: ISession;
  timezone: string;
}

const TimeEntriesTableCell: React.FC<IProps> = ({ session, timezone }) => {
  const isAURegion = isRegion('AU');
  const [showAdditionalEntries, setShowAdditionalEntries] = useState(false);
  const numberOfTimeEntries = session.timeEntries.length;
  const firstTimeEntry = session.timeEntries[0];
  const lastTimeEntry = session.timeEntries[numberOfTimeEntries - 1];

  const getAbsenceLabel = () => {
    if (isAURegion)
      switch (session.absence?.type) {
        case 'ADDITIONAL':
          return 'ADDITIONAL ABSENCE';
        case 'HOLIDAY':
          return 'HOLIDAY';
        default:
          return 'ABSENT';
      }
    return 'ABSENT';
  };

  return (
    <div>
      <Row className="flex-nowrap" align="start">
        <Col>
          <small className="mb-1">{session.dropOffTime && moment(session.dropOffTime, 'HH:mm').format('h:mm A')}</small>
        </Col>
        <Col>
          <small className="mb-1">{session.pickUpTime && moment(session.pickUpTime, 'HH:mm').format('h:mm A')}</small>
        </Col>
      </Row>
      {session.absence && (
        <Row>
          <Col>
            <div>{getAbsenceLabel()}</div>
          </Col>
        </Row>
      )}
      {numberOfTimeEntries > 0 && (
        <Row className="flex-nowrap">
          <Col>
            <div>{moment(firstTimeEntry.timeIn).tz(timezone).format('h:mm A')}</div>
          </Col>
          <Col>
            <div>{lastTimeEntry.timeOut && moment(lastTimeEntry.timeOut).tz(timezone).format('h:mm A')}</div>
          </Col>
        </Row>
      )}
      {numberOfTimeEntries > 0 && (
        <div className="d-flex">
          <ButtonAsLink onClick={() => setShowAdditionalEntries(!showAdditionalEntries)}>
            show {showAdditionalEntries ? 'less' : 'more'}
          </ButtonAsLink>
        </div>
      )}
      <Collapse in={numberOfTimeEntries > 0 && showAdditionalEntries}>
        <div>
          <div className="border-top pt-2">
            {session.timeEntries.map((te) => (
              <div key={`time-entry-${te.id}`}>
                <Row className="flex-nowrap mb-2">
                  <Col>
                    <span>
                      <small className="mr-2">IN:</small>
                      <span className="sm">{moment(te.timeIn).tz(timezone).format('h:mm A')}</span>
                    </span>
                  </Col>
                  <Col className="text-truncate">
                    <PersonAvatar size="xs" person={te.timeInBy} className="mr-2" />
                    <small>{getFullName(te.timeInBy)}</small>
                  </Col>
                </Row>
                {te.timeOut && (
                  <Row className="flex-nowrap mb-2">
                    <Col>
                      <span>
                        <small className="mr-2">OUT:</small>
                        <span className="sm">{moment(te.timeOut).tz(timezone).format('h:mm A')}</span>
                      </span>
                    </Col>
                    <Col className="text-truncate">
                      {te.timeOutBy && <PersonAvatar size="xs" person={te.timeOutBy} className="mr-2" />}
                      <small>{`${getFullName(te.timeOutBy)}`}</small>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default TimeEntriesTableCell;
