import { AccountStatusType, EnrolmentCategory, useGetCcsEnrolmentsReportLazyQuery } from 'generated/graphql';
import moment from 'moment';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shared/components/Layout';
import ModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { showToast } from 'shared/components/Toast';
import { ccsEnrollmentStatus, ccsTypeEnum2 } from 'shared/constants/enums/ccssEnums';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';

interface IProps {
  businessId: string;
  isOpen: boolean;
  defaultFormValues: {
    centerIds: string[];
  };
  onClose: () => void;
}

const CcsEnrolmentsReportModal: React.FC<IProps> = ({ isOpen, defaultFormValues, onClose, businessId, ...props }) => {
  const reportDataToFile = useReportDataToFile();
  const { t } = useTranslation(['translation', 'timelog', 'ccss', 'reporting', 'enrollment']);
  const [centerIds, setCenterIds] = useState<string[] | null>(defaultFormValues.centerIds);
  const [accountStatuses, setAccountStatuses] = useState<AccountStatusType[]>([AccountStatusType.Active]);
  const [ccsTypes, setCcsTypes] = useState<string[]>(ccsTypeEnum2.map((c) => c.value));
  const [enrolmentStatuses, setEnrolmentStatuses] = useState<CcsEnrollmentStatus[]>(
    Object.values(ccsEnrollmentStatus).map((e) => e.value)
  );

  const accountStatusOptions: ITableFilterOption[] = useMemo(() => {
    return Object.values(AccountStatusType)
      .filter((accountStatus) => AccountStatusType.Future !== accountStatus)
      .map((accountStatus) => {
        return { label: accountStatus, value: accountStatus };
      });
  }, []);

  const enrolmentStatusOptions: ITableFilterOption[] = useMemo(() => {
    return Object.values(ccsEnrollmentStatus);
  }, []);

  const [getCcsEnrolmentsReportFn, { loading }] = useGetCcsEnrolmentsReportLazyQuery({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(result.getCcsEnrolmentsReport, ReportTypeEnum.CCS_ENROLMENTS);
      handleClose();
    },
    onError: (error) => {
      showToast('Unable to get CCS Enrolments Report', 'error');
    },
  });

  const handleSelectAccountStatus = useCallback((options: ITableFilterOption[]) => {
    setAccountStatuses(options.map((o) => o.value as AccountStatusType));
  }, []);

  const handleSelectCcsTypes = useCallback((options) => {
    setCcsTypes(options.map((o) => o.value));
  }, []);

  const handleSelecEnrolmentStatus = useCallback((options) => {
    setEnrolmentStatuses(options.map((o) => o.value));
  }, []);

  const handleClose = useCallback(() => {
    setCenterIds([]);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    getCcsEnrolmentsReportFn({
      variables: {
        businessId: businessId,
        input: {
          centerIds: centerIds,
          accountStatusAtDate: moment().format('YYYY-MM-DD').toString(),
          accountStatusTypes: accountStatuses.length === 1 ? accountStatuses[0] : undefined,
          ccsTypes: ccsTypes.length > 0 ? ccsTypes : undefined,
          enrolmentStatuses: enrolmentStatuses.length > 0 ? enrolmentStatuses : undefined,
        },
      },
    });
  }, [accountStatuses, businessId, ccsTypes, centerIds, enrolmentStatuses, getCcsEnrolmentsReportFn]);

  useEffect(() => {
    setCenterIds(defaultFormValues.centerIds);
  }, [defaultFormValues]);

  return (
    <ModalDrawer
      title={t('ccss:session-entitlement-report')}
      show={isOpen}
      onHide={handleClose}
      primaryButtonProps={{ disabled: false, loading }}
      primaryChoice="Run Report"
      primaryCallback={handleSubmit}
      closeOnPrimaryCallback={false}
      secondaryCallback={handleClose}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            selectedCenterIds={centerIds}
            onSelect={(centerIds) => setCenterIds(centerIds)}
            useNullForAllOption={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            label="Account Status"
            className="flex-wrap"
            options={accountStatusOptions}
            onChange={handleSelectAccountStatus}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            value={accountStatusOptions.filter((status) => accountStatuses.includes(status.value as AccountStatusType))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            label="Enrolment Type"
            className="flex-wrap"
            options={ccsTypeEnum2}
            onChange={handleSelectCcsTypes}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            value={ccsTypeEnum2.filter((ccsType) => ccsTypes.includes(ccsType.value))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            label="Enrolment Status"
            className="flex-wrap"
            options={enrolmentStatusOptions}
            onChange={handleSelecEnrolmentStatus}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            value={enrolmentStatusOptions.filter((status) =>
              enrolmentStatuses.includes(status.value as CcsEnrollmentStatus)
            )}
          />
        </Col>
      </Row>
    </ModalDrawer>
  );
};

export default CcsEnrolmentsReportModal;
