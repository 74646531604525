import React, { useCallback, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import RegulationInputs from './RegulationInputs';
import { useUpdateRegulation } from './graphql/mutations';
import { showToast } from 'shared/components/Toast';
import cast from 'shared/util/cast';
import { LoadingLines } from 'shared/components/LoadingSkeletons';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  regulation: IRegulation;
  validateRegulation: (reg: any) => boolean;
}

const EditRegulation: React.FC<IProps> = ({ regulation, isOpen, onClose, validateRegulation, ...props }) => {
  const [regulationUpdates, setRegulationUpdates] = useState<IRegulation>(regulation);
  const [updateRegulation, { loading }] = useUpdateRegulation();

  const handleUpdateRegulation = useCallback(
    (reg: IRegulation) => {
      // using ts-ignore to ensure the properties are typed correctly for the backend
      updateRegulation({
        variables: {
          input: {
            id: reg.id,
            state: reg.state,
            county: reg.county ?? '',
            startAge: {
              // @ts-ignore
              age: parseFloat(reg.startAge.age),
              unit: cast<RegulationAgeUnit>(reg.startAge.unit),
            },
            endAge: {
              // @ts-ignore
              age: parseFloat(reg.endAge.age),
              unit: cast<RegulationAgeUnit>(reg.endAge.unit),
            },
            // @ts-ignore
            capacity: parseInt(reg.capacity, 10),
            // @ts-ignore
            ratioTeachers: parseInt(reg.ratioTeachers, 10),
            // @ts-ignore
            ratioChildren: parseInt(reg.ratioChildren, 10),
          },
        },
      }).then(() => {
        showToast('Regulation updated successfully.', 'success');
        onClose();
      });
    },
    [updateRegulation, onClose]
  );

  if (!isOpen || !regulationUpdates) {
    return null;
  }

  return (
    <SideModalDrawer
      title="Edit Regulation"
      show={isOpen}
      onHide={onClose}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={() => handleUpdateRegulation(regulationUpdates)}
      primaryButtonProps={{
        disabled: !validateRegulation(regulationUpdates),
      }}
    >
      {loading ? (
        <LoadingLines />
      ) : (
        <RegulationInputs regulation={regulationUpdates} updateRegulation={setRegulationUpdates} />
      )}
    </SideModalDrawer>
  );
};

export default EditRegulation;
