import React from 'react';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import Select from 'shared/components/Select';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetProvidersByBusinessId } from 'gql/business/queries';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import NotifiableEventsHistoryTable from '../NotifiableEventsHistoryTable/NotifiableEventsHistoryTable';

type NotifiableEventsHistoryTableProps = typeof NotifiableEventsHistoryTable extends React.FC<infer T> ? T : never;
interface Props {
  title: string;
  subTitle?: string;
  notificationMessage?: string;
  child: any;
  onCancel: () => void;
  onSave: () => void;
  formIsDirty: boolean;
  toggleDirty: (isDirty: boolean) => void;
  saveDisabled: boolean;
  primaryChoice?: string;
  providerOnChange: (option: any) => void;
  selectedProvider: any;
  isSaving?: boolean;
  isLoading?: boolean;
  notifiableEventsHistoryTableProps?: NotifiableEventsHistoryTableProps;
}

const NotifiableEventsContainer: React.FC<Props> = ({
  title,
  child,
  subTitle,
  notificationMessage,
  onCancel,
  onSave,
  formIsDirty,
  toggleDirty,
  saveDisabled,
  primaryChoice = 'Update',
  providerOnChange,
  selectedProvider,
  isLoading = false,
  isSaving = false,
  notifiableEventsHistoryTableProps = {},
}) => {
  const providers = useSelector((state: RootState) => state.provider.providers) ?? [];
  const providerOptions = providers.map((p) => {
    return { value: p.id, label: p.name };
  });

  return (
    <SpinnerOverlay show={isLoading}>
      <Card header={title}>
        {subTitle && <div className="mb-6">{subTitle}</div>}
        {notificationMessage && (
          <div className="ccs-account-management blue-info-block p-4 d-flex flex-row mb-6">
            <div className="mr-4">
              <FontAwesomeIcon icon={faInfoCircle} size="2x" />
            </div>
            <div className="mr-6 d-flex align-items-center">{notificationMessage}</div>
          </div>
        )}
        <FormWrapper2
          onCancel={onCancel}
          onSave={onSave}
          loading={isSaving}
          formIsDirty={formIsDirty}
          toggleDirty={toggleDirty}
          saveDisabled={saveDisabled}
          primaryChoice={primaryChoice}
        >
          <div>
            <Row>
              <Col className="mb-2">
                <Row>
                  <Col>
                    <Select
                      inputId="provider"
                      label={'Provider'}
                      options={providerOptions}
                      value={selectedProvider}
                      onChange={providerOnChange}
                    />
                  </Col>
                  <Col>{''}</Col>
                </Row>
              </Col>
              <Col>{''}</Col>
            </Row>
            {child}
          </div>
        </FormWrapper2>
        {/** @todo Add this when backend supports history */
        /*
            <Card header='Update History' loading={false} loadingLines={2} bodyClassName="notifiable-events">
              <NotifiableEventsHistoryTable {...notifiableEventsHistoryTableProps} />
            </Card>
        */}
      </Card>
    </SpinnerOverlay>
  );
};

export default NotifiableEventsContainer;
