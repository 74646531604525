import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import { camelCaseToSpaced } from 'shared/util/string';
import { useGetHealthTerms } from 'gql/healthTerm/queries';
import { orderBy } from 'lodash';
import { MedicalSymptomType } from 'generated/graphql';

interface IProps {
  medicalCondition: IMedicalConditionInput;
  updateMedicalCondition: (medicalCondition: IMedicalConditionInput) => void;
  readOnly: boolean;
  readOnlyNameAndType?: boolean;
}

const MedicalConditionInput: React.FC<IProps> = ({
  medicalCondition,
  updateMedicalCondition,
  readOnly,
  readOnlyNameAndType,
}) => {
  const { data, loading } = useGetHealthTerms();
  const medicalConditionTypes = orderBy(
    data?.getHealthTerms
      .filter((ht) => ht.type === 'Medical' && !ht.archivedAt)
      .map((ht) => ({ label: ht.name, value: ht.id })) ?? [],
    'label'
  );

  const selectedMedicalCondition = medicalConditionTypes.find((mct) => mct.value === medicalCondition.typeId);

  const handleChange = useCallback(
    (value, name) => {
      updateMedicalCondition({ ...medicalCondition, [name]: value });
    },
    [medicalCondition, updateMedicalCondition]
  );

  useEffect(() => {
    if (selectedMedicalCondition?.label !== 'Other') {
      updateMedicalCondition({
        ...medicalCondition,
        otherMedicalConditionType: undefined,
      });
    }
  }, [selectedMedicalCondition?.label]);

  return (
    <div>
      <Row align="end">
        <Col md>
          <Select
            name="typeId"
            label="Type"
            isLoading={loading}
            options={medicalConditionTypes}
            value={medicalCondition.typeId}
            onChange={(o) => handleChange(o.value, 'typeId')}
            required={true}
            disabled={readOnly || readOnlyNameAndType}
          />
        </Col>
        {selectedMedicalCondition?.label === 'Other' && (
          <Col md>
            <TextInput
              placeholder="Specify"
              name="otherMedicalConditionType"
              value={medicalCondition.otherMedicalConditionType}
              onChange={handleChange}
              disabled={readOnly || readOnlyNameAndType}
            />
          </Col>
        )}
      </Row>
      <TextInput
        label="Name"
        name="medical-condition"
        value={medicalCondition.name}
        onChange={(value: string) => handleChange(value, 'name')}
        required={true}
        disabled={readOnly || readOnlyNameAndType}
      />
      <Select
        name="risk"
        label="Risk"
        options={['None', 'Low', 'Moderate', 'High']}
        value={medicalCondition.risk}
        onChange={handleChange}
        required={true}
        disabled={readOnly}
      />
      <Select
        isMulti={true}
        label="Symptoms"
        options={Object.values(MedicalSymptomType).map((s) => ({
          value: s,
          label: camelCaseToSpaced(s),
        }))}
        value={
          medicalCondition.symptoms
            ? medicalCondition.symptoms.map((s) => ({
                value: s,
                label: camelCaseToSpaced(s),
              }))
            : []
        }
        onChange={(options) => handleChange(options ? options.map((o: any) => o.value) : options, 'symptoms')}
        required={medicalCondition.risk !== 'None' && medicalCondition.risk !== 'Low'}
        disabled={readOnly}
        className="flex-wrap"
      />
      <TextInput
        label="Care Instructions"
        name="instructions"
        value={medicalCondition.instructions || ''}
        onChange={handleChange}
        required={medicalCondition.risk !== 'None' && medicalCondition.risk !== 'Low'}
        as="textarea"
        rows={3}
        disabled={readOnly}
      />
    </div>
  );
};

export default MedicalConditionInput;
