import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DateInput from 'shared/components/DateInput';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Row, Col } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { concessionTypeEnum } from 'shared/constants/enums/programEnum';
import { getFullName } from 'shared/util/string';

interface IProps {
  contacts?: IContact[];
  accountChildren: IAccountChild[];
  formData: IConcessionForm;
  isPrePopulated: boolean;
  handleChange: (value: any, name: any) => void;
}

const ConcessionCardForm: React.FC<IProps> = ({
  contacts,
  accountChildren,
  formData,
  isPrePopulated,
  handleChange,
}) => {
  const { t } = useTranslation(['subsidies']);
  const handleFileChange = (files: File[]) => {
    handleChange(files[files.length - 1], 'file');
  };

  const hasAnyFormValues = Object.entries(formData).some((entry) => entry[0] !== 'children' && !isEmpty(entry[1]));

  return (
    <div>
      <Row>
        <Col>
          <Select
            required={hasAnyFormValues}
            label={t('subsidies:program-child.create-modal.concession-contact-label')}
            options={contacts || []}
            name="contact"
            getOptionLabel={(contact: IContact) => getFullName(contact)}
            getOptionValue={(contact: IContact) => contact.id}
            onChange={handleChange}
            value={formData.contact}
            disabled={isPrePopulated}
          />
        </Col>
        <Col>
          <Select
            required={hasAnyFormValues}
            label={t('subsidies:program-child.create-modal.concession-type-label')}
            options={concessionTypeEnum}
            value={formData.type}
            onChange={(option) => {
              handleChange(option.value, 'type');
            }}
            disabled={isPrePopulated}
          />
        </Col>
        <Col>
          <TextInput
            required={hasAnyFormValues}
            label={t('subsidies:program-child.create-modal.concession-number-label')}
            value={formData.number}
            onChange={handleChange}
            name="number"
            disabled={isPrePopulated}
          />
        </Col>
      </Row>
      {formData.linkToFile && (
        <Row className="mb-2">
          <Col>
            <a href={formData.linkToFile}>Current Document: {formData.fileName}</a>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FileSelectDropbox
            multiple={false}
            className="mb-2"
            value={formData.file ? [formData.file] : []}
            onChange={handleFileChange}
            showFiles={true}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <DateInput
            required={hasAnyFormValues}
            label={t('subsidies:program-child.create-modal.concession-effective-date-label')}
            className="max-width-200"
            date={formData.effectiveDate}
            onDateSelect={(value) => {
              handleChange(value, 'effectiveDate');
            }}
          />
        </Col>
        <Col className="mb-4">
          <DateInput
            required={hasAnyFormValues}
            label={t('subsidies:program-child.create-modal.concession-expiry-label')}
            className="max-width-200"
            date={formData.expiryDate}
            onDateSelect={(value) => {
              handleChange(value, 'expiryDate');
            }}
          />
        </Col>
        <Col>
          <Select
            required={hasAnyFormValues}
            options={accountChildren}
            getOptionLabel={(child: IAccountChild) => getFullName(child)}
            getOptionValue={(child: IAccountChild) => child.id}
            label={t('subsidies:program-child.create-modal.concession-applies-to')}
            name="children"
            value={formData.children}
            onChange={handleChange}
            isMulti
          />
        </Col>
      </Row>
    </div>
  );
};

export default ConcessionCardForm;
