import { PREDICATES, SEARCH_EXPRESSIONS } from './elastic';

export const feeStatusFilterOptions: ITableFilterOption[] = [
  {
    label: 'Active',
    value: 'active',
    searchExpression: {
      [SEARCH_EXPRESSIONS.TERM]: {
        field: 'deactivatedAt',
        predicate: PREDICATES.DOES_NOT_EXIST,
        value: '',
      },
    },
  },
  {
    label: 'Deactivated',
    value: 'deactivated',
    searchExpression: {
      [SEARCH_EXPRESSIONS.TERM]: {
        field: 'deactivatedAt',
        predicate: PREDICATES.EXISTS,
        value: '',
      },
    },
  },
];
