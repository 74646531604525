import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import Select from 'shared/components/Select';
import { Row, Col } from 'shared/components/Layout';
import { DateInputWithTimezone, DateRangeInputWithTimezone } from 'shared/components/DateInput';
import { Form } from 'react-bootstrap';
import TextInput from 'shared/components/TextInput';
import moment from 'moment';
import { getTotalHours } from '../../utils';
import TimeRangePicker2 from 'shared/components/TimePicker/TimeRangePicker2';
import { isValid24HourString } from 'shared/util/timeUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {
  timeOff: ITimeOffInput | ITimeOff;
  updateTimeOff: React.Dispatch<React.SetStateAction<ITimeOffInput>> | React.Dispatch<React.SetStateAction<ITimeOff>>;
  isRequesting?: boolean;
  timezone: Timezone;
  ptoTypeOptions: IPayCodeType[];
}

const TimeOffInputs: React.FC<IProps> = ({
  timeOff,
  timezone,
  updateTimeOff,
  isRequesting = false,
  ptoTypeOptions,
  ...props
}) => {
  const { k2WebPtoTypes, k2TimeoffRequestType } = useFlags();

  const [formattedTimes, setFormattedTimes] = useState<{ start: string; end: string }>({
    start: timeOff.startTime ? moment(timeOff.startTime).tz(timezone).format('HH:mm') : '',
    end: timeOff.endTime ? moment(timeOff.endTime).tz(timezone).format('HH:mm') : '',
  });

  const handleChange = useCallback(
    (val, name) => {
      updateTimeOff((prev: any) => ({ ...prev, [name]: val }));
    },
    [updateTimeOff]
  );

  const [duration, setDuration] = useState(
    !timeOff.allDay ? 'Partial Day' : getTotalHours(timeOff) > 8 ? 'Multiple Days' : 'All Day'
  );

  const isUnderTwoWeeksNotice = isRequesting && moment(timeOff.startTime).subtract(2, 'weeks').isBefore(moment());

  const isMounted = useRef(false);

  useEffect(() => {
    handleChange(duration !== 'Partial Day', 'allDay');
  }, [duration, handleChange]);

  useEffect(() => {
    if (isMounted.current) {
      const hoursApproved = getTotalHours(timeOff);

      handleChange(hoursApproved, 'hoursApproved');
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange, timeOff.startTime, timeOff.endTime, timeOff.allDay]);

  return (
    <div>
      <Row align="start">
        <Col>
          <Select
            required
            label="Duration"
            options={['All Day', 'Partial Day', 'Multiple Days']}
            value={duration}
            onChange={(val) => {
              setDuration(val);

              if (val === 'All Day') {
                updateTimeOff((prev: ITimeOff) => ({
                  ...prev,
                  endTime: prev.startTime,
                }));
              }
            }}
          />
        </Col>
        <Col>
          {duration === 'Multiple Days' ? (
            <DateRangeInputWithTimezone
              required
              label="Date"
              startDate={timeOff.startTime}
              endDate={timeOff.endTime}
              onChange={(dates: any) => {
                updateTimeOff((prev: any) => ({ ...prev, startTime: dates.startDate, endTime: dates.endDate }));
              }}
              timezone={timezone}
            />
          ) : (
            <DateInputWithTimezone
              required
              label="Date"
              date={timeOff.startTime}
              onDateSelect={(val) => {
                updateTimeOff((prev: any) => ({ ...prev, startTime: val, endTime: val }));
              }}
              timezone={timezone}
            />
          )}
          {isUnderTwoWeeksNotice && (
            <div className="line-height-1">
              <small className="text-danger">Submission is under 2 weeks notice.</small>
            </div>
          )}
        </Col>
      </Row>
      {duration === 'Partial Day' && (
        <div className="mb-4">
          <div className="d-flex flex-row">
            <Form.Label>Hours</Form.Label>
            <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
          </div>
          <TimeRangePicker2
            start={formattedTimes.start ?? null}
            end={formattedTimes.end ?? null}
            onChange={(start: string | null, end: string | null) => {
              const [startHours, startMinutes] = (start ?? '').split(':');
              const [endHours, endMinutes] = (end ?? '').split(':');

              setFormattedTimes({ start: start ?? '', end: end ?? '' });
              updateTimeOff((prev: ITimeOff) => ({
                ...prev,
                startTime: isValid24HourString(start ?? '')
                  ? moment(prev.startTime || undefined)
                      .tz(timezone)
                      .set({ h: parseInt(startHours, 10), m: parseInt(startMinutes, 10) })
                      .format()
                  : prev.startTime,
                endTime: isValid24HourString(end ?? '')
                  ? moment(prev.endTime || undefined)
                      .tz(timezone)
                      .set({ h: parseInt(endHours, 10), m: parseInt(endMinutes, 10) })
                      .format()
                  : prev.endTime,
              }));
            }}
          />
        </div>
      )}
      <TextInput
        required
        label="Description"
        name="description"
        value={timeOff.description || ''}
        placeholder="Please add a short reason why..."
        onChange={handleChange}
        as="textarea"
        rows={3}
      />
      {k2WebPtoTypes && !isRequesting && (
        <Row>
          <Col>
            <Select
              label="Request Type"
              options={ptoTypeOptions}
              getOptionLabel={(opt: IPayCodeType) => `${opt.code} - ${opt.name}`}
              getOptionValue={(opt: IPayCodeType) => opt.id}
              value={ptoTypeOptions.find((opt) => opt.id === timeOff.ptoType)}
              onChange={(opt: IPayCodeType) => updateTimeOff((prev: any) => ({ ...prev, ptoType: opt.id }))}
            />
          </Col>
        </Row>
      )}
      {!isRequesting && (
        <Row>
          <Col>
            <TextInput
              small
              required
              label="Paid Hours"
              name="hoursApproved"
              value={timeOff.hoursApproved?.toString()}
              onChange={(val) => handleChange(val ? parseFloat(val) : undefined, 'hoursApproved')}
            />
          </Col>
          <Col>
            <TextInput
              small
              label="Unpaid Hours"
              disabled={true}
              value={
                timeOff.hoursApproved !== undefined
                  ? (getTotalHours(timeOff) - (timeOff.hoursApproved || 0)).toString()
                  : undefined
              }
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TimeOffInputs;
