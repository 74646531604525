import React, { useCallback } from 'react';
import TextInput, { RatioInput } from 'shared/components/TextInput';
import Form from 'react-bootstrap/Form';
import Select from 'shared/components/Select';
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { Row, Col } from 'shared/components/Layout';
import RegulationAgeInput from 'shared/components/TextInput/RegulationAgeInput';
import config from 'config';

interface IProps {
  regulation: any;
  updateRegulation:
    | React.Dispatch<React.SetStateAction<IRegulation>>
    | React.Dispatch<React.SetStateAction<INewRegulationInput>>;
}

const RegulationInputs: React.FC<IProps> = ({ regulation, updateRegulation, ...props }) => {
  const handleChange = useCallback(
    (value: string, name: string) => {
      updateRegulation((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    },
    [updateRegulation]
  );

  const handleAgeInput = useCallback(
    (value: string, ageUnit: 'startAge' | 'endAge') => {
      updateRegulation((prev: any) => ({
        ...prev,
        [ageUnit]: {
          ...prev[ageUnit],
          age: parseFloat(value),
        },
      }));
    },
    [updateRegulation]
  );

  const handleAgeUnitSelect = useCallback(
    (value: string, ageUnit: 'startAge' | 'endAge') => {
      updateRegulation((prev: any) => ({
        ...prev,
        [ageUnit]: {
          ...prev[ageUnit],
          unit: value,
        },
      }));
    },
    [updateRegulation]
  );

  const handleRatioChanged = useCallback(
    (value: string) => {
      const ratio = value.split(':');
      updateRegulation((prev: any) => ({
        ...prev,
        ratioTeachers: ratio[0],
        ratioChildren: ratio[1],
      }));
    },
    [updateRegulation]
  );

  return (
    <form>
      <Row>
        <Col>
          <Select
            id="regulation-state-input"
            label="State"
            placeholder="State"
            name="state"
            options={STATE_SELECT_OPTIONS[config.locale.region]}
            value={
              STATE_SELECT_OPTIONS[config.locale.region].find((option) => option.value === regulation.state) || null
            }
            onChange={(option) => handleChange(option.value, 'state')}
            getOptionLabel={(option) => option.label}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            id="regulation-county-input"
            label="County (Optional)"
            name="county"
            value={regulation.county ?? ''}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RegulationAgeInput
            label="Start Age"
            idPrefix="regulation-start"
            value={regulation.startAge}
            onChange={(age) => handleAgeInput(age, 'startAge')}
            onAgeUnitChange={(unit) => handleAgeUnitSelect(unit, 'startAge')}
          />
        </Col>
        <Col>
          <RegulationAgeInput
            label="End Age"
            idPrefix="regulation-end"
            value={regulation.endAge}
            onChange={(age) => handleAgeInput(age, 'endAge')}
            onAgeUnitChange={(unit) => handleAgeUnitSelect(unit, 'endAge')}
          />
        </Col>
      </Row>
      <Row align="start">
        <Col>
          {/* Need the ratio input */}
          <RatioInput
            id="regulation-ratio-input"
            name="ratio"
            label="Teacher Student Ratio"
            value={
              regulation.ratioChildren || regulation.ratioTeachers
                ? `${regulation.ratioTeachers}:${regulation.ratioChildren}`
                : ''
            }
            onChange={(val) => handleRatioChanged(val)}
            maxLength={4}
            required={true}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Total Capacity</Form.Label>
            <Form.Control
              id="regulation-capacity-input"
              name="capacity"
              value={regulation.capacity}
              onChange={(event: any) => handleChange(event.target.value, event.target.name)}
              type="number"
              min={0}
            />
          </Form.Group>
        </Col>
      </Row>
    </form>
  );
};

export default RegulationInputs;
