import React from 'react';
import { IRowType } from '../History';
import { PaymentRowOverview } from './PaymentRowOverview';
import { BillRowOverview } from './BillRowOverview';
import { UnselectedOverview } from './UnselectedOverview';

interface IProps {
  selectedRow: IRowType | null;
}

const RowOverview: React.FC<IProps> = ({ selectedRow }) => {
  if (!selectedRow) return <UnselectedOverview></UnselectedOverview>;

  if (selectedRow.type === 'Bill') {
    return <BillRowOverview billId={selectedRow.id} />;
  } else if (
    selectedRow?.type == 'Payment' ||
    selectedRow?.type === 'ScheduledPayment' ||
    selectedRow?.type === 'Refund'
  ) {
    return <PaymentRowOverview paymentId={selectedRow.id} />;
  } else {
    return null;
  }
};

export default RowOverview;
