import React from 'react';
import RBAccordion from 'react-bootstrap/Accordion';

interface IProps {
  children: React.ReactNode | null;
  className?: string;
  defaultActiveKey?: string;
}

const Accordion: React.FC<IProps> = ({ children, className, defaultActiveKey, ...props }) => {
  // add the `eventKey` programatically to the children components
  return (
    <RBAccordion defaultActiveKey={defaultActiveKey} className={className} {...props}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            // @ts-ignore
            eventKey: `${index}`,
          });
        }
      })}
    </RBAccordion>
  );
};

export default Accordion;
