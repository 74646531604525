import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

type ComplyingWrittenArrangementStatus =
  | 'READY_TO_BE_SENT'
  | 'PENDING'
  | 'APPROVED'
  | 'REJECTED'
  | 'Cancelled'
  | 'Rejected'
  | 'Pending'
  | 'Approved'
  | 'CANCELLED'
  | 'Ready to be sent';
type sortArray = { field: string; direction: string }[];
type sortObject = { field: string; direction: string };
interface Session {}

interface IDeleteShareVariables {
  businessId: string;
  arrangementId: string;
}

interface IArrangementMetrics {
  businessId: string;
}

export interface GetComplyingWrittenArrangementPageInput {
  businessId: string;
  centerIds: string[] | null;
  pageNumber: number;
  pageSize: number;
  sortDtos: sortArray | sortObject;
  searchText: string;
  statuses: string[];
  accountStatuses: string[];
}

interface IEndComplyingWrittenArrangementPageInputVariables {
  input: GetComplyingWrittenArrangementPageInput;
}

export interface GetComplyingWrittenArrangementPage {
  getComplyingWrittenArrangementPage: {
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: {
      id: string;
      accountId: string;
      accountName: string;
      centerId: string;
      centerName: string;
      childId: string;
      childFirstName: string;
      childLastName: string;
      enrolmentId: string;
      status: ComplyingWrittenArrangementStatus;
      updatedAt: Date;
      updatedBy: string;
      updatedByPerson: {
        id: string;
        firstname: string;
        lastname: string;
      };
      sentDate: Date;
      guardianFirstName: string;
      guardianLastName: string;
    }[];
  };
}

interface GetArrangementHistory {
  getArrangementHistory: {
    status: ComplyingWrittenArrangementStatus;
    updatedBy: string;
    updatedAt: Date;
    updatedByPerson: {
      firstname: string;
      lastname: string;
    };
  };
}
interface GetArrangementEmailPreview {
  getArrangementEmailPreview: string;
}

interface GetArrangementMetrics {
  getArrangementMetrics: {
    approvedCount: number;
    rejectedCount: number;
    cancelledCount: number;
    pendingCount: number;
    readyToBeSentCount: number;
  };
}

export const getComplyingWrittenArrangementPage = gql`
  query ($input: GetComplyingWrittenArrangementPageInput!) {
    getComplyingWrittenArrangementPage(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        id
        accountId
        accountName
        centerId
        centerName
        childId
        childFirstName
        childLastName
        enrolmentId
        status
        updatedAt
        updatedBy
        updatedByPerson {
          id
          firstname
          lastname
        }
        sentDate
        guardianFirstName
        guardianLastName
      }
    }
  }
`;

const getArrangementHistory = gql`
  query ($businessId: ID!, $arrangementId: ID!) {
    getArrangementHistory(businessId: $businessId, arrangementId: $arrangementId) {
      status
      updatedBy
      updatedAt
      updatedByPerson {
        firstname
        lastname
      }
    }
  }
`;

const getArrangementMetrics = gql`
  query ($businessId: ID!) {
    getArrangementMetrics(businessId: $businessId) {
      approvedCount
      rejectedCount
      cancelledCount
      pendingCount
      readyToBeSentCount
    }
  }
`;

const getArrangementEmailPreview = gql`
  query ($businessId: ID!, $arrangementId: ID!) {
    getArrangementEmailPreview(businessId: $businessId, arrangementId: $arrangementId)
  }
`;

export const useGetComplyingWrittenArrangementPage = (input: IEndComplyingWrittenArrangementPageInputVariables) =>
  useQuery<GetComplyingWrittenArrangementPage, IEndComplyingWrittenArrangementPageInputVariables>(
    getComplyingWrittenArrangementPage,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        ...input,
      },
    }
  );

export const useGetArrangementHistory = (input: IDeleteShareVariables) =>
  useQuery<GetArrangementHistory, IDeleteShareVariables>(getArrangementHistory, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      ...input,
    },
  });

export const useGetArrangementMetrics = (input: IArrangementMetrics) =>
  useQuery<GetArrangementMetrics, IArrangementMetrics>(getArrangementMetrics, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      ...input,
    },
  });

export const useGetArrangementEmailPreview = (input: IDeleteShareVariables) =>
  useQuery<GetArrangementEmailPreview, IDeleteShareVariables>(getArrangementEmailPreview, {
    variables: {
      ...input,
    },
  });
