import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import { Col, Row } from 'shared/components/Layout';
import { capitalize } from 'lodash';
import { Form } from 'react-bootstrap';
import { orderBy } from 'lodash';
import { showToast } from 'shared/components/Toast';
import { useSearchCenters } from 'gql/center/queries';
import { SEARCH_MY_CENTERS } from 'pages/Centers/subroutes/Profiles/graphql/fields';
import { getCentersSuccess } from 'pages/Centers/duck/actions';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { useGetKindyForAllForecastReportLazy } from 'gql/reports/queries';
import { useTranslation } from 'react-i18next';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select/Select';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
import useDatatableState from 'shared/hooks/useDatatableState';
import { getYearOptionsFromYear, getTermOptions } from '../utils';

interface IFormStateShape {
  centerIds: string[];
  year: number;
  period: string | null;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  year: number;
  onClose: () => void;
}

const ForecastReportModal: React.FC<IProps> = ({ isOpen, isLoading, year, onClose, ...props }) => {
  const [formData, setFormData] = useState<IFormStateShape>({
    centerIds: [],
    year: year,
    period: null,
  });
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const initialTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('center', initialTableSort);

  // TODO: Don't query for all centers via ... datatable??
  // We have useGetActiveCentersWithLoading
  if (tableState.pageSize != 640) {
    tableFunctions.changeSizePerPage(640);
  }

  useSearchCenters(
    {
      variables: {
        input: tableFunctions.getElasticQuery(),
      },
      onCompleted: (data) => {
        dispatch(getCentersSuccess(data.searchCenters.data ?? []));
      },
    },
    SEARCH_MY_CENTERS
  );

  const selectedBusinessId = useSelector((state: RootState) => state.context.businessId);
  const centers = useSelector((state: RootState) => state.centers.all)?.filter((c) => c.address.state === 'AU-QLD');
  const centersForBusiness = React.useMemo(
    () => orderBy(centers?.filter((c) => c.entityId === selectedBusinessId) || [], (c) => c.name, 'asc'),
    [selectedBusinessId, centers]
  );

  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context)?.businessId ?? user?.entityId;
  const [selectedCenters, setSelectedCenters] = React.useState<ICenter[] | null>(null);
  const reportDataToFile = useReportDataToFile();

  const [kindyForAllForecastReportFn, { loading: kfaReportLoading }] = useGetKindyForAllForecastReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(result.getKindyForAllForecastReport, ReportTypeEnum.KINDY_FOR_ALL);
      onClose();
    },
    onError: () => {
      showToast(t('reports.general-failure-message'), 'error');
    },
  });

  const handleCentersChange = (centers) => {
    setSelectedCenters(centers);
    const centerIds = centers.map((c) => c.id);
    setFormData((state) => ({
      ...state,
      centerIds: centerIds,
    }));
  };

  const handleSubmit = useCallback(() => {
    if (formData.centerIds && formData.year && formData.period) {
      kindyForAllForecastReportFn({
        variables: {
          input: {
            businessId: businessId,
            centerIds: formData.centerIds,
            year: formData.year.toString(),
            period: formData.period,
          },
        },
      });
    }
  }, [businessId, formData, kindyForAllForecastReportFn]);

  const handleClose = useCallback(() => {
    setSelectedCenters(null);
    setFormData({
      centerIds: [],
      year: year,
      period: null,
    });
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!isOpen) {
      handleClose();
    }
  }, [handleClose, isOpen]);

  return (
    <SideModalDrawer
      title={`Run Forecast Report`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Generate Report"
      primaryCallback={() => handleSubmit()}
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryButtonProps={{
        loading: kfaReportLoading,
        disabled: !formData.centerIds.length || !formData.period || !formData.year,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      hasPrimaryChoice
    >
      <Form>
        <Row className="mb-2">
          <Col>
            <SelectMultiple
              required
              label={capitalize(t('spelling.center_plural'))}
              newLabelStyle
              onChange={(prev) => {
                handleCentersChange(prev);
              }}
              options={centersForBusiness}
              value={selectedCenters}
              getOptionLabel={(option: ICenter) => option.name}
              getOptionValue={(option: ICenter) => option.id}
              displayCountInContainerRenderer={(selectedCount) =>
                selectedCount > 0
                  ? selectedCount +
                    ' ' +
                    (selectedCount === 1 ? capitalize(t('spelling.center')) : capitalize(t('spelling.center_plural'))) +
                    ' Selected'
                  : `Select a ${capitalize(t('spelling.center'))}`
              }
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Select
              disabled={year <= 2023}
              options={getYearOptionsFromYear(year)}
              label="Year"
              required
              getOptionLabel={(g) => g.label}
              getOptionValue={(g) => g.value}
              name="year"
              value={formData?.year}
              onChange={(val) =>
                setFormData((prev) => ({
                  ...prev,
                  year: val.value,
                }))
              }
            />
          </Col>
          <Col lg={6}>
            <Select
              options={getTermOptions()}
              label="Term"
              required
              getOptionLabel={(g) => g.label}
              getOptionValue={(g) => g.value}
              name="period"
              value={formData?.period}
              onChange={(val) =>
                setFormData((prev) => ({
                  ...prev,
                  period: val.value,
                }))
              }
            />
          </Col>
        </Row>
      </Form>
    </SideModalDrawer>
  );
};

export default ForecastReportModal;
