import { faCheckCircle, faCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetStatementLink } from 'gql/statement/queries';
import React from 'react';
import DataTable from 'shared/components/DataTable';
import { statementStatusLabels } from 'shared/constants/enums/statementEnums';
import colors from '_colors.module.scss';

interface IProps {
  data: IStatement[];
  centerId?: string;
}
const StatementRunsTableRowDetail: React.FC<IProps> = ({ data, centerId }) => {
  const [getStatementLink, { loading: statementLinkLoading, data: statementLinkData }] = useGetStatementLink();

  const onClickViewStatement = (statementToView: string) => {
    getStatementLink({
      variables: {
        input: {
          centerId: centerId ?? '',
          statementId: statementToView,
        },
      },
    });
  };

  const getStatusLabel = (status: StatementStatus) => {
    switch (status) {
      case 'ERROR':
        return (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="mr-2" icon={faCircle} style={{ fontSize: 10 }} color={colors.danger} />
            <span>{statementStatusLabels[status].label}</span>
          </div>
        );
      case 'SUCCESS':
        return (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="mr-2" icon={faCircle} style={{ fontSize: 10 }} color={colors.success} />
            <span>{statementStatusLabels[status].label}</span>
          </div>
        );
      default:
        return (
          <div className="d-flex align-items-center">
            <span>{statementStatusLabels[status]?.label ?? status}</span>
          </div>
        );
    }
  };

  const seenFormatter = (hasSeen: boolean) =>
    hasSeen ? (
      <FontAwesomeIcon icon={faCheckCircle} color={colors.success} size={'2x'} />
    ) : (
      <FontAwesomeIcon icon={faTimesCircle} color={colors.secondary} size={'2x'} />
    );

  return (
    <DataTable
      data={data}
      showSelect={false}
      showPagination={false}
      showLoadingOverlay={statementLinkLoading}
      columns={[
        {
          text: 'Account Name',
          dataField: 'accountLastName',
          formatter: (cell: string, row: IStatement) =>
            `${row.accountLastName}, ${row.accountFirstName.substring(0, 1)}.`,
        },
        // {
        //   text: "Recipient",
        //   dataField: "recipient"
        // },
        {
          text: 'Email',
          dataField: 'email',
        },
        {
          text: 'Status',
          dataField: 'status',
          formatter: (cell: string, row: IStatement) => getStatusLabel(row.status),
        },
        {
          text: 'Seen?',
          dataField: 'hasSeen',
          formatter: (cell: string, row: IStatement) => seenFormatter(!!row.customerDownloadedStatementAt),
        },
        {
          text: '',
          dataField: '',
          isDummyField: true,
          formatter: (_: any, row: IStatement) => (
            <ul className="list-inline mb-0">
              {/* <li className="list-inline-item text-secondary mr-6">
              <a>Resend</a>
            </li> */}
              <li className="list-inline-item text-primary">
                <a onClick={() => onClickViewStatement(row.id)} style={{ cursor: 'pointer' }}>
                  View Statement
                </a>
              </li>
            </ul>
          ),
        },
      ]}
    />
  );
};

export default StatementRunsTableRowDetail;
