import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { GET_STAFF } from 'gql/staff/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
interface IDeactivateStaffInput {
  id: string;
  reasonForLeaving: ReasonForLeaving | null;
}

interface IDeactivateStaffData {
  id: string;
  employmentEndDate: string;
  reasonForLeaving: ReasonForLeaving;
  employmentStatus: EmploymentStatus;
}

export const DEACTIVATE_STAFF = gql`
  mutation ($input: DeactivateStaffInput!) {
    deactivateStaff(input: $input) {
      id
      firstname
      lastname
      email
      employmentStartDate
      employmentEndDate
      reasonForLeaving
      avatar {
        url
      }
      address {
        state
        city
      }
      roleship {
        roleId
        scopeType
        scopes {
          ... on Center {
            name
            id
          }
          ... on Entity {
            name
            id
          }
        }
      }
      employmentStatus
      primaryCenter {
        name
        id
      }
    }
  }
`;

export const useDeactivateStaff = () => {
  const user = useSelector((state: RootState) => state.user);
  return useMutation<{ deactivateStaff: IDeactivateStaffData }, { input: IDeactivateStaffInput }>(DEACTIVATE_STAFF, {
    update: (proxy, result: any) => {
      const newDeactivatedStaff: IStaff = result?.data?.deactivateStaff;
      // update get staff query
      const cachedGetStaff = proxy.readQuery<{ getStaff: IStaff }>({
        query: GET_STAFF,
        variables: { id: newDeactivatedStaff.id },
      });
      if (cachedGetStaff?.getStaff) {
        proxy.writeQuery({
          query: GET_STAFF,
          variables: { id: newDeactivatedStaff.id },
          data: { getStaff: { ...cachedGetStaff.getStaff, ...newDeactivatedStaff } },
        });
      }
    },
  });
};

interface IReactivateStaffInput {
  id: string;
  employmentStartDate: string | null;
  roleId: string;
  scopeIds: string[];
  primaryCenterId?: string | null;
  scopeType: 'CENTER' | 'ENTITY';
}

interface IReactivateStaffData {
  id: string;
  employmentStartDate: string | null;
  employmentEndDate: string | null;
  reasonForLeaving: ReasonForLeaving | null;
  employmentStatus: EmploymentStatus;
  roleship: IRoleship;
  primaryCenter: ICenter | null;
}

export const REACTIVATE_STAFF = gql`
  mutation ($input: ReactivateStaffInput!) {
    reactivateStaff(input: $input) {
      id
      firstname
      lastname
      email
      employmentStartDate
      employmentEndDate
      reasonForLeaving
      avatar {
        url
      }
      address {
        state
        city
      }
      roleship {
        roleId
        scopeType
        name
        scopes {
          ... on Center {
            name
            id
          }
          ... on Entity {
            name
            id
          }
        }
      }
      employmentStatus
      primaryCenter {
        name
        id
      }
    }
  }
`;

export const useReactivateStaff = () => {
  return useMutation<{ deactivateStaff: IReactivateStaffData }, { input: IReactivateStaffInput }>(REACTIVATE_STAFF, {
    update: (proxy, result: any) => {
      const newReactivatedStaff: IStaff = result?.data?.reactivateStaff;
      const { id } = result.data.reactivateStaff;
      const cachedResult: { getStaff: IStaff } | null = proxy.readQuery({ query: GET_STAFF, variables: { id } });
      if (cachedResult?.getStaff) {
        proxy.writeQuery({
          query: GET_STAFF,
          variables: { id },
          data: {
            getStaff: {
              ...cachedResult.getStaff,
              employmentStartDate: newReactivatedStaff.employmentStartDate,
              employmentEndDate: newReactivatedStaff.employmentEndDate,
              employmentStatus: newReactivatedStaff.employmentStatus,
              roleship: newReactivatedStaff.roleship,
              primaryCenter: {
                ...(cachedResult.getStaff.primaryCenter ?? {}),
                ...(newReactivatedStaff.primaryCenter ?? {}),
              },
            },
          },
        });
      }
    },
  });
};
