import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { toProperCase } from 'shared/util/string';

interface IProps {
  label: string;
  value: IRegulationAge;
  onChange: (text: string) => void;
  onAgeUnitChange: (unit: 'WEEK' | 'MONTH' | 'YEAR') => void;
  idPrefix?: string;
  disabled?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  errorText?: string;
  btnClassName?: string;
}

const RegulationAgeInput: React.FC<IProps> = ({
  label,
  value,
  onChange,
  onAgeUnitChange,
  idPrefix = 'regulation',
  disabled = false,
  required = false,
  isInvalid = false,
  errorText = '',
  btnClassName = '',
  ...props
}) => {
  return (
    <Form.Group>
      <div className="d-flex flex-row">
        {label && <Form.Label>{label}</Form.Label>}
        {required && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
      </div>
      <InputGroup>
        <Form.Control
          id={`${idPrefix}-age-input`}
          name="startAge.age"
          value={value.age.toString()}
          onChange={(event: any) => onChange(parseFloat(event.target.value).toFixed(2))} // allow 2 decimal places max
          type="number"
          step="0.5"
          min={0}
          disabled={disabled}
          isInvalid={isInvalid}
        />
        <DropdownButton
          alignRight
          id={`${idPrefix}-age-unit-dropdown`}
          className={`${btnClassName} kt-regulations-age-unit-dropdown border-style-none`}
          as={InputGroup.Append}
          title={
            <span className="pl-1">
              {' '}
              {`${toProperCase(value.unit)}s`} <FontAwesomeIcon transform={'right-5'} icon={faAngleDown} />{' '}
            </span>
          }
          variant="outline-secondary"
          disabled={disabled}
        >
          <DropdownItem onClick={() => onAgeUnitChange('WEEK')}>Weeks</DropdownItem>
          <DropdownItem onClick={() => onAgeUnitChange('MONTH')}>Months</DropdownItem>
          <DropdownItem onClick={() => onAgeUnitChange('YEAR')}>Years</DropdownItem>
        </DropdownButton>
        {errorText && <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>}
      </InputGroup>
    </Form.Group>
  );
};

export default RegulationAgeInput;
