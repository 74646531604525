import React from 'react';
import { useTranslation } from 'react-i18next';
import CpavFeeInclusion from './CpavFeeInclusion';
import DataControlAccordion from 'pages/TimeManagement/subroutes/StaffSchedules/components/DataControls/DataControlAccordion';
import './_cpavDetailedFee.scss';

interface IProps {
  cpavService?: IServiceCpav;
  handleChange: (sessionType: CpavSessionType, ageGroup: ICpavAgeGroup) => void;
}

const CpavDetailedFee: React.FC<IProps> = ({ cpavService, handleChange }) => {
  const { t } = useTranslation(['ccss']);

  if (!cpavService) return <></>;

  const displayFeeDetails = (sessionType: CpavSessionType, ageGroups?: ICpavAgeGroup[]) => {
    if (!ageGroups || !ageGroups.length) return <></>;
    return <CpavFeeInclusion sessionType={sessionType} ageGroup={ageGroups[0]} handleChange={handleChange} />;
  };

  const displayAgeGroup = (ageGroup: CpavAgeGroup) => {
    const { fees } = cpavService;
    if (!fees) return <></>;

    const ageGroupBEFSCH = !!fees['BEFSCH'] ? fees['BEFSCH'].filter((i) => i.ageGroup === ageGroup) : [];
    const ageGroupAFTSCH = !!fees['AFTSCH'] ? fees['AFTSCH'].filter((i) => i.ageGroup === ageGroup) : [];
    const ageGroupFULLDY = !!fees['FULLDY'] ? fees['FULLDY'].filter((i) => i.ageGroup === ageGroup) : [];
    const ageGroupHALFDY = !!fees['HALFDY'] ? fees['HALFDY'].filter((i) => i.ageGroup === ageGroup) : [];
    const ageGroupHOURLY = !!fees['HOURLY'] ? fees['HOURLY'].filter((i) => i.ageGroup === ageGroup) : [];

    if (
      !ageGroupBEFSCH.some((i) => i.ageGroup === ageGroup) &&
      !ageGroupAFTSCH.some((i) => i.ageGroup === ageGroup) &&
      !ageGroupFULLDY.some((i) => i.ageGroup === ageGroup) &&
      !ageGroupHALFDY.some((i) => i.ageGroup === ageGroup) &&
      !ageGroupHOURLY.some((i) => i.ageGroup === ageGroup)
    )
      return <></>;

    return (
      <>
        <div>
          <DataControlAccordion
            className="mb-4 cpav-service-fee-details__included-fees"
            title={t(`ccss:cpav.age-group.${ageGroup}`)}
          >
            {displayFeeDetails('BEFSCH', ageGroupBEFSCH)}
            {displayFeeDetails('AFTSCH', ageGroupAFTSCH)}
            {displayFeeDetails('FULLDY', ageGroupFULLDY)}
            {displayFeeDetails('HALFDY', ageGroupHALFDY)}
            {displayFeeDetails('HOURLY', ageGroupHOURLY)}
          </DataControlAccordion>
        </div>
      </>
    );
  };

  return (
    <div>
      {displayAgeGroup('0012MN')}
      {displayAgeGroup('1324MN')}
      {displayAgeGroup('2535MN')}
      {displayAgeGroup('36MNPR')}
      {displayAgeGroup('OVPRAG')}
    </div>
  );
};

export default CpavDetailedFee;
