import React, { useState, useCallback } from 'react';
import { Col, Row } from 'shared/components/Layout';
import Card from 'shared/components/Card';
import TextInput from 'shared/components/TextInput';
import Button, { CreateButton, IconButtonCircle } from 'shared/components/Buttons';
import { useCancelReturnFeeReduction, useUpdateEntityInviteCode } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import PersonCard from 'shared/components/Card/PersonCard';
import { ContactModal } from 'shared/components/ContactDisplayComponents';
import '../../businessProfile.scss';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import DataTable from 'shared/components/DataTable';
import CreateReturnFeeReductionModal from './CreateReturnFeeReductionModal';
import { useGetReturnFeeReductions } from '../../graphql/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

interface IProps {
  entityId: string;
}

const ReturnFeeReductionTab: React.FC<IProps> = ({ entityId }) => {
  const { data, refetch } = useGetReturnFeeReductions(entityId);
  const [cancelReturnFeeReduction, { loading: cancelReturnFeeReductionLoading }] = useCancelReturnFeeReduction();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleCancel = (returnFeeReductionIdToCancel: string) => {
    cancelReturnFeeReduction({
      variables: {
        input: {
          returnFeeReductionId: returnFeeReductionIdToCancel,
        },
      },
    })
      .then((response) => {
        showToast('Return fee reduction cancelled successfully.', 'success');
        refetch();
      })
      .catch((err) => {
        err = err.toString().replace('Error: GraphQL error:', '').trim();
        const errorMessage = !!err ? err : 'There was an error.';
        showToast(errorMessage, 'error');
      });
  };

  return (
    <>
      <Row align="start">
        <Col>
          <Card
            header={
              <div className="d-flex align-items-center">
                <div>{'Return fee reduction'}</div>
              </div>
            }
          >
            {!data?.getReturnFeeReductions.data ? (
              <DataTableLoadingSkeleton />
            ) : (
              <DataTable
                className="personnel-table"
                data={data.getReturnFeeReductions.data}
                dataSize={data.getReturnFeeReductions.data.length}
                showSelect={false}
                showPagination={false}
                noPadding
                columns={[
                  {
                    text: 'Enrolment ID',
                    dataField: 'enrolmentId',
                    sort: true,
                  },
                  {
                    text: 'Week start date',
                    dataField: 'sessionReportStartDate',
                    sort: true,
                    formatter: (cell: string, row: IReturnFeeReduction) => (
                      <>{moment(row.sessionReportStartDate).format('DD/MM/YYYY')}</>
                    ),
                  },
                  {
                    text: 'Amount returned',
                    dataField: 'totalFeeReductionAmountForWeek',
                    sort: true,
                  },
                  {
                    text: '',
                    dataField: 'returnFeeReductionId',
                    sort: true,
                    formatter: (cell: string, row: IReturnFeeReduction) => (
                      <>
                        {row.isCancelled ? (
                          <span>Cancelled</span>
                        ) : (
                          <IconButtonCircle
                            size="sm"
                            icon={faTimes}
                            color="gray"
                            tooltipText="Archive"
                            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleCancel(row.returnFeeReductionId);
                            }}
                          />
                        )}
                      </>
                    ),
                  },
                ]}
              />
            )}
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>{''}</Col>
        <Col>
          <CreateButton
            className="float-right kt-account-ccs-tab add-button"
            variant="primary"
            onClick={() => setShowCreateModal(true)}
          >
            Create
          </CreateButton>
        </Col>
      </Row>

      <CreateReturnFeeReductionModal isOpen={showCreateModal} onClose={() => setShowCreateModal(false)} />
    </>
  );
};

export default ReturnFeeReductionTab;
