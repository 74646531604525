import { useGetGLCodeCount } from 'gql/glCode/queries';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IProps {
  isOpen: boolean;
  loading: boolean;
  onSubmit: (values: string) => void;
  handleClose: () => void;
  glCode?: IGLCode;
  businessId: string;
}

const ArchiveGLCodeModal: React.FC<IProps> = ({ isOpen, loading, glCode, businessId, handleClose, onSubmit }) => {
  const { t } = useTranslation(['billing']);
  const [glCodeCount, setGLCodeCount] = useState(0);

  useGetGLCodeCount({
    variables: {
      input: {
        businessId: businessId,
        glCodeId: glCode?.id ?? '',
      },
    },
    onCompleted: (response) => {
      setGLCodeCount(response.getGLCodeCount === undefined ? 0 : response.getGLCodeCount);
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const handleSubmit = () => {
    onSubmit(glCode?.id ?? '');
  };

  return (
    <CenteredModal
      title={t('billing:gl-codes.archive-gl-code-modal-title')}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={true}
      closeOnSecondaryCallback={true}
      primaryButtonProps={{
        loading,
      }}
      primaryCallback={handleSubmit}
      secondaryCallback={handleClose}
    >
      <p>
        <b>GL-Code:</b> {glCode?.name}
      </p>
      <p>
        {glCodeCount >= 1
          ? glCodeCount === 1
            ? `${glCodeCount} assignment is `
            : `${glCodeCount} assignments are `
          : null}
        {glCodeCount >= 1 ? t('billing:gl-codes.archive-gl-code-modal-body') : null}
      </p>
    </CenteredModal>
  );
};

export default ArchiveGLCodeModal;
