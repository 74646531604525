import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import GLCodesTab from './components/GLCodesTab';
import GLCodesAssignmentsTab from './components/GLCodesAssignmentsTab';
import SettingsTab from './components/SettingsTab';
import { useTranslation } from 'react-i18next';
import { useGLCodeContext, TabKey } from './Context/GLCodeContext';
interface IProps {
  showCreateModal: boolean;
  dismissCreateModal: () => void;
}

const GLCodes: React.FC<IProps> = ({ showCreateModal, dismissCreateModal }) => {
  const { t } = useTranslation(['billing']);
  const { GLCodeTabKey, setGLCodeTabKey } = useGLCodeContext();
  return (
    <div>
      <Tabs
        activeKey={GLCodeTabKey ?? 'list'}
        onSelect={(eventKey) => {
          setGLCodeTabKey(eventKey as TabKey);
        }}
        className="kt-billing-tab"
      >
        <Tab eventKey="list" title={t('billing:gl-codes.tab-title')}>
          <GLCodesTab showCreateModal={showCreateModal} dismissCreateModal={dismissCreateModal} />
        </Tab>
        <Tab eventKey="assignments" title={t('billing:gl-codes.gl-code-assignments')}>
          <GLCodesAssignmentsTab />
        </Tab>
        <Tab eventKey="settings" title={t('billing:gl-codes.gl-code-settings')}>
          <SettingsTab />
        </Tab>
      </Tabs>
    </div>
  );
};

export default GLCodes;
