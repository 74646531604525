import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';

interface Session {}
interface IDeleteShareVariables {
  businessId: string;
  arrangementId: string;
}
interface IBulkVariables {
  businessId: string;
  arrangementIds: string[];
}

const SendResendEmail = gql`
  mutation ($businessId: ID!, $arrangementId: ID!) {
    sendCwaAgreementEmail(businessId: $businessId, arrangementId: $arrangementId) {
      status
    }
  }
`;

const CancelArrangementCWA = gql`
  mutation ($businessId: ID!, $arrangementId: ID!) {
    cancelArrangement(businessId: $businessId, arrangementId: $arrangementId) {
      accountId
      status
    }
  }
`;

const BulkSendEmails = gql`
  mutation ($businessId: ID!, $arrangementIds: [ID!]!) {
    bulkSendAgreementEmails(businessId: $businessId, arrangementIds: $arrangementIds) {
      status
    }
  }
`;

const BulkCancelArrangements = gql`
  mutation ($businessId: ID!, $arrangementIds: [ID!]!) {
    bulkCancelArrangements(businessId: $businessId, arrangementIds: $arrangementIds) {
      status
    }
  }
`;

const useCancelArrangementCWA = (input: IDeleteShareVariables) => {
  return useMutation<Session, IDeleteShareVariables>(CancelArrangementCWA, {
    variables: {
      ...input,
    },
  });
};

const useSendResendEmail = (input: IDeleteShareVariables) => {
  return useMutation<Session, IDeleteShareVariables>(SendResendEmail, {
    variables: {
      ...input,
    },
  });
};

const useBulkSendEmail = (input: IBulkVariables) => {
  return useMutation<Session, IBulkVariables>(BulkSendEmails, {
    variables: {
      ...input,
    },
  });
};

const useBulkCancelArrangements = (input: IBulkVariables) => {
  return useMutation<Session, IBulkVariables>(BulkCancelArrangements, {
    variables: {
      ...input,
    },
  });
};

export { useCancelArrangementCWA, useSendResendEmail, useBulkSendEmail, useBulkCancelArrangements };
