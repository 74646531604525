import React, { useState, useCallback, useEffect } from 'react';
import ResizeModal from './ResizeModal';
import Circle from 'shared/components/LoadingSkeletons/Circle';
import './_avatarUpload.scss';
import { isString } from 'shared/util/string';
import cast from 'shared/util/cast';
import UploadOverlay from './UploadOverlay';
import placeholderImage from 'shared/components/Avatar/avatar.png';

interface IProps {
  onFileSelect: (file: File) => void;
  image?: string;
  loading?: boolean;
  className?: string;
  placeholder?: string;
  imageResizeWidth?: number;
  imageResizeHeight?: number;
}

const AvatarUpload: React.FC<IProps> = ({
  onFileSelect,
  image: imageSrc,
  loading = false,
  placeholder = 'Upload Photo',
  className,
  imageResizeWidth,
  imageResizeHeight,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isResizeModalOpen, setResizeModalOpen] = useState(false);
  const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
  const [image, setImage] = useState<string | undefined | File>(imageSrc);

  useEffect(() => {
    setImage(imageSrc);
  }, [imageSrc]);

  const handleFileSelect = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedFile(event.target.files[0]);
    setResizeModalOpen(true);
    event.target.value = ''; // reset value so onChange is triggered if user keeps selecting same file
  }, []);

  return (
    <div className={`kt-avatar-upload-container ${className}`}>
      <input ref={fileInputRef} type="file" className="d-none" accept="image/*" onChange={handleFileSelect} />
      <UploadOverlay onClick={() => fileInputRef.current && fileInputRef.current.click()}>
        {loading ? (
          <Circle size="lg" />
        ) : image ? (
          <img
            className="d-flex kt-avatar-upload-image"
            alt="avatar"
            // @ts-ignore
            src={isString(image) ? cast<string>(image) : URL.createObjectURL(image)}
            onError={(event) => setImage(placeholderImage)}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center kt-avatar-upload-button">
            <span className="d-block text-center">Upload Photo</span>
          </div>
        )}
      </UploadOverlay>
      <ResizeModal
        open={isResizeModalOpen}
        onHide={() => {
          setResizeModalOpen(false);
        }}
        image={selectedFile}
        saveCallback={(file) => {
          onFileSelect(file);
          setImage(file);
        }}
        width={imageResizeWidth}
        height={imageResizeHeight}
      />
    </div>
  );
};

export default AvatarUpload;
