import React from 'react';
//components
import Modal from 'react-bootstrap/Modal';
import Button from 'shared/components/Buttons';
import { Row, Col } from 'shared/components/Layout';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
//styles
import '../../EndOfCareTab.scss';
//store
import { useGetEndCareEnrollmentHistoryByAccountChildId } from '../../graphql/queries';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers';
//utils
import { formatDate, getActionType } from '../../Tabs/EndOfCareActive/common';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onHide: () => void;
  accountID: string;
  timezone: string;
}

export const ModalHistoryWindow: React.FC<IProps> = ({ isOpen, onHide, accountID, timezone }) => {
  const { t } = useTranslation('subsidies');
  const entityId = useSelector((state: RootState) => state?.user?.entityId) ?? '';

  const { data: dataHistory, loading: loadingDataByAccountId } = useGetEndCareEnrollmentHistoryByAccountChildId({
    input: {
      businessId: entityId,
      accountChildId: accountID,
      actionTypes: ['HIDE', 'REVEAL'],
    },
  });
  const endCareTableData =
    // @ts-ignore
    dataHistory?.getEndCareEnrollmentHistoryByAccountChildId?.map((i) => {
      const person = `${i.person.firstname} ${i.person.lastname}`;
      const childName = `${i.childFirstName} ${i.childLastName}`;
      return {
        id: i.id,
        actionBy: person,
        actionType: getActionType(i.actionType),
        childFirstName: childName,
        date: formatDate(i.date, timezone),
        transactionId: i.transactionId,
      };
    }) ?? [];

  return (
    <Modal backdrop="static" centered show={isOpen} onHide={onHide} scrollable={true} size="lg">
      <Modal.Header closeButton>
        <h4>{t('end-care.history-tab.view-history.modal-buttons.history')}</h4>
      </Modal.Header>
      <Modal.Body className="p-4 m-0">
        {loadingDataByAccountId ? (
          <LoadingLines />
        ) : (
          <div>
            {endCareTableData.map(
              // @ts-ignore
              (item) => (
                <div className="d-flex flex-row align-items-center" key={item.transactionId}>
                  <span className="flex-grow-1 kt-accordion-card-header text-text-color font-weight-normal pt-4 pb-4">
                    <Row>
                      <Col xs={3}>{item.date}</Col>
                      <Col xs={4}>{item.actionBy}</Col>
                      <Col xs={2}>{item.actionType}</Col>
                      <Col xs={3}>{item.childFirstName}</Col>
                    </Row>
                  </span>
                </div>
              )
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className={`border-top-0`}>
        <Button variant="light" onClick={onHide}>
          {t('end-care.history-tab.view-history.modal-buttons.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
