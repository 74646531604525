import moment from 'moment';
const CCSS_WEEK_START = '2018-07-02';

export const getCcssWeek = (date: string, timezone = moment.tz.guess()): number | null => {
  const firstCssWeek = moment.tz(CCSS_WEEK_START, timezone);
  const dateMoment = moment.tz(date, timezone).startOf('isoWeek');

  if (dateMoment.isSameOrAfter(firstCssWeek, 'day')) {
    const diffWeeks = dateMoment.diff(firstCssWeek, 'weeks');
    const ccssWeek = diffWeeks % 2 === 0 ? 1 : 2;
    return ccssWeek;
  }

  return null;
};
