import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWrapper from 'shared/components/PageWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { Col, Row } from 'shared/components/Layout';
import Card from 'shared/components/Card';
import Select from 'shared/components/Select';
import DateInput from 'shared/components/DateInput';
import TextInput from 'shared/components/TextInput';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useGetProgramById, useGetKindyProgramFundingSchedules } from 'gql/program/queries';
import moment from 'moment';
import StaffSelectInput from 'shared/components/StaffSelectInput/StaffSelectInput';
import { useTranslation } from 'react-i18next';
import { useGetClassesForCenter } from 'gql/center/queries';
import { useGetBasicStaffForCenter } from 'pages/Centers/subroutes/Class/graphql/queries';
import { ApolloError } from '@apollo/client';
import Checkbox from 'shared/components/Checkbox';
import { useProgramUpdate } from 'gql/program/mutation';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import { Form } from 'react-bootstrap';
import ProgramLeaveDayComponent from './ProgramLeaveDayComponent/ProgramLeaveDayComponent';
import { RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import ProgramChildrenCard from './ProgramChildrenCard';
import TimeInput from '../../../../shared/components/TimePicker/TimeInput';
import EducatorQualifications from './EducatorQualifications';
import { omitTypename } from 'shared/util/object';
import Button from 'shared/components/Buttons';
import _ from 'lodash';
import {
  freeKindyStartDate,
  freeKindySubsidySchemeId,
  kindyForAllSubsidySchemeId,
  startStrongSubsidySchemeId,
} from 'pages/Subsidies/KindyFunding/utils';
import KindyForAllFundingSchedulesCard from './KindyForAllFundingSchedulesCard/KindyForAllFundingSchedulesCard';
import StartStrongFundingSchedulesCard from './StartStrongFundingSchedulesCard/StartStrongFundingSchedulesCard';
import FreeKindyFundingSchedulesCard from './FreeKindyFundingSchedulesCard/FreeKindyFundingSchedulesCard';

interface IRouteProps {
  id?: string;
}

interface IProps extends RouteComponentProps<IRouteProps, any, {}> {}

interface IForm {
  subsidyId: string;
  programName: string;
  programStartDate: string;
  programEndDate?: string;
  schedules: ProgramSchedule[];
  classes?: IClass[];
  staff: IBasicStaff[];
  educators: IProgramTeacher[];
  programId: string;
  paymentRule?: IQkfsPaymentRule;
  fundingSchedules?: IKindyProgramFundingSchedule[];
}

const initialState: IForm = {
  subsidyId: '',
  programName: '',
  programStartDate: '',
  schedules: [],
  classes: undefined,
  staff: [],
  educators: [],
  programId: '',
  paymentRule: undefined, //TODO: Remove this once we clean up on the back-end
  fundingSchedules: [],
};

const daySettings = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

export interface ProgramContextShape {
  businessId?: string;
  programId?: string;
}

export const ProgramContext = React.createContext<ProgramContextShape>({});

const Programs: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['subsidies', 'translation']);
  const programId = props.match.params.id ?? '';
  const businessId: string = useSelector((state: RootState) => state.context.businessId) ?? '';

  const {
    data: programData,
    loading: getProgramLoading,
    refetch,
  } = useGetProgramById({
    variables: {
      input: {
        businessId,
        programId,
      },
    },
  });

  const {
    data: programFundingSchedulesData,
    loading: fundingLoading,
    refetch: refetchSchedules,
  } = useGetKindyProgramFundingSchedules({
    variables: {
      input: {
        programId,
      },
    },
  });

  const { data: centerClassData, loading: classesLoading } = useGetClassesForCenter({
    variables: { centerId: programData?.getProgramById?.centerId || '' },
    fetchPolicy: 'network-only',
  });

  const { data: staffForCenter, loading: fetchingStaff } = useGetBasicStaffForCenter(
    programData?.getProgramById?.centerId || ''
  );

  const history = useHistory();

  const [formData, setFormData] = useState<IForm>(initialState);

  useEffect(() => {
    if (!!programData) {
      const program = programData?.getProgramById;
      const staffData = staffForCenter?.getBasicStaffForCenter?.data || [];
      const classData = centerClassData?.getClassesForCenter || [];
      const fundingSchedulesData = programFundingSchedulesData?.getKindyProgramFundingSchedules || [];

      setFormData({
        subsidyId: program.subsidyId,
        programName: program.programName,
        programStartDate: program.programStartDate,
        programEndDate: program.programEndDate,
        programId: program.programId,
        schedules: program.schedules,
        classes: classData.filter((c) => program.classIds.map((i) => i.classId).includes(c.id)),
        staff: staffData.filter((s) => program.educators.map((i) => i.staffId).includes(s.id)) || [],
        educators: program.educators,
        paymentRule: program.paymentRule,
        fundingSchedules: fundingSchedulesData,
      });
    }

    return () => {
      setFormData(initialState);
    };
  }, [programData, centerClassData, staffForCenter, programFundingSchedulesData?.getKindyProgramFundingSchedules]);

  const isLoading = useMemo(
    () => getProgramLoading || classesLoading || fetchingStaff,
    [getProgramLoading, classesLoading, fetchingStaff]
  );

  const classOptions = centerClassData?.getClassesForCenter.filter((c) => !c.archivedAt) ?? [];

  const handleChangeCallback = useCallback((componentName: string, value: any) => {
    setFormData((prevState) => {
      return { ...prevState, [componentName]: value };
    });
  }, []);

  const handleStaffChange = useCallback(
    (staff: IBasicStaff[]) => {
      const existingEducators = (formData.educators ?? []).filter((e) =>
        staff.map((s) => s.id).includes(e.staffId ?? '')
      );

      const newEducators: IProgramTeacher[] = staff
        .filter((s) => !existingEducators.map((e) => e.staffId ?? '').includes(s.id))
        .map((s) => {
          return {
            staffId: s.id,
            firstName: s.firstname,
            lastName: s.lastname,
            qualificationStatus: 'None',
          };
        });

      setFormData((prevState) => {
        return { ...prevState, staff: staff, educators: existingEducators.concat(newEducators) };
      });
    },
    [formData.educators]
  );

  const [updateProgram, { loading: updateProgramInProgress }] = useProgramUpdate();

  const updateEducators = useCallback(
    (teacher: IProgramTeacher) => {
      const educatorsExcludingTeacher = formData.educators.filter((e) => e.staffId !== teacher.staffId);

      setFormData((prevState) => {
        return { ...prevState, educators: educatorsExcludingTeacher.concat(teacher) };
      });
    },
    [formData.educators]
  );

  const updateFundingSchedules = (fundingSchedules: IKindyProgramFundingSchedule[]) => {
    setFormData((state) => {
      return { ...state, fundingSchedules: fundingSchedules };
    });
  };

  const handleSubmit = () => {
    updateProgram({
      variables: {
        input: {
          programName: formData.programName,
          classIds: formData.classes?.map((c) => c.id) ?? [],
          programStartDate: moment(formData.programStartDate).format('YYYY-MM-DD'),
          programId: programData?.getProgramById.programId,
          programEndDate: formData.programEndDate ? moment(formData.programEndDate).format('YYYY-MM-DD') : undefined,
          schedules: formData.schedules.map((i) => {
            return { day: i.day, startTime: i.startTime, endTime: i.endTime };
          }),
          educators: formData.educators.map((e) => omitTypename(e)),
          subsidyId: formData.subsidyId,
          centerId: programData?.getProgramById.centerId || '',
          businessId,
          paymentRule: formData.paymentRule,
          fundingSchedules:
            formData.fundingSchedules?.map(
              (f) =>
                ({
                  subsidyTypes: f.subsidyTypes ?? [],
                  basePercentage: f.basePercentage,
                  startDate: f.startDate,
                  weeks: f.weeks,
                } as IKindyProgramFundingScheduleInput)
            ) || [],
        },
      },
    })
      .then((result) => {
        showToast(`${t('subsidies:ok.update', { programName: formData.programName })}`, 'success');
        refetch();
        refetchSchedules();
      })
      .catch((err: Error) => {
        const message = err instanceof ApolloError ? getApolloErrorMessage(err) : err.message;
        showToast(message, 'error');
      });
  };

  const handleScheduleTimeChange = useCallback(
    (name: string, attribute: 'startTime' | 'endTime', value: any) => {
      const { schedules } = formData;
      let idx = schedules.findIndex((o) => o.day === name);

      if (idx >= 0) {
        schedules[idx][attribute] = moment(value, 'HH:mm').format('HH:mm:ss');
      }

      handleChangeCallback('schedules', schedules);
    },
    [formData, handleChangeCallback]
  );

  const isValidProgramFundingSchedules = (form: IKindyProgramFundingSchedule[] | undefined) => {
    var isValid = true;
    form?.map((bc) => {
      isValid = isValidProgramFunding(bc);
      return isValid;
    });
    return isValid;
  };

  const isValidProgramFunding = (schedule: IKindyProgramFundingSchedule) => {
    if (!schedule.startDate || !schedule.weeks || schedule.weeks > 52 || schedule.weeks < 40) {
      return false;
    }
    if (schedule.subsidyTypes?.includes('Base') && formData.subsidyId != freeKindySubsidySchemeId) {
      if (!schedule.basePercentage || schedule.basePercentage < 0 || schedule.basePercentage > 100) {
        return false;
      }
    }
    return true;
  };

  const startDateIsValid =
    !formData.subsidyId ||
    formData.subsidyId == startStrongSubsidySchemeId ||
    (formData.subsidyId == freeKindySubsidySchemeId && formData.programStartDate >= freeKindyStartDate) ||
    (formData.subsidyId == kindyForAllSubsidySchemeId && formData.programStartDate < freeKindyStartDate);

  const endDateIsValid =
    (moment(formData.programStartDate).year() < 2023 && moment(formData.programEndDate).year() <= 2023) ||
    moment(formData.programStartDate).year() === moment(formData.programEndDate).year();

  const startDatePreceedsEndDate =
    formData.programEndDate === undefined || moment(formData.programStartDate) < moment(formData.programEndDate);

  const isFormValid = useMemo(
    () =>
      startDateIsValid &&
      endDateIsValid &&
      startDatePreceedsEndDate &&
      formData.subsidyId &&
      formData.programName &&
      formData.programStartDate &&
      formData.classes?.length &&
      isValidProgramFundingSchedules(formData.fundingSchedules),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData]
  );

  const getFundingScheduleComponent = (subsidyId: string): JSX.Element => {
    switch (subsidyId) {
      case kindyForAllSubsidySchemeId:
        return (
          <KindyForAllFundingSchedulesCard
            programId={programId}
            fundingSchedules={formData.fundingSchedules}
            setFundingSchedules={updateFundingSchedules}
          />
        );

      case startStrongSubsidySchemeId:
        return (
          <StartStrongFundingSchedulesCard
            programId={programId}
            fundingSchedules={formData.fundingSchedules}
            setFundingSchedules={updateFundingSchedules}
          />
        );

      case freeKindySubsidySchemeId:
        return (
          <FreeKindyFundingSchedulesCard
            programId={programId}
            fundingSchedules={formData.fundingSchedules}
            setFundingSchedules={updateFundingSchedules}
          />
        );
    }

    return <></>;
  };

  return (
    <ProgramContext.Provider value={{ businessId: businessId, programId: programId }}>
      <SpinnerOverlay show={isLoading}>
        <PageWrapper
          pageTitle={programData?.getProgramById.subsidyName || ''}
          buttonComponent={
            <Button
              disabled={isLoading || updateProgramInProgress || !isFormValid}
              onClick={() => handleSubmit()}
              loading={updateProgramInProgress}
              permission={{
                permission: 'Base',
                level: RoleLevelType.Edit,
                area: 'Agency',
              }}
            >
              Save
            </Button>
          }
          secondaryButtonComponent={
            <Button variant="light" className="mr-4" onClick={() => history.push('/centers/programs')}>
              Cancel
            </Button>
          }
        >
          <div>
            <Row align="start">
              <Col lg={6}>
                <Card header={t('subsidies:program.information')} loading={false} loadingLines={7}>
                  <Form>
                    <Row>
                      <Col>
                        <TextInput
                          required
                          value={formData.programName}
                          onChange={(v) => handleChangeCallback('programName', v)}
                          label={t('subsidies:program.name')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Select
                          label={t('subsidies:classes.assign-class')}
                          value={formData.classes ?? []}
                          options={classOptions}
                          onChange={(values: any) => handleChangeCallback('classes', values)}
                          getOptionValue={(option: IClass) => option.id}
                          getOptionLabel={(option: IClass) => option.name}
                          isLoading={false}
                          required
                          isMulti
                        />
                      </Col>
                    </Row>
                  </Form>
                </Card>
                <Card header={t('subsidies:classes.educator-assignment')} className="h-100">
                  <Row>
                    <Col>
                      <StaffSelectInput
                        centerId={programData?.getProgramById.centerId || ''}
                        label={t('subsidies:classes.assign-educators')}
                        value={formData['staff'] ?? []}
                        onChange={(values) => {
                          handleStaffChange(values);
                        }}
                      />
                    </Col>
                  </Row>
                  {formData.staff.map((s, idx) => (
                    <EducatorQualifications
                      key={idx}
                      required={formData.subsidyId === kindyForAllSubsidySchemeId}
                      teacher={formData.educators.find((e) => e.staffId === s.id)}
                      onChange={updateEducators}
                    />
                  ))}
                </Card>
                {!fundingLoading && getFundingScheduleComponent(formData.subsidyId)}
                <ProgramChildrenCard
                  businessId={businessId}
                  programId={programId}
                  startDate={formData.programStartDate}
                  startStrong={formData.subsidyId === startStrongSubsidySchemeId}
                />
              </Col>
              <Col lg={6}>
                <Card header={t('subsidies:program.schedule')} loading={false} loadingLines={7}>
                  <Row className="mb-4">
                    <Col>{t('subsidies:classes.program-schedule-message')}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <DateInput
                        required
                        isValid={startDateIsValid && startDatePreceedsEndDate}
                        label={t('subsidies:program.start-date')}
                        date={formData['programStartDate']}
                        onDateSelect={(input) =>
                          setFormData((prev) => ({
                            ...prev,
                            programStartDate: !!input ? moment(input).format('YYYY-MM-DD') : input,
                          }))
                        }
                        className="kt-date-input-no-max-width"
                        dateOnly
                      />
                    </Col>
                    <Col>
                      <DateInput
                        required
                        isValid={endDateIsValid && startDatePreceedsEndDate}
                        label={t('subsidies:program.ends-on')}
                        date={formData['programEndDate']}
                        onDateSelect={(input) =>
                          setFormData((prev) => ({
                            ...prev,
                            programEndDate: !!input ? moment(input).format('YYYY-MM-DD') : input,
                          }))
                        }
                        className="kt-date-input-no-max-width"
                        dateOnly
                      />
                    </Col>
                  </Row>
                  {!startDatePreceedsEndDate && (
                    <p className="text-danger small">{'* Start date must be before end date'}</p>
                  )}
                  {formData.subsidyId == freeKindySubsidySchemeId &&
                    formData.programStartDate !== '' &&
                    formData.programStartDate < '2024-01-01' && (
                      <p className="text-danger small">{'* QLD Free Kindy subsidy is not available before 2024'}</p>
                    )}
                  {formData.subsidyId == kindyForAllSubsidySchemeId && formData.programStartDate >= '2024-01-01' && (
                    <p className="text-danger small">{'* QLD Kindy for All subsidy is not available after 2023'}</p>
                  )}
                  {formData.programEndDate && !endDateIsValid && (
                    <p className="text-danger small">{'* End date must be within the same year as the start date'}</p>
                  )}
                  <Row className="mt-4">
                    <Col>{t('subsidies:program.scheduled-days')}</Col>
                    <Col>{t('subsidies:program.start-time')}</Col>
                    <Col>{t('subsidies:program.end-time')}</Col>
                  </Row>
                  {daySettings.map((value, idx) => {
                    const label = t(
                      // @ts-ignore
                      `translation:day.${value.toLowerCase()}` as const
                    ) as string;

                    const dayUpperCase = value.toUpperCase();
                    return (
                      <Row key={`subsidies-schedule-day-${value}`}>
                        <Col>
                          <Checkbox
                            label={label}
                            value={formData['schedules'].find((v) => v.day === dayUpperCase) != null}
                            onChange={(v) => {
                              const { schedules } = formData;
                              let find = schedules.findIndex((o) => o.day === dayUpperCase);
                              if (find < 0) {
                                schedules.push({ day: value as DayOfWeek });
                              }

                              if (!v) {
                                schedules.splice(find, 1);
                              }

                              handleChangeCallback('schedules', schedules);
                            }}
                          />
                        </Col>
                        <Col className="mt-2">
                          <TimeInput
                            isAM={true}
                            value={formData.schedules.find((v) => v.day === dayUpperCase)?.startTime}
                            onChange={(v) => handleScheduleTimeChange(dayUpperCase, 'startTime', v)}
                            disabled={formData['schedules'].find((v) => v.day === dayUpperCase) == null}
                          />
                        </Col>
                        <Col className="mt-2">
                          <TimeInput
                            isAM={false}
                            value={formData.schedules.find((v) => v.day === dayUpperCase)?.endTime}
                            onChange={(v) => handleScheduleTimeChange(dayUpperCase, 'endTime', v)}
                            disabled={formData['schedules'].find((v) => v.day === dayUpperCase) == null}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </Card>
                {(formData.subsidyId == freeKindySubsidySchemeId ||
                  formData.subsidyId == kindyForAllSubsidySchemeId) && (
                  <Card header="Program Calendar">
                    <ProgramLeaveDayComponent
                      leaveDays={programData?.getProgramById.programLeaveDays ?? []}
                      refetch={refetch}
                    ></ProgramLeaveDayComponent>
                  </Card>
                )}
              </Col>
            </Row>
          </div>
        </PageWrapper>
      </SpinnerOverlay>
    </ProgramContext.Provider>
  );
};

export default Programs;
