import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18n';
import { Button } from 'react-bootstrap';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Col, Row } from 'shared/components/Layout';
import { NumberInput } from 'shared/components/TextInput';
import { useUpdateSessionAmendmentBusinessConfig } from 'gql/sessionAmendment/mutations';
import { useGetBusinessConfig } from 'gql/sessionAmendment/queries';

import { showToast } from 'shared/components/Toast';
import Alert from 'shared/components/Alert';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'shared/util/string';

interface IProps {
  businessId: string;
  refreshDataGrid: () => void;
}

const AttendanceDaysForm: React.FC<IProps> = ({ businessId, refreshDataGrid }) => {
  const { t } = useTranslation();

  const [isDirty, setDirty] = useState(false);
  const [getBusinessConfig, { data: businessConfigData }] = useGetBusinessConfig({
    variables: {
      input: {
        businessId: businessId,
      },
    },
  });
  const [editDay, setEditDay] = useState(0);
  const [updateSessionAmendmentBusinessConfig] = useUpdateSessionAmendmentBusinessConfig({
    onCompleted: (result) => {
      if (result?.updateSessionAmendmentBusinessConfig) {
        showToast(i18n.t('translation:general.updateSuccessful'), 'success');
        setDirty(false);
        getBusinessConfig();
      }
    },
  });

  const handleSave = useCallback(async () => {
    await updateSessionAmendmentBusinessConfig({
      variables: {
        input: {
          businessId,
          editDay: editDay ?? 0,
        },
      },
    });
  }, [editDay, updateSessionAmendmentBusinessConfig]);

  useEffect(() => {
    if (businessId) {
      getBusinessConfig({
        variables: {
          input: {
            businessId: businessId,
          },
        },
      });
    }
  }, [businessId]);

  useEffect(() => {
    setEditDay(businessConfigData?.getSessionAmendmentGetBusinessConfig?.editDay);
  }, [businessConfigData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    (value) => {
      setEditDay(value);
      setDirty(true);
    },
    [setEditDay, setDirty]
  );

  const handleCancel = () => {
    const savedBusinessDay = businessConfigData?.getSessionAmendmentGetBusinessConfig?.editDay || 0;
    setEditDay(savedBusinessDay);
    setDirty(false);
  };

  return (
    <div>
      <FormWrapper2
        formIsDirty={false}
        onCancel={() => {}}
        onSave={handleSave}
        saveDisabled={false}
        primaryChoice="Save"
        loading={false}
        toggleDirty={setDirty}
      >
        <p>{t('attendance.history-control.select-days-number')}</p>
        <Row className="mb-8">
          <Col lg={3} xl={2}>
            <NumberInput
              name="name"
              label={capitalize(t('spelling.days'))}
              value={editDay}
              onChange={handleChange}
              min="0"
              isInvalid={false}
              errorText={''}
            />
          </Col>
          <Col>
            {isDirty && (
              <div className="d-flex mt-5">
                <Button className="mb-4 ml-4 mr-2" variant="light" onClick={handleCancel}>
                  {capitalize(t('spelling.cancel'))}
                </Button>
                <Button className="mb-4" onClick={handleSave}>
                  {capitalize(t('spelling.save'))}
                </Button>
              </div>
            )}
          </Col>
        </Row>
        <Alert variant="info">{t('attendance.history-control.zero-info')}</Alert>
      </FormWrapper2>
    </div>
  );
};

export default AttendanceDaysForm;
