import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  Phone: any;
  Time: any;
  URL: any;
  Upload: any;
  Void: any;
};

export type ApiKey = {
  __typename?: 'APIKey';
  description?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastRolled: Scalars['DateTime'];
  privateKey?: Maybe<Scalars['String']>;
  rolledBy: Scalars['ID'];
};

export enum AuState {
  Act = 'ACT',
  Nsw = 'NSW',
  Nt = 'NT',
  Qld = 'QLD',
  Sa = 'SA',
  Tas = 'TAS',
  Vic = 'VIC',
  Wa = 'WA',
}

export type Absence = {
  __typename?: 'Absence';
  accountChildId: Scalars['ID'];
  contractId?: Maybe<Scalars['ID']>;
  date: Scalars['Date'];
  id: Scalars['ID'];
  reason?: Maybe<AbsenceReason>;
  reportedBy?: Maybe<Person>;
  sessionId: Scalars['ID'];
  type: AbsenceType;
  waiveGapFee?: Maybe<Scalars['Boolean']>;
};

export type AbsenceDto = {
  __typename?: 'AbsenceDto';
  absenceDocumentHeld?: Maybe<Scalars['Boolean']>;
  accountChildId?: Maybe<Scalars['ID']>;
  contractId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  reason?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  waiveGapFee?: Maybe<Scalars['Boolean']>;
};

export type AbsenceFailure = {
  __typename?: 'AbsenceFailure';
  accountChildId: Scalars['ID'];
  reason: Scalars['String'];
};

export type AbsenceInfo = {
  contractForDates?: InputMaybe<ContractForDates>;
  sessionId?: InputMaybe<Scalars['String']>;
};

export enum AbsenceReason {
  AlternativeCare = 'ALTERNATIVE_CARE',
  Appointment = 'APPOINTMENT',
  ChildAttendingPreSchool = 'CHILD_ATTENDING_PRE_SCHOOL',
  ChildIll = 'CHILD_ILL',
  CourtOrderOrParentingOrderInPlace = 'COURT_ORDER_OR_PARENTING_ORDER_IN_PLACE',
  IndividualCaringForChildIsIll = 'INDIVIDUAL_CARING_FOR_CHILD_IS_ILL',
  IndividualWhoLivesWithChildIsIll = 'INDIVIDUAL_WHO_LIVES_WITH_CHILD_IS_ILL',
  LocalEmergencyChildsCarerDoesNotWishToAttend = 'LOCAL_EMERGENCY_CHILDS_CARER_DOES_NOT_WISH_TO_ATTEND',
  LocalEmergencyServiceClosed = 'LOCAL_EMERGENCY_SERVICE_CLOSED',
  LocalEmergencyUnableToAttend = 'LOCAL_EMERGENCY_UNABLE_TO_ATTEND',
  NotImmunisedAgainstParticularInfectiousDisease = 'NOT_IMMUNISED_AGAINST_PARTICULAR_INFECTIOUS_DISEASE',
  PartnerOfIndividualCaringForChildIsIll = 'PARTNER_OF_INDIVIDUAL_CARING_FOR_CHILD_IS_ILL',
  PrescribedEnrolmentCeasedIncorrectly = 'PRESCRIBED_ENROLMENT_CEASED_INCORRECTLY',
  PrescribedFamilyTragedy = 'PRESCRIBED_FAMILY_TRAGEDY',
  PrescribedServiceHasChangedOwnership = 'PRESCRIBED_SERVICE_HAS_CHANGED_OWNERSHIP',
  PrescribedUsualServiceClosedAndChildAttendingDifferentServiceUnderTheSameProvider = 'PRESCRIBED_USUAL_SERVICE_CLOSED_AND_CHILD_ATTENDING_DIFFERENT_SERVICE_UNDER_THE_SAME_PROVIDER',
  PupilFreeDay = 'PUPIL_FREE_DAY',
  Quarantine = 'QUARANTINE',
  Sick = 'SICK',
  TransportationIssue = 'TRANSPORTATION_ISSUE',
  Vacation = 'VACATION',
}

export enum AbsenceType {
  Additional = 'ADDITIONAL',
  Holiday = 'HOLIDAY',
  Standard = 'STANDARD',
}

export type Account = {
  __typename?: 'Account';
  balance?: Maybe<Scalars['Float']>;
  billingCycle?: Maybe<BillingCycle>;
  center?: Maybe<Center>;
  centerId: Scalars['ID'];
  children: Array<AccountChild>;
  contacts: Array<Contact>;
  discounts: Array<AppliedAccountDiscount>;
  entity?: Maybe<Entity>;
  entityId: Scalars['ID'];
  family: Family;
  familyId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  paymentMethods: Array<PaymentMethod>;
  payrixCustomerId?: Maybe<Scalars['String']>;
  pendingBalance?: Maybe<Scalars['Float']>;
  periodBalances?: Maybe<PeriodBalances>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchPosition?: Maybe<AccountPosition>;
  status?: Maybe<AccountStatusType>;
  surrogateKey: Scalars['Int'];
  tags: Array<Tag>;
  transactionsInTimeframe: Array<Transaction>;
};

export type AccountSearchPositionArgs = {
  search: AccountSearchInput;
};

export type AccountTransactionsInTimeframeArgs = {
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type AccountAdhocStatementRunsInput = {
  accountId: Scalars['ID'];
  periodEndOn: Scalars['Date'];
  periodStartFrom: Scalars['Date'];
};

export type AccountAutopayPaymentMethodInput = {
  accountId: Scalars['ID'];
  currentPaymentMethodId?: InputMaybe<Scalars['ID']>;
  newAutopayPaymentId?: InputMaybe<Scalars['ID']>;
  termsAndConditionsConfirmed?: InputMaybe<Scalars['Boolean']>;
};

export type AccountBillingOverview = {
  __typename?: 'AccountBillingOverview';
  accountId: Scalars['ID'];
  amountDue: Scalars['Float'];
  balance?: Maybe<Scalars['Float']>;
  billingCycleFrequency?: Maybe<BillingFrequency>;
  center: Center;
  centerId: Scalars['ID'];
  dueDate?: Maybe<Scalars['Date']>;
  gap?: Maybe<Scalars['Float']>;
  hasAutoPay: Scalars['Boolean'];
  isBillingCycleEnabled: Scalars['Boolean'];
  isItemizedBillsPaused: Scalars['Boolean'];
  name: Scalars['String'];
  newCharges?: Maybe<Scalars['Float']>;
  pendingBalance?: Maybe<Scalars['Float']>;
  periodBalances?: Maybe<PeriodBalances>;
  previousBalance?: Maybe<Scalars['Float']>;
  tagIds: Array<Scalars['ID']>;
  transactionLimit?: Maybe<Scalars['Float']>;
};

export type AccountCenter = {
  __typename?: 'AccountCenter';
  entityId: Scalars['ID'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type AccountChild = {
  __typename?: 'AccountChild';
  account?: Maybe<Account>;
  accountChildId: Scalars['ID'];
  accountId: Scalars['ID'];
  archivedAt?: Maybe<Scalars['String']>;
  avatar?: Maybe<S3SignedUrl>;
  ccssAttributes?: Maybe<ChildCcssAttributes>;
  contracts?: Maybe<Array<Maybe<Contract>>>;
  dob: Scalars['String'];
  firstname: Scalars['String'];
  fullName: Scalars['String'];
  /** NOTE: this id is the ID of the child record, not the AccountChild record */
  id: Scalars['ID'];
  lastname: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  status?: Maybe<AccountChildStatusType>;
  tags?: Maybe<Array<Tag>>;
};

export type AccountChildContactInput = {
  contactId: Scalars['ID'];
  permissions: Array<Scalars['String']>;
  relationship: RelationshipEnum;
};

export type AccountChildContactRelationship = {
  __typename?: 'AccountChildContactRelationship';
  accountChildId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  relationshipType?: Maybe<RelationshipType>;
};

export enum AccountChildStatusType {
  Active = 'Active',
  Inactive = 'Inactive',
  Prospective = 'Prospective',
}

export type AccountContactChildInput = {
  childId: Scalars['ID'];
  permissions: Array<Scalars['String']>;
  relationship: RelationshipEnum;
};

export type AccountContactPermissionInput = {
  type: AccountContactPermissionType;
};

export type AccountContactPermissionLabelValue = {
  __typename?: 'AccountContactPermissionLabelValue';
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum AccountContactPermissionType {
  CanPayBill = 'CanPayBill',
  CanViewActivityFeedAndGallery = 'CanViewActivityFeedAndGallery',
  CanViewComms = 'CanViewComms',
}

export type AccountDeposit = {
  __typename?: 'AccountDeposit';
  amount: Scalars['Float'];
  childId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  createdByPerson?: Maybe<Person>;
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['ID']>;
};

export type AccountDepositSummary = {
  __typename?: 'AccountDepositSummary';
  amount: Scalars['Float'];
  childId?: Maybe<Scalars['ID']>;
};

export type AccountDepositSummaryInput = {
  amount: Scalars['Float'];
  childId?: InputMaybe<Scalars['ID']>;
};

export type AccountDepositsBalance = {
  __typename?: 'AccountDepositsBalance';
  accountId: Scalars['ID'];
  balance?: Maybe<Scalars['Float']>;
  center: AccountCenter;
  centerId: Scalars['ID'];
  childIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  children?: Maybe<ChildrenHasDeposits>;
  name: Scalars['String'];
  tags: Array<Tag>;
};

export type AccountDepositsBalancePagedResult = {
  __typename?: 'AccountDepositsBalancePagedResult';
  data: Array<AccountDepositsBalance>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type AccountGapAndBalances = {
  __typename?: 'AccountGapAndBalances';
  accountId: Scalars['ID'];
  balance?: Maybe<Scalars['Float']>;
  center: AccountCenter;
  centerId: Scalars['ID'];
  gap?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type AccountGapAndBalancesPagedResult = {
  __typename?: 'AccountGapAndBalancesPagedResult';
  data: Array<AccountGapAndBalances>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type AccountPaymentMethodInput = {
  accountId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
};

export type AccountPaymentMethodSetting = {
  __typename?: 'AccountPaymentMethodSetting';
  accountId: Scalars['ID'];
  autoPayPaymentMethodId?: Maybe<Scalars['ID']>;
  primaryPaymentMethodId?: Maybe<Scalars['ID']>;
};

export type AccountPermissions = {
  __typename?: 'AccountPermissions';
  account?: Maybe<Account>;
  accountId: Scalars['ID'];
  centerId?: Maybe<Scalars['ID']>;
  childId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  isPrimary: Scalars['Boolean'];
  permissions: Array<Scalars['String']>;
};

export type AccountPosition = {
  __typename?: 'AccountPosition';
  accountId?: Maybe<Scalars['ID']>;
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
};

export type AccountSearchInput = {
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  centreTagIds?: InputMaybe<Array<Scalars['ID']>>;
  from: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSort>>;
  statusAtDate?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<AccountStatusType>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AccountSearchResults = {
  __typename?: 'AccountSearchResults';
  data: Array<Account>;
  totalResults: Scalars['Int'];
};

export enum AccountStatusType {
  Active = 'Active',
  Future = 'Future',
  Inactive = 'Inactive',
}

export type AccountingPeriod = {
  __typename?: 'AccountingPeriod';
  businessId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdByPerson?: Maybe<Staff>;
  endDate: Scalars['Date'];
  history?: Maybe<Array<Maybe<AccountingPeriod>>>;
  id: Scalars['ID'];
  open?: Maybe<Scalars['Boolean']>;
  startDate: Scalars['Date'];
};

export type AccountingPeriodsPagedResult = {
  __typename?: 'AccountingPeriodsPagedResult';
  data: Array<AccountingPeriod>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type AccountsBillingOverviewPagedResult = {
  __typename?: 'AccountsBillingOverviewPagedResult';
  data: Array<AccountBillingOverview>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum AccsApplicationType {
  Appl = 'APPL',
  Cert = 'CERT',
  Noactn = 'NOACTN',
}

export type AccsCertDert = {
  __typename?: 'AccsCertDert';
  accountName: Scalars['String'];
  accsId: Scalars['String'];
  category: AccsDisplayCategory;
  centerName: Scalars['String'];
  certDertType: CertDertType;
  certificateStatus?: Maybe<Scalars['String']>;
  child: Child;
  childId: Scalars['String'];
  childName: Scalars['String'];
  determinationStatus?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  evidenceHeld: Scalars['Boolean'];
  id: Scalars['String'];
  riskReasons?: Maybe<Array<Scalars['String']>>;
  startDate: Scalars['String'];
  stateTerritoryBody?: Maybe<StateTerritoryBody>;
  weeksAtRisk: Scalars['Int'];
};

export type AccsDisplayCategoriesCount = {
  __typename?: 'AccsDisplayCategoriesCount';
  active: Scalars['Int'];
  expired: Scalars['Int'];
  expiring: Scalars['Int'];
  pending: Scalars['Int'];
};

export type AccsDisplayCategoriesInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
};

export enum AccsDisplayCategory {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Expiring = 'EXPIRING',
  Pending = 'PENDING',
}

export type AccsReportInput = {
  centerIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AccsServiceMetric = {
  __typename?: 'AccsServiceMetric';
  applicationType: AccsApplicationType;
  centerId: Scalars['String'];
  centerName: Scalars['String'];
  id: Scalars['ID'];
  lastUpdated: Scalars['DateTime'];
  numberOfChildrenAtRisk: Scalars['Int'];
  percentageCap: Scalars['Float'];
  percentageOfChildrenAtRisk: Scalars['Float'];
};

export type AcquittalDto = {
  __typename?: 'AcquittalDto';
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  messages: Array<MessageDto>;
};

export type AcssCertDertPagedResult = {
  __typename?: 'AcssCertDertPagedResult';
  data: Array<AccsCertDert>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum ActivityFeedReleaseSchedule {
  Immediately = 'Immediately',
  OnCheckout = 'OnCheckout',
}

export type ActivityPerson = {
  __typename?: 'ActivityPerson';
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  profileUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum ActivityType {
  ChannelJoined = 'ChannelJoined',
  ChannelLeft = 'ChannelLeft',
  FlaggedMessageResolved = 'FlaggedMessageResolved',
  MessageAcknowledged = 'MessageAcknowledged',
  MessageDeleted = 'MessageDeleted',
  MessageEdited = 'MessageEdited',
  MessageFailed = 'MessageFailed',
  MessageFlagged = 'MessageFlagged',
  MessageSent = 'MessageSent',
}

export type AddCasualBookingExclusionsInput = {
  accountIds: Array<Scalars['ID']>;
  reason: Scalars['String'];
};

export type AddCcssApprovalInput = {
  careType: Scalars['String'];
  centerId: Scalars['ID'];
  serviceId: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type AddCcssProviderNotificationInput = {
  businessId: Scalars['ID'];
  notification: Scalars['String'];
  notificationDate: Scalars['DateTime'];
  providerId: Scalars['ID'];
};

export type AddCenterToAccountInput = {
  accountId: Scalars['String'];
  centerId: Scalars['String'];
};

export type AddChildCcsEnrolmentInput = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  casualSessionReporting?: InputMaybe<Scalars['Boolean']>;
  ccsType: ArrangementType;
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  childInStateCare: Scalars['Boolean'];
  contactId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  lateSubmissionReason?: InputMaybe<Scalars['String']>;
  reasonForPea?: InputMaybe<PeaReason>;
  routineSessionReporting?: InputMaybe<Scalars['Boolean']>;
  signingParty: SigningParty;
  startDate: Scalars['Date'];
};

export type AddChildToAccountInput = {
  accountId: Scalars['String'];
  child: CreateChildOnAccountInput;
  childContacts?: InputMaybe<Array<AccountChildContactInput>>;
  childId?: InputMaybe<Scalars['String']>;
};

export type AddEducationPermissionToGroupInput = {
  groupId: Scalars['String'];
  permissionId: Scalars['String'];
};

export type AddExistingChildToExistingAccountInput = {
  accountId: Scalars['ID'];
  childId: Scalars['ID'];
  relationships: Array<AccountChildContactInput>;
};

export type AddNewContactToAccountInput = {
  accountId: Scalars['ID'];
  children: Array<AccountContactChildInput>;
  contact?: InputMaybe<CreateContactInput>;
  existingContactId?: InputMaybe<Scalars['ID']>;
  isPrimary: Scalars['Boolean'];
  permissions?: InputMaybe<Array<AccountContactPermissionInput>>;
  sendInvite?: InputMaybe<Scalars['Boolean']>;
};

export type AddResourceInput = {
  mimeType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerGroupId: Scalars['String'];
  parentFolderId: Scalars['String'];
  readerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resourceId: Scalars['String'];
  updaterGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AdditionalAbsenceMessageRecord = {
  absenceDocumentHeld?: InputMaybe<Scalars['Boolean']>;
  absenceInfo?: InputMaybe<AbsenceInfo>;
  reason?: InputMaybe<AbsenceReason>;
};

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Country;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressDiff = {
  __typename?: 'AddressDiff';
  diffCount: Scalars['Int'];
  diffState: DiffState;
  systemFields: Array<DiffDto>;
};

export type AddressInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country: Country;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type Adjustment = {
  __typename?: 'Adjustment';
  archivedAt?: Maybe<Scalars['String']>;
  businessId: Scalars['ID'];
  glCode: Scalars['String'];
  glCodeMapping?: Maybe<GlCodeMapping>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: AdjustmentType;
};

export enum AdjustmentType {
  Fee = 'FEE',
  Subsidy = 'SUBSIDY',
}

export type AdviseChildNoLongerAtRisk = {
  __typename?: 'AdviseChildNoLongerAtRisk';
  certficate?: Maybe<CcssCertificate>;
  determination?: Maybe<CcssDetermination>;
};

export type AdviseChildNoLongerAtRiskInput = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  certificateId?: InputMaybe<Scalars['ID']>;
  dateNoLongerAtRisk: Scalars['Date'];
  determinationId?: InputMaybe<Scalars['ID']>;
  isDeclarationGiven: Scalars['Boolean'];
  noLongerAtRiskReason: Scalars['String'];
  recordToSupportNoLongerAtRisk: Scalars['Boolean'];
  supportingDocuments?: InputMaybe<Array<SupportingDocumentInput>>;
};

export type AgeRange = {
  __typename?: 'AgeRange';
  end: Scalars['Int'];
  endUOM?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  start: Scalars['Int'];
  startUOM?: Maybe<Scalars['String']>;
};

export type AgeRangeOutcome = {
  __typename?: 'AgeRangeOutcome';
  ageRangeId: Scalars['ID'];
  id: Scalars['ID'];
  outcomes: Array<Outcome>;
  subDomainId: Scalars['ID'];
};

export type Agency = {
  __typename?: 'Agency';
  absentDay?: Maybe<Scalars['String']>;
  agencyLinks?: Maybe<Array<Maybe<AgencyLink>>>;
  archivedAt?: Maybe<Scalars['String']>;
  assignedAccountChildIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attendanceRequirement: Scalars['String'];
  billingCalculationType: BillingCalculationType;
  businessId: Scalars['ID'];
  centerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  county?: Maybe<Scalars['String']>;
  glCodeMapping?: Maybe<GlCodeMapping>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['Phone']>;
  reimbursementSchedule?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  subsidyPeriod: SubsidyPaymentPeriodType;
};

export type AgencyAccountChild = {
  __typename?: 'AgencyAccountChild';
  accountChildId: Scalars['ID'];
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  agency: Agency;
  agencyAccountChildEnrollments: Array<AgencyAccountChildEnrollment>;
  agencyId: Scalars['ID'];
  agencyName: Scalars['String'];
  archivedAt?: Maybe<Scalars['Date']>;
  avatar?: Maybe<S3SignedUrl>;
  billingCalculationType: BillingCalculationType;
  centerName: Scalars['String'];
  childFirstName: Scalars['String'];
  childLastName: Scalars['String'];
  id: Scalars['ID'];
  status: AgencyEnrollmentStatusType;
  subsidyPeriod: SubsidyPaymentPeriodType;
};

export type AgencyAccountChildEnrollment = {
  __typename?: 'AgencyAccountChildEnrollment';
  childSubsidyEnrollmentId?: Maybe<Scalars['String']>;
  endDate: Scalars['Date'];
  excludedContractIds?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  ignoreCopay: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  status?: Maybe<AgencyEnrollmentStatusType>;
  subsidyPaymentPeriodType?: Maybe<SubsidyPaymentPeriodType>;
  subsidyPaymentPortion: Scalars['Float'];
};

export type AgencyAccountChildPagedResult = {
  __typename?: 'AgencyAccountChildPagedResult';
  data: Array<AgencyAccountChild>;
  metaData?: Maybe<AgencyAccountChildrenMetaData>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type AgencyAccountChildrenMetaData = {
  __typename?: 'AgencyAccountChildrenMetaData';
  numberOfActive: Scalars['Int'];
  numberOfExpired: Scalars['Int'];
  numberOfExpiringSoon: Scalars['Int'];
  numberOfFuture: Scalars['Int'];
};

export enum AgencyEnrollmentStatusType {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Expired = 'EXPIRED',
  ExpiringSoon = 'EXPIRING_SOON',
  Future = 'FUTURE',
}

export type AgencyLink = {
  __typename?: 'AgencyLink';
  id: Scalars['ID'];
  link: Scalars['String'];
};

export type AgencyPagedResult = {
  __typename?: 'AgencyPagedResult';
  data: Array<Agency>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type AgencyPayment = {
  __typename?: 'AgencyPayment';
  agencyId: Scalars['ID'];
  agencyName: Scalars['String'];
  amountDifference: Scalars['Float'];
  amountExpected: Scalars['Float'];
  amountPaid: Scalars['Float'];
  avatar?: Maybe<S3SignedUrl>;
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  checkNumber?: Maybe<Scalars['String']>;
  childAgencyPayments?: Maybe<Array<Maybe<ChildAgencyPayment>>>;
  endDate: Scalars['Date'];
  id: Scalars['ID'];
  isAdjusted?: Maybe<Scalars['Boolean']>;
  startDate: Scalars['Date'];
  submittedBy?: Maybe<Scalars['ID']>;
  submittedByPerson?: Maybe<Person>;
};

export type AgencyPaymentPagedResult = {
  __typename?: 'AgencyPaymentPagedResult';
  data: Array<AgencyPayment>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type AgencyPaymentResult = {
  __typename?: 'AgencyPaymentResult';
  completed: AgencyPaymentPagedResult;
  draft: AgencyPaymentPagedResult;
};

export type AllDocuments = {
  __typename?: 'AllDocuments';
  data?: Maybe<Array<Maybe<CustomFieldDocumentValuesResponse>>>;
};

export type Allergen = {
  __typename?: 'Allergen';
  archivedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: HealthTerm;
  typeId: Scalars['ID'];
};

export enum AllergenSeverity {
  Mild = 'Mild',
  Moderate = 'Moderate',
  NotApplicable = 'NotApplicable',
  Severe = 'Severe',
}

export type Allergy = {
  __typename?: 'Allergy';
  allergen: Allergen;
  allergenId: Scalars['ID'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedByPerson?: Maybe<Staff>;
  childId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByPerson?: Maybe<Staff>;
  documents: Array<WellnessItemDocument>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  isRevised?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['ID']>;
  /** if the severity is Mild, reaction and instructions are optional */
  reactions?: Maybe<Array<Scalars['String']>>;
  severity: AllergenSeverity;
};

export type AllergyInput = {
  allergenId: Scalars['ID'];
  instructions?: InputMaybe<Scalars['String']>;
  /** if the severity is Mild, reactions and instructions are optional */
  reactions?: InputMaybe<Array<Scalars['String']>>;
  severity: AllergenSeverity;
};

export type AllergyRevision = {
  __typename?: 'AllergyRevision';
  allergyVersion?: Maybe<Allergy>;
  documentsChanged?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  reactionsChanged?: Maybe<Scalars['Boolean']>;
  revisedAt?: Maybe<Scalars['DateTime']>;
  revisedByPerson?: Maybe<Staff>;
  severity?: Maybe<AllergenSeverity>;
};

export enum AltArrangementType {
  P2P = 'P2P',
  Whs = 'WHS',
}

export type AlterChildTag = {
  childId: Scalars['ID'];
  photoId: Scalars['ID'];
};

export type AlternateArangementCreate = {
  __typename?: 'AlternateArangementCreate';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type AlternateArrangement = {
  __typename?: 'AlternateArrangement';
  dateSubmitted?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  offsetPercentage?: Maybe<Scalars['Float']>;
  paymentPeriod?: Maybe<Scalars['String']>;
  providerId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  status: Scalars['String'];
  totalAmount: Scalars['Float'];
  type: AltArrangementType;
};

export type Ancestor = {
  __typename?: 'Ancestor';
  depth?: Maybe<Scalars['Int']>;
  folderId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Announcement = {
  __typename?: 'Announcement';
  centerId: Scalars['ID'];
  emergency?: Maybe<Scalars['Boolean']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
};

export type ApiKeyWebHook = {
  __typename?: 'ApiKeyWebHook';
  apiKeyId?: Maybe<Scalars['ID']>;
  businessId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  lastModifiedAt?: Maybe<Scalars['String']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  webHookApiKey: Scalars['String'];
  webHookType?: Maybe<Scalars['String']>;
};

export enum ApiScopeAuthType {
  None = 'None',
  Read = 'Read',
  Write = 'Write',
}

export type ApiScopesType = {
  __typename?: 'ApiScopesType';
  accounts?: Maybe<ApiScopeAuthType>;
  applications?: Maybe<ApiScopeAuthType>;
  attendances?: Maybe<ApiScopeAuthType>;
  business?: Maybe<ApiScopeAuthType>;
  centers?: Maybe<ApiScopeAuthType>;
  children?: Maybe<ApiScopeAuthType>;
  classes?: Maybe<ApiScopeAuthType>;
  contacts?: Maybe<ApiScopeAuthType>;
  deposits?: Maybe<ApiScopeAuthType>;
  entitlements?: Maybe<ApiScopeAuthType>;
  fees?: Maybe<ApiScopeAuthType>;
  sessions?: Maybe<ApiScopeAuthType>;
  staff?: Maybe<ApiScopeAuthType>;
  transactions?: Maybe<ApiScopeAuthType>;
};

export type ApiScopesTypeInput = {
  accounts?: InputMaybe<ApiScopeAuthType>;
  applications?: InputMaybe<ApiScopeAuthType>;
  attendances?: InputMaybe<ApiScopeAuthType>;
  business?: InputMaybe<ApiScopeAuthType>;
  centers?: InputMaybe<ApiScopeAuthType>;
  children?: InputMaybe<ApiScopeAuthType>;
  classes?: InputMaybe<ApiScopeAuthType>;
  contacts?: InputMaybe<ApiScopeAuthType>;
  deposits?: InputMaybe<ApiScopeAuthType>;
  entitlements?: InputMaybe<ApiScopeAuthType>;
  fees?: InputMaybe<ApiScopeAuthType>;
  sessions?: InputMaybe<ApiScopeAuthType>;
  staff?: InputMaybe<ApiScopeAuthType>;
  transactions?: InputMaybe<ApiScopeAuthType>;
};

export type Application = {
  __typename?: 'Application';
  accountId?: Maybe<Scalars['ID']>;
  accountName: Scalars['String'];
  applicationDeposits: Array<ApplicationDeposit>;
  appliedFlowType?: Maybe<ApplicationFlowType>;
  businessId: Scalars['ID'];
  centers: Array<Scalars['ID']>;
  children: Array<ApplicationChild>;
  email: Scalars['String'];
  enquiryDate: Scalars['DateTime'];
  enrollmentSource?: Maybe<EnrolmentSource>;
  familyReceivesSubsidy?: Maybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  notes: Array<Maybe<ApplicationNote>>;
  offers?: Maybe<Array<Maybe<ApplicationOffer>>>;
  phoneNumber: Scalars['String'];
  state: ApplicationState;
  userFlag?: Maybe<UserFlag>;
};

export type ApplicationApproveOffer = {
  __typename?: 'ApplicationApproveOffer';
  isApproved: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  offerId: Scalars['ID'];
};

export type ApplicationCategoryTotals = {
  __typename?: 'ApplicationCategoryTotals';
  ACCEPTED: Scalars['Int'];
  COMPLETED: Scalars['Int'];
  CONTACTED: Scalars['Int'];
  ERRORED: Scalars['Int'];
  LOST: Scalars['Int'];
  NEW: Scalars['Int'];
  OFFERED: Scalars['Int'];
  WAITLISTED: Scalars['Int'];
};

export type ApplicationChild = {
  __typename?: 'ApplicationChild';
  dateOfBirth: Scalars['Date'];
  enquiries: Array<ApplicationScheduleEnquiry>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isNew?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  offers: Array<Maybe<ApplicationScheduleOffer>>;
};

export enum ApplicationDayFilterType {
  Casual = 'CASUAL',
  NumberOfDays = 'NUMBER_OF_DAYS',
  ScheduleIncludes = 'SCHEDULE_INCLUDES',
  ScheduleIsExactly = 'SCHEDULE_IS_EXACTLY',
}

export type ApplicationDaysInput = {
  dayFilterType: ApplicationDayFilterType;
  numberOfDay?: InputMaybe<Scalars['Int']>;
  scheduleValues?: InputMaybe<Array<InputMaybe<DayOfWeek>>>;
};

export type ApplicationDeposit = {
  __typename?: 'ApplicationDeposit';
  amount: Scalars['Float'];
  appliesDate?: Maybe<Scalars['Date']>;
  collected: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum ApplicationFeeType {
  PerApplication = 'PerApplication',
  PerPlace = 'PerPlace',
}

export enum ApplicationFlowType {
  InquireOfferEnrollment = 'INQUIRE_OFFER_ENROLLMENT',
  InquireProgramEnrollmentApprove = 'INQUIRE_PROGRAM_ENROLLMENT_APPROVE',
  InquireProgramFormPayApprove = 'INQUIRE_PROGRAM_FORM_PAY_APPROVE',
}

export type ApplicationMarkLostInput = {
  applicationId: Scalars['ID'];
  businessId: Scalars['ID'];
  scheduleOfferIds: Array<InputMaybe<Scalars['ID']>>;
};

export type ApplicationMultiApproveInput = {
  businessId: Scalars['ID'];
  scheduleOfferIds: Array<Scalars['ID']>;
};

export type ApplicationMultiApproveResponse = {
  __typename?: 'ApplicationMultiApproveResponse';
  applicationId: Scalars['ID'];
  isApproved: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  offers?: Maybe<Array<Maybe<ApplicationApproveOffer>>>;
};

export type ApplicationNote = {
  __typename?: 'ApplicationNote';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['ID']>;
  archivedByName?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  createdByAvatar?: Maybe<S3SignedUrl>;
  createdByName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  lastEditedBy?: Maybe<Scalars['ID']>;
  lastEditedByName?: Maybe<Scalars['String']>;
};

export type ApplicationOffer = {
  __typename?: 'ApplicationOffer';
  adminFeeRequired?: Maybe<Scalars['Boolean']>;
  applicationPaidAmount?: Maybe<Scalars['Float']>;
  applicationPaidDate?: Maybe<Scalars['Date']>;
  applicationPaymentReference?: Maybe<Scalars['String']>;
  applicationScheduleOfferIds?: Maybe<Array<Scalars['ID']>>;
  enrollmentForm?: Maybe<Scalars['String']>;
  enrollmentFormChanged?: Maybe<Scalars['Boolean']>;
  enrolmentFormDataRecordId?: Maybe<Scalars['String']>;
  enrolmentFormPdfLink?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isAllScheduleOffersAcceptInWaitlisted?: Maybe<Scalars['Boolean']>;
  linkCode?: Maybe<Scalars['String']>;
};

export type ApplicationPagedResult = {
  __typename?: 'ApplicationPagedResult';
  data: Array<Application>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
  unreadCount: Scalars['Int'];
};

export type ApplicationScheduleDay = {
  __typename?: 'ApplicationScheduleDay';
  dayOfWeek: Scalars['String'];
  id: Scalars['ID'];
  weekType: Scalars['String'];
};

export type ApplicationScheduleDayInput = {
  dayOfWeek: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  weekType: Scalars['String'];
};

export type ApplicationScheduleEnquiry = {
  __typename?: 'ApplicationScheduleEnquiry';
  careType: Scalars['String'];
  cycleType: Scalars['String'];
  days: Array<ApplicationScheduleDay>;
  id: Scalars['ID'];
  startDate: Scalars['Date'];
};

export type ApplicationScheduleOffer = {
  __typename?: 'ApplicationScheduleOffer';
  applicationOfferId?: Maybe<Scalars['ID']>;
  careType: Scalars['String'];
  class?: Maybe<Class>;
  classId?: Maybe<Scalars['ID']>;
  cycleType?: Maybe<Scalars['String']>;
  days: Array<ApplicationScheduleDay>;
  endDate?: Maybe<Scalars['Date']>;
  fee?: Maybe<Fee>;
  feeAmount?: Maybe<Scalars['Float']>;
  feeId?: Maybe<Scalars['ID']>;
  generationState?: Maybe<ApplicationScheduleOfferGenerationState>;
  id: Scalars['ID'];
  offerCenterId?: Maybe<Scalars['ID']>;
  program?: Maybe<EnrollmentProgram>;
  programId?: Maybe<Scalars['ID']>;
  startDate: Scalars['Date'];
  state?: Maybe<ApplicationScheduleOfferState>;
};

export enum ApplicationScheduleOfferGenerationState {
  Completed = 'COMPLETED',
  GenerateAccount = 'GENERATE_ACCOUNT',
  GenerateAdminFeeTransactions = 'GENERATE_ADMIN_FEE_TRANSACTIONS',
  GenerateAuthorizations = 'GENERATE_AUTHORIZATIONS',
  GenerateBillingCycle = 'GENERATE_BILLING_CYCLE',
  GenerateChildren = 'GENERATE_CHILDREN',
  GenerateContacts = 'GENERATE_CONTACTS',
  GenerateContracts = 'GENERATE_CONTRACTS',
  GenerateCustomDocumentFields = 'GENERATE_CUSTOM_DOCUMENT_FIELDS',
  GenerateCustomFields = 'GENERATE_CUSTOM_FIELDS',
  GenerateDeposit = 'GENERATE_DEPOSIT',
  GenerateImmunizationDocument = 'GENERATE_IMMUNIZATION_DOCUMENT',
  GenerateMedicalDocuments = 'GENERATE_MEDICAL_DOCUMENTS',
  GeneratePaymentMethod = 'GENERATE_PAYMENT_METHOD',
  GeneratePdfForm = 'GENERATE_PDF_FORM',
  GeneratePrimaryContacts = 'GENERATE_PRIMARY_CONTACTS',
  GenerateSystemFields = 'GENERATE_SYSTEM_FIELDS',
  Nothing = 'NOTHING',
}

export enum ApplicationScheduleOfferState {
  Accepted = 'ACCEPTED',
  AcceptedWaitlist = 'ACCEPTED_WAITLIST',
  ApprovalPending = 'APPROVAL_PENDING',
  ApprovalWaitlistPending = 'APPROVAL_WAITLIST_PENDING',
  Completed = 'COMPLETED',
  Creating = 'CREATING',
  DebugQueued = 'DEBUG_QUEUED',
  DebugSubmitted = 'DEBUG_SUBMITTED',
  Deleted = 'DELETED',
  Errored = 'ERRORED',
  Lost = 'LOST',
  New = 'NEW',
  OfferedWaitlist = 'OFFERED_WAITLIST',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
}

export type ApplicationSetting = {
  __typename?: 'ApplicationSetting';
  businessId: Scalars['String'];
  flowType: ApplicationFlowType;
  isUsePerProgramGroupFees?: Maybe<Scalars['Boolean']>;
  isUseProgramGroup?: Maybe<Scalars['Boolean']>;
  offersExpireInDays: Scalars['Int'];
};

export enum ApplicationStage {
  Accepted = 'Accepted',
  Completed = 'Completed',
  Errored = 'Errored',
  InProgress = 'InProgress',
  Lost = 'Lost',
  Offered = 'Offered',
  Waitlisted = 'Waitlisted',
}

export type ApplicationStageAggregationItem = {
  __typename?: 'ApplicationStageAggregationItem';
  count: Scalars['Int'];
  stage: ApplicationStage;
  userFlag?: Maybe<UserFlag>;
};

export enum ApplicationState {
  Accepted = 'ACCEPTED',
  AcceptedOfferPendingPayment = 'ACCEPTED_OFFER_PENDING_PAYMENT',
  ApplicantAcceptedOffer = 'APPLICANT_ACCEPTED_OFFER',
  ApplicantInitiated = 'APPLICANT_INITIATED',
  ApprovalPending = 'APPROVAL_PENDING',
  Completed = 'COMPLETED',
  Contacted = 'CONTACTED',
  Errored = 'ERRORED',
  Lost = 'LOST',
  None = 'NONE',
  Offered = 'OFFERED',
  OfferedPendingAcceptance = 'OFFERED_PENDING_ACCEPTANCE',
  OnWaitlist = 'ON_WAITLIST',
  Partial = 'PARTIAL',
  ReadyForGeneration = 'READY_FOR_GENERATION',
  Submitted = 'SUBMITTED',
}

export type AppliedAccountDiscount = {
  __typename?: 'AppliedAccountDiscount';
  accountChildId?: Maybe<Scalars['ID']>;
  accountId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy: Scalars['ID'];
  createdByPerson?: Maybe<Person>;
  discountAuth?: Maybe<DiscountAuthType>;
  discountId: Scalars['ID'];
  discountName: Scalars['String'];
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  startDate: Scalars['Date'];
};

export type ApplyDiscountToTransactionInput = {
  discountId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type ApproveStaffInput = {
  sendInvitation?: InputMaybe<Scalars['Boolean']>;
  staff?: InputMaybe<ApprovedStaffFields>;
};

export type ApprovedStaffFields = {
  employmentStartDate?: InputMaybe<Scalars['DateTime']>;
  firstname: Scalars['String'];
  id: Scalars['ID'];
  lastname: Scalars['String'];
  position: CreatePositionInput;
  roleship: CreateStaffRoleshipInput;
};

export type ArchiveAgencyEnrollmentInput = {
  id: Scalars['ID'];
};

export type ArchiveBasePositionInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type ArchiveClassInput = {
  id: Scalars['ID'];
};

export type ArchiveCommentInput = {
  CommentId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export type ArchiveGlCodeInput = {
  businessId: Scalars['ID'];
  glCodeId: Scalars['ID'];
};

export type ArchiveNoteInput = {
  areaType: NoteAreas;
  businessId: Scalars['ID'];
  noteId: Scalars['ID'];
};

export type ArchivePtoTypesInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export enum ArrangementType {
  Accs = 'ACCS',
  Cwa = 'CWA',
  Oa = 'OA',
  Pea = 'PEA',
  Ra = 'RA',
}

export type ArrangementTypeDto = {
  __typename?: 'ArrangementTypeDto';
  accountId: Scalars['ID'];
  arrangementType?: Maybe<ArrangementType>;
  category?: Maybe<EnrolmentCategory>;
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  enrolmentId: Scalars['ID'];
  sessionIndicator?: Maybe<SessionIndicator>;
  status?: Maybe<CcsEnrolmentStatus>;
};

export enum AttachedToEntityType {
  Note = 'Note',
}

export type Attendance = {
  __typename?: 'Attendance';
  center: Center;
  centerId: Scalars['ID'];
  days: Array<DailyAttendance>;
  person: Staff;
  personId: Scalars['ID'];
  totalHoursScheduled: TotalTime;
  totalHoursWorked: TotalTime;
};

export type AttendanceBusinessSettings = {
  __typename?: 'AttendanceBusinessSettings';
  centerCount?: Maybe<Scalars['Int']>;
  entityId: Scalars['String'];
  usesAttendanceSettings: Scalars['Boolean'];
  usesTodayScreen: Scalars['Boolean'];
};

export type AttendanceCenterSettings = {
  __typename?: 'AttendanceCenterSettings';
  businessUsesAttendanceSetting: Scalars['Boolean'];
  centerId?: Maybe<Scalars['String']>;
  centerName?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  fromDefault: Scalars['Boolean'];
  usesTodayScreen: Scalars['Boolean'];
};

export type AttendanceOpenSpots = {
  __typename?: 'AttendanceOpenSpots';
  byClass: Array<ClassAttendanceOpenSpots>;
  centerId: Scalars['ID'];
  ratioCompliantOpenSpots: Scalars['Int'];
  totalOpenSpots: Scalars['Float'];
};

export type AttendanceReportDataInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  endDate: Scalars['String'];
  selectedAccountTag?: InputMaybe<Scalars['ID']>;
  selectedChildTag?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['String'];
};

export type AttendanceTimeEntry = {
  __typename?: 'AttendanceTimeEntry';
  contractId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sessionId: Scalars['ID'];
  timeIn: Scalars['String'];
  timeInBy: Person;
  timeInById: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  timeOutBy?: Maybe<Person>;
  timeOutById?: Maybe<Scalars['String']>;
};

export type AttendanceTotalsPerWeekInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  includeNonCcs: Scalars['Boolean'];
  week: Scalars['Date'];
};

export type AuthDto = {
  __typename?: 'AuthDto';
  canDeleteMessage?: Maybe<Scalars['Boolean']>;
  canReportMessage?: Maybe<Scalars['Boolean']>;
  canWriteMessage?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['String']>;
};

export type AuthIds = {
  __typename?: 'AuthIds';
  centerChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AuthToken = {
  __typename?: 'AuthToken';
  authToken?: Maybe<Scalars['String']>;
  expiryDateTimeOffset?: Maybe<Scalars['String']>;
  publishKey?: Maybe<Scalars['String']>;
  subscribeKey?: Maybe<Scalars['String']>;
};

export type AuthenticationIdentityInput = {
  email: Scalars['String'];
};

export type AuthenticationIdentityResponse = {
  __typename?: 'AuthenticationIdentityResponse';
  businessId: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  idpIdentifier: Scalars['String'];
  metadataDocument?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
};

export type BaseItemizedBill = {
  __typename?: 'BaseItemizedBill';
  accountId: Scalars['ID'];
  amount: Scalars['Float'];
  billingCycleId?: Maybe<Scalars['ID']>;
  billingPeriodEndDate: Scalars['Date'];
  billingPeriodStartDate?: Maybe<Scalars['Date']>;
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  createdByPerson?: Maybe<Person>;
  dueOnDate: Scalars['Date'];
  forwardAmount: Scalars['Float'];
  id: Scalars['ID'];
  isCurrentBill: Scalars['Boolean'];
  isManuallyCreated: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  lateOnDate?: Maybe<Scalars['Date']>;
  totalAmount: Scalars['Float'];
  userFriendlyId: Scalars['String'];
};

export type BasicStaff = {
  __typename?: 'BasicStaff';
  avatar?: Maybe<S3SignedUrl>;
  firstname: Scalars['String'];
  fullname: Scalars['String'];
  id: Scalars['ID'];
  lastname: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
};

export type BasicStaffSearchResults = {
  __typename?: 'BasicStaffSearchResults';
  data: Array<BasicStaff>;
  totalResults: Scalars['Int'];
};

export type Batch = {
  __typename?: 'Batch';
  amount: Scalars['Float'];
  batchPaymentPaymentIds: Array<Scalars['ID']>;
  center: Center;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  createdByPerson?: Maybe<Person>;
  documentNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  userFriendlyId: Scalars['String'];
};

export type BatchesPagedResult = {
  __typename?: 'BatchesPagedResult';
  data: Array<Batch>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type BillingBusinessSettings = {
  __typename?: 'BillingBusinessSettings';
  mandatoryGlCodeMapping: Scalars['Boolean'];
  parentPaymentDirection: Scalars['String'];
  transactionAmountDisplay: Scalars['String'];
  transactionAmountThemed: Scalars['Boolean'];
  usMode: Scalars['Boolean'];
  useGlCodes: Scalars['Boolean'];
};

export type BillingBusinessSettingsInput = {
  businessId: Scalars['ID'];
  mandatoryGlCodeMapping?: InputMaybe<Scalars['Boolean']>;
  parentPaymentDirection?: InputMaybe<Scalars['String']>;
  transactionAmountDisplay?: InputMaybe<Scalars['String']>;
  transactionAmountThemed?: InputMaybe<Scalars['Boolean']>;
  useGlCodes?: InputMaybe<Scalars['Boolean']>;
};

export enum BillingCalculationType {
  CopayAmount = 'COPAY_AMOUNT',
  SubsidyAmount = 'SUBSIDY_AMOUNT',
}

export type BillingCycle = {
  __typename?: 'BillingCycle';
  account: Account;
  accountId: Scalars['ID'];
  additionalChargePeriod?: Maybe<BillingTimePeriod>;
  billingCycleTemplateId?: Maybe<Scalars['ID']>;
  billingPeriods: Array<Maybe<BillingPeriodCustom>>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  cycleType: BillingCycleType;
  dayOfMonth?: Maybe<Scalars['Int']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  endDate?: Maybe<Scalars['Date']>;
  frequency: BillingFrequency;
  gracePeriodDays?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoiceSchedule: BillingTimePeriod;
  isPaused: Scalars['Boolean'];
  lateChargeAmount?: Maybe<Scalars['Float']>;
  nextBillingDate: Scalars['Date'];
  nextBillingPeriod?: Maybe<BillingCycleBillingPeriod>;
  nextInvoiceDate?: Maybe<Scalars['Date']>;
  nextPaymentDate: Scalars['Date'];
  period?: Maybe<BillingPeriod>;
  startDate: Scalars['Date'];
  transactionLimit?: Maybe<Scalars['Float']>;
};

export type BillingCycleBillingPeriod = {
  __typename?: 'BillingCycleBillingPeriod';
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type BillingCycleDiff = {
  __typename?: 'BillingCycleDiff';
  diffCount: Scalars['Int'];
  diffState: DiffState;
  systemFields: Array<DiffDto>;
};

export type BillingCycleSchedulePeriod = {
  __typename?: 'BillingCycleSchedulePeriod';
  endDate: Scalars['Date'];
  hasLateFee: Scalars['Boolean'];
  invoiceDate?: Maybe<Scalars['Date']>;
  lateOnDate?: Maybe<Scalars['Date']>;
  paymentDueDate?: Maybe<Scalars['Date']>;
  startDate: Scalars['Date'];
};

export type BillingCycleSchedulePreviewInput = {
  additionalChargePeriod?: InputMaybe<BillingTimePeriodInput>;
  cycleType: BillingCycleType;
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  frequency: BillingFrequency;
  frequencyPayPeriod?: InputMaybe<FrequencyPayPeriod>;
  gracePeriodDays?: InputMaybe<Scalars['Int']>;
  invoiceSchedule: BillingTimePeriodInput;
  period: BillingPeriod;
  scheduleEndDate: Scalars['Date'];
  scheduleStartDate: Scalars['Date'];
};

export type BillingCycleTemplate = {
  __typename?: 'BillingCycleTemplate';
  accountIds: Array<Scalars['ID']>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  additionalChargePeriod?: Maybe<BillingTimePeriod>;
  billingPeriods?: Maybe<Array<Maybe<BillingPeriodCustom>>>;
  business: Entity;
  businessId: Scalars['ID'];
  center?: Maybe<Center>;
  centerId?: Maybe<Scalars['ID']>;
  centerIds: Array<Scalars['ID']>;
  centers: Array<Center>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  cycleType: BillingCycleType;
  dayOfMonth?: Maybe<Scalars['Int']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  frequency: BillingFrequency;
  frequencyPayPeriod?: Maybe<FrequencyPayPeriod>;
  gracePeriodDays?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoiceSchedule: BillingTimePeriod;
  lateChargeAmount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  period?: Maybe<BillingPeriod>;
};

export enum BillingCycleTimePeriodType {
  Days = 'DAYS',
  Months = 'MONTHS',
  Weeks = 'WEEKS',
}

export enum BillingCycleType {
  BalanceBased = 'BALANCE_BASED',
  PeriodBased = 'PERIOD_BASED',
}

export enum BillingFrequency {
  Biweekly = 'BIWEEKLY',
  Custom = 'CUSTOM',
  EveryFourWeeks = 'EVERY_FOUR_WEEKS',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
}

export enum BillingPeriod {
  Advance = 'ADVANCE',
  Arrears = 'ARREARS',
  Current = 'CURRENT',
  CurrentWeek = 'CURRENT_WEEK',
  Custom = 'CUSTOM',
  FourWeeks = 'FOUR_WEEKS',
  Month = 'MONTH',
  PreviousWeek = 'PREVIOUS_WEEK',
  TwoWeeks = 'TWO_WEEKS',
}

export type BillingPeriodCustom = {
  __typename?: 'BillingPeriodCustom';
  endDate?: Maybe<Scalars['Date']>;
  paymentDueDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type BillingPeriodCustomInput = {
  endDate: Scalars['String'];
  paymentDueDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type BillingTimePeriod = {
  __typename?: 'BillingTimePeriod';
  unit: BillingCycleTimePeriodType;
  value: Scalars['Int'];
};

export type BillingTimePeriodInput = {
  unit: BillingCycleTimePeriodType;
  value: Scalars['Int'];
};

export enum BodyType {
  Code01 = 'CODE01',
  Code02 = 'CODE02',
  Code03 = 'CODE03',
  Code04 = 'CODE04',
  Code05 = 'CODE05',
  Code06 = 'CODE06',
  Code07 = 'CODE07',
  Code08 = 'CODE08',
  Code09 = 'CODE09',
  Code10 = 'CODE10',
  Code11 = 'CODE11',
  Code12 = 'CODE12',
}

export type BookingPolicy = {
  __typename?: 'BookingPolicy';
  atLeastInAdvance: BookingWindow;
  atMostInAdvance: BookingWindow;
};

export type BookingPolicyInput = {
  atLeastInAdvance: BookingWindowInput;
  atMostInAdvance: BookingWindowInput;
};

export type BookingWindow = {
  __typename?: 'BookingWindow';
  quantity: Scalars['Int'];
  unit: BookingWindowUnit;
};

export type BookingWindowInput = {
  quantity: Scalars['Int'];
  unit: BookingWindowUnit;
};

export enum BookingWindowUnit {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type BulkAbsenceInfo = {
  contractsForDates?: InputMaybe<BulkContractForDates>;
  sessionIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkAdditionalAbsenceMessageRecords = {
  absenceDocumentHeld?: InputMaybe<Scalars['Boolean']>;
  absenceInfo?: InputMaybe<BulkAbsenceInfo>;
  reason?: InputMaybe<AbsenceReason>;
};

export type BulkContractForDates = {
  absentContractIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  absentDates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkCustodyInput = {
  centerId: Scalars['ID'];
  children: Array<BulkCustodyInputChildren>;
  classId: Scalars['ID'];
  isCustodyTaken: Scalars['Boolean'];
  sessionDate: Scalars['Date'];
  staffId: Scalars['ID'];
};

export type BulkCustodyInputChildren = {
  accountChildId: Scalars['ID'];
  childStatus: Scalars['String'];
  contractId?: InputMaybe<Scalars['ID']>;
  enrolledClassId: Scalars['ID'];
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type BulkCustodyResponse = {
  __typename?: 'BulkCustodyResponse';
  data?: Maybe<Array<Maybe<CustodyChildren>>>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type BulkStatusChangeInput = {
  isApproved: Scalars['Boolean'];
  photoSelectionCriteria: SearchPhotoInput;
};

export type BulletinBoardAuthIds = {
  __typename?: 'BulletinBoardAuthIds';
  bulletinBoardChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BulletinBoardChannelAndAuth = {
  __typename?: 'BulletinBoardChannelAndAuth';
  auth?: Maybe<AuthDto>;
  channelId?: Maybe<Scalars['ID']>;
  channelIdentifier?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
};

export type BusRoster = {
  __typename?: 'BusRoster';
  businessId: Scalars['ID'];
  center?: Maybe<Center>;
  centerId: Scalars['ID'];
  children?: Maybe<Array<Maybe<BusRosterAccountChild>>>;
  destinations: Array<BusRosterDestination>;
  effectiveDate: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: BusRunType;
};

export type BusRosterAccountChild = {
  __typename?: 'BusRosterAccountChild';
  accountChildId: Scalars['ID'];
  avatar?: Maybe<S3SignedUrl>;
  busRosterDestinationId: Scalars['ID'];
  busRosterId: Scalars['ID'];
  destination: BusRosterDestination;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  scheduleSlots?: Maybe<Array<Maybe<BusRosterAccountChildScheduleSlot>>>;
  type: BusRunType;
};

export type BusRosterAccountChildInput = {
  accountChildId: Scalars['ID'];
  destination: Scalars['String'];
  scheduleSlots?: InputMaybe<Array<InputMaybe<BusRosterAccountChildScheduleSlotInput>>>;
  type: BusRunType;
};

export type BusRosterAccountChildScheduleSlot = {
  __typename?: 'BusRosterAccountChildScheduleSlot';
  busRosterAccountChildId: Scalars['ID'];
  day?: Maybe<DayOfWeek>;
  id: Scalars['ID'];
  weekType?: Maybe<WeekType>;
};

export type BusRosterAccountChildScheduleSlotInput = {
  day: DayOfWeek;
  weekType: WeekType;
};

export type BusRosterAvailableAccountChild = {
  __typename?: 'BusRosterAvailableAccountChild';
  childAvatar?: Maybe<S3SignedUrl>;
  childName: Scalars['String'];
  classIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  classNames?: Maybe<Scalars['String']>;
  expectedScheduleSlots?: Maybe<Array<Maybe<BusRosterAvailableAccountChildScheduleSlot>>>;
  id: Scalars['ID'];
};

export type BusRosterAvailableAccountChildScheduleSlot = {
  __typename?: 'BusRosterAvailableAccountChildScheduleSlot';
  days: Array<Maybe<Scalars['String']>>;
  weekType: WeekType;
};

export type BusRosterDestination = {
  __typename?: 'BusRosterDestination';
  busRosterId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BusRosterDestinationInput = {
  name: Scalars['String'];
};

export type BusRosterPagedResponse = {
  __typename?: 'BusRosterPagedResponse';
  data?: Maybe<Array<Maybe<BusRoster>>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum BusRunType {
  Am = 'AM',
  Both = 'BOTH',
  Pm = 'PM',
}

export type BusinessAddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Country;
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type BusinessCentersPagedResult = {
  __typename?: 'BusinessCentersPagedResult';
  items: Array<EducationCenter>;
  numberRequested: Scalars['Int'];
  start: Scalars['Int'];
  totalNumber: Scalars['Int'];
};

export type BusinessConfiguration = {
  __typename?: 'BusinessConfiguration';
  activityFeedReleaseSchedule: ActivityFeedReleaseSchedule;
  businessId: Scalars['ID'];
  educationEnabled: Scalars['Boolean'];
  educationFeatures: Array<EducationFeature>;
  expressCheckinEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  mealTrackingEnabled: Scalars['Boolean'];
  parentContactManagementEnabled: Scalars['Boolean'];
};

export type BusinessCurriculaPagedResult = {
  __typename?: 'BusinessCurriculaPagedResult';
  items: Array<Curriculum>;
  numberRequested: Scalars['Int'];
  start: Scalars['Int'];
  totalNumber: Scalars['Int'];
};

export type BusinessEnrolmentFormAssignment = {
  __typename?: 'BusinessEnrolmentFormAssignment';
  allCentreAssigned?: Maybe<Scalars['Boolean']>;
  centreIdsWithForm?: Maybe<Array<Scalars['String']>>;
};

export type BusinessEntityCounts = {
  __typename?: 'BusinessEntityCounts';
  centers: Scalars['Int'];
  roles: Scalars['Int'];
  staff: Scalars['Int'];
};

export type BusinessFeature = {
  __typename?: 'BusinessFeature';
  businessId: Scalars['ID'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  subFeatures?: Maybe<Array<Maybe<BusinessSubFeature>>>;
  type: BusinessFeatureType;
};

export enum BusinessFeatureType {
  AdpVantage = 'AdpVantage',
  AdpWorkforce = 'AdpWorkforce',
  All = 'All',
  CcrmIntegration = 'CcrmIntegration',
}

export type BusinessSearchTimeCardLockedUntilInput = {
  businessId: Scalars['ID'];
};

export type BusinessStaffPagedResult = {
  __typename?: 'BusinessStaffPagedResult';
  items: Array<EducationStaff>;
  numberRequested: Scalars['Int'];
  start: Scalars['Int'];
  totalNumber: Scalars['Int'];
};

export type BusinessSubFeature = {
  __typename?: 'BusinessSubFeature';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  type: BusinessSubFeatureType;
};

export type BusinessSubFeatureInput = {
  enabled: Scalars['Boolean'];
  type?: InputMaybe<BusinessSubFeatureType>;
};

export enum BusinessSubFeatureType {
  All = 'All',
  TimeAndAttendance = 'TimeAndAttendance',
}

export type CalculateQualifyingRateInput = {
  contractCycleType: ContractCycleType;
  contractTimeslots: Array<InputMaybe<CreateContractTimeslotInput>>;
  date: Scalars['String'];
  feeId: Scalars['String'];
};

export enum CalculationType {
  Flat = 'FLAT',
  Percent = 'PERCENT',
}

export type CancelCcssCertificateInput = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  certificateId: Scalars['ID'];
  isDeclarationGiven: Scalars['Boolean'];
  reasonForCancellation: Scalars['String'];
};

export type CancelReturnFeeReductionInput = {
  returnFeeReductionId: Scalars['ID'];
};

export type CancelTimeOffRequestInput = {
  id: Scalars['ID'];
};

export type CancellationPolicy = {
  __typename?: 'CancellationPolicy';
  casualBooking: BookingWindow;
  permanentBooking: BookingWindow;
};

export type CancellationPolicyInput = {
  casualBooking: BookingWindowInput;
  permanentBooking: BookingWindowInput;
};

export enum CancellationReason {
  ChronicNonAttendance = 'ChronicNonAttendance',
  FailureToComply = 'FailureToComply',
  NonPaymentOfFees = 'NonPaymentOfFees',
  None = 'None',
}

export type CasualBookingCountInput = {
  centerId: Scalars['ID'];
  classIds?: InputMaybe<Array<Scalars['ID']>>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type CasualBookingExclusion = {
  __typename?: 'CasualBookingExclusion';
  account?: Maybe<Account>;
  accountId: Scalars['ID'];
  centerId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  reason?: Maybe<Scalars['String']>;
};

export type CasualBookingExclusionsInput = {
  centerId: Scalars['ID'];
};

export type CasualBookingSettings = {
  __typename?: 'CasualBookingSettings';
  active: Scalars['Boolean'];
  available: Scalars['Boolean'];
  availableReason?: Maybe<Scalars['String']>;
  bookingPolicy?: Maybe<BookingPolicy>;
  cancellationPolicy?: Maybe<CancellationPolicy>;
  classId: Scalars['ID'];
};

export type CasualBookingSettingsInput = {
  active: Scalars['Boolean'];
  bookingPolicy?: InputMaybe<BookingPolicyInput>;
  cancellationPolicy?: InputMaybe<CancellationPolicyInput>;
  classId: Scalars['ID'];
};

export type CcmsApproval = {
  __typename?: 'CcmsApproval';
  approvalId: Scalars['String'];
  id: Scalars['ID'];
  username: Scalars['String'];
};

export type CcmsApprovalCreateInput = {
  approvalId: Scalars['String'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type CcmsApprovalDeleteInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  id: Scalars['ID'];
};

export type CcmsApprovalEditInput = {
  approvalId: Scalars['String'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  id: Scalars['ID'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type CcsAbsences = {
  __typename?: 'CcsAbsences';
  absences: Scalars['Int'];
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  active: Scalars['Boolean'];
  additionalAbsences: Scalars['Int'];
  allowableAbsences: Scalars['Int'];
  category: CcsAbsencesCategory;
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  child: Child;
  childId: Scalars['ID'];
  childName: Scalars['String'];
};

export type CcsAbsencesCategories = {
  __typename?: 'CcsAbsencesCategories';
  atRisk: Scalars['Int'];
  inTheClear: Scalars['Int'];
  nearingLimit: Scalars['Int'];
};

export type CcsAbsencesCategoriesInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
};

export enum CcsAbsencesCategory {
  AtRisk = 'AtRisk',
  InTheClear = 'InTheClear',
  NearingLimit = 'NearingLimit',
}

export type CcsAbsencesInput = {
  account?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  category?: InputMaybe<CcsAbsencesCategory>;
  centerIds: Array<Scalars['ID']>;
  childName?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortDtos?: InputMaybe<Array<CcsAbsencesSortInput>>;
  statusAtDate?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<AccountStatusType>;
};

export type CcsAbsencesPagedResult = {
  __typename?: 'CcsAbsencesPagedResult';
  data: Array<CcsAbsences>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CcsAbsencesSortInput = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type CcsCertificateCategoriesInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
};

export type CcsEnrolment = {
  __typename?: 'CcsEnrolment';
  accountId?: Maybe<Scalars['ID']>;
  accountName?: Maybe<Scalars['String']>;
  arrangementEndDate?: Maybe<Scalars['String']>;
  arrangementStartDate?: Maybe<Scalars['String']>;
  arrangementType?: Maybe<ArrangementType>;
  category?: Maybe<EnrolmentCategory>;
  centerId?: Maybe<Scalars['ID']>;
  centerName?: Maybe<Scalars['String']>;
  child: Child;
  childCrn?: Maybe<Scalars['String']>;
  childDateOfBirth?: Maybe<Scalars['String']>;
  childFirstName?: Maybe<Scalars['String']>;
  childId: Scalars['ID'];
  childInStateCare: Scalars['Boolean'];
  childLastName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  contactOfChild?: Maybe<Contact>;
  created: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  enrolmentId: Scalars['ID'];
  id: Scalars['ID'];
  individualCrn?: Maybe<Scalars['String']>;
  individualDateOfBirth?: Maybe<Scalars['String']>;
  individualFirstName?: Maybe<Scalars['String']>;
  individualLastName?: Maybe<Scalars['String']>;
  lateSubmissionReason?: Maybe<Scalars['String']>;
  occurrenceNumber?: Maybe<Scalars['Int']>;
  previousEnrolments: Array<CcsEnrolment>;
  reasonForPea?: Maybe<PeaReason>;
  recordEffectiveEndDate?: Maybe<Scalars['String']>;
  sessionIndicator?: Maybe<SessionIndicator>;
  signingPartyFirstName?: Maybe<Scalars['String']>;
  signingPartyLastName?: Maybe<Scalars['String']>;
  signingPartyOrganisation?: Maybe<Scalars['String']>;
  status?: Maybe<CcsEnrolmentStatus>;
};

export type CcsEnrolmentCategoryCount = {
  __typename?: 'CcsEnrolmentCategoryCount';
  ActionRequired: Scalars['Int'];
  Active: Scalars['Int'];
  Inactive: Scalars['Int'];
  Pending: Scalars['Int'];
};

export type CcsEnrolmentPagedResult = {
  __typename?: 'CcsEnrolmentPagedResult';
  data: Array<CcsEnrolment>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CcsEnrolmentReportInput = {
  accountStatusAtDate?: InputMaybe<Scalars['String']>;
  accountStatusTypes?: InputMaybe<Scalars['String']>;
  ccsTypes?: InputMaybe<Array<Scalars['String']>>;
  centerIds?: InputMaybe<Array<Scalars['String']>>;
  enrolmentCategories?: InputMaybe<Array<Scalars['String']>>;
  enrolmentStatuses?: InputMaybe<Array<Scalars['String']>>;
};

export enum CcsEnrolmentStatus {
  Approv = 'APPROV',
  Ceased = 'CEASED',
  Confir = 'CONFIR',
  Disput = 'DISPUT',
  Manual = 'MANUAL',
  Notapp = 'NOTAPP',
  Pendel = 'PENDEL',
  Pendin = 'PENDIN',
  Receiv = 'RECEIV',
  Reject = 'REJECT',
  Withdr = 'WITHDR',
}

export type CcsEntitlement = {
  __typename?: 'CcsEntitlement';
  absenceCount: Scalars['Int'];
  absencesAvailableNoEvidence: Scalars['Int'];
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  accsHourlyRateCapIncreasePercentage: Scalars['Float'];
  apportionedHoursPerFortnight: Scalars['Int'];
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  child: Child;
  childId: Scalars['ID'];
  childName: Scalars['String'];
  date: Scalars['Date'];
  enrolmentId: Scalars['String'];
  id: Scalars['ID'];
  paidAbsences: Scalars['Int'];
  percentage: Scalars['Float'];
  preschoolExemption: Scalars['Boolean'];
  previousEntitlement?: Maybe<CcsEntitlement>;
  totalHoursPerFortnight: Scalars['Int'];
  unpaidAbsences: Scalars['Int'];
  withholdingPercentage: Scalars['Float'];
};

export type CcsEntitlementChangeHistory = {
  __typename?: 'CcsEntitlementChangeHistory';
  date: Scalars['Date'];
  percentage: Scalars['Float'];
  totalHoursPerFortnight: Scalars['Int'];
};

export type CcsEntitlementChangeHistoryInput = {
  businessId: Scalars['ID'];
  enrollmentId: Scalars['ID'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type CcsEntitlementChangeHistoryPagedResult = {
  __typename?: 'CcsEntitlementChangeHistoryPagedResult';
  data: Array<CcsEntitlementChangeHistory>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CcsEntitlementInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  changedSinceDays?: InputMaybe<Scalars['Int']>;
  childName?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortDtos?: InputMaybe<Array<CcsEntitlementSortInput>>;
  statusAtDate?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<AccountStatusType>;
};

export type CcsEntitlementPagedResult = {
  __typename?: 'CcsEntitlementPagedResult';
  data: Array<CcsEntitlement>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CcsEntitlementSortInput = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type CcsEntitlementsReportInput = {
  accountStatus?: InputMaybe<AccountStatusType>;
  centerIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CcsOutstandingReportInput = {
  accountStatus?: InputMaybe<AccountStatusType>;
  businessId: Scalars['ID'];
  centerIds: Array<InputMaybe<Scalars['ID']>>;
  dateType: TransactionDate;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type CcsSubsidyPaymentReportInput = {
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  businessId: Scalars['ID'];
  centerIds: Array<InputMaybe<Scalars['ID']>>;
  childIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type CcsSubsidyRemittanceReportInput = {
  businessId: Scalars['ID'];
  centerIds: Array<InputMaybe<Scalars['ID']>>;
  clearingNumber?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type CcssApproval = {
  __typename?: 'CcssApproval';
  careType: Scalars['String'];
  id: Scalars['ID'];
  serviceId: Scalars['String'];
  serviceName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type CcssApprovalId = {
  __typename?: 'CcssApprovalId';
  id: Scalars['ID'];
};

export type CcssCertificate = {
  __typename?: 'CcssCertificate';
  category: AccsDisplayCategory;
  centerName: Scalars['String'];
  certificateId: Scalars['String'];
  child: Child;
  childId: Scalars['ID'];
  childName: Scalars['String'];
  decisionMakingAgency?: Maybe<DecisionMakingAgency>;
  endDate: Scalars['Date'];
  evidenceHeld: Scalars['Boolean'];
  exceptionalCircumstanceReason?: Maybe<ExceptionalCircumstanceReason>;
  exceptionalCircumstanceText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  previousCertificates: Array<CcssCertificate>;
  recordStatus?: Maybe<RecordStatus>;
  riskReasons?: Maybe<Array<RiskReason>>;
  startDate: Scalars['Date'];
  stateTerritoryBody?: Maybe<StateTerritoryBody>;
  status: CertificateStatus;
  weeksAtRisk: Scalars['Int'];
};

export type CcssCertificatePagedResult = {
  __typename?: 'CcssCertificatePagedResult';
  data: Array<CcssCertificate>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CcssCorrespondenceFileContent = {
  __typename?: 'CcssCorrespondenceFileContent';
  content: Scalars['String'];
};

export type CcssDetermination = {
  __typename?: 'CcssDetermination';
  category: AccsDisplayCategory;
  child: Child;
  childId: Scalars['ID'];
  determinationId: Scalars['String'];
  endDate: Scalars['Date'];
  exceptionalCircumstanceReason?: Maybe<ExceptionalCircumstanceReason>;
  exceptionalCircumstanceText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  previousDeterminations?: Maybe<Array<CcssDetermination>>;
  riskReasons?: Maybe<Array<RiskReason>>;
  startDate: Scalars['Date'];
  stateTerritoryBody?: Maybe<StateTerritoryBody>;
  status?: Maybe<DeterminationStatus>;
  weeksAtRisk: Scalars['Int'];
};

export type CcssEvent = {
  __typename?: 'CcssEvent';
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  eventId: Scalars['ID'];
  id: Scalars['ID'];
  recipientType: Scalars['String'];
  specialAbsences?: Maybe<Array<EventSpecialAbsence>>;
  specialConditions?: Maybe<Array<EventSpecialCondition>>;
  startDate: Scalars['Date'];
  subType?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CcssEventSortInput = {
  direction?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
};

export enum CcssEventType {
  Le = 'LE',
  Mr = 'MR',
}

export enum CcssMessageNotificationType {
  Correspondence = 'CORRESPONDENCE',
  Error = 'ERROR',
  Immed = 'IMMED',
  Info = 'INFO',
  Warnig = 'WARNIG',
}

export enum CcssMessageSourceType {
  Accscw = 'ACCSCW',
  Acctmg = 'ACCTMG',
  Carepv = 'CAREPV',
  Debtmg = 'DEBTMG',
  Enrcrt = 'ENRCRT',
  Enrupd = 'ENRUPD',
  Enttlm = 'ENTTLM',
  Fdcexm = 'FDCEXM',
  Paymnt = 'PAYMNT',
  Rtnfee = 'RTNFEE',
  Ssrcrt = 'SSRCRT',
  Ssrupd = 'SSRUPD',
}

export enum CcssMessageType {
  Correspondence = 'CORRESPONDENCE',
  Notification = 'NOTIFICATION',
}

export enum CcssMessageViewedStatus {
  Archived = 'ARCHIVED',
  Read = 'READ',
  Unread = 'UNREAD',
}

export type CcssMessagesSortInput = {
  direction?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
};

export type CcssPersonnel = {
  __typename?: 'CcssPersonnel';
  conflictsOfInterest?: Maybe<Array<PersonnelConflictOfInterest>>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  employedInServices?: Maybe<Array<PersonnelService>>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  operationOfServices?: Maybe<Array<PersonnelService>>;
  otherEnterprises?: Maybe<Array<PersonnelOtherEnterprise>>;
  personId: Scalars['ID'];
  providerRoles?: Maybe<Array<Maybe<CcssProviderRole>>>;
  serviceRoles?: Maybe<Array<Maybe<CcssServiceRole>>>;
  workingWithChildrenChecks?: Maybe<Array<PersonnelWwcc>>;
};

export type CcssPersonnelInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  notificationOfSuitability?: InputMaybe<NotificationOfSuitability>;
  personId?: InputMaybe<Scalars['ID']>;
};

export type CcssPersonnelPagedResult = {
  __typename?: 'CcssPersonnelPagedResult';
  data: Array<CcssPersonnel>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CcssProviderCorrespondenceInput = {
  businessId: Scalars['ID'];
  correspondenceId: Scalars['ID'];
};

export type CcssProviderMessage = {
  __typename?: 'CcssProviderMessage';
  body?: Maybe<Scalars['String']>;
  businessId: Scalars['ID'];
  businessName: Scalars['String'];
  created: Scalars['String'];
  id: Scalars['ID'];
  messageType: CcssMessageType;
  source?: Maybe<CcssMessageSourceType>;
  sourceId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type: CcssMessageNotificationType;
  viewedStatus: CcssMessageViewedStatus;
};

export type CcssProviderMessageUpdateViewedStatusInput = {
  businessId: Scalars['ID'];
  messageId: Scalars['ID'];
  messageType: CcssMessageType;
  viewedStatus: CcssMessageViewedStatus;
};

export type CcssProviderMessagesInput = {
  businessId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['String']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<CcssMessagesSortInput>>;
  sources?: InputMaybe<Array<CcssMessageSourceType>>;
  startDate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CcssMessageNotificationType>;
  viewedStatus?: InputMaybe<CcssMessageViewedStatus>;
};

export type CcssProviderMessagesPagedResult = {
  __typename?: 'CcssProviderMessagesPagedResult';
  data: Array<CcssProviderMessage>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CcssProviderRole = CcssRole & {
  __typename?: 'CcssProviderRole';
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  position?: Maybe<Scalars['String']>;
  positionText?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type CcssRole = {
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  position?: Maybe<Scalars['String']>;
  positionText?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type CcssServiceCorrespondenceInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  correspondenceId: Scalars['ID'];
};

export type CcssServiceMessage = {
  __typename?: 'CcssServiceMessage';
  body?: Maybe<Scalars['String']>;
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  created: Scalars['String'];
  id: Scalars['ID'];
  messageType: CcssMessageType;
  source?: Maybe<CcssMessageSourceType>;
  sourceId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type: CcssMessageNotificationType;
  viewedStatus: CcssMessageViewedStatus;
};

export type CcssServiceMessageUpdateViewedStatusInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  messageId: Scalars['ID'];
  messageType: CcssMessageType;
  viewedStatus: CcssMessageViewedStatus;
};

export type CcssServiceMessagesInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['String']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<CcssMessagesSortInput>>;
  sources?: InputMaybe<Array<CcssMessageSourceType>>;
  startDate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CcssMessageNotificationType>;
  viewedStatus?: InputMaybe<CcssMessageViewedStatus>;
};

export type CcssServiceMessagesPagedResult = {
  __typename?: 'CcssServiceMessagesPagedResult';
  data: Array<CcssServiceMessage>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CcssServiceRole = CcssRole & {
  __typename?: 'CcssServiceRole';
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  position?: Maybe<Scalars['String']>;
  positionText?: Maybe<Scalars['String']>;
  serviceId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export type Center = {
  __typename?: 'Center';
  abn?: Maybe<Scalars['String']>;
  acn?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  applicationSetting?: Maybe<CenterSettings>;
  attendanceSetting?: Maybe<AttendanceCenterSettings>;
  avatar?: Maybe<S3SignedUrl>;
  billingEnabledSettings?: Maybe<CenterBillingEnabledSettings>;
  casualBookingExclusions?: Maybe<Array<Maybe<CasualBookingExclusion>>>;
  centerEnrollmentBillingCycleTemplates?: Maybe<Array<Maybe<CenterEnrollmentBillingCycleTemplate>>>;
  classes?: Maybe<Array<Maybe<Class>>>;
  closingDate?: Maybe<Scalars['Date']>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  deactivatedByPersonId?: Maybe<Scalars['ID']>;
  defaultBillingCycleTemplateId?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedByPersonId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  enableBillingCycleProcessing?: Maybe<Scalars['Boolean']>;
  entity?: Maybe<Entity>;
  entityId: Scalars['ID'];
  externalId?: Maybe<Scalars['String']>;
  familyOperationHours?: Maybe<Array<OperationHours>>;
  gallerySettings?: Maybe<CenterGallerySettings>;
  id: Scalars['ID'];
  inviteCode?: Maybe<Scalars['String']>;
  kisiPlaceId?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Location>>>;
  locks?: Maybe<Array<Maybe<Lock>>>;
  logo?: Maybe<S3SignedUrl>;
  merchant?: Maybe<Merchant>;
  name: Scalars['String'];
  openingDate?: Maybe<Scalars['Date']>;
  owner?: Maybe<Owner>;
  payPeriodWeekEnd?: Maybe<DayOfWeek>;
  payPeriodWeekStart?: Maybe<DayOfWeek>;
  phoneNumber?: Maybe<Scalars['Phone']>;
  pocState?: Maybe<PocCenterState>;
  primaryContact?: Maybe<Staff>;
  primaryContactPersonId?: Maybe<Scalars['ID']>;
  reEnrolSettings?: Maybe<ReEnrolCenterSettings>;
  secondaryContact?: Maybe<Staff>;
  secondaryContactPersonId?: Maybe<Scalars['ID']>;
  serviceType?: Maybe<CenterServiceType>;
  spaces?: Maybe<Array<Maybe<Space>>>;
  staffOperationHours?: Maybe<Array<OperationHours>>;
  tags: Array<Tag>;
  taxId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CenterBatchingSetting = {
  __typename?: 'CenterBatchingSetting';
  center: Center;
  centerId: Scalars['ID'];
  enableAutomaticBatching: Scalars['Boolean'];
};

export type CenterBillingEnabledSettings = {
  __typename?: 'CenterBillingEnabledSettings';
  billingEnabledAt?: Maybe<Scalars['DateTime']>;
  billingEnabledBy?: Maybe<Scalars['ID']>;
  billingEnabledByPerson?: Maybe<Person>;
  centerId?: Maybe<Scalars['ID']>;
  enableBillingCycleProcessing?: Maybe<Scalars['Boolean']>;
  enableBillingCycleProcessingEffectiveDate?: Maybe<Scalars['Date']>;
};

export type CenterBillingEnabledSettingsInput = {
  centerId?: InputMaybe<Scalars['String']>;
  enableBillingCycleProcessing?: InputMaybe<Scalars['Boolean']>;
  enableBillingCycleProcessingEffectiveDate?: InputMaybe<Scalars['Date']>;
};

export type CenterBillingSetting = {
  __typename?: 'CenterBillingSetting';
  center: Center;
  centerId: Scalars['ID'];
  dishonorFeeAmount?: Maybe<Scalars['Float']>;
  entityId: Scalars['ID'];
  groupSubsidyAndSessionLineItems: Scalars['Boolean'];
  itemizedBillType?: Maybe<ItemizedBillType>;
  lastCharged?: Maybe<Scalars['DateTime']>;
  passThroughAchSurcharge: Scalars['Boolean'];
  passThroughCreditSurcharge: Scalars['Boolean'];
  passThroughDishonor: Scalars['Boolean'];
  passThroughSurcharge: Scalars['Boolean'];
};

export type CenterClasses = {
  __typename?: 'CenterClasses';
  centerId?: Maybe<Scalars['ID']>;
  classes?: Maybe<Array<Maybe<ClassDto>>>;
};

export type CenterEnrollmentBillingCycleTemplate = {
  __typename?: 'CenterEnrollmentBillingCycleTemplate';
  billingCycleTemplate: BillingCycleTemplate;
  billingCycleTemplateId: Scalars['ID'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  id: Scalars['ID'];
};

export type CenterGallerySettings = {
  __typename?: 'CenterGallerySettings';
  autoApprove?: Maybe<Scalars['Boolean']>;
  businessId?: Maybe<Scalars['String']>;
  centerId?: Maybe<Scalars['String']>;
  releaseOnCheckout?: Maybe<Scalars['Boolean']>;
};

export type CenterPhotoStatistics = {
  __typename?: 'CenterPhotoStatistics';
  approvedCount?: Maybe<Scalars['Int']>;
  centerId: Scalars['ID'];
  declinedCount?: Maybe<Scalars['Int']>;
  takenAfter?: Maybe<Scalars['String']>;
  takenBefore?: Maybe<Scalars['String']>;
  waitingForApprovalCount?: Maybe<Scalars['Int']>;
};

export type CenterProgram = {
  __typename?: 'CenterProgram';
  centerId?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  paymentRule?: Maybe<QkfsPaymentRule>;
  programChildren: Array<Maybe<ProgramChild>>;
  programClasses: Array<Maybe<ProgramClass>>;
  programLeaveDays: Array<Maybe<ProgramLeaveDay>>;
  programTeachers: Array<Maybe<ProgramTeacher>>;
  startDate: Scalars['DateTime'];
  subsidyScheme: ProgramSubsidyScheme;
};

export type CenterProgramPagedResult = {
  __typename?: 'CenterProgramPagedResult';
  data: Array<CenterProgram>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CenterSearchResults = {
  __typename?: 'CenterSearchResults';
  data: Array<Center>;
  totalResults: Scalars['Int'];
};

export type CenterSearchTimeCardLockedUntilInput = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  from?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
};

export enum CenterServiceType {
  Ldc = 'LDC',
  Ldcoshc = 'LDCOSHC',
  Oshc = 'OSHC',
}

export type CenterSettings = {
  __typename?: 'CenterSettings';
  askFamilyAboutSubsidy: Scalars['Boolean'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  centerName?: Maybe<Scalars['String']>;
  enrollmentOptions?: Maybe<EnrollmentOptions>;
  feeAmount: Scalars['Float'];
  feeType?: Maybe<ApplicationFeeType>;
};

export type CenterSettingsInput = {
  askFamilyAboutSubsidy: Scalars['Boolean'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  enrollmentOptions?: InputMaybe<EnrollmentOptions>;
  feeAmount: Scalars['Float'];
  feeType?: InputMaybe<ApplicationFeeType>;
};

export type CenterStaff = {
  __typename?: 'CenterStaff';
  centerId?: Maybe<Scalars['ID']>;
  staff?: Maybe<Array<Maybe<StaffDto>>>;
};

export type CenterSummaryStatistic = {
  __typename?: 'CenterSummaryStatistic';
  centerId: Scalars['ID'];
  dailyAverage: Scalars['Int'];
  flaggedCount: Scalars['Int'];
  totalCount: Scalars['Int'];
  unreadCount: Scalars['Int'];
};

export type CenterThreadMessages = {
  __typename?: 'CenterThreadMessages';
  centerId?: Maybe<Scalars['ID']>;
  messages?: Maybe<Array<Maybe<ParentThreadMessagesDto>>>;
};

export type CenterWithSurcharge = {
  __typename?: 'CenterWithSurcharge';
  city?: Maybe<Scalars['String']>;
  entityId: Scalars['ID'];
  id: Scalars['ID'];
  lastCharged?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  passThroughAchSurcharge: Scalars['Boolean'];
  passThroughCreditSurcharge: Scalars['Boolean'];
  passThroughSurcharge: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
};

export type CentersPagedResult = {
  __typename?: 'CentersPagedResult';
  data: Array<Center>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum CertDertType {
  Certificate = 'CERTIFICATE',
  Determination = 'DETERMINATION',
}

export enum CertificateStatus {
  Approv = 'APPROV',
  Cancel = 'CANCEL',
  Ineff = 'INEFF',
  Reject = 'REJECT',
}

export type ChangeChildStatusInput = {
  centerId: Scalars['ID'];
  currentCustodyStatus: CustodyStatus;
  id: Scalars['ID'];
};

export type Channel = {
  __typename?: 'Channel';
  account?: Maybe<CommsAccount>;
  accountChild?: Maybe<AccountChild>;
  accountStatus?: Maybe<AccountStatusType>;
  businessId: Scalars['ID'];
  centerGroup?: Maybe<Scalars['String']>;
  centerId: Scalars['ID'];
  channelId: Scalars['ID'];
  channelIdentifier: Scalars['String'];
  channelType?: Maybe<ChannelType>;
  description?: Maybe<Scalars['String']>;
  hasUnreadMessages?: Maybe<Scalars['Boolean']>;
  lastAccessedByPersonId?: Maybe<Scalars['String']>;
  lastActive?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['ID']>;
  statistics?: Maybe<ChannelStatistics>;
};

export type ChannelActivityCountDto = {
  __typename?: 'ChannelActivityCountDto';
  activityCount?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['ID']>;
};

export type ChannelAndAuth = {
  __typename?: 'ChannelAndAuth';
  auth?: Maybe<AuthDto>;
  channelId?: Maybe<Scalars['ID']>;
  channelIdentifier?: Maybe<Scalars['String']>;
};

export type ChannelDtoPagedResult = {
  __typename?: 'ChannelDtoPagedResult';
  data: Array<Channel>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos: Array<SortDto>;
  totalRecords: Scalars['Int'];
};

export type ChannelStatistics = {
  __typename?: 'ChannelStatistics';
  channelId: Scalars['String'];
  dailyAverage?: Maybe<Scalars['Int']>;
  flaggedCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  unreadCount?: Maybe<Scalars['Int']>;
};

export enum ChannelType {
  Account = 'Account',
  BulletinBoard = 'BulletinBoard',
  Center = 'Center',
  Student = 'Student',
}

export type ChargeFullFeeEndCareSessionsInput = {
  accountChildId: Scalars['ID'];
  businessId: Scalars['ID'];
  sessionIds: Array<Scalars['ID']>;
};

export type CheckAvailabilityDto = {
  __typename?: 'CheckAvailabilityDto';
  hasCapacity: Scalars['Boolean'];
  scheduleOfferId: Scalars['ID'];
};

export type CheckCurrentCcrmCredentialsInput = {
  businessId: Scalars['ID'];
};

export type CheckInInput = {
  centerId?: InputMaybe<Scalars['ID']>;
  checkIns: Array<CheckInSessionInput>;
  date: Scalars['Date'];
  permissions?: InputMaybe<Array<InputMaybe<PermissionForCheckInOut>>>;
  personId?: InputMaybe<Scalars['ID']>;
  pocStaffId?: InputMaybe<Scalars['ID']>;
  sendNotification?: InputMaybe<Scalars['Boolean']>;
};

export type CheckInOutData = {
  __typename?: 'CheckInOutData';
  failed?: Maybe<Array<CheckInOutFailure>>;
  successful?: Maybe<Array<AttendanceTimeEntry>>;
};

export type CheckInOutFailure = {
  __typename?: 'CheckInOutFailure';
  accountChildId?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
};

export type CheckInSessionInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type CheckInviteCodeEmailComboInput = {
  email: Scalars['String'];
  inviteCode?: InputMaybe<Scalars['String']>;
};

export type CheckOutInput = {
  centerId?: InputMaybe<Scalars['ID']>;
  permissions?: InputMaybe<Array<InputMaybe<PermissionForCheckInOut>>>;
  personId?: InputMaybe<Scalars['ID']>;
  pocStaffId?: InputMaybe<Scalars['ID']>;
  sendNotification?: InputMaybe<Scalars['Boolean']>;
  sessionIds: Array<Scalars['ID']>;
};

export type CheckOutPreviousTimeEntryInput = {
  centerId?: InputMaybe<Scalars['ID']>;
  permissions?: InputMaybe<Array<InputMaybe<PermissionForCheckInOut>>>;
  personId?: InputMaybe<Scalars['ID']>;
  timeEntryIds: Array<Scalars['ID']>;
};

export type CheckedInviteCodeEmail = {
  __typename?: 'CheckedInviteCodeEmail';
  emailValid?: Maybe<Scalars['Boolean']>;
  existingActiveStaff?: Maybe<Scalars['Boolean']>;
  existingRequest?: Maybe<Scalars['Boolean']>;
  inviteValid?: Maybe<Scalars['Boolean']>;
  personFirstName?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['ID']>;
  personLastName?: Maybe<Scalars['String']>;
};

export type Child = {
  __typename?: 'Child';
  accounts: Array<Account>;
  allergies: Array<Allergy>;
  avatar?: Maybe<S3SignedUrl>;
  ccssAttributes?: Maybe<ChildCcssAttributes>;
  contacts: Array<ChildContact>;
  contracts?: Maybe<Array<Contract>>;
  dob: Scalars['String'];
  emergencyContacts: Array<EmergencyContact>;
  firstname: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Gender>;
  grade?: Maybe<Grade>;
  hasActiveContractInCenter?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  immunizations: Array<Immunization>;
  indigenousStatus?: Maybe<IndigenousStatus>;
  lastname: Scalars['String'];
  mealStatus?: Maybe<ChildMealStatus>;
  medicalConditions: Array<MedicalCondition>;
  multipleBirths?: Maybe<Scalars['Boolean']>;
  nickname?: Maybe<Scalars['String']>;
  residesWith?: Maybe<Contact>;
  restrictAccessToChildDocuments: Scalars['Boolean'];
  restrictions: Array<Restriction>;
  tags?: Maybe<Array<Tag>>;
};

export type ChildHasActiveContractInCenterArgs = {
  centerId: Scalars['String'];
  date: Scalars['Date'];
};

export type ChildAgencyPayment = {
  __typename?: 'ChildAgencyPayment';
  accountChildEnrollmentId: Scalars['ID'];
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  adjustment?: Maybe<Adjustment>;
  adjustmentId?: Maybe<Scalars['ID']>;
  agencyPaymentId: Scalars['ID'];
  amountExpected: Scalars['Float'];
  amountPaid: Scalars['Float'];
  childFirstName: Scalars['String'];
  childLastName: Scalars['String'];
  childName: Scalars['String'];
  childSubsidyEnrollmentId?: Maybe<Scalars['String']>;
  differenceAction: DifferenceActionType;
  differenceActionReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  numberOfSessions: Scalars['Int'];
};

export type ChildAttendanceSummaryInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  childTagId?: InputMaybe<Scalars['ID']>;
  classIds?: InputMaybe<Array<Scalars['ID']>>;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type ChildBirth = {
  childId: Scalars['ID'];
  isMultiBirth: Scalars['Boolean'];
};

export type ChildBirthdayStatus = {
  __typename?: 'ChildBirthdayStatus';
  avatar?: Maybe<S3SignedUrl>;
  centerIds: Array<Scalars['ID']>;
  dob: Scalars['String'];
  firstname: Scalars['String'];
  id: Scalars['ID'];
  isAccountActive: Scalars['Boolean'];
  lastname: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
};

export type ChildCcssAttributes = {
  __typename?: 'ChildCcssAttributes';
  crn?: Maybe<Scalars['String']>;
  isCrnEditable: Scalars['Boolean'];
};

export type ChildCcssAttributesInput = {
  crn?: InputMaybe<Scalars['String']>;
};

export type ChildCheckInReportData = {
  __typename?: 'ChildCheckInReportData';
  checkInTime: Scalars['String'];
  checkOutTime?: Maybe<Scalars['String']>;
  checkedInByFirstName: Scalars['String'];
  checkedInByLastName: Scalars['String'];
  checkedOutByFirstName?: Maybe<Scalars['String']>;
  checkedOutByLastName?: Maybe<Scalars['String']>;
  childName: Scalars['String'];
  className: Scalars['String'];
  date: Scalars['String'];
};

export type ChildConcessionCard = {
  __typename?: 'ChildConcessionCard';
  accountChildren: Array<Scalars['ID']>;
  accountId: Scalars['ID'];
  cardNumber: Scalars['String'];
  contactId: Scalars['ID'];
  documentUri?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['String']>;
  expiryDate: Scalars['String'];
  id: Scalars['ID'];
  type: ConcessionCardType;
};

export type ChildContact = {
  __typename?: 'ChildContact';
  accountPermissions: Array<AccountPermissions>;
  additionalPhoneNumbers?: Maybe<Array<PhoneNumber>>;
  address?: Maybe<Address>;
  avatar?: Maybe<S3SignedUrl>;
  childId: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  id: Scalars['ID'];
  lastname: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<PhoneNumber>;
  relationshipType: RelationshipType;
};

export type ChildContactPermissionLabelValue = {
  __typename?: 'ChildContactPermissionLabelValue';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ChildDiff = {
  __typename?: 'ChildDiff';
  childId: Scalars['String'];
  customFields: Array<CustomFieldValueDataDiff>;
  diffCount: Scalars['Int'];
  diffState: DiffState;
  firstName: Scalars['String'];
  immunization?: Maybe<DocumentUploadDiff>;
  isNewChild: Scalars['Boolean'];
  lastName: Scalars['String'];
  medicalInfo: Array<MedicalInfoDto>;
  systemFields: Array<DiffDto>;
};

export type ChildDocument = {
  __typename?: 'ChildDocument';
  accountChildId: Scalars['ID'];
  documentUri?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type ChildForAgencyPaymentAtCenter = {
  __typename?: 'ChildForAgencyPaymentAtCenter';
  accountChildEnrollmentId: Scalars['ID'];
  accountChildId: Scalars['ID'];
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  childAgencyId?: Maybe<Scalars['String']>;
  childFirstName: Scalars['String'];
  childLastName: Scalars['String'];
  contractedNumberOfSessions: Scalars['Int'];
  expectedSubsidyAmount: Scalars['Float'];
};

export type ChildLastAttendanceReportInput = {
  accountStatus?: InputMaybe<AccountStatusType>;
  businessId: Scalars['String'];
  centerIds?: InputMaybe<Array<Scalars['String']>>;
  date: Scalars['Date'];
};

export type ChildMealStatus = {
  __typename?: 'ChildMealStatus';
  mealPricing?: Maybe<MealPricing>;
};

export type ChildMedicalDocumentationInput = {
  expirationDate?: InputMaybe<Scalars['Date']>;
  file: Scalars['Upload'];
  filename?: InputMaybe<Scalars['String']>;
  reviewDate?: InputMaybe<Scalars['Date']>;
  reviewDaysBeforeExpiration?: InputMaybe<Scalars['Int']>;
};

export type ChildPhoto = {
  __typename?: 'ChildPhoto';
  child?: Maybe<Child>;
  childId?: Maybe<Scalars['String']>;
  galleryPhotoId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ChildPhotoCount = {
  __typename?: 'ChildPhotoCount';
  childId: Scalars['ID'];
  photoCount: Scalars['Int'];
};

export type ChildRelationshipDiff = {
  __typename?: 'ChildRelationshipDiff';
  diffCount: Scalars['Int'];
  diffState: DiffState;
  key: Scalars['String'];
  systemFields: Array<DiffDto>;
};

export type ChildSearchInput = {
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  classIds?: InputMaybe<Array<Scalars['ID']>>;
  from: Scalars['Int'];
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  searchKey?: InputMaybe<Scalars['String']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSort>>;
  statusAtDate?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<AccountChildStatusType>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ChildSearchResults = {
  __typename?: 'ChildSearchResults';
  items?: Maybe<Array<Maybe<Child>>>;
  nextToken?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};

export enum ChildStatus {
  CheckedIn = 'CHECKED_IN',
  CheckedOut = 'CHECKED_OUT',
  CustodyReleased = 'CUSTODY_RELEASED',
  CustodyTaken = 'CUSTODY_TAKEN',
  Expected = 'EXPECTED',
  Late = 'LATE',
  Off = 'OFF',
  PlannedAbsence = 'PLANNED_ABSENCE',
  Unknown = 'UNKNOWN',
}

export type ChildUploadDocument = {
  __typename?: 'ChildUploadDocument';
  childId: Scalars['ID'];
  documentUri?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ChildWellnessConditionReportInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  classIds: Array<Scalars['ID']>;
  conditionIds?: InputMaybe<Array<Scalars['ID']>>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
};

export type ChildrenHasDeposits = {
  __typename?: 'ChildrenHasDeposits';
  accountChildren?: Maybe<Array<Maybe<AccountChild>>>;
};

export type ChildrenSearchResults = {
  __typename?: 'ChildrenSearchResults';
  data: Array<Child>;
  totalResults: Scalars['Int'];
};

export type Class = {
  __typename?: 'Class';
  archivedAt?: Maybe<Scalars['String']>;
  availabilityForTimeframe: Array<ClassAvailability>;
  capacity: Scalars['Int'];
  careType?: Maybe<Scalars['String']>;
  casualBookingSettings: CasualBookingSettings;
  center?: Maybe<Center>;
  centerId: Scalars['ID'];
  colorCode: Scalars['String'];
  currentChildren?: Maybe<Array<Maybe<Child>>>;
  currentSpace?: Maybe<Space>;
  currentSpaceId: Scalars['ID'];
  defaultCasualFeeId?: Maybe<Scalars['ID']>;
  defaultPermanentFeeId?: Maybe<Scalars['ID']>;
  defaultSpace?: Maybe<Space>;
  defaultSpaceId: Scalars['ID'];
  endTime?: Maybe<Scalars['Time']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  enrollmentDataForTimeframe: ClassEnrollmentDataForTimeframe;
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
  fees: Array<Fee>;
  filteredCurrentEnrollments?: Maybe<Array<Maybe<Enrollment>>>;
  glCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPreSchoolProgram?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pocState?: Maybe<PocClassState>;
  regulation?: Maybe<Regulation>;
  regulationId: Scalars['ID'];
  regulationOverride?: Maybe<RegulationOverride>;
  staffAssignments: Array<Staff>;
  startTime?: Maybe<Scalars['Time']>;
  startsAt?: Maybe<Scalars['DateTime']>;
};

export type ClassAvailabilityForTimeframeArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  includeFutureContracts?: InputMaybe<Scalars['Boolean']>;
  includeLtbOfferings?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type ClassEnrollmentDataForTimeframeArgs = {
  end?: InputMaybe<Scalars['Date']>;
  includeFutureContracts: Scalars['Boolean'];
  includeLtbOfferings: Scalars['Boolean'];
  start?: InputMaybe<Scalars['Date']>;
};

export type ClassAttendanceOpenSpots = {
  __typename?: 'ClassAttendanceOpenSpots';
  capacity: Scalars['Int'];
  classId: Scalars['ID'];
  closure?: Maybe<ClassClosureDto>;
  date: Scalars['DateTime'];
  fte: Scalars['Float'];
  maxCapacityForDate: Scalars['Int'];
  numberOfSessions: Scalars['Int'];
  numberOfTeachersScheduled: Scalars['Int'];
  ratioCompliantOpenSpots: Scalars['Int'];
  totalOpenSpots: Scalars['Float'];
  utilization: Scalars['Float'];
};

export type ClassAttendanceRosterReportInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  classIds: Array<Scalars['ID']>;
  date: Scalars['Date'];
};

export type ClassAvailability = {
  __typename?: 'ClassAvailability';
  ageUpAm: Scalars['Int'];
  ageUpPm: Scalars['Int'];
  date: Scalars['String'];
  isFullDayUtilization: Scalars['Boolean'];
  openSpotsAm: Scalars['Int'];
  openSpotsPm: Scalars['Int'];
  totalAgeUp: Scalars['Int'];
  totalOpenSpots: Scalars['Int'];
};

export type ClassCapacityOverride = {
  __typename?: 'ClassCapacityOverride';
  capacity: Scalars['Int'];
  classId: Scalars['ID'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type ClassClosure = {
  classId: Scalars['String'];
  closureResolution: ClosureResolution;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type ClassClosureDto = {
  __typename?: 'ClassClosureDto';
  classId: Scalars['ID'];
  closureResolution: ClosureResolution;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export type ClassDto = {
  __typename?: 'ClassDto';
  colorCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ClassEnrollmentAvailability = {
  __typename?: 'ClassEnrollmentAvailability';
  availabilityByDayOfWeek?: Maybe<Array<Maybe<EnrollmentAvailabilityByDayOfWeek>>>;
  classId: Scalars['ID'];
  openSpots: Scalars['Int'];
};

export type ClassEnrollmentDataForTimeframe = {
  __typename?: 'ClassEnrollmentDataForTimeframe';
  enrollments: Array<Enrollment>;
  futureEnrollments: Array<Enrollment>;
  metricsByWeek: Array<ClassEnrollmentMetrics>;
  totalAgeUps: Scalars['Int'];
  totalOpenSpots: Scalars['Int'];
};

export type ClassEnrollmentMetrics = {
  __typename?: 'ClassEnrollmentMetrics';
  fte: Scalars['Float'];
  ftePercentage: Scalars['Float'];
  startOfWeek: Scalars['DateTime'];
  totalEnrollments: Scalars['Int'];
  totalExpectedRevenue: Scalars['Float'];
};

export type ClassError = {
  __typename?: 'ClassError';
  classId: Scalars['ID'];
  error: Scalars['String'];
};

export type ClassPhotoCounts = {
  __typename?: 'ClassPhotoCounts';
  class?: Maybe<Class>;
  classId: Scalars['ID'];
  dailyPhotoCounts: Array<Maybe<DailyPhotoCounts>>;
};

export type ClassSearchResults = {
  __typename?: 'ClassSearchResults';
  data: Array<Class>;
  totalResults: Scalars['Int'];
};

export type ClearEducationExperiencesInput = {
  dates: Array<Scalars['DateTime']>;
  programIds: Array<Scalars['ID']>;
};

export type ClearExperiencesForCurriculumScheduleProgramThemeInput = {
  businessId: Scalars['ID'];
  curriculumScheduleId: Scalars['ID'];
  date: Scalars['DateTime'];
  programThemeId: Scalars['ID'];
};

export type ClockInInput = {
  centerId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  personId: Scalars['ID'];
  positionId: Scalars['ID'];
  scheduleId?: InputMaybe<Scalars['ID']>;
  shiftId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<TimeEntryType>;
};

export type ClockInInputV2 = {
  centerId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  personId: Scalars['ID'];
  positionId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<TimeEntryTypeV2>;
};

export enum ClosureResolution {
  Absent = 'Absent',
  Clear = 'Clear',
}

export type CognitoCreateNewUserInput = {
  cognitoContext?: InputMaybe<Scalars['JSON']>;
  cognitoEvent?: InputMaybe<Scalars['JSON']>;
  userAttributes: Scalars['JSON'];
};

export type CognitoCreateNewUserResponse = {
  __typename?: 'CognitoCreateNewUserResponse';
  personId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type CognitoStaffAcceptInviteInput = {
  clientMetadata: Scalars['JSON'];
  cognitoContext?: InputMaybe<Scalars['JSON']>;
  cognitoEvent: Scalars['JSON'];
  userAttributes: Scalars['JSON'];
};

export type CognitoStaffAcceptInviteResult = {
  __typename?: 'CognitoStaffAcceptInviteResult';
  entityId?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type Comment = {
  __typename?: 'Comment';
  attachedToEntityId: Scalars['ID'];
  businessId: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdByAccount?: Maybe<Staff>;
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  replyCount: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
};

export type CommentDisplay = {
  __typename?: 'CommentDisplay';
  displayAvatar?: Maybe<Scalars['String']>;
  displayValue?: Maybe<Scalars['String']>;
};

export type CommentPagedResult = {
  __typename?: 'CommentPagedResult';
  data: Array<Maybe<Comment>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CommsAccount = {
  __typename?: 'CommsAccount';
  centerId: Scalars['ID'];
  children?: Maybe<Array<Maybe<AccountChild>>>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  entityId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status?: Maybe<AccountStatusType>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CommsBusinessSetting = {
  __typename?: 'CommsBusinessSetting';
  businessId?: Maybe<Scalars['ID']>;
  centerCount?: Maybe<Scalars['Int']>;
  usesAccountChannels?: Maybe<Scalars['Boolean']>;
  usesBroadcastChannels?: Maybe<Scalars['Boolean']>;
  usesBulletinBoards?: Maybe<Scalars['Boolean']>;
  usesComms?: Maybe<Scalars['Boolean']>;
  usesInactiveAccounts?: Maybe<Scalars['Boolean']>;
  usesStudentChannels?: Maybe<Scalars['Boolean']>;
};

export type CommsCenterSetting = {
  __typename?: 'CommsCenterSetting';
  businessId?: Maybe<Scalars['ID']>;
  businessUsesComms?: Maybe<Scalars['Boolean']>;
  centerId?: Maybe<Scalars['ID']>;
  centerName?: Maybe<Scalars['String']>;
  fromDefault?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  usesAccountChannels?: Maybe<Scalars['Boolean']>;
  usesBroadcastChannels?: Maybe<Scalars['Boolean']>;
  usesBulletinBoards?: Maybe<Scalars['Boolean']>;
  usesInactiveAccounts?: Maybe<Scalars['Boolean']>;
  usesStudentChannels?: Maybe<Scalars['Boolean']>;
};

export type CommsCenterSettingPaged = {
  __typename?: 'CommsCenterSettingPaged';
  data: Array<CommsCenterSetting>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos: Array<SortDto>;
  totalRecords: Scalars['Int'];
};

export type ComplyingWrittenArrangement = {
  __typename?: 'ComplyingWrittenArrangement';
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childFirstName: Scalars['String'];
  childId: Scalars['ID'];
  childLastName: Scalars['String'];
  enrolmentId: Scalars['String'];
  guardianFirstName?: Maybe<Scalars['String']>;
  guardianId?: Maybe<Scalars['ID']>;
  guardianLastName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sentDate?: Maybe<Scalars['DateTime']>;
  status: ComplyingWrittenArrangementStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedByPerson?: Maybe<Staff>;
};

export type ComplyingWrittenArrangementMetrics = {
  __typename?: 'ComplyingWrittenArrangementMetrics';
  approvedCount: Scalars['Int'];
  cancelledCount: Scalars['Int'];
  pendingCount: Scalars['Int'];
  readyToBeSentCount: Scalars['Int'];
  rejectedCount: Scalars['Int'];
};

export type ComplyingWrittenArrangementPagedResult = {
  __typename?: 'ComplyingWrittenArrangementPagedResult';
  data: Array<ComplyingWrittenArrangement>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum ComplyingWrittenArrangementStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  ReadyToBeSent = 'READY_TO_BE_SENT',
  Rejected = 'REJECTED',
}

export type ConcessionCard = {
  __typename?: 'ConcessionCard';
  accountChildren: Array<Scalars['ID']>;
  accountId: Scalars['ID'];
  cardNumber: Scalars['String'];
  contactFirstName: Scalars['String'];
  contactId: Scalars['ID'];
  contactLastName: Scalars['String'];
  documentUri?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['String']>;
  expiryDate: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: ConcessionCardType;
};

export enum ConcessionCardType {
  DepartmentOfVeteransCard = 'DepartmentOfVeteransCard',
  HealthCareCard = 'HealthCareCard',
  PensionCard = 'PensionCard',
}

export enum ConditionType {
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEqual = 'GREATER_THAN_EQUAL',
  LessThan = 'LESS_THAN',
  LessThanEqual = 'LESS_THAN_EQUAL',
  NotEqual = 'NOT_EQUAL',
}

export type Contact = Person & {
  __typename?: 'Contact';
  accounts: Array<Account>;
  additionalPhoneNumbers?: Maybe<Array<PhoneNumber>>;
  address?: Maybe<Address>;
  avatar?: Maybe<S3SignedUrl>;
  ccssAttributes?: Maybe<ContactCcssAttributes>;
  children: Array<ContactChild>;
  cognitoConfiguredAt?: Maybe<Scalars['DateTime']>;
  contactAccountPermissions?: Maybe<Array<Maybe<ContactAccountPermissions>>>;
  devices?: Maybe<Array<Maybe<Device>>>;
  dob?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  hasKtConnectAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  lastname: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  onboardingStatus?: Maybe<OnboardingStatus>;
  phoneNumber?: Maybe<Scalars['Phone']>;
  phoneNumberAlt?: Maybe<Scalars['Phone']>;
  pin?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<PhoneNumber>;
  restoreId?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  userType: UserType;
};

export type ContactAccountPermissions = {
  __typename?: 'ContactAccountPermissions';
  accountId: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

export type ContactAccountPermissionsInput = {
  accountContactPermissions?: InputMaybe<Array<InputMaybe<AccountContactPermissionInput>>>;
  accountId: Scalars['ID'];
  /** make enum */
  permissions: Array<Scalars['String']>;
};

export type ContactCcssAttributes = {
  __typename?: 'ContactCcssAttributes';
  crn?: Maybe<Scalars['String']>;
  isCrnEditable: Scalars['Boolean'];
};

export type ContactCcssAttributesInput = {
  crn?: InputMaybe<Scalars['String']>;
};

export type ContactChild = {
  __typename?: 'ContactChild';
  accountPermissions: Array<AccountPermissions>;
  avatar?: Maybe<S3SignedUrl>;
  childId: Scalars['ID'];
  contactId: Scalars['ID'];
  dob: Scalars['String'];
  firstname: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastname: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  relationshipType: RelationshipType;
};

export type ContactData = {
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  relationship: Scalars['String'];
};

export type ContactDiff = {
  __typename?: 'ContactDiff';
  address?: Maybe<AddressDiff>;
  customFields?: Maybe<Array<CustomFieldValueDataDiff>>;
  diffCount: Scalars['Int'];
  diffState: DiffState;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<PhoneNumberDiff>>;
  relationships?: Maybe<Array<ChildRelationshipDiff>>;
  systemFields?: Maybe<Array<DiffDto>>;
};

export type ContactSearchResults = {
  __typename?: 'ContactSearchResults';
  data: Array<Contact>;
  totalResults: Scalars['Int'];
};

export type Content = {
  __typename?: 'Content';
  actions: Array<Maybe<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<EducationPerson>;
  description?: Maybe<Scalars['String']>;
  discriminator?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isParentRoot?: Maybe<Scalars['Boolean']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  mimeType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerGroups?: Maybe<Array<Maybe<GroupSummary>>>;
  parentFolderId?: Maybe<Scalars['String']>;
  parentFolderName?: Maybe<Scalars['String']>;
  readerGroups?: Maybe<Array<Maybe<GroupSummary>>>;
  updaterGroups?: Maybe<Array<Maybe<GroupSummary>>>;
};

export type ContentPagedResult = {
  __typename?: 'ContentPagedResult';
  items?: Maybe<Array<Maybe<Content>>>;
  numberRequested: Scalars['Int'];
  start: Scalars['Int'];
  totalNumber?: Maybe<Scalars['Int']>;
};

export type Contract = {
  __typename?: 'Contract';
  accountChildId: Scalars['ID'];
  accountId: Scalars['ID'];
  adjustment?: Maybe<Adjustment>;
  adjustmentId?: Maybe<Scalars['ID']>;
  associatedSubsidies?: Maybe<Array<Maybe<AgencyAccountChild>>>;
  casualFee?: Maybe<Fee>;
  casualFeeId?: Maybe<Scalars['ID']>;
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  class: Class;
  classId: Scalars['ID'];
  createdAt: Scalars['String'];
  createdBy: Scalars['ID'];
  createdByAccount?: Maybe<Person>;
  cycleType: ContractCycleType;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['String']>;
  lastModifiedBy?: Maybe<Scalars['ID']>;
  lastModifiedByAccount?: Maybe<Person>;
  metadata?: Maybe<Metadata>;
  originalFee?: Maybe<Fee>;
  originalFeeId?: Maybe<Scalars['ID']>;
  permanentFee: Fee;
  permanentFeeId: Scalars['ID'];
  reasonForEnding?: Maybe<ReasonForEndingContract>;
  startDate: Scalars['Date'];
  status: ContractStatus;
  timeslots: Array<ContractTimeslot>;
};

export enum ContractCycleType {
  Biweekly = 'BIWEEKLY',
  Casual = 'CASUAL',
  Custom = 'CUSTOM',
  Weekly = 'WEEKLY',
}

export type ContractForDates = {
  absentContractId?: InputMaybe<Scalars['String']>;
  absentDates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContractReportInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  childTagId?: InputMaybe<Scalars['ID']>;
  runAtDate: Scalars['String'];
};

export enum ContractStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Future = 'FUTURE',
}

export type ContractTimeslot = {
  __typename?: 'ContractTimeslot';
  archivedAt?: Maybe<Scalars['DateTime']>;
  contractId: Scalars['ID'];
  date?: Maybe<Scalars['Date']>;
  day?: Maybe<DayOfWeek>;
  dropOffTime?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  pickUpTime?: Maybe<Scalars['Time']>;
  week?: Maybe<WeekType>;
};

export enum ContractUpdateType {
  End = 'END',
  EndAndCreateNew = 'END_AND_CREATE_NEW',
  UpdateExisting = 'UPDATE_EXISTING',
}

export type CopyScheduleInput = {
  centerId: Scalars['ID'];
  id: Scalars['ID'];
  weekToCopyTo: Scalars['DateTime'];
};

export type CopyShiftInput = {
  centerId: Scalars['ID'];
  classId?: InputMaybe<Scalars['String']>;
  dates: Array<DateTimeRangeInput>;
  id: Scalars['ID'];
  locationId?: InputMaybe<Scalars['String']>;
};

export type CoreDocument = {
  __typename?: 'CoreDocument';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['ID']>;
  associatedEntityId?: Maybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  centerId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByPerson?: Maybe<Staff>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  link: Scalars['URL'];
  name: Scalars['String'];
  reviewDate?: Maybe<Scalars['Date']>;
  reviewDaysBeforeExpiration?: Maybe<Scalars['Int']>;
  /** type DocumentType from core2 */
  type: DocumentType;
  wellnessItemId: Scalars['ID'];
};

export enum CorrespondenceType {
  ReenrollmentRequest = 'REENROLLMENT_REQUEST',
  UpdateEnrollmentForm = 'UPDATE_ENROLLMENT_FORM',
}

export enum Country {
  Au = 'AU',
  Ca = 'CA',
  Us = 'US',
}

export type CpavAgeGroup = {
  __typename?: 'CpavAgeGroup';
  ageGroup?: Maybe<Scalars['String']>;
  inclusions?: Maybe<Scalars['Int']>;
  usualFeeAmount?: Maybe<Scalars['Float']>;
};

export type CpavAgeGroupRequest = {
  ageGroup?: InputMaybe<Scalars['String']>;
  inclusions?: InputMaybe<Scalars['Int']>;
  usualFeeAmount?: InputMaybe<Scalars['Float']>;
};

export enum CpavCareType {
  Asc = 'ASC',
  Bsc = 'BSC',
  Ldc = 'LDC',
  Vac = 'VAC',
}

export type CpavFeeGroup = {
  __typename?: 'CpavFeeGroup';
  AFTSCH?: Maybe<Array<Maybe<CpavAgeGroup>>>;
  BEFSCH?: Maybe<Array<Maybe<CpavAgeGroup>>>;
  FULLDY?: Maybe<Array<Maybe<CpavAgeGroup>>>;
  HALFDY?: Maybe<Array<Maybe<CpavAgeGroup>>>;
  HOURLY?: Maybe<Array<Maybe<CpavAgeGroup>>>;
};

export type CpavFeeGroupRequest = {
  AFTSCH?: InputMaybe<Array<InputMaybe<CpavAgeGroupRequest>>>;
  BEFSCH?: InputMaybe<Array<InputMaybe<CpavAgeGroupRequest>>>;
  FULLDY?: InputMaybe<Array<InputMaybe<CpavAgeGroupRequest>>>;
  HALFDY?: InputMaybe<Array<InputMaybe<CpavAgeGroupRequest>>>;
  HOURLY?: InputMaybe<Array<InputMaybe<CpavAgeGroupRequest>>>;
};

export type CreateApiKeyInput = {
  description?: InputMaybe<Scalars['String']>;
  expires?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAccountBillingCycleInput = {
  accountId: Scalars['ID'];
  additionalChargePeriod: BillingTimePeriodInput;
  billingCycleTemplateId?: InputMaybe<Scalars['ID']>;
  billingPeriods?: InputMaybe<Array<InputMaybe<BillingPeriodCustomInput>>>;
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  endDate?: InputMaybe<Scalars['Date']>;
  frequency: BillingFrequency;
  gracePeriodDays?: InputMaybe<Scalars['Int']>;
  invoiceSchedule: BillingTimePeriodInput;
  isPaused: Scalars['Boolean'];
  lateChargeAmount?: InputMaybe<Scalars['Float']>;
  period?: InputMaybe<BillingPeriod>;
  startDate: Scalars['Date'];
  transactionLimit?: InputMaybe<Scalars['Float']>;
};

export type CreateAccountInput = {
  accountName: Scalars['String'];
  centerId: Scalars['ID'];
  child?: InputMaybe<CreateNewChildOnAccountCreateInput>;
  childId?: InputMaybe<Scalars['ID']>;
  contactCcssAttributes?: InputMaybe<ContactCcssAttributesInput>;
  contactId?: InputMaybe<Scalars['ID']>;
  contacts: Array<CreateContactInput>;
  entityId: Scalars['ID'];
  relationship: RelationshipEnum;
  sendContactInvites: Scalars['Boolean'];
};

export type CreateAccountingPeriodInput = {
  businessId: Scalars['ID'];
  endDate: Scalars['Date'];
};

export type CreateActivity = {
  __typename?: 'CreateActivity';
  activityTime?: Maybe<Scalars['Float']>;
  activityType?: Maybe<ActivityType>;
  channelId?: Maybe<Scalars['ID']>;
  channelType?: Maybe<ChannelType>;
  error?: Maybe<Scalars['String']>;
  messageContent?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['ID']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateActivityInput = {
  activityTime?: InputMaybe<Scalars['Float']>;
  activityType?: InputMaybe<ActivityType>;
  additionalInformation?: InputMaybe<Scalars['String']>;
  attachmentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  businessId?: InputMaybe<Scalars['ID']>;
  channelId?: InputMaybe<Scalars['ID']>;
  channelType?: InputMaybe<ChannelType>;
  channelWasRead?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<FlagReason>;
  messageContent?: InputMaybe<Scalars['String']>;
  messageDirectedAt?: InputMaybe<Array<InputMaybe<UserActivityMessageDirectedDto>>>;
  parentPubNubMessageId?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['ID']>;
  pubNubMessageId?: InputMaybe<Scalars['String']>;
};

export type CreateAdjustmentInput = {
  businessId: Scalars['ID'];
  glCode: Scalars['String'];
  glCodeMapping?: InputMaybe<GlCodeMappingInput>;
  name: Scalars['String'];
  type: AdjustmentType;
};

export type CreateAgeRangeInput = {
  end: Scalars['Int'];
  endUOM?: InputMaybe<Scalars['String']>;
  start: Scalars['Int'];
  startUOM?: InputMaybe<Scalars['String']>;
};

export type CreateAgencyAccountChildInput = {
  accountChildId: Scalars['ID'];
  agencyId: Scalars['ID'];
  childSubsidyEnrollmentId?: InputMaybe<Scalars['String']>;
  endDate: Scalars['Date'];
  excludedContractIds?: InputMaybe<Array<Scalars['ID']>>;
  ignoreCopay: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  subsidyPaymentPortion: Scalars['Float'];
};

export type CreateAgencyInput = {
  absentDay?: InputMaybe<Scalars['String']>;
  attendanceRequirement: Scalars['String'];
  billingCalculationType: BillingCalculationType;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  county?: InputMaybe<Scalars['String']>;
  glCodeMapping?: InputMaybe<GlCodeMappingInput>;
  links?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['Phone']>;
  reimbursementSchedule?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  subsidyPeriod: SubsidyPaymentPeriodType;
};

export type CreateAgencyPaymentInput = {
  agencyId: Scalars['ID'];
  amountPaid: Scalars['Float'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  checkNumber?: InputMaybe<Scalars['String']>;
  createChildAgencyPaymentMessages: Array<CreateChildAgencyPaymentInput>;
  endDate: Scalars['Date'];
  isSubmitted?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['Date'];
};

export type CreateAltArrangementInput = {
  businessId: Scalars['ID'];
  evenAmountsPaymentPeriod?: InputMaybe<Scalars['String']>;
  offsettingArrangementPercentage?: InputMaybe<Scalars['Int']>;
  offsettingArrangementServiceId?: InputMaybe<Scalars['String']>;
  providerId: Scalars['ID'];
  startDate: Scalars['Date'];
  type: AltArrangementType;
};

export type CreateAnnouncementInput = {
  centerId: Scalars['ID'];
  emergency: Scalars['Boolean'];
  endsAt?: InputMaybe<Scalars['DateTime']>;
  message: Scalars['String'];
  startsAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateApiKeyWebHookInput = {
  apiKeyId: Scalars['ID'];
  businessId: Scalars['ID'];
  url: Scalars['String'];
  webHookApiKey: Scalars['String'];
  webHookType: Scalars['String'];
};

export type CreateApplicationNoteInput = {
  applicationId: Scalars['ID'];
  businessId: Scalars['ID'];
  comment: Scalars['String'];
};

export type CreateAppliedAccountDiscountInput = {
  accountChildIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  accountId: Scalars['ID'];
  discountId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate: Scalars['Date'];
};

export type CreateAuthorizedPersonPaymentMethodInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  accountName?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  expirationDate?: InputMaybe<Scalars['Date']>;
  isAutoPay?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  lastFour: Scalars['String'];
  personId: Scalars['ID'];
  processorId: Scalars['String'];
  routingNumber?: InputMaybe<Scalars['Int']>;
  termsAndConditionsConfirmed?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
  type: PaymentMethodType;
};

export type CreateBasePositionInput = {
  businessId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateBatchInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  paymentIds: Array<Scalars['ID']>;
};

export type CreateBillingCycleTemplateInput = {
  additionalChargePeriod?: InputMaybe<BillingTimePeriodInput>;
  billingPeriods?: InputMaybe<Array<InputMaybe<BillingPeriodCustomInput>>>;
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  cycleType: BillingCycleType;
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  frequency: BillingFrequency;
  frequencyPayPeriod?: InputMaybe<FrequencyPayPeriod>;
  gracePeriodDays?: InputMaybe<Scalars['Int']>;
  invoiceSchedule: BillingTimePeriodInput;
  lateChargeAmount?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  period: BillingPeriod;
};

export type CreateBusRosterInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  children: Array<BusRosterAccountChildInput>;
  destinations: Array<BusRosterDestinationInput>;
  effectiveDate: Scalars['String'];
  endDate: Scalars['String'];
  name: Scalars['String'];
  type: BusRunType;
};

export type CreateCcssCertificateInput = {
  accountId: Scalars['String'];
  businessId: Scalars['String'];
  centerId: Scalars['String'];
  children: Array<Scalars['ID']>;
  evidenceHeld: Scalars['Boolean'];
  exceptionalCircumstanceReason?: InputMaybe<ExceptionalCircumstanceReason>;
  exceptionalCircumstanceText?: InputMaybe<Scalars['String']>;
  isDeclarationGiven: Scalars['Boolean'];
  riskReasons: Array<RiskReason>;
  startDate: Scalars['Date'];
  stateTerritoryBody?: InputMaybe<StateTerritoryBodyInput>;
  supportingDocuments?: InputMaybe<Array<SupportingDocumentInput>>;
  weeksAtRisk: Scalars['Int'];
};

export type CreateCcssDeterminationInput = {
  accountId: Scalars['String'];
  additionalInfo?: InputMaybe<Scalars['String']>;
  businessId: Scalars['String'];
  centerId: Scalars['String'];
  children: Array<Scalars['ID']>;
  exceptionalCircumstanceReason?: InputMaybe<ExceptionalCircumstanceReason>;
  exceptionalCircumstanceText?: InputMaybe<Scalars['String']>;
  extensionReasons?: InputMaybe<Array<InputMaybe<ExtensionReason>>>;
  indicativeRiskToDate: Scalars['Date'];
  isDeclarationGiven: Scalars['Boolean'];
  riskReasons: Array<RiskReason>;
  startDate: Scalars['Date'];
  stateTerritoryBody: StateTerritoryBodyInput;
  supportingDocuments?: InputMaybe<Array<SupportingDocumentInput>>;
  weeksAtRisk: Scalars['Int'];
};

export type CreateCenterInput = {
  address: AddressInput;
  avatar?: InputMaybe<Scalars['String']>;
  billingEnabledSettings?: InputMaybe<CenterBillingEnabledSettingsInput>;
  enableBillingCycleProcessing?: InputMaybe<Scalars['Boolean']>;
  entityId: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber: Scalars['Phone'];
  primaryContactPersonId?: InputMaybe<Scalars['ID']>;
  serviceType?: InputMaybe<CenterServiceType>;
  tags?: InputMaybe<Array<Scalars['ID']>>;
  timezone: Scalars['String'];
};

export type CreateChildAgencyPaymentInput = {
  accountChildId: Scalars['ID'];
  accountId: Scalars['ID'];
  adjustmentId?: InputMaybe<Scalars['ID']>;
  agencyAccountChildEnrollmentId?: InputMaybe<Scalars['String']>;
  amountExpected: Scalars['Float'];
  amountPaid: Scalars['Float'];
  differenceAction: DifferenceActionType;
  differenceActionReason?: InputMaybe<Scalars['String']>;
  numberOfSessions: Scalars['Int'];
};

export type CreateChildAllergyInput = {
  allergenId: Scalars['ID'];
  childId: Scalars['ID'];
  documentation: Array<ChildMedicalDocumentationInput>;
  instructions: Scalars['String'];
  reactions: Array<Scalars['String']>;
  severity?: InputMaybe<AllergenSeverity>;
};

export type CreateChildAsChargeOfPersonAtCenterInput = {
  allergies?: InputMaybe<Scalars['JSON']>;
  avatar?: InputMaybe<Scalars['String']>;
  centerId: Scalars['ID'];
  dob: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Scalars['JSON']>;
  personId: Scalars['ID'];
  primary: Scalars['Boolean'];
  race?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<RelationshipEnum>;
};

export type CreateChildAtCenterInput = {
  allergies?: InputMaybe<Scalars['JSON']>;
  avatar?: InputMaybe<Scalars['String']>;
  centerId: Scalars['ID'];
  dob: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
};

export type CreateChildImmunizationInput = {
  childId: Scalars['ID'];
  document: ChildMedicalDocumentationInput;
};

export type CreateChildOnAccountInput = {
  centerId: Scalars['String'];
  childCcssAttributes?: InputMaybe<ChildCcssAttributesInput>;
  dob: Scalars['Date'];
  entityId: Scalars['String'];
  familyId: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
};

export type CreateClassInput = {
  capacity: Scalars['Int'];
  careType?: InputMaybe<Scalars['String']>;
  centerId: Scalars['ID'];
  colorCode: Scalars['String'];
  defaultCasualFeeId?: InputMaybe<Scalars['ID']>;
  defaultPermanentFeeId?: InputMaybe<Scalars['ID']>;
  defaultSpaceId: Scalars['ID'];
  endTime?: InputMaybe<Scalars['Time']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  glCode?: InputMaybe<Scalars['String']>;
  isPreSchoolProgram?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  regulationId: Scalars['ID'];
  regulationOverride?: InputMaybe<RegulationOverrideInput>;
  staffAssignmentIds: Array<Scalars['String']>;
  startTime?: InputMaybe<Scalars['Time']>;
  startsAt: Scalars['DateTime'];
};

export type CreateCommentInput = {
  attachedToEntityType: AttachedToEntityType;
  attachedToId: Scalars['ID'];
  businessId: Scalars['ID'];
  parentId?: InputMaybe<Scalars['ID']>;
  text: Scalars['String'];
};

export type CreateContactForChildInput = {
  accounts: Array<ContactAccountPermissionsInput>;
  childId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: PhoneNumberInput;
  relationship: RelationshipEnum;
  sendContactInvites?: InputMaybe<Scalars['Boolean']>;
};

export type CreateContactInput = {
  ccssAttributes?: InputMaybe<ContactCcssAttributesInput>;
  centerId?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  entityId: Scalars['String'];
  familyId?: InputMaybe<Scalars['String']>;
  firstname: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  lastname: Scalars['String'];
  primaryPhoneNumber?: InputMaybe<PhoneNumberInput>;
};

export type CreateContractAndBillingCycle = {
  __typename?: 'CreateContractAndBillingCycle';
  billingCycle: BillingCycle;
  contract: Contract;
};

export type CreateContractAndBillingCycleInput = {
  billingCycle: CreateAccountBillingCycleInput;
  contract: CreateContractInput;
};

export type CreateContractInput = {
  accountId: Scalars['ID'];
  adjustmentId?: InputMaybe<Scalars['ID']>;
  casualFeeId?: InputMaybe<Scalars['ID']>;
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  classId: Scalars['ID'];
  cycleType: ContractCycleType;
  endDate?: InputMaybe<Scalars['Date']>;
  originalFeeId?: InputMaybe<Scalars['ID']>;
  permanentFeeId: Scalars['ID'];
  reasonForEnding?: InputMaybe<ReasonForEndingContract>;
  startDate: Scalars['Date'];
  timeslots: Array<CreateContractTimeslotInput>;
};

export type CreateContractTimeslotInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  contractId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  day?: InputMaybe<DayOfWeek>;
  dropOffTime?: InputMaybe<Scalars['Time']>;
  id?: InputMaybe<Scalars['ID']>;
  pickUpTime?: InputMaybe<Scalars['Time']>;
  week?: InputMaybe<WeekType>;
};

export type CreateCurriculumInput = {
  businessId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateCurriculumScheduleInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  end: Scalars['DateTime'];
  name: Scalars['String'];
  start: Scalars['DateTime'];
};

export type CreateCurriculumSubjectInput = {
  color: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCustomFieldInput = {
  area: CustomFieldArea;
  attributes?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  displayOrder: Scalars['Int'];
  label: Scalars['String'];
  notForConsoleDisplay: Scalars['Boolean'];
  type: CustomFieldType;
};

export type CreateDepositInput = {
  accountIds: Array<InputMaybe<Scalars['ID']>>;
  amount: Scalars['Float'];
  appliesDate?: InputMaybe<Scalars['Date']>;
  childId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
};

export type CreateDeviceMessageInput = {
  deviceId: Scalars['String'];
  notificationType?: InputMaybe<DeviceNotificationType>;
};

export type CreateDiscountInput = {
  amount: Scalars['Float'];
  amountType: RateAmountType;
  appliesTo: DiscountAppliesToType;
  applySessionGapDiscountIfNoSubsidy?: InputMaybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  glCode?: InputMaybe<Scalars['String']>;
  glCodeMapping?: InputMaybe<GlCodeMappingInput>;
  name: Scalars['String'];
  startDate: Scalars['Date'];
  type: DiscountType;
};

export type CreateEditKindyForAllCenterAgreementInput = {
  agreementId: Scalars['ID'];
  agreementNumber: Scalars['String'];
  beneficiaryAccountNumber: Scalars['String'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
};

export type CreateEditKindyForAllCredentialInput = {
  applicationKey: Scalars['String'];
  applicationName: Scalars['String'];
  applicationSecret: Scalars['String'];
  businessId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
};

export type CreateEditKindyForAllIneligibilitiesInput = {
  ineligibilities: Array<KindyForAllIneligibilityInput>;
};

export type CreateEditTransitionStatementInput = {
  catholicCount?: InputMaybe<Scalars['Int']>;
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  independentCount?: InputMaybe<Scalars['Int']>;
  stateCount?: InputMaybe<Scalars['Int']>;
};

export type CreateEducationAttachmentInput = {
  attachmentId: Scalars['ID'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
};

export type CreateEducationExperienceInput = {
  attachments?: InputMaybe<Array<Scalars['ID']>>;
  dates: Array<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  outcomes: Array<Scalars['ID']>;
  programIds: Array<Scalars['ID']>;
  subjectId?: InputMaybe<Scalars['ID']>;
  supplies: Array<Scalars['String']>;
};

export type CreateEducationProgramInput = {
  name: Scalars['String'];
};

export type CreateEnrollmentFormInput = {
  businessId: Scalars['ID'];
  formSchema: Scalars['JSON'];
  name: Scalars['String'];
  usedByCenterIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateEnrollmentProgramGroupInput = {
  businessId: Scalars['ID'];
  description: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  programIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateEnrollmentProgramInput = {
  businessId: Scalars['ID'];
  description: Scalars['String'];
  endDate: Scalars['Date'];
  enrolmentCloseAt?: InputMaybe<Scalars['DateTime']>;
  enrolmentOpenAt?: InputMaybe<Scalars['DateTime']>;
  includeCasualContractCount?: InputMaybe<Scalars['Boolean']>;
  isOpenForEnrollment: Scalars['Boolean'];
  maxEnrolmentDays: Scalars['Int'];
  minEnrolmentDays: Scalars['Int'];
  name: Scalars['String'];
  operatingDays: Scalars['String'];
  programCenters: Array<EnrollmentProgramCenterInput>;
  programGroupId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['Date'];
};

export type CreateEntityInput = {
  accountOwnerEmail: Scalars['String'];
  accountOwnerFirstName: Scalars['String'];
  accountOwnerLastName: Scalars['String'];
  address: BusinessAddressInput;
  dataType: DataType;
  name: Scalars['String'];
  phoneNumber: Scalars['Phone'];
  timezone: Scalars['String'];
};

export type CreateFeatureFlagInput = {
  businessId: Scalars['String'];
  name: FeatureFlagName;
  value: FeatureFlagValue;
};

export type CreateFeeInput = {
  centerId: Scalars['ID'];
  classIds: Array<Scalars['ID']>;
  feeStructure?: InputMaybe<FeeStructure>;
  feeType?: InputMaybe<FeeType>;
  glCode?: InputMaybe<Scalars['String']>;
  glCodeMapping?: InputMaybe<GlCodeMappingInput>;
  name: Scalars['String'];
  schedule: CreateFeeScheduleInput;
  utilization: Scalars['Float'];
};

export type CreateFeeScheduleInput = {
  endTime: Scalars['Time'];
  overtimeCost: Scalars['Float'];
  overtimeGracePeriod: Scalars['Int'];
  overtimeInterval: Scalars['Int'];
  rates: Array<FeeRateInput>;
  startTime: Scalars['Time'];
};

export type CreateFileStorageInfoInput = {
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
};

export type CreateFolderInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerGroupId: Scalars['String'];
  parentId: Scalars['String'];
  readerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updaterGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreateGlCodesInput = {
  businessId: Scalars['ID'];
  glCode: Scalars['String'];
  name: Scalars['String'];
  types?: InputMaybe<Array<GlCodeAreaType>>;
};

export type CreateGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreateIntegrationApiKeyInput = {
  apiScopes?: InputMaybe<ApiScopesTypeInput>;
  businessId: Scalars['ID'];
  centerScopes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
};

export type CreateKindyChildFundingSchedulesInput = {
  programChildId: Scalars['String'];
  schedules: Array<KindyFundingScheduleInput>;
};

export type CreateKindyProgramFundingSchedulesInput = {
  programId: Scalars['String'];
  schedules: Array<KindyProgramFundingScheduleInput>;
};

export type CreateKisiShare = {
  businessId: Scalars['ID'];
  kisiEmail?: InputMaybe<Scalars['String']>;
  kisiShareId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  personId: Scalars['ID'];
};

export type CreateLearningStandardDomainInput = {
  learningStandardId: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type CreateLearningStandardInput = {
  ageRanges?: InputMaybe<Array<InputMaybe<CreateAgeRangeInput>>>;
  name: Scalars['String'];
};

export type CreateLearningStandardSubDomainInput = {
  learningStandardDomainId: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type CreateLessonPlanExperienceInput = {
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  subjectId: Scalars['ID'];
};

export type CreateLibraryInput = {
  businessId?: InputMaybe<Scalars['String']>;
  libraryOwnerGroupId?: InputMaybe<Scalars['String']>;
  readerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updaterGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreateLocationInput = {
  centerId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateLockInput = {
  allowParentUnlock: Scalars['Boolean'];
  allowStaffUnlock: Scalars['Boolean'];
  centerId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  lockId: Scalars['Int'];
};

export type CreateManualItemizedBillInput = {
  accountId: Scalars['ID'];
  transactionIds: Array<Scalars['ID']>;
};

export type CreateManualPaymentInput = {
  accountId: Scalars['ID'];
  amount: Scalars['Float'];
  date: Scalars['Date'];
  description?: InputMaybe<Scalars['String']>;
  nonDigitalPaymentType?: InputMaybe<NonDigitalPaymentType>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
};

export type CreateNewChildOnAccountCreateInput = {
  childCcssAttributes?: InputMaybe<ChildCcssAttributesInput>;
  dob?: InputMaybe<Scalars['Date']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
};

export type CreateNoteInput = {
  areaType: NoteAreas;
  associations?: InputMaybe<Array<InputMaybe<NoteAssociationInput>>>;
  attachedToId: Scalars['ID'];
  businessId: Scalars['ID'];
  comment: Scalars['String'];
  noteType: NoteCategories;
  title: Scalars['String'];
};

export type CreateOfferScheduleInput = {
  applicationChildId: Scalars['String'];
  applicationId: Scalars['String'];
  businessId: Scalars['String'];
  careType: Scalars['String'];
  classId?: InputMaybe<Scalars['ID']>;
  cycleType?: InputMaybe<Scalars['String']>;
  days?: InputMaybe<Array<ApplicationScheduleDayInput>>;
  endDate?: InputMaybe<Scalars['Date']>;
  feeAmount?: InputMaybe<Scalars['Float']>;
  feeId?: InputMaybe<Scalars['ID']>;
  offerCenterId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type CreateOrUpdateIntegrationMappingMessage = {
  integrationType: IntegrationType;
  mapping: Array<IntegrationMappingMessage>;
};

export type CreateOutcomeInput = {
  ageRangeOutcomesId: Scalars['ID'];
  description: Scalars['String'];
  order: Scalars['Int'];
};

export type CreatePaymentMethodInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  accountName?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  expirationDate?: InputMaybe<Scalars['Date']>;
  isAutoPay?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  lastFour: Scalars['String'];
  processorId: Scalars['String'];
  routingNumber?: InputMaybe<Scalars['Int']>;
  termsAndConditionsConfirmed?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
  type: PaymentMethodType;
};

export type CreatePermissionInput = {
  action: Scalars['String'];
  roleId: Scalars['String'];
};

export type CreatePhotoInput = {
  centerId?: InputMaybe<Scalars['ID']>;
  childIds: Array<InputMaybe<Scalars['ID']>>;
  classId: Scalars['ID'];
  isVideo?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  photoKey?: InputMaybe<Scalars['String']>;
  staffId: Scalars['ID'];
  takenOn?: InputMaybe<Scalars['String']>;
  thumbnailKey?: InputMaybe<Scalars['String']>;
};

export type CreatePositionInput = {
  businessId: Scalars['ID'];
  payRate?: InputMaybe<Scalars['Float']>;
  positionId: Scalars['ID'];
  scopeId: Scalars['ID'];
  scopeType: ScopeType;
  staffId: Scalars['ID'];
  type?: InputMaybe<PayRateType>;
};

export type CreateProgramLeaveDayInput = {
  businessId: Scalars['ID'];
  data?: InputMaybe<Array<InputMaybe<ProgramLeaveDayInput>>>;
};

export type CreateProviderAdministratorInput = {
  abn: Scalars['String'];
  addresses: Array<ProviderAdninistrationAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  phone: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type CreateProviderFinancialStatusInput = {
  administrator?: InputMaybe<CreateProviderAdministratorInput>;
  businessId: Scalars['ID'];
  endDate: Scalars['DateTime'];
  providerId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  status: ProviderFinancialStatusType;
  supportingDocumentFiles: Array<Scalars['Upload']>;
};

export type CreateProviderInput = {
  businessId: Scalars['ID'];
  communicationsPersonId: Scalars['String'];
  deviceName: Scalars['String'];
  name: Scalars['String'];
  organisationId: Scalars['String'];
  otac: Scalars['String'];
  providerId: Scalars['String'];
};

export type CreatePtoTypesInput = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  code: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  overtimeEligible?: InputMaybe<Scalars['Boolean']>;
  trainingTimeEventType?: InputMaybe<TrainingTimeEventType>;
};

export type CreateRegulationInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  county?: InputMaybe<Scalars['String']>;
  endAge: RegulationAgeInput;
  ratioChildren: Scalars['Int'];
  ratioTeachers: Scalars['Int'];
  startAge: RegulationAgeInput;
  state: Scalars['String'];
};

export type CreateRoleInput = {
  businessId: Scalars['ID'];
  hierarchyLevel: Scalars['Int'];
  name: Scalars['String'];
  scopeType?: InputMaybe<ScopeType>;
};

export type CreateScheduleInput = {
  centerId: Scalars['ID'];
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type CreateSessionInput = {
  accountId: Scalars['ID'];
  childId: Scalars['ID'];
  classId: Scalars['ID'];
  contractId?: InputMaybe<Scalars['ID']>;
  date: Scalars['Date'];
  dropOffTime?: InputMaybe<Scalars['Time']>;
  feeId: Scalars['ID'];
  pickUpTime?: InputMaybe<Scalars['Time']>;
};

export type CreateShiftInput = {
  breakMinutes: Scalars['Int'];
  centerId: Scalars['ID'];
  classId?: InputMaybe<Scalars['ID']>;
  dates: Array<DateTimeRangeInput>;
  locationId?: InputMaybe<Scalars['ID']>;
  note?: InputMaybe<Scalars['String']>;
  paidBreak: Scalars['Boolean'];
  personId?: InputMaybe<Scalars['ID']>;
  positionId?: InputMaybe<Scalars['ID']>;
  scheduleId: Scalars['ID'];
};

export type CreateSpaceInput = {
  centerId: Scalars['ID'];
  color: Scalars['String'];
  icon: SpaceIconEnum;
  name: Scalars['String'];
};

export type CreateStaffAllergyInput = {
  allergenId: Scalars['ID'];
  instructions: Scalars['String'];
  reactions: Array<Scalars['String']>;
  severity?: InputMaybe<AllergenSeverity>;
  staffId: Scalars['ID'];
};

export type CreateStaffFields = {
  email: Scalars['String'];
  employmentStartDate?: InputMaybe<Scalars['DateTime']>;
  entityId: Scalars['ID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  payRate?: InputMaybe<Scalars['Float']>;
  payRateType?: InputMaybe<PayRateType>;
  phoneNumber?: InputMaybe<Scalars['Phone']>;
  positionId: Scalars['ID'];
  primaryCenterId?: InputMaybe<Scalars['ID']>;
  roleship: CreateStaffRoleshipInput;
};

export type CreateStaffInput = {
  sendInvitation?: InputMaybe<Scalars['Boolean']>;
  staff: CreateStaffFields;
};

export type CreateStaffRoleshipInput = {
  roleId: Scalars['String'];
  scopeIds: Array<Scalars['String']>;
  scopeType: Scalars['String'];
};

export type CreateStudentActivityInput = {
  activityTime?: InputMaybe<Scalars['Float']>;
  activityType?: InputMaybe<ActivityType>;
  businessId?: InputMaybe<Scalars['ID']>;
  channelId?: InputMaybe<Scalars['ID']>;
  channelType?: InputMaybe<ChannelType>;
  channelWasRead?: InputMaybe<Scalars['Boolean']>;
  messageContent?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['ID']>;
};

export type CreateSubscriptionInput = {
  billingActivitySuspended?: InputMaybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
};

export type CreateThemeInput = {
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  unitId: Scalars['ID'];
};

export type CreateThemeLessonExperienceInput = {
  attachments?: InputMaybe<Array<Scalars['ID']>>;
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  outcomes: Array<Scalars['ID']>;
  programIds: Array<Scalars['ID']>;
  subjectId?: InputMaybe<Scalars['ID']>;
  supplies: Array<Scalars['String']>;
  themeId: Scalars['ID'];
  themeLessonId: Scalars['ID'];
  unitId: Scalars['ID'];
};

export type CreateThemeLessonInput = {
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  themeId: Scalars['ID'];
  unitId: Scalars['ID'];
};

export type CreateTimeCardLockInput = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  lockedUntil: Scalars['String'];
};

export type CreateTimeEntryInput = {
  centerId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  personId: Scalars['ID'];
  positionId: Scalars['ID'];
  scheduleId?: InputMaybe<Scalars['ID']>;
  shiftId?: InputMaybe<Scalars['ID']>;
  timeIn: Scalars['DateTime'];
  timeOut?: InputMaybe<Scalars['DateTime']>;
  type: TimeEntryType;
};

export type CreateTimeEntryInputV2 = {
  centerId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  personId: Scalars['ID'];
  positionId: Scalars['ID'];
  timeIn: Scalars['DateTime'];
  timeOut?: InputMaybe<Scalars['DateTime']>;
  type: TimeEntryTypeV2;
};

export type CreateTimeEntryV2Data = {
  __typename?: 'CreateTimeEntryV2Data';
  clockedOutTimeEntry?: Maybe<TimeEntry>;
  timeEntry: TimeEntry;
};

export type CreateTimeOffRequestInput = {
  allDay: Scalars['Boolean'];
  centerId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  entityId?: InputMaybe<Scalars['ID']>;
  hoursApproved?: InputMaybe<Scalars['Float']>;
  personId: Scalars['ID'];
  ptoType?: InputMaybe<Scalars['ID']>;
  startTime: Scalars['DateTime'];
};

export type CreateTrainingTimeEventInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  date: Scalars['Date'];
  hours: Scalars['Float'];
  name: Scalars['String'];
  ptoTypeId?: InputMaybe<Scalars['ID']>;
  staffIds: Array<Scalars['ID']>;
  type: TrainingTimeEventType;
};

export type CreateTransactionInput = {
  accountChildId?: InputMaybe<Scalars['ID']>;
  accountId: Scalars['ID'];
  amount: Scalars['Float'];
  appliesDate: Scalars['Date'];
  description?: InputMaybe<Scalars['String']>;
  transactionTypeId: Scalars['ID'];
};

export type CreateTransactionTypeInput = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  transactionTypes: Array<TransactionTypeInput>;
};

export type CreateTransactionsForSessionInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  date: Scalars['String'];
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type CreateTransactionsForSessionsInput = {
  sessionMessages: Array<CreateTransactionsForSessionInput>;
};

export type CreateUnitInput = {
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CrnErrorDetails = {
  __typename?: 'CrnErrorDetails';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  type: Scalars['String'];
};

export type CrnValidation = {
  __typename?: 'CrnValidation';
  details?: Maybe<CrnErrorDetails>;
  error?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
};

export type Curriculum = {
  __typename?: 'Curriculum';
  businessId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  experiencesCount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: CurriculumStatus;
  themesCount: Scalars['Int'];
  units: Array<Unit>;
  unitsCount: Scalars['Int'];
};

export type CurriculumSchedule = {
  __typename?: 'CurriculumSchedule';
  businessId: Scalars['ID'];
  centers?: Maybe<Array<EducationCenter>>;
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  isForEntireBusiness: Scalars['Boolean'];
  name: Scalars['String'];
  numberOfDays: Scalars['Int'];
  start: Scalars['DateTime'];
  status: CurriculumScheduleStatus;
};

export type CurriculumScheduleProgramTheme = {
  __typename?: 'CurriculumScheduleProgramTheme';
  curriculumScheduleId: Scalars['ID'];
  end: Scalars['DateTime'];
  experiences: Array<CurriculumScheduleThemeProgramExperiences>;
  id: Scalars['ID'];
  program?: Maybe<EducationProgram>;
  programId: Scalars['ID'];
  start: Scalars['DateTime'];
  theme?: Maybe<Theme>;
  themeId: Scalars['ID'];
  themeName: Scalars['String'];
};

export enum CurriculumScheduleStatus {
  Draft = 'Draft',
  Public = 'Public',
}

export type CurriculumScheduleThemeProgramExperiences = {
  __typename?: 'CurriculumScheduleThemeProgramExperiences';
  date: Scalars['DateTime'];
  experiences: Array<EducationExperience>;
};

export enum CurriculumStatus {
  Draft = 'Draft',
  Public = 'Public',
}

export type CurriculumSubject = {
  __typename?: 'CurriculumSubject';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: EducationPerson;
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  name: Scalars['String'];
};

export type CustodyChildren = {
  __typename?: 'CustodyChildren';
  accountChildId: Scalars['ID'];
  checkInTime?: Maybe<Scalars['DateTime']>;
  checkOutTime?: Maybe<Scalars['DateTime']>;
  enrolledClassId: Scalars['ID'];
};

export type CustodyInput = {
  accountChildId: Scalars['ID'];
  centerId: Scalars['ID'];
  classId: Scalars['ID'];
  contractId?: InputMaybe<Scalars['ID']>;
  enrolledClassId: Scalars['ID'];
  isCustodyTaken: Scalars['Boolean'];
  sessionDate: Scalars['Date'];
  sessionId?: InputMaybe<Scalars['ID']>;
  staffId: Scalars['ID'];
};

export type CustodyResponse = {
  __typename?: 'CustodyResponse';
  data?: Maybe<CustodyChildren>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum CustodyStatus {
  CheckedIn = 'CHECKED_IN',
  CheckedOut = 'CHECKED_OUT',
  DayComplete = 'DAY_COMPLETE',
  Expected = 'EXPECTED',
  Missing = 'MISSING',
  PlannedAbsence = 'PLANNED_ABSENCE',
  Present = 'PRESENT',
}

export type CustomField = {
  __typename?: 'CustomField';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['ID']>;
  area: CustomFieldArea;
  attributesAsString?: Maybe<Scalars['String']>;
  businessId: Scalars['ID'];
  centerIds?: Maybe<Array<Scalars['ID']>>;
  displayDateFormat?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  groupQuestionSchemaAsString?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<CustomFieldGroup>>>;
  id: Scalars['ID'];
  label: Scalars['String'];
  notForConsoleDisplay: Scalars['Boolean'];
  type: CustomFieldType;
  valuesAsString?: Maybe<Scalars['String']>;
};

export enum CustomFieldArea {
  Account = 'Account',
  Child = 'Child',
  Contact = 'Contact',
  MedicalProfessional = 'MedicalProfessional',
}

export type CustomFieldDocuemntFile = {
  __typename?: 'CustomFieldDocuemntFile';
  entityID: Scalars['ID'];
  fileID?: Maybe<Scalars['ID']>;
};

export type CustomFieldDocument = {
  __typename?: 'CustomFieldDocument';
  archivedAt?: Maybe<Scalars['String']>;
  archivedBy?: Maybe<Scalars['String']>;
  businessId: Scalars['ID'];
  centerId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  wellnessItemId: Scalars['String'];
};

export type CustomFieldDocumentFile = {
  __typename?: 'CustomFieldDocumentFile';
  entityId?: Maybe<Scalars['ID']>;
  fileId?: Maybe<Scalars['ID']>;
};

export enum CustomFieldDocumentType {
  CustomField = 'CustomField',
}

export type CustomFieldDocumentValuesResponse = {
  __typename?: 'CustomFieldDocumentValuesResponse';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  centerId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  createdByPerson: Staff;
  customFieldArea?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  link: Scalars['URL'];
  name: Scalars['String'];
  reviewDate?: Maybe<Scalars['Date']>;
  reviewDaysBeforeExpiration?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  wellnessItemId: Scalars['ID'];
};

export type CustomFieldFileUploadInput = {
  customFieldId: Scalars['ID'];
  file: Scalars['Upload'];
  filename?: InputMaybe<Scalars['String']>;
};

export type CustomFieldGroup = {
  __typename?: 'CustomFieldGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CustomFieldPagedResult = {
  __typename?: 'CustomFieldPagedResult';
  data: Array<CustomField>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum CustomFieldType {
  Address = 'Address',
  Checkbox = 'Checkbox',
  ChildrenCheckboxSelector = 'ChildrenCheckboxSelector',
  ChildrenSelector = 'ChildrenSelector',
  Combo = 'Combo',
  ContactSelector = 'ContactSelector',
  Date = 'Date',
  DateText = 'DateText',
  Document = 'Document',
  GroupedFields = 'GroupedFields',
  HtmlText = 'HtmlText',
  MedicareText = 'MedicareText',
  MultiSelectCombo = 'MultiSelectCombo',
  PhoneNumber = 'PhoneNumber',
  Radio = 'Radio',
  Signature = 'Signature',
  Text = 'Text',
}

export type CustomFieldValue = {
  __typename?: 'CustomFieldValue';
  customFieldId: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};

export type CustomFieldValueDataDiff = {
  __typename?: 'CustomFieldValueDataDiff';
  diffCount: Scalars['Int'];
  diffState: DiffState;
  id: Scalars['String'];
  name: Scalars['String'];
  newValue: Scalars['String'];
  oldValue: Scalars['String'];
  thirdParty: Scalars['Boolean'];
};

export type CustomFieldValueInput = {
  customFieldId: Scalars['ID'];
  value?: InputMaybe<Scalars['String']>;
};

export type CustomFieldValuesResponse = {
  __typename?: 'CustomFieldValuesResponse';
  area?: Maybe<CustomFieldArea>;
  centerId: Scalars['ID'];
  customFields?: Maybe<Array<Maybe<CustomField>>>;
  entityId: Scalars['ID'];
  values?: Maybe<Array<Maybe<CustomFieldValue>>>;
};

export type CustomFieldsReportInput = {
  accountStatusType?: InputMaybe<AccountStatusType>;
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  classIds?: InputMaybe<Array<Scalars['String']>>;
  customFieldIds: Array<Scalars['String']>;
  customFieldReportType: CustomFieldArea;
  inclNotForConsoleDisplay: Scalars['Boolean'];
  showPrimaryContactsOnly?: InputMaybe<Scalars['Boolean']>;
  tags: Array<Scalars['String']>;
};

export type DailyAttendance = {
  __typename?: 'DailyAttendance';
  centerId: Scalars['ID'];
  date: Scalars['String'];
  personId: Scalars['ID'];
  shifts: Array<Shift>;
  timeEntries: Array<TimeEntry>;
  totalHoursScheduled: TotalTime;
  totalHoursWorked: TotalTime;
  trainingTimeEvents: Array<TrainingTimeEvent>;
};

export type DailyChannelMessageCount = {
  __typename?: 'DailyChannelMessageCount';
  accountChild?: Maybe<AccountChild>;
  channelId: Scalars['ID'];
  date: Scalars['String'];
  messageCount: Scalars['Int'];
};

export type DailyPhotoCounts = {
  __typename?: 'DailyPhotoCounts';
  centerId: Scalars['ID'];
  childCount: Scalars['Int'];
  childPhotoCounts: Array<Maybe<ChildPhotoCount>>;
  classId: Scalars['ID'];
  date: Scalars['String'];
  photoCount: Scalars['Int'];
};

export enum DataType {
  Churned = 'CHURNED',
  InternalTest = 'INTERNAL_TEST',
  Prod = 'PROD',
  Sandbox = 'SANDBOX',
  Unknown = 'UNKNOWN',
}

export type DateTimeRange = {
  __typename?: 'DateTimeRange';
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type DateTimeRangeInput = {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DeactivateStaffInput = {
  id: Scalars['ID'];
  reasonForLeaving: ReasonForLeaving;
};

export type Debt = {
  __typename?: 'Debt';
  ccssProviderId: Scalars['String'];
  ccssServiceId?: Maybe<Scalars['String']>;
  debtId: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  issuedDate?: Maybe<Scalars['DateTime']>;
  legislationReferenceForTheDebt?: Maybe<Scalars['String']>;
  originalAmount: Scalars['Float'];
  outstandingBalance: Scalars['Float'];
  providerName: Scalars['String'];
  reason: Scalars['String'];
  serviceName?: Maybe<Scalars['String']>;
  status: DebtStatus;
  totalInterestCharged?: Maybe<Scalars['Float']>;
};

export type DebtReportInput = {
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  excludeCredit: Scalars['Boolean'];
  excludeZero: Scalars['Boolean'];
  runAtDate: Scalars['String'];
  statusType?: InputMaybe<AccountStatusType>;
  transactionDateType?: InputMaybe<TransactionDate>;
};

export enum DebtStatus {
  Det = 'DET',
  Dmc = 'DMC',
  Dwo = 'DWO',
  Paj = 'PAJ',
  Pfd = 'PFD',
  Pin = 'PIN',
  Pwo = 'PWO',
  Pwv = 'PWV',
}

export enum DecisionMakingAgency {
  Dese = 'DESE',
  Sa = 'SA',
}

export type DelayedExit = {
  __typename?: 'DelayedExit';
  accountChildId: Scalars['ID'];
  documentUri?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type DeleteAnnouncementInput = {
  id: Scalars['ID'];
};

export type DeleteApplicationNoteInput = {
  noteId: Scalars['ID'];
};

export type DeleteCustomFieldFileInput = {
  area: Scalars['String'];
  businessId: Scalars['ID'];
  customFieldId: Scalars['ID'];
  documentId: Scalars['ID'];
  entityId: Scalars['ID'];
};

export type DeleteEntityInput = {
  id: Scalars['ID'];
};

export type DeleteLearningStandardDomainInput = {
  learningStandardDomainId: Scalars['ID'];
  learningStandardId: Scalars['ID'];
};

export type DeleteLearningStandardSubDomainInput = {
  learningStandardDomainId: Scalars['ID'];
  learningStandardSubDomainId: Scalars['ID'];
};

export type DeleteMessageInput = {
  businessId?: InputMaybe<Scalars['ID']>;
  channelId?: InputMaybe<Scalars['ID']>;
  channelWasRead?: InputMaybe<Scalars['Boolean']>;
  deleteReasonText?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['ID']>;
  timeToken?: InputMaybe<Scalars['Float']>;
};

export type DeleteProgramLeaveDayInput = {
  businessId: Scalars['ID'];
  data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DeleteRegulationInput = {
  id: Scalars['ID'];
};

export type DeleteRoleInput = {
  businessId: Scalars['ID'];
  roleId: Scalars['ID'];
};

export type DeleteServiceTemporarilyCeasingInput = {
  businessId: Scalars['ID'];
  endDate: Scalars['DateTime'];
  reasonType: ServiceTemporarilyCeasingReasonType;
  serviceId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export type DeleteSessionTimeEntryInput = {
  sessionId: Scalars['ID'];
  timeEntryId: Scalars['ID'];
};

export type DeleteShiftsInput = {
  centerId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};

export type DeleteSpaceInput = {
  id: Scalars['ID'];
};

export type DeleteTimeEntryInput = {
  centerId: Scalars['ID'];
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['ID']>;
  positionId?: InputMaybe<Scalars['ID']>;
  scheduleId?: InputMaybe<Scalars['ID']>;
  shiftId?: InputMaybe<Scalars['ID']>;
  trackedTimeIn?: InputMaybe<Scalars['DateTime']>;
  trackedTimeOut?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<TimeEntryType>;
};

export type DeleteTrainingTimeEventInput = {
  id: Scalars['String'];
};

export type DeletedAcquittalDto = {
  __typename?: 'DeletedAcquittalDto';
  acquittalId: Scalars['ID'];
  agreementId: Scalars['ID'];
  id: Scalars['ID'];
  period: Scalars['String'];
  year: Scalars['Int'];
};

export type DeletedBatchData = {
  __typename?: 'DeletedBatchData';
  batch: Batch;
  unbatchedPaymentTransactions: Array<Transaction>;
};

export type DeletedForecastDto = {
  __typename?: 'DeletedForecastDto';
  agreementId: Scalars['ID'];
  forecastId: Scalars['ID'];
  id: Scalars['ID'];
  period: Scalars['String'];
  year: Scalars['Int'];
};

export type Deposit = {
  __typename?: 'Deposit';
  accountId: Scalars['ID'];
  amount: Scalars['Float'];
  centerId?: Maybe<Scalars['ID']>;
  childId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  transactionId: Scalars['ID'];
};

export type DepositsHeldReportInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export enum DeterminationStatus {
  Approv = 'APPROV',
  Reject = 'REJECT',
  Revoke = 'REVOKE',
}

export type Device = {
  __typename?: 'Device';
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  notificationType?: Maybe<DeviceNotificationType>;
  personId: Scalars['String'];
};

export enum DeviceNotificationType {
  OneSignal = 'OneSignal',
}

export type DiffDto = {
  __typename?: 'DiffDto';
  diffCount: Scalars['Int'];
  diffState: DiffState;
  name: Scalars['String'];
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
};

export enum DiffState {
  Added = 'Added',
  Removed = 'Removed',
  Unchanged = 'Unchanged',
  Updated = 'Updated',
}

export enum DifferenceActionType {
  Adjust = 'ADJUST',
  None = 'NONE',
  TransferToAccount = 'TRANSFER_TO_ACCOUNT',
}

export type DirectDebitPaymentMethod = {
  __typename?: 'DirectDebitPaymentMethod';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bsb?: Maybe<Scalars['String']>;
};

export type DisbursementsMonthlyInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  month: Scalars['Date'];
};

export type Discount = {
  __typename?: 'Discount';
  appliesTo: DiscountAppliesToType;
  applySessionGapDiscountIfNoSubsidy?: Maybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  centerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  glCode?: Maybe<Scalars['String']>;
  glCodeMapping?: Maybe<GlCodeMapping>;
  id: Scalars['ID'];
  name: Scalars['String'];
  schedules: Array<DiscountSchedule>;
  type: DiscountType;
};

export enum DiscountAppliesToType {
  Balance = 'BALANCE',
  BillingCycle = 'BILLING_CYCLE',
  Session = 'SESSION',
  SessionGap = 'SESSION_GAP',
  Transaction = 'TRANSACTION',
}

export enum DiscountAuthType {
  Delete = 'DELETE',
  End = 'END',
  Unknown = 'UNKNOWN',
}

export type DiscountSchedule = {
  __typename?: 'DiscountSchedule';
  amount: Scalars['Float'];
  amountType: RateAmountType;
  discountId: Scalars['ID'];
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  startDate: Scalars['Date'];
};

export enum DiscountType {
  Manual = 'MANUAL',
  Recurring = 'RECURRING',
}

export type DissociateStaffFromCenterInput = {
  centerId: Scalars['ID'];
  id: Scalars['ID'];
};

export type Document = {
  expirationDate?: InputMaybe<Scalars['Date']>;
  file: Scalars['Upload'];
  filename?: InputMaybe<Scalars['String']>;
  reviewDate?: InputMaybe<Scalars['Date']>;
  reviewDaysBeforeExpiration?: InputMaybe<Scalars['Int']>;
};

export type DocumentPagedResult = {
  __typename?: 'DocumentPagedResult';
  data: Array<CoreDocument>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum DocumentType {
  Allergy = 'Allergy',
  ConcessionCard = 'ConcessionCard',
  CustomField = 'CustomField',
  DelayedExit = 'DelayedExit',
  EnrolmentForm = 'EnrolmentForm',
  FamilyTaxBenefit = 'FamilyTaxBenefit',
  Immunization = 'Immunization',
  Medical = 'Medical',
  Restriction = 'Restriction',
  StartStrongDeclarationForm = 'StartStrongDeclarationForm',
  StartStrongSchoolExemption = 'StartStrongSchoolExemption',
}

export type DocumentUpload = {
  __typename?: 'DocumentUpload';
  expiryDate: Scalars['String'];
  file: Scalars['String'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
};

export type DocumentUploadDiff = {
  __typename?: 'DocumentUploadDiff';
  diffCount?: Maybe<Scalars['Int']>;
  diffState?: Maybe<DiffState>;
  id?: Maybe<Scalars['String']>;
  systemFields?: Maybe<Array<DiffDto>>;
};

export type EditApplicationChildMessage = {
  applicationChildId: Scalars['ID'];
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  editApplicationScheduleEnquiryMessages?: InputMaybe<Array<InputMaybe<EditApplicationScheduleEnquiryMessage>>>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type EditApplicationInput = {
  applicationId: Scalars['ID'];
  businessId: Scalars['ID'];
  editApplicationChildMessages?: InputMaybe<Array<InputMaybe<EditApplicationChildMessage>>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type EditApplicationNoteInput = {
  comment: Scalars['String'];
  noteId: Scalars['ID'];
};

export type EditApplicationScheduleEnquiryDaysMessage = {
  dayOfWeek: DayOfWeek;
  weekType: WeekType;
};

export type EditApplicationScheduleEnquiryMessage = {
  applicationScheduleEnquiryId: Scalars['ID'];
  cycleType?: InputMaybe<ContractCycleType>;
  editApplicationScheduleEnquiryDaysMessages?: InputMaybe<Array<InputMaybe<EditApplicationScheduleEnquiryDaysMessage>>>;
};

export type EditCcssApprovalInput = {
  careType: Scalars['String'];
  centerId: Scalars['ID'];
  id: Scalars['ID'];
  serviceId: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type EditCcssCertificateInput = {
  accountId: Scalars['String'];
  businessId: Scalars['String'];
  centerId: Scalars['String'];
  certificateId: Scalars['ID'];
  isDeclarationGiven: Scalars['Boolean'];
  stateTerritoryBody?: InputMaybe<StateTerritoryBodyInput>;
  supportingDocuments?: InputMaybe<Array<SupportingDocumentInput>>;
};

export type EditChildCcsEnrolmentInput = {
  ccsType: Scalars['String'];
  centerId: Scalars['ID'];
  childInStateCare: Scalars['Boolean'];
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  lateSubmissionReason?: InputMaybe<Scalars['String']>;
  signingParty: SigningParty;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type EditFee = {
  classId: Scalars['ID'];
  contractIds: Array<Scalars['ID']>;
  date: Scalars['Date'];
  feeId: Scalars['ID'];
  sessionIds: Array<Scalars['ID']>;
};

export type EditFeeInput = {
  centerId: Scalars['ID'];
  classIds: Array<Scalars['ID']>;
  deactivatedAt?: InputMaybe<Scalars['DateTime']>;
  feeStructure: FeeStructure;
  feeType?: InputMaybe<FeeType>;
  glCode?: InputMaybe<Scalars['String']>;
  glCodeMapping?: InputMaybe<GlCodeMappingInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
  schedules: Array<EditFeeScheduleInput>;
  utilization: Scalars['Float'];
};

export type EditFeeResult = {
  __typename?: 'EditFeeResult';
  errors: Array<ClassError>;
  updatedSessions: Array<Session>;
};

export type EditFeeScheduleInput = {
  endTime: Scalars['Time'];
  id?: InputMaybe<Scalars['ID']>;
  overtimeCost: Scalars['Float'];
  overtimeGracePeriod: Scalars['Int'];
  overtimeInterval: Scalars['Int'];
  rates: Array<FeeRateInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  startTime: Scalars['Time'];
};

export type EditFeesInput = {
  centerId: Scalars['ID'];
  fees: Array<EditFee>;
};

export type EducationAttachment = {
  __typename?: 'EducationAttachment';
  createdAt: Scalars['DateTime'];
  createdById: Scalars['ID'];
  downloadUrl?: Maybe<Scalars['URL']>;
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedById?: Maybe<Scalars['ID']>;
  mimeType: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Int'];
  viewUrl?: Maybe<Scalars['URL']>;
};

export type EducationAttachmentPagedResult = {
  __typename?: 'EducationAttachmentPagedResult';
  items: Array<EducationAttachment>;
  numberRequested: Scalars['Int'];
  start: Scalars['Int'];
  totalNumber?: Maybe<Scalars['Int']>;
};

export type EducationCenter = {
  __typename?: 'EducationCenter';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EducationDailyExperienceAssignment = {
  __typename?: 'EducationDailyExperienceAssignment';
  date: Scalars['DateTime'];
  experience: EducationExperience;
  experienceId: Scalars['ID'];
  id: Scalars['ID'];
  program?: Maybe<EducationProgram>;
  programId?: Maybe<Scalars['ID']>;
};

export type EducationDailyExperienceAssignmentGroup = {
  __typename?: 'EducationDailyExperienceAssignmentGroup';
  date: Scalars['DateTime'];
  experienceAssignments: Array<EducationDailyExperienceAssignment>;
  programId?: Maybe<Scalars['ID']>;
};

export type EducationExperience = {
  __typename?: 'EducationExperience';
  assignedPrograms: Array<EducationProgram>;
  attachments: Array<EducationAttachment>;
  businessId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<EducationPerson>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  name: Scalars['String'];
  outcomes: Array<EducationExperienceOutcomeItem>;
  subject?: Maybe<CurriculumSubject>;
  subjectId?: Maybe<Scalars['ID']>;
  supplies: Array<EducationExperienceSupplyItem>;
};

export type EducationExperienceOutcomeItem = {
  __typename?: 'EducationExperienceOutcomeItem';
  ageRange: AgeRange;
  description: Scalars['String'];
  domainId: Scalars['ID'];
  learningStandardId: Scalars['ID'];
  outcomeId: Scalars['ID'];
  outcomePath: Scalars['String'];
};

export type EducationExperienceSupplyItem = {
  __typename?: 'EducationExperienceSupplyItem';
  experienceId: Scalars['ID'];
  id: Scalars['ID'];
  supplyId: Scalars['ID'];
  supplyName: Scalars['String'];
};

export type EducationExperienceSupplyItemInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type EducationFeature = {
  __typename?: 'EducationFeature';
  feature: EducationFeatureEnum;
  isEnabled: Scalars['Boolean'];
};

export enum EducationFeatureEnum {
  CurriculumManagement = 'CurriculumManagement',
  EducationNotes = 'EducationNotes',
  LessonPlans = 'LessonPlans',
  ResourceLibrary = 'ResourceLibrary',
}

export type EducationPermission = {
  __typename?: 'EducationPermission';
  category?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<GroupSummary>>>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EducationPermissionsResponse = {
  __typename?: 'EducationPermissionsResponse';
  permissions: Array<Maybe<EducationPermission>>;
};

export type EducationPerson = {
  __typename?: 'EducationPerson';
  actions: Array<Maybe<Scalars['String']>>;
  businessId: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<GroupSummary>>>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  userName?: Maybe<Scalars['String']>;
};

export type EducationProgram = {
  __typename?: 'EducationProgram';
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EducationStaff = {
  __typename?: 'EducationStaff';
  id: Scalars['ID'];
  name: Scalars['String'];
  userName: Scalars['String'];
};

export type EmailAddressReportInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  contactTagId?: InputMaybe<Scalars['ID']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  statusList?: InputMaybe<Array<AccountStatusType>>;
};

export type EmailSendResponse = {
  __typename?: 'EmailSendResponse';
  messageId: Scalars['String'];
  personId: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum EmailTemplate {
  Invite = 'invite',
  RequestToJoin = 'requestToJoin',
}

export type EmbedReport = {
  __typename?: 'EmbedReport';
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  reportId?: Maybe<Scalars['String']>;
  slot?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  token?: Maybe<EmbedReportToken>;
};

export type EmbedReportExport = {
  __typename?: 'EmbedReportExport';
  environmentId?: Maybe<Scalars['String']>;
  export?: Maybe<EmbedReportExportDetails>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  reportId?: Maybe<Scalars['String']>;
  slot?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
};

export type EmbedReportExportDetails = {
  __typename?: 'EmbedReportExportDetails';
  downloadUrl?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  powerBiExportId?: Maybe<Scalars['String']>;
};

export type EmbedReportInput = {
  businessId?: InputMaybe<Scalars['String']>;
  embedContextId?: InputMaybe<Scalars['String']>;
  reportId?: InputMaybe<Scalars['String']>;
  reportName?: InputMaybe<Scalars['String']>;
};

export type EmbedReportToken = {
  __typename?: 'EmbedReportToken';
  expiresAt?: Maybe<Scalars['String']>;
  powerBiDatasetId?: Maybe<Scalars['String']>;
  powerBiReportId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  childId: Scalars['ID'];
  contactId: Scalars['ID'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<PhoneNumber>;
  relationshipType: RelationshipType;
};

export type EmergencyContactData = ContactData & {
  __typename?: 'EmergencyContactData';
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  relationship: Scalars['String'];
};

export type EmergencyContactInput = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberType?: InputMaybe<Scalars['String']>;
  relationship: Scalars['String'];
};

export type EmergencyContactWithPhoneTypeInput = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  phoneNumber: PhoneNumberInput;
  type: Scalars['String'];
};

export type EmployeeTimeSheetReportInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  endDate: Scalars['String'];
  staffIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startDate: Scalars['String'];
};

export enum EmploymentStatus {
  Active = 'Active',
  Created = 'Created',
  Deactivated = 'Deactivated',
  Invited = 'Invited',
  Pending = 'Pending',
  Requested = 'Requested',
}

export type EndAndCreateContractInput = {
  endDate: Scalars['Date'];
  endedId: Scalars['ID'];
  newContract: CreateContractInput;
  reasonForEnding: ReasonForEndingContract;
};

export type EndCareEnrollment = {
  __typename?: 'EndCareEnrollment';
  accountChildId: Scalars['ID'];
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childFirstName: Scalars['String'];
  childId: Scalars['ID'];
  childLastName: Scalars['String'];
  enrollmentId?: Maybe<Scalars['String']>;
  sessionIds: Array<Scalars['ID']>;
  status?: Maybe<CcsEnrolmentStatus>;
  totalDue: Scalars['Float'];
};

export type EndCareEnrollmentHistoryAction = {
  __typename?: 'EndCareEnrollmentHistoryAction';
  accountChildId: Scalars['ID'];
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  actionType: EndCareHistoryActionType;
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childFirstName: Scalars['String'];
  childId: Scalars['ID'];
  childLastName: Scalars['String'];
  date: Scalars['String'];
  enrollmentId?: Maybe<Scalars['String']>;
  person?: Maybe<Staff>;
  personId: Scalars['ID'];
  sessionIds: Array<Scalars['ID']>;
  status?: Maybe<CcsEnrolmentStatus>;
  totalDue: Scalars['Float'];
  transactionId: Scalars['ID'];
};

export type EndCareEnrollmentHistoryPagedResult = {
  __typename?: 'EndCareEnrollmentHistoryPagedResult';
  data: Array<EndCareEnrollmentHistoryAction>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type EndCareEnrollmentMetrics = {
  __typename?: 'EndCareEnrollmentMetrics';
  accountCount: Scalars['Int'];
  sessionCount: Scalars['Int'];
  sessionIds: Array<Scalars['ID']>;
  totalDue: Scalars['Float'];
};

export type EndCareEnrollmentPagedResult = {
  __typename?: 'EndCareEnrollmentPagedResult';
  data: Array<EndCareEnrollment>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum EndCareHistoryActionType {
  ChargeFullFee = 'CHARGE_FULL_FEE',
  Hide = 'HIDE',
  Reveal = 'REVEAL',
  Unhandled = 'UNHANDLED',
  Void = 'VOID',
}

export type EndedContractsReportInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type EnrolApplicationDeposit = {
  amount: Scalars['Float'];
  appliesDate?: InputMaybe<Scalars['Date']>;
  centerId: Scalars['String'];
  collected: Scalars['Boolean'];
  transactionId?: InputMaybe<Scalars['String']>;
};

export type Enrollment = {
  __typename?: 'Enrollment';
  child: Child;
  childId: Scalars['ID'];
  class: Class;
  classId: Scalars['ID'];
  contract: Contract;
  endDate?: Maybe<Scalars['DateTime']>;
  expectedAgeUpDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['DateTime']>;
  status: EnrollmentStatus;
  timeslots: Array<ContractTimeslot>;
};

export type EnrollmentAvailabilityByDayOfWeek = {
  __typename?: 'EnrollmentAvailabilityByDayOfWeek';
  dayOfWeek?: Maybe<Scalars['String']>;
  openSpotsAm: Scalars['Int'];
  openSpotsPm: Scalars['Int'];
  totalOpenSpots: Scalars['Int'];
};

export type EnrollmentDiff = {
  __typename?: 'EnrollmentDiff';
  billingCycle?: Maybe<BillingCycleDiff>;
  children: Array<ChildDiff>;
  contacts: Array<ContactDiff>;
  customFields: Array<CustomFieldValueDataDiff>;
  diffCount: Scalars['Int'];
  diffState: DiffState;
  payment: PaymentDiff;
  primaryContacts: Array<ContactDiff>;
  systemFields: Array<DiffDto>;
};

export type EnrollmentForm = {
  __typename?: 'EnrollmentForm';
  businessId: Scalars['ID'];
  formSchema: Scalars['JSON'];
  id?: Maybe<Scalars['ID']>;
  isDraft: Scalars['Boolean'];
  name: Scalars['String'];
  usedByCenterIds?: Maybe<Array<Scalars['ID']>>;
  version: Scalars['Int'];
};

export type EnrollmentFormFieldStatus = {
  __typename?: 'EnrollmentFormFieldStatus';
  customFieldId: Scalars['ID'];
  formId: Scalars['ID'];
  formName: Scalars['String'];
  formVersion: Scalars['Int'];
  useCount?: Maybe<Scalars['Int']>;
};

export type EnrollmentFormPagedResult = {
  __typename?: 'EnrollmentFormPagedResult';
  data: Array<EnrollmentForm>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum EnrollmentOptions {
  CasualOnly = 'CasualOnly',
  PermanentAndCasual = 'PermanentAndCasual',
  PermanentOnly = 'PermanentOnly',
}

export type EnrollmentProgram = {
  __typename?: 'EnrollmentProgram';
  businessId: Scalars['String'];
  description: Scalars['String'];
  endDate: Scalars['Date'];
  enrolmentCloseAt?: Maybe<Scalars['DateTime']>;
  enrolmentOpenAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  includeCasualContractCount?: Maybe<Scalars['Boolean']>;
  isOpenForEnrollment: Scalars['Boolean'];
  maxEnrolmentDays: Scalars['Int'];
  minEnrolmentDays: Scalars['Int'];
  name: Scalars['String'];
  operatingDays: Array<Maybe<Scalars['String']>>;
  programCenters: Array<EnrollmentProgramCenter>;
  programGroup?: Maybe<EnrollmentProgramGroup>;
  programGroupId?: Maybe<Scalars['ID']>;
  startDate: Scalars['Date'];
};

export type EnrollmentProgramCenter = {
  __typename?: 'EnrollmentProgramCenter';
  applicationFeeAmount?: Maybe<Scalars['Float']>;
  applicationFeeType?: Maybe<ApplicationFeeType>;
  casualFeeId?: Maybe<Scalars['ID']>;
  casualFeeName?: Maybe<Scalars['String']>;
  centerId: Scalars['ID'];
  centerName?: Maybe<Scalars['String']>;
  classId: Scalars['ID'];
  className?: Maybe<Scalars['String']>;
  enrollmentOptions?: Maybe<EnrollmentOptions>;
  feeId: Scalars['ID'];
  feeName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  programId: Scalars['ID'];
};

export type EnrollmentProgramCenterInput = {
  applicationFeeAmount?: InputMaybe<Scalars['Float']>;
  applicationFeeType?: InputMaybe<ApplicationFeeType>;
  casualFeeId?: InputMaybe<Scalars['ID']>;
  casualFeeName?: InputMaybe<Scalars['String']>;
  centerId: Scalars['ID'];
  classId: Scalars['ID'];
  enrollmentOptions?: InputMaybe<EnrollmentOptions>;
  feeId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
};

export type EnrollmentProgramGroup = {
  __typename?: 'EnrollmentProgramGroup';
  businessId: Scalars['ID'];
  description: Scalars['String'];
  endDt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  programIds?: Maybe<Array<Scalars['ID']>>;
  programs?: Maybe<Array<Maybe<EnrollmentProgram>>>;
  startDt?: Maybe<Scalars['Date']>;
};

export type EnrollmentProgramGroupPagedResult = {
  __typename?: 'EnrollmentProgramGroupPagedResult';
  data: Array<EnrollmentProgramGroup>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type EnrollmentProgramPagedResult = {
  __typename?: 'EnrollmentProgramPagedResult';
  data: Array<EnrollmentProgram>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum EnrollmentStatus {
  Enrolled = 'ENROLLED',
  Pending = 'PENDING',
  Waitlist = 'WAITLIST',
}

export enum EnrollmentTimeOfDay {
  Afternoon = 'AFTERNOON',
  Morning = 'MORNING',
}

export enum EnrollmentTimeslotType {
  Am = 'AM',
  FullDay = 'FULL_DAY',
  Pm = 'PM',
}

export enum EnrolmentCategory {
  ActionRequired = 'ActionRequired',
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending',
}

export enum EnrolmentSource {
  Inquiry = 'Inquiry',
  ReenrollmentAtExistingCenter = 'ReenrollmentAtExistingCenter',
  ReenrollmentAtNewCenter = 'ReenrollmentAtNewCenter',
}

export type Entity = {
  __typename?: 'Entity';
  accountOwner?: Maybe<Staff>;
  accountOwnerPersonId?: Maybe<Scalars['ID']>;
  accountOwners: Array<Staff>;
  address?: Maybe<Address>;
  applicationFlowType?: Maybe<ApplicationFlowType>;
  avatar?: Maybe<S3SignedUrl>;
  centers?: Maybe<Array<Maybe<Center>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dataType?: Maybe<DataType>;
  email?: Maybe<Scalars['String']>;
  hubSpotCustomerId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviteCode?: Maybe<Scalars['String']>;
  isGlCodeMandatory?: Maybe<Scalars['Boolean']>;
  kisiApiKey?: Maybe<Scalars['String']>;
  locks?: Maybe<Array<Maybe<Lock>>>;
  name: Scalars['String'];
  paymentMethods: Array<PaymentMethod>;
  payrixCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['Phone']>;
  primaryContact?: Maybe<Staff>;
  primaryContactPersonId?: Maybe<Scalars['ID']>;
  primaryPaymentMethod?: Maybe<PaymentMethod>;
  primaryPaymentMethodId?: Maybe<Scalars['ID']>;
  secondaryContact?: Maybe<Staff>;
  secondaryContactPersonId?: Maybe<Scalars['ID']>;
  subscription?: Maybe<Subscription>;
  tags: Array<Tag>;
  timezone: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type ErrorDto = {
  __typename?: 'ErrorDto';
  code: Scalars['String'];
  innerError?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  target?: Maybe<Scalars['String']>;
};

export type EventSpecialAbsence = {
  __typename?: 'EventSpecialAbsence';
  available: Scalars['Int'];
  childCrn: Scalars['String'];
  childFirstName?: Maybe<Scalars['String']>;
  childLastName?: Maybe<Scalars['String']>;
  childMiddleName?: Maybe<Scalars['String']>;
  consumed: Scalars['Int'];
  enrolmentId: Scalars['String'];
};

export type EventSpecialCondition = {
  __typename?: 'EventSpecialCondition';
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  type: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export enum ExceptionalCircumstanceReason {
  Beyond = 'BEYOND',
  Other = 'OTHER',
}

export type ExistingChildrenMapping = {
  __typename?: 'ExistingChildrenMapping';
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  ccrmChildDateOfBirth?: Maybe<Scalars['String']>;
  ccrmChildFullName?: Maybe<Scalars['String']>;
  ccrmChildId?: Maybe<Scalars['String']>;
  ccrmFamilyId?: Maybe<Scalars['String']>;
  ccrmGuardianFullName?: Maybe<Scalars['String']>;
  childId: Scalars['ID'];
  id: Scalars['ID'];
  ktChildAccountName?: Maybe<Scalars['String']>;
  ktChildDateOfBirth?: Maybe<Scalars['String']>;
  ktChildFullName?: Maybe<Scalars['String']>;
  ktPrimaryContactName?: Maybe<Scalars['String']>;
  mappingGroupId: Scalars['ID'];
  selected?: Maybe<Scalars['Boolean']>;
};

export type ExistingContactMapping = {
  __typename?: 'ExistingContactMapping';
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  ccrmChildren?: Maybe<Array<Maybe<Scalars['String']>>>;
  ccrmContactFullName?: Maybe<Scalars['String']>;
  ccrmContactId?: Maybe<Scalars['String']>;
  ccrmEmailAddress?: Maybe<Scalars['String']>;
  ccrmFamilyId?: Maybe<Scalars['String']>;
  ccrmPrimaryPhoneNumber?: Maybe<Scalars['String']>;
  contactId: Scalars['ID'];
  id: Scalars['ID'];
  isGuardian?: Maybe<Scalars['Boolean']>;
  ktContactEmailAddress?: Maybe<Scalars['String']>;
  ktContactFullName?: Maybe<Scalars['String']>;
  ktContactPrimaryPhoneNumber?: Maybe<Scalars['String']>;
  mappingGroupId: Scalars['ID'];
  selected?: Maybe<Scalars['Boolean']>;
};

export type ExportReportInput = {
  bookmarkState?: InputMaybe<Scalars['String']>;
  businessId?: InputMaybe<Scalars['String']>;
  exportContextId?: InputMaybe<Scalars['String']>;
  pages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reportId?: InputMaybe<Scalars['String']>;
  reportName?: InputMaybe<Scalars['String']>;
};

export enum ExtensionReason {
  Foskin = 'FOSKIN',
  Longpo = 'LONGPO',
}

export type Family = {
  __typename?: 'Family';
  centerId?: Maybe<Scalars['ID']>;
  children: Array<Child>;
  entityId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type FamilyTaxBenefit = {
  __typename?: 'FamilyTaxBenefit';
  accountId: Scalars['ID'];
  documentUri?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  part?: Maybe<FamilyTaxBenefitPart>;
};

export enum FamilyTaxBenefitPart {
  FamilyTaxBenefitA = 'FamilyTaxBenefitA',
  FamilyTaxBenefitAAndB = 'FamilyTaxBenefitAAndB',
  None = 'None',
}

export type FeatureConfiguration = {
  __typename?: 'FeatureConfiguration';
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  businessId: Scalars['String'];
  id: Scalars['String'];
  name: FeatureFlagName;
  value: FeatureFlagValue;
};

export enum FeatureFlagName {
  Adp = 'Adp',
}

export enum FeatureFlagValue {
  AdpVantage = 'AdpVantage',
  AdpWorkforce = 'AdpWorkforce',
  None = 'None',
}

export type Fee = {
  __typename?: 'Fee';
  centerId: Scalars['ID'];
  classIds: Array<Scalars['ID']>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  feeStructure: FeeStructure;
  feeType?: Maybe<FeeType>;
  glCode?: Maybe<Scalars['String']>;
  glCodeMapping?: Maybe<GlCodeMapping>;
  id: Scalars['ID'];
  name: Scalars['String'];
  schedules: Array<FeeSchedule>;
  utilization: Scalars['Float'];
};

export type FeeRate = {
  __typename?: 'FeeRate';
  qualifyingNumber: Scalars['Int'];
  value: Scalars['Float'];
};

export type FeeRateInput = {
  qualifyingNumber: Scalars['Int'];
  value: Scalars['Float'];
};

export type FeeSchedule = {
  __typename?: 'FeeSchedule';
  archivedAt?: Maybe<Scalars['String']>;
  endTime: Scalars['Time'];
  id: Scalars['ID'];
  overtimeCost: Scalars['Float'];
  overtimeGracePeriod: Scalars['Int'];
  overtimeInterval: Scalars['Int'];
  rates: Array<FeeRate>;
  startDate?: Maybe<Scalars['Date']>;
  startTime: Scalars['Time'];
};

export enum FeeStructure {
  NumberOfDays = 'NUMBER_OF_DAYS',
  NumberOfSiblings = 'NUMBER_OF_SIBLINGS',
  Session = 'SESSION',
  SingleFlatRate = 'SINGLE_FLAT_RATE',
}

export enum FeeType {
  FlatRate = 'FLAT_RATE',
  FlatRateV2 = 'FLAT_RATE_V2',
  Session = 'SESSION',
}

export type FeesSearchResults = {
  __typename?: 'FeesSearchResults';
  data: Array<Fee>;
  totalResults: Scalars['Int'];
};

export type FileResource = {
  __typename?: 'FileResource';
  actions: Array<Maybe<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<EducationPerson>;
  downloadUrl?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  isParentRoot?: Maybe<Scalars['Boolean']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  mimeType: Scalars['String'];
  name: Scalars['String'];
  ownerGroups?: Maybe<Array<Maybe<GroupSummary>>>;
  parentFolderId: Scalars['String'];
  parentFolderName?: Maybe<Scalars['String']>;
  path?: Maybe<Array<Maybe<Ancestor>>>;
  readerGroups?: Maybe<Array<Maybe<GroupSummary>>>;
  updaterGroups?: Maybe<Array<Maybe<GroupSummary>>>;
  viewUrl?: Maybe<Scalars['String']>;
};

export type FileStorageInfo = {
  __typename?: 'FileStorageInfo';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type FlagMessageInput = {
  businessId?: InputMaybe<Scalars['ID']>;
  channelId?: InputMaybe<Scalars['ID']>;
  channelWasRead?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<FlagReason>;
  flagReasonText?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['ID']>;
  timeToken?: InputMaybe<Scalars['Float']>;
};

export type FlagMessagePerson = {
  __typename?: 'FlagMessagePerson';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum FlagReason {
  Abusive = 'Abusive',
  Inappropriate = 'Inappropriate',
  Other = 'Other',
}

export type FlatRate = {
  __typename?: 'FlatRate';
  id: Scalars['ID'];
  periodEndDate: Scalars['DateTime'];
  periodStartDate: Scalars['DateTime'];
  sessions?: Maybe<Array<FlatRateSession>>;
};

export type FlatRateSession = {
  __typename?: 'FlatRateSession';
  contractId?: Maybe<Scalars['ID']>;
  date: Scalars['Date'];
  id: Scalars['ID'];
};

export type Folder = {
  __typename?: 'Folder';
  actions: Array<Maybe<Scalars['String']>>;
  contents?: Maybe<Array<Maybe<Content>>>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<EducationPerson>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isParentRoot?: Maybe<Scalars['Boolean']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  name: Scalars['String'];
  ownerGroups?: Maybe<Array<Maybe<GroupSummary>>>;
  parentFolderId?: Maybe<Scalars['String']>;
  parentFolderName?: Maybe<Scalars['String']>;
  path?: Maybe<Array<Maybe<Ancestor>>>;
  readerGroups?: Maybe<Array<Maybe<GroupSummary>>>;
  totalContentCount?: Maybe<Scalars['Int']>;
  updaterGroups?: Maybe<Array<Maybe<GroupSummary>>>;
};

export type FolderSearchInput = {
  currentFolderId: Scalars['ID'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  showFolders?: InputMaybe<Scalars['Boolean']>;
  term: Scalars['String'];
};

export type ForecastDto = {
  __typename?: 'ForecastDto';
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  errors: Array<ErrorDto>;
};

export type ForgetChildAtCenterInput = {
  centerId: Scalars['ID'];
  id: Scalars['ID'];
};

export type FreeKindyAcquittalCenterSummaryDto = {
  __typename?: 'FreeKindyAcquittalCenterSummaryDto';
  baseSubsidyAcquittal?: Maybe<Scalars['Float']>;
  baseSubsidyActual?: Maybe<Scalars['Float']>;
  baseSubsidyPrior?: Maybe<Scalars['Float']>;
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childCount: Scalars['Int'];
  freeKindyAcquittal?: Maybe<Scalars['Float']>;
  freeKindyActual?: Maybe<Scalars['Float']>;
  freeKindyPrior?: Maybe<Scalars['Float']>;
  inclusionReadyAcquittal?: Maybe<Scalars['Float']>;
  inclusionReadyActual?: Maybe<Scalars['Float']>;
  inclusionReadyPrior?: Maybe<Scalars['Float']>;
  messages: Array<ErrorDto>;
  paidToFamiliesAmount?: Maybe<Scalars['Float']>;
  period: Scalars['String'];
  serviceLocationAcquittal?: Maybe<Scalars['Float']>;
  serviceLocationActual?: Maybe<Scalars['Float']>;
  serviceLocationPrior?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  totalOwed?: Maybe<Scalars['Float']>;
  totalPayment?: Maybe<Scalars['Float']>;
  year: Scalars['Int'];
};

export type FreeKindyAcquittalChildSummaryDto = {
  __typename?: 'FreeKindyAcquittalChildSummaryDto';
  acquittedAmount?: Maybe<Scalars['Float']>;
  actualAmount?: Maybe<Scalars['Float']>;
  agreementId?: Maybe<Scalars['ID']>;
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childId: Scalars['ID'];
  childName?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['Boolean']>;
  forecastedAmount?: Maybe<Scalars['Float']>;
  paidToFamiliesAmount?: Maybe<Scalars['Float']>;
  period: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

export type FreeKindyCenterSummaryDto = {
  __typename?: 'FreeKindyCenterSummaryDto';
  ariaTeacherAmount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childCount: Scalars['Int'];
  errors: Array<ErrorDto>;
  freeKindyAmount?: Maybe<Scalars['Float']>;
  fundedAmount?: Maybe<Scalars['Float']>;
  period: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  universalInclusionAmount?: Maybe<Scalars['Float']>;
  year: Scalars['Int'];
};

export type FreeKindyChildSummaryDto = {
  __typename?: 'FreeKindyChildSummaryDto';
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childId: Scalars['ID'];
  childName?: Maybe<Scalars['String']>;
  fundedAmount?: Maybe<Scalars['Float']>;
  period: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

export enum FrequencyPayPeriod {
  FirstMonth = 'FIRST_MONTH',
  FirstWeek = 'FIRST_WEEK',
  SecondMonth = 'SECOND_MONTH',
  SecondWeek = 'SECOND_WEEK',
  ThirdMonth = 'THIRD_MONTH',
}

export type GlCode = {
  __typename?: 'GLCode';
  businessId: Scalars['ID'];
  glCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<GlCodeAreaType>;
};

export enum GlCodeAreaType {
  Agency = 'AGENCY',
  CustomTransactionType = 'CUSTOM_TRANSACTION_TYPE',
  Discount = 'DISCOUNT',
  Fee = 'FEE',
  NotSet = 'NOT_SET',
  PaymentSurcharge = 'PAYMENT_SURCHARGE',
  ReasonType = 'REASON_TYPE',
  SystemTransactionType = 'SYSTEM_TRANSACTION_TYPE',
}

export type GlCodeAssignment = {
  __typename?: 'GLCodeAssignment';
  businessId: Scalars['ID'];
  centerId?: Maybe<Scalars['ID']>;
  centerName?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  glCodeCode?: Maybe<Scalars['String']>;
  glCodeId?: Maybe<Scalars['String']>;
  glCodeMappingId?: Maybe<Scalars['String']>;
  glCodeName?: Maybe<Scalars['String']>;
  surchargeType?: Maybe<Scalars['String']>;
  type: GlCodeAreaType;
};

export type GlCodeAssignmentPagedResult = {
  __typename?: 'GLCodeAssignmentPagedResult';
  data: Array<GlCodeAssignment>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GlCodeMapping = {
  __typename?: 'GLCodeMapping';
  adjustmentId?: Maybe<Scalars['ID']>;
  agencyId?: Maybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  discountId?: Maybe<Scalars['ID']>;
  feeId?: Maybe<Scalars['ID']>;
  glCodeCode: Scalars['String'];
  glCodeId: Scalars['ID'];
  glCodeName: Scalars['String'];
  id: Scalars['ID'];
  surchargeType?: Maybe<Scalars['ID']>;
  transactionTypeId?: Maybe<Scalars['ID']>;
};

export type GlCodeMappingInput = {
  adjustmentId?: InputMaybe<Scalars['ID']>;
  agencyId?: InputMaybe<Scalars['ID']>;
  businessId?: InputMaybe<Scalars['ID']>;
  discountId?: InputMaybe<Scalars['ID']>;
  feeId?: InputMaybe<Scalars['ID']>;
  glCodeCode?: InputMaybe<Scalars['String']>;
  glCodeId: Scalars['ID'];
  glCodeName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  surchargeType?: InputMaybe<Scalars['ID']>;
  transactionTypeId?: InputMaybe<Scalars['ID']>;
};

export type GlCodePagedResult = {
  __typename?: 'GLCodePagedResult';
  data: Array<GlCode>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GallerySettings = {
  __typename?: 'GallerySettings';
  autoApprove: Scalars['Boolean'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  releaseOnCheckout: Scalars['Boolean'];
};

export type GallerySettingsInput = {
  autoApprove: Scalars['Boolean'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  releaseOnCheckout: Scalars['Boolean'];
};

export enum Gender {
  Female = 'Female',
  Male = 'Male',
  NotIndicated = 'NotIndicated',
  Other = 'Other',
}

export type GeneratePdfStatementInput = {
  accountId: Scalars['ID'];
  periodEndOn: Scalars['Date'];
  periodStartFrom: Scalars['Date'];
};

export type GetAccountChildrenAbsencesForTimeFrameInput = {
  accountChildren: Array<Scalars['ID']>;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type GetAccountContactInformationInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  date?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<AccountStatusType>;
};

export type GetAccountDepositsBalanceInput = {
  accountSearchKey?: InputMaybe<Scalars['String']>;
  businessId: Scalars['String'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  end: Scalars['Date'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  start: Scalars['Date'];
  statusType?: InputMaybe<AccountStatusType>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetAccountDepositsInput = {
  accountId: Scalars['String'];
};

export type GetAccountDocumentsInput = {
  accountId: Scalars['ID'];
  associatedEntityId?: InputMaybe<Scalars['ID']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchText?: InputMaybe<Scalars['String']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<SearchSort>>;
  type?: InputMaybe<DocumentType>;
};

export type GetAccountGapAndBalancesInput = {
  accountSearchKey?: InputMaybe<Scalars['String']>;
  businessId: Scalars['String'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  end: Scalars['Date'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  start: Scalars['Date'];
};

export type GetAccountingPeriodsInput = {
  businessId: Scalars['ID'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
};

export type GetAccountsBillingOverviewInput = {
  billingFrequencies?: InputMaybe<Array<InputMaybe<BillingFrequency>>>;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  end: Scalars['Date'];
  pagination: PaginateInput;
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy: Scalars['String'];
  sortDirection: Scalars['String'];
  start: Scalars['Date'];
  statusAtDate: Scalars['Date'];
  statusType?: InputMaybe<AccountStatusType>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetAdjustmentsInput = {
  businessId: Scalars['String'];
  showArchived?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<AdjustmentType>;
};

export type GetAgenciesInput = {
  businessId: Scalars['String'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  counties?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetAgencyAccountChildrenInput = {
  agencyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<AgencyEnrollmentStatusType>>>;
};

export type GetApiKeyWebHookInput = {
  apiKeyId: Scalars['ID'];
};

export type GetApplicationCategoriesInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type GetApplicationOfferInput = {
  applicationId: Scalars['String'];
  applicationOfferId: Scalars['String'];
  businessId: Scalars['String'];
};

export type GetApplicationScheduleInput = {
  applicationId: Scalars['ID'];
  applicationScheduleId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export type GetApplicationSettingInput = {
  businessId: Scalars['String'];
};

export type GetApplicationsInput = {
  applicationStage?: InputMaybe<ApplicationStage>;
  birthdayAsAtDate?: InputMaybe<Scalars['Date']>;
  careTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  centerIds: Array<Scalars['ID']>;
  days?: InputMaybe<ApplicationDaysInput>;
  enrollmentSources?: InputMaybe<Array<InputMaybe<EnrolmentSource>>>;
  inProgressStatus?: InputMaybe<Array<InputMaybe<ApplicationState>>>;
  maxBirthdayMonths?: InputMaybe<Scalars['Int']>;
  minBirthdayMonths?: InputMaybe<Scalars['Int']>;
  onlyShowUpdatedForms?: InputMaybe<Scalars['Boolean']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  paid?: InputMaybe<YesNoType>;
  programIds?: InputMaybe<Array<Scalars['ID']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<SearchSort>>;
  subsidyEligibility?: InputMaybe<YesNoType>;
};

export type GetAppllicationCenterSettingsInput = {
  businessId: Scalars['ID'];
};

export type GetAppllicationCenterSettingsResponse = {
  __typename?: 'GetAppllicationCenterSettingsResponse';
  data: Array<Maybe<CenterSettings>>;
};

export type GetAttendanceCenterSettingInput = {
  businessId: Scalars['ID'];
};

export type GetAttendanceInput = {
  centerId?: InputMaybe<Scalars['ID']>;
  endDate: Scalars['DateTime'];
  personId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['DateTime'];
};

export type GetAttendanceOpenSpotsInput = {
  centerId: Scalars['ID'];
  end?: InputMaybe<Scalars['Date']>;
  start?: InputMaybe<Scalars['Date']>;
};

export type GetAuthenticationIdentityResponseMessage = {
  __typename?: 'GetAuthenticationIdentityResponseMessage';
  idpIdentifier: Scalars['String'];
  onboardingCompleted: Scalars['Boolean'];
};

export type GetAvailableChildrenForBusRosterInput = {
  centerId: Scalars['ID'];
  effectiveDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type GetBalanceByDateInput = {
  accountTagId?: InputMaybe<Scalars['ID']>;
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  createdOn?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<AccountStatusType>;
};

export type GetBatchesInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  end?: InputMaybe<Scalars['Date']>;
  omitAutomatedBatches: Scalars['Boolean'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Date']>;
};

export type GetBillingEditsReportInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type GetBillingSummaryInput = {
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetCcrmCredentialsInput = {
  businessId: Scalars['ID'];
};

export type GetCcrmCredentialsResponse = {
  __typename?: 'GetCcrmCredentialsResponse';
  username?: Maybe<Scalars['String']>;
};

export type GetCcrmExistingChildMappingInput = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  childId: Scalars['ID'];
  integrationType: IntegrationType;
};

export type GetCcrmExistingChildMappingResponse = {
  __typename?: 'GetCcrmExistingChildMappingResponse';
  mappingRequired: Scalars['Boolean'];
};

export type GetCcrmExistingContactMappingInput = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  integrationType: IntegrationType;
};

export type GetCcrmExistingContactMappingResponse = {
  __typename?: 'GetCcrmExistingContactMappingResponse';
  mappingRequired: Scalars['Boolean'];
};

export type GetCcrmExistingEntityMappingInput = {
  businessId: Scalars['ID'];
  integrationType: IntegrationType;
  matchType: MatchType;
};

export type GetCcrmExistingEntityMappingResponse = {
  __typename?: 'GetCcrmExistingEntityMappingResponse';
  existingChildrenMapping: Array<ExistingChildrenMapping>;
  existingContactMapping: Array<ExistingContactMapping>;
};

export type GetCcssEventInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDate?: InputMaybe<Scalars['String']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<CcssEventSortInput>>;
  startDate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CcssEventType>;
};

export type GetCcssEventPagedResult = {
  __typename?: 'GetCcssEventPagedResult';
  data: Array<CcssEvent>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetCenterBatchingSettingsByBusinessInput = {
  businessId: Scalars['ID'];
};

export type GetCenterBillingSettingsByBusinessInput = {
  businessId: Scalars['ID'];
};

export type GetCenterBillingSettingsByCenterInput = {
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetCenterProgramsInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  classIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  pageNumber: Scalars['Int'];
  pageSize?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
  subsidySchemeId?: InputMaybe<Scalars['String']>;
};

export type GetCenterStatementRunsInput = {
  centerId: Scalars['ID'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<SearchSort>>;
};

export type GetCenterStatementRunsResults = {
  __typename?: 'GetCenterStatementRunsResults';
  data: Array<StatementRun>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetCenterStatementScheduleInput = {
  centerId: Scalars['ID'];
};

export type GetCenterStatementsForAccountInput = {
  accountId: Scalars['ID'];
  centerId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['Date']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<SearchSort>>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type GetCenterStatementsForAccountResults = {
  __typename?: 'GetCenterStatementsForAccountResults';
  data: Array<Statement>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetCenterSummaryQueryInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  channelType: ChannelType;
  messagesAfter?: InputMaybe<Scalars['String']>;
  messagesBefore?: InputMaybe<Scalars['String']>;
};

export type GetCentersInput = {
  businessId: Scalars['ID'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type GetCentersWithSurchargeInput = {
  businessId: Scalars['ID'];
};

export type GetChannelsSentMessagesCountInput = {
  channelIds: Array<InputMaybe<Scalars['ID']>>;
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type GetChildCheckInInput = {
  centerId: Scalars['ID'];
  classIds: Array<Scalars['ID']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetChildIdsWithContractsOnDateInput = {
  businessId: Scalars['ID'];
  date: Scalars['Date'];
};

export type GetChildTrackingReportInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  childId?: InputMaybe<Scalars['ID']>;
  endDate: Scalars['Date'];
  spaceId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['Date'];
};

export type GetChildrenForAgencyPaymentAtCenterInput = {
  agencyId: Scalars['ID'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type GetClassClosureByDateInput = {
  classId: Scalars['ID'];
  date: Scalars['DateTime'];
};

export type GetComplyingWrittenArrangementPageInput = {
  accountStatuses: Array<AccountStatusType>;
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
  statuses: Array<ComplyingWrittenArrangementStatus>;
};

export type GetContractEditsReportInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type GetContractFeeDetailedInput = {
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  date: Scalars['String'];
};

export type GetContractFeeInput = {
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  date: Scalars['String'];
};

export type GetCurrentCpavForCentreServiceInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  govServiceId: Scalars['ID'];
};

export type GetCurriculumInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type GetCurriculumScheduleInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type GetCurriculumScheduleProgramThemeInput = {
  businessId: Scalars['ID'];
  curriculumScheduleId: Scalars['ID'];
  programThemeId: Scalars['ID'];
};

export type GetCurriculumSchedulesForBusinessInput = {
  activeOnly: Scalars['Boolean'];
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchTerm: Scalars['String'];
  sortDirection: Scalars['String'];
  sortField: Scalars['String'];
  statuses: Array<CurriculumScheduleStatus>;
};

export type GetCustomFieldDocumentValuesInput = {
  businessId: Scalars['ID'];
  documentId: Scalars['ID'];
};

export type GetCustomFieldValuesInput = {
  area: CustomFieldArea;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  entityId: Scalars['ID'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
};

export type GetCustomFieldsInput = {
  allowedCenterIds?: InputMaybe<Array<Scalars['ID']>>;
  area?: InputMaybe<CustomFieldArea>;
  businessId: Scalars['ID'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  matchAnyCenterId?: InputMaybe<Scalars['Boolean']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CustomFieldType>;
};

export type GetDailyExperienceAssignmentsInput = {
  end: Scalars['DateTime'];
  programIds: Array<Scalars['ID']>;
  start: Scalars['DateTime'];
};

export type GetDailyNotesForCenterInput = {
  centerId: Scalars['ID'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type GetDailyNotesOfTypeForChildrenInput = {
  centerId: Scalars['ID'];
  children?: InputMaybe<Array<Scalars['ID']>>;
  endDate: Scalars['DateTime'];
  noteType?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type GetDateCalculate = {
  __typename?: 'GetDateCalculate';
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type GetDateCalculateInput = {
  centerId: Scalars['ID'];
  periodBasis?: InputMaybe<StatementSchedulePeriodBasis>;
  periodWeeksAfter: Scalars['Int'];
  periodWeeksPrior: Scalars['Int'];
};

export type GetDiscountsWithFiltersInput = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  discountType?: InputMaybe<DiscountType>;
  end?: InputMaybe<Scalars['Date']>;
  start?: InputMaybe<Scalars['Date']>;
};

export type GetDownloadUrlInput = {
  resourceId: Scalars['String'];
};

export type GetDownloadUrlResponse = {
  __typename?: 'GetDownloadUrlResponse';
  downloadUrl: Scalars['String'];
  id: Scalars['String'];
};

export type GetEducationCentersForBusinessInput = {
  businessId: Scalars['ID'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchTerm: Scalars['String'];
  states: Array<Scalars['String']>;
};

export type GetEducationStaffForBusinessInput = {
  businessId: Scalars['ID'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchTerm: Scalars['String'];
};

export type GetEmergencyContactInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  classIds: Array<Scalars['ID']>;
  date: Scalars['String'];
};

export type GetEndCareEnrollmentHistoryByAccountChildIdInput = {
  accountChildId: Scalars['ID'];
  actionTypes: Array<EndCareHistoryActionType>;
  businessId: Scalars['ID'];
};

export type GetEndCareEnrollmentMetricsInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
};

export type GetEndCareEnrollmentPageInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
};

export type GetEndCareHistorySessionsInput = {
  businessId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type GetEndCareSessionsInput = {
  accountChildId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export type GetEnrollmentAvailabilityInput = {
  centerId: Scalars['ID'];
  classId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  includeFutureContracts: Scalars['Boolean'];
  includeLtbOfferings: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type GetEnrollmentProgramGroupsInput = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  pagination: PaginateInput;
  searchText?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SearchSort>>;
};

export type GetEnrollmentProgramsInput = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  pagination: PaginateInput;
  programGroupId?: InputMaybe<Scalars['ID']>;
  searchText?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SearchSort>>;
  withNoProgramGroup?: InputMaybe<Scalars['Boolean']>;
};

export type GetExcursionReportInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  checkpointsCount: Scalars['Float'];
  classIds: Array<Scalars['ID']>;
  date: Scalars['Date'];
};

export type GetExpectedSessionsForAccountChildInTimeframeInput = {
  accountChildId: Scalars['ID'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type GetExpectedSessionsInput = {
  centerId: Scalars['ID'];
  classId?: InputMaybe<Scalars['ID']>;
  endDate: Scalars['Date'];
  excludeCost?: InputMaybe<Scalars['Boolean']>;
  includeVoid?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['Date'];
};

export type GetExternalTypesInput = {
  businessId: Scalars['ID'];
  integrationMappingType: IntegrationMappingType;
  integrationType: IntegrationType;
};

export type GetExternalTypesResponse = {
  __typename?: 'GetExternalTypesResponse';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GetFatZebraTransactionsInput = {
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetFolderCountInput = {
  currentFolderId: Scalars['ID'];
  showFolders?: InputMaybe<Scalars['Boolean']>;
  term: Scalars['String'];
};

export type GetFolderInput = {
  folderId: Scalars['String'];
  order?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  property?: InputMaybe<Scalars['String']>;
};

export type GetForNewProgramByAccountId = {
  __typename?: 'GetForNewProgramByAccountId';
  accountId: Scalars['ID'];
  associatedAccountId?: Maybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  child: Child;
  childId: Scalars['ID'];
  childReceivesAccs?: Maybe<Scalars['Boolean']>;
  concessionCard?: Maybe<ConcessionCard>;
  declarationForm?: Maybe<ChildDocument>;
  delayedExit?: Maybe<DelayedExit>;
  endDate?: Maybe<Scalars['Date']>;
  excludeStartStrongFunding?: Maybe<Scalars['Boolean']>;
  familyTaxBenefit?: Maybe<FamilyTaxBenefit>;
  isActive: Scalars['Boolean'];
  kindyForAllChild?: Maybe<KindyForAllChild>;
  lastFundPaidDate?: Maybe<Scalars['Date']>;
  primaryAccountId?: Maybe<Scalars['ID']>;
  schoolExemption?: Maybe<ChildDocument>;
  startDate: Scalars['Date'];
  startStrongChild?: Maybe<StartStrongChild>;
  startStrongConsent?: Maybe<Scalars['String']>;
  startStrongDeclarationForm?: Maybe<ChildUploadDocument>;
  startStrongSchoolExemption?: Maybe<ChildUploadDocument>;
};

export type GetGlCodeAssignmentsInput = {
  areaType?: InputMaybe<GlCodeAreaType>;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  hideMapped?: InputMaybe<Scalars['Boolean']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<SearchSort>>;
};

export type GetGlCodeCountInput = {
  businessId: Scalars['ID'];
  glCodeId: Scalars['String'];
};

export type GetGlCodesInput = {
  areaType?: InputMaybe<GlCodeAreaType>;
  businessId: Scalars['ID'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<SearchSort>>;
};

export type GetHighestDailyMessagesPerChannelInput = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  messagesAfter?: InputMaybe<Scalars['String']>;
  messagesBefore?: InputMaybe<Scalars['String']>;
};

export type GetIntegrationApiKeyForBusinessInput = {
  id: Scalars['ID'];
  showRevoked: Scalars['Boolean'];
};

export type GetIntegrationMappingResponse = {
  __typename?: 'GetIntegrationMappingResponse';
  businessId: Scalars['ID'];
  externalId: Scalars['String'];
  externalType: Scalars['String'];
  foreignTenantId: Scalars['String'];
  integrationType: IntegrationType;
  internalId: Scalars['String'];
  internalType: IntegrationMappingType;
};

export type GetIntegrationMappingsInput = {
  businessId: Scalars['ID'];
  integrationMappingType: IntegrationMappingType;
  integrationType: IntegrationType;
};

export type GetIsCaseClaimPaymentsForCentersInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
};

export type GetIsCaseClaimsForCentersInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  includeActive: Scalars['Boolean'];
  includeInActive: Scalars['Boolean'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  paymentStatus?: InputMaybe<IsCaseClaimPaymentStatusCategory>;
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
  weeksEnding: Array<Scalars['Date']>;
};

export type GetIsCasesForCentersInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  includeActive: Scalars['Boolean'];
  includeInActive: Scalars['Boolean'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
};

export type GetIsInnovativeCasesForCentersInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  includeActive: Scalars['Boolean'];
  includeInActive: Scalars['Boolean'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
};

export type GetIsMessagesForCentersInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
  viewedStatus: Array<CcssMessageViewedStatus>;
};

export type GetKindyChildFundingSchedulesForAccountInput = {
  accountId: Scalars['String'];
};

export type GetKindyChildFundingSchedulesInput = {
  accountId: Scalars['String'];
  programChildId: Scalars['String'];
};

export type GetKindyForAllAgreementsInput = {
  businessId: Scalars['String'];
};

export type GetKindyForAllIneligibilityInput = {
  centerId: Scalars['String'];
  childId: Scalars['String'];
};

export type GetKindyForAllQuarter = {
  __typename?: 'GetKindyForAllQuarter';
  endDate: Scalars['String'];
  isCurrent: Scalars['Boolean'];
  period: Scalars['String'];
  startDate: Scalars['String'];
  year: Scalars['Int'];
};

export type GetKindyForAllQuartersInput = {
  years?: InputMaybe<Array<Scalars['Int']>>;
};

export type GetKindyForAllReportInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  period: Scalars['String'];
  year: Scalars['String'];
};

export type GetKindyForAllSubmissionErrorsReportInput = {
  businessId: Scalars['ID'];
  errors: Array<KindyForAllSubmissionError>;
};

export type GetKindyProgramFundingSchedulesInput = {
  programId: Scalars['String'];
};

export type GetLearningStandardDomainInput = {
  id: Scalars['ID'];
  learningStandardId: Scalars['ID'];
};

export type GetLessonPlanExperiences = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type GetLock = {
  __typename?: 'GetLock';
  allowParentUnlock?: Maybe<Scalars['Boolean']>;
  allowStaffUnlock?: Maybe<Scalars['Boolean']>;
  centerId?: Maybe<Scalars['ID']>;
  centerName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hasPermissionToUnlock: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  lockId: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GetMonthlyMessageStatisticsInput = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  messagesAfter?: InputMaybe<Scalars['String']>;
  messagesBefore?: InputMaybe<Scalars['String']>;
  splitByPersonType?: InputMaybe<Scalars['Boolean']>;
};

export type GetMyTimeOffRequestInput = {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type GetNameToFaceInput = {
  centerId: Scalars['ID'];
  classIds: Array<Scalars['ID']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetOccupancyTotalInput = {
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  classIds?: InputMaybe<Array<Scalars['String']>>;
  endDate: Scalars['String'];
  excludeClassesWithNoAttendance: Scalars['Boolean'];
  excludeNonCcsCareType: Scalars['Boolean'];
  includeLeadApplications?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['String'];
};

export type GetOpenTimeEntryForPersonAtCenterInput = {
  centerId: Scalars['ID'];
  personId: Scalars['ID'];
};

export type GetPagedFreeKindyAcquittalCenterSummariesResult = {
  __typename?: 'GetPagedFreeKindyAcquittalCenterSummariesResult';
  data?: Maybe<Array<FreeKindyAcquittalCenterSummaryDto>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetPagedFreeKindyAcquittalChildSummariesResult = {
  __typename?: 'GetPagedFreeKindyAcquittalChildSummariesResult';
  data?: Maybe<Array<FreeKindyAcquittalChildSummaryDto>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetPagedFreeKindyCenterSummariesResult = {
  __typename?: 'GetPagedFreeKindyCenterSummariesResult';
  data: Array<FreeKindyCenterSummaryDto>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetPagedFreeKindyChildSummariesResult = {
  __typename?: 'GetPagedFreeKindyChildSummariesResult';
  data: Array<FreeKindyChildSummaryDto>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetPagedKindyForAllAcquittalCenterSummariesResult = {
  __typename?: 'GetPagedKindyForAllAcquittalCenterSummariesResult';
  data?: Maybe<Array<KindyForAllAcquittalCenterSummaryDto>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetPagedKindyForAllAcquittalChildSummariesResult = {
  __typename?: 'GetPagedKindyForAllAcquittalChildSummariesResult';
  data?: Maybe<Array<KindyForAllAcquittalChildSummaryDto>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetPagedKindyForAllCenterAgreementsResult = {
  __typename?: 'GetPagedKindyForAllCenterAgreementsResult';
  data?: Maybe<Array<KindyForAllCenterAgreementDto>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetPagedKindyForAllCenterSummariesInput = {
  centerIds: Array<Scalars['String']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  period: Scalars['String'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  year: Scalars['Int'];
};

export type GetPagedKindyForAllCenterSummariesResult = {
  __typename?: 'GetPagedKindyForAllCenterSummariesResult';
  data?: Maybe<Array<KindyForAllCenterSummaryDto>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetPagedKindyForAllChildSummariesInput = {
  centerIds: Array<Scalars['String']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  period: Scalars['String'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  year: Scalars['Int'];
};

export type GetPagedKindyForAllChildSummariesResult = {
  __typename?: 'GetPagedKindyForAllChildSummariesResult';
  data?: Maybe<Array<KindyForAllChildSummaryDto>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetPagedKindyForAllCredentialsResult = {
  __typename?: 'GetPagedKindyForAllCredentialsResult';
  data?: Maybe<Array<KindyForAllCredentialDto>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetPagedKindyForAllResultInput = {
  businessId: Scalars['String'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type GetPaginatedAgencyPaymentsInput = {
  agencyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  end?: InputMaybe<Scalars['Date']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Date']>;
};

export type GetPaginatedBusRostersInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
};

export type GetPaginatedBusinessCurriculaInput = {
  businessId: Scalars['ID'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchTerm: Scalars['String'];
  sortDirection: Scalars['String'];
  sortField: Scalars['String'];
  statuses: Array<CurriculumStatus>;
};

export type GetPaginatedPaymentsInput = {
  businessId: Scalars['String'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDate: Scalars['Date'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  paymentFilters?: InputMaybe<Array<InputMaybe<PaymentFilterType>>>;
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  transactionTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type GetPaginatedServiceFeeGroupsInput = {
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  feeTypes?: InputMaybe<Array<InputMaybe<ServiceFeeType>>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
};

export type GetPaginatedTrainingTimeEventsForCenterInput = {
  centerId: Scalars['ID'];
  endDate: Scalars['Date'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type GetPaginatedTransactionTypesInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isTuition?: InputMaybe<Scalars['Boolean']>;
  manualTransactionTypesOnly?: InputMaybe<Scalars['Boolean']>;
  nameContains?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
};

export type GetPaginatedTransactionsInTimeframeInput = {
  businessId: Scalars['String'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  end: Scalars['Date'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  paymentFilters?: InputMaybe<Array<InputMaybe<PaymentFilterType>>>;
  paymentTransactionsOnly?: InputMaybe<Scalars['Boolean']>;
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  start: Scalars['Date'];
  transactionTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type GetPaymentInput = {
  paymentId: Scalars['ID'];
};

export type GetPayrixDisbursementReport = {
  __typename?: 'GetPayrixDisbursementReport';
  reportData: Array<Maybe<Scalars['String']>>;
  reportMetaData: ReportMetaData;
};

export type GetPayrixDisbursementReportInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  documentNumber: Scalars['String'];
};

export type GetPayrixDisbursementsForCentersInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  endDate: Scalars['Date'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type GetPayrixDisbursementsInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  endDate: Scalars['Date'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type GetPersonChannelsSummaryInput = {
  channelIds: Array<InputMaybe<Scalars['ID']>>;
  personId: Scalars['ID'];
};

export type GetPersonChannelsUnreadMessagesInput = {
  channelIds: Array<InputMaybe<Scalars['ID']>>;
  personId: Scalars['ID'];
};

export type GetProgramByIdInput = {
  businessId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type GetProgramChildrenInput = {
  businessId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['Date']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  programId: Scalars['ID'];
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type GetProgramsByAccountId = {
  __typename?: 'GetProgramsByAccountId';
  accountId: Scalars['ID'];
  associatedAccountId?: Maybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  child: Child;
  childId: Scalars['ID'];
  childReceivesAccs?: Maybe<Scalars['Boolean']>;
  concessionCard?: Maybe<ConcessionCard>;
  declarationForm?: Maybe<ChildDocument>;
  delayedExit?: Maybe<DelayedExit>;
  endDate?: Maybe<Scalars['Date']>;
  excludeStartStrongFunding?: Maybe<Scalars['Boolean']>;
  familyTaxBenefit?: Maybe<FamilyTaxBenefit>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  kindyForAllChild?: Maybe<KindyForAllChild>;
  lastFundPaidDate?: Maybe<Scalars['Date']>;
  primaryAccountId?: Maybe<Scalars['ID']>;
  program: GetProgramsByAccountIdProgram;
  schoolExemption?: Maybe<ChildDocument>;
  startDate: Scalars['Date'];
  startStrongChild?: Maybe<StartStrongChild>;
  startStrongConsent?: Maybe<Scalars['String']>;
  startStrongDeclarationForm?: Maybe<ChildUploadDocument>;
  startStrongSchoolExemption?: Maybe<ChildUploadDocument>;
};

export type GetProgramsByAccountIdInput = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export type GetProgramsByAccountIdProgram = {
  __typename?: 'GetProgramsByAccountIdProgram';
  centerId: Scalars['ID'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  subsidyScheme: ProgramSubsidyScheme;
};

export type GetProviderDebtInput = {
  businessId: Scalars['ID'];
  providerId: Scalars['ID'];
};

export type GetQldCensusReportInput = {
  businessId?: InputMaybe<Scalars['String']>;
  centerIds?: InputMaybe<Array<Scalars['String']>>;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type GetQkfsReportInput = {
  businessId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['String']>;
  isConsolidated?: InputMaybe<Scalars['Boolean']>;
  programIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate: Scalars['String'];
};

export type GetQkfsTransactionCountAfterDateInput = {
  atDate: Scalars['String'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type GetReEnrolBusinessSettingsInput = {
  businessId: Scalars['String'];
};

export type GetReEnrolHistoryOfActionsInput = {
  historyFilterBy: ReenrollmentGetHistoryOfActionsFilterBy;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
};

export type GetReturnFeeReductionsResults = {
  __typename?: 'GetReturnFeeReductionsResults';
  data: Array<ReturnFeeReduction>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetServiceDebtInput = {
  businessId: Scalars['ID'];
  serviceId: Scalars['ID'];
};

export type GetServiceNameChangeLogsResults = {
  __typename?: 'GetServiceNameChangeLogsResults';
  data: Array<ServiceNameChangeLog>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetServicesByBusinessResults = {
  __typename?: 'GetServicesByBusinessResults';
  data: Array<ServicesByBusiness>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type GetSessionInput = {
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type GetStageAggregationInput = {
  centerIds: Array<Scalars['ID']>;
};

export type GetStartStrongReportInput = {
  centerIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetStartStrongReportResult = {
  __typename?: 'GetStartStrongReportResult';
  csv?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

export type GetSubsidyCenterRating = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  id: Scalars['ID'];
};

export type GetSubsidySchemeProgramsCountByCenterInput = {
  centerIds: Array<InputMaybe<Scalars['ID']>>;
  subsidySchemeIds: Array<InputMaybe<Scalars['ID']>>;
};

export type GetSubsidySchemeProgramsCountResponse = {
  __typename?: 'GetSubsidySchemeProgramsCountResponse';
  count: Scalars['Int'];
};

export type GetSumOfPaymentsInTimeFrameInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  end?: InputMaybe<Scalars['Date']>;
  start?: InputMaybe<Scalars['Date']>;
};

export type GetThemeInput = {
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  themeId: Scalars['ID'];
  unitId: Scalars['ID'];
};

export type GetTimeEntriesForDatesInput = {
  centerId: Scalars['ID'];
  endDate: Scalars['DateTime'];
  personId?: InputMaybe<Scalars['ID']>;
  positionId?: InputMaybe<Scalars['ID']>;
  scheduleId?: InputMaybe<Scalars['ID']>;
  shiftID?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['DateTime'];
};

export type GetTimeOffRequestByScopeInput = {
  endTime: Scalars['DateTime'];
  scopeId: Scalars['ID'];
  scopeType: ScopeType;
  startTime: Scalars['DateTime'];
};

export type GetTotalUnbatchedTransactionPaymentsInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetTransactionByTransactionTypeTotalInput = {
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  includeLiabilities: Scalars['Boolean'];
  interval: ReportInterval;
  startDate: Scalars['String'];
  transactionDate?: InputMaybe<TransactionDate>;
};

export type GetTransactionInput = {
  transactionId: Scalars['ID'];
};

export type GetTransactionTotalInput = {
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  interval: ReportInterval;
  startDate: Scalars['String'];
  transactionDate?: InputMaybe<TransactionDate>;
};

export type GetTransitionStatementsInput = {
  centerId?: InputMaybe<Scalars['ID']>;
  year: Scalars['Int'];
};

export type GetUnbatchedTransactionPaymentsInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  end?: InputMaybe<Scalars['Date']>;
  paymentMedium?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Date']>;
};

export type GetUnitInput = {
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  id: Scalars['ID'];
};

export type GetWeeklyPayrollInput = {
  businessId: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export enum Grade {
  Fifth = 'FIFTH',
  First = 'FIRST',
  Fourth = 'FOURTH',
  Prek = 'PREK',
  Prep = 'PREP',
  Second = 'SECOND',
  Seventh = 'SEVENTH',
  Sixth = 'SIXTH',
  Third = 'THIRD',
}

export type Group = {
  __typename?: 'Group';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<EducationPerson>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  links?: Maybe<LinkList>;
  members?: Maybe<Array<Maybe<EducationPerson>>>;
  name: Scalars['String'];
  sourceGroups?: Maybe<Array<Maybe<GroupSummary>>>;
  userCount?: Maybe<Scalars['Int']>;
};

export type GroupOrUser = {
  __typename?: 'GroupOrUser';
  discriminator: Scalars['String'];
  groupType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isManaged?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  userCount?: Maybe<Scalars['Int']>;
};

export type GroupPagedResult = {
  __typename?: 'GroupPagedResult';
  data: Array<Maybe<Group>>;
  items?: Maybe<Array<Maybe<GroupSummary>>>;
  numberRequested: Scalars['Int'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  start: Scalars['Int'];
  totalNumber: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum GroupSortDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum GroupSortField {
  Name = 'Name',
  UserCount = 'UserCount',
}

export type GroupSummary = {
  __typename?: 'GroupSummary';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<EducationPerson>;
  description?: Maybe<Scalars['String']>;
  groupType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAccountOwnerGroup?: Maybe<Scalars['Boolean']>;
  isEveryoneGroup?: Maybe<Scalars['Boolean']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  members?: Maybe<Array<Maybe<EducationPerson>>>;
  name: Scalars['String'];
  userCount?: Maybe<Scalars['Int']>;
};

export enum GroupType {
  CenterGroup = 'CenterGroup',
  CustomGroup = 'CustomGroup',
  RoleGroup = 'RoleGroup',
  StateGroup = 'StateGroup',
}

export type GroupsAndUsersPagedResult = {
  __typename?: 'GroupsAndUsersPagedResult';
  items: Array<Maybe<GroupOrUser>>;
  numberRequested: Scalars['Int'];
  start: Scalars['Int'];
  totalNumber: Scalars['Int'];
};

export type HealthProfessional = {
  __typename?: 'HealthProfessional';
  address: Address;
  businessId: Scalars['ID'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  linkedAccountChildren: Array<HealthProfessionalLinkedChildren>;
  phoneNumber: Scalars['String'];
  practice: Scalars['String'];
  professionalType: Scalars['String'];
};

export type HealthProfessionalAddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Country;
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type HealthProfessionalLinkedChildren = {
  __typename?: 'HealthProfessionalLinkedChildren';
  accountChildId: Scalars['ID'];
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  childFirstName: Scalars['String'];
  childId: Scalars['ID'];
  childLastName: Scalars['String'];
};

export type HealthTerm = {
  __typename?: 'HealthTerm';
  archivedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: HealthTermType;
};

export type HealthTermInput = {
  name: Scalars['String'];
  type: HealthTermType;
};

export enum HealthTermType {
  Allergy = 'Allergy',
  Medical = 'Medical',
  Restriction = 'Restriction',
}

export type HideEndCareSessionsInput = {
  accountChildId: Scalars['ID'];
  businessId: Scalars['ID'];
  untilDate: Scalars['Date'];
};

export type ICreateRoyaltyFeeExceptionInput = {
  businessId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  overridePercentage?: InputMaybe<Scalars['Float']>;
  participantCenterIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  royaltyFeeId: Scalars['ID'];
};

export type ICreateRoyaltyFeeInput = {
  businessId: Scalars['ID'];
  name: Scalars['String'];
  percentage: Scalars['Float'];
};

export type IEvaluatePhotoInput = {
  galleryPhotoIds: Array<InputMaybe<Scalars['String']>>;
};

export type IGetStatementLinkInput = {
  centerId: Scalars['ID'];
  statementId: Scalars['ID'];
};

export type IIsCaseClaimPaymentCategoryCount = {
  __typename?: 'IIsCaseClaimPaymentCategoryCount';
  paid: Scalars['Int'];
  pending: Scalars['Int'];
  rejected: Scalars['Int'];
};

export type ISelectExistingEntityInput = {
  businessId: Scalars['ID'];
  integrationType: IntegrationType;
  mappings: Array<ISelectExistingEntityMapping>;
  matchType: MatchType;
};

export type ISelectExistingEntityMapping = {
  groupId: Scalars['String'];
  rowId: Scalars['String'];
};

export type IUpdateRoyaltyFeeExceptionInput = {
  businessId: Scalars['ID'];
  exceptionId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  overridePercentage?: InputMaybe<Scalars['Float']>;
  participantCenterIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  royaltyFeeId: Scalars['ID'];
};

export type IUpdateRoyaltyFeeInput = {
  businessId: Scalars['ID'];
  name: Scalars['String'];
  percentage: Scalars['Float'];
  royaltyFeeId: Scalars['ID'];
};

export type IUpsertBusinessConfigurationInput = {
  activityFeedReleaseSchedule?: InputMaybe<ActivityFeedReleaseSchedule>;
  businessId: Scalars['ID'];
  educationEnabled?: InputMaybe<Scalars['Boolean']>;
  expressCheckinEnabled?: InputMaybe<Scalars['Boolean']>;
  mealTrackingEnabled?: InputMaybe<Scalars['Boolean']>;
  parentContactManagementEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type IUpsertBusinessFeatureInput = {
  businessId: Scalars['ID'];
  enabled: Scalars['Boolean'];
  subFeatures?: InputMaybe<Array<InputMaybe<BusinessSubFeatureInput>>>;
  type: BusinessFeatureType;
};

export type IUpsertRoyaltyFeeConfigInput = {
  businessId: Scalars['ID'];
  includeFees: Scalars['Boolean'];
  includeTransactionDebit: Scalars['Boolean'];
};

export type Immunization = {
  __typename?: 'Immunization';
  activeDocument: WellnessItemDocument;
  businessId: Scalars['ID'];
  childId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  createdByPerson?: Maybe<Staff>;
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['ID']>;
  lastModifiedByPerson?: Maybe<Staff>;
};

export enum ImportDataTypes {
  Center = 'CENTER',
  Entity = 'ENTITY',
  Regulation = 'REGULATION',
  Staff = 'STAFF',
}

export type ImportEzChildTrackContractsInput = {
  businessId: Scalars['ID'];
  dryRun: Scalars['Boolean'];
  enrollmentAttendanceFile: Scalars['Upload'];
  programMappingFile: Scalars['Upload'];
};

export type ImportEzChildTrackFamiliesInput = {
  businessId: Scalars['ID'];
  childContacts: Scalars['Upload'];
  demographics: Scalars['Upload'];
  dryRun: Scalars['Boolean'];
};

export enum IndigenousStatus {
  Aboriginal = 'Aboriginal',
  BothAboriginalTorresStraitIslander = 'BothAboriginalTorresStraitIslander',
  NeitherAboriginalTorresStraitIslander = 'NeitherAboriginalTorresStraitIslander',
  NotIndicated = 'NotIndicated',
  TorresStraitIslander = 'TorresStraitIslander',
}

export type InsertAttachmentInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  fileName: Scalars['String'];
  mimetype: Scalars['String'];
  userActivityMessageId?: InputMaybe<Scalars['Int']>;
};

export type IntegrationApiKey = {
  __typename?: 'IntegrationApiKey';
  apiKeyWebHooks?: Maybe<Array<Maybe<ApiKeyWebHook>>>;
  apiScopes?: Maybe<ApiScopesType>;
  businessId: Scalars['ID'];
  centerScopes?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  key: Scalars['String'];
  revokedAt?: Maybe<Scalars['DateTime']>;
};

export type IntegrationMappingMessage = {
  externalId: Scalars['String'];
  externalType?: InputMaybe<Scalars['String']>;
  internalId: Scalars['String'];
  internalType: IntegrationMappingType;
};

export enum IntegrationMappingType {
  AccountStatus = 'AccountStatus',
  ChildStatus = 'ChildStatus',
  WithdrawalReasons = 'WithdrawalReasons',
}

export enum IntegrationType {
  ChildcareCrm = 'ChildcareCrm',
}

export type InternalUser = Person & {
  __typename?: 'InternalUser';
  address?: Maybe<Address>;
  avatar?: Maybe<S3SignedUrl>;
  cognitoConfiguredAt?: Maybe<Scalars['DateTime']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastname?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  onboardingStatus?: Maybe<OnboardingStatus>;
  phoneNumber?: Maybe<Scalars['Phone']>;
  phoneNumberAlt?: Maybe<Scalars['Phone']>;
  restoreId?: Maybe<Scalars['String']>;
  userType: UserType;
};

export type IsCase = {
  __typename?: 'IsCase';
  agencyName?: Maybe<Scalars['String']>;
  agencyRegion?: Maybe<Scalars['String']>;
  approvalId: Scalars['String'];
  careEnvironmentName?: Maybe<Scalars['String']>;
  careProvision?: Maybe<Scalars['String']>;
  caseId: Scalars['String'];
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  children: Array<IsCaseChildDayOfCare>;
  dateLastInactivated?: Maybe<Scalars['Date']>;
  dateLastReactivated?: Maybe<Scalars['Date']>;
  dateLastWithdrawn?: Maybe<Scalars['Date']>;
  daysOfCare: Array<IsCaseCareHours>;
  endDate: Scalars['Date'];
  enrolments: Array<IsCaseChildEnrolment>;
  excludedWeeks: Array<IsCaseExcludedWeek>;
  id: Scalars['String'];
  nonFaceToFaceHoursAllowed: Scalars['String'];
  nonFaceToFaceHoursBalance: Scalars['String'];
  professionalFirstName?: Maybe<Scalars['String']>;
  professionalLastName?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  status: Scalars['String'];
  supportHoursAllowed: Scalars['String'];
  supportHoursBalance: Scalars['String'];
  type: IsCaseType;
  unsubmittedWeeks: Array<Scalars['Date']>;
  weeklyHoursAllowed: Scalars['String'];
  weeklyTolerance: Scalars['String'];
};

export type IsCaseCareHours = {
  __typename?: 'IsCaseCareHours';
  dayOfCare: Scalars['String'];
  hours: Scalars['String'];
  paymentType: Scalars['String'];
};

export type IsCaseChildDayOfCare = {
  __typename?: 'IsCaseChildDayOfCare';
  child: Child;
  childId: Scalars['String'];
  dayOfCare: Scalars['String'];
};

export type IsCaseChildEnrolment = {
  __typename?: 'IsCaseChildEnrolment';
  accountChildId: Scalars['ID'];
  childId: Scalars['ID'];
  enrolmentId: Scalars['ID'];
};

export type IsCaseClaim = {
  __typename?: 'IsCaseClaim';
  approvalId: Scalars['String'];
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  children: Array<IsCaseClaimChild>;
  claimStatus?: Maybe<IsCaseClaimStatus>;
  daysOfCare: Array<IsCaseCareHours>;
  educators?: Maybe<Array<IsCaseClaimEducator>>;
  hoursClaimed?: Maybe<Scalars['String']>;
  hoursPaid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCaseId: Scalars['String'];
  isClaimId: Scalars['String'];
  paymentStatus: IsCaseClaimPaymentStatusCategory;
  paymentType: Scalars['String'];
  serviceProvided: IsServiceProvisionType;
  weekEnding: Scalars['Date'];
};

export type IsCaseClaimCancelInput = {
  businessId: Scalars['ID'];
  caseClaimId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type IsCaseClaimChild = {
  __typename?: 'IsCaseClaimChild';
  child?: Maybe<Child>;
  childId: Scalars['ID'];
};

export type IsCaseClaimCreateInput = {
  businessId: Scalars['ID'];
  caseDayClaims: Array<IsCaseDayClaim>;
  caseId: Scalars['ID'];
  centerId: Scalars['ID'];
  children: Array<Scalars['ID']>;
  educatorClaims: Array<IsCaseDayEducatorClaim>;
  isAdditionalEducatorDeclarationGiven: Scalars['Boolean'];
  paymentType: Scalars['String'];
  weekEnding: Scalars['Date'];
};

export type IsCaseClaimEducator = {
  __typename?: 'IsCaseClaimEducator';
  careDate: Scalars['Date'];
  educatorId: Scalars['String'];
  firstName: Scalars['String'];
  hours: Scalars['String'];
  lastName: Scalars['String'];
};

export type IsCaseClaimPagedResult = {
  __typename?: 'IsCaseClaimPagedResult';
  data: Array<IsCaseClaim>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type IsCaseClaimPayment = {
  __typename?: 'IsCaseClaimPayment';
  calculationDetails?: Maybe<Scalars['String']>;
  centerName: Scalars['String'];
  hoursClaimed: Scalars['String'];
  hoursPaid: Scalars['String'];
  id: Scalars['ID'];
  isCaseId: Scalars['String'];
  isClaimId: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentType: Scalars['String'];
  serviceProvision: IsServiceProvisionType;
  weekEnding: Scalars['Date'];
};

export type IsCaseClaimPaymentPagedResult = {
  __typename?: 'IsCaseClaimPaymentPagedResult';
  data: Array<IsCaseClaimPayment>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum IsCaseClaimPaymentStatusCategory {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum IsCaseClaimStatus {
  CancellationRequired = 'CANCELLATION_REQUIRED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Processed = 'PROCESSED',
  ReadyForSubmission = 'READY_FOR_SUBMISSION',
  Rejected = 'REJECTED',
  RejectedLateSubmission = 'REJECTED_LATE_SUBMISSION',
  Submitted = 'SUBMITTED',
}

export type IsCaseClaimToggleWeekStatusInput = {
  businessId: Scalars['ID'];
  caseId: Scalars['ID'];
  centerId: Scalars['ID'];
  weekNumber: Scalars['Int'];
  year: Scalars['Int'];
};

export type IsCaseDayClaim = {
  careDate: Scalars['Date'];
  faceToFaceHours: Scalars['Float'];
  nonFaceToFaceHours: Scalars['Float'];
};

export type IsCaseDayEducatorClaim = {
  careDate: Scalars['Date'];
  educatorId?: InputMaybe<Scalars['String']>;
  faceToFaceHours: Scalars['Float'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nonFaceToFaceHours: Scalars['Float'];
};

export type IsCaseExcludedWeek = {
  __typename?: 'IsCaseExcludedWeek';
  weekNumber: Scalars['Int'];
  year: Scalars['Int'];
};

export type IsCasePagedResult = {
  __typename?: 'IsCasePagedResult';
  data: Array<IsCase>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum IsCaseStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  CreatedInError = 'CREATED_IN_ERROR',
  Duplicate = 'DUPLICATE',
  Inactive = 'INACTIVE',
  NotApproved = 'NOT_APPROVED',
  Pending = 'PENDING',
  ReadyForApproval = 'READY_FOR_APPROVAL',
  Started = 'STARTED',
  SubmitForApproval = 'SUBMIT_FOR_APPROVAL',
  SubmitForEndorsement = 'SUBMIT_FOR_ENDORSEMENT',
  Varied = 'VARIED',
  Waitlisted = 'WAITLISTED',
  Withdrawn = 'WITHDRAWN',
}

export enum IsCaseType {
  FdcTopUp = 'FDC_TOP_UP',
  IdfSubsidy = 'IDF_SUBSIDY',
  ImmediateTimeLimited = 'IMMEDIATE_TIME_LIMITED',
  InclusionSupportIhc = 'INCLUSION_SUPPORT_IHC',
}

export type IsEducator = {
  __typename?: 'IsEducator';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type IsEducatorCreateInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type IsInnovativeCase = {
  __typename?: 'IsInnovativeCase';
  acquittalStatus: Scalars['String'];
  agencyName?: Maybe<Scalars['String']>;
  agencyRegion?: Maybe<Scalars['String']>;
  approvalId: Scalars['String'];
  approvedFundingAmount: Scalars['Float'];
  careEnvironmentName?: Maybe<Scalars['String']>;
  caseId: Scalars['String'];
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  claims: Array<IsInnovativeCaseClaim>;
  endDate: Scalars['Date'];
  executed: Scalars['Boolean'];
  id: Scalars['ID'];
  professionalFirstName?: Maybe<Scalars['String']>;
  professionalLastName?: Maybe<Scalars['String']>;
  serviceType: Scalars['String'];
  shortDescription: Scalars['String'];
  startDate: Scalars['Date'];
  status: IsInnovativeCaseStatus;
  type: IsInnovativeCaseType;
};

export type IsInnovativeCaseClaim = {
  __typename?: 'IsInnovativeCaseClaim';
  approvedPaymentAmount: Scalars['Float'];
  cancelled?: Maybe<Scalars['Date']>;
  caseId: Scalars['String'];
  claimId: Scalars['String'];
  created: Scalars['Date'];
  id: Scalars['String'];
  paymentType: Scalars['String'];
  status: IsInnovativeCaseClaimStatus;
};

export enum IsInnovativeCaseClaimStatus {
  CancellationRequired = 'CANCELLATION_REQUIRED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Processed = 'PROCESSED',
  ReadyForSubmission = 'READY_FOR_SUBMISSION',
  Submitted = 'SUBMITTED',
}

export type IsInnovativeCasePagedResult = {
  __typename?: 'IsInnovativeCasePagedResult';
  data: Array<IsInnovativeCase>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum IsInnovativeCaseStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  CreatedInError = 'CREATED_IN_ERROR',
  Duplicate = 'DUPLICATE',
  Inactive = 'INACTIVE',
  NotApproved = 'NOT_APPROVED',
  Pending = 'PENDING',
  ReadyForApproval = 'READY_FOR_APPROVAL',
  Recovered = 'RECOVERED',
  Started = 'STARTED',
  SubmitForApproval = 'SUBMIT_FOR_APPROVAL',
  SubmitForEndorsement = 'SUBMIT_FOR_ENDORSEMENT',
  Varied = 'VARIED',
  Waitlisted = 'WAITLISTED',
  Waived = 'WAIVED',
  Withdrawn = 'WITHDRAWN',
}

export enum IsInnovativeCaseType {
  InnovativeSolutions = 'INNOVATIVE_SOLUTIONS',
}

export type IsMessage = {
  __typename?: 'IsMessage';
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  comments: Scalars['String'];
  created: Scalars['DateTime'];
  effectiveDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  messageId: Scalars['String'];
  messageType: Scalars['String'];
  source: Scalars['String'];
  viewedStatus: CcssMessageViewedStatus;
};

export type IsMessagePagedResult = {
  __typename?: 'IsMessagePagedResult';
  data: Array<IsMessage>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type IsMessageUpdateViewedStatusInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  messageId: Scalars['ID'];
  viewedStatus: CcssMessageViewedStatus;
};

export type IsPaymentReportInput = {
  businessId: Scalars['ID'];
  centerIds: Array<InputMaybe<Scalars['ID']>>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export enum IsServiceProvisionType {
  FaceToFace = 'FACE_TO_FACE',
  NotFaceToFace = 'NOT_FACE_TO_FACE',
}

export type IsValidArrangement = {
  __typename?: 'IsValidArrangement';
  isValidArrangement: Scalars['Boolean'];
};

export type ItemizedBill = {
  __typename?: 'ItemizedBill';
  accountId: Scalars['ID'];
  amount: Scalars['Float'];
  billingCycleId?: Maybe<Scalars['ID']>;
  billingPeriodEndDate: Scalars['Date'];
  billingPeriodStartDate?: Maybe<Scalars['Date']>;
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  createdByPerson?: Maybe<Person>;
  displayableTransactions?: Maybe<Array<Maybe<Transaction>>>;
  dueOnDate: Scalars['Date'];
  forwardAmount: Scalars['Float'];
  id: Scalars['ID'];
  isCurrentBill: Scalars['Boolean'];
  isManuallyCreated: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  lateOnDate?: Maybe<Scalars['Date']>;
  latePaymentFee?: Maybe<LatePaymentFee>;
  totalAmount: Scalars['Float'];
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  userFriendlyId: Scalars['String'];
};

export type ItemizedBillSummary = {
  __typename?: 'ItemizedBillSummary';
  childGroups?: Maybe<Array<Maybe<ItemizedBillSummaryChildGroup>>>;
  ungroupedDiscounts?: Maybe<Array<Maybe<ItemizedBillSummaryDiscount>>>;
  ungroupedTransactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type ItemizedBillSummaryChildGroup = {
  __typename?: 'ItemizedBillSummaryChildGroup';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  sessionGroups?: Maybe<Array<Maybe<ItemizedBillSummarySessionGroup>>>;
  ungroupedDiscounts?: Maybe<Array<Maybe<ItemizedBillSummaryDiscount>>>;
  ungroupedTransactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type ItemizedBillSummaryDiscount = {
  __typename?: 'ItemizedBillSummaryDiscount';
  amount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type ItemizedBillSummarySessionGroup = {
  __typename?: 'ItemizedBillSummarySessionGroup';
  adjustmentName: Scalars['String'];
  baseFeeTotal?: Maybe<Scalars['Float']>;
  discounts?: Maybe<Array<Maybe<ItemizedBillSummaryDiscount>>>;
  earlyFee?: Maybe<Scalars['Float']>;
  estimatedSubsidyCredit?: Maybe<Scalars['Float']>;
  feeAdjustment?: Maybe<Scalars['Float']>;
  feeName: Scalars['String'];
  lateFee?: Maybe<Scalars['Float']>;
};

export enum ItemizedBillType {
  Detailed = 'DETAILED',
  Summary = 'SUMMARY',
}

export type KindyChildFundingSchedulesResult = {
  __typename?: 'KindyChildFundingSchedulesResult';
  basePercentage?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  programChildId: Scalars['String'];
  startDate: Scalars['String'];
  subsidyTypes?: Maybe<Array<Scalars['String']>>;
};

export type KindyForAllAcquittalCenterSummaryDto = {
  __typename?: 'KindyForAllAcquittalCenterSummaryDto';
  acquittedAmount?: Maybe<Scalars['Float']>;
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childCount: Scalars['Int'];
  forecastedAmount?: Maybe<Scalars['Float']>;
  kfsPlusCount: Scalars['Int'];
  kindyFtbCount: Scalars['Int'];
  messages: Array<ErrorDto>;
  paidToFamiliesAmount?: Maybe<Scalars['Float']>;
  period: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

export type KindyForAllAcquittalChildSummaryDto = {
  __typename?: 'KindyForAllAcquittalChildSummaryDto';
  acquittedAmount?: Maybe<Scalars['Float']>;
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childId: Scalars['ID'];
  childName?: Maybe<Scalars['String']>;
  forecastedAmount?: Maybe<Scalars['Float']>;
  kfsPlus: Scalars['Boolean'];
  kindyFtb: Scalars['Boolean'];
  paidToFamiliesAmount?: Maybe<Scalars['Float']>;
  period: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

export type KindyForAllAcquittalSubmissionInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  dryRun: Scalars['Boolean'];
  ownershipTransferred?: InputMaybe<Scalars['Boolean']>;
  period: Scalars['String'];
  softwareChangedDate?: InputMaybe<Scalars['String']>;
  year: Scalars['String'];
};

export type KindyForAllAgreementDto = {
  __typename?: 'KindyForAllAgreementDto';
  agreementNumber: Scalars['String'];
  applicantAccountName: Scalars['String'];
  applicantAccountNumber: Scalars['String'];
  beneficiaryAccountName: Scalars['String'];
  beneficiaryAccountNumber: Scalars['String'];
  exists: Scalars['Boolean'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type KindyForAllCenterAgreementDto = {
  __typename?: 'KindyForAllCenterAgreementDto';
  agreementId: Scalars['ID'];
  agreementNumber: Scalars['String'];
  beneficiaryAccountNumber: Scalars['String'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  id: Scalars['ID'];
};

export type KindyForAllCenterSummaryDto = {
  __typename?: 'KindyForAllCenterSummaryDto';
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childCount: Scalars['Int'];
  errors: Array<ErrorDto>;
  fundedAmount?: Maybe<Scalars['Float']>;
  kfsPlusCount: Scalars['Int'];
  kindyFtbCount: Scalars['Int'];
  period: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

export type KindyForAllChild = {
  __typename?: 'KindyForAllChild';
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationReason?: Maybe<CancellationReason>;
  childId: Scalars['ID'];
  disability?: Maybe<Scalars['Boolean']>;
  discretionaryStartDate?: Maybe<Scalars['Date']>;
  formalKinshipStartDate?: Maybe<Scalars['Date']>;
  formalProtectionCareStartDate?: Maybe<Scalars['Date']>;
  multiBirthStartDate?: Maybe<Scalars['Date']>;
  nonEnglishSpeakingBackground?: Maybe<Scalars['Boolean']>;
  visaType?: Maybe<VisaType>;
};

export type KindyForAllChildSummaryDto = {
  __typename?: 'KindyForAllChildSummaryDto';
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  childId: Scalars['ID'];
  childName?: Maybe<Scalars['String']>;
  fundedAmount?: Maybe<Scalars['Float']>;
  kfsPlus: Scalars['Boolean'];
  kindyFtb: Scalars['Boolean'];
  period: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

export type KindyForAllCredentialDto = {
  __typename?: 'KindyForAllCredentialDto';
  applicationKey: Scalars['String'];
  applicationName: Scalars['String'];
  applicationSecret: Scalars['String'];
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type KindyForAllDeleteSubmissionInput = {
  centerId: Scalars['ID'];
  period: Scalars['String'];
  year: Scalars['Int'];
};

export type KindyForAllIneligibilityDto = {
  __typename?: 'KindyForAllIneligibilityDto';
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  ineligible: Scalars['Boolean'];
  weekStart: Scalars['String'];
};

export type KindyForAllIneligibilityInput = {
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  ineligible: Scalars['Boolean'];
  weekStart: Scalars['String'];
};

export type KindyForAllSubmissionError = {
  centerId: Scalars['String'];
  centerName: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  innerError?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  period: Scalars['String'];
  target?: InputMaybe<Scalars['String']>;
  year: Scalars['String'];
};

export type KindyForAllSubmissionInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  dryRun: Scalars['Boolean'];
  period: Scalars['String'];
  year: Scalars['String'];
};

export type KindyFundingScheduleInput = {
  basePercentage?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  subsidyTypes?: InputMaybe<Array<Scalars['String']>>;
};

export type KindyProgramFundingScheduleInput = {
  basePercentage?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  subsidyTypes?: InputMaybe<Array<Scalars['String']>>;
  weeks?: InputMaybe<Scalars['Int']>;
};

export type KindyProgramFundingSchedulesResult = {
  __typename?: 'KindyProgramFundingSchedulesResult';
  basePercentage?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  programId: Scalars['String'];
  startDate: Scalars['String'];
  subsidyTypes?: Maybe<Array<Scalars['String']>>;
  weeks?: Maybe<Scalars['Int']>;
};

export type KisiApiShare = {
  __typename?: 'KisiApiShare';
  email?: Maybe<Scalars['String']>;
  group_id: Scalars['Int'];
  id: Scalars['Int'];
};

export type KisiAuthorization = {
  __typename?: 'KisiAuthorization';
  id?: Maybe<Scalars['Int']>;
  scram_credentials?: Maybe<ScramCredentials>;
  secret?: Maybe<Scalars['String']>;
};

export type KisiPairedCenter = {
  __typename?: 'KisiPairedCenter';
  id: Scalars['ID'];
  kisiApiKey?: Maybe<Scalars['String']>;
  kisiPlace?: Maybe<KisiPlace>;
  kisiPlaceId?: Maybe<Scalars['Int']>;
  kisiShareId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  staleInKisi: Scalars['Boolean'];
  syncedLockCount: Scalars['Int'];
};

export type KisiParentStatus = {
  __typename?: 'KisiParentStatus';
  accountCreatedInKisi: Scalars['Boolean'];
  centersWithAccess?: Maybe<Array<Maybe<KisiPairedCenter>>>;
  emailUsed: Scalars['String'];
  kisiSharesAssignedToUserCurrently?: Maybe<Array<Maybe<KisiApiShare>>>;
  memberId: Scalars['Int'];
  shareRecordsCurrently?: Maybe<Array<Maybe<KisiShare>>>;
  shareRecordsToBeCreated?: Maybe<Array<Maybe<KisiShare>>>;
  shareRecordsToBeDeleted?: Maybe<Array<Maybe<KisiShare>>>;
  sharesToBeCreatedOnNextSync?: Maybe<Array<Maybe<KisiApiShare>>>;
  sharesToBeDeletedOnNextSync?: Maybe<Array<Maybe<KisiApiShare>>>;
};

export type KisiPlace = {
  __typename?: 'KisiPlace';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locks_Count?: Maybe<Scalars['Int']>;
  members_Count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type KisiShare = {
  __typename?: 'KisiShare';
  businessId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  kisiEmail?: Maybe<Scalars['String']>;
  kisiShareId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['ID']>;
};

export type LastTimeEntry = {
  __typename?: 'LastTimeEntry';
  date: Scalars['Date'];
  id: Scalars['ID'];
  timeIn: Scalars['DateTime'];
  timeOut?: Maybe<Scalars['DateTime']>;
};

export type LatePaymentFee = {
  __typename?: 'LatePaymentFee';
  amount?: Maybe<Scalars['Float']>;
  waived?: Maybe<Scalars['Boolean']>;
  waivedAt?: Maybe<Scalars['String']>;
  waivedBy?: Maybe<Person>;
};

export type LearningStandard = {
  __typename?: 'LearningStandard';
  ageRanges?: Maybe<Array<Maybe<AgeRange>>>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<EducationPerson>;
  createdById: Scalars['ID'];
  domains?: Maybe<Array<Maybe<LearningStandardDomain>>>;
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  lastModifiedById?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type LearningStandardDomain = {
  __typename?: 'LearningStandardDomain';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<EducationPerson>;
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  lastModifiedById?: Maybe<Scalars['ID']>;
  learningStandardId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  subDomains?: Maybe<Array<Maybe<LearningStandardSubDomain>>>;
};

export type LearningStandardSubDomain = {
  __typename?: 'LearningStandardSubDomain';
  ageRangeOutcomes: Array<AgeRangeOutcome>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<EducationPerson>;
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  lastModifiedById?: Maybe<Scalars['ID']>;
  learningStandardDomainId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export enum LeaveType {
  Emergency = 'Emergency',
  Planned = 'PLANNED',
  PublicHoliday = 'PublicHoliday',
  PupilFree = 'PupilFree',
  Unplanned = 'UNPLANNED',
}

export type LessonPlanDay = {
  __typename?: 'LessonPlanDay';
  date: Scalars['DateTime'];
  experiences?: Maybe<Array<Maybe<LessonPlanExperience>>>;
  id: Scalars['ID'];
};

export type LessonPlanExperience = {
  __typename?: 'LessonPlanExperience';
  createdAt: Scalars['DateTime'];
  createdBy: EducationPerson;
  dayId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<EducationPerson>;
  name: Scalars['String'];
  order: Scalars['Int'];
  subject?: Maybe<CurriculumSubject>;
};

export type Library = {
  __typename?: 'Library';
  businessId: Scalars['ID'];
  contents?: Maybe<Array<Maybe<Content>>>;
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rootFolder?: Maybe<Folder>;
};

export type LibrarySearchInput = {
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  showFolders?: InputMaybe<Scalars['Boolean']>;
  term: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  associatedCenterIds?: Maybe<Array<Scalars['ID']>>;
  businessId: Scalars['ID'];
  href?: Maybe<Scalars['String']>;
  httpVerb?: Maybe<Scalars['String']>;
  linkCode: Scalars['ID'];
  name: Scalars['String'];
  rel?: Maybe<Scalars['String']>;
};

export type LinkDeleteLinkCodeInput = {
  businessId: Scalars['ID'];
  linkCode: Scalars['ID'];
};

export type LinkGenerateLinkCodeInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
};

export type LinkList = {
  __typename?: 'LinkList';
  actions?: Maybe<Array<Maybe<Link>>>;
  related?: Maybe<Array<Maybe<Link>>>;
  self?: Maybe<Link>;
};

export type LinkedCenterDetail = {
  __typename?: 'LinkedCenterDetail';
  ccssServiceId?: Maybe<Scalars['String']>;
  ccssServiceName?: Maybe<Scalars['String']>;
  centerName?: Maybe<Scalars['String']>;
};

export type LinkedCenterDetailPagedResults = {
  __typename?: 'LinkedCenterDetailPagedResults';
  data: Array<LinkedCenterDetail>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type ListFeesResponse = {
  __typename?: 'ListFeesResponse';
  data?: Maybe<Array<Maybe<Fee>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type ListPersonsInput = {
  count?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type Location = {
  __typename?: 'Location';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Lock = {
  __typename?: 'Lock';
  allowParentUnlock: Scalars['Boolean'];
  allowStaffUnlock: Scalars['Boolean'];
  centerId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lockId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type LookupJson = {
  __typename?: 'LookupJson';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ManualStatementRunsInput = {
  accountIds?: InputMaybe<Array<Scalars['ID']>>;
  accountTagIds?: InputMaybe<Array<Scalars['ID']>>;
  centerId: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
  periodEndOn: Scalars['Date'];
  periodStartFrom: Scalars['Date'];
};

export enum MatchType {
  All = 'ALL',
  Any = 'ANY',
  Child = 'Child',
  Contact = 'Contact',
}

export enum MealPricing {
  Free = 'Free',
  Paid = 'Paid',
  Reduced = 'Reduced',
}

export type MedicalCondition = {
  __typename?: 'MedicalCondition';
  archivedAt?: Maybe<Scalars['String']>;
  archivedByPerson?: Maybe<Staff>;
  childId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['String']>;
  createdByPerson?: Maybe<Staff>;
  documents: Array<WellnessItemDocument>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  isRevised?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  otherMedicalConditionType?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['ID']>;
  risk: Risk;
  symptoms?: Maybe<Array<MedicalSymptomType>>;
  type: HealthTerm;
  typeId: Scalars['ID'];
};

export type MedicalConditionInput = {
  documentation?: InputMaybe<Array<ChildMedicalDocumentationInput>>;
  instructions?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  otherMedicalConditionType?: InputMaybe<Scalars['String']>;
  risk: Risk;
  symptoms?: InputMaybe<Array<MedicalSymptomType>>;
  typeId: Scalars['String'];
};

export type MedicalConditionRevision = {
  __typename?: 'MedicalConditionRevision';
  conditionVersion?: Maybe<MedicalCondition>;
  documentsChanged?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  revisedAt?: Maybe<Scalars['DateTime']>;
  revisedByPerson?: Maybe<Staff>;
  risk?: Maybe<Risk>;
  symptomsChanged?: Maybe<Scalars['Boolean']>;
};

export type MedicalInfoDto = {
  __typename?: 'MedicalInfoDto';
  allergyName?: Maybe<LookupJson>;
  category?: Maybe<LookupJson>;
  documents?: Maybe<Array<DocumentUpload>>;
  id: Scalars['String'];
  importance?: Maybe<LookupJson>;
  medicalName?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  reactions?: Maybe<LookupJson>;
  restrictionName?: Maybe<Scalars['String']>;
  risk?: Maybe<LookupJson>;
  severity?: Maybe<LookupJson>;
  symptoms?: Maybe<LookupJson>;
  type?: Maybe<LookupJson>;
};

export enum MedicalSymptomType {
  ChestPain = 'ChestPain',
  DifficultyBreathing = 'DifficultyBreathing',
  DiscolorationOfSkin = 'DiscolorationOfSkin',
  Dizziness = 'Dizziness',
  ExcessiveSweating = 'ExcessiveSweating',
  Gas = 'Gas',
  GeneralPainOrDiscomfort = 'GeneralPainOrDiscomfort',
  Headache = 'Headache',
  Irritability = 'Irritability',
  Lethargy = 'Lethargy',
  Nausea = 'Nausea',
  Other = 'Other',
  RepetitiveCough = 'RepetitiveCough',
  Seizure = 'Seizure',
  ShockOrCirculatoryCollapse = 'ShockOrCirculatoryCollapse',
  Wheezing = 'Wheezing',
}

export type MedicareCard = {
  __typename?: 'MedicareCard';
  accountChildId: Scalars['ID'];
  businessId: Scalars['ID'];
  expiry: Scalars['String'];
  id: Scalars['ID'];
  medicareNumber: Scalars['String'];
  position: Scalars['String'];
};

export type Merchant = {
  __typename?: 'Merchant';
  id: Scalars['ID'];
  isSandbox: Scalars['Boolean'];
  /** payrix */
  merchantId?: Maybe<Scalars['String']>;
  merchantToken?: Maybe<Scalars['String']>;
  /** fat zebra */
  merchantUsername?: Maybe<Scalars['String']>;
  processor: PaymentProcessor;
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
  mimetype?: Maybe<Scalars['String']>;
  presignedDownloadUrl?: Maybe<Scalars['String']>;
  presignedUploadUrl?: Maybe<Scalars['String']>;
  uploadState: UploadState;
  userActivityMessageId?: Maybe<Scalars['Int']>;
};

export type MessageAttachmentsDtoPagedResult = {
  __typename?: 'MessageAttachmentsDtoPagedResult';
  data: Array<MessageAttachment>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos: Array<SortDto>;
  totalRecords: Scalars['Int'];
};

export enum MessageDirectedAtType {
  Class = 'Class',
  Staff = 'Staff',
}

export type MessageDto = {
  __typename?: 'MessageDto';
  code: Scalars['String'];
  innerError?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  target?: Maybe<Scalars['String']>;
};

export type Metadata = {
  __typename?: 'Metadata';
  isWritable?: Maybe<Scalars['Boolean']>;
};

export type MonthlyMessageCount = {
  __typename?: 'MonthlyMessageCount';
  messagesSent: Scalars['Int'];
  month: Scalars['String'];
  personType?: Maybe<PersonType>;
};

export type MoveChildToClassInput = {
  centerId: Scalars['ID'];
  currentlyInClassId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MoveChildrenToNewClassChildDataInput = {
  childId: Scalars['ID'];
  contractId: Scalars['ID'];
  enrolledClassId?: InputMaybe<Scalars['ID']>;
};

export type MoveChildrenToNewClassInput = {
  actionByStaffId: Scalars['ID'];
  centerId: Scalars['ID'];
  childData: Array<InputMaybe<MoveChildrenToNewClassChildDataInput>>;
  newClassId: Scalars['ID'];
  newClassName: Scalars['String'];
  spaceId: Scalars['ID'];
};

export type MovePersonToClassInput = {
  centerId: Scalars['ID'];
  classId: Scalars['ID'];
  personId: Scalars['ID'];
};

export type MultiDeleteSubsidyCenterRatingInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  data: Array<InputMaybe<Scalars['ID']>>;
};

export type MultiUpsertSubsidyCenterRatingInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  data: Array<InputMaybe<SubsidyCenterRatingInput>>;
};

export type MutateRoleAreaLevelInput = {
  area: Scalars['String'];
  level: Scalars['Int'];
  permission: Scalars['String'];
  roleId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateFee?: Maybe<Fee>;
  addCasualBookingExclusions: Array<CasualBookingExclusion>;
  addCcssApproval: CcssApproval;
  addCcssProviderNotification?: Maybe<Scalars['Void']>;
  addCenterToAccount: Account;
  addChildCcsEnrolment: CcsEnrolment;
  addChildTag?: Maybe<Photo>;
  addChildToAccount: Account;
  addChildrenToProgram: Scalars['Boolean'];
  addClassClosures: Array<ClassClosureDto>;
  addEducationPermissionToGroup?: Maybe<EducationPermission>;
  addExistingChildToExistingAccount: Scalars['Boolean'];
  addNewContactToAccount: Contact;
  addResourceToFolder?: Maybe<FileResource>;
  addStaffToCenter: Staff;
  addTagsToPersonAtEntity?: Maybe<Staff>;
  adminClockOut: TimeEntry;
  adminUnlockLock?: Maybe<UnlockResponse>;
  adviseChildNoLongerAtRisk: AdviseChildNoLongerAtRisk;
  applyDiscountToTransaction?: Maybe<Transaction>;
  approvePayment?: Maybe<Transaction>;
  approvePhotos?: Maybe<Array<Maybe<Photo>>>;
  approveStaff: Staff;
  approveStaffAvailabilityRequest: Staff;
  archiveAdjustment?: Maybe<Adjustment>;
  archiveAgency?: Maybe<Agency>;
  archiveAgencyAccountChild?: Maybe<AgencyAccountChild>;
  archiveAgencyEnrollment?: Maybe<AgencyAccountChildEnrollment>;
  archiveAllergyForChild: Allergy;
  archiveAllergyForStaff: Allergy;
  archiveBasePosition: Position;
  archiveChild: Scalars['Boolean'];
  archiveChildOnAccount: AccountChild;
  archiveChildProgram: Scalars['Boolean'];
  archiveClass?: Maybe<Class>;
  archiveComment: Comment;
  archiveCustomField?: Maybe<CustomField>;
  archiveGLCode: GlCode;
  archiveHealthTerm: HealthTerm;
  archiveMedicalCondition: MedicalCondition;
  archiveNote: Note;
  archiveProgram: Scalars['Boolean'];
  archivePtoType?: Maybe<PtoType>;
  archiveRestriction: Restriction;
  archiveTransactionType: TransactionType;
  bouncedCheck?: Maybe<Transaction>;
  bulkCancelArrangements: Array<ComplyingWrittenArrangement>;
  bulkCustody: BulkCustodyResponse;
  bulkEditFee: EditFeeResult;
  bulkSendAgreementEmails: Array<ComplyingWrittenArrangement>;
  bulkStatusChange?: Maybe<Scalars['Int']>;
  bulkUpsertClassCapacityOverride: Array<ClassCapacityOverride>;
  /** Complying Written Arrangement (CWA) */
  cancelArrangement: ComplyingWrittenArrangement;
  cancelCcssCertificate: CcssCertificate;
  cancelIsCaseClaim: IsCaseClaim;
  cancelPayment?: Maybe<Transaction>;
  cancelReturnFeeReduction: Array<Scalars['ID']>;
  cancelTimeOffRequest: TimeOffRequest;
  changeChildStatus?: Maybe<SuccessfulChildStatusChange>;
  /** magic link mutations */
  changeEmailViaMagicLink?: Maybe<Staff>;
  chargeFullFeeEndCareSessions: Array<Session>;
  /** mutations related to attendance sessions */
  checkIn: CheckInOutData;
  checkOut: CheckInOutData;
  checkOutPreviousTimeEntry: CheckInOutData;
  checkOutTest: CheckInOutData;
  checkProcessingStatusOfPayments: Scalars['Boolean'];
  clearExperiencesForDates: Array<EducationExperience>;
  clearNotifications: Array<Scalars['ID']>;
  clearProgramThemeExperiences?: Maybe<Scalars['Void']>;
  clearSession: Session;
  /** mutations related to Time Entry */
  clockIn: TimeEntry;
  clockInV2: TimeEntry;
  clockOut: TimeEntry;
  closeReEnrollments?: Maybe<ReEnrollmentCloseResponse>;
  copySchedule: Schedule;
  copyShift: Array<Shift>;
  createAPIKey: ApiKey;
  /** mutations related to accounts */
  createAccount: Account;
  createAccountBillingCycle?: Maybe<BillingCycle>;
  createAccountingPeriod: Scalars['Boolean'];
  /** adjustment */
  createAdjustment?: Maybe<Adjustment>;
  /** mutations related to Agencies */
  createAgency?: Maybe<Agency>;
  createAgencyAccountChild?: Maybe<AgencyAccountChild>;
  createAgencyPayment?: Maybe<AgencyPayment>;
  createAllergyForChild: Allergy;
  createAllergyForStaff: Allergy;
  /** ccss debt */
  createAlternateArrangement: AlternateArangementCreate;
  createAnnouncement?: Maybe<Announcement>;
  /** APiKey WebHooks */
  createApiKeyWebHook: ApiKeyWebHook;
  createApplicationNote: ApplicationNote;
  createAppliedAccountDiscount?: Maybe<Array<Maybe<AppliedAccountDiscount>>>;
  createAuthorizedPersonPaymentMethod: PaymentMethod;
  /** mutations related to positions and staff positions */
  createBasePosition: Position;
  /** Batch */
  createBatch?: Maybe<Batch>;
  /** mutations related to billing */
  createBillingCycleTemplate: BillingCycleTemplate;
  /** bus roster */
  createBusRoster?: Maybe<BusRoster>;
  /** mutations related to inclusion support */
  createCcmsApproval: CcmsApproval;
  createCcssCertificate: Array<CcssCertificate>;
  createCcssDetermination: Array<CcssDetermination>;
  createCcssProviderFinancialStatus?: Maybe<Scalars['Void']>;
  createCenter?: Maybe<Center>;
  createCenterLocation: Location;
  /** Comms Mutations */
  createChatActivity?: Maybe<CreateActivity>;
  createChildAsChargeOfPersonAtCenter?: Maybe<Person>;
  createChildAtCenter?: Maybe<Child>;
  createChildImmunizationRecord: Array<Immunization>;
  createChildMedicalCondition: MedicalCondition;
  createChildRestriction: Restriction;
  createClass?: Maybe<Class>;
  /** Comments */
  createComment: Comment;
  createContactForChild?: Maybe<ChildContact>;
  /** mutations related to contracts */
  createContract: Contract;
  createContractAndAccountBillingCycle?: Maybe<CreateContractAndBillingCycle>;
  createCurriculum: Curriculum;
  createCurriculumSchedule?: Maybe<CurriculumSchedule>;
  /** Custom Data Fields */
  createCustomField?: Maybe<CustomField>;
  createDeposit?: Maybe<Array<Maybe<Deposit>>>;
  /** Device Mutations */
  createDeviceForContact?: Maybe<Device>;
  /** mutations related to Discounts */
  createDiscount?: Maybe<Discount>;
  createEditKindyForAllCenterAgreement: KindyForAllCenterAgreementDto;
  createEditKindyForAllCredential: KindyForAllCredentialDto;
  createEditKindyForAllIneligibilities: Array<KindyForAllIneligibilityDto>;
  createEditTransitionStatement: TransitionStatementDto;
  createEducationAttachment: EducationAttachment;
  createEducationExperience: Array<EducationExperience>;
  createEducationProgram: EducationProgram;
  /** EnrollmentForm */
  createEnrollmentForm: EnrollmentForm;
  createEnrollmentProgram?: Maybe<EnrollmentProgram>;
  /** Enrollment Program Mutations */
  createEnrollmentProgramGroup?: Maybe<EnrollmentProgramGroup>;
  createEntity: Entity;
  /** Feature Flags */
  createFeatureFlag: FeatureFlag;
  createFee: Fee;
  /** Files Mutations */
  createFileStorageInfo?: Maybe<FileStorageInfo>;
  /** Folder Mutations */
  createFolder?: Maybe<Folder>;
  /** GL Codes */
  createGLCodes: Array<GlCode>;
  /** Group Mutations */
  createGroup?: Maybe<Group>;
  createHealthTerm: HealthTerm;
  /** IntegerationAPiKey */
  createIntegrationApiKey: IntegrationApiKey;
  createIsEducator: IsEducator;
  /** Kindy For All Mutations */
  createKindyChildFundingSchedules: Array<KindyChildFundingSchedulesResult>;
  createKindyProgramFundingSchedules: Array<Maybe<KindyProgramFundingSchedulesResult>>;
  createKisiShare?: Maybe<KisiShare>;
  /** Education Learning Standard Mutations */
  createLearningStandard: LearningStandard;
  createLearningStandardDomain: LearningStandardDomain;
  createLearningStandardOutcome: Outcome;
  createLearningStandardSubDomain: LearningStandardSubDomain;
  /** Library Mutations */
  createLibrary?: Maybe<Library>;
  createLock: Lock;
  createManualItemizedBill?: Maybe<ItemizedBill>;
  createManualPayment?: Maybe<Transaction>;
  createMessageAttachment?: Maybe<MessageAttachment>;
  /** Notes */
  createNote: Note;
  createOfferSchedule: ApplicationScheduleOffer;
  createPaymentMethod: PaymentMethod;
  createPayrixCustomerForAccount?: Maybe<Scalars['String']>;
  createPermission: Permission;
  createPhoto: Photo;
  createPosition: StaffPosition;
  /** Program */
  createProgram: Program;
  /** Program Leave Day */
  createProgramLeaveDay: Array<Maybe<ProgramLeaveDay>>;
  /** mutations related to ccss */
  createProvider: Provider;
  createProviderConflictOfInterests?: Maybe<Scalars['Void']>;
  /** PtoTypes */
  createPtoType?: Maybe<PtoType>;
  createRegulation?: Maybe<Regulation>;
  /** ccss return fee reduction */
  createReturnFeeReduction: ReturnFeeReduction;
  createReverseTransaction: Transaction;
  /** mutations related to roles and permissions */
  createRole: Role;
  createRoyaltyFee?: Maybe<RoyaltyFee>;
  createRoyaltyFeeException?: Maybe<RoyaltyFeeException>;
  /** mutations related to schedules */
  createSchedule: Schedule;
  createServiceTemporarilyCeasing?: Maybe<Scalars['Void']>;
  createSession: Session;
  /** Session Amendment */
  createSessionAmendment?: Maybe<Array<Maybe<SessionAmendmentDto>>>;
  createShift: Array<Shift>;
  createSpace?: Maybe<Space>;
  createStaff: Staff;
  /** mutations related to medical */
  createStaffMedicalCondition: MedicalCondition;
  createStudentChatActivity?: Maybe<CreateActivity>;
  /** Education Curriculum Mutations */
  createSubject: CurriculumSubject;
  createSubscription: Subscription;
  createTag?: Maybe<Tag>;
  createTheme: Theme;
  createThemeLesson: ThemeLesson;
  createThemeLessonExperience?: Maybe<EducationExperience>;
  /** TimeCardLock */
  createTimeCardLockForBusiness?: Maybe<TimeCardLockDto>;
  createTimeEntry: TimeEntry;
  createTimeEntryV2: CreateTimeEntryV2Data;
  /** mutations related to time off requests */
  createTimeOffRequest: TimeOffRequest;
  createTimeOffRequestForMe: TimeOffRequest;
  /** Training time events */
  createTrainingTimeEvent: TrainingTimeEvent;
  createTransaction: Transaction;
  createTransactionType: Array<TransactionType>;
  createTransactionTypesInBulk: Array<TransactionType>;
  createTransactionsForSessions: Array<Transaction>;
  createUnit: Unit;
  custody: CustodyResponse;
  deactivateCenter?: Maybe<Center>;
  /** mutations related to fees */
  deactivateFee?: Maybe<Fee>;
  deactivateStaff: Staff;
  deleteAPIKey?: Maybe<ApiKey>;
  deleteAdjustment?: Maybe<Adjustment>;
  deleteAgencyPayment?: Maybe<AgencyPayment>;
  deleteAllergyForChild: Allergy;
  deleteAllergyForStaff: Allergy;
  deleteAnnouncement?: Maybe<Announcement>;
  deleteApplicationNote: ApplicationNote;
  deleteAppliedAccountDiscount?: Maybe<AppliedAccountDiscount>;
  deleteBatch: DeletedBatchData;
  deleteBillingCycleTemplate: BillingCycleTemplate;
  deleteBusRoster?: Maybe<BusRoster>;
  deleteCasualBookingExclusion: CasualBookingExclusion;
  deleteCcmsApproval: Scalars['Boolean'];
  deleteCcssApproval: CcssApprovalId;
  deleteCenter?: Maybe<Center>;
  deleteCenterInviteCode: Center;
  deleteChildContactRelationship: Contact;
  deleteChildImmunizationRecord?: Maybe<Immunization>;
  deleteContract: Scalars['Boolean'];
  deleteCustomFieldFile: Scalars['Boolean'];
  deleteDailyNote?: Maybe<Scalars['Boolean']>;
  deleteDeviceForContact?: Maybe<Device>;
  deleteDiscount?: Maybe<Discount>;
  deleteEducationExperience: Scalars['ID'];
  deleteEducationProgram: Scalars['ID'];
  deleteEnrollmentProgramGroup?: Maybe<Scalars['Boolean']>;
  deleteEntity?: Maybe<Entity>;
  deleteFreeKindyAcquittal: DeletedAcquittalDto;
  deleteFreeKindyForecast: DeletedForecastDto;
  deleteHealthProfessional?: Maybe<HealthProfessional>;
  deleteIntegrationApiKey: IntegrationApiKey;
  deleteKindyForAllAcquittal: DeletedAcquittalDto;
  deleteKindyForAllForecast: DeletedForecastDto;
  deleteKisiShare?: Maybe<KisiShare>;
  deleteLearningStandard?: Maybe<Scalars['String']>;
  deleteLearningStandardDomain?: Maybe<Scalars['String']>;
  deleteLearningStandardOutcome?: Maybe<Scalars['String']>;
  deleteLearningStandardSubDomain?: Maybe<Scalars['String']>;
  deleteLinkCode: Scalars['Boolean'];
  deleteMedicalCondition: MedicalCondition;
  deleteMedicareCard?: Maybe<MedicareCard>;
  deleteMessage?: Maybe<CreateActivity>;
  deletePaymentMethod: PaymentMethod;
  deletePendingStaff: Staff;
  deletePersonPaymentMethod: PaymentMethod;
  deletePhoto?: Maybe<Photo>;
  deletePosition: StaffPosition;
  deleteProgramLeaveDay: Scalars['Boolean'];
  deleteRegulation?: Maybe<Regulation>;
  deleteRestriction: Restriction;
  deleteRole: Role;
  deleteRoyaltyFee?: Maybe<RoyaltyFee>;
  deleteRoyaltyFeeException?: Maybe<RoyaltyFeeException>;
  deleteServiceFeeGroup: Scalars['Boolean'];
  deleteServiceTemporarilyCeasing?: Maybe<Scalars['Void']>;
  deleteSessionAmendment?: Maybe<Scalars['Boolean']>;
  deleteSessionTimeEntry: AttendanceTimeEntry;
  deleteShifts: Array<Scalars['ID']>;
  deleteSpace?: Maybe<Space>;
  deleteSubject?: Maybe<Scalars['String']>;
  /** SubsidyCenterRating */
  deleteSubsidyCenterRatings: Scalars['Boolean'];
  deleteTag?: Maybe<Tag>;
  deleteTimeEntry: TimeEntry;
  deleteTrainingTimeEvent?: Maybe<Scalars['ID']>;
  deleteTransactionTypeAuthorizationRoles?: Maybe<Scalars['Boolean']>;
  denyPhotos?: Maybe<Array<Maybe<Photo>>>;
  denyStaffAvailabilityRequest: Staff;
  destroyBusiness: Scalars['ID'];
  dissociateStaffFromCenter: Staff;
  editApplication: Application;
  editApplicationNote: ApplicationNote;
  editCcmsApproval: CcmsApproval;
  editCcssApproval: CcssApproval;
  editCcssCertificate?: Maybe<CcssCertificate>;
  editChildCcsEnrolment: CcsEnrolment;
  editFee: Fee;
  encryptEnrollmentFormHash: Scalars['String'];
  endAndCreateContract: Contract;
  flagMessage?: Maybe<CreateActivity>;
  forgetChildAtCenter?: Maybe<Child>;
  /** Enrolment */
  generateLinkCode: Link;
  generatePdfStatement: Scalars['String'];
  getApplicationCenterSettings: GetAppllicationCenterSettingsResponse;
  hideEndCareSessions?: Maybe<Scalars['Void']>;
  importEzChildTrackContracts?: Maybe<Scalars['Void']>;
  importEzChildTrackFamilies?: Maybe<Scalars['Void']>;
  linkEnrolmentById: Scalars['Boolean'];
  manualStatementRuns: StatementRun;
  markLost?: Maybe<Scalars['Boolean']>;
  markMessageAttachmentUploaded?: Maybe<MessageAttachment>;
  markNotificationsAsRead: Array<Scalars['ID']>;
  markRead?: Maybe<Scalars['Boolean']>;
  moveChildToClass?: Maybe<Scalars['Boolean']>;
  moveChildrenToClass?: Maybe<Scalars['Boolean']>;
  moveChildrenToNewClass?: Maybe<Scalars['Boolean']>;
  moveClassToSpace?: Maybe<Scalars['Boolean']>;
  movePersonToClass?: Maybe<SuccessfulPersonClassMove>;
  moveStaffToClass?: Maybe<Scalars['Boolean']>;
  multiApproveApplication: Array<Maybe<ApplicationMultiApproveResponse>>;
  nameToFace?: Maybe<Scalars['Boolean']>;
  openClassOnDate?: Maybe<Scalars['Boolean']>;
  postFreeKindyAcquittal: Array<AcquittalDto>;
  postFreeKindyForecast: Array<ForecastDto>;
  postKindyForAllAcquittal: Array<AcquittalDto>;
  postKindyForAllForecast: Array<ForecastDto>;
  processBillingCycles: Scalars['Boolean'];
  /** Itemized Bill */
  processLateItemizedBills: Scalars['Boolean'];
  /** Payrix Webhook */
  processPayrixTxnWebhookEvent: Scalars['Boolean'];
  processScheduledNotifications: Array<ScheduledNotification>;
  publishSchedule: Schedule;
  reRollAPIKey?: Maybe<ApiKey>;
  reactivateCenter?: Maybe<Center>;
  reactivateStaff: Staff;
  releaseCustody?: Maybe<Scalars['Boolean']>;
  removeCenterLocation: Location;
  removeChildOnAccount: AccountChild;
  removeChildTag?: Maybe<Photo>;
  removeClassFromCenter?: Maybe<Scalars['Boolean']>;
  removeContactFromAccount: Contact;
  removeDiscountFromTransaction?: Maybe<Transaction>;
  removeEducationPermissionFromGroup?: Maybe<EducationPermission>;
  removeEmployeeFromTrainingTimeEvent?: Maybe<Scalars['ID']>;
  removeFolder?: Maybe<Scalars['String']>;
  removeGroup?: Maybe<Scalars['Void']>;
  removeResourceFromFolder?: Maybe<Scalars['ID']>;
  removeTagsFromPersonAtEntity?: Maybe<Staff>;
  reorderProgramThemeExperiences?: Maybe<Scalars['Void']>;
  reorderThemes?: Maybe<Scalars['Void']>;
  reorderUnits?: Maybe<Scalars['Void']>;
  reportAbsence: ReportAbsenceData;
  reportAdditionalAbsence: Array<Absence>;
  reportAdditionalAbsenceNew: Array<AbsenceDto>;
  reportBulkAdditionalAbsence: Array<Absence>;
  reportBulkAdditionalAbsenceNew: Array<AbsenceDto>;
  requestAvailabilityChangeForMe: Staff;
  requestToJoinCenterViaCode: Staff;
  requestUpdateEnrolmentForm?: Maybe<Scalars['String']>;
  resendApplicationOffer: Scalars['Boolean'];
  resetAttendanceCentersSetting: AttendanceCenterSettings;
  resetCenterSettings?: Maybe<CommsCenterSetting>;
  resetCenterState?: Maybe<Scalars['Boolean']>;
  resetReEnrolCenterSettings: ReEnrolCenterSettings;
  resubmitSessionReportSync?: Maybe<Scalars['Boolean']>;
  returnDeposit?: Maybe<Array<Deposit>>;
  revealEndCareSessions?: Maybe<Scalars['Void']>;
  /** Service Fees */
  saveServiceFeeGroup?: Maybe<ServiceFeeGroup>;
  scheduleProgramThemeExperiences?: Maybe<Scalars['Void']>;
  selectCcrmMapping?: Maybe<Scalars['Boolean']>;
  sendAdhocStatement?: Maybe<Scalars['Boolean']>;
  /** Lead to Booking */
  sendApplicationOffer: ApplicationOffer;
  sendCwaAgreementEmail: ComplyingWrittenArrangement;
  sendDailyNote?: Maybe<Scalars['Boolean']>;
  sendEmailVerificationLinkForMe: Scalars['ID'];
  sendInvitation: EmailSendResponse;
  sendMobileAppInvitationEmailToContact?: Maybe<Scalars['Boolean']>;
  /** ReEnrollment Mutations */
  sendReEnrollments?: Maybe<Scalars['String']>;
  sendTransactionsToPaymentProcessor: Scalars['Boolean'];
  setAttendanceBusinessSetting: AttendanceBusinessSettings;
  setAttendanceCentersSetting: AttendanceCenterSettings;
  setCenterSession?: Maybe<Scalars['Boolean']>;
  setChildPresence?: Maybe<Scalars['Boolean']>;
  setCommsBusinessSettings?: Maybe<CommsBusinessSetting>;
  setCommsCenterSettings?: Maybe<CommsCenterSetting>;
  /** mutations related to cpav */
  setCurrentCpavForCentreService: ServiceCpav;
  setPaymentMethodIsAutoPay?: Maybe<AccountPaymentMethodSetting>;
  /** account payment method */
  setPrimaryPaymentMethod?: Maybe<AccountPaymentMethodSetting>;
  setReEnrolBusinessSettings: ReEnrolBusinessSettings;
  setReEnrolCenterSettings: ReEnrolCenterSettings;
  setStaffPresence?: Maybe<Scalars['Boolean']>;
  signUpNewUser?: Maybe<CognitoCreateNewUserResponse>;
  signUpStaffInvite?: Maybe<CognitoStaffAcceptInviteResult>;
  splitClass?: Maybe<Scalars['Boolean']>;
  stopOperatingService?: Maybe<Scalars['Void']>;
  submitIsCaseClaim: Array<IsCaseClaim>;
  syncBusinessLocks?: Maybe<Array<Maybe<Lock>>>;
  syncCenterLocks?: Maybe<Array<Maybe<Lock>>>;
  takeCustody?: Maybe<Scalars['Boolean']>;
  toggleBusinessFeatures: Array<EducationFeature>;
  toggleIsCaseClaimWeekStatus: Array<IsCaseExcludedWeek>;
  turnOffUSMode: BillingBusinessSettings;
  turnOnUSMode: BillingBusinessSettings;
  unarchiveChildOnAccount: AccountChild;
  unarchiveHealthTerm: HealthTerm;
  unlinkEnrolmentById: Scalars['Boolean'];
  unlockLock?: Maybe<UnlockResponse>;
  unpublishSchedule: Schedule;
  updateAPIKey?: Maybe<ApiKey>;
  updateAccount: Account;
  updateAccountAutoPayPaymentMethod?: Maybe<PaymentMethod>;
  updateAccountBillingCycle: BillingCycle;
  updateAccountContactRelationships: Array<ContactChild>;
  updateAccountDefaultPaymentMethod: PaymentMethod;
  updateAccountTags: Account;
  updateAccountingPeriod: Scalars['Boolean'];
  updateAdjustment?: Maybe<Adjustment>;
  updateAgency?: Maybe<Agency>;
  updateAgencyAccountChildEnrollment?: Maybe<AgencyAccountChild>;
  updateAgencyPayment?: Maybe<AgencyPayment>;
  updateAllergenListForHealthTerm: Array<Allergen>;
  updateAllergyForChild: Allergy;
  updateAllergyForStaff: Allergy;
  updateAnnouncement?: Maybe<Announcement>;
  updateApiKeyWebHook: ApiKeyWebHook;
  updateApplicationCenterSetting?: Maybe<CenterSettings>;
  updateApplicationMultipleCentersSetting: GetAppllicationCenterSettingsResponse;
  updateApplicationOfferAndSchedules: UpdateApplicationOfferResponse;
  updateApplicationSetting: ApplicationSetting;
  updateAppliedAccountDiscount?: Maybe<AppliedAccountDiscount>;
  updateAvailabilityForStaff: Staff;
  updateBasePosition: Position;
  updateBillingBusinessSettings: BillingBusinessSettings;
  updateBillingCycleTemplate: BillingCycleTemplate;
  updateBusRoster?: Maybe<BusRoster>;
  updateBusinessAccountOwners?: Maybe<Entity>;
  updateBusinessAutoPayPaymentMethod?: Maybe<PaymentMethod>;
  updateBusinessAvatar?: Maybe<Entity>;
  updateBusinessDefaultPaymentMethod: PaymentMethod;
  updateBusinessHubSpotCustomerId?: Maybe<Entity>;
  updateBusinessKisiApiKey: Entity;
  updateBusinessProfileInfo?: Maybe<Entity>;
  /** Casual booking */
  updateCasualBookingSettings: Array<CasualBookingSettings>;
  updateCcssProviderMessage: CcssProviderMessage;
  updateCcssServiceMessage: CcssServiceMessage;
  updateCenter?: Maybe<Center>;
  updateCenterAvatar?: Maybe<Center>;
  updateCenterInviteCode: Center;
  /** Kisi */
  updateCenterKisiPlaceId: Center;
  updateCenterLogo?: Maybe<Center>;
  updateCenterMerchantId: Merchant;
  updateCenterPayPeriod?: Maybe<Center>;
  updateCenterSettings: UpdateCenterSettingsData;
  updateCenterSettingsForAllByBusinessId: UpdateCenterSettingsForAllByBusinessIdData;
  updateCenterStatementMessage?: Maybe<Scalars['String']>;
  /** statement */
  updateCenterStatementSchedule: StatementSchedule;
  updateCenterTags?: Maybe<Center>;
  updateChildAtCenter?: Maybe<Child>;
  updateChildAvatar?: Maybe<Child>;
  updateChildContact: ChildContact;
  updateChildEmergencyContacts?: Maybe<Array<Maybe<EmergencyContact>>>;
  updateChildMealStatus?: Maybe<ChildMealStatus>;
  /** mutations related to children */
  updateChildPersonalInfo?: Maybe<Child>;
  updateChildProgram: Scalars['Boolean'];
  updateChildTags?: Maybe<Child>;
  updateClass?: Maybe<Class>;
  updateContactAvatar?: Maybe<Contact>;
  updateContactChildRelationship: ChildContact;
  updateContactEmail?: Maybe<Contact>;
  /** mutations related to Contacts */
  updateContactPersonalInfo?: Maybe<Contact>;
  updateContactTags?: Maybe<Contact>;
  updateContract: Contract;
  updateCurriculum: Curriculum;
  updateCurriculumSchedule?: Maybe<CurriculumSchedule>;
  updateCurriculumScheduleStatus?: Maybe<CurriculumSchedule>;
  updateCurriculumStatus: Curriculum;
  updateCustomField?: Maybe<CustomField>;
  updateCustomFieldFileUpload?: Maybe<CustomFieldDocumentFile>;
  /** Custom Fields */
  updateCustomFieldValues: Scalars['Boolean'];
  updateDailyNote?: Maybe<Scalars['Boolean']>;
  updateDiscount?: Maybe<Discount>;
  updateEducationExperience: EducationExperience;
  /** Education Permissions Mutations */
  updateEducationPermissions?: Maybe<EducationPermission>;
  updateEducationProgram: EducationProgram;
  updateEmail: UpdateEmail;
  updateEmergencyContactsOfPerson?: Maybe<Array<Maybe<EmergencyContact>>>;
  updateEmploymentHistory: Staff;
  updateEnrollmentForm: EnrollmentForm;
  updateEnrollmentProgram?: Maybe<EnrollmentProgram>;
  updateEnrollmentProgramGroup?: Maybe<EnrollmentProgramGroup>;
  updateEntity?: Maybe<Entity>;
  updateEntityDataType?: Maybe<Entity>;
  updateEntityInviteCode: Entity;
  updateFeatureFlag: FeatureFlag;
  updateFolder?: Maybe<Folder>;
  updateFolderPermissions?: Maybe<UpdateFolderPermissionsResponse>;
  updateGLCode: GlCode;
  updateGLCodeAssignment: Array<GlCodeMapping>;
  updateGroup?: Maybe<Group>;
  updateImmunizationDocument: Immunization;
  updateIntegrationApiKey: IntegrationApiKey;
  updateIsMessageViewedStatus: IsMessage;
  updateItemizedBillType?: Maybe<Array<Maybe<CenterBillingSetting>>>;
  updateLearningStandard: LearningStandard;
  updateLearningStandardDomain: LearningStandardDomain;
  updateLearningStandardOutcome: Outcome;
  updateLearningStandardSubDomain: LearningStandardSubDomain;
  updateLinkCode: Link;
  updateLock: Lock;
  updateMe?: Maybe<Person>;
  updateMedicalCondition: MedicalCondition;
  updateMyEmergencyContacts: Staff;
  /** mutations below use the jwt user context */
  updateMyProfile: Person;
  updateNoteType: Note;
  updateOfferSchedule: ApplicationScheduleOffer;
  updateOnboardingStatus: Person;
  updatePassThroughDishonor?: Maybe<CenterBillingSetting>;
  updatePassThroughDishonorForAll?: Maybe<Array<Maybe<CenterBillingSetting>>>;
  updatePassThroughSurcharge?: Maybe<CenterBillingSetting>;
  updatePassThroughSurchargeForAll?: Maybe<Array<Maybe<CenterBillingSetting>>>;
  updatePayMethod: PaymentMethod;
  updatePayment?: Maybe<Transaction>;
  updatePaymentMethod: PaymentMethod;
  /** photo */
  updatePhotoNote?: Maybe<Photo>;
  updatePinForMe: Staff;
  updatePinForPerson?: Maybe<Scalars['String']>;
  updatePinForStaff: Staff;
  updatePosition: StaffPosition;
  updateProgram: ProgramResponseDto;
  updateProvider: Provider;
  updateProviderAddress: ProviderAddress;
  updateProviderContact: ProviderContact;
  updateProviderFinancialDetails?: Maybe<Scalars['Boolean']>;
  updateProviderLocationOfRecord?: Maybe<Scalars['Boolean']>;
  updateProviderName: ProviderName;
  /** ccss personnel */
  updateProviderPersonnel?: Maybe<Scalars['Boolean']>;
  updatePtoType?: Maybe<PtoType>;
  updateRegulation?: Maybe<Regulation>;
  updateResource?: Maybe<FileResource>;
  updateRestoreId: Person;
  updateRestrictChildDocuments?: Maybe<Child>;
  updateRestriction: Restriction;
  updateRole: Role;
  updateRoyaltyFee?: Maybe<RoyaltyFee>;
  updateRoyaltyFeeException?: Maybe<RoyaltyFeeException>;
  updateServiceAddress: Scalars['Boolean'];
  updateServiceContact: ServiceContact;
  updateServiceFinancialDetails?: Maybe<Scalars['Boolean']>;
  updateServiceLocationOfRecord?: Maybe<Array<Maybe<ServiceLocationOfRecord>>>;
  updateServiceName: ServiceNameChangeLog;
  updateServiceTemporarilyCeasing?: Maybe<Scalars['Void']>;
  updateSession: Session;
  updateSessionAmendmentBusinessConfig?: Maybe<Scalars['Boolean']>;
  updateSessionEarlyLateFees: Array<Session>;
  /** Session Report Synchronisation */
  updateSessionReportSync?: Maybe<Scalars['Boolean']>;
  updateShift: Shift;
  updateSpace?: Maybe<Space>;
  updateStaffPositionsAtScope: Staff;
  updateStaffProfile: Staff;
  updateSubject: CurriculumSubject;
  updateSubscription: Subscription;
  updateTag?: Maybe<Tag>;
  updateTheme: Theme;
  updateThemeLessonExperience?: Maybe<EducationExperience>;
  updateTimeEntry: TimeEntry;
  updateTimeOffRequest: TimeOffRequest;
  updateTrainingTimeEvent: TrainingTimeEvent;
  updateTransactionType: TransactionType;
  updateUnit: Unit;
  updateUserRoleship: Staff;
  updateWellnessConditionDocuments: Array<WellnessItemDocument>;
  upsertApplicationDeposit: Application;
  /** Business Configuration */
  upsertBusinessConfiguration?: Maybe<BusinessConfiguration>;
  /** Business Feature */
  upsertBusinessFeature?: Maybe<BusinessFeature>;
  /** mutations related to integration */
  upsertCcrmCredentials?: Maybe<Array<Maybe<UpsertCcrmCredentialsResponse>>>;
  upsertGallerySettings: GallerySettings;
  /** Health Professional Mutations */
  upsertHealthProfessional?: Maybe<HealthProfessional>;
  upsertIntegrationMappings?: Maybe<Array<GetIntegrationMappingResponse>>;
  /** Medicare Card Mutations */
  upsertMedicareCard?: Maybe<MedicareCard>;
  /** royaltyConfig */
  upsertRoyaltyConfig?: Maybe<RoyaltyFeeConfig>;
  upsertSubsidyCenterRatings: Array<SubsidyCenterRating>;
  /** TransactionTypeAuthorizationRoles */
  upsertTransactionTypeAuthorizationRoles: TransactionTypeAuthorizationRolesUpsertResponseDto;
  verifyEmailViaMagicLink?: Maybe<Staff>;
  voidEndCareSessions: Array<Session>;
  voidSession: Session;
  waiveAbsenceGapFee?: Maybe<Array<Absence>>;
  waiveLatePaymentFee?: Maybe<ItemizedBill>;
};

export type MutationActivateFeeArgs = {
  id: Scalars['ID'];
};

export type MutationAddCasualBookingExclusionsArgs = {
  input: AddCasualBookingExclusionsInput;
};

export type MutationAddCcssApprovalArgs = {
  input: AddCcssApprovalInput;
};

export type MutationAddCcssProviderNotificationArgs = {
  input: AddCcssProviderNotificationInput;
};

export type MutationAddCenterToAccountArgs = {
  input: AddCenterToAccountInput;
};

export type MutationAddChildCcsEnrolmentArgs = {
  input: AddChildCcsEnrolmentInput;
};

export type MutationAddChildTagArgs = {
  input: AlterChildTag;
};

export type MutationAddChildToAccountArgs = {
  input: AddChildToAccountInput;
};

export type MutationAddChildrenToProgramArgs = {
  input?: InputMaybe<ProgramChildAddInput>;
};

export type MutationAddClassClosuresArgs = {
  input: Array<ClassClosure>;
};

export type MutationAddEducationPermissionToGroupArgs = {
  input: AddEducationPermissionToGroupInput;
};

export type MutationAddExistingChildToExistingAccountArgs = {
  input: AddExistingChildToExistingAccountInput;
};

export type MutationAddNewContactToAccountArgs = {
  input: AddNewContactToAccountInput;
};

export type MutationAddResourceToFolderArgs = {
  input: AddResourceInput;
};

export type MutationAddStaffToCenterArgs = {
  centerId: Scalars['ID'];
  personId: Scalars['ID'];
  positionId: Scalars['ID'];
};

export type MutationAddTagsToPersonAtEntityArgs = {
  entityId: Scalars['ID'];
  personId: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
};

export type MutationAdminClockOutArgs = {
  id: Scalars['ID'];
  trackedTimeOut: Scalars['String'];
};

export type MutationAdminUnlockLockArgs = {
  input: UnlockLockInput;
  lockId: Scalars['Int'];
};

export type MutationAdviseChildNoLongerAtRiskArgs = {
  input: AdviseChildNoLongerAtRiskInput;
};

export type MutationApplyDiscountToTransactionArgs = {
  input: ApplyDiscountToTransactionInput;
};

export type MutationApprovePaymentArgs = {
  paymentId: Scalars['ID'];
};

export type MutationApprovePhotosArgs = {
  input: IEvaluatePhotoInput;
};

export type MutationApproveStaffArgs = {
  input?: InputMaybe<ApproveStaffInput>;
};

export type MutationApproveStaffAvailabilityRequestArgs = {
  personId: Scalars['ID'];
};

export type MutationArchiveAdjustmentArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveAgencyArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveAgencyAccountChildArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveAgencyEnrollmentArgs = {
  input?: InputMaybe<ArchiveAgencyEnrollmentInput>;
};

export type MutationArchiveAllergyForChildArgs = {
  allergyId: Scalars['ID'];
  childId: Scalars['ID'];
};

export type MutationArchiveAllergyForStaffArgs = {
  allergyId: Scalars['ID'];
  staffId: Scalars['ID'];
};

export type MutationArchiveBasePositionArgs = {
  input: ArchiveBasePositionInput;
};

export type MutationArchiveChildArgs = {
  input: ProgramArchiveChildInput;
};

export type MutationArchiveChildOnAccountArgs = {
  accountId: Scalars['ID'];
  childId: Scalars['ID'];
};

export type MutationArchiveChildProgramArgs = {
  input: ProgramChildArchiveInput;
};

export type MutationArchiveClassArgs = {
  input: ArchiveClassInput;
};

export type MutationArchiveCommentArgs = {
  input: ArchiveCommentInput;
};

export type MutationArchiveCustomFieldArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationArchiveGlCodeArgs = {
  input?: InputMaybe<ArchiveGlCodeInput>;
};

export type MutationArchiveHealthTermArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveMedicalConditionArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveNoteArgs = {
  input: ArchiveNoteInput;
};

export type MutationArchiveProgramArgs = {
  input: ProgramArchiveProgramInput;
};

export type MutationArchivePtoTypeArgs = {
  input: ArchivePtoTypesInput;
};

export type MutationArchiveRestrictionArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveTransactionTypeArgs = {
  id: Scalars['ID'];
};

export type MutationBouncedCheckArgs = {
  paymentId: Scalars['ID'];
};

export type MutationBulkCancelArrangementsArgs = {
  arrangementIds: Array<Scalars['ID']>;
  businessId: Scalars['ID'];
};

export type MutationBulkCustodyArgs = {
  input?: InputMaybe<BulkCustodyInput>;
};

export type MutationBulkEditFeeArgs = {
  input: EditFeesInput;
};

export type MutationBulkSendAgreementEmailsArgs = {
  arrangementIds: Array<Scalars['ID']>;
  businessId: Scalars['ID'];
};

export type MutationBulkStatusChangeArgs = {
  input: BulkStatusChangeInput;
};

export type MutationBulkUpsertClassCapacityOverrideArgs = {
  input: Array<UpsertClassCapacityOverrideInput>;
};

export type MutationCancelArrangementArgs = {
  arrangementId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export type MutationCancelCcssCertificateArgs = {
  input: CancelCcssCertificateInput;
};

export type MutationCancelIsCaseClaimArgs = {
  input: IsCaseClaimCancelInput;
};

export type MutationCancelPaymentArgs = {
  paymentId: Scalars['ID'];
};

export type MutationCancelReturnFeeReductionArgs = {
  input: CancelReturnFeeReductionInput;
};

export type MutationCancelTimeOffRequestArgs = {
  input: CancelTimeOffRequestInput;
};

export type MutationChangeChildStatusArgs = {
  input: ChangeChildStatusInput;
};

export type MutationChangeEmailViaMagicLinkArgs = {
  action: Scalars['String'];
  token: Scalars['String'];
};

export type MutationChargeFullFeeEndCareSessionsArgs = {
  input: ChargeFullFeeEndCareSessionsInput;
};

export type MutationCheckInArgs = {
  input?: InputMaybe<CheckInInput>;
};

export type MutationCheckOutArgs = {
  input?: InputMaybe<CheckOutInput>;
};

export type MutationCheckOutPreviousTimeEntryArgs = {
  input?: InputMaybe<CheckOutPreviousTimeEntryInput>;
};

export type MutationCheckOutTestArgs = {
  input?: InputMaybe<CheckOutInput>;
};

export type MutationClearExperiencesForDatesArgs = {
  input: ClearEducationExperiencesInput;
};

export type MutationClearNotificationsArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationClearProgramThemeExperiencesArgs = {
  input: ClearExperiencesForCurriculumScheduleProgramThemeInput;
};

export type MutationClearSessionArgs = {
  id: Scalars['ID'];
};

export type MutationClockInArgs = {
  input?: InputMaybe<ClockInInput>;
};

export type MutationClockInV2Args = {
  input?: InputMaybe<ClockInInputV2>;
};

export type MutationClockOutArgs = {
  id: Scalars['ID'];
};

export type MutationCloseReEnrollmentsArgs = {
  businessId: Scalars['ID'];
  input: ReenrollmentCloseRequestInput;
};

export type MutationCopyScheduleArgs = {
  input: CopyScheduleInput;
};

export type MutationCopyShiftArgs = {
  input: CopyShiftInput;
};

export type MutationCreateApiKeyArgs = {
  input?: InputMaybe<CreateApiKeyInput>;
};

export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

export type MutationCreateAccountBillingCycleArgs = {
  input: CreateAccountBillingCycleInput;
};

export type MutationCreateAccountingPeriodArgs = {
  input: CreateAccountingPeriodInput;
};

export type MutationCreateAdjustmentArgs = {
  input: CreateAdjustmentInput;
};

export type MutationCreateAgencyArgs = {
  input: CreateAgencyInput;
};

export type MutationCreateAgencyAccountChildArgs = {
  input: CreateAgencyAccountChildInput;
};

export type MutationCreateAgencyPaymentArgs = {
  input: CreateAgencyPaymentInput;
};

export type MutationCreateAllergyForChildArgs = {
  input: CreateChildAllergyInput;
};

export type MutationCreateAllergyForStaffArgs = {
  input: CreateStaffAllergyInput;
};

export type MutationCreateAlternateArrangementArgs = {
  input: CreateAltArrangementInput;
};

export type MutationCreateAnnouncementArgs = {
  input: CreateAnnouncementInput;
};

export type MutationCreateApiKeyWebHookArgs = {
  input?: InputMaybe<CreateApiKeyWebHookInput>;
};

export type MutationCreateApplicationNoteArgs = {
  input: CreateApplicationNoteInput;
};

export type MutationCreateAppliedAccountDiscountArgs = {
  input: CreateAppliedAccountDiscountInput;
};

export type MutationCreateAuthorizedPersonPaymentMethodArgs = {
  input: CreateAuthorizedPersonPaymentMethodInput;
};

export type MutationCreateBasePositionArgs = {
  input: CreateBasePositionInput;
};

export type MutationCreateBatchArgs = {
  input: CreateBatchInput;
};

export type MutationCreateBillingCycleTemplateArgs = {
  input: CreateBillingCycleTemplateInput;
};

export type MutationCreateBusRosterArgs = {
  input: CreateBusRosterInput;
};

export type MutationCreateCcmsApprovalArgs = {
  input: CcmsApprovalCreateInput;
};

export type MutationCreateCcssCertificateArgs = {
  input: CreateCcssCertificateInput;
};

export type MutationCreateCcssDeterminationArgs = {
  input: CreateCcssDeterminationInput;
};

export type MutationCreateCcssProviderFinancialStatusArgs = {
  input: CreateProviderFinancialStatusInput;
};

export type MutationCreateCenterArgs = {
  input: CreateCenterInput;
};

export type MutationCreateCenterLocationArgs = {
  input: CreateLocationInput;
};

export type MutationCreateChatActivityArgs = {
  businessId: Scalars['ID'];
  input?: InputMaybe<CreateActivityInput>;
};

export type MutationCreateChildAsChargeOfPersonAtCenterArgs = {
  input: CreateChildAsChargeOfPersonAtCenterInput;
};

export type MutationCreateChildAtCenterArgs = {
  input: CreateChildAtCenterInput;
};

export type MutationCreateChildImmunizationRecordArgs = {
  input: CreateChildImmunizationInput;
};

export type MutationCreateChildMedicalConditionArgs = {
  childId: Scalars['ID'];
  input: MedicalConditionInput;
};

export type MutationCreateChildRestrictionArgs = {
  childId: Scalars['ID'];
  input: RestrictionInput;
};

export type MutationCreateClassArgs = {
  input: CreateClassInput;
};

export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};

export type MutationCreateContactForChildArgs = {
  input: CreateContactForChildInput;
};

export type MutationCreateContractArgs = {
  input: CreateContractInput;
};

export type MutationCreateContractAndAccountBillingCycleArgs = {
  input: CreateContractAndBillingCycleInput;
};

export type MutationCreateCurriculumArgs = {
  input: CreateCurriculumInput;
};

export type MutationCreateCurriculumScheduleArgs = {
  input: CreateCurriculumScheduleInput;
};

export type MutationCreateCustomFieldArgs = {
  input: CreateCustomFieldInput;
};

export type MutationCreateDepositArgs = {
  input: CreateDepositInput;
};

export type MutationCreateDeviceForContactArgs = {
  id: Scalars['ID'];
  input: CreateDeviceMessageInput;
};

export type MutationCreateDiscountArgs = {
  input: CreateDiscountInput;
};

export type MutationCreateEditKindyForAllCenterAgreementArgs = {
  input: CreateEditKindyForAllCenterAgreementInput;
};

export type MutationCreateEditKindyForAllCredentialArgs = {
  input: CreateEditKindyForAllCredentialInput;
};

export type MutationCreateEditKindyForAllIneligibilitiesArgs = {
  input: CreateEditKindyForAllIneligibilitiesInput;
};

export type MutationCreateEditTransitionStatementArgs = {
  input?: InputMaybe<CreateEditTransitionStatementInput>;
};

export type MutationCreateEducationAttachmentArgs = {
  input: CreateEducationAttachmentInput;
};

export type MutationCreateEducationExperienceArgs = {
  input: CreateEducationExperienceInput;
};

export type MutationCreateEducationProgramArgs = {
  input: CreateEducationProgramInput;
};

export type MutationCreateEnrollmentFormArgs = {
  input: CreateEnrollmentFormInput;
};

export type MutationCreateEnrollmentProgramArgs = {
  input: CreateEnrollmentProgramInput;
};

export type MutationCreateEnrollmentProgramGroupArgs = {
  input?: InputMaybe<CreateEnrollmentProgramGroupInput>;
};

export type MutationCreateEntityArgs = {
  input: CreateEntityInput;
};

export type MutationCreateFeatureFlagArgs = {
  input: CreateFeatureFlagInput;
};

export type MutationCreateFeeArgs = {
  input: CreateFeeInput;
};

export type MutationCreateFileStorageInfoArgs = {
  input: CreateFileStorageInfoInput;
};

export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};

export type MutationCreateGlCodesArgs = {
  input: CreateGlCodesInput;
};

export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationCreateHealthTermArgs = {
  input: HealthTermInput;
};

export type MutationCreateIntegrationApiKeyArgs = {
  input?: InputMaybe<CreateIntegrationApiKeyInput>;
};

export type MutationCreateIsEducatorArgs = {
  input: IsEducatorCreateInput;
};

export type MutationCreateKindyChildFundingSchedulesArgs = {
  input: CreateKindyChildFundingSchedulesInput;
};

export type MutationCreateKindyProgramFundingSchedulesArgs = {
  input: CreateKindyProgramFundingSchedulesInput;
};

export type MutationCreateKisiShareArgs = {
  input: CreateKisiShare;
};

export type MutationCreateLearningStandardArgs = {
  input: CreateLearningStandardInput;
};

export type MutationCreateLearningStandardDomainArgs = {
  input: CreateLearningStandardDomainInput;
};

export type MutationCreateLearningStandardOutcomeArgs = {
  input: CreateOutcomeInput;
};

export type MutationCreateLearningStandardSubDomainArgs = {
  input: CreateLearningStandardSubDomainInput;
};

export type MutationCreateLibraryArgs = {
  input: CreateLibraryInput;
};

export type MutationCreateLockArgs = {
  input: CreateLockInput;
};

export type MutationCreateManualItemizedBillArgs = {
  input: CreateManualItemizedBillInput;
};

export type MutationCreateManualPaymentArgs = {
  input: CreateManualPaymentInput;
};

export type MutationCreateMessageAttachmentArgs = {
  businessId: Scalars['ID'];
  input: InsertAttachmentInput;
};

export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};

export type MutationCreateOfferScheduleArgs = {
  input: CreateOfferScheduleInput;
};

export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};

export type MutationCreatePayrixCustomerForAccountArgs = {
  id: Scalars['ID'];
};

export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};

export type MutationCreatePhotoArgs = {
  input: CreatePhotoInput;
};

export type MutationCreatePositionArgs = {
  input: CreatePositionInput;
};

export type MutationCreateProgramArgs = {
  input: ProgramCreateInput;
};

export type MutationCreateProgramLeaveDayArgs = {
  input: CreateProgramLeaveDayInput;
};

export type MutationCreateProviderArgs = {
  input: CreateProviderInput;
};

export type MutationCreateProviderConflictOfInterestsArgs = {
  input: ProviderConflictOfInterestsInput;
};

export type MutationCreatePtoTypeArgs = {
  input: CreatePtoTypesInput;
};

export type MutationCreateRegulationArgs = {
  input: CreateRegulationInput;
};

export type MutationCreateReturnFeeReductionArgs = {
  input: ReturnFeeReductionInput;
};

export type MutationCreateReverseTransactionArgs = {
  reason: Scalars['String'];
  transactionId: Scalars['ID'];
};

export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type MutationCreateRoyaltyFeeArgs = {
  input: ICreateRoyaltyFeeInput;
};

export type MutationCreateRoyaltyFeeExceptionArgs = {
  input: ICreateRoyaltyFeeExceptionInput;
};

export type MutationCreateScheduleArgs = {
  input: CreateScheduleInput;
};

export type MutationCreateServiceTemporarilyCeasingArgs = {
  input: ServiceTemporarilyCeasingInput;
};

export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};

export type MutationCreateSessionAmendmentArgs = {
  input: SessionAmendmentCreateInput;
};

export type MutationCreateShiftArgs = {
  input: CreateShiftInput;
};

export type MutationCreateSpaceArgs = {
  input: CreateSpaceInput;
};

export type MutationCreateStaffArgs = {
  input: CreateStaffInput;
};

export type MutationCreateStaffMedicalConditionArgs = {
  input: MedicalConditionInput;
  staffId: Scalars['ID'];
};

export type MutationCreateStudentChatActivityArgs = {
  businessId: Scalars['ID'];
  input?: InputMaybe<CreateStudentActivityInput>;
};

export type MutationCreateSubjectArgs = {
  input: CreateCurriculumSubjectInput;
};

export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};

export type MutationCreateTagArgs = {
  input: TagInput;
};

export type MutationCreateThemeArgs = {
  input: CreateThemeInput;
};

export type MutationCreateThemeLessonArgs = {
  input: CreateThemeLessonInput;
};

export type MutationCreateThemeLessonExperienceArgs = {
  input: CreateThemeLessonExperienceInput;
};

export type MutationCreateTimeCardLockForBusinessArgs = {
  input: CreateTimeCardLockInput;
};

export type MutationCreateTimeEntryArgs = {
  input: CreateTimeEntryInput;
};

export type MutationCreateTimeEntryV2Args = {
  input: CreateTimeEntryInputV2;
};

export type MutationCreateTimeOffRequestArgs = {
  input: CreateTimeOffRequestInput;
};

export type MutationCreateTimeOffRequestForMeArgs = {
  input: CreateTimeOffRequestInput;
};

export type MutationCreateTrainingTimeEventArgs = {
  input: CreateTrainingTimeEventInput;
};

export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput;
};

export type MutationCreateTransactionTypeArgs = {
  input: CreateTransactionTypeInput;
};

export type MutationCreateTransactionTypesInBulkArgs = {
  input: CreateTransactionTypeInput;
};

export type MutationCreateTransactionsForSessionsArgs = {
  input: CreateTransactionsForSessionsInput;
};

export type MutationCreateUnitArgs = {
  input: CreateUnitInput;
};

export type MutationCustodyArgs = {
  input?: InputMaybe<CustodyInput>;
};

export type MutationDeactivateCenterArgs = {
  id: Scalars['ID'];
};

export type MutationDeactivateFeeArgs = {
  id: Scalars['ID'];
};

export type MutationDeactivateStaffArgs = {
  input: DeactivateStaffInput;
};

export type MutationDeleteApiKeyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAdjustmentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAgencyPaymentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAllergyForChildArgs = {
  allergyId: Scalars['ID'];
  childId: Scalars['ID'];
};

export type MutationDeleteAllergyForStaffArgs = {
  allergyId: Scalars['ID'];
  staffId: Scalars['ID'];
};

export type MutationDeleteAnnouncementArgs = {
  input: DeleteAnnouncementInput;
};

export type MutationDeleteApplicationNoteArgs = {
  input: DeleteApplicationNoteInput;
};

export type MutationDeleteAppliedAccountDiscountArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBatchArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBillingCycleTemplateArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBusRosterArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCasualBookingExclusionArgs = {
  accountId: Scalars['String'];
};

export type MutationDeleteCcmsApprovalArgs = {
  input: CcmsApprovalDeleteInput;
};

export type MutationDeleteCcssApprovalArgs = {
  centerId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationDeleteCenterArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCenterInviteCodeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteChildContactRelationshipArgs = {
  accountIds: Array<Scalars['ID']>;
  childId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type MutationDeleteChildImmunizationRecordArgs = {
  childId: Scalars['ID'];
  immunizationId: Scalars['ID'];
};

export type MutationDeleteContractArgs = {
  contractId: Scalars['ID'];
};

export type MutationDeleteCustomFieldFileArgs = {
  input: DeleteCustomFieldFileInput;
};

export type MutationDeleteDailyNoteArgs = {
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  id: Scalars['ID'];
  staffId: Scalars['ID'];
  timeStamp: Scalars['String'];
};

export type MutationDeleteDeviceForContactArgs = {
  deviceId: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationDeleteDiscountArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEducationExperienceArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEducationProgramArgs = {
  programId: Scalars['ID'];
};

export type MutationDeleteEnrollmentProgramGroupArgs = {
  businessId: Scalars['ID'];
  programGroupId: Scalars['ID'];
};

export type MutationDeleteEntityArgs = {
  input: DeleteEntityInput;
};

export type MutationDeleteFreeKindyAcquittalArgs = {
  input: KindyForAllDeleteSubmissionInput;
};

export type MutationDeleteFreeKindyForecastArgs = {
  input: KindyForAllDeleteSubmissionInput;
};

export type MutationDeleteHealthProfessionalArgs = {
  healthProfessionalId: Scalars['ID'];
};

export type MutationDeleteIntegrationApiKeyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteKindyForAllAcquittalArgs = {
  input: KindyForAllDeleteSubmissionInput;
};

export type MutationDeleteKindyForAllForecastArgs = {
  input: KindyForAllDeleteSubmissionInput;
};

export type MutationDeleteKisiShareArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationDeleteLearningStandardArgs = {
  input: Scalars['String'];
};

export type MutationDeleteLearningStandardDomainArgs = {
  input: DeleteLearningStandardDomainInput;
};

export type MutationDeleteLearningStandardOutcomeArgs = {
  input: Scalars['String'];
};

export type MutationDeleteLearningStandardSubDomainArgs = {
  input: DeleteLearningStandardSubDomainInput;
};

export type MutationDeleteLinkCodeArgs = {
  input: LinkDeleteLinkCodeInput;
};

export type MutationDeleteMedicalConditionArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMedicareCardArgs = {
  medicareCardId: Scalars['ID'];
};

export type MutationDeleteMessageArgs = {
  body?: InputMaybe<DeleteMessageInput>;
  businessId: Scalars['ID'];
};

export type MutationDeletePaymentMethodArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type MutationDeletePendingStaffArgs = {
  personId: Scalars['ID'];
};

export type MutationDeletePersonPaymentMethodArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePhotoArgs = {
  photoId: Scalars['String'];
};

export type MutationDeletePositionArgs = {
  positionId: Scalars['ID'];
};

export type MutationDeleteProgramLeaveDayArgs = {
  input: DeleteProgramLeaveDayInput;
};

export type MutationDeleteRegulationArgs = {
  input: DeleteRegulationInput;
};

export type MutationDeleteRestrictionArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};

export type MutationDeleteRoyaltyFeeArgs = {
  businessId: Scalars['ID'];
  royaltyFeeId: Scalars['ID'];
};

export type MutationDeleteRoyaltyFeeExceptionArgs = {
  businessId: Scalars['ID'];
  exceptionId: Scalars['ID'];
  royaltyFeeId: Scalars['ID'];
};

export type MutationDeleteServiceFeeGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteServiceTemporarilyCeasingArgs = {
  input: DeleteServiceTemporarilyCeasingInput;
};

export type MutationDeleteSessionAmendmentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteSessionTimeEntryArgs = {
  input: DeleteSessionTimeEntryInput;
};

export type MutationDeleteShiftsArgs = {
  input: DeleteShiftsInput;
};

export type MutationDeleteSpaceArgs = {
  input: DeleteSpaceInput;
};

export type MutationDeleteSubjectArgs = {
  input: Scalars['String'];
};

export type MutationDeleteSubsidyCenterRatingsArgs = {
  input: MultiDeleteSubsidyCenterRatingInput;
};

export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTimeEntryArgs = {
  input: DeleteTimeEntryInput;
};

export type MutationDeleteTrainingTimeEventArgs = {
  input: DeleteTrainingTimeEventInput;
};

export type MutationDeleteTransactionTypeAuthorizationRolesArgs = {
  input: TransactionTypeAuthorizationRolesDeleteInput;
};

export type MutationDenyPhotosArgs = {
  input: IEvaluatePhotoInput;
};

export type MutationDenyStaffAvailabilityRequestArgs = {
  personId: Scalars['ID'];
};

export type MutationDestroyBusinessArgs = {
  id: Scalars['ID'];
};

export type MutationDissociateStaffFromCenterArgs = {
  input: DissociateStaffFromCenterInput;
};

export type MutationEditApplicationArgs = {
  input: EditApplicationInput;
};

export type MutationEditApplicationNoteArgs = {
  input: EditApplicationNoteInput;
};

export type MutationEditCcmsApprovalArgs = {
  input: CcmsApprovalEditInput;
};

export type MutationEditCcssApprovalArgs = {
  input: EditCcssApprovalInput;
};

export type MutationEditCcssCertificateArgs = {
  input: EditCcssCertificateInput;
};

export type MutationEditChildCcsEnrolmentArgs = {
  input: EditChildCcsEnrolmentInput;
};

export type MutationEditFeeArgs = {
  input: EditFeeInput;
};

export type MutationEncryptEnrollmentFormHashArgs = {
  businessId: Scalars['ID'];
  enrollmentFormId: Scalars['ID'];
};

export type MutationEndAndCreateContractArgs = {
  input: EndAndCreateContractInput;
};

export type MutationFlagMessageArgs = {
  body?: InputMaybe<FlagMessageInput>;
  businessId: Scalars['ID'];
};

export type MutationForgetChildAtCenterArgs = {
  input: ForgetChildAtCenterInput;
};

export type MutationGenerateLinkCodeArgs = {
  input: LinkGenerateLinkCodeInput;
};

export type MutationGeneratePdfStatementArgs = {
  input: GeneratePdfStatementInput;
};

export type MutationGetApplicationCenterSettingsArgs = {
  input: GetAppllicationCenterSettingsInput;
};

export type MutationHideEndCareSessionsArgs = {
  input: HideEndCareSessionsInput;
};

export type MutationImportEzChildTrackContractsArgs = {
  input?: InputMaybe<ImportEzChildTrackContractsInput>;
};

export type MutationImportEzChildTrackFamiliesArgs = {
  input?: InputMaybe<ImportEzChildTrackFamiliesInput>;
};

export type MutationLinkEnrolmentByIdArgs = {
  accountId: Scalars['ID'];
  centerId: Scalars['ID'];
  enrolmentId: Scalars['ID'];
};

export type MutationManualStatementRunsArgs = {
  input: ManualStatementRunsInput;
};

export type MutationMarkLostArgs = {
  input?: InputMaybe<ApplicationMarkLostInput>;
};

export type MutationMarkMessageAttachmentUploadedArgs = {
  attachmentId: Scalars['ID'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type MutationMarkNotificationsAsReadArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationMarkReadArgs = {
  applicationId: Scalars['String'];
  businessId: Scalars['String'];
};

export type MutationMoveChildToClassArgs = {
  actionByStaffId: Scalars['ID'];
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  classId: Scalars['ID'];
  spaceId?: InputMaybe<Scalars['ID']>;
};

export type MutationMoveChildrenToClassArgs = {
  actionByStaffId: Scalars['ID'];
  centerId: Scalars['ID'];
  childIds: Array<Scalars['ID']>;
  contractId?: InputMaybe<Scalars['ID']>;
  enrolledClassId?: InputMaybe<Scalars['ID']>;
  newClassId: Scalars['ID'];
  newClassName: Scalars['String'];
  spaceId?: InputMaybe<Scalars['ID']>;
};

export type MutationMoveChildrenToNewClassArgs = {
  moveChildrenToNewClassInput?: InputMaybe<MoveChildrenToNewClassInput>;
};

export type MutationMoveClassToSpaceArgs = {
  actionByStaffId: Scalars['ID'];
  centerId: Scalars['ID'];
  childIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  classId: Scalars['ID'];
  spaceId: Scalars['ID'];
};

export type MutationMovePersonToClassArgs = {
  input?: InputMaybe<MovePersonToClassInput>;
};

export type MutationMoveStaffToClassArgs = {
  actionByStaffId: Scalars['ID'];
  centerId: Scalars['ID'];
  classId: Scalars['ID'];
  staffId: Scalars['ID'];
};

export type MutationMultiApproveApplicationArgs = {
  input: ApplicationMultiApproveInput;
};

export type MutationNameToFaceArgs = {
  centerId: Scalars['ID'];
  childIds: Array<Scalars['ID']>;
  className?: InputMaybe<Scalars['String']>;
  currentClassId: Scalars['ID'];
  currentSpaceId?: InputMaybe<Scalars['ID']>;
  spaceName?: InputMaybe<Scalars['String']>;
  staffId: Scalars['ID'];
};

export type MutationOpenClassOnDateArgs = {
  input?: InputMaybe<OpenClassOnDateInput>;
};

export type MutationPostFreeKindyAcquittalArgs = {
  input: KindyForAllAcquittalSubmissionInput;
};

export type MutationPostFreeKindyForecastArgs = {
  input: KindyForAllSubmissionInput;
};

export type MutationPostKindyForAllAcquittalArgs = {
  input: KindyForAllAcquittalSubmissionInput;
};

export type MutationPostKindyForAllForecastArgs = {
  input: KindyForAllSubmissionInput;
};

export type MutationProcessPayrixTxnWebhookEventArgs = {
  input: PayrixTxnWebhookMessageInput;
};

export type MutationPublishScheduleArgs = {
  centerId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationReRollApiKeyArgs = {
  id: Scalars['ID'];
};

export type MutationReactivateCenterArgs = {
  id: Scalars['ID'];
};

export type MutationReactivateStaffArgs = {
  input: ReactivateStaffInput;
};

export type MutationReleaseCustodyArgs = {
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  classId?: InputMaybe<Scalars['ID']>;
  contactId: Scalars['ID'];
  contractId?: InputMaybe<Scalars['ID']>;
  enrolledClassId?: InputMaybe<Scalars['ID']>;
  staffId: Scalars['ID'];
};

export type MutationRemoveCenterLocationArgs = {
  input: RemoveLocationInput;
};

export type MutationRemoveChildOnAccountArgs = {
  accountId: Scalars['ID'];
  childId: Scalars['ID'];
};

export type MutationRemoveChildTagArgs = {
  input: AlterChildTag;
};

export type MutationRemoveClassFromCenterArgs = {
  centerId: Scalars['ID'];
  classId: Scalars['ID'];
};

export type MutationRemoveContactFromAccountArgs = {
  accountId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type MutationRemoveDiscountFromTransactionArgs = {
  input: RemoveDiscountFromTransactionInput;
};

export type MutationRemoveEducationPermissionFromGroupArgs = {
  input: RemoveEducationPermissionFromGroupInput;
};

export type MutationRemoveEmployeeFromTrainingTimeEventArgs = {
  input: RemoveEmployeeFromTrainingTimeEventInput;
};

export type MutationRemoveFolderArgs = {
  input: RemoveFolderInput;
};

export type MutationRemoveGroupArgs = {
  input: Scalars['ID'];
};

export type MutationRemoveResourceFromFolderArgs = {
  input: RemoveResourceInput;
};

export type MutationRemoveTagsFromPersonAtEntityArgs = {
  entityId: Scalars['ID'];
  personId: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
};

export type MutationReorderProgramThemeExperiencesArgs = {
  input: ReorderProgramThemeExperiencesInput;
};

export type MutationReorderThemesArgs = {
  input: ReorderThemesInput;
};

export type MutationReorderUnitsArgs = {
  input: ReorderUnitsInput;
};

export type MutationReportAbsenceArgs = {
  input: ReportAbsenceInput;
};

export type MutationReportAdditionalAbsenceArgs = {
  input: ReportAdditionalAbsenceInput;
};

export type MutationReportAdditionalAbsenceNewArgs = {
  input: AdditionalAbsenceMessageRecord;
};

export type MutationReportBulkAdditionalAbsenceArgs = {
  input: ReportBulkAdditionalAbsenceInput;
};

export type MutationReportBulkAdditionalAbsenceNewArgs = {
  input: BulkAdditionalAbsenceMessageRecords;
};

export type MutationRequestAvailabilityChangeForMeArgs = {
  input: RequestAvailabityChangeForMeInput;
};

export type MutationRequestToJoinCenterViaCodeArgs = {
  input?: InputMaybe<RequestToJoinCenterViaCodeInput>;
};

export type MutationRequestUpdateEnrolmentFormArgs = {
  input: RequestUpdateEnrolmentFormInput;
};

export type MutationResendApplicationOfferArgs = {
  input: SendApplicationOfferInput;
};

export type MutationResetAttendanceCentersSettingArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type MutationResetCenterSettingsArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type MutationResetCenterStateArgs = {
  centerId: Scalars['ID'];
};

export type MutationResetReEnrolCenterSettingsArgs = {
  input: ResetReEnrolCenterSettingsInput;
};

export type MutationResubmitSessionReportSyncArgs = {
  input: SessionReportSyncResubmitInput;
};

export type MutationReturnDepositArgs = {
  input: ReturnDepositInput;
};

export type MutationRevealEndCareSessionsArgs = {
  input: RevealEndCareSessionsInput;
};

export type MutationSaveServiceFeeGroupArgs = {
  input: SaveServiceFeeGroupInput;
};

export type MutationScheduleProgramThemeExperiencesArgs = {
  input?: InputMaybe<ScheduleExperiencesForCurriculumScheduleProgramTheme>;
};

export type MutationSelectCcrmMappingArgs = {
  input: ISelectExistingEntityInput;
};

export type MutationSendAdhocStatementArgs = {
  input: AccountAdhocStatementRunsInput;
};

export type MutationSendApplicationOfferArgs = {
  input: SendApplicationOfferInput;
};

export type MutationSendCwaAgreementEmailArgs = {
  arrangementId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export type MutationSendDailyNoteArgs = {
  centerId: Scalars['ID'];
  childIds: Array<Scalars['ID']>;
  note: Scalars['String'];
  noteData: Scalars['String'];
  noteType: Scalars['String'];
  staffId: Scalars['ID'];
};

export type MutationSendInvitationArgs = {
  input: SendInvitationInput;
};

export type MutationSendMobileAppInvitationEmailToContactArgs = {
  id: Scalars['ID'];
};

export type MutationSendReEnrollmentsArgs = {
  input: ReenrollmentSendRequestInput;
};

export type MutationSetAttendanceBusinessSettingArgs = {
  input?: InputMaybe<SetAttendanceBusinessSettingInput>;
};

export type MutationSetAttendanceCentersSettingArgs = {
  input?: InputMaybe<SetAttendanceCenterSettingInput>;
};

export type MutationSetCenterSessionArgs = {
  centerId: Scalars['ID'];
  sessionId?: InputMaybe<Scalars['String']>;
};

export type MutationSetChildPresenceArgs = {
  actionByStaffId: Scalars['ID'];
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  classId?: InputMaybe<Scalars['ID']>;
  contractId?: InputMaybe<Scalars['ID']>;
  enrolledClassId?: InputMaybe<Scalars['ID']>;
  isPresent: Scalars['Boolean'];
  spaceId?: InputMaybe<Scalars['ID']>;
};

export type MutationSetCommsBusinessSettingsArgs = {
  businessId: Scalars['ID'];
  input?: InputMaybe<SetCommsBusinessSettingsInput>;
};

export type MutationSetCommsCenterSettingsArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  input?: InputMaybe<SetCommsCenterSettingsInput>;
};

export type MutationSetCurrentCpavForCentreServiceArgs = {
  input: SetCurrentCpavForCentreServiceInput;
};

export type MutationSetPaymentMethodIsAutoPayArgs = {
  input: AccountAutopayPaymentMethodInput;
};

export type MutationSetPrimaryPaymentMethodArgs = {
  input: AccountPaymentMethodInput;
};

export type MutationSetReEnrolBusinessSettingsArgs = {
  input: SetReEnrolBusinessSettingsInput;
};

export type MutationSetReEnrolCenterSettingsArgs = {
  input: SetReEnrolCenterSettingsInput;
};

export type MutationSetStaffPresenceArgs = {
  actionByStaffId: Scalars['ID'];
  centerId: Scalars['ID'];
  isPresent: Scalars['Boolean'];
  staffId: Scalars['ID'];
};

export type MutationSignUpNewUserArgs = {
  input: CognitoCreateNewUserInput;
};

export type MutationSignUpStaffInviteArgs = {
  input: CognitoStaffAcceptInviteInput;
};

export type MutationSplitClassArgs = {
  splitClassInput?: InputMaybe<SplitClassInput>;
};

export type MutationStopOperatingServiceArgs = {
  input: StopOperatingServiceInput;
};

export type MutationSubmitIsCaseClaimArgs = {
  input: IsCaseClaimCreateInput;
};

export type MutationSyncBusinessLocksArgs = {
  id: Scalars['ID'];
};

export type MutationSyncCenterLocksArgs = {
  id: Scalars['ID'];
};

export type MutationTakeCustodyArgs = {
  centerId: Scalars['ID'];
  childIds: Array<Scalars['ID']>;
  classId?: InputMaybe<Scalars['ID']>;
  contractId?: InputMaybe<Scalars['ID']>;
  enrolledClassId?: InputMaybe<Scalars['ID']>;
  staffId: Scalars['ID'];
};

export type MutationToggleBusinessFeaturesArgs = {
  input: ToggleEducationFeaturesInput;
};

export type MutationToggleIsCaseClaimWeekStatusArgs = {
  input: IsCaseClaimToggleWeekStatusInput;
};

export type MutationTurnOffUsModeArgs = {
  input: UsModeInput;
};

export type MutationTurnOnUsModeArgs = {
  input: UsModeInput;
};

export type MutationUnarchiveChildOnAccountArgs = {
  accountId: Scalars['ID'];
  childId: Scalars['ID'];
};

export type MutationUnarchiveHealthTermArgs = {
  id: Scalars['ID'];
};

export type MutationUnlinkEnrolmentByIdArgs = {
  accountId: Scalars['ID'];
  centerId: Scalars['ID'];
  enrolmentId: Scalars['ID'];
};

export type MutationUnlockLockArgs = {
  input: UnlockLockInput;
  lockId: Scalars['Int'];
};

export type MutationUnpublishScheduleArgs = {
  centerId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationUpdateApiKeyArgs = {
  input: UpdateApiKeyInput;
};

export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};

export type MutationUpdateAccountAutoPayPaymentMethodArgs = {
  accountId: Scalars['ID'];
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  termsAndConditionsConfirmed?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateAccountBillingCycleArgs = {
  input: UpdateAccountBillingCycleInput;
};

export type MutationUpdateAccountContactRelationshipsArgs = {
  input: UpdateAccountContactRelationshipsInput;
};

export type MutationUpdateAccountDefaultPaymentMethodArgs = {
  accountId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
};

export type MutationUpdateAccountTagsArgs = {
  input: UpdateAccountTagsInput;
};

export type MutationUpdateAccountingPeriodArgs = {
  input: UpdateAccountingPeriodInput;
};

export type MutationUpdateAdjustmentArgs = {
  input: UpdateAdjustmentInput;
};

export type MutationUpdateAgencyArgs = {
  input: UpdateAgencyInput;
};

export type MutationUpdateAgencyAccountChildEnrollmentArgs = {
  input: UpdateAgencyAccountChildEnrollmentInput;
};

export type MutationUpdateAgencyPaymentArgs = {
  input: UpdateAgencyPaymentInput;
};

export type MutationUpdateAllergenListForHealthTermArgs = {
  input: UpdateAllergenListInput;
};

export type MutationUpdateAllergyForChildArgs = {
  input: UpdateChildAllergyInput;
};

export type MutationUpdateAllergyForStaffArgs = {
  input: UpdateStaffAllergyInput;
};

export type MutationUpdateAnnouncementArgs = {
  input: UpdateAnnouncementInput;
};

export type MutationUpdateApiKeyWebHookArgs = {
  input?: InputMaybe<UpdateApiKeyWebHookInput>;
};

export type MutationUpdateApplicationCenterSettingArgs = {
  input: UpdateApplicationCenterSettingInput;
};

export type MutationUpdateApplicationMultipleCentersSettingArgs = {
  input: UpdateApplicationMultipleCentersSettingInput;
};

export type MutationUpdateApplicationOfferAndSchedulesArgs = {
  applicationId: Scalars['ID'];
  applicationOfferId: Scalars['ID'];
  businessId: Scalars['ID'];
  input: UpdateApplicationOfferRequest;
};

export type MutationUpdateApplicationSettingArgs = {
  input: UpdateApplicationSettingInput;
};

export type MutationUpdateAppliedAccountDiscountArgs = {
  input: UpdateAppliedAccountDiscountInput;
};

export type MutationUpdateAvailabilityForStaffArgs = {
  input: UpdateAvailabityChangeForStaffInput;
};

export type MutationUpdateBasePositionArgs = {
  input: UpdateBasePositionInput;
};

export type MutationUpdateBillingBusinessSettingsArgs = {
  input: BillingBusinessSettingsInput;
};

export type MutationUpdateBillingCycleTemplateArgs = {
  input?: InputMaybe<UpdateBillingCycleTemplateInput>;
};

export type MutationUpdateBusRosterArgs = {
  input: UpdateBusRosterInput;
};

export type MutationUpdateBusinessAccountOwnersArgs = {
  input: UpdateBusinessAccountOwnersInput;
};

export type MutationUpdateBusinessAutoPayPaymentMethodArgs = {
  businessId: Scalars['ID'];
  paymentMethodId?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateBusinessAvatarArgs = {
  input: UpdateBusinessAvatarInput;
};

export type MutationUpdateBusinessDefaultPaymentMethodArgs = {
  businessId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
};

export type MutationUpdateBusinessHubSpotCustomerIdArgs = {
  input: UpdateBusinessHubSpotCustomerIdInput;
};

export type MutationUpdateBusinessKisiApiKeyArgs = {
  input: UpdateBusinessKisiApiKeyInput;
};

export type MutationUpdateBusinessProfileInfoArgs = {
  input: UpdateBusinessProfileInfoInput;
};

export type MutationUpdateCasualBookingSettingsArgs = {
  input: Array<CasualBookingSettingsInput>;
};

export type MutationUpdateCcssProviderMessageArgs = {
  input: CcssProviderMessageUpdateViewedStatusInput;
};

export type MutationUpdateCcssServiceMessageArgs = {
  input: CcssServiceMessageUpdateViewedStatusInput;
};

export type MutationUpdateCenterArgs = {
  input: UpdateCenterInput;
};

export type MutationUpdateCenterAvatarArgs = {
  input: UpdateAvatarInput;
};

export type MutationUpdateCenterInviteCodeArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateCenterKisiPlaceIdArgs = {
  input: UpdateCenterKisiPlaceIdInput;
};

export type MutationUpdateCenterLogoArgs = {
  input: UpdateAvatarInput;
};

export type MutationUpdateCenterMerchantIdArgs = {
  input: UpdateCenterMerchantIdInput;
};

export type MutationUpdateCenterPayPeriodArgs = {
  input: UpdateCenterPayPeriodInput;
};

export type MutationUpdateCenterSettingsArgs = {
  input: UpdateCenterSettingsInput;
};

export type MutationUpdateCenterSettingsForAllByBusinessIdArgs = {
  input: UpdateCenterSettingsForAllByBusinessIdInput;
};

export type MutationUpdateCenterStatementMessageArgs = {
  input: UpdateCenterStatementMessageInput;
};

export type MutationUpdateCenterStatementScheduleArgs = {
  input: UpdateCenterStatementScheduleInput;
};

export type MutationUpdateCenterTagsArgs = {
  input: UpdateCenterTagsInput;
};

export type MutationUpdateChildAtCenterArgs = {
  input: UpdateChildAtCenterInput;
};

export type MutationUpdateChildAvatarArgs = {
  input: UpdateChildAvatarInput;
};

export type MutationUpdateChildContactArgs = {
  input: UpdateChildContactInput;
};

export type MutationUpdateChildEmergencyContactsArgs = {
  input: UpdateChildEmergencyContactsInput;
};

export type MutationUpdateChildMealStatusArgs = {
  input: UpdateChildMealStatusInput;
};

export type MutationUpdateChildPersonalInfoArgs = {
  input: UpdateChildPersonalInfoInput;
};

export type MutationUpdateChildProgramArgs = {
  input?: InputMaybe<ProgramChildUpdateInput>;
};

export type MutationUpdateChildTagsArgs = {
  input: UpdateChildTagsInput;
};

export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};

export type MutationUpdateContactAvatarArgs = {
  input: UpdateContactAvatarInput;
};

export type MutationUpdateContactChildRelationshipArgs = {
  input: UpdateContactChildRelationshipInput;
};

export type MutationUpdateContactEmailArgs = {
  input: UpdateContactEmailInput;
};

export type MutationUpdateContactPersonalInfoArgs = {
  input: UpdateContactPersonalInfoInput;
};

export type MutationUpdateContactTagsArgs = {
  input: UpdateContactTagsInput;
};

export type MutationUpdateContractArgs = {
  input: UpdateContractInput;
};

export type MutationUpdateCurriculumArgs = {
  input: UpdateCurriculumInput;
};

export type MutationUpdateCurriculumScheduleArgs = {
  input: UpdateCurriculumScheduleInput;
};

export type MutationUpdateCurriculumScheduleStatusArgs = {
  input: UpdateCurriculumScheduleStatusInput;
};

export type MutationUpdateCurriculumStatusArgs = {
  input: UpdateCurriculumStatusInput;
};

export type MutationUpdateCustomFieldArgs = {
  input: UpdateCustomFieldInput;
};

export type MutationUpdateCustomFieldFileUploadArgs = {
  input: UpdateCustomFieldFileUploadInput;
};

export type MutationUpdateCustomFieldValuesArgs = {
  input: UpdateCustomFieldValuesInput;
};

export type MutationUpdateDailyNoteArgs = {
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  id: Scalars['ID'];
  note: Scalars['String'];
  noteData: Scalars['String'];
  noteType: Scalars['String'];
  staffId: Scalars['ID'];
  timeStamp: Scalars['String'];
};

export type MutationUpdateDiscountArgs = {
  input: UpdateDiscountInput;
};

export type MutationUpdateEducationExperienceArgs = {
  input: UpdateEducationExperienceInput;
};

export type MutationUpdateEducationPermissionsArgs = {
  input: UpdateEducationPermissionsInput;
};

export type MutationUpdateEducationProgramArgs = {
  input: UpdateEducationProgramInput;
};

export type MutationUpdateEmailArgs = {
  input: UpdateEmailInput;
};

export type MutationUpdateEmergencyContactsOfPersonArgs = {
  input: UpdatePersonEmergencyContactsInput;
};

export type MutationUpdateEmploymentHistoryArgs = {
  input: UpdateEmploymentHistoryInput;
};

export type MutationUpdateEnrollmentFormArgs = {
  input: UpdateEnrollmentFormInput;
};

export type MutationUpdateEnrollmentProgramArgs = {
  input: UpdateEnrollmentProgramInput;
};

export type MutationUpdateEnrollmentProgramGroupArgs = {
  input?: InputMaybe<UpdateEnrollmentProgramGroupInput>;
};

export type MutationUpdateEntityArgs = {
  input: UpdateEntityInput;
};

export type MutationUpdateEntityDataTypeArgs = {
  input: UpdateEntityDataTypeInput;
};

export type MutationUpdateEntityInviteCodeArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateFeatureFlagArgs = {
  input: UpdateFeatureFlagInput;
};

export type MutationUpdateFolderArgs = {
  input: UpdateFolderInput;
};

export type MutationUpdateFolderPermissionsArgs = {
  input: UpdateFolderPermissionsInput;
};

export type MutationUpdateGlCodeArgs = {
  input: UpdateGlCodeInput;
};

export type MutationUpdateGlCodeAssignmentArgs = {
  input: UpdateGlCodeAssignmentsInput;
};

export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};

export type MutationUpdateImmunizationDocumentArgs = {
  input: UpdateImmunizationDocumentInput;
};

export type MutationUpdateIntegrationApiKeyArgs = {
  input?: InputMaybe<UpdateIntegrationApiKeyInput>;
};

export type MutationUpdateIsMessageViewedStatusArgs = {
  input: IsMessageUpdateViewedStatusInput;
};

export type MutationUpdateItemizedBillTypeArgs = {
  input: UpdateBillingSettingItemizedBillsInput;
};

export type MutationUpdateLearningStandardArgs = {
  input: UpdateLearningStandardInput;
};

export type MutationUpdateLearningStandardDomainArgs = {
  input: UpdateLearningStandardDomainInput;
};

export type MutationUpdateLearningStandardOutcomeArgs = {
  input: UpdateOutcomeInput;
};

export type MutationUpdateLearningStandardSubDomainArgs = {
  input: UpdateLearningStandardSubDomainInput;
};

export type MutationUpdateLinkCodeArgs = {
  input: UpdateLinkCodeInput;
};

export type MutationUpdateLockArgs = {
  id: Scalars['ID'];
  input: UpdateLockInput;
};

export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};

export type MutationUpdateMedicalConditionArgs = {
  id: Scalars['ID'];
  input: MedicalConditionInput;
};

export type MutationUpdateMyEmergencyContactsArgs = {
  input: UpdateMyEmergencyContactsInput;
};

export type MutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput;
};

export type MutationUpdateNoteTypeArgs = {
  input: UpdateNoteTypeInput;
};

export type MutationUpdateOfferScheduleArgs = {
  input: UpdateOfferScheduleInput;
};

export type MutationUpdateOnboardingStatusArgs = {
  input?: InputMaybe<UpdateOnboardingStatusInput>;
};

export type MutationUpdatePassThroughDishonorArgs = {
  input: UpdatePassThroughDishonorInput;
};

export type MutationUpdatePassThroughDishonorForAllArgs = {
  input: UpdatePassThroughDishonorForAllInput;
};

export type MutationUpdatePassThroughSurchargeArgs = {
  input: UpdatePassThroughSurchargeInput;
};

export type MutationUpdatePassThroughSurchargeForAllArgs = {
  input: UpdatePassThroughSurchargeForAllInput;
};

export type MutationUpdatePayMethodArgs = {
  input: UpdatePayMethodInput;
};

export type MutationUpdatePaymentArgs = {
  input: UpdatePaymentInput;
};

export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};

export type MutationUpdatePhotoNoteArgs = {
  input: UpdatePhotoNoteInput;
};

export type MutationUpdatePinForMeArgs = {
  pin: Scalars['String'];
};

export type MutationUpdatePinForPersonArgs = {
  personId: Scalars['ID'];
  pin?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePinForStaffArgs = {
  input: UpdatePinInput;
};

export type MutationUpdatePositionArgs = {
  input: UpdatePositionInput;
};

export type MutationUpdateProgramArgs = {
  input: ProgramUpdateInput;
};

export type MutationUpdateProviderArgs = {
  input: UpdateProviderInput;
};

export type MutationUpdateProviderAddressArgs = {
  input: UpdateProviderAddressInput;
};

export type MutationUpdateProviderContactArgs = {
  input: UpdateProviderContactInput;
};

export type MutationUpdateProviderFinancialDetailsArgs = {
  input: UpdateProviderFinancialDetailsInput;
};

export type MutationUpdateProviderLocationOfRecordArgs = {
  input: UpdateProviderLocationOfRecordInput;
};

export type MutationUpdateProviderNameArgs = {
  input: UpdateProviderNameInput;
};

export type MutationUpdateProviderPersonnelArgs = {
  input: UpdateProviderPersonnelInput;
};

export type MutationUpdatePtoTypeArgs = {
  input: UpdatePtoTypesInput;
};

export type MutationUpdateRegulationArgs = {
  input: UpdateRegulationInput;
};

export type MutationUpdateResourceArgs = {
  input?: InputMaybe<UpdateResourceInput>;
};

export type MutationUpdateRestoreIdArgs = {
  input: UpdateRestoreIdInput;
};

export type MutationUpdateRestrictChildDocumentsArgs = {
  businessId: Scalars['ID'];
  childId: Scalars['ID'];
  restrictDocuments: Scalars['Boolean'];
};

export type MutationUpdateRestrictionArgs = {
  id: Scalars['ID'];
  input: UpdateRestrictionInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationUpdateRoyaltyFeeArgs = {
  input: IUpdateRoyaltyFeeInput;
};

export type MutationUpdateRoyaltyFeeExceptionArgs = {
  input: IUpdateRoyaltyFeeExceptionInput;
};

export type MutationUpdateServiceAddressArgs = {
  input: UpdateServiceAddressInput;
};

export type MutationUpdateServiceContactArgs = {
  input: UpdateServiceContactInput;
};

export type MutationUpdateServiceFinancialDetailsArgs = {
  input: UpdateServiceFinancialDetailsInput;
};

export type MutationUpdateServiceLocationOfRecordArgs = {
  input: UpdateServiceLocationOfRecordInput;
};

export type MutationUpdateServiceNameArgs = {
  input: UpdateServiceNameInput;
};

export type MutationUpdateServiceTemporarilyCeasingArgs = {
  input: ServiceTemporarilyCeasingInput;
};

export type MutationUpdateSessionArgs = {
  input: UpdateSessionInput;
};

export type MutationUpdateSessionAmendmentBusinessConfigArgs = {
  input: SessionAmendmentUpdateBusinessInput;
};

export type MutationUpdateSessionEarlyLateFeesArgs = {
  input: UpdateSessionsEarlyLateFeesInput;
};

export type MutationUpdateSessionReportSyncArgs = {
  input: SessionReportSyncUpdateInput;
};

export type MutationUpdateShiftArgs = {
  input: UpdateShiftInput;
};

export type MutationUpdateSpaceArgs = {
  input: UpdateSpaceInput;
};

export type MutationUpdateStaffPositionsAtScopeArgs = {
  input: UpdateStaffPositionsAtScopeInput;
};

export type MutationUpdateStaffProfileArgs = {
  input: UpdatePersonAsStaff;
};

export type MutationUpdateSubjectArgs = {
  input: UpdateCurriculumSubjectInput;
};

export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};

export type MutationUpdateThemeArgs = {
  input: UpdateThemeInput;
};

export type MutationUpdateThemeLessonExperienceArgs = {
  input: UpdateThemeLessonExperienceInput;
};

export type MutationUpdateTimeEntryArgs = {
  input: UpdateTimeEntryInput;
};

export type MutationUpdateTimeOffRequestArgs = {
  input: UpdateTimeOffRequestInput;
};

export type MutationUpdateTrainingTimeEventArgs = {
  input: UpdateTrainingTimeEventInput;
};

export type MutationUpdateTransactionTypeArgs = {
  input: UpdateTransactionTypeInput;
};

export type MutationUpdateUnitArgs = {
  input: UpdateUnitInput;
};

export type MutationUpdateUserRoleshipArgs = {
  input: UpdateRoleshipInput;
};

export type MutationUpdateWellnessConditionDocumentsArgs = {
  input: UpdateWellnessConditionDocumentsInput;
};

export type MutationUpsertApplicationDepositArgs = {
  input: UpsertApplicationDepositInput;
};

export type MutationUpsertBusinessConfigurationArgs = {
  input: IUpsertBusinessConfigurationInput;
};

export type MutationUpsertBusinessFeatureArgs = {
  input: IUpsertBusinessFeatureInput;
};

export type MutationUpsertCcrmCredentialsArgs = {
  input?: InputMaybe<UpsertCcrmCredentialsInput>;
};

export type MutationUpsertGallerySettingsArgs = {
  input: GallerySettingsInput;
};

export type MutationUpsertHealthProfessionalArgs = {
  input: UpsertHealthProfessionalInput;
};

export type MutationUpsertIntegrationMappingsArgs = {
  input: UpsertIntegrationMappingsInput;
};

export type MutationUpsertMedicareCardArgs = {
  input: UpsertMedicareCardInput;
};

export type MutationUpsertRoyaltyConfigArgs = {
  input: IUpsertRoyaltyFeeConfigInput;
};

export type MutationUpsertSubsidyCenterRatingsArgs = {
  input: MultiUpsertSubsidyCenterRatingInput;
};

export type MutationUpsertTransactionTypeAuthorizationRolesArgs = {
  input: TransactionTypeAuthorizationRolesInput;
};

export type MutationVerifyEmailViaMagicLinkArgs = {
  token: Scalars['String'];
};

export type MutationVoidEndCareSessionsArgs = {
  input: VoidEndCareSessionsInput;
};

export type MutationVoidSessionArgs = {
  input: VoidSessionInput;
};

export type MutationWaiveAbsenceGapFeeArgs = {
  input: Array<WaiveAbsenceGapFeeInput>;
};

export type MutationWaiveLatePaymentFeeArgs = {
  input?: InputMaybe<WaiveLatePaymentFeeInput>;
};

export enum NonDigitalPaymentType {
  Cash = 'CASH',
  Check = 'CHECK',
  DirectDeposit = 'DIRECT_DEPOSIT',
  Eftpos = 'EFTPOS',
  MoneyOrder = 'MONEY_ORDER',
}

export type Note = {
  __typename?: 'Note';
  areaType?: Maybe<NoteAreas>;
  associatedWith?: Maybe<Array<Maybe<NoteAssociation>>>;
  attachedToEntityId: Scalars['ID'];
  businessId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdByAccount?: Maybe<Staff>;
  id: Scalars['ID'];
  lastCommentedAt?: Maybe<Scalars['String']>;
  lastCommentedBy?: Maybe<Scalars['ID']>;
  lastCommentedByAccount?: Maybe<Staff>;
  noteType?: Maybe<NoteCategories>;
  title?: Maybe<Scalars['String']>;
};

export enum NoteAreas {
  Account = 'Account',
  Staff = 'Staff',
}

export type NoteAssociation = {
  __typename?: 'NoteAssociation';
  display?: Maybe<NoteDisplay>;
  objectId: Scalars['ID'];
  objectType?: Maybe<NoteAssociationObjectType>;
};

export type NoteAssociationInput = {
  objectId: Scalars['ID'];
  objectType?: InputMaybe<NoteAssociationObjectType>;
};

export enum NoteAssociationObjectType {
  Account = 'Account',
  AccountChild = 'AccountChild',
  AccountContact = 'AccountContact',
  Staff = 'Staff',
}

export enum NoteCategories {
  Attendance = 'Attendance',
  Behavior = 'Behavior',
  Billing = 'Billing',
  Coaching = 'Coaching',
  Communication = 'Communication',
  Concern = 'Concern',
  Conflict = 'Conflict',
  Development = 'Development',
  Discipline = 'Discipline',
  Education = 'Education',
  General = 'General',
  PerformanceReview = 'PerformanceReview',
  Recognition = 'Recognition',
}

export type NoteDisplay = {
  __typename?: 'NoteDisplay';
  displayAvatar?: Maybe<Scalars['String']>;
  displayValue?: Maybe<Scalars['String']>;
};

export type NotePagedResult = {
  __typename?: 'NotePagedResult';
  data: Array<Maybe<Note>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export enum NoteType {
  Attendance = 'Attendance',
  Coaching = 'Coaching',
  Communication = 'Communication',
  Conflict = 'Conflict',
  Development = 'Development',
  Disciplinary = 'Disciplinary',
  General = 'General',
  PerformanceReview = 'PerformanceReview',
  Recognition = 'Recognition',
}

export type Notification = {
  __typename?: 'Notification';
  action: NotificationAction;
  category: NotificationCategory;
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  timestamp: Scalars['DateTime'];
  values: Array<NotificationValue>;
};

export enum NotificationAction {
  Create = 'CREATE',
  Delete = 'DELETE',
  Late = 'LATE',
  Publish = 'PUBLISH',
  Request = 'REQUEST',
  Unpublish = 'UNPUBLISH',
  Update = 'UPDATE',
}

export enum NotificationCategory {
  Center = 'CENTER',
  Entity = 'ENTITY',
  Role = 'ROLE',
  Schedule = 'SCHEDULE',
  Staff = 'STAFF',
  StaffLate = 'STAFF_LATE',
  TimeEntry = 'TIME_ENTRY',
  TimeOff = 'TIME_OFF',
}

export type NotificationOfSuitability = {
  actionTaken?: InputMaybe<Scalars['String']>;
  dateOfAwareness?: InputMaybe<Scalars['Date']>;
  personSuitability?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<SuitabilityReasonType>;
  relevantInfo?: InputMaybe<Scalars['String']>;
};

export type NotificationValue = {
  __typename?: 'NotificationValue';
  id: Scalars['ID'];
  label: Scalars['String'];
  type: NotificationValueType;
};

export enum NotificationValueType {
  Center = 'CENTER',
  Entity = 'ENTITY',
  Role = 'ROLE',
  Schedule = 'SCHEDULE',
  Staff = 'STAFF',
  TimeEntry = 'TIME_ENTRY',
  TimeOff = 'TIME_OFF',
}

export type OccupancyTotalReport = {
  __typename?: 'OccupancyTotalReport';
  reportData?: Maybe<Array<Maybe<OccupancyTotalReportData>>>;
  reportMetaData: ReportMetaData;
};

export type OccupancyTotalReportData = {
  __typename?: 'OccupancyTotalReportData';
  capacity?: Maybe<Scalars['Int']>;
  center: Scalars['String'];
  centerId: Scalars['String'];
  class: Scalars['String'];
  classId: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  utilization?: Maybe<Scalars['Float']>;
};

export enum OnboardingStatus {
  Completed = 'Completed',
  Incomplete = 'Incomplete',
  Skipped = 'Skipped',
}

export type OpenClassOnDateInput = {
  closureId: Scalars['ID'];
  date: Scalars['DateTime'];
};

export type OperationHours = {
  __typename?: 'OperationHours';
  dayOfWeek: DayOfWeek;
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export type OperationHoursInput = {
  dayOfWeek: DayOfWeek;
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export type Outcome = {
  __typename?: 'Outcome';
  description: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Int'];
};

export type Owner = {
  __typename?: 'Owner';
  dob?: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  phoneNumber?: Maybe<Scalars['Phone']>;
};

export type PagedCustomFields = {
  __typename?: 'PagedCustomFields';
  data: Array<CustomField>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type PaginateInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type PaginatedCurriculumScheduleResults = {
  __typename?: 'PaginatedCurriculumScheduleResults';
  items: Array<CurriculumSchedule>;
  numberRequested: Scalars['Int'];
  start: Scalars['Int'];
  totalNumber: Scalars['Int'];
};

export type PaginatedPayrixDisbursements = {
  __typename?: 'PaginatedPayrixDisbursements';
  data: Array<Maybe<PayrixDisbursement>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type PaginatedTrainingTimeEventsResult = {
  __typename?: 'PaginatedTrainingTimeEventsResult';
  data: Array<TrainingTimeEvent>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type PaginatedTransactionTypes = {
  __typename?: 'PaginatedTransactionTypes';
  data?: Maybe<Array<Maybe<TransactionType>>>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type PaidTimeOffBreakdown = {
  __typename?: 'PaidTimeOffBreakdown';
  bereavement?: Maybe<Scalars['Float']>;
  disaster?: Maybe<Scalars['Float']>;
  holiday?: Maybe<Scalars['Float']>;
  inclementWeather?: Maybe<Scalars['Float']>;
  juryDuty?: Maybe<Scalars['Float']>;
  paidTimeOff?: Maybe<Scalars['Float']>;
  training?: Maybe<Scalars['Float']>;
};

export type ParentThreadMessagesDto = {
  __typename?: 'ParentThreadMessagesDto';
  parentMessage?: Maybe<ThreadMessageDto>;
  relatedMessages?: Maybe<Array<Maybe<ThreadMessageDto>>>;
  rootPubNubMessageId?: Maybe<Scalars['String']>;
};

export enum PayRateType {
  Hourly = 'Hourly',
  Salary = 'Salary',
}

export enum PayRateType_ {
  Hourly = 'HOURLY',
  Salaried = 'SALARIED',
}

export type Payment = {
  __typename?: 'Payment';
  amount?: Maybe<Scalars['Float']>;
  approvedAt?: Maybe<Scalars['String']>;
  approvedBy?: Maybe<Scalars['ID']>;
  approvedByPerson?: Maybe<Person>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  depositedAmount?: Maybe<Scalars['Float']>;
  flags: Array<PaymentFlag>;
  id: Scalars['ID'];
  paymentMethodUsedLastFour?: Maybe<Scalars['String']>;
  paymentMethodUsedType?: Maybe<PaymentMethodType>;
  processorFailureMessage?: Maybe<Scalars['String']>;
  processorTxnId?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['String']>;
  rejectedBy?: Maybe<Scalars['ID']>;
  rejectedByPerson?: Maybe<Person>;
  serviceFee?: Maybe<Scalars['Float']>;
  status: PaymentStatus;
  submittedAt?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type PaymentDiff = {
  __typename?: 'PaymentDiff';
  diffCount: Scalars['Int'];
  diffState: DiffState;
  systemFields: Array<DiffDto>;
};

export enum PaymentFilterType {
  Automated = 'AUTOMATED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Flagged = 'FLAGGED',
  Manual = 'MANUAL',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Returned = 'RETURNED',
  Unsubmitted = 'UNSUBMITTED',
}

export type PaymentFlag = {
  __typename?: 'PaymentFlag';
  flagType: PaymentFlagType;
  id: Scalars['ID'];
  paymentId: Scalars['ID'];
};

export enum PaymentFlagType {
  MultiplePaymentsOnSingleDay = 'MULTIPLE_PAYMENTS_ON_SINGLE_DAY',
  PercentageBreach = 'PERCENTAGE_BREACH',
  SubsidyReduction = 'SUBSIDY_REDUCTION',
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  accountId?: Maybe<Scalars['ID']>;
  accountName?: Maybe<Scalars['String']>;
  businessId: Scalars['ID'];
  centerId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdByName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Date']>;
  hasEvidence?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isAutoPay: Scalars['Boolean'];
  isCreditCard: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  lastFour: Scalars['String'];
  personId?: Maybe<Scalars['ID']>;
  processorId: Scalars['String'];
  routingNumber?: Maybe<Scalars['Int']>;
  token: Scalars['String'];
  type: PaymentMethodType;
};

export enum PaymentMethodType {
  Amex = 'AMEX',
  CheckingAccount = 'CHECKING_ACCOUNT',
  CorporateCheckingAccount = 'CORPORATE_CHECKING_ACCOUNT',
  CorporateSavingsAccount = 'CORPORATE_SAVINGS_ACCOUNT',
  CreditCard = 'CREDIT_CARD',
  DinersClub = 'DINERS_CLUB',
  Discover = 'DISCOVER',
  Mastercard = 'MASTERCARD',
  SavingsAccount = 'SAVINGS_ACCOUNT',
  Visa = 'VISA',
}

export enum PaymentProcessor {
  FatZebra = 'FAT_ZEBRA',
  Payrix = 'PAYRIX',
}

export type PaymentReportInput = {
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDate: Scalars['String'];
  paymentMethodTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showFlagged: Scalars['Boolean'];
  startDate: Scalars['String'];
  statuses?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
  transactionDate?: InputMaybe<TransactionDate>;
};

export enum PaymentStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Flagged = 'FLAGGED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Returned = 'RETURNED',
  Unsubmitted = 'UNSUBMITTED',
}

export type PaymentSummary = {
  __typename?: 'PaymentSummary';
  earliestEntry?: Maybe<Scalars['String']>;
  latestEntry?: Maybe<Scalars['String']>;
  paymentCount?: Maybe<Scalars['Float']>;
  paymentStatus?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type PaymentsPagedResult = {
  __typename?: 'PaymentsPagedResult';
  data: Array<Transaction>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type PayrixDisbursement = {
  __typename?: 'PayrixDisbursement';
  amount: Scalars['Int'];
  created: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  processed?: Maybe<Scalars['Date']>;
  returnedAmount?: Maybe<Scalars['Int']>;
  status: PayrixDisbursementStatus;
};

export enum PayrixDisbursementStatus {
  Denied = 'Denied',
  Failed = 'Failed',
  Processed = 'Processed',
  Processing = 'Processing',
  Requested = 'Requested',
  Returned = 'Returned',
}

/** Based on the sample response provided from Payrix, "txnStatus" in the "alert" is a string */
export type PayrixTxnWebhookAlertMessageInput = {
  merchantName?: InputMaybe<Scalars['String']>;
  paymentNumber?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  txnAmount?: InputMaybe<Scalars['String']>;
  txnCardHolder?: InputMaybe<Scalars['String']>;
  txnCreated?: InputMaybe<Scalars['String']>;
  txnId?: InputMaybe<Scalars['String']>;
  txnStatus?: InputMaybe<Scalars['String']>;
};

export type PayrixTxnWebhookMessageInput = {
  alert: PayrixTxnWebhookAlertMessageInput;
  data: Array<PayrixWebhookTxnInput>;
};

/**
 * Based on the sample response provided from Payrix, "status" in the "data" is an int
 * I have created a type for this in the payrixWebhook.d.ts, that the int maps too
 */
export type PayrixWebhookTxnInput = {
  fortxn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  merchant?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  total?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
};

export enum PeaReason {
  Foskin = 'FOSKIN',
  Nocare = 'NOCARE',
}

export type PeaWeeksRemaining = {
  __typename?: 'PeaWeeksRemaining';
  childId: Scalars['ID'];
  weeksRemaining: Scalars['Int'];
};

export type PeriodBalances = {
  __typename?: 'PeriodBalances';
  accountId: Scalars['ID'];
  amountDue?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  pendingPayments?: Maybe<Scalars['Float']>;
  unbilledBalance?: Maybe<Scalars['Float']>;
};

export type Permission = {
  __typename?: 'Permission';
  action: Scalars['String'];
  userLevel: Scalars['Int'];
};

export type PermissionForCheckInOut = {
  childId?: InputMaybe<Scalars['String']>;
  hasPermission?: InputMaybe<Scalars['Boolean']>;
};

export type Person = {
  address?: Maybe<Address>;
  avatar?: Maybe<S3SignedUrl>;
  cognitoConfiguredAt?: Maybe<Scalars['DateTime']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastname?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  onboardingStatus?: Maybe<OnboardingStatus>;
  phoneNumber?: Maybe<Scalars['Phone']>;
  phoneNumberAlt?: Maybe<Scalars['Phone']>;
  restoreId?: Maybe<Scalars['String']>;
  userType: UserType;
};

export type PersonChannelSummaryDto = {
  __typename?: 'PersonChannelSummaryDto';
  channelId?: Maybe<Scalars['ID']>;
  channelLastSentMessageTimestamp?: Maybe<Scalars['DateTime']>;
  doesPersonHaveUnreadMessages?: Maybe<Scalars['Boolean']>;
  personLastJoinedChannelTimestamp?: Maybe<Scalars['DateTime']>;
};

export type PersonChannelsUnreadMessagesDto = {
  __typename?: 'PersonChannelsUnreadMessagesDto';
  hasUnreadMessages?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['ID']>;
};

export type PersonPagedResult = {
  __typename?: 'PersonPagedResult';
  items?: Maybe<Array<Maybe<EducationPerson>>>;
  numberRequested: Scalars['Int'];
  start: Scalars['Int'];
  totalNumber?: Maybe<Scalars['Int']>;
};

export enum PersonType {
  Guardian = 'GUARDIAN',
  Internal = 'INTERNAL',
  Staff = 'STAFF',
}

export type PersonelSortInput = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export enum PersonnelBusinessType {
  O = 'O',
  R = 'R',
}

export type PersonnelConflictOfInterest = {
  __typename?: 'PersonnelConflictOfInterest';
  dateOfAwareness: Scalars['Date'];
  qualification: Scalars['String'];
  rtoRegistrationCode: Scalars['String'];
  trainingOrganisationName: Scalars['String'];
};

export type PersonnelOtherEnterprise = {
  __typename?: 'PersonnelOtherEnterprise';
  abn: Scalars['String'];
  businessName: Scalars['String'];
  businessType: PersonnelBusinessType;
  businessTypeText?: Maybe<Scalars['String']>;
  dateOfAwareness?: Maybe<Scalars['Date']>;
  rtoRegistrationCode: Scalars['String'];
  typeOfInvolvement: Scalars['String'];
};

export enum PersonnelPositionType {
  Z7 = 'Z7',
  Z8 = 'Z8',
  Z9 = 'Z9',
  Z10 = 'Z10',
  Z11 = 'Z11',
  Z12 = 'Z12',
  Z13 = 'Z13',
  Z14 = 'Z14',
  Z15 = 'Z15',
  Z16 = 'Z16',
  Z17 = 'Z17',
  Z18 = 'Z18',
  Z19 = 'Z19',
  Z20 = 'Z20',
  Z21 = 'Z21',
  Z22 = 'Z22',
  Z23 = 'Z23',
}

export type PersonnelService = {
  __typename?: 'PersonnelService';
  periodFrom: Scalars['Date'];
  periodTo: Scalars['Date'];
  position: PersonnelPositionType;
  positionText?: Maybe<Scalars['String']>;
  postCode: Scalars['String'];
  serviceName?: Maybe<Scalars['String']>;
  state: AuState;
  streetLine1: Scalars['String'];
  streetLine2?: Maybe<Scalars['String']>;
  suburb: Scalars['String'];
};

export type PersonnelWwcc = {
  __typename?: 'PersonnelWwcc';
  action?: Maybe<Scalars['String']>;
  cardNumber: Scalars['String'];
  expiryDate: Scalars['Date'];
  issuingState: AuState;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  number: Scalars['String'];
  type: Scalars['String'];
};

export type PhoneNumberDiff = {
  __typename?: 'PhoneNumberDiff';
  diffCount: Scalars['Int'];
  diffState: DiffState;
  systemFields: Array<DiffDto>;
};

export type PhoneNumberInput = {
  number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type Photo = {
  __typename?: 'Photo';
  centerId: Scalars['ID'];
  children?: Maybe<Array<Maybe<ChildPhoto>>>;
  classId: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  evaluatedBy?: Maybe<Scalars['String']>;
  evaluatedOn?: Maybe<Scalars['String']>;
  evaluator?: Maybe<Staff>;
  id: Scalars['ID'];
  isApproved?: Maybe<Scalars['Boolean']>;
  isVideo: Scalars['Boolean'];
  note: Scalars['String'];
  photoKey: Scalars['String'];
  signedDownloadUrl?: Maybe<Scalars['String']>;
  signedurl?: Maybe<Scalars['String']>;
  staffId: Scalars['ID'];
  takenAt?: Maybe<Scalars['String']>;
  takenBy?: Maybe<Staff>;
  thumbnailKey?: Maybe<Scalars['String']>;
  thumbnailKeySignedUrl?: Maybe<Scalars['String']>;
};

export type PhotoCountInput = {
  centerId: Scalars['ID'];
  classIds?: InputMaybe<Array<Scalars['ID']>>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type PhotoPagedResult = {
  __typename?: 'PhotoPagedResult';
  data: Array<Maybe<Photo>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type PocCenterState = {
  __typename?: 'PocCenterState';
  currentChildrenIds?: Maybe<Array<Scalars['ID']>>;
  currentClassIds?: Maybe<Array<Scalars['ID']>>;
  currentSpaceIds?: Maybe<Array<Scalars['ID']>>;
  currentStaffIds?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  sessionId?: Maybe<Scalars['String']>;
};

export type PocChildState = {
  __typename?: 'PocChildState';
  classes?: Maybe<Array<Maybe<PocClass>>>;
  currentCenterId?: Maybe<Scalars['ID']>;
  currentClassId?: Maybe<Scalars['ID']>;
  dailyNotes?: Maybe<Array<Maybe<PocDailyNote>>>;
  id: Scalars['ID'];
  isPresent: Scalars['Boolean'];
  status: ChildStatus;
};

export type PocClass = {
  __typename?: 'PocClass';
  contractId?: Maybe<Scalars['ID']>;
  currentClassId?: Maybe<Scalars['ID']>;
  enrolledClassId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  isPresent?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['ID']>;
  status?: Maybe<ChildStatus>;
};

export type PocClassState = {
  __typename?: 'PocClassState';
  className?: Maybe<Scalars['String']>;
  coreClassId?: Maybe<Scalars['ID']>;
  currentCenterId?: Maybe<Scalars['ID']>;
  currentChildrenIds?: Maybe<Array<Scalars['ID']>>;
  currentSpaceId?: Maybe<Scalars['ID']>;
  currentStaffIds?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  nameToFaceTimestamp?: Maybe<Scalars['String']>;
};

export type PocDailyNote = {
  __typename?: 'PocDailyNote';
  accountChild?: Maybe<AccountChild>;
  /** this ID is actually an AccountChildId */
  childId: Scalars['ID'];
  currentCenterId: Scalars['ID'];
  id: Scalars['ID'];
  note: Scalars['String'];
  noteData: Scalars['String'];
  noteType: Scalars['String'];
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['ID']>;
  timeStamp: Scalars['String'];
};

export type PocExpectedChildren = {
  __typename?: 'PocExpectedChildren';
  classId: Scalars['ID'];
  sessions: Array<Session>;
};

export type PocSpaceState = {
  __typename?: 'PocSpaceState';
  currentCenterId?: Maybe<Scalars['ID']>;
  currentChildrenIds?: Maybe<Array<Scalars['ID']>>;
  currentClassIds?: Maybe<Array<Scalars['ID']>>;
  currentStaffIds?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
};

export type PocStaffAtCenter = {
  __typename?: 'PocStaffAtCenter';
  activeStaff: Array<Staff>;
  staffAttendance: Array<Attendance>;
  staffTimeOffRequests?: Maybe<Array<TimeOffRequest>>;
};

export type PocStaffState = {
  __typename?: 'PocStaffState';
  clockedIn: Scalars['Boolean'];
  currentCenterId?: Maybe<Scalars['ID']>;
  currentClassId?: Maybe<Scalars['ID']>;
  currentSpaceId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isPresent: Scalars['Boolean'];
  status: StaffStatus;
  timeEntryType?: Maybe<Scalars['String']>;
};

export enum PocStatus {
  Break = 'BREAK',
  ClockedIn = 'CLOCKED_IN',
  ClockedOut = 'CLOCKED_OUT',
  PlannedAbsence = 'PLANNED_ABSENCE',
}

export type Position = {
  __typename?: 'Position';
  archivedAt?: Maybe<Scalars['Date']>;
  assignedStaffCount: Scalars['Int'];
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PossibleKisiAssociationsInput = {
  businessId: Scalars['ID'];
  kisiEmail?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  personId: Scalars['ID'];
};

export type PrimaryContactData = ContactData & {
  __typename?: 'PrimaryContactData';
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  relationship: Scalars['String'];
};

export type ProcessingPaymentsChart = {
  __typename?: 'ProcessingPaymentsChart';
  statusTransactions: Array<Maybe<ProcessingPaymentsStatusChart>>;
  totalAmountOfPayments: Scalars['Float'];
};

export type ProcessingPaymentsStatusChart = {
  __typename?: 'ProcessingPaymentsStatusChart';
  numberOfTransactions: Scalars['Int'];
  status: Scalars['String'];
  value: Scalars['Float'];
};

export type Program = {
  __typename?: 'Program';
  id: Scalars['ID'];
};

export type ProgramArchiveChildInput = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  childId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type ProgramArchiveProgramInput = {
  businessId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type ProgramChild = {
  __typename?: 'ProgramChild';
  accountName?: Maybe<Scalars['String']>;
  child: Child;
  childId: Scalars['ID'];
  endDate?: Maybe<Scalars['Date']>;
  excludeStartStrongFunding?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  startDate: Scalars['Date'];
  startStrongConsent?: Maybe<Scalars['String']>;
};

export type ProgramChildAddInput = {
  businessId: Scalars['ID'];
  concessionCard?: InputMaybe<ProgramChildConcessionCardInput>;
  delayedExits?: InputMaybe<Array<ProgramChildDelayedExitInput>>;
  familyTaxBenefit?: InputMaybe<ProgramChildFamilyTaxBenefitInput>;
  kindyForAllChildren?: InputMaybe<Array<ProgramKindyForAllChildInput>>;
  multipleBirth?: InputMaybe<ProgramChildMultipleBirthInput>;
  programChildren: Array<InputMaybe<ProgramChildInput>>;
  programId: Scalars['ID'];
  startStrongChildren?: InputMaybe<Array<ProgramStartStrongChildInput>>;
};

export type ProgramChildArchiveInput = {
  programChildId: Scalars['ID'];
};

export type ProgramChildConcessionCardInput = {
  accountChildren: Array<Scalars['ID']>;
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  cardNumber: Scalars['String'];
  contactId: Scalars['ID'];
  document?: InputMaybe<Document>;
  effectiveDate?: InputMaybe<Scalars['String']>;
  expiryDate: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  type: ConcessionCardType;
};

export type ProgramChildDelayedExitInput = {
  accountChildId: Scalars['ID'];
  businessId: Scalars['ID'];
  childId: Scalars['ID'];
  document?: InputMaybe<Document>;
  id?: InputMaybe<Scalars['ID']>;
  notes: Scalars['String'];
};

export type ProgramChildDocumentInput = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  childId: Scalars['ID'];
  document?: InputMaybe<Document>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ProgramChildFamilyTaxBenefitInput = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  document?: InputMaybe<Document>;
  id?: InputMaybe<Scalars['ID']>;
  part: FamilyTaxBenefitPart;
};

export type ProgramChildInput = {
  associatedAccountId?: InputMaybe<Scalars['ID']>;
  childId: Scalars['ID'];
  childReceivesAccs?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Date']>;
  excludeStartStrongFunding?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  lastFundPaidDate?: InputMaybe<Scalars['Date']>;
  primaryAccountId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['Date'];
  startStrongConsent?: InputMaybe<Scalars['String']>;
};

export type ProgramChildMultipleBirthInput = {
  accountId: Scalars['ID'];
  childBirthData: Array<ChildBirth>;
};

export type ProgramChildUpdateInput = {
  businessId: Scalars['ID'];
  childReceivesAccs?: InputMaybe<Scalars['Boolean']>;
  concessionCard?: InputMaybe<ProgramChildConcessionCardInput>;
  delayedExit?: InputMaybe<ProgramChildDelayedExitInput>;
  familyTaxBenefit?: InputMaybe<ProgramChildFamilyTaxBenefitInput>;
  kindyForAllChildren?: InputMaybe<Array<ProgramKindyForAllChildInput>>;
  multipleBirth?: InputMaybe<ProgramChildMultipleBirthInput>;
  programChild?: InputMaybe<ProgramChildInput>;
  programId: Scalars['ID'];
  startStrongChildren?: InputMaybe<Array<ProgramStartStrongChildInput>>;
};

export type ProgramChildrenPagedResult = {
  __typename?: 'ProgramChildrenPagedResult';
  data: Array<ProgramChild>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type ProgramClass = {
  __typename?: 'ProgramClass';
  class?: Maybe<Class>;
  classId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type ProgramCreateInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  classIds?: InputMaybe<Array<Scalars['ID']>>;
  educators: Array<InputMaybe<ProgramTeacherInput>>;
  paymentRule?: InputMaybe<QkfsPaymentRule>;
  programEndDate?: InputMaybe<Scalars['String']>;
  programName: Scalars['String'];
  programStartDate: Scalars['String'];
  schedules?: InputMaybe<Array<InputMaybe<ProgramScheduleInput>>>;
  subsidyId: Scalars['ID'];
};

export type ProgramFundingScheduleInput = {
  basePercentage?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  subsidyTypes?: InputMaybe<Array<Scalars['String']>>;
  weeks: Scalars['Int'];
};

export type ProgramKindyForAllChildInput = {
  cancellationDate?: InputMaybe<Scalars['Date']>;
  cancellationReason?: InputMaybe<CancellationReason>;
  childId: Scalars['ID'];
  disability?: InputMaybe<Scalars['Boolean']>;
  discretionaryStartDate?: InputMaybe<Scalars['Date']>;
  formalKinshipStartDate?: InputMaybe<Scalars['Date']>;
  formalProtectionCareStartDate?: InputMaybe<Scalars['Date']>;
  multiBirthStartDate?: InputMaybe<Scalars['Date']>;
  nonEnglishSpeakingBackground?: InputMaybe<Scalars['Boolean']>;
  visaType?: InputMaybe<VisaType>;
};

export type ProgramLeaveDay = {
  __typename?: 'ProgramLeaveDay';
  endDate: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  leaveType: LeaveType;
  programId: Scalars['ID'];
  startDate: Scalars['String'];
};

export type ProgramLeaveDayInput = {
  endDate?: InputMaybe<Scalars['String']>;
  leaveType?: InputMaybe<LeaveType>;
  programId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type ProgramResponseDto = {
  __typename?: 'ProgramResponseDto';
  centerId: Scalars['ID'];
  childIds?: Maybe<Array<ProgramChild>>;
  classIds?: Maybe<Array<ProgramClass>>;
  educators?: Maybe<Array<ProgramTeacher>>;
  paymentRule?: Maybe<QkfsPaymentRule>;
  programEndDate?: Maybe<Scalars['String']>;
  programId: Scalars['ID'];
  programLeaveDays: Array<Maybe<ProgramLeaveDay>>;
  programName: Scalars['String'];
  programStartDate: Scalars['String'];
  schedules?: Maybe<Array<Maybe<ProgramSchedule>>>;
  subsidyId: Scalars['ID'];
  subsidyName: Scalars['String'];
};

export type ProgramSchedule = {
  __typename?: 'ProgramSchedule';
  day: DayOfWeek;
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type ProgramScheduleInput = {
  day: DayOfWeek;
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type ProgramStartStrongChildInput = {
  childId: Scalars['ID'];
  declarationForm?: InputMaybe<ProgramChildDocumentInput>;
  hasSchoolExemptionCertificate?: InputMaybe<Scalars['Boolean']>;
  nonEnglishSpeakingBackground?: InputMaybe<Scalars['Boolean']>;
  schoolExemption?: InputMaybe<ProgramChildDocumentInput>;
};

export type ProgramSubsidyScheme = {
  __typename?: 'ProgramSubsidyScheme';
  name: Scalars['String'];
  subsidySchemeId: Scalars['ID'];
};

export type ProgramTeacher = {
  __typename?: 'ProgramTeacher';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  qualificationStatus?: Maybe<Scalars['String']>;
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['ID']>;
};

export type ProgramTeacherInput = {
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  qualificationStatus?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['ID']>;
};

export type ProgramUpdateInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  classIds?: InputMaybe<Array<Scalars['ID']>>;
  educators: Array<InputMaybe<ProgramTeacherInput>>;
  fundingSchedules?: InputMaybe<Array<ProgramFundingScheduleInput>>;
  paymentRule?: InputMaybe<QkfsPaymentRule>;
  programEndDate?: InputMaybe<Scalars['String']>;
  programId: Scalars['ID'];
  programName: Scalars['String'];
  programStartDate: Scalars['String'];
  schedules?: InputMaybe<Array<InputMaybe<ProgramScheduleInput>>>;
  subsidyId: Scalars['ID'];
};

export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String'];
  id: Scalars['ID'];
};

export type Provider = {
  __typename?: 'Provider';
  businessId: Scalars['ID'];
  commencementDate?: Maybe<Scalars['DateTime']>;
  communicationsPersonId: Scalars['ID'];
  deviceExpiry: Scalars['String'];
  deviceName: Scalars['String'];
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organisationId: Scalars['String'];
  otac?: Maybe<Scalars['String']>;
  providerId: Scalars['String'];
};

export type ProviderAddress = {
  __typename?: 'ProviderAddress';
  endDate?: Maybe<Scalars['DateTime']>;
  postcode: Scalars['String'];
  startDate: Scalars['DateTime'];
  state: Scalars['String'];
  streetLine1: Scalars['String'];
  streetLine2?: Maybe<Scalars['String']>;
  suburb: Scalars['String'];
  type: ProviderAddressType;
};

export enum ProviderAddressType {
  Zphysical = 'ZPHYSICAL',
  Zpostal = 'ZPOSTAL',
}

export type ProviderAdninistrationAddressInput = {
  postcode: Scalars['String'];
  state: Scalars['String'];
  streetLine1: Scalars['String'];
  streetLine2?: InputMaybe<Scalars['String']>;
  suburb: Scalars['String'];
  type: ProviderAddressType;
};

export type ProviderConflictOfInterestsInput = {
  abn: Scalars['String'];
  awarenessDate: Scalars['DateTime'];
  businessId: Scalars['ID'];
  businessName: Scalars['String'];
  date: Scalars['DateTime'];
  details: Scalars['String'];
  providerId: Scalars['ID'];
  rto: Scalars['String'];
};

export type ProviderContact = {
  __typename?: 'ProviderContact';
  dateOfEvent: Scalars['String'];
  email: Scalars['String'];
  mobileNumber: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ProviderFinancialDetails = {
  __typename?: 'ProviderFinancialDetails';
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  applyToAllServices: Scalars['Boolean'];
  bsb: Scalars['String'];
  dateOfEvent: Scalars['DateTime'];
};

export enum ProviderFinancialStatusType {
  Admn = 'ADMN',
  Bank = 'BANK',
  Liqu = 'LIQU',
  Rece = 'RECE',
}

export type ProviderLocationOfRecord = {
  __typename?: 'ProviderLocationOfRecord';
  addresses?: Maybe<Array<Maybe<ProviderLocationOfRecordsAddress>>>;
  dateOfEvent: Scalars['DateTime'];
};

export type ProviderLocationOfRecordsAddress = {
  __typename?: 'ProviderLocationOfRecordsAddress';
  postcode: Scalars['String'];
  state: Scalars['String'];
  streetLine1: Scalars['String'];
  streetLine2?: Maybe<Scalars['String']>;
  suburb: Scalars['String'];
};

export type ProviderName = {
  __typename?: 'ProviderName';
  dateOfEvent: Scalars['DateTime'];
  name: Scalars['String'];
  type: ProviderNameType;
};

export enum ProviderNameType {
  Bus = 'BUS',
  Lgl = 'LGL',
}

export type PtoType = {
  __typename?: 'PtoType';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  center?: Maybe<Center>;
  centerId?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  overtimeEligible?: Maybe<Scalars['Boolean']>;
  trainingTimeEventType?: Maybe<TrainingTimeEventType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['ID']>;
};

export type PtoTypesPagedResult = {
  __typename?: 'PtoTypesPagedResult';
  data: Array<Maybe<PtoType>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type QldCensusReportData = {
  __typename?: 'QLDCensusReportData';
  Disability?: Maybe<Scalars['Int']>;
  HealthCareCard?: Maybe<Scalars['Int']>;
  HoursAttendedPreSchool?: Maybe<Scalars['Float']>;
  HoursEnrolledPreSchool?: Maybe<Scalars['Float']>;
  NESB?: Maybe<Scalars['Int']>;
  PreschoolProgramRepeatIndicator?: Maybe<Scalars['Int']>;
  childClassName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  indigenousStatus?: Maybe<Scalars['Int']>;
  postCode?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
};

export enum QkfsPaymentRule {
  AnySeifa = 'AnySeifa',
  QkfsPlusAndSeifa = 'QkfsPlusAndSeifa',
  QkfsPlusOnly = 'QkfsPlusOnly',
}

export type QuarterHourCheckInInput = {
  centerId: Scalars['ID'];
  date: Scalars['Date'];
};

export type Query = {
  __typename?: 'Query';
  calculateQualifyingRate?: Maybe<FeeRate>;
  checkCurrentCcrmCredentials: Scalars['Boolean'];
  checkInviteCodeEmailCombo?: Maybe<CheckedInviteCodeEmail>;
  checkIsEmailTaken?: Maybe<Scalars['Boolean']>;
  checkMyPermissions?: Maybe<Scalars['Boolean']>;
  checkOfferAvailability: Array<CheckAvailabilityDto>;
  exportEmbedReport?: Maybe<EmbedReportExport>;
  fetchBillingBusinessSettings?: Maybe<BillingBusinessSettings>;
  getAPIKey?: Maybe<ApiKey>;
  getAPIKeys: Array<ApiKey>;
  getAPIKeysForEntity: Array<ApiKey>;
  getAccountBillingCycle?: Maybe<BillingCycle>;
  /** account queries */
  getAccountById?: Maybe<Account>;
  getAccountChannel?: Maybe<Channel>;
  /** Comms Queries */
  getAccountChannels?: Maybe<ChannelDtoPagedResult>;
  getAccountChildrenAbsencesForTimeFrame: Array<Absence>;
  getAccountContactInformationReport: Scalars['String'];
  getAccountContactPermissions: Array<AccountContactPermissionLabelValue>;
  getAccountDeposits: Array<AccountDeposit>;
  getAccountDepositsBalance: AccountDepositsBalancePagedResult;
  getAccountDepositsSummary: Array<AccountDepositSummary>;
  /** Documents */
  getAccountDocuments: DocumentPagedResult;
  getAccountGapAndBalances: AccountGapAndBalancesPagedResult;
  /** Payment Methods */
  getAccountPaymentMethods: Array<Maybe<PaymentMethod>>;
  getAccountTransactionsInTimeframe: TransactionsForAccountInTimeframeResponseDto;
  getAccountingPeriods: AccountingPeriodsPagedResult;
  getAccountsBillingOverview: AccountsBillingOverviewPagedResult;
  getAccountsForCenter: Array<Account>;
  getAccountsForChild: Array<Account>;
  getAccsMetricsForCenters: Array<AccsServiceMetric>;
  getAccsReport: Scalars['String'];
  getAcssCertDertsForCenters: AcssCertDertPagedResult;
  getActiveClassesByCenterAndDate?: Maybe<Array<Maybe<Class>>>;
  /** Adjustment */
  getAdjustment: Lock;
  getAdjustmentsForBusiness?: Maybe<Array<Maybe<Adjustment>>>;
  getAdpWeeklyPayrollReport: Scalars['String'];
  getAgenciesForBusiness?: Maybe<Array<Maybe<Agency>>>;
  /** Agency */
  getAgency?: Maybe<Agency>;
  getAgencyAccountChild?: Maybe<AgencyAccountChild>;
  getAgencyAccountChildrenForAccount?: Maybe<Array<Maybe<AgencyAccountChild>>>;
  getAgencyPayment?: Maybe<AgencyPayment>;
  getAllEntities?: Maybe<Array<Maybe<Entity>>>;
  getAllPositionsByBusinessId: Array<Position>;
  getAllRegulations?: Maybe<Array<Maybe<Regulation>>>;
  /** medical queries */
  getAllergens: Array<Allergen>;
  getAllergyHistory: Array<AllergyRevision>;
  getAllowedEntities?: Maybe<Array<Maybe<Entity>>>;
  getAllowedTransactionTypes: Array<TransactionType>;
  getAlternateArrangements: Array<AlternateArrangement>;
  getAnnouncement?: Maybe<Announcement>;
  getAnnouncementsForCenter?: Maybe<Array<Maybe<Announcement>>>;
  /** APiKey WebHooks */
  getApiKeyWebHook: Array<ApiKeyWebHook>;
  getApplicationCenterSettings: GetAppllicationCenterSettingsResponse;
  getApplicationOffer: ApplicationOffer;
  getApplicationScheduleEnquiry: ApplicationScheduleEnquiry;
  getApplicationScheduleOffer: ApplicationScheduleOffer;
  getApplicationSetting: ApplicationSetting;
  /** Applications */
  getApplicationsByStage: ApplicationPagedResult;
  getAppliedAccountDiscount?: Maybe<AppliedAccountDiscount>;
  getAppliedAccountDiscountsForAccount?: Maybe<Array<Maybe<AppliedAccountDiscount>>>;
  getArrangementEmailPreview: Scalars['String'];
  getArrangementHistory?: Maybe<Array<Maybe<ComplyingWrittenArrangement>>>;
  getArrangementMetrics: ComplyingWrittenArrangementMetrics;
  getArrangementTypeForChildrenInTimeFrame: Array<ArrangementTypeDto>;
  getAssignableRolesForUser?: Maybe<Array<Role>>;
  getAttendance: Array<Attendance>;
  /** Attendance Settings */
  getAttendanceBusinessSetting: AttendanceBusinessSettings;
  getAttendanceCentersSetting: Array<Maybe<AttendanceCenterSettings>>;
  getAttendanceOpenSpots: AttendanceOpenSpots;
  getAttendanceReportCsv: Scalars['String'];
  getAttendanceReportExcel: Scalars['String'];
  getAttendanceTotalsPerWeekReport: Scalars['String'];
  getAttendanceWithArchivedEntries: Array<Attendance>;
  getAuthBroadcastChannelIds?: Maybe<AuthIds>;
  getAuthBulletinBoardChannelIds?: Maybe<BulletinBoardAuthIds>;
  /** AuthenticationIdentity */
  getAuthenticationIdentity?: Maybe<Scalars['String']>;
  getAuthenticationIdentityResponse: GetAuthenticationIdentityResponseMessage;
  getAvailableChildrenForBusRoster?: Maybe<Array<Maybe<BusRosterAvailableAccountChild>>>;
  getBalanceByDatePdfReport: Scalars['String'];
  getBalanceByDateReport: Scalars['String'];
  getBasicStaffForCenter?: Maybe<BasicStaffSearchResults>;
  /** Batch */
  getBatch?: Maybe<Batch>;
  getBatchPaymentReport: Scalars['String'];
  getBatchesForBusiness?: Maybe<Array<Maybe<Batch>>>;
  getBillingCycleScheduleForTemplate?: Maybe<Array<Maybe<BillingCycleSchedulePeriod>>>;
  getBillingCycleSchedulePreviewFor?: Maybe<Array<Maybe<BillingCycleSchedulePeriod>>>;
  getBillingCycleTemplate: BillingCycleTemplate;
  getBillingCycleTemplates: Array<BillingCycleTemplate>;
  /** billing queries */
  getBillingCycleTemplatesForAccount: Array<Maybe<BillingCycleTemplate>>;
  getBillingEditsPdfReport: Scalars['String'];
  getBillingSummaryPdfReport: Scalars['String'];
  getBulletinBoardChannel?: Maybe<Channel>;
  getBulletinBoardChannels?: Maybe<ChannelDtoPagedResult>;
  getBulletinBoardChatAuth?: Maybe<BulletinBoardChannelAndAuth>;
  /** Bus Roster */
  getBusRosterById: BusRoster;
  getBusRosterPdf?: Maybe<Array<Maybe<Scalars['URL']>>>;
  /** Business Configuration */
  getBusinessConfigurationByBusinessId?: Maybe<BusinessConfiguration>;
  getBusinessEducationFeatures: Array<EducationFeature>;
  getBusinessEntityCounts: BusinessEntityCounts;
  /** Business Feature */
  getBusinessFeatureByBusinessId?: Maybe<Array<Maybe<BusinessFeature>>>;
  getCcmsApproval?: Maybe<CcmsApproval>;
  getCcrmCredentials: GetCcrmCredentialsResponse;
  getCcrmExistingChildMapping: GetCcrmExistingChildMappingResponse;
  getCcrmExistingContactMapping: GetCcrmExistingContactMappingResponse;
  getCcsAbsencesCategoriesForCenter: CcsAbsencesCategories;
  /** CCSS Absences queries */
  getCcsAbsencesForCenter: CcsAbsencesPagedResult;
  getCcsCategoriesForCenter: AccsDisplayCategoriesCount;
  getCcsCertificateCategoriesForCenter: AccsDisplayCategoriesCount;
  getCcsDeterminationCategoriesForCenter: AccsDisplayCategoriesCount;
  getCcsEnrolmentCategoryCountForCenters: CcsEnrolmentCategoryCount;
  getCcsEnrolmentsForCenters: CcsEnrolmentPagedResult;
  /** CCSS enrolment queries */
  getCcsEnrolmentsForChildren: Array<Maybe<CcsEnrolment>>;
  getCcsEnrolmentsReport: Scalars['String'];
  getCcsEntitlementChangeHistory: CcsEntitlementChangeHistoryPagedResult;
  /** CCSS Entitlement queries */
  getCcsEntitlements: CcsEntitlementPagedResult;
  getCcsEntitlementsForChildren: Array<Maybe<CcsEntitlement>>;
  getCcsEntitlementsReport: Scalars['String'];
  getCcsOutstandingReport: Scalars['String'];
  getCcsSubsidyPaymentReport: Scalars['String'];
  getCcsSubsidyRemittanceReport: Scalars['String'];
  getCcssApprovalsForCenter: Array<CcssApproval>;
  /** ACCS Queries */
  getCcssCertificatesForCenters: CcssCertificatePagedResult;
  /** CCSS Certificate queries */
  getCcssCertificatesForChildren: Array<Maybe<CcssCertificate>>;
  /** CCSS Determination queries */
  getCcssDeterminationsForChildren: Array<CcssDetermination>;
  getCcssEvents: GetCcssEventPagedResult;
  getCcssProviderCorrespondence: CcssCorrespondenceFileContent;
  /** CCSS Messages queries */
  getCcssProviderMessages: CcssProviderMessagesPagedResult;
  getCcssServiceCorrespondence: CcssCorrespondenceFileContent;
  getCcssServiceMessages: CcssServiceMessagesPagedResult;
  getCenter?: Maybe<Center>;
  getCenterBatchingSettingsByBusiness?: Maybe<Array<Maybe<CenterBatchingSetting>>>;
  getCenterBillingSettingsByBusiness?: Maybe<Array<Maybe<CenterBillingSetting>>>;
  getCenterBillingSettingsByCenter?: Maybe<Array<Maybe<CenterBillingSetting>>>;
  getCenterChannel?: Maybe<Channel>;
  getCenterChannels?: Maybe<ChannelDtoPagedResult>;
  getCenterClasses?: Maybe<Array<CenterClasses>>;
  /** schedule queries */
  getCenterScheduleForWeek?: Maybe<Schedule>;
  getCenterStaff?: Maybe<Array<CenterStaff>>;
  getCenterStatementMessage?: Maybe<Scalars['String']>;
  getCenterStatementRuns: GetCenterStatementRunsResults;
  /** statements */
  getCenterStatementSchedule?: Maybe<StatementSchedule>;
  getCenterStatementsForAccount: GetCenterStatementsForAccountResults;
  getCenterStatesUsedAcrossEntity: Array<Scalars['String']>;
  getCenterSummaryStatistics: CenterSummaryStatistic;
  getCentersForBusiness: BusinessCentersPagedResult;
  getCentreIdsHasFormAttached?: Maybe<BusinessEnrolmentFormAssignment>;
  getChannelActivity?: Maybe<SearchUserActivityPagedResult>;
  getChannelUsers?: Maybe<Array<UserActivityPerson>>;
  getChannelsSentMessagesCount: Array<Maybe<ChannelActivityCountDto>>;
  getChatActivity?: Maybe<UserActivityPagedResult>;
  getChatAuth?: Maybe<Array<Maybe<ChannelAndAuth>>>;
  getChatAuthToken?: Maybe<AuthToken>;
  getChildAttendanceSummaryReport: Scalars['String'];
  getChildById?: Maybe<Child>;
  getChildCheckInReport: Scalars['String'];
  getChildContactRelationshipPermissions: Array<ChildContactPermissionLabelValue>;
  getChildIdsWithContractsOnDate: Array<Scalars['ID']>;
  getChildLastAttendanceReport: Scalars['String'];
  getChildTrackingPdfReport: Scalars['String'];
  getChildWellnessPdfReport: Scalars['String'];
  getChildWellnessReport: Scalars['String'];
  getChildrenByBirthdayWeek: Array<ChildBirthdayStatus>;
  getChildrenForAgencyPaymentAtCenter: Array<ChildForAgencyPaymentAtCenter>;
  getClass?: Maybe<Class>;
  getClassAttendanceRosterReport: Scalars['String'];
  getClassClosureByDate?: Maybe<ClassClosureDto>;
  getClassPhotoCounts?: Maybe<Array<Maybe<ClassPhotoCounts>>>;
  getClassesForBusiness?: Maybe<Array<Maybe<Class>>>;
  getClassesForCenter?: Maybe<Array<Maybe<Class>>>;
  getClassesForCenters?: Maybe<Array<Maybe<Class>>>;
  getCommsAllCenterSettings?: Maybe<CommsCenterSettingPaged>;
  getCommsBusinessSettings?: Maybe<CommsBusinessSetting>;
  getCommsCenterSettings?: Maybe<CommsCenterSetting>;
  /** Complying Written Arrangement (CWA) */
  getComplyingWrittenArrangementPage?: Maybe<ComplyingWrittenArrangementPagedResult>;
  getContactById?: Maybe<Contact>;
  getContactTagsInUseAtEntity: Array<Tag>;
  getContactsByPhoneNumber: Array<Contact>;
  getContext: Scalars['JSON'];
  getContractEditsPdfReport: Scalars['String'];
  getContractFeeDetailedPdfReport: Scalars['String'];
  getContractFeePdfReport: Scalars['String'];
  getContractReport: Scalars['String'];
  /** contract queries */
  getContractsForAccount: Array<Contract>;
  getContractsForAccountChild: Array<Contract>;
  getContractsForChild: Array<Contract>;
  /** Cpav queries */
  getCurrentCpavForCentreService?: Maybe<ServiceCpav>;
  getCurricula: BusinessCurriculaPagedResult;
  getCurriculum?: Maybe<Curriculum>;
  getCurriculumSchedule?: Maybe<CurriculumSchedule>;
  getCurriculumScheduleProgramTheme?: Maybe<CurriculumScheduleProgramTheme>;
  getCurriculumSchedulesForBusiness: PaginatedCurriculumScheduleResults;
  getCustomFieldById: CustomField;
  getCustomFieldDocumentValues?: Maybe<Array<CustomFieldDocumentValuesResponse>>;
  /** CustomFields */
  getCustomFieldValues: CustomFieldValuesResponse;
  getCustomFieldsReport: Scalars['String'];
  getDailyExperienceAssignments: Array<EducationDailyExperienceAssignmentGroup>;
  getDailyNotesForCenter: Array<PocDailyNote>;
  getDailyNotesOfTypeForChildren: Array<PocDailyNote>;
  getDateCalculate: GetDateCalculate;
  getDebtCsvReport: Scalars['String'];
  getDebtExcelReport: Scalars['String'];
  getDepositsHeldReport: Scalars['String'];
  getDirectDebitPaymentMethod: DirectDebitPaymentMethod;
  /** Discounts */
  getDiscount?: Maybe<Discount>;
  getDiscountsForBusiness?: Maybe<Array<Maybe<Discount>>>;
  getDiscountsWithFilters?: Maybe<Array<Maybe<Discount>>>;
  getDocumentById: CoreDocument;
  getDocumentLinkById?: Maybe<Scalars['String']>;
  getDoesChildHaveHistoryOnAccount: Scalars['Boolean'];
  /** Files Query */
  getDownloadUrl: GetDownloadUrlResponse;
  /** Education Permissions Queries */
  getEducationPermissions: EducationPermissionsResponse;
  getEducationPerson: EducationPerson;
  getEducationPrograms: Array<EducationProgram>;
  getEmailAddressReport: Scalars['String'];
  /** embed report queries */
  getEmbedReport?: Maybe<EmbedReport>;
  getEmergencyContactPdfReport: Scalars['String'];
  getEmergencyContactReport: Scalars['String'];
  getEmployeeTimeSheetExcelReport: Scalars['String'];
  getEmployeeTimeSheetReport: Scalars['String'];
  getEndCareEnrollmentHistoryByAccountChildId: Array<EndCareEnrollmentHistoryAction>;
  getEndCareEnrollmentHistoryPage?: Maybe<EndCareEnrollmentHistoryPagedResult>;
  getEndCareEnrollmentMetrics?: Maybe<EndCareEnrollmentMetrics>;
  /** End Care */
  getEndCareEnrollmentPage?: Maybe<EndCareEnrollmentPagedResult>;
  getEndCareHistorySessions: Array<Session>;
  getEndCareSessions: Array<Session>;
  getEndedContractsReport: Scalars['String'];
  /** Enrollment */
  getEnrollmentAvailability?: Maybe<ClassEnrollmentAvailability>;
  /** Enrollment Program Groups */
  getEnrollmentProgramGroups: EnrollmentProgramGroupPagedResult;
  /** EnrollmentProgram */
  getEnrollmentPrograms?: Maybe<EnrollmentProgramPagedResult>;
  getEnrolmentFormDiff?: Maybe<EnrollmentDiff>;
  getEntity?: Maybe<Entity>;
  getExcursionPdfReport: Scalars['String'];
  getExistingEntitiesMapping: GetCcrmExistingEntityMappingResponse;
  /** attendance for children queries */
  getExpectedSessions: Array<Session>;
  getExpectedSessionsForAccountChildInTimeframe: Array<Session>;
  /** Integrations */
  getExternalTypes: Array<GetExternalTypesResponse>;
  getFatZebraTransactionsReport: Scalars['String'];
  getFeatureConfigurationsForStaffMember: Array<Maybe<FeatureConfiguration>>;
  /** FeatureFlag */
  getFeatureFlag: FeatureFlag;
  getFeatureFlagsForBusiness: Array<FeatureFlag>;
  /** fee queries */
  getFeesByClassId: Array<Fee>;
  getFlaggedPayments: Array<Transaction>;
  /** Education Folder Queries */
  getFolder: Folder;
  getFolderCount: Scalars['Int'];
  getForNewProgramByAccountId: Array<Maybe<GetForNewProgramByAccountId>>;
  getFreeKindyAcquittalCenterSummaries: GetPagedFreeKindyAcquittalCenterSummariesResult;
  getFreeKindyAcquittalChildSummaries: GetPagedFreeKindyAcquittalChildSummariesResult;
  getFreeKindyCenterSummaries: GetPagedFreeKindyCenterSummariesResult;
  getFreeKindyChildSummaries: GetPagedFreeKindyChildSummariesResult;
  getGLCodeAssignments: GlCodeAssignmentPagedResult;
  getGLCodeCount: Scalars['Int'];
  /** GLCode */
  getGLCodes: GlCodePagedResult;
  getGallerySettings?: Maybe<GallerySettings>;
  getGroup: Group;
  getHealthProfessionalByChildId: Array<HealthProfessional>;
  getHealthProfessionalById: Array<HealthProfessional>;
  getHealthTerms: Array<HealthTerm>;
  getHighestDailyMessagesPerChannelStatistics: Array<Maybe<DailyChannelMessageCount>>;
  /** IntegerationAPiKey */
  getIntegrationApiKeyForBusiness: Array<IntegrationApiKey>;
  getIntegrationMappings: Array<GetIntegrationMappingResponse>;
  getIsCaseClaimCategoryCountsForCenters: IIsCaseClaimPaymentCategoryCount;
  getIsCaseClaimPaymentsForCenters: IsCaseClaimPaymentPagedResult;
  getIsCaseClaimsForCenters: IsCaseClaimPagedResult;
  /** Inclusion Support queries */
  getIsCasesForCenters: IsCasePagedResult;
  getIsEducators: Array<IsEducator>;
  getIsInnovativeCasesForCenters: IsInnovativeCasePagedResult;
  getIsMessagesForCenters: IsMessagePagedResult;
  getIsPaymentReport: Scalars['String'];
  getIsValidArrangement: IsValidArrangement;
  /** Itemized Bill */
  getItemizedBill?: Maybe<ItemizedBill>;
  getItemizedBillPdf?: Maybe<Scalars['URL']>;
  getItemizedBillSummary?: Maybe<ItemizedBillSummary>;
  getItemizedBillSummaryPdf?: Maybe<Scalars['URL']>;
  getItemizedBillsByAccountId?: Maybe<Array<Maybe<BaseItemizedBill>>>;
  getKindyChildFundingSchedules: Array<KindyChildFundingSchedulesResult>;
  getKindyChildFundingSchedulesForAccount: Array<KindyChildFundingSchedulesResult>;
  getKindyForAllAcquittalCenterSummaries: GetPagedKindyForAllAcquittalCenterSummariesResult;
  getKindyForAllAcquittalChildSummaries: GetPagedKindyForAllAcquittalChildSummariesResult;
  getKindyForAllAcquittalErrorsReport: Scalars['String'];
  getKindyForAllAcquittalReport: Scalars['String'];
  /** Kindy For All Queries */
  getKindyForAllAgreements: Array<Maybe<KindyForAllAgreementDto>>;
  getKindyForAllCenterAgreements: GetPagedKindyForAllCenterAgreementsResult;
  getKindyForAllCenterSummaries: GetPagedKindyForAllCenterSummariesResult;
  getKindyForAllChildSummaries: GetPagedKindyForAllChildSummariesResult;
  getKindyForAllCredentials: GetPagedKindyForAllCredentialsResult;
  getKindyForAllForecastErrorsReport: Scalars['String'];
  getKindyForAllForecastReport: Scalars['String'];
  getKindyForAllIneligibility: Array<KindyForAllIneligibilityDto>;
  getKindyForAllQuarters: Array<Maybe<GetKindyForAllQuarter>>;
  getKindyProgramFundingSchedules: Array<Maybe<KindyProgramFundingSchedulesResult>>;
  getKindySubsidySchemes: Array<SubsidySchemeDto>;
  getKisiPairedPlaces?: Maybe<Array<Maybe<KisiPairedCenter>>>;
  getKisiShare?: Maybe<KisiShare>;
  /** Session Amendment */
  getLastTimeEntryByAccountChildId?: Maybe<Array<Maybe<SessionTimeEntry>>>;
  getLastTimeEntryByContractId?: Maybe<SessionTimeEntry>;
  getLastTimeEntryForChildInClass?: Maybe<SessionTimeEntry>;
  /** Education Learning Standard Queries */
  getLearningStandard: LearningStandard;
  getLearningStandardDomain: LearningStandardDomain;
  getLearningStandardSubDomain: LearningStandardSubDomain;
  getLearningStandards: Array<Maybe<LearningStandard>>;
  /** Education Library Queries */
  getLibrary: Library;
  /** Enroll */
  getLinkCodes: Array<Link>;
  getLinkedCenters: LinkedCenterDetailPagedResults;
  /** Kisi */
  getLock: Lock;
  getLockByLockId: GetLock;
  getLockedUntilForBusiness?: Maybe<TimeCardLockedUntilResult>;
  getLockedUntilForCenter?: Maybe<TimeCardLockedUntilResult>;
  getMe?: Maybe<Person>;
  getMedicalConditionHistory: Array<MedicalConditionRevision>;
  getMedicareCardByChildId: Array<MedicareCard>;
  getMedicareCardById: Array<MedicareCard>;
  getMonthlyMessagesSentStatistics?: Maybe<Array<Maybe<MonthlyMessageCount>>>;
  getMyAttendance: Array<Attendance>;
  getMyNotifications: Array<Notification>;
  getMyTimeOffRequests: Array<Maybe<TimeOffRequest>>;
  /** Zendesk */
  getMyZendeskChatToken: Scalars['String'];
  getMyZendeskClassicChatToken: Scalars['String'];
  getNameToFacePdfReport: Scalars['String'];
  getNameToFaceReport: Scalars['String'];
  getNewContractReport: Scalars['String'];
  getOccupancyTotalReport?: Maybe<OccupancyTotalReport>;
  getOccupancyWeeklyTotalReport: Scalars['String'];
  getOpenTimeEntryForPersonAtCenter: TimeEntry;
  getPaginatedAgenciesForBusiness: AgencyPagedResult;
  getPaginatedAgencyAccountChildrenForBusiness: AgencyAccountChildPagedResult;
  getPaginatedAgencyPaymentsForBusiness: AgencyPaymentResult;
  getPaginatedBatchesForBusiness: BatchesPagedResult;
  getPaginatedBusRosters: BusRosterPagedResponse;
  getPaginatedCentersForBusiness: CentersPagedResult;
  getPaginatedCustomFieldsForBusiness: CustomFieldPagedResult;
  getPaginatedPayments: PaymentsPagedResult;
  /** Service Fees */
  getPaginatedServiceFeeGroups: ServiceFeeGroupPagedResponse;
  /** Training time events */
  getPaginatedTrainingTimeEventsForCenter: PaginatedTrainingTimeEventsResult;
  getPaginatedTransactionTypes: PaginatedTransactionTypes;
  getPaginatedTransactionsInTimeframe: TransactionsInTimeframePagedResult;
  getPayment?: Maybe<Payment>;
  getPaymentMethod: Array<PaymentMethod>;
  getPaymentMethodsByBusinessId: Array<PaymentMethod>;
  getPaymentReport: Scalars['String'];
  getPayrixDisbursementReport: GetPayrixDisbursementReport;
  getPayrixDisbursementReportPdf: Scalars['String'];
  getPayrixDisbursements: PaginatedPayrixDisbursements;
  getPayrixDisbursementsForCenters?: Maybe<Array<Maybe<PayrixDisbursement>>>;
  getPayrixDisbursementsMonthlyReport: Scalars['String'];
  getPeaWeeksRemaining: Array<PeaWeeksRemaining>;
  getPerson?: Maybe<Person>;
  getPersonChannelsSummary: Array<Maybe<PersonChannelSummaryDto>>;
  getPersonChannelsUnreadMessages: PersonChannelsUnreadMessagesDto;
  getPersonnelByBusinessId: Array<Maybe<CcssPersonnel>>;
  getPersonnelByProviderId: CcssPersonnelPagedResult;
  getPersonnelByServiceId: CcssPersonnelPagedResult;
  /** Photo Queries */
  getPhotoById?: Maybe<Photo>;
  getPhotoStatistics?: Maybe<CenterPhotoStatistics>;
  getPocCenterState?: Maybe<PocCenterState>;
  getPocChildState?: Maybe<PocChildState>;
  getPocChildrenAtCenter: Array<PocExpectedChildren>;
  getPocChildrenState?: Maybe<Array<Maybe<PocChildState>>>;
  getPocClassState?: Maybe<PocClassState>;
  getPocClassesForCenter?: Maybe<Array<Maybe<Class>>>;
  getPocSpaceState?: Maybe<PocSpaceState>;
  getPocStaffAtCenter?: Maybe<PocStaffAtCenter>;
  getPocStaffState?: Maybe<PocStaffState>;
  getPocStaffStateBulk?: Maybe<Array<Maybe<PocStaffState>>>;
  getPositionsByBusinessId: Array<Position>;
  getPositionsByPositionId: Array<StaffPosition>;
  getPositionsByScopeId: Array<StaffPosition>;
  /** position queries */
  getPositionsByStaffId: StaffPosition;
  getPossibleKisiParentAssociations?: Maybe<KisiParentStatus>;
  getProcessingPaymentInTimeframe: ProcessingPaymentsChart;
  getProgramById: ProgramResponseDto;
  getProgramChildren: ProgramChildrenPagedResult;
  getProgramsByAccountId: Array<Maybe<GetProgramsByAccountId>>;
  /** Programs */
  getProgramsByCenterId: CenterProgramPagedResult;
  getPromoCodeByCode?: Maybe<PromoCode>;
  getProviderAddresses: Array<Maybe<ProviderAddress>>;
  getProviderContact: ProviderContact;
  getProviderDebts: Array<Debt>;
  getProviderFinancialDetails: ProviderFinancialDetails;
  getProviderLocationOfRecord: ProviderLocationOfRecord;
  getProviderNames: Array<Maybe<ProviderName>>;
  /** ccss queries */
  getProvidersByBusinessId: Array<Provider>;
  getPutEducationSignedUrl?: Maybe<S3SignedUrlWithResourceId>;
  getPutGallerySignedUrl?: Maybe<S3SignedUrl>;
  getPutSignedUrl?: Maybe<S3SignedUrl>;
  getQLDCensusReport: Scalars['String'];
  getQkfsReport: Scalars['String'];
  getQkfsTransactionCountAfterDate: Scalars['Int'];
  getQuarterHourCheckInReport: Scalars['String'];
  getReEnrolAccountsByBatchId?: Maybe<ReEnrollmentGetAccountsResponsePagedResult>;
  getReEnrolBusinessSettings: ReEnrolBusinessSettings;
  getReEnrolCorrespondenceMetadataByLinkCode?: Maybe<ReEnrolAccountCorrespondenceMeta>;
  getReEnrolHistoryOfActions?: Maybe<ReEnrollmentGetHistoryofActionsResponsePagedResult>;
  getReEnrolHistoryReport: Scalars['String'];
  getReenrollmentAccountContracts?: Maybe<Array<ReEnrollChild>>;
  /** Reenrolment */
  getReenrollmentAccounts?: Maybe<ReEnrollmentGetAccountsResponsePagedResult>;
  getRegulation?: Maybe<Regulation>;
  /** report queries */
  getReportList: Array<Report>;
  getResource: FileResource;
  getRestrictedResourceTypes: Array<RestrictedResourceType>;
  getRestrictionHistory: Array<RestrictionRevision>;
  /** CCSS Return fee reduction queries */
  getReturnFeeReductions: GetReturnFeeReductionsResults;
  /** Roles */
  getRolesForBusiness: Array<Role>;
  /** Royalty Queries */
  getRoyaltyConfigByBusinessId?: Maybe<RoyaltyFeeConfig>;
  getRoyaltyFeeById?: Maybe<RoyaltyFee>;
  getRoyaltyFeeExceptionById?: Maybe<RoyaltyFeeException>;
  getRoyaltyFeeExceptionsByRoyaltyFeeId?: Maybe<Array<Maybe<RoyaltyFeeException>>>;
  getRoyaltyFeesByBusinessId?: Maybe<Array<Maybe<RoyaltyFee>>>;
  getRoyaltyPdfReport: Scalars['String'];
  getSSOLoginUrl: Scalars['String'];
  getSageGlDetailsReport?: Maybe<SageGlDetailsReport>;
  getSageGlReport?: Maybe<SageGlReport>;
  getScopesForAction: Scopes;
  getServiceContact: ServiceContact;
  /** debt queries */
  getServiceDebts: Array<Debt>;
  getServiceFeeGroupById?: Maybe<ServiceFeeGroup>;
  getServiceFinancialDetails: ServiceFinancialDetails;
  getServiceNameChangeLogs: GetServiceNameChangeLogsResults;
  getServiceTemporarityCeasing?: Maybe<ServiceTemporarilyCeasing>;
  getServicesByBusiness: GetServicesByBusinessResults;
  getSessionAmendmentGetBusinessConfig: SessionAmendmentGetBusinessConfigDto;
  getSessionAmendments?: Maybe<SessionAmendmentSearchPagedDto>;
  getSessionById: Session;
  getSessionCountByTimeFrame: Scalars['Int'];
  getSessionsAndActivityForChildrenByContactId: Array<SessionsAndActivityForChildrenByContactId>;
  getShiftsForPersonForDates: Array<Shift>;
  getSignInSignOutReport: Scalars['String'];
  getSortedAccounts: SortedAccountsPagedResult;
  getSortedChildren: SortedChildrenPagedResult;
  getSpace?: Maybe<Space>;
  getSpacesForCenter?: Maybe<Array<Maybe<Space>>>;
  getStaff: Staff;
  getStaffByBirthdayWeek: Array<Staff>;
  getStaffByEmail?: Maybe<Staff>;
  getStaffByPhoneNumberAndBusinessId: Array<Staff>;
  getStaffForBusiness: BusinessStaffPagedResult;
  getStageAggregation?: Maybe<Array<Maybe<ApplicationStageAggregationItem>>>;
  getStartStrongReport: GetStartStrongReportResult;
  getStatementLink?: Maybe<Scalars['String']>;
  getStudentChannels?: Maybe<ChannelDtoPagedResult>;
  /** Education Curriculum Queries */
  getSubject: CurriculumSubject;
  getSubjects: Array<Maybe<CurriculumSubject>>;
  getSubscription: Subscription;
  getSubsidyCategories: SubsidyCategoriesData;
  /** SubsidyCenterRating */
  getSubsidyCenterRating: SubsidyCenterRating;
  getSubsidyDashboard: SubsidyDashboardData;
  getSubsidySchemeProgramsCountByCenterId: GetSubsidySchemeProgramsCountResponse;
  getSubsidySchemes: Array<SubsidyScheme>;
  getSumOfPaymentsInTimeFrame: Array<PaymentSummary>;
  /** System Fields */
  getSystemFields: Array<Maybe<CustomField>>;
  getTableMetaData?: Maybe<TableMetaData>;
  getTagsUsedAcrossEntity: Array<Tag>;
  getTheme?: Maybe<Theme>;
  getThreadMessages?: Maybe<Array<CenterThreadMessages>>;
  getTimeEntriesForDates: Array<TimeEntry>;
  getTimeOffRequestsByScope: Array<Maybe<TimeOffRequest>>;
  getTotalUnbatchedTransactionPayments: TotalUnBatchedTransactions;
  getTransaction?: Maybe<Transaction>;
  getTransactionDiscounts: Array<Maybe<Transaction>>;
  getTransactionReport: Scalars['String'];
  getTransactionTotal?: Maybe<TransactionTotalReport>;
  getTransactionTotalByTransactionTypeReport: Scalars['String'];
  getTransactionTotalReport: Scalars['String'];
  /** TransactionTypeAuthorizationRoles */
  getTransactionTypeAuthorizationRoles?: Maybe<TransactionTypeAuthorizationRolesSearchPagedDto>;
  getTransactionTypes: Array<TransactionType>;
  getTransactionsInTimeframe: Array<Transaction>;
  getTransitionStatements: Array<Maybe<TransitionStatementDto>>;
  getUnbatchedTransactionPayments: UnBatchedTransactions;
  getUnbilledTransactionsForAccount: Array<Transaction>;
  getUnit?: Maybe<Unit>;
  getUnlinkedEnrolmentsForAccount: Array<CcsEnrolment>;
  getUnreadMessages?: Maybe<Array<StaffUnreadMessagesDto>>;
  getWeeklyAttendanceReport: Scalars['String'];
  getWeeklyPayrollReport: Scalars['String'];
  /** Subsidy queries */
  getWeeklySubsidySummaries: SubsidyWeeklySummaryPagedResult;
  isCustomFieldInUseByForm?: Maybe<Array<Maybe<EnrollmentFormFieldStatus>>>;
  kisiAuthorize?: Maybe<KisiAuthorization>;
  /** Education Person Queries */
  listEducationPersons: PersonPagedResult;
  onboardingCompletedForPerson?: Maybe<Scalars['Boolean']>;
  readAccountTransactions?: Maybe<ReadAccountTransactionsResponse>;
  searchAccounts: AccountSearchResults;
  /** elasticsearch queries */
  searchCenters: CenterSearchResults;
  searchChildAtCenter?: Maybe<ChildSearchResults>;
  searchChildren: ChildrenSearchResults;
  searchClasses: ClassSearchResults;
  /** Comment */
  searchComment: CommentPagedResult;
  searchContacts: Array<Contact>;
  /** Contact */
  searchContactsV2: SearchContactsV2PagedResult;
  searchEducationAttachments: EducationAttachmentPagedResult;
  /** EnrollmentForm */
  searchEnrollmentForm: EnrollmentFormPagedResult;
  searchFees: FeesSearchResults;
  searchFees2?: Maybe<ListFeesResponse>;
  searchFolder: ContentPagedResult;
  /** Education Group Queries */
  searchGroup: GroupPagedResult;
  searchGroupsAndUsers: GroupsAndUsersPagedResult;
  searchLibrary: ContentPagedResult;
  searchMessagingAttachments: MessageAttachmentsDtoPagedResult;
  /** Note */
  searchNote: NotePagedResult;
  searchPeopleByPhoneNumber: SearchPeopleByPhoneNumberResults;
  searchPhoto: PhotoPagedResult;
  /** PtoTypes */
  searchPtoType?: Maybe<PtoTypesPagedResult>;
  searchRoyaltyFee: RoyaltyFeePagedResult;
  searchSchedulableStaff: StaffSearchResults;
  searchStaff: StaffSearchResults;
  searchSubsidyCenterRatings: SubsidyCenterRatingPagedResult;
  /** TimeCardLocks */
  searchTimeCardLock?: Maybe<TimeCardLockPagedResult>;
  sendEmail: EmailSendResponse;
  sendEmailTakenEmail: EmailSendResponse;
  /** Crn */
  validateCrn?: Maybe<CrnValidation>;
  verifyPin: Scalars['Boolean'];
};

export type QueryCalculateQualifyingRateArgs = {
  input?: InputMaybe<CalculateQualifyingRateInput>;
};

export type QueryCheckCurrentCcrmCredentialsArgs = {
  input: CheckCurrentCcrmCredentialsInput;
};

export type QueryCheckInviteCodeEmailComboArgs = {
  input: CheckInviteCodeEmailComboInput;
};

export type QueryCheckIsEmailTakenArgs = {
  email: Scalars['String'];
};

export type QueryCheckMyPermissionsArgs = {
  action: Scalars['String'];
  scopeId: Scalars['ID'];
  scopeType: ScopeType;
};

export type QueryCheckOfferAvailabilityArgs = {
  applicationId: Scalars['ID'];
  applicationOfferId: Scalars['ID'];
  businessId: Scalars['ID'];
  input: UpdateApplicationOfferRequest;
};

export type QueryExportEmbedReportArgs = {
  input: ExportReportInput;
};

export type QueryFetchBillingBusinessSettingsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetApiKeyArgs = {
  id: Scalars['ID'];
};

export type QueryGetApiKeysForEntityArgs = {
  id: Scalars['ID'];
};

export type QueryGetAccountBillingCycleArgs = {
  accountId: Scalars['ID'];
};

export type QueryGetAccountByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetAccountChannelArgs = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export type QueryGetAccountChannelsArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lastActive?: InputMaybe<Scalars['String']>;
  orderByLastJoined?: InputMaybe<Scalars['Boolean']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type QueryGetAccountChildrenAbsencesForTimeFrameArgs = {
  input: GetAccountChildrenAbsencesForTimeFrameInput;
};

export type QueryGetAccountContactInformationReportArgs = {
  input: GetAccountContactInformationInput;
};

export type QueryGetAccountDepositsArgs = {
  input?: InputMaybe<GetAccountDepositsInput>;
};

export type QueryGetAccountDepositsBalanceArgs = {
  input?: InputMaybe<GetAccountDepositsBalanceInput>;
};

export type QueryGetAccountDepositsSummaryArgs = {
  input?: InputMaybe<GetAccountDepositsInput>;
};

export type QueryGetAccountDocumentsArgs = {
  input: GetAccountDocumentsInput;
};

export type QueryGetAccountGapAndBalancesArgs = {
  input?: InputMaybe<GetAccountGapAndBalancesInput>;
};

export type QueryGetAccountPaymentMethodsArgs = {
  accountId: Scalars['ID'];
};

export type QueryGetAccountTransactionsInTimeframeArgs = {
  accountId: Scalars['ID'];
  end: Scalars['Date'];
  paymentTransactionsOnly?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['Date'];
};

export type QueryGetAccountingPeriodsArgs = {
  input: GetAccountingPeriodsInput;
};

export type QueryGetAccountsBillingOverviewArgs = {
  input: GetAccountsBillingOverviewInput;
};

export type QueryGetAccountsForCenterArgs = {
  centerId: Scalars['ID'];
};

export type QueryGetAccountsForChildArgs = {
  id: Scalars['ID'];
};

export type QueryGetAccsMetricsForCentersArgs = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
};

export type QueryGetAccsReportArgs = {
  businessId: Scalars['ID'];
  input: AccsReportInput;
};

export type QueryGetAcssCertDertsForCentersArgs = {
  businessId: Scalars['ID'];
  category?: InputMaybe<AccsDisplayCategory>;
  centerIds: Array<Scalars['ID']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
  statusAtDate?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<AccountStatusType>;
};

export type QueryGetActiveClassesByCenterAndDateArgs = {
  centerId: Scalars['String'];
  date?: InputMaybe<Scalars['Date']>;
};

export type QueryGetAdjustmentArgs = {
  id: Scalars['ID'];
};

export type QueryGetAdjustmentsForBusinessArgs = {
  input: GetAdjustmentsInput;
};

export type QueryGetAdpWeeklyPayrollReportArgs = {
  input: GetWeeklyPayrollInput;
};

export type QueryGetAgenciesForBusinessArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetAgencyArgs = {
  id: Scalars['ID'];
};

export type QueryGetAgencyAccountChildArgs = {
  id: Scalars['ID'];
};

export type QueryGetAgencyAccountChildrenForAccountArgs = {
  accountId: Scalars['ID'];
};

export type QueryGetAgencyPaymentArgs = {
  id: Scalars['ID'];
};

export type QueryGetAllPositionsByBusinessIdArgs = {
  businessId: Scalars['ID'];
  includeArchived?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetAllergyHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryGetAlternateArrangementsArgs = {
  businessId: Scalars['ID'];
  providerId: Scalars['ID'];
};

export type QueryGetAnnouncementArgs = {
  id: Scalars['String'];
};

export type QueryGetAnnouncementsForCenterArgs = {
  centerId: Scalars['String'];
};

export type QueryGetApiKeyWebHookArgs = {
  input?: InputMaybe<GetApiKeyWebHookInput>;
};

export type QueryGetApplicationCenterSettingsArgs = {
  input: GetAppllicationCenterSettingsInput;
};

export type QueryGetApplicationOfferArgs = {
  input: GetApplicationOfferInput;
};

export type QueryGetApplicationScheduleEnquiryArgs = {
  input: GetApplicationScheduleInput;
};

export type QueryGetApplicationScheduleOfferArgs = {
  input: GetApplicationScheduleInput;
};

export type QueryGetApplicationSettingArgs = {
  input: GetApplicationSettingInput;
};

export type QueryGetApplicationsByStageArgs = {
  businessId: Scalars['String'];
  input: GetApplicationsInput;
};

export type QueryGetAppliedAccountDiscountArgs = {
  id: Scalars['ID'];
};

export type QueryGetAppliedAccountDiscountsForAccountArgs = {
  accountId: Scalars['ID'];
};

export type QueryGetArrangementEmailPreviewArgs = {
  arrangementId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export type QueryGetArrangementHistoryArgs = {
  arrangementId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export type QueryGetArrangementMetricsArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetArrangementTypeForChildrenInTimeFrameArgs = {
  centerId: Scalars['ID'];
  childIds: Array<Scalars['ID']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type QueryGetAssignableRolesForUserArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetAttendanceArgs = {
  input: GetAttendanceInput;
};

export type QueryGetAttendanceBusinessSettingArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetAttendanceCentersSettingArgs = {
  input?: InputMaybe<GetAttendanceCenterSettingInput>;
};

export type QueryGetAttendanceOpenSpotsArgs = {
  input: GetAttendanceOpenSpotsInput;
};

export type QueryGetAttendanceReportCsvArgs = {
  input: AttendanceReportDataInput;
};

export type QueryGetAttendanceReportExcelArgs = {
  input: AttendanceReportDataInput;
};

export type QueryGetAttendanceTotalsPerWeekReportArgs = {
  input: AttendanceTotalsPerWeekInput;
};

export type QueryGetAttendanceWithArchivedEntriesArgs = {
  input: GetAttendanceInput;
};

export type QueryGetAuthBroadcastChannelIdsArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetAuthBulletinBoardChannelIdsArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetAuthenticationIdentityArgs = {
  input: AuthenticationIdentityInput;
};

export type QueryGetAuthenticationIdentityResponseArgs = {
  input: AuthenticationIdentityInput;
};

export type QueryGetAvailableChildrenForBusRosterArgs = {
  input: GetAvailableChildrenForBusRosterInput;
};

export type QueryGetBalanceByDatePdfReportArgs = {
  input: GetBalanceByDateInput;
};

export type QueryGetBalanceByDateReportArgs = {
  input: GetBalanceByDateInput;
};

export type QueryGetBasicStaffForCenterArgs = {
  centerId: Scalars['String'];
};

export type QueryGetBatchArgs = {
  id: Scalars['ID'];
};

export type QueryGetBatchPaymentReportArgs = {
  batchId: Scalars['ID'];
};

export type QueryGetBatchesForBusinessArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetBillingCycleScheduleForTemplateArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  templateId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetBillingCycleSchedulePreviewForArgs = {
  input?: InputMaybe<BillingCycleSchedulePreviewInput>;
};

export type QueryGetBillingCycleTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryGetBillingCycleTemplatesArgs = {
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryGetBillingCycleTemplatesForAccountArgs = {
  accountId: Scalars['ID'];
};

export type QueryGetBillingEditsPdfReportArgs = {
  input: GetBillingEditsReportInput;
};

export type QueryGetBillingSummaryPdfReportArgs = {
  input: GetBillingSummaryInput;
};

export type QueryGetBulletinBoardChannelArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type QueryGetBulletinBoardChannelsArgs = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lastActive?: InputMaybe<Scalars['String']>;
  orderByLastJoined?: InputMaybe<Scalars['Boolean']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type QueryGetBulletinBoardChatAuthArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  staffId: Scalars['ID'];
};

export type QueryGetBusRosterByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetBusRosterPdfArgs = {
  additionalChildren: Scalars['Int'];
  endDate: Scalars['Date'];
  id: Scalars['ID'];
  startDate: Scalars['Date'];
};

export type QueryGetBusinessConfigurationByBusinessIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetBusinessEducationFeaturesArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetBusinessEntityCountsArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetBusinessFeatureByBusinessIdArgs = {
  id: Scalars['ID'];
  type: BusinessFeatureType;
};

export type QueryGetCcmsApprovalArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type QueryGetCcrmCredentialsArgs = {
  input: GetCcrmCredentialsInput;
};

export type QueryGetCcrmExistingChildMappingArgs = {
  input: GetCcrmExistingChildMappingInput;
};

export type QueryGetCcrmExistingContactMappingArgs = {
  input: GetCcrmExistingContactMappingInput;
};

export type QueryGetCcsAbsencesCategoriesForCenterArgs = {
  input: CcsAbsencesCategoriesInput;
};

export type QueryGetCcsAbsencesForCenterArgs = {
  input: CcsAbsencesInput;
};

export type QueryGetCcsCategoriesForCenterArgs = {
  input: AccsDisplayCategoriesInput;
};

export type QueryGetCcsCertificateCategoriesForCenterArgs = {
  input: CcsCertificateCategoriesInput;
};

export type QueryGetCcsDeterminationCategoriesForCenterArgs = {
  input: AccsDisplayCategoriesInput;
};

export type QueryGetCcsEnrolmentCategoryCountForCentersArgs = {
  businessId: Scalars['ID'];
  centerIds: Array<InputMaybe<Scalars['ID']>>;
};

export type QueryGetCcsEnrolmentsForCentersArgs = {
  businessId: Scalars['ID'];
  category?: InputMaybe<EnrolmentCategory>;
  centerIds: Array<InputMaybe<Scalars['ID']>>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
  statusAtDate?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<AccountStatusType>;
};

export type QueryGetCcsEnrolmentsForChildrenArgs = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  children: Array<Scalars['ID']>;
};

export type QueryGetCcsEnrolmentsReportArgs = {
  businessId: Scalars['ID'];
  input: CcsEnrolmentReportInput;
};

export type QueryGetCcsEntitlementChangeHistoryArgs = {
  input: CcsEntitlementChangeHistoryInput;
};

export type QueryGetCcsEntitlementsArgs = {
  input: CcsEntitlementInput;
};

export type QueryGetCcsEntitlementsForChildrenArgs = {
  accountId: Scalars['ID'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  children: Array<Scalars['ID']>;
};

export type QueryGetCcsEntitlementsReportArgs = {
  businessId: Scalars['ID'];
  input: CcsEntitlementsReportInput;
};

export type QueryGetCcsOutstandingReportArgs = {
  input: CcsOutstandingReportInput;
};

export type QueryGetCcsSubsidyPaymentReportArgs = {
  input: CcsSubsidyPaymentReportInput;
};

export type QueryGetCcsSubsidyRemittanceReportArgs = {
  input: CcsSubsidyRemittanceReportInput;
};

export type QueryGetCcssApprovalsForCenterArgs = {
  centerId: Scalars['ID'];
};

export type QueryGetCcssCertificatesForCentersArgs = {
  businessId: Scalars['ID'];
  category?: InputMaybe<AccsDisplayCategory>;
  centerIds: Array<Scalars['ID']>;
  childName?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
};

export type QueryGetCcssCertificatesForChildrenArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  children: Array<Scalars['ID']>;
};

export type QueryGetCcssDeterminationsForChildrenArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  children: Array<Scalars['ID']>;
};

export type QueryGetCcssEventsArgs = {
  input: GetCcssEventInput;
};

export type QueryGetCcssProviderCorrespondenceArgs = {
  input: CcssProviderCorrespondenceInput;
};

export type QueryGetCcssProviderMessagesArgs = {
  input: CcssProviderMessagesInput;
};

export type QueryGetCcssServiceCorrespondenceArgs = {
  input: CcssServiceCorrespondenceInput;
};

export type QueryGetCcssServiceMessagesArgs = {
  input: CcssServiceMessagesInput;
};

export type QueryGetCenterArgs = {
  id: Scalars['String'];
};

export type QueryGetCenterBatchingSettingsByBusinessArgs = {
  input: GetCenterBatchingSettingsByBusinessInput;
};

export type QueryGetCenterBillingSettingsByBusinessArgs = {
  input: GetCenterBillingSettingsByBusinessInput;
};

export type QueryGetCenterBillingSettingsByCenterArgs = {
  input: GetCenterBillingSettingsByCenterInput;
};

export type QueryGetCenterChannelArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type QueryGetCenterChannelsArgs = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lastActive?: InputMaybe<Scalars['String']>;
  orderByLastJoined?: InputMaybe<Scalars['Boolean']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type QueryGetCenterClassesArgs = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetCenterScheduleForWeekArgs = {
  centerId: Scalars['ID'];
  date: Scalars['DateTime'];
};

export type QueryGetCenterStaffArgs = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetCenterStatementMessageArgs = {
  centerId: Scalars['ID'];
};

export type QueryGetCenterStatementRunsArgs = {
  input?: InputMaybe<GetCenterStatementRunsInput>;
};

export type QueryGetCenterStatementScheduleArgs = {
  centerId: Scalars['ID'];
};

export type QueryGetCenterStatementsForAccountArgs = {
  input: GetCenterStatementsForAccountInput;
};

export type QueryGetCenterSummaryStatisticsArgs = {
  businessId: Scalars['ID'];
  input?: InputMaybe<GetCenterSummaryQueryInput>;
};

export type QueryGetCentersForBusinessArgs = {
  input: GetEducationCentersForBusinessInput;
};

export type QueryGetCentreIdsHasFormAttachedArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetChannelActivityArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  channelId?: InputMaybe<Scalars['ID']>;
  loadAcknowledgements?: InputMaybe<Scalars['Boolean']>;
  loadAdminData?: InputMaybe<Scalars['Boolean']>;
  loadFlaggedMessages?: InputMaybe<Scalars['Boolean']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortByDateCreated?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetChannelUsersArgs = {
  businessId: Scalars['ID'];
  channelId: Scalars['ID'];
};

export type QueryGetChannelsSentMessagesCountArgs = {
  businessId: Scalars['ID'];
  input?: InputMaybe<GetChannelsSentMessagesCountInput>;
};

export type QueryGetChatActivityArgs = {
  activitySince?: InputMaybe<Scalars['String']>;
  activityType?: InputMaybe<ActivityType>;
  businessId?: InputMaybe<Scalars['ID']>;
  channelId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['ID']>;
  pubNubMessageId?: InputMaybe<Scalars['String']>;
  timeToken?: InputMaybe<Scalars['Float']>;
};

export type QueryGetChatAuthArgs = {
  businessId: Scalars['ID'];
  channelId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  returnWildcardChannels?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetChatAuthTokenArgs = {
  businessId: Scalars['ID'];
  returnWildcardInfo?: InputMaybe<Scalars['Boolean']>;
  useRegEx?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetChildAttendanceSummaryReportArgs = {
  input: ChildAttendanceSummaryInput;
};

export type QueryGetChildByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetChildCheckInReportArgs = {
  input: GetChildCheckInInput;
};

export type QueryGetChildIdsWithContractsOnDateArgs = {
  input?: InputMaybe<GetChildIdsWithContractsOnDateInput>;
};

export type QueryGetChildLastAttendanceReportArgs = {
  input: ChildLastAttendanceReportInput;
};

export type QueryGetChildTrackingPdfReportArgs = {
  input: GetChildTrackingReportInput;
};

export type QueryGetChildWellnessPdfReportArgs = {
  input: ChildWellnessConditionReportInput;
};

export type QueryGetChildWellnessReportArgs = {
  input: ChildWellnessConditionReportInput;
};

export type QueryGetChildrenByBirthdayWeekArgs = {
  currentWeekOffset: Scalars['Int'];
};

export type QueryGetChildrenForAgencyPaymentAtCenterArgs = {
  input: GetChildrenForAgencyPaymentAtCenterInput;
};

export type QueryGetClassArgs = {
  id: Scalars['String'];
};

export type QueryGetClassAttendanceRosterReportArgs = {
  input: ClassAttendanceRosterReportInput;
};

export type QueryGetClassClosureByDateArgs = {
  input: GetClassClosureByDateInput;
};

export type QueryGetClassPhotoCountsArgs = {
  input: PhotoCountInput;
};

export type QueryGetClassesForBusinessArgs = {
  id: Scalars['String'];
};

export type QueryGetClassesForCenterArgs = {
  centerId: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryGetClassesForCentersArgs = {
  centerIds: Array<Scalars['String']>;
};

export type QueryGetCommsAllCenterSettingsArgs = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  showOverrides?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type QueryGetCommsBusinessSettingsArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetCommsCenterSettingsArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type QueryGetComplyingWrittenArrangementPageArgs = {
  input: GetComplyingWrittenArrangementPageInput;
};

export type QueryGetContactByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetContactTagsInUseAtEntityArgs = {
  entityId: Scalars['ID'];
};

export type QueryGetContactsByPhoneNumberArgs = {
  centerId: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type QueryGetContractEditsPdfReportArgs = {
  input: GetContractEditsReportInput;
};

export type QueryGetContractFeeDetailedPdfReportArgs = {
  input: GetContractFeeDetailedInput;
};

export type QueryGetContractFeePdfReportArgs = {
  input: GetContractFeeInput;
};

export type QueryGetContractReportArgs = {
  input: ContractReportInput;
};

export type QueryGetContractsForAccountArgs = {
  accountId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type QueryGetContractsForAccountChildArgs = {
  accountChildId: Scalars['ID'];
};

export type QueryGetContractsForChildArgs = {
  accountId: Scalars['ID'];
  childId: Scalars['ID'];
};

export type QueryGetCurrentCpavForCentreServiceArgs = {
  input: GetCurrentCpavForCentreServiceInput;
};

export type QueryGetCurriculaArgs = {
  input: GetPaginatedBusinessCurriculaInput;
};

export type QueryGetCurriculumArgs = {
  input: GetCurriculumInput;
};

export type QueryGetCurriculumScheduleArgs = {
  input: GetCurriculumScheduleInput;
};

export type QueryGetCurriculumScheduleProgramThemeArgs = {
  input: GetCurriculumScheduleProgramThemeInput;
};

export type QueryGetCurriculumSchedulesForBusinessArgs = {
  input: GetCurriculumSchedulesForBusinessInput;
};

export type QueryGetCustomFieldByIdArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryGetCustomFieldDocumentValuesArgs = {
  input?: InputMaybe<GetCustomFieldDocumentValuesInput>;
};

export type QueryGetCustomFieldValuesArgs = {
  input?: InputMaybe<GetCustomFieldValuesInput>;
};

export type QueryGetCustomFieldsReportArgs = {
  input: CustomFieldsReportInput;
};

export type QueryGetDailyExperienceAssignmentsArgs = {
  input: GetDailyExperienceAssignmentsInput;
};

export type QueryGetDailyNotesForCenterArgs = {
  input: GetDailyNotesForCenterInput;
};

export type QueryGetDailyNotesOfTypeForChildrenArgs = {
  input: GetDailyNotesOfTypeForChildrenInput;
};

export type QueryGetDateCalculateArgs = {
  input: GetDateCalculateInput;
};

export type QueryGetDebtCsvReportArgs = {
  input: DebtReportInput;
};

export type QueryGetDebtExcelReportArgs = {
  input: DebtReportInput;
};

export type QueryGetDepositsHeldReportArgs = {
  input: DepositsHeldReportInput;
};

export type QueryGetDirectDebitPaymentMethodArgs = {
  id: Scalars['ID'];
};

export type QueryGetDiscountArgs = {
  id: Scalars['ID'];
};

export type QueryGetDiscountsForBusinessArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetDiscountsWithFiltersArgs = {
  input: GetDiscountsWithFiltersInput;
};

export type QueryGetDocumentByIdArgs = {
  businessId: Scalars['ID'];
  documentId: Scalars['ID'];
};

export type QueryGetDocumentLinkByIdArgs = {
  businessId: Scalars['ID'];
  documentId: Scalars['ID'];
};

export type QueryGetDoesChildHaveHistoryOnAccountArgs = {
  accountId: Scalars['ID'];
  childId: Scalars['ID'];
};

export type QueryGetDownloadUrlArgs = {
  input: GetDownloadUrlInput;
};

export type QueryGetEducationPersonArgs = {
  input: Scalars['String'];
};

export type QueryGetEmailAddressReportArgs = {
  input: EmailAddressReportInput;
};

export type QueryGetEmbedReportArgs = {
  input: EmbedReportInput;
};

export type QueryGetEmergencyContactPdfReportArgs = {
  input: GetEmergencyContactInput;
};

export type QueryGetEmergencyContactReportArgs = {
  input: GetEmergencyContactInput;
};

export type QueryGetEmployeeTimeSheetExcelReportArgs = {
  input: EmployeeTimeSheetReportInput;
};

export type QueryGetEmployeeTimeSheetReportArgs = {
  input: EmployeeTimeSheetReportInput;
};

export type QueryGetEndCareEnrollmentHistoryByAccountChildIdArgs = {
  input: GetEndCareEnrollmentHistoryByAccountChildIdInput;
};

export type QueryGetEndCareEnrollmentHistoryPageArgs = {
  input: GetEndCareEnrollmentPageInput;
};

export type QueryGetEndCareEnrollmentMetricsArgs = {
  input: GetEndCareEnrollmentMetricsInput;
};

export type QueryGetEndCareEnrollmentPageArgs = {
  input: GetEndCareEnrollmentPageInput;
};

export type QueryGetEndCareHistorySessionsArgs = {
  input: GetEndCareHistorySessionsInput;
};

export type QueryGetEndCareSessionsArgs = {
  input: GetEndCareSessionsInput;
};

export type QueryGetEndedContractsReportArgs = {
  input: EndedContractsReportInput;
};

export type QueryGetEnrollmentAvailabilityArgs = {
  input?: InputMaybe<GetEnrollmentAvailabilityInput>;
};

export type QueryGetEnrollmentProgramGroupsArgs = {
  input: GetEnrollmentProgramGroupsInput;
};

export type QueryGetEnrollmentProgramsArgs = {
  input: GetEnrollmentProgramsInput;
};

export type QueryGetEnrolmentFormDiffArgs = {
  businessId: Scalars['ID'];
  enrolmentFormId: Scalars['String'];
};

export type QueryGetEntityArgs = {
  id: Scalars['String'];
};

export type QueryGetExcursionPdfReportArgs = {
  input: GetExcursionReportInput;
};

export type QueryGetExistingEntitiesMappingArgs = {
  input: GetCcrmExistingEntityMappingInput;
};

export type QueryGetExpectedSessionsArgs = {
  input?: InputMaybe<GetExpectedSessionsInput>;
};

export type QueryGetExpectedSessionsForAccountChildInTimeframeArgs = {
  input?: InputMaybe<GetExpectedSessionsForAccountChildInTimeframeInput>;
};

export type QueryGetExternalTypesArgs = {
  input: GetExternalTypesInput;
};

export type QueryGetFatZebraTransactionsReportArgs = {
  input: GetFatZebraTransactionsInput;
};

export type QueryGetFeatureFlagArgs = {
  id: Scalars['ID'];
};

export type QueryGetFeatureFlagsForBusinessArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetFeesByClassIdArgs = {
  classId: Scalars['ID'];
};

export type QueryGetFolderArgs = {
  input: GetFolderInput;
};

export type QueryGetFolderCountArgs = {
  input: GetFolderCountInput;
};

export type QueryGetForNewProgramByAccountIdArgs = {
  input: GetProgramsByAccountIdInput;
};

export type QueryGetFreeKindyAcquittalCenterSummariesArgs = {
  input?: InputMaybe<GetPagedKindyForAllCenterSummariesInput>;
};

export type QueryGetFreeKindyAcquittalChildSummariesArgs = {
  input?: InputMaybe<GetPagedKindyForAllChildSummariesInput>;
};

export type QueryGetFreeKindyCenterSummariesArgs = {
  input?: InputMaybe<GetPagedKindyForAllCenterSummariesInput>;
};

export type QueryGetFreeKindyChildSummariesArgs = {
  input?: InputMaybe<GetPagedKindyForAllChildSummariesInput>;
};

export type QueryGetGlCodeAssignmentsArgs = {
  input: GetGlCodeAssignmentsInput;
};

export type QueryGetGlCodeCountArgs = {
  input: GetGlCodeCountInput;
};

export type QueryGetGlCodesArgs = {
  input: GetGlCodesInput;
};

export type QueryGetGallerySettingsArgs = {
  centerId: Scalars['ID'];
};

export type QueryGetGroupArgs = {
  input: Scalars['String'];
};

export type QueryGetHealthProfessionalByChildIdArgs = {
  childId: Scalars['ID'];
};

export type QueryGetHealthProfessionalByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetHighestDailyMessagesPerChannelStatisticsArgs = {
  businessId: Scalars['ID'];
  input?: InputMaybe<GetHighestDailyMessagesPerChannelInput>;
};

export type QueryGetIntegrationApiKeyForBusinessArgs = {
  input?: InputMaybe<GetIntegrationApiKeyForBusinessInput>;
};

export type QueryGetIntegrationMappingsArgs = {
  input: GetIntegrationMappingsInput;
};

export type QueryGetIsCaseClaimCategoryCountsForCentersArgs = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
};

export type QueryGetIsCaseClaimPaymentsForCentersArgs = {
  input: GetIsCaseClaimPaymentsForCentersInput;
};

export type QueryGetIsCaseClaimsForCentersArgs = {
  input: GetIsCaseClaimsForCentersInput;
};

export type QueryGetIsCasesForCentersArgs = {
  input: GetIsCasesForCentersInput;
};

export type QueryGetIsEducatorsArgs = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type QueryGetIsInnovativeCasesForCentersArgs = {
  input: GetIsInnovativeCasesForCentersInput;
};

export type QueryGetIsMessagesForCentersArgs = {
  input: GetIsMessagesForCentersInput;
};

export type QueryGetIsPaymentReportArgs = {
  input: IsPaymentReportInput;
};

export type QueryGetIsValidArrangementArgs = {
  accountId: Scalars['ID'];
  arrangementType: Scalars['ID'];
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
};

export type QueryGetItemizedBillArgs = {
  id: Scalars['ID'];
};

export type QueryGetItemizedBillPdfArgs = {
  id: Scalars['ID'];
};

export type QueryGetItemizedBillSummaryArgs = {
  id: Scalars['ID'];
};

export type QueryGetItemizedBillSummaryPdfArgs = {
  id: Scalars['ID'];
};

export type QueryGetItemizedBillsByAccountIdArgs = {
  accountId: Scalars['ID'];
};

export type QueryGetKindyChildFundingSchedulesArgs = {
  input?: InputMaybe<GetKindyChildFundingSchedulesInput>;
};

export type QueryGetKindyChildFundingSchedulesForAccountArgs = {
  input: GetKindyChildFundingSchedulesForAccountInput;
};

export type QueryGetKindyForAllAcquittalCenterSummariesArgs = {
  input?: InputMaybe<GetPagedKindyForAllCenterSummariesInput>;
};

export type QueryGetKindyForAllAcquittalChildSummariesArgs = {
  input?: InputMaybe<GetPagedKindyForAllChildSummariesInput>;
};

export type QueryGetKindyForAllAcquittalErrorsReportArgs = {
  input: GetKindyForAllSubmissionErrorsReportInput;
};

export type QueryGetKindyForAllAcquittalReportArgs = {
  input: GetKindyForAllReportInput;
};

export type QueryGetKindyForAllAgreementsArgs = {
  input?: InputMaybe<GetKindyForAllAgreementsInput>;
};

export type QueryGetKindyForAllCenterAgreementsArgs = {
  input?: InputMaybe<GetPagedKindyForAllResultInput>;
};

export type QueryGetKindyForAllCenterSummariesArgs = {
  input?: InputMaybe<GetPagedKindyForAllCenterSummariesInput>;
};

export type QueryGetKindyForAllChildSummariesArgs = {
  input?: InputMaybe<GetPagedKindyForAllChildSummariesInput>;
};

export type QueryGetKindyForAllCredentialsArgs = {
  input?: InputMaybe<GetPagedKindyForAllResultInput>;
};

export type QueryGetKindyForAllForecastErrorsReportArgs = {
  input: GetKindyForAllSubmissionErrorsReportInput;
};

export type QueryGetKindyForAllForecastReportArgs = {
  input: GetKindyForAllReportInput;
};

export type QueryGetKindyForAllIneligibilityArgs = {
  input?: InputMaybe<GetKindyForAllIneligibilityInput>;
};

export type QueryGetKindyForAllQuartersArgs = {
  input?: InputMaybe<GetKindyForAllQuartersInput>;
};

export type QueryGetKindyProgramFundingSchedulesArgs = {
  input?: InputMaybe<GetKindyProgramFundingSchedulesInput>;
};

export type QueryGetKisiPairedPlacesArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetKisiShareArgs = {
  businessId: Scalars['ID'];
  personId: Scalars['ID'];
};

export type QueryGetLastTimeEntryByAccountChildIdArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryGetLastTimeEntryByContractIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetLastTimeEntryForChildInClassArgs = {
  accountChildId: Scalars['ID'];
  classId: Scalars['ID'];
};

export type QueryGetLearningStandardArgs = {
  input: Scalars['String'];
};

export type QueryGetLearningStandardDomainArgs = {
  input: GetLearningStandardDomainInput;
};

export type QueryGetLearningStandardSubDomainArgs = {
  input: Scalars['String'];
};

export type QueryGetLibraryArgs = {
  input: Scalars['String'];
};

export type QueryGetLinkCodesArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetLinkedCentersArgs = {
  businessId: Scalars['ID'];
  input: PaginateInput;
  providerId: Scalars['ID'];
};

export type QueryGetLockArgs = {
  id: Scalars['ID'];
};

export type QueryGetLockByLockIdArgs = {
  id: Scalars['Int'];
};

export type QueryGetLockedUntilForBusinessArgs = {
  input?: InputMaybe<BusinessSearchTimeCardLockedUntilInput>;
};

export type QueryGetLockedUntilForCenterArgs = {
  input?: InputMaybe<CenterSearchTimeCardLockedUntilInput>;
};

export type QueryGetMedicalConditionHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryGetMedicareCardByChildIdArgs = {
  childId: Scalars['ID'];
};

export type QueryGetMedicareCardByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetMonthlyMessagesSentStatisticsArgs = {
  businessId: Scalars['ID'];
  input?: InputMaybe<GetMonthlyMessageStatisticsInput>;
};

export type QueryGetMyAttendanceArgs = {
  input: GetAttendanceInput;
};

export type QueryGetMyTimeOffRequestsArgs = {
  input: GetMyTimeOffRequestInput;
};

export type QueryGetNameToFacePdfReportArgs = {
  input: GetNameToFaceInput;
};

export type QueryGetNameToFaceReportArgs = {
  input: GetNameToFaceInput;
};

export type QueryGetNewContractReportArgs = {
  input: ContractReportInput;
};

export type QueryGetOccupancyTotalReportArgs = {
  input: GetOccupancyTotalInput;
};

export type QueryGetOccupancyWeeklyTotalReportArgs = {
  input: GetOccupancyTotalInput;
};

export type QueryGetOpenTimeEntryForPersonAtCenterArgs = {
  input: GetOpenTimeEntryForPersonAtCenterInput;
};

export type QueryGetPaginatedAgenciesForBusinessArgs = {
  input: GetAgenciesInput;
};

export type QueryGetPaginatedAgencyAccountChildrenForBusinessArgs = {
  input: GetAgencyAccountChildrenInput;
};

export type QueryGetPaginatedAgencyPaymentsForBusinessArgs = {
  completedInput: GetPaginatedAgencyPaymentsInput;
  draftInput: GetPaginatedAgencyPaymentsInput;
};

export type QueryGetPaginatedBatchesForBusinessArgs = {
  input: GetBatchesInput;
};

export type QueryGetPaginatedBusRostersArgs = {
  input: GetPaginatedBusRostersInput;
};

export type QueryGetPaginatedCentersForBusinessArgs = {
  input: GetCentersInput;
};

export type QueryGetPaginatedCustomFieldsForBusinessArgs = {
  input: GetCustomFieldsInput;
};

export type QueryGetPaginatedPaymentsArgs = {
  input?: InputMaybe<GetPaginatedPaymentsInput>;
};

export type QueryGetPaginatedServiceFeeGroupsArgs = {
  input: GetPaginatedServiceFeeGroupsInput;
};

export type QueryGetPaginatedTrainingTimeEventsForCenterArgs = {
  input?: InputMaybe<GetPaginatedTrainingTimeEventsForCenterInput>;
};

export type QueryGetPaginatedTransactionTypesArgs = {
  input?: InputMaybe<GetPaginatedTransactionTypesInput>;
};

export type QueryGetPaginatedTransactionsInTimeframeArgs = {
  input?: InputMaybe<GetPaginatedTransactionsInTimeframeInput>;
};

export type QueryGetPaymentArgs = {
  input?: InputMaybe<GetPaymentInput>;
};

export type QueryGetPaymentMethodArgs = {
  id: Scalars['ID'];
};

export type QueryGetPaymentMethodsByBusinessIdArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetPaymentReportArgs = {
  input: PaymentReportInput;
};

export type QueryGetPayrixDisbursementReportArgs = {
  input: GetPayrixDisbursementReportInput;
};

export type QueryGetPayrixDisbursementReportPdfArgs = {
  input: GetPayrixDisbursementReportInput;
};

export type QueryGetPayrixDisbursementsArgs = {
  input: GetPayrixDisbursementsInput;
};

export type QueryGetPayrixDisbursementsForCentersArgs = {
  input: GetPayrixDisbursementsForCentersInput;
};

export type QueryGetPayrixDisbursementsMonthlyReportArgs = {
  input: DisbursementsMonthlyInput;
};

export type QueryGetPeaWeeksRemainingArgs = {
  accountId: Scalars['ID'];
  centerId: Scalars['ID'];
  childIds: Array<InputMaybe<Scalars['ID']>>;
};

export type QueryGetPersonArgs = {
  id: Scalars['String'];
};

export type QueryGetPersonChannelsSummaryArgs = {
  businessId: Scalars['ID'];
  input?: InputMaybe<GetPersonChannelsSummaryInput>;
};

export type QueryGetPersonChannelsUnreadMessagesArgs = {
  businessId: Scalars['ID'];
  input?: InputMaybe<GetPersonChannelsUnreadMessagesInput>;
};

export type QueryGetPersonnelByBusinessIdArgs = {
  businessId: Scalars['ID'];
  role?: InputMaybe<Scalars['String']>;
};

export type QueryGetPersonnelByProviderIdArgs = {
  businessId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  providerId: Scalars['ID'];
  sortDtos?: InputMaybe<Array<InputMaybe<PersonelSortInput>>>;
};

export type QueryGetPersonnelByServiceIdArgs = {
  businessId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  serviceId: Scalars['ID'];
  sortDtos?: InputMaybe<Array<InputMaybe<PersonelSortInput>>>;
};

export type QueryGetPhotoByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetPhotoStatisticsArgs = {
  input: SearchPhotoInput;
};

export type QueryGetPocCenterStateArgs = {
  id: Scalars['ID'];
};

export type QueryGetPocChildStateArgs = {
  id: Scalars['ID'];
};

export type QueryGetPocChildrenAtCenterArgs = {
  centerId: Scalars['ID'];
  date: Scalars['Date'];
};

export type QueryGetPocChildrenStateArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGetPocClassStateArgs = {
  id: Scalars['ID'];
};

export type QueryGetPocClassesForCenterArgs = {
  centerId: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryGetPocSpaceStateArgs = {
  id: Scalars['ID'];
};

export type QueryGetPocStaffAtCenterArgs = {
  centerId: Scalars['ID'];
  date: Scalars['DateTime'];
  includeCallerIfAdmin?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetPocStaffStateArgs = {
  id: Scalars['ID'];
};

export type QueryGetPocStaffStateBulkArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGetPositionsByBusinessIdArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetPositionsByPositionIdArgs = {
  positionId: Scalars['ID'];
};

export type QueryGetPositionsByScopeIdArgs = {
  scopeId: Scalars['ID'];
  scopeType: ScopeType;
};

export type QueryGetPositionsByStaffIdArgs = {
  staffId: Scalars['ID'];
};

export type QueryGetPossibleKisiParentAssociationsArgs = {
  input: PossibleKisiAssociationsInput;
};

export type QueryGetProcessingPaymentInTimeframeArgs = {
  end: Scalars['Date'];
  paymentTransactionsOnly?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['Date'];
};

export type QueryGetProgramByIdArgs = {
  input: GetProgramByIdInput;
};

export type QueryGetProgramChildrenArgs = {
  input: GetProgramChildrenInput;
};

export type QueryGetProgramsByAccountIdArgs = {
  input: GetProgramsByAccountIdInput;
};

export type QueryGetProgramsByCenterIdArgs = {
  input: GetCenterProgramsInput;
};

export type QueryGetPromoCodeByCodeArgs = {
  code: Scalars['String'];
};

export type QueryGetProviderAddressesArgs = {
  businessId: Scalars['ID'];
  providerId: Scalars['ID'];
};

export type QueryGetProviderContactArgs = {
  businessId: Scalars['ID'];
  providerId: Scalars['ID'];
};

export type QueryGetProviderDebtsArgs = {
  businessId: Scalars['ID'];
  providerId: Scalars['ID'];
};

export type QueryGetProviderFinancialDetailsArgs = {
  providerId: Scalars['ID'];
};

export type QueryGetProviderLocationOfRecordArgs = {
  providerId: Scalars['ID'];
};

export type QueryGetProviderNamesArgs = {
  businessId: Scalars['ID'];
  providerId: Scalars['ID'];
};

export type QueryGetProvidersByBusinessIdArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetQldCensusReportArgs = {
  input: GetQldCensusReportInput;
};

export type QueryGetQkfsReportArgs = {
  input: GetQkfsReportInput;
};

export type QueryGetQkfsTransactionCountAfterDateArgs = {
  input: GetQkfsTransactionCountAfterDateInput;
};

export type QueryGetQuarterHourCheckInReportArgs = {
  input: QuarterHourCheckInInput;
};

export type QueryGetReEnrolAccountsByBatchIdArgs = {
  batchId: Scalars['ID'];
  businessId: Scalars['ID'];
  input: ReenrollmentGetAccountsRequestInput;
};

export type QueryGetReEnrolBusinessSettingsArgs = {
  input: GetReEnrolBusinessSettingsInput;
};

export type QueryGetReEnrolCorrespondenceMetadataByLinkCodeArgs = {
  businessId: Scalars['ID'];
  linkCode: Scalars['String'];
};

export type QueryGetReEnrolHistoryOfActionsArgs = {
  businessId: Scalars['ID'];
  input: GetReEnrolHistoryOfActionsInput;
};

export type QueryGetReEnrolHistoryReportArgs = {
  businessId: Scalars['ID'];
  input: GetReEnrolHistoryOfActionsInput;
};

export type QueryGetReenrollmentAccountContractsArgs = {
  input: ReenrollmentGetAccountContractsRequestInput;
};

export type QueryGetReenrollmentAccountsArgs = {
  input: ReenrollmentGetAccountsRequestInput;
};

export type QueryGetRegulationArgs = {
  id: Scalars['String'];
};

export type QueryGetResourceArgs = {
  input: Scalars['String'];
};

export type QueryGetRestrictedResourceTypesArgs = {
  input?: InputMaybe<Scalars['Void']>;
};

export type QueryGetRestrictionHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryGetReturnFeeReductionsArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetRolesForBusinessArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetRoyaltyConfigByBusinessIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetRoyaltyFeeByIdArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryGetRoyaltyFeeExceptionByIdArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  royaltyFeeId: Scalars['ID'];
};

export type QueryGetRoyaltyFeeExceptionsByRoyaltyFeeIdArgs = {
  businessId: Scalars['ID'];
  royaltyFeeId: Scalars['ID'];
};

export type QueryGetRoyaltyFeesByBusinessIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetRoyaltyPdfReportArgs = {
  input: RoyaltyReportInput;
};

export type QueryGetSsoLoginUrlArgs = {
  input: SsoLoginElementsInput;
};

export type QueryGetSageGlDetailsReportArgs = {
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetSageGlReportArgs = {
  input: SageGlImportInput;
};

export type QueryGetScopesForActionArgs = {
  action: Scalars['String'];
};

export type QueryGetServiceContactArgs = {
  serviceId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetServiceDebtsArgs = {
  businessId: Scalars['ID'];
  serviceId: Scalars['ID'];
};

export type QueryGetServiceFeeGroupByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetServiceFinancialDetailsArgs = {
  serviceId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetServiceNameChangeLogsArgs = {
  serviceId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetServiceTemporarityCeasingArgs = {
  businessId: Scalars['ID'];
  serviceId: Scalars['ID'];
};

export type QueryGetServicesByBusinessArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetSessionAmendmentGetBusinessConfigArgs = {
  input: SessionAmendmentGetBusinessConfigInput;
};

export type QueryGetSessionAmendmentsArgs = {
  input: SessionAmendmentSearchInput;
};

export type QueryGetSessionByIdArgs = {
  input: GetSessionInput;
};

export type QueryGetSessionCountByTimeFrameArgs = {
  input: CasualBookingCountInput;
};

export type QueryGetSessionsAndActivityForChildrenByContactIdArgs = {
  centerId: Scalars['String'];
  contactId: Scalars['String'];
  date: Scalars['Date'];
};

export type QueryGetShiftsForPersonForDatesArgs = {
  dates: Array<DateTimeRangeInput>;
  personId: Scalars['ID'];
};

export type QueryGetSortedAccountsArgs = {
  input: AccountSearchInput;
};

export type QueryGetSortedChildrenArgs = {
  input: ChildSearchInput;
};

export type QueryGetSpaceArgs = {
  id: Scalars['String'];
};

export type QueryGetSpacesForCenterArgs = {
  centerId: Scalars['String'];
};

export type QueryGetStaffArgs = {
  id: Scalars['String'];
};

export type QueryGetStaffByBirthdayWeekArgs = {
  currentWeekOffset: Scalars['Int'];
};

export type QueryGetStaffByEmailArgs = {
  email: Scalars['String'];
};

export type QueryGetStaffByPhoneNumberAndBusinessIdArgs = {
  businessId: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type QueryGetStaffForBusinessArgs = {
  input: GetEducationStaffForBusinessInput;
};

export type QueryGetStageAggregationArgs = {
  businessId: Scalars['String'];
  input: GetStageAggregationInput;
};

export type QueryGetStartStrongReportArgs = {
  input: GetStartStrongReportInput;
};

export type QueryGetStatementLinkArgs = {
  input: IGetStatementLinkInput;
};

export type QueryGetStudentChannelsArgs = {
  accountChildIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']>;
  hasUnresolvedFlags?: InputMaybe<Scalars['Boolean']>;
  includeInactiveChannels?: InputMaybe<Scalars['Boolean']>;
  lastActive?: InputMaybe<Scalars['String']>;
  orderByLastJoined?: InputMaybe<Scalars['Boolean']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type QueryGetSubjectArgs = {
  input: Scalars['String'];
};

export type QueryGetSubscriptionArgs = {
  businessId: Scalars['ID'];
};

export type QueryGetSubsidyCategoriesArgs = {
  input: SubsidyCategoriesInput;
};

export type QueryGetSubsidyCenterRatingArgs = {
  input: GetSubsidyCenterRating;
};

export type QueryGetSubsidyDashboardArgs = {
  input: SubsidyDashboardInput;
};

export type QueryGetSubsidySchemeProgramsCountByCenterIdArgs = {
  input: GetSubsidySchemeProgramsCountByCenterInput;
};

export type QueryGetSubsidySchemesArgs = {
  input: SubsidySchemeInput;
};

export type QueryGetSumOfPaymentsInTimeFrameArgs = {
  input: GetSumOfPaymentsInTimeFrameInput;
};

export type QueryGetTableMetaDataArgs = {
  input: TableMetaDataInput;
};

export type QueryGetTagsUsedAcrossEntityArgs = {
  elasticIndex: Scalars['String'];
};

export type QueryGetThemeArgs = {
  input: GetThemeInput;
};

export type QueryGetThreadMessagesArgs = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  rootPubNubMessageId?: InputMaybe<Scalars['String']>;
};

export type QueryGetTimeEntriesForDatesArgs = {
  input: GetTimeEntriesForDatesInput;
};

export type QueryGetTimeOffRequestsByScopeArgs = {
  input: GetTimeOffRequestByScopeInput;
};

export type QueryGetTotalUnbatchedTransactionPaymentsArgs = {
  input?: InputMaybe<GetTotalUnbatchedTransactionPaymentsInput>;
};

export type QueryGetTransactionArgs = {
  input?: InputMaybe<GetTransactionInput>;
};

export type QueryGetTransactionDiscountsArgs = {
  transactionId: Scalars['ID'];
};

export type QueryGetTransactionReportArgs = {
  input: TransactionReportInput;
};

export type QueryGetTransactionTotalArgs = {
  input: GetTransactionTotalInput;
};

export type QueryGetTransactionTotalByTransactionTypeReportArgs = {
  input: GetTransactionByTransactionTypeTotalInput;
};

export type QueryGetTransactionTotalReportArgs = {
  input: GetTransactionTotalInput;
};

export type QueryGetTransactionTypeAuthorizationRolesArgs = {
  input: TransactionTypeAuthorizationRolesSearchInput;
};

export type QueryGetTransactionTypesArgs = {
  businessId: Scalars['ID'];
  category?: InputMaybe<Scalars['String']>;
  centerId?: InputMaybe<Scalars['ID']>;
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  manualTransactionTypesOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetTransactionsInTimeframeArgs = {
  end: Scalars['Date'];
  paymentTransactionsOnly?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['Date'];
};

export type QueryGetTransitionStatementsArgs = {
  input: GetTransitionStatementsInput;
};

export type QueryGetUnbatchedTransactionPaymentsArgs = {
  input?: InputMaybe<GetUnbatchedTransactionPaymentsInput>;
};

export type QueryGetUnbilledTransactionsForAccountArgs = {
  accountId: Scalars['ID'];
  date?: InputMaybe<Scalars['Date']>;
};

export type QueryGetUnitArgs = {
  input: GetUnitInput;
};

export type QueryGetUnlinkedEnrolmentsForAccountArgs = {
  accountId: Scalars['ID'];
  centerId: Scalars['ID'];
};

export type QueryGetUnreadMessagesArgs = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  classId?: InputMaybe<Scalars['ID']>;
  staffId: Scalars['ID'];
};

export type QueryGetWeeklyAttendanceReportArgs = {
  input: WeeklyAttendanceReportInput;
};

export type QueryGetWeeklyPayrollReportArgs = {
  input: GetWeeklyPayrollInput;
};

export type QueryGetWeeklySubsidySummariesArgs = {
  input: SubsidyWeeklySummaryInput;
};

export type QueryIsCustomFieldInUseByFormArgs = {
  businessId: Scalars['ID'];
  customFieldId: Scalars['ID'];
};

export type QueryKisiAuthorizeArgs = {
  businessId: Scalars['ID'];
  personId: Scalars['ID'];
};

export type QueryListEducationPersonsArgs = {
  input: ListPersonsInput;
};

export type QueryOnboardingCompletedForPersonArgs = {
  businessId: Scalars['String'];
  email: Scalars['String'];
};

export type QueryReadAccountTransactionsArgs = {
  input?: InputMaybe<ReadAccountTransactionsInput>;
};

export type QuerySearchAccountsArgs = {
  input: SearchInput;
};

export type QuerySearchCentersArgs = {
  input: SearchInput;
};

export type QuerySearchChildAtCenterArgs = {
  centerId: Scalars['String'];
  text: Scalars['String'];
};

export type QuerySearchChildrenArgs = {
  input: SearchInput;
};

export type QuerySearchClassesArgs = {
  input: SearchInput;
};

export type QuerySearchCommentArgs = {
  input: SearchCommentInput;
};

export type QuerySearchContactsArgs = {
  input: SearchInput;
};

export type QuerySearchContactsV2Args = {
  input: SearchContactsV2Input;
};

export type QuerySearchEducationAttachmentsArgs = {
  input: SearchEducationAttachmentsInput;
};

export type QuerySearchEnrollmentFormArgs = {
  businessId: Scalars['ID'];
  input: SearchEnrollmentFormInput;
};

export type QuerySearchFeesArgs = {
  input: SearchInput;
};

export type QuerySearchFees2Args = {
  input?: InputMaybe<SearchFees2Input>;
};

export type QuerySearchFolderArgs = {
  input: FolderSearchInput;
};

export type QuerySearchGroupArgs = {
  input: SearchGroupInput;
};

export type QuerySearchGroupsAndUsersArgs = {
  input: SearchGroupsAndUsersInput;
};

export type QuerySearchLibraryArgs = {
  input: LibrarySearchInput;
};

export type QuerySearchMessagingAttachmentsArgs = {
  businessId: Scalars['ID'];
  input?: InputMaybe<SearchMessageAttachmentsInput>;
};

export type QuerySearchNoteArgs = {
  input: SearchNoteInput;
};

export type QuerySearchPeopleByPhoneNumberArgs = {
  centerId: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type QuerySearchPhotoArgs = {
  input: SearchPhotoInput;
};

export type QuerySearchPtoTypeArgs = {
  input?: InputMaybe<SearchPtoTypesInput>;
};

export type QuerySearchRoyaltyFeeArgs = {
  input: SearchRoyaltyFeeInput;
};

export type QuerySearchSchedulableStaffArgs = {
  centerId: Scalars['ID'];
};

export type QuerySearchStaffArgs = {
  input: SearchInput;
};

export type QuerySearchSubsidyCenterRatingsArgs = {
  input: SearchSubsidyCenterRatingInput;
};

export type QuerySearchTimeCardLockArgs = {
  input?: InputMaybe<SearchTimeCardLocksInput>;
};

export type QuerySendEmailArgs = {
  input: SendEmailInput;
};

export type QuerySendEmailTakenEmailArgs = {
  input: SendEmailTakenEmailInput;
};

export type QueryValidateCrnArgs = {
  businessId: Scalars['ID'];
  crn?: InputMaybe<Scalars['String']>;
};

export type QueryVerifyPinArgs = {
  input?: InputMaybe<VerifyPinInput>;
};

export enum RateAmountType {
  Flat = 'FLAT',
  Percentage = 'PERCENTAGE',
}

export enum RatingType {
  Seifa = 'SEIFA',
}

export type ReEnrolAccountCorrespondenceMeta = {
  __typename?: 'ReEnrolAccountCorrespondenceMeta';
  enrolmentFormAccountDataRecordId?: Maybe<Scalars['String']>;
  enrolmentFormAccountId?: Maybe<Scalars['String']>;
  linkCode: Scalars['String'];
  relatedApplication?: Maybe<RelatedApplication>;
  relatedApplicationId?: Maybe<Scalars['String']>;
};

export type ReEnrolBusinessSettings = {
  __typename?: 'ReEnrolBusinessSettings';
  centerCount: Scalars['Int'];
  entityId: Scalars['String'];
  usesEnrolmentForm: Scalars['Boolean'];
  usesParentApplication: Scalars['Boolean'];
  usesReEnrol: Scalars['Boolean'];
};

export type ReEnrolCenterSettings = {
  __typename?: 'ReEnrolCenterSettings';
  businessUsesReEnrol: Scalars['Boolean'];
  centerId: Scalars['String'];
  centerName: Scalars['String'];
  entityId: Scalars['String'];
  fromDefault: Scalars['Boolean'];
  usesEnrolmentForm: Scalars['Boolean'];
  usesParentApplication: Scalars['Boolean'];
};

export type ReEnrollChild = {
  __typename?: 'ReEnrollChild';
  accountChildId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  archivedAt?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Maybe<ReenrollContract>>>;
  dob?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastname?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
};

export type ReEnrollmentAccount = {
  __typename?: 'ReEnrollmentAccount';
  accountBalanceAmount?: Maybe<Scalars['Float']>;
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  accountStatus: AccountStatusType;
  centerId: Scalars['String'];
  lastContactedDate?: Maybe<Scalars['String']>;
  stage?: Maybe<Stage>;
};

export type ReEnrollmentCloseResponse = {
  __typename?: 'ReEnrollmentCloseResponse';
  totalRequestsClosed: Scalars['Int'];
};

export type ReEnrollmentGetAccountsResponsePagedResult = {
  __typename?: 'ReEnrollmentGetAccountsResponsePagedResult';
  data?: Maybe<Array<ReEnrollmentAccount>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type ReEnrollmentGetHistoryofActionsResponsePagedResult = {
  __typename?: 'ReEnrollmentGetHistoryofActionsResponsePagedResult';
  data?: Maybe<Array<ReEnrollmentHistory>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type ReEnrollmentHistory = {
  __typename?: 'ReEnrollmentHistory';
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  batchId: Scalars['ID'];
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  completedBy?: Maybe<Scalars['String']>;
  completedByName?: Maybe<Scalars['String']>;
  correspondenceType?: Maybe<CorrespondenceType>;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  createdByName?: Maybe<Scalars['String']>;
  declineReason?: Maybe<ReenrollmentDeclineReason>;
  declinedAt?: Maybe<Scalars['String']>;
  declinedBy?: Maybe<Scalars['String']>;
  declinedByName?: Maybe<Scalars['String']>;
  linkCode?: Maybe<Scalars['String']>;
  otherReason?: Maybe<Scalars['String']>;
  stage?: Maybe<Stage>;
  stageLastModifiedAt?: Maybe<Scalars['String']>;
  userFriendlyBatchId: Scalars['String'];
};

export type ReactivateStaffInput = {
  employmentStartDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  primaryCenterId?: InputMaybe<Scalars['ID']>;
  roleId: Scalars['String'];
  scopeIds: Array<Scalars['String']>;
  scopeType: ScopeType;
};

export type ReadAccountTransactionsInput = {
  accountId: Scalars['ID'];
  childrenIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  maxDate?: InputMaybe<Scalars['Date']>;
  minDate?: InputMaybe<Scalars['Date']>;
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  transactionTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export enum ReasonForEndingContract {
  AccountDebt = 'ACCOUNT_DEBT',
  AddingDays = 'ADDING_DAYS',
  CareNotRequired = 'CARE_NOT_REQUIRED',
  CenterDismissal = 'CENTER_DISMISSAL',
  ChangingToCasual = 'CHANGING_TO_CASUAL',
  ChangingToPermanent = 'CHANGING_TO_PERMANENT',
  ContractChange = 'CONTRACT_CHANGE',
  Covid = 'COVID',
  DecreasingDays = 'DECREASING_DAYS',
  EndingCare = 'ENDING_CARE',
  ExtendedHolidays = 'EXTENDED_HOLIDAYS',
  ExtendedMedical = 'EXTENDED_MEDICAL',
  ExtendedTimeOff = 'EXTENDED_TIME_OFF',
  GoingToKindergarten = 'GOING_TO_KINDERGARTEN',
  GoingToSchool = 'GOING_TO_SCHOOL',
  LeaveCenter = 'LEAVE_CENTER',
  MovingAway = 'MOVING_AWAY',
  ReEnrollment = 'RE_ENROLLMENT',
  RoomChange = 'ROOM_CHANGE',
  TemporaryEnrollment = 'TEMPORARY_ENROLLMENT',
}

export enum ReasonForEndingType {
  AccountDebt = 'ACCOUNT_DEBT',
  AddingDays = 'ADDING_DAYS',
  CareNotRequired = 'CARE_NOT_REQUIRED',
  ChangingToCasual = 'CHANGING_TO_CASUAL',
  ChangingToPermanent = 'CHANGING_TO_PERMANENT',
  ContractChange = 'CONTRACT_CHANGE',
  Covid = 'COVID',
  DecreasingDays = 'DECREASING_DAYS',
  EndingCare = 'ENDING_CARE',
  ExtendedHolidays = 'EXTENDED_HOLIDAYS',
  GoingToKindergarten = 'GOING_TO_KINDERGARTEN',
  GoingToSchool = 'GOING_TO_SCHOOL',
  LeaveCenter = 'LEAVE_CENTER',
  MovingAway = 'MOVING_AWAY',
  RoomChange = 'ROOM_CHANGE',
  TemporaryEnrollment = 'TEMPORARY_ENROLLMENT',
}

export enum ReasonForLeaving {
  Dissatisfied = 'dissatisfied',
  GoingBackToSchool = 'goingBackToSchool',
  LeaveOfAbsence = 'leaveOfAbsence',
  Moving = 'moving',
  NewJob = 'newJob',
  NoCallNoShow = 'noCallNoShow',
  Resigned = 'resigned',
  StayingHome = 'stayingHome',
  Temporary = 'temporary',
  Terminated = 'terminated',
  WalkOut = 'walkOut',
}

export enum RecordStatus {
  C = 'C',
  P = 'P',
}

export type ReenrollContract = {
  __typename?: 'ReenrollContract';
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
};

export type ReenrollmentCloseRequestInput = {
  historyFilterBy: ReenrollmentGetHistoryOfActionsFilterBy;
  includeAccountCorrespondenceIds?: InputMaybe<Array<SimplifiedAccountCorrespondence>>;
  includeAllAccountCorrespondenceIds?: InputMaybe<Scalars['Boolean']>;
};

export enum ReenrollmentDeclineReason {
  NotInterested = 'NOT_INTERESTED',
  NotReturning = 'NOT_RETURNING',
  Other = 'OTHER',
  OutstandingRequest = 'OUTSTANDING_REQUEST',
  UpToDate = 'UP_TO_DATE',
}

export type ReenrollmentGetAccountContractsRequestInput = {
  accountId: Scalars['String'];
  businessId: Scalars['String'];
};

export type ReenrollmentGetAccountsFilterBy = {
  accountNameSearchTerm?: InputMaybe<Scalars['String']>;
  accountStatuses?: InputMaybe<Array<InputMaybe<AccountStatusType>>>;
  asOfDate: Scalars['String'];
  centerIds: Array<Scalars['String']>;
  excludeAccountIds?: InputMaybe<Array<Scalars['ID']>>;
  includeAccountIds?: InputMaybe<Array<Scalars['ID']>>;
  includeAllAccountIds?: InputMaybe<Scalars['Boolean']>;
  stage?: InputMaybe<Stage>;
};

export type ReenrollmentGetAccountsRequestInput = {
  businessId: Scalars['String'];
  filter?: InputMaybe<ReenrollmentGetAccountsFilterBy>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
};

export type ReenrollmentGetHistoryOfActionsFilterBy = {
  centerIds: Array<Scalars['String']>;
  correspondenceTypes?: InputMaybe<Array<InputMaybe<CorrespondenceType>>>;
  endDate?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  stages?: InputMaybe<Array<Stage>>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type ReenrollmentSendRequestInput = {
  businessId: Scalars['String'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  filter?: InputMaybe<ReenrollmentGetAccountsFilterBy>;
  usesEnrolmentFormOverride?: InputMaybe<Scalars['Boolean']>;
};

export type Regulation = {
  __typename?: 'Regulation';
  capacity?: Maybe<Scalars['Int']>;
  county?: Maybe<Scalars['String']>;
  endAge: RegulationAge;
  id: Scalars['ID'];
  ratioChildren: Scalars['Int'];
  ratioTeachers: Scalars['Int'];
  regulationOverride?: Maybe<RegulationOverride>;
  startAge: RegulationAge;
  state: Scalars['String'];
};

export type RegulationAge = {
  __typename?: 'RegulationAge';
  age: Scalars['Float'];
  unit: RegulationAgeUnit;
};

export type RegulationAgeInput = {
  age: Scalars['Float'];
  unit: RegulationAgeUnit;
};

export enum RegulationAgeUnit {
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type RegulationOverride = {
  __typename?: 'RegulationOverride';
  capacity?: Maybe<Scalars['Int']>;
  endAge: RegulationAge;
  oldestEndAge?: Maybe<RegulationAge>;
  ratioChildren: Scalars['Int'];
  ratioTeachers: Scalars['Int'];
  startAge: RegulationAge;
  youngestStartAge?: Maybe<RegulationAge>;
};

export type RegulationOverrideInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  endAge: RegulationAgeInput;
  oldestEndAge?: InputMaybe<RegulationAgeInput>;
  ratioChildren: Scalars['Int'];
  ratioTeachers: Scalars['Int'];
  startAge: RegulationAgeInput;
  youngestStartAge?: InputMaybe<RegulationAgeInput>;
};

export type RelatedApplication = {
  __typename?: 'RelatedApplication';
  appliedFlowType?: Maybe<ApplicationFlowType>;
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['String']>;
  relatedApplicationChildren: Array<RelatedApplicationChildren>;
  relatedApplicationOffers?: Maybe<Array<RelatedApplicationOffers>>;
  relatedApplicationScheduleOffers?: Maybe<Array<RelatedApplicationScheduleOffers>>;
  state?: Maybe<ApplicationState>;
};

export type RelatedApplicationChildren = {
  __typename?: 'RelatedApplicationChildren';
  applicationChildId: Scalars['ID'];
  childFullName: Scalars['String'];
};

export type RelatedApplicationOffers = {
  __typename?: 'RelatedApplicationOffers';
  applicationScheduleOfferIds?: Maybe<Array<Scalars['String']>>;
  applicationState?: Maybe<ApplicationState>;
  centerId?: Maybe<Scalars['String']>;
  enrolmentFormDataRecordId?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  linkCode?: Maybe<Scalars['String']>;
};

export type RelatedApplicationScheduleOffers = {
  __typename?: 'RelatedApplicationScheduleOffers';
  IisWaitListOffered?: Maybe<Scalars['Boolean']>;
  applicationChildId: Scalars['ID'];
  applicationOfferId?: Maybe<Scalars['ID']>;
  applicationScheduleOfferState?: Maybe<ApplicationScheduleOfferState>;
  id: Scalars['ID'];
};

export enum RelationshipEnum {
  Caretaker = 'Caretaker',
  FamilyFriend = 'FamilyFriend',
  Father = 'Father',
  FosterParent = 'FosterParent',
  Grandfather = 'Grandfather',
  Grandmother = 'Grandmother',
  Grandparent = 'Grandparent',
  LegalGuardian = 'LegalGuardian',
  Mother = 'Mother',
  Parent = 'Parent',
  Relative = 'Relative',
  Sibling = 'Sibling',
  StepParent = 'StepParent',
  Stepfather = 'Stepfather',
  Stepmother = 'Stepmother',
}

export enum RelationshipType {
  Caretaker = 'Caretaker',
  FamilyFriend = 'FamilyFriend',
  FosterParent = 'FosterParent',
  Grandparent = 'Grandparent',
  LegalGuardian = 'LegalGuardian',
  Parent = 'Parent',
  Relative = 'Relative',
  Sibling = 'Sibling',
  StepParent = 'StepParent',
}

export type RemoveDiscountFromTransactionInput = {
  discountTransactionId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type RemoveEducationPermissionFromGroupInput = {
  groupId: Scalars['String'];
  permissionId: Scalars['String'];
};

export type RemoveEmployeeFromTrainingTimeEventInput = {
  employeeId: Scalars['ID'];
  trainingTimeEventId: Scalars['ID'];
};

export type RemoveFolderInput = {
  folderToRemoveId: Scalars['ID'];
  force?: InputMaybe<Scalars['Boolean']>;
  parentFolderId: Scalars['ID'];
};

export type RemoveLocationInput = {
  centerId: Scalars['ID'];
  id: Scalars['ID'];
};

export type RemoveResourceInput = {
  folderId: Scalars['ID'];
  resourceId: Scalars['ID'];
};

export type ReorderInput = {
  id: Scalars['ID'];
  order: Scalars['Int'];
};

export type ReorderProgramThemeExperiencesInput = {
  businessId: Scalars['ID'];
  curriculumScheduleId: Scalars['ID'];
  date: Scalars['DateTime'];
  experiences: Array<ReorderInput>;
  programThemeId: Scalars['ID'];
};

export type ReorderThemesInput = {
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  themes: Array<ReorderInput>;
  unitId: Scalars['ID'];
};

export type ReorderUnitsInput = {
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  units: Array<ReorderInput>;
};

export type Report = {
  __typename?: 'Report';
  categoryType?: Maybe<ReportCategoryType>;
  formatTypes?: Maybe<Array<Maybe<ReportFormatType>>>;
  lastGeneratedOn?: Maybe<Scalars['String']>;
  type?: Maybe<ReportType>;
};

export type ReportAbsenceData = {
  __typename?: 'ReportAbsenceData';
  failed: Array<AbsenceFailure>;
  successful: Array<Absence>;
};

export type ReportAbsenceInput = {
  children: Array<ReportChildAbsentInput>;
  contactId?: InputMaybe<Scalars['ID']>;
};

export type ReportAdditionalAbsenceInput = {
  absenceDocumentHeld?: InputMaybe<Scalars['Boolean']>;
  accountChildId: Scalars['ID'];
  dates: Array<Scalars['Date']>;
  reason?: InputMaybe<AbsenceReason>;
};

export type ReportBulkAdditionalAbsenceInput = {
  absenceDocumentHeld?: InputMaybe<Scalars['Boolean']>;
  accountChildIds: Array<Scalars['ID']>;
  dates: Array<Scalars['Date']>;
  reason?: InputMaybe<AbsenceReason>;
};

export enum ReportCategoryType {
  Attendance = 'ATTENDANCE',
  ChildSchedule = 'CHILD_SCHEDULE',
  Families = 'FAMILIES',
  Finance = 'FINANCE',
  Generic = 'GENERIC',
}

export type ReportChildAbsentInput = {
  absenceDocumentHeld?: InputMaybe<Scalars['Boolean']>;
  absenceType?: InputMaybe<AbsenceType>;
  contractId?: InputMaybe<Scalars['ID']>;
  date: Scalars['Date'];
  reason?: InputMaybe<AbsenceReason>;
  sessionId?: InputMaybe<Scalars['ID']>;
};

export enum ReportFormatType {
  Excel = 'EXCEL',
  Pdf = 'PDF',
}

export enum ReportInterval {
  Month = 'MONTH',
  Week = 'WEEK',
}

export type ReportMetaData = {
  __typename?: 'ReportMetaData';
  categoryType: ReportCategoryType;
  generatedBy: Scalars['String'];
  generatedOn: Scalars['String'];
  params?: Maybe<Array<Maybe<ReportParams>>>;
  type: ReportType;
};

export type ReportParams = {
  __typename?: 'ReportParams';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum ReportType {
  AccountContactInformation = 'ACCOUNT_CONTACT_INFORMATION',
  Accs = 'ACCS',
  AdpWeeklyPayroll = 'ADP_WEEKLY_PAYROLL',
  Attendance = 'ATTENDANCE',
  AttendanceTotalsPerWeek = 'ATTENDANCE_TOTALS_PER_WEEK',
  BalanceAtDate = 'BALANCE_AT_DATE',
  BatchPayment = 'BATCH_PAYMENT',
  BillingEdits = 'BILLING_EDITS',
  BillingSummary = 'BILLING_SUMMARY',
  CcsEnrolments = 'CCS_ENROLMENTS',
  CcsEntitlements = 'CCS_ENTITLEMENTS',
  CcsSubsidy = 'CCS_SUBSIDY',
  CcsSubsidyRemittance = 'CCS_SUBSIDY_REMITTANCE',
  ChildAttendanceSummary = 'CHILD_ATTENDANCE_SUMMARY',
  ChildCheckIn = 'CHILD_CHECK_IN',
  ChildHealthAndWellness = 'CHILD_HEALTH_AND_WELLNESS',
  ChildLastAttendance = 'CHILD_LAST_ATTENDANCE',
  ChildTracking = 'CHILD_TRACKING',
  ClassAttendanceRoster = 'CLASS_ATTENDANCE_ROSTER',
  Contract = 'CONTRACT',
  ContractEdits = 'CONTRACT_EDITS',
  ContractFeeDetailedOverview = 'CONTRACT_FEE_DETAILED_OVERVIEW',
  ContractFeeOverview = 'CONTRACT_FEE_OVERVIEW',
  CustomFieldReport = 'CUSTOM_FIELD_REPORT',
  Debt = 'DEBT',
  DepositsHeld = 'DEPOSITS_HELD',
  DisbursementsMonthly = 'DISBURSEMENTS_MONTHLY',
  EmailAddress = 'EMAIL_ADDRESS',
  EmergencyContacts = 'EMERGENCY_CONTACTS',
  EmployeeTimesheet = 'EMPLOYEE_TIMESHEET',
  EndedContracts = 'ENDED_CONTRACTS',
  Excursion = 'EXCURSION',
  FatZebraTransactions = 'FAT_ZEBRA_TRANSACTIONS',
  IsPayments = 'IS_PAYMENTS',
  MealTracking = 'MEAL_TRACKING',
  NameToFace = 'NAME_TO_FACE',
  NewContracts = 'NEW_CONTRACTS',
  OccupancyTotals = 'OCCUPANCY_TOTALS',
  OutstandingCcs = 'OUTSTANDING_CCS',
  Payment = 'PAYMENT',
  QldCensus = 'QLD_CENSUS',
  QuarterHourCheckIn = 'QUARTER_HOUR_CHECK_IN',
  ReenrolHistory = 'REENROL_HISTORY',
  Royalty = 'ROYALTY',
  SageCash = 'SAGE_CASH',
  SageGl = 'SAGE_GL',
  SageGlDetails = 'SAGE_GL_DETAILS',
  SessionFeesAndTimes = 'SESSION_FEES_AND_TIMES',
  SignInOut = 'SIGN_IN_OUT',
  Transaction = 'TRANSACTION',
  TransactionTotalsByTransactionTypePerMonth = 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH',
  TransactionTotalsByTransactionTypePerWeek = 'TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_WEEK',
  TransactionTotalsPerMonth = 'TRANSACTION_TOTALS_PER_MONTH',
  TransactionTotalsPerWeek = 'TRANSACTION_TOTALS_PER_WEEK',
  WeeklyAttendance = 'WEEKLY_ATTENDANCE',
  WeeklyPayroll = 'WEEKLY_PAYROLL',
}

export type RequestAvailabilityDayChangeInput = {
  dayOfWeek: DayOfWeek;
  times: Array<RequestAvailabilityDayTimesInput>;
};

export type RequestAvailabilityDayTimesInput = {
  end: Scalars['Time'];
  start: Scalars['Time'];
};

export type RequestAvailabityChangeForMeInput = {
  days: Array<RequestAvailabilityDayChangeInput>;
};

export type RequestToJoinCenterViaCodeInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
};

export type RequestUpdateEnrolmentFormInput = {
  businessId: Scalars['ID'];
  filter: ReenrollmentGetAccountsFilterBy;
};

export type ResetReEnrolCenterSettingsInput = {
  businessId: Scalars['String'];
  centerId: Scalars['String'];
};

export type RestrictedResourceType = {
  __typename?: 'RestrictedResourceType';
  extensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  mimeType?: Maybe<Scalars['String']>;
};

export type Restriction = {
  __typename?: 'Restriction';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedByPerson?: Maybe<Staff>;
  childId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByPerson?: Maybe<Staff>;
  description?: Maybe<Scalars['String']>;
  documents: Array<WellnessItemDocument>;
  id: Scalars['ID'];
  importance: Risk;
  isRevised?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  otheType?: Maybe<Scalars['String']>;
  type: HealthTerm;
  typeId: Scalars['ID'];
};

export type RestrictionInput = {
  description?: InputMaybe<Scalars['String']>;
  documentation?: InputMaybe<Array<ChildMedicalDocumentationInput>>;
  importance: Risk;
  name: Scalars['String'];
  otherType?: InputMaybe<Scalars['String']>;
  typeId: Scalars['String'];
};

export type RestrictionRevision = {
  __typename?: 'RestrictionRevision';
  description?: Maybe<Scalars['String']>;
  documentsChanged?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  importance?: Maybe<Risk>;
  restrictionVersion?: Maybe<Restriction>;
  revisedAt?: Maybe<Scalars['DateTime']>;
  revisedByPerson?: Maybe<Staff>;
};

export type ReturnDepositInput = {
  accountId: Scalars['ID'];
  appliesDate: Scalars['Date'];
  description?: InputMaybe<Scalars['String']>;
  returnDeposits: Array<AccountDepositSummaryInput>;
};

export type ReturnFeeReduction = {
  __typename?: 'ReturnFeeReduction';
  amountNotPassedOnToIndividual: Scalars['String'];
  amountPassedOnToIndividual: Scalars['String'];
  enrolmentId: Scalars['ID'];
  isCancelled: Scalars['Boolean'];
  returnFeeReductionId: Scalars['String'];
  returnFeeReductionReason: Scalars['String'];
  sessionReportStartDate: Scalars['String'];
  totalFeeReductionAmountForWeek: Scalars['String'];
};

export type ReturnFeeReductionInput = {
  amountNotPassedOnToIndividual: Scalars['String'];
  amountPassedOnToIndividual: Scalars['String'];
  enrolmentId: Scalars['ID'];
  returnFeeReductionReason: Scalars['String'];
  sessionReportStartDate: Scalars['String'];
  totalFeeReductionAmountForWeek: Scalars['String'];
};

export type RevealEndCareSessionsInput = {
  accountChildId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export enum Risk {
  AwarenessOnly = 'AwarenessOnly',
  High = 'High',
  Low = 'Low',
  Moderate = 'Moderate',
  None = 'None',
}

export enum RiskReason {
  Abpast = 'ABPAST',
  Abused = 'ABUSED',
  Costb = 'COSTB',
  Domvio = 'DOMVIO',
  Neglec = 'NEGLEC',
  Negpst = 'NEGPST',
  Sabuse = 'SABUSE',
  Sapast = 'SAPAST',
  Vipast = 'VIPAST',
}

export type Role = {
  __typename?: 'Role';
  areaLevels: Array<RoleAreaLevel>;
  assignedStaffIds: Array<Scalars['ID']>;
  businessId: Scalars['String'];
  hasAllPermissions: Scalars['Boolean'];
  hierarchyLevel: Scalars['Int'];
  id: Scalars['String'];
  isAccountOwnerRole: Scalars['Boolean'];
  isIntegrationRole?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  scheduleVisibility?: Maybe<Scalars['Boolean']>;
  scopeType: ScopeType;
  staff: Array<Staff>;
};

export type RoleAreaLevel = {
  __typename?: 'RoleAreaLevel';
  area: Scalars['String'];
  level: Scalars['Int'];
  permission: Scalars['String'];
  roleId: Scalars['ID'];
};

export type RoleTransactionTypeShape = {
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  transactionTypeId: Scalars['ID'];
};

export type Roleship = {
  __typename?: 'Roleship';
  name: Scalars['String'];
  permissions: Array<Permission>;
  roleId: Scalars['String'];
  scopeType: ScopeType;
  scopes: Array<Scope>;
};

export type RoyaltyFee = {
  __typename?: 'RoyaltyFee';
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  percentage?: Maybe<Scalars['Float']>;
  royaltyExceptions?: Maybe<Array<Maybe<RoyaltyFeeException>>>;
};

export type RoyaltyFeeConfig = {
  __typename?: 'RoyaltyFeeConfig';
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  includeFees: Scalars['Boolean'];
  includeTransactionDebit: Scalars['Boolean'];
};

export type RoyaltyFeeException = {
  __typename?: 'RoyaltyFeeException';
  id: Scalars['ID'];
  name: Scalars['String'];
  overridePercentage?: Maybe<Scalars['Float']>;
  participantCenterIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantCenters?: Maybe<Array<Maybe<Center>>>;
  royaltyFeeId: Scalars['ID'];
};

export type RoyaltyFeePagedResult = {
  __typename?: 'RoyaltyFeePagedResult';
  data: Array<Maybe<RoyaltyFee>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type RoyaltyReportInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDate: Scalars['Date'];
  includeSubsidyPayments: Scalars['Boolean'];
  startDate: Scalars['Date'];
};

export type S3SignedUrl = {
  __typename?: 'S3SignedUrl';
  fields?: Maybe<Scalars['JSON']>;
  provisionalGetUrl?: Maybe<Scalars['URL']>;
  url: Scalars['URL'];
};

export type S3SignedUrlWithResourceId = {
  __typename?: 'S3SignedUrlWithResourceId';
  fields?: Maybe<Scalars['JSON']>;
  provisionalGetUrl?: Maybe<Scalars['URL']>;
  resourceId?: Maybe<Scalars['ID']>;
  url: Scalars['URL'];
};

export type SsoLoginElementsInput = {
  clientId: Scalars['String'];
  domain: Scalars['String'];
  idpIdentifier: Scalars['String'];
  redirectSignIn: Scalars['String'];
};

export type SageGlDetailsReportData = {
  __typename?: 'SageGLDetailsReportData';
  accountName: Scalars['String'];
  adjustment?: Maybe<Scalars['Float']>;
  adjustmentFeeName?: Maybe<Scalars['String']>;
  adjustmentGlCode?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  billedFeeName?: Maybe<Scalars['String']>;
  centerName: Scalars['String'];
  child?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  glCode?: Maybe<Scalars['String']>;
  originalCost?: Maybe<Scalars['Float']>;
  originalSessionFeeName?: Maybe<Scalars['String']>;
  transactionNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SageGlDetailsReport = {
  __typename?: 'SageGlDetailsReport';
  count: Scalars['Int'];
  endDate: Scalars['String'];
  rows: Array<Maybe<SageGlDetailsReportData>>;
  startDate: Scalars['String'];
};

export type SageGlImportInput = {
  centerIds: Array<InputMaybe<Scalars['ID']>>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type SageGlReport = {
  __typename?: 'SageGlReport';
  endDate: Scalars['String'];
  records: Array<Maybe<SageGlReportData>>;
  startDate: Scalars['String'];
};

export type SageGlReportData = {
  __typename?: 'SageGlReportData';
  amount: Scalars['Float'];
  centerName: Scalars['String'];
  date: Scalars['String'];
  glCode?: Maybe<Scalars['String']>;
};

export type SaveServiceFeeGroupInput = {
  assignedCenters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  serviceFees?: InputMaybe<Array<InputMaybe<ServiceFeeInput>>>;
};

export type Schedule = {
  __typename?: 'Schedule';
  centerId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  publishStatus: SchedulePublishStatus;
  shifts: Array<Shift>;
  startTime: Scalars['DateTime'];
  timeEntries?: Maybe<Array<Maybe<TimeEntry>>>;
};

export type ScheduleAvailability = {
  __typename?: 'ScheduleAvailability';
  approved?: Maybe<Array<SingleDayAvailability>>;
  requested?: Maybe<Array<SingleDayAvailability>>;
};

export type ScheduleExperiencesForCurriculumScheduleProgramTheme = {
  businessId: Scalars['ID'];
  curriculumScheduleId: Scalars['ID'];
  date: Scalars['DateTime'];
  experienceIds: Array<Scalars['ID']>;
  programThemeId: Scalars['ID'];
};

export enum SchedulePublishStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type ScheduledNotification = {
  __typename?: 'ScheduledNotification';
  centerID?: Maybe<Scalars['ID']>;
  entityID: Scalars['ID'];
  id: Scalars['ID'];
  sent: Scalars['Boolean'];
  shiftId: Scalars['ID'];
  timeToTrigger: Scalars['DateTime'];
};

export type Scope = Center | Entity;

export enum ScopeType {
  Center = 'CENTER',
  Entity = 'ENTITY',
}

/** For development use only! */
export type Scopes = {
  __typename?: 'Scopes';
  center?: Maybe<Array<Scalars['ID']>>;
  entity?: Maybe<Array<Scalars['ID']>>;
};

export type ScramCredentials = {
  __typename?: 'ScramCredentials';
  online_certificate?: Maybe<Scalars['String']>;
  phone_key?: Maybe<Scalars['String']>;
};

export type SearchCommentInput = {
  attachedToEntityId: Scalars['ID'];
  businessId: Scalars['ID'];
  from?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  parentCommentId?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
};

export type SearchContactsV2Filters = {
  businessIds: Array<Scalars['ID']>;
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  centerTagIds?: InputMaybe<Array<Scalars['ID']>>;
  contactTagIds?: InputMaybe<Array<Scalars['ID']>>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type SearchContactsV2Input = {
  filters: SearchContactsV2Filters;
  pagination: SearchContactsV2Pagination;
  sortLastNameAsc?: InputMaybe<Scalars['Boolean']>;
};

export type SearchContactsV2PagedResult = {
  __typename?: 'SearchContactsV2PagedResult';
  data: Array<Contact>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type SearchContactsV2Pagination = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type SearchEducationAttachmentsInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  term: Scalars['String'];
};

export type SearchEnrollmentFormInput = {
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  includeNoCenter?: InputMaybe<Scalars['Boolean']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<SearchSort>>;
};

export type SearchExpression = {
  all?: InputMaybe<Array<SearchExpression>>;
  any?: InputMaybe<Array<SearchExpression>>;
  term?: InputMaybe<SearchFilter>;
};

export type SearchFees2Input = {
  centerId: Scalars['ID'];
  classIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  showActive?: InputMaybe<Scalars['Boolean']>;
  showDeactivated?: InputMaybe<Scalars['Boolean']>;
};

export type SearchFilter = {
  field: Scalars['String'];
  predicate: SearchPredicate;
  value?: InputMaybe<Scalars['JSON']>;
};

export type SearchGroupInput = {
  count?: InputMaybe<Scalars['Int']>;
  groupTypes?: InputMaybe<Array<InputMaybe<GroupType>>>;
  name?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<GroupSortDirection>;
  sortField?: InputMaybe<GroupSortField>;
  start?: InputMaybe<Scalars['Int']>;
};

export type SearchGroupsAndUsersInput = {
  count?: InputMaybe<Scalars['Int']>;
  groupTypes?: InputMaybe<Array<InputMaybe<GroupType>>>;
  name?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type SearchInput = {
  filter: SearchExpression;
  from: Scalars['Int'];
  size: Scalars['Int'];
  sort: Array<SearchSort>;
};

export type SearchMessageAttachmentsInput = {
  attachmentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  businessId: Scalars['ID'];
  generateDownloadLinks: Scalars['Boolean'];
};

export type SearchNoteInput = {
  areaType: NoteAreas;
  attachedToEntityId: Scalars['ID'];
  businessId: Scalars['ID'];
  from?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
};

export type SearchPeopleByPhoneNumberResults = {
  __typename?: 'SearchPeopleByPhoneNumberResults';
  contacts: Array<Contact>;
  staff: Array<Staff>;
};

export type SearchPhotoInput = {
  Ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  childIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  classIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  from?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isEvaluated?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirection>;
  takenAfter?: InputMaybe<Scalars['String']>;
  takenBefore?: InputMaybe<Scalars['String']>;
};

export enum SearchPredicate {
  Active = 'ACTIVE',
  AfterNow = 'AFTER_NOW',
  BeforeNow = 'BEFORE_NOW',
  Contains = 'CONTAINS',
  Deactivated = 'DEACTIVATED',
  Deleted = 'DELETED',
  DoesNotExist = 'DOES_NOT_EXIST',
  Equals = 'EQUALS',
  Exists = 'EXISTS',
  OneOf = 'ONE_OF',
  Range = 'RANGE',
  StartsWith = 'STARTS_WITH',
}

export type SearchPtoTypesInput = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  from?: InputMaybe<Scalars['Int']>;
  includeBusinessTypes?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  nameCodeContains?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
};

export type SearchRoyaltyFeeInput = {
  businessId: Scalars['ID'];
  from?: InputMaybe<Scalars['Int']>;
  nameContains?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type SearchSort = {
  direction: SortDirection;
  field: Scalars['String'];
};

export type SearchSubsidyCenterRatingInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
};

export type SearchTimeCardLocksInput = {
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  from?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
};

export type SearchUserActivity = {
  __typename?: 'SearchUserActivity';
  activity?: Maybe<UserActivity>;
  flaggedMessages?: Maybe<Array<Maybe<UserActivityFlaggedMessages>>>;
  isMessageDeleted?: Maybe<Scalars['Boolean']>;
  isMessageUnread?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  threadMessages?: Maybe<Array<Maybe<UserActivityThreadMessages>>>;
  userAcknowledgements?: Maybe<Array<Maybe<UserActivityAcknowledgements>>>;
};

export type SearchUserActivityPagedResult = {
  __typename?: 'SearchUserActivityPagedResult';
  data: Array<SearchUserActivity>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos: Array<SortDto>;
  totalRecords: Scalars['Int'];
};

export type SendApplicationOfferInput = {
  applicationId: Scalars['ID'];
  businessId: Scalars['ID'];
  depositAmount?: InputMaybe<Scalars['Float']>;
  depositAppliesDate?: InputMaybe<Scalars['Date']>;
  depositCollected?: InputMaybe<Scalars['Boolean']>;
  scheduleOfferIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type SendEmailInput = {
  emailTemplate: EmailTemplate;
  personId: Scalars['String'];
  scopeId: Scalars['String'];
  scopeType: ScopeType;
};

export type SendEmailTakenEmailInput = {
  email: Scalars['String'];
  isSSO?: InputMaybe<Scalars['Boolean']>;
};

export type SendInvitationInput = {
  entityId: Scalars['ID'];
  personId: Scalars['ID'];
  primaryCenterId?: InputMaybe<Scalars['ID']>;
};

export type ServiceContact = {
  __typename?: 'ServiceContact';
  dateOfEvent: Scalars['Date'];
  email: Scalars['String'];
  mobileNumber: Scalars['String'];
  phoneNumber: Scalars['String'];
  serviceUrl: Scalars['String'];
};

export type ServiceCpav = {
  __typename?: 'ServiceCpav';
  centerClosing?: Maybe<Scalars['String']>;
  centerIsOpen: Scalars['Boolean'];
  centerOpening?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactMobile?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactServiceUrl?: Maybe<Scalars['String']>;
  feeServiceUrl?: Maybe<Scalars['String']>;
  fees?: Maybe<CpavFeeGroup>;
  hasVacancyFri: Scalars['Boolean'];
  hasVacancyMon: Scalars['Boolean'];
  hasVacancySat: Scalars['Boolean'];
  hasVacancySun: Scalars['Boolean'];
  hasVacancyThu: Scalars['Boolean'];
  hasVacancyTue: Scalars['Boolean'];
  hasVacancyWed: Scalars['Boolean'];
  lastSubmitted?: Maybe<Scalars['String']>;
  lastUpdated: Scalars['String'];
  nextAutomatedSubmission?: Maybe<Scalars['String']>;
  openingHours: Array<Maybe<ServiceOperationalDay>>;
};

export type ServiceCpavRequest = {
  feeServiceUrl?: InputMaybe<Scalars['String']>;
  fees?: InputMaybe<CpavFeeGroupRequest>;
  hasVacancyFri: Scalars['Boolean'];
  hasVacancyMon: Scalars['Boolean'];
  hasVacancySat: Scalars['Boolean'];
  hasVacancySun: Scalars['Boolean'];
  hasVacancyThu: Scalars['Boolean'];
  hasVacancyTue: Scalars['Boolean'];
  hasVacancyWed: Scalars['Boolean'];
};

export type ServiceFee = {
  __typename?: 'ServiceFee';
  active?: Maybe<Scalars['Boolean']>;
  amount: Scalars['Float'];
  calculationType?: Maybe<CalculationType>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isNew: Scalars['Boolean'];
  name: Scalars['String'];
  serviceFeeRuleGroups?: Maybe<Array<Maybe<ServiceFeeRuleGroup>>>;
  serviceFeeType?: Maybe<ServiceFeeType>;
  startDate: Scalars['String'];
};

export type ServiceFeeGroup = {
  __typename?: 'ServiceFeeGroup';
  centerCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  serviceFeeGroupCenters?: Maybe<Array<Maybe<ServiceFeeGroupCenter>>>;
  serviceFeeGroupId: Scalars['String'];
  serviceFees?: Maybe<Array<Maybe<ServiceFee>>>;
};

export type ServiceFeeGroupCenter = {
  __typename?: 'ServiceFeeGroupCenter';
  addedBy: Scalars['String'];
  addedDate: Scalars['String'];
  center?: Maybe<Center>;
  centerId: Scalars['String'];
  id: Scalars['ID'];
  serviceFeeGroup?: Maybe<ServiceFeeGroup>;
};

export type ServiceFeeGroupPagedResponse = {
  __typename?: 'ServiceFeeGroupPagedResponse';
  data?: Maybe<Array<Maybe<ServiceFeeGroup>>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type ServiceFeeInput = {
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  calculationType?: InputMaybe<CalculationType>;
  endDate?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isNew: Scalars['Boolean'];
  name: Scalars['String'];
  serviceFeeRuleGroups?: InputMaybe<Array<InputMaybe<ServiceFeeRuleGroupInput>>>;
  serviceFeeType?: InputMaybe<ServiceFeeType>;
  startDate: Scalars['String'];
};

export type ServiceFeeRule = {
  __typename?: 'ServiceFeeRule';
  condition: ConditionType;
  id: Scalars['ID'];
  isNew: Scalars['Boolean'];
  serviceFeeRuleGroupId: Scalars['ID'];
  value: Scalars['String'];
};

export type ServiceFeeRuleGroup = {
  __typename?: 'ServiceFeeRuleGroup';
  id: Scalars['ID'];
  isNew: Scalars['Boolean'];
  matchType: MatchType;
  serviceFeeRuleGroupParent?: Maybe<ServiceFeeRuleGroup>;
  serviceFeeRuleGroupParentId?: Maybe<Scalars['ID']>;
  serviceFeeRuleGroups?: Maybe<Array<Maybe<ServiceFeeRuleGroup>>>;
  serviceFeeRules?: Maybe<Array<Maybe<ServiceFeeRule>>>;
};

export type ServiceFeeRuleGroupInput = {
  id: Scalars['String'];
  isNew: Scalars['Boolean'];
  matchType: MatchType;
  serviceFeeRuleGroupParentId?: InputMaybe<Scalars['String']>;
  serviceFeeRuleGroups?: InputMaybe<Array<InputMaybe<ServiceFeeRuleGroupInput>>>;
  serviceFeeRules?: InputMaybe<Array<InputMaybe<ServiceFeeRuleInput>>>;
};

export type ServiceFeeRuleInput = {
  condition: ConditionType;
  id: Scalars['String'];
  isNew: Scalars['Boolean'];
  serviceFeeRuleGroupId: Scalars['String'];
  value: Scalars['String'];
};

export enum ServiceFeeType {
  Ach = 'ACH',
  Cc = 'CC',
}

export type ServiceFinancialDetails = {
  __typename?: 'ServiceFinancialDetails';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bsb?: Maybe<Scalars['String']>;
  dateOfEvent?: Maybe<Scalars['DateTime']>;
};

export type ServiceLocationOfRecord = {
  __typename?: 'ServiceLocationOfRecord';
  dateOfEvent: Scalars['DateTime'];
  postcode: Scalars['String'];
  serviceId: Scalars['ID'];
  state: Scalars['String'];
  streetLine1: Scalars['String'];
  streetLine2?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
};

export type ServiceNameChangeEvidenceInput = {
  file: Scalars['Upload'];
};

export type ServiceNameChangeLog = {
  __typename?: 'ServiceNameChangeLog';
  endDate: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
};

export type ServiceOperationalDay = {
  __typename?: 'ServiceOperationalDay';
  careType: CpavCareType;
  closeTime?: Maybe<Scalars['String']>;
  openTime?: Maybe<Scalars['String']>;
};

export type ServiceTemporarilyCeasing = {
  __typename?: 'ServiceTemporarilyCeasing';
  endDate: Scalars['DateTime'];
  reason: ServiceTemporarilyCeasingReasonType;
  reasonText: Scalars['String'];
  serviceId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export type ServiceTemporarilyCeasingInput = {
  businessId: Scalars['ID'];
  endDate: Scalars['DateTime'];
  reasonText: Scalars['String'];
  reasonType: ServiceTemporarilyCeasingReasonType;
  serviceId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export enum ServiceTemporarilyCeasingReasonType {
  Asre = 'ASRE',
  Clho = 'CLHO',
  Cycl = 'CYCL',
  Fire = 'FIRE',
  Floo = 'FLOO',
  Infe = 'INFE',
  Lach = 'LACH',
  Last = 'LAST',
  Othe = 'OTHE',
  Ptdy = 'PTDY',
  Quak = 'QUAK',
  Rcdy = 'RCDY',
  Reno = 'RENO',
  Stda = 'STDA',
  Tofu = 'TOFU',
  Uoth = 'UOTH',
  Utin = 'UTIN',
}

export type ServicesByBusiness = {
  __typename?: 'ServicesByBusiness';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  serviceId: Scalars['ID'];
};

export type Session = {
  __typename?: 'Session';
  absence?: Maybe<Absence>;
  accountChild?: Maybe<AccountChild>;
  accountChildId: Scalars['ID'];
  accountId: Scalars['ID'];
  accountName?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<BillingCycle>;
  centerId: Scalars['ID'];
  charged: Scalars['Boolean'];
  child: Child;
  childId: Scalars['ID'];
  class: Class;
  classId: Scalars['ID'];
  contractId?: Maybe<Scalars['ID']>;
  cost?: Maybe<SessionCost>;
  date: Scalars['Date'];
  dropOffTime?: Maybe<Scalars['String']>;
  endOfCareSubsidy: Scalars['Float'];
  existingEnrollmentYearAbsences: Array<Absence>;
  fee: Fee;
  feeEndTime?: Maybe<Scalars['String']>;
  feeId: Scalars['ID'];
  feeStartTime?: Maybe<Scalars['String']>;
  hasEarlyFee: Scalars['Boolean'];
  hasLateFee: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Metadata>;
  nonSubsidized?: Maybe<Scalars['Boolean']>;
  pickUpTime?: Maybe<Scalars['String']>;
  pocState?: Maybe<PocChildState>;
  qualifyingRate?: Maybe<FeeRate>;
  reasonForLateChange?: Maybe<Scalars['String']>;
  source?: Maybe<SessionSource>;
  subsidy?: Maybe<SessionSubsidy>;
  timeEntries: Array<AttendanceTimeEntry>;
  totalTime: TotalTime;
  void?: Maybe<Scalars['Boolean']>;
};

export type SessionExistingEnrollmentYearAbsencesArgs = {
  enrollmentYearEnd?: InputMaybe<Scalars['Date']>;
  enrollmentYearStart?: InputMaybe<Scalars['Date']>;
};

export type SessionAmendmentCreateInput = {
  authorizedByPersonId: Scalars['ID'];
  authorizedPersonIds: Array<Scalars['ID']>;
  businessId: Scalars['ID'];
  expiryDate: Scalars['String'];
  reasonText?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
};

export type SessionAmendmentDto = {
  __typename?: 'SessionAmendmentDto';
  authorizedBy?: Maybe<Staff>;
  authorizedByPersonId: Scalars['ID'];
  authorizedPersonId: Scalars['ID'];
  authorizedStaff?: Maybe<Staff>;
  businessId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expiryDate: Scalars['Date'];
  id?: Maybe<Scalars['ID']>;
  reasonText?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type SessionAmendmentGetBusinessConfigDto = {
  __typename?: 'SessionAmendmentGetBusinessConfigDto';
  editDay?: Maybe<Scalars['Int']>;
  userEditAllowExpiryDate?: Maybe<Scalars['String']>;
};

export type SessionAmendmentGetBusinessConfigInput = {
  businessId: Scalars['ID'];
};

export type SessionAmendmentSearchInput = {
  businessId: Scalars['ID'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
  todayDate?: InputMaybe<Scalars['String']>;
};

export type SessionAmendmentSearchPagedDto = {
  __typename?: 'SessionAmendmentSearchPagedDto';
  data: Array<Maybe<SessionAmendmentDto>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type SessionAmendmentUpdateBusinessInput = {
  businessId: Scalars['String'];
  editDay: Scalars['Int'];
};

export type SessionCost = {
  __typename?: 'SessionCost';
  discount: Scalars['Float'];
  earlyFee: Scalars['Float'];
  gap: Scalars['Float'];
  lateFee: Scalars['Float'];
  sessionCost: Scalars['Float'];
  total: Scalars['Float'];
};

export enum SessionIndicator {
  B = 'B',
  C = 'C',
  R = 'R',
}

export type SessionReportSyncResubmitInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type SessionReportSyncUpdateInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  reasonForLateChange?: InputMaybe<Scalars['String']>;
};

export enum SessionSource {
  Adhoc = 'ADHOC',
  Default = 'DEFAULT',
  Migrations = 'MIGRATIONS',
  ParentApp = 'PARENT_APP',
  ThirdParty = 'THIRD_PARTY',
}

export type SessionSubsidy = {
  __typename?: 'SessionSubsidy';
  amount: Scalars['Float'];
  error: Scalars['Boolean'];
  hours: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
};

export type SessionTimeEntry = {
  __typename?: 'SessionTimeEntry';
  accountChildId: Scalars['ID'];
  accountId: Scalars['ID'];
  centerId: Scalars['ID'];
  childId: Scalars['ID'];
  classId: Scalars['ID'];
  id: Scalars['ID'];
  sessionId: Scalars['ID'];
  timeIn: Scalars['DateTime'];
  timeInById: Scalars['ID'];
  timeOut?: Maybe<Scalars['DateTime']>;
  timeOutById?: Maybe<Scalars['ID']>;
};

export type SessionsAndActivityForChildrenByContactId = {
  __typename?: 'SessionsAndActivityForChildrenByContactId';
  child: Child;
  expectedSessions?: Maybe<Array<Maybe<Session>>>;
  lastTimeEntry?: Maybe<LastTimeEntry>;
  sessionsToday: Array<Session>;
};

export type SetAttendanceBusinessSettingInput = {
  businessId: Scalars['ID'];
  usesAttendanceSettings?: InputMaybe<Scalars['Boolean']>;
  usesTodayScreen: Scalars['Boolean'];
};

export type SetAttendanceCenterSettingInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  usesTodayScreen: Scalars['Boolean'];
};

export type SetCommsBusinessSettingsInput = {
  businessId?: InputMaybe<Scalars['ID']>;
  useComms?: InputMaybe<Scalars['Boolean']>;
  usesAccountChannels?: InputMaybe<Scalars['Boolean']>;
  usesBroadcastChannels?: InputMaybe<Scalars['Boolean']>;
  usesBulletinBoards?: InputMaybe<Scalars['Boolean']>;
  usesInactiveAccounts?: InputMaybe<Scalars['Boolean']>;
  usesStudentChannels?: InputMaybe<Scalars['Boolean']>;
};

export type SetCommsCenterSettingsInput = {
  businessId?: InputMaybe<Scalars['ID']>;
  centerId?: InputMaybe<Scalars['ID']>;
  useBroadcastChannels?: InputMaybe<Scalars['Boolean']>;
  usesAccountChannels?: InputMaybe<Scalars['Boolean']>;
  usesBulletinBoards?: InputMaybe<Scalars['Boolean']>;
  usesInactiveAccounts?: InputMaybe<Scalars['Boolean']>;
  usesStudentChannels?: InputMaybe<Scalars['Boolean']>;
};

export type SetCurrentCpavForCentreServiceInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  cpavRequest: ServiceCpavRequest;
  govServiceId: Scalars['ID'];
};

export type SetReEnrolBusinessSettingsInput = {
  businessId: Scalars['String'];
  usesEnrolmentForm: Scalars['Boolean'];
  usesParentApplication: Scalars['Boolean'];
  usesReEnrol: Scalars['Boolean'];
};

export type SetReEnrolCenterSettingsInput = {
  businessId: Scalars['String'];
  centerId: Scalars['String'];
  usesEnrolmentForm: Scalars['Boolean'];
  usesParentApplication: Scalars['Boolean'];
};

export type Shift = {
  __typename?: 'Shift';
  breakMinutes: Scalars['Int'];
  center: Center;
  centerId: Scalars['ID'];
  class?: Maybe<Class>;
  classId?: Maybe<Scalars['ID']>;
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  paidBreak: Scalars['Boolean'];
  person?: Maybe<Staff>;
  personId?: Maybe<Scalars['ID']>;
  position?: Maybe<StaffPosition>;
  positionId?: Maybe<Scalars['ID']>;
  published: Scalars['Boolean'];
  scheduleId: Scalars['ID'];
  startTime: Scalars['DateTime'];
  timeEntries: Array<TimeEntry>;
};

export type SigningParty = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organisationName?: InputMaybe<Scalars['String']>;
};

export type SimplifiedAccountCorrespondence = {
  accountId: Scalars['ID'];
  reEnrollmentBatchId: Scalars['ID'];
};

export type SingleDayAvailability = {
  __typename?: 'SingleDayAvailability';
  dayOfWeek: DayOfWeek;
  /** lack of a timeRange implies that this is all day */
  times?: Maybe<Array<TimeRange>>;
};

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type SortDto = {
  __typename?: 'SortDto';
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type SortSearchInput = {
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  from: Scalars['Int'];
  searchKey?: InputMaybe<Scalars['String']>;
  size: Scalars['Int'];
  sort: Array<SearchSort>;
  statusAtDate?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<AccountStatusType>;
};

export type SortedAccountsPagedResult = {
  __typename?: 'SortedAccountsPagedResult';
  data: Array<Account>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type SortedChildrenPagedResult = {
  __typename?: 'SortedChildrenPagedResult';
  data: Array<AccountChild>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type Space = {
  __typename?: 'Space';
  center?: Maybe<Center>;
  centerId: Scalars['ID'];
  color: Scalars['String'];
  icon: SpaceIconEnum;
  id: Scalars['ID'];
  name: Scalars['String'];
  pocState?: Maybe<PocSpaceState>;
};

export enum SpaceIconEnum {
  FaAppleAlt = 'faAppleAlt',
  FaBaby = 'faBaby',
  FaBasketballHoop = 'faBasketballHoop',
  FaBed = 'faBed',
  FaBusSchool = 'faBusSchool',
  FaChalkboardTeacher = 'faChalkboardTeacher',
  FaChild = 'faChild',
  FaFirstAid = 'faFirstAid',
  FaSchool = 'faSchool',
  FaShapes = 'faShapes',
  FaTreeAlt = 'faTreeAlt',
  FaUtensilsAlt = 'faUtensilsAlt',
}

export type SplitClassInput = {
  centerId: Scalars['ID'];
  classId: Scalars['ID'];
  className: Scalars['String'];
  coreId: Scalars['ID'];
  currentSpaceId: Scalars['ID'];
};

export type Staff = Person & {
  __typename?: 'Staff';
  additionalEmergencyContacts?: Maybe<Array<StaffEmergencyContact>>;
  address?: Maybe<Address>;
  allergies: Array<Allergy>;
  availability?: Maybe<ScheduleAvailability>;
  avatar?: Maybe<S3SignedUrl>;
  classAssignments: Array<Class>;
  cognitoConfiguredAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  devices?: Maybe<Array<Maybe<Device>>>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailIsManaged?: Maybe<Scalars['Boolean']>;
  employmentEndDate?: Maybe<Scalars['DateTime']>;
  employmentStartDate?: Maybe<Scalars['DateTime']>;
  employmentStatus?: Maybe<EmploymentStatus>;
  entity?: Maybe<Entity>;
  entityId?: Maybe<Scalars['ID']>;
  firstname?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invitedAt?: Maybe<Scalars['DateTime']>;
  isEmailVerified: Scalars['Boolean'];
  lastname?: Maybe<Scalars['String']>;
  medicalConditions: Array<MedicalCondition>;
  nickname?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  onboardingStatus?: Maybe<OnboardingStatus>;
  phoneNumber?: Maybe<Scalars['Phone']>;
  phoneNumberAlt?: Maybe<Scalars['Phone']>;
  pin?: Maybe<Scalars['String']>;
  pocCurrentStatus?: Maybe<PocStatus>;
  pocCurrentlyInClassId?: Maybe<Scalars['ID']>;
  pocState?: Maybe<PocStaffState>;
  positions: Array<StaffPosition>;
  primaryCenter?: Maybe<Center>;
  primaryCenterId?: Maybe<Scalars['ID']>;
  primaryEmergencyContact?: Maybe<StaffEmergencyContact>;
  reasonForLeaving?: Maybe<ReasonForLeaving>;
  requestedToJoinAt?: Maybe<Scalars['DateTime']>;
  restoreId?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  roleship?: Maybe<Roleship>;
  staffTrainings?: Maybe<Array<StaffTraining>>;
  tags: Array<Tag>;
  userType: UserType;
  verifiedEmail?: Maybe<Scalars['String']>;
};

export type StaffDto = {
  __typename?: 'StaffDto';
  email: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  profileUrl?: Maybe<Scalars['String']>;
};

export type StaffEmergencyContact = {
  __typename?: 'StaffEmergencyContact';
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  phoneNumber?: Maybe<Scalars['Phone']>;
  phoneNumberType?: Maybe<Scalars['String']>;
  relationship: Scalars['String'];
};

export type StaffPosition = {
  __typename?: 'StaffPosition';
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  isExempt?: Maybe<Scalars['Boolean']>;
  payRate?: Maybe<Scalars['Float']>;
  personId: Scalars['ID'];
  positionId: Scalars['ID'];
  positionName: Scalars['String'];
  scopeId: Scalars['ID'];
  scopeType: ScopeType;
  /**
   * 9-29-2020
   * 'title' is from the previous Position implementation
   * but is being kept so other apps do not break (ex: staff app)
   */
  title: Scalars['String'];
  type?: Maybe<PayRateType>;
};

export type StaffSearchResults = {
  __typename?: 'StaffSearchResults';
  data: Array<Staff>;
  totalResults: Scalars['Int'];
};

export enum StaffStatus {
  Break = 'BREAK',
  ClockedIn = 'CLOCKED_IN',
  ClockedOut = 'CLOCKED_OUT',
  Expected = 'EXPECTED',
  ExpectedSoon = 'EXPECTED_SOON',
  Late = 'LATE',
  Missing = 'MISSING',
  Off = 'OFF',
  PlannedAbsence = 'PLANNED_ABSENCE',
}

export type StaffTraining = {
  __typename?: 'StaffTraining';
  expiryDate: Scalars['Date'];
  name: Scalars['String'];
};

export type StaffTrainingInput = {
  expiryDate: Scalars['Date'];
  name: Scalars['String'];
};

export type StaffUnreadMessagesDto = {
  __typename?: 'StaffUnreadMessagesDto';
  centerId?: Maybe<Scalars['ID']>;
  unreadMentions: Scalars['Int'];
  unreadMessages: Scalars['Int'];
};

export enum Stage {
  Closed = 'CLOSED',
  ParentCompleted = 'PARENT_COMPLETED',
  ParentDeclined = 'PARENT_DECLINED',
  ReadyToBeSent = 'READY_TO_BE_SENT',
  Sent = 'SENT',
  Viewed = 'VIEWED',
}

export type StartStrongChild = {
  __typename?: 'StartStrongChild';
  childId: Scalars['ID'];
  hasDeclarationForm?: Maybe<Scalars['Boolean']>;
  hasNonEnglishSpeakingBackground?: Maybe<Scalars['Boolean']>;
  hasPersonalDataConsent?: Maybe<Scalars['Boolean']>;
  hasSchoolExemptionCertificate?: Maybe<Scalars['Boolean']>;
  nonEnglishSpeakingBackground?: Maybe<Scalars['Boolean']>;
  shouldExcludeFunding?: Maybe<Scalars['Boolean']>;
};

export type StateTerritoryBody = {
  __typename?: 'StateTerritoryBody';
  noticeGivenDate: Scalars['Date'];
  noticeToStateTerritory: Scalars['Boolean'];
  notifiedByPersonFirstName?: Maybe<Scalars['String']>;
  notifiedByPersonLastName?: Maybe<Scalars['String']>;
  notifiedByStateTerritory: Scalars['Boolean'];
  organisationName: Scalars['String'];
  statePersonEmail?: Maybe<Scalars['String']>;
  statePersonNameOrId?: Maybe<Scalars['String']>;
  statePersonPhoneNumber?: Maybe<Scalars['String']>;
  stateReferenceNumber?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<BodyType>;
};

export type StateTerritoryBodyInput = {
  noticeGivenDate: Scalars['Date'];
  noticeToStateTerritory: Scalars['Boolean'];
  notifiedByPersonFirstName?: InputMaybe<Scalars['String']>;
  notifiedByPersonLastName?: InputMaybe<Scalars['String']>;
  notifiedByStateTerritory: Scalars['Boolean'];
  organisationName: Scalars['String'];
  statePersonEmail?: InputMaybe<Scalars['String']>;
  statePersonNameOrId?: InputMaybe<Scalars['String']>;
  statePersonPhoneNumber?: InputMaybe<Scalars['String']>;
  stateReferenceNumber?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BodyType>;
};

export type Statement = {
  __typename?: 'Statement';
  accountFirstName: Scalars['String'];
  accountId: Scalars['ID'];
  accountLastName: Scalars['String'];
  customerDownloadedStatementAt?: Maybe<Scalars['DateTime']>;
  dateRun?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  status: StatementStatus;
};

export type StatementRun = {
  __typename?: 'StatementRun';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isScheduledRun: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  periodEndOn: Scalars['Date'];
  periodStartFrom: Scalars['Date'];
  statements: Array<Statement>;
  status: StatementRunStatus;
};

export enum StatementRunStatus {
  CompletedError = 'COMPLETED_ERROR',
  CompletedSuccess = 'COMPLETED_SUCCESS',
  Processing = 'PROCESSING',
  Queued = 'QUEUED',
}

export type StatementSchedule = {
  __typename?: 'StatementSchedule';
  centerId: Scalars['ID'];
  dayOfMonth?: Maybe<Scalars['Int']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  frequency: StatementScheduleFrequency;
  isEnabled: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  periodBasis: StatementSchedulePeriodBasis;
  periodWeeksAfter: Scalars['Int'];
  periodWeeksPrior: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
};

export enum StatementScheduleFrequency {
  Biweekly = 'BIWEEKLY',
  Custom = 'CUSTOM',
  EveryFourWeeks = 'EVERY_FOUR_WEEKS',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
}

export enum StatementSchedulePeriodBasis {
  CurrentWeek = 'CURRENT_WEEK',
  PreviousWeek = 'PREVIOUS_WEEK',
}

export enum StatementStatus {
  Error = 'ERROR',
  Generating = 'GENERATING',
  Queued = 'QUEUED',
  Sending = 'SENDING',
  Success = 'SUCCESS',
}

export type StopOperatingServiceInput = {
  businessId: Scalars['ID'];
  expectedClosureDate: Scalars['Date'];
  isParentsNotified: Scalars['Boolean'];
  isSale: Scalars['Boolean'];
  notifyDept: Scalars['Boolean'];
  sale?: InputMaybe<StopOperatingServiceSaleInput>;
  serviceId: Scalars['ID'];
};

export type StopOperatingServiceSaleInput = {
  contactName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  expectedDate: Scalars['Date'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  providerName: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  billingActivitySuspended?: Maybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  freeTrialEndDate: Scalars['Date'];
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy: Scalars['ID'];
  notificationReceived: Notification;
  pocCenter?: Maybe<PocCenterState>;
  pocChildrenAtCenter?: Maybe<PocChildState>;
  pocClassesAtCenter?: Maybe<PocClassState>;
  pocSpacesAtCenter?: Maybe<PocSpaceState>;
  pocStaffAtCenter?: Maybe<PocStaffState>;
  primaryPaymentMethodId: Scalars['ID'];
};

export type SubscriptionPocCenterArgs = {
  id: Scalars['ID'];
};

export type SubscriptionPocChildrenAtCenterArgs = {
  id: Scalars['ID'];
};

export type SubscriptionPocClassesAtCenterArgs = {
  id: Scalars['ID'];
};

export type SubscriptionPocSpacesAtCenterArgs = {
  id: Scalars['ID'];
};

export type SubscriptionPocStaffAtCenterArgs = {
  id: Scalars['ID'];
};

export type SubsidyCategoriesData = {
  __typename?: 'SubsidyCategoriesData';
  ERRORED: Scalars['Float'];
  FINALISED: Scalars['Float'];
  PENDING: Scalars['Float'];
  UNSUBMITTED: Scalars['Float'];
  erroredEarliest?: Maybe<Scalars['Date']>;
  erroredLatest?: Maybe<Scalars['Date']>;
  finalisedEarliest?: Maybe<Scalars['Date']>;
  finalisedLatest?: Maybe<Scalars['Date']>;
  pendingEarliest?: Maybe<Scalars['Date']>;
  pendingLatest?: Maybe<Scalars['Date']>;
  unsubmittedEarliest?: Maybe<Scalars['Date']>;
  unsubmittedLatest?: Maybe<Scalars['Date']>;
};

export type SubsidyCategoriesInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type SubsidyCenterRating = {
  __typename?: 'SubsidyCenterRating';
  effectiveDate: Scalars['Date'];
  id?: Maybe<Scalars['ID']>;
  ratingType: RatingType;
  ratingValue: Scalars['Float'];
};

export type SubsidyCenterRatingInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  effectiveDate: Scalars['Date'];
  id?: InputMaybe<Scalars['ID']>;
  ratingType: RatingType;
  ratingValue: Scalars['Float'];
};

export type SubsidyCenterRatingPagedResult = {
  __typename?: 'SubsidyCenterRatingPagedResult';
  data: Array<SubsidyCenterRating>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type SubsidyDashboardData = {
  __typename?: 'SubsidyDashboardData';
  ERRORED: Scalars['Float'];
  PENDING: Scalars['Float'];
  UNSUBMITTED: Scalars['Float'];
};

export type SubsidyDashboardInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
};

export type SubsidyEntitlement = {
  __typename?: 'SubsidyEntitlement';
  enrolmentId: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  recipient: Scalars['String'];
  sessionId: Scalars['String'];
};

export enum SubsidyPaymentPeriodType {
  EveryTwoWeeks = 'EVERY_TWO_WEEKS',
  Monthly = 'MONTHLY',
  Session = 'SESSION',
  Weekly = 'WEEKLY',
}

export type SubsidyScheme = {
  __typename?: 'SubsidyScheme';
  businessId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export type SubsidySchemeDto = {
  __typename?: 'SubsidySchemeDto';
  businessId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  subsidySchemeId: Scalars['ID'];
};

export type SubsidySchemeInput = {
  businessId: Scalars['ID'];
};

export enum SubsidyWeeklyStatus {
  Errored = 'ERRORED',
  Finalised = 'FINALISED',
  Pending = 'PENDING',
  Unsubmitted = 'UNSUBMITTED',
}

export type SubsidyWeeklySummary = {
  __typename?: 'SubsidyWeeklySummary';
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  centerId: Scalars['ID'];
  centerName: Scalars['String'];
  child: Child;
  childId: Scalars['ID'];
  childName: Scalars['String'];
  estimated: Scalars['Float'];
  paid: Scalars['Float'];
  reasonForLateChange?: Maybe<Scalars['String']>;
  sessionIds: Array<Scalars['String']>;
  sessionReportSynchronisationErrorDetails?: Maybe<Scalars['String']>;
  sessionReportSynchronisationId: Scalars['ID'];
  status: SubsidyWeeklyStatus;
  subsidyEntitlements: Array<SubsidyEntitlement>;
  weekEnding: Scalars['Date'];
};

export type SubsidyWeeklySummaryInput = {
  businessId: Scalars['ID'];
  centerIds: Array<Scalars['ID']>;
  endDate: Scalars['Date'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchText?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<SearchSort>>;
  startDate: Scalars['Date'];
  status?: InputMaybe<SubsidyWeeklyStatus>;
};

export type SubsidyWeeklySummaryPagedResult = {
  __typename?: 'SubsidyWeeklySummaryPagedResult';
  data: Array<SubsidyWeeklySummary>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type SuccessfulChildClassMove = {
  __typename?: 'SuccessfulChildClassMove';
  centerId: Scalars['ID'];
  currentlyInClassId: Scalars['ID'];
  id: Scalars['ID'];
};

export type SuccessfulChildStatusChange = {
  __typename?: 'SuccessfulChildStatusChange';
  centerId: Scalars['ID'];
  currentCustodyStatus?: Maybe<CustodyStatus>;
  id: Scalars['ID'];
};

export type SuccessfulPersonClassMove = {
  __typename?: 'SuccessfulPersonClassMove';
  centerId: Scalars['ID'];
  classId: Scalars['ID'];
  personId: Scalars['ID'];
};

export enum SuitabilityReasonType {
  Cuba = 'CUBA',
  Diba = 'DIBA',
  Offr = 'OFFR',
  Ofpr = 'OFPR',
  Ofse = 'OFSE',
  Ofvi = 'OFVI',
  Otma = 'OTMA',
  Wwst = 'WWST',
}

export type SupportingDocumentInput = {
  file: Scalars['Upload'];
  type: Scalars['String'];
};

export type TableFieldSet = {
  __typename?: 'TableFieldSet';
  dataSet: Array<Scalars['String']>;
  fieldName: Scalars['String'];
};

export type TableMetaData = {
  __typename?: 'TableMetaData';
  fieldSets: Array<TableFieldSet>;
  totalResults: Scalars['Int'];
};

export type TableMetaDataInput = {
  fields: Array<Scalars['String']>;
  tableName: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  category: TagCategory;
  entityId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum TagCategory {
  Account = 'ACCOUNT',
  Center = 'CENTER',
  Child = 'CHILD',
  Contact = 'CONTACT',
  Staff = 'STAFF',
}

export type TagInput = {
  category: TagCategory;
  entityId: Scalars['ID'];
  name: Scalars['String'];
};

export type Theme = {
  __typename?: 'Theme';
  curriculumId: Scalars['ID'];
  curriculumName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  experiencesCount: Scalars['Int'];
  id: Scalars['ID'];
  lessons: Array<ThemeLesson>;
  name: Scalars['String'];
  order: Scalars['Int'];
  unitId: Scalars['ID'];
  unitName: Scalars['String'];
};

export type ThemeLesson = {
  __typename?: 'ThemeLesson';
  experiences: Array<EducationExperience>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  themeId: Scalars['ID'];
};

export type ThreadMessageCustom = {
  __typename?: 'ThreadMessageCustom';
  directedAt?: Maybe<Array<Maybe<ThreadMessageDirectedAtDto>>>;
};

export type ThreadMessageDirectedAtDto = {
  __typename?: 'ThreadMessageDirectedAtDto';
  displayName?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ThreadMessageDto = {
  __typename?: 'ThreadMessageDto';
  activityTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  custom?: Maybe<ThreadMessageCustom>;
  id?: Maybe<Scalars['String']>;
  sender?: Maybe<ThreadMessageSender>;
  text?: Maybe<Scalars['String']>;
};

export type ThreadMessageSender = {
  __typename?: 'ThreadMessageSender';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profileUrl?: Maybe<Scalars['String']>;
};

export type TimeCardLock = {
  __typename?: 'TimeCardLock';
  archivedAt?: Maybe<Scalars['String']>;
  archivedBy?: Maybe<Scalars['ID']>;
  archivedByStaff?: Maybe<Staff>;
  businessId: Scalars['ID'];
  center?: Maybe<Center>;
  centerId: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdByStaff?: Maybe<Staff>;
  id: Scalars['ID'];
  lockedUntil?: Maybe<Scalars['String']>;
};

export type TimeCardLockDto = {
  __typename?: 'TimeCardLockDto';
  data?: Maybe<Array<Maybe<TimeCardLock>>>;
};

export type TimeCardLockPagedResult = {
  __typename?: 'TimeCardLockPagedResult';
  data: Array<Maybe<TimeCardLock>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type TimeCardLockedUntilResult = {
  __typename?: 'TimeCardLockedUntilResult';
  data?: Maybe<TimeCardLock>;
};

export type TimeEntry = {
  __typename?: 'TimeEntry';
  adjustedAt?: Maybe<Scalars['DateTime']>;
  adjustedBy?: Maybe<Scalars['ID']>;
  adjustedByPerson?: Maybe<Person>;
  adjustmentType?: Maybe<Scalars['String']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['ID']>;
  centerId: Scalars['ID'];
  clockedSelfIn: Scalars['Boolean'];
  clockedSelfOut: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  personId: Scalars['ID'];
  positionId: Scalars['ID'];
  scheduleId?: Maybe<Scalars['ID']>;
  shiftId?: Maybe<Scalars['ID']>;
  staffPosition: StaffPosition;
  timeIn: Scalars['DateTime'];
  timeOut?: Maybe<Scalars['DateTime']>;
  trackedTimeIn: Scalars['DateTime'];
  trackedTimeOut?: Maybe<Scalars['DateTime']>;
  type: TimeEntryType;
  updatedAt?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<Scalars['DateTime']>;
};

export enum TimeEntryType {
  Break = 'BREAK',
  Shift = 'SHIFT',
  Training = 'TRAINING',
  Unscheduled = 'UNSCHEDULED',
}

export enum TimeEntryTypeV2 {
  Shift = 'SHIFT',
  Training = 'TRAINING',
}

export type TimeOffRequest = {
  __typename?: 'TimeOffRequest';
  allDay: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['ID']>;
  centerId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  entityId: Scalars['ID'];
  hoursApproved?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  person: Staff;
  personId: Scalars['ID'];
  ptoType?: Maybe<Scalars['ID']>;
  ptoTypeObject?: Maybe<PtoType>;
  startTime: Scalars['DateTime'];
  status: TimeOffRequestStatus;
  timeOffType: TimeOffRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['ID']>;
};

export enum TimeOffRequestStatus {
  Approved = 'Approved',
  Canceled = 'Canceled',
  Denied = 'Denied',
  Pending = 'Pending',
}

export enum TimeOffRequestType {
  Bereavement = 'Bereavement',
  CallIn = 'CallIn',
  Paid = 'Paid',
  Quarantine = 'Quarantine',
  SickTime = 'SickTime',
  Unpaid = 'Unpaid',
}

export type TimeRange = {
  __typename?: 'TimeRange';
  end: Scalars['Time'];
  start: Scalars['Time'];
};

export type TimeRangeInput = {
  end: Scalars['Time'];
  start: Scalars['Time'];
};

export type ToggleEducationFeatureInput = {
  enabled: Scalars['Boolean'];
  feature: EducationFeatureEnum;
};

export type ToggleEducationFeaturesInput = {
  businessId: Scalars['ID'];
  features: Array<ToggleEducationFeatureInput>;
};

export type TotalTime = {
  __typename?: 'TotalTime';
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
};

export type TotalUnBatchedTransactions = {
  __typename?: 'TotalUnBatchedTransactions';
  totalUnbatchedTransactions: Scalars['Int'];
};

export type TrainingTimeEvent = {
  __typename?: 'TrainingTimeEvent';
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy: Scalars['ID'];
  date: Scalars['Date'];
  hours: Scalars['Float'];
  id: Scalars['ID'];
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  ptoTypeId?: Maybe<Scalars['ID']>;
  staffIds: Array<Scalars['ID']>;
  type: TrainingTimeEventType;
};

export enum TrainingTimeEventType {
  Closure = 'CLOSURE',
  Holiday = 'HOLIDAY',
  Oobh = 'OOBH',
  Training = 'TRAINING',
}

export type Transaction = {
  __typename?: 'Transaction';
  account: Account;
  accountId: Scalars['ID'];
  amount: Scalars['Float'];
  appliedDiscountTransactions?: Maybe<Array<Transaction>>;
  appliedToAccountChild?: Maybe<AccountChild>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  createdByPerson?: Maybe<Person>;
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  flatRates?: Maybe<Array<FlatRate>>;
  id: Scalars['ID'];
  isTransactionTypeReversible: Scalars['Boolean'];
  itemizedBill?: Maybe<ItemizedBill>;
  payment?: Maybe<Payment>;
  reversedAt?: Maybe<Scalars['DateTime']>;
  reversedBy?: Maybe<Scalars['ID']>;
  reversedByPerson?: Maybe<Person>;
  reversedByTransaction?: Maybe<Transaction>;
  reversedByTransactionId?: Maybe<Scalars['ID']>;
  reversedTransaction?: Maybe<Transaction>;
  reversedTransactionId?: Maybe<Scalars['ID']>;
  session?: Maybe<Session>;
  sessionId?: Maybe<Scalars['ID']>;
  sessionSource?: Maybe<SessionSource>;
  transactionNumber?: Maybe<Scalars['String']>;
  transactionType: TransactionType;
  transactionTypeId: Scalars['ID'];
};

export enum TransactionDate {
  Applies = 'APPLIES',
  Period = 'PERIOD',
}

export type TransactionReportInput = {
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  businessId: Scalars['String'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  transactionDate?: InputMaybe<TransactionDate>;
  transactionTypeIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TransactionTotalReport = {
  __typename?: 'TransactionTotalReport';
  reportData?: Maybe<Array<Maybe<TransactionTotalReportData>>>;
  reportMetaData: ReportMetaData;
};

export type TransactionTotalReportData = {
  __typename?: 'TransactionTotalReportData';
  centerExternalId?: Maybe<Scalars['String']>;
  centerId: Scalars['String'];
  centerName: Scalars['String'];
  creditAmount?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  debitAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  earlyLateAmount?: Maybe<Scalars['Float']>;
  feeAmount?: Maybe<Scalars['Float']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  transactionCount?: Maybe<Scalars['Int']>;
  transactionTypeId?: Maybe<Scalars['String']>;
  transactionTypeName?: Maybe<Scalars['String']>;
};

export type TransactionType = {
  __typename?: 'TransactionType';
  accountType: Scalars['String'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['ID']>;
  authorisedRoles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  businessId?: Maybe<Scalars['ID']>;
  center?: Maybe<Center>;
  centerId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  glCode?: Maybe<Scalars['String']>;
  glCodeMapping?: Maybe<GlCodeMapping>;
  id: Scalars['ID'];
  isCredit: Scalars['Boolean'];
  isDebit: Scalars['Boolean'];
  isDefaultType: Scalars['Boolean'];
  isTuition: Scalars['Boolean'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['ID']>;
  metadata: Metadata;
  name: Scalars['String'];
};

export type TransactionTypeAuthorizationRoles = {
  __typename?: 'TransactionTypeAuthorizationRoles';
  id: Scalars['ID'];
  roleId: Scalars['ID'];
  transactionTypeId: Scalars['ID'];
};

export type TransactionTypeAuthorizationRolesDeleteInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TransactionTypeAuthorizationRolesDto = {
  __typename?: 'TransactionTypeAuthorizationRolesDto';
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  roleId: Scalars['ID'];
  transactionTypeId: Scalars['ID'];
};

export type TransactionTypeAuthorizationRolesInput = {
  businessId: Scalars['ID'];
  data: RoleTransactionTypeShape;
};

export type TransactionTypeAuthorizationRolesSearchInput = {
  businessId: Scalars['ID'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  roleId?: InputMaybe<Scalars['String']>;
  sortDtos?: InputMaybe<Array<InputMaybe<SearchSort>>>;
  transactionTypeId?: InputMaybe<Scalars['String']>;
};

export type TransactionTypeAuthorizationRolesSearchPagedDto = {
  __typename?: 'TransactionTypeAuthorizationRolesSearchPagedDto';
  data: Array<Maybe<TransactionTypeAuthorizationRolesDto>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type TransactionTypeAuthorizationRolesUpdateInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  roleId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  transactionTypeId: Scalars['ID'];
};

export type TransactionTypeAuthorizationRolesUpsertResponseDto = {
  __typename?: 'TransactionTypeAuthorizationRolesUpsertResponseDto';
  roleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  transactionTypeId: Scalars['ID'];
};

export type TransactionTypeInput = {
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  glCode?: InputMaybe<Scalars['String']>;
  glCodeMapping?: InputMaybe<GlCodeMappingInput>;
  isDebit: Scalars['Boolean'];
  isTuition: Scalars['Boolean'];
  name: Scalars['String'];
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TransactionsForAccountInTimeframeResponseDto = {
  __typename?: 'TransactionsForAccountInTimeframeResponseDto';
  closingBalance: Scalars['Float'];
  openingBalance: Scalars['Float'];
  transactions: Array<Transaction>;
};

export type TransactionsInTimeframePagedResult = {
  __typename?: 'TransactionsInTimeframePagedResult';
  data: Array<Transaction>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type TransitionStatementDto = {
  __typename?: 'TransitionStatementDto';
  catholicCount?: Maybe<Scalars['Int']>;
  centerId: Scalars['ID'];
  centerName?: Maybe<Scalars['String']>;
  childId: Scalars['ID'];
  childName?: Maybe<Scalars['String']>;
  independentCount?: Maybe<Scalars['Int']>;
  stateCount?: Maybe<Scalars['Int']>;
};

export type UsModeInput = {
  businessId: Scalars['ID'];
};

export type UnBatchedTransactions = {
  __typename?: 'UnBatchedTransactions';
  totalUnbatchedTransactions: Scalars['Int'];
  transactions: Array<Transaction>;
};

export type Unit = {
  __typename?: 'Unit';
  description?: Maybe<Scalars['String']>;
  experiencesCount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  themes: Array<Theme>;
  themesCount: Scalars['Int'];
};

export type UnlockLockInput = {
  proximityProof?: InputMaybe<Scalars['String']>;
};

export type UnlockResponse = {
  __typename?: 'UnlockResponse';
  error?: Maybe<Scalars['String']>;
  isUnlock?: Maybe<Scalars['Boolean']>;
};

export type UpdateApiKeyInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateAccountBillingCycleInput = {
  additionalChargePeriod: BillingTimePeriodInput;
  billingCycleTemplateId?: InputMaybe<Scalars['ID']>;
  billingPeriods?: InputMaybe<Array<InputMaybe<BillingPeriodCustomInput>>>;
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  endDate?: InputMaybe<Scalars['Date']>;
  frequency: BillingFrequency;
  gracePeriodDays?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoiceSchedule: BillingTimePeriodInput;
  isPaused: Scalars['Boolean'];
  lateChargeAmount?: InputMaybe<Scalars['Float']>;
  period?: InputMaybe<BillingPeriod>;
  startDate: Scalars['Date'];
  transactionLimit?: InputMaybe<Scalars['Float']>;
};

export type UpdateAccountContactRelationshipsInput = {
  accountId: Scalars['ID'];
  children: Array<AccountContactChildInput>;
  contactId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  isPrimary: Scalars['Boolean'];
  permissions?: InputMaybe<Array<AccountContactPermissionInput>>;
};

export type UpdateAccountInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateAccountTagsInput = {
  id: Scalars['ID'];
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateAccountingPeriodInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  open: Scalars['Boolean'];
};

export type UpdateAdjustmentInput = {
  glCode: Scalars['String'];
  glCodeMapping?: InputMaybe<GlCodeMappingInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateAgeRangeInput = {
  end: Scalars['Int'];
  endUOM?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  start: Scalars['Int'];
  startUOM?: InputMaybe<Scalars['String']>;
};

export type UpdateAgencyAccountChildEnrollmentInput = {
  agencyAccountChildEnrollmentId: Scalars['ID'];
  endDate: Scalars['Date'];
  excludedContractIds?: InputMaybe<Array<Scalars['ID']>>;
  ignoreCopay?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  subsidyPaymentPortion?: InputMaybe<Scalars['Float']>;
};

export type UpdateAgencyInput = {
  absentDay?: InputMaybe<Scalars['String']>;
  agencyLinks?: InputMaybe<Array<InputMaybe<UpdateAgencyLinkInput>>>;
  attendanceRequirement: Scalars['String'];
  billingCalculationType: BillingCalculationType;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  county?: InputMaybe<Scalars['String']>;
  glCodeMapping?: InputMaybe<GlCodeMappingInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['Phone']>;
  reimbursementSchedule?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  subsidyPeriod: SubsidyPaymentPeriodType;
};

export type UpdateAgencyLinkInput = {
  id?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
};

export type UpdateAgencyPaymentInput = {
  id: Scalars['ID'];
  isSubmitted?: InputMaybe<Scalars['Boolean']>;
  updateChildAgencyPaymentMessages: Array<UpdateChildAgencyPaymentInput>;
};

export type UpdateAllergenListInput = {
  toArchive: Array<Scalars['ID']>;
  toCreate: Array<Scalars['String']>;
  typeId: Scalars['ID'];
};

export type UpdateAnnouncementInput = {
  emergency?: InputMaybe<Scalars['Boolean']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateApiKeyWebHookInput = {
  apiKeyId: Scalars['ID'];
  businessId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  url: Scalars['String'];
  webHookApiKey: Scalars['String'];
  webHookType: Scalars['String'];
};

export type UpdateApplicationCenterSettingInput = {
  askFamilyAboutSubsidy: Scalars['Boolean'];
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  enrollmentOptions?: InputMaybe<EnrollmentOptions>;
  feeAmount: Scalars['Float'];
  feeType?: InputMaybe<ApplicationFeeType>;
};

export type UpdateApplicationMultipleCentersSettingInput = {
  businessId: Scalars['ID'];
  centerIds: Array<InputMaybe<Scalars['ID']>>;
  centerSettingsList?: InputMaybe<Array<InputMaybe<CenterSettingsInput>>>;
};

export type UpdateApplicationOfferRequest = {
  expiryDate?: InputMaybe<Scalars['Date']>;
  resendOfferEmail: Scalars['Boolean'];
  scheduleOffers: Array<InputMaybe<UpdateScheduleOfferInput>>;
};

export type UpdateApplicationOfferResponse = {
  __typename?: 'UpdateApplicationOfferResponse';
  applicationId: Scalars['ID'];
  applicationOfferExpiry?: Maybe<Scalars['Date']>;
  applicationOfferId: Scalars['ID'];
  applicationScheduleOffers: Array<ApplicationScheduleOffer>;
};

export type UpdateApplicationSettingInput = {
  businessId: Scalars['String'];
  flowType: ApplicationFlowType;
  isUsePerProgramGroupFees?: InputMaybe<Scalars['Boolean']>;
  isUseProgramGroup?: InputMaybe<Scalars['Boolean']>;
  offersExpireInDays: Scalars['Int'];
};

export type UpdateAppliedAccountDiscountInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  startDate: Scalars['Date'];
};

export type UpdateAvailabityChangeForStaffInput = {
  days: Array<RequestAvailabilityDayChangeInput>;
  personId: Scalars['ID'];
};

export type UpdateAvatarInput = {
  avatar: Scalars['String'];
  /** shared input for updating an avatar (entity, center, child, person, etc.) */
  id: Scalars['ID'];
  useNewUpdate?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateBasePositionInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateBillingCycleTemplateInput = {
  additionalChargePeriod?: InputMaybe<BillingTimePeriodInput>;
  billingPeriods?: InputMaybe<Array<InputMaybe<BillingPeriodCustomInput>>>;
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Scalars['ID']>;
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  frequency: BillingFrequency;
  frequencyPayPeriod?: InputMaybe<FrequencyPayPeriod>;
  gracePeriodDays?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoiceSchedule: BillingTimePeriodInput;
  lateChargeAmount?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  period: BillingPeriod;
};

export type UpdateBillingSettingItemizedBillsInput = {
  centerIds: Array<Scalars['ID']>;
  groupSubsidyAndSessionLineItems: Scalars['Boolean'];
  type: ItemizedBillType;
};

export type UpdateBusRosterInput = {
  centerId: Scalars['ID'];
  children: Array<BusRosterAccountChildInput>;
  destinations: Array<BusRosterDestinationInput>;
  effectiveDate: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: BusRunType;
};

export type UpdateBusinessAccountOwnersInput = {
  id: Scalars['ID'];
  primaryContactPersonId: Scalars['ID'];
  secondaryContactPersonId?: InputMaybe<Scalars['ID']>;
};

export type UpdateBusinessAvatarInput = {
  avatar: Scalars['ID'];
  id: Scalars['ID'];
  useNewUpdate?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateBusinessHubSpotCustomerIdInput = {
  hubSpotCustomerId: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateBusinessKisiApiKeyInput = {
  id: Scalars['ID'];
  kisiApiKey: Scalars['String'];
};

export type UpdateBusinessProfileInfoInput = {
  address: BusinessAddressInput;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  timezone: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateCenterBatchingSettingsResult = {
  __typename?: 'UpdateCenterBatchingSettingsResult';
  enableAutomaticBatching: Scalars['Boolean'];
  entityId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateCenterInput = {
  abn?: InputMaybe<Scalars['String']>;
  acn?: InputMaybe<Scalars['String']>;
  address: AddressInput;
  avatar?: InputMaybe<Scalars['String']>;
  billingEnabledSettings?: InputMaybe<CenterBillingEnabledSettingsInput>;
  centerEnrollmentBillingCycleTemplateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  closingDate?: InputMaybe<Scalars['Date']>;
  defaultBillingCycleTemplateId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  enableBillingCycleProcessing?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['String']>;
  familyOperationHours?: InputMaybe<Array<OperationHoursInput>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  openingDate?: InputMaybe<Scalars['Date']>;
  phoneNumber: Scalars['Phone'];
  primaryContactPersonId?: InputMaybe<Scalars['ID']>;
  secondaryContactPersonId?: InputMaybe<Scalars['ID']>;
  serviceType?: InputMaybe<CenterServiceType>;
  staffOperationHours?: InputMaybe<Array<OperationHoursInput>>;
  tags?: InputMaybe<Array<Scalars['ID']>>;
  taxId?: InputMaybe<Scalars['String']>;
  timezone: Scalars['String'];
  useNewUpdate?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateCenterKisiPlaceIdInput = {
  id: Scalars['ID'];
  kisiPlaceId?: InputMaybe<Scalars['Int']>;
  kisiShareId?: InputMaybe<Scalars['Int']>;
};

export type UpdateCenterMerchantIdInput = {
  id: Scalars['ID'];
  isSandbox: Scalars['Boolean'];
  merchantId?: InputMaybe<Scalars['String']>;
  merchantToken?: InputMaybe<Scalars['String']>;
  merchantUsername?: InputMaybe<Scalars['String']>;
};

export type UpdateCenterPayPeriodInput = {
  id: Scalars['ID'];
  payPeriodWeekEnd: DayOfWeek;
  payPeriodWeekStart: DayOfWeek;
};

export type UpdateCenterSettingsData = {
  __typename?: 'UpdateCenterSettingsData';
  enableAutomaticBatching: Scalars['Boolean'];
  entityId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateCenterSettingsForAllByBusinessIdData = {
  __typename?: 'UpdateCenterSettingsForAllByBusinessIdData';
  results: Array<UpdateCenterBatchingSettingsResult>;
};

export type UpdateCenterSettingsForAllByBusinessIdInput = {
  businessId: Scalars['ID'];
  enableAutomaticBatching: Scalars['Boolean'];
};

export type UpdateCenterSettingsInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  enableAutomaticBatching: Scalars['Boolean'];
};

export type UpdateCenterStatementMessageInput = {
  centerId: Scalars['ID'];
  message: Scalars['String'];
};

export type UpdateCenterStatementScheduleInput = {
  centerId: Scalars['ID'];
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  frequency: StatementScheduleFrequency;
  isEnabled: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  periodBasis: StatementSchedulePeriodBasis;
  periodWeeksAfter: Scalars['Int'];
  periodWeeksPrior: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateCenterTagsInput = {
  id: Scalars['ID'];
  tags?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateChildAgencyPaymentInput = {
  adjustmentId?: InputMaybe<Scalars['ID']>;
  amountPaid: Scalars['Float'];
  differenceAction: DifferenceActionType;
  differenceActionReason?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateChildAllergyInput = {
  allergenId: Scalars['ID'];
  allergyId: Scalars['ID'];
  childId: Scalars['ID'];
  documentation: Array<ChildMedicalDocumentationInput>;
  instructions: Scalars['String'];
  reactions: Array<Scalars['String']>;
  severity?: InputMaybe<AllergenSeverity>;
};

export type UpdateChildAtCenterInput = {
  allergies?: InputMaybe<Scalars['JSON']>;
  avatar?: InputMaybe<Scalars['String']>;
  centerId: Scalars['ID'];
  dob?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastname?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
};

export type UpdateChildAvatarInput = {
  avatar: Scalars['String'];
  id: Scalars['ID'];
  useNewUpdate?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateChildContactInput = {
  accounts: Array<ContactAccountPermissionsInput>;
  childId: Scalars['ID'];
  contactId: Scalars['ID'];
  relationship: RelationshipEnum;
};

export type UpdateChildEmergencyContactsInput = {
  id: Scalars['ID'];
  toAdd: Array<Scalars['ID']>;
  toRemove: Array<Scalars['ID']>;
};

export type UpdateChildMealStatusInput = {
  id: Scalars['ID'];
  mealPricing: MealPricing;
};

export type UpdateChildMedicalDocumentationInput = {
  expirationDate?: InputMaybe<Scalars['Date']>;
  filename?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateChildPersonalInfoInput = {
  childCcssAttributes?: InputMaybe<ChildCcssAttributesInput>;
  dob?: InputMaybe<Scalars['Date']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  grade?: InputMaybe<Grade>;
  id: Scalars['ID'];
  indigenousStatus?: InputMaybe<IndigenousStatus>;
  lastname?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  residesWithId?: InputMaybe<Scalars['String']>;
};

export type UpdateChildTagsInput = {
  id: Scalars['ID'];
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateClassInput = {
  capacity: Scalars['Int'];
  careType?: InputMaybe<Scalars['String']>;
  centerId: Scalars['ID'];
  colorCode: Scalars['String'];
  defaultCasualFeeId?: InputMaybe<Scalars['ID']>;
  defaultPermanentFeeId?: InputMaybe<Scalars['ID']>;
  defaultSpaceId: Scalars['ID'];
  endTime?: InputMaybe<Scalars['Time']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  glCode?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isPreSchoolProgram?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  regulationId: Scalars['ID'];
  regulationOverride?: InputMaybe<RegulationOverrideInput>;
  staffAssignmentIds: Array<Scalars['String']>;
  startTime?: InputMaybe<Scalars['Time']>;
  startsAt: Scalars['DateTime'];
};

export type UpdateContactAvatarInput = {
  avatar: Scalars['String'];
  id: Scalars['ID'];
  useNewUpdate?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateContactChildRelationshipInput = {
  childId: Scalars['ID'];
  contactId: Scalars['ID'];
  relationship: RelationshipEnum;
};

export type UpdateContactEmailInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateContactPersonalInfoInput = {
  additionalPhoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  address?: InputMaybe<AddressInput>;
  businessId: Scalars['ID'];
  ccssAttributes?: InputMaybe<ContactCcssAttributesInput>;
  dob?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastname?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  primaryPhoneNumber?: InputMaybe<PhoneNumberInput>;
};

export type UpdateContactTagsInput = {
  id: Scalars['ID'];
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateContractInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  adjustmentId?: InputMaybe<Scalars['ID']>;
  casualFeeId?: InputMaybe<Scalars['ID']>;
  centerId?: InputMaybe<Scalars['ID']>;
  childId?: InputMaybe<Scalars['ID']>;
  classId?: InputMaybe<Scalars['ID']>;
  cycleType?: InputMaybe<ContractCycleType>;
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  originalFeeId?: InputMaybe<Scalars['ID']>;
  permanentFeeId?: InputMaybe<Scalars['ID']>;
  reasonForEnding?: InputMaybe<ReasonForEndingContract>;
  startDate?: InputMaybe<Scalars['Date']>;
  timeslots?: InputMaybe<Array<InputMaybe<CreateContractTimeslotInput>>>;
  updateType?: InputMaybe<ContractUpdateType>;
};

export type UpdateCurriculumInput = {
  businessId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateCurriculumScheduleInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  start: Scalars['DateTime'];
};

export type UpdateCurriculumScheduleStatusInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  status: CurriculumScheduleStatus;
};

export type UpdateCurriculumStatusInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  status: CurriculumStatus;
};

export type UpdateCurriculumSubjectInput = {
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateCustomFieldFileUploadInput = {
  area: CustomFieldArea;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  document?: InputMaybe<CustomFieldFileUploadInput>;
  entityId: Scalars['ID'];
};

export type UpdateCustomFieldInput = {
  area?: InputMaybe<CustomFieldArea>;
  attributes?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  displayOrder: Scalars['Int'];
  id: Scalars['ID'];
  label: Scalars['String'];
  notForConsoleDisplay: Scalars['Boolean'];
  type?: InputMaybe<CustomFieldType>;
};

export type UpdateCustomFieldValuesInput = {
  area: CustomFieldArea;
  businessId: Scalars['ID'];
  entityId: Scalars['ID'];
  values: Array<CustomFieldValueInput>;
};

export type UpdateDiscountInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  glCode?: InputMaybe<Scalars['String']>;
  glCodeMapping?: InputMaybe<GlCodeMappingInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
  schedules: Array<UpdateDiscountSchedulesInput>;
};

export type UpdateDiscountSchedulesInput = {
  amount: Scalars['Float'];
  amountType: RateAmountType;
  discountId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['Date'];
};

export type UpdateEducationExperienceInput = {
  attachments?: InputMaybe<Array<Scalars['ID']>>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  instructions?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  outcomes: Array<Scalars['ID']>;
  programIds: Array<Scalars['ID']>;
  subjectId?: InputMaybe<Scalars['ID']>;
  supplies: Array<EducationExperienceSupplyItemInput>;
};

export type UpdateEducationPermissionsInput = {
  groupIds: Array<InputMaybe<Scalars['String']>>;
  permissionId: Scalars['String'];
};

export type UpdateEducationProgramInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateEmail = {
  __typename?: 'UpdateEmail';
  person?: Maybe<Person>;
  success: Scalars['Boolean'];
};

export type UpdateEmailInput = {
  newEmail: Scalars['String'];
  personId: Scalars['String'];
};

export type UpdateEmploymentHistoryInput = {
  employmentEndDate?: InputMaybe<Scalars['DateTime']>;
  employmentStartDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  reasonForLeaving?: InputMaybe<ReasonForLeaving>;
};

export type UpdateEnrollmentFormInput = {
  businessId: Scalars['ID'];
  formSchema: Scalars['JSON'];
  id: Scalars['ID'];
  isDraft: Scalars['Boolean'];
  name: Scalars['String'];
  usedByCenterIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateEnrollmentProgramGroupInput = {
  businessId: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  programIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateEnrollmentProgramInput = {
  businessId: Scalars['ID'];
  description: Scalars['String'];
  endDate: Scalars['Date'];
  enrolmentCloseAt?: InputMaybe<Scalars['DateTime']>;
  enrolmentOpenAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  includeCasualContractCount?: InputMaybe<Scalars['Boolean']>;
  isOpenForEnrollment: Scalars['Boolean'];
  maxEnrolmentDays: Scalars['Int'];
  minEnrolmentDays: Scalars['Int'];
  name: Scalars['String'];
  operatingDays: Scalars['String'];
  programCenters: Array<EnrollmentProgramCenterInput>;
  programGroupId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['Date'];
};

export type UpdateEntityDataTypeInput = {
  dataType: DataType;
  id: Scalars['ID'];
};

export type UpdateEntityInput = {
  accountOwnerPersonId?: InputMaybe<Scalars['ID']>;
  address?: InputMaybe<AddressInput>;
  avatar?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hubSpotCustomerId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['Phone']>;
  primaryContactPersonId?: InputMaybe<Scalars['ID']>;
  primaryPaymentMethodId?: InputMaybe<Scalars['ID']>;
  secondaryContactPersonId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateFeatureFlagInput = {
  id: Scalars['String'];
  name?: InputMaybe<FeatureFlagName>;
  value?: InputMaybe<FeatureFlagValue>;
};

export type UpdateFolderInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateFolderPermissionsInput = {
  id: Scalars['String'];
  readerGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updaterGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateFolderPermissionsResponse = {
  __typename?: 'UpdateFolderPermissionsResponse';
  id: Scalars['String'];
  readerGroupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  updaterGroupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateGlCodeAssignmentInput = {
  entityGuid?: InputMaybe<Scalars['String']>;
  glCodeId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  surchargeType?: InputMaybe<Scalars['String']>;
};

export type UpdateGlCodeAssignmentsInput = {
  businessId: Scalars['ID'];
  glCodeMappingsInput: Array<UpdateGlCodeAssignmentInput>;
};

export type UpdateGlCodeInput = {
  businessId: Scalars['ID'];
  glCode: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  groupId: Scalars['ID'];
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateImmunizationDocumentInput = {
  expirationDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  immunizationId: Scalars['ID'];
  reviewDaysBeforeExpiration?: InputMaybe<Scalars['Int']>;
};

export type UpdateIntegrationApiKeyInput = {
  apiScopes?: InputMaybe<ApiScopesTypeInput>;
  businessId: Scalars['ID'];
  centerScopes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  revoked: Scalars['Boolean'];
};

export type UpdateLearningStandardDomainInput = {
  learningStandardDomainId: Scalars['ID'];
  learningStandardId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

export type UpdateLearningStandardInput = {
  ageRanges?: InputMaybe<Array<InputMaybe<UpdateAgeRangeInput>>>;
  learningStandardId: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateLearningStandardSubDomainInput = {
  learningStandardDomainId: Scalars['ID'];
  learningStandardSubDomainId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

export type UpdateLessonPlanExperienceInput = {
  dayId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  subjectId: Scalars['ID'];
};

export type UpdateLinkCodeInput = {
  businessId: Scalars['ID'];
  centerIds?: InputMaybe<Array<Scalars['ID']>>;
  linkCode: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateLockInput = {
  allowParentUnlock: Scalars['Boolean'];
  allowStaffUnlock: Scalars['Boolean'];
  description?: InputMaybe<Scalars['String']>;
  lockId: Scalars['Int'];
};

export type UpdateMeInput = {
  address?: InputMaybe<AddressInput>;
  avatar?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['Phone']>;
  phoneNumberAlt?: InputMaybe<Scalars['Phone']>;
  pin?: InputMaybe<Scalars['String']>;
};

export type UpdateMyEmergencyContactsInput = {
  additionalEmergencyContacts?: InputMaybe<Array<InputMaybe<EmergencyContactInput>>>;
  primaryEmergencyContact: EmergencyContactInput;
};

export type UpdateMyProfileInput = {
  address: AddressInput;
  dob: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['Phone'];
};

export type UpdateNoteTypeInput = {
  id: Scalars['ID'];
  type: NoteType;
};

export type UpdateOfferScheduleInput = {
  applicationChildId: Scalars['String'];
  applicationId: Scalars['String'];
  applicationScheduleOfferId: Scalars['String'];
  businessId: Scalars['String'];
  careType: Scalars['String'];
  classId?: InputMaybe<Scalars['ID']>;
  cycleType: Scalars['String'];
  days?: InputMaybe<Array<ApplicationScheduleDayInput>>;
  endDate?: InputMaybe<Scalars['Date']>;
  feeAmount?: InputMaybe<Scalars['Float']>;
  feeId?: InputMaybe<Scalars['ID']>;
  offerCenterId: Scalars['ID'];
  programId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateOnboardingStatusInput = {
  onboardingStatus?: InputMaybe<OnboardingStatus>;
};

export type UpdateOutcomeInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Int'];
};

export type UpdatePassThroughDishonorForAllInput = {
  businessId: Scalars['ID'];
  passThroughDishonor: Scalars['Boolean'];
};

export type UpdatePassThroughDishonorInput = {
  centerId: Scalars['ID'];
  dishonorFeeAmount?: InputMaybe<Scalars['Float']>;
  passThroughDishonor: Scalars['Boolean'];
};

export type UpdatePassThroughSurchargeForAllInput = {
  businessId: Scalars['ID'];
  passThroughAchSurcharge?: InputMaybe<Scalars['Boolean']>;
  passThroughCreditSurcharge?: InputMaybe<Scalars['Boolean']>;
  passThroughSurcharge: Scalars['Boolean'];
};

export type UpdatePassThroughSurchargeInput = {
  centerId: Scalars['ID'];
  passThroughAchSurcharge?: InputMaybe<Scalars['Boolean']>;
  passThroughCreditSurcharge?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePayMethodInput = {
  month: Scalars['Int'];
  paymentMethodId: Scalars['ID'];
  year: Scalars['Int'];
};

export type UpdatePaymentInput = {
  amount: Scalars['Float'];
  paymentId: Scalars['ID'];
};

export type UpdatePaymentMethodInput = {
  expirationDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
};

export type UpdatePersonAsStaff = {
  additionalEmergencyContacts?: InputMaybe<Array<EmergencyContactInput>>;
  address?: InputMaybe<AddressInput>;
  allergies?: InputMaybe<Array<AllergyInput>>;
  avatar?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastname?: InputMaybe<Scalars['String']>;
  medicalConditions?: InputMaybe<Array<MedicalConditionInput>>;
  nickname?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['Phone']>;
  phoneNumberAlt?: InputMaybe<Scalars['Phone']>;
  primaryCenterId?: InputMaybe<Scalars['ID']>;
  primaryEmergencyContact?: InputMaybe<EmergencyContactInput>;
  staffTrainings?: InputMaybe<Array<StaffTrainingInput>>;
  useNewUpdate?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePersonEmergencyContactsInput = {
  centerId?: InputMaybe<Scalars['ID']>;
  emergencyContacts: Array<EmergencyContactInput>;
  entityId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type UpdatePhotoNoteInput = {
  id: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
};

export type UpdatePinInput = {
  id: Scalars['ID'];
  pin: Scalars['String'];
};

export type UpdatePositionInput = {
  id: Scalars['ID'];
  payRate?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<PayRateType>;
};

export type UpdateProviderAddressInput = {
  businessId: Scalars['ID'];
  postcode: Scalars['String'];
  providerId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  state: Scalars['String'];
  streetLine1: Scalars['String'];
  streetLine2?: InputMaybe<Scalars['String']>;
  suburb: Scalars['String'];
  type: ProviderAddressType;
};

export type UpdateProviderContactInput = {
  applyEmailToAllServices: Scalars['Boolean'];
  businessId: Scalars['ID'];
  dateOfEvent: Scalars['String'];
  email: Scalars['String'];
  mobileNumber: Scalars['String'];
  phoneNumber: Scalars['String'];
  providerId: Scalars['ID'];
};

export type UpdateProviderFinancialDetailsInput = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  applyToAllServices: Scalars['Boolean'];
  bsb: Scalars['String'];
  dateOfEvent: Scalars['DateTime'];
  providerId: Scalars['ID'];
};

export type UpdateProviderInput = {
  businessId: Scalars['ID'];
  communicationsPersonId: Scalars['String'];
  deviceName: Scalars['String'];
  otac: Scalars['String'];
  providerId: Scalars['ID'];
};

export type UpdateProviderLocationOfRecordInput = {
  dateOfEvent: Scalars['DateTime'];
  postcode: Scalars['String'];
  providerId: Scalars['ID'];
  state: Scalars['String'];
  streetLine1: Scalars['String'];
  streetLine2?: InputMaybe<Scalars['String']>;
  suburb: Scalars['String'];
};

export type UpdateProviderNameInput = {
  DateOfEvent: Scalars['DateTime'];
  Name: Scalars['String'];
  SupportingDocumentFiles: Array<Scalars['Upload']>;
  Type: ProviderNameType;
  businessId: Scalars['ID'];
  providerId: Scalars['ID'];
};

export type UpdateProviderPersonnelInput = {
  businessId: Scalars['String'];
  personnel: CcssPersonnelInput;
  providerId: Scalars['String'];
};

export type UpdatePtoTypesInput = {
  businessId: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateRegulationInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  county?: InputMaybe<Scalars['String']>;
  endAge?: InputMaybe<RegulationAgeInput>;
  id: Scalars['ID'];
  ratioChildren?: InputMaybe<Scalars['Int']>;
  ratioTeachers?: InputMaybe<Scalars['Int']>;
  startAge?: InputMaybe<RegulationAgeInput>;
  state?: InputMaybe<Scalars['String']>;
};

export type UpdateResourceInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateRestoreIdInput = {
  restoreId: Scalars['String'];
};

export type UpdateRestrictionInput = {
  description?: InputMaybe<Scalars['String']>;
  documentation: Array<ChildMedicalDocumentationInput>;
  importance: Risk;
};

export type UpdateRoleInput = {
  areaLevels: Array<MutateRoleAreaLevelInput>;
  hierarchyLevel: Scalars['Int'];
  isIntegrationRole?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  roleId: Scalars['ID'];
  scheduleVisibility?: InputMaybe<Scalars['Boolean']>;
  scopeType?: InputMaybe<ScopeType>;
};

export type UpdateRoleshipInput = {
  id: Scalars['ID'];
  primaryCenterId?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
  scopeIds: Array<Scalars['String']>;
};

export type UpdateScheduleOfferInput = {
  applicationChildId: Scalars['String'];
  applicationId: Scalars['String'];
  applicationScheduleOfferId: Scalars['String'];
  businessId: Scalars['String'];
  careType: Scalars['String'];
  classId?: InputMaybe<Scalars['ID']>;
  cycleType: Scalars['String'];
  days?: InputMaybe<Array<ApplicationScheduleDayInput>>;
  endDate?: InputMaybe<Scalars['Date']>;
  feeAmount?: InputMaybe<Scalars['Float']>;
  feeId?: InputMaybe<Scalars['ID']>;
  id: Scalars['String'];
  offerCenterId: Scalars['ID'];
  programId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateServiceAddressInput = {
  postcode: Scalars['String'];
  serviceId: Scalars['ID'];
  startDate: Scalars['String'];
  state: Scalars['String'];
  streetLine1: Scalars['String'];
  streetLine2?: InputMaybe<Scalars['String']>;
  suburb: Scalars['String'];
  supportingDocumentFiles?: InputMaybe<Array<ServiceNameChangeEvidenceInput>>;
  type: Scalars['String'];
};

export type UpdateServiceContactInput = {
  dateOfEvent: Scalars['Date'];
  email: Scalars['String'];
  mobileNumber: Scalars['String'];
  phoneNumber: Scalars['String'];
  serviceId: Scalars['ID'];
  serviceUrl: Scalars['String'];
};

export type UpdateServiceFinancialDetailsInput = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  bsb: Scalars['String'];
  dateOfEvent: Scalars['DateTime'];
  serviceId: Scalars['ID'];
};

export type UpdateServiceLocationOfRecordInput = {
  dateOfEvent: Scalars['DateTime'];
  postcode: Scalars['String'];
  serviceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  state: Scalars['String'];
  streetLine1: Scalars['String'];
  streetLine2?: InputMaybe<Scalars['String']>;
  suburb: Scalars['String'];
};

export type UpdateServiceNameInput = {
  endDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  serviceId: Scalars['ID'];
  startDate: Scalars['String'];
  supportingDocumentFiles?: InputMaybe<Array<ServiceNameChangeEvidenceInput>>;
};

export type UpdateSessionAbsenceInput = {
  id: Scalars['ID'];
  reason: AbsenceReason;
};

export type UpdateSessionEarlyLateFeesInput = {
  hasEarlyFee: Scalars['Boolean'];
  hasLateFee: Scalars['Boolean'];
  sessionId: Scalars['ID'];
};

export type UpdateSessionInput = {
  absence?: InputMaybe<UpdateSessionAbsenceInput>;
  classId?: InputMaybe<Scalars['ID']>;
  dropOffTime?: InputMaybe<Scalars['Time']>;
  feeId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  newTimeEntries?: InputMaybe<Array<UpdateSessionNewTimeEntryInput>>;
  pickUpTime?: InputMaybe<Scalars['Time']>;
  reasonForLateChange?: InputMaybe<Scalars['String']>;
  timeEntries?: InputMaybe<Array<UpdateSessionTimeEntryInput>>;
};

export type UpdateSessionNewTimeEntryInput = {
  timeIn: Scalars['String'];
  timeOut?: InputMaybe<Scalars['String']>;
};

export type UpdateSessionTimeEntryInput = {
  id: Scalars['ID'];
  timeIn: Scalars['String'];
  timeOut?: InputMaybe<Scalars['String']>;
};

export type UpdateSessionsEarlyLateFeesInput = {
  sessions: Array<UpdateSessionEarlyLateFeesInput>;
};

export type UpdateShiftInput = {
  breakMinutes: Scalars['Int'];
  centerId: Scalars['ID'];
  classId?: InputMaybe<Scalars['ID']>;
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  locationId?: InputMaybe<Scalars['ID']>;
  note?: InputMaybe<Scalars['String']>;
  paidBreak: Scalars['Boolean'];
  personId?: InputMaybe<Scalars['ID']>;
  positionId?: InputMaybe<Scalars['ID']>;
  startTime: Scalars['DateTime'];
};

export type UpdateSpaceInput = {
  centerId?: InputMaybe<Scalars['ID']>;
  color?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<SpaceIconEnum>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateStaffAllergyInput = {
  allergenId: Scalars['ID'];
  allergyId: Scalars['ID'];
  instructions: Scalars['String'];
  reactions: Array<Scalars['String']>;
  severity?: InputMaybe<AllergenSeverity>;
  staffId: Scalars['ID'];
};

/** temp - so the schema builds for the current updateStaffPositionsAtScope mutation */
export type UpdateStaffPositionsAtScopeInput = {
  id: Scalars['ID'];
  positions: Array<UpdatedPositionInput>;
  scopeId: Scalars['ID'];
};

export type UpdateSubscriptionInput = {
  billingActivitySuspended?: InputMaybe<Scalars['Boolean']>;
  freeTrialEndDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
};

export type UpdateTagInput = {
  category?: InputMaybe<TagCategory>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateThemeInput = {
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  unitId: Scalars['ID'];
};

export type UpdateThemeLessonExperienceInput = {
  attachments?: InputMaybe<Array<Scalars['ID']>>;
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  experienceId: Scalars['ID'];
  instructions?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  outcomes: Array<Scalars['ID']>;
  programIds: Array<Scalars['ID']>;
  subjectId?: InputMaybe<Scalars['ID']>;
  supplies: Array<EducationExperienceSupplyItemInput>;
  themeId: Scalars['ID'];
  themeLessonId: Scalars['ID'];
  unitId: Scalars['ID'];
};

export type UpdateTimeEntryInput = {
  centerId: Scalars['ID'];
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['ID']>;
  positionId?: InputMaybe<Scalars['ID']>;
  scheduleId?: InputMaybe<Scalars['ID']>;
  shiftId?: InputMaybe<Scalars['ID']>;
  trackedTimeIn?: InputMaybe<Scalars['DateTime']>;
  trackedTimeOut?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<TimeEntryType>;
};

export type UpdateTimeOffRequestInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  centerId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  hoursApproved?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  personId?: InputMaybe<Scalars['ID']>;
  ptoType?: InputMaybe<Scalars['ID']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<TimeOffRequestStatus>;
};

export type UpdateTrainingTimeEventInput = {
  date: Scalars['Date'];
  hours: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  ptoTypeId?: InputMaybe<Scalars['ID']>;
  staffIds: Array<Scalars['ID']>;
  type: TrainingTimeEventType;
};

export type UpdateTransactionTypeInput = {
  centerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  glCode?: InputMaybe<Scalars['String']>;
  glCodeMapping?: InputMaybe<GlCodeMappingInput>;
  id: Scalars['ID'];
  isDebit: Scalars['Boolean'];
  isTuition: Scalars['Boolean'];
  name: Scalars['String'];
};

export type UpdateUnitInput = {
  businessId: Scalars['ID'];
  curriculumId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateWellnessConditionDocumentsInput = {
  toArchive: Array<Scalars['ID']>;
  toCreate: Array<ChildMedicalDocumentationInput>;
  toDelete: Array<Scalars['ID']>;
  toUpdate: Array<UpdateChildMedicalDocumentationInput>;
  wellnessConditionId: Scalars['ID'];
};

/** temp */
export type UpdatedPositionInput = {
  id?: InputMaybe<Scalars['ID']>;
  scopeId: Scalars['String'];
  scopeType: ScopeType;
  title: Scalars['String'];
};

export enum UploadState {
  Created = 'Created',
  Linked = 'Linked',
  Uploaded = 'Uploaded',
}

export type UpsertApplicationDepositInput = {
  applicationId: Scalars['String'];
  businessId: Scalars['String'];
  deposit: EnrolApplicationDeposit;
};

export type UpsertCcrmCredentialsInput = {
  businessId: Scalars['ID'];
  foreignTenantId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UpsertCcrmCredentialsResponse = {
  __typename?: 'UpsertCcrmCredentialsResponse';
  businessId: Scalars['ID'];
  foreignTenantId?: Maybe<Scalars['String']>;
  integrationType: IntegrationType;
  key: Scalars['String'];
};

export type UpsertClassCapacityOverrideInput = {
  capacity: Scalars['Int'];
  classId: Scalars['ID'];
  date: Scalars['DateTime'];
};

export type UpsertHealthProfessionalInput = {
  accountChildIds: Array<Scalars['ID']>;
  address: HealthProfessionalAddressInput;
  businessId: Scalars['ID'];
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  practice: Scalars['String'];
  professionalType: Scalars['String'];
};

export type UpsertIntegrationMappingsInput = {
  businessId: Scalars['ID'];
  message: CreateOrUpdateIntegrationMappingMessage;
};

export type UpsertMedicareCardInput = {
  accountChildId: Scalars['ID'];
  businessId: Scalars['ID'];
  expiry: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  medicareCardNumber: Scalars['String'];
  position: Scalars['String'];
};

export type UserAcknowledgementPerson = {
  __typename?: 'UserAcknowledgementPerson';
  id?: Maybe<Scalars['String']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type UserActivity = {
  __typename?: 'UserActivity';
  activityId?: Maybe<Scalars['ID']>;
  activityTime?: Maybe<Scalars['Float']>;
  activityType?: Maybe<ActivityType>;
  channelId?: Maybe<Scalars['ID']>;
  channelIdentifier?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  childContactRelationship?: Maybe<AccountChildContactRelationship>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  person?: Maybe<ActivityPerson>;
  personId?: Maybe<Scalars['ID']>;
};

export type UserActivityAcknowledgements = {
  __typename?: 'UserActivityAcknowledgements';
  createdAt?: Maybe<Scalars['String']>;
  parentUserActivityId?: Maybe<Scalars['ID']>;
  person?: Maybe<UserAcknowledgementPerson>;
  userActivityId?: Maybe<Scalars['ID']>;
};

export type UserActivityFlaggedMessages = {
  __typename?: 'UserActivityFlaggedMessages';
  createdAt?: Maybe<Scalars['String']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  parentUserActivityId?: Maybe<Scalars['ID']>;
  person?: Maybe<FlagMessagePerson>;
  resolvedAt?: Maybe<Scalars['String']>;
  resolvedPerson?: Maybe<FlagMessagePerson>;
  userActivityId?: Maybe<Scalars['ID']>;
};

export type UserActivityMessageDirectedDto = {
  directedAtId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<MessageDirectedAtType>;
};

export type UserActivityPagedResult = {
  __typename?: 'UserActivityPagedResult';
  data: Array<UserActivity>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDtos: Array<SortDto>;
  totalRecords: Scalars['Int'];
};

export type UserActivityPerson = {
  __typename?: 'UserActivityPerson';
  hasKTConnect?: Maybe<Scalars['Boolean']>;
  isPrimaryContact?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['ID']>;
  type?: Maybe<PersonType>;
};

export type UserActivityThreadMessages = {
  __typename?: 'UserActivityThreadMessages';
  activityTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  sender?: Maybe<ThreadMessageSender>;
  text?: Maybe<Scalars['String']>;
};

export enum UserFlag {
  New = 'New',
  Read = 'Read',
}

export enum UserType {
  Guardian = 'GUARDIAN',
  Internal = 'INTERNAL',
  Staff = 'STAFF',
}

export type VerifyPinInput = {
  personId: Scalars['ID'];
  pin: Scalars['String'];
};

export enum VisaType {
  Ahmadi = 'Ahmadi',
  BridgingVisaA = 'BridgingVisaA',
  BridgingVisaB = 'BridgingVisaB',
  BridgingVisaC = 'BridgingVisaC',
  BridgingVisaE = 'BridgingVisaE',
  BurmeseInBurma = 'BurmeseInBurma',
  BurmeseInThailand = 'BurmeseInThailand',
  BusinessInnovationInvestment = 'BusinessInnovationInvestment',
  CitizensOfTheFormerYugoslavia = 'CitizensOfTheFormerYugoslavia',
  ContributoryAgedParent = 'ContributoryAgedParent',
  DependentChild = 'DependentChild',
  EmergencyRescueVisa = 'EmergencyRescueVisa',
  GlobalSpecialHumanitarianProgramVisa = 'GlobalSpecialHumanitarianProgramVisa',
  InCountrySpecialHumanitarianVisa = 'InCountrySpecialHumanitarianVisa',
  KosovarSafeHaven = 'KosovarSafeHaven',
  MaritimeCrew = 'MaritimeCrew',
  NewZealandCitizenFamilyResponsibility = 'NewZealandCitizenFamilyResponsibility',
  None = 'None',
  Other = 'Other',
  Partner = 'Partner',
  PartnerMigrantVisa = 'PartnerMigrantVisa',
  ProspectiveMarriage = 'ProspectiveMarriage',
  ProtectionVisa = 'ProtectionVisa',
  RefugeeVisa = 'RefugeeVisa',
  ReturnPendingRepealed = 'ReturnPendingRepealed',
  SafeHavenEnterpriseVisa = 'SafeHavenEnterpriseVisa',
  SecondaryMovementAndRelocation = 'SecondaryMovementAndRelocation',
  SecondaryMovementOffshoreEntry = 'SecondaryMovementOffshoreEntry',
  SkilledEmployerSponsoredRegional = 'SkilledEmployerSponsoredRegional',
  SkilledRecognisedGraduate = 'SkilledRecognisedGraduate',
  SkilledRegional = 'SkilledRegional',
  SkilledWorkRegional = 'SkilledWorkRegional',
  SpecialCategoryVisa = 'SpecialCategoryVisa',
  SponsoredParent = 'SponsoredParent',
  Spouse = 'Spouse',
  SriLankanSpecialAssistance = 'SriLankanSpecialAssistance',
  StudentExchange = 'StudentExchange',
  StudentParentPrimaryVisaHolder = 'StudentParentPrimaryVisaHolder',
  Sudanese = 'Sudanese',
  TemporaryActivity = 'TemporaryActivity',
  TemporaryGraduate = 'TemporaryGraduate',
  TemporaryHumanitarianConcern = 'TemporaryHumanitarianConcern',
  TemporaryHumanitarianStay = 'TemporaryHumanitarianStay',
  TemporaryProtectionVisa = 'TemporaryProtectionVisa',
  TemporarySkillShortage = 'TemporarySkillShortage',
  TemporaryWorkInternationalRelations = 'TemporaryWorkInternationalRelations',
  TemporaryWorkShortStay = 'TemporaryWorkShortStay',
  TerritorialAsylumResidence = 'TerritorialAsylumResidence',
  Training = 'Training',
  WomanAtRiskVisa = 'WomanAtRiskVisa',
}

export type VoidEndCareSessionsInput = {
  accountChildId: Scalars['ID'];
  businessId: Scalars['ID'];
  sessionIds: Array<Scalars['ID']>;
};

export type VoidSessionInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  date: Scalars['Date'];
  reasonForLateChange?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type WaiveAbsenceGapFeeInput = {
  centerId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type WaiveLatePaymentFeeInput = {
  itemizedBillId: Scalars['ID'];
};

export enum WeekType {
  Week1 = 'WEEK1',
  Week2 = 'WEEK2',
}

export type WeeklyAttendanceReportInput = {
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  classIds: Array<Scalars['ID']>;
  startOfWeek: Scalars['Date'];
};

export type WeeklyPayrollPaidTimeEventTotals = {
  __typename?: 'WeeklyPayrollPaidTimeEventTotals';
  closure: Scalars['Float'];
  holiday: Scalars['Float'];
  outsideOfBusinessHours: Scalars['Float'];
  training: Scalars['Float'];
};

export type WeeklyPayrollTimeByPayCode = {
  __typename?: 'WeeklyPayrollTimeByPayCode';
  payCode: Scalars['String'];
  total: Scalars['Float'];
};

export enum WellnessDocumentType {
  Allergy = 'Allergy',
  Immunization = 'Immunization',
  Medical = 'Medical',
  Restriction = 'Restriction',
}

export type WellnessItemDocument = {
  __typename?: 'WellnessItemDocument';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  centerId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByPerson?: Maybe<Staff>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  link: Scalars['URL'];
  name: Scalars['String'];
  reviewDate?: Maybe<Scalars['Date']>;
  reviewDaysBeforeExpiration?: Maybe<Scalars['Int']>;
  /** type DocumentType from core2 */
  type: WellnessDocumentType;
  wellnessItemId: Scalars['ID'];
};

export enum YesNoType {
  No = 'No',
  NoneApplicable = 'NoneApplicable',
  Yes = 'Yes',
}

export type ReadAccountTransactionsResponse = {
  __typename?: 'readAccountTransactionsResponse';
  data?: Maybe<Array<Maybe<Transaction>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UpdateApplicationOfferAndSchedulesMutationVariables = Exact<{
  businessId: Scalars['ID'];
  applicationId: Scalars['ID'];
  applicationOfferId: Scalars['ID'];
  input: UpdateApplicationOfferRequest;
}>;

export type UpdateApplicationOfferAndSchedulesMutation = {
  __typename?: 'Mutation';
  updateApplicationOfferAndSchedules: {
    __typename?: 'UpdateApplicationOfferResponse';
    applicationId: string;
    applicationOfferId: string;
    applicationOfferExpiry?: any | null;
    applicationScheduleOffers: Array<{
      __typename?: 'ApplicationScheduleOffer';
      id: string;
      careType: string;
      startDate: any;
      endDate?: any | null;
      classId?: string | null;
      offerCenterId?: string | null;
      feeId?: string | null;
      feeAmount?: number | null;
      cycleType?: string | null;
      applicationOfferId?: string | null;
      state?: ApplicationScheduleOfferState | null;
      generationState?: ApplicationScheduleOfferGenerationState | null;
      programId?: string | null;
      days: Array<{ __typename?: 'ApplicationScheduleDay'; id: string; weekType: string; dayOfWeek: string }>;
    }>;
  };
};

export type CheckOfferAvailabilityQueryVariables = Exact<{
  businessId: Scalars['ID'];
  applicationId: Scalars['ID'];
  applicationOfferId: Scalars['ID'];
  input: UpdateApplicationOfferRequest;
}>;

export type CheckOfferAvailabilityQuery = {
  __typename?: 'Query';
  checkOfferAvailability: Array<{ __typename?: 'CheckAvailabilityDto'; scheduleOfferId: string; hasCapacity: boolean }>;
};

export type GetApplicationCenterSettingsPaginatedQueryVariables = Exact<{
  input: SearchInput;
}>;

export type GetApplicationCenterSettingsPaginatedQuery = {
  __typename?: 'Query';
  searchCenters: {
    __typename?: 'CenterSearchResults';
    totalResults: number;
    data: Array<{
      __typename?: 'Center';
      id: string;
      name: string;
      address?: { __typename?: 'Address'; state?: string | null } | null;
      tags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
      applicationSetting?: {
        __typename?: 'CenterSettings';
        businessId: string;
        centerId: string;
        feeType?: ApplicationFeeType | null;
        feeAmount: number;
        askFamilyAboutSubsidy: boolean;
        enrollmentOptions?: EnrollmentOptions | null;
        centerName?: string | null;
      } | null;
    }>;
  };
};

export type SetAttendanceCentersSettingMutationVariables = Exact<{
  input: SetAttendanceCenterSettingInput;
}>;

export type SetAttendanceCentersSettingMutation = {
  __typename?: 'Mutation';
  setAttendanceCentersSetting: {
    __typename?: 'AttendanceCenterSettings';
    businessUsesAttendanceSetting: boolean;
    entityId: string;
    centerId?: string | null;
    centerName?: string | null;
    usesTodayScreen: boolean;
  };
};

export type SetAttendanceBusinessSettingMutationVariables = Exact<{
  input: SetAttendanceBusinessSettingInput;
}>;

export type SetAttendanceBusinessSettingMutation = {
  __typename?: 'Mutation';
  setAttendanceBusinessSetting: {
    __typename?: 'AttendanceBusinessSettings';
    entityId: string;
    usesTodayScreen: boolean;
  };
};

export type ResetAttendanceCentersSettingMutationVariables = Exact<{
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
}>;

export type ResetAttendanceCentersSettingMutation = {
  __typename?: 'Mutation';
  resetAttendanceCentersSetting: {
    __typename?: 'AttendanceCenterSettings';
    centerId?: string | null;
    centerName?: string | null;
    usesTodayScreen: boolean;
    businessUsesAttendanceSetting: boolean;
  };
};

export type GetAttendanceCentersSettingQueryVariables = Exact<{
  input: SearchInput;
}>;

export type GetAttendanceCentersSettingQuery = {
  __typename?: 'Query';
  searchCenters: {
    __typename?: 'CenterSearchResults';
    totalResults: number;
    data: Array<{
      __typename?: 'Center';
      id: string;
      name: string;
      address?: { __typename?: 'Address'; state?: string | null } | null;
      tags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
      attendanceSetting?: {
        __typename?: 'AttendanceCenterSettings';
        businessUsesAttendanceSetting: boolean;
        centerId?: string | null;
        centerName?: string | null;
        fromDefault: boolean;
        entityId: string;
        usesTodayScreen: boolean;
      } | null;
    }>;
  };
};

export type GetAllAttendanceCentersSettingQueryVariables = Exact<{
  input: GetAttendanceCenterSettingInput;
}>;

export type GetAllAttendanceCentersSettingQuery = {
  __typename?: 'Query';
  getAttendanceCentersSetting: Array<{
    __typename?: 'AttendanceCenterSettings';
    businessUsesAttendanceSetting: boolean;
    centerId?: string | null;
    centerName?: string | null;
    fromDefault: boolean;
    entityId: string;
    usesTodayScreen: boolean;
  } | null>;
};

export type GetAttendanceBusinessSettingQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;

export type GetAttendanceBusinessSettingQuery = {
  __typename?: 'Query';
  getAttendanceBusinessSetting: {
    __typename?: 'AttendanceBusinessSettings';
    entityId: string;
    usesTodayScreen: boolean;
  };
};

export type GetAuthenticationIdentityQueryVariables = Exact<{
  input: AuthenticationIdentityInput;
}>;

export type GetAuthenticationIdentityQuery = { __typename?: 'Query'; getAuthenticationIdentity?: string | null };

export type GetAuthenticationIdentityResponseQueryVariables = Exact<{
  input: AuthenticationIdentityInput;
}>;

export type GetAuthenticationIdentityResponseQuery = {
  __typename?: 'Query';
  getAuthenticationIdentityResponse: {
    __typename?: 'GetAuthenticationIdentityResponseMessage';
    idpIdentifier: string;
    onboardingCompleted: boolean;
  };
};

export type GetSsoLoginUrlQueryVariables = Exact<{
  input: SsoLoginElementsInput;
}>;

export type GetSsoLoginUrlQuery = { __typename?: 'Query'; getSSOLoginUrl: string };

export type GetPayrixDisbursementsQueryVariables = Exact<{
  input: GetPayrixDisbursementsInput;
}>;

export type GetPayrixDisbursementsQuery = {
  __typename?: 'Query';
  getPayrixDisbursements: {
    __typename?: 'PaginatedPayrixDisbursements';
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: Array<{
      __typename?: 'PayrixDisbursement';
      id: string;
      amount: number;
      created: any;
      description?: string | null;
      processed?: any | null;
      returnedAmount?: number | null;
      status: PayrixDisbursementStatus;
    } | null>;
  };
};

export type UpdateHubSpotCustomerIdMutationVariables = Exact<{
  businessId: Scalars['ID'];
  hubSpotCustomerId: Scalars['String'];
}>;

export type UpdateHubSpotCustomerIdMutation = {
  __typename?: 'Mutation';
  updateEntity?: { __typename?: 'Entity'; hubSpotCustomerId?: string | null } | null;
};

export type GetHubSpotCustomerIdQueryVariables = Exact<{
  businessId: Scalars['String'];
}>;

export type GetHubSpotCustomerIdQuery = {
  __typename?: 'Query';
  getEntity?: { __typename?: 'Entity'; hubSpotCustomerId?: string | null } | null;
};

export type ChildFieldsFragment = {
  __typename?: 'Child';
  id: string;
  firstname: string;
  lastname: string;
  nickname?: string | null;
  dob: string;
  indigenousStatus?: IndigenousStatus | null;
  gender?: Gender | null;
  grade?: Grade | null;
  restrictAccessToChildDocuments: boolean;
  fullName: string;
  avatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
  residesWith?: {
    __typename?: 'Contact';
    id: string;
    firstname: string;
    lastname: string;
    nickname?: string | null;
    address?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      country: Country;
    } | null;
  } | null;
  contacts: Array<{
    __typename?: 'ChildContact';
    id: string;
    firstname: string;
    lastname: string;
    nickname?: string | null;
    email: string;
    relationshipType: RelationshipType;
    avatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
    primaryPhoneNumber?: { __typename?: 'PhoneNumber'; number: string; type: string } | null;
    address?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      country: Country;
    } | null;
    accountPermissions: Array<{
      __typename?: 'AccountPermissions';
      accountId: string;
      isPrimary: boolean;
      permissions: Array<string>;
      account?: {
        __typename?: 'Account';
        id: string;
        name: string;
        center?: { __typename?: 'Center'; id: string; name: string } | null;
      } | null;
    }>;
  }>;
  accounts: Array<{
    __typename?: 'Account';
    id: string;
    name: string;
    entityId: string;
    centerId: string;
    center?: { __typename?: 'Center'; id: string; name: string } | null;
    children: Array<{ __typename?: 'AccountChild'; id: string; accountChildId: string; archivedAt?: string | null }>;
  }>;
  tags?: Array<{ __typename?: 'Tag'; id: string; entityId: string; name: string; category: TagCategory }> | null;
  emergencyContacts: Array<{
    __typename?: 'EmergencyContact';
    id: string;
    childId: string;
    contactId: string;
    firstName: string;
    lastName: string;
    relationshipType: RelationshipType;
    phoneNumber?: { __typename?: 'PhoneNumber'; number: string; type: string } | null;
  }>;
  ccssAttributes?: { __typename?: 'ChildCcssAttributes'; crn?: string | null; isCrnEditable: boolean } | null;
  mealStatus?: { __typename?: 'ChildMealStatus'; mealPricing?: MealPricing | null } | null;
};

export type UpdateRestrictChildDocumentsMutationVariables = Exact<{
  businessId: Scalars['ID'];
  childId: Scalars['ID'];
  restrictDocuments: Scalars['Boolean'];
}>;

export type UpdateRestrictChildDocumentsMutation = {
  __typename?: 'Mutation';
  updateRestrictChildDocuments?: {
    __typename?: 'Child';
    id: string;
    firstname: string;
    lastname: string;
    nickname?: string | null;
    dob: string;
    indigenousStatus?: IndigenousStatus | null;
    gender?: Gender | null;
    grade?: Grade | null;
    restrictAccessToChildDocuments: boolean;
    fullName: string;
    avatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
    residesWith?: {
      __typename?: 'Contact';
      id: string;
      firstname: string;
      lastname: string;
      nickname?: string | null;
      address?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        country: Country;
      } | null;
    } | null;
    contacts: Array<{
      __typename?: 'ChildContact';
      id: string;
      firstname: string;
      lastname: string;
      nickname?: string | null;
      email: string;
      relationshipType: RelationshipType;
      avatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
      primaryPhoneNumber?: { __typename?: 'PhoneNumber'; number: string; type: string } | null;
      address?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        country: Country;
      } | null;
      accountPermissions: Array<{
        __typename?: 'AccountPermissions';
        accountId: string;
        isPrimary: boolean;
        permissions: Array<string>;
        account?: {
          __typename?: 'Account';
          id: string;
          name: string;
          center?: { __typename?: 'Center'; id: string; name: string } | null;
        } | null;
      }>;
    }>;
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      entityId: string;
      centerId: string;
      center?: { __typename?: 'Center'; id: string; name: string } | null;
      children: Array<{ __typename?: 'AccountChild'; id: string; accountChildId: string; archivedAt?: string | null }>;
    }>;
    tags?: Array<{ __typename?: 'Tag'; id: string; entityId: string; name: string; category: TagCategory }> | null;
    emergencyContacts: Array<{
      __typename?: 'EmergencyContact';
      id: string;
      childId: string;
      contactId: string;
      firstName: string;
      lastName: string;
      relationshipType: RelationshipType;
      phoneNumber?: { __typename?: 'PhoneNumber'; number: string; type: string } | null;
    }>;
    ccssAttributes?: { __typename?: 'ChildCcssAttributes'; crn?: string | null; isCrnEditable: boolean } | null;
    mealStatus?: { __typename?: 'ChildMealStatus'; mealPricing?: MealPricing | null } | null;
  } | null;
};

export type CreateChatActivityMutationVariables = Exact<{
  businessId: Scalars['ID'];
  input?: InputMaybe<CreateActivityInput>;
}>;

export type CreateChatActivityMutation = {
  __typename?: 'Mutation';
  createChatActivity?: {
    __typename?: 'CreateActivity';
    channelType?: ChannelType | null;
    channelId?: string | null;
    personId?: string | null;
    activityType?: ActivityType | null;
    activityTime?: number | null;
    messageContent?: string | null;
  } | null;
};

export type DeleteMessageMutationVariables = Exact<{
  businessId: Scalars['ID'];
  body?: InputMaybe<DeleteMessageInput>;
}>;

export type DeleteMessageMutation = {
  __typename?: 'Mutation';
  deleteMessage?: {
    __typename?: 'CreateActivity';
    channelType?: ChannelType | null;
    channelId?: string | null;
    personId?: string | null;
    activityType?: ActivityType | null;
    activityTime?: number | null;
    messageContent?: string | null;
  } | null;
};

export type FlagMessageMutationVariables = Exact<{
  businessId: Scalars['ID'];
  body?: InputMaybe<FlagMessageInput>;
}>;

export type FlagMessageMutation = {
  __typename?: 'Mutation';
  flagMessage?: {
    __typename?: 'CreateActivity';
    channelType?: ChannelType | null;
    channelId?: string | null;
    personId?: string | null;
    activityType?: ActivityType | null;
    activityTime?: number | null;
    messageContent?: string | null;
  } | null;
};

export type SetCommsBusinessSettingsMutationVariables = Exact<{
  businessId: Scalars['ID'];
  input?: InputMaybe<SetCommsBusinessSettingsInput>;
}>;

export type SetCommsBusinessSettingsMutation = {
  __typename?: 'Mutation';
  setCommsBusinessSettings?: {
    __typename?: 'CommsBusinessSetting';
    businessId?: string | null;
    usesComms?: boolean | null;
    usesBroadcastChannels?: boolean | null;
    usesAccountChannels?: boolean | null;
    usesBulletinBoards?: boolean | null;
    usesInactiveAccounts?: boolean | null;
    usesStudentChannels?: boolean | null;
  } | null;
};

export type SetCommsCenterSettingsMutationVariables = Exact<{
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
  input?: InputMaybe<SetCommsCenterSettingsInput>;
}>;

export type SetCommsCenterSettingsMutation = {
  __typename?: 'Mutation';
  setCommsCenterSettings?: {
    __typename?: 'CommsCenterSetting';
    businessId?: string | null;
    centerId?: string | null;
    centerName?: string | null;
    usesBroadcastChannels?: boolean | null;
    usesAccountChannels?: boolean | null;
    usesBulletinBoards?: boolean | null;
    usesInactiveAccounts?: boolean | null;
    usesStudentChannels?: boolean | null;
    state?: string | null;
    fromDefault?: boolean | null;
    businessUsesComms?: boolean | null;
  } | null;
};

export type ResetCenterSettingsMutationVariables = Exact<{
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
}>;

export type ResetCenterSettingsMutation = {
  __typename?: 'Mutation';
  resetCenterSettings?: {
    __typename?: 'CommsCenterSetting';
    businessId?: string | null;
    centerId?: string | null;
    centerName?: string | null;
    usesBroadcastChannels?: boolean | null;
    usesAccountChannels?: boolean | null;
    usesBulletinBoards?: boolean | null;
    usesStudentChannels?: boolean | null;
    fromDefault?: boolean | null;
    businessUsesComms?: boolean | null;
  } | null;
};

export type GetAccountChannelsQueryVariables = Exact<{
  businessId: Scalars['ID'];
  accountId?: InputMaybe<Scalars['ID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  lastActive?: InputMaybe<Scalars['String']>;
  centerId?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  orderByLastJoined?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type GetAccountChannelsQuery = {
  __typename?: 'Query';
  getAccountChannels?: {
    __typename?: 'ChannelDtoPagedResult';
    totalRecords: number;
    pageNumber: number;
    pageSize: number;
    data: Array<{
      __typename?: 'Channel';
      businessId: string;
      centerGroup?: string | null;
      centerId: string;
      channelId: string;
      name?: string | null;
      description?: string | null;
      lastActive?: string | null;
      personId?: string | null;
      lastAccessedByPersonId?: string | null;
      hasUnreadMessages?: boolean | null;
      channelIdentifier: string;
      channelType?: ChannelType | null;
      accountStatus?: AccountStatusType | null;
      account?: {
        __typename?: 'CommsAccount';
        id: string;
        entityId: string;
        centerId: string;
        name: string;
        status?: AccountStatusType | null;
        tags?: Array<string | null> | null;
        contacts?: Array<{
          __typename?: 'Contact';
          id: string;
          isPrimary: boolean;
          hasKtConnectAccess: boolean;
          firstname: string;
          lastname: string;
          nickname?: string | null;
          email: string;
          contactAccountPermissions?: Array<{
            __typename?: 'ContactAccountPermissions';
            accountId: string;
            permissions: Array<string>;
          } | null> | null;
          avatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
        } | null> | null;
        children?: Array<{
          __typename?: 'AccountChild';
          id: string;
          accountChildId: string;
          nickname?: string | null;
          fullName: string;
          firstname: string;
          lastname: string;
        } | null> | null;
      } | null;
    }>;
  } | null;
};

export type GetCenterChannelsQueryVariables = Exact<{
  businessId: Scalars['ID'];
  searchTerm?: InputMaybe<Scalars['String']>;
  lastActive?: InputMaybe<Scalars['String']>;
  centerId?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  orderByLastJoined?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type GetCenterChannelsQuery = {
  __typename?: 'Query';
  getCenterChannels?: {
    __typename?: 'ChannelDtoPagedResult';
    totalRecords: number;
    pageNumber: number;
    pageSize: number;
    data: Array<{
      __typename?: 'Channel';
      businessId: string;
      centerGroup?: string | null;
      centerId: string;
      channelId: string;
      name?: string | null;
      description?: string | null;
      lastActive?: string | null;
      personId?: string | null;
      lastAccessedByPersonId?: string | null;
      hasUnreadMessages?: boolean | null;
      channelIdentifier: string;
      channelType?: ChannelType | null;
    }>;
  } | null;
};

export type GetBulletinBoardChannelsQueryVariables = Exact<{
  businessId: Scalars['ID'];
  searchTerm?: InputMaybe<Scalars['String']>;
  lastActive?: InputMaybe<Scalars['String']>;
  centerId?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  orderByLastJoined?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type GetBulletinBoardChannelsQuery = {
  __typename?: 'Query';
  getBulletinBoardChannels?: {
    __typename?: 'ChannelDtoPagedResult';
    totalRecords: number;
    pageNumber: number;
    pageSize: number;
    data: Array<{
      __typename?: 'Channel';
      businessId: string;
      centerGroup?: string | null;
      centerId: string;
      channelId: string;
      name?: string | null;
      description?: string | null;
      lastActive?: string | null;
      personId?: string | null;
      lastAccessedByPersonId?: string | null;
      hasUnreadMessages?: boolean | null;
      channelIdentifier: string;
      channelType?: ChannelType | null;
    }>;
  } | null;
};

export type GetCenterChannelQueryVariables = Exact<{
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
}>;

export type GetCenterChannelQuery = {
  __typename?: 'Query';
  getCenterChannel?: {
    __typename?: 'Channel';
    businessId: string;
    centerGroup?: string | null;
    centerId: string;
    channelId: string;
    name?: string | null;
    description?: string | null;
    lastActive?: string | null;
    personId?: string | null;
    lastAccessedByPersonId?: string | null;
    hasUnreadMessages?: boolean | null;
    channelIdentifier: string;
    channelType?: ChannelType | null;
  } | null;
};

export type GetAccountChannelQueryVariables = Exact<{
  businessId: Scalars['ID'];
  accountId: Scalars['ID'];
}>;

export type GetAccountChannelQuery = {
  __typename?: 'Query';
  getAccountChannel?: {
    __typename?: 'Channel';
    businessId: string;
    centerGroup?: string | null;
    centerId: string;
    channelId: string;
    name?: string | null;
    description?: string | null;
    lastActive?: string | null;
    personId?: string | null;
    lastAccessedByPersonId?: string | null;
    hasUnreadMessages?: boolean | null;
    channelIdentifier: string;
    channelType?: ChannelType | null;
  } | null;
};

export type GetBulletinBoardChannelQueryVariables = Exact<{
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
}>;

export type GetBulletinBoardChannelQuery = {
  __typename?: 'Query';
  getBulletinBoardChannel?: {
    __typename?: 'Channel';
    businessId: string;
    centerGroup?: string | null;
    centerId: string;
    channelId: string;
    name?: string | null;
    description?: string | null;
    lastActive?: string | null;
    personId?: string | null;
    lastAccessedByPersonId?: string | null;
    hasUnreadMessages?: boolean | null;
    channelIdentifier: string;
    channelType?: ChannelType | null;
  } | null;
};

export type GetChatActivityQueryVariables = Exact<{
  businessId: Scalars['ID'];
  id?: InputMaybe<Scalars['String']>;
  channelId?: InputMaybe<Scalars['ID']>;
  personId?: InputMaybe<Scalars['ID']>;
  pubNubMessageId?: InputMaybe<Scalars['String']>;
  timeToken?: InputMaybe<Scalars['Float']>;
  activityType?: InputMaybe<ActivityType>;
  activitySince?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type GetChatActivityQuery = {
  __typename?: 'Query';
  getChatActivity?: {
    __typename?: 'UserActivityPagedResult';
    totalRecords: number;
    pageNumber: number;
    pageSize: number;
    data: Array<{
      __typename?: 'UserActivity';
      channelId?: string | null;
      channelType?: ChannelType | null;
      channelIdentifier?: string | null;
      name?: string | null;
      id?: string | null;
      personId?: string | null;
      activityType?: ActivityType | null;
      activityTime?: number | null;
      person?: {
        __typename?: 'ActivityPerson';
        id?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        type?: string | null;
      } | null;
    }>;
  } | null;
};

export type GetChatAuthQueryVariables = Exact<{
  businessId: Scalars['ID'];
  returnWildcardChannels?: InputMaybe<Scalars['Boolean']>;
  channelId?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type GetChatAuthQuery = {
  __typename?: 'Query';
  getChatAuth?: Array<{
    __typename?: 'ChannelAndAuth';
    channelIdentifier?: string | null;
    channelId?: string | null;
    auth?: {
      __typename?: 'AuthDto';
      personId?: string | null;
      canWriteMessage?: boolean | null;
      canDeleteMessage?: boolean | null;
      canReportMessage?: boolean | null;
    } | null;
  } | null> | null;
};

export type GetChatAuthTokenQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;

export type GetChatAuthTokenQuery = {
  __typename?: 'Query';
  getChatAuthToken?: {
    __typename?: 'AuthToken';
    authToken?: string | null;
    expiryDateTimeOffset?: string | null;
    publishKey?: string | null;
    subscribeKey?: string | null;
  } | null;
};

export type GetAuthBroadcastChannelIdsQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;

export type GetAuthBroadcastChannelIdsQuery = {
  __typename?: 'Query';
  getAuthBroadcastChannelIds?: { __typename?: 'AuthIds'; centerChannels?: Array<string | null> | null } | null;
};

export type GetAuthBulletinBoardChannelIdsQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;

export type GetAuthBulletinBoardChannelIdsQuery = {
  __typename?: 'Query';
  getAuthBulletinBoardChannelIds?: {
    __typename?: 'BulletinBoardAuthIds';
    bulletinBoardChannels?: Array<string | null> | null;
  } | null;
};

export type GetChannelUsersQueryVariables = Exact<{
  businessId: Scalars['ID'];
  channelId: Scalars['ID'];
}>;

export type GetChannelUsersQuery = {
  __typename?: 'Query';
  getChannelUsers?: Array<{
    __typename?: 'UserActivityPerson';
    personId?: string | null;
    name?: string | null;
    type?: PersonType | null;
    isPrimaryContact?: boolean | null;
    hasKTConnect?: boolean | null;
  }> | null;
};

export type GetCommsBusinessSettingsQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;

export type GetCommsBusinessSettingsQuery = {
  __typename?: 'Query';
  getCommsBusinessSettings?: {
    __typename?: 'CommsBusinessSetting';
    businessId?: string | null;
    usesComms?: boolean | null;
    usesBroadcastChannels?: boolean | null;
    usesAccountChannels?: boolean | null;
    usesBulletinBoards?: boolean | null;
    usesInactiveAccounts?: boolean | null;
    usesStudentChannels?: boolean | null;
    centerCount?: number | null;
  } | null;
};

export type GetCommsAllCenterSettingsQueryVariables = Exact<{
  businessId: Scalars['ID'];
  centerId?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  showOverrides?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type GetCommsAllCenterSettingsQuery = {
  __typename?: 'Query';
  getCommsAllCenterSettings?: {
    __typename?: 'CommsCenterSettingPaged';
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: Array<{
      __typename?: 'CommsCenterSetting';
      businessId?: string | null;
      centerId?: string | null;
      centerName?: string | null;
      usesBroadcastChannels?: boolean | null;
      usesAccountChannels?: boolean | null;
      usesBulletinBoards?: boolean | null;
      usesInactiveAccounts?: boolean | null;
      usesStudentChannels?: boolean | null;
      fromDefault?: boolean | null;
      businessUsesComms?: boolean | null;
      state?: string | null;
      tags?: Array<string | null> | null;
    }>;
  } | null;
};

export type GetCommsCenterSettingsQueryVariables = Exact<{
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
}>;

export type GetCommsCenterSettingsQuery = {
  __typename?: 'Query';
  getCommsCenterSettings?: {
    __typename?: 'CommsCenterSetting';
    businessId?: string | null;
    centerId?: string | null;
    centerName?: string | null;
    usesBroadcastChannels?: boolean | null;
    usesAccountChannels?: boolean | null;
    usesBulletinBoards?: boolean | null;
    usesStudentChannels?: boolean | null;
    fromDefault?: boolean | null;
    businessUsesComms?: boolean | null;
  } | null;
};

export type GetMentionableEntitiesAtCenterQueryVariables = Exact<{
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
}>;

export type GetMentionableEntitiesAtCenterQuery = {
  __typename?: 'Query';
  getCenterStaff?: Array<{
    __typename?: 'CenterStaff';
    centerId?: string | null;
    staff?: Array<{ __typename?: 'StaffDto'; id?: string | null; name?: string | null; email: string } | null> | null;
  }> | null;
  getCenterClasses?: Array<{
    __typename?: 'CenterClasses';
    centerId?: string | null;
    classes?: Array<{ __typename?: 'ClassDto'; id?: string | null; name?: string | null } | null> | null;
  }> | null;
};

export type GetThreadMessagesQueryVariables = Exact<{
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
}>;

export type GetThreadMessagesQuery = {
  __typename?: 'Query';
  getThreadMessages?: Array<{
    __typename?: 'CenterThreadMessages';
    centerId?: string | null;
    messages?: Array<{
      __typename?: 'ParentThreadMessagesDto';
      rootPubNubMessageId?: string | null;
      parentMessage?: {
        __typename?: 'ThreadMessageDto';
        id?: string | null;
        activityTime?: number | null;
        text?: string | null;
        createdAt?: string | null;
        sender?: {
          __typename?: 'ThreadMessageSender';
          id?: string | null;
          name?: string | null;
          profileUrl?: string | null;
        } | null;
        custom?: {
          __typename?: 'ThreadMessageCustom';
          directedAt?: Array<{
            __typename?: 'ThreadMessageDirectedAtDto';
            id?: string | null;
            displayName?: string | null;
            entityType?: string | null;
          } | null> | null;
        } | null;
      } | null;
      relatedMessages?: Array<{
        __typename?: 'ThreadMessageDto';
        id?: string | null;
        activityTime?: number | null;
        text?: string | null;
        createdAt?: string | null;
        sender?: {
          __typename?: 'ThreadMessageSender';
          id?: string | null;
          name?: string | null;
          profileUrl?: string | null;
        } | null;
        custom?: {
          __typename?: 'ThreadMessageCustom';
          directedAt?: Array<{
            __typename?: 'ThreadMessageDirectedAtDto';
            id?: string | null;
            displayName?: string | null;
            entityType?: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null> | null;
  }> | null;
};

export type SearchContactsV2QueryVariables = Exact<{
  input: SearchContactsV2Input;
}>;

export type SearchContactsV2Query = {
  __typename?: 'Query';
  searchContactsV2: {
    __typename?: 'SearchContactsV2PagedResult';
    totalRecords: number;
    pageNumber: number;
    pageSize: number;
    data: Array<{
      __typename?: 'Contact';
      email: string;
      firstname: string;
      hasKtConnectAccess: boolean;
      id: string;
      lastname: string;
      nickname?: string | null;
      accounts: Array<{ __typename?: 'Account'; id: string }>;
      avatar?: { __typename?: 'S3SignedUrl'; url: any; provisionalGetUrl?: any | null; fields?: any | null } | null;
      children: Array<{
        __typename?: 'ContactChild';
        dob: string;
        firstname: string;
        id: string;
        lastname: string;
        nickname?: string | null;
        relationshipType: RelationshipType;
        avatar?: { __typename?: 'S3SignedUrl'; url: any; provisionalGetUrl?: any | null; fields?: any | null } | null;
      }>;
      primaryPhoneNumber?: { __typename?: 'PhoneNumber'; number: string; type: string } | null;
    }>;
  };
};

export type AvatarFieldsFragment = {
  __typename?: 'S3SignedUrl';
  url: any;
  provisionalGetUrl?: any | null;
  fields?: any | null;
};

export type GetDocumentByIdQueryVariables = Exact<{
  businessId: Scalars['ID'];
  documentId: Scalars['ID'];
}>;

export type GetDocumentByIdQuery = {
  __typename?: 'Query';
  getDocumentById: {
    __typename?: 'CoreDocument';
    id: string;
    businessId: string;
    name: string;
    type: DocumentType;
    expirationDate?: any | null;
    link: any;
    reviewDaysBeforeExpiration?: number | null;
    reviewDate?: any | null;
    wellnessItemId: string;
    archivedAt?: any | null;
    archivedBy?: string | null;
    createdAt?: any | null;
    createdByPerson?: {
      __typename?: 'Staff';
      id: string;
      firstname?: string | null;
      lastname?: string | null;
      nickname?: string | null;
      avatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
    } | null;
  };
};

export type MarkApplicationReadMutationVariables = Exact<{
  businessId: Scalars['String'];
  applicationId: Scalars['String'];
}>;

export type MarkApplicationReadMutation = { __typename?: 'Mutation'; markRead?: boolean | null };

export type BulkApproveOffersMutationVariables = Exact<{
  input: ApplicationMultiApproveInput;
}>;

export type BulkApproveOffersMutation = {
  __typename?: 'Mutation';
  multiApproveApplication: Array<{
    __typename?: 'ApplicationMultiApproveResponse';
    isApproved: boolean;
    applicationId: string;
    message?: string | null;
    offers?: Array<{
      __typename?: 'ApplicationApproveOffer';
      offerId: string;
      isApproved: boolean;
      message?: string | null;
    } | null> | null;
  } | null>;
};

export type UpdateEnrolmentFormMutationVariables = Exact<{
  input: UpdateEnrollmentFormInput;
}>;

export type UpdateEnrolmentFormMutation = {
  __typename?: 'Mutation';
  updateEnrollmentForm: {
    __typename?: 'EnrollmentForm';
    id?: string | null;
    businessId: string;
    formSchema: any;
    name: string;
    usedByCenterIds?: Array<string> | null;
    version: number;
    isDraft: boolean;
  };
};

export type EncryptEnrollmentFormHashMutationVariables = Exact<{
  businessId: Scalars['ID'];
  enrollmentFormId: Scalars['ID'];
}>;

export type EncryptEnrollmentFormHashMutation = { __typename?: 'Mutation'; encryptEnrollmentFormHash: string };

export type MarkLostMutationVariables = Exact<{
  input?: InputMaybe<ApplicationMarkLostInput>;
}>;

export type MarkLostMutation = { __typename?: 'Mutation'; markLost?: boolean | null };

export type UpsertApplicationDepositMutationVariables = Exact<{
  input: UpsertApplicationDepositInput;
}>;

export type UpsertApplicationDepositMutation = {
  __typename?: 'Mutation';
  upsertApplicationDeposit: {
    __typename?: 'Application';
    id: string;
    applicationDeposits: Array<{
      __typename?: 'ApplicationDeposit';
      id: string;
      amount: number;
      description?: string | null;
      collected: boolean;
      appliesDate?: any | null;
    }>;
  };
};

export type RequestUpdateEnrolmentFormMutationVariables = Exact<{
  input: RequestUpdateEnrolmentFormInput;
}>;

export type RequestUpdateEnrolmentFormMutation = {
  __typename?: 'Mutation';
  requestUpdateEnrolmentForm?: string | null;
};

export type EditApplicationMutationVariables = Exact<{
  input: EditApplicationInput;
}>;

export type EditApplicationMutation = {
  __typename?: 'Mutation';
  editApplication: {
    __typename?: 'Application';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    children: Array<{
      __typename?: 'ApplicationChild';
      id: string;
      firstName: string;
      lastName: string;
      dateOfBirth: any;
      enquiries: Array<{ __typename?: 'ApplicationScheduleEnquiry'; id: string; startDate: any }>;
    }>;
  };
};

export type ApplicationNoteFieldsFragment = {
  __typename?: 'ApplicationNote';
  id: string;
  comment: string;
  createdAt: any;
  createdBy: string;
  createdByName?: string | null;
  archivedAt?: any | null;
  archivedByName?: string | null;
  lastEditedAt?: any | null;
  lastEditedByName?: string | null;
  createdByAvatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
};

export type CreateApplicationNoteMutationVariables = Exact<{
  input: CreateApplicationNoteInput;
}>;

export type CreateApplicationNoteMutation = {
  __typename?: 'Mutation';
  createApplicationNote: {
    __typename?: 'ApplicationNote';
    id: string;
    comment: string;
    createdAt: any;
    createdBy: string;
    createdByName?: string | null;
    archivedAt?: any | null;
    archivedByName?: string | null;
    lastEditedAt?: any | null;
    lastEditedByName?: string | null;
    createdByAvatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
  };
};

export type EditApplicationNoteMutationVariables = Exact<{
  input: EditApplicationNoteInput;
}>;

export type EditApplicationNoteMutation = {
  __typename?: 'Mutation';
  editApplicationNote: {
    __typename?: 'ApplicationNote';
    id: string;
    comment: string;
    createdAt: any;
    createdBy: string;
    createdByName?: string | null;
    archivedAt?: any | null;
    archivedByName?: string | null;
    lastEditedAt?: any | null;
    lastEditedByName?: string | null;
    createdByAvatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
  };
};

export type DeleteApplicationNoteMutationVariables = Exact<{
  input: DeleteApplicationNoteInput;
}>;

export type DeleteApplicationNoteMutation = {
  __typename?: 'Mutation';
  deleteApplicationNote: {
    __typename?: 'ApplicationNote';
    id: string;
    comment: string;
    createdAt: any;
    createdBy: string;
    createdByName?: string | null;
    archivedAt?: any | null;
    archivedByName?: string | null;
    lastEditedAt?: any | null;
    lastEditedByName?: string | null;
    createdByAvatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
  };
};

export type EnrollmentProgramFieldsFragment = {
  __typename?: 'EnrollmentProgram';
  id: string;
  businessId: string;
  name: string;
  isOpenForEnrollment: boolean;
  startDate: any;
  endDate: any;
  minEnrolmentDays: number;
  maxEnrolmentDays: number;
  operatingDays: Array<string | null>;
  description: string;
  programGroupId?: string | null;
  programCenters: Array<{
    __typename?: 'EnrollmentProgramCenter';
    id: string;
    programId: string;
    centerId: string;
    classId: string;
    feeId: string;
    centerName?: string | null;
    className?: string | null;
    feeName?: string | null;
    casualFeeId?: string | null;
    enrollmentOptions?: EnrollmentOptions | null;
    casualFeeName?: string | null;
  }>;
};

export type GetApplicationsByStageQueryVariables = Exact<{
  businessId: Scalars['String'];
  input: GetApplicationsInput;
}>;

export type GetApplicationsByStageQuery = {
  __typename?: 'Query';
  getApplicationsByStage: {
    __typename?: 'ApplicationPagedResult';
    totalRecords: number;
    pageNumber: number;
    data: Array<{
      __typename?: 'Application';
      id: string;
      businessId: string;
      state: ApplicationState;
      userFlag?: UserFlag | null;
      enquiryDate: any;
      firstName: string;
      lastName: string;
      phoneNumber: string;
      email: string;
      accountName: string;
      accountId?: string | null;
      centers: Array<string>;
      familyReceivesSubsidy?: boolean | null;
      enrollmentSource?: EnrolmentSource | null;
      appliedFlowType?: ApplicationFlowType | null;
      applicationDeposits: Array<{
        __typename?: 'ApplicationDeposit';
        id: string;
        amount: number;
        description?: string | null;
        collected: boolean;
        appliesDate?: any | null;
      }>;
      offers?: Array<{
        __typename?: 'ApplicationOffer';
        id: string;
        linkCode?: string | null;
        expiryDate?: any | null;
        enrolmentFormPdfLink?: string | null;
        applicationPaidAmount?: number | null;
        applicationPaidDate?: any | null;
        adminFeeRequired?: boolean | null;
        applicationPaymentReference?: string | null;
        enrolmentFormDataRecordId?: string | null;
        enrollmentFormChanged?: boolean | null;
        isAllScheduleOffersAcceptInWaitlisted?: boolean | null;
      } | null> | null;
      children: Array<{
        __typename?: 'ApplicationChild';
        id: string;
        firstName: string;
        lastName: string;
        dateOfBirth: any;
        isNew?: boolean | null;
        enquiries: Array<{
          __typename?: 'ApplicationScheduleEnquiry';
          id: string;
          careType: string;
          cycleType: string;
          startDate: any;
          days: Array<{ __typename?: 'ApplicationScheduleDay'; id: string; weekType: string; dayOfWeek: string }>;
        }>;
        offers: Array<{
          __typename?: 'ApplicationScheduleOffer';
          cycleType?: string | null;
          id: string;
          state?: ApplicationScheduleOfferState | null;
          generationState?: ApplicationScheduleOfferGenerationState | null;
          careType: string;
          startDate: any;
          endDate?: any | null;
          applicationOfferId?: string | null;
          feeId?: string | null;
          classId?: string | null;
          fee?: { __typename?: 'Fee'; name: string } | null;
          class?: { __typename?: 'Class'; name: string } | null;
          days: Array<{ __typename?: 'ApplicationScheduleDay'; id: string; weekType: string; dayOfWeek: string }>;
          program?: {
            __typename?: 'EnrollmentProgram';
            id: string;
            businessId: string;
            name: string;
            isOpenForEnrollment: boolean;
            startDate: any;
            endDate: any;
            minEnrolmentDays: number;
            maxEnrolmentDays: number;
            operatingDays: Array<string | null>;
            description: string;
            programGroupId?: string | null;
            programCenters: Array<{
              __typename?: 'EnrollmentProgramCenter';
              id: string;
              programId: string;
              centerId: string;
              classId: string;
              feeId: string;
              centerName?: string | null;
              className?: string | null;
              feeName?: string | null;
              casualFeeId?: string | null;
              enrollmentOptions?: EnrollmentOptions | null;
              casualFeeName?: string | null;
            }>;
          } | null;
        } | null>;
      }>;
      notes: Array<{
        __typename?: 'ApplicationNote';
        id: string;
        comment: string;
        createdAt: any;
        createdBy: string;
        createdByName?: string | null;
        archivedAt?: any | null;
        archivedByName?: string | null;
        lastEditedAt?: any | null;
        lastEditedByName?: string | null;
        createdByAvatar?: { __typename?: 'S3SignedUrl'; url: any } | null;
      } | null>;
    }>;
  };
};

export type GetEnrollmentProgramsQueryVariables = Exact<{
  input: GetEnrollmentProgramsInput;
}>;

export type GetEnrollmentProgramsQuery = {
  __typename?: 'Query';
  getEnrollmentPrograms?: {
    __typename?: 'EnrollmentProgramPagedResult';
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: Array<{
      __typename?: 'EnrollmentProgram';
      id: string;
      businessId: string;
      name: string;
      isOpenForEnrollment: boolean;
      startDate: any;
      endDate: any;
      minEnrolmentDays: number;
      maxEnrolmentDays: number;
      operatingDays: Array<string | null>;
      description: string;
      programGroupId?: string | null;
      programCenters: Array<{
        __typename?: 'EnrollmentProgramCenter';
        id: string;
        programId: string;
        centerId: string;
        classId: string;
        feeId: string;
        centerName?: string | null;
        className?: string | null;
        feeName?: string | null;
        casualFeeId?: string | null;
        enrollmentOptions?: EnrollmentOptions | null;
        casualFeeName?: string | null;
      }>;
    }>;
  } | null;
};

export type GetApplicationSettingQueryVariables = Exact<{
  input: GetApplicationSettingInput;
}>;

export type GetApplicationSettingQuery = {
  __typename?: 'Query';
  getApplicationSetting: {
    __typename?: 'ApplicationSetting';
    offersExpireInDays: number;
    flowType: ApplicationFlowType;
  };
};

export type GetCustomFieldByIdQueryVariables = Exact<{
  businessId: Scalars['ID'];
  customFieldId: Scalars['ID'];
}>;

export type GetCustomFieldByIdQuery = {
  __typename?: 'Query';
  getCustomFieldById: {
    __typename?: 'CustomField';
    id: string;
    label: string;
    attributesAsString?: string | null;
    type: CustomFieldType;
    centerIds?: Array<string> | null;
    archivedAt?: any | null;
    archivedBy?: string | null;
  };
};

export type GetAllActiveProgramsByCenterIdQueryVariables = Exact<{
  businessId: Scalars['ID'];
  centerId: Scalars['ID'];
}>;

export type GetAllActiveProgramsByCenterIdQuery = {
  __typename?: 'Query';
  getEnrollmentPrograms?: {
    __typename?: 'EnrollmentProgramPagedResult';
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: Array<{
      __typename?: 'EnrollmentProgram';
      id: string;
      name: string;
      minEnrolmentDays: number;
      maxEnrolmentDays: number;
      operatingDays: Array<string | null>;
      isOpenForEnrollment: boolean;
      startDate: any;
      endDate: any;
      programGroupId?: string | null;
      programCenters: Array<{
        __typename?: 'EnrollmentProgramCenter';
        centerId: string;
        feeName?: string | null;
        feeId: string;
        className?: string | null;
        classId: string;
        casualFeeId?: string | null;
        enrollmentOptions?: EnrollmentOptions | null;
        casualFeeName?: string | null;
      }>;
    }>;
  } | null;
};

export type GetCentreIdsHasFormAttachedQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;

export type GetCentreIdsHasFormAttachedQuery = {
  __typename?: 'Query';
  getCentreIdsHasFormAttached?: {
    __typename?: 'BusinessEnrolmentFormAssignment';
    allCentreAssigned?: boolean | null;
    centreIdsWithForm?: Array<string> | null;
  } | null;
};

export type CreateEnrollmentProgramGroupMutationVariables = Exact<{
  input: CreateEnrollmentProgramGroupInput;
}>;

export type CreateEnrollmentProgramGroupMutation = {
  __typename?: 'Mutation';
  createEnrollmentProgramGroup?: {
    __typename?: 'EnrollmentProgramGroup';
    id: string;
    name: string;
    description: string;
    isActive?: boolean | null;
  } | null;
};

export type UpdateEnrollmentProgramGroupMutationVariables = Exact<{
  input: UpdateEnrollmentProgramGroupInput;
}>;

export type UpdateEnrollmentProgramGroupMutation = {
  __typename?: 'Mutation';
  updateEnrollmentProgramGroup?: {
    __typename?: 'EnrollmentProgramGroup';
    id: string;
    name: string;
    description: string;
    isActive?: boolean | null;
  } | null;
};

export type DeleteEnrollmentProgramGroupMutationVariables = Exact<{
  businessId: Scalars['ID'];
  programGroupId: Scalars['ID'];
}>;

export type DeleteEnrollmentProgramGroupMutation = {
  __typename?: 'Mutation';
  deleteEnrollmentProgramGroup?: boolean | null;
};

export type GetEnrollmentProgramGroupsQueryVariables = Exact<{
  input: GetEnrollmentProgramGroupsInput;
}>;

export type GetEnrollmentProgramGroupsQuery = {
  __typename?: 'Query';
  getEnrollmentProgramGroups: {
    __typename?: 'EnrollmentProgramGroupPagedResult';
    totalRecords: number;
    data: Array<{
      __typename?: 'EnrollmentProgramGroup';
      id: string;
      name: string;
      businessId: string;
      description: string;
      programIds?: Array<string> | null;
      isActive?: boolean | null;
      endDt?: any | null;
      startDt?: any | null;
      programs?: Array<{ __typename?: 'EnrollmentProgram'; id: string; name: string; startDate: any } | null> | null;
    }>;
  };
};

export type UpsertCcrmCredentialsMutationVariables = Exact<{
  input: UpsertCcrmCredentialsInput;
}>;

export type UpsertCcrmCredentialsMutation = {
  __typename?: 'Mutation';
  upsertCcrmCredentials?: Array<{
    __typename?: 'UpsertCcrmCredentialsResponse';
    integrationType: IntegrationType;
    businessId: string;
    key: string;
    foreignTenantId?: string | null;
  } | null> | null;
};

export type UpsertIntegrationMappingsMutationVariables = Exact<{
  input: UpsertIntegrationMappingsInput;
}>;

export type UpsertIntegrationMappingsMutation = {
  __typename?: 'Mutation';
  upsertIntegrationMappings?: Array<{
    __typename?: 'GetIntegrationMappingResponse';
    internalId: string;
    externalId: string;
  }> | null;
};

export type SelectCcrmMappingMutationVariables = Exact<{
  input: ISelectExistingEntityInput;
}>;

export type SelectCcrmMappingMutation = { __typename?: 'Mutation'; selectCcrmMapping?: boolean | null };

export type ImportEzChildTrackFamiliesMutationVariables = Exact<{
  input?: InputMaybe<ImportEzChildTrackFamiliesInput>;
}>;

export type ImportEzChildTrackFamiliesMutation = { __typename?: 'Mutation'; importEzChildTrackFamilies?: any | null };

export type ImportEzChildTrackContractsMutationVariables = Exact<{
  input?: InputMaybe<ImportEzChildTrackContractsInput>;
}>;

export type ImportEzChildTrackContractsMutation = { __typename?: 'Mutation'; importEzChildTrackContracts?: any | null };

export type GetExternalTypesQueryVariables = Exact<{
  input: GetExternalTypesInput;
}>;

export type GetExternalTypesQuery = {
  __typename?: 'Query';
  getExternalTypes: Array<{ __typename?: 'GetExternalTypesResponse'; id: string; name: string }>;
};

export type GetIntegrationMappingsQueryVariables = Exact<{
  input: GetIntegrationMappingsInput;
}>;

export type GetIntegrationMappingsQuery = {
  __typename?: 'Query';
  getIntegrationMappings: Array<{
    __typename?: 'GetIntegrationMappingResponse';
    internalId: string;
    externalId: string;
  }>;
};

export type GetCcrmExistingContactMappingQueryVariables = Exact<{
  input: GetCcrmExistingContactMappingInput;
}>;

export type GetCcrmExistingContactMappingQuery = {
  __typename?: 'Query';
  getCcrmExistingContactMapping: { __typename?: 'GetCcrmExistingContactMappingResponse'; mappingRequired: boolean };
};

export type GetCcrmExistingChildMappingQueryVariables = Exact<{
  input: GetCcrmExistingChildMappingInput;
}>;

export type GetCcrmExistingChildMappingQuery = {
  __typename?: 'Query';
  getCcrmExistingChildMapping: { __typename?: 'GetCcrmExistingChildMappingResponse'; mappingRequired: boolean };
};

export type GetExistingEntitiesMappingByContactQueryVariables = Exact<{
  input: GetCcrmExistingEntityMappingInput;
}>;

export type GetExistingEntitiesMappingByContactQuery = {
  __typename?: 'Query';
  getExistingEntitiesMapping: {
    __typename?: 'GetCcrmExistingEntityMappingResponse';
    existingContactMapping: Array<{
      __typename?: 'ExistingContactMapping';
      id: string;
      businessId: string;
      mappingGroupId: string;
      accountId: string;
      contactId: string;
      ccrmFamilyId?: string | null;
      ccrmContactId?: string | null;
      isGuardian?: boolean | null;
      ccrmContactFullName?: string | null;
      ccrmPrimaryPhoneNumber?: string | null;
      ccrmEmailAddress?: string | null;
      ccrmChildren?: Array<string | null> | null;
      selected?: boolean | null;
      ktContactFullName?: string | null;
      ktContactPrimaryPhoneNumber?: string | null;
      ktContactEmailAddress?: string | null;
    }>;
  };
};

export type GetExistingEntitiesMappingByChildQueryVariables = Exact<{
  input: GetCcrmExistingEntityMappingInput;
}>;

export type GetExistingEntitiesMappingByChildQuery = {
  __typename?: 'Query';
  getExistingEntitiesMapping: {
    __typename?: 'GetCcrmExistingEntityMappingResponse';
    existingChildrenMapping: Array<{
      __typename?: 'ExistingChildrenMapping';
      id: string;
      businessId: string;
      mappingGroupId: string;
      accountId: string;
      childId: string;
      ccrmFamilyId?: string | null;
      ccrmChildId?: string | null;
      ccrmChildFullName?: string | null;
      ccrmChildDateOfBirth?: string | null;
      ccrmGuardianFullName?: string | null;
      selected?: boolean | null;
      ktChildFullName?: string | null;
      ktChildDateOfBirth?: string | null;
      ktChildAccountName?: string | null;
      ktPrimaryContactName?: string | null;
    }>;
  };
};

export type GetCcrmCredentialsQueryVariables = Exact<{
  input: GetCcrmCredentialsInput;
}>;

export type GetCcrmCredentialsQuery = {
  __typename?: 'Query';
  getCcrmCredentials: { __typename?: 'GetCcrmCredentialsResponse'; username?: string | null };
};

export type CheckCurrentCcrmCredentialsQueryVariables = Exact<{
  input: CheckCurrentCcrmCredentialsInput;
}>;

export type CheckCurrentCcrmCredentialsQuery = { __typename?: 'Query'; checkCurrentCcrmCredentials: boolean };

export type ErrorFragment = {
  __typename?: 'ErrorDto';
  code: string;
  message: string;
  target?: string | null;
  innerError?: string | null;
};

export type FreeKindyCenterSummaryFragment = {
  __typename?: 'FreeKindyCenterSummaryDto';
  centerId: string;
  centerName: string;
  period: string;
  year: number;
  childCount: number;
  fundedAmount?: number | null;
  baseAmount?: number | null;
  universalInclusionAmount?: number | null;
  ariaTeacherAmount?: number | null;
  freeKindyAmount?: number | null;
  status?: string | null;
  errors: Array<{
    __typename?: 'ErrorDto';
    code: string;
    message: string;
    target?: string | null;
    innerError?: string | null;
  }>;
};

export type FreeKindyChildSummaryFragment = {
  __typename?: 'FreeKindyChildSummaryDto';
  centerId: string;
  centerName: string;
  childId: string;
  childName?: string | null;
  fundedAmount?: number | null;
  period: string;
  status?: string | null;
  year: number;
};

export type TransitionStatementFragment = {
  __typename?: 'TransitionStatementDto';
  childId: string;
  childName?: string | null;
  centerId: string;
  centerName?: string | null;
  catholicCount?: number | null;
  independentCount?: number | null;
  stateCount?: number | null;
};

export type CreateEditTransitionStatementMutationVariables = Exact<{
  input: CreateEditTransitionStatementInput;
}>;

export type CreateEditTransitionStatementMutation = {
  __typename?: 'Mutation';
  createEditTransitionStatement: {
    __typename?: 'TransitionStatementDto';
    childId: string;
    childName?: string | null;
    centerId: string;
    centerName?: string | null;
    catholicCount?: number | null;
    independentCount?: number | null;
    stateCount?: number | null;
  };
};

export type GetFreeKindyCenterSummariesQueryVariables = Exact<{
  input: GetPagedKindyForAllCenterSummariesInput;
}>;

export type GetFreeKindyCenterSummariesQuery = {
  __typename?: 'Query';
  getFreeKindyCenterSummaries: {
    __typename?: 'GetPagedFreeKindyCenterSummariesResult';
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: Array<{
      __typename?: 'FreeKindyCenterSummaryDto';
      centerId: string;
      centerName: string;
      period: string;
      year: number;
      childCount: number;
      fundedAmount?: number | null;
      baseAmount?: number | null;
      universalInclusionAmount?: number | null;
      ariaTeacherAmount?: number | null;
      freeKindyAmount?: number | null;
      status?: string | null;
      errors: Array<{
        __typename?: 'ErrorDto';
        code: string;
        message: string;
        target?: string | null;
        innerError?: string | null;
      }>;
    }>;
  };
};

export type GetFreeKindyChildSummariesQueryVariables = Exact<{
  input: GetPagedKindyForAllChildSummariesInput;
}>;

export type GetFreeKindyChildSummariesQuery = {
  __typename?: 'Query';
  getFreeKindyChildSummaries: {
    __typename?: 'GetPagedFreeKindyChildSummariesResult';
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: Array<{
      __typename?: 'FreeKindyChildSummaryDto';
      centerId: string;
      centerName: string;
      childId: string;
      childName?: string | null;
      fundedAmount?: number | null;
      period: string;
      status?: string | null;
      year: number;
    }>;
  };
};

export type GetFreeKindyAcquittalCenterSummariesQueryVariables = Exact<{
  input: GetPagedKindyForAllCenterSummariesInput;
}>;

export type GetFreeKindyAcquittalCenterSummariesQuery = {
  __typename?: 'Query';
  getFreeKindyAcquittalCenterSummaries: {
    __typename?: 'GetPagedFreeKindyAcquittalCenterSummariesResult';
    totalRecords: number;
    pageNumber: number;
    pageSize: number;
    data?: Array<{
      __typename?: 'FreeKindyAcquittalCenterSummaryDto';
      centerId: string;
      centerName: string;
      period: string;
      year: number;
      childCount: number;
      totalPayment?: number | null;
      totalOwed?: number | null;
      baseSubsidyPrior?: number | null;
      baseSubsidyActual?: number | null;
      baseSubsidyAcquittal?: number | null;
      inclusionReadyPrior?: number | null;
      inclusionReadyActual?: number | null;
      inclusionReadyAcquittal?: number | null;
      serviceLocationPrior?: number | null;
      serviceLocationActual?: number | null;
      serviceLocationAcquittal?: number | null;
      freeKindyPrior?: number | null;
      freeKindyActual?: number | null;
      freeKindyAcquittal?: number | null;
      paidToFamiliesAmount?: number | null;
      status?: string | null;
      messages: Array<{
        __typename?: 'ErrorDto';
        code: string;
        message: string;
        target?: string | null;
        innerError?: string | null;
      }>;
    }> | null;
  };
};

export type GetFreeKindyAcquittalChildSummariesQueryVariables = Exact<{
  input: GetPagedKindyForAllChildSummariesInput;
}>;

export type GetFreeKindyAcquittalChildSummariesQuery = {
  __typename?: 'Query';
  getFreeKindyAcquittalChildSummaries: {
    __typename?: 'GetPagedFreeKindyAcquittalChildSummariesResult';
    totalRecords: number;
    pageNumber: number;
    pageSize: number;
    data?: Array<{
      __typename?: 'FreeKindyAcquittalChildSummaryDto';
      agreementId?: string | null;
      centerId: string;
      centerName: string;
      childId: string;
      childName?: string | null;
      year: number;
      period: string;
      forecastedAmount?: number | null;
      actualAmount?: number | null;
      acquittedAmount?: number | null;
      paidToFamiliesAmount?: number | null;
      errors?: boolean | null;
      status?: string | null;
    }> | null;
  };
};

export type GetStartStrongReportQueryVariables = Exact<{
  input: GetStartStrongReportInput;
}>;

export type GetStartStrongReportQuery = {
  __typename?: 'Query';
  getStartStrongReport: { __typename?: 'GetStartStrongReportResult'; csv?: string | null; errors: Array<string> };
};

export type GetTransitionStatementsQueryVariables = Exact<{
  input: GetTransitionStatementsInput;
}>;

export type GetTransitionStatementsQuery = {
  __typename?: 'Query';
  getTransitionStatements: Array<{
    __typename?: 'TransitionStatementDto';
    childId: string;
    childName?: string | null;
    centerId: string;
    centerName?: string | null;
    catholicCount?: number | null;
    independentCount?: number | null;
    stateCount?: number | null;
  } | null>;
};

export type GetDirectDebitPaymentMethodQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDirectDebitPaymentMethodQuery = {
  __typename?: 'Query';
  getDirectDebitPaymentMethod: {
    __typename?: 'DirectDebitPaymentMethod';
    accountName?: string | null;
    accountNumber?: string | null;
    bsb?: string | null;
  };
};

export type SendReEnrollmentMutationVariables = Exact<{
  input: ReenrollmentSendRequestInput;
}>;

export type SendReEnrollmentMutation = { __typename?: 'Mutation'; sendReEnrollments?: string | null };

export type CloseReEnrollmentsMutationVariables = Exact<{
  businessId: Scalars['ID'];
  input: ReenrollmentCloseRequestInput;
}>;

export type CloseReEnrollmentsMutation = {
  __typename?: 'Mutation';
  closeReEnrollments?: { __typename?: 'ReEnrollmentCloseResponse'; totalRequestsClosed: number } | null;
};

export type SetReEnrolBusinessSettingsMutationVariables = Exact<{
  input: SetReEnrolBusinessSettingsInput;
}>;

export type SetReEnrolBusinessSettingsMutation = {
  __typename?: 'Mutation';
  setReEnrolBusinessSettings: {
    __typename?: 'ReEnrolBusinessSettings';
    entityId: string;
    usesReEnrol: boolean;
    usesParentApplication: boolean;
    usesEnrolmentForm: boolean;
    centerCount: number;
  };
};

export type SetReEnrolCenterSettingsMutationVariables = Exact<{
  input: SetReEnrolCenterSettingsInput;
}>;

export type SetReEnrolCenterSettingsMutation = {
  __typename?: 'Mutation';
  setReEnrolCenterSettings: {
    __typename?: 'ReEnrolCenterSettings';
    entityId: string;
    centerId: string;
    centerName: string;
    usesParentApplication: boolean;
    usesEnrolmentForm: boolean;
    fromDefault: boolean;
    businessUsesReEnrol: boolean;
  };
};

export type ResetReEnrolCenterSettingsMutationVariables = Exact<{
  input: ResetReEnrolCenterSettingsInput;
}>;

export type ResetReEnrolCenterSettingsMutation = {
  __typename?: 'Mutation';
  resetReEnrolCenterSettings: {
    __typename?: 'ReEnrolCenterSettings';
    entityId: string;
    centerId: string;
    centerName: string;
    usesParentApplication: boolean;
    usesEnrolmentForm: boolean;
    fromDefault: boolean;
    businessUsesReEnrol: boolean;
  };
};

export type GetReenrollmentAccountsQueryVariables = Exact<{
  input: ReenrollmentGetAccountsRequestInput;
}>;

export type GetReenrollmentAccountsQuery = {
  __typename?: 'Query';
  getReenrollmentAccounts?: {
    __typename?: 'ReEnrollmentGetAccountsResponsePagedResult';
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data?: Array<{
      __typename?: 'ReEnrollmentAccount';
      accountId: string;
      accountName: string;
      centerId: string;
      stage?: Stage | null;
      accountStatus: AccountStatusType;
      lastContactedDate?: string | null;
      accountBalanceAmount?: number | null;
    }> | null;
  } | null;
};

export type GetReenrollmentAccountContractsQueryVariables = Exact<{
  input: ReenrollmentGetAccountContractsRequestInput;
}>;

export type GetReenrollmentAccountContractsQuery = {
  __typename?: 'Query';
  getReenrollmentAccountContracts?: Array<{
    __typename?: 'ReEnrollChild';
    id: string;
    childId?: string | null;
    accountChildId?: string | null;
    nickname?: string | null;
    firstname?: string | null;
    lastname?: string | null;
    avatar?: string | null;
    dob?: string | null;
    archivedAt?: string | null;
    accountId?: string | null;
    contracts?: Array<{
      __typename?: 'ReenrollContract';
      id: string;
      startDate?: string | null;
      endDate?: string | null;
    } | null> | null;
  }> | null;
};

export type GetReEnrolBusinessSettingsQueryVariables = Exact<{
  input: GetReEnrolBusinessSettingsInput;
}>;

export type GetReEnrolBusinessSettingsQuery = {
  __typename?: 'Query';
  getReEnrolBusinessSettings: {
    __typename?: 'ReEnrolBusinessSettings';
    entityId: string;
    usesReEnrol: boolean;
    usesParentApplication: boolean;
    usesEnrolmentForm: boolean;
    centerCount: number;
  };
};

export type GetReEnrolCenterSettingsQueryVariables = Exact<{
  input: SearchInput;
  input2: GetReEnrolBusinessSettingsInput;
}>;

export type GetReEnrolCenterSettingsQuery = {
  __typename?: 'Query';
  searchCenters: {
    __typename?: 'CenterSearchResults';
    totalResults: number;
    data: Array<{
      __typename?: 'Center';
      id: string;
      name: string;
      address?: { __typename?: 'Address'; state?: string | null } | null;
      tags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
      reEnrolSettings?: {
        __typename?: 'ReEnrolCenterSettings';
        entityId: string;
        centerId: string;
        centerName: string;
        usesParentApplication: boolean;
        usesEnrolmentForm: boolean;
        fromDefault: boolean;
        businessUsesReEnrol: boolean;
      } | null;
    }>;
  };
  getReEnrolBusinessSettings: {
    __typename?: 'ReEnrolBusinessSettings';
    entityId: string;
    usesReEnrol: boolean;
    usesParentApplication: boolean;
    usesEnrolmentForm: boolean;
    centerCount: number;
  };
};

export type GetReEnrolHistoryOfActionsQueryVariables = Exact<{
  businessId: Scalars['ID'];
  input: GetReEnrolHistoryOfActionsInput;
}>;

export type GetReEnrolHistoryOfActionsQuery = {
  __typename?: 'Query';
  getReEnrolHistoryOfActions?: {
    __typename?: 'ReEnrollmentGetHistoryofActionsResponsePagedResult';
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data?: Array<{
      __typename?: 'ReEnrollmentHistory';
      batchId: string;
      userFriendlyBatchId: string;
      createdAt: string;
      createdBy: string;
      accountId: string;
      accountName: string;
      centerId: string;
      centerName: string;
      stage?: Stage | null;
      declineReason?: ReenrollmentDeclineReason | null;
      otherReason?: string | null;
      declinedAt?: string | null;
      declinedBy?: string | null;
      declinedByName?: string | null;
      stageLastModifiedAt?: string | null;
      createdByName?: string | null;
      correspondenceType?: CorrespondenceType | null;
      completedBy?: string | null;
      completedByName?: string | null;
      linkCode?: string | null;
    }> | null;
  } | null;
};

export type GetReEnrolAccountsByBatchIdQueryVariables = Exact<{
  businessId: Scalars['ID'];
  batchId: Scalars['ID'];
  input: ReenrollmentGetAccountsRequestInput;
}>;

export type GetReEnrolAccountsByBatchIdQuery = {
  __typename?: 'Query';
  getReEnrolAccountsByBatchId?: {
    __typename?: 'ReEnrollmentGetAccountsResponsePagedResult';
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data?: Array<{
      __typename?: 'ReEnrollmentAccount';
      accountId: string;
      accountName: string;
      centerId: string;
      stage?: Stage | null;
      accountStatus: AccountStatusType;
      lastContactedDate?: string | null;
      accountBalanceAmount?: number | null;
    }> | null;
  } | null;
};

export type GetReEnrolCorrespondenceMetadataByLinkCodeQueryVariables = Exact<{
  businessId: Scalars['ID'];
  linkCode: Scalars['String'];
}>;

export type GetReEnrolCorrespondenceMetadataByLinkCodeQuery = {
  __typename?: 'Query';
  getReEnrolCorrespondenceMetadataByLinkCode?: {
    __typename?: 'ReEnrolAccountCorrespondenceMeta';
    linkCode: string;
    relatedApplicationId?: string | null;
    enrolmentFormAccountId?: string | null;
    enrolmentFormAccountDataRecordId?: string | null;
    relatedApplication?: {
      __typename?: 'RelatedApplication';
      id: string;
      businessId: string;
      state?: ApplicationState | null;
      lastModifiedAt?: string | null;
      appliedFlowType?: ApplicationFlowType | null;
      relatedApplicationChildren: Array<{
        __typename?: 'RelatedApplicationChildren';
        applicationChildId: string;
        childFullName: string;
      }>;
      relatedApplicationOffers?: Array<{
        __typename?: 'RelatedApplicationOffers';
        id: string;
        expiryDate?: string | null;
        applicationScheduleOfferIds?: Array<string> | null;
        linkCode?: string | null;
        applicationState?: ApplicationState | null;
        centerId?: string | null;
        enrolmentFormDataRecordId?: string | null;
      }> | null;
      relatedApplicationScheduleOffers?: Array<{
        __typename?: 'RelatedApplicationScheduleOffers';
        id: string;
        applicationOfferId?: string | null;
        applicationChildId: string;
        applicationScheduleOfferState?: ApplicationScheduleOfferState | null;
        IisWaitListOffered?: boolean | null;
      }> | null;
    } | null;
  } | null;
};

export type SystemFieldFragmentFragment = {
  __typename?: 'DiffDto';
  name: string;
  diffCount: number;
  diffState: DiffState;
  oldValue?: string | null;
  newValue?: string | null;
};

export type CustomFieldFragmentFragment = {
  __typename?: 'CustomFieldValueDataDiff';
  id: string;
  thirdParty: boolean;
  name: string;
  diffCount: number;
  diffState: DiffState;
  oldValue: string;
  newValue: string;
};

export type DocumentUploadDiffFragmentFragment = {
  __typename?: 'DocumentUploadDiff';
  id?: string | null;
  diffCount?: number | null;
  diffState?: DiffState | null;
  systemFields?: Array<{
    __typename?: 'DiffDto';
    name: string;
    diffCount: number;
    diffState: DiffState;
    oldValue?: string | null;
    newValue?: string | null;
  }> | null;
};

export type DocumentUploadFragmentFragment = {
  __typename?: 'DocumentUpload';
  id?: string | null;
  file: string;
  fileName: string;
  expiryDate: string;
};

export type LookupJsonFragmentFragment = { __typename?: 'LookupJson'; label?: string | null; value?: string | null };

export type ContactDiffFragmentFragment = {
  __typename?: 'ContactDiff';
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  diffCount: number;
  diffState: DiffState;
  relationships?: Array<{
    __typename?: 'ChildRelationshipDiff';
    key: string;
    diffCount: number;
    diffState: DiffState;
    systemFields: Array<{
      __typename?: 'DiffDto';
      name: string;
      diffCount: number;
      diffState: DiffState;
      oldValue?: string | null;
      newValue?: string | null;
    }>;
  }> | null;
  customFields?: Array<{
    __typename?: 'CustomFieldValueDataDiff';
    id: string;
    thirdParty: boolean;
    name: string;
    diffCount: number;
    diffState: DiffState;
    oldValue: string;
    newValue: string;
  }> | null;
  address?: {
    __typename?: 'AddressDiff';
    diffCount: number;
    diffState: DiffState;
    systemFields: Array<{
      __typename?: 'DiffDto';
      name: string;
      diffCount: number;
      diffState: DiffState;
      oldValue?: string | null;
      newValue?: string | null;
    }>;
  } | null;
  phoneNumbers?: Array<{
    __typename?: 'PhoneNumberDiff';
    diffCount: number;
    diffState: DiffState;
    systemFields: Array<{
      __typename?: 'DiffDto';
      name: string;
      diffCount: number;
      diffState: DiffState;
      oldValue?: string | null;
      newValue?: string | null;
    }>;
  }> | null;
  systemFields?: Array<{
    __typename?: 'DiffDto';
    name: string;
    diffCount: number;
    diffState: DiffState;
    oldValue?: string | null;
    newValue?: string | null;
  }> | null;
};

export type GetEnrolmentFormDiffQueryVariables = Exact<{
  businessId: Scalars['ID'];
  enrolmentFormId: Scalars['String'];
}>;

export type GetEnrolmentFormDiffQuery = {
  __typename?: 'Query';
  getEnrolmentFormDiff?: {
    __typename?: 'EnrollmentDiff';
    diffCount: number;
    diffState: DiffState;
    systemFields: Array<{
      __typename?: 'DiffDto';
      name: string;
      diffCount: number;
      diffState: DiffState;
      oldValue?: string | null;
      newValue?: string | null;
    }>;
    payment: {
      __typename?: 'PaymentDiff';
      diffCount: number;
      diffState: DiffState;
      systemFields: Array<{
        __typename?: 'DiffDto';
        name: string;
        diffCount: number;
        diffState: DiffState;
        oldValue?: string | null;
        newValue?: string | null;
      }>;
    };
    billingCycle?: {
      __typename?: 'BillingCycleDiff';
      diffCount: number;
      diffState: DiffState;
      systemFields: Array<{
        __typename?: 'DiffDto';
        name: string;
        diffCount: number;
        diffState: DiffState;
        oldValue?: string | null;
        newValue?: string | null;
      }>;
    } | null;
    customFields: Array<{
      __typename?: 'CustomFieldValueDataDiff';
      id: string;
      thirdParty: boolean;
      name: string;
      diffCount: number;
      diffState: DiffState;
      oldValue: string;
      newValue: string;
    }>;
    children: Array<{
      __typename?: 'ChildDiff';
      childId: string;
      firstName: string;
      lastName: string;
      isNewChild: boolean;
      diffCount: number;
      diffState: DiffState;
      immunization?: {
        __typename?: 'DocumentUploadDiff';
        id?: string | null;
        diffCount?: number | null;
        diffState?: DiffState | null;
        systemFields?: Array<{
          __typename?: 'DiffDto';
          name: string;
          diffCount: number;
          diffState: DiffState;
          oldValue?: string | null;
          newValue?: string | null;
        }> | null;
      } | null;
      medicalInfo: Array<{
        __typename?: 'MedicalInfoDto';
        id: string;
        restrictionName?: string | null;
        medicalName?: string | null;
        note?: string | null;
        category?: { __typename?: 'LookupJson'; label?: string | null; value?: string | null } | null;
        type?: { __typename?: 'LookupJson'; label?: string | null; value?: string | null } | null;
        allergyName?: { __typename?: 'LookupJson'; label?: string | null; value?: string | null } | null;
        risk?: { __typename?: 'LookupJson'; label?: string | null; value?: string | null } | null;
        importance?: { __typename?: 'LookupJson'; label?: string | null; value?: string | null } | null;
        symptoms?: { __typename?: 'LookupJson'; label?: string | null; value?: string | null } | null;
        severity?: { __typename?: 'LookupJson'; label?: string | null; value?: string | null } | null;
        reactions?: { __typename?: 'LookupJson'; label?: string | null; value?: string | null } | null;
        documents?: Array<{
          __typename?: 'DocumentUpload';
          id?: string | null;
          file: string;
          fileName: string;
          expiryDate: string;
        }> | null;
      }>;
      customFields: Array<{
        __typename?: 'CustomFieldValueDataDiff';
        id: string;
        thirdParty: boolean;
        name: string;
        diffCount: number;
        diffState: DiffState;
        oldValue: string;
        newValue: string;
      }>;
      systemFields: Array<{
        __typename?: 'DiffDto';
        name: string;
        diffCount: number;
        diffState: DiffState;
        oldValue?: string | null;
        newValue?: string | null;
      }>;
    }>;
    primaryContacts: Array<{
      __typename?: 'ContactDiff';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      diffCount: number;
      diffState: DiffState;
      relationships?: Array<{
        __typename?: 'ChildRelationshipDiff';
        key: string;
        diffCount: number;
        diffState: DiffState;
        systemFields: Array<{
          __typename?: 'DiffDto';
          name: string;
          diffCount: number;
          diffState: DiffState;
          oldValue?: string | null;
          newValue?: string | null;
        }>;
      }> | null;
      customFields?: Array<{
        __typename?: 'CustomFieldValueDataDiff';
        id: string;
        thirdParty: boolean;
        name: string;
        diffCount: number;
        diffState: DiffState;
        oldValue: string;
        newValue: string;
      }> | null;
      address?: {
        __typename?: 'AddressDiff';
        diffCount: number;
        diffState: DiffState;
        systemFields: Array<{
          __typename?: 'DiffDto';
          name: string;
          diffCount: number;
          diffState: DiffState;
          oldValue?: string | null;
          newValue?: string | null;
        }>;
      } | null;
      phoneNumbers?: Array<{
        __typename?: 'PhoneNumberDiff';
        diffCount: number;
        diffState: DiffState;
        systemFields: Array<{
          __typename?: 'DiffDto';
          name: string;
          diffCount: number;
          diffState: DiffState;
          oldValue?: string | null;
          newValue?: string | null;
        }>;
      }> | null;
      systemFields?: Array<{
        __typename?: 'DiffDto';
        name: string;
        diffCount: number;
        diffState: DiffState;
        oldValue?: string | null;
        newValue?: string | null;
      }> | null;
    }>;
    contacts: Array<{
      __typename?: 'ContactDiff';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      diffCount: number;
      diffState: DiffState;
      relationships?: Array<{
        __typename?: 'ChildRelationshipDiff';
        key: string;
        diffCount: number;
        diffState: DiffState;
        systemFields: Array<{
          __typename?: 'DiffDto';
          name: string;
          diffCount: number;
          diffState: DiffState;
          oldValue?: string | null;
          newValue?: string | null;
        }>;
      }> | null;
      customFields?: Array<{
        __typename?: 'CustomFieldValueDataDiff';
        id: string;
        thirdParty: boolean;
        name: string;
        diffCount: number;
        diffState: DiffState;
        oldValue: string;
        newValue: string;
      }> | null;
      address?: {
        __typename?: 'AddressDiff';
        diffCount: number;
        diffState: DiffState;
        systemFields: Array<{
          __typename?: 'DiffDto';
          name: string;
          diffCount: number;
          diffState: DiffState;
          oldValue?: string | null;
          newValue?: string | null;
        }>;
      } | null;
      phoneNumbers?: Array<{
        __typename?: 'PhoneNumberDiff';
        diffCount: number;
        diffState: DiffState;
        systemFields: Array<{
          __typename?: 'DiffDto';
          name: string;
          diffCount: number;
          diffState: DiffState;
          oldValue?: string | null;
          newValue?: string | null;
        }>;
      }> | null;
      systemFields?: Array<{
        __typename?: 'DiffDto';
        name: string;
        diffCount: number;
        diffState: DiffState;
        oldValue?: string | null;
        newValue?: string | null;
      }> | null;
    }>;
  } | null;
};

export type ReportMetaDataFragment = {
  __typename?: 'ReportMetaData';
  type: ReportType;
  categoryType: ReportCategoryType;
  generatedOn: string;
  generatedBy: string;
  params?: Array<{ __typename?: 'ReportParams'; key?: string | null; value?: string | null } | null> | null;
};

export type GetPayrixDisbursementReportQueryVariables = Exact<{
  input: GetPayrixDisbursementReportInput;
}>;

export type GetPayrixDisbursementReportQuery = {
  __typename?: 'Query';
  getPayrixDisbursementReport: {
    __typename?: 'GetPayrixDisbursementReport';
    reportData: Array<string | null>;
    reportMetaData: {
      __typename?: 'ReportMetaData';
      type: ReportType;
      categoryType: ReportCategoryType;
      generatedOn: string;
      generatedBy: string;
      params?: Array<{ __typename?: 'ReportParams'; key?: string | null; value?: string | null } | null> | null;
    };
  };
};

export type GetPayrixDisbursementReportPdfQueryVariables = Exact<{
  input: GetPayrixDisbursementReportInput;
}>;

export type GetPayrixDisbursementReportPdfQuery = { __typename?: 'Query'; getPayrixDisbursementReportPdf: string };

export type GetCcsEnrolmentsReportQueryVariables = Exact<{
  input: CcsEnrolmentReportInput;
  businessId: Scalars['ID'];
}>;

export type GetCcsEnrolmentsReportQuery = { __typename?: 'Query'; getCcsEnrolmentsReport: string };

export type GetCustomFieldsReportQueryVariables = Exact<{
  input: CustomFieldsReportInput;
}>;

export type GetCustomFieldsReportQuery = { __typename?: 'Query'; getCustomFieldsReport: string };

export type GetReEnrolHistoryReportQueryVariables = Exact<{
  businessId: Scalars['ID'];
  input: GetReEnrolHistoryOfActionsInput;
}>;

export type GetReEnrolHistoryReportQuery = { __typename?: 'Query'; getReEnrolHistoryReport: string };

export type GetEmployeeTimeSheetExcelReportQueryVariables = Exact<{
  input: EmployeeTimeSheetReportInput;
}>;

export type GetEmployeeTimeSheetExcelReportQuery = { __typename?: 'Query'; getEmployeeTimeSheetExcelReport: string };

export type ResubmitSessionReportSyncMutationVariables = Exact<{
  input: SessionReportSyncResubmitInput;
}>;

export type ResubmitSessionReportSyncMutation = { __typename?: 'Mutation'; resubmitSessionReportSync?: boolean | null };

export type ReportAdditionalAbsenceNewMutationVariables = Exact<{
  input: AdditionalAbsenceMessageRecord;
}>;

export type ReportAdditionalAbsenceNewMutation = {
  __typename?: 'Mutation';
  reportAdditionalAbsenceNew: Array<{
    __typename?: 'AbsenceDto';
    id?: string | null;
    sessionId?: string | null;
    contractId?: string | null;
    accountChildId?: string | null;
    type?: string | null;
    reason?: string | null;
    absenceDocumentHeld?: boolean | null;
    date?: string | null;
    createdAt?: string | null;
    createdBy?: string | null;
    waiveGapFee?: boolean | null;
  }>;
};

export type ReportBulkAdditionalAbsenceNewMutationVariables = Exact<{
  input: BulkAdditionalAbsenceMessageRecords;
}>;

export type ReportBulkAdditionalAbsenceNewMutation = {
  __typename?: 'Mutation';
  reportBulkAdditionalAbsenceNew: Array<{
    __typename?: 'AbsenceDto';
    id?: string | null;
    sessionId?: string | null;
    contractId?: string | null;
    accountChildId?: string | null;
    type?: string | null;
    reason?: string | null;
    absenceDocumentHeld?: boolean | null;
    date?: string | null;
    createdAt?: string | null;
    createdBy?: string | null;
    waiveGapFee?: boolean | null;
  }>;
};

export type GetKindySubsidySchemesQueryVariables = Exact<{ [key: string]: never }>;

export type GetKindySubsidySchemesQuery = {
  __typename?: 'Query';
  getKindySubsidySchemes: Array<{
    __typename?: 'SubsidySchemeDto';
    businessId?: string | null;
    name: string;
    subsidySchemeId: string;
  }>;
};

export type GetSystemFieldsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSystemFieldsQuery = {
  __typename?: 'Query';
  getSystemFields: Array<{
    __typename?: 'CustomField';
    id: string;
    area: CustomFieldArea;
    attributesAsString?: string | null;
    businessId: string;
    centerIds?: Array<string> | null;
    label: string;
    displayOrder?: number | null;
    notForConsoleDisplay: boolean;
    type: CustomFieldType;
    valuesAsString?: string | null;
    groupQuestionSchemaAsString?: string | null;
    displayDateFormat?: string | null;
    groups?: Array<{ __typename?: 'CustomFieldGroup'; name: string; id: string } | null> | null;
  } | null>;
};

export type IsCustomFieldInUseByFormQueryVariables = Exact<{
  businessId: Scalars['ID'];
  customFieldId: Scalars['ID'];
}>;

export type IsCustomFieldInUseByFormQuery = {
  __typename?: 'Query';
  isCustomFieldInUseByForm?: Array<{
    __typename?: 'EnrollmentFormFieldStatus';
    customFieldId: string;
    formId: string;
    formName: string;
    formVersion: number;
    useCount?: number | null;
  } | null> | null;
};

export type GetBillingBusinessSettingsQueryVariables = Exact<{
  businessId?: InputMaybe<Scalars['ID']>;
}>;

export type GetBillingBusinessSettingsQuery = {
  __typename?: 'Query';
  fetchBillingBusinessSettings?: {
    __typename?: 'BillingBusinessSettings';
    mandatoryGlCodeMapping: boolean;
    parentPaymentDirection: string;
    transactionAmountDisplay: string;
    transactionAmountThemed: boolean;
  } | null;
};

export const ChildFieldsFragmentDoc = gql`
  fragment childFields on Child {
    id
    firstname
    lastname
    nickname
    dob
    indigenousStatus
    gender
    grade
    avatar {
      url
    }
    residesWith {
      id
      firstname
      lastname
      nickname
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }
    }
    contacts {
      id
      firstname
      lastname
      nickname
      avatar {
        url
      }
      email
      primaryPhoneNumber {
        number
        type
      }
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }
      relationshipType
      accountPermissions {
        accountId
        isPrimary
        permissions
        account {
          id
          name
          center {
            id
            name
          }
        }
      }
    }
    accounts {
      id
      name
      entityId
      centerId
      center {
        id
        name
      }
      children {
        id
        accountChildId
        archivedAt
      }
    }
    tags {
      id
      entityId
      name
      category
    }
    emergencyContacts {
      id
      childId
      contactId
      firstName
      lastName
      phoneNumber {
        number
        type
      }
      relationshipType
    }
    ccssAttributes {
      crn
      isCrnEditable
    }
    mealStatus {
      mealPricing
    }
    restrictAccessToChildDocuments
    fullName
  }
`;
export const AvatarFieldsFragmentDoc = gql`
  fragment avatarFields on S3SignedUrl {
    url
    provisionalGetUrl
    fields
  }
`;
export const ApplicationNoteFieldsFragmentDoc = gql`
  fragment applicationNoteFields on ApplicationNote {
    id
    comment
    createdAt
    createdBy
    createdByName
    createdByAvatar {
      url
    }
    archivedAt
    archivedByName
    lastEditedAt
    lastEditedByName
  }
`;
export const EnrollmentProgramFieldsFragmentDoc = gql`
  fragment enrollmentProgramFields on EnrollmentProgram {
    id
    businessId
    name
    isOpenForEnrollment
    startDate
    endDate
    minEnrolmentDays
    maxEnrolmentDays
    operatingDays
    description
    programGroupId
    programCenters {
      id
      programId
      centerId
      classId
      feeId
      centerName
      className
      feeName
      casualFeeId
      enrollmentOptions
      casualFeeName
    }
  }
`;
export const ErrorFragmentDoc = gql`
  fragment Error on ErrorDto {
    code
    message
    target
    innerError
  }
`;
export const FreeKindyCenterSummaryFragmentDoc = gql`
  fragment FreeKindyCenterSummary on FreeKindyCenterSummaryDto {
    centerId
    centerName
    period
    year
    childCount
    fundedAmount
    baseAmount
    universalInclusionAmount
    ariaTeacherAmount
    freeKindyAmount
    status
    errors {
      ...Error
    }
  }
  ${ErrorFragmentDoc}
`;
export const FreeKindyChildSummaryFragmentDoc = gql`
  fragment FreeKindyChildSummary on FreeKindyChildSummaryDto {
    centerId
    centerName
    childId
    childName
    fundedAmount
    period
    status
    year
  }
`;
export const TransitionStatementFragmentDoc = gql`
  fragment TransitionStatement on TransitionStatementDto {
    childId
    childName
    centerId
    centerName
    catholicCount
    independentCount
    stateCount
  }
`;
export const SystemFieldFragmentFragmentDoc = gql`
  fragment systemFieldFragment on DiffDto {
    name
    diffCount
    diffState
    oldValue
    newValue
  }
`;
export const DocumentUploadDiffFragmentFragmentDoc = gql`
  fragment documentUploadDiffFragment on DocumentUploadDiff {
    id
    diffCount
    diffState
    systemFields {
      ...systemFieldFragment
    }
  }
  ${SystemFieldFragmentFragmentDoc}
`;
export const DocumentUploadFragmentFragmentDoc = gql`
  fragment documentUploadFragment on DocumentUpload {
    id
    file
    fileName
    expiryDate
  }
`;
export const LookupJsonFragmentFragmentDoc = gql`
  fragment lookupJsonFragment on LookupJson {
    label
    value
  }
`;
export const CustomFieldFragmentFragmentDoc = gql`
  fragment customFieldFragment on CustomFieldValueDataDiff {
    id
    thirdParty
    name
    diffCount
    diffState
    oldValue
    newValue
  }
`;
export const ContactDiffFragmentFragmentDoc = gql`
  fragment contactDiffFragment on ContactDiff {
    id
    firstName
    lastName
    relationships {
      key
      diffCount
      diffState
      systemFields {
        ...systemFieldFragment
      }
    }
    customFields {
      ...customFieldFragment
    }
    address {
      diffCount
      diffState
      systemFields {
        ...systemFieldFragment
      }
    }
    phoneNumbers {
      diffCount
      diffState
      systemFields {
        ...systemFieldFragment
      }
    }
    diffCount
    diffState
    systemFields {
      ...systemFieldFragment
    }
  }
  ${SystemFieldFragmentFragmentDoc}
  ${CustomFieldFragmentFragmentDoc}
`;
export const ReportMetaDataFragmentDoc = gql`
  fragment reportMetaData on ReportMetaData {
    type
    categoryType
    generatedOn
    generatedBy
    params {
      key
      value
    }
  }
`;
export const UpdateApplicationOfferAndSchedulesDocument = gql`
  mutation updateApplicationOfferAndSchedules(
    $businessId: ID!
    $applicationId: ID!
    $applicationOfferId: ID!
    $input: UpdateApplicationOfferRequest!
  ) {
    updateApplicationOfferAndSchedules(
      businessId: $businessId
      applicationId: $applicationId
      applicationOfferId: $applicationOfferId
      input: $input
    ) {
      applicationId
      applicationOfferId
      applicationOfferExpiry
      applicationScheduleOffers {
        id
        careType
        startDate
        endDate
        classId
        offerCenterId
        feeId
        feeAmount
        cycleType
        days {
          id
          weekType
          dayOfWeek
        }
        applicationOfferId
        state
        generationState
        programId
      }
    }
  }
`;
export type UpdateApplicationOfferAndSchedulesMutationFn = Apollo.MutationFunction<
  UpdateApplicationOfferAndSchedulesMutation,
  UpdateApplicationOfferAndSchedulesMutationVariables
>;

/**
 * __useUpdateApplicationOfferAndSchedulesMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationOfferAndSchedulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationOfferAndSchedulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationOfferAndSchedulesMutation, { data, loading, error }] = useUpdateApplicationOfferAndSchedulesMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      applicationId: // value for 'applicationId'
 *      applicationOfferId: // value for 'applicationOfferId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationOfferAndSchedulesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationOfferAndSchedulesMutation,
    UpdateApplicationOfferAndSchedulesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationOfferAndSchedulesMutation,
    UpdateApplicationOfferAndSchedulesMutationVariables
  >(UpdateApplicationOfferAndSchedulesDocument, options);
}
export type UpdateApplicationOfferAndSchedulesMutationHookResult = ReturnType<
  typeof useUpdateApplicationOfferAndSchedulesMutation
>;
export type UpdateApplicationOfferAndSchedulesMutationResult =
  Apollo.MutationResult<UpdateApplicationOfferAndSchedulesMutation>;
export type UpdateApplicationOfferAndSchedulesMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationOfferAndSchedulesMutation,
  UpdateApplicationOfferAndSchedulesMutationVariables
>;
export const CheckOfferAvailabilityDocument = gql`
  query checkOfferAvailability(
    $businessId: ID!
    $applicationId: ID!
    $applicationOfferId: ID!
    $input: UpdateApplicationOfferRequest!
  ) {
    checkOfferAvailability(
      businessId: $businessId
      applicationId: $applicationId
      applicationOfferId: $applicationOfferId
      input: $input
    ) {
      scheduleOfferId
      hasCapacity
    }
  }
`;

/**
 * __useCheckOfferAvailabilityQuery__
 *
 * To run a query within a React component, call `useCheckOfferAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOfferAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOfferAvailabilityQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      applicationId: // value for 'applicationId'
 *      applicationOfferId: // value for 'applicationOfferId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckOfferAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<CheckOfferAvailabilityQuery, CheckOfferAvailabilityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckOfferAvailabilityQuery, CheckOfferAvailabilityQueryVariables>(
    CheckOfferAvailabilityDocument,
    options
  );
}
export function useCheckOfferAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckOfferAvailabilityQuery, CheckOfferAvailabilityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckOfferAvailabilityQuery, CheckOfferAvailabilityQueryVariables>(
    CheckOfferAvailabilityDocument,
    options
  );
}
export type CheckOfferAvailabilityQueryHookResult = ReturnType<typeof useCheckOfferAvailabilityQuery>;
export type CheckOfferAvailabilityLazyQueryHookResult = ReturnType<typeof useCheckOfferAvailabilityLazyQuery>;
export type CheckOfferAvailabilityQueryResult = Apollo.QueryResult<
  CheckOfferAvailabilityQuery,
  CheckOfferAvailabilityQueryVariables
>;
export const GetApplicationCenterSettingsPaginatedDocument = gql`
  query getApplicationCenterSettingsPaginated($input: SearchInput!) {
    searchCenters(input: $input) {
      totalResults
      data {
        id
        name
        address {
          state
        }
        tags {
          id
          name
        }
        applicationSetting {
          businessId
          centerId
          feeType
          feeAmount
          askFamilyAboutSubsidy
          enrollmentOptions
          centerName
        }
      }
    }
  }
`;

/**
 * __useGetApplicationCenterSettingsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetApplicationCenterSettingsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationCenterSettingsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationCenterSettingsPaginatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetApplicationCenterSettingsPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationCenterSettingsPaginatedQuery,
    GetApplicationCenterSettingsPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationCenterSettingsPaginatedQuery,
    GetApplicationCenterSettingsPaginatedQueryVariables
  >(GetApplicationCenterSettingsPaginatedDocument, options);
}
export function useGetApplicationCenterSettingsPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationCenterSettingsPaginatedQuery,
    GetApplicationCenterSettingsPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationCenterSettingsPaginatedQuery,
    GetApplicationCenterSettingsPaginatedQueryVariables
  >(GetApplicationCenterSettingsPaginatedDocument, options);
}
export type GetApplicationCenterSettingsPaginatedQueryHookResult = ReturnType<
  typeof useGetApplicationCenterSettingsPaginatedQuery
>;
export type GetApplicationCenterSettingsPaginatedLazyQueryHookResult = ReturnType<
  typeof useGetApplicationCenterSettingsPaginatedLazyQuery
>;
export type GetApplicationCenterSettingsPaginatedQueryResult = Apollo.QueryResult<
  GetApplicationCenterSettingsPaginatedQuery,
  GetApplicationCenterSettingsPaginatedQueryVariables
>;
export const SetAttendanceCentersSettingDocument = gql`
  mutation setAttendanceCentersSetting($input: SetAttendanceCenterSettingInput!) {
    setAttendanceCentersSetting(input: $input) {
      businessUsesAttendanceSetting
      entityId
      centerId
      centerName
      usesTodayScreen
    }
  }
`;
export type SetAttendanceCentersSettingMutationFn = Apollo.MutationFunction<
  SetAttendanceCentersSettingMutation,
  SetAttendanceCentersSettingMutationVariables
>;

/**
 * __useSetAttendanceCentersSettingMutation__
 *
 * To run a mutation, you first call `useSetAttendanceCentersSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAttendanceCentersSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAttendanceCentersSettingMutation, { data, loading, error }] = useSetAttendanceCentersSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAttendanceCentersSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAttendanceCentersSettingMutation,
    SetAttendanceCentersSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetAttendanceCentersSettingMutation, SetAttendanceCentersSettingMutationVariables>(
    SetAttendanceCentersSettingDocument,
    options
  );
}
export type SetAttendanceCentersSettingMutationHookResult = ReturnType<typeof useSetAttendanceCentersSettingMutation>;
export type SetAttendanceCentersSettingMutationResult = Apollo.MutationResult<SetAttendanceCentersSettingMutation>;
export type SetAttendanceCentersSettingMutationOptions = Apollo.BaseMutationOptions<
  SetAttendanceCentersSettingMutation,
  SetAttendanceCentersSettingMutationVariables
>;
export const SetAttendanceBusinessSettingDocument = gql`
  mutation setAttendanceBusinessSetting($input: SetAttendanceBusinessSettingInput!) {
    setAttendanceBusinessSetting(input: $input) {
      entityId
      usesTodayScreen
    }
  }
`;
export type SetAttendanceBusinessSettingMutationFn = Apollo.MutationFunction<
  SetAttendanceBusinessSettingMutation,
  SetAttendanceBusinessSettingMutationVariables
>;

/**
 * __useSetAttendanceBusinessSettingMutation__
 *
 * To run a mutation, you first call `useSetAttendanceBusinessSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAttendanceBusinessSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAttendanceBusinessSettingMutation, { data, loading, error }] = useSetAttendanceBusinessSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAttendanceBusinessSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAttendanceBusinessSettingMutation,
    SetAttendanceBusinessSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetAttendanceBusinessSettingMutation, SetAttendanceBusinessSettingMutationVariables>(
    SetAttendanceBusinessSettingDocument,
    options
  );
}
export type SetAttendanceBusinessSettingMutationHookResult = ReturnType<typeof useSetAttendanceBusinessSettingMutation>;
export type SetAttendanceBusinessSettingMutationResult = Apollo.MutationResult<SetAttendanceBusinessSettingMutation>;
export type SetAttendanceBusinessSettingMutationOptions = Apollo.BaseMutationOptions<
  SetAttendanceBusinessSettingMutation,
  SetAttendanceBusinessSettingMutationVariables
>;
export const ResetAttendanceCentersSettingDocument = gql`
  mutation resetAttendanceCentersSetting($businessId: ID!, $centerId: ID!) {
    resetAttendanceCentersSetting(businessId: $businessId, centerId: $centerId) {
      centerId
      centerName
      usesTodayScreen
      businessUsesAttendanceSetting
    }
  }
`;
export type ResetAttendanceCentersSettingMutationFn = Apollo.MutationFunction<
  ResetAttendanceCentersSettingMutation,
  ResetAttendanceCentersSettingMutationVariables
>;

/**
 * __useResetAttendanceCentersSettingMutation__
 *
 * To run a mutation, you first call `useResetAttendanceCentersSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAttendanceCentersSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAttendanceCentersSettingMutation, { data, loading, error }] = useResetAttendanceCentersSettingMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      centerId: // value for 'centerId'
 *   },
 * });
 */
export function useResetAttendanceCentersSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetAttendanceCentersSettingMutation,
    ResetAttendanceCentersSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetAttendanceCentersSettingMutation, ResetAttendanceCentersSettingMutationVariables>(
    ResetAttendanceCentersSettingDocument,
    options
  );
}
export type ResetAttendanceCentersSettingMutationHookResult = ReturnType<
  typeof useResetAttendanceCentersSettingMutation
>;
export type ResetAttendanceCentersSettingMutationResult = Apollo.MutationResult<ResetAttendanceCentersSettingMutation>;
export type ResetAttendanceCentersSettingMutationOptions = Apollo.BaseMutationOptions<
  ResetAttendanceCentersSettingMutation,
  ResetAttendanceCentersSettingMutationVariables
>;
export const GetAttendanceCentersSettingDocument = gql`
  query getAttendanceCentersSetting($input: SearchInput!) {
    searchCenters(input: $input) {
      totalResults
      data {
        id
        name
        address {
          state
        }
        tags {
          id
          name
        }
        attendanceSetting {
          businessUsesAttendanceSetting
          centerId
          centerName
          fromDefault
          entityId
          usesTodayScreen
        }
      }
    }
  }
`;

/**
 * __useGetAttendanceCentersSettingQuery__
 *
 * To run a query within a React component, call `useGetAttendanceCentersSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceCentersSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceCentersSettingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAttendanceCentersSettingQuery(
  baseOptions: Apollo.QueryHookOptions<GetAttendanceCentersSettingQuery, GetAttendanceCentersSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAttendanceCentersSettingQuery, GetAttendanceCentersSettingQueryVariables>(
    GetAttendanceCentersSettingDocument,
    options
  );
}
export function useGetAttendanceCentersSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAttendanceCentersSettingQuery, GetAttendanceCentersSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAttendanceCentersSettingQuery, GetAttendanceCentersSettingQueryVariables>(
    GetAttendanceCentersSettingDocument,
    options
  );
}
export type GetAttendanceCentersSettingQueryHookResult = ReturnType<typeof useGetAttendanceCentersSettingQuery>;
export type GetAttendanceCentersSettingLazyQueryHookResult = ReturnType<typeof useGetAttendanceCentersSettingLazyQuery>;
export type GetAttendanceCentersSettingQueryResult = Apollo.QueryResult<
  GetAttendanceCentersSettingQuery,
  GetAttendanceCentersSettingQueryVariables
>;
export const GetAllAttendanceCentersSettingDocument = gql`
  query getAllAttendanceCentersSetting($input: GetAttendanceCenterSettingInput!) {
    getAttendanceCentersSetting(input: $input) {
      businessUsesAttendanceSetting
      centerId
      centerName
      fromDefault
      entityId
      usesTodayScreen
    }
  }
`;

/**
 * __useGetAllAttendanceCentersSettingQuery__
 *
 * To run a query within a React component, call `useGetAllAttendanceCentersSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAttendanceCentersSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAttendanceCentersSettingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllAttendanceCentersSettingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllAttendanceCentersSettingQuery,
    GetAllAttendanceCentersSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAttendanceCentersSettingQuery, GetAllAttendanceCentersSettingQueryVariables>(
    GetAllAttendanceCentersSettingDocument,
    options
  );
}
export function useGetAllAttendanceCentersSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAttendanceCentersSettingQuery,
    GetAllAttendanceCentersSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAttendanceCentersSettingQuery, GetAllAttendanceCentersSettingQueryVariables>(
    GetAllAttendanceCentersSettingDocument,
    options
  );
}
export type GetAllAttendanceCentersSettingQueryHookResult = ReturnType<typeof useGetAllAttendanceCentersSettingQuery>;
export type GetAllAttendanceCentersSettingLazyQueryHookResult = ReturnType<
  typeof useGetAllAttendanceCentersSettingLazyQuery
>;
export type GetAllAttendanceCentersSettingQueryResult = Apollo.QueryResult<
  GetAllAttendanceCentersSettingQuery,
  GetAllAttendanceCentersSettingQueryVariables
>;
export const GetAttendanceBusinessSettingDocument = gql`
  query getAttendanceBusinessSetting($businessId: ID!) {
    getAttendanceBusinessSetting(businessId: $businessId) {
      entityId
      usesTodayScreen
    }
  }
`;

/**
 * __useGetAttendanceBusinessSettingQuery__
 *
 * To run a query within a React component, call `useGetAttendanceBusinessSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceBusinessSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceBusinessSettingQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetAttendanceBusinessSettingQuery(
  baseOptions: Apollo.QueryHookOptions<GetAttendanceBusinessSettingQuery, GetAttendanceBusinessSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAttendanceBusinessSettingQuery, GetAttendanceBusinessSettingQueryVariables>(
    GetAttendanceBusinessSettingDocument,
    options
  );
}
export function useGetAttendanceBusinessSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAttendanceBusinessSettingQuery,
    GetAttendanceBusinessSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAttendanceBusinessSettingQuery, GetAttendanceBusinessSettingQueryVariables>(
    GetAttendanceBusinessSettingDocument,
    options
  );
}
export type GetAttendanceBusinessSettingQueryHookResult = ReturnType<typeof useGetAttendanceBusinessSettingQuery>;
export type GetAttendanceBusinessSettingLazyQueryHookResult = ReturnType<
  typeof useGetAttendanceBusinessSettingLazyQuery
>;
export type GetAttendanceBusinessSettingQueryResult = Apollo.QueryResult<
  GetAttendanceBusinessSettingQuery,
  GetAttendanceBusinessSettingQueryVariables
>;
export const GetAuthenticationIdentityDocument = gql`
  query getAuthenticationIdentity($input: AuthenticationIdentityInput!) {
    getAuthenticationIdentity(input: $input)
  }
`;

/**
 * __useGetAuthenticationIdentityQuery__
 *
 * To run a query within a React component, call `useGetAuthenticationIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticationIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticationIdentityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAuthenticationIdentityQuery(
  baseOptions: Apollo.QueryHookOptions<GetAuthenticationIdentityQuery, GetAuthenticationIdentityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthenticationIdentityQuery, GetAuthenticationIdentityQueryVariables>(
    GetAuthenticationIdentityDocument,
    options
  );
}
export function useGetAuthenticationIdentityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAuthenticationIdentityQuery, GetAuthenticationIdentityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuthenticationIdentityQuery, GetAuthenticationIdentityQueryVariables>(
    GetAuthenticationIdentityDocument,
    options
  );
}
export type GetAuthenticationIdentityQueryHookResult = ReturnType<typeof useGetAuthenticationIdentityQuery>;
export type GetAuthenticationIdentityLazyQueryHookResult = ReturnType<typeof useGetAuthenticationIdentityLazyQuery>;
export type GetAuthenticationIdentityQueryResult = Apollo.QueryResult<
  GetAuthenticationIdentityQuery,
  GetAuthenticationIdentityQueryVariables
>;
export const GetAuthenticationIdentityResponseDocument = gql`
  query getAuthenticationIdentityResponse($input: AuthenticationIdentityInput!) {
    getAuthenticationIdentityResponse(input: $input) {
      idpIdentifier
      onboardingCompleted
    }
  }
`;

/**
 * __useGetAuthenticationIdentityResponseQuery__
 *
 * To run a query within a React component, call `useGetAuthenticationIdentityResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticationIdentityResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticationIdentityResponseQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAuthenticationIdentityResponseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthenticationIdentityResponseQuery,
    GetAuthenticationIdentityResponseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthenticationIdentityResponseQuery, GetAuthenticationIdentityResponseQueryVariables>(
    GetAuthenticationIdentityResponseDocument,
    options
  );
}
export function useGetAuthenticationIdentityResponseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthenticationIdentityResponseQuery,
    GetAuthenticationIdentityResponseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuthenticationIdentityResponseQuery, GetAuthenticationIdentityResponseQueryVariables>(
    GetAuthenticationIdentityResponseDocument,
    options
  );
}
export type GetAuthenticationIdentityResponseQueryHookResult = ReturnType<
  typeof useGetAuthenticationIdentityResponseQuery
>;
export type GetAuthenticationIdentityResponseLazyQueryHookResult = ReturnType<
  typeof useGetAuthenticationIdentityResponseLazyQuery
>;
export type GetAuthenticationIdentityResponseQueryResult = Apollo.QueryResult<
  GetAuthenticationIdentityResponseQuery,
  GetAuthenticationIdentityResponseQueryVariables
>;
export const GetSsoLoginUrlDocument = gql`
  query getSSOLoginUrl($input: SSOLoginElementsInput!) {
    getSSOLoginUrl(input: $input)
  }
`;

/**
 * __useGetSsoLoginUrlQuery__
 *
 * To run a query within a React component, call `useGetSsoLoginUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSsoLoginUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSsoLoginUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSsoLoginUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetSsoLoginUrlQuery, GetSsoLoginUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSsoLoginUrlQuery, GetSsoLoginUrlQueryVariables>(GetSsoLoginUrlDocument, options);
}
export function useGetSsoLoginUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSsoLoginUrlQuery, GetSsoLoginUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSsoLoginUrlQuery, GetSsoLoginUrlQueryVariables>(GetSsoLoginUrlDocument, options);
}
export type GetSsoLoginUrlQueryHookResult = ReturnType<typeof useGetSsoLoginUrlQuery>;
export type GetSsoLoginUrlLazyQueryHookResult = ReturnType<typeof useGetSsoLoginUrlLazyQuery>;
export type GetSsoLoginUrlQueryResult = Apollo.QueryResult<GetSsoLoginUrlQuery, GetSsoLoginUrlQueryVariables>;
export const GetPayrixDisbursementsDocument = gql`
  query getPayrixDisbursements($input: GetPayrixDisbursementsInput!) {
    getPayrixDisbursements(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        id
        amount
        created
        description
        processed
        returnedAmount
        status
      }
    }
  }
`;

/**
 * __useGetPayrixDisbursementsQuery__
 *
 * To run a query within a React component, call `useGetPayrixDisbursementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrixDisbursementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrixDisbursementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPayrixDisbursementsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPayrixDisbursementsQuery, GetPayrixDisbursementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPayrixDisbursementsQuery, GetPayrixDisbursementsQueryVariables>(
    GetPayrixDisbursementsDocument,
    options
  );
}
export function useGetPayrixDisbursementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPayrixDisbursementsQuery, GetPayrixDisbursementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPayrixDisbursementsQuery, GetPayrixDisbursementsQueryVariables>(
    GetPayrixDisbursementsDocument,
    options
  );
}
export type GetPayrixDisbursementsQueryHookResult = ReturnType<typeof useGetPayrixDisbursementsQuery>;
export type GetPayrixDisbursementsLazyQueryHookResult = ReturnType<typeof useGetPayrixDisbursementsLazyQuery>;
export type GetPayrixDisbursementsQueryResult = Apollo.QueryResult<
  GetPayrixDisbursementsQuery,
  GetPayrixDisbursementsQueryVariables
>;
export const UpdateHubSpotCustomerIdDocument = gql`
  mutation updateHubSpotCustomerId($businessId: ID!, $hubSpotCustomerId: String!) {
    updateEntity(input: { id: $businessId, hubSpotCustomerId: $hubSpotCustomerId }) {
      hubSpotCustomerId
    }
  }
`;
export type UpdateHubSpotCustomerIdMutationFn = Apollo.MutationFunction<
  UpdateHubSpotCustomerIdMutation,
  UpdateHubSpotCustomerIdMutationVariables
>;

/**
 * __useUpdateHubSpotCustomerIdMutation__
 *
 * To run a mutation, you first call `useUpdateHubSpotCustomerIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubSpotCustomerIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubSpotCustomerIdMutation, { data, loading, error }] = useUpdateHubSpotCustomerIdMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      hubSpotCustomerId: // value for 'hubSpotCustomerId'
 *   },
 * });
 */
export function useUpdateHubSpotCustomerIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHubSpotCustomerIdMutation, UpdateHubSpotCustomerIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateHubSpotCustomerIdMutation, UpdateHubSpotCustomerIdMutationVariables>(
    UpdateHubSpotCustomerIdDocument,
    options
  );
}
export type UpdateHubSpotCustomerIdMutationHookResult = ReturnType<typeof useUpdateHubSpotCustomerIdMutation>;
export type UpdateHubSpotCustomerIdMutationResult = Apollo.MutationResult<UpdateHubSpotCustomerIdMutation>;
export type UpdateHubSpotCustomerIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateHubSpotCustomerIdMutation,
  UpdateHubSpotCustomerIdMutationVariables
>;
export const GetHubSpotCustomerIdDocument = gql`
  query getHubSpotCustomerId($businessId: String!) {
    getEntity(id: $businessId) {
      hubSpotCustomerId
    }
  }
`;

/**
 * __useGetHubSpotCustomerIdQuery__
 *
 * To run a query within a React component, call `useGetHubSpotCustomerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHubSpotCustomerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHubSpotCustomerIdQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetHubSpotCustomerIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetHubSpotCustomerIdQuery, GetHubSpotCustomerIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHubSpotCustomerIdQuery, GetHubSpotCustomerIdQueryVariables>(
    GetHubSpotCustomerIdDocument,
    options
  );
}
export function useGetHubSpotCustomerIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHubSpotCustomerIdQuery, GetHubSpotCustomerIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHubSpotCustomerIdQuery, GetHubSpotCustomerIdQueryVariables>(
    GetHubSpotCustomerIdDocument,
    options
  );
}
export type GetHubSpotCustomerIdQueryHookResult = ReturnType<typeof useGetHubSpotCustomerIdQuery>;
export type GetHubSpotCustomerIdLazyQueryHookResult = ReturnType<typeof useGetHubSpotCustomerIdLazyQuery>;
export type GetHubSpotCustomerIdQueryResult = Apollo.QueryResult<
  GetHubSpotCustomerIdQuery,
  GetHubSpotCustomerIdQueryVariables
>;
export const UpdateRestrictChildDocumentsDocument = gql`
  mutation updateRestrictChildDocuments($businessId: ID!, $childId: ID!, $restrictDocuments: Boolean!) {
    updateRestrictChildDocuments(businessId: $businessId, childId: $childId, restrictDocuments: $restrictDocuments) {
      ...childFields
    }
  }
  ${ChildFieldsFragmentDoc}
`;
export type UpdateRestrictChildDocumentsMutationFn = Apollo.MutationFunction<
  UpdateRestrictChildDocumentsMutation,
  UpdateRestrictChildDocumentsMutationVariables
>;

/**
 * __useUpdateRestrictChildDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateRestrictChildDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestrictChildDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestrictChildDocumentsMutation, { data, loading, error }] = useUpdateRestrictChildDocumentsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      childId: // value for 'childId'
 *      restrictDocuments: // value for 'restrictDocuments'
 *   },
 * });
 */
export function useUpdateRestrictChildDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRestrictChildDocumentsMutation,
    UpdateRestrictChildDocumentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRestrictChildDocumentsMutation, UpdateRestrictChildDocumentsMutationVariables>(
    UpdateRestrictChildDocumentsDocument,
    options
  );
}
export type UpdateRestrictChildDocumentsMutationHookResult = ReturnType<typeof useUpdateRestrictChildDocumentsMutation>;
export type UpdateRestrictChildDocumentsMutationResult = Apollo.MutationResult<UpdateRestrictChildDocumentsMutation>;
export type UpdateRestrictChildDocumentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateRestrictChildDocumentsMutation,
  UpdateRestrictChildDocumentsMutationVariables
>;
export const CreateChatActivityDocument = gql`
  mutation createChatActivity($businessId: ID!, $input: CreateActivityInput) {
    createChatActivity(businessId: $businessId, input: $input) {
      channelType
      channelId
      personId
      activityType
      activityTime
      messageContent
    }
  }
`;
export type CreateChatActivityMutationFn = Apollo.MutationFunction<
  CreateChatActivityMutation,
  CreateChatActivityMutationVariables
>;

/**
 * __useCreateChatActivityMutation__
 *
 * To run a mutation, you first call `useCreateChatActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatActivityMutation, { data, loading, error }] = useCreateChatActivityMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChatActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChatActivityMutation, CreateChatActivityMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChatActivityMutation, CreateChatActivityMutationVariables>(
    CreateChatActivityDocument,
    options
  );
}
export type CreateChatActivityMutationHookResult = ReturnType<typeof useCreateChatActivityMutation>;
export type CreateChatActivityMutationResult = Apollo.MutationResult<CreateChatActivityMutation>;
export type CreateChatActivityMutationOptions = Apollo.BaseMutationOptions<
  CreateChatActivityMutation,
  CreateChatActivityMutationVariables
>;
export const DeleteMessageDocument = gql`
  mutation deleteMessage($businessId: ID!, $body: DeleteMessageInput) {
    deleteMessage(businessId: $businessId, body: $body) {
      channelType
      channelId
      personId
      activityType
      activityTime
      messageContent
    }
  }
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useDeleteMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, options);
}
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;
export const FlagMessageDocument = gql`
  mutation flagMessage($businessId: ID!, $body: FlagMessageInput) {
    flagMessage(businessId: $businessId, body: $body) {
      channelType
      channelId
      personId
      activityType
      activityTime
      messageContent
    }
  }
`;
export type FlagMessageMutationFn = Apollo.MutationFunction<FlagMessageMutation, FlagMessageMutationVariables>;

/**
 * __useFlagMessageMutation__
 *
 * To run a mutation, you first call `useFlagMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlagMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flagMessageMutation, { data, loading, error }] = useFlagMessageMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useFlagMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<FlagMessageMutation, FlagMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FlagMessageMutation, FlagMessageMutationVariables>(FlagMessageDocument, options);
}
export type FlagMessageMutationHookResult = ReturnType<typeof useFlagMessageMutation>;
export type FlagMessageMutationResult = Apollo.MutationResult<FlagMessageMutation>;
export type FlagMessageMutationOptions = Apollo.BaseMutationOptions<FlagMessageMutation, FlagMessageMutationVariables>;
export const SetCommsBusinessSettingsDocument = gql`
  mutation setCommsBusinessSettings($businessId: ID!, $input: SetCommsBusinessSettingsInput) {
    setCommsBusinessSettings(businessId: $businessId, input: $input) {
      businessId
      usesComms
      usesBroadcastChannels
      usesAccountChannels
      usesBulletinBoards
      usesInactiveAccounts
      usesStudentChannels
    }
  }
`;
export type SetCommsBusinessSettingsMutationFn = Apollo.MutationFunction<
  SetCommsBusinessSettingsMutation,
  SetCommsBusinessSettingsMutationVariables
>;

/**
 * __useSetCommsBusinessSettingsMutation__
 *
 * To run a mutation, you first call `useSetCommsBusinessSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCommsBusinessSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCommsBusinessSettingsMutation, { data, loading, error }] = useSetCommsBusinessSettingsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCommsBusinessSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<SetCommsBusinessSettingsMutation, SetCommsBusinessSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetCommsBusinessSettingsMutation, SetCommsBusinessSettingsMutationVariables>(
    SetCommsBusinessSettingsDocument,
    options
  );
}
export type SetCommsBusinessSettingsMutationHookResult = ReturnType<typeof useSetCommsBusinessSettingsMutation>;
export type SetCommsBusinessSettingsMutationResult = Apollo.MutationResult<SetCommsBusinessSettingsMutation>;
export type SetCommsBusinessSettingsMutationOptions = Apollo.BaseMutationOptions<
  SetCommsBusinessSettingsMutation,
  SetCommsBusinessSettingsMutationVariables
>;
export const SetCommsCenterSettingsDocument = gql`
  mutation setCommsCenterSettings($businessId: ID!, $centerId: ID!, $input: SetCommsCenterSettingsInput) {
    setCommsCenterSettings(businessId: $businessId, centerId: $centerId, input: $input) {
      businessId
      centerId
      centerName
      usesBroadcastChannels
      usesAccountChannels
      usesBulletinBoards
      usesInactiveAccounts
      usesStudentChannels
      state
      fromDefault
      businessUsesComms
    }
  }
`;
export type SetCommsCenterSettingsMutationFn = Apollo.MutationFunction<
  SetCommsCenterSettingsMutation,
  SetCommsCenterSettingsMutationVariables
>;

/**
 * __useSetCommsCenterSettingsMutation__
 *
 * To run a mutation, you first call `useSetCommsCenterSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCommsCenterSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCommsCenterSettingsMutation, { data, loading, error }] = useSetCommsCenterSettingsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      centerId: // value for 'centerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCommsCenterSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<SetCommsCenterSettingsMutation, SetCommsCenterSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetCommsCenterSettingsMutation, SetCommsCenterSettingsMutationVariables>(
    SetCommsCenterSettingsDocument,
    options
  );
}
export type SetCommsCenterSettingsMutationHookResult = ReturnType<typeof useSetCommsCenterSettingsMutation>;
export type SetCommsCenterSettingsMutationResult = Apollo.MutationResult<SetCommsCenterSettingsMutation>;
export type SetCommsCenterSettingsMutationOptions = Apollo.BaseMutationOptions<
  SetCommsCenterSettingsMutation,
  SetCommsCenterSettingsMutationVariables
>;
export const ResetCenterSettingsDocument = gql`
  mutation resetCenterSettings($businessId: ID!, $centerId: ID!) {
    resetCenterSettings(businessId: $businessId, centerId: $centerId) {
      businessId
      centerId
      centerName
      usesBroadcastChannels
      usesAccountChannels
      usesBulletinBoards
      usesStudentChannels
      fromDefault
      businessUsesComms
    }
  }
`;
export type ResetCenterSettingsMutationFn = Apollo.MutationFunction<
  ResetCenterSettingsMutation,
  ResetCenterSettingsMutationVariables
>;

/**
 * __useResetCenterSettingsMutation__
 *
 * To run a mutation, you first call `useResetCenterSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCenterSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCenterSettingsMutation, { data, loading, error }] = useResetCenterSettingsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      centerId: // value for 'centerId'
 *   },
 * });
 */
export function useResetCenterSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetCenterSettingsMutation, ResetCenterSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetCenterSettingsMutation, ResetCenterSettingsMutationVariables>(
    ResetCenterSettingsDocument,
    options
  );
}
export type ResetCenterSettingsMutationHookResult = ReturnType<typeof useResetCenterSettingsMutation>;
export type ResetCenterSettingsMutationResult = Apollo.MutationResult<ResetCenterSettingsMutation>;
export type ResetCenterSettingsMutationOptions = Apollo.BaseMutationOptions<
  ResetCenterSettingsMutation,
  ResetCenterSettingsMutationVariables
>;
export const GetAccountChannelsDocument = gql`
  query getAccountChannels(
    $businessId: ID!
    $accountId: ID
    $searchTerm: String
    $lastActive: String
    $centerId: [ID]
    $orderByLastJoined: Boolean
    $state: String
    $tag: String
    $pageNumber: Int
    $pageSize: Int
  ) {
    getAccountChannels(
      businessId: $businessId
      accountId: $accountId
      searchTerm: $searchTerm
      lastActive: $lastActive
      centerId: $centerId
      orderByLastJoined: $orderByLastJoined
      state: $state
      tag: $tag
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      totalRecords
      pageNumber
      pageSize
      data {
        businessId
        centerGroup
        centerId
        channelId
        name
        description
        lastActive
        personId
        lastAccessedByPersonId
        hasUnreadMessages
        channelIdentifier
        channelType
        accountStatus
        account {
          id
          entityId
          centerId
          name
          contacts {
            id
            isPrimary
            contactAccountPermissions {
              accountId
              permissions
            }
            hasKtConnectAccess
            firstname
            lastname
            nickname
            email
            avatar {
              url
            }
          }
          children {
            id
            accountChildId
            nickname
            fullName
            firstname
            lastname
          }
          status
          tags
        }
      }
    }
  }
`;

/**
 * __useGetAccountChannelsQuery__
 *
 * To run a query within a React component, call `useGetAccountChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountChannelsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *      searchTerm: // value for 'searchTerm'
 *      lastActive: // value for 'lastActive'
 *      centerId: // value for 'centerId'
 *      orderByLastJoined: // value for 'orderByLastJoined'
 *      state: // value for 'state'
 *      tag: // value for 'tag'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetAccountChannelsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountChannelsQuery, GetAccountChannelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountChannelsQuery, GetAccountChannelsQueryVariables>(
    GetAccountChannelsDocument,
    options
  );
}
export function useGetAccountChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountChannelsQuery, GetAccountChannelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountChannelsQuery, GetAccountChannelsQueryVariables>(
    GetAccountChannelsDocument,
    options
  );
}
export type GetAccountChannelsQueryHookResult = ReturnType<typeof useGetAccountChannelsQuery>;
export type GetAccountChannelsLazyQueryHookResult = ReturnType<typeof useGetAccountChannelsLazyQuery>;
export type GetAccountChannelsQueryResult = Apollo.QueryResult<
  GetAccountChannelsQuery,
  GetAccountChannelsQueryVariables
>;
export const GetCenterChannelsDocument = gql`
  query getCenterChannels(
    $businessId: ID!
    $searchTerm: String
    $lastActive: String
    $centerId: [ID]
    $orderByLastJoined: Boolean
    $state: String
    $tag: String
    $pageNumber: Int
    $pageSize: Int
  ) {
    getCenterChannels(
      businessId: $businessId
      searchTerm: $searchTerm
      lastActive: $lastActive
      centerId: $centerId
      orderByLastJoined: $orderByLastJoined
      state: $state
      tag: $tag
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      totalRecords
      pageNumber
      pageSize
      data {
        businessId
        centerGroup
        centerId
        channelId
        name
        description
        lastActive
        personId
        lastAccessedByPersonId
        hasUnreadMessages
        channelIdentifier
        channelType
      }
    }
  }
`;

/**
 * __useGetCenterChannelsQuery__
 *
 * To run a query within a React component, call `useGetCenterChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCenterChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCenterChannelsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      searchTerm: // value for 'searchTerm'
 *      lastActive: // value for 'lastActive'
 *      centerId: // value for 'centerId'
 *      orderByLastJoined: // value for 'orderByLastJoined'
 *      state: // value for 'state'
 *      tag: // value for 'tag'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetCenterChannelsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCenterChannelsQuery, GetCenterChannelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCenterChannelsQuery, GetCenterChannelsQueryVariables>(GetCenterChannelsDocument, options);
}
export function useGetCenterChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCenterChannelsQuery, GetCenterChannelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCenterChannelsQuery, GetCenterChannelsQueryVariables>(
    GetCenterChannelsDocument,
    options
  );
}
export type GetCenterChannelsQueryHookResult = ReturnType<typeof useGetCenterChannelsQuery>;
export type GetCenterChannelsLazyQueryHookResult = ReturnType<typeof useGetCenterChannelsLazyQuery>;
export type GetCenterChannelsQueryResult = Apollo.QueryResult<GetCenterChannelsQuery, GetCenterChannelsQueryVariables>;
export const GetBulletinBoardChannelsDocument = gql`
  query getBulletinBoardChannels(
    $businessId: ID!
    $searchTerm: String
    $lastActive: String
    $centerId: [ID]
    $orderByLastJoined: Boolean
    $state: String
    $tag: String
    $pageNumber: Int
    $pageSize: Int
  ) {
    getBulletinBoardChannels(
      businessId: $businessId
      searchTerm: $searchTerm
      lastActive: $lastActive
      centerId: $centerId
      orderByLastJoined: $orderByLastJoined
      state: $state
      tag: $tag
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      totalRecords
      pageNumber
      pageSize
      data {
        businessId
        centerGroup
        centerId
        channelId
        name
        description
        lastActive
        personId
        lastAccessedByPersonId
        hasUnreadMessages
        channelIdentifier
        channelType
      }
    }
  }
`;

/**
 * __useGetBulletinBoardChannelsQuery__
 *
 * To run a query within a React component, call `useGetBulletinBoardChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulletinBoardChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulletinBoardChannelsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      searchTerm: // value for 'searchTerm'
 *      lastActive: // value for 'lastActive'
 *      centerId: // value for 'centerId'
 *      orderByLastJoined: // value for 'orderByLastJoined'
 *      state: // value for 'state'
 *      tag: // value for 'tag'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetBulletinBoardChannelsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBulletinBoardChannelsQuery, GetBulletinBoardChannelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBulletinBoardChannelsQuery, GetBulletinBoardChannelsQueryVariables>(
    GetBulletinBoardChannelsDocument,
    options
  );
}
export function useGetBulletinBoardChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBulletinBoardChannelsQuery, GetBulletinBoardChannelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBulletinBoardChannelsQuery, GetBulletinBoardChannelsQueryVariables>(
    GetBulletinBoardChannelsDocument,
    options
  );
}
export type GetBulletinBoardChannelsQueryHookResult = ReturnType<typeof useGetBulletinBoardChannelsQuery>;
export type GetBulletinBoardChannelsLazyQueryHookResult = ReturnType<typeof useGetBulletinBoardChannelsLazyQuery>;
export type GetBulletinBoardChannelsQueryResult = Apollo.QueryResult<
  GetBulletinBoardChannelsQuery,
  GetBulletinBoardChannelsQueryVariables
>;
export const GetCenterChannelDocument = gql`
  query getCenterChannel($businessId: ID!, $centerId: ID!) {
    getCenterChannel(businessId: $businessId, centerId: $centerId) {
      businessId
      centerGroup
      centerId
      channelId
      name
      description
      lastActive
      personId
      lastAccessedByPersonId
      hasUnreadMessages
      channelIdentifier
      channelType
    }
  }
`;

/**
 * __useGetCenterChannelQuery__
 *
 * To run a query within a React component, call `useGetCenterChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCenterChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCenterChannelQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      centerId: // value for 'centerId'
 *   },
 * });
 */
export function useGetCenterChannelQuery(
  baseOptions: Apollo.QueryHookOptions<GetCenterChannelQuery, GetCenterChannelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCenterChannelQuery, GetCenterChannelQueryVariables>(GetCenterChannelDocument, options);
}
export function useGetCenterChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCenterChannelQuery, GetCenterChannelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCenterChannelQuery, GetCenterChannelQueryVariables>(GetCenterChannelDocument, options);
}
export type GetCenterChannelQueryHookResult = ReturnType<typeof useGetCenterChannelQuery>;
export type GetCenterChannelLazyQueryHookResult = ReturnType<typeof useGetCenterChannelLazyQuery>;
export type GetCenterChannelQueryResult = Apollo.QueryResult<GetCenterChannelQuery, GetCenterChannelQueryVariables>;
export const GetAccountChannelDocument = gql`
  query getAccountChannel($businessId: ID!, $accountId: ID!) {
    getAccountChannel(businessId: $businessId, accountId: $accountId) {
      businessId
      centerGroup
      centerId
      channelId
      name
      description
      lastActive
      personId
      lastAccessedByPersonId
      hasUnreadMessages
      channelIdentifier
      channelType
    }
  }
`;

/**
 * __useGetAccountChannelQuery__
 *
 * To run a query within a React component, call `useGetAccountChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountChannelQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountChannelQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountChannelQuery, GetAccountChannelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountChannelQuery, GetAccountChannelQueryVariables>(GetAccountChannelDocument, options);
}
export function useGetAccountChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountChannelQuery, GetAccountChannelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountChannelQuery, GetAccountChannelQueryVariables>(
    GetAccountChannelDocument,
    options
  );
}
export type GetAccountChannelQueryHookResult = ReturnType<typeof useGetAccountChannelQuery>;
export type GetAccountChannelLazyQueryHookResult = ReturnType<typeof useGetAccountChannelLazyQuery>;
export type GetAccountChannelQueryResult = Apollo.QueryResult<GetAccountChannelQuery, GetAccountChannelQueryVariables>;
export const GetBulletinBoardChannelDocument = gql`
  query getBulletinBoardChannel($businessId: ID!, $centerId: ID!) {
    getBulletinBoardChannel(businessId: $businessId, centerId: $centerId) {
      businessId
      centerGroup
      centerId
      channelId
      name
      description
      lastActive
      personId
      lastAccessedByPersonId
      hasUnreadMessages
      channelIdentifier
      channelType
    }
  }
`;

/**
 * __useGetBulletinBoardChannelQuery__
 *
 * To run a query within a React component, call `useGetBulletinBoardChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulletinBoardChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulletinBoardChannelQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      centerId: // value for 'centerId'
 *   },
 * });
 */
export function useGetBulletinBoardChannelQuery(
  baseOptions: Apollo.QueryHookOptions<GetBulletinBoardChannelQuery, GetBulletinBoardChannelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBulletinBoardChannelQuery, GetBulletinBoardChannelQueryVariables>(
    GetBulletinBoardChannelDocument,
    options
  );
}
export function useGetBulletinBoardChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBulletinBoardChannelQuery, GetBulletinBoardChannelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBulletinBoardChannelQuery, GetBulletinBoardChannelQueryVariables>(
    GetBulletinBoardChannelDocument,
    options
  );
}
export type GetBulletinBoardChannelQueryHookResult = ReturnType<typeof useGetBulletinBoardChannelQuery>;
export type GetBulletinBoardChannelLazyQueryHookResult = ReturnType<typeof useGetBulletinBoardChannelLazyQuery>;
export type GetBulletinBoardChannelQueryResult = Apollo.QueryResult<
  GetBulletinBoardChannelQuery,
  GetBulletinBoardChannelQueryVariables
>;
export const GetChatActivityDocument = gql`
  query getChatActivity(
    $businessId: ID!
    $id: String
    $channelId: ID
    $personId: ID
    $pubNubMessageId: String
    $timeToken: Float
    $activityType: ActivityType
    $activitySince: String
    $pageNumber: Int
    $pageSize: Int
  ) {
    getChatActivity(
      businessId: $businessId
      id: $id
      channelId: $channelId
      personId: $personId
      pubNubMessageId: $pubNubMessageId
      timeToken: $timeToken
      activityType: $activityType
      activitySince: $activitySince
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      totalRecords
      pageNumber
      pageSize
      data {
        channelId
        channelType
        channelIdentifier
        name
        id
        personId
        activityType
        activityTime
        person {
          id
          firstname
          lastname
          type
        }
      }
    }
  }
`;

/**
 * __useGetChatActivityQuery__
 *
 * To run a query within a React component, call `useGetChatActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatActivityQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      id: // value for 'id'
 *      channelId: // value for 'channelId'
 *      personId: // value for 'personId'
 *      pubNubMessageId: // value for 'pubNubMessageId'
 *      timeToken: // value for 'timeToken'
 *      activityType: // value for 'activityType'
 *      activitySince: // value for 'activitySince'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetChatActivityQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatActivityQuery, GetChatActivityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatActivityQuery, GetChatActivityQueryVariables>(GetChatActivityDocument, options);
}
export function useGetChatActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatActivityQuery, GetChatActivityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatActivityQuery, GetChatActivityQueryVariables>(GetChatActivityDocument, options);
}
export type GetChatActivityQueryHookResult = ReturnType<typeof useGetChatActivityQuery>;
export type GetChatActivityLazyQueryHookResult = ReturnType<typeof useGetChatActivityLazyQuery>;
export type GetChatActivityQueryResult = Apollo.QueryResult<GetChatActivityQuery, GetChatActivityQueryVariables>;
export const GetChatAuthDocument = gql`
  query getChatAuth($businessId: ID!, $returnWildcardChannels: Boolean, $channelId: [String]) {
    getChatAuth(businessId: $businessId, returnWildcardChannels: $returnWildcardChannels, channelId: $channelId) {
      channelIdentifier
      channelId
      auth {
        personId
        canWriteMessage
        canDeleteMessage
        canReportMessage
      }
    }
  }
`;

/**
 * __useGetChatAuthQuery__
 *
 * To run a query within a React component, call `useGetChatAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatAuthQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      returnWildcardChannels: // value for 'returnWildcardChannels'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetChatAuthQuery(baseOptions: Apollo.QueryHookOptions<GetChatAuthQuery, GetChatAuthQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatAuthQuery, GetChatAuthQueryVariables>(GetChatAuthDocument, options);
}
export function useGetChatAuthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatAuthQuery, GetChatAuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatAuthQuery, GetChatAuthQueryVariables>(GetChatAuthDocument, options);
}
export type GetChatAuthQueryHookResult = ReturnType<typeof useGetChatAuthQuery>;
export type GetChatAuthLazyQueryHookResult = ReturnType<typeof useGetChatAuthLazyQuery>;
export type GetChatAuthQueryResult = Apollo.QueryResult<GetChatAuthQuery, GetChatAuthQueryVariables>;
export const GetChatAuthTokenDocument = gql`
  query getChatAuthToken($businessId: ID!) {
    getChatAuthToken(businessId: $businessId) {
      authToken
      expiryDateTimeOffset
      publishKey
      subscribeKey
    }
  }
`;

/**
 * __useGetChatAuthTokenQuery__
 *
 * To run a query within a React component, call `useGetChatAuthTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatAuthTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatAuthTokenQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetChatAuthTokenQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatAuthTokenQuery, GetChatAuthTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatAuthTokenQuery, GetChatAuthTokenQueryVariables>(GetChatAuthTokenDocument, options);
}
export function useGetChatAuthTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatAuthTokenQuery, GetChatAuthTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatAuthTokenQuery, GetChatAuthTokenQueryVariables>(GetChatAuthTokenDocument, options);
}
export type GetChatAuthTokenQueryHookResult = ReturnType<typeof useGetChatAuthTokenQuery>;
export type GetChatAuthTokenLazyQueryHookResult = ReturnType<typeof useGetChatAuthTokenLazyQuery>;
export type GetChatAuthTokenQueryResult = Apollo.QueryResult<GetChatAuthTokenQuery, GetChatAuthTokenQueryVariables>;
export const GetAuthBroadcastChannelIdsDocument = gql`
  query getAuthBroadcastChannelIds($businessId: ID!) {
    getAuthBroadcastChannelIds(businessId: $businessId) {
      centerChannels
    }
  }
`;

/**
 * __useGetAuthBroadcastChannelIdsQuery__
 *
 * To run a query within a React component, call `useGetAuthBroadcastChannelIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthBroadcastChannelIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthBroadcastChannelIdsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetAuthBroadcastChannelIdsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAuthBroadcastChannelIdsQuery, GetAuthBroadcastChannelIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthBroadcastChannelIdsQuery, GetAuthBroadcastChannelIdsQueryVariables>(
    GetAuthBroadcastChannelIdsDocument,
    options
  );
}
export function useGetAuthBroadcastChannelIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAuthBroadcastChannelIdsQuery, GetAuthBroadcastChannelIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuthBroadcastChannelIdsQuery, GetAuthBroadcastChannelIdsQueryVariables>(
    GetAuthBroadcastChannelIdsDocument,
    options
  );
}
export type GetAuthBroadcastChannelIdsQueryHookResult = ReturnType<typeof useGetAuthBroadcastChannelIdsQuery>;
export type GetAuthBroadcastChannelIdsLazyQueryHookResult = ReturnType<typeof useGetAuthBroadcastChannelIdsLazyQuery>;
export type GetAuthBroadcastChannelIdsQueryResult = Apollo.QueryResult<
  GetAuthBroadcastChannelIdsQuery,
  GetAuthBroadcastChannelIdsQueryVariables
>;
export const GetAuthBulletinBoardChannelIdsDocument = gql`
  query getAuthBulletinBoardChannelIds($businessId: ID!) {
    getAuthBulletinBoardChannelIds(businessId: $businessId) {
      bulletinBoardChannels
    }
  }
`;

/**
 * __useGetAuthBulletinBoardChannelIdsQuery__
 *
 * To run a query within a React component, call `useGetAuthBulletinBoardChannelIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthBulletinBoardChannelIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthBulletinBoardChannelIdsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetAuthBulletinBoardChannelIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthBulletinBoardChannelIdsQuery,
    GetAuthBulletinBoardChannelIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthBulletinBoardChannelIdsQuery, GetAuthBulletinBoardChannelIdsQueryVariables>(
    GetAuthBulletinBoardChannelIdsDocument,
    options
  );
}
export function useGetAuthBulletinBoardChannelIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthBulletinBoardChannelIdsQuery,
    GetAuthBulletinBoardChannelIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuthBulletinBoardChannelIdsQuery, GetAuthBulletinBoardChannelIdsQueryVariables>(
    GetAuthBulletinBoardChannelIdsDocument,
    options
  );
}
export type GetAuthBulletinBoardChannelIdsQueryHookResult = ReturnType<typeof useGetAuthBulletinBoardChannelIdsQuery>;
export type GetAuthBulletinBoardChannelIdsLazyQueryHookResult = ReturnType<
  typeof useGetAuthBulletinBoardChannelIdsLazyQuery
>;
export type GetAuthBulletinBoardChannelIdsQueryResult = Apollo.QueryResult<
  GetAuthBulletinBoardChannelIdsQuery,
  GetAuthBulletinBoardChannelIdsQueryVariables
>;
export const GetChannelUsersDocument = gql`
  query getChannelUsers($businessId: ID!, $channelId: ID!) {
    getChannelUsers(businessId: $businessId, channelId: $channelId) {
      personId
      name
      type
      isPrimaryContact
      hasKTConnect
    }
  }
`;

/**
 * __useGetChannelUsersQuery__
 *
 * To run a query within a React component, call `useGetChannelUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelUsersQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetChannelUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetChannelUsersQuery, GetChannelUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChannelUsersQuery, GetChannelUsersQueryVariables>(GetChannelUsersDocument, options);
}
export function useGetChannelUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChannelUsersQuery, GetChannelUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChannelUsersQuery, GetChannelUsersQueryVariables>(GetChannelUsersDocument, options);
}
export type GetChannelUsersQueryHookResult = ReturnType<typeof useGetChannelUsersQuery>;
export type GetChannelUsersLazyQueryHookResult = ReturnType<typeof useGetChannelUsersLazyQuery>;
export type GetChannelUsersQueryResult = Apollo.QueryResult<GetChannelUsersQuery, GetChannelUsersQueryVariables>;
export const GetCommsBusinessSettingsDocument = gql`
  query getCommsBusinessSettings($businessId: ID!) {
    getCommsBusinessSettings(businessId: $businessId) {
      businessId
      usesComms
      usesBroadcastChannels
      usesAccountChannels
      usesBulletinBoards
      usesInactiveAccounts
      usesStudentChannels
      centerCount
    }
  }
`;

/**
 * __useGetCommsBusinessSettingsQuery__
 *
 * To run a query within a React component, call `useGetCommsBusinessSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommsBusinessSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommsBusinessSettingsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetCommsBusinessSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommsBusinessSettingsQuery, GetCommsBusinessSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommsBusinessSettingsQuery, GetCommsBusinessSettingsQueryVariables>(
    GetCommsBusinessSettingsDocument,
    options
  );
}
export function useGetCommsBusinessSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommsBusinessSettingsQuery, GetCommsBusinessSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommsBusinessSettingsQuery, GetCommsBusinessSettingsQueryVariables>(
    GetCommsBusinessSettingsDocument,
    options
  );
}
export type GetCommsBusinessSettingsQueryHookResult = ReturnType<typeof useGetCommsBusinessSettingsQuery>;
export type GetCommsBusinessSettingsLazyQueryHookResult = ReturnType<typeof useGetCommsBusinessSettingsLazyQuery>;
export type GetCommsBusinessSettingsQueryResult = Apollo.QueryResult<
  GetCommsBusinessSettingsQuery,
  GetCommsBusinessSettingsQueryVariables
>;
export const GetCommsAllCenterSettingsDocument = gql`
  query getCommsAllCenterSettings(
    $businessId: ID!
    $centerId: [String]
    $searchTerm: String
    $showOverrides: Boolean
    $state: String
    $tag: String
    $pageNumber: Int
    $pageSize: Int
  ) {
    getCommsAllCenterSettings(
      businessId: $businessId
      centerId: $centerId
      searchTerm: $searchTerm
      showOverrides: $showOverrides
      state: $state
      tag: $tag
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      pageNumber
      pageSize
      totalRecords
      data {
        businessId
        centerId
        centerName
        usesBroadcastChannels
        usesAccountChannels
        usesBulletinBoards
        usesInactiveAccounts
        usesStudentChannels
        fromDefault
        businessUsesComms
        state
        tags
      }
    }
  }
`;

/**
 * __useGetCommsAllCenterSettingsQuery__
 *
 * To run a query within a React component, call `useGetCommsAllCenterSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommsAllCenterSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommsAllCenterSettingsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      centerId: // value for 'centerId'
 *      searchTerm: // value for 'searchTerm'
 *      showOverrides: // value for 'showOverrides'
 *      state: // value for 'state'
 *      tag: // value for 'tag'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetCommsAllCenterSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommsAllCenterSettingsQuery, GetCommsAllCenterSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommsAllCenterSettingsQuery, GetCommsAllCenterSettingsQueryVariables>(
    GetCommsAllCenterSettingsDocument,
    options
  );
}
export function useGetCommsAllCenterSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommsAllCenterSettingsQuery, GetCommsAllCenterSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommsAllCenterSettingsQuery, GetCommsAllCenterSettingsQueryVariables>(
    GetCommsAllCenterSettingsDocument,
    options
  );
}
export type GetCommsAllCenterSettingsQueryHookResult = ReturnType<typeof useGetCommsAllCenterSettingsQuery>;
export type GetCommsAllCenterSettingsLazyQueryHookResult = ReturnType<typeof useGetCommsAllCenterSettingsLazyQuery>;
export type GetCommsAllCenterSettingsQueryResult = Apollo.QueryResult<
  GetCommsAllCenterSettingsQuery,
  GetCommsAllCenterSettingsQueryVariables
>;
export const GetCommsCenterSettingsDocument = gql`
  query getCommsCenterSettings($businessId: ID!, $centerId: ID!) {
    getCommsCenterSettings(businessId: $businessId, centerId: $centerId) {
      businessId
      centerId
      centerName
      usesBroadcastChannels
      usesAccountChannels
      usesBulletinBoards
      usesStudentChannels
      fromDefault
      businessUsesComms
    }
  }
`;

/**
 * __useGetCommsCenterSettingsQuery__
 *
 * To run a query within a React component, call `useGetCommsCenterSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommsCenterSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommsCenterSettingsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      centerId: // value for 'centerId'
 *   },
 * });
 */
export function useGetCommsCenterSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommsCenterSettingsQuery, GetCommsCenterSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommsCenterSettingsQuery, GetCommsCenterSettingsQueryVariables>(
    GetCommsCenterSettingsDocument,
    options
  );
}
export function useGetCommsCenterSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommsCenterSettingsQuery, GetCommsCenterSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommsCenterSettingsQuery, GetCommsCenterSettingsQueryVariables>(
    GetCommsCenterSettingsDocument,
    options
  );
}
export type GetCommsCenterSettingsQueryHookResult = ReturnType<typeof useGetCommsCenterSettingsQuery>;
export type GetCommsCenterSettingsLazyQueryHookResult = ReturnType<typeof useGetCommsCenterSettingsLazyQuery>;
export type GetCommsCenterSettingsQueryResult = Apollo.QueryResult<
  GetCommsCenterSettingsQuery,
  GetCommsCenterSettingsQueryVariables
>;
export const GetMentionableEntitiesAtCenterDocument = gql`
  query getMentionableEntitiesAtCenter($businessId: ID!, $centerId: ID!) {
    getCenterStaff(businessId: $businessId, centerId: $centerId) {
      centerId
      staff {
        id
        name
        email
      }
    }
    getCenterClasses(businessId: $businessId, centerId: $centerId) {
      centerId
      classes {
        id
        name
      }
    }
  }
`;

/**
 * __useGetMentionableEntitiesAtCenterQuery__
 *
 * To run a query within a React component, call `useGetMentionableEntitiesAtCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMentionableEntitiesAtCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMentionableEntitiesAtCenterQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      centerId: // value for 'centerId'
 *   },
 * });
 */
export function useGetMentionableEntitiesAtCenterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMentionableEntitiesAtCenterQuery,
    GetMentionableEntitiesAtCenterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMentionableEntitiesAtCenterQuery, GetMentionableEntitiesAtCenterQueryVariables>(
    GetMentionableEntitiesAtCenterDocument,
    options
  );
}
export function useGetMentionableEntitiesAtCenterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMentionableEntitiesAtCenterQuery,
    GetMentionableEntitiesAtCenterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMentionableEntitiesAtCenterQuery, GetMentionableEntitiesAtCenterQueryVariables>(
    GetMentionableEntitiesAtCenterDocument,
    options
  );
}
export type GetMentionableEntitiesAtCenterQueryHookResult = ReturnType<typeof useGetMentionableEntitiesAtCenterQuery>;
export type GetMentionableEntitiesAtCenterLazyQueryHookResult = ReturnType<
  typeof useGetMentionableEntitiesAtCenterLazyQuery
>;
export type GetMentionableEntitiesAtCenterQueryResult = Apollo.QueryResult<
  GetMentionableEntitiesAtCenterQuery,
  GetMentionableEntitiesAtCenterQueryVariables
>;
export const GetThreadMessagesDocument = gql`
  query getThreadMessages($businessId: ID!, $centerId: ID!) {
    getThreadMessages(businessId: $businessId, centerId: $centerId) {
      centerId
      messages {
        rootPubNubMessageId
        parentMessage {
          id
          activityTime
          text
          sender {
            id
            name
            profileUrl
          }
          createdAt
          custom {
            directedAt {
              id
              displayName
              entityType
            }
          }
        }
        relatedMessages {
          id
          activityTime
          text
          sender {
            id
            name
            profileUrl
          }
          createdAt
          custom {
            directedAt {
              id
              displayName
              entityType
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetThreadMessagesQuery__
 *
 * To run a query within a React component, call `useGetThreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadMessagesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      centerId: // value for 'centerId'
 *   },
 * });
 */
export function useGetThreadMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<GetThreadMessagesQuery, GetThreadMessagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadMessagesQuery, GetThreadMessagesQueryVariables>(GetThreadMessagesDocument, options);
}
export function useGetThreadMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetThreadMessagesQuery, GetThreadMessagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadMessagesQuery, GetThreadMessagesQueryVariables>(
    GetThreadMessagesDocument,
    options
  );
}
export type GetThreadMessagesQueryHookResult = ReturnType<typeof useGetThreadMessagesQuery>;
export type GetThreadMessagesLazyQueryHookResult = ReturnType<typeof useGetThreadMessagesLazyQuery>;
export type GetThreadMessagesQueryResult = Apollo.QueryResult<GetThreadMessagesQuery, GetThreadMessagesQueryVariables>;
export const SearchContactsV2Document = gql`
  query searchContactsV2($input: SearchContactsV2Input!) {
    searchContactsV2(input: $input) {
      totalRecords
      data {
        accounts {
          id
        }
        avatar {
          ...avatarFields
        }
        children {
          avatar {
            ...avatarFields
          }
          dob
          firstname
          id
          lastname
          nickname
          relationshipType
        }
        email
        firstname
        hasKtConnectAccess
        id
        lastname
        nickname
        primaryPhoneNumber {
          number
          type
        }
      }
      pageNumber
      pageSize
    }
  }
  ${AvatarFieldsFragmentDoc}
`;

/**
 * __useSearchContactsV2Query__
 *
 * To run a query within a React component, call `useSearchContactsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchContactsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContactsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchContactsV2Query(
  baseOptions: Apollo.QueryHookOptions<SearchContactsV2Query, SearchContactsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchContactsV2Query, SearchContactsV2QueryVariables>(SearchContactsV2Document, options);
}
export function useSearchContactsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchContactsV2Query, SearchContactsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchContactsV2Query, SearchContactsV2QueryVariables>(SearchContactsV2Document, options);
}
export type SearchContactsV2QueryHookResult = ReturnType<typeof useSearchContactsV2Query>;
export type SearchContactsV2LazyQueryHookResult = ReturnType<typeof useSearchContactsV2LazyQuery>;
export type SearchContactsV2QueryResult = Apollo.QueryResult<SearchContactsV2Query, SearchContactsV2QueryVariables>;
export const GetDocumentByIdDocument = gql`
  query getDocumentById($businessId: ID!, $documentId: ID!) {
    getDocumentById(businessId: $businessId, documentId: $documentId) {
      id
      businessId
      name
      type
      expirationDate
      link
      reviewDaysBeforeExpiration
      reviewDate
      wellnessItemId
      archivedAt
      archivedBy
      createdAt
      createdByPerson {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
      }
    }
  }
`;

/**
 * __useGetDocumentByIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentByIdQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetDocumentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>(GetDocumentByIdDocument, options);
}
export function useGetDocumentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>(GetDocumentByIdDocument, options);
}
export type GetDocumentByIdQueryHookResult = ReturnType<typeof useGetDocumentByIdQuery>;
export type GetDocumentByIdLazyQueryHookResult = ReturnType<typeof useGetDocumentByIdLazyQuery>;
export type GetDocumentByIdQueryResult = Apollo.QueryResult<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>;
export const MarkApplicationReadDocument = gql`
  mutation markApplicationRead($businessId: String!, $applicationId: String!) {
    markRead(businessId: $businessId, applicationId: $applicationId)
  }
`;
export type MarkApplicationReadMutationFn = Apollo.MutationFunction<
  MarkApplicationReadMutation,
  MarkApplicationReadMutationVariables
>;

/**
 * __useMarkApplicationReadMutation__
 *
 * To run a mutation, you first call `useMarkApplicationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkApplicationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markApplicationReadMutation, { data, loading, error }] = useMarkApplicationReadMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useMarkApplicationReadMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkApplicationReadMutation, MarkApplicationReadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkApplicationReadMutation, MarkApplicationReadMutationVariables>(
    MarkApplicationReadDocument,
    options
  );
}
export type MarkApplicationReadMutationHookResult = ReturnType<typeof useMarkApplicationReadMutation>;
export type MarkApplicationReadMutationResult = Apollo.MutationResult<MarkApplicationReadMutation>;
export type MarkApplicationReadMutationOptions = Apollo.BaseMutationOptions<
  MarkApplicationReadMutation,
  MarkApplicationReadMutationVariables
>;
export const BulkApproveOffersDocument = gql`
  mutation bulkApproveOffers($input: ApplicationMultiApproveInput!) {
    multiApproveApplication(input: $input) {
      isApproved
      applicationId
      message
      offers {
        offerId
        isApproved
        message
      }
    }
  }
`;
export type BulkApproveOffersMutationFn = Apollo.MutationFunction<
  BulkApproveOffersMutation,
  BulkApproveOffersMutationVariables
>;

/**
 * __useBulkApproveOffersMutation__
 *
 * To run a mutation, you first call `useBulkApproveOffersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkApproveOffersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkApproveOffersMutation, { data, loading, error }] = useBulkApproveOffersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkApproveOffersMutation(
  baseOptions?: Apollo.MutationHookOptions<BulkApproveOffersMutation, BulkApproveOffersMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkApproveOffersMutation, BulkApproveOffersMutationVariables>(
    BulkApproveOffersDocument,
    options
  );
}
export type BulkApproveOffersMutationHookResult = ReturnType<typeof useBulkApproveOffersMutation>;
export type BulkApproveOffersMutationResult = Apollo.MutationResult<BulkApproveOffersMutation>;
export type BulkApproveOffersMutationOptions = Apollo.BaseMutationOptions<
  BulkApproveOffersMutation,
  BulkApproveOffersMutationVariables
>;
export const UpdateEnrolmentFormDocument = gql`
  mutation updateEnrolmentForm($input: UpdateEnrollmentFormInput!) {
    updateEnrollmentForm(input: $input) {
      id
      businessId
      formSchema
      name
      usedByCenterIds
      version
      isDraft
    }
  }
`;
export type UpdateEnrolmentFormMutationFn = Apollo.MutationFunction<
  UpdateEnrolmentFormMutation,
  UpdateEnrolmentFormMutationVariables
>;

/**
 * __useUpdateEnrolmentFormMutation__
 *
 * To run a mutation, you first call `useUpdateEnrolmentFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnrolmentFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnrolmentFormMutation, { data, loading, error }] = useUpdateEnrolmentFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnrolmentFormMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEnrolmentFormMutation, UpdateEnrolmentFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEnrolmentFormMutation, UpdateEnrolmentFormMutationVariables>(
    UpdateEnrolmentFormDocument,
    options
  );
}
export type UpdateEnrolmentFormMutationHookResult = ReturnType<typeof useUpdateEnrolmentFormMutation>;
export type UpdateEnrolmentFormMutationResult = Apollo.MutationResult<UpdateEnrolmentFormMutation>;
export type UpdateEnrolmentFormMutationOptions = Apollo.BaseMutationOptions<
  UpdateEnrolmentFormMutation,
  UpdateEnrolmentFormMutationVariables
>;
export const EncryptEnrollmentFormHashDocument = gql`
  mutation encryptEnrollmentFormHash($businessId: ID!, $enrollmentFormId: ID!) {
    encryptEnrollmentFormHash(businessId: $businessId, enrollmentFormId: $enrollmentFormId)
  }
`;
export type EncryptEnrollmentFormHashMutationFn = Apollo.MutationFunction<
  EncryptEnrollmentFormHashMutation,
  EncryptEnrollmentFormHashMutationVariables
>;

/**
 * __useEncryptEnrollmentFormHashMutation__
 *
 * To run a mutation, you first call `useEncryptEnrollmentFormHashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEncryptEnrollmentFormHashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [encryptEnrollmentFormHashMutation, { data, loading, error }] = useEncryptEnrollmentFormHashMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      enrollmentFormId: // value for 'enrollmentFormId'
 *   },
 * });
 */
export function useEncryptEnrollmentFormHashMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EncryptEnrollmentFormHashMutation,
    EncryptEnrollmentFormHashMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EncryptEnrollmentFormHashMutation, EncryptEnrollmentFormHashMutationVariables>(
    EncryptEnrollmentFormHashDocument,
    options
  );
}
export type EncryptEnrollmentFormHashMutationHookResult = ReturnType<typeof useEncryptEnrollmentFormHashMutation>;
export type EncryptEnrollmentFormHashMutationResult = Apollo.MutationResult<EncryptEnrollmentFormHashMutation>;
export type EncryptEnrollmentFormHashMutationOptions = Apollo.BaseMutationOptions<
  EncryptEnrollmentFormHashMutation,
  EncryptEnrollmentFormHashMutationVariables
>;
export const MarkLostDocument = gql`
  mutation markLost($input: ApplicationMarkLostInput) {
    markLost(input: $input)
  }
`;
export type MarkLostMutationFn = Apollo.MutationFunction<MarkLostMutation, MarkLostMutationVariables>;

/**
 * __useMarkLostMutation__
 *
 * To run a mutation, you first call `useMarkLostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkLostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markLostMutation, { data, loading, error }] = useMarkLostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkLostMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkLostMutation, MarkLostMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkLostMutation, MarkLostMutationVariables>(MarkLostDocument, options);
}
export type MarkLostMutationHookResult = ReturnType<typeof useMarkLostMutation>;
export type MarkLostMutationResult = Apollo.MutationResult<MarkLostMutation>;
export type MarkLostMutationOptions = Apollo.BaseMutationOptions<MarkLostMutation, MarkLostMutationVariables>;
export const UpsertApplicationDepositDocument = gql`
  mutation upsertApplicationDeposit($input: UpsertApplicationDepositInput!) {
    upsertApplicationDeposit(input: $input) {
      id
      applicationDeposits {
        id
        amount
        description
        collected
        appliesDate
      }
    }
  }
`;
export type UpsertApplicationDepositMutationFn = Apollo.MutationFunction<
  UpsertApplicationDepositMutation,
  UpsertApplicationDepositMutationVariables
>;

/**
 * __useUpsertApplicationDepositMutation__
 *
 * To run a mutation, you first call `useUpsertApplicationDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertApplicationDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertApplicationDepositMutation, { data, loading, error }] = useUpsertApplicationDepositMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertApplicationDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertApplicationDepositMutation, UpsertApplicationDepositMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertApplicationDepositMutation, UpsertApplicationDepositMutationVariables>(
    UpsertApplicationDepositDocument,
    options
  );
}
export type UpsertApplicationDepositMutationHookResult = ReturnType<typeof useUpsertApplicationDepositMutation>;
export type UpsertApplicationDepositMutationResult = Apollo.MutationResult<UpsertApplicationDepositMutation>;
export type UpsertApplicationDepositMutationOptions = Apollo.BaseMutationOptions<
  UpsertApplicationDepositMutation,
  UpsertApplicationDepositMutationVariables
>;
export const RequestUpdateEnrolmentFormDocument = gql`
  mutation requestUpdateEnrolmentForm($input: RequestUpdateEnrolmentFormInput!) {
    requestUpdateEnrolmentForm(input: $input)
  }
`;
export type RequestUpdateEnrolmentFormMutationFn = Apollo.MutationFunction<
  RequestUpdateEnrolmentFormMutation,
  RequestUpdateEnrolmentFormMutationVariables
>;

/**
 * __useRequestUpdateEnrolmentFormMutation__
 *
 * To run a mutation, you first call `useRequestUpdateEnrolmentFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUpdateEnrolmentFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUpdateEnrolmentFormMutation, { data, loading, error }] = useRequestUpdateEnrolmentFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestUpdateEnrolmentFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestUpdateEnrolmentFormMutation,
    RequestUpdateEnrolmentFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestUpdateEnrolmentFormMutation, RequestUpdateEnrolmentFormMutationVariables>(
    RequestUpdateEnrolmentFormDocument,
    options
  );
}
export type RequestUpdateEnrolmentFormMutationHookResult = ReturnType<typeof useRequestUpdateEnrolmentFormMutation>;
export type RequestUpdateEnrolmentFormMutationResult = Apollo.MutationResult<RequestUpdateEnrolmentFormMutation>;
export type RequestUpdateEnrolmentFormMutationOptions = Apollo.BaseMutationOptions<
  RequestUpdateEnrolmentFormMutation,
  RequestUpdateEnrolmentFormMutationVariables
>;
export const EditApplicationDocument = gql`
  mutation editApplication($input: EditApplicationInput!) {
    editApplication(input: $input) {
      id
      firstName
      lastName
      email
      phoneNumber
      children {
        id
        firstName
        lastName
        dateOfBirth
        enquiries {
          id
          startDate
        }
      }
    }
  }
`;
export type EditApplicationMutationFn = Apollo.MutationFunction<
  EditApplicationMutation,
  EditApplicationMutationVariables
>;

/**
 * __useEditApplicationMutation__
 *
 * To run a mutation, you first call `useEditApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editApplicationMutation, { data, loading, error }] = useEditApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<EditApplicationMutation, EditApplicationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditApplicationMutation, EditApplicationMutationVariables>(
    EditApplicationDocument,
    options
  );
}
export type EditApplicationMutationHookResult = ReturnType<typeof useEditApplicationMutation>;
export type EditApplicationMutationResult = Apollo.MutationResult<EditApplicationMutation>;
export type EditApplicationMutationOptions = Apollo.BaseMutationOptions<
  EditApplicationMutation,
  EditApplicationMutationVariables
>;
export const CreateApplicationNoteDocument = gql`
  mutation createApplicationNote($input: CreateApplicationNoteInput!) {
    createApplicationNote(input: $input) {
      ...applicationNoteFields
    }
  }
  ${ApplicationNoteFieldsFragmentDoc}
`;
export type CreateApplicationNoteMutationFn = Apollo.MutationFunction<
  CreateApplicationNoteMutation,
  CreateApplicationNoteMutationVariables
>;

/**
 * __useCreateApplicationNoteMutation__
 *
 * To run a mutation, you first call `useCreateApplicationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationNoteMutation, { data, loading, error }] = useCreateApplicationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApplicationNoteMutation, CreateApplicationNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApplicationNoteMutation, CreateApplicationNoteMutationVariables>(
    CreateApplicationNoteDocument,
    options
  );
}
export type CreateApplicationNoteMutationHookResult = ReturnType<typeof useCreateApplicationNoteMutation>;
export type CreateApplicationNoteMutationResult = Apollo.MutationResult<CreateApplicationNoteMutation>;
export type CreateApplicationNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationNoteMutation,
  CreateApplicationNoteMutationVariables
>;
export const EditApplicationNoteDocument = gql`
  mutation editApplicationNote($input: EditApplicationNoteInput!) {
    editApplicationNote(input: $input) {
      ...applicationNoteFields
    }
  }
  ${ApplicationNoteFieldsFragmentDoc}
`;
export type EditApplicationNoteMutationFn = Apollo.MutationFunction<
  EditApplicationNoteMutation,
  EditApplicationNoteMutationVariables
>;

/**
 * __useEditApplicationNoteMutation__
 *
 * To run a mutation, you first call `useEditApplicationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditApplicationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editApplicationNoteMutation, { data, loading, error }] = useEditApplicationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditApplicationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<EditApplicationNoteMutation, EditApplicationNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditApplicationNoteMutation, EditApplicationNoteMutationVariables>(
    EditApplicationNoteDocument,
    options
  );
}
export type EditApplicationNoteMutationHookResult = ReturnType<typeof useEditApplicationNoteMutation>;
export type EditApplicationNoteMutationResult = Apollo.MutationResult<EditApplicationNoteMutation>;
export type EditApplicationNoteMutationOptions = Apollo.BaseMutationOptions<
  EditApplicationNoteMutation,
  EditApplicationNoteMutationVariables
>;
export const DeleteApplicationNoteDocument = gql`
  mutation deleteApplicationNote($input: DeleteApplicationNoteInput!) {
    deleteApplicationNote(input: $input) {
      ...applicationNoteFields
    }
  }
  ${ApplicationNoteFieldsFragmentDoc}
`;
export type DeleteApplicationNoteMutationFn = Apollo.MutationFunction<
  DeleteApplicationNoteMutation,
  DeleteApplicationNoteMutationVariables
>;

/**
 * __useDeleteApplicationNoteMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationNoteMutation, { data, loading, error }] = useDeleteApplicationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApplicationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteApplicationNoteMutation, DeleteApplicationNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteApplicationNoteMutation, DeleteApplicationNoteMutationVariables>(
    DeleteApplicationNoteDocument,
    options
  );
}
export type DeleteApplicationNoteMutationHookResult = ReturnType<typeof useDeleteApplicationNoteMutation>;
export type DeleteApplicationNoteMutationResult = Apollo.MutationResult<DeleteApplicationNoteMutation>;
export type DeleteApplicationNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicationNoteMutation,
  DeleteApplicationNoteMutationVariables
>;
export const GetApplicationsByStageDocument = gql`
  query getApplicationsByStage($businessId: String!, $input: GetApplicationsInput!) {
    getApplicationsByStage(businessId: $businessId, input: $input) {
      totalRecords
      pageNumber
      totalRecords
      data {
        id
        businessId
        state
        userFlag
        enquiryDate
        firstName
        lastName
        phoneNumber
        email
        accountName
        accountId
        centers
        familyReceivesSubsidy
        enrollmentSource
        appliedFlowType
        applicationDeposits {
          id
          amount
          description
          collected
          appliesDate
        }
        offers {
          id
          linkCode
          expiryDate
          enrolmentFormPdfLink
          applicationPaidAmount
          applicationPaidDate
          adminFeeRequired
          applicationPaymentReference
          enrolmentFormDataRecordId
          enrollmentFormChanged
          isAllScheduleOffersAcceptInWaitlisted
        }
        children {
          id
          firstName
          lastName
          dateOfBirth
          isNew
          enquiries {
            id
            careType
            cycleType
            startDate
            days {
              id
              weekType
              dayOfWeek
            }
          }
          offers {
            cycleType
            id
            state
            generationState
            careType
            startDate
            endDate
            applicationOfferId
            feeId
            classId
            fee {
              name
            }
            class {
              name
            }
            days {
              id
              weekType
              dayOfWeek
            }
            program {
              ...enrollmentProgramFields
            }
          }
        }
        notes {
          ...applicationNoteFields
        }
      }
    }
  }
  ${EnrollmentProgramFieldsFragmentDoc}
  ${ApplicationNoteFieldsFragmentDoc}
`;

/**
 * __useGetApplicationsByStageQuery__
 *
 * To run a query within a React component, call `useGetApplicationsByStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsByStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsByStageQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetApplicationsByStageQuery(
  baseOptions: Apollo.QueryHookOptions<GetApplicationsByStageQuery, GetApplicationsByStageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApplicationsByStageQuery, GetApplicationsByStageQueryVariables>(
    GetApplicationsByStageDocument,
    options
  );
}
export function useGetApplicationsByStageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationsByStageQuery, GetApplicationsByStageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApplicationsByStageQuery, GetApplicationsByStageQueryVariables>(
    GetApplicationsByStageDocument,
    options
  );
}
export type GetApplicationsByStageQueryHookResult = ReturnType<typeof useGetApplicationsByStageQuery>;
export type GetApplicationsByStageLazyQueryHookResult = ReturnType<typeof useGetApplicationsByStageLazyQuery>;
export type GetApplicationsByStageQueryResult = Apollo.QueryResult<
  GetApplicationsByStageQuery,
  GetApplicationsByStageQueryVariables
>;
export const GetEnrollmentProgramsDocument = gql`
  query getEnrollmentPrograms($input: GetEnrollmentProgramsInput!) {
    getEnrollmentPrograms(input: $input) {
      data {
        ...enrollmentProgramFields
      }
      pageNumber
      pageSize
      totalRecords
    }
  }
  ${EnrollmentProgramFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentProgramsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentProgramsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEnrollmentProgramsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEnrollmentProgramsQuery, GetEnrollmentProgramsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEnrollmentProgramsQuery, GetEnrollmentProgramsQueryVariables>(
    GetEnrollmentProgramsDocument,
    options
  );
}
export function useGetEnrollmentProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEnrollmentProgramsQuery, GetEnrollmentProgramsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEnrollmentProgramsQuery, GetEnrollmentProgramsQueryVariables>(
    GetEnrollmentProgramsDocument,
    options
  );
}
export type GetEnrollmentProgramsQueryHookResult = ReturnType<typeof useGetEnrollmentProgramsQuery>;
export type GetEnrollmentProgramsLazyQueryHookResult = ReturnType<typeof useGetEnrollmentProgramsLazyQuery>;
export type GetEnrollmentProgramsQueryResult = Apollo.QueryResult<
  GetEnrollmentProgramsQuery,
  GetEnrollmentProgramsQueryVariables
>;
export const GetApplicationSettingDocument = gql`
  query getApplicationSetting($input: GetApplicationSettingInput!) {
    getApplicationSetting(input: $input) {
      offersExpireInDays
      flowType
    }
  }
`;

/**
 * __useGetApplicationSettingQuery__
 *
 * To run a query within a React component, call `useGetApplicationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationSettingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetApplicationSettingQuery(
  baseOptions: Apollo.QueryHookOptions<GetApplicationSettingQuery, GetApplicationSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApplicationSettingQuery, GetApplicationSettingQueryVariables>(
    GetApplicationSettingDocument,
    options
  );
}
export function useGetApplicationSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationSettingQuery, GetApplicationSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApplicationSettingQuery, GetApplicationSettingQueryVariables>(
    GetApplicationSettingDocument,
    options
  );
}
export type GetApplicationSettingQueryHookResult = ReturnType<typeof useGetApplicationSettingQuery>;
export type GetApplicationSettingLazyQueryHookResult = ReturnType<typeof useGetApplicationSettingLazyQuery>;
export type GetApplicationSettingQueryResult = Apollo.QueryResult<
  GetApplicationSettingQuery,
  GetApplicationSettingQueryVariables
>;
export const GetCustomFieldByIdDocument = gql`
  query getCustomFieldById($businessId: ID!, $customFieldId: ID!) {
    getCustomFieldById(businessId: $businessId, id: $customFieldId) {
      id
      label
      attributesAsString
      type
      centerIds
      archivedAt
      archivedBy
    }
  }
`;

/**
 * __useGetCustomFieldByIdQuery__
 *
 * To run a query within a React component, call `useGetCustomFieldByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFieldByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFieldByIdQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      customFieldId: // value for 'customFieldId'
 *   },
 * });
 */
export function useGetCustomFieldByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomFieldByIdQuery, GetCustomFieldByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomFieldByIdQuery, GetCustomFieldByIdQueryVariables>(
    GetCustomFieldByIdDocument,
    options
  );
}
export function useGetCustomFieldByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFieldByIdQuery, GetCustomFieldByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomFieldByIdQuery, GetCustomFieldByIdQueryVariables>(
    GetCustomFieldByIdDocument,
    options
  );
}
export type GetCustomFieldByIdQueryHookResult = ReturnType<typeof useGetCustomFieldByIdQuery>;
export type GetCustomFieldByIdLazyQueryHookResult = ReturnType<typeof useGetCustomFieldByIdLazyQuery>;
export type GetCustomFieldByIdQueryResult = Apollo.QueryResult<
  GetCustomFieldByIdQuery,
  GetCustomFieldByIdQueryVariables
>;
export const GetAllActiveProgramsByCenterIdDocument = gql`
  query getAllActiveProgramsByCenterId($businessId: ID!, $centerId: ID!) {
    getEnrollmentPrograms(
      input: {
        businessId: $businessId
        centerIds: [$centerId]
        pagination: { pageSize: 10000, pageNumber: 1 }
        sort: [{ field: "name", direction: ASCENDING }]
        activeOnly: true
      }
    ) {
      pageNumber
      pageSize
      totalRecords
      data {
        id
        name
        minEnrolmentDays
        maxEnrolmentDays
        operatingDays
        isOpenForEnrollment
        startDate
        endDate
        programGroupId
        programCenters {
          centerId
          feeName
          feeId
          className
          classId
          casualFeeId
          enrollmentOptions
          casualFeeName
        }
      }
    }
  }
`;

/**
 * __useGetAllActiveProgramsByCenterIdQuery__
 *
 * To run a query within a React component, call `useGetAllActiveProgramsByCenterIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllActiveProgramsByCenterIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllActiveProgramsByCenterIdQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      centerId: // value for 'centerId'
 *   },
 * });
 */
export function useGetAllActiveProgramsByCenterIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllActiveProgramsByCenterIdQuery,
    GetAllActiveProgramsByCenterIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllActiveProgramsByCenterIdQuery, GetAllActiveProgramsByCenterIdQueryVariables>(
    GetAllActiveProgramsByCenterIdDocument,
    options
  );
}
export function useGetAllActiveProgramsByCenterIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllActiveProgramsByCenterIdQuery,
    GetAllActiveProgramsByCenterIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllActiveProgramsByCenterIdQuery, GetAllActiveProgramsByCenterIdQueryVariables>(
    GetAllActiveProgramsByCenterIdDocument,
    options
  );
}
export type GetAllActiveProgramsByCenterIdQueryHookResult = ReturnType<typeof useGetAllActiveProgramsByCenterIdQuery>;
export type GetAllActiveProgramsByCenterIdLazyQueryHookResult = ReturnType<
  typeof useGetAllActiveProgramsByCenterIdLazyQuery
>;
export type GetAllActiveProgramsByCenterIdQueryResult = Apollo.QueryResult<
  GetAllActiveProgramsByCenterIdQuery,
  GetAllActiveProgramsByCenterIdQueryVariables
>;
export const GetCentreIdsHasFormAttachedDocument = gql`
  query getCentreIdsHasFormAttached($businessId: ID!) {
    getCentreIdsHasFormAttached(businessId: $businessId) {
      allCentreAssigned
      centreIdsWithForm
    }
  }
`;

/**
 * __useGetCentreIdsHasFormAttachedQuery__
 *
 * To run a query within a React component, call `useGetCentreIdsHasFormAttachedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCentreIdsHasFormAttachedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCentreIdsHasFormAttachedQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetCentreIdsHasFormAttachedQuery(
  baseOptions: Apollo.QueryHookOptions<GetCentreIdsHasFormAttachedQuery, GetCentreIdsHasFormAttachedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCentreIdsHasFormAttachedQuery, GetCentreIdsHasFormAttachedQueryVariables>(
    GetCentreIdsHasFormAttachedDocument,
    options
  );
}
export function useGetCentreIdsHasFormAttachedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCentreIdsHasFormAttachedQuery, GetCentreIdsHasFormAttachedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCentreIdsHasFormAttachedQuery, GetCentreIdsHasFormAttachedQueryVariables>(
    GetCentreIdsHasFormAttachedDocument,
    options
  );
}
export type GetCentreIdsHasFormAttachedQueryHookResult = ReturnType<typeof useGetCentreIdsHasFormAttachedQuery>;
export type GetCentreIdsHasFormAttachedLazyQueryHookResult = ReturnType<typeof useGetCentreIdsHasFormAttachedLazyQuery>;
export type GetCentreIdsHasFormAttachedQueryResult = Apollo.QueryResult<
  GetCentreIdsHasFormAttachedQuery,
  GetCentreIdsHasFormAttachedQueryVariables
>;
export const CreateEnrollmentProgramGroupDocument = gql`
  mutation createEnrollmentProgramGroup($input: CreateEnrollmentProgramGroupInput!) {
    createEnrollmentProgramGroup(input: $input) {
      id
      name
      description
      isActive
    }
  }
`;
export type CreateEnrollmentProgramGroupMutationFn = Apollo.MutationFunction<
  CreateEnrollmentProgramGroupMutation,
  CreateEnrollmentProgramGroupMutationVariables
>;

/**
 * __useCreateEnrollmentProgramGroupMutation__
 *
 * To run a mutation, you first call `useCreateEnrollmentProgramGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnrollmentProgramGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnrollmentProgramGroupMutation, { data, loading, error }] = useCreateEnrollmentProgramGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnrollmentProgramGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEnrollmentProgramGroupMutation,
    CreateEnrollmentProgramGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEnrollmentProgramGroupMutation, CreateEnrollmentProgramGroupMutationVariables>(
    CreateEnrollmentProgramGroupDocument,
    options
  );
}
export type CreateEnrollmentProgramGroupMutationHookResult = ReturnType<typeof useCreateEnrollmentProgramGroupMutation>;
export type CreateEnrollmentProgramGroupMutationResult = Apollo.MutationResult<CreateEnrollmentProgramGroupMutation>;
export type CreateEnrollmentProgramGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateEnrollmentProgramGroupMutation,
  CreateEnrollmentProgramGroupMutationVariables
>;
export const UpdateEnrollmentProgramGroupDocument = gql`
  mutation updateEnrollmentProgramGroup($input: UpdateEnrollmentProgramGroupInput!) {
    updateEnrollmentProgramGroup(input: $input) {
      id
      name
      description
      isActive
    }
  }
`;
export type UpdateEnrollmentProgramGroupMutationFn = Apollo.MutationFunction<
  UpdateEnrollmentProgramGroupMutation,
  UpdateEnrollmentProgramGroupMutationVariables
>;

/**
 * __useUpdateEnrollmentProgramGroupMutation__
 *
 * To run a mutation, you first call `useUpdateEnrollmentProgramGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnrollmentProgramGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnrollmentProgramGroupMutation, { data, loading, error }] = useUpdateEnrollmentProgramGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnrollmentProgramGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEnrollmentProgramGroupMutation,
    UpdateEnrollmentProgramGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEnrollmentProgramGroupMutation, UpdateEnrollmentProgramGroupMutationVariables>(
    UpdateEnrollmentProgramGroupDocument,
    options
  );
}
export type UpdateEnrollmentProgramGroupMutationHookResult = ReturnType<typeof useUpdateEnrollmentProgramGroupMutation>;
export type UpdateEnrollmentProgramGroupMutationResult = Apollo.MutationResult<UpdateEnrollmentProgramGroupMutation>;
export type UpdateEnrollmentProgramGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateEnrollmentProgramGroupMutation,
  UpdateEnrollmentProgramGroupMutationVariables
>;
export const DeleteEnrollmentProgramGroupDocument = gql`
  mutation deleteEnrollmentProgramGroup($businessId: ID!, $programGroupId: ID!) {
    deleteEnrollmentProgramGroup(businessId: $businessId, programGroupId: $programGroupId)
  }
`;
export type DeleteEnrollmentProgramGroupMutationFn = Apollo.MutationFunction<
  DeleteEnrollmentProgramGroupMutation,
  DeleteEnrollmentProgramGroupMutationVariables
>;

/**
 * __useDeleteEnrollmentProgramGroupMutation__
 *
 * To run a mutation, you first call `useDeleteEnrollmentProgramGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnrollmentProgramGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnrollmentProgramGroupMutation, { data, loading, error }] = useDeleteEnrollmentProgramGroupMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      programGroupId: // value for 'programGroupId'
 *   },
 * });
 */
export function useDeleteEnrollmentProgramGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEnrollmentProgramGroupMutation,
    DeleteEnrollmentProgramGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEnrollmentProgramGroupMutation, DeleteEnrollmentProgramGroupMutationVariables>(
    DeleteEnrollmentProgramGroupDocument,
    options
  );
}
export type DeleteEnrollmentProgramGroupMutationHookResult = ReturnType<typeof useDeleteEnrollmentProgramGroupMutation>;
export type DeleteEnrollmentProgramGroupMutationResult = Apollo.MutationResult<DeleteEnrollmentProgramGroupMutation>;
export type DeleteEnrollmentProgramGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteEnrollmentProgramGroupMutation,
  DeleteEnrollmentProgramGroupMutationVariables
>;
export const GetEnrollmentProgramGroupsDocument = gql`
  query getEnrollmentProgramGroups($input: GetEnrollmentProgramGroupsInput!) {
    getEnrollmentProgramGroups(input: $input) {
      totalRecords
      data {
        id
        name
        businessId
        description
        programIds
        isActive
        endDt
        startDt
        programIds
        programs {
          id
          name
          startDate
        }
      }
    }
  }
`;

/**
 * __useGetEnrollmentProgramGroupsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentProgramGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentProgramGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentProgramGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEnrollmentProgramGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEnrollmentProgramGroupsQuery, GetEnrollmentProgramGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEnrollmentProgramGroupsQuery, GetEnrollmentProgramGroupsQueryVariables>(
    GetEnrollmentProgramGroupsDocument,
    options
  );
}
export function useGetEnrollmentProgramGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEnrollmentProgramGroupsQuery, GetEnrollmentProgramGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEnrollmentProgramGroupsQuery, GetEnrollmentProgramGroupsQueryVariables>(
    GetEnrollmentProgramGroupsDocument,
    options
  );
}
export type GetEnrollmentProgramGroupsQueryHookResult = ReturnType<typeof useGetEnrollmentProgramGroupsQuery>;
export type GetEnrollmentProgramGroupsLazyQueryHookResult = ReturnType<typeof useGetEnrollmentProgramGroupsLazyQuery>;
export type GetEnrollmentProgramGroupsQueryResult = Apollo.QueryResult<
  GetEnrollmentProgramGroupsQuery,
  GetEnrollmentProgramGroupsQueryVariables
>;
export const UpsertCcrmCredentialsDocument = gql`
  mutation upsertCcrmCredentials($input: UpsertCcrmCredentialsInput!) {
    upsertCcrmCredentials(input: $input) {
      integrationType
      businessId
      key
      foreignTenantId
    }
  }
`;
export type UpsertCcrmCredentialsMutationFn = Apollo.MutationFunction<
  UpsertCcrmCredentialsMutation,
  UpsertCcrmCredentialsMutationVariables
>;

/**
 * __useUpsertCcrmCredentialsMutation__
 *
 * To run a mutation, you first call `useUpsertCcrmCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCcrmCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCcrmCredentialsMutation, { data, loading, error }] = useUpsertCcrmCredentialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCcrmCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertCcrmCredentialsMutation, UpsertCcrmCredentialsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertCcrmCredentialsMutation, UpsertCcrmCredentialsMutationVariables>(
    UpsertCcrmCredentialsDocument,
    options
  );
}
export type UpsertCcrmCredentialsMutationHookResult = ReturnType<typeof useUpsertCcrmCredentialsMutation>;
export type UpsertCcrmCredentialsMutationResult = Apollo.MutationResult<UpsertCcrmCredentialsMutation>;
export type UpsertCcrmCredentialsMutationOptions = Apollo.BaseMutationOptions<
  UpsertCcrmCredentialsMutation,
  UpsertCcrmCredentialsMutationVariables
>;
export const UpsertIntegrationMappingsDocument = gql`
  mutation upsertIntegrationMappings($input: UpsertIntegrationMappingsInput!) {
    upsertIntegrationMappings(input: $input) {
      internalId
      externalId
    }
  }
`;
export type UpsertIntegrationMappingsMutationFn = Apollo.MutationFunction<
  UpsertIntegrationMappingsMutation,
  UpsertIntegrationMappingsMutationVariables
>;

/**
 * __useUpsertIntegrationMappingsMutation__
 *
 * To run a mutation, you first call `useUpsertIntegrationMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertIntegrationMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertIntegrationMappingsMutation, { data, loading, error }] = useUpsertIntegrationMappingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertIntegrationMappingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertIntegrationMappingsMutation,
    UpsertIntegrationMappingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertIntegrationMappingsMutation, UpsertIntegrationMappingsMutationVariables>(
    UpsertIntegrationMappingsDocument,
    options
  );
}
export type UpsertIntegrationMappingsMutationHookResult = ReturnType<typeof useUpsertIntegrationMappingsMutation>;
export type UpsertIntegrationMappingsMutationResult = Apollo.MutationResult<UpsertIntegrationMappingsMutation>;
export type UpsertIntegrationMappingsMutationOptions = Apollo.BaseMutationOptions<
  UpsertIntegrationMappingsMutation,
  UpsertIntegrationMappingsMutationVariables
>;
export const SelectCcrmMappingDocument = gql`
  mutation selectCcrmMapping($input: ISelectExistingEntityInput!) {
    selectCcrmMapping(input: $input)
  }
`;
export type SelectCcrmMappingMutationFn = Apollo.MutationFunction<
  SelectCcrmMappingMutation,
  SelectCcrmMappingMutationVariables
>;

/**
 * __useSelectCcrmMappingMutation__
 *
 * To run a mutation, you first call `useSelectCcrmMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectCcrmMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectCcrmMappingMutation, { data, loading, error }] = useSelectCcrmMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectCcrmMappingMutation(
  baseOptions?: Apollo.MutationHookOptions<SelectCcrmMappingMutation, SelectCcrmMappingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SelectCcrmMappingMutation, SelectCcrmMappingMutationVariables>(
    SelectCcrmMappingDocument,
    options
  );
}
export type SelectCcrmMappingMutationHookResult = ReturnType<typeof useSelectCcrmMappingMutation>;
export type SelectCcrmMappingMutationResult = Apollo.MutationResult<SelectCcrmMappingMutation>;
export type SelectCcrmMappingMutationOptions = Apollo.BaseMutationOptions<
  SelectCcrmMappingMutation,
  SelectCcrmMappingMutationVariables
>;
export const ImportEzChildTrackFamiliesDocument = gql`
  mutation importEzChildTrackFamilies($input: ImportEzChildTrackFamiliesInput) {
    importEzChildTrackFamilies(input: $input)
  }
`;
export type ImportEzChildTrackFamiliesMutationFn = Apollo.MutationFunction<
  ImportEzChildTrackFamiliesMutation,
  ImportEzChildTrackFamiliesMutationVariables
>;

/**
 * __useImportEzChildTrackFamiliesMutation__
 *
 * To run a mutation, you first call `useImportEzChildTrackFamiliesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportEzChildTrackFamiliesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importEzChildTrackFamiliesMutation, { data, loading, error }] = useImportEzChildTrackFamiliesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportEzChildTrackFamiliesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportEzChildTrackFamiliesMutation,
    ImportEzChildTrackFamiliesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportEzChildTrackFamiliesMutation, ImportEzChildTrackFamiliesMutationVariables>(
    ImportEzChildTrackFamiliesDocument,
    options
  );
}
export type ImportEzChildTrackFamiliesMutationHookResult = ReturnType<typeof useImportEzChildTrackFamiliesMutation>;
export type ImportEzChildTrackFamiliesMutationResult = Apollo.MutationResult<ImportEzChildTrackFamiliesMutation>;
export type ImportEzChildTrackFamiliesMutationOptions = Apollo.BaseMutationOptions<
  ImportEzChildTrackFamiliesMutation,
  ImportEzChildTrackFamiliesMutationVariables
>;
export const ImportEzChildTrackContractsDocument = gql`
  mutation importEzChildTrackContracts($input: ImportEzChildTrackContractsInput) {
    importEzChildTrackContracts(input: $input)
  }
`;
export type ImportEzChildTrackContractsMutationFn = Apollo.MutationFunction<
  ImportEzChildTrackContractsMutation,
  ImportEzChildTrackContractsMutationVariables
>;

/**
 * __useImportEzChildTrackContractsMutation__
 *
 * To run a mutation, you first call `useImportEzChildTrackContractsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportEzChildTrackContractsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importEzChildTrackContractsMutation, { data, loading, error }] = useImportEzChildTrackContractsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportEzChildTrackContractsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportEzChildTrackContractsMutation,
    ImportEzChildTrackContractsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportEzChildTrackContractsMutation, ImportEzChildTrackContractsMutationVariables>(
    ImportEzChildTrackContractsDocument,
    options
  );
}
export type ImportEzChildTrackContractsMutationHookResult = ReturnType<typeof useImportEzChildTrackContractsMutation>;
export type ImportEzChildTrackContractsMutationResult = Apollo.MutationResult<ImportEzChildTrackContractsMutation>;
export type ImportEzChildTrackContractsMutationOptions = Apollo.BaseMutationOptions<
  ImportEzChildTrackContractsMutation,
  ImportEzChildTrackContractsMutationVariables
>;
export const GetExternalTypesDocument = gql`
  query getExternalTypes($input: GetExternalTypesInput!) {
    getExternalTypes(input: $input) {
      id
      name
    }
  }
`;

/**
 * __useGetExternalTypesQuery__
 *
 * To run a query within a React component, call `useGetExternalTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetExternalTypesQuery(
  baseOptions: Apollo.QueryHookOptions<GetExternalTypesQuery, GetExternalTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExternalTypesQuery, GetExternalTypesQueryVariables>(GetExternalTypesDocument, options);
}
export function useGetExternalTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExternalTypesQuery, GetExternalTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExternalTypesQuery, GetExternalTypesQueryVariables>(GetExternalTypesDocument, options);
}
export type GetExternalTypesQueryHookResult = ReturnType<typeof useGetExternalTypesQuery>;
export type GetExternalTypesLazyQueryHookResult = ReturnType<typeof useGetExternalTypesLazyQuery>;
export type GetExternalTypesQueryResult = Apollo.QueryResult<GetExternalTypesQuery, GetExternalTypesQueryVariables>;
export const GetIntegrationMappingsDocument = gql`
  query getIntegrationMappings($input: GetIntegrationMappingsInput!) {
    getIntegrationMappings(input: $input) {
      internalId
      externalId
    }
  }
`;

/**
 * __useGetIntegrationMappingsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationMappingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetIntegrationMappingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetIntegrationMappingsQuery, GetIntegrationMappingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIntegrationMappingsQuery, GetIntegrationMappingsQueryVariables>(
    GetIntegrationMappingsDocument,
    options
  );
}
export function useGetIntegrationMappingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationMappingsQuery, GetIntegrationMappingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIntegrationMappingsQuery, GetIntegrationMappingsQueryVariables>(
    GetIntegrationMappingsDocument,
    options
  );
}
export type GetIntegrationMappingsQueryHookResult = ReturnType<typeof useGetIntegrationMappingsQuery>;
export type GetIntegrationMappingsLazyQueryHookResult = ReturnType<typeof useGetIntegrationMappingsLazyQuery>;
export type GetIntegrationMappingsQueryResult = Apollo.QueryResult<
  GetIntegrationMappingsQuery,
  GetIntegrationMappingsQueryVariables
>;
export const GetCcrmExistingContactMappingDocument = gql`
  query getCcrmExistingContactMapping($input: GetCcrmExistingContactMappingInput!) {
    getCcrmExistingContactMapping(input: $input) {
      mappingRequired
    }
  }
`;

/**
 * __useGetCcrmExistingContactMappingQuery__
 *
 * To run a query within a React component, call `useGetCcrmExistingContactMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCcrmExistingContactMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCcrmExistingContactMappingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCcrmExistingContactMappingQuery(
  baseOptions: Apollo.QueryHookOptions<GetCcrmExistingContactMappingQuery, GetCcrmExistingContactMappingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCcrmExistingContactMappingQuery, GetCcrmExistingContactMappingQueryVariables>(
    GetCcrmExistingContactMappingDocument,
    options
  );
}
export function useGetCcrmExistingContactMappingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCcrmExistingContactMappingQuery,
    GetCcrmExistingContactMappingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCcrmExistingContactMappingQuery, GetCcrmExistingContactMappingQueryVariables>(
    GetCcrmExistingContactMappingDocument,
    options
  );
}
export type GetCcrmExistingContactMappingQueryHookResult = ReturnType<typeof useGetCcrmExistingContactMappingQuery>;
export type GetCcrmExistingContactMappingLazyQueryHookResult = ReturnType<
  typeof useGetCcrmExistingContactMappingLazyQuery
>;
export type GetCcrmExistingContactMappingQueryResult = Apollo.QueryResult<
  GetCcrmExistingContactMappingQuery,
  GetCcrmExistingContactMappingQueryVariables
>;
export const GetCcrmExistingChildMappingDocument = gql`
  query getCcrmExistingChildMapping($input: GetCcrmExistingChildMappingInput!) {
    getCcrmExistingChildMapping(input: $input) {
      mappingRequired
    }
  }
`;

/**
 * __useGetCcrmExistingChildMappingQuery__
 *
 * To run a query within a React component, call `useGetCcrmExistingChildMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCcrmExistingChildMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCcrmExistingChildMappingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCcrmExistingChildMappingQuery(
  baseOptions: Apollo.QueryHookOptions<GetCcrmExistingChildMappingQuery, GetCcrmExistingChildMappingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCcrmExistingChildMappingQuery, GetCcrmExistingChildMappingQueryVariables>(
    GetCcrmExistingChildMappingDocument,
    options
  );
}
export function useGetCcrmExistingChildMappingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCcrmExistingChildMappingQuery, GetCcrmExistingChildMappingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCcrmExistingChildMappingQuery, GetCcrmExistingChildMappingQueryVariables>(
    GetCcrmExistingChildMappingDocument,
    options
  );
}
export type GetCcrmExistingChildMappingQueryHookResult = ReturnType<typeof useGetCcrmExistingChildMappingQuery>;
export type GetCcrmExistingChildMappingLazyQueryHookResult = ReturnType<typeof useGetCcrmExistingChildMappingLazyQuery>;
export type GetCcrmExistingChildMappingQueryResult = Apollo.QueryResult<
  GetCcrmExistingChildMappingQuery,
  GetCcrmExistingChildMappingQueryVariables
>;
export const GetExistingEntitiesMappingByContactDocument = gql`
  query getExistingEntitiesMappingByContact($input: GetCcrmExistingEntityMappingInput!) {
    getExistingEntitiesMapping(input: $input) {
      existingContactMapping {
        id
        businessId
        mappingGroupId
        accountId
        contactId
        ccrmFamilyId
        ccrmContactId
        isGuardian
        ccrmContactFullName
        ccrmPrimaryPhoneNumber
        ccrmEmailAddress
        ccrmChildren
        selected
        ktContactFullName
        ktContactPrimaryPhoneNumber
        ktContactEmailAddress
      }
    }
  }
`;

/**
 * __useGetExistingEntitiesMappingByContactQuery__
 *
 * To run a query within a React component, call `useGetExistingEntitiesMappingByContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingEntitiesMappingByContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingEntitiesMappingByContactQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetExistingEntitiesMappingByContactQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExistingEntitiesMappingByContactQuery,
    GetExistingEntitiesMappingByContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExistingEntitiesMappingByContactQuery, GetExistingEntitiesMappingByContactQueryVariables>(
    GetExistingEntitiesMappingByContactDocument,
    options
  );
}
export function useGetExistingEntitiesMappingByContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExistingEntitiesMappingByContactQuery,
    GetExistingEntitiesMappingByContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExistingEntitiesMappingByContactQuery,
    GetExistingEntitiesMappingByContactQueryVariables
  >(GetExistingEntitiesMappingByContactDocument, options);
}
export type GetExistingEntitiesMappingByContactQueryHookResult = ReturnType<
  typeof useGetExistingEntitiesMappingByContactQuery
>;
export type GetExistingEntitiesMappingByContactLazyQueryHookResult = ReturnType<
  typeof useGetExistingEntitiesMappingByContactLazyQuery
>;
export type GetExistingEntitiesMappingByContactQueryResult = Apollo.QueryResult<
  GetExistingEntitiesMappingByContactQuery,
  GetExistingEntitiesMappingByContactQueryVariables
>;
export const GetExistingEntitiesMappingByChildDocument = gql`
  query getExistingEntitiesMappingByChild($input: GetCcrmExistingEntityMappingInput!) {
    getExistingEntitiesMapping(input: $input) {
      existingChildrenMapping {
        id
        businessId
        mappingGroupId
        accountId
        childId
        ccrmFamilyId
        ccrmChildId
        ccrmChildFullName
        ccrmChildDateOfBirth
        ccrmGuardianFullName
        selected
        ktChildFullName
        ktChildDateOfBirth
        ktChildAccountName
        ktPrimaryContactName
      }
    }
  }
`;

/**
 * __useGetExistingEntitiesMappingByChildQuery__
 *
 * To run a query within a React component, call `useGetExistingEntitiesMappingByChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingEntitiesMappingByChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingEntitiesMappingByChildQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetExistingEntitiesMappingByChildQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExistingEntitiesMappingByChildQuery,
    GetExistingEntitiesMappingByChildQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExistingEntitiesMappingByChildQuery, GetExistingEntitiesMappingByChildQueryVariables>(
    GetExistingEntitiesMappingByChildDocument,
    options
  );
}
export function useGetExistingEntitiesMappingByChildLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExistingEntitiesMappingByChildQuery,
    GetExistingEntitiesMappingByChildQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExistingEntitiesMappingByChildQuery, GetExistingEntitiesMappingByChildQueryVariables>(
    GetExistingEntitiesMappingByChildDocument,
    options
  );
}
export type GetExistingEntitiesMappingByChildQueryHookResult = ReturnType<
  typeof useGetExistingEntitiesMappingByChildQuery
>;
export type GetExistingEntitiesMappingByChildLazyQueryHookResult = ReturnType<
  typeof useGetExistingEntitiesMappingByChildLazyQuery
>;
export type GetExistingEntitiesMappingByChildQueryResult = Apollo.QueryResult<
  GetExistingEntitiesMappingByChildQuery,
  GetExistingEntitiesMappingByChildQueryVariables
>;
export const GetCcrmCredentialsDocument = gql`
  query getCcrmCredentials($input: GetCcrmCredentialsInput!) {
    getCcrmCredentials(input: $input) {
      username
    }
  }
`;

/**
 * __useGetCcrmCredentialsQuery__
 *
 * To run a query within a React component, call `useGetCcrmCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCcrmCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCcrmCredentialsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCcrmCredentialsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCcrmCredentialsQuery, GetCcrmCredentialsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCcrmCredentialsQuery, GetCcrmCredentialsQueryVariables>(
    GetCcrmCredentialsDocument,
    options
  );
}
export function useGetCcrmCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCcrmCredentialsQuery, GetCcrmCredentialsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCcrmCredentialsQuery, GetCcrmCredentialsQueryVariables>(
    GetCcrmCredentialsDocument,
    options
  );
}
export type GetCcrmCredentialsQueryHookResult = ReturnType<typeof useGetCcrmCredentialsQuery>;
export type GetCcrmCredentialsLazyQueryHookResult = ReturnType<typeof useGetCcrmCredentialsLazyQuery>;
export type GetCcrmCredentialsQueryResult = Apollo.QueryResult<
  GetCcrmCredentialsQuery,
  GetCcrmCredentialsQueryVariables
>;
export const CheckCurrentCcrmCredentialsDocument = gql`
  query checkCurrentCcrmCredentials($input: CheckCurrentCcrmCredentialsInput!) {
    checkCurrentCcrmCredentials(input: $input)
  }
`;

/**
 * __useCheckCurrentCcrmCredentialsQuery__
 *
 * To run a query within a React component, call `useCheckCurrentCcrmCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCurrentCcrmCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCurrentCcrmCredentialsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckCurrentCcrmCredentialsQuery(
  baseOptions: Apollo.QueryHookOptions<CheckCurrentCcrmCredentialsQuery, CheckCurrentCcrmCredentialsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckCurrentCcrmCredentialsQuery, CheckCurrentCcrmCredentialsQueryVariables>(
    CheckCurrentCcrmCredentialsDocument,
    options
  );
}
export function useCheckCurrentCcrmCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckCurrentCcrmCredentialsQuery, CheckCurrentCcrmCredentialsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckCurrentCcrmCredentialsQuery, CheckCurrentCcrmCredentialsQueryVariables>(
    CheckCurrentCcrmCredentialsDocument,
    options
  );
}
export type CheckCurrentCcrmCredentialsQueryHookResult = ReturnType<typeof useCheckCurrentCcrmCredentialsQuery>;
export type CheckCurrentCcrmCredentialsLazyQueryHookResult = ReturnType<typeof useCheckCurrentCcrmCredentialsLazyQuery>;
export type CheckCurrentCcrmCredentialsQueryResult = Apollo.QueryResult<
  CheckCurrentCcrmCredentialsQuery,
  CheckCurrentCcrmCredentialsQueryVariables
>;
export const CreateEditTransitionStatementDocument = gql`
  mutation createEditTransitionStatement($input: CreateEditTransitionStatementInput!) {
    createEditTransitionStatement(input: $input) {
      ...TransitionStatement
    }
  }
  ${TransitionStatementFragmentDoc}
`;
export type CreateEditTransitionStatementMutationFn = Apollo.MutationFunction<
  CreateEditTransitionStatementMutation,
  CreateEditTransitionStatementMutationVariables
>;

/**
 * __useCreateEditTransitionStatementMutation__
 *
 * To run a mutation, you first call `useCreateEditTransitionStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditTransitionStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditTransitionStatementMutation, { data, loading, error }] = useCreateEditTransitionStatementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditTransitionStatementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEditTransitionStatementMutation,
    CreateEditTransitionStatementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEditTransitionStatementMutation, CreateEditTransitionStatementMutationVariables>(
    CreateEditTransitionStatementDocument,
    options
  );
}
export type CreateEditTransitionStatementMutationHookResult = ReturnType<
  typeof useCreateEditTransitionStatementMutation
>;
export type CreateEditTransitionStatementMutationResult = Apollo.MutationResult<CreateEditTransitionStatementMutation>;
export type CreateEditTransitionStatementMutationOptions = Apollo.BaseMutationOptions<
  CreateEditTransitionStatementMutation,
  CreateEditTransitionStatementMutationVariables
>;
export const GetFreeKindyCenterSummariesDocument = gql`
  query getFreeKindyCenterSummaries($input: GetPagedKindyForAllCenterSummariesInput!) {
    getFreeKindyCenterSummaries(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        ...FreeKindyCenterSummary
      }
    }
  }
  ${FreeKindyCenterSummaryFragmentDoc}
`;

/**
 * __useGetFreeKindyCenterSummariesQuery__
 *
 * To run a query within a React component, call `useGetFreeKindyCenterSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreeKindyCenterSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreeKindyCenterSummariesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFreeKindyCenterSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<GetFreeKindyCenterSummariesQuery, GetFreeKindyCenterSummariesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFreeKindyCenterSummariesQuery, GetFreeKindyCenterSummariesQueryVariables>(
    GetFreeKindyCenterSummariesDocument,
    options
  );
}
export function useGetFreeKindyCenterSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFreeKindyCenterSummariesQuery, GetFreeKindyCenterSummariesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFreeKindyCenterSummariesQuery, GetFreeKindyCenterSummariesQueryVariables>(
    GetFreeKindyCenterSummariesDocument,
    options
  );
}
export type GetFreeKindyCenterSummariesQueryHookResult = ReturnType<typeof useGetFreeKindyCenterSummariesQuery>;
export type GetFreeKindyCenterSummariesLazyQueryHookResult = ReturnType<typeof useGetFreeKindyCenterSummariesLazyQuery>;
export type GetFreeKindyCenterSummariesQueryResult = Apollo.QueryResult<
  GetFreeKindyCenterSummariesQuery,
  GetFreeKindyCenterSummariesQueryVariables
>;
export const GetFreeKindyChildSummariesDocument = gql`
  query getFreeKindyChildSummaries($input: GetPagedKindyForAllChildSummariesInput!) {
    getFreeKindyChildSummaries(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        ...FreeKindyChildSummary
      }
    }
  }
  ${FreeKindyChildSummaryFragmentDoc}
`;

/**
 * __useGetFreeKindyChildSummariesQuery__
 *
 * To run a query within a React component, call `useGetFreeKindyChildSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreeKindyChildSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreeKindyChildSummariesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFreeKindyChildSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<GetFreeKindyChildSummariesQuery, GetFreeKindyChildSummariesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFreeKindyChildSummariesQuery, GetFreeKindyChildSummariesQueryVariables>(
    GetFreeKindyChildSummariesDocument,
    options
  );
}
export function useGetFreeKindyChildSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFreeKindyChildSummariesQuery, GetFreeKindyChildSummariesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFreeKindyChildSummariesQuery, GetFreeKindyChildSummariesQueryVariables>(
    GetFreeKindyChildSummariesDocument,
    options
  );
}
export type GetFreeKindyChildSummariesQueryHookResult = ReturnType<typeof useGetFreeKindyChildSummariesQuery>;
export type GetFreeKindyChildSummariesLazyQueryHookResult = ReturnType<typeof useGetFreeKindyChildSummariesLazyQuery>;
export type GetFreeKindyChildSummariesQueryResult = Apollo.QueryResult<
  GetFreeKindyChildSummariesQuery,
  GetFreeKindyChildSummariesQueryVariables
>;
export const GetFreeKindyAcquittalCenterSummariesDocument = gql`
  query getFreeKindyAcquittalCenterSummaries($input: GetPagedKindyForAllCenterSummariesInput!) {
    getFreeKindyAcquittalCenterSummaries(input: $input) {
      totalRecords
      pageNumber
      pageSize
      data {
        centerId
        centerName
        period
        year
        childCount
        totalPayment
        totalOwed
        baseSubsidyPrior
        baseSubsidyActual
        baseSubsidyAcquittal
        inclusionReadyPrior
        inclusionReadyActual
        inclusionReadyAcquittal
        serviceLocationPrior
        serviceLocationActual
        serviceLocationAcquittal
        freeKindyPrior
        freeKindyActual
        freeKindyAcquittal
        paidToFamiliesAmount
        status
        messages {
          code
          message
          target
          innerError
        }
      }
    }
  }
`;

/**
 * __useGetFreeKindyAcquittalCenterSummariesQuery__
 *
 * To run a query within a React component, call `useGetFreeKindyAcquittalCenterSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreeKindyAcquittalCenterSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreeKindyAcquittalCenterSummariesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFreeKindyAcquittalCenterSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFreeKindyAcquittalCenterSummariesQuery,
    GetFreeKindyAcquittalCenterSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFreeKindyAcquittalCenterSummariesQuery, GetFreeKindyAcquittalCenterSummariesQueryVariables>(
    GetFreeKindyAcquittalCenterSummariesDocument,
    options
  );
}
export function useGetFreeKindyAcquittalCenterSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFreeKindyAcquittalCenterSummariesQuery,
    GetFreeKindyAcquittalCenterSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFreeKindyAcquittalCenterSummariesQuery,
    GetFreeKindyAcquittalCenterSummariesQueryVariables
  >(GetFreeKindyAcquittalCenterSummariesDocument, options);
}
export type GetFreeKindyAcquittalCenterSummariesQueryHookResult = ReturnType<
  typeof useGetFreeKindyAcquittalCenterSummariesQuery
>;
export type GetFreeKindyAcquittalCenterSummariesLazyQueryHookResult = ReturnType<
  typeof useGetFreeKindyAcquittalCenterSummariesLazyQuery
>;
export type GetFreeKindyAcquittalCenterSummariesQueryResult = Apollo.QueryResult<
  GetFreeKindyAcquittalCenterSummariesQuery,
  GetFreeKindyAcquittalCenterSummariesQueryVariables
>;
export const GetFreeKindyAcquittalChildSummariesDocument = gql`
  query getFreeKindyAcquittalChildSummaries($input: GetPagedKindyForAllChildSummariesInput!) {
    getFreeKindyAcquittalChildSummaries(input: $input) {
      totalRecords
      pageNumber
      pageSize
      data {
        agreementId
        centerId
        centerName
        childId
        childName
        year
        period
        forecastedAmount
        actualAmount
        acquittedAmount
        paidToFamiliesAmount
        errors
        status
      }
    }
  }
`;

/**
 * __useGetFreeKindyAcquittalChildSummariesQuery__
 *
 * To run a query within a React component, call `useGetFreeKindyAcquittalChildSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreeKindyAcquittalChildSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreeKindyAcquittalChildSummariesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFreeKindyAcquittalChildSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFreeKindyAcquittalChildSummariesQuery,
    GetFreeKindyAcquittalChildSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFreeKindyAcquittalChildSummariesQuery, GetFreeKindyAcquittalChildSummariesQueryVariables>(
    GetFreeKindyAcquittalChildSummariesDocument,
    options
  );
}
export function useGetFreeKindyAcquittalChildSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFreeKindyAcquittalChildSummariesQuery,
    GetFreeKindyAcquittalChildSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFreeKindyAcquittalChildSummariesQuery,
    GetFreeKindyAcquittalChildSummariesQueryVariables
  >(GetFreeKindyAcquittalChildSummariesDocument, options);
}
export type GetFreeKindyAcquittalChildSummariesQueryHookResult = ReturnType<
  typeof useGetFreeKindyAcquittalChildSummariesQuery
>;
export type GetFreeKindyAcquittalChildSummariesLazyQueryHookResult = ReturnType<
  typeof useGetFreeKindyAcquittalChildSummariesLazyQuery
>;
export type GetFreeKindyAcquittalChildSummariesQueryResult = Apollo.QueryResult<
  GetFreeKindyAcquittalChildSummariesQuery,
  GetFreeKindyAcquittalChildSummariesQueryVariables
>;
export const GetStartStrongReportDocument = gql`
  query getStartStrongReport($input: GetStartStrongReportInput!) {
    getStartStrongReport(input: $input) {
      csv
      errors
    }
  }
`;

/**
 * __useGetStartStrongReportQuery__
 *
 * To run a query within a React component, call `useGetStartStrongReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStartStrongReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStartStrongReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStartStrongReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetStartStrongReportQuery, GetStartStrongReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStartStrongReportQuery, GetStartStrongReportQueryVariables>(
    GetStartStrongReportDocument,
    options
  );
}
export function useGetStartStrongReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStartStrongReportQuery, GetStartStrongReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStartStrongReportQuery, GetStartStrongReportQueryVariables>(
    GetStartStrongReportDocument,
    options
  );
}
export type GetStartStrongReportQueryHookResult = ReturnType<typeof useGetStartStrongReportQuery>;
export type GetStartStrongReportLazyQueryHookResult = ReturnType<typeof useGetStartStrongReportLazyQuery>;
export type GetStartStrongReportQueryResult = Apollo.QueryResult<
  GetStartStrongReportQuery,
  GetStartStrongReportQueryVariables
>;
export const GetTransitionStatementsDocument = gql`
  query getTransitionStatements($input: GetTransitionStatementsInput!) {
    getTransitionStatements(input: $input) {
      ...TransitionStatement
    }
  }
  ${TransitionStatementFragmentDoc}
`;

/**
 * __useGetTransitionStatementsQuery__
 *
 * To run a query within a React component, call `useGetTransitionStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransitionStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransitionStatementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTransitionStatementsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTransitionStatementsQuery, GetTransitionStatementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransitionStatementsQuery, GetTransitionStatementsQueryVariables>(
    GetTransitionStatementsDocument,
    options
  );
}
export function useGetTransitionStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransitionStatementsQuery, GetTransitionStatementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransitionStatementsQuery, GetTransitionStatementsQueryVariables>(
    GetTransitionStatementsDocument,
    options
  );
}
export type GetTransitionStatementsQueryHookResult = ReturnType<typeof useGetTransitionStatementsQuery>;
export type GetTransitionStatementsLazyQueryHookResult = ReturnType<typeof useGetTransitionStatementsLazyQuery>;
export type GetTransitionStatementsQueryResult = Apollo.QueryResult<
  GetTransitionStatementsQuery,
  GetTransitionStatementsQueryVariables
>;
export const GetDirectDebitPaymentMethodDocument = gql`
  query getDirectDebitPaymentMethod($id: ID!) {
    getDirectDebitPaymentMethod(id: $id) {
      accountName
      accountNumber
      bsb
    }
  }
`;

/**
 * __useGetDirectDebitPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetDirectDebitPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectDebitPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectDebitPaymentMethodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDirectDebitPaymentMethodQuery(
  baseOptions: Apollo.QueryHookOptions<GetDirectDebitPaymentMethodQuery, GetDirectDebitPaymentMethodQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDirectDebitPaymentMethodQuery, GetDirectDebitPaymentMethodQueryVariables>(
    GetDirectDebitPaymentMethodDocument,
    options
  );
}
export function useGetDirectDebitPaymentMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDirectDebitPaymentMethodQuery, GetDirectDebitPaymentMethodQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDirectDebitPaymentMethodQuery, GetDirectDebitPaymentMethodQueryVariables>(
    GetDirectDebitPaymentMethodDocument,
    options
  );
}
export type GetDirectDebitPaymentMethodQueryHookResult = ReturnType<typeof useGetDirectDebitPaymentMethodQuery>;
export type GetDirectDebitPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetDirectDebitPaymentMethodLazyQuery>;
export type GetDirectDebitPaymentMethodQueryResult = Apollo.QueryResult<
  GetDirectDebitPaymentMethodQuery,
  GetDirectDebitPaymentMethodQueryVariables
>;
export const SendReEnrollmentDocument = gql`
  mutation sendReEnrollment($input: ReenrollmentSendRequestInput!) {
    sendReEnrollments(input: $input)
  }
`;
export type SendReEnrollmentMutationFn = Apollo.MutationFunction<
  SendReEnrollmentMutation,
  SendReEnrollmentMutationVariables
>;

/**
 * __useSendReEnrollmentMutation__
 *
 * To run a mutation, you first call `useSendReEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReEnrollmentMutation, { data, loading, error }] = useSendReEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendReEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<SendReEnrollmentMutation, SendReEnrollmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendReEnrollmentMutation, SendReEnrollmentMutationVariables>(
    SendReEnrollmentDocument,
    options
  );
}
export type SendReEnrollmentMutationHookResult = ReturnType<typeof useSendReEnrollmentMutation>;
export type SendReEnrollmentMutationResult = Apollo.MutationResult<SendReEnrollmentMutation>;
export type SendReEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  SendReEnrollmentMutation,
  SendReEnrollmentMutationVariables
>;
export const CloseReEnrollmentsDocument = gql`
  mutation closeReEnrollments($businessId: ID!, $input: ReenrollmentCloseRequestInput!) {
    closeReEnrollments(businessId: $businessId, input: $input) {
      totalRequestsClosed
    }
  }
`;
export type CloseReEnrollmentsMutationFn = Apollo.MutationFunction<
  CloseReEnrollmentsMutation,
  CloseReEnrollmentsMutationVariables
>;

/**
 * __useCloseReEnrollmentsMutation__
 *
 * To run a mutation, you first call `useCloseReEnrollmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseReEnrollmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeReEnrollmentsMutation, { data, loading, error }] = useCloseReEnrollmentsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseReEnrollmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<CloseReEnrollmentsMutation, CloseReEnrollmentsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseReEnrollmentsMutation, CloseReEnrollmentsMutationVariables>(
    CloseReEnrollmentsDocument,
    options
  );
}
export type CloseReEnrollmentsMutationHookResult = ReturnType<typeof useCloseReEnrollmentsMutation>;
export type CloseReEnrollmentsMutationResult = Apollo.MutationResult<CloseReEnrollmentsMutation>;
export type CloseReEnrollmentsMutationOptions = Apollo.BaseMutationOptions<
  CloseReEnrollmentsMutation,
  CloseReEnrollmentsMutationVariables
>;
export const SetReEnrolBusinessSettingsDocument = gql`
  mutation setReEnrolBusinessSettings($input: SetReEnrolBusinessSettingsInput!) {
    setReEnrolBusinessSettings(input: $input) {
      entityId
      usesReEnrol
      usesParentApplication
      usesEnrolmentForm
      centerCount
    }
  }
`;
export type SetReEnrolBusinessSettingsMutationFn = Apollo.MutationFunction<
  SetReEnrolBusinessSettingsMutation,
  SetReEnrolBusinessSettingsMutationVariables
>;

/**
 * __useSetReEnrolBusinessSettingsMutation__
 *
 * To run a mutation, you first call `useSetReEnrolBusinessSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReEnrolBusinessSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReEnrolBusinessSettingsMutation, { data, loading, error }] = useSetReEnrolBusinessSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetReEnrolBusinessSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetReEnrolBusinessSettingsMutation,
    SetReEnrolBusinessSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetReEnrolBusinessSettingsMutation, SetReEnrolBusinessSettingsMutationVariables>(
    SetReEnrolBusinessSettingsDocument,
    options
  );
}
export type SetReEnrolBusinessSettingsMutationHookResult = ReturnType<typeof useSetReEnrolBusinessSettingsMutation>;
export type SetReEnrolBusinessSettingsMutationResult = Apollo.MutationResult<SetReEnrolBusinessSettingsMutation>;
export type SetReEnrolBusinessSettingsMutationOptions = Apollo.BaseMutationOptions<
  SetReEnrolBusinessSettingsMutation,
  SetReEnrolBusinessSettingsMutationVariables
>;
export const SetReEnrolCenterSettingsDocument = gql`
  mutation setReEnrolCenterSettings($input: SetReEnrolCenterSettingsInput!) {
    setReEnrolCenterSettings(input: $input) {
      entityId
      centerId
      centerName
      usesParentApplication
      usesEnrolmentForm
      fromDefault
      businessUsesReEnrol
    }
  }
`;
export type SetReEnrolCenterSettingsMutationFn = Apollo.MutationFunction<
  SetReEnrolCenterSettingsMutation,
  SetReEnrolCenterSettingsMutationVariables
>;

/**
 * __useSetReEnrolCenterSettingsMutation__
 *
 * To run a mutation, you first call `useSetReEnrolCenterSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReEnrolCenterSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReEnrolCenterSettingsMutation, { data, loading, error }] = useSetReEnrolCenterSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetReEnrolCenterSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<SetReEnrolCenterSettingsMutation, SetReEnrolCenterSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetReEnrolCenterSettingsMutation, SetReEnrolCenterSettingsMutationVariables>(
    SetReEnrolCenterSettingsDocument,
    options
  );
}
export type SetReEnrolCenterSettingsMutationHookResult = ReturnType<typeof useSetReEnrolCenterSettingsMutation>;
export type SetReEnrolCenterSettingsMutationResult = Apollo.MutationResult<SetReEnrolCenterSettingsMutation>;
export type SetReEnrolCenterSettingsMutationOptions = Apollo.BaseMutationOptions<
  SetReEnrolCenterSettingsMutation,
  SetReEnrolCenterSettingsMutationVariables
>;
export const ResetReEnrolCenterSettingsDocument = gql`
  mutation resetReEnrolCenterSettings($input: ResetReEnrolCenterSettingsInput!) {
    resetReEnrolCenterSettings(input: $input) {
      entityId
      centerId
      centerName
      usesParentApplication
      usesEnrolmentForm
      fromDefault
      businessUsesReEnrol
    }
  }
`;
export type ResetReEnrolCenterSettingsMutationFn = Apollo.MutationFunction<
  ResetReEnrolCenterSettingsMutation,
  ResetReEnrolCenterSettingsMutationVariables
>;

/**
 * __useResetReEnrolCenterSettingsMutation__
 *
 * To run a mutation, you first call `useResetReEnrolCenterSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetReEnrolCenterSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetReEnrolCenterSettingsMutation, { data, loading, error }] = useResetReEnrolCenterSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetReEnrolCenterSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetReEnrolCenterSettingsMutation,
    ResetReEnrolCenterSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetReEnrolCenterSettingsMutation, ResetReEnrolCenterSettingsMutationVariables>(
    ResetReEnrolCenterSettingsDocument,
    options
  );
}
export type ResetReEnrolCenterSettingsMutationHookResult = ReturnType<typeof useResetReEnrolCenterSettingsMutation>;
export type ResetReEnrolCenterSettingsMutationResult = Apollo.MutationResult<ResetReEnrolCenterSettingsMutation>;
export type ResetReEnrolCenterSettingsMutationOptions = Apollo.BaseMutationOptions<
  ResetReEnrolCenterSettingsMutation,
  ResetReEnrolCenterSettingsMutationVariables
>;
export const GetReenrollmentAccountsDocument = gql`
  query getReenrollmentAccounts($input: ReenrollmentGetAccountsRequestInput!) {
    getReenrollmentAccounts(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        accountId
        accountName
        centerId
        stage
        accountStatus
        lastContactedDate
        accountBalanceAmount
      }
    }
  }
`;

/**
 * __useGetReenrollmentAccountsQuery__
 *
 * To run a query within a React component, call `useGetReenrollmentAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReenrollmentAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReenrollmentAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReenrollmentAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReenrollmentAccountsQuery, GetReenrollmentAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReenrollmentAccountsQuery, GetReenrollmentAccountsQueryVariables>(
    GetReenrollmentAccountsDocument,
    options
  );
}
export function useGetReenrollmentAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReenrollmentAccountsQuery, GetReenrollmentAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReenrollmentAccountsQuery, GetReenrollmentAccountsQueryVariables>(
    GetReenrollmentAccountsDocument,
    options
  );
}
export type GetReenrollmentAccountsQueryHookResult = ReturnType<typeof useGetReenrollmentAccountsQuery>;
export type GetReenrollmentAccountsLazyQueryHookResult = ReturnType<typeof useGetReenrollmentAccountsLazyQuery>;
export type GetReenrollmentAccountsQueryResult = Apollo.QueryResult<
  GetReenrollmentAccountsQuery,
  GetReenrollmentAccountsQueryVariables
>;
export const GetReenrollmentAccountContractsDocument = gql`
  query getReenrollmentAccountContracts($input: ReenrollmentGetAccountContractsRequestInput!) {
    getReenrollmentAccountContracts(input: $input) {
      id
      childId
      accountChildId
      nickname
      firstname
      lastname
      avatar
      dob
      archivedAt
      accountId
      contracts {
        id
        startDate
        endDate
      }
    }
  }
`;

/**
 * __useGetReenrollmentAccountContractsQuery__
 *
 * To run a query within a React component, call `useGetReenrollmentAccountContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReenrollmentAccountContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReenrollmentAccountContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReenrollmentAccountContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReenrollmentAccountContractsQuery,
    GetReenrollmentAccountContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReenrollmentAccountContractsQuery, GetReenrollmentAccountContractsQueryVariables>(
    GetReenrollmentAccountContractsDocument,
    options
  );
}
export function useGetReenrollmentAccountContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReenrollmentAccountContractsQuery,
    GetReenrollmentAccountContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReenrollmentAccountContractsQuery, GetReenrollmentAccountContractsQueryVariables>(
    GetReenrollmentAccountContractsDocument,
    options
  );
}
export type GetReenrollmentAccountContractsQueryHookResult = ReturnType<typeof useGetReenrollmentAccountContractsQuery>;
export type GetReenrollmentAccountContractsLazyQueryHookResult = ReturnType<
  typeof useGetReenrollmentAccountContractsLazyQuery
>;
export type GetReenrollmentAccountContractsQueryResult = Apollo.QueryResult<
  GetReenrollmentAccountContractsQuery,
  GetReenrollmentAccountContractsQueryVariables
>;
export const GetReEnrolBusinessSettingsDocument = gql`
  query getReEnrolBusinessSettings($input: GetReEnrolBusinessSettingsInput!) {
    getReEnrolBusinessSettings(input: $input) {
      entityId
      usesReEnrol
      usesParentApplication
      usesEnrolmentForm
      centerCount
    }
  }
`;

/**
 * __useGetReEnrolBusinessSettingsQuery__
 *
 * To run a query within a React component, call `useGetReEnrolBusinessSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReEnrolBusinessSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReEnrolBusinessSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReEnrolBusinessSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReEnrolBusinessSettingsQuery, GetReEnrolBusinessSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReEnrolBusinessSettingsQuery, GetReEnrolBusinessSettingsQueryVariables>(
    GetReEnrolBusinessSettingsDocument,
    options
  );
}
export function useGetReEnrolBusinessSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReEnrolBusinessSettingsQuery, GetReEnrolBusinessSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReEnrolBusinessSettingsQuery, GetReEnrolBusinessSettingsQueryVariables>(
    GetReEnrolBusinessSettingsDocument,
    options
  );
}
export type GetReEnrolBusinessSettingsQueryHookResult = ReturnType<typeof useGetReEnrolBusinessSettingsQuery>;
export type GetReEnrolBusinessSettingsLazyQueryHookResult = ReturnType<typeof useGetReEnrolBusinessSettingsLazyQuery>;
export type GetReEnrolBusinessSettingsQueryResult = Apollo.QueryResult<
  GetReEnrolBusinessSettingsQuery,
  GetReEnrolBusinessSettingsQueryVariables
>;
export const GetReEnrolCenterSettingsDocument = gql`
  query getReEnrolCenterSettings($input: SearchInput!, $input2: GetReEnrolBusinessSettingsInput!) {
    searchCenters(input: $input) {
      totalResults
      data {
        id
        name
        address {
          state
        }
        tags {
          id
          name
        }
        reEnrolSettings {
          entityId
          centerId
          centerName
          usesParentApplication
          usesEnrolmentForm
          fromDefault
          businessUsesReEnrol
        }
      }
    }
    getReEnrolBusinessSettings(input: $input2) {
      entityId
      usesReEnrol
      usesParentApplication
      usesEnrolmentForm
      centerCount
    }
  }
`;

/**
 * __useGetReEnrolCenterSettingsQuery__
 *
 * To run a query within a React component, call `useGetReEnrolCenterSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReEnrolCenterSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReEnrolCenterSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      input2: // value for 'input2'
 *   },
 * });
 */
export function useGetReEnrolCenterSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReEnrolCenterSettingsQuery, GetReEnrolCenterSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReEnrolCenterSettingsQuery, GetReEnrolCenterSettingsQueryVariables>(
    GetReEnrolCenterSettingsDocument,
    options
  );
}
export function useGetReEnrolCenterSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReEnrolCenterSettingsQuery, GetReEnrolCenterSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReEnrolCenterSettingsQuery, GetReEnrolCenterSettingsQueryVariables>(
    GetReEnrolCenterSettingsDocument,
    options
  );
}
export type GetReEnrolCenterSettingsQueryHookResult = ReturnType<typeof useGetReEnrolCenterSettingsQuery>;
export type GetReEnrolCenterSettingsLazyQueryHookResult = ReturnType<typeof useGetReEnrolCenterSettingsLazyQuery>;
export type GetReEnrolCenterSettingsQueryResult = Apollo.QueryResult<
  GetReEnrolCenterSettingsQuery,
  GetReEnrolCenterSettingsQueryVariables
>;
export const GetReEnrolHistoryOfActionsDocument = gql`
  query getReEnrolHistoryOfActions($businessId: ID!, $input: GetReEnrolHistoryOfActionsInput!) {
    getReEnrolHistoryOfActions(businessId: $businessId, input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        batchId
        userFriendlyBatchId
        createdAt
        createdBy
        accountId
        accountName
        centerId
        centerName
        stage
        declineReason
        otherReason
        declinedAt
        declinedBy
        declinedByName
        stageLastModifiedAt
        createdByName
        correspondenceType
        completedBy
        completedByName
        linkCode
      }
    }
  }
`;

/**
 * __useGetReEnrolHistoryOfActionsQuery__
 *
 * To run a query within a React component, call `useGetReEnrolHistoryOfActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReEnrolHistoryOfActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReEnrolHistoryOfActionsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReEnrolHistoryOfActionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReEnrolHistoryOfActionsQuery, GetReEnrolHistoryOfActionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReEnrolHistoryOfActionsQuery, GetReEnrolHistoryOfActionsQueryVariables>(
    GetReEnrolHistoryOfActionsDocument,
    options
  );
}
export function useGetReEnrolHistoryOfActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReEnrolHistoryOfActionsQuery, GetReEnrolHistoryOfActionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReEnrolHistoryOfActionsQuery, GetReEnrolHistoryOfActionsQueryVariables>(
    GetReEnrolHistoryOfActionsDocument,
    options
  );
}
export type GetReEnrolHistoryOfActionsQueryHookResult = ReturnType<typeof useGetReEnrolHistoryOfActionsQuery>;
export type GetReEnrolHistoryOfActionsLazyQueryHookResult = ReturnType<typeof useGetReEnrolHistoryOfActionsLazyQuery>;
export type GetReEnrolHistoryOfActionsQueryResult = Apollo.QueryResult<
  GetReEnrolHistoryOfActionsQuery,
  GetReEnrolHistoryOfActionsQueryVariables
>;
export const GetReEnrolAccountsByBatchIdDocument = gql`
  query getReEnrolAccountsByBatchId($businessId: ID!, $batchId: ID!, $input: ReenrollmentGetAccountsRequestInput!) {
    getReEnrolAccountsByBatchId(businessId: $businessId, batchId: $batchId, input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        accountId
        accountName
        centerId
        stage
        accountStatus
        lastContactedDate
        accountBalanceAmount
      }
    }
  }
`;

/**
 * __useGetReEnrolAccountsByBatchIdQuery__
 *
 * To run a query within a React component, call `useGetReEnrolAccountsByBatchIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReEnrolAccountsByBatchIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReEnrolAccountsByBatchIdQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      batchId: // value for 'batchId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReEnrolAccountsByBatchIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetReEnrolAccountsByBatchIdQuery, GetReEnrolAccountsByBatchIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReEnrolAccountsByBatchIdQuery, GetReEnrolAccountsByBatchIdQueryVariables>(
    GetReEnrolAccountsByBatchIdDocument,
    options
  );
}
export function useGetReEnrolAccountsByBatchIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReEnrolAccountsByBatchIdQuery, GetReEnrolAccountsByBatchIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReEnrolAccountsByBatchIdQuery, GetReEnrolAccountsByBatchIdQueryVariables>(
    GetReEnrolAccountsByBatchIdDocument,
    options
  );
}
export type GetReEnrolAccountsByBatchIdQueryHookResult = ReturnType<typeof useGetReEnrolAccountsByBatchIdQuery>;
export type GetReEnrolAccountsByBatchIdLazyQueryHookResult = ReturnType<typeof useGetReEnrolAccountsByBatchIdLazyQuery>;
export type GetReEnrolAccountsByBatchIdQueryResult = Apollo.QueryResult<
  GetReEnrolAccountsByBatchIdQuery,
  GetReEnrolAccountsByBatchIdQueryVariables
>;
export const GetReEnrolCorrespondenceMetadataByLinkCodeDocument = gql`
  query getReEnrolCorrespondenceMetadataByLinkCode($businessId: ID!, $linkCode: String!) {
    getReEnrolCorrespondenceMetadataByLinkCode(businessId: $businessId, linkCode: $linkCode) {
      linkCode
      relatedApplicationId
      relatedApplication {
        id
        businessId
        state
        lastModifiedAt
        appliedFlowType
        relatedApplicationChildren {
          applicationChildId
          childFullName
        }
        relatedApplicationOffers {
          id
          expiryDate
          applicationScheduleOfferIds
          linkCode
          applicationState
          centerId
          enrolmentFormDataRecordId
        }
        relatedApplicationScheduleOffers {
          id
          applicationOfferId
          applicationChildId
          applicationScheduleOfferState
          IisWaitListOffered
        }
      }
      enrolmentFormAccountId
      enrolmentFormAccountDataRecordId
    }
  }
`;

/**
 * __useGetReEnrolCorrespondenceMetadataByLinkCodeQuery__
 *
 * To run a query within a React component, call `useGetReEnrolCorrespondenceMetadataByLinkCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReEnrolCorrespondenceMetadataByLinkCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReEnrolCorrespondenceMetadataByLinkCodeQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      linkCode: // value for 'linkCode'
 *   },
 * });
 */
export function useGetReEnrolCorrespondenceMetadataByLinkCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReEnrolCorrespondenceMetadataByLinkCodeQuery,
    GetReEnrolCorrespondenceMetadataByLinkCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReEnrolCorrespondenceMetadataByLinkCodeQuery,
    GetReEnrolCorrespondenceMetadataByLinkCodeQueryVariables
  >(GetReEnrolCorrespondenceMetadataByLinkCodeDocument, options);
}
export function useGetReEnrolCorrespondenceMetadataByLinkCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReEnrolCorrespondenceMetadataByLinkCodeQuery,
    GetReEnrolCorrespondenceMetadataByLinkCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReEnrolCorrespondenceMetadataByLinkCodeQuery,
    GetReEnrolCorrespondenceMetadataByLinkCodeQueryVariables
  >(GetReEnrolCorrespondenceMetadataByLinkCodeDocument, options);
}
export type GetReEnrolCorrespondenceMetadataByLinkCodeQueryHookResult = ReturnType<
  typeof useGetReEnrolCorrespondenceMetadataByLinkCodeQuery
>;
export type GetReEnrolCorrespondenceMetadataByLinkCodeLazyQueryHookResult = ReturnType<
  typeof useGetReEnrolCorrespondenceMetadataByLinkCodeLazyQuery
>;
export type GetReEnrolCorrespondenceMetadataByLinkCodeQueryResult = Apollo.QueryResult<
  GetReEnrolCorrespondenceMetadataByLinkCodeQuery,
  GetReEnrolCorrespondenceMetadataByLinkCodeQueryVariables
>;
export const GetEnrolmentFormDiffDocument = gql`
  query getEnrolmentFormDiff($businessId: ID!, $enrolmentFormId: String!) {
    getEnrolmentFormDiff(businessId: $businessId, enrolmentFormId: $enrolmentFormId) {
      diffCount
      diffState
      systemFields {
        ...systemFieldFragment
      }
      payment {
        systemFields {
          ...systemFieldFragment
        }
        diffCount
        diffState
      }
      billingCycle {
        systemFields {
          ...systemFieldFragment
        }
        diffCount
        diffState
      }
      customFields {
        ...customFieldFragment
      }
      children {
        childId
        firstName
        lastName
        isNewChild
        immunization {
          ...documentUploadDiffFragment
        }
        medicalInfo {
          id
          restrictionName
          category {
            ...lookupJsonFragment
          }
          type {
            ...lookupJsonFragment
          }
          medicalName
          allergyName {
            ...lookupJsonFragment
          }
          risk {
            ...lookupJsonFragment
          }
          importance {
            ...lookupJsonFragment
          }
          symptoms {
            ...lookupJsonFragment
          }
          severity {
            ...lookupJsonFragment
          }
          reactions {
            ...lookupJsonFragment
          }
          note
          documents {
            ...documentUploadFragment
          }
        }
        customFields {
          ...customFieldFragment
        }
        diffCount
        diffState
        systemFields {
          ...systemFieldFragment
        }
      }
      primaryContacts {
        ...contactDiffFragment
      }
      contacts {
        ...contactDiffFragment
      }
    }
  }
  ${SystemFieldFragmentFragmentDoc}
  ${CustomFieldFragmentFragmentDoc}
  ${DocumentUploadDiffFragmentFragmentDoc}
  ${LookupJsonFragmentFragmentDoc}
  ${DocumentUploadFragmentFragmentDoc}
  ${ContactDiffFragmentFragmentDoc}
`;

/**
 * __useGetEnrolmentFormDiffQuery__
 *
 * To run a query within a React component, call `useGetEnrolmentFormDiffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrolmentFormDiffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrolmentFormDiffQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      enrolmentFormId: // value for 'enrolmentFormId'
 *   },
 * });
 */
export function useGetEnrolmentFormDiffQuery(
  baseOptions: Apollo.QueryHookOptions<GetEnrolmentFormDiffQuery, GetEnrolmentFormDiffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEnrolmentFormDiffQuery, GetEnrolmentFormDiffQueryVariables>(
    GetEnrolmentFormDiffDocument,
    options
  );
}
export function useGetEnrolmentFormDiffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEnrolmentFormDiffQuery, GetEnrolmentFormDiffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEnrolmentFormDiffQuery, GetEnrolmentFormDiffQueryVariables>(
    GetEnrolmentFormDiffDocument,
    options
  );
}
export type GetEnrolmentFormDiffQueryHookResult = ReturnType<typeof useGetEnrolmentFormDiffQuery>;
export type GetEnrolmentFormDiffLazyQueryHookResult = ReturnType<typeof useGetEnrolmentFormDiffLazyQuery>;
export type GetEnrolmentFormDiffQueryResult = Apollo.QueryResult<
  GetEnrolmentFormDiffQuery,
  GetEnrolmentFormDiffQueryVariables
>;
export const GetPayrixDisbursementReportDocument = gql`
  query getPayrixDisbursementReport($input: GetPayrixDisbursementReportInput!) {
    getPayrixDisbursementReport(input: $input) {
      reportMetaData {
        ...reportMetaData
      }
      reportData
    }
  }
  ${ReportMetaDataFragmentDoc}
`;

/**
 * __useGetPayrixDisbursementReportQuery__
 *
 * To run a query within a React component, call `useGetPayrixDisbursementReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrixDisbursementReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrixDisbursementReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPayrixDisbursementReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetPayrixDisbursementReportQuery, GetPayrixDisbursementReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPayrixDisbursementReportQuery, GetPayrixDisbursementReportQueryVariables>(
    GetPayrixDisbursementReportDocument,
    options
  );
}
export function useGetPayrixDisbursementReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPayrixDisbursementReportQuery, GetPayrixDisbursementReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPayrixDisbursementReportQuery, GetPayrixDisbursementReportQueryVariables>(
    GetPayrixDisbursementReportDocument,
    options
  );
}
export type GetPayrixDisbursementReportQueryHookResult = ReturnType<typeof useGetPayrixDisbursementReportQuery>;
export type GetPayrixDisbursementReportLazyQueryHookResult = ReturnType<typeof useGetPayrixDisbursementReportLazyQuery>;
export type GetPayrixDisbursementReportQueryResult = Apollo.QueryResult<
  GetPayrixDisbursementReportQuery,
  GetPayrixDisbursementReportQueryVariables
>;
export const GetPayrixDisbursementReportPdfDocument = gql`
  query getPayrixDisbursementReportPdf($input: GetPayrixDisbursementReportInput!) {
    getPayrixDisbursementReportPdf(input: $input)
  }
`;

/**
 * __useGetPayrixDisbursementReportPdfQuery__
 *
 * To run a query within a React component, call `useGetPayrixDisbursementReportPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrixDisbursementReportPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrixDisbursementReportPdfQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPayrixDisbursementReportPdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayrixDisbursementReportPdfQuery,
    GetPayrixDisbursementReportPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPayrixDisbursementReportPdfQuery, GetPayrixDisbursementReportPdfQueryVariables>(
    GetPayrixDisbursementReportPdfDocument,
    options
  );
}
export function useGetPayrixDisbursementReportPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayrixDisbursementReportPdfQuery,
    GetPayrixDisbursementReportPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPayrixDisbursementReportPdfQuery, GetPayrixDisbursementReportPdfQueryVariables>(
    GetPayrixDisbursementReportPdfDocument,
    options
  );
}
export type GetPayrixDisbursementReportPdfQueryHookResult = ReturnType<typeof useGetPayrixDisbursementReportPdfQuery>;
export type GetPayrixDisbursementReportPdfLazyQueryHookResult = ReturnType<
  typeof useGetPayrixDisbursementReportPdfLazyQuery
>;
export type GetPayrixDisbursementReportPdfQueryResult = Apollo.QueryResult<
  GetPayrixDisbursementReportPdfQuery,
  GetPayrixDisbursementReportPdfQueryVariables
>;
export const GetCcsEnrolmentsReportDocument = gql`
  query getCcsEnrolmentsReport($input: CcsEnrolmentReportInput!, $businessId: ID!) {
    getCcsEnrolmentsReport(businessId: $businessId, input: $input)
  }
`;

/**
 * __useGetCcsEnrolmentsReportQuery__
 *
 * To run a query within a React component, call `useGetCcsEnrolmentsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCcsEnrolmentsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCcsEnrolmentsReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetCcsEnrolmentsReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetCcsEnrolmentsReportQuery, GetCcsEnrolmentsReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCcsEnrolmentsReportQuery, GetCcsEnrolmentsReportQueryVariables>(
    GetCcsEnrolmentsReportDocument,
    options
  );
}
export function useGetCcsEnrolmentsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCcsEnrolmentsReportQuery, GetCcsEnrolmentsReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCcsEnrolmentsReportQuery, GetCcsEnrolmentsReportQueryVariables>(
    GetCcsEnrolmentsReportDocument,
    options
  );
}
export type GetCcsEnrolmentsReportQueryHookResult = ReturnType<typeof useGetCcsEnrolmentsReportQuery>;
export type GetCcsEnrolmentsReportLazyQueryHookResult = ReturnType<typeof useGetCcsEnrolmentsReportLazyQuery>;
export type GetCcsEnrolmentsReportQueryResult = Apollo.QueryResult<
  GetCcsEnrolmentsReportQuery,
  GetCcsEnrolmentsReportQueryVariables
>;
export const GetCustomFieldsReportDocument = gql`
  query getCustomFieldsReport($input: CustomFieldsReportInput!) {
    getCustomFieldsReport(input: $input)
  }
`;

/**
 * __useGetCustomFieldsReportQuery__
 *
 * To run a query within a React component, call `useGetCustomFieldsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFieldsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFieldsReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomFieldsReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomFieldsReportQuery, GetCustomFieldsReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomFieldsReportQuery, GetCustomFieldsReportQueryVariables>(
    GetCustomFieldsReportDocument,
    options
  );
}
export function useGetCustomFieldsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFieldsReportQuery, GetCustomFieldsReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomFieldsReportQuery, GetCustomFieldsReportQueryVariables>(
    GetCustomFieldsReportDocument,
    options
  );
}
export type GetCustomFieldsReportQueryHookResult = ReturnType<typeof useGetCustomFieldsReportQuery>;
export type GetCustomFieldsReportLazyQueryHookResult = ReturnType<typeof useGetCustomFieldsReportLazyQuery>;
export type GetCustomFieldsReportQueryResult = Apollo.QueryResult<
  GetCustomFieldsReportQuery,
  GetCustomFieldsReportQueryVariables
>;
export const GetReEnrolHistoryReportDocument = gql`
  query getReEnrolHistoryReport($businessId: ID!, $input: GetReEnrolHistoryOfActionsInput!) {
    getReEnrolHistoryReport(businessId: $businessId, input: $input)
  }
`;

/**
 * __useGetReEnrolHistoryReportQuery__
 *
 * To run a query within a React component, call `useGetReEnrolHistoryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReEnrolHistoryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReEnrolHistoryReportQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReEnrolHistoryReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetReEnrolHistoryReportQuery, GetReEnrolHistoryReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReEnrolHistoryReportQuery, GetReEnrolHistoryReportQueryVariables>(
    GetReEnrolHistoryReportDocument,
    options
  );
}
export function useGetReEnrolHistoryReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReEnrolHistoryReportQuery, GetReEnrolHistoryReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReEnrolHistoryReportQuery, GetReEnrolHistoryReportQueryVariables>(
    GetReEnrolHistoryReportDocument,
    options
  );
}
export type GetReEnrolHistoryReportQueryHookResult = ReturnType<typeof useGetReEnrolHistoryReportQuery>;
export type GetReEnrolHistoryReportLazyQueryHookResult = ReturnType<typeof useGetReEnrolHistoryReportLazyQuery>;
export type GetReEnrolHistoryReportQueryResult = Apollo.QueryResult<
  GetReEnrolHistoryReportQuery,
  GetReEnrolHistoryReportQueryVariables
>;
export const GetEmployeeTimeSheetExcelReportDocument = gql`
  query getEmployeeTimeSheetExcelReport($input: EmployeeTimeSheetReportInput!) {
    getEmployeeTimeSheetExcelReport(input: $input)
  }
`;

/**
 * __useGetEmployeeTimeSheetExcelReportQuery__
 *
 * To run a query within a React component, call `useGetEmployeeTimeSheetExcelReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeTimeSheetExcelReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeTimeSheetExcelReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEmployeeTimeSheetExcelReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmployeeTimeSheetExcelReportQuery,
    GetEmployeeTimeSheetExcelReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEmployeeTimeSheetExcelReportQuery, GetEmployeeTimeSheetExcelReportQueryVariables>(
    GetEmployeeTimeSheetExcelReportDocument,
    options
  );
}
export function useGetEmployeeTimeSheetExcelReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeTimeSheetExcelReportQuery,
    GetEmployeeTimeSheetExcelReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEmployeeTimeSheetExcelReportQuery, GetEmployeeTimeSheetExcelReportQueryVariables>(
    GetEmployeeTimeSheetExcelReportDocument,
    options
  );
}
export type GetEmployeeTimeSheetExcelReportQueryHookResult = ReturnType<typeof useGetEmployeeTimeSheetExcelReportQuery>;
export type GetEmployeeTimeSheetExcelReportLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeTimeSheetExcelReportLazyQuery
>;
export type GetEmployeeTimeSheetExcelReportQueryResult = Apollo.QueryResult<
  GetEmployeeTimeSheetExcelReportQuery,
  GetEmployeeTimeSheetExcelReportQueryVariables
>;
export const ResubmitSessionReportSyncDocument = gql`
  mutation resubmitSessionReportSync($input: SessionReportSyncResubmitInput!) {
    resubmitSessionReportSync(input: $input)
  }
`;
export type ResubmitSessionReportSyncMutationFn = Apollo.MutationFunction<
  ResubmitSessionReportSyncMutation,
  ResubmitSessionReportSyncMutationVariables
>;

/**
 * __useResubmitSessionReportSyncMutation__
 *
 * To run a mutation, you first call `useResubmitSessionReportSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResubmitSessionReportSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resubmitSessionReportSyncMutation, { data, loading, error }] = useResubmitSessionReportSyncMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResubmitSessionReportSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResubmitSessionReportSyncMutation,
    ResubmitSessionReportSyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResubmitSessionReportSyncMutation, ResubmitSessionReportSyncMutationVariables>(
    ResubmitSessionReportSyncDocument,
    options
  );
}
export type ResubmitSessionReportSyncMutationHookResult = ReturnType<typeof useResubmitSessionReportSyncMutation>;
export type ResubmitSessionReportSyncMutationResult = Apollo.MutationResult<ResubmitSessionReportSyncMutation>;
export type ResubmitSessionReportSyncMutationOptions = Apollo.BaseMutationOptions<
  ResubmitSessionReportSyncMutation,
  ResubmitSessionReportSyncMutationVariables
>;
export const ReportAdditionalAbsenceNewDocument = gql`
  mutation reportAdditionalAbsenceNew($input: AdditionalAbsenceMessageRecord!) {
    reportAdditionalAbsenceNew(input: $input) {
      id
      sessionId
      contractId
      accountChildId
      type
      reason
      absenceDocumentHeld
      date
      createdAt
      createdBy
      waiveGapFee
    }
  }
`;
export type ReportAdditionalAbsenceNewMutationFn = Apollo.MutationFunction<
  ReportAdditionalAbsenceNewMutation,
  ReportAdditionalAbsenceNewMutationVariables
>;

/**
 * __useReportAdditionalAbsenceNewMutation__
 *
 * To run a mutation, you first call `useReportAdditionalAbsenceNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportAdditionalAbsenceNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportAdditionalAbsenceNewMutation, { data, loading, error }] = useReportAdditionalAbsenceNewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportAdditionalAbsenceNewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportAdditionalAbsenceNewMutation,
    ReportAdditionalAbsenceNewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReportAdditionalAbsenceNewMutation, ReportAdditionalAbsenceNewMutationVariables>(
    ReportAdditionalAbsenceNewDocument,
    options
  );
}
export type ReportAdditionalAbsenceNewMutationHookResult = ReturnType<typeof useReportAdditionalAbsenceNewMutation>;
export type ReportAdditionalAbsenceNewMutationResult = Apollo.MutationResult<ReportAdditionalAbsenceNewMutation>;
export type ReportAdditionalAbsenceNewMutationOptions = Apollo.BaseMutationOptions<
  ReportAdditionalAbsenceNewMutation,
  ReportAdditionalAbsenceNewMutationVariables
>;
export const ReportBulkAdditionalAbsenceNewDocument = gql`
  mutation reportBulkAdditionalAbsenceNew($input: BulkAdditionalAbsenceMessageRecords!) {
    reportBulkAdditionalAbsenceNew(input: $input) {
      id
      sessionId
      contractId
      accountChildId
      type
      reason
      absenceDocumentHeld
      date
      createdAt
      createdBy
      waiveGapFee
    }
  }
`;
export type ReportBulkAdditionalAbsenceNewMutationFn = Apollo.MutationFunction<
  ReportBulkAdditionalAbsenceNewMutation,
  ReportBulkAdditionalAbsenceNewMutationVariables
>;

/**
 * __useReportBulkAdditionalAbsenceNewMutation__
 *
 * To run a mutation, you first call `useReportBulkAdditionalAbsenceNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportBulkAdditionalAbsenceNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportBulkAdditionalAbsenceNewMutation, { data, loading, error }] = useReportBulkAdditionalAbsenceNewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportBulkAdditionalAbsenceNewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportBulkAdditionalAbsenceNewMutation,
    ReportBulkAdditionalAbsenceNewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReportBulkAdditionalAbsenceNewMutation, ReportBulkAdditionalAbsenceNewMutationVariables>(
    ReportBulkAdditionalAbsenceNewDocument,
    options
  );
}
export type ReportBulkAdditionalAbsenceNewMutationHookResult = ReturnType<
  typeof useReportBulkAdditionalAbsenceNewMutation
>;
export type ReportBulkAdditionalAbsenceNewMutationResult =
  Apollo.MutationResult<ReportBulkAdditionalAbsenceNewMutation>;
export type ReportBulkAdditionalAbsenceNewMutationOptions = Apollo.BaseMutationOptions<
  ReportBulkAdditionalAbsenceNewMutation,
  ReportBulkAdditionalAbsenceNewMutationVariables
>;
export const GetKindySubsidySchemesDocument = gql`
  query getKindySubsidySchemes {
    getKindySubsidySchemes {
      businessId
      name
      subsidySchemeId
    }
  }
`;

/**
 * __useGetKindySubsidySchemesQuery__
 *
 * To run a query within a React component, call `useGetKindySubsidySchemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKindySubsidySchemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKindySubsidySchemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKindySubsidySchemesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetKindySubsidySchemesQuery, GetKindySubsidySchemesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetKindySubsidySchemesQuery, GetKindySubsidySchemesQueryVariables>(
    GetKindySubsidySchemesDocument,
    options
  );
}
export function useGetKindySubsidySchemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetKindySubsidySchemesQuery, GetKindySubsidySchemesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetKindySubsidySchemesQuery, GetKindySubsidySchemesQueryVariables>(
    GetKindySubsidySchemesDocument,
    options
  );
}
export type GetKindySubsidySchemesQueryHookResult = ReturnType<typeof useGetKindySubsidySchemesQuery>;
export type GetKindySubsidySchemesLazyQueryHookResult = ReturnType<typeof useGetKindySubsidySchemesLazyQuery>;
export type GetKindySubsidySchemesQueryResult = Apollo.QueryResult<
  GetKindySubsidySchemesQuery,
  GetKindySubsidySchemesQueryVariables
>;
export const GetSystemFieldsDocument = gql`
  query getSystemFields {
    getSystemFields {
      id
      area
      attributesAsString
      businessId
      centerIds
      label
      area
      displayOrder
      notForConsoleDisplay
      type
      groups {
        name
        id
      }
      valuesAsString
      groupQuestionSchemaAsString
      displayDateFormat
    }
  }
`;

/**
 * __useGetSystemFieldsQuery__
 *
 * To run a query within a React component, call `useGetSystemFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSystemFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSystemFieldsQuery, GetSystemFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSystemFieldsQuery, GetSystemFieldsQueryVariables>(GetSystemFieldsDocument, options);
}
export function useGetSystemFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSystemFieldsQuery, GetSystemFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSystemFieldsQuery, GetSystemFieldsQueryVariables>(GetSystemFieldsDocument, options);
}
export type GetSystemFieldsQueryHookResult = ReturnType<typeof useGetSystemFieldsQuery>;
export type GetSystemFieldsLazyQueryHookResult = ReturnType<typeof useGetSystemFieldsLazyQuery>;
export type GetSystemFieldsQueryResult = Apollo.QueryResult<GetSystemFieldsQuery, GetSystemFieldsQueryVariables>;
export const IsCustomFieldInUseByFormDocument = gql`
  query isCustomFieldInUseByForm($businessId: ID!, $customFieldId: ID!) {
    isCustomFieldInUseByForm(businessId: $businessId, customFieldId: $customFieldId) {
      customFieldId
      formId
      formName
      formVersion
      useCount
    }
  }
`;

/**
 * __useIsCustomFieldInUseByFormQuery__
 *
 * To run a query within a React component, call `useIsCustomFieldInUseByFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsCustomFieldInUseByFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsCustomFieldInUseByFormQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      customFieldId: // value for 'customFieldId'
 *   },
 * });
 */
export function useIsCustomFieldInUseByFormQuery(
  baseOptions: Apollo.QueryHookOptions<IsCustomFieldInUseByFormQuery, IsCustomFieldInUseByFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsCustomFieldInUseByFormQuery, IsCustomFieldInUseByFormQueryVariables>(
    IsCustomFieldInUseByFormDocument,
    options
  );
}
export function useIsCustomFieldInUseByFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsCustomFieldInUseByFormQuery, IsCustomFieldInUseByFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsCustomFieldInUseByFormQuery, IsCustomFieldInUseByFormQueryVariables>(
    IsCustomFieldInUseByFormDocument,
    options
  );
}
export type IsCustomFieldInUseByFormQueryHookResult = ReturnType<typeof useIsCustomFieldInUseByFormQuery>;
export type IsCustomFieldInUseByFormLazyQueryHookResult = ReturnType<typeof useIsCustomFieldInUseByFormLazyQuery>;
export type IsCustomFieldInUseByFormQueryResult = Apollo.QueryResult<
  IsCustomFieldInUseByFormQuery,
  IsCustomFieldInUseByFormQueryVariables
>;
export const GetBillingBusinessSettingsDocument = gql`
  query getBillingBusinessSettings($businessId: ID) {
    fetchBillingBusinessSettings(businessId: $businessId) {
      mandatoryGlCodeMapping
      parentPaymentDirection
      transactionAmountDisplay
      transactionAmountThemed
    }
  }
`;

/**
 * __useGetBillingBusinessSettingsQuery__
 *
 * To run a query within a React component, call `useGetBillingBusinessSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingBusinessSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingBusinessSettingsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetBillingBusinessSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBillingBusinessSettingsQuery, GetBillingBusinessSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBillingBusinessSettingsQuery, GetBillingBusinessSettingsQueryVariables>(
    GetBillingBusinessSettingsDocument,
    options
  );
}
export function useGetBillingBusinessSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBillingBusinessSettingsQuery, GetBillingBusinessSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBillingBusinessSettingsQuery, GetBillingBusinessSettingsQueryVariables>(
    GetBillingBusinessSettingsDocument,
    options
  );
}
export type GetBillingBusinessSettingsQueryHookResult = ReturnType<typeof useGetBillingBusinessSettingsQuery>;
export type GetBillingBusinessSettingsLazyQueryHookResult = ReturnType<typeof useGetBillingBusinessSettingsLazyQuery>;
export type GetBillingBusinessSettingsQueryResult = Apollo.QueryResult<
  GetBillingBusinessSettingsQuery,
  GetBillingBusinessSettingsQueryVariables
>;
