import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'shared/components/Buttons';
import ShiftDetailsInformation from '../ShiftDetailsInformation';
import colors from '_colors.module.scss';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  shift: IShift | null;
  disableActionButtons: boolean;
  showActionButtons?: boolean;
  onClose: () => void;
  onCopyShift: (shift: IShift) => void;
  onEditShift: (shift: IShift) => void;
  onDeleteShift: (shift: IShift) => void;
}

const ShiftDetailsModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  shift,
  disableActionButtons,
  showActionButtons = true,
  onClose,
  onCopyShift,
  onEditShift,
  onDeleteShift,
  ...props
}) => {
  if (!shift) {
    return null;
  }

  const shiftClassAssignment: string = shift.classId
    ? `${shift.class?.name ?? ''}`
    : shift.locationId
    ? `${shift.location?.name ?? ''}`
    : '';

  return (
    <Modal backdrop="static" centered show={isOpen} onHide={onClose} dialogClassName="kt-staff-schedules-shift-modal">
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Shift Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row align-items-center mb-4">
            <div
              className="kt-staff-schedules-shift-modal-avatar"
              style={{ backgroundColor: shift.class?.colorCode ?? colors.gray }}
            >
              {shiftClassAssignment.charAt(0).toUpperCase()}
            </div>
            <span className="ml-4 kt-staff-schedules-shift-modal-primary-text">{shiftClassAssignment}</span>
            <HasRoleAreaLevel
              action={{ area: AreaType.Schedule, permission: PermissionType.Base, level: RoleLevelType.Edit }}
            >
              <Button
                variant="light"
                className="ml-auto"
                onClick={() => onEditShift(shift)}
                disabled={disableActionButtons}
              >
                Edit
              </Button>
            </HasRoleAreaLevel>
          </div>
          <ShiftDetailsInformation shift={shift} />
        </div>
      </Modal.Body>
      {showActionButtons && (
        <Modal.Footer className="border-top-0">
          <HasRoleAreaLevel
            action={{ area: AreaType.Schedule, permission: PermissionType.Base, level: RoleLevelType.Delete }}
          >
            <Button
              loading={isLoading}
              disabled={isLoading || disableActionButtons}
              variant="danger"
              onClick={() => onDeleteShift(shift)}
            >
              Delete
            </Button>
          </HasRoleAreaLevel>
          <HasRoleAreaLevel
            action={{ area: AreaType.Schedule, permission: PermissionType.Base, level: RoleLevelType.Edit }}
          >
            <Button variant="primary" onClick={() => onCopyShift(shift)} disabled={disableActionButtons}>
              Copy Shift
            </Button>
          </HasRoleAreaLevel>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ShiftDetailsModal;
