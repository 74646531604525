import Card from 'shared/components/Card';
import React from 'react';
import { Col, Row } from 'shared/components/Layout';
import surchargesBanner from 'shared/images/Surcharges-Banner.svg';
import { useTranslation } from 'react-i18next';
import Switch from 'shared/components/Switch';
import { ApplicationSettingType } from '../ProgramGroupsTab';

interface IProps {
  onChangeGroupSetting: (value: boolean, valueType: ApplicationSettingType) => void;
  groupSettingValue?: boolean;
  feeSettingValue?: boolean;
}
const ProgramGroupSettingBanner: React.FC<IProps> = ({ onChangeGroupSetting, groupSettingValue, feeSettingValue }) => {
  const { t } = useTranslation();
  return (
    <Card bodyClassName="p-8">
      <div className="d-flex align-items-center">
        <img src={surchargesBanner} alt="cartoon helper man" className="align-self-start" style={{ width: '235px' }} />
        <div className="pl-8">
          <Row>
            <Col>
              <h3>{t('enrollment.program-groups.banner.title')}</h3>
              <br />
              <p>{t('enrollment.program-groups.banner.description')}</p>

              <ul>
                <li>{t('enrollment.program-groups.banner.note-1')}</li>
                <li>{t('enrollment.program-groups.banner.note-2')}</li>
              </ul>
              <h5>{t('enrollment.program-groups.banner.turn-groups-on')}</h5>
              <Switch
                value={groupSettingValue ?? false}
                onChange={(val) => {
                  onChangeGroupSetting(val, ApplicationSettingType.isUseProgramGroup);
                }}
              />
            </Col>
          </Row>
          {groupSettingValue && (
            <Row className="mt-4">
              <Col>
                <hr className="mb-4" />
                <h5>{t('enrollment.program-groups.banner.turn-separate-fees-on.title')}</h5>
                <p>{t('enrollment.program-groups.banner.turn-separate-fees-on.description')}</p>
                <Switch
                  value={feeSettingValue ?? false}
                  onChange={(val) => {
                    onChangeGroupSetting(val, ApplicationSettingType.isUsePerProgramGroupFees);
                  }}
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </Card>
  );
};
export default ProgramGroupSettingBanner;
