export const apiKeyWebHookFields = `
  id
  apiKeyId
  createdBy
  businessId
  createdAt
  url
  webHookType
  webHookApiKey
  lastModifiedAt
  lastModifiedBy
  `;
