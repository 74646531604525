import React from 'react';
import { ApplicationNote, Maybe } from 'generated/graphql';
import './viewNotes.scss';
import { useTranslation } from 'react-i18next';
import Note from './Note';

interface IViewNotesProps {
  data: Maybe<ApplicationNote>[];
  onEditOpen: () => void;
  onEditSuccessful: () => void;
  onEditCanceled: () => void;
}

const ViewNotes = ({ data, onEditOpen, onEditSuccessful, onEditCanceled }: IViewNotesProps) => {
  const { t } = useTranslation();

  return (
    <div className="view-notes-section">
      <div className="view-notes-section__container">
        {data.map((note) => (
          <Note
            note={note}
            key={note?.id}
            onEditOpen={onEditOpen}
            onEditSuccessful={onEditSuccessful}
            onEditCanceled={onEditCanceled}
          />
        ))}
      </div>
    </div>
  );
};

export default ViewNotes;
