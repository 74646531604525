import React from 'react';
import { SchedulesByOffers } from './ViewOffers/ViewOffers';

interface IProps {
  schedulesByOffers: SchedulesByOffers[];
}

const OfferSummary: React.FC<IProps> = ({ schedulesByOffers }) => {
  const offerIds = schedulesByOffers.map((o) => o.offer.id);

  return (
    <div className="offer-summary d-flex">
      <div className="d-flex flex-wrap mr-2 px-2">
        <span className="font-weight-bold mr-2">{`${offerIds.length} Offers:`}</span>
        {schedulesByOffers.map((schedulesByOffer, index) => {
          const childNames = schedulesByOffer.childrenwithSchedules.map((c) => c.childFullName).join(', ');

          return (
            <>
              <span className="font-weight-bold  mr-1">{index + 1}.</span>
              <span className="mr-2">{childNames}</span>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default OfferSummary;
