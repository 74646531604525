import {
  CentersActionTypes,
  GET_CENTERS_SUCCESS,
  GET_CENTER_SUCCESS,
  CREATE_CENTER,
  UPDATE_CENTER,
  REMOVE_CENTER_FROM_STORE,
  UPDATE_CENTERS_TABLE_FILTERS,
  IUpdateCentersTableFilters_Status,
} from './types';

export const getCentersSuccess = (centers: ICenter[]): CentersActionTypes => ({
  type: GET_CENTERS_SUCCESS,
  centers,
});

export const getCenterSuccess = (center: ICenter): CentersActionTypes => ({
  type: GET_CENTER_SUCCESS,
  center,
});

export const createCenter = (center: ICenter): CentersActionTypes => ({
  type: CREATE_CENTER,
  center,
});

export const updateCenter = (center: ICenter): CentersActionTypes => ({
  type: UPDATE_CENTER,
  center,
});

export const removeCenterFromStore = (id: string): CentersActionTypes => ({
  type: REMOVE_CENTER_FROM_STORE,
  id,
});

export const updateCentersTableFilters = (status: IUpdateCentersTableFilters_Status): CentersActionTypes => ({
  type: UPDATE_CENTERS_TABLE_FILTERS,
  filters: {
    status,
  },
});
