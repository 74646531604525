import React from 'react';
import Card from 'shared/components/Card';
import { CreateButton } from 'shared/components/Buttons';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import bitmap from './bitmap.png';

interface IProps {
  onButtonClick: () => void;
  userCanCreateChild: boolean;
}

// empty table state component to show if a user has not added any children
const InitialEmptyChildrenTable: React.FC<IProps> = ({ onButtonClick, userCanCreateChild, ...props }) => {
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <div className="mt-12">
      <div className="mx-auto" style={{ maxWidth: 900 }}>
        <h2 className="font-weight-bold text-center mb-4">Children</h2>
        <div className="font-size-24 text-center mb-6">
          It's time to add children to your {fieldLabels.center.toLowerCase()}!
        </div>
        <Card>
          <div className="d-flex flex-column flex-sm-row">
            <div className="d-flex flex-grow-1 justify-content-center align-items-center mb-4 mb-sm-0">
              <img src={bitmap} alt="child riding bicycle" className="img-fluid" />
            </div>
            <div className="d-flex flex-grow-1 justify-content-center align-items-center">
              {userCanCreateChild && <CreateButton onClick={onButtonClick}>Add a Child</CreateButton>}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default InitialEmptyChildrenTable;
