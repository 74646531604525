import React, { useState } from 'react';
import PageWrapper from 'shared/components/PageWrapper';
import { CreateButton, CirclePlusButton } from 'shared/components/Buttons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { Row, Col } from 'shared/components/Layout';
import WeekView from './components/WeeklySection/WeekView';
import MonthView from './components/MonthlySection/MonthView';
import SearchAndFilterBar from './components/SearchAndFilterBar';
import ReviewTimeOffModal from './components/Modals/ReviewTimeOffModal';
import { useGetMyTimeOff } from './grapgql/queries';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import Spinner from 'shared/components/Spinner';
import AddTimeOffModal from './components/Modals/AddTimeOffModal';

const MyTimeOff: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const [date, updateDate] = useState<string>(moment().toISOString());
  const [isAddTimeOffModalOpen, setAddTimeOffModalOpen] = useState(false);
  const [selectedTimeOff, setSelectedTimeOff] = useState<ITimeOff | null>(null);
  const { loading, networkStatus } = useGetMyTimeOff({
    startTime: moment(date).startOf('M').startOf('W').toISOString(),
    endTime: moment(date).endOf('M').endOf('W').toISOString(),
  });
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpFeature = Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.subFeatures ?? undefined;
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;
  const AdpTimeEnabled = AdpFeature?.find((x) => x.type === 'TimeAndAttendance')?.enabled ?? false;
  const [filter, updateFilter] = useState({ status: 'All' });

  const timeOffRequests =
    useSelector((state: RootState) => state.timeOff.all).filter(
      (t) => t.personId === user?.id && (filter.status === 'All' || filter.status === t.status)
    ) || [];

  return (
    <PageWrapper
      pageTitle="My Time Off"
      mobileButtonComponent={
        !AdpTimeEnabled && (
          <CirclePlusButton
            variant="primary"
            className="mt-4 mb-4"
            onClick={() => {
              setAddTimeOffModalOpen(true);
            }}
          />
        )
      }
      buttonComponent={
        !AdpTimeEnabled && (
          <CreateButton
            onClick={() => {
              setAddTimeOffModalOpen(true);
            }}
          >
            Request Time Off
          </CreateButton>
        )
      }
    >
      <SearchAndFilterBar hideSearch={true} filter={filter} updateFilter={updateFilter} />
      {loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES ? (
        <div className="d-flex h-100">
          <Spinner large className="mx-auto my-auto" />{' '}
        </div>
      ) : (
        <Row align="stretch">
          <Col sm={12} md={4} className="mb-2">
            <WeekView
              date={date}
              updateDate={updateDate}
              timeOffRequests={timeOffRequests}
              setSelectedTimeOff={setSelectedTimeOff}
            />
          </Col>
          <Col sm={12} md={8} className="mb-2">
            <MonthView
              date={date}
              updateDate={updateDate}
              timeOffRequests={timeOffRequests}
              setSelectedTimeOff={setSelectedTimeOff}
            />
          </Col>
        </Row>
      )}
      {user && (
        <AddTimeOffModal
          isOpen={isAddTimeOffModalOpen}
          onClose={() => setAddTimeOffModalOpen(false)}
          centerId={user.primaryCenterId}
          entityId={user?.entityId}
          isRequesting={true}
          user={user}
        />
      )}
      {selectedTimeOff && (
        <ReviewTimeOffModal
          isOpen={Boolean(selectedTimeOff)}
          onClose={() => setSelectedTimeOff(null)}
          timeOff={selectedTimeOff}
          isMyTimeOff={true}
        />
      )}
    </PageWrapper>
  );
};

export default MyTimeOff;
