import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  centerName: string;
}

const RequestToJoinSuccessModal: React.FC<IProps> = ({ isOpen, onClose, centerName, ...props }) => {
  return (
    <Modal centered backdrop="static" show={isOpen} onHide={onClose}>
      <Modal.Header closeButton className="border-bottom-0 pb-0" />
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          <FontAwesomeIcon icon={faCheckCircle} size="4x" color={colors.success} className="mb-4" />
          <h4 className="text-success mb-4">Success</h4>
          <span className="text-center mb-8">
            Your request to join {centerName} has been sent! You will receive an email with next steps as soon as it is
            approved.
          </span>
          <Button onClick={onClose}>Close</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RequestToJoinSuccessModal;
