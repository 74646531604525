import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { applicationCenterSettingsFields } from './fields';

export const GET_CENTER_APPLICATION_SETTINGS = (fields: string = applicationCenterSettingsFields) => gql`
  query ($input: GetAppllicationCenterSettingsInput!) {
    getApplicationCenterSettings(input: $input) {
      ${fields}
    }
  }
`;

interface ICenterSettings {
  businessId: string;
  centerId: string;
  centerName: string;
  feeType?: FeeType | undefined;
  feeAmount?: number;
  askFamilyAboutSubsidy?: boolean | undefined;
}

interface IGetCenterSettings {
  data: ICenterSettings[];
}

interface IGetCenterApplicationSetting {
  getApplicationCenterSettings: IGetCenterSettings;
}

interface IGetApplicationCenterSettingsVariables {
  input: {
    businessId: string;
  };
}

export const useGetApplicationCentersSettings = (
  options?: QueryHookOptions<IGetCenterApplicationSetting, IGetApplicationCenterSettingsVariables>,
  fields?: string
) =>
  useQuery<IGetCenterApplicationSetting, IGetApplicationCenterSettingsVariables>(
    GET_CENTER_APPLICATION_SETTINGS(fields),
    options
  );
