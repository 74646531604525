import Card from 'shared/components/Card';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  bulkTransaction: IBulkTransaction;
  transactionTypeOptions: ITransactionType[];
  transactionTypeLoading: boolean;
  appliesTo: string;
  mustApplyChildren: boolean;
  onChange: (bulkTransactionUpdates: Partial<IBulkTransaction>) => void;
  onAppliesToChange: (appliesTo: 'Account' | 'Children') => void;
}

const TransactionDetails: React.FC<IProps> = ({
  bulkTransaction,
  transactionTypeOptions,
  transactionTypeLoading,
  appliesTo,
  mustApplyChildren,
  onChange,
  onAppliesToChange,
}) => {
  const { t } = useTranslation();
  const [formIsDirty, setFormIsDirty] = useState(false);

  return (
    <Card header={<div className="d-flex flex-row align-items-center">Transaction Details</div>}>
      <FormWrapper2 formIsDirty={formIsDirty} toggleDirty={setFormIsDirty}>
        <Row align="end">
          <Col xs={12} sm={4} className="order-1">
            <TextInput
              name="name"
              label="Name / Description"
              value={bulkTransaction.description}
              onChange={(value) => onChange({ description: value })}
              required
            />
          </Col>
          <Col xs={12} sm={4} className="order-2">
            <Select
              required
              label="Type"
              value={bulkTransaction.transactionType}
              options={orderBy(
                (transactionTypeOptions ?? []).filter((t) => t.archivedAt === null && t.metadata.isWritable),
                (t) => t.name,
                'asc'
              )}
              isLoading={transactionTypeLoading}
              getOptionLabel={(option: ITransactionType) => `${option.name} (${option.isCredit ? '+' : '-'})`}
              getOptionValue={(option: ITransactionType) => option.id}
              onChange={(option: any) => {
                onChange({ transactionTypeId: option.id, transactionType: option });
              }}
              noOptionsMessage={() => {
                return 'No transaction types found.';
              }}
            />
          </Col>
          <Col xs={12} sm={2} className="order-3">
            <Form.Group>
              <DateInput
                required
                label="Apply Date"
                date={bulkTransaction.date}
                onDateSelect={(date) => onChange({ date })}
                placeholder={t('formatters.date')}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={2} className="order-4">
            <CurrencyInput2
              className="border-radius-0"
              required
              label="Amount"
              value={bulkTransaction.amount}
              onChange={(value) => onChange({ amount: value })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="order-5">
            <Form.Group>
              <div className="d-flex flex-row">
                <Form.Label>Apply To</Form.Label>
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              </div>
              <Form.Check
                disabled={mustApplyChildren}
                inline
                label={'Account'}
                name="applies-to"
                type="radio"
                checked={appliesTo === 'Account'}
                onChange={() => onAppliesToChange('Account')}
              />
              <Form.Check
                inline
                label={'Children'}
                name="applies-to"
                type="radio"
                checked={appliesTo === 'Children'}
                onChange={() => onAppliesToChange('Children')}
              />
            </Form.Group>
          </Col>
        </Row>
      </FormWrapper2>
    </Card>
  );
};

export default TransactionDetails;
