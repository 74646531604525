import React, { useState, useCallback } from 'react';
import { orderBy, debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import DataTable, { TableHeader, TableSearch, TABLE_DEFAULTS } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { STATES, US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { useGetEntity } from 'gql/business/queries';
import AgencyTableExpandedRow from './AgencyTableExpandedRow';
import { parsePhoneNumberWithRegion } from 'shared/util/string';
import { useGetPaginatedAgenciesForBusiness } from 'gql/agency/queries';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { getAgenciesTableData } from './duck/actions';
import Switch from 'shared/components/Switch';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IAgencyTableFilters {
  term: string;
  centerIds: string[];
  states: string[];
  sortField: string;
  sortDirection: 'asc' | 'desc';
  showArchived: boolean;
}

interface IProps {
  businessId: string;
  onEditAgency: (agency: IAgency) => void;
  onArchiveAgency: (agency: IAgency) => void;
  onViewAgencyChildren: (agency: IAgency) => void;
}

const AgenciesTable: React.FC<IProps> = ({
  businessId,
  onEditAgency,
  onArchiveAgency,
  onViewAgencyChildren,
  ...props
}) => {
  const { t } = useTranslation(['subsidies']);
  const dispatch = useDispatch();
  const tableData = useSelector((state: RootState) => state.billing.agency.tableData);

  const [tableFilters, setTableFilters] = useState<IAgencyTableFilters>({
    term: '',
    centerIds: [],
    states: [],
    sortField: 'name',
    sortDirection: 'asc',
    showArchived: false,
  });
  const [tableState, tableFunctions] = useDatatableState();

  const { data: getEntityData } = useGetEntity(
    {
      variables: {
        id: businessId,
      },
      skip: !businessId,
    },
    `id centers { id name address { state } }`
  );

  const { data: paginatedAgenciesData, loading: paginatedAgenciesLoading } = useGetPaginatedAgenciesForBusiness({
    variables: {
      input: {
        businessId,
        searchKey: tableFilters.term,
        sortBy: tableFilters.sortField,
        sortDirection: tableFilters.sortDirection,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        centerIds: tableFilters.centerIds,
        states: tableFilters.states,
        counties: [],
        showArchived: tableFilters.showArchived,
      },
    },
    fetchPolicy: 'network-only',
    skip: !businessId,
    onCompleted: (result) => {
      dispatch(getAgenciesTableData(result.getPaginatedAgenciesForBusiness.data));
    },
  });

  const centerSelectOptions = orderBy(getEntityData?.getEntity.centers ?? [], (center) => center.name, 'asc');

  const handleSearchTerm = debounce(
    useCallback(
      (term: string) => {
        tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
        setTableFilters((prev) => ({ ...prev, term }));
      },
      [tableFunctions]
    ),
    250
  );

  const handleFilterSelect = useCallback(
    (filters: string[], key: 'centerIds' | 'states') => {
      tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
      setTableFilters((prev) => ({ ...prev, [key]: filters }));
    },
    [tableFunctions]
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <DataTable
      data={tableData}
      dataSize={paginatedAgenciesData?.getPaginatedAgenciesForBusiness.totalRecords ?? 0}
      showSelect={false}
      showLoadingOverlay={paginatedAgenciesLoading}
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      onSort={(field, direction) =>
        setTableFilters((prev) => ({
          ...prev,
          sortField: field,
          sortDirection: direction === 'ASCENDING' ? 'asc' : 'desc',
        }))
      }
      expandRow={(row) => (
        <AgencyTableExpandedRow
          row={row}
          onEdit={onEditAgency}
          onArchive={onArchiveAgency}
          onViewChildren={onViewAgencyChildren}
        />
      )}
      className="kt-agencies-table"
      columns={[
        {
          text: t('subsidies:agencies.data-table.name-column'),
          dataField: 'name',
          sort: true,
        },
        {
          text: t('subsidies:agencies.data-table.state-column'),
          dataField: 'state',
          sort: true,
          formatter: (cell: string, row: IAgency) => STATES[row.state] ?? '',
        },
        {
          text: t('subsidies:agencies.data-table.county-column'),
          dataField: 'county',
          sort: true,
        },
        {
          text: t('subsidies:agencies.data-table.phone-column'),
          dataField: 'phoneNumber',
          formatter: (cell: string, row: IAgency) => {
            let str = '';
            try {
              const phoneNumber = parsePhoneNumberWithRegion(row.phoneNumber ?? '');
              str = phoneNumber.formatNational();
            } catch (error) {
              // could throw ParseError
            }

            return str;
          },
        },
      ]}
      renderHeader={(paginationProps, searchProps) => (
        <>
          <div
            className={isMobile ? 'd-flex justify-content-center align-items-center' : 'align-items-center flex-row'}
          >
            <TableHeader
              className={isMobile ? 'd-flex justify-content-center align-items-center flex-wrap' : 'flex-nowrap'}
            >
              <TableSearch placeholder="Search" className={isMobile ? 'mb-2' : 'mr-auto'} onChange={handleSearchTerm} />
              <DropdownFilter
                title={t('subsidies:agencies.data-table.center-dropdown')}
                className="mr-4"
                selectedFilters={tableFilters.centerIds}
                options={centerSelectOptions
                  .map((c) => ({ label: c.name, value: c.id }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                onFilterSelect={(centers) =>
                  handleFilterSelect(
                    centers.map((c) => c.value),
                    'centerIds'
                  )
                }
              />
              <DropdownFilter
                title={t('subsidies:agencies.data-table.state-dropdown')}
                selectedFilters={tableFilters.states}
                options={US_STATE_SELECT_OPTIONS} // US agency billing only requires us to show US states
                onFilterSelect={(states) =>
                  handleFilterSelect(
                    states.map((c) => c.value),
                    'states'
                  )
                }
              />
            </TableHeader>
          </div>
          <div
            className={
              isMobile
                ? 'd-flex justify-content-center align-items-center'
                : 'd-flex align-items-center justify-content-end px-8 pt-4 pb-1'
            }
          >
            <Switch
              value={tableFilters.showArchived}
              onChange={(checked) => setTableFilters((prev) => ({ ...prev, showArchived: checked }))}
              label={t('subsidies:agencies.data-table.show-archived-toggle')}
              labelSide="left"
            />
          </div>
        </>
      )}
    />
  );
};

export default AgenciesTable;
