import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'shared/components/Select';
import { ICustomFieldProps } from '../CustomField';
import { parseOptions } from '../utils';
import { Option } from 'react-select/src/filters';

const Combo: React.FC<ICustomFieldProps> = ({ disabled, isReadOnly, customField, value, onChange }) => {
  const isMulti = useMemo(() => customField.type === 'MultiSelectCombo', [customField.type]);
  const handleSelect = useCallback(
    (option: any) => {
      if (isMulti) {
        onChange((option ?? ([] as Option[])).map((o: Option) => o.value).join(','), customField.id);
      } else {
        onChange(option, customField.id);
      }
    },
    [value]
  );

  let parseOptions1 = parseOptions(customField.attributesAsString);
  const options = isMulti ? parseOptions1.map((o) => ({ label: o, value: o })) : parseOptions1;
  return (
    <div className="mb-2">
      <Form.Label>{customField.label}</Form.Label>
      <Select
        options={options}
        onChange={handleSelect}
        value={
          isMulti
            ? value
                ?.split(',')
                .map((o: string) => _.find(options, (option: { label: string; value: string }) => option.value === o))
            : value
        }
        disabled={disabled || isReadOnly}
        isMulti={isMulti}
      />
    </div>
  );
};
export default Combo;
