import React from 'react';
import { addressFields } from 'gql/address/fields';
import * as Mui from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { IconButtonCircle } from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isRegion } from 'shared/util/region';
import { useTranslation } from 'react-i18next';

export const CENTERS_FIELDS: string = `
  id
  entity {
    id
    name
  }
  name
  tags {
    id
    name
    category
  }
  avatar {
    url
  }
  address {
    ${addressFields}
  }
  timezone
  email
  externalId
`;

interface IProps {
  index: number;
  // row: ITransaction;
  changeRowsPerPage: (val: number) => void;
  changeCenterSearchText: (val: string) => void;
  changeIsTuition: (val: boolean | null) => void;
  changeSelectedCenters: (val: ICenter[]) => void;
  changeArchivedSwitch: (val: boolean) => void;
  centers: ICenter[] | undefined;
}

const BillingTransactionsFilters: React.FC<IProps> = ({
  index,
  changeRowsPerPage,
  changeCenterSearchText,
  changeIsTuition,
  changeSelectedCenters,
  changeArchivedSwitch,
  centers,
  ...props
}) => {
  const { t } = useTranslation();
  const [isTuition, setIsTuition] = React.useState<ITableFilterOption[]>();
  const [isTuitionText, setIsTuitionText] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [centerSearchText, setCenterSearchText] = React.useState('');
  const [selectedCenters, setSelectedCenters] = React.useState<ICenter[] | [] | null>([]);
  const [archivedSwitch, setArchivedSwitch] = React.useState(false);
  const theme = useTheme();
  const isMobile = Mui.useMediaQuery(theme.breakpoints.down('lg'));

  const clearFilters = () => {
    setCenterSearchText('');
    setIsTuition([]);
    setSelectedCenters([]);
  };

  React.useEffect(() => {
    changeRowsPerPage(rowsPerPage);
  }, [rowsPerPage]);

  React.useEffect(() => {
    changeCenterSearchText(centerSearchText);
  }, [centerSearchText]);

  React.useEffect(() => {
    if (isTuitionText === 'Yes') {
      changeIsTuition(true);
    } else if (isTuitionText === 'No') {
      changeIsTuition(false);
    } else {
      changeIsTuition(null);
    }
  }, [isTuitionText]);

  React.useEffect(() => {
    changeSelectedCenters(selectedCenters ?? []);
  }, [selectedCenters]);

  React.useEffect(() => {
    changeArchivedSwitch(archivedSwitch);
  }, [archivedSwitch]);

  React.useEffect(() => {
    if (isTuition === undefined || isTuition?.length > 1 || isTuition?.length === 0) {
      changeIsTuition(null);
    } else if (isTuition?.length === 1) {
      changeIsTuition(isTuition[0].value === 'Yes' ? true : false);
    }
  }, [isTuition]);

  return (
    <>
      <Mui.Box sx={{ width: '100%', background: 'white' }}>
        <Mui.CardContent
          sx={{
            '&:last-child': {
              paddingBottom: '16px',
            },
          }}
        >
          <Mui.Grid container spacing={2}>
            <Mui.Grid
              item
              md={12}
              lg={6}
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
            >
              <Mui.Typography sx={{ marginLeft: '8px' }}>Show</Mui.Typography>
              <Mui.Select
                sx={{
                  '& legend': {
                    width: 0,
                  },
                  marginLeft: '8px',
                }}
                inputProps={{
                  'aria-label': 'show number transaction types',
                }}
                value={rowsPerPage.toString()}
                onChange={(evt) => setRowsPerPage(parseFloat(evt.target.value))}
                size={'small'}
              >
                <Mui.MenuItem value={10}>10</Mui.MenuItem>
                <Mui.MenuItem value={25}>25</Mui.MenuItem>
                <Mui.MenuItem value={50}>50</Mui.MenuItem>
              </Mui.Select>
              <Mui.TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Search"
                value={centerSearchText}
                onChange={(evt) => setCenterSearchText(evt.target.value)}
                size="small"
                sx={{
                  minWidth: '200px',
                  '& legend': {
                    width: 0,
                  },
                  marginLeft: '8px',
                }}
                InputProps={{
                  startAdornment: (
                    <Mui.InputAdornment position="start">
                      <FontAwesomeIcon icon={faSearch} />
                    </Mui.InputAdornment>
                  ),
                }}
              />
            </Mui.Grid>
            <Mui.Grid
              item
              md={12}
              lg={6}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isMobile ? 'flex-start' : 'flex-end',
                alignItems: 'center',
              }}
            >
              {isRegion('US') && (
                <DropdownFilter
                  title="Tuition"
                  className="mr-4"
                  selectedFilters={isTuition}
                  options={[
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' },
                  ]}
                  onFilterSelect={(option) => setIsTuition(option)}
                />
              )}
              <SelectMultiple
                onChange={(prev) => setSelectedCenters(prev)}
                options={centers ?? []}
                value={selectedCenters}
                getOptionLabel={(option: ICenter) => option.name}
                getOptionValue={(option: ICenter) => option.id}
                displayCountInContainerRenderer={(selectedCount) =>
                  selectedCount > 0
                    ? `${t('spelling.center-with-count', { count: selectedCount })} Selected`
                    : `Select a ${t('spelling.capitalCenter')}`
                }
                className="mt-4"
              />

              <Mui.Box sx={{ marginLeft: '8px' }}>
                <IconButtonCircle icon={faTimes} onClick={(evt) => clearFilters()} tooltipText="Clear Filters" />
              </Mui.Box>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.CardContent>
      </Mui.Box>
      <Mui.Box sx={{ m: 1 }} display="flex" justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
        <Mui.FormControlLabel
          value="ArchivedSwitch"
          control={<Mui.Switch color="success" onChange={(e) => setArchivedSwitch(e.target.checked)} />}
          label="Show Archived"
          labelPlacement="start"
          checked={archivedSwitch}
          sx={{
            marginTop: '8px',
          }}
        />
      </Mui.Box>
    </>
  );
};

export default BillingTransactionsFilters;
