import { useDeleteRole } from 'gql/role/mutations';
import React, { useCallback } from 'react';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isOpen: boolean;
  businessId: string;
  role: IRole | null;
  onClose: () => void;
  onRoleDelete: (role: IRole) => void;
}

const DeleteRoleModal: React.FC<IProps> = ({ isOpen, businessId, role, onClose, onRoleDelete, ...props }) => {
  const [deleteRoleFn, { loading: deleteRoleLoading }] = useDeleteRole({
    onCompleted: (result) => {
      showToast('Role deleted successfully.', 'success');
      onRoleDelete(result.deleteRole);
      handleClose();
    },
    onError: (err) => {
      showToast('There was an error trying to delete this role. Please try again later.', 'error');
    },
  });

  const handleSubmit = useCallback(() => {
    deleteRoleFn({
      variables: {
        input: {
          businessId,
          roleId: role?.id || '',
        },
      },
    });
  }, [role, businessId, deleteRoleFn]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <CenteredModal
      title="Delete Role"
      show={isOpen}
      onHide={handleClose}
      primaryCallback={handleSubmit}
      secondaryCallback={handleClose}
      primaryChoice="Delete"
      primaryButtonProps={{
        disabled: deleteRoleLoading,
        loading: deleteRoleLoading,
      }}
      primaryButtonVariant="danger"
    >
      Are you sure you sure you want to delete this role?
    </CenteredModal>
  );
};

export default DeleteRoleModal;
