import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import Switch from 'shared/components/Switch';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { showToast } from 'shared/components/Toast';
import { useUpdateGallerySettings } from 'pages/Photos/subroutes/PhotoSettings/gql/mutations';
import { useGetCenterGallerySettingsWithLoading } from './gql/queries';

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const PhotoSettings: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const { data: centerData, loading: centersLoading } = useGetCenterGallerySettingsWithLoading();

  const [centers, updateCenters] = useState<ICenter[]>(centerData ?? []);

  useEffect(() => {
    updateCenters(centerData ?? []);
  }, [centerData]);

  const [updateCenterSettingsFn] = useUpdateGallerySettings({
    onCompleted: () => {
      showToast('Center Updated Successfully', 'success');
    },
    onError: () => {
      showToast('Error updating center', 'error');
    },
  });

  return (
    <PageWrapper pageTitle="Photo Settings" applyPadding={true}>
      <DataTable
        data={centers ?? []}
        showSelect={false}
        showPagination={false}
        showLoadingOverlay={centersLoading}
        columns={[
          {
            text: `${capitalize(t('spelling.center'))} Name`,
            dataField: 'name',
            sort: true,
            formatter: (cell: string, row: ICenter) => (
              <AvatarDataTableCell
                initials={row.name && row.name[0].toUpperCase()}
                avatar={row.avatar && row.avatar.url}
                primaryText={row.name}
              />
            ),
          },
          {
            text: `Auto Approval`,
            dataField: 'approval',
            formatter: (cell: string, row: ICenter) => (
              <Switch
                checkedText="On"
                uncheckedText="Off"
                value={row.gallerySettings?.autoApprove ?? false}
                onChange={(checked) => {
                  updateCenters(
                    centers.map((c) =>
                      c.id === row.id
                        ? {
                            ...c,
                            gallerySettings: {
                              autoApprove: checked,
                              centerId: row.id,
                            },
                          }
                        : c
                    )
                  );
                  updateCenterSettingsFn({
                    variables: {
                      input: {
                        centerId: row.id,
                        businessId: row.entityId,
                        autoApprove: checked,
                        releaseOnCheckout: false,
                      },
                    },
                  });
                }}
              />
            ),
          },
        ]}
      />
    </PageWrapper>
  );
};

export default PhotoSettings;
