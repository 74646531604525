import React, { useCallback, useContext, useMemo, useState } from 'react';
import DataTable from 'shared/components/DataTable';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import {
  Application,
  ApplicationChild,
  ApplicationFlowType,
  ApplicationScheduleOffer,
  ApplicationScheduleOfferGenerationState,
  ApplicationScheduleOfferState,
  ApplicationStage,
  EnrolmentSource,
  UserFlag,
} from 'generated/graphql';
import moment from 'moment';
import LeadTableHeader from '../LeadTableHeader';
import LeadDetails from '../LeadDetails';
import { useLeadsContext } from '../../LeadsContext';
import {
  NEW_TAB_FOR_NORMAL_FLOW,
  STAGE_TO_ASO_STATE_MAPPING_FOR_NORMAL_FLOW,
  STAGE_TO_ASO_STATE_MAPPING_FOR_PROGRAM_FLOW,
} from '../../types';
import BulkActions from '../BulkActions';
import './leadTable.scss';
import {
  checkIsLostApplicationWithoutOffer,
  checkIsNewApplication,
  getCareTypeNamesMatchAsoState,
  getChildrenNamesMatchAsoState,
  getProgramNamesMatchAsoState,
  isProgramBasedFlow,
} from '../../utils';
import { capitalize } from 'lodash';
import { useGetApplicationCentersSettings } from 'gql/applicationCenterSettings/queries';
import _ from 'lodash';
import { IApplication } from 'shared/types/application';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';
import { ApplicationContext } from 'shared/contexts/ApplicationContext';
import { showToast } from 'shared/components/Toast';
import AccountLink from 'shared/components/AccountLink/AccountLink';
import ActionDropdown from 'shared/components/ActionDropdown';
import { Badge } from 'react-bootstrap';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ColoredBackgroundTag } from 'shared/components/Tag';

const useGetSubsidySettingForCentre = (centreIds: string[]) => {
  const user = useSelector((state: RootState) => state.user);

  const [centreSettings, setCentreSettings] = useState<Record<string, string>>({});

  const isCentreUseSubsidySetting = useCallback(
    (centreId: string): boolean => {
      return Boolean(centreSettings[centreId]);
    },
    [centreSettings]
  );

  const businessId = user?.entityId ?? '';
  const { loading: getCenterListLoading } = useGetApplicationCentersSettings({
    variables: {
      input: {
        businessId,
      },
    },
    onCompleted: (result) => {
      if (result.getApplicationCenterSettings) {
        const records = {};
        result.getApplicationCenterSettings.data.forEach((o) => (records[o.centerId] = o.askFamilyAboutSubsidy));
        setCentreSettings(records);
      }
    },
  });

  const displaySubsidyColumn = useMemo(() => {
    return _.some(centreIds, (o) => centreSettings[o]) ?? false;
  }, [centreIds, centreSettings]);

  return {
    displaySubsidyColumn,
    isCentreUseSubsidySetting,
  };
};

const LeadTable: React.FC = () => {
  const { k2EnrolFormDifference } = useFlags();
  const { t } = useTranslation(['translation', 'enrollment']);
  const centersById = useSelector((state: RootState) => state.centers.byId);
  const { isSupportMode } = useContext(ApplicationContext);

  const {
    tableState,
    tableFunctions,
    applicationsData,
    getApplicationsQueryLoading,
    handleSort,
    applicationFlowType,
    activeTab,
    markAsRead,
    setIsBulkApproveModalOpen,
    centerIds,
    setNoteModalState,
  } = useLeadsContext();

  const showPaidColumn = useMemo(() => {
    return activeTab === ApplicationStage.InProgress;
  }, [activeTab]);

  const timezoneByCenterId = useSelector((state: RootState) => state.timezone.byCenterId);

  const activeTabIsNewTab =
    applicationFlowType === ApplicationFlowType.InquireOfferEnrollment ? activeTab === NEW_TAB_FOR_NORMAL_FLOW : false;

  const { displaySubsidyColumn, isCentreUseSubsidySetting } = useGetSubsidySettingForCentre(centerIds);

  const expandRow = useCallback(
    (row: Application) => (
      <LeadDetails
        application={row}
        applicationFlowType={applicationFlowType}
        applicationStage={activeTab as ApplicationStage}
      />
    ),
    [applicationFlowType, activeTab]
  );
  const renderPaidColumn = (application: Application) => {
    const allChargeableScheduleOffers: ApplicationScheduleOffer[] = [];
    application.children.forEach((child) => {
      child.offers.forEach((offer) => {
        if (
          offer &&
          offer.state !== ApplicationScheduleOfferState.OfferedWaitlist &&
          offer.state !== ApplicationScheduleOfferState.AcceptedWaitlist
        ) {
          allChargeableScheduleOffers.push(offer);
        }
      });
    });

    if (allChargeableScheduleOffers.length === 0) {
      return (
        <>
          N/A
          <span>
            <HelpTooltip text={'This offer will be waitlisted'} direction="top" />
          </span>
        </>
      );
    }

    return (application.offers?.[0]?.applicationPaidAmount ?? 0) > 0 ? <>Paid</> : <>Not Paid</>;
  };

  const onExpand = useCallback(
    (row: Application, isExpand: boolean) => {
      if (isExpand && (row.userFlag === null || row.userFlag === UserFlag.New) && activeTabIsNewTab) {
        setTimeout(() => {
          markAsRead(row);
        }, 0);
      }
    },
    [markAsRead, activeTabIsNewTab]
  );

  const getTableHeader = useCallback(
    (paginationProps) => {
      if (tableState.selectedRows.length) {
        return (
          <BulkActions
            selectedApplications={tableState.selectedRows}
            openBulkApproveModal={() => setIsBulkApproveModalOpen(true)}
          />
        );
      }

      return <LeadTableHeader paginationProps={paginationProps} />;
    },
    [setIsBulkApproveModalOpen, tableState.selectedRows]
  );

  const STAGE_TO_ASO_STATE_MAPPING =
    applicationFlowType === ApplicationFlowType.InquireOfferEnrollment
      ? STAGE_TO_ASO_STATE_MAPPING_FOR_NORMAL_FLOW
      : STAGE_TO_ASO_STATE_MAPPING_FOR_PROGRAM_FLOW;

  const targetedAsoStates = STAGE_TO_ASO_STATE_MAPPING[activeTab as ApplicationStage];

  const isNewApplication = checkIsNewApplication(activeTab as ApplicationStage, applicationFlowType);

  const getErrorMessage = (state: ApplicationScheduleOfferGenerationState) => {
    const prefix = `Failed to generate`;

    switch (state) {
      case ApplicationScheduleOfferGenerationState.GenerateAccount:
        return `${prefix} Account`;
      case ApplicationScheduleOfferGenerationState.GeneratePrimaryContacts:
        return `${prefix} Primary Contacts`;
      case ApplicationScheduleOfferGenerationState.GenerateContacts:
        return `${prefix} Contacts`;
      case ApplicationScheduleOfferGenerationState.GenerateContracts:
        return `${prefix} Contracts`;
      case ApplicationScheduleOfferGenerationState.GenerateAuthorizations:
        return `${prefix} ${t('translation:spelling.authorizations', 'capitalize')}`;
      case ApplicationScheduleOfferGenerationState.GenerateMedicalDocuments:
        return `${prefix} Medical Documents`;
      case ApplicationScheduleOfferGenerationState.GeneratePaymentMethod:
        return `${prefix} Payment Method`;
      case ApplicationScheduleOfferGenerationState.GenerateCustomFields:
        return `${prefix} Custom Fields`;
      case ApplicationScheduleOfferGenerationState.GenerateCustomDocumentFields:
        return `${prefix} Custom Document Fields`;
      case ApplicationScheduleOfferGenerationState.GenerateAdminFeeTransactions:
        return `${prefix} Admin Fee Transactions`;
      case ApplicationScheduleOfferGenerationState.GeneratePdfForm:
        return `${prefix} PDF Form`;
      case ApplicationScheduleOfferGenerationState.GenerateImmunizationDocument:
        return `${prefix} ${t('translation:spelling.immunization', 'capitalize')} document`;
      case ApplicationScheduleOfferGenerationState.GenerateBillingCycle:
        return `${prefix} Billing Cycle`;
      case ApplicationScheduleOfferGenerationState.GenerateSystemFields:
        return `${prefix} System Fields`;
      default:
        return '';
    }
  };

  const offerErrorStage = (children: ApplicationChild[] | undefined) => {
    if (children) {
      const erroredApplication = children.find((child) => {
        return child.offers.find((offer) => {
          return offer?.state === ApplicationScheduleOfferState.Errored && offer?.generationState;
        });
      });

      const erroredOffer = erroredApplication?.offers.find((offer) => {
        return offer?.state === ApplicationScheduleOfferState.Errored && offer?.generationState;
      });

      if (erroredOffer?.generationState) {
        return getErrorMessage(erroredOffer.generationState);
      }
    }

    return 'An error occured processing the Application';
  };

  return (
    <DataTable
      data={applicationsData?.getApplicationsByStage.data ?? []}
      className="lead-table"
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      dataSize={applicationsData?.getApplicationsByStage.totalRecords ?? 0}
      noPadding
      onPageChange={tableFunctions.changePage}
      showLoadingOverlay={getApplicationsQueryLoading}
      showSelect={
        isProgramBasedFlow(applicationFlowType) &&
        (activeTab === ApplicationStage.Accepted || activeTab === ApplicationStage.Waitlisted)
      }
      selectedRows={tableState.selectedRows}
      updateSelectedRows={tableFunctions.updateSelectedRows}
      onlyOneExpanding
      expandHeaderColumnRenderer={(props) => <></>}
      expandRow={expandRow}
      renderHeader={getTableHeader}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      onExpand={onExpand}
      onSort={handleSort as ((field: string, direction: ElasticsearchSortDirection) => void) | undefined}
      rowClasses={(row: Application) => (activeTabIsNewTab && row.userFlag !== UserFlag.Read ? 'unread' : 'read')}
      columns={[
        isSupportMode && {
          text: 'ApplicationId',
          sort: false,
          dataField: 'id',
          formatter: (cell: string, row: IApplication) => {
            return (
              <a
                className=""
                onClick={async (e) => {
                  e.stopPropagation();
                  if (navigator?.clipboard) {
                    await navigator.clipboard.writeText(row.id);
                    showToast('ApplicationId copied to clipboard', 'info');
                  }
                }}
              >
                Copy ID
              </a>
            );
          },
        },
        {
          text: capitalize(t('translation:spelling.inquiry')),
          sort: true,
          dataField: 'enquiryDate',
          formatter: (cell: string, row: IApplication) => {
            const centerId = row.centers[0];
            const timezone = timezoneByCenterId[centerId] ?? moment.tz.guess();
            const timeInTimezone = moment(cell).tz(timezone);
            return (
              <div>
                <div>{timeInTimezone.format('MMM D, YYYY')}</div>
                <div>{timeInTimezone.format('@ LT')}</div>
              </div>
            );
          },
        },
        {
          text: `${capitalize(t('translation:spelling.inquiry'))} Type`,
          sort: true,
          dataField: 'enrollmentSource',
          formatter: (cell: string, row: IApplication) => {
            return t(
              `enrollment:enrollmentSourceOptions.${
                !(row.enrollmentSource === null || row.enrollmentSource === undefined)
                  ? (row.enrollmentSource as EnrolmentSource)
                  : EnrolmentSource.Inquiry
              }`
            );
          },
        },
        {
          text: capitalize(t('translation:spelling.center')),
          sort: true,
          dataField: 'centerName',
          formatter: (cell: string, row: Application) => (
            <ul className="center-names">
              {row.centers.map((centerId) => (
                <li key={centerId}>{Boolean(centersById) ? centersById[centerId]?.name : ''} </li>
              ))}
            </ul>
          ),
        },
        {
          text: 'Child(ren)',
          dataField: 'children',
          sort: true,
          formatter: (_: any, row: Application) => {
            const isLostApplicationWithoutOffer = checkIsLostApplicationWithoutOffer(
              activeTab as ApplicationStage,
              row
            );
            return (
              <ul className="children-names">
                {getChildrenNamesMatchAsoState(
                  row.children,
                  targetedAsoStates,
                  isNewApplication,
                  isLostApplicationWithoutOffer
                )?.[0].map((childFullName, index) => (
                  <li key={index}>{childFullName}</li>
                ))}
              </ul>
            );
          },
        },
        {
          text: t('translation:enrollment.lead-management.DOB'),
          dataField: 'children',
          sort: false,
          formatter: (_: any, row: Application) => {
            const isLostApplicationWithoutOffer = checkIsLostApplicationWithoutOffer(
              activeTab as ApplicationStage,
              row
            );
            return (
              <ul className="children-names">
                {getChildrenNamesMatchAsoState(
                  row.children,
                  targetedAsoStates,
                  isNewApplication,
                  isLostApplicationWithoutOffer
                )?.[1].map((dob, index) => (
                  <li key={index}>{moment.utc(dob).format(t('translation:formatters.MMM D, YYYY'))}</li>
                ))}
              </ul>
            );
          },
        },
        ...(isProgramBasedFlow(applicationFlowType)
          ? [
              {
                text: 'Program',
                dataField: '',
                sort: false,
                formatter: (_: any, row: Application) => (
                  <ul className="program-names">
                    {getProgramNamesMatchAsoState(row.children, targetedAsoStates, isNewApplication).map(
                      (programName, index) => (
                        <li key={index}>{programName}</li>
                      )
                    )}
                  </ul>
                ),
              },
            ]
          : []),
        ...(applicationFlowType === ApplicationFlowType.InquireOfferEnrollment
          ? [
              {
                text: 'Care Type',
                dataField: '',
                sort: false,
                formatter: (_: any, row: Application) => {
                  const isLostApplicationWithoutOffer = checkIsLostApplicationWithoutOffer(
                    activeTab as ApplicationStage,
                    row
                  );
                  return (
                    <ul className="program-names">
                      {getCareTypeNamesMatchAsoState(
                        row.children,
                        targetedAsoStates,
                        isNewApplication,
                        isLostApplicationWithoutOffer
                      ).map((careType, index) => (
                        <li key={index}>{careType}</li>
                      ))}
                    </ul>
                  );
                },
              },
            ]
          : []),
        ...(showPaidColumn
          ? [
              {
                text: 'Application Fee',
                dataField: 'offers',
                sort: false,
                formatter: (cell: string, row: Application) => renderPaidColumn(row),
              },
            ]
          : []),
        ...(displaySubsidyColumn
          ? [
              {
                text: 'Subsidy',
                dataField: 'familyReceivesSubsidy',
                sort: false,
                formatter: (cell: string, row: Application) =>
                  `${
                    isCentreUseSubsidySetting(row.centers[0])
                      ? row.familyReceivesSubsidy == null
                        ? 'N/A'
                        : row.familyReceivesSubsidy
                        ? 'Yes'
                        : 'No'
                      : 'N/A'
                  }`,
              },
            ]
          : []),
        ...(activeTab === ApplicationStage.Errored
          ? [
              {
                text: 'Error',
                dataField: '',
                sort: true,
                formatter: (_: any, row: Application) => {
                  return offerErrorStage(row.children);
                },
              },
            ]
          : []),
        ...(activeTab === ApplicationStage.Completed
          ? [
              {
                text: 'Account Name',
                dataField: '',
                sort: true,
                formatter: (_: any, row: Application) => {
                  return <AccountLink accountId={row.accountId ?? ''} accountName={row.accountName} openInNewTab />;
                },
              },
            ]
          : []),
        {
          text: '',
          dataField: '',
          sort: false,
          formatter: (_: any, row: Application) => {
            if (k2EnrolFormDifference && row.offers?.some((offer) => offer?.enrollmentFormChanged))
              return <Badge className="form-updated-badge">Form Updated</Badge>;

            return <></>;
          },
        },
        {
          text: '',
          dataField: '',
          sort: false,
          formatter: (_: any, row: Application) => {
            return (
              <ActionDropdown
                actions={[
                  {
                    label: 'Notes',
                    onClick: () => setNoteModalState({ isOpen: true, applicationId: row.id }),
                  },
                ]}
              />
            );
          },
        },
      ]}
    />
  );
};

export default LeadTable;
