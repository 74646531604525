import React, { useMemo } from 'react';
import ReactSwitch from 'react-switch';
import colors from '_colors.module.scss';
import { Row } from 'shared/components/Layout';
import classNames from 'classnames';
import _uniqueId from 'lodash/uniqueId';

export interface IProps {
  id?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  className?: string;
  height?: number;
  labelSide?: 'left' | 'right' | 'top';
  disabled?: boolean;
  checkedText?: string;
  uncheckedText?: string;
  width?: number;
  uncheckedClassName?: string;
  checkedClassName?: string;
}

const Switch: React.FC<IProps> = ({
  id,
  value,
  onChange,
  label,
  className,
  height = 40,
  width = 88,
  labelSide = 'right',
  disabled = false,
  checkedText = 'ON',
  uncheckedText = 'OFF',
  uncheckedClassName,
  checkedClassName,
}) => {
  const switchId = useMemo(() => {
    return !id ? _uniqueId() : id;
  }, [id]);
  return (
    <>
      <div>
        {label && labelSide === 'top' && (
          <label className="mb-2" htmlFor={switchId}>
            {label}
          </label>
        )}
      </div>
      <div className={className}>
        {label && labelSide === 'left' && (
          <label className="mr-2" htmlFor={switchId}>
            {label}
          </label>
        )}
        <ReactSwitch
          id={switchId}
          checked={value}
          onChange={onChange}
          uncheckedIcon={
            <Row noGutters justify="center" className={classNames('h-100 text-dark-gray', uncheckedClassName)}>
              {uncheckedText}
            </Row>
          }
          checkedIcon={
            <Row noGutters justify="center" className={classNames('h-100 text-white', checkedClassName)}>
              {checkedText}
            </Row>
          }
          handleDiameter={height - 12}
          offColor={colors.slate}
          onColor={colors.success}
          offHandleColor={colors.white}
          onHandleColor={colors.white}
          height={height}
          width={width}
          disabled={disabled}
        />
        {label && labelSide === 'right' && (
          <label className="ml-2" htmlFor={switchId}>
            {label}
          </label>
        )}
      </div>
    </>
  );
};

export default Switch;
