import { MutationHookOptions, MutationTuple } from '@apollo/client';
import { useMutation } from '../../shared/apis/core';
import { InputVariable } from '../program/mutation';
import { gql } from '@apollo/client';
import { showToast } from 'shared/components/Toast';
import { ApolloError } from '@apollo/client';

//region UPDATE_SESSION_AMENDMENT_BUSINESS_CONFIG
const UPDATE_SESSION_AMENDMENT_BUSINESS_CONFIG = () => gql`
  mutation ($input: SessionAmendmentUpdateBusinessInput!) {
    updateSessionAmendmentBusinessConfig(input: $input)
  }
`;

export interface IUpdateSessionAmendmentBusinessConfigData {
  updateSessionAmendmentBusinessConfig: boolean;
}

export const useUpdateSessionAmendmentBusinessConfig = (
  options?: MutationHookOptions<
    IUpdateSessionAmendmentBusinessConfigData,
    InputVariable<ISessionAmendmentUpdateBusinessInput>
  >
): MutationTuple<IUpdateSessionAmendmentBusinessConfigData, InputVariable<ISessionAmendmentUpdateBusinessInput>> =>
  useMutation<IUpdateSessionAmendmentBusinessConfigData, InputVariable<ISessionAmendmentUpdateBusinessInput>>(
    UPDATE_SESSION_AMENDMENT_BUSINESS_CONFIG(),
    {
      ...options,
      onError: (error: ApolloError) => {
        showToast(error.message, 'error');
      },
    }
  );
//endregion

//region CREATE_SESSION_AMENDMENT
const CREATE_SESSION_AMENDMENT = () => gql`
  mutation ($input: SessionAmendmentCreateInput!) {
    createSessionAmendment(input: $input) {
      authorizedByPersonId
      authorizedPersonId
      businessId
      expiryDate
      startDate
      id
      reasonText
    }
  }
`;

export interface ICreateSessionAmendmentData {
  createSessionAmendment: ISessionAmendmentDto[];
}

export const useCreateSessionAmendment = (
  options?: MutationHookOptions<ICreateSessionAmendmentData, InputVariable<ISessionAmendmentCreateInput>>
) =>
  useMutation<ICreateSessionAmendmentData, InputVariable<ISessionAmendmentCreateInput>>(CREATE_SESSION_AMENDMENT(), {
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });
//endregion

//region DELETE_SESSION_AMENDMENT
const DELETE_SESSION_AMENDMENT = () => gql`
  mutation ($id: ID!) {
    deleteSessionAmendment(id: $id)
  }
`;

export interface IDeleteSessionAmendmentData {
  deleteSessionAmendment: boolean;
}

export const useDeleteSessionAmendment = (
  options?: MutationHookOptions<IDeleteSessionAmendmentData, { id: string }>
): MutationTuple<IDeleteSessionAmendmentData, { id: string }> =>
  useMutation<IDeleteSessionAmendmentData, { id: string }>(DELETE_SESSION_AMENDMENT(), {
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });
//endregion
