export const SET_REQUESTED_EMPLOYEES_DATA = 'SET_REQUESTED_EMPLOYEES_DATA';
export const RESET_EMPLOYEES_DATA = 'RESET_EMPLOYEES_DATA';

// MEDICAL
export const GET_STAFF_MEDICAL_DATA_SUCCESS = 'GET_STAFF_MEDICAL_DATA_SUCCESS';

export const CREATE_MEDICAL_CONDITION_FOR_STAFF = 'CREATE_MEDICAL_CONDITION_FOR_STAFF';
export const UPDATE_MEDICAL_CONDITION_FOR_STAFF = 'UPDATE_MEDICAL_CONDITION_FOR_STAFF';
export const DELETE_MEDICAL_CONDITION_FOR_STAFF = 'DELETE_MEDICAL_CONDITION_FOR_STAFF';

export const CREATE_ALLERGY_FOR_STAFF = 'CREATE_ALLERGY_FOR_STAFF';
export const UPDATE_ALLERGY_FOR_STAFF = 'UPDATE_ALLERGY_FOR_STAFF';
export const DELETE_ALLERGY_FOR_STAFF = 'DELETE_ALLERGY_FOR_STAFF';

export type StaffActionTypes =
  | { type: typeof SET_REQUESTED_EMPLOYEES_DATA; staff: IStaff[] }
  | { type: typeof RESET_EMPLOYEES_DATA }
  | { type: typeof GET_STAFF_MEDICAL_DATA_SUCCESS; staff: IStaff }
  | { type: typeof CREATE_MEDICAL_CONDITION_FOR_STAFF; condition: IMedicalCondition }
  | { type: typeof UPDATE_MEDICAL_CONDITION_FOR_STAFF; condition: IMedicalCondition }
  | { type: typeof DELETE_MEDICAL_CONDITION_FOR_STAFF; condition: IMedicalCondition }
  | { type: typeof CREATE_ALLERGY_FOR_STAFF; allergy: IAllergy }
  | { type: typeof UPDATE_ALLERGY_FOR_STAFF; allergy: IAllergy }
  | { type: typeof DELETE_ALLERGY_FOR_STAFF; allergy: IAllergy };
