import React, { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import BaseSelect from 'shared/components/Select/Base';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { useGetPrograms } from 'gql/program/queries';
import { useMemo } from 'react';

interface IProps {
  /**
   * `null` can represent `All` when `useNullForAllOption` is provided
   */
  selectedProgramIds: string[] | null;
  /**
   * Provide `null` as the value via `onSelect` if the all option is selected.
   */
  useNullForAllOption?: boolean;
  onSelect: (programIds: string[] | null) => void;
  businessId: string;
  subsidySchemeId?: string;
  centerIds?: string[];
}

/**
 * Component is wrapped in Form.Group to match our standard Select component.
 * The regular <Select /> component was not used since the actionMetadata on dropdown selection
 * is needed and our shared component does not expose that information
 */
const MultipleProgramSelect: React.FC<IProps> = ({
  selectedProgramIds,
  useNullForAllOption = false,
  businessId,
  subsidySchemeId,
  centerIds,
  onSelect,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'subsidies']);
  const ALL_PROGRAMS_OPTIONS = { value: null, label: `All ${capitalize(t('translation:spelling.program'))}s` };

  const { data: searchProgramData, loading: searchProgramsLoading } = useGetPrograms({
    variables: {
      input: {
        businessId,
        centerIds: [],
        classIds: undefined,
        pageNumber: 1,
        pageSize: 10000,
        searchText: undefined,
        sortDtos: [{ field: 'name.keyword', direction: 'ASCENDING' }],
      },
    },
  });
  const programSelectOptions = useMemo(() => {
    // @ts-ignore
    let optionData = searchProgramData?.getProgramsByCenterId.data ?? [];
    if (!!subsidySchemeId) {
      optionData = optionData.filter((i) => i.subsidyScheme.subsidySchemeId === subsidySchemeId);
    }
    if (!!centerIds && centerIds.length > 0) {
      optionData = optionData.filter((i) => centerIds.includes(i.centerId));
    }

    return optionData.map((program) => ({ label: program.name, value: program.id })) ?? [];
  }, [centerIds, subsidySchemeId, searchProgramData]);

  /**
   * Determine if the "all" option has been selected in the dropdown
   */
  const isAllOptionSelected = useCallback((): boolean => {
    return (
      (useNullForAllOption && !selectedProgramIds) ||
      (selectedProgramIds !== null &&
        selectedProgramIds.length > 0 &&
        selectedProgramIds.length === programSelectOptions.length)
    );
  }, [selectedProgramIds, useNullForAllOption, programSelectOptions]);

  /**
   * Get the array of selected values. React-select wants an array of all of the objects selected
   */
  const getSelectValue = useCallback(() => {
    return isAllOptionSelected()
      ? [ALL_PROGRAMS_OPTIONS]
      : programSelectOptions.filter((opt) => (selectedProgramIds ?? []).includes(opt.value));
  }, [isAllOptionSelected, ALL_PROGRAMS_OPTIONS, programSelectOptions, selectedProgramIds]);

  /**
   * Handle the selection of an option from the dropdown
   */
  const handleSelect = useCallback(
    (options: any[], actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === ALL_PROGRAMS_OPTIONS.value) {
        onSelect(useNullForAllOption ? null : programSelectOptions.map((opt) => opt.value as string));
      } else if (
        (action === 'deselect-option' && option.value === ALL_PROGRAMS_OPTIONS.value) ||
        (action === 'remove-value' && removedValue.value === ALL_PROGRAMS_OPTIONS.value)
      ) {
        onSelect([]);
      } else if (actionMeta.action === 'deselect-option' && isAllOptionSelected()) {
        onSelect((selectedProgramIds ?? []).filter((id) => id !== option.value));
      } else {
        onSelect((options ?? []).filter((opt) => opt.value !== null).map((opt) => opt.value));
      }
    },
    [programSelectOptions, selectedProgramIds, useNullForAllOption, ALL_PROGRAMS_OPTIONS, isAllOptionSelected, onSelect]
  );

  return (
    <Form.Group>
      <Form.Label>{capitalize(t('subsidies:program.program-select'))}</Form.Label>
      <BaseSelect
        // @ts-ignore
        isMulti
        // @ts-ignore
        value={searchProgramsLoading ? null : getSelectValue()}
        // @ts-ignore
        options={[ALL_PROGRAMS_OPTIONS, ...programSelectOptions]}
        // @ts-ignore
        onChange={(options, action) => handleSelect(options as any[], action)}
        isLoading={searchProgramsLoading}
        className="react-select-container flex-wrap"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        noOptionsMessage={() => `No ${t('translation:spelling.program_plural')} found`}
        components={{ IndicatorSeparator: null }}
        data-testid="multiple-center-select"
      />
    </Form.Group>
  );
};

export default MultipleProgramSelect;
