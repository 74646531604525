import { FreeKindyAcquittalCenterSummaryDto, Maybe } from 'generated/graphql';
import Currency from 'shared/components/Currency';

interface IProps {
  year: number;
  summary?: FreeKindyAcquittalCenterSummaryDto;
}

const AcquittalCenterSummaryExpandRow: React.FC<IProps> = ({ year, summary }) => {
  const formatCurrency = (value?: number | Maybe<number>) => {
    return value ? <Currency display="DirectedNumbers" aggregate amount={value} /> : '-';
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Forecast</th>
          <th>Acquittal Adjustment</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Base Subsidy</td>
          <td>{formatCurrency(summary?.baseSubsidyPrior)}</td>
          <td>{formatCurrency(summary?.baseSubsidyAcquittal)}</td>
          <td>{formatCurrency(summary?.baseSubsidyActual)}</td>
        </tr>
        <tr>
          <td>Inclusion Ready</td>
          <td>{formatCurrency(summary?.inclusionReadyPrior)}</td>
          <td>{formatCurrency(summary?.inclusionReadyAcquittal)}</td>
          <td>{formatCurrency(summary?.inclusionReadyActual)}</td>
        </tr>
        <tr>
          <td>Service Location</td>
          <td>{formatCurrency(summary?.serviceLocationPrior)}</td>
          <td>{formatCurrency(summary?.serviceLocationAcquittal)}</td>
          <td>{formatCurrency(summary?.serviceLocationActual)}</td>
        </tr>
        <tr>
          <td>Free Kindy</td>
          <td>{formatCurrency(summary?.freeKindyPrior)}</td>
          <td>{formatCurrency(summary?.freeKindyAcquittal)}</td>
          <td>{formatCurrency(summary?.freeKindyActual)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default AcquittalCenterSummaryExpandRow;
