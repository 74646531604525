/**
 * Determines the rate units label
 * @param session
 * @returns a label that should should appendable to the phrase "per <return value>"
 */
export function getRateUnitsLabelFromSession(session: ISession): string {
  let rateUnitsLabel = 'Period'; //default
  let feeType = session?.fee?.feeType ?? null;
  if (feeType === 'SESSION') {
    rateUnitsLabel = 'Session';
  } else if (feeType === 'FLAT_RATE') {
    switch (session?.billingCycle?.frequency) {
      case 'WEEKLY':
        rateUnitsLabel = 'Week';
        break;
      case 'BIWEEKLY':
        rateUnitsLabel = 'Two Weeks';
        break;
      case 'MONTHLY':
        rateUnitsLabel = 'Month';
        break;
      case 'EVERY_FOUR_WEEKS':
        rateUnitsLabel = 'Four Weeks';
        break;
      default:
        break;
    }
  }
  return rateUnitsLabel;
}
