import React, { useCallback } from 'react';
import { isRegion } from 'shared/util/region';
import { useFlags } from 'launchdarkly-react-client-sdk';
import GlCodeDropdown from './GlCodeDropdown';
import GlCodeTextInput from './GlCodeTextInput';
import { AreaTypeToIdName } from 'shared/constants/enums/glCodes';

interface IProps<T> {
  legacyValue?: string | null;
  glCodeMapping?: IGLCodeMapping | null;
  updateFn: (value: React.SetStateAction<T>) => void;
  disabled?: boolean;
  areaType: GLCodeAreaType;
  required?: boolean;
}

interface Base {
  id?: string;
  glCodeMapping?: IGLCodeMapping | null;
  glCode?: string | null;
}

const GlCodeInput = <T extends Base>({
  legacyValue,
  glCodeMapping,
  updateFn,
  disabled = false,
  areaType,
  required = false,
}: IProps<T>) => {
  const isUS = isRegion('US');
  const { k2GlCodeActivationCheck } = useFlags();

  const handleUpdateGLCode = useCallback(
    (value: string) => {
      if (k2GlCodeActivationCheck) {
        updateFn((prev) => ({
          ...prev,
          glCodeMapping: {
            id: prev.glCodeMapping?.id,
            glCodeId: value,
            [AreaTypeToIdName[areaType]]: prev.id,
          },
        }));
      } else {
        // Replace all non-word characters and _ with nothing.
        updateFn((prev) => ({ ...prev, glCode: value.replace(/[\W_]/g, '') }));
      }
    },
    [k2GlCodeActivationCheck, updateFn, areaType]
  );

  if (k2GlCodeActivationCheck) {
    // GlCode Dropdown is for both AU and US
    return (
      <GlCodeDropdown
        glCodeMapping={glCodeMapping}
        onChange={handleUpdateGLCode}
        disabled={disabled}
        areaType={areaType}
        required={required}
      />
    );
  } else {
    if (isUS) {
      // GlCode TextInput is only for US
      return (
        <GlCodeTextInput value={legacyValue} onChange={handleUpdateGLCode} disabled={disabled} required={required} />
      );
    } else {
      // AU should not see GL Code TextInput
      return null;
    }
  }
};

export default GlCodeInput;
