import React, { useState } from 'react';
import { PersonAvatar } from 'shared/components/Avatar';
import { getFullName } from 'shared/util/string';
import { Row } from 'shared/components/Layout';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import ActionDropdown from 'shared/components/ActionDropdown';
import { faPencil, faStar, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { orderBy } from 'lodash';
import { Col, Container, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetAccountContactPermissions } from '../../../../../../../gql/account/queries';
import { relationshipPermissionType } from 'shared/constants/enums/RelationshipPermissionType';

interface IProps {
  contact: IContact;
  account: IAccount;
  canEdit: boolean;
  canRemove: boolean;
  onRemove: (c: IContact) => void;
  onEdit: (c: IContact) => void;
  onSetPrimary: (c: IContact) => void;
}

const AccountContactsTableExpandedRow: React.FC<IProps> = ({
  contact,
  account,
  canEdit,
  canRemove,
  onEdit,
  onRemove,
  onSetPrimary,
  ...props
}) => {
  const { data: getAccountContactPermissionsData } = useGetAccountContactPermissions();
  const [accountContactPermissions, setAccountContactPermissions] = useState<AccountPermissions[]>(
    contact.contactAccountPermissions.find((cap) => cap.accountId === account.id)?.permissions ?? []
  );

  return (
    <>
      {accountContactPermissions.length > 0 && (
        <Table className="no-box-shadow collaped">
          <thead>
            <tr>
              <td className="lg-column">
                <b>Contact Permissions</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {getAccountContactPermissionsData?.getAccountContactPermissions.map((p, i) => {
              return (
                accountContactPermissions.includes(p.value) && (
                  <tr key={i}>
                    <td>{p.label}</td>
                  </tr>
                )
              );
            })}
          </tbody>
        </Table>
      )}
      <Table className="no-box-shadow collaped">
        <thead>
          <tr>
            <td className="lg-column">Relationship</td>
            <td className="xl-column">Children</td>
            <td className="md-column">Permissions</td>
            {(canEdit || canRemove) && (
              <td>
                <ActionDropdown
                  actions={[
                    ...(canEdit
                      ? [
                          {
                            label: 'Edit Permissions',
                            onClick: () => onEdit(contact),
                            icon: faPencil,
                          },
                          {
                            label: 'Set as Primary',
                            onClick: () => onSetPrimary(contact),
                            icon: faStar,
                          },
                        ]
                      : []),
                    ...(canRemove
                      ? [
                          {
                            label: 'Remove Contact',
                            onClick: () => onRemove(contact),
                            icon: faTrashAlt,
                          },
                        ]
                      : []),
                  ]}
                />
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {orderBy(contact.children, 'firstname')
            .filter((child) => child.accountPermissions.some((permission) => permission.accountId === account.id))
            .map((child) => {
              const canPickup = child.accountPermissions
                .find((ac) => ac.accountId === account.id)
                ?.permissions.includes('Pickup');
              return (
                <tr key={child.id}>
                  <td>
                    <div className="tag" style={{ width: '140px' }}>
                      <div className="mx-auto">{childContactRelationship[child.relationshipType]}</div>
                    </div>
                  </td>
                  <td>
                    <Link to={`/families/children/${child.childId}`} className="d-flex align-items-center">
                      <PersonAvatar size="md" person={child} className={`${canPickup ? 'blue-outline' : ''} mr-4`} />
                      <div className="text=text-color">{getFullName(child)}</div>
                    </Link>
                  </td>
                  <td>
                    <Container>
                      <Row noGutters>
                        {!canPickup && (
                          <Col className="mr-2 ml-2 mb-2">
                            <small className="text-dark bg-info-10 px-2 py-1 rounded border sm">
                              Not Permitted to Pick Up
                            </small>
                          </Col>
                        )}
                        {child.accountPermissions
                          .find((ac) => ac.accountId === account.id)
                          ?.permissions.map((v) => {
                            return (
                              <Col className="m-2">
                                <small className="bg-info-10 px-2 py-1 rounded border sm">
                                  {relationshipPermissionType[v]}
                                </small>
                              </Col>
                            );
                          })}
                      </Row>
                    </Container>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default AccountContactsTableExpandedRow;
