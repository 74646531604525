import React from 'react';
import { Col, Row } from 'shared/components/Layout';
import PaymentProcessorCard from './PaymentProcessorCard';
import { isRegion } from 'shared/util/region';
import ImportTool from './ImportTool';
import UsImportTool from './UsImportTool';

interface IProps {
  center?: ICenter;
}

const KTAdminTab: React.FC<IProps> = ({ center, ...props }) => {
  return (
    <>
      {isRegion('US') && (
        <>
          <Row>
            <Col sm={12} md={6}>
              <PaymentProcessorCard
                hasMerchantId={Boolean(center?.merchant)}
                merchant={center?.merchant ?? null}
                centerId={center?.id ?? ''}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <UsImportTool center={center} />
            </Col>
          </Row>
        </>
      )}
      {isRegion('AU') && (
        <Row>
          <Col sm={12} md={12}>
            <ImportTool center={center} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default KTAdminTab;
