import React from 'react';
import Avatar from 'shared/components/Avatar';

interface IProps {
  avatar?: string;
  initials: string;
  primaryText: string;
  secondaryText?: string;
  color: string;
}

const SelectOptionWithAvatar: React.FC<IProps> = ({
  avatar = '',
  initials,
  primaryText,
  secondaryText,
  color,
  ...props
}) => {
  return (
    <div className="d-flex align-items-center">
      <Avatar color={color} size="sm" image={avatar} initials={initials} />
      <div className="d-flex flex-column pl-4 text-truncate">
        <span className="text-truncate">{primaryText}</span>
        {secondaryText && <small>{secondaryText}</small>}
      </div>
    </div>
  );
};

export default SelectOptionWithAvatar;
