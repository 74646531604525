import React from 'react';

import * as Mui from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

import { useTranslation } from 'react-i18next';

import CentersTable from './CentersTable';
import { useGetPaginatedCentersWithAddress } from 'gql/center/queries';

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
};

interface IModalProps {
  businessId: string;
  openCheckinModal: boolean;
  setOpenCheckinModal: (x: boolean) => void;
  turnOnExpressCheckin: () => void;
}

const ExpressCheckinModal: React.FC<IModalProps> = ({
  businessId,
  openCheckinModal,
  setOpenCheckinModal,
  turnOnExpressCheckin,
}) => {
  // const [centerListLoading, setCenterListLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);

  const { t } = useTranslation(['translation']);

  const { data: getPaginatedCentersData, loading: getPaginatedCentersLoading } = useGetPaginatedCentersWithAddress({
    variables: {
      input: {
        businessId: businessId,
        pageNumber: pageNumber,
        pageSize: 10,
        sortDirection: 'asc',
        sortBy: 'name',
        searchKey: '',
      },
    },
    onCompleted: (result) => {
      console.log('called getPaginatedCentersData: ', result);
    },
  });

  return (
    <Mui.Dialog open={openCheckinModal} onClose={() => setOpenCheckinModal(false)}>
      <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Mui.Typography variant="dialogTitle">Confirmation</Mui.Typography>
        <Mui.IconButton onClick={() => setOpenCheckinModal(false)} disableRipple sx={styles.closeIcon}>
          <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
        </Mui.IconButton>
      </Mui.DialogTitle>
      <Mui.DialogContent>
        <Mui.Typography
          marginBottom={2}
          border="2px solid #3ba8ff"
          borderRadius="25px"
          padding={2}
          sx={{ backgroundColor: '#ebf3fa' }}
        >
          For this beta, only Express Check-<em>in</em> is available - there is{' '}
          <b>no equivalent for the check out process.</b> We are actively developing Express Check-out right now - stay
          tuned for updates!
        </Mui.Typography>
        <Mui.Typography marginBottom={2}>
          Express Check-in relies on the address(es) you've entered for your {t('translation:spelling.center')}(s) in
          order to help family members checking their children in/out when they have not yet arrived on site.
        </Mui.Typography>
        <Mui.Typography marginBottom={2}>
          Please check your address(es) for your {t('translation:spelling.center')}(s) below for accuracy
        </Mui.Typography>
        <CentersTable
          updatePage={setPageNumber}
          currentCenters={getPaginatedCentersData?.getPaginatedCentersForBusiness?.data}
          getPaginatedCentersLoading={getPaginatedCentersLoading}
          totalCenters={getPaginatedCentersData?.getPaginatedCentersForBusiness.totalRecords ?? 0}
        />
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button variant="cancel" onClick={() => setOpenCheckinModal(false)}>
          Cancel
        </Mui.Button>
        <Mui.Button
          variant="contained"
          color="secondary"
          onClick={() => {
            turnOnExpressCheckin();
            setOpenCheckinModal(false);
          }}
        >
          Turn on Express Check-In
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default ExpressCheckinModal;
