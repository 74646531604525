import React from 'react';
import Card from 'shared/components/Card';
import { Box } from 'shared/components/LoadingSkeletons';

interface IProps {
  title: string;
  number: string;
  color: string;
  loading: boolean;
}

const DataCard: React.FC<IProps> = ({ title, number, color, loading, ...props }) => {
  return (
    <Card className="w-100" bodyClassName="d-flex align-items-center">
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex flex-row flex-wrap">
          <div>
            <b>{title}</b>
            <div>
              <small>{props.children}</small>
            </div>
          </div>
          <div className="d-flex flex-grow-1 justify-content-end">
            {loading ? (
              <Box height={56} width={56} />
            ) : (
              <div className="d-flex flex-column flex-grow-0 align-items-center">
                {/* since this number needs to line up with the time off card's number which aren't exactly centered since they have a title above them */}
                &nbsp;
                <p className="h1" style={{ color }}>
                  {number}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DataCard;
