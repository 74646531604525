const crnChecks: string[] = ['A', 'B', 'C', 'H', 'J', 'K', 'L', 'S', 'T', 'V', 'X'];

const crnFactors: number[] = [512, 256, 128, 64, 32, 16, 8, 4, 2];

export const isValidCrn = (crn: string): boolean => {
  if (crn.length != 10) {
    return false;
  }

  let accumulator = 0;
  const check = crn[9];

  for (let index = 0; index < 9; ++index) {
    let digit = Number.parseInt(crn[index]);

    if (Number.isNaN(digit)) {
      return false;
    }

    accumulator += digit * crnFactors[index];
  }

  return check == crnChecks[10 - (accumulator % 11)];
};
