//region DeleteProgramLeaveDay
import { MutationHookOptions, MutationTuple } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { InputVariable } from '../program/mutation';
import { useMutation } from '../../shared/apis/core';
import showToast from '../../shared/components/Toast/showToast';
import { gql } from '@apollo/client';

const DeleteProgramLeaveDay = () => gql`
  mutation ($input: DeleteProgramLeaveDayInput!) {
    deleteProgramLeaveDay(input: $input)
  }
`;

export interface IDeleteProgramLeaveDayData {
  deleteProgramLeaveDay: boolean;
}

export interface IDeleteProgramLeaveDayInput {
  businessId: string;
  data: string[];
}

export const useDeleteProgramLeaveDay = (
  options?: MutationHookOptions<IDeleteProgramLeaveDayData, InputVariable<IDeleteProgramLeaveDayInput>>
): MutationTuple<IDeleteProgramLeaveDayData, InputVariable<IDeleteProgramLeaveDayInput>> =>
  useMutation<IDeleteProgramLeaveDayData, InputVariable<IDeleteProgramLeaveDayInput>>(DeleteProgramLeaveDay(), {
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });
//endregion

//region CREATE_PROGRAM_LEAVE_DAY
const CREATE_PROGRAM_LEAVE_DAY = () => gql`
  mutation ($input: CreateProgramLeaveDayInput!) {
    createProgramLeaveDay(input: $input) {
      id
      startDate
      endDate
      leaveType
      programId
    }
  }
`;

export interface ICreateProgramLeaveDayData {
  createProgramLeaveDay: IProgramLeaveDay[];
}

export const useCreateProgramLeaveDay = (
  options?: MutationHookOptions<ICreateProgramLeaveDayData, InputVariable<ICreateProgramLeaveDayInput>>
): MutationTuple<ICreateProgramLeaveDayData, InputVariable<ICreateProgramLeaveDayInput>> =>
  useMutation<ICreateProgramLeaveDayData, InputVariable<ICreateProgramLeaveDayInput>>(CREATE_PROGRAM_LEAVE_DAY(), {
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });
//endregion
