import React, { useState } from 'react';
import classnames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';

interface IProps {
  className?: string;
}

/**
 * the classes used are the same as <ClassScheduleAccordion />
 */
const TimeOffForWeekAccordion: React.FC<IProps> = ({ className = '', ...props }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Accordion className={classnames('kt-schedules-class-schedule', className)} defaultActiveKey="0">
      <Accordion.Toggle
        eventKey="0"
        className="kt-schedules-class-schedule-accordion-toggle"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="d-flex flex-row align-items-center">
          <span className="ml-1 mr-auto">Time Off</span>
          <FontAwesomeIcon color={colors.dark} icon={isOpen ? faChevronUp : faChevronDown} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse className="kt-schedules-class-schedule-accordion-collapse" eventKey="0">
        <div className="kt-schedules-class-schedule-accordion-body">{props.children}</div>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default TimeOffForWeekAccordion;
