import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import Row from 'shared/components/Layout/Row';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import { useUnlinkEnrolmentById } from 'gql/ccssEnrolment/mutations';

interface IProps {
  accountId: string;
  centerId: string;
  enrolmentToUnlink: ICcsEnrolment | null;
  isOpen: boolean;
  onClose: () => void;
}

const UnlinkCcsEnrolmentModal: React.FC<IProps> = ({ accountId, centerId, enrolmentToUnlink, isOpen, onClose }) => {
  const [userText, setUserText] = useState<string | undefined>(undefined);

  const handleClose = useCallback(() => {
    setUserText(undefined);
    onClose();
  }, [onClose]);

  const [unlinkCcsEnrolment, { loading }] = useUnlinkEnrolmentById({
    onError: (err) => showToast(err.message, 'error'),
    onCompleted: () => {
      showToast('Child CCS enrolment unlinked successfully', 'success');
      handleClose();
    },
  });

  const handleContinue = useCallback(() => {
    if (enrolmentToUnlink?.id)
      unlinkCcsEnrolment({
        variables: {
          centerId: centerId,
          accountId: accountId,
          enrolmentId: enrolmentToUnlink?.id,
        },
      });
  }, [accountId, centerId, enrolmentToUnlink, unlinkCcsEnrolment]);

  const isFormValid = userText?.toUpperCase() == enrolmentToUnlink?.enrolmentId?.toUpperCase();

  return (
    <CenteredModal
      size="lg"
      show={isOpen}
      onHide={handleClose}
      title="Unlink CCS Enrolment"
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryChoice="Confirm"
      primaryCallback={handleContinue}
      primaryButtonProps={{ disabled: !isFormValid, loading }}
    >
      <Form>
        <Row align="start" className="ml-1 mb-4">
          To confirm, please type the Enrolment ID below:
        </Row>
        <Row align="start" className="ml-1 mr-1">
          <TextInput
            label={enrolmentToUnlink?.enrolmentId}
            value={userText}
            onChange={setUserText}
            isInvalid={!isFormValid}
          />
        </Row>
      </Form>
    </CenteredModal>
  );
};

export default UnlinkCcsEnrolmentModal;
