import { uniqBy } from 'lodash';
import { feeStatusFilterOptions } from 'shared/constants/feeStatusFilterOptions';
import * as types from './types';
export interface IFeesStateShape {
  all: IFee[];
  byId: Record<string, IFee>;
  searchResults: IFee[];
  searchFilter: ITableFiltersMap;
  searchSort: IElasticsearchSortFilter;
  totalFees: number;
}

const initialState: IFeesStateShape = {
  all: [],
  byId: {},
  searchResults: [],
  searchFilter: { status: feeStatusFilterOptions.filter((o) => o.value === 'active') },
  searchSort: { field: 'name.keyword', direction: 'ASCENDING' },
  totalFees: 0,
};

const getFeesWithUpdatedFee = (fees: IFee[], updatedFee: IFee) =>
  fees.map((f) => (f.id === updatedFee.id ? updatedFee : f));

export const feesReducers = (state: IFeesStateShape = initialState, action: types.FeesActionTypes): IFeesStateShape => {
  switch (action.type) {
    case types.SEARCH_FEES:
      const updatedAll = uniqBy([...state.all, ...action.fees], 'id');
      return {
        ...state,
        all: updatedAll,
        byId: Object.fromEntries(updatedAll.map((fee) => [fee.id, fee])),
        searchResults: action.fees,
        totalFees: action.totalFees,
      };
    case types.CREATE_FEE:
      return {
        ...state,
        all: [...state.all, action.fee],
        byId: { ...state.byId, [action.fee.id]: action.fee },
        searchResults: [...state.searchResults, action.fee],
        totalFees: state.totalFees + 1,
      };
    case types.UPDATE_FEE:
      return {
        ...state,
        all: state.all.map((f) => (f.id === action.fee.id ? action.fee : f)),
        byId: { ...state.byId, [action.fee.id]: action.fee },
        searchResults: state.searchResults.map((f) => (f.id === action.fee.id ? action.fee : f)),
      };
    case types.DEACTIVATE_FEE:
      const filterOutDeactivated =
        state.searchFilter.status?.length && !state.searchFilter.status?.map((s) => s.value).includes('deactivated');
      return {
        ...state,
        all: getFeesWithUpdatedFee(state.all, action.fee),
        byId: { ...state.byId, [action.fee.id]: { ...state.byId[action.fee.id], ...action.fee } },
        searchResults: getFeesWithUpdatedFee(state.searchResults, action.fee).filter((f) =>
          filterOutDeactivated ? !f.deactivatedAt : true
        ),
      };
    case types.REACTIVATE_FEE:
      const filterOutActive =
        state.searchFilter.status?.length && !state.searchFilter.status?.map((s) => s.value).includes('active');
      return {
        ...state,
        all: getFeesWithUpdatedFee(state.all, action.fee),
        byId: { ...state.byId, [action.fee.id]: { ...state.byId[action.fee.id], ...action.fee } },
        searchResults: getFeesWithUpdatedFee(state.searchResults, action.fee).filter((f) =>
          filterOutActive ? f.deactivatedAt : true
        ),
      };
    case types.UPDATE_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.filter,
      };
    case types.UPDATE_SEARCH_SORT:
      return {
        ...state,
        searchSort: { field: action.field, direction: action.direction },
      };
    default:
      return state;
  }
};
