import React, { useCallback, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Row, Col } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-light-svg-icons';
import DetailedRadioOption from 'shared/components/DetailedRadioOption';
import { isEmpty } from 'lodash';

interface IProps {
  isOpen: boolean;
  loading: boolean;
  handleDownload: (itemizedBillType: string) => void;
  handleClose: () => void;
}

interface IFormShape {
  selectedItemizedBillType: string | null;
}

const DownloadItemizedBillModal: React.FC<IProps> = ({ isOpen, loading, handleDownload, handleClose }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<IFormShape>({
    selectedItemizedBillType: null,
  });

  const validDownloadForm = useCallback(() => {
    return !isEmpty(formData.selectedItemizedBillType);
  }, [formData]);

  return (
    <SideModalDrawer
      title={t('itemized-bills.download-itemized-bill-modal.title')}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Download"
      primaryCallback={() => {
        setFormData({ selectedItemizedBillType: null });
        handleDownload(formData.selectedItemizedBillType ?? '');
      }}
      secondaryCallback={() => {
        setFormData({ selectedItemizedBillType: null });
        handleClose();
      }}
      primaryButtonProps={{ disabled: !validDownloadForm(), loading: loading }}
      closeOnSecondaryCallback={true}
      closeOnPrimaryCallback={false}
    >
      <Form>
        <Row>
          <Col>
            <div>
              <div className="d-flex flex-row">
                <Form.Label className="text-nowrap">
                  {t('itemized-bills.download-itemized-bill-modal.format-label')}
                </Form.Label>
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              </div>
              <ul className="unstyled-list mb-0">
                <li className="mb-2" key={'detailed'}>
                  <DetailedRadioOption
                    id={'detailed'}
                    title={t('itemized-bills.detailed-label')}
                    description={t('itemized-bills.download-itemized-bill-modal.detailed-description')}
                    onSelect={(selected) =>
                      setFormData((prev) => ({ selectedItemizedBillType: selected ? 'detailed' : '' }))
                    }
                    isSelected={formData.selectedItemizedBillType === 'detailed'}
                  />
                </li>
                <li className="mb-2" key={'summary'}>
                  <DetailedRadioOption
                    id={'summary'}
                    title={t('itemized-bills.summary-label')}
                    description={t('itemized-bills.download-itemized-bill-modal.summary-description')}
                    onSelect={(selected) =>
                      setFormData((prev) => ({ selectedItemizedBillType: selected ? 'summary' : '' }))
                    }
                    isSelected={formData.selectedItemizedBillType === 'summary'}
                  />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Form>
    </SideModalDrawer>
  );
};

export default DownloadItemizedBillModal;
