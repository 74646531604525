import * as Mui from '@mui/material';
import { Tab, Tabs } from 'react-bootstrap';
import Card from 'shared/components/Card/Card';
import EzChildTrackMigration from './EzChildTrack/EzChildTrackMigration';

interface IProps {
  businessId: string;
}

const Migrations = ({ businessId }: IProps) => {
  return (
    <Mui.Stack>
      <p className="font-weight-bold">Import Source</p>
      <Tabs defaultActiveKey="EzChildTrack" id="migration-tabs">
        <Tab eventKey="EzChildTrack" title="EzChildTrack">
          <EzChildTrackMigration businessId={businessId} />
        </Tab>
      </Tabs>
    </Mui.Stack>
  );
};

export default Migrations;
