import React, { useState } from 'react';
import classnames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';

interface IProps {
  name: string;
  classColorCode: string;
  ratioChildren?: number;
  ratioTeacher?: number;
  className?: string;
}

const ClassScheduleAccordion: React.FC<IProps> = ({
  name,
  classColorCode,
  ratioChildren,
  ratioTeacher,
  className = '',
  ...props
}) => {
  const showRatio: boolean = Boolean(ratioChildren && ratioTeacher);
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Accordion className={classnames('kt-schedules-class-schedule', className)} defaultActiveKey="0">
      <Accordion.Toggle
        eventKey="0"
        className="kt-schedules-class-schedule-accordion-toggle"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="d-flex flex-row align-items-center">
          <div className="kt-schedules-class-schedule-class-avatar" style={{ backgroundColor: classColorCode }}>
            {name.charAt(0).toUpperCase()}
          </div>
          <span className="ml-1">{name}</span>
          <small className="ml-4 mr-auto">{showRatio && `(RATIO ${ratioTeacher}:${ratioChildren})`}</small>
          <FontAwesomeIcon color={colors.dark} icon={isOpen ? faChevronUp : faChevronDown} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse className="kt-schedules-class-schedule-accordion-collapse" eventKey="0">
        <div className="kt-schedules-class-schedule-accordion-body">{props.children}</div>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default ClassScheduleAccordion;
