import { useCallback, useState } from 'react';

const pageSizeOptions = [25, 50, 100].map((a) => ({ label: a.toString(10), value: a }));

export const defaultPageSize = 25;
export const defaultPage = 1;

/**
 * Provides a convenience hook for basic pagination logic and handling
 * @returns
 */
export function usePagination() {
  const [activePage, setActivePage] = useState<number>(defaultPage);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const handlePageSizeChange = useCallback((pageSize: number) => {
    setActivePage(1);
    setPageSize(pageSize);
  }, []);
  const handlePageChange = useCallback((page: number) => setActivePage(page), []);
  return { activePage, pageSize, pageSizeOptions, handlePageSizeChange, handlePageChange };
}
