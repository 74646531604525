import React from 'react';
import Card from 'shared/components/Card';
import TextInput from 'shared/components/TextInput';
import Button from 'shared/components/Buttons';
import { showToast } from 'shared/components/Toast';
import { useUpdateCenterInviteCode } from '../../../graphql/mutations';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  center: ICenter | undefined;
  canPerformEdit: boolean;
  loading: boolean;
}

const InviteCodeCard: React.FC<IProps> = ({ center, canPerformEdit, loading }) => {
  const [updateInviteFn, { loading: inviteLoading }] = useUpdateCenterInviteCode();
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  return (
    <Card header={`${fieldLabels.center} Code`} loading={loading} loadingLines={4}>
      <p>
        This code is unique to your {fieldLabels.center.toLowerCase()} and used to invite new staff. It’s important to
        note, if you have invitations sent out, regenerating this code will void them.{' '}
      </p>
      <div className="d-flex justify-content-start align-items-end">
        <TextInput
          disabled={true}
          value={center?.inviteCode?.toUpperCase()}
          label="Code"
          className="mb-0 mr-4 xs-input flex-grow-0"
        />
        {canPerformEdit && (
          <Button
            onClick={() =>
              center &&
              updateInviteFn({ variables: { id: center.id } })
                .then(() => {
                  showToast('Code updated successfully.', 'success');
                })
                .catch(() => {
                  showToast('There was an error updating your code. Please try again later.', 'error');
                })
            }
            loading={inviteLoading}
            variant="secondary"
          >
            Generate New Code
          </Button>
        )}
      </div>
    </Card>
  );
};

export default InviteCodeCard;
