import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const EmailSentSuccess = () => (
  <div className="d-flex flex-column align-items-center">
    <p className="h1 font-weight-bold mb-8">Thank you!</p>
    <p className="mb-8 text-center">
      We’ve sent password reset instructions to your email address. If no email is received within ten minutes, check
      that the submitted address is correct.
    </p>
    <Link className="mt-8" to="/login">
      <Button>Back to Sign in</Button>
    </Link>
  </div>
);

export default EmailSentSuccess;
