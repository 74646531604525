export const customFieldTypeOptions: { value: string; label: string }[] = [
  { value: 'Text', label: 'Text' },
  { value: 'HtmlText', label: 'HTML Text' },
  { value: 'Checkbox', label: 'Checkbox' },
  { value: 'Combo', label: 'Combo' },
  { value: 'Radio', label: 'Radio' },
  { value: 'Document', label: 'Document' },
  { value: 'Address', label: 'Address' },
  { value: 'MultiSelectCombo', label: 'Multi Select Combo' },
  { value: 'Signature', label: 'Signature' },
  // **************
  // These are explicitly commented but not removed
  // Because else you, reading this, will never know they are needed eventually
  // **************
  //{ value: 'Date', label: 'Date' },
  //{ value: 'ContactSelector', label: 'Contact Selector' },
];
