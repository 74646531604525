import React from 'react';
import { isBlank } from 'shared/util/string';
import Header from './Header';
import Body from './Body';
import useWindowSize from '../../hooks/useWindowSize';

interface IProps {
  pageTitle?: string;
  badge?: React.ReactNode;
  buttonComponent?: React.ReactNode;
  secondaryButtonComponent?: React.ReactNode;
  mobileButtonComponent?: React.ReactNode;
  children: React.ReactNode | null;
  applyPadding?: boolean;
  savingComponent: React.ReactNode;
}

/**
 * Provide the header container for every page that contains:
 * - page title
 *
 * Every page component should be wrapped with this component
 */
const PageWrapperAutoSave: React.FC<IProps> = ({
  pageTitle = '',
  badge,
  buttonComponent,
  secondaryButtonComponent,
  mobileButtonComponent,
  applyPadding = true, // apply padding to your wrapped component. if this is false, you are then responsible for adding <Body /> where you deem necessary
  savingComponent,
  ...props
}) => {
  const showHeader: boolean = !isBlank(pageTitle);

  const windowSize = useWindowSize();
  const isMobile = windowSize.innerWidth <= 768;

  // styles for `app-page-content-header|body` are found in app.scss
  return (
    <>
      {showHeader && (
        <Header>
          <div className="d-flex align-items-center">
            <h3 className="font-weight-bold">{pageTitle}</h3>
            {badge}
          </div>
          <div className="ml-auto d-flex flex-row flex-wrap">
            {savingComponent}
            {!isMobile && secondaryButtonComponent}
            {!isMobile && buttonComponent}
            {isMobile && mobileButtonComponent}
          </div>
        </Header>
      )}
      {applyPadding ? <Body>{props.children}</Body> : props.children}
    </>
  );
};

export default PageWrapperAutoSave;
