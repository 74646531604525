import moment from 'moment';
import { groupBy, orderBy } from 'lodash';
import { getTotalHours } from '../../TimeOff/utils';

/**
 * the api will return our requested attendance in an array where each attendance object is attendance in the requested timeframe for a center
 * in the ui we want to display this data by center and by week
 *
 * this will also ensure that the dates are sorted in chronological order
 * we also need to recalculate totals here since we're splitting them up from what the api gave us. the calculations used are the same from the api
 */
export const groupAttendanceByWeek = (attendance?: IAttendance[] | null): IAttendance[] => {
  const attendanceRecordsGroupedByWeek: IAttendance[] = [];

  if (attendance) {
    attendance.forEach((att: IAttendance) => {
      const entriesGroupedByWeek = groupBy(att.days, (dailyAtt) => moment(dailyAtt.date).week());

      Object.entries(entriesGroupedByWeek).forEach(([week, dailyAttendance]) => {
        const newGroupedSecondsBreak = calculateSecondsBreak(dailyAttendance);
        const newGroupedSecondsScheduled = calculateSecondsScheduled(dailyAttendance) - newGroupedSecondsBreak;
        const newGroupedSecondsWorked = calculateSecondsWorked(dailyAttendance);

        attendanceRecordsGroupedByWeek.push({
          ...att,
          days: orderBy(dailyAttendance, (dailyAtt) => moment(dailyAtt.date), 'asc'),
          totalHoursScheduled: {
            hours: Math.floor(newGroupedSecondsScheduled / 3600),
            minutes: Math.floor((newGroupedSecondsScheduled % 3600) / 60),
          },
          totalHoursWorked: {
            hours: Math.floor(newGroupedSecondsWorked / 3600),
            minutes: Math.floor((newGroupedSecondsWorked % 3600) / 60),
          },
        });
      });
    });
  }

  return attendanceRecordsGroupedByWeek;
};

export const calculateSecondsBreak = (dailyAttendance: IDailyAttendance[]): number => {
  return (
    dailyAttendance.reduce((acc, curr) => {
      return (acc += curr.shifts.reduce((bcc, durr) => (bcc += durr.paidBreak ? 0 : durr.breakMinutes), 0));
    }, 0) * 60
  );
};

export const calculateSecondsScheduled = (dailyAttendance: IDailyAttendance[]): number => {
  return dailyAttendance.reduce((acc, curr) => {
    return (acc += curr.shifts.reduce(
      (bcc, durr) => (bcc += moment(durr.endTime).diff(moment(durr.startTime), 'seconds')),
      0
    ));
  }, 0);
};

export const calculateSecondsWorked = (dailyAttendance: IDailyAttendance[]): number => {
  return dailyAttendance.reduce((acc, curr) => {
    return (acc += curr.timeEntries.reduce((bcc, durr) => {
      let seconds;

      if (durr.trackedTimeOut) {
        seconds = moment(durr.trackedTimeOut).diff(moment(durr.trackedTimeIn), 'seconds');
      } else {
        seconds = moment().diff(moment(durr.trackedTimeIn), 'seconds');
      }

      return (bcc += seconds);
    }, 0));
  }, 0);
};

export const calculateTrainingHours = (attendance: IAttendance[]): number => {
  const trainingMinutesWorked = attendance.reduce((acc: number, curr: IAttendance) => {
    acc += curr.days
      .map((day: IDailyAttendance) => {
        return day.timeEntries.reduce((bcc: number, timeEntry: ITimeEntry) => {
          if (timeEntry.type === 'TRAINING') {
            bcc += (timeEntry.trackedTimeOut ? moment(timeEntry.trackedTimeOut) : moment()).diff(
              moment(timeEntry.trackedTimeIn),
              'minutes'
            );
          }

          return bcc;
        }, 0);
      })
      .reduce((a: number, b: number) => a + b, 0);

    return acc;
  }, 0);

  return trainingMinutesWorked / 60;
};

export const calculateAbsentHours = (attendance: IAttendance[]): number => {
  const trainingMinutesWorked = attendance.reduce((acc: number, curr: IAttendance) => {
    acc += curr.days
      .map((day: IDailyAttendance) => {
        if (day.shifts.length && !day.timeEntries.length) {
          return day.shifts.reduce(
            (bcc: number, shift: IShift) => moment(shift.endTime).diff(shift.startTime, 'minutes'),
            0
          );
        }

        return 0;
      })
      .reduce((a: number, b: number) => a + b, 0);

    return acc;
  }, 0);

  return trainingMinutesWorked / 60;
};

export const calculateTotalTimeOffHours = (requests: ITimeOff[]): { paid: number; unpaid: number } => {
  return requests.reduce(
    (acc: { paid: number; unpaid: number }, request: ITimeOff) => {
      const totalHours = getTotalHours(request);
      // hours approved is paid hours
      acc.paid += request.hoursApproved ?? 0;
      acc.unpaid += totalHours - (request.hoursApproved ?? 0);
      return acc;
    },
    { paid: 0, unpaid: 0 }
  );
};
