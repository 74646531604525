import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';

interface IRequestToJoinCenterViaCodeInput {
  code: string;
  email: string;
  firstname: string;
  lastname: string;
}

interface IRequestToJoinCenterViaCodeVariables {
  input: IRequestToJoinCenterViaCodeInput;
}

interface IRequestToJoinCenterViaCodeData {
  requestToJoinCenterViaCode: IStaff;
}

export const REQUEST_TO_JOIN_CENTER_VIA_CODE = gql`
  mutation ($input: RequestToJoinCenterViaCodeInput!) {
    requestToJoinCenterViaCode(input: $input) {
      id
      email
      firstname
      lastname
      primaryCenter {
        name
      }
    }
  }
`;

export const useRequestToJoinCenterViaCode = () =>
  useMutation<IRequestToJoinCenterViaCodeData, IRequestToJoinCenterViaCodeVariables>(REQUEST_TO_JOIN_CENTER_VIA_CODE);
