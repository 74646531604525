import React, { Dispatch, SetStateAction } from 'react';
import DetailedRadioOption from '../../../../../../../../shared/components/DetailedRadioOption';
import { INewContactWithPermissions } from './AddContactModalForm';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  contact: INewContactWithPermissions;
  setContact: Dispatch<SetStateAction<INewContactWithPermissions>>;
  account: IAccount;
}

const AddContactTypeOptions: React.FC<IProps> = ({ contact, setContact, account }) => {
  const { t } = useTranslation(['accounts']);
  return (
    <div className="mb-6">
      <FormLabel>
        {t('accounts:contacts.contactTypeLabel')}{' '}
        <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
      </FormLabel>
      <ul className="unstyled-list mb-0">
        <li className="mb-2">
          <DetailedRadioOption
            title={t('accounts:contacts.primaryContactDetailedOptionTitle')}
            description={t('accounts:contacts.primaryContactDetailedOptionDescription')}
            isSelected={contact.isPrimary}
            onSelect={() => {
              const childPermissions: IAccountContactChildInput[] = [];
              account.children
                .filter((child) => !child.archivedAt)
                .forEach((x) =>
                  childPermissions.push({
                    childId: x.id,
                    relationship: '',
                    permissions: [
                      'Pickup',
                      'GiveAmbulanceTransportation',
                      'GiveConsentRegularOutings',
                      'GiveMedicalTreatment',
                    ],
                  })
                );
              setContact((prev) => ({ ...prev, isPrimary: true, childPermissions }));
            }}
          />
        </li>
        <li className="mb-2">
          <DetailedRadioOption
            title={t('accounts:contacts.authorizedContactDetailedOptionTitle')}
            description={t('accounts:contacts.authorizedContactDetailedOptionDescription')}
            isSelected={!contact.isPrimary}
            onSelect={() => {
              setContact((prev) => ({ ...prev, isPrimary: false }));
            }}
          />
        </li>
      </ul>
    </div>
  );
};

export default AddContactTypeOptions;
