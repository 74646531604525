import React, { useCallback, useContext, useState } from 'react';
import moment from 'moment';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { capitalize, Dictionary } from 'lodash';
import { QuarterStatsShape } from '../ProgramLeaveDayComponent';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shared/components/Layout';
import Button from 'shared/components/Buttons';
import { useDeleteProgramLeaveDay } from 'gql/programLeaveDay/mutation';
import { ProgramContext, ProgramContextShape } from '../../Program';
import { getClosureTypeLabel } from 'pages/Subsidies/KindyFunding/utils';

export type LeaveDaysDetailModelProps = {
  data: Dictionary<QuarterStatsShape>;
  showLeaveDaysDetail: boolean;
  onHide: (shouldRefetch: boolean) => void;
};

export default function LeaveDaysDetailModel({ data, showLeaveDaysDetail, onHide }: LeaveDaysDetailModelProps) {
  const programContext = useContext<ProgramContextShape>(ProgramContext);
  const [isDeleted, setIsDelete] = useState<boolean>(false);
  const [deletedLeaveDays, setDeleteLeaveDays] = useState<IProgramLeaveDay[]>([]);
  const { t } = useTranslation(['translation', 'subsidies']);

  const [deleteProgramLeaveDayFn, { data: response, loading: isDeleting }] = useDeleteProgramLeaveDay();

  const onHideHandler = useCallback(() => {
    onHide(isDeleted);
  }, [isDeleted]);

  const onDelete = useCallback(
    async (item: IProgramLeaveDay) => {
      const iDeleteProgramLeaveDayDataExecutionResult = await deleteProgramLeaveDayFn({
        variables: {
          input: {
            businessId: programContext.businessId!,
            data: [item.id],
          },
        },
      });

      setDeleteLeaveDays([...deletedLeaveDays, item]);
      setIsDelete(iDeleteProgramLeaveDayDataExecutionResult.data?.deleteProgramLeaveDay ?? false);
    },
    [programContext.businessId, deletedLeaveDays]
  );

  return (
    <CenteredModal
      size={'lg'}
      title={t('subsidies:program.program-closure-days')}
      show={showLeaveDaysDetail}
      hasFooter={false}
      onHide={onHideHandler}
    >
      <div>
        {Object.keys(data).length > 0 && (
          <>
            {Object.keys(data).map((o) => {
              return data[o].records.map((oo) => {
                return (
                  <Row key={oo.id} className="mb-5">
                    <Col>
                      {moment(oo.startDate).format(t('translation:formatters.date'))}{' '}
                      {!moment(oo.startDate).isSame(moment(oo.endDate)) && <>-</>}{' '}
                      {!moment(oo.startDate).isSame(moment(oo.endDate)) &&
                        moment(oo.endDate).format(t('translation:formatters.date'))}
                    </Col>
                    <Col>{getClosureTypeLabel(oo.leaveType)}</Col>
                    <Col>
                      <Button
                        loading={isDeleting}
                        disabled={deletedLeaveDays.find((o) => o.id === oo.id) != null}
                        variant="danger"
                        onClick={() => onDelete(oo)}
                      >
                        {t('translation:core.capitalize', {
                          value: t('translation:spelling.delete'),
                        })}
                      </Button>
                    </Col>
                  </Row>
                );
              });
            })}
          </>
        )}
      </div>
    </CenteredModal>
  );
}
