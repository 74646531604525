import React, { useCallback } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ActionDropdown from 'shared/components/ActionDropdown';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import Select from 'shared/components/Select';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState2';
import useFormatDate from 'shared/hooks/useFormatDate';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { setCurrentBusiness, setCurrentCenterFilters } from 'store/context/actions';
import { RootState } from 'store/reducers';
import EnrollmentProgramRow from '../EnrollmentProgramRow';
import { capitalize } from 'shared/util/string';
import DayInput from 'shared/components/DayInput';
import moment from 'moment';
import { EnrollmentProgramGroup } from 'generated/graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DayDisplayEnum } from 'pages/ISCases/components/ISCaseDetailsRow/ISCaseChildrenTable';
import { IEnrollmentProgram } from 'shared/types/enrollmentProgram';

export interface IEnrollmentProgramsTableProps {
  data: IEnrollmentProgram[];
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  centers: ICenter[];
  businessId: string;
  centerFilterIds: string[];
  searchTerm: string;
  totalResults: number;
  loading: boolean;
  showSelect?: boolean;
  showActions?: boolean;
  showExpand?: boolean;
  columns?: any[];
  handleEdit?: (program: IEnrollmentProgram) => void;
  setSearchTerm: (value: string) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
}
const EnrollmentProgramsTable: React.FC<IEnrollmentProgramsTableProps> = ({
  data,
  tableFunctions,
  tableState,
  businessId,
  centers,
  centerFilterIds,
  searchTerm,
  totalResults,
  loading,
  showSelect = false,
  showActions = true,
  showExpand = true,
  columns,
  onSort,
  setSearchTerm,
  handleEdit = (program) => {},
}) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const allEntities = useSelector((state: RootState) => state.entities.allEntities);
  const businessTimeZone = allEntities[businessId]?.timezone || moment.tz.guess();
  const { data: businessesData } = useGetAllowedEntities();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const formatter = useFormatDate();
  const dispatch = useDispatch();
  const hasEditPermission = true;

  const handleCenterFilterSelect = useCallback(
    (options: ITableFilterOption[]) => {
      dispatch(setCurrentCenterFilters((options ?? []).map((c) => c.value)));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const handleBusinessSelect = useCallback(
    (option: ITableFilterOption) => {
      dispatch(setCurrentBusiness(option.value));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const defaultColumns = [
    {
      text: capitalize(t('spelling.name')),
      dataField: 'name',
      sort: true,
    },
    {
      text: `Program ${t('spelling.start-date')}`,
      dataField: 'startDate',
      sort: true,
      formatter: (cell: string) => formatter(cell),
    },
    {
      text: `Program ${t('spelling.end-date')}`,
      dataField: 'endDate',
      sort: true,
      formatter: (cell: string) => formatter(cell),
    },
    {
      text: t('enrollment.programs.open-enrollment-period'),
      dataField: 'enrolmentOpenAt',
      sort: true,
      formatter: (cell: any, row: IEnrollmentProgram) => {
        if (!row.enrolmentOpenAt) return null;
        if (row.enrolmentOpenAt && !row.enrolmentCloseAt) {
          return `From ${moment(row.enrolmentOpenAt).tz(businessTimeZone).format(t('formatters.MM/DD/YYYY'))}`;
        }
        if (row.enrolmentOpenAt && row.enrolmentCloseAt) {
          return `${moment(row.enrolmentOpenAt).tz(businessTimeZone).format(t('formatters.MM/DD/YYYY'))} - ${moment(
            row.enrolmentCloseAt
          )
            .tz(businessTimeZone)
            .format(t('formatters.MM/DD/YYYY'))}`;
        }
        return null;
      },
    },
    {
      text: t('enrollment.programs.operating-days'),
      headerAlign: 'center',
      align: 'center',
      dataField: 'operatingDays',
      formatter: (cell: string[]) => {
        let days: WeekDay[] = cell.map((a) => DayDisplayEnum[a]);
        return days.join(',');
      },
    },
    {
      text: t('enrollment.programs.group'),
      dataField: 'programGroup.name',
      sort: true,
    },
    {
      text: capitalize(t('spelling.actions')),
      dataField: '',
      classes: 'action-column',
      hidden: !showActions,
      formatter: (cell: any, row: IEnrollmentProgram) => (
        <ActionDropdown
          actions={[
            ...(hasEditPermission
              ? [
                  {
                    label: capitalize(t('spelling.edit')),
                    onClick: () => {
                      handleEdit(row);
                    },
                  },
                ]
              : []),
          ]}
        />
      ),
    },
  ];
  return (
    <DataTable
      data={data}
      dataSize={totalResults}
      noPadding
      showLoadingOverlay={loading}
      showSelect={showSelect}
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      onSort={onSort}
      className="enrollment-programs-table"
      selectedRows={tableState.selectedRows}
      updateSelectedRows={tableFunctions.updateSelectedRows}
      expandRow={!showExpand ? undefined : (row: IEnrollmentProgram) => <EnrollmentProgramRow program={row} />}
      renderHeader={(paginationProps) => (
        <TableHeader className="flex-wrap justify-content-between">
          <SizePerPage paginationProps={paginationProps} />
          <TableSearch className="max-width-200" onChange={setSearchTerm} value={searchTerm} enableDebounce={false} />
          <div className="ml-auto d-flex align-items-center">
            {user?.isInternal && (
              <Select
                title={capitalize(t('spelling.business'))}
                className="mr-4 my-2 min-width-200"
                options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                value={businessId}
                name="business"
                onChange={handleBusinessSelect}
              />
            )}
            <DropdownFilter
              title={t('spelling.capitalCenter')}
              className="mr-4"
              selectedFilters={centerFilterIds}
              options={
                centers
                  ?.filter((c) => c.entityId === businessId)
                  .map((c) => ({ label: c.name, value: c.id }))
                  .sort((a, b) => a.label.localeCompare(b.label)) ?? []
              }
              onFilterSelect={handleCenterFilterSelect}
            />
          </div>
        </TableHeader>
      )}
      columns={columns ?? defaultColumns}
    />
  );
};

export default EnrollmentProgramsTable;
