import { CommsBusinessSetting } from 'generated/graphql';

export const GET_COMMS_BUSINESS_SETTINGS_SUCCESS = 'GET_COMMS_BUSINESS_SETTINGS_SUCCESS';
export const GET_USES_COMMS_SUCCESS = 'GET_USES_COMMS_SUCCESS';

interface IGetCommsBusinessSettingsSuccessAction {
  type: typeof GET_COMMS_BUSINESS_SETTINGS_SUCCESS;
  businessSettings: CommsBusinessSetting | undefined | null;
}

interface IGetUsesCommsSuccessAction {
  type: typeof GET_USES_COMMS_SUCCESS;
  usesComms: boolean;
}

export type CommsActionTypes = IGetCommsBusinessSettingsSuccessAction | IGetUsesCommsSuccessAction;
