import React from 'react';
import AbsenceWidget from './AbsenceWidget';
import SubsidyTrackingWidget from './SubsidyTrackingWidget';

interface IProps {
  businessId: string;
  centerIds: string[];
}

const OverviewTab: React.FC<IProps> = ({ businessId, centerIds }) => {
  return (
    <div>
      <SubsidyTrackingWidget businessId={businessId} centerIds={centerIds} />
      <AbsenceWidget businessId={businessId} centerIds={centerIds} />
    </div>
  );
};

export default OverviewTab;
