import React, { useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import useFormatDate from 'shared/hooks/useFormatDate';
import { getFullName } from 'shared/util/string';
import Currency from 'shared/components/Currency';
import Button from 'shared/components/Buttons';
import './SelectedItemizedBillOverviewCard.scss';

interface IProps {
  accountId: string;
  loading: boolean;
  onWaiveLatePaymentFeeClicked?: () => void;
  waivingLatePaymentFee?: boolean;
}

interface ItemizedBillView {
  billingDate: string;
  createdBy: string;
  daysLate?: string;
  dueOnDate: string;
  forwardBalance?: number;
  latePaymentFee?: { amount: number; waived: boolean; waivedAt?: string; waivedBy: string };
  paymentDueDate: string;
  subTotal: number;
  totalDue: number;
}

const SelectedItemizedBillOverviewCard: React.FC<IProps> = ({
  accountId,
  onWaiveLatePaymentFeeClicked = () => {},
  waivingLatePaymentFee = false,
}) => {
  const { t } = useTranslation(['accounts']);
  const formatDate = useFormatDate();

  const selectedItemizedBill = useSelector(
    (state: RootState) => state.accounts.itemizedBills.byAccountId[accountId]?.selectedItemizedBill ?? null
  );

  const itemizedBillView: ItemizedBillView = useMemo(() => {
    const daysLate =
      selectedItemizedBill?.isCurrentBill &&
      selectedItemizedBill.dueOnDate !== null &&
      moment(selectedItemizedBill.dueOnDate).isBefore(moment())
        ? Math.abs(moment().diff(moment(selectedItemizedBill.dueOnDate), 'days'))
        : 0;
    return {
      billingDate: selectedItemizedBill?.createdAt ? formatDate(selectedItemizedBill?.createdAt) : '',
      createdBy: getFullName(selectedItemizedBill?.createdByPerson),
      daysLate:
        !selectedItemizedBill?.isPaid && selectedItemizedBill?.isCurrentBill && daysLate !== 0
          ? t('accounts:profile.billing-itemized-bills-tab.days-late', { count: daysLate })
          : undefined,
      dueOnDate: formatDate(selectedItemizedBill?.dueOnDate ?? ''),
      forwardBalance: selectedItemizedBill?.forwardAmount,
      latePaymentFee: selectedItemizedBill?.latePaymentFee
        ? {
            amount: selectedItemizedBill.latePaymentFee.amount,
            waived: selectedItemizedBill.latePaymentFee.waived,
            waivedBy: getFullName(selectedItemizedBill?.latePaymentFee?.waivedBy) ?? '',
            waivedAt: selectedItemizedBill?.latePaymentFee?.waivedAt
              ? formatDate(selectedItemizedBill?.latePaymentFee?.waivedAt)
              : '',
          }
        : undefined,
      paymentDueDate: selectedItemizedBill?.dueOnDate ? formatDate(selectedItemizedBill?.dueOnDate) : '',
      subTotal: selectedItemizedBill?.amount ?? 0,
      totalDue: selectedItemizedBill?.totalAmount ?? 0,
    };
  }, [formatDate, selectedItemizedBill, t]);

  return (
    <Card bodyClassName="p-4">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center justify-content-between mb-4">
          <div>
            <h5>{t('accounts:profile.billing-itemized-bills-tab.total-due')}</h5>
          </div>
          <div>
            <h3>
              <Currency aggregate amount={itemizedBillView.totalDue} />
            </h3>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between mb-2">
          <div>
            <span>
              <b>{t('accounts:profile.billing-itemized-bills-tab.payment-due')}:</b> {itemizedBillView.paymentDueDate}{' '}
              <span className="text-danger">{itemizedBillView.daysLate}</span>
            </span>
          </div>
          {itemizedBillView.forwardBalance !== undefined && (
            <div>
              <div>
                {t('accounts:profile.billing-itemized-bills-tab.forwarded-balance')}:{' '}
                <Currency amount={itemizedBillView.forwardBalance} />
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <small>
              {t('accounts:profile.billing-itemized-bills-tab.billing-date')}: {itemizedBillView.billingDate}
            </small>
          </div>
          <div>
            {selectedItemizedBill?.isManuallyCreated && selectedItemizedBill.createdByPerson ? (
              <small>Created by: {itemizedBillView.createdBy}</small>
            ) : (
              <div>
                {t('accounts:profile.billing-itemized-bills-tab.total')}:{' '}
                <Currency amount={itemizedBillView.subTotal} />
              </div>
            )}
          </div>
        </div>
        {itemizedBillView.latePaymentFee && (
          <div className="waive-late-fee-container">
            {itemizedBillView.latePaymentFee?.waived ? (
              <small className="late-payment-fee-waived-info">
                {t('accounts:profile.billing-itemized-bills-tab.waive-late-payment-fee-waived-text', {
                  date: itemizedBillView.latePaymentFee?.waivedAt,
                  personName: itemizedBillView.latePaymentFee?.waivedBy,
                })}
              </small>
            ) : (
              <Button variant="outline-danger" onClick={onWaiveLatePaymentFeeClicked} disabled={waivingLatePaymentFee}>
                {t('accounts:profile.billing-itemized-bills-tab.waive-late-payment-fee-button')}
              </Button>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export default SelectedItemizedBillOverviewCard;
