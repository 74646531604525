import React, { useCallback, useMemo, useState, useImperativeHandle } from 'react';
import { Modal } from 'react-bootstrap';
import { Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import Button, { CreateButton } from 'shared/components/Buttons';
import { Col, Row } from 'shared/components/Layout';
import { Page, SectionType } from 'shared/types/enrollment-form';
import { PublicApi } from '../page-detail/page-detail';
import { isUndefined } from 'lodash';
import DraggablePageRow from './dragable-page-row';
import classNames from 'classnames';
import { orderArray } from 'shared/util/dnd';
import { v4 as uuidv4 } from 'uuid';
import { isRegion, Region } from 'shared/util/region';
import { hasUsedPaymentSystemFieldInAccountPage } from '../utils';

export type ManagePageModalProps = {
  pages: Page[];
  onPagesChanged: (pages: Page[]) => void;
  onHide: () => void;
};

export type PageEx = Page & {
  immutable?: boolean;
};

const PageSectionDict = {
  child: { label: 'Child', customFieldAreaType: 'Child' },
  contact: { label: 'Contact', customFieldAreaType: 'Contact', namespace: 'primaryContacts' },
  'secondary-contact': { label: 'Secondary contact', customFieldAreaType: 'Contact', namespace: 'contacts' },
  cwa: { label: 'CWA', customFieldAreaType: 'Account', region: 'AU' },
  payment: { label: 'Direct Debit', customFieldAreaType: 'Account' },
  'medical-professional': {
    label: 'Health Professionals',
    customFieldAreaType: 'MedicalProfessional',
    namespace: 'medicalProfessionals',
  },
} as {
  [key: string]: {
    label: string;
    customFieldAreaType: string;
    region?: Region;
    namespace?: string;
  };
};

const createNewPage = (): PageEx => {
  return {
    immutable: false,
    label: '',
    sectionType: undefined,
    sections: [
      {
        id: uuidv4(),
        hideTitle: false,
        fields: [],
      },
    ],
  };
};

export default React.forwardRef<PublicApi, ManagePageModalProps>(({ pages: payload, onPagesChanged, onHide }, ref) => {
  const { t } = useTranslation(['translation', 'enrollment', 'business']);
  const isAU = isRegion('AU');
  const [pages, setPages] = useState<PageEx[]>(payload.map((p) => ({ ...p, immutable: true } as PageEx)));
  const errors = pages.map((page) => {
    const title = page.label;
    if (!title) {
      return t('enrollment:form.manage-pages-modal.errors.page-title-required');
    }
    if (pages.filter((p) => p.label === title).length > 1) {
      return t('enrollment:form.manage-pages-modal.errors.page-title-unique');
    }
    return undefined;
  });

  const updatePage = useCallback(
    (page: Page, idx) => {
      const newPages = [...pages];
      newPages[idx] = page;
      setPages(newPages);
    },
    [pages, setPages]
  );

  const sectionTypeOptions = useMemo(() => {
    const options = [] as { id: string; label: string; value: string; isDisabled?: boolean; namespace?: string }[];
    options.push({
      id: '',
      label: 'Customer defined',
      value: '',
    });

    const existingSectionType = pages.map((o) => o.sectionType);

    const isPaymentSystemFieldsHaveUsedSomeWhere = hasUsedPaymentSystemFieldInAccountPage(pages);

    for (const key in PageSectionDict) {
      const value = PageSectionDict[key];
      if (value.region && !isRegion(value.region)) {
        continue;
      }

      // If payment system fields have been used in account page, then we should not allow to add a new payment type page
      const shouldDisablePaymentTypePage = isPaymentSystemFieldsHaveUsedSomeWhere && key === 'payment';

      options.push({
        id: key,
        isDisabled: existingSectionType.includes(key as SectionType) || shouldDisablePaymentTypePage,
        label: value.label,
        value: key,
        namespace: value.namespace,
      });
    }
    return options;
  }, [pages]);

  const onRemovePage = async (pageIdx: number) => {
    const newPages = [...pages];
    newPages.splice(pageIdx, 1);
    setPages(newPages);
  };

  useImperativeHandle(
    ref,
    () => ({
      ...((ref as React.MutableRefObject<PublicApi | null>)?.current ?? {}),
      setManagePageModalPages: (dropResult: DropResult) => {
        const { destination, source } = dropResult;
        if (!destination) return;
        const newPages = orderArray<PageEx>(pages, source.index, destination.index);
        setPages(newPages);
      },
    }),
    [ref, pages]
  );

  return (
    <div className="manage-page-modal">
      <Modal show backdrop="static" onHide={onHide} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{t('enrollment:form.manage-pages-modal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Droppable droppableId="manage-pages-modal" type="manage-pages">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{}}
                {...provided.droppableProps}
                className={classNames({
                  'p-2': true,
                  'is-dragging-over': snapshot.isDraggingOver,
                })}
              >
                {pages.map((page, idx) => (
                  <DraggablePageRow
                    page={page}
                    draggableIndex={idx}
                    key={idx}
                    updatePage={updatePage}
                    onRemovePage={onRemovePage}
                    error={errors[idx]}
                    sectionTypeOptions={sectionTypeOptions}
                  />
                ))}
                {provided.placeholder}
                <Row>
                  <Col xs={{ offset: 1, span: 8 }} className="text-center">
                    <CreateButton
                      variant="outline-secondary"
                      className="w-50"
                      onClick={() => {
                        pages.push(createNewPage());
                        setPages([...pages]);
                      }}
                    >
                      {t('translation:core.capitalize', { value: t('enrollment:form.add-page') })}
                    </CreateButton>
                  </Col>
                </Row>
              </div>
            )}
          </Droppable>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <div>
            <Button variant="light" onClick={onHide} className="mr-2">
              {t('translation:core.capitalize', { value: t('translation:spelling.cancel') })}
            </Button>
            <Button
              variant="primary"
              disabled={errors.some((e) => !isUndefined(e))}
              onClick={() => {
                onPagesChanged(
                  pages.map((p) => {
                    const o = { ...p };
                    delete o.immutable;
                    return o;
                  })
                );
                onHide();
              }}
            >
              {t('translation:core.capitalize', { value: t('translation:spelling.save') })}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
});
