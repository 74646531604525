import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import CountrySelect from './CountrySelect';
import MaskedInput from 'react-text-mask';
import { COUNTRY_CODES, countryCodeToFlag, countryCodeToPlaceholder, countryCodeToMask } from './countryCodes';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import cast from 'shared/util/cast';
import { parsePhoneNumberFromStringWithRegion } from 'shared/util/string';
import config from 'config';
import useValidPhoneInputForm from './useValidPhoneInputForm';

interface IProps {
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  isInvalid?: boolean;
  errorText?: object | string;
  className?: string;
  onChange?: (value: string, name: string) => void;
  showCountrySelect?: boolean;
  value?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onFocus?: any;
  onBlur?: any;
  isCcsManagement?: boolean;
  phoneNotMobileNumber?: boolean;
}

const PhoneInput: React.FC<IProps> = ({
  id = '',
  name = 'phoneNumber',
  label,
  required,
  isInvalid,
  errorText,
  className,
  onChange,
  showCountrySelect = false, // dont show select for now since we are only supporting US
  value = '',
  disabled = false,
  autoFocus = false,
  onFocus,
  onBlur,
  isCcsManagement = false,
  phoneNotMobileNumber = false,
  ...props
}) => {
  // get phoneNumber data from passed value of phone number ex: '+17163334444'
  const defaultCountryCode = config.locale.region;
  const phoneNumberData = value ? parsePhoneNumberFromStringWithRegion(value) : undefined;
  const countryCodeFromPhone = phoneNumberData
    ? phoneNumberData.country
      ? phoneNumberData.country
      : phoneNumberData.countryCallingCode
      ? Object.keys(COUNTRY_CODES).find(
          (code: string) => COUNTRY_CODES[code].value.replace('+', '') === phoneNumberData.countryCallingCode
        )
      : defaultCountryCode
    : defaultCountryCode;
  const [countryCode, setCountryCode] = useState<any>(countryCodeFromPhone);
  const [isFocused, setFocus] = useState(autoFocus);
  const [touched, setTouched] = useState(autoFocus);
  const { inputHelperState } = useValidPhoneInputForm(
    required,
    phoneNotMobileNumber,
    value,
    isFocused,
    touched,
    isInvalid
  );

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setTouched(true);
      // update number to parsed number with country code
      const phoneNumberData = value ? parsePhoneNumberFromString(value, countryCode) : undefined;
      if (phoneNumberData && phoneNumberData.isValid()) {
        onChange?.(cast<string>(phoneNumberData.number), name); // send full number back to parent
      } else {
        onChange?.(value, name); // fallback if number isn't long enough to get data
      }
    },
    [onChange, countryCode, name]
  );

  const handleFocus = useCallback(
    (e) => {
      setFocus(true);
      onFocus && onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      setTouched(true);
      setFocus(false);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  const handleCountrySelect = useCallback(
    (selectedCountryCode) => {
      setCountryCode(selectedCountryCode);
      const phoneNumberData = value ? parsePhoneNumberFromString(value, selectedCountryCode) : undefined;
      if (phoneNumberData && phoneNumberData.nationalNumber) {
        const updatedPhoneNumberData = parsePhoneNumberFromString(
          cast<string>(phoneNumberData.nationalNumber),
          selectedCountryCode
        );
        updatedPhoneNumberData && onChange?.(cast<string>(updatedPhoneNumberData.number), name);
      }
    },
    [onChange, name, value]
  );

  const formattedValue = useCallback(() => {
    const phoneNumberData = value ? parsePhoneNumberFromString(value, countryCode) : undefined;

    if (phoneNumberData && phoneNumberData.isValid()) return phoneNumberData.formatNational(); // This must match the mask provided to MaskedInput

    if (isCcsManagement && !phoneNumberData) return value;

    if (!isCcsManagement && !phoneNumberData) return undefined;

    return new AsYouType(countryCode).input(value);
  }, [countryCode, value]);

  return (
    <div className={`mb-4 ${className || ''}`}>
      {label && (
        <div className="d-flex flex-row">
          <label>{label}</label>
          {required && !disabled && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
        </div>
      )}
      <div
        className={`d-flex flex-row align-items-center ${
          inputHelperState.borderClass && 'kt-phone-input-' + inputHelperState.borderClass
        } kt-phone-input-container ${disabled ? 'kt-phone-input-container-disabled' : ''} ${
          touched && isInvalid ? 'is-invalid form-control' : ''
        }`}
      >
        {showCountrySelect && (
          <CountrySelect
            className="kt-phone-input-country-container"
            onSelect={handleCountrySelect}
            countryCode={countryCode}
            disabled={disabled}
          />
        )}
        {/* show code flag instead of select since we arent supporting changing code currently */}
        {countryCodeToFlag(countryCode) ? (
          <img className="mr-3" src={countryCodeToFlag(countryCode)} alt={countryCode} />
        ) : (
          <small className="mr-3 mt-1">{countryCode}</small>
        )}

        <MaskedInput
          id={id}
          name={name}
          mask={countryCodeToMask(countryCode)}
          guide={true}
          className={`kt-phone-input ${disabled ? 'kt-phone-input-disabled' : ''}`}
          placeholder={countryCodeToPlaceholder(countryCode)}
          onChange={handleChange}
          value={formattedValue()}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={disabled}
          autoComplete="off"
        />
        {inputHelperState.icon && (
          <div className="d-flex input-icon-container">
            <FontAwesomeIcon
              className="input-icon"
              icon={inputHelperState.icon}
              color={`${inputHelperState.iconColor}`}
            />
          </div>
        )}
      </div>
      <div className="invalid-feedback">{errorText}</div>
    </div>
  );
};

export default PhoneInput;
