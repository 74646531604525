import React, { useCallback, useEffect, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import CreditCardInputs from 'shared/components/CreditCardInputs';
import { getCardValidation } from 'shared/components/CreditCardInputs/utils';
import errors from 'shared/constants/errorMessages';
import AddressInput from 'shared/components/AddressInput/AddressInputWithAutoComplete';
import Checkbox from 'shared/components/Checkbox';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'shared/util/string';

const newCardInfo: ICreditCardInput = {
  name: '',
  method: 0,
  ccv: '',
  number: '',
  expiration: '',
  zip: '',
};

const newAddress: IAddress = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: 'US',
  postalCode: '',
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (formData: ICreateAccountCreditCardInput) => Promise<void>;
  billingAddressOptions?: IAddress[];
  contact?: IContact;
}

const AddCreditCardModal: React.FC<IProps> = ({ isOpen, contact, onClose, onSave, billingAddressOptions = [] }) => {
  const { t } = useTranslation(['billing']);
  const [cardInfo, setCardInfo] = useState(newCardInfo);
  const [isCardValid, setIsCardValid] = useState(false);
  const [address, setAddress] = useState({ ...newAddress });
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [isAutoPay, setIsAutoPay] = useState<boolean>(false);
  const [termsAndConditionsConfirmed, setTermsAndConditionsConfirmed] = useState<boolean>(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const { address1, city, state, country, postalCode } = address;
  const cardValidation = getCardValidation(cardInfo);

  const formNotComplete = !cardInfo.name || !cardInfo.number || !cardInfo.ccv || !cardInfo.expiration;

  const handleClose = useCallback(() => {
    onClose();
    setCardInfo(newCardInfo);
    setAddress(newAddress);
    setIsDefault(false);
    setIsAutoPay(false);
    setStep(1);
  }, [onClose]);

  useEffect(() => {
    billingAddressOptions.length && setAddress(billingAddressOptions[0] ?? newAddress);
  }, [billingAddressOptions, isOpen]);

  const handleSave = useCallback(async () => {
    setLoading(true);
    await onSave({
      ...cardInfo,
      ...address,
      state: address.state.split('-')[1], // State is in format US-NY. Only need NY.
      zip: address.postalCode,
      isDefault,
      isAutoPay,
      termsAndConditionsConfirmed,
      contact,
    });
    handleClose();
    setLoading(false);
  }, [onSave, cardInfo, address, isDefault, isAutoPay, termsAndConditionsConfirmed, handleClose]);

  useEffect(() => {
    if (contact) {
      setCardInfo({ ...cardInfo, name: getFullName(contact) });
    }
  }, [contact]);

  return (
    <SideModalDrawer
      title={step === 1 ? 'Step 1: Input Card Details' : 'Step 2: Input Billing Address'}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice={step === 1 ? 'Continue' : 'Save'}
      primaryCallback={step === 1 ? () => setStep(2) : handleSave}
      primaryButtonProps={{
        disabled:
          !cardValidation.isCardPotentiallyValid ||
          !isCardValid ||
          (step === 2 && [address1, city, state, country, postalCode].some((x) => !x)),
        loading: loading,
      }}
      secondaryChoice={step === 1 ? 'Cancel' : 'Back'}
      secondaryCallback={step === 1 ? handleClose : () => setStep(1)}
      closeOnSecondaryCallback={false}
      enforceFocus={false}
      footerHelperText={formNotComplete ? errors.formRequirements : ''}
    >
      {step === 1 ? (
        <div>
          <CreditCardInputs
            cardInfo={cardInfo}
            updateCardInfo={setCardInfo}
            updateIsCardValid={setIsCardValid}
            contact={contact}
          />
          {(!cardValidation.isCardPotentiallyValid || !isCardValid) && !formNotComplete && (
            <div className="text-danger form-text small mb-4">Invalid credit card information.</div>
          )}
          <Checkbox
            value={isDefault}
            onChange={setIsDefault}
            label={t('billing:payment-methods.mark-as-default-checkbox-label')}
            className="mb-4"
          />
          <Checkbox
            value={isAutoPay}
            onChange={setIsAutoPay}
            label={t('billing:payment-methods.auto-pay-checkbox-label')}
            className="mb-4"
          />
        </div>
      ) : (
        <div>
          <AddressInput required address={address} onChange={setAddress} showHelperText={false} />
        </div>
      )}
    </SideModalDrawer>
  );
};

export default AddCreditCardModal;
