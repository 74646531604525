import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { orderBy, sortBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionDropdown from 'shared/components/ActionDropdown';
import { IconButtonCircle } from 'shared/components/Buttons';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { ColoredBackgroundTag, TagsTableCell } from 'shared/components/Tag';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { DIRECTIONS, PREDICATES, SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { accountStatusColorHexes } from 'shared/constants/tagColors';
import useDatatableState from 'shared/hooks/useDatatableState';
import { useGetActiveCentersQuery } from 'shared/hooks/useGetActiveCenters';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { capitalize, stringToHueDegree } from 'shared/util/string';
import AccountChildrenColumn from '../AccountChildrenColumn';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import Currency from 'shared/components/Currency';
import { useGetCenterTagOptions } from '../../../../../Centers/subroutes/Profile/graphql/queries';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers';
import { useGetSortedAccounts } from 'gql/account/queries';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const { TERM } = SEARCH_EXPRESSIONS;
const { CONTAINS } = PREDICATES;

interface IProps {
  showAddCenterToAccountModal: (account: IAccount) => void;
}

const AccountsTable: React.FC<IProps> = ({ showAddCenterToAccountModal }) => {
  const user = useSelector((state: RootState) => state.user);
  const isInternal = user?.isInternal ?? false;
  const { data: activeCentersData } = useGetActiveCentersQuery();
  const { data: getAllowedEntitiesData } = useGetAllowedEntities(!isInternal);
  const { k2AccountTags } = useFlags();

  const { t } = useTranslation();
  const hasBillingPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasCreateAccountPermission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });
  const hasEditAccountPermission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const hasViewChildPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasReadAccountTagsPermission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Tags,
    level: RoleLevelType.Read,
  });

  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: DIRECTIONS.ASCENDING }];
  const [tableState, tableFunctions] = useDatatableState('account', initalTableSort);
  const history = useHistory();
  const accountData = useSelector((state: RootState) => state.accounts.all ?? []);
  const totalResults = useSelector((state: RootState) => state.accounts.totalResults ?? 0);

  const navigateTo = useCallback(
    (route: string, params: Record<string, string> = {}) => {
      history.push(route, params);
    },
    [history]
  );

  const { data: accountsPagedResult, loading } = useGetSortedAccounts({
    variables: {
      input: {
        from: tableState.activePage,
        size: tableState.pageSize,
        centerIds: tableState.selectedFilters.center?.map((c) => c.value) ?? [],
        sort: tableState.sort,
        searchKey: tableState.searchExpressions.reduce((acc, e) => (acc + ' ' + e.term?.value).trim(), ''),
        tagIds: tableState.selectedFilters.tags?.map((tag) => tag.value) ?? [],
        centreTagIds: tableState.selectedFilters.centerTags?.map((centreTag) => centreTag.value) ?? [],
        statusType:
          !!tableState.selectedFilters.status && !!tableState.selectedFilters.status.length
            ? tableState.selectedFilters.status.length > 1
              ? undefined
              : (tableState.selectedFilters.status.map((s) => s.value)[0] as AccountStatusType)
            : undefined,
        statusAtDate: moment().format('YYYY-MM-DD'),
      },
    },
  });

  const { data: centerTags } = useGetCenterTagOptions(user?.entityId || '');

  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Account)?.data?.getTagsUsedAcrossEntity || [];

  const centerOptions: ITableFilterOption[] = useMemo(
    () =>
      orderBy(activeCentersData?.searchCenters.data ?? [], (center) => center.name.toLocaleLowerCase(), 'asc').map(
        (center: ICenter) => ({
          label: center.name,
          value: center.id,
          searchExpression: {
            [SEARCH_EXPRESSIONS.TERM]: {
              field: 'centerId.keyword',
              predicate: PREDICATES.CONTAINS,
              value: center.id,
            },
          },
        })
      ),
    [activeCentersData]
  );

  const statusOptions: ITableFilterOption[] = [
    {
      label: capitalize(t('spelling.active')),
      value: 'Active',
      indicatorColor: accountStatusColorHexes.Active,
    },
    {
      label: capitalize(t('spelling.inactive')),
      value: 'Inactive',
      indicatorColor: accountStatusColorHexes.Inactive,
    },
  ];

  const businessOptions = useMemo(
    () =>
      orderBy(getAllowedEntitiesData?.getAllowedEntities ?? [], (business) => business.name, 'asc').map(
        (business: IBusiness) => ({
          label: business.name,
          value: business.id,
          searchExpression: {
            [SEARCH_EXPRESSIONS.TERM]: {
              field: 'entityId.keyword',
              predicate: PREDICATES.CONTAINS,
              value: business.id,
            },
          },
        })
      ),
    [getAllowedEntitiesData]
  );

  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name'])
    .map((tag) => ({
      label: tag.name,
      value: tag.id,
      searchExpression: { [TERM]: { field: 'tags.keyword', predicate: CONTAINS, value: tag.id } },
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const centerTagOptions: ITableFilterOption[] = orderBy(
    centerTags?.getCenterTagOptions ?? [],
    (tag) => tag.name.toLocaleLowerCase(),
    'asc'
  ).map((tag) => ({
    label: tag.name,
    value: tag.id,
    searchExpression: {
      [SEARCH_EXPRESSIONS.TERM]: { field: 'centerTagIds.keyword', predicate: PREDICATES.CONTAINS, value: tag.id },
    },
  }));

  const clearActiveFilters = useCallback(() => {
    tableFunctions.updateSelectedFilters({});
  }, [tableFunctions]);

  const handleBusinessFilterSelect = useCallback(
    (values: ITableFilterOption[]) => {
      tableFunctions.updateSelectedFilters({
        ...tableState.selectedFilters,
        entity: values,
      });
    },
    [tableFunctions, tableState]
  );

  const handleChangeSizePerPage = (value: number) => {
    tableFunctions.changeSizePerPage(value);
    // after changing size per page, update the current page to page 1
    // this way the system will not think you are on a page that may no longer 'exist'
    tableFunctions.changePage(1, value);
  };

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigateToAccountProfile = (accountId: string) => {
    navigateTo(`/families/accounts/${accountId}/profile`);
  };

  return (
    <DataTable
      noPadding
      data={accountsPagedResult?.getSortedAccounts?.data ?? accountData}
      dataSize={totalResults ?? 0}
      pageSize={tableState.pageSize}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={(number) => handleChangeSizePerPage(number)}
      activePage={tableState.activePage}
      onSort={tableFunctions.updateSort}
      showLoadingOverlay={loading}
      showSelect={false}
      handleRowClick={(event, row: IAccount) => navigateToAccountProfile(row.id)}
      columns={[
        {
          headerAttrs: {
            id: 'account-name-header-column-cell',
          },
          text: 'Account Name',
          sort: true,
          dataField: 'name',
          formatter: (cell: string, row: IAccount) => (
            <AvatarDataTableCell
              avatar=""
              initials={row.name.charAt(0).toUpperCase()}
              primaryText={row.name}
              color={`hsl(${stringToHueDegree(row.id)}, ${stringToHueDegree(row.id) < 50 ? '100%' : '40%'}, 40%`}
            />
          ),
        },
        ...(hasViewChildPermission
          ? [
              {
                headerAttrs: {
                  id: 'account-children-header-column-cell',
                },
                text: 'Children',
                dataField: 'children',
                formatter: (cell: string, row: IAccount) => (
                  <AccountChildrenColumn
                    children={(row.children ?? []).filter((c) => !c.archivedAt) ?? []}
                    navigateToChildProfile={(child) => navigateTo(`/families/children/${child.id}`)}
                  />
                ),
              },
            ]
          : []),
        centerOptions.length > 1
          ? {
              headerAttrs: {
                id: 'account-center-header-column-cell',
              },
              text: fieldLabels.center,
              dataField: 'center.name',
            }
          : null,
        {
          headerAttrs: {
            id: 'account-status-header-column-cell',
          },
          text: capitalize(t('spelling.status')),
          dataField: 'status',
          formatter: (cell: any, row: IAccount) => (
            <div className="d-flex">
              <ColoredBackgroundTag color={accountStatusColorHexes[row.status]} text={row.status} />
            </div>
          ),
        },
        hasReadAccountTagsPermission && k2AccountTags
          ? {
              text: t('families.accounts.account-tags'),
              dataField: 'tags',
              formatter: (_: any, row: IAccount) => row.tags && <TagsTableCell tags={row.tags} />,
            }
          : null,
        hasBillingPermission && {
          headerAttrs: {
            id: 'account-balance-header-column-cell',
          },
          dataField: 'balance',
          text: 'Balance',
          align: 'right',
          headerAlign: 'right',
          formatter: (balance: number) => (
            <div>
              <Currency aggregate themed={false} amount={balance} />
            </div>
          ),
        },
        {
          text: 'Actions',
          align: 'center',
          dataField: '',
          headerClasses: 'text-center',
          formatter: (cell: any, row: IAccount) => (
            <ActionDropdown
              actions={[
                {
                  label: hasEditAccountPermission ? 'Edit Account' : 'View Account',
                  onClick: () => navigateToAccountProfile(row.id),
                },
                ...(hasCreateAccountPermission
                  ? [
                      {
                        label: `Add to ${fieldLabels.center}`,
                        onClick: () => showAddCenterToAccountModal(row),
                      },
                    ]
                  : []),
              ]}
            />
          ),
        },
      ]}
      renderHeader={(paginationProps: any) => (
        <TableHeader className="flex-wrap align-items-center">
          <div className="d-flex flex-wrap mr-auto">
            <SizePerPage paginationProps={paginationProps} />
            <TableSearch
              placeholder="Search Accounts"
              className={isMobile ? 'my-2 mb-1' : ''}
              onChange={(search) => {
                tableFunctions.updateSearchExpressions([{ term: { field: '', predicate: 'CONTAINS', value: search } }]);
                tableFunctions.changePage(1, tableState.pageSize);
              }}
            />
          </div>
          <div className="d-flex flex-wrap align-items-center">
            {isInternal && (
              <DropdownFilter
                title="Business"
                className={isMobile ? 'my-1 mr-4' : 'mr-4'}
                selectedFilters={tableState.selectedFilters.entity}
                options={businessOptions}
                onFilterSelect={handleBusinessFilterSelect}
              />
            )}
            <DropdownFilter
              className={isMobile ? 'mt-2 pl-0 ml-2' : 'my-0 mx-2 p-0'}
              title={t('families.contacts.center-tags')}
              selectedFilters={tableState.selectedFilters.centerTags}
              options={centerTagOptions}
              onFilterSelect={(val) => {
                tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, centerTags: val });
                tableFunctions.changePage(1, tableState.pageSize);
              }}
            />
            {centerOptions.length > 1 && (
              <DropdownFilter
                title={fieldLabels.center}
                className={isMobile ? 'my-1 mr-4' : 'mr-4'}
                selectedFilters={tableState.selectedFilters.center}
                options={centerOptions}
                onFilterSelect={(val) => {
                  tableFunctions.updateSelectedFilters({
                    ...tableState.selectedFilters,
                    center: val,
                  });
                  tableFunctions.changePage(1, tableState.pageSize);
                }}
              />
            )}
            <DropdownFilter
              title={capitalize(t('spelling.status'))}
              className={isMobile ? 'my-1 mr-4' : 'mr-4'}
              selectedFilters={tableState.selectedFilters.status}
              options={statusOptions}
              onFilterSelect={(val) => {
                tableFunctions.updateSelectedFilters({
                  ...tableState.selectedFilters,
                  status: val,
                });
                tableFunctions.changePage(1, tableState.pageSize);
              }}
            />
            {k2AccountTags && (
              <DropdownFilter
                title={t('families.accounts.account-tags')}
                className={isMobile ? ' my-1 mr-4' : 'mr-4'}
                selectedFilters={tableState.selectedFilters.tags}
                options={tagOptions}
                onFilterSelect={(val) => {
                  tableFunctions.updateSelectedFilters({
                    ...tableState.selectedFilters,
                    tags: val,
                  });
                  tableFunctions.changePage(1, tableState.pageSize);
                }}
              />
            )}
            <IconButtonCircle
              icon={faTimes}
              onClick={clearActiveFilters}
              tooltipDirection="bottom"
              tooltipText="Clear Filters"
              className={isMobile ? 'mx-auto' : ''}
            />
          </div>
        </TableHeader>
      )}
    />
  );
};

export default AccountsTable;
