import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { discountFields } from './fields';

interface IGetDiscountsData {
  getDiscountsForBusiness: IDiscount[];
}

interface IGetDiscountsFilterData {
  getDiscountsWithFilters: IDiscount[];
}

interface IGetDiscountsVariables {
  businessId: string;
}

interface IGetDiscountsWithFiltersVariables {
  input: {
    businessId: string;
    centerId?: string;
    discountType?: DiscountType;
    start?: string;
    end?: string;
  };
}

export const GET_DISCOUNTS_FOR_BUSINESS = (fields: string = discountFields) => gql`
  query ($businessId: ID!) {
    getDiscountsForBusiness(businessId: $businessId) {
      ${fields}
    }
  }
`;

export const GET_DISCOUNTS_WITH_FILTER = (fields: string = discountFields) => gql`
  query ($input: GetDiscountsWithFiltersInput!) {
    getDiscountsWithFilters(input: $input) {
      ${fields}
    }
  }
`;

export const useGetDiscountsForBusiness = (
  options?: QueryHookOptions<IGetDiscountsData, IGetDiscountsVariables>,
  fields?: string
) =>
  useQuery<IGetDiscountsData, IGetDiscountsVariables>(GET_DISCOUNTS_FOR_BUSINESS(fields), {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useGetDiscountsWithFilters = (
  options?: QueryHookOptions<IGetDiscountsFilterData, IGetDiscountsWithFiltersVariables>,
  fields?: string
) =>
  useQuery<IGetDiscountsFilterData, IGetDiscountsWithFiltersVariables>(GET_DISCOUNTS_WITH_FILTER(fields), {
    fetchPolicy: 'network-only',
    ...options,
  });
