import React from 'react';
import moment from 'moment';
import { ToolbarProps } from 'react-big-calendar';
import WeekPicker from 'shared/components/DateInput/WeekPicker';

interface IProps {
  toolbar: ToolbarProps;
  startDate: moment.Moment;
  endDate: moment.Moment;
  onDateChange: (dates: { startDate: moment.Moment; endDate: moment.Moment }) => void;
}

const CalendarToolbar: React.FC<IProps> = ({ startDate, endDate, toolbar, onDateChange, ...props }) => {
  return (
    <div className="rbc-toolbar">
      <div>
        <WeekPicker startDate={startDate} endDate={endDate} onChange={onDateChange} reactDatesController="RANGE" />
      </div>
    </div>
  );
};

export default CalendarToolbar;
