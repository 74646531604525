import React from 'react';
import Card from 'shared/components/Card';
import { PersonAvatar } from 'shared/components/Avatar';
import { RowWithMb, Row, Col } from 'shared/components/Layout';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { Circle, LoadingLines } from 'shared/components/LoadingSkeletons';
import { getFullName } from 'shared/util/string';

interface IProps {
  person: IPerson;
  subtitle?: string;
  onClick?: () => void;
  loading?: boolean;
  className?: string;
  hasAdditionalActions?: boolean;
}

const PersonCard: React.FC<IProps> = ({
  loading,
  person,
  subtitle,
  onClick,
  className,
  hasAdditionalActions = true,
}) => {
  if (loading) {
    return (
      <Card className={`person-card ${className || ''}`}>
        <Col>
          {hasAdditionalActions && (
            <Row justify="end" className="mt-n4 mr-n4">
              <IconButtonCircle icon={faEllipsisV} iconSize="2x" />
            </Row>
          )}
          <RowWithMb justify="center">
            <Circle size="xl" />
          </RowWithMb>
          <LoadingLines number={2} />
        </Col>
      </Card>
    );
  }

  return (
    <Card onClick={onClick} className={`person-card text-center ${className || ''}`}>
      <Col>
        {hasAdditionalActions && (
          <Row justify="end" className="mt-n4 mr-n4">
            <IconButtonCircle icon={faEllipsisV} onClick={onClick} iconSize="2x" />
          </Row>
        )}
        <RowWithMb justify="center">
          <PersonAvatar person={person} size="xl" />
        </RowWithMb>
        <Row className="mb-2" justify="center">
          <small>{subtitle}</small>
        </Row>
        <Row justify="center">
          <p className="text-uppercase">{getFullName(person)}</p>
        </Row>
      </Col>
    </Card>
  );
};

export default PersonCard;
