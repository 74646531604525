import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { APIError } from './queries/useBillingApi';
import confusedRoo from 'shared/images/confused-roo.svg';
import RenderWhen from 'US/Utilities/Components/RenderWhen';
import './styles.scss';

const defaultMessage = 'Whoops we could not find that right now. Please try again later.';

export function ErrorContent(props: { onTryAgain?: () => void; error?: APIError }) {
  const { onTryAgain = () => {} } = props;
  const isDev = process.env.NODE_ENV !== 'production';
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Stack direction="row" alignItems="center">
        {!isSmallScreen && <img src={confusedRoo} alt="confused kangaroo image" className="confused-roo-img" />}

        <div>
          <Typography>{props.error?.publicMessage() ?? defaultMessage}</Typography>
          {props.error?.isRetryable() && (
            <Box textAlign="center">
              <Button size="small" variant="outlined" onClick={() => onTryAgain()}>
                Try Again
              </Button>
            </Box>
          )}
          {isDev && <Typography color="red">Error Message: {props.error?.message ?? ''}</Typography>}
        </div>
      </Stack>
    </>
  );
}
