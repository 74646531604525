import React, { useEffect, useState } from 'react';
import { ButtonAsLink } from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import PhoneInput from 'shared/components/PhoneInput';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { practitionerTypes } from './util';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import config from 'config';
import { useGetHealthProfessionalByChildId } from 'gql/healthProfessional/queries';
import { HealthProfessionalModal } from './HealthProfessionalModal';
import { CreateButton } from 'shared/components/Buttons';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import Avatar from 'shared/components/Avatar';
import { stringToHueDegree } from 'shared/util/string';
import AccountLink from 'shared/components/AccountLink/AccountLink';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import _mapValues from 'lodash/mapValues';
import Select from 'shared/components/Select';

const stateOptions = _mapValues(COUNTRY_INFO, (country) =>
  Object.entries(country.subdivisions).map((arr) => ({ value: arr[0], label: arr[1] }))
);

type ContentProps = {
  data: IHealthProfessional[];
  isOpen: boolean;
  openModal: (value: IHealthProfessional) => void;
};

const HealthProfessionalsContent = (props: ContentProps) => {
  const countryCode = DEFAULT_COUNTRY;
  const fieldLabels = COUNTRY_INFO[countryCode].fieldLabels;
  const handleCanEdit = (healthProfessional: IHealthProfessional) => {
    if (props.isOpen) return;
    props.openModal(healthProfessional);
  };

  if (props.data.length === 0) {
    return (
      <div className={'no-data'}>
        <strong>No health professionals have been reported for this child.</strong>
        <br />
        Click the 'Add Health Professional' button above to add a new health professional to this child profile.
      </div>
    );
  }

  return (
    <div className="health-professionals">
      {props.data.map((healthProfessional, index) => {
        const readablePractitionerType = practitionerTypes.find(
          (item) => item.value === healthProfessional.professionalType
        );
        const defaultCountryCode = config.locale.region as CountryCode;
        const phoneNumberData = healthProfessional.phoneNumber
          ? parsePhoneNumberFromString(healthProfessional.phoneNumber, defaultCountryCode)
          : undefined;
        return (
          <div className="health-professional" key={`HealthProfessional_${index}`}>
            <div className="d-flex flex-row justify-content-between">
              <div>
                <small className="practitioner-type-pill p-1 rounded border">
                  <strong>{readablePractitionerType?.label ?? healthProfessional.professionalType}</strong>
                </small>
              </div>
              {!props.isOpen && (
                <ButtonAsLink className="sm" onClick={() => handleCanEdit(healthProfessional)}>
                  <FontAwesomeIcon icon={faPen} className="mr-2" />
                  Edit
                </ButtonAsLink>
              )}
            </div>

            <div className="d-flex flex-column">
              <Row className="last-form-row mb-3">
                <Col sm={6}>
                  <TextInput
                    disabled
                    label="Practitioner's Name"
                    value={healthProfessional.firstName + ' ' + healthProfessional.lastName}
                  />
                </Col>
                <Col lg={6}>
                  <TextInput disabled label="Practice" value={healthProfessional.practice} />
                </Col>
              </Row>

              <Row className="last-form-row mb-3">
                <Col sm={6}>
                  {phoneNumberData ? (
                    <div className="phone d-flex flex-column form-control__container">
                      <label className="form-label">Phone Number</label>
                      <a
                        href={`tel:${healthProfessional.phoneNumber}`}
                        className="d-flex form-control form-control__container_inner"
                      >
                        <div className="align-self-end dark-gray">{phoneNumberData.formatNational()}</div>
                      </a>
                    </div>
                  ) : (
                    <PhoneInput
                      className={'phone'}
                      label="Phone Number"
                      value={healthProfessional.phoneNumber}
                      disabled
                    />
                  )}
                </Col>
                <Col lg={6}>
                  <TextInput
                    disabled
                    label="Address"
                    value={`${
                      healthProfessional.address.address2 && healthProfessional.address.address2.length > 0
                        ? `${healthProfessional.address.address2}, `
                        : ''
                    }${healthProfessional.address.address1}`}
                  />
                </Col>
              </Row>

              <Row className="last-form-row">
                <Col lg={6}>
                  <TextInput disabled label={fieldLabels.city} value={healthProfessional.address.city} />
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col sm={6}>
                      <Select
                        label={fieldLabels.subdivision}
                        options={stateOptions[countryCode]}
                        value={
                          stateOptions[countryCode].find(
                            (option) => option.value === healthProfessional.address.state
                          ) ?? null
                        }
                        disabled
                      />
                    </Col>
                    <Col lg={6}>
                      <TextInput
                        disabled
                        label={fieldLabels.postalCode}
                        value={healthProfessional.address.postalCode}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        );
      })}
    </div>
  );
};

type Props = {
  childId: string;
  childAccounts: {
    accountChildId: string;
    accountId: string;
    accountName: string;
    centerId: string;
    centerName: string;
  }[];
  childAccountOptions: { label: string; value: string }[];
};

const HealthProfessionals = (props: Props) => {
  const { loading, data } = useGetHealthProfessionalByChildId(props.childId);
  const [professionals, setProfessionals] = useState<IHealthProfessional[]>(
    data ? data.getHealthProfessionalByChildId : []
  );

  const updateProfessional = (healthProfessional: IHealthProfessional) => {
    setProfessionals(
      (professionals ?? []).map((a) => (a.id === healthProfessional.id ? { ...healthProfessional } : a))
    );
  };

  const createProfessional = (healthProfessional: IHealthProfessional) => {
    setProfessionals([...(professionals ?? []), healthProfessional]);
  };

  const deleteProfessional = (healthProfessional: IHealthProfessional) => {
    setProfessionals(professionals.filter((a) => a.id !== healthProfessional.id));
  };

  useEffect(() => {
    setProfessionals(data ? data.getHealthProfessionalByChildId : []);
  }, [data]);

  const [modal, setModal] = useState<true | IHealthProfessional | null>(null);
  const openModal = (value: IHealthProfessional) => setModal(value);
  const openBlankModal = () => setModal(true);
  const closeModal = () => setModal(null);

  return (
    <div className="d-flex flex-column">
      <HasRoleAreaLevel
        action={{ area: AreaType.Child, permission: PermissionType.Documents, level: RoleLevelType.Create }}
      >
        <CreateButton className={'mb-4 align-self-end'} onClick={openBlankModal}>
          Add Health Professional
        </CreateButton>
      </HasRoleAreaLevel>
      <Card
        bodyClassName={'health-professionals-list'}
        header={'Health Professionals'}
        loading={loading}
        loadingLines={2}
      >
        {props.childAccounts.map((accountDetails, index) => {
          return (
            <div
              className={'list-by-account'}
              key={`HealthProfessional_ChildAccount_${accountDetails.centerId}_${accountDetails.accountId}_${accountDetails.accountChildId}_${index}`}
            >
              <Row noGutters justify="between" className="mb-6 pb-2 account-divider">
                <div className="flex-grow-1">
                  <div className="d-flex">
                    <Avatar
                      color={`hsl(${stringToHueDegree(accountDetails.accountName)}, ${
                        stringToHueDegree(accountDetails.accountName) < 50 ? '100%' : '40%'
                      }, 40%`}
                      size={'sm'}
                      initials={accountDetails.accountName.charAt(0).toUpperCase()}
                      image={''}
                      className="mr-3"
                    />
                    <div>
                      <p className="small-font mb-0">Account name:</p>
                      <p className="mb-0 account-link">
                        <AccountLink
                          accountId={accountDetails.accountId}
                          accountName={accountDetails.accountName}
                          openInNewTab
                        />
                      </p>
                      <p className="small-font mb-0">
                        <i>{accountDetails.centerName}</i>
                      </p>
                    </div>
                  </div>
                </div>
              </Row>
              <HealthProfessionalsContent
                data={professionals.filter((p) =>
                  p.linkedAccountChildren.find(
                    (accountChildren) => accountChildren.accountChildId === accountDetails.accountChildId
                  )
                )}
                isOpen={!!modal}
                openModal={openModal}
              />
            </div>
          );
        })}
      </Card>
      {modal && (
        <HealthProfessionalModal
          isOpen={!!modal}
          editingProfessional={modal === true ? undefined : modal}
          onClose={closeModal}
          professionals={professionals}
          update={updateProfessional}
          create={createProfessional}
          delete={deleteProfessional}
          childId={props.childId}
          childAccountOptions={props.childAccountOptions}
        />
      )}
    </div>
  );
};

export default HealthProfessionals;
