export const healthProfessionalFields: string = `
    id
    professionalType
    firstName
    lastName
    practice
    phoneNumber
    address {
      address1
      address2
      city
      state
      postalCode
      country
    }
    linkedAccountChildren {
      accountChildId
      childId
      accountId
      accountName
      childFirstName
      childLastName
    }
    businessId
`;
