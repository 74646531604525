import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAccountPrimaryPaymentMethod } from 'gql/paymentMethod/account/mutations';
import { useDispatch } from 'react-redux';
import { showToast } from 'shared/components/Toast';
import { setAccountDefaultPaymentMethod } from 'pages/Families/subroutes/Accounts/duck/actions';
import PaymentMethodSelectionModal from 'shared/components/PaymentMethodSelectionModal/PaymentMethodSelectionModal';

interface IProps {
  isOpen: boolean;
  accountId: string;
  paymentMethods: IPaymentMethod[];
  onClose: () => void;
}

const DefaultPaymentModal: React.FC<IProps> = ({ isOpen, accountId, paymentMethods, onClose }) => {
  const { t } = useTranslation(['billing']);
  const dispatch = useDispatch();
  const [primaryMethod, setPrimaryMethod] = useState<IPaymentMethod | null>(null);

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      const defaultPayment = paymentMethods.find((p) => p.isPrimary);
      setPrimaryMethod(defaultPayment ?? null);
    }
  }, [paymentMethods, isOpen]);

  const [setAccountPrimaryPaymentMethodFn, { loading }] = useSetAccountPrimaryPaymentMethod({
    onCompleted: (result) => {
      dispatch(setAccountDefaultPaymentMethod(accountId, result.setPrimaryPaymentMethod?.primaryPaymentMethodId ?? ''));
      showToast(t('billing:payment-methods.default-payment-method-success'), 'success');
      handleDismiss();
    },
  });

  const handleDismiss = useCallback(() => {
    setPrimaryMethod(null);
    onClose();
  }, [paymentMethods, onClose]);

  const handleSubmit = useCallback(() => {
    if (primaryMethod) {
      setAccountPrimaryPaymentMethodFn({
        variables: {
          input: {
            accountId: accountId as string,
            paymentMethodId: primaryMethod.id,
          },
        },
      });
    }
  }, [primaryMethod, accountId]);

  return (
    <PaymentMethodSelectionModal
      selectedMethod={primaryMethod}
      paymentMethods={paymentMethods}
      isOpen={isOpen}
      title={t('billing:payment-methods.default-payment-modal.title')}
      description={t('billing:payment-methods.default-payment-modal.description')}
      loading={loading}
      onHide={handleDismiss}
      handleSubmit={handleSubmit}
      collapseText=""
      onChange={(paymentMethod: IPaymentMethod) => setPrimaryMethod(paymentMethod)}
      termsAndConditionsConfirmed={false}
      setTermsAndConditionsConfirmed={() => {}}
    />
  );
};

export default DefaultPaymentModal;
