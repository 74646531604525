import React from 'react';
import { sortBy } from 'lodash';
import Collapse from 'react-bootstrap/Collapse';
import Checkbox from 'shared/components/Checkbox';
import { Col, Row } from 'shared/components/Layout';
import { useGetChildContactPermissions } from 'gql/contact/queries';
import { getFullName } from 'shared/util/string';
import { Link } from 'react-router-dom';

interface IProps {
  contact?: IContact;
  accountOptions: IAccount[];
  accountPermissions: IAccountPermissionsInput[];
  setAccountPermissions: (ap: IAccountPermissionsInput[]) => void;
  canAddAndRemoveAccounts: boolean;
}

const AccountPermissionsInputs: React.FC<IProps> = ({
  contact,
  accountOptions,
  accountPermissions,
  setAccountPermissions,
  canAddAndRemoveAccounts,
  ...props
}) => {
  const { data: permissionsData } = useGetChildContactPermissions();
  const permissionsOptions = permissionsData?.getChildContactRelationshipPermissions ?? [];
  return (
    <ul className="unstyled-list">
      {sortBy(accountOptions, (acc) => acc.name).map((account: IAccount, idx: number) => {
        const permissionsForAccount = accountPermissions.find((ap) => ap.accountId === account.id);
        return (
          <li className="mb-4" key={`child-contact-account-${account.id}-${idx}`}>
            <Row>
              <Col>
                <div className="d-flex align-items-center mb-2">
                  {canAddAndRemoveAccounts && (
                    <Checkbox
                      value={Boolean(permissionsForAccount)}
                      onChange={(checked) =>
                        setAccountPermissions(
                          checked
                            ? [...accountPermissions, { accountId: account.id, permissions: [] }]
                            : accountPermissions.filter((ap) => ap.accountId !== account.id)
                        )
                      }
                    />
                  )}
                  <div className="d-flex flex-column ml-2 text-truncate">{account.name}</div>
                </div>
                <Collapse in={Boolean(permissionsForAccount)}>
                  <div className="ml-8">
                    {permissionsOptions.map((permission, idx) => (
                      <Checkbox
                        key={`permission-${permission.value}-${idx}`}
                        label={permission.label}
                        disabled={permissionsForAccount?.isPrimary}
                        value={permissionsForAccount?.permissions.includes(permission.value)}
                        onChange={(checked) =>
                          setAccountPermissions(
                            accountPermissions.map((ap) =>
                              ap.accountId === account.id
                                ? {
                                    ...ap,
                                    permissions: checked
                                      ? [...ap.permissions, permission.value]
                                      : ap.permissions.filter((p) => p != permission.value),
                                  }
                                : ap
                            )
                          )
                        }
                      />
                    ))}
                    {permissionsForAccount?.isPrimary && (
                      <small>
                        {getFullName(contact)} is a primary contact on this account.{' '}
                        <Link to={`/families/accounts/${account.id}/contacts`}>Go to the account profile</Link> and make
                        them a non-primary contact in order to edit permissions.
                      </small>
                    )}
                  </div>
                </Collapse>
              </Col>
            </Row>
          </li>
        );
      })}
    </ul>
  );
};

export default AccountPermissionsInputs;
