import React, { useState } from 'react';
import CardLoadingSkeleton from 'pages/Families/subroutes/Accounts/Account/CardLoadingSkeleton';
import UpdateContactPermissionsModalForm from './UpdateContactPermissionsModalForm';
import RemoveContactFromAccountModal from './RemoveContactFromAccountModal';
import { Col, Row } from 'shared/components/Layout';
import { orderBy } from 'lodash';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import AccountContactsTable from './AccountContactsTable';
import AddContactModalForm from './AddContactModalForm/AddContactModalForm';
import { CreateButton } from 'shared/components/Buttons';
import PrimaryContactCard from 'shared/components/PrimaryContactCard';
import { groupBy } from 'lodash';
import { useCallback } from 'react';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { getPaymentMethodsByContact } from '../BillingTab/OverviewTabV2/utils/getPaymentMethodsByContact';

interface IEditModalState {
  isOpen: boolean;
  setPrimary: boolean;
  setNonPrimary: boolean;
  contact: IContact | null;
}

const editModalClosedState = {
  isOpen: false,
  setPrimary: false,
  setNonPrimary: false,
  contact: null,
};

interface IProps {
  account: IAccount;
  loading: boolean;
}

const ContactsTab: React.FC<IProps> = ({ account, loading }) => {
  const [addContactModalState, setAddContactModalState] = useState({
    isOpen: false,
    isPrimary: false,
  });
  const [editModalState, setEditModalState] = useState<IEditModalState>(editModalClosedState);
  const [contactToRemove, setContactToRemove] = useState<IContact | null>(null);
  const [removedContact, setRemovedContact] = useState<IContact | null>(null);
  const primaryContacts = orderBy(account?.contacts ?? [], 'firstname')
    .filter((c) => c.isPrimary)
    .filter((contact) => {
      if (removedContact) {
        return contact.id !== removedContact.id;
      } else {
        return contact;
      }
    });
  const nonPrimaryContacts = [
    // @ts-ignore
    ...new Map(
      orderBy(account?.contacts ?? [], 'firstname')
        .filter((c) => !c.isPrimary)
        .map((item) => [item['id'], item])
    ).values(),
  ];

  const relationshipsForAccount = useCallback(
    (contact: IContact): [string, IContactChild[]][] => {
      const { children: accountChildren } = account;
      const { children: childrenWithRelationship } = contact;
      const childIdsOnAccount = accountChildren.map((ac) => ac.id);
      const children = childrenWithRelationship.filter((c) => childIdsOnAccount.includes(c.childId ?? ''));

      return Object.entries(groupBy(children, (c) => c.relationshipType));
    },
    [account]
  );

  const paymentMethodsPerContact = getPaymentMethodsByContact(account?.paymentMethods ?? []);

  return (
    <div>
      <Row className="mb-4" noGutters>
        <h4 className="my-4">Primary Contacts</h4>
        <HasRoleAreaLevel
          action={{
            area: AreaType.Contact,
            permission: PermissionType.Base,
            level: RoleLevelType.Create,
          }}
        >
          <CreateButton className="ml-auto" onClick={() => setAddContactModalState({ isOpen: true, isPrimary: true })}>
            Add Contact
          </CreateButton>
          {/*<CreateButton className='ml-auto' onClick={() => setAddContactModalState({ isOpen: true, isPrimary: true })}>Add Primary Contact</CreateButton>*/}
        </HasRoleAreaLevel>
      </Row>
      {loading ? (
        <Row noGutters>
          {' '}
          {[1, 2].map((i) => (
            <CardLoadingSkeleton className="mb-4 mr-4" key={i} />
          ))}
        </Row>
      ) : (
        <Row className="flex-wrap  mb-4">
          {primaryContacts.map((contact, i) => (
            <Col lg={6} xl={4} key={i}>
              <PrimaryContactCard
                canRemove={primaryContacts.length > 1}
                contact={contact}
                className="h-100 mb-4"
                onClickEdit={() =>
                  setEditModalState((prev) => ({
                    ...prev,
                    isOpen: true,
                    contact,
                  }))
                }
                onRemoveRelationship={() => setContactToRemove(contact)}
                onRemovePrimary={() =>
                  setEditModalState((prev) => ({
                    ...prev,
                    isOpen: true,
                    contact,
                    setNonPrimary: true,
                  }))
                }
                relationshipsOnAccount={relationshipsForAccount(contact)}
              />
            </Col>
          ))}
        </Row>
      )}
      <Row noGutters className="mb-4">
        <h4 className="my-4">Contacts</h4>
        <HasRoleAreaLevel
          action={{
            area: AreaType.Contact,
            permission: PermissionType.Base,
            level: RoleLevelType.Create,
          }}
        >
          {/*<CreateButton className='ml-auto' onClick={() => setAddContactModalState({ isOpen: true, isPrimary: false })}>Add Contact</CreateButton>*/}
        </HasRoleAreaLevel>
      </Row>
      {loading ? (
        <DataTableLoadingSkeleton />
      ) : (
        <div className="m-n8">
          <AccountContactsTable
            onEdit={(contact) => setEditModalState((prev) => ({ ...prev, isOpen: true, contact }))}
            onSetPrimary={(contact) =>
              setEditModalState((prev) => ({
                ...prev,
                isOpen: true,
                contact,
                setPrimary: true,
              }))
            }
            onRemove={setContactToRemove}
            account={account}
            contacts={nonPrimaryContacts}
          />
        </div>
      )}
      {account && (
        <AddContactModalForm
          // isPrimary={addContactModalState.isPrimary}
          isOpen={addContactModalState.isOpen}
          onClose={() => setAddContactModalState({ isOpen: false, isPrimary: false })}
          account={account}
          setRemovedContact={setRemovedContact}
        />
      )}
      {account && editModalState.contact && (
        <UpdateContactPermissionsModalForm
          contact={editModalState.contact}
          isOpen={editModalState.isOpen}
          onClose={() => setEditModalState(editModalClosedState)}
          setAsPrimary={editModalState.setPrimary}
          setAsNonPrimary={editModalState.setNonPrimary}
          account={account}
          paymentMethods={paymentMethodsPerContact[editModalState.contact?.id]}
        />
      )}
      {account && contactToRemove && (
        <RemoveContactFromAccountModal
          contact={contactToRemove}
          paymentMethods={paymentMethodsPerContact[contactToRemove?.id]}
          isOpen={Boolean(contactToRemove)}
          onClose={() => setContactToRemove(null)}
          setRemovedContact={setRemovedContact}
          account={account}
        />
      )}
    </div>
  );
};

export default ContactsTab;
