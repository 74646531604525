import React, { useCallback, useState } from 'react';
import { Row, Col } from 'shared/components/Layout';
import AddCcssCenterApprovalModal from './AddCcssApprovalModal';
import EditCcssCenterApprovalModal from './EditCcssApprovalModal';
import Card from 'shared/components/Card';
import { CreateButton, IconButtonCircle } from 'shared/components/Buttons';
import DataTable from 'shared/components/DataTable';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import { useGetCcssApprovalsForCenter } from '../../graphql/queries';
import { useDeleteCcssApproval } from '../../graphql/mutations';
import { faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { showToast } from 'shared/components/Toast';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import PermissionActions from 'shared/constants/enums/actionEnum';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import CpavForm from './CpavForm/CpavForm';
import CcmsApprovalTable from './CcmsApprovalTable/CcmsApprovalTable';
import AddCcmsApprovalModal, { AddCcmsApprovalFormSubmit } from './AddCcmsApprovalModal/AddCcmsApprovalModal';
import { useGetCcmsApproval } from 'gql/ccmsApprovals/queries';
import { useCreateCcmsApproval, useDeleteCcmsApproval, useEditCcmsApproval } from 'gql/ccmsApprovals/mutations';
import EditCcmsApprovalModal from './AddCcmsApprovalModal/EditCcmsApprovalModal/EditCcmsApprovalModal';
import { omitTypename } from 'shared/util/object';
import { useGetCurrentCpavForCentreService } from 'gql/cpav/queries';
import { useSetCurrentCpavForCentreService } from 'gql/cpav/mutations';

interface IProps {
  center: ICenter;
}

const CcssDetailsTab: React.FC<IProps> = ({ center }) => {
  const [ccssApprovals, setCcssApprovals] = useState<ICcssApproval[]>([]);
  const [isCreateApprovalModalOpen, setCreateApprovalModalOpen] = useState(false);
  const [isEditApprovalModalOpen, setEditApprovalModalOpen] = useState(false);
  const [approvalInEdit, setApprovalInEdit] = useState<ICcssApproval | null>(null);
  const [isCreateCcmsApprovalModalOpen, setIsCreateCcmsApprovalModalOpen] = useState(false);
  const [isEditCcmsApprovalModalOpen, setIsEditCcmsApprovalModalOpen] = useState(false);
  const [ccmsApprovalInEdit, setCcmsApprovalInEdit] = useState<ICcmsApproval | null>(null);

  const [ccmsApprovals, setCcmsApprovals] = useState<ICcmsApproval[]>([]);

  const user = useSelector((state: RootState) => state.user);
  const hasFullCcssAccess = user?.hasPermission(PermissionActions.FULL_CCSS) ?? false;

  const { loading: loadingCcss } = useGetCcssApprovalsForCenter({
    variables: {
      centerId: center?.id,
    },
    onCompleted: (data) => setCcssApprovals(data.getCcssApprovalsForCenter),
  });

  const { loading: loadingCcms } = useGetCcmsApproval({
    variables: {
      businessId: center?.entityId,
      centerId: center?.id,
    },
    onCompleted: (data) => {
      setCcmsApprovals([omitTypename(data.getCcmsApproval) as ICcmsApproval]);
    },
  });
  const [createCcmsApproval] = useCreateCcmsApproval();
  const handleCcmsApprovalCreate = (data: AddCcmsApprovalFormSubmit) =>
    createCcmsApproval({ variables: { input: { ...data, centerId: center.id, businessId: center.entityId } } }).then(
      (result) => {
        if (result.data) setCcmsApprovals([...ccmsApprovals, result.data.createCcmsApproval]);
      }
    );

  const [deleteCcmsApproval] = useDeleteCcmsApproval();
  const handleCcmsApprovalDelete = (ccmsApproval: ICcmsApproval) =>
    deleteCcmsApproval({
      variables: { input: { id: ccmsApproval.id, centerId: center.id, businessId: center.entityId } },
    })
      .then(() => {
        setCcmsApprovals(ccmsApprovals.filter((x) => x.id !== ccmsApproval.id));
        showToast('CCMS approval deleted successfully.', 'success');
      })
      .catch((err) => showToast(`${err.toString()}`, 'error'));

  const onCcmsEditClick = (ccmsApproval: ICcmsApproval) => {
    setCcmsApprovalInEdit(ccmsApproval);
    setIsEditCcmsApprovalModalOpen(true);
  };

  const [editCcmsApproval] = useEditCcmsApproval();
  const handleCcmsApprovalEdit = (data: AddCcmsApprovalFormSubmit) =>
    editCcmsApproval({
      variables: { input: { ...data, centerId: center.id, businessId: center.entityId, id: ccmsApprovals[0].id } },
    }).then((result) => {
      if (result.data) setCcmsApprovals([result.data.editCcmsApproval]);
    });

  const [deleteApproval] = useDeleteCcssApproval();

  const handleApprovalAdded = useCallback(
    (ccssApproval: ICcssApproval) => {
      setCcssApprovals([...ccssApprovals, ccssApproval]);
    },
    [ccssApprovals]
  );

  const handleApprovalEdited = useCallback(
    (ccssApproval: ICcssApproval) => {
      setCcssApprovals([...ccssApprovals.filter((a) => a.id !== ccssApproval.id), ccssApproval]);
    },
    [ccssApprovals]
  );

  const dismissCreateApprovalModal = useCallback(() => {
    setCreateApprovalModalOpen(false);
  }, []);

  const dismissEditApprovalModal = useCallback(() => {
    setEditApprovalModalOpen(false);
  }, []);

  const onEditClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
    setEditApprovalModalOpen(true);
    const approvalInEdit = ccssApprovals.find((a) => a.id === id);
    approvalInEdit && setApprovalInEdit(approvalInEdit);
  };

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const onDeleteClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
    const approvalToDelete = ccssApprovals.find((a) => a.id === id);
    approvalToDelete &&
      deleteApproval({
        variables: {
          id: approvalToDelete.id,
          centerId: center.id,
        },
      })
        .then((ccssApprovalDeleted) => {
          if (ccssApprovalDeleted.data?.deleteCcssApproval.id) {
            setCcssApprovals(ccssApprovals.filter((a) => a.id !== ccssApprovalDeleted.data?.deleteCcssApproval.id));
            showToast('Approval deleted successfully.', 'success');
          }
        })
        .catch((err) => {
          showToast('There was an error.', 'error');
        });
  };

  const [serviceCpav, setServiceCpav] = useState<IServiceCpav>();
  const { loading: loadingServiceCpav } = useGetCurrentCpavForCentreService({
    variables: {
      input: {
        businessId: center?.entityId,
        centerId: center?.id,
        govServiceId: ccssApprovals[0]?.serviceId,
      },
    },
    skip: !center || !ccssApprovals[0],
    onCompleted: (data) => setServiceCpav(omitTypename(data.getCurrentCpavForCentreService, true) as IServiceCpav),
  });

  const [setCurrentCpavForCentreService] = useSetCurrentCpavForCentreService();
  const handleOnCpavFormSubmit = (data: IServiceCpavRequest) => {
    setCurrentCpavForCentreService({
      variables: {
        input: {
          centerId: center.id,
          businessId: center.entityId,
          govServiceId: ccssApprovals[0].serviceId,
          cpavRequest: data,
        },
      },
    })
      .then((data) => {
        showToast('Updated CPAV.', 'success');
        setServiceCpav(data.data?.setCurrentCpavForCentreService);
      })
      .catch((error) => showToast(error.toString(), 'error'));
  };

  return (
    <>
      <Row align="start">
        <Col>
          <Card
            header={
              <div className="d-flex align-items-center">
                <div>{`${fieldLabels.center} Approvals`}</div>
              </div>
            }
          >
            <Card
              loading={loadingCcss}
              header={
                <div className="d-flex align-items-center">
                  <div>CCSS Approvals</div>
                  {hasFullCcssAccess && (
                    <CreateButton
                      className="ml-auto"
                      id="add-approval-btn"
                      onClick={() => setCreateApprovalModalOpen(true)}
                    >
                      Add Approval
                    </CreateButton>
                  )}
                </div>
              }
            >
              {loadingCcss ? (
                <DataTableLoadingSkeleton />
              ) : (
                <DataTable
                  className="approval-table"
                  data={ccssApprovals}
                  dataSize={ccssApprovals.length}
                  showSelect={false}
                  showPagination={false}
                  noPadding
                  columns={[
                    {
                      text: 'Care Type',
                      dataField: 'careType',
                      sort: true,
                    },
                    {
                      text: 'CCSS Service ID',
                      dataField: 'serviceId',
                      sort: true,
                    },
                    {
                      text: 'CCSS Service Name',
                      dataField: 'serviceName',
                      sort: true,
                    },
                    {
                      text: 'CCSS Start Date',
                      dataField: 'startDate',
                      sort: true,
                      formatter: (startDate: string) => moment(startDate).format('DD/MM/YYYY'),
                    },
                    {
                      text: '',
                      dataField: '',
                      sort: false,
                      align: 'center',
                      formatter: (cell: string, row: any) => {
                        return hasFullCcssAccess ? (
                          <div>
                            <IconButtonCircle
                              className="ml-auto"
                              onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => onEditClick(event, row.id)}
                              size="sm"
                              icon={faPencilAlt}
                            />
                            <IconButtonCircle
                              className="ml-4"
                              onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
                                onDeleteClick(event, row.id)
                              }
                              size="sm"
                              icon={faTrashAlt}
                            />
                          </div>
                        ) : null;
                      },
                    },
                  ]}
                />
              )}
            </Card>
            <Card
              header={
                <div className="d-flex align-items-center">
                  <div>CCMS Approvals</div>
                  {hasFullCcssAccess && ccmsApprovals.length === 0 && (
                    <CreateButton
                      className={`ml-auto`}
                      id="add-approval-btn"
                      onClick={() => setIsCreateCcmsApprovalModalOpen(true)}
                    >
                      Add Approval
                    </CreateButton>
                  )}
                </div>
              }
            >
              <CcmsApprovalTable
                data={ccmsApprovals}
                {...(hasFullCcssAccess && {
                  onEditClick: (ccmsApproval) => onCcmsEditClick(ccmsApproval),
                  onDeleteClick: (ccmsApproval) => handleCcmsApprovalDelete(ccmsApproval),
                })}
              />
            </Card>
          </Card>
          <Card header={'Care Provided And Vacancy Details'} loading={loadingServiceCpav}>
            <CpavForm
              data={serviceCpav}
              centerName={center?.name}
              onSubmit={handleOnCpavFormSubmit}
              isLoading={loadingServiceCpav || loadingCcss || loadingCcms}
            />
          </Card>
        </Col>
      </Row>

      {isCreateApprovalModalOpen && (
        <AddCcssCenterApprovalModal
          isOpen={isCreateApprovalModalOpen}
          onClose={dismissCreateApprovalModal}
          centerId={center.id}
          onApprovalAddedSuccess={handleApprovalAdded}
        ></AddCcssCenterApprovalModal>
      )}

      {isEditApprovalModalOpen && approvalInEdit !== null && (
        <EditCcssCenterApprovalModal
          isOpen={isEditApprovalModalOpen}
          onClose={dismissEditApprovalModal}
          approvalInEdit={approvalInEdit}
          approvalEditedSuccess={handleApprovalEdited}
          centerId={center.id}
        ></EditCcssCenterApprovalModal>
      )}

      <AddCcmsApprovalModal
        show={hasFullCcssAccess && isCreateCcmsApprovalModalOpen}
        onHide={() => setIsCreateCcmsApprovalModalOpen(false)}
        onSubmit={(data) => handleCcmsApprovalCreate(data)}
      />

      <EditCcmsApprovalModal
        show={hasFullCcssAccess && isEditCcmsApprovalModalOpen}
        onHide={() => setIsEditCcmsApprovalModalOpen(false)}
        onSubmit={(data) => handleCcmsApprovalEdit(data)}
        formDataPopulation={ccmsApprovalInEdit ?? {}}
      />
    </>
  );
};

export default CcssDetailsTab;
