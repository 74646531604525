import React from 'react';
import moment from 'moment';
import Avatar from 'shared/components/Avatar';
import { getFullName } from 'shared/util/string';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  staff: IStaff;
}

const AvatarCell: React.FC<IProps> = ({ staff, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const name = getFullName(staff);
  const colorCode =
    staff.classAssignments?.find((p) => !p.endsAt || moment(p.endsAt).isAfter(moment()))?.colorCode ?? null;

  return (
    <td>
      <div className="d-flex flex-row align-items-center flex-wrap overflow-hidden" title={name}>
        <div
          className="kt-schedules-employee-schedule-table-avatar-cell-class-color d-none d-md-block"
          style={{ backgroundColor: colorCode || 'transparent' }}
        />
        <Avatar size="md" initials={name.charAt(0).toUpperCase()} className="mx-2" image={staff.avatar?.url ?? ''} />
        {!isMobile && <div>{name}</div>}
      </div>
    </td>
  );
};

export default AvatarCell;
