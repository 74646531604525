import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';

export const UPSERT_BUSINESS_FEATURE = gql`
  mutation ($input: IUpsertBusinessFeatureInput!) {
    upsertBusinessFeature(input: $input) {
      businessId
      type
      enabled
      subFeatures {
        id
        type
        enabled
      }
    }
  }
`;

export const useUpsertBusinessFeature = (
  input?: MutationHookOptions<{ upsertBusinessFeature: IBusinessFeature }, IUpsertBusinessFeature>
) => useMutation<{ upsertBusinessFeature: IBusinessFeature }, IUpsertBusinessFeature>(UPSERT_BUSINESS_FEATURE, input);
