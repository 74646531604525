import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { contactFields, childContactFields } from './fields';

interface ICreateContactForChildData {
  createContactForChild: IChildContact;
}

interface IUpdateContactForChildData {
  updateChildContact: IChildContact;
}

interface ICreateContactForChildVariables {
  input: {
    childId: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    phoneNumber: {
      number?: string;
      type?: string;
    };
    relationship: string;
    accounts: {
      accountId: string;
      permissions: string[];
    }[];
    sendContactInvites?: boolean;
  };
}

interface IUpdateContactForChildVariables {
  input: {
    childId: string;
    contactId: string;
    relationship: string;
    accounts: {
      accountId: string;
      permissions: string[];
    }[];
  };
}

interface IDeleteChildContactRelationshipData {
  deleteChildContactRelationship: IContact;
}

interface IDeleteChildContactRelationshipVariables {
  childId: string;
  contactId: string;
  accountIds: string[];
}

export const CREATE_CONTACT_FOR_CHILD = (fields: string = childContactFields) => gql`
  mutation ($input: CreateContactForChildInput!) {
    createContactForChild(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_CHILD_CONTACT_RELATIONSHIP = (fields: string = contactFields) => gql`
  mutation ($childId: ID!, $contactId: ID!, $accountIds: [ID!]!) {
    deleteChildContactRelationship(childId: $childId, contactId: $contactId, accountIds: $accountIds) {
      ${fields}
    }
  }
`;

export const UPDATE_CONTACT_FOR_CHILD = (fields: string = childContactFields) => gql`
  mutation ($input: UpdateChildContactInput!) {
    updateChildContact(input: $input) {
      ${fields}
    }
  }
`;

export const useCreateContactForChild = (
  options?: MutationHookOptions<ICreateContactForChildData, ICreateContactForChildVariables>,
  fields?: string
) =>
  useMutation<ICreateContactForChildData, ICreateContactForChildVariables>(CREATE_CONTACT_FOR_CHILD(fields), options);

export const useDeleteChildContactRelationship = (
  options?: MutationHookOptions<IDeleteChildContactRelationshipData, IDeleteChildContactRelationshipVariables>,
  fields?: string
) =>
  useMutation<IDeleteChildContactRelationshipData, IDeleteChildContactRelationshipVariables>(
    DELETE_CHILD_CONTACT_RELATIONSHIP(fields),
    options
  );

export const useUpdateContactForChild = (
  options?: MutationHookOptions<IUpdateContactForChildData, IUpdateContactForChildVariables>,
  fields?: string
) =>
  useMutation<IUpdateContactForChildData, IUpdateContactForChildVariables>(UPDATE_CONTACT_FOR_CHILD(fields), options);
