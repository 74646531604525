import React from 'react';
import RBCard from 'react-bootstrap/Card';
import ClassEnrollmentsTable from '../ClassEnrollmentsTable';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  enrollments: IEnrollment[];
  availability: IClassAvailability[];
  loading: boolean;
  refetchData: any;
  centerTimezone: string;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const ClassEnrollmentsCard: React.FC<IProps> = ({
  enrollments,
  availability,
  loading,
  refetchData,
  centerTimezone,
  ...props
}) => {
  return (
    <RBCard className="mx-0 mt-0 mb-3 ">
      <RBCard.Header>
        <div className="d-flex">
          <div>{fieldLabels.enrollment}s</div>
        </div>
      </RBCard.Header>
      <RBCard.Body className="pt-0 mt-5">
        <div className="d-flex flex-row align-items-center mt-1 mb-3">
          <div className="d-flex">
            <div className="d-inline-block mr-2 bg-yellow" style={{ height: 24, width: 24 }} />
            Age Up Threshold met
          </div>
        </div>
        <SpinnerOverlay show={loading}>
          <ClassEnrollmentsTable
            enrollments={enrollments}
            availability={availability}
            centerTimezone={centerTimezone}
          />
        </SpinnerOverlay>
      </RBCard.Body>
    </RBCard>
  );
};

export default ClassEnrollmentsCard;
