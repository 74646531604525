import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { GET_ME } from 'shared/hooks/useGetMe';

interface IUpdateMyProfileVariables {
  input: {
    firstname: string;
    lastname: string;
    dob: string;
    email: string;
    phoneNumber: string;
    nickname: string;
    address: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
}

interface IUpdateMyProfileData {
  updateMyProfile: IPerson | IStaff;
}

interface IUpdateOnboardingStatusVariables {
  input: {
    onboardingStatus: OnboardingStatus;
  };
}

interface IUpdateUpdateRestoreIdVariables {
  input: {
    restoreId: string;
  };
}

interface IUpdateOnboardingStatusData {
  updateOnboardingStatus: IPerson | IStaff;
}

interface IUpdateUpdateRestoreIdData {
  updateRestoreId: IPerson | IStaff;
}

interface IUpdateMyEmergencyContactsVariables {
  input: {
    primaryEmergencyContact: IEmergencyContact;
    additionalEmergencyContacts: IEmergencyContact[] | null;
  };
}

interface IUpdateMyEmergencyContactsData {
  updateMyEmergencyContacts: IStaff;
}

interface IUpdateMyAvailabilityVariables {
  input: {
    days: IDayAvailability[];
  };
}

interface IUpdateMyAvailabilityData {
  requestAvailabilityChangeForMe: IStaff;
}

const UPDATE_MY_PROFILE = gql`
  mutation ($input: UpdateMyProfileInput!) {
    updateMyProfile(input: $input) {
      __typename
      id
      firstname
      lastname
      nickname
      email
      dob
      phoneNumber
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }
    }
  }
`;

const UPDATE_ONBOARDING_STATUS = gql`
  mutation ($input: UpdateOnboardingStatusInput!) {
    updateOnboardingStatus(input: $input) {
      id
      onboardingStatus
    }
  }
`;

const UPDATE_RESTORE_ID = gql`
  mutation ($input: UpdateRestoreIdInput!) {
    updateRestoreId(input: $input) {
      id
    }
  }
`;

const UPDATE_MY_EMERGENCY_CONTACTS = gql`
  mutation ($input: UpdateMyEmergencyContactsInput!) {
    updateMyEmergencyContacts(input: $input) {
      id
      primaryEmergencyContact {
        firstname
        lastname
        phoneNumber
        phoneNumberType
        relationship
      }
      additionalEmergencyContacts {
        firstname
        lastname
        phoneNumber
        phoneNumberType
        relationship
      }
    }
  }
`;

const UPDATE_EMAIL = gql`
  mutation ($input: UpdateEmailInput!) {
    updateEmail(input: $input) {
      success
      person {
        id
        email
        ... on Staff {
          isEmailVerified
        }
      }
    }
  }
`;

const UPDATE_MY_AVAILABILITY = gql`
  mutation ($input: RequestAvailabityChangeForMeInput!) {
    requestAvailabilityChangeForMe(input: $input) {
      id
      availability {
        approved {
          dayOfWeek
          times {
            start
            end
          }
        }
      }
    }
  }
`;

export const useUpdateMyProfile = () => useMutation<IUpdateMyProfileData, IUpdateMyProfileVariables>(UPDATE_MY_PROFILE);
export const useUpdateOnboardingStatus = () =>
  useMutation<IUpdateOnboardingStatusData, IUpdateOnboardingStatusVariables>(UPDATE_ONBOARDING_STATUS);
export const useUpdateRestoreId = () =>
  useMutation<IUpdateUpdateRestoreIdData, IUpdateUpdateRestoreIdVariables>(UPDATE_RESTORE_ID);

export const useUpdateMyEmergencyContacts = () =>
  useMutation<IUpdateMyEmergencyContactsData, IUpdateMyEmergencyContactsVariables>(UPDATE_MY_EMERGENCY_CONTACTS);

export const useUpdateEmail = () => useMutation<IUpdateEmailData, IUpdateEmailVariables>(UPDATE_EMAIL);

export const useUpdateMyAvailability = () =>
  useMutation<IUpdateMyAvailabilityData, IUpdateMyAvailabilityVariables>(UPDATE_MY_AVAILABILITY, {
    update: (proxy, result) => {
      if (result.data?.requestAvailabilityChangeForMe) {
        const { availability } = result.data?.requestAvailabilityChangeForMe;
        const cachedResult: { getMe: IStaff } | null = proxy.readQuery({ query: GET_ME });
        if (cachedResult?.getMe) {
          proxy.writeQuery({
            query: GET_ME,
            data: { getMe: { ...cachedResult.getMe, availability } },
          });
        }
      }
    },
  });
