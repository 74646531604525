import React, { useCallback } from 'react';
import { Page, Section } from 'shared/types/enrollment-form';
import { cloneDeep, set } from 'lodash';

export const UNTITLED_SECTION = 'Untitled Section';

type SectionName = string;

interface SectionSelectorContextShape {
  lastSelectedSections: {
    [pageTitle: string]: SectionName;
  };
  lastSelectedSection: SectionName;
  setLastSelectedSection: (sectionName: SectionName) => void;
  sectionsCanBeSelected: Section[];
  setSectionsCanBeSelected: (sections: Section[]) => void;
}

interface SectionSelectorContextProviderProps {
  children: React.ReactNode;
  pages: Page[];
  currentPage: Page;
}

export const SectionSelectorContext = React.createContext<SectionSelectorContextShape | null>(null);

export default (props: SectionSelectorContextProviderProps) => {
  const defaultValue: SectionSelectorContextShape['lastSelectedSections'] = props.pages.reduce(
    (acc, page) => ({ ...acc, [page.label]: page.sections[0]?.label ?? UNTITLED_SECTION }),
    {}
  );

  const [lastSelectedSections, setLastSelectedSections] = React.useState(defaultValue);
  const [sectionsCanBeSelected, setSectionsCanBeSelected] = React.useState<Section[]>([]);

  const setLastSelectedSection = useCallback(
    (sectionName: SectionName) => {
      const mutableLastSelectedSections = cloneDeep(lastSelectedSections);
      set(mutableLastSelectedSections, props.currentPage.label, sectionName);
      setLastSelectedSections(mutableLastSelectedSections);
    },
    [lastSelectedSections, props.currentPage]
  );

  const value = React.useMemo(
    () => ({
      lastSelectedSections,
      lastSelectedSection: lastSelectedSections[props.currentPage.label] ?? UNTITLED_SECTION,
      setLastSelectedSection,
      sectionsCanBeSelected,
      setSectionsCanBeSelected,
    }),
    [lastSelectedSections, setLastSelectedSection, props.currentPage, sectionsCanBeSelected, setSectionsCanBeSelected]
  );

  return <SectionSelectorContext.Provider value={value}>{props.children}</SectionSelectorContext.Provider>;
};
