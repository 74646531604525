import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DateInput from 'shared/components/DateInput';
import Col from 'shared/components/Layout/Col';
import Row from 'shared/components/Layout/Row';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Select from 'shared/components/Select';
import TextInput, { EmailInput } from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import { isValidPhoneNumber, parsePhoneNumberFromStringWithRegion } from 'shared/util/string';
import Checkbox from 'shared/components/Checkbox';
import {
  accsDocumentTypeEnum,
  ccsRiskReasons,
  ccsStateTerritoryBodyEnum,
  exceptionalCircumstanceReasons,
} from 'shared/constants/enums/ccssEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { TinyDeleteButton } from 'shared/components/Buttons';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { cloneDeep, compact, get, groupBy, orderBy, uniqBy } from 'lodash';
import { useCreateCcssCertificate } from 'gql/ccssCertificates/mutations';
import PhoneInput from 'shared/components/PhoneInput';
import { ICertificateModalControlsProps } from './types';
import { getCcsCertificateForAccountChild } from '../../../../duck/actions';
import { COUNTRY_CODES } from 'shared/components/PhoneInput/countryCodes';
import { isEmailValid } from 'shared/util/email';
import { ApolloError } from '@apollo/client';
import DeclarationAccordion, { DeclarationType } from './DeclarationAccordion';
import { useGetCcsCertificatesForChildren } from 'gql/ccssCertificates/queries';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import colors from '_colors.module.scss';
import Switch from 'shared/components/Switch';

const submitDateFormat = moment.HTML5_FMT.DATE;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  account: IAccount;
  selectedChildId?: string;
  modalControlsProps?: ICertificateModalControlsProps;
  baseCertificateInput?: ICreateCcssCertificateInput;
  title?: string;
  onValidateForm?: (
    setFormErrors: (error: IFormErrors) => void,
    setIsFormValid: (isValid: boolean) => void,
    certificate: ICreateCcssCertificateInput
  ) => void;
  onSubmit?: (certificate: ICreateCcssCertificateInput, account: IAccount, handleClose: () => void) => void;
  loadingExternal?: boolean;
}

function getCertStartDate(certEndDateStr: string): string {
  const certEndDate = moment(certEndDateStr);

  // Certificate end dates should be a monday but just in case it isn't we will
  // get the Monday of the week that it falls in. So if the end date was Thursday, we
  // would get the Monday previous and not the Monday for next week.

  const daysToAdd = 7 - certEndDate.day() - 6;
  const startDate = certEndDate.add(daysToAdd, 'days');

  return startDate.toString();
}

const startDateMinimum = new Date(2018, 7, 2);
const dateFormat = 'DD/MM/YYYY';

const newCertificate: ICreateCcssCertificateInput = {
  businessId: '',
  accountId: '',
  centerId: '',
  startDate: moment().day('Monday').format(submitDateFormat),
  children: [],
  evidenceHeld: undefined,
  weeksAtRisk: 0,
  isDeclarationGiven: false,
  riskReasons: [],
  stateTerritoryBody: {
    noticeGivenDate: '',
    noticeToStateTerritory: false,
    notifiedByStateTerritory: false,
    organisationName: '',
  },
};

const newStateTerritoryBody = {
  noticeGivenDate: '',
  noticeToStateTerritory: false,
  notifiedByStateTerritory: false,
  organisationName: '',
};

const weeksAtRiskOptions = [1, 2, 3, 4, 5, 6];

const yesNoOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const acceptedFileExts = ['.pdf', '.png', '.tiff', '.jpg'];

const noticeOptions = [
  {
    label: 'The provider has notified a State or Territory body',
    value: 'ToState',
  },
  {
    label: 'Provider was notified by a State or Territory body',
    value: 'ByState',
  },
];

export interface IFormErrors {
  businessId: string;
  accountId: string;
  centerId: string;
  children: string;
  evidenceHeld: string;
  startDate: string;
  weeksAtRisk: string;
  isDeclarationGiven: string;
  stateTerritoryBody: {
    noticeGivenDate: string;
    notice: string;
    notifiedByPersonFirstName?: string;
    notifiedByPersonLastName?: string;
    organisationName: string;
    statePersonEmail?: string;
    statePersonNameOrId?: string;
    statePersonPhoneNumber?: string;
    stateReferenceNumber?: string;
    text?: string;
    type?: string;
  };
  riskReasons: string;
  supportingDocuments: {
    file: string;
    error: string;
  }[];
}

export const getEmptyErrorState = (): IFormErrors => {
  const errors: IFormErrors = {
    businessId: '',
    accountId: '',
    centerId: '',
    children: '',
    evidenceHeld: '',
    startDate: '',
    weeksAtRisk: '',
    isDeclarationGiven: '',
    stateTerritoryBody: {
      noticeGivenDate: '',
      notice: '',
      notifiedByPersonFirstName: '',
      notifiedByPersonLastName: '',
      organisationName: '',
      statePersonEmail: '',
      statePersonNameOrId: '',
      statePersonPhoneNumber: '',
      stateReferenceNumber: '',
      text: '',
      type: '',
    },
    riskReasons: '',
    supportingDocuments: [],
  };

  return errors;
};

const areFormFieldsValid = (
  certificate: ICreateCcssCertificateInput,
  previousCertificates: ICcssCertificate[]
): [boolean, IFormErrors] => {
  const errors = getEmptyErrorState();
  let hasError = false;
  const emptyChildAndRisk = (certificate.weeksAtRisk > 1 || certificate.weeksAtRisk < 6) && certificate.evidenceHeld;
  const dateAfterRisk = certificate.children.length > 0 && certificate.riskReasons.length > 0;
  const endDate = moment(certificate.startDate).add(certificate.weeksAtRisk, 'weeks').subtract(1, 'day');

  if (!certificate.startDate) {
    errors.startDate = 'Start date is required';
    hasError = true;
  }

  if (certificate.startDate && moment(certificate.startDate).isBefore(moment(startDateMinimum))) {
    errors.startDate = `Start date cannot be before ${moment(startDateMinimum).format(dateFormat)}`;
    hasError = true;
  }

  if (
    previousCertificates
      .filter((cert) => cert.status === 'APPROV')
      .some(
        (cert) =>
          moment(certificate.startDate).isSameOrBefore(moment(cert.endDate)) &&
          moment(endDate).isSameOrAfter(moment(cert.startDate))
      )
  ) {
    errors.startDate =
      'At risk period of the certificate is overlapping with an existing certificate for the same child/service combination.';
    hasError = true;
  }

  if (
    certificate.startDate &&
    !moment(certificate.startDate).isBetween(moment().subtract(13, 'weeks'), moment(), 'days', '[]') &&
    moment(certificate.startDate).isSameOrBefore(moment(), 'days')
  ) {
    errors.startDate = `Start date cannot be more than 13 weeks before submission date`;
    hasError = true;
  }

  if (certificate.weeksAtRisk < 1 || certificate.weeksAtRisk > 6) {
    errors.weeksAtRisk = 'Weeks at risk must be between 1 and 6';
    hasError = true;
  }

  if (!certificate.evidenceHeld) {
    errors.evidenceHeld = 'Evidence held is required';
    hasError = true;
  }

  if (emptyChildAndRisk && certificate.children.length === 0) {
    errors.children = 'At least 1 child needs to be selected';
    hasError = true;
  }

  if (emptyChildAndRisk && certificate.riskReasons.length === 0) {
    errors.riskReasons = 'At least 1 reason needs to be selected';
    hasError = true;
  }
  if (certificate.stateTerritoryBody) {
    if (!certificate.stateTerritoryBody.noticeGivenDate && dateAfterRisk) {
      errors.stateTerritoryBody.noticeGivenDate = 'Notice date is required';
      hasError = true;
    }

    if (
      !certificate.stateTerritoryBody.noticeToStateTerritory &&
      !certificate.stateTerritoryBody.notifiedByStateTerritory
    ) {
      errors.stateTerritoryBody.notice = 'Notice is required';
      hasError = true;
    }

    if (
      certificate.stateTerritoryBody.statePersonEmail &&
      !isEmailValid(certificate.stateTerritoryBody.statePersonEmail)
    ) {
      errors.stateTerritoryBody.statePersonEmail = 'Email is invalid';
      hasError = true;
    }

    if (certificate.stateTerritoryBody.noticeToStateTerritory && !certificate.stateTerritoryBody.organisationName) {
      errors.stateTerritoryBody.organisationName = 'Organisation name is required';
      hasError = true;
    }

    if (!certificate.stateTerritoryBody.type) {
      errors.stateTerritoryBody.type = 'Body type is required';
      hasError = true;
    }

    if (certificate.stateTerritoryBody.notifiedByStateTerritory) {
      const hasACC003 = certificate.supportingDocuments?.find((doc) => doc?.type === 'ACC003');
      if (!hasACC003) {
        hasError = true;
        errors.supportingDocuments.push({
          error: '204k notice is required when notified by state territory',
          file: '',
        });
      }
    }

    if (
      certificate.stateTerritoryBody.statePersonPhoneNumber &&
      !isValidPhoneNumber(certificate.stateTerritoryBody.statePersonPhoneNumber)
    ) {
      errors.stateTerritoryBody.statePersonPhoneNumber = 'Phone number provided is invalid';
      hasError = true;
    }
  }

  if (!certificate.isDeclarationGiven) {
    errors.isDeclarationGiven = 'You must declare the information provided is true and correct';
    hasError = true;
  }

  for (const doc of certificate.supportingDocuments ?? []) {
    if (!doc.type) {
      hasError = true;
      errors.supportingDocuments.push({
        error: 'Document type is required',
        file: doc.file.name,
      });
    }
  }

  return [!hasError, errors];
};

function formatCreateCcssCertificateDates(certificateInput: ICreateCcssCertificateInput) {
  const { stateTerritoryBody } = certificateInput;

  const result: ICreateCcssCertificateInput = {
    ...certificateInput,
    startDate: moment(certificateInput.startDate).format(submitDateFormat),
    ...(stateTerritoryBody && {
      stateTerritoryBody: {
        ...stateTerritoryBody,
        noticeGivenDate: moment(stateTerritoryBody.noticeGivenDate).format(submitDateFormat),
      },
    }),
  };
  return result;
}

const CreateCertificateModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  account,
  selectedChildId,
  modalControlsProps,
  baseCertificateInput = cloneDeep(newCertificate),
  title = 'Create Certificate',
  onValidateForm,
  onSubmit,
  loadingExternal,
}) => {
  const dispatch = useDispatch();
  const [certificate, setCertificate] = useState<ICreateCcssCertificateInput>({
    ...baseCertificateInput,
    children: selectedChildId ? [selectedChildId] : [],
  });
  const [isFormValid, setIsFormValid] = useState(true);
  const [formErrors, setFormErrors] = useState<IFormErrors>(getEmptyErrorState());

  const [shouldSubmitWithStateTerritory, setShouldSubmitWithStateTerritory] = useState(true);

  useEffect(() => {
    const { stateTerritoryBody, ...rest } = certificate;
    // Clear ST if not required for validation
    if (!shouldSubmitWithStateTerritory && stateTerritoryBody) return setCertificate(rest);
    // repopulate mandatory fields if required
    if (shouldSubmitWithStateTerritory && !stateTerritoryBody)
      return setCertificate({
        ...rest,
        stateTerritoryBody: { ...newStateTerritoryBody },
      });
  }, [baseCertificateInput.stateTerritoryBody, certificate, shouldSubmitWithStateTerritory]);

  const { data: previousCertificates, loading: previousCertLoading } = useGetCcsCertificatesForChildren(
    account.entityId,
    account.centerId,
    account.id,
    compact(certificate.children)
  );

  const [hasPopulatedStartDate, setHasPopulatedStartDate] = useState(false);
  useEffect(() => {
    if (hasPopulatedStartDate) return;
    if (previousCertLoading) return;
    if (!previousCertificates) return;
    if (previousCertificates.getCcssCertificatesForChildren.length === 0) return;
    if (certificate.startDate !== '') return;
    const latest = (a: string, b: string) => (moment(a).isAfter(moment(b)) ? a : b);
    const latestDate = previousCertificates?.getCcssCertificatesForChildren
      .filter((x) => x.status === 'APPROV')
      .map((x) => x.endDate)
      .reduce(latest, '');
    setCertificate({ ...certificate, startDate: latestDate !== '' ? getCertStartDate(latestDate ?? '') : '' });
    setHasPopulatedStartDate(true);
  }, [certificate, hasPopulatedStartDate, previousCertLoading, previousCertificates]);

  const [createCcssCertificate, { loading: loadingCreate, error }] = useCreateCcssCertificate();

  const loading = loadingExternal ?? loadingCreate;

  const validateForm = useCallback(() => {
    if (onValidateForm) {
      // use suppled onValidateForm if provided.
      onValidateForm(setFormErrors, setIsFormValid, certificate);
      return;
    }

    const [isValid, errors] = areFormFieldsValid(
      certificate,
      previousCertificates?.getCcssCertificatesForChildren ?? []
    );
    setFormErrors(errors);
    setIsFormValid(isValid);
    console.info('Form Errors: ', errors);
  }, [certificate, onValidateForm, previousCertificates]);

  useEffect(() => {
    validateForm();
  }, [certificate, validateForm]);

  const handleClose = useCallback(() => {
    onClose();
    setCertificate(newCertificate);
  }, [onClose]);

  const handleContinue = () => {
    validateForm();
    if (!isFormValid) {
      return;
    }
    if (onSubmit) {
      // Use onSubmit provided if it exists
      onSubmit(certificate, account, handleClose);
      return;
    }

    const createCertificateInput = formatCreateCcssCertificateDates({
      ...certificate,
      businessId: account.entityId,
      accountId: account.id,
      centerId: account.centerId,
    });

    createCcssCertificate({
      variables: {
        input: { ...createCertificateInput },
      },
    })
      .then((data) => {
        const childCertificates = groupBy(data.data?.createCcssCertificate ?? [], (e) => e.childId);

        for (let childId in childCertificates) {
          const certificates = childCertificates[childId];
          dispatch(getCcsCertificateForAccountChild(account.id, childId, certificates));
        }
        showToast('Certificate(s) created', 'success');
        handleClose();
      })
      .catch((error) => {
        if (error instanceof ApolloError) {
          if (error.graphQLErrors?.length > 0) {
            showToast(
              `${error.graphQLErrors
                .map((err) => {
                  // @ts-ignore
                  return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
                })
                .join(', ')}`,
              'error'
            );
          } else {
            showToast(
              'Unable to perform the action. Please check the upload document location (if applicable) and make sure it exists.',
              'error'
            );
          }
        } else {
          showToast(error, 'error');
        }
      });
  };

  const handleChange = useCallback(
    (value, name) => {
      setCertificate({ ...certificate, [name]: value });
    },
    [certificate]
  );

  const handleStateBodyChange = useCallback(
    (value, name) => {
      setCertificate({
        ...certificate,
        stateTerritoryBody: {
          ...newStateTerritoryBody,
          ...certificate.stateTerritoryBody,
          [name]: value,
        },
      });
    },
    [certificate]
  );

  const selectedChildren = useMemo(
    () => certificate.children.map((c) => account?.children?.find((ac) => ac.id === c)),
    [account, certificate.children]
  );

  const handleStartDateChanged = (value: string) => {
    handleChange(value, 'startDate');
  };

  const isExceptionalReasonRequired = useMemo<boolean>(() => {
    return !moment(certificate.startDate).isBetween(moment().subtract(28, 'days'), moment(), 'days', '[]');
  }, [certificate.startDate]);

  const isExceptionalTextDisabled = useMemo<boolean>(() => {
    const result = certificate.exceptionalCircumstanceReason !== 'OTHER';

    if (result) {
      handleChange('', 'exceptionalCircumstanceText');
    }
    return result;
  }, [certificate.exceptionalCircumstanceReason]);

  const handleNoticeChanged = (value: string) => {
    if (value === 'ToState') {
      setCertificate({
        ...certificate,
        stateTerritoryBody: {
          ...newStateTerritoryBody,
          ...certificate.stateTerritoryBody,
          noticeToStateTerritory: true,
          notifiedByStateTerritory: false,
        },
      });
    } else {
      setCertificate({
        ...certificate,
        stateTerritoryBody: {
          ...newStateTerritoryBody,
          ...certificate.stateTerritoryBody,
          noticeToStateTerritory: false,
          notifiedByStateTerritory: true,
        },
      });
    }
  };

  const setSelectedFiles = (files: File[]) => {
    // When you select the same file twice it duplicates in the file list, so we only use unique filenames.
    // Also the user can select AllFiles so filter out the files we don't support
    const acceptedFiles = uniqBy(files, (f) => f.name).filter((f) =>
      acceptedFileExts.includes('.' + (f.name.split('.').pop()?.toLowerCase() ?? ''))
    );

    const supportingFiles: ISupportingDocument[] = acceptedFiles.map((file) => {
      return {
        file: file,
        type: undefined,
      };
    });
    setCertificate({
      ...certificate,

      supportingDocuments: supportingFiles,
    });
  };

  const onFileDeleted = (fileName: string) => {
    const newSupportingDocuments = certificate.supportingDocuments?.filter((doc) => !(doc.file.name === fileName));
    setCertificate({
      ...certificate,
      supportingDocuments: newSupportingDocuments,
    });
  };

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  let availableCertificates = previousCertificates?.getCcssCertificatesForChildren ?? [];
  availableCertificates = availableCertificates.filter((currCert) => {
    const startDate = certificate.startDate ? moment(certificate.startDate) : moment();
    return startDate.isBetween(moment(currCert.startDate), moment(currCert.endDate));
  });

  return (
    <CenteredModal
      size="lg"
      show={isOpen}
      onHide={handleClose}
      title={title}
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryChoice="Submit"
      primaryCallback={handleContinue}
      primaryButtonProps={{ disabled: !isFormValid, loading }}
    >
      <Form>
        <Row align="start">
          <Col md="3">
            <TextInput
              label={`${fieldLabels.center} Name`}
              value={account?.center?.name ?? ''}
              disabled={true}
              {...modalControlsProps?.centerName}
            />
          </Col>

          <Col md="3">
            <DateInput
              label="Start Date"
              date={certificate.startDate}
              isOutsideRange={(day: moment.Moment) => {
                const invalidDateRange = !moment(day).isBetween(moment().subtract(13, 'weeks'), moment(), 'days', '[]');

                return (
                  day.day() !== moment().day('Monday').day() || // Must be a monday
                  day.isBefore(moment(startDateMinimum)) || // Day cannot be before minimum
                  invalidDateRange ||
                  (previousCertificates?.getCcssCertificatesForChildren
                    .filter((cert) => cert.status === 'APPROV')
                    .some((cert) => moment(cert.endDate).isAfter(moment(day))) ??
                    false)
                );
              }}
              onDateSelect={(startDate: string) => handleStartDateChanged(startDate)}
              required
              isValid={formErrors.startDate === ''}
              {...modalControlsProps?.startDate}
            />
            {formErrors.startDate !== '' && <Form.Text className="text-danger">{formErrors.startDate}</Form.Text>}
          </Col>
          <Col md="3">
            <Select
              required
              label="Weeks at Risk"
              value={certificate.weeksAtRisk !== 0 ? certificate.weeksAtRisk : undefined}
              name="weeksAtRisk"
              options={weeksAtRiskOptions}
              onChange={handleChange}
              isInvalid={formErrors.weeksAtRisk !== ''}
              errorText={formErrors.weeksAtRisk}
              {...modalControlsProps?.weeksAtRisk}
            />
          </Col>

          <Col md="3">
            <Select
              required
              name="evidenceHeld"
              label="Evidence Held"
              value={certificate.evidenceHeld === undefined ? undefined : certificate.evidenceHeld ? 'true' : 'false'}
              options={yesNoOptions}
              onChange={(value) => {
                handleChange(get({ false: false, true: true }, value.value, undefined), 'evidenceHeld');
              }}
              isInvalid={formErrors.evidenceHeld !== ''}
              errorText={formErrors.evidenceHeld}
              {...modalControlsProps?.evidenceHeld}
            />
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <Select
              className="mb-1"
              label="Child"
              required
              value={selectedChildren}
              options={account.children}
              getOptionLabel={(c) => `${c.firstname} ${c.lastname}`}
              isMulti
              name="children"
              getOptionValue={(c: IChild) => c.id}
              onChange={(value: IChild[], name) => {
                handleChange(value?.map((c) => c.id) ?? [], name);
              }}
              isInvalid={formErrors.children !== ''}
              errorText={formErrors.children}
              {...modalControlsProps?.child}
            />
            {availableCertificates.map((childCert) => (
              <p className="mb-1 font-size-12" style={{ color: colors.steelBlue }}>
                {`${childCert?.child?.firstname} ${childCert?.child?.lastname}`} has {childCert.weeksAtRisk} week/s of
                certificate available
              </p>
            ))}
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <Select
              label="Risk Reasons"
              name="riskReasons"
              value={ccsRiskReasons.filter(({ value, label }) => certificate.riskReasons.includes(value))}
              isMulti
              required
              options={orderBy(ccsRiskReasons, (r) => r.label)}
              onChange={(value: any[], name) => {
                handleChange(value?.map((v) => v.value) ?? [], name);
              }}
              isInvalid={formErrors.riskReasons !== ''}
              errorText={formErrors.riskReasons}
              {...modalControlsProps?.riskReasons}
            />
          </Col>
        </Row>

        {isExceptionalReasonRequired && (
          <>
            <Row align="start">
              <Col>
                <Select
                  label="Exceptional Circumstances Reason"
                  name="exceptionalCircumstanceReason"
                  value={exceptionalCircumstanceReasons.find(
                    ({ value, label }) => value === certificate?.exceptionalCircumstanceReason
                  )}
                  required={isExceptionalReasonRequired}
                  disabled={!isExceptionalReasonRequired}
                  options={exceptionalCircumstanceReasons}
                  onChange={(value: { label: string; value: string }, name) => {
                    handleChange(value?.value, name);
                  }}
                />
              </Col>
            </Row>
            <Row align="start">
              <Col>
                <TextInput
                  label="Exceptional Circumstance Information"
                  name="exceptionalCircumstanceText"
                  value={certificate?.exceptionalCircumstanceText}
                  maxlength="255"
                  onChange={(value: string, name) => {
                    handleChange(value, name);
                  }}
                  as="textarea"
                  rows={3}
                  disabled={isExceptionalTextDisabled}
                  required={!isExceptionalTextDisabled}
                />
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col md="auto">Enter State/Territory Body details?</Col>
          <Col>
            <Switch
              className="float-right"
              value={shouldSubmitWithStateTerritory}
              onChange={(value) => setShouldSubmitWithStateTerritory(value)}
              labelSide="left"
              height={32}
              checkedText="Yes"
              uncheckedText="No"
            />
          </Col>
        </Row>

        {shouldSubmitWithStateTerritory && (
          <>
            <Row align="start">
              <Col>
                <h5>State/Territory Body Details</h5>
              </Col>
            </Row>

            <Row align="start">
              <Col md="3">
                <DateInput
                  required
                  label="Notice Date"
                  isOutsideRange={(day) => day.isAfter(moment().endOf('day'))}
                  onDateSelect={(value) => handleStateBodyChange(value, 'noticeGivenDate')}
                  date={certificate?.stateTerritoryBody?.noticeGivenDate}
                  isValid={formErrors.stateTerritoryBody.noticeGivenDate === ''}
                  {...modalControlsProps?.noticeGivenDate}
                />
                {formErrors.stateTerritoryBody.noticeGivenDate !== '' && (
                  <Form.Text className="text-danger">{formErrors.stateTerritoryBody.noticeGivenDate}</Form.Text>
                )}
              </Col>
              <Col md="9">
                <Select
                  required
                  label="Notice"
                  value={
                    certificate?.stateTerritoryBody?.noticeToStateTerritory
                      ? 'ToState'
                      : certificate?.stateTerritoryBody?.notifiedByStateTerritory
                      ? 'ByState'
                      : undefined
                  }
                  name="notice"
                  options={noticeOptions}
                  onChange={(value) => handleNoticeChanged(value.value)}
                  isInvalid={formErrors.stateTerritoryBody.notice !== ''}
                  errorText={formErrors.stateTerritoryBody.notice}
                  {...modalControlsProps?.notice}
                />
              </Col>
            </Row>

            <Row align="start">
              <Col md="3">
                <TextInput
                  label="Organistion Name"
                  name="organisationName"
                  value={certificate?.stateTerritoryBody?.organisationName}
                  onChange={handleStateBodyChange}
                  required={certificate?.stateTerritoryBody?.noticeToStateTerritory}
                  isInvalid={formErrors.stateTerritoryBody.organisationName !== ''}
                  errorText={formErrors.stateTerritoryBody.organisationName}
                  {...modalControlsProps?.organisationName}
                />
              </Col>
              <Col md="3">
                <TextInput
                  label="Reference Number"
                  name="stateReferenceNumber"
                  onChange={handleStateBodyChange}
                  value={certificate?.stateTerritoryBody?.stateReferenceNumber}
                  isInvalid={
                    !(
                      formErrors.stateTerritoryBody.stateReferenceNumber === '' ||
                      formErrors.stateTerritoryBody.stateReferenceNumber === undefined
                    )
                  }
                  errorText={formErrors.stateTerritoryBody.stateReferenceNumber}
                />
              </Col>
              <Col md="6">
                <Select
                  label="Body Type"
                  value={certificate?.stateTerritoryBody?.type}
                  name="type"
                  required
                  options={orderBy(ccsStateTerritoryBodyEnum, (e) => e.label)}
                  onChange={(value, name) => {
                    handleStateBodyChange(value.value, name);
                  }}
                  isInvalid={formErrors.stateTerritoryBody.type !== ''}
                  errorText={formErrors.stateTerritoryBody.type}
                  {...modalControlsProps?.type}
                />
              </Col>
            </Row>

            <Row align="start">
              <Col>
                <h6>Notified by:</h6>
              </Col>
            </Row>

            <Row align="start">
              <Col md="6">
                <TextInput
                  label="First Name"
                  value={certificate?.stateTerritoryBody?.notifiedByPersonFirstName}
                  name="notifiedByPersonFirstName"
                  onChange={handleStateBodyChange}
                  isInvalid={formErrors.stateTerritoryBody.notifiedByPersonFirstName !== ''}
                  errorText={formErrors.stateTerritoryBody.notifiedByPersonFirstName}
                  {...modalControlsProps?.notifiedByPersonFirstName}
                />
              </Col>
              <Col md="6">
                <TextInput
                  label="Last Name"
                  name="notifiedByPersonLastName"
                  onChange={handleStateBodyChange}
                  value={certificate?.stateTerritoryBody?.notifiedByPersonLastName}
                  isInvalid={formErrors.stateTerritoryBody.notifiedByPersonLastName !== ''}
                  errorText={formErrors.stateTerritoryBody.notifiedByPersonLastName}
                  {...modalControlsProps?.notifiedByPersonLastName}
                />
              </Col>
            </Row>

            <Row align="start">
              <Col>
                <h6>State/Territory Contact</h6>
              </Col>
            </Row>

            <Row align="start">
              <Col>
                <TextInput
                  label="Name or Id"
                  name="statePersonNameOrId"
                  onChange={handleStateBodyChange}
                  value={certificate?.stateTerritoryBody?.statePersonNameOrId}
                  isInvalid={formErrors.stateTerritoryBody.statePersonNameOrId !== ''}
                  errorText={formErrors.stateTerritoryBody.statePersonNameOrId}
                  {...modalControlsProps?.statePersonNameOrId}
                />
              </Col>
              <Col>
                <PhoneInput
                  label="Phone Number"
                  name="statePersonPhoneNumber"
                  value={certificate?.stateTerritoryBody?.statePersonPhoneNumber}
                  onChange={(value: string) => {
                    const forceLeadingZeroOrOne = (val?: string) =>
                      val && !val.startsWith('0') && !val.startsWith('1') ? `0${val}` : val;
                    const formattedValue = forceLeadingZeroOrOne(
                      parsePhoneNumberFromStringWithRegion(value)?.nationalNumber.toString()
                    );
                    const stripPlaceholder = (val: string) => (val === COUNTRY_CODES.AU.placeholder ? undefined : val);
                    handleStateBodyChange(formattedValue ?? stripPlaceholder(value), 'statePersonPhoneNumber');
                  }}
                  isInvalid={
                    certificate?.stateTerritoryBody?.statePersonPhoneNumber
                      ? !isValidPhoneNumber(certificate.stateTerritoryBody.statePersonPhoneNumber ?? '')
                      : false
                  }
                  errorText={formErrors.stateTerritoryBody.statePersonPhoneNumber}
                  {...modalControlsProps?.statePersonPhoneNumber}
                />
              </Col>
              <Col>
                <EmailInput
                  label="Email"
                  name="statePersonEmail"
                  onChange={handleStateBodyChange}
                  value={certificate?.stateTerritoryBody?.statePersonEmail}
                  isInvalid={formErrors.stateTerritoryBody.statePersonEmail !== ''}
                  errorText={formErrors.stateTerritoryBody.statePersonEmail}
                  {...modalControlsProps?.statePersonEmail}
                />
              </Col>
            </Row>

            <Row align="start">
              <Col>
                <TextInput
                  label="Further Information"
                  name="text"
                  value={certificate?.stateTerritoryBody?.text}
                  onChange={handleStateBodyChange}
                  as="textarea"
                  rows={3}
                  isInvalid={formErrors.stateTerritoryBody.text !== ''}
                  errorText={formErrors.stateTerritoryBody.text}
                  {...modalControlsProps?.text}
                />
              </Col>
            </Row>
          </>
        )}
        <Row align="start">
          <Col>
            <div className="d-flex flex-row">
              <h5>Supporting Documents</h5>
              {certificate?.stateTerritoryBody?.notifiedByStateTerritory && (
                <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
              )}
            </div>
          </Col>
        </Row>

        <Row align="start">
          <Col>
            <FileSelectDropbox
              onChange={setSelectedFiles}
              value={certificate.supportingDocuments?.map((d) => d?.file)}
              showFiles={false}
              acceptedFileExts={acceptedFileExts.join(',')}
            />
          </Col>
        </Row>

        <Row align="start">
          <Col>
            {orderBy(certificate.supportingDocuments ?? [], (c) => c.file.name).map((doc, i) => {
              return (
                <Row key={doc.file.name} className="file-display m-0">
                  <Col md="6">
                    <h6>{doc.file.name}</h6>
                  </Col>
                  <Col md="5">
                    <Select
                      className="my-auto"
                      placeholder="Document Type"
                      options={orderBy(accsDocumentTypeEnum, (e) => e.label)}
                      value={doc.type}
                      onChange={(value) => {
                        const otherFiles = certificate.supportingDocuments?.filter((d) => d !== doc) ?? [];
                        const newSupportDocs: ISupportingDocument[] = [
                          ...otherFiles,
                          { file: doc.file, type: value.value },
                        ];
                        setCertificate({
                          ...certificate,
                          supportingDocuments: newSupportDocs,
                        });
                        //doc.type = value;
                      }}
                      required
                      isInvalid={formErrors.supportingDocuments.some((x) => x.file === doc?.file?.name)}
                      errorText={formErrors.supportingDocuments
                        .filter((x) => x.file === doc?.file?.name)
                        .map((x) => x.error)
                        .join('\n')}
                    />
                  </Col>
                  <Col md="1">
                    <TinyDeleteButton onClick={() => onFileDeleted(doc.file.name)} />
                  </Col>
                </Row>
              );
            })}
            {formErrors.supportingDocuments
              .filter((x) => x.file === '')
              .map((value, index) => (
                <Form.Text key={index} className="text-danger">
                  {`${value.error}`}
                </Form.Text>
              ))}
          </Col>
        </Row>

        <Row align="start" className="mt-4">
          <DeclarationAccordion declarationType={DeclarationType.Certficate} />
        </Row>

        <Row align="start" className="mt-4">
          <Col>
            <div className="d-flex flex-row">
              <Checkbox
                label="I declare that the information provided is true and correct"
                value={certificate.isDeclarationGiven}
                onChange={(value) => handleChange(value, 'isDeclarationGiven')}
                {...modalControlsProps?.declaration}
              />
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
          </Col>
        </Row>
      </Form>
    </CenteredModal>
  );
};

export default CreateCertificateModal;
