import React from 'react';
import _ from 'lodash';

import * as Mui from '@mui/material';
import PageWrapper from 'shared/components/PageWrapper';
import KangarootimeRoutes, { IRoute } from 'shared/util/routes';
import cast from 'shared/util/cast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faSidebar, faUniversalAccess } from '@fortawesome/pro-solid-svg-icons';

interface IProps {}

const SiteMap: React.FC<IProps> = ({}) => {
  const routes = React.useMemo(() => {
    // @ts-ignore
    const iterableMenu: IRoute[] = Object.values(KangarootimeRoutes).map((route: IRoute) => {
      if (route.subRoutes) {
        return {
          ...route,
          subRoutes: Object.values(route.subRoutes),
        };
      }
      return route;
    });
    //this is intentional
    console.log('ROUTES:', iterableMenu);
    return iterableMenu;
  }, []);

  return (
    <PageWrapper pageTitle="Sitemap">
      <Mui.Typography>
        A site map generated from all the main routes in the app, regardless of permission or launch darkly
        configuration.
      </Mui.Typography>
      <Mui.Box sx={{ marginBottom: 2 }}>
        <Mui.Stack direction="row" alignItems="center" spacing={1}>
          <FontAwesomeIcon icon={faFlag} />
          <Mui.Typography>Has a Launch Darkly Configuration</Mui.Typography>
        </Mui.Stack>
        <Mui.Stack direction="row" alignItems="center" spacing={1}>
          <FontAwesomeIcon icon={faSidebar} />
          <Mui.Typography>Should Display in Sidebar</Mui.Typography>
        </Mui.Stack>
        <Mui.Stack direction="row" alignItems="center" spacing={1}>
          <FontAwesomeIcon icon={faUniversalAccess} />
          <Mui.Typography>Has a Permission Configuration</Mui.Typography>
        </Mui.Stack>
      </Mui.Box>
      {routes.map((route: IRoute, index: number) => (
        <Mui.Box key={`${index}-${route.name}`}>
          <Mui.Stack direction="row" alignItems="center" spacing={1}>
            <Mui.Link href={route.route}>{route.name}</Mui.Link>
            {Boolean(route.launchDarklyFlag) && <FontAwesomeIcon icon={faFlag} />}
            {!Boolean(route.doNotShowInSidebar) && <FontAwesomeIcon icon={faSidebar} />}
            {(Boolean(route.requiredAreaLevel) || Boolean(route.requiredPermission)) && (
              <FontAwesomeIcon icon={faUniversalAccess} />
            )}
          </Mui.Stack>
          <Mui.Box sx={{ marginLeft: 3 }}>
            {(cast<IRoute[]>(route.subRoutes) ?? []).map((sub, idx) => (
              <Mui.Stack direction="row" alignItems="center" spacing={1} key={`${idx}-${sub.name}`}>
                <Mui.Link href={sub.route}>{sub.name}</Mui.Link>
                {Boolean(sub.launchDarklyFlag) && <FontAwesomeIcon icon={faFlag} />}
                {!Boolean(sub.doNotShowInSidebar) && <FontAwesomeIcon icon={faSidebar} />}
                {(Boolean(sub.requiredAreaLevel) || Boolean(route.requiredPermission)) && (
                  <FontAwesomeIcon icon={faUniversalAccess} />
                )}
              </Mui.Stack>
            ))}

            {/* {cast<IRoute[]>(route.subRoutes).map((subRoute: IRoute, idx: number) => {
              return <Mui.Link>subitems</Mui.Link>;
            })} */}
          </Mui.Box>
        </Mui.Box>
      ))}
    </PageWrapper>
  );
};

export default SiteMap;
