import React, { useState, useCallback, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'shared/components/Buttons';
import { CreateProgramSteps, IFormDataShape } from '../../context/ProgramsContext';
import ProgramDetails from '../CreateProgramModal/ProgramDetails';
import LinkToClassesTable from '../CreateProgramModal/LinkToClassesTable';
import { useTranslation } from 'react-i18next';
import { capitalize, isEmpty } from 'lodash';
import ConfirmProgramDetailsModal from '../ConfirmProgramDetailsModal';
import { EnrollmentProgramGroup } from 'generated/graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';
import './_programModal.scss';

interface IProps {
  formData: IFormDataShape;
  formErrors: Partial<Record<keyof IFormDataShape, string | null>>;
  isDateValid: boolean;
  loading: boolean;
  isModalOpen: boolean;
  step: CreateProgramSteps;
  modalTitle: string;
  confirmTitle: string;
  programGroups?: EnrollmentProgramGroup[];
  handleSubmit: () => Promise<void>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resetForm: () => void;
  setStep: React.Dispatch<React.SetStateAction<CreateProgramSteps>>;
  setFormData: React.Dispatch<React.SetStateAction<IFormDataShape>>;
}

const ProgramModal: React.FC<IProps> = ({
  formData,
  isDateValid,
  loading,
  isModalOpen,
  step,
  formErrors,
  confirmTitle,
  programGroups,
  setStep,
  resetForm,
  setIsModalOpen,
  handleSubmit,
  setFormData,
  modalTitle,
}) => {
  const { t } = useTranslation();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    resetForm();
  }, [setIsModalOpen, resetForm]);

  const handleSubmitForm = () => {
    handleSubmit().then(() => setIsConfirmationModalOpen(false));
  };

  const primaryButtonText =
    step === CreateProgramSteps.programDetails ? capitalize(t('spelling.next')) : capitalize(t('spelling.save'));
  const secondaryButtonText =
    step === CreateProgramSteps.programDetails
      ? capitalize(t('spelling.cancel'))
      : capitalize(t('enrollment.programs.modal.back'));

  const handlePrimaryButtonClick = useCallback(() => {
    if (step === CreateProgramSteps.programDetails) {
      setStep(CreateProgramSteps.linkToClass);
    } else {
      setIsConfirmationModalOpen(true);
    }
  }, [step, setStep]);

  const handleSecondaryButtonClick = useCallback(() => {
    if (step === CreateProgramSteps.programDetails) {
      handleClose();
    } else {
      setStep(CreateProgramSteps.programDetails);
    }
  }, [step, handleClose, setStep]);

  const isPrimaryButtonDisabled = useMemo(() => {
    const isFormInvalid = !formData.name || !isDateValid || isEmpty(formData.description);
    if (step === CreateProgramSteps.programDetails) {
      return isFormInvalid || Object.values(formErrors ?? {}).some((a) => Boolean(a));
    }

    if (step === CreateProgramSteps.linkToClass) {
      return isFormInvalid || !formData.programCenters.length;
    }

    return true;
  }, [step, formData, isDateValid, formErrors]);

  return (
    <>
      <Modal
        centered
        scrollable={step === CreateProgramSteps.linkToClass}
        backdrop="static"
        show={isModalOpen}
        onHide={handleClose}
        size="xl"
        fullScreen={true}
        className={classNames('kt-enrollment-create-program-modal', {
          'width-1500': true,
        })}
      >
        <Modal.Header closeButton className="px-6 py-4">
          <Modal.Title as="h5">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-6 overflow-scroll">
          <ProgramDetails
            formData={formData}
            setFormData={setFormData}
            show={step === CreateProgramSteps.programDetails}
            isDateValid={isDateValid}
            formErrors={formErrors}
            programGroups={programGroups}
          />
          <LinkToClassesTable show={step === CreateProgramSteps.linkToClass} />
        </Modal.Body>
        <Modal.Footer className="px-4 py-2">
          <Button variant="light" className="mr-3" onClick={handleSecondaryButtonClick}>
            {secondaryButtonText}
          </Button>
          <Button
            disabled={isPrimaryButtonDisabled}
            variant="primary"
            onClick={handlePrimaryButtonClick}
            loading={loading}
          >
            {primaryButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfirmProgramDetailsModal
        isOpen={isConfirmationModalOpen}
        loading={loading}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        formData={formData}
        title={confirmTitle}
        handleSubmit={handleSubmitForm}
      />
    </>
  );
};

export default ProgramModal;
