import React, { useCallback } from 'react';
import { cloneDeep, uniqBy } from 'lodash';
import momentTz from 'moment-timezone';
import { Row, Col } from 'shared/components/Layout';
import ContractScheduleDays from './ContractScheduleDays';
import BiweeklySchedule from './BiweeklySchedule';
import CustomSchedule from './CustomSchedule';
import CasualSchedule from './CasualSchedule';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  contract: IContract;
  centerTimezone: Timezone;
}

const ContractSchedule: React.FC<IProps> = ({ contract, centerTimezone, ...props }) => {
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const cycleToString = useCallback((cycle: ContractCycleType): string => {
    switch (cycle) {
      case 'WEEKLY':
        return 'Weekly';
      case 'BIWEEKLY':
        return fieldLabels.biWeekly;
      case 'CUSTOM':
        return 'Custom';
      case 'CASUAL':
        return 'Casual';
      default:
        return '';
    }
  }, []);

  const sortByDayOfWeek = useCallback(
    (timeslots: IContractTimeslot[]): IContractTimeslot[] => {
      // provide a fallback value
      const last = 7;
      const sorter: Record<string, number> = {
        SUNDAY: 0,
        MONDAY: 1,
        TUESDAY: 2,
        WEDNESDAY: 3,
        THURSDAY: 4,
        FRIDAY: 5,
        SATURDAY: 6,
      };

      return cloneDeep(timeslots).sort((a, b) => {
        const dayOfWeek1 =
          a.day ??
          momentTz(a.date ?? '')
            .tz(centerTimezone)
            .format('dddd')
            .toUpperCase();
        const dayOfWeek2 =
          b.day ??
          momentTz(b.date ?? '')
            .tz(centerTimezone)
            .format('dddd')
            .toUpperCase();

        return (sorter[dayOfWeek1] ?? last) - (sorter[dayOfWeek2] ?? last);
      });
    },
    [centerTimezone]
  );

  const renderSchedule = useCallback(() => {
    switch (contract.cycleType) {
      case 'WEEKLY':
        return (
          <ContractScheduleDays
            contract={contract}
            centerTimezone={centerTimezone}
            timeslots={sortByDayOfWeek(contract.timeslots)}
          />
        );
      case 'BIWEEKLY':
        return (
          <BiweeklySchedule
            contract={contract}
            centerTimezone={centerTimezone}
            week1Timeslots={sortByDayOfWeek(contract.timeslots.filter((ts) => ts.week === 'WEEK1'))}
            week2Timeslots={sortByDayOfWeek(contract.timeslots.filter((ts) => ts.week === 'WEEK2'))}
          />
        );
      case 'CUSTOM':
        return (
          <CustomSchedule
            contract={contract}
            centerTimezone={centerTimezone}
            /**
             * filter the timeslots array to only include one record for each day of the week since
             * custom schedules allow a single drop off/pick up time to be set for all days in the contract
             * (if someone selects 5 mondays, they will all be the same times)
             */
            timeslots={sortByDayOfWeek(
              uniqBy(contract.timeslots, (ts) => ts.date && momentTz(ts.date).tz(centerTimezone).day())
            )}
          />
        );
      case 'CASUAL':
        return (
          <CasualSchedule
            contract={contract}
            // casual contracts are expected to have a single timeslot record showing the drop off and pick up
            timeslot={contract.timeslots[0]}
          />
        );
      default:
        return null;
    }
  }, [contract, centerTimezone, sortByDayOfWeek]);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <div>Frequency</div>
          <div className="text-dark">{cycleToString(contract.cycleType)}</div>
        </Col>
      </Row>
      {renderSchedule()}
    </>
  );
};

export default ContractSchedule;
