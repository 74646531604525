import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import { currencyFormat } from 'shared/util/currency';

interface IProps {
  balance: any;
  loading: boolean;
}

const AccountDepositOverviewCard: React.FC<IProps> = ({ balance, loading, ...props }) => {
  const { t } = useTranslation(['accounts']);

  return (
    <Card bodyClassName="p-4">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <h5>{t('accounts:profile.billing-deposits-tab.total-deposit-balance')}</h5>
          </div>
          <div>
            <h3 className={classnames({ 'text-danger': balance < 0 })}>{currencyFormat(balance ?? 0)}</h3>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AccountDepositOverviewCard;
