import moment from 'moment';
import momentTz from 'moment-timezone';

/**
 * check that there are no future time entries
 *
 * ex: if I am entering a time entry at 9am I shouldn't be able to enter a time for 2pm since that is in the future
 */
export const checkForFutureTimeEntries = (entry: ITimeEntry, timezone: Timezone): boolean => {
  const nowMoment: moment.Moment = momentTz().tz(timezone);
  const localizedTrackedTimeOut = entry.trackedTimeOut && momentTz(entry.trackedTimeOut).tz(timezone);
  const localizedTimeInMoment: moment.Moment = momentTz(entry.trackedTimeIn).tz(timezone);

  if (
    localizedTimeInMoment.isAfter(nowMoment) ||
    (localizedTrackedTimeOut && localizedTrackedTimeOut.isAfter(nowMoment)) ||
    (localizedTrackedTimeOut && localizedTrackedTimeOut.isBefore(localizedTimeInMoment))
  ) {
    return false;
  }

  return true;
};

/**
 * check that there are no overlaps between time entries
 *
 * ex: If I enter a shift for 1PM - 2PM, a valid break would be 2PM - 3PM, but NOT 1:45PM - 2:45 PM
 */
export const checkForOverlappingTimeEntries = (
  entries: ITimeEntry[],
  currentEntry: ITimeEntry,
  timezone: Timezone
): boolean => {
  let isValid = true;

  for (let i = 0; i < entries.length; i++) {
    const entryToCompare = entries[i];

    // skip compare if it is the same entry
    if (entryToCompare.id === currentEntry.id) continue;

    const localizedTrackedTimeInOfEntryToCompare = momentTz(entryToCompare.trackedTimeIn).tz(timezone);
    const localizedTrackedTimeInOfCurrentEntry = momentTz(currentEntry.trackedTimeIn).tz(timezone);
    const localizedTrackedTimeOutOfCurrentEntry =
      currentEntry.trackedTimeOut && momentTz(currentEntry.trackedTimeOut).tz(timezone);

    /**
     * The third argument that is passed in to inBetween is granularity. We have to establish this comparison cuts off at the minute mark
     * otherwise you can get some strange behavior where seconds/milliseconds can cause this function to return some unintended behavior of
     * falsely invalidating the entry because, for example 12:30:23 is not equal to 12:30:00.
     *
     * The fourth argument is inclusivity, meaning if the boundary time is equal to the time it is being compared to, does that count as
     * being in between. Parenthesis indicate no, brackets indicate yes.
     * https://momentjs.com/docs/#/query/is-between/
     */
    if (
      localizedTrackedTimeOutOfCurrentEntry &&
      localizedTrackedTimeInOfEntryToCompare.isBetween(
        localizedTrackedTimeInOfCurrentEntry,
        localizedTrackedTimeOutOfCurrentEntry,
        'minutes',
        '[)'
      )
    ) {
      isValid = false;
      // if this comes back invalid there is no reason to continue to iterate over additional items
      break;
    }
  }

  return isValid;
};
