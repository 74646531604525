import classNames from 'classnames';
import { CustomFieldType } from 'generated/graphql';
import { capitalize } from 'lodash';
import { SYSTEM_REQUIRED_FIELDS } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/hooks/useFieldMetadata';
import MinRequiredContact from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/page-detail/min-required-contact/min-required-contact';
import PageSection from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/page-section/page-section';
import React, { useCallback, useRef } from 'react';
import { Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { default as BsCard } from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { CreateButton } from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import { Area, ICustomField } from 'shared/types/customField';
import { EnrolFeExtraType, Field, Page, Section, SectionType } from 'shared/types/enrollment-form';
import AddNewSectionModal from '../section-modal/section-modal';
import { SectionSelectorContext } from '../section-selector-provider';
import { getNonConditionalSections } from '../utils';
import AddNewQuestionModal from './add-new-question-modal/add-new-question-modal';
import MinRequiredPerson from './min-required-person/min-required-person';

export type PageDetailProps = {
  page: Page;
  pages: Page[];
  onPageChanged: (page: Page, switchToPage?: boolean) => void;
  businessId: string;
  centerIds: string[];
};

export type PublicApi = {
  showAddNewQuestionModel?: (show: boolean) => void;
  showSectionModel?: (show: boolean) => void;
  setManagePageModalPages?: (dropResult: DropResult) => void;
};

export const SectionTypeToAreaMapper = (sectionType?: SectionType): Area => {
  switch (sectionType) {
    case 'child':
      return 'Child';
    case 'contact':
      return 'Contact';
    case 'secondary-contact':
      return 'Contact';
    case 'medical-professional':
      return 'MedicalProfessional';
    default:
      return 'Account';
  }
};

export default function PageDetail({ page, onPageChanged, businessId, centerIds, pages }: PageDetailProps) {
  const { t } = useTranslation(['translation', 'enrollment', 'business']);
  const { setSectionsCanBeSelected } = React.useContext(SectionSelectorContext)!;

  const modalApi = useRef<PublicApi | null>(null);
  const openAddNewQuestionModel = () =>
    modalApi.current?.showAddNewQuestionModel && modalApi.current.showAddNewQuestionModel(true);

  let sectionType = '';

  if (page.sectionType) {
    sectionType = page.sectionType
      .split('-')
      .map((s) => (s !== 'cwa' ? capitalize(s) : s.toUpperCase()))
      .join(' ');
  }

  const handleAddNewSection = useCallback(
    (newSection: Section) => {
      const sections = page.sections;

      // for these two page types, we need to inject the namespace so that the fields are repeatable on the enrolment form frontend
      if (page.sectionType === 'contact') sections.push({ ...newSection, namespace: 'primaryContacts' });
      else if (page.sectionType === 'secondary-contact') sections.push({ ...newSection, namespace: 'contacts' });
      else if (page.sectionType === 'medical-professional')
        sections.push({ ...newSection, namespace: 'medicalProfessionals' });
      else sections.push(newSection);

      onPageChanged({ ...page });
    },
    [onPageChanged, page]
  );

  const getHtmlFieldText = (field: ICustomField) => {
    if (field.type !== CustomFieldType.HtmlText) return undefined;
    if (!field.attributesAsString) return undefined;
    try {
      return JSON.parse(field.attributesAsString);
    } catch (e) {
      return undefined;
    }
  };

  return (
    <div className="page-detail">
      <fieldset>
        <legend>{page.label}</legend>
        <Card>
          {pages.find(
            (p) =>
              p.sectionType === 'secondary-contact' &&
              p.sections.find((section) => section.namespace === 'contacts') != null
          ) != null &&
            page.sectionType === 'child' && (
              <MinRequiredContact
                onSectionMetadataChange={(sectionMetadata) => {
                  let newVar = pages.find((p) => p.sectionType === 'secondary-contact')!;
                  newVar.sections.find((section) => section.namespace === 'contacts')!.sectionMetadata =
                    sectionMetadata;
                  onPageChanged({ ...newVar }, false);
                }}
                page={pages.find((p) => p.sectionType === 'secondary-contact')!}
              />
            )}

          {['medical-professional'].includes(page.sectionType ?? '') && (
            <MinRequiredPerson
              page={pages.find((p) => p.sectionType === 'medical-professional')!}
              onSectionMetadataChange={(sectionMetadata) => {
                let newVar = pages.find((p) => p.sectionType === 'medical-professional')!;
                newVar.sections.find((section) => section.namespace === 'medicalProfessionals')!.sectionMetadata =
                  sectionMetadata;
                onPageChanged({ ...newVar }, false);
              }}
            />
          )}

          {['cwa', 'payment'].includes(page.sectionType ?? '') && (
            <Row className="system-default-section py-4 mx-1" noGutters>
              <Col>
                <div className="text-center">
                  {t('enrollment:form.system-default-questions', {
                    sectionType,
                  })}
                </div>
              </Col>
            </Row>
          )}

          <Droppable droppableId="page" type="page">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{}}
                {...provided.droppableProps}
                className={classNames({
                  'is-dragging-over': snapshot.isDraggingOver,
                })}
              >
                {page.sections.map((section, idx) => {
                  // iterate the page.sections(don't filter it first then iterate) because the index is matter for PageSection component to work
                  if (!section.clientSideBehaviours) {
                    return (
                      <Draggable
                        draggableId={section.id || section.label || ''}
                        index={idx}
                        key={section.id || section.label || ''}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={classNames({
                              'p-1': true,
                              'is-dragging': snapshot.isDragging,
                            })}
                          >
                            <PageSection
                              draggableProvidedDragHandleProps={provided.dragHandleProps}
                              sectionIndex={idx}
                              onPageChanged={onPageChanged}
                              page={page}
                              openAddNewQuestionModel={openAddNewQuestionModel}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                  return null;
                })}

                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <BsCard.Footer>
            <div className="d-flex justify-content-center page-btn-group">
              <CreateButton
                onClick={() => modalApi.current?.showSectionModel && modalApi.current.showSectionModel(true)}
                variant="secondary"
              >
                {t('enrollment:form.add-new-section')}
              </CreateButton>
              <CreateButton
                onClick={() => {
                  setSectionsCanBeSelected(getNonConditionalSections(page.sections));
                  openAddNewQuestionModel();
                }}
                variant="primary"
                disabled={page.sections.length === 0}
              >
                {t('enrollment:form.add-new-question')}
              </CreateButton>
            </div>
          </BsCard.Footer>
        </Card>
        <AddNewQuestionModal
          ref={modalApi}
          centerIds={centerIds}
          businessId={businessId}
          currentPage={page}
          area={SectionTypeToAreaMapper(page.sectionType)}
          onAddNewFields={(fields: ICustomField[], section: Section) => {
            const ktFields = fields.map<Field>((f) => {
              let newVar = {
                name: f.id,
                label: f.label,
                type: f.type as CustomFieldType | EnrolFeExtraType,
                isMandatory: SYSTEM_REQUIRED_FIELDS.find((o) => o === f.id) != null,
                displayDateFormat: f.displayDateFormat,
              } as Field;

              if (f.type === CustomFieldType.HtmlText) {
                newVar.text = getHtmlFieldText(f);
              }

              if (f.type === 'CompositionField' && f.id === 'relationships') {
                newVar = { ...newVar, ...JSON.parse(f.attributesAsString!) };
              }
              return newVar;
            });

            const newFields: Field[] = [...section.fields, ...ktFields];

            page.sections.splice(page.sections.indexOf(page.sections.find((s) => s.label === section.label)!), 1, {
              ...section,
              fields: newFields,
            });
            onPageChanged({ ...page });
          }}
          pages={pages}
        />
        <AddNewSectionModal ref={modalApi} existingSections={page.sections} onAddNewSection={handleAddNewSection} />
      </fieldset>
    </div>
  );
}
