import React, { useEffect, useState } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { ConfirmationContent, Callback, eventManager, Event } from './eventManager';

export default () => {
  const [confirmationContent, setConfirmationContent] = useState<ConfirmationContent | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const buildConfirmationContent: Callback = (content) => {
    setConfirmationContent({
      ...content,
      onHide: () => {
        content?.onHide && content.onHide();
        setConfirmationContent(null);
      },
      onConfirm: async () => {
        if (content?.onConfirm) {
          setIsSubmitting(true);
          await content.onConfirm().then(() => {
            setIsSubmitting(false);
            setConfirmationContent(null);
          });
        }
      },
    });
  };

  useEffect(() => {
    eventManager.on(Event.Show, buildConfirmationContent);
    return () => {
      eventManager.cancel();
    };
  }, []);

  if (confirmationContent) {
    return (
      <ConfirmationModal
        title={confirmationContent.title}
        show
        scrollable
        onHide={confirmationContent.onHide}
        primaryChoice={confirmationContent.primaryButtonLabel ?? 'Confirm'}
        primaryCallback={confirmationContent.onConfirm}
        hideOnCallback={false}
        primaryButtonProps={{
          variant: confirmationContent.primaryButtonVariant ?? 'danger',
          loading: isSubmitting,
        }}
        size={confirmationContent.modalSize}
      >
        {confirmationContent.message}
      </ConfirmationModal>
    );
  }

  return null;
};
