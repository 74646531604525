import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';

const DocumentsTab = ({ personId, ...props }) => {
  return (
    <Row>
      <Column>
        <span>Documents for person</span>
      </Column>
    </Row>
  );
};

DocumentsTab.propTypes = {
  personId: PropTypes.string.isRequired,
};

export default DocumentsTab;
