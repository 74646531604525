import { useDeleteAppliedAccountDiscount } from 'gql/discount/mutations';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isOpen: boolean;
  discount: IAppliedAccountDiscount;
  onRemove: (removedDiscount: IAppliedAccountDiscount) => void;
  onClose: () => void;
}

const RemoveDiscountModal = (props: IProps) => {
  const { t } = useTranslation(['billing']);
  const [deleteFn, { loading: deleteLoading }] = useDeleteAppliedAccountDiscount({
    onCompleted: (result) => {
      const ended = props.discount.endDate === null && result.deleteAppliedAccountDiscount.endDate !== null;
      showToast(
        ended
          ? t('billing:discounts.account.delete-modal.success-end-toast-message')
          : t('billing:discounts.account.delete-modal.success-delete-toast-message'),
        'success'
      );
      props.onRemove(result.deleteAppliedAccountDiscount);
      props.onClose();
    },
    onError: (_err) => {
      showToast(t('billing:discounts.account.delete-modal.error-toast-message'), 'error');
    },
  });

  const handlePrimaryCallback = useCallback(() => {
    deleteFn({
      variables: {
        id: props.discount.id,
      },
    });
  }, [props.onClose, deleteFn, props.discount.id]);

  return (
    <ConfirmationModal
      title={t('billing:discounts.account.delete-modal.title')}
      show={props.isOpen}
      onHide={props.onClose}
      hideOnCallback={false}
      primaryChoice="Delete"
      primaryCallback={handlePrimaryCallback}
      primaryButtonProps={{ variant: 'danger', loading: deleteLoading }}
    >
      <p>
        {props.discount?.discountAuth === 'DELETE'
          ? t('billing:discounts.account.delete-modal.no-historical-body')
          : t('billing:discounts.account.delete-modal.body')}
      </p>
      <p>{t('billing:discounts.account.delete-modal.confirmation-text')}</p>
    </ConfirmationModal>
  );
};

export default RemoveDiscountModal;
