import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/pro-light-svg-icons';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { TinyDeleteButton } from 'shared/components/Buttons';

// TODO: Make the display of this much more flexible when we do the doc manager
interface IProps {
  file: IFile;
  progress: number;
  deleteCb: (id: string) => void;
  updateContentsCb: (id: string | ArrayBuffer | null) => void;
  fileIsInvalid: boolean;
  uploadComplete: boolean;
}

const UploadedFile: React.FC<IProps> = ({
  file,
  progress,
  deleteCb,
  updateContentsCb,
  fileIsInvalid = false,
  uploadComplete = false,
  ...props
}) => {
  useEffect(() => {
    const reader: FileReader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = (event: ProgressEvent<FileReader>) => {
      event && event.target && updateContentsCb(event.target.result);
    };
  }, [file, updateContentsCb]);

  return (
    <div className="d-flex flex-row w-100 align-items-center justify-content-between file-display px-4 py-3">
      <FontAwesomeIcon size="2x" icon={faFileImport} />
      <div className="d-flex w-100 flex-column ml-4 my-1 justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <h6>Upload{fileIsInvalid ? ' Failed!' : uploadComplete ? ' Complete!' : `ing File... ${progress}%`}</h6>
          <TinyDeleteButton onClick={() => deleteCb(file.name)} />
        </div>
        <div className="description">
          <ProgressBar
            variant={fileIsInvalid ? 'danger' : 'success'}
            className="mt-1"
            now={fileIsInvalid ? 100 : progress}
          />
        </div>
      </div>
    </div>
  );
};

UploadedFile.defaultProps = {
  progress: 0,
  deleteCb: () => {},
  updateContentsCb: () => {},
};

export default UploadedFile;
