import { QueryHookOptions } from '@apollo/client';
import { roleFields } from 'gql/role/fields';
import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import User from 'shared/types/user';
import { getIsRoleARankedAboveRoleB, isHighestRole } from 'shared/util/getIsRoleARankedAboveRoleB';

interface IGetRolesForBusinessVariables {
  businessId: string;
}

interface IGetRolesForBusinessData {
  getRolesForBusiness: IRole[];
}

export const GET_ROLES_FOR_BUSINESS = (fields: string = roleFields) => gql`
  query($businessId: ID!) {
    getRolesForBusiness(businessId: $businessId) {
      ${fields}
    }
  }
`;

export const useGetRolesForBusiness = (
  businessId: string,
  fields: string = roleFields,
  options?: QueryHookOptions<IGetRolesForBusinessData, IGetRolesForBusinessVariables>
) =>
  useQuery<IGetRolesForBusinessData, IGetRolesForBusinessVariables>(GET_ROLES_FOR_BUSINESS(fields), {
    variables: {
      businessId: businessId,
    },
    skip: !businessId || businessId === '',
    ...options,
    fetchPolicy: 'network-only',
  });

export const useGetRolesForBusinessThatUserCanManage = (businessId: string, user?: User | null) => {
  const result = useQuery<IGetRolesForBusinessData, IGetRolesForBusinessVariables>(GET_ROLES_FOR_BUSINESS(), {
    variables: {
      businessId: businessId,
    },
    skip: !businessId || businessId === '',
  });

  return {
    ...result,
    data:
      result.data?.getRolesForBusiness.filter(
        (role) =>
          user?.isInternal ||
          isHighestRole(user?.role, result.data?.getRolesForBusiness) ||
          getIsRoleARankedAboveRoleB(user?.role, role)
      ) ?? [],
  };
};
