import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { busRosterAllFields, busRosterFields } from './fields';

export type BusRunType = 'AM' | 'PM' | 'BOTH';

interface ICreateBusRosterData {
  createBusRoster: IBusRoster;
}

interface IUpdateBusRosterData {
  updateBusRoster: IBusRoster;
}

interface IDeleteBusRosterData {
  deleteBusRoster: IBusRoster;
}

interface ICreateBusRosterVariables {
  input: {
    businessId: string;
    centerId: string;
    name: string;
    effectiveDate: string;
    endDate: string;
    type: BusRunType;
    destinations: ICreateBusRosterDestinationVariables[];
    children: ICreateBusRosterAccountChildVariables[];
  };
}

interface IUpdateBusRosterVariables {
  input: {
    id: string;
    centerId: string;
    name: string;
    effectiveDate: string;
    endDate: string;
    type: BusRunType;
    destinations: ICreateBusRosterDestinationVariables[];
    children: ICreateBusRosterAccountChildVariables[];
  };
}

interface ICreateBusRosterDestinationVariables {
  name: string;
}

interface ICreateBusRosterAccountChildVariables {
  accountChildId: string;
  type: BusRunType;
  destination: string;
  scheduleSlots: ICreateBusRosterAccountChildScheduleSlotVariables[];
}

interface ICreateBusRosterAccountChildScheduleSlotVariables {
  weekType: WeekType;
  day: DayOfWeekType;
}

interface IDeleteBusRosterVariables {
  id: string;
}

export const CREATE_BUS_ROSTER = gql`
    mutation ($input: CreateBusRosterInput!) {
        createBusRoster(input: $input) {
            ${busRosterAllFields}
        }
    }
`;

export const UPDATE_BUS_ROSTER = gql`
    mutation($input: UpdateBusRosterInput!) {
        updateBusRoster(input: $input) {
            ${busRosterAllFields}
        }
    }
`;

export const DELETE_BUS_ROSTER = gql`
    mutation($id: ID!) {
        deleteBusRoster(id: $id) {
            ${busRosterFields}
        }
    }
`;

export const useCreateBusRosters = (options?: MutationHookOptions<ICreateBusRosterData, ICreateBusRosterVariables>) =>
  useMutation<ICreateBusRosterData, ICreateBusRosterVariables>(CREATE_BUS_ROSTER, options);

export const useUpdateBusRoster = (options?: MutationHookOptions<IUpdateBusRosterData, IUpdateBusRosterVariables>) =>
  useMutation<IUpdateBusRosterData, IUpdateBusRosterVariables>(UPDATE_BUS_ROSTER, options);

export const useDeleteBusRoster = (options?: MutationHookOptions<IDeleteBusRosterData, IDeleteBusRosterVariables>) =>
  useMutation<IDeleteBusRosterData, IDeleteBusRosterVariables>(DELETE_BUS_ROSTER, options);
