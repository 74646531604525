import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { groupBy, uniq } from 'lodash';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import moment from 'moment';
import Card from 'shared/components/Card';
import Select from 'shared/components/Select';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import { IconButton } from 'shared/components/Buttons';
import { useGetOccupancyTotalReport } from 'gql/reports/queries';
import { RootState } from 'store/reducers';
import { Box as LoadingBox } from 'shared/components/LoadingSkeletons';

interface IChartFilterShape {
  centerId: string | null; // null will represent "all"
  start: string | null;
  end: string | null;
}

interface IProps {
  loadingCenters: boolean;
  centerOptions: ICenter[];
  onDownloadCsv: (data: IOccupancyTotalReport) => void;
}

const OccupancyTotalReportDashboardCard: React.FC<IProps> = ({
  loadingCenters,
  centerOptions,
  onDownloadCsv,
  ...props
}) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const [chartFilters, setChartFilters] = useState<IChartFilterShape>({
    centerId: centerOptions[0]?.id ?? null,
    start: moment().startOf('week').format('YYYY-MM-DD'),
    end: moment().endOf('week').format('YYYY-MM-DD'),
  });

  const { loading: getOccupancyTotalsLoading, data: getOccupancyTotalsData } = useGetOccupancyTotalReport({
    variables: {
      input: {
        businessId: user?.entityId ?? '',
        centerIds: centerOptions.map((center) => center.id) ?? [],
        startDate: chartFilters.start as string,
        endDate: chartFilters.end as string,
        classIds: [],
        excludeNonCcsCareType: false,
        excludeClassesWithNoAttendance: false,
      },
    },
    skip: !centerOptions.length,
  });
  const classIdClassNameLookup = useMemo((): Record<string, string> => {
    return (getOccupancyTotalsData?.getOccupancyTotalReport.reportData ?? []).reduce((acc, curr) => {
      return { ...acc, [curr.classId]: curr.class };
    }, {});
  }, [getOccupancyTotalsData]);

  useEffect(() => {
    if (centerOptions.length) {
      setChartFilters((prev) => ({
        ...prev,
        centerId: !prev.centerId ? centerOptions[0].id : prev.centerId,
      }));
    }
  }, [centerOptions]);

  const transformReportDataForChart = useCallback(
    (data: IOccupancyTotalReportData[]) => {
      const filteredData = data.filter((datum) =>
        chartFilters.centerId ? datum.centerId === chartFilters.centerId : true
      );

      const groupedByDate = groupBy(filteredData, (datum) => datum.date);
      const heatmapData: any[] = [];

      Object.keys(groupedByDate).forEach((date) => {
        const group = groupedByDate[date];

        heatmapData.push({
          date: moment(date).format('MMM Do'), // y-axis label
          ...group.reduce((acc, curr) => ({ ...acc, [curr.classId]: curr.utilization.toFixed(2) }), {}), //  keys are the x-axis label, values are dispalyed in the heatmap cell
        });
      });

      return heatmapData;
    },
    [chartFilters]
  );

  const getDatumForClassOnDate = useCallback(
    (classId: string, date: string): IOccupancyTotalReportData | null => {
      // the heatmap data transformed the YYYY-MM-DD date string into a more readable version
      return (
        getOccupancyTotalsData?.getOccupancyTotalReport.reportData.find(
          (datum) => moment(datum.date).format('MMM Do') === date && datum.classId === classId
        ) ?? null
      );
    },
    [getOccupancyTotalsData]
  );

  const renderHeatmapTooltip = useCallback(
    (classId: string, date: string): JSX.Element => {
      const datum = getDatumForClassOnDate(classId, date);

      if (!datum) return <div />;

      return (
        <div className="text-text-dark bg-white p-2 rounded box-shadow-lg">
          <div>
            <b>{date}</b> {datum.class}
          </div>
          <div className="d-flex flex-1">
            {datum.count} / {datum.capacity}
          </div>
          <div className="d-flex flex-1">
            {(datum.utilization * 100).toFixed(2)}% {t('spelling.utilized')}
          </div>
        </div>
      );
    },
    [getDatumForClassOnDate, t]
  );

  const topAxisKeys = uniq(
    getOccupancyTotalsData?.getOccupancyTotalReport.reportData
      .filter((datum) => (chartFilters.centerId ? datum.centerId === chartFilters.centerId : true))
      .map((datum) => datum.classId)
  );

  return (
    <Card
      header={
        <div className="d-flex flex-row align-items-center">
          Class Occupancy per Week
          {getOccupancyTotalsData?.getOccupancyTotalReport && (
            <IconButton
              icon={faArrowToBottom}
              onClick={() => onDownloadCsv(getOccupancyTotalsData.getOccupancyTotalReport)}
              className="ml-auto"
              tooltipText="Download CSV"
            />
          )}
        </div>
      }
      className="h-100"
    >
      <Row>
        <Column md={6} className="mb-4">
          <WeekPicker
            startDate={chartFilters.start ? moment(chartFilters.start) : null}
            endDate={chartFilters.end ? moment(chartFilters.end) : null}
            onChange={(dates) =>
              setChartFilters((prev) => ({
                ...prev,
                start: dates.startDate.format('YYYY-MM-DD'),
                end: dates.endDate.format('YYYY-MM-DD'),
              }))
            }
            reactDatesController="RANGE"
            rangeType="WEEK"
            className="mb-0"
          />
        </Column>
        <Column md={6} className="mb-4">
          <Select
            options={[
              { value: null, label: 'All Centers' },
              ...(centerOptions.map((center) => ({ label: center.name, value: center.id })) ?? []),
            ]}
            value={chartFilters.centerId ?? { label: 'All Centers', value: null }}
            onChange={(option) => setChartFilters((prev) => ({ ...prev, centerId: option.value }))}
            className="mb-0"
            isLoading={loadingCenters}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </Column>
      </Row>
      {getOccupancyTotalsLoading && (
        <div className="d-flex flex-row align-items-center mb-4" style={{ height: 286 }}>
          <LoadingBox width="100%" height="100%" />
        </div>
      )}
      {!getOccupancyTotalsLoading && (
        <div className="kt-occupancy-total-report-chart-container">
          <div className="kt-reporting-chart mb-4">
            <ResponsiveHeatMap
              indexBy="date"
              keys={topAxisKeys}
              data={transformReportDataForChart(getOccupancyTotalsData?.getOccupancyTotalReport.reportData ?? [])}
              minValue={0}
              maxValue={1}
              // @ts-ignore
              colors="greens"
              margin={{ top: 64, left: 80 }}
              forceSquare={false}
              axisTop={{
                tickRotation: 25,
                tickPadding: 7,
                format: (value) => classIdClassNameLookup[value as string] ?? '', // show the class name instead of class id
              }}
              // @ts-ignore
              tooltip={({ xKey, yKey, value, color }) => renderHeatmapTooltip(xKey, yKey)}
            />
          </div>
        </div>
      )}
    </Card>
  );
};

export default OccupancyTotalReportDashboardCard;
