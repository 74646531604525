import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onSuccess: () => void;
  handleDelete: () => void;
}

const DeleteContractConfirmationModal: React.FC<IProps> = ({ isOpen, loading, onClose, handleDelete }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      title="Are you sure?"
      show={isOpen}
      onHide={onClose}
      primaryChoice={'Delete'}
      primaryCallback={handleDelete}
      hideOnCallback={false}
      primaryButtonProps={{
        variant: 'danger',
        loading: loading,
      }}
    >
      {t('enrollment.contracts.delete-confirmation')}
    </ConfirmationModal>
  );
};

export default DeleteContractConfirmationModal;
