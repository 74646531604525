export const SEARCH_FEES = 'SEARCH_FEES';
export const CREATE_FEE = 'CREATE_FEE';
export const UPDATE_FEE = 'UPDATE_FEE';
export const DEACTIVATE_FEE = 'DEACTIVATE_FEE';
export const REACTIVATE_FEE = 'REACTIVATE_FEE';
export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER';
export const UPDATE_SEARCH_SORT = 'UPDATE_SEARCH_SORT';

interface ISearchFeesAction {
  type: typeof SEARCH_FEES;
  fees: IFee[];
  totalFees: number;
}
interface ICreateFeeAction {
  type: typeof CREATE_FEE;
  fee: IFee;
}
interface IUpdateFeeAction {
  type: typeof UPDATE_FEE;
  fee: IFee;
}
interface IDeactivateFeeAction {
  type: typeof DEACTIVATE_FEE;
  fee: IFee;
}
interface IReactivateFeeAction {
  type: typeof REACTIVATE_FEE;
  fee: IFee;
}
interface IUpdateSearchFilterAction {
  type: typeof UPDATE_SEARCH_FILTER;
  filter: ITableFiltersMap;
}
interface IUpdateSearchSortAction {
  type: typeof UPDATE_SEARCH_SORT;
  field: string;
  direction: ElasticsearchSortDirection;
}

export type FeesActionTypes =
  | ISearchFeesAction
  | ICreateFeeAction
  | IUpdateFeeAction
  | IDeactivateFeeAction
  | IReactivateFeeAction
  | IUpdateSearchFilterAction
  | IUpdateSearchSortAction;
