import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { capitalize } from 'lodash';
import Button from 'shared/components/Buttons';
import PageWrapper from 'shared/components/PageWrapper';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UtilizationTableCard from '../../UtilizationTableCard';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { orderBy } from 'lodash';
import { useGetClassesForCenter } from 'gql/class/queries';
import { ageToNumberOfDays } from 'shared/util/ageToNumberOfDays';
import { useGetAttendanceOpenSpots } from 'gql/session/queries';
import { useBulkUpsertCapacityOverride } from 'gql/class/mutations';
import { showToast } from 'shared/components/Toast';
import CenteredModal from 'shared/components/Modals/CenteredModal';

const ManageCapacities = () => {
  const { t } = useTranslation();
  const [capacityOverrides, setCapacityOverrides] = useState<ICapacityOverridesDictionary>({});
  const history = useHistory();
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();
  const [date, setDate] = useState(moment().tz(timezone).format());
  const startOfWeek = moment(date).tz(timezone).day(1).startOf('d').format();
  const endOfWeek = moment(date).tz(timezone).day(6).endOf('d').format();
  const [modalState, setModalState] = useState({
    isOpen: false,
    newDate: '',
  });
  const { data } = useGetClassesForCenter({
    variables: { centerId: selectedCenterId },
  });
  const {
    loading: getOpenSpotsLoading,
    data: getOpenSpotsData,
    refetch: refetchOpenSpotsFn,
  } = useGetAttendanceOpenSpots({
    variables: {
      input: {
        centerId: selectedCenterId,
        start: startOfWeek,
        end: endOfWeek,
      },
    },
  });

  const [bulkUpsertCapacityOverride, { loading: bulkUpsertCapacityOverrideLoading }] = useBulkUpsertCapacityOverride({
    onCompleted: () => {
      refetchOpenSpotsFn();
      setCapacityOverrides({});
      showToast(t('attendance.manage-capacities.upsert-capacity-success-toast'), 'success');
    },
  });

  const onCapacityChange = useCallback(
    (classId: string, date: string, newCapacity: number, oldCapacity: number) => {
      setCapacityOverrides((prev) => ({
        ...prev,
        [`${classId}-${date}`]: {
          classId,
          date,
          newCapacity,
          oldCapacity,
        },
      }));
    },
    [setCapacityOverrides]
  );

  const capacityOverrideList = useMemo(() => {
    return Object.values(capacityOverrides).filter((capacityOverride) => {
      return capacityOverride.newCapacity !== capacityOverride.oldCapacity;
    });
  }, [capacityOverrides]);

  const onSave = useCallback(async () => {
    await bulkUpsertCapacityOverride({
      variables: {
        input: capacityOverrideList.map((capacityOverride) => ({
          classId: capacityOverride.classId,
          date: capacityOverride.date,
          capacity: capacityOverride.newCapacity,
        })),
      },
    });
    setCapacityOverrides({});
  }, [bulkUpsertCapacityOverride, capacityOverrideList]);

  const classes = useMemo(
    () =>
      orderBy(
        data?.getClassesForCenter ?? [],
        (c) => ageToNumberOfDays(c.regulationOverride ? c.regulationOverride?.startAge : c.regulation.startAge),
        'asc'
      ),
    [data]
  );

  const onDateChange = useCallback(
    (date: string) => {
      if (capacityOverrideList.length > 0) {
        setModalState({
          isOpen: true,
          newDate: date,
        });
      } else {
        setDate(date);
      }
    },
    [capacityOverrideList]
  );

  const handleDiscard = useCallback(() => {
    setDate(modalState.newDate);
    setModalState({
      isOpen: false,
      newDate: '',
    });
  }, [modalState]);

  const handleSaveAndFetchNewDate = useCallback(() => {
    onSave().then(() => {
      setDate(modalState.newDate);
      setModalState({
        isOpen: false,
        newDate: '',
      });
    });
  }, [modalState, onSave]);

  useEffect(() => {
    setCapacityOverrides({});
  }, [getOpenSpotsData]);

  const isInputValid = useMemo(
    () => capacityOverrideList.every((c) => typeof c.newCapacity === 'number' && Number(c.newCapacity) >= 0),
    [capacityOverrideList]
  );

  return (
    <>
      <PageWrapper
        pageTitle={t('attendance.manage-capacities.title')}
        applyPadding={true}
        buttonComponent={
          <>
            <Button variant="light" onClick={() => history.push('/attendance/attendance')} className="mr-2">
              {capitalize(t('spelling.cancel'))}
            </Button>
            <Button
              variant="primary"
              disabled={capacityOverrideList.length === 0 || !isInputValid}
              onClick={onSave}
              loading={bulkUpsertCapacityOverrideLoading}
            >
              {capitalize(t('spelling.save'))}
            </Button>
          </>
        }
      >
        <CenterSelectBanner pageName="attendance" />
        <UtilizationTableCard
          centerId={selectedCenterId}
          timezone={timezone}
          startOfWeek={startOfWeek}
          endOfWeek={endOfWeek}
          classes={classes}
          utilizationData={getOpenSpotsData}
          loadingUtilizationData={getOpenSpotsLoading}
          onDateChange={onDateChange}
          mode="edit"
          onCapacityChange={onCapacityChange}
          capacityOverrides={capacityOverrides}
        />
      </PageWrapper>
      <CenteredModal
        title={t('attendance.manage-capacities.confirm-modal-title')}
        show={modalState.isOpen}
        onHide={() => setModalState((prev) => ({ ...prev, isOpen: false }))}
        primaryCallback={handleSaveAndFetchNewDate}
        secondaryChoice={t('attendance.manage-capacities.discard')}
        secondaryCallback={handleDiscard}
        primaryButtonProps={{ loading: bulkUpsertCapacityOverrideLoading, variant: 'primary' }}
      >
        {t('attendance.manage-capacities.confirm-modal-message')}
      </CenteredModal>
    </>
  );
};

export default ManageCapacities;
