import React, { useEffect, useState } from 'react';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { LATE_CHANGE_DAYS, LATE_WITHDRAWAL_DAYS } from 'shared/constants/enums/subsidyTrackingEnums';

interface IProps {
  isOpen: boolean;
  loading: boolean;
  handleClose: () => void;
  handleSubmit: (reason?: string) => void;
  summary?: ISubsidyWeeklySummary;
}

const AddReasonForLateChangeModal: React.FC<IProps> = ({ isOpen, handleClose, handleSubmit, loading, summary }) => {
  const { t } = useTranslation();
  const [reasonForLateChange, setReason] = useState<string | undefined>();
  useEffect(() => {
    if (summary) setReason(summary.reasonForLateChange);
    else setReason(undefined);
  }, [summary, setReason]);

  const isWithdrawal = isEmpty(summary?.sessionIds);
  const weekStart = moment(summary?.weekEnding).add(-6, 'days');
  const isOutsideTimeframe = isWithdrawal
    ? moment().diff(weekStart, 'd') < LATE_WITHDRAWAL_DAYS
    : moment().diff(moment(summary?.weekEnding), 'd') < LATE_CHANGE_DAYS;

  return (
    <CenteredModal
      title={t('subsidy-tracking.add-reason-for-late-change')}
      show={isOpen}
      onHide={handleClose}
      primaryCallback={() => handleSubmit(reasonForLateChange)}
      secondaryCallback={handleClose}
      primaryButtonProps={{ loading }}
    >
      <>
        {isOutsideTimeframe && isWithdrawal && (
          <p>
            Reason for late withdrawal should be added <strong>at least 28 days</strong> after the week start.
          </p>
        )}
        {isOutsideTimeframe && !isWithdrawal && (
          <p>
            Reason for late change should be added <strong>at least 14 days</strong> after the week ending.
          </p>
        )}
        <Row>
          <Col lg={8}>
            <TextInput
              label={t('attendance.checkinout.reasonForLateChangeLabel')}
              value={reasonForLateChange}
              onChange={(value) => setReason(value)}
              className="mt-5"
            />
          </Col>
        </Row>
      </>
    </CenteredModal>
  );
};
export default AddReasonForLateChangeModal;
