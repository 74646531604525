import React, { useEffect, useState } from 'react';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import './busRosterChildrenTable.scss';
import Checkbox from 'shared/components/Checkbox';
import Select from 'shared/components/Select';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { isBlank, stringToHueDegree } from 'shared/util/string';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  title: string;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  data: IBusRosterAccountChildGridShape[] | undefined;
  busRunType: string;
  destinations: IDestinationStateShape[];
  selectedDestinations: string[];
  classes: IClass[];
  loading: boolean;
  assignedMode: boolean;
  onChange: React.Dispatch<React.SetStateAction<IBusRosterAccountChildGridShape[]>>;
  onAssignmentChange: React.Dispatch<React.SetStateAction<IBusRosterAccountChildGridShape[]>>;
}

interface IFilterDataShape {
  searchTerm: string | null;
  classes: string[] | undefined;
  destinations: string[] | undefined;
}

const BusRosterChildrenTable: React.FC<IProps> = ({
  title,
  tableState,
  tableFunctions,
  data,
  busRunType,
  destinations,
  selectedDestinations,
  classes,
  loading,
  assignedMode,
  onChange,
  onAssignmentChange,
}) => {
  const handleChange = (row: IBusRosterAccountChildGridShape) => {
    if (!data) return;
    let newValue = data.map((x) => x);
    let existingRowIndex = newValue.findIndex((x) => x.data.id === row.data.id);
    newValue.splice(existingRowIndex, 1, row);
    onChange(newValue);
  };

  const handleScheduleSlotCheck = (
    row: IBusRosterAccountChildGridShape,
    weekType: WeekType,
    day: DayOfWeekType,
    checked: boolean
  ) => {
    // If we have more than 1 day selected, continue to allow changes to the slots.
    if (row.input.scheduleSlots.filter((ss) => ss.weekType === weekType).length > 1 || checked) {
      let scheduleSlots = row.input.scheduleSlots;
      let existingIndex = scheduleSlots.findIndex((x) => x.weekType === weekType && x.day === day);
      if (existingIndex >= 0 && !checked) {
        scheduleSlots.splice(existingIndex, 1);
      } else if (existingIndex < 0) {
        scheduleSlots.push({ weekType: weekType, day: day });
      }
      row.input.scheduleSlots = scheduleSlots;
    }

    return row;
  };

  const assignDestination = (destination: string | null) => {
    if (!data) return;

    let selectedRows = data.filter((child: IBusRosterAccountChildGridShape) =>
      tableState.selectedRows.some((row: IBusRosterAccountChildGridShape) => row.id === child.id)
    );
    let destinationId = destinations.find((d) => d.label === destination)?.value;

    if (assignedMode === true && destination) {
      onChange((prev) =>
        prev.map((child) => {
          return {
            ...child,
            input: {
              ...child.input,
              destination: tableState.selectedRows.some((s: IBusRosterAccountChildGridShape) => s.id === child.id)
                ? destinationId!
                : child.input.destination,
            },
          };
        })
      );
    } else {
      onAssignmentChange((prev) =>
        prev.concat(
          data
            .filter((child) => selectedRows.some((x) => x.id === child.id))
            .map((child) => {
              return {
                ...child,
                input: {
                  ...child.input,
                  destination: destinationId!,
                },
              };
            })
        )
      );
      onChange(data.filter((child) => !selectedRows.some((x) => x.id === child.id)));
      tableFunctions.updateSelectedRows([]);
    }
  };

  const { t } = useTranslation();
  const typeOptions = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
    { value: 'BOTH', label: 'Both' },
  ];

  const getTableColumns = (): any[] => {
    let cols = [
      {
        text: 'Child',
        dataField: 'childName',
        sort: true,
        formatter: (cell: string, row: IBusRosterAccountChildGridShape) => (
          <AvatarDataTableCell
            initials={
              (row.data.childName.split(' ')[0] ?? '').toUpperCase().charAt(0) +
              (row.data.childName.split(' ')[1] ?? '').toUpperCase().charAt(0)
            }
            color={`hsl(${stringToHueDegree(row.data.id)}, ${
              stringToHueDegree(row.data.id) < 50 ? '100%' : '40%'
            }, 40%`}
            avatar={row.data.childAvatar?.url ?? ''}
            primaryText={row.data.childName}
          />
        ),
      },
      {
        text: 'Class',
        dataField: 'classNames',
        sort: true,
        formatter: (cell: any, row: IBusRosterAccountChildGridShape) => row.data.classNames,
      },
      {
        text: 'Scheduled Days',
        dataField: 'expectedScheduleSlots',
        formatter: (cell: string, row: IBusRosterAccountChildGridShape) => {
          let uniqueWeeks = row.input.scheduleSlots
            .map((slot) => slot.weekType)
            .filter((value, index, self) => self.indexOf(value) === index);
          return uniqueWeeks.sort().map((uniqueWeek) => {
            let slots = row.input.scheduleSlots.filter((slot) => slot.weekType === uniqueWeek);
            let expectedSlots = row.data.expectedScheduleSlots.filter((slot) => slot.weekType === uniqueWeek);
            if (
              slots.some(
                (s) =>
                  s.day !== 'MONDAY' &&
                  s.day !== 'TUESDAY' &&
                  s.day !== 'WEDNESDAY' &&
                  s.day !== 'THURSDAY' &&
                  s.day !== 'FRIDAY'
              )
            ) {
              return (
                <span key={uuidv4()} className="schedule-slot-message">
                  {slots[0].day}
                </span>
              );
            } else {
              return (
                <div className="d-flex" key={uuidv4()}>
                  <Checkbox
                    label="Mon"
                    value={slots.some((s) => s.day === 'MONDAY')}
                    onChange={(checked: boolean) => {
                      handleScheduleSlotCheck(row, uniqueWeek, 'MONDAY', checked);
                      handleChange(row);
                    }}
                    disabled={
                      !expectedSlots.some((s) => s.days.some((d) => d === 'MONDAY')) ||
                      (slots.length === 1 && slots.some((s) => s.day === 'MONDAY'))
                    }
                    className="mr-5"
                  />
                  <Checkbox
                    label="Tue"
                    value={slots.some((s) => s.day === 'TUESDAY')}
                    onChange={(checked: boolean) => {
                      handleScheduleSlotCheck(row, uniqueWeek, 'TUESDAY', checked);
                      handleChange(row);
                    }}
                    className="mr-5"
                    disabled={
                      !expectedSlots.some((s) => s.days.some((d) => d === 'TUESDAY')) ||
                      (slots.length === 1 && slots.some((s) => s.day === 'TUESDAY'))
                    }
                  />
                  <Checkbox
                    label="Wed"
                    value={slots.some((s) => s.day === 'WEDNESDAY')}
                    onChange={(checked: boolean) => {
                      handleScheduleSlotCheck(row, uniqueWeek, 'WEDNESDAY', checked);
                      handleChange(row);
                    }}
                    className="mr-5"
                    disabled={
                      !expectedSlots.some((s) => s.days.some((d) => d === 'WEDNESDAY')) ||
                      (slots.length === 1 && slots.some((s) => s.day === 'WEDNESDAY'))
                    }
                  />
                  <Checkbox
                    label="Thur"
                    value={slots.some((s) => s.day === 'THURSDAY')}
                    onChange={(checked: boolean) => {
                      handleScheduleSlotCheck(row, uniqueWeek, 'THURSDAY', checked);
                      handleChange(row);
                    }}
                    className="mr-5"
                    disabled={
                      !expectedSlots.some((s) => s.days.some((d) => d === 'THURSDAY')) ||
                      (slots.length === 1 && slots.some((s) => s.day === 'THURSDAY'))
                    }
                  />
                  <Checkbox
                    label="Fri"
                    value={slots.some((s) => s.day === 'FRIDAY')}
                    onChange={(checked: boolean) => {
                      handleScheduleSlotCheck(row, uniqueWeek, 'FRIDAY', checked);
                      handleChange(row);
                    }}
                    disabled={
                      !expectedSlots.some((s) => s.days.some((d) => d === 'FRIDAY')) ||
                      (slots.length === 1 && slots.some((s) => s.day === 'FRIDAY'))
                    }
                  />
                </div>
              );
            }
          });
        },
      },
      {
        text: 'Time',
        dataField: 'type',
        sort: true,
        style: { overflow: 'visible' },
        formatter: (cell: any, row: IBusRosterAccountChildGridShape) => (
          <div className="d-flex child-time-field">
            <Select
              value={busRunType ? row.input.type : null}
              options={typeOptions}
              disabled={!busRunType || busRunType === 'AM' || busRunType === 'PM'}
              onChange={(option: any) => {
                row.input.type = option.value;
                handleChange(row);
              }}
            />
          </div>
        ),
      },
    ];

    if (assignedMode === true) {
      cols.push({
        text: 'Destination',
        dataField: 'destination',
        sort: true,
        formatter: (cell: any, row: IBusRosterAccountChildGridShape) => {
          return destinations.find((x) => x.value === row.input.destination)?.label ?? '--';
        },
      });
    }

    return cols;
  };

  const [filterData, setFilterData] = useState<IFilterDataShape>({
    searchTerm: '',
    classes: [],
    destinations: [],
  });

  const [filteredTableData, setFilteredTableData] = useState<IBusRosterAccountChildGridShape[]>(
    data?.map((child) => child) ?? []
  );
  const filterTableData = (filterData: any) => {
    let filteredData =
      data?.filter((child) => {
        let searchTermPass: boolean = false;
        let classesPass: boolean = false;
        let destinationsPass: boolean = false;

        if (!isBlank(filterData.searchTerm)) {
          searchTermPass =
            child.data.childName.toUpperCase().includes(filterData.searchTerm.toUpperCase()) ||
            child.data.classNames.toUpperCase().includes(filterData.searchTerm.toUpperCase());
        } else {
          searchTermPass = true;
        }

        if (!isEmpty(filterData.classes)) {
          classesPass = filterData.classes.some((classItem: any) =>
            child.data.classNames.toUpperCase().includes(classItem.label.toUpperCase())
          );
        } else {
          classesPass = true;
        }

        if (!isEmpty(filterData.destinations)) {
          destinationsPass = filterData.destinations.some(
            (destination: any) => child.input.destination === destination.value
          );
        } else {
          destinationsPass = true;
        }

        return searchTermPass && classesPass && destinationsPass;
      }) ?? [];

    setFilteredTableData(
      filteredData.sort((a, b) => {
        let sortKey = tableState.sort[0].field;
        let sortDir = tableState.sort[0].direction;

        if (sortKey === 'childName') {
          if (b.data.childName < a.data.childName) return sortDir === 'ASCENDING' ? 1 : -1;
          if (b.data.childName > a.data.childName) return sortDir === 'ASCENDING' ? -1 : 1;
          else return 0;
        }

        if (sortKey === 'classNames') {
          if (b.data.classNames < a.data.classNames) return sortDir === 'ASCENDING' ? 1 : -1;
          if (b.data.classNames > a.data.classNames) return sortDir === 'ASCENDING' ? -1 : 1;
          else return 0;
        }

        if (sortKey === 'type') {
          if (b.input.type < a.input.type) return sortDir === 'ASCENDING' ? 1 : -1;
          if (b.input.type > a.input.type) return sortDir === 'ASCENDING' ? -1 : 1;
          else return 0;
        }

        if (sortKey === 'destination') {
          let aDest = destinations.find((d) => d.value === a.input.destination)?.label ?? '';
          let bDest = destinations.find((d) => d.value === b.input.destination)?.label ?? '';
          if (bDest < aDest) return sortDir === 'ASCENDING' ? 1 : -1;
          if (bDest > aDest) return sortDir === 'ASCENDING' ? -1 : 1;
          else return 0;
        }

        return 0;
      })
    );
  };
  useEffect(() => {
    filterTableData(filterData);
  }, [filterData.searchTerm, filterData.classes, filterData.destinations]);
  useEffect(() => {
    setFilterData((prev: any) => ({ ...prev, destinations: [] }));
    filterTableData(filterData);
  }, [destinations, selectedDestinations]);
  useEffect(() => {
    filterTableData(filterData);
  }, [data]);
  useEffect(() => {
    filterTableData(filterData);
  }, [tableState.sort]);

  return (
    <Card
      className="bus-roster-children"
      header={
        <div className="d-flex flex-row align-items-center">
          {title}
          <p className="ml-auto mb-0">{`${tableState?.selectedRows?.length} ${
            tableState?.selectedRows?.length === 1 ? 'child' : 'children'
          } selected`}</p>
          {assignedMode === true && (
            <Button
              variant="secondary"
              className="ml-8"
              onClick={() => {
                assignDestination(null);
              }}
              disabled={tableState?.selectedRows?.length === 0}
            >
              Unassign
            </Button>
          )}
          {selectedDestinations && selectedDestinations.length > 1 && (
            <Dropdown className="ml-8">
              <Dropdown.Toggle
                variant="secondary"
                disabled={tableState?.selectedRows?.length === 0}
                id="dropdown-basic"
              >
                {assignedMode === false ? 'Assign Destination' : 'Reassign Destination'}
                <FontAwesomeIcon className="ml-4" icon={faAngleDown} size="1x" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {destinations
                  .filter((d) => selectedDestinations.some((s) => s === d.value))
                  .map((x) => x.label)
                  .sort()
                  .map((destination) => (
                    <Dropdown.Item
                      key={uuidv4()}
                      onClick={() => {
                        assignDestination(destination);
                      }}
                    >
                      {destination}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {assignedMode === false && selectedDestinations && selectedDestinations.length === 1 && (
            <Button
              variant="secondary"
              className="ml-8"
              onClick={() => {
                assignDestination(destinations.find((x) => x.value === selectedDestinations[0])?.label ?? null);
              }}
              disabled={tableState?.selectedRows?.length === 0}
            >
              Assign to Destination
            </Button>
          )}
        </div>
      }
    >
      {loading ? (
        <DataTableLoadingSkeleton />
      ) : (
        <DataTable
          noPadding={true}
          data={filteredTableData ?? []}
          dataSize={filteredTableData?.length ?? 0}
          pageSize={tableState.pageSize}
          showLoadingOverlay={loading}
          columns={getTableColumns()}
          renderHeader={(paginationProps: any) => (
            <TableHeader className="flex-wrap">
              <>
                <div className="d-flex flex-row flex-grow-1">
                  <Row className="d-flex flex-row align-items-center justify-content-start flex-grow-1">
                    <Col xs="auto" className="my-2">
                      <TableSearch
                        placeholder="Search"
                        onChange={(term) => setFilterData((prev: any) => ({ ...prev, searchTerm: term }))}
                      />
                    </Col>
                    <Col xs="auto" className="my-2">
                      <DropdownFilter
                        title="Class"
                        selectedFilters={filterData?.classes ?? []}
                        options={classes.map((classItem) => {
                          return { value: classItem.id, label: classItem.name };
                        })}
                        onFilterSelect={(val) => setFilterData((prev: any) => ({ ...prev, classes: val }))}
                      />
                    </Col>
                    {assignedMode === true && (
                      <Col xs="auto" className="my-2">
                        <DropdownFilter
                          title="Destination"
                          selectedFilters={filterData?.destinations ?? []}
                          options={
                            selectedDestinations?.map((destinationValue) => {
                              return {
                                value: destinationValue,
                                label: destinations.find((d) => d.value === destinationValue)?.label ?? '',
                              };
                            }) ?? []
                          }
                          onFilterSelect={(val) => setFilterData((prev: any) => ({ ...prev, destinations: val }))}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              </>
            </TableHeader>
          )}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          activePage={tableState.activePage}
          selectedRows={tableState.selectedRows}
          updateSelectedRows={tableFunctions.updateSelectedRows}
          onSort={tableFunctions.updateSort}
        />
      )}
    </Card>
  );
};

export default BusRosterChildrenTable;
