import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Col, Row } from 'shared/components/Layout';
import ModalDrawer from 'shared/components/ModalDrawer';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { useLazyGetCcsSubsidyRemittanceReport } from 'gql/reports/queries';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { showToast } from 'shared/components/Toast';
import { convertTimeRangeObjectToString, convertTimeRangeStringToObject } from 'shared/util/timeUtils';
import Select from 'shared/components/Select';
import DateInput from 'shared/components/DateInput';
import TextInput from '../../../shared/components/TextInput';

interface IProps {
  isOpen: boolean;
  defaultFormValues: {
    centerIds: string[];
  };
  onClose: () => void;
}

const CcsSubsidyRemittanceReportModal: React.FC<IProps> = ({ isOpen, defaultFormValues, onClose, ...props }) => {
  const { t } = useTranslation(['translation', 'timelog', 'ccss', 'reporting']);
  const reportDataToFile = useReportDataToFile();
  const user = useSelector((state: RootState) => state.user);
  const [centerIds, setCenterIds] = useState<string[] | null>(defaultFormValues.centerIds);
  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [clearingNumberText, setClearingNumberText] = useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<ITimeRange>(convertTimeRangeStringToObject('Last 2 Weeks'));

  const resetFormData = () => {
    setAccountIds([]);
    setDateRange(convertTimeRangeStringToObject('Last 2 Weeks'));
  };

  const handleClose = useCallback(() => {
    resetFormData();
    onClose();
  }, [onClose]);

  const [getCcsSubsidyRemittanceReportFn, { loading }] = useLazyGetCcsSubsidyRemittanceReport({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(
        result.getCcsSubsidyRemittanceReport,
        t('reporting:report-file-name.css-payment')
      );
      handleClose();
    },
    onError: (error) => {
      showToast(t('translation:reports.general-failure-message'), 'error');
    },
  });

  useEffect(() => {
    setCenterIds(defaultFormValues.centerIds);
  }, [defaultFormValues]);

  const handleSubmit = useCallback(() => {
    getCcsSubsidyRemittanceReportFn({
      variables: {
        input: {
          businessId: user?.entityId ?? '',
          centerIds: centerIds ?? [],
          startDate: !!clearingNumberText ? null : moment(dateRange.start).format('YYYY-MM-DD'),
          endDate: !!clearingNumberText ? null : moment(dateRange.end).format('YYYY-MM-DD'),
          clearingNumber: clearingNumberText,
        },
      },
    });
  }, [getCcsSubsidyRemittanceReportFn, centerIds, accountIds, dateRange, user, clearingNumberText]);

  const dateRangeOptions: TimeRange[] = ['Last 2 Weeks', 'This Week', 'Last Week', 'Last Month', 'Custom'];

  const [isDateValid, setDateValidity] = useState<boolean>(true);

  const validateDateRange = (start: string, end: string) => {
    if (start !== '' && end !== '') {
      setDateValidity(moment(start).isBefore(moment(end)));
    }
  };

  useEffect(() => {
    validateDateRange(dateRange.start, dateRange.end);
  }, [dateRange]);

  const formDisabled = !isDateValid;

  return (
    <ModalDrawer
      // @ts-ignore - TypeScript doesn't like the dynamic lookup
      title={t('ccss:payment-report')}
      show={isOpen}
      onHide={handleClose}
      primaryButtonProps={{ disabled: formDisabled, loading }}
      primaryChoice={t('translation:run-report')}
      primaryCallback={handleSubmit}
      closeOnPrimaryCallback={false}
      secondaryCallback={handleClose}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            selectedCenterIds={centerIds}
            onSelect={(centerIds) => setCenterIds(centerIds)}
            useNullForAllOption={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="pl-2">{''}</Row>
          <Row className="pl-2">
            <Select
              name="status"
              disabled={!!clearingNumberText?.length}
              options={dateRangeOptions}
              label={t('timelog:time-card.date-range-label')}
              value={convertTimeRangeObjectToString(dateRange)}
              onChange={(string) => setDateRange(convertTimeRangeStringToObject(string))}
            />
          </Row>
          <Row className="pl-2" justify="around">
            <DateInput
              date={dateRange.start}
              disabled={!!clearingNumberText?.length}
              onDateSelect={(start) => setDateRange({ ...dateRange, start })}
              isValid={isDateValid}
              hasCustomValidation
              className="flex-grow-0 my-2"
            />
            <div className="my-2">to</div>
            <DateInput
              date={dateRange.end}
              disabled={!!clearingNumberText?.length}
              isValid={isDateValid}
              hasCustomValidation
              onDateSelect={(end) => setDateRange({ ...dateRange, end })}
              className="flex-grow-0 my-2"
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            label={t('ccss:clearing-number')}
            onChange={(v) => setClearingNumberText(v)}
            value={clearingNumberText}
          />
        </Col>
      </Row>
    </ModalDrawer>
  );
};

export default CcsSubsidyRemittanceReportModal;
