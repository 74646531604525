import { gql } from '@apollo/client';
import { useQuery, useLazyQuery } from 'shared/apis/core';
import { SEARCH_EXPRESSIONS, PREDICATES, DIRECTIONS } from 'shared/constants/elastic';
import { active, pending } from 'shared/constants/StaffStatusSearchExpressions';
import { useDispatch } from 'react-redux';
import { addClass } from '../../Classes/duck/actions';

interface IGetCenterDetailsData {
  getCenter: ICenter;
}

interface IGetCenterDetailsVariables {
  id: string;
}

interface IGetClassByIdVariables {
  id: string;
}

interface IGetClassByIdData {
  getClass: IClass;
}

export interface ISearchStaffData {
  searchStaff: {
    totalResults: number;
    data: IStaff[];
  };
}

export interface ISearchStaffVariables {
  input: IElasticsearchQuery;
}

export interface ISearchBasicStaffData {
  getBasicStaffForCenter: {
    totalResults: number;
    data: IBasicStaff[];
  };
}

export interface ISearchBasicStaffVariables {
  centerId: string;
}

export const SEARCH_STAFF = gql`
  query ($input: SearchInput!) {
    searchStaff(input: $input) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
        }
      }
    }
  }
`;

export const BASIC_STAFF_AT_CENTER = gql`
  query ($centerId: String!) {
    getBasicStaffForCenter(centerId: $centerId) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        avatar {
          url
        }
      }
    }
  }
`;

export const GET_CENTER_DETAILS = gql`
  query ($id: String!) {
    getCenter(id: $id) {
      id
      timezone
      address {
        state
        country
      }
      spaces {
        id
        name
      }
    }
  }
`;

export const GET_CLASS_BY_ID = gql`
  query ($id: String!) {
    getClass(id: $id) {
      id
      centerId
      name
      startsAt
      endsAt
      isPreSchoolProgram
      regulationId
      defaultSpaceId
      colorCode
      defaultSpace {
        id
        name
      }
      currentSpace {
        id
        name
      }
      regulation {
        id
        state
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        youngestStartAge {
          age
          unit
        }
        oldestEndAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
        capacity
      }
      colorCode
      staffAssignments {
        id
        nickname
        firstname
        lastname
        avatar {
          url
        }
        classAssignments {
          id
          startsAt
          endsAt
        }
      }
      regulationOverride {
        startAge {
          age
          unit
        }
        endAge {
          age
          unit
        }
        ratioTeachers
        ratioChildren
      }
      archivedAt
      defaultCasualFeeId
      defaultPermanentFeeId
      fees {
        id
        name
        deactivatedAt
      }
      capacity
      glCode
    }
  }
`;

export const useGetCenterDetails = (id: string) =>
  useQuery<IGetCenterDetailsData, IGetCenterDetailsVariables>(GET_CENTER_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

export const useSearchActiveStaffForCenter = (centerId: string) =>
  useQuery<ISearchStaffData, ISearchStaffVariables>(SEARCH_STAFF, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        size: 10000, // assumes a single center doesn't have 10,000 staff + manager + assistant manager
        from: 0,
        sort: [{ field: 'lastname.keyword', direction: DIRECTIONS.ASCENDING }],
        filter: {
          [SEARCH_EXPRESSIONS.ALL]: [
            { [SEARCH_EXPRESSIONS.ANY]: [active, pending] },
            {
              [SEARCH_EXPRESSIONS.TERM]: {
                field: 'roleship.scopeIds.keyword',
                predicate: PREDICATES.CONTAINS,
                value: centerId,
              },
            },
          ],
        },
      },
    },
  });

export const useGetBasicStaffForCenter = (centerId: string) =>
  useQuery<ISearchBasicStaffData, ISearchBasicStaffVariables>(BASIC_STAFF_AT_CENTER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      centerId,
    },
    skip: !centerId,
  });

export const useLazyGetClassById = (id: string) => {
  const dispatch = useDispatch();

  return useLazyQuery<IGetClassByIdData, IGetCenterDetailsVariables>(GET_CLASS_BY_ID, {
    onCompleted: (data) => {
      dispatch(addClass(data.getClass));
    },
    variables: {
      id,
    },
  });
};

export const useSearchStaffForCenter = (centerId: string, entityId: string) =>
  useQuery<ISearchStaffData, ISearchStaffVariables>(SEARCH_STAFF, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        size: 10000, // assumes a single center doesn't have 10,000 staff + manager + assistant manager
        from: 0,
        sort: [{ field: 'lastname.keyword', direction: DIRECTIONS.ASCENDING }],
        filter: {
          [SEARCH_EXPRESSIONS.ALL]: [
            {
              [SEARCH_EXPRESSIONS.TERM]: {
                field: 'roleship.scopeIds.keyword',
                predicate: PREDICATES.ONE_OF,
                value: [centerId, entityId],
              },
            },
          ],
        },
      },
    },
  });
