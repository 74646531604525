import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { allergenFields } from './fields';

interface IGetAllergensForTypeData {
  getAllergens: IAllergen[];
}

interface IGetAllergensForTypeVariables {}

export const GET_ALLERGENS = gql`
  query {
    getAllergens {
      ${allergenFields}
    }
  }
`;

export const useGetAllergens = () =>
  useQuery<IGetAllergensForTypeData, IGetAllergensForTypeVariables>(GET_ALLERGENS, {
    fetchPolicy: 'cache-and-network',
  });
