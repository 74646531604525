import React, { useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { sortBy } from 'lodash';
import ProviderMessagesTab from './ProviderMessagesTab';
import ServiceMessagesTab from './ServiceMessagesTab';
import './ccssMessages.scss';
import Select from 'shared/components/Select';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import CCSSEventTab from './CCSSEventsTab/CCSSEventTab';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const CCSSMessages: React.FC<IProps> = ({ ...props }) => {
  const user = useSelector((state: RootState) => state.user);
  const { data: businessesData } = useGetAllowedEntities();
  const { data: centers } = useGetActiveCentersWithLoading();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const [selectedCenters, setSelectedCenters] = useState<ITableFilterOption[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<ITableFilterOption | null>(null);

  const { t } = useTranslation(['subsidies']);
  const { k2CcssEventManagement } = useFlags();

  return (
    <ProfilePageWrapper title="CCS Management" loading={false} showAvatar={false}>
      <div className="mx-auto">
        {centers && centers.length > 1 && (
          <div className="d-flex">
            {user?.isInternal && (
              <>
                <h6 className="my-auto mr-2">Selected Businesses</h6>
                <Select
                  className="ccs-msg-business-select"
                  options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                  onChange={setSelectedBusiness}
                  placeholder="Filter..."
                />
              </>
            )}

            {
              <>
                <h6 className="my-auto mr-2">Selected {fieldLabels.center}s</h6>
                <DropdownFilter
                  title="Filter..."
                  className="mr-4 my-2"
                  selectedFilters={selectedCenters}
                  options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
                  onFilterSelect={setSelectedCenters}
                />
              </>
            }
          </div>
        )}
        {/* made business selection singular */}
        <Tabs id="messages-tab-group" defaultActiveKey="service-messages">
          <Tab eventKey="service-messages" title="Service Messages">
            {businesses && businesses.length > 0 && (
              <ServiceMessagesTab
                selectedBusiness={selectedBusiness || { label: businesses[0].name, value: businesses[0].id }}
                selectedCenters={selectedCenters}
              />
            )}
          </Tab>
          {k2CcssEventManagement && (
            <Tab eventKey="event-management" title={t('subsidies:event-management.tab-title')}>
              {businesses && businesses.length > 0 && (
                <CCSSEventTab
                  selectedBusiness={selectedBusiness || { label: businesses[0].name, value: businesses[0].id }}
                  selectedCenters={selectedCenters}
                />
              )}
            </Tab>
          )}
        </Tabs>
      </div>
    </ProfilePageWrapper>
  );
};

export default CCSSMessages;
