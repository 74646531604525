import React, { useCallback, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { getCustomizedColumns, getCustomSelect, getPageOptionsFromDataLength } from './DataTableCustomizations';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { ApplicationContext } from 'shared/contexts/ApplicationContext';
import { showToast } from 'shared/components/Toast';

interface IProps {
  data: any[];
  columns: any[];
  renderHeader?: (paginationProps: any, searchProps: any, setFilteredData: any) => React.ReactNode | null;
  keyField?: string;
  handleRowClick?: (event: React.SyntheticEvent, rowData: any) => void | null;
  defaultSortField?: string;
  defaultSorting?: string;
  showSelect?: boolean;
  showPagination?: boolean;
  dataSize?: number;
  pageSize?: number;
  onPageChange?: (page: number, sizePerPage: number) => void;
  onSizePerPageChange?: (sizePerPage: number) => void;
  activePage?: number;
  showLoadingOverlay?: boolean;
  updateSelectedRows?: (rows: any) => void;
  onSort?: (field: string, direction: ElasticsearchSortDirection) => void;
  selectedRows?: any[];
  noDataText?: string;
  className?: string;
  noPadding?: boolean;
  expandRow?: (row: any) => JSX.Element;
  expanded?: string[];
  onExpand?: (row: any, isExpand: boolean, rowIndex: number, e: any) => void;
  expandHeaderColumnRenderer?: (props: { isAnyExpands: boolean }) => JSX.Element | null;
  expandColumnRenderer?: (props: { expanded: boolean }) => JSX.Element | null;
  onlyOneExpanding?: boolean;
  headerClasses?: string;
  hasOverlay?: boolean;
  striped?: boolean;
  rowClasses?: (row: any, rowIndex: number) => string;
  remote?: { pagination: boolean; sort: boolean };
  nonSelectable?: string[];
  singleSelect?: boolean;
  multiPageSelect?: boolean;
  noBoxShadow?: boolean;
}

const DataTable: React.FC<IProps> = ({
  data,
  columns,
  renderHeader,
  keyField = 'id',
  handleRowClick,
  defaultSortField = 'name',
  defaultSorting = 'asc',
  showSelect = true,
  showPagination = true,
  dataSize,
  pageSize = 20,
  onPageChange = (page, sizePerPage) => {},
  onSizePerPageChange = (sizePerPage) => {},
  activePage = 1,
  showLoadingOverlay = false,
  updateSelectedRows = (rows) => {},
  selectedRows = [],
  onSort,
  noDataText = 'Oops. We don’t have any data for that. Try another search.',
  noBoxShadow = false,
  className = '',
  noPadding = true,
  expandRow,
  expanded,
  onExpand,
  singleSelect = false,
  expandHeaderColumnRenderer = (props: { isAnyExpands: boolean }) => (
    <FontAwesomeIcon
      className="icon-lowered cursor-pointer"
      icon={props.isAnyExpands ? faAngleUp : faAngleDown}
      size="2x"
    />
  ),
  expandColumnRenderer = (props: { expanded: boolean }) => (
    <FontAwesomeIcon
      className="icon-lowered cursor-pointer"
      icon={props.expanded ? faAngleUp : faAngleDown}
      size="lg"
    />
  ),
  onlyOneExpanding = false,
  headerClasses,
  hasOverlay = true,
  striped = false,
  rowClasses = (row, rowIdx) => '',
  remote = { pagination: true, sort: true },
  nonSelectable,
  multiPageSelect = false,
}) => {
  const [filteredData, setFilteredData] = useState(data);

  const { isSupportMode } = useContext(ApplicationContext);

  const writeToClipboard = useCallback(
    async (rowData: any) => {
      if (isSupportMode && navigator?.clipboard) {
        await navigator.clipboard.writeText(JSON.stringify(rowData));
        showToast('row data copied to clipboard', 'info');
      }
    },
    [isSupportMode]
  );

  // if the provided data array changes (ex: after a mutation), we need to update the data array in state which is used in the data table
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const onSelectAll = useCallback(
    (isSelect, rows) => {
      if (singleSelect) return [];
      if (!isSelect) updateSelectedRows([]);
      if (isSelect) updateSelectedRows(multiPageSelect ? [...selectedRows, ...rows] : rows);
    },
    [updateSelectedRows, singleSelect, selectedRows, multiPageSelect]
  );
  const onSelect = useCallback(
    (row, isSelect) => {
      if (singleSelect) {
        isSelect ? updateSelectedRows([row]) : updateSelectedRows([]);
      } else {
        isSelect
          ? updateSelectedRows([...selectedRows, row])
          : updateSelectedRows([...selectedRows.filter((r) => r[keyField] !== row[keyField])]);
      }
    },
    [keyField, selectedRows, updateSelectedRows]
  );

  const handleTableSort = useCallback(
    (type: DatatableHandleChangeType, newState: IDatatableHandleChangeState) => {
      if (type === 'sort' && onSort) {
        const { sortOrder, sortField } = newState;
        const direction = sortOrder.match(/asc/) ? 'ASCENDING' : 'DESCENDING';
        onSort(sortField, direction);
      }
    },
    [onSort]
  );

  // if handle table change is not passed, use default sort function
  const defaultHandleTableSort = (
    type: DatatableHandleChangeType,
    { sortField, sortOrder, data }: IDatatableHandleChangeState
  ) => {
    let result;
    if (sortOrder === 'asc') {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setFilteredData(result);
  };

  return (
    <PaginationProvider
      pagination={paginationFactory(
        getPageOptionsFromDataLength({
          dataSize,
          pageSize,
          onPageChange,
          onSizePerPageChange,
          currentPage: activePage,
        })
      )}
    >
      {({ paginationProps, paginationTableProps }: any) => (
        <div>
          <ToolkitProvider bootstrap4 keyField={keyField} data={filteredData} columns={getCustomizedColumns(columns)}>
            {(toolkitProps: any) => (
              <div>
                {renderHeader && renderHeader(paginationProps, toolkitProps.searchProps, setFilteredData)}
                <BootstrapTable
                  striped={striped}
                  loading={showLoadingOverlay}
                  classes={
                    noBoxShadow
                      ? 'table table-borderless kt-datatable no-box-shadow'
                      : 'table table-borderless kt-datatable'
                  }
                  wrapperClasses={`d-flex table-responsive ${!noPadding ? 'p-8' : ''} ${className}`}
                  headerClasses={headerClasses}
                  bordered={false}
                  defaultSorted={[{ dataField: defaultSortField, order: defaultSorting }]}
                  defaultSortDirection="asc"
                  selectRow={getCustomSelect({
                    showSelect,
                    onSelect,
                    onSelectAll,
                    selectedRows,
                    nonSelectable,
                    keyField,
                    singleSelect,
                  })}
                  rowEvents={
                    handleRowClick
                      ? {
                          onClick: (evt: any, rowData: any) => {
                            writeToClipboard(rowData);
                            handleRowClick(evt, rowData);
                          },
                        }
                      : {
                          onClick: (evt: any, rowData: any) => {
                            writeToClipboard(rowData);
                          },
                        }
                  }
                  rowClasses={(row: any, idx: number) =>
                    classnames({
                      'cursor-pointer': Boolean(handleRowClick),
                      [rowClasses(row, idx)]: true,
                    })
                  }
                  remote={remote}
                  onTableChange={onSort ? handleTableSort : defaultHandleTableSort}
                  noDataIndication={noDataText}
                  overlay={
                    hasOverlay
                      ? (loading: boolean) => (_props: any) =>
                          <SpinnerOverlay show={loading}>{_props.children}</SpinnerOverlay>
                      : null
                  }
                  expandRow={
                    expandRow
                      ? {
                          showExpandColumn: true,
                          expandColumnPosition: 'right',
                          expandHeaderColumnRenderer,
                          expandColumnRenderer,
                          renderer: expandRow,
                          onlyOneExpanding: onlyOneExpanding,
                          onExpand: onExpand ?? undefined,
                          expanded: expanded ?? undefined,
                        }
                      : undefined
                  }
                  {...paginationTableProps}
                  {...toolkitProps.baseProps}
                />
                {showPagination && (
                  <div className={`d-flex justify-content-between k2-pagination`}>
                    <PaginationTotalStandalone {...paginationProps} />
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                )}
              </div>
            )}
          </ToolkitProvider>
        </div>
      )}
    </PaginationProvider>
  );
};

export default DataTable;
