import React from 'react';
import { ICustomFieldProps } from '../CustomField';

const Signature: React.FC<ICustomFieldProps> = ({ customField, value, disabled, isReadOnly, onChange }) => {
  // signatures will only ever be read only ....?
  const valueExists = !(value === null || value === undefined || value === '');
  return (
    <>
      <label>{customField.label}</label>
      {!valueExists && <p>No Signature Captured</p>}
      {valueExists && <img src={value} className="w-50" />}
    </>
  );
};
export default Signature;
