import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import Amplify from '@aws-amplify/core';
import config from './config';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import LaunchDarklyFlags from 'shared/constants/launchdarklyFlags';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.scss';
import { ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as BSThemeProvider } from 'react-bootstrap';
import theme from 'muiTheme';
import { ApplicationContext } from './shared/contexts/ApplicationContext';

if (process.env.NODE_ENV.includes('prod')) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: `production-${config.locale.region}`,
  });
}

if (document.cookie) {
  const cookies = document.cookie.split(';').reduce((a, e) => {
    const [key, value] = e.split('=');
    a[key.trim()] = value.trim();
    return a;
  }, {});

  if (cookies['KtSupportAuth.WasUsed'] === 'true') {
    for (let [key, value] of Object.entries(cookies)) {
      if (key.indexOf(`CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}`) === 0) {
        localStorage.setItem(key, value);
      }
    }
  }
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      redirectSignIn: `${window.location.origin}/`,
      redirectSignOut: `${window.location.origin}/login`,
      responseType: 'code',
      ...config.oauth,
    },
  },
});

(async () => {
  const urlSearchParams = new URLSearchParams(document?.location?.search);
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarkly.apiKey,
    flags: LaunchDarklyFlags,
  });

  // render micro frontend function
  window.renderCreatereactapp = (containerId, history) =>
    ReactDOM.render(
      <ApplicationContext.Provider value={{ isSupportMode: urlSearchParams?.get('isSupportMode') === 'true' ?? false }}>
        <Provider store={store}>
          <App history={history} />
        </Provider>
      </ApplicationContext.Provider>,
      document.getElementById(containerId)
    );

  // unmount micro frontend function
  window.unmountCreatereactapp = (containerId) => ReactDOM.unmountComponentAtNode(document.getElementById(containerId));

  // Mount to root if it is not a micro frontend
  if (!document.getElementById('Createreactapp-container')) {
    ReactDOM.render(
      <ApplicationContext.Provider value={{ isSupportMode: urlSearchParams?.get('isSupportMode') === 'true' ?? false }}>
        <LDProvider>
          <Provider store={store}>
            <BSThemeProvider>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </BSThemeProvider>
          </Provider>
        </LDProvider>
      </ApplicationContext.Provider>,
      document.getElementById('root')
    );
  }
})();
