import { CommsBusinessSetting } from 'generated/graphql';
import { CommsActionTypes, GET_COMMS_BUSINESS_SETTINGS_SUCCESS, GET_USES_COMMS_SUCCESS } from './types';

export const getCommsBusinessSettings = (
  businessSettings: CommsBusinessSetting | undefined | null
): CommsActionTypes => ({
  type: GET_COMMS_BUSINESS_SETTINGS_SUCCESS,
  businessSettings,
});

export const getUsesComms = (usesComms: boolean): CommsActionTypes => ({
  type: GET_USES_COMMS_SUCCESS,
  usesComms,
});
