import React from 'react';
import './newBill.scss';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Dialog, DialogContent, DialogTitle, useTheme, IconButton as MUIiconButton, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFormatDate from 'shared/hooks/useFormatDate';
import { Bill } from '../types/Bill';
import { LineItemGroupComponent } from './LineItemGroup';
import { parsePhoneNumberWithRegion } from 'shared/util/string';

interface IProps {
  open: boolean;
  onClose: () => void;
  accountId: string;
  loading?: boolean;
  bill?: Bill | null;
}

// interface INewBillLineItem {
//   amount: number | null;
//   description: string;
//   transactionType?: ITransactionType;
// }

const NewUpcomingBill: React.FC<IProps> = ({ open, onClose, loading = false, bill }) => {
  const formatDate = useFormatDate();
  const theme = useTheme();
  // const [addedLineItems, setAddedLineItems] = React.useState<INewBillLineItem[]>([]);

  // const handleClearLineItems = React.useCallback(() => {
  //   setAddedLineItems([]);
  // }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle component="div" sx={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>
        Bill{' '}
        <MUIiconButton
          size="small"
          onClick={onClose}
          title={`Close Bill dialog`}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={faTimes} />
          </Box>
        </MUIiconButton>
      </DialogTitle>
      <DialogContent sx={{ marginTop: 2, paddingBottom: '64px' }}>
        <div id="header">
          <div id="center-info">
            {bill?.sellerContactInfo?.logo && (
              <div id="center-title">
                <img id="center-avatar" src={bill?.sellerContactInfo?.logo} />
                <h2>{bill?.sellerContactInfo?.name}</h2>
              </div>
            )}
            <div id="contact-information">
              <div>{bill?.sellerContactInfo?.address?.address1}</div>
              <div>{bill?.sellerContactInfo?.address?.address2}</div>
              <div>
                {bill?.sellerContactInfo?.address?.city}, {bill?.sellerContactInfo?.address?.state},{' '}
                {bill?.sellerContactInfo?.address?.postalCode}
              </div>
              <div>
                {bill?.sellerContactInfo.phoneNumber &&
                  parsePhoneNumberWithRegion(bill?.sellerContactInfo.phoneNumber ?? '').formatNational()}
              </div>{' '}
            </div>
          </div>
          <div id="header-info">
            <div className="info">
              <div className="label">Bill No.</div>
              <div className="value">{bill?.billNo}</div>
            </div>
            <div className="info">
              <div className="label">Issue Date</div>
              <div className="value">{formatDate(bill?.billDate ?? '', 'MM/DD/YY')}</div>
            </div>
            <div className="info">
              <div className="label">Account No.</div>
              <div className="value">{bill?.accountNo}</div>
            </div>
          </div>
        </div>

        <div id="summary" className="section">
          <div>
            <div id="total-due" className="info">
              <div className="label">TOTAL DUE</div>
              <div className="value">{bill?.totalDue}</div>
            </div>
            <div id="due-by">
              Your bill is due <b>{formatDate(bill?.dueDate ?? '', 'MM/DD/YY')}</b>
            </div>
          </div>
          <div id="greeting">
            <div>
              <div id="hello">{bill?.greeting?.greeting}</div>
              <span id="message">{bill?.greeting?.body}</span>
            </div>
          </div>
        </div>
        <hr />

        <div id="account-summary" className="section">
          <h3>ACCOUNT SUMMARY</h3>
          <div className="account-summary__content">
            <div style={{ fontStyle: 'italic', fontWeight: 'lighter', marginBottom: '.5rem' }}>
              (This is based on the Billing History as of now, and is subject to change until the bill is published)
            </div>
            {bill?.accountSummary?.map((summaryItem, index) => (
              <div className="account-summary__content__balances" key={`${summaryItem?.type} ${index}`}>
                <div>{`${summaryItem.type}${summaryItem.date ? ` on (${summaryItem.date})` : ''}`}:</div>
                <div>{summaryItem?.amount}</div>
              </div>
            ))}
            <div className="account-summary__content__balances">
              <div>Current Charges:</div>
              <div>{bill?.subTotal}</div>
            </div>
            {(bill?.discounts ?? []).map((d) => (
              <div className="account-summary__content__balances">
                <div>{d.description}</div>
                <div>{d.amount}</div>
              </div>
            ))}
            <div className="account-summary__content__balances" id="account-summary-total-due">
              <div>Total Due:</div>
              <div>{bill?.totalDue}</div>
            </div>
            {bill?.latePaymentFee && (
              <div id="late-fee-warning">
                <em>
                  To avoid a late payment fee of
                  {` `}
                  {bill?.latePaymentFee?.amount},{` `}
                  {bill?.totalDue}
                  {` `}
                  must be received by
                  {` `}
                  {formatDate(bill?.latePaymentFee?.date ?? '', 'MM/DD/YY')}.
                </em>
              </div>
            )}
          </div>
        </div>

        <hr />

        <div id="bill-details" className="section">
          <h3>CURRENT CHARGES</h3>
          <p>
            Any charges or credits applied to your account after
            {` `}
            {formatDate(bill?.billDate ?? '', 'MM/DD/YY')}
            {` `}
            will be shown on a future bill.
          </p>
        </div>
        <div className="charges">
          {bill?.lineItemGroups?.map((group, index) => (
            <LineItemGroupComponent group={group} key={`${group.name}_${index}`} />
          ))}
        </div>
        {/* {addedLineItems.map((lineItem) => (
          <Row className="d-flex">
            <Col sm={7}>
              <p>{lineItem?.description ?? ''}</p>
            </Col>
            <Col sm={2}>
              <p>{lineItem?.transactionType?.name ?? ''}</p>
            </Col>
            <Col sm={2}>
              <Currency amount={-Math.abs(lineItem?.amount ?? 0)} />
            </Col>
            <Col sm={1}>
              <IconButton
                icon={faTrash}
                onClick={() => {
                  handleClearLineItems();
                }}
              />
            </Col>
          </Row>
        ))} */}
        {/* <IndividualLineItem
          accountId={accountId}
          setAddedLineItems={setAddedLineItems}
          addedLineItems={addedLineItems}
        /> */}
        {/* <div className="charges">
          {mockData.bill.charges.map((charge) => (
            <div className="charges-section">
              <h4>
                <span>{charge.name}</span>
                <span>{charge.total}</span>
              </h4>
              <div className="charges-section__charges-list"></div>
            </div>
          ))}
        </div> */}
      </DialogContent>
    </Dialog>
  );
};

export default NewUpcomingBill;
