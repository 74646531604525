import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'react-bootstrap';
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import colors from '_colors.module.scss';

import '../AddProgramModal/addProgramModal.scss';

import { v4 as uuidv4 } from 'uuid';
import { IEditIneligibility, IEditIneligibilityForm, IKindyForAllIneligibility } from 'shared/types/kindyForAll';
import { useGetKindyForAllIneligibility } from 'gql/kindyForAll/queries';
import { cloneDeep } from 'lodash';
import { ButtonAsLink, IconButton } from 'shared/components/Buttons';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import moment from 'moment';
import { KindyForAllIneligibilityDto } from 'generated/graphql';
import Spinner from 'shared/components/Spinner';
import { DateRangeInputWithArrows } from 'shared/components/DateInput';
import Alert from 'shared/components/Alert/Alert';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  isLoading: boolean;
  programChild: IProgramChild;
  centerId: string;
  handleSubmit: (values: KindyForAllIneligibilityDto[]) => void;
}

const EditIneligibilityModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  programChild,
  centerId,
  handleClose,
  handleSubmit,
}) => {
  const { t } = useTranslation(['translation', 'subsidies']);
  const dateSettings = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;
  const [formData, setFormData] = useState<IEditIneligibilityForm>({ ineligibilities: [] });

  const {
    data: data,
    loading,
    refetch,
  } = useGetKindyForAllIneligibility({
    variables: {
      input: {
        centerId: centerId,
        childId: programChild.childId,
      },
    },
    skip: !centerId || !programChild,
  });

  const handleChange = useCallback(
    (selected: IEditIneligibility) => {
      const mutableState = cloneDeep(formData);
      const ineligibilities = (mutableState.ineligibilities ?? []) as IEditIneligibility[];
      if (ineligibilities) {
        const index = ineligibilities.findIndex((c) => c.id === selected.id);

        if (index >= 0) {
          ineligibilities[index] = selected;
        } else {
          ineligibilities.push(selected);
        }
      } else {
        // @ts-ignore
        ineligibilities.push(selected);
      }

      setFormData({
        ...formData,
        ineligibilities: ineligibilities,
      });
    },
    [formData]
  );

  const buildIneligibilityWeeks = useCallback(
    (ineligibilities: IEditIneligibility[]): IKindyForAllIneligibility[] => {
      const ineligibilityWeeks = ineligibilities.flatMap((i) => i.ineligibilityWeeks) ?? [];

      ineligibilities.forEach((i: IEditIneligibility) => {
        const start = moment(i.startDate).startOf(dateSettings.week);
        const end = i.endDate
          ? moment(i.endDate).endOf(dateSettings.week)
          : moment(i.startDate).endOf(dateSettings.week);

        let current = start;

        while (current.isSameOrBefore(end, 'day')) {
          let weekStart = current.toString();
          let currentWeekIneligibility = ineligibilityWeeks.find((iw) => iw.weekStart === weekStart);

          if (currentWeekIneligibility) {
            currentWeekIneligibility.ineligible = i.ineligible;
          } else {
            currentWeekIneligibility = {
              centerId: centerId,
              childId: programChild.childId,
              weekStart: weekStart.toString(),
              ineligible: i.ineligible,
            };

            ineligibilityWeeks.push(currentWeekIneligibility);
          }

          current.add(1, `week`);
        }
      });

      return ineligibilityWeeks;
    },
    [centerId, dateSettings.week, programChild.childId]
  );

  const rangesOverlap = useCallback(() => {
    if (!formData.ineligibilities) {
      return false;
    }

    const intersect = (rangeA, rangeB) => {
      const startA = moment(rangeA.startDate);
      const endA = moment(rangeA.endDate);
      const startB = moment(rangeB.startDate);
      const endB = moment(rangeB.endDate);
      return (startA.isBefore(endB) && endA.isAfter(startB)) || (startB.isBefore(endA) && endB.isAfter(startA));
    };

    for (let i = 0; i < formData.ineligibilities.length; i++) {
      const currentRange = formData.ineligibilities[i];
      const filteredRanges = formData.ineligibilities.filter((ineligibility) => ineligibility.id !== currentRange.id);

      if (filteredRanges.some((range) => intersect(currentRange, range))) {
        return true;
      }
    }

    return false;
  }, [formData.ineligibilities]);

  const formIsValid = useCallback(() => {
    if (!formData.ineligibilities) {
      return false;
    }

    if (
      formData.ineligibilities.find(
        (i) => i.startDate === undefined || i.endDate === undefined || i.ineligible === undefined
      )
    ) {
      return false;
    }

    if (rangesOverlap()) {
      return false;
    }

    return true;
  }, [formData.ineligibilities, rangesOverlap]);

  const toDto = useCallback(
    (ineligibility: IKindyForAllIneligibility) =>
      ({
        centerId: ineligibility.centerId,
        childId: ineligibility.childId,
        weekStart: ineligibility.weekStart ? moment(ineligibility.weekStart).format('YYYY-MM-DD') : '',
        ineligible: ineligibility.ineligible,
      } as KindyForAllIneligibilityDto),
    []
  );

  const onSave = useCallback(() => {
    if (formIsValid()) {
      const ineligibilities = buildIneligibilityWeeks(formData.ineligibilities ?? []);

      handleSubmit(ineligibilities!.map((i) => toDto(i)));
    }
  }, [buildIneligibilityWeeks, formData.ineligibilities, formIsValid, handleSubmit, toDto]);

  const onOpen = useCallback(() => {
    setFormData({
      ...formData,
      ineligibilities: [
        {
          id: uuidv4(),
          ineligibilityWeeks: [],
        },
      ],
    });

    refetch();
  }, [formData, refetch]);

  const onClose = useCallback(() => {
    setFormData({
      ...formData,
      ineligibilities: [],
    });

    handleClose();
  }, [formData, handleClose]);

  const ineligibilityOptions = [
    { value: true, label: t('subsidies:child-ineligibilities.update-modal.ineligible') },
    { value: false, label: t('subsidies:child-ineligibilities.update-modal.eligible') },
  ];

  const addAdditionalIneligibility = useCallback(() => {
    handleChange({
      id: uuidv4(),
      ineligibilityWeeks: [],
    });
  }, [handleChange]);

  const handledDateChange = (id: string, startDate: string | null, endDate: string | null) => {
    const start = moment(startDate).startOf(dateSettings.week).format().toString();
    const end = endDate
      ? moment(endDate).endOf(dateSettings.week).format().toString()
      : moment(startDate).endOf(dateSettings.week).format().toString();

    let selected = formData.ineligibilities?.find((i) => i.id === id);

    if (selected) {
      selected.startDate = start;
      selected.endDate = end;
    } else {
      selected = {
        id: uuidv4(),
        startDate: start,
        endDate: end,
        ineligible: undefined,
        ineligibilityWeeks: [],
      };
    }

    handleChange(selected);
  };

  const handleIneligibilityChange = useCallback(
    (id: string, value: any) => {
      let selected = formData.ineligibilities?.find((i) => i.id === id);

      if (selected) {
        selected.ineligible = value.value;
      } else {
        selected = {
          id: uuidv4(),
          ineligible: value.value,
          ineligibilityWeeks: [],
        };
      }

      handleChange(selected);
    },
    [formData.ineligibilities, handleChange]
  );

  const handleRemove = useCallback(
    (id: string) => {
      const ineligibilities = formData.ineligibilities?.filter((i) => i.id !== id);
      setFormData({
        ...formData,
        ineligibilities: ineligibilities,
      });
    },
    [formData]
  );

  const sortedData = [...(data?.getKindyForAllIneligibility ?? [])]
    .filter(
      (i) =>
        moment(i.weekStart) <= moment(programChild.endDate ?? i.weekStart) &&
        moment(i.weekStart) >= moment(programChild.startDate)
    )
    .sort((a, b) => moment(a.weekStart).valueOf() - moment(b.weekStart).valueOf());

  const dateOutsideOfRange = (day: any) => {
    const date = moment(day);
    const startOfWeek = date.clone().startOf(dateSettings.week);
    const endOfWeek = date.clone().endOf(dateSettings.week);

    const programChildStartDate = moment(programChild.startDate);
    const programChildEndDate = moment(programChild.endDate ?? day);

    if (date.isBefore(programChildStartDate, 'day') || date.isAfter(programChildEndDate, 'day')) {
      return true;
    }

    if (date.isSame(startOfWeek, 'day') || date.isSame(endOfWeek, 'day')) {
      return false;
    }

    return true;
  };

  return (
    <Modal centered backdrop="static" show={isOpen} onShow={onOpen} onHide={onClose} size="lg">
      <Modal.Header closeButton className="px-4 py-2">
        <div className="d-flex flex-column">
          <Modal.Title as="h5">
            {' '}
            {t('subsidies:child-ineligibilities.update-modal.title')} - {programChild.child.fullName}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-4 px-5">
        <FormWrapper2
          formIsDirty
          toggleDirty={(value) => {}}
          onCancel={onClose}
          onSave={onSave}
          loading={isLoading}
          saveDisabled={!formIsValid()}
        >
          <p className="mb-4">{t('subsidies:child-ineligibilities.update-modal.details')}</p>
          {formData.ineligibilities && (
            <>
              {rangesOverlap() && (
                <Alert variant="danger" className="mb-4">
                  Please ensure date ranges do not overlap.
                </Alert>
              )}
              {formData.ineligibilities.map((i: IEditIneligibility) => (
                <Row key={i.id}>
                  <Col lg={4}>
                    <Form.Group>
                      <DateRangeInputWithArrows
                        required
                        label={t('subsidies:child-ineligibilities.update-modal.select-week-label')}
                        className="kt-date-input-max-width"
                        startDate={i.startDate}
                        endDate={i.endDate}
                        dateOnly={true}
                        isOutsideRange={dateOutsideOfRange}
                        onChange={(dates) => handledDateChange(i.id!, dates.startDate, dates.endDate)}
                      ></DateRangeInputWithArrows>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Select
                      required
                      name="program"
                      label={t('subsidies:child-ineligibilities.update-modal.select-ineligibility-label')}
                      options={ineligibilityOptions}
                      value={ineligibilityOptions.find((o) => o.value === i.ineligible) ?? null}
                      onChange={(val) => {
                        handleIneligibilityChange(i.id!, val);
                      }}
                    />
                  </Col>
                  <IconButton
                    icon={faTrashAlt}
                    className="ml-2 mt-2"
                    iconSize="1x"
                    tooltipText="Remove"
                    onClick={() => {
                      handleRemove(i.id!);
                    }}
                  />
                </Row>
              ))}
            </>
          )}
          <Row noGutters>
            <ButtonAsLink className="" onClick={addAdditionalIneligibility}>
              <FontAwesomeIcon icon={faPlus} color={colors.secondary} className="mr-1" />
              Add date range
            </ButtonAsLink>
          </Row>
          <div className="mt-4">
            <hr />
            <h6 className="mt-4">History</h6>
            {loading && <Spinner className="text-gray mt-4 ml-2" large />}
            {!loading && data?.getKindyForAllIneligibility && (
              <div className="mt-4 pb-4">
                {sortedData.map((i) => (
                  <Row key={i.weekStart}>
                    <Col lg={4}>
                      <span>
                        {moment(i.weekStart).startOf(dateSettings.week).format('MMM D yyyy')} &#8594;{' '}
                        {moment(i.weekStart).endOf(dateSettings.week).format('MMM D yyyy')}
                      </span>
                    </Col>
                    <Col lg={4}>
                      <span>{ineligibilityOptions.find((o) => o.value === i.ineligible)?.label ?? ''}</span>
                    </Col>
                  </Row>
                ))}
              </div>
            )}
            <hr />
          </div>
        </FormWrapper2>
      </Modal.Body>
    </Modal>
  );
};

export default EditIneligibilityModal;
