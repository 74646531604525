import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import DateInput from 'shared/components/DateInput';
import MonthPicker2 from 'shared/components/DateInput/MonthPicker2';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import Switch from 'shared/components/Switch';
import Checkbox from 'shared/components/Checkbox';
import Select from 'shared/components/Select';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';

interface IFormStateShape {
  centerIds: string[];
  start: string | null;
  end: string | null;
  transactionDate: TransactionDateType;
  format: 'CSV' | 'EXCEL';
  includeSubsidyPayments: boolean;
  includeActiveAccounts: boolean;
  includeInactiveAccounts: boolean;
  includeLiabilities: boolean;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  timeframeType: ReportInterval;
  showTransactionDate: boolean;
  showFormatSelector?: boolean;
  showIncludeSubsidyToggle?: boolean;
  showIncludeLiabilitiesToggle?: boolean;
  hasAccountStatusOption?: boolean;
  defaultDate: string | null;
  onSubmit: (
    start: string,
    end: string,
    centerIds: string[],
    format: 'CSV' | 'EXCEL',
    transactionDate: TransactionDateType,
    includeSubsidyPayments: boolean,
    includeLiabilities: boolean,
    statusType?: AccountStatusType
  ) => void;
  onClose: () => void;
}

/**
 * Modal that allows a timeframe to be selected and bubbled up. Depending on the date selection type, different
 * inputs will be rendered
 */
const TimeframeMultipleCenterReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  timeframeType,
  showTransactionDate,
  showFormatSelector = false,
  showIncludeSubsidyToggle = false,
  showIncludeLiabilitiesToggle = false,
  hasAccountStatusOption = false,
  onSubmit,
  onClose,
  defaultDate = null,
  ...props
}) => {
  const [formData, setFormData] = useState<IFormStateShape>({
    centerIds: [],
    start: null,
    end: null,
    format: 'CSV',
    transactionDate: 'APPLIES',
    includeSubsidyPayments: false,
    includeActiveAccounts: true,
    includeInactiveAccounts: false,
    includeLiabilities: false,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!!defaultDate) {
      setFormData({
        ...formData,
        start: defaultDate,
        end: moment.utc().startOf('day').format('YYYY-MM-DD'),
      });
    }
  }, [defaultDate]);

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        centerIds: [],
        start: null,
        end: null,
        format: 'CSV',
        transactionDate: 'APPLIES',
        includeSubsidyPayments: false,
        includeActiveAccounts: true,
        includeInactiveAccounts: false,
        includeLiabilities: false,
      });
    }
  }, [isOpen]);

  const isValidAccountStatusOptions = useMemo(
    () => (hasAccountStatusOption ? formData.includeActiveAccounts || formData.includeInactiveAccounts : true),
    [hasAccountStatusOption, formData]
  );

  const accountStatusType = useMemo(() => {
    if (formData.includeActiveAccounts && formData.includeInactiveAccounts) return undefined;
    if (formData.includeActiveAccounts) return 'Active';
    if (formData.includeInactiveAccounts) return 'Inactive';
    return undefined;
  }, [formData]);

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (formData.start && formData.end && isValidAccountStatusOptions) {
      onSubmit(
        formData.start,
        formData.end,
        formData.centerIds,
        formData.format,
        formData.transactionDate,
        formData.includeSubsidyPayments,
        formData.includeLiabilities,
        accountStatusType
      );
    }
  }, [formData, accountStatusType, isValidAccountStatusOptions, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({
      centerIds: [],
      start: null,
      end: null,
      format: 'CSV',
      transactionDate: 'APPLIES',
      includeSubsidyPayments: false,
      includeActiveAccounts: true,
      includeInactiveAccounts: false,
      includeLiabilities: false,
    });
    onClose();
  }, [onClose]);

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{ disabled: !formData.start || !formData.end, loading: isLoading }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            isRequired
            selectedCenterIds={formData.centerIds}
            onSelect={(ids) => setFormData((prev) => ({ ...prev, centerIds: ids as string[] }))}
          />
        </Col>
      </Row>
      {timeframeType === 'WEEK' && (
        <Row>
          <Col>
            <DateInput
              label="Start"
              required
              date={formData.start}
              onDateSelect={(date) => setFormData((prev) => ({ ...prev, start: date }))}
              className="kt-date-input-no-max-width"
              isOutsideRange={(date: moment.Moment) =>
                formData.end ? date.isSameOrAfter(moment(formData.end), 'date') : false
              }
            />
          </Col>
          <Col>
            <DateInput
              required
              label="End"
              date={formData.end}
              onDateSelect={(date) => setFormData((prev) => ({ ...prev, end: date }))}
              className="kt-date-input-no-max-width"
              isOutsideRange={(date: moment.Moment) =>
                formData.start ? date.isSameOrBefore(moment(formData.start), 'date') : false
              }
            />
          </Col>
        </Row>
      )}
      {timeframeType === 'MONTH' && (
        <Row>
          <Col>
            <MonthPicker2
              required
              label="Start Month"
              date={formData.start}
              onMonthClick={(date: string) =>
                setFormData((prev) => ({ ...prev, start: moment(date).startOf('month').format('YYYY-MM-DD') }))
              }
              className="kt-date-input-no-max-width"
            />
          </Col>
          <Col>
            <MonthPicker2
              required
              label="End Month"
              date={formData.end}
              onMonthClick={(date: string) =>
                setFormData((prev) => ({ ...prev, end: moment(date).endOf('month').format('YYYY-MM-DD') }))
              }
              className="kt-date-input-no-max-width"
            />
          </Col>
        </Row>
      )}
      {showTransactionDate && (
        <Row>
          <Col>
            <Switch
              label="Period Accounting"
              labelSide="top"
              value={formData.transactionDate === 'PERIOD'}
              onChange={(value: boolean) => setFormData({ ...formData, transactionDate: value ? 'PERIOD' : 'APPLIES' })}
              className="mb-4"
              height={30}
            />
          </Col>
        </Row>
      )}
      {showIncludeLiabilitiesToggle && (
        <Row>
          <Col>
            <Switch
              label="Include Liabilities"
              labelSide="top"
              value={formData.includeLiabilities}
              onChange={(value: boolean) => setFormData({ ...formData, includeLiabilities: value })}
              className="mb-4"
              height={30}
            />
          </Col>
        </Row>
      )}
      {hasAccountStatusOption && (
        <>
          <Row className="pl-2">{t('spelling.accountStatusFilter')}</Row>
          <Row className="pl-2">
            <Checkbox
              label={capitalize(t('spelling.active'))}
              value={formData.includeActiveAccounts}
              onChange={(value) => setFormData({ ...formData, includeActiveAccounts: value })}
            />
          </Row>
          <Row className="pl-2">
            <Checkbox
              label={capitalize(t('spelling.inactive'))}
              value={formData.includeInactiveAccounts}
              onChange={(value) => setFormData({ ...formData, includeInactiveAccounts: value })}
            />
          </Row>
        </>
      )}
      {showIncludeSubsidyToggle && (
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Include Subsidy Payments</Form.Label>
              <Form.Check
                inline
                label="Yes"
                name="subsidy"
                type="radio"
                checked={formData.includeSubsidyPayments}
                onChange={(value) => setFormData((prev) => ({ ...prev, includeSubsidyPayments: true }))}
              />
              <Form.Check
                inline
                label="No"
                name="subsidy"
                type="radio"
                checked={!formData.includeSubsidyPayments}
                onChange={(value) => setFormData((prev) => ({ ...prev, includeSubsidyPayments: false }))}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {showFormatSelector && (
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className="mt-4">{capitalize(t('spelling.format'))}</Form.Label>
              <Select
                options={[
                  { value: 'CSV', label: 'CSV' },
                  { value: 'EXCEL', label: 'EXCEL' },
                ]}
                value={formData.format ?? { value: 'CSV', label: 'CSV' }}
                onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </SideModalDrawer>
  );
};

export default TimeframeMultipleCenterReportModal;
