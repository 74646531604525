import React, { useCallback, useEffect, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import errorMessage from 'shared/constants/errorMessages';
import MedicalConditionInput from './MedicalConditionInput';
import { useUpdateMedicalCondition } from 'gql/medicalCondition/mutations';
import { showToast } from 'shared/components/Toast';
import Checkbox from '../Checkbox';
import { Collapse } from 'react-bootstrap';
import ChildMedicalDocumentationUploadForm from 'pages/Families/subroutes/Children/Child/components/HealthWellnessTab/ChildMedicalDocumentationUploadForm';
import ActiveDocumentsSummary from 'pages/Families/subroutes/Children/Child/components/HealthWellnessTab/ActiveDocumentsSummary';
import _ from 'lodash';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  medicalCondition: IMedicalCondition;
  readOnly: boolean;
}

const EditMedicalConditionModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  medicalCondition: savedMedicalCondition,
  readOnly,
}) => {
  const {
    id,
    personId,
    childId,
    type,
    __typename,
    archivedAt,
    createdAt,
    createdByPerson,
    isRevised,
    archivedByPerson,
    documents,
    ...rest
  } = savedMedicalCondition;
  const [medicalCondition, updateMedicalCondition] = useState<IMedicalConditionInput>({ ...rest, documentation: [] });
  const [showDocumentationFormGroup, setShowDocumentationFormGroup] = useState<boolean>(false);

  const [updateMedicalConditionFn, { loading }] = useUpdateMedicalCondition();

  const handleSave = useCallback(() => {
    updateMedicalConditionFn({
      variables: {
        id,
        input: medicalCondition,
      },
    })
      .then(() => {
        showToast('Medical condition saved.', 'success');
        onClose();
      })
      .catch(() => {
        showToast('There was an error saving medical condition.', 'error');
      });
  }, [onClose, medicalCondition, id]);

  const isFormValid =
    medicalCondition.name &&
    medicalCondition.typeId &&
    medicalCondition.risk &&
    (medicalCondition.risk === 'Low' ||
      medicalCondition.risk === 'None' ||
      (!_.isNil(medicalCondition.symptoms) && medicalCondition.symptoms?.length != 0 && medicalCondition.instructions));

  return (
    <SideModalDrawer
      title="Edit Medical Condition"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Save"
      primaryCallback={handleSave}
      primaryButtonProps={{
        disabled: !isFormValid || readOnly,
        loading,
      }}
      footerHelperText={!isFormValid ? errorMessage.formRequirements : ''}
      closeOnPrimaryCallback={false}
    >
      <MedicalConditionInput
        medicalCondition={medicalCondition}
        updateMedicalCondition={updateMedicalCondition}
        readOnly={readOnly}
        readOnlyNameAndType={true}
      />
      <ActiveDocumentsSummary condition={savedMedicalCondition} />
      {Boolean(childId) && (
        <Checkbox
          label="Add Documentation"
          value={showDocumentationFormGroup}
          onChange={(checked) => setShowDocumentationFormGroup(checked)}
          className="mb-4"
        />
      )}
      <Collapse in={showDocumentationFormGroup}>
        <div>
          <ChildMedicalDocumentationUploadForm
            documents={medicalCondition.documentation}
            onFilesAdd={(files) =>
              updateMedicalCondition((prev) => ({
                ...prev,
                documentation: [...prev.documentation, ...files],
              }))
            }
            onFileUpdate={(updatedFile) =>
              updateMedicalCondition((prev) => ({
                ...prev,
                documentation: prev.documentation.map((f) => (f.file.name === updatedFile.file.name ? updatedFile : f)),
              }))
            }
            onRemoveFile={(filename) =>
              updateMedicalCondition((prev) => ({
                ...prev,
                documentation: prev.documentation.filter((file) => file.file.name !== filename),
              }))
            }
            childId={childId ?? ''}
          />
        </div>
      </Collapse>
    </SideModalDrawer>
  );
};

export default EditMedicalConditionModal;
