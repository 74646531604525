import React from 'react';
import PersonalInformationForm from './PersonalInformationForm';
import Card from 'shared/components/Card';
import { Row, Col } from 'shared/components/Layout';
import AvatarUpload from 'shared/components/AvatarUpload';
import { PersonAvatar } from 'shared/components/Avatar';
import cast from 'shared/util/cast';
import TagsForm from './TagsForm';
import MealForm from './MealForm';
import useUpdateAvatar from 'shared/hooks/useUpdateAvatar';
import { getFullName } from 'shared/util/string';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import moment from 'moment';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import ChildEmergencyContacts from './ChildEmergencyContacts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RestrictDocuments from './RestrictDocuments';

interface IProps {
  child: IChild | undefined;
  loading: boolean;
  readOnly: boolean;
}

const ProfileTab: React.FC<IProps> = ({ child, loading, readOnly }) => {
  const [updateAvatarFn, { loading: avatarLoading }] = useUpdateAvatar('child');
  const hasEditChildTagsPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Tags,
    level: RoleLevelType.Edit,
  });
  const mealTrackingEnabled = useSelector(
    (state: RootState) => state.context?.businessConfiguration?.mealTrackingEnabled ?? false
  );

  return (
    <div>
      <Row align="start">
        <Col lg={6}>
          <Card bodyClassName="p-4" loading={loading} loadingLines={2}>
            {child && (
              <Row noGutters>
                {readOnly ? (
                  <PersonAvatar person={cast<IPerson>(child)} size="lg" className="mr-4" />
                ) : (
                  <AvatarUpload
                    loading={avatarLoading}
                    image={child?.avatar?.url}
                    onFileSelect={(file: File) => {
                      child && updateAvatarFn({ id: child.id, avatar: file });
                    }}
                    placeholder="Upload"
                    className="mr-4"
                  />
                )}
                <div>
                  <h5>{getFullName(child)}</h5>
                  <small>{getAgeStringFromDateOfBirth(moment(child.dob))}</small>
                </div>
              </Row>
            )}
          </Card>
          <Card header="Personal Information" loading={loading} loadingLines={7}>
            {child && <PersonalInformationForm child={child} readOnly={readOnly} />}
          </Card>
          <HasRoleAreaLevel
            action={{ area: AreaType.Child, permission: PermissionType.Tags, level: RoleLevelType.Read }}
          >
            <Card header="Tags" loading={loading} loadingLines={3}>
              {child && <TagsForm child={child} readOnly={!hasEditChildTagsPermission} />}
            </Card>
          </HasRoleAreaLevel>
          <Card header="Restrictions" loading={loading} loadingLines={1}>
            {child && <RestrictDocuments child={child} readOnly={readOnly} />}
          </Card>
        </Col>
        <Col lg={6}>
          <Card header="Emergency Contacts" loading={loading} loadingLines={4}>
            {child && <ChildEmergencyContacts child={child} readOnly={readOnly} />}
          </Card>
          {mealTrackingEnabled && (
            <Card header="Meals" loading={loading} loadingLines={1}>
              {child && <MealForm child={child} readOnly={readOnly} />}
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProfileTab;
