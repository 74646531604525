import React from 'react';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import Button from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';
import Select from 'shared/components/Select';
import DateInput from 'shared/components/DateInput';
import NumberInput from 'shared/components/TextInput/NumberInput';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import ServiceFeeRuleGroup from '../ServiceFeeRuleGroup';
import ActionDropdown from 'shared/components/ActionDropdown';

interface IProps {
  serviceFee: IServiceFee;
  onUpdate: (updateServiceFee: IServiceFee) => void;
  onDelete: (deleteServiceFee: IServiceFee) => void;
  onAddRuleGroup: (serviceFee: IServiceFee, parentGroup: IServiceFeeRuleGroup | undefined) => void;
  onUpdateRuleGroup: (serviceFee: IServiceFee, updateGroup: IServiceFeeRuleGroup) => void;
  onDeleteRuleGroup: (
    serviceFee: IServiceFee,
    deleteGroup: IServiceFeeRuleGroup,
    parentGroup: IServiceFeeRuleGroup | undefined
  ) => void;
  onAddRule: (parentGroup: IServiceFeeRuleGroup) => void;
  onUpdateRule: (serviceFee: IServiceFee, updateRule: IServiceFeeRule, parentGroup: IServiceFeeRuleGroup) => void;
  onDeleteRule: (deleteRule: IServiceFeeRule, parentGroup: IServiceFeeRuleGroup) => void;
}

const ServiceFeeCard: React.FC<IProps> = ({
  serviceFee,
  onUpdate,
  onDelete,
  onAddRuleGroup,
  onUpdateRuleGroup,
  onDeleteRuleGroup,
  onAddRule,
  onUpdateRule,
  onDeleteRule,
}) => {
  const { t } = useTranslation();

  const serviceFeeTypeOptions: { value: ServiceFeeType; label: string }[] = [
    { value: 'CC', label: t('billing.spelling.cc') },
    { value: 'ACH', label: t('billing.spelling.ach') },
  ];

  const calculationTypeOptions: { value: CalculationType; label: string }[] = [
    { value: 'FLAT', label: 'Flat' },
    { value: 'PERCENT', label: 'Percent' },
  ];

  return (
    <Card className={`${!serviceFee.active ? 'bg-smoke' : ''} m-0`}>
      <Row>
        <Col lg>
          <TextInput
            required
            id="fee-name-input"
            label="Name"
            name="fee-name"
            placeholder="Fee Name"
            value={serviceFee.name}
            onChange={(value: string) => onUpdate({ ...serviceFee, name: value })}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <Select
            label="Type"
            options={serviceFeeTypeOptions}
            getOptionLabel={(g) => g.label}
            getOptionValue={(g) => g.value}
            value={serviceFee.serviceFeeType}
            onChange={(option) => onUpdate({ ...serviceFee, serviceFeeType: option.value })}
          />
        </Col>
        <Col xl={4}>
          <Select
            label="Calculation Type"
            options={calculationTypeOptions}
            getOptionLabel={(g) => g.label}
            getOptionValue={(g) => g.value}
            value={serviceFee.calculationType}
            onChange={(option) => onUpdate({ ...serviceFee, calculationType: option.value })}
          />
        </Col>
        <Col xl={4}>
          {serviceFee.calculationType === 'FLAT' ? (
            <CurrencyInput2
              required
              className="border-radius-0"
              type="number"
              id="fee-amount-input"
              label="Amount (in dollars)"
              name="fee-amount"
              placeholder="0"
              value={serviceFee.amount.toFixed(2)}
              onChange={(value: string) => onUpdate({ ...serviceFee, amount: Number(value) })}
            />
          ) : (
            <NumberInput
              required
              type="number"
              id="fee-amount-input"
              label="Amount (in percentage)"
              name="fee-amount"
              placeholder="0"
              appendText="%"
              value={serviceFee.amount}
              onChange={(value: string) => onUpdate({ ...serviceFee, amount: Number(value) })}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <DateInput
            required
            label="Start Date"
            date={serviceFee.startDate}
            onDateSelect={(value) => onUpdate({ ...serviceFee, startDate: value })}
            dateOnly
          />
        </Col>
        <Col xl={4}>
          <DateInput
            label="End Date"
            date={serviceFee.endDate ?? null}
            onDateSelect={(value) => onUpdate({ ...serviceFee, endDate: value })}
            dateOnly
          />
        </Col>
      </Row>
      <hr />
      <Row className="mb-4" noGutters>
        <span className="border-radius-0 font-weight-bold mr-2">Service Fee Rules</span>
        <ActionDropdown
          className="ml-auto"
          actions={[
            {
              label: 'Add Rule Group',
              onClick: () => onAddRuleGroup(serviceFee, undefined),
            },
          ]}
        />
      </Row>
      <Row>
        <Col xl>
          {serviceFee.serviceFeeRuleGroups.map((group) => (
            <ServiceFeeRuleGroup
              key={group.id}
              serviceFee={serviceFee}
              serviceFeeRuleGroup={group}
              level={0}
              onAdd={onAddRuleGroup}
              onUpdate={onUpdateRuleGroup}
              onDelete={onDeleteRuleGroup}
              onAddRule={onAddRule}
              onUpdateRule={onUpdateRule}
              onDeleteRule={onDeleteRule}
            />
          ))}
        </Col>
      </Row>
      <Row>
        <Col lg>
          <Button className="ml-auto" onClick={() => onDelete(serviceFee)}>
            Delete Service Fee
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default ServiceFeeCard;
