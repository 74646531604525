import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { accountingPeriodPaginatedFields } from './fields';

interface IGetAccountingPeriodsData {
  getAccountingPeriods: IAccountingPeriodsPagedResult;
}

interface IGetAccountingPeriodsVariables {
  input: IGetAccountingPeriodInput;
}

export const GET_ACCOUNTING_PERIOD = (fields: string = accountingPeriodPaginatedFields) => gql`
  query ($input: GetAccountingPeriodsInput!) {
    getAccountingPeriods(input: $input) {
      ${fields}
    }
  }
`;

export const useGetAccountingPeriod = (
  options?: QueryHookOptions<IGetAccountingPeriodsData, IGetAccountingPeriodsVariables>,
  fields?: string
) =>
  useQuery(GET_ACCOUNTING_PERIOD(fields), {
    fetchPolicy: 'network-only',
    ...options,
  });
