import { Skeleton, Stack, Tooltip, Typography } from '@mui/material';

/**
 * Component for displaying Information about something in the RowOverview
 * @param param0
 * @returns
 */
export function RowDetail({
  loading,
  name,
  toolTip,
  value,
}: {
  loading: boolean;
  name: string;
  toolTip?: string;
  value?: string;
}) {
  return (
    <Tooltip title={toolTip}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        {loading ? <Skeleton width="80%" /> : <Typography>{name}:</Typography>}
        {loading ? <Skeleton width="20%" /> : <Typography>{value}</Typography>}
      </Stack>
    </Tooltip>
  );
}
