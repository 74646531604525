import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { SingleAccordion } from 'shared/components/Accordion';
import { Row } from 'shared/components/Layout';
import moment from 'moment';
import Button from 'shared/components/Buttons';
import SearchAvailabilityInputs, { SearchAvailabilityInput } from './SearchAvailabilityInputs';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import SearchAvailabilityResults from 'shared/components/SearchAvailabilityResults';
import useSearchAvailability from 'shared/hooks/useSearchAvailability';

interface IProps {
  initialInput?: SearchAvailabilityInput;
  withSearch?: boolean;
}

const SearchAvailability: React.FC<IProps> = ({ ...props }) => {
  const selectedCenterId = useSelector((state: RootState) => state.enrollment.activeCenter.id);

  const [searchInput, updateSearchInput] = useState<SearchAvailabilityInput>({
    startDate: '',
    birthDate: '',
    classes: [],
  });

  const { state: searchState, fn: searchFn } = useSearchAvailability({
    selectedCenterId,
    searchInput,
  });

  const { data, loading, classes, firstDateInView, dataDateRange, classesToFilter, networkStatus } = searchState;

  const { setClassesToFilter, setDataDateRange, setFirstDateInView } = searchFn;

  return (
    <SingleAccordion title="Search Availability" className="mb-4 border-bottom-0">
      <Row noGutters align="end">
        <SearchAvailabilityInputs input={searchInput} updateInput={updateSearchInput} />
        <Button
          disabled={!searchInput.birthDate || !searchInput.startDate || networkStatus === NETWORK_STATUS.SET_VARIABLES}
          variant="secondary"
          loading={loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES}
          onClick={() => {
            setClassesToFilter(searchInput.classes);
            setFirstDateInView(searchInput.startDate);
            setDataDateRange({
              startDate: moment(searchInput.startDate).clone().subtract(10, 'd').format(),
              endDate: moment(searchInput.startDate).clone().add(20, 'd').format(),
            });
          }}
        >
          Search
        </Button>
      </Row>

      <SearchAvailabilityResults
        data={data}
        loading={loading}
        classes={classes}
        dataDateRange={dataDateRange}
        firstDateInView={firstDateInView}
        classesToFilter={classesToFilter}
        setDataDateRange={setDataDateRange}
        setFirstDateInView={setFirstDateInView}
      />
    </SingleAccordion>
  );
};

export default SearchAvailability;
