import React, { useCallback } from 'react';
import moment from 'moment';
import { groupBy } from 'lodash';
import DataTable from 'shared/components/DataTable';
import ActionDropdown from 'shared/components/ActionDropdown';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import { getFullName } from 'shared/util/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerCheck,
  faMapMarkerTimes,
  faMapMarkerMinus,
  faMapMarkerQuestion,
  faMapMarkerExclamation,
  faPencilAlt,
} from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { isRegion } from 'shared/util/region';
import cast from '../../../shared/util/cast';
import { PersonAvatar } from '../../../shared/components/Avatar';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ITableRowShape {
  child: IChild;
  class: IClass;
  accountName: string | undefined;
  accountId: string;
  sessionsForWeek: ISession[];
  existingEnrollmentYearAbsences: { id: string }[];
}

interface IProps {
  data: ISession[];
  loading: boolean;
  timezone: string;
  startOfWeek: string; // iso string
  onEditSession: (sessions: ISession[], child: IChild) => void;
  onSort: (field: string, direction: 'asc' | 'desc') => void;
}

const WeeklyAttendanceTable: React.FC<IProps> = ({
  data,
  loading,
  timezone,
  startOfWeek,
  onEditSession,
  onSort,
  ...props
}) => {
  const { k2AttendanceAccountName } = useFlags();
  /**
   * our data comes in as an array of sessions for the week. we need to group these by account+child id
   * otherwise we will have a new table row for each session
   */
  const groupedData = groupBy(data, (s) => `${s.accountId}_${s.childId}_${s.classId}`);
  const tableData: ITableRowShape[] = Object.values(groupedData).map((sessions: ISession[]) => ({
    child: sessions[0].child,
    class: sessions[0].class,
    accountId: sessions[0].accountId,
    accountName: sessions[0].accountName,
    sessionsForWeek: sessions,
    existingEnrollmentYearAbsences: sessions[0].existingEnrollmentYearAbsences ?? [],
  }));
  const renderDayOfWeekCell = useCallback(
    (dayOfWeek: number, tableRow: ITableRowShape) => {
      const { sessionsForWeek } = tableRow;
      const expectedSession = sessionsForWeek.find((s: ISession) => {
        const sessionDate = moment(s.date, 'YYYY-MM-DD');
        // use a moment object with the session date and current time in the requested timezone for comparison
        const zonedSessionDate = moment().tz(timezone).set({
          year: sessionDate.year(),
          month: sessionDate.month(),
          date: sessionDate.date(),
        });

        return zonedSessionDate.day() === dayOfWeek;
      });

      if (expectedSession) {
        if (expectedSession.absence) {
          return (
            <div className="d-flex flex-row align-items-center">
              <FontAwesomeIcon icon={faMapMarkerTimes} color={colors.secondaryOrange} size="2x" className="mr-4" />
              <div>
                <div>
                  {expectedSession.totalTime.hours}h {expectedSession.totalTime.minutes}m
                </div>
                <div className="text-orange">Absent</div>
              </div>
            </div>
          );
        }

        if (expectedSession.timeEntries.length) {
          const allTimeEntriesClosed = expectedSession.timeEntries.every((te) => te.timeOut);

          return (
            <div className="d-flex flex-row align-items-center">
              <FontAwesomeIcon
                icon={allTimeEntriesClosed ? faMapMarkerMinus : faMapMarkerCheck}
                color={allTimeEntriesClosed ? colors.slate : colors.success}
                size="2x"
                className="mr-4"
              />
              <div>
                {expectedSession.totalTime.hours}h {expectedSession.totalTime.minutes}m
              </div>
            </div>
          );
        }

        if (expectedSession.dropOffTime) {
          const sessionDate = moment(expectedSession.date, 'YYYY-MM-DD');
          const dropOffTime = moment(expectedSession.dropOffTime, 'HH:mm');
          const dropOffDateAndTime = moment().tz(timezone).set({
            year: sessionDate.year(),
            month: sessionDate.month(),
            date: sessionDate.date(),
            hours: dropOffTime.hours(),
            minutes: dropOffTime.minutes(),
          });

          if (moment().tz(timezone).isAfter(dropOffDateAndTime)) {
            return (
              <div className="d-flex flex-row align-items-center">
                <FontAwesomeIcon icon={faMapMarkerExclamation} color={colors.danger} size="2x" className="mr-4" />
                <div>
                  {expectedSession.totalTime.hours}h {expectedSession.totalTime.minutes}m
                </div>
              </div>
            );
          }
        }

        return (
          <div className="d-flex flex-row align-items-center">
            <FontAwesomeIcon icon={faMapMarkerQuestion} color={colors.info} size="2x" className="mr-4" />
            <div>
              {expectedSession.totalTime.hours}h {expectedSession.totalTime.minutes}m
            </div>
          </div>
        );
      }

      return null;
    },
    [timezone]
  );

  const totalDurationForWeek = useCallback((tableRow: ITableRowShape): string => {
    const { sessionsForWeek } = tableRow;
    const totalMinutes = sessionsForWeek.reduce(
      (acc, curr) => (acc += curr.totalTime.hours * 60 + curr.totalTime.minutes),
      0
    );
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return `${totalHours}h ${remainingMinutes}m`;
  }, []);

  const additionalActions = useCallback(
    (tableRow: ITableRowShape): IDropdownAction[] => {
      const actions: IDropdownAction[] = [
        {
          label: 'View/Edit',
          onClick: () => onEditSession(tableRow.sessionsForWeek, tableRow.child),
          icon: faPencilAlt,
          disabled: !tableRow.sessionsForWeek.every((s) => s.metadata.isWritable),
        },
      ];

      return actions;
    },
    [onEditSession]
  );

  return (
    <DataTable
      noPadding
      className="attendance-table"
      data={tableData}
      dataSize={tableData.length}
      showLoadingOverlay={loading}
      showPagination={false}
      showSelect={false}
      onSort={(field, direction) => onSort(field, direction === 'ASCENDING' ? 'asc' : 'desc')}
      columns={[
        {
          text: 'Child',
          sort: true,
          dataField: 'child.lastname',
          formatter: (cell: any, row: ITableRowShape) => (
            <div className="d-flex align-items-center">
              <PersonAvatar person={cast<IPerson>(row.child)} className="mr-2" />
              <div>
                <div>{getFullName(row.child)}</div>
                {k2AttendanceAccountName && (
                  <Link className="session-account-name" to={`/families/accounts/${row.accountId}/profile`}>
                    {row?.accountName}
                  </Link>
                )}
                {isRegion('AU') && <small>Absences: {row.existingEnrollmentYearAbsences?.length ?? 0}</small>}
              </div>
            </div>
          ),
        },
        {
          text: 'Class',
          dataField: 'class.name',
          sort: true,
        },
        {
          text: 'Age',
          dataField: 'child.dob',
          align: 'center',
          sort: true,
          formatter: (cell: any, row: ITableRowShape) => getAgeStringFromDateOfBirth(moment(row.child.dob), false),
        },

        {
          text: `${moment(startOfWeek).day(1).format('ddd, D')}`,
          align: 'center',
          formatter: (cell: any, row: ITableRowShape) => renderDayOfWeekCell(1, row),
        },
        {
          text: `${moment(startOfWeek).day(2).format('ddd, D')}`,
          align: 'center',
          formatter: (cell: any, row: ITableRowShape) => renderDayOfWeekCell(2, row),
        },
        {
          text: `${moment(startOfWeek).day(3).format('ddd, D')}`,
          align: 'center',
          formatter: (cell: any, row: ITableRowShape) => renderDayOfWeekCell(3, row),
        },
        {
          text: `${moment(startOfWeek).day(4).format('ddd, D')}`,
          align: 'center',
          formatter: (cell: any, row: ITableRowShape) => renderDayOfWeekCell(4, row),
        },
        {
          text: `${moment(startOfWeek).day(5).format('ddd, D')}`,
          align: 'center',
          formatter: (cell: any, row: ITableRowShape) => renderDayOfWeekCell(5, row),
        },
        {
          text: 'Session',
          align: 'center',
          formatter: (cell: any, row: ITableRowShape) => totalDurationForWeek(row),
        },
        // CCS. Add when we implement
        {
          text: 'Actions',
          dataField: 'actions',
          isDummyField: true,
          align: 'center',
          headerClasses: 'text-center',
          formatter: (cell: any, row: ITableRowShape) => <ActionDropdown actions={additionalActions(row)} />,
        },
      ]}
    />
  );
};

export default WeeklyAttendanceTable;
