import React from 'react';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { Dropdown, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './_groupedFilters.scss';
import { useMediaQuery } from '@mui/material';
import theme from 'muiTheme';
import Button from '../Buttons';
import { Col } from '../Layout';

interface IProps {
  totalFilters: number;
  handleClearFilters: () => void;
}

const GroupedFilters: React.FC<IProps> = ({ totalFilters, handleClearFilters, ...props }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Dropdown id="grouped-filter" className={isMobile ? 'w-100' : 'ml-2'}>
        <Dropdown.Toggle className="k2-datatable-dropdown-toggle" as="button">
          <div className={`filter-count ${totalFilters === 0 ? '' : 'active'}`}>{totalFilters}</div>
          More Filters
          <FontAwesomeIcon className="ml-4" icon={faAngleDown} size="1x" />
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className="main-dropdown">
          <Row className="align-items-end">{props.children}</Row>
          <Row className="save-filters-button justify-content-end mt-6">
            <Col xs={4}>
              <Button className="clear-filters" variant={'outline-light'} onClick={handleClearFilters}>
                Clear All Filters
              </Button>
            </Col>
          </Row>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default GroupedFilters;
