import { s3SignedUrlFields } from 'gql/s3SignedUrl/fields';

export const timeEntryFields = `
  id
  sessionId
  contractId
  date
  timeIn
  timeOut
  timeInById
  timeOutById
  timeInBy {
    id
    firstname
    lastname
    email
    avatar {
      ${s3SignedUrlFields}
    }
    nickname
  }
  timeOutBy {
    id
    firstname
    lastname
    email
    avatar {
      ${s3SignedUrlFields}
    }
    nickname
  }
`;

export const absenceFields = `
  id
  sessionId
  contractId
  date
  type
  reason
  reportedBy {
    id
    firstname
    lastname
    email
    avatar {
      ${s3SignedUrlFields}
    }
    nickname
  }
  waiveGapFee
`;

export const sessionCostFields = `
  total
  discount
  earlyFee
  lateFee
  gap
  sessionCost
`;

export const sessionSubsidyFields = `
  amount
  error
  hours
  reason
`;

export const sessionFields = `
  id
  contractId
  accountId
  childId
  accountChildId
  centerId
  classId
  feeId
  dropOffTime
  pickUpTime
  date
  child {
    id
    firstname
    lastname
    nickname
    grade
    avatar {
      url
    }
    dob
  }
  class {
    id
    name
  }
  fee {
    id
    name
  }
  timeEntries {
    ${timeEntryFields}
  }
  totalTime {
    hours
    minutes
  }
  absence {
    ${absenceFields}
  }
  void
  cost {
    ${sessionCostFields}
  }
  subsidy {
    ${sessionSubsidyFields}
  }
  hasEarlyFee
  hasLateFee
  feeStartTime
  feeEndTime
  metadata {
    isWritable
  }
`;

export const removeSessionFields = `
  id
  contractId
  date
  void
`;

export const attendanceOpenSpotsFields = `
  centerId
  totalOpenSpots
  ratioCompliantOpenSpots
  byClass {
    classId
    date
    numberOfSessions
    totalOpenSpots
    ratioCompliantOpenSpots
    numberOfTeachersScheduled
    utilization
    maxCapacityForDate
    fte
    capacity
    closure {
      id
      startDate
      endDate
      closureResolution
    }
  }
`;

export const sessionFieldsWithEnrollmentYearAbsences = `
  id
  contractId
  accountId
  childId
  accountChildId
  accountChild {
    id
    archivedAt
  }
  centerId
  classId
  feeId
  dropOffTime
  pickUpTime
  date
  accountName
  child {
    id
    firstname
    lastname
    nickname
    grade
    avatar {
      url
    }
    dob
    }
  class {
    id
    name
  }
  fee {
    id
    name
    feeType
  }
  timeEntries {
    ${timeEntryFields}
  }
  totalTime {
    hours
    minutes
  }
  absence {
    ${absenceFields}
  }
  void
  # This method retrieves all absences for an account and child for an entire year. For each expected session (!!)
  # So there is a somewhat cartesian explosion of data returned, but we can mitigate it by asking just for "id."
  # We don't even want that - all we do is count the number of absences a child has in a given enrollment year.
  existingEnrollmentYearAbsences(enrollmentYearStart: $enrollmentYearStart, enrollmentYearEnd: $enrollmentYearEnd) {
    id
  }
  cost {
    ${sessionCostFields}
  }
  subsidy {
    ${sessionSubsidyFields}
  }
  hasEarlyFee
  hasLateFee
  feeStartTime
  feeEndTime
  charged
  reasonForLateChange
  metadata {
    isWritable
  }
  qualifyingRate {
    value
  }
  billingCycle {
    frequency
  }
  source
`;

export const childAbsenceFields = `
  id
  sessionId
  contractId
  date
  accountChildId
`;

export const sessionFieldsForStaffAttendance = `
  id
  classId
  date
  feeStartTime
  feeEndTime
  fee {
   utilization
  }
  absence {
    ${absenceFields}
  }
`;

export const sessionFieldsForChildDropDown = `
  accountChildId
  child {
    id
    firstname
    lastname
    nickname
    grade
    avatar {
      url
    }
  }
`;

export const sessionFieldsMinimal = `
  id
  contractId
  accountId
  childId
  accountChildId
  accountChild {
    id
    archivedAt
  }
  centerId
  classId
  feeId
  dropOffTime
  pickUpTime
  date
  accountName
  child {
    id
    firstname
    lastname
    nickname
    grade
    avatar {
      url
    }
    dob
    }
  class {
    id
    name
  }  
  timeEntries {
    ${timeEntryFields}
  }
  totalTime {
    hours
    minutes
  }
  absence {
    ${absenceFields}
  }
  void
  hasEarlyFee
  hasLateFee
  feeStartTime
  feeEndTime
  metadata {
    isWritable
  }
`;

export const classError = `
classId
error
`;

export const editBulkFeeFields = `
  updatedSessions {
    ${sessionFields}
  }
  errors
  {
     ${classError}
  }
`;
