import React, { useState } from 'react';
//components
import { IconButtonCircle } from 'shared/components/Buttons';
import TableSearch from 'shared/components/DataTable/TableSearch';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import DataTable, { SizePerPage, TableHeader } from 'shared/components/DataTable';
import { ActionsColumnCWA, CwaCards, ExpandCwaHistory, SelectedItemsHeader } from './index';
//utils
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetComplyingWrittenArrangementPage } from '../graphql/queries';
import {
  getNullableVariable,
  getStatusCWA,
  statusOptionsForCWA,
  statusAccountOptionsForCwa,
  statusOptionsForCWATable,
} from '../common';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { capitalize } from 'shared/util/string';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { RootState } from '../../../../store/reducers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
//styles
import '../CwaTAB.scss';

interface IProps {}
interface Sorting {
  field: string;
  direction: string;
}

export const CwaTable: React.FC<IProps> = () => {
  const { t } = useTranslation(['billing', 'subsidies', 'translation']);
  const entityId = useSelector((state: RootState) => state?.user?.entityId) ?? '';
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();
  const { k2CwaActions } = useFlags();

  const [searchValue, setSearchValue] = useState<string>('');
  const [centerIds, setCenterIds] = useState<string[]>([]);
  const [filteredStatus, setFilteredStatus] = useState<string[]>([]);
  const [tableState, tableFunctions] = useDatatableState();
  const [tableExpandRowState, tableExpandRowFunctions] = useDatatableState();
  const [sort, setSort] = useState<Sorting>({
    field: '',
    direction: '',
  });
  const [filteredAccountStatus, setFilteredAccountStatus] = useState<string[]>(['Active']);

  const CWAPageInput = {
    input: {
      businessId: entityId,
      centerIds,
      pageNumber: tableState.activePage,
      pageSize: tableState.pageSize,
      sortDtos: sort.field && sort.direction ? sort : [],
      searchText: searchValue,
      statuses: filteredStatus,
      accountStatuses: filteredAccountStatus,
    },
  };

  const { loading: loading, data: tableData, refetch } = useGetComplyingWrittenArrangementPage(CWAPageInput);

  const DataForCWATable =
    tableData?.getComplyingWrittenArrangementPage.data.map((i) => {
      const childName = `${i.childFirstName} ${i.childLastName}`;
      const updatedBy = i.updatedByPerson ? `${i.updatedByPerson.firstname} ${i.updatedByPerson.lastname}` : '-';
      const sentTo = i.guardianFirstName ? `${i.guardianFirstName} ${i.guardianLastName}` : '-';
      return {
        id: i.id,
        accountId: i.accountId,
        accountName: i.accountName,
        centerId: i.centerId,
        centerName: i.centerName,
        childFirstName: childName,
        childId: i.childId,
        enrolmentId: i.enrolmentId,
        status: getStatusCWA(i.status),
        updatedAt: getNullableVariable(i.updatedAt, timezone),
        updatedBy: i.updatedBy,
        updatedByPerson: {
          id: i.updatedByPerson ? i.updatedByPerson.id : '',
          updatedByFullName: updatedBy,
        },
        sentDate: getNullableVariable(i.sentDate, timezone),
        guardianFirstName: sentTo,
      };
    }) ?? [];

  const handleFilteredStatus = (value: string[]) => {
    setFilteredStatus(value);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  const handleFilteredAccountStatus = (value: string[]) => {
    setFilteredAccountStatus(value);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  const onSelect = (value: string) => {
    !filteredStatus?.find((f) => f === value)
      ? // @ts-ignore
        setFilteredStatus((prevState) => [...prevState, value])
      : setFilteredStatus(filteredStatus.filter((f) => f !== value));
  };

  const removeAllSelectedRows = () => {
    tableExpandRowFunctions.updateSelectedRows([]);
  };

  return (
    <>
      <CwaCards
        handleFilteredStatus={handleFilteredStatus}
        entityId={entityId}
        onSelect={onSelect}
        selectedStatuses={filteredStatus}
      />
      <DataTable
        activePage={tableState ? tableState.activePage : 1}
        pageSize={tableState ? tableState.pageSize : 25}
        onPageChange={tableFunctions.changePage}
        keyField="id"
        dataSize={tableData?.getComplyingWrittenArrangementPage.totalRecords ?? 0}
        data={DataForCWATable ?? []}
        showSelect={true}
        selectedRows={tableExpandRowState.selectedRows}
        updateSelectedRows={tableExpandRowFunctions.updateSelectedRows}
        showPagination={!loading}
        onSort={(field, direction) => setSort({ field, direction: direction })}
        rowClasses={(row: IMedicalCondition) => (row.archivedAt ? 'kt-datatable-inactive-row' : '')}
        defaultSorting="asc"
        renderHeader={(paginationProps: any) =>
          tableExpandRowState.selectedRows.length === 0 ? (
            <>
              <TableHeader className="px-1 mt-2 flex-wrap justify-content-between">
                <div className="d-flex">
                  <div className={'d-flex align-items-center mr-4'}>
                    <SizePerPage paginationProps={paginationProps} />
                  </div>
                  <TableSearch
                    placeholder={capitalize(t('subsidies:cwa.filter-name.search'))}
                    onChange={(i) => {
                      setSearchValue(i);
                      return searchValue;
                    }}
                    className="mr-4 my-2"
                  />
                  <DropdownFilter
                    title={capitalize(t('subsidies:cwa.filter-name.status'))}
                    className="ml-2 my-2"
                    selectedFilters={filteredStatus}
                    options={statusOptionsForCWA}
                    onFilterSelect={(values) => {
                      handleFilteredStatus(values.map((value) => value.value));
                    }}
                  />
                  <>
                    <label className="d-flex ml-4 flex-nowrap my-2 align-items-center">
                      {capitalize(t('translation:spelling.accountStatusFilter'))}
                    </label>
                    <DropdownFilter
                      title={capitalize(t('translation:spelling.accountStatusFilter'))}
                      className="ml-2 my-2"
                      selectedFilters={filteredAccountStatus}
                      options={statusAccountOptionsForCwa}
                      onFilterSelect={(values) => handleFilteredAccountStatus(values.map((value) => value.value))}
                    />
                  </>
                </div>

                <div className="d-flex flex-direction-row align-items-center">
                  <MultipleCenterSelect
                    useNullForAllOption={true}
                    selectedCenterIds={centerIds}
                    onSelect={(ids) => setCenterIds(!ids?.length ? [] : ids)}
                    showLabel={false}
                    className="flex-grow-0 w-240px mb-0"
                  />
                  <IconButtonCircle
                    icon={faTimes}
                    onClick={() => {
                      setCenterIds([]);
                      setSearchValue('');
                    }}
                    tooltipDirection="bottom"
                    tooltipText={t('billing:transactions.batch-table.clear-filter-tool-tip')}
                    className="mx-4 my-2"
                  />
                </div>
              </TableHeader>
            </>
          ) : (
            <SelectedItemsHeader
              selectedRowItems={tableExpandRowState.selectedRows}
              removeAllSelectedRows={removeAllSelectedRows}
              entityId={entityId}
              refetch={refetch}
            />
          )
        }
        showLoadingOverlay={loading}
        columns={[
          {
            text: t('subsidies:cwa.table-headers.center'),
            dataField: 'centerName',
            sort: true,
            classes: 'md-column py-4',
          },
          {
            text: t('subsidies:cwa.table-headers.enrollment-id'),
            dataField: 'enrolmentId',
            sort: true,
            classes: 'md-column py-4',
          },
          {
            text: t('subsidies:cwa.table-headers.account'),
            dataField: 'accountName',
            sort: true,
            classes: 'md-column py-4',
          },
          {
            text: t('subsidies:cwa.table-headers.child-name'),
            dataField: 'childFirstName',
            sort: true,
            classes: 'md-column py-4',
          },
          {
            text: capitalize(t('subsidies:cwa.table-headers.sent-date')),
            dataField: 'sentDate',
            sort: true,
            classes: 'md-column py-4 text-center',
            headerClasses: 'text-center',
          },
          {
            text: capitalize(t('subsidies:cwa.table-headers.sent-to')),
            dataField: 'guardianFirstName',
            sort: true,
            classes: 'md-column py-4 text-center',
            headerClasses: 'text-center',
          },
          {
            text: capitalize(t('subsidies:cwa.table-headers.status')),
            dataField: 'status',
            sort: true,
            classes: 'md-column py-4',
            formatter: (status) => {
              const statusColor = statusOptionsForCWATable.find((i) => i.value === status);
              return <div className={`font-weight-bold text-${statusColor?.indicatorColor as string}`}>{status}</div>;
            },
          },
          k2CwaActions && {
            text: t('subsidies:cwa.table-headers.actions'),
            dataField: 'actions',
            sort: false,
            classes: 'kt-cwa-column-actions py-4',
            formatter: (id, item) => {
              return <ActionsColumnCWA entityId={entityId} data={item} refetch={refetch} />;
            },
          },
        ]}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        expandRow={(row) => <ExpandCwaHistory entityId={entityId} data={row} timezone={timezone} />}
        onlyOneExpanding={false}
      />
    </>
  );
};
