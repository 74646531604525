import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

interface IGetChildContactRelationshipPermissionsData {
  getChildContactRelationshipPermissions: IChildContactPermissionsObject[];
}

interface IGetChildContactRelationshipPermissionsVariables {}

export const GET_CHILD_CONTACT_PERMISSIONS = gql`
  query {
    getChildContactRelationshipPermissions {
      label
      value
    }
  }
`;

export const useGetChildContactPermissions = (
  options?: QueryHookOptions<
    IGetChildContactRelationshipPermissionsData,
    IGetChildContactRelationshipPermissionsVariables
  >
) =>
  useQuery<IGetChildContactRelationshipPermissionsData, IGetChildContactRelationshipPermissionsVariables>(
    GET_CHILD_CONTACT_PERMISSIONS,
    options
  );
