import React, { useMemo } from 'react';
import classnames from 'classnames';
import Button, { IconButtonCircle } from 'shared/components/Buttons';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import DayPicker from 'shared/components/DateInput/DayPicker';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import Select from 'shared/components/Select/Select';
import { ButtonGroup } from 'react-bootstrap';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import ActionDropdown from 'shared/components/ActionDropdown';
import { useTranslation } from 'react-i18next';
import { isRegion } from 'shared/util/region';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import Tag from 'shared/components/Tag/ColoredBackgroundTag';
import colors from '_colors.module.scss';
import { getCcssWeek } from 'shared/util/ccss';

interface IProps {
  classOptions: IClass[];
  classIds: string[] | null;
  setClassId: (id: string) => void;
  setClassIds: (id: string[]) => void;
  timeRange: 'DAY' | 'WEEK';
  setTimeRange: (tr: 'DAY' | 'WEEK') => void;
  timezone: string;
  date: string;
  setDate: (date: string) => void;
  view: 'TIMES' | 'FINANCE';
  setView: (v: 'TIMES' | 'FINANCE') => void;
  selectedClasses?: IClass[] | null;
  handleGapWaiverOpen: () => void;
  dateRangeForAttendance?: (date: moment.Moment) => boolean;
}

const AttendanceViewHeader: React.FC<IProps> = ({
  classOptions,
  classIds,
  setClassIds,
  setClassId,
  timeRange,
  setTimeRange,
  timezone,
  date,
  setDate,
  view,
  setView,
  selectedClasses,
  handleGapWaiverOpen,
  dateRangeForAttendance,
  ...props
}) => {
  const { t } = useTranslation();
  const isAuRegion: boolean = isRegion('AU');

  const dateSettings = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;

  const hasViewFinancePermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const classFilterOptions = classOptions.map((c) => ({ ...c, value: c.id, label: c.name }));
  const ccssWeek = useMemo(() => getCcssWeek(date, timezone), [date, timezone]);
  return (
    <div className="bg-white p-4 d-flex flex-wrap justify-content-between border-bottom">
      {/* // no sense in showing a single button that does nothing if they don't have the finance permission, setting a visibility class instead of conditionally rendering so the header remains centered */}
      <ButtonGroup>
        <Button
          className={classnames({ invisible: !hasViewFinancePermission })}
          onClick={() => setView('TIMES')}
          variant={view === 'TIMES' ? 'secondary' : 'light'}
        >
          Times
        </Button>
        <Button
          disabled={!hasViewFinancePermission}
          className={classnames({ invisible: !hasViewFinancePermission })}
          onClick={() => setView('FINANCE')}
          variant={view === 'FINANCE' ? 'secondary' : 'light'}
        >
          Finance
        </Button>
      </ButtonGroup>
      <div className="d-flex align-items-center">
        {timeRange === 'DAY' ? (
          <DayPicker
            className="flex-grow-0 mb-0 mr-2"
            displayFormat="ddd MMM D"
            date={moment(date).tz(timezone)}
            onChange={(date) => {
              setDate(date.format());
            }}
            isOutsideRange={(date: moment.Moment) => (dateRangeForAttendance ? dateRangeForAttendance(date) : false)}
          />
        ) : (
          <WeekPicker
            className="flex-grow-0 mb-0 mr-2"
            displayFormat="MMM D"
            startDate={moment(date).tz(timezone).day(dateSettings.weekDayStart)}
            endDate={moment(date).tz(timezone).day(dateSettings.weekDayEnd)}
            rangeComponent={<span className="mx-1">-</span>}
            onChange={(dates) => setDate(dates.startDate.format())}
            reactDatesController="RANGE"
            isOutsideRange={(date: moment.Moment) => (dateRangeForAttendance ? dateRangeForAttendance(date) : false)}
          />
        )}
        <Select
          className="mb-0 flex-grow-0"
          options={[
            { value: 'DAY', label: 'Day' },
            { value: 'WEEK', label: 'Week' },
          ]}
          value={timeRange}
          onChange={(option) => setTimeRange(option.value)}
        />
        {isAuRegion && ccssWeek && (
          <div className="ml-2">
            <Tag text={`CCS WEEK ${ccssWeek}`} color={colors.dark}></Tag>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center">
        <DropdownFilter
          className="mb-0 max-width-200 flex-wrap"
          options={classFilterOptions}
          title={'All Classes'}
          onFilterSelect={(options) => {
            setClassIds(options?.map((c) => c.value));
          }}
          selectedFilters={classFilterOptions?.filter((c) => classIds?.includes(c.id))}
        />
        {isAuRegion && (
          <div className="kt-schedules-timeframe-control-actions">
            <ActionDropdown
              actions={[
                {
                  label: t('gap-waiver.dropdown-action-label'),
                  onClick: handleGapWaiverOpen,
                },
              ]}
              customIconButton={
                <IconButtonCircle
                  className="rounded-circle circle-md bg-pale-grey ml-2"
                  icon={faEllipsisV}
                  iconSize="2x"
                  backgroundColor="transparent"
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceViewHeader;
