import React from 'react';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';
import { IApplication } from 'shared/types/application';

interface IProps {
  selectedApplications: IApplication[];
  openBulkApproveModal: () => void;
}

const BulkActions: React.FC<IProps> = ({ selectedApplications, openBulkApproveModal }) => {
  const { t } = useTranslation();
  const text =
    selectedApplications.length === 1
      ? t('enrollment.lead-management.approve')
      : t('enrollment.lead-management.bulk-approve');
  return (
    <div className="bg-white p-4 d-flex border-bottom" style={{ height: '82px' }}>
      <IconButtonCircle icon={faCheck} className="mr-2" tooltipText={text} onClick={openBulkApproveModal} />
    </div>
  );
};

export default BulkActions;
