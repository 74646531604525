import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { healthTermFields } from './fields';

interface IGetHealthTermsData {
  getHealthTerms: IHealthTerm[];
}

interface IGetHealthTermsVariables {}

export const GET_HEALTH_TERMS = (fields: string = healthTermFields) => gql`
  query {
    getHealthTerms {
      ${fields}
    }
  }
`;

export const useGetHealthTerms = (
  options?: QueryHookOptions<IGetHealthTermsData, IGetHealthTermsVariables>,
  fields?: string
) =>
  useQuery<IGetHealthTermsData, IGetHealthTermsVariables>(GET_HEALTH_TERMS(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
