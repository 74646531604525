import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import momentTz from 'moment-timezone';
import RBCard from 'react-bootstrap/Card';
import TimeSheetDailyAttendance, { ITimeEntriesById } from './TimeSheetDailyAttendance';

interface IProps {
  canPerformEdit?: boolean;
  savingTimeEntryUpdates?: boolean;
  attendance: IAttendance;
  staffPositions?: IStaffPosition[];
  saveTimeEntryUpdates?: (updates: ITimeEntriesById) => Promise<void>;
}

const WeekTimeSheetCard: React.FC<IProps> = ({
  canPerformEdit = false,
  savingTimeEntryUpdates = false,
  attendance,
  staffPositions = [],
  saveTimeEntryUpdates,
  ...props
}) => {
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);

  const getWeekSpan = useCallback(
    (attendance: IAttendance): string => {
      if (attendance.days.length) {
        const timezone = timezonesByCenter[attendance.centerId] ?? momentTz.tz.guess();
        const startOfWeek = momentTz(attendance.days[0].date).tz(timezone).startOf('week');
        const endOfWeek = momentTz(attendance.days[0].date).tz(timezone).endOf('week');

        return `${startOfWeek.format('MMMM D')} - ${endOfWeek.format('MMMM D')}`;
      }

      return '';
    },
    [timezonesByCenter]
  );

  const formatAttendanceTime = useCallback((time: ITotalTime): number => {
    return (time.hours * 60 + time.minutes) / 60;
  }, []);

  return (
    <RBCard className="mx-0 mt-0 mb-4">
      <RBCard.Header className="d-flex flex-row align-items-center">
        <div className="mr-auto">{attendance.center.name}</div>
        <div>{getWeekSpan(attendance)}</div>
      </RBCard.Header>
      <RBCard.Body className="px-8 py-4">
        {attendance.days.map((dailyAttendance: IDailyAttendance, idx: number) => (
          <TimeSheetDailyAttendance
            key={`daily-attendance-time-sheet-${attendance.centerId}-${attendance.personId}-${dailyAttendance.date}-${idx}`}
            dailyAttendance={dailyAttendance}
            canPerformEdit={canPerformEdit}
            saveTimeEntryUpdates={saveTimeEntryUpdates}
            positionOptions={staffPositions}
            savingTimeEntryUpdates={savingTimeEntryUpdates}
          />
        ))}
        <div className="d-flex flex-row align-items-center justify-center flex-wrap">
          <b className="mr-auto">Totals:</b>
          <div className="d-flex flex-row flex-grow-1 justify-content-md-end">
            <div>
              Scheduled Time: <b>{formatAttendanceTime(attendance.totalHoursScheduled).toFixed(2)}</b>
            </div>
            <div className="ml-8">
              Actual Time: <b>{formatAttendanceTime(attendance.totalHoursWorked).toFixed(2)}</b>
            </div>
          </div>
        </div>
      </RBCard.Body>
    </RBCard>
  );
};

export default WeekTimeSheetCard;
