import React, { useState } from 'react';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';

import DataTable, { TableSearch } from 'shared/components/DataTable';
import { TableHeader } from 'shared/components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import { ISCaseStatusFilters, ISCaseStatusLabelEnum, ISCaseTypeLabelEnum } from 'shared/constants/enums/isCaseEnums';
import colors from '_colors.module.scss';
import moment from 'moment';
import ISInnovativeSolutionsDetailsRow from '../ISInnovativeSolutionsDetailsRow';

interface ITableProps {
  data: IISInnovativeCasesForCenters[];
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  centers: ICenter[];
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  activeFilters: IISCaseFilter;
  onActiveFilterChange: (value: IISCaseFilter) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
}

const ISInnovativeSolutionsTable: React.FC<ITableProps> = ({
  data,
  loading,
  dataSize,
  pageSize,
  activePage,
  centers,
  onPageChange,
  onSizePerPageChange,
  activeFilters,
  onActiveFilterChange,
  onSort,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const handleCenterChange = (values: ITableFilterOption[]) => {
    onActiveFilterChange({ ...activeFilters, centers: values });
  };

  const handleStatusChange = (values: ITableFilterOption[]) => {
    onActiveFilterChange({ ...activeFilters, statuses: values });
  };

  const handleSearch = (value: string) => {
    onActiveFilterChange({ ...activeFilters, searchText: value });
  };

  const onExpand = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    const expanded = isExpand ? [row.id] : [];
    setExpandedRows(expanded);
  };

  return (
    <>
      <TableHeader>
        <div className="d-flex flex-grow-1 my-2">
          <TableSearch onChange={handleSearch} placeholder="Search by IS case Id or child" />
        </div>
        <DropdownFilter
          dropdownId="statusFilter"
          title="Status"
          placeholder="Status"
          options={ISCaseStatusFilters}
          onFilterSelect={(option: any) => handleStatusChange(option)}
          className="mr-4 my-2"
          selectedFilters={activeFilters.statuses || []}
        />

        <DropdownFilter
          dropdownId="centerFilter"
          title="Center"
          placeholder="Center"
          options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
          onFilterSelect={handleCenterChange}
          className="mr-4 my-2"
          selectedFilters={activeFilters.centers || []}
        />
      </TableHeader>
      <SpinnerTableOverlay show={loading}>
        <DataTable
          keyField="id"
          noPadding={true}
          data={data}
          dataSize={dataSize}
          handleRowClick={() => {}}
          hasOverlay={false}
          pageSize={pageSize}
          activePage={activePage}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          showPagination={true}
          showSelect={false}
          className="ccs-table"
          expandHeaderColumnRenderer={(props) => <></>}
          onExpand={onExpand}
          expanded={expandedRows}
          expandRow={(row: IISInnovativeCasesForCenters) => <ISInnovativeSolutionsDetailsRow data={row} />}
          onSort={onSort}
          defaultSortField="created"
          defaultSorting="desc"
          onlyOneExpanding={true}
          columns={[
            {
              text: 'Center',
              dataField: 'centerName',
              sort: true,
            },
            {
              text: 'Case Status',
              dataField: 'status',
              sort: true,
              formatter: (cell: ISCaseStatus) => (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon className="mr-2" icon={faCircle} style={{ fontSize: 10 }} color={colors.success} />
                  {ISCaseStatusLabelEnum[cell]}
                </div>
              ),
            },
            {
              text: 'IS Case ID',
              dataField: 'caseId',
              sort: true,
            },
            {
              text: 'IS Case Type',
              dataField: 'type',
              sort: true,
              formatter: (cell: ISInnovativeCaseType) => (
                <div className="d-flex align-items-center">{ISCaseTypeLabelEnum[cell]}</div>
              ),
            },
            {
              text: 'Approved Funding Amount',
              align: 'center',
              headerClasses: 'text-center',
              dataField: 'approvedFundingAmount',
            },
            {
              text: 'IS Case Start Date',
              dataField: 'startDate',
              align: 'center',
              headerClasses: 'text-center',
              sort: true,
              formatter: (cell: string) => moment(cell).format('DD/MM/YYYY'),
            },
            {
              text: 'IS Case End Date',
              dataField: 'endDate',
              align: 'center',
              headerClasses: 'text-center',
              sort: true,
              formatter: (cell: string) => moment(cell).format('DD/MM/YYYY'),
            },
          ]}
        />
      </SpinnerTableOverlay>
    </>
  );
};

export default ISInnovativeSolutionsTable;
