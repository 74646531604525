import React, { useState } from 'react';
import moment from 'moment';

import { TableHeader } from 'shared/components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import CCSDebtRow from '../CCSDebtRow';
import DataTable from 'shared/components/DataTable';
import Select from 'shared/components/Select';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';
import colors from '_colors.module.scss';

import { CcsDebtColors, CcsDebtStatusLabelEnum } from 'shared/constants/enums/ccssDebtEnums';

interface ITableProps {
  data: ICcsDebt[];
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  providers: IProvider[];
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  activeFilters: ICcsDebtFilter;
  onActiveFilterChange: (value: ICcsDebtFilter) => void;
  handleMakeArrangement: () => void;
}

const CCSDebtsTable: React.FC<ITableProps> = ({
  data,
  loading,
  dataSize,
  pageSize,
  activePage,
  activeFilters,
  providers,
  onActiveFilterChange,
  onPageChange,
  onSizePerPageChange,
  handleMakeArrangement,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const onExpand = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    const expanded = isExpand ? [row.debtId] : [];
    setExpandedRows(expanded);
  };

  const handleProviderChange = (provider: ITableFilterOption) => {
    onActiveFilterChange({ ...activeFilters, providerId: provider.value });
  };

  return (
    <>
      <TableHeader>
        <div className="d-flex my-2">
          <Select
            placeholder="Provider"
            options={providers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
            onChange={handleProviderChange}
            className="mr-4 my-2"
            value={activeFilters.providerId}
          />
        </div>
      </TableHeader>
      <SpinnerTableOverlay show={loading}>
        <DataTable
          keyField="debtId"
          noPadding={true}
          data={data}
          dataSize={dataSize}
          handleRowClick={() => {}}
          hasOverlay={false}
          pageSize={pageSize}
          activePage={activePage}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          showPagination={false}
          showSelect={false}
          className="ccs-table"
          expandHeaderColumnRenderer={(props) => <></>}
          onExpand={onExpand}
          expanded={expandedRows}
          expandRow={(row: ICcsDebt) => <CCSDebtRow data={row} handleMakeArrangement={handleMakeArrangement} />}
          defaultSortField="created"
          defaultSorting="desc"
          onlyOneExpanding={true}
          columns={[
            {
              text: 'Provider ID',
              dataField: 'ccssProviderId',
            },
            {
              text: 'Provider Name',
              dataField: 'providerName',
            },
            {
              text: 'Debt ID',
              dataField: 'debtId',
            },
            {
              text: 'Issued Date',
              dataField: 'issuedDate',
              align: 'center',
              headerClasses: 'text-center',
              formatter: (cell: string) => (cell ? moment(cell).format('DD/MM/YYYY') : ''),
            },
            {
              text: 'Due Date',
              dataField: 'dueDate',
              align: 'center',
              headerClasses: 'text-center',
              formatter: (cell: string) => moment(cell).format('DD/MM/YYYY'),
            },
            {
              text: 'Status',
              align: 'center',
              headerClasses: 'text-center',
              dataField: 'status',
              formatter: (cell: CcsDebtStatus) => (
                <span>
                  <FontAwesomeIcon
                    className="mr-2 payment-status-circle"
                    icon={faCircle}
                    color={CcsDebtColors[cell]?.color || colors.gray}
                    fontSize="8px"
                  />
                  {CcsDebtStatusLabelEnum[cell]}
                </span>
              ),
            },
            {
              text: 'Outstanding Amount',
              dataField: 'outstandingBalance',
              headerClasses: 'text-right pr-6 w-80',
              align: 'right',
              formatter: (cell: number) => (cell ? <span className="pr-6">${cell.toFixed(2)}</span> : ''),
            },
          ]}
        />
      </SpinnerTableOverlay>
    </>
  );
};

export default CCSDebtsTable;
