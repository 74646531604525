import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from 'store/reducers';
import { Row } from 'shared/components/Layout';
import Alert from 'shared/components/Alert';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import Switch from 'shared/components/Switch';
import { useGetBillingCycleInvoiceDate, useGetBillingCycleTemplates } from 'gql/billingCycle/queries';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { setAccountBillingCycle } from 'pages/Families/subroutes/Accounts/duck/actions';
import { isDateRangeInvalid } from 'shared/util/timeUtils';
import { useCreateAccountBillingCycle, useUpdateAccountBillingCycle } from 'gql/billingCycle/mutations';
import { showToast } from 'shared/components/Toast';
import Tooltip from 'shared/components/Tooltip';
import useFormatDate from 'shared/hooks/useFormatDate';
import BillingCycleSentence from 'pages/Billing/Settings/Tabs/Cycles/BillingCycleSentence';
import ConfirmPausePaymentModal from './ConfirmPausePaymentModal';
import Card from 'shared/components/Card';
import errors from 'shared/constants/errorMessages';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import PeriodBasedBillingCycleSentence from 'pages/Billing/Settings/Tabs/Cycles/PeriodBasedBillingCycleSentence';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isRegion } from 'shared/util/region';

interface IProps {
  account: IAccount;
  accountId: string;
  loading: boolean;
  accountDataErrors: boolean;
}

const BillingInformationCard: React.FC<IProps> = ({ account, accountId, loading, accountDataErrors }) => {
  const { k2BillingStartDate, k2GetInvoiceDate: enforceFurtureInvoiceDate } = useFlags();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatDate = useFormatDate();

  const [createBillingCycleFn, { loading: createBillingCycleLoading }] = useCreateAccountBillingCycle({
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });
  const [updateBillingCycleFn, { loading: updateBillingCycleLoading }] = useUpdateAccountBillingCycle({
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const savedBillingCycle = account?.billingCycle;

  const newBillingCycle = useMemo(
    () => ({ accountId, isPaused: false, startDate: moment().format('YYYY-MM-DD') }),
    [accountId]
  );

  const hasEditAccountBillingCyclePermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.AccountBillingCycles,
    level: RoleLevelType.Edit,
  });
  const hasCreateAccountBillingCyclePermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.AccountBillingCycles,
    level: RoleLevelType.Create,
  });
  const hasEditBillingCycleTemplatePermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.BillingCycleTemplates,
    level: RoleLevelType.Edit,
  });
  const canEditCycle = savedBillingCycle
    ? hasEditAccountBillingCyclePermissions
    : hasCreateAccountBillingCyclePermissions;
  const canEditCycleTemplate = canEditCycle && hasEditBillingCycleTemplatePermissions;

  const allTemplates: IBillingCycleTemplate[] = useSelector(
    (state: RootState) => Object.values(state.billing.settings.templates.byId) ?? []
  );
  const templates = useMemo(() => {
    return allTemplates.filter(
      (t) =>
        !t.deactivatedAt && (t?.centerId === account?.centerId || (!t.centerId && t.businessId === account?.entityId))
    );
  }, [account, allTemplates]);

  const { loading: templatesLoading } = useGetBillingCycleTemplates({ fetchPolicy: 'network-only' });

  const [billingCycle, updateBillingCycle] = useState<IBillingCycleFormData>(
    savedBillingCycle ? { ...savedBillingCycle } : newBillingCycle
  );
  const [isFormDirty, setFormDirty] = useState(false);
  const [isConfirmPausePaymentModalOpen, setConfirmPausePaymentModalOpen] = useState(false);

  const handleUpdateTemplate = useCallback((template: IBillingCycleTemplate) => {
    const { frequency, dayOfWeek, dayOfMonth, invoiceSchedule, period, additionalChargePeriod, cycleType, id } =
      template;
    updateBillingCycle((prev) => ({
      ...prev,
      frequency,
      dayOfWeek,
      dayOfMonth,
      invoiceSchedule,
      period,
      additionalChargePeriod: cycleType === 'PERIOD_BASED' ? { value: 0, unit: 'DAYS' } : additionalChargePeriod,
      cycleType,
      billingCycleTemplateId: id,
      billingPeriods: template.billingPeriods ?? [],
    }));
    setFormDirty(true);
  }, []);

  const handleUpdateCycle = useCallback((updates: Partial<IBillingCycleFormData>) => {
    updateBillingCycle((prev) => ({ ...prev, ...updates }));
    setFormDirty(true);
  }, []);

  const resetForm = useCallback(() => {
    updateBillingCycle(savedBillingCycle ?? newBillingCycle);
  }, [newBillingCycle, savedBillingCycle]);

  const submitForm = useCallback(() => {
    const cycle = billingCycle as IBillingCycle;

    const baseInput = {
      startDate: cycle.startDate,
      endDate: cycle.endDate ?? null,
      period: cycle.period,
      frequency: cycle.frequency,
      dayOfWeek: cycle.dayOfWeek ?? null,
      dayOfMonth: cycle.dayOfMonth ?? null,
      invoiceSchedule: {
        unit: cycle.invoiceSchedule?.unit,
        value: cycle.invoiceSchedule?.value,
      },
      additionalChargePeriod: {
        unit: cycle.additionalChargePeriod?.unit,
        value: cycle.additionalChargePeriod?.value,
      },
      transactionLimit: cycle.transactionLimit ?? null,
      isPaused: cycle.isPaused,
      billingCycleTemplateId: cycle.billingCycleTemplateId,
      billingPeriods: cycle.billingPeriods,
    };
    // perform create/update mutation depending if cycle has an id
    // dispatch result to redux via setAccountBillingCycle
    if (!savedBillingCycle || !savedBillingCycle.id) {
      createBillingCycleFn({
        variables: {
          input: {
            accountId: cycle.accountId,
            ...baseInput,
          },
        },
      }).then((res) => {
        if (res?.data?.createAccountBillingCycle) {
          dispatch(setAccountBillingCycle(res.data.createAccountBillingCycle));
          showToast('Successfully set billing cycle.', 'success');
          setFormDirty(false);
        }
      });
    } else {
      updateBillingCycleFn({
        variables: {
          input: {
            id: savedBillingCycle.id,
            ...baseInput,
          },
        },
      }).then((res) => {
        if (res?.data?.updateAccountBillingCycle) {
          dispatch(setAccountBillingCycle(res.data.updateAccountBillingCycle));
          showToast('Successfully updated billing cycle.', 'success');
          setFormDirty(false);
        }
      });
    }
  }, [billingCycle, createBillingCycleFn, updateBillingCycleFn, dispatch]);

  const handleCancel = useCallback(() => {
    setFormDirty(false);
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    resetForm();
  }, [resetForm, savedBillingCycle]);

  useEffect(() => {
    if (billingCycle.period === 'PREVIOUS_WEEK') {
      updateBillingCycle((prev) => ({ ...prev, additionalChargePeriod: { value: 0, unit: 'WEEKS' } }));
    }
  }, [billingCycle.period]);

  const isWeekly = billingCycle.frequency?.includes('WEEK');
  const invalidDateRange = isDateRangeInvalid(billingCycle.startDate, billingCycle.endDate);
  const existingCycle = !!(billingCycle as IBillingCycle).id;

  const isIncomplete =
    !billingCycle.accountId ||
    !billingCycle.startDate ||
    invalidDateRange ||
    !billingCycle.frequency ||
    (isWeekly ? !billingCycle.dayOfWeek : isNaN(billingCycle.dayOfMonth)) ||
    isNaN(billingCycle.invoiceSchedule?.value ?? NaN) ||
    !billingCycle.invoiceSchedule?.unit ||
    !billingCycle.period ||
    isNaN(billingCycle.additionalChargePeriod?.value ?? NaN) ||
    !billingCycle.additionalChargePeriod?.unit;

  const periodBasedBillingCycleSelected: boolean = billingCycle.cycleType === 'PERIOD_BASED';

  const { loading: invoiceDateLoading, data: invoiceDateData } = useGetBillingCycleInvoiceDate({
    skip: !enforceFurtureInvoiceDate || existingCycle || isIncomplete,
    variables: {
      message: {
        accountId: billingCycle.accountId!,
        startDate: billingCycle.startDate!,
        frequency: billingCycle.frequency!,
        period: billingCycle.period!,
        dayOfWeek: billingCycle.dayOfWeek!,
        dayOfMonth: billingCycle.dayOfMonth!,
        invoiceSchedule: {
          unit: billingCycle.invoiceSchedule?.unit ?? null,
          value: billingCycle.invoiceSchedule?.value ?? 0,
        },
        additionalChargePeriod: {
          unit: billingCycle.additionalChargePeriod?.unit ?? null,
          value: billingCycle.additionalChargePeriod?.value ?? 0,
        },
        transactionLimit: null,
        isPaused: false,
        billingCycleTemplateId:
          billingCycle.billingCycleTemplateId && periodBasedBillingCycleSelected
            ? billingCycle.billingCycleTemplateId
            : null,
      },
    },
  });

  let invoiceDateString = invoiceDateData?.billingCycleInvoiceDate;

  if (!enforceFurtureInvoiceDate || existingCycle) {
    invoiceDateString = '9999-12-31';
  }

  const futureInvoiceDate = invoiceDateString && moment(invoiceDateString) > moment();
  const isSaveDisabled = isIncomplete || invoiceDateLoading || !futureInvoiceDate;

  return (
    <div>
      <Card header="Billing Information" loading={loading}>
        <FormWrapper2
          formIsDirty={isFormDirty}
          toggleDirty={setFormDirty}
          onSave={() => {
            if (billingCycle.isPaused) {
              setConfirmPausePaymentModalOpen(true);
            } else {
              submitForm();
            }
          }}
          onCancel={handleCancel}
          loading={loading || createBillingCycleLoading || updateBillingCycleLoading}
          saveDisabled={isSaveDisabled || createBillingCycleLoading || updateBillingCycleLoading}
        >
          {!isIncomplete && !invoiceDateLoading && !futureInvoiceDate && (
            <Alert className="box-shadow-0 mb-6 mt-n4" variant="danger">
              This billing cycle does not start billing in the future, check the start date.
            </Alert>
          )}
          {(accountDataErrors || !account) && (
            <Alert className="box-shadow-0 mb-6 mt-n4" variant="danger">
              Something went wrong. Unable to retrieve this account's data.
            </Alert>
          )}
          {!accountDataErrors && account && !savedBillingCycle && (
            <Alert className="box-shadow-0 mb-6 mt-n4" variant="danger">
              This account does not have a billing cycle assigned.
            </Alert>
          )}
          {k2BillingStartDate && !accountDataErrors && account && !account?.center?.enableBillingCycleProcessing && (
            <Alert className="box-shadow-0 mb-6 mt-n4" variant="warning">
              {t('billing.account.billing-cycle.center-disabled-billing-cycle')}
            </Alert>
          )}
          {!accountDataErrors &&
            account &&
            account?.center?.enableBillingCycleProcessing &&
            savedBillingCycle?.isPaused && (
              <Alert className="box-shadow-0 mb-6 mt-n4" variant="warning">
                {t('billing.account.billing-cycle.itemized-billing-paused')}
              </Alert>
            )}
          <div className="mb-4">
            <Row noGutters>
              <DateInput
                required={billingCycle.cycleType !== 'PERIOD_BASED'}
                label={
                  billingCycle.cycleType === 'PERIOD_BASED'
                    ? t('billing.account.billing-cycle.period-based-start-date-input-label')
                    : t('billing.account.billing-cycle.balance-based-start-date-input-label')
                }
                date={billingCycle.startDate}
                onDateSelect={(startDate) => handleUpdateCycle({ startDate })}
                disabled={!canEditCycle || periodBasedBillingCycleSelected}
                className="mr-4 mb-0"
              />
              <DateInput
                label={
                  billingCycle.cycleType === 'PERIOD_BASED'
                    ? t('billing.account.billing-cycle.period-based-end-date-input-label')
                    : t('billing.account.billing-cycle.balance-based-end-date-input-label')
                }
                date={billingCycle.endDate}
                onDateSelect={(endDate) => handleUpdateCycle({ endDate })}
                disabled={!canEditCycle || periodBasedBillingCycleSelected}
                className="mb-0"
              />
            </Row>
            {periodBasedBillingCycleSelected && (
              <small>
                * For period based billing cycles the start date aligns with the first active contract on an account and
                the end date aligns with the last active contract.
              </small>
            )}
          </div>
          {invalidDateRange && <div className="text-danger sm mt-n2 mb-4">End date must be after start date.</div>}
          <Select
            label="Billing Cycle Template"
            placeholder="Select the template you want to use"
            options={templates}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            value={templates.find((t) => t.id === billingCycle.billingCycleTemplateId) ?? null}
            onChange={handleUpdateTemplate}
            isLoading={templatesLoading || loading}
            required={!canEditCycleTemplate}
            disabled={!canEditCycle}
            className="mb-6"
          />
          <h6 className="mb-4">What does this mean for you... </h6>
          {isRegion('US') && (
            <PeriodBasedBillingCycleSentence
              readOnly
              cycle={billingCycle}
              onUpdate={() => {}}
              showNextCycleInformation={false}
            />
          )}
          {isRegion('AU') && (
            <BillingCycleSentence
              readOnly={!!billingCycle.billingCycleTemplateId || !canEditCycle}
              cycle={billingCycle}
              onUpdate={(frequencyUpdates) => handleUpdateCycle({ billingCycleTemplateId: null, ...frequencyUpdates })}
              fieldsRequired={canEditCycleTemplate}
            />
          )}
          <Row noGutters align="end" justify="between">
            <div>
              <Tooltip text="Individual transaction limit, processed to an account." direction="top">
                <CurrencyInput2
                  small
                  label="Transaction Limit"
                  value={billingCycle.transactionLimit}
                  onChange={(transactionLimit) => handleUpdateCycle({ transactionLimit })}
                  className="border-radius-0 mb-4"
                  disabled={!canEditCycle}
                />
              </Tooltip>
            </div>
            <Tooltip
              text="Freeze payments collected from an account. Charges to balance will continue to accumulate."
              direction="top"
            >
              <div>
                <Switch
                  label="Pause Payments?"
                  labelSide="left"
                  value={billingCycle.isPaused}
                  onChange={(isPaused) => handleUpdateCycle({ isPaused })}
                  className="mb-4"
                  disabled={!canEditCycle}
                />
              </div>
            </Tooltip>
          </Row>
          {savedBillingCycle && !savedBillingCycle.isPaused && (
            <Alert className="box-shadow-0" variant="info">
              <div>
                <div>Next payment collected: {formatDate(savedBillingCycle.nextPaymentDate, 'MMM Do, YYYY')}.</div>
                <div>
                  Payments collected for the current balance + sessions during:{' '}
                  {formatDate(savedBillingCycle.nextBillingPeriod.start, 'MMM Do, YYYY')} -{' '}
                  {formatDate(savedBillingCycle.nextBillingPeriod.end, 'MMM Do, YYYY')}.
                </div>
              </div>
            </Alert>
          )}
          {isSaveDisabled && isFormDirty && <small className="ml-auto">{errors.formRequirements}</small>}
        </FormWrapper2>
      </Card>
      <ConfirmPausePaymentModal
        isOpen={isConfirmPausePaymentModalOpen}
        onClose={() => {
          setConfirmPausePaymentModalOpen(false);
        }}
        onConfirm={submitForm}
      />
    </div>
  );
};

export default BillingInformationCard;
