import React from 'react';
import { EventProps } from 'react-big-calendar';
import { Circle } from 'shared/components/Shapes';
import { getFullName } from 'shared/util/string';
import colors from '_colors.module.scss';

const TimeOffCalendarEvent: React.FC<EventProps<ITimeOff>> = ({ event }) => {
  return (
    <div className="d-flex align-items-center">
      <Circle className="mr-1" size="xxs" background={event.person.classAssignments[0]?.colorCode || colors.gray} />
      <div className="time-off-name mr-6">{getFullName(event.person)}</div>
      <div className="xxs">{event.allDay ? 'All Day' : 'Partial Day'}</div>
    </div>
  );
};

export default TimeOffCalendarEvent;
