import { useGetCenter } from 'gql/center/queries';
import { getCenterSuccess } from 'pages/Centers/duck/actions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'shared/components/Alert';
import AvatarUpload from 'shared/components/AvatarUpload';
import Card from 'shared/components/Card';
import { RootState } from 'store/reducers';
import { GET_CENTER_PROFILE } from '../../graphql/fields';
import { useUpdateCenterLogo } from '../../graphql/mutations';
import './_statementTab.scss';

interface IProps {
  center?: ICenter;
}

const StatementTab: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const centerId = !!props.center ? props.center?.id : '';
  const { loading, error } = useGetCenter(
    {
      variables: {
        id: centerId,
      },
      onCompleted: (data) => {
        dispatch(getCenterSuccess(data.getCenter));
      },
    },
    GET_CENTER_PROFILE
  );
  const reduxCenter: ICenter | null = useSelector((state: RootState) => state.centers.byId)[centerId] ?? null;
  const center = !loading && reduxCenter ? reduxCenter : undefined;
  // @ts-ignore
  const [updateLogo, { loading: logoLoading }] = useUpdateCenterLogo();
  const [invalidImageSize, setInvalidImageSize] = useState(false);

  const validImageSize = (image: HTMLImageElement) => image.width <= 300 && image.height <= 75;

  const onDrop = (file: File) => {
    setInvalidImageSize(false);
    const image = new Image();
    image.onload = () => {
      if (validImageSize(image)) {
        // @ts-ignore
        updateLogo({ id: center.id, avatar: file });
      } else {
        setInvalidImageSize(true);
      }
    };

    image.src = URL.createObjectURL(file);
  };

  return (
    <Card header="Statement Logo">
      {invalidImageSize && (
        <Alert variant="danger">Image should have a max width of 300px and max height of 75px.</Alert>
      )}
      <div className="statement-tab__center-logo__container">
        <AvatarUpload
          className="statement-tab__center-logo"
          loading={logoLoading}
          image={center && center.logo && center.logo.url}
          // @ts-ignore
          onFileSelect={(file) => onDrop(file)}
          placeholder="Upload"
          imageResizeWidth={300}
          imageResizeHeight={75}
        />
      </div>
    </Card>
  );
};

export default StatementTab;
