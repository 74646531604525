const ccssAttributes = `
  ccssAttributes {
    crn
  }
`;

const childFields = `
child {
  nickname
  firstname
  lastname
  fullName
  id
  dob
  ${ccssAttributes}
  avatar {
    url
  }
}
`;

const enrolmentSubFields = `
  id
  enrolmentId
  childId
  category
  occurrenceNumber
  contactId
  contactOfChild {
    firstname
    lastname
    dob
    ccssAttributes {
      crn
    }
  }
  arrangementStartDate
  arrangementEndDate
  endDate
  recordEffectiveEndDate
  lateSubmissionReason
  arrangementType
  status
  sessionIndicator
  childCrn
  childCrn
  childDateOfBirth
  childFirstName
  childLastName
  individualCrn
  individualDateOfBirth
  individualFirstName
  individualLastName
  signingPartyFirstName
  signingPartyLastName
  signingPartyOrganisation
  childInStateCare
  created
  accountId
  accountName
  centerId
  centerName
  reasonForPea
  ${childFields}
`;

export const enrolmentFields = `
  ${enrolmentSubFields}
  previousEnrolments {
    ${enrolmentSubFields}
  }
`;

export const ccsEnrolmentPagedResultFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${enrolmentFields}
  }
`;

export const ccsEnrolmentCategoryCountResultFields = `
  Pending
  Active
  ActionRequired
  Inactive
`;

export const arrangementTypeFields = `
  enrolmentId
  childId
  accountId
  centerId
  category
  arrangementType
  status
  sessionIndicator    
`;
