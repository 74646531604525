import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import Select from 'shared/components/Select/Select';
import { faTimes, faPencilAlt, faTrashAlt, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFormatDate from 'shared/hooks/useFormatDate';
import { RootState } from 'store/reducers';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { setCurrentBusiness, setCurrentBusinessFilters, setCurrentCenterFilters } from 'store/context/actions';
import ActionDropdown from 'shared/components/ActionDropdown';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState2';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import Button from 'shared/components/Buttons';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { capitalize } from 'shared/util/string';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  data: IBusRoster[];
  loading: boolean;
  setSearchTerm: (str: string) => void;
  centers: ICenter[] | undefined;
  pageSize: number;
  activePage: number;
  totalResults: number;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  downloadLoading: boolean;
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  onDelete: (busRosterId: string) => void;
  onDownload: (busRoster: IBusRoster) => void;
}

const BusRosterTable: React.FC<IProps> = ({
  data,
  pageSize,
  activePage,
  setSearchTerm,
  centers,
  loading,
  totalResults,
  tableState,
  tableFunctions,
  downloadLoading,
  onSort,
  onPageChange,
  onSizePerPageChange,
  onDelete,
  onDownload,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user);
  const isInternalUser = currentUser?.isInternal;
  const { centerFilterIds, businessId } = useSelector((state: RootState) => state.context);
  const { data: businessesData } = useGetAllowedEntities(!isInternalUser);
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const hasViewBusRosterPermission = useHasRoleAreaLevel({
    area: AreaType.Attendance,
    permission: PermissionType.BusRoster,
    level: RoleLevelType.Read,
  });
  const hasEditBusRosterPermission = useHasRoleAreaLevel({
    area: AreaType.Attendance,
    permission: PermissionType.BusRoster,
    level: RoleLevelType.Edit,
  });
  const hasDeleteBusRosterPermission = useHasRoleAreaLevel({
    area: AreaType.Attendance,
    permission: PermissionType.BusRoster,
    level: RoleLevelType.Delete,
  });

  useEffect(() => {
    if (businesses.length > 0 && businessId === '') dispatch(setCurrentBusiness(businesses[0].id));
  }, [businesses, businessId, dispatch]);

  const clearAppliedFilters = useCallback(() => {
    dispatch(setCurrentCenterFilters([]));
    dispatch(setCurrentBusinessFilters([]));
  }, [dispatch]);

  const handleBusinessSelect = useCallback(
    (option: ITableFilterOption) => {
      dispatch(setCurrentBusiness(option.value));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const handleCenterFilterSelect = useCallback(
    (options: ITableFilterOption[]) => {
      dispatch(setCurrentCenterFilters(options.map((c) => c.value)));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const getDropdownOptionsForBusRosters = useCallback(
    (row: IBusRoster): IDropdownAction[] => {
      const actions: { label: string; onClick: () => void; icon: IconDefinition }[] = [];

      hasEditBusRosterPermission &&
        actions.push({
          label: 'Edit',
          onClick: () => {
            history.push(`/bus-roster/edit/${row.id}`);
          },
          icon: faPencilAlt,
        });
      hasDeleteBusRosterPermission &&
        actions.push({
          label: 'Delete',
          onClick: () => {
            onDelete(row.id);
          },
          icon: faTrashAlt,
        });

      return actions;
    },
    [hasEditBusRosterPermission, hasDeleteBusRosterPermission]
  );

  const getTableColumns = useCallback((): any[] => {
    const tableColumns: any = [
      {
        dataField: 'name',
        text: t('bus-roster.bus-roster-table.name-column'),
        sort: true,
      },
      {
        dataField: 'center.name',
        text: `${t('spelling.capitalCenter')}`,
        sort: true,
      },
      {
        dataField: 'destinations',
        text: t('bus-roster.bus-roster-table.destinations-column'),
        sort: true,
        formatter: (cell: any, row: IBusRoster) =>
          cell
            .reduce((previous: any, currentValue: any) => [...previous, currentValue.name], [])
            .sort()
            .join(', '),
      },
      {
        dataField: 'effectiveDate',
        text: t('bus-roster.bus-roster-table.start-end-date-column'),
        sort: true,
        formatter: (cell: any, row: IBusRoster) =>
          `${formatDate(row.effectiveDate, 'MM/DD/YYYY')} - ${formatDate(row.endDate, 'MM/DD/YYYY')}`,
      },
      {
        dataField: 'type',
        text: t('bus-roster.bus-roster-table.bus-runs-column'),
        sort: true,
        formatter: (cell: any, row: IBusRoster) => (cell?.toUpperCase() === 'BOTH' ? 'AM and PM' : cell),
      },
    ];

    if (hasViewBusRosterPermission) {
      tableColumns.push({
        text: '',
        dataField: 'downloadPDF',
        align: 'center',
        headerAlign: 'center',
        formatter: (cell: any, row: IBusRoster) => (
          <div className="d-flex justify-content-center">
            <Button onClick={() => onDownload(row)} loading={downloadLoading} variant="secondary">
              Download PDF
            </Button>
          </div>
        ),
      });
    }

    if (hasEditBusRosterPermission || hasDeleteBusRosterPermission) {
      tableColumns.push({
        text: '',
        dataField: 'actions',
        align: 'center',
        headerAlign: 'center',
        formatter: (cell: any, row: IBusRoster) => (
          <div className="d-flex justify-content-center">
            <ActionDropdown actions={getDropdownOptionsForBusRosters(row)} />
          </div>
        ),
      });
    }

    return tableColumns;
  }, [t, hasViewBusRosterPermission, formatDate, history]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <DataTable
      data={data}
      columns={getTableColumns()}
      dataSize={totalResults}
      pageSize={pageSize}
      activePage={activePage}
      showLoadingOverlay={loading}
      showSelect={false}
      onSort={(field, direction) => onSort(field, direction)}
      onPageChange={onPageChange}
      showPagination={true}
      onSizePerPageChange={onSizePerPageChange}
      renderHeader={(paginationProps, searchProps) => (
        <TableHeader className="flex-wrap align-items-center">
          <div className="d-flex flex-wrap align-items-center mr-auto">
            <SizePerPage paginationProps={paginationProps} className={isMobile ? 'mr-4 mb-1' : ''} />
            <TableSearch placeholder="Search" onChange={setSearchTerm} className={isMobile ? 'mr-4 mt-2 mb-1' : ''} />
          </div>
          <div className="d-flex align-items-center flex-wrap">
            {centers && centers.length > 1 && (
              <div className="d-flex align-items-center flex-wrap">
                {currentUser?.isInternal && (
                  <Select
                    title={capitalize(t('spelling.business'))}
                    className={isMobile ? 'mr-4 my-1' : 'mb-0 mr-4 pb-0'}
                    options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                    value={businessId}
                    name="business"
                    onChange={handleBusinessSelect}
                  />
                )}
                <DropdownFilter
                  title={fieldLabels.center}
                  className={isMobile ? 'mt-1 mb-2 mr-4' : 'mr-4'}
                  selectedFilters={centerFilterIds}
                  options={
                    centers
                      ?.filter((c) => c.entityId === businessId)
                      .map((c) => ({ label: c.name, value: c.id }))
                      .sort((a, b) => a.label.localeCompare(b.label)) ?? []
                  }
                  onFilterSelect={handleCenterFilterSelect}
                />
              </div>
            )}
            <IconButtonCircle
              icon={faTimes}
              onClick={clearAppliedFilters}
              tooltipDirection="bottom"
              tooltipText="Clear Filters"
            />
          </div>
        </TableHeader>
      )}
    ></DataTable>
  );
};

export default BusRosterTable;
