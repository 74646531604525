import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { adjustmentFields } from 'gql/adjustment/fields';

interface ICreateAdjustmentData {
  createAdjustment: IAdjustment;
}

interface ICreateAdjustmentVariables {
  input: ICreateAdjustmentInput;
}

interface IUpdateAdjustmentData {
  updateAdjustment: IAdjustment;
}

interface IUpdateAdjustmentVariables {
  input: IUpdateAdjustmentInput;
}

interface IArchiveAdjustmentData {
  archiveAdjustment: IAdjustment;
}

interface IArchiveAdjustmentVariables {
  id: string;
}

export const CREATE_ADJUSTMENT = (fields: string = adjustmentFields) => gql`
  mutation ($input: CreateAdjustmentInput!) {
    createAdjustment(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_ADJUSTMENT = (fields: string = adjustmentFields) => gql`
  mutation ($input: UpdateAdjustmentInput!) {
    updateAdjustment(input: $input) {
      ${fields}
    }
  }
`;

export const ARCHIVE_ADJUSTMENT = (fields: string = adjustmentFields) => gql`
  mutation ($id: ID!) {
    archiveAdjustment(id: $id) {
      ${fields}
    }
  }
`;

export const useCreateAdjustment = (
  options?: MutationHookOptions<ICreateAdjustmentData, ICreateAdjustmentVariables>,
  fields?: string
) => useMutation<ICreateAdjustmentData, ICreateAdjustmentVariables>(CREATE_ADJUSTMENT(fields), options);

export const useUpdateAdjustment = (
  options?: MutationHookOptions<IUpdateAdjustmentData, IUpdateAdjustmentVariables>,
  fields?: string
) => useMutation<IUpdateAdjustmentData, IUpdateAdjustmentVariables>(UPDATE_ADJUSTMENT(fields), options);

export const useArchiveAdjustment = (
  options?: MutationHookOptions<IArchiveAdjustmentData, IArchiveAdjustmentVariables>,
  fields?: string
) => useMutation<IArchiveAdjustmentData, IArchiveAdjustmentVariables>(ARCHIVE_ADJUSTMENT(fields), options);
