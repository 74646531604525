import {
  ClassesActionTypes,
  GET_CENTER_CLASSES_SUCCESS,
  UPDATE_CLASS,
  ADD_CLASS,
  ARCHIVE_CLASS,
  ADD_CLASS_FEE,
} from './types';

interface IClassesStateShape {
  all: IClass[];
  byId: Record<string, IClass>;
}

const initialState: IClassesStateShape = {
  all: [],
  byId: {},
};

export const classesReducers = (
  state: IClassesStateShape = initialState,
  action: ClassesActionTypes
): IClassesStateShape => {
  const byId: Record<string, IClass> = { ...state.byId };

  switch (action.type) {
    case GET_CENTER_CLASSES_SUCCESS:
      return {
        ...state,
        all: action.classes,
        byId: action.classes.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
      };
    case ADD_CLASS:
    case UPDATE_CLASS:
      byId[action.class.id] = action.class;

      return {
        ...state,
        all: Object.values(byId),
        byId,
      };
    case ARCHIVE_CLASS:
      delete byId[action.id];

      return {
        ...state,
        all: Object.values(byId),
        byId,
      };
    case ADD_CLASS_FEE:
      byId[action.classId] = {
        ...byId[action.classId],
        fees: byId[action.classId].fees ? [...byId[action.classId].fees, action.fee] : [action.fee],
      };
      return {
        ...state,
        all: Object.values(byId),
        byId,
      };
    default:
      return state;
  }
};
