import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UpdateEnrollmentProgramGroupInput } from 'generated/graphql';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { useUpdateEnrollmentProgramGroupMutation } from 'generated/graphql';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IEditEnrollmentProgramGroup {
  loading: boolean;
  onSubmit: () => {};
  onFieldUpdate: (a: any, b: any) => void;
  values: UpdateEnrollmentProgramGroupInput;
  setValues: Dispatch<SetStateAction<UpdateEnrollmentProgramGroupInput>>;
  businessTimeZone: Timezone;
}
const initialProgramGroupInput = {
  id: '',
  isActive: false,
  name: '',
  description: '',
  businessId: '',
  programIds: [],
};

export const useEditEnrollmentProgramGroup = ({ programGroup, tableProps, onCompleted }) => {
  const { businessId } = useSelector((state: RootState) => state.context);
  const allEntities = useSelector((state: RootState) => state.entities.allEntities);
  const businessTimeZone = allEntities[businessId ?? '']?.timezone || moment.tz.guess();
  const [values, setValues] = useState<UpdateEnrollmentProgramGroupInput>(initialProgramGroupInput);

  const [updateProgramGroup, { loading: updateLoading }] = useUpdateEnrollmentProgramGroupMutation({
    onCompleted,
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  useEffect(() => {
    if (tableProps?.tableState?.selectedRows) {
      setValues({ ...values, programIds: tableProps.tableState?.selectedRows?.map((s) => s.id) });
    }
  }, [tableProps?.tableState?.selectedRows]);

  useEffect(() => {
    if (programGroup) {
      const { name, description, programIds, isActive, businessId, id } = programGroup;
      setValues({ name, description, programIds, isActive, businessId, id });
      if (tableProps.data) {
        const selected = tableProps.data.filter((p) => programIds?.includes(p.id)) ?? [];
        tableProps?.tableFunctions.updateSelectedRows(selected);
      }
    } else setValues(initialProgramGroupInput);
  }, [programGroup]);

  const handleSubmitForm = async () => {
    await updateProgramGroup({
      variables: {
        input: values,
      },
    });
  };

  const onFieldUpdate = (value: any, name: any) => {
    setValues({
      ...values,
      [name]: value,
    });
  };
  return {
    loading: updateLoading,
    onSubmit: handleSubmitForm,
    onFieldUpdate,
    values,
    setValues,
    businessTimeZone,
  } as IEditEnrollmentProgramGroup;
};
