import * as types from './types';

export interface IDiscountsStateShape {
  all: IDiscount[];
  byId: Record<string, IDiscount>;
}

const initialState: IDiscountsStateShape = {
  all: [],
  byId: {},
};

export const discountReducers = (
  state: IDiscountsStateShape = initialState,
  action: types.DiscountActionTypes
): IDiscountsStateShape => {
  const byId: Record<string, IDiscount> = { ...state.byId };

  switch (action.type) {
    case types.GET_DISCOUNTS_SUCCESS:
      return {
        ...state,
        all: action.discounts,
        byId: action.discounts.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
      };
    case types.CREATE_DISCOUNT_SUCCESS:
    case types.UPDATE_DISCOUNT_SUCCESS:
      if (byId[action.discount.id]) {
        byId[action.discount.id] = {
          ...byId[action.discount.id],
          ...action.discount,
        };
      } else {
        byId[action.discount.id] = action.discount;
      }

      return {
        ...state,
        all: Object.values(byId),
        byId,
      };
    default:
      return state;
  }
};
