import React, { useMemo } from 'react';
import DataTable from 'shared/components/DataTable';

import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState2';
import { ITableFilters } from '../../DocumentsTab';
import DocumentsTableHeader from '../DocumentsTableHeader';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import colors from '_colors.module.scss';
import { capitalize, getFullName } from 'shared/util/string';
import useFormatDate from 'shared/hooks/useFormatDate';
import { useTranslation } from 'react-i18next';
import { getDocumentTypeLabel } from 'shared/constants/dropdownOptions/documentType';
import { downloadFile } from 'shared/util/downloadFile';
import { ButtonAsLink } from 'shared/components/Buttons';
import moment from 'moment';

interface IProps {
  persons: IPerson[];
  tableState: IDatatableState;
  tableFn: IStateControls;
  filters: ITableFilters;
  totalRecords: number;
  data: ICoreDocument[];
  loading: boolean;
  timezone: string;
  setFilters: React.Dispatch<React.SetStateAction<ITableFilters>>;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
}
const DocumentTable: React.FC<IProps> = ({
  persons,
  data,
  loading,
  tableState,
  tableFn,
  filters,
  timezone,
  totalRecords,
  setFilters,
  onSort,
}) => {
  const formatDate = useFormatDate();
  const { t } = useTranslation(['accounts', 'translation']);

  const personsById = useMemo(() => {
    const personsObject: Record<string, IPerson> = {};
    persons.forEach((person) => {
      personsObject[person.id] = person;
    });
    return personsObject;
  }, [persons]);

  return (
    <DataTable
      data={data}
      dataSize={totalRecords}
      noPadding
      showSelect={false}
      showLoadingOverlay={loading}
      activePage={tableState.activePage}
      pageSize={tableState.pageSize}
      onSizePerPageChange={tableFn.changeSizePerPage}
      onPageChange={tableFn.changePage}
      onSort={onSort}
      renderHeader={(paginationProps) => (
        <DocumentsTableHeader
          paginationProps={paginationProps}
          filters={filters}
          setFilters={setFilters}
          contacts={persons}
        />
      )}
      columns={[
        {
          text: capitalize(t('translation:spelling.name')),
          dataField: 'name',
          sort: true,
          formatter: (cell: string, row: ICoreDocument) => {
            return <ButtonAsLink onClick={() => downloadFile(row.link, row.name)}>{cell}</ButtonAsLink>;
          },
        },
        {
          text: capitalize(t('translation:spelling.type')),
          dataField: 'type',
          sort: true,
          formatter: (cell: CoreDocumentType) => getDocumentTypeLabel(cell),
        },
        {
          text: capitalize(t('translation:spelling.status')),
          dataField: 'archivedAt',
          sort: true,
          formatter: (archivedAt: string, document: ICoreDocument) => {
            if (Boolean(archivedAt))
              return <ColoredBackgroundTag text={capitalize(t('translation:spelling.archived'))} color={colors.gray} />;
            return document.expirationDate &&
              moment.tz(document.expirationDate, timezone).hours(0).isBefore(moment.tz(timezone)) ? (
              <ColoredBackgroundTag text={capitalize(t('translation:spelling.expired'))} color={colors.danger} />
            ) : (
              <ColoredBackgroundTag text={capitalize(t('translation:spelling.active'))} color={colors.success} />
            );
          },
        },
        {
          text: t('accounts:documents.associated-with'),
          dataField: 'associatedEntityId',
          sort: true,
          formatter: (personId: string) => {
            return personId && personsById[personId] ? getFullName(personsById[personId]) : '';
          },
        },
        {
          text: capitalize(t('accounts:documents.created')),
          dataField: 'createdAt',
          sort: true,
          formatter: (cell: string) => formatDate(cell, 'MMM D, YYYY'),
        },
        {
          text: capitalize(t('accounts:documents.expires')),
          dataField: 'expirationDate',
          sort: true,
          formatter: (date?: string) => (date ? formatDate(moment.tz(date, timezone), 'MMM D, YYYY') : ''),
        },
      ]}
    />
  );
};

export default DocumentTable;
