import React, { useState, useCallback, useEffect, createContext, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PageWrapper from 'shared/components/PageWrapper';
import Button, { CirclePlusButton } from 'shared/components/Buttons';
import ClassHelperInformation from './ClassHelperInformation';
import { useLazyGetClassById } from './graphql/queries';
import { useCreateNewClass, useUpdateClass } from './graphql/mutations';
import AddSpaceForClassModal from './AddSpaceForClassModal';
import { isBlank } from 'shared/util/string';
import { showToast } from 'shared/components/Toast';
import cast from 'shared/util/cast';
import { omitFalsy, omitTypename } from 'shared/util/object';
import { getClassById } from '../Classes/duck/selectors';
import { RootState } from 'store/reducers';
import { completeStep } from 'store/onBoarding/actions';
import { ONBOARDING_STEPS } from 'shared/constants/Onboarding';
import OverviewTab from './Tabs/Overview';
import FeesTab from './Tabs/Fees';
import CreateFeeModal from 'pages/Centers/subroutes/Fees/components/CreateFeeModal';
import ActivateFeeModal from 'shared/components/Fees/ActivateFeeModal';
import DeactivateFeeModal from 'shared/components/Fees/DeactivateFeeModal';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { sortBy } from 'lodash';
import { isRegion } from 'shared/util/region';
import PermissionActions from 'shared/constants/enums/actionEnum';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { isTimeRangeInvalid } from 'shared/util/timeUtils';

interface IFeeModalState {
  show: boolean;
  fee: IFee | null;
}

// NOTE: this is repeated in Regulations.tsx. when we have time to refactor this sohuld be moved elsewhere
const ageToNumberOfDays = (regulationAge: IRegulationAge): number => {
  const { age, unit } = regulationAge;

  switch (unit) {
    case 'YEAR':
      return age * 365;
    case 'MONTH':
      return age * 30;
    case 'WEEK':
      return age * 7;
    default:
      return age;
  }
};

// new class assumes the class will be 'ongoing' and doesn't have an end date
const NEW_CLASS = {
  name: '',
  startsAt: moment().toISOString(),
  startTime: null,
  endTime: null,
  defaultSpaceId: '',
  regulationId: '',
  colorCode: '',
  staffAssignments: [],
  defaultCasualFeeId: null,
  defaultPermanentFeeId: null,
  capacity: null,
  glCode: '',
};

interface IRouteParams {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

export interface IClassStateShape {
  id?: string;
  name: string;
  centerId: string;
  defaultSpaceId: string;
  startsAt: string;
  endsAt?: string;
  startTime?: string;
  endTime?: string;
  regulationId: string;
  regulationOverride?: IRegulationOverride;
  colorCode: string;
  staffAssignments: IStaff[];
  regulation?: IRegulation; // set via the regulation select
  archivedAt?: string;
  defaultCasualFeeId: string | null;
  defaultPermanentFeeId: string | null;
  careType?: string | null;
  capacity: number | null;
  isPreSchoolProgram?: boolean;
  glCode?: string | null;
}

export interface IClassPermissionContext {
  canRead: boolean;
  canWrite: boolean;
  canCreate: boolean;
}

export const ClassPermissionContext = createContext<IClassPermissionContext>({
  canCreate: false,
  canRead: false,
  canWrite: false,
});

interface IClassShape {
  classId: string;
  centerId: string;
  centerName: string;
}

const Class: React.FC<IProps> = ({ ...props }) => {
  const hasCreateClassPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Classes,
    level: RoleLevelType.Create,
  });
  const hasEditClassPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Classes,
    level: RoleLevelType.Edit,
  });

  const user = useSelector((state: RootState) => state.user);
  const { k2ClassStartEndTime } = useFlags();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<IClassShape>();
  const hasViewFeePermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Fees,
    level: RoleLevelType.Read,
  });
  const hasEditFeePermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Fees,
    level: RoleLevelType.Edit,
  });

  const classById = useSelector((state: RootState) => getClassById(state, props.match.params.id));
  const editingExistingClass: boolean = props.match.params.id !== 'new';
  const [_class, setClass] = useState<IClassStateShape>(
    cast<IClassStateShape>(classById) ?? {
      ...NEW_CLASS,
      centerId: location.state?.centerId ?? '',
    }
  );
  const [isCreateFeeOpen, setIsCreateFeeOpen] = useState(false);
  const [showAddSpaceModal, setShowSpaceModal] = useState(false);
  const [isPromptToSetDefaultFeeOpen, setPromptToSetDefaultFeeOpen] = useState(
    Boolean(classById?.fees.length > 1 && !classById?.defaultPermanentFeeId)
  );
  const [isPromptToSetDefaultFeeDismissed, setPromptToSetDefaultFeeDismissed] = useState(false);
  /**
   * this is used as part of a component's key value.
   * if we want to force a re-render of a component we can update this value
   */
  const [pageRenderedAt, setPageRenderedAt] = useState<number>(Date.now);

  const [activateFeeModalState, setActivateFeeModalState] = useState<IFeeModalState>({
    show: false,
    fee: null,
  });
  const [deactivateFeeModalState, setDeactivateFeeModalState] = useState<IFeeModalState>({
    show: false,
    fee: null,
  });

  const classPermission = useMemo<IClassPermissionContext>(() => {
    if (user) {
      return {
        canCreate: user.hasAreaPermissionLevel({
          area: AreaType.Center,
          permission: PermissionType.Classes,
          level: RoleLevelType.Create,
        }),
        canWrite: user.hasAreaPermissionLevel({
          area: AreaType.Center,
          permission: PermissionType.Classes,
          level: RoleLevelType.Edit,
        }),
        canRead: user.hasAreaPermissionLevel({
          area: AreaType.Center,
          permission: PermissionType.Classes,
          level: RoleLevelType.Read,
        }),
      };
    }

    return { canRead: false, canWrite: false, canCreate: false };
  }, [user]);

  const [getClassByIdFn] = useLazyGetClassById('');
  // per ADR doc, this will be implemented later.
  // const user = useSelector((state: RootState) => state.user);
  // const showHelperInfo = !localStorage.getItem(`${user?.email}-class-helper-closed`);
  const [createClassFn, { loading: createClassLoading }] = useCreateNewClass();
  const [updateClassFn, { loading: updatingClassLoading }] = useUpdateClass();

  // ON MOUNT if there is a class id in the react router state and not in the redux store dispatch a query
  useEffect(() => {
    // when clicking the add class button on the classes screen we go to a route of `/centers/classes/new` and 'new' is being passed in as the :id variable
    if (props.match.params.id && !classById && props.match.params.id !== 'new') {
      getClassByIdFn({
        variables: {
          id: props.match.params.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if this was previously undefined and now has a value (from the getClassByIdFn effect), update state
    if (classById) {
      setClass(cast<IClassStateShape>(classById));
      if (classById?.fees.length > 1 && !isPromptToSetDefaultFeeDismissed && !classById?.defaultPermanentFeeId) {
        setPromptToSetDefaultFeeOpen(true);
      }
    }
  }, [classById, isPromptToSetDefaultFeeDismissed]);

  const validClassForm = useCallback(() => {
    const validStartDate: boolean = !!moment(_class.startsAt).startOf('day').toISOString();
    // start date must be before end date if has any value
    const validStartEndTime = !Boolean(isTimeRangeInvalid(_class.startTime, _class.endTime));
    // if k2ClassStartEndTime is true, start time and end time are mandatory fields
    const startEndTimeNotNull = k2ClassStartEndTime ? Boolean(_class.startTime && _class.endTime) : true;
    const validTimeframe: boolean = _class.endsAt ? moment(_class.endsAt).isAfter(moment(_class.startsAt)) : true;
    const validRegulationOverride: boolean = _class.regulationOverride
      ? // @ts-ignore - making sure this is really a number
        Number.isInteger(parseInt(_class.regulationOverride.ratioTeachers, 10)) &&
        // @ts-ignore
        Number.isInteger(parseInt(_class.regulationOverride.ratioChildren, 10)) &&
        ageToNumberOfDays(cast<IRegulationAge>(_class.regulationOverride.endAge)) >=
          ageToNumberOfDays(cast<IRegulationAge>(_class.regulationOverride.startAge)) &&
        ageToNumberOfDays(cast<IRegulationAge>(_class.regulationOverride.oldestEndAge)) >=
          ageToNumberOfDays(cast<IRegulationAge>(_class.regulationOverride.youngestStartAge)) &&
        // need to ensure the youngest age is not greater than the regulation's start age and the oldest age is not smaller than the regulation's end age
        ageToNumberOfDays(cast<IRegulationAge>(_class.regulationOverride.youngestStartAge)) <=
          ageToNumberOfDays(cast<IRegulationAge>(_class.regulationOverride.startAge)) &&
        ageToNumberOfDays(cast<IRegulationAge>(_class.regulationOverride.oldestEndAge)) >=
          ageToNumberOfDays(cast<IRegulationAge>(_class.regulationOverride.endAge))
      : true;

    const validCareType: boolean = isRegion('AU') ? !!_class.careType : true;

    return (
      !isBlank(_class.name) &&
      !isBlank(_class.colorCode) &&
      !isBlank(_class.defaultSpaceId) &&
      !isBlank(_class.regulationId) &&
      validTimeframe &&
      validRegulationOverride &&
      validStartDate &&
      validCareType &&
      validStartEndTime &&
      startEndTimeNotNull
    );
  }, [_class, k2ClassStartEndTime]);

  const createClass = useCallback(
    (_class: IClassStateShape) => {
      const input: ICreateClassInput = {
        name: _class.name,
        centerId: _class.centerId,
        regulationId: _class.regulationId,
        defaultSpaceId: _class.defaultSpaceId,
        startsAt: moment(_class.startsAt).startOf('day').format(),
        staffAssignmentIds: _class.staffAssignments.map((staff: IStaff) => staff.id),
        colorCode: _class.colorCode.toUpperCase(),
        defaultCasualFeeId: _class.defaultCasualFeeId ?? null,
        defaultPermanentFeeId: _class.defaultPermanentFeeId ?? null,
        careType: _class.careType ?? null,
        capacity: _class.capacity as number,
        glCode: _class.glCode,
        startTime: _class.startTime,
        endTime: _class.endTime,
        isPreSchoolProgram: _class.isPreSchoolProgram,
      };

      if (_class.endsAt) {
        input.endsAt = _class.endsAt;
      }

      if (_class.regulationOverride) {
        input.regulationOverride = cast<IRegulationOverride>(omitFalsy(_class.regulationOverride));
      }

      createClassFn({
        variables: {
          input: cast<ICreateClassInput>(omitTypename(input, true)),
        },
      }).then((data) => {
        showToast('Class created successfully.', 'success');
        dispatch(completeStep(ONBOARDING_STEPS.setupClass));
        setPageRenderedAt(Date.now);
        history.replace('/centers/classes');
      });
    },
    [createClassFn, dispatch, history]
  );

  const updateClass = useCallback(
    (_class: IClassStateShape) => {
      if (_class.id) {
        const input: IUpdateClassInput = {
          id: _class.id,
          name: _class.name,
          centerId: _class.centerId,
          regulationId: _class.regulationId,
          defaultSpaceId: _class.defaultSpaceId,
          startsAt: moment(_class.startsAt).startOf('day').format(),
          staffAssignmentIds: _class.staffAssignments.map((staff: IStaff) => staff.id),
          colorCode: _class.colorCode.toUpperCase(),
          endsAt: _class.endsAt ?? '',
          startTime: _class.startTime,
          endTime: _class.endTime,
          defaultCasualFeeId: _class.defaultCasualFeeId ?? null,
          defaultPermanentFeeId: _class.defaultPermanentFeeId ?? null,
          careType: _class.careType ?? null,
          capacity: _class.capacity as number,
          isPreSchoolProgram: _class.isPreSchoolProgram,
          glCode: _class.glCode,
        };

        input.regulationOverride = _class.regulationOverride
          ? cast<IRegulationOverride>(omitFalsy(_class.regulationOverride))
          : null;
        updateClassFn({
          variables: {
            input: cast<IUpdateClassInput>(omitTypename(input, true)),
          },
        })
          .then(() => {
            showToast('Class updated successfully.', 'success');
            history.replace('/centers/classes');
          })
          .catch((error) => {
            showToast(
              `${error.graphQLErrors
                .map((err: any) => {
                  return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
                })
                .join(', ')}`,
              'error'
            );
          });
      }
    },
    [updateClassFn]
  );

  const displayDeactivateFeeModal = useCallback((fee: IFee) => {
    setActivateFeeModalState({ show: false, fee: null });
    setDeactivateFeeModalState({ show: true, fee });
  }, []);

  const displayActivateFeeModal = useCallback((fee: IFee) => {
    setDeactivateFeeModalState({ show: false, fee: null });
    setActivateFeeModalState({ show: true, fee });
  }, []);

  return (
    <ClassPermissionContext.Provider value={classPermission}>
      <PageWrapper
        pageTitle={`${location.state?.centerName ? `${location.state.centerName} Class` : `${_class?.name ?? ''}`}`}
        mobileButtonComponent={
          (hasCreateClassPermission || hasEditClassPermission) && (
            <CirclePlusButton
              disabled={!validClassForm() || Boolean(_class.archivedAt)}
              variant="primary"
              className="mt-4 mb-4"
              onClick={() => {
                editingExistingClass ? updateClass(_class) : createClass(_class);
              }}
            />
          )
        }
        buttonComponent={
          (hasCreateClassPermission || hasEditClassPermission) && (
            <Button
              loading={createClassLoading || updatingClassLoading}
              disabled={!validClassForm() || Boolean(_class.archivedAt)}
              onClick={() => {
                editingExistingClass ? updateClass(_class) : createClass(_class);
              }}
            >
              Save
            </Button>
          )
        }
        secondaryButtonComponent={
          (hasCreateClassPermission || hasEditClassPermission) && (
            <Button variant="light" className="mr-4" onClick={() => history.goBack()}>
              Cancel
            </Button>
          )
        }
      >
        <Container fluid>
          {/* {showHelperInfo && */}
          <Row>
            <Column>
              <ClassHelperInformation />
            </Column>
          </Row>
          <Tabs defaultActiveKey="overview" id="kt-class-tab-group">
            <Tab eventKey="overview" title="Class">
              <OverviewTab
                /**
                 * we're adding a key with a timestamp as part of it to allow us to force re-render the component if necessary.
                 * this was added with AB#2182 due to inputs still retaining their validation borders after successful saves.
                 */
                key={`class-overview-tab-${pageRenderedAt}`}
                classId={_class.id ?? ''}
                centerId={location.state?.centerId ?? _class.centerId}
                classData={_class}
                updateClass={setClass}
                openNewSpaceModal={() => setShowSpaceModal(true)}
                openNewFeeModal={() => setIsCreateFeeOpen(true)}
                // @ts-ignore
                feeOptions={sortBy(
                  // @ts-ignore
                  (_class.fees ?? []).filter((f: IFee) => !f.deactivatedAt),
                  'name'
                )}
              />
            </Tab>
            {_class.id && hasViewFeePermission && (
              <Tab eventKey="fees" title="Fees">
                <FeesTab
                  classId={_class.id}
                  centerId={_class.centerId}
                  openNewFeeModal={() => setIsCreateFeeOpen(true)}
                  handleDeactivateFee={displayDeactivateFeeModal}
                  handleReactivateFee={displayActivateFeeModal}
                  isReadOnly={Boolean(_class.archivedAt) || !hasEditFeePermission}
                />
              </Tab>
            )}
          </Tabs>
        </Container>
        <AddSpaceForClassModal
          isOpen={showAddSpaceModal}
          onClose={() => setShowSpaceModal(false)}
          centerId={_class.centerId}
        />
        {_class.id && (
          <CreateFeeModal
            classId={_class.id}
            centerId={_class.centerId}
            isOpen={isCreateFeeOpen}
            onClose={() => setIsCreateFeeOpen(false)}
          />
        )}
        {activateFeeModalState.fee && (
          <ActivateFeeModal
            isOpen={activateFeeModalState.show}
            fee={activateFeeModalState.fee}
            onClose={() => setActivateFeeModalState({ show: false, fee: null })}
          />
        )}
        {deactivateFeeModalState.fee && (
          <DeactivateFeeModal
            isOpen={deactivateFeeModalState.show}
            fee={deactivateFeeModalState.fee}
            onClose={() => setDeactivateFeeModalState({ show: false, fee: null })}
          />
        )}
        <ConfirmationModal
          title="No Default Fee"
          show={isPromptToSetDefaultFeeOpen}
          onHide={() => {
            setPromptToSetDefaultFeeOpen(false);
            setPromptToSetDefaultFeeDismissed(true);
          }}
          primaryChoice={'Ok'}
          primaryCallback={() => {
            setPromptToSetDefaultFeeOpen(false);
            setPromptToSetDefaultFeeDismissed(true);
          }}
          noSecondaryChoice
        >
          No default fee has been set for this class.
        </ConfirmationModal>
      </PageWrapper>
    </ClassPermissionContext.Provider>
  );
};

export default Class;
