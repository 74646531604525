import React from 'react';
import CreateISCaseClaimForm from 'pages/ISCases/components/CreateISCaseClaimForm';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { useSearchStaff } from 'pages/Employees/subroutes/Profiles/graphql/queries';
import { active, pending } from 'shared/constants/StaffStatusSearchExpressions';
import ElasticConstants from 'shared/constants/elastic';
import './iSCaseCreateModal.scss';

interface IProps {
  isOpen: boolean;
  businessId: string;
  iSCase: IISCase | null;
  loading: boolean;
  onSubmit: (data: ISCaseClaimCreateInput) => void;
  onClose: () => void;
}

const ISCasesClaimsCreateModal: React.FC<IProps> = ({ iSCase, isOpen, loading, onSubmit, onClose, businessId }) => {
  const elasticScopeValues = [businessId];
  const { data: educators } = useSearchStaff({
    filter: {
      [ElasticConstants.SEARCH_EXPRESSIONS.ALL]: [
        {
          [ElasticConstants.SEARCH_EXPRESSIONS.ANY]: [active, pending],
        },
        {
          [ElasticConstants.SEARCH_EXPRESSIONS.ANY]: [
            {
              [ElasticConstants.SEARCH_EXPRESSIONS.TERM]: {
                field: 'entityId.keyword',
                predicate: ElasticConstants.PREDICATES.ONE_OF,
                value: elasticScopeValues,
              },
            },
          ],
        },
      ],
    },
    sort: [
      {
        field: 'lastname.keyword',
        direction: ElasticConstants.DIRECTIONS.ASCENDING,
      },
    ],
    size: 100,
    from: 0,
  });
  if (!iSCase) return null;
  return (
    <CenteredModal
      dialogClassName="iscase-modal"
      show={isOpen}
      hasFooter={false}
      onHide={onClose}
      size="xl"
      title="Create IS Case Claim"
      primaryChoice="Submit"
    >
      <CreateISCaseClaimForm
        iSCase={iSCase}
        educators={educators?.searchStaff.data || []}
        handleCreateClaim={onSubmit}
        handleClose={onClose}
        loading={loading}
        businessId={businessId}
      />
    </CenteredModal>
  );
};

export default ISCasesClaimsCreateModal;
