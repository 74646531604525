import { useState } from 'react';
import PageWrapper from 'shared/components/PageWrapper';
import Button, { CreateButton } from 'shared/components/Buttons';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import AddProgramModalForm from './AddProgramModalForm/AddProgramModalForm';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import ProgramsTable from './ProgamsTable';
import { useGetPrograms, useGetSubsidyProgramsCount } from 'gql/program/queries';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetClassesForCenter } from 'gql/class/queries';
import RunProgramReportModal from './RunProgramReportModal/RunProgramReportModal';
import { Tab, Tabs } from 'react-bootstrap';
import KindyForAllCredentialsTab from './Tabs/KindyForAllCredentialsTab';
import { RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import KindyForAllCenterAgreementsTab from './Tabs/KindyForAllCenterAgreementsTab';
import RunStartStrongReportModal from './RunStartStrongReportModal/RunStartStrongReportModal';
import { freeKindySubsidySchemeId, kindyForAllSubsidySchemeId } from 'pages/Subsidies/KindyFunding/utils';
import { useGetKindySubsidySchemesQuery } from 'generated/graphql';

type Props = {};
type ProgramsTabs = 'programs' | 'settings';
type ProgramsSettingsTabs = 'ratings' | 'center-agreements' | 'credentials';

export default function Programs({}: Props) {
  const { t } = useTranslation(['translation', 'subsidies']);
  const [searchText, setSearchText] = useState('');
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const [tableState, tableFunctions] = useDatatableState();

  const [statusFilter, setStatusFilter] = useState<string | undefined | null>();
  const [classIds, setClassIds] = useState<string[] | undefined>();
  const [subsidySchemeId, setSubsidySchemeId] = useState<string | undefined | null>();
  const [sort, setSort] = useState<IElasticsearchSortFilter[]>([{ field: 'name', direction: 'ASCENDING' }]);

  const onSort = (field: string, direction: ElasticsearchSortDirection) => setSort([{ field, direction }]);

  const [isAddProgramModalFormOpen, setAddProgramModalForm] = useState(false);
  const [isRunReportModalFormOpen, setIsRunReportModalForm] = useState(false);
  const [isRunStartStrongReportModalOpen, setIsRunStartStrongReportModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<ProgramsTabs>('programs');
  const [activeSubTab, setActiveSubTab] = useState<ProgramsSettingsTabs>('credentials');
  const selectedCenterId: string = useSelector((state: RootState) => state.context.centerId) ?? '';
  const businessId: string = useSelector((state: RootState) => state.context.businessId) ?? '';

  const { data: subsidySchemesData } = useGetKindySubsidySchemesQuery();

  const { data, loading, refetch } = useGetPrograms({
    variables: {
      input: {
        businessId,
        centerIds: !!currentCenterId ? [currentCenterId] : [],
        classIds,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        searchText: searchText === '' ? undefined : searchText,
        sortDtos: sort,
        subsidySchemeId: subsidySchemeId ?? undefined,
        isActive: isEmpty(statusFilter) ? undefined : statusFilter === 'Active',
      },
    },
    skip: currentCenterId === null,
  });

  const { data: qldKindyPrograms } = useGetSubsidyProgramsCount({
    variables: {
      input: {
        centerIds: [],
        subsidySchemeIds: [kindyForAllSubsidySchemeId, freeKindySubsidySchemeId],
      },
    },
    skip: !businessId,
  });

  const { data: classes } = useGetClassesForCenter({
    variables: { centerId: selectedCenterId },
    fetchPolicy: 'network-only',
  });

  const clearFilters = () => {
    setSubsidySchemeId(null);
    setStatusFilter(null);
    setClassIds([]);
  };

  const getAvailableSubsidySchemes = () => {
    const subsidySchemes = subsidySchemesData?.getKindySubsidySchemes || [];
    return subsidySchemes;
  };

  const classesOptions: IClass[] = classes?.getClassesForCenter.sort((a, b) => a.name.localeCompare(b.name)) || [];

  return (
    <PageWrapper
      applyPadding={true}
      // @ts-ignore
      pageTitle={t('subsidies:programs')}
      buttonComponent={
        activeTab === 'programs' ? (
          <>
            <Button
              variant="secondary"
              className="mr-2"
              onClick={() => setIsRunStartStrongReportModalOpen(true)}
              permission={{ permission: 'Base', level: RoleLevelType.Create, area: 'Agency' } as IAreaPermissionLevel}
            >
              {t('subsidies:run-report')}
            </Button>
            <CreateButton
              onClick={() => setAddProgramModalForm(true)}
              permission={{ permission: 'Base', level: RoleLevelType.Create, area: 'Agency' } as IAreaPermissionLevel}
            >
              {t('subsidies:add-program')}
            </CreateButton>
          </>
        ) : (
          <></>
        )
      }
    >
      <Tabs id="center-programs-tab-group" activeKey={activeTab} onSelect={(tab) => setActiveTab(tab as ProgramsTabs)}>
        <Tab eventKey="programs" title="Programs">
          <CenterSelectBanner pageName="program" />

          <RunProgramReportModal
            isOpen={isRunReportModalFormOpen}
            centerId={selectedCenterId}
            businessId={businessId}
            subsidySchemes={getAvailableSubsidySchemes()}
            onClose={() => setIsRunReportModalForm(false)}
            refetchPrograms={refetch}
          />

          <RunStartStrongReportModal
            isOpen={isRunStartStrongReportModalOpen}
            onClose={() => setIsRunStartStrongReportModalOpen(false)}
          />

          <AddProgramModalForm
            isOpen={isAddProgramModalFormOpen}
            centerId={selectedCenterId}
            businessId={businessId}
            subsidySchemes={getAvailableSubsidySchemes()}
            onClose={() => setAddProgramModalForm(false)}
            refetchPrograms={refetch}
          />

          <ProgramsTable
            data={data?.getProgramsByCenterId.data || []}
            dataSize={data?.getProgramsByCenterId.totalRecords || 0}
            statusFilter={statusFilter}
            pageSize={tableState.pageSize}
            activePage={tableState.activePage}
            onPageChange={tableFunctions.changePage}
            onSizePerPageChange={tableFunctions.changeSizePerPage}
            loading={loading}
            onClassesSelect={setClassIds}
            onSort={onSort}
            onSearch={setSearchText}
            clearFilters={clearFilters}
            onStatusChange={setStatusFilter}
            selectedClasses={classIds}
            classes={classesOptions}
            subsidySchemes={subsidySchemesData?.getKindySubsidySchemes || []}
            selectedSubsidyScheme={subsidySchemeId}
            onSchemeSelect={setSubsidySchemeId}
            refetchPrograms={refetch}
          />
        </Tab>
        {(qldKindyPrograms?.getSubsidySchemeProgramsCountByCenterId?.count ?? 0) > 0 && (
          <Tab eventKey="settings" title="QLD Settings">
            <Tabs
              id="center-programs-settings-tab-group"
              activeKey={activeSubTab}
              onSelect={(tab) => setActiveSubTab(tab as ProgramsSettingsTabs)}
            >
              {/* <Tab eventKey="ratings" title="Subsidy Centre Ratings">
                <SubsityRatingsTab />
              </Tab> */}
              <Tab eventKey="center-agreements" title="Kindy Funding Numbers">
                <KindyForAllCenterAgreementsTab />
              </Tab>
              <Tab eventKey="credentials" title="Api Keys">
                <KindyForAllCredentialsTab />
              </Tab>
            </Tabs>
          </Tab>
        )}
      </Tabs>
    </PageWrapper>
  );
}
