import { capitalize } from 'lodash';
import { IFormDataShape } from './ProgramsContext';
import moment from 'moment';
import { EnrollmentOptions } from 'generated/graphql';

export const formatWeekDaysToString = (weekDays: WeekDay[]) => {
  if (weekDays.length > 0) {
    return weekDays.map((d) => capitalize(d)).join(', ');
  }
  return 'None';
};

export const formatStringToWeekDays = (daysString: string) => {
  let days: WeekDay[] = daysString.split(', ').map((a) => a.toUpperCase() as WeekDay);
  if (daysString.match('None')) days = [];
  return days;
};

export const convertFormDataToPayload = (
  programFormData: IFormDataShape,
  businessId: string,
  businessTimeZone: string
) => {
  let adjustedEnrolmentOpenAt: string | null = null;
  let adjustedEnrolmentCloseAt: string | null = null;

  const { enrolmentOpenDate, enrolmentCloseDate, enrolmentOpenTime, enrolmentCloseTime, ...restProgramFormData } =
    programFormData;

  if (enrolmentOpenDate) {
    const openTime = enrolmentOpenTime || '00:00';
    const [openHour, openMinute] = openTime.split(':');
    const openDateTime = moment
      .tz(enrolmentOpenDate, businessTimeZone)
      .set({ hour: parseInt(openHour), minute: parseInt(openMinute) });
    adjustedEnrolmentOpenAt = openDateTime.toISOString();
  }

  if (enrolmentOpenDate && enrolmentCloseDate) {
    const closeTime = enrolmentCloseTime || '23:59';
    const [closeHour, closeMinute] = closeTime.split(':');
    const closeDateTime = moment
      .tz(enrolmentCloseDate, businessTimeZone)
      .set({ hour: parseInt(closeHour), minute: parseInt(closeMinute), second: 59 });
    adjustedEnrolmentCloseAt = closeDateTime.toISOString();
  }

  return {
    businessId,
    ...restProgramFormData,
    operatingDays: formatWeekDaysToString(programFormData.operatingWeekDays ?? []),
    operatingWeekDays: undefined,
    enrolmentOpenAt: adjustedEnrolmentOpenAt,
    enrolmentCloseAt: adjustedEnrolmentCloseAt,
    programCenters: programFormData.programCenters.map(
      ({ id, centerId, classId, feeId, applicationFeeType, applicationFeeAmount, enrollmentOptions, casualFeeId }) => ({
        id,
        centerId,
        classId,
        feeId: enrollmentOptions === EnrollmentOptions.CasualOnly ? casualFeeId ?? feeId : feeId, // if casual only, set both the feeId an casualId to be the same
        applicationFeeType,
        applicationFeeAmount,
        enrollmentOptions,
        casualFeeId,
      })
    ),
    __typename: undefined,
  };
};
