import React, { useMemo } from 'react';
import { groupBy } from 'lodash';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { getFullName, getInitials, stringToHueDegree } from 'shared/util/string';
import SessionAbsenceCell from './SessionAbsenceCell';
import SessionAbsenceHeader from './SessionAbsenceHeader';
import type { IGapWaiveEligibilityMap, IChildrenEnrolmentTypeMap } from 'shared/hooks/useGapWaiveEligibleChecker';
import { isRegion } from 'shared/util/region';
import { useTranslation } from 'react-i18next';

const DAY_OF_WEEK = [1, 2, 3, 4, 5] as const;

interface ITableRowShape {
  child: IChild;
  class: IClass;
  sessionsForWeek: ISession[];
}

interface IGapWaiverTableProps {
  absentSessionsOfWeek: ISession[];
  gapWaiveEligibilityMap: IGapWaiveEligibilityMap;
  childrenEnrolmentTypeMap: IChildrenEnrolmentTypeMap;
  loading: boolean;
  timezone: string;
  startOfWeek: string;
  checkboxValues: { [key: string]: boolean };
  handleCheckboxChange: (session: ISession | undefined, value: boolean) => void;
}

const GapWaiverTable: React.FC<IGapWaiverTableProps> = ({
  absentSessionsOfWeek,
  gapWaiveEligibilityMap,
  childrenEnrolmentTypeMap,
  loading,
  timezone,
  startOfWeek,
  checkboxValues,
  handleCheckboxChange,
}) => {
  const isAU = isRegion('AU');
  const { t } = useTranslation();
  const tableData: ITableRowShape[] = useMemo(() => {
    const groupedData = groupBy(absentSessionsOfWeek, (s) => `${s.accountId}_${s.childId}_${s.classId}`);
    return Object.values(groupedData).map((sessions: ISession[]) => ({
      child: sessions[0].child,
      class: sessions[0].class,
      sessionsForWeek: sessions,
    }));
  }, [absentSessionsOfWeek]);

  return (
    <DataTable
      noPadding
      className="gap-waiver-table"
      data={tableData}
      dataSize={tableData.length}
      showLoadingOverlay={loading}
      showPagination={false}
      showSelect={false}
      columns={[
        {
          text: 'Child',
          dataField: 'child.lastname',
          formatter: (cell: any, row: ITableRowShape) => (
            <AvatarDataTableCell
              className={`${row.child.id}`}
              avatar={row.child.avatar?.url ?? ''}
              initials={getInitials(row.child)}
              color={`hsl(${stringToHueDegree(row.child.id)}, ${
                stringToHueDegree(row.child.id) < 50 ? '100%' : '40%'
              }, 40%`}
              primaryText={getFullName(row.child)}
              secondaryText={
                isAU
                  ? `${t('gap-waiver.enrollment-type')} ${
                      childrenEnrolmentTypeMap[row.child.id]?.arrangementType || 'N/A'
                    }`
                  : ''
              }
            />
          ),
        },
        {
          text: 'Class',
          dataField: 'class.name',
        },
        ...DAY_OF_WEEK.map((dayOfWeek) => ({
          text: (
            <SessionAbsenceHeader
              key={dayOfWeek}
              dayOfWeek={dayOfWeek}
              allAbsentSessionsOfWeek={absentSessionsOfWeek}
              gapWaiveEligibilityMap={gapWaiveEligibilityMap}
              timezone={timezone}
              checkboxValues={checkboxValues}
              handleCheckboxChange={handleCheckboxChange}
            />
          ),
          align: 'left',
          formatter: (cell: any, row: ITableRowShape) => (
            <SessionAbsenceCell
              gapWaiveEligibilityMap={gapWaiveEligibilityMap}
              key={dayOfWeek}
              dayOfWeek={dayOfWeek}
              sessionsForWeek={row.sessionsForWeek}
              timezone={timezone}
              startOfWeek={startOfWeek}
              checkboxValues={checkboxValues}
              handleCheckboxChange={handleCheckboxChange}
            />
          ),
        })),
      ]}
    />
  );
};

export default GapWaiverTable;
