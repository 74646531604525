import { useState, useCallback, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import NewRegulationModal from './NewRegulationModal';
import DataTable, { SizePerPage, TableHeader } from 'shared/components/DataTable';
import EditRegulation from './EditRegulationModal';
import { useGetRegulations } from './graphql/queries';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable';
import PageWrapper from 'shared/components/PageWrapper';
import { CreateButton, CirclePlusButton } from 'shared/components/Buttons';
import { STATES } from 'shared/constants/dropdownOptions/countryInfo';
import { toProperCase, isBlank } from 'shared/util/string';
import { orderBy } from 'lodash';
import FilterGroup from './FilterGroup';
import cast from 'shared/util/cast';
import ActionDropdown from 'shared/components/ActionDropdown';
import { ageToNumberOfDays } from 'shared/util/ageToNumberOfDays';

const Regulations = () => {
  const [tableData, setTableData] = useState<IRegulation[]>([]);
  const { data, loading, error } = useGetRegulations({
    onCompleted: (data) => setTableData(data.getAllRegulations ?? []),
  });
  const [tableFilters, setTableFilters] = useState({
    search: '',
    states: cast<ITableFilterOption[]>([]),
  });
  const [tablePage, setTablePage] = useState({
    sizePerPage: 25,
    page: 1,
  });
  const [regulationToEdit, setRegulationToEdit] = useState<IRegulation | null>(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    // updated count in cache, update state
    if (data?.getAllRegulations) {
      setTableData(data.getAllRegulations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getAllRegulations.length]);

  // filter data based on the selected filters
  useEffect(() => {
    if (data?.getAllRegulations) {
      const copy = [...data.getAllRegulations];
      const stateCodes = tableFilters.states.map((opt) => opt.value);
      let regs = searchRegulationsByTerm(tableFilters.search, copy);

      if (stateCodes.length) {
        regs = regs.filter((reg) => stateCodes.includes(reg.state));
      }

      setTableData(regs);
    }
    // ignoring since `searchRegulationsByTerm` is not included in the dependency array. it's not needed since it's defintion won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tableFilters]);

  const toggleAddModal = useCallback(() => setAddModalOpen((prev) => !prev), []);
  // const toggleDeleteModal = useCallback(() => setDeleteModalOpen(prev => !prev), []);

  const handleEdit = useCallback((regulation) => {
    const { __typename, ...regulationCopy } = regulation;
    setRegulationToEdit(regulationCopy);
    setEditModalOpen((prev) => !prev);
  }, []);

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    // lodash uses 'asc' and 'desc'
    const sortOrder: 'asc' | 'desc' = direction === 'ASCENDING' ? 'asc' : 'desc';

    if (field === 'startAge' || field === 'endAge') {
      setTableData((prev) => orderBy(prev, [(item: IRegulation) => ageToNumberOfDays(item[field])], [sortOrder]));
    } else {
      // sort state and county
      setTableData((prev) =>
        orderBy(prev, [(item: IRegulation) => `${STATES[item.state]}_${item.county ?? ''}`.toLowerCase()], [sortOrder])
      );
    }
  }, []);

  const searchRegulationsByTerm = useCallback((term: string, regs: IRegulation[]): IRegulation[] => {
    const lowercasedTerm = term.toLowerCase();

    return regs
      .map((reg: IRegulation) => ({ ...reg, _state: STATES[reg.state] }))
      .filter(
        (reg) =>
          reg._state.toLowerCase().includes(lowercasedTerm) ||
          reg.county?.toLowerCase().includes(lowercasedTerm) ||
          reg.startAge.age.toString().includes(lowercasedTerm) ||
          reg.endAge.age.toString().includes(lowercasedTerm) ||
          reg.ratioChildren.toString().includes(lowercasedTerm) ||
          reg.capacity?.toString().includes(lowercasedTerm)
      );
  }, []);

  const validateRegulation = useCallback((regulation: any): boolean => {
    return (
      !isBlank(regulation.state) &&
      Number.isInteger(parseInt(regulation.ratioTeachers, 10)) &&
      Number.isInteger(parseInt(regulation.ratioChildren, 10)) &&
      (!regulation.capacity || Number.isInteger(parseInt(regulation.capacity, 10))) &&
      ageToNumberOfDays(cast<IRegulationAge>(regulation.endAge)) >=
        ageToNumberOfDays(cast<IRegulationAge>(regulation.startAge))
    );
  }, []);

  const paginateData = useCallback((arr: IRegulation[], page: number, sizePerPage: number): IRegulation[] => {
    return arr.slice((page - 1) * sizePerPage, page * sizePerPage);
  }, []);

  if (error) console.log(error); // TODO : decide how to display errors

  return (
    <PageWrapper
      pageTitle="Regulations"
      mobileButtonComponent={<CirclePlusButton variant="primary" className="mt-4 mb-4" onClick={toggleAddModal} />}
      buttonComponent={
        <CreateButton className="my-2" onClick={toggleAddModal}>
          Add Regulation
        </CreateButton>
      }
      applyPadding={false}
    >
      <Container fluid className="px-0">
        {loading ? (
          <PageWrapperBody>
            <DataTableLoadingSkeleton />
          </PageWrapperBody>
        ) : (
          <DataTable
            keyField="id"
            data={paginateData(tableData ?? [], tablePage.page, tablePage.sizePerPage)}
            dataSize={tableData.length ?? 0}
            pageSize={tablePage.sizePerPage}
            activePage={tablePage.page}
            onSizePerPageChange={(size) => setTablePage((prev) => ({ ...prev, sizePerPage: size }))}
            onPageChange={(page) => setTablePage((prev) => ({ ...prev, page }))}
            showSelect={false}
            columns={[
              {
                text: 'State/County',
                dataField: 'state',
                sort: true,
                formatter: (cell: any, row: IRegulation) =>
                  `${STATES[row.state]} ${row.county ? ` / ${row.county}` : ''}`,
              },
              {
                text: 'Start Age',
                dataField: 'startAge',
                sort: true,
                formatter: (cell: any, row: IRegulation) =>
                  `${row.startAge.age} ${toProperCase(row.startAge.unit)}${row.startAge.age === 1 ? '' : 's'}`,
              },
              {
                text: 'End Age',
                dataField: 'endAge',
                sort: true,
                formatter: (cell: any, row: IRegulation) =>
                  `${row.endAge.age} ${toProperCase(row.endAge.unit)}${row.endAge.age === 1 ? '' : 's'}`,
              },
              {
                text: 'Ratio (Adult:Child)',
                dataField: 'ratioChildren',
                formatter: (cell: any, row: IRegulation) => `${row.ratioTeachers} : ${row.ratioChildren}`,
              },
              {
                text: 'Capacity',
                dataField: 'capacity',
                formatter: (cell: any, row: IRegulation) => row.capacity,
              },
              {
                text: 'Actions',
                dataField: '',
                align: 'center',
                headerClasses: 'text-center',
                formatter: (cell: any, row: IRegulation) => (
                  <ActionDropdown actions={[{ label: 'Edit', onClick: () => handleEdit(row) }]} />
                ),
              },
            ]}
            onSort={handleSort}
            renderHeader={(paginationProps) => (
              <TableHeader>
                <SizePerPage paginationProps={paginationProps} />
                <FilterGroup
                  onSearch={(term) => setTableFilters((prev) => ({ ...prev, search: term }))}
                  onStateSelect={(states) => setTableFilters((prev) => ({ ...prev, states }))}
                  selectedStates={tableFilters.states}
                />
              </TableHeader>
            )}
          />
        )}
      </Container>
      <NewRegulationModal isOpen={isAddModalOpen} onClose={toggleAddModal} validateRegulation={validateRegulation} />
      {regulationToEdit && (
        <EditRegulation
          regulation={regulationToEdit}
          isOpen={isEditModalOpen}
          onClose={() => {
            setEditModalOpen(false);
            setRegulationToEdit(null);
          }}
          validateRegulation={validateRegulation}
        />
      )}
    </PageWrapper>
  );
};

export default Regulations;
