import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'shared/components/PageWrapper';
import { CreateButton } from 'shared/components/Buttons';
import ProgramsContextProvider from './context/ProgramsContext';
import CreateProgramModal from './components/CreateProgramModal/CreateProgramModal';
import { useProgramsContext } from './context/ProgramsContext';
import EnrollmentProgramsTable from './components/EnrollmentProgramsTable';
import useGetEnrollmentProgramTable from './hooks/useGetEnrollmentProgramTable';
import { IEnrollmentProgramsTableProps } from './components/EnrollmentProgramsTable/EnrollmentProgramsTable';
import EditProgramModal from './components/EditProgramModal';
import { Tab, Tabs } from 'react-bootstrap';
import './_programs.scss';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import ProgramGroupsTabs from './tabs/ProgamGroupsTab/ProgramGroupsTab';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IEnrollmentProgram } from 'shared/types/enrollmentProgram';

interface IRouteParams {
  activeTab: EnrollmentProgramsTab;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {
  tableProps: IEnrollmentProgramsTableProps;
}

type EnrollmentProgramsTab = 'individual' | 'groups';
const Programs: React.FC<IProps> = ({ tableProps, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { activeTab } = props.match.params;
  const [tabKey, setTabKey] = useState<EnrollmentProgramsTab>(activeTab ?? 'individual');
  const {
    setIsCreateProgramModalOpen,
    setIsCreateProgramGroupModalOpen,
    setActiveProgram,
    setIsEditOpen,
    isCreateProgramGroupModalOpen,
  } = useProgramsContext();

  const handleEdit = (program: IEnrollmentProgram) => {
    setActiveProgram(program);
    setIsEditOpen(true);
  };

  const renderCreateButton = () => {
    if (tabKey === 'individual')
      return (
        <CreateButton onClick={() => setIsCreateProgramModalOpen(true)}>
          {t('enrollment.programs.create-program')}
        </CreateButton>
      );
    return (
      <CreateButton onClick={() => setIsCreateProgramGroupModalOpen(true)}>
        {t('enrollment.program-groups.create-program-group')}
      </CreateButton>
    );
  };

  return (
    <>
      <PageWrapper
        pageTitle={t('enrollment.programs.page-title')}
        applyPadding={true}
        buttonComponent={renderCreateButton()}
      >
        <Tabs
          id="enrollment-tabs"
          defaultActiveKey="individual"
          activeKey={tabKey}
          unmountOnExit
          onSelect={(tab) => {
            setTabKey(tab as EnrollmentProgramsTab);
            history.push(`/enrollment/programs/${tab}`);
          }}
        >
          <Tab eventKey="individual" title={t('enrollment.programs.tab-title')}>
            <EnrollmentProgramsTable {...tableProps} handleEdit={handleEdit} />
          </Tab>
          <Tab eventKey="groups" title={t('enrollment.program-groups.tab-title')}>
            <ProgramGroupsTabs
              setIsCreateProgramGroupModalOpen={setIsCreateProgramGroupModalOpen}
              isCreateProgramGroupModalOpen={isCreateProgramGroupModalOpen}
            />
          </Tab>
        </Tabs>
      </PageWrapper>
      <CreateProgramModal />
      <EditProgramModal />
    </>
  );
};

export default (props: RouteComponentProps<IRouteParams, any, {}>) => {
  const { tableProps, refetch } = useGetEnrollmentProgramTable();

  return (
    <ProgramsContextProvider refetch={refetch}>
      <Programs tableProps={tableProps} {...props} />
    </ProgramsContextProvider>
  );
};
