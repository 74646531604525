import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import { RootState } from 'store/reducers';
import { updateEnrollmentTableWeek } from 'pages/Enrollment/duck/actions';
import TimeframeAndClassControls from './components/TimeframeAndClassControls';
import ClassAvailabilityCard from './components/ClassAvailabilityCard';
import ClassEnrollmentsCard from './components/ClassEnrollmentsCard';
import { useGetClassEnrollmentInformation } from './graphql/queries';
import ClassEnrollmentWeekContext from './classEnrollmentWeekProvider';

interface IProps {
  centerTimezone: string;
}

const ClassEnrollment: React.FC<IProps> = ({ centerTimezone, ...props }) => {
  const dispatch = useDispatch();
  const startOfWeek = useSelector((state: RootState) => state.enrollment.activeTimeframe.start);
  const endOfWeek = useSelector((state: RootState) => state.enrollment.activeTimeframe.end);
  const activeClass = useSelector((state: RootState) => state.enrollment.activeClass);
  const includeFutureContracts = useSelector((state: RootState) => state.enrollment.includeFutureContracts);
  const includeLtbOffers = useSelector((state: RootState) => state.enrollment.includeLtbOffers);

  const {
    data: classEnrollmentData,
    loading: loadingClassEnrollment,
    refetch,
  } = useGetClassEnrollmentInformation(
    activeClass?.id ?? '',
    startOfWeek.toISOString(),
    endOfWeek.toISOString(),
    includeFutureContracts,
    includeLtbOffers
  );

  const handleWeekChange = useCallback(
    (start: moment.Moment, end: moment.Moment) => {
      dispatch(updateEnrollmentTableWeek(start, end));
    },
    [dispatch]
  );

  const getEnrollmentsToShow = useCallback((data: IEnrollmentDataForTimeframe | undefined): IEnrollment[] => {
    const currentEnrollments = data?.enrollments ?? [];
    const futureEnrollments = data?.futureEnrollments ?? [];
    const combined = uniqBy([...currentEnrollments, ...futureEnrollments], (e) => e.id);

    return combined.sort((a, b) => {
      const aValue = a.contract.status === 'FUTURE' ? -1 : 1;
      const bValue = b.contract.status === 'FUTURE' ? -1 : 1;

      return bValue - aValue;
    });
  }, []);

  return (
    <Column className="p-0">
      <div className="mb-4">
        <TimeframeAndClassControls
          startOfWeek={startOfWeek}
          endOfWeek={endOfWeek}
          onWeekChange={handleWeekChange}
          includeLtbOffers={includeLtbOffers}
          includeFutureContracts={includeFutureContracts}
        />
      </div>
      <Row>
        <Column>
          {activeClass && (
            <ClassAvailabilityCard
              loading={loadingClassEnrollment}
              availability={classEnrollmentData?.getClass.availabilityForTimeframe ?? []}
              centerTimezone={centerTimezone}
            />
          )}
        </Column>
      </Row>
      <Row>
        <Column>
          {activeClass && (
            <ClassEnrollmentWeekContext.Provider value={{ startOfWeek, endOfWeek }}>
              <ClassEnrollmentsCard
                loading={loadingClassEnrollment}
                enrollments={getEnrollmentsToShow(classEnrollmentData?.getClass.enrollmentDataForTimeframe)}
                availability={classEnrollmentData?.getClass.availabilityForTimeframe ?? []}
                refetchData={refetch}
                centerTimezone={centerTimezone}
              />
            </ClassEnrollmentWeekContext.Provider>
          )}
        </Column>
      </Row>
    </Column>
  );
};

export default ClassEnrollment;
