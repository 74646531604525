import {
  EnrollmentActionTypes,
  UPDATE_ENROLLMENT_DASHBOARD_CLASSES_DATA,
  UPDATE_ENROLLMENT_TABLE_WEEK,
  UPDATE_INCLUDE_FUTURE_CONTRACTS,
  UPDATE_INCLUDE_LTB_OFFERS,
  UPDATE_VISIBLE_CENTER_FOR_ENROLLMENT,
  UPDATE_VISIBLE_CLASS_FOR_ENROLLMENT,
} from './types';
import moment from 'moment';
import { orderBy } from 'lodash';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

export interface IEnrollmentStateShape {
  activeTimeframe: {
    // the week being viewed across the various enrollment screens needs to remain the same
    start: moment.Moment;
    end: moment.Moment;
  };
  activeCenter: {
    id: string;
    businessId: string;
  };
  dashboard: {
    availabilityDataset: IClass[];
  };
  activeClass: IClass | undefined;
  includeLtbOffers: boolean;
  includeFutureContracts: boolean;
}

const dateSettings = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;

const initialState: IEnrollmentStateShape = {
  activeTimeframe: {
    start: moment().startOf(dateSettings.week),
    end: moment().endOf(dateSettings.week),
  },
  activeCenter: {
    id: '',
    businessId: '',
  },
  dashboard: {
    availabilityDataset: [],
  },
  activeClass: undefined,
  includeLtbOffers: true,
  includeFutureContracts: true,
};

export const enrollmentReducers = (
  state: IEnrollmentStateShape = initialState,
  action: EnrollmentActionTypes
): IEnrollmentStateShape => {
  switch (action.type) {
    case UPDATE_ENROLLMENT_TABLE_WEEK:
      return {
        ...state,
        activeTimeframe: {
          ...state.activeTimeframe,
          start: action.startOfWeek,
          end: action.endOfWeek,
        },
      };
    case UPDATE_VISIBLE_CENTER_FOR_ENROLLMENT:
      return {
        ...state,
        activeCenter: {
          ...state.activeCenter,
          id: action.centerId,
          businessId: action.businessId ?? state.activeCenter.businessId,
        },
        activeClass: undefined,
      };
    case UPDATE_VISIBLE_CLASS_FOR_ENROLLMENT:
      return {
        ...state,
        activeClass: action._class,
      };
    case UPDATE_INCLUDE_FUTURE_CONTRACTS:
      return {
        ...state,
        includeFutureContracts: action.includeFutureContracts,
      };
    case UPDATE_INCLUDE_LTB_OFFERS:
      return {
        ...state,
        includeLtbOffers: action.includeLtbOffers,
      };
    case UPDATE_ENROLLMENT_DASHBOARD_CLASSES_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          availabilityDataset: orderBy(action.classes, (c) => c.name, 'asc'),
        },
      };
    default:
      return state;
  }
};
