import React from 'react';
import Card from 'shared/components/Card';
import Avatar from 'shared/components/Avatar';
import Select from 'shared/components/Select';

interface IProps {
  centerOptions: ICenter[];
  assignedCenters: ICenter[];
  fetchingCenters: boolean;
  onUpdate: (updateCenters: ICenter[]) => void;
}

const ServiceFeeGroupCenters: React.FC<IProps> = ({ centerOptions, assignedCenters, fetchingCenters, onUpdate }) => {
  return (
    <Card header="Group Centers" className="h-100">
      <Select
        isMulti
        value={assignedCenters}
        isLoading={fetchingCenters}
        options={centerOptions}
        onChange={onUpdate}
        getOptionValue={(option: ICenter) => option.id}
        getOptionLabel={(option: ICenter) => option.name}
        formatOptionLabel={(option: ICenter, { context }: { context: 'menu' | 'value' }) => (
          <div key={`class-center-assignment-${option.id}`} className="d-flex align-items-center">
            <Avatar size="sm" image={option.avatar?.url} initials="" />
            <div className="d-flex flex-column pl-4 text-truncate">
              <span className="text-truncate">{option.name}</span>
            </div>
          </div>
        )}
      />
    </Card>
  );
};

export default ServiceFeeGroupCenters;
