import { baseTransactionFields } from 'gql/transaction/fields';

export const itemizedBillFields = `
  id
  userFriendlyId
  businessId
  centerId
  accountId
  billingCycleId
  billingPeriodStartDate
  billingPeriodEndDate
  forwardAmount
  dueOnDate
  lateOnDate
  amount
  createdAt
  createdBy
  isCurrentBill
  isManuallyCreated
  isPaid
  totalAmount
  createdByPerson {
    id
    firstname
    nickname
    lastname
    avatar {
      url
    }
  }
`;

export const itemizedBillWithTransactionFields = `
  ${itemizedBillFields}
  transactions {
    id
    date
    description
    amount
    createdBy
    session {
      id
      accountChild {
        id
        accountChildId
        firstname
        lastname
        nickname
      }
    }
    transactionType {
      id
      name
    }
  }
  displayableTransactions {
    id
    date
    description
    amount
    createdBy
    session {
      id
      accountChild {
        id
        accountChildId
        firstname
        lastname
        nickname
      }
    }
    transactionType {
      id
      name
    }
    appliedToAccountChild {
      id
      accountChildId
      firstname
      lastname
      nickname
    }
  }
`;

export const itemizedBillSummaryDiscountFields = `
  name
  amount
`;

export const itemizedBillSummarySessionGroupFields = `
  feeName
  baseFeeTotal
  feeAdjustment
  adjustmentName
  estimatedSubsidyCredit
  earlyFee
  lateFee
  discounts {
    ${itemizedBillSummaryDiscountFields}
  }
`;

export const itemizedBillSummaryChildGroupFields = `
  firstName
  lastName
  sessionGroups {
    ${itemizedBillSummarySessionGroupFields}
  }
  ungroupedTransactions {
    ${baseTransactionFields}
  }
  ungroupedDiscounts {
    ${itemizedBillSummaryDiscountFields}
  }
`;

export const itemizedBillSummaryFields = `
  childGroups {
    ${itemizedBillSummaryChildGroupFields}
  }
  ungroupedTransactions {
    ${baseTransactionFields}
  }
  ungroupedDiscounts {
    ${itemizedBillSummaryDiscountFields}
  }
`;
