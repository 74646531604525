import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApolloError } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { useCancelFlaggedPayment, useUpdateFlaggedPayment } from 'gql/transaction/mutations';
import { showToast } from 'shared/components/Toast';
import { updatePayment, updateTransaction } from 'pages/BillingTransactions/duck/action';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import Button from 'shared/components/Buttons';
import { Row } from 'shared/components/Layout';

interface IProps {
  isOpen: boolean;
  payment: IPayment | null;
  /**
   * called whenever the modal wants to close i.e. cancel button, x button, or save is finished
   * @returns
   */
  onClose: () => void;
  /**
   * called when the payment has been successfully canceled
   * @returns
   */
  onComplete?: () => void;
}

const CancelPaymentModal: React.FC<IProps> = ({ isOpen, payment, onClose, onComplete = () => {} }) => {
  const dispatch = useDispatch();
  const [newAmount, updateNewAmount] = useState(0);

  const handleClose = () => {
    onClose();
    updateNewAmount(0);
  };

  const [cancelFlaggedPaymentFn, { loading: cancelFlaggedPaymentLoading }] = useCancelFlaggedPayment({
    onCompleted: (result) => {
      if (result.cancelPayment) {
        dispatch(updateTransaction(result.cancelPayment));
        showToast('Cancelled payment successfully.', 'success');
        handleClose();
        onComplete();
      }
    },
    onError: (err: any) => {
      let message;
      if (err instanceof ApolloError) {
        message = getApolloErrorMessage(err);
      } else if (err instanceof Error) {
        message = err.toString();
      } else {
        message = 'Something went wrong. Please try again later.';
      }

      showToast(message, 'error');
    },
  });

  const [updateFlaggedPaymentFn, { loading: updateFlaggedPaymentLoading }] = useUpdateFlaggedPayment({
    onCompleted: (result) => {
      if (result.updatePayment) {
        dispatch(updatePayment(result.updatePayment, payment?.id ?? ''));
        showToast('Updated payment successfully.', 'success');
        handleClose();
      }
    },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleCancel = useCallback(() => {
    if (payment) {
      cancelFlaggedPaymentFn({
        variables: {
          paymentId: payment.id,
        },
      });
    }
  }, [cancelFlaggedPaymentFn, payment]);

  const handleUpdate = useCallback(() => {
    if (payment) {
      updateFlaggedPaymentFn({
        variables: {
          input: {
            paymentId: payment.id,
            amount: newAmount as number,
          },
        },
      });
    }
  }, [cancelFlaggedPaymentFn, payment, newAmount]);

  return (
    <ConfirmationModal
      show={isOpen}
      onHide={handleClose}
      title="Cancel or Update Payment?"
      hideOnCallback={false}
      size="lg"
      noPrimaryChoice
      noSecondaryChoice
    >
      <p>Update the amount of the payment and proceed with processing.</p>
      <div style={{ width: '260px' }}>
        <CurrencyInput2
          label="Updated Payment Amount"
          value={newAmount}
          onChange={updateNewAmount}
          className="mb-6 border-radius-0"
        />
      </div>
      <div className="mb-6">
        <b>Or</b>, cancel payment entirely. It will not be processed and funds will not be collected.
      </div>
      <Row noGutters>
        <Button onClick={handleClose} variant="light">
          Cancel
        </Button>
        <Button
          onClick={handleCancel}
          className="ml-auto mr-2"
          loading={cancelFlaggedPaymentLoading}
          disabled={updateFlaggedPaymentLoading}
          variant="outline-danger"
        >
          Cancel Payment
        </Button>
        <Button
          onClick={handleUpdate}
          loading={updateFlaggedPaymentLoading}
          disabled={newAmount === 0 || cancelFlaggedPaymentLoading}
        >
          Update Payment
        </Button>
      </Row>
    </ConfirmationModal>
  );
};

export default CancelPaymentModal;
