import React, { useState } from 'react';
import moment from 'moment';
import SessionQuery from './SessionQuery';
import DataTable from 'shared/components/DataTable';
import Alert from 'shared/components/Alert';

interface IProps {
  data: ISubsidyWeeklySummary;
}

const SubsidiesTrackingRow: React.FC<IProps> = ({ data }) => {
  const [sessionPerDay, setSessionPerDay] = useState<Record<string, ISession[]>>({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [isLoading, setLoading] = useState(false);

  const getSubsidyEntitlements = (sessionId: string): ISubsidyEntitlement[] => {
    if (!data.subsidyEntitlements) return [];

    var subsidyEntitlements = data.subsidyEntitlements.filter((se) => se.sessionId === sessionId);
    return subsidyEntitlements;
  };

  const addSessions = (session: ISession) => {
    const day = moment(session.date).format('dddd');
    if (!session.void) {
      setSessionPerDay((perDay) => ({
        ...perDay,
        [day]: [...perDay[day], session],
      }));
    }
  };

  const formatTime = (date?: string) => (date ? moment(date).format('h:mm a') : '');

  const formatTimeRange = (start?: string, end?: string) => `${formatTime(start)} - ${formatTime(end)}`;

  const formatSessionTimes = (session: ISession) => {
    const { feeStartTime, date, feeEndTime } = session;
    const startTime = feeStartTime ? `${date}T${feeStartTime}` : undefined;
    const endTime = feeEndTime ? `${date}T${feeEndTime}` : undefined;
    return formatTimeRange(startTime, endTime);
  };

  const formatAttendedTimes = (timeEntries: IAttendanceTimeEntry[]) => (
    <>
      {timeEntries?.map((time) => (
        <div key={time.id}>{formatTimeRange(time.timeIn, time.timeOut)}</div>
      ))}
    </>
  );

  const renderAttendedTimes = (session: ISession) => {
    if (session) {
      return !!session.absence ? (
        <span className="text-orange">Absent</span>
      ) : (
        formatAttendedTimes(session?.timeEntries)
      );
    }
    return '';
  };

  const tableData = Object.keys(sessionPerDay)
    .map((day: string) => {
      var sessions = sessionPerDay[day];
      const totalSessionSubsidy = sessions.reduce(
        (acc: number, curr: ISession) => acc + (curr.subsidy?.amount ?? 0),
        0
      );
      return {
        id: day,
        sessionSubsidy: sessions.length > 0 ? `$${totalSessionSubsidy.toFixed(2)}` : null,
        hasSession: sessions.length > 0,
        sessionTimes: sessions.map((session) => (session ? formatSessionTimes(session) : '')),
        attendedTimes: sessions.map((session) => renderAttendedTimes(session)),
        subsidyEntitlements: sessions.length > 0 ? getSubsidyEntitlements(sessions[0].id) : null,
      };
    })
    .filter((s) => !!s.hasSession);

  return (
    <div>
      {data &&
        data.sessionIds &&
        data.sessionIds.map((sessionId) => (
          <SessionQuery key={sessionId} sessionId={sessionId} updateSessions={addSessions} setLoading={setLoading} />
        ))}

      {data.sessionReportSynchronisationErrorDetails && (
        <Alert variant="danger">{data.sessionReportSynchronisationErrorDetails}</Alert>
      )}

      <DataTable
        data={tableData}
        showSelect={false}
        showPagination={false}
        showLoadingOverlay={isLoading}
        striped
        columns={[
          {
            text: 'Day',
            dataField: 'id',
          },
          {
            text: 'Session Subsidy',
            dataField: 'sessionSubsidy',
            formatter: (cell: string) => cell ?? '',
          },
          {
            text: 'Attended Times',
            dataField: 'attendedTimes',
            formatter: (cell: string[]) => (
              <div>
                {cell.map((time: string) => (
                  <>{time}</>
                ))}
              </div>
            ),
          },
          {
            text: 'Session Times',
            dataField: 'sessionTimes',
            formatter: (cell: string[]) => (
              <div>
                {cell.map((time: string, index: number) => (
                  <>
                    {time}
                    {index < cell.length && <br />}
                  </>
                ))}
              </div>
            ),
          },
          {
            isDummyField: true,
            text: 'Enrolment Id',
            dataField: 'enrolmentId',
            formatter: (cell: any, row: any) => (
              <div>
                {row.subsidyEntitlements.map((se: ISubsidyEntitlement, index: number) => (
                  <>
                    {se.enrolmentId ?? ''}
                    {index < row.subsidyEntitlements.length && <br />}
                  </>
                ))}
              </div>
            ),
          },
          {
            isDummyField: true,
            text: 'CCS Event Id',
            dataField: 'eventId',
            formatter: (cell: any, row: any) => (
              <div>
                {row.subsidyEntitlements.map((se: ISubsidyEntitlement, index: number) => (
                  <>
                    {se.eventId ?? ''}
                    {index < row.subsidyEntitlements.length && <br />}
                  </>
                ))}
              </div>
            ),
          },
          {
            isDummyField: true,
            text: 'Reason',
            dataField: 'reason',
            formatter: (cell: any, row: any) => (
              <div>
                {row.subsidyEntitlements.map((se: ISubsidyEntitlement, index: number) => (
                  <>
                    {se.reason ?? ''}
                    {index < row.subsidyEntitlements.length && <br />}
                  </>
                ))}
              </div>
            ),
          },
          {
            isDummyField: true,
            text: 'Recipient',
            dataField: 'recipient',
            formatter: (cell: any, row: any) => (
              <div>
                {row.subsidyEntitlements.map((se: ISubsidyEntitlement, index: number) => (
                  <>
                    {se.recipient ?? ''}
                    {index < row.subsidyEntitlements.length && <br />}
                  </>
                ))}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default SubsidiesTrackingRow;
