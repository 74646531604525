import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ApplicationFlowType,
  SortDirection,
  ApplicationStage,
  useGetApplicationsByStageQuery,
  Application,
  useMarkApplicationReadMutation,
  ApplicationChild,
  useBulkApproveOffersMutation,
  ApplicationScheduleOfferState,
  ApplicationScheduleOffer,
  ApplicationDayFilterType,
  DayOfWeek,
  useGetApplicationSettingQuery,
  EnrolmentSource,
  YesNoType,
  ApplicationState,
} from 'generated/graphql';
import { RootState } from 'store/reducers';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useLocation } from 'react-router';
import {
  NORMAL_FLOW_TABS,
  PROGRAM_FLOW_TABS,
  WAITLIST_BULK_APPROVAL_CAN_APPROVE_STATE,
  APPROVAL_PENDING_BULK_APPROVAL_CAN_APPROVE_STATE,
} from './types';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { useGetApplicationCategories } from 'gql/application/queries';
import { useGetActiveCenterOptions } from 'shared/hooks/useGetActiveCenters';
import { useGetClassesForMultipleCenters } from 'pages/Centers/subroutes/Classes/graphql/queries';
import { IAgeFilter, IEditApplicationModalState, ILeadsContext, INoteModalState } from './leadsContext.type';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import { PREDICATES, SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
const { TERM } = SEARCH_EXPRESSIONS;
const { CONTAINS } = PREDICATES;
import { sortBy, uniq } from 'lodash';
import { getCenterIdsByTags } from './utils';
import { FormattedEnrolmentDiffAreas } from './components/EnrollmentFormDiffModal/useEnrolmentFormDiffValueFormatter';

export const LeadsContext = React.createContext<ILeadsContext | null>(null);

export default ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  // settings
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const { data: centers, centerOptions } = useGetActiveCenterOptions(`
    id
    entityId
    name
    tags {
      id
    }
    serviceType
  `);

  const { data: flowSetting, loading: flowSettingLoading } = useGetApplicationSettingQuery({
    variables: {
      input: {
        businessId,
      },
    },
    skip: !Boolean(businessId),
  });
  const applicationFlowType = flowSetting?.getApplicationSetting.flowType ?? ApplicationFlowType.InquireOfferEnrollment;
  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];

  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name'])
    .map((tag) => ({
      label: tag.name,
      value: tag.id,
      searchExpression: { [TERM]: { field: 'tags.keyword', predicate: CONTAINS, value: tag.id } },
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  // state from link
  const { state: linkState } = useLocation<{ firstName: string; centreId: string }>();

  // tabs
  const [activeTab, setActiveTab] = useState<string>(
    applicationFlowType === ApplicationFlowType.InquireOfferEnrollment
      ? Object.keys(NORMAL_FLOW_TABS)[0]
      : Object.keys(PROGRAM_FLOW_TABS)[0]
  );

  // various modals
  const [editApplicationModalState, setEditApplicationModalState] = useState<IEditApplicationModalState>({
    isOpen: false,
    editLevel: 'basic',
  });

  const [noteModalState, setNoteModalState] = useState<INoteModalState>({
    isOpen: false,
  });

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isBulkApproveModalOpen, setIsBulkApproveModalOpen] = useState(false);

  // helper states
  const [selectedApplication, setSelectedApplication] = useState<Application>();
  const [selectedChild, setSelectedChild] = useState<ApplicationChild>();

  // various filters
  const [searchText, setSearchText] = useState(linkState?.firstName ?? '');
  const [tableState, tableFunctions] = useDatatableState();
  const [sort, setSort] = useState([{ field: 'enquiryDate', direction: SortDirection.Descending }]);
  const [centerFilters, setCenterFilters] = useState<ITableFilterOption[] | null>(null);
  const [tagFilters, setTagFilters] = useState<ITableFilterOption[] | null>(null);
  const [programFilters, setProgramFilters] = useState<ITableFilterOption[] | null>(null);
  const [careTypeFilters, setCareTypeFilters] = useState<ITableFilterOption[] | null>(null);
  const [ageRangeFilters, setAgeRangeFilters] = useState<IAgeFilter | null>(null);
  const [daysFilter, setDaysFilter] = useState<ITableFilterOption[] | null>(null);
  const [enrollmentSource, setEnrolmentSource] = useState<ITableFilterOption[] | null>(null);
  const [selectedDaysFilterGroup, setSelectedDaysFilterGroup] = useState<string>(
    ApplicationDayFilterType.ScheduleIncludes
  );
  const [paidFilter, setPaidFilter] = useState<YesNoType | null>(null);
  const [subsidyEligibilityFilter, setSubsidyEligibilityFilter] = useState<YesNoType | null>(null);
  const [inProgressStatusFilter, setInProgressStatusFilter] = useState<ITableFilterOption[] | null>(null);
  const [onlyShowUpdatedForms, setOnlyShowUpdatedForms] = useState<boolean | null>(null);

  const centerIds = useMemo(() => {
    const centerIdsByTags = getCenterIdsByTags(
      centers,
      tagFilters?.map((t) => t.value)
    );
    const centerFilterIds = centerFilters?.map((c) => c.value) ?? [];
    const hasTagFilter = (tagFilters ?? [])?.length > 0;
    const hasCenterFilter = (centerFilters ?? [])?.length > 0;

    if (hasTagFilter && hasCenterFilter) return uniq([...centerIdsByTags, ...centerFilterIds]);
    else if (hasCenterFilter) return centerFilterIds ?? [];
    else if (hasTagFilter) return centerIdsByTags ?? [];
    return centerOptions?.map((c) => c.value) ?? [];
  }, [tagFilters, centers, centerOptions, centerFilters]);

  // permissions
  const hasCreateEnrollmentLeadManagementPermissions = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementMain,
    level: RoleLevelType.Create,
  });

  const hasEditEnrollmentLeadManagementPermissions = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementMain,
    level: RoleLevelType.Edit,
  });
  // fetch classes for lead details
  useGetClassesForMultipleCenters(centerIds);

  const enrollmentSources: EnrolmentSource[] | undefined = useMemo(() => {
    if (enrollmentSource !== null) {
      return enrollmentSource.map((s) => s.value as EnrolmentSource);
    }
    return undefined;
  }, [enrollmentSource]);

  const inProgressSatuses: ApplicationState[] | undefined = useMemo(() => {
    if (inProgressStatusFilter !== null && inProgressStatusFilter.length > 0) {
      if (inProgressStatusFilter.some((p) => (p.value as ApplicationState) === ApplicationState.Accepted)) {
        return [
          ...inProgressStatusFilter.map((p) => p.value as ApplicationState),
          ApplicationState.ApplicantAcceptedOffer,
        ];
      } else {
        return inProgressStatusFilter.map((p) => p.value as ApplicationState);
      }
    }
    return undefined;
  }, [inProgressStatusFilter]);

  // fetching data
  const {
    data: applicationsData,
    loading: getApplicationsQueryLoading,
    refetch: refetchData,
  } = useGetApplicationsByStageQuery({
    variables: {
      businessId,
      input: {
        centerIds,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        searchText: searchText || undefined,
        sortDtos: sort,
        applicationStage: activeTab as ApplicationStage,
        programIds: programFilters && programFilters.length > 0 ? programFilters?.map((p) => p.value) : undefined,
        careTypes: careTypeFilters && careTypeFilters.length > 0 ? careTypeFilters.map((ct) => ct.value) : undefined,
        minBirthdayMonths: ageRangeFilters?.min,
        maxBirthdayMonths: ageRangeFilters?.max,
        birthdayAsAtDate: ageRangeFilters?.dateAsAt,
        days:
          daysFilter && daysFilter.length > 0
            ? {
                dayFilterType: selectedDaysFilterGroup as ApplicationDayFilterType,
                scheduleValues:
                  !daysFilter.some((d) => (d.value as ApplicationDayFilterType) === ApplicationDayFilterType.Casual) &&
                  [ApplicationDayFilterType.ScheduleIncludes, ApplicationDayFilterType.ScheduleIsExactly].includes(
                    selectedDaysFilterGroup as ApplicationDayFilterType
                  )
                    ? // filter value is in form of 'namespace'-'WeekDay'
                      (daysFilter.map((d) => d.value.split('-')[1].toUpperCase()) as DayOfWeek[])
                    : undefined,
                numberOfDay:
                  !daysFilter.some((d) => (d.value as ApplicationDayFilterType) === ApplicationDayFilterType.Casual) &&
                  selectedDaysFilterGroup === ApplicationDayFilterType.NumberOfDays
                    ? Number(daysFilter[0].value.split('-')[1])
                    : undefined,
              }
            : (selectedDaysFilterGroup as ApplicationDayFilterType) === ApplicationDayFilterType.Casual
            ? {
                dayFilterType: selectedDaysFilterGroup as ApplicationDayFilterType,
              }
            : undefined,
        enrollmentSources,
        paid: paidFilter,
        subsidyEligibility: subsidyEligibilityFilter,
        inProgressStatus: inProgressSatuses,
        onlyShowUpdatedForms: onlyShowUpdatedForms,
      },
    },
    skip:
      centerIds?.length === 0 || centerOptions.length === 0 || !Boolean(businessId) || !Boolean(applicationFlowType),
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: applicationsAggregateData,
    loading: getApplicationsAggregateQueryLoading,
    refetch: refetchAggregateData,
  } = useGetApplicationCategories({
    variables: {
      input: {
        centerIds,
      },
      businessId: businessId,
    },
    skip:
      centerIds?.length === 0 || centerOptions.length === 0 || !Boolean(businessId) || !Boolean(applicationFlowType),

    fetchPolicy: 'cache-and-network',
  });

  // methods
  const handleDaysFilterChange = useCallback((v: ITableFilterOption[] | null) => {
    if (!v) return;
    // radio type filter can only select 1 option at a time
    if (v.some((each) => each.type === 'radio')) {
      setDaysFilter([v[v.length - 1]]);
    } else {
      setDaysFilter(v);
    }
  }, []);

  const handleSourceChange = (v: ITableFilterOption[] | null) => {
    if (v !== null) setEnrolmentSource(v);
  };

  const handleRefetchApplications = () => {
    refetchData();
  };

  const handleSort = useCallback((field: string, direction: SortDirection) => {
    setSort([{ field, direction }]);
  }, []);

  const resetFilters = useCallback(() => {
    setSearchText('');
    setCareTypeFilters(null);
    setProgramFilters(null);
    setDaysFilter(null);
    setSelectedDaysFilterGroup(ApplicationDayFilterType.ScheduleIncludes);
    setEnrolmentSource(null);
    setPaidFilter(null);
    setSubsidyEligibilityFilter(null);
    setInProgressStatusFilter(null);
    setAgeRangeFilters(null);
  }, []);

  const resetCenterFilters = useCallback(() => {
    setCenterFilters(null);
    setTagFilters(null);
  }, [setCenterFilters, setTagFilters]);

  const [markApplicationReadMutation] = useMarkApplicationReadMutation({
    onCompleted: () => {
      refetchData();
      refetchAggregateData();
    },
  });

  const markAsRead = useCallback(
    (application: Application) => {
      markApplicationReadMutation({
        variables: {
          businessId,
          applicationId: application.id,
        },
      });
    },
    [businessId, markApplicationReadMutation]
  );

  const [approveOffers, { loading: approveApplicationsLoading }] = useBulkApproveOffersMutation({
    onError: (err) => showToast(getApolloErrorMessage(err), 'error'),
    onCompleted: () => {
      showToast(t('enrollment.lead-management.send-offer-success'), 'success');
      tableFunctions.updateSelectedRows([]);
      refetchData();
      setIsBulkApproveModalOpen(false);
    },
  });

  const bulkApproveValidState = useMemo(() => {
    if (activeTab === ApplicationStage.Accepted) {
      return APPROVAL_PENDING_BULK_APPROVAL_CAN_APPROVE_STATE;
    }
    if (activeTab === ApplicationStage.Waitlisted) {
      return WAITLIST_BULK_APPROVAL_CAN_APPROVE_STATE;
    }
    return [];
  }, [activeTab]);

  const handleBulkApprove = useCallback(() => {
    const applications: Application[] = tableState.selectedRows;
    const allChildren: ApplicationChild[] = applications.reduce(
      (children: ApplicationChild[], application: Application) => [...children, ...application.children],
      []
    );
    const allOffers = allChildren.reduce(
      (offers: ApplicationScheduleOffer[], child: ApplicationChild) => [
        ...offers,
        ...(child.offers as ApplicationScheduleOffer[]),
      ],
      []
    );

    const offersCanBeBulkApproved = allOffers.filter((offer) =>
      bulkApproveValidState.includes((offer.state as ApplicationScheduleOfferState) ?? '')
    );

    approveOffers({
      variables: {
        input: {
          businessId,
          scheduleOfferIds: offersCanBeBulkApproved.map((offer) => offer.id),
        },
      },
    }).then(() => {
      refetchAggregateData();
    });
  }, [tableState.selectedRows, businessId, approveOffers, bulkApproveValidState]);

  // enrolmentFormDiff
  const [enrolmentFormDiffData, setEnrolmentFormDiffData] = useState<FormattedEnrolmentDiffAreas | null>(null);
  const [isEnrolmentFormDiffModalOpen, setIsEnrolmentFormDiffModalOpen] = useState(false);

  // side effects
  useEffect(() => {
    // invalidate selected filters when filter group changes
    setDaysFilter((prev) => (prev ? prev.filter((f) => f.namespace === selectedDaysFilterGroup) : null));
  }, [selectedDaysFilterGroup]);

  useEffect(() => {
    setActiveTab(
      applicationFlowType === ApplicationFlowType.InquireOfferEnrollment
        ? Object.keys(NORMAL_FLOW_TABS)[0]
        : Object.keys(PROGRAM_FLOW_TABS)[0]
    );
  }, [applicationFlowType]);

  return (
    <LeadsContext.Provider
      value={{
        activeTab,
        setActiveTab,
        applicationFlowType,
        linkState,
        searchText,
        setSearchText,
        tableState,
        tableFunctions,
        sort,
        setSort,
        programFilters,
        setProgramFilters,
        careTypeFilters,
        setCareTypeFilters,
        ageRangeFilters,
        setAgeRangeFilters,
        daysFilter,
        setDaysFilter,
        selectedDaysFilterGroup,
        setSelectedDaysFilterGroup,
        handleDaysFilterChange,
        applicationsData,
        getApplicationsQueryLoading: getApplicationsQueryLoading,
        applicationsAggregateData,
        getApplicationsAggregateQueryLoading,
        refetchAggregateData,
        handleSort,
        resetFilters,
        hasCreateEnrollmentLeadManagementPermissions,
        editApplicationModalState,
        setEditApplicationModalState,
        businessId,
        centerIds,
        selectedApplication,
        setSelectedApplication,
        handleRefetchApplications,
        hasEditEnrollmentLeadManagementPermissions,
        markAsRead,
        selectedChild,
        setSelectedChild,
        isSearchOpen,
        setIsSearchOpen,
        isBulkApproveModalOpen,
        setIsBulkApproveModalOpen,
        approveApplicationsLoading,
        handleBulkApprove,
        flowSettingLoading,
        bulkApproveValidState,
        centerOptions,
        centerFilters,
        setCenterFilters,
        tagOptions,
        tagFilters,
        setTagFilters,
        resetCenterFilters,
        handleSourceChange,
        enrollmentSource,
        paidFilter,
        setPaidFilter,
        subsidyEligibilityFilter,
        setSubsidyEligibilityFilter,
        inProgressStatusFilter,
        setInProgressStatusFilter,
        centers: centers ?? [],
        noteModalState,
        setNoteModalState,
        enrolmentFormDiffData,
        setEnrolmentFormDiffData,
        isEnrolmentFormDiffModalOpen,
        setIsEnrolmentFormDiffModalOpen,
        onlyShowUpdatedForms,
        setOnlyShowUpdatedForms,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};

export const useLeadsContext = () => useContext(LeadsContext)!;
