import React from 'react';
import { getFullName, getInitials } from 'shared/util/string';
import Avatar from 'shared/components/Avatar';
import Select from 'shared/components/Select';
import { useGetBasicStaffForCenter } from 'pages/Centers/subroutes/Class/graphql/queries';

type Props = {
  label: string;
  disabled?: boolean;
  centerId: string;
  showAvatar?: boolean;
  value: IBasicStaff[];
  onChange: (values: IBasicStaff[]) => void;
};

export default function StaffSelectInput({
  label,
  disabled = false,
  centerId,
  showAvatar = true,
  value,
  onChange,
  ...props
}: Props) {
  const { data: staffForCenter, loading: fetchingStaff } = useGetBasicStaffForCenter(centerId);

  return (
    <Select
      isMulti
      disabled={disabled}
      closeMenuOnSelect
      label={label}
      value={value.length ? value : null}
      isLoading={fetchingStaff}
      options={staffForCenter?.getBasicStaffForCenter.data ?? []}
      onChange={(options: IBasicStaff[]) => onChange(options)}
      getOptionValue={(option: IBasicStaff) => option.id}
      getOptionLabel={(option: IBasicStaff) => getFullName(option)}
      formatOptionLabel={(option: IBasicStaff, { context }: { context: 'menu' | 'value' }) => (
        <div key={`class-staff-assignment-${option.id}`} className="d-flex align-items-center">
          {showAvatar && <Avatar size="sm" image={option.avatar?.url} initials={getInitials(option)} />}
          <div className="d-flex flex-column pl-4 text-truncate">
            <span className="text-truncate">{getFullName(option)}</span>
          </div>
        </div>
      )}
    ></Select>
  );
}
