import { QueryHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

export interface ISearchStaffData {
  searchStaff: {
    totalResults: number;
    data: IStaff[];
  };
}

export interface ISearchStaffVariables {
  input: IElasticsearchQuery;
}

interface IGetPositionsForBusinessVariables {
  businessId: string;
}

interface IGetPositionsForBusinessData {
  getPositionsByBusinessId: IPosition[];
}

// TODO: add positions and tags when available
export const SEARCH_STAFF = gql`
  query ($input: SearchInput!) {
    searchStaff(input: $input) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        email
        avatar {
          url
        }
        address {
          state
          city
        }
        roleship {
          roleId
          name
          scopeType
          scopes {
            ... on Center {
              name
            }
            ... on Entity {
              name
            }
          }
        }
        employmentStatus
        primaryCenter {
          name
          id
        }
        invitedAt
        entityId
        primaryCenterId
        createdAt
        requestedToJoinAt
        employmentStartDate
      }
    }
  }
`;

export const GET_POSITIONS_FOR_BUSINESS = gql`
  query ($businessId: ID!) {
    getPositionsByBusinessId(businessId: $businessId) {
      id
      name
      businessId
    }
  }
`;

export const useSearchStaff = (
  input: IElasticsearchQuery,
  options?: QueryHookOptions<ISearchStaffData, ISearchStaffVariables>
) =>
  useQuery<ISearchStaffData, ISearchStaffVariables>(SEARCH_STAFF, {
    variables: { input },
    ...options,
  });

export const useGetPositionsForBusiness = (businessId: string) =>
  useQuery<IGetPositionsForBusinessData, IGetPositionsForBusinessVariables>(GET_POSITIONS_FOR_BUSINESS, {
    variables: {
      businessId: businessId,
    },
    skip: !businessId || businessId === '',
  });
