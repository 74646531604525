import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { ccssMsgUpdateFields } from './fields';

interface IUpdateCcsServiceMessage {
  updateCcssServiceMessage: ICcsMessage;
}

interface IUpdateCcsProviderMessage {
  updateCcssProviderMessage: ICcsMessage;
}

interface IUpdateCcsServiceMessagesVariables {
  input: ICcsMessagesUpdateInput;
}

interface IUpdateCcsServiceMessagesVariables {
  input: ICcsMessagesUpdateInput;
}

export const UPDATE_PROVIDER_MESSAGE = gql`
  mutation($input: CcssProviderMessageUpdateViewedStatusInput!){
    updateCcssProviderMessage(input: $input) {
      ${ccssMsgUpdateFields}
    }
  }
`;

export const UPDATE_SERVICE_MESSAGE = gql`
  mutation($input: CcssServiceMessageUpdateViewedStatusInput!){
    updateCcssServiceMessage(input: $input) {
      ${ccssMsgUpdateFields}
    }
  }
`;

export const useUpdateProviderMessage = (
  options?: MutationHookOptions<IUpdateCcsProviderMessage, IUpdateCcsServiceMessagesVariables>
) => {
  return useMutation<IUpdateCcsProviderMessage, IUpdateCcsServiceMessagesVariables>(UPDATE_PROVIDER_MESSAGE, options);
};

export const useUpdateServiceMessage = (
  options?: MutationHookOptions<IUpdateCcsServiceMessage, IUpdateCcsServiceMessagesVariables>
) => {
  return useMutation<IUpdateCcsServiceMessage, IUpdateCcsServiceMessagesVariables>(UPDATE_SERVICE_MESSAGE, options);
};
