export const defaultCommentFields = `
 id
 businessId
 attachedToEntityId
 parentId
 text
 createdBy
 createdAt
 replyCount
 createdByAccount{
    firstname
    lastname
    email
    avatar{
        url
    }
 }
`;
