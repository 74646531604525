import { useGetDiscountsForBusiness } from 'gql/discount/queries';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { CirclePlusButton, CreateButton, IconButtonCircle } from 'shared/components/Buttons';
import PageWrapper from 'shared/components/PageWrapper';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { RootState } from 'store/reducers';
import CreateDiscountModal from './CreateDiscountModal';
import DiscountsTable from './DiscountsTable';
import { getDiscountsSuccess } from './duck/actions';
import EditDiscountModal from './EditDiscountModal';
import { useTranslation } from 'react-i18next';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CreateDiscountModalOld from './CreateDiscountModalOld';
import SearchInput from '../../shared/components/SearchInput';
import { DateRangeInputWithArrows } from '../../shared/components/DateInput';
import Select from '../../shared/components/Select';
import moment, { Moment } from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../muiTheme';
import { getDateRangesOverlap } from 'shared/util/timeUtils';
import { discountMatchesActiveStatus, discountMatchesSearchFilter } from './DiscountFilters';
import DropdownFilter from '../../shared/components/Dropdown/DropdownFilter';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import './_discounts.scss';

interface IEditDiscountModalState {
  isOpen: boolean;
  discount: IDiscount | null;
}

const discountMatchesDateRange = (discount: IDiscount, startDate: Moment | null, endDate: Moment | null) => {
  const targetEndDate = endDate ?? moment('2100-01-01');
  const targetStartDate = startDate ?? moment('1900-01-01');
  const targetDateRange = { start: targetStartDate, end: targetEndDate };

  let result = false;
  discount.schedules.forEach((schedule) => {
    const scheduleDateRange = { start: moment(schedule.startDate), end: moment(schedule.endDate) };
    if (getDateRangesOverlap(scheduleDateRange, targetDateRange)) {
      result = true;
      return false;
    }
  });

  return result;
};

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const Discounts: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['billing']);
  const { k2AuDiscounts } = useFlags();

  const [showCreateDiscountModal, setShowCreateDiscountModal] = useState<boolean>(false);
  const [editModalState, setEditModalState] = useState<IEditDiscountModalState>({ isOpen: false, discount: null });
  const user = useSelector((state: RootState) => state.user);
  const discounts = useSelector((state: RootState) => state.discounts.all);
  const centres = useSelector((state: RootState) => state.centers.all);
  const activeBusinessId = useSelector((state: RootState) => state.context.businessId);

  const [startDateFilter, setStartDateFilter] = useState<string | null>();
  const [endDateFilter, setEndDateFilter] = useState<string | null>();

  const { loading: getDiscountsForBusinessLoading } = useGetDiscountsForBusiness({
    variables: {
      businessId: activeBusinessId ?? '',
    },
    onCompleted: (result) => {
      dispatch(getDiscountsSuccess(result.getDiscountsForBusiness));
    },
    skip: !activeBusinessId,
  });

  const methodOptions = [
    { label: 'All', value: null },
    { label: 'Recurring', value: 'RECURRING' },
    { label: 'Manual', value: 'MANUAL' },
  ];

  const typeOptions = [
    { label: 'All Types', value: null },
    { label: 'Session Gap', value: 'SESSION_GAP' },
    { label: 'Fee', value: 'SESSION' },
  ];

  const statusOptions = [
    { label: 'All', value: null },
    { label: 'Active', value: 'Active' },
    { label: 'Scheduled', value: 'Scheduled' },
    { label: 'Ended', value: 'Ended' },
  ];

  const [searchFilter, setSearchFilter] = useState<string | null>();
  const [centreFilter, setCentreFilter] = useState<{ label: string; value: string }[]>([]);
  const [typeFilter, setTypeFilter] = useState<string | null>();
  const [methodFilter, setMethodFilter] = useState<string | null>();
  const [statusFilter, setStatusFilter] = useState<string | null>();

  const clearAppliedFilters = () => {
    setCentreFilter([]);
    setTypeFilter(null);
    setMethodFilter(null);
    setStatusFilter(null);
    setStartDateFilter(null);
    setEndDateFilter(null);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const filterDiscounts = () => {
    let filteredDiscounts = discounts;

    if (typeFilter) {
      filteredDiscounts = filteredDiscounts.filter((discount: IDiscount) => {
        return discount.appliesTo === typeFilter;
      });
    }

    if (methodFilter) {
      filteredDiscounts = filteredDiscounts.filter((discount: IDiscount) => {
        return discount.type === methodFilter;
      });
    }

    if (statusFilter) {
      filteredDiscounts = filteredDiscounts.filter((discount: IDiscount) =>
        discountMatchesActiveStatus(discount, statusFilter)
      );
    }

    if (startDateFilter || endDateFilter) {
      filteredDiscounts = filteredDiscounts.filter((discount: IDiscount) => {
        return discountMatchesDateRange(
          discount,
          startDateFilter ? moment(startDateFilter) : null,
          endDateFilter ? moment(endDateFilter) : null
        );
      });
    }

    if (searchFilter) {
      filteredDiscounts = filteredDiscounts.filter((discount: IDiscount) => {
        return discountMatchesSearchFilter(discount, searchFilter);
      });
    }

    if (centreFilter.length) {
      filteredDiscounts = filteredDiscounts.filter((discount: IDiscount) => {
        if (!discount.centerIds) {
          return true;
        }

        let result = discount.centerIds.filter((centreId) => {
          return centreFilter.map((option) => option.value).indexOf(centreId) !== -1;
        });

        return !!result.length;
      });
    }

    return filteredDiscounts;
  };

  return (
    <PageWrapper
      pageTitle={t('billing:discounts.page-title')}
      applyPadding={true}
      mobileButtonComponent={
        <HasRoleAreaLevel
          action={{
            area: AreaType.Billing,
            permission: PermissionType.DiscountManagement,
            level: RoleLevelType.Create,
          }}
        >
          <CirclePlusButton variant="primary" onClick={() => setShowCreateDiscountModal(true)} />
        </HasRoleAreaLevel>
      }
      buttonComponent={
        <HasRoleAreaLevel
          action={{
            area: AreaType.Billing,
            permission: PermissionType.DiscountManagement,
            level: RoleLevelType.Create,
          }}
        >
          <CreateButton onClick={() => setShowCreateDiscountModal(true)}>
            {t('billing:discounts.create-discount-button')}
          </CreateButton>
        </HasRoleAreaLevel>
      }
    >
      {user?.isInternal && (
        <CenterSelectBanner pageName={t('billing:discounts.internal-user-banner')} showCenterSelect={false} />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          borderRadius: '0.5rem',
          color: '#1F2937',
          marginBottom: '1rem',
          backgroundColor: '#fff',
          padding: '1rem',
          border: '1px solid #EAECF0',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: isMediumScreen ? 'column' : 'row',
            marginBottom: isMobile ? '0.5rem' : '0',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <SearchInput onChange={(newValue: string) => setSearchFilter(newValue)} searchString={searchFilter} />
              <div style={{ marginLeft: '0.5rem', marginRight: '0.5rem', textAlign: 'center', width: '12rem' }}>
                <DateRangeInputWithArrows
                  required
                  className="kt-date-input-max-width"
                  startDate={startDateFilter}
                  endDate={endDateFilter}
                  dateOnly={true}
                  isOutsideRange={() => false}
                  onChange={(dates) => {
                    setStartDateFilter(dates.startDate || undefined);
                    setEndDateFilter(dates.endDate || undefined);
                  }}
                ></DateRangeInputWithArrows>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginLeft: '1rem', width: isMobile ? '100%' : '12rem', maxWidth: '16rem' }}>
                <Select
                  placeholder={`Method`}
                  title="Method"
                  className={false ? 'my-1 mr-3' : 'mr-3 mb-0'}
                  options={methodOptions.map((option) => ({ label: option.label, value: option.value }))}
                  // value={businessId}
                  value={methodFilter}
                  name="method"
                  onChange={(selectedOption) => setMethodFilter(selectedOption.value)}
                />
              </div>
              <div style={{ marginLeft: '0.5rem', width: isMobile ? '100%' : '12rem', maxWidth: '16rem' }}>
                <Select
                  placeholder={`Type`}
                  title="Type"
                  className={isMobile ? 'my-1 mr-3' : 'mr-3 mb-0'}
                  options={typeOptions.map((option) => ({ label: option.label, value: option.value }))}
                  // value={businessId}
                  value={typeFilter}
                  name="type"
                  onChange={(selectedOption) => setTypeFilter(selectedOption.value)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: isMobile ? 'space-between' : 'flex-end',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginLeft: isMobile ? '0' : '0.5rem', width: '12rem' }}>
                <Select
                  title="Status"
                  placeholder={`Status`}
                  // className={isMobile ? 'my-1 mr-3' : 'mr-3 mb-0'}
                  className={false ? 'my-1 mr-3' : 'mr-3 mb-0'}
                  options={statusOptions.map((option) => ({
                    label: option.label,
                    value: option.value,
                  }))}
                  // value={businessId}
                  value={statusFilter}
                  name="status"
                  onChange={(selectedOption) => setStatusFilter(selectedOption.value)}
                />
              </div>
              <div style={{ marginLeft: '0.5rem', width: '12rem' }}>
                <DropdownFilter
                  title={'Centre'}
                  selectedFilters={centreFilter}
                  options={centres?.map((c) => ({ label: c.name, value: c.id })) ?? []}
                  onFilterSelect={(selectedOptions) => setCentreFilter(selectedOptions)}
                />
              </div>
            </div>
            <IconButtonCircle
              icon={faTimes}
              className={`ml-2`}
              onClick={clearAppliedFilters}
              tooltipDirection="bottom"
              tooltipText="Clear Filters"
            />
          </div>
        </div>
      </div>

      <DiscountsTable
        data={filterDiscounts()}
        loading={getDiscountsForBusinessLoading}
        onEditDiscount={(discount) => setEditModalState({ isOpen: true, discount })}
      />

      {k2AuDiscounts && (
        <CreateDiscountModal
          isOpen={showCreateDiscountModal}
          businessId={activeBusinessId ?? ''}
          onClose={() => setShowCreateDiscountModal(false)}
        />
      )}

      {!k2AuDiscounts && (
        <CreateDiscountModalOld
          isOpen={showCreateDiscountModal}
          businessId={activeBusinessId ?? ''}
          onClose={() => setShowCreateDiscountModal(false)}
        />
      )}

      {editModalState.discount && (
        <EditDiscountModal
          isOpen={editModalState.isOpen}
          discount={editModalState.discount}
          onClose={() => setEditModalState({ isOpen: false, discount: null })}
        />
      )}
    </PageWrapper>
  );
};

export default Discounts;
