import { gql } from '@apollo/client';

export const GET_ALLOWED_ENTITIES = (isInternal = false) => {
  const bizPaymentFields = isInternal
    ? ``
    : `primaryPaymentMethodId
        primaryPaymentMethod {
          id
          token
          processorId
          expirationDate
          lastFour
        }
        payrixCustomerId`;

  return gql`
    query {
      getAllowedEntities {
        id
        name
        timezone
        centers {
          id
          name
          entityId
          deactivatedAt
          spaces {
            id
            name
          }
          address {
            state
          }
          timezone
        }
        ${bizPaymentFields}
        isGlCodeMandatory
        applicationFlowType
      }
    }
  `;
};
