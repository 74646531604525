import React, { useCallback } from 'react';
import { IconButtonCircle } from 'shared/components/Buttons';
import { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Select from 'shared/components/Select';
import Switch from 'shared/components/Switch';
import { ITableFilters } from '../../DocumentsTab';
import { capitalize, getFullName } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { documentTypeOptions } from 'shared/constants/dropdownOptions/documentType';
import './documentTableHeader.scss';

interface IProps {
  paginationProps: any;
  filters: ITableFilters;
  contacts: IPerson[];
  setFilters: React.Dispatch<React.SetStateAction<ITableFilters>>;
}

const DocumentsTableHeader: React.FC<IProps> = ({ paginationProps, filters, contacts, setFilters }) => {
  const { t } = useTranslation(['accounts', 'translation']);
  const clearFilters = () => {
    setFilters({
      searchText: '',
      person: null,
      type: null,
      showArchived: false,
    });
  };

  return (
    <div>
      <TableHeader className="flex-wrap justify-content-between">
        <div className="d-flex">
          <SizePerPage paginationProps={paginationProps} />
          <TableSearch
            className="max-width-200 m-0"
            onChange={(text) => {
              setFilters({ ...filters, searchText: text });
            }}
          />
        </div>

        <div className="d-flex flex-direction-row align-items-center">
          <Select
            placeholder={capitalize(t('translation:spelling.type'))}
            className="ml-2 mb-0 document-filter-select"
            value={filters.type}
            selectedFilters={[]}
            options={documentTypeOptions}
            onChange={(option) => {
              setFilters({ ...filters, type: option.value });
            }}
          />
          <Select
            placeholder={t('accounts:documents.associated-with')}
            className="ml-2 mb-0 document-filter-select"
            value={filters.person}
            options={contacts.map((c) => ({
              label: getFullName(c),
              value: c.id,
            }))}
            onChange={(option) => {
              setFilters({ ...filters, person: option.value });
            }}
          />
          <IconButtonCircle
            icon={faTimes}
            onClick={clearFilters}
            tooltipDirection="bottom"
            tooltipText={t('translation:general.clear-filter')}
            className="mx-4 my-2"
          />
        </div>
      </TableHeader>
      <div className="d-flex align-items-center justify-content-end mt-4 mb-2">
        <p className="mb-0 mr-2">{t('accounts:documents.show-archived')}</p>
        <Switch
          value={filters.showArchived}
          onChange={(value) => {
            setFilters({ ...filters, showArchived: value });
          }}
        />
      </div>
    </div>
  );
};

export default DocumentsTableHeader;
