import React, { useState, useCallback, Fragment } from 'react';
import { Col, Row } from 'shared/components/Layout';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGetServiceFeeGroupById } from 'gql/serviceFee/queries';
import { useSaveServiceFeeGroup } from 'gql/serviceFee/mutations';
import PageWrapper from 'shared/components/PageWrapper';
import Button, { CirclePlusButton } from 'shared/components/Buttons';
import ServiceFeeGroupInformation from './components/ServiceFeeGroupInformation';
import ServiceFeeGroupCenters from './components/ServiceFeeGroupCenters';
import { useSearchCenters } from 'gql/center/queries';
import { getCentersSuccess } from '../../../../Centers/duck/actions';
import { SEARCH_MY_CENTERS } from '../../../../Centers/subroutes/Profiles/graphql/fields';
import useDatatableState from 'shared/hooks/useDatatableState';
import ServiceFeeManagement from './components/ServiceFeeManagement';
import moment from 'moment';
import { isBlank } from 'shared/util/string';
import { showToast } from 'shared/components/Toast';
import { omitTypename } from 'shared/util/object';
import cast from 'shared/util/cast';

interface IRouteProps {
  id?: string;
}

interface IProps
  extends RouteComponentProps<
    {
      id?: string;
    },
    any,
    IRouteProps
  > {}

const ServiceFeeGroup: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const serviceFeeGroupId = props.match.params.id ?? '';
  const [newServiceFeeId, setNewServiceFeeId] = useState(0);

  const initialTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('center', initialTableSort);
  const { loading: centersLoading, data: centersData } = useSearchCenters(
    {
      variables: {
        input: {
          filter: { all: [] },
          sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
          from: 0,
          size: 10000,
        },
      },
      onCompleted: (data) => {
        dispatch(getCentersSuccess(data.searchCenters.data ?? []));
      },
    },
    SEARCH_MY_CENTERS
  );

  const { loading } = useGetServiceFeeGroupById({
    variables: {
      id: serviceFeeGroupId,
    },
    onCompleted: (result) => {
      setFormData({
        id: result.getServiceFeeGroupById.id,
        name: result.getServiceFeeGroupById.name,
        description: result.getServiceFeeGroupById.description,
        assignedCenters: handleGroupCenters(result.getServiceFeeGroupById.serviceFeeGroupCenters),
        serviceFees: result.getServiceFeeGroupById.serviceFees,
      });
    },
  });

  const handleGroupCenters = (value: IServiceFeeGroupCenter[]) => {
    let centers: ICenter[] = [];
    value.forEach((groupCenter) => centers.push(groupCenter.center));

    return centers;
  };

  const [formData, setFormData] = useState<IServiceFeeGroupForm>({
    id: null,
    name: '',
    assignedCenters: [],
    serviceFees: [],
  });

  const updateCenters = (value: ICenter[]) => {
    setFormData({ ...formData, assignedCenters: value === null ? [] : value });
  };

  const addServiceFee = () => {
    let serviceFees: IServiceFee[] = [...formData.serviceFees];

    const newServiceFee: IServiceFee = {
      id: newServiceFeeId.toString(),
      name: '',
      serviceFeeType: 'CC' as ServiceFeeType,
      amount: 0,
      calculationType: 'FLAT' as CalculationType,
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      active: true,
      serviceFeeRuleGroups: [],
      isNew: true,
    };

    serviceFees.push(newServiceFee);

    setNewServiceFeeId(newServiceFeeId + 1);

    setFormData({ ...formData, serviceFees: serviceFees });
  };

  const updateServiceFee = (value: IServiceFee) => {
    let serviceFees: IServiceFee[] = [...formData.serviceFees];

    let changingServiceFee = serviceFees.find((sf) => sf.id === value.id);
    if (changingServiceFee) {
      Object.assign(changingServiceFee, value);

      setFormData({ ...formData, serviceFees: serviceFees });
    }
  };

  const deleteServiceFee = (value: IServiceFee) => {
    let serviceFees: IServiceFee[] = [...formData.serviceFees];

    let deletingServiceFeeIndex = serviceFees.findIndex((sf) => sf.id === value.id);
    if (deletingServiceFeeIndex !== -1) {
      serviceFees.splice(deletingServiceFeeIndex, 1);

      setFormData({ ...formData, serviceFees: serviceFees });
    }
  };

  const addServiceFeeRuleGroup = (serviceFee: IServiceFee, parentGroup: IServiceFeeRuleGroup | undefined) => {
    let serviceFees: IServiceFee[] = [...formData.serviceFees];

    const newServiceFeeRuleGroup: IServiceFeeRuleGroup = {
      id: newServiceFeeId.toString(),
      matchType: 'ALL' as MatchType,
      serviceFeeRuleGroupParentId: parentGroup ? parentGroup.id : undefined,
      serviceFeeRuleGroupParent: parentGroup ? parentGroup : undefined,
      serviceFeeRuleGroups: [],
      serviceFeeRules: [],
      isNew: true,
    };

    if (!parentGroup) {
      serviceFee.serviceFeeRuleGroups.push(newServiceFeeRuleGroup);
    } else {
      parentGroup.serviceFeeRuleGroups.push(newServiceFeeRuleGroup);
    }

    setNewServiceFeeId(newServiceFeeId + 1);

    setFormData({ ...formData, serviceFees: serviceFees });
  };

  const updateServiceFeeRuleGroup = (serviceFee: IServiceFee, updateGroup: IServiceFeeRuleGroup) => {
    let serviceFees: IServiceFee[] = [...formData.serviceFees];

    let changingServiceFee = serviceFees.find((sf) => sf.id === serviceFee.id);
    if (changingServiceFee) {
      let rg = findRuleGroupInTree(updateGroup, changingServiceFee.serviceFeeRuleGroups);
      if (rg) {
        Object.assign(rg, updateGroup);

        setFormData({ ...formData, serviceFees: serviceFees });
      }
    }
  };

  const findRuleGroupInTree = (search: IServiceFeeRuleGroup, tree: IServiceFeeRuleGroup[]) => {
    let ruleGroup = tree.find((sfrg) => sfrg.id === search.id);
    if (ruleGroup) {
      return ruleGroup;
    }

    tree.forEach((rg) => {
      if (rg.serviceFeeRuleGroups.length > 0) {
        let ruleGroup = findRuleGroupInTree(search, rg.serviceFeeRuleGroups);
        if (ruleGroup) {
          return ruleGroup;
        }
      }
    });

    return undefined;
  };

  const deleteServiceFeeRuleGroup = (
    serviceFee: IServiceFee,
    deleteGroup: IServiceFeeRuleGroup,
    parentGroup: IServiceFeeRuleGroup | undefined
  ) => {
    let serviceFees: IServiceFee[] = [...formData.serviceFees];

    if (!parentGroup) {
      let index = serviceFee.serviceFeeRuleGroups.findIndex((sfrg) => sfrg.id === deleteGroup.id);
      if (index !== -1) {
        serviceFee.serviceFeeRuleGroups.splice(index, 1);
      }
    } else {
      let index = parentGroup.serviceFeeRuleGroups.findIndex((sfrg) => sfrg.id === deleteGroup.id);
      if (index !== -1) {
        parentGroup.serviceFeeRuleGroups.splice(index, 1);
      }
    }

    setFormData({ ...formData, serviceFees: serviceFees });
  };

  const addServiceFeeRule = (parentGroup: IServiceFeeRuleGroup) => {
    let serviceFees: IServiceFee[] = [...formData.serviceFees];

    const newServiceFeeRule: IServiceFeeRule = {
      id: newServiceFeeId.toString(),
      serviceFeeRuleGroupId: parentGroup.id,
      condition: 'EQUAL' as ConditionType,
      value: '0',
      isNew: true,
    };

    if (parentGroup) {
      parentGroup.serviceFeeRules.push(newServiceFeeRule);

      setNewServiceFeeId(newServiceFeeId + 1);

      setFormData({ ...formData, serviceFees: serviceFees });
    }
  };

  const updateServiceFeeRule = (
    serviceFee: IServiceFee,
    updateRule: IServiceFeeRule,
    parentGroup: IServiceFeeRuleGroup
  ) => {
    let serviceFees: IServiceFee[] = [...formData.serviceFees];

    let changingRule = parentGroup.serviceFeeRules.find((sfr) => sfr.id === updateRule.id);
    if (changingRule) {
      Object.assign(changingRule, updateRule);

      setFormData({ ...formData, serviceFees: serviceFees });
    }
  };

  const deleteServiceFeeRule = (deleteRule: IServiceFeeRule, parentGroup: IServiceFeeRuleGroup) => {
    let serviceFees: IServiceFee[] = [...formData.serviceFees];

    let index = parentGroup.serviceFeeRules.findIndex((sfr) => sfr.id === deleteRule.id);
    if (index !== -1) {
      parentGroup.serviceFeeRules.splice(index, 1);
    }

    setFormData({ ...formData, serviceFees: serviceFees });
  };

  const isEdit = !!formData.id;

  const validGroup = (group: IServiceFeeRuleGroup) => {
    let valid = true;

    group.serviceFeeRuleGroups.forEach((ruleGroup) => {
      if (!validGroup(ruleGroup)) {
        valid = false;
      }
    });

    group.serviceFeeRules.forEach((rule) => {
      if (!validRule(rule)) {
        valid = false;
      }
    });

    return valid;
  };

  const validRule = (rule: IServiceFeeRule) => {
    return rule.value || (rule.value !== undefined && rule.value.toString().length >= 0);
  };

  const validServiceFeeForm = useCallback(() => {
    let valid = true;

    // Check the base service fee
    valid = !isBlank(formData.name);

    // Check all groups
    formData.serviceFees.forEach((serviceFee) => {
      if (
        isBlank(serviceFee.name) ||
        !serviceFee.name ||
        (!serviceFee.amount && serviceFee.amount !== 0) ||
        isBlank(serviceFee.startDate) ||
        !serviceFee.startDate
      ) {
        valid = false;
      }

      serviceFee.serviceFeeRuleGroups.forEach((ruleGroup) => (!validGroup(ruleGroup) ? (valid = false) : valid));
    });

    return valid;
  }, [formData, validGroup]);

  const [saveServiceFeeGroupFn, { loading: saveServiceFeeGroupLoading }] = useSaveServiceFeeGroup();
  const saveServiceFeeGroup = useCallback(
    (formData: IServiceFeeGroupForm) => {
      const input: ISaveServiceFeeGroupInput = {
        id: formData.id,
        name: formData.name,
        description: formData.description,
        assignedCenters: formData.assignedCenters.map((center) => center.id),
        serviceFees: formData.serviceFees,
      };

      saveServiceFeeGroupFn({
        variables: {
          input: cast<ISaveServiceFeeGroupInput>(omitTypename(input, true)),
        },
      })
        .then(() => {
          showToast(t('service-fee.save-service-fee.success-message'), 'success');
          history.replace('/settings/service-fees');
        })
        .catch((error: any) => {
          showToast(
            `${error.graphQLErrors
              .map((err: any) => {
                return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
              })
              .join(', ')}`,
            'error'
          );
        });
    },
    [formData]
  );

  return (
    <PageWrapper
      pageTitle={`${isEdit ? `Edit ${formData.name}` : loading ? `` : `Add New Service Fee Group`}`}
      mobileButtonComponent={
        <CirclePlusButton
          variant="primary"
          className="mt-4 mb-4"
          onClick={() => history.push('/settings/service-fees/new')}
        />
      }
      buttonComponent={
        <Button
          disabled={!validServiceFeeForm()}
          onClick={() => saveServiceFeeGroup(formData)}
          loading={saveServiceFeeGroupLoading}
        >
          Save
        </Button>
      }
      secondaryButtonComponent={
        <Button variant="light" className="mr-4" onClick={() => history.goBack()}>
          Cancel
        </Button>
      }
    >
      <Fragment>
        <Row className="mb-4">
          <Col xl={8}>
            <ServiceFeeGroupInformation _serviceFeeGroup={formData} updateGroup={setFormData} isEdit={isEdit} />
          </Col>
          <Col>
            <ServiceFeeGroupCenters
              centerOptions={centersData?.searchCenters.data ?? []}
              assignedCenters={formData.assignedCenters}
              fetchingCenters={centersLoading}
              onUpdate={updateCenters}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <ServiceFeeManagement
              _serviceFeeGroup={formData}
              onAdd={addServiceFee}
              onUpdate={updateServiceFee}
              onDelete={deleteServiceFee}
              onAddRuleGroup={addServiceFeeRuleGroup}
              onUpdateRuleGroup={updateServiceFeeRuleGroup}
              onDeleteRuleGroup={deleteServiceFeeRuleGroup}
              onAddRule={addServiceFeeRule}
              onUpdateRule={updateServiceFeeRule}
              onDeleteRule={deleteServiceFeeRule}
            />
          </Col>
        </Row>
      </Fragment>
    </PageWrapper>
  );
};

export default ServiceFeeGroup;
