import React from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { IconButtonCircle } from 'shared/components/Buttons';
import { TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { messageStatusFilters } from 'shared/constants/enums/ccssMessageEnums';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface ITHeadProps {
  centers: ICenter[];
  activeFilters: IISMessagesFilter;
  onActiveFilterChange: (value: IISMessagesFilter) => void;
  clearFilters: () => void;
  handleSearchChange: (str: string) => void;
  handleCenterChange: (option: any) => void;
  handleStatusChange: (option: any) => void;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const ISMessagesTableHeader: React.FC<ITHeadProps> = ({
  activeFilters,
  centers,
  clearFilters,
  handleSearchChange,
  handleStatusChange,
  handleCenterChange,
}) => {
  const statusOptions = messageStatusFilters.slice(1);

  return (
    <TableHeader className="flex-wrap messages-table-header">
      <TableSearch onChange={handleSearchChange} className="mr-4" />

      <div className="d-flex justify-content-between align-items-center right-filter">
        <DropdownFilter
          dropdownId="viewedStatusFilter"
          title="Status"
          placeholder="Status"
          options={statusOptions}
          onFilterSelect={handleStatusChange}
          className="mr-4 my-2 sourceFilter"
          selectedFilters={activeFilters.statuses || []}
        />

        <DropdownFilter
          dropdownId="centerFilter"
          title={fieldLabels.center}
          placeholder={fieldLabels.center}
          options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
          onFilterSelect={handleCenterChange}
          className="mr-4 my-2"
          selectedFilters={activeFilters.centers || []}
        />
        <IconButtonCircle
          icon={faTimes}
          onClick={clearFilters}
          tooltipDirection="bottom"
          tooltipText="Clear Filters"
          className="mr-4 my-2"
        />
      </div>
    </TableHeader>
  );
};

export default ISMessagesTableHeader;
