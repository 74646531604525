import React from 'react';
import _ from 'lodash';
import * as Mui from '@mui/material';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

import useFormatDate from 'shared/hooks/useFormatDate';
import ViewNoteThread from './ViewNoteThread';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useArchiveInternalAccountNote } from './gql/mutation';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { getFullName } from 'shared/util/string';
import IconButtonCircle from 'shared/components/Buttons/IconButtonCircle';

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
};

interface INote {
  id: string;
  businessId: string;
  areaType: string;
  attachedToEntityId: string;
  title: string;
  noteType: string;
  comment: string;
  createdBy: string;
  createdAt: string;
  associatedWith: INoteAssociation[];
  createdByAccount: IStaff;
  lastCommentedAt: string;
  lastCommentedByAccount: IStaff;
}

interface INoteAssociation {
  objectId: string;
  objectType: NoteAssociationObjectType;
  display?: {
    displayValue: string;
    displayAvatar?: string;
  };
}

type NoteAssociationObjectType = 'Account' | 'AccountChild' | 'AccountContact' | 'Staff';

interface IRowProps {
  index: number;
  row: INote;
  refetch: () => void;
}

const NoteTableRow: React.FC<IRowProps> = ({ index, row, refetch, ...props }) => {
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const formatDate = useFormatDate();
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [viewOpen, setViewOpen] = React.useState(false);
  const [openArchiveConfirm, setOpenArchiveConfirm] = React.useState(false);

  const [archiveNoteFn] = useArchiveInternalAccountNote({
    onCompleted: () => {
      setOpenArchiveConfirm(false);
      showToast('Note Archived', 'success');
      refetch();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onArchiveClick = () => {
    archiveNoteFn({
      variables: {
        input: {
          businessId: businessId,
          noteId: row.id,
          areaType: 'Staff',
        },
      },
    });
  };

  return (
    <>
      <Mui.TableRow key={row.title}>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">{row.title}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">{row.noteType}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">
            {formatDate(row.lastCommentedAt ?? row.createdAt, 'MM/DD/YYYY-timestamp')}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left">
          <Mui.Typography display="flex" alignItems="center">
            <AvatarDataTableCell
              initials={
                row.lastCommentedByAccount != null
                  ? row.lastCommentedByAccount.firstname.substring(0, 1)
                  : row.createdByAccount != null
                  ? row.createdByAccount.firstname.substring(0, 1)
                  : ''
              }
              avatar={
                row.lastCommentedByAccount != null
                  ? row.lastCommentedByAccount.avatar?.url ?? ''
                  : row.createdByAccount != null
                  ? row.createdByAccount.avatar?.url ?? ''
                  : ''
              }
              size="sm"
            />
            {row.lastCommentedByAccount != null
              ? getFullName(row.lastCommentedByAccount ?? '')
              : row.createdByAccount != null
              ? getFullName(row.createdByAccount ?? '')
              : ''}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="right">
          <>
            <IconButtonCircle
              id="action-dropdown-btn"
              iconSize="2x"
              icon={faEllipsisH}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <Mui.Menu
              PaperProps={{ sx: { boxShadow: '1' } }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <Mui.MenuItem
                onClick={() => {
                  setViewOpen(true);
                  setAnchorEl(null);
                }}
              >
                View Note Thread
              </Mui.MenuItem>
              <HasRoleAreaLevel
                action={{
                  area: AreaType.Staff,
                  permission: PermissionType.Notes,
                  level: RoleLevelType.Delete,
                }}
              >
                <Mui.MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setOpenArchiveConfirm(true);
                  }}
                >
                  Archive Note Thread
                </Mui.MenuItem>
              </HasRoleAreaLevel>
            </Mui.Menu>
          </>
        </Mui.TableCell>
      </Mui.TableRow>
      <ViewNoteThread note={row} open={viewOpen} setOpen={setViewOpen} reloadNotes={refetch} />
      <Mui.Dialog open={openArchiveConfirm} onClose={() => setOpenArchiveConfirm(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography variant="dialogTitle">Archive Note</Mui.Typography>
          <Mui.IconButton onClick={() => setOpenArchiveConfirm(false)} disableRipple sx={styles.closeIcon}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>{`Are you sure you would like to archive ${row.title}?`}</Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setOpenArchiveConfirm(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button variant="contained" color="secondary" onClick={() => onArchiveClick()}>
            Confirm
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default NoteTableRow;
