import React from 'react';
import * as Mui from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useArchiveTransactionType } from 'gql/transaction/mutations';
import { archiveTransactionTypes } from 'pages/Billing/Settings/duck/actions';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { RootState } from 'store/reducers';
import { showToast } from 'shared/components/Toast';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { IconButtonCircle } from 'shared/components/Buttons';
import EditTransactionType from './EditTransactionType';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { isRegion } from 'shared/util/region';
import { CurrencyTrans } from 'shared/components/Currency';

interface ITransactionType {
  id?: string;
  archivedAt: string | null;
  areaType?: string;
  attachedToEntityId?: string;
  name?: string;
  noteType?: string;
  center?: ICenter | null;
  glCode: string | null;
  glCodeMapping?: IGLCodeMapping | null;
  comment?: string;
  createdBy?: string;
  createdAt?: string;
  createdByAccount?: IStaff;
  isCredit?: boolean;
  isDebit?: boolean;
  isTuition?: boolean;
  authorisedRoles?: string[];
  isDefaultType: boolean;
}

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
};

interface IRowProps {
  index: number;
  row: ITransactionType;
  refetch: () => void;
  canViewBusinessLevelTransactionTypes: boolean;
}

const BillingTransactionTableRow: React.FC<IRowProps> = ({
  index,
  row,
  refetch,
  canViewBusinessLevelTransactionTypes,
  ...props
}) => {
  const { t } = useTranslation();
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [editModal, setEditModal] = React.useState(false);
  const [openArchiveConfirm, setOpenArchiveConfirm] = React.useState(false);

  const canEditTransactionTypes = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.TransactionTypes,
    level: RoleLevelType.Edit,
  });
  const canArchiveTransactionTypes = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.TransactionTypes,
    level: RoleLevelType.Delete,
  });

  const editAndArchivePermissions =
    (canViewBusinessLevelTransactionTypes && canEditTransactionTypes && row.center === null) ||
    (canEditTransactionTypes && row.center !== null);

  const dispatch = useDispatch();
  const [archiveTransactionTypeFn, { loading: archiveTransactionTypeLoading }] = useArchiveTransactionType({
    onCompleted: (result) => {
      if (result?.archiveTransactionType) {
        setOpenArchiveConfirm(false);
        showToast(`Transaction type archived successfully.`, 'success');
        dispatch(archiveTransactionTypes(result.archiveTransactionType));
        refetch();
      }
    },
    onError: (error) => {
      setOpenArchiveConfirm(false);
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleArchiveSubmit = React.useCallback(
    (id: string) => {
      if (id) {
        archiveTransactionTypeFn({
          variables: {
            id,
          },
        });
      }
    },
    [archiveTransactionTypeFn]
  );

  const statusText = () => {
    if (row.isDefaultType) {
      return 'System Default';
    } else {
      if (row.archivedAt == null) {
        return 'Active';
      } else {
        return 'Archived';
      }
    }
  };

  return (
    <>
      <Mui.TableRow key={'row.title'}>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">{row.name}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">
            {row.center?.name ?? `All ${capitalize(t('spelling.center_plural'))}`}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">{statusText()}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">
            <CurrencyTrans>{row.isCredit ? 'Credit' : 'Debit'}</CurrencyTrans>
          </Mui.Typography>
        </Mui.TableCell>
        {isRegion('US') && (
          <Mui.TableCell align="left">
            <Mui.Typography variant="body1">{row.isTuition ? 'Yes' : 'No'}</Mui.Typography>
          </Mui.TableCell>
        )}
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">
            {row.authorisedRoles?.length === 0 ? 'All Roles' : `${row.authorisedRoles?.length} roles`}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left">
          {row.glCodeMapping ? (
            <Mui.Typography variant="body1">
              {row.glCodeMapping?.glCodeCode ?? ''} - {row.glCodeMapping?.glCodeName ?? ''}
            </Mui.Typography>
          ) : (
            <Mui.Typography variant="body1" className="text-danger">
              <FontAwesomeIcon icon={faInfoCircle} color="#B81111" size="1x" className="mr-2" />
              Not Set
            </Mui.Typography>
          )}
        </Mui.TableCell>
        {/* Permissions need to be set for individual centers as well */}
        {row.archivedAt === null && editAndArchivePermissions && (
          <Mui.TableCell align="right">
            <>
              <IconButtonCircle
                id="action-dropdown-btn"
                iconSize="2x"
                icon={faEllipsisH}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              />
              <Mui.Menu
                PaperProps={{ sx: { boxShadow: '1' } }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <Mui.MenuItem
                  onClick={() => {
                    setEditModal(true);
                    setAnchorEl(null);
                  }}
                >
                  Edit
                </Mui.MenuItem>
                {!row.isDefaultType && canArchiveTransactionTypes && (
                  <HasRoleAreaLevel
                    action={{
                      area: AreaType.Staff,
                      permission: PermissionType.Notes,
                      level: RoleLevelType.Delete,
                    }}
                  >
                    <Mui.MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setOpenArchiveConfirm(true);
                      }}
                    >
                      Archive
                    </Mui.MenuItem>
                  </HasRoleAreaLevel>
                )}
              </Mui.Menu>
            </>
          </Mui.TableCell>
        )}
      </Mui.TableRow>
      <EditTransactionType
        transactionType={row}
        transactionTypeId={row.id!}
        isOpen={editModal}
        businessId={businessId}
        centerId={row.center?.id ?? ''}
        onClose={() => setEditModal(false)}
        refetch={() => refetch}
      />
      <Mui.Dialog open={openArchiveConfirm} onClose={() => setOpenArchiveConfirm(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography
            variant="dialogTitle"
            display="flex"
            fontWeight={700}
            fontSize="1.75rem"
            component="span"
            sx={{ alignItems: 'center' }}
          >
            Archive Transaction Type
          </Mui.Typography>
          <Mui.IconButton sx={styles.closeIcon} disableRipple onClick={() => setOpenArchiveConfirm(false)}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>
          {`Are you sure you would like to archive ${row.name}?`} <br />
          <br /> This action cannot be undone.
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setOpenArchiveConfirm(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button variant="contained" color="secondary" onClick={() => handleArchiveSubmit(row.id ?? '')}>
            Confirm
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default BillingTransactionTableRow;
