import { MutationHookOptions, MutationTuple } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { InputVariable } from '../program/mutation';
import { ApolloError } from '@apollo/client';
import { showToast } from 'shared/components/Toast';

export const MUTATE_PROGRAM_CHILD_CREATE_GQL = gql`
  mutation ($input: ProgramChildAddInput!) {
    addChildrenToProgram(input: $input)
  }
`;

interface IProgramChildCreateData {
  addChildrenToProgram: boolean;
}

interface IProgramChildAddVariables {
  input: IProgramChildAddInput;
}

export const useProgramChildCreate = (
  options?: MutationHookOptions<IProgramChildCreateData, IProgramChildAddVariables>
) => useMutation<IProgramChildCreateData, IProgramChildAddVariables>(MUTATE_PROGRAM_CHILD_CREATE_GQL, options);

export const MUTATE_PROGRAM_CHILD_UPDATE_GQL = gql`
  mutation ($input: ProgramChildUpdateInput!) {
    updateChildProgram(input: $input)
  }
`;

interface IUpdateChildProgramData {
  updateChildProgram: boolean;
}

interface IUpdateChildProgramVariables {
  input: IProgramChildUpdateInput;
}

export const useUpdateChildProgram = (
  options?: MutationHookOptions<IUpdateChildProgramData, IUpdateChildProgramVariables>
) => useMutation<IUpdateChildProgramData, IUpdateChildProgramVariables>(MUTATE_PROGRAM_CHILD_UPDATE_GQL, options);

//region ARCHIVE_CHILD
const ARCHIVE_CHILD = () => gql`
  mutation ($input: ProgramArchiveChildInput!) {
    archiveChild(input: $input)
  }
`;

const ARCHIVE_CHILD_PROGRAM = () => gql`
  mutation ($input: ProgramChildArchiveInput!) {
    archiveChildProgram(input: $input)
  }
`;

export interface IArchiveChildData {
  archiveChild: boolean;
}

export interface IArchiveProgramChildData {
  archiveChildProgram: boolean;
}

export interface IProgramArchiveChildInput extends TenantAware {
  programId: string;
  childId: string;
  accountId: string;
}

export interface IProgramArchiveProgramChildInput {
  programChildId: string;
}

export const useArchiveChild = (
  options?: MutationHookOptions<IArchiveChildData, InputVariable<IProgramArchiveChildInput>>
): MutationTuple<IArchiveChildData, InputVariable<IProgramArchiveChildInput>> =>
  useMutation<IArchiveChildData, InputVariable<IProgramArchiveChildInput>>(ARCHIVE_CHILD(), {
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });

export const useArchiveChildProgram = (
  options?: MutationHookOptions<IArchiveProgramChildData, InputVariable<IProgramArchiveProgramChildInput>>
): MutationTuple<IArchiveProgramChildData, InputVariable<IProgramArchiveProgramChildInput>> =>
  useMutation<IArchiveProgramChildData, InputVariable<IProgramArchiveProgramChildInput>>(ARCHIVE_CHILD_PROGRAM(), {
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });
//endregion

//region Archive_Program
const Archive_Program = () => gql`
  mutation ($input: ProgramArchiveProgramInput!) {
    archiveProgram(input: $input)
  }
`;

export interface IArchiveProgramData {
  archiveProgram: boolean;
}

export interface IProgramArchiveProgramInput extends TenantAware {
  programId: string;
}

export const useArchiveProgram = (
  options?: MutationHookOptions<IArchiveProgramData, InputVariable<IProgramArchiveProgramInput>>
): MutationTuple<IArchiveProgramData, InputVariable<IProgramArchiveProgramInput>> =>
  useMutation<IArchiveProgramData, InputVariable<IProgramArchiveProgramInput>>(Archive_Program(), {
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });
//endregion
