import { Box, Stack, Grid, Alert, AlertTitle, Link } from '@mui/material';
import React from 'react';
import ActivityTable from './ActivityTable';
import PendingPaymentsTable from './PendingPaymentsTable';
import LatestBillCard from './LatestBillCard';
import NextBillCard from './NextBillCard';
import RowOverview from './RowOverview/RowOverview';
import { useGetBillingAccountOverviewV3 } from './queries/useGetBillingAccountOverviewV3';
import './styles.scss';

interface IProps {
  accountId: string;
}

export interface IRowType {
  id: string;
  type: 'ScheduledPayment' | 'Payment' | 'Credit' | 'Bill' | 'Refund';
}

const History: React.FC<IProps> = ({ accountId }) => {
  const [selectedRow, setSelectedRow] = React.useState<IRowType | null>(null);
  const [take, setTake] = React.useState(50);

  const handleUpdateSelectedRow = React.useCallback(
    (newRow: IRowType) => {
      if (selectedRow && selectedRow.id === newRow.id) {
        setSelectedRow(null);
      } else {
        setSelectedRow(newRow);
      }
    },
    [selectedRow]
  );

  const { data, loading } = useGetBillingAccountOverviewV3({ accountId, take });

  const handleLoadMore = React.useCallback(() => {
    setTake(take + 50);
  }, [take]);

  //clear selected row when accountId changes
  React.useEffect(() => setSelectedRow(null), [accountId]);

  return (
    <>
      <Alert severity="info" sx={{ mb: 2, border: '1px solid #bce5ff', borderRadius: '8px' }}>
        <Box maxWidth="800px">
          <AlertTitle>About This Page</AlertTitle>
          Review upcoming and historical billing and payments on this account.{' '}
          <strong>
            Have suggestions?
            <Link id="account-billing-overview-v3-billing-history-pendo-feedback" sx={{ ml: 0.5, cursor: 'pointer' }}>
              Send us feedback about this page.
            </Link>
          </strong>
        </Box>
      </Alert>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} marginBottom={2}>
        <LatestBillCard accountId={accountId} />
        <NextBillCard accountId={accountId} />
      </Stack>
      <Grid container spacing={2} className="overviewV3" sx={{ height: '100%' }}>
        <Grid item xs={8}>
          <Box width="100%">
            <PendingPaymentsTable
              handleUpdateSelectedRow={handleUpdateSelectedRow}
              loading={loading}
              scheduledPayments={data?.scheduledPayments ?? []}
              selectedRow={selectedRow}
            />
            <ActivityTable
              selectedRow={selectedRow}
              handleUpdateSelectedRow={handleUpdateSelectedRow}
              billingActivityItems={data?.history?.activities ?? []}
              totalItems={data?.history.totalItems ?? 0}
              take={take}
              handleLoadMore={handleLoadMore}
              loading={loading}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ alignSelf: 'flex-start', position: 'sticky', top: '150px' }}>
          <Box width="100%" sx={{ marginTop: 7 }}>
            <RowOverview selectedRow={selectedRow} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default History;
