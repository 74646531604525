import moment from 'moment';
import React from 'react';
interface IProps {
  data: IISCase;
}

const ISCaseMaxHoursTable: React.FC<IProps> = ({ data }) => {
  const { daysOfCare } = data;
  return (
    <>
      <h6 className="font-weight-normal mb-4 mt-1">Maximum hours per day</h6>
      <table className="table-simple" id="maximum-hours-table">
        <tbody>
          {daysOfCare.map(({ dayOfCare, hours }) => (
            <tr key={dayOfCare}>
              <td>{dayOfCare}</td>
              <td>{moment.duration(hours).asHours()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ISCaseMaxHoursTable;
