import React from 'react';
import { useSelector } from 'react-redux';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import Switch from 'shared/components/Switch';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import { useGetUserNotificationSubscriptions } from 'gql/notificationSubscription/queries';
import { useUpdateNotificationSubscriptions } from 'gql/notificationSubscription/mutations';
import { getNotificationTypeLabel } from 'shared/constants/enums/notificationTypes';
import { groupBy, sortBy, toPairs } from 'lodash';
import { showToast } from 'shared/components/Toast';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IProps {
  data: IStaff | IPerson | null;
  loading: boolean;
}

const Notifications: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { k2Comms } = useFlags();
  const user = useSelector((state: RootState) => state.user);

  const { data, loading, refetch } = useGetUserNotificationSubscriptions({
    variables: { personId: user?.id ?? '' },
    skip: user === null,
  });

  const hasMessagingPermissions = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.AccountMessaging,
    level: RoleLevelType.Read,
  });

  const [update] = useUpdateNotificationSubscriptions({
    onCompleted: () => {
      refetch();
    },
    onError: () => showToast(t('general.error'), 'error'),
  });

  const subscriptions = data?.userNotificationSubscriptions;
  const handleToggle = (subscription: INotificationSubscription) => {
    const updatedSubscription = {
      type: subscription.type,
      category: subscription.category,
      enabled: !subscription.enabled,
    };
    update({
      variables: {
        personId: user?.id ?? '',
        subscriptions: [updatedSubscription],
      },
    });
  };

  const subscriptionsByCategory = sortBy(toPairs(groupBy(subscriptions, (s) => s.category)), ([category, value]) =>
    t(`notification-v2.categories.${category as NotificationCategoryV2}`)
  );

  const filteredSubscriptionsByCategory = hasMessagingPermissions
    ? subscriptionsByCategory
    : subscriptionsByCategory.filter(([category, types]) => category !== AreaType.Comms);

  const renderList = (types: INotificationSubscription[]) => {
    return (
      <>
        {types.map((subscription: INotificationSubscription) => (
          <div key={subscription.type} className="d-flex align-items-center justify-content-between mb-4">
            <p className="mt-0 mb-0">{getNotificationTypeLabel(subscription.category, subscription.type)}</p>
            <Switch
              onChange={() => {
                handleToggle(subscription);
              }}
              value={subscription.enabled}
              checkedText="On"
              uncheckedText="Off"
            />
          </div>
        ))}
      </>
    );
  };
  return (
    <Row>
      <Col lg={6}>
        <Card header={t('notification-v2.manage-notifications')} loading={loading}>
          {filteredSubscriptionsByCategory.map(([category, types], index, array) => {
            return (
              <div className="mb-6">
                <h6>{t(`notification-v2.categories.${category as NotificationCategoryV2}`)}</h6>
                <div>{renderList(sortBy(types, (x) => getNotificationTypeLabel(x.category, x.type)))}</div>
                {index + 1 < array.length && <hr />}
              </div>
            );
          })}
        </Card>
      </Col>
    </Row>
  );
};

export default Notifications;
