import React from 'react';
import { Col, Row } from 'shared/components/Layout';
import CenteredModal from './CenteredModal/CenteredModal';
import Input from '../TextInput/Input';
import { useGetDirectDebitPaymentMethodQuery } from 'generated/graphql';

interface IProps {
  isOpen: boolean;
  paymentMethod: IPaymentMethod;
  onClose: () => void;
}

const ViewPaymentMethodModal: React.FC<IProps> = ({ isOpen, paymentMethod, onClose }) => {
  const { data, loading } = useGetDirectDebitPaymentMethodQuery({
    variables: {
      id: paymentMethod.id,
    },
  });

  return (
    <CenteredModal
      show={isOpen}
      title="Payment Method"
      onHide={onClose}
      primaryCallback={onClose}
      hasSecondaryChoice={false}
      primaryChoice="Got It"
      primaryButtonProps={{ loading }}
    >
      <Row>
        <Col>
          <Input label={'Account Name'} value={data?.getDirectDebitPaymentMethod?.accountName} disabled />
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Input label={'BSB'} value={data?.getDirectDebitPaymentMethod?.bsb} disabled />
        </Col>
        <Col lg={8}>
          <Input label={'Account Number'} value={data?.getDirectDebitPaymentMethod?.accountNumber} disabled />
        </Col>
      </Row>
    </CenteredModal>
  );
};

export default ViewPaymentMethodModal;
