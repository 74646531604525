import React, { useEffect, useState } from 'react';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import NotifiableEventsHistoryTable from '../../NotifiableEventsHistoryTable/NotifiableEventsHistoryTable';
import moment from 'moment';
import PhoneInput from 'shared/components/PhoneInput';
import NotifiableServiceEventsContainer from '../NotifiableServiceEventsContainer';
import { useGetServiceContact } from '../../graphql/queries';
import { useUpdateServiceContact } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import { isValidPhoneNumber } from 'shared/util/string';

interface Props {}

interface IServiceContactDetailsInput {
  dateOfEvent: string;
  phoneNumber: string;
  mobileNumber: string;
  email: string;
  serviceUrl: string;
  service: ITableFilterOption | undefined;
}

const ServiceContactDetails: React.FC<Props> = ({}) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<IServiceContactDetailsInput>({
    dateOfEvent: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    serviceUrl: '',
    service: undefined,
  });
  const [cancelFormData, setCancelFormData] = useState<IServiceContactDetailsInput>(formData);

  const { data, loading } = useGetServiceContact(formData.service?.value || '');
  const [updateContact, { loading: updateContactLoading }] = useUpdateServiceContact({
    onCompleted: () => {
      showToast('Successfully submitted the updated contact details.', 'success');
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  useEffect(() => {
    if (data?.getServiceContact) {
      setFormData({
        ...formData,
        ...data?.getServiceContact,
      });
      setCancelFormData({
        ...formData,
        ...data?.getServiceContact,
      });
    }
  }, [data]);

  const validate = () => {
    const { mobileNumber, phoneNumber } = formData;
    if (Object.values(formData).some((val: string) => val === '')) {
      return false;
    }
    return isValidPhoneNumber(mobileNumber) && isValidPhoneNumber(phoneNumber);
  };

  const onSubmit = () => {
    const { mobileNumber, phoneNumber, email, serviceUrl, service } = formData;
    if (!validate()) {
      setFormIsDirty(false);
      return;
    }
    updateContact({
      variables: {
        input: {
          serviceId: service?.value || '',
          email,
          serviceUrl,
          mobileNumber: mobileNumber.replace('+61', '0'),
          phoneNumber: phoneNumber.replace('+61', '0'),
          dateOfEvent: moment(formData.dateOfEvent).utc().format(),
        },
      },
    });
  };
  return (
    <>
      <NotifiableServiceEventsContainer
        title="Service Contact Details"
        subTitle={undefined}
        notificationMessage="Within 14 days after the change"
        onCancel={() => setFormData(cancelFormData)}
        onSave={onSubmit}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!validate()}
        serviceOnChange={(option) => setFormData({ ...formData, service: option })}
        selectedService={formData.service}
        isLoading={loading}
        isSaving={updateContactLoading}
        child={
          <div>
            <Row>
              <Col className="mb-2" md={6}>
                <DateInput
                  required
                  label="Date of effect"
                  date={formData.dateOfEvent}
                  isOutsideRange={(date: moment.Moment) => date.isBefore(moment())}
                  onDateSelect={(date: string) => setFormData({ ...formData, dateOfEvent: date })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" md={6}>
                <PhoneInput
                  required
                  label="Contact Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(value: string) => setFormData({ ...formData, phoneNumber: value })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" md={6}>
                <PhoneInput
                  required
                  label="Contact Mobile Number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={(value: string) => setFormData({ ...formData, mobileNumber: value })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" md={6}>
                <TextInput
                  required
                  label="Contact Email Address"
                  name="email"
                  value={formData.email}
                  onChange={(value: string) => setFormData({ ...formData, email: value })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2" md={6}>
                <TextInput
                  required
                  label="Service Website"
                  name="website"
                  value={formData.serviceUrl}
                  onChange={(value: string) => setFormData({ ...formData, serviceUrl: value })}
                  disabled={false}
                />
              </Col>
            </Row>
          </div>
        }
      ></NotifiableServiceEventsContainer>
    </>
  );
};

export default ServiceContactDetails;
