import { useCallback, useMemo, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import moment from 'moment';
import DateInput from 'shared/components/DateInput';
import Checkbox from 'shared/components/Checkbox';
import { useGetClassesForCenter } from 'gql/class/queries';
import StaffSelectInput from 'shared/components/StaffSelectInput/StaffSelectInput';
import { useProgramCreate } from 'gql/program/mutation';
import { capitalize } from 'shared/util/string';
import { showToast } from 'shared/components/Toast';
import { ApolloError } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import TimeInput from '../../../../../shared/components/TimePicker/TimeInput';
import EducatorQualifications from '../../Program/EducatorQualifications';
import { omitTypename } from 'shared/util/object';
import {
  freeKindySubsidySchemeId,
  kindyForAllSubsidySchemeId,
  startStrongSubsidySchemeId,
  freeKindyStartDate,
} from 'pages/Subsidies/KindyFunding/utils';
import { SubsidySchemeDto } from 'generated/graphql';

type Props = {
  centerId: string;
  businessId: string;
  subsidySchemes: SubsidySchemeDto[];
  isOpen: boolean;
  refetchPrograms: () => void;
  onClose?: () => void;
};

interface IForm {
  subsidyId: string;
  programName: string;
  programStartDate: string;
  programEndDate?: string;
  schedules: ProgramSchedule[];
  classes: IClass[];
  staff: IBasicStaff[];
  educators: IProgramTeacher[];
  paymentRule: IQkfsPaymentRule;
}

const initialState: IForm = {
  subsidyId: '',
  programName: '',
  programStartDate: '',
  schedules: [],
  classes: [],
  staff: [],
  educators: [],
  paymentRule: 'QkfsPlusOnly', //TODO: Remove this once we clean up on the back-end
};

const daySettings = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

export default function AddProgramModalForm({
  isOpen,
  onClose,
  centerId,
  businessId,
  subsidySchemes,
  refetchPrograms,
  ...props
}: Props) {
  const { t } = useTranslation(['subsidies', 'translation']);
  const [formData, setFormData] = useState<IForm>(initialState);

  const { data, loading: classesLoading } = useGetClassesForCenter({
    variables: { centerId: centerId },
    fetchPolicy: 'network-only',
  });

  const subsidySchemesOptions = useMemo<{ label: string; value: string }[]>(() => {
    return subsidySchemes.map((subsidyScheme) => ({
      value: subsidyScheme.subsidySchemeId,
      label: subsidyScheme.name,
    }));
  }, [subsidySchemes]);

  const classOptions = data?.getClassesForCenter.filter((c) => !c.archivedAt) ?? [];

  const handleChangeCallback = useCallback((componentName: string, value: any) => {
    setFormData((prevState) => {
      return { ...prevState, [componentName]: value };
    });
  }, []);

  const getEducators: IProgramTeacher[] = useMemo(() => {
    return formData.educators
      .filter((e) => formData.staff.map((s) => s.id).includes(e.staffId ?? ''))
      .map((e) => omitTypename(e));
  }, [formData]);

  const updateEducators = useCallback(
    (teacher: IProgramTeacher) => {
      const educatorsExcludingTeacher = formData.educators.filter((e) => e.staffId !== teacher.staffId);

      setFormData((prevState) => {
        return { ...prevState, educators: educatorsExcludingTeacher.concat(teacher) };
      });
    },
    [formData.educators]
  );

  const handleStaffChange = useCallback(
    (staff: IBasicStaff[]) => {
      const existingEducators = (formData.educators ?? []).filter((e) =>
        staff.map((s) => s.id).includes(e.staffId ?? '')
      );

      const newEducators: IProgramTeacher[] = staff
        .filter((s) => !existingEducators.map((e) => e.staffId ?? '').includes(s.id))
        .map((s) => {
          return {
            staffId: s.id,
            firstName: s.firstname,
            lastName: s.lastname,
            qualificationStatus: 'None',
          };
        });

      setFormData((prevState) => {
        return { ...prevState, staff: staff, educators: existingEducators.concat(newEducators) };
      });
    },
    [formData.educators]
  );

  const [createProgram, { loading: createProgramInProgress }] = useProgramCreate();
  const handleSubmit = useCallback(() => {
    createProgram({
      variables: {
        input: {
          businessId,
          programName: formData.programName,
          classIds: formData.classes.map((c) => c.id),
          programStartDate: moment(formData.programStartDate).format('YYYY-MM-DD'),
          programEndDate: formData.programEndDate ? moment(formData.programEndDate).format('YYYY-MM-DD') : undefined,
          schedules: formData.schedules,
          educators: getEducators,
          subsidyId: formData.subsidyId,
          centerId,
          paymentRule: formData.paymentRule,
        },
      },
    })
      .then((result) => {
        showToast(`${t('subsidies:ok.create', { programName: formData.programName })}`, 'success');
        refetchPrograms();
        if (onClose) onClose();
      })
      .catch((err: Error) => {
        const message = err instanceof ApolloError ? getApolloErrorMessage(err) : err.message;
        showToast(message, 'error');
      });
  }, [businessId, centerId, createProgram, getEducators, formData, onClose, refetchPrograms, t]);

  const handleScheduleTimeChange = useCallback(
    (name: string, attribute: 'startTime' | 'endTime', value: any) => {
      const { schedules } = formData;
      let idx = schedules.findIndex((o) => o.day == name);

      if (idx >= 0) {
        schedules[idx][attribute] = moment(value, 'HH:mm').format('HH:mm:ss');
      }

      handleChangeCallback('schedules', schedules);
    },
    [formData, handleChangeCallback]
  );

  const startDateIsValid =
    !formData.subsidyId ||
    formData.subsidyId == startStrongSubsidySchemeId ||
    (formData.subsidyId == freeKindySubsidySchemeId && formData.programStartDate >= freeKindyStartDate) ||
    (formData.subsidyId == kindyForAllSubsidySchemeId && formData.programStartDate < freeKindyStartDate);

  const endDateIsValid =
    (moment(formData.programStartDate).year() < 2023 && moment(formData.programEndDate).year() <= 2023) ||
    moment(formData.programStartDate).year() === moment(formData.programEndDate).year();

  const startDatePreceedsEndDate =
    formData.programEndDate === undefined || moment(formData.programStartDate) < moment(formData.programEndDate);

  const isFormValid = () => {
    return (
      startDateIsValid &&
      endDateIsValid &&
      startDatePreceedsEndDate &&
      formData.subsidyId &&
      formData.programName &&
      formData.programStartDate &&
      formData.classes.length
    );
  };

  return (
    <SideModalDrawer
      title={t('subsidies:add-program')}
      show={isOpen}
      primaryChoice={capitalize(t('translation:spelling.create'))}
      primaryCallback={handleSubmit}
      primaryButtonProps={{ disabled: !isFormValid() }}
      onHide={() => {
        setFormData(initialState);
        if (onClose) onClose();
      }}
      closeOnPrimaryCallback={false}
    >
      <Form>
        <Row className="mb-4">
          <Col>
            <Select
              required
              label={t('subsidies:subsidy-name')}
              options={subsidySchemesOptions}
              onChange={(o) => {
                handleChangeCallback('subsidyId', o.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              value={formData.programName}
              onChange={(v) => handleChangeCallback('programName', v)}
              label={t('subsidies:program.name')}
            />
          </Col>
        </Row>
        <h5 className="mb-4">{t('subsidies:program.schedule')}</h5>
        <Row>
          <Col>
            <DateInput
              required
              label={t('subsidies:program.start-date')}
              date={formData['programStartDate']}
              onDateSelect={(input) =>
                setFormData((prev) => ({
                  ...prev,
                  programStartDate: !!input ? moment(input).format('YYYY-MM-DD') : input,
                }))
              }
              className="kt-date-input-no-max-width"
              isValid={startDateIsValid}
              dateOnly
            />
          </Col>
          <Col>
            <DateInput
              required
              label={t('subsidies:program.ends-on')}
              date={formData['programEndDate']}
              onDateSelect={(input) =>
                setFormData((prev) => ({
                  ...prev,
                  programEndDate: !!input ? moment(input).format('YYYY-MM-DD') : input,
                }))
              }
              className="kt-date-input-no-max-width"
              isValid={endDateIsValid}
              dateOnly
            />
          </Col>
        </Row>
        {!startDatePreceedsEndDate && <p className="text-danger small">{'* Start date must be before end date'}</p>}
        {formData.subsidyId == freeKindySubsidySchemeId &&
          formData.programStartDate !== '' &&
          formData.programStartDate < '2024-01-01' && (
            <p className="text-danger small">{'* QLD Free Kindy subsidy is not available before 2024'}</p>
          )}
        {formData.subsidyId == kindyForAllSubsidySchemeId && formData.programStartDate >= '2024-01-01' && (
          <p className="text-danger small">{'* QLD Kindy for All subsidy is not available after 2023'}</p>
        )}
        {formData.programEndDate && !endDateIsValid && (
          <p className="text-danger small">{'* End date must be within the same year as the start date'}</p>
        )}
        <Row>
          <Col className="mb-2 mt-2">{t('subsidies:program.scheduled-days')}</Col>
        </Row>
        <ul className="unstyled-list ">
          {daySettings.map((value, idx) => {
            // @ts-ignore
            const label = t(`translation:day.${value.toLowerCase()}` as const) as string;
            const dayUpperCase = value.toUpperCase();
            return (
              <Row key={`subsidies-schedule-day-${value}`}>
                <li
                  className="m-2"
                  style={{
                    listStylePosition: 'inside',
                    border: '1px solid #c5d0de',
                    borderRadius: '3px',
                    width: '100%',
                  }}
                >
                  <Col className="mt-1 font-weight-semi-bold">
                    <Checkbox
                      label={label}
                      value={formData['schedules'].find((v) => v.day === dayUpperCase) != null}
                      onChange={(v) => {
                        const { schedules } = formData;
                        let find = schedules.findIndex((o) => o.day === dayUpperCase);
                        if (find < 0) {
                          schedules.push({ day: value as DayOfWeek });
                        }

                        if (!v) {
                          schedules.splice(find, 1);
                        }

                        handleChangeCallback('schedules', schedules);
                      }}
                    />
                  </Col>
                  <Row className="m-2">
                    <Col>
                      <div className="font-size-12"> {t('subsidies:program.start-time')} </div>
                      <TimeInput
                        isAM={true}
                        value={formData.schedules.find((v) => v.day === dayUpperCase)?.startTime}
                        onChange={(v) => handleScheduleTimeChange(dayUpperCase, 'startTime', v)}
                        disabled={formData['schedules'].find((v) => v.day === dayUpperCase) == null}
                      />
                    </Col>
                    <Col>
                      <div className="font-size-12"> {t('subsidies:program.end-time')}</div>
                      <TimeInput
                        isAM={false}
                        value={formData.schedules.find((v) => v.day === dayUpperCase)?.endTime}
                        onChange={(v) => handleScheduleTimeChange(dayUpperCase, 'endTime', v)}
                        disabled={formData['schedules'].find((v) => v.day === dayUpperCase) == null}
                      />
                    </Col>
                  </Row>
                </li>
              </Row>
            );
          })}
        </ul>
        <hr className="m-0 mb-4" />
        <h5 className="mb-4">{capitalize(t('translation:spelling.class'))}</h5>
        <Row>
          <Col>
            <Select
              label={capitalize(t('translation:spelling.class'))}
              value={formData['classes']}
              options={classOptions}
              onChange={(_classes: IClass[]) => handleChangeCallback('classes', _classes)}
              getOptionLabel={(_class: IClass) => _class.name}
              getOptionValue={(_class: IClass) => _class.id}
              isLoading={false}
              required
              isMulti
            />
          </Col>
        </Row>
        <hr className="m-0 mb-4" />
        <h5 className="mb-4">{t('subsidies:classes.educators')}</h5>
        <Row>
          <Col>
            <StaffSelectInput
              centerId={centerId}
              label={t('subsidies:classes.select-educators')}
              value={formData['staff'] ?? []}
              onChange={(values) => {
                handleStaffChange(values);
              }}
            />
          </Col>
        </Row>
        {formData.staff.map((s, idx) => (
          <EducatorQualifications
            key={idx}
            required={formData.subsidyId == kindyForAllSubsidySchemeId}
            teacher={formData.educators.find((e) => e.staffId === s.id)}
            onChange={updateEducators}
          />
        ))}
      </Form>
    </SideModalDrawer>
  );
}
