import React from 'react';
import { Application, ApplicationStage, ApplicationFlowType, ApplicationScheduleOfferState } from 'generated/graphql';
import { useLeadsContext } from '../../LeadsContext';
import ContactDetails from './ContactDetails';
import './leadDetails.scss';
import { Container } from 'react-bootstrap';
import {
  STAGE_TO_ASO_STATE_MAPPING_FOR_NORMAL_FLOW,
  APPROVAL_PENDING_BULK_APPROVAL_CAN_APPROVE_STATE,
  WAITLIST_BULK_APPROVAL_CAN_APPROVE_STATE,
  STAGE_TO_ASO_STATE_MAPPING_FOR_PROGRAM_FLOW,
} from '../../types';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import MakeOffers from './MakeOffers';
import ViewOffers from './ViewOffers/ViewOffers';
import { isProgramBasedFlow } from '../../utils';
interface IProps {
  application: Application;
  applicationStage: ApplicationStage;
  applicationFlowType: ApplicationFlowType;
}

const LeadDetails: React.FC<IProps> = ({ application, applicationStage, applicationFlowType }) => {
  const { handleRefetchApplications, refetchAggregateData, setSelectedChild, setIsSearchOpen, setSelectedApplication } =
    useLeadsContext();

  const hasEditLeadPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementMain,
    level: RoleLevelType.Edit,
  });

  const useAdvancedApplicationEditFeature =
    applicationStage === ApplicationStage.Waitlisted &&
    applicationFlowType === ApplicationFlowType.InquireOfferEnrollment;

  return (
    <Container fluid className="py-2">
      <ContactDetails
        application={application}
        showStatus={applicationStage === ApplicationStage.InProgress}
        useAdvancedApplicationEditFeature={useAdvancedApplicationEditFeature}
      />
      {/* 
        Flow: Normal Flow
        Tab: New
        Action: Save and Send Offer with deposit input
        ApplicationStage: waitlisted
       */}
      {applicationStage === ApplicationStage.Waitlisted &&
        applicationFlowType === ApplicationFlowType.InquireOfferEnrollment && (
          <MakeOffers
            child={undefined}
            validApplicationScheduleOfferStates={STAGE_TO_ASO_STATE_MAPPING_FOR_NORMAL_FLOW.Waitlisted}
            application={application}
            isApplicationAction={true}
            refetch={handleRefetchApplications}
            refetchAggregateData={refetchAggregateData}
            setChild={setSelectedChild}
            openSearch={() => {
              setSelectedApplication(application);
              setIsSearchOpen(true);
            }}
          />
        )}
      {/* 
        Flow: Normal Flow
        Tab: Offered
        Action: view,  resend offer and edit deposit
        ApplicationStage: offered
       */}
      {applicationStage === ApplicationStage.Offered &&
        applicationFlowType === ApplicationFlowType.InquireOfferEnrollment && (
          <ViewOffers
            application={application}
            validApplicationScheduleOfferStates={STAGE_TO_ASO_STATE_MAPPING_FOR_NORMAL_FLOW[ApplicationStage.Offered]}
            showResendOfferButton={true}
            allowEdit={false}
            showMarkAsLostButton={true}
            allowEditDeposit={hasEditLeadPermission}
          />
        )}
      {/* 
        Flow: Normal Flow
        Tab: Accepted
        Action: view offers and edit deposit
        ApplicationStage: accepted
       */}
      {applicationStage === ApplicationStage.Accepted &&
        applicationFlowType === ApplicationFlowType.InquireOfferEnrollment && (
          <ViewOffers
            application={application}
            validApplicationScheduleOfferStates={STAGE_TO_ASO_STATE_MAPPING_FOR_NORMAL_FLOW[ApplicationStage.Accepted]}
            showResendOfferButton={false}
            allowEdit={false}
            showMarkAsLostButton={true}
            allowEditDeposit={hasEditLeadPermission}
          />
        )}
      {/* 
        Flow: Normal Flow
        Tab: Completed
        Action: view offers
        ApplicationStage: completed
       */}
      {applicationStage === ApplicationStage.Completed &&
        applicationFlowType === ApplicationFlowType.InquireOfferEnrollment && (
          <ViewOffers
            application={application}
            validApplicationScheduleOfferStates={STAGE_TO_ASO_STATE_MAPPING_FOR_NORMAL_FLOW[ApplicationStage.Completed]}
            showResendOfferButton={false}
            allowEdit={false}
            showMarkAsLostButton={false}
            allowEditDeposit={false}
          />
        )}
      {/* 
        Flow: Program Flow
        Tab: In Progress
        Action: view and edit deposit
        ApplicationStage: InProgress
       */}
      {applicationStage === ApplicationStage.InProgress && isProgramBasedFlow(applicationFlowType) && (
        <ViewOffers
          application={application}
          validApplicationScheduleOfferStates={STAGE_TO_ASO_STATE_MAPPING_FOR_PROGRAM_FLOW[ApplicationStage.InProgress]}
          showResendOfferButton={false}
          allowEdit={false}
          showMarkAsLostButton={true}
          allowEditDeposit={hasEditLeadPermission}
        />
      )}
      {/* 
        Flow: Program Flow
        Tab: Approval Pending
        Action: view, approve offer and edit deposit
        ApplicationStage: Accepted
       */}
      {applicationStage === ApplicationStage.Accepted && isProgramBasedFlow(applicationFlowType) && (
        <ViewOffers
          application={application}
          validApplicationScheduleOfferStates={APPROVAL_PENDING_BULK_APPROVAL_CAN_APPROVE_STATE}
          showResendOfferButton={false}
          allowEdit={false}
          showMarkAsLostButton={true}
          allowEditDeposit={hasEditLeadPermission}
        />
      )}
      {/* 
        Flow: Program Flow
        Tab: Waitlist
        Action: view, approve and edit deposit
        ApplicationStage: Accepted
       */}
      {applicationStage === ApplicationStage.Waitlisted && isProgramBasedFlow(applicationFlowType) && (
        <ViewOffers
          application={application}
          validApplicationScheduleOfferStates={WAITLIST_BULK_APPROVAL_CAN_APPROVE_STATE}
          showResendOfferButton={false}
          allowEdit={hasEditLeadPermission}
          showMarkAsLostButton={true}
          allowEditDeposit={hasEditLeadPermission}
        />
      )}
      {/* 
        Flow: Program Flow
        Tab: Completed
        Action: view offers
        ApplicationStage: Completed
       */}
      {applicationStage === ApplicationStage.Completed && isProgramBasedFlow(applicationFlowType) && (
        <ViewOffers
          application={application}
          validApplicationScheduleOfferStates={[
            ApplicationScheduleOfferState.Completed,
            ApplicationScheduleOfferState.Submitted,
          ]}
          showResendOfferButton={false}
          allowEdit={false}
          allowEditDeposit={false}
        />
      )}
      {/* 
        Flow: Normal Flow and Program Flow
        Tab: Lost
        Action: view offers
        ApplicationStage: Lost
       */}
      {applicationStage === ApplicationStage.Lost && (
        <ViewOffers
          application={application}
          validApplicationScheduleOfferStates={Object.values(ApplicationScheduleOfferState)}
          showResendOfferButton={false}
          allowEdit={false}
          allowEditDeposit={false}
        />
      )}
    </Container>
  );
};

export default LeadDetails;
