export const stateTerritoryBodyFields = `
  noticeGivenDate
  noticeToStateTerritory
  notifiedByStateTerritory
  notifiedByPersonFirstName
  notifiedByPersonLastName
  organisationName
  statePersonEmail
  statePersonNameOrId
  statePersonPhoneNumber
  stateReferenceNumber
  text
  type
`;

export const determinationFields = `
  id
  determinationId
  childId
  startDate
  endDate
  status
  weeksAtRisk
  category
  stateTerritoryBody {
    ${stateTerritoryBodyFields}
  }
  riskReasons
  exceptionalCircumstanceReason
  exceptionalCircumstanceText
`;

export const determinationFieldsWithPrevious = `
  ${determinationFields}
  previousDeterminations {
    ${determinationFields}
  }
`;
