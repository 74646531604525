export const allergenReactions: Record<string, string> = {
  VomitingStomachCramps: 'Vomiting and/or stomach cramps',
  Diarrhea: 'Diarrhea',
  Hives: 'Hives; itchiness',
  ShortnessBreath: 'Shortness of breath',
  Wheezing: 'Wheezing',
  Cough: 'Repetitive cough',
  Shock: 'Shock or circulatory collapse',
  TroubleSwallowing: 'Tight, hoarse throat; trouble swallowing',
  TongueSwelling: 'Swelling of the tongue or lips; cannot talk or breathe',
  Pulse: 'Weak pulse',
  PaleBlueSkin: 'Pale or blue coloring of skin',
  GeneralSwelling: 'Swelling of the affected area',
  Redness: 'Redness',
  Dizziness: 'Dizziness or feeling faint',
  Lethargy: 'Lethargy',
  Pain: 'Pain',
  MoodSwings: 'Irritability; mood swings',
  Anaphylaxis: 'Anaphylaxis',
};
