import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { useCreateInternalAccountNote } from './gql/mutation';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { useTranslation } from 'react-i18next';
import RightModalDrawer from 'shared/components/MUI/RightModalDrawer';

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

interface IProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  accountId: string;
  children: IAccountChild[];
  refetch: () => void;
  contacts: IContact[];
}

const defaultNoteCategoriesOptions = [
  'General',
  'Conflict',
  'Concern',
  'Development',
  'Education',
  'Behavior',
  'Attendance',
  'Billing',
];

interface INoteAssociation {
  objectId: string;
  objectType: NoteAssociationObjectType;
  display?: {
    displayValue: string;
    displayAvatar?: string;
  };
}
type NoteAssociationObjectType = 'Account' | 'AccountChild' | 'AccountContact' | 'Staff';

interface INoteAssignOption {
  objectId: string; //this is going to be the contactId or childAccountId
  objectType: NoteAssociationObjectType;
  groupLabel: string;
  displayName: string;
  displayAvatarUrl?: string | null;
}

const AddNoteThread: React.FC<IProps> = ({ open, setOpen, accountId, children, refetch, contacts }) => {
  const { t } = useTranslation();
  const businessIdRedux = useSelector((state: RootState) => state.context.businessId);
  const businessId = _.isNil(businessIdRedux) ? '' : businessIdRedux;
  const [title, setTitle] = React.useState('');
  const [noteType, setNoteType] = React.useState('');
  const [noteContact, setNoteContact] = React.useState<INoteAssignOption[] | []>([]);
  const [noteComment, setNoteComment] = React.useState('');

  const assignmentDropdownOptions = children
    .map((child) => {
      const obj: INoteAssignOption = {
        objectId: child.accountChildId,
        objectType: 'AccountChild',
        groupLabel: 'Child',
        displayName: child.fullName,
        displayAvatarUrl: child.avatar?.url ?? '',
      };
      return obj;
    })
    .concat(
      contacts.map((contact) => {
        const obj: INoteAssignOption = {
          objectId: contact.id,
          objectType: 'AccountContact',
          groupLabel: 'Contact',
          displayName: `${contact.firstname} ${contact.lastname}`,
          displayAvatarUrl: contact.avatar?.url ?? '',
        };
        return obj;
      })
    );

  const [createNoteThreadFn] = useCreateInternalAccountNote({
    onCompleted: () => {
      refetch();
      showToast('Note Created', 'success');
      onClose();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onSave = () => {
    const associations = noteContact.map((contact) => {
      const noteAssoication: INoteAssociation = {
        objectId: contact.objectId,
        objectType: contact.objectType,
      };
      return noteAssoication;
    });
    createNoteThreadFn({
      variables: {
        input: {
          businessId: businessId,
          attachedToId: accountId,
          areaType: 'Account',
          noteType: noteType,
          title: title,
          comment: noteComment,
          associations: associations,
        },
      },
    });
  };

  const onClose = () => {
    setOpen(false);
    setTitle('');
    setNoteType('');
    setNoteContact([]);
    setNoteComment('');
  };

  return (
    <>
      <RightModalDrawer open={open} onClose={onClose} title="Note Thread">
        <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '16px' }}>
          <Mui.Stack direction="column" spacing={2} marginLeft="16px">
            <Mui.FormControlLabel
              label={
                <span>
                  Title
                  <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
                </span>
              }
              labelPlacement="top"
              control={
                <Mui.TextField
                  size="small"
                  value={title}
                  required
                  onChange={(evt) => setTitle(evt.target.value)}
                  fullWidth
                  sx={styles.inputStyles}
                />
              }
              componentsProps={{
                typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
              }}
              style={{ margin: 0 }}
            />
            <Mui.FormControlLabel
              label={
                <span>
                  Type
                  <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
                </span>
              }
              labelPlacement="top"
              control={
                <Mui.Select
                  size="small"
                  value={noteType}
                  fullWidth
                  sx={styles.inputStyles}
                  onChange={(evt) => setNoteType(evt.target.value)}
                >
                  {defaultNoteCategoriesOptions.map((option, index) => (
                    <Mui.MenuItem key={option} value={option}>
                      {option === 'Behavior' ? t('internal-notes.capital-behavior') : option}
                    </Mui.MenuItem>
                  ))}
                </Mui.Select>
              }
              componentsProps={{
                typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
              }}
            />
            <Mui.FormControlLabel
              label={'Child/Contact'}
              labelPlacement="top"
              control={
                <Mui.Autocomplete
                  fullWidth
                  multiple
                  id="child-select"
                  options={assignmentDropdownOptions}
                  getOptionLabel={(option: INoteAssignOption) => option.displayName}
                  groupBy={(option) => option.groupLabel}
                  onChange={(evt, value) => setNoteContact(value)}
                  renderInput={(params) => <Mui.TextField {...params} sx={styles.inputStyles} />}
                  renderOption={(props, option: INoteAssignOption) => (
                    <Mui.Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <AvatarDataTableCell
                        initials={option.displayName.substring(0, 1)}
                        avatar={option.displayAvatarUrl ?? ''}
                        primaryText={option.displayName}
                      />
                    </Mui.Box>
                  )}
                  size="small"
                  value={noteContact}
                />
              }
              componentsProps={{
                typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
              }}
            />
            <Mui.FormControlLabel
              label={
                <span>
                  Note
                  <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
                </span>
              }
              labelPlacement="top"
              control={
                <Mui.TextField
                  size="small"
                  value={noteComment}
                  required
                  onChange={(evt) => setNoteComment(evt.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  sx={styles.inputStyles}
                  variant="outlined"
                />
              }
              componentsProps={{
                typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
              }}
            />
          </Mui.Stack>
        </Mui.DialogContent>
        <Mui.Box style={{ margin: '8px' }}>
          <Mui.DialogActions>
            <Mui.Button variant="cancel" onClick={() => onClose()}>
              Cancel
            </Mui.Button>
            <Mui.Button
              disabled={_.isEmpty(title) || _.isEmpty(noteType) || _.isEmpty(noteComment)}
              color="secondary"
              variant="contained"
              onClick={() => onSave()}
              id="modal-drawer-primary-btn"
            >
              Save
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Box>
      </RightModalDrawer>
    </>
  );
};

export default AddNoteThread;
