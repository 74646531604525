import React, { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'shared/components/Tooltip';
import { IRoute } from 'shared/util/routes';
import { faRocket, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';
import { Collapse } from 'react-bootstrap';
import cast from 'shared/util/cast';
import K2SidebarSubItem from './K2SidebarSubItem';

interface IProps {
  sidebarExpanded: boolean;
  route: IRoute;
  navigateTo: (route: IRoute) => void;
  expandSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const K2SidebarItem: React.FC<IProps> = ({ sidebarExpanded, route, navigateTo, expandSidebar, ...props }) => {
  const routeMatch = useRouteMatch(route.route);
  const [subMenuExpanded, setSubMenuExpanded] = useState<boolean>(false);
  const hasSubMenu: boolean = Boolean(route.subRoutes);

  // on mount, expand submenu and sidebar if active route is nested
  useEffect(() => {
    if (routeMatch) {
      expandSidebar(true);
      setSubMenuExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes: string = classnames({
    'd-flex': true,
    'align-items-center': true,
    'w-100': true,
    'k2-navigation-sidebar-item': true,
    'justify-content-center': !sidebarExpanded,
    active: (!sidebarExpanded || !subMenuExpanded) && Boolean(routeMatch),
    expanded: sidebarExpanded,
  });

  const handleClick = useCallback(
    (route: IRoute) => {
      if (route.component) {
        navigateTo(route);
      }
      if (hasSubMenu) {
        expandSidebar(true);
        setSubMenuExpanded((prev) => !prev);
      }
    },
    [expandSidebar, hasSubMenu, navigateTo]
  );

  if (route.doNotShowInSidebar) {
    return null;
  }

  return (
    <div>
      <Tooltip
        additionalClass="k2-navigation-sidebar-tooltip"
        text={route.name}
        direction="left"
        showTooltip={!sidebarExpanded}
      >
        <div>
          {route.component ? (
            <NavLink
              exact={true}
              activeClassName="active"
              to={route.route}
              id={`nav-${route.name.toLowerCase()}`}
              role={route.subRoutes ? 'button' : 'link'}
              aria-haspopup={!!route.subRoutes}
              aria-expanded={sidebarExpanded}
              aria-label={route.name}
              onClick={() => handleClick(route)}
              className={classes}
            >
              <FontAwesomeIcon icon={route.icon ?? faRocket} />
              {sidebarExpanded && <span className="d-block ml-4 mr-auto">{route.name}</span>}
              {sidebarExpanded && hasSubMenu && (
                <FontAwesomeIcon icon={subMenuExpanded ? faChevronUp : faChevronDown} color={colors.white} />
              )}
            </NavLink>
          ) : (
            <div
              id={`nav-${route.name.toLowerCase()}`}
              role={route.subRoutes ? 'button' : 'link'}
              aria-haspopup={!!route.subRoutes}
              aria-expanded={sidebarExpanded}
              aria-label={route.name}
              onClick={() => handleClick(route)}
              className={classes}
            >
              <FontAwesomeIcon icon={route.icon ?? faRocket} />
              {sidebarExpanded && <span className="d-block ml-4 mr-auto">{route.name}</span>}
              {sidebarExpanded && hasSubMenu && (
                <FontAwesomeIcon icon={subMenuExpanded ? faChevronUp : faChevronDown} color={colors.white} />
              )}
            </div>
          )}
        </div>
      </Tooltip>
      {hasSubMenu && Boolean(route.subRoutes) && (
        <Collapse in={subMenuExpanded && sidebarExpanded}>
          <div className="k2-navigation-sidebar-submenu">
            {cast<IRoute[]>(route.subRoutes).map((subRoute: IRoute, idx: number) => {
              return (
                <K2SidebarSubItem
                  key={`sidebar-route-${route.name}-subroute-${subRoute.name}-${idx}`}
                  route={route}
                  subRoute={subRoute}
                  sidebarExpanded={sidebarExpanded}
                  navigateTo={navigateTo}
                />
              );
            })}
          </div>
        </Collapse>
      )}
    </div>
  );
};

export default K2SidebarItem;
