import moment from 'moment';
import { IDayOption } from 'shared/constants/enums/weekDayOptions';

export function isTimeslotOnWeekday(day: IDayOption, timeslot: ICreateContractTimeslotInput) {
  if (timeslot.date) {
    return moment(timeslot.date).day() === day.dayNumber;
  }
  if (timeslot.day) {
    return timeslot.day === day.value;
  }
  return false;
}

export const removeTimeSlotsPostEndDateForCustomContractEndInput = (input: IUpdateContractInput) => {
  const { updateType, cycleType, endDate, timeslots } = input;

  // this helper is intended to be used only for custom contracts and for updateType === 'END'
  if (updateType !== 'END' || cycleType !== 'CUSTOM' || !endDate || !timeslots || timeslots?.length === 0) {
    return input;
  }

  const timeSlotsSameOrBeforeEndDate = (timeslots as IContractTimeslot[]).filter((t) =>
    moment(t.date).isSameOrBefore(endDate, 'day')
  );

  return {
    ...input,
    timeslots: timeSlotsSameOrBeforeEndDate,
  };
};
