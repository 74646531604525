import React, { useCallback, useState, useEffect } from 'react';
import momentTz from 'moment-timezone';
import PageWrapper from 'shared/components/PageWrapper';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { updateVisibleCenterForEnrollment, updateVisibleClassForEnrollment } from 'pages/Enrollment/duck/actions';
import SearchAvailability from './Tabs/Overview/components/SearchAvailability';
import AvailabilityTable from './Tabs/Overview/components/AvailabilityTable';
import { Tab, Tabs } from 'react-bootstrap';
import ClassEnrollment from './Tabs/ClassEnrollment';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useTranslation } from 'react-i18next';
import ReEnrollment from './Tabs/ReEnrollment';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { ApplicationFlowType } from 'generated/graphql';
import { isProgramBasedFlow } from '../LeadManagement/utils';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import Button from 'shared/components/Buttons';
import ReEnrollRunHistoryReportModal from './Tabs/ReEnrollment/Tabs/ReEnrollHistoryOfActions/components/ReEnrollRunHistoryReportModal';

type EnrollmentManagementTab = 'overview' | 'classes' | 're-enrollment';
interface IProps {}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const Management: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['enrollment', 'translation']);
  const dispatch = useDispatch();
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const selectedCenterId = useSelector((state: RootState) => state.enrollment.activeCenter.id);
  const centerTimezones = useSelector((state: RootState) => state.timezone.byCenterId);
  const entities = useSelector((state: RootState) => state.entities.allEntities);
  const applicationFlowType = entities[currentBusinessId ?? '']?.applicationFlowType;
  const [tabKey, setTabKey] = useState<EnrollmentManagementTab>('overview');
  const isOnOverview = tabKey === 'overview';
  const goToClassAvailability = useCallback(
    (_class) => {
      setTabKey('classes');
      dispatch(updateVisibleClassForEnrollment(_class));
    },
    [dispatch]
  );
  const [showHistoryReportModal, setShowHistoryReportModal] = useState<boolean>(false);

  useEffect(() => {
    currentBusinessId && dispatch(updateVisibleCenterForEnrollment('', currentBusinessId));
  }, [dispatch, currentBusinessId]);

  useEffect(() => {
    currentCenterId && dispatch(updateVisibleCenterForEnrollment(currentCenterId));
  }, [dispatch, currentCenterId]);

  const hasAccountEditPermission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const hasReadEnrollmentRequestPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.FamilyEnrollmentRequests,
    level: RoleLevelType.Read,
  });

  const hasReadUpdateRequestPermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.FamilyUpdateDetailsRequests,
    level: RoleLevelType.Read,
  });

  return (
    <PageWrapper
      pageTitle={`${t('enrollment:enrollment-management')}`}
      applyPadding={false}
      buttonComponent={
        <>
          {tabKey == 're-enrollment' && (
            <HasRoleAreaLevel
              action={{ area: AreaType.Report, permission: PermissionType.Base, level: RoleLevelType.Read }}
            >
              <Button variant="secondary" className="my-2 mr-2" onClick={() => setShowHistoryReportModal(true)}>
                Run History Report
              </Button>
            </HasRoleAreaLevel>
          )}
        </>
      }
    >
      {tabKey !== 're-enrollment' && <CenterSelectBanner pageName={fieldLabels.enrollment.toLowerCase()} />}
      <PageWrapperBody className="enrollment-management-page-wrapper">
        <Tabs
          id="enrollment-tabs"
          defaultActiveKey="overview"
          activeKey={tabKey}
          onSelect={(key) => setTabKey(key as EnrollmentManagementTab)}
        >
          <Tab eventKey="overview" title="Overview">
            {tabKey === 'overview' && (
              <>
                <SearchAvailability />
                <h5 className="mb-4 mt-6">Availability</h5>
                {selectedCenterId && isOnOverview && (
                  <AvailabilityTable
                    onClassSelect={goToClassAvailability}
                    centerTimezone={centerTimezones[selectedCenterId] ?? momentTz.tz.guess()}
                  />
                )}
              </>
            )}
          </Tab>
          <Tab eventKey="classes" title="Classes">
            {tabKey === 'classes' && (
              <ClassEnrollment centerTimezone={centerTimezones[selectedCenterId] ?? momentTz.tz.guess()} />
            )}
          </Tab>
          {hasAccountEditPermission &&
            (hasReadEnrollmentRequestPermission || hasReadUpdateRequestPermission) &&
            (isProgramBasedFlow(applicationFlowType) ||
              applicationFlowType === ApplicationFlowType.InquireOfferEnrollment) && (
              <Tab eventKey="re-enrollment" title="Families">
                <ReEnrollment />
                {showHistoryReportModal && (
                  <ReEnrollRunHistoryReportModal
                    isOpen={showHistoryReportModal}
                    onHide={() => setShowHistoryReportModal(false)}
                  />
                )}
              </Tab>
            )}
        </Tabs>
      </PageWrapperBody>
    </PageWrapper>
  );
};

export default Management;
