import React from 'react';
import moment from 'moment';
import Tooltip from 'shared/components/Tooltip';

interface IProps {
  leftOffset: string | number;
}

const NowIndicator: React.FC<IProps> = ({ leftOffset, ...props }) => {
  return (
    <Tooltip direction="top" text={moment().format('h:mm A')}>
      <div
        className="now-indicator"
        style={{
          left: `${leftOffset}px`,
        }}
      />
    </Tooltip>
  );
};

export default NowIndicator;
