import React, { useCallback, useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'shared/components/Buttons';
// @ts-ignore - Ignore because CalendarDay is exported
import { DayPickerSingleDateController, CalendarDay } from 'react-dates';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  scheduleStartDate: moment.Moment;
  onClose: () => void;
  onSave: (date: moment.Moment[]) => void;
}

// this component renders the days of the week for the current schedule and allows users to remove all shifts for one or more days
const DeleteShiftsModal: React.FC<IProps> = ({ isOpen, isLoading, scheduleStartDate, onClose, onSave, ...props }) => {
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);
  // custom day renderer to make the component act as a multi-day select. not sure about actual type
  const renderCalendarDay = useCallback(
    (dateControllerProps: any) => {
      const { day, modifiers } = dateControllerProps;

      if (selectedDates.includes(day)) {
        modifiers && modifiers.add('selected');
      } else {
        modifiers && modifiers.delete('selected');
      }

      return <CalendarDay {...dateControllerProps} modifiers={modifiers} />;
    },
    [selectedDates]
  );

  const handleDateChange = useCallback(
    (date: moment.Moment | null) => {
      if (date) {
        const updatedDates = selectedDates.includes(date)
          ? selectedDates.filter((d) => !date.isSame(d))
          : [...selectedDates, date];

        setSelectedDates(updatedDates);
      }
    },
    [selectedDates]
  );

  const checkOutsideRange = useCallback(
    (date: moment.Moment): boolean => {
      const isWeekend: boolean = date.day() === 0 || date.day() === 6;

      return isWeekend || !date.isSame(scheduleStartDate, 'week');
    },
    [scheduleStartDate]
  );

  return (
    <Modal
      backdrop="static"
      centered
      show={isOpen}
      onHide={() => {
        onClose();
        setSelectedDates([]);
      }}
      dialogClassName="kt-staff-schedules-shift-modal"
    >
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Clear Shifts</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <div className="d-flex flex-column align-items-center">
          <div className="mb-4">Select the day(s) you wish to remove all shifts for, below.</div>
          <DayPickerSingleDateController
            hideKeyboardShortcutsPanel
            focused
            date={scheduleStartDate.clone().add(1, 'day')} // start of schedule will be sunday but we really only care about monday
            onFocusChange={() => {}}
            numberOfMonths={1}
            onDateChange={handleDateChange}
            renderCalendarDay={renderCalendarDay}
            isOutsideRange={checkOutsideRange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button variant="light" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          loading={isLoading}
          disabled={!selectedDates.length || isLoading}
          onClick={() => onSave(selectedDates)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteShiftsModal;
