import React, { useState } from 'react';
import ACcsCategoryCards from './AccsCategoryCards';
import ACcsTable from './AccsTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import Button from 'shared/components/Buttons';
import ViewAccsServiceMetricModal from '../components/ViewAccsServiceMetricModal/ViewAccsServiceMetricModal';
import { Col, Row } from 'shared/components/Layout';
import { useGetAcssCertDertsForCenters, useGetCcsCategoriesForCenter } from 'gql/ccssCertificates/queries';
import moment from 'moment';

interface IProps {
  businessId: string;
  centerIds: string[];
}

const emptyCertificateCategoryCount = (): Record<AccsDisplayCategory, number> => {
  return { pending: 0, active: 0, expiring: 0, expired: 0 };
};

const AccsTab: React.FC<IProps> = ({ businessId, centerIds, ...props }) => {
  const [CategoryCount, setCategoryCount] = useState<Record<AccsDisplayCategory, number>>(
    emptyCertificateCategoryCount()
  );
  const [tableState, tableFunctions, setDatatableState] = useDatatableState();
  const [searchText, setSearchText] = useState<undefined | string>();

  const [sort, setSort] = useState<IElasticsearchSortFilter[]>();
  const onSort = (field: string, direction: ElasticsearchSortDirection) => setSort([{ field, direction }]);
  const [filteredStatus, setFilteredStatus] = useState<string[]>(['Active']);

  const handleFilteredStatus = (value: string[]) => {
    setFilteredStatus(value);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  const { data: CategoryCounts } = useGetCcsCategoriesForCenter({
    variables: {
      input: { businessId: businessId, centerIds: centerIds },
    },
    onCompleted: () => {
      if (!CategoryCounts) return;
      setCategoryCount(CategoryCounts.getCcsCategoriesForCenter);
    },
  });

  const [categoryFilter, certificates] = useState<AccsDisplayCategoryEnum | null>(null);
  const onSelected = (selectedStatus: AccsDisplayCategoryEnum | null) => {
    certificates(selectedStatus);
  };

  const { data: accsCertDertForCenters, loading: accsCertDertForCentersLoading } = useGetAcssCertDertsForCenters({
    variables: {
      businessId: businessId,
      centerIds: centerIds,
      ...(categoryFilter && { category: categoryFilter }),
      searchText: searchText === '' ? undefined : searchText,
      pageSize: tableState.pageSize,
      pageNumber: tableState.activePage,
      sortDtos: sort,
      statusType:
        !!filteredStatus && !!filteredStatus.length
          ? filteredStatus.length > 1
            ? undefined
            : (filteredStatus[0] as AccountStatusType)
          : undefined,
      statusAtDate: moment().format('YYYY-MM-DD'),
    },
  });

  const [showServiceMetricModal, setShowServiceMetricModal] = useState(false);
  return (
    <div>
      <ViewAccsServiceMetricModal
        businessId={businessId}
        centerIds={centerIds}
        isOpen={showServiceMetricModal}
        onClose={() => setShowServiceMetricModal(false)}
      />
      <ACcsCategoryCards onSelected={onSelected} activeFilter={categoryFilter} counts={CategoryCount} />
      <Row className="mb-4 mt-2">
        <Col>
          <Button onClick={() => setShowServiceMetricModal(true)} className="float-right">
            View ACCS Limits
          </Button>
        </Col>
      </Row>

      <ACcsTable
        showLoadingOverlay={accsCertDertForCentersLoading}
        data={accsCertDertForCenters?.getAcssCertDertsForCenters?.data ?? []}
        showPagination={true}
        dataSize={accsCertDertForCenters?.getAcssCertDertsForCenters?.totalRecords ?? 0}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        onSort={onSort}
        onSearch={setSearchText}
        filteredStatus={filteredStatus}
        onStatusFilter={handleFilteredStatus}
      />
    </div>
  );
};

export default AccsTab;
