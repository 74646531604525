import React from 'react';
import { CreateButton } from 'shared/components/Buttons';

interface ICreateBatchButtonProps {
  onClick: () => void;
  disabled: boolean;
}
export function CreateBatchButton(props: ICreateBatchButtonProps) {
  const { onClick, disabled } = props;
  return (
    <div className="unbatched_payments__create_batch_button">
      <CreateButton onClick={onClick} disabled={disabled}>
        Batch Payments
      </CreateButton>
    </div>
  );
}
