/**
 * This is simply a utility to do a faux loading bar
 *
 * Generates sets of random numbers in sequential order, which can faux
 * fill a progress bar when there is no true indicator to base this off of.
 *
 * @param {Function} setProgress
 */
export default (setProgress: Function): void => {
  const percentageBenchmarks: Array<number> = [40, 60, 80, 99];
  let prevBenchmark: number = 0;
  percentageBenchmarks.forEach((pb: number, i: number) => {
    setTimeout(() => {
      if (i < percentageBenchmarks.length - 1) {
        const newProgress: number = generateNewRandomBenchmark(pb, prevBenchmark);
        prevBenchmark = newProgress;
        setProgress(newProgress);
      } else {
        setProgress(99);
      }
    }, 500 * (i + 1));
  });
};

// This ensures that the number never ends up going backwards.
const generateNewRandomBenchmark = (pb: number, prevBm: number): number => {
  const newBenchmark = Math.ceil(Math.random() * (pb + 1));
  return newBenchmark > prevBm ? newBenchmark : generateNewRandomBenchmark(pb, prevBm);
};
