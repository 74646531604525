import { useArchiveAdjustment } from 'gql/adjustment/mutations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';

interface IProps {
  isOpen: boolean;
  adjustment: IAdjustment;
  onConfirm: (adjustment: IAdjustment) => void;
  onClose: () => void;
}

const ArchiveAdjustmentConfirmationModal: React.FC<IProps> = ({ isOpen, adjustment, onConfirm, onClose, ...props }) => {
  const { t } = useTranslation(['billing']);

  const [archiveAdjustmentFn, { loading: archiveAdjustmentLoading }] = useArchiveAdjustment({
    onCompleted: (result) => {
      onConfirm(result.archiveAdjustment);
      onClose();
      showToast(t('billing:adjustments.archive-success-toast'), 'success');
    },
    onError: (err) => {
      showToast(t('billing:adjustments.archive-error-toast'), 'error');
    },
  });

  return (
    <ConfirmationModal
      title={t('billing:adjustments.archive-confirmation-modal-title')}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Continue"
      primaryCallback={() => archiveAdjustmentFn({ variables: { id: adjustment.id } })}
      primaryButtonProps={{ loading: archiveAdjustmentLoading, variant: 'danger' }}
    >
      <p>{t('billing:adjustments.archive-confirmation-modal-body')}</p>
    </ConfirmationModal>
  );
};

export default ArchiveAdjustmentConfirmationModal;
