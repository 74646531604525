import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { getChannelsFields, getStudentChannelsFields } from './fields';
import {
  IGetCenterSummaryStatistics,
  IGetCenterSummaryStatisticsVariables,
  IGetHighestDailyMessagesPerChannelData,
  IGetHighestDailyMessagesPerChannelStatisticsVariables,
  IGetMonthlyMessagesSentStatisticsVariables,
  IGetPaginatedChannelActivityData,
  IGetPaginatedChannelActivityVariables,
  IGetStudentChannelsData,
  IGetStudentChannelsVariables,
  IGetStudentMonthlyChannelsData,
  IMessageAttachmentPagedResult,
} from 'shared/types/channel';

export const GET_CHANNEL_ACTIVITY = gql`
  query ($businessId: ID!, $channelId: ID!, $searchText: String, $pageNumber: Int, $pageSize: Int) {
    getChannelActivity(businessId: $businessId, channelId: $channelId, searchText: $searchText,  pageNumber: $pageNumber, pageSize: $pageSize, sortByDateCreated: true, loadAcknowledgements: true, loadFlaggedMessages: true, loadAdminData: true) {
      ${getChannelsFields}
    }
  }
`;

export const GET_STUDENT_CHANNELS = gql`
  query ($businessId: ID!, $centerId: [ID], $searchTerm: String, $pageNumber: Int, $pageSize: Int, $accountChildIds:[ID], $hasUnreadMessages: Boolean, $hasUnresolvedFlags: Boolean) {
    getStudentChannels(businessId: $businessId, centerId: $centerId, searchTerm: $searchTerm, pageNumber:$pageNumber, pageSize: $pageSize, accountChildIds: $accountChildIds, hasUnreadMessages: $hasUnreadMessages, hasUnresolvedFlags: $hasUnresolvedFlags, includeInactiveChannels: true) {
      ${getStudentChannelsFields}
    }
  }
`;

export const GET_MONTHLY_MESSAGES_STATISTICS = gql`
  query ($businessId: ID!, $input: GetMonthlyMessageStatisticsInput!) {
    getMonthlyMessagesSentStatistics(businessId: $businessId, input: $input) {
      messagesSent
      month
      personType
    }
  }
`;
export const GET_HIGHEST_DAILY_MESSAGE_STATISTICS = gql`
  query ($businessId: ID!, $input: GetHighestDailyMessagesPerChannelInput!) {
    getHighestDailyMessagesPerChannelStatistics(businessId: $businessId, input: $input) {
      channelId
      date
      messageCount
      accountChild {
        firstname
        lastname
        nickname
        id
        avatar {
          url
        }
      }
    }
  }
`;
export const GET_CENTER_SUMMARY = gql`
  query ($businessId: ID!, $input: GetCenterSummaryQueryInput!) {
    getCenterSummaryStatistics(businessId: $businessId, input: $input) {
      centerId
      unreadCount
      flaggedCount
      dailyAverage
      totalCount
    }
  }
`;

export const SEARCH_MESSAGE_ATTACHMENTS = gql`
  query ($businessId: ID!, $attachmentIds: [String]!, $generateDownloadLinks: Boolean!) {
    searchMessagingAttachments(
      businessId: $businessId
      input: { businessId: $businessId, generateDownloadLinks: $generateDownloadLinks, attachmentIds: $attachmentIds }
    ) {
      pageNumber
      pageSize
      totalRecords
      data {
        id
        businessId
        centerId
        userActivityMessageId
        fileName
        mimetype
        presignedUploadUrl
        presignedDownloadUrl
        uploadState
      }
    }
  }
`;

export const useGetChannelActivity = (
  options?: QueryHookOptions<IGetPaginatedChannelActivityData, IGetPaginatedChannelActivityVariables>
) =>
  useQuery(GET_CHANNEL_ACTIVITY, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useGetStudentChannels = (
  options?: QueryHookOptions<IGetStudentChannelsData, IGetStudentChannelsVariables>
) =>
  useQuery(GET_STUDENT_CHANNELS, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useGetMonthlyMessagesSentStatistics = (
  options?: QueryHookOptions<IGetStudentMonthlyChannelsData, IGetMonthlyMessagesSentStatisticsVariables>
) =>
  useQuery(GET_MONTHLY_MESSAGES_STATISTICS, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useGetHighestDailyMessagesPerChannelStatistics = (
  options?: QueryHookOptions<
    IGetHighestDailyMessagesPerChannelData,
    IGetHighestDailyMessagesPerChannelStatisticsVariables
  >
) =>
  useQuery(GET_HIGHEST_DAILY_MESSAGE_STATISTICS, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useGetCenterSummaryStatistics = (
  options?: QueryHookOptions<IGetCenterSummaryStatistics, IGetCenterSummaryStatisticsVariables>
) =>
  useQuery(GET_CENTER_SUMMARY, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useSearchAttachments = (
  options?: QueryHookOptions<
    { searchMessagingAttachments: IMessageAttachmentPagedResult },
    { businessId: string; attachmentIds: string[]; generateDownloadLinks: boolean }
  >
) =>
  useLazyQuery(SEARCH_MESSAGE_ATTACHMENTS, {
    fetchPolicy: 'network-only',
    ...options,
  });
