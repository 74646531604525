import React, { useCallback, useMemo, useState } from 'react';
import colors from '_colors.module.scss';
import { RootState } from 'store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPaginatedAgencyAccountChildrenForBusiness } from 'gql/agency/queries';
import { getAgencyAccountChildrenTableData } from '../duck/actions';
import SubsidyChildAttendanceModal from '../SubsidyChildAttendanceModal';
import { useTranslation } from 'react-i18next';
import ColoredNumberCard from 'shared/components/Card/ColoredNumberCard';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import _, { orderBy } from 'lodash';
import moment from 'moment';
import useFormatDate from 'shared/hooks/useFormatDate';
import { agencyAccountChildFields as defaultAgencyAccountChildFields } from 'gql/agency/fields';
import ChildrenTable, { SubsidyChildRow } from '../ChildrenTab/components/AgencyChildrenTable';
import { useAgencyChildrenTableFilters } from '../ChildrenTab/useAgencyChildrenTableFilters';
import { currencyFormat } from 'shared/util/currency';

interface ISubsidyChildModalState {
  open: boolean;
  agencyAccountChild: IAgencyAccountChild | null;
}

const agencyAccountChildFields = `
${defaultAgencyAccountChildFields}
agency {
  billingCalculationType
  subsidyPeriod
}
`;

interface AgencyAccountChildData extends IAgencyAccountChild {
  agency: {
    billingCalculationType: IAgency['billingCalculationType'];
    subsidyPeriod: IAgency['subsidyPeriod'];
  };
}

interface IProps {}
const ChildrenTab: React.FC<IProps> = () => {
  const { t } = useTranslation(['translation', 'subsidies']);
  const formatDate = useFormatDate();
  const dispatch = useDispatch();
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const tableMeta = useSelector((state: RootState) => state.billing.agencyAccountChildren.tableMetaData);

  const [subsidyChildModal, setSubsidyChildModal] = useState<ISubsidyChildModalState>({
    open: false,
    agencyAccountChild: null,
  });

  const {
    agencyFilterOptions,
    centerFilterOptions,
    enrollmwentStatusOptions,
    filterState,
    handleClearFilter,
    handleFilterUpdate,
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
    handleSort,
  } = useAgencyChildrenTableFilters();

  const { data, loading: queryLoading } = useGetPaginatedAgencyAccountChildrenForBusiness(
    {
      variables: {
        input: {
          businessId: currentBusinessId ?? '',
          searchKey: filterState.searchKey,
          sortBy: filterState.sortField,
          sortDirection: filterState.sortDirection,
          pageNumber: filterState.pageNumber,
          pageSize: filterState.pageSize,
          agencyIds: filterState.agencyIds,
          centerIds: filterState.centerIds,
          statuses: filterState.statuses,
        },
      },
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        dispatch(getAgencyAccountChildrenTableData(result.getPaginatedAgencyAccountChildrenForBusiness));
      },
    },
    agencyAccountChildFields
  );
  const agencyChildrenRows = useMemo<SubsidyChildRow[]>(() => {
    const x = (data?.getPaginatedAgencyAccountChildrenForBusiness.data ?? []) as AgencyAccountChildData[];
    return x.map((row: AgencyAccountChildData) => {
      /**
       * This is used as a dashboard type of screen to check on a child's enrollment status, more than the enrollment itself.
       * Therefore we try to pick the latest enrollment to use as the display information and status.
       */
      const enrollmentToShow = (() => {
        const today = moment();
        const sorted = orderBy(row.agencyAccountChildEnrollments, (s) => s.startDate, 'asc');
        const subsidy = sorted.find(
          (subsidy) => today.isSameOrAfter(moment(subsidy.startDate)) && today.isSameOrBefore(moment(subsidy.endDate))
        );

        return subsidy ?? sorted[sorted.length - 1];
      })();

      return {
        id: row.id,
        accountId: row.accountId,
        notes: enrollmentToShow.notes ?? '',
        initials: `${row.childFirstName.toUpperCase().charAt(0)}${row.childLastName.toUpperCase().charAt(0)}`,
        fullName: `${_.capitalize(row.childFirstName)} ${_.capitalize(row.childLastName)}`,
        enrollmentId: enrollmentToShow.childSubsidyEnrollmentId ?? undefined,
        avatarUrl: row.avatar?.url ?? '',
        accountName: row.accountName,
        centerName: row.centerName,
        agencyName: row.agencyName,
        startDate: formatDate(enrollmentToShow.startDate),
        endDate: formatDate(enrollmentToShow.endDate),
        subsidyAmount:
          row.agency.billingCalculationType === 'COPAY_AMOUNT'
            ? `${currencyFormat(enrollmentToShow.subsidyPaymentPortion)} (Copay)`
            : `${currencyFormat(enrollmentToShow.subsidyPaymentPortion)} (Subsidy)`,
        subsidyPaymentPeriodType: (() => {
          switch (row.agency.subsidyPeriod ?? '') {
            case 'SESSION':
              return 'Session';
            case 'EVERY_TWO_WEEKS':
              return 'Every two weeks';
            case 'MONTHLY':
              return 'Monthly';
            case 'WEEKLY':
              return 'Weekly';
            default:
              return '';
          }
        })(),
        status: row.status,
      };
    });
  }, [data?.getPaginatedAgencyAccountChildrenForBusiness.data, formatDate]);

  const handleViewChildAttendance = useCallback(
    (agencyAccountChildId: string) => {
      const agencyAccountChild = data?.getPaginatedAgencyAccountChildrenForBusiness.data.find(
        (a) => a.id === agencyAccountChildId
      );
      if (agencyAccountChild) {
        setSubsidyChildModal({ open: true, agencyAccountChild });
      }
    },
    [data?.getPaginatedAgencyAccountChildrenForBusiness.data]
  );

  return (
    <>
      <div className={isMobile ? 'd-flex flex-wrap' : 'display-flex justify-content-between flex-wrap'}>
        <div
          className={
            isMobile
              ? 'd-flex flex-direction-column flex-column flex-wrap'
              : 'd-flex flex-direction-row justify-content-between mb-4'
          }
        >
          <ColoredNumberCard
            //@ts-ignore
            title={t('subsidies:children.expired')}
            description={t('subsidies:children.expired-description')}
            color={colors.danger}
            number={tableMeta.numberOfExpired ?? 0}
            loading={queryLoading}
            className={isMobile ? '' : ''}
          />
          <ColoredNumberCard
            title={t('subsidies:children.expiring')}
            description={t('subsidies:children.expiring-description')}
            color={colors.warning}
            number={tableMeta.numberOfExpiringSoon ?? 0}
            loading={queryLoading}
            className={isMobile ? '' : ''}
          />
          <ColoredNumberCard
            title={t('subsidies:children.active')}
            description={t('subsidies:children.active-description')}
            color={colors.success}
            number={tableMeta.numberOfActive ?? 0}
            loading={queryLoading}
            className={isMobile ? '' : ''}
          />
        </div>
      </div>
      <ChildrenTable
        activePage={filterState.pageNumber}
        agencyFilterOptions={agencyFilterOptions}
        centerFilterOptions={centerFilterOptions}
        data={agencyChildrenRows}
        onClearAppliedFiltersClick={handleClearFilter}
        onFilterChange={handleFilterUpdate}
        onPageChange={handlePageChange}
        onSearchTermChange={handleSearchTermChange}
        onSizePerPageChange={handlePageSizeChange}
        onSort={handleSort}
        onViewChildAttendance={handleViewChildAttendance}
        pageSize={filterState.pageSize}
        showLoadingOverlay={queryLoading}
        searchTerm={filterState.searchKey}
        selectedAgencyIds={filterState.agencyIds}
        selectedCenterIds={filterState.centerIds}
        selectedStatuses={filterState.statuses}
        statusFilterOptions={enrollmwentStatusOptions}
      ></ChildrenTable>

      {subsidyChildModal.agencyAccountChild && (
        <SubsidyChildAttendanceModal
          isOpen={subsidyChildModal.open}
          agencyAccountChild={subsidyChildModal.agencyAccountChild}
          onClose={() => setSubsidyChildModal({ open: false, agencyAccountChild: null })}
        />
      )}
    </>
  );
};

export default ChildrenTab;
