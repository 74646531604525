import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import Alert from 'shared/components/Alert';
import DataTable, { TableHeader, TableSearch, SizePerPage, TABLE_DEFAULTS } from 'shared/components/DataTable';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import Select from 'shared/components/Select';
import useDatatableState from 'shared/hooks/useDatatableState2';
import centerStatusFilterOptions from 'shared/constants/CenterStatusSearchExpressions';
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { isRegion } from 'shared/util/region';
import { useGetPaginatedCentersForBusiness } from 'gql/center/queries';
import { useUpdateCenterPayPeriod } from 'gql/center/mutations';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { useGetCenterStatesInUse } from 'shared/hooks/useGetCenterStatesInUse';

const DAY_OF_WEEK_OPTIONS = [
  { label: 'Sunday', value: 'SUNDAY' },
  { label: 'Monday', value: 'MONDAY' },
  { label: 'Tuesday', value: 'TUESDAY' },
  { label: 'Wednesday', value: 'WEDNESDAY' },
  { label: 'Thursday', value: 'THURSDAY' },
  { label: 'Friday', value: 'FRIDAY' },
  { label: 'Saturday', value: 'SATURDAY' },
];

interface IFiltersStateShape {
  sortField: string;
  sortDirection: string;
  term: string;
  states: string[];
  statuses: string[];
  tagIds: string[];
}

interface IProps {}

const PayWeekTab: React.FC<IProps> = ({ ...props }) => {
  const { t } = useTranslation(['settings']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const [tableData, setTableData] = useState<ICenter[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<IFiltersStateShape>({
    sortField: 'name',
    sortDirection: 'asc',
    term: '',
    states: [],
    statuses: [],
    tagIds: [],
  });
  const hasEditOperationsPermission = useHasRoleAreaLevel({
    area: AreaType.Operations,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const { data: tagsInUseData } = useGetTagsInUse(TagsTypeElasticIndex.Center);
  const [tableState, tableFunctions] = useDatatableState();
  const defaultStateOptions = isRegion('AU') ? STATE_SELECT_OPTIONS['AU'] : STATE_SELECT_OPTIONS['US'];
  const { data: getPaginatedCentersData, loading: getPaginatedCentersLoading } = useGetPaginatedCentersForBusiness(
    {
      variables: {
        input: {
          businessId,
          pageNumber: tableState.activePage,
          pageSize: tableState.pageSize,
          searchKey: selectedFilters.term,
          sortBy: selectedFilters.sortField,
          sortDirection: selectedFilters.sortDirection,
          states: selectedFilters.states,
          statuses: selectedFilters.statuses,
          tagIds: selectedFilters.tagIds,
        },
      },
      onCompleted: (result) => {
        setTableData(result.getPaginatedCentersForBusiness.data);
      },
    },
    `id name avatar { url } payPeriodWeekStart payPeriodWeekEnd`
  );

  const [updateCenterPayPeriodFn] = useUpdateCenterPayPeriod(
    {
      onCompleted: (result) => {
        setTableData((prev) =>
          prev.map((center) =>
            center.id === result.updateCenterPayPeriod.id ? { ...center, ...result.updateCenterPayPeriod } : center
          )
        );
      },
    },
    `id payPeriodWeekStart payPeriodWeekEnd`
  );

  // just like the center profiles table (powered by elasticsearch) we only show states where centers are lcoated in the filter
  const statesInUse: string[] = useGetCenterStatesInUse()?.data?.getCenterStatesUsedAcrossEntity || [];
  const stateOptions =
    !!statesInUse && statesInUse.length > 0
      ? defaultStateOptions.filter((state) => statesInUse.includes(state.value))
      : defaultStateOptions;

  const updateFilters = useCallback(
    (updates: Partial<IFiltersStateShape>) => {
      setSelectedFilters((prev) => ({ ...prev, ...updates }));
      tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.ITEM_OFFSET);
    },
    [tableFunctions]
  );

  const handleSearchTerm = debounce(
    useCallback(
      (term: string) => {
        updateFilters({ term });
      },
      [updateFilters]
    ),
    250
  );

  const handleSort = useCallback(
    (column: string, direction: ElasticsearchSortDirection) => {
      const sortDirection = direction === 'ASCENDING' ? 'asc' : 'desc';

      updateFilters({ sortField: column, sortDirection });
    },
    [updateFilters]
  );

  const updateCenterPayPeriod = useCallback(
    (centerId: string, startDay: DayOfWeek, endDay: DayOfWeek) => {
      updateCenterPayPeriodFn({
        variables: {
          input: {
            id: centerId,
            payPeriodWeekStart: startDay,
            payPeriodWeekEnd: endDay,
          },
        },
      });
    },
    [updateCenterPayPeriodFn]
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <PageWrapperBody>
      {user?.isInternal && <CenterSelectBanner pageName="operations" showCenterSelect={false} />}
      <br />
      <DataTable
        noPadding
        keyField="id"
        data={tableData}
        dataSize={getPaginatedCentersData?.getPaginatedCentersForBusiness.totalRecords ?? 0}
        showLoadingOverlay={getPaginatedCentersLoading}
        showSelect={false}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        onSort={handleSort}
        columns={[
          {
            text: t('settings:operations.pay-week.center-column'),
            sort: true,
            dataField: 'name',
            formatter: (cell: string, row: ICenter) => (
              <AvatarDataTableCell
                initials={row.name && row.name[0].toUpperCase()}
                avatar={row.avatar?.url ?? ''}
                primaryText={row.name}
              />
            ),
          },
          {
            text: t('settings:operations.pay-week.start-day-column'),
            sort: true,
            dataField: 'payPeriodStartDay',
            formatter: (cell: string, row: ICenter) => (
              <Select
                value={DAY_OF_WEEK_OPTIONS.find((opt) => opt.value === row.payPeriodWeekStart)}
                options={DAY_OF_WEEK_OPTIONS}
                onChange={(opt) => updateCenterPayPeriod(row.id, opt.value as DayOfWeek, row.payPeriodWeekEnd)}
                className="mb-0 w-50"
                menuPortalTarget={document.body}
                disabled={!hasEditOperationsPermission}
              />
            ),
          },
          {
            text: t('settings:operations.pay-week.end-day-column'),
            sort: true,
            dataField: 'payPeriodEndDay',
            formatter: (cell: string, row: ICenter) => (
              <Select
                value={DAY_OF_WEEK_OPTIONS.find((opt) => opt.value === row.payPeriodWeekEnd)}
                options={DAY_OF_WEEK_OPTIONS}
                onChange={(opt) => updateCenterPayPeriod(row.id, row.payPeriodWeekStart, opt.value as DayOfWeek)}
                className="mb-0 w-50"
                menuPortalTarget={document.body}
                disabled={!hasEditOperationsPermission}
              />
            ),
          },
        ]}
        renderHeader={(paginationProps, searchProps) => (
          <>
            <TableHeader className="flex-wrap align-items-center">
              <div className={isMobile ? 'd-flex flex-wrap ml-2 mr-auto' : 'd-flex flex-wrap mr-auto'}>
                <SizePerPage paginationProps={paginationProps} className={isMobile ? 'my-1' : ''} />
                <TableSearch placeholder="Search" onChange={handleSearchTerm} className={isMobile ? 'my-1' : ''} />
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <DropdownFilter
                  title="Status"
                  className={isMobile ? 'my-1 ml-2' : 'my-0 mr-2 ml-auto p-0'}
                  selectedFilters={selectedFilters.statuses}
                  options={Object.values(centerStatusFilterOptions)}
                  onFilterSelect={(filters) => updateFilters({ statuses: filters.map((f) => f.value) })}
                />
                <DropdownFilter
                  title="State"
                  className={isMobile ? 'my-1 pl-0 ml-2' : 'my-0 mx-2 p-0'}
                  selectedFilters={selectedFilters.states}
                  options={stateOptions}
                  onFilterSelect={(filters) => updateFilters({ states: filters.map((f) => f.value) })}
                />
                <DropdownFilter
                  title="Tags"
                  className={isMobile ? 'my-1 pl-0 ml-2' : 'my-0 mx-2 p-0'}
                  selectedFilters={selectedFilters.tagIds}
                  options={(tagsInUseData?.getTagsUsedAcrossEntity ?? []).map((t) => ({ label: t.name, value: t.id }))}
                  onFilterSelect={(filters) => updateFilters({ tagIds: filters.map((f) => f.value) })}
                />
                <IconButtonCircle
                  icon={faTimes}
                  onClick={() => updateFilters({ tagIds: [], states: [], statuses: [] })}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                  className={isMobile ? 'mt-1 ml-2' : 'my-0 ml-2 p-0'}
                />
              </div>
            </TableHeader>
            <Alert variant="info" className="mt-4">
              {t('settings:operations.pay-week.info-alert')}
            </Alert>
          </>
        )}
      />
    </PageWrapperBody>
  );
};

export default PayWeekTab;
