import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import { TimezoneContext } from 'shared/contexts/timezoneContext';
import { IDailyAttendanceForDate } from './TimeCards';
import TotalsTimeCard from './TotalsTimeCard';
import { isDateWithinTimeframe } from 'shared/util/timeUtils';

export interface IPayPeriodDailyAttendanceGrouping {
  payPeriodStartDate: string;
  payPeriodEndDate: string;
  dailyAttendances: IDailyAttendance[];
  timeOffHoursApproved: number;
}

interface IProps {
  dailyAttendances: IDailyAttendanceForDate[];
  timeOffRequestsForTimeframe: ITimeOff[];
  isLoading: boolean;
  startDate: string;
  endDate: string;
  payPeriodStartDay: DayOfWeek;
  payPeriodEndDay: DayOfWeek;
  centerId: string;
}

const TotalsTimeCardView: React.FC<IProps> = ({
  dailyAttendances,
  timeOffRequestsForTimeframe,
  isLoading,
  payPeriodStartDay,
  payPeriodEndDay,
  startDate,
  endDate,
  centerId,
  ...props
}) => {
  const centerTimezoneContext = useContext(TimezoneContext);

  const getNumberOfDaysTimeOffSpansInPayPeriod = useCallback((timeOff: ITimeOff, start: string, end: string) => {
    let numberOfDays = 0;
    let timeOffMoment = moment(timeOff.startTime);
    const startMoment = moment(start);
    const endMoment = moment(end);

    while (timeOffMoment.isSameOrBefore(endMoment, 'date')) {
      // only increment non-weekend days
      const isWeekday = timeOffMoment.day() !== 0 && timeOffMoment.day() !== 6;

      if (timeOffMoment.isBetween(startMoment, endMoment, 'date', '[]') && isWeekday) {
        numberOfDays += 1;
      }

      timeOffMoment = timeOffMoment.clone().add(1, 'days');
    }

    return numberOfDays;
  }, []);

  const groupDataByPayPeriod = useCallback(
    (data: IDailyAttendanceForDate[], timeOffRequests: ITimeOff[]): IPayPeriodDailyAttendanceGrouping[] => {
      const groups: IPayPeriodDailyAttendanceGrouping[] = [];

      const endDateMoment = moment(endDate).tz(centerTimezoneContext.timezone);
      let payPeriodStartDate = moment(startDate).tz(centerTimezoneContext.timezone);
      // this may not work if my pay period is Wednesday - Tuesday. we need the next instance of the endDay. test this
      let payPeriodEndDate = payPeriodStartDate.clone().day(payPeriodEndDay); // may need to get numeric day of week from string, we see

      if (payPeriodEndDate.isSameOrBefore(payPeriodStartDate, 'date')) {
        payPeriodEndDate = payPeriodEndDate.clone().add(1, 'week');
      }

      while (payPeriodStartDate.isBefore(endDateMoment)) {
        const dailyAttendancesBetweenForPayPeriod = data
          // eslint-disable-next-line no-loop-func
          .filter((datum) => {
            const dateMoment = moment(datum.date).tz(centerTimezoneContext.timezone);

            return dateMoment.isSameOrAfter(payPeriodStartDate) && dateMoment.isSameOrBefore(payPeriodEndDate);
          })
          .reduce((acc, curr) => {
            if (curr.attendance) {
              acc.push(curr.attendance);
            }

            return acc;
          }, [] as IDailyAttendance[]);

        groups.push({
          payPeriodStartDate: payPeriodStartDate.toISOString(),
          payPeriodEndDate: payPeriodEndDate.toISOString(),
          dailyAttendances: dailyAttendancesBetweenForPayPeriod,
          timeOffHoursApproved: timeOffRequests
            // eslint-disable-next-line no-loop-func
            .filter(
              (te) =>
                (isDateWithinTimeframe(
                  te.startTime,
                  payPeriodStartDate.toISOString(),
                  payPeriodEndDate.toISOString()
                ) ||
                  isDateWithinTimeframe(
                    te.endTime,
                    payPeriodStartDate.toISOString(),
                    payPeriodEndDate.toISOString()
                  )) &&
                te.status === 'Approved' &&
                te.centerId === centerId
            )
            // eslint-disable-next-line no-loop-func
            .reduce<number>((acc, curr) => {
              const hoursApproved = curr.hoursApproved ?? 0;
              const startTimeWeekOfYear = moment(curr.startTime).week();
              const endTimeWeekOfYear = moment(curr.endTime).week();
              const timeOffSpansMultipleWeeks = startTimeWeekOfYear !== endTimeWeekOfYear;

              if (timeOffSpansMultipleWeeks) {
                const daysBeforePayPeriod = getNumberOfDaysTimeOffSpansInPayPeriod(
                  curr,
                  curr.startTime,
                  payPeriodStartDate.clone().subtract(1, 'day').tz(centerTimezoneContext.timezone).toISOString()
                );
                const numberOfDaysInPeriod = getNumberOfDaysTimeOffSpansInPayPeriod(
                  curr,
                  payPeriodStartDate.toISOString(),
                  payPeriodEndDate.toISOString()
                );

                const approvedPaidHoursRemaining = hoursApproved - daysBeforePayPeriod * 8; // 8 hours equals 1 full day
                const totalNumberOfHoursForPeriod = numberOfDaysInPeriod * 8;
                const approvedHoursForPeriod =
                  approvedPaidHoursRemaining - totalNumberOfHoursForPeriod > 0
                    ? totalNumberOfHoursForPeriod
                    : approvedPaidHoursRemaining;

                return (acc += Math.abs(approvedHoursForPeriod));
              }

              return (acc += hoursApproved);
            }, 0),
        });

        payPeriodStartDate = payPeriodStartDate.clone().add(1, 'week');
        payPeriodEndDate = payPeriodEndDate.clone().add(1, 'week');
      }

      return groups;
    },
    [
      endDate,
      centerTimezoneContext.timezone,
      startDate,
      payPeriodEndDay,
      centerId,
      getNumberOfDaysTimeOffSpansInPayPeriod,
    ]
  );

  return (
    <>
      {groupDataByPayPeriod(dailyAttendances, timeOffRequestsForTimeframe).map((group, idx) => (
        <TotalsTimeCard key={idx} payPeriodData={group} />
      ))}
    </>
  );
};

export default TotalsTimeCardView;
