import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import { faSortUp, faSortDown } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  previous?: number;
  current: number;
  suffix?: string;
}

const EntitlementChangeIndicator: React.FC<IProps> = ({ previous, current, suffix, ...props }) => {
  if (!previous || previous === current) {
    return <>0 {suffix ?? ''}</>;
  }

  // Be precise for percentages, otherwise use integers.
  const digits = suffix === '%' ? 2 : 0;

  return previous < current ? (
    <>
      <span className="mr-1">
        {(current - previous).toFixed(digits)} {suffix ?? ''}
      </span>
      <FontAwesomeIcon icon={faSortUp} color={colors.success} opacity={0.5} size="1x" />
    </>
  ) : (
    <>
      <span className="mr-1">
        {(previous - current).toFixed(digits)} {suffix ?? ''}
      </span>
      <FontAwesomeIcon icon={faSortDown} color={colors.danger} opacity={0.5} size="1x" />
    </>
  );
};

export default EntitlementChangeIndicator;
