import {
  CLEAR_FIELD_VALIDATION_STATE,
  EnrolmentFormActionTypes,
  UPDATE_FIELD_VALIDATION_STATE,
} from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/duck/types';

export const updateFieldValidationState = (
  enrolmentFormId: string,
  fieldValidationState: {
    fieldName: string;
    isValid?: boolean;
    isArchived?: boolean;
  }
): EnrolmentFormActionTypes => ({
  enrolmentFormId,
  type: UPDATE_FIELD_VALIDATION_STATE,
  fieldValidationState,
});

export const clearFieldValidationState = (enrolmentFormId: string): EnrolmentFormActionTypes => ({
  type: CLEAR_FIELD_VALIDATION_STATE,
  enrolmentFormId: enrolmentFormId,
});
