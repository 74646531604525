import React, { useCallback } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { IRoute } from 'shared/util/routes';
import K2SidebarItem from '../K2Sidebar/K2SidebarItem';

interface IProps {
  routes: IRoute[];
  navigateTo: (route: IRoute) => void;
  toggleMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const K2MobileMenu: React.FC<IProps> = ({ routes, navigateTo, toggleMobileMenu, ...props }) => {
  const handleClick = useCallback(
    (route: IRoute) => {
      toggleMobileMenu(false);
      navigateTo(route);
    },
    [toggleMobileMenu, navigateTo]
  );

  return (
    <Navbar.Collapse id="kangarootime-navbar-collapse-nav">
      <Nav className="mr-auto d-lg-none k2-navigation-mobile-nav-dropdown">
        {routes.map((route: IRoute, idx: number) => (
          <K2SidebarItem
            key={`mobile-menu-route-${route.name}-${idx}`}
            sidebarExpanded
            route={route}
            expandSidebar={() => {}}
            navigateTo={handleClick}
          />
        ))}
      </Nav>
    </Navbar.Collapse>
  );
};

export default K2MobileMenu;
