import React from 'react';
import useUniqueId from 'shared/hooks/useUniqueId';
import Select, { components, Props as SelectProps } from 'react-select';
import './_select.scss';

interface IProps {
  id?: string;
}

// minimally styled select component
const BaseSelect: React.FC<IProps & SelectProps> = ({ id, ...props }) => {
  const selectId = useUniqueId(id);

  return (
    <Select
      id={selectId}
      className={`react-select-container ${props.className}`}
      classNamePrefix="react-select"
      components={{ IndicatorSeparator: null, ...props.components }}
      {...props}
    />
  );
};

export default BaseSelect;
