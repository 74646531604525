import React from 'react';
import { useHistory } from 'react-router-dom';

import * as Mui from '@mui/material';

import Avatar from 'shared/components/Avatar';
import { faPencil } from '@fortawesome/pro-light-svg-icons';

import IconButtonCircle from 'shared/components/Buttons/IconButtonCircle';

interface ICentersTableRowProps {
  row: ICenter;
  index: number;
  key: string;
}

const CentersTable: React.FC<ICentersTableRowProps> = ({ row }) => {
  const history = useHistory();

  return (
    <Mui.TableRow
      key={'row?.channelId ?? '}
      sx={{ borderTop: 'solid 10px #F8FAFB', borderBottom: 'solid 10px #F8FAFB' }}
    >
      <Mui.TableCell align="left" sx={{ paddingLeft: 0 }}>
        <Mui.CardHeader
          avatar={
            <Avatar image={row?.avatar?.url ?? ''} size="md" initials={row?.name && row?.name[0].toUpperCase()} />
          }
          title={<Mui.Typography variant="body1">{row?.name}</Mui.Typography>}
          sx={{ paddingLeft: 0, borderBottom: 0 }}
        />
      </Mui.TableCell>
      <Mui.TableCell>
        <Mui.Typography variant="body1">{row?.address?.address1}</Mui.Typography>
        <Mui.Typography variant="body1">{`${row?.address?.city} ${row?.address?.state}, ${row?.address?.postalCode}`}</Mui.Typography>
      </Mui.TableCell>
      <Mui.TableCell align="right" sx={{ paddingRight: 0 }}>
        <IconButtonCircle
          id="action-dropdown-btn"
          iconSize="lg"
          icon={faPencil}
          onClick={() => history.push(`/centers/profiles/${row?.id}`)}
        />
      </Mui.TableCell>
    </Mui.TableRow>
  );
};

export default CentersTable;
