import { useEffect, useState } from 'react';
import moment from 'moment';
import { ButtonGroup, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

interface IProps {
  isAM: boolean;
  value?: string | null;
  onChange: (time: any) => void;
  disabled?: boolean;
}
const dataFormats = ['hh:mm', 'h:mm', 'hhmm', 'hmm', 'hh:mm A'];

const TimeInput: React.FC<IProps> = ({ isAM, value, onChange, disabled }) => {
  const [inputTime, setInputTime] = useState<string>('');
  const [selected, setSelected] = useState<number>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    var time = moment(value, 'HH:mm');
    if (time.isValid()) {
      setInputTime(time.format('hh:mm'));
      setIsValid(true);
      setSelected(time.format('A') === 'AM' ? 1 : 2);
    } else setSelected(isAM ? 1 : 2);
  }, [isAM, value]);

  const handlePaste = (e) => {
    const value = e.clipboardData.getData('text');
    const time = moment(value, dataFormats, true);

    if (time.isValid()) {
      setInputTime(time.format('hh:mm'));
      setIsValid(true);
    } else {
      setIsValid(false);
      setInputTime('');
    }
    e.preventDefault();
  };
  const handleInput = (e) => {
    let value = e.target.value;
    const length = value.length;
    let isCol = false;
    const key = e.nativeEvent.data;
    const { selectionStart, selectionEnd } = e.target;
    if ((length === 5 && /^[3-9]/.test(value)) || (selectionEnd === 4 && /[6-9]/.test(key))) {
      window.requestAnimationFrame(() => {
        e.target.setSelectionRange(selectionStart - 1, selectionEnd - 1);
      });
      return;
    }

    if (!/^0?[1-9]|1[0-2]/.test(value) && length >= 2 && !/:/.test(value)) {
      setInputTime('');
      return;
    } else if (key == null) {
      const obj = moment(value, 'hhmm', true);
      if (obj.isValid()) {
        setInputTime(obj.format('hh:mm'));
        setIsValid(true);
      } else if (/:/.test(inputTime) && !/:/.test(value)) setInputTime('');
      else setInputTime(value);
      window.requestAnimationFrame(() => {
        e.target.setSelectionRange(selectionStart, selectionEnd);
      });
      return;
    } else if (
      !/[0-9]/.test(key) ||
      (/:$/.test(inputTime) && /[6-9]/.test(key)) ||
      (length === 2 && /^[0]/.test(value) && /0|:/.test(key))
    ) {
      window.requestAnimationFrame(() => {
        e.target.setSelectionRange(selectionStart - 1, selectionEnd - 1);
      });
      return;
    }

    if (/:/.test(value)) isCol = true;
    if (/:/.test(key) && (isCol || length === 0)) return;
    if (length === 1) {
      if (/[2-9]/.test(key)) value = '0' + key + ':';
    } else if (!isCol && /^0|1/.test(value)) {
      if (/:/.test(key)) {
        value = '0' + value + ':';
      } else if (length === 2) {
        if (/^1/.test(value)) {
          if (/[6-9]/.test(key)) return;
          else if (/[3-5]/.test(key)) value = '0' + value[0] + ':' + key;
          else if (/[0-2]/.test(key)) value = value + ':';
        } else if (/^0/.test(value)) value = value + ':';
      } else if (/[0-2]/.test(key)) {
        value = value + key + ':';
      }
    }

    const timeFormat = moment(`${value} ${selected === 1 ? 'AM' : 'PM'}`, dataFormats, true);

    if (timeFormat.isValid()) {
      value = timeFormat.format('hh:mm');
      onChange(timeFormat.format('HH:mm'));
      setIsValid(true);
    }
    setInputTime(value);
  };
  const handleToggle = (e) => {
    if (e.key === 'Tab') return;
    const selectedValue = selected === 1 ? 2 : 1;
    const formattedTime = moment(`${inputTime} ${selectedValue === 1 ? 'AM' : 'PM'}`, ['hh:mm A', 'hhmm A'], true);
    onChange(formattedTime.format('HH:mm'));
    setSelected(selectedValue);
  };
  const handleBlur = (e) => {
    const time = moment(`${e.target.value} ${selected === 1 ? 'AM' : 'PM'}`, [...dataFormats, 'h:mm A'], true);
    if (time.isValid()) {
      setInputTime(time.format('hh:mm'));
      setIsValid(true);
    } else setIsValid(false);
    onChange(time.format('HH:mm'));
  };
  const handleOnSelect = (e) => {
    const { selectionEnd } = e.target;
    const index = e.target.value.indexOf(':');
    const length = e.target.value.length;
    if (index === 2 && selectionEnd === 3 && selectedIndex === 2)
      e.target.selectionStart = e.target.selectionEnd = index + 2;
    else if (!(length === 4 && selectionEnd === 3) && length != index + 1 && index && selectionEnd === index + 1)
      e.target.selectionStart = e.target.selectionEnd = index;

    setSelectedIndex(selectionEnd);
  };

  return (
    <ButtonGroup className={`time-selector-group ${inputTime && !isValid ? 'invalid' : ''}`}>
      <Form.Control
        maxLength={5}
        minLength={5}
        style={{ border: 'none' }}
        placeholder="HH:MM"
        aria-describedby="Select Time"
        value={inputTime}
        onPaste={(e) => handlePaste(e)}
        onChange={(e) => handleInput(e)}
        onSelect={(e) => handleOnSelect(e)}
        onBlur={(e) => handleBlur(e)}
        onDragStart={(event) => event.preventDefault()}
        disabled={disabled}
      />
      <ToggleButtonGroup
        tabIndex={0}
        className="time-selector-group"
        size="sm"
        type="radio"
        style={{ border: 'none' }}
        name="options"
        value={selected}
        onKeyUp={(e) => handleToggle(e)}
        onChange={(e) => handleToggle(e)}
      >
        <ToggleButton disabled={disabled} variant="outline" size="sm" value={1}>
          AM
        </ToggleButton>
        <ToggleButton disabled={disabled} variant="outline" size="sm" value={2}>
          PM
        </ToggleButton>
      </ToggleButtonGroup>
    </ButtonGroup>
  );
};

export default TimeInput;
