const userType = {
  STAFF: 'STAFF',
  INTERNAL: 'INTERNAL',
};

// These should line up with types found in role.d.ts
export enum AreaType {
  Center = 'Center',
  Class = 'Class',
  Fees = 'Fees',
  Schedule = 'Schedule',
  Person = 'Person',
  Staff = 'Staff',
  Contact = 'Contact',
  Child = 'Child',
  Account = 'Account',
  PayRate = 'PayRate',
  Enrollment = 'Enrollment',
  Contracts = 'Contracts',
  Attendance = 'Attendance',
  Ccss = 'Ccss',
  Billing = 'Billing',
  Business = 'Business',
  Spaces = 'Spaces', // NO
  Statement = 'Statement',
  Report = 'Report',
  Kiosk = 'Kiosk',
  Agency = 'Agency',
  Activities = 'Activities',
  Comms = 'Comms',
  Operations = 'Operations',
}

export enum PermissionType {
  Base = 'Base',
  Employment = 'Employment',
  PayRate = 'PayRate',
  Position = 'Position',
  Medical = 'Medical',
  Documents = 'Documents',
  Notes = 'Notes',
  Tags = 'Tags',
  Permissions = 'Permissions',
  Spaces = 'Spaces',
  CheckInOut = 'CheckInOut',
  TransactionTypes = 'TransactionTypes',
  Payments = 'Payments',
  Surcharges = 'Surcharges',
  BillingCycleTemplates = 'BillingCycleTemplates',
  AccountBillingCycles = 'AccountBillingCycles',
  BillingSettings = 'BillingSettings',
  TimeLog = 'TimeLog',
  TimeOff = 'TimeOff',
  Contracts = 'Contracts',
  LeadManagementMain = 'LeadManagementMain',
  LeadManagementSettings = 'LeadManagementSettings',
  Fees = 'Fees',
  Classes = 'Classes',
  InternalNotes = 'InternalNotes',
  Billing = 'Billing',
  Roles = 'Roles',
  PaymentMethods = 'PaymentMethods',
  Discount = 'Discount',
  DiscountManagement = 'DiscountManagement',
  Adjustments = 'Adjustments',
  AgencyChild = 'AgencyChild',
  AgencyMain = 'AgencyMain',
  Photo = 'Photo',
  BusRoster = 'BusRoster',
  ItemizedBills = 'ItemizedBills',
  CustomFields = 'CustomFields',
  BillingCycle = 'BillingCycle',
  CasualBookingManagement = 'CasualBookingManagement',
  CasualBookingSettings = 'CasualBookingSettings',
  EndOfCare = 'EndOfCare',
  AccountMessaging = 'AccountMessaging',
  BulletinBoard = 'BulletinBoard',
  StudentMessaging = 'StudentMessaging',
  Deposit = 'Deposit',
  FamilyEnrollmentRequests = 'FamilyEnrollmentRequests',
  FamilyUpdateDetailsRequests = 'FamilyUpdateDetailsRequests',
}

export enum RoleLevelType {
  None = 0,
  Read = 1,
  Edit = 1 << 1,
  Create = 1 << 2,
  Delete = 1 << 3,
}

export default userType;
