export const getIsRoleARankedAboveRoleB = (roleA?: IRole, roleB?: IRole): boolean =>
  // a lower `hierarchyLevel` indicates a higher spot in the hierarchy structure
  (roleA?.hierarchyLevel ?? Infinity) < (roleB?.hierarchyLevel ?? Infinity);

export const isHighestRole = (role?: IRole, businessRoles?: IRole[]): boolean => {
  const roleHierarchyLevels = businessRoles?.map((role) => role.hierarchyLevel) ?? [Infinity];
  // hierarchy is in ascending order (0 is highest, followed by 1, etc.)
  const highestLevel = Math.min(...roleHierarchyLevels);
  return role?.hierarchyLevel === highestLevel;
};
