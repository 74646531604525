import * as types from './types';

export const getAgenciesTableData = (agencies: IAgency[]): types.AgencyActionTypes => ({
  type: types.GET_AGENCIES_TABLE_DATA,
  agencies,
});

export const createAgency = (agency: IAgency): types.AgencyActionTypes => ({
  type: types.CREATE_AGENCY,
  agency,
});

export const updateAgency = (agency: IAgency): types.AgencyActionTypes => ({
  type: types.UPDATE_AGENCY,
  agency,
});
