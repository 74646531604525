import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { programDetailsFields } from './fields';

export interface InputVariable<T> {
  input: T;
}

export const MUTATE_PROGRAM_CREATE_GQL = gql`
  mutation ($input: ProgramCreateInput!) {
    createProgram(input: $input) {
      id
    }
  }
`;

export const MUTATE_PROGRAM_UPDATE_GQL = gql`
  mutation ($input: ProgramUpdateInput!) {
      updateProgram(input: $input) {
        ${programDetailsFields}
      }
  }
`;

interface IProgram {
  id: string;
}

interface IProgramCreateData {
  createProgram: IProgram;
}

interface IProgramUpdateData {
  updateProgram: IProgramDetails;
}

export interface ProgramSchedule {
  day: DayOfWeek;
  startTime?: string;
  endTime?: string;
}

interface IProgramCreateUpdateVariables {
  programId?: string;
  subsidyId: string;
  programName: string;
  programStartDate: string;
  programEndDate?: string;
  schedules: ProgramSchedule[];
  classIds: string[];
  educators: IProgramTeacher[];
  centerId: string;
  businessId: string;
  paymentRule?: IQkfsPaymentRule;
  fundingSchedules?: IKindyProgramFundingScheduleInput[];
}

export const useProgramCreate = (
  options?: MutationHookOptions<IProgramCreateData, InputVariable<IProgramCreateUpdateVariables>>
) => useMutation<IProgramCreateData, InputVariable<IProgramCreateUpdateVariables>>(MUTATE_PROGRAM_CREATE_GQL, options);

export const useProgramUpdate = (
  options?: MutationHookOptions<IProgramUpdateData, InputVariable<IProgramCreateUpdateVariables>>
) => useMutation<IProgramUpdateData, InputVariable<IProgramCreateUpdateVariables>>(MUTATE_PROGRAM_UPDATE_GQL, options);
