import React, { useCallback } from 'react';
import Select from 'shared/components/Select';
import { Row, Col } from 'shared/components/Layout';
import PhoneInput from 'shared/components/PhoneInput';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from 'shared/components/Buttons';

interface IProps {
  phoneNumber: IPhoneNumber;
  updatePhoneNumber: (phoneNumber: IPhoneNumber) => void;
  label: string;
  required?: boolean;
  errorText?: string;
  isInvalid?: boolean;
  handleRemove?: () => void;
  disabled?: boolean;
  extraDiv?: boolean;
  showErrorMessage?: boolean;
}

const PhoneNumberAndTypeInput: React.FC<IProps> = ({
  phoneNumber,
  updatePhoneNumber,
  label,
  required = false,
  errorText,
  isInvalid = false,
  disabled = false,
  extraDiv = true,
  showErrorMessage = true,
  handleRemove,
  ...props
}) => {
  const handleChange = useCallback(
    (value: any, name: string) => {
      updatePhoneNumber({
        ...phoneNumber,
        [name]: value,
      });
    },
    [phoneNumber, updatePhoneNumber]
  );

  return (
    <Row align="start">
      <Col xs={extraDiv ? 6 : 7}>
        <PhoneInput
          required={required}
          label={label}
          value={phoneNumber.number}
          onChange={(value) => handleChange(value, 'number')}
          errorText={errorText}
          isInvalid={isInvalid}
          disabled={disabled}
        />
      </Col>
      <Col xs={5}>
        <Select
          required={required}
          options={['Mobile', 'Home', 'Work']}
          label="Type"
          value={phoneNumber.type}
          onChange={(value) => handleChange(value, 'type')}
          errorText="Select a type."
          isInvalid={required && !phoneNumber.type}
          disabled={disabled}
          showErrorMessage={showErrorMessage}
        />
      </Col>
      {extraDiv && (
        <Col xs={1} className="mt-8">
          {handleRemove && !disabled && <IconButton icon={faTrashAlt} onClick={handleRemove} />}
        </Col>
      )}
    </Row>
  );
};

export default PhoneNumberAndTypeInput;
