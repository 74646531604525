import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';
import { timeEntryFields } from 'gql/timeEntry/fields';

interface IUpdateTimeEntryVariables {
  input: {
    id: string;
    personId?: string;
    centerId: string;
    shiftId?: string;
    scheduleId?: string;
    positionId?: string;
    trackedTimeIn?: string;
    trackedTimeOut?: string;
    type: TimeEntryType;
    note: string | null;
  };
}

interface IUpdateTimeEntryData {
  updateTimeEntry: ITimeEntry;
}

interface ICreateTimeEntryV2Variables {
  input: {
    personId: string;
    centerId: string;
    positionId: string;
    timeIn: string;
    timeOut?: string | null;
    type: TimeEntryTypeV2;
    note?: string | null;
  };
}

interface ICreateTimeEntryV2Data {
  createTimeEntryV2: {
    timeEntry: ITimeEntry;
    clockedOutTimeEntry: ITimeEntry | null;
  };
}

interface IDeleteTimeEntryData {
  deleteTimeEntry: ITimeEntry;
}

interface IDeleteTimeEntryVariables {
  input: {
    id: string;
    personId: string;
    centerId: string;
    positionId: string;
    shiftId?: string;
    scheduleId?: string;
    trackedTimeIn?: string;
    trackedTimeOut?: string;
    note?: string;
  };
}

export const UPDATE_TIME_ENTRY = gql`
  mutation($input: UpdateTimeEntryInput!) {
    updateTimeEntry(input: $input) {
      ${timeEntryFields}
    }
  }
`;

export const CREATE_TIME_ENTRY_V2 = gql`
  mutation($input: CreateTimeEntryInputV2!) {
    createTimeEntryV2(input: $input) {
      timeEntry {
        ${timeEntryFields}
      }
      clockedOutTimeEntry {
        ${timeEntryFields}
      }
    }
  }
`;

export const DELETE_TIME_ENTRY = gql`
  mutation($input: DeleteTimeEntryInput!) {
    deleteTimeEntry(input: $input) {
        ${timeEntryFields}
    }
  }
`;

export const useUpdateTimeEntry = (
  options?: MutationHookOptions<IUpdateTimeEntryData, IUpdateTimeEntryVariables> | undefined
) => useMutation<IUpdateTimeEntryData, IUpdateTimeEntryVariables>(UPDATE_TIME_ENTRY, options);

export const useCreateTimeEntryV2 = (
  options?: MutationHookOptions<ICreateTimeEntryV2Data, ICreateTimeEntryV2Variables>
) => useMutation<ICreateTimeEntryV2Data, ICreateTimeEntryV2Variables>(CREATE_TIME_ENTRY_V2, options);

export const useDeleteTimeEntry = (options?: MutationHookOptions<IDeleteTimeEntryData, IDeleteTimeEntryVariables>) =>
  useMutation<IDeleteTimeEntryData, IDeleteTimeEntryVariables>(DELETE_TIME_ENTRY, options);
