import React from 'react';
import { Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import {
  billingFrequencyOptions,
  dayOfWeekOptions,
  billingPeriodOptions,
  additionalBillingPeriodOptions,
  dayOfMonthOptions,
} from 'shared/constants/enums/billingCycleOptions';
import { useTranslation } from 'react-i18next';
import cast from 'shared/util/cast';
import { getChargeDayValue } from './utils';

interface IProps {
  cycle: IBillingCycleTemplate | INewBillingCycleTemplateFormData | IBillingCycleFormData;
  onUpdate: (
    update: Partial<IBillingCycleTemplate> | Partial<INewBillingCycleTemplateFormData | IBillingCycleFormData>
  ) => void;
  readOnly?: boolean;
  fieldsRequired?: boolean;
}

const BillingCycleSentence: React.FC<IProps> = ({ cycle, onUpdate, readOnly, fieldsRequired = false, ...props }) => {
  const { t } = useTranslation();
  const isWeekly = cycle.frequency?.includes('WEEK');
  const isMonthly = cycle.frequency === 'MONTHLY' || cycle.frequency === 'QUARTERLY';
  const canBillInAdvance = cycle.period === 'CURRENT_WEEK';

  return (
    <>
      <Row noGutters className="mb-4">
        {t('billing.settings.cycles.payForBalanceSentenceOne')}
        <Select
          className="mb-0 mx-2 flex-grow-0"
          options={billingFrequencyOptions}
          value={cycle.frequency ?? null}
          onChange={(option) =>
            onUpdate({
              frequency: option.value,
              dayOfWeek: null,
              dayOfMonth: null,
            })
          }
          placeholder={t('spelling.frequency')}
          disabled={readOnly}
          required={fieldsRequired}
        />
        {`${t('spelling.on')} ${isMonthly ? t('spelling.the') : ''}`}
        <Select
          className="mb-0 mx-2 flex-grow-0"
          options={cycle.frequency ? (cycle.frequency.includes('WEEK') ? dayOfWeekOptions : dayOfMonthOptions) : []}
          value={getChargeDayValue(cycle) ?? null}
          onChange={(option) =>
            onUpdate({
              dayOfWeek: isWeekly ? option.value : null,
              dayOfMonth: isMonthly ? option.value : null,
            })
          }
          placeholder={t('billing.settings.cycles.chargeDay')}
          noOptionsMessage={() => t('billing.settings.cycles.noOptionFrequency')}
          disabled={readOnly}
          required={fieldsRequired}
        />
        {isMonthly ? (
          <span>{t('billing.settings.cycles.dayOfTheMonth')}.</span>
        ) : (
          <span className="align-self-end">.</span>
        )}
      </Row>
      <Row noGutters className="mb-4">
        {t('general.IWant')} {t('billing.templates.generateCharges')}
        <Select
          className="mb-0 ml-2 flex-grow-0"
          options={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
          onChange={(option) =>
            onUpdate({
              invoiceSchedule: cast<ITimePeriod>({
                ...(cycle.invoiceSchedule ?? {}),
                value: option,
              }),
            })
          }
          value={cycle.invoiceSchedule?.value ?? null}
          placeholder={t('spelling.number')}
          disabled={readOnly}
          required={fieldsRequired}
        />
        <Select
          className="mb-0 mx-2 flex-grow-0"
          options={[
            { value: 'DAYS', label: cycle.invoiceSchedule?.value === 1 ? t('spelling.day') : t('spelling.days') },
            { value: 'WEEKS', label: cycle.invoiceSchedule?.value === 1 ? t('spelling.week') : t('spelling.weeks') },
          ]}
          onChange={(option) =>
            onUpdate({
              invoiceSchedule: cast<ITimePeriod>({
                ...(cycle.invoiceSchedule ?? {}),
                unit: option.value,
              }),
            })
          }
          value={cycle.invoiceSchedule?.unit ?? null}
          placeholder={t('spelling.period')}
          disabled={readOnly}
          required={fieldsRequired}
        />
        {t('billing.settings.cycles.payForBalanceSentenceTwo')}
      </Row>
      <Row noGutters className="mb-4">
        {t('billing.settings.cycles.payForBalanceSentenceThree')}
        <Select
          className="mb-0 mx-2 flex-grow-0"
          options={billingPeriodOptions}
          value={cycle.period ?? null}
          onChange={(option) =>
            onUpdate({
              period: option.value,
              ...(option.value === 'PREVIOUS_WEEK' && { additionalChargePeriod: { value: 0, unit: 'WEEKS' } }),
            })
          }
          placeholder={t('billing.settings.cycles.timePeriod')}
          disabled={readOnly}
          required={fieldsRequired}
        />
        {t('spelling.of')} {t('spelling.care')}
        {canBillInAdvance ? ', plus ' : '.'}
        {canBillInAdvance && (
          <Select
            className="mb-0 mx-2 flex-grow-0"
            options={cycle.period === 'CURRENT_WEEK' ? additionalBillingPeriodOptions : []}
            onChange={(option) => onUpdate({ additionalChargePeriod: option.value })}
            value={
              additionalBillingPeriodOptions.find(
                (opt) =>
                  opt.value.value === cycle.additionalChargePeriod?.value &&
                  opt.value.unit === cycle.additionalChargePeriod.unit
              ) ?? null
            }
            placeholder={t('billing.settings.cycles.timePeriod')}
            noOptionsMessage={() =>
              !cycle.period
                ? 'Select a billing period to see options'
                : 'Must bill for current week to bill in advance.'
            }
            disabled={readOnly}
            required={fieldsRequired}
          />
        )}
        {canBillInAdvance && ` ${t('spelling.in')} ${t('spelling.advance')}.`}
      </Row>
    </>
  );
};

export default BillingCycleSentence;
