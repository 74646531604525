import React, { useMemo } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { getFullName, getInitials, stringToHueDegree } from 'shared/util/string';
import ContactPaymentRow from '../ContactPaymentRow';
import { getPaymentMethodsByContact } from '../../utils/getPaymentMethodsByContact';

interface IProps {
  contacts: IContact[];
  onCreateCardClick: (contact: IContact) => void;
  onCreateAchClick: (contact: IContact) => void;
  onDeletePaymentMethod: (paymentMethod: IPaymentMethod) => void;
  onEditPaymentMethod: (paymentMethod: IPaymentMethod) => void;
  loading: boolean;
  showCreateAchButton?: boolean;
  paymentMethods: IPaymentMethod[];
}

const PaymentContactsTable: React.FC<IProps> = ({
  contacts,
  showCreateAchButton = true,
  onCreateCardClick,
  onCreateAchClick,
  onDeletePaymentMethod,
  onEditPaymentMethod,
  loading,
  paymentMethods,
}) => {
  const { t } = useTranslation(['billing']);
  const allowedContacts = contacts.filter(
    (c) => c.contactAccountPermissions.find((p) => p.permissions.includes('CanPayBill')) != null || c.isPrimary
  );

  const paymentMethodsByContact = useMemo(() => {
    return getPaymentMethodsByContact(paymentMethods);
  }, [paymentMethods]);
  return (
    <>
      <h3>{t('billing:payment-methods.payment-contacts')}</h3>
      <DataTable
        data={allowedContacts || []}
        dataSize={allowedContacts?.length ?? 0}
        showSelect={false}
        noPadding
        expandRow={(row: IContact) => (
          <ContactPaymentRow
            onCreateAchClick={() => onCreateAchClick(row)}
            onCreateCardClick={() => onCreateCardClick(row)}
            onDeletePaymentMethod={onDeletePaymentMethod}
            onEditPaymentMethod={onEditPaymentMethod}
            loading={loading}
            paymentMethods={paymentMethodsByContact[row.id] ?? []}
            showCreateAchButton={showCreateAchButton}
          />
        )}
        expandHeaderColumnRenderer={(props: { isAnyExpands: boolean }) => (
          <>
            <div style={{ textAlign: 'right' }}>
              <FontAwesomeIcon
                className="icon-lowered cursor-pointer"
                icon={props.isAnyExpands ? faAngleUp : faAngleDown}
                size="2x"
              />
            </div>
          </>
        )}
        expandColumnRenderer={(props: { expanded: boolean }) => (
          <div style={{ textAlign: 'right' }}>
            <FontAwesomeIcon
              className="icon-lowered cursor-pointer"
              icon={props.expanded ? faAngleUp : faAngleDown}
              size="lg"
            />
          </div>
        )}
        renderHeader={() => <></>}
        columns={[
          {
            text: t('billing:payment-methods.table.name'),
            sort: true,
            dataField: 'firstname',
            formatter: (cell: string, contact: IContact) => {
              return (
                <AvatarDataTableCell
                  avatar={contact.avatar?.url ?? ''}
                  initials={getInitials(contact)}
                  color={`hsl(${stringToHueDegree(contact.id)}, ${
                    stringToHueDegree(contact.id) < 50 ? '100%' : '40%'
                  }, 40%`}
                  primaryText={getFullName(contact)}
                />
              );
            },
          },
          {
            text: '',
            align: 'center',
            formatter: (cell: any, row: IContact) => {
              const total = paymentMethodsByContact[row.id]?.length || 0;
              return (
                <div>
                  {t(`billing:payment-methods.table.payment-method-total_${total === 1 ? 'one' : 'other'}`, {
                    count: total,
                  })}
                </div>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default PaymentContactsTable;
