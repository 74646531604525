import { CommsAccount } from 'generated/graphql';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { stringToHsl } from 'shared/util/string';
import { RootState } from 'store/reducers';
import AvatarContent from '../../Avatar/AvatarContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAltCheck, faCommentAltTimes } from '@fortawesome/pro-solid-svg-icons';
import Spinner from '../../Spinner/Spinner';

interface IProps {
  channelKey?: number | string;
  channelName: string;
  className?: string;
  channelId: string;
  account?: CommsAccount | IAccount;
  lastActive?: string | null;
  onClick?: () => void;
  unread?: boolean;
  hideUnreadIndicator?: boolean;
  loading?: boolean;
  sent?: boolean;
  resend?: boolean;
  hideLastActive?: boolean;
  handleResend?: () => void;
}

const ChannelCard: React.FC<IProps> = ({
  channelKey,
  channelName,
  className,
  channelId,
  account,
  lastActive,
  onClick,
  unread,
  hideUnreadIndicator,
  loading,
  sent,
  hideLastActive,
  resend,
  handleResend,
}) => {
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();

  const getInitials = (channelName: string | null | undefined) => {
    if (channelName === null || channelName === undefined) return '';

    const initials = channelName
      .split(' ')
      .map((word) => (/^[A-Za-z0-9 -]*$/.test(word[0]) ? word[0] : null))
      .join('')
      .toUpperCase();

    if (initials.length <= 2) return initials;
    else return initials.replace(initials.substring(2, initials.length - 1), '');
  };

  return (
    <div
      className={`${className} ${unread ? 'unread' : ''}`}
      onClick={resend ? handleResend : onClick}
      key={channelKey}
    >
      <div className="avatar d-inline-block float-none mr-2">
        <AvatarContent
          size="md"
          image=""
          initials={getInitials(channelName)}
          alt={`${channelName} avatar`}
          color={stringToHsl(channelName ?? '1')}
        />
      </div>
      <div className="pn-channel__title">
        <p className="pn-channel__name">{channelName}</p>
        {account && account.children && (
          <p className="pn-channel__children">
            {account.children.map((c) => c?.fullName ?? `${c.firstname} ${c.lastname}`).join(', ')}
          </p>
        )}
        {!hideLastActive && lastActive !== null && (
          <p className="pn-channel__lastActive">
            {moment(lastActive).tz(timezone).format('MMM D[,] YYYY [-] h:mmA').toString()}
          </p>
        )}
      </div>
      {unread && !hideUnreadIndicator && <div className="unread"></div>}
      {sent && (
        <div className="sent">
          <FontAwesomeIcon size="2x" icon={faCommentAltCheck} />
        </div>
      )}
      {resend && (
        <div className="resend">
          <div>
            <FontAwesomeIcon size="2x" icon={faCommentAltTimes} />
          </div>
        </div>
      )}
      {loading && <Spinner className="text-gray mr-2" />}
    </div>
  );
};

export default ChannelCard;
