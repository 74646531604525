import { useGetSystemFieldsQuery } from 'generated/graphql';
import { CustomFieldDatagridProps } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/page-detail/custom-field-datagrid/custom-field-datagrid';
import { SystemFieldBuilder } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/page-section/field-container/kt-field/system-field-builder';
import { useMemo, useState } from 'react';
import DataTable from 'shared/components/DataTable';
import { ICustomField } from 'shared/types/customField';
import { isRegion } from '../../../../../../../../../shared/util/region';
import useDatatableState from 'shared/hooks/useDatatableState2';

export type SystemFieldDatagridProps = CustomFieldDatagridProps;

export default function SystemFieldDatagrid({
  page,
  area,
  onSelectedRows,
  nonSelectableIds = [],
}: SystemFieldDatagridProps) {
  const [tableState, tableFn] = useDatatableState();
  const { data: systemFieldsData } = useGetSystemFieldsQuery();
  const [selectedCustomFields, setSelectedCustomFields] = useState();
  const { sectionType } = page;

  const data = useMemo((): ICustomField[] => {
    let sysfields = [
      {
        ...SystemFieldBuilder.build('gender'),
        area: 'Child',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('grade'),
        area: 'Child',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('firstName'),
        area: 'Child',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        attributesAsString: null,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('firstName'),
        area: 'Contact',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        attributesAsString: null,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('dateOfBirth'),
        area: 'Contact',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        attributesAsString: null,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('dateOfBirth'),
        area: 'Child',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        attributesAsString: null,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('middleName'),
        area: 'Child',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        attributesAsString: null,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('middleName'),
        area: 'Contact',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        attributesAsString: null,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('lastName'),
        area: 'Child',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        attributesAsString: null,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('lastName'),
        area: 'Contact',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        attributesAsString: null,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('address'),
        area: 'Contact',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        attributesAsString: null,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('email'),
        area: 'Contact',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        attributesAsString: null,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('relationships', sectionType),
        area: 'Contact',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('hasMedicalCondition'),
        area: 'Child',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        displayOrder: undefined,
      },
      {
        ...SystemFieldBuilder.build('hasImmunisation'),
        area: 'Child',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        displayOrder: undefined,
      },
      // we don't allow to use these system fields on `payment` page because they are build-in default fields for payment page.
      ...(sectionType === 'payment'
        ? []
        : [
            {
              ...SystemFieldBuilder.build('billingCycle'),
              area: 'Account',
              centerIds: [],
              businessId: '00000000-0000-0000-000000000000',
              notForConsoleDisplay: false,
              displayOrder: undefined,
            },
            {
              ...SystemFieldBuilder.build('paymentMethod'),
              area: 'Account',
              centerIds: [],
              businessId: '00000000-0000-0000-000000000000',
              notForConsoleDisplay: false,
              displayOrder: undefined,
            },
          ]),
    ] as ICustomField[];
    const systemFieldsFromDb: ICustomField[] = (systemFieldsData?.getSystemFields ?? []) as ICustomField[];

    if (isRegion('AU')) {
      ['Child', 'Contact'].forEach((area) => {
        sysfields.push({
          ...SystemFieldBuilder.build('crn'),
          area: area,
          centerIds: [],
          businessId: '00000000-0000-0000-000000000000',
          notForConsoleDisplay: false,
          attributesAsString: null,
          displayOrder: undefined,
        } as ICustomField);
      });

      sysfields.push({
        ...SystemFieldBuilder.build('indigenousStatus'),
        area: 'Child',
        centerIds: [],
        businessId: '00000000-0000-0000-000000000000',
        notForConsoleDisplay: false,
        displayOrder: undefined,
      } as ICustomField);
    }

    if (systemFieldsData?.getSystemFields) {
      sysfields = [...sysfields, ...systemFieldsFromDb];
    }

    return sysfields.filter((o) => o.area === area) as ICustomField[];
  }, [area, sectionType, systemFieldsData?.getSystemFields]);

  const nonSelectable = data
    //account page doesn't have sectionType property
    .filter((row) => nonSelectableIds.includes(`${page.sectionType ?? ''}_${row.id}`))
    .map((r) => r.id);

  return (
    <div className="system-field-datagrid">
      <DataTable
        remote={{ pagination: false, sort: false }}
        keyField="id"
        activePage={tableState.activePage}
        onPageChange={tableFn.changePage}
        nonSelectable={nonSelectable}
        noPadding={true}
        data={data}
        dataSize={data.length}
        showLoadingOverlay={false}
        showSelect={true}
        showPagination={true}
        selectedRows={selectedCustomFields}
        updateSelectedRows={(o) => {
          setSelectedCustomFields(o);
          onSelectedRows(o);
        }}
        columns={[
          {
            text: 'Label',
            dataField: 'label',
            sort: true,
          },
          {
            text: 'Type',
            dataField: 'type',
            sort: true,
            formatter: (cell: any, row: ICustomField) => (row.type === 'HtmlText' ? 'HTML Text' : row.type),
          },
          {
            text: 'Groups',
            sort: true,
            hidden: isRegion('US'),
            formatter: (cell: any, row: ICustomField) => row?.groups?.map((g) => g.name).join(', '),
          },
        ]}
      ></DataTable>
    </div>
  );
}
