import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'shared/components/Buttons';

import ProgramGroupForm from '../ProgramGroupForm/ProgramGroupForm';
import { CreateEnrollmentProgramGroupInput, EnrollmentProgramGroup } from 'generated/graphql';
import { IEnrollmentProgramsTableProps } from '../../../components/EnrollmentProgramsTable/EnrollmentProgramsTable';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'shared/util/string';

interface IProps {
  isModalOpen: boolean;
  loading: boolean;
  businessTimeZone: Timezone;
  values: EnrollmentProgramGroup | CreateEnrollmentProgramGroupInput;
  title: string;
  handleClose: () => void;
  onFieldUpdate: (a, b) => void;
  onSubmit: () => void;
  tableProps: IEnrollmentProgramsTableProps;
}

const ProgramGroupModal: React.FC<IProps> = ({
  title,
  tableProps,
  businessTimeZone,
  isModalOpen,
  loading,
  values,
  onFieldUpdate,
  onSubmit,
  handleClose,
}) => {
  const { t } = useTranslation();
  const isDisabled = useMemo(() => {
    return isEmpty(values.name) || isEmpty(values.description);
  }, [values]);

  return (
    <Modal
      centered
      backdrop="static"
      show={isModalOpen}
      onHide={handleClose}
      size="xl"
      fullScreen={true}
      className="kt-enrollment-create-program-modal"
    >
      <Modal.Header closeButton className="px-6 py-4">
        <Modal.Title as="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-6 overflow-scroll">
        <ProgramGroupForm
          tableProps={tableProps}
          timezone={businessTimeZone}
          values={values as EnrollmentProgramGroup}
          handleFieldChange={onFieldUpdate}
        />
      </Modal.Body>

      <Modal.Footer className="px-4 py-2">
        <Button variant="light" className="mr-3" onClick={handleClose}>
          {capitalize(t('spelling.cancel'))}
        </Button>
        <Button disabled={isDisabled} variant="primary" onClick={onSubmit} loading={loading}>
          {capitalize(t('spelling.save'))}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProgramGroupModal;
