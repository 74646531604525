import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import momentTz from 'moment-timezone';
import classnames from 'classnames';
import Card from 'shared/components/Card';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { PersonAvatar } from 'shared/components/Avatar';
import { orderBy } from 'lodash';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import { getFullName } from 'shared/util/string';
import { TimezoneContext } from 'shared/contexts/timezoneContext';
import WeekTimeLogTableCell from './WeekTimeLogTableCell';
import { ITimeLogTableRowData } from '../TimelogsTab/TimelogsTab';
import { getAdjustedMinutesWorked } from '../../timeLogUtils';

const HOURS_IN_WEEK_BEFORE_OVERTIME = 40;

export interface ITimeInformation {
  isScheduled: boolean;
  isWorking: boolean;
  earliestTimeIn: string;
  latestTimeOut: string | null;
  earliestShiftStart: string;
  latestShiftEnd: string;
  closingTime: Date;
  timeOff: ITimeOff | null;
  hasTrainingTimeEvents: boolean;
  hasHoldidayTimeEvents: boolean;
  hasClosureTimeEvents: boolean;
  hasOutsideBusinessTimeEvents: boolean;
  totalTrainingTimeHours: number;
  timeEntries: ITimeEntry[];
}

interface ITableRow {
  person: IStaff;
  dailyAttendance: ITimeInformation[] | null;
  totalHoursScheduled: ITotalTime | null;
  totalHoursWorked: ITotalTime | null;
  overTime: ITotalTime | null;
}

interface IProps {
  date: string;
  centerId: string;
  startDate: string;
  endDate: string;
  onFilter: React.Dispatch<React.SetStateAction<string>>;
  tableData: ITimeLogTableRowData[];
  loading: boolean;
  centerOperationHours: IOperationHours[];
  showSearch?: Boolean;
}

const WeeklyTimeLog: React.FC<IProps> = ({
  date,
  tableData,
  loading,
  centerOperationHours,
  showSearch,
  centerId,
  startDate,
  endDate,
  onFilter,
  ...props
}) => {
  const { timezone: centerTimezone } = useContext(TimezoneContext);
  // get the dates for each day of the week
  const days = [0, 1, 2, 3, 4, 5, 6].map((num) => moment(date).day(num));
  // get the formatted daily attendence data for each day of the week
  const getAttendenceDayData = useCallback(
    (attendance: IAttendance | null, timeOffRequests: ITimeOff[], day: moment.Moment): ITimeInformation => {
      const attendanceDay = attendance?.days?.find(
        (d: any) => moment(d.date).startOf('d').toISOString() === day.startOf('d').toISOString()
      );
      const timeEntries = attendanceDay?.timeEntries.filter((te) => te.type !== 'BREAK') ?? [];
      const earliestTimeIn = orderBy(
        timeEntries,
        (te) => momentTz(te.trackedTimeIn).tz(centerTimezone).valueOf(),
        'asc'
      )[0]?.trackedTimeIn;
      const latestTimeOut = orderBy(
        timeEntries,
        (te) => te.trackedTimeOut && momentTz(te.trackedTimeOut).tz(centerTimezone).valueOf(),
        'desc'
      )[0]?.trackedTimeOut;
      const earliestShiftStart = orderBy(
        attendanceDay?.shifts,
        (shift) => momentTz(shift.startTime).tz(centerTimezone).valueOf(),
        'asc'
      )[0]?.startTime;
      const latestShiftEnd = orderBy(
        attendanceDay?.shifts,
        (shift) => momentTz(shift.endTime).tz(centerTimezone).valueOf(),
        'desc'
      )[0]?.endTime;
      const operationHours = centerOperationHours.find((hours) => hours.dayOfWeek === day.format('dddd').toUpperCase());

      const timeOffForDay =
        timeOffRequests.find(
          (to) =>
            day.isBetween(
              moment(to.startTime).tz(centerTimezone),
              moment(to.endTime).tz(centerTimezone),
              'date',
              '[]'
            ) || day.isSame(moment(to.startTime).tz(centerTimezone), 'date')
        ) ?? null;

      return {
        isScheduled: Boolean(attendanceDay?.shifts),
        isWorking: Boolean(attendanceDay?.timeEntries),
        earliestTimeIn,
        latestTimeOut,
        earliestShiftStart,
        latestShiftEnd,
        closingTime: operationHours
          ? day
              .set({
                h: parseInt(operationHours.endTime.split(':')[0]),
                m: parseInt(operationHours.endTime.split(':')[1]),
              })
              .toDate()
          : day.set({ h: 21, m: 0 }).toDate(),
        timeOff: timeOffForDay,
        hasHoldidayTimeEvents: attendanceDay?.trainingTimeEvents.some((te) => te.type === 'HOLIDAY') ?? false,
        hasTrainingTimeEvents: attendanceDay?.trainingTimeEvents.some((te) => te.type === 'TRAINING') ?? false,
        hasClosureTimeEvents: attendanceDay?.trainingTimeEvents.some((te) => te.type === 'CLOSURE') ?? false,
        hasOutsideBusinessTimeEvents: attendanceDay?.trainingTimeEvents.some((te) => te.type === 'OOBH') ?? false,
        totalTrainingTimeHours:
          attendanceDay?.trainingTimeEvents.reduce(
            (total, trainingEvent) => (total = total + trainingEvent.hours),
            0
          ) ?? 0,
        timeEntries: timeEntries,
      };
    },
    [centerOperationHours, centerTimezone]
  );

  const formattedTableData: ITableRow[] = tableData.map((row) => {
    const { attendance, timeOffRequests } = row;
    const dailyAttendance = days.map((day) => getAttendenceDayData(attendance, timeOffRequests, day));
    const timeEntriesTotal = dailyAttendance.map((d) => getAdjustedMinutesWorked(d.timeEntries));
    const totalTimeWithTraining: ITotalTime = {
      hours:
        dailyAttendance.reduce((total, info) => (total = total + info.totalTrainingTimeHours), 0) +
        Math.floor(timeEntriesTotal.reduce((total, info) => (total = total + info), 0) / 60),
      minutes: Math.floor(timeEntriesTotal.reduce((total, info) => (total = total + info), 0) % 60) ?? 0,
    };
    const totalMinutesWorked = totalTimeWithTraining.hours * 60 + totalTimeWithTraining.minutes;
    const overtimeHours = Math.floor((totalMinutesWorked - HOURS_IN_WEEK_BEFORE_OVERTIME * 60) / 60);
    const overtimeMinutes = (totalMinutesWorked - HOURS_IN_WEEK_BEFORE_OVERTIME * 60) % 60;

    return {
      person: row.staff,
      dailyAttendance,
      totalHoursScheduled: attendance?.totalHoursScheduled ?? { hours: 0, minutes: 0 },
      totalHoursWorked: totalTimeWithTraining,
      overTime: {
        hours: overtimeHours,
        minutes: overtimeMinutes,
      },
    };
  });

  return (
    <Card bodyClassName="p-0 pt-2">
      <Table responsive borderless className="weekly-time-log">
        <thead>
          <tr>
            <th>Employee</th>
            <th>Total</th>
            {days.map((day, i) => (
              <th key={i} className="text-center">
                <span
                  className={classnames('p-1', {
                    today: day.startOf('d').toISOString() === moment().startOf('d').toISOString(),
                  })}
                >
                  {day.format('dddd, D')}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!loading &&
            formattedTableData.map((attendance: ITableRow, i: number) => (
              <tr key={i}>
                <td>
                  <Link
                    to={`/employees/time-log/time-card?personId=${attendance.person.id}&centerId=${centerId}&start=${startDate}&end=${endDate}`}
                  >
                    <div className="d-flex align-items-center">
                      <PersonAvatar person={attendance.person} className="mr-2" />
                      <div className="text-overflow-ellipsis">{getFullName(attendance.person)}</div>
                    </div>
                  </Link>
                </td>
                <td>
                  <div className="d-flex">
                    <div className="font-weight-semi-bold mr-2">
                      {attendance?.totalHoursWorked?.hours ?? 0}h {attendance?.totalHoursWorked?.minutes ?? 0}m
                    </div>
                    {((attendance?.overTime?.hours ?? 0) > 0 || (attendance?.overTime?.minutes ?? 0) > 0) && (
                      <div className="text-danger">
                        (+ {attendance?.overTime?.hours ?? 0}h {attendance?.overTime?.minutes ?? 0}m)
                      </div>
                    )}
                  </div>
                  <div className="text-gray">
                    {attendance?.totalHoursScheduled?.hours ?? 0}h {attendance?.totalHoursScheduled?.minutes ?? 0}m
                  </div>
                </td>
                {(attendance.dailyAttendance ?? []).map((dailyAttendance, j) => (
                  <WeekTimeLogTableCell key={j} attendanceData={dailyAttendance} centerTimezone={centerTimezone} />
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
      {loading && <DataTableLoadingSkeleton />}
    </Card>
  );
};

export default WeeklyTimeLog;
