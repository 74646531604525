import { groupBy, orderBy } from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { minimizeArrays } from 'shared/util/array';
import { showToast } from 'shared/components/Toast';
import _ from 'lodash';

export interface IShiftsGroupedByClassShape {
  CLASSES: {
    [key: string]: IShift[];
  };
  LOCATIONS: {
    [key: string]: IShift[];
  };
}

// create an array of dates representing the schedule's week
export const getDatesInSchedule = (schedule: ISchedule, timezone: string, omitWeekends = true): moment.Moment[] => {
  const dates: moment.Moment[] = [];

  if (schedule) {
    const startOfWeek = moment.tz(schedule.startTime, timezone);
    const endOfWeek = moment.tz(schedule.endTime, timezone);
    const current = startOfWeek.clone();

    while (current.isSameOrBefore(endOfWeek)) {
      // we currently don't care about sunday and saturday
      if (current.day() > 0 && current.day() < 6) {
        dates.push(current.clone());
      }

      current.add(1, 'day');
    }
  }

  return dates;
};

// group shifts by class and location
export const groupShifts = (schedule?: ISchedule): IShiftsGroupedByClassShape => {
  if (schedule) {
    return {
      CLASSES: groupBy(schedule.shifts?.filter((shift) => shift.classId) ?? [], 'classId'),
      LOCATIONS: groupBy(schedule.shifts?.filter((shift) => shift.locationId) ?? [], 'locationId'),
    };
  }

  return {
    CLASSES: {},
    LOCATIONS: {},
  };
};

export const sortClassByRatio = (classes: IClass[]): IClass[] => {
  return orderBy(
    classes,
    [
      (p: IClass) =>
        _.isNil(p.regulationOverride)
          ? p.regulation.ratioTeachers / p.regulation.ratioChildren
          : p.regulationOverride.ratioTeachers / p.regulationOverride.ratioChildren,
      (p: IClass) => p.name,
    ],
    ['desc', 'asc']
  );
};

export const exportScheduleToCSV = (
  schedule: ISchedule,
  classes: IClass[],
  locations: ILocation[],
  timezone: string
) => {
  try {
    let csvRows: string[][] = [];
    const datesInSchedule = getDatesInSchedule(schedule, timezone);
    const shiftsGroupedByClass = groupShifts(schedule);
    const datesFormattedHeaderArr = datesInSchedule.map((d) => d.tz(timezone).format('dddd - MMMM Do'));
    const header = ['Class / Location', ...datesFormattedHeaderArr];

    // push the first row of table columns headers
    csvRows.push(header);

    // format classes
    classes.forEach((c: IClass) => {
      const shifts: IShift[] = shiftsGroupedByClass.CLASSES[c.id];
      const className = c.name;
      // NOTE: expects that the regulation was returned as part of the graphql response
      const classRatio = `${c.regulation.ratioTeachers}:${c.regulation.ratioChildren}`;

      // push the class name and projected attendance (coming soon...!)
      csvRows.push([`${className} (${classRatio}), "", "", "", "", ""`]);
      const classShiftsRows: string[][] = [];

      // some classes may not have any shifts scheduled but we still need to show them in the export
      if (!shifts) {
        // push an empty row is the class doens't have any shifts
        csvRows.push([`"", "", "", "", "", ""`]);
        return;
      }

      shifts.forEach((shift: IShift) => {
        const arr = ['', '', '', '', '', ''];
        const formattedStart = momentTz(shift.startTime).tz(timezone).format('h:mm a');
        const formattedEnd = momentTz(shift.endTime).tz(timezone).format('h:mm a');
        /**
         * this won't cause issues now since the first column is exclusively for the room name and "Monday" is column 2 (index 1, day 1)
         * if we include weekends, this strategy will need to be revisited
         */
        const indexToInsertAt = momentTz(shift.startTime).tz(timezone).day();
        const name = shift.personId ? `${shift.person?.firstname ?? ''} ${shift.person?.lastname ?? ''}` : 'Unassigned';
        arr[indexToInsertAt] = `${name} - ${formattedStart}-${formattedEnd}`;
        classShiftsRows.push(arr);
      });

      // if we see performance issues we can revisit the algorithm used to create the rows
      csvRows = csvRows.concat(minimizeArrays(classShiftsRows));
    });

    // format locations, slightly different from class
    locations.forEach((location: ILocation) => {
      const shifts: IShift[] = shiftsGroupedByClass.LOCATIONS[location.id];
      const locationName = location.name;

      csvRows.push([`${locationName}, "", "", "", "", ""`]);
      const locationShiftsRows: string[][] = [];

      if (!shifts) {
        csvRows.push([`"", "", "", "", "", ""`]);
        return;
      }

      shifts.forEach((shift: IShift) => {
        const arr = ['', '', '', '', '', ''];
        const formattedStart = momentTz(shift.startTime).tz(timezone).format('h:mm a');
        const formattedEnd = momentTz(shift.endTime).tz(timezone).format('h:mm a');
        const indexToInsertAt = momentTz(shift.startTime).tz(timezone).day();
        const name = shift.personId ? `${shift.person?.firstname ?? ''} ${shift.person?.lastname ?? ''}` : 'Unassigned';

        arr[indexToInsertAt] = `${name} - ${formattedStart}-${formattedEnd}`;
        locationShiftsRows.push(arr);
      });

      // if we see performance issues we can revisit the algorithm used to create the rows
      csvRows = csvRows.concat(minimizeArrays(locationShiftsRows));
    });

    const stringifiedRows = csvRows.map((row) => row.join(',')).join('\n');

    // create the file
    const file = new Blob([stringifiedRows], { type: 'text/csv;charset=utf-8;' });
    const downloadLink = document.createElement('a');
    downloadLink.download = `Employee_Schedule_${momentTz(schedule?.startTime)
      .tz(timezone)
      .format('M.D.YY')}_to_${momentTz(schedule?.endTime).tz(timezone).format('M.D.YY')}.csv`;
    downloadLink.href = window.URL.createObjectURL(file);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();
  } catch (error) {
    console.log(error);
    showToast('Uknown error creating export.', 'error');
  }
};
