import React from 'react';
import cx from 'classnames';

interface IProps {
  variant?: 'standard' | 'pill';
  count: number;
  overflowCount?: number;
  className?: string;
}

const Badge: React.FC<IProps> = ({ variant = 'standard', count, overflowCount, className = '', ...props }) => {
  const classnames = cx(className, {
    'd-flex': true,
    'align-items-center': true,
    'justify-content-center': true,
    'kt-badge': true,
    [`kt-badge-${variant}`]: true,
    'kt-badge-overflow': overflowCount && count > overflowCount,
  });

  const badgeCount: string = overflowCount && count > overflowCount ? `${overflowCount}+` : `${count}`;

  return (
    <div className={classnames}>
      <span>{badgeCount}</span>
    </div>
  );
};

export default Badge;
