import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Alert from 'shared/components/Alert';
import CenterDetailInputs, { closingDateValidation } from '../InputGroups/CenterDetailInputs';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { useUpdateCenterProfile } from 'pages/Centers/subroutes/Profile/graphql/mutations';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import errorMessage from 'shared/constants/errorMessages';
import { isBlank } from 'shared/util/string';
import { omitFalsy, omitTypename } from 'shared/util/object';
import { isEmailValid } from 'shared/util/email';
import { isValidPhoneNumber } from 'shared/util/string';
import { Badge } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {
  canPerformEdit?: boolean;
  center: ICenter;
}

const CenterInformationForm: React.FC<IProps> = ({ center, canPerformEdit }) => {
  const { k2NewUpdateCenter } = useFlags();
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [formData, setFormData] = useState(center); // store copy of center as editable formData
  // this useEffect allows us to receive data updates (i.e. the center props was updated) while we aren't actively editing the form.
  useEffect(() => {
    if (!formIsDirty && !_.isEqual(formData, center)) setFormData(center);
  }, [center, formData, formIsDirty]);
  const resetForm = useCallback(() => setFormData(center), [center]); // reset formData to OG center on cancel

  const [updateCenterProfile, { loading, error }] = useUpdateCenterProfile();

  const isValidAcn = useMemo(() => {
    if (!formData.acn) return true;

    if (formData.acn.length !== 9) return false;

    const acnValues = formData.acn.split('');
    if (acnValues.filter((a) => isNaN(Number(a))).length > 0) return false;

    let weight = 8;
    let sumOfAcnValues = 0;
    for (var i = 0; i < 8; i++) {
      sumOfAcnValues += Number(acnValues[i]) * weight;
      weight--;
    }

    const remainder = sumOfAcnValues % 10;
    let complement = 10 - remainder;
    complement = complement === 10 ? 0 : complement;
    return Number(acnValues[8]) === complement;
  }, [formData]);

  const isValidAbn = useMemo(() => {
    if (!formData.abn) return true;

    if (formData.abn.length !== 11) return false;

    const abnValues = formData.abn.split('');
    if (abnValues.filter((a) => isNaN(Number(a))).length > 0) return false;

    const abnWeights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
    abnValues[0] = (Number(abnValues[0]) - 1).toString();
    let sumOfAbnValues = 0;
    for (var i = 0; i <= 10; i++) {
      sumOfAbnValues += Number(abnValues[i]) * abnWeights[i];
    }

    const remainder = sumOfAbnValues % 89;
    return remainder === 0;
  }, [formData]);

  const isValidTaxId = useMemo(() => {
    if (!formData.taxId) return true;

    const taxRegex = /^([0-9]{2})([ \-]?)([0-9]{7})$/;
    return taxRegex.test(formData.taxId);
  }, [formData]);

  const handleTextInput = useCallback(
    (value, name) => {
      if (!formIsDirty) {
        setFormIsDirty(true);
      }

      setFormData({ ...formData, [name]: value });
    },
    [formData, formIsDirty]
  );

  const formValid =
    !isBlank(formData.name) &&
    !isBlank(formData.address.address1) &&
    !isBlank(formData.address.city) &&
    !isBlank(formData.address.state) &&
    !isBlank(formData.address.postalCode) &&
    isValidPhoneNumber(formData.phoneNumber) &&
    !isBlank(formData.timezone) &&
    (formData.email ? isEmailValid(formData.email) : true) &&
    closingDateValidation(formData.closingDate, formData.openingDate).isValid &&
    isValidAcn &&
    isValidAbn &&
    isValidTaxId;

  const saveCenter = useCallback(() => {
    const {
      name,
      address,
      phoneNumber,
      timezone,
      openingDate,
      closingDate,
      email,
      website,
      acn,
      abn,
      externalId,
      taxId,
      serviceType,
    } = formData;
    // taken from the previous implementation of the update hook
    const updates = {
      ...center,
      name,
      address,
      phoneNumber,
      timezone,
      openingDate,
      closingDate,
      email,
      website,
      acn,
      abn,
      externalId,
      taxId,
      serviceType,
    };

    const centerInfoWithoutTypes: any = omitTypename(updates, true);
    const addressWithoutFalsy = omitTypename(omitFalsy(address), true);
    const updateCenterInput = {
      id: centerInfoWithoutTypes.id,
      name: centerInfoWithoutTypes.name,
      phoneNumber: centerInfoWithoutTypes.phoneNumber,
      address: addressWithoutFalsy,
      primaryContactPersonId: centerInfoWithoutTypes.primaryContactPersonId,
      secondaryContactPersonId: centerInfoWithoutTypes.secondaryContactPersonId,
      tags: centerInfoWithoutTypes.tags.map((tag: any) => tag.id),
      timezone: centerInfoWithoutTypes.timezone,
      openingDate: centerInfoWithoutTypes.openingDate,
      closingDate: centerInfoWithoutTypes.closingDate,
      email: centerInfoWithoutTypes.email,
      website: centerInfoWithoutTypes.website,
      acn: centerInfoWithoutTypes.acn,
      abn: centerInfoWithoutTypes.abn,
      externalId: centerInfoWithoutTypes.externalId,
      taxId: centerInfoWithoutTypes.taxId,
      serviceType: centerInfoWithoutTypes.serviceType,
      useNewUpdate: k2NewUpdateCenter ? true : false,
    };

    updateCenterProfile({
      variables: {
        input: updateCenterInput,
      },
    });

    setFormIsDirty(false);
  }, [center, formData, updateCenterProfile]);

  return (
    <>
      {error && <Alert variant="danger">{error.message || 'Something went wrong.'}</Alert>}
      {statusBadge(formData.openingDate, formData.closingDate, center.deactivatedAt)}
      {loading ? (
        <LoadingLines />
      ) : (
        <FormWrapper2
          formId="center-details-form"
          formIsDirty={formIsDirty}
          toggleDirty={setFormIsDirty}
          onCancel={resetForm}
          onSave={saveCenter}
          saveDisabled={!formValid}
        >
          <CenterDetailInputs
            formEditable={canPerformEdit}
            name={formData.name}
            address={formData.address}
            timezone={formData.timezone}
            phoneNumber={formData.phoneNumber}
            handleInput={handleTextInput}
            openingDate={formData.openingDate}
            closingDate={formData.closingDate}
            website={formData.website}
            email={formData.email ?? ''}
            acn={formData.acn ?? ''}
            abn={formData.abn ?? ''}
            isValidAcn={isValidAcn}
            isValidAbn={isValidAbn}
            externalId={formData.externalId}
            isValidTaxId={isValidTaxId}
            taxId={formData.taxId ?? ''}
            serviceType={formData.serviceType}
          />
          {!formValid && <small className="ml-auto">{errorMessage.formRequirements}</small>}
        </FormWrapper2>
      )}
    </>
  );
};

export default CenterInformationForm;

const statusBadge = (openingDate?: string, closingDate?: string, deactivatedAt?: string) => {
  if (!openingDate) {
    return;
  } else if (deactivatedAt) {
    return <Badge variant="dark">Deactivated</Badge>;
  } else if (openingDate && closingDate) {
    const isOpen = moment().isBetween(moment(openingDate), moment(closingDate), undefined, '[)');
    return isOpen ? <Badge variant="success">Open</Badge> : <Badge variant="dark">Closed</Badge>;
  } else {
    const isOpen = moment().isSameOrAfter(openingDate);
    return isOpen ? <Badge variant="success">Open</Badge> : <Badge variant="dark">Closed</Badge>;
  }
};
