import React from 'react';
//components
import * as Mui from '@mui/material';
//utils
import { useTranslation } from 'react-i18next';
import cast from 'shared/util/cast';

interface IProps {
  setModal: (p: { isOpen: boolean; submit: string; title: string; type: string; content: string }) => void;
  setAnchorEl: (p: any) => void;
  item: {
    id: number;
    type: string;
    function: string;
    optionsContent?: any;
  };
}

export const DropdownItem: React.FC<IProps> = ({ setModal, setAnchorEl, item }) => {
  const { t } = useTranslation(['subsidies']);
  return (
    <Mui.MenuItem
      onClick={() => {
        setModal({
          title: t(cast<any>(`subsidies:cwa.modal.title.${item.function}`)),
          content: t(cast<any>(`subsidies:cwa.modal.text.${item.function}`), item.optionsContent),
          isOpen: true,
          submit: t(cast<any>(`subsidies:cwa.modal.submit.${item.function}`)),
          type: item.type,
        });
        setAnchorEl(null);
      }}
    >
      {t(cast<any>(`subsidies:cwa.modal.submit.${item.function}`))}
    </Mui.MenuItem>
  );
};
