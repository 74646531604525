import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faExclamationSquare,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { CcsMsgTypesEnum } from 'shared/constants/enums/ccssMessageEnums';

interface ICCSTypeIconProps {
  type: CcsMsgTypeCodes;
}

interface CCSSIconType {
  icon: IconDefinition;
  color: string;
}
type CCSSIconMapType = Record<CcsMsgTypeCodes, CCSSIconType>;

const CCSSTypeIcon: React.FC<ICCSTypeIconProps> = ({ type }) => {
  const icons: CCSSIconMapType = {
    [CcsMsgTypesEnum.IMMED]: {
      icon: faExclamationCircle,
      color: colors.danger,
    },
    [CcsMsgTypesEnum.ERROR]: {
      icon: faExclamationSquare,
      color: colors.secondaryOrange,
    },
    [CcsMsgTypesEnum.WARNIG]: {
      icon: faExclamationCircle,
      color: colors.warning,
    },
    [CcsMsgTypesEnum.INFO]: {
      icon: faInfoCircle,
      color: colors.info,
    },
    [CcsMsgTypesEnum.CORRESPONDENCE]: {
      icon: faInfoCircle,
      color: colors.success,
    },
  };
  return <FontAwesomeIcon {...icons[type]} className="mr-4" />;
};

export default CCSSTypeIcon;
