import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
//components
import TableSearch from '../../../../../shared/components/DataTable/TableSearch';
import MultipleCenterSelect from '../../../../../shared/components/Select/MultipleCenterSelect';
import DataTable, { SizePerPage, TableHeader } from '../../../../../shared/components/DataTable';
import useDatatableState from '../../../../../shared/hooks/useDatatableState2';
import { IconButtonCircle } from '../../../../../shared/components/Buttons';
//styles
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import '../../EndOfCareTab.scss';
//utils
import {
  formatDate,
  getActionType,
  getEnrollmentStatus,
  HeadersForEndOfCareHistoryTable,
} from '../EndOfCareActive/common';
import { currencyFormat } from '../../../../../shared/util/currency';
import moment from 'moment';
//store
import { RootState } from '../../../../../store/reducers';
import { useGetEndCareEnrollmentHistoryPage } from '../../graphql/queries';
import { EndOfCareHistoryExpandRow } from './EndOfCareHistoryExpandRow';

interface IProps {}
interface Sorting {
  field: string;
  direction: string;
}

export const EndOfCareHistory: React.FC<IProps> = () => {
  const { t } = useTranslation(['billing', 'subsidies']);
  const entityId = useSelector((state: RootState) => state?.user?.entityId) ?? '';
  const [tableState, tableFunctions] = useDatatableState();
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();

  const [centerIds, setCenterIds] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sort, setSort] = useState<Sorting>({
    field: '',
    direction: '',
  });

  const {
    data: getEndCareEnrollmentHistoryPage,
    loading: loadingEndCareEnrollmentPage,
    refetch: refetchEndCareEnrollmentHistoryPage,
  } = useGetEndCareEnrollmentHistoryPage({
    input: {
      businessId: entityId,
      centerIds,
      pageNumber: tableState.activePage,
      pageSize: tableState.pageSize,
      sortDtos: sort.field && sort.direction ? sort : [],
      searchText: searchValue,
    },
  });

  const historyTabData =
    getEndCareEnrollmentHistoryPage?.getEndCareEnrollmentHistoryPage?.data?.map((i) => {
      const person = `${i.person.firstname} ${i.person.lastname}`;
      const childName = `${i.childFirstName} ${i.childLastName}`;
      return {
        accountChildId: i.accountChildId,
        accountId: i.accountId,
        accountName: i.accountName,
        // @ts-ignore
        actionType: getActionType(i.actionType),
        actionBy: person,
        centerId: i.centerId,
        centerName: i.centerName,
        childFirstName: childName,
        childId: i.childId,
        childLastName: i.childLastName,
        date: formatDate(i.date, timezone),
        enrollmentId: i.enrollmentId,
        status: getEnrollmentStatus(i.status),
        totalDue: currencyFormat(i.totalDue),
        transactionId: i.transactionId,
        person: i.person,
      };
    }) ?? [];

  return (
    <DataTable
      activePage={tableState.activePage}
      className="pt-2 pb-2"
      pageSize={tableState.pageSize}
      onPageChange={tableFunctions.changePage}
      keyField="transactionId"
      dataSize={getEndCareEnrollmentHistoryPage?.getEndCareEnrollmentHistoryPage.totalRecords ?? 0}
      data={historyTabData ?? []}
      showSelect={false}
      showPagination={!loadingEndCareEnrollmentPage}
      onSort={(field, direction) => setSort({ field, direction: direction })}
      rowClasses={(row: IMedicalCondition) => (row.archivedAt ? 'kt-datatable-inactive-row' : '')}
      defaultSorting="asc"
      renderHeader={(paginationProps: any) => (
        <TableHeader className="px-1 flex-wrap justify-content-between mt-2">
          <div className="d-flex">
            <div className={'d-flex align-items-center mr-4'}>
              <SizePerPage paginationProps={paginationProps} />
            </div>
            <TableSearch
              placeholder="Search"
              onChange={(i) => {
                setSearchValue(i);
                return searchValue;
              }}
              className="mr-4 my-2"
            />
          </div>

          <div className="d-flex flex-direction-row align-items-center">
            <MultipleCenterSelect
              useNullForAllOption={true}
              selectedCenterIds={centerIds}
              onSelect={(ids) => setCenterIds(!ids?.length ? [] : ids)}
              showLabel={false}
              className="flex-grow-0 w-240px mb-0"
            />
            <IconButtonCircle
              icon={faTimes}
              onClick={() => {
                setCenterIds([]);
                setSearchValue('');
              }}
              tooltipDirection="bottom"
              tooltipText={t('billing:transactions.batch-table.clear-filter-tool-tip')}
              className="mx-4 my-2"
            />
          </div>
        </TableHeader>
      )}
      showLoadingOverlay={loadingEndCareEnrollmentPage}
      expandRow={(row) => (
        <EndOfCareHistoryExpandRow
          row={row}
          actionType={row.actionType}
          refetch={() => {
            refetchEndCareEnrollmentHistoryPage();
          }}
        />
      )}
      columns={HeadersForEndOfCareHistoryTable}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      onlyOneExpanding={true}
    />
  );
};
