import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import AgencyEnrollmentStatus from 'shared/Agency/AgencyEnrollments/AgencyEnrollmentStatus';
import Button, { IconButtonCircle } from 'shared/components/Buttons';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';

interface IChildTableFilterProps {
  agencyFilterOptions: ITableFilterOption[];
  centerFilterOptions: ITableFilterOption[];
  onClearAppliedFiltersClick: () => void;
  onSearchTermChange: (term: string) => void;
  onFilterChange: (values: string[], filterName: string) => void;
  searchTerm: string;
  selectedAgencyIds: string[];
  selectedCenterIds: string[];
  selectedStatuses: AgencyAccountChildStatus[];
  statusFilterOptions: ITableFilterOption[];
}
export interface SubsidyChildRow {
  id: string;
  accountId: string;
  initials: string;
  fullName: string;
  avatarUrl?: string;
  enrollmentId?: string;
  accountName: string;
  centerName: string;
  agencyName: string;
  startDate: string;
  endDate: string;
  subsidyAmount: string;
  subsidyPaymentPeriodType: string;
  status: string;
  notes: string;
}
interface ChildTableProps extends IChildTableFilterProps {
  data: SubsidyChildRow[];
  showLoadingOverlay: boolean;
  pageSize: number;
  activePage: number;
  onPageChange: (page: number) => void;
  onSizePerPageChange: (size: number) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  onViewChildAttendance: (id: string) => void;
}
export default function ChildrenTable({
  agencyFilterOptions,
  centerFilterOptions,
  onClearAppliedFiltersClick,
  onSearchTermChange,
  showLoadingOverlay,
  data,
  pageSize,
  activePage,
  onFilterChange,
  onPageChange,
  onSizePerPageChange,
  onSort,
  onViewChildAttendance,
  searchTerm,
  selectedAgencyIds,
  selectedCenterIds,
  selectedStatuses,
  statusFilterOptions,
}: ChildTableProps) {
  const { t } = useTranslation(['subsidies', 'translation']);
  return (
    <DataTable
      activePage={activePage}
      columns={[
        {
          text: t('subsidies:agencies.account-subsidy-table.child-column'),
          dataField: 'childLastName',
          sort: true,
          formatter: (cell: any, row: SubsidyChildRow) => (
            <AvatarDataTableCell
              initials={row.initials}
              avatar={row.avatarUrl ?? ''}
              primaryText={row.fullName}
              secondaryText={row.enrollmentId}
            />
          ),
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.account-column'),
          dataField: 'accountName',
          sort: true,
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.center-column'),
          dataField: 'centerName',
          sort: true,
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.subsidy-column'),
          dataField: 'agencyName',
          sort: true,
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.start-column'),
          dataField: 'startDate',
          sort: true,
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.end-column'),
          dataField: 'endDate',
          sort: true,
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.expected-column'),
          dataField: 'subsidyAmount',
          sort: false,
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.subsidy-period-column'),
          dataField: 'subsidyPaymentPeriodType',
        },
        {
          text: t('subsidies:agencies.account-subsidy-table.status-column'),
          dataField: 'status',
          sort: true,
          formatter: (cell: any, row: IAgencyAccountChild) => (
            <AgencyEnrollmentStatus status={row.status}></AgencyEnrollmentStatus>
          ),
        },
      ]}
      data={data}
      dataSize={data.length}
      expandRow={(row) => <ExpandedRow row={row} onViewChildAttendance={onViewChildAttendance}></ExpandedRow>}
      noPadding
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSort={onSort}
      pageSize={pageSize}
      renderHeader={() => {
        return (
          <ChildTableFilters
            agencyFilterOptions={agencyFilterOptions}
            centerFilterOptions={centerFilterOptions}
            onClearAppliedFiltersClick={onClearAppliedFiltersClick}
            onSearchTermChange={onSearchTermChange}
            onFilterChange={onFilterChange}
            searchTerm={searchTerm}
            selectedAgencyIds={selectedAgencyIds}
            selectedCenterIds={selectedCenterIds}
            selectedStatuses={selectedStatuses}
            statusFilterOptions={statusFilterOptions}
          ></ChildTableFilters>
        );
      }}
      showLoadingOverlay={showLoadingOverlay}
      showPagination={true}
      showSelect={false}
    ></DataTable>
  );
}

interface ExpandedRowProps {
  onViewChildAttendance: (id: string) => void;
  row: SubsidyChildRow;
}
function ExpandedRow({ row, onViewChildAttendance }: ExpandedRowProps) {
  const [t] = useTranslation(['subsidies', 'translation']);
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div>
            <label>Notes</label>
          </div>
          <div>{row.notes}</div>
        </div>
        <div>
          {onViewChildAttendance && (
            <Button variant="outline-primary" onClick={() => onViewChildAttendance(row.id)} className="mr-4">
              {t('subsidies:agencies.account-subsidy-table.view-child-attendance-button')}
            </Button>
          )}
          <Button
            target="_blank"
            href={`/families/accounts/${row.accountId}/agency`}
            variant="primary"
            className="ml-4"
          >
            {t('subsidies:agencies.account-subsidy-table.view-child-profile-button')}
          </Button>
        </div>
      </div>
    </div>
  );
}

function ChildTableFilters({
  agencyFilterOptions,
  centerFilterOptions,
  onClearAppliedFiltersClick,
  onSearchTermChange,
  onFilterChange,
  searchTerm,
  selectedAgencyIds,
  selectedCenterIds,
  selectedStatuses,
  statusFilterOptions,
}: IChildTableFilterProps) {
  const { t } = useTranslation(['subsidies', 'translation']);
  const handleFilterChangeFactory = (filterName: string) => {
    return (options: { value: string }[]) => {
      onFilterChange(
        options.map((o) => o.value),
        filterName
      );
    };
  };
  return (
    <TableHeader className="flex-wrap">
      <TableSearch onChange={onSearchTermChange} value={searchTerm} className="my-2" enableDebounce={false} />
      <div className="d-flex ml-auto align-items-center flex-wrap">
        <DropdownFilter
          title={t('subsidies:agencies.account-subsidy-table.subsidies-filter')}
          className="mr-4"
          selectedFilters={selectedAgencyIds}
          options={agencyFilterOptions}
          onFilterSelect={handleFilterChangeFactory('agencyIds')}
        />
        <DropdownFilter
          title={capitalize(t('translation:spelling.center_plural'))}
          className="mr-4"
          selectedFilters={selectedCenterIds}
          options={centerFilterOptions}
          onFilterSelect={handleFilterChangeFactory('centerIds')}
        />
        <DropdownFilter
          title={capitalize(t('subsidies:agencies.account-subsidy-table.status-column'))}
          selectedFilters={selectedStatuses}
          options={statusFilterOptions}
          onFilterSelect={handleFilterChangeFactory('statuses')}
        />
        <IconButtonCircle
          className="ml-4"
          icon={faTimes}
          onClick={onClearAppliedFiltersClick}
          tooltipDirection="bottom"
          tooltipText="Clear Filters"
        />
      </div>
    </TableHeader>
  );
}
