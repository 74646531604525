import {
  IconDefinition,
  faUtensilsAlt,
  faTreeAlt,
  faShapes,
  faSchool,
  faFirstAid,
  faChild,
  faChalkboardTeacher,
  faBusSchool,
  faBed,
  faAppleAlt,
  faBasketballHoop,
  faBaby,
} from '@fortawesome/pro-light-svg-icons';

type IconMap = Record<string, IconDefinition>;
const spaceIcons: IconMap = {
  faBaby,
  faChild,
  faTreeAlt,
  faUtensilsAlt,
  faSchool,
  faShapes,
  faFirstAid,
  faChalkboardTeacher,
  faBusSchool,
  faBed,
  faAppleAlt,
  faBasketballHoop,
};

export default spaceIcons;
