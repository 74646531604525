/* eslint-disable */
import React, { useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactDates, { DayPickerRangeController, DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { DefaultCountry } from './countryCodes';

// https://github.com/airbnb/react-dates/issues/1343

interface IProps {
  id?: string;
  label?: string;
  autoFocus?: boolean;
  date?: moment.Moment;
  className?: string;
  onChange: (date: moment.Moment) => void;
  displayFormat?: string;
  isOutsideRange?: (day: any) => boolean;
}

const DayPicker: React.FC<IProps> = ({
  id = 'day-date-picker',
  autoFocus = false,
  date = moment(),
  displayFormat = DefaultCountry.dateFormat,
  onChange,
  label,
  className,
  isOutsideRange,
}) => {
  const [showWeekPicker, setShowWeekPicker] = useState<boolean>(false);

  return (
    <Form.Group className={`kt-week-input ${className}`}>
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup className="flex-nowrap" style={{ width: 'unset' }}>
        <InputGroup.Prepend>
          <Button
            variant="light"
            onClick={() => onChange(date.clone().subtract(1, 'd'))}
            disabled={isOutsideRange && isOutsideRange(date.clone().subtract(1, 'd'))}
          >
            <FontAwesomeIcon icon={faChevronLeft} size="sm" color="gray" />
          </Button>
        </InputGroup.Prepend>
        <div
          style={{ width: 'unset' }}
          className="form-control cursor-pointer d-flex align-items-center flex-grow-0"
          role="button"
          tabIndex={-1}
          onFocus={() => setShowWeekPicker(true)}
        >
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" className="mr-3" />
          {date.format(displayFormat)}
        </div>
        <InputGroup.Append>
          <Button
            variant="light"
            onClick={() => onChange(date.clone().add(1, 'd'))}
            disabled={isOutsideRange && isOutsideRange(date.clone().add(1, 'd'))}
          >
            <FontAwesomeIcon icon={faChevronRight} size="sm" color="gray" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {showWeekPicker && (
        <div className="position-absolute" style={{ zIndex: 3 }}>
          <DayPickerSingleDateController
            hideKeyboardShortcutsPanel
            enableOutsideDays
            focused
            numberOfMonths={1}
            date={date}
            onDateChange={(date) => date && onChange(date)}
            onFocusChange={() => {}}
            onOutsideClick={() => setShowWeekPicker(false)}
            isOutsideRange={isOutsideRange}
            firstDayOfWeek={DefaultCountry.firstDayOfWeek}
          />
        </div>
      )}
    </Form.Group>
  );
};

export default DayPicker;
