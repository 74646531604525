import React from 'react';

import * as Mui from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { useCreateComment } from 'gql/comments/mutations';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
};

interface IProps {
  refetchComments: () => void;
  attachedToId: string;
}

const CreateCommentInModal: React.FC<IProps> = ({ refetchComments, attachedToId }) => {
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const [commentText, setCommentText] = React.useState('');

  const [createCommentFn] = useCreateComment({
    onCompleted: () => {
      refetchComments();
      showToast('Comment Created', 'success');
      setCommentText('');
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onPostComment = () => {
    createCommentFn({
      variables: {
        input: {
          businessId: businessId,
          attachedToId: attachedToId,
          text: commentText,
          attachedToEntityType: 'Note',
        },
      },
    });
  };

  return (
    <>
      <Mui.Box display="flex" position="sticky" padding="0px 24px 24px 24px" borderTop="2px solid #ff005e">
        <Mui.FormControlLabel
          label={<span>New Comment</span>}
          labelPlacement="top"
          control={
            <Mui.TextField
              size="small"
              value={commentText}
              required
              onChange={(evt) => setCommentText(evt.target.value)}
              fullWidth
              sx={styles.inputStyles}
            />
          }
          componentsProps={{
            typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
          }}
          style={{ margin: 0, width: '100%', marginTop: '16px' }}
        />
      </Mui.Box>
      <Mui.Box className="ml-auto" marginBottom="8px" marginRight="8px">
        <Mui.Button
          startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          color="secondary"
          variant="contained"
          onClick={() => onPostComment()}
          size="small"
        >
          Post Comment
        </Mui.Button>
      </Mui.Box>
    </>
  );
};

export default CreateCommentInModal;
