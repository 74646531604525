import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'shared/components/Layout';
import Card from 'shared/components/Card';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

import * as Mui from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';

import { RootState } from 'store/reducers';

import { useGetKisiPairedPlaces } from 'gql/kisiIntegration/queries';
import { useUpdateKisiApiKey } from 'gql/kisiIntegration/mutations';
import { showToast } from 'shared/components/Toast';
import '../../businessProfile.scss';
import KisiPlacesTable from './KisiPlacesTable';

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

interface IProps {
  entityId: string;
  kisiApiKey: string;
}

interface ISortBy {
  field: string;
  direction: SortDirection;
}

type SortDirection = 'ASCENDING' | 'DESCENDING';

const KisiTab: React.FC<IProps> = ({ entityId, kisiApiKey }) => {
  const theme = useTheme();
  const isMobile = Mui.useMediaQuery(theme.breakpoints.down('md'));

  const { businessId } = useSelector((state: RootState) => state.context);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);

  const [apiKey, setApiKey] = useState('');
  const [listPairedPlaces, setListPairedPlaces] = useState<IKisiPairedCenter[]>([]);

  const [sort, setSort] = React.useState<ISortBy>({
    field: 'Name',
    direction: 'ASCENDING',
  });

  const {
    data,
    loading,
    refetch: refetchPairedPlaces,
  } = useGetKisiPairedPlaces({
    variables: {
      businessId,
    },
    onError: (err) => {
      setListPairedPlaces([]);
    },
  });

  const [updateKisiApiKey] = useUpdateKisiApiKey({
    variables: {
      input: {
        id: businessId || '',
        kisiApiKey: apiKey,
      },
    },
    onCompleted: (response) => {
      showToast('Kisi Api Key updated successfully.', 'success');
      if (response) {
        // refetchPairedPlaces();
        document.location.reload();
      }
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  const onSaveApiKey = () => {
    updateKisiApiKey();
  };

  React.useEffect(() => {
    setListPairedPlaces(data?.getKisiPairedPlaces ?? []);
  }, [data]);

  return (
    <>
      <Row align="start">
        <Col>
          <Mui.Grid container spacing={2} alignItems="stretch">
            <Mui.Grid item xs={12}>
              <Card
                header={
                  <div className="d-flex align-items-center">
                    <div>{'API Settings'}</div>
                  </div>
                }
                style={{ height: '100%' }}
              >
                <Mui.FormLabel id="tuition-radio-group" sx={{ width: '100%' }}>
                  <span>
                    Kisi API Key
                    <FontAwesomeIcon icon={faAsterisk} color={'#FF2C2C'} style={styles.labelIcon} />
                  </span>
                </Mui.FormLabel>
                <Mui.TextField
                  size="small"
                  value={apiKey}
                  onChange={(evt) => setApiKey(evt.target.value)}
                  sx={{
                    mr: 1,
                    mb: 1,
                    width: '50%',
                    '& legend': {
                      width: 0,
                    },
                  }}
                  inputProps={{
                    sx: {
                      '&::placeholder': {
                        color: '#516173 !important',
                      },
                    },
                  }}
                  placeholder={kisiApiKey ? '********-****-****-****-********' + kisiApiKey : ''}
                />
                <Mui.Button disabled={apiKey === ''} color="secondary" variant="contained" onClick={onSaveApiKey}>
                  Save
                </Mui.Button>
              </Card>
            </Mui.Grid>
            <Mui.Grid item xs={12}>
              {loading ? (
                <LoadingLines />
              ) : (
                <KisiPlacesTable
                  loading={false}
                  page={page}
                  setPage={setPage}
                  size={size}
                  totalCount={100}
                  refetch={refetchPairedPlaces}
                  sort={sort}
                  setSort={setSort}
                  listPairedPlaces={listPairedPlaces}
                />
              )}
            </Mui.Grid>
          </Mui.Grid>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(KisiTab);
