import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from 'shared/components/PageWrapper';
import { RootState } from 'store/reducers';
import { useGetContactTagsInUseAtEntity } from '../graphql/queries';
import ContactsEmptyState from './components/ContactsEmptyState';
import useGetTableMetadata from 'shared/hooks/useGetTableMetadata';
import { useSearchContactsV2Query } from 'generated/graphql';
import ContactsTableV2, { FilterData } from './components/ContactsTablev2';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { useGetCenterTagOptions } from 'pages/Centers/subroutes/Profile/graphql/queries';
import useDatatableState2 from 'shared/hooks/useDatatableState2';
import { getContactsSuccess } from '../duck/actions';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { useHistory } from 'react-router-dom';
import { nameof } from 'ts-simple-nameof';

const Contacts = () => {
  const dispatch = useDispatch();
  const { data, loading: metaDataLoading } = useGetTableMetadata({ tableName: 'guardian', fields: [] });
  const isEmptyState = data?.totalResults === 0;

  const [tableState2, tableFunctions2] = useDatatableState2();
  const user = useSelector((state: RootState) => state.user);
  const centers = useGetActiveCenters();
  const hasViewChildPermissions = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const { data: getAllowedEntitiesData } = useGetAllowedEntities(false);
  const { data: contactTags } = useGetContactTagsInUseAtEntity(user?.entityId || '');
  const { data: centerTags } = useGetCenterTagOptions(user?.entityId || '');
  const [filters, setFilters] = useState<FilterData>({});
  const [sortLastNameAsc, setSortLastNameAsc] = useState<boolean>(true);
  // TODO: If this is too slow, read and implement: https://www.apollographql.com/docs/react/pagination/overview/
  const {
    data: searchContactsData,
    loading: searchContactsLoading,
    networkStatus,
  } = useSearchContactsV2Query({
    variables: {
      input: {
        filters: {
          ...filters,
          businessIds: filters.businessIds ?? getAllowedEntitiesData?.getAllowedEntities.map((x) => x.id) ?? [],
        },
        sortLastNameAsc: sortLastNameAsc,
        pagination: {
          pageNumber: tableState2.activePage - 1, // Backend indexed on 0, fe index on 1
          pageSize: tableState2.pageSize,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: !getAllowedEntitiesData?.getAllowedEntities,
    onCompleted: (data) => {
      dispatch(getContactsSuccess((data.searchContactsV2.data as IContact[]) ?? []));
    },
  });
  const contacts: IContact[] = (searchContactsData?.searchContactsV2.data as IContact[]) ?? [];

  const history = useHistory();

  if (searchContactsLoading && networkStatus !== NETWORK_STATUS.SET_VARIABLES)
    return (
      <PageWrapperBody>
        <DataTableLoadingSkeleton />
      </PageWrapperBody>
    );

  return (
    <PageWrapper pageTitle="Contact Profiles" applyPadding={true}>
      {isEmptyState ? (
        <ContactsEmptyState />
      ) : (
        <ContactsTableV2
          contacts={contacts}
          loading={searchContactsLoading}
          dataSize={searchContactsData?.searchContactsV2.totalRecords}
          ktChildren={{
            show: hasViewChildPermissions,
            onClick: (childId) => {
              history.push(`/families/children/${childId}`);
            },
          }}
          tableFunctions={tableFunctions2}
          tableState={tableState2}
          headerProps={{
            isInternal: user?.isInternal ?? false,
            filterOptionsRecord: {
              businessIds:
                getAllowedEntitiesData?.getAllowedEntities.map((x) => ({ value: x.id, label: x.name })) ?? [],
              centerTagIds: centerTags?.getCenterTagOptions.map((x) => ({ value: x.id, label: x.name })) ?? [],
              centerIds: centers
                .map((x) => ({ value: x.id, label: x.name }))
                .sort((a, b) => a.label.localeCompare(b.label)),
              contactTagIds:
                contactTags?.getContactTagsInUseAtEntity
                  .map((x) => ({ value: x.id, label: x.name }))
                  .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
            },
            onFilterUpdate: (x) => {
              setFilters(x);
            },
          }}
          onRowClick={(contact) => {
            history.push(`/families/contacts/${contact.id}`);
          }}
          onSort={(field, direction) => {
            if (field !== nameof<IContact>((x) => x.lastname)) return;
            setSortLastNameAsc(direction === 'ASCENDING' ? true : false);
          }}
        />
      )}
    </PageWrapper>
  );
};

export default Contacts;
