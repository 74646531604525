// the below should be removed, it's simply added since useUpdateEmployeeProfile and useUpdatePersonEmergencyContacts aren't typed
// @ts-nocheck
import { useCallback, useState } from 'react';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from 'shared/apis/core';
import { GET_STAFF } from './queries';

// Graphql Interfaces //

interface IUpdateAvailabilityData {
  updateAvailabilityForStaff: IStaff;
}
// End Graphql Interfaces //

const UPDATE_STAFF_PROFILE = gql`
  mutation ($input: UpdatePersonAsStaff!) {
    updateStaffProfile(input: $input) {
      id
      firstname
      lastname
      nickname
      phoneNumber
      address {
        address1
        address2
        city
        state
        country
        postalCode
      }
      dob
      email
      staffTrainings {
        name
        expiryDate
      }
      primaryCenterId
    }
  }
`;

const ADD_STAFF_TAG = gql`
  mutation ($entityId: ID!, $personId: ID!, $tagIds: [ID!]!) {
    addTagsToPersonAtEntity(entityId: $entityId, personId: $personId, tagIds: $tagIds) {
      id
    }
  }
`;

const REMOVE_STAFF_TAG = gql`
  mutation ($entityId: ID!, $personId: ID!, $tagIds: [ID!]!) {
    removeTagsFromPersonAtEntity(entityId: $entityId, personId: $personId, tagIds: $tagIds) {
      id
    }
  }
`;

const UPDATE_AVAILABILITY = gql`
  mutation ($input: UpdateAvailabityChangeForStaffInput!) {
    updateAvailabilityForStaff(input: $input) {
      id
      availability {
        approved {
          dayOfWeek
          times {
            start
            end
          }
        }
      }
    }
  }
`;

const CREATE_KISI_SHARE = gql`
  mutation ($input: CreateKisiShare!) {
    createKisiShare(input: $input) {
      id
      personId
      kisiEmail
      kisiShareId
      businessId
    }
  }
`;
const DELETE_KISI_SHARE = gql`
  mutation ($shareId: ID!, $businessId: ID!) {
    deleteKisiShare(id: $shareId, businessId: $businessId) {
      id
      personId
      kisiEmail
      kisiShareId
      businessId
    }
  }
`;

export const useUpdateStaffProfile = () =>
  useMutation<IUpdateProfileData, IUpdateProfileVariables>(UPDATE_STAFF_PROFILE, {
    update: (proxy, result) => {
      if (result.data?.updateStaffProfile) {
        const { id, ...rest } = result.data.updateStaffProfile;
        const cachedResult: { getStaff: IStaff } | null = proxy.readQuery({ query: GET_STAFF, variables: { id } });
        if (cachedResult?.getStaff) {
          proxy.writeQuery({
            query: GET_STAFF,
            variables: { id },
            data: { getStaff: { ...cachedResult.getStaff, rest } },
          });
        }
      }
    },
  });

export const useCreateKisiShare = (options?: MutationHookOptions<ICreateShareData, ICreateShareVariables>) =>
  useMutation<ICreateShareData, ICreateShareVariables>(CREATE_KISI_SHARE, {
    ...options,
  });

export const useRemoveKisiShare = (options?: MutationHookOptions<IDeleteShareData, IDeleteShareVariables>) =>
  useMutation<IDeleteShareData, IDeleteShareVariables>(DELETE_KISI_SHARE, {
    ...options,
  });

// update the emergency contact portion of the staff profile
export const useUpdateStaffEmergencyContacts = (personId: string) =>
  useMutation(UPDATE_STAFF_PROFILE, {
    refetchQueries: [
      {
        query: GET_STAFF,
        variables: {
          id: personId,
        },
      },
    ],
  });

export const useUpdateStaffTags: () => [
  (staff: IStaff, tagIdsToAdd: string[], tagIdsToRemove: string[]) => Promise<void>,
  IMutationResponse
] = () => {
  const [response, setResponse] = useState<IMutationResponse>({
    loading: false,
    error: null,
    data: null,
  });

  // initially skip the query since we don't have a person id.... yet
  const { refetch: refetchPerson } = useQuery(GET_STAFF, { skip: true });
  const [addStaffTagMutation] = useMutation(ADD_STAFF_TAG);
  const [removeStaffTagMutation] = useMutation(REMOVE_STAFF_TAG);

  const updateStaffTagsFn = useCallback(
    async (staff: IStaff, tagIdsToAdd: string[], tagIdsToRemove: string[]) => {
      const personId: string = staff.id;
      setResponse({ ...response, loading: true });
      const mutations = [];

      if (tagIdsToAdd.length) {
        mutations.push(
          addStaffTagMutation({
            variables: { entityId: staff.entityId, personId: staff.id, tagIds: tagIdsToAdd },
          })
        );
      }
      if (tagIdsToRemove.length) {
        mutations.push(
          removeStaffTagMutation({
            variables: { entityId: staff.entityId, personId: staff.id, tagIds: tagIdsToRemove },
          })
        );
      }

      try {
        const data = await Promise.all(mutations);
        await refetchPerson({ id: personId });
        setResponse({ ...response, loading: false, data });
      } catch (error) {
        setResponse({ ...response, loading: false, error });
      }
    },
    [addStaffTagMutation, removeStaffTagMutation, response, refetchPerson]
  );

  return [updateStaffTagsFn, response];
};

export const useUpdateAvailability = () =>
  useMutation<IUpdateAvailabilityData, IUpdateAvailabilityVariables>(UPDATE_AVAILABILITY, {
    update: (proxy, result) => {
      if (result.data?.updateAvailabilityForStaff) {
        const { id, availability } = result.data.updateAvailabilityForStaff;
        const cachedResult: { getStaff: IStaff } | null = proxy.readQuery({ query: GET_STAFF, variables: { id } });
        if (cachedResult?.getStaff) {
          proxy.writeQuery({
            query: GET_STAFF,
            variables: { id },
            data: { getStaff: { ...cachedResult.getStaff, availability } },
          });
        }
      }
    },
  });
