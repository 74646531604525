import React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import * as Mui from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faPlus, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';

import RoyaltyFeesTable from './RoyaltyFeesTable';
import { useSearchRoyaltyFees } from 'pages/Businesses/subroutes/BusinessSettings/gql/query';
import AddRoyaltyFee from './AddRoyaltyFee';
import { sortedAccountsPagedResultFields } from 'gql/account/fields';

const RoyaltyFees: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openAddFee, setOpenAddFee] = React.useState(false);
  const history = useHistory();
  const businessIdRedux = useSelector((state: RootState) => state.context.businessId);
  const businessId = _.isNil(businessIdRedux) ? '' : businessIdRedux;
  const [from, setFrom] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [nameSortDirection, setNameSortDirection] = React.useState('ASCENDING');
  const [searchInput, setSearchInput] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');

  const {
    data: royaltyFees,
    loading: loadingFees,
    refetch,
  } = useSearchRoyaltyFees({
    skip: _.isEmpty(businessId),
    variables: {
      input: {
        businessId: businessId,
        from: from,
        size: size,
        nameContains: searchQuery,
        sortDirection: nameSortDirection,
      },
    },
  });

  React.useEffect(() => {
    updateSearchValue(searchInput);
  }, [searchInput]);

  const updateSearchValue = React.useCallback(
    _.debounce((input: string) => {
      setSearchQuery(input);
    }, 500),
    []
  );

  React.useEffect(() => {
    setFrom(1);
  }, [searchQuery]);

  return (
    <>
      <Mui.Box>
        <Mui.Box display="flex" justifyContent="space-between" padding={2}>
          <Mui.Typography variant="h2">Royalty Fees</Mui.Typography>
          {isMobile ? (
            <Mui.Button onClick={() => setOpenAddFee(true)} color="secondary" variant="contained">
              <FontAwesomeIcon icon={faPlus} />
            </Mui.Button>
          ) : (
            <Mui.Button
              onClick={() => setOpenAddFee(true)}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              variant="contained"
              color="secondary"
            >
              Add Fee
            </Mui.Button>
          )}
        </Mui.Box>
        <Mui.Card sx={{ marginTop: '8px' }} elevation={0} variant="outlined">
          <Mui.CardContent sx={{ padding: '24px' }}>
            <Mui.Box display="flex" justifyContent="space-between">
              <Mui.TextField
                variant="outlined"
                value={searchInput}
                onChange={(evt) => setSearchInput(evt.target.value)}
                InputProps={{
                  startAdornment: (
                    <Mui.InputAdornment position="start">
                      <FontAwesomeIcon size="xs" icon={faSearch} />
                    </Mui.InputAdornment>
                  ),
                  endAdornment: (
                    <Mui.InputAdornment position="end">
                      <Mui.IconButton onClick={() => setSearchInput('')}>
                        <FontAwesomeIcon size="xs" icon={faTimes} />
                      </Mui.IconButton>
                    </Mui.InputAdornment>
                  ),
                }}
                inputProps={{
                  'aria-label': 'Search Royalty Fees',
                }}
                placeholder="Search Royalty Fees"
                size="small"
                sx={{
                  minWidth: '400px',
                  '& legend': {
                    width: 0,
                  },
                }}
              />
            </Mui.Box>
          </Mui.CardContent>
        </Mui.Card>
        {!_.isNil(royaltyFees) && (
          <Mui.Box>
            {royaltyFees.searchRoyaltyFee.totalRecords === 0 ? (
              <Mui.Stack direction="column" spacing={2} alignItems="center" marginTop={4}>
                <FontAwesomeIcon icon={faHandHoldingUsd} size="4x" />
                <Mui.Typography>There are no Royalty Fees available to display.</Mui.Typography>
              </Mui.Stack>
            ) : (
              <RoyaltyFeesTable
                feeData={royaltyFees?.searchRoyaltyFee.data}
                refetch={refetch}
                from={from}
                size={size}
                setFrom={setFrom}
                setSize={setSize}
                totalItems={royaltyFees?.searchRoyaltyFee.totalRecords}
                nameSortDirection={nameSortDirection}
                setNameSortDirection={setNameSortDirection}
              />
            )}
          </Mui.Box>
        )}
      </Mui.Box>
      <AddRoyaltyFee openAddFee={openAddFee} setOpenAddFee={setOpenAddFee} refetch={refetch} />
    </>
  );
};

export default RoyaltyFees;
