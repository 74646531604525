import { QueryHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

/**
 *
 * @param options
 */
export function useGetRateFor(options: QueryHookOptions) {
  const query = gql`
    query ($input: CalculateQualifyingRateInput) {
      calculateQualifyingRate(input: $input) {
        value
        qualifyingNumber
      }
    }
  `;
  return useQuery(query, { ...options });
}
