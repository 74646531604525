import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import Alert from 'shared/components/Alert';
import { capitalize } from 'shared/util/string';
import MultipleClassSelect from 'shared/components/Select/MultipleClassSelect';
import TagsDropdown from './components/TagsDropdown';

interface IFormStateShape {
  centerId: string;
  selectedClasses: string[] | null;
  selectedConditions: Array<{ value: string; label: string }>;
  startDate: string | null;
  endDate: string | null;
  format: 'EXCEL' | 'PDF';
  selectedChildTag: ITag[];
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  onSubmit: (
    startDate: string,
    endDate: string | null,
    centerId: string,
    classIds: string[] | null,
    childTagId: string | null,
    conditionIds: string[],
    format: 'EXCEL' | 'PDF'
  ) => void;
  onClose: () => void;
  failedReportDownload: string | null;
  entityId: string;
  showReportFormat?: boolean;
  showConditions?: boolean;
  className?: string;
  resetFailedDownload: () => void;
  isEndDateRequired?: boolean;
  pastOnly?: boolean;
  hideArchivedClasses?: boolean;
  hideInactiveClasses?: boolean;
}

const TimeframeMultipleClassTagFilterReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  failedReportDownload,
  entityId,
  resetFailedDownload,
  className,
  isEndDateRequired = true,
  pastOnly = false,
  hideArchivedClasses = false,
  hideInactiveClasses = false,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<IFormStateShape>({
    centerId: '',
    selectedClasses: null,
    selectedConditions: [],
    startDate: null,
    endDate: null,
    format: 'EXCEL',
    selectedChildTag: [],
  });

  const centerSelectOptions: ICenter[] = useGetActiveCenters();
  let submitTimer;

  useEffect(() => {
    if (failedReportDownload) resetFailedDownload();
  }, [failedReportDownload, formData, resetFailedDownload]);

  useEffect(() => {
    if (!isOpen) {
      clearTimeout(submitTimer);
      setFormData({
        centerId: '',
        selectedClasses: null,
        selectedConditions: [],
        startDate: null,
        endDate: null,
        format: 'EXCEL',
        selectedChildTag: [],
      });
    }
  }, [isOpen, submitTimer]);

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (formData.startDate && formData.centerId) {
      onSubmit(
        formData.startDate,
        formData.endDate,
        formData.centerId,
        formData.selectedClasses,
        formData.selectedChildTag.length > 0 ? formData.selectedChildTag[0].id : null,
        formData.selectedConditions.map((i) => i.value),
        formData.format
      );
      if (!!submitTimer) {
        clearTimeout(submitTimer);
      }
      submitTimer = setTimeout(() => {
        handleSubmit();
      }, 3 * 55 * 1000);
    }
  }, [formData, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({
      centerId: '',
      selectedClasses: null,
      selectedConditions: [],
      startDate: null,
      endDate: null,
      format: 'EXCEL',
      selectedChildTag: [],
    });
    onClose();
  }, [onClose]);

  const downloadReport = () => {
    if (failedReportDownload) {
      window.open(failedReportDownload);
      handleClose();
    }
  };

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice={failedReportDownload ? 'Download Report' : 'Run Export'}
      primaryCallback={() => {
        failedReportDownload ? window.open(failedReportDownload) : handleSubmit();
      }}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled: !formData.startDate || !formData.centerId || (isEndDateRequired && !formData.endDate),
        loading: isLoading,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      className={className}
    >
      <Row>
        <Col>
          <Select
            required
            id="center-input"
            label={capitalize(t('spelling.center'))}
            aria-label={`Select ${capitalize(t('spelling.center'))}`}
            value={formData.centerId}
            options={centerSelectOptions.map((c) => ({ value: c.id, label: c.name }))}
            onChange={(option: any) => setFormData((prev) => ({ ...prev, centerId: option.value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <MultipleClassSelect
              required
              label={`Select ${capitalize(t('spelling.class'))}`}
              selectedClassIds={formData.selectedClasses}
              onSelect={(classIds) => {
                setFormData({ ...formData, selectedClasses: classIds });
              }}
              centerId={formData.centerId}
              useNullForAllOption
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <DateInput
            required
            label={capitalize(t('spelling.start-date'))}
            date={formData.startDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, startDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(date: moment.Moment) =>
              (pastOnly && moment(date).isAfter(moment(), 'date')) ||
              (moment(formData.endDate).isValid() && date.isSameOrAfter(moment(formData.endDate), 'date'))
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <DateInput
            label={capitalize(t('spelling.end-date'))}
            date={formData.endDate}
            required={isEndDateRequired}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, endDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(date: moment.Moment) =>
              (pastOnly && moment(date).isAfter(moment(), 'date')) ||
              (moment(formData.startDate).isValid() && date.isBefore(moment(formData.startDate), 'date'))
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <TagsDropdown
            categories={['CHILD']}
            selectedTags={formData.selectedChildTag ?? []}
            setSelectedTags={(tags: ITag[]): void => {
              setFormData((prev) => ({ ...prev, selectedChildTag: tags }));
            }}
          />
        </Col>
      </Row>
      {!!failedReportDownload && (
        <Alert className="mt-4" variant="success">
          <p className="mb-0">
            Your report was succesfully generated and can now be{' '}
            <a onClick={downloadReport} className="font-size-18 font-weight-normal cursor-pointer">
              downloaded
            </a>
            .
          </p>
        </Alert>
      )}
    </SideModalDrawer>
  );
};

export default TimeframeMultipleClassTagFilterReportModal;
