import React, { useCallback } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Col, Row } from 'shared/components/Layout';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'shared/util/string';
import useFormatDate from 'shared/hooks/useFormatDate';

interface IProps {
  isOpen: boolean;
  timeEntry: ITimeEntry | null;
  onClose: () => void;
}

const DetailsTimeEntryModal: React.FC<IProps> = ({ isOpen, timeEntry, onClose, ...props }) => {
  const { t } = useTranslation(['timelog']);
  const formatDate = useFormatDate();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <SideModalDrawer
      title={t('timelog:time-card.time-adjustment-detail-modal-title')}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Close"
      primaryCallback={handleClose}
      hasSecondaryChoice={false}
    >
      <Row className="mb-4">
        <Col>
          <h5>{t('timelog:time-card.adjustedBy-modal-row')}</h5>
          <p>
            {timeEntry?.adjustedAt
              ? timeEntry?.adjustmentType === 'SYSTEM'
                ? 'SYSTEM'
                : getFullName(timeEntry.adjustedByPerson)
              : ''}
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <h5>{t('timelog:time-card.adjustedAt-modal-row')}</h5>
          <p>{formatDate(timeEntry?.adjustedAt ?? '', 'MMMM Do, YYYY')}</p>
        </Col>
      </Row>
      {timeEntry?.note && (
        <Row className="mb-4">
          <Col>
            <h5>{t('timelog:time-card.notes-modal-row')}</h5>
            <p>{timeEntry?.note}</p>
          </Col>
        </Row>
      )}
    </SideModalDrawer>
  );
};

export default DetailsTimeEntryModal;
