import { useEffect, useState } from 'react';
import DataTable from 'shared/components/DataTable';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { nameof } from 'ts-simple-nameof';
import { showToast } from 'shared/components/Toast';
import {
  ExistingContactMapping,
  IntegrationType,
  MatchType,
  useGetExistingEntitiesMappingByContactQuery,
  useSelectCcrmMappingMutation,
} from 'generated/graphql';
import { Col, Row } from 'shared/components/Layout';
import { Container } from 'react-bootstrap';
import { parsePhoneNumberWithRegion } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';

interface IProps {
  businessId: string;
}

const CcrmUnlinkedContactsForm = ({ businessId }: IProps) => {
  const [ktCcrmMap, setKtCcrmMap] = useState<{ groupId: string; id: string }[]>([]);
  const { data, loading, refetch } = useGetExistingEntitiesMappingByContactQuery({
    variables: { input: { businessId, integrationType: IntegrationType.ChildcareCrm, matchType: MatchType.Contact } },
  });
  const [mutateSelectCcrmMapping, { loading: mutateLoading }] = useSelectCcrmMappingMutation();
  const unlinkedKTContacts = data?.getExistingEntitiesMapping.existingContactMapping ?? [];

  const handleCancel = (mappingGroupId) => {
    const existingRows = ktCcrmMap.filter((e) => e.groupId !== mappingGroupId);
    setKtCcrmMap(existingRows);
  };

  const handleSave = (mappingGroupId) => {
    const match = ktCcrmMap.find((r) => r.groupId === mappingGroupId);

    if (!match) return;

    mutateSelectCcrmMapping({
      variables: {
        input: {
          businessId,
          integrationType: IntegrationType.ChildcareCrm,
          matchType: MatchType.Contact,
          mappings: [
            {
              rowId: match.id,
              groupId: match.groupId,
            },
          ],
        },
      },
    })
      .then((result) => {
        setKtCcrmMap([]);
        showToast(t('integrations:ccrm.unlinked.contacts.toast-success'), 'success');
        refetch();
      })
      .catch((err) => showToast(`${err.toString()}`, 'error'));
  };

  const { t } = useTranslation(['integrations']);
  const groupedKtContacts = groupBy(unlinkedKTContacts, (e) => e.mappingGroupId);
  const tableData: ExistingContactMapping[] = Object.values(groupedKtContacts).map(
    (existingContact: ExistingContactMapping[]) => existingContact[0]
  );

  return (
    <>
      <DataTable
        showLoadingOverlay={loading || mutateLoading}
        data={tableData}
        dataSize={tableData.length ?? 0}
        showSelect={false}
        showPagination={false}
        noPadding={true}
        keyField={nameof<ExistingContactMapping>((uc) => uc.id)}
        columns={[
          {
            text: t('integrations:ccrm.unlinked.contacts.data-table.kt-contact-name'),
            dataField: nameof<ExistingContactMapping>((uc) => uc.ktContactFullName),
          },
          {
            text: t('integrations:ccrm.unlinked.contacts.data-table.kt-phone-number'),
            dataField: nameof<ExistingContactMapping>((uc) => uc.ktContactPrimaryPhoneNumber),
            formatter: (cell) => {
              const phoneNumber = parsePhoneNumberWithRegion(cell);
              return phoneNumber.formatNational();
            },
          },
          {
            text: t('integrations:ccrm.unlinked.contacts.data-table.kt-email-address'),
            dataField: nameof<ExistingContactMapping>((uc) => uc.ktContactEmailAddress),
          },
        ]}
        expandRow={(ktContact: ExistingContactMapping) => {
          const handleToggle = (row: ExistingContactMapping) => {
            const existingRows = ktCcrmMap.filter((e) => e.groupId !== row.mappingGroupId);
            setKtCcrmMap(
              existingRows.concat([
                {
                  groupId: row.mappingGroupId,
                  id: row.id,
                },
              ])
            );
          };

          const filteredContacts = unlinkedKTContacts.filter((e) => e.mappingGroupId === ktContact.mappingGroupId);
          return (
            <FormWrapper2
              formIsDirty={true}
              toggleDirty={() => {}}
              className="pl-5"
              onCancel={() => handleCancel(ktContact.mappingGroupId)}
              onSave={() => handleSave(ktContact.mappingGroupId)}
            >
              <DataTable
                data={filteredContacts}
                dataSize={filteredContacts.length ?? 0}
                showSelect={false}
                showPagination={false}
                striped
                columns={[
                  {
                    text: ``,
                    dataField: 'linkToAccount',
                    isDummyField: true,
                    formatter: (cell, row: ExistingContactMapping) => (
                      <Container>
                        <Row align="center" justify="center">
                          <Col>
                            <input
                              id={row.id}
                              checked={!!ktCcrmMap.find((r) => r.groupId === row.mappingGroupId && r.id === row.id)}
                              type="radio"
                              className="custom-form-input"
                              onChange={(e) => handleToggle(row)}
                            />
                          </Col>
                        </Row>
                      </Container>
                    ),
                  },
                  {
                    text: t('integrations:ccrm.unlinked.contacts.data-table.ccrm-contact-name'),
                    dataField: nameof<ExistingContactMapping>((uc) => uc.ccrmContactFullName),
                  },
                  {
                    text: t('integrations:ccrm.unlinked.contacts.data-table.ccrm-phone-number'),
                    dataField: nameof<ExistingContactMapping>((uc) => uc.ccrmPrimaryPhoneNumber),
                    formatter: (cell) => {
                      const phoneNumber = parsePhoneNumberWithRegion(cell);
                      return phoneNumber.formatNational();
                    },
                  },
                  {
                    text: t('integrations:ccrm.unlinked.contacts.data-table.ccrm-email-address'),
                    dataField: nameof<ExistingContactMapping>((uc) => uc.ccrmEmailAddress),
                  },
                  {
                    text: t('integrations:ccrm.unlinked.contacts.data-table.ccrm-family-id'),
                    dataField: nameof<ExistingContactMapping>((uc) => uc.ccrmFamilyId),
                  },
                  {
                    text: t('integrations:ccrm.unlinked.contacts.data-table.ccrm-children'),
                    dataField: nameof<ExistingContactMapping>((uc) => uc.ccrmChildren),
                    formatter: (cell: string[]) => (
                      <Container>
                        {cell.map((child, index) => (
                          <Row key={index} align="center" justify="center">
                            <Col>{child}</Col>
                          </Row>
                        ))}
                      </Container>
                    ),
                  },
                ]}
              />
            </FormWrapper2>
          );
        }}
      />
    </>
  );
};

export default CcrmUnlinkedContactsForm;
