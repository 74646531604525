import moment from 'moment';
import React from 'react';
import { HorizontalDivider } from 'shared/components/Dividers';
import { Col, Row } from 'shared/components/Layout';

interface IProps {
  data: IAccsCertDertForCenters;
}

const dateFormat = 'DD/MM/YYYY';

const AccsCard: React.FC<IProps> = ({ data }) => {
  return (
    <div className="kt-account-ccs-enrolment-card">
      <Row align="start" className="mb-4">
        <Col md="3">
          <HeadingCaption heading="Weeks at Risk" caption={data.weeksAtRisk} />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="Days Remaining"
            caption={
              !!data.startDate && !!data.endDate ? `${moment(data.endDate).diff(moment(data.startDate), 'days')}` : ''
            }
          />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="State/Territory Notified"
            caption={`${data.stateTerritoryBody && data.stateTerritoryBody.noticeToStateTerritory ? 'Yes' : 'No'}`}
          />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="State/Territory Notified Provider"
            caption={`${data.stateTerritoryBody && data.stateTerritoryBody.notifiedByStateTerritory ? 'Yes' : 'No'}`}
          />
        </Col>
      </Row>
      <Row align="start" className="mb-4">
        <Col md="3">
          <h6>State/Territory Body Details</h6>
          <HeadingCaption
            heading={'Reference Number'}
            caption={`${data.stateTerritoryBody && data.stateTerritoryBody.stateReferenceNumber}`}
          />
        </Col>
        <Col md="3">
          <h6>&nbsp;</h6>
          <HeadingCaption
            heading={'Notice Date'}
            caption={`${moment(data.stateTerritoryBody && data.stateTerritoryBody.noticeGivenDate).format(dateFormat)}`}
          />
        </Col>
        <Col md="3">
          <h6>&nbsp;</h6>
          <HeadingCaption
            heading={'Organisation'}
            caption={`${data.stateTerritoryBody && data.stateTerritoryBody.organisationName}`}
          />
        </Col>
        <Col md="3">
          <h6>&nbsp;</h6>
          <HeadingCaption
            heading={'Notified By'}
            caption={
              !!data.stateTerritoryBody &&
              data.stateTerritoryBody.notifiedByPersonFirstName &&
              data.stateTerritoryBody.notifiedByPersonLastName
                ? `${data.stateTerritoryBody.notifiedByPersonFirstName} ${data.stateTerritoryBody.notifiedByPersonLastName}`
                : ''
            }
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <HorizontalDivider />
        </Col>
      </Row>
    </div>
  );
};

const HeadingCaption = (props: { heading: string; caption: string }) => {
  return (
    <>
      <div>
        <small>{props.heading}</small>
      </div>
      <div>
        <small className="caption">{props.caption}</small>
      </div>
    </>
  );
};

export default AccsCard;
