import { useUpdateRestrictChildDocumentsMutation } from 'generated/graphql';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'shared/components/Switch';
import { showToast } from 'shared/components/Toast';
import { RootState } from 'store/reducers';
import { getChildByIdSuccess } from '../../../duck/actions';

interface IProps {
  child: IChild;
  readOnly: boolean;
}
const RestrictDocuments: React.FC<IProps> = ({ child: savedChild, readOnly }) => {
  const dispatch = useDispatch();
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const [restrictDocuments, setRestrictDocuments] = useState<boolean>(savedChild.restrictAccessToChildDocuments);

  const [updateRestrictChildDocumentsMutation, { data, loading, error }] = useUpdateRestrictChildDocumentsMutation({
    onError: (err) => showToast('Unable to update child restricted documents', 'error'),
    onCompleted: (response) => {
      response.updateRestrictChildDocuments && showToast('Document restriction updated', 'success');
    },
  });

  const handleChangeValue = useCallback(
    (value: boolean) => {
      setRestrictDocuments(value);
      updateRestrictChildDocumentsMutation({
        variables: {
          businessId: businessId,
          childId: savedChild.id,
          restrictDocuments: value,
        },
      });
    },
    [updateRestrictChildDocumentsMutation, businessId, savedChild.id]
  );

  useEffect(() => {
    if (savedChild.restrictAccessToChildDocuments) {
      setRestrictDocuments(savedChild.restrictAccessToChildDocuments);
    }
  }, [savedChild]);

  return (
    <div className="d-flex justify-content-between">
      <div className="pr-5">
        <p className="mb-0">
          <b>Restrict access to child documents</b>
          <br />
          Restrict account contacts' ability to view or download any uploaded documents on the child's profile and
          enrolment form.
        </p>
      </div>
      <div>
        <Switch
          disabled={loading}
          value={restrictDocuments}
          onChange={(checked: boolean) => handleChangeValue(checked)}
        />
      </div>
    </div>
  );
};

export default RestrictDocuments;
