import React, { useCallback } from 'react';
import { currencyFormat } from 'shared/util/currency';
import { orderBy } from 'lodash';

interface IProps {
  contract: IContract;
}

const ContractSubsidiesTab: React.FC<IProps> = ({ contract, ...props }) => {
  const getSubsidyPaymentPeriodAbbreviation = useCallback((paymentPeriod: SubsidyPaymentPeriodType): string => {
    switch (paymentPeriod) {
      case 'EVERY_TWO_WEEKS':
        return 'two weeks';
      case 'MONTHLY':
        return 'month';
      case 'WEEKLY':
        return 'week';
      case 'SESSION':
        return 'session';
      default:
        return '';
    }
  }, []);

  const getSubsidyPaymentTypeLabel = useCallback((type: AgencyBillingCalculation): string => {
    const label = type === 'COPAY_AMOUNT' ? 'Copay' : 'Subsidy';

    return `${label} Amount`;
  }, []);

  const getSubsidySortOrder = useCallback((subsidy: IAgencyAccountChild): number => {
    // per product the order should be: expiring soon, active, future (expired will not be visible)
    switch (subsidy.status) {
      case 'EXPIRING_SOON':
        return 3;
      case 'ACTIVE':
        return 2;
      case 'FUTURE':
        return 1;
      case 'EXPIRED':
        return 0;
      default:
        return -1;
    }
  }, []);

  return (
    <div className="d-flex flex-1">
      <div className="d-flex flex-column flex-1">
        {orderBy(contract.associatedSubsidies ?? [], getSubsidySortOrder, 'desc')
          .filter((subsidy: IAgencyAccountChild) => subsidy.status !== 'EXPIRED')
          .map((subsidy: IAgencyAccountChild, idx: number) => (
            <div key={idx} className="mb-4 w-100">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="font-size-12">Agency</div>
                  <div className="text-dark">{subsidy.agencyName}</div>
                </div>
                <div className="d-flex flex-column">
                  {subsidy.agencyAccountChildEnrollments.map((childEnrollment, i) => (
                    <div key={`${i}-${childEnrollment.id}`}>
                      <div className="font-size-12 text-right">
                        {getSubsidyPaymentTypeLabel(subsidy.billingCalculationType)}
                      </div>
                      <div className="text-dark text-right">
                        {currencyFormat(childEnrollment.subsidyPaymentPortion)} /{' '}
                        {getSubsidyPaymentPeriodAbbreviation(subsidy.subsidyPeriod)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ContractSubsidiesTab;
