import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'shared/components/DataTable';
import { Col, Row } from 'shared/components/Layout';
import applicationFeeTypes from 'shared/constants/dropdownOptions/applicationFeeTypes';
import { IEnrollmentProgram } from 'shared/types/enrollmentProgram';
import { currencyFormat } from 'shared/util/currency';
import { capitalize } from 'shared/util/string';

interface IProps {
  program: IEnrollmentProgram;
}
const EnrollmentProgramRow: React.FC<IProps> = ({ program }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Row>
        <Col lg={4}>
          <label>{t('enrollment.programs.description')}</label>
          <p style={{ whiteSpace: 'normal' }}>{program.description === '' ? '-' : program.description}</p>
        </Col>
        <Col lg={2}>
          <label>{t('enrollment.programs.min-enrollment-days')}</label>
          <p>{program.minEnrolmentDays}</p>
        </Col>
        <Col lg={2}>
          <label>{t('enrollment.programs.max-enrollment-days')}</label>
          <p>{program.maxEnrolmentDays}</p>
        </Col>
      </Row>
      <DataTable
        data={program.programCenters}
        showPagination={false}
        noPadding
        showSelect={false}
        striped
        columns={[
          {
            text: t('spelling.capitalCenter'),
            dataField: 'centerName',
          },
          {
            text: capitalize(t('spelling.class')),
            dataField: 'className',
          },
          {
            text: capitalize(t('spelling.fee')),
            dataField: 'feeName',
          },
          {
            text: capitalize(t('enrollment.programs.application-fee-type')),
            dataField: 'applicationFeeType',
            formatter: (cell: string) => {
              const applicationFeeType = applicationFeeTypes.find((fee) => fee.value === cell);
              return applicationFeeType ? applicationFeeType.label : '-';
            },
          },
          {
            text: capitalize(t('enrollment.programs.application-fee')),
            dataField: 'applicationFeeAmount',
            formatter: (cell: number) => <div className="ml-4">{cell ? currencyFormat(cell) : '-'}</div>,
          },
        ]}
      />
    </div>
  );
};

export default EnrollmentProgramRow;
