import { staffPositionFields } from 'gql/position/fields';

export const timeEntryFields = `
  id
  shiftId
  centerId
  positionId
  timeIn
  trackedTimeIn
  timeOut
  trackedTimeOut
  type
  note
  updatedAt
  updatedBy
  adjustedAt
  adjustedBy
  adjustmentType
  archivedAt
  archivedBy
  staffPosition {
    ${staffPositionFields}
  }
  adjustedByPerson {
    id
    firstname
    lastname
  }
  note
  clockedSelfIn
  clockedSelfOut
`;
