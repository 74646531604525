//@ts-check
import React, { useContext, useState } from 'react';
import _ from 'lodash';
import ReactGA from 'react-ga';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Provider as CoreApiProvider } from './shared/apis/core';
import { Provider as K2ApiProvider } from './shared/apis/k2Api';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import routes from './shared/util/routes';
import Login from './pages/Login';
import Join from './pages/Join';
import RecoverPassword from './pages/RecoverPassword';
import VerifyEmailAlert from 'pages/Account/VerifyEmailAlert';
import MagicLinkChangeEmail from 'pages/MagicLinks/ChangeEmail';
import MagicLinkVerifyEmail from 'pages/MagicLinks/VerifyEmail';
import Spinner from 'shared/components/Spinner';
import { Toast } from 'shared/components/Toast';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import K2Navigation from 'shared/components/Navigation/K2Navigation';
import useGetMe from 'shared/hooks/useGetMe';
import JWTRefresher from 'shared/components/JWTRefresher';
import K2ErrorBoundaryAndLink from 'shared/components/ErrorBoundary/ErrorBoundaryBannerAndLink';
import { sessionStatus as sessionStatusEnum } from 'shared/constants/enums/sessionStatus';
import { fetchAllowedEntities } from 'store/entities/actions';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import './App.scss';
import ScrollToTop from 'shared/components/ScrollToTop';
import './i18n';
import config from 'config';
import Alert from 'shared/components/Alert';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import moment from 'moment';
import DownloadKTConnect from './pages/DownloadKTConnect/DownloadKTConnect';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { PermissionContext } from './shared/contexts/permissionContext';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import ApplicationContextStatusIndicator from './shared/components/ApplicationContextStatusIndicator/ApplicationContextStatusIndicator';
import { ApplicationContext } from './shared/contexts/ApplicationContext';
import ConfirmationContainer from 'shared/components/ConfirmationContainer/';
import { useGetCommsBusinessSettingsQuery } from 'generated/graphql';
import { getCommsBusinessSettings, getUsesComms } from 'pages/Engagement/duck/actions';
import { useGetEntity } from 'gql/business/queries';
import { useManageUSModeSetting } from 'US/Billing/USMode';
import { useGetBusinessConfigurationOptionsById } from 'gql/businessConfiguration/queries';
import { useGetBusinessFeatureOptionsById } from 'gql/businessFeature/queries';
import Freshchat from 'shared/apis/Freshchat';

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: config.applicationInsightsConnectionString,
    extensions: [reactPlugin],
    disableFetchTracking: false,
    disableAjaxTracking: true,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    correlationHeaderDomains: ['*.kangarootime.com', 'kangarootime.com', 'localhost'],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((envelope) => {
  envelope.tags = envelope.tags || [];
  envelope.tags.push({ 'ai.cloud.role': 'K2 Console' });
});

// pull the Provider wrapping out of <LoggedInApp /> so we can make use of queries
const ApolloProviderApp = () => {
  const apiToken = useSelector((state) => state.session.token);
  return (
    <CoreApiProvider token={apiToken}>
      <K2ApiProvider token={apiToken}>
        <JWTRefresher>
          <LoggedInApp />
        </JWTRefresher>
      </K2ApiProvider>
    </CoreApiProvider>
  );
};

const LoggedInApp = () => {
  const dispatch = useDispatch();

  const myCenterIds = useSelector(
    (state) =>
      Object.values(state.entities.allEntities)
        .flatMap((e) => e.centers)
        .map((c) => c.id),
    shallowEqual
  );

  // TODO: Switch other places in the application to use this - we shouldn't need multiple
  // places where we fetch the getMe query. This never changes per session.
  const user = useSelector((state) => state.user);
  const ldClient = useLDClient();
  const { data: getMeData } = useGetMe();

  const { data: getBusinessConfigData } = useGetBusinessConfigurationOptionsById({
    skip: _.isEmpty(user?.entityId),
    variables: {
      id: user?.entityId,
    },
  });

  const { data: getBusinessFeatureData } = useGetBusinessFeatureOptionsById({
    skip: _.isEmpty(user?.entityId),
    variables: {
      id: user?.entityId,
      type: 'All',
    },
  });

  const { data: getBusinessData } = useGetEntity(
    {
      skip: _.isEmpty(user?.entityId),
      variables: {
        id: user.entityId ?? '',
      },
    },
    `id name dataType hubSpotCustomerId createdAt`
  );

  const business = getBusinessData?.getEntity;

  const { usMode } = useManageUSModeSetting(business?.id);

  // ON MOUNT, call the saga that is responsible for fetching entities a user has access to
  // isGlCodeMandatory flag will be set by fetchAllowedEntities, so read the flag from the store when required
  React.useEffect(() => {
    dispatch(fetchAllowedEntities());
  }, [dispatch]);

  const [ldActive, setLdActive] = useState(false);

  if (user && user.id) {
    ldClient.identify(
      {
        key: user.id,
        name: `${user.firstname} ${user.lastname}`,
        email: user.email,
        custom: {
          entityIds: user.entityId ? user.entityId : '',
          entityName: `${_.isNil(business?.name) ? `` : business?.name} ${user.entityId ? user.entityId : 'internal'}`,
          centerIds: myCenterIds && myCenterIds.length ? myCenterIds : [],
          isInternal: user.isInternal,
          country: config.locale.region,
        },
      },
      undefined,
      () => {
        setLdActive(true);
      }
    );

    const { data: commsBusinessSettings } = useGetCommsBusinessSettingsQuery({
      skip: _.isEmpty(user?.entityId),
      variables: {
        businessId: user?.entityId,
      },
      onCompleted: (data) => {
        dispatch(getCommsBusinessSettings(data.getCommsBusinessSettings));
        dispatch(
          getUsesComms(data.getCommsBusinessSettings ? data.getCommsBusinessSettings?.usesComms ?? false : false)
        );
      },
    });
  }

  const envCheck = process.env.NODE_ENV.includes('prod');
  const userLoggedInWithData = user && user?.role && business && business?.dataType;

  // recursively flatten nested subRoutes
  const flattenSubroutes = React.useCallback(
    (arr) =>
      arr.reduce((acc, currentValue) => {
        const copy = { ...currentValue };

        if (
          !copy.requiredPermission ||
          user.hasPermission(copy.requiredPermission) ||
          (copy.requiredAreaLevel && copy.requiredAreaLevel.some((areaLevel) => user.hasAreaPermissionLevel(areaLevel)))
        ) {
          acc = acc.concat(copy);

          if (copy.subRoutes) {
            acc = acc.concat(flattenSubroutes(Object.values(copy.subRoutes)));
            copy.subRoutes = [];
          }

          // handle groupings (ex: Settings screen)
          if (copy.groupings) {
            Object.values(copy.groupings).forEach((group) => {
              if (group.subRoutes) {
                acc = acc.concat(flattenSubroutes(Object.values(group.subRoutes)));
              }
            });
          }
        }

        return acc;
      }, []),
    [user]
  );

  const flatRoutes = flattenSubroutes(Object.values(routes));
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <SentryErrorBoundary
      showDialog
      dialogOptions={{
        title: "It looks like we're having an issue.",
        subtitle: 'Our team has been notified.',
        subtitle2: 'Would you like to send us a crash report, to help?',
        labelClose: 'No, thanks',
        labelSubmit: 'Send Report',
      }}
      fallback={({ resetError }) => <K2ErrorBoundaryAndLink onLinkClick={resetError} />}
    >
      <PermissionContext.Provider value={user}>
        <K2Navigation>
          {user.employmentStatus === 'Pending' && (
            <Alert variant="info">Stay tuned for more access, once you reach your start date.</Alert>
          )}
          {user.employmentStatus === 'Deactivated' && (
            <Alert variant="info">
              Your account has been deactivated. You no longer have access to features. Please contact your{' '}
              {fieldLabels.center.toLowerCase()} if you need any historical information.
            </Alert>
          )}

          <main className="app-page-content" id="k2-front-end-main-page-content">
            <>
              {/* explicit false equality check since isEmailVerified will be undefined for internal users */}
              {getMeData?.getMe && getMeData.getMe.isEmailVerified === false && <VerifyEmailAlert />}
              {/* Having our Toast component here at the top level allows us to call `showToast` from any page */}

              <Toast autoClose={5000} />
              <ConfirmationContainer />

              {getMeData?.getMe && <Freshchat user={getMeData.getMe} />}

              <ScrollToTop />
              <Switch>
                {flatRoutes.map((r, i) => (
                  <Route
                    exact
                    key={i}
                    path={r.route}
                    // changed the below props from 'component' to 'render' since dispatching a redux action would cause the component to reset its local state
                    // you can also read react-router's documentation on when to use component or render: https://reacttraining.com/react-router/web/api/Route/component
                    render={(props) =>
                      r.component ? (
                        <AppInsightsContext.Provider value={reactPlugin}>
                          {' '}
                          <r.component {...props} />{' '}
                        </AppInsightsContext.Provider>
                      ) : (
                        ''
                      )
                    }
                  />
                ))}
                <Redirect from="*" to={routes.home.route} />
              </Switch>
            </>
          </main>
          {/* <div className="fab-btn-container">
            <Feedback />
            <BetaTag />
          </div> */}
        </K2Navigation>
      </PermissionContext.Provider>
    </SentryErrorBoundary>
  );
};

const App = () => {
  const { isSupportMode } = useContext(ApplicationContext);
  const user = useSelector((state) => state.user);
  const sessionStatus = useSelector((state) => state.session.status);
  const isAuthed = sessionStatus === sessionStatusEnum.READY;

  if (sessionStatus === sessionStatusEnum.LOADING) {
    return (
      <div className="d-flex h-100">
        <Spinner large className="mx-auto my-auto" />
      </div>
    );
  } else {
    return (
      <CoreApiProvider>
        {ReactGA.initialize('G-XNFBGBVF80')}
        <Router>
          <Switch>
            <Route exact path="/login" render={() => <Login isAuthed={isAuthed} />} />
            <Route path="/recover-password" render={() => <RecoverPassword isAuthed={isAuthed} />} />
            <Route path="/apps/downloadKTConnect" render={() => <DownloadKTConnect />} />
            <Route path="/join" render={() => <Join isAuthed={isAuthed} />} />
            <Route path="/magic/change-email" render={() => <MagicLinkChangeEmail />} />
            <Route path="/magic/verify-email" render={() => <MagicLinkVerifyEmail />} />
            {isAuthed ? <Route component={ApolloProviderApp} /> : <Redirect to="/login" />}
          </Switch>
        </Router>
        {isSupportMode && <ApplicationContextStatusIndicator />}
      </CoreApiProvider>
    );
  }
};

export default App;
