import React from 'react';
import { Row, Col } from 'shared/components/Layout';
import { Form, FormLabel, InputGroup } from 'react-bootstrap';
import Select from 'shared/components/Select';
import HelpTooltip from 'shared/components/Tooltip/HelpTooltip';

export type MappingOptionType = { label: string; value: string };

interface IProps<t extends MappingOptionType, k extends keyof any> {
  id: k;
  sourceName: string;
  sourceHint?: string;
  options?: ReadonlyArray<t>;
  onChange?: (
    key: k,
    value: { value: ReadonlyArray<t>[number]['value']; label: ReadonlyArray<t>[number]['label'] },
    name: ReadonlyArray<t>[number]['label']
  ) => void;
  defaultValue?: t;
}

const MappingListItemInput = <t extends MappingOptionType, k extends keyof any>({
  id,
  sourceName,
  sourceHint = ' ',
  options = [],
  onChange = () => {},
  defaultValue,
}: IProps<t, k>) => {
  return (
    <Row className="mb-3" noGutters>
      <Col>
        <InputGroup>
          <InputGroup.Text>
            <HelpTooltip text={sourceHint} className="" size="sm" />
          </InputGroup.Text>
          <Form.Control value={sourceName} disabled />
        </InputGroup>
      </Col>
      <Col>
        <InputGroup>
          <InputGroup.Text>=</InputGroup.Text>
          <Select
            className="mb-0"
            options={options}
            onChange={(value, name) => onChange(id, value, name) as any}
            defaultValue={defaultValue}
          />
        </InputGroup>
      </Col>
    </Row>
  );
};

export default MappingListItemInput;
