export const accountingPeriodFields = `
  id
  businessId
  startDate
  endDate
  open
  history {
    id
    businessId
    startDate
    endDate
    open
    createdAt
    createdBy
    createdByPerson {
      id
      firstname
      lastname
      nickname
      avatar {
        url
      }
    }
  }
`;

export const accountingPeriodPaginatedFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${accountingPeriodFields}
  }
`;
