import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { LineItemGroup, LineItem } from '../types/Bill';

/**
 * Componenet for rendering a LineItemGroup for the bill
 * @param param0
 * @returns
 */
export function LineItemGroupComponent({ group }: { group: LineItemGroup }) {
  return (
    <div className="line-item-group charges-section">
      <h4>
        <span>{group.name}</span>
        <span>{group.total}</span>
      </h4>
      <div className="line-item-group__line-items charges-section__charges-list">
        {group.lineItems.map((li, index) => (
          <LineItemComponent lineItem={li} key={`lineItem_${li.description}_${index}`} />
        ))}
      </div>
    </div>
  );
}

function LineItemComponent({ lineItem }: { lineItem: LineItem }) {
  const { amount, breakdown, description, internalDescription } = lineItem;
  return (
    <div className="charge">
      <Tooltip
        placement="bottom-end"
        title={
          <>
            <Typography color="inherit">{`${description}\u00A0${amount}`}</Typography>
            <em>{'Internal Description:'}</em> <b>{internalDescription}</b>
          </>
        }
      >
        <div className="charge__description">
          <span>{description}</span>
          {breakdown === null ? <span>{amount}</span> : undefined}
        </div>
      </Tooltip>
      {breakdown !== null && (
        <div className="charge__discounts" style={{ paddingLeft: '1rem' }}>
          {breakdown.map((d) => (
            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
              key={`breakdown_${d.description}_${d.amount}`}
            >
              <span>{d.description}</span>
              <span>{d.amount}</span>
            </div>
          ))}
          <div className="transaction total">
            <span>Total: </span>
            <span>{lineItem.amount}</span>
          </div>
        </div>
      )}
    </div>
  );
}
