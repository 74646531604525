import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PageWrapper from 'shared/components/PageWrapper';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetExpectedSessions } from 'gql/session/queries';
import moment from 'moment';
import { useGetClassesForCenter } from 'gql/class/queries';
import { sortBy, orderBy } from 'lodash';
import TodayAttendanceHeader from './TodayAttendanceHeader';
import TodayAttendanceTable from './Times/TodayAttendanceTable';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import { StatusFilter } from './AttendanceSearchAndFilters';
import { useCheckIn, useCheckOut } from 'gql/session/mutations';
import { showToast } from 'shared/components/Toast';
import errors from 'shared/constants/errorMessages';
import { getExpectedSessions } from './duck/actions';
import { sessionFieldsMinimal } from 'gql/session/fields';
import ClearSessionModal from './Times/ClearSessionModal';
import { useTranslation } from 'react-i18next';
import { getTranslation } from 'shared/util/region';
import { useGetAllAttendanceCentersSettingQuery } from 'generated/graphql';

import TodayAttendanceNotPermitted from './TodayAttendanceNotPermitted';

const t = getTranslation('en', 'translation');

export const statusMap: { [key: string]: number } = {
  [t('attendance.today.status.unknown')]: 1,
  [t('attendance.today.status.in')]: 2,
  [t('attendance.today.status.out')]: 3,
  [t('attendance.today.status.absent')]: 4,
};

export const getAttendanceStatus = (session: ISession, timezone: string): string => {
  if (session.absence) {
    return 'A';
  }

  if (session.timeEntries.length) {
    return session.timeEntries.every((te) => te.timeOut)
      ? t('attendance.today.status.out')
      : t('attendance.today.status.in');
  }
  if (session.dropOffTime) {
    const dropOffTime = moment(session.dropOffTime, 'HH:mm');
    const dropOffDateAndTime = moment(session.date)
      .tz(timezone)
      .set('hours', dropOffTime.hours())
      .set('minutes', dropOffTime.minutes());
    if (moment().tz(timezone).isAfter(dropOffDateAndTime)) {
      return t('attendance.today.status.unknown');
    }
  }
  return t('attendance.today.status.unknown');
};

const TodayAttendance = () => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const [sessionToBeCleared, setSessionToBeCleared] = useState<ISession | null>(null);
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'status',
    direction: 'asc',
  });
  const { data: centers, loading } = useGetAllAttendanceCentersSettingQuery({
    variables: {
      input: {
        businessId,
      },
    },
    fetchPolicy: 'network-only',
    skip: businessId === null || businessId === '',
  });

  const centersWithEnabledSettings =
    centers?.getAttendanceCentersSetting?.filter((c) => c?.usesTodayScreen)?.map((c) => c?.centerId ?? '') ?? [];

  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const sessions = useSelector((state: RootState) => state.attendance.sessions) ?? [];
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();

  const [date, setDate] = useState(moment().tz(timezone).format());
  const [classIds, setClassIds] = useState<string[] | null>(null);
  const [childIdsInLoadingState, setChildIdsInLoadingState] = useState<string[]>([]);
  const { data } = useGetClassesForCenter({ variables: { centerId: selectedCenterId }, fetchPolicy: 'cache-first' });

  // filter out non active classes
  const classOptions =
    sortBy(
      data?.getClassesForCenter.filter(
        (c) =>
          !c.archivedAt &&
          moment(c.startsAt).tz(timezone).isSameOrBefore(date) &&
          (!c.endsAt || moment(c.endsAt).tz(timezone).isSameOrAfter(date))
      ),
      'name'
    ) ?? [];

  const {
    loading: sessionsLoading,
    networkStatus,
    refetch,
  } = useGetExpectedSessions(
    {
      variables: {
        input: {
          centerId: selectedCenterId,
          startDate: moment(date).format('YYYY-MM-DD'),
          endDate: moment(date).format('YYYY-MM-DD'),
          excludeCost: true,
        },
      },
      onError: (err) => {
        dispatch(getExpectedSessions([]));
        showToast(errors.generic, 'error');
      },
    },
    sessionFieldsMinimal,
    false
  );

  useEffect(() => {
    setDate(moment().tz(timezone).format());
  }, [timezone]);

  const sortData = useCallback(
    (data: ISession[]) => {
      return orderBy(data, [sortField.field, 'firstName'], [sortField.direction, 'asc']);
    },
    [sortField]
  );

  const validSessions = useMemo(
    () =>
      sessions
        .filter((s) => {
          return !s.void && !s.accountChild?.archivedAt && classIds?.length ? classIds.includes(s.classId) : true;
        })
        .map((s) => ({
          ...s,
          status: statusMap[getAttendanceStatus(s, timezone)],
          firstName: s.child.firstname,
        })),
    [classIds, sessions, timezone]
  );

  const currentDayAttendanceByStatus: Record<AttendanceStatus, ISession[]> = {
    Expected: validSessions,
    Unknown: validSessions.filter((a) => a.timeEntries.length === 0 && !a.absence),
    Late: validSessions.filter((a) => {
      if (!a.dropOffTime) {
        return false;
      }
      const dropOffTime = moment(a.dropOffTime, 'HH:mm').tz(timezone);
      const dropOffDateAndTime = moment(a.date)
        .tz(timezone)
        .set('hours', dropOffTime.hours())
        .set('minutes', dropOffTime.minutes());
      return a.timeEntries.length === 0 && !a.absence && moment().tz(timezone).isAfter(dropOffDateAndTime);
    }),
    Absent: validSessions.filter((a) => a.absence),
    'Checked In': validSessions.filter((a) => a.timeEntries.length > 0 && a.timeEntries.some((te) => !te.timeOut)),
    'Checked Out': validSessions.filter(
      (a) => a.timeEntries.length > 0 && a.timeEntries.every((te) => te.timeIn && te.timeOut)
    ),
  };

  const [checkIn, { loading: checkInLoading }] = useCheckIn();
  const [checkOut, { loading: checkOutLoading }] = useCheckOut();

  const handleCheckIn = useCallback(
    (sessions: ISession[]) => {
      setChildIdsInLoadingState(sessions.map((s) => s.childId));
      checkIn({
        variables: {
          input: {
            checkIns: sessions.map((s) => ({
              sessionId: s.id?.includes('contract') ? undefined : s.id, // if the session id is from contract, dont send,
              contractId: s.contractId,
            })),
            date: moment(date).tz(timezone).format('YYYY-MM-DD'),
            sendNotification: true,
          },
        },
      }).then((data) => {
        if (data.data?.checkIn.successful?.length) {
          showToast(
            `${data.data?.checkIn.successful?.length > 1 ? 'Children' : 'Child'} checked in successfully`,
            'success'
          );
        }
        if (data.data?.checkIn.failed?.length) {
          const errorMessages = data.data?.checkIn.failed?.map((f) => f.reason);
          showToast(errorMessages.join(', '), 'error');
        }
        setChildIdsInLoadingState([]);
      });
    },
    [timezone, checkIn, date]
  );

  const handleCheckOut = useCallback(
    (sessions: ISession[]) => {
      setChildIdsInLoadingState(sessions.map((s) => s.childId));
      checkOut({
        variables: {
          input: {
            sessionIds: sessions.map((s) => s.id),
            sendNotification: true,
          },
        },
      }).then((data) => {
        if (data.data?.checkOut.successful?.length) {
          showToast(
            `${data.data?.checkOut.successful?.length > 1 ? 'Children' : 'Child'} checked out successfully`,
            'success'
          );
        }
        if (data.data?.checkOut.failed?.length) {
          const errorMessages = data.data?.checkOut.failed?.map((f) => f.reason);
          showToast(errorMessages.join(', '), 'error');
        }
        setChildIdsInLoadingState([]);
      });
    },
    [checkOut]
  );

  if (loading && !user?.isInternal) return <DataTableLoadingSkeleton />;
  if (centersWithEnabledSettings?.length === 0 && !user?.isInternal) {
    return <TodayAttendanceNotPermitted />;
  }

  return (
    <PageWrapper pageTitle={t('attendance.today.title')} applyPadding={true}>
      <CenterSelectBanner pageName={t('attendance.today.title')} filteredCenterIds={centersWithEnabledSettings ?? []} />
      <div>
        <TodayAttendanceHeader classIds={classIds} classOptions={classOptions} setClassIds={setClassIds} date={date} />
        <StatusFilter currentDayAttendanceByStatus={currentDayAttendanceByStatus} className="p-2" />
        {!data ||
        (sessionsLoading &&
          networkStatus !== NETWORK_STATUS.SET_VARIABLES &&
          networkStatus !== NETWORK_STATUS.REFETCH) ? (
          <DataTableLoadingSkeleton />
        ) : (
          <TodayAttendanceTable
            attendanceSessions={sortData(validSessions)}
            centerId={selectedCenterId}
            loading={sessionsLoading}
            timezone={timezone}
            date={date}
            handleCheckIn={handleCheckIn}
            handleCheckOut={handleCheckOut}
            childIdsInLoadingState={checkOutLoading || checkInLoading ? childIdsInLoadingState : []}
            onSort={(field: string, direction: 'asc' | 'desc') => setSortField({ field, direction })}
            onClearSession={setSessionToBeCleared}
          />
        )}
      </div>

      {sessionToBeCleared && (
        <ClearSessionModal
          isOpen={Boolean(sessionToBeCleared)}
          onSuccess={refetch}
          onClose={() => setSessionToBeCleared(null)}
          session={sessionToBeCleared}
        />
      )}
    </PageWrapper>
  );
};

export default TodayAttendance;
