import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { useDispatch } from 'react-redux';
import { groupBy } from 'lodash';
import { getCcsDeterminationForAccountChild } from 'pages/Families/subroutes/Accounts/duck/actions';
import { determinationFieldsWithPrevious } from './fields';

interface IGetCcsDeterminationsForChildrenVariables {
  businessId: string;
  centerId: string;
  children: string[];
}

export const GET_CCS_DETERMINATIONS_FOR_CHILDREN = gql`
  query($businessId: ID!, $centerId: ID!, $children:[ID!]!) {
    getCcssDeterminationsForChildren(businessId: $businessId, centerId: $centerId, children: $children) {
      ${determinationFieldsWithPrevious}
    }
  }
`;

export const useGetCcsDeterminationsForChildren = (
  businessId: string,
  centerId: string,
  accountId: string,
  children: string[]
) => {
  const dispatch = useDispatch();

  return useQuery<
    { getCcssDeterminationsForChildren: ICcssDetermination[] },
    IGetCcsDeterminationsForChildrenVariables
  >(GET_CCS_DETERMINATIONS_FOR_CHILDREN, {
    variables: {
      businessId: businessId,
      centerId: centerId,
      children: children,
    },
    fetchPolicy: 'cache-and-network',
    skip: businessId === '',
    onCompleted: (data) => {
      const childDeterminations = groupBy(data.getCcssDeterminationsForChildren, (e) => e.childId);

      for (let childId in childDeterminations) {
        const determinations = childDeterminations[childId];
        dispatch(getCcsDeterminationForAccountChild(accountId, childId, determinations));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
