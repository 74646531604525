import React, { useCallback } from 'react';
import surchargesBanner from './Surcharges-Banner.svg';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import Button from 'shared/components/Buttons';
import Form from 'react-bootstrap/Form';
import BillingCycleSentence from './BillingCycleSentence';
import PeriodBasedBillingCycleSentence from './PeriodBasedBillingCycleSentence';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';

interface IProps {
  billingCycle: INewBillingCycleTemplateFormData;
  templateType: BillingCycleType;
  onPreview: () => void;
  updateBillingCycle: (billingCycle: INewBillingCycleTemplateFormData) => void;
}

const CreateBillingCycleCard: React.FC<IProps> = ({ billingCycle, templateType, onPreview, updateBillingCycle }) => {
  const { t } = useTranslation();

  const handleUpdate = useCallback(
    (updates: Partial<INewBillingCycleTemplateFormData>) => {
      updateBillingCycle({
        ...billingCycle,
        ...updates,
      });
    },
    [billingCycle, updateBillingCycle]
  );

  const handleClickPreview = useCallback(
    (e) => {
      e.preventDefault();
      onPreview();
    },
    [onPreview]
  );

  const isMonthly = billingCycle.frequency?.includes('MONTH') || billingCycle.frequency?.includes('QUARTER');
  const hasFrequencyPayPeriod =
    billingCycle.period?.includes('CURRENT') &&
    (billingCycle.frequency?.includes('BIWEEKLY') || billingCycle.frequency?.includes('QUARTERLY'));

  const checkForFrequencyPayPeriod = hasFrequencyPayPeriod ? billingCycle.frequencyPayPeriod : true;

  const payForBalanceFormDisabled =
    !billingCycle.cycleType ||
    !billingCycle.frequency ||
    (isMonthly ? isNaN(billingCycle.dayOfMonth) : !billingCycle.dayOfWeek) ||
    isNaN(billingCycle.invoiceSchedule?.value ?? NaN) ||
    !billingCycle.invoiceSchedule?.unit ||
    !billingCycle.period ||
    isNaN(billingCycle.additionalChargePeriod?.value ?? NaN) ||
    !billingCycle.additionalChargePeriod?.unit;

  const payBillingCycleFormDisabled =
    !billingCycle.cycleType ||
    !billingCycle.frequency ||
    (isMonthly ? isNaN(billingCycle.dayOfMonth) : !billingCycle.dayOfWeek) ||
    isNaN(billingCycle.invoiceSchedule?.value ?? NaN) ||
    !billingCycle.invoiceSchedule?.unit ||
    !billingCycle.period ||
    !checkForFrequencyPayPeriod;

  return (
    <Card bodyClassName="p-8">
      <Form onSubmit={handleClickPreview}>
        <Row className="flex-nowrap">
          <span className="d-none d-lg-inline align-self-end">
            <img
              src={surchargesBanner}
              alt="cartoon helper man"
              className="mt-n2 mb-n8 mr-4"
              style={{ width: '275px' }}
            />
          </span>
          <Col>
            {templateType === 'BALANCE_BASED' && (
              <div>
                <h5 className="mb-6">{t('billing.settings.cycles.createCycle')}</h5>
                <BillingCycleSentence cycle={billingCycle} onUpdate={handleUpdate} />
                <div className="d-flex">
                  <Button className="ml-auto" disabled={payForBalanceFormDisabled} type="submit">
                    {capitalize(t('spelling.preview'))}
                  </Button>
                </div>
              </div>
            )}
            {templateType === 'PERIOD_BASED' && (
              <div>
                <h5 className="mb-6">{t('billing.settings.cycles.createCycle')}</h5>
                <PeriodBasedBillingCycleSentence cycle={billingCycle} onUpdate={handleUpdate} />
                <div className="d-flex">
                  <Button className="ml-auto" disabled={payBillingCycleFormDisabled} type="submit">
                    {capitalize(t('spelling.save'))}
                  </Button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CreateBillingCycleCard;
