import React from 'react';
import { useSelector } from 'react-redux';
import { debounce, isEmpty } from 'lodash';

import * as Mui from '@mui/material';
import { useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faIslandTropical, faPlus, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';

import AddPayCodeModal from './components/AddPayCodeModal';
import { RootState } from 'store/reducers';
import { useSearchPayCodes } from 'gql/payCode/queries';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import PayCodeTableRow from './components/PayCodeTableRow';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { IconButtonCircle } from 'shared/components/Buttons';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { defaultPayCodeFields } from './graphQL/defaultFields';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';

const styles = {
  iconButton: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
  inputStyles: {
    minWidth: '200px',
    '& legend': {
      width: 0,
    },
  },
};

const columns = [
  { name: 'Name', field: 'name', displayLabel: true, alignLeft: true, sortable: true },
  { name: 'Purpose', field: 'trainingTimeEventType', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'Code', field: 'code', displayLabel: true, alignLeft: true, sortable: true },
  { name: 'Center(s)', field: 'noteType', displayLabel: true, alignLeft: true, sortable: false },
  { name: 'Row Actions', field: 'id', displayLabel: false, alignLeft: false, sortable: false },
];

interface ISortBy {
  field: string;
  direction: SortDirection;
}

type SortDirection = 'ASCENDING' | 'DESCENDING';

const PayCodesTab: React.FC = () => {
  const theme = useTheme();
  const isMobile = Mui.useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const ALL_CENTERS_OPTIONS = { value: null, label: `All ${capitalize(t('spelling.center'))}s` };
  const centerSelectOptions =
    useGetActiveCenters()
      .map((center) => ({ label: center.name, value: center.id }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [sort, setSort] = React.useState<ISortBy>({
    field: 'CreatedAt',
    direction: 'DESCENDING',
  });
  const [searchInput, setSearchInput] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [size, setSize] = React.useState<number>(25);
  const [selectedCenters, setSelectedCenters] = React.useState<{ value: null | string; label: string } | null>(null);
  const [page, setPage] = React.useState(0);
  const [from, setFrom] = React.useState(1);

  const hasEditOperationsPermission = useHasRoleAreaLevel({
    area: AreaType.Operations,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const { data, loading, refetch } = useSearchPayCodes(
    {
      skip: isEmpty(businessId),
      variables: {
        input: {
          businessId: businessId,
          sortDirection: sort.direction,
          sortField: sort.field,
          size: size,
          from: from,
          nameCodeContains: searchQuery,
          centerId: selectedCenters?.value,
          includeBusinessTypes: true,
        },
      },
    },
    defaultPayCodeFields
  );

  React.useEffect(() => {
    updateSearchValue(searchInput);
  }, [searchInput]);

  const updateSearchValue = React.useCallback(
    debounce((input: string) => {
      setSearchQuery(input);
    }, 500),
    []
  );

  const changeSort = (field: string) => {
    setSort({
      field: field,
      direction: sort.direction === 'ASCENDING' ? 'DESCENDING' : 'ASCENDING',
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setFrom(newPage + 1);
  };

  return (
    <PageWrapperBody>
      {user?.isInternal && <CenterSelectBanner pageName="operations" showCenterSelect={false} />}
      <br />
      <Mui.Card elevation={0} variant="outlined">
        <Mui.Box sx={{ padding: '16px' }}>
          <Mui.Stack
            justifyContent={'space-between'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            spacing={2}
            direction={isMobile ? 'column' : 'row'}
          >
            <Mui.Stack
              alignItems={isMobile ? 'flex-start' : 'center'}
              spacing={2}
              direction={isMobile ? 'column' : 'row'}
            >
              <Mui.Typography>Show</Mui.Typography>
              <Mui.Select
                sx={{
                  minWidth: '50px',
                  '& legend': {
                    width: 0,
                  },
                }}
                inputProps={{
                  'aria-label': 'change the amount of items to show',
                }}
                value={size.toString()}
                onChange={(evt) => setSize(parseInt(evt.target.value))}
                size={'small'}
              >
                <Mui.MenuItem value={'10'}>10</Mui.MenuItem>
                <Mui.MenuItem value={'25'}>25</Mui.MenuItem>
                <Mui.MenuItem value={'50'}>50</Mui.MenuItem>
              </Mui.Select>
              <Mui.TextField
                variant="outlined"
                value={searchInput}
                onChange={(evt) => setSearchInput(evt.target.value)}
                InputProps={{
                  startAdornment: (
                    <Mui.InputAdornment position="start">
                      <FontAwesomeIcon size="xs" icon={faSearch} />
                    </Mui.InputAdornment>
                  ),
                }}
                inputProps={{
                  'aria-label': 'Search PTO Types',
                }}
                placeholder="Search"
                size="small"
                sx={{
                  minWidth: '200px',
                  '& legend': {
                    width: 0,
                  },
                }}
              />
            </Mui.Stack>
            <Mui.Stack
              alignItems={isMobile ? 'flex-start' : 'center'}
              spacing={2}
              direction={isMobile ? 'column' : 'row'}
            >
              <Mui.Autocomplete
                id="center-select"
                options={[ALL_CENTERS_OPTIONS, ...centerSelectOptions]}
                getOptionLabel={(option) => option.label}
                onChange={(evt, value) => setSelectedCenters(value)}
                renderInput={(params) => <Mui.TextField {...params} sx={styles.inputStyles} placeholder="Center" />}
                size="small"
                value={selectedCenters}
                sx={{ paddingRight: '16px' }}
              />
              <IconButtonCircle
                icon={faTimes}
                onClick={() => {
                  setSearchInput('');
                  setSelectedCenters(null);
                }}
                tooltipDirection="bottom"
                tooltipText="Clear Filters"
                className="mx-4 my-2"
              />
            </Mui.Stack>
          </Mui.Stack>
        </Mui.Box>
      </Mui.Card>
      {hasEditOperationsPermission && (
        <Mui.Box display="flex" justifyContent="flex-end" marginTop="16px">
          <Mui.Button
            color="secondary"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setOpenAddModal(true)}
          >
            Add Pay Code
          </Mui.Button>
        </Mui.Box>
      )}
      <SpinnerOverlay show={loading}>
        {(data?.searchPtoType.totalRecords ?? 0) > 0 ? (
          <Mui.Box sx={{ width: '100%' }}>
            <Mui.Paper sx={{ mb: 2 }} elevation={0}>
              <Mui.TableContainer>
                <Mui.Table aria-labelledby="account-notes-table" style={{ width: '100%' }}>
                  <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
                    {columns.map((column) => (
                      <Mui.TableCell key={column.name} align={column.alignLeft ? 'left' : 'center'} padding={'normal'}>
                        <Mui.Typography variant="tableHeadCells">
                          {column.displayLabel && column.name}
                          {column.sortable && (
                            <Mui.IconButton
                              sx={styles.iconButton}
                              disableRipple
                              onClick={() => changeSort(column.field)}
                            >
                              <FontAwesomeIcon
                                size="xs"
                                icon={
                                  sort.field === column.field
                                    ? sort.direction === 'ASCENDING'
                                      ? faAngleDown
                                      : faAngleUp
                                    : faAngleDown
                                }
                              />
                            </Mui.IconButton>
                          )}
                        </Mui.Typography>
                      </Mui.TableCell>
                    ))}
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {data?.searchPtoType.data.map((row, index) => (
                      <PayCodeTableRow key={row.id} row={row} refetch={refetch} />
                    ))}
                  </Mui.TableBody>
                </Mui.Table>
              </Mui.TableContainer>
              <Mui.TablePagination
                sx={{
                  '.MuiTablePagination-displayedRows': {
                    margin: 0,
                    padding: 0,
                  },
                }}
                rowsPerPageOptions={[size]}
                component="div"
                count={data?.searchPtoType.totalRecords ?? 0}
                rowsPerPage={data?.searchPtoType.pageSize ?? 0}
                page={page}
                onPageChange={handleChangePage}
              />
            </Mui.Paper>
          </Mui.Box>
        ) : (
          <Mui.Stack spacing={2} direction="column" alignItems="center" justifyContent="center" marginTop="32px">
            <FontAwesomeIcon icon={faIslandTropical} size="5x" />
            <Mui.Typography>There are no PTO Types available to display.</Mui.Typography>
          </Mui.Stack>
        )}
      </SpinnerOverlay>
      <AddPayCodeModal open={openAddModal} onClose={() => setOpenAddModal(false)} refetch={refetch} />
    </PageWrapperBody>
  );
};

export default PayCodesTab;
