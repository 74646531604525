import React, { useState } from 'react';
import Card from 'shared/components/Card';
import classesBanner from './images/Classes-Banner.svg';
import './_classHelper.scss';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import useWindowSize from 'shared/hooks/useWindowSize';
import { TinyDeleteButton } from 'shared/components/Buttons';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { Row, Col } from 'shared/components/Layout';
const ClassHelperInformation: React.FC = () => {
  const [index, setIndex] = useState(0);
  const [showCard, setShowCard] = useState(true);
  const windowSize = useWindowSize();
  const isMobile = windowSize.innerWidth <= 768;
  const user = useSelector((state: RootState) => state.user);
  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  if (showCard) {
    return (
      <Card bodyClassName="py-4 class-helper">
        <Row>
          <TinyDeleteButton
            className="ml-auto mr-4 d-flex"
            onClick={() => {
              setShowCard(false);
              localStorage.setItem(`${user?.email}-class-helper-closed`, 'true');
            }}
          />
        </Row>
        <Row className="align-items-stretch">
          <Col className="d-none d-md-block">
            <img id="classesBanner" src={classesBanner} alt="tag-example" className="tag-example ml-auto w-100" />
          </Col>
          <Col>
            <Row className="d-flex flex-row">
              <div className="carouselBox">
                <Carousel
                  activeIndex={index}
                  onSelect={handleSelect}
                  slide={false}
                  nextIcon={<FontAwesomeIcon icon={faAngleRight} size="2x" />}
                  prevIcon={<FontAwesomeIcon icon={faAngleLeft} size="2x" />}
                  interval={null}
                >
                  <Carousel.Item>
                    <div className="px-8 pb-4 d-flex">
                      <div className="mb-2 rounded py-2 px-4 helper-text">
                        <p className="grayHeader pb-2">Classes</p>
                        <h5 className="text-muted">
                          <b>Let's look at what a Class is…</b>
                        </h5>
                        <ul className="ml-n6 pt-3">
                          <li>
                            <p>Is a group of children who are assigned to a teacher.</p>
                          </li>
                          <li>
                            <p>
                              Is defined by age (regulations) to determine how young or old a child can be, to be
                              enrolled.
                            </p>
                          </li>
                          <li>
                            <p>Belongs to a "homeroom"; the Space where this group spends most of their time.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="px-8 pb-4 d-flex">
                      <div className="mb-2 rounded py-2 px-4 helper-text">
                        <p className="grayHeader pb-2">Getting Setup</p>
                        <h5 className="text-muted">
                          <b>Let's get you setup...</b>
                        </h5>
                        <ul className="ml-n6 pt-3">
                          <li>
                            <p>Name - What do you call this Class?</p>
                          </li>
                          <li>
                            <p>
                              Space - What location in your {fieldLabels.center.toLowerCase()} does this Class belong
                              to?
                            </p>
                          </li>
                          <li>
                            <p>
                              Start/End Date - Does this Class have ongoing {fieldLabels.enrollment}? Yes, mark it as
                              "ongoing". Does this Class only {fieldLabels.enroll.toLowerCase()} for the school year,
                              summer, or specific range of dates? Set BOTH a start and end date.
                            </p>
                          </li>
                          <li>
                            <p>
                              {fieldLabels.color} - What {fieldLabels.color.toLowerCase()} do you want to track this
                              Class by throughout Kangarootime?
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="px-8 pb-4 d-flex">
                      <div className="mb-2 rounded py-2 px-4 helper-text">
                        <p className="grayHeader pb-2">Regulations</p>
                        <h5 className="text-muted">
                          <b>We've made Regulations easy for you…</b>
                        </h5>
                        <ul className="ml-n6 pt-3">
                          <li>
                            <p>Select the age range this Class {fieldLabels.enroll.toLowerCase()}s for.</p>
                          </li>
                          <li>
                            <p>Select override, if you wish to change the age, ratio or capacity provided.</p>
                          </li>
                          <li>
                            <p>
                              If you select override, you can choose to extend the youngest and oldest ages you{' '}
                              {fieldLabels.enroll.toLowerCase()} for. (Example: Typical Start Age is 3 Years, but you
                              can {fieldLabels.enroll.toLowerCase()} for the Youngest Start Age of 33 Months).
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  } else {
    return <div></div>;
  }
};
export default ClassHelperInformation;
