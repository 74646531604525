import React from 'react';
import Select from 'shared/components/Select';
import SearchInput from 'shared/components/SearchInput';

interface IProps {
  filter: any;
  updateFilter: React.Dispatch<React.SetStateAction<any>>;
  hideSearch?: boolean;
}

const SearchAndFilterBar: React.FC<IProps> = ({ filter, updateFilter, hideSearch = false }) => (
  <div className="bg-white rounded p-2 mb-2 box-shadow-lg d-flex align-items-center">
    {!hideSearch && (
      <SearchInput
        className="max-width-200"
        onChange={(val: string) => updateFilter((prev: any) => ({ ...prev, search: val }))}
        placeholder="Search"
      />
    )}
    <div className="d-flex ml-auto">
      <Select
        className="mb-0 mr-4 time-off-status-filter"
        options={['All', 'Approved', 'Pending', 'Denied']}
        onChange={(val: string) => updateFilter((prev: any) => ({ ...prev, status: val }))}
        placeholder="All"
        value={filter.status}
      />
    </div>
  </div>
);

export default SearchAndFilterBar;
