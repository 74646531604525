import React, { useState } from 'react';
import PersonalInformationForm from './PersonalInformationForm';
import Card from 'shared/components/Card';
import { Row, Col } from 'shared/components/Layout';
import TagsForm from './TagsForm';
import { ChangeEmailCard } from 'shared/components/ChangeEmail';
import AvatarUpload from 'shared/components/AvatarUpload';
import useUpdateAvatar from 'shared/hooks/useUpdateAvatar';
import { PersonAvatar } from 'shared/components/Avatar';
import ChangeContactEmailModal from './ChangeContactEmailModal';
import cast from 'shared/util/cast';
import { getFullName } from 'shared/util/string';
import ChangePinCard from 'shared/components/ChangePin/ChangePinCard';
import ChangePinModal from 'shared/components/ChangePin/ChangePinModal';
import { useUpdatePinForPerson } from 'gql/person/mutations';
import { showToast } from 'shared/components/Toast';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {
  contact: IContact | undefined;
  loading: boolean;
  readOnly: boolean;
}

const ProfileTab: React.FC<IProps> = ({ contact, loading, readOnly }) => {
  const hasEditContactTagsPermission = useHasRoleAreaLevel({
    area: AreaType.Contact,
    permission: PermissionType.Tags,
    level: RoleLevelType.Edit,
  });
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showChangePinModal, setShowChangePinModal] = useState(false);
  const [updateAvatarFn, { loading: avatarLoading }] = useUpdateAvatar('contact');
  const [updatePinForPersonFn, { loading: updatePinForPersonLoading }] = useUpdatePinForPerson({
    onCompleted: (result) => {
      showToast('Pin changed successfully.', 'success');
      setShowChangePinModal(false);
    },
    onError: (err) => {
      showToast(`There was an error changing this contact's pin. Please try again later.`, 'error');
    },
  });

  return (
    <div>
      <Row align="start">
        <Col lg={6}>
          <Card bodyClassName="p-4" loading={loading} loadingLines={2}>
            {contact && (
              <Row noGutters>
                {readOnly ? (
                  <PersonAvatar person={cast<IPerson>(contact)} size="lg" className="mr-4" />
                ) : (
                  <AvatarUpload
                    loading={avatarLoading}
                    image={contact?.avatar?.url}
                    onFileSelect={(file: File) => {
                      contact && updateAvatarFn({ id: contact.id, avatar: file });
                    }}
                    placeholder="Upload"
                    className="mr-4"
                  />
                )}
                <div>
                  <h5>{getFullName(contact)}</h5>
                </div>
              </Row>
            )}
          </Card>
          <Card header="Personal Information" loading={loading} loadingLines={7}>
            {contact && <PersonalInformationForm contact={contact} readOnly={readOnly} />}
          </Card>
        </Col>
        <Col lg={6}>
          <HasRoleAreaLevel
            action={{ area: AreaType.Contact, permission: PermissionType.Tags, level: RoleLevelType.Read }}
          >
            <Card header="Contact Tags" loading={loading} loadingLines={3}>
              {contact && <TagsForm contact={contact} readOnly={!hasEditContactTagsPermission} />}
            </Card>
          </HasRoleAreaLevel>
          <Row align="stretch">
            <Col md={6}>
              <ChangeEmailCard disabled={readOnly} showChangeEmailModal={() => setShowChangeEmailModal(true)} />
            </Col>
            <Col md={6}>
              <HasRoleAreaLevel
                action={{ area: AreaType.Contact, permission: PermissionType.Base, level: RoleLevelType.Edit }}
              >
                <ChangePinCard
                  showChangePinModal={() => setShowChangePinModal(true)}
                  text={`Need to change this user's PIN?`}
                />
              </HasRoleAreaLevel>
            </Col>
          </Row>
        </Col>
      </Row>
      {contact && (
        <ChangeContactEmailModal
          currentEmail={contact.email ?? ''}
          isOpen={showChangeEmailModal}
          onClose={() => setShowChangeEmailModal(false)}
          personId={contact.id}
          isCognitoUserCreated={Boolean(contact.cognitoConfiguredAt)}
        />
      )}
      <ChangePinModal
        isOpen={showChangePinModal}
        isLoading={updatePinForPersonLoading}
        onSubmit={(pin) => updatePinForPersonFn({ variables: { personId: contact?.id ?? '', pin } })}
        onClose={() => setShowChangePinModal(false)}
      />
    </div>
  );
};

export default ProfileTab;
