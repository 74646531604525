import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import SideModalDrawer from 'shared/components/ModalDrawer';
import errorMessage from 'shared/constants/errorMessages';
import Select from 'shared/components/Select';
import DateInput from 'shared/components/DateInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import { useApproveStaff } from './graphql/mutations';
import { showToast } from 'shared/components/Toast';
import cast from 'shared/util/cast';
import { useGetPositionsForBusiness } from '../Profiles/graphql/queries';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput';
import { sortBy } from 'lodash';
import { setRequestedEmployees } from 'store/employees/actions';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useGetRolesForBusinessThatUserCanManage } from 'shared/hooks/useGetRolesForBusiness';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  staff: IStaff;
}

interface IFormStateShape extends IStaff {
  position: {
    positionId: string | null;
    payRate: string;
    payRateType: 'Hourly' | 'Salary';
  };
}

const ApproveStaffModal: React.FC<IProps> = ({ isOpen, onClose, staff: requestedStaff, ...props }) => {
  const dispatch = useDispatch();
  const requestedStaffList = useSelector((state: RootState) => state.employees.requested);
  const scopeType = requestedStaff.primaryCenterId ? 'CENTER' : 'ENTITY';
  const user = useSelector((state: RootState) => state.user);
  let { data: roleOptions } = useGetRolesForBusinessThatUserCanManage(requestedStaff.entityId, user);
  roleOptions = roleOptions.filter((i: IRole) => i.scopeType == scopeType);
  const { data: positionsForBusiness, loading: loadingPositionsForBusiness } = useGetPositionsForBusiness(
    requestedStaff.entityId
  );

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const [staff, updateStaff] = useState<IFormStateShape>({
    ...requestedStaff,
    position: {
      positionId: null,
      payRate: '',
      payRateType: 'Hourly',
    },
  });
  const [roleId, updateRoleId] = useState<string | null>(null);
  const [approveStaff, { loading }] = useApproveStaff();
  const approve = useCallback(async () => {
    await approveStaff({
      variables: {
        input: {
          staff: {
            id: staff.id,
            firstname: staff.firstname,
            lastname: staff.lastname,
            roleship: {
              roleId: cast<string>(roleId),
              scopeType: requestedStaff.primaryCenterId ? 'CENTER' : 'ENTITY',
              scopeIds: requestedStaff.primaryCenterId ? [requestedStaff.primaryCenterId] : [requestedStaff.entityId],
            },
            employmentStartDate: staff.employmentStartDate,
            position: {
              staffId: staff.id,
              positionId: staff.position.positionId ?? '',
              scopeType: requestedStaff.primaryCenterId ? 'CENTER' : 'ENTITY',
              scopeId: requestedStaff.primaryCenterId ? requestedStaff.primaryCenterId : requestedStaff.entityId,
              businessId: staff.entityId,
              payRate:
                staff.position.payRate !== '' ? Number.parseFloat(staff.position.payRate.replace(/,/, '')) : null,
              type: staff.position.payRate !== '' ? staff.position.payRateType : null,
            },
          },
          sendInvitation: true,
        },
      },
    }).then(
      (result) => {
        const data = requestedStaffList?.filter((i: IStaff) => i.id !== result?.data?.approveStaff.id);
        dispatch(setRequestedEmployees(data ?? []));
        showToast('Staff Approved and Invited', 'success');
        onClose();
      },
      () => {
        showToast(errorMessage.generic, 'error');
        onClose();
      }
    );
  }, [
    approveStaff,
    staff.id,
    staff.firstname,
    staff.lastname,
    staff.employmentStartDate,
    staff.position.positionId,
    staff.position.payRate,
    staff.position.payRateType,
    staff.entityId,
    roleId,
    requestedStaff.primaryCenterId,
    requestedStaff.entityId,
    requestedStaffList,
    dispatch,
    onClose,
  ]);

  const formInvalid =
    !staff.firstname ||
    !staff.lastname ||
    !staff.email ||
    !roleId ||
    (scopeType === 'CENTER' && !staff.primaryCenter) ||
    !staff.position.positionId;

  return (
    <SideModalDrawer
      title="Approve Request"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Approve"
      closeOnPrimaryCallback={false}
      primaryCallback={approve}
      primaryButtonProps={{ disabled: formInvalid, loading }}
      footerHelperText={formInvalid ? errorMessage.formRequirements : ''}
    >
      <form>
        <Row>
          <Col>
            <TextInput
              required
              label="First Name"
              value={staff.firstname}
              onChange={(firstname) => updateStaff({ ...staff, firstname })}
            />
          </Col>
          <Col>
            <TextInput
              required
              label="Last Name"
              value={staff.lastname}
              onChange={(lastname) => updateStaff({ ...staff, lastname })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              options={roleOptions.map((r) => ({ value: r.id, label: r.name }))}
              label="Role"
              value={staff.roleship?.roleId}
              onChange={(option) => updateRoleId(option.value)}
              required={true}
            />
          </Col>
          <Col>
            <DateInput
              label="Start Date"
              date={staff.employmentStartDate}
              onDateSelect={(employmentStartDate) => updateStaff({ ...staff, employmentStartDate })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              required
              id="position-dropdown-input"
              label="Position"
              helpTooltipText="Title or scope of responsibilities."
              options={sortBy(positionsForBusiness?.getPositionsByBusinessId ?? [], (p) => p.name)}
              isLoading={loadingPositionsForBusiness}
              onChange={(option: IPosition) =>
                updateStaff((prev) => ({
                  ...prev,
                  position: {
                    ...prev.position,
                    positionId: option.id,
                  },
                }))
              }
              getOptionLabel={(option: IPosition) => option.name}
              getOptionValue={(option: IPosition) => option.id}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Pay Rate</Form.Label>
              <InputGroup>
                <CurrencyInput
                  className="border-radius-0"
                  onChange={(value) =>
                    updateStaff((prev) => ({
                      ...prev,
                      position: {
                        ...prev.position,
                        payRate: value,
                      },
                    }))
                  }
                  value={staff.position.payRate}
                  step="0.01"
                  placeholder="0.00"
                  min="0"
                  appendNode={
                    <DropdownButton
                      alignRight
                      id="pay-rate-type-dropdown"
                      variant="outline-secondary"
                      as={InputGroup.Append}
                      title={
                        <span className="pl-1">
                          {`${staff.position.payRateType}`} <FontAwesomeIcon transform={'right-5'} icon={faAngleDown} />
                        </span>
                      }
                    >
                      <DropdownItem
                        onClick={() =>
                          updateStaff((prev) => ({
                            ...prev,
                            position: {
                              ...prev.position,
                              payRateType: 'Hourly',
                            },
                          }))
                        }
                      >
                        Hourly
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          updateStaff((prev) => ({
                            ...prev,
                            position: {
                              ...prev.position,
                              payRateType: 'Salary',
                            },
                          }))
                        }
                      >
                        Salary
                      </DropdownItem>
                    </DropdownButton>
                  }
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        {scopeType === 'CENTER' && (
          <Select
            options={[staff.primaryCenter]}
            label={`Primary ${fieldLabels.center} Assignment`}
            value={staff.primaryCenter}
            onChange={(primaryCenter) => {}}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            disabled
          />
        )}
        <TextInput disabled label="Email" value={staff.email} onChange={(email) => updateStaff({ ...staff, email })} />
      </form>
    </SideModalDrawer>
  );
};

export default ApproveStaffModal;
