import * as type from './types';

export const getChildrenSuccess = (children: IChild[]) => ({ type: type.GET_CHILDREN_SUCCESS, children });
export const getChildByIdSuccess = (child: IChild) => ({ type: type.GET_CHILD_BY_ID_SUCCESS, child });
export const addChildSuccess = (child: IChild) => ({ type: type.ADD_CHILD_SUCCESS, child });
export const updateChildSuccess = (child: IChild) => ({ type: type.UPDATE_CHILD_SUCCESS, child });

//CONTACTS
export const addContactForChild = (contact: IChildContact): type.ChildActionTypes => ({
  type: type.ADD_CONTACT_FOR_CHILD,
  contact,
});
export const updateContactForChild = (contact: IChildContact): type.ChildActionTypes => ({
  type: type.UPDATE_CONTACT_FOR_CHILD,
  contact,
});
export const removeChildContactRelationship = (childId: string, contactId: string): type.ChildActionTypes => ({
  type: type.REMOVE_CHILD_CONTACT_RELATIONSHIP,
  childId,
  contactId,
});

//WELLNESS
export const getChildWellnessDataSuccess = (child: IChild) => ({ type: type.GET_CHILD_WELLNESS_DATA_SUCCESS, child });
export const getChildImmunizationDataSuccess = (child: IChild) => ({
  type: type.GET_CHILD_IMMUNIZATION_DATA_SUCCESS,
  child,
});

export const createMedicalConditionForChild = (condition: IMedicalCondition): type.ChildActionTypes => ({
  type: type.CREATE_MEDICAL_CONDITION_FOR_CHILD,
  condition,
});

export const updateMedicalConditionForChild = (condition: IMedicalCondition): type.ChildActionTypes => ({
  type: type.UPDATE_MEDICAL_CONDITION_FOR_CHILD,
  condition,
});

export const createAllergyForChild = (allergy: IAllergy): type.ChildActionTypes => ({
  type: type.CREATE_ALLERGY_FOR_CHILD,
  allergy,
});

export const updateAllergyForChild = (allergy: IAllergy): type.ChildActionTypes => ({
  type: type.UPDATE_ALLERGY_FOR_CHILD,
  allergy,
});

export const createChildRestrictionSuccess = (childId: string, restriction: IRestriction): type.ChildActionTypes => ({
  type: type.CREATE_CHILD_RESTRICTION,
  childId,
  restriction,
});

export const updateChildRestrictionSuccess = (childId: string, restriction: IRestriction): type.ChildActionTypes => ({
  type: type.UPDATE_CHILD_RESTRICTION,
  childId,
  restriction,
});

export const updateChildDocuments = (childId: string, documents: IWellnessDocument[]): type.ChildActionTypes => ({
  type: type.UPDATE_CHILD_DOCUMENTS,
  childId,
  documents,
});

export const deleteChildDocuments = (childId: string, documentIds: string[]): type.ChildActionTypes => ({
  type: type.DELETE_CHILD_DOCUMENTS,
  childId,
  documentIds,
});

export const createImmunizationForChild = (immunizations: IImmunization[]): type.ChildActionTypes => ({
  type: type.CREATE_CHILD_IMMUNIZATION,
  immunizations,
});

export const updateImmunizationForChild = (immunization: IImmunization): type.ChildActionTypes => ({
  type: type.UPDATE_IMMUNIZATION,
  immunization,
});

export const deleteChildImmunization = (immunization: IImmunization): type.ChildActionTypes => ({
  type: type.DELETE_CHILD_IMMUNIZATION,
  immunization,
});

export const updateContactChildRelationship = (
  childId: string,
  contactId: string,
  relationship: string
): type.ChildActionTypes => ({
  type: type.UPDATE_CONTACT_CHILD_RELATIONSHIP,
  childId,
  contactId,
  relationship,
});
