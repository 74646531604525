import React from 'react';
import Masonry from 'react-masonry-css';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import * as Mui from '@mui/material';
import Button, { CirclePlusButton } from 'shared/components/Buttons';
import PageWrapper from 'shared/components/PageWrapper';
import Select from 'shared/components/Select';

import { RootState } from 'store/reducers';

import './_photos.scss';
import AddPhotoModal from './components/AddPhotoModal';
import { showToast } from 'shared/components/Toast';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro, faPlus } from '@fortawesome/pro-light-svg-icons';
import GalleryItem from './components/GalleryItem/GalleryItem';
import ClassPhotoStats from './components/ClassPhotoStats';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import PhotoCounts from './components/PhotoCounts';
import { useGetSearchedPhotos } from 'pages/Photos/gql/query';
import { useApprovePhotos, useDenyPhotos } from 'pages/Photos/gql/mutations';
import { useGetCenterPhotoSettings } from '../PhotoSettings/gql/queries';

const approvedFilterOptions = [
  { value: null, label: 'Pending Approval' },
  { value: true, label: 'Approved' },
  { value: false, label: 'Denied' },
];

const Photos: React.FC = () => {
  const hasGalleryEdit = useHasRoleAreaLevel({
    area: AreaType.Activities,
    permission: PermissionType.Photo,
    level: RoleLevelType.Edit,
  });
  const hasGalleryCreate = useHasRoleAreaLevel({
    area: AreaType.Activities,
    permission: PermissionType.Photo,
    level: RoleLevelType.Create,
  });
  const [approvedFilter, setApprovedFilter] = React.useState(approvedFilterOptions[0]);
  const [isAddPhotoOpen, setIsAddPhotoOpen] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [items, setItems] = React.useState<IPhoto[]>([]);
  const [refetchStats, setRefetchStats] = React.useState(false);

  const [selectedItems, setSelectedItems] = React.useState<IPhoto[]>([]);
  const centerId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const {
    data: photosData,
    loading: loadingPhotos,
    refetch,
  } = useGetSearchedPhotos({
    skip: _.isNil(centerId) || _.isEmpty(centerId),
    variables: {
      input: {
        centerIds: [centerId],
        from: pageNumber,
        size: 24,
        isApproved: approvedFilter.value,
        isEvaluated: _.isNil(approvedFilter.value) ? false : true,
      },
    },
  });
  const totalRecords = photosData?.searchPhoto.totalRecords ?? 0;

  React.useEffect(() => {
    setItems(_.uniqBy([...items, ...(photosData?.searchPhoto.data ?? [])], 'id'));
  }, [photosData]);

  React.useEffect(() => {
    setItems([]);
    setPageNumber(1);
  }, [centerId, approvedFilter.value, approvedFilter]);

  const refetchPhotos = (size: number) => {
    setRefetchStats(true);
    refetch({
      input: {
        centerIds: [centerId],
        from: 1,
        size: size,
        isApproved: approvedFilter.value,
        isEvaluated: _.isNil(approvedFilter.value) ? false : true,
      },
    });
  };

  const [approvePhotos] = useApprovePhotos({
    onCompleted: () => {
      refetchPhotos(24);
      setRefetchStats(true);
      showToast('Photos Approved', 'success');
    },
    onError: () => {
      showToast('Error Approving Photos', 'error');
    },
  });
  const [denyPhotos] = useDenyPhotos({
    onCompleted: () => {
      refetchPhotos(24);
      setRefetchStats(true);
      showToast('Photos Denied', 'success');
    },
    onError: () => {
      showToast('Error Denying Photos', 'error');
    },
  });

  const onApprove = () => {
    approvePhotos({
      variables: { input: { galleryPhotoIds: selectedItems.map((i) => i.id) } },
    });
    setSelectedItems([]);
    setItems([]);
  };

  const onDeny = () => {
    denyPhotos({
      variables: { input: { galleryPhotoIds: selectedItems.map((i) => i.id) } },
    });
    setSelectedItems([]);
    setItems([]);
  };

  const onGalleryItemClick = (item: IPhoto) => {
    const isItThere = selectedItems.some((i: IPhoto) => {
      return item === i;
    });
    if (!isItThere) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        _.reject(selectedItems, function (i) {
          return i === item;
        })
      );
    }
  };

  const { data: centerData } = useGetCenterPhotoSettings({
    skip: _.isNil(centerId) || _.isEmpty(centerId),
    variables: { id: centerId },
  });

  return (
    <PageWrapper
      pageTitle="Dashboard"
      buttonComponent={
        (hasGalleryCreate || hasGalleryEdit) && (
          <Mui.Button color="secondary" variant="contained" onClick={() => setIsAddPhotoOpen(true)}>
            <FontAwesomeIcon icon={faPlus} />
            <span className="pl-2">Add Photo / Video</span>
          </Mui.Button>
        )
      }
      mobileButtonComponent={
        (hasGalleryCreate || hasGalleryEdit) && (
          <CirclePlusButton variant="primary" className="my-4" onClick={() => setIsAddPhotoOpen(true)} />
        )
      }
    >
      <CenterSelectBanner pageName="Photo Dashboard" showCenterSelect={true} />
      <ClassPhotoStats centerId={centerId} refetchStats={refetchStats} setRefetchStats={setRefetchStats} />
      {(_.isNil(centerData?.getCenter.gallerySettings) ||
        centerData?.getCenter.gallerySettings?.autoApprove === false) && (
        <PhotoCounts refetchStats={refetchStats} setRefetchStats={setRefetchStats} />
      )}
      <div className="d-flex align-items-flex-end">
        <Select
          className="mr-2 mb-6 w-260px flex-grow-0"
          value={approvedFilter}
          options={approvedFilterOptions}
          onChange={setApprovedFilter}
        />
        {approvedFilter.value == null && (hasGalleryEdit || hasGalleryCreate) && (
          <div className="ml-auto">
            <Button disabled={!selectedItems.length} className="mr-2 mb-6" variant="outline-primary" onClick={onDeny}>
              Decline Selected
            </Button>
            <Button disabled={!selectedItems.length} className="mr-2 mb-6" variant="primary" onClick={onApprove}>
              Approve Selected
            </Button>
          </div>
        )}
      </div>
      <SpinnerOverlay show={loadingPhotos}>
        {_.size(items) === 0 && (
          <Mui.Stack
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ paddingTop: '24px', paddingBottom: '24px' }}
          >
            <FontAwesomeIcon icon={faCameraRetro} size="3x" />
            <Mui.Typography variant="h4" component="span">
              No Photos or Videos Found
            </Mui.Typography>
          </Mui.Stack>
        )}
        <Masonry
          breakpointCols={{
            default: 4,
            1375: 3,
            1100: 2,
            600: 1,
          }}
          className="masonry-grid"
          style={{ minHeight: '200px' }}
          columnClassName="masonry-grid_column"
        >
          {_.size(items) > 0 &&
            items.map((galleryItem) => (
              <GalleryItem
                approvedFilter={approvedFilter.value}
                className={
                  (hasGalleryCreate || hasGalleryEdit) && approvedFilter.value === null ? 'cursor-pointer' : ''
                }
                key={galleryItem.id}
                photo={galleryItem}
                onGalleryItemClick={
                  (hasGalleryCreate || hasGalleryEdit) && approvedFilter.value === null ? onGalleryItemClick : () => {}
                }
                isSelected={selectedItems.some((i) => i.id === galleryItem.id)}
              />
            ))}
        </Masonry>
      </SpinnerOverlay>
      {items.length < totalRecords && !loadingPhotos && (
        <div className="d-flex">
          <Button className="mx-auto" variant="secondary" onClick={() => setPageNumber(pageNumber + 1)}>
            Load More
          </Button>
        </div>
      )}
      {isAddPhotoOpen && (
        <AddPhotoModal
          isOpen={isAddPhotoOpen}
          setIsOpen={setIsAddPhotoOpen}
          centerId={centerId}
          refetchPhotos={() => refetchPhotos(items.length + 1)}
        />
      )}
    </PageWrapper>
  );
};

export default Photos;
