import React from 'react';
import Spinner from 'shared/components/Spinner';

interface IProps {}

const MagicLinkUI: React.FC<IProps> = ({ ...props }) => {
  return (
    <div className="d-flex h-100">
      <Spinner large className="mx-auto my-auto" />
    </div>
  );
};

export default MagicLinkUI;
