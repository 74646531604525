import React, { useState } from 'react';
import Card from 'shared/components/Card';
import Button from 'shared/components/Buttons';
import DeactivateStaffModal from '../../../../shared/components/DeactivateStaffModal/DeactivateStaffModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers';

interface IProps {
  staff: IStaff;
}

const DeactivateCard: React.FC<IProps> = ({ staff }) => {
  const [modalOpen, setModelOpen] = useState(false);
  return (
    <div>
      <Card>
        <p>
          <strong>Deactivate User</strong>
        </p>
        <p>
          Deactivating this user will terminate all relationships that exist for this person and immediately end all
          permissions. Historical data and reporting for this person will remain available.
        </p>
        <div className="d-flex">
          <Button className="ml-auto" variant="outline-danger" onClick={() => setModelOpen(true)}>
            Deactivate
          </Button>
        </div>
      </Card>
      <DeactivateStaffModal staff={staff} isOpen={modalOpen} onClose={() => setModelOpen(false)} />
    </div>
  );
};

export default DeactivateCard;
