import React, { useCallback } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Button from 'shared/components/Buttons';

interface IProps {
  dailyAttendance: IDailyAttendance;
  dailyAttendanceTimezone: string;
  canPerformEdit: boolean;
  positionById: Record<string, string>;
  onEditButtonClick: () => void;
}

const ActualTimeBlock: React.FC<IProps> = ({
  dailyAttendance,
  dailyAttendanceTimezone,
  canPerformEdit,
  positionById,
  onEditButtonClick,
  ...props
}) => {
  const requiresFillerDivs: boolean = dailyAttendance.timeEntries.length < dailyAttendance.shifts.length;
  const numberOfFillerDivs: number = requiresFillerDivs
    ? dailyAttendance.shifts.length - dailyAttendance.timeEntries.length
    : 0;

  const renderPosition = useCallback(
    (timeEntry: ITimeEntry): string => {
      switch (timeEntry.type) {
        case 'SHIFT':
        case 'UNSCHEDULED':
          return positionById[timeEntry.positionId] ?? '';
        case 'BREAK':
          return 'Break';
        case 'TRAINING':
          return 'Training'; // not sure if there is a position associated to training so we'll just show this string
        default:
          return '';
      }
    },
    [positionById]
  );

  return (
    <div className="d-flex flex-column kt-time-sheets time-block">
      <div className="d-flex time-block__container">
        <div className="time-block__items mb-2">
          <div className="time-block__item">
            <div className="flex-grow-1 kt-time-sheets-time-entry-day-text-secondary">In</div>
            {dailyAttendance.timeEntries.map((timeEntry: ITimeEntry, idx: number) => (
              <div
                key={`actual-in-${timeEntry.id}-${idx}`}
                className="flex-grow-1 kt-time-sheets-time-entry-day-text-regular mb-2"
              >
                {momentTz(timeEntry.trackedTimeIn).tz(dailyAttendanceTimezone).format('h:mm A')}
              </div>
            ))}
            {Array.from(Array(numberOfFillerDivs).keys()).map((num: number, idx: number) => (
              <div
                key={`actual-in-filler-${num}-${idx}`}
                className="flex-grow-1 kt-time-sheets-time-entry-day-text-regular mb-2"
              >
                &nbsp;
              </div>
            ))}
          </div>
          <div className="time-block__item">
            <div className="flex-grow-1 kt-time-sheets-time-entry-day-text-secondary">Out</div>
            {dailyAttendance.timeEntries.map((timeEntry: ITimeEntry, idx: number) => (
              <div
                key={`actual-out-${timeEntry.id}-${idx}`}
                className="flex-grow-1 kt-time-sheets-time-entry-day-text-regular mb-2"
              >
                {timeEntry.trackedTimeOut
                  ? momentTz(timeEntry.trackedTimeOut).tz(dailyAttendanceTimezone).format('h:mm A')
                  : ''}
              </div>
            ))}
            {Array.from(Array(numberOfFillerDivs).keys()).map((num: number, idx: number) => (
              <div
                key={`actual-out-filler-${num}-${idx}`}
                className="flex-grow-1 kt-time-sheets-time-entry-day-text-regular mb-2"
              >
                &nbsp;
              </div>
            ))}
          </div>
          <div className="time-block__item">
            <div className="flex-grow-1 kt-time-sheets-time-entry-day-text-secondary">Position</div>
            {dailyAttendance.timeEntries.map((timeEntry: ITimeEntry, idx: number) => (
              <div
                key={`actual-position-${timeEntry.id}-${idx}`}
                className="flex-grow-1 kt-time-sheets-time-entry-day-text-regular mb-2"
              >
                {renderPosition(timeEntry)}
              </div>
            ))}
            {Array.from(Array(numberOfFillerDivs).keys()).map((num: number, idx: number) => (
              <div
                key={`actual-position-filler-${num}-${idx}`}
                className="flex-grow-1 kt-time-sheets-time-entry-day-text-regular mb-2"
              >
                &nbsp;
              </div>
            ))}
          </div>
        </div>

        {canPerformEdit && dailyAttendance.timeEntries.length > 0 && (
          <div className="time-block__control">
            <Button onClick={() => onEditButtonClick()} variant="light">
              Edit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActualTimeBlock;
