import React, { useCallback, useState } from 'react';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';

import DataTable, { TableSearch } from 'shared/components/DataTable';
import { TableHeader } from 'shared/components/DataTable';
import AvatarContent from 'shared/components/Avatar/AvatarContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { getInitials, stringToHsl } from 'shared/util/string';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import { ISCaseStatusFilters, ISCaseStatusLabelEnum } from 'shared/constants/enums/isCaseEnums';
import ISCaseDetailsRow from '../ISCaseDetailsRow';
import colors from '_colors.module.scss';
import moment from 'moment';
import { uniqBy } from 'lodash';
import { displayDuration } from 'shared/util/timeUtils';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface ITableProps {
  data: IISCase[];
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  centers: ICenter[];
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  activeFilters: IISCaseFilter;
  onActiveFilterChange: (value: IISCaseFilter) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  handleCreateClaim: (data: IISCase) => void;
  refetchCases: () => void;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const ISCaseTable: React.FC<ITableProps> = ({
  data,
  loading,
  dataSize,
  pageSize,
  activePage,
  centers,
  onPageChange,
  onSizePerPageChange,
  activeFilters,
  onActiveFilterChange,
  onSort,
  handleCreateClaim,
  refetchCases,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const handleCenterChange = (values: ITableFilterOption[]) => {
    onActiveFilterChange({ ...activeFilters, centers: values });
  };

  const handleStatusChange = (values: ITableFilterOption[]) => {
    onActiveFilterChange({ ...activeFilters, statuses: values });
  };

  const handleSearch = (value: string) => {
    onActiveFilterChange({ ...activeFilters, searchText: value });
  };

  const onExpand = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    const expanded = isExpand ? [row.id] : [];
    setExpandedRows(expanded);
  };

  const showActionRequired = (unsubmittedWeeks: string[], item: IISCase) => {
    return unsubmittedWeeks.some(
      (week) =>
        !item.excludedWeeks.some((item) => {
          const momentDate = moment(week);
          return momentDate.year() === item.year && momentDate.week() === item.weekNumber;
        })
    );
  };

  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? user?.entityId ?? '';

  return (
    <>
      <TableHeader>
        <div className="d-flex flex-grow-1 my-2">
          <TableSearch onChange={handleSearch} placeholder="Search by IS case Id or child" />
        </div>
        <DropdownFilter
          dropdownId="statusFilter"
          title="Status"
          placeholder="Status"
          options={ISCaseStatusFilters}
          onFilterSelect={(option: any) => handleStatusChange(option)}
          className="mr-4 my-2"
          selectedFilters={activeFilters.statuses || []}
        />

        <DropdownFilter
          dropdownId="centerFilter"
          title={fieldLabels.center}
          placeholder={fieldLabels.center}
          options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
          onFilterSelect={handleCenterChange}
          className="mr-4 my-2"
          selectedFilters={activeFilters.centers || []}
        />
      </TableHeader>
      <SpinnerTableOverlay show={loading}>
        <DataTable
          keyField="id"
          noPadding={true}
          data={data}
          dataSize={dataSize}
          handleRowClick={() => {}}
          hasOverlay={false}
          pageSize={pageSize}
          activePage={activePage}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          showPagination={true}
          showSelect={false}
          className="ccs-table"
          expandHeaderColumnRenderer={(props) => <></>}
          onExpand={onExpand}
          expanded={expandedRows}
          expandRow={(row: IISCase) => (
            <ISCaseDetailsRow
              data={row}
              businessId={businessId}
              handleCreateClaim={handleCreateClaim}
              refetchCases={refetchCases}
            />
          )}
          onSort={onSort}
          defaultSortField="created"
          defaultSorting="desc"
          onlyOneExpanding={true}
          columns={[
            {
              text: fieldLabels.center,
              dataField: 'centerName',
              sort: true,
            },
            {
              text: 'Case Status',
              dataField: 'status',
              sort: true,
              formatter: (cell: ISCaseStatus) => (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon className="mr-2" icon={faCircle} style={{ fontSize: 10 }} color={colors.success} />
                  {ISCaseStatusLabelEnum[cell]}
                </div>
              ),
            },
            {
              text: 'Children',
              dataField: 'children',
              formatter: (cell: ISCaseChildDayOfCare[]) => {
                return uniqBy(cell, (c) => c.childId).map((caseChild: ISCaseChildDayOfCare, index: number) => {
                  const { child, childId } = caseChild;
                  return (
                    <div
                      className="avatar d-inline-block float-none"
                      key={child.id}
                      style={{
                        marginLeft: index > 0 ? '-10px' : 0,
                      }}
                    >
                      <AvatarContent
                        color={stringToHsl(childId)}
                        initials={getInitials(child)}
                        image={child.avatar?.url ?? ''}
                        size="md"
                        alt={`${(child.nickname ?? child.firstname).toUpperCase().charAt(0)}${child.lastname
                          .toUpperCase()
                          .charAt(0)}`}
                      />
                    </div>
                  );
                });
              },
            },
            {
              text: 'IS Case ID',
              dataField: 'caseId',
              sort: true,
            },
            {
              text: 'F2F',
              align: 'center',
              headerClasses: 'text-center',
              dataField: 'supportHoursBalance',
              formatter: (cell: string) => displayDuration(cell),
            },
            {
              text: 'Non F2F',
              align: 'center',
              headerClasses: 'text-center',
              dataField: 'nonFaceToFaceHoursBalance',
              formatter: (cell: string) => displayDuration(cell),
            },
            {
              text: 'Start',
              dataField: 'startDate',
              align: 'center',
              headerClasses: 'text-center',
              sort: true,
              formatter: (cell: string) => moment(cell).format('DD/MM/YYYY'),
            },
            {
              text: 'End',
              dataField: 'endDate',
              align: 'center',
              headerClasses: 'text-center',
              sort: true,
              formatter: (cell: string) => moment(cell).format('DD/MM/YYYY'),
            },
            {
              text: 'Action Required',
              dataField: 'unsubmittedWeeks',
              align: 'center',
              headerClasses: 'text-center',
              formatter: (cell: string[], row: IISCase) =>
                showActionRequired(cell, row) ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color={colors.danger} size="2x" />
                ) : null,
            },
          ]}
        />
      </SpinnerTableOverlay>
    </>
  );
};

export default ISCaseTable;
