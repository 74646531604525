import React, { useEffect, useState } from 'react';
import './_messenger.scss';
import { useTranslation } from 'react-i18next';
import PubNub from 'pubnub';
import {
  ActivityType,
  ChannelType,
  PersonType,
  UserActivityPerson,
  useCreateChatActivityMutation,
  useGetAccountChannelLazyQuery,
  useGetCenterChannelLazyQuery,
  useGetChannelUsersLazyQuery,
  AccountStatusType,
} from 'generated/graphql';
import { MessageEnvelope, MessagePayload, UserEntity } from '@pubnub/react-chat-components';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { Channel } from 'pages/Engagement/subroutes/Messaging/Tabs/AccountMessagingTab/AccountMessagingTab';
import { Row, Col, Button } from 'react-bootstrap';
import Channels from './components/Channels';
import { showToast } from '../Toast';
import { faComments } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CenterChannelSelectModal from './components/CenterChannelSelectModal';
import MultiChannelMessageModal from './components/MultiChannelModal';
import { useHistory } from 'react-router-dom';
import { PubNubProvider } from 'pubnub-react';
import PubnubChatComponent from './components/AttachmentUploader/PubnubChatComponent';
import AccountChannelSelectModal from './components/AccountChannelSelectModal';
import FlagMessageModal from './components/FlagMessageModal';
import customEvents from 'shared/constants/customEvents';
import { IMessageAttachment } from 'shared/types/channel';

interface IProps {
  pubnub: PubNub;
  authCenters: string[];
  initialChannels: Channel[];
  loadingChannels: boolean;
  channelsType: ChannelType;
  hasWritePermission: boolean;
  hasDeletePermission: boolean;
  accountsPage?: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  selectedCenters: ITableFilterOption[];
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  onSearchTermChange: (searchTerm: string) => void;
  setSelectedCenters: (centerFilter: ITableFilterOption[]) => void;
  refetchChannels: () => void;
}

const Messenger: React.FC<IProps> = ({
  pubnub,
  authCenters,
  initialChannels,
  loadingChannels,
  channelsType,
  hasWritePermission,
  hasDeletePermission,
  accountsPage,
  dataSize,
  pageSize,
  activePage,
  selectedCenters,
  onPageChange,
  onSizePerPageChange,
  onSearchTermChange,
  setSelectedCenters,
  refetchChannels,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['comms']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();
  const syncNotificationMiniScreen = new Event(customEvents.REFETCH_NOTIFICATION_V2_MINI_SCREEN);

  const [showFlagMessageModal, setShowFlagMessageModal] = useState(false);
  const [messageToFlag, setMessageToFlag] = useState<MessageEnvelope | undefined>();

  const [selectedChannelsToMessage, setSelectedChannelsToMessage] = useState<IAccount[] | ICenter[]>([]);
  const [showMultiChannelMessageModal, setShowMultiChannelMessageModal] = useState(false);
  const [showAccountChannelSelectModal, setShowAccountChannelSelectModal] = useState(false);
  const [showCenterChannelSelectModal, setShowCenterChannelSelectModal] = useState(false);

  const [channelsSearchValue, setChannelsSearchValue] = useState('');

  const [channels, setChannels] = useState<Channel[] | undefined>(initialChannels);
  const [currentChannel, setCurrentChannel] = useState<string | undefined>();
  const [currentChannelInfo, setCurrentChannelInfo] = useState<Channel>();

  const [currentChannelUsers, setCurrentChannelUsers] = useState<UserActivityPerson[] | undefined | null>([]);
  const [users, setUsers] = useState<UserEntity[]>([
    {
      id: user?.id ?? '',
      name: `${user?.firstname} ${user?.lastname}`,
      profileUrl: user?.avatar ? user?.avatar.url : undefined,
      eTag: '',
      updated: '',
    },
  ]);

  const [getAccountChannel, { loading: getAccountChannelLoading, data: getAccountChannelData }] =
    useGetAccountChannelLazyQuery({
      onCompleted: (result) => {
        if (result.getAccountChannel) {
          handleCreateNewChannel(result.getAccountChannel);
          getChannelUsers({
            variables: {
              businessId: businessId ?? '',
              channelId: result.getAccountChannel.channelId,
            },
          });
        } else
          showToast(t('comms:getChannels.error', { channelType: channelsType.toLowerCase(), plural: '' }), 'error');
      },
      onError: (error) => {
        showToast(t('comms:getChannels.error', { channelType: channelsType.toLowerCase(), plural: '' }), 'error');
      },
    });

  const [getCenterChannel, { loading: getCenterChannelLoading, data: getCenterChannelData }] =
    useGetCenterChannelLazyQuery({
      onCompleted: (result) => {
        if (result.getCenterChannel) handleCreateNewChannel(result.getCenterChannel);
        else
          showToast(
            t('comms:getChannels.error', {
              channelType: channelsType === ChannelType.Account ? 'account' : 'announcement',
              plural: '',
            }),
            'error'
          );
      },
      onError: (error) => {
        showToast(t('comms:getChannels.error', { channelType: channelsType.toLowerCase(), plural: '' }), 'error');
      },
    });

  const [createChatActivity, { data, loading: createActivityLoading, error: createChatActivityError }] =
    useCreateChatActivityMutation({
      onError: (err) => showToast('Unable to send user data', 'error'),
      onCompleted: (response) => {
        if (response.createChatActivity?.activityType === ActivityType.ChannelJoined)
          setTimeout(() => document.dispatchEvent(syncNotificationMiniScreen), 5000);
      },
    });

  const [getChannelUsers, { loading: getChannelUsersLoading, data: getChannelUsersData }] = useGetChannelUsersLazyQuery(
    {
      onCompleted: (result) => {
        if (channelsType === ChannelType.Account)
          setCurrentChannelUsers(result.getChannelUsers?.filter((u) => u.type === PersonType.Guardian));
      },
      onError: (error) => {
        showToast('unable to fetch user data', 'error');
      },
    }
  );

  /* Functions */

  const clearSelectedChannel = () => {
    setCurrentChannel('');
    setCurrentChannelInfo(undefined);
  };

  const handleChannelSwitch = (channel: Channel) => {
    if (currentChannel && currentChannel !== '') {
      createChatActivity({
        variables: {
          businessId: businessId ?? '',
          input: {
            businessId: businessId ?? '',
            channelType: currentChannelInfo?.channelType === 'Account' ? ChannelType.Account : ChannelType.Center,
            channelId: currentChannelInfo?.channelId,
            personId: user?.id,
            activityType: ActivityType.ChannelLeft,
            activityTime: 0,
          },
        },
      });
    }

    if (currentChannel === channel.channelIdentifier) {
      setCurrentChannel(undefined); // unselect
      setCurrentChannelInfo(undefined);
      setCurrentChannelUsers(undefined);
      return;
    }

    createChatActivity({
      variables: {
        businessId: businessId ?? '',
        input: {
          businessId: businessId ?? '',
          channelType: channel?.channelType === 'Account' ? ChannelType.Account : ChannelType.Center,
          channelId: channel.channelId,
          personId: user?.id,
          activityType: ActivityType.ChannelJoined,
          activityTime: 0,
        },
      },
    });

    if (channelsType === ChannelType.Account) {
      getChannelUsers({
        variables: {
          businessId: businessId ?? '',
          channelId: channel.channelId,
        },
      });
    }

    handleUpdateChannelTimes(channel.channelIdentifier, true, false);
    setCurrentChannel(channel.channelIdentifier);
    setCurrentChannelInfo(channel);
  };

  const handleSetSelectedChannelsToMessage = (selectedRows: IAccount[] | ICenter[]) => {
    setSelectedChannelsToMessage(selectedRows);
  };

  const handleHideModal = (newChannel: IAccount | ICenter | undefined) => {
    setShowAccountChannelSelectModal(false);
    setShowCenterChannelSelectModal(false);

    const channel = channels?.find((c) => c.channelId == newChannel?.id);
    if (channel && channel.channelIdentifier !== currentChannel) {
      setCurrentChannel(channel.channelIdentifier);
      setCurrentChannelInfo(channel);
    }
  };

  const handleCreateNewChannel = (newChannel: Channel) => {
    createChatActivity({
      variables: {
        businessId: businessId ?? '',
        input: {
          businessId: businessId ?? '',
          channelType: newChannel?.channelType === 'Account' ? ChannelType.Account : ChannelType.Center,
          channelId: newChannel.channelId,
          personId: user?.id,
          activityType: ActivityType.ChannelJoined,
          activityTime: 0,
        },
      },
    });

    onSearchTermChange('');
    setSelectedCenters([]);
    onPageChange(1, pageSize);
    refetchChannels();
    setCurrentChannel(newChannel.channelIdentifier);
    setCurrentChannelInfo(newChannel);
  };

  const handleUpdateChannelTimes = (
    channelIdentifier: string | undefined,
    updateLastRead?: boolean,
    updateLastActive?: boolean
  ) => {
    const currentChannels = channels && channels.length > 0 ? channels : initialChannels;
    if (currentChannels) {
      setChannels(
        currentChannels.map((obj) => {
          if (obj.channelIdentifier === channelIdentifier) {
            return {
              ...obj,
              lastAccessedByPersonId: updateLastRead
                ? moment().utc().tz(timezone).format().toString()
                : obj.lastAccessedByPersonId,
              lastActive: updateLastActive ? moment().utc().tz(timezone).format().toString() : obj.lastActive,
            };
          }
          return obj;
        })
      );
    }
  };

  useEffect(() => {
    setChannels(initialChannels && initialChannels.length > 0 ? initialChannels : undefined);
    if (initialChannels.length === 1) {
      setCurrentChannel(initialChannels[0].channelIdentifier);
      setCurrentChannelInfo(initialChannels[0]);
      getChannelUsers({
        variables: {
          businessId: businessId ?? '',
          channelId: initialChannels[0].channelId,
        },
      });
    }
  }, [initialChannels]);

  // used to listen out to messages and send userActivity when the current user sends a message
  // and to update the channel list when a new message comes in
  const messageListener = {
    message: function (message) {
      const payload = message.message as MessagePayload;
      const messageSender = payload.sender?.id ?? message.publisher ?? '';
      const messageChannelType = message.channel?.toLowerCase().includes('.broadcast')
        ? ChannelType.Center
        : ChannelType.Account;
      const attachments = payload.KtAttachments as IMessageAttachment[];
      var attachmentIds = attachments?.map((a) => a.id) ?? null;
      if (currentChannel === message.channel && messageChannelType === channelsType) {
        handleUpdateChannelTimes(currentChannel, true, true);
        if (messageSender === user?.id) {
          createChatActivity({
            variables: {
              businessId: businessId ?? '',
              input: {
                businessId: businessId ?? '',
                channelType: currentChannelInfo?.channelType === 'Account' ? ChannelType.Account : ChannelType.Center,
                channelId: currentChannelInfo?.channelId,
                personId: user?.id,
                activityType: ActivityType.MessageSent,
                activityTime: parseInt(message.timetoken.toString()),
                pubNubMessageId: payload.id,
                messageContent: payload.text,
                channelWasRead: true,
                attachmentIds: attachmentIds,
              },
            },
          });
        }
      }

      if (
        messageSender !== user?.id &&
        messageChannelType === channelsType &&
        channels?.some((c) => c.channelIdentifier === message.channel)
      ) {
        handleUpdateChannelTimes(message.channel, false, true);
      }
    },
  };

  useEffect(() => {
    pubnub.addListener(messageListener);
    return function cleanupPubNubListener() {
      pubnub.removeListener(messageListener);
      pubnub.unsubscribeAll();
    };
  }, [currentChannel, currentChannelInfo]);

  useEffect(() => {
    if (channelsSearchValue) {
      onSearchTermChange(channelsSearchValue);
    }
  }, [channelsSearchValue, onSearchTermChange]);

  return (
    <>
      <Row className="messenger-container">
        <Col md={12} lg={6} xl={4}>
          <Channels
            channels={channels}
            authCenters={authCenters}
            handleChannelSwitch={handleChannelSwitch}
            currentChannel={currentChannel}
            handleOpenNewMessageModal={() => {
              channelsType === ChannelType.Account
                ? setShowAccountChannelSelectModal(true)
                : setShowCenterChannelSelectModal(true);
            }}
            channelType={channelsType}
            loading={loadingChannels}
            dataSize={dataSize}
            pageSize={pageSize}
            activePage={activePage}
            selectedCenters={selectedCenters}
            accountsPage={accountsPage}
            hideUnreadIndicator={channelsType === ChannelType.Center}
            onPageChange={(page: number, sizePerPage: number) => {
              clearSelectedChannel();
              onPageChange(page, sizePerPage);
            }}
            onSizePerPageChange={(sizePerPage: number) => {
              clearSelectedChannel();
              onSizePerPageChange(sizePerPage);
            }}
            onSearchTermChange={(searchTerm: string) => {
              // I need to clear modal setChannelsSearchValue here
              setChannelsSearchValue('');
              clearSelectedChannel();
              onSearchTermChange(searchTerm);
            }}
            searchTerm={channelsSearchValue}
            setSelectedCenters={(centerFilter: ITableFilterOption[]) => {
              clearSelectedChannel();
              setSelectedCenters(centerFilter);
            }}
            hasWritePermission={hasWritePermission}
          />
        </Col>
        <PubNubProvider client={pubnub}>
          {currentChannel && (
            <PubnubChatComponent
              pubnub={pubnub}
              authCenters={authCenters}
              currentChannel={currentChannel}
              subscribedChannels={channels?.map((c) => c.channelIdentifier) ?? []}
              users={users}
              getUsersLoading={getChannelUsersLoading}
              hasWritePermission={hasWritePermission}
              hasDeletePermission={hasDeletePermission}
              currentChannelUsers={currentChannelUsers}
              channelsType={channelsType}
              currentChannelInfo={currentChannelInfo}
              onFlagMessage={(message: MessageEnvelope) => {
                setShowFlagMessageModal(true);
                setMessageToFlag(message);
              }}
              msgSent={() => setChannelsSearchValue('')}
            />
          )}
          {/* multi-message and flag-message modal must go in this section as it depends on being inside the PubNubProvider*/}
          <MultiChannelMessageModal
            pubnub={pubnub}
            channelType={channelsType}
            isOpen={showMultiChannelMessageModal}
            selectedChannels={selectedChannelsToMessage}
            onClose={() => {
              setSelectedChannelsToMessage([]);
              setShowMultiChannelMessageModal(false);
              refetchChannels();
            }}
            handleBack={() => {
              setShowMultiChannelMessageModal(false);
              if (channelsType == ChannelType.Account) setShowAccountChannelSelectModal(true);
              else setShowCenterChannelSelectModal(true);
              refetchChannels();
            }}
          />
          <FlagMessageModal
            pubnub={pubnub}
            isOpen={showFlagMessageModal}
            onClose={() => {
              setShowFlagMessageModal(false);
              setMessageToFlag(undefined);
            }}
            handleSubmit={() => {
              setShowFlagMessageModal(false);
              setMessageToFlag(undefined);
            }}
            message={messageToFlag}
            currentChannel={currentChannel}
            currentChannelInfo={currentChannelInfo}
          />
        </PubNubProvider>
        {!currentChannel && (
          <Col md={12} lg={6} xl={8} className={'no-messages-container'}>
            <div className={'no-messages'}>
              <FontAwesomeIcon icon={faComments} size={'10x'} />
              <p>No chat currently selected</p>
              {hasWritePermission && !accountsPage && (
                <Button
                  onClick={() => {
                    channelsType === ChannelType.Account
                      ? setShowAccountChannelSelectModal(true)
                      : setShowCenterChannelSelectModal(true);
                  }}
                >
                  {channelsType === ChannelType.Center ? t('comms:new-announcement-btn') : t('comms:new-message-btn')}
                </Button>
              )}
            </div>
          </Col>
        )}
      </Row>

      <CenterChannelSelectModal
        isOpen={showCenterChannelSelectModal}
        onClose={() => setShowCenterChannelSelectModal(false)}
        handleSubmit={() => {
          if (selectedChannelsToMessage.length === 1) {
            handleHideModal(selectedChannelsToMessage[0]);
            if (currentChannelInfo?.channelId !== selectedChannelsToMessage[0].id) {
              getCenterChannel({
                variables: { businessId: businessId ?? '', centerId: selectedChannelsToMessage[0]?.id ?? '' },
              });
            }
            setSelectedChannelsToMessage([]);
          } else {
            setCurrentChannel('');
            setCurrentChannelInfo(undefined);
            setShowCenterChannelSelectModal(false);
            setShowMultiChannelMessageModal(true);
          }
        }}
        selectedChannels={selectedChannelsToMessage}
        setSelectedChannels={handleSetSelectedChannelsToMessage}
      />
      <AccountChannelSelectModal
        isOpen={showAccountChannelSelectModal}
        setSelectedChannelName={(x) => {
          setChannelsSearchValue(x);
        }}
        onClose={() => {
          setShowAccountChannelSelectModal(false);
          setSelectedChannelsToMessage([]);
        }}
        selectedChannels={selectedChannelsToMessage}
        setSelectedChannels={handleSetSelectedChannelsToMessage}
        handleSubmit={() => {
          if (selectedChannelsToMessage.length === 1) {
            handleHideModal(selectedChannelsToMessage[0]);
            if (
              currentChannelInfo?.channelId !== selectedChannelsToMessage[0].id &&
              !channels?.some((c) => c.channelId === selectedChannelsToMessage[0].id)
            ) {
              getAccountChannel({
                variables: { businessId: businessId ?? '', accountId: selectedChannelsToMessage[0]?.id ?? '' },
              });
            }
            setSelectedChannelsToMessage([]);
          } else {
            setCurrentChannel('');
            setCurrentChannelInfo(undefined);
            setShowAccountChannelSelectModal(false);
            setShowMultiChannelMessageModal(true);
          }
        }}
      />
    </>
  );
};

export default Messenger;
