import React, { useCallback, useState, useEffect } from 'react';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
import { useSearchActiveStaffForCenter } from 'shared/hooks/useSearchActiveStaffForCenter';
import { getFullName } from 'shared/util/string';
import { SEARCH_STAFF } from 'gql/staff/queries';
import { useCreateTrainingTimeEvent, useUpdateTrainingTimeEvent } from 'gql/trainingTimeEvent/mutations';
import { showToast } from 'shared/components/Toast';
import { useDispatch } from 'react-redux';
import { createTrainingTimeEvent, updateTrainingTimeEvent } from '../duck/actions';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSearchPayCodes } from 'gql/payCode/queries';
import Select from 'shared/components/Select';
import { isEmpty } from 'lodash';
import { useGetLockedUntilForCenter } from 'gql/timeLogLock/queries';
import moment from 'moment';

export class TrainingHolidayEvent {
  id: string;
  businessId: string;
  centerId: string;
  name: string;
  type: TrainingHolidayEventType;
  date: string;
  hours: number;
  staffIds: string[];
  ptoTypeId: string;

  constructor(args: ITrainingHolidayEvent) {
    this.id = args.id;
    this.businessId = args.businessId;
    this.centerId = args.centerId;
    this.name = args.name;
    this.type = args.type;
    this.date = args.date;
    this.hours = args.hours;
    this.staffIds = args.staffIds;
    this.ptoTypeId = args.ptoTypeId || '';
  }

  typeToString(): string {
    switch (this.type) {
      case 'TRAINING':
        return 'Training';
      case 'HOLIDAY':
        return 'Holiday';
      case 'CLOSURE':
        return 'Closure';
      case 'OOBH':
        return 'Out of Business Hours';
      default:
        return '';
    }
  }
}

interface IFormShape {
  name: string;
  type: TrainingHolidayEventType | null;
  date: string | null;
  hours: number | null;
  employeeIds: string[];
  ptoTypeId?: string | null;
}

interface IProps {
  isOpen: boolean;
  businessId: string;
  centerId: string;
  trainingTimeEvent?: ITrainingHolidayEvent | null;
  onClose: () => void;
}

const TrainingTimeEventModal: React.FC<IProps> = ({
  isOpen,
  businessId,
  centerId,
  trainingTimeEvent,
  onClose,
  ...props
}) => {
  const { t } = useTranslation(['timelog']);
  const { k2WebPtoTypes, k2TimeLogLock, k2TimeoffRequestType } = useFlags();
  const dispatch = useDispatch();
  const [showEditWarning, setShowEditWarning] = useState<boolean>(false);
  const { data: searchActiveStaffData } = useSearchActiveStaffForCenter(
    centerId,
    SEARCH_STAFF(`id firstname lastname nickname`)
  );
  const [formData, setFormData] = useState<IFormShape>({
    name: trainingTimeEvent?.name ?? '',
    type: trainingTimeEvent?.type ?? null,
    date: trainingTimeEvent?.date ?? null,
    hours: trainingTimeEvent?.hours ?? null,
    employeeIds: trainingTimeEvent?.staffIds ?? [],
    ptoTypeId: trainingTimeEvent?.ptoTypeId ?? null,
  });
  const [createTrainingTimeEventFn, { loading: createTrainingTimeEventLoading }] = useCreateTrainingTimeEvent({
    onCompleted: (result) => {
      showToast(t('timelog:training-time.create-success-toast'), 'success');
      dispatch(createTrainingTimeEvent(result.createTrainingTimeEvent));
      handleClose();
    },
    onError: (err) => {
      showToast(t('timelog:training-time.create-failure-toast'), 'error');
    },
  });
  const [updateTrainingTimeEventFn, { loading: updateTrainingTimeEventLoading }] = useUpdateTrainingTimeEvent({
    onCompleted: (result) => {
      showToast(t('timelog:training-time.update-success-toast'), 'success');
      dispatch(updateTrainingTimeEvent(result.updateTrainingTimeEvent));
      handleClose();
    },
    onError: (err) => {
      showToast(t('timelog:training-time.update-failure-toast'), 'error');
    },
  });
  const [filteredPtoTypes, setFilteredPtoTypes] = useState<IPayCodeType[]>([]);

  const { data: ptoTypesData } = useSearchPayCodes({
    skip: isEmpty(businessId),
    variables: {
      input: {
        businessId,
        centerId,
        from: 0,
        size: 10000, // if they have more than this ¯\_(ツ)_/¯
        sortDirection: 'ASCENDING',
        sortField: 'name',
        includeBusinessTypes: true,
      },
    },
  });

  useEffect(() => {
    if (trainingTimeEvent) {
      setFormData({
        name: trainingTimeEvent.name,
        type: trainingTimeEvent.type,
        date: trainingTimeEvent.date,
        hours: trainingTimeEvent.hours,
        employeeIds: trainingTimeEvent.staffIds,
        ptoTypeId: trainingTimeEvent.ptoTypeId,
      });
      !k2TimeLogLock && setShowEditWarning(true);
    }
  }, [trainingTimeEvent]);

  const [lockedUntil, setLockedUntil] = useState(moment().subtract(1, 'days'));
  const { data: lockedUntilData } = useGetLockedUntilForCenter({
    variables: {
      input: {
        businessId: businessId ?? '',
        centerId,
      },
    },
    skip: !businessId,
  });
  React.useEffect(() => {
    // @ts-ignore
    const lockedUntilDate = lockedUntilData?.getLockedUntilForCenter?.data?.lockedUntil;

    if (lockedUntilDate) {
      setLockedUntil(moment(lockedUntilDate).endOf('day'));
    } else {
      //if there is no time log locking in effect, unblock the date picker to go back upto an year
      setLockedUntil(moment().subtract(1, 'years'));
    }
  }, [lockedUntilData]);

  const handleClose = useCallback(() => {
    setFormData({
      name: '',
      type: null,
      date: null,
      hours: null,
      employeeIds: [],
      ptoTypeId: null,
    });

    setShowEditWarning(false);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(
    (data: IFormShape) => {
      if (!businessId.trim() || !centerId.trim()) return;

      if (trainingTimeEvent) {
        updateTrainingTimeEventFn({
          variables: {
            input: {
              id: trainingTimeEvent.id,
              name: data.name,
              type: data.type as TrainingHolidayEventType,
              date: data.date as string,
              hours: data.hours as number,
              staffIds: data.employeeIds,
              ptoTypeId: data.ptoTypeId as string,
            },
          },
        });
      } else {
        createTrainingTimeEventFn({
          variables: {
            input: {
              businessId,
              centerId,
              name: data.name,
              type: data.type as TrainingHolidayEventType,
              date: data.date as string,
              hours: data.hours as number,
              staffIds: data.employeeIds,
              ptoTypeId: data.ptoTypeId as string,
            },
          },
        });
      }
    },
    [businessId, centerId, trainingTimeEvent, createTrainingTimeEventFn, updateTrainingTimeEventFn]
  );

  const selectedEmployees = (searchActiveStaffData?.searchStaff.data ?? []).filter((emp) =>
    formData.employeeIds.includes(emp.id)
  );

  const formDisabled =
    !formData.name.trim() ||
    !formData.date ||
    !formData.hours ||
    (formData.hours !== null && formData.hours < 0) ||
    !formData.type ||
    !formData.employeeIds.length;

  const timeEventCateogries: { label: string; value: TrainingHolidayEventType }[] = [
    { label: 'Training', value: 'TRAINING' },
    { label: 'Holiday', value: 'HOLIDAY' },
    // @ts-ignore
    ...(k2WebPtoTypes
      ? [
          { label: 'Closure', value: 'CLOSURE' },
          { label: 'Out of Business Hours', value: 'OOBH' },
        ]
      : []),
  ];

  const filterPtoTypes = useCallback(
    (category: string = '') => {
      // @ts-ignore
      const types = ptoTypesData?.searchPtoType.data ?? [];
      const categorizedEvents = types.filter((type) => type.trainingTimeEventType);
      return (
        (category ? categorizedEvents.filter((type) => type.trainingTimeEventType === category) : categorizedEvents) ??
        []
      );
    },
    [ptoTypesData]
  );

  useEffect(() => {
    setFilteredPtoTypes(filterPtoTypes());
    if (trainingTimeEvent) {
      setFilteredPtoTypes(filterPtoTypes(trainingTimeEvent.type));
    }
  }, [ptoTypesData, trainingTimeEvent, filterPtoTypes]);

  const selectedPtoType = filteredPtoTypes.find((opt) => opt.id === formData.ptoTypeId) || '';

  const timeCardLockDisableEdit = React.useMemo(
    () => k2TimeLogLock && Boolean(trainingTimeEvent) && moment(formData.date).isSameOrBefore(lockedUntil),
    [lockedUntil, formData]
  );

  return (
    <SideModalDrawer
      title={t('timelog:training-time.time-event-modal-title', { action: Boolean(trainingTimeEvent) ? 'Edit' : 'Add' })}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice={showEditWarning ? 'Proceed' : 'Save'}
      primaryCallback={() => (showEditWarning ? setShowEditWarning(false) : handleSubmit(formData))}
      primaryButtonProps={{
        disabled: formDisabled || (!showEditWarning && timeCardLockDisableEdit),
        loading: createTrainingTimeEventLoading || updateTrainingTimeEventLoading,
      }}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      {showEditWarning ? (
        <Row>
          <Col>
            <p>{t('timelog:training-time.update-warning-body')}</p>
            <p>{t('timelog:training-time.update-warning-body-detail')}</p>
          </Col>
        </Row>
      ) : (
        <>
          {timeCardLockDisableEdit && (
            <Row>
              <Col>
                <p>{`This event cannot be modified because time has been locked for payroll.`}</p>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <TextInput
                required
                label="Name"
                value={formData.name}
                onChange={(value) => setFormData((prev) => ({ ...prev, name: value }))}
                disabled={timeCardLockDisableEdit}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <div className="d-flex flex-row">
                  <Form.Label>Type</Form.Label>
                  <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
                </div>
                {timeEventCateogries.map((category, idx) => (
                  <Form.Check
                    label={category.label}
                    name="action-group"
                    type="radio"
                    checked={formData.type === category.value}
                    disabled={timeCardLockDisableEdit}
                    onChange={(event) => {
                      setFormData((prev) => ({ ...prev, type: category.value, ptoTypeId: null }));
                      setFilteredPtoTypes(filterPtoTypes(category.value));
                    }}
                  />
                ))}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                required={k2TimeoffRequestType}
                label="Request Type"
                options={filteredPtoTypes}
                getOptionLabel={(opt: IPayCodeType) => `${opt.code} - ${opt.name}`}
                getOptionValue={(opt: IPayCodeType) => opt.id}
                value={selectedPtoType}
                disabled={timeCardLockDisableEdit}
                onChange={(opt: IPayCodeType) => {
                  setFormData((prev) => ({ ...prev, ptoTypeId: opt.id }));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DateInput
                required
                label="Date"
                date={formData.date}
                onDateSelect={(date) => setFormData((prev) => ({ ...prev, date }))}
                disabled={timeCardLockDisableEdit}
                isOutsideRange={(day) => moment(day).isBefore(lockedUntil)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberInput
                required
                label="Hours"
                value={formData.hours?.toString() ?? ''}
                disabled={timeCardLockDisableEdit}
                onChange={(value) => setFormData((prev) => ({ ...prev, hours: value }))}
                min="0"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {/* always show employee selector if time card lock LD is off */}
              {!k2TimeLogLock && (
                <SelectMultiple
                  required
                  label="Employees"
                  onChange={(employees) =>
                    setFormData((prev) => ({ ...prev, employeeIds: (employees ?? []).map((opt) => opt.id) }))
                  }
                  options={searchActiveStaffData?.searchStaff.data ?? []}
                  value={selectedEmployees}
                  getOptionLabel={(option: IStaff) => getFullName(option)}
                  getOptionValue={(option: IStaff) => option.id}
                  displayCountInContainerRenderer={(selectedCount) =>
                    `${selectedCount} ${selectedCount === 1 ? 'Employee' : 'Employees'} Selected`
                  }
                />
              )}
              {/* show employee selector if time card lock LD is on, and it is a new group time off request */}
              {k2TimeLogLock && !Boolean(trainingTimeEvent) && (
                <SelectMultiple
                  required
                  label="Employees"
                  onChange={(employees) =>
                    setFormData((prev) => ({ ...prev, employeeIds: (employees ?? []).map((opt) => opt.id) }))
                  }
                  options={searchActiveStaffData?.searchStaff.data ?? []}
                  value={selectedEmployees}
                  getOptionLabel={(option: IStaff) => getFullName(option)}
                  getOptionValue={(option: IStaff) => option.id}
                  displayCountInContainerRenderer={(selectedCount) =>
                    `${selectedCount} ${selectedCount === 1 ? 'Employee' : 'Employees'} Selected`
                  }
                />
              )}
              {k2TimeLogLock && Boolean(trainingTimeEvent) && moment(formData.date).isAfter(lockedUntil) && (
                <SelectMultiple
                  required
                  label="Employees"
                  onChange={(employees) =>
                    setFormData((prev) => ({ ...prev, employeeIds: (employees ?? []).map((opt) => opt.id) }))
                  }
                  options={searchActiveStaffData?.searchStaff.data ?? []}
                  value={selectedEmployees}
                  getOptionLabel={(option: IStaff) => getFullName(option)}
                  getOptionValue={(option: IStaff) => option.id}
                  displayCountInContainerRenderer={(selectedCount) =>
                    `${selectedCount} ${selectedCount === 1 ? 'Employee' : 'Employees'} Selected`
                  }
                />
              )}
              {timeCardLockDisableEdit && <span>Selected Employees</span>}
              <div className="d-flex flex-row flex-wrap">
                {selectedEmployees.length > 0 ? (
                  selectedEmployees.map((emp) => (
                    <div key={emp.id} className="tag mr-2 mb-2 rounded">
                      {getFullName(emp)}
                    </div>
                  ))
                ) : (
                  <small>No employees selected.</small>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
    </SideModalDrawer>
  );
};

export default TrainingTimeEventModal;
