import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import {
  ISCaseClaimPaymentPagedResultFields,
  iSCaseFieldsPagedResult,
  iSInnovativeCasesForCentersPagedResult,
} from './fields';
import { orderBy } from 'lodash';

interface IGetISCases {
  getIsCasesForCenters: IPagedResult<IISCase>;
}

export const GET_IS_CASES = gql`
  query($input: GetIsCasesForCentersInput!) {
    getIsCasesForCenters(input: $input) {
      ${iSCaseFieldsPagedResult}
    }
  }
`;

const daySort = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

export const useGetISCases = (options?: QueryHookOptions<IGetISCases, IGetISCaseVariables>) =>
  useQuery<IGetISCases, IGetISCaseVariables>(GET_IS_CASES, {
    fetchPolicy: 'network-only',
    onCompleted: (data: IGetISCases) => {
      for (const isCase of data.getIsCasesForCenters.data) {
        isCase.daysOfCare = orderBy(isCase.daysOfCare, (d) => daySort[d.dayOfCare]);
        isCase.children = orderBy(isCase.children, (d) => daySort[d.dayOfCare]);
      }
    },
    ...options,
  });

const GET_IS_CASE_CLAIM_PAYMENTS_FOR_CENTERS = gql`
  query($input: GetIsCaseClaimPaymentsForCentersInput!) {
    getIsCaseClaimPaymentsForCenters(input: $input) {
      ${ISCaseClaimPaymentPagedResultFields}
    }
  }
`;

interface IGetISCaseClaimPaymentsData {
  getIsCaseClaimPaymentsForCenters: IPagedResult<IIsCaseClaimPayment>;
}

interface IGetISCaseClaimPaymentsVariables {
  input: IGetIsCaseClaimPaymentsForCenters;
}

export const useGetISCaseClaimPaymentsForCenters = (
  options?: QueryHookOptions<IGetISCaseClaimPaymentsData, IGetISCaseClaimPaymentsVariables>
) =>
  useQuery<IGetISCaseClaimPaymentsData, IGetISCaseClaimPaymentsVariables>(
    GET_IS_CASE_CLAIM_PAYMENTS_FOR_CENTERS,
    options
  );

interface IGetISInnovativeCasesForCenters {
  getIsInnovativeCasesForCenters: IPagedResult<IISInnovativeCasesForCenters>;
}

export const GET_IS_INNOVATIVE_CASES_FOR_CENTERS = gql`
  query($input: GetIsInnovativeCasesForCentersInput!) {
    getIsInnovativeCasesForCenters(input: $input) {
      ${iSInnovativeCasesForCentersPagedResult}
    }
  }
`;

export const useGetISInnovativeCasesForCenters = (
  options?: QueryHookOptions<IGetISInnovativeCasesForCenters, IGetISInnovativeCasesForCentersVariables>
) =>
  useQuery<IGetISInnovativeCasesForCenters, IGetISInnovativeCasesForCentersVariables>(
    GET_IS_INNOVATIVE_CASES_FOR_CENTERS,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );
