import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useMediaQuery, useTheme } from '@mui/material';
import { useGetKindyForAllAcquittalChildSummaries } from 'gql/kindyForAll/queries';
import { useCallback, useContext, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButtonCircle from 'shared/components/Buttons/IconButtonCircle';
import Currency from 'shared/components/Currency';
import DataTable, { SizePerPage, TableHeader, TableSearch, TABLE_DEFAULTS } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { IKindyForAllAcquittalChildSummary, IKindyForAllQuarterlyPeriod } from 'shared/types/kindyForAll';
import { setCurrentCenterFilters } from 'store/context/actions';
import { RootState } from 'store/reducers';
import PeriodPicker from '../../PeriodPicker';
import { PeriodContext } from '../..';
import {
  FreeKindyAcquittalChildSummaryDto,
  SortDirection,
  useGetFreeKindyAcquittalChildSummariesQuery,
} from 'generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import Chip from 'shared/components/Chip/Chip';

interface IProps {}

interface ISort {
  direction: ElasticsearchSortDirection;
  field: string;
}

const ChildAcquittalsTab: React.FC<IProps> = ({}, ref) => {
  const { businessId, centerFilterIds } = useSelector((state: RootState) => state.context);
  const { data: centers } = useGetActiveCentersWithLoading();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { year, period } = useContext(PeriodContext);
  const [sort, setSort] = useState<ISort>({ field: 'centerName', direction: 'ASCENDING' });
  const [tableState, tableFunctions] = useDatatableState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const skipKindyForAllQuery = businessId == null || businessId === '' || year !== 2023;
  const skipFreeKindyQuery = businessId == null || businessId === '' || year < 2024;

  const {
    data: data,
    loading,
    refetch: refetchKindyForAllChildSummaries,
  } = useGetKindyForAllAcquittalChildSummaries({
    variables: {
      input: {
        centerIds: centerFilterIds ?? [],
        year,
        period,
        searchKey: searchTerm,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortBy: sort.field,
        sortDirection: sort.direction,
      },
    },
    skip: skipKindyForAllQuery,
  });

  const {
    data: freeKindyData,
    loading: freeKindyLoading,
    refetch: refetchFreeKindyChildSummaries,
  } = useGetFreeKindyAcquittalChildSummariesQuery({
    variables: {
      input: {
        centerIds: centerFilterIds ?? [],
        year,
        period,
        searchKey: searchTerm,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortBy: sort.field,
        sortDirection: sort.direction == 'ASCENDING' ? SortDirection.Ascending : SortDirection.Descending,
      },
    },
    skip: skipFreeKindyQuery,
  });

  const clearAppliedFilters = useCallback(() => {
    dispatch(setCurrentCenterFilters([]));
    // Reset paging when changing filters
    tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.PAGE_SIZE);
  }, [dispatch, tableFunctions]);

  const getStatus = (status: Maybe<string> | undefined) => {
    switch (status) {
      case 'Errored':
        return <Chip text={status} type="danger" />;

      case 'Submitted':
        return <Chip text={status} type="success" />;

      case 'Unsubmitted':
        return <Chip text={status} type="info" />;

      case 'Processing':
        return <Chip text={status} type="warning" />;

      default:
        return status;
    }
  };

  const getTableColumns = useCallback((): any[] => {
    const formatCurrency = (value?: number | Maybe<number>) => {
      return value ? <Currency display="DirectedNumbers" aggregate amount={value} /> : '-';
    };

    const tableColumns: any = [
      {
        dataField: 'centerName',
        text: 'Centre', // Do we care about translations here? This is an AU only page
        sort: true,
      },
      {
        dataField: 'childName',
        text: 'Child',
        sort: true,
      },
      {
        dataField: 'year',
        text: 'Year',
        sort: true,
      },
      {
        dataField: 'period',
        text: 'Term',
        sort: true,
      },
    ];

    if (year < 2024) {
      tableColumns.push(
        ...[
          {
            dataField: 'kindyFtb',
            text: 'Kindy FTB',
            sort: false,
            formatter: (kindyFtb: boolean) => (kindyFtb ? 'Yes' : 'No'),
          },
          {
            dataField: 'kfsPlus',
            text: 'Kindy Plus',
            sort: false,
            formatter: (kfsPlus: boolean) => (kfsPlus ? 'Yes' : 'No'),
          },
          {
            dataField: 'status',
            text: 'Status',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalChildSummary) => {
              return getStatus(row.status);
            },
          },
          {
            dataField: 'forecastedAmount',
            text: 'Forecast ($)',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalChildSummary) =>
              row.forecastedAmount ? <Currency direction="Positive" amount={row.forecastedAmount} /> : '-',
          },
          {
            dataField: 'acquittedAmount',
            text: 'Acquitted ($)',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalChildSummary) =>
              row.acquittedAmount ? <Currency direction="Positive" amount={row.acquittedAmount} /> : '-',
          },
          {
            text: 'Difference ($)',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalChildSummary) =>
              row.acquittedAmount && row.forecastedAmount ? (
                <Currency amount={row.acquittedAmount - row.forecastedAmount} />
              ) : (
                '-'
              ),
          },
          {
            dataField: 'paidToFamiliesAmount',
            text: 'Paid to Families ($)',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalChildSummary) =>
              row.paidToFamiliesAmount ? <Currency direction="Positive" amount={row.paidToFamiliesAmount} /> : '-',
          },
        ]
      );
    }

    if (year >= 2024) {
      tableColumns.push(
        ...[
          {
            dataField: 'status',
            text: 'Status',
            sort: false,
            formatter: (cell: any, row: IKindyForAllAcquittalChildSummary) => {
              return getStatus(row.status);
            },
          },
          {
            dataField: 'forecastedAmount',
            text: 'Forecast',
            sort: false,
            formatter: (cell: any, row: FreeKindyAcquittalChildSummaryDto) => formatCurrency(row.forecastedAmount),
          },
          {
            dataField: 'acquittedAmount',
            text: 'Acquittal Adjustment',
            sort: false,
            formatter: (cell: any, row: FreeKindyAcquittalChildSummaryDto) => formatCurrency(row.acquittedAmount),
          },
          {
            dataField: 'actualAmount',
            text: 'Total',
            sort: false,
            formatter: (cell: any, row: FreeKindyAcquittalChildSummaryDto) => formatCurrency(row.actualAmount),
          },
          {
            dataField: 'paidToFamiliesAmount',
            text: 'Paid to Families',
            sort: false,
            formatter: (cell: any, row: FreeKindyAcquittalChildSummaryDto) => formatCurrency(row.paidToFamiliesAmount),
          },
        ]
      );
    }

    return tableColumns;
  }, [year]);

  const handleCenterFilter = useCallback(
    (centers: ITableFilterOption[]) => dispatch(setCurrentCenterFilters(centers.map((c) => c.value))),
    [dispatch]
  );

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSort({ field, direction });
  }, []);

  const dataTableProps =
    year < 2024
      ? {
          data: data?.getKindyForAllAcquittalChildSummaries.data ?? [],
          dataSize: data?.getKindyForAllAcquittalChildSummaries?.totalRecords ?? 0,
        }
      : {
          data: freeKindyData?.getFreeKindyAcquittalChildSummaries.data ?? [],
          dataSize: freeKindyData?.getFreeKindyAcquittalChildSummaries?.totalRecords ?? 0,
        };

  return (
    <DataTable
      {...dataTableProps}
      columns={getTableColumns()}
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      showLoadingOverlay={loading || freeKindyLoading}
      showSelect={false}
      onSort={handleSort}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      renderHeader={(paginationProps) => (
        <>
          <TableHeader className="flex-wrap align-items-center">
            <div className="d-flex flex-wrap mr-auto align-items-center">
              <SizePerPage paginationProps={paginationProps} />
              <TableSearch placeholder="Search" onChange={setSearchTerm} className={isMobile ? 'mt-2 mb-1' : ''} />
            </div>
            <div className="d-flex flex-wrap mr-auto align-items-center">
              <PeriodPicker
                onChange={() => {
                  // Reset paging when changing filters
                  tableFunctions.changePage(TABLE_DEFAULTS.PAGE, TABLE_DEFAULTS.PAGE_SIZE);
                }}
              />
            </div>
            <div className={isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex align-items-center'}>
              <DropdownFilter
                title="Centre"
                className="mr-4"
                selectedFilters={centerFilterIds}
                options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
                onFilterSelect={handleCenterFilter}
              />

              <IconButtonCircle
                icon={faTimes}
                onClick={clearAppliedFilters}
                tooltipDirection="bottom"
                tooltipText="Clear Filters"
              />
            </div>
          </TableHeader>
        </>
      )}
    />
  );
};

export default ChildAcquittalsTab;
