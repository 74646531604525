import moment from 'moment';
import { PaymentMethodType } from 'shared/services/payrixService';

const getIsCardNumberLengthValid = (card: ICreditCardInput): boolean => {
  switch (card.method) {
    case PaymentMethodType.AMEX:
      return card.number.length === 15;
    case PaymentMethodType.VISA:
      return [13, 16, 19].includes(card.number.length);
    case PaymentMethodType.DISCOVER:
      return [16, 19].includes(card.number.length);
    default:
      return card.number.length === 16;
  }
};

export const getCardNumberFormat = (card: ICreditCardInput): string => {
  switch (card.method) {
    case PaymentMethodType.AMEX:
      return '#### ###### #####';
    case PaymentMethodType.VISA:
      return '#### #### #### #### ###';
    case PaymentMethodType.DISCOVER:
      return '#### #### #### #### ###';
    default:
      return '#### #### #### ####';
  }
};

export const getCardValidation: (c: ICreditCardInput) => ICardValidation = (card) => {
  const isAmex = card.method === PaymentMethodType.AMEX || card.method === 'AMEX';
  const isNumberLengthValid = getIsCardNumberLengthValid(card);
  const isExpirationDateValid =
    moment(card.expiration, 'MMYY').isValid() &&
    moment(card.expiration, 'MMYY').endOf('month').isSameOrAfter(moment().endOf('month'));
  const isCvsValid = card.ccv.length === (isAmex ? 4 : 3);
  return {
    isCardPotentiallyValid: Boolean(card.name && isNumberLengthValid && isExpirationDateValid && isCvsValid),
    isNumberLengthValid,
    isExpirationDateValid,
    isCvsValid,
  };
};
