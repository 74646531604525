import React from 'react';
import { default as CommonCheckbox } from 'shared/components/Checkbox';
import { ICustomFieldProps } from '../CustomField';

const Checkbox: React.FC<ICustomFieldProps> = ({ customField, onChange, value, isReadOnly, disabled }) => {
  return (
    <CommonCheckbox
      label={customField.label}
      className="mb-4"
      value={value?.toLowerCase() === 'true'}
      disabled={isReadOnly || disabled}
      onChange={(value) => onChange(value.toString(), customField.id)}
    />
  );
};
export default Checkbox;
