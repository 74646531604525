import { gql, QueryHookOptions } from '@apollo/client';
import { getAccountsSuccess } from 'pages/Families/subroutes/Accounts/duck/actions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'shared/apis/core';
import {
  accountAndCenterFields,
  accountFields,
  accountPaymentMethodFields,
  accountsTableFields,
  centerAccountAndChildrenFields,
  sortedAccountsPagedResultFields,
} from './fields';

interface IGetAccountsForCenterData {
  getAccountsForCenter: IAccount[];
}

interface IGetAccountsForCenterVariables {
  centerId: string;
}

interface ISearchAccountsVariables {
  input: IElasticsearchQuery;
}

interface ISearchAccountsData {
  searchAccounts: {
    totalResults: number;
    data: IAccount[];
  };
}

interface IGetAccountByIdData {
  getAccountById: IAccount;
}

interface IGetAccountByIdVariables {
  id: string;
}

interface IGetAccountTagOptionsData {
  getEntity: IEntity;
  getAccountTagOptions: ITag[];
}

export interface IGetSortedAccountsData {
  getSortedAccounts: IPagedResult<IAccount>;
}

export interface IGetSortedAccountsVariables {
  input: {
    from: number;
    size: number;
    centerIds: string[];
    sort: ISearchSort[];
    searchKey: string;
    statusType?: AccountStatusType;
    statusAtDate?: string;
    tagIds?: string[];
    centreTagIds?: string[];
  };
}

interface ISearchSort {
  field: string;
  direction: SortDirection;
}

interface IGetAccountContactPermissionsData {
  getAccountContactPermissions: IAccountContactPermissionsObject[];
}

interface IGetAccountContactPermissionsVariables {}

type SortDirection = 'ASCENDING' | 'DESCENDING';

export const GET_ACCOUNTS_FOR_CENTER = (fields: string = centerAccountAndChildrenFields) => gql`
  query ($centerId: ID!) {
    getAccountsForCenter(centerId: $centerId) {
      ${fields}
    }
  }
`;

export const GET_ACCOUNTS_FOR_CHILD = (fields: string = accountAndCenterFields) => gql`
  query ($id: ID!) {
    getAccountsForChild(id: $id) {
      ${fields}
    }
  }
`;

export const SEARCH_ACCOUNTS = (fields: string = accountFields) => gql`
  query($input: SearchInput!) {
    searchAccounts(input: $input) {
      totalResults
      data {
        ${fields}
      }
    }
  }
`;

export const GET_ACCOUNT_BY_ID = (fields: string = accountFields) => gql`
  query($id: ID!) {
    getAccountById(id: $id) {
      ${fields}
    }
  }
`;

export const GET_DOES_CHILD_HAVE_HISTORY_ON_ACCOUNT = () => gql`
  query ($childId: ID!, $accountId: ID!) {
    getDoesChildHaveHistoryOnAccount(childId: $childId, accountId: $accountId)
  }
`;

export const GET_SORTED_ACCOUNTS = (fields: string = sortedAccountsPagedResultFields) => gql`
  query($input: AccountSearchInput!) {
    getSortedAccounts(input: $input) {
      ${fields}
    }
  }
`;

export const GET_ACCOUNT_CONTACT_PERMISSIONS = gql`
  query {
    getAccountContactPermissions {
      label
      value
    }
  }
`;

export const GET_ACCOUNT_TAG_OPTIONS = gql`
  query ($entityId: String!) {
    getEntity(id: $entityId) {
      id
      tags {
        id
        name
        category
      }
    }
  }
`;

export const useGetAccountTableData = (input: IElasticsearchQuery) => {
  const dispatch = useDispatch();
  return useQuery<ISearchAccountsData, ISearchAccountsVariables>(SEARCH_ACCOUNTS(accountsTableFields), {
    variables: { input },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      dispatch(getAccountsSuccess(data.searchAccounts.data, data.searchAccounts.totalResults));
    },
  });
};

export const useGetCenterAccountsWithChildren = (
  options?: QueryHookOptions<IGetAccountsForCenterData, IGetAccountsForCenterVariables>,
  fields?: string
) => useQuery<IGetAccountsForCenterData, IGetAccountsForCenterVariables>(GET_ACCOUNTS_FOR_CENTER(fields), options);

export const useSearchAccounts = (
  options?: QueryHookOptions<ISearchAccountsData, ISearchAccountsVariables>,
  fields?: string
) => useQuery<ISearchAccountsData, ISearchAccountsVariables>(SEARCH_ACCOUNTS(fields), options);

export const useGetPaymentMethodsForAccount = (id: string) =>
  useQuery<IGetAccountByIdData, IGetAccountByIdVariables>(GET_ACCOUNT_BY_ID(accountPaymentMethodFields), {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    skip: id === '',
  });

export const useGetAccountsForChild = (id: string) =>
  useQuery<{ getAccountsForChild: IAccount[] }, { id: string }>(GET_ACCOUNTS_FOR_CHILD(), {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

export const useGetDoesChildHaveHistoryOnAccount = (childId: string, accountId: string) =>
  useQuery<{ getDoesChildHaveHistoryOnAccount: boolean }, { childId: string; accountId: string }>(
    GET_DOES_CHILD_HAVE_HISTORY_ON_ACCOUNT(),
    {
      variables: { childId, accountId },
      fetchPolicy: 'cache-and-network',
    }
  );

export const useGetSortedAccounts = (
  options?: QueryHookOptions<IGetSortedAccountsData, IGetSortedAccountsVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useQuery<IGetSortedAccountsData, IGetSortedAccountsVariables>(GET_SORTED_ACCOUNTS(fields), {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(getAccountsSuccess(data.getSortedAccounts.data, data.getSortedAccounts.totalRecords));
    },
    ...options,
  });
};

export const useGetSortedAccountsNoStateUpdate = (
  options?: QueryHookOptions<IGetSortedAccountsData, IGetSortedAccountsVariables>,
  fields?: string
) => useQuery<IGetSortedAccountsData, IGetSortedAccountsVariables>(GET_SORTED_ACCOUNTS(fields), options);

export const useGetAccountTagOptions = (entityId: string) => {
  const response = useQuery<IGetAccountTagOptionsData, { entityId: string }>(GET_ACCOUNT_TAG_OPTIONS, {
    variables: {
      entityId,
    },
  });

  if (response.data) {
    const tags: ITag[] = response.data.getEntity.tags;
    const accountTags = tags.filter((tag) => tag.category === 'ACCOUNT');

    return {
      ...response,
      data: {
        getAccountTagOptions: accountTags,
      },
    };
  }

  return response;
};

export const useGetAccountContactPermissions = (
  options?: QueryHookOptions<IGetAccountContactPermissionsData, IGetAccountContactPermissionsVariables>
) =>
  useQuery<IGetAccountContactPermissionsData, IGetAccountContactPermissionsVariables>(
    GET_ACCOUNT_CONTACT_PERMISSIONS,
    options
  );
