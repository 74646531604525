import React from 'react';
import classnames from 'classnames';
import Navbar from 'react-bootstrap/Navbar';
import Logo from 'shared/components/Logo/Logo';
import { IRoute } from 'shared/util/routes';
import K2SidebarItem from './K2SidebarItem';

interface IProps {
  isExpanded: boolean;
  sidebarRoutes: IRoute[];
  navigateTo: (route: IRoute) => void;
  expandSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const K2Sidebar: React.FC<IProps> = ({ isExpanded, sidebarRoutes, navigateTo, expandSidebar, ...props }) => {
  const sidebarClassNames = classnames({
    'd-none': true,
    'd-lg-block': true,
    'd-xl-block': true,
    'k2-navigation-sidebar': true,
    'k2-navigation-sidebar-expanded': isExpanded,
    'k2-navigation-sidebar-collapsed': !isExpanded,
  });

  const logoContainerClasses = classnames({
    'k2-navigation-sidebar-logo': true,
    'd-flex': true,
    'align-items-center': true,
    'justify-content-center': !isExpanded,
    'px-2': isExpanded,
  });

  return (
    <Navbar className={sidebarClassNames}>
      <div className={logoContainerClasses}>
        <Logo type="icon-white" size="icon" />
        {isExpanded && <span className="d-block ml-4">Kangarootime</span>}
      </div>
      <div className="d-flex flex-column">
        {sidebarRoutes.map((route: IRoute, idx: number) => (
          <K2SidebarItem
            key={`sidebar-route-${route.name}-${idx}`}
            route={route}
            sidebarExpanded={isExpanded}
            navigateTo={navigateTo}
            expandSidebar={expandSidebar}
          />
        ))}
      </div>
    </Navbar>
  );
};

export default K2Sidebar;
