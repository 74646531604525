import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDeleteChildContactRelationship } from 'gql/contact/mutations';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { removeChildContactRelationship } from 'pages/Families/subroutes/Children/duck/actions';
import { sortBy } from 'lodash';
import Checkbox from 'shared/components/Checkbox';

interface IProps {
  isOpen: boolean;
  defaultAccountIdToRemove?: string;
  contactId: string;
  childId: string;
  contactName: string;
  childName: string;
  accountPermissions: IAccountPermissions[];
  onClose: () => void;
}

const RemoveContactRelationshipModal: React.FC<IProps> = ({
  isOpen,
  childId,
  childName,
  contactId,
  contactName,
  defaultAccountIdToRemove,
  accountPermissions,
  onClose,
  ...props
}) => {
  const [accountIdsRemoveFrom, setAccountIdsToRemoveFrom] = useState(
    defaultAccountIdToRemove ? [defaultAccountIdToRemove] : []
  );
  const accounts = accountPermissions.map((a) => a.account);
  const accountPermissionsMap: Record<string, IAccountPermissions> = accountPermissions.reduce(
    (acc, curr) => ({ ...acc, [curr.accountId]: curr }),
    {}
  );
  const currentAccount = accounts.find((a) => a.id === defaultAccountIdToRemove);
  const otherAccountsToRemove = accounts.filter((a) => a.id !== defaultAccountIdToRemove);

  const dispatch = useDispatch();
  const [deleteChildContactRelationshipFn, { loading }] = useDeleteChildContactRelationship({
    onCompleted: (result) => {
      showToast('Successfully removed contact from child.', 'success');
      dispatch(removeChildContactRelationship(childId, contactId));
      onClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  return (
    <ConfirmationModal
      title="Remove Contact from Child?"
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Remove"
      primaryCallback={() =>
        deleteChildContactRelationshipFn({
          variables: {
            childId: childId,
            contactId: contactId,
            accountIds: accountIdsRemoveFrom,
          },
        })
      }
      primaryButtonProps={{ variant: 'danger', loading, disabled: !accountIdsRemoveFrom.length }}
    >
      {defaultAccountIdToRemove ? (
        <>
          <p>
            You are about to remove {contactName}'s relationship with {childName} on the {currentAccount?.name} account.
          </p>
          <p>By doing so all related permissions this contact has to this child on this account will be terminated.</p>
          {otherAccountsToRemove.length > 0 && (
            <div>
              <h5>Remove this relationship on other accounts as well?</h5>
              <ul className="unstyled-list">
                {sortBy(otherAccountsToRemove, (acc) => acc.name).map((account: IAccount, idx: number) => (
                  <li className="mb-4 d-flex" key={`child-contact-account-${account.id}-${idx}`}>
                    <Checkbox
                      value={Boolean(accountIdsRemoveFrom.includes(account.id))}
                      onChange={(checked) =>
                        setAccountIdsToRemoveFrom(
                          checked
                            ? [...accountIdsRemoveFrom, account.id]
                            : accountIdsRemoveFrom.filter((id) => id !== account.id)
                        )
                      }
                    />
                    <div className="d-flex flex-column ml-2 text-truncate">{account.name}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <>
          <p>
            You are about to remove {contactName}'s relationship with {childName}.
          </p>
          <p>
            By doing so all related permissions this contact has to this child on the selected accounts will be
            terminated.
          </p>
          {otherAccountsToRemove.length > 0 && (
            <div>
              <h5>Remove this relationship on the following accounts:</h5>
              <ul className="unstyled-list">
                {sortBy(otherAccountsToRemove, (acc) => acc.name).map((account: IAccount, idx: number) => (
                  <li className="mb-4 d-flex" key={`child-contact-account-${account.id}-${idx}`}>
                    <Checkbox
                      disabled={accountPermissionsMap[account.id].isPrimary}
                      value={Boolean(accountIdsRemoveFrom.includes(account.id))}
                      onChange={(checked) =>
                        setAccountIdsToRemoveFrom(
                          checked
                            ? [...accountIdsRemoveFrom, account.id]
                            : accountIdsRemoveFrom.filter((id) => id !== account.id)
                        )
                      }
                    />
                    <div>
                      <div className="d-flex flex-column ml-2 text-truncate">{account.name}</div>
                      {accountPermissionsMap[account.id].isPrimary && (
                        <small>
                          {contactName} is a primary contact on this account.{' '}
                          <Link to={`/families/accounts/${account.id}/contacts`}>Go to the account profile</Link> and
                          make them a non-primary contact in order to remove this relationship.
                        </small>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </ConfirmationModal>
  );
};

export default RemoveContactRelationshipModal;
