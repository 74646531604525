import React, { useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import PageWrapper from 'shared/components/PageWrapper';
import { useTranslation } from 'react-i18next';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import DateInput from 'shared/components/DateInput';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import ActionDropdown from 'shared/components/ActionDropdown';
import { useAgencyTableFilters } from './TableFilters';
import { IAgencySubsidyPayment, useGetAgencyPayments } from './query';
import DeleteAgencyPaymentModal from './components/DeleteAgencyPaymentModal';
import { useDeleteAgencyPayment } from './components/useDeleteAgencyPayment';

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const AgencyPayments: React.FC<IProps> = () => {
  const { t } = useTranslation(['subsidies']);
  const history = useHistory();

  const hasCreateAgencyBillingPaymentPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Create,
  });

  // Filter Controls
  const {
    agencyOptions,
    centerOptions,
    statusOptions,
    handleClearSearchCritieria,
    handleChangeSearchCriteria,
    handlePageChange,
    handlePageSizeChange,
    searchCriteria,
  } = useAgencyTableFilters();

  const { agencyPayments, isLoading, loadData } = useGetAgencyPayments(searchCriteria);

  const { showDeleteModal, handleDeleteButton, hideModal, deleteAgencySubsidyPayment } =
    useDeleteAgencyPayment(loadData);

  const handleOnCreateClick = useCallback(() => {
    history.push('/subsidies/payments/create-payment');
  }, [history]);

  const handleViewEditButton = useCallback(
    (paymentId: string) => {
      history.push(`/subsidies/payments/${paymentId}`);
    },
    [history]
  );

  return (
    <PageWrapper
      pageTitle={t('subsidies:agency-payments.page-title')}
      mobileButtonComponent={<CirclePlusButton variant="primary" className="my-4" onClick={handleOnCreateClick} />}
      buttonComponent={
        hasCreateAgencyBillingPaymentPermission && (
          <CreateButton id="create-agency-payment-btn" onClick={handleOnCreateClick}>
            {t('subsidies:agency-payments.create-button-text')}
          </CreateButton>
        )
      }
    >
      <DataTable
        noPadding
        showPagination
        data={agencyPayments}
        dataSize={agencyPayments.length}
        showSelect={false}
        pageSize={searchCriteria.pageSize}
        activePage={searchCriteria.pageNumber}
        onPageChange={handlePageChange}
        onSizePerPageChange={handlePageSizeChange}
        handleRowClick={(event, rowData) => handleViewEditButton(rowData.id)}
        showLoadingOverlay={isLoading}
        noDataText="There are no agency payments available to display."
        columns={[
          {
            text: t('subsidies:agency-payments.agency'),
            dataField: 'agencyName',
            sort: true,
          },
          {
            text: t('subsidies:agency-payments.center', {}),
            dataField: 'centerName',
            sort: true,
            formatter: (cell: string, row: IAgencySubsidyPayment) =>
              row.centers.length > 4 ? row.centers.length + ' Centers' : row.centers.map((c) => c.name).join(', '),
          },
          {
            text: t('subsidies:agency-payments.payment-date'),
            dataField: 'paymentDate',
            sort: true,
          },
          {
            text: t('subsidies:agency-payments.reference-number'),
            dataField: 'referenceId',
            sort: true,
            formatter: (cell: string, row: { referenceId: string }) =>
              `${Boolean(row.referenceId) ? row.referenceId : '--'}`,
          },
          {
            text: t('subsidies:agency-payments.status'),
            dataField: 'status',
            sort: true,
          },
          {
            text: '',
            dataField: '',
            formatter: (cell, row: { id: string }) => (
              <ActionDropdown
                actions={[
                  { label: 'View / Edit', onClick: () => handleViewEditButton(row.id) },
                  { label: 'Delete', onClick: () => handleDeleteButton(row.id) },
                ]}
              />
            ),
          },
        ]}
        renderHeader={(paginationProps) => (
          <div className="align-items-center">
            <TableHeader className="flex-wrap">
              <SizePerPage paginationProps={paginationProps} />
              <TableSearch onChange={(term) => handleChangeSearchCriteria('searchTerm', term)} className="mr-4" />
              <div className="d-flex align-items-center">
                <DateInput
                  date={searchCriteria.startDate}
                  onDateSelect={(start) => handleChangeSearchCriteria('startDate', start)}
                  className="flex-grow-0 mb-0"
                />
                <div className="mx-2">to</div>
                <DateInput
                  date={searchCriteria.endDate}
                  onDateSelect={(end) => handleChangeSearchCriteria('endDate', end)}
                  className="mr-4 flex-grow-0 mb-0"
                />
              </div>
              <div className="d-flex align-items-center ml-auto">
                <DropdownFilter
                  title="Status"
                  className="mr-4"
                  selectedFilters={searchCriteria.status}
                  options={statusOptions}
                  onFilterSelect={(statuses) => handleChangeSearchCriteria('status', statuses.map(optionToValueMap))}
                />
                <DropdownFilter
                  title="Center"
                  className="mr-4"
                  selectedFilters={searchCriteria.centerIds}
                  options={centerOptions}
                  onFilterSelect={(selectedCenterFilterOptions) =>
                    handleChangeSearchCriteria('centerIds', selectedCenterFilterOptions.map(optionToValueMap))
                  }
                />
                <DropdownFilter
                  title="Agency"
                  className="mr-4"
                  selectedFilters={searchCriteria.agencyIds}
                  options={agencyOptions}
                  onFilterSelect={(agencies) => handleChangeSearchCriteria('agencyIds', agencies.map(optionToValueMap))}
                />
                <IconButtonCircle
                  icon={faTimes}
                  onClick={handleClearSearchCritieria}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                />
              </div>
            </TableHeader>
          </div>
        )}
      />

      <DeleteAgencyPaymentModal
        isOpen={showDeleteModal}
        onClose={hideModal}
        deleteAgencyPayment={deleteAgencySubsidyPayment}
      />
    </PageWrapper>
  );
};

export default AgencyPayments;

function optionToValueMap(o: ISelectMenuItem): ISelectMenuItem['value'] {
  return o.value;
}
