import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { defaultNoteFields } from './fields';

// default note types
type DefaultNoteCategories =
  | 'General'
  | 'Conflict'
  | 'Concern'
  | 'Development'
  | 'Education'
  | 'Behavior'
  | 'Attendance'
  | 'Billing'
  | 'Discipline'
  | 'Coaching'
  | 'PerformanceReview'
  | 'Communication'
  | 'Recognition';

type NoteAreas = 'Account';

type NoteAssociationObjectType = 'Account' | 'AccountChild' | 'AccountContact' | 'Staff';

// thinking for when we allow custom note types, this type can then be expanded upon
type NoteCategoryType = DefaultNoteCategories;

interface INote {
  id: string;
  businessId: string;
  areaType: string;
  attachedToEntityId: string;
  title: string;
  noteType: string;
  comment: string;
  associatedWith: INoteAssociation[];
  createdBy: string;
  createdAt: string;
  createdByAccount: IStaff;
  lastCommentedAt: string;
  lastCommentedByAccount: IStaff;
}

interface INoteAssociation {
  objectId: string;
  objectType: NoteAssociationObjectType;
  display?: {
    displayValue: string;
    displayAvatar?: string;
  };
}

type SortDirection = 'ASCENDING' | 'DESCENDING';

interface ISearchNoteInput {
  input: {
    isArchived?: boolean;
    businessId: string;
    areaType: string;
    attachedToEntityId: string;
    size?: number;
    from?: number;
    sortDirection?: SortDirection;
    sortField?: string;
  };
}

interface IPagedListNotes {
  totalRecords?: number;
  pageNumber?: number;
  pageSize?: number;
  data: INote[];
}

export const SEARCH_NOTES = gql`
  query ($input: SearchNoteInput!) {
    searchNote(input: $input) {
      totalRecords
      pageNumber
      pageSize
      data {
        ${defaultNoteFields}
      }
    }
  }
`;

export const useSearchNotes = (options?: QueryHookOptions<{ searchNote: IPagedListNotes }, ISearchNoteInput>) =>
  useQuery(SEARCH_NOTES, {
    fetchPolicy: 'network-only',
    ...options,
  });
