import { CommsBusinessSetting } from 'generated/graphql';
import { CommsActionTypes, GET_COMMS_BUSINESS_SETTINGS_SUCCESS, GET_USES_COMMS_SUCCESS } from './types';

export interface ICommsStateShape {
  usesComms: boolean;
  all: CommsBusinessSetting | null | undefined;
}

const initialState: ICommsStateShape = {
  usesComms: false,
  all: undefined,
};

export const commsReducers = (state: ICommsStateShape = initialState, action: CommsActionTypes): ICommsStateShape => {
  switch (action.type) {
    case GET_COMMS_BUSINESS_SETTINGS_SUCCESS:
      return { ...state, all: action.businessSettings };
    case GET_USES_COMMS_SUCCESS:
      return { ...state, usesComms: action.usesComms };
    default:
      return state;
  }
};
