/**
 * convert a regulation's age to the number of days for table sorting
 * since this is generalized and only used for sorting, it assumes 1 month = 30 days
 */
export const ageToNumberOfDays = (regulationAge: IRegulationAge): number => {
  const { age, unit } = regulationAge;

  switch (unit) {
    case 'YEAR':
      return age * 365;
    case 'MONTH':
      return age * 30;
    case 'WEEK':
      return age * 7;
    default:
      return age;
  }
};
