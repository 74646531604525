import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { agencyFields, agencyAccountChildFields, agencyPaymentFields } from 'gql/agency/fields';

interface ICreateAgencyData {
  createAgency: IAgency;
}

interface ICreateAgencyVariables {
  input: ICreateAgencyInput;
}

interface IUpdateAgencyData {
  updateAgency: IAgency;
}

interface IUpdateAgencyVariables {
  input: IUpdateAgencyInput;
}

interface IArchiveAgencyData {
  archiveAgency: IAgency;
}

interface IArchiveAgencyVariables {
  id: string;
}

interface ICreateAgencyAccountChildData {
  createAgencyAccountChild: IAgencyAccountChild;
}

interface ICreateAgencyAccountChildVariables {
  input: ICreateAgencyAccountChildInput;
}

interface IUpdateAgencyAccountChildData {
  updateAgency: IAgencyAccountChild;
}

interface IUpdateAgencyAccountChildVariables {
  input: IUpdateAgencyAccountChildInput;
}

interface IArchiveAgencyAccountChildData {
  archiveAgencyAccountChild: IAgencyAccountChild;
}

interface IArchiveAgencyAccountChildVariables {
  id: string;
}

interface IEditChildAgencyEnrollmentData {
  updateAgencyAccountChildEnrollment: IAgencyAccountChild;
}

interface IEditChildAgencyEnrollmentVariables {
  input: {
    agencyAccountChildEnrollmentId: string;
    endDate: string;
    excludedContractIds: string[];
    startDate: string;
    subsidyPaymentPortion: number;
    ignoreCopay: boolean;
    notes: string | null;
  };
}

interface ICreateAgencyPaymentData {
  createAgencyPayment: IAgencyPayment;
}

interface ICreateAgencyPaymentVariables {
  input: ICreateAgencyPaymentInput;
}

interface IUpdateAgencyPaymentData {
  updateAgencyPayment: IAgencyPayment;
}

interface IUpdateAgencyPaymentVariables {
  input: IUpdateAgencyPaymentInput;
}

interface IDeleteAgencyPaymentData {
  deleteAgencyPayment: IAgencyPayment;
}

interface IDeleteAgencyPaymentVariables {
  id: string;
}

export const CREATE_AGENCY = (fields: string = agencyFields) => gql`
  mutation ($input: CreateAgencyInput!) {
    createAgency(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_AGENCY = (fields: string = agencyFields) => gql`
  mutation ($input: UpdateAgencyInput!) {
    updateAgency(input: $input) {
      ${fields}
    }
  }
`;

export const ARCHIVE_AGENCY = (fields: string = agencyFields) => gql`
  mutation ($id: ID!) {
    archiveAgency(id: $id) {
      ${fields}
    }
  }
`;

export const CREATE_AGENCY_ACCOUNT_CHILD = (fields: string = agencyAccountChildFields) => gql`
  mutation($input: CreateAgencyAccountChildInput!) {
    createAgencyAccountChild(input: $input) {
      ${fields}
    }
  }
`;
export const UPDATE_AGENCY_ACCOUNT_CHILD = (fields: string = agencyAccountChildFields) => gql`
  mutation ($input: UpdateAgencyAccountChildInput!) {
    updateAgencyAccountChild(input: $input) {
      ${fields}
    }
  }
`;

export const EDIT_CHILD_AGENCY_ENROLLMENT = (fields: string = agencyAccountChildFields) => gql`
  mutation($input: UpdateAgencyAccountChildEnrollmentInput!) {
    updateAgencyAccountChildEnrollment(input: $input) {
      ${fields}
    }
  }
`;

export const ARCHIVE_AGENCY_ACCOUNT_CHILD = (fields: string = agencyAccountChildFields) => gql`
  mutation ($id: ID!) {
    archiveAgencyAccountChild(id: $id) {
      ${fields}
    }
  }
`;

export const CREATE_AGENCY_PAYMENT = (fields: string = agencyPaymentFields) => gql`
  mutation($input: CreateAgencyPaymentInput!) {
    createAgencyPayment(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_AGENCY_PAYMENT = (fields: string = agencyPaymentFields) => gql`
  mutation($input: UpdateAgencyPaymentInput!) {
    updateAgencyPayment(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_AGENCY_PAYMENT = (fields: string = agencyPaymentFields) => gql`
  mutation($id: ID!) {
    deleteAgencyPayment(id: $id) {
      ${fields}
    }
  }
`;

export const useCreateAgency = (
  options?: MutationHookOptions<ICreateAgencyData, ICreateAgencyVariables>,
  fields?: string
) => useMutation<ICreateAgencyData, ICreateAgencyVariables>(CREATE_AGENCY(fields), options);

export const useUpdateAgency = (
  options?: MutationHookOptions<IUpdateAgencyData, IUpdateAgencyVariables>,
  fields?: string
) => useMutation<IUpdateAgencyData, IUpdateAgencyVariables>(UPDATE_AGENCY(fields), options);

export const useArchiveAgency = (
  options?: MutationHookOptions<IArchiveAgencyData, IArchiveAgencyVariables>,
  fields?: string
) => useMutation<IArchiveAgencyData, IArchiveAgencyVariables>(ARCHIVE_AGENCY(fields), options);

export const useAssignChildToAgency = (
  options?: MutationHookOptions<ICreateAgencyAccountChildData, ICreateAgencyAccountChildVariables>,
  fields?: string
) =>
  useMutation<ICreateAgencyAccountChildData, ICreateAgencyAccountChildVariables>(
    CREATE_AGENCY_ACCOUNT_CHILD(fields),
    options
  );

export const useEditChildAgencyEnrollment = (
  options?: MutationHookOptions<IEditChildAgencyEnrollmentData, IEditChildAgencyEnrollmentVariables>,
  fields?: string
) =>
  useMutation<IEditChildAgencyEnrollmentData, IEditChildAgencyEnrollmentVariables>(
    EDIT_CHILD_AGENCY_ENROLLMENT(fields),
    options
  );

export const useUpdateAgencyAccountChild = (
  options?: MutationHookOptions<IUpdateAgencyAccountChildData, IUpdateAgencyAccountChildVariables>,
  fields?: string
) =>
  useMutation<IUpdateAgencyAccountChildData, IUpdateAgencyAccountChildVariables>(
    UPDATE_AGENCY_ACCOUNT_CHILD(fields),
    options
  );

export const useArchiveAgencyAccountChild = (
  options?: MutationHookOptions<IArchiveAgencyAccountChildData, IArchiveAgencyAccountChildVariables>,
  fields?: string
) =>
  useMutation<IArchiveAgencyAccountChildData, IArchiveAgencyAccountChildVariables>(
    ARCHIVE_AGENCY_ACCOUNT_CHILD(fields),
    options
  );

export const useCreateAgencyPayment = (
  options?: MutationHookOptions<ICreateAgencyPaymentData, ICreateAgencyPaymentVariables>,
  fields?: string
) => useMutation<ICreateAgencyPaymentData, ICreateAgencyPaymentVariables>(CREATE_AGENCY_PAYMENT(fields), options);

export const useUpdateAgencyPayment = (
  options?: MutationHookOptions<IUpdateAgencyPaymentData, IUpdateAgencyPaymentVariables>,
  fields?: string
) => useMutation<IUpdateAgencyPaymentData, IUpdateAgencyPaymentVariables>(UPDATE_AGENCY_PAYMENT(fields), options);

export const useDeleteAgencyPayment = (
  options?: MutationHookOptions<IDeleteAgencyPaymentData, IDeleteAgencyPaymentVariables>,
  fields?: string
) => useMutation<IDeleteAgencyPaymentData, IDeleteAgencyPaymentVariables>(DELETE_AGENCY_PAYMENT(fields), options);
