import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import { PersonAvatar } from 'shared/components/Avatar';
import cast from 'shared/util/cast';
import moment from 'moment';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import { getFullName } from 'shared/util/string';
import { faArchive, faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import ActionDropdown from 'shared/components/ActionDropdown';
import { Row } from 'shared/components/Layout';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {
  child: IAccountChild;
  className?: string;
  onClick?: () => void;
  onArchive: () => void;
  onRemove: () => void;
  onUnarchive: () => void;
}

const ChildCard: React.FC<IProps> = ({ child, className, onClick, onArchive, onRemove, onUnarchive }) => {
  const { t } = useTranslation();

  const hasDeleteChildPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });

  return (
    <Card
      onClick={onClick}
      className={`${
        child.archivedAt ? 'bg-smoke' : ''
      } text-center d-flex flex-column align-items-center m-0 p-6 ${className}`}
    >
      <div className="w-100">
        <div className="ml-10 d-flex">
          <PersonAvatar person={cast<IPerson>(child)} size="xl" className="mb-4 mx-auto" />
          {!child.archivedAt && hasDeleteChildPermission && (
            <ActionDropdown
              actions={[
                ...(hasDeleteChildPermission
                  ? [
                      {
                        label: 'Archive',
                        onClick: onArchive,
                        icon: faArchive,
                      },
                      {
                        label: 'Remove from account',
                        onClick: onRemove,
                        icon: faMinusCircle,
                      },
                    ]
                  : []),
              ]}
            />
          )}
          {child.archivedAt && hasDeleteChildPermission && (
            <ActionDropdown
              actions={[
                {
                  label: `Unarchive`,
                  onClick: onUnarchive,
                  icon: faArchive,
                },
              ]}
            />
          )}
        </div>
        <h5>{getFullName(child)}</h5>
        {child.archivedAt && (
          <Row noGutters justify="center">
            <div className="tag mb-2">Archived</div>
          </Row>
        )}
        <div>DOB: {moment(child.dob).format(t('formatters.MM/DD/YYYY'))}</div>
        <small>{getAgeStringFromDateOfBirth(moment(child.dob))}</small>
      </div>
    </Card>
  );
};

export default ChildCard;
