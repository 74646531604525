import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';

interface IGetTagsInUseData {
  getTagsUsedAcrossEntity: ITag[];
}

interface IGetTagsInUseVariables {
  elasticIndex: TagsTypeElasticIndex;
}

export const GET_TAGS_USED_FOR_CENTERS = gql`
  query ($elasticIndex: String!) {
    getTagsUsedAcrossEntity(elasticIndex: $elasticIndex) {
      id
      name
      category
    }
  }
`;

export const useGetTagsInUse = (elasticIndex: TagsTypeElasticIndex) =>
  useQuery<IGetTagsInUseData, IGetTagsInUseVariables>(GET_TAGS_USED_FOR_CENTERS, {
    variables: {
      elasticIndex,
    },
    fetchPolicy: 'cache-first',
  });
