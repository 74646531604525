import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'react-bootstrap';
import './form-status.scss';

interface IProps {
  isChanged: boolean;
  isPublished: boolean;
}

export default ({ isChanged, isPublished }: IProps) => {
  const { t } = useTranslation(['enrollment']);
  const props = useMemo(() => {
    if (isChanged) {
      return {
        variant: 'info',
        children: t('enrollment:form.form-status.changed'),
      };
    }
    if (isPublished) {
      return {
        variant: 'success',
        children: t('enrollment:form.form-status.published'),
      };
    }

    return {
      variant: 'danger',
      children: t('enrollment:form.form-status.draft'),
    };
  }, [isChanged, isPublished, t]);

  return <Badge {...props} className="ml-2 form-status" />;
};
