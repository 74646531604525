import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { SEARCH_EXPRESSIONS, PREDICATES, DIRECTIONS } from 'shared/constants/elastic';
import { active, pending, created } from 'shared/constants/StaffStatusSearchExpressions';

interface ISearchActiveStaffForCenterData {
  searchStaff: {
    totalResults: number;
    data: IStaff[];
  };
}

interface ISearchActiveStaffForCenterVariables {
  input: IElasticsearchQuery;
}

export const GET_TAGS_USED_FOR_CENTERS = gql`
  query ($elasticIndex: String!) {
    getTagsUsedAcrossEntity(elasticIndex: $elasticIndex) {
      id
      name
      category
    }
  }
`;

export const useSearchActiveStaffForCenter = (centerId: string, graphqlQuery: any, includeCreated = false) =>
  useQuery<ISearchActiveStaffForCenterData, ISearchActiveStaffForCenterVariables>(graphqlQuery, {
    skip: centerId === '',
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        size: 10000, // assumes a single center doesn't have 10,000 staff + manager + assistant manager
        from: 0,
        sort: [{ field: 'lastname.keyword', direction: DIRECTIONS.ASCENDING }],
        filter: {
          [SEARCH_EXPRESSIONS.ALL]: [
            { [SEARCH_EXPRESSIONS.ANY]: includeCreated ? [created, active, pending] : [active, pending] },
            {
              [SEARCH_EXPRESSIONS.TERM]: {
                field: 'roleship.scopeIds.keyword',
                predicate: PREDICATES.CONTAINS,
                value: centerId,
              },
            },
          ],
        },
      },
    },
  });

export const useSearchActiveStaffForEntity = (entityId: string, graphqlQuery: any, includeCreated = false) =>
  useQuery<ISearchActiveStaffForCenterData, ISearchActiveStaffForCenterVariables>(graphqlQuery, {
    skip: entityId === '',
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        size: 10000, // assumes a single center doesn't have 10,000 staff + manager + assistant manager
        from: 0,
        sort: [{ field: 'lastname.keyword', direction: DIRECTIONS.ASCENDING }],
        filter: {
          [SEARCH_EXPRESSIONS.ALL]: [
            { [SEARCH_EXPRESSIONS.ANY]: includeCreated ? [created, active, pending] : [active, pending] },
            {
              [SEARCH_EXPRESSIONS.TERM]: {
                field: 'entityId.keyword',
                predicate: PREDICATES.EQUALS,
                value: entityId,
              },
            },
          ],
        },
      },
    },
  });

// query for anyone that belongs to a center AND the entity
export const useSearchActiveStaffForCenterAndEntity = (
  centerId: string,
  entityId: string,
  graphqlQuery: any,
  includeCreated = false
) =>
  useQuery<ISearchActiveStaffForCenterData, ISearchActiveStaffForCenterVariables>(graphqlQuery, {
    skip: entityId === '' || centerId === '',
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        size: 10000, // assumes a single center doesn't have 10,000 staff + manager + assistant manager
        from: 0,
        sort: [{ field: 'lastname.keyword', direction: DIRECTIONS.ASCENDING }],
        filter: {
          [SEARCH_EXPRESSIONS.ALL]: [
            { [SEARCH_EXPRESSIONS.ANY]: includeCreated ? [created, active, pending] : [active, pending] },
            {
              [SEARCH_EXPRESSIONS.TERM]: {
                field: 'roleship.scopeIds.keyword',
                predicate: PREDICATES.ONE_OF,
                value: [centerId, entityId],
              },
            },
          ],
        },
      },
    },
  });

export const useSearchActiveStaffScopedToEntity = (entityId: string, graphqlQuery: any, includeCreated = false) =>
  useQuery<ISearchActiveStaffForCenterData, ISearchActiveStaffForCenterVariables>(graphqlQuery, {
    skip: entityId === '',
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        size: 10000, // assumes a single center doesn't have 10,000 staff + manager + assistant manager
        from: 0,
        sort: [{ field: 'lastname.keyword', direction: DIRECTIONS.ASCENDING }],
        filter: {
          [SEARCH_EXPRESSIONS.ALL]: [
            { [SEARCH_EXPRESSIONS.ANY]: includeCreated ? [created, active, pending] : [active, pending] },
            {
              [SEARCH_EXPRESSIONS.TERM]: {
                field: 'roleship.scopeIds.keyword',
                predicate: PREDICATES.ONE_OF,
                value: [entityId],
              },
            },
          ],
        },
      },
    },
  });
