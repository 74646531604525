import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';

import * as Mui from '@mui/material';

import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { useUpdateIntegrationApiKey } from '../../../../gql/integrationApiKey/mutations';
import AddApiKeyWebHookDialog from '../components/AddApiKeyWebHookDialog';
import { ApiScopeAuthType, IApiScopesType } from '../../../../gql/integrationApiKey/queries';
import AddApiKeyDialog from './AddApiKeyDialog';
import { showToast } from 'shared/components/Toast';

export interface IIntegrationApiKey {
  id: string;
  key: string;
  createdBy: string;
  description?: string;
  businessId: string;
  createdAt: string;
  revokedAt: string;
  isDeleted: boolean;
  centerScopes: string[];
  apiKeyWebHooks: IApiKeyWebHook[];
  apiScopes: IApiScopesType;
}

export interface IApiKeyWebHook {
  id: string;
  apiKeyId: string;
  url: string;
  webHookType: number;
  webHookApiKey?: string;
}

interface IProps {
  apiKey: IIntegrationApiKey;
  refetchKeys: () => void;
}

const ApiKey: React.FC<IProps> = ({ apiKey, refetchKeys, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [addHookDialogOpen, setAddHookDialogOpen] = React.useState(false);
  const [editApiKeyDialog, setEditApiKeyDialogOpen] = React.useState(false);

  const [revokeApiKeyfn, { loading: loadingRevoke }] = useUpdateIntegrationApiKey({
    onCompleted: () => {
      refetchKeys();
    },
  });

  const onRevoke = () => {
    revokeApiKeyfn({
      variables: {
        input: {
          id: apiKey.id,
          businessId: apiKey.businessId,
          description: apiKey.description!,
          revoked: true,
          centerScopes: apiKey.centerScopes,
          apiScopes: {}, // blank overwrites nothing
        },
      },
      onCompleted: () => {
        showToast('API Key revoked', 'success');
      },
      onError: (e) => {
        showToast(e.message, 'error');
      },
    });
  };

  const unRevoke = () => {
    revokeApiKeyfn({
      variables: {
        input: {
          id: apiKey.id,
          businessId: apiKey.businessId,
          description: apiKey.description!,
          revoked: false,
          centerScopes: apiKey.centerScopes,
          apiScopes: {}, // blank overwrites nothing
        },
      },
      onCompleted: () => {
        showToast('API Key unrevoked', 'success');
      },
      onError: (e) => {
        showToast(e.message, 'error');
      },
    });
  };

  return (
    <>
      <Mui.TableRow key={apiKey.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, margin: 0 }}>
        <Mui.TableCell>{apiKey.description}</Mui.TableCell>
        <Mui.TableCell>{moment(apiKey.createdAt).format('ll')}</Mui.TableCell>
        <Mui.TableCell>
          <Mui.Box display="flex" justifyContent="space-between" alignItems="center">
            <span
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '180px',
              }}
            >
              {apiKey.key}
            </span>
            <Mui.Grid container justifyContent="flex-end" spacing={2} flexDirection={'column'}>
              <Mui.Grid item xs={8}>
                <Mui.Button
                  sx={{ mx: 1, paddingLeft: '8px', paddingRight: '8px' }}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => navigator.clipboard.writeText(apiKey.key)}
                >
                  Copy Key
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Box>
        </Mui.TableCell>
        <Mui.TableCell>{_.isNil(apiKey.revokedAt) ? 'Active' : 'Revoked'}</Mui.TableCell>
        <Mui.TableCell>
          <Mui.Box display="flex" justifyContent="space-between" alignItems="center">
            <span
              style={{
                width: '360px',
              }}
            >
              {apiKey.apiKeyWebHooks[0]?.url}
            </span>
          </Mui.Box>
        </Mui.TableCell>
        <Mui.TableCell>
          <>
            <Mui.IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              {' '}
              <MoreHorizOutlinedIcon />{' '}
            </Mui.IconButton>
            <Mui.Menu
              PaperProps={{ sx: { boxShadow: '1' } }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Mui.MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setEditApiKeyDialogOpen(true);
                }}
              >
                Edit
              </Mui.MenuItem>
              {_.isNil(apiKey.revokedAt) ? (
                <Mui.MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    onRevoke();
                  }}
                >
                  Revoke Key
                </Mui.MenuItem>
              ) : (
                <Mui.MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    unRevoke();
                  }}
                >
                  Unrevoke Key
                </Mui.MenuItem>
              )}
              {apiKey.apiKeyWebHooks?.length > 0 ? (
                <Mui.MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setAddHookDialogOpen(true);
                  }}
                >
                  Edit WebHook
                </Mui.MenuItem>
              ) : (
                <Mui.MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setAddHookDialogOpen(true);
                  }}
                >
                  Add WebHook
                </Mui.MenuItem>
              )}
            </Mui.Menu>
          </>
        </Mui.TableCell>
      </Mui.TableRow>
      <AddApiKeyWebHookDialog
        apiKey={apiKey}
        addHookDialogOpen={addHookDialogOpen}
        setAddHookDialogOpen={setAddHookDialogOpen}
        refetchKeys={refetchKeys}
      />
      <AddApiKeyDialog
        apiKey={apiKey}
        addKeyDialogOpen={editApiKeyDialog}
        setAddorEditKeyDialogOpen={setEditApiKeyDialogOpen}
        refetchKeys={refetchKeys}
      />
    </>
  );
};

export default ApiKey;
