import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import DateInput from 'shared/components/DateInput';

interface IProps extends IImportStepProps {
  adjustZeroBalance: (data: IAdjustZeroBalanceVariables) => void;
  adjustSubsidyZeroBalance: (data: IAdjustSubsidyZeroBalanceVariables) => void;
  zeroBalanceLoading: boolean;
  subsidyZeroBalanceLoading: boolean;
}

const Actions: React.FC<IProps> = ({
  adjustZeroBalance,
  adjustSubsidyZeroBalance,
  zeroBalanceLoading,
  subsidyZeroBalanceLoading,
  center,
  shouldReset,
}) => {
  const { t } = useTranslation('ktAdmin');
  const [date, setDate] = useState<string | undefined>();

  useEffect(() => {
    if (shouldReset) setDate(undefined);
  }, [shouldReset]);

  const handleSubsidyZeroBalance = () => {
    adjustSubsidyZeroBalance({
      centerId: center?.id ?? '',
      businessId: center?.entityId ?? '',
      date: date ?? '',
    });
  };
  const handleZeroBalance = () => {
    adjustZeroBalance({
      centerId: center?.id ?? '',
      businessId: center?.entityId ?? '',
      date: moment(date).format('YYYY-MM-DD') ?? '',
    });
  };

  return (
    <Card
      header={t('data-importer.step-number', {
        number: 6,
        title: t('data-importer.actions'),
      })}
    >
      <DateInput label="Date" date={date} onDateSelect={setDate} />
      <p>{t('data-importer.adjust-balances-on-import')}</p>
      <Button
        variant="secondary"
        className="mr-2"
        disabled={date === undefined}
        onClick={handleZeroBalance}
        loading={zeroBalanceLoading}
      >
        {t('data-importer.balance-zero')}
      </Button>
      <Button
        variant="secondary"
        disabled={date === undefined}
        onClick={handleSubsidyZeroBalance}
        loading={subsidyZeroBalanceLoading}
      >
        {t('data-importer.subsidies-balance-zero')}
      </Button>
    </Card>
  );
};

export default Actions;
