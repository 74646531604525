import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import { Col, Row } from 'shared/components/Layout';
import { faFileImport } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
interface IProps {
  onClick: () => void;
  totalFiles: number;
  selectedFiles: number;
  disabled?: boolean;
  completed?: boolean;
  loading?: boolean;
}

const UploadButton: React.FC<IProps> = ({
  onClick,
  totalFiles,
  selectedFiles,
  loading,
  disabled = false,
  completed = false,
}) => {
  const { t } = useTranslation('ktAdmin');
  if (completed) {
    return (
      <Row>
        <Col className="d-flex mt-4 flex-column">
          <div>
            <FontAwesomeIcon icon={faFileImport} size="2x" color={colors.success} />
          </div>
          <p className="text-center">{t('data-importer.upload-complete')}</p>
        </Col>
      </Row>
    );
  }
  return (
    <Row>
      <Col className="d-flex mt-4">
        <div className="text-center">
          <Button onClick={onClick} disabled={disabled} loading={loading}>
            {t('data-importer.upload-files')}
          </Button>
          <p className="text-center">
            <strong>
              {t('data-importer.files-selected', {
                selectedFiles,
                totalFiles,
              })}
            </strong>
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default UploadButton;
