import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import moment from 'moment';
import { PAYMENT_FILTERS, useGetPaginatedPayments } from 'gql/transaction/queries';
import { useApproveFlaggedPayment } from 'gql/transaction/mutations';
import { showToast } from 'shared/components/Toast';
import PaymentTransactionsTable from 'pages/BillingPayments/components/PaymentTransactionsTable';
import AddManualPaymentModal from 'shared/components/AddManualPaymentModal';
import CancelPaymentModal from '../components/CancelPaymentModal';
import PaymentsReportModal from '../PaymentsReportModal';
import VoidManualCheckModal from '../components/VoidManualCheckModal';
import useDatatableState from '../../../shared/hooks/useDatatableState2';
import { isRegion } from 'shared/util/region';

// @ts-ignore
const PAYMENT_STATUSES: PaymentStatus[] = [
  'UNSUBMITTED',
  'PENDING',
  'FAILED',
  'COMPLETED',
  'FLAGGED',
  // @ts-ignore
  'READY',
  'CANCELLED',
];

export interface ICancelPaymentModalStateShape {
  open: boolean;
  payment: IPayment | null;
}

interface ITableFiltersShape {
  start: string;
  end: string;
  paymentStatuses: PaymentStatus[];
  paymentCategories: Array<'MANUAL' | 'AUTOMATED'>;
}

interface IProps {
  showRunReportModal: boolean;
  showAddPaymentModal: boolean;
  onHideRunReportModal: () => void;
  onAddPaymentModal: () => void;
}

type TransactionCategory = 'manual' | 'automated' | 'attendance';
const automatedTransactionTypeNames = ['Automated CC', 'Automated ACH/DD'];
const attendanceTransactionTypeNames = ['Session Fee', 'Early Fee', 'Late Fee', 'Discount', 'Subsidy'];

export const getTransactionCategory: (t: ITransaction) => TransactionCategory = (t) => {
  if (!t.transactionType.centerId && !t.transactionType.businessId) {
    if (automatedTransactionTypeNames.includes(t.transactionType.name)) {
      return 'automated';
    } else {
      return attendanceTransactionTypeNames.includes(t.transactionType.name) ? 'attendance' : 'manual';
    }
  } else {
    return 'manual';
  }
};

const AllPaymentsTab: React.FC<IProps> = ({
  showRunReportModal,
  showAddPaymentModal,
  onHideRunReportModal,
  onAddPaymentModal,
  ...props
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const defaultStatus = queryString.parse(location.search).status;
  const defaultStartDate = queryString.parse(location.search).start ?? moment().startOf('month').format();
  const defaultEndDate = queryString.parse(location.search).end ?? moment().endOf('month').format();

  const [cancelPaymentModalState, setCancelPaymentModalState] = useState<ICancelPaymentModalStateShape>({
    open: false,
    payment: null,
  });
  const [voidManualCheckPaymentModal, setVoidManualCheckPaymentModal] = useState<ICancelPaymentModalStateShape>({
    open: false,
    payment: null,
  });

  const [tableFilters, setTableFilters] = useState<ITableFiltersShape>({
    start: defaultStartDate,
    end: defaultEndDate,
    paymentStatuses: defaultStatus ? [defaultStatus.toUpperCase()] : [],
    paymentCategories: [],
  });

  const { businessId, businessFilterIds, centerFilterIds } = useSelector((state: RootState) => state.context);
  const [tableState, tableFunctions] = useDatatableState();
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'date',
    direction: 'desc',
  });
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedCategories, setSelectedCategories] = useState<ITableFilterOption[]>([]);

  const { data, loading, networkStatus } = useGetPaginatedPayments({
    variables: {
      input: {
        businessId: businessId ?? '',
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        centerIds: centerFilterIds,
        startDate: tableFilters.start,
        endDate: tableFilters.end,
        sortBy: sortField.field,
        sortDirection: sortField.direction === 'asc' ? 'asc' : 'desc',
        searchKey: searchTerm,
        transactionTypeIds: [],
        paymentFilters: [
          ...PAYMENT_FILTERS.filter((x) => (tableFilters.paymentStatuses as string[]).includes(x)),
          ...PAYMENT_FILTERS.filter((x) => (tableFilters.paymentCategories as string[]).includes(x)),
        ],
      },
    },
    fetchPolicy: 'network-only',
    skip: businessId === null || businessId === '' || !tableFilters.start || !tableFilters.end,
  });

  const [approveFlaggedPaymentFn, { loading: approveFlaggedPaymentLoading }] = useApproveFlaggedPayment({
    onCompleted: (result) => {
      if (result.approvePayment) {
        showToast('Approved payment successfully.', 'success');
      }
    },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  // only show the loading lines on the initial load and if we don't have any data in redux
  const showLoadingLines =
    loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES && networkStatus !== NETWORK_STATUS.REFETCH;

  const handleApprovePayment = useCallback(
    (payment: IPayment) => {
      if (payment) {
        approveFlaggedPaymentFn({
          variables: {
            paymentId: payment.id,
          },
        });
      }
    },
    [approveFlaggedPaymentFn]
  );

  return (
    <>
      {showLoadingLines ? (
        <PageWrapperBody>
          <DataTableLoadingSkeleton />
        </PageWrapperBody>
      ) : (
        <PaymentTransactionsTable
          noPadding
          loading={loading}
          isPaymentTransactionsOnly
          data={data?.getPaginatedPayments.data ?? []}
          dateRange={{ start: tableFilters.start, end: tableFilters.end }}
          approvePaymentLoading={approveFlaggedPaymentLoading}
          setDateRange={(timeRange: ITimeRange) =>
            setTableFilters((prev) => ({ ...prev, start: timeRange.start, end: timeRange.end }))
          }
          onApproveFlaggedPayment={handleApprovePayment}
          onRejectFlaggedPayment={(payment: IPayment, voidManualCheckPayment: boolean) =>
            voidManualCheckPayment
              ? setVoidManualCheckPaymentModal({ open: true, payment })
              : setCancelPaymentModalState({ open: true, payment })
          }
          onPaymentStatusFilter={(statuses: PaymentStatus[]) =>
            setTableFilters((prev) => ({
              ...prev,
              paymentStatuses: statuses,
              start: moment().startOf('month').format(),
              end: moment().endOf('month').format(),
            }))
          }
          onStatusTileSelected={(start: string, end: string, status?: PaymentStatus) =>
            setTableFilters((prev) => ({
              ...prev,
              paymentStatuses: !!status
                ? status === 'FAILED' && isRegion('US')
                  ? ['FAILED', 'RETURNED']
                  : [status]
                : [],
              start,
              end,
            }))
          }
          pageSize={tableState.pageSize}
          activePage={tableState.activePage}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          totalRecords={data?.getPaginatedPayments.totalRecords ?? 0}
          onSort={(field, direction) => setSortField({ field, direction: direction === 'ASCENDING' ? 'asc' : 'desc' })}
          onSearchChange={setSearchTerm}
          onSelectedTransactionTypeChange={(categoryTableFilterOptions) => {
            const categories = categoryTableFilterOptions.map((x) => {
              return { automated: 'AUTOMATED', manual: 'MANUAL' }[x.value];
            }) as Array<'AUTOMATED' | 'MANUAL'>;
            setTableFilters((prev) => ({ ...prev, paymentCategories: categories }));
            setSelectedCategories(categoryTableFilterOptions);

            // Fix page number persisting on filter (this should not happen or it can result in no data being displayed)
            tableFunctions.changePage(1, tableState.pageSize);
          }}
          selectedTransactionType={selectedCategories}
          disableSortingOnStatusAndMethod
        />
      )}
      <AddManualPaymentModal isOpen={showAddPaymentModal} onClose={onAddPaymentModal} />
      <CancelPaymentModal
        isOpen={cancelPaymentModalState.open}
        payment={cancelPaymentModalState.payment}
        onClose={() => setCancelPaymentModalState({ open: false, payment: null })}
      />
      <VoidManualCheckModal
        isOpen={voidManualCheckPaymentModal.open}
        payment={voidManualCheckPaymentModal.payment}
        onClose={() => setVoidManualCheckPaymentModal({ open: false, payment: null })}
      />
      {showRunReportModal && (
        <PaymentsReportModal
          isOpen={showRunReportModal}
          defaultFormValues={{
            start: tableFilters.start,
            end: tableFilters.end,
            centerIds: centerFilterIds,
            statuses:
              !tableFilters.paymentStatuses.length || tableFilters.paymentStatuses.length === 0
                ? PAYMENT_STATUSES
                : tableFilters.paymentStatuses,
          }}
          onClose={onHideRunReportModal}
        />
      )}
    </>
  );
};

export default AllPaymentsTab;
