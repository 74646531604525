import React from 'react';
interface ITdProps {
  iSMessage: IISMessage;
  column: 'source' | 'messageType' | 'centerName' | 'created' | 'messageId';
  text?: string;
  className?: string;
}

const ISMessagesTd: React.FC<ITdProps> = ({ column, className = '', iSMessage, text }) => {
  const { viewedStatus } = iSMessage;
  const fontWeight = viewedStatus === 'UNREAD' ? 'semi-bold' : 'normal';

  return <div className={`font-weight-${fontWeight} ${className}`}>{text ?? iSMessage[column]}</div>;
};

export default ISMessagesTd;
