import React, { useState, useCallback, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Alert from 'shared/components/Alert';
import Card from 'shared/components/Card';
import RadioButton from 'shared/components/RadioButton';
import RatioInput from 'shared/components/TextInput/RatioInput';
import RegulationAgeInput from 'shared/components/TextInput/RegulationAgeInput';
import cast from 'shared/util/cast';

const DEFAULT_AGE: IRegulationAge = {
  age: 0,
  unit: 'WEEK',
};

const DEFAULT_REG: IRegulationOverride = {
  startAge: DEFAULT_AGE,
  endAge: DEFAULT_AGE,
  youngestStartAge: DEFAULT_AGE,
  oldestEndAge: DEFAULT_AGE,
  ratioTeachers: 0,
  ratioChildren: 0,
};

interface IProps {
  isViewOnly: boolean;
  hasOverride: boolean;
  existingOverride: IRegulationOverride | undefined;
  baseRegulation: IRegulation;
  updateOverrides: (reg: IRegulationOverride | undefined) => void;
  ageToNumberOfDays: (age: IRegulationAge) => number;
}

const ClassRegulationOverride: React.FC<IProps> = ({
  isViewOnly,
  hasOverride,
  baseRegulation,
  existingOverride,
  updateOverrides,
  ageToNumberOfDays,
  ...props
}) => {
  const [isOverride, setIsOverride] = useState(hasOverride ?? false);
  useEffect(() => {
    setIsOverride(hasOverride);
  }, [hasOverride]);
  const regulation: IRegulationOverride = existingOverride ?? cast<IRegulationOverride>(baseRegulation) ?? DEFAULT_REG;

  const handleChange = useCallback(
    (val: any, name: string) => {
      existingOverride && updateOverrides({ ...existingOverride, [name]: val });
    },
    [existingOverride, updateOverrides]
  );

  const setOverride = useCallback(() => {
    const { id, state, county, capacity, ...rest } = baseRegulation;

    updateOverrides({
      ...rest,
      startAge: existingOverride?.startAge ?? baseRegulation.startAge,
      endAge: existingOverride?.endAge ?? baseRegulation.endAge,
      youngestStartAge: existingOverride?.youngestStartAge ?? baseRegulation.startAge,
      oldestEndAge: existingOverride?.oldestEndAge ?? baseRegulation.endAge,
      ratioChildren: existingOverride?.ratioChildren ?? baseRegulation.ratioChildren,
      ratioTeachers: existingOverride?.ratioTeachers ?? baseRegulation.ratioTeachers,
    });
  }, [baseRegulation, updateOverrides, existingOverride]);

  const overrideCompliant = useCallback(
    (overrideRatioTeacher: number, overrideRationChildren: number): boolean => {
      const override = overrideRatioTeacher / overrideRationChildren;
      const base = baseRegulation.ratioTeachers / baseRegulation.ratioChildren;
      return base > override;
    },
    [baseRegulation]
  );

  const isMounted = useRef(false);
  useEffect(() => {
    if (baseRegulation && isOverride && isMounted.current) {
      setOverride();
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseRegulation]);

  const isNullOrEmpty = (value: any) => value === null || value === undefined || value === '' || isNaN(value);

  return (
    <Card header="Override Settings" className="h-100">
      <div className="d-flex flex-row align-items-center mb-4">
        <span className="d-block mr-auto">Do you wish to override these settings?</span>
        <RadioButton
          className="radio-margin-0"
          label="Yes"
          value={isOverride}
          onChange={() => {
            setIsOverride(true);
            setOverride();
          }}
          disabled={!baseRegulation || isViewOnly}
        />
        <div className="ml-2">
          <RadioButton
            label="No"
            className="radio-margin-0"
            value={!isOverride}
            onChange={() => {
              setIsOverride(false);
              updateOverrides(undefined);
            }}
            disabled={!baseRegulation || isViewOnly}
          />
        </div>
      </div>
      <Row>
        <Column lg>
          <RegulationAgeInput
            required={isOverride}
            idPrefix="regulation-start"
            disabled={!isOverride || isViewOnly}
            label="Typical Start Age"
            value={regulation.startAge}
            isInvalid={
              existingOverride?.endAge &&
              ageToNumberOfDays(existingOverride?.endAge) <= ageToNumberOfDays(existingOverride?.startAge)
            }
            errorText="Must be less than Typical End Age"
            onChange={(age) => handleChange({ ...existingOverride?.startAge, age: parseFloat(age) }, 'startAge')}
            onAgeUnitChange={(unit) => handleChange({ ...existingOverride?.startAge, unit }, 'startAge')}
          />
        </Column>
        <span className="d-none d-lg-block mx-auto mb-n4 align-self-center">to</span>
        <Column lg>
          <RegulationAgeInput
            required={isOverride}
            idPrefix="regulation-end"
            disabled={!isOverride || isViewOnly}
            label="Typical End Age"
            value={regulation.endAge}
            onChange={(age) =>
              handleChange(
                isNullOrEmpty(age) ? DEFAULT_AGE : { ...existingOverride?.endAge, age: parseFloat(age) },
                'endAge'
              )
            }
            onAgeUnitChange={(unit) => handleChange({ ...existingOverride?.endAge, unit }, 'endAge')}
          />
        </Column>
      </Row>
      <Collapse in={isOverride}>
        <Row>
          <Column lg>
            <RegulationAgeInput
              btnClassName="appended-input-override"
              idPrefix="regulation-youngest-start"
              disabled={!isOverride || isViewOnly}
              label="Youngest Start Age"
              value={regulation?.youngestStartAge ?? DEFAULT_AGE}
              onChange={(age) =>
                handleChange(
                  isNullOrEmpty(age) ? DEFAULT_AGE : { ...existingOverride?.youngestStartAge, age: parseFloat(age) },
                  'youngestStartAge'
                )
              }
              onAgeUnitChange={(unit) =>
                handleChange({ ...existingOverride?.youngestStartAge, unit }, 'youngestStartAge')
              }
              isInvalid={
                (existingOverride?.youngestStartAge &&
                  ageToNumberOfDays(existingOverride.youngestStartAge) >
                    ageToNumberOfDays(existingOverride.startAge)) ||
                !existingOverride?.youngestStartAge
              }
              errorText={
                existingOverride?.youngestStartAge &&
                ageToNumberOfDays(existingOverride.youngestStartAge) > ageToNumberOfDays(existingOverride.startAge)
                  ? 'Must be less than or equal to Typical Start Age'
                  : `Input can't be left blank`
              }
            />
          </Column>
          <span className="d-none d-lg-block mx-auto mb-n4 align-self-center">to</span>
          <Column lg>
            <RegulationAgeInput
              btnClassName="appended-input-override"
              idPrefix="regulation-oldest-end"
              disabled={!isOverride || isViewOnly}
              label="Oldest End Age"
              value={regulation?.oldestEndAge ?? DEFAULT_AGE}
              onChange={(age) =>
                handleChange(
                  isNullOrEmpty(age) ? DEFAULT_AGE : { ...existingOverride?.oldestEndAge, age: parseFloat(age) },
                  'oldestEndAge'
                )
              }
              onAgeUnitChange={(unit) => handleChange({ ...existingOverride?.oldestEndAge, unit }, 'oldestEndAge')}
              isInvalid={
                (existingOverride?.oldestEndAge || existingOverride?.oldestEndAge?.age === 0) &&
                ageToNumberOfDays(existingOverride.oldestEndAge) < ageToNumberOfDays(existingOverride.endAge)
              }
              errorText={
                existingOverride?.oldestEndAge &&
                ageToNumberOfDays(existingOverride.oldestEndAge) < ageToNumberOfDays(existingOverride.endAge)
                  ? 'Must be greater than or equal to Typical End Age'
                  : `Input can't be left blank`
              }
            />
          </Column>
        </Row>
      </Collapse>
      <Row>
        <Column lg>
          <RatioInput
            required={isOverride}
            id="regulation-ratio-input"
            name="ratio"
            label="Ratio (Adult:Child)"
            value={`${regulation.ratioTeachers}:${regulation.ratioChildren}`}
            onChange={(ratio: string) => {
              const [ratioTeachers, ratioChildren] = ratio.split(':');
              existingOverride &&
                updateOverrides({
                  ...existingOverride,
                  ratioTeachers: parseInt(ratioTeachers, 10),
                  ratioChildren: parseInt(ratioChildren, 10),
                });
            }}
            maxLength={4}
            disabled={!isOverride || isViewOnly}
          />
        </Column>
        <Column lg></Column>
      </Row>
      <Collapse
        in={
          isOverride &&
          existingOverride &&
          overrideCompliant(existingOverride.ratioTeachers, existingOverride.ratioChildren)
        }
      >
        <div>
          <Alert variant="warning">The ratio you entered is out of compliance with your state regulation.</Alert>
        </div>
      </Collapse>
    </Card>
  );
};

export default ClassRegulationOverride;
