import React from 'react';
import ConfirmationModal from '../../../../shared/components/ConfirmationModal';
import { useTranslation } from 'react-i18next';

interface DeleteAgencyModalProps {
  isOpen: boolean;
  onClose: () => void;
  deleteAgencyPayment?: () => void;
}

export default function DeleteAgencyPaymentModal({ isOpen, onClose, deleteAgencyPayment }: DeleteAgencyModalProps) {
  const { t } = useTranslation(['subsidies']);

  return (
    <ConfirmationModal
      title={t('subsidies:agency-payments.delete-modal-title')}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice={'Delete'}
      primaryCallback={deleteAgencyPayment}
      primaryButtonProps={{
        variant: 'danger',
      }}
      secondaryCallback={onClose}
    >
      {t('subsidies:agency-payments.delete-modal-primary')}
      <div className="mt-4">{t('subsidies:agency-payments.delete-modal-confirmation')}</div>
    </ConfirmationModal>
  );
}
