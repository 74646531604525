import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { useGetBusRosterById } from 'gql/busRoster/queries';
import React, { useCallback } from 'react';
import { capitalize, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import Select from 'shared/components/Select';
import Card from 'shared/components/Card';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import { RootState } from 'store/reducers';
import BusRosterInformationForm from './BusRosterInformationForm';
import { useTranslation } from 'react-i18next';

interface IProps {
  busRosterFromData: any | undefined;
  assignedChildren: IBusRosterAccountChildGridShape[] | [];
  destinationOptions: IDestinationStateShape[];
  loading: boolean;
  onChange: React.Dispatch<React.SetStateAction<IBusRosterInformationForm>>;
  onAddDestination: () => void;
  onRemoveDestination: (destinationId: string) => void;
}

const BusRosterInfromationCard: React.FC<IProps> = ({
  busRosterFromData,
  assignedChildren,
  destinationOptions,
  loading,
  onChange,
  onAddDestination,
  onRemoveDestination,
}) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: RootState) => state.user);
  const isInternalUser = currentUser?.isInternal;
  const { data: businessesData } = useGetAllowedEntities(!isInternalUser);
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');

  return (
    <Card
      header={
        <div className="d-flex flex-row align-items-center">
          {t('bus-roster.information-title')}
          {currentUser?.isInternal && (
            <div className="ml-auto">
              <Select
                title={capitalize(t('spelling.business'))}
                className="mr-4 my-2 min-width-200"
                options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                value={busRosterFromData.businessId}
                name="business"
                onChange={(option) =>
                  onChange((prev) => ({
                    ...prev,
                    businessId: option.value,
                  }))
                }
              />
            </div>
          )}
        </div>
      }
    >
      {loading ? (
        <LoadingLines />
      ) : (
        <BusRosterInformationForm
          busRoster={busRosterFromData}
          assignedChildren={assignedChildren}
          destinationOptions={destinationOptions}
          loading={false}
          onChange={onChange}
          onAddDestination={onAddDestination}
          onRemoveDestination={onRemoveDestination}
        ></BusRosterInformationForm>
      )}
    </Card>
  );
};

export default BusRosterInfromationCard;
