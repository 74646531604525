export const CREATE_CENTER_TIMEZONE_HASH = 'CREATE_CENTER_TIMEZONE_HASH';
export const ADD_CENTER_TO_TIMEZONE_HASH = 'ADD_CENTER_TO_TIMEZONE_HASH';
export const CREATE_BUSINESS_TIMEZONE_HASH = 'CREATE_BUSINESS_TIMEZONE_HASH';

interface ICreateCenterTimezoneHashAction {
  type: typeof CREATE_CENTER_TIMEZONE_HASH;
  centers: ICenter[];
}

interface IAddCenterToTimezoneHashAction {
  type: typeof ADD_CENTER_TO_TIMEZONE_HASH;
  center: ICenter;
}

interface ICreateBusinessTimezoneHashAction {
  type: typeof CREATE_BUSINESS_TIMEZONE_HASH;
  businesses: IEntity[];
}

export type TimezoneActionTypes =
  | ICreateCenterTimezoneHashAction
  | IAddCenterToTimezoneHashAction
  | ICreateBusinessTimezoneHashAction;
