export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACT_BY_ID_SUCCESS = 'GET_CONTACT_BY_ID_SUCCESS';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';

export type ContactActionTypes =
  | { type: typeof GET_CONTACTS_SUCCESS; contacts: IContact[] }
  | { type: typeof GET_CONTACT_BY_ID_SUCCESS; contact: IContact }
  | { type: typeof ADD_CONTACT_SUCCESS; contact: IContact }
  | { type: typeof UPDATE_CONTACT_SUCCESS; contact: IContact };
