import React, { useCallback, useMemo, useState, useEffect } from 'react';
//components
import NotifiableServiceEventsContainer from '../NotifiableServiceEventsContainer';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import TextInput from 'shared/components/TextInput';
import Select from 'shared/components/Select';
import { showToast } from 'shared/components/Toast';
//utils
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { reasonsForClosure } from './common';
import {
  useCreateServiceTemporarilyCeasing,
  useDeleteServiceTemporarilyCeasing,
  useUpdateServiceTemporarilyCeasing,
} from '../../graphql/mutations';
import { useGetServiceTemporarilyCeasing } from '../../graphql/queries';

interface IProps {
  businessId: string;
}

interface IServiceTemporarilyCeasingOperationsInput {
  startDate: string | Date;
  endDate: string | Date;
  reasonText: string;
  reasonType: string;
  service: any;
  businessId: string;
}

export const ServiceTemporarilyCeasingOperations: React.FC<IProps> = ({ businessId }) => {
  const emptyInputs = {
    startDate: '',
    endDate: '',
    reasonText: '',
    reasonType: '',
    service: undefined,
    businessId,
  };

  const { t } = useTranslation('business');
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<IServiceTemporarilyCeasingOperationsInput>(emptyInputs);
  const [formPreviousData, setFormPreviousData] = useState<IServiceTemporarilyCeasingOperationsInput>(formData);

  const createUpdateInput = {
    input: {
      serviceId: formData?.service?.value,
      startDate: formData.startDate,
      reasonText: formData.reasonText,
      reasonType: formData.reasonType,
      businessId: formData.businessId,
      endDate: formData.endDate,
    },
  };

  const [createServiceTemporarilyCeasing, { loading: createLoading }] = useCreateServiceTemporarilyCeasing();
  const [updateServiceTemporarilyCeasing, { loading: updateLoading }] = useUpdateServiceTemporarilyCeasing();
  const [deleteServiceTemporarilyCeasing, { loading: deleteLoading }] = useDeleteServiceTemporarilyCeasing();

  const {
    data: data,
    loading,
    refetch,
  } = useGetServiceTemporarilyCeasing({
    businessId,
    serviceId: formData?.service?.value ?? '',
  });

  useEffect(() => {
    if (!loading && !!data) {
      const dataForForm = data.getServiceTemporarityCeasing;
      const { startDate, endDate, reasonText, reason } = dataForForm;
      setFormData({ ...formData, startDate, endDate, reasonText, reasonType: reason });
      setFormPreviousData({ ...formData, startDate, endDate, reasonText, reasonType: reason });
    }
  }, [data]);

  useEffect(() => {
    if (data?.getServiceTemporarityCeasing) {
      const { startDate, endDate, reasonText, reason } = data.getServiceTemporarityCeasing;
      setFormData({ ...formData, startDate, endDate, reasonText, reasonType: reason });
      setFormPreviousData({ ...formData, startDate, endDate, reasonText, reasonType: reason });
    } else {
      const { startDate, endDate, reasonText, reasonType } = emptyInputs;
      setFormData({ ...formData, startDate, endDate, reasonText, reasonType });
      setFormPreviousData({ ...formData, startDate, endDate, reasonText, reasonType });
    }
  }, [data]);

  const showErrorMessage = (error) => {
    const err = error.toString().replace('Error: GraphQL error:', '').trim();
    const errorMessage = !!err ? err : t('ccs-management.message-error');
    showToast(errorMessage, 'error');
  };

  const handleDelete = useCallback(() => {
    deleteServiceTemporarilyCeasing({
      variables: {
        input: {
          startDate: formData.startDate,
          endDate: formData.endDate,
          reasonType: formData.reasonType,
          serviceId: formData.service?.value,
          businessId,
        },
      },
    })
      .then(() => {
        showToast(t('ccs-management.service-temporarily-ceasing-operations.message-toast.success-delete'), 'success');
      })
      .then(() => {
        const { service } = formPreviousData;
        setFormData({ ...emptyInputs, service });
        setFormPreviousData({ ...emptyInputs, service });
      })
      .catch((err) => showErrorMessage(err));
  }, [deleteServiceTemporarilyCeasing, formData]);

  const handleSave = useCallback(() => {
    if (data) {
      updateServiceTemporarilyCeasing({
        variables: createUpdateInput,
      })
        .then(() => {
          showToast(t('ccs-management.service-temporarily-ceasing-operations.message-toast.success-update'), 'success');
        })
        .then(() => refetch())
        .catch((err) => showErrorMessage(err));
    } else {
      createServiceTemporarilyCeasing({
        variables: createUpdateInput,
      })
        .then(() => {
          showToast(t('ccs-management.service-temporarily-ceasing-operations.message-toast.success'), 'success');
        })
        .catch((err) => showErrorMessage(err));
    }
  }, [createServiceTemporarilyCeasing, updateServiceTemporarilyCeasing, formData]);

  const isFormValid = useMemo(
    () =>
      !!formData.startDate &&
      !!formData.endDate &&
      !!formData.reasonText &&
      !!formData.reasonType &&
      !!formData.service &&
      !!formData.service.value,
    [formData]
  );

  return (
    <NotifiableServiceEventsContainer
      title={t('ccs-management.service-temporarily-ceasing-operations.title')}
      notificationMessage={t('ccs-management.service-temporarily-ceasing-operations.notification')}
      onCancel={() => setFormData(formPreviousData)}
      onSave={handleSave}
      formIsDirty={!formIsDirty}
      toggleDirty={setFormIsDirty}
      saveDisabled={!isFormValid}
      isSaving={createLoading || updateLoading}
      serviceOnChange={(option) => setFormData({ ...formData, service: option })}
      selectedService={formData.service}
      deleteButton={true}
      onDelete={handleDelete}
      deleteLoading={deleteLoading}
      isLoading={loading}
      child={
        <>
          <Row>
            <Col className="mb-2">
              <Row>
                <Col>
                  <DateInput
                    required
                    label={t('ccs-management.service-temporarily-ceasing-operations.labels.start-date')}
                    date={formData.startDate as string}
                    isOutsideRange={(day) => formData.endDate && day.isAfter(moment(formData.endDate).endOf('day'))}
                    onDateSelect={(date: string) => setFormData({ ...formData, startDate: date })}
                    disabled={!!data?.getServiceTemporarityCeasing}
                  />
                </Col>
                <Col>
                  <DateInput
                    required
                    label={t('ccs-management.service-temporarily-ceasing-operations.labels.end-date')}
                    date={formData.endDate as string}
                    isOutsideRange={(day) =>
                      formData.startDate && day.isBefore(moment(formData.startDate).endOf('day'))
                    }
                    onDateSelect={(date: string) => setFormData({ ...formData, endDate: date })}
                    disabled={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                required
                inputId="reason-type"
                label={t('ccs-management.service-temporarily-ceasing-operations.labels.reason-type')}
                options={reasonsForClosure}
                className="mb-2"
                // @ts-ignore
                value={reasonsForClosure.find((option) => option?.value === formData.reasonType)}
                onChange={(option) => setFormData({ ...formData, reasonType: option.value })}
                disabled={!!data?.getServiceTemporarityCeasing}
              />
            </Col>
            <Col>{''}</Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <TextInput
                required={false}
                label={t('ccs-management.service-temporarily-ceasing-operations.labels.reason-text')}
                name="FreeTextForReason"
                value={formData.reasonText}
                onChange={(value: string) => setFormData({ ...formData, reasonText: value })}
                disabled={false}
                maxlength="250"
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};
