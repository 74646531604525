import { useGetTransactionTypes } from 'gql/transaction/queries';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

/**
 * Gets the correct transaction type for displaying bulk transaction details
 * @param bulkTransaction
 * @returns
 */
export function useGetTransactionTypeForBulkTransaction(bulkTransaction?: { transactionTypeId: string }) {
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const { data: getTransactionTypesData, loading } = useGetTransactionTypes({
    variables: {
      businessId: currentBusinessId ?? '',
      centerIds: null,
    },
  });
  const transactionType = useMemo(
    () => getTransactionTypesData?.getTransactionTypes.find((tt) => tt.id === bulkTransaction?.transactionTypeId),
    [bulkTransaction?.transactionTypeId, getTransactionTypesData?.getTransactionTypes]
  );
  return { transactionType, loading };
}
