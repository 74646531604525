import React, { useCallback, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useSelector } from 'react-redux';
import { union, difference, orderBy, groupBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Collapse from 'react-bootstrap/Collapse';
import Column from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import Checkbox from 'shared/components/Checkbox';
import { useGetShiftsForPersonForDatesLazy, useSearchSchedulableStaff } from '../../graphql/queries';
import { checkConflictingTimeOffRequests } from '../../utils/checkForConflicts';
import Subtext from './Subtext';
import { RootState } from 'store/reducers';
import { convert24HourTimeToFormatted, isValid24HourString } from 'shared/util/timeUtils';
import colors from '_colors.module.scss';
import { getFullName } from 'shared/util/string';
import TimeInput from 'shared/components/TimePicker/TimeInput';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from '../../../../../../shared/constants/dropdownOptions/countryInfo';

const BREAK_MINUNTE_OPTIONS = [
  { label: '0 minutes', value: 0 },
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '60 minutes', value: 60 },
  { label: '90 minutes', value: 90 },
  { label: '120 minutes', value: 120 },
];

export interface IShiftFormShape {
  id?: string;
  personId?: string | null;
  positionId?: string | null;
  locationId?: string | null;
  classId?: string | null;
  startTime: moment.Moment;
  endTime: moment.Moment;
  /**
   * startTimeString and endTimeString are for TimePicker2 to allow typing
   */
  startTimeString: string;
  endTimeString: string;
  breakMinutes: number;
  paidBreak: boolean;
  note?: string;
}

/**
 * we're passing the shifts for the current schedule to this modal so we can let the user know
 * if they're about to create a shift for someone that overlaps an existing shift
 */
interface IProps {
  isOpen: boolean;
  onClose: () => void;
  entityId: string;
  centerId: string;
  locationId?: string;
  classId?: string;
  selectedDate: moment.Moment;
  datesForWeek: moment.Moment[];
  existingShiftToEdit?: IShift | null;
  loading: boolean;
  timeOffRequestsForWeek: ITimeOff[];
  classes: IClass[];
  locations: ILocation[];
  onSave: (data: IShiftFormShape, dates?: IDateTimeRange[]) => void;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const ShiftSideModal: React.FC<IProps> = ({
  isOpen,
  selectedDate,
  datesForWeek,
  centerId,
  entityId,
  locationId,
  classId,
  loading,
  existingShiftToEdit = null,
  timeOffRequestsForWeek,
  classes,
  locations,
  onClose,
  onSave,
  ...props
}) => {
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const shiftTimezone = timezonesByCenter[centerId] ?? momentTz.tz.guess();
  const timeOffByPersonId = useMemo(
    () => groupBy(timeOffRequestsForWeek, (req) => req.personId),
    [timeOffRequestsForWeek]
  );
  const [selectedEmployee, setSelectedEmployee] = useState<IStaff | null>(null);
  const [shiftFormData, setShiftFormData] = useState<IShiftFormShape>({
    personId: null,
    positionId: null,
    locationId,
    classId,
    startTime: selectedDate.clone().hours(9).minutes(0).seconds(0),
    endTime: selectedDate.clone().hours(17).minutes(0).seconds(0),
    startTimeString: selectedDate.clone().hours(9).minutes(0).seconds(0).format('HH:mm'),
    endTimeString: selectedDate.clone().hours(17).minutes(0).seconds(0).format('HH:mm'),
    breakMinutes: 0,
    paidBreak: false,
    note: '',
  });
  const [selectedPersonPositions, setSelectedPersonPositions] = useState<IStaffPosition[]>([]);
  const [datesToCopyTo, setDatesToCopyTo] = useState<moment.Moment[]>([selectedDate]);
  const { loading: loadingStaff, data: staffData } = useSearchSchedulableStaff(centerId);
  const [getPersonShiftsForDatesFn, { data: shiftsForPersonForDatesData }] = useGetShiftsForPersonForDatesLazy();
  const shiftDates: IDateTimeRange[] = datesToCopyTo.map((day) => ({
    startTime: day.hours(shiftFormData.startTime.hours()).minutes(shiftFormData.startTime.minutes()).toISOString(),
    endTime: day.hours(shiftFormData.endTime.hours()).minutes(shiftFormData.endTime.minutes()).toISOString(),
  }));

  const localisedPositionsForBusiness = () => {
    //filter positions based on scope type
    return (
      selectedPersonPositions.map((p) => ({
        ...p,
        name: p.positionName.replace(/Center|Centre/g, fieldLabels.center),
      })) ?? []
    );
  };

  useEffect(() => {
    // update the postions dropdown whenever the selected person changes
    if (shiftFormData.personId && staffData?.searchSchedulableStaff.data) {
      const person: IStaff | undefined = staffData.searchSchedulableStaff.data.find(
        (staff: IStaff) => staff.id === shiftFormData.personId
      );
      const positions =
        person?.positions.filter((i: IStaffPosition) => i.scopeType === 'ENTITY' || i.scopeId === centerId) ?? [];

      setSelectedPersonPositions(positions);
      //
      /**
       * when a person is switched, reset the position dropdown selected value
       * we need to check the previous value here incase a personId was set on mount (editing a shift). the positionId should then not be nulled
       */
      setShiftFormData((prev) => ({
        ...prev,
        positionId: shiftFormData.personId !== prev.personId ? null : prev.positionId,
      }));
    }
  }, [centerId, shiftFormData.personId, staffData]);

  // if an existing shift is passed on mount, update state
  useEffect(() => {
    if (existingShiftToEdit) {
      setShiftFormData({
        id: existingShiftToEdit.id,
        personId: existingShiftToEdit.personId,
        locationId: existingShiftToEdit.locationId,
        classId: existingShiftToEdit.classId,
        positionId: existingShiftToEdit.personId ? existingShiftToEdit.positionId : null,
        startTime: moment(existingShiftToEdit.startTime).tz(shiftTimezone),
        endTime: moment(existingShiftToEdit.endTime).tz(shiftTimezone),
        startTimeString: moment(existingShiftToEdit.startTime).tz(shiftTimezone).format('HH:mm'),
        endTimeString: moment(existingShiftToEdit.endTime).tz(shiftTimezone).format('HH:mm'),
        breakMinutes: existingShiftToEdit.breakMinutes,
        paidBreak: existingShiftToEdit.paidBreak,
        note: existingShiftToEdit.note ?? '',
      });
    }
    // eslint since we're using this effect as an equivalent of componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // every time a person is selected fetch their created shifts for the week
    if (shiftFormData.personId) {
      getPersonShiftsForDatesFn({
        variables: {
          personId: shiftFormData.personId,
          dates: datesForWeek.map((m) => ({
            startTime: m.clone().startOf('day').toISOString(),
            endTime: m.clone().endOf('day').toISOString(),
          })),
        },
      });
    }
  }, [shiftFormData.personId, datesForWeek, getPersonShiftsForDatesFn]);

  const calculateShiftLength = useCallback(
    (start: moment.Moment, end: moment.Moment, breakMinutes: number, paidBreak: boolean): number => {
      const lengthInMinutes: number = paidBreak
        ? end.diff(start, 'minutes')
        : end.diff(start, 'minutes') - breakMinutes;

      return lengthInMinutes / 60;
    },
    []
  );

  const conflictingShifts = useCallback((): string[] => {
    const existingShiftsForPerson = shiftsForPersonForDatesData?.getShiftsForPersonForDates ?? [];

    if (existingShiftsForPerson) {
      // based on the selected dates, check if creating this shift will result in any conflicts
      return datesToCopyTo
        .map((date: moment.Moment) => {
          // get the hours and minutes from the form....
          const formStartHours = shiftFormData.startTime.hours();
          const formStartMinutes = shiftFormData.startTime.minutes();
          const formEndHours = shiftFormData.endTime.hours();
          const formEndMinutes = shiftFormData.endTime.minutes();
          // ... and set them for each selected date...
          const _start = date.clone().hours(formStartHours).minutes(formStartMinutes);
          const _end = date.clone().hours(formEndHours).minutes(formEndMinutes);
          // ... and check if it conflicts with an existing shift
          const conflictingShift = existingShiftsForPerson.find(
            (shift) =>
              _start.isBetween(
                moment(shift.startTime).tz(shiftTimezone),
                moment(shift.endTime).tz(shiftTimezone),
                undefined,
                '[)'
              ) ||
              _end.isBetween(
                moment(shift.startTime).tz(shiftTimezone),
                moment(shift.endTime).tz(shiftTimezone),
                undefined,
                '(]'
              ) ||
              (_start.isSameOrBefore(moment(shift.startTime)) && _end.isSameOrAfter(moment(shift.endTime))) // shift exists for 10am-4pm and I select 9am-5pm for the shift
          );

          // ignore the conflict if the conflicting shift id is the same as the shift being edited
          if (conflictingShift && conflictingShift.id !== shiftFormData.id) {
            return `There is an existing conflict on ${momentTz(conflictingShift.startTime)
              .tz(shiftTimezone)
              .format('dddd')} from ${momentTz(conflictingShift.startTime)
              .tz(shiftTimezone)
              .format('h:mm A')} - ${momentTz(conflictingShift.endTime).tz(shiftTimezone).format('h:mm A')}.`;
          }

          return '';
        })
        .filter((s) => s !== '');
    }

    return [];
  }, [
    shiftsForPersonForDatesData,
    datesToCopyTo,
    shiftFormData.startTime,
    shiftFormData.endTime,
    shiftFormData.id,
    shiftTimezone,
  ]);

  /**
   * based on the time requests already fetched and displayed for the current week, check if there are any conflicts
   * a conflict would be:
   * - an approved time off requests that overlaps the date and time of shift being created
   */
  const conflictingTimeOffRequests = useCallback(
    (personId: string | null | undefined): string[] => {
      if (personId) {
        const timeOffForPerson: ITimeOff[] | undefined = timeOffByPersonId[personId];

        return checkConflictingTimeOffRequests(
          datesToCopyTo,
          timeOffForPerson,
          moment(shiftFormData.startTime),
          moment(shiftFormData.endTime)
        );
      }

      return [];
    },
    [timeOffByPersonId, datesToCopyTo, shiftFormData.startTime, shiftFormData.endTime]
  );

  conflictingTimeOffRequests(shiftFormData.personId);

  const shiftTimeConflictsWithAvailability = useCallback(
    (date: moment.Moment, startTime: string, endTime: string): boolean => {
      const shiftStart = date.clone().hours(shiftFormData.startTime.hours()).minutes(shiftFormData.startTime.minutes());
      const shiftEnd = date.clone().hours(shiftFormData.endTime.hours()).minutes(shiftFormData.endTime.minutes());

      const [startHours, startMinutes] = startTime.split(':');
      const [endHours, endMinutes] = endTime.split(':');

      // `startTime` and `endTime` are 24 hour strings in the format of HH:mm:ss
      const availabilityStart = date.clone().hours(parseInt(startHours, 10)).minutes(parseInt(startMinutes, 10));
      const availabilityEnd = date.clone().hours(parseInt(endHours, 10)).minutes(parseInt(endMinutes, 10));

      return (
        shiftStart.isBefore(availabilityStart) ||
        shiftStart.isAfter(availabilityEnd) ||
        shiftEnd.isAfter(availabilityEnd)
      );
    },
    [shiftFormData]
  );

  const getAvailabilityForDay = useCallback(
    (date: moment.Moment): ITimeRange[] => {
      const availability: IDayAvailability | null =
        selectedEmployee?.availability?.approved.find(
          (availability) => availability.dayOfWeek === date.clone().format('dddd').toUpperCase()
        ) ?? null;

      return availability?.times ?? [];
    },
    [selectedEmployee]
  );

  const handleClose = useCallback(() => {
    onClose();
    setSelectedEmployee(null);
    setShiftFormData({
      personId: null,
      positionId: null,
      locationId: null,
      classId: null,
      startTime: selectedDate.clone().hours(9).minutes(0).seconds(0),
      endTime: selectedDate.clone().hours(17).minutes(0).seconds(0),
      startTimeString: selectedDate.clone().hours(9).minutes(0).seconds(0).format('HH:mm'),
      endTimeString: selectedDate.clone().hours(17).minutes(0).seconds(0).format('HH:mm'),
      breakMinutes: 0,
      paidBreak: false,
      note: '',
    });
  }, [onClose, selectedDate]);

  const validateForm = useCallback(() => {
    const validPersonalInformation = shiftFormData.personId ? shiftFormData.personId && shiftFormData.positionId : true;

    return (
      validPersonalInformation &&
      shiftFormData.endTime.isAfter(shiftFormData.startTime) &&
      !conflictingShifts().length &&
      !conflictingTimeOffRequests(shiftFormData.personId).length &&
      isValid24HourString(shiftFormData.startTimeString) &&
      isValid24HourString(shiftFormData.endTimeString)
    );
  }, [shiftFormData, conflictingShifts, conflictingTimeOffRequests]);

  return (
    <SideModalDrawer
      title={existingShiftToEdit ? 'Edit Shift' : 'Create Shift'}
      show={isOpen}
      onHide={handleClose}
      dialogClassName="kt-staff-schedules-side-shift-modal"
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={() => onSave(shiftFormData, shiftDates)}
      primaryButtonProps={{
        disabled: !validateForm() || loading,
        loading: loading,
      }}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      /**
       * Since we have a component that renders a popover (<TimeRangePicker />),
       * we must allow focus to leave the modal and to the Popover to allow full functionality of the time inputs.
       * Without allowing focus to leave, the time inputs lose focus immediately.
       *
       * According to the documentation:
       * "Consider leaving the default value here (true), as it is necessary to make the Modal work well with assistive technologies, such as screen readers."
       *
       * When we need this component to support screen readers, we will have to revisit the TimePicker used here.
       */
      enforceFocus={false}
    >
      <Form>
        <Subtext newShift={!existingShiftToEdit} />
        <Select
          label="Location"
          required
          options={[
            ...classes.map((c) => ({ value: c.id, label: c.name, isClass: true })),
            ...locations.map((c) => ({ value: c.id, label: c.name, isClass: false })),
          ]}
          value={shiftFormData.classId || shiftFormData.locationId}
          onChange={(o) =>
            setShiftFormData((prev) => ({
              ...prev,
              classId: o.isClass ? o.value : null,
              locationId: o.isClass ? null : o.value,
            }))
          }
        />
        <Row>
          <Column>
            <Select
              required
              isLoading={loadingStaff}
              label="Employee"
              value={{ id: shiftFormData.personId }}
              // @ts-ignore
              options={[
                { id: null },
                ...(staffData?.searchSchedulableStaff.data.filter((s) => s.role.scheduleVisibility) ?? []),
              ]}
              onChange={(option: IStaff) => {
                setSelectedEmployee(option);
                setShiftFormData((prev) => ({
                  ...prev,
                  personId: option.id,
                  positionId: option.id && option.id === prev.personId ? prev.positionId : null, // if unassigned is selected remove any existing selected position
                }));
              }}
              getOptionValue={(option: IStaff) => option.id}
              getOptionLabel={(option: IStaff) => {
                if (!option.id) {
                  return '-- Unassigned --';
                } else if (option.firstname) {
                  return getFullName(option);
                } else if (!option.firstname && staffData?.searchSchedulableStaff.data) {
                  // when editing a shift we only have a person id
                  const person = staffData.searchSchedulableStaff.data.find((p) => p.id === option.id);

                  return getFullName(person);
                }

                return '';
              }}
            />
          </Column>
        </Row>
        <Row className="mb-4">
          <Column>
            {existingShiftToEdit ? (
              <Checkbox
                disabled
                label={moment(existingShiftToEdit.startTime).tz(shiftTimezone).format('dddd, D')}
                value={true}
              />
            ) : (
              datesForWeek.map((date, idx) => {
                const availability = getAvailabilityForDay(date);

                return (
                  <div key={`date-checkbox-${idx}`} className="d-flex flex-row align-items-center">
                    <Checkbox
                      label={date.format('dddd, D')}
                      disabled={
                        datesToCopyTo.some((d: moment.Moment) => d.isSame(date, 'day')) &&
                        date.isSame(selectedDate, 'day')
                      }
                      value={datesToCopyTo.some((d: moment.Moment) => d.isSame(date, 'day'))}
                      onChange={(checked: boolean) =>
                        setDatesToCopyTo((prev) =>
                          checked
                            ? orderBy(union(prev, [date]), [(date: moment.Moment) => date.day()], ['asc'])
                            : difference(prev, [date])
                        )
                      }
                    />
                    {availability.length > 0 && (
                      <div className="ml-auto">
                        {shiftTimeConflictsWithAvailability(date, availability[0].start, availability[0].end) && (
                          <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} color={colors.warning} />
                        )}
                        {availability
                          .map(
                            (time) =>
                              `${convert24HourTimeToFormatted(time.start)} - ${convert24HourTimeToFormatted(time.end)}`
                          )
                          .join(',')}
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </Column>
        </Row>
        <Row>
          <Column>
            <div className="d-flex flex-row">
              <Form.Label>Shift</Form.Label>
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
          </Column>
        </Row>
        <Row>
          <Column>
            <Form.Label>Start Time</Form.Label>
            <TimeInput
              key={shiftFormData.startTimeString}
              isAM={true}
              value={shiftFormData.startTimeString ?? null}
              onChange={(start: string | null) => {
                const [startHours, startMinutes] = (start ?? '').split(':');
                const startTime = selectedDate
                  .clone()
                  .minutes(parseInt(startMinutes, 10))
                  .hours(parseInt(startHours, 10));
                setShiftFormData((prev) => ({ ...prev, startTime, startTimeString: start ?? '' }));
              }}
            />
          </Column>
          <Column>
            <Form.Label>End Time</Form.Label>
            <TimeInput
              key={shiftFormData.endTimeString}
              isAM={false}
              value={shiftFormData.endTimeString ?? null}
              onChange={(end: string | null) => {
                const [endHours, endMinutes] = (end ?? '').split(':');
                const endTime = selectedDate.clone().minutes(parseInt(endMinutes, 10)).hours(parseInt(endHours, 10));
                setShiftFormData((prev) => ({ ...prev, endTime, endTimeString: end ?? '' }));
              }}
            />
          </Column>
        </Row>
        <br />
        <Row>
          <Column>
            <div className="d-flex flex-row align-items-center">
              <Select
                label="Break Minutes"
                value={{ label: `${shiftFormData.breakMinutes} minutes`, value: shiftFormData.breakMinutes }}
                options={BREAK_MINUNTE_OPTIONS}
                onChange={(option) => setShiftFormData((prev) => ({ ...prev, breakMinutes: option.value }))}
                className="mr-2"
                helpTooltipText="Paid, includes break minutes in hourly totals."
              />
              <Checkbox
                label="Paid?"
                value={shiftFormData.paidBreak}
                onChange={(checked: boolean) => setShiftFormData((prev) => ({ ...prev, paidBreak: checked }))}
              />
            </div>
          </Column>
        </Row>
        <Collapse in={conflictingTimeOffRequests(shiftFormData.personId).length > 0}>
          <Row className="mb-4">
            <Column className="text-danger">
              {conflictingTimeOffRequests(shiftFormData.personId).map((s: string, idx: number) => (
                <div key={`schedule-time-off-conflict-${idx}`}>{s}</div>
              ))}
            </Column>
          </Row>
        </Collapse>
        <Collapse in={conflictingShifts().length > 0}>
          <Row className="mb-4">
            <Column className="text-danger">
              {conflictingShifts().map((s: string, idx: number) => (
                <div key={`schedule-conflict-${idx}`}>{s}</div>
              ))}
            </Column>
          </Row>
        </Collapse>
        <Row>
          <Column>
            <Select
              required={Boolean(shiftFormData.personId)}
              label="Position"
              value={{ id: shiftFormData.positionId }}
              options={localisedPositionsForBusiness()}
              onChange={(option: IStaffPosition) => setShiftFormData((prev) => ({ ...prev, positionId: option.id }))}
              disabled={!shiftFormData.personId}
              getOptionValue={(option: IStaffPosition) => option.id}
              getOptionLabel={(option: IStaffPosition) => {
                if (!option.id) {
                  return 'Select Position';
                } else if (!option.positionName && option.id) {
                  return (
                    selectedPersonPositions
                      .find((p) => p.id === option.id)
                      ?.positionName.replace(/Center|Centre/g, fieldLabels.center) ?? ''
                  );
                }

                return option.positionName.replace(/Center|Centre/g, fieldLabels.center);
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <TextInput
              as="textarea"
              label="Note"
              placeholder="You can add a note here..."
              value={shiftFormData.note}
              rows={5}
              onChange={(value: string) => setShiftFormData((prev) => ({ ...prev, note: value }))}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <ul>
              {datesToCopyTo.map((date: moment.Moment, idx: number) => (
                <li key={`date-total-hours-${date.format('dddd')}-${idx}`}>
                  <b>{date.format('dddd')}</b> -{' '}
                  {calculateShiftLength(
                    shiftFormData.startTime,
                    shiftFormData.endTime,
                    shiftFormData.breakMinutes,
                    shiftFormData.paidBreak
                  ).toFixed(2)}{' '}
                  hours
                </li>
              ))}
              <li className="mt-2">
                <b>Total</b> -{' '}
                {(
                  calculateShiftLength(
                    shiftFormData.startTime,
                    shiftFormData.endTime,
                    shiftFormData.breakMinutes,
                    shiftFormData.paidBreak
                  ) * datesToCopyTo.length
                ).toFixed(2)}{' '}
                hours
              </li>
            </ul>
          </Column>
        </Row>
      </Form>
    </SideModalDrawer>
  );
};

export default ShiftSideModal;
