import React, { useState, useCallback, useMemo } from 'react';
import RBCard from 'react-bootstrap/Card';
import { CreateButton } from 'shared/components/Buttons';
import ChildSelectList from '../../ChildSelectList';
import AddSubsidyModal from './AddEditSubsidyModal';
import ChildSubsidyTable, { EnrollmentTableRow } from './ChildSubsidyTable';
import SubsidyChildAttendanceModal from 'pages/USSubsidies/SubsidyChildren/SubsidyChildAttendanceModal';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toInitials } from 'shared/util/nameHelpers';
import { currencyFormat } from 'shared/util/currency';
import useFormatDate from 'shared/hooks/useFormatDate';
import { AgencyEnrollmentStatusType } from 'generated/graphql';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import Alert from 'shared/components/Alert';
import { useTranslation } from 'react-i18next';
import { ArchiveAgencyEnrollmentMutation } from './mutations';
import { showToast } from 'shared/components/Toast';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { useEnrollmentStatusMultiSelectFilter } from 'shared/Agency/AgencyEnrollments/hooks/useGetEnrollmentStatuses';

const agencyAccountChildQuery = gql`
  query ($accountId: ID!) {
    getAgencyAccountChildrenForAccount(accountId: $accountId) {
      id
      agencyId
      accountChildId
      accountId
      childFirstName
      childLastName
      accountName
      centerName
      archivedAt
      avatar {
        url
      }
      agencyAccountChildEnrollments {
        id
        startDate
        endDate
        subsidyPaymentPortion
        notes
        childSubsidyEnrollmentId
        ignoreCopay
        excludedContractIds
        status
      }
      agency {
        id
        name
        billingCalculationType
        subsidyPeriod
      }
    }
  }
`;

interface ExtendedEnrollment extends IAgencyAccountChildEnrollment {
  status: AgencyEnrollmentStatusType;
}
interface AgencyAccountChildData extends IAgencyAccountChild {
  agency: {
    id: string;
    name: string;
    billingCalculationType: IAgency['billingCalculationType'];
    subsidyPeriod: IAgency['subsidyPeriod'];
  };
  agencyAccountChildEnrollments: ExtendedEnrollment[];
}

interface IProps {
  account?: IAccount;
  loading: boolean;
}
const SubsidyTab: React.FC<IProps> = ({ account, loading: loadingAccountData }) => {
  const formatDate = useFormatDate();
  const [t] = useTranslation(['subsidies']);
  const [selectedChildId, setSelectedChildId] = useState<string>('');
  const [showAddEditSubsidyModal, setShowAddEditSubsidyModal] = useState<{
    open: boolean;
    agencyChild: IAgencyAccountChild | null;
    agencyEnrollment: IAgencyAccountChildEnrollment | null;
  }>({
    open: false,
    agencyChild: null,
    agencyEnrollment: null,
  });
  const [showAgencyChildEnrollmentModal, setShowAgencyChildEnrollmentModal] = useState<{
    open: boolean;
    agencyChild: IAgencyAccountChild | null;
  }>({ open: false, agencyChild: null });

  const {
    options: enrollmentStatusOptions,
    handleSelectChange: handleStatusFilterChange,
    selectedOptions: selectedStatusOptions,
    selectedStatusIds,
  } = useEnrollmentStatusMultiSelectFilter();

  const [enrollmentIdToArchive, setEnrollmentIdToArchive] = useState<string>();

  const getAccountChildIdFromChildId = useCallback(
    (childId: string): string => {
      return (account?.children ?? []).find((c) => c.id === childId)?.accountChildId ?? '';
    },
    [account]
  );

  const hasCreateChildAgencyPermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.AgencyChild,
    level: RoleLevelType.Create,
  });

  const {
    data,
    loading: loadingAgencyData,
    refetch: refetchEnrollmentData,
  } = useQuery<{
    getAgencyAccountChildrenForAccount: AgencyAccountChildData[];
  }>(agencyAccountChildQuery, { variables: { accountId: account?.id }, skip: !account, nextFetchPolicy: 'no-cache' });

  const [archiveEnrollmentFn, { loading: archivingEnrollment }] = useMutation(ArchiveAgencyEnrollmentMutation, {
    onCompleted: () => {
      refetchEnrollmentData();
      showToast(t('subsidies:agencies.archive-agancy-child-modal.success-toast-message'), 'success');
      setEnrollmentIdToArchive(undefined);
    },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string'
              ? err.message
              : // @ts-ignore - TypeScript doesn't like the dynamic lookup
                err.message?.message?.toString() ??
                  t('subsidies:agencies.archive-agancy-child-modal.error-toast-message');
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const loading = useMemo(() => loadingAccountData || loadingAgencyData, [loadingAccountData, loadingAgencyData]);

  const agencyEnrollments: EnrollmentTableRow[] = useMemo(() => {
    let enrollmentRows: EnrollmentTableRow[] = [];
    const agencyAccountChildren = data?.getAgencyAccountChildrenForAccount ?? [];
    const accountChildren = account?.children ?? [];
    const selectedAccountChildId = accountChildren.find((c) => c.id === selectedChildId)?.accountChildId;
    const childrenToConsider = selectedAccountChildId
      ? accountChildren.filter((c) => c.accountChildId === selectedAccountChildId)
      : accountChildren;
    for (let accountChild of childrenToConsider) {
      //We must remember here that there are multiple agencyAccountChildren per child. One for each agency they have ever been assigned to.
      //Therefore we need to loop over every AgencyAccountChild and then get all their enrollments from each of those.
      const agencyDataForChild = agencyAccountChildren.filter(
        (aac) => aac.accountChildId === accountChild.accountChildId
      );
      const enrollments: any[] = [];
      for (let a of agencyDataForChild) {
        for (let e of a.agencyAccountChildEnrollments) {
          if (!selectedStatusIds.includes(e.status)) continue;
          enrollments.push({
            id: e.id,
            accountChildId: accountChild.accountChildId,
            agencyEnrollmentId: e.childSubsidyEnrollmentId ?? undefined,
            agencyName: a.agency.name,
            avatarUrl: accountChild.avatar?.url,
            childFullName: accountChild.fullName,
            childInitials: toInitials(accountChild.firstname, accountChild.lastname),
            endDate: formatDate(e.endDate),
            enrollmentId: e.id,
            enrollmentStatus: e.status,
            expectedSubsidyAmount:
              a.agency.billingCalculationType === 'COPAY_AMOUNT'
                ? `${currencyFormat(e.subsidyPaymentPortion)} (Copay)`
                : `${currencyFormat(e.subsidyPaymentPortion)} (Subsidy)`,
            isArchived: false,
            notes: e.notes ?? '',
            startDate: formatDate(e.startDate),
            subsidyPeriodType: a.agency.subsidyPeriod,
          });
        }
      }

      enrollmentRows = enrollmentRows.concat(enrollments);
    }
    return enrollmentRows;
  }, [account?.children, data?.getAgencyAccountChildrenForAccount, formatDate, selectedChildId, selectedStatusIds]);

  const handleViewChildAttendance = useCallback(
    (accountChildId: string) => {
      const agencyChild = data?.getAgencyAccountChildrenForAccount.find((aac) => aac.accountChildId === accountChildId);
      if (!agencyChild) return;
      setShowAgencyChildEnrollmentModal({ open: true, agencyChild });
    },
    [data?.getAgencyAccountChildrenForAccount]
  );

  const handleEditEnrollment = useCallback(
    (enrollmentId: string) => {
      /**
       * I'm not trying to refactor AddEditSubsidyModal at the moment. It should be because the logic around adding and editing is
       * very confused and jumbled together. So instead we will just use the enrollment to provide that modal with the data it needs.
       */
      let agencyAccountChild: IAgencyAccountChild | undefined = undefined;
      let enrollmentToEdit: IAgencyAccountChildEnrollment | undefined = undefined;
      for (let agencyChild of data?.getAgencyAccountChildrenForAccount ?? []) {
        for (let enrollment of agencyChild.agencyAccountChildEnrollments) {
          if (enrollment.id === enrollmentId) {
            agencyAccountChild = agencyChild;
            enrollmentToEdit = enrollment;
          }
        }
      }
      if (!(agencyAccountChild && enrollmentToEdit)) return;
      setShowAddEditSubsidyModal({ open: true, agencyChild: agencyAccountChild, agencyEnrollment: enrollmentToEdit });
    },
    [data?.getAgencyAccountChildrenForAccount]
  );

  const handleOnArchiveEnrollment = useCallback((enrollmentId: string) => {
    setEnrollmentIdToArchive(enrollmentId);
  }, []);

  const handleAddEditEnrollmentClose = useCallback(
    (createdOrUpdatedEnrollment) => {
      setShowAddEditSubsidyModal({ open: false, agencyChild: null, agencyEnrollment: null });
      if (createdOrUpdatedEnrollment) refetchEnrollmentData();
    },
    [refetchEnrollmentData]
  );

  const enrollmentToArchive = useMemo(
    () => agencyEnrollments.find((e) => e.enrollmentId === enrollmentIdToArchive),
    [agencyEnrollments, enrollmentIdToArchive]
  );

  return (
    <>
      <div className="kt-account-tab kt-account-subsidy-tab">
        <RBCard className="mb-4 mx-0">
          <RBCard.Body className="px-4 py-3 d-flex flex-row align-items-center justify-content-between">
            <ChildSelectList
              children={(account?.children ?? []).filter((c) => !c.archivedAt)}
              selectedChildId={selectedChildId}
              onChildSelect={(childId) => setSelectedChildId(childId)}
            />
            {hasCreateChildAgencyPermission && (
              <CreateButton
                onClick={() => setShowAddEditSubsidyModal({ open: true, agencyChild: null, agencyEnrollment: null })}
              >
                Add to Subsidy
              </CreateButton>
            )}
          </RBCard.Body>
        </RBCard>
        <div style={{ display: 'flex' }}>
          <DropdownFilter
            options={enrollmentStatusOptions}
            title="Status"
            onFilterSelect={handleStatusFilterChange}
            selectedFilters={selectedStatusOptions}
          ></DropdownFilter>
          <div style={{ flex: 2 }}></div>
        </div>

        <ChildSubsidyTable
          isLoading={loading}
          data={agencyEnrollments}
          onViewChildAttendance={handleViewChildAttendance}
          onArchiveEnrollment={handleOnArchiveEnrollment}
          onEditChildAgencyEnrollment={handleEditEnrollment}
        />
      </div>
      {account && showAddEditSubsidyModal.open && (
        <AddSubsidyModal
          isOpen={showAddEditSubsidyModal.open}
          onClose={handleAddEditEnrollmentClose}
          account={account}
          agencyAccountChildToEdit={showAddEditSubsidyModal.agencyChild ?? undefined}
          agencyEnrollmentToEdit={showAddEditSubsidyModal.agencyEnrollment ?? undefined}
          agencyEnrollments={(data?.getAgencyAccountChildrenForAccount ?? []).filter(
            (datum) => selectedChildId === '' || datum.accountChildId === getAccountChildIdFromChildId(selectedChildId)
          )}
        />
      )}
      {showAgencyChildEnrollmentModal.agencyChild && (
        <SubsidyChildAttendanceModal
          isOpen={showAgencyChildEnrollmentModal.open}
          agencyAccountChild={showAgencyChildEnrollmentModal.agencyChild}
          onClose={() => setShowAgencyChildEnrollmentModal({ open: false, agencyChild: null })}
        />
      )}
      <ConfirmationModal
        title={t('subsidies:agencies.archive-agancy-child-modal.title', {
          childName: enrollmentToArchive?.childFullName ?? '',
        })}
        show={!!enrollmentIdToArchive}
        onHide={() => setEnrollmentIdToArchive(undefined)}
        hideOnCallback={false}
        primaryChoice={t('subsidies:agencies.archive-agancy-child-modal.primary-button-text')}
        primaryCallback={() => archiveEnrollmentFn({ variables: { input: { id: enrollmentIdToArchive } } })}
        primaryButtonProps={{ variant: 'danger', loading: archivingEnrollment }}
      >
        <p>{t('subsidies:agencies.archive-agancy-child-modal.body')}</p>
        <Alert header="Please Note" variant="info">
          <p>{t('subsidies:agencies.archive-agancy-child-modal.confirmation-info-text')}</p>
        </Alert>
      </ConfirmationModal>
    </>
  );
};

export default SubsidyTab;
