import React, { useCallback } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import classNames from 'classnames';
import Button from 'shared/components/Buttons';
import './_confirmationModal.scss';

const NO_OP = () => {};

interface IProps extends ModalProps {
  className?: string;
  title?: string;
  subtitle?: string;
  onHide?: () => void;
  noSecondaryChoice?: boolean;
  noPrimaryChoice?: boolean;
  primaryChoice?: string;
  primaryCallback?: () => void;
  primaryButtonProps?: any;
  secondaryChoice?: string;
  secondaryCallback?: () => void;
  secondaryButtonProps?: any;
  scrollable?: boolean;
  children?: React.ReactNode | null;
  hideOnCallback?: boolean;
}

const ConfirmationModal: React.FC<IProps> = ({
  className,
  title = '',
  subtitle = '',
  onHide = NO_OP,
  noSecondaryChoice = false,
  noPrimaryChoice = false,
  primaryChoice = 'Save',
  primaryCallback = NO_OP,
  primaryButtonProps = {},
  secondaryChoice = 'Cancel',
  secondaryCallback = NO_OP,
  secondaryButtonProps = {},
  scrollable,
  children,
  hideOnCallback = true,
  ...rest
}) => {
  const affirmativeCallbackFunction = useCallback(() => {
    // may need to remove
    if (onHide && hideOnCallback) onHide();
    if (primaryCallback) primaryCallback();
  }, [onHide, hideOnCallback, primaryCallback]);

  const secondaryCallbackFunction = useCallback(() => {
    if (onHide) onHide();
    if (secondaryCallback) secondaryCallback();
  }, [onHide, secondaryCallback]);

  return (
    <Modal
      backdrop="static"
      centered
      dialogClassName={`confirmation-modal ${className || ''}`}
      onHide={onHide}
      scrollable={scrollable}
      {...rest}
    >
      <Modal.Header
        closeButton
        className={classNames({
          'border-bottom-1': !scrollable,
        })}
      >
        <div className="modal-header-title-container d-flex flex-column">
          <Modal.Title as="h3">{title}</Modal.Title>
          {subtitle && <p>{subtitle}</p>}
        </div>
      </Modal.Header>
      <Modal.Body
        className={classNames('pt-5', {
          'my-3': scrollable,
        })}
      >
        {children}
      </Modal.Body>
      {(!noPrimaryChoice || !noSecondaryChoice) && (
        <Modal.Footer className={`border-width-${scrollable ?? 1}`}>
          {!noSecondaryChoice && (
            <Button
              id="confirmation-modal-secondary-btn"
              variant="light"
              {...secondaryButtonProps}
              onClick={secondaryCallbackFunction}
            >
              {secondaryChoice}
            </Button>
          )}
          {!noPrimaryChoice && (
            <Button
              id="confirmation-modal-primary-btn"
              variant="primary"
              {...primaryButtonProps}
              onClick={affirmativeCallbackFunction}
            >
              {primaryChoice}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
