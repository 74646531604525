import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import AccountSelectWithCenter from 'shared/components/Select/AccountSelectWithCenter';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { capitalize } from 'shared/util/string';
import colors from '../../../_colors.module.scss';

interface IFormStateShape {
  centerId: string;
  accountId: string | null;
  startDate: string | null;
  endDate: string | null;
  format: 'EXCEL' | 'PDF';
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  onSubmit: (
    centerId: string,
    accountId: string | null,
    startDate: string,
    endDate: string,
    format: 'EXCEL' | 'PDF'
  ) => void;
  onClose: () => void;
  showReportFormat?: boolean;
  className?: string;
}

enum ReportFilters {
  CENTER = 'Center',
  ACCOUNT = 'Account',
}

const TimeframeSingleCenterReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  showReportFormat,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<IFormStateShape>({
    centerId: '',
    accountId: '',
    startDate: null,
    endDate: null,
    format: 'PDF',
  });
  const centerSelectOptions: ICenter[] = useGetActiveCenters();
  const [selectedReportFilter, setSelectedReportFilter] = useState(ReportFilters.CENTER);

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        centerId: '',
        accountId: '',
        startDate: null,
        endDate: null,
        format: 'PDF',
      });
    }
  }, [isOpen]);

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (
      ((selectedReportFilter === ReportFilters.CENTER && formData.centerId) ||
        (selectedReportFilter === ReportFilters.ACCOUNT && formData.accountId)) &&
      formData.startDate &&
      formData.endDate
    ) {
      onSubmit(formData.centerId, formData.accountId, formData.startDate, formData.endDate, formData.format);
    }
  }, [formData, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({
      centerId: '',
      accountId: '',
      startDate: null,
      endDate: null,
      format: 'PDF',
    });
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (selectedReportFilter) {
      setFormData((prev) => ({ ...prev, accountId: '', centerId: '' }));
    }
  }, [selectedReportFilter]);

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{
        disabled:
          (selectedReportFilter === ReportFilters.CENTER && !formData.centerId) ||
          (selectedReportFilter === ReportFilters.ACCOUNT && !formData.accountId) ||
          !formData.startDate ||
          !formData.endDate,
        loading: isLoading,
      }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      className={className}
    >
      <Row>
        <Col>
          <div className="form-group">
            <Form.Label>
              Report Type
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color={colors.red} />
            </Form.Label>
            {Object.values(ReportFilters).map((filter) => {
              return (
                <div className="d-block">
                  <input
                    type="radio"
                    name="report-filter"
                    checked={selectedReportFilter === filter}
                    onChange={() => setSelectedReportFilter(filter)}
                  />
                  <label htmlFor="report-filter" className="pl-2">
                    {filter}
                  </label>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
      {selectedReportFilter === ReportFilters.CENTER && (
        <Row>
          <Col>
            <Select
              required
              id="center-input"
              label={capitalize(t('spelling.center'))}
              aria-label={`Select ${capitalize(t('spelling.center'))}`}
              value={formData.centerId}
              options={centerSelectOptions.map((c) => ({ value: c.id, label: c.name }))}
              onChange={(option: any) => setFormData((prev) => ({ ...prev, accountId: '', centerId: option.value }))}
            />
          </Col>
        </Row>
      )}
      {selectedReportFilter === ReportFilters.ACCOUNT && (
        <Row>
          <Col>
            <AccountSelectWithCenter
              selectedAccountIds={[formData.accountId!]}
              onSelect={(val) => setFormData((prev) => ({ ...prev, ...val![0] }))}
              required
              isMulti={false}
              disableAllOption={true}
              centerIds={centerSelectOptions.map((c) => c.id)}
              statusType={'All'}
              appearance="detailed"
              showAccountStatus
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <DateInput
            required
            label={capitalize(t('spelling.start-date'))}
            date={formData.startDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, startDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(date: moment.Moment) =>
              formData.endDate ? date.isSameOrAfter(moment(formData.endDate), 'date') : false
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            required
            label={capitalize(t('spelling.end-date'))}
            date={formData.endDate}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, endDate: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={(date: moment.Moment) =>
              formData.startDate ? date.isSameOrBefore(moment(formData.startDate), 'date') : false
            }
          />
        </Col>
      </Row>

      {showReportFormat && (
        <Row>
          <Col>
            <Select
              options={[
                { value: 'EXCEL', label: 'EXCEL' },
                { value: 'PDF', label: 'PDF' },
              ]}
              label={`${capitalize(t('spelling.format'))}(s)`}
              value={formData.format ?? { value: 'EXCEL', label: 'EXCEL' }}
              onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
              className="mb-0"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Col>
        </Row>
      )}
    </SideModalDrawer>
  );
};

export default TimeframeSingleCenterReportModal;
