import { gql, useQuery } from '@apollo/client';
import { faAngleDown, faDollarSign, faPlus, faTimes, faUndo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import classnames from 'classnames';
import { transactionFields } from 'gql/transaction/fields';
import { useApproveFlaggedPayment } from 'gql/transaction/mutations';
import { useGetTransactionTypes } from 'gql/transaction/queries';
import { orderBy } from 'lodash';
import CancelPaymentModal from 'pages/BillingPayments/components/CancelPaymentModal';
import ApplyDiscountToTransactionModal from 'pages/BillingTransactions/components/ApplyDiscountToTransactionModal';
import RemoveDiscountFromTransactionModal from 'pages/BillingTransactions/components/RemoveDiscountFromTransactionModal';
import ReverseTransactionModal from 'pages/BillingTransactions/components/ReverseTransactionModal';
import { updateTransaction } from 'pages/BillingTransactions/duck/action';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButtonCircle } from 'shared/components/Buttons';
import Currency from 'shared/components/Currency';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { showToast } from 'shared/components/Toast';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useFormatDate from 'shared/hooks/useFormatDate';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { RootState } from 'store/reducers';
import './OrderedTransactionsTable.scss';
import DateInput from 'shared/components/DateInput';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import useAccountBalances from '../../hooks/useAccountBalances';
import AddManualPaymentModal from 'shared/components/AddManualPaymentModal';
import CreateTransactionModal from './CreateTransactionModal';
import TransactionExpandedRow from './TransactionExpandedRow';
import { PeriodBasedAccountBalanceTile } from './PeriodBasedAccountBalances';

import './OrderedTransactionsTable.scss';

interface IProps {
  accountId: string;
}
export function OrderedTransactionsTable(props: IProps) {
  const formatDate = useFormatDate();
  const dispatch = useDispatch();

  const hasCreateBaseTransactionPermissions = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });

  const { refetch: refetchBalances } = useAccountBalances(props.accountId);

  const { activityDateFilter, transactionTypeFilter, childFilter, pages, searchBar, clearFilters } =
    useTransactionFilter(props.accountId);

  const {
    data,
    loading,
    refetch: refetchAccountTransactions,
  } = useReadAccountTransactions(props.accountId, {
    transactionTypeIds: transactionTypeFilter.selectedTransactionTypes,
    childrenIds: childFilter.selectedChildrenIds,
    searchText: searchBar.searchText,
    minDate: activityDateFilter.minDate,
    maxDate: activityDateFilter.maxDate,
    skip: pages.skip,
    take: pages.take,
  });

  const refetch = useCallback(() => {
    refetchAccountTransactions();
    refetchBalances();
  }, [refetchAccountTransactions, refetchBalances]);

  // Apply Discount to transcation
  const [applyDiscountToTransactionModal, setApplyDiscountToTransactionModal] = useState<{
    open: boolean;
    transaction: ITransaction | null;
  }>({
    open: false,
    transaction: null,
  });
  const handleApplyDiscountComplete = useCallback(() => refetch(), [refetch]);
  const handleApplyDiscountClose = useCallback(() => {
    setApplyDiscountToTransactionModal({ open: false, transaction: null });
  }, []);

  const handleApplyDiscountToTransactionClick = useCallback((transaction) => {
    setApplyDiscountToTransactionModal({ open: true, transaction });
  }, []);
  // End Apply Discount to transaction

  // Remove Discount from Transaction
  const [removeDiscountModal, setRemoveDiscountModal] = useState<{
    open: boolean;
    transaction: ITransaction | null;
    discountTransaction: ITransaction | null;
  }>({ open: false, transaction: null, discountTransaction: null });

  const handleRemoveDiscountComplete = useCallback(() => refetch(), [refetch]);
  const handleRemoveDiscountModalClose = useCallback(() => {
    setRemoveDiscountModal({ open: false, transaction: null, discountTransaction: null });
  }, []);
  const handleRemoveDiscountFromTransactionClick = useCallback((transaction, discountTransaction) => {
    setRemoveDiscountModal({ open: true, transaction, discountTransaction });
  }, []);

  // End Remove Discount from transaction

  // Reverse Transaction
  const [reverseTransaction, setReverseTransaction] = useState<{ open: boolean; transaction: ITransaction | null }>({
    open: false,
    transaction: null,
  });
  const handleReverseTransactionButtonClick = useCallback((transaction) => {
    setReverseTransaction({ open: true, transaction });
  }, []);
  const handleReverseTransactionModalClose = useCallback(() => {
    setReverseTransaction({ open: false, transaction: null });
  }, []);
  const handleReverseTransactionComplete = useCallback(() => {
    refetch();
  }, [refetch]);
  // End Reverse Transaction

  // Create Payment
  const [showAddManualPaymentModal, setShowAddManualPaymentModal] = useState<boolean>(false);
  const handleCreatePaymentClick = useCallback(() => {
    setShowAddManualPaymentModal(true);
  }, []);
  const handleCreatePaymentClose = useCallback(() => {
    setShowAddManualPaymentModal(false);
  }, []);

  // End Create Payment

  // Cancel Payment
  const [cancelPaymentModalState, setCancelPaymentModalState] = useState<{
    open: boolean;
    payment: IPayment | null;
  }>({ open: false, payment: null });

  const handleCancelPaymentModalClose = useCallback(() => {
    setCancelPaymentModalState({ open: false, payment: null });
  }, []);

  const handleCancelPaymentButtonClick = useCallback((payment) => {
    setCancelPaymentModalState({ open: true, payment });
  }, []);

  const handleCancelPaymentComplete = useCallback(() => refetch(), [refetch]);
  // End Cancel Payment

  //Approve Payment
  const [approveFlaggedPaymentFn] = useApproveFlaggedPayment({
    onCompleted: (result) => {
      if (result.approvePayment) {
        dispatch(updateTransaction(result.approvePayment));
        showToast('Approved payment successfully.', 'success');
      }
    },
    onError: (err) => {
      showToast(
        `${err.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleApprovePayment = useCallback(
    (payment: IPayment) => {
      if (payment) {
        approveFlaggedPaymentFn({
          variables: {
            paymentId: payment.id,
          },
        });
      }
    },
    [approveFlaggedPaymentFn]
  );
  // End Approve Payment

  // Create Transaction
  const [createTransactionModalIsOpen, setCreateTransactionModalIsOpen] = useState<{
    filterBy: 'isDebit' | 'isCredit' | undefined;
    open: boolean;
  }>({
    filterBy: undefined,
    open: false,
  });
  const handleCreateButtonClick = useCallback(
    (filterBy: 'isDebit' | 'isCredit') => setCreateTransactionModalIsOpen({ filterBy: filterBy, open: true }),
    []
  );
  const handleCreateTransactionModalClose = useCallback(
    () => setCreateTransactionModalIsOpen({ filterBy: undefined, open: false }),
    []
  );
  const handleCreateTransactionComplete = useCallback(() => refetch(), [refetch]);
  // End Create Transaction

  return (
    <div className="mx-auto">
      <div className="d-flex align-items-center mb-4">
        <h2>Activity</h2>
        {hasCreateBaseTransactionPermissions && (
          <Dropdown className="ml-auto">
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              <FontAwesomeIcon className="mr-2" icon={faPlus} />
              Add
              <FontAwesomeIcon className="ml-4" icon={faAngleDown} size="1x" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                key="charge"
                onClick={() => handleCreateButtonClick('isDebit')}
                disabled={!hasCreateBaseTransactionPermissions}
              >
                Charge
              </Dropdown.Item>
              <Dropdown.Item
                key="credit"
                onClick={() => handleCreateButtonClick('isCredit')}
                disabled={!hasCreateBaseTransactionPermissions}
              >
                Credit
              </Dropdown.Item>
              <Dropdown.Item
                key="payment"
                onClick={handleCreatePaymentClick}
                disabled={!hasCreateBaseTransactionPermissions}
              >
                Payment
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      <div>
        <PeriodBasedAccountBalanceTile accountId={props.accountId} className="mb-4" />
      </div>
      <div className="ordered-transactions-container">
        <DataTable
          data={data.data}
          dataSize={data.totalCount}
          showLoadingOverlay={loading}
          showPagination
          activePage={pages.page}
          pageSize={pages.pageSize}
          onPageChange={pages.changePage}
          onSizePerPageChange={pages.changePageSize}
          onlyOneExpanding
          showSelect={false}
          onSort={() => {}}
          columns={[
            { dataField: 'transactionNumber', text: 'Transaction ID', sort: false },
            { dataField: 'createdAt', text: 'Created On', formatter: (date: string) => formatDate(date) },
            { dataField: 'description', text: 'Description' },
            {
              dataField: 'transactionType.name',
              text: 'Type',
              formatter(transactionType) {
                if (transactionType === 'Automated CC' || transactionType === 'Automated ACH/DD') {
                  return 'Automated Payment';
                }
                if (transactionType === 'Manual CC' || transactionType === 'Manual ACH/DD') {
                  return 'Manual Payment';
                } else return transactionType;
              },
            },
            {
              dataField: 'appliedToAccountChild',
              text: 'Child Name',
              sort: false,
              formatter: (accountChild: IAccountChild) =>
                !!accountChild ? `${accountChild.firstname} ${accountChild.lastname}` : '',
            },
            {
              dataField: 'amount',
              text: 'Amount',
              sort: false,
              align: 'right',
              headerAlign: 'right',
              // {/* this is set to directedNumbers because we want this view to always show the - sign for negative numbers */}
              formatter: (total: number, row: ITransaction) => (
                <Currency payment={!!row.payment} amount={total} display="DirectedNumbers" />
              ),
            },
            {
              dataField: 'reversedTransactionId',
              text: '',
              formatter: (id: string) => (
                <span className={classnames('fa-layers fa-fw mx-2', { invisible: !id })}>
                  <FontAwesomeIcon icon={faUndo} size="lg" color={colors['dark-gray']} />
                  <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
                </span>
              ),
            },
          ]}
          expandRow={(transaction) => {
            return (
              <TransactionExpandedRow
                onAddDiscountToTransaction={handleApplyDiscountToTransactionClick}
                onRemoveDiscountFromTransaction={handleRemoveDiscountFromTransactionClick}
                handleReverse={handleReverseTransactionButtonClick}
                transaction={transaction}
                isPaymentTransactionsOnly={false}
                onApproveFlaggedPayment={handleApprovePayment}
                onRejectFlaggedPayment={handleCancelPaymentButtonClick}
                approvePaymentLoading={false}
              />
            );
          }}
          renderHeader={() => {
            return (
              <div className="ordered-transactions-table__table-header">
                <div>
                  <TableSearch
                    placeholder={'Search Activity'}
                    onChange={searchBar.setSearchText}
                    className="ordered-transaction-table__table-search"
                  />
                </div>
                <div>
                  <div>Created On: </div>
                  <DateInput
                    placeholder="Min Date"
                    date={activityDateFilter.minDate ?? null}
                    onDateSelect={activityDateFilter.setMinDate}
                  ></DateInput>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> - </div>
                  <DateInput
                    placeholder="Max Date"
                    date={activityDateFilter.maxDate ?? null}
                    onDateSelect={activityDateFilter.setMaxDate}
                  ></DateInput>
                </div>
                <div>
                  <DropdownFilter
                    title="Child"
                    options={childFilter.childFilterOptions}
                    selectedFilters={childFilter.selectedChildFilterOptions}
                    onFilterSelect={childFilter.setSelectedChildren}
                  ></DropdownFilter>
                  <DropdownFilter
                    title="Type"
                    selectedFilters={transactionTypeFilter.selectedTransactionTypeOptions}
                    options={transactionTypeFilter.transactionTypeOptions}
                    onFilterSelect={transactionTypeFilter.setTransactionTypeOptions}
                  />
                  <IconButtonCircle
                    icon={faTimes}
                    onClick={clearFilters}
                    tooltipDirection="bottom"
                    tooltipText="Clear Filters"
                  />
                </div>
              </div>
            );
          }}
        ></DataTable>
      </div>
      {applyDiscountToTransactionModal.transaction && (
        <ApplyDiscountToTransactionModal
          isOpen={applyDiscountToTransactionModal.open}
          transaction={applyDiscountToTransactionModal.transaction}
          onClose={handleApplyDiscountClose}
          onCompleted={handleApplyDiscountComplete}
        ></ApplyDiscountToTransactionModal>
      )}
      {removeDiscountModal.transaction && removeDiscountModal.discountTransaction && (
        <RemoveDiscountFromTransactionModal
          isOpen={removeDiscountModal.open}
          transaction={removeDiscountModal.transaction}
          discountTransaction={removeDiscountModal.discountTransaction}
          onClose={handleRemoveDiscountModalClose}
          onComplete={handleRemoveDiscountComplete}
        />
      )}
      {reverseTransaction.transaction && (
        <ReverseTransactionModal
          isOpen={reverseTransaction.open}
          onClose={handleReverseTransactionModalClose}
          transaction={reverseTransaction.transaction}
          onComplete={handleReverseTransactionComplete}
        />
      )}
      <CancelPaymentModal
        isOpen={cancelPaymentModalState.open}
        payment={cancelPaymentModalState.payment}
        onClose={handleCancelPaymentModalClose}
        onComplete={handleCancelPaymentComplete}
      />
      <CreateTransactionModal
        isOpen={createTransactionModalIsOpen.open}
        onClose={handleCreateTransactionModalClose}
        accountId={props.accountId}
        onComplete={handleCreateTransactionComplete}
        filterBy={createTransactionModalIsOpen.filterBy}
      />

      <AddManualPaymentModal
        isOpen={showAddManualPaymentModal}
        onClose={handleCreatePaymentClose}
        accountId={props.accountId}
        onComplete={handleCreateTransactionComplete}
      />
    </div>
  );
}

/**
 * Transaction Filter functionality
 * @param accountId
 * @returns
 */
function useTransactionFilter(accountId: string) {
  const {
    options: transactionTypeOptions,
    setSelectedOptions: setSelectedTransactionTypeOptions,
    selectedTransactionTypes,
    selectedOptions: selectedTransactionTypeOptions,
  } = useTransactionTypeMultiSelect();

  const {
    options: childFilterOptions,
    selectedOptions: selectedChildFilterOptions,
    setSelectedOptions: setSelectedChildFilterOptions,
    selectedChildrenIds,
  } = useAccountChildMultiSelect(accountId);

  const { page, pageSize, skip, take, changePage, changePageSize } = useSkipTakePaging();

  const [searchText, setSearchText] = useState<string>('');

  const [minDate, setMinDate] = useState<string | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<string | undefined>(undefined);

  const handleMinDateChange = useCallback((date) => {
    setMinDate(date ? moment(date).format('YYYY-MM-DD') : undefined);
  }, []);

  const handleMaxDateChange = useCallback((date) => {
    setMaxDate(date ? moment(date).format('YYYY-MM-DD') : undefined);
  }, []);

  const handleChildFilterChange = useCallback(
    (selectedOptions) => {
      changePage(1);
      setSelectedChildFilterOptions(selectedOptions);
    },
    [changePage, setSelectedChildFilterOptions]
  );

  const handleTransactionTypeFilterChange = useCallback(
    (selectedOptions) => {
      changePage(1);
      setSelectedTransactionTypeOptions(selectedOptions);
    },
    [changePage, setSelectedTransactionTypeOptions]
  );

  const handleClearFilters = useCallback(() => {
    setSelectedChildFilterOptions([]);
    setSelectedTransactionTypeOptions([]);
    setMinDate(undefined);
    setMaxDate(undefined);
  }, [setSelectedChildFilterOptions, setSelectedTransactionTypeOptions]);

  return {
    activityDateFilter: { minDate, maxDate, setMinDate: handleMinDateChange, setMaxDate: handleMaxDateChange },
    childFilter: {
      childFilterOptions,
      selectedChildFilterOptions,
      selectedChildrenIds,
      setSelectedChildren: handleChildFilterChange,
    },
    pages: { changePageSize, page, pageSize, skip, take, changePage },
    searchBar: { searchText, setSearchText },
    transactionTypeFilter: {
      selectedTransactionTypeOptions,
      selectedTransactionTypes,
      transactionTypeOptions,
      setTransactionTypeOptions: handleTransactionTypeFilterChange,
    },
    clearFilters: handleClearFilters,
  };
}

/**
 * This function is designed to help translate between our data tables that speak in pages and pageSize
 * and apis that have implemented a skip/take strategy.
 * @returns
 */
function useSkipTakePaging() {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [take, setTake] = useState<number>(25);
  const skip = useMemo(() => {
    return (pageNumber - 1) * take;
  }, [pageNumber, take]);

  const changePageSize = useCallback((pageSize: number) => {
    setPageNumber(1);
    setTake(pageSize);
  }, []);

  return { page: pageNumber, pageSize: take, changePage: setPageNumber, changePageSize, skip, take };
}

function useReadAccountTransactions(
  accountId: string,
  options: {
    transactionTypeIds: string[];
    childrenIds: string[];
    searchText?: string;
    skip: number;
    take: number;
    minDate?: string;
    maxDate?: string;
  }
) {
  const query = gql`
    query($input: ReadAccountTransactionsInput) {
      readAccountTransactions(input: $input) {
        data {
          ${transactionFields}
        }
        totalCount
      }
    }
  `;
  const { data, loading, refetch } = useQuery(query, {
    variables: {
      input: {
        accountId,
        transactionTypeIds: options.transactionTypeIds,
        childrenIds: options.childrenIds,
        searchText: options.searchText ?? undefined,
        minDate: options.minDate,
        maxDate: options.maxDate,
        skip: options.skip,
        take: options.take,
      },
    },
  });
  return { data: data?.readAccountTransactions ?? { data: [], totalCount: 0 }, loading, refetch };
}

function useAccountChildMultiSelect(accountId: string) {
  const query = gql`
    query ($accountId: ID!) {
      getAccountById(id: $accountId) {
        children {
          id
          fullName
        }
      }
    }
  `;
  const { data } = useQuery(query, { variables: { accountId } });
  const children: { id: string; fullName: string }[] = useMemo(() => {
    return data?.getAccountById.children ?? [];
  }, [data?.getAccountById.children]);

  const [selectedOptions, setSelectedOptions] = useState<{ label: string; value: string }[]>([]);
  const options = useMemo(() => {
    return children.map((c) => ({ label: c.fullName, value: c.id }));
  }, [children]);
  const selectedChildrenIds = useMemo(() => {
    return selectedOptions.map((o) => o.value);
  }, [selectedOptions]);
  return { options, selectedOptions, setSelectedOptions, selectedChildrenIds };
}

function useTransactionTypeMultiSelect() {
  const user = useSelector((state: RootState) => state.user);
  const { data: centers } = useGetActiveCentersWithLoading();
  const { data: getTransactionTypesData } = useGetTransactionTypes({
    variables: {
      businessId: user?.entityId ?? '',
      centerIds: centers ? centers.map((c) => c.id) : [],
      manualTransactionTypesOnly: false,
    },
    skip: !user?.entityId,
  });
  const [selectedOptions, setSelectedOptions] = useState<{ label: string; value: string }[]>([]);
  const options = useMemo(() => {
    return orderBy(
      getTransactionTypesData?.getTransactionTypes.map((tt) => ({ value: tt.id, label: tt.name })) ?? [],
      (o) => o.label,
      'asc'
    );
  }, [getTransactionTypesData?.getTransactionTypes]);
  const selectedTransactionTypes = useMemo(() => {
    return selectedOptions.map((o) => o.value);
  }, [selectedOptions]);
  return {
    selectedTransactionTypes,
    options,
    setSelectedOptions,
    selectedOptions: selectedOptions,
  };
}
