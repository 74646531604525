import { roleshipFields } from 'gql/roleship/fields';
import businessFields from 'gql/business/fields';
import centerFields from 'gql/center/fields';
import { classFields } from 'gql/class/fields';
import { tagFields } from 'gql/tag/fields';
import { emergencyContactFields } from 'gql/emergencyContact/fields';
import { staffPositionFields } from 'gql/position/fields';
import { allergyFields } from 'gql/allergy/fields';
import { personFields } from 'gql/person/fields';
import { roleFields } from 'gql/role/fields';

export const staffTrainingFields: string = `
  name
  expiryDate
`;

export const scheduleAvailabilityFields: string = `
  approved {
    dayOfWeek
    times {
      start
      end
    }
  }
  requested {
    dayOfWeek
    times {
      start
      end
    }
  }
`;

export const staffNoteFields: string = `
  id
  personId
  type
  title
  text
  submittedByPersonId
  submittedByPerson {
    ${personFields}
  }
  dateSubmitted
`;

let staffFields: string = `
  ${personFields}
  employmentStatus
  roleship {
    ${roleshipFields}
  }
  role {
    ${roleFields}
  }
  entityId
  primaryCenterId
  employmentStatus
  employmentStartDate
  employmentEndDate
  reasonForLeaving
  createdAt
  invitedAt
  requestedToJoinAt
  pin
  verifiedEmail
  isEmailVerified
  pocCurrentlyInClassId
  primaryEmergencyContact {
    ${emergencyContactFields}
  }
  additionalEmergencyContacts {
    ${emergencyContactFields}
  }
  positions {
    ${staffPositionFields}
  }
  tags {
    ${tagFields}
  }
  availability {
    ${scheduleAvailabilityFields}
  }
  staffTrainings {
    ${staffTrainingFields}
  }
  allergies {
    ${allergyFields}
  }

  classAssignments {
    ${classFields}
  }
`;

staffFields = staffFields.concat(`
  entity {
    ${businessFields}
  }
  primaryCenter {
    ${centerFields}
  }
  roleship {
    ${roleshipFields}
  }
  role {
    ${roleFields}
  }
`);

export default staffFields;
