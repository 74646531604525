import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import { Col, Row } from 'react-bootstrap';
import CCSAltArrangementTable from './components/CCSAltArrangementTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetAltArrangements, useGetProviderDebts } from 'gql/ccssDebts/queries';
import { useGetProvidersByBusinessId } from 'gql/business/queries';

interface ISort {
  field: string;
  direction: ElasticsearchSortDirection;
}

const CCSAltArrangements: React.FC = () => {
  const entityId = useSelector((state: RootState) => state.user?.entityId) ?? '';
  const { data: providers, loading: getProvidersLoading } = useGetProvidersByBusinessId({
    variables: {
      businessId: entityId,
    },
    skip: entityId === '',
  });

  const [tableState, tableFunctions] = useDatatableState();
  const providerList = providers?.getProvidersByBusinessId || [];
  const [activeFilters, setActiveFilters] = useState<ICcsDebtFilter>({
    sources: [],
    searchText: '',
  });

  const handleFilterChange = (newFilters: ICcsDebtFilter) => {
    setActiveFilters(() => newFilters);
  };

  const { data, loading } = useGetAltArrangements({
    variables: {
      providerId: activeFilters.providerId || '',
      businessId: entityId,
    },
    skip: !Boolean(activeFilters.providerId),
  });

  useEffect(() => {
    if (providerList.length > 0) {
      setActiveFilters({ ...activeFilters, providerId: providerList[0].id });
    }
  }, [providerList]);

  const arrangements = data?.getAlternateArrangements || [];

  return (
    <div className="mx-auto">
      <Row>
        <Col>
          <CCSAltArrangementTable
            data={arrangements || []}
            dataSize={0}
            loading={loading || getProvidersLoading}
            pageSize={tableState.pageSize}
            activePage={tableState.activePage}
            onPageChange={tableFunctions.changePage}
            onSizePerPageChange={tableFunctions.changeSizePerPage}
            activeFilters={activeFilters}
            onActiveFilterChange={handleFilterChange}
            providers={providerList}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CCSAltArrangements;
