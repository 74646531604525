import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { defaultNoteFields } from './fields';

// default note types
type DefaultNoteCategories =
  | 'General'
  | 'Conflict'
  | 'Concern'
  | 'Development'
  | 'Education'
  | 'Behavior'
  | 'Attendance'
  | 'Billing';

type NoteAreas = 'Account';

type NoteAssociationObjectType = 'Account' | 'AccountChild' | 'AccountContact' | 'Staff';

// thinking for when we allow custom note types, this type can then be expanded upon
type NoteCategoryType = DefaultNoteCategories;

interface INote {
  id: string;
  businessId: string;
  areaType: string;
  attachedToEntityId: string;
  title: string;
  noteType: string;
  comment: string;
  createdBy: string;
  createdAt: string;
  associatedWith: INoteAssociation[];
  createdByAccount: IStaff;
}

interface INoteAssociation {
  objectId: string;
  objectType: NoteAssociationObjectType;
  display?: {
    displayValue: string;
    displayAvatar?: string;
  };
}

interface ICreateNoteInput {
  input: {
    associations?: INoteAssociation[];
    businessId: string;
    attachedToId: string;
    title: string;
    comment: string;
    noteType: string;
    areaType: string;
  };
}

interface IArchiveNoteInput {
  input: {
    businessId: string;
    noteId: string;
    areaType: NoteAreas;
  };
}

export const CREATE_INTERNAL_ACCOUNT_NOTE = gql`
  mutation($input: CreateNoteInput!) {
    createNote(input: $input){
       ${defaultNoteFields}
    }
  }
`;

export const useCreateInternalAccountNote = (input?: MutationHookOptions<INote, ICreateNoteInput>) =>
  useMutation<INote, ICreateNoteInput>(CREATE_INTERNAL_ACCOUNT_NOTE, input);

export const ARCHIVE_INTERNAL_ACCOUNT_NOTE = gql`
  mutation ($input: ArchiveNoteInput!) {
    archiveNote(input: $input) {
      id
      title
    }
  }
`;

export const useArchiveInternalAccountNote = (input?: MutationHookOptions<INote, IArchiveNoteInput>) =>
  useMutation<INote, IArchiveNoteInput>(ARCHIVE_INTERNAL_ACCOUNT_NOTE, input);
