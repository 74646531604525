import { useQuery, gql, useMutation } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isRegion } from 'shared/util/region';

function CacheKey(businessId: string) {
  return `billing-business-settings:${businessId}:USMode`;
}

const usModeSettingsQuery = gql`
  query ($businessId: ID!) {
    fetchBillingBusinessSettings(businessId: $businessId) {
      usMode
    }
  }
`;

/**
 * Use this hook for reading the USMode setting. I.e. for switching views or functionality based on the setting
 * @see useManageUSModeSetting for scenarios where you are actively changing the setting
 * @param businessId
 * @returns
 */
export function useUSModeSettings(businessId: string | null | undefined) {
  const isUS = isRegion('US');
  const cacheKey = businessId ? CacheKey(businessId) : '';
  const [usMode, setUSMode] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    const cachedUsModeSetting = localStorage.getItem(cacheKey);
    if (cachedUsModeSetting !== undefined && cachedUsModeSetting !== null)
      setUSMode(cachedUsModeSetting === 'true' ? true : false);
  }, [cacheKey]);
  const { loading: loadingSettings } = useQuery(usModeSettingsQuery, {
    variables: { businessId },
    skip: !(businessId && isUS),
    onCompleted: (d) => {
      if (d.fetchBillingBusinessSettings) {
        localStorage.setItem(cacheKey, d.fetchBillingBusinessSettings?.usMode ? 'true' : 'false');
        setUSMode(d.fetchBillingBusinessSettings?.usMode);
      }
    },
  });

  const loading = useMemo(() => {
    return usMode === undefined && loadingSettings;
  }, [loadingSettings, usMode]);

  return { usMode, loading };
}

/**
 * Use this hook when you are in a scenario where you are actively changing the setting.
 * @see useUSModeSettings if you only need to read the setting.
 * @param businessId
 * @returns
 */
export function useManageUSModeSetting(businessId: string | null | undefined) {
  const {
    data,
    loading: loadingSettings,
    refetch,
  } = useQuery(usModeSettingsQuery, { variables: { businessId }, skip: !businessId });
  const [turnOnUSModeFn, { loading: turningOn }] = useMutation(
    gql`
      mutation ($input: USModeInput!) {
        turnOnUSMode(input: $input) {
          usMode
        }
      }
    `,
    { variables: { input: { businessId } } }
  );
  const [turnOffUSModeFn, { loading: turningOff }] = useMutation(
    gql`
      mutation ($input: USModeInput!) {
        turnOffUSMode(input: $input) {
          usMode
        }
      }
    `,
    { variables: { input: { businessId } } }
  );

  const turnOnUSMode = useCallback(async () => {
    if (!businessId || !data?.fetchBillingBusinessSettings) return;
    if (data.fetchBillingBusinessSettings.usMode) return;
    await turnOnUSModeFn();
    refetch();
  }, [businessId, data?.fetchBillingBusinessSettings, refetch, turnOnUSModeFn]);

  const turnOffUSMode = useCallback(async () => {
    if (!businessId || !data?.fetchBillingBusinessSettings) return;
    if (data.fetchBillingBusinessSettings.usMode === false) return;
    await turnOffUSModeFn();
    refetch();
  }, [businessId, data?.fetchBillingBusinessSettings, refetch, turnOffUSModeFn]);

  const loading = useMemo(() => {
    return loadingSettings || turningOn || turningOff;
  }, [loadingSettings, turningOff, turningOn]);

  return { usMode: data?.fetchBillingBusinessSettings?.usMode ?? false, loading, turnOnUSMode, turnOffUSMode };
}

export const ldFlags = { 'k2-us-mode-kt-admin': false };
