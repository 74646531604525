import { getI18n } from 'react-i18next';
import { ApplicationFeeType } from '../enums/applicationFeeType';

function t(key: string): string {
  const i18n = getI18n();
  return i18n.getResource('en', 'enrollment', `application-fee-type.${key}`);
}

const applicationFeeTypes: { label: string; value?: ApplicationFeeType }[] = [
  { value: undefined, label: t('none') },
  { value: ApplicationFeeType.PerApplication, label: t('per-application') },
  { value: ApplicationFeeType.PerPlace, label: t('per-place') },
];

export default applicationFeeTypes;
