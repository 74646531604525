import React from 'react';

interface IProps {
  className?: string;
  my?: number;
}

const HorizontalDivider: React.FC<IProps> = ({ className, my = 4 }) => {
  return <hr className={`${className} mx-auto my-${my} horizontal-divider`}></hr>;
};

export default HorizontalDivider;
