import {
  Chat,
  MessageEnvelope,
  MessageInput,
  MessageList,
  MessagePayload,
  MessageRendererProps,
  UserEntity,
} from '@pubnub/react-chat-components';
import {
  AccountStatusType,
  ActivityType,
  ChannelType,
  useCreateChatActivityMutation,
  UserActivityPerson,
} from 'generated/graphql';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Spinner from 'shared/components/Spinner';
import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { Channel } from 'pages/Engagement/subroutes/Messaging/Tabs/AccountMessagingTab/AccountMessagingTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faPaperPlane, faStar, faTrash, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import MessageBubble from '../MessageBubble';
import ActionDropdown from 'shared/components/ActionDropdown';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { ButtonAsLink, IconButtonCircle } from 'shared/components/Buttons';
import PubNub from 'pubnub';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useHistory } from 'react-router-dom';
import Alert from 'shared/components/Alert';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import FileUploadDropbox from 'shared/components/FileOperations/FileUploadDropbox';
import useUploadMessageAttachment from '../hooks/useUploadMessageAttachment';
import { IMessageAttachment } from 'shared/types/channel';
import { useSearchAttachments } from 'gql/communications/queries';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import * as Mui from '@mui/material';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { useDropzone } from 'react-dropzone';
import MessageAttachment from './MessageAttachment';

interface IProps {
  pubnub: PubNub;
  authCenters: string[];
  currentChannel: string;
  subscribedChannels: string[];
  users: UserEntity[];
  getUsersLoading?: boolean;
  hasWritePermission: boolean;
  hasDeletePermission: boolean;
  currentChannelUsers?: UserActivityPerson[] | null | undefined;
  channelsType: ChannelType;
  currentChannelInfo?: Channel;
  onFlagMessage: (message: MessageEnvelope) => void;
  msgSent?: () => void;
}

const PubnubChatComponent: React.FC<IProps> = ({
  pubnub,
  authCenters,
  currentChannel,
  subscribedChannels,
  users,
  getUsersLoading,
  hasWritePermission,
  hasDeletePermission,
  currentChannelUsers,
  channelsType,
  currentChannelInfo,
  onFlagMessage,
  msgSent,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['comms', 'translation']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const [uploadedFiles, setuploadedFiles] = useState<File[]>([]);
  const [uploadedFilesResult, setuploadedFilesResult] = useState<Array<IMessageAttachment>>([]);
  const [inputText, setInputText] = useState<string>('');
  const [fileHasBeenDownloaded, setFileHasBeenDownloaded] = useState<boolean>(false);

  const hasCenterEditPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const [useUploadMessageAttachmentFn, { data: uploadData, loading: uploadLoading, error }] =
    useUploadMessageAttachment();

  useEffect(() => {
    if (error) {
      showToast('Unable to upload attachment', 'error');
    }
  }, [error]);

  const handleFileAdd = (files: File[]) => {
    useUploadMessageAttachmentFn(files[0], {
      businessId: businessId ?? '',
      centerId: currentChannelInfo?.centerId ?? '',
      fileName: files[0]?.name ?? '',
      mimetype: files[0]?.type ?? '',
    });
  };

  const onSendClear = () => {
    // Send signal that a msg was sent
    if (msgSent) {
      msgSent();
    }
    // Clear attachments
    setuploadedFiles([]);
    setuploadedFilesResult([]);
  };

  const [createChatActivity, { data, loading: createActivityLoading, error: createChatActivityError }] =
    useCreateChatActivityMutation({
      onError: (err) => showToast('Unable to send user data', 'error'),
      onCompleted: (response) => {},
    });

  const [searchAttachmentsFunction, presignedUrlResponse] = useSearchAttachments();
  React.useEffect(() => {
    if (uploadData) {
      var arr = [...uploadedFilesResult];
      if (!(arr.findIndex((element) => element.id === uploadData.id) > -1)) {
        arr.push(uploadData);
        setuploadedFilesResult(arr);
      }
    }
  }, [uploadData]);

  const downloadFile = useCallback(
    async (file: IMessageAttachment) => {
      await searchAttachmentsFunction({
        variables: {
          businessId: businessId ?? '',
          attachmentIds: [file.id],
          generateDownloadLinks: true,
        },
        onCompleted: (result) => {
          if (result.searchMessagingAttachments && !fileHasBeenDownloaded) {
            if (result.searchMessagingAttachments.data[0].presignedDownloadUrl === null) {
              showToast('File scanning in progress, please try back in 30 seconds', 'error');
            } else if (result.searchMessagingAttachments.data[0].presignedDownloadUrl === 'Virus Detected') {
              showToast('Virus Detected, cannot download file', 'error');
            } else {
              setFileHasBeenDownloaded(true);
              window.open(result.searchMessagingAttachments.data[0].presignedDownloadUrl, '_blank', 'noreferrer');
            }
          }
        },
      });
    },
    [searchAttachmentsFunction, presignedUrlResponse]
  );

  const appInsightsContext = useAppInsightsContext();

  const handleChatClientError = (error) => {
    if (error?.name !== 'TypeError') {
      if (error?.status?.category === 'PNAccessDeniedCategory') showToast(t('comms:pubnub.error.auth'), 'error');
      else {
        showToast(t('comms:pubnub.error.refresh'), 'error');
        appInsightsContext.trackException(
          {
            exception: error,
          },
          {
            'Error Name': 'Chat Client Error',
          }
        );
      }
    }
  };
  const handleAttachments = (message: MessagePayload) => {
    var unique = uploadedFilesResult.filter((value, index, array) => array.indexOf(value) === index);

    if (unique.length > 0) {
      const existingMessageAttachments = message.KtAttachments as IMessageAttachment[];
      message.KtAttachments = unique;

      return message;
    } else {
      return message;
    }
  };

  const handleDeleteMessage = async (message: MessageEnvelope) => {
    const payload = message.message as MessagePayload;
    pubnub
      .addMessageAction({
        channel: message.channel ?? currentChannel,
        messageTimetoken: message.timetoken.toString(),
        action: {
          type: 'deleted',
          value: '.',
        },
      })
      .then((result) => {
        createChatActivity({
          variables: {
            businessId: businessId ?? '',
            input: {
              businessId: businessId ?? '',
              channelType: currentChannelInfo?.channelType === 'Account' ? ChannelType.Account : ChannelType.Center,
              channelId: currentChannelInfo?.channelId,
              personId: payload?.sender?.id ?? user?.id,
              activityType: ActivityType.MessageDeleted,
              activityTime: parseInt(message.timetoken.toString()),
              pubNubMessageId: payload.id,
              messageContent: payload.text,
              channelWasRead: true,
              additionalInformation: JSON.stringify({ DeletedPubNubMessageId: payload.id }),
            },
          },
        });
        showToast(
          t('comms:delete-message-btn.success-toast-message', {
            message: payload.text,
            interpolation: { escapeValue: false },
          }),
          'success'
        );
      })
      .catch((error) => {
        showToast(t('comms:delete-message-btn.error-toast-message'), 'error');
      });
  };

  const renderMessage = (props: MessageRendererProps) => {
    const payload = props.message.message as MessagePayload;
    const senderName = props.isOwn
      ? `${user?.firstname} ${user?.lastname}`
      : props.user?.name ?? payload.sender?.name ?? 'Unknown User';
    const avatarUrl = props.user?.profileUrl ?? payload.sender?.profileUrl ?? '';

    const flaggedReasons = props.message.actions ? Object.keys(props.message.actions.flagged) : [];
    const flaggedReason = flaggedReasons.length > 0 ? flaggedReasons[flaggedReasons.length - 1] : '';

    return (
      <>
        {/* Flag Msg Work */}
        {/* {props.message.actions?.flagged && (
          <div className={'flagged-message'}>
            <div>
              <FontAwesomeIcon icon={faFlagAlt} />
            </div>
            <div>
              <p>Message flagged for {flaggedReason} Content</p>
            </div>
          </div>
        )} */}
        <MessageBubble
          messageText={payload.text ?? ''}
          avatarUrl={avatarUrl}
          senderName={senderName}
          createdAt={payload.createdAt ?? ''}
          attachments={payload.KtAttachments as IMessageAttachment[]}
          onDownloadAttachment={(attachment) => {
            setFileHasBeenDownloaded(false);
            downloadFile(attachment);
          }}
        />
      </>
    );
  };

  const renderMessageActions = (message: MessageEnvelope) => {
    return (
      <ActionDropdown
        actions={[
          {
            label: t('comms:delete-message-btn.title'),
            icon: faTrash,
            onClick: () => handleDeleteMessage(message),
          },
          // Flag Msg Work
          // {
          //   label: t('comms:flag-message.action'),
          //   icon: faFlag,
          //   onClick: () => onFlagMessage(message),
          // },
        ]}
        customIconButton={
          <IconButtonCircle className="rounded-circle circle-md bg-pale-grey ml-2" icon={faEllipsisH} iconSize="2x" />
        }
      />
    );
  };

  // Message Attachments Functions
  const onFilesAdded = (filesAddedEvent: React.ChangeEvent<HTMLInputElement>) => {
    if (uploadedFiles && filesAddedEvent && filesAddedEvent.target) {
      const files = filesAddedEvent.target.files;
      const filesArray: any = Array.prototype.slice.call(files);
      filesArray.length && setuploadedFiles(filesArray);
      handleFileAdd(filesArray);
    }
  };

  const removeFileFromUploadList = (fileName: string) => {
    const newUploadList = uploadedFilesResult.filter((f: IMessageAttachment) => !(f.fileName === fileName));
    setuploadedFilesResult(newUploadList);
  };

  const fileInputRef = React.createRef<HTMLInputElement>();

  const openFileDialog = (): void => {
    if (uploadedFilesResult.length > 9) {
      showToast('You can only send a maximum of 10 attachments per message.', 'error');
    } else {
      fileInputRef.current && fileInputRef.current.click();
    }
  };

  const renderAttachmentUploader = () => {
    if (!uploadLoading) {
      return (
        <div className="align-items-center">
          <span className="ml-2 text-center">
            <ButtonAsLink className="md text-primary" onClick={openFileDialog}>
              <FontAwesomeIcon icon={faPaperclip} />
            </ButtonAsLink>
          </span>
          <input
            // {...getInputProps()}
            ref={fileInputRef}
            className="fileInput"
            type="file"
            multiple={false}
            onChange={onFilesAdded}
          />
        </div>
      );
    } else return <></>;
  };

  // End Message Attachments Functions

  return (
    <>
      <Chat
        enablePresence={false}
        currentChannel={currentChannel ?? 'Default Channel'}
        channels={subscribedChannels}
        users={users}
        onError={handleChatClientError}
        retryOptions={{
          maxRetries: 5,
          timeout: 1000,
          exponentialFactor: 2,
        }}
      >
        <Col md={12} lg={6} xl={8} className={`messages ${!currentChannel ? 'chat-disabled' : ''}`}>
          {currentChannel && currentChannelUsers && channelsType === ChannelType.Account && (
            <div className="contacts-container">
              <div className="contacts-header">
                <p>Contacts:</p>
              </div>
              <div className={`contacts-list ${getUsersLoading ? 'laoding' : ''}`}>
                {getUsersLoading && channelsType === ChannelType.Account && <Spinner className="text-gray mr-2" />}
                {!getUsersLoading &&
                  currentChannelUsers !== null &&
                  currentChannelUsers !== undefined &&
                  currentChannelUsers.map((contact, key) => {
                    return (
                      <OverlayTrigger
                        key={key}
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id={contact.personId ?? ''}>
                            <div>{contact.isPrimaryContact ? 'Primary Contact' : 'Secondary Contact'}</div>
                            <div>{contact.hasKTConnect ? 'Has KTConnect' : 'Does Not Have KT Connect'}</div>
                          </Tooltip>
                        }
                      >
                        <div className={`channel-contact ${!contact.hasKTConnect ? 'no-kt-connect' : ''}`} key={key}>
                          <div className="contact-name">
                            {contact.isPrimaryContact ? <FontAwesomeIcon icon={faStar} /> : ''} {contact.name}
                          </div>
                        </div>
                      </OverlayTrigger>
                    );
                  })}
              </div>
            </div>
          )}
          <MessageList
            fetchMessages={20}
            messageRenderer={renderMessage}
            extraActionsRenderer={(message: MessageEnvelope) => {
              return hasDeletePermission ? renderMessageActions(message) : <></>;
            }}
          />
          {currentChannelInfo?.accountStatus && currentChannelInfo.accountStatus === AccountStatusType.Inactive && (
            <Alert variant="info">
              {'Contacts within this channel may no longer have access KT Connect to receive this message'}
            </Alert>
          )}
          <div className={!hasWritePermission ? 'disabled' : ''}>
            {authCenters.some((c) => c === currentChannelInfo?.centerId) && (
              <MessageInput
                onChange={(text) => setInputText(text)}
                disabled={!currentChannel || !hasWritePermission}
                emojiPicker={<Picker data={emojiData} theme={'light'} />}
                extraActionsRenderer={() => renderAttachmentUploader()}
                onBeforeSend={handleAttachments}
                onSend={onSendClear} //clear state}
                placeholder={
                  !hasWritePermission
                    ? t('comms:messageInputPlaceholder.no-permission')
                    : t('comms:messageInputPlaceholder.default')
                }
                senderInfo={true}
                sendButton={
                  <div
                    className={!hasWritePermission || uploadLoading || inputText.trim().length === 0 ? 'disabled' : ''}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} fontSize="10px" />
                    <span>
                      {channelsType === ChannelType.Account ? t('comms:send-message') : t('comms:send-announcement')}
                    </span>
                  </div>
                }
              />
            )}
            <SpinnerOverlay show={uploadLoading} size="small">
              <Mui.Box sx={{ backgroundColor: 'white', padding: '0 20px 20px 20px', minHeight: '50px' }}>
                {uploadedFilesResult.map((f, i) => {
                  return (
                    <MessageAttachment
                      file={f}
                      key={i}
                      updateContentsCb={() => {}}
                      onDeleteClick={removeFileFromUploadList}
                    />
                  );
                })}
              </Mui.Box>
            </SpinnerOverlay>
            {!authCenters.some((c) => c === currentChannelInfo?.centerId) && (
              <div className="inactive-center">
                <span>{t('comms:inactive-channel.main-text')}</span>
                {!hasCenterEditPermission && <span>{t('comms:inactive-channel.additonal-text')}</span>}
                {hasCenterEditPermission && (
                  <span>
                    go to your <a onClick={() => history.push('/engagement/settings')}>Engagement Settings</a>
                  </span>
                )}
              </div>
            )}
          </div>
        </Col>
      </Chat>
    </>
  );
};
export default PubnubChatComponent;
