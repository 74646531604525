import React from 'react';
import { ICreateAgencyFormShape } from './CreateUpdateAgencyModal';
import Select from 'shared/components/Select';
import { useTranslation } from 'react-i18next';

interface IProps {
  data: ICreateAgencyFormShape;
  centerOptionsForSelectedState: ICenter[];
  onUpdate: React.Dispatch<React.SetStateAction<ICreateAgencyFormShape>>;
}

const CreateAgencyFormStepTwo: React.FC<IProps> = ({ data, centerOptionsForSelectedState, onUpdate, ...props }) => {
  const { t } = useTranslation(['subsidies']);

  return (
    <section>
      <h5>{t('subsidies:agencies.create-modal.center-assignment-section-label')}</h5>
      <p>{t('subsidies:agencies.create-modal.center-assignment-section-description')}</p>
      <Select
        isMulti
        label=""
        options={centerOptionsForSelectedState}
        onChange={(options: ICenter[] | null) =>
          onUpdate((prev) => ({ ...prev, centerIds: (options ?? []).map((center) => center.id) }))
        }
        noOptionsMessage={() => t('subsidies:agencies.create-modal.center-assignment-no-options-message')}
        getOptionLabel={(option: ICenter) => option.name}
        getOptionValue={(option: ICenter) => option.id}
        className="flex-wrap"
        closeMenuOnSelect={false}
        name="agency-centers"
        value={
          centerOptionsForSelectedState.length > 0
            ? centerOptionsForSelectedState.filter((c) => data.centerIds?.includes(c.id))
            : null
        }
      />
    </section>
  );
};

export default CreateAgencyFormStepTwo;
