import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useLazyGetProviderCorrespondence, useLazyGetServiceCorrespondence } from 'gql/ccssMessages/queries';
import { MsgStatusCodes, CcsMsgTypesEnum } from 'shared/constants/enums/ccssMessageEnums';
import { downloadPDF } from 'shared/util/downloadFile';
import './ccssMessageRow.scss';

interface IProps {
  data: ICcsMessage;
  businessId: string;
  updateMsgStatus?: (data: ICcsMessage) => void;
  type: 'service' | 'provider';
}

const CCSSMessageRow: React.FC<IProps> = ({ data, businessId, type, updateMsgStatus = (data: ICcsMessage) => {} }) => {
  const { body, viewedStatus, subject, source, sourceId } = data;
  const isCorrespondence = data.type === CcsMsgTypesEnum.CORRESPONDENCE;

  const [getProviderCorrespondenceData, { loading }] = useLazyGetProviderCorrespondence({
    onCompleted: (result) => {
      const blob = result?.getCcssProviderCorrespondence.content;
      downloadPDF(blob, `${data.id}.pdf`);
    },
  });

  const [getServiceCorrespondenceData, { loading: serviceLoading }] = useLazyGetServiceCorrespondence({
    onCompleted: (result) => {
      const blob = result?.getCcssServiceCorrespondence.content;
      downloadPDF(blob, `${data.id}.pdf`);
    },
  });

  const onDownload = () => {
    if (type === 'service') {
      getServiceCorrespondenceData({
        variables: {
          input: {
            businessId,
            correspondenceId: data.id,
            centerId: data.centerId ?? '',
          },
        },
      });
    } else {
      getProviderCorrespondenceData({
        variables: {
          input: {
            businessId,
            correspondenceId: data.id,
          },
        },
      });
    }
  };

  const renderDownload = () => (
    <div>
      <Button variant="primary" className="ml-auto download-message" onClick={onDownload}>
        {(loading || serviceLoading) && <Spinner className="mr-2" animation="border" size="sm" />}
        Download Message
      </Button>
    </div>
  );

  const renderCorrespondence = () => (
    <div>
      <p>You have a message from {source || 'N/A'}. To download the message click the pink button.</p>
      <p>Once you've read the message, you can archive it if you wish.</p>
    </div>
  );

  const handleUpdate = (status = MsgStatusCodes.ARCHIVED) => {
    updateMsgStatus({
      ...data,
      viewedStatus: status,
    });
  };

  return (
    <div className="mesage-row-container">
      <div className="d-flex mb-5 white-space align-items-center">
        <label className="font-weight-normal font-size-14 mb-0 mr-4">Subject</label>
        <strong>
          {sourceId ? `${sourceId} - ` : ''}
          {subject}
        </strong>
      </div>

      <div className="mt-6 white-space">
        {isCorrespondence && renderCorrespondence()}
        <div>{body}</div>
      </div>
      <div className="mt-5 d-flex mb-5">
        {isCorrespondence && renderDownload()}
        {viewedStatus !== MsgStatusCodes.ARCHIVED ? (
          <Button
            variant="outline-danger"
            className="ml-auto archive-button"
            onClick={() => handleUpdate(MsgStatusCodes.ARCHIVED)}
          >
            Archive Message
          </Button>
        ) : (
          <Button
            variant="outline-success"
            className="ml-auto archive-button"
            onClick={() => handleUpdate(MsgStatusCodes.READ)}
          >
            Unarchive Message
          </Button>
        )}
      </div>
    </div>
  );
};

export default CCSSMessageRow;
