import i18n from 'i18next';
import { ReportingCategoryEnum, ReportTypeEnum } from 'shared/constants/enums/reportingEnums';

/**
 * Create a human readable name for a report
 */
export const getReadableReportName = (type: ReportType): string => {
  switch (type) {
    case ReportTypeEnum.BALANCE_AT_DATE:
      return i18n.t('reporting:readable-report-name.balance-at-date');
    case ReportTypeEnum.FAT_ZEBRA_TRANSACTIONS:
      return i18n.t('reporting:readable-report-name.fat-zebra-transactions');
    case ReportTypeEnum.TRANSACTION_TOTALS_PER_WEEK:
      return i18n.t('reporting:readable-report-name.transaction-totals-per-week');
    case ReportTypeEnum.TRANSACTION_TOTALS_PER_MONTH:
      return i18n.t('reporting:readable-report-name.transaction-totals-per-month');
    case ReportTypeEnum.OCCUPANCY_TOTALS:
      return i18n.t('reporting:readable-report-name.occupancy-totals');
    case ReportTypeEnum.EMERGENCY_CONTACTS:
      return i18n.t('reporting:readable-report-name.emergency-contacts');
    case ReportTypeEnum.WEEKLY_ATTENDANCE:
      return i18n.t('reporting:readable-report-name.weekly-attendance');
    case ReportTypeEnum.SIGN_IN_OUT:
      return i18n.t('reporting:readable-report-name.sign-in-out');
    case ReportTypeEnum.WEEKLY_PAYROLL:
      return i18n.t('reporting:readable-report-name.weekly-payroll');
    case ReportTypeEnum.ADP_WEEKLY_PAYROLL:
      return i18n.t('reporting:readable-report-name.adp-weekly-payroll');
    case ReportTypeEnum.SESSION_FEES_AND_TIMES:
      return i18n.t('reporting:readable-report-name.session-fees-and-times');
    case ReportTypeEnum.DEBT:
      return i18n.t('reporting:readable-report-name.debt');
    case ReportTypeEnum.CONTRACT:
      return i18n.t('reporting:readable-report-name.contract');
    case ReportTypeEnum.TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_MONTH:
      return i18n.t('reporting:readable-report-name.transaction-totals-by-transaction-type-per-month');
    case ReportTypeEnum.TRANSACTION_TOTALS_BY_TRANSACTION_TYPE_PER_WEEK:
      return i18n.t('reporting:readable-report-name.transaction-totals-by-transaction-type-per-week');
    case ReportTypeEnum.PAYMENTS:
      return i18n.t('reporting:readable-report-name.payments');
    case ReportTypeEnum.TRANSACTIONS:
      return i18n.t('reporting:readable-report-name.transactions');
    case ReportTypeEnum.NAME_TO_FACE:
      return i18n.t('reporting:readable-report-name.name-to-face');
    case ReportTypeEnum.CHILD_CHECK_IN:
      return i18n.t('reporting:readable-report-name.child-check-in');
    case ReportTypeEnum.QUARTER_HOUR_CHECK_IN:
      return i18n.t('reporting:readable-report-name.quarter-hour-check-in');
    case ReportTypeEnum.CHILD_HEALTH_AND_WELLNESS:
      return i18n.t('reporting:readable-report-name.child-health-and-wellness');
    case ReportTypeEnum.CHILD_LAST_ATTENDANCE:
      return i18n.t('reporting:readable-report-name.child-last-attendance');
    case ReportTypeEnum.EMPLOYEE_TIMESHEET:
      return i18n.t('reporting:readable-report-name.employee-timesheet');
    case ReportTypeEnum.SAGE_GL:
      return i18n.t('reporting:readable-report-name.sage-gl');
    case ReportTypeEnum.SAGE_GL_DETAILS:
      return i18n.t('reporting:readable-report-name.sage-gl-details');
    case ReportTypeEnum.ACCOUNT_CONTACT_INFORMATION:
      return i18n.t('reporting:readable-report-name.account-contact-information');
    case ReportTypeEnum.SAGE_CASH:
      return i18n.t('reporting:readable-report-name.sage-cash');
    case ReportTypeEnum.CLASS_ATTENDANCE_ROSTER:
      return i18n.t('reporting:readable-report-name.class-attendance-roster');
    case ReportTypeEnum.OUTSTANDING_CCS:
      return i18n.t('reporting:readable-report-name.outstanding-css-report');
    case ReportTypeEnum.BILLING_SUMMARY:
      return i18n.t('reporting:readable-report-name.billing-summary');
    case ReportTypeEnum.CONTRACT_FEE_OVERVIEW:
      return i18n.t('reporting:readable-report-name.contract-fee-overview');
    case ReportTypeEnum.CONTRACT_FEE_DETAILED_OVERVIEW:
      return i18n.t('reporting:readable-report-name.contract-fee-detailed-overview');
    case ReportTypeEnum.ROYALTY:
      return i18n.t('reporting:readable-report-name.royalty');
    case ReportTypeEnum.NEW_CONTRACTS:
      return i18n.t('reporting:readable-report-name.new-contracts');
    case ReportTypeEnum.CHILD_TRACKING:
      return i18n.t('reporting:readable-report-name.child-tracking');
    case ReportTypeEnum.ENDED_CONTRACTS:
      return i18n.t('reporting:readable-report-name.ended-contracts');
    case ReportTypeEnum.EMAIL_ADDRESS:
      return i18n.t('reporting:readable-report-name.email-address');
    case ReportTypeEnum.CONTRACT_EDITS:
      return i18n.t('reporting:readable-report-name.contract-edits');
    case ReportTypeEnum.QLD_CENSUS:
      return i18n.t('reporting:readable-report-name.qld-census');
    case ReportTypeEnum.EXCURSION:
      return i18n.t('reporting:readable-report-name.excursion');
    case ReportTypeEnum.DEPOSITS_HELD:
      return i18n.t('reporting:readable-report-name.deposits-held');
    case ReportTypeEnum.CHILD_ATTENDANCE_SUMMARY:
      return i18n.t('reporting:readable-report-name.child-attendance-summary');
    case ReportTypeEnum.ATTENDANCE_TOTALS_PER_WEEK:
      return i18n.t('reporting:readable-report-name.attendance-totals-per-week');
    case ReportTypeEnum.DISBURSEMENTS_MONTHLY:
      return i18n.t('reporting:readable-report-name.disbursements-monthly');
    case ReportTypeEnum.MEAL_TRACKING:
      return i18n.t('reporting:readable-report-name.meal-tracking');
    case ReportTypeEnum.CUSTOM_FIELD_REPORT:
      return i18n.t('reporting:readable-report-name.custom-fields');
    case ReportTypeEnum.BILLING_EDITS:
      return i18n.t('reporting:readable-report-name.billing-edits');
    default:
      return '';
  }
};

/**
 * Create a human readable name for a report category
 */
export const getReadableReportCategory = (category: ReportingCategoryType): string => {
  switch (category) {
    case ReportingCategoryEnum.FINANCE:
      return i18n.t('reporting:readable-report-category.finance');
    case ReportingCategoryEnum.FAMILIES:
      return i18n.t('reporting:readable-report-category.families');
    case ReportingCategoryEnum.ATTENDANCE:
      return i18n.t('reporting:readable-report-category.attendance');
    default:
      return '';
  }
};
