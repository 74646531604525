import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import Row from 'shared/components/Layout/Row';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { showToast } from 'shared/components/Toast';
import { useLinkEnrolmentById } from 'gql/ccssEnrolment/mutations';
import DataTable from 'shared/components/DataTable';
import moment from 'moment';
import { find } from 'lodash';
import { ccsEnrollmentStatus } from 'shared/constants/enums/ccssEnums';

interface IProps {
  accountId: string;
  centerId: string;
  unlinkedEnrolments: ICcsEnrolment[];
  isOpen: boolean;
  onClose: () => void;
}

const LinkCcsEnrolmentModal: React.FC<IProps> = ({ accountId, centerId, unlinkedEnrolments, isOpen, onClose }) => {
  const [enrolmentToLink, setEnrolmentToLink] = useState<ICcsEnrolment | undefined>(undefined);

  const handleClose = useCallback(() => {
    setEnrolmentToLink(undefined);
    onClose();
  }, [onClose]);

  const [linkEnrolmentByid, { loading }] = useLinkEnrolmentById({
    onError: (err) => showToast(err.message, 'error'),
    onCompleted: () => {
      showToast('Child CCS enrolment linked successfully', 'success');
      handleClose();
    },
  });

  const handleContinue = useCallback(() => {
    if (enrolmentToLink)
      linkEnrolmentByid({
        variables: {
          centerId: centerId,
          accountId: accountId,
          enrolmentId: enrolmentToLink?.id,
        },
      });
  }, [accountId, centerId, enrolmentToLink, linkEnrolmentByid]);

  const dateFormat = 'DD/MM/YYYY';

  return (
    <CenteredModal
      size="lg"
      show={isOpen}
      onHide={handleClose}
      title="Link CCS Enrolment"
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryChoice="Confirm"
      primaryCallback={handleContinue}
      primaryButtonProps={{ disabled: !enrolmentToLink, loading }}
    >
      <Form>
        <Row align="start" className="ml-1 mr-1">
          {unlinkedEnrolments.length > 0 ? (
            <div>
              <Row align="start" className="ml-1 mb-4">
                Select a CCS Enrolment to link to this account
              </Row>
              <DataTable
                data={unlinkedEnrolments}
                showSelect
                singleSelect
                showPagination={false}
                selectedRows={enrolmentToLink ? [enrolmentToLink] : []}
                updateSelectedRows={(rows) => setEnrolmentToLink(rows[0])}
                columns={[
                  {
                    text: 'Name',
                    dataField: 'childFirstName',
                    formatter: (cell: string, row: ICcsEnrolment) =>
                      row.childFirstName?.trim().length ?? 0 >= 12
                        ? `${row.childFirstName?.trim().substring(0, 12)}...`
                        : `${row.childFirstName?.trim()}`,
                  },
                  {
                    text: '',
                    dataField: 'childLastName',
                    formatter: (cell: string, row: ICcsEnrolment) =>
                      row.childLastName?.trim().length ?? 0 >= 12
                        ? `${row.childLastName?.trim().substring(0, 12)}...`
                        : `${row.childLastName?.trim()}`,
                  },
                  {
                    text: 'Type',
                    dataField: 'arrangementType',
                    formatter: (cell: string, row: ICcsEnrolment) => `${row.arrangementType}`,
                  },
                  {
                    text: 'Enrolment ID',
                    dataField: 'enrolmentId',
                    formatter: (cell: string, row: ICcsEnrolment) => `${row.enrolmentId}`,
                  },
                  {
                    text: 'Start - End',
                    dataField: 'arrangementEndDate',
                    formatter: (cell: string, row: ICcsEnrolment) => {
                      const endDateLabel = row.endDate ? moment(row.endDate).format(dateFormat) : 'Ongoing';
                      return `${moment(row.arrangementStartDate).format(dateFormat)} - ${endDateLabel}`;
                    },
                  },
                  {
                    text: 'Status',
                    dataField: 'status',
                    formatter: (cell: string, row: ICcsEnrolment) =>
                      `${find(ccsEnrollmentStatus, (status) => status.value === row.status)?.label ?? 'Unknown'}`,
                  },
                ]}
              />
            </div>
          ) : (
            <div>No CCS Enrolments are available to link for this account.</div>
          )}
        </Row>
      </Form>
    </CenteredModal>
  );
};

export default LinkCcsEnrolmentModal;
