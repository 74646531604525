import React from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import * as actions from 'pages/Billing/Settings/duck/actions';
import { useDispatch } from 'react-redux';
import { useDeleteBillingCycleTemplate } from 'gql/billingCycle/mutations';

interface IProps {
  isOpen: boolean;
  billingCycleTemplate: IBillingCycleTemplate;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteBillingCycleTemplateModal: React.FC<IProps> = ({
  isOpen,
  billingCycleTemplate,
  onClose,
  onSubmit,
  ...props
}) => {
  const dispatch = useDispatch();
  const [deleteCylceFn, { loading }] = useDeleteBillingCycleTemplate({
    onCompleted: (data) => {
      showToast('Successfully deleted cycle.', 'success');
      dispatch(actions.deleteBillingCycleTemplate(data.deleteBillingCycleTemplate));
      onClose();
      onSubmit();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  return (
    <ConfirmationModal
      title="Delete Billing Cycle?"
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Delete"
      primaryCallback={() =>
        deleteCylceFn({
          variables: {
            id: billingCycleTemplate.id,
          },
        })
      }
      primaryButtonProps={{
        variant: 'danger',
        loading,
      }}
    >
      <div>Are you sure you want to delete this billing cycle?</div>
    </ConfirmationModal>
  );
};

export default DeleteBillingCycleTemplateModal;
