import { SET_IMPORTED_STAFF_DATA, RESET_IMPORTED_STAFF_DATA } from './types';

export const setImportedStaffData = (importedStaffData: IStaff[]) => ({
  type: SET_IMPORTED_STAFF_DATA,
  staff: importedStaffData,
});

export const resetImportedStaffData = () => ({
  type: RESET_IMPORTED_STAFF_DATA,
});
