export const programFields = `
  id
  name
  isActive
  startDate
  endDate
  centerId
  programClasses {
    classId
  }
  programTeachers {
    staffId
    firstName
    lastName
    id
    staff {
      firstname
      lastname
      avatar {
        url
      }
    }
    qualificationStatus
  }
  programChildren {
    childId
    id
    startStrongConsent
    excludeStartStrongFunding
  }
  subsidyScheme {
    name
    subsidySchemeId
  }
  paymentRule
`;

export const programPagedFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${programFields}
  }
`;

export const programDetailsFields = `
  programId
  subsidyId
  programName
  subsidyName
  programStartDate
  programEndDate
  paymentRule
  schedules {
    day
    startTime
    endTime
  }
  classIds {
    id
    classId
  }
  educators {
    id
    staffId
    firstName
    lastName
    qualificationStatus
  }
  childIds {
    id
    childId
  }
  centerId
  programLeaveDays {
    id
    startDate
    endDate
    leaveType
    programId
  }
`;

export const programFundingSchedulesFields = `
  programId
  startDate
  subsidyTypes
  basePercentage
  weeks
  createdAt
  createdBy
`;
