import React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'shared/components/Alert';
import Button from 'shared/components/Buttons';
import { HorizontalDivider } from 'shared/components/Dividers';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {
  row: IAgency;
  onEdit: (agency: IAgency) => void;
  onArchive: (agency: IAgency) => void;
  onViewChildren: (agency: IAgency) => void;
}

const AgencyTableExpandedRow: React.FC<IProps> = ({ row, onEdit, onArchive, onViewChildren, ...props }) => {
  const { t } = useTranslation(['subsidies']);

  const hasEditAgencyPermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.AgencyMain,
    level: RoleLevelType.Edit,
  });
  const hasArchiveAgencyPermission = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.AgencyMain,
    level: RoleLevelType.Delete,
  });

  return (
    <div className="kt-agency-table-expanded-row">
      {row.archivedAt && (
        <Alert variant="info" className="mb-4 w-25">
          {t('subsidies:agencies.data-table.archived-message')}
        </Alert>
      )}
      <div className="d-flex">
        <div className="d-flex flex-column w-50 agency-information-column">
          <h5 className="mb-4">{t('subsidies:agencies.data-table.policies-section-label')}</h5>
          <p className="white-space-normal">
            <b>{t('subsidies:agencies.data-table.absent-days-policy-label')}: </b>
            {row.absentDay}
          </p>
          <p className="white-space-normal">
            <b>{t('subsidies:agencies.data-table.attendance-policy-label')}: </b>
            {row.attendanceRequirement}
          </p>
          <p className="white-space-normal">
            <b>{t('subsidies:agencies.data-table.reimbursement-policy-label')}: </b>
            {row.reimbursementSchedule}
          </p>
        </div>
        <div className="d-flex flex-column w-50 pl-4">
          <div className="d-flex justify-content-between">
            <div>
              <h5>{t('subsidies:agencies.data-table.children-assigned-label')}</h5>
              <p>
                {t('subsidies:agencies.data-table.children-assigned-description', {
                  count: row.assignedAccountChildIds.length,
                })}
              </p>
            </div>
            <Button variant="light" onClick={() => onViewChildren(row)}>
              {t('subsidies:agencies.data-table.view-children-button-text')}
            </Button>
          </div>
          <div>
            <h5>{t('subsidies:agencies.data-table.links-label')}</h5>
            <ul className="unstyled-list agency-links-list">
              {row.agencyLinks && row.agencyLinks.length > 0 ? (
                row.agencyLinks.map((link: IAgencyLink, idx: number) => (
                  <li key={`agency-link-${link.id}-${idx}`}>
                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                      {link.link}
                    </a>
                  </li>
                ))
              ) : (
                <li>None</li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {!row.archivedAt && (
        <>
          <HorizontalDivider />
          <div className="d-flex justify-content-between">
            {hasArchiveAgencyPermission && (
              <Button variant="outline-danger" onClick={() => onArchive(row)}>
                {t('subsidies:agencies.data-table.archive-button-text')}
              </Button>
            )}
            {hasEditAgencyPermission && (
              <Button variant="secondary" onClick={() => onEdit(row)}>
                {t('subsidies:agencies.data-table.edit-button-text')}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AgencyTableExpandedRow;
