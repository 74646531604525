import { COMPLETE_STEP, RESET_ON_BOARDING_STATE } from './types';

export const completeStep = (step: number) => ({
  type: COMPLETE_STEP,
  payload: step,
});

export const resetOnBoarding = () => ({
  type: RESET_ON_BOARDING_STATE,
});
