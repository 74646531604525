import colors from '_colors.module.scss';

export enum SubsidySummaryStatusCodes {
  FINALISED = 'FINALISED',
  ERRORED = 'ERRORED',
  PENDING = 'PENDING',
  UNSUBMITTED = 'UNSUBMITTED',
}
export enum SubsidySummaryStatusLabels {
  FINALISED = 'Finalised',
  ERRORED = 'Errored',
  PENDING = 'Pending',
  UNSUBMITTED = 'Unsubmitted',
}

export const SubsidySummaryColors: Record<ISubsidySummaryStatus, { color: string; colorName: string }> = {
  [SubsidySummaryStatusCodes.FINALISED]: { color: colors.success, colorName: 'success' },
  [SubsidySummaryStatusCodes.ERRORED]: { color: colors.danger, colorName: 'danger' },
  [SubsidySummaryStatusCodes.PENDING]: { color: colors.warning, colorName: 'warning' },
  [SubsidySummaryStatusCodes.UNSUBMITTED]: { color: colors.gray, colorName: 'gray' },
};

export const LATE_WITHDRAWAL_DAYS = 28;
export const LATE_CHANGE_DAYS = 14;
