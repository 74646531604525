import { useCallback, useMemo, useState } from 'react';
import { useGetAgencyOptions } from 'shared/Agency/hooks/useGetAgencyOptions';

export function useMultiAgencySelector() {
  const { agencyOptions } = useGetAgencyOptions();
  const [selectedAgencies, setSelectedAgencies] = useState<string[]>([]);
  const handleSelectionChange = useCallback((selections: ISelectMenuItem[]) => {
    setSelectedAgencies(selections.map((o) => o.value));
  }, []);

  const handleClear = useCallback(() => setSelectedAgencies([]), []);

  const selectedOptions = useMemo(
    () => agencyOptions.filter((o) => selectedAgencies.includes(o.value)),
    [agencyOptions, selectedAgencies]
  );

  return { agencyOptions, selectedOptions, handleSelectionChange, selectedAgencies, handleClear };
}
