export enum PaymentMethodType {
  AMEX = 1,
  VISA = 2,
  MASTERCARD = 3,
  DINERS_CLUB = 4,
  DISCOVER = 5,
  CHECKING_ACCOUNT = 8,
  SAVINGS_ACCOUNT = 9,
  CORPORATE_CHECKING_ACCOUNT = 10,
  CORPORATE_SAVINGS_ACCOUNT = 11,
}

export class PayrixService {
  determinePaymentMethodType = (cardNumber: string): PaymentMethodType | void => {
    const firstDigit = cardNumber.substring(0, 1);

    switch (firstDigit) {
      case '3':
        return PaymentMethodType.AMEX;
      case '4':
        return PaymentMethodType.VISA;
      case '5':
        return PaymentMethodType.MASTERCARD;
      case '6':
        return PaymentMethodType.DISCOVER;
    }
  };

  // allow us to send an enum as its string value to the api (to remain consistent)
  enumValueToString = (value: PaymentMethodType): string => {
    switch (value) {
      case PaymentMethodType.AMEX:
        return 'AMEX';
      case PaymentMethodType.VISA:
        return 'VISA';
      case PaymentMethodType.MASTERCARD:
        return 'MASTERCARD';
      case PaymentMethodType.DINERS_CLUB:
        return 'DINERS_CLUB';
      case PaymentMethodType.DISCOVER:
        return 'DISCOVER';
      case PaymentMethodType.CHECKING_ACCOUNT:
        return 'CHECKING_ACCOUNT';
      case PaymentMethodType.SAVINGS_ACCOUNT:
        return 'SAVINGS_ACCOUNT';
      case PaymentMethodType.CORPORATE_CHECKING_ACCOUNT:
        return 'CORPORATE_CHECKING_ACCOUNT';
      case PaymentMethodType.CORPORATE_SAVINGS_ACCOUNT:
        return 'CORPORATE_SAVINGS_ACCOUNT';
      default:
        return '';
    }
  };
}
