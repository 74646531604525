import { capitalize } from 'lodash';
import React, { useImperativeHandle, useMemo, useState } from 'react';
import DataTable, { SizePerPage, TableHeader } from 'shared/components/DataTable';
import Select from 'shared/components/Select';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useTranslation } from 'react-i18next';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable';
import { useSearchEnrollmentForm } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/graphql/queries';
import ActionDropdown from 'shared/components/ActionDropdown';
import './EnrollmentForms.scss';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useHistory } from 'react-router-dom';
import PreviewFormModal, {
  IPreviewFormModalProps,
} from './enrollment-form-detail/preview-form-modal/preview-form-modal';
import { getHostName } from '../../../LeadManagement/utils';
import { useEncryptEnrollmentFormHashMutation } from '../../../../../../generated/graphql';
import { showToast } from '../../../../../../shared/components/Toast';
import getApolloErrorMessage from '../../../../../../shared/util/getApolloErrorMessage';

type EnrollmentFormsTableProps = {
  businessId: string;
};

export type ExternalHandler = {
  refetch: () => void;
};

export type EnrolmentFormLocationState = {
  enrolmentForm: EnrollmentForm;
};

const EnrollmentFormsTable = React.forwardRef<ExternalHandler, EnrollmentFormsTableProps>(({ businessId }, ref) => {
  const { t } = useTranslation(['translation', 'enrollment', 'business']);
  const centers = useSelector<RootState, ICenter[]>((state) => state.centers.all);
  const centerOptions = useMemo(() => centers, [centers]);
  const [selectedCenters, setSelectedCenters] = useState<ICenter[]>([]);
  const locationStateHistory = useHistory<EnrolmentFormLocationState>();
  const [previewFormModalState, setPreviewFormModalState] = useState<Omit<
    IPreviewFormModalProps,
    'setShowPreviewModal'
  > | null>(null);

  const [sort, setSortOptions] = useState<ISortDto>();

  const [tableState, tableFunctions] = useDatatableState();

  const {
    data,
    loading: isFetching,
    refetch,
  } = useSearchEnrollmentForm({
    variables: {
      businessId: businessId,
      input: {
        centerIds: selectedCenters.map((c) => c.id),
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortDtos: sort ? [sort] : undefined,
      },
    },
  });

  const hasCreateEnrollmentLeadSettingsDeletePermissions = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Delete,
  });

  const hasCreateEnrollmentLeadSettingsEditPermissions = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Edit,
  });

  const hasCreateEnrollmentLeadSettingsReadPermissions = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementSettings,
    level: RoleLevelType.Read,
  });

  useImperativeHandle(
    ref,
    () => ({
      refetch: () => {
        refetch();
      },
    }),
    [refetch]
  );

  const [encryptEnrollmentFormHashFn] = useEncryptEnrollmentFormHashMutation({
    onError: (error) => showToast(getApolloErrorMessage(error), 'error'),
  });

  const generateActions = (form: EnrollmentForm): IDropdownAction[] => {
    const values: { label: string; onClick: () => void }[] = [];

    if (hasCreateEnrollmentLeadSettingsReadPermissions) {
      values.push({
        label: t('translation:core.capitalize', { value: t('translation:spelling.preview') }),
        onClick: () => {
          setPreviewFormModalState({
            showPreviewModal: true,
            businessId: form.businessId,
            enrolmentFormId: form.id ?? '',
          });
        },
      });
      values.push({
        label: 'Print Form',
        onClick: async () => {
          const response = await encryptEnrollmentFormHashFn({ variables: { enrollmentFormId: form.id!, businessId } });
          window.open(
            `https://${getHostName()}/preview/${encodeURIComponent(
              response?.data?.encryptEnrollmentFormHash ?? ''
            )}?isSkipValidation=true&isPrintMode=true`,
            '_blank',
            'noreferrer'
          );
        },
      });
    }

    if (hasCreateEnrollmentLeadSettingsEditPermissions) {
      values.push({
        label: t('translation:core.capitalize', { value: t('translation:spelling.edit') }),
        onClick: () => {
          locationStateHistory.push({
            pathname: '/enrollment/settings/enrollment-form/' + form.id,
            state: { enrolmentForm: form },
          });
        },
      });
    }

    // to be implemented
    // if (hasCreateEnrollmentLeadSettingsDeletePermissions) {
    //   values.push({
    //     label: t('translation:core.capitalize', { value: t('translation:spelling.archive') }),
    //     onClick: () => {},
    //   });
    // }

    return values;
  };

  return (
    <>
      {!data?.searchEnrollmentForm ? (
        <DataTableLoadingSkeleton />
      ) : (
        <DataTable
          className="enrollment-forms-table"
          data={data.searchEnrollmentForm.data}
          dataSize={data.searchEnrollmentForm.totalRecords ?? 0}
          showSelect={false}
          showPagination={true}
          pageSize={tableState.pageSize}
          activePage={tableState.activePage}
          noPadding
          onSizePerPageChange={(n) => tableFunctions.changeSizePerPage(n)}
          onPageChange={(page: number, sizePerPage: number) => tableFunctions.changePage(page, sizePerPage)}
          showLoadingOverlay={isFetching}
          keyField="id"
          onSort={(field, direction) => setSortOptions({ field, direction })}
          expandRow={(row: EnrollmentForm) => (
            <>
              <span>{t('enrollment:settings.enrollment-form.associated-with-center')}</span>
              <ul>
                {row.usedByCenterIds?.map((c) => (
                  <li key={`center_expand_${c}`}>
                    <span>{centers.find((o) => o.id === c)?.name}</span>
                  </li>
                ))}
                {row.usedByCenterIds?.length === 0 && (
                  <span>{t('enrollment:settings.enrollment-form.associated-with-all-center')}</span>
                )}
              </ul>
            </>
          )}
          renderHeader={(paginationProps: any, searchProps: any) => (
            <TableHeader className="flex-wrap justify-content-between">
              <div className="d-flex flex-direction-row align-items-center">
                <SizePerPage paginationProps={paginationProps} />
                <Select
                  className="mr-4 my-2 ml-4 min-width-260"
                  isMulti
                  placeholder={capitalize(t('business:enrolment.center-selection'))}
                  options={centerOptions.map((c) => ({ ...c, value: c.id, label: c.name }))}
                  onChange={(selectedCenterValues: ICenter[]) => {
                    setSelectedCenters(selectedCenterValues || []);
                  }}
                  value={selectedCenters}
                  isLoading={false}
                  getOptionValue={(option: ICenter) => option.id}
                  getOptionLabel={(option: ICenter) => option.name}
                />
              </div>
            </TableHeader>
          )}
          columns={[
            {
              text: t('enrollment:settings.enrollment-form.enrollment-form-data-grid-col-name'),
              dataField: 'name',
              sort: true,
            },
            {
              text: capitalize(t('translation:spelling.actions')),
              dataField: 'actions',
              align: 'center',
              headerAlign: 'center',
              formatter: (cell: string, row: EnrollmentForm) => (
                <div className="d-flex justify-content-center">
                  <ActionDropdown actions={generateActions(row)} />
                </div>
              ),
            },
          ]}
        />
      )}
      {previewFormModalState && (
        <PreviewFormModal {...previewFormModalState} setShowPreviewModal={() => setPreviewFormModalState(null)} />
      )}
    </>
  );
});

export default EnrollmentFormsTable;
