import React, { useCallback, useState } from 'react';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import SideModalDrawer from 'shared/components/ModalDrawer';
import approvalCareTypes from 'shared/constants/dropdownOptions/approvalCareTypes';
import { isBlank } from 'shared/util/string';
import errorMessage from 'shared/constants/errorMessages';
import { useAddCcssApproval } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  centerId: string;
  onApprovalAddedSuccess: (approval: ICcssApproval) => void;
}

const newApproval: IAddCcssApprovalInput = {
  centerId: '',
  careType: '',
  serviceId: '',
  startDate: '',
};

const AddCcsCenterApprovalModal: React.FC<IProps> = ({ isOpen, onClose, centerId, onApprovalAddedSuccess }) => {
  const [approval, setApproval] = useState<IAddCcssApprovalInput>({ ...newApproval, centerId });

  const [addApproval, { loading }] = useAddCcssApproval();

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const isFormValid = !isBlank(approval.careType) && !isBlank(approval.serviceId) && !isBlank(approval.startDate);
  const handleUpdateApproval = useCallback(
    (value, name) => {
      setApproval({ ...approval, [name]: value });
    },
    [approval]
  );

  const handleClose = useCallback(() => {
    onClose();
    setApproval({ ...newApproval, centerId });
  }, [onClose]);

  const handleSave = useCallback(() => {
    addApproval({
      variables: {
        input: {
          ...approval,
        },
      },
    })
      .then((approval) => {
        if (approval.data?.addCcssApproval) {
          onApprovalAddedSuccess(approval.data?.addCcssApproval);
          showToast('Approval added successfully.', 'success');
          handleClose();
        }
      })
      .catch((err) => {
        showToast('There was an error.', 'error');
      });
  }, [addApproval, approval, handleClose]);

  return (
    <SideModalDrawer
      title={`Add ${fieldLabels.center} CCS Approval`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Update"
      primaryCallback={handleSave}
      secondaryChoice="Cancel"
      secondaryCallback={handleClose}
      closeOnPrimaryCallback={false}
      primaryButtonProps={{ disabled: !isFormValid, loading }}
      footerHelperText={isFormValid ? errorMessage.formRequirements : ''}
    >
      <form>
        <Row>
          <Col>
            <Select
              label="Care Type"
              required={true}
              value={approval.careType}
              options={approvalCareTypes}
              onChange={(careType) => handleUpdateApproval(careType.value, 'careType')}
              className="mb-2"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              required
              name="serviceId"
              label="CCS Service ID"
              onChange={handleUpdateApproval}
              value={approval.serviceId}
            ></TextInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <DateInput
              required
              label="CCS Start Date"
              date={approval.startDate}
              onDateSelect={(date: string) => handleUpdateApproval(date, 'startDate')}
            ></DateInput>
          </Col>
        </Row>
      </form>
    </SideModalDrawer>
  );
};

export default AddCcsCenterApprovalModal;
