import React, { useCallback, useState, useMemo } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { capitalize, orderBy, chunk } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { setCurrentCenterFilters } from 'store/context/actions';
import DataTable from 'shared/components/DataTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { TableHeader, TableSearch } from 'shared/components/DataTable';
import { IconButtonCircle } from 'shared/components/Buttons';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import Tooltip from 'shared/components/Tooltip';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { stringToHueDegree } from 'shared/util/string';
import ActionDropdown from 'shared/components/ActionDropdown';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type IExclusionTableRow = ICasualBookingExclusion & {
  centerName: string;
};

interface IProps {
  data: ICenterClassesCasualBookingSettings[] | undefined;
  loading: boolean;
  handleExclusionDeleteModalOpen: (accountName: string, accountId: string) => void;
}

const FamilyExclusionsTable: React.FC<IProps> = ({ data, loading, handleExclusionDeleteModalOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const centers: ICenter[] = useGetActiveCenters();
  const { centerFilterIds, businessId } = useSelector((state: RootState) => state.context);
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'createdAt',
    direction: 'desc',
  });

  const handleCenterFilterSelect = useCallback(
    (options: ITableFilterOption[]) => {
      dispatch(setCurrentCenterFilters(options.map((c) => c.value)));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const handleClearFilter = useCallback(() => {
    dispatch(setCurrentCenterFilters([]));
    setSearchTerm('');
    tableFunctions.changePage(1, tableState.pageSize);
  }, [dispatch, tableFunctions, tableState]);

  const onSort = (field: string, direction: 'asc' | 'desc') => setSortField({ field, direction });

  const formattedData: IExclusionTableRow[] = useMemo(() => {
    return (data ?? []).reduce<IExclusionTableRow[]>(
      (acc, center) => [
        ...acc,
        ...center.casualBookingExclusions.map((exclusion) => ({
          ...exclusion,
          centerName: center.name,
        })),
      ],
      []
    );
  }, [data]);

  const filteredData: IExclusionTableRow[] = useMemo(() => {
    let filteredData = formattedData;

    if (searchTerm) {
      filteredData =
        filteredData.filter((row) => {
          return row.account.name.toLowerCase().includes(searchTerm.toLowerCase());
        }) ?? [];
    }

    if (centerFilterIds.length) {
      filteredData = filteredData.filter((row) => centerFilterIds.some((centerId) => centerId === row.centerId));
    }

    return filteredData;
  }, [formattedData, searchTerm, centerFilterIds]);

  const sortedData = useMemo(() => {
    return orderBy(filteredData, sortField.field, sortField.direction);
  }, [sortField, filteredData]);

  const activePageData = useMemo(() => {
    return chunk(sortedData, tableState.pageSize)[tableState.activePage - 1] ?? [];
  }, [sortedData, tableState]);

  const handleSearchTermChange = (v: string) => {
    setSearchTerm(v);
    tableFunctions.changePage(1, tableState.pageSize);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <DataTable
      noPadding
      showSelect={false}
      className="kt-attendance-settings-booking-rules-exclusions-table"
      pageSize={tableState.pageSize}
      activePage={tableState.activePage}
      onPageChange={tableFunctions.changePage}
      data={activePageData}
      dataSize={sortedData.length}
      showLoadingOverlay={loading}
      noDataText={t('attendance.settings.booking-rules.families.data-table.no-data-text')}
      onSort={(field, direction) => onSort(field, direction === 'ASCENDING' ? 'asc' : 'desc')}
      renderHeader={() => (
        <TableHeader className="flex-wrap align-items-center justify-content-between">
          <TableSearch
            className={isMobile ? 'mb-2' : ''}
            onChange={handleSearchTermChange}
            placeholder={`${capitalize(t('spelling.search'))} ${capitalize(t('spelling.family_plural'))}`}
            value={searchTerm}
            enableDebounce={false}
          />
          <div className="d-flex flex-direction-row align-items-center">
            <DropdownFilter
              title={capitalize(t('spelling.center_plural'))}
              selectedFilters={centerFilterIds}
              options={
                centers
                  ?.filter((c) => c.entityId === businessId)
                  .map((c) => ({ label: c.name, value: c.id }))
                  .sort((a, b) => a.label.localeCompare(b.label)) ?? []
              }
              onFilterSelect={handleCenterFilterSelect}
            />
            <IconButtonCircle
              icon={faTimes}
              onClick={handleClearFilter}
              tooltipDirection="bottom"
              tooltipText={t('attendance.settings.booking-rules.families.data-table.clear-filter-icon-tooltip')}
              className="ml-4"
            />
          </div>
        </TableHeader>
      )}
      columns={[
        {
          text: capitalize(t('attendance.settings.booking-rules.families.data-table.column-labels.account')),
          dataField: 'account.name',
          sort: true,
          formatter: (cell: string, row: IExclusionTableRow) => (
            <AvatarDataTableCell
              avatar=""
              initials={row.account.name.charAt(0).toUpperCase()}
              primaryText={cell}
              color={`hsl(${stringToHueDegree(row.accountId)}, ${
                stringToHueDegree(row.accountId) < 50 ? '100%' : '40%'
              }, 40%`}
            />
          ),
        },
        {
          text: capitalize(t('attendance.settings.booking-rules.families.data-table.column-labels.center')),
          dataField: 'centerName',
          sort: true,
        },
        {
          text: t('attendance.settings.booking-rules.families.data-table.column-labels.reason'),
          dataField: 'reason',
          sort: true,
          formatter: (cell: string) => (
            <Tooltip text={cell || '-'} direction="top">
              <span className="md-column text-truncate">{cell ? cell : '-'}</span>
            </Tooltip>
          ),
        },
        {
          text: t('attendance.settings.booking-rules.families.data-table.column-labels.exclusion-date'),
          dataField: 'createdAt',
          sort: true,
          formatter: (cell: string) => moment(cell).format(t('formatters.MMM Do, YYYY')),
        },
        {
          text: '',
          align: 'center',
          dataField: '',
          headerClasses: 'text-center',
          classes: 'sm-column',
          formatter: (cell: any, row: IExclusionTableRow) => (
            <ActionDropdown
              actions={[
                {
                  label: capitalize(t('spelling.delete')),
                  onClick: () => handleExclusionDeleteModalOpen(row.account.name, row.accountId),
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};

export default FamilyExclusionsTable;
