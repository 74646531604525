import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { faBan, faDollarSign, faMoneyCheckEditAlt, faUndo } from '@fortawesome/pro-light-svg-icons';
import { Row } from 'shared/components/Layout';
import Tooltip from 'shared/components/Tooltip';
import colors from '_colors.module.scss';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {
  selectedSessions: ISession[];
  handleBulkRemoveFee: (type: 'early' | 'late', sessions: ISession[]) => void;
  handleBulkRestoreFee: (type: 'early' | 'late', sessions: ISession[]) => void;
  handleBulkCharge: (sessions: ISession[]) => void;
  isCharging?: boolean;
  handleBulkEditFee: (sessions: ISession[]) => void;
}

/**
 * slight modification of our existing <IconCircleButton /> to allow for a custom icon that isn't provided from FontAwesome
 */
const FinanceBulkActions: React.FC<IProps> = ({
  selectedSessions,
  handleBulkRemoveFee,
  handleBulkRestoreFee,
  handleBulkCharge,
  handleBulkEditFee,
  isCharging = false,
}) => {
  const allHaveEarlyFee = selectedSessions.every((s) => s.hasEarlyFee);
  const allHaveLateFee = selectedSessions.every((s) => s.hasLateFee);
  const noneHaveEarlyFee = selectedSessions.every((s) => !s.hasEarlyFee);
  const noneHaveLateFee = selectedSessions.every((s) => !s.hasLateFee);
  const noneCharged = selectedSessions.every((s) => !s.charged);

  const hasEditFeePermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Fees,
    level: RoleLevelType.Edit,
  });
  const { k2BulkEditFees } = useFlags();
  const canEditFee = selectedSessions.length > 0 && hasEditFeePermission; // Always show EditBulkFees when we have sessions selected

  return (
    <div className="bg-white p-4 d-flex border-bottom" style={{ height: '73px' }}>
      {allHaveEarlyFee && (
        <Tooltip text="Clear Early Drop-off Fees" direction="top">
          <Button
            onClick={() => handleBulkRemoveFee('early', selectedSessions)}
            className="bg-pale-gray kt-button rounded-circle circle-md icon-button mr-2"
            variant="light"
          >
            <Row align="center" justify="center" noGutters className="icon-container circle-md">
              <span className="fa-layers fa-fw m-auto">
                <FontAwesomeIcon icon={faBan} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
            </Row>
          </Button>
        </Tooltip>
      )}
      {noneHaveEarlyFee && (
        <Tooltip text="Restore Early Drop-off Fees" direction="top">
          <Button
            onClick={() => handleBulkRestoreFee('early', selectedSessions)}
            className="bg-pale-gray kt-button rounded-circle circle-md icon-button mr-2"
            variant="light"
          >
            <Row align="center" justify="center" noGutters className="icon-container circle-md">
              <span className="fa-layers fa-fw m-auto">
                <FontAwesomeIcon icon={faUndo} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
            </Row>
          </Button>
        </Tooltip>
      )}
      {allHaveLateFee && (
        <Tooltip text="Clear Late Drop-off Fees" direction="top">
          <Button
            onClick={() => handleBulkRemoveFee('late', selectedSessions)}
            className="bg-pale-gray kt-button rounded-circle circle-md icon-button mr-2"
            variant="light"
          >
            <Row align="center" justify="center" noGutters className="icon-container circle-md">
              <span className="fa-layers fa-fw m-auto">
                <FontAwesomeIcon icon={faBan} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
            </Row>
          </Button>
        </Tooltip>
      )}
      {noneHaveLateFee && (
        <Tooltip text="Restore Late Drop-off Fees" direction="top">
          <Button
            onClick={() => handleBulkRestoreFee('late', selectedSessions)}
            className="bg-pale-gray kt-button rounded-circle circle-md icon-button mr-2"
            variant="light"
          >
            <Row align="center" justify="center" noGutters className="icon-container circle-md">
              <span className="fa-layers fa-fw m-auto">
                <FontAwesomeIcon icon={faUndo} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
            </Row>
          </Button>
        </Tooltip>
      )}
      {noneCharged && (
        <Tooltip text="Charge" direction="top">
          <Button
            onClick={() => handleBulkCharge(selectedSessions)}
            className="bg-pale-gray kt-button rounded-circle circle-md icon-button mr-2"
            variant="light"
            disabled={isCharging}
          >
            <Row align="center" justify="center" noGutters className="icon-container circle-md">
              <FontAwesomeIcon className="m-auto fa-fw" icon={faDollarSign} color={colors['dark-gray']} />
            </Row>
          </Button>
        </Tooltip>
      )}
      {canEditFee && k2BulkEditFees && (
        <Tooltip text="Bulk Edit Fee" direction="top">
          <Button
            onClick={() => handleBulkEditFee(selectedSessions)}
            className="bg-pale-gray kt-button rounded-circle circle-md icon-button mr-2"
            variant="light"
          >
            <Row align="center" justify="center" noGutters className="icon-container circle-md">
              <FontAwesomeIcon className="m-auto fa-fw" icon={faMoneyCheckEditAlt} color={colors['dark-gray']} />
            </Row>
          </Button>
        </Tooltip>
      )}
      {selectedSessions.length &&
        !allHaveEarlyFee &&
        !allHaveLateFee &&
        !noneHaveEarlyFee &&
        !noneHaveLateFee &&
        !noneCharged &&
        !canEditFee && <small className="my-auto">No bulk actions can be performed on the selected sessions.</small>}
    </div>
  );
};

export default FinanceBulkActions;
