// @ts-nocheck
import { gql } from '@apollo/client';
import { regulationFields } from 'shared/constants/graphqlFields';
import { useMutation } from 'shared/apis/core';
import { GET_REGULATIONS } from './queries';

interface IAddRegulationVariables {
  input: {
    state: string;
    county: string | null;
    ratioTeachers: number;
    ratioChildren: number;
    capacity: number;
    startAge: {
      age: number;
      unit: RegulationAgeUnit;
    };
    endAge: {
      age: number;
      unit: RegulationAgeUnit;
    };
  };
}

interface IAddRegulationData {
  createRegulation: IRegulation;
}

interface IUpdateRegulationVariables {
  input: {
    id: string;
    state: string;
    county: string | null;
    ratioTeachers: number;
    ratioChildren: number;
    capacity: number;
    startAge: {
      age: number;
      unit: RegulationAgeUnit;
    };
    endAge: {
      age: number;
      unit: RegulationAgeUnit;
    };
  };
}

interface IUpdateRegulationData {
  updateRegulation: IRegulation;
}

const ADD_REGULATION = gql`
  mutation ($input: CreateRegulationInput!) {
    createRegulation(input: $input) {
      ${regulationFields}
    }
  }
`;

const UPDATE_REGULATION = gql`
  mutation ($input: UpdateRegulationInput!) {
    updateRegulation(input: $input) {
      ${regulationFields}
    }
  }
`;

const DELETE_REGULATION = gql`
  mutation ($input: DeleteRegulationInput!) {
    deleteRegulation(input: $input) {
      id
    }
  }
`;

export const useAddRegulation = () =>
  useMutation<IAddRegulationData, IAddRegulationVariables>(ADD_REGULATION, {
    update: (cache, result) => {
      if (result.data?.createRegulation) {
        const { createRegulation } = result.data;
        const cachedRegulations = cache.readQuery({ query: GET_REGULATIONS });

        if (cachedRegulations.getAllRegulations) {
          cache.writeQuery({
            query: GET_REGULATIONS,
            data: {
              getAllRegulations: [...cachedRegulations.getAllRegulations, createRegulation],
            },
          });
        }
      }
    },
  });

export const useUpdateRegulation = () =>
  useMutation<IUpdateRegulationData, IUpdateRegulationVariables>(UPDATE_REGULATION);

export const useDeleteRegulation = () =>
  useMutation(DELETE_REGULATION, {
    update(cache, { data: { deleteRegulation } }) {
      const { getAllRegulations } = cache.readQuery({ query: GET_REGULATIONS });
      cache.writeQuery({
        query: GET_REGULATIONS,
        data: { getAllRegulations: getAllRegulations.filter((r) => r.id !== deleteRegulation.id) },
      });
    },
  });
