import { useClearSession } from 'gql/session/mutations';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { updateSession } from '../duck/actions';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  session: ISession;
}

const ClearSessionModal: React.FC<IProps> = ({ isOpen, onClose, onSuccess, session }) => {
  const dispatch = useDispatch();

  const [clearSessionFn, { loading }] = useClearSession({
    onCompleted: (data) => {
      dispatch(updateSession(data.clearSession));
      showToast('Session cleared.', 'success');
      onSuccess();
      onClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err) => {
            // @ts-ignore - logging GraphqlErrors shows that the message can sometimes be an object
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleClearSession = useCallback(() => {
    session.id &&
      clearSessionFn({
        variables: {
          id: session.id,
        },
      });
  }, [session]);

  return (
    <ConfirmationModal
      title="Are you sure?"
      show={isOpen}
      onHide={onClose}
      primaryChoice={'Clear'}
      primaryCallback={handleClearSession}
      hideOnCallback={false}
      primaryButtonProps={{
        variant: 'danger',
        loading: loading,
      }}
    >
      You are about to clear the {session.absence ? 'absence' : 'sign in and out'} data for this session of care. This
      may require the parent to reconfirm times.
    </ConfirmationModal>
  );
};

export default ClearSessionModal;
