import { useGetItemizedBillPdfLazy, useGetItemizedBillSummaryPdfLazy } from 'gql/itemizedBill/queries';
import { useCallback, useMemo } from 'react';

export interface UseItemizedBillPdfOptions {
  onComplete?: (url: string) => void;
  onError?: (e: Error) => void;
}
export const useItemizedBillPdf = (options?: UseItemizedBillPdfOptions) => {
  const defaultOptions = {
    onComplete: () => {},
    onError: () => {},
  };
  if (!options) {
    options = defaultOptions;
  }
  const { onComplete, onError } = { ...defaultOptions, ...options };
  const [getItemizedBillPdfFn, { loading: itemizedBillPdfLoading }] = useGetItemizedBillPdfLazy({
    onCompleted: (result) => {
      if (result.getItemizedBillPdf) {
        onComplete(result.getItemizedBillPdf);
      } else {
        onError(new Error('No pdf found'));
      }
    },
    onError: onError,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [getItemizedBillSummaryPdfFn, { loading: itemizedBillSummaryPdfLoading }] = useGetItemizedBillSummaryPdfLazy({
    onCompleted: (result) => {
      if (result.getItemizedBillSummaryPdf) {
        onComplete(result.getItemizedBillSummaryPdf);
      } else {
        onError(new Error('No pdf found'));
      }
    },
    onError: onError,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const downloadingPdf = useMemo(
    () => itemizedBillPdfLoading || itemizedBillSummaryPdfLoading,
    [itemizedBillPdfLoading, itemizedBillSummaryPdfLoading]
  );
  const downloadPdf = useCallback(
    (itemizedBillId: string, pdfType: string) => {
      const variables = { variables: { id: itemizedBillId } };
      if (pdfType === 'summary') return getItemizedBillSummaryPdfFn(variables);
      if (pdfType === 'detailed') return getItemizedBillPdfFn(variables);
    },
    [getItemizedBillPdfFn, getItemizedBillSummaryPdfFn]
  );
  return { downloadingPdf, downloadPdf };
};
