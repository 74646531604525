import { useGetDocumentByIdQuery } from 'generated/graphql';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'shared/components/Spinner/Spinner';
import { RootState } from 'store/reducers';

interface IProps {
  documentId: string;
  documentName?: string | null;
  expiry?: string | null;
}

const EnrollmentFormDiffDocument: React.FC<IProps> = ({ documentId, documentName, expiry, ...props }) => {
  const { businessId } = useSelector((state: RootState) => state.context);

  const { data: documentData, loading: loadingDocument } = useGetDocumentByIdQuery({
    variables: {
      businessId: businessId ?? '',
      documentId: documentId ?? '',
    },
    skip: isEmpty(businessId) || isEmpty(documentId) || documentId === '',
  });

  const document = useMemo(() => documentData?.getDocumentById, [documentData]);

  return (
    <>
      {loadingDocument && <Spinner small />}
      {!loadingDocument && document && (
        <a className="text-primary text-primary-hover" href={document.link} target={'_blank'}>
          <span>{document?.name}</span> {expiry ? `- ${expiry}` : ''}
        </a>
      )}
      {((!loadingDocument && !document) || documentId === '') && (
        <div className="text-primary text-primary-hover">
          {documentName ?? 'Unable to get document'} {expiry ? `- ${expiry}` : ''}
        </div>
      )}
    </>
  );
};

export default EnrollmentFormDiffDocument;
