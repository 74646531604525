import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';
import { useGetPayrixDisbursementReportLazyQuery, GetPayrixDisbursementReportInput } from 'generated/graphql';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';

type UseGetPayrixDisbursementReportReturnType = [(input: GetPayrixDisbursementReportInput) => void, boolean];

const useGetPayrixDisbursementReport = (onCompleted?: () => void): UseGetPayrixDisbursementReportReturnType => {
  const { t } = useTranslation(['translation']);
  const reportDataToFile = useReportDataToFile();

  const [getPayrixDisbursementReport, { loading }] = useGetPayrixDisbursementReportLazyQuery({
    onCompleted: (result) => {
      reportDataToFile.downloadCsvFromString(
        result.getPayrixDisbursementReport.reportData[0] ?? '',
        ReportTypeEnum.PAYRIX_DISBURSEMENT
      );

      if (onCompleted) {
        onCompleted();
      }
    },
    onError: (error) => {
      showToast(t('translation:reports.general-failure-message'), 'error');
    },
  });

  const downloadReport = useCallback(
    (input: GetPayrixDisbursementReportInput) => {
      getPayrixDisbursementReport({
        variables: {
          input,
        },
      });
    },
    [getPayrixDisbursementReport]
  );

  return [downloadReport, loading];
};

export default useGetPayrixDisbursementReport;
