import { QueryHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { accsServiceMetricsFields } from './fields';

interface IGetAccsServiceMetricsForCentersVariables {
  businessId: string;
  centerIds: string[];
}

interface IGetAccsServiceMetricsForCentersData {
  getAccsMetricsForCenters: IAccsServiceMetric[];
}

export const GET_ACCS_SERVICE_METRICS_FOR_CENTERS = (fields: string = accsServiceMetricsFields) => gql`
  query($businessId: ID!, $centerIds: [ID!]!) {
    getAccsMetricsForCenters(businessId: $businessId, centerIds: $centerIds) {
      ${fields}
    }
  }
`;

export const useGetAccsServiceMetricsForCenters = (
  options?: QueryHookOptions<IGetAccsServiceMetricsForCentersData, IGetAccsServiceMetricsForCentersVariables>,
  fields?: string
) =>
  useQuery<IGetAccsServiceMetricsForCentersData, IGetAccsServiceMetricsForCentersVariables>(
    GET_ACCS_SERVICE_METRICS_FOR_CENTERS(fields),
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );
