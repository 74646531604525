import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import { batchFields } from './fields';
import { LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { transactionFields } from 'gql/transaction/fields';
import { PayrixDisbursement } from 'generated/graphql';

interface IGetBatchesForBusinessData {
  getBatchesForBusiness: IBatch[];
}

interface IGetBatchesForBusinessVariables {
  businessId: string;
}

interface IGetTotalUnbatchedPaymentTransactionsData {
  getTotalUnbatchedTransactionPayments: {
    totalUnbatchedTransactions: number;
  };
}

interface IGetUnbatchedPaymentTransactionsData {
  getUnbatchedTransactionPayments: {
    transactions: ITransaction[];
    totalUnbatchedTransactions: number;
  };
}

interface IGetTotalUnbatchedPaymentTransactionsVariables {
  input: {
    businessId: string;
    centerIds?: string[];
  };
}

interface IGetUnbatchedPaymentTransactionsVariables {
  input: {
    businessId: string;
    centerIds?: string[];
    start: string | null;
    end: string | null;
    paymentMedium?: string | null;
  };
}

interface IGetPaginatedBatchesForBusinessData {
  getPaginatedBatchesForBusiness: IPagedResult<IBatch>;
}

interface IGetPaginatedBatchesAndDisbursementsForBusinessData {
  getPaginatedBatchesForBusiness: IPagedResult<IBatch>;
  getPayrixDisbursementsForCenters: PayrixDisbursement[];
}

interface IGetPaginatedBatchesForBusinessVariables {
  input: IPaginatedBatchesQueryInputVariables;
}

interface IGetPaginatedBatchesAndDisbursementsForBusinessVariables {
  input: IPaginatedBatchesQueryInputVariables;
  input2: {
    businessId: string;
    centerIds: string[];
    startDate: string;
    endDate: string;
    pageNumber: number;
    pageSize: number;
    searchTerm: string;
    sortBy: string;
    sortDirection: string;
  };
}

export const GET_BATCHES_FOR_BUSINESS = (fields: string = batchFields) => gql`
  query ($businessId: ID!) {
    getBatchesForBusiness(businessId: $businessId) {
      ${fields}
    }
  }
`;
const totalUnbatchedFields: string = `
  totalUnbatchedTransactions`;

const unbatchedFields: string = `
  totalUnbatchedTransactions
  transactions {
    id
    transactionNumber
    accountId
    account {
      id
      name
      centerId
      center {
        id
        name
        entityId
      }
    }
    description
    amount
    date
    transactionTypeId
    transactionType {
      id
      name
      isCredit
      isDebit
      isDefaultType
    }
    createdAt
    createdBy
    createdByPerson {
      id
      firstname
      nickname
      lastname
      avatar {
        url
      }
    }
    reversedAt
    reversedBy
    reversedTransactionId
    reversedByTransactionId
    sessionId
    payment {
      amount
      approvedAt
      approvedBy
      approvedByPerson {
        id
        firstname
        nickname
        lastname
        avatar {
          url
        }
      }
      depositedAmount
      flags {
        flagType
        id
        paymentId
      }
      id
      paymentMethodUsedLastFour
      paymentMethodUsedType
      processorFailureMessage
      processorTxnId
      rejectedAt
      rejectedBy
      serviceFee
      status
      submittedAt
      total
    }
  }
`;

export const GET_TOTAL_UNBATCHED_PAYMENT_TRANSACTIONS = (fields: string = totalUnbatchedFields) => gql`
  query ($input: GetTotalUnbatchedTransactionPaymentsInput!) {
    getTotalUnbatchedTransactionPayments(input: $input) {
      ${fields}
    }
  }
`;

export const GET_UNBATCHED_PAYMENT_TRANSACTIONS = (fields: string = unbatchedFields) => gql`
  query ($input: GetUnbatchedTransactionPaymentsInput!) {
    getUnbatchedTransactionPayments(input: $input) {
      ${fields}
    }
  }
`;

export const GET_PAGINATED_BATCHES_FOR_BUSINESS = (fields: string = batchFields) => gql`
  query ($input: GetBatchesInput!) {
    getPaginatedBatchesForBusiness(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        ${fields}
      }
    }
  }
`;

export const GET_PAGINATED_BATCHES_FOR_BUSINESS_AND_DISBURSEMENTS = (fields: string = batchFields) => gql`
  query ($input: GetBatchesInput!,$input2:GetPayrixDisbursementsForCentersInput!) {
    getPaginatedBatchesForBusiness(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        ${fields}
      }
    }
    getPayrixDisbursementsForCenters(input: $input2) {
      id
      amount
      created
      description
      processed
      returnedAmount
      status
    }
  }
`;

export const useGetBatchesForBusiness = (
  options?: QueryHookOptions<IGetBatchesForBusinessData, IGetBatchesForBusinessVariables>,
  fields?: string
) => useQuery<IGetBatchesForBusinessData, IGetBatchesForBusinessVariables>(GET_BATCHES_FOR_BUSINESS(fields), options);

export const useGetTotalUnbatchedPaymentTransactions = (
  options?: QueryHookOptions<IGetTotalUnbatchedPaymentTransactionsData, IGetTotalUnbatchedPaymentTransactionsVariables>
) =>
  useQuery<IGetTotalUnbatchedPaymentTransactionsData, IGetTotalUnbatchedPaymentTransactionsVariables>(
    GET_TOTAL_UNBATCHED_PAYMENT_TRANSACTIONS(),
    options
  );

export const useGetUnbatchedPaymentTransactions = (
  options?: QueryHookOptions<IGetUnbatchedPaymentTransactionsData, IGetUnbatchedPaymentTransactionsVariables>
) =>
  useQuery<IGetUnbatchedPaymentTransactionsData, IGetUnbatchedPaymentTransactionsVariables>(
    GET_UNBATCHED_PAYMENT_TRANSACTIONS(),
    options
  );

export const useGetPaginatedBatchesForBusiness = (
  options?: QueryHookOptions<IGetPaginatedBatchesForBusinessData, IGetPaginatedBatchesForBusinessVariables>,
  fields?: string
) =>
  useQuery<IGetPaginatedBatchesForBusinessData, IGetPaginatedBatchesForBusinessVariables>(
    GET_PAGINATED_BATCHES_FOR_BUSINESS(fields),
    options
  );

export const useGetPaginatedBatchesAndDisbursementsForBusinessLazy = (
  options?: LazyQueryHookOptions<
    IGetPaginatedBatchesAndDisbursementsForBusinessData,
    IGetPaginatedBatchesAndDisbursementsForBusinessVariables
  >,
  fields?: string
) =>
  useLazyQuery<
    IGetPaginatedBatchesAndDisbursementsForBusinessData,
    IGetPaginatedBatchesAndDisbursementsForBusinessVariables
  >(GET_PAGINATED_BATCHES_FOR_BUSINESS_AND_DISBURSEMENTS(fields), options);
