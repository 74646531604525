import React, { useState, useCallback, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import { SingleAccordion } from 'shared/components/Accordion';
import PersonalInformationForm from './PersonalInformationForm';
import EmergencyContactsForm from './EmergencyContactsForm';
import TagsForm from './TagsForm';
import Card from 'shared/components/Card';
import { ApolloError } from '@apollo/client';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import DeactivateCard from './DeactivateCard';
import ReactivateCard from './ReactivateCard';
import { ChangeEmailCard, ChangeEmailModal } from 'shared/components/ChangeEmail';
import AlertContainer from 'shared/components/AlertContainer';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import ChangePinCard from 'shared/components/ChangePin/ChangePinCard';
import ChangePinModal from 'shared/components/ChangePin/ChangePinModal';
import { useUpdatePinForPerson } from 'gql/person/mutations';
import { showToast } from 'shared/components/Toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers';
import hasAreaPermissionLevel from 'shared/types/user';

interface IProps {
  data: IStaff | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  readOnly: boolean;
  canDeactivateStaff?: boolean;
  refetchStaff: () => void;
}

const ProfileTab: React.FC<IProps> = ({
  data,
  loading,
  error,
  readOnly,
  canDeactivateStaff,
  refetchStaff,
  ...props
}) => {
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showChangePinModal, setShowChangePinModal] = useState(false);
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;

  const hasEditStaffTagsPermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Tags,
    level: RoleLevelType.Edit,
  });

  const hasEditManagedEmailPermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const [updatePinForPersonFn, { loading: updatePinForPersonLoading }] = useUpdatePinForPerson({
    onCompleted: (result) => {
      showToast('Pin changed successfully.', 'success');
      setShowChangePinModal(false);
    },
    onError: (err) => {
      showToast(`There was an error changing this employee's pin. Please try again later.`, 'error');
    },
  });

  const userEmailIsExternallyManaged = useCallback(
    (override: boolean) => {
      //const user = useSelector((state: RootState) => state.user);

      if (data) {
        // a person's email is SSO Managed
        // IF they have a managed email by SSO auth AND they DON'T have edit permissions
        return data.emailIsManaged && !override;
      }

      return false;
    },
    [data]
  );
  // added so there aren't any conditional renders. if data is undefined, something went wrong and an error should be shown
  if (loading) {
    return (
      <Row>
        <Column lg>
          <Card>
            <LoadingLines number={7} />
          </Card>
        </Column>
        <Column lg>
          <Card>
            <LoadingLines number={7} />
          </Card>
        </Column>
      </Row>
    );
  }

  if (error || !data) {
    return <AlertContainer errorApolloError={error!}></AlertContainer>;
  }

  return (
    <>
      <Row>
        <Column lg>
          <Card header="Personal Information" loading={loading} loadingLines={7}>
            <PersonalInformationForm data={data} readOnly={readOnly} />
          </Card>
          {data && canDeactivateStaff && data.employmentStatus === 'Active' && <DeactivateCard staff={data} />}
          {data && canDeactivateStaff && data.employmentStatus === 'Deactivated' && <ReactivateCard staff={data} />}
        </Column>
        <Column lg>
          <Card header="Emergency Contacts" loading={loading} loadingLines={2}>
            <EmergencyContactsForm staff={data} readOnly={readOnly || AdpEnabled} />
          </Card>
          <HasRoleAreaLevel
            action={{ area: AreaType.Staff, permission: PermissionType.Tags, level: RoleLevelType.Read }}
          >
            <SingleAccordion addMb title="Employee Tags" loading={loading} loadingLines={2}>
              <TagsForm staff={data} readOnly={!hasEditStaffTagsPermission || AdpEnabled} />
            </SingleAccordion>
          </HasRoleAreaLevel>
          <Row>
            {!userEmailIsExternallyManaged(hasEditManagedEmailPermission) && (
              <Column lg={6}>
                <ChangeEmailCard
                  disabled={false}
                  isUserDeactivated={data.employmentStatus === 'Deactivated'}
                  showChangeEmailModal={() => setShowChangeEmailModal(true)}
                  AdpEnabled={AdpEnabled}
                />
              </Column>
            )}
            <Column lg={6}>
              <ChangePinCard
                disabled={data.employmentStatus === 'Deactivated'}
                showChangePinModal={() => setShowChangePinModal(true)}
                text={`Need to change this user's PIN?`}
              />
            </Column>
          </Row>
        </Column>
      </Row>
      {data && (
        <ChangeEmailModal
          refetchStaff={refetchStaff}
          currentEmail={data.email ?? ''}
          isOpen={showChangeEmailModal}
          onClose={() => setShowChangeEmailModal(false)}
          personId={data.id}
          isCognitoUserCreated={Boolean(data.cognitoConfiguredAt)}
        />
      )}
      <ChangePinModal
        isOpen={showChangePinModal}
        isLoading={updatePinForPersonLoading}
        onSubmit={(pin) => updatePinForPersonFn({ variables: { personId: data?.id ?? '', pin } })}
        onClose={() => setShowChangePinModal(false)}
      />
    </>
  );
};

export default ProfileTab;
