import React from 'react';
//components
import DataTable from 'shared/components/DataTable';
//utils
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import { useGetArrangementHistory } from '../graphql/queries';
import { getNullableVariable, getStatusCWA, statusOptionsForCWA } from '../common';
import IndicatorColor from 'shared/components/Dropdown/DropdownFilter/IndicatorColor';

interface DataTableProps {
  id: string;
  accountId: string;
  accountName: string;
  centerId: string;
  centerName: string;
  childId: string;
  childFirstName: string;
  childLastName: string;
  enrolmentId: string;
  status: string;
}

interface IProps {
  entityId: string;
  data: DataTableProps;
  timezone: string;
}

export const ExpandCwaHistory: React.FC<IProps> = ({ entityId, data, timezone }) => {
  const { t } = useTranslation(['subsidies']);

  const CwaHistoryInput = {
    businessId: entityId,
    arrangementId: data.id,
  };
  const { loading: loadingHistory, data: tableDataHistory } = useGetArrangementHistory(CwaHistoryInput);
  const tableData =
    // @ts-ignore
    tableDataHistory?.getArrangementHistory?.map((i) => {
      const lastName = i.updatedByPerson && i.updatedByPerson.lastname ? i.updatedByPerson.lastname : '';
      const updateByPerson = i.updatedByPerson
        ? `${i.updatedByPerson.firstname} ${lastName}`
        : i.status === 'REJECTED' || i.status === 'APPROVED'
        ? t('subsidies:cwa.translations.system')
        : '-';
      return {
        status: i.status,
        updatedAt: getNullableVariable(i.updatedAt, timezone),
        updatedByPerson: updateByPerson,
      };
    }) ?? [];
  return (
    <DataTable
      keyField="id"
      data={tableData ?? []}
      showSelect={false}
      showPagination={false}
      defaultSorting="asc"
      showLoadingOverlay={loadingHistory}
      columns={[
        {
          text: capitalize(t('subsidies:cwa.table-headers.status')),
          dataField: 'status',
          sort: false,
          classes: 'md-column py-4',
          formatter: (status) => {
            const statusColor = statusOptionsForCWA.find((item) => item.value === status);
            return (
              <div className="d-flex align-items-center">
                <IndicatorColor color={statusColor?.indicatorColor as string} />
                <div className="ml-2">{getStatusCWA(status)}</div>
              </div>
            );
          },
        },
        {
          text: capitalize(t('subsidies:cwa.table-headers.updated-at')),
          dataField: 'updatedAt',
          sort: false,
          classes: 'md-column py-4',
        },
        {
          text: capitalize(t('subsidies:cwa.table-headers.updated-by')),
          dataField: 'updatedByPerson',
          sort: false,
          classes: 'md-column py-4',
        },
      ]}
    />
  );
};
