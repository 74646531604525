import React, { useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useFormatDate from 'shared/hooks/useFormatDate';
import { currencyFormat } from 'shared/util/currency';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { DataTable } from 'shared/components/Table/DataTable';
import { faEllipsisV, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import Chip from 'shared/components/Chip';
import { Row } from '@tanstack/table-core';
import { MuiActionDropdown } from '../../shared/components/ActionDropdown/ActionDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { discountMatchesActiveStatus } from './DiscountFilters';
import { faMagnifyingGlass, faUserAlt } from '@fortawesome/pro-solid-svg-icons';
import HelpTooltip from '../../shared/components/Tooltip/HelpTooltip';
import { Card } from '@mui/material';

interface IProps {
  data: IDiscount[];
  loading: boolean;
  onEditDiscount: (discount: IDiscount) => void;
}

const DiscountsTable: React.FC<IProps> = ({ data, loading, onEditDiscount }) => {
  const hasEditDiscountPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.DiscountManagement,
    level: RoleLevelType.Edit,
  });

  const { t } = useTranslation(['billing']);
  const formatDate = useFormatDate();
  const getDiscountTimeframe = useCallback((discount: IDiscount): [string, string | null] => {
    if (discount.schedules.length === 0) {
      return ['0001-01-01', null];
    }

    // assumes that the backend has sorted the schedules
    const start = discount.schedules[0].startDate;
    const end = discount.schedules[discount.schedules.length - 1].endDate ?? null;

    return [start, end];
  }, []);

  const getActiveDiscountSchedule = useCallback((discount: IDiscount): IDiscountSchedule | undefined => {
    if (discount.schedules.length === 0) {
      return undefined;
    }

    const today = moment();
    // assumes that the backend has sorted the schedules
    const firstSchedule = discount.schedules[0];

    if (today.isSameOrBefore(moment(firstSchedule.startDate))) {
      return firstSchedule;
    }

    const lastSchedule = discount.schedules[discount.schedules.length - 1];

    if (today.isSameOrAfter(moment(lastSchedule.startDate))) {
      return lastSchedule;
    }

    return (
      discount.schedules.find(
        (schedule) => today.isSameOrAfter(moment(schedule.startDate)) && today.isSameOrBefore(moment(schedule.endDate))
      ) ?? firstSchedule
    );
  }, []);

  const sortStartDateColums = (rowA: Row<IDiscount>, rowB: Row<IDiscount>, _columnId: string): number => {
    const activeDiscountScheduleA = getActiveDiscountSchedule(rowA.original);
    const activeDiscountScheduleB = getActiveDiscountSchedule(rowB.original);
    const dateA = activeDiscountScheduleA ? moment(activeDiscountScheduleA.startDate) : moment();
    const dateB = activeDiscountScheduleB ? moment(activeDiscountScheduleB.startDate) : moment();

    return dateA.isBefore(dateB) ? -1 : 1; // ascending
  };

  return data.length ? (
    <>
      <DataTable<IDiscount>
        data={data}
        dataSize={data.length}
        pageSize={25}
        columnFilters={true}
        initialSort={[
          {
            id: 'Discount_Column_Start',
            desc: true,
          },
        ]}
        noDataText={t('billing:discounts.data-table.no-data-message')}
        loading={loading}
        onPageChange={(pageIndex, pageSize) => {
          // Manual page change handling goes here.
          // console.log('Index', pageIndex, 'Size', pageSize);
        }}
        columns={[
          {
            header: t('billing:discounts.data-table.name-column'),
            accessorKey: 'name',
            id: 'Discount_Column_Name',
            size: 200,
          },
          {
            header: t('billing:discounts.data-table.status-column'),
            id: 'Discount_Column_Status',
            size: 110,
            enableSorting: true,
            Cell: ({ row }) => {
              let status;
              let chipType;
              if (discountMatchesActiveStatus(row.original, 'Active')) {
                status = 'Active';
                chipType = 'success';
              } else if (discountMatchesActiveStatus(row.original, 'Scheduled')) {
                status = 'Scheduled';
                chipType = 'info';
              } else if (discountMatchesActiveStatus(row.original, 'Ended')) {
                status = 'Ended';
                chipType = 'default';
              } else {
                status = 'Inactive';
                chipType = 'warning';
              }

              return (
                <Chip text={status} type={chipType} rounded border style={{ fontSize: '0.75rem', fontWeight: 400 }} />
              );
            },
            sortingFn: sortStartDateColums,
          },
          {
            // header: t('billing:discounts.data-table.associated-children-column'),
            // header: 'Associated',
            header: '',
            Header: () => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>Associated</div>
                <div>
                  <HelpTooltip
                    icon={faQuestionCircle}
                    size={`sm`}
                    direction="bottom"
                    text="The number of children this discount is actively associated with."
                  />
                </div>
              </div>
            ),
            id: 'Discount_Column_Associated_Children',
            size: 110,
            enableSorting: true,
            Cell: ({ row }) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FontAwesomeIcon
                    icon={faUserAlt}
                    size="2x"
                    color={`#88689D`}
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.08)',
                      borderRadius: '9999px',
                      width: '1.25rem',
                      height: '1.25rem',
                      padding: '0.25rem',
                      backgroundColor: '#F8F6F9',
                      overflow: 'hidden',
                    }}
                  />
                  <span
                    style={{
                      display: 'inline-block',
                      paddingTop: '0.3rem',
                      paddingLeft: '0.25rem',
                      marginLeft: '-0.25rem',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '9999px',
                      width: '2rem',
                      height: '2rem',
                      color: '#98A2B3',
                      fontWeight: 600,
                      backgroundColor: '#F2F4F7',
                      border: '1px solid rgba(0, 0, 0, 0.08)',
                      cursor: 'pointer',
                    }}
                    title={'Click to Expand'}
                  >
                    +50
                  </span>
                </div>
              );
            },
          },
          {
            header: t('billing:discounts.data-table.type-column'),
            id: 'Discount_Column_Type',
            size: 130,
            filterVariant: 'text',
            Cell: ({ row }) => {
              return row.original.appliesTo === 'SESSION'
                ? 'Fee'
                : row.original.appliesTo
                    .toLowerCase()
                    .replace('_', ' ')
                    .split(' ')
                    .map((word) => word[0].toUpperCase() + word.substring(1))
                    .join(' ');
            },
          },
          {
            header: t('billing:discounts.data-table.start-column'),
            accessorKey: 'start',
            id: 'Discount_Column_Start',
            size: 135,
            enableSorting: true,
            Cell: ({ row }) => {
              const timeframe = getDiscountTimeframe(row.original);

              // return formatDate(timeframe[0]);
              return formatDate(timeframe[0], 'MMM D, YYYY');
            },
            sortingFn: sortStartDateColums,
          },
          {
            header: t('billing:discounts.data-table.end-column'),
            accessorKey: 'end',
            id: 'Discount_Column_End',
            size: 135,
            Cell: ({ row }) => {
              const timeframe = getDiscountTimeframe(row.original);
              const end = timeframe[1];

              return !end ? 'Ongoing' : formatDate(end, 'MMM D, YYYY');
            },
          },
          {
            header: t('billing:discounts.data-table.amount-column'),
            muiTableHeadCellProps: {
              align: 'right',
            },
            muiTableBodyCellProps: {
              align: 'right',
            },
            accessorKey: 'amount',
            id: 'Discount_Column_Amount',
            size: 20,
            Cell: ({ row }) => {
              const activeDiscountSchedule = getActiveDiscountSchedule(row.original);

              let amount: string;
              if (!activeDiscountSchedule) {
                return <div></div>;
              }

              if (activeDiscountSchedule.amountType === 'FLAT') {
                amount = `${currencyFormat(activeDiscountSchedule.amount)}`;
              } else {
                amount = `${(activeDiscountSchedule.amount * 100).toFixed(2)}%`;
              }

              return amount;
            },
          },
          ...(hasEditDiscountPermission
            ? [
                {
                  header: '',
                  accessorKey: '',
                  id: 'Discount_Column_Actions',
                  size: 68,
                  Cell: ({ row }) => (
                    <div className={`text-right`}>
                      <MuiActionDropdown
                        className="flex-grow-0 rounded-left"
                        actions={[
                          {
                            label: t('billing:discounts.data-table.view-edit-discount-menu-item'),
                            onClick: () => onEditDiscount(row.original),
                          },
                          {
                            label: t('billing:discounts.data-table.archive-discount-menu-item'),
                            onClick: () => {},
                            labelClassName: `context-menu-item-archive`,
                          },
                        ]}
                        customIconButton={<FontAwesomeIcon size={'lg'} icon={faEllipsisV} />}
                      />
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
    </>
  ) : (
    <Card
      style={{
        marginTop: '1rem',
        padding: '2rem',
        border: '0.125rem solid #EAECF0',
        borderRadius: '1rem',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          color: '#606060',
        }}
      >
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '1rem',
            borderRadius: '1rem',
            border: '1px solid #EAECF0',
            marginBottom: '1.25rem',
          }}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} size={`xl`} color={'#667085'} />
        </div>
        <div style={{ fontWeight: 'bold', color: '#667085', marginBottom: '0.75rem' }}>
          {t('billing:discounts.data-table.no-data-message')}
        </div>
        <div style={{ fontSize: '0.75rem', color: '#98A2B3' }}>
          {t('billing:discounts.data-table.no-data-message-explanation')}
        </div>
      </div>
    </Card>
  );
};

export default DiscountsTable;
