import React from 'react';
import { Form } from 'react-bootstrap';
import { Row } from 'shared/components/Layout';
import useFormatDate from 'shared/hooks/useFormatDate';

interface IProps {
  condition: IAllergy | IMedicalCondition | IRestriction;
}

const ActiveDocumentsSummary: React.FC<IProps> = ({ condition }) => {
  const formatDate = useFormatDate();
  const activeDocuments = condition.documents?.filter((d) => !d.archivedAt) ?? [];

  return activeDocuments.length > 0 ? (
    <div className="mb-4">
      <Form.Label>Documentation</Form.Label>
      <div className="bg-very-light-blue px-4 py-2 rounded">
        {activeDocuments.map((d) => (
          <Row className="flex-nowrap text-overflow-ellipsis py-1" noGutters>
            <div className="text-overflow-ellipsis mr-4">{d.name}</div>
            <div className="ml-auto">Submitted: {formatDate(d.createdAt, 'MM/DD/YYYY')}</div>
          </Row>
        ))}
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default ActiveDocumentsSummary;
