import { toast, TypeOptions, ToastContent, PositionOptions } from 'react-toastify';

// simply re-export react-toastify's 'toast' function with some standardized options
export default (content: ToastContent, type: TypeOptions, position?: PositionOptions) =>
  toast(content, {
    type,
    position: position ?? 'top-right',
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
  });
