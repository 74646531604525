import React from 'react';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Button from 'react-bootstrap/Button';

type AccsType = 'Certificate' | 'Determination' | undefined;

interface IProps {
  isOpen: boolean;
  onClose: (type: AccsType) => void;
}

const AccsSelectTypeModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const handleClose = () => {
    onClose(undefined);
  };

  const handleCreate = (selectedType: AccsType) => {
    onClose(selectedType);
  };

  return (
    <CenteredModal
      size="sm"
      show={isOpen}
      onHide={handleClose}
      title="Child at Risk"
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      //@ts-ignore
      primaryButtonProps={{ className: 'd-none' }}
    >
      <Button className="w-100 mt-4" onClick={() => handleCreate('Certificate')}>
        <span className="pl-2">Create certificate</span>
      </Button>
      <Button className="w-100 mt-2" onClick={() => handleCreate('Determination')}>
        <span className="pl-2">Create determination</span>
      </Button>
    </CenteredModal>
  );
};

export default AccsSelectTypeModal;
