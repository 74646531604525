import React from 'react';

import * as Mui from '@mui/material';
import RightModalDrawer from 'shared/components/MUI/RightModalDrawer';
import { useUpdatePosition } from '../graphQL/mutations';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import _ from 'lodash';

interface IProps {
  open: boolean;
  onClose: () => void;
  position: IPosition;
}

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
};

const EditPosition: React.FC<IProps> = ({ open, onClose, position }) => {
  const [newPositionName, setNewPositionName] = React.useState(position.name);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const { t } = useTranslation(['translation', 'settings']);

  const [updatePositionFn] = useUpdatePosition({
    onCompleted: () => {
      showToast('Position updated successfully', 'success');
      onClose();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onSave = () => {
    setEditModalOpen(false);
    updatePositionFn({
      variables: {
        input: {
          businessId: position.businessId,
          id: position.id,
          name: newPositionName,
        },
      },
    });
  };

  return (
    <>
      <RightModalDrawer open={open} onClose={onClose} title="Edit Position">
        <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '16px' }}>
          <Mui.Stack direction="column" spacing={2} marginLeft="16px">
            <Mui.FormControlLabel
              label={<span>Position Name</span>}
              labelPlacement="top"
              control={
                <Mui.TextField
                  size="small"
                  value={newPositionName}
                  required
                  onChange={(evt) => setNewPositionName(evt.target.value)}
                  fullWidth
                  sx={styles.inputStyles}
                  variant="outlined"
                />
              }
              componentsProps={{
                typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
              }}
            />
          </Mui.Stack>
        </Mui.DialogContent>
        <Mui.Box style={{ margin: '8px' }}>
          <Mui.DialogActions>
            <Mui.Button variant="cancel" onClick={() => onClose()}>
              Cancel
            </Mui.Button>
            <Mui.Button
              disabled={_.isEmpty(newPositionName) || position.name === newPositionName}
              color="secondary"
              variant="contained"
              onClick={() => setEditModalOpen(true)}
            >
              Review
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Box>
      </RightModalDrawer>
      <Mui.Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography variant="dialogTitle">Edit Position</Mui.Typography>
          <Mui.IconButton onClick={() => setEditModalOpen(false)} disableRipple sx={styles.closeIcon}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>
          {`Are you sure you want to rename ${position.name} to ${newPositionName}?`}
          {position.assignedStaffCount > 0 && (
            <>
              <br />
              {`${position.assignedStaffCount} active employees will have their position changed to ${newPositionName}.`}
            </>
          )}
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setEditModalOpen(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button variant="contained" color="secondary" onClick={() => onSave()} id="modal-drawer-primary-btn">
            Confirm
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default EditPosition;
