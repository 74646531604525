import { Box, Button, Card, Stack, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import Currency from 'shared/components/Currency';
import { IRowType } from './History';
import './styles.scss';
import useFormatDate from 'shared/hooks/useFormatDate';

interface IProps {
  billingActivityItems: any[];
  handleLoadMore: () => void;
  handleUpdateSelectedRow: (x: IRowType) => void;
  loading?: boolean;
  selectedRow: IRowType | null;
  take: number;
  totalItems: number;
}

const ActivityTable: React.FC<IProps> = ({
  billingActivityItems,
  handleLoadMore,
  handleUpdateSelectedRow,
  loading = false,
  selectedRow,
  totalItems,
  take,
}) => {
  const formatDate = useFormatDate();
  const canLoadMore = React.useMemo(() => totalItems > take, [totalItems, take]);

  const theme = useTheme();

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        disableReorder: true,
        disableColumnMenu: true,
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams) => formatDate(params.value, 'MM/DD/YY'),
        description: 'Date the activity occurred on',
      },
      {
        field: 'type',
        headerName: 'Activity',
        disableReorder: true,
        disableColumnMenu: true,
        flex: 1,
        sortable: false,
        description: 'The kind of activity that occurred',
      },
      {
        field: 'charges',
        headerName: 'Charges',
        description:
          'These events have affected the account balance in a way that makes the customer owe you more money',
        disableReorder: true,
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'credits',
        headerName: 'Payments/Credits',
        description:
          'These events have affected the account balance in a way that makes the customer owe you less money',
        disableReorder: true,
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'runningBalance',
        headerName: 'Running Balance',
        headerAlign: 'right',
        align: 'right',
        disableReorder: true,
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
    ],
    [formatDate]
  );

  return (
    <>
      <Box>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          marginBottom={2}
          marginTop={2}
          alignItems="center"
        >
          <Tooltip title="Billing History shows a list of events that affected the balance of this account.">
            <h2>Billing History</h2>
          </Tooltip>
        </Stack>
        <Card variant="outlined" sx={{ borderRadius: '4px 4px 4px 4px' }}>
          <DataGrid
            autoHeight={true}
            loading={loading}
            aria-label="Billing History Table"
            rows={billingActivityItems ?? []}
            columns={columns}
            hideFooterPagination
            disableRowSelectionOnClick
            density="compact"
            sx={{
              color: theme.palette.text.primary,
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
              '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            onRowClick={(params: GridRowParams) =>
              handleUpdateSelectedRow({ id: params.row.id, type: params.row.type })
            }
            getRowClassName={(params) => (params.row.id === selectedRow?.id ? 'selected-table-row' : '')}
            slots={{
              footer: () => (
                <Box textAlign="center">
                  <Button size="small" fullWidth onClick={handleLoadMore} disabled={!canLoadMore}>
                    Load More
                  </Button>
                </Box>
              ),
              toolbar: () => (
                <GridToolbarContainer>
                  <Tooltip title={'Exporting will only export the data currently on the screen.'}>
                    <GridToolbarExport />
                  </Tooltip>
                </GridToolbarContainer>
              ),
            }}
          />
        </Card>
      </Box>
    </>
  );
};

export default ActivityTable;
