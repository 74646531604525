import React from 'react';

import * as Mui from '@mui/material';
import RightModalDrawer from 'shared/components/MUI/RightModalDrawer';
import { useUpdatePayCode } from '../graphQL/mutations';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import DetailedRadioOption from 'shared/components/DetailedRadioOption';
import FormLabel from 'shared/components/Form/FormLabel';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import _ from 'lodash';

interface IProps {
  open: boolean;
  onClose: () => void;
  payCode: IPayCodeType;
}

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

const EditPayCode: React.FC<IProps> = ({ open, onClose, payCode }) => {
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const { t } = useTranslation(['translation', 'settings']);
  const [description, setDescription] = React.useState(payCode.description);
  const [overTime, setOvertime] = React.useState<boolean>(payCode.overtimeEligible);
  //k2-adp-overtime
  const { k2WebPtoTypes } = useFlags();

  const adpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;

  const [updatePayCodeFn] = useUpdatePayCode({
    onCompleted: () => {
      showToast('PTO Type Updated', 'success');
      onClose();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onSave = () => {
    updatePayCodeFn({
      variables: {
        input: {
          businessId: payCode.businessId,
          id: payCode.id,
          description: payCode.description,
        },
      },
    });
  };

  return (
    <>
      <RightModalDrawer open={open} onClose={onClose} title="Edit Pay Code">
        <Mui.DialogContent style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '16px' }}>
          <Mui.Stack direction="column" spacing={2} marginLeft="16px">
            <Mui.Box>
              <Mui.Typography variant="inputLabel">Name</Mui.Typography>
              <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                <Mui.Typography variant="body1" fontSize="18px">
                  {payCode.name}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
            <Mui.FormControlLabel
              label={<span>Description</span>}
              labelPlacement="top"
              control={
                <Mui.TextField
                  size="small"
                  value={description}
                  required
                  onChange={(evt) => setDescription(evt.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  sx={styles.inputStyles}
                  variant="outlined"
                />
              }
              componentsProps={{
                typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
              }}
            />
            <div>
              <FormLabel>Purpose</FormLabel>
              <DetailedRadioOption
                id="personal-code-type"
                // @ts-ignore
                title={t('settings:operations.pay-code.personal-purpose-label')}
                description={t('settings:operations.pay-code.personal-purpose-description')}
                onSelect={(isSelected) => console.log(isSelected ? 'PERSONAL' : 'CENTER')}
                isDisabled
                isSelected={payCode.trainingTimeEventType === null}
                className="mb-2"
              />
              <DetailedRadioOption
                id="center-code-type"
                // @ts-ignore
                title={t('settings:operations.pay-code.center-purpose-label')}
                description={t('settings:operations.pay-code.center-purpose-description')}
                onSelect={(isSelected) => console.log(isSelected ? 'CENTER' : 'PERSONAL')}
                isDisabled
                isSelected={payCode.trainingTimeEventType !== null}
              />
            </div>
            {payCode.trainingTimeEventType !== null && (
              <Mui.Box>
                <Mui.Typography variant="inputLabel">
                  {t('settings:operations.pay-code.center-category-label')}
                </Mui.Typography>
                <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                  <Mui.Typography variant="body1" fontSize="18px">
                    {payCode.trainingTimeEventType}
                  </Mui.Typography>
                </Mui.Box>
              </Mui.Box>
            )}
            <Mui.Box>
              <Mui.Typography variant="inputLabel">Code</Mui.Typography>
              <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                <Mui.Typography variant="body1" fontSize="18px">
                  {payCode.code}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
            <Mui.Box>
              <Mui.Typography variant="inputLabel">Center</Mui.Typography>
              <Mui.Box bgcolor="#F9FAFB" border="solid 1px #C5D0DE" borderRadius="5px" padding="8px">
                <Mui.Typography variant="body1" fontSize="18px">
                  {payCode.center?.name ?? 'All Centers'}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
            {k2WebPtoTypes && adpEnabled && (
              <div>
                <FormLabel required>Eligible for Overtime</FormLabel>
                <DetailedRadioOption
                  id="personal-overtime-yes"
                  title="Yes"
                  onSelect={() => setOvertime(true)}
                  isDisabled
                  isSelected={payCode.overtimeEligible}
                  className="kt-detailed-radio-option-no-border mb-0"
                />
                <DetailedRadioOption
                  id="personal-overtime-no"
                  title="No"
                  onSelect={() => setOvertime(false)}
                  isDisabled
                  isSelected={!payCode.overtimeEligible}
                  className="kt-detailed-radio-option-no-border mb-0"
                />
              </div>
            )}
          </Mui.Stack>
        </Mui.DialogContent>
        <Mui.Box style={{ margin: '8px' }}>
          <Mui.DialogActions>
            <Mui.Button variant="cancel" onClick={() => onClose()}>
              Cancel
            </Mui.Button>
            <Mui.Button
              // disabled={_.isEmpty(title) || _.isEmpty(noteType) || _.isEmpty(noteComment)}
              color="secondary"
              variant="contained"
              onClick={() => onSave()}
              id="modal-drawer-primary-btn"
            >
              Save
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Box>
      </RightModalDrawer>
    </>
  );
};

export default EditPayCode;
