import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { orderBy, groupBy, mapValues } from 'lodash';
import { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import PageWrapper from 'shared/components/PageWrapper';
import { Col } from 'shared/components/Layout';
import { useGetRolesForBusiness } from 'shared/hooks/useGetRolesForBusiness';
import HierarchyLevelGroup from './HierarchyLevelGroup';
import RoleCard from './RoleCard';
import { getNumberWithOrdinal } from 'shared/util/string';
import CreateUpdateRoleModal from './CreateUpdateRoleModal';
import { useUpdateRole } from 'gql/role/mutations';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { roleFieldsWithStaff } from 'gql/role/fields';
import BusinessAndCenterSelectBanner from 'shared/components/CenterSelectBanner/CenterSelectBanner';
import PageBody from 'shared/components/PageWrapper/Body';
import DeleteRoleModal from './DeleteRoleModal';

interface IRoleModalState {
  isOpen: boolean;
  role: IRole | null;
}

interface IProps {}

const groupRolesByLevel = (roles: IRole[]): Record<string, IRole[]> => {
  const grouped = mapValues(
    groupBy(roles, (role) => role.hierarchyLevel),
    (roles) => orderBy(roles, (role) => role.name, 'asc')
  );
  const startingLevel = Math.min(...Object.keys(grouped).map((level) => parseInt(level, 10)));
  const endingLevel = Math.max(...Object.keys(grouped).map((level) => parseInt(level, 10)));

  // there should be no level jumps (i.e. 3 to 5) in the ui so we need to add any missing levels into our map
  for (let index = startingLevel; index < endingLevel; index++) {
    if (!grouped[index]) {
      grouped[index] = [];
    }
  }

  return grouped;
};

const RoleHierarchy: React.FC<IProps> = ({ ...props }) => {
  const [roleHierarchyLevels, setRoleHierarchyLevels] = useState<Record<string, IRole[]>>({});
  const [showRoleModal, setShowRoleModal] = useState<IRoleModalState>({ isOpen: false, role: null });
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState<IRoleModalState>({ isOpen: false, role: null });
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const user = useSelector((state: RootState) => state.user);
  const { data: getRolesForBusinessData, loading: getRolesForBusinessLoading } = useGetRolesForBusiness(
    currentBusinessId ?? '',
    roleFieldsWithStaff
  );
  const [updateRoleFn] = useUpdateRole();

  useEffect(() => {
    // only set state from query if the local state is empty and the query is not loading (to prevent reading cached data)
    if (
      getRolesForBusinessData?.getRolesForBusiness &&
      !Object.keys(roleHierarchyLevels).length &&
      !getRolesForBusinessLoading
    ) {
      setRoleHierarchyLevels(groupRolesByLevel(getRolesForBusinessData.getRolesForBusiness));
    }
  }, [getRolesForBusinessData, roleHierarchyLevels, getRolesForBusinessLoading]);

  useEffect(() => {
    // reset the roles in state so roles from the previous business aren't visible
    if (currentBusinessId) {
      setRoleHierarchyLevels({});
    }
  }, [currentBusinessId]);

  /**
   * Remove an existing role id out of its current level and into its new one in state
   */
  const updateStateHierarchy = useCallback(
    (roleId: string, updatedLevel: string) => {
      let role: IRole | null = null;
      const roles: Record<string, IRole[]> = { ...roleHierarchyLevels };

      Object.entries(roles).forEach(([key, value]) => {
        const roleIndex = value.map((role) => role.id).indexOf(roleId);

        if (roleIndex !== -1) {
          role = value.find((role) => role.id === roleId) as IRole;
          role.hierarchyLevel = parseInt(updatedLevel, 10);

          roles[key] = roles[key].filter((role) => role.id !== roleId);
        }
      });

      if (role) {
        roles[updatedLevel].push(role);
      }

      setRoleHierarchyLevels(roles);
    },
    [roleHierarchyLevels]
  );

  const updateRoleHierarchyLevel = useCallback(
    (roleId: string, hierarchyLevel: string) => {
      const draggedRole = Object.values(roleHierarchyLevels)
        .flat()
        .find((role) => role.id === roleId);

      if (!draggedRole) return;

      // optimistic UI update, assume the api call will succeed but don't wait for it
      updateStateHierarchy(roleId, hierarchyLevel);
      updateRoleFn({
        variables: {
          input: {
            roleId,
            name: draggedRole.name,
            hierarchyLevel: parseInt(hierarchyLevel, 10),
            areaLevels: draggedRole.areaLevels.map((al) => ({
              roleId: al.roleId,
              area: al.area,
              permission: al.permission,
              level: al.level,
            })),
          },
        },
      }).catch((err) => {});
    },
    [roleHierarchyLevels, updateStateHierarchy, updateRoleFn]
  );

  const handleRoleDragAndDrop = useCallback(
    (result: DropResult) => {
      // dropped nowhere
      if (!result.destination) {
        return;
      }

      // did not move anywhere
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.source.index === result.destination.index
      ) {
        return;
      }

      const newRoleLevel = result.destination.droppableId;
      const draggedRoleId = result.draggableId;

      updateRoleHierarchyLevel(draggedRoleId, newRoleLevel);
    },
    [updateRoleHierarchyLevel]
  );

  const addLevel = useCallback(() => {
    setRoleHierarchyLevels((prev) => ({
      ...prev,
      [Object.keys(prev).length]: [],
    }));
  }, []);

  const getHierarchyLevelLabel = useCallback((level: string): string => {
    const numberLevel = parseInt(level, 10);

    if (numberLevel === 0) return 'Top Level';

    return `${getNumberWithOrdinal(numberLevel + 1)} Level`;
  }, []);

  const handleSuccessfulRoleCreation = useCallback((role: IRole) => {
    setRoleHierarchyLevels((prev) => ({
      ...prev,
      [role.hierarchyLevel]: orderBy([...prev[role.hierarchyLevel], role], (role) => role.name, 'asc'),
    }));
    document.location.reload();
  }, []);

  const handleSuccessfulRoleEdit = useCallback((role: IRole) => {
    setRoleHierarchyLevels((prev) => ({
      ...prev,
      [role.hierarchyLevel]: prev[role.hierarchyLevel].map((r) => (r.id === role.id ? role : r)),
    }));
    document.location.reload();
  }, []);

  /**
   * Get the lowest hierarchy level. Math.max is used since our hierarchy levels are ascending:
   * - the higher the role, the lower its numeric value (i.e. Accounts Owners have a level of 0)
   */
  const getLowestHierarchyLevel = useCallback((): number => {
    return Math.max(...Object.keys(roleHierarchyLevels).map((level) => parseInt(level, 10)), 1);
  }, [roleHierarchyLevels]);

  return (
    <PageWrapper
      pageTitle="Roles and Permissions"
      mobileButtonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Business, permission: PermissionType.Roles, level: RoleLevelType.Create }}
        >
          <CirclePlusButton variant="primary" onClick={() => setShowRoleModal({ isOpen: true, role: null })} />
        </HasRoleAreaLevel>
      }
      buttonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Business, permission: PermissionType.Roles, level: RoleLevelType.Create }}
        >
          <CreateButton onClick={() => setShowRoleModal({ isOpen: true, role: null })}>Create New Role</CreateButton>
        </HasRoleAreaLevel>
      }
      applyPadding={false}
    >
      {user?.isInternal && <BusinessAndCenterSelectBanner showCenterSelect={false} pageName="roles and permissions" />}
      <PageBody>
        {getRolesForBusinessLoading ? (
          <section className="kt-role-hierarchy-group">
            <div className="row align-items-center kt-role-hierarchy-group-body mx-0 p-4 justify-content-center mb-4">
              <Col md={6}>
                <RoleCard loading />
              </Col>
            </div>
          </section>
        ) : (
          <DragDropContext onDragEnd={handleRoleDragAndDrop}>
            {Object.keys(roleHierarchyLevels).map((level: string, idx: number) => (
              <HierarchyLevelGroup
                key={`${level}-${idx}`}
                label={getHierarchyLevelLabel(level)}
                level={level}
                roles={roleHierarchyLevels[level]}
                onEditRole={(role) => setShowRoleModal({ isOpen: true, role })}
                onDeleteRole={(role) => setShowDeleteRoleModal({ isOpen: true, role })}
              />
            ))}
          </DragDropContext>
        )}
        <HasRoleAreaLevel
          action={{ area: AreaType.Business, permission: PermissionType.Roles, level: RoleLevelType.Create }}
        >
          <CreateButton
            variant="outline-secondary"
            className="w-100"
            onClick={addLevel}
            disabled={getRolesForBusinessLoading}
          >
            Create Another Level
          </CreateButton>
        </HasRoleAreaLevel>
      </PageBody>
      {showRoleModal.isOpen && (
        <CreateUpdateRoleModal
          isOpen={showRoleModal.isOpen}
          onClose={() => setShowRoleModal({ isOpen: false, role: null })}
          onRoleCreate={handleSuccessfulRoleCreation}
          onRoleEdit={handleSuccessfulRoleEdit}
          businessId={currentBusinessId ?? ''}
          levelToCreateRoleAt={getLowestHierarchyLevel()}
          role={showRoleModal.role}
        />
      )}
      {showDeleteRoleModal.isOpen && (
        <DeleteRoleModal
          isOpen={showDeleteRoleModal.isOpen}
          onClose={() => setShowDeleteRoleModal({ isOpen: false, role: null })}
          onRoleDelete={(role) => {
            // ** Reloads the page. Might be a better solution that is close to what I have below it. **
            document.location.reload();
          }}
          businessId={currentBusinessId ?? ''}
          role={showDeleteRoleModal.role}
        />
      )}
    </PageWrapper>
  );
};

export default RoleHierarchy;
