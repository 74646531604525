import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import momentTz from 'moment';
import { orderBy } from 'lodash';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import { CreateButton } from 'shared/components/Buttons';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import ChildSelect from './ChildSelect';
import ContractCarousel from './ContractCarousel';
import { RootState } from 'store/reducers';
import { useGetContractsForChild } from 'gql/contract/queries';
import { getContractsForAccountChild } from 'pages/Families/subroutes/Accounts/duck/actions';
import UpdateContractWithReasonModal from './UpdateContractWithReasonModal';
import CreateContractModal from './CreateContractModal';
import UpdateContractModal from './UpdateContractModal';
import { getFullName } from 'shared/util/string';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import Switch from 'shared/components/Switch';

export const ACTIVE_CONTRACT_OPTION = { label: 'Active', value: 'ACTIVE' };
export const FUTURE_CONTRACT_OPTION = { label: 'Future', value: 'FUTURE' };
export const EXPIRED_CONTRACT_OPTION = { label: 'Expired', value: 'EXPIRED' };

export const ContractStatusOptions: ITableFilterOption[] = [
  ACTIVE_CONTRACT_OPTION,
  FUTURE_CONTRACT_OPTION,
  EXPIRED_CONTRACT_OPTION,
];

interface IProps {
  children: IAccountChild[];
  centerId: string;
  accountId: string;
  showArchivedChildren: boolean;
  setShowArchivedChilden: (value: boolean) => void;
}

const EnrollmentTab: React.FC<IProps> = ({
  children,
  centerId,
  accountId,
  showArchivedChildren,
  setShowArchivedChilden,
  ...props
}) => {
  const dispatch = useDispatch();
  const timezones = useSelector((state: RootState) => state.timezone.byCenterId);
  const contractsByAccountId = useSelector((state: RootState) => state.accounts.contracts.byAccountId);
  const contractsByChildForAccount = contractsByAccountId[accountId] ?? {};

  const timezone = timezones[centerId] ?? momentTz.tz.guess();
  const [selectedChildId, setSelectedChildId] = useState('');
  const [isCreateContractModalOpen, setCreateContractModalOpen] = useState(false);
  const [contractPendingUpdateOrEnd, setContractPendingUpdateOrEnd] = useState<IContract | null>(null);
  const [contractToUpdate, setContractToUpdate] = useState<IContract | null>(null);
  const [contractToEnd, setContractToEnd] = useState<IContract | null>(null);

  const [contractStatusFilters, setContractStatusFilters] = useState<ITableFilterOption[]>([
    ACTIVE_CONTRACT_OPTION,
    FUTURE_CONTRACT_OPTION,
  ]);
  const accountData = useSelector((state: RootState) => state.accounts.byId[accountId]);
  const childrenForSelect = useMemo(
    () => children.filter((c) => showArchivedChildren || !c.archivedAt),
    [children, showArchivedChildren]
  );
  const activeChild = useMemo(
    () => childrenForSelect.find((c) => c.id === selectedChildId),
    [selectedChildId, childrenForSelect]
  );
  const activeChildSubsidyEnrollments: IAgencyAccountChildEnrollment[] = activeChild
    ? (accountData.agencyAccountChildren ?? [])
        .filter(
          (agencyAccountChild: IAgencyAccountChild) =>
            agencyAccountChild.accountChildId === activeChild.accountChildId && agencyAccountChild.archivedAt === null
        )
        .reduce<IAgencyAccountChildEnrollment[]>((acc, curr) => [...acc, ...curr.agencyAccountChildEnrollments], [])
        .filter((e) => e.status !== 'ARCHIVED')
    : [];
  const { loading: getContractsForAccountChildLoading } = useGetContractsForChild({
    skip: !selectedChildId || !accountId,
    variables: {
      accountId,
      childId: selectedChildId,
    },
    onCompleted: (data) => {
      dispatch(getContractsForAccountChild(accountId, selectedChildId, data?.getContractsForChild ?? []));
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    /**
     * we may have an empty array on mount while we wait for the api.
     * if that is the case and we eventually receive data,
     * update the state to  set the first child as selected
     */

    if (
      childrenForSelect.length > 0 &&
      (!selectedChildId || !childrenForSelect.map((c) => c.id).includes(selectedChildId))
    ) {
      setSelectedChildId(childrenForSelect[0].id);
    }
  }, [selectedChildId, childrenForSelect]);

  const filterContracts = useCallback(
    (contracts: IContract[]): IContract[] => {
      const selectedFilterValues = Object.fromEntries(contractStatusFilters.map((filter) => [filter.value, true]));
      const filteredContracts = contracts.filter((contract) => selectedFilterValues[contract.status]);

      return orderBy(filteredContracts, (c) => c.startDate, 'asc');
    },
    [contractStatusFilters]
  );

  const childContracts = filterContracts(contractsByChildForAccount[selectedChildId] ?? []);

  return (
    <div className="kt-account-tab kt-account-enrollment-tab">
      <ChildSelect
        childrenList={childrenForSelect}
        selectedChildId={selectedChildId}
        selectedFilters={contractStatusFilters}
        onChildSelect={(child) => setSelectedChildId(child.id)}
        onContractStatusSelect={setContractStatusFilters}
      />
      {activeChild && (
        <div className="mb-4">
          <div className="d-flex flex-row align-item-center">
            <div className="d-flex flex-grow-1">
              <h5 className="font-weight-bold d-inline mr-4">{getFullName(activeChild)}</h5>
              <span>{getAgeStringFromDateOfBirth(momentTz(activeChild.dob).tz(timezone))}</span>
            </div>
            <HasRoleAreaLevel
              action={{ area: AreaType.Enrollment, permission: PermissionType.Contracts, level: RoleLevelType.Create }}
            >
              <div>
                <CreateButton variant="secondary" onClick={() => setCreateContractModalOpen(true)}>
                  {' '}
                  New Contract
                </CreateButton>
              </div>
            </HasRoleAreaLevel>
          </div>
          <div className="d-flex flex-row align-item-center mt-1">
            <DropdownFilter
              title="Status"
              selectedFilters={contractStatusFilters}
              options={ContractStatusOptions}
              onFilterSelect={setContractStatusFilters}
            />
            <Switch
              label="Show archived children"
              labelSide="left"
              value={showArchivedChildren}
              onChange={(value: boolean) => setShowArchivedChilden(value)}
              className="ml-auto"
            />
          </div>
        </div>
      )}
      <ContractCarousel
        contracts={childContracts}
        centerTimezone={timezone}
        selectContractToUpdate={setContractPendingUpdateOrEnd}
      />
      {contractPendingUpdateOrEnd && (
        <UpdateContractWithReasonModal
          isOpen={Boolean(contractPendingUpdateOrEnd)}
          onClose={() => setContractPendingUpdateOrEnd(null)}
          contract={contractPendingUpdateOrEnd}
          onContinue={(contract, updateMethod) => {
            if (updateMethod === 'UPDATE_EXISTING') {
              setContractToUpdate(contract);
            } else if (updateMethod === 'END_AND_CREATE_NEW') {
              setContractToEnd(contract);
              setCreateContractModalOpen(true);
            }
          }}
        />
      )}
      {accountId && centerId && selectedChildId && activeChild && isCreateContractModalOpen && (
        <CreateContractModal
          accountId={accountId}
          centerId={centerId}
          activeChildSubsidyEnrollments={activeChildSubsidyEnrollments}
          childId={selectedChildId}
          existingContracts={childContracts.map((c) => (c.id === contractToEnd?.id ? contractToEnd : c))}
          isOpen={isCreateContractModalOpen}
          previousContract={contractToEnd}
          onClose={() => {
            setCreateContractModalOpen(false);
            setContractToEnd(null);
          }}
        />
      )}
      {contractToUpdate && (
        <UpdateContractModal
          contract={contractToUpdate}
          activeChildSubsidyEnrollments={activeChildSubsidyEnrollments}
          isOpen={Boolean(contractToUpdate)}
          onClose={() => setContractToUpdate(null)}
          existingContracts={childContracts.filter((c) => c.id !== contractToUpdate.id)}
        />
      )}
    </div>
  );
};

export default EnrollmentTab;
