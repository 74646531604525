export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const CREATE_REVERSE_TRANSACTION = 'CREATE_REVERSE_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const APPLY_DISCOUNT_TO_TRANSACTION = 'APPLY_DISCOUNT_TO_TRANSACTION';
export const REMOVE_APPLIED_DISCOUNT_TO_TRANSACTION = 'REMOVE_APPLIED_DISCOUNT_TO_TRANSACTION';
export const GET_APPLIED_DISCOUNT_TRANSACTIONS = 'GET_APPLIED_DISCOUNT_TRANSACTIONS';

interface IGetTransactionsAction {
  type: typeof GET_TRANSACTIONS;
  transactions: ITransaction[];
  openingBalance: number | undefined;
  closingBalance: number | undefined;
}

interface ICreateTransactionAction {
  type: typeof CREATE_TRANSACTION;
  transaction: ITransaction;
}

interface IUpdateTransactionAction {
  type: typeof UPDATE_TRANSACTION;
  transaction: ITransaction;
}

interface ICreateReverseTransactionAction {
  type: typeof CREATE_REVERSE_TRANSACTION;
  transaction: ITransaction;
}

interface IUpdatePaymentAction {
  type: typeof UPDATE_PAYMENT;
  newTransaction: ITransaction;
  oldPaymentId: string;
}

interface IApplyDiscountToTransactionAction {
  type: typeof APPLY_DISCOUNT_TO_TRANSACTION;
  discountedTransactionId: string;
  discount: ITransaction;
}

interface IRemoveAppliedDiscountToTransactionAction {
  type: typeof REMOVE_APPLIED_DISCOUNT_TO_TRANSACTION;
  discountedTransactionId: string;
  discount: ITransaction;
}

interface IGetAppliedDiscountTransactionsAction {
  type: typeof GET_APPLIED_DISCOUNT_TRANSACTIONS;
  transactionId: string;
  appliedDiscounts: ITransaction[];
}

export type TransactionActionTypes =
  | IGetTransactionsAction
  | ICreateTransactionAction
  | ICreateReverseTransactionAction
  | IUpdateTransactionAction
  | IUpdatePaymentAction
  | IApplyDiscountToTransactionAction
  | IRemoveAppliedDiscountToTransactionAction
  | IGetAppliedDiscountTransactionsAction;
