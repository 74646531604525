import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import { ButtonProps } from 'react-bootstrap/Button';
import useUniqueId from 'shared/hooks/useUniqueId';

interface DropdownButtonProps {
  id?: string;
  toggleText: string;
  toggleVariant?: ButtonProps['variant'];
  dropdownItems: DropdownItemProps[];
  toggleClassName?: string;
  toggleDisabled?: boolean;
  className?: string;
}

export default ({
  id,
  toggleText,
  toggleVariant = 'primary',
  dropdownItems,
  className = '',
  toggleDisabled = false,
  toggleClassName = '',
}: DropdownButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const _id = useUniqueId(id);

  return (
    <Dropdown alignRight onToggle={(open) => setIsOpen(open)} id={_id} className={className}>
      <Dropdown.Toggle title={toggleText} variant={toggleVariant} className={toggleClassName} disabled={toggleDisabled}>
        {toggleText}
        <FontAwesomeIcon className="ml-2 " icon={isOpen ? faAngleUp : faAngleDown} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {dropdownItems.map((item) => (
          <Dropdown.Item {...item} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
