import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import { FileUploadDropbox } from 'shared/components/FileOperations';
import { Row, Col } from 'shared/components/Layout';
import FormLabel from 'shared/components/Form/FormLabel';
import { capitalize } from 'shared/util/string';
import UploadButton from '../UploadButton';

interface IProps {
  disabled?: boolean;
  completed?: boolean;
}
const CenterDetailsImport: React.FC<IProps> = ({ disabled = false, completed = false }) => {
  const { t } = useTranslation(['ktAdmin', 'translation']);
  return (
    <Card
      header={`${t('ktAdmin:data-importer.step-number', {
        number: 1,
        title: capitalize(t('translation:spelling.center')),
      })}`}
    >
      <p>{t('ktAdmin:data-importer.upload-instruction')}</p>
      <Row>
        <Col>
          <FormLabel required>{t('ktAdmin:data-importer.care-type')}</FormLabel>
          <FileUploadDropbox onFileAdd={() => {}} uploadedFiles={[]} setUploadedFiles={() => {}} />
        </Col>
        <Col>
          <FormLabel required>{t('ktAdmin:data-importer.classes')}</FormLabel>
          <FileUploadDropbox onFileAdd={() => {}} uploadedFiles={[]} setUploadedFiles={() => {}} />
        </Col>
        <Col>
          <FormLabel required>{t('ktAdmin:data-importer.fees')}</FormLabel>
          <FileUploadDropbox onFileAdd={() => {}} uploadedFiles={[]} setUploadedFiles={() => {}} />
        </Col>
      </Row>
      {/* 
        uncomment when implemented
      <UploadButton
        onClick={() => {}}
        selectedFiles={0}
        totalFiles={3}
        disabled
        completed={false}
      /> */}
      <h5 className="text-center mt-6">Upload Coming soon</h5>
    </Card>
  );
};

export default CenterDetailsImport;
