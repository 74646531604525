import React from 'react';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import useFormatDate from 'shared/hooks/useFormatDate';

interface IProps {
  classOptions: IClass[];
  classIds: string[] | null;
  setClassIds: (id: string[]) => void;
  date: string;
}

const TodayAttendanceHeader: React.FC<IProps> = ({ classOptions, classIds, setClassIds, date }) => {
  const formatDate = useFormatDate();
  const classFilterOptions = classOptions.map((c) => ({ ...c, value: c.id, label: c.name }));

  return (
    <div className="bg-white p-4 d-flex justify-content-between align-items-center border-bottom">
      <h6>Date: {formatDate(date)}</h6>
      <div className="d-flex align-items-center">
        <DropdownFilter
          className="mb-0 max-width-200 flex-wrap"
          options={classFilterOptions}
          title={'All Classes'}
          onFilterSelect={(options) => {
            setClassIds(options?.map((c) => c.value));
          }}
          selectedFilters={classFilterOptions?.filter((c) => classIds?.includes(c.id))}
        />
      </div>
    </div>
  );
};

export default TodayAttendanceHeader;
