import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CenterAvatar from 'shared/components/Avatar/CenterAvatar';
import Button from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import { Row } from 'shared/components/Layout';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  business: IEntity;
  loading: boolean;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const BusinessCentersCard: React.FC<IProps> = ({ business, loading }) => {
  const history = useHistory();
  const [index, setIndex] = useState(0);

  const centers = sortBy(business.centers, 'name');

  const groupsOfFourCenters: ICenter[][] = [];
  for (let x = 0; x < centers.length; x += 4) {
    groupsOfFourCenters.push(centers.slice(x, x + 4));
  }

  return (
    <Card
      bodyClassName="p-4"
      header={
        <div className="d-flex align-items-center">
          <div>{fieldLabels.center}s</div>
          <Button
            variant="outline-secondary"
            className="ml-auto btn-sm btn-short my-n4"
            onClick={() => {
              history.push('/centers/profiles', { businessId: business.id });
            }}
          >
            See All
            <FontAwesomeIcon className="ml-2" icon={faAngleRight} />
          </Button>
        </div>
      }
      loading={loading}
      loadingLines={3}
    >
      <Carousel
        className="k2-carousel hide-indicators"
        activeIndex={index}
        onSelect={setIndex}
        nextIcon={<FontAwesomeIcon icon={faAngleRight} size="2x" />}
        prevIcon={<FontAwesomeIcon icon={faAngleLeft} size="2x" />}
        interval={null}
      >
        {groupsOfFourCenters.map((g, i) => (
          <Carousel.Item key={i}>
            <Row noGutters align="start" justify="between" className="flex-nowrap m-2">
              {g.map((center) => (
                <div
                  style={{ width: '88px' }}
                  key={center.id}
                  className="mr-2 d-flex flex-column raised-hover-animated"
                  onClick={() => history.push(`/centers/profiles/${center.id}`)}
                >
                  <CenterAvatar center={center} size="lg" className="mx-auto" />
                  <div className="text-center">{center.name}</div>
                </div>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </Card>
  );
};

export default BusinessCentersCard;
