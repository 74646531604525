import React from 'react';
import Card from 'react-bootstrap/Card';
import ActionDropdown from 'shared/components/Card/CardActionDropdown';
import { Circle, LoadingLines } from 'shared/components/LoadingSkeletons';

interface IProps {
  className?: string;
}

const CardLoadingSkeleton: React.FC<IProps> = ({ className }) => {
  return (
    <Card className={`w-260px text-center d-flex flex-column align-items-center m-0 p-4 ${className}`}>
      <div className="d-flex w-100 mb-2">
        <div className="ml-auto">
          <ActionDropdown actions={[]} />
        </div>
      </div>
      <Circle size="xl" className="mb-4" />
      <div className="w-75">
        <LoadingLines number={2} />
      </div>
    </Card>
  );
};

export default CardLoadingSkeleton;
