import React from 'react';
import useUniqueId from 'shared/hooks/useUniqueId';

export interface IProps {
  id?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  label?: string;
  className?: string;
  disabled?: boolean;
}

const Checkbox: React.FC<IProps> = ({
  id: propId,
  value = false,
  onChange,
  label,
  className,
  disabled = false,
  ...props
}) => {
  const id = useUniqueId(propId);
  return (
    <div className={`custom-control custom-checkbox ${className}`}>
      <input
        {...props}
        id={id}
        checked={value}
        type="checkbox"
        disabled={disabled}
        className="custom-control-input"
        onChange={(e) => onChange && onChange(e.target.checked)}
        data-checked={value}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
