import { Certificate } from 'crypto';
import { find, get, map, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import {
  ccsCertificateStatusEnum,
  ccsDeterminationCategoryEnum,
  ccsEnrollmentStatus,
} from 'shared/constants/enums/ccssEnums';
import { capitalize, getInitials, stringToHsl } from 'shared/util/string';
import CcsCertificateCard from './CcsCertificateCard';
import { ICcsCertificateDetails } from './CertificatesTable';
import { IAccsRowEntry, IAccsTableData } from './types';

export function createAccsTableData(
  account: IAccount,
  certificates: ICcssCertificate[],
  determinations: ICcssDetermination[]
) {
  function isValid(value: Partial<IAccsTableData> | IAccsTableData): value is IAccsTableData {
    let valid = true;
    if (value.child === undefined) valid = false;
    return valid;
  }

  const certificateToEntry: (certificate: ICcssCertificate) => IAccsRowEntry = (certificate) => {
    return {
      type: 'Certificate',
      accsId: certificate.certificateId,
      startDate: certificate.startDate,
      endDate: certificate.endDate,
      status: certificate.status,
    };
  };

  const determinationToEntry: (determination: ICcssDetermination) => IAccsRowEntry = (determination) => {
    return {
      type: 'Determination',
      accsId: determination.determinationId,
      startDate: determination.startDate,
      endDate: determination.endDate,
      status: determination.status,
    };
  };

  const rows: IAccsTableData[] = [];

  rows.push(
    ...certificates
      .map((certificate) => {
        const value: IAccsTableData | Partial<IAccsTableData> = {
          id: certificate.id,
          child: account.children.find((c) => c.id === certificate.childId),
          base: certificate,
          baseType: 'CcssCertificate',
          entry: certificateToEntry(certificate),
          previousEntries: certificate.previousCertificates.map(certificateToEntry),
          account: account,
        };

        return value;
      })
      .filter(isValid)
  );

  rows.push(
    ...determinations
      .map((determination) => {
        const value: IAccsTableData | Partial<IAccsTableData> = {
          id: determination.id,
          child: account.children.find((c) => c.id === determination.childId),
          base: determination,
          baseType: 'CcssDetermination',
          entry: determinationToEntry(determination),
          previousEntries: [],
          account: account,
        };

        return value;
      })
      .filter(isValid)
  );

  return rows;
}

interface ITableProps {
  data: IAccsTableData[];
  loading: boolean;
  expandRow?: ((row: any) => JSX.Element) | false;
  onEditClick?: (certificate: ICcssCertificate) => void; // only editing for certificates
  onCreateClick?: (certificate: ICcssCertificate) => void; // only create determinations from certificates
  onCancelClick?: (certificate: ICcssCertificate) => void;
  onNotAtRiskClick?: (certificate: IAccsTableData) => void;
}

const dateFormat = 'DD/MM/YYYY';

const AccsTable: React.FC<ITableProps> = ({
  data,
  loading,
  expandRow = expandRowDefault,
  onEditClick = (certificate) => {},
  onCreateClick = (certificate) => {},
  onCancelClick = (certificate) => {},
  onNotAtRiskClick = (certificate) => {},
}) => {
  const { t } = useTranslation();

  const getRecordStatusDisplay = (certificate: ICcssCertificate) => {
    if (!certificate) {
      return '';
    }

    switch (certificate?.recordStatus) {
      case 'P':
        return 'Provisional';
      default:
        return find(ccsCertificateStatusEnum, (status) => status.value === certificate.status)?.label ?? 'N/A';
    }
  };

  return (
    <DataTable
      data={data}
      expandRow={
        expandRow ? (row) => expandRow(row, onEditClick, onCreateClick, onCancelClick, onNotAtRiskClick) : undefined
      }
      showLoadingOverlay={loading}
      headerClasses={'d-none'}
      noPadding={true}
      showPagination={false}
      showSelect={false}
      columns={[
        {
          text: '',
          dataField: 'avatar',
          sort: true,
          formatter: (cell: string, row: IAccsTableData) => (
            <AvatarDataTableCell
              color={stringToHsl(row.child.id)}
              initials={getInitials(row.child)}
              avatar={row.child.avatar?.url ?? ''}
              primaryText={row.child.fullName}
            />
          ),
        },
        {
          text: '',
          dataField: 'type',
          formatter: (cell: string, row: IAccsTableData) => row.entry.type,
        },
        {
          text: '',
          dataField: 'accsId',
          formatter: (cell: string, row: IAccsTableData) => `${row.entry.accsId}`,
        },
        {
          text: '',
          dataField: 'endDate',
          formatter: (cell: string, row: IAccsTableData) => {
            return `${moment(row.entry.startDate).format(dateFormat)}
            -
            ${moment(row.entry.endDate).format(dateFormat)}`;
          },
        },
        {
          text: '',
          dataField: 'status',
          formatter: (cell: string, row: IAccsTableData) => {
            let result: string = capitalize(t('spelling.pending'));
            if (row.baseType === 'CcssCertificate') {
              result = getRecordStatusDisplay(row.base as ICcssCertificate);
            } else if (row.baseType === 'CcssDetermination') {
              result = `${
                find(ccsDeterminationCategoryEnum, (category) => category.value === row.entry.status)?.label ??
                capitalize(t('spelling.pending'))
              }`;
            }
            return result;
          },
        },
      ]}
    />
  );
};

export default AccsTable;

export function sortDefaultAccsTableData(data: IAccsTableData[]) {
  if (data[0] && !data[0].child.fullName) return data; // Don't sort if fullname is not populated/exists (due to bug).
  return data.sort((a, b) => {
    // By name
    if (a.child.fullName.localeCompare(b.child.fullName)) return a.child.fullName.localeCompare(b.child.fullName);
    // Then by type
    const typeSortOrder: Record<'CcssCertificate' | 'CcssDetermination', number> = {
      CcssCertificate: 1,
      CcssDetermination: -1,
    };
    if (get(typeSortOrder, a.baseType, 0) > get(typeSortOrder, b.baseType, 0)) return -1;
    if (get(typeSortOrder, a.baseType, 0) < get(typeSortOrder, b.baseType, 0)) return 1;
    // else equal
    return 0;
  });
}

const expandRowDefault = (
  row: IAccsTableData,
  onEditClick: (certificate: ICcssCertificate) => void,
  onCreateClick: (certificate: ICcssCertificate) => void,
  onCancelClick: (certificate: ICcssCertificate) => void,
  onNotAtRiskClick: (certificate: IAccsTableData) => void
) => {
  // map determinations and certs to certificates so I don't need to rework the card too much.
  const mappedToCertificate = AccsTableDataToCcssCertificate(row);
  let PreviousDeterminations: ((props: { previousCertificates: ICcsCertificateDetails[] }) => JSX.Element) | undefined;
  if (row.baseType === 'CcssDetermination') {
    const dert = row.base as ICcssDetermination;
    const prev = dert.previousDeterminations;
    PreviousDeterminations = CreateRenderPreviousDeterminations(row.account, prev ?? []);
  }

  const isDert = row.baseType === 'CcssDetermination';
  const isIneffective = row.entry.status === 'INEFF';
  const isCanceled = row.entry.status === 'CANCEL';
  const isRejected = row.entry.status === 'REJECT';

  return (
    <div>
      <CcsCertificateCard
        certificate={mappedToCertificate}
        child={row.child}
        previousCertificates={
          CCssCertificateAndChildToCcsCertificateDetails(mappedToCertificate, row.child).previousCertificates
        }
        onEditClick={() => onEditClick(mappedToCertificate)}
        onCreateClick={() => (row.baseType === 'CcssCertificate' ? onCreateClick(row.base as ICcssCertificate) : null)}
        onCancelClick={() => onCancelClick(mappedToCertificate)}
        onNotAtRiskClick={() => onNotAtRiskClick(row)}
        hideEdit={isDert}
        hideCancel={isIneffective || isCanceled || isRejected || isDert}
        hideCreate={isDert}
        hideNotAtRisk={isIneffective || isCanceled || (isRejected && !isDert)}
        renderPrevious={PreviousDeterminations}
      />
    </div>
  );
};

const CreateRenderPreviousDeterminations = (account: IAccount, prev: ICcssDetermination[]) => {
  const PreviousDeterminations = (props: { previousCertificates: ICcsCertificateDetails[] }) => {
    const tableData = createAccsTableData(account, [], prev);

    return (
      <Accordion
        className={'d-flex flex-column justify-content-center pb-4'}
        defaultActiveKey="-1"
        style={{ width: '100%' }}
      >
        <Accordion.Toggle eventKey="0" className="kt-account-ccs-tab-accordion-toggle">
          <div className="float-center">
            <span>View Previous Determinations ({tableData.length})</span>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse className="" eventKey="0">
          <AccsTable data={tableData} loading={false} expandRow={false}></AccsTable>
        </Accordion.Collapse>
      </Accordion>
    );
  };

  return PreviousDeterminations;
};

function AccsTableDataToCcssCertificate(data: IAccsTableData) {
  // Instead of using type guards I'm just telling it what type it is.
  const cert = data.baseType === 'CcssCertificate' ? (data.base as ICcssCertificate) : undefined;
  const dert = data.baseType === 'CcssDetermination' ? (data.base as ICcssDetermination) : undefined;

  // map determinations and certs to certificates so I don't need to rework the card too much.
  if (cert) return cert;
  if (dert) return CcssDeterminationToCertificate(dert);
  throw new Error('Could not match to determination or certificate.');
}

function CcssDeterminationToCertificate(dert: ICcssDetermination) {
  const cert: ICcssCertificate = {
    id: dert.id,
    certificateId: dert.determinationId,
    childId: dert.childId ?? 'Unknown',
    evidenceHeld: false, // Field does not exist on derts
    startDate: dert.startDate,
    endDate: dert.endDate,
    status: 'APPROV', // Field should be dert.status, but doesn't matter as it's not used anyway.
    weeksAtRisk: dert.weeksAtRisk,
    stateTerritoryBody: dert.stateTerritoryBody,
    previousCertificates: dert.previousDeterminations?.map(CcssDeterminationToCertificate) ?? [],
    riskReasons: dert.riskReasons,
  };
  return cert;
}

function CCssCertificateAndChildToCcsCertificateDetails(certificate: ICcssCertificate, child: IAccountChild) {
  const previousCertificates = map(certificate.previousCertificates, (c) => {
    const enrolment: ICcsCertificateDetails = {
      id: c.certificateId,
      child: child,
      certificate: c,
      previousCertificates: [],
    };

    return enrolment;
  });
  const result: ICcsCertificateDetails = {
    id: `${certificate.certificateId}`,
    child,
    certificate: certificate,
    previousCertificates: previousCertificates,
  };
  return result;
}
