import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'shared/components/Checkbox';
import DateInput from 'shared/components/DateInput';
import MonthPicker2 from 'shared/components/DateInput/MonthPicker2';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Select from 'shared/components/Select';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { capitalize } from 'shared/util/string';
import TagsDropdown from './components/TagsDropdown';

const accountStatusOptions: { label: string; value: AccountStatusType }[] = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Future', label: 'Future' },
];

interface IFormStateShape {
  centerIds: string[];
  date: string | null;
  includeActiveAccounts: boolean;
  includeInactiveAccounts: boolean;
  statusList: AccountStatusType[];
  selectedAccountTag: ITag[];
  selectedChildTag: ITag[];
  selectedContactTag: ITag[];
  includePrimaryAccountContacts: boolean;
  includeNonPrimaryAccountContacts: boolean;
  format: 'EXCEL' | 'PDF';
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  hasAccountStatusOption?: boolean;
  hasMultipleAccountStatusOption?: boolean;
  hasAccountContactTypeOption?: boolean;
  showReportFormat?: boolean;
  hasDateInput?: boolean;
  showMonthOnly?: boolean;
  showChildTags?: boolean;
  showAccountTags?: boolean;
  showContactTags?: boolean;
  onSubmit: (
    date: string | null,
    centerIds: string[],
    format: 'EXCEL' | 'PDF',
    statusList: AccountStatusType[],
    selectedAccountTag: string | null,
    selectedChildTag: string | null,
    selectedContactTag: string | null,
    isPrimary?: boolean,
    statusType?: AccountStatusType
  ) => void;
  onClose: () => void;
}

const SingleDateMultipleCenterTagFilterReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  hasAccountStatusOption = false,
  hasAccountContactTypeOption = false,
  hasMultipleAccountStatusOption = false,
  showReportFormat = false,
  hasDateInput = true,
  showMonthOnly = false,
  showChildTags = false,
  showAccountTags = false,
  showContactTags = false,
  ...props
}) => {
  const [formData, setFormData] = useState<IFormStateShape>({
    centerIds: [],
    date: null,
    includeActiveAccounts: true,
    includeInactiveAccounts: false,
    format: 'EXCEL',
    statusList: ['Active', 'Inactive', 'Future'],
    selectedAccountTag: [],
    selectedChildTag: [],
    selectedContactTag: [],
    includeNonPrimaryAccountContacts: false,
    includePrimaryAccountContacts: true,
  });

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        centerIds: [],
        date: null,
        includeActiveAccounts: true,
        includeInactiveAccounts: false,
        format: 'EXCEL',
        statusList: ['Active', 'Inactive', 'Future'],
        selectedAccountTag: [],
        selectedChildTag: [],
        selectedContactTag: [],
        includeNonPrimaryAccountContacts: false,
        includePrimaryAccountContacts: true,
      });
    }
  }, [isOpen]);

  const isValidAccountStatusOptions = useMemo(
    () => (hasAccountStatusOption ? formData.includeActiveAccounts || formData.includeInactiveAccounts : true),
    [hasAccountStatusOption, formData]
  );

  const isValidStatusListOptions = useMemo(
    () => (hasMultipleAccountStatusOption ? formData.statusList?.length > 0 : true),
    [hasMultipleAccountStatusOption, formData]
  );

  const isValidContactTypeOptions = useMemo(
    () =>
      hasAccountContactTypeOption
        ? formData.includePrimaryAccountContacts || formData.includeNonPrimaryAccountContacts
        : true,
    [hasAccountContactTypeOption, formData]
  );

  const isValidDateOption = useMemo(() => {
    if (hasDateInput && formData.date == null) {
      return false;
    }
    return true;
  }, [hasDateInput, formData]);

  const isValidCenterOption = useMemo(() => {
    if (formData.centerIds.length >= 1) {
      return true;
    }
    return false;
  }, [formData]);

  const isDisabled = useMemo(() => {
    if (isValidDateOption && isValidCenterOption) {
      return true;
    }
    return false;
  }, [isValidDateOption, isValidCenterOption]);

  const accountStatusType = useMemo(() => {
    if (formData.includeActiveAccounts && formData.includeInactiveAccounts) return undefined;
    if (formData.includeActiveAccounts) return 'Active';
    if (formData.includeInactiveAccounts) return 'Inactive';
    return undefined;
  }, [hasAccountStatusOption, formData]);

  const accountContactType = useMemo(() => {
    if (
      !hasAccountContactTypeOption ||
      (formData.includePrimaryAccountContacts && formData.includeNonPrimaryAccountContacts)
    ) {
      return undefined;
    }

    if (formData.includePrimaryAccountContacts) {
      return true;
    }

    if (formData.includeNonPrimaryAccountContacts) {
      return false;
    }

    return undefined;
  }, [hasAccountContactTypeOption, formData]);

  const getSelectedStatuses = useMemo(() => {
    return accountStatusOptions.filter((status) => formData.statusList?.includes(status.value));
  }, [formData]);

  const handleStatusChange = (statuses: { label: string; value: AccountStatusType }[]) =>
    setFormData({ ...formData, statusList: statuses?.map((item) => item.value) });

  const { t } = useTranslation();

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (isValidDateOption && isValidAccountStatusOptions && isValidStatusListOptions && isValidContactTypeOptions) {
      onSubmit(
        formData.date,
        formData.centerIds,
        formData.format,
        formData.statusList,
        formData.selectedAccountTag.length > 0 ? formData.selectedAccountTag[0].id : null,
        formData.selectedChildTag.length > 0 ? formData.selectedChildTag[0].id : null,
        formData.selectedContactTag.length > 0 ? formData.selectedContactTag[0].id : null,
        accountContactType,
        accountStatusType
      );
    }
  }, [
    isValidDateOption,
    isValidAccountStatusOptions,
    isValidStatusListOptions,
    isValidContactTypeOptions,
    onSubmit,
    formData.date,
    formData.centerIds,
    formData.format,
    formData.statusList,
    formData.selectedAccountTag,
    formData.selectedChildTag,
    formData.selectedAccountTag,
    accountContactType,
    accountStatusType,
  ]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({
      centerIds: [],
      date: null,
      includeActiveAccounts: true,
      includeInactiveAccounts: false,
      statusList: ['Active', 'Inactive', 'Future'],
      selectedAccountTag: [],
      selectedChildTag: [],
      selectedContactTag: [],
      includePrimaryAccountContacts: true,
      includeNonPrimaryAccountContacts: false,
      format: 'EXCEL',
    });
    onClose();
  }, [onClose]);

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{ disabled: !isDisabled, loading: isLoading }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            isRequired
            selectedCenterIds={formData.centerIds}
            onSelect={(ids) => setFormData((prev) => ({ ...prev, centerIds: ids as string[] }))}
          />
        </Col>
      </Row>
      {hasDateInput && !showMonthOnly && (
        <Row>
          <Col>
            <DateInput
              label="Date"
              required
              date={formData.date}
              onDateSelect={(date) => setFormData((prev) => ({ ...prev, date }))}
              className="kt-date-input-no-max-width"
            />
          </Col>
        </Row>
      )}
      {hasDateInput && showMonthOnly && (
        <Row>
          <Col>
            <MonthPicker2
              label="Month"
              required
              date={formData.date}
              onMonthClick={(date: string) => setFormData((prev) => ({ ...prev, date }))}
              className="kt-date-input-no-max-width"
            />
          </Col>
        </Row>
      )}
      {showChildTags && (
        <Row>
          <Col className="mt-4">
            <TagsDropdown
              categories={['CHILD']}
              selectedTags={formData.selectedChildTag ?? []}
              setSelectedTags={(tags: ITag[]): void => {
                setFormData((prev) => ({ ...prev, selectedChildTag: tags }));
              }}
            />
          </Col>
        </Row>
      )}
      {showReportFormat && (
        <Row className="mt-4 mb-4">
          <Col>
            <Select
              options={[
                { value: 'EXCEL', label: 'EXCEL' },
                { value: 'PDF', label: 'PDF' },
              ]}
              label={`${capitalize(t('spelling.format'))}(s)`}
              value={formData.format ?? { value: 'EXCEL', label: 'EXCEL' }}
              onChange={(option) => setFormData((prev) => ({ ...prev, format: option.value }))}
              className="mb-0"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Col>
        </Row>
      )}
      {hasAccountStatusOption && (
        <>
          <Row className="pl-2">{t('spelling.accountStatusFilter')}</Row>
          <Row className="pl-2">
            <Checkbox
              label={capitalize(t('spelling.active'))}
              value={formData.includeActiveAccounts}
              onChange={(value) => setFormData({ ...formData, includeActiveAccounts: value })}
            />
          </Row>
          <Row className="pl-2">
            <Checkbox
              label={capitalize(t('spelling.inactive'))}
              value={formData.includeInactiveAccounts}
              onChange={(value) => setFormData({ ...formData, includeInactiveAccounts: value })}
            />
          </Row>
        </>
      )}
      {hasMultipleAccountStatusOption && (
        <Select
          options={accountStatusOptions}
          isMulti
          required
          label={t('spelling.accountStatusFilter')}
          value={getSelectedStatuses}
          onChange={(data) => handleStatusChange(data)}
        />
      )}
      {hasAccountContactTypeOption && (
        <>
          <Row className="pl-2 row-label">{t('spelling.accountContactTypeFilter')}</Row>
          <Row className="pl-2">
            <Checkbox
              label={capitalize(t('spelling.primary'))}
              value={formData.includePrimaryAccountContacts}
              onChange={(value) => setFormData({ ...formData, includePrimaryAccountContacts: value })}
            />
          </Row>
          <Row className="pl-2">
            <Checkbox
              label={capitalize(t('spelling.nonPrimary'))}
              value={formData.includeNonPrimaryAccountContacts}
              onChange={(value) => setFormData({ ...formData, includeNonPrimaryAccountContacts: value })}
            />
          </Row>
        </>
      )}
      {showAccountTags && (
        <Row>
          <Col className="mt-4">
            <TagsDropdown
              categories={['ACCOUNT']}
              selectedTags={formData.selectedAccountTag ?? []}
              setSelectedTags={(tags: ITag[]): void => {
                setFormData((prev) => ({ ...prev, selectedAccountTag: tags }));
              }}
            />
          </Col>
        </Row>
      )}
      {showContactTags && (
        <Row>
          <Col className="mt-4">
            <TagsDropdown
              categories={['CONTACT']}
              selectedTags={formData.selectedContactTag ?? []}
              setSelectedTags={(tags: ITag[]): void => {
                setFormData((prev) => ({ ...prev, selectedContactTag: tags }));
              }}
            />
          </Col>
        </Row>
      )}
    </SideModalDrawer>
  );
};

export default SingleDateMultipleCenterTagFilterReportModal;
