import React, { useState } from 'react';
import moment from 'moment';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';
import DataTable from 'shared/components/DataTable';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import CCSEventsTableHeader from './CCSEventsTableHeader';
import CCSSEventRow from './CCSSEventRow';
import { useTranslation } from 'react-i18next';

interface ITableProps {
  data: ICcssEvent[];
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  activeFilters: IEventFilter;
  onActiveFilterChange: (value: IEventFilter) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  dateRange: ITimeRange;
  setDateRange: (tr: ITimeRange) => void;
  selectedRows: any;
  updateSelectedRows: any;
  type?: 'LE' | 'MR' | null | undefined;
}

const CCSSEventsTable: React.FC<ITableProps> = ({
  data,
  loading,
  dataSize,
  pageSize,
  activePage,
  onPageChange,
  onSizePerPageChange,
  onActiveFilterChange,
  activeFilters,
  onSort,
  dateRange,
  setDateRange,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const handleTypeChange = (option: any) => {
    onActiveFilterChange({ type: option.value });
  };

  const { t } = useTranslation(['translation', 'subsidies']);

  const clearFilters = () => {
    setDateRange({ start: '', end: '' });
    onActiveFilterChange({ type: null });
  };

  const markAsRead = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    const expanded = isExpand ? [row.id] : [];
    setExpandedRows(expanded);
  };

  const eventTypeText = (row: ICcssEvent) => {
    switch (row.type) {
      case 'LE':
        return t('subsidies:event-management.event-type-le');
      case 'MR':
        return t('subsidies:event-management.event-type-mr');
      default:
        return '';
    }
  };

  const eventSubtypeText = (row: ICcssEvent) => {
    switch (row.subType) {
      case 'LE01':
        return t('subsidies:event-management.event-subtype-le01');
      case 'LE02':
        return t('subsidies:event-management.event-subtype-le02');
      case 'LE03':
        return t('subsidies:event-management.event-subtype-le03');
      case 'LE04':
        return t('subsidies:event-management.event-subtype-le04');
      case 'LE05':
        return t('subsidies:event-management.event-subtype-le05');
      case 'LE06':
        return t('subsidies:event-management.event-subtype-le06');
      case 'LE07':
        return t('subsidies:event-management.event-subtype-le07');
      case 'MR01':
        return t('subsidies:event-management.event-subtype-mr01');
      case 'MR02':
        return t('subsidies:event-management.event-subtype-mr02');
      default:
        return '';
    }
  };

  const recipientTypeText = (row: ICcssEvent) => {
    switch (row.recipientType) {
      case 'SERV':
        return t('subsidies:event-management.event-recipient-type-serv');
      case 'CHILD':
        return t('subsidies:event-management.event-recipient-type-child');
      default:
        return '';
    }
  };

  return (
    <>
      <SpinnerTableOverlay show={loading}>
        <DataTable
          keyField="id"
          noPadding={true}
          data={data}
          dataSize={dataSize}
          handleRowClick={() => {}}
          hasOverlay={false}
          pageSize={pageSize}
          activePage={activePage}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          showPagination={true}
          showSelect={false}
          className="ccs-table"
          expandHeaderColumnRenderer={(props) => <></>}
          onExpand={markAsRead}
          expanded={expandedRows}
          expandRow={(row) => <CCSSEventRow data={row} />}
          onSort={onSort}
          defaultSortField="startDate"
          defaultSorting="desc"
          onlyOneExpanding={true}
          renderHeader={(paginationProps: any, searchProps: any) => (
            <CCSEventsTableHeader
              dateRange={dateRange}
              activeFilters={activeFilters}
              onActiveFilterChange={onActiveFilterChange}
              setDateRange={setDateRange}
              handleTypeChange={handleTypeChange}
              clearFilters={clearFilters}
            />
          )}
          columns={[
            {
              text: t('subsidies:event-management.event-id'),
              dataField: 'eventId',
              sort: true,
            },
            {
              text: t('subsidies:event-management.center-name'),
              dataField: 'centerName',
              sort: true,
            },
            {
              text: t('subsidies:event-management.recipient-type'),
              dataField: 'recipientType',
              sort: true,
              formatter: (cell: any, row: ICcssEvent) => recipientTypeText(row),
            },
            {
              text: t('subsidies:event-management.event-type'),
              dataField: 'type',
              sort: true,
              formatter: (cell: any, row: ICcssEvent) =>
                `${eventTypeText(row)}${!!row.subType ? ' - ' + eventSubtypeText(row) : ''}`,
            },
            {
              text: t('subsidies:event-management.start-date'),
              dataField: 'startDate',
              sort: true,
              formatter: (cell: any, row: ICcssEvent) =>
                moment(row.startDate).format(t('translation:formatters.MM/DD/YYYY')),
            },
            {
              text: t('subsidies:event-management.end-date'),
              dataField: 'endDate',
              sort: true,
              formatter: (cell: any, row: ICcssEvent) =>
                !!row.endDate ? moment(row.endDate).format(t('translation:formatters.MM/DD/YYYY')) : '',
            },
          ]}
        />
      </SpinnerTableOverlay>
    </>
  );
};

export default CCSSEventsTable;
