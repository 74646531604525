import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

export const GET_ALLOWED_ENTITIES = gql`
  query {
    getAllowedEntities {
      id
      name
    }
  }
`;

interface IGetAllowedEntitiesData {
  getAllowedEntities: IEntity[];
}

interface IGetAllowedEntitiesVariables {}

const useGetAllowedEntities = (skip = false) =>
  useQuery<IGetAllowedEntitiesData, IGetAllowedEntitiesVariables>(GET_ALLOWED_ENTITIES, {
    skip,
  });

export default useGetAllowedEntities;
