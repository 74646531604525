import classNames from 'classnames';
import React from 'react';
import { Collapse, Form, Card } from 'react-bootstrap';
import PaymentMethod from '../PaymentMethods/PaymentMethod';
import './paymentMethodSelect.scss';

interface IProps {
  paymentMethod: IPaymentMethod;
  isSelected: boolean;
  collapseText: string;
  onChange: () => void;
  disabled?: boolean;
}

const PaymentMethodSelect: React.FC<IProps> = ({
  paymentMethod,
  isSelected,
  disabled,
  collapseText = '',
  onChange,
}) => {
  const handleClick = () => {
    if (!disabled) {
      onChange();
    }
  };
  return (
    <Card
      className={classNames('payment-method-select px-4 py-4', { disabled })}
      border={isSelected ? 'secondary' : ''}
      onClick={handleClick}
    >
      <div className="d-flex">
        <Form.Check>
          <Form.Check.Input
            name="payment-method"
            type="radio"
            checked={isSelected}
            disabled={disabled}
            onChange={handleClick}
          />
          <Form.Check.Label>
            <PaymentMethod paymentMethod={paymentMethod} showCardHolder className="payment-summary-card" />
          </Form.Check.Label>
        </Form.Check>
      </div>
      {collapseText !== '' && (
        <Collapse in={isSelected} className="mx-5 mt-2">
          <div>
            <small>{collapseText}</small>
          </div>
        </Collapse>
      )}
    </Card>
  );
};

export default PaymentMethodSelect;
