import React from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const KtRangeSlider = styled(Slider)({
  color: 'rgba(248, 193, 213, 1)',
  height: 6,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    color: 'rgba(0, 0, 0, 0.11)',
    height: 6,
  },
  '& .MuiSlider-thumb': {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    border: '2px solid rgba(0, 0, 0, 0.11)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    color: 'rgba(84, 97, 114, 1)',
    fontSize: 16,
    background: 'unset',
    padding: '0 5px',
    height: 40,
    borderRadius: '4px',
    backgroundColor: 'rgba(248, 193, 213, 1)',
    top: '65px',
    '&:before': {
      top: '-8px',
    },
  },
});

export default KtRangeSlider;
