import React, { useState, Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import { useGetBillingCycleTemplates } from 'gql/billingCycle/queries';
import cast from 'shared/util/cast';
import ViewBillingCycleTemplateModal from './ViewBillingCycleTemplateModal';
import DeleteBillingCycleTemplateModal from './DeleteBillingCycleTemplateModal';
import EditBillingCycleTemplateModal from './EditBillingCycleTemplateModal';
import CreateBillingCycleCard from './CreateBillingCycleCard';
import BillingCycleTemplatesTable from './BillingCycleTemplatesTable';
import PreviewBillingCycleModal from './PreviewBillingCycleModal';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { isRegion, getRegion } from 'shared/util/region';
import PreviewPeriodBasedBillingCycleModal from './PreviewPeriodBasedBillingCycleModal';
import EditPeriodBasedBillingCycleTemplateModal from './EditPeriodBasedBillingCycleTemplateModal';
import ViewPeriodBasedBillingCycleTemplateModal from './ViewPeriodBasedBillingCycleTemplateModal';
import { useCallback } from 'react';
import { getNumberWithOrdinal, snakeCaseToProperCase, toProperCase } from 'shared/util/string';
import { convertTimePeriodToString } from './utils';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';

const defaultDropDownTimePeriod: ITimePeriod = {
  value: 1,
  unit: 'DAYS',
};

interface IEditTemplateModalState {
  open: boolean;
  template: IBillingCycleTemplate | null;
}

interface IProps {}

const BillingCyclesTab: React.FC<IProps> = () => {
  const { t } = useTranslation();
  // Load and fetch billing cycle templates
  const { loading: getBillingCycleTemplatesLoading } = useGetBillingCycleTemplates();
  const billingCycleTemplates = useSelector((state: RootState) =>
    Object.values(state.billing.settings.templates.byId)
  ).filter((t) => !t.deactivatedAt);
  // end load and fetch billing cycle templates
  const user = useSelector((state: RootState) => state.user);
  const [newBillingCycleInPreview, setNewBillingCycleInPreview] = useState<ICreateBillingCycleTemplateInput | null>(
    null
  );
  const [newBillingCycleFormData, setNewBillingCycleFormData] = useState<INewBillingCycleTemplateFormData>({
    ...(isRegion('US')
      ? {
          period: 'ADVANCE' as BillingPeriod,
          frequency: 'WEEKLY' as BillingFrequency,
          dayOfWeek: 'MONDAY' as DayOfWeek,
          invoiceSchedule: { ...defaultDropDownTimePeriod },
          cycleType: 'PERIOD_BASED' as BillingCycleType,
        }
      : {
          cycleType: 'BALANCE_BASED' as BillingCycleType,
        }),
  });
  const [editTemplateModalState, setEditTemplateModalState] = useState<IEditTemplateModalState>({
    open: false,
    template: null,
  });
  const [templateInDeleteModal, setTemplateInDeleteModal] = useState<IBillingCycleTemplate | null>(null);
  const [templateInViewModal, setTemplateInViewModal] = useState<IBillingCycleTemplate | null>(null);

  const formatDayOf = useCallback((template: IBillingCycleTemplate): string => {
    if (template.frequency === 'CUSTOM') {
      return 'Custom';
    }
    if (template.dayOfMonth !== null) {
      if (template.dayOfMonth === -1) {
        return 'Last of the Month';
      }

      return `${getNumberWithOrdinal(template.dayOfMonth)} of the Month`;
    } else {
      return toProperCase(template.dayOfWeek?.toString() ?? '');
    }
  }, []);

  const editCycleTemplate = (template: IBillingCycleTemplate) => {
    setEditTemplateModalState((prev) => ({ ...prev, open: true, template }));
  };

  const cycleTableColumns = useMemo(() => {
    return {
      US: [
        {
          text: 'Name',
          dataField: 'name',
          sort: true,
        },
        {
          dataField: 'period',
          text: 'Billing Period',
          formatter: (period: BillingPeriod) =>
            period === 'ADVANCE' ? 'Advance' : period === 'ARREARS' ? 'Arrears' : 'Current',
        },
        {
          dataField: 'frequency',
          text: 'Frequency',
          formatter: (frequency: BillingFrequency) =>
            frequency === 'BIWEEKLY' ? snakeCaseToProperCase(t('spelling.biweekly')) : snakeCaseToProperCase(frequency),
          sort: true,
        },
        {
          text: 'Payment Day',
          dataField: 'chargeDay',
          formatter: (cell: DayOfWeek | number, row: IBillingCycleTemplate) => formatDayOf(row),
        },
        {
          text: 'Billed before payment',
          dataField: 'invoiceSchedule',
          formatter: (cell: ITimePeriod) =>
            `${cell.value && cell.unit ? snakeCaseToProperCase(convertTimePeriodToString(cell)) : ''}`,
        },
        user?.isInternal && {
          text: 'Business',
          dataField: 'business',
          formatter: (business: IBusiness) => business.name,
          sort: true,
        },
        {
          text: `${t('spelling.capitalCenter')}(s)`,
          dataField: 'center',
          formatter: (center: { name: string } | null) => center?.name ?? 'All',
          sort: true,
        },
      ],
      AU: [
        {
          text: 'Name',
          dataField: 'name',
          sort: true,
        },
        {
          dataField: 'frequency',
          text: 'Frequency',
          formatter: (frequency: BillingFrequency) =>
            frequency === 'BIWEEKLY' ? snakeCaseToProperCase(t('spelling.biweekly')) : snakeCaseToProperCase(frequency),
          sort: true,
        },
        {
          text: 'Charge Day',
          dataField: 'chargeDay',
          formatter: (cell: DayOfWeek | number, row: IBillingCycleTemplate) => formatDayOf(row),
        },
        {
          text: 'Invoice Period',
          dataField: 'invoiceSchedule',
          formatter: (cell: ITimePeriod) =>
            `${cell.value && cell.unit ? snakeCaseToProperCase(convertTimePeriodToString(cell)) : ''}`,
          sort: true,
        },
        {
          text: 'Billing Period',
          dataField: 'period',
          formatter: (cell: BillingPeriod) => snakeCaseToProperCase(cell),
          sort: true,
        },
        {
          text: 'Advanced Billing',
          dataField: 'additionalChargePeriod',
          formatter: (cell: ITimePeriod) =>
            `${cell.value && cell.unit ? snakeCaseToProperCase(convertTimePeriodToString(cell)) : ''}`,
          sort: true,
        },
        user?.isInternal && {
          text: 'Business',
          dataField: 'business',
          formatter: (business: IBusiness) => business.name,
          sort: true,
        },
        {
          text: `${t('spelling.capitalCenter')}(s)`,
          dataField: 'center',
          formatter: (center: { name: string } | null) => center?.name ?? 'All',
          sort: true,
        },
      ],
    };
  }, [formatDayOf, t, user?.isInternal]);
  const cycleType = isRegion('AU') ? 'BALANCE_BASED' : 'PERIOD_BASED';

  return (
    <Fragment>
      <HasRoleAreaLevel
        action={{
          area: AreaType.Billing,
          permission: PermissionType.BillingCycleTemplates,
          level: RoleLevelType.Create,
        }}
      >
        <CreateBillingCycleCard
          billingCycle={newBillingCycleFormData}
          templateType={cycleType}
          updateBillingCycle={setNewBillingCycleFormData}
          onPreview={() => setNewBillingCycleInPreview(cast<ICreateBillingCycleTemplateInput>(newBillingCycleFormData))}
        />
      </HasRoleAreaLevel>
      <BillingCycleTemplatesTable
        data={billingCycleTemplates.filter((template) => template.cycleType === cycleType)}
        loading={getBillingCycleTemplatesLoading}
        onEdit={(template) => editCycleTemplate(template)}
        onDelete={setTemplateInDeleteModal}
        onView={setTemplateInViewModal}
        columns={cycleTableColumns[getRegion()]}
      />

      {newBillingCycleInPreview && newBillingCycleInPreview.cycleType === 'BALANCE_BASED' && (
        <PreviewBillingCycleModal
          isOpen={Boolean(newBillingCycleInPreview)}
          onClose={() => setNewBillingCycleInPreview(null)}
          clearForm={() => setNewBillingCycleFormData({ cycleType: 'BALANCE_BASED' as BillingCycleType })}
          billingCycle={newBillingCycleInPreview}
        />
      )}
      {newBillingCycleInPreview && newBillingCycleInPreview.cycleType === 'PERIOD_BASED' && (
        <PreviewPeriodBasedBillingCycleModal
          isOpen={Boolean(newBillingCycleInPreview)}
          onClose={() => setNewBillingCycleInPreview(null)}
          clearForm={() => setNewBillingCycleFormData({ cycleType: 'PERIOD_BASED' as BillingCycleType })}
          billingCycle={newBillingCycleInPreview}
        />
      )}
      {editTemplateModalState.template && editTemplateModalState.template.cycleType !== 'PERIOD_BASED' && (
        <EditBillingCycleTemplateModal
          isOpen={editTemplateModalState.open}
          template={editTemplateModalState.template}
          onClose={() => setEditTemplateModalState((prev) => ({ ...prev, open: false, template: null }))}
          onDelete={setTemplateInDeleteModal}
        />
      )}
      {editTemplateModalState.template && editTemplateModalState.template.cycleType === 'PERIOD_BASED' && (
        <EditPeriodBasedBillingCycleTemplateModal
          isOpen={editTemplateModalState.open}
          template={editTemplateModalState.template}
          onClose={() => setEditTemplateModalState((prev) => ({ ...prev, open: false, template: null }))}
          onDelete={setTemplateInDeleteModal}
        />
      )}
      {templateInDeleteModal && (
        <DeleteBillingCycleTemplateModal
          isOpen={Boolean(templateInDeleteModal)}
          billingCycleTemplate={templateInDeleteModal}
          onClose={() => setTemplateInDeleteModal(null)}
          onSubmit={() => setEditTemplateModalState((prev) => ({ ...prev, open: false, template: null }))}
        />
      )}
      {templateInViewModal && templateInViewModal.cycleType !== 'PERIOD_BASED' && (
        <ViewBillingCycleTemplateModal
          isOpen={Boolean(templateInViewModal)}
          template={templateInViewModal}
          onClose={() => setTemplateInViewModal(null)}
        />
      )}
      {templateInViewModal && templateInViewModal.cycleType === 'PERIOD_BASED' && (
        <ViewPeriodBasedBillingCycleTemplateModal
          isOpen={Boolean(templateInViewModal)}
          template={templateInViewModal}
          onClose={() => setTemplateInViewModal(null)}
        />
      )}
    </Fragment>
  );
};

export default BillingCyclesTab;
