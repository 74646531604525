import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { Row, Col } from 'shared/components/Layout';
import colors from '_colors.module.scss';
import { convert24HourTimeToFormatted } from 'shared/util/timeUtils';

interface IProps {
  contract: IContract;
  timeslot: IContractTimeslot;
}

const CasualSchedule: React.FC<IProps> = ({ contract, timeslot, ...props }) => {
  return (
    <Row className="mb-3">
      <Col>
        <div className="mb-4">Drop Off</div>
        <div className="mb-4">
          <FontAwesomeIcon icon={faClock} size="lg" color={colors.gray} className="mr-1" />
          {timeslot?.dropOffTime ? convert24HourTimeToFormatted(timeslot.dropOffTime) : '\u00A0'}
        </div>
      </Col>
      <Col>
        <div className="mb-4">Pick Up</div>
        <div className="mb-4">
          {timeslot?.pickUpTime ? convert24HourTimeToFormatted(timeslot.pickUpTime) : '\u00A0'}
        </div>
      </Col>
    </Row>
  );
};

export default CasualSchedule;
