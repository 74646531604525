import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { GET_STAFF } from 'gql/staff/queries';

interface IEmploymentHistoryInput {
  id: string;
  employmentStartDate?: string | null;
  employmentEndDate?: string | null;
  reasonForLeaving?: ReasonForLeaving | null;
}

interface IEmploymentHistoryData {
  id: string;
  employmentStartDate: string | null;
  employmentEndDate: string | null;
  reasonForLeaving: ReasonForLeaving | undefined;
  employmentStatus: EmploymentStatus;
}

interface IUpdateRoleshiptVariables {
  input: {
    id: string;
    roleId: string;
    scopeIds: string[];
    primaryCenterId: string | null;
  };
}

interface IUpdateRoleshipData {
  updateUserRoleship: IStaff;
}

interface IPositionsInput {
  id: string;
  scopeId: string;
  positions: IPositionInput[];
}

interface IPositionsData {
  id: string;
  positions: IStaffPosition[];
}

const positionFields = `
  id
  positionId
  personId
  positionName
  scopeId
  scopeType
  payRate
  type
`;

export const UPDATE_EMPLOYMENT_HISTORY = gql`
  mutation ($input: UpdateEmploymentHistoryInput!) {
    updateEmploymentHistory(input: $input) {
      id
      employmentStartDate
      employmentEndDate
      reasonForLeaving
      employmentStatus
    }
  }
`;

export const UPDATE_ROLESHIP = gql`
  mutation ($input: UpdateRoleshipInput!) {
    updateUserRoleship(input: $input) {
      id
      roleship {
        roleId
        name
        scopeType
        scopes {
          ... on Center {
            id
            name
            avatar {
              url
            }
          }
          ... on Entity {
            id
            name
          }
        }
      }
    }
  }
`;

export const DISSOCIATE_FROM_CENTER = gql`
  mutation($input: DissociateStaffFromCenterInput!) {
    dissociateStaffFromCenter(input: $input) {
      id
      roleship {
        roleId
        name
        scopeType
        scopes {
          ... on Center {
            id
            name
            avatar {
              url
            }
          }
          ... on Entity {
            id
            name
          }
        }
      }
      positions {
        ${positionFields}
      }
    }
  }
`;

export const CREATE_POSITION = gql`
  mutation($input: CreatePositionInput!) {
    createPosition(input: $input) {
      ${positionFields}
    }
  }
`;

export const UPDATE_POSITION = gql`
  mutation($input: UpdatePositionInput!) {
    updatePosition(input: $input) {
      ${positionFields}
    }
  }
`;

export const DELETE_POSITION = gql`
  mutation ($positionId: ID!) {
    deletePosition(positionId: $positionId) {
      id
      personId
    }
  }
`;

export const useUpdateEmploymentHistory = () =>
  useMutation<{ updateEmploymentHistory: IEmploymentHistoryData }, { input: IEmploymentHistoryInput }>(
    UPDATE_EMPLOYMENT_HISTORY,
    {
      update: (proxy, result) => {
        if (result.data?.updateEmploymentHistory) {
          const { id, ...updatedEmploymentHistory }: IEmploymentHistoryData = result.data.updateEmploymentHistory;

          const cachedResult = proxy.readQuery<{ getStaff: IStaff }>({
            query: GET_STAFF,
            variables: {
              id,
            },
          });

          if (cachedResult?.getStaff) {
            const person: IStaff = cachedResult.getStaff;
            proxy.writeQuery({
              query: GET_STAFF,
              variables: {
                id,
              },
              data: {
                getStaff: {
                  ...person,
                  ...updatedEmploymentHistory,
                },
              },
            });
          }
        }
      },
    }
  );

export const useUpdateRoleship = (personId: string) =>
  useMutation<IUpdateRoleshipData, IUpdateRoleshiptVariables>(UPDATE_ROLESHIP, {
    update: (proxy, result) => {
      if (result.data?.updateUserRoleship) {
        const cachedPersonQuery = proxy.readQuery<{ getStaff: IStaff }>({
          query: GET_STAFF,
          variables: {
            id: personId,
          },
        });

        if (cachedPersonQuery?.getStaff) {
          proxy.writeQuery({
            query: GET_STAFF,
            variables: {
              id: personId,
            },
            data: {
              getStaff: {
                ...cachedPersonQuery.getStaff,
                roleship: result.data.updateUserRoleship.roleship,
              },
            },
          });
        }
      }
    },
  });

export const useDissociateStaffFromCenter = () =>
  useMutation<{ dissociateStaffFromCenter: IStaff }, { input: { id: string; centerId: string } }>(
    DISSOCIATE_FROM_CENTER,
    {
      update: (proxy, result) => {
        if (result.data?.dissociateStaffFromCenter) {
          const cachedResult = proxy.readQuery<{ getStaff: IStaff }>({
            query: GET_STAFF,
            variables: {
              id: result.data.dissociateStaffFromCenter.id,
            },
          });

          if (cachedResult?.getStaff) {
            const person: IStaff = cachedResult.getStaff;
            proxy.writeQuery({
              query: GET_STAFF,
              variables: {
                id: result.data.dissociateStaffFromCenter.id,
              },
              data: {
                getStaff: {
                  ...person,
                  positions: result.data.dissociateStaffFromCenter.positions,
                  roleship: result.data.dissociateStaffFromCenter.roleship,
                },
              },
            });
          }
        }
      },
    }
  );

export const useCreatePosition = () =>
  useMutation<{ createPosition: IStaffPosition }, { input: ICreateStaffPositionInput }>(CREATE_POSITION, {
    update: (proxy, result) => {
      const newPosition = result.data?.createPosition;
      if (newPosition) {
        const cachedResult = proxy.readQuery<{ getStaff: IStaff }>({
          query: GET_STAFF,
          variables: { id: newPosition.personId },
        });

        if (cachedResult?.getStaff) {
          const person: IStaff = cachedResult.getStaff;
          proxy.writeQuery({
            query: GET_STAFF,
            variables: { id: newPosition.personId },
            data: { getStaff: { ...person, positions: [...person.positions, newPosition] } },
          });
        }
      }
    },
  });

export const useUpdatePosition = () =>
  useMutation<{ updatePosition: IStaffPosition }, { input: IUpdateStaffPositionInput }>(UPDATE_POSITION, {
    update: (proxy, result) => {
      const updatedPosition = result.data?.updatePosition;
      if (updatedPosition) {
        const cachedResult = proxy.readQuery<{ getStaff: IStaff }>({
          query: GET_STAFF,
          variables: { id: updatedPosition.personId },
        });

        if (cachedResult?.getStaff) {
          const person: IStaff = cachedResult.getStaff;
          proxy.writeQuery({
            query: GET_STAFF,
            variables: { id: updatedPosition.personId },
            data: {
              getStaff: {
                ...person.positions,
                positions: person.positions.map((p) => (p.id === updatedPosition.id ? updatedPosition : p)),
              },
            },
          });
        }
      }
    },
  });

export const useDeletePosition = () =>
  useMutation<{ deletePosition: IStaffPosition }, { positionId: string }>(DELETE_POSITION, {
    update: (proxy, result) => {
      const deletedPosition = result.data?.deletePosition;
      if (deletedPosition) {
        const cachedResult = proxy.readQuery<{ getStaff: IStaff }>({
          query: GET_STAFF,
          variables: { id: deletedPosition.personId },
        });

        if (cachedResult?.getStaff) {
          const person: IStaff = cachedResult.getStaff;
          proxy.writeQuery({
            query: GET_STAFF,
            variables: { id: deletedPosition.personId },
            data: { getStaff: { ...person, positions: person.positions.filter((p) => p.id !== deletedPosition.id) } },
          });
        }
      }
    },
  });
