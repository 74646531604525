import React, { useCallback } from 'react';
import { Menu, MenuItem } from 'shared/components/Dropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import { faEllipsisH, faBan, faDollarSign, faUndo } from '@fortawesome/pro-light-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import useUniqueId from 'shared/hooks/useUniqueId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'shared/components/ActionDropdown/_actionDropdown.scss';
import colors from '_colors.module.scss';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ChargeFeeModal from './ChargeFeeModal';

const NO_OP = () => {};

interface IProps {
  sessionCostData: ISessionCost | undefined | null;
  id?: string;
  className?: string;
  hasEarlyFee: boolean;
  hasLateFee: boolean;
  onRemoveFee: (feeType: 'early' | 'late') => void;
  onRestoreFee: (feeType: 'early' | 'late') => void;
  hasBeenCharged: boolean;
  onCharge: () => void;
  isDisabledAllActions: boolean;
  sessionFromFlatRateFee?: boolean;
  isWeeklyView?: boolean;
}

interface IIconProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const getIcon = () =>
  React.forwardRef<HTMLDivElement, IIconProps>(({ onClick }, ref) => {
    const handleClick = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onClick && onClick(event);
    };

    return (
      <div ref={ref} onClick={handleClick}>
        <IconButtonCircle id="action-dropdown-btn" iconSize="2x" icon={faEllipsisH} onClick={NO_OP} />
      </div>
    );
  });

/**
 * Near 1 for 1 copy of <ActionDropdown /> except this has custom icons created by layering multiple font awesome icons, something our shared component does not support
 */
const AttendanceTableFinanceActionDropdown: React.FC<IProps> = ({
  sessionCostData,
  hasEarlyFee,
  hasLateFee,
  onRemoveFee,
  onRestoreFee,
  id = 'attendance-finance-table-actions-dropdown',
  className = '',
  hasBeenCharged,
  onCharge,
  isDisabledAllActions,
  sessionFromFlatRateFee,
  isWeeklyView,
  ...props
}) => {
  const _id = useUniqueId(id);
  const { k2ChargeFlatRates, k2ChargeConfirmationModal } = useFlags();
  const [isChargeFeeOpen, setChargeFeeOpen] = React.useState(false);
  const onProceedCharge = useCallback(() => {
    onCharge();
    setChargeFeeOpen(false);
  }, [onCharge]);

  const onCancel = useCallback(() => {
    setChargeFeeOpen(false);
  }, []);

  return (
    <>
      <Dropdown alignRight className={className}>
        <Dropdown.Toggle id={_id} as={getIcon()} />
        <Menu>
          {hasEarlyFee ? (
            <MenuItem
              as="div"
              className="cursor-pointer"
              id="clear-early-fees-menu-item"
              onClick={(e: React.MouseEvent<any, MouseEvent>) => {
                e.stopPropagation();
                e.preventDefault();
                onRemoveFee('early');
              }}
              disabled={isDisabledAllActions}
            >
              <span className="fa-layers fa-fw mr-4">
                <FontAwesomeIcon icon={faBan} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
              Clear Early Drop-off Fees
            </MenuItem>
          ) : (
            <MenuItem
              as="div"
              className="cursor-pointer"
              id="recalculate-fees-menu-item"
              onClick={(e: React.MouseEvent<any, MouseEvent>) => {
                e.stopPropagation();
                e.preventDefault();
                onRestoreFee('early');
              }}
              disabled={isDisabledAllActions}
            >
              <span className="fa-layers fa-fw mr-4">
                <FontAwesomeIcon icon={faUndo} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
              Restore Early Drop-off Fees
            </MenuItem>
          )}
          {hasLateFee ? (
            <MenuItem
              as="div"
              className="cursor-pointer"
              id="clear-late-fees-menu-item"
              onClick={(e: React.MouseEvent<any, MouseEvent>) => {
                e.stopPropagation();
                e.preventDefault();
                onRemoveFee('late');
              }}
              disabled={isDisabledAllActions}
            >
              <span className="fa-layers fa-fw mr-4">
                <FontAwesomeIcon icon={faBan} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
              Clear Late Drop-off Fees
            </MenuItem>
          ) : (
            <MenuItem
              as="div"
              className="cursor-pointer"
              id="recalculate-fees-menu-item"
              onClick={(e: React.MouseEvent<any, MouseEvent>) => {
                e.stopPropagation();
                e.preventDefault();
                onRestoreFee('late');
              }}
              disabled={isDisabledAllActions}
            >
              <span className="fa-layers fa-fw mr-4">
                <FontAwesomeIcon icon={faUndo} size="lg" color={colors['dark-gray']} />
                <FontAwesomeIcon icon={faDollarSign} size="xs" color={colors['dark-gray']} />
              </span>
              Restore Late Drop-off Fees
            </MenuItem>
          )}
          {((!hasBeenCharged && !sessionFromFlatRateFee) ||
            (!hasBeenCharged && sessionFromFlatRateFee && !isWeeklyView && k2ChargeFlatRates)) && (
            <MenuItem
              as="div"
              className="cursor-pointer"
              id="clear-late-fees-menu-item"
              onClick={(e: React.MouseEvent<any, MouseEvent>) => {
                if (k2ChargeConfirmationModal) {
                  setChargeFeeOpen(!isChargeFeeOpen);
                } else {
                  e.stopPropagation();
                  e.preventDefault();
                  onCharge();
                }
              }}
              disabled={isDisabledAllActions}
            >
              <FontAwesomeIcon className="mr-4 fa-fw" icon={faDollarSign} color={colors['dark-gray']} />
              Charge
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
      <ChargeFeeModal
        sessionCostData={sessionCostData}
        chargeFeeOpen={isChargeFeeOpen}
        onProceedCharge={onProceedCharge}
        onCancel={onCancel}
        isWeeklyView={isWeeklyView}
      />
    </>
  );
};

export default AttendanceTableFinanceActionDropdown;
