import { IAgencyPaymentsTableFilters } from './reducers';

export const GET_AGENCY_PAYMENTS = 'GET_AGENCY_PAYMENTS';
export const CREATE_AGENCY_PAYMENT = 'CREATE_AGENCY_PAYMENT';
export const UPDATE_AGENCY_PAYMENT = 'UPDATE_AGENCY_PAYMENT';
export const DELETE_AGENCY_PAYMENT = 'DELETE_AGENCY_PAYMENT';
export const UPDATE_AGENCY_PAYMENT_TABLE_FILTERS = 'UPDATE_AGENCY_PAYMENT_TABLE_FILTERS';

interface IGetAgencyPaymentsAction {
  type: typeof GET_AGENCY_PAYMENTS;
  completedPayments: IPagedResult<IAgencyPayment>;
  draftPayments: IPagedResult<IAgencyPayment>;
}

interface ICreateAgencyPaymentAction {
  type: typeof CREATE_AGENCY_PAYMENT;
  payment: IAgencyPayment;
}

interface IUpdateAgencyPaymentAction {
  type: typeof UPDATE_AGENCY_PAYMENT;
  payment: IAgencyPayment;
}

interface IDeleteAgencyPaymentAction {
  type: typeof DELETE_AGENCY_PAYMENT;
  payment: IAgencyPayment;
}

interface IUpdateAgencyPaymentTableFilters {
  type: typeof UPDATE_AGENCY_PAYMENT_TABLE_FILTERS;
  filters: Partial<IAgencyPaymentsTableFilters>;
}

export type AgencyPaymentActionTypes =
  | IGetAgencyPaymentsAction
  | ICreateAgencyPaymentAction
  | IUpdateAgencyPaymentTableFilters
  | IDeleteAgencyPaymentAction
  | IUpdateAgencyPaymentAction;
