import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import cast from 'shared/util/cast';
import DayInput from '../DayInput';
interface IProps {
  firstWeekDays: WeekDay[];
  secondWeekDays: WeekDay[];
  onDaySelect?: (value: WeekDay, week: WeekType) => void;
}
const DayForthnightlyInput: React.FC<IProps> = ({ firstWeekDays, secondWeekDays, onDaySelect }) => {
  const handleDaySelect = (value: WeekDay, week: WeekType) => {
    if (onDaySelect) {
      onDaySelect(value, week);
    }
  };
  return (
    <Tabs defaultActiveKey="WEEK1" id="biweekly-day-input">
      {['WEEK1', 'WEEK2'].map((week, idx) => (
        <Tab eventKey={week} title={`Week ${week === 'WEEK1' ? '1' : '2'}`} key={idx}>
          <DayInput
            value={week === 'WEEK1' ? firstWeekDays : secondWeekDays}
            onDaySelect={(value) => handleDaySelect(value, cast<WeekType>(week))}
          />
        </Tab>
      ))}
    </Tabs>
  );
};

export default DayForthnightlyInput;
