export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

interface ISetNotificationsAction {
  type: typeof SET_NOTIFICATIONS;
  notifications: INotificationV2[];
}

interface IUpdateNotificationAction {
  type: typeof UPDATE_NOTIFICATION;
  notification: INotificationV2;
}

export type NotificationV2ActionTypes = ISetNotificationsAction | IUpdateNotificationAction;
