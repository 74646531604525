/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import { useUpdateStaffProfile } from 'gql/staff/mutations';
import AddressInput from 'shared/components/AddressInput';
import errorMessage from 'shared/constants/errorMessages';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import PhoneInput from 'shared/components/PhoneInput';
import { showToast } from 'shared/components/Toast';
import { omitTypename, omitFalsy } from 'shared/util/object';
import cast from 'shared/util/cast';
import { isValidPhoneNumber } from 'shared/util/string';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers';

interface IProps {
  data: IStaff;
  readOnly?: boolean;
}

const ADDRESS_OBJECT = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: 'US',
  postalCode: '',
};

const PersonalInformationForm: React.FC<IProps> = ({ data, readOnly = false, ...props }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [personalInformation, updatePersonalInformation] = useState({
    ...data,
    phoneNumber: data.phoneNumber ?? '',
    address: data.address ?? { ...ADDRESS_OBJECT },
  });
  const [updateStaffProfile, { loading }] = useUpdateStaffProfile();
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);

  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;

  const handleResetForm = useCallback(() => {
    updatePersonalInformation({
      ...data,
      phoneNumber: data.phoneNumber ?? '',
      address: data.address || { ...ADDRESS_OBJECT },
    });
  }, [updatePersonalInformation, data]);

  const handleDateSelect = useCallback(
    (date) => {
      updatePersonalInformation({
        ...personalInformation,
        dob: date,
      });
      !formIsDirty && setFormIsDirty(true);
    },
    [formIsDirty, updatePersonalInformation, personalInformation]
  );

  const handleAddressUpdate = useCallback(
    (address) => {
      updatePersonalInformation({
        ...personalInformation,
        address: address,
      });
      !formIsDirty && setFormIsDirty(true);
    },
    [formIsDirty, personalInformation, updatePersonalInformation]
  );

  const handleFormUpdateAndToggleDirty = useCallback(
    (value: any, name: string | symbol) => {
      updatePersonalInformation({
        ...personalInformation,
        [name]: value,
      });
      !formIsDirty && setFormIsDirty(true);
    },
    [formIsDirty, updatePersonalInformation, personalInformation]
  );

  useEffect(() => {
    updatePersonalInformation((prevState) => {
      return {
        ...prevState,
        verifiedEmail: data.verifiedEmail ?? '',
      };
    });
  }, [data.verifiedEmail]);

  const save = useCallback(() => {
    updateStaffProfile({
      variables: {
        input: {
          id: personalInformation.id,
          firstname: personalInformation.firstname,
          lastname: personalInformation.lastname,
          address: cast<IAddress>(omitTypename(omitFalsy(personalInformation.address))),
          dob: personalInformation.dob,
          phoneNumber: personalInformation.phoneNumber || null,
          nickname: personalInformation.nickname,
        },
      },
    })
      .then(() => {
        showToast('Staff updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch(() => {
        showToast('There was an error updating staff. Please try again later.', 'error');
      });
  }, [
    personalInformation.address,
    personalInformation.dob,
    personalInformation.firstname,
    personalInformation.id,
    personalInformation.lastname,
    personalInformation.nickname,
    personalInformation.phoneNumber,
    updateStaffProfile,
  ]);

  const formInvalid =
    !personalInformation.firstname ||
    !personalInformation.lastname ||
    !isValidPhoneNumber(personalInformation.phoneNumber);

  return (
    <FormWrapper2
      formId="staff-personal-info"
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onSave={save}
      onCancel={handleResetForm}
      loading={loading}
      toggleDirtyOnSave={false}
      saveDisabled={formInvalid}
    >
      <Row>
        <Col md className="col-md-8 col-sm-12">
          <TextInput
            label="Preferred Name"
            id="nickname-input"
            name="nickname"
            value={personalInformation.nickname}
            onChange={handleFormUpdateAndToggleDirty}
            disabled={readOnly}
          />
        </Col>
        <Col md className="mb-4">
          <DateInput
            label="Date of Birth"
            required={false}
            date={personalInformation.dob}
            maxDate={moment().subtract(15, 'years')}
            onDateSelect={handleDateSelect}
            placeholder="Date of Birth"
            isOutsideRange={(day) => moment().diff(day, 'year') < 15}
            disabled={readOnly || AdpEnabled}
            initialVisibleMonth={() => moment().subtract(15, 'years').month(0)}
            dateOnly
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <TextInput
            id="firstname-input"
            label="First Name"
            name="firstname"
            required={true}
            value={personalInformation.firstname}
            onChange={handleFormUpdateAndToggleDirty}
            disabled={readOnly || AdpEnabled}
          />
        </Col>
        <Col md>
          <TextInput
            id="lastname-input"
            label="Last Name"
            name="lastname"
            required={true}
            value={personalInformation.lastname}
            onChange={handleFormUpdateAndToggleDirty}
            disabled={readOnly || AdpEnabled}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <AddressInput
            address={personalInformation.address}
            onChange={handleAddressUpdate}
            errorText={errorMessage.address.staff}
            disabled={readOnly || AdpEnabled}
            addressLabel="Street Address"
            required={false}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <PhoneInput
            label="Phone Number"
            name="staff-ph"
            value={personalInformation.phoneNumber}
            onChange={(value: string) => handleFormUpdateAndToggleDirty(value, 'phoneNumber')}
            required={false}
            disabled={readOnly || AdpEnabled}
          />
        </Col>
        <Col md={8}>
          <TextInput required disabled label="Verified Email" name="email" value={personalInformation.verifiedEmail} />
        </Col>
      </Row>
      {formInvalid && <small className="mt-4">{errorMessage.formRequirements}</small>}
    </FormWrapper2>
  );
};

export default PersonalInformationForm;
