import React from 'react';
import { IInputWithAppendedNodeProps, IInputWithAppendedTextProps } from './Input';
import NumberInput, { INumberInputProps } from './NumberInput';

const CurrencyInput: React.FC<(IInputWithAppendedNodeProps | IInputWithAppendedTextProps) & INumberInputProps> = ({
  placeholder = '0.00',
  ...props
}) => {
  return (
    <NumberInput
      {...props}
      prepend="$"
      numberFormat={{ thousandSeparator: true, decimalScale: 2, fixedDecimalScale: true }}
      placeholder={placeholder}
    />
  );
};

export default CurrencyInput;
