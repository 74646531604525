export const SET_ENTITIES = 'SET_ENTITIES';
export const FETCH_ALLOWED_ENTITIES = 'FETCH_ALLOWED_ENTITIES';

interface IFetchAllowedEntities {
  type: typeof FETCH_ALLOWED_ENTITIES;
}

interface ISetEntities {
  type: typeof SET_ENTITIES;
  entities: IEntity[];
}

export type EntityActionTypes = IFetchAllowedEntities | ISetEntities;
