import React from 'react';
import Tooltip from './Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  text: string;
  direction?: 'left' | 'right' | 'top' | 'bottom';
  className?: string;
  size?: SizeProp;
  icon?: IconDefinition;
}

const HelpTooltip: React.FC<IProps> = ({ text, direction = 'right', className, size, icon, ...props }) => {
  return (
    <Tooltip text={text} direction={direction}>
      <FontAwesomeIcon icon={icon ? icon : faInfoCircle} size={size ?? 'xs'} className={className ?? 'ml-2'} />
    </Tooltip>
  );
};

export default HelpTooltip;
