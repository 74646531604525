import React from 'react';

export interface PermissionDiscriminator {
  hasAreaPermissionLevel(action: IAreaPermissionLevel): boolean;
}

class EverythingIsOkPermissionDiscriminator implements PermissionDiscriminator {
  hasAreaPermissionLevel(action: IAreaPermissionLevel): boolean {
    return true;
  }
}

export const PermissionContext = React.createContext<PermissionDiscriminator>(
  new EverythingIsOkPermissionDiscriminator()
);
