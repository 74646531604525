import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Col, Row } from 'shared/components/Layout';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';

import { sortBy } from 'lodash';

import './ccssPersonnelTab.scss';
import { useGetServicePersonnel } from 'gql/ccssPersonnel/queries';
import { PersonnelPositionLabels } from 'shared/constants/enums/ccssPersonnelEnums';
import useDatatableState from 'shared/hooks/useDatatableState2';
import Select from 'shared/components/Select';
import { useGetServicesByBusiness } from '../CcsAccountManagement/graphql/queries';

interface IProps {
  business: IBusiness;
}

interface CcsServicePersonnelFilter {
  serviceId: string;
  searchText: string;
  centers: ITableFilterOption[];
}

const CcssServicePersonnelTab: React.FC<IProps> = ({ business, ...props }) => {
  const [sort, setSortOptions] = useState<IElasticsearchSortFilter[]>([{ field: 'firstName', direction: 'ASCENDING' }]);
  const [activeFilters, setActiveFilters] = useState<CcsServicePersonnelFilter>({
    centers: [],
    serviceId: '',
    searchText: '',
  });
  const [tableState, tableFunctions] = useDatatableState();

  const { data, loading: getPersonnelLoading } = useGetServicePersonnel({
    variables: {
      businessId: business.id,
      serviceId: activeFilters.serviceId,
      pageNumber: tableState.activePage,
      pageSize: tableState.pageSize,
      name: activeFilters.searchText || '',
      sortDtos: sort,
    },
    skip: business === null || activeFilters.serviceId === '',
  });

  const { data: services, loading } = useGetServicesByBusiness(business.id);

  const serviceOptions = useMemo(
    () =>
      services?.getServicesByBusiness.data.map((s) => {
        return { value: s.id, label: `${s.serviceId} - ${s.name}` };
      }) || [],
    [services]
  );
  const personnel = data?.getPersonnelByServiceId.data || [];

  useEffect(() => {
    if (serviceOptions?.length > 0) {
      setActiveFilters((filters) => ({
        ...filters,
        serviceId: serviceOptions[0]?.value,
      }));
    }
  }, [serviceOptions]);

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field, direction }]);
  };

  return (
    <Row align="start">
      <Col>
        <DataTable
          showLoadingOverlay={getPersonnelLoading || loading}
          className="ccss-personnel-table"
          data={personnel}
          pageSize={tableState.pageSize}
          activePage={tableState.activePage}
          dataSize={data?.getPersonnelByServiceId.totalRecords || 0}
          showSelect={false}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          noPadding
          onSort={handleSort}
          renderHeader={(paginationProps: any) => (
            <TableHeader>
              <div className="d-flex flex-grow-1 my-2">
                <SizePerPage paginationProps={paginationProps} />
                <TableSearch
                  onChange={(value) => {
                    setActiveFilters({ ...activeFilters, searchText: value });
                  }}
                  placeholder="Search"
                />
              </div>

              <Select
                className="mb-0 service-select"
                placeholder="Service"
                options={serviceOptions ?? []}
                value={activeFilters.serviceId}
                onChange={(value) => {
                  setActiveFilters({ ...activeFilters, serviceId: value.value });
                }}
              />
            </TableHeader>
          )}
          columns={[
            {
              text: 'Name',
              dataField: 'firstName',
              sort: true,
              formatter: (cell: string, row: ICcssPersonnel) => `${row.firstName} ${row.lastName}`,
            },
            {
              text: 'Person ID',
              dataField: 'personId',
            },
            {
              text: 'Role',
              dataField: 'role',
              formatter: () => 'Operations',
            },
            {
              text: 'Position',
              dataField: '',
              formatter: (_cell: string, row: ICcssPersonnel) => {
                const lastProviderRole = _(sortBy(row.providerRoles, (pr) => pr.startDate)).last()!;
                const positionText = PersonnelPositionLabels[lastProviderRole?.position];

                return positionText || '';
              },
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default CcssServicePersonnelTab;
