import { DataProxy } from '@apollo/client/cache';

interface DataProxyExt extends DataProxy {
  data?: {
    data?: { [key: string]: string };
    delete: (key: string) => void;
  };
}

const deleteCacheForQuery = (queryName: string) => (proxy: DataProxyExt) => {
  const cache = proxy.data;
  if (cache) {
    Object.keys(cache.data || {})
      .filter((name) => name.startsWith(queryName) || name.startsWith(`$ROOT_QUERY.${queryName}`))
      .forEach((name) => cache.delete(name));
  }
};

export default deleteCacheForQuery;
