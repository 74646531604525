import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import { CreateButton, CirclePlusButton, IconButton } from 'shared/components/Buttons';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetAttendanceOpenSpots, useGetExpectedSessions } from 'gql/session/queries';
import moment from 'moment';
import { useGetClassesForCenter } from 'gql/class/queries';
import { sortBy, orderBy } from 'lodash';
import AttendanceViewHeader from './AttendanceViewHeader';
import CreateSessionModal from './Times/CreateSessionModal';
import DailyAttendanceTable from './Times/DailyAttendanceTable';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import AttendanceSearchAndFilters from './AttendanceSearchAndFilters';
import WeekAttendanceView from './Times/WeeklyAttendanceTable';
import EditSessionModal from './Times/EditSessionModal';
import ClearSessionModal from './Times/ClearSessionModal';
import RemoveSessionModal from './Times/RemoveSessionModal';
import { useCheckIn, useCheckOut } from 'gql/session/mutations';
import { showToast } from 'shared/components/Toast';
import BulkActions from './Times/BulkActions';
import DailyAttendanceFinanceTable from './Finance/DailyAttendanceFinanceTable';
import WeeklyAttendanceFinanceTable from './Finance/WeeklyAttendanceFinanceTable';
import BulkEditFeeModal from './Finance/BulkEditFeeModal';
import RemoveRestoreFeeModal from './Finance/RemoveRestoreFeeModal';
import FinanceBulkActions from './Finance/FinanceBulkActions';
import UtilizationTableCard from './UtilizationTableCard';
import { ageToNumberOfDays } from 'shared/util/ageToNumberOfDays';
import { useCreateTransactionsForSessions } from 'gql/transaction/mutations';
import errors from 'shared/constants/errorMessages';
import { getExpectedSessions, getSessionTransactions } from './duck/actions';
import ReportAbsentModal from './Times/ReportAbsentModal';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import DeleteSessionTimeEntryModal from './Times/DeleteSessionTimeEntryModal';
import GapWaiverModal from './GapWaiverModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isRegion } from 'shared/util/region';
import { useOpenClassOnDate } from 'gql/class/mutations';
import { useTranslation } from 'react-i18next';
import { faArrowsRotate } from 'shared/constants/customIcons';

interface IRouteProps {}

interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}
interface ISingleChildAbsenceModalState {
  open: boolean;
  sessionId: string | null;
  contractId: string | null;
  accountChildId: string | null;
  date: string | null;
}

interface IMultipleChildAbsenceModalState {
  open: boolean;
  date: string | null;
  sessions: IReportAbsenceItem[];
}

interface IBulkEditFeeModalState {
  open: boolean;
  date: string | null;
  sessions: ISession[];
}

interface IEditSessionModalState {
  open: boolean;
  sessions: ISession[];
  child: IChild | null;
}

interface IRemoveRestoreFeeModalState {
  open: boolean;
  action: 'remove' | 'restore';
  feeType: 'early' | 'late';
  sessions: ISession[];
}

interface IDeleteSessionTimeEntryModalState {
  open: boolean;
  timeEntry: IAttendanceTimeEntry | null;
}

const Attendance: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { k2AttendanceRefresh } = useFlags();
  const isAuRegion = isRegion('AU');
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'lastname',
    direction: 'asc',
  });
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const sessions = useSelector((state: RootState) => state.attendance.sessions) ?? [];
  const timezone =
    useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? (moment.tz.guess() as Timezone);
  const hasEditTransactionPermission = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const [timeRange, setTimeRange] = useState<'DAY' | 'WEEK'>('DAY');
  const [statusFilter, setStatusFilter] = useState<AttendanceStatus>('Expected');
  const [view, setView] = useState<'TIMES' | 'FINANCE'>('TIMES');
  const [date, setDate] = useState(moment().tz(timezone).format());
  const [searchTerm, setSearchTerm] = useState('');
  const [createSessionModalOpen, setCreateSessionModalOpen] = useState(false);
  const [classId, setClassId] = useState('');
  const [classIds, setClassIds] = useState<string[] | null>(null);
  const [singleAbsenceModalState, setSingleAbsenceModalState] = useState<ISingleChildAbsenceModalState>({
    open: false,
    sessionId: null,
    contractId: null,
    accountChildId: null,
    date: null,
  });

  const [multipleAbsenceModalState, setMultipleAbsenceModalState] = useState<IMultipleChildAbsenceModalState>({
    open: false,
    date: null,
    sessions: [],
  });

  const [editSessionModalState, setEditSessionModalState] = useState<IEditSessionModalState>({
    open: false,
    sessions: [],
    child: null,
  });
  const [bulkEditFeeModalState, setBulkEditFeeModalState] = useState<IBulkEditFeeModalState>({
    open: false,
    date: null,
    sessions: [],
  });

  const [removeRestoreModalState, setRemoveRestoreModalState] = useState<IRemoveRestoreFeeModalState>({
    open: false,
    action: 'remove',
    feeType: 'early',
    sessions: [],
  });
  const [deleteSessionTimeEntryModalState, setDeleteSessionTimeEntryModalState] =
    useState<IDeleteSessionTimeEntryModalState>({
      open: false,
      timeEntry: null,
    });
  const [sessionToBeCleared, setSessionToBeCleared] = useState<ISession | null>(null);
  const [sessionToBeRemoved, setSessionToBeRemoved] = useState<ISession | null>(null);
  const [selectedSessions, setSelectedSessions] = useState<ISession[]>([]);
  const [childIdsInLoadingState, setChildIdsInLoadingState] = useState<string[]>([]);
  const [childAndClassIdsInLoadingState, setChildAndClassIdsInLoadingState] = useState<ISessionCharge[]>([]);
  const isCharging = useMemo(() => childAndClassIdsInLoadingState.length > 0, [childAndClassIdsInLoadingState.length]);
  const [isGapWaiverModalOpen, setIsGapWaiverModalOpen] = useState<boolean>(false);

  const changeView = (v: 'TIMES' | 'FINANCE') => {
    setSelectedSessions([]);
    setView(v);
  };

  // day(Number): This method can be used to set the day of the week, with Sunday as 0 and Saturday as 6.
  // AU considers a week to be from Mon -> Sun (if selected date is sunday then -6/0, otherwise 1/7)
  // US considers a week to be from Sun -> Sat (0/6)
  const startDay = isAuRegion ? (moment(date).tz(timezone).weekday() === 0 ? -6 : 1) : 0;
  const endDay = isAuRegion ? (moment(date).tz(timezone).weekday() === 0 ? 0 : 7) : 6;
  const startOfWeek = moment(date).tz(timezone).day(startDay).startOf('d').format();
  const endOfWeek = moment(date).tz(timezone).day(endDay).endOf('d').format();
  const julyCurrentYear = moment().tz(timezone).set('month', 6).startOf('month').startOf('day');
  const enrollmentYearStart = moment(date).tz(timezone).isBefore(julyCurrentYear)
    ? julyCurrentYear.clone().subtract(1, 'year')
    : julyCurrentYear;
  const enrollmentYearEnd = enrollmentYearStart.clone().add(1, 'year').subtract(1, 'minute');
  const { data } = useGetClassesForCenter({ variables: { centerId: selectedCenterId } });

  // filter out non active classes
  const classOptions =
    sortBy(
      data?.getClassesForCenter.filter(
        (c) =>
          !c.archivedAt &&
          moment(c.startsAt)
            .tz(timezone)
            .isSameOrBefore(timeRange === 'DAY' ? date : startOfWeek) &&
          (!c.endsAt ||
            moment(c.endsAt)
              .tz(timezone)
              .isSameOrAfter(timeRange === 'DAY' ? date : endOfWeek))
      ),
      'name'
    ) ?? [];

  // fetch sessions
  const {
    loading: sessionsLoading,
    networkStatus,
    refetch: refetchExpectedSessionsFn,
  } = useGetExpectedSessions({
    variables: {
      input: {
        centerId: selectedCenterId,
        startDate: startOfWeek,
        endDate: endOfWeek,
        excludeCost: view === 'TIMES' ? true : false,
      },
      enrollmentYearStart: enrollmentYearStart.format('YYYY-MM-DD'),
      enrollmentYearEnd: enrollmentYearEnd.format('YYYY-MM-DD'),
    },
    onError: (err) => {
      dispatch(getExpectedSessions([]));
      showToast(errors.generic, 'error');
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setDate(moment().tz(timezone).format());
  }, [timezone]);

  useEffect(() => {
    /**
     * if the sessions are updated in redux, update them in the edit modal state
     * so the changes are shown
     */
    if (editSessionModalState.open) {
      const classIdsInEditModal: Record<string, boolean> = editSessionModalState.sessions.reduce(
        (acc, curr) => ({ ...acc, [curr.classId]: true }),
        {}
      );
      /**
       * it is assumed that the array in redux will at most have sessions for one week. if the week view is visible then we can fetch all for the child,
       * otherwise we need to filter down to the specific date
       */
      const updatedSessionsForEditModal = sessions.filter(
        (s) =>
          s.childId === editSessionModalState.child?.id &&
          classIdsInEditModal[s.classId] &&
          (timeRange === 'DAY' ? moment(s.date).isSame(date, 'date') : true)
      );

      setEditSessionModalState((prev) => ({
        ...prev,
        sessions: updatedSessionsForEditModal,
        open: updatedSessionsForEditModal.length ? prev.open : false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  useEffect(() => {
    setClassIds([]);
  }, [selectedCenterId]);

  // reset selected children/sessions when session data is loaded/reloaded
  useEffect(() => {
    if (networkStatus !== NETWORK_STATUS.SET_VARIABLES || networkStatus !== NETWORK_STATUS.REFETCH) {
      setChildIdsInLoadingState([]);
      setSelectedSessions([]);
    }
  }, [networkStatus]);

  //FILTER ATTENDANCE
  const sessionMatchesSearchTerm = useCallback((session: ISession, term: string): boolean => {
    const lowercasedTerm = term.toLowerCase();
    return (
      session.child.firstname.toLowerCase().includes(lowercasedTerm) ||
      session.child.lastname.toLowerCase().includes(lowercasedTerm) ||
      `${(
        session.child.nickname ?? session.child.firstname
      ).toLowerCase()} ${session.child.firstname.toLowerCase()}`.includes(lowercasedTerm)
    );
  }, []);

  const filterData = useCallback(
    (arr: ISession[]): ISession[] => {
      return arr.filter(
        (session) =>
          (searchTerm ? sessionMatchesSearchTerm(session, searchTerm) : true) &&
          (classIds?.length ? classIds.includes(session.classId) : true) &&
          moment(session.date).isSameOrAfter(moment(startOfWeek), 'date') &&
          moment(session.date).isSameOrBefore(moment(endOfWeek), 'date') &&
          !session.void
      );
    },
    [searchTerm, classIds, sessionMatchesSearchTerm, startOfWeek, endOfWeek]
  );

  const sortData = useCallback(
    (data: ISession[]) => {
      return orderBy(data, sortField.field, sortField.direction);
    },
    [sortField]
  );

  const sortedAndFilteredData = useMemo(() => sortData(filterData(sessions)), [sessions, filterData, sortData]);

  const currentDayAttendance = filterData(
    sessions.filter((s) => {
      const sessionDate = moment(s.date, 'YYYY-MM-DD');
      // use a moment object with the session date and current time in the requested timezone for comparison
      const zonedSessionDate = moment().tz(timezone).set({
        year: sessionDate.year(),
        month: sessionDate.month(),
        date: sessionDate.date(),
      });
      return zonedSessionDate.isSame(date, 'd');
    })
  );

  const currentDayAttendanceByStatus: Record<AttendanceStatus, ISession[]> = {
    Expected: currentDayAttendance,
    Unknown: currentDayAttendance.filter((a) => a.timeEntries.length === 0 && !a.absence),
    Late: currentDayAttendance.filter((a) => {
      if (!a.dropOffTime) {
        return false;
      }
      const dropOffTime = moment(a.dropOffTime, 'HH:mm').tz(timezone);
      const dropOffDateAndTime = moment(a.date)
        .tz(timezone)
        .set('hours', dropOffTime.hours())
        .set('minutes', dropOffTime.minutes());
      return a.timeEntries.length === 0 && !a.absence && moment().tz(timezone).isAfter(dropOffDateAndTime);
    }),
    Absent: currentDayAttendance.filter((a) => a.absence),
    'Checked In': currentDayAttendance.filter(
      (a) => a.timeEntries.length > 0 && a.timeEntries.some((te) => !te.timeOut)
    ),
    'Checked Out': currentDayAttendance.filter(
      (a) => a.timeEntries.length > 0 && a.timeEntries.every((te) => te.timeIn && te.timeOut)
    ),
  };

  // QUERIES
  const {
    loading: getOpenSpotsLoading,
    data: getOpenSpotsData,
    refetch: refetchOpenSpotsFn,
  } = useGetAttendanceOpenSpots({
    variables: {
      input: {
        centerId: selectedCenterId,
        start: startOfWeek,
        end: endOfWeek,
      },
    },
  });

  const [openClassOnDate, { loading: openClassOnDateLoading }] = useOpenClassOnDate({
    onCompleted: () => {
      showToast(t('attendance.manage-closures.open-class-success-toast'), 'success');
      refetchOpenSpotsFn();
    },
  });

  const handleOpenClassOnDate = useCallback(
    async (closureId: string, date: string) => {
      openClassOnDate({
        variables: {
          input: {
            closureId,
            date,
          },
        },
      });
    },
    [openClassOnDate]
  );

  // MUTATIONS
  const [checkIn, { loading: checkInLoading }] = useCheckIn();
  const [checkOut, { loading: checkOutLoading }] = useCheckOut();
  const [createTransactions, { loading: chargeLoading }] = useCreateTransactionsForSessions();

  const handleCheckIn = useCallback(
    (sessions: ISession[]) => {
      setChildIdsInLoadingState(sessions.map((s) => s.childId));
      checkIn({
        variables: {
          input: {
            checkIns: sessions.map((s) => ({
              sessionId: s.id?.includes('contract') ? undefined : s.id, // if the session id is from contract, dont send,
              contractId: s.contractId,
            })),
            date: moment(date).tz(timezone).format('YYYY-MM-DD'),
            sendNotification: true,
          },
        },
      }).then((data) => {
        if (data.data?.checkIn.successful?.length) {
          showToast(
            `${data.data?.checkIn.successful?.length > 1 ? 'Children' : 'Child'} checked in successfully`,
            'success'
          );
        }
        if (data.data?.checkIn.failed?.length) {
          const errorMessages = data.data?.checkIn.failed?.map((f) => f.reason);
          showToast(errorMessages.join(', '), 'error');
        }
        setChildIdsInLoadingState([]);
        setSelectedSessions([]);
      });
    },
    [timezone, date]
  );

  const handleCheckOut = useCallback((sessions: ISession[]) => {
    setChildIdsInLoadingState(sessions.map((s) => s.childId));
    checkOut({
      variables: {
        input: {
          sessionIds: sessions.map((s) => s.id),
          sendNotification: true,
        },
      },
    }).then((data) => {
      if (data.data?.checkOut.successful?.length) {
        showToast(
          `${data.data?.checkOut.successful?.length > 1 ? 'Children' : 'Child'} checked out successfully`,
          'success'
        );
      }
      if (data.data?.checkOut.failed?.length) {
        const errorMessages = data.data?.checkOut.failed?.map((f) => f.reason);
        showToast(errorMessages.join(', '), 'error');
      }
      setChildIdsInLoadingState([]);
      setSelectedSessions([]);
    });
  }, []);

  const handleReportAbsence = useCallback((sessions: ISession[]) => {
    setChildIdsInLoadingState(sessions.map((s) => s.childId));
    setMultipleAbsenceModalState({
      open: true,
      date: null,
      sessions: sessions.map((item) => ({
        sessionId: item.id,
        contractId: item.contractId,
        accountChildId: item.accountChildId,
      })),
    });
  }, []);

  const handleBulkEditFee = useCallback((sessions: ISession[]) => {
    setBulkEditFeeModalState({
      open: true,
      date: null,
      sessions: sessions,
    });
    setChildIdsInLoadingState([]);
    setSelectedSessions([]);
  }, []);

  const handleRemoveRestoreFees = useCallback(
    (type: 'early' | 'late', action: 'remove' | 'restore', sessions: ISession[]) => {
      setRemoveRestoreModalState((prev) => ({
        ...prev,
        open: true,
        action,
        feeType: type,
        sessions,
      }));
    },
    []
  );

  const handleChargeSessions = useCallback(
    (sessions: ISession[]) => {
      setChildAndClassIdsInLoadingState(
        sessions.map((s) => ({
          sessionId: s.id,
          date: s.date,
          contractId: s.contractId,
          childId: s.childId,
          classId: s.classId,
        }))
      );

      createTransactions({
        variables: {
          input: {
            sessionMessages: sessions.map((s) => ({
              sessionId: s.id?.includes('contract') ? undefined : s.id, // if the session id is from contract, dont send,
              contractId: s.contractId,
              date: s.date,
            })),
          },
        },
      })
        .then((data) => {
          if (data.data?.createTransactionsForSessions) {
            showToast(`Session${sessions.length > 1 ? 's' : ''} charged successfully.`, 'success');
            dispatch(getSessionTransactions(data.data?.createTransactionsForSessions));
          }
          setChildAndClassIdsInLoadingState([]);
        })
        .catch(() => {
          setChildAndClassIdsInLoadingState([]);
          showToast(errors.generic, 'error');
        });
    },
    [createTransactions, dispatch]
  );

  const refetchData = () => {
    refetchExpectedSessionsFn();
    refetchOpenSpotsFn();
  };

  const handleAbsenceModalClose = () => {
    if (multipleAbsenceModalState.open) {
      setMultipleAbsenceModalState({ open: false, sessions: [], date: null });
      setSelectedSessions([]);
    } else {
      setSingleAbsenceModalState({ open: false, date: null, sessionId: null, contractId: null, accountChildId: null });
    }
  };

  const selectedClasses = data?.getClassesForCenter.filter((c) => classIds?.includes(c?.id));
  const isFuture = date ? moment.tz(date, timezone).isAfter(moment.tz(timezone)) : false;

  const dateSelectionClasses = selectedClasses?.length ? selectedClasses : data?.getClassesForCenter ?? [];
  const latestPossibleDate =
    dateSelectionClasses?.length && dateSelectionClasses.every((c) => c.endsAt)
      ? sortBy(dateSelectionClasses, 'endsAt').reverse()[0].endsAt
      : null;
  const earliestPossibleDate = dateSelectionClasses?.length
    ? sortBy(dateSelectionClasses, 'startsAt')[0].startsAt
    : null;

  const dateRangeForAttendance = (date: moment.Moment) =>
    Boolean(
      (latestPossibleDate && moment(date).tz(timezone).isAfter(moment(latestPossibleDate).tz(timezone), 'd')) ||
        (earliestPossibleDate && moment(date).tz(timezone).isBefore(moment(earliestPossibleDate).tz(timezone), 'd'))
    );

  return (
    <PageWrapper
      pageTitle="Attendance"
      badge={
        k2AttendanceRefresh ? (
          <IconButton
            icon={faArrowsRotate}
            className="ml-2"
            tooltipText={t('core.capitalize', { value: t('spelling.refresh') })}
            onClick={refetchData}
            iconSize="1x"
          />
        ) : null
      }
      mobileButtonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Attendance, permission: PermissionType.Base, level: RoleLevelType.Create }}
        >
          <CirclePlusButton variant="primary" className="my-4" onClick={() => setCreateSessionModalOpen(true)} />
        </HasRoleAreaLevel>
      }
      buttonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Attendance, permission: PermissionType.Base, level: RoleLevelType.Create }}
        >
          <CreateButton onClick={() => setCreateSessionModalOpen(true)}>Add Session</CreateButton>
        </HasRoleAreaLevel>
      }
      applyPadding={true}
    >
      <CenterSelectBanner pageName="attendance" />
      <div>
        <UtilizationTableCard
          centerId={selectedCenterId}
          timezone={timezone}
          startOfWeek={startOfWeek}
          endOfWeek={endOfWeek}
          classes={orderBy(
            data?.getClassesForCenter ?? [],
            (c) => ageToNumberOfDays(c.regulationOverride ? c.regulationOverride?.startAge : c.regulation.startAge),
            'asc'
          )}
          utilizationData={getOpenSpotsData}
          loadingUtilizationData={getOpenSpotsLoading}
          onDateChange={setDate}
          openClassOnDateLoading={openClassOnDateLoading}
          handleOpenClassOnDate={handleOpenClassOnDate}
          dateRangeForAttendance={dateRangeForAttendance}
        />
        <AttendanceViewHeader
          classIds={classIds}
          classOptions={classOptions}
          selectedClasses={selectedClasses}
          setClassId={setClassId}
          setClassIds={setClassIds}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          timezone={timezone}
          date={date}
          setDate={(date) => {
            setDate(date);
            setSelectedSessions([]);
          }}
          view={view}
          setView={changeView}
          handleGapWaiverOpen={() => setIsGapWaiverModalOpen(true)}
          dateRangeForAttendance={dateRangeForAttendance}
        />
        {selectedSessions.length === 0 ? (
          <AttendanceSearchAndFilters
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            currentDayAttendanceByStatus={currentDayAttendanceByStatus}
            showStatusFilters={view === 'TIMES' && timeRange === 'DAY'}
          />
        ) : view === 'TIMES' ? (
          <BulkActions
            isCheckInAllowed={!isFuture}
            selectedSessions={selectedSessions}
            handleCheckOut={handleCheckOut}
            handlingCheckOut={checkOutLoading}
            handleCheckIn={handleCheckIn}
            handlingCheckIn={checkInLoading}
            handleReportAbsence={handleReportAbsence}
          />
        ) : (
          <FinanceBulkActions
            selectedSessions={selectedSessions}
            handleBulkRemoveFee={(type, sessions) => handleRemoveRestoreFees(type, 'remove', sessions)}
            handleBulkRestoreFee={(type, sessions) => handleRemoveRestoreFees(type, 'restore', sessions)}
            handleBulkCharge={handleChargeSessions}
            handleBulkEditFee={handleBulkEditFee}
            isCharging={isCharging}
          />
        )}
        {!data ||
        (sessionsLoading &&
          networkStatus !== NETWORK_STATUS.SET_VARIABLES &&
          networkStatus !== NETWORK_STATUS.REFETCH) ? (
          <DataTableLoadingSkeleton />
        ) : view === 'TIMES' ? (
          timeRange === 'DAY' ? (
            <DailyAttendanceTable
              attendanceSessions={sortData(currentDayAttendanceByStatus[statusFilter])}
              centerId={selectedCenterId}
              loading={sessionsLoading}
              timezone={timezone}
              date={date}
              onSingleRowReportAbsence={(sessionId, contractId, accountChildId) =>
                setSingleAbsenceModalState({ open: true, date: null, sessionId, contractId, accountChildId })
              }
              onEditSession={(session, child) =>
                setEditSessionModalState((prev) => ({ ...prev, open: true, child, sessions: [session] }))
              }
              onClearSession={setSessionToBeCleared}
              onRemoveSession={setSessionToBeRemoved}
              handleCheckIn={handleCheckIn}
              handleCheckOut={handleCheckOut}
              selectedSessions={selectedSessions}
              updateSelectedSessions={setSelectedSessions}
              childIdsInLoadingState={checkOutLoading || checkInLoading ? childIdsInLoadingState : []}
              onSort={(field: string, direction: 'asc' | 'desc') => setSortField({ field, direction })}
              canEditAttendanceFinance={hasEditTransactionPermission}
            />
          ) : (
            <WeekAttendanceView
              loading={sessionsLoading}
              data={sortedAndFilteredData}
              timezone={timezone}
              startOfWeek={startOfWeek}
              onEditSession={(sessions, child) =>
                setEditSessionModalState((prev) => ({ ...prev, open: true, child, sessions }))
              }
              onSort={(field: string, direction: 'asc' | 'desc') => setSortField({ field, direction })}
            />
          )
        ) : timeRange === 'DAY' ? (
          <DailyAttendanceFinanceTable
            attendanceSessions={sortData(currentDayAttendanceByStatus[statusFilter])}
            loading={sessionsLoading}
            timezone={timezone}
            selectedSessions={selectedSessions}
            onRowSelect={setSelectedSessions}
            onSort={(field: string, direction: 'asc' | 'desc') => setSortField({ field, direction })}
            canEditAttendanceFinance={hasEditTransactionPermission}
            onSingleSessionRemoveFee={(type, session) => handleRemoveRestoreFees(type, 'remove', [session])}
            onSingleSessionRestoreFee={(type, session) => handleRemoveRestoreFees(type, 'restore', [session])}
            onCharge={handleChargeSessions}
            childAndClassIdsInLoadingState={chargeLoading ? childAndClassIdsInLoadingState : []}
          />
        ) : (
          <WeeklyAttendanceFinanceTable
            loading={sessionsLoading}
            data={sortedAndFilteredData}
            timezone={timezone}
            startOfWeek={startOfWeek}
            onSort={(field: string, direction: 'asc' | 'desc') => setSortField({ field, direction })}
            canEditAttendanceFinance={hasEditTransactionPermission}
            onRemoveFeeForSessions={(type, sessions) => handleRemoveRestoreFees(type, 'remove', sessions)}
            onRestoreFeeForSessions={(type, sessions) => handleRemoveRestoreFees(type, 'restore', sessions)}
            onCharge={handleChargeSessions}
            childAndClassIdsInLoadingState={chargeLoading ? childAndClassIdsInLoadingState : []}
          />
        )}
      </div>
      {createSessionModalOpen && (
        <CreateSessionModal
          centerId={selectedCenterId}
          isOpen={createSessionModalOpen}
          onClose={() => setCreateSessionModalOpen(false)}
          centerTimezone={timezone}
          enrollmentYearStart={enrollmentYearStart.format('YYYY-MM-DD')}
          enrollmentYearEnd={enrollmentYearEnd.format('YYYY-MM-DD')}
          refetchOpenSpotsData={refetchOpenSpotsFn}
          refetchExpectedSessions={refetchExpectedSessionsFn}
        />
      )}

      <ReportAbsentModal
        onSuccess={refetchData}
        isOpen={singleAbsenceModalState.open || multipleAbsenceModalState.open}
        onClose={handleAbsenceModalClose}
        absenceDate={moment(singleAbsenceModalState.date ?? multipleAbsenceModalState.date ?? date)
          .tz(timezone)
          .format('YYYY-MM-DD')}
        sessionId={singleAbsenceModalState.sessionId}
        contractId={singleAbsenceModalState.contractId}
        accountChildId={singleAbsenceModalState.accountChildId}
        isMultiple={multipleAbsenceModalState.open}
        multipleData={multipleAbsenceModalState.sessions}
      />

      <EditSessionModal
        isOpen={editSessionModalState.open}
        onClose={() => setEditSessionModalState({ open: false, child: null, sessions: [] })}
        child={editSessionModalState.child}
        tableRowSessions={editSessionModalState.sessions}
        childSessionsForWeek={
          Boolean(editSessionModalState.child)
            ? sessions.filter((s) => s.childId === editSessionModalState.child?.id)
            : []
        }
        viewingWeek={timeRange === 'WEEK'}
        timezone={timezone}
        onClearSession={setSessionToBeCleared}
        onReportAbsent={(sessionId, contractId, date, accountChildId) =>
          setSingleAbsenceModalState((prev) => ({ ...prev, open: true, date, sessionId, contractId, accountChildId }))
        }
        refetchOpenSpotsData={refetchOpenSpotsFn}
        refetchExpectedSessions={refetchExpectedSessionsFn}
        onDeleteSessionTimeEntry={(timeEntry) => setDeleteSessionTimeEntryModalState({ open: true, timeEntry })}
      />
      {sessionToBeCleared && (
        <ClearSessionModal
          isOpen={Boolean(sessionToBeCleared)}
          onSuccess={refetchData}
          onClose={() => setSessionToBeCleared(null)}
          session={sessionToBeCleared}
        />
      )}
      {sessionToBeRemoved && (
        <RemoveSessionModal
          isOpen={Boolean(sessionToBeRemoved)}
          onSuccess={refetchData}
          onClose={() => setSessionToBeRemoved(null)}
          session={sessionToBeRemoved}
          timezone={timezone}
        />
      )}

      <BulkEditFeeModal
        isOpen={bulkEditFeeModalState.open}
        onClose={(selectedSessions) => {
          setBulkEditFeeModalState((prev) => ({ ...prev, open: false, sessions: [] }));
          setSelectedSessions(selectedSessions);
        }}
        sessions={bulkEditFeeModalState.sessions}
        date={date}
        centerId={selectedCenterId}
        refetchExpectedSessions={refetchExpectedSessionsFn}
      />
      <RemoveRestoreFeeModal
        isOpen={removeRestoreModalState.open}
        onClose={(selectedSessions) => {
          setRemoveRestoreModalState((prev) => ({ ...prev, open: false, sessions: selectedSessions }));
          setSelectedSessions(selectedSessions);
        }}
        feeTypeToPerformAction={removeRestoreModalState.feeType}
        actionToPerform={removeRestoreModalState.action}
        sessions={removeRestoreModalState.sessions}
      />
      {deleteSessionTimeEntryModalState.timeEntry && (
        <DeleteSessionTimeEntryModal
          isOpen={deleteSessionTimeEntryModalState.open}
          onClose={() => setDeleteSessionTimeEntryModalState({ open: false, timeEntry: null })}
          timeEntry={deleteSessionTimeEntryModalState.timeEntry}
          timezone={timezone}
        />
      )}
      {isGapWaiverModalOpen && isAuRegion && (
        <GapWaiverModal
          data={sortedAndFilteredData}
          loading={sessionsLoading}
          isOpen={isGapWaiverModalOpen}
          classOptions={classOptions}
          classIds={classIds}
          setClassIds={setClassIds}
          date={date}
          setDate={setDate}
          selectedClasses={selectedClasses}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onClose={() => setIsGapWaiverModalOpen(false)}
          refetchData={refetchData}
        />
      )}
    </PageWrapper>
  );
};

export default Attendance;
