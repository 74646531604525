import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewEmployeeModalForm from './components/NewEmployeeModalForm';
import StaffProfilesTable from './components/StaffProfilesTable/StaffProfilesTable';
import PageWrapper from 'shared/components/PageWrapper';
import { CreateButton, CirclePlusButton } from 'shared/components/Buttons';
import useDatatableState from 'shared/hooks/useDatatableState';
import { RootState } from 'store/reducers';
import { Row, Col } from 'shared/components/Layout';
import { ISearchStaffData, useSearchStaff } from './graphql/queries';
import MangeStaffCard from './components/ManageStaffCard';
import DeactivateStaffModal from 'pages/Employees/shared/components/DeactivateStaffModal/DeactivateStaffModal';
import ReactivateStaffModal from 'pages/Employees/shared/components/ReactivateStaffModal/ReactivateStaffModal';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import {
  active,
  pending,
  deactivated,
  created,
  invited,
  requested,
} from 'shared/constants/StaffStatusSearchExpressions';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import {
  getTotalCreatedStaffSuccess,
  getTotalInvitedStaffSuccess,
  getTotalRequestedStaffSuccess,
} from './duck/actions';
import { getFullName } from 'shared/util/string';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

const { ALL, ANY } = SEARCH_EXPRESSIONS;

const Profiles = ({ ...props }) => {
  const autoOpenSideModal = props.location.state && props.location.state.autoOpenSideModal;
  const [showNewEmployeeModal, setShowNewEmployeeForm] = useState(autoOpenSideModal || false);
  const currentUser = useSelector((state: RootState) => state.user);
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const canCreateStaff = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });

  const canViewStaff = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const AdpEnabled =
    (Object.values(businessFeatures).find((x) => x.type === 'AdpVantage')?.enabled ||
      Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.enabled) ??
    false;

  const toggleNewEmployeeForm = useCallback(
    () => setShowNewEmployeeForm(!showNewEmployeeModal),
    [showNewEmployeeModal]
  );

  const [selectedStaff, setSelectedStaff] = useState<IStaff>(Object);
  const updateSelectedStaff = useCallback((staff: IStaff) => setSelectedStaff(staff), [setSelectedStaff]);

  const [reactivateModalIsVisible, setReactivateModalIsVisible] = useState(false);
  const showReactivateModal = useCallback(
    (show: boolean) => {
      setReactivateModalIsVisible(show);
    },
    [setReactivateModalIsVisible]
  );
  const [deactivateModalIsVisible, setDeactivateModalIsVisible] = useState(false);
  const showDeactivateModal = useCallback(
    (show: boolean) => {
      setDeactivateModalIsVisible(show);
    },
    [setDeactivateModalIsVisible]
  );

  const navigateToStaffProfile = useCallback(
    (staff: IStaff) =>
      props.history.push(`/employees/profiles/${staff.id}`, {
        name: getFullName(staff),
      }),
    [props.history]
  );
  const reactivateStaff = useCallback(
    (staff: IStaff) => {
      updateSelectedStaff(staff);
      showReactivateModal(true);
    },
    [updateSelectedStaff, showReactivateModal]
  );
  const deactivateStaff = useCallback(
    (staff: IStaff) => {
      updateSelectedStaff(staff);
      showDeactivateModal(true);
    },
    [updateSelectedStaff, showDeactivateModal]
  );

  const goTo = useCallback((route) => props.history.push(route), [props.history]);

  const dispatch = useDispatch();
  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'lastname.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('staff', initalTableSort);
  const createdStaff = useSearchStaff(
    { filter: created, sort: [], size: 0, from: 1 },
    {
      fetchPolicy: 'network-only',
      onCompleted: (data: ISearchStaffData) => {
        dispatch(getTotalCreatedStaffSuccess(data.searchStaff.totalResults, data.searchStaff.data));
      },
    }
  );

  const profiles = useSelector((state: RootState) => state.profiles);

  const { loading, data, networkStatus } = useSearchStaff({
    filter: {
      [ALL]: [
        ...tableState.searchExpressions,
        ...tableState.filterExpressions,
        { [ANY]: [active, pending, deactivated] },
      ],
    },
    sort: tableState.sort,
    size: tableState.pageSize,
    from: tableState.currentItemOffset,
  });
  const invitedStaff = useSearchStaff(
    { filter: invited, sort: [], size: 0, from: 1 },
    {
      fetchPolicy: 'network-only',
      onCompleted: (data: ISearchStaffData) => {
        dispatch(getTotalInvitedStaffSuccess(data.searchStaff.totalResults, data.searchStaff.data));
      },
    }
  );
  const requestedStaff = useSearchStaff(
    { filter: requested, sort: [], size: 0, from: 1 },
    {
      fetchPolicy: 'network-only',
      onCompleted: (data: ISearchStaffData) => {
        dispatch(getTotalRequestedStaffSuccess(data.searchStaff.totalResults, data.searchStaff.data));
      },
    }
  );
  return (
    <PageWrapper
      pageTitle="Employee Profiles"
      mobileButtonComponent={
        canCreateStaff && <CirclePlusButton variant="primary" className="mt-4 mb-4" onClick={toggleNewEmployeeForm} />
      }
      buttonComponent={
        canCreateStaff &&
        !AdpEnabled && (
          <CreateButton id="create-staff-btn" className="my-2" onClick={toggleNewEmployeeForm}>
            Add Employee
          </CreateButton>
        )
      }
    >
      {(canViewStaff || AdpEnabled) && (
        <Row>
          <Col>
            <MangeStaffCard
              title="Created"
              description="Employees added but not invited"
              color="sky-blue"
              number={profiles.totalCreatedStaff}
              loading={createdStaff.loading && !createdStaff.data}
              onClick={() => goTo('/employees/profiles/created')}
            />
          </Col>
          <Col>
            <MangeStaffCard
              title="Invited"
              description="Profile not completed yet"
              color="dark-gray"
              number={profiles.totalInvitedStaff}
              loading={invitedStaff.loading && !invitedStaff.data}
              onClick={() => goTo('/employees/profiles/invited')}
            />
          </Col>
          <Col>
            <MangeStaffCard
              title="Requested"
              description="Employees awaiting approval"
              color="orange"
              number={profiles.totalRequestedStaff}
              loading={requestedStaff.loading && !invitedStaff.data}
              onClick={() => goTo('/employees/profiles/requested')}
            />
          </Col>
        </Row>
      )}
      {loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES ? (
        <PageWrapperBody className="p-0">
          <DataTableLoadingSkeleton />
        </PageWrapperBody>
      ) : (
        <StaffProfilesTable
          handleRowClick={(event, rowData) => navigateToStaffProfile(rowData)}
          handleActivateStaff={reactivateStaff}
          handleDeactivateStaff={deactivateStaff}
          tableState={tableState}
          tableFunctions={tableFunctions}
          data={data}
          loading={loading}
          networkStatus={networkStatus}
        />
      )}
      {currentUser && (
        <NewEmployeeModalForm
          isOpen={showNewEmployeeModal}
          onClose={toggleNewEmployeeForm}
          currentUser={currentUser}
          navigateToStaffProfile={navigateToStaffProfile}
        />
      )}
      <DeactivateStaffModal
        staff={selectedStaff}
        isOpen={deactivateModalIsVisible}
        onClose={() => showDeactivateModal(false)}
      />
      <ReactivateStaffModal
        staff={selectedStaff}
        isOpen={reactivateModalIsVisible}
        onClose={() => showReactivateModal(false)}
      />
    </PageWrapper>
  );
};

export default Profiles;
