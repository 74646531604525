import React, { useCallback, useState, useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { addDateToTime } from 'shared/util/timeUtils';
import { TimezoneContext } from 'shared/contexts/timezoneContext';
import { useCreateTimeEntryV2, useUpdateTimeEntry } from '../TimeSheets/graphql/mutations';
import { showToast } from 'shared/components/Toast';
import TimeInput from 'shared/components/TimePicker/TimeInput';
import { Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IFormDataShape {
  positionId: string | null;
  timeIn: string | null;
  timeOut: string | null;
  note: string | null;
}

interface IProps {
  date: string;
  personId: string;
  centerId: string;
  timeEntry: ITimeEntry | null; // null would represent creating a new time entry
  positionOptions: IStaffPosition[];
  showClosePreviousOpenShiftPrompt: boolean; // this will be true if we attempt to add a new time entry for a day that has an open one
  onSuccessfulTimeEntryAdjustment: (timeEntry: ITimeEntry, clockedOutTimeEntry: ITimeEntry | null) => void;
  onCancel: () => void;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const CreateUpdateTimeEntryTableRow: React.FC<IProps> = ({
  date,
  personId,
  centerId,
  timeEntry,
  positionOptions,
  showClosePreviousOpenShiftPrompt,
  onSuccessfulTimeEntryAdjustment,
  onCancel,
  ...props
}) => {
  const { t } = useTranslation(['timelog']);
  const centerTimezoneContext = useContext(TimezoneContext);
  const [formData, setFormData] = useState<IFormDataShape>({
    positionId: timeEntry?.positionId ?? null,
    timeIn: timeEntry?.trackedTimeIn
      ? moment(timeEntry.trackedTimeIn).tz(centerTimezoneContext.timezone).format('HH:mm')
      : null,
    timeOut: timeEntry?.trackedTimeOut
      ? moment(timeEntry.trackedTimeOut).tz(centerTimezoneContext.timezone).format('HH:mm')
      : null,
    note: timeEntry?.note ?? null,
  });
  const [createTimeEntryV2Fn, { loading: createTimeEntryLoading }] = useCreateTimeEntryV2({
    onCompleted: (result) => {
      onSuccessfulTimeEntryAdjustment(result.createTimeEntryV2.timeEntry, result.createTimeEntryV2.clockedOutTimeEntry);
      showToast(t('timelog:time-card.create-time-entry-success-toast'), 'success');
    },
    onError: (err) => {
      showToast(t('timelog:time-card.create-time-entry-failure-toast'), 'error');
    },
  });
  const [updateTimeEntryFn, { loading: updateTimeEntryLoading }] = useUpdateTimeEntry({
    onCompleted: (result) => {
      onSuccessfulTimeEntryAdjustment(result.updateTimeEntry, null);
      showToast(t('timelog:time-card.update-time-entry-success-toast'), 'success');
    },
    onError: (err) => {
      showToast(t('timelog:time-card.update-time-entry-failure-toast'), 'error');
    },
  });

  const handleSave = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (timeEntry) {
        updateTimeEntryFn({
          variables: {
            input: {
              id: timeEntry.id,
              centerId: timeEntry.centerId,
              positionId: timeEntry.positionId,
              trackedTimeIn: addDateToTime(formData.timeIn, date, centerTimezoneContext.timezone)
                .tz(centerTimezoneContext.timezone)
                .toISOString(),
              trackedTimeOut: formData.timeOut
                ? addDateToTime(formData.timeOut, date, centerTimezoneContext.timezone)
                    .tz(centerTimezoneContext.timezone)
                    .toISOString()
                : undefined,
              type: timeEntry.type,
              note: formData.note,
            },
          },
        });
      } else {
        createTimeEntryV2Fn({
          variables: {
            input: {
              centerId,
              personId,
              positionId: formData.positionId as string,
              type: 'SHIFT',
              timeIn: addDateToTime(formData.timeIn, date, centerTimezoneContext.timezone)
                .tz(centerTimezoneContext.timezone)
                .toISOString(),
              timeOut: formData.timeOut
                ? addDateToTime(formData.timeOut, date, centerTimezoneContext.timezone)
                    .tz(centerTimezoneContext.timezone)
                    .toISOString()
                : null,
              note: formData.note,
            },
          },
        });
      }
    },
    [personId, centerId, date, timeEntry, formData, centerTimezoneContext, createTimeEntryV2Fn, updateTimeEntryFn]
  );

  const handleCancel = useCallback(() => {
    setFormData({
      positionId: null,
      timeIn: null,
      timeOut: null,
      note: '',
    });
    onCancel();
  }, [onCancel]);

  return (
    <form onSubmit={handleSave}>
      {showClosePreviousOpenShiftPrompt && (
        <div className="text-danger text-left mb-4">{t('timelog:time-card.must-close-previous-time-entry')}</div>
      )}
      <div className="kt-time-card-adjust-time-entry-row-content">
        <div>
          <Select
            required
            label={t('timelog:time-card.position-column')}
            options={positionOptions}
            getOptionValue={(option: IStaffPosition) => option.id}
            getOptionLabel={(option: IStaffPosition) =>
              option.positionName.replace(/Center|Centre/g, fieldLabels.center)
            }
            onChange={(opt: IStaffPosition) => setFormData((prev) => ({ ...prev, positionId: opt.id }))}
            // allow the position dropdown to overflow its parent container
            menuPortalTarget={document.body}
            disabled={timeEntry !== null}
            value={positionOptions.find((pos) => pos.id === formData.positionId)}
          />
        </div>
        <div>
          <Col>
            <div className="d-flex flex-row">
              <Form.Label>{t('timelog:time-card.in-column')}</Form.Label>
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
            <TimeInput
              isAM={true}
              value={formData.timeIn}
              onChange={(time) => setFormData((prev) => ({ ...prev, timeIn: time }))}
            />
          </Col>
        </div>
        <div>
          <Col>
            <div className="d-flex flex-row">
              <Form.Label>{t('timelog:time-card.out-column')}</Form.Label>
            </div>
            <TimeInput
              isAM={false}
              value={formData.timeOut}
              onChange={(time) => setFormData((prev) => ({ ...prev, timeOut: time }))}
            />
          </Col>
        </div>
        <div>
          <TextInput
            label={t('timelog:time-card.note-column')}
            value={formData.note}
            onChange={(value) => setFormData((prev) => ({ ...prev, note: value }))}
            disabled={Boolean(timeEntry?.note)}
          />
        </div>
        <div className="d-flex justify-content-end align-items-end">
          <Button variant="light" onClick={() => handleCancel()} className="mr-4">
            {t('timelog:time-card.cancel-btn')}
          </Button>
          <Button variant="primary" type="submit" loading={createTimeEntryLoading || updateTimeEntryLoading}>
            {t('timelog:time-card.save-btn')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateUpdateTimeEntryTableRow;
