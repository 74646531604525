import React, { Fragment, useEffect } from 'react';
import classnames from 'classnames';
import { IClassStateShape } from '../../../../../../../Centers/subroutes/Class/Class';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../store/reducers';
import { updateVisibleCenterForEnrollment } from '../../../../../../duck/actions';
// import IRegulation from 'src/shared/types/regulation.d.ts';

interface ISpotsOpenDayShape {
  am: number;
  pm: number;
}

interface ICellProps {
  day: ISpotsOpenDayShape;
  isFullDayClass: boolean;
  capacity: number;
}

interface IProps {
  capacity: number;
  isFullDayClass: boolean;
  monday: ISpotsOpenDayShape;
  tuesday: ISpotsOpenDayShape;
  wednesday: ISpotsOpenDayShape;
  thursday: ISpotsOpenDayShape;
  friday: ISpotsOpenDayShape;
}
const whiteSpace = <span>&nbsp;</span>;
const Cell: React.FC<ICellProps> = ({ day, isFullDayClass, capacity, ...props }) => {
  return (
    <Fragment>
      <td
        colSpan={isFullDayClass ? 2 : 1}
        className={classnames({
          'text-dark': day.am,
        })}
      >
        {whiteSpace}
        {capacity - day.am}/{capacity}
        {whiteSpace}
      </td>
      {!isFullDayClass && (
        <td
          colSpan={1}
          className={classnames({
            'text-dark': day.pm,
          })}
        >
          {whiteSpace}
          {capacity - day.pm}/{capacity}
          {whiteSpace}
        </td>
      )}
    </Fragment>
  );
};

const SpotsOpenRow: React.FC<IProps> = ({
  isFullDayClass,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  capacity,
  ...props
}) => {
  return (
    <tr className="spots-open">
      <td />
      <td colSpan={2} className="text-right">
        Total Enrolled:
      </td>
      <Cell day={monday} isFullDayClass={isFullDayClass} capacity={capacity} />
      <Cell day={tuesday} isFullDayClass={isFullDayClass} capacity={capacity} />
      <Cell day={wednesday} isFullDayClass={isFullDayClass} capacity={capacity} />
      <Cell day={thursday} isFullDayClass={isFullDayClass} capacity={capacity} />
      <Cell day={friday} isFullDayClass={isFullDayClass} capacity={capacity} />
      <td className={classnames({ 'full-day-open-spots': isFullDayClass })} />
    </tr>
  );
};

export default SpotsOpenRow;
