import React, { useCallback, useMemo } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { getCenterSuccess } from 'pages/Centers/duck/actions';
import { GET_CENTER_PROFILE } from './graphql/fields';
import { useGetCenter } from 'gql/center/queries';
import ProfileTab from './components/ProfileTab/ProfileTab';
import SpacesTab from './components/SpacesTab/SpacesTab';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { faFileInvoiceDollar, faFileChartLine } from '@fortawesome/pro-light-svg-icons';
import { useUpdateCenterAvatar } from 'pages/Centers/subroutes/Profile/graphql/mutations';
import PermissionActions from 'shared/constants/enums/actionEnum';
import { dataType } from 'shared/constants/enums/dataTypeEnum';
import CcssDetailsTab from './components/CcssDetailsTab/CcssDetailsTab';
import { isRegion } from 'shared/util/region';
import BillingTab from './components/BillingTab';
import KTAdminTab from './components/KTAdminTab';
import StatementTab from './components/StatementTab/StatementTab';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { RoleLevelType, AreaType, PermissionType } from 'shared/constants/enums/permissionsEnums';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IRouteProps {
  id?: string;
}

interface IProps extends RouteComponentProps<IRouteProps, any, {}> {}

// FUTURE refactor - fix the typing of useUpdateCenterAvatar so the ts-ignore's can be removed

const Profile: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { k2NewUpdateCenter } = useFlags();

  const hasBaseCenterRead = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasBaseCenterEdit = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const hasBaseCenterSpacesRead = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Spaces,
    level: RoleLevelType.Read,
  });
  const hasReadCenterBilling = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.BillingCycle,
    level: RoleLevelType.Read,
  });

  const canCreateStatements =
    useHasRoleAreaLevel({ area: AreaType.Statement, permission: PermissionType.Base, level: RoleLevelType.Create }) ??
    false;

  const user = useSelector((state: RootState) => state.user);
  const centerId = props.match.params.id ?? '';

  const { data, loading, error } = useGetCenter(
    {
      variables: {
        id: centerId,
      },
      onCompleted: (data) => {
        dispatch(getCenterSuccess(data.getCenter));
      },
    },
    GET_CENTER_PROFILE
  );

  const center = data?.getCenter;
  // @ts-ignore
  const [updateAvatar, { loading: avatarLoading }] = useUpdateCenterAvatar();

  const hasViewCcssAccess = user?.hasPermission(PermissionActions.VIEW_CCSS) ?? false;

  const getSubtitle = useCallback(() => {
    if (center) {
      if (user?.isInternal) {
        return center.id;
      } else if (center.address && center.address.state) {
        return `${center.address.city}, ${center.address.state.split('-')[1]}`;
      }
    }

    return '';
  }, [center, user]);

  const canEditFormInformation = useCallback(() => {
    if (center && user) {
      return user.hasPermission(PermissionActions.UPDATE_CENTER) && !center.deactivatedAt;
    }

    return false;
  }, [center, user]);

  const tabPermissions = useMemo(() => {
    if (user) {
      return {
        profile: {
          view: user.hasPermission(PermissionActions.VIEW_CENTER),
          full: user.hasPermission(PermissionActions.UPDATE_CENTER),
        },
        // this will be expanded to additionally check a permission action. right now just check for the feature flag
        billing: {
          view: true,
          full: true,
        },
        ccss: {
          view: isRegion('AU') && user.hasPermission(PermissionActions.VIEW_CCSS),
          full: isRegion('AU') && user.hasPermission(PermissionActions.FULL_CCSS),
        },
        ktAdmin: {
          view: user.isInternal ?? false,
        },
      };
    }

    return {
      profile: {
        view: false,
        full: false,
      },
      billing: {
        view: false,
        full: false,
      },
      ccss: {
        view: false,
        full: false,
      },
      ktAdmin: {
        view: false,
      },
    };
  }, [user]);

  return (
    <ProfilePageWrapper
      loading={loading}
      avatarLoading={avatarLoading}
      title={center ? center.name.toUpperCase() : ''}
      subtitle={getSubtitle()}
      readOnlyAvatar={!hasBaseCenterEdit || (center ? Boolean(center.deactivatedAt) : false)}
      dataTypeLabel={center?.entity?.dataType ? dataType[center.entity.dataType] : dataType.PROD}
      onAvatarClick={(file) => {
        // @ts-ignore
        updateAvatar({ id: center.id, avatar: file, useNewUpdate: k2NewUpdateCenter ? true : false });
      }}
      avatarUrl={center && center.avatar && center.avatar.url}
      additionalActions={[
        { icon: faFileInvoiceDollar, tooltip: 'tbd', onClick: () => {} },
        { icon: faFileChartLine, tooltip: 'tbd', onClick: () => {} },
      ]}
    >
      {error && <Alert variant="danger">{error.message}</Alert>}
      <Tabs id="center-profile-tab-group" defaultActiveKey="profile">
        {hasBaseCenterRead && (
          <Tab eventKey="profile" title="Profile">
            <ProfileTab center={center} canPerformEdit={canEditFormInformation()} loading={loading} />
          </Tab>
        )}
        {isRegion('AU') && hasViewCcssAccess && !!center && (
          <Tab eventKey="ccssDetails" title="CCSS Details">
            <CcssDetailsTab center={center}></CcssDetailsTab>
          </Tab>
        )}
        {hasReadCenterBilling && (
          <Tab eventKey="billing" title="Billing">
            <BillingTab center={center} />
          </Tab>
        )}
        {hasBaseCenterSpacesRead && (
          <Tab eventKey="spaces" title="Spaces">
            <SpacesTab
              // @ts-ignore
              center={center}
            />
          </Tab>
        )}
        {tabPermissions.ktAdmin.view && (
          <Tab eventKey="ktAdmin" title="KT Admin">
            <KTAdminTab center={center} />
          </Tab>
        )}
        {isRegion('AU') && canCreateStatements && (
          <Tab eventKey="statement" title="Statement">
            <StatementTab
              // @ts-ignore
              center={center}
            ></StatementTab>
          </Tab>
        )}
      </Tabs>
    </ProfilePageWrapper>
  );
};

export default Profile;
