import React, { Fragment } from 'react';
import RBCard from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import ActionDropdown from 'shared/components/ActionDropdown';
import { Box, Line } from 'shared/components/LoadingSkeletons';
import { capitalize } from 'shared/util/string';
import ColoredBackgroundTag from '../../../shared/components/Tag/ColoredBackgroundTag';
import InvertedColoredBackgroundTag from '../../../shared/components/Tag/InvertedColoredBackgroundTag';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {
  role?: IRole;
  loading?: boolean;
  canEdit?: boolean;
  onEdit?: (role: IRole) => void;
  onDelete?: (role: IRole) => void;
}

const RoleCard: React.FC<IProps> = ({
  role,
  loading = false,
  canEdit = false,
  onEdit = () => {},
  onDelete = () => {},
  ...props
}) => {
  const { k2AddRoleTypes } = useFlags();
  const { t } = useTranslation();

  // React.useEffect(() => {
  //   console.log('role->> ', role);
  // }, [role]);

  const hasUsersAssigned = role?.staff?.length && role.staff?.length > 0 ? true : false;

  return (
    <RBCard className="kt-role-hierarchy-card">
      <RBCard.Header>
        <div
          className="d-flex align-items-center"
          style={{ justifyContent: 'space-between', height: 'fit-content', fontWeight: 'normal' }}
        >
          {role?.scopeType === 'ENTITY' ? (
            <div style={{ alignItems: 'center' }}>
              <ColoredBackgroundTag
                text="Business"
                color="#273143"
                className="2px-solid-#273143"
              ></ColoredBackgroundTag>
            </div>
          ) : (
            <div style={{ alignItems: 'center', height: 'fit-content' }}>
              <InvertedColoredBackgroundTag
                text={_.capitalize(t('spelling.center'))}
                color="#273143"
                className="inverted-kt-role-tag"
              ></InvertedColoredBackgroundTag>
            </div>
          )}
          <div className="w-100 kt-role-hierarchy-card-header-label d-flex flex-column align-items-center">
            {loading ? (
              <div className="w-75">
                <Line />
              </div>
            ) : (
              <Fragment>
                {role?.name ?? ''}
                {role?.isIntegrationRole && (
                  <div className="w-50 kt-role-hierarchy-card-all-permissions-badge">
                    Import users from integrations into this role
                  </div>
                )}
                {role?.hasAllPermissions && (
                  <div className="w-50 kt-role-hierarchy-card-all-permissions-badge">All permissions granted</div>
                )}
                {role?.areaLevels.every((al) => al.level === 0) && (
                  <div className="w-50 kt-role-hierarchy-card-no-permissions-badge">No permissions granted</div>
                )}
              </Fragment>
            )}
          </div>
          {/* *** Delete when removing LD *** */}
          {canEdit && !k2AddRoleTypes && (
            <div>
              <ActionDropdown
                actions={[
                  {
                    label: 'Edit',
                    onClick: () => onEdit(role as IRole),
                    icon: faPencil,
                  },
                ]}
              />
            </div>
          )}
          {/* *** */}
          {(role?.isIntegrationRole ||
            (canEdit && k2AddRoleTypes && !role?.isAccountOwnerRole && hasUsersAssigned)) && (
            <div>
              <ActionDropdown
                actions={[
                  {
                    label: 'Edit',
                    onClick: () => onEdit(role as IRole),
                    icon: faPencil,
                  },
                  {
                    label: 'Delete',
                    onClick: () => console.log('Disabled Delete Click'),
                    icon: faTrashAlt,
                    disabled: true,
                  },
                ]}
              />
            </div>
          )}
          {canEdit && k2AddRoleTypes && !role?.isAccountOwnerRole && !hasUsersAssigned && !role?.isIntegrationRole && (
            <div>
              <ActionDropdown
                actions={[
                  {
                    label: 'Edit',
                    onClick: () => onEdit(role as IRole),
                    icon: faPencil,
                  },
                  {
                    label: 'Delete',
                    onClick: () => onDelete(role as IRole),
                    icon: faTrashAlt,
                  },
                ]}
              />
            </div>
          )}
        </div>
      </RBCard.Header>
      <RBCard.Body className="px-4 py-0">
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-column flex-1 py-4 kt-role-hierarchy-card-info-section kt-role-hierarchy-card-info-section-with-border">
            <label>{capitalize(t('spelling.users'))}</label>
            <div className="d-flex flex-row align-items-center justify-content-center">
              <FontAwesomeIcon icon={faUser} size="sm" />
              {loading ? (
                <Box height={24} width={24} className="ml-2" />
              ) : (
                <Link to={{ pathname: `/settings/roles/${role?.id}`, state: { role, view: 'staff' } }} className="ml-2">
                  {role?.assignedStaffIds.length ?? 0}
                </Link>
              )}
            </div>
          </div>
          <div className="d-flex flex-column flex-1 py-4 kt-role-hierarchy-card-info-section">
            <label>{capitalize(t('spelling.permissions'))}</label>
            <div className="d-flex flex-row align-items-center justify-content-center">
              <FontAwesomeIcon icon={faLock} size="sm" />
              {loading ? (
                <Box height={24} width={24} className="ml-2" />
              ) : (
                <Link
                  to={{ pathname: `/settings/roles/${role?.id}`, state: { role, view: 'permissions' } }}
                  className="ml-2"
                >
                  View
                </Link>
              )}
            </div>
          </div>
        </div>
      </RBCard.Body>
    </RBCard>
  );
};

export default RoleCard;
