import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { useDeleteFreeKindyForecast, useDeleteKindyForAllForecast } from 'gql/kindyForAll/mutations';
import { IKindyForAllCenterSummary } from 'shared/types/kindyForAll';

interface IProps {
  isOpen: boolean;
  forecast: IKindyForAllCenterSummary;
  onClose: () => void;
  refetchCenterSummaries: () => void;
}

const DeleteForecastModal: React.FC<IProps> = ({ isOpen, forecast, onClose, refetchCenterSummaries, ...props }) => {
  const [deleteKindyForAllForecastFn, { loading: kindyForAllLoading }] = useDeleteKindyForAllForecast({
    onCompleted: (result) => {
      showToast('Forecast deleted successfully.', 'success');
      refetchCenterSummaries();
      onClose();
    },
    onError: (err) => {
      if (err.graphQLErrors.find((x) => x.extensions?.exception?.response?.message === 'Forecast is not submitted.')) {
        showToast('Forecast is not submitted.', 'error');
      } else if (
        err.graphQLErrors.find(
          (x) => x.extensions?.exception?.response?.message === 'Forecast is unable to be deleted.'
        )
      ) {
        showToast('Forecast is unable to be deleted.', 'error');
      } else {
        showToast('An error occurred. Please try again later.', 'error');
      }
      refetchCenterSummaries();
      onClose();
    },
  });

  const [deleteFreeKindyForecastFn, { loading: freeKindyLoading }] = useDeleteFreeKindyForecast({
    onCompleted: (result) => {
      showToast('Forecast deleted successfully.', 'success');
      refetchCenterSummaries();
      onClose();
    },
    onError: (err) => {
      if (err.graphQLErrors.find((x) => x.extensions?.exception?.response?.message === 'Forecast is not submitted.')) {
        showToast('Forecast is not submitted.', 'error');
      } else if (
        err.graphQLErrors.find(
          (x) => x.extensions?.exception?.response?.message === 'Forecast is unable to be deleted.'
        )
      ) {
        showToast('Forecast is unable to be deleted.', 'error');
      } else {
        showToast('An error occurred. Please try again later.', 'error');
      }
      refetchCenterSummaries();
      onClose();
    },
  });

  const handleConfirm = useCallback(() => {
    if (forecast.year < 2024)
      deleteKindyForAllForecastFn({
        variables: {
          input: {
            centerId: forecast.centerId,
            year: forecast.year,
            period: forecast.period,
          },
        },
      });
    else
      deleteFreeKindyForecastFn({
        variables: {
          input: {
            centerId: forecast.centerId,
            year: forecast.year,
            period: forecast.period,
          },
        },
      });
  }, [deleteFreeKindyForecastFn, deleteKindyForAllForecastFn, forecast]);

  return (
    <ConfirmationModal
      title="Are you sure?"
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Delete"
      primaryCallback={handleConfirm}
      primaryButtonProps={{ variant: 'danger', loading: kindyForAllLoading || freeKindyLoading }}
    >
      <p>
        Are you sure you want to delete the submitted forecast for {forecast.centerName}, year {forecast.year}, term
        {forecast.period.replace('P', '')}?
      </p>
    </ConfirmationModal>
  );
};

export default DeleteForecastModal;
