import { ApplicationStage } from 'generated/graphql';
import { getI18n } from 'react-i18next';
import { ApplicationScheduleOfferState } from 'generated/graphql';

function t(flowType: string, key: string): string {
  const i18n = getI18n();
  return i18n.getResource('en', 'translation', `enrollment.lead-management.tabs.${flowType}.${key}`);
}

// the `New` tab for normal flow is `ApplicationStage.Waitlisted`
export const NEW_TAB_FOR_NORMAL_FLOW = ApplicationStage.Waitlisted;
// the `New` tab for program flow is `ApplicationStage.Offered`

export const PROGRAM_FLOW_TABS: {
  [key: string]: string;
} = [
  ApplicationStage.InProgress,
  ApplicationStage.Accepted,
  ApplicationStage.Waitlisted,
  ApplicationStage.Completed,
  ApplicationStage.Lost,
  ApplicationStage.Errored,
].reduce<{ [key: string]: string }>((acc, curr) => {
  acc[curr] = t('program-flow', curr);
  return acc;
}, {});

export const NORMAL_FLOW_TABS: {
  [key: string]: string;
} = [
  NEW_TAB_FOR_NORMAL_FLOW,
  ApplicationStage.Offered,
  ApplicationStage.Accepted,
  ApplicationStage.Completed,
  ApplicationStage.Lost,
  ApplicationStage.Errored,
].reduce<{ [key: string]: string }>((acc, curr) => {
  acc[curr] = t('normal-flow', curr);
  return acc;
}, {});

// ASO stands for application schedule offer
export const STAGE_TO_ASO_STATE_MAPPING_FOR_NORMAL_FLOW = {
  [ApplicationStage.Waitlisted]: [ApplicationScheduleOfferState.New],
  [ApplicationStage.Offered]: [ApplicationScheduleOfferState.New, ApplicationScheduleOfferState.OfferedWaitlist],
  [ApplicationStage.Accepted]: [
    ApplicationScheduleOfferState.Accepted,
    ApplicationScheduleOfferState.AcceptedWaitlist,
    ApplicationScheduleOfferState.ApprovalWaitlistPending,
    ApplicationScheduleOfferState.ApprovalPending,
  ],
  [ApplicationStage.Completed]: [ApplicationScheduleOfferState.Completed, ApplicationScheduleOfferState.Submitted],
  [ApplicationStage.Lost]: [ApplicationScheduleOfferState.Lost, ApplicationScheduleOfferState.Rejected],
  [ApplicationStage.Errored]: [ApplicationScheduleOfferState.Errored],
};

export const STAGE_TO_ASO_STATE_MAPPING_FOR_PROGRAM_FLOW = {
  [ApplicationStage.Offered]: [ApplicationScheduleOfferState.Accepted, ApplicationScheduleOfferState.AcceptedWaitlist],
  [ApplicationStage.Accepted]: [ApplicationScheduleOfferState.ApprovalPending],
  [ApplicationStage.Waitlisted]: [ApplicationScheduleOfferState.ApprovalWaitlistPending],
  [ApplicationStage.Completed]: [ApplicationScheduleOfferState.Completed, ApplicationScheduleOfferState.Submitted],
  [ApplicationStage.Lost]: [ApplicationScheduleOfferState.Lost, ApplicationScheduleOfferState.Rejected],
  [ApplicationStage.Errored]: [ApplicationScheduleOfferState.Errored],
  [ApplicationStage.InProgress]: [
    ApplicationScheduleOfferState.Accepted,
    ApplicationScheduleOfferState.AcceptedWaitlist,
    ApplicationScheduleOfferState.OfferedWaitlist,
    ApplicationScheduleOfferState.New,
  ],
};

export const APPROVAL_PENDING_BULK_APPROVAL_CAN_APPROVE_STATE = [ApplicationScheduleOfferState.ApprovalPending];

export const WAITLIST_BULK_APPROVAL_CAN_APPROVE_STATE = [ApplicationScheduleOfferState.ApprovalWaitlistPending];
