import React from 'react';
import { Col, Row } from 'react-bootstrap';
import EnableBillingCard from './Cards/EnableBillingCard';

interface IProps {
  center: ICenter | undefined;
}

const BillingTab: React.FC<IProps> = ({ center, ...props }) => {
  return (
    <Row>
      <Col xs={12} sm={6} md={5}>
        {center && <EnableBillingCard center={center} />}
      </Col>
    </Row>
  );
};

export default BillingTab;
