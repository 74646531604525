import React from 'react';
import BSSpinner from 'react-bootstrap/Spinner';
import './spinner.scss';

interface IProps {
  small?: boolean;
  large?: boolean;
  className?: string;
}

const Spinner: React.FC<IProps> = ({ small, large, className }) => {
  return (
    <BSSpinner
      id="spinner"
      className={`${className} ${small ? 'spinner-small' : ''} ${large ? 'spinner-large' : ''}`}
      animation="border"
      role="status"
      variant="secondary"
    >
      <span className="sr-only">Loading...</span>
    </BSSpinner>
  );
};

export default Spinner;
