import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import Checkbox from 'shared/components/Checkbox';
import type { IGapWaiveEligibilityMap } from 'shared/hooks/useGapWaiveEligibleChecker';

interface ISessionAbsenceHeaderProps {
  dayOfWeek: number;
  allAbsentSessionsOfWeek: ISession[];
  gapWaiveEligibilityMap: IGapWaiveEligibilityMap;
  timezone: string;
  checkboxValues: { [key: string]: boolean };
  handleCheckboxChange: (session: ISession | undefined, value: boolean) => void;
}

const SessionAbsenceHeader: React.FC<ISessionAbsenceHeaderProps> = ({
  dayOfWeek,
  allAbsentSessionsOfWeek,
  gapWaiveEligibilityMap,
  timezone,
  checkboxValues,
  handleCheckboxChange,
}) => {
  const filteredAbsentSessions = useMemo(
    () =>
      allAbsentSessionsOfWeek.filter((s: ISession) => {
        const sessionDate = moment(s.date, 'YYYY-MM-DD');
        const zonedSessionDate = moment().tz(timezone).set({
          year: sessionDate.year(),
          month: sessionDate.month(),
          date: sessionDate.date(),
        });
        const isSameDay = zonedSessionDate.day() === dayOfWeek;
        const hasWaived = s.absence?.waiveGapFee;
        const isEligibleToWaive = gapWaiveEligibilityMap[s.id || '']?.isWaivable;
        return isSameDay && !hasWaived && isEligibleToWaive;
      }),
    [allAbsentSessionsOfWeek, dayOfWeek, timezone, gapWaiveEligibilityMap]
  );

  const isAllChecked = useMemo(() => {
    return (
      filteredAbsentSessions.length > 0 &&
      filteredAbsentSessions.map((s: ISession) => checkboxValues[s.id]).every((v) => v)
    );
  }, [filteredAbsentSessions, checkboxValues]);

  const isDisabled = filteredAbsentSessions.length === 0;

  const handleCheckAll = useCallback(() => {
    if (isAllChecked) {
      filteredAbsentSessions.forEach((session) => handleCheckboxChange(session, false));
    } else {
      filteredAbsentSessions.forEach((session) => handleCheckboxChange(session, true));
    }
  }, [isAllChecked, filteredAbsentSessions, handleCheckboxChange]);

  return (
    <Checkbox
      className="gap-waiver-table--cb-header"
      label={`All`}
      disabled={isDisabled}
      value={isAllChecked}
      onChange={handleCheckAll}
    />
  );
};

export default SessionAbsenceHeader;
