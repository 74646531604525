import React from 'react';
import * as Mui from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../store/reducers';
import { useGetEnrollmentAvailability } from '../../../../../../../../gql/enrollment/queries';
import { isEmpty, isNil } from 'lodash';

interface IProps {
  availability: IClassAvailability[];
  isFullDayClass: boolean;
}

const EnrollmentPeriodTab: React.FC<IProps> = ({ availability, isFullDayClass, ...props }) => {
  // new Date(b) - new Date(a) is only valid in JavaScript and not TypeScript when using to sort
  const start = moment().format('YYYY-MM-DD');
  const end = useSelector((state: RootState) => state.enrollment.activeClass?.endsAt);
  const activeClass = useSelector((state: RootState) => state.enrollment.activeClass?.id);

  const activeCenter = useSelector((state: RootState) => state.enrollment.activeCenter.id);

  const includeFutureContracts = useSelector((state: RootState) => state.enrollment.includeFutureContracts);
  const includeLtbOffers = useSelector((state: RootState) => state.enrollment.includeLtbOffers);

  const { data } = useGetEnrollmentAvailability({
    skip:
      isNil(activeCenter) ||
      isNil(activeClass) ||
      isEmpty(activeCenter) ||
      isEmpty(activeClass) ||
      !moment(start).isValid(),
    variables: {
      input: {
        centerId: activeCenter,
        classId: activeClass,
        startDate: start,
        endDate: end,
        includeLtbOfferings: includeLtbOffers,
        includeFutureContracts: includeFutureContracts,
      },
    },
  });

  const amPmCell = [
    ['AM', 'PM'],
    ['AM', 'PM'],
    ['AM', 'PM'],
    ['AM', 'PM'],
    ['AM', 'PM'],
  ];

  return (
    <>
      <Mui.TableContainer>
        <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
          <Mui.TableHead>
            <Mui.TableRow sx={{ backgroundColor: isFullDayClass ? '#F1F4F8' : 'white' }}>
              <Mui.TableCell sx={{ padding: 0, fontWeight: 'bold' }} align="center">
                Mon
              </Mui.TableCell>
              <Mui.TableCell sx={{ padding: 0, fontWeight: 'bold' }} align="center">
                Tue
              </Mui.TableCell>
              <Mui.TableCell sx={{ padding: 0, fontWeight: 'bold' }} align="center">
                Wed
              </Mui.TableCell>
              <Mui.TableCell sx={{ padding: 0, fontWeight: 'bold' }} align="center">
                Thur
              </Mui.TableCell>
              <Mui.TableCell sx={{ padding: 0, fontWeight: 'bold' }} align="center">
                Fri
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          {!isFullDayClass && (
            <Mui.TableRow sx={{ backgroundColor: '#F1F4F8', height: '5%' }}>
              {amPmCell?.map((row) => (
                <Mui.TableCell sx={{ padding: 0 }} align="center">
                  <Mui.Typography
                    color="black"
                    fontWeight="bold"
                    display="flex"
                    marginX="3rem"
                    justifyContent="space-evenly"
                  >
                    <span>{row[0]}</span>
                    {row[1]}
                  </Mui.Typography>
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          )}
          <Mui.TableBody>
            <Mui.TableRow>
              {data?.getEnrollmentAvailability.availabilityByDayOfWeek.slice(0, 5).map((num, index) => (
                <Mui.TableCell align="center">
                  {isFullDayClass ? (
                    <Mui.Typography fontWeight="bold" align="center">
                      {num.totalOpenSpots}
                    </Mui.Typography>
                  ) : (
                    <Mui.Typography display="flex" fontWeight="bold" marginX="2rem" justifyContent="space-evenly">
                      <span>{num.openSpotsAm}</span> {num.openSpotsPm}
                    </Mui.Typography>
                  )}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
    </>
  );
};

export default EnrollmentPeriodTab;
