import React, { useCallback, useEffect, useState } from 'react';
import TextInput from 'shared/components/TextInput';
import { Col, Row } from 'shared/components/Layout';
import AddressInput from 'shared/components/AddressInput';
import errorMessage from 'shared/constants/errorMessages';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import DateInput from 'shared/components/DateInput';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import moment from 'moment';
import Select from 'shared/components/Select';
import { useUpdateChildPersonalInfo } from '../../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import CrnInput from 'shared/components/CrnInput';
import { isRegion } from 'shared/util/region';
import { capitalize, getFullName } from 'shared/util/string';
import { ApolloError } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import i18n from 'i18next';

const ADDRESS_OBJECT = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: 'US',
  postalCode: '',
};

interface IProps {
  child: IChild;
  readOnly: boolean;
}

export const gradeStatusOptions = [
  { value: 'PREK', label: i18n.t('child.grades.prek') },
  { value: 'PREP', label: i18n.t('child.grades.prep') },
  { value: 'FIRST', label: '1' },
  { value: 'SECOND', label: '2' },
  { value: 'THIRD', label: '3' },
  { value: 'FOURTH', label: '4' },
  { value: 'FIFTH', label: '5' },
  { value: 'SIXTH', label: '6' },
  { value: 'SEVENTH', label: '7' },
];

const PersonalInformation: React.FC<IProps> = ({ child: savedChild, readOnly }) => {
  const [child, updateChild] = useState(savedChild);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [updateChildPersonalInfo, { loading }] = useUpdateChildPersonalInfo();
  const [isCrnValid, setCrnValid] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    !formIsDirty && updateChild(savedChild);
  }, [formIsDirty, savedChild]);

  const handleChange = useCallback(
    (value: any, name: string) => {
      updateChild({ ...child, [name]: value });
      setFormIsDirty(true);
    },
    [child]
  );

  const handleCrnChange = useCallback(
    (value: any, name: string) => {
      updateChild((prev) => ({
        ...prev,
        ccssAttributes: {
          ...prev.ccssAttributes,
          crn: value,
          isCrnEditable: prev.ccssAttributes?.isCrnEditable ?? true,
        },
      }));
      setFormIsDirty(true);
    },
    [child]
  );

  const isAuRegion = isRegion('AU');

  const indigenousStatusOptions = [
    { value: 'NotIndicated', label: t('child.indigenous-types.not-indicated') },
    { value: 'Aboriginal', label: t('child.indigenous-types.aboriginal') },
    { value: 'TorresStraitIslander', label: t('child.indigenous-types.torres-strait-islander') },
    { value: 'BothAboriginalTorresStraitIslander', label: t('child.indigenous-types.both') },
    { value: 'NeitherAboriginalTorresStraitIslander', label: t('child.indigenous-types.neither') },
  ];

  const genderOptions = [
    { value: 'NotIndicated', label: t('child.gender.not-indicated') },
    { value: 'Female', label: t('child.gender.female') },
    { value: 'Male', label: t('child.gender.male') },
    { value: 'Other', label: t('child.gender.other') },
  ];

  const save = useCallback(() => {
    const ccssAttributes: IChildCcssAttributesInput = {
      crn: child.ccssAttributes?.crn,
    };

    updateChildPersonalInfo({
      variables: {
        input: {
          id: child.id,
          firstname: child.firstname,
          lastname: child.lastname,
          nickname: child.nickname,
          residesWithId: child.residesWith?.id,
          dob: child.dob,
          grade: child.grade,
          childCcssAttributes: isAuRegion ? ccssAttributes : undefined,
          indigenousStatus: isAuRegion ? child.indigenousStatus : 'NotIndicated',
          gender: child.gender ?? 'NotIndicated',
        },
      },
    })
      .then(() => {
        showToast('Child updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch((error: Error) => {
        const message = error instanceof ApolloError ? getApolloErrorMessage(error) : error.message;
        showToast(message, 'error');
      });
  }, [child, updateChildPersonalInfo]);

  return (
    <FormWrapper2
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      onSave={save}
      onCancel={() => {
        updateChild(savedChild);
      }}
      loading={loading}
      saveDisabled={!child.firstname || !child.lastname || !child.dob || !isCrnValid}
    >
      <Row align="start">
        <Col>
          <TextInput
            required
            label="First Name"
            value={child.firstname}
            onChange={(value) => handleChange(value, 'firstname')}
            errorText={errorMessage.genericFirstName}
            disabled={readOnly}
          />
        </Col>
        <Col>
          <TextInput
            required
            label="Last Name"
            value={child.lastname}
            onChange={(value) => handleChange(value, 'lastname')}
            errorText={errorMessage.genericLastName}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <TextInput
            label="Preferred Name"
            value={child.nickname || ''}
            onChange={(value) => handleChange(value, 'nickname')}
            disabled={readOnly}
            helpTooltipText="First name displayed throughout Kangarootime"
            helpTooltipTextDirection="top"
          />
        </Col>
        {isAuRegion && (
          <Col sm={6}>
            <Select
              label={t('spelling.indigenousStatus')}
              options={indigenousStatusOptions}
              getOptionLabel={(g) => g.label}
              getOptionValue={(g) => g.value}
              value={child.indigenousStatus}
              onChange={(option) => handleChange(option.value, 'indigenousStatus')}
              disabled={readOnly}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={4} className="mb-2">
          <DateInput
            required
            label="Date of Birth"
            date={child.dob ?? null}
            onDateSelect={(value) => handleChange(value, 'dob')}
            dateOnly
            disabled={readOnly}
          />
        </Col>
        <Col sm={2}>
          <div className="mt-3">{getAgeStringFromDateOfBirth(moment(child.dob))}</div>
        </Col>
        <Col sm={6}>
          <Select
            label={t('spelling.gender')}
            options={genderOptions}
            getOptionLabel={(g) => g.label}
            getOptionValue={(g) => g.value}
            value={child.gender}
            onChange={(option) => handleChange(option.value, 'gender')}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <Select
            label={capitalize(t('spelling.grade'))}
            options={gradeStatusOptions}
            getOptionLabel={(g) => g.label}
            getOptionValue={(g) => g.value}
            value={child.grade}
            onChange={(option) => handleChange(option.value, 'grade')}
            disabled={readOnly}
          />
        </Col>
        <Col sm={4}>
          {isAuRegion && (
            <CrnInput
              label="CRN"
              value={child.ccssAttributes?.crn ?? ''}
              onChange={(value) => handleCrnChange(value, 'crn')}
              disabled={readOnly || !(child.ccssAttributes?.isCrnEditable ?? true)}
              onValidityChanged={(value: boolean) => setCrnValid(value)}
            />
          )}
          {/* TODO: status */}
        </Col>

        <Col sm={6}>
          <Select
            label="Select the contact this child lives with"
            options={child.contacts}
            getOptionLabel={(g) => getFullName(g)}
            getOptionValue={(g) => g.id}
            value={child.contacts.find((c) => c.id === child.residesWith?.id)}
            onChange={(value) => handleChange(value, 'residesWith')}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <AddressInput
        required={false}
        address={child.residesWith?.address ?? ADDRESS_OBJECT}
        onChange={(value: IAddress) => {}}
        disabled={true}
        errorText={errorMessage.address.generic}
        showHelperText={false}
      />
    </FormWrapper2>
  );
};

export default PersonalInformation;
