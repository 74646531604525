import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { billingCycleFields, billingCycleTemplateFields } from './fields';
import { GET_BILLING_CYCLE_TEMPLATES } from './queries';

//interfaces
interface IUpdateBillingCycleSessionData {
  updateBillingCycleTemplate: IBillingCycleTemplate;
}

interface IUpdateBillingCycleSessionVariables {
  input: IUpdateBillingCycleTemplateInput;
}

interface IDeleteBillingCycleSessionData {
  deleteBillingCycleTemplate: IBillingCycleTemplate;
}

interface IDeleteBillingCycleSessionVariables {
  id: string;
}

interface ICreateAccountBillingCycleData {
  createAccountBillingCycle: IBillingCycle;
}

interface ICreateAccountBillingCycleVariables {
  input: ICreateAccountBillingCycleInput;
}

interface IUpdateAccountBillingCycleData {
  updateAccountBillingCycle: IBillingCycle;
}

interface IUpdateAccountBillingCycleVariables {
  input: IUpdateAccountBillingCycleInput;
}

//mutations

export const UPDATE_BILLING_CYCLE_TEMPLATE = (fields: string = billingCycleTemplateFields) => gql`
  mutation($input: UpdateBillingCycleTemplateInput!) {
    updateBillingCycleTemplate(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_BILLING_CYCLE_TEMPLATE = (fields: string = billingCycleTemplateFields) => gql`
  mutation($id: ID!) {
    deleteBillingCycleTemplate(id: $id) {
      ${fields}
    }
  }
`;

export const CREATE_ACCOUNT_BILLING_CYCLE = (fields: string = billingCycleFields) => gql`
  mutation($input: CreateAccountBillingCycleInput!) {
    createAccountBillingCycle(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_ACCOUNT_BILLING_CYCLE = (fields: string = billingCycleFields) => gql`
  mutation($input: UpdateAccountBillingCycleInput!) {
    updateAccountBillingCycle(input: $input) {
      ${fields}
    }
  }
`;

// CREATE BILLING CYCLE TEMPLATE
interface ICreateBillingCycleSessionData {
  createBillingCycleTemplate: IBillingCycleTemplate;
}

interface ICreateBillingCycleSessionVariables {
  input: ICreateBillingCycleTemplateInput;
}

export const CREATE_BILLING_CYCLE_TEMPLATE = (fields: string = billingCycleTemplateFields) => gql`
  mutation($input: CreateBillingCycleTemplateInput!) {
    createBillingCycleTemplate(input: $input) {
      ${fields}
    }
  }
`;

export const useCreateBillingCycleTemplate = (
  options?: MutationHookOptions<ICreateBillingCycleSessionData, ICreateBillingCycleSessionVariables>,
  fields?: string
) => {
  return useMutation<ICreateBillingCycleSessionData, ICreateBillingCycleSessionVariables>(
    CREATE_BILLING_CYCLE_TEMPLATE(fields),
    {
      ...options,
      refetchQueries: ['getBillingCycleTemplates'],
    }
  );
};

//hooks

export const useUpdateBillingCycleTemplate = (
  options?: MutationHookOptions<IUpdateBillingCycleSessionData, IUpdateBillingCycleSessionVariables>,
  fields?: string
) =>
  useMutation<IUpdateBillingCycleSessionData, IUpdateBillingCycleSessionVariables>(
    UPDATE_BILLING_CYCLE_TEMPLATE(fields),
    {
      ...options,
    }
  );

export const useDeleteBillingCycleTemplate = (
  options?: MutationHookOptions<IDeleteBillingCycleSessionData, IDeleteBillingCycleSessionVariables>,
  fields?: string
) =>
  useMutation<IDeleteBillingCycleSessionData, IDeleteBillingCycleSessionVariables>(
    DELETE_BILLING_CYCLE_TEMPLATE(fields),
    {
      ...options,
    }
  );

export const useCreateAccountBillingCycle = (
  options?: MutationHookOptions<ICreateAccountBillingCycleData, ICreateAccountBillingCycleVariables>,
  fields?: string
) =>
  useMutation<ICreateAccountBillingCycleData, ICreateAccountBillingCycleVariables>(
    CREATE_ACCOUNT_BILLING_CYCLE(fields),
    {
      ...options,
    }
  );

export const useUpdateAccountBillingCycle = (
  options?: MutationHookOptions<IUpdateAccountBillingCycleData, IUpdateAccountBillingCycleVariables>,
  fields?: string
) =>
  useMutation<IUpdateAccountBillingCycleData, IUpdateAccountBillingCycleVariables>(
    UPDATE_ACCOUNT_BILLING_CYCLE(fields),
    {
      ...options,
    }
  );
