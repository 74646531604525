import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import { GLCodeLabelPerType } from 'shared/constants/enums/glCodes';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState2';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { sortBy, capitalize, orderBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentCenterFilters } from 'store/context/actions';
import { RootState } from 'store/reducers';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'shared/components/Switch';
import { useGLCodeContext } from '../../Context/GLCodeContext';
import { debounce } from 'lodash';

interface IGLCodeAssignmentTableProps {
  data: IGLCodeAssignment[];
  totalResults: number;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  loading: boolean;
  handleSort: (field: string, direction: ElasticsearchSortDirection) => void;
  onSearch: (searchText: string) => void;
  searchText: string;
}

const GLCodeAssignmentTable: React.FC<IGLCodeAssignmentTableProps> = ({
  data,
  totalResults,
  tableState,
  tableFunctions,
  loading,
  handleSort,
  onSearch,
  searchText,
}) => {
  const { t } = useTranslation(['billing', 'translation']);
  const { setHideMapped, hideMapped } = useGLCodeContext();
  const centerFilterIds = useSelector((state: RootState) => state.context.centerFilterIds);
  const [keyword, setKeyword] = useState(searchText);
  const dispatch = useDispatch();
  const centers = useGetActiveCenters();
  const centerOptions: ITableFilterOption[] = orderBy(
    centers ?? [],
    (center) => center.name.toLocaleLowerCase(),
    'asc'
  ).map((center) => ({
    label: center.name,
    value: center.id,
  }));

  const handleClearFilter = () => {
    handleCenterFilterSelect([]);
    setHideMapped(false);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  const handleCenterFilterSelect = useCallback(
    (options: ITableFilterOption[]) => {
      dispatch(setCurrentCenterFilters((options ?? []).map((c) => c.value)));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const debouncedOnSearch = useCallback(
    debounce((value: string) => {
      onSearch(value);
    }, 500),
    [onSearch]
  );

  useEffect(() => {
    debouncedOnSearch(keyword);
  }, [keyword, debouncedOnSearch]);

  useEffect(() => {
    setKeyword(searchText);
  }, [searchText]);

  return (
    <DataTable
      data={data}
      dataSize={totalResults}
      noPadding
      showLoadingOverlay={loading}
      showSelect={true}
      selectedRows={tableState.selectedRows}
      updateSelectedRows={tableFunctions.updateSelectedRows}
      pageSize={tableState.pageSize}
      onSort={handleSort}
      activePage={tableState.activePage}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={tableFunctions.changeSizePerPage}
      renderHeader={(paginationProps: any) => (
        <TableHeader className="d-flex justify-content-between">
          <div className="d-flex flex-direction-row align-items-center ">
            <SizePerPage paginationProps={paginationProps} />
            <Switch
              label={t('billing:gl-codes.unlinked-only')}
              labelSide="left"
              value={hideMapped}
              onChange={(v) => setHideMapped(v)}
              className="ml-auto my-2"
              checkedText="Yes"
              uncheckedText="No"
              height={35}
            />
            <TableSearch
              className="mr-4 ml-3"
              onChange={setKeyword}
              // need the search text persist when switch tabs
              // the so will need use stateless approach
              // and the build in debounce in this component will not work
              // thus, we need to handle debounce in the parent component
              value={keyword}
              placeholder="Search"
              enableDebounce={false}
            />
          </div>
          <div className="d-flex align-items-center">
            <DropdownFilter
              title={capitalize(t('translation:spelling.center'))}
              selectedFilters={centerFilterIds}
              options={centerOptions}
              onFilterSelect={handleCenterFilterSelect}
            />
            <IconButtonCircle
              icon={faTimes}
              onClick={handleClearFilter}
              disabled={centerFilterIds.length === 0}
              tooltipDirection="bottom"
              tooltipText="Clear Filters"
              className="ml-4"
            />
          </div>
        </TableHeader>
      )}
      columns={[
        {
          text: capitalize(t('translation:spelling.name')),
          dataField: 'entityName',
          sort: true,
        },
        {
          text: capitalize(t('translation:spelling.center')),
          dataField: 'centerName',
        },
        {
          sort: true,
          text: t('translation:billing.settings.transactions.glCode'),
          dataField: 'glCodeName',
          formatter: (cell: string | null, row: IGLCodeAssignment) =>
            cell ? (
              `${row.glCodeCode} - ${row.glCodeName}`
            ) : (
              <div className="text-danger">
                <FontAwesomeIcon icon={faInfoCircle} color="#B81111" size="1x" className="mr-2" />
                {t('billing:gl-codes.types.not-set')}
              </div>
            ),
        },
        {
          text: capitalize(t('translation:spelling.type')),
          dataField: 'type',
          sort: true,
          formatter: (cell: GLCodeAreaType) => GLCodeLabelPerType[cell],
        },
      ]}
    />
  );
};

export default GLCodeAssignmentTable;
