import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { SEARCH_STAFF, ISearchStaffData } from './queries';
import { invited, created } from 'shared/constants/StaffStatusSearchExpressions';

interface ICreateNewStaffFields {
  firstname: string;
  lastname: string;
  email: string;
  roleship: {
    roleId: string;
    scopeType: 'CENTER' | 'ENTITY';
    scopeIds: string[];
  };
  employmentStartDate?: string | null;
  primaryCenterId?: string | null;
  entityId: string;
  positionId: string;
  payRateType?: 'Hourly' | 'Salary';
  payRate?: number;
  phoneNumber?: string;
}

export interface ICreateNewStaffInput {
  staff: ICreateNewStaffFields;
  sendInvitation?: boolean;
}

interface ICreateNewStaffVariables {
  input: ICreateNewStaffInput;
}

interface ICreateNewStaffData {
  createStaff: IStaff;
}

interface IAddStaffToCenterVariables {
  personId: string;
  centerId: string;
  positionId: string;
}

interface IAddStaffToCenterData {
  addStaffToCenter: IStaff;
}

export const CREATE_NEW_STAFF = gql`
  mutation ($input: CreateStaffInput!) {
    createStaff(input: $input) {
      id
      firstname
      lastname
      email
      avatar {
        url
      }
      address {
        state
        city
      }
      roleship {
        roleId
        name
        scopeType
        scopes {
          ... on Center {
            name
          }
          ... on Entity {
            name
          }
        }
      }
      tags {
        id
        name
        category
      }
      invitedAt
    }
  }
`;

export const ADD_STAFF_TO_CENTER = gql`
  mutation ($personId: ID!, $centerId: ID!, $positionId: ID!) {
    addStaffToCenter(personId: $personId, centerId: $centerId, positionId: $positionId) {
      id
      firstname
      lastname
      email
      avatar {
        url
      }
      address {
        state
        city
      }
      roleship {
        roleId
        name
        scopeType
        scopes {
          ... on Center {
            name
          }
          ... on Entity {
            name
          }
        }
      }
      tags {
        id
        name
        category
      }
      employmentStatus
    }
  }
`;

export const useCreateStaff = () =>
  useMutation<ICreateNewStaffData, ICreateNewStaffVariables>(CREATE_NEW_STAFF, {
    update: (proxy, result) => {
      if (result.data?.createStaff) {
        // update the count on either the "created" or "invited" manage staff card
        const userWasInvited: boolean = Boolean(result.data.createStaff.invitedAt);
        // default values used to provide data to the manage staff cards (specifically invite or created)
        const manageStaffCardElasticFilters = {
          filter: userWasInvited ? invited : created,
          sort: [],
          size: 0,
          from: 1,
        };

        const cachedManageStaffCardData: ISearchStaffData | null = proxy.readQuery({
          query: SEARCH_STAFF,
          variables: {
            input: manageStaffCardElasticFilters,
          },
        });

        if (cachedManageStaffCardData?.searchStaff) {
          proxy.writeQuery({
            query: SEARCH_STAFF,
            variables: {
              input: manageStaffCardElasticFilters,
            },
            data: {
              searchStaff: {
                ...cachedManageStaffCardData.searchStaff,
                totalResults: cachedManageStaffCardData.searchStaff.totalResults + 1,
              },
            },
          });
        }
      }
    },
  });

export const useAddStaffToCenter = () =>
  useMutation<IAddStaffToCenterData, IAddStaffToCenterVariables>(ADD_STAFF_TO_CENTER);
