import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PersonAvatar } from 'shared/components/Avatar';
import Button, { ButtonAsLink } from 'shared/components/Buttons';
import { HorizontalDivider } from 'shared/components/Dividers';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { Row } from 'shared/components/Layout';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useFormatDate from 'shared/hooks/useFormatDate';
import { useGetWellnessConditionHistory } from 'shared/hooks/useGetConditionHistory';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import cast from 'shared/util/cast';
import { camelCaseToSpaced, getFullName } from 'shared/util/string';
import ConditionDocumentsModal from './ConditionDocumentsModal';
import ConditionHistoryModal from './ConditionHistoryModal';
import { mapToGeneralConditionRevision } from './util';

interface IProps {
  condition: IWellnessCondition;
  category: WellnessCategoryType;
  onEdit: (row: IWellnessCondition) => void;
  onArchive: (row: IWellnessCondition) => void;
}

const ConditionExpandedRow: React.FC<IProps> = ({ condition, category, onEdit, onArchive, ...props }) => {
  const formatDate = useFormatDate();
  const [showAllDocuments, setShowAllDocuments] = useState<boolean>(false);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState<boolean>(false);
  const { data, loading } = useGetWellnessConditionHistory(category, condition.id);
  const { t } = useTranslation('accounts');
  const hasEditChildDocumentsPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Documents,
    level: RoleLevelType.Edit,
  });
  const hasDeleteChildDocumentPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Documents,
    level: RoleLevelType.Delete,
  });

  const dataForModalViewHistory = cast<IMedicalConditionRevision[]>(data).map((d) =>
    mapToGeneralConditionRevision(category, d)
  );
  const lastEditPerson = dataForModalViewHistory[dataForModalViewHistory.length - 1];
  const renderDocumentSection = useCallback(
    (documents: IWellnessDocument[]): JSX.Element => {
      const defaultNumberOfDocumentsToShow = 2;
      const nonArchivedDocuments = documents.filter((doc) => !doc.archivedAt);
      const numberOfArchivedDocuments = documents.length - nonArchivedDocuments.length;

      if (!documents.length) {
        return <span>No documents attached.</span>;
      }

      if (!nonArchivedDocuments.length && documents.length && numberOfArchivedDocuments > 0) {
        return (
          <span>
            No active documents attached. There {numberOfArchivedDocuments > 1 ? 'are' : 'is'}{' '}
            {numberOfArchivedDocuments} archived {numberOfArchivedDocuments > 1 ? 'documents' : 'document'} attached.
          </span>
        );
      }

      const hideExtraDocuments = nonArchivedDocuments.length > defaultNumberOfDocumentsToShow;
      const additionalNumberOfDocuments = nonArchivedDocuments.length - defaultNumberOfDocumentsToShow;
      const firstTwoDocuments = nonArchivedDocuments.slice(0, 2);
      const remainingDocuments = nonArchivedDocuments.slice(2);

      return (
        <div className="d-flex flex-row flex-wrap">
          {firstTwoDocuments.map((doc: IWellnessDocument, idx: number) => (
            <a key={idx} href={doc.link} target="_blank" rel="noopener noreferrer" className="mr-2">
              {doc.name}
            </a>
          ))}
          {!showAllDocuments && hideExtraDocuments && (
            <ButtonAsLink className="border-0" onClick={() => setShowAllDocuments(true)}>
              (+{additionalNumberOfDocuments})
            </ButtonAsLink>
          )}
          {showAllDocuments &&
            remainingDocuments.map((doc: IWellnessDocument, idx: number) => (
              <a key={idx} href={doc.link} target="_blank" rel="noopener noreferrer" className="mr-2">
                {doc.name}
              </a>
            ))}
        </div>
      );
    },
    [showAllDocuments]
  );

  return (
    <>
      <section>
        <Row noGutters className="mb-4" align="start">
          {condition.symptoms && condition.symptomsLabel && (
            <div className="d-flex flex-column flex-1">
              <Form.Label>{condition.symptomsLabel}</Form.Label>
              <Row noGutters>
                {condition.symptoms.map((s, i) => (
                  <div key={i} className="tag mr-2 mb-2">
                    {' '}
                    {camelCaseToSpaced(s)}{' '}
                  </div>
                ))}
              </Row>
            </div>
          )}
          <div className="d-flex flex-column flex-1">
            <Form.Label>{condition.freeTextLabel}</Form.Label>
            <div className="text-wrap">{condition.freeText}</div>
          </div>
        </Row>
        <div className="d-flex flex-row mb-4">
          <div className="d-flex flex-column flex-1">
            <Form.Label>
              {dataForModalViewHistory.length > 1
                ? `${t('health-care.last-edited-by')}`
                : `${t('health-care.submitted-by')}`}
            </Form.Label>
            <div className="d-flex flex-row">
              {dataForModalViewHistory.length > 0 ? (
                <>
                  <PersonAvatar size="xs" person={lastEditPerson.revisedByPerson} className="mr-2" />
                  {`${getFullName(lastEditPerson.revisedByPerson)}
                  on ${formatDate(lastEditPerson.revisedAt, 'MM/DD/YYYY')}`}
                </>
              ) : (
                <>
                  <PersonAvatar size="xs" person={condition.createdByPerson} className="mr-2" />
                  {`${getFullName(condition.createdByPerson)} on ${formatDate(condition.createdAt, 'MM/DD/YYYY')}`}
                </>
              )}
            </div>
          </div>
          <HasRoleAreaLevel
            action={{ area: AreaType.Child, permission: PermissionType.Documents, level: RoleLevelType.Read }}
          >
            {condition.childId && (
              <div className="d-flex flex-column flex-1">
                <Form.Label>Documents attached</Form.Label>
                <div>{renderDocumentSection(condition.documents ?? [])}</div>
              </div>
            )}
          </HasRoleAreaLevel>
        </div>
        <Row noGutters>
          {!condition.archivedAt && hasDeleteChildDocumentPermission && (
            <Button variant="outline-danger" onClick={() => onArchive(condition)}>
              Archive
            </Button>
          )}
          {!condition.archivedAt && hasEditChildDocumentsPermission && (
            <Button className="ml-auto mr-4" variant="secondary" onClick={() => onEdit(condition)}>
              Update
            </Button>
          )}
        </Row>
        {(condition.isRevised || condition.archivedAt) && (
          <div>
            <HorizontalDivider />
            <Row>
              <ButtonAsLink className="mr-auto text-center ml-auto" onClick={() => setShowHistoryModal(true)}>
                View History
              </ButtonAsLink>
            </Row>
          </div>
        )}
      </section>
      <ConditionHistoryModal
        isOpen={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        revisions={cast<IMedicalConditionRevision[]>(data).map((d) => mapToGeneralConditionRevision(category, d))}
        condition={condition}
        loading={loading}
      />
      <ConditionDocumentsModal
        isOpen={showDocumentsModal}
        onHide={() => setShowDocumentsModal(false)}
        condition={condition}
        category={category}
        canEdit={hasEditChildDocumentsPermission}
        canDelete={hasDeleteChildDocumentPermission}
      />
    </>
  );
};

export default ConditionExpandedRow;
