import { find, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'shared/components/Buttons';
import { HorizontalDivider } from 'shared/components/Dividers';
import { Col, Row } from 'shared/components/Layout';
import useDateDiffInDays from 'shared/hooks/useGetDateDiff';
import CertificatesTable, { ICcsCertificateDetails } from './CertificatesTable';
import { ccsCertificateStatusEnum, DecisionMakingAgencyToDisplayName } from 'shared/constants/enums/ccssEnums';

interface IProps {
  certificate: ICcssCertificate;
  child: IAccountChild;
  previousCertificates: ICcsCertificateDetails[];
  onEditClick: () => void;
  onCreateClick?: () => void;
  onCancelClick: () => void;
  onNotAtRiskClick: () => void;
  hideEdit?: boolean;
  hidePrevious?: boolean;
  hideCreate?: boolean;
  hideCancel?: boolean;
  hideNotAtRisk?: boolean;
  renderPrevious?: (props: { previousCertificates: ICcsCertificateDetails[] }) => JSX.Element;
}

const dateFormat = 'DD/MM/YYYY';

const CcsCertificateCard: React.FC<IProps> = ({
  certificate,
  previousCertificates,
  onEditClick,
  onCreateClick = () => {},
  onCancelClick,
  onNotAtRiskClick,
  hideEdit,
  hidePrevious,
  hideCreate,
  hideCancel,
  hideNotAtRisk,
  renderPrevious,
}) => {
  const RenderPrevious = renderPrevious ? renderPrevious : () => <></>;
  const orderedRows = orderBy(previousCertificates, (p) => moment(p.certificate.startDate));
  let daysRemaining = useDateDiffInDays(certificate.endDate, moment().startOf('day').toString());

  daysRemaining = daysRemaining < 0 ? 0 : daysRemaining;

  const stateTerritoryBody = certificate.stateTerritoryBody;
  const showTextRow = stateTerritoryBody?.text !== '';
  const showBodyPersonRow =
    stateTerritoryBody?.statePersonNameOrId !== '' ||
    stateTerritoryBody?.statePersonEmail !== '' ||
    stateTerritoryBody.statePersonPhoneNumber !== '';

  const getRecordStatusDisplay = (certificate: ICcssCertificate) => {
    switch (certificate.recordStatus) {
      case 'P':
        return 'Provisional';
      default:
        return find(ccsCertificateStatusEnum, (status) => status.value === certificate.status)?.label ?? 'N/A';
    }
  };

  return (
    <div className="kt-account-ccs-enrolment-card">
      <Row align="start" className="mb-4">
        <Col md="3">
          <HeadingCaption heading="Weeks at Risk" caption={certificate.weeksAtRisk.toString()} />
        </Col>
        <Col md="3">
          <HeadingCaption heading="Days Remaining" caption={daysRemaining.toString()} />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="State/Territory Notified"
            caption={certificate.stateTerritoryBody?.noticeToStateTerritory ? 'Yes' : 'No'}
          />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="State/Territory Notified Provider"
            caption={certificate.stateTerritoryBody?.notifiedByStateTerritory ? 'Yes' : 'No'}
          />
        </Col>
      </Row>

      <Row align="start" className="mb-4">
        <Col md="3">
          <HeadingCaption heading="Record Status" caption={getRecordStatusDisplay(certificate)} />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="Decision Making Agency"
            caption={`${
              certificate.decisionMakingAgency
                ? DecisionMakingAgencyToDisplayName[certificate.decisionMakingAgency]
                : 'N/A'
            }`}
          />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="Exceptional Circumstance Reason"
            caption={`${certificate.exceptionalCircumstanceReason ?? 'N/A'}`}
          />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="Exceptional Circumstance Text"
            caption={`${certificate.exceptionalCircumstanceText ?? 'N/A'}`}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h6>State/Territory Body Details</h6>
        </Col>
      </Row>
      {certificate.stateTerritoryBody ? (
        <>
          <Row align="start">
            <Col md="3">
              <HeadingCaption
                heading="Reference Number"
                caption={certificate.stateTerritoryBody.stateReferenceNumber ?? ''}
              />
            </Col>

            <Col md="3">
              <HeadingCaption
                heading="Notice Date"
                caption={moment(certificate.stateTerritoryBody.noticeGivenDate).format(dateFormat)}
              />
            </Col>

            <Col md="3">
              <HeadingCaption heading="Organisation" caption={certificate.stateTerritoryBody.organisationName} />
            </Col>

            <Col md="3">
              <HeadingCaption
                heading="Notified By"
                caption={`${certificate.stateTerritoryBody.notifiedByPersonFirstName} ${certificate.stateTerritoryBody.notifiedByPersonLastName}`}
              />
            </Col>
          </Row>

          {showBodyPersonRow && (
            <>
              <Row className="mt-4">
                <Col>
                  <h6>State/Territory Body Person</h6>
                </Col>
              </Row>
              <Row align="start">
                <Col md="3">
                  <HeadingCaption
                    heading="Name or Id"
                    caption={certificate.stateTerritoryBody.statePersonNameOrId ?? ''}
                  />
                </Col>

                <Col md="3">
                  <HeadingCaption heading="Email" caption={certificate.stateTerritoryBody.statePersonEmail ?? ''} />
                </Col>

                <Col md="3">
                  <HeadingCaption
                    heading="Phone Number"
                    caption={certificate.stateTerritoryBody.statePersonPhoneNumber ?? ''}
                  />
                </Col>
              </Row>
            </>
          )}
          {showTextRow && (
            <>
              <Row className="mt-4">
                <Col>
                  <h6>Text</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p
                    style={{
                      overflowWrap: 'break-word',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {certificate.stateTerritoryBody.text}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <Row align="start">
          <Col>
            <small className="caption">No details have been submitted</small>
          </Col>
        </Row>
      )}

      <Row align="start" className="mt-4">
        {!(hideNotAtRisk && hideCreate && hideCancel) && (
          <Col>
            {!hideNotAtRisk && (
              <Button variant="outline-primary" onClick={onNotAtRiskClick} className="mr-4">
                Not at Risk
              </Button>
            )}
            {!hideCreate && (
              <Button variant="outline-primary" onClick={onCreateClick} className="mr-4">
                Create Determination
              </Button>
            )}
            {!hideCancel && (
              <Button variant="outline-primary" onClick={onCancelClick}>
                Cancel
              </Button>
            )}
          </Col>
        )}

        <Col>
          {!hideEdit && (
            <div className="float-right">
              <Button variant="primary" onClick={onEditClick}>
                Edit
              </Button>
            </div>
          )}
        </Col>
      </Row>

      {!hidePrevious && (
        <Row>
          <Col>
            <HorizontalDivider />
          </Col>
        </Row>
      )}

      {!hidePrevious && (
        <Row>
          {renderPrevious ? (
            <RenderPrevious previousCertificates={orderedRows} />
          ) : (
            <PreviousCertificates previousCertificates={orderedRows} />
          )}
        </Row>
      )}
    </div>
  );
};

const HeadingCaption = (props: { heading: string; caption: string }) => {
  return (
    <>
      <div>
        <small>{props.heading}</small>
      </div>
      <div>
        <small className="caption">{props.caption}</small>
      </div>
    </>
  );
};

const PreviousCertificates = (props: { previousCertificates: ICcsCertificateDetails[] }) => {
  return (
    <Accordion
      className={'d-flex flex-column justify-content-center pb-4'}
      defaultActiveKey="-1"
      style={{ width: '100%' }}
    >
      <Accordion.Toggle eventKey="0" className="kt-account-ccs-tab-accordion-toggle">
        <div className="float-center">
          <span>View Previous Certificates ({props.previousCertificates.length})</span>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse className="" eventKey="0">
        <CertificatesTable data={props.previousCertificates} loading={false} />
      </Accordion.Collapse>
    </Accordion>
  );
};

export default CcsCertificateCard;
