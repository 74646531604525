interface iStartDate {
  startDate: string;
}

/**
 * Helper function for sorting fee schedules based on start date. Returns a new sorted array, leaving original array intact.
 * Dev Note: We use the generic here to allow the caller to define the output type since the only requirement for sorting is that it has a start date
 * @param schedules
 * @param order either asc or desc, defaults to asc
 * @returns sorted array
 */
export function sortSchedules<T>(schedules: iStartDate[], order: 'asc' | 'desc' = 'asc'): T[] {
  const tempArray = [...schedules];
  tempArray.sort((a: iStartDate, b: iStartDate) => {
    const aStartDate = new Date(a.startDate).getTime();
    const bStartDate = new Date(b.startDate).getTime();
    if (aStartDate < bStartDate) return order === 'asc' ? -1 : 1;
    if (aStartDate > bStartDate) return order === 'asc' ? 1 : -1;
    return 0;
  });
  return tempArray as T[];
}

/**
 * gets the currently active schedule from the list of schedules.
 * "Currently Active" is defined as the schedule that would apply to "today"
 * Dev Note: We use the generic here to allow the caller to define the output type since the only requirement for sorting is that it has a start date
 * @param schedules list of schedules
 * @returns active schedule if there is one
 */
export function getActiveSchedule<T>(schedules: iStartDate[]): T | undefined {
  const possiblyActive = schedules.filter((s) => {
    const today = new Date().getTime();
    const startDate = new Date(s.startDate).getTime();
    return startDate <= today;
  });
  const sorted = sortSchedules(possiblyActive, 'desc');
  return sorted[0] as T;
}
