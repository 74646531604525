import React, { useCallback, useEffect } from 'react';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import Row from 'react-bootstrap/Row';
import { Col } from 'shared/components/Layout';
import { TableSearch } from 'shared/components/DataTable';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import { sortBy } from 'lodash';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import centerStatusFilterOptions from 'shared/constants/CenterStatusSearchExpressions';
import useGetSearchExpression from './useGetCenterSearchExpression';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import { updateCentersTableFilters } from 'pages/Centers/duck/actions';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { showConfirm } from 'shared/components/ConfirmationContainer';
import { useTranslation } from 'react-i18next';

const { TERM } = SEARCH_EXPRESSIONS;
const { CONTAINS, EQUALS } = PREDICATES;

interface IProps {
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  tags: ITableFilterOption[];
  states: { value: string; label: string }[];
  entities: IEntity[];
  autoSelectedBusinessId?: string;
  hideStatus?: boolean;
  showUnsavedChangesWarning?: boolean;
  totalChanges?: number;
}

const FilterGroup: React.FC<IProps> = ({
  tableState,
  tableFunctions,
  tags,
  states,
  entities,
  autoSelectedBusinessId,
  hideStatus = false,
  showUnsavedChangesWarning = false,
  totalChanges = 0,
}) => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const statusOptions = user?.isInternal
    ? Object.values(centerStatusFilterOptions)
    : Object.values(centerStatusFilterOptions).filter((o) => o.value !== 'deleted');
  const getSearchExpression = useGetSearchExpression(tags, states); // converts search term string into query expression
  const stateOptions: ITableFilterOption[] = sortBy(states, ['label']).map((state) => ({
    label: state.label,
    value: state.value,
    searchExpression: { [TERM]: { field: 'address.state.keyword', predicate: EQUALS, value: state.value } },
  }));
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name']).map((tag) => ({
    label: tag.label,
    value: tag.value,
    searchExpression: { [TERM]: { field: 'tags.keyword', predicate: CONTAINS, value: tag.value } },
  }));

  const businessFilterOptions = sortBy(Object.values(entities || []), 'name').map((entity) => ({
    label: entity.name,
    value: entity.id,
    searchExpression: { [TERM]: { field: 'entityId.keyword', predicate: EQUALS, value: entity.id } },
  }));

  const updateSelectedStatusFilters = useCallback(
    (values: ITableFilterOption[]) => {
      tableFunctions.updateSelectedFilters({
        ...tableState.selectedFilters,
        status: values,
      });

      const statusFilters = {
        active: values.some((v) => v.value === 'active'),
        deactivated: values.some((v) => v.value === 'deactivated'),
        deleted: values.some((v) => v.value === 'deleted'),
      };

      dispatch(updateCentersTableFilters(statusFilters));
    },
    [dispatch, tableFunctions, tableState]
  );

  const clearAppliedFilters = useCallback(() => {
    tableFunctions.updateSelectedFilters({ status: [], state: [], tags: [], entity: [] });
  }, [tableFunctions]);

  const showUnsavedChangesWarningConfirmation = useCallback(
    (runAfter: () => void) => {
      showConfirm({
        title: t('enrollment:unsaved-changes-modal.title', { totalChanges: totalChanges }),
        message: (
          <>
            <p>{t('enrollment:unsaved-changes-modal.body1')}</p>
            <p>{t('enrollment:unsaved-changes-modal.body2')}</p>
          </>
        ),
        primaryButtonLabel: t('enrollment:unsaved-changes-modal.continue'),
        onConfirm: async () => {
          runAfter();
          tableFunctions.updateSelectedRows([]);
        },
        primaryButtonVariant: 'primary',
      });
    },
    [t, tableFunctions, totalChanges]
  );

  useEffect(() => {
    tableFunctions.updateSelectedFilters({
      ...tableState.selectedFilters,
      entity: businessFilterOptions.filter((o) => o.value == autoSelectedBusinessId ?? []),
    });
  }, [autoSelectedBusinessId]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Row className="d-flex flex-row align-items-center flex-wrap flex-grow-1">
      <div
        className={
          isMobile
            ? 'd-flex flex-wrap my-1 ml-2 align-items-center mr-auto'
            : 'd-flex flex-wrap mr-auto align-items-center'
        }
      >
        <TableSearch
          onChange={(term) => {
            if (showUnsavedChangesWarning)
              showUnsavedChangesWarningConfirmation(() =>
                tableFunctions.updateSearchExpressions(getSearchExpression(term))
              );
            else tableFunctions.updateSearchExpressions(getSearchExpression(term));
          }}
          placeholder={`Search ${fieldLabels.center}(s)`}
        />
      </div>
      <div
        className={
          isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex flex-wrap align-items-center justify-content-end'
        }
      >
        {user?.isInternal && (
          <div className={isMobile ? 'my-1 ml-2' : 'my-0 mr-2 p-0'}>
            <DropdownFilter
              title="Business"
              selectedFilters={tableState.selectedFilters.entity}
              options={businessFilterOptions}
              onFilterSelect={(val) => {
                if (showUnsavedChangesWarning)
                  showUnsavedChangesWarningConfirmation(() =>
                    tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, entity: val })
                  );
                else tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, entity: val });
              }}
            />
          </div>
        )}
        {!hideStatus && (
          <div className={isMobile ? 'my-1 pl-0 ml-2 flex-wrap' : user?.isInternal ? 'my-0 mx-2 p-0' : 'my-0 mr-2 p-0'}>
            <DropdownFilter
              title="Status"
              selectedFilters={tableState.selectedFilters.status}
              options={statusOptions}
              onFilterSelect={(values) => {
                if (showUnsavedChangesWarning)
                  showUnsavedChangesWarningConfirmation(() => updateSelectedStatusFilters(values));
                else updateSelectedStatusFilters(values);
              }}
            />
          </div>
        )}
        <div className={isMobile ? 'my-1 pl-0 ml-2' : 'my-0 mx-2 p-0'}>
          <DropdownFilter
            title="State"
            selectedFilters={tableState.selectedFilters.state}
            options={stateOptions}
            onFilterSelect={(val) => {
              if (showUnsavedChangesWarning)
                showUnsavedChangesWarningConfirmation(() =>
                  tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, state: val })
                );
              else tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, state: val });
            }}
          />
        </div>
        <div className={isMobile ? 'my-1 pl-0 ml-2' : 'my-0 mx-2 p-0'}>
          <DropdownFilter
            title="Tags"
            selectedFilters={tableState.selectedFilters.tags}
            options={tagOptions}
            onFilterSelect={(val) => {
              if (showUnsavedChangesWarning)
                showUnsavedChangesWarningConfirmation(() =>
                  tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, tags: val })
                );
              else tableFunctions.updateSelectedFilters({ ...tableState.selectedFilters, tags: val });
            }}
          />
        </div>
        <div className={isMobile ? 'mt-1 ml-2' : 'my-0 ml-2 p-0'}>
          <IconButtonCircle
            icon={faTimes}
            onClick={() => {
              if (showUnsavedChangesWarning) showUnsavedChangesWarningConfirmation(() => clearAppliedFilters());
              else clearAppliedFilters();
            }}
            disabled={Object.values(tableState.selectedFilters).every((filter) => !filter || filter.length === 0)}
            tooltipDirection="bottom"
            tooltipText="Clear Filters"
          />
        </div>
      </div>
    </Row>
  );
};

export default FilterGroup;
