import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'shared/components/DataTable';
import { Col, Row } from 'shared/components/Layout';

interface IProps {
  data: ICcssEvent;
}

const CCSSEventRow: React.FC<IProps> = ({ data }) => {
  const { description, specialConditions, specialAbsences } = data;

  const { t } = useTranslation(['translation', 'subsidies']);

  const specialConditionTypeText = (condition: IEventSpecialCondition) => {
    switch (condition.type) {
      case 'SC0001':
        return t('subsidies:event-management.event-special-condition-sc0001');
      case 'SC0002':
        return t('subsidies:event-management.event-special-condition-sc0002');
      default:
        return '';
    }
  };

  return (
    <div>
      <Row align="start" className="mb-4">
        <Col>
          <HeadingCaption heading={t('subsidies:event-management.description')} caption={description} />
        </Col>
      </Row>
      {!!specialConditions && !!specialConditions.length && (
        <>
          <Row align="start">
            <Col>
              <h6>{t('subsidies:event-management.special-conditions')}</h6>
            </Col>
          </Row>
          <Row align="start" className="mb-4">
            <Col>
              <DataTable
                keyField="id"
                noPadding={true}
                data={specialConditions}
                handleRowClick={() => {}}
                hasOverlay={false}
                showSelect={false}
                showPagination={false}
                className="ccs-table"
                expandHeaderColumnRenderer={(props) => <></>}
                columns={[
                  {
                    text: t('subsidies:event-management.event-type'),
                    dataField: 'type',
                    formatter: (cell: any, row: IEventSpecialCondition) => specialConditionTypeText(row),
                  },
                  {
                    text: t('subsidies:event-management.start-date'),
                    dataField: 'startDate',
                    formatter: (cell: any, row: IEventSpecialCondition) =>
                      !!row.startDate ? moment(row.startDate).format(t('translation:formatters.MM/DD/YYYY')) : '',
                  },
                  {
                    text: t('subsidies:event-management.end-date'),
                    dataField: 'endDate',
                    formatter: (cell: any, row: IEventSpecialCondition) =>
                      !!row.endDate ? moment(row.endDate).format(t('translation:formatters.MM/DD/YYYY')) : '',
                  },
                  {
                    text: t('subsidies:event-management.description'),
                    dataField: 'value',
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      )}
      {!!specialConditions && !!specialConditions.length && !!specialAbsences && !!specialAbsences.length && (
        <hr className="mx-auto mt-4 mb-4 horizontal-divider" />
      )}
      {!!specialAbsences && !!specialAbsences.length && (
        <>
          <Row align="start">
            <Col>
              <h6>{t('subsidies:event-management.special-absences')}</h6>
            </Col>
          </Row>
          <Row align="start" className="mb-4">
            <Col>
              <DataTable
                keyField="id"
                noPadding={true}
                data={specialAbsences}
                handleRowClick={() => {}}
                hasOverlay={false}
                showSelect={false}
                showPagination={false}
                className="ccs-table"
                expandHeaderColumnRenderer={(props) => <></>}
                columns={[
                  {
                    text: t('subsidies:event-management.crn'),
                    dataField: 'childCrn',
                  },
                  {
                    text: t('subsidies:event-management.child-name'),
                    dataField: 'childFirstName',
                    formatter: (cell: any, row: IEventSpecialAbsence) => `${row.childFirstName} ${row.childLastName}`,
                  },
                  {
                    text: t('subsidies:event-management.enrollment-id'),
                    dataField: 'enrolmentId',
                  },
                  {
                    text: t('subsidies:event-management.available'),
                    dataField: 'available',
                  },
                  {
                    text: t('subsidies:event-management.used'),
                    dataField: 'consumed',
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

const HeadingCaption = (props: { heading: string; caption: string }) => {
  return (
    <>
      <div>
        <h6>{props.heading}</h6>
      </div>
      <div>
        <small className="caption">{props.caption}</small>
      </div>
    </>
  );
};

export default CCSSEventRow;
