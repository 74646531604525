import React, { useContext } from 'react';
import moment from 'moment';
import ClassSchedule from '../ClassSchedule';
import { IShiftsGroupedByClassShape } from '../../services/scheduleService';
import ScheduleDataFilterContext from '../../providers/scheduleDataFilterProvider';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import _ from 'lodash';

interface IProps {
  classes: IClass[];
  locations: ILocation[];
  datesForWeek: moment.Moment[];
  startOfWeek: moment.Moment;
  calendarType: CalendarViewType;
  shiftsForCurrentWeek: IShiftsGroupedByClassShape;
  weekHasSchedule: boolean;
  loadingSchedule: boolean;
  isSchedulePublished: boolean;
  onNewShift: (date: moment.Moment, id: string, idType: 'CLASS' | 'LOCATION') => void;
  onShiftSelect: (shift: IShift) => void;
  sessions: ISession[];
  sessionsLoading?: boolean;
}

const ScheduleOverview: React.FC<IProps> = ({
  classes,
  locations,
  datesForWeek,
  startOfWeek,
  calendarType,
  shiftsForCurrentWeek,
  weekHasSchedule,
  loadingSchedule,
  onNewShift,
  onShiftSelect,
  isSchedulePublished,
  sessions,
  sessionsLoading,
  ...props
}) => {
  const activeDataFiltersContextValue = useContext(ScheduleDataFilterContext);
  const hasCreateShiftPermission = useHasRoleAreaLevel({
    area: AreaType.Schedule,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });
  const sessionsByClass = _.groupBy(sessions, (s) => s.classId);

  return (
    <>
      {classes
        .filter((p) => activeDataFiltersContextValue.classIds.includes(p.id))
        .map((_class: IClass, idx: number) => {
          const regulation = _class.regulationOverride || _class.regulation;
          return (
            <ClassSchedule
              showProjectedAttendance
              key={`class-schedule-${_class.id}-week-of-${startOfWeek.format('YYYY_MM_DD')}-${idx}`}
              className="mb-2"
              idToAssociateShiftTo={_class.id}
              title={_class.name}
              colorCode={_class.colorCode}
              ratioChildren={regulation.ratioChildren}
              ratioTeacher={regulation.ratioTeachers}
              shifts={shiftsForCurrentWeek.CLASSES[_class.id] ?? []}
              datesForWeek={datesForWeek}
              calendarType={calendarType}
              loadingSchedule={loadingSchedule}
              canAddShifts={
                startOfWeek.isSameOrAfter(moment().startOf('week'), 'd') &&
                weekHasSchedule &&
                !isSchedulePublished &&
                hasCreateShiftPermission
              }
              onCreateNewShift={(date, id) => onNewShift(date, id, 'CLASS')}
              onShiftSelect={onShiftSelect}
              sessions={sessionsByClass[_class.id]}
              sessionsLoading={sessionsLoading}
            />
          );
        })}
      {locations
        .filter((l) => activeDataFiltersContextValue.locationIds.includes(l.id))
        .map((location: ILocation, idx: number) => {
          return (
            <ClassSchedule
              showProjectedAttendance={false}
              key={`class-schedule-${location.id}-week-of-${startOfWeek.format('YYYY_MM_DD')}-${idx}`}
              className="mb-2"
              idToAssociateShiftTo={location.id}
              title={location.name}
              shifts={shiftsForCurrentWeek.LOCATIONS[location.id] ?? []}
              datesForWeek={datesForWeek}
              calendarType={calendarType}
              loadingSchedule={loadingSchedule}
              canAddShifts={
                startOfWeek.isSameOrAfter(moment().startOf('week')) &&
                !location.archivedAt &&
                weekHasSchedule &&
                !isSchedulePublished &&
                hasCreateShiftPermission
              }
              onCreateNewShift={(date, id) => onNewShift(date, id, 'LOCATION')}
              onShiftSelect={onShiftSelect}
            />
          );
        })}
    </>
  );
};

export default ScheduleOverview;
