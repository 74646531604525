import { CREATE_DEPOSIT, RETURN_DEPOSIT, DepositActionTypes } from './types';

export const createDeposit = (deposits: IDeposit[]): DepositActionTypes => ({
  type: CREATE_DEPOSIT,
  deposits,
});

export const returnDeposit = (deposits: IDeposit[]): DepositActionTypes => ({
  type: RETURN_DEPOSIT,
  deposits,
});
