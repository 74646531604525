import axios from 'axios';
import config from 'config';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetCurrentJwt } from 'shared/hooks/user-context/useGetCurrentJwt';
import { DisplayLineItem } from './components/LineItems';
import moment from 'moment';
import { showToast } from '../../../shared/components/Toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export interface IDisplayableAgencyPayment {
  id: string;
  agencyName: string;
  centers: [{ id: string; name: string; centerId: string }];
  paymentDate: string;
  referenceId: string;
  amount: number;
  paymentFormat: string;
  lineItems: LineItem[];
  isDirty: boolean;
}

export interface Column {
  id: string;
  startDate: string | null;
  endDate: string | null;
  isDirty: boolean;
}

interface AutoSaveRequest {
  paymentFormat: string;
  agencyPayment?: { id: string; paymentDate: string; referenceId: string; amount: number };
  lineItems: { id?: string | null; agencyPaymentId: string; recordId: string; amount: number; dateId: string }[];
  lineItemsToDelete: { id: string }[];
  dates: { id: string; startDate: string | null; endDate: string | null; changed: boolean }[];
}

interface FullSaveRequest {
  paymentFormat: string;
  agencyPayment?: { id: string; paymentDate: string; referenceId: string; amount: number };
  lineItems: { id?: string | null; agencyPaymentId: string; recordId: string; amount: number; dateId: string }[];
  dates: { id: string; startDate: string | null; endDate: string | null; changed: boolean }[];
}

interface UseManageAgencyPaymentOutput {
  agencyPayment?: IDisplayableAgencyPayment;
  columns: Column[];
  lineItems: DisplayLineItem[];
  totalEntered?: number;
  difference?: number;
  handleRemoveLineItem: (id: string) => void;
  handleDateChange: (id: string, startDate: string | null, endDate: string | null) => void;
  handleAccountSelection: (account: IAccount | null) => void;
  handleChildSelection: (child: IAccountChild | null) => void;
  handleAmountChange: (id: string, dateKey: string, amount) => void;
  handleHeaderAmountChange: (amount: number) => void;
  handleHeaderDateChange: (date: string) => void;
  handleHeaderReferenceIdChange: (referenceId: string) => void;
  loading: boolean;
  saving: boolean;
  lastSave: string;
  saveAgencySubsidyPayment: () => void;
}
/**
 * function which holds state, event handlers, and logic for managing agency payments
 *
 * Meant to be used in the AgencyPayment component
 */
export function useManageAgencyPayment(paymentId: string): UseManageAgencyPaymentOutput {
  const authToken = useGetCurrentJwt();
  const { t } = useTranslation(['subsidies']);
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [lastSave, setLastSave] = useState<string>('');
  const [agencyPayment, setAgencyPayment] = useState<IDisplayableAgencyPayment>();
  const [columns, setColumns] = useState<Column[]>([]);
  const [lineItems, setLineItems] = useState<DisplayLineItem[]>([]);
  const saveLock = useRef(false);

  useEffect(() => {
    setLoading(true);
    loadAgencyPayment({ paymentId, authToken })
      .then((agencyPayment) => {
        agencyPayment.isDirty = false;
        setAgencyPayment(agencyPayment);
        let columns = getDefaultColumns(agencyPayment.lineItems);
        setColumns(columns);
        let displayLineItems = convertLineItemsToDisplayLineItems(
          agencyPayment.paymentFormat,
          agencyPayment.lineItems,
          columns
        );
        setLineItems(displayLineItems);
        setLoading(false);
      })
      .catch((e) => {
        console.error('Error fetching agency payment', e);
        setLoading(false);
        // TODO handle various errors
      });
  }, [authToken, paymentId]);

  useEffect(() => {
    const AUTOSAVE_INTERVAL = 3000; // 3 seconds

    const timer = setTimeout(() => {
      if (!saveLock.current) {
        let saveRequest = createAutoSaveObject();

        if (
          saveRequest.lineItems.length == 0 &&
          saveRequest.lineItemsToDelete.length == 0 &&
          saveRequest.agencyPayment == null
        ) {
          return;
        }

        setSaving(true);
        autoSaveAgencyPayment({ paymentId, authToken, saveBody: saveRequest })
          .then((response) => {
            setLineItems((prev) => {
              const temp = [...prev];
              response.newLineItems.map((newLineItem) => {
                // Find the line item for the record, and update the column record with the id
                const li = temp.find((li) => li.id == newLineItem.recordId);

                if (li) {
                  li.amounts[newLineItem.columnId].id = newLineItem.id;
                }
              });

              return temp;
            });

            setLastSave(moment().format('MMMM Do, YYYY [at] h:mm:ss a'));
            setSaving(false);
          })
          .catch((e) => {
            console.error('Error auto-saving agency payment', e);
            setSaving(false);
            // TODO handle various errors
          });

        // perform state cleanup
        updateSavedStates(saveRequest);
      }
    }, AUTOSAVE_INTERVAL);

    return () => clearTimeout(timer);
  }, [lineItems, agencyPayment, columns]);

  const fullSaveObject = useMemo(() => {
    let saveRequest: FullSaveRequest = {
      lineItems: [],
      dates: [],
      paymentFormat: agencyPayment?.paymentFormat ?? 'Account',
    };

    if (agencyPayment) {
      saveRequest.agencyPayment = {
        id: agencyPayment.id,
        paymentDate: agencyPayment.paymentDate,
        referenceId: agencyPayment.referenceId,
        amount: agencyPayment.amount,
      };
    }

    columns.forEach((column) => {
      saveRequest.dates.push({
        id: column.id,
        startDate: column.startDate,
        endDate: column.endDate,
        changed: column.isDirty,
      });
    });

    lineItems.map((lineItem) => {
      for (const key of saveRequest.dates.filter((d) => d.startDate !== null && d.endDate !== null).map((d) => d.id)) {
        saveRequest.lineItems.push({
          id: lineItem.amounts[key]?.id,
          agencyPaymentId: agencyPayment?.id ?? '',
          recordId: lineItem.id ?? '',
          dateId: key,
          amount: lineItem.amounts[key]?.amount ?? 0,
        });
      }
    });

    return saveRequest;
  }, [agencyPayment, lineItems, columns]);

  const saveAgencySubsidyPayment = useCallback(() => {
    setSaving(true);
    saveLock.current = true;
    fullSaveAgencyPayment({ paymentId, authToken, saveBody: fullSaveObject })
      .then((response) => {
        showToast(t('subsidies:agency-payment.manage-agency-payment.success-message'), 'success');
        history.replace('/subsidies/payments');
      })
      .catch((error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      });
  }, [fullSaveObject, paymentId, authToken]);

  function createAutoSaveObject() {
    let saveRequest: AutoSaveRequest = {
      lineItems: [],
      lineItemsToDelete: [],
      dates: [],
      paymentFormat: agencyPayment?.paymentFormat ?? 'Account',
    };

    // Check if the agency payment record is dirty
    if (agencyPayment?.isDirty === true) {
      saveRequest.agencyPayment = {
        id: agencyPayment.id,
        paymentDate: agencyPayment.paymentDate,
        referenceId: agencyPayment.referenceId,
        amount: agencyPayment.amount,
      };
    }

    columns.forEach((column) => {
      saveRequest.dates.push({
        id: column.id,
        startDate: column.startDate,
        endDate: column.endDate,
        changed: column.isDirty,
      });

      if (column.isDirty) {
        for (const li of lineItems) {
          if (li.amounts[column.id]) {
            saveRequest.lineItems.push({
              id: li.amounts[column.id].id,
              agencyPaymentId: agencyPayment?.id ?? '',
              recordId: li.id ?? '',
              dateId: column.id,
              amount: li.amounts[column.id].amount,
            });
          }
        }
      }
    });

    lineItems
      .filter((lineItem) => lineItem.isDeleted)
      .map((lineItem) => {
        for (const key of Object.keys(lineItem.amounts)) {
          if (lineItem.amounts[key].id !== null) {
            saveRequest.lineItemsToDelete.push({ id: lineItem.amounts[key].id });
          }
        }
      });

    lineItems
      .filter((lineItem) => Object.values(lineItem.amounts).some((a) => a.isDirty) && !lineItem.isDeleted)
      .map((lineItem) => {
        for (const key of Object.keys(lineItem.amounts)) {
          if (lineItem.amounts[key].isDirty) {
            // If the amount value is set and isn't 0, we want to send the record for adding or updating
            if (lineItem.amounts[key].amount && lineItem.amounts[key].amount !== 0) {
              const existingIndex = saveRequest.lineItems.findIndex(
                (li) => li.id == lineItem.amounts[key].id || (li.recordId == lineItem.id && li.dateId == key)
              );

              if (existingIndex === -1) {
                saveRequest.lineItems.push({
                  id: lineItem.amounts[key].id,
                  agencyPaymentId: agencyPayment?.id ?? '',
                  recordId: lineItem.id ?? '',
                  dateId: key,
                  amount: lineItem.amounts[key].amount,
                });
              }
            }
            // If the amount value isn't set or is 0, we want to send the record for deleting only if it exists
            else {
              if (lineItem.amounts[key].id) {
                const existingIndex = saveRequest.lineItemsToDelete.findIndex(
                  (li) => li.id == lineItem.amounts[key].id
                );

                if (existingIndex === -1) {
                  saveRequest.lineItemsToDelete.push({ id: lineItem.amounts[key].id });
                }
              }
            }
          }
        }
      });

    return saveRequest;
  }

  function updateSavedStates(saveRequest: AutoSaveRequest) {
    // reset agency payment
    if (saveRequest.agencyPayment) {
      if (
        agencyPayment?.paymentDate == saveRequest.agencyPayment!.paymentDate &&
        agencyPayment?.referenceId == saveRequest.agencyPayment!.referenceId &&
        agencyPayment.amount == saveRequest.agencyPayment!.amount
      ) {
        agencyPayment.isDirty = false;
      }
    }

    setColumns((prev) => {
      const temp = [...prev];

      // reset columns
      for (const column of saveRequest.dates) {
        let col = temp.find((c) => c.id == column.id);
        if (col && col.startDate == column.startDate && col.endDate == column.endDate) {
          col.isDirty = false;
        }
      }

      return temp;
    });

    setLineItems((prev) => {
      const temp = [...prev];

      // reset deleted line items
      for (const deleted of saveRequest.lineItemsToDelete) {
        let index = temp.findIndex((li) => Object.values(li.amounts).some((a) => a.id == deleted.id));
        if (index !== -1) {
          if (temp[index].isDeleted) {
            temp.splice(index, 1);
          } else {
            let amountToRemoveIndex = Object.values(temp[index].amounts).findIndex((a) => a.id == deleted.id);

            delete temp[index].amounts[amountToRemoveIndex];
          }
        }
      }

      // reset line items
      for (const lineItem of saveRequest.lineItems) {
        let li = temp.find((li) => li.id == lineItem.recordId);
        if (li) {
          let amt = li.amounts[lineItem.dateId];
          if (amt && amt.amount == lineItem.amount) {
            amt.isDirty = false;
          }
        }
      }

      return temp;
    });
  }

  const handleAccountSelection = useCallback((account: IAccount | null) => {
    if (account !== null) {
      setLineItems((prev) => {
        const temp = [...prev];
        // check if the new account id has already been added
        const index = temp.findIndex((li) => li.id === account.id);

        if (index === -1) {
          temp.push({
            id: account.id,
            name: account.name,
            initials: account.name.charAt(0).toUpperCase(),
            secondaryText: account.center?.name ?? '',
            avatarUrl: null,
            amounts: {},
            isDeleted: false,
          });
        } else {
          let record = temp[index];
          record.isDeleted = false;
          record.amounts = {};
        }

        return temp;
      });
    }
  }, []);

  const handleChildSelection = useCallback((child: IAccountChild | null) => {
    if (child !== null) {
      setLineItems((prev) => {
        const temp = [...prev];
        // check if the new account id has already been added
        const index = temp.findIndex((li) => li.id === child.id);

        if (index === -1) {
          temp.push({
            id: child.id,
            name: child.firstname + ' ' + child.lastname,
            initials: child.firstname.charAt(0).toUpperCase() + child.lastname.charAt(0).toUpperCase(),
            secondaryText: child.status,
            avatarUrl: child.avatar?.url ?? '',
            amounts: {},
            isDeleted: false,
          });
        } else {
          let record = temp[index];
          record.isDeleted = false;
          record.amounts = {};
        }

        return temp;
      });
    }
  }, []);

  const handleRemoveLineItem = useCallback((id: string) => {
    if (id === null) return;
    setLineItems((prev) => {
      const temp = [...prev]; // do this for immutability
      const index = temp.findIndex((li) => li.id === id);
      if (index !== -1) {
        const li = temp[index];
        li.isDeleted = true;
      }
      return temp;
    });
  }, []);

  function getDefaultColumns(lineItems: LineItem[]) {
    const dateColumns: { [key: string]: Column } = {};
    for (let lineItem of lineItems) {
      const startDate = lineItem.startDate ?? '';
      const endDate = lineItem.endDate ?? '';
      const key = `${startDate}-${endDate}`;
      dateColumns[key] = { id: key, startDate: lineItem.startDate, endDate: lineItem.endDate, isDirty: false };
    }
    const dateColumnList = Object.values(dateColumns);
    for (let i = dateColumnList.length; i < 2; i++) {
      dateColumnList.push({ id: '', startDate: null, endDate: null, isDirty: false });
    }

    return dateColumnList.map((c, index) => ({ ...c, id: index.toString() }));
  }

  const handleDateChange = useCallback((id: string, startDate: string | null, endDate: string | null) => {
    setColumns((prev) => {
      const newState = [...prev];
      const changeIndex = newState.findIndex((c) => c.id === id);
      if (changeIndex !== -1) {
        newState.splice(changeIndex, 1, { id, startDate, endDate, isDirty: true });
      }
      return newState;
    });
  }, []);

  const handleAmountChange = useCallback((id: string, dateKey: string, amount) => {
    if (id === null) return;
    setLineItems((prev) => {
      const temp = [...prev];
      const li = temp.find((li) => li.id === id);
      if (li) {
        let id: string | null = null;
        if (li.amounts[dateKey]) {
          id = li.amounts[dateKey].id;
        }
        li.amounts[dateKey] = { id, amount: amount, isDirty: true };
      }

      return temp;
    });
  }, []);

  const { totalEntered, difference } = useMemo(() => {
    let totalEntered = calculateTotalEntered(lineItems);
    let difference = (agencyPayment?.amount ?? 0) - totalEntered;

    return { totalEntered, difference };
  }, [lineItems, agencyPayment]);

  const handleHeaderAmountChange = useCallback((amount: number) => {
    setAgencyPayment((prev) => {
      if (prev === undefined) {
        return prev;
      }

      const temp = { ...prev };
      temp.amount = amount;
      temp.isDirty = true;
      return temp;
    });
  }, []);
  const handleHeaderDateChange = useCallback((date: string) => {
    setAgencyPayment((prev) => {
      if (prev === undefined) {
        return prev;
      }

      const temp = { ...prev };
      temp.paymentDate = date;
      temp.isDirty = true;
      return temp;
    });
  }, []);
  const handleHeaderReferenceIdChange = useCallback((referenceId: string) => {
    setAgencyPayment((prev) => {
      if (prev === undefined) {
        return prev;
      }

      const temp = { ...prev };
      temp.referenceId = referenceId;
      temp.isDirty = true;
      return temp;
    });
  }, []);

  return {
    agencyPayment,
    columns,
    lineItems,
    totalEntered,
    difference,
    handleRemoveLineItem,
    handleDateChange,
    handleAccountSelection,
    handleChildSelection,
    handleAmountChange,
    handleHeaderAmountChange,
    handleHeaderDateChange,
    handleHeaderReferenceIdChange,
    loading,
    saving,
    lastSave,
    saveAgencySubsidyPayment,
  };
}

async function loadAgencyPayment({ paymentId, authToken }: { paymentId: string; authToken: string }) {
  const response = await axios.get(`${config.api.billing.uri}/api/v2/agency-payments/${paymentId}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
  return response.data;
}

async function autoSaveAgencyPayment({
  paymentId,
  authToken,
  saveBody,
}: {
  paymentId: string;
  authToken: string;
  saveBody: AutoSaveRequest;
}) {
  const response = await axios.patch(`${config.api.billing.uri}/api/v2/agency-payments/${paymentId}`, saveBody, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
  return response.data;
}

async function fullSaveAgencyPayment({
  paymentId,
  authToken,
  saveBody,
}: {
  paymentId: string;
  authToken: string;
  saveBody: FullSaveRequest;
}) {
  const response = await axios.post(`${config.api.billing.uri}/api/v2/agency-payments/${paymentId}`, saveBody, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
  return response.data;
}

function calculateTotalEntered(lineItems: DisplayLineItem[]) {
  let total = 0;

  lineItems.forEach((lineItem) => {
    for (const key of Object.keys(lineItem.amounts)) {
      total += lineItem.amounts[key].amount ?? 0;
    }
  });

  return total;
}

function convertLineItemsToDisplayLineItems(paymentFormat: string, lineItems: LineItem[], columns: Column[]) {
  let displayLineItems: DisplayLineItem[] = [];

  lineItems.forEach((lineItem) => {
    let column = columns.find((c) => c.startDate == lineItem.startDate && c.endDate == lineItem.endDate);

    if (column) {
      const idToSearch = paymentFormat == 'Child' ? lineItem.childId : lineItem.accountId;
      let displayLineItem = displayLineItems.find((dli) => dli.id == idToSearch);
      if (!displayLineItem) {
        displayLineItem = {
          id: paymentFormat == 'Child' ? lineItem.childId : lineItem.accountId,
          name:
            paymentFormat == 'Child'
              ? lineItem.childFirstName + ' ' + lineItem.childLastName
              : lineItem.accountName ?? '',
          initials:
            paymentFormat == 'Child'
              ? (lineItem.childFirstName ?? '').charAt(0).toUpperCase() +
                (lineItem.childLastName ?? '').charAt(0).toUpperCase()
              : (lineItem.accountName ?? '').charAt(0).toUpperCase(),
          secondaryText: paymentFormat == 'Child' ? lineItem.childStatus : lineItem.centerName,
          avatarUrl: lineItem.childAvatar,
          amounts: { [column.id]: { id: lineItem.id, amount: lineItem.amount ?? 0, isDirty: false } },
          isDeleted: false,
        };

        displayLineItems.push(displayLineItem);
      } else {
        displayLineItem.amounts[column.id] = { id: lineItem.id, amount: lineItem.amount ?? 0, isDirty: false };
      }
    }
  });

  return displayLineItems;
}

interface LineItem {
  id: string | null;
  accountId: string | null;
  accountName: string | null;
  centerName: string | null;
  childId: string | null;
  childFirstName: string | null;
  childLastName: string | null;
  childAvatar: string | null;
  childStatus: string | null;
  startDate: string;
  endDate: string;
  amount: number | null;
}
