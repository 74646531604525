import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';

export const CREATE_TIME_CARD_LOCK = gql`
  mutation ($input: CreateTimeCardLockInput!) {
    createTimeCardLockForBusiness(input: $input) {
      data {
        lockedUntil
      }
    }
  }
`;

export const useCreateTimeCardLock = (
  input?: MutationHookOptions<ITimeCardLock, { input: ICreateTimeCardLockInput }>
) => useMutation<ITimeCardLock, { input: ICreateTimeCardLockInput }>(CREATE_TIME_CARD_LOCK, input);
