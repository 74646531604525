import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { feeFields } from './fields';

//interfaces
interface ISearchFeesData {
  searchFees: {
    totalResults: number;
    data: IFee[];
  };
}

interface ISearchFeesVariables {
  input: IElasticsearchQuery;
}

interface IGetFeesByClassIdData {
  getFeesByClassId: IFee[];
}

interface IGetFeesByClassIdVariables {
  classId: string;
}

//queries
export const SEARCH_FEES = (fields: string = feeFields) => gql`
  query ($input: SearchInput!) {
    searchFees(input: $input) {
      totalResults
      data {
        ${fields}
      }
    }
  }
`;

export const GET_FEES_BY_CLASS_ID = (fields: string = feeFields) => gql`
  query ($classId: ID!) {
    getFeesByClassId(classId: $classId) {
      ${fields}
    }
  }
`;

//hooks
export const useSearchFees = (options?: QueryHookOptions<ISearchFeesData, ISearchFeesVariables>, fields?: string) =>
  useQuery<ISearchFeesData, ISearchFeesVariables>(SEARCH_FEES(fields), options);

export const useGetFeesByClassId = (
  options?: QueryHookOptions<IGetFeesByClassIdData, IGetFeesByClassIdVariables>,
  fields?: string
) => useQuery<IGetFeesByClassIdData, IGetFeesByClassIdVariables>(GET_FEES_BY_CLASS_ID(fields), options);
