import React, { useState } from 'react';
import ISCasesTable from 'pages/ISCases/components/ISCasesTable';
import ISCasesClaimsCreateModal from 'pages/ISCases/components/ISCasesClaimsCreateModal';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { useGetISCases } from 'gql/iSCase/queries';
import { ISCaseStatusEnum, ISCaseStatusLabelEnum } from 'shared/constants/enums/isCaseEnums';
import { useSubmitCaseClaim } from 'gql/iSClaim/mutations';
import { showToast } from 'shared/components/Toast';

interface ISort {
  field: string;
  direction: ElasticsearchSortDirection;
}
interface IProps {
  selectedBusiness: ITableFilterOption | null;
}

const ISCasesSummaryTab: React.FC<IProps> = ({ selectedBusiness }) => {
  const businessId = selectedBusiness?.value || '';
  const [showCreate, setShowCreate] = useState(false);
  const { data: centers } = useGetActiveCentersWithLoading();
  const [iSCaseSelected, setISCaseSelected] = useState<IISCase | null>(null);
  const [tableState, tableFunctions] = useDatatableState();
  const [sort, setSortOptions] = useState<ISort[]>([{ field: 'centerName', direction: 'ASCENDING' }]);
  const [activeFilters, setActiveFilters] = useState<IISCaseFilter>({
    centers: [],
    statuses: [
      {
        label: ISCaseStatusLabelEnum.ACTIVE,
        value: ISCaseStatusEnum.ACTIVE,
      },
    ],
    searchText: '',
  });

  const {
    data: isCasesData,
    loading,
    refetch: refetchCases,
  } = useGetISCases({
    variables: {
      input: {
        businessId,
        centerIds: activeFilters?.centers.map((center) => center.value) ?? [],
        includeActive:
          activeFilters.statuses?.length > 0
            ? Boolean(activeFilters.statuses?.find((option) => option.value === ISCaseStatusEnum.ACTIVE))
            : true,
        includeInActive:
          activeFilters.statuses?.length > 0
            ? Boolean(activeFilters.statuses?.find((option) => option.value === ISCaseStatusEnum.INACTIVE))
            : true,
        searchText: activeFilters.searchText,
        sortDtos: sort,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
      },
    },
  });

  const [submitCaseClaim, { loading: submitCaseClaimLoading }] = useSubmitCaseClaim({
    onCompleted: (res) => {
      showToast('Successfully created an IS Case Claim', 'success');
      setShowCreate(false);
      refetchCases();
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field, direction }]);
  };

  const handleFilterChange = (newFilters: IISCaseFilter) => {
    setActiveFilters(() => newFilters);
  };
  const openCreateClaimModal = (data: IISCase | null) => {
    setShowCreate(true);
    setISCaseSelected(data);
  };

  const submitClaim = (data: ISCaseClaimCreateInput) => {
    submitCaseClaim({
      variables: {
        input: { ...data, businessId },
      },
    });
  };

  return (
    <>
      <ISCasesClaimsCreateModal
        businessId={businessId}
        iSCase={iSCaseSelected}
        isOpen={showCreate}
        onSubmit={submitClaim}
        loading={submitCaseClaimLoading}
        onClose={() => setShowCreate(false)}
      />
      <ISCasesTable
        handleCreateClaim={openCreateClaimModal}
        data={isCasesData?.getIsCasesForCenters.data || []}
        dataSize={isCasesData?.getIsCasesForCenters.totalRecords || 0}
        loading={loading}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        onSort={handleSort}
        activeFilters={activeFilters}
        onActiveFilterChange={handleFilterChange}
        centers={centers || []}
        refetchCases={refetchCases}
      />
    </>
  );
};

export default ISCasesSummaryTab;
