import React, { useState, useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Button, { CirclePlusButton } from 'shared/components/Buttons';
import PageWrapper from 'shared/components/PageWrapper';
import TransactionsTable from './TransactionsTable';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import CreateTransactionModal from 'shared/components/CreateTransactionModal';
import CreateDepositModal from 'shared/components/CreateDepositModal';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import TransactionsReportModal from './TransactionsReportModal';
import ApplyDiscountToTransactionModal from './ApplyDiscountToTransactionModal';
import RemoveDiscountFromTransactionModal from './RemoveDiscountFromTransactionModal';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faPlus } from '@fortawesome/pro-light-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IModalStateShape {
  open: boolean;
  transaction: ITransaction | null;
}

interface IRemoveDiscountModalStateShape extends IModalStateShape {
  discountTransaction: ITransaction | null;
}

interface ITableFiltersShape {
  start: string;
  end: string;
  transactionTypeIds: string[];
}
interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

const Transactions: React.FC<IProps> = () => {
  const { bulkTransactions: canUseBulkTransactions } = useFlags();
  const history = useHistory();
  const canCreateTransaction = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });
  const canCreateBulkTransaction = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });

  const canCreateDeposit = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });

  const canCreateTransactionTypes = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.TransactionTypes,
    level: RoleLevelType.Create,
  });
  const [createTransactionModalIsOpen, setCreateTransactionModalIsOpen] = useState(false);
  const [createDepositModalIsOpen, setCreateDepositModalIsOpen] = useState(false);
  const [showTransactionsReportModal, setShowTransactionsReportModal] = useState<boolean>(false);
  const [applyDiscountToTransactionModal, setApplyDiscountToTransactionModal] = useState<IModalStateShape>({
    open: false,
    transaction: null,
  });
  const [removeDiscountModal, setRemoveDiscountModal] = useState<IRemoveDiscountModalStateShape>({
    open: false,
    transaction: null,
    discountTransaction: null,
  });
  const closeRemoveDiscountModal = () =>
    setRemoveDiscountModal({ open: false, transaction: null, discountTransaction: null });

  const [tableFilters, setTableFilters] = useState<ITableFiltersShape>({
    start: moment().startOf('month').format(),
    end: moment().endOf('month').format(),
    transactionTypeIds: [],
  });

  const { centerFilterIds } = useSelector((state: RootState) => state.context);

  const onTransactionTypeFilter = useCallback(
    (transactionTypeIds) => setTableFilters({ ...tableFilters, transactionTypeIds }),
    [tableFilters]
  );

  return (
    <PageWrapper
      pageTitle="Transactions"
      applyPadding={true}
      mobileButtonComponent={
        canCreateTransaction && (
          <CirclePlusButton variant="primary" className="my-4" onClick={() => setCreateTransactionModalIsOpen(true)} />
        )
      }
      buttonComponent={
        <div className="justify-content-end align-items-center page-btn-group row no-gutters">
          <HasRoleAreaLevel
            action={{ area: AreaType.Report, permission: PermissionType.Base, level: RoleLevelType.Read }}
          >
            <Button variant="secondary" className="mr-2" onClick={() => setShowTransactionsReportModal(true)}>
              Run Report
            </Button>
          </HasRoleAreaLevel>
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              <FontAwesomeIcon className="mr-2" icon={faPlus} />
              Add Transaction
              <FontAwesomeIcon className="ml-4" icon={faAngleDown} size="1x" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                key={'transaction'}
                onClick={() => setCreateTransactionModalIsOpen(true)}
                disabled={!canCreateTransaction}
              >
                Transaction
              </Dropdown.Item>
              {canUseBulkTransactions && (
                <Dropdown.Item
                  key={'bulk-transaction'}
                  onClick={() => history.push('/billing/bulk-transactions/create')}
                  disabled={!canCreateBulkTransaction}
                >
                  Bulk Transaction
                </Dropdown.Item>
              )}
              <Dropdown.Item
                key={'deposit'}
                onClick={() => setCreateDepositModalIsOpen(true)}
                disabled={!canCreateDeposit}
              >
                Deposit
              </Dropdown.Item>
              <Dropdown.Item
                key={'transactionType'}
                onClick={() => history.push('/billing/settings/transactions')}
                disabled={!canCreateTransactionTypes}
              >
                Transaction Type
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      }
    >
      <div className="mx-auto">
        <TransactionsTable
          dateRange={{ start: tableFilters.start, end: tableFilters.end }}
          setDateRange={(timeRange) =>
            setTableFilters((prev) => ({ ...prev, start: timeRange.start, end: timeRange.end }))
          }
          onTransactionTypeFilter={onTransactionTypeFilter}
          onAddDiscountToTransaction={(transaction) => setApplyDiscountToTransactionModal({ open: true, transaction })}
          onRemoveDiscountFromTransaction={(transaction, discountTransaction) =>
            setRemoveDiscountModal({ open: true, transaction, discountTransaction })
          }
        />
      </div>
      <CreateTransactionModal
        isOpen={createTransactionModalIsOpen}
        onClose={() => setCreateTransactionModalIsOpen(false)}
      />
      <CreateDepositModal isOpen={createDepositModalIsOpen} onClose={() => setCreateDepositModalIsOpen(false)} />
      {showTransactionsReportModal && (
        <TransactionsReportModal
          isOpen={showTransactionsReportModal}
          defaultFormValues={{
            start: tableFilters.start,
            end: tableFilters.end,
            centerIds: centerFilterIds,
            transactionTypeIds: tableFilters.transactionTypeIds,
          }}
          onClose={() => setShowTransactionsReportModal(false)}
        />
      )}
      {applyDiscountToTransactionModal.transaction && (
        <ApplyDiscountToTransactionModal
          isOpen={applyDiscountToTransactionModal.open}
          transaction={applyDiscountToTransactionModal.transaction}
          onClose={() => setApplyDiscountToTransactionModal({ open: false, transaction: null })}
        />
      )}
      {removeDiscountModal.transaction && removeDiscountModal.discountTransaction && (
        <RemoveDiscountFromTransactionModal
          isOpen={removeDiscountModal.open}
          transaction={removeDiscountModal.transaction}
          discountTransaction={removeDiscountModal.discountTransaction}
          onClose={closeRemoveDiscountModal}
        />
      )}
    </PageWrapper>
  );
};

export default Transactions;
