import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';
import { medicalConditionFields, medicalConditionWithDocumentsFields } from './fields';
import { useDispatch } from 'react-redux';
import {
  createMedicalConditionForChild,
  updateMedicalConditionForChild,
} from 'pages/Families/subroutes/Children/duck/actions';
import {
  createStaffMedicalCondition,
  deleteStaffMedicalCondition,
  updateStaffMedicalCondition,
} from 'store/employees/actions';
import { documentFields } from 'gql/document/fields';

interface ICreateStaffMedicalConditionData {
  createStaffMedicalCondition: IMedicalCondition;
}

interface ICreateStaffMedicalConditionVariables {
  staffId: string;
  input: IMedicalConditionInput;
}

interface ICreateChildMedicalConditionData {
  createChildMedicalCondition: IMedicalCondition;
}

interface ICreateChildMedicalConditionVariables {
  childId: string;
  input: IMedicalConditionInput;
}

interface IUpdateWellnessConditionDocumentsData {
  updateWellnessConditionDocuments: IWellnessDocument[];
}

interface IUpdateWellnessConditionDocumentsVariables {
  input: {
    wellnessConditionId: string;
    toCreate: IHealthConditionDocumentUploadInput[];
    toArchive: string[];
    toDelete: string[];
    toUpdate: {
      id: string;
      filename: string;
      expirationDate: string | null;
    }[];
  };
}

export const CREATE_STAFF_MEDICAL_CONDITION = (fields: string = medicalConditionFields) => gql`
  mutation($staffId: ID!, $input: MedicalConditionInput!) {
    createStaffMedicalCondition(staffId: $staffId, input: $input) {
      ${fields}
    }
  }
`;

export const CREATE_CHILD_MEDICAL_CONDITION = (fields: string = medicalConditionWithDocumentsFields) => gql`
  mutation($childId: ID!, $input: MedicalConditionInput!) {
    createChildMedicalCondition(childId: $childId, input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE__MEDICAL_CONDITION = (fields: string = medicalConditionWithDocumentsFields) => gql`
  mutation($id: ID!, $input: MedicalConditionInput!) {
    updateMedicalCondition(id: $id, input: $input) {
      ${fields}
    }
  }
`;

export const DELETE__MEDICAL_CONDITION = (fields: string = medicalConditionFields) => gql`
  mutation($id: ID!) {
    deleteMedicalCondition(id: $id) {
      ${fields}
    }
  }
`;

export const ARCHIVE__MEDICAL_CONDITION = (fields: string = medicalConditionFields) => gql`
  mutation($id: ID!) {
    archiveMedicalCondition(id: $id) {
      ${fields}
    }
  }
`;

export const UPDATE_WELLNESS_CONDITION_DOCUMENTS = () => gql`
  mutation($input: UpdateWellnessConditionDocumentsInput!) {
    updateWellnessConditionDocuments(input: $input) {
      ${documentFields}
    }
  }
`;

export const useCreateStaffMedicalCondition = (
  options?: MutationHookOptions<ICreateStaffMedicalConditionData, ICreateStaffMedicalConditionVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useMutation<ICreateStaffMedicalConditionData, ICreateStaffMedicalConditionVariables>(
    CREATE_STAFF_MEDICAL_CONDITION(fields),
    {
      ...options,
      onCompleted: (response) => {
        dispatch(createStaffMedicalCondition(response.createStaffMedicalCondition));
      },
    }
  );
};

export const useCreateChildMedicalCondition = (
  options?: MutationHookOptions<ICreateChildMedicalConditionData, ICreateChildMedicalConditionVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useMutation<ICreateChildMedicalConditionData, ICreateChildMedicalConditionVariables>(
    CREATE_CHILD_MEDICAL_CONDITION(fields),
    {
      ...options,
      onCompleted: (response) => {
        dispatch(createMedicalConditionForChild(response.createChildMedicalCondition));
      },
    }
  );
};

export const useUpdateMedicalCondition = (
  options?: MutationHookOptions<
    { updateMedicalCondition: IMedicalCondition },
    { id: string; input: IMedicalConditionInput }
  >,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useMutation<{ updateMedicalCondition: IMedicalCondition }, { id: string; input: IMedicalConditionInput }>(
    UPDATE__MEDICAL_CONDITION(fields),
    {
      ...options,
      onCompleted: (response) => {
        if (response.updateMedicalCondition.childId) {
          dispatch(updateMedicalConditionForChild(response.updateMedicalCondition));
        }
        if (response.updateMedicalCondition.personId) {
          dispatch(updateStaffMedicalCondition(response.updateMedicalCondition));
        }
      },
    }
  );
};

export const useDeleteMedicalCondition = (
  options?: MutationHookOptions<{ deleteMedicalCondition: IMedicalCondition }, { id: string }>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useMutation<{ deleteMedicalCondition: IMedicalCondition }, { id: string }>(DELETE__MEDICAL_CONDITION(fields), {
    ...options,
    onCompleted: (response) => {
      dispatch(deleteStaffMedicalCondition(response.deleteMedicalCondition));
    },
  });
};

export const useArchiveMedicalCondition = (
  options?: MutationHookOptions<{ archiveMedicalCondition: IMedicalCondition }, { id: string }>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useMutation<{ archiveMedicalCondition: IMedicalCondition }, { id: string }>(
    ARCHIVE__MEDICAL_CONDITION(fields),
    {
      ...options,
      onCompleted: (response) => {
        if (response.archiveMedicalCondition.childId) {
          dispatch(updateMedicalConditionForChild(response.archiveMedicalCondition));
        } else if (response.archiveMedicalCondition.personId) {
          dispatch(updateStaffMedicalCondition(response.archiveMedicalCondition));
        }
      },
    }
  );
};

export const useUpdateWellnessConditionDocuments = (
  options?: MutationHookOptions<IUpdateWellnessConditionDocumentsData, IUpdateWellnessConditionDocumentsVariables>,
  fields?: string
) =>
  useMutation<IUpdateWellnessConditionDocumentsData, IUpdateWellnessConditionDocumentsVariables>(
    UPDATE_WELLNESS_CONDITION_DOCUMENTS(),
    {
      ...options,
    }
  );
