import React from 'react';
import moment from 'moment';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import { TableSearch } from 'shared/components/DataTable';
import { sortBy } from 'lodash';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';

interface IProps {
  classOptions: IClass[];
  classIds: string[] | null;
  setClassIds: (classIds: string[] | null) => void;
  timezone: string;
  selectedClasses?: IClass[] | null;
  setDate: (date: string) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const GapWaiverHeader: React.FC<IProps> = ({
  classOptions,
  classIds,
  setClassIds,
  timezone,
  setDate,
  selectedClasses,
  searchTerm,
  setSearchTerm,
  startDate,
  endDate,
}) => {
  const dateSelectionClasses = selectedClasses?.length ? selectedClasses : classOptions;
  const latestPossibleDate =
    dateSelectionClasses?.length && dateSelectionClasses.every((c) => c.endsAt)
      ? sortBy(dateSelectionClasses, 'endsAt').reverse()[0].endsAt
      : null;
  const earliestPossibleDate = dateSelectionClasses?.length
    ? sortBy(dateSelectionClasses, 'startsAt')[0].startsAt
    : null;

  const classFilterOptions = classOptions?.map((c) => ({ label: c.name, value: c.id }));

  return (
    <div className="bg-white p-4 d-flex justify-content-between border-bottom">
      <TableSearch
        className="max-width-200 mr-2 mb-2"
        onChange={setSearchTerm}
        placeholder="Search"
        value={searchTerm}
      />
      <WeekPicker
        className="flex-grow-0 mb-0 mr-2"
        displayFormat="MMM D"
        startDate={startDate}
        endDate={endDate}
        rangeComponent={<span className="mx-1">-</span>}
        onChange={(dates) => setDate(dates.startDate.format())}
        reactDatesController="RANGE"
        isOutsideRange={(date: moment.Moment) =>
          Boolean(
            (latestPossibleDate && moment(date).tz(timezone).isAfter(moment(latestPossibleDate).tz(timezone), 'd')) ||
              (earliestPossibleDate &&
                moment(date).tz(timezone).isBefore(moment(earliestPossibleDate).tz(timezone), 'd'))
          )
        }
      />
      <DropdownFilter
        className="mb-0 max-width-200 flex-wrap"
        options={classFilterOptions}
        title={'All Classes'}
        onFilterSelect={(options) => {
          setClassIds(options?.map((c) => c.value));
        }}
        selectedFilters={classFilterOptions?.filter((c) => classIds?.includes(c.value))}
      />
    </div>
  );
};

export default GapWaiverHeader;
