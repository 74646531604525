import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '_colors.module.scss';
import { Col, Row } from 'shared/components/Layout';
import _ from 'lodash';

interface IProps {
  areaTitle: string;
}

const FormDiffArea: React.FC<IProps> = ({ areaTitle, ...props }) => {
  const { t } = useTranslation(['translation', 'enrollment']);

  return (
    <>
      <div className="mb-3 mt-2">
        <b>{areaTitle}</b>
      </div>
      <div className="difference-list mb-6">{props.children}</div>
    </>
  );
};

export default FormDiffArea;
