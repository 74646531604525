import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionDropdown from 'shared/components/ActionDropdown';
import DataTable from 'shared/components/DataTable';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import useFormatDate from 'shared/hooks/useFormatDate';
import { capitalize, getInitials, stringToHsl } from 'shared/util/string';
import colors from '_colors.module.scss';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { showToast } from 'shared/components/Toast';
import i18n from 'i18n';
import { useArchiveChildProgram, useUpdateChildProgram } from 'gql/programChild/mutation';
import { Col, Row } from 'shared/components/Layout';
import moment from 'moment';
import Checkbox from 'shared/components/Checkbox';
import { concessionTypeEnum } from 'shared/constants/enums/programEnum';
import { find } from 'lodash';
import './programChildTable.scss';
import EditProgramModal from '../EditProgramModal/EditProgramModal';
import customEvents from 'shared/constants/customEvents';
import EditIneligibilityModal from '../EditProgramModal/EditIneligibilityModal';
import { useCreateEditKindyForAllIneligibilities, useUpdateChildFundingSchedules } from 'gql/kindyForAll/mutations';
import { KindyChildFundingSchedulesResult, KindyForAllIneligibilityDto } from 'generated/graphql';

const refetchAccountDocuments = new Event(customEvents.REFETCH_ACCOUNT_DOCUMENTS);
const QGrantsSubsidySchemeIds = [
  '00000000-0000-0000-0000-000000000001' /* Kindy For All */,
  '00000000-0000-0000-0000-000000000003' /* Free Kindy */,
];

interface ITableProps {
  loading: boolean;
  businessId: string;
  account: IAccount;
  data: IProgramChild[];
  fundingSchedules: KindyChildFundingSchedulesResult[];
  refetch: () => void;
  refetchFundingSchedules: () => void;
}
const expandRowDefault = (row: IProgramChild) => <ExpandRowDefaultComponent row={row} />;

const HeadingCaption = (props: { heading: string; caption: string }) => {
  return (
    <>
      <div>
        <small>{props.heading}</small>
      </div>
      <div>
        <small className="caption">{props.caption}</small>
      </div>
    </>
  );
};

const ExpandRowDefaultComponent: React.FC<{ row: IProgramChild }> = ({ row }) => {
  const { t } = useTranslation(['translation', 'subsidies']);
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  const dateFormat = t('translation:formatters.MM/DD/YYYY');
  return (
    <div>
      {!!row.familyTaxBenefit && (
        <>
          <Row align="start">
            <Col>
              <h6>{t('subsidies:program-child.create-modal.family-tax-benefit-info')}</h6>
            </Col>
          </Row>
          <Row align="start">
            <Col>
              <HeadingCaption heading="" caption={row.familyTaxBenefit.part.replace(/([A-Z])/g, ' $1').trim()} />
            </Col>
          </Row>
          <Row align="start" className="mb-4">
            <Col>
              {!!row.familyTaxBenefit.documentUri && (
                <a href={row.familyTaxBenefit.documentUri} target="_blank">
                  <small className="caption program-child-table link-to-file">
                    {t('subsidies:program-child.list.link-to-file')}
                  </small>
                </a>
              )}
            </Col>
          </Row>
        </>
      )}
      {!!row.concessionCard && (
        <>
          <Row align="start">
            <Col>
              <h6>{t('subsidies:program-child.create-modal.concession-card')}</h6>
            </Col>
          </Row>
          <Row align="start" className="mb-4">
            <Col md="3">
              <HeadingCaption
                heading={t('subsidies:program-child.create-modal.concession-type-label')}
                caption={
                  find(concessionTypeEnum, (status) => status.value === row.concessionCard.type)?.label ??
                  capitalize(t('translation:spelling.unknown'))
                }
              />
            </Col>
            <Col md="3">
              <HeadingCaption
                heading={t('subsidies:program-child.create-modal.concession-contact-label')}
                caption={`${row.concessionCard.contactFirstName} ${row.concessionCard.contactLastName}`}
              />
            </Col>
            <Col md="3">
              <HeadingCaption
                heading={t('subsidies:program-child.create-modal.concession-effective-date-label')}
                caption={
                  !!row.concessionCard.effectiveDate
                    ? moment(row.concessionCard.effectiveDate).format(dateFormat)
                    : 'N/A'
                }
              />
            </Col>
            <Col md="3">
              <HeadingCaption
                heading={t('subsidies:program-child.create-modal.concession-expiry-label')}
                caption={
                  !!row.concessionCard.expiryDate ? moment(row.concessionCard.expiryDate).format(dateFormat) : 'N/A'
                }
              />
            </Col>
            <Col md="3">
              <div>
                <small>&nbsp;</small>
              </div>
              <div>
                {!!row.concessionCard.documentUri && (
                  <a href={row.concessionCard.documentUri} target="_blank">
                    <small className="caption program-child-table link-to-file">
                      {t('subsidies:program-child.list.link-to-file')}
                    </small>
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
      {!!row.delayedExit && (
        <>
          <Row align="start">
            <Col>
              <h6>{t('subsidies:program-child.create-modal.delayed-exit-info')}</h6>
            </Col>
          </Row>
          <Row align="start">
            <Col>
              <HeadingCaption
                heading={t('subsidies:program-child.create-modal.delayed-notes-label')}
                caption={row.delayedExit.notes}
              />
            </Col>
          </Row>
          <Row align="start" className="mb-4">
            <Col>
              {!!row.delayedExit.documentUri && (
                <a href={row.delayedExit.documentUri} target="_blank">
                  <small className="caption program-child-table link-to-file">
                    {t('subsidies:program-child.list.link-to-file')}
                  </small>
                </a>
              )}
            </Col>
          </Row>
        </>
      )}
      {row.child.multipleBirths && (
        <>
          <Row align="start">
            <Col>
              <h6>{t('subsidies:program-child.create-modal.multi-births-info')}</h6>
            </Col>
          </Row>
          <Row align="start">
            <Col>
              <div className="ml-2">
                <Checkbox
                  value={row.child.multipleBirths}
                  label={t('subsidies:program-child.list.multi-child-birth')}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

const ProgramChildTable: React.FC<ITableProps> = ({
  businessId,
  account,
  data,
  fundingSchedules,
  loading,
  refetch,
  refetchFundingSchedules,
}) => {
  const { t } = useTranslation(['translation', 'subsidies']);
  const [archiveChildProgramFn, { loading: isArchivingChild }] = useArchiveChildProgram();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditIneligibilityModal, setShowEditIneligibilityModal] = useState(false);
  const [programChildToEdit, setProgramChildToEdit] = useState<IProgramChild | undefined>(undefined);
  const [childFundingSchedules, setChildFundingSchedules] = useState<
    IKindyProgramChildFundingSchedulesInput | undefined
  >(undefined);
  const [childIneligibilityToEdit, setChildIneligibilityToEdit] = useState<IProgramChild | undefined>(undefined);
  const formatDate = useFormatDate();

  const [updateChildProgram, { loading: updateLoading }] = useUpdateChildProgram({
    onError: () => {
      showToast(t('subsidies:program-child.update-modal.error-saving'), 'error');
    },
    onCompleted: () => {
      setShowEditModal(false);
      refetch();
      document.dispatchEvent(refetchAccountDocuments);
      showToast(t('subsidies:program-child.update-modal.success'), 'success');
    },
  });

  const [updateChildFundingSchedules, { loading: loadingFundingScheduleMutation }] = useUpdateChildFundingSchedules({
    onError: () => {
      showToast(t('subsidies:automated-funding.child.error'), 'error');
    },
    onCompleted: () => {
      refetchFundingSchedules();
    },
  });

  const [updateChildIneligibility, { loading: updateIneligibilityLoading }] = useCreateEditKindyForAllIneligibilities({
    onError: () => {
      showToast(t('subsidies:child-ineligibilities.update-modal.error-saving'), 'error');
    },
    onCompleted: () => {
      setShowEditIneligibilityModal(false);
      refetch();
      showToast(t('subsidies:child-ineligibilities.update-modal.success'), 'success');
    },
  });

  const handleUpdate = (values: IProgramChildAndFundingSchedulesUpdateInput) => {
    updateChildProgram({
      variables: {
        input: {
          businessId: values.businessId,
          programId: values.programId,
          programChild: values.programChild,
          delayedExit: values.delayedExit,
          familyTaxBenefit: values.familyTaxBenefit,
          multipleBirth: values.multipleBirth,
          concessionCard: values.concessionCard,
          associatedAccountId: values.associatedAccountId,
          primaryAccountId: values.primaryAccountId,
          lastFundPaidDate: values.lastFundPaidDate,
          childReceivesAccs: values.childReceivesAccs,
          kindyForAllChildren: values.kindyForAllChildren,
          startStrongChildren: values.startStrongChildren,
        },
      },
    });

    updateChildFundingSchedules({
      variables: {
        input: {
          programChildId: values.childFundingSchedules?.programChildId ?? '',
          schedules: values.childFundingSchedules?.schedules ?? [],
        },
      },
    });
  };

  const handleIneligbilityUpdate = (values: KindyForAllIneligibilityDto[]) => {
    updateChildIneligibility({
      variables: {
        input: { ineligibilities: values },
      },
    });
  };

  const getSchedules = useCallback(
    (programChildId: string) => {
      const schedules = fundingSchedules
        .filter((fs) => fs.programChildId == programChildId)
        .map(
          (fs) =>
            ({
              startDate: fs.startDate,
              subsidyTypes: fs.subsidyTypes,
              basePercentage: fs.basePercentage,
            } as IKindyProgramChildFundingSchedule)
        );

      return {
        programChildId: programChildId,
        schedules: schedules ?? [],
      } as IKindyProgramChildFundingSchedulesInput;
    },
    [fundingSchedules]
  );

  const actionsForRow = useCallback(
    (cell: any, row: IProgramChild): IDropdownAction[] => {
      const standardActions: IDropdownAction[] = [
        {
          label: 'Edit',
          permission: { permission: 'Base', area: 'Agency', level: RoleLevelType.Edit },
          onClick: () => {
            setProgramChildToEdit(row);
            setChildFundingSchedules(getSchedules(row.id));
            setShowEditModal(true);
          },
        },
        {
          label: t('translation:core.capitalize', { value: t('translation:spelling.delete') }),
          permission: { permission: 'Base', area: 'Agency', level: RoleLevelType.Delete },
          onClick: () => {
            archiveChildProgramFn({
              variables: {
                input: {
                  programChildId: row.id,
                },
              },
            }).then((value) => {
              showToast(i18n.t('translation:general.updateSuccessful'), 'success');
              refetch();
            });
          },
        },
      ];

      const updateInEligibilityAction: IDropdownAction = {
        label: 'Update Eligibility',
        permission: { permission: 'Base', area: 'Agency', level: RoleLevelType.Edit },
        onClick: () => {
          setChildIneligibilityToEdit(row);
          setShowEditIneligibilityModal(true);
        },
      };

      const isQGrantsProgram = QGrantsSubsidySchemeIds.includes(row.program?.subsidyScheme?.subsidySchemeId ?? '');
      return isQGrantsProgram ? standardActions.concat(updateInEligibilityAction) : standardActions;
    },
    [t, archiveChildProgramFn, account, businessId, refetch, getSchedules]
  );

  return (
    <div>
      <DataTable
        noPadding
        data={data}
        handleRowClick={() => {}}
        showLoadingOverlay={loading}
        showSelect={false}
        showPagination={false}
        expandHeaderColumnRenderer={(props) => <></>}
        expandRow={expandRowDefault}
        columns={[
          {
            text: t('subsidies:program-label'),
            dataField: 'program.name',
          },
          {
            text: t('subsidies:program.child'),
            dataField: 'child',
            formatter: (cell: string, row: IProgramChild) => (
              <AvatarDataTableCell
                color={stringToHsl(row.child.id)}
                initials={getInitials(row.child)}
                avatar={row.child.avatar?.url ?? ''}
                primaryText={row.child.fullName}
              />
            ),
          },
          {
            text: t('subsidies:program.start-date'),
            dataField: 'startDate',
            formatter: (cell: string, row: IProgramChild) =>
              row.startDate ? formatDate(row.startDate, 'MMM-D-YYYY').toUpperCase() : '',
          },
          {
            text: t('subsidies:program.end-date'),
            dataField: 'endDate',
            formatter: (cell: string, row: IProgramChild) =>
              row.endDate ? formatDate(row.endDate, 'MMM-D-YYYY').toUpperCase() : '',
          },
          {
            text: t('subsidies:program.status'),
            dataField: 'isActive',
            formatter: (cell: string, row: IProgramChild) =>
              row.isActive ? (
                <ColoredBackgroundTag color={colors.success} text="Active" />
              ) : (
                <ColoredBackgroundTag color={colors.gray} text="Inactive" />
              ),
          },
          {
            text: t('subsidies:program.amount-received'),
            dataField: 'amount',
            formatter: (cell: string, row: IProgramChild) => (cell ? `$${cell}` : ''),
            hidden: true,
          },
          {
            text: capitalize(t('translation:spelling.actions')),
            dataField: '',
            align: 'center',
            headerClasses: 'text-center',
            classes: 'td-overflow',
            formatter: (cell: any, row: IProgramChild) => <ActionDropdown actions={actionsForRow(cell, row)} />,
          },
        ]}
      />
      {programChildToEdit && childFundingSchedules && (
        <EditProgramModal
          handleClose={() => setShowEditModal(false)}
          isOpen={showEditModal}
          programChild={programChildToEdit}
          childFundingSchedules={childFundingSchedules}
          account={account}
          handleSubmit={(values: IProgramChildAndFundingSchedulesUpdateInput) => handleUpdate(values)}
          isLoading={updateLoading || loadingFundingScheduleMutation}
        />
      )}
      {childIneligibilityToEdit && (
        <EditIneligibilityModal
          handleClose={() => setShowEditIneligibilityModal(false)}
          isOpen={showEditIneligibilityModal}
          programChild={childIneligibilityToEdit}
          centerId={account.centerId}
          handleSubmit={(values: KindyForAllIneligibilityDto[]) => handleIneligbilityUpdate(values)}
          isLoading={updateIneligibilityLoading}
        />
      )}
    </div>
  );
};

export default ProgramChildTable;
