import React, { useCallback, useEffect } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { ButtonProps } from 'react-bootstrap/Button';
import Button from 'shared/components/Buttons';
import { Row, Col } from 'shared/components/Layout';
import './_modalDrawer.scss';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { isRegion } from 'shared/util/region';

const NO_OP = () => {};

export interface DropdownItemOption {
  key: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface IProps extends ModalProps {
  alignment?: string;
  show: boolean;
  onHide?: () => void;
  primaryCallback?: () => void;
  secondaryCallback?: () => void;
  tertiaryCallback?: () => void;
  title: string;
  subtitle?: string;
  primaryChoice?: string;
  secondaryChoice?: string;
  tertiaryChoice?: string;
  primaryButtonProps?: ButtonProps & { loading?: boolean };
  secondaryButtonProps?: ButtonProps & { loading?: boolean };
  tertiaryButtonProps?: ButtonProps & { loading?: boolean };
  footerHelperText?: string;
  closeOnPrimaryCallback?: boolean;
  closeOnSecondaryCallback?: boolean;
  children: React.ReactNode | null;
  hasFooter?: boolean;
  hasPrimaryChoice?: boolean;
  hasSecondaryChoice?: boolean;
  className?: string;
  isPrimaryDropdown?: boolean;
  primaryDropdownOptions?: DropdownItemOption[];
}

const ModalDrawer: React.FC<IProps> = ({
  alignment = 'right',
  title = '',
  subtitle = '',
  onHide = NO_OP,
  primaryChoice = 'Save',
  primaryCallback = NO_OP,
  primaryButtonProps = {},
  isPrimaryDropdown = false,
  primaryDropdownOptions = [],
  secondaryChoice = 'Cancel',
  secondaryCallback = NO_OP,
  secondaryButtonProps = {},
  tertiaryChoice = undefined,
  tertiaryButtonProps = {},
  tertiaryCallback = NO_OP,
  closeOnPrimaryCallback = true,
  closeOnSecondaryCallback = true,
  footerHelperText,
  children,
  hasFooter = true,
  hasPrimaryChoice = true,
  hasSecondaryChoice = true,
  className,
  show,
  ...rest
}) => {
  const isAU = isRegion('AU');

  const affirmativeCallbackFunction = useCallback(() => {
    if (onHide && closeOnPrimaryCallback) onHide();
    if (primaryCallback) primaryCallback();
  }, [onHide, primaryCallback, closeOnPrimaryCallback]);

  const secondaryCallbackFunction = useCallback(() => {
    if (onHide && closeOnSecondaryCallback) onHide();
    if (secondaryCallback) secondaryCallback();
  }, [onHide, secondaryCallback, closeOnSecondaryCallback]);

  const renderDropdown = () => (
    <Dropdown as={ButtonGroup} size="sm" variant="primary" title="Drop small">
      <Button variant="primary" {...primaryButtonProps} onClick={affirmativeCallbackFunction}>
        {primaryChoice}
      </Button>

      <Dropdown.Toggle split variant="primary" id="dropdown-split-basic">
        <FontAwesomeIcon icon={faChevronDown} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {primaryDropdownOptions.map((option) => (
          <Dropdown.Item onClick={option.onClick} key={option.key} disabled={option.disabled ?? false}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  useEffect(() => {
    const chatBubble = document.getElementById('fc_widget');

    if (chatBubble) {
      chatBubble.style.display = show ? 'none' : 'block';
    }
  }, [show, isAU]);

  return (
    <Modal
      className={`modal-drawer-${alignment} ${className ?? ''}`}
      show={show}
      onHide={onHide}
      backdrop="static"
      {...rest}
    >
      <Modal.Header closeButton className="border-bottom-0 pb-4">
        <div className="d-flex flex-column">
          <Modal.Title as="h3">{title}</Modal.Title>
          {subtitle && <p>{subtitle}</p>}
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0">{children}</Modal.Body>
      {hasFooter && (
        <Modal.Footer className="border-top-0">
          <Col>
            <Row>
              <small className="mb-4 ml-auto">{footerHelperText}</small>
            </Row>
            <Row>
              <div className="d-flex justify-content-between controls w-100">
                <div>
                  {tertiaryChoice && (
                    <Button
                      id="modal-drawer-secondary-btn"
                      className="mr-4"
                      variant="light"
                      {...tertiaryButtonProps}
                      onClick={tertiaryCallback}
                    >
                      {tertiaryChoice}
                    </Button>
                  )}
                </div>
                <div>
                  {hasSecondaryChoice && (
                    <Button
                      id="modal-drawer-secondary-btn"
                      className="mr-4"
                      variant="light"
                      {...secondaryButtonProps}
                      onClick={secondaryCallbackFunction}
                    >
                      {secondaryChoice}
                    </Button>
                  )}
                  {hasPrimaryChoice ? (
                    isPrimaryDropdown ? (
                      renderDropdown()
                    ) : (
                      <Button
                        id="modal-drawer-primary-btn"
                        variant="primary"
                        {...primaryButtonProps}
                        onClick={affirmativeCallbackFunction}
                      >
                        {primaryChoice}
                      </Button>
                    )
                  ) : null}
                </div>
              </div>
            </Row>
          </Col>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalDrawer;
