import { omitBy, isNil, isPlainObject } from 'lodash';
import { isBlank } from './string';

//  object where keys are strings and value is anything
interface IStringKeyObject {
  [key: string]: any;
}

// custom object with an __typename property (present from apollo)
interface IPossibleApolloObject extends IStringKeyObject {
  __typename?: string;
}

/**
 * Remove null and undefined properties of provided object
 * @param {Object} obj
 * @returns {Object}
 */
export const omitNil = (obj: object): object => omitBy(obj, isNil);

/**
 * Removes all falsy properties of the provided object
 * - empty string
 * - 0, number
 * - false
 * - null
 * - undefined
 * @param {Object} obj
 * @returns {Object}
 */
export const omitFalsy = (obj: object): object =>
  obj
    ? Object.entries(obj).reduce(
        (accumulator, [key, value]) => (value ? { ...accumulator, [key]: value } : accumulator),
        {}
      )
    : {};

/**
 * Remove `__typename` from an object if present. Returns a new object.
 * @param {Object} obj - object to remove __typename from
 * @param {Boolean} [deep = false] - should nested objects have __typename removed if found. Default value is `false`.
 * @returns {Object}
 */
export const omitTypename = <T>(obj: IPossibleApolloObject, deep: boolean = false): T => {
  const _obj = { ...obj };

  _obj.__typename && delete _obj.__typename;

  if (deep) {
    Object.keys(_obj).forEach((key) => {
      if (key === '__typename') {
        delete _obj[key];
      } else if (isPlainObject(_obj[key])) {
        // run recursively if the value of this key is an object created via the object constructor
        _obj[key] = omitTypename(_obj[key], true);
      } else if (Array.isArray(_obj[key])) {
        _obj[key] = _obj[key].map((element: any) => (isPlainObject(element) ? omitTypename(element, true) : element));
      }
    });
  }

  return _obj as T;
};

/**
 * Checks whether object has empty string props
 * @param {Object} obj
 * @param {String[]?} ignoreProps
 * @returns {Boolean}
 */
export const somePropsEmpty = (obj: IStringKeyObject, ignoreProps?: String[]): boolean => {
  if (!obj) return false;
  return Object.keys(obj)
    .filter((key) => !ignoreProps || !ignoreProps.includes(key))
    .some(
      (key) =>
        obj[key] === undefined ||
        obj[key] === null ||
        isBlank(obj[key]) ||
        (Array.isArray(obj[key]) && !obj[key].length)
    );
};

/**
 * Remove keys from an object
 * @param {Object} obj - object to remove keys from
 * @param {String[]} keys - array of keys to remove
 * @returns {String}
 */
export const omitKeys = (obj: IStringKeyObject, keys: string[]): object => {
  // if an empty array is provided return early
  if (!keys.length) {
    return obj;
  }

  return Object.keys(obj).reduce((acc: IStringKeyObject, key: string) => {
    if (!keys.includes(key)) {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

/**
 * Turn an array of values into an object
 * @param {Array} arr - array of desired keys
 * @param {*} defaultValue - value to set for keys. Default value is null
 * @returns {Object}
 */
export const arrayToObject = (arr: (string | number)[], defaultValue: any = null): object => {
  if (!arr.length) {
    return {};
  }

  return arr.reduce((acc: IStringKeyObject, key: string | number) => {
    acc[key] = defaultValue;

    return acc;
  }, {});
};

/**
 * Add `__typename` to an object. Returns a new object.
 * @param {Object} obj - object to add __typename to
 * @param {string} __typename - __typename to add
 * @param {Boolean} [deep = true] - should nested objects have __typename added. Default value is `true`.
 * @returns {Object}
 */
export const addTypename = (obj: IPossibleApolloObject, __typename: string, deep: boolean = true) => {
  const _obj = { ...obj };
  _obj.__typename = __typename;

  if (deep) {
    Object.keys(_obj).forEach((key) => {
      if (isPlainObject(_obj[key])) {
        // run recursively if the value of this key is an object created via the object constructor
        _obj[key] = addTypename(_obj[key], key);
      } else if (Array.isArray(_obj[key])) {
        _obj[key] = _obj[key].map((element: any) => (isPlainObject(element) ? addTypename(element, key) : element));
      }
    });
  }
  return _obj;
};
