import React from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { IconButtonCircle } from 'shared/components/Buttons';
import { Row, Col } from 'shared/components/Layout';
import cast from 'shared/util/cast';

interface IProps {
  selectedStates: ITableFilterOption[];
  onSearch: (term: string) => void;
  onStateSelect: (states: ITableFilterOption[]) => void;
}

const FilterGroup: React.FC<IProps> = ({ selectedStates, onSearch, onStateSelect, ...props }) => {
  return (
    <div className="d-flex flex-row flex-grow-1">
      <Row align="center" className="flex-grow-1">
        <Col xs={12} md={9} className="mt-2 mb-2">
          <TableSearch placeholder="Search Regulations" onChange={onSearch} />
        </Col>
        <Col xs="auto" className="mt-2 mb-2">
          <DropdownFilter
            title="State"
            selectedFilters={selectedStates}
            options={cast<ITableFilterOption[]>(STATE_SELECT_OPTIONS['US'])}
            onFilterSelect={onStateSelect}
          />
        </Col>
        <Col xs="auto" className="mt-2 mb-2">
          <IconButtonCircle
            icon={faTimes}
            onClick={() => onStateSelect([])}
            disabled={!selectedStates.length}
            tooltipDirection="bottom"
            tooltipText="Clear Filters"
            className="ml-4"
          />
        </Col>
      </Row>
    </div>
  );
};

export default FilterGroup;
