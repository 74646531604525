import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'shared/components/Layout';
import ModalDrawer from 'shared/components/ModalDrawer';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import { useGetAccsReportLazy } from 'gql/reports/queries';
import useReportDataToFile from 'pages/Reporting/useReportDataToFile';
import { ReportTypeEnum } from 'shared/constants/enums/reportingEnums';
import { showToast } from 'shared/components/Toast';

interface IFormShape {
  centerIds: string[];
}

interface IProps {
  isOpen: boolean;
  defaultFormValues: {
    centerIds: string[];
  };
  onClose: () => void;
}

const AccsReportModal: React.FC<IProps> = ({ isOpen, defaultFormValues, onClose, ...props }) => {
  const { t } = useTranslation();
  const reportDataToFile = useReportDataToFile();
  const user = useSelector((state: RootState) => state.user);
  const [formData, setFormData] = useState<IFormShape>({
    centerIds: defaultFormValues.centerIds ?? [],
  });

  const [getAccsReportFn, { loading }] = useGetAccsReportLazy({
    onCompleted: (result) => {
      reportDataToFile.downloadXlsxFromBase64(result.getAccsReport, ReportTypeEnum.ACCS);
      handleClose();
    },
    onError: () => showToast(t('reports.general-failure-message'), 'error'),
  });

  const formDisabled = !formData.centerIds.length;

  const handleClose = useCallback(() => {
    setFormData({
      centerIds: [],
    });
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if (formData.centerIds) {
      getAccsReportFn({
        variables: {
          businessId: user?.entityId ?? '',
          input: {
            centerIds: formData.centerIds,
          },
        },
      });
    }
  }, [getAccsReportFn, formData, user]);

  return (
    <ModalDrawer
      title="ACCS Report"
      show={isOpen}
      onHide={handleClose}
      primaryButtonProps={{ disabled: formDisabled, loading }}
      primaryChoice="Run Report"
      primaryCallback={handleSubmit}
      closeOnPrimaryCallback={false}
      secondaryCallback={handleClose}
    >
      <Row>
        <Col>
          <MultipleCenterSelect
            selectedCenterIds={formData.centerIds}
            onSelect={(centerIds) => setFormData((prev) => ({ ...prev, centerIds: centerIds as string[] }))}
          />
        </Col>
      </Row>
    </ModalDrawer>
  );
};

export default AccsReportModal;
