import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Page, Section } from 'shared/types/enrollment-form';
import Button from 'shared/components/Buttons';
import CustomFieldDatagrid from '../custom-field-datagrid/custom-field-datagrid';
import { PublicApi } from '../page-detail';
import Select from 'shared/components/Select';
import { SectionSelectorContext, UNTITLED_SECTION } from '../../section-selector-provider';
import classNames from 'classnames';
import { Area, ICustomField } from 'shared/types/customField';
import SystemFieldDatagrid from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/enrollment-form-detail/page-detail/system-field-datagrid/system-field-datagrid';
import CustomFieldForm, {
  INewCustomFieldForm,
} from 'pages/Businesses/subroutes/BusinessProfile/components/CustomDataFieldsTab/CustomFieldForm';
import { Col, Row } from 'shared/components/Layout';
import { useCreateCustomFields } from 'pages/Businesses/subroutes/BusinessProfile/graphql/mutations';
import { ExternalHandler } from 'pages/Enrollment/subroutes/Settings/Tabs/EnrollmentForms/EnrollmentFormsTable';
import { showToast } from 'shared/components/Toast';

export type AddNewQuestionModalProps = {
  centerIds: string[];
  businessId: string;
  area: Area;
  currentPage: Page;
  onAddNewFields: (fields: ICustomField[], section: Section) => void;
  pages: Page[];
};

const ADD_CUSTOM_QUESTION_TAB = 'add-custom-question-tab';
const DEFAULT_QUESTION_TAB = 'default-question-tab';

export default React.forwardRef<PublicApi, AddNewQuestionModalProps>(
  ({ businessId, centerIds, area, onAddNewFields, pages, currentPage }, ref) => {
    const { t } = useTranslation(['translation', 'enrollment', 'business']);
    const [showAddNewQuestionModal, setShowAddNewQuestionModal] = useState(false);
    const [selectedCustomFields, setSelectedCustomFields] = useState<ICustomField[]>([] as ICustomField[]);
    const { lastSelectedSection, setLastSelectedSection, sectionsCanBeSelected } =
      React.useContext(SectionSelectorContext)!;
    const selectedSection =
      sectionsCanBeSelected.length > 1
        ? sectionsCanBeSelected.find((section) => section.label === lastSelectedSection) ?? sectionsCanBeSelected[0]
        : sectionsCanBeSelected[0];
    const isSingleSection = sectionsCanBeSelected.length === 1;

    const [tabKey, setTabKey] = useState<string>(DEFAULT_QUESTION_TAB);
    const handleSetTab = (value: string | null) => {
      if (value) {
        setTabKey(value);
      } else {
        setTabKey(DEFAULT_QUESTION_TAB);
      }
    };

    useEffect(() => {
      if (area === 'MedicalProfessional') {
        setTabKey(DEFAULT_QUESTION_TAB);
      }
    }, [area]);

    const [newCustomFieldFormValues, setNewCustomFieldFormValues] = useState<INewCustomFieldForm>({
      area: area as string,
      centerIds: centerIds,
      businessId: businessId,
      notForConsoleDisplay: false,
      type: 'Text',
      displayOrder: 0,
      label: '',
      attributes: '',
    });

    const refetchApi = useRef<ExternalHandler | null>(null);

    const [createCustomField, { loading: loading }] = useCreateCustomFields();

    const addHandler = useCallback(
      async (closeModalUpon200: boolean) => {
        if (tabKey !== ADD_CUSTOM_QUESTION_TAB) {
          onAddNewFields(selectedCustomFields, selectedSection);
        } else {
          try {
            const customField = await createCustomField({
              variables: {
                input: { ...newCustomFieldFormValues, centerIds: newCustomFieldFormValues.centerIds ?? [] },
              },
            });

            onAddNewFields([customField.data!.createCustomField], selectedSection);
            refetchApi?.current?.refetch();

            showToast(t('translation:custom-data-fields.create-custom-data-field-success'), 'success');
          } catch (e) {
            showToast(t('enrollment:form.add-new-question-modal.unable-to-create'), 'error');
          }
        }

        setSelectedCustomFields([]);

        if (closeModalUpon200) {
          setShowAddNewQuestionModal(false);
        }
        setNewCustomFieldFormValues({ ...newCustomFieldFormValues, label: '', attributes: '' });
      },
      [
        newCustomFieldFormValues,
        selectedCustomFields,
        selectedSection,
        tabKey,
        setShowAddNewQuestionModal,
        onAddNewFields,
        refetchApi,
        currentPage,
      ]
    );

    useEffect(() => {
      setNewCustomFieldFormValues({ ...newCustomFieldFormValues, area: area as string, centerIds: centerIds });
    }, [centerIds, area]);

    const usedCustomFieldIds = useMemo(() => {
      let ids: string[] = [];

      // filter out the fields if the current page is contact type
      // the custom fields for the contact type is not reusable for its own page - but other (contact) pages such as secondary contact
      // page can have the custom field used even if the primary contact has used such field
      pages
        .filter((o) => o.sectionType === 'contact' || o.sectionType === 'secondary-contact')
        .forEach((page) => {
          page.sections.forEach((section) => {
            section.fields.forEach((field) => {
              ids.push(`${page.sectionType ?? ''}_${field.name}`);
            });
          });
        });

      // custom fields belongs to `account`, and contact and secondary-contact area can be reused in their own context page
      // so necessary to iterate all pages
      // filter out the fields for other pages
      pages
        .filter((o) => o.sectionType !== 'contact' && o.sectionType !== 'secondary-contact')
        .forEach((page) => {
          page.sections.forEach((section) => {
            section.fields.forEach((field) => {
              ids.push(`${page.sectionType ?? ''}_${field.name}`);
            });
          });

          // if the `payment` type page exists, then `billingCycle` and `paymentMethod` should become unavailable
          if (page.sectionType === 'payment') {
            // the naming convention is `${page.sectionType}_${field.name}`
            // but the account page doesn't have pageSection property
            ids.push(`_billingCycle`);
            ids.push(`_paymentMethod`);
          }
        });

      return ids;
    }, [currentPage, pages]);

    const isFormInValid = useCallback(() => {
      if (tabKey === ADD_CUSTOM_QUESTION_TAB)
        return newCustomFieldFormValues.type === '' || newCustomFieldFormValues.label === '';

      return selectedCustomFields.length < 1;
    }, [tabKey, newCustomFieldFormValues, selectedCustomFields]);

    useImperativeHandle(
      ref,
      () => ({
        ...((ref as React.MutableRefObject<PublicApi | null>)?.current ?? {}),
        showAddNewQuestionModel: (show) => {
          setShowAddNewQuestionModal(show);
        },
      }),
      [setShowAddNewQuestionModal, ref]
    );

    return (
      <div className="add-new-question-modal">
        <Modal
          scrollable
          show={showAddNewQuestionModal}
          backdrop="static"
          onHide={() => setShowAddNewQuestionModal(false)}
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('enrollment:form.add-new-question-modal.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              id="add-new-question-modal-tabs"
              defaultActiveKey={DEFAULT_QUESTION_TAB}
              activeKey={tabKey}
              onSelect={handleSetTab}
            >
              <Tab
                eventKey={DEFAULT_QUESTION_TAB}
                id="add-new-question-tabs-tab-default-question"
                title={t('enrollment:form.add-new-question-modal.default-questions')}
              >
                <SystemFieldDatagrid
                  area={area}
                  page={currentPage}
                  businessId={businessId}
                  centerIds={centerIds}
                  onSelectedRows={setSelectedCustomFields}
                  nonSelectableIds={usedCustomFieldIds}
                />
              </Tab>
              {area !== 'MedicalProfessional' && (
                <Tab
                  id="add-new-question-tabs-tab-custom-question"
                  eventKey="custom-question-tab"
                  title={t('enrollment:form.add-new-question-modal.custom-questions')}
                >
                  <CustomFieldDatagrid
                    ref={refetchApi}
                    page={currentPage}
                    area={area}
                    businessId={businessId}
                    centerIds={centerIds}
                    onSelectedRows={setSelectedCustomFields}
                    nonSelectableIds={usedCustomFieldIds}
                  />
                </Tab>
              )}
              {area !== 'MedicalProfessional' && (
                <Tab
                  id="add-new-question-tabs-tab-add-custom-question"
                  eventKey={ADD_CUSTOM_QUESTION_TAB}
                  title={t('enrollment:form.add-new-question-modal.create-custom-questions')}
                >
                  <Row>
                    <Col md={6}>
                      <CustomFieldForm
                        disable={{ centerIds: true, area: true }}
                        formValues={newCustomFieldFormValues}
                        handleInput={(field, value) => {
                          setNewCustomFieldFormValues((prevState) => ({ ...prevState, [field]: value }));
                        }}
                      />
                    </Col>
                  </Row>
                </Tab>
              )}
            </Tabs>
          </Modal.Body>
          <Modal.Footer
            className={classNames({
              'd-flex ': true,
              'justify-content-between': !isSingleSection,
              'justify-content-end': isSingleSection,
            })}
          >
            {!isSingleSection && (
              <div className="d-flex align-items-center">
                <label className="my-0 mr-2" htmlFor="form-editor-add-new-question-modal-select-section">
                  Add to:
                </label>
                <Select
                  id="form-editor-add-new-question-modal-select-section"
                  options={sectionsCanBeSelected}
                  getOptionLabel={(section: Section) => section.label ?? UNTITLED_SECTION}
                  getOptionValue={(section: Section) => section.label ?? UNTITLED_SECTION}
                  onChange={(section: Section) => setLastSelectedSection(section.label ?? UNTITLED_SECTION)}
                  className="w-200px mb-0"
                  menuPlacement="top"
                  value={selectedSection}
                />
              </div>
            )}

            <div>
              <Button variant="light" onClick={() => setShowAddNewQuestionModal(false)} className="mr-2">
                {t('translation:core.capitalize', { value: t('translation:spelling.cancel') })}
              </Button>
              {tabKey === ADD_CUSTOM_QUESTION_TAB && (
                <Button
                  className="mr-2"
                  disabled={isFormInValid()}
                  loading={loading}
                  variant="primary"
                  onClick={() => {
                    addHandler(false);
                  }}
                >
                  {t('enrollment:form.add-new-question-modal.create-add-another')}
                </Button>
              )}
              <Button
                disabled={isFormInValid()}
                loading={loading}
                variant="primary"
                onClick={() => {
                  addHandler(true);
                }}
              >
                {tabKey === ADD_CUSTOM_QUESTION_TAB
                  ? t('enrollment:form.add-new-question-modal.create-add')
                  : t('enrollment:form.add-new-question-modal.add-question', { count: selectedCustomFields.length })}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
);
