import React, { useRef } from 'react';
import Checkbox from 'shared/components/Checkbox';
import Tooltip from 'shared/components/Tooltip';
import IndicatorColor from './IndicatorColor';
import { components, OptionProps } from 'react-select';

const NO_OP = () => {};

const MenuItem = (props: OptionProps<ITableFilterOption, false>) => {
  const textRef = useRef<HTMLSpanElement>(null);

  // show a tooltip if the text-overflow property kicked in
  const showTooltip: boolean =
    textRef && textRef.current ? textRef.current.scrollWidth > textRef.current.clientWidth : false;
  const { type = 'checkbox' } = props.data;

  return (
    <Tooltip direction="top" text={props.label} showTooltip={showTooltip}>
      <components.Option {...props}>
        <div className="d-flex flex-row align-items-center px-2">
          {type === 'checkbox' && (
            <Checkbox
              value={props.isSelected}
              onChange={NO_OP}
              data-testid={`option-checkbox-${props.label}`}
              disabled={props.data?.isDisabled}
            />
          )}
          {type === 'radio' && (
            <input
              type="radio"
              id={props.data.value}
              name={props.data.value}
              value={props.data.value}
              checked={props.isSelected}
              className="mr-2"
              disabled={props.data?.isDisabled}
            />
          )}
          {props.data.indicatorColor && <IndicatorColor color={props.data.indicatorColor} />}
          <div className="flex-grow-1 ml-2">
            <span ref={textRef} className="k2-datatable-dropdown-menu-item-text d-block">
              {props.label}
            </span>
          </div>
        </div>
      </components.Option>
    </Tooltip>
  );
};

export default MenuItem;
