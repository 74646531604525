import React, { useState, useCallback, useEffect } from 'react';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { capitalize } from '../../../shared/util/string';
import useGetActiveCenters from '../../../shared/hooks/useGetActiveCenters';
import { useTranslation } from 'react-i18next';
import Select from 'shared/components/Select';

interface IFormStateShape {
  centerId: string;
  date: string | null;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  reportName: string;
  onSubmit: (date: string, centerId: string) => void;
  onClose: () => void;
}

const SingleDateSingleCenterReportModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  reportName,
  onSubmit,
  onClose,
  ...props
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<IFormStateShape>({ centerId: '', date: null });
  const centerSelectOptions: ICenter[] = useGetActiveCenters();

  useEffect(() => {
    if (!isOpen) {
      setFormData({ centerId: '', date: null });
    }
  }, [isOpen]);

  /**
   * Bubble the form selections up to the parent component
   */
  const handleSubmit = useCallback(() => {
    if (formData.date) {
      onSubmit(formData.date, formData.centerId);
    }
  }, [formData, onSubmit]);

  /**
   * Handler for when the modal has been dismissed
   */
  const handleClose = useCallback(() => {
    setFormData({ centerId: '', date: null });
    onClose();
  }, [onClose]);

  return (
    <SideModalDrawer
      title={`${reportName}`}
      show={isOpen}
      onHide={handleClose}
      primaryChoice="Run Export"
      primaryCallback={() => handleSubmit()}
      secondaryCallback={handleClose}
      primaryButtonProps={{ disabled: !formData.date, loading: isLoading }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
    >
      <Row>
        <Col>
          <Select
            required
            id="center-input"
            label={capitalize(t('spelling.center'))}
            aria-label={`Select ${capitalize(t('spelling.center'))}`}
            placeholder={`${capitalize(t('spelling.center'))}`}
            value={formData.centerId}
            options={centerSelectOptions
              .map((c) => ({ value: c.id, label: c.name }))
              .sort((a, b) => a.label.localeCompare(b.label))}
            onChange={(option: any) => setFormData((prev) => ({ ...prev, centerId: option.value }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            label="Date"
            date={formData.date}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, date }))}
            className="kt-date-input-no-max-width"
          />
        </Col>
      </Row>
    </SideModalDrawer>
  );
};

export default SingleDateSingleCenterReportModal;
