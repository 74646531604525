import React, { useState } from 'react';
import moment from 'moment';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { getFullName, getInitials, stringToHueDegree } from 'shared/util/string';
import { useHistory } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import './_birthday-table.scss';

interface IProps {
  children: IChildBirthdayStatus[];
  staff: IStaff[];
  type: 'CHILD' | 'STAFF';
  selectedWeekDayNum: number;
  weekOffset: number;
  isOpen: boolean;
  onClose: () => void;
}

const BirthdayDetailsModal: React.FC<IProps> = ({
  children,
  staff,
  type,
  weekOffset,
  selectedWeekDayNum,
  isOpen,
  onClose,
}) => {
  const history = useHistory();
  const startOfCurrentWeek = moment().startOf('w');
  const [weekDayNum, setWeekDayNum] = useState(selectedWeekDayNum);
  const date = startOfCurrentWeek.clone().add(weekOffset, 'w').add(weekDayNum, 'd');
  const { t } = useTranslation();

  const data = ((type === 'STAFF' ? staff : children) as IPerson[]).filter(
    (person) => date.month() === moment(person.dob).month() && date.date() === moment(person.dob).date()
  );

  return (
    <Modal centered backdrop="static" show={isOpen} onHide={onClose} size="lg">
      <Modal.Header closeButton className="px-4 py-2">
        <Modal.Title as="h5">
          {`${type === 'CHILD' ? `Children's` : 'Staff'} Birthdays - ${date.format('ddd')} ${date.format(
            t('formatters.day-month-short')
          )}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-4 px-4">
        <Tabs
          id="birthday-dates"
          defaultActiveKey={String(selectedWeekDayNum)}
          activeKey={String(weekDayNum)}
          onSelect={(tab) => {
            setWeekDayNum(Number(tab));
          }}
        >
          {[0, 1, 2, 3, 4, 5, 6].map((num) => (
            <Tab key={num} eventKey={num} title={startOfCurrentWeek.clone().add(num, 'd').format('dddd')}>
              <DataTable
                noPadding
                className="birthday-table"
                data={data}
                showPagination={false}
                showSelect={false}
                noDataText="No birthdays for selected day."
                columns={[
                  {
                    text: 'Name',
                    dataField: 'child.lastname',
                    classes: 'md-column',
                    formatter: (cell: any, row: IStaff | IChild) => (
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          history.push(`/${type === 'STAFF' ? 'employees/profiles' : 'families/children'}/${row.id}`)
                        }
                      >
                        <AvatarDataTableCell
                          size="sm"
                          avatar={row.avatar?.url ?? ''}
                          initials={getInitials(row)}
                          color={`hsl(${stringToHueDegree(row.id)}, ${
                            stringToHueDegree(row.id) < 50 ? '100%' : '40%'
                          }, 40%`}
                          primaryText={getFullName(row)}
                        />
                      </div>
                    ),
                  },
                  {
                    text: 'Age',
                    headerAlign: 'center',
                    dataField: 'child.dob',
                    align: 'center',
                    formatter: (cell: any, row: IStaff | IChild) => Math.ceil(moment().diff(row.dob, 'month') / 12),
                  },
                ]}
              />
            </Tab>
          ))}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default BirthdayDetailsModal;
