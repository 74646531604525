import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { getFullName } from 'shared/util/string';
import { useUnarchiveChildOnAccount } from 'gql/account/mutations';

interface IProps {
  isOpen: boolean;
  child: IAccountChild;
  onClose: () => void;
  account: IAccount;
}

const UnarchiveChildOnAccountModal: React.FC<IProps> = ({ isOpen, child, onClose, account, ...props }) => {
  const [unarchiveChildFn, { loading }] = useUnarchiveChildOnAccount(account.id, child.id);
  const handleSubmit = useCallback(() => {
    unarchiveChildFn()
      .then(() => {
        showToast('Child unarchived successfully.', 'success');
        onClose();
      })
      .catch((error) => {
        showToast(
          `${error.graphQLErrors
            .map((err: any) => {
              return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
            })
            .join(', ')}`,
          'error'
        );
      });
  }, []);

  return (
    <ConfirmationModal
      title="Unarchive Child on Account?"
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Unarchive"
      primaryCallback={handleSubmit}
      primaryButtonProps={{ variant: 'primary', loading }}
    >
      <p>When a child is unarchived, they will again become active on this account.</p>
      <p>Are you sure you would like to unarchive {getFullName(child)} ?</p>
    </ConfirmationModal>
  );
};

export default UnarchiveChildOnAccountModal;
