import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Setting from 'shared/components/Settings';
import { capitalize } from 'shared/util/string';
import Switch from 'shared/components/Switch';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { useGetCenterStatesInUse } from 'shared/hooks/useGetCenterStatesInUse';
import { showToast } from 'shared/components/Toast';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import FilterGroup from 'pages/Centers/subroutes/Profiles/components/CenterProfilesTable/FilterGroup';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import useDatatableState from 'shared/hooks/useDatatableState';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import { STATES, US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import {
  SortDirection,
  useGetAttendanceBusinessSettingQuery,
  useGetAttendanceCentersSettingQuery,
  useResetAttendanceCentersSettingMutation,
  useSetAttendanceBusinessSettingMutation,
  useSetAttendanceCentersSettingMutation,
} from 'generated/graphql';
import { orderBy } from 'lodash';

const GeneralSettingsTab = () => {
  const { t } = useTranslation(['translation', 'enrollment']);
  const [settingsLoading, setLoading] = useState(false);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: 'ASCENDING' }];
  const [tableState, tableFunctions] = useDatatableState('center', initalTableSort);
  const currentUser = useSelector((state: RootState) => state.user);
  const isInternalUser = currentUser?.isInternal;
  const { data: getAllowedEntitiesData } = useGetAllowedEntities(!isInternalUser);
  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];
  const tagsOptions: ITableFilterOption[] = orderBy(tags ?? [], (tag) => tag.name.toLocaleLowerCase(), 'asc').map(
    (tag) => ({ label: tag.name, value: tag.id })
  );

  const statesInUse: string[] = useGetCenterStatesInUse()?.data?.getCenterStatesUsedAcrossEntity || [];

  const {
    data,
    loading,
    refetch: refetchCenters,
  } = useGetAttendanceCentersSettingQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      input: {
        filter: { [SEARCH_EXPRESSIONS.ALL]: [...tableState.searchExpressions, ...tableState.filterExpressions] },
        from: tableState.currentItemOffset,
        size: tableState.pageSize,
        sort: [
          {
            field: 'name.keyword',
            direction: SortDirection.Ascending,
          },
        ],
      },
    },
    onError: (err) => {
      showToast(t('translation:attendance.settings.general-settings.errors.attendance-settings'), 'error');
      setLoading(false);
    },
    onCompleted: () => setLoading(false),
  });

  const {
    data: businessSettings,
    loading: businessSettingsLoading,
    refetch,
  } = useGetAttendanceBusinessSettingQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      businessId: businessId ?? '',
    },
    fetchPolicy: 'network-only',
    onError: (err) =>
      showToast(t('translation:attendance.settings.general-settings.errors.attendance-settings'), 'error'),
  });

  const handleReload = () => {
    setLoading(true);
    refetch();
    refetchCenters();
  };

  const [updateBusinessSetting, { loading: setBusinessSettingLoading }] = useSetAttendanceBusinessSettingMutation({
    onCompleted: () => handleReload(),
  });
  const [updateCenterSettings, { loading: setCenterLoading }] = useSetAttendanceCentersSettingMutation({
    onCompleted: () => handleReload(),
  });

  const [resetCenterSettings, { loading: resetCenterLoading }] = useResetAttendanceCentersSettingMutation({
    onCompleted: () => handleReload(),
  });

  const formattedTableData = data?.searchCenters.data.map((center) => ({
    ...center,
    address: {
      ...center.address,
      state: { keyword: STATES[center.address?.state ?? ''] },
    },
  }));

  const handleUpdateRow = (rowId: string, usesDefault: boolean, usesTodayScreen: boolean) => {
    if (usesDefault) {
      resetCenterSettings({
        variables: {
          businessId: businessId,
          centerId: rowId,
        },
      });
    } else {
      updateCenterSettings({
        variables: {
          input: {
            businessId: businessId,
            centerId: rowId,
            usesTodayScreen,
          },
        },
      });
    }
  };

  const handleUpdateBusinessSetting = (value: boolean) => {
    updateBusinessSetting({
      variables: {
        input: {
          businessId: businessId ?? '',
          usesAttendanceSettings: true,
          usesTodayScreen: value,
        },
      },
    });
  };
  const states =
    !!statesInUse && statesInUse.length > 0
      ? US_STATE_SELECT_OPTIONS.filter((state) => statesInUse.includes(state.value))
      : US_STATE_SELECT_OPTIONS;
  return (
    <div>
      <Setting
        title={t('translation:attendance.settings.general-settings.today-attendance-setting.title')}
        subtitle={t('translation:attendance.settings.general-settings.today-attendance-setting.subtitle')}
        sectionHeader="Global Attendance Settings"
        toggleValue={businessSettings?.getAttendanceBusinessSetting.usesTodayScreen}
        handleToggle={handleUpdateBusinessSetting}
      />
      <Box display="flex" justifyContent="space-between" padding={2}>
        <Typography variant="h2">{capitalize(t('translation:spelling.center_plural'))}</Typography>
      </Box>
      <DataTable
        data={formattedTableData ?? []}
        dataSize={data?.searchCenters.totalResults ?? 0}
        pageSize={tableState.pageSize}
        showLoadingOverlay={
          loading ||
          businessSettingsLoading ||
          setCenterLoading ||
          setBusinessSettingLoading ||
          resetCenterLoading ||
          settingsLoading
        }
        onPageChange={tableFunctions.changePage}
        activePage={tableState.activePage}
        onSizePerPageChange={(sizePerPage: number) => {
          if (tableState.activePage !== 1) tableFunctions.changePage(1, sizePerPage);
          tableFunctions.changeSizePerPage(sizePerPage);
        }}
        showSelect={false}
        columns={[
          {
            text: `${capitalize(t('translation:spelling.center'))} Name`,
            dataField: 'name',
            sort: true,
          },
          {
            text: 'State',
            dataField: 'address.state.keyword',
            sort: false,
          },
          {
            text: 'Uses Global Settings',
            dataField: '',
            formatter: (cell: any, row: any) => {
              const defaultSetting = row.attendanceSetting.fromDefault ?? false;
              return (
                <Switch
                  value={defaultSetting}
                  onChange={(checked: boolean) => {
                    const usesTodayScreen = row.attendanceSetting.usesTodayScreen ?? false;
                    handleUpdateRow(row.attendanceSetting.centerId, checked, usesTodayScreen);
                  }}
                />
              );
            },
          },
          {
            text: 'Today Screen Access',
            dataField: '',
            formatter: (cell: any, row: any) => {
              const usesTodayScreen = row.attendanceSetting.usesTodayScreen ?? false;
              return (
                <Switch
                  value={usesTodayScreen}
                  onChange={(checked: boolean) => {
                    updateCenterSettings({
                      variables: {
                        input: {
                          businessId: businessId,
                          centerId: row.attendanceSetting.centerId,
                          usesTodayScreen: checked,
                        },
                      },
                    });
                  }}
                />
              );
            },
          },
        ]}
        renderHeader={(paginationProps: any) => (
          <TableHeader className="d-flex flex-row align-items-center flex-wrap flex-grow-1">
            <SizePerPage paginationProps={paginationProps} />
            <FilterGroup
              tableState={tableState}
              tableFunctions={tableFunctions}
              tags={tagsOptions}
              states={states}
              entities={getAllowedEntitiesData?.getAllowedEntities || []}
              autoSelectedBusinessId={businessId ?? ''}
            />
          </TableHeader>
        )}
      />
    </div>
  );
};
export default GeneralSettingsTab;
