import { MutationHookOptions } from '@apollo/client';
import { childContactFields } from 'gql/contact/fields';
import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';

interface IUpdateContactChildRelationshipData {
  updateContactChildRelationshipData: any;
}

interface IUpdateContactChildRelationshipVariables {
  input: {
    childId: string;
    contactId: string;
    relationship: string;
  };
}

export const UPDATE_CONTACT_CHILD_RELATIONSHIP = (fields: string = childContactFields) => gql`
  mutation ($input: UpdateContactChildRelationshipInput!) {
    updateContactChildRelationship(input: $input) {
      id
      childId
      relationshipType
    }
  }
`;

export const useUpdateContactChildRelationship = (
  options: MutationHookOptions<IUpdateContactChildRelationshipData, IUpdateContactChildRelationshipVariables>,
  fields?: string
) =>
  useMutation<IUpdateContactChildRelationshipData, IUpdateContactChildRelationshipVariables>(
    UPDATE_CONTACT_CHILD_RELATIONSHIP(fields),
    options
  );
