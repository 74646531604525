import React, { useEffect, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import Accordion from 'react-bootstrap/Accordion';
import { useGetCcsEntitlementChangeHistory } from 'gql/subsidies/queries';
import EntitlementsHistoryTable from './EntitlementsHistoryTable';
import { ICcsEntitlement } from 'shared/types/ccsEntitlement';
import { HorizontalDivider } from 'shared/components/Dividers';

interface IProps {
  entitlement: ICcsEntitlement;
  account: IAccount;
}

const CcsEntitlementCard: React.FC<IProps> = ({ entitlement, account }) => {
  return (
    <div>
      <Row align="start" className="mb-4">
        <Col md="3">
          <HeadingCaption heading="Absence Count" caption={entitlement.absenceCount.toString()} />
        </Col>
        <Col md="3">
          <HeadingCaption heading="Paid Absences" caption={entitlement.paidAbsences.toString()} />
        </Col>
        <Col md="3">
          <HeadingCaption heading="Unpaid Absences" caption={entitlement.unpaidAbsences.toString()} />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="Absences Available No Evidence"
            caption={entitlement.absencesAvailableNoEvidence.toString()}
          />
        </Col>
      </Row>
      <Row align="start" className="mb-4">
        <Col md="3">
          <HeadingCaption heading="Total Hours Per Fortnight" caption={entitlement.totalHoursPerFortnight.toString()} />
        </Col>
        <Col md="3">
          <HeadingCaption heading="Preschool Exemption" caption={entitlement.preschoolExemption ? 'Yes' : 'No'} />
        </Col>
        <Col md="3">
          <HeadingCaption heading="Withholding Percentage" caption={`${entitlement.withholdingPercentage}%`} />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="ACCS Hourly Rate Cap Increase Percentage"
            caption={`${entitlement.accsHourlyRateCapIncreasePercentage}%`}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <HorizontalDivider />
        </Col>
      </Row>
      <Row>
        <PreviousEntitlements entitlement={entitlement} account={account} />
      </Row>
    </div>
  );
};

const HeadingCaption = (props: { heading: string; caption: string }) => {
  return (
    <>
      <div>
        <small>{props.heading}</small>
      </div>
      <div>
        <small className="caption">{props.caption}</small>
      </div>
    </>
  );
};

const PreviousEntitlements: React.FC<IProps> = ({ entitlement, account }) => {
  const businessId = account?.entityId ?? '';
  const [viewMore, setViewMore] = useState(0);

  const { data, loading } = useGetCcsEntitlementChangeHistory({
    variables: {
      input: {
        businessId,
        enrollmentId: entitlement.enrolmentId,
        pageNumber: 1,
        pageSize: viewMore,
      },
    },
  });

  if (data?.getCcsEntitlementChangeHistory?.totalRecords === 0) return null;

  return (
    <Accordion
      className={'d-flex flex-column justify-content-center pb-4'}
      defaultActiveKey="-1"
      style={{ width: '100%' }}
    >
      <Accordion.Collapse className="" eventKey="0">
        <>
          {(data?.getCcsEntitlementChangeHistory?.data ?? []).length > 0 && (
            <EntitlementsHistoryTable
              data={data?.getCcsEntitlementChangeHistory?.data ?? []}
              entitlement={entitlement.child}
              loading={loading}
            />
          )}
        </>
      </Accordion.Collapse>

      {viewMore === 0 && !loading ? (
        <Accordion.Toggle
          eventKey="0"
          className="kt-account-ccs-tab-accordion-toggle"
          onClick={() => setViewMore(viewMore + 10)}
        >
          <div className="float-center">
            <span>View {(data?.getCcsEntitlementChangeHistory?.totalRecords ?? 0) - viewMore} More Entitlements</span>
          </div>
        </Accordion.Toggle>
      ) : null}

      {viewMore <= (data?.getCcsEntitlementChangeHistory?.totalRecords ?? 0) && viewMore !== 0 ? (
        <div className="kt-account-ccs-tab-accordion-toggle">
          <div style={{ textAlign: 'center' }} onClick={() => setViewMore(viewMore + 10)}>
            <span>View {(data?.getCcsEntitlementChangeHistory?.totalRecords ?? 0) - viewMore} More Entitlements</span>
          </div>
        </div>
      ) : null}

      {viewMore >= (data?.getCcsEntitlementChangeHistory?.totalRecords ?? 0) && viewMore !== 0 ? (
        <Accordion.Toggle eventKey="0" className="kt-account-ccs-tab-accordion-toggle" onClick={() => setViewMore(0)}>
          <div className="float-center">
            <span>Hide Entitlements</span>
          </div>
        </Accordion.Toggle>
      ) : null}
    </Accordion>
  );
};

export default CcsEntitlementCard;
