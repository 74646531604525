import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { showToast } from 'shared/components/Toast';
import { getFullName } from 'shared/util/string';
import { useRemoveContactFromAccount } from 'gql/account/mutations';
import { useDeletePersonPaymentMethods } from 'gql/paymentMethod/authorizedPerson/mutation';

interface IProps {
  isOpen: boolean;
  contact: IContact;
  onClose: () => void;
  account: IAccount;
  paymentMethods?: IPaymentMethod[];
  setRemovedContact: (contact: IContact) => void;
}

const RemoveContactFromAccountModal: React.FC<IProps> = ({
  isOpen,
  contact,
  onClose,
  account,
  paymentMethods = [],
  setRemovedContact,
}) => {
  const [removeContactFn, { loading }] = useRemoveContactFromAccount(account.id, contact.id);
  const [deletePaymentMethods, result] = useDeletePersonPaymentMethods(account?.id ?? '');
  const handleSubmit = useCallback(() => {
    removeContactFn()
      .then(async () => {
        if (paymentMethods.length > 0) {
          await deletePaymentMethods(paymentMethods, account?.id);
        }
        showToast('Contact removed successfully.', 'success');
        setRemovedContact(contact);
        onClose();
      })
      .catch(() => {
        showToast('There was an error removing contact.', 'error');
      });
  }, [account?.id, contact, deletePaymentMethods, onClose, paymentMethods, removeContactFn, setRemovedContact]);

  return (
    <ConfirmationModal
      title={'Remove ' + getFullName(contact) + ' from Account?'}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Remove"
      primaryCallback={handleSubmit}
      primaryButtonProps={{ variant: 'danger', loading: loading || result.loading }}
      size="lg"
    >
      <p>All permissions the contact has on this account will be terminated.</p>
      {paymentMethods.length > 0 && <p>The payment information for this contact will be removed.</p>}
      {paymentMethods.some((x) => x.isAutoPay) && (
        <p>
          <b>Removing the contact will leave the account without an auto payment method.</b>
        </p>
      )}
    </ConfirmationModal>
  );
};

export default RemoveContactFromAccountModal;
