import React, { useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import DataTypeCard from './DataTypeCard';
import { isRegion } from 'shared/util/region';
import DeleteBusinessModal from './DeleteBusinessModal';
import ParentContactManagementCard from './ParentContactManagementCard';
import HubspotCard from './HubSpotCard';
import { USModeCard } from './USModeCard/USModeCard';

//Styles
import './KTAdminTab.scss';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {
  entityId: string;
  dataType: DataType;
  loading: boolean;
  businessName: string;
}

const KTAdminTab: React.FC<IProps> = ({ entityId, dataType, loading = true, businessName, ...props }) => {
  const [showDeleteBusinessModal, setShowDeleteBusinessModal] = useState<boolean>(false);
  const { k2UsModeKtAdmin } = useFlags();

  return (
    <div className="kt-admin-tab__content">
      <Row align="start">
        <Col sm="12" md="6">
          <DataTypeCard entityId={entityId} dataType={dataType} loading={loading} />
        </Col>
      </Row>
      <Row align="start">
        <Col sm="12" md="6">
          <ParentContactManagementCard entityId={entityId} />
        </Col>
      </Row>
      <Row align="start">
        <Col sm="12" md="6">
          <HubspotCard businessId={entityId} />
        </Col>
        {isRegion('US') && k2UsModeKtAdmin && (
          <Col sm="12" md="6">
            <USModeCard businessId={entityId}></USModeCard>
          </Col>
        )}
      </Row>
      <DeleteBusinessModal
        isOpen={showDeleteBusinessModal}
        onClose={() => setShowDeleteBusinessModal(false)}
        businessId={entityId}
        businessName={businessName}
      />
    </div>
  );
};

export default KTAdminTab;
