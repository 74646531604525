import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  loading: boolean;
}
export default ({ show, onHide, onConfirm, loading }: IProps) => {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      title={t('enrollment.lead-management.mark-as-lost-confirm-modal.title')}
      show={show}
      onHide={onHide}
      primaryChoice={t('enrollment.lead-management.mark-as-lost-confirm-modal.confirm')}
      primaryCallback={onConfirm}
      hideOnCallback={false}
      primaryButtonProps={{
        variant: 'primary',
        loading: loading,
      }}
    >
      {t('enrollment.lead-management.mark-as-lost-confirm-modal.message')}
    </ConfirmationModal>
  );
};
