import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';
import { allergyFields, allergyWithDocumentsFields } from 'gql/allergy/fields';
import { useDispatch } from 'react-redux';
import { createStaffAllergy, deleteStaffAllergy, updateStaffAllergy } from 'store/employees/actions';
export interface IAllergyInput {
  allergenId: string;
  severity: AllergenSeverity;
  reactions: string[];
  instructions: string;
}
interface ICreateAllergyForStaffData {
  createAllergyForStaff: IAllergy;
}
interface ICreateAllergyForStaffVariables {
  input: {
    staffId: string;
    allergenId: string;
    severity: AllergenSeverity;
    instructions: string;
    reactions: string[];
  };
}

interface IUpdateAllergyForStaffData {
  updateAllergyForStaff: IAllergy;
}

interface IUpdateAllergyForStaffVariables {
  input: {
    staffId: string;
    allergyId: string;
    allergenId: string;
    severity: AllergenSeverity;
    instructions: string;
    reactions: string[];
  };
}

interface IDeleteAllergyForStaffData {
  deleteAllergyForStaff: IAllergy;
}

interface IDeleteAllergyForStaffVariables {
  allergyId: string;
  staffId: string;
}

interface IArchiveAllergyForStaffData {
  archiveAllergyForStaff: IAllergy;
}

interface IArchiveAllergyForStaffVariables {
  allergyId: string;
  staffId: string;
}

interface ICreateAllergyForChildData {
  createAllergyForChild: IAllergy;
}

interface ICreateAllergyForChildVariables {
  input: ICreateChildAllergyInput;
}

interface IUpdateAllergyForChildData {
  updateAllergyForChild: IAllergy;
}

interface IUpdateAllergyForChildVariables {
  input: IUpdateChildAllergyInput;
}

interface IDeleteAllergyForChildData {
  deleteAllergyForChild: IAllergy;
}

interface IDeleteAllergyForChildVariables {
  allergyId: string;
  childId: string;
}

interface IArchiveAllergyForChildData {
  archiveAllergyForChild: IAllergy;
}

interface IArchiveAllergyForChildVariables {
  allergyId: string;
  childId: string;
}

//STAFF GQL

export const CREATE_ALLERGY_FOR_STAFF = (fields: string = allergyFields) => gql`
  mutation($input: CreateStaffAllergyInput!) {
    createAllergyForStaff(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_ALLERGY_FOR_STAFF = (fields: string = allergyFields) => gql`
  mutation($input: UpdateStaffAllergyInput!) {
    updateAllergyForStaff(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_ALLERGY_FOR_STAFF = (fields: string = allergyFields) => gql`
  mutation($allergyId: ID!, $staffId: ID!) {
    deleteAllergyForStaff(allergyId: $allergyId, staffId: $staffId) {
      ${fields}
    }
  }
`;

export const ARCHIVE_ALLERGY_FOR_STAFF = (fields: string = allergyFields) => gql`
  mutation ($allergyId: ID!, $staffId: ID!) {
    archiveAllergyForStaff(allergyId: $allergyId, staffId: $staffId) {
      ${fields}
    }
  }
`;

//CHILD GQL

export const CREATE_ALLERGY_FOR_CHILD = (fields: string = allergyWithDocumentsFields) => gql`
  mutation ($input: CreateChildAllergyInput!) {
    createAllergyForChild(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_ALLERGY_FOR_CHILD = (fields: string = allergyWithDocumentsFields) => gql`
  mutation ($input: UpdateChildAllergyInput!) {
    updateAllergyForChild(input: $input) {
      ${fields}
    }
  }
`;

export const ARCHIVE_ALLERGY_FOR_CHILD = (fields: string = allergyFields) => gql`
  mutation ($allergyId: ID!, $childId: ID!) {
    archiveAllergyForChild(allergyId: $allergyId, childId: $childId) {
      ${fields}
    }
  }
`;

//STAFF HOOKS

export const useCreateAllergyForStaff = (
  options?: MutationHookOptions<ICreateAllergyForStaffData, ICreateAllergyForStaffVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useMutation<ICreateAllergyForStaffData, ICreateAllergyForStaffVariables>(
    CREATE_ALLERGY_FOR_STAFF(fields),
    options
  );
};

export const useUpdateAllergyForStaff = (
  options?: MutationHookOptions<IUpdateAllergyForStaffData, IUpdateAllergyForStaffVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useMutation<IUpdateAllergyForStaffData, IUpdateAllergyForStaffVariables>(UPDATE_ALLERGY_FOR_STAFF(fields), {
    ...options,
    onCompleted: (data) => {
      if (data?.updateAllergyForStaff) {
        dispatch(updateStaffAllergy(data.updateAllergyForStaff));
      }
    },
  });
};

export const useDeleteAllergyForStaff = (
  options?: MutationHookOptions<IDeleteAllergyForStaffData, IDeleteAllergyForStaffVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useMutation<IDeleteAllergyForStaffData, IDeleteAllergyForStaffVariables>(DELETE_ALLERGY_FOR_STAFF(fields), {
    ...options,
    onCompleted: (data) => {
      if (data?.deleteAllergyForStaff) {
        dispatch(deleteStaffAllergy(data.deleteAllergyForStaff));
      }
    },
  });
};

export const useArchiveAllergyForStaff = (
  options?: MutationHookOptions<IArchiveAllergyForStaffData, IArchiveAllergyForStaffVariables>,
  fields?: string
) =>
  useMutation<IArchiveAllergyForStaffData, IArchiveAllergyForStaffVariables>(
    ARCHIVE_ALLERGY_FOR_STAFF(fields),
    options
  );

//CHILD HOOKS
export const useCreateAllergyForChild = (
  options?: MutationHookOptions<ICreateAllergyForChildData, ICreateAllergyForChildVariables>,
  fields?: string
) =>
  useMutation<ICreateAllergyForChildData, ICreateAllergyForChildVariables>(CREATE_ALLERGY_FOR_CHILD(fields), options);

export const useUpdateAllergyForChild = (
  options?: MutationHookOptions<IUpdateAllergyForChildData, IUpdateAllergyForChildVariables>,
  fields?: string
) =>
  useMutation<IUpdateAllergyForChildData, IUpdateAllergyForChildVariables>(UPDATE_ALLERGY_FOR_CHILD(fields), options);

export const useArchiveAllergyForChild = (
  options?: MutationHookOptions<IArchiveAllergyForChildData, IArchiveAllergyForChildVariables>,
  fields?: string
) =>
  useMutation<IArchiveAllergyForChildData, IArchiveAllergyForChildVariables>(
    ARCHIVE_ALLERGY_FOR_CHILD(fields),
    options
  );
