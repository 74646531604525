import { orderBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import { capitalize } from 'shared/util/string';
import ActionDropdown from 'shared/components/ActionDropdown';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { faPencilAlt, faTimes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { PREDICATES, SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { useGetActiveCentersQuery } from 'shared/hooks/useGetActiveCenters';
import { IconButtonCircle } from 'shared/components/Buttons';

interface IProps {
  data: IServiceFeeGroup[];
  totalResults: number;
  loading: boolean;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  navigateToFeeGroup: (serviceFeeGroup: IServiceFeeGroup) => void;
  deleteFeeGroup: (serviceFeeGroup: IServiceFeeGroup) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  onSearch: (term: string) => void;
}

const ServiceGroupsTable: React.FC<IProps> = ({
  data,
  totalResults,
  loading,
  tableState,
  tableFunctions,
  navigateToFeeGroup,
  deleteFeeGroup,
  onSort,
  onSearch,
}) => {
  const { t } = useTranslation();
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const { data: activeCentersData } = useGetActiveCentersQuery();

  const centerOptions: ITableFilterOption[] = useMemo(
    () =>
      orderBy(activeCentersData?.searchCenters.data ?? [], (center) => center.name.toLocaleLowerCase(), 'asc').map(
        (center: ICenter) => ({
          label: center.name,
          value: center.id,
          searchExpression: {
            [SEARCH_EXPRESSIONS.TERM]: {
              field: 'centerId.keyword',
              predicate: PREDICATES.CONTAINS,
              value: center.id,
            },
          },
        })
      ),
    [activeCentersData]
  );

  const feeTypeOptions: ITableFilterOption[] = [
    {
      label: capitalize(t('spelling.cc')),
      value: 'CC',
    },
    {
      label: capitalize(t('spelling.ach')),
      value: 'ACH',
    },
  ];

  const clearActiveFilters = useCallback(() => {
    tableFunctions.updateSelectedFilters({});
  }, [tableFunctions]);

  const handleChangeSizePerPage = (value: number) => {
    tableFunctions.changeSizePerPage(value);
    // after changing size per page, update the current page to page 1
    // this way the system will not think you are on a page that may no longer 'exist'
    tableFunctions.changePage(1, value);
  };

  return (
    <DataTable
      noPadding
      data={data}
      dataSize={totalResults}
      pageSize={tableState.pageSize}
      onPageChange={tableFunctions.changePage}
      onSizePerPageChange={(number) => handleChangeSizePerPage(number)}
      activePage={tableState.activePage}
      onSort={onSort}
      showLoadingOverlay={loading}
      showSelect={false}
      handleRowClick={(event, row: IServiceFeeGroup) => navigateToFeeGroup(row)}
      columns={[
        {
          headerAttrs: {
            id: 'group-name-header-column-cell',
          },
          text: 'Group Name',
          sort: true,
          dataField: 'name',
        },
        {
          headerAttrs: {
            id: 'group-description-header-column-cell',
          },
          text: 'Description',
          sort: true,
          dataField: 'description',
        },
        {
          headerAttrs: {
            id: 'group-center-header-column-cell',
          },
          text: 'Number of Centers',
          sort: true,
          dataField: 'centerCount',
        },
        {
          text: 'Actions',
          align: 'center',
          dataField: '',
          headerClasses: 'text-center',
          formatter: (cell: any, row: IServiceFeeGroup) => (
            <ActionDropdown
              actions={[
                {
                  label: 'Edit Fee Group',
                  onClick: () => navigateToFeeGroup(row),
                  icon: faPencilAlt,
                },
                {
                  label: 'Delete Fee Group',
                  onClick: () => deleteFeeGroup(row),
                  icon: faPencilAlt,
                },
              ]}
            />
          ),
        },
      ]}
      renderHeader={(paginationProps: any) => (
        <TableHeader className="flex-wrap">
          <SizePerPage paginationProps={paginationProps} />
          <TableSearch placeholder="Search Service Fees" onChange={onSearch} />
          <div className="ml-auto d-flex">
            <DropdownFilter
              title={fieldLabels.center}
              className="mr-4"
              selectedFilters={tableState.selectedFilters.center}
              options={centerOptions}
              onFilterSelect={(val) =>
                tableFunctions.updateSelectedFilters({
                  ...tableState.selectedFilters,
                  center: val,
                })
              }
            />
            <DropdownFilter
              title={capitalize(t('spelling.feeType'))}
              className="mr-4"
              selectedFilters={tableState.selectedFilters.feeType}
              options={feeTypeOptions}
              onFilterSelect={(val) =>
                tableFunctions.updateSelectedFilters({
                  ...tableState.selectedFilters,
                  feeType: val,
                })
              }
            />
            <IconButtonCircle
              icon={faTimes}
              onClick={clearActiveFilters}
              tooltipDirection="bottom"
              tooltipText="Clear Filters"
            />
          </div>
        </TableHeader>
      )}
    />
  );
};

export default ServiceGroupsTable;
