import React from 'react';
import { useGetSessionCountByTimeFrame } from 'gql/casualBooking/queries';
import Line from 'shared/components/LoadingSkeletons/Line';

interface ISessionCountProps {
  classIds: string[];
  startDate: string;
  endDate: string;
  centerId: string;
}

const SessionCount = ({ classIds, startDate, endDate, centerId }: ISessionCountProps) => {
  const { data, loading } = useGetSessionCountByTimeFrame({
    variables: {
      input: {
        centerId,
        classIds,
        startDate,
        endDate,
      },
    },
  });

  if (loading) {
    return <Line />;
  }

  return <>{data?.getSessionCountByTimeFrame ?? 0}</>;
};

export default React.memo(SessionCount);
