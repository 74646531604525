import React, { useState } from 'react';
import Card from 'shared/components/Card';
import { Row, Col } from 'shared/components/Layout';
import { useTranslation } from 'react-i18next';
import CcrmCredentialsForm from './CcrmCredentialsForm';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CcrmAccountStatusMapForm from './CcrmAccountStatusMapForm';
import CcrmChildStatusMapForm from './CcrmChildStatusMapForm';
import CcrmWithdrawlReasonsMapForm from './CcrmWithdrawlReasonsMapForm';
import CcrmUnlinkedContactsForm from './CcrmUnlinkedContactsForm';
import CcrmUnlinkedChildrenForm from './CcrmUnlinkedChildrenForm';

interface IProps {
  businessId: string;
}

const CcrmIntegrationCard: React.FC<IProps> = ({ businessId }) => {
  const { t } = useTranslation(['integrations']);

  // TODO: Add tabs to url path so links can be shared/bookmarked
  // Actually that doesn work since there will be many intergrations on the page
  // Actually, do we just want a mapping tab full of modals?
  // Current resolution - just get it working, UX can come later.
  return (
    <Card header={t('integrations:ccrm.card-header')}>
      <Tabs>
        <Tab eventKey="credentials" title={t('integrations:ccrm.tabs.credentials')}>
          <Row>
            <CcrmCredentialsForm businessId={businessId} />
          </Row>
        </Tab>
        <Tab eventKey="accountStatusMapping" title={t('integrations:ccrm.tabs.account-status-map')}>
          <Row>
            <CcrmAccountStatusMapForm businessId={businessId} />
          </Row>
        </Tab>
        <Tab eventKey="childStatusMapping" title={t('integrations:ccrm.tabs.child-status-map')}>
          <Row>
            <CcrmChildStatusMapForm businessId={businessId} />
          </Row>
        </Tab>
        <Tab eventKey="withdrawlMapping" title={t('integrations:ccrm.tabs.reasons-for-ending-map')}>
          <Row>
            <CcrmWithdrawlReasonsMapForm businessId={businessId} />
          </Row>
        </Tab>
        <Tab eventKey="unlinkedContacts" title={t('integrations:ccrm.tabs.unlinked-contacts')}>
          <Row>
            <Col>
              <CcrmUnlinkedContactsForm businessId={businessId} />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="unlinkedChildren" title={t('integrations:ccrm.tabs.unlinked-children')}>
          <Row>
            <Col>
              <CcrmUnlinkedChildrenForm businessId={businessId} />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Card>
  );
};

export default CcrmIntegrationCard;
