import React, { useState, useCallback } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import errorMessage from 'shared/constants/errorMessages';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import cast from 'shared/util/cast';
import { useCreateStaffMedicalCondition } from 'gql/medicalCondition/mutations';
import { showToast } from 'shared/components/Toast';
import MedicalConditionInput from 'shared/components/Medical/MedicalConditionInput';
import { useCreateAllergyForStaff } from '../../../../../../gql/allergy/mutations';
import AllergyInputGroup, {
  IAllergyInputGroupFormShape,
} from '../../../../../../shared/components/InputGroups/AllergyInputGroup';
import Select from '../../../../../../shared/components/Select';
import Checkbox from '../../../../../../shared/components/Checkbox';
import { useDispatch } from 'react-redux';
import { createStaffAllergy } from '../../../../../../store/employees/actions';

enum ConditionType {
  'Medical' = 'Medical Condition',
  'Allergy' = 'Allergy',
}

const newAllergy = {
  allergen: null,
  instructions: '',
  severity: null,
  allergenType: null,
  reactions: [],
  documentation: [],
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  staffId: string;
}

const AddMedicalConditionModal: React.FC<IProps> = ({ isOpen, onClose, staffId }) => {
  const dispatch = useDispatch();
  const newMedicalCondition = cast<IMedicalConditionInput>({ typeId: null, risk: null });

  const [createMedicalCondition, { loading: createMedicalConditionLoading }] = useCreateStaffMedicalCondition();

  const [createAllergyForStaffFn, { loading: createAllergryLoading }] = useCreateAllergyForStaff({
    onCompleted: (result) => {
      showToast('New allergy saved successfully.', 'success');
      dispatch(createStaffAllergy(result.createAllergyForStaff));
      handleClose();
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const [showDocumentationFormGroup, setShowDocumentationFormGroup] = useState<boolean>(false);
  const [conditionType, setConditionType] = useState<ConditionType | undefined>(undefined);
  const [medicalCondition, setMedicalCondition] = useState(newMedicalCondition);
  const [allergyFormData, setAllergyFormData] = useState<IAllergyInputGroupFormShape>(newAllergy);
  const [medicalDocumentation, setMedicalDocumentation] = useState<IHealthConditionDocumentUploadInput[]>([]);
  const isLoading = createMedicalConditionLoading || createAllergryLoading;

  const resetForm = useCallback(() => {
    setMedicalCondition(newMedicalCondition);
    setConditionType(undefined);
    setAllergyFormData(newAllergy);
    setShowDocumentationFormGroup(false);
    setMedicalDocumentation([]);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  const handleSaveMedicalCondition = useCallback(
    (closeOnComplete: boolean) => {
      createMedicalCondition({
        variables: {
          staffId,
          input: {
            ...medicalCondition,
            symptoms: medicalCondition.symptoms ?? [],
          },
        },
      })
        .then(() => {
          showToast('Medical condition saved.', 'success');
          resetForm();
          closeOnComplete && handleClose();
        })
        .catch(() => {
          showToast('There was an error saving medical condition.', 'error');
        });
    },
    [onClose, resetForm, staffId, medicalCondition, medicalDocumentation]
  );

  const handleSaveStaffAllergy = useCallback(() => {
    createAllergyForStaffFn({
      variables: {
        input: {
          staffId,
          allergenId: allergyFormData.allergen?.id as string,
          severity: allergyFormData.severity as AllergenSeverity,
          reactions: allergyFormData.reactions?.map((opt) => opt.value) ?? [],
          instructions: allergyFormData.instructions ?? '',
        },
      },
    });
  }, [staffId, allergyFormData, medicalDocumentation, createAllergyForStaffFn]);

  const handlePrimaryCallback = useCallback(() => {
    switch (conditionType) {
      case ConditionType.Medical:
        handleSaveMedicalCondition(true);
        break;
      case ConditionType.Allergy:
        handleSaveStaffAllergy();
        break;
      default:
        break;
    }
  }, [handleSaveMedicalCondition, handleSaveStaffAllergy, conditionType]);

  const validateForm = useCallback((): boolean => {
    switch (conditionType) {
      case ConditionType.Medical:
        return Boolean(
          medicalCondition.name &&
            medicalCondition.typeId &&
            medicalCondition.risk &&
            (medicalCondition.risk === 'Low' ||
              medicalCondition.symptoms ||
              medicalCondition.risk === 'None' ||
              medicalCondition.symptoms) &&
            (medicalCondition.risk === 'Low' ||
              medicalCondition.instructions ||
              medicalCondition.risk === 'None' ||
              medicalCondition.instructions)
        );
      case ConditionType.Allergy:
        return Boolean(
          allergyFormData.allergen &&
            allergyFormData.severity &&
            (allergyFormData.severity === 'Mild' || allergyFormData.severity === 'NotApplicable'
              ? true
              : allergyFormData.reactions && allergyFormData.instructions)
        );
      default:
        return false;
    }
  }, [conditionType, medicalCondition, allergyFormData]);

  return (
    <SideModalDrawer
      title="New Condition"
      show={isOpen}
      onHide={handleClose}
      secondaryChoice="Cancel"
      primaryChoice="Save"
      primaryCallback={handlePrimaryCallback}
      secondaryCallback={handleClose}
      primaryButtonProps={{ disabled: !validateForm(), loading: isLoading }}
      secondaryButtonProps={{ disabled: !validateForm() }}
      closeOnSecondaryCallback={false}
      closeOnPrimaryCallback={false}
      footerHelperText={!validateForm() ? errorMessage.formRequirements : ''}
    >
      {isLoading ? (
        <LoadingLines />
      ) : (
        <div>
          <Select
            label="Category"
            options={Object.values(ConditionType)}
            value={conditionType}
            onChange={setConditionType}
          />
          {conditionType === ConditionType.Medical && (
            <MedicalConditionInput
              medicalCondition={medicalCondition}
              updateMedicalCondition={setMedicalCondition}
              readOnly={false}
            />
          )}
          {conditionType === ConditionType.Allergy && (
            <AllergyInputGroup formData={allergyFormData} onUpdate={setAllergyFormData} readOnly={false} />
          )}
        </div>
      )}
    </SideModalDrawer>
  );
};

export default AddMedicalConditionModal;
