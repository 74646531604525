import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import colors from '_colors.module.scss';

interface IProps {
  title: string | JSX.Element;
  description?: string;
  isSelected?: boolean;
  onSelect: (selected: boolean) => void;
  id?: string;
  className?: string;
  isDisabled?: boolean;
}

const DetailedRadioOption: React.FC<IProps> = ({
  title,
  description,
  isSelected = false,
  className = '',
  id = '',
  onSelect,
  isDisabled = false,
  ...props
}) => {
  const classes = cx({
    'd-flex flex-row align-items-center kt-detailed-radio-option': true,
    selected: isSelected,
    'cursor-pointer': !isDisabled,
    [className]: true,
  });

  return (
    <div id={id} className={classes} onClick={() => !isDisabled && onSelect(!isSelected)}>
      <div className="mr-4">
        <FontAwesomeIcon
          size="2x"
          icon={isSelected ? faCheckCircle : faCircle}
          color={isDisabled ? colors.slate : isSelected ? colors.success : colors.slate}
        />
      </div>
      <div className="flex-grow-1">
        {title}
        {description && <small className="d-block">{description}</small>}
      </div>
    </div>
  );
};

export default DetailedRadioOption;
