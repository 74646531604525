import { wrap } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import SideModalDrawer from 'shared/components/ModalDrawer';
import TextInput, { PasswordInput } from 'shared/components/TextInput';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { showToast } from 'shared/components/Toast';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

export type AddCcmsApprovalFormSubmit = Omit<ICcmsApprovalCreateInput, 'businessId' | 'centerId'>;

type AddCcmsApprovalFormData = Partial<AddCcmsApprovalFormSubmit>;

type AddCcmsApprovalFormValidationError = {
  error: string;
  field: keyof AddCcmsApprovalFormData;
};

export interface IProps {
  show: boolean;
  onHide: () => void;
  onSubmit?: (data: AddCcmsApprovalFormSubmit) => Promise<void>;
  onSuccess?: () => void;
  formDataPopulation?: AddCcmsApprovalFormData;
  title?: string;
}

const AddCcmsApprovalModal: React.FC<IProps> = ({
  show,
  onHide,
  onSubmit,
  onSuccess = () => showToast('Created CCMS approval.', 'success'),
  formDataPopulation = {},
  title = `Add ${fieldLabels.center} CCMS Approval`,
}) => {
  const [formData, setFormData] = useState<AddCcmsApprovalFormData>(formDataPopulation);
  // Clear all fields on close of the modal.
  useEffect(() => void (!show && setFormData({})), [show]);

  const [loading, setLoading] = useState(false);

  // Wrap onSubmit with loading, success and error handling
  if (onSubmit)
    onSubmit = wrap(onSubmit, (onSubmit, data) => {
      setLoading(true);
      return onSubmit(data)
        .then(() => {
          onSuccess();
          onHide();
        })
        .catch((err) => void showToast(`${err.toString()}`, 'error'))
        .finally(() => setLoading(false));
    });

  const handleChange = (value: AddCcmsApprovalFormData) => setFormData({ ...formData, ...value });

  const { getErrors, hasErrors } = formValidation(formData);

  const getProps = (field: keyof AddCcmsApprovalFormData) => ({
    value: formData[field],
    onChange: (value: any) => handleChange({ [field]: value }),
    isInvalid: hasErrors(field),
    errorText: getErrors(field)[0],
    autoComplete: 'off',
  });

  return (
    <SideModalDrawer
      title={title}
      show={show}
      onHide={onHide}
      primaryChoice="Update"
      primaryCallback={() => onSubmit && onSubmit(formData as AddCcmsApprovalFormSubmit)}
      primaryButtonProps={{ disabled: hasErrors(), loading: loading }}
      closeOnPrimaryCallback={false}
      secondaryChoice="Cancel"
    >
      <Form>
        <TextInput required label={'CCMS Username'} {...getProps('username')} />
        <PasswordInput required label={'Password'} {...getProps('password')} />
        <TextInput required label={'Approval ID'} {...getProps('approvalId')} />
      </Form>
    </SideModalDrawer>
  );
};

export default AddCcmsApprovalModal;

function formValidation(data: AddCcmsApprovalFormData) {
  const validationRules: ((data: AddCcmsApprovalFormData) => AddCcmsApprovalFormValidationError[])[] = [
    mandatory({ field: 'username', error: 'CCMS Username is required' }),
    mandatory({ field: 'password', error: 'Password is required' }),
    mandatory({ field: 'approvalId', error: 'Approval ID is required' }),
  ];
  const errors = validationRules.flatMap((value) => value(data));

  function getErrors(key?: keyof AddCcmsApprovalFormData) {
    if (!key) return errors;
    return errors.filter(({ field }) => field === key).map((x) => x.error);
  }

  function hasErrors(key?: keyof AddCcmsApprovalFormData) {
    return getErrors(key).length !== 0;
  }

  return { getErrors, hasErrors };
}

function mandatory({ field, error }: AddCcmsApprovalFormValidationError) {
  return (data: AddCcmsApprovalFormData) => (!data[field] ? [{ field, error }] : []);
}
