import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { ToolbarProps } from 'react-big-calendar';

const CalendarToolbar = ({ label, onNavigate, date }: ToolbarProps) => {
  return (
    <div className="calendar-toolbar">
      <div className="toolbar-main">
        <IconButton aria-label="back" onClick={() => onNavigate('PREV', date)} size="medium">
          <FontAwesomeIcon icon={faArrowLeft as IconProp} size="1x"></FontAwesomeIcon>
        </IconButton>
        <div className="calendar-toolbar__label">{label}</div>
        <IconButton aria-label="forward" onClick={() => onNavigate('NEXT', date)} size="medium">
          <FontAwesomeIcon icon={faArrowRight as IconProp}></FontAwesomeIcon>
        </IconButton>
      </div>
    </div>
  );
};

export default CalendarToolbar;
