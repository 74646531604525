import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import Button from '../../Buttons';
import DataTable, { SizePerPage, TableHeader, TableSearch } from '../../DataTable';
import { useTranslation } from 'react-i18next';
import useDatatableState from 'shared/hooks/useDatatableState2';
import {
  CommsCenterSetting,
  useGetAuthBroadcastChannelIdsQuery,
  useGetCommsAllCenterSettingsQuery,
} from 'generated/graphql';
import { useMediaQuery } from '@mui/material';
import theme from 'muiTheme';
import { capitalize, sortBy } from 'lodash';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import { STATES, US_STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import DropdownFilter from '../../Dropdown/DropdownFilter';
import { TagsTableCell } from '../../Tag';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useGetCenterStatesInUse } from 'shared/hooks/useGetCenterStatesInUse';

const { TERM } = SEARCH_EXPRESSIONS;
const { CONTAINS, EQUALS } = PREDICATES;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedChannels: IAccount[] | ICenter[];
  setSelectedChannels: (selectedAccounts: IAccount[] | ICenter[]) => void;
  handleSubmit: () => void;
}

interface CenterSettings extends CommsCenterSetting {
  id: string;
}

const CenterChannelSelectModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  selectedChannels,
  setSelectedChannels,
  handleSubmit,
}) => {
  const { t } = useTranslation(['comms', 'translation']);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const allowedCenters = useSelector((state: RootState) => state.centers.all.map((c) => c.id));

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [centers, setCenters] = useState<CenterSettings[] | []>([]);
  const [authCenters, setAuthCenters] = useState<(string | null)[] | null | undefined>([]);
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const allTags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Center)?.data?.getTagsUsedAcrossEntity || [];
  const [selectedState, setSelectedState] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  const hasReadCenterTagsPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Tags,
    level: RoleLevelType.Read,
  });

  const statesInUse: string[] = useGetCenterStatesInUse()?.data?.getCenterStatesUsedAcrossEntity || [];
  const states =
    !!statesInUse && statesInUse.length > 0
      ? US_STATE_SELECT_OPTIONS.filter((state) => statesInUse.includes(state.value))
      : US_STATE_SELECT_OPTIONS;

  const stateOptions: ITableFilterOption[] = sortBy(states, ['label']).map((state) => ({
    label: state.label,
    value: state.value,
    searchExpression: { [TERM]: { field: 'address.state.keyword', predicate: EQUALS, value: state.value } },
  }));

  const tagOptions: ITableFilterOption[] = sortBy(allTags, ['name']).map((tag) => ({
    label: tag.name,
    value: tag.id,
    searchExpression: { [TERM]: { field: 'tags.keyword', predicate: CONTAINS, value: tag.id } },
  }));

  const { data, loading, error } = useGetCommsAllCenterSettingsQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      businessId: businessId ?? '',
      searchTerm: searchTerm,
      centerId: authCenters?.filter((c) => allowedCenters.some((ac) => ac == c)),
      pageNumber: tableState.activePage,
      pageSize: tableState.pageSize,
      state: selectedState,
      tag: selectedTag,
    },
  });

  const {
    data: getAuthBroadcastIds,
    loading: getAuthBroadcastIdsLoading,
    error: getAuthBroadcastIdsError,
  } = useGetAuthBroadcastChannelIdsQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      businessId: businessId ?? '',
    },
  });

  useEffect(() => {
    setCenters(
      data && data.getCommsAllCenterSettings
        ? data?.getCommsAllCenterSettings?.data
            .filter((c) => c.usesBroadcastChannels && allowedCenters.some((ac) => ac == c.centerId))
            .map((c) => {
              return { ...c, id: c.centerId ?? '' };
            })
        : []
    );
    setTotalRecords(data && data.getCommsAllCenterSettings ? data.getCommsAllCenterSettings.totalRecords : 0);
    setAuthCenters(
      getAuthBroadcastIds && getAuthBroadcastIds?.getAuthBroadcastChannelIds
        ? getAuthBroadcastIds?.getAuthBroadcastChannelIds.centerChannels
        : []
    );
  }, [data, getAuthBroadcastIds]);

  const handleClose = () => {
    setSearchTerm('');
    setSelectedState('');
    setSelectedTag('');
    setSelectedChannels([]);
    onClose();
  };

  return (
    <Modal
      centered
      backdrop="static"
      show={isOpen}
      onHide={handleClose}
      scrollable={true}
      size="xl"
      className="channel-select-modal"
    >
      <Modal.Header closeButton className="px-6 py-4">
        <Modal.Title as="h5"> {t('comms:select-channel-modal.center.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-4 px-4 gap-waiver-confirmation-container">
        <DataTable
          data={centers}
          dataSize={totalRecords}
          showLoadingOverlay={loading}
          showSelect={true}
          selectedRows={selectedChannels}
          updateSelectedRows={setSelectedChannels}
          onPageChange={tableFunctions.changePage}
          activePage={tableState.activePage}
          pageSize={tableState.pageSize}
          onSizePerPageChange={(sizePerPage: number) => {
            if (tableState.activePage !== 1) tableFunctions.changePage(1, sizePerPage);
            tableFunctions.changeSizePerPage(sizePerPage);
          }}
          columns={[
            {
              text: `${capitalize(t('translation:spelling.center'))} Name`,
              dataField: 'centerName',
            },
            {
              text: 'State',
              dataField: 'state',
              sort: true,
              formatter: (cell: any, row: CommsCenterSetting) => {
                return <>{STATES[row.state ?? '']}</>;
              },
            },
            hasReadCenterTagsPermission && {
              text: 'Tags',
              dataField: 'tags',
              formatter: (cell: any, row: CommsCenterSetting) => {
                const tags: ITag[] | undefined = row.tags?.map((t) => {
                  const matchingTag = allTags?.find((at) => at.id === t);
                  return {
                    id: t ?? '',
                    name: matchingTag?.name ?? '',
                    entityId: businessId ?? '',
                    category: 'CENTER',
                  };
                });
                return tags && <TagsTableCell tags={tags} />;
              },
            },
          ]}
          renderHeader={(paginationProps: any) => (
            <TableHeader className="d-flex flex-row align-items-center flex-wrap flex-grow-1">
              <div className="d-flex flex-wrap mr-auto">
                <SizePerPage paginationProps={paginationProps} />
                <TableSearch
                  placeholder="Search"
                  className={isMobile ? 'my-2 mb-1' : ''}
                  onChange={(value) => {
                    setSearchTerm(value);
                  }}
                />
              </div>
              <div className={isMobile ? 'my-1 pl-0 ml-2 flex-wrap' : 'my-0 mr-2 p-0'}>
                <DropdownFilter
                  title="State"
                  selectedFilters={[selectedState]}
                  options={stateOptions}
                  onFilterSelect={(val) => {
                    if (val.length <= 0) setSelectedState('');
                    else if (val.length === 1) setSelectedState(val[0].value);
                    else setSelectedState(val[0].value === selectedState ? val[1].value : val[0].value);
                  }}
                />
              </div>
              {hasReadCenterTagsPermission && (
                <div className={isMobile ? 'my-1 pl-0 ml-2 flex-wrap' : 'my-0 mr-2 p-0'}>
                  <DropdownFilter
                    title="Tags"
                    selectedFilters={[selectedTag]}
                    options={tagOptions}
                    onFilterSelect={(val) => {
                      if (val.length <= 0) setSelectedTag('');
                      else if (val.length === 1) setSelectedTag(val[0].value);
                      else setSelectedTag(val[0].value === selectedTag ? val[1].value : val[0].value);
                    }}
                  />
                </div>
              )}
            </TableHeader>
          )}
        />
      </Modal.Body>
      <Modal.Footer className="px-4 py-2">
        <Button variant="light" className="mr-3" onClick={handleClose}>
          {t('comms:close-btn')}
        </Button>
        <Button
          disabled={selectedChannels.length <= 0}
          variant="primary"
          onClick={() => {
            setSearchTerm('');
            setSelectedState('');
            setSelectedTag('');
            handleSubmit();
          }}
        >
          {t('comms:select-channel-modal.center.select-btn')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CenterChannelSelectModal;
