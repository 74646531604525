const absencesFields = `
  absences,
  allowableAbsences,
  additionalAbsences,
  accountId,
  accountName,
  category,
  active,
  centerId,
  centerName,
  childId,
  childName,
  child {
    id
    firstname,
    lastname,
    nickname,
    fullName,
    avatar {
      url
    }
  }
`;

export const ccsAbsencesPagedResultFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${absencesFields}
  }
`;

export const ccsAbsencesCategoryFields = `
  atRisk,
  nearingLimit,
  inTheClear
`;
