import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'shared/components/Checkbox';
import { Col, Row } from 'shared/components/Layout';

interface IProps {
  isDryRun: boolean;
  setDryRun: (value: boolean) => void;
}

const ImportInstruction: React.FC<IProps> = ({ isDryRun, setDryRun }) => {
  const { t } = useTranslation('ktAdmin');
  return (
    <Row>
      <Col>
        <p>{t('data-importer.upload-instruction')}</p>
      </Col>
      <Col>
        <Checkbox
          label={t('data-importer.is-dryrun')}
          className="text-right"
          value={isDryRun}
          onChange={(value) => setDryRun(value)}
        />
      </Col>
    </Row>
  );
};

export default ImportInstruction;
