import React from 'react';
import { CardDeck } from 'react-bootstrap';
import { Row, Col } from 'shared/components/Layout';
import { SubsidySummaryColors, SubsidySummaryStatusCodes } from 'shared/constants/enums/subsidyTrackingEnums';
import NumberCard from '../components/NumberCard/NumberCard';

interface ITableProps {
  onSelected: (filter: ISubsidySummaryStatus | null) => void;
  activeFilter: ISubsidySummaryStatus | null;
  counts: Record<ISubsidySummaryStatus, number>;
}

const SubsidiesTrackingCards: React.FC<ITableProps> = ({ onSelected, activeFilter, counts }) => {
  // All these counts need to hit the endpoint with a query (since the data is paginated)
  const unsubmittedCount = counts.UNSUBMITTED;
  const pendingCount = counts.PENDING;
  const erroredCount = counts.ERRORED;
  // Active check
  const unsubmittedActive = activeFilter === SubsidySummaryStatusCodes.UNSUBMITTED;
  const pendingActive = activeFilter === SubsidySummaryStatusCodes.PENDING;
  const erroredActive = activeFilter === SubsidySummaryStatusCodes.ERRORED;

  const toggle = (filter: ISubsidySummaryStatus) => (activeFilter !== filter ? filter : null);

  const cards = [
    <NumberCard
      key="unsubmitted"
      title="Unsubmitted"
      titleClassName="payment-status-card"
      subTitle="Sessions that have not yet been submitted to CCS"
      number={unsubmittedCount}
      numberColor={SubsidySummaryColors.UNSUBMITTED.colorName}
      onClick={() => onSelected(toggle(SubsidySummaryStatusCodes.UNSUBMITTED))}
      active={unsubmittedActive}
    />,
    <NumberCard
      key="pending"
      title="Pending"
      titleClassName="payment-status-card"
      subTitle="Submitted sessions that are still being processed by CCS"
      number={pendingCount}
      numberColor={SubsidySummaryColors.PENDING.colorName}
      onClick={() => onSelected(toggle(SubsidySummaryStatusCodes.PENDING))}
      active={pendingActive}
    />,
    <NumberCard
      key="errored"
      title="Errored"
      titleClassName="payment-status-card"
      subTitle="Sessions that have been rejected by CCS"
      number={erroredCount}
      numberColor={SubsidySummaryColors.ERRORED.colorName}
      onClick={() => onSelected(toggle(SubsidySummaryStatusCodes.ERRORED))}
      active={erroredActive}
    />,
  ];

  return <CardDeck>{cards.map((card) => card)}</CardDeck>;
};

export default SubsidiesTrackingCards;
