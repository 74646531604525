import { useUpdateEnrollmentProgram } from 'gql/enrollment/mutations';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';
import { IFormDataShape, IProgramCenter } from '../context/ProgramsContext';
import { convertFormDataToPayload } from '../context/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { IEnrollmentProgram } from 'shared/types/enrollmentProgram';

interface IUseEditEnrollmentParams {
  formData: IFormDataShape;
  businessId: string;
  onSuccess: () => void;
  setFormData: React.Dispatch<React.SetStateAction<IFormDataShape>>;
  program?: IEnrollmentProgram;
}
export interface IUseEditEnrollment {
  isEditOpen: boolean;
  isEditLoading: boolean;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditProgram: () => Promise<void>;
}
const useEditEnrollmentProgram = (params: IUseEditEnrollmentParams) => {
  const { t } = useTranslation();
  const { formData, businessId, onSuccess, setFormData, program } = params;
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const allEntities = useSelector((state: RootState) => state.entities.allEntities);
  const businessTimeZone = allEntities[businessId]?.timezone || moment.tz.guess();

  useEffect(() => {
    if (program) {
      // initialize program fields
      const {
        programCenters,
        operatingDays,
        enrolmentOpenAt,
        enrolmentCloseAt,
        programGroupId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        programGroup,
        ...fields
      } = program;

      setFormData({
        ...fields,
        enrolmentOpenDate: enrolmentOpenAt ? moment(enrolmentOpenAt).tz(businessTimeZone).format('YYYY-MM-DD') : null,
        enrolmentCloseDate: enrolmentCloseAt
          ? moment(enrolmentCloseAt).tz(businessTimeZone).format('YYYY-MM-DD')
          : null,
        enrolmentOpenTime: enrolmentOpenAt ? moment(enrolmentOpenAt).tz(businessTimeZone).format('HH:mm') : null,
        enrolmentCloseTime: enrolmentCloseAt ? moment(enrolmentCloseAt).tz(businessTimeZone).format('HH:mm') : null,
        operatingDays: '',
        operatingWeekDays: operatingDays.map((d: string) => d.toUpperCase() as WeekDay),
        programCenters: programCenters.map((p) => p as IProgramCenter),
        programGroupId,
      });
    }
  }, [program, businessTimeZone]);

  const [updateFn, { loading: isEditLoading }] = useUpdateEnrollmentProgram({
    variables: {
      input: {
        ...convertFormDataToPayload(formData, businessId, businessTimeZone),
        id: program?.id ?? '',
      },
    },
    onCompleted: () => {
      showToast(t('enrollment.programs.edit-program-success'), 'success');
      setIsEditOpen(false);
      onSuccess();
    },
  });

  const handleEditProgram = useCallback(async () => {
    await updateFn();
  }, [updateFn]);

  return {
    isEditOpen,
    isEditLoading,
    setIsEditOpen,
    handleEditProgram,
  };
};
export default useEditEnrollmentProgram;
