import React from 'react';
import { showToast } from 'shared/components/Toast';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import timezoneOptions from 'shared/constants/dropdownOptions/timezoneOptions';

const showTimezoneToast = (timezone: Timezone) => {
  const timezoneLabel = timezoneOptions.find((tz) => tz.value === timezone)?.label;
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  return showToast(
    () => (
      <div>
        <h5>Timezone</h5>
        <div className="sm">
          We noticed you're in a different time zone. The times displayed reflect the times in the{' '}
          {fieldLabels.center.toLowerCase()} timezone, {timezoneLabel}.
        </div>
      </div>
    ),
    'info',
    'bottom-left'
  );
};

export default showTimezoneToast;
