import React, { useCallback, useEffect } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Avatar from 'shared/components/Avatar';
import Select from 'shared/components/Select';
import Checkbox from 'shared/components/Checkbox';
import { stringToHsl, getFullName, getInitials } from 'shared/util/string';
import { childContactRelationship } from 'shared/constants/enums/RelationshipEnum';
import { useGetChildContactPermissions } from 'gql/contact/queries';
import { Row, Col } from 'shared/components/Layout';
import { orderBy } from 'lodash';
import { Container } from 'react-bootstrap';

interface IProps {
  childOptions: IAccountChild[];
  contactChildren: IAccountContactChildInput[];
  updateContactChildren: (children: IAccountContactChildInput[]) => void;
  isPrimaryContact: boolean;
  setAsPrimaryOrNot?: boolean;
}

const ContactChildrenInputs: React.FC<IProps> = ({
  childOptions,
  contactChildren,
  updateContactChildren,
  isPrimaryContact,
  setAsPrimaryOrNot,
  ...props
}) => {
  const { data: getChildContactPermissionsData } = useGetChildContactPermissions();
  const permissionsOptions = getChildContactPermissionsData?.getChildContactRelationshipPermissions ?? [];
  const orderedChildOptions = orderBy(childOptions, 'firstname');

  const handleChildSelect = useCallback(
    (checked: boolean, childId: string) => {
      updateContactChildren(
        checked
          ? [...contactChildren, { childId, relationship: '', permissions: [] }]
          : contactChildren.filter((acc) => acc.childId !== childId)
      );
    },
    [contactChildren]
  );

  const handleRelationshipSelect = useCallback(
    (childId: string, relationship: string) => {
      updateContactChildren(contactChildren.map((acc) => (acc.childId === childId ? { ...acc, relationship } : acc)));
    },
    [contactChildren]
  );

  const handlePermissionSelect = useCallback(
    (checked: boolean, childId: string, permission: AccountChildRelationshipPermission) => {
      updateContactChildren(
        contactChildren.map((acc) =>
          acc.childId === childId
            ? {
                ...acc,
                permissions: checked
                  ? [...acc.permissions, permission]
                  : acc.permissions.filter((p) => p !== permission),
              }
            : acc
        )
      );
    },
    [contactChildren]
  );

  useEffect(() => {
    if (setAsPrimaryOrNot) {
      orderedChildOptions.map((child) => {
        permissionsOptions.map((permission) => {
          handlePermissionSelect(setAsPrimaryOrNot, child.id, permission.value);
        });
      });
    }
  }, [setAsPrimaryOrNot]);

  return (
    <ul className="unstyled-list">
      {orderedChildOptions.map((child, idx: number) => {
        const isChildChecked = contactChildren.some((c) => c.childId === child.id);

        // 'relationship' is sometimes an empty string, not null (our TypeScript types don't allow null)...
        const selectedRelationship = contactChildren.find((c) => c.childId === child.id)?.relationship;
        // ...so see whether it's truth-y or not before deciding to use its value or null.
        const selectedValue = selectedRelationship ? selectedRelationship : null;

        return (
          <li className="mb-8" key={`account-contact-child-${child.id}-${idx}`}>
            <Row className="mb-2">
              <Col>
                <Row>
                  {
                    <Checkbox
                      disabled={isPrimaryContact}
                      value={isChildChecked}
                      onChange={(checked) => handleChildSelect(checked, child.id)}
                    />
                  }
                  <Avatar
                    color={stringToHsl(child.id)}
                    size="sm"
                    image={child.avatar?.url ?? ''}
                    initials={getInitials(child)}
                  />
                  <div className="d-flex flex-column ml-2 text-truncate sm">{getFullName(child)}</div>
                </Row>
              </Col>
              <Col>
                {
                  <Collapse in={Boolean(isChildChecked)}>
                    <Select
                      className="mb-0"
                      value={selectedValue}
                      options={Object.entries(childContactRelationship).map((r) => ({ value: r[0], label: r[1] }))}
                      onChange={(option) => handleRelationshipSelect(child.id, option.value)}
                    />
                  </Collapse>
                }
              </Col>
            </Row>
            {
              <Collapse in={Boolean(isChildChecked)}>
                <Row className="ml-8">
                  <Container>
                    {permissionsOptions.map((permission, idx) => (
                      <Row key={idx}>
                        <Col>
                          <Checkbox
                            className="ml-auto"
                            label={permission.label}
                            disabled={isPrimaryContact}
                            value={
                              setAsPrimaryOrNot
                                ? setAsPrimaryOrNot
                                : contactChildren
                                    .find((c) => c.childId === child.id)
                                    ?.permissions?.includes(permission.value)
                            }
                            onChange={(checked) => handlePermissionSelect(checked, child.id, permission.value)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Container>
                </Row>
              </Collapse>
            }
          </li>
        );
      })}
    </ul>
  );
};

export default ContactChildrenInputs;
