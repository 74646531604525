import { faCalendarDay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useCallback } from 'react';
import { Col, Row } from 'shared/components/Layout';
import useFormatDate from 'shared/hooks/useFormatDate';
import { isRegion } from 'shared/util/region';
import colors from '_colors.module.scss';
import reasonForContractEndingOptions from 'shared/constants/enums/reasonForContractEndingOptions';

interface IProps {
  contract: IContract;
  centerTimezone: Timezone;
}

const ContractInformation: React.FC<IProps> = ({ contract, centerTimezone, ...props }) => {
  const formatDate = useFormatDate();

  const reasonForEndingToString = useCallback((reason: ReasonForContractEnding): string => {
    return reasonForContractEndingOptions.filter((i) => i.value === reason)[0].label ?? '';
  }, []);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <div className="font-size-12">Class</div>
          <div className="text-dark">{contract.class?.name}</div>
        </Col>
        <Col>
          <div className="font-size-12">{isRegion('US') ? 'Billed Fee' : 'Fee'}</div>
          <div className="text-dark">{contract.permanentFee.name}</div>
        </Col>
      </Row>
      {contract.originalFeeId && contract.adjustmentId && isRegion('US') && (
        <Row className="mb-3" align="start" justify="start">
          <Col>
            <div className="font-size-12">Adjustment Reason</div>
            <div className="text-dark">{contract.adjustment?.name}</div>
          </Col>
          <Col>
            <div className="font-size-12">Standard Fee</div>
            <div className="text-dark">{contract.originalFee?.name}</div>
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col>
          <div className="font-size-12">Start Date</div>
          <div className="text-dark">
            <FontAwesomeIcon icon={faCalendarDay} size="lg" color={colors.gray} className="mr-1" />
            {formatDate(contract.startDate, 'MM/DD/YYYY')}
          </div>
        </Col>
        <Col>
          <div className="font-size-12">End Date</div>
          <div className="text-dark">
            <FontAwesomeIcon icon={faCalendarDay} size="lg" color={colors.gray} className="mr-1" />
            {contract.endDate && formatDate(contract.endDate, 'MM/DD/YYYY')}
          </div>
        </Col>
      </Row>
      <Row className="mb-3" align="start">
        <Col>
          <div className="font-size-12">Created</div>
          <div className="text-dark">
            {!!contract.createdByAccount
              ? `${contract.createdByAccount.firstname} ${contract.createdByAccount.lastname} at ${formatDate(
                  moment.tz(contract.createdAt, centerTimezone),
                  'MM/DD/YYYY'
                )}`
              : `System at ${formatDate(moment.tz(contract.createdAt, centerTimezone), 'MM/DD/YYYY')}`}
          </div>
        </Col>
        <Col>
          <div className="font-size-12">Last Modified</div>
          <div className="text-dark">
            {!!contract.lastModifiedByAccount && !!contract.lastModifiedAt
              ? `${contract.lastModifiedByAccount.firstname} ${contract.lastModifiedByAccount.lastname} at ${formatDate(
                  moment.tz(contract.lastModifiedAt, centerTimezone),
                  'MM/DD/YYYY'
                )}`
              : '-'}
          </div>
        </Col>
      </Row>
      {contract.reasonForEnding && (
        <Row className="mb-3">
          <Col>
            <div className="font-size-12">Reason for ending contract</div>
            <div className="text-dark">
              {contract.reasonForEnding ? reasonForEndingToString(contract.reasonForEnding) : 'fill'}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ContractInformation;
