import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateBillingCycleTemplate } from 'gql/billingCycle/mutations';
import Modal from 'react-bootstrap/Modal';
import Button from 'shared/components/Buttons';
import { showToast } from 'shared/components/Toast';
import BillingCycleSentence from './BillingCycleSentence';
import { updateBillingCycleTemplate } from 'pages/Billing/Settings/duck/actions';
import TextInput from 'shared/components/TextInput';

interface IProps {
  isOpen: boolean;
  template: IBillingCycleTemplate;
  onClose: () => void;
  onDelete: (template: IBillingCycleTemplate) => void;
}

const EditBillingCycleTemplateModal: React.FC<IProps> = ({ isOpen, template, onClose, onDelete, ...props }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IBillingCycleTemplate>({ ...template });
  const [updateTemplateFn, { loading: updateTemplateLoading }] = useUpdateBillingCycleTemplate({
    onCompleted: (data) => {
      if (data.updateBillingCycleTemplate) {
        handleClose();
        showToast('Updated template successfully.', 'success');
        dispatch(updateBillingCycleTemplate(data.updateBillingCycleTemplate));
      }
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleUpdate = useCallback((updates: Partial<IBillingCycleTemplate>) => {
    setFormData((prev) => ({
      ...prev,
      ...updates,
    }));
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const validateForm = useCallback((form: IBillingCycleTemplate): boolean => {
    const isWeeklyFrequency = /WEEKLY/i.test(form.frequency);

    return (
      Boolean(form.name) &&
      form.frequency !== null &&
      form.period !== null &&
      Boolean(isWeeklyFrequency ? form.dayOfWeek !== null : true) &&
      Boolean(!isWeeklyFrequency ? form.dayOfMonth !== null : true)
    );
  }, []);

  const handleSubmit = useCallback(() => {
    updateTemplateFn({
      variables: {
        input: {
          id: formData.id,
          businessId: formData.businessId,
          name: formData.name,
          frequency: formData.frequency,
          period: formData.period,
          dayOfMonth: formData.dayOfMonth ?? null,
          dayOfWeek: formData.dayOfWeek ?? null,
          invoiceSchedule: {
            unit: formData.invoiceSchedule.unit,
            value: formData.invoiceSchedule.value,
          },
          additionalChargePeriod: {
            unit: formData?.additionalChargePeriod?.unit ?? null,
            value: formData?.additionalChargePeriod?.value ?? 0,
          },
          gracePeriodDays: formData.gracePeriodDays ?? null,
          lateChargeAmount: formData.lateChargeAmount ?? null,
        },
      },
    });
  }, [updateTemplateFn, formData]);

  return (
    <Modal centered backdrop="static" show={isOpen} onHide={handleClose} size="lg">
      <Modal.Header closeButton className="border-bottom-0 pb-4">
        <div className="d-flex flex-column">
          <Modal.Title as="h3">Edit Billing Cycle</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <h5 className="mb-6"> Complete this sentence to edit this billing cycle. </h5>
        <BillingCycleSentence cycle={formData} onUpdate={handleUpdate} />
        <TextInput
          label="Billing Cycle Name"
          value={formData.name}
          onChange={(value: string) => setFormData((prev) => ({ ...prev, name: value }))}
          placeholder="Billing cycle name"
          className="w-50"
          isInvalid={!formData.name}
          errorText="A name is required for a billing cycle"
        />
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div>
          <Button id="edit-cycle-modal-deactivate-btn" variant="outline-danger" onClick={() => onDelete(template)}>
            Delete Cycle
          </Button>
        </div>
        <div className="ml-auto">
          <Button id="edit-cycle-modal-secondary-btn" className="mr-3" variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            id="edit-cycle-modal-primary-btn"
            variant="primary"
            disabled={updateTemplateLoading || !validateForm(formData)}
            loading={updateTemplateLoading}
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditBillingCycleTemplateModal;
