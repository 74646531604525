import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginWithEmailAndPassword } from './duck/actions';
import { isEmailSyntaxValid, isEmailValid } from 'shared/util/email';
import { PasswordInput, EmailInput } from 'shared/components/TextInput';
import Button, { ButtonAsLink } from 'shared/components/Buttons';
import Alert from 'shared/components/Alert';
import errorMessage from 'shared/constants/errorMessages';
import apple from './images/apple.png';
import google from './images/google.png';
import { sessionStatus } from 'shared/constants/enums/sessionStatus';
import { Row } from 'shared/components/Layout';
import { useTranslation } from 'react-i18next';
import { useGetAuthenticationIdentityResponseLazyQuery } from 'generated/graphql';

const EmailLogin = ({ toggleSSO, email, updateEmail }) => {
  const { t } = useTranslation();
  const [password, updatePassword] = useState('');
  const errorCode = useSelector((state) => state.session.errorCode);
  const isLoading = useSelector((state) => state.session.status) === sessionStatus.LOADING;
  const dispatch = useDispatch();

  const [getIdpIdentifierResponseQuery] = useGetAuthenticationIdentityResponseLazyQuery({
    variables: {
      input: {
        email,
      },
    },
  });

  const handleUpdateEmail = useCallback(
    (value) => {
      updateEmail(value);
    },
    [updateEmail]
  );

  const login = useCallback(
    async (event) => {
      event.preventDefault();
      const result = await getIdpIdentifierResponseQuery();

      // We do not continue logging in if this is a managed email
      const idpIdentifier = result?.data?.getAuthenticationIdentityResponse?.idpIdentifier;

      if (idpIdentifier) {
        console.log(idpIdentifier);
        toggleSSO();
        return;
      }

      dispatch(loginWithEmailAndPassword({ email, password }));
      // store users sign in method (SSO vs password) and show that same method next time they go to login
      localStorage.setItem('isUserSSO', false);
    },
    [getIdpIdentifierResponseQuery, dispatch, email, password, toggleSSO]
  );

  return (
    <>
      {errorCode && <Alert variant="danger">{errorMessage[errorCode]}</Alert>}
      <div className="login-container mx-auto p-8">
        {/* <SocialSignOn title="Log In" /> */}
        <Row justify="center">
          <p className="h1 font-weight-light">Log In</p>
        </Row>
        <form id="login-regular-form" onSubmit={login} className="d-flex flex-column">
          <EmailInput
            id="cypress--login-email-input"
            label="Email Address"
            value={email}
            onChange={handleUpdateEmail}
            isInvalid={email !== '' ? !isEmailValid(email) : false}
            requiresValidation={email !== ''}
            errorText={!isEmailSyntaxValid(email) ? errorMessage.invalidEmailSyntax : errorMessage.invalidEmailDomain}
            autoComplete="on"
          />
          <PasswordInput
            id="cypress--login-password-input"
            label="Password"
            type="password"
            value={password}
            onChange={updatePassword}
          />
          <Link className="mb-8 ml-auto sm" to="/recover-password">
            Forgot password?
          </Link>
          <Button
            id="cypress--login-submit-btn"
            loading={isLoading}
            className="mb-8 bg-primary text-white opaque"
            type="submit"
            disabled={!isEmailValid(email) || !password.length}
          >
            Log In
          </Button>
          <ButtonAsLink onClick={toggleSSO}>Log in with SSO</ButtonAsLink>
        </form>
        <div className="d-flex justify-content-center mt-2">
          <p style={{ fontFamily: '"proxima-soft", "sans-serif"' }}>
            Questions? <a href={t('link.kangarootimeWebsite.url')}>Schedule a demo</a>
          </p>
        </div>
        <div style={{ marginTop: '72px' }}>
          <h5 className="text-center mb-8">Are you a parent? We've got an app for you. </h5>
          <div className="d-flex justify-content-center">
            <a href="https://apps.apple.com/us/app/kt-connect/id1577113348" className="mx-8">
              <img src={apple} alt="download for ios" width="120" className="mb-6" />
            </a>
            <a href="https://play.google.com/store/search?q=kt+connect+app&c=apps" className="mx-8">
              <img src={google} alt="download for android" width="120" className="mb-6" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailLogin;
