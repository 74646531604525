import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import moment from 'moment';
import KindyFundingSettings from './KindyFundingSettings';
import { cloneDeep } from 'lodash';
import classnames from 'classnames';
import { IconButton, IconButtonCircle } from 'shared/components/Buttons';
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import useFormatDate from 'shared/hooks/useFormatDate';
import _ from 'lodash';
import StartStrongFundingSettings from './StartStrongFundingSettings';
import {
  freeKindySubsidySchemeId,
  kindyForAllSubsidySchemeId,
  startStrongSubsidySchemeId,
} from 'pages/Subsidies/KindyFunding/utils';
import FreeKindyFundingSettings from './FreeKindyFundingSettings';

interface IProps {
  subsidySchemeId?: string;
  formData: IKindyProgramChildFundingSchedule[] | undefined;
  handleChange: (schedules: IKindyProgramChildFundingSchedule[]) => void;
}

const KindyFundingSchedules: React.FC<IProps> = ({ subsidySchemeId, formData, handleChange }) => {
  const [settingFormData, setSettingFormData] = useState<IKindyProgramChildFundingSchedule[]>(formData ?? []);
  const newSettingSchedule: IKindyProgramChildFundingSchedule = {
    startDate: '',
    subsidyTypes: [],
    basePercentage: undefined,
  };
  //Automated Settings
  const formatDate = useFormatDate();
  const [isCreatingNewSchedule, setIsCreatingNewSchedule] = useState(false);
  const [selectedScheduleStartDate, setSelectedScheduleStartDate] = useState<string | undefined>(
    formData?.find((a) => a.startDate)?.startDate
  );

  const isStartDateTaken = useCallback(
    (startDate: string | undefined) =>
      formData?.some((s) => moment(startDate).format('MM/DD/YY') === moment(s.startDate).format('MM/DD/YY')) ?? false,
    [formData]
  );
  const handleFundingSettings = useCallback(
    (selected: IKindyProgramChildFundingSchedule) => {
      const mutableState = cloneDeep(settingFormData);
      const fundingSettings = (mutableState ?? []) as IKindyProgramChildFundingSchedule[];
      if (fundingSettings) {
        const index = fundingSettings.findIndex((c) => c.startDate === selected.startDate);

        if (index >= 0) {
          fundingSettings[index] = selected;
        } else {
          fundingSettings.push(selected);
        }
      } else {
        // @ts-ignore
        fundingSettings.push(selected);
      }
      setSettingFormData(fundingSettings);
    },
    [settingFormData]
  );

  const handleUpdateFundingSettings = useCallback((updatedSchedules: IKindyProgramChildFundingSchedule[]) => {
    setSettingFormData(updatedSchedules);
  }, []);

  const handleAddNewFundingSchedule = useCallback(
    (newScheduleDate) => {
      if (newScheduleDate) {
        if (!isStartDateTaken(newScheduleDate)) {
          const scheduleToAdd = {
            ...newSettingSchedule,
            startDate: newScheduleDate,
          } as IKindyProgramChildFundingSchedule;
          handleFundingSettings(scheduleToAdd);
          setSelectedScheduleStartDate(newScheduleDate);
          setIsCreatingNewSchedule(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleFundingSettings, isStartDateTaken]
  );

  useEffect(() => {
    handleChange(settingFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingFormData]);

  return (
    <div>
      <Row noGutters align="stretch" className="h-100">
        <Col xs={3} align="stretch" className="border rounded mr-4 h-200 flex-column d-flex">
          <div
            className={
              selectedScheduleStartDate
                ? 'border rounded flex-grow-1 flex-column d-flex'
                : 'flex-grow-1 flex-column d-flex'
            }
          >
            {isCreatingNewSchedule && (
              <DateInput
                autoFocus={true}
                dateOnly
                date={undefined}
                onDateSelect={(date) => {
                  handleAddNewFundingSchedule(date);
                }}
                isDayBlocked={isStartDateTaken}
                isOutsideRange={(date: moment.Moment) => date.day() !== 1}
              />
            )}
            <ul className="list-unstyled option-list">
              {_.orderBy(settingFormData, 'startDate', 'desc')
                ?.filter((a) => a.startDate)
                .map((schedule, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setSelectedScheduleStartDate(schedule?.startDate);
                    }}
                    className={classnames({
                      'px-4 py-2': true,
                      'cursor-pointer': !isCreatingNewSchedule,
                      'font-weight-semi-bold': schedule.startDate === selectedScheduleStartDate,
                      'bg-pale-grey': schedule.startDate === selectedScheduleStartDate,
                      'bg-danger-10':
                        !isCreatingNewSchedule &&
                        ((schedule.subsidyTypes.length > 0 && !schedule.basePercentage) ||
                          (schedule.basePercentage && (schedule.basePercentage <= 0 || schedule.basePercentage > 100))),
                    })}
                  >
                    <div className="d-flex ">
                      {schedule.startDate === selectedScheduleStartDate ? (
                        <IconButton
                          icon={faTrashAlt}
                          className="mr-2"
                          iconSize="1x"
                          tooltipText="Delete schedule"
                          onClick={() => {
                            if (settingFormData) {
                              const updatedSchedules = [...settingFormData];
                              const index = updatedSchedules.findIndex((s) => s.startDate === schedule.startDate);
                              if (index !== -1) {
                                updatedSchedules.splice(index, 1);
                              }
                              handleUpdateFundingSettings(updatedSchedules);
                            }
                          }}
                        />
                      ) : (
                        <div className="pr-6"></div>
                      )}
                      {schedule.startDate && formatDate(moment(schedule.startDate), 'MM/DD/YY')}
                    </div>
                  </li>
                ))}
            </ul>

            <IconButtonCircle
              icon={faPlus}
              iconSize="sm"
              size="sm"
              color="white"
              backgroundColor="secondary"
              className="mt-auto ml-auto mr-2 mb-2"
              onClick={() => {
                setIsCreatingNewSchedule(true);
                setSelectedScheduleStartDate(undefined);
              }}
              tooltipText="Add another schedule"
              disabled={isCreatingNewSchedule}
            />
          </div>
        </Col>
        <Col align="stretch">
          {subsidySchemeId === startStrongSubsidySchemeId && (
            <StartStrongFundingSettings
              formData={settingFormData
                ?.map((fs) => {
                  return {
                    startDate: fs.startDate,
                    subsidyTypes: fs.subsidyTypes,
                    basePercentage: undefined,
                  };
                })
                .find((s) => s.startDate === selectedScheduleStartDate)}
              handleChange={handleFundingSettings}
              selectedDate={selectedScheduleStartDate}
            />
          )}
          {subsidySchemeId === kindyForAllSubsidySchemeId && (
            <KindyFundingSettings
              formData={settingFormData
                ?.map((fs) => {
                  return {
                    startDate: fs.startDate,
                    subsidyTypes: fs.subsidyTypes,
                    basePercentage: fs.basePercentage ? Math.round(fs.basePercentage * 100) : undefined,
                  };
                })
                .find((s) => s.startDate === selectedScheduleStartDate)}
              handleChange={handleFundingSettings}
              selectedDate={selectedScheduleStartDate}
            />
          )}
          {subsidySchemeId === freeKindySubsidySchemeId && (
            <FreeKindyFundingSettings
              formData={settingFormData
                ?.map((fs) => {
                  return {
                    startDate: fs.startDate,
                    subsidyTypes: fs.subsidyTypes,
                    basePercentage: fs.basePercentage ? Math.round(fs.basePercentage * 100) : undefined,
                  };
                })
                .find((s) => s.startDate === selectedScheduleStartDate)}
              handleChange={handleFundingSettings}
              selectedDate={selectedScheduleStartDate}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default KindyFundingSchedules;
