import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

type EndCareHistoryActionType = 'UNHANDLED' | 'HIDE' | 'REVEAL' | 'VOID' | 'CHARGE_FULL_FEE';

export interface GetEndCareEnrollmentPageInput {
  businessId: string;
  centerIds: string[] | null;
  pageNumber: number;
  pageSize: number;
  sortDtos:
    | {
        field: string;
        direction: string;
      }[]
    | {
        field: string;
        direction: string;
      };
  searchText: string;
}

interface IEndCareEnrollmentPageInputVariables {
  input: GetEndCareEnrollmentPageInput;
}

interface GetEndCareEnrollmentPage {
  getEndCareEnrollmentPage: {
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: {
      accountId: string;
      accountName: string;
      centerId: string;
      centerName: string;
      accountChildId: string;
      childId: string;
      childFirstName: string;
      childLastName: string;
      enrollmentId: string;
      status: CcsEnrollmentStatus | null;
      totalDue: string | number;
      sessionIds: string[];
    }[];
  };
}

export const getEndCareEnrollmentPage = gql`
  query ($input: GetEndCareEnrollmentPageInput!) {
    getEndCareEnrollmentPage(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        accountId
        accountName
        centerId
        centerName
        accountChildId
        childId
        childFirstName
        childLastName
        enrollmentId
        status
        totalDue
        sessionIds
      }
    }
  }
`;

export const useGetEndCareEnrollmentPage = (input: IEndCareEnrollmentPageInputVariables) =>
  useQuery<GetEndCareEnrollmentPage, IEndCareEnrollmentPageInputVariables>(getEndCareEnrollmentPage, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      ...input,
    },
  });

interface GetEndCareEnrollmentMetricsInput {
  input: {
    businessId: string;
    centerIds: string[];
  };
}

interface GetEndCareEnrollmentMetrics {
  getEndCareEnrollmentMetrics: {
    accountCount: number;
    sessionCount: number;
    totalDue: number;
  };
}

export const getEndCareEnrollmentMetricsInput = gql`
  query ($input: GetEndCareEnrollmentMetricsInput!) {
    getEndCareEnrollmentMetrics(input: $input) {
      accountCount
      sessionCount
      sessionIds
      totalDue
    }
  }
`;

export const useGetEndCareEnrollmentMetricsInput = (input: GetEndCareEnrollmentMetricsInput) =>
  useQuery<GetEndCareEnrollmentMetrics, GetEndCareEnrollmentMetricsInput>(getEndCareEnrollmentMetricsInput, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      ...input,
    },
  });

interface GetEndCareSessionsInput {
  input: {
    businessId: string;
    accountChildId: string;
  };
}

interface getEndCareSessions {
  getEndCareSessions: {
    id: string;
    date: string;
    class: {
      name: string;
      careType: string;
    };
    absence: {
      reason: string;
      type: string;
    };
    cost: { sessionCost: string };
    endOfCareSubsidy: number;
  }[];
}

export const getEndCareSessions = gql`
  query ($input: GetEndCareSessionsInput!) {
    getEndCareSessions(input: $input) {
      id
      date
      class {
        name
        careType
      }
      absence {
        reason
        type
      }
      cost {
        sessionCost
      }
      endOfCareSubsidy
    }
  }
`;

export const useGetEndCareSessions = (input: GetEndCareSessionsInput) =>
  useQuery<getEndCareSessions, GetEndCareSessionsInput>(getEndCareSessions, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...input,
    },
  });

interface GetEndCareHistorySessionsInput {
  input: {
    businessId: string;
    transactionId: string;
  };
}

interface getEndCareHistorySessions {
  getEndCareHistorySessions: {
    id: string;
    date: string;
    class: {
      name: string;
      careType: string;
    };
    absence: {
      reason: string;
      type: string;
    };
    cost: { sessionCost: string };
    endOfCareSubsidy: number;
  }[];
}

export const getEndCareHistorySessions = gql`
  query ($input: GetEndCareHistorySessionsInput!) {
    getEndCareHistorySessions(input: $input) {
      id
      date
      class {
        name
        careType
      }
      absence {
        reason
        type
      }
      cost {
        sessionCost
      }
      endOfCareSubsidy
    }
  }
`;

export const useGetEndCareHistorySessions = (input: GetEndCareHistorySessionsInput) =>
  useQuery<getEndCareHistorySessions, GetEndCareHistorySessionsInput>(getEndCareHistorySessions, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...input,
    },
  });

interface IEndCareEnrollmentHistoryPageInputVariables {
  input: GetEndCareEnrollmentPageInput;
}

interface GetEndCareEnrollmentHistoryPage {
  getEndCareEnrollmentHistoryPage: {
    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    data: {
      transactionId: string;
      accountId: string;
      accountName: string;
      centerId: string;
      centerName: string;
      accountChildId: string;
      childId: string;
      childFirstName: string;
      childLastName: string;
      enrollmentId: string;
      status: CcsEnrollmentStatus | null;
      totalDue: number;
      date: string;
      actionType: string;
      person: {
        id: string;
        firstname: string;
        lastname: string;
        avatar: {
          url: string;
        };
      };
    }[];
  };
}

export const getEndCareEnrollmentHistoryPage = gql`
  query ($input: GetEndCareEnrollmentPageInput!) {
    getEndCareEnrollmentHistoryPage(input: $input) {
      pageNumber
      pageSize
      totalRecords
      data {
        transactionId
        accountId
        accountName
        centerId
        centerName
        accountChildId
        childId
        childFirstName
        childLastName
        enrollmentId
        status
        totalDue
        date
        actionType
        person {
          id
          firstname
          lastname
          avatar {
            url
          }
        }
      }
    }
  }
`;

export const useGetEndCareEnrollmentHistoryPage = (input: IEndCareEnrollmentHistoryPageInputVariables) =>
  useQuery<GetEndCareEnrollmentHistoryPage, IEndCareEnrollmentHistoryPageInputVariables>(
    getEndCareEnrollmentHistoryPage,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        ...input,
      },
    }
  );

interface GetEndCareEnrollmentHistoryByAccountChildIdInput {
  businessId: string;
  accountChildId: string;
  actionTypes: EndCareHistoryActionType[];
}

interface IGetEndCareEnrollmentHistoryByAccountChildIdInput {
  input: GetEndCareEnrollmentHistoryByAccountChildIdInput;
}

interface GetEndCareEnrollmentHistoryByAccountChildId {
  getEndCareEnrollmentHistoryByAccountChildId: {
    transactionId: string;
    accountId: string;
    accountName: string;
    centerId: string;
    centerName: string;
    accountChildId: string;
    childId: string;
    childFirstName: string;
    childLastName: string;
    date: string;
    actionType: string;
    person: {
      id: string;
      firstname: string;
      lastname: string;
    };
  };
}

export const getEndCareEnrollmentHistoryByAccountChildId = gql`
  query ($input: GetEndCareEnrollmentHistoryByAccountChildIdInput!) {
    getEndCareEnrollmentHistoryByAccountChildId(input: $input) {
      transactionId
      accountId
      accountName
      centerId
      centerName
      accountChildId
      childId
      childFirstName
      childLastName
      date
      actionType
      person {
        id
        firstname
        lastname
      }
    }
  }
`;

export const useGetEndCareEnrollmentHistoryByAccountChildId = (
  input: IGetEndCareEnrollmentHistoryByAccountChildIdInput
) =>
  useQuery<GetEndCareEnrollmentHistoryByAccountChildId, IGetEndCareEnrollmentHistoryByAccountChildIdInput>(
    getEndCareEnrollmentHistoryByAccountChildId,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        ...input,
      },
    }
  );
