import {
  ApplicationFlowType,
  ApplicationOffer,
  ApplicationScheduleOffer,
  EnrollmentProgram,
  UpdateScheduleOfferInput,
  useCheckOfferAvailabilityLazyQuery,
  useUpdateApplicationOfferAndSchedulesMutation,
} from 'generated/graphql';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OfferAccordion from '../OfferCard/OfferAccordion';
import { Modal } from 'react-bootstrap';
import { capitalize, cloneDeep } from 'lodash';
import cast from 'shared/util/cast';
import { toggleDayInWeek } from '../../utils';
import { useLeadsContext } from '../../LeadsContext';
import { IApplicationScheduleDay } from 'shared/types/application';
import Button from 'shared/components/Buttons';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { showToast } from 'shared/components/Toast';
import classNames from 'classnames';
import DateInput from 'shared/components/DateInput';
import moment from 'moment';
import colors from '_colors.module.scss';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import Checkbox from 'shared/components/Checkbox';
import { ApplicationChildWithSchedules } from './ViewOffers/ViewOffers';
import { Col, Row } from 'shared/components/Layout';
import { useGetApplicationSettings } from 'gql/applicationSetting/queries';
import Alert from 'shared/components/Alert';

interface IEditOfferModalProps {
  isOpen: boolean;
  onHide: () => void;
  onSave: () => void;
  offer: ApplicationOffer;
  childrenForOffer: ApplicationChildWithSchedules[];
  centerIds: string[];
  applicationId: string;
}

type IOfferForm = Record<string, ApplicationScheduleOffer[]>;
type ApplicationScheduleOfferWithChildId = ApplicationScheduleOffer & { childId: string };

const EditOfferModal: React.FC<IEditOfferModalProps> = ({
  isOpen,
  onHide,
  onSave,
  offer,
  childrenForOffer,
  centerIds,
  applicationId,
}) => {
  const { t } = useTranslation();
  const [showSummary, setShowSummary] = useState(false);
  const centerId = centerIds[0];
  const { businessId, applicationFlowType, handleRefetchApplications: refetch } = useLeadsContext();
  const [expiryDate, setExpiryDate] = useState<string>(offer.expiryDate);
  const timezoneByCenterId = useSelector((state: RootState) => state.timezone.byCenterId);
  const timezone = timezoneByCenterId[centerId] ?? moment.tz.guess();

  const isProgramFlow = applicationFlowType !== ApplicationFlowType.InquireOfferEnrollment;
  const [resendOfferEmail, setResendOfferEmail] = useState<boolean>(!isProgramFlow);

  const { data: applicationSettings } = useGetApplicationSettings({
    variables: {
      input: {
        businessId: businessId ?? '',
      },
    },
    skip: !businessId,
  });

  const initialFormData: IOfferForm = useMemo(() => {
    return childrenForOffer.reduce((acc: IOfferForm, curr: ApplicationChildWithSchedules) => {
      acc[curr.childId] = cloneDeep([...curr.childSchedulesForOffer]).map((sched) => {
        return { ...sched };
      });
      return acc;
    }, {});
  }, [childrenForOffer]);

  const [formData, setFormData] = useState<IOfferForm>(initialFormData);

  const [checkAvailability, { loading: loadingCheckAvailability, data: dataCheckAvailability }] =
    useCheckOfferAvailabilityLazyQuery({
      onCompleted: (result) => {
        setShowSummary(true);
      },
      onError: (err) => showToast(getApolloErrorMessage(err), 'error'),
    });

  const [updateOfferAndSchedule, { loading: loadingUpdateOffer, data: dataUpdateOffer }] =
    useUpdateApplicationOfferAndSchedulesMutation({
      onCompleted: (result) => {
        showToast(t('enrollment.lead-management.update-offer-success'), 'success');
        resetData();
        onSave();
      },
      onError: (err) => showToast(getApolloErrorMessage(err), 'error'),
    });

  const handleChange = useCallback(
    (value: any, field: keyof ApplicationScheduleOffer, childId: string, schedIndex: number) => {
      const mutableSchedule = cloneDeep(formData[childId]);
      if (field === 'program') {
        const program = cast<EnrollmentProgram>(value);
        mutableSchedule[schedIndex][field] = program;
        mutableSchedule[schedIndex].programId = program.id;
        const programCenter = program.programCenters.find((pc) => pc.centerId === centerId);
        if (programCenter) {
          mutableSchedule[schedIndex].classId = programCenter.classId;
          mutableSchedule[schedIndex].feeId = programCenter.feeId;
        }
        mutableSchedule[schedIndex].days = [];
        mutableSchedule[schedIndex].startDate = '';
        mutableSchedule[schedIndex].endDate = program.endDate;
      } else if (field === 'classId' && formData[childId][schedIndex].classId !== value) {
        // if we select a different class then reset the fee to be empty because classes can have different fees
        mutableSchedule[schedIndex][field] = value;
        mutableSchedule[schedIndex].feeId = null;
      } else {
        mutableSchedule[schedIndex][field] = value;
      }

      setFormData({ ...formData, [childId]: mutableSchedule });
    },
    [formData, centerId]
  );

  const handleDayToggle = useCallback(
    (value: WeekDay, weekType: WeekType, childId: string, schedIndex: number) => {
      if (formData) {
        const scheds = [...formData[childId]];
        const schedCopy: ApplicationScheduleOffer = { ...scheds[schedIndex] };
        let firstWeek = schedCopy?.days?.filter((d) => d.weekType === 'WEEK1');
        let secondWeek = schedCopy?.days?.filter((d) => d.weekType === 'WEEK2');

        if (weekType === 'WEEK1') {
          firstWeek = toggleDayInWeek(value, weekType, firstWeek);
        } else {
          secondWeek = toggleDayInWeek(value, weekType, secondWeek);
        }

        schedCopy.days = [...firstWeek, ...secondWeek];
        scheds[schedIndex] = schedCopy;
        setFormData({ ...formData, [childId]: scheds });
      }
    },
    [formData]
  );

  const buildOfferMessage = useCallback(() => {
    const allScheduleOffers = Object.keys(formData).reduce<ApplicationScheduleOfferWithChildId[]>((acc, cur) => {
      const schedules = formData[cur];
      const schedulesWithChildId = schedules.map((schedule) => ({ ...schedule, childId: cur }));
      return [...acc, ...schedulesWithChildId];
    }, []);

    return {
      businessId,
      applicationId,
      applicationOfferId: offer.id,
      input: {
        resendOfferEmail: resendOfferEmail,
        expiryDate: expiryDate,
        scheduleOffers: allScheduleOffers.map((schedule) => {
          return {
            id: schedule.id,
            applicationScheduleOfferId: schedule.id,
            applicationId,
            businessId,
            careType: schedule.careType,
            startDate: schedule.startDate,
            endDate: schedule.endDate,
            applicationChildId: schedule.childId,
            classId: schedule.classId,
            offerCenterId: centerId,
            cycleType: (schedule.cycleType ?? 'WEEKLY') as ContractCycleType,
            feeId: schedule.feeId,
            programId: schedule.programId || schedule.program?.id,
            days:
              schedule.cycleType === 'CASUAL'
                ? []
                : (schedule.days.map((day) => ({
                    weekType: day.weekType,
                    dayOfWeek: day.dayOfWeek,
                  })) as IApplicationScheduleDay[]),
          };
        }) as UpdateScheduleOfferInput[],
      },
    };
  }, [applicationId, businessId, centerId, expiryDate, formData, offer.id, resendOfferEmail]);

  const isFormValid = useMemo(() => {
    let isValid = true;
    Object.keys(formData).forEach((childId) => {
      const schedules = formData[childId];
      const hasMissingData = schedules.filter((schedule) => {
        const { startDate, program, days, cycleType } = schedule;
        if (!program) return false;
        const { minEnrolmentDays, startDate: programStartDate, endDate: programEndDate } = program;
        if (
          (cycleType !== 'CASUAL' && days.length < minEnrolmentDays) ||
          !moment(startDate).isValid() ||
          moment(startDate).isBefore(moment(programStartDate)) ||
          moment(startDate).isAfter(moment(programEndDate))
        ) {
          return true;
        }

        return false;
      });

      if (hasMissingData.length > 0) {
        isValid = false;
      }
    });
    return isValid;
  }, [formData]);

  const handleCheckAvailability = useCallback(() => {
    const updateOfferVariables = buildOfferMessage();
    checkAvailability({ variables: updateOfferVariables });
  }, [buildOfferMessage, checkAvailability]);

  const handleUpdateOffer = useCallback(() => {
    const updateOfferVariables = buildOfferMessage();
    updateOfferAndSchedule({ variables: updateOfferVariables });
  }, [buildOfferMessage, updateOfferAndSchedule]);

  const resetData = useCallback(() => {
    setShowSummary(false);
    setFormData(initialFormData);
    setResendOfferEmail(true);
    if (offer.expiryDate && moment(offer.expiryDate).isBefore(moment())) {
      const daysToExpire = applicationSettings?.getApplicationSetting.offersExpireInDays;
      setExpiryDate(
        moment()
          .add(daysToExpire ?? 1, 'days')
          .toString()
      );
    } else {
      setExpiryDate(offer.expiryDate ?? '');
    }
  }, [applicationSettings?.getApplicationSetting.offersExpireInDays, initialFormData, offer.expiryDate]);

  const handleCloseModal = useCallback(() => {
    resetData();
    onHide();
  }, [onHide, resetData]);

  useEffect(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  // if the expiry date is in the past, force it to update to (daysToExpire setting + 1 day)
  useEffect(() => {
    if (offer.expiryDate && moment(offer.expiryDate).isBefore(moment())) {
      const daysToExpire = applicationSettings?.getApplicationSetting.offersExpireInDays;
      setExpiryDate(
        moment()
          .add(daysToExpire ?? 1, 'days')
          .toString()
      );
    } else {
      setExpiryDate(offer.expiryDate ?? '');
    }
  }, [applicationSettings?.getApplicationSetting.offersExpireInDays, offer.expiryDate]);

  return (
    <Modal centered backdrop="static" show={isOpen} onHide={handleCloseModal} className="edit-offer-modal" size="xl">
      <Modal.Header closeButton className="border-bottom-0 pb-4">
        <div className="d-flex flex-column">
          <Modal.Title as="h3" className="px-4">
            {t('enrollment.lead-management.edit-offer')}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-0">
        {!showSummary && (
          <>
            <Row className="px-4 py-0 mb-0">
              <Col md={3} className="mb-4">
                <label>Expiry Date</label>
                <DateInput
                  required
                  date={expiryDate}
                  onDateSelect={(date) => setExpiryDate(date)}
                  isValid={moment(expiryDate).isBefore(moment())}
                  isOutsideRange={(day) => {
                    return moment(day).isBefore(moment());
                  }}
                />
              </Col>
            </Row>
            <hr className="mb-5 mt-5" />
          </>
        )}
        {showSummary && (
          <>
            <div className="d-flex justify-content-start align-items-center mb-5 px-4">
              <div className="px-4 py-1 lead-label" style={{ border: `1px solid ${colors.gray}` }}>
                <p>
                  <strong>{t('enrollment.lead-management.offer-expires-on')}</strong>:{' '}
                  {moment(expiryDate).tz(timezone).format(t('formatters.MMM D, YYYY'))}
                </p>
              </div>
            </div>
          </>
        )}
        {Object.keys(formData).length > 0 &&
          Object.keys(formData).map((childId) => {
            const child = childrenForOffer.find((c) => c.childId == childId);
            const scheduleOffersForChild = formData[childId];

            return (
              <div
                className={classNames('edit-offer-modal', {
                  'hide-accordion-title': !showSummary,
                })}
                key={childId}
              >
                <h5 className="mb-3 px-4">{child?.childFullName}</h5>
                {scheduleOffersForChild.map((sched, index) => {
                  const classHasAvailability = dataCheckAvailability?.checkOfferAvailability
                    ? dataCheckAvailability?.checkOfferAvailability?.find((a) => a.scheduleOfferId === sched.id)
                        ?.hasCapacity
                    : undefined;

                  const subtitle =
                    applicationFlowType === ApplicationFlowType.InquireOfferEnrollment
                      ? sched.careType
                      : sched.program?.name ?? sched.careType;

                  return (
                    <div key={sched.id}>
                      <OfferAccordion
                        key={sched.id}
                        schedule={sched}
                        handleChange={(value, field) => handleChange(value, field, childId, index)}
                        handleDayToggle={(value, weekType) => handleDayToggle(value, weekType, childId, index)}
                        title={''}
                        subtitle={subtitle}
                        isDisabled={false}
                        centers={centerIds ?? []}
                        classHasAvailability={classHasAvailability}
                        summaryMode={showSummary}
                      />
                      {!showSummary && <hr className="mb-5 mt-5" />}
                    </div>
                  );
                })}
              </div>
            );
          })}
        {showSummary && isProgramFlow && (
          <Alert variant={'danger'}>{t('enrollment.lead-management.change-program-modal.message')}</Alert>
        )}

        {showSummary && !isProgramFlow && (
          <>
            <hr className="mb-5 mt-5" />
            <div className="d-flex justify-content-start align-items-center px-4">
              <Checkbox value={resendOfferEmail} onChange={(val) => setResendOfferEmail(val)} />
              <label className="mb-0">Send updated offer to guardian</label>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div className="ml-auto">
          <Button id="centered-modal-secondary-btn" className="mr-3" variant="light" onClick={() => handleCloseModal()}>
            {capitalize(t('spelling.cancel'))}
          </Button>
          {showSummary && (
            <>
              <Button
                id="centered-modal-secondary-btn"
                className="mr-3"
                variant="light"
                onClick={() => setShowSummary(false)}
              >
                {t('enrollment.lead-management.go-back')}
              </Button>
              <Button
                id="centered-modal-primary-btn"
                variant="primary"
                loading={loadingUpdateOffer}
                onClick={() => {
                  handleUpdateOffer();
                }}
              >
                {t('enrollment.lead-management.update-offer')}
              </Button>
            </>
          )}
          {!showSummary && (
            <Button
              id="centered-modal-primary-btn"
              variant="primary"
              loading={loadingCheckAvailability}
              disabled={!isFormValid}
              onClick={() => {
                handleCheckAvailability();
              }}
            >
              {'Check Availability'}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditOfferModal;
