import { QueryHookOptions } from '@apollo/client';
import { allergyFields } from 'gql/allergy/fields';
import { medicalConditionFields } from 'gql/medicalCondition/fields';
import { gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useQuery } from 'shared/apis/core';
import { getStaffMedicalDataSuccess } from 'store/employees/actions';

interface IGetStaffTagOptionsData {
  getEntity: IEntity;
  getStaffTagOptions: ITag[];
}

interface ISearchStaffData {
  searchStaff: {
    totalResults: number;
    data: IStaff[];
  };
}

interface ISearchStaffVariables {
  input: IElasticsearchQuery;
}

export const GET_STAFF = gql`
  query ($id: String!) {
    getStaff(id: $id) {
      id
      firstname
      lastname
      dob
      nickname
      email
      verifiedEmail
      emailIsManaged
      cognitoConfiguredAt
      avatar {
        url
      }
      address {
        address1
        address2
        city
        state
        country
        postalCode
      }
      phoneNumber
      phoneNumberAlt
      entityId
      primaryEmergencyContact {
        firstname
        lastname
        phoneNumber
        phoneNumberType
        relationship
      }
      additionalEmergencyContacts {
        firstname
        lastname
        phoneNumber
        phoneNumberType
        relationship
      }
      availability {
        approved {
          dayOfWeek
          times {
            start
            end
          }
        }
      }
      staffTrainings {
        name
        expiryDate
      }
      tags {
        id
        entityId
        name
        category
      }
      role {
        id
        name
        businessId
        scopeType
        areaLevels {
          roleId
          area
          level
          permission
        }
      }
      roleship {
        roleId
        name
        scopeType
        scopes {
          ... on Center {
            id
            name
            avatar {
              url
            }
          }
          ... on Entity {
            id
            name
          }
        }
      }
      positions {
        id
        positionId
        personId
        positionName
        scopeId
        scopeType
        payRate
        type
      }
      employmentStartDate
      employmentEndDate
      employmentStatus
      reasonForLeaving
      primaryCenterId
      primaryCenter {
        name
        id
        avatar {
          url
        }
      }
    }
  }
`;

export const GET_KISI_SHARE = gql`
  query ($id: ID!, $businessId: ID!) {
    getKisiShare(businessId: $businessId, personId: $id) {
      id
      personId
      kisiEmail
      kisiShareId
      businessId
    }
  }
`;

export const GET_STAFF_TAG_OPTIONS = gql`
  query ($entityId: String!) {
    getEntity(id: $entityId) {
      id
      tags {
        id
        name
        category
      }
    }
  }
`;

export const GET_STAFF_MEDICAL_DATA = gql`
  query ($id: String!) {
    getStaff(id: $id) {
      id
      allergies {
        ${allergyFields}
      }
      medicalConditions {
        ${medicalConditionFields}
      }
    }
  }
`;

export const GET_STAFF_BY_BIRTHDAY_WEEK = gql`
  query ($currentWeekOffset: Int!) {
    getStaffByBirthdayWeek(currentWeekOffset: $currentWeekOffset) {
      id
      firstname
      lastname
      dob
      nickname
      avatar {
        url
      }
      employmentStatus
      roleship {
        scopeType
        scopes {
          ... on Center {
            id
            name
          }
          ... on Entity {
            id
            name
          }
        }
      }
    }
  }
`;

// staff has a lot of fields so we won't default to that being returned
export const SEARCH_STAFF = (fields: string = 'id') => gql`
  query($input: SearchInput!) {
    searchStaff(input: $input) {
      totalResults
      data {
        ${fields}
      }
    }
  }
`;

export const useGetStaffProfileInformation = (id: string) =>
  useQuery<{ getStaff: IStaff }, { id: string }>(GET_STAFF, {
    variables: {
      id,
    },
    skip: id === '',
  });

export const useGetStaffGetKisiShare = (id: string, businessId: string) =>
  useQuery<{ getKisiShare: IKisiShare }, { id: string; businessId: string }>(GET_KISI_SHARE, {
    variables: {
      id,
      businessId,
    },
    skip: id === '',
  });

export const useGetStaffTagOptions = (entityId: string) => {
  const response = useQuery<IGetStaffTagOptionsData, { entityId: string }>(GET_STAFF_TAG_OPTIONS, {
    variables: {
      entityId,
    },
  });

  if (response.data) {
    const tags: ITag[] = response.data.getEntity.tags;
    const staffTags = tags.filter((tag) => tag.category === 'STAFF');

    return {
      ...response,
      data: {
        getStaffTagOptions: staffTags,
      },
    };
  }

  return response;
};

export const useGetStaffMedicalData = (id: string) => {
  const dispatch = useDispatch();

  return useQuery<{ getStaff: IStaff }, { id: string }>(GET_STAFF_MEDICAL_DATA, {
    variables: {
      id,
    },
    skip: id === '',
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(getStaffMedicalDataSuccess(data.getStaff));
    },
  });
};

export const useSearchStaff = (options?: QueryHookOptions<ISearchStaffData, ISearchStaffVariables>, fields?: string) =>
  useQuery<ISearchStaffData, ISearchStaffVariables>(SEARCH_STAFF(fields), options);

export const useGetStaffByBirthdayWeek = (currentWeekOffset: number, skip: boolean = false) =>
  useQuery<{ getStaffByBirthdayWeek: IStaff[] }, { currentWeekOffset?: number }>(GET_STAFF_BY_BIRTHDAY_WEEK, {
    variables: { currentWeekOffset },
    skip,
  });
