import { PermissionDiscriminator } from '../contexts/permissionContext';

class User implements PermissionDiscriminator {
  id: string;
  isInternal: boolean;
  firstname: string;
  lastname: string;
  avatar: IAvatar;
  email: string;
  address: IAddress;
  roleship: IRoleship;
  role: IRole;
  entityId: string;
  employmentStatus: EmploymentStatus;
  myCenters: ICenter[];
  hasPin: boolean;
  primaryCenterId: string;
  onboardingStatus?: OnboardingStatus | null;
  myEntity: IEntity | null; // ony administrators will have an entity object returned

  constructor(user: any) {
    this.id = user.id;
    this.isInternal = user.__typename === 'InternalUser';
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.avatar = user.avatar;
    this.email = user.email;
    this.address = user.address;
    this.roleship = user.roleship;
    this.role = user.role;
    this.entityId = user.entityId;
    this.hasPin = !!user.pin;
    this.employmentStatus = user.employmentStatus;
    this.primaryCenterId = user.primaryCenterId;
    this.onboardingStatus = user.onboardingStatus;
    this.myEntity = user.roleship?.scopeType === 'ENTITY' ? user.roleship.scopes[0] : null;
    this.myCenters = user.roleship?.scopeType === 'CENTER' ? user.roleship.scopes : this.myEntity?.centers ?? [];
  }

  hasBusinessLevelRole(): boolean {
    return this.roleship.scopeType === 'ENTITY';
  }

  /** @deprecated */
  hasPermission(action: string): boolean {
    if (this.isInternal) {
      return true; // internal users have all permissions
    } else if (['Active', 'Pending'].includes(this.employmentStatus) && action === 'STAFF_ONBOARDING') {
      return true; // all active and pending employees have ability to do onboarding actions
    } else if (this.employmentStatus === 'Active') {
      return this.roleship.permissions.some((permission) => permission.action === action);
    } else {
      return false;
    }
  }

  hasAreaPermissionLevel(action: IAreaPermissionLevel): boolean {
    const { area, level, permission } = action;

    if (this.isInternal) {
      return true;
    }
    // @ts-ignore - can remove when we figure out what "ONBOARDING" should be
    else if (['Active', 'Pending'].includes(this.employmentStatus) && area === 'Staff' && permission === 'ONBOARDING') {
      return true;
    } else if (this.employmentStatus === 'Active') {
      return (
        this.role?.areaLevels.some(
          (al) => al.area === area && al.permission === permission && (al.level & level) === level
        ) ?? false
      );
    }

    return false;
  }

  hasActiveEmployment(): boolean {
    if (this.isInternal) {
      return true;
    }

    return this.employmentStatus === 'Active';
  }
}

export default User;
