import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PageWrapper from 'shared/components/PageWrapper';
import BillingTransactionsTab from './Tabs/Transactions/BillingTransactionsTab';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import BillingCyclesTab from './Tabs/Cycles/BillingCyclesTab';
import BillingFeesTab from './Tabs/Fees/BillingFeesTab';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTranslation } from 'react-i18next';
import AccountingPeriodsTab from './Tabs/AccountingPeriods/AccountingPeriodsTab';
import { isRegion } from 'shared/util/region';
import AdjustmentsTab from './Tabs/AdjustmentsTab';
import { CreateButton } from 'shared/components/Buttons';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import ItemizedBillsTab from './Tabs/ItemizedBills/ItemizedBillsTab';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import GLCodes from './Tabs/GLCodes';
import GLCodeProvider, { useGLCodeContext } from './Tabs/GLCodes/Context/GLCodeContext';
import LinkGLCodeWidget from './Tabs/GLCodes/components/GLCodesAssignmentsTab/LinkGLCodeWidget';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AutomatedBatchingTab from './Tabs/AutomatedBatching/AutomatedBatchingTab';
import { DisplaySettingsTab } from './Tabs/DisplaySettingsTab';
import { PaymentBreachFlags } from 'PaymentBreachFlagDisable/PaymentBreachFlag';
import { useUSModeSettings } from 'US/Billing/USMode';
import { useGetCurrentBusiness } from 'shared/hooks/user-context/useGetCurrentBusiness';

type BillingSettingsTab = 'surcharges' | 'cycles' | 'transactions' | 'accounting-period' | 'adjustments' | 'gl-codes';

interface IAdjustmentModalStateShape {
  isOpen: boolean;
  adjustment: IAdjustment | null;
}

interface IRouteProps {}
interface IProps extends RouteComponentProps<{ activeTab: BillingSettingsTab | string }, any, IRouteProps> {}

const BillingSettings: React.FC<IProps> = ({ ...props }) => {
  const { activeTab } = props.match.params;
  const [activeTabKey, setActiveTabKey] = useState<string>(activeTab ?? 'surcharges');
  const { GLCodeTabKey } = useGLCodeContext();
  const user = useSelector((state: RootState) => state.user);
  const [showAdjustmentModal, setShowAdjustmentModal] = useState<IAdjustmentModalStateShape>({
    isOpen: false,
    adjustment: null,
  });
  const [showCreateGLCode, setShowCreateGLCode] = useState(false);
  const [showCreateTransactionType, setShowCreateTransactionType] = useState(false);
  const businessId = useGetCurrentBusiness();
  const history = useHistory();
  const { t } = useTranslation();
  const { k2AutomaticBatching, k2GlCodeManagement, usBillingNewBillExperience } = useFlags();
  const { usMode, loading: loadingUSMode } = useUSModeSettings(businessId);

  const hasEditDisplaySettingsPermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Create,
  });

  const canEditDisplaySettings = useMemo(() => {
    if (isRegion('AU')) return hasEditDisplaySettingsPermission;
    return !loadingUSMode && !usMode && hasEditDisplaySettingsPermission;
  }, [hasEditDisplaySettingsPermission, loadingUSMode, usMode]);

  const canViewSurcharges = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Surcharges,
    level: RoleLevelType.Read,
  });
  const canViewBillingCycleTemplates = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.BillingCycleTemplates,
    level: RoleLevelType.Read,
  });
  const canViewTransactionTypes = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.TransactionTypes,
    level: RoleLevelType.Read,
  });
  const canViewItemizedBills = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Payments,
    level: RoleLevelType.Read,
  });
  const canViewAccountingPeriods = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const canViewAdjustments = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Adjustments,
    level: RoleLevelType.Read,
  });
  const canViewGLCodes = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Billing,
    level: RoleLevelType.Read,
  });
  const canViewAutomatedBatching = canViewSurcharges;

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const [itemizedBillType, setItemizedBillType] = useState<string>();
  const [itemizedBillTypeSaving, setItemizedBillTypeSaving] = useState<boolean>();
  const [selectedCenters, setSelectedCenters] = useState<ICenterBillingSettings[]>([]);

  React.useEffect(() => {
    setActiveTabKey(activeTab ?? 'surcharges');
  }, [activeTab]);

  return (
    <PageWrapper
      pageTitle="Billing Settings"
      applyPadding={true}
      buttonComponent={
        <div>
          {activeTabKey === 'adjustments' && (
            <HasRoleAreaLevel
              action={{ area: AreaType.Billing, permission: PermissionType.Adjustments, level: RoleLevelType.Create }}
            >
              <CreateButton onClick={() => setShowAdjustmentModal({ isOpen: true, adjustment: null })}>
                Add Adjustment
              </CreateButton>
            </HasRoleAreaLevel>
          )}
          {activeTabKey === 'itemized-bills' && (
            <HasRoleAreaLevel
              action={{ area: AreaType.Billing, permission: PermissionType.Payments, level: RoleLevelType.Edit }}
            >
              <div className="d-flex flex-row align-items-center">
                <p className="ml-auto mr-8 mb-0">
                  {selectedCenters?.length} {t('spelling.center')}
                  {selectedCenters?.length === 1 ? '' : 's'} selected
                </p>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    disabled={selectedCenters?.length === 0 || itemizedBillTypeSaving}
                  >
                    {t('itemized-bills.assign-view-button')}
                    <FontAwesomeIcon className="ml-4" icon={faAngleDown} size="1x" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      key={'detailed'}
                      onClick={() => {
                        setItemizedBillType('detailed');
                      }}
                    >
                      {t('itemized-bills.detailed-view-button')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      key={'summary'}
                      onClick={() => {
                        setItemizedBillType('summary');
                      }}
                    >
                      {t('itemized-bills.summary-view-button')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      key={'summary-grouped'}
                      onClick={() => {
                        setItemizedBillType('summary-grouped');
                      }}
                    >
                      {t('itemized-bills.summary-grouped-view-button')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </HasRoleAreaLevel>
          )}
          {activeTab === 'transactions' && (
            <HasRoleAreaLevel
              action={{ area: AreaType.Business, permission: PermissionType.Base, level: RoleLevelType.Create }}
            >
              <CreateButton onClick={() => setShowCreateTransactionType(true)}>Add Transaction Type</CreateButton>
            </HasRoleAreaLevel>
          )}
          {activeTabKey === 'gl-codes' && GLCodeTabKey === 'list' && (
            <HasRoleAreaLevel
              action={{ area: AreaType.Business, permission: PermissionType.Base, level: RoleLevelType.Create }}
            >
              <CreateButton onClick={() => setShowCreateGLCode(true)}>Add GL Code</CreateButton>
            </HasRoleAreaLevel>
          )}
          {activeTabKey === 'gl-codes' && GLCodeTabKey === 'assignments' && (
            <HasRoleAreaLevel
              action={{ area: AreaType.Business, permission: PermissionType.Base, level: RoleLevelType.Create }}
            >
              <LinkGLCodeWidget />
            </HasRoleAreaLevel>
          )}
        </div>
      }
    >
      {user?.isInternal && (
        <CenterSelectBanner
          pageName={`billing settings (and for its ${fieldLabels.center.toLowerCase()}s)`}
          showCenterSelect={false}
        />
      )}
      <Container fluid>
        <Tabs
          activeKey={activeTabKey}
          id="billing-settings-tab-group"
          onSelect={(tab) => {
            setActiveTabKey(tab ?? 'surcharges');
            history.push(`/billing/settings/${tab}`);
          }}
        >
          {canViewSurcharges && (
            <Tab eventKey="surcharges" title="Surcharges">
              <BillingFeesTab />
            </Tab>
          )}
          {canViewBillingCycleTemplates && (
            <Tab eventKey="cycles" title="Cycles">
              <BillingCyclesTab />
            </Tab>
          )}
          {canViewTransactionTypes && (
            <Tab eventKey="transactions" title="Transactions">
              <BillingTransactionsTab
                canViewBusinessLevelTransactionTypes={(user?.isInternal || user?.hasBusinessLevelRole()) ?? false}
                showCreateModal={showCreateTransactionType}
                dismissCreateModal={() => {
                  setShowCreateTransactionType(false);
                }}
              />
            </Tab>
          )}
          {isRegion('US') && canViewItemizedBills && !usBillingNewBillExperience && (
            <Tab eventKey="itemized-bills" title={t('itemized-bills.tab-name')}>
              <ItemizedBillsTab
                assignedBillView={itemizedBillType}
                loading={itemizedBillTypeSaving}
                onCentersSelected={setSelectedCenters}
                onSaving={(value) => setItemizedBillTypeSaving(value)}
              />
            </Tab>
          )}
          {canViewAccountingPeriods && (
            <Tab eventKey="accounting-period" title={t('billing.accounting-period.accounting-periods')}>
              <AccountingPeriodsTab />
            </Tab>
          )}
          {isRegion('US') && canViewAdjustments && (
            <Tab eventKey="adjustments" title="Adjustments">
              <AdjustmentsTab
                adjustmentModalState={showAdjustmentModal}
                onEditAdjustment={(adjustment) => setShowAdjustmentModal({ isOpen: true, adjustment })}
                dismissAdjustmentModal={() => setShowAdjustmentModal({ isOpen: false, adjustment: null })}
              />
            </Tab>
          )}
          {canViewGLCodes && k2GlCodeManagement && (
            <Tab eventKey="gl-codes" title="GL Codes">
              <GLCodes
                showCreateModal={showCreateGLCode}
                dismissCreateModal={() => {
                  setShowCreateGLCode(false);
                }}
              />
            </Tab>
          )}
          {canViewAutomatedBatching && k2AutomaticBatching && (
            <Tab eventKey="automated-batching" title="Automated Batching">
              <AutomatedBatchingTab />
            </Tab>
          )}
          {canEditDisplaySettings && (
            <Tab eventKey="display-settings" title="Transaction Display">
              <DisplaySettingsTab />
            </Tab>
          )}
          {user?.isInternal && (
            <Tab eventKey="deprecate-payment-flagging" title="Deprecate Payment Flagging">
              <PaymentBreachFlags></PaymentBreachFlags>
            </Tab>
          )}
        </Tabs>
      </Container>
    </PageWrapper>
  );
};

export default (props: IProps) => (
  <GLCodeProvider>
    <BillingSettings {...props} />
  </GLCodeProvider>
);
