import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';

interface IGetCenterStatesInUseData {
  getCenterStatesUsedAcrossEntity: string[];
}

export const GET_STATES_USED_FOR_CENTERS = gql`
  query {
    getCenterStatesUsedAcrossEntity
  }
`;

export const useGetCenterStatesInUse = () =>
  useQuery<IGetCenterStatesInUseData, void>(GET_STATES_USED_FOR_CENTERS, {
    fetchPolicy: 'cache-first',
  });
