import { useCallback, useEffect, useState } from 'react';
import PubNub from 'pubnub';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useDatatableState from 'shared/hooks/useDatatableState2';
import {
  ChannelType,
  CommsCenterSetting,
  GetAccountChannelsQuery,
  useGetAccountChannelQuery,
  useGetAccountChannelsQuery,
  useGetChatAuthQuery,
  useGetChatAuthTokenQuery,
  useGetCommsAllCenterSettingsQuery,
} from 'generated/graphql';
import { debounce } from 'lodash';
import config from 'config';
import { showToast } from 'shared/components/Toast';
import { PubNubProvider } from 'pubnub-react';
import Messenger from 'shared/components/Messenger/Messenger';
import MessagingOff from 'shared/components/Messenger/MessagingOff';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import moment from 'moment';

export type Channel = NonNullable<GetAccountChannelsQuery['getAccountChannels']>['data'][number];

interface IProps {
  hasAccountReadPermission: boolean;
  hasAccountWritePermission: boolean;
  hasAccountDeletePermission: boolean;
  accountId: string;
}

const MessagingTab: React.FC<IProps> = ({
  hasAccountReadPermission,
  hasAccountWritePermission,
  hasAccountDeletePermission,
  accountId,
}) => {
  const { t } = useTranslation(['comms', 'translation']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const centers = useSelector((state: RootState) => state.centers.all) ?? '';

  const [channels, setChannels] = useState<Channel[]>();
  const [selectedCenters, setSelectedCenters] = useState<ITableFilterOption[]>([]);
  const [tableState, tableFunctions] = useDatatableState();
  const [searchTerm, setSearchTerm] = useState('');
  const [authAccountCenters, setAuthAccountCenters] = useState<CommsCenterSetting[] | undefined | null>([]);

  const [authToken, setAuthToken] = useState<string | null | undefined>();
  const [authTokenExpiry, setAuthExpiryToken] = useState<string | null | undefined>();
  const [publishKey, setPublishKey] = useState<string | null | undefined>();
  const [subscribeKey, setSubscribeKey] = useState<string | null | undefined>();
  const [pollInterval, setPollInterval] = useState(1000 * 30); // initially poll every 30 seconds

  const hasCenterEditPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const {
    data: getAuthChannels,
    loading: getAuthChannelsLoading,
    error: getAuthChannelsError,
  } = useGetCommsAllCenterSettingsQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      businessId: businessId ?? '',
      pageNumber: 1,
      pageSize: 2000,
    },
  });

  const {
    data: getChatAuthTokenData,
    loading: loadingAuthToken,
    error: getAuthTokenError,
    refetch: refetchAuthToken,
  } = useGetChatAuthTokenQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      businessId: businessId ?? '',
    },
  });

  const {
    data: getAccountChannels,
    loading: getAccountChannelLoading,
    error: getAccountChannelError,
    refetch: refetchAccountChannel,
  } = useGetAccountChannelQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    pollInterval: pollInterval,
    variables: {
      businessId: businessId ?? '',
      accountId: accountId,
    },
  });

  const onSearchTermChange = useCallback(
    debounce((newSearchTerm: string) => {
      setSearchTerm(newSearchTerm);
      if (tableState.activePage !== 1) tableFunctions.changePage(1, tableState.pageSize);
    }, 500),
    []
  );

  const onCentersSelect = useCallback(
    (centerFilter: ITableFilterOption[]) => {
      if (tableState.activePage !== 1) tableFunctions.changePage(1, tableState.pageSize);
      setSelectedCenters(centerFilter);
    },
    [tableFunctions, setSelectedCenters]
  );

  const handleRefetch = useCallback(() => {
    refetchAccountChannel({
      businessId: businessId ?? '',
      accountId: accountId,
    });
  }, [tableFunctions, refetchAccountChannel]);

  const onFocusFunction = () => {
    setPollInterval(1000 * 30); // poll every 30 seconds when looking at page
  };

  const onBlurFunction = () => {
    setPollInterval(1000 * 60 * 5); // poll every 10 minutes when not looking at page
  };

  useEffect(() => {
    onFocusFunction();
    window.addEventListener('focus', onFocusFunction);
    window.addEventListener('blur', onBlurFunction);

    return () => {
      onBlurFunction();
      window.removeEventListener('focus', onFocusFunction);
      window.removeEventListener('blur', onBlurFunction);
    };
  }, []);

  useEffect(() => {
    if (getAccountChannelError)
      showToast(t('comms:getChannels.error', { channelType: 'account', plural: 's' }), 'error');
    setChannels(
      getAccountChannels && getAccountChannels?.getAccountChannel ? [getAccountChannels?.getAccountChannel] : []
    );
  }, [getAccountChannelError, getAccountChannels]);

  useEffect(() => {
    setAuthAccountCenters(getAuthChannels?.getCommsAllCenterSettings?.data.filter((c) => c.usesAccountChannels));
  }, [getAuthChannels, getAuthChannelsLoading]);

  useEffect(() => {
    if (getAuthTokenError) showToast(`Unable to get Messaging ${t('translation:spelling.authorization')}`, 'error');
    setAuthToken(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.authToken : undefined);
    setAuthExpiryToken(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.expiryDateTimeOffset : undefined);
    setPublishKey(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.publishKey : undefined);
    setSubscribeKey(getChatAuthTokenData ? getChatAuthTokenData.getChatAuthToken?.subscribeKey : undefined);
  }, [getAuthTokenError, getChatAuthTokenData]);

  useEffect(() => {
    // refresh token 5 minutes before it expires
    const expiry = moment(authTokenExpiry);
    const expiryminus5mins = moment(authTokenExpiry).subtract(5, 'minutes');
    const diffInMilisec = expiryminus5mins.diff(moment()).valueOf();
    setTimeout(refetchAuthToken, diffInMilisec);
  }, [authTokenExpiry]);

  const pubnub = new PubNub({
    publishKey: publishKey ?? config.pubNubConfig.publishKey,
    subscribeKey: subscribeKey ?? config.pubNubConfig.subscribeKey,
    uuid: user?.id ?? businessId ?? 'DefaultUser',
    restore: true,
    heartbeatInterval: 500,
    logVerbosity:
      businessId === 'aca26847-7862-4f3f-ad1c-f393ed8056d3' || businessId === 'ecee07be-0be7-48a3-a570-4930bec79cd5',
  });
  pubnub.setToken(authToken ?? '');
  pubnub.unsubscribeAll();

  return (
    <>
      {!(publishKey || subscribeKey) && !loadingAuthToken && (
        <MessagingOff hasCenterEditPermission={hasCenterEditPermission} title={t('comms:messaging-off.title.other')} />
      )}
      {publishKey && subscribeKey && !loadingAuthToken && (
        <Messenger
          pubnub={pubnub}
          authCenters={authAccountCenters?.map((c) => c.centerId ?? '') ?? []}
          initialChannels={channels ?? []}
          loadingChannels={getAccountChannelLoading || getAuthChannelsLoading}
          channelsType={ChannelType.Account}
          hasWritePermission={hasAccountWritePermission}
          hasDeletePermission={hasAccountDeletePermission}
          accountsPage={true}
          dataSize={1}
          pageSize={tableState.pageSize}
          activePage={tableState.activePage}
          selectedCenters={selectedCenters}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          onSearchTermChange={onSearchTermChange}
          setSelectedCenters={onCentersSelect}
          refetchChannels={handleRefetch}
        />
      )}
    </>
  );
};

export default MessagingTab;
