import React, { useCallback, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { sortBy } from 'lodash';
import Select from 'shared/components/Select';
import CcsAccountManagement from './components/CcsAccountManagement/CcsAccountManagement';
import CCSDebtManagement from './components/CCSDebtManagement';
import CCSAltArrangements from './components/CCSAltArrangement';
import CcssServicePersonnelTab from './components/CcssServicePersonnelTab';
import CcssProviderPersonnelTab from './components/CcssProviderPersonnelTab';
import { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import ProviderMessagesTab from 'pages/CCSSMessages/ProviderMessagesTab/ProviderMessagesTab';

type CcsTabs =
  | 'provider-management'
  | 'debt-management'
  | 'debt-management'
  | 'service-personnel'
  | 'provider-personnel';

interface IRouteParams {
  activeTab: CcsTabs;
}

interface IProps extends RouteComponentProps<IRouteParams, any, {}> {}

const CcsAccount: React.FC<IProps> = ({ ...props }) => {
  const { activeTab } = props.match.params;
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const [activeTabKey, setActiveTabKey] = useState<CcsTabs>(activeTab ?? 'provider-management');
  const { data: businessesData } = useGetAllowedEntities();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const [selectedBusiness, setSelectedBusiness] = useState<ITableFilterOption | null>(null);

  const renderPageButton = useCallback((): JSX.Element | null => {
    switch (activeTab) {
      case 'service-personnel':
      case 'provider-personnel':
        return (
          <CreateButton onClick={() => {}} className="ml-auto">
            Add Personnel
          </CreateButton>
        );
      default:
        return null;
    }
  }, [activeTab]);

  const renderPageButtonMobile = useCallback((): JSX.Element | null => {
    switch (activeTab) {
      case 'service-personnel':
      case 'provider-personnel':
        return <CirclePlusButton variant="primary" className="mt-4 mb-4" onClick={() => {}} />;
      default:
        return null;
    }
  }, [activeTab]);

  return (
    <ProfilePageWrapper
      title="CCS Management"
      loading={false}
      showAvatar={false}
      buttonComponent={renderPageButton()}
      mobileButtonComponent={renderPageButtonMobile()}
    >
      <div className="mx-auto">
        <Tabs
          id="ccs-tab-group"
          activeKey={activeTabKey}
          unmountOnExit
          onSelect={(tab) => {
            setActiveTabKey(tab as CcsTabs);
            history.push(`/ccs/${tab}`);
          }}
        >
          <Tab eventKey="provider-management" title="Provider Management">
            {businesses && businesses.length > 0 && <CcsAccountManagement />}
          </Tab>
          <Tab eventKey="provider-messages" title="CCS Provider Messages">
            {businesses && businesses.length > 0 && (
              <ProviderMessagesTab
                selectedBusiness={selectedBusiness || { label: businesses[0].name, value: businesses[0].id }}
              />
            )}
          </Tab>
          <Tab eventKey="debt-management" title="Debt Management">
            {businesses && businesses.length > 0 && <CCSDebtManagement />}
          </Tab>
          <Tab eventKey="alternate-arrangement" title="Alternate Arrangements">
            {businesses && businesses.length > 0 && <CCSAltArrangements />}
          </Tab>
          <Tab eventKey="provider-personnel" title="Provider Personnel">
            {businesses && businesses.length > 0 && <CcssProviderPersonnelTab business={businesses[0]} />}
          </Tab>
          <Tab eventKey="service-personnel" title="Service Personnel">
            {businesses && businesses.length > 0 && <CcssServicePersonnelTab business={businesses[0]} />}
          </Tab>
        </Tabs>
      </div>
    </ProfilePageWrapper>
  );
};

export default CcsAccount;
