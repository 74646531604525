import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'shared/components/Buttons';
import { Col as Column } from 'shared/components/Layout';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  loadingProfile: boolean;
  performingMutation: boolean;
  onDeactivateClick: () => void; // dispatch an action to deactivate a center
  onReactivateClick: () => void; // dispatch an action to reactivate a center
  onDeleteClick: () => void; // dispatch an action to delete a center
  centerIsDeactivated: boolean;
  centerIsDeleted: boolean;
}

const DeactivateCenterCard: React.FC<IProps> = ({
  loadingProfile,
  performingMutation,
  onDeactivateClick,
  onReactivateClick,
  onDeleteClick,
  centerIsDeactivated,
  centerIsDeleted,
  ...props
}) => {
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const deactivationText = (
    <>
      <strong className="mb-4">Need to deactivate this {fieldLabels.center.toLowerCase()}?</strong>
      <span className="d-block mb-4">
        Deactivating this {fieldLabels.center.toLowerCase()} will terminate all existing relationships and functionality
        to this {fieldLabels.center.toLowerCase()}.
      </span>
    </>
  );
  const reactivationText = (
    <>
      <strong className="mb-4">Want to reactivate this {fieldLabels.center.toLowerCase()}?</strong>
      <span className="d-block mb-4">
        Reactivating this {fieldLabels.center.toLowerCase()} will restore this {fieldLabels.center.toLowerCase()}'s
        functionality, but all previous relationships will have to be reestablished.
      </span>
      <span className="d-block mb-4">
        Or you can permanently delete this {fieldLabels.center.toLowerCase()} - deleting all functionality and data
        entered.
      </span>
    </>
  );
  return (
    <Card className="mx-0">
      <Card.Body>
        <Column className="d-flex flex-column px-0" align="start">
          {loadingProfile ? (
            <div className="d-block w-100">
              <LoadingLines number={2} />
            </div>
          ) : (
            <>
              {centerIsDeactivated ? reactivationText : deactivationText}
              <div className="align-self-end">
                {centerIsDeactivated ? (
                  <Button
                    id="reactivate-center-btn"
                    variant="outline-success"
                    loading={performingMutation}
                    onClick={onReactivateClick}
                    disabled={!centerIsDeactivated || centerIsDeleted}
                  >
                    Reactivate
                  </Button>
                ) : (
                  <Button
                    id="deactivate-center-btn"
                    variant="outline-danger"
                    loading={performingMutation}
                    onClick={onDeactivateClick}
                    disabled={centerIsDeactivated}
                  >
                    Deactivate
                  </Button>
                )}
                <Button
                  id="delete-center-btn"
                  variant="danger"
                  loading={performingMutation}
                  disabled={!centerIsDeactivated || centerIsDeleted}
                  onClick={onDeleteClick}
                  className="ml-4"
                >
                  Delete
                </Button>
              </div>
            </>
          )}
        </Column>
      </Card.Body>
    </Card>
  );
};

export default DeactivateCenterCard;
