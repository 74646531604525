import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';

interface IUpdateSessionReportSyncData {
  updateSessionReportSync: boolean;
}

interface IUpdateSessionReportSyncVariables {
  input: {
    id: string;
    reasonForLateChange: null | string;
    businessId: string;
  };
}

export const UPDATE_SESSION_REPORT_SYNC = gql`
  mutation ($input: SessionReportSyncUpdateInput!) {
    updateSessionReportSync(input: $input)
  }
`;

export const useUpdateSessionReportSync = (
  options?: MutationHookOptions<IUpdateSessionReportSyncData, IUpdateSessionReportSyncVariables>
) => useMutation<IUpdateSessionReportSyncData, IUpdateSessionReportSyncVariables>(UPDATE_SESSION_REPORT_SYNC, options);
