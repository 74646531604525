import * as Mui from '@mui/material';
import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Card from 'shared/components/Card/Card';
import EzChildTrackFamiliesForm from './EzChildTrackFamiliesForm';
import BusinessAndCenterSelectBanner from 'shared/components/CenterSelectBanner/CenterSelectBanner';
import EzChildTrackContractsForm from './EzChildTrackContractsForm';

interface IProps {
  businessId: string;
}

const EzChildTrackMigration = ({ businessId }: IProps) => {
  return (
    <Mui.Stack>
      <Card header="Select a Business">
        <BusinessAndCenterSelectBanner
          pageName="EzChildTrack Migration"
          showCenterSelect={false}
          removeDefaultBackground
        />
      </Card>
      <Card header="STEP 1: Families">
        <EzChildTrackFamiliesForm businessId={businessId} />
      </Card>
      <Card header="STEP 2: Contracts">
        <EzChildTrackContractsForm businessId={businessId} />
      </Card>
    </Mui.Stack>
  );
};

export default EzChildTrackMigration;
