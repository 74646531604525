import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { cpavServiceFields } from './fields';

const GET_CURRENT_CPAV_FOR_CENTRE_SERVICE = (fields: string = cpavServiceFields) => gql`
  query($input: GetCurrentCpavForCentreServiceInput!) {
    getCurrentCpavForCentreService(input: $input) {
      ${fields}
    }
  }
`;

interface IGetCurrentCpavForCentreServiceData {
  getCurrentCpavForCentreService: IServiceCpav;
}

interface IGetCurrentCpavForCentreServiceVariables {
  input: IGetCurrentCpavForCentreServiceInput;
}

export const useGetCurrentCpavForCentreService = (
  options?: QueryHookOptions<IGetCurrentCpavForCentreServiceData, IGetCurrentCpavForCentreServiceVariables>,
  fields?: string
) =>
  useQuery<IGetCurrentCpavForCentreServiceData, IGetCurrentCpavForCentreServiceVariables>(
    GET_CURRENT_CPAV_FOR_CENTRE_SERVICE(fields),
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );
