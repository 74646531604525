export const ccssServiceMessageFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    id
    type
    source
    sourceId
    subject
    body
    created
    viewedStatus
    messageType
    centerName
    centerId
  }
`;

export const ccssProviderMessageFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    id
    type
    source
    sourceId
    subject
    body
    created
    viewedStatus
    messageType
    businessId
  }
`;
export const ccssCorrespondenceFile = `
  content
`;

export const ccssMsgUpdateFields = `
  id
  type
  source
  subject
  body
  created
  viewedStatus
  messageType
`;

export const ccssEventFields = `
  pageNumber
  pageSize
  totalRecords
  data { 
    id
    eventId
    centerId
    centerName
    description
    endDate
    startDate
    recipientType
    subType
    type
    specialAbsences {
      available
      childCrn
      childFirstName
      childMiddleName
      childLastName
      consumed
      enrolmentId
    }
    specialConditions {
      endDate
      startDate
      type
      value
    }
  }
`;
