import React from 'react';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'shared/components/Layout';
import Button from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';

interface IProps {
  notification: INotificationV2;
  showMute: boolean;
  handleMarkNotification: (notification: INotificationV2, markAs: NotificationV2MarkAs) => Promise<void>;
  handleNotifSubscription: (notification: INotificationV2) => void;
}

const NotificationDetails: React.FC<IProps> = ({
  notification,
  showMute,
  handleMarkNotification,
  handleNotifSubscription,
}) => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Row>
        <Col>
          <p className="text-wrap">{notification.description}</p>
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          {showMute && (
            <Button
              variant="outline-danger mr-2"
              onClick={() => {
                handleNotifSubscription(notification);
              }}
            >
              {t('notification-v2.buttons.mute')}
            </Button>
          )}
          <Button
            variant="outline-primary mr-2"
            onClick={() => handleMarkNotification(notification, notification.archived ? 'unarchive' : 'archived')}
          >
            {notification.archived ? t('notification-v2.buttons.unarchive') : t('notification-v2.buttons.archive')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleMarkNotification(notification, 'unread');
            }}
            disabled={!notification.read}
          >
            {t('notification-v2.buttons.mark-as-unread')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotificationDetails;
