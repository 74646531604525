import { childFields } from 'gql/child/fields';

export const photoFields = `
  id
  takenBy{
    id
    firstname
    lastname
  }
  classId
  centerId
  photoKey
  note
  signedurl
  thumbnailKeySignedUrl
  signedDownloadUrl
  createdAt
  takenAt
  isVideo
  children{
    id
    childId
    child{
      id
      firstname
      lastname
    }
  }
  evaluator{
    firstname
    lastname
    id
  }
`;

export const searchPhotoFields = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${photoFields}
  }

`;

export const photoCountFields = `
  class {
    id
    name
  }
  dailyPhotoCounts {
    date
    photoCount
    childCount
    childPhotoCounts {
      childId
      photoCount
    }
  }
`;

export const photoStatisticsFields = `
  centerId
  takenAfter
  takenBefore
  approvedCount
  waitingForApprovalCount
  declinedCount
`;
