import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Auth from '@aws-amplify/auth';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { logout, updateSessionToken } from 'pages/Login/duck/actions';
import useRefreshToken from 'shared/hooks/useRefreshToken';

const ONE_MINUTE = 60 * 1000;
const FIVE_MINUTES = ONE_MINUTE * 5;

interface IProps {}

/**
 * HOC that provides functionality on handling JWT refreshes in the background
 * Notes:
 * - refresh if tokens have the same date (last issued and refresh)
 * - expire if tokens are different dates (ex: expire if a session has been left overnight)
 */
const JWTRefresher: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { refreshToken } = useRefreshToken();

  // token has elapsed one day, remove the current session and navigate to login
  const expireSession = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const checkToken = useCallback(async () => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const tokenIssuedAt = accessToken.getIssuedAt();
    const tokenExpiresAt = accessToken.getExpiration();
    const issuedAtMoment = moment(tokenIssuedAt * 1000);
    const expiresAtMoment = moment(tokenExpiresAt * 1000);

    if (!issuedAtMoment.isSame(moment(), 'date')) {
      expireSession();
    } else if (expiresAtMoment.diff(moment(), 'minutes') <= 5) {
      refreshToken();
    }
  }, [expireSession, refreshToken]);

  useEffect(() => {
    const checkTokenInterval = setInterval(() => {
      checkToken();
    }, FIVE_MINUTES);

    return () => clearInterval(checkTokenInterval);
  }, [checkToken]);

  return <>{props.children}</>;
};

export default JWTRefresher;
