interface IProps {
  targetType: string;
  amountSelected: number;
}
const TargetsHeader: React.FC<IProps> = ({ targetType, amountSelected }: IProps) => {
  return (
    <div className="d-flex flex-row align-items-center">
      {targetType}
      <p className="ml-auto mb-0">
        {`${amountSelected} ${targetType === 'Account' ? 'Account(s)' : 'Child(ren)'} selected`}
      </p>
    </div>
  );
};

export default TargetsHeader;
