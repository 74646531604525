import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useK2Mutation } from 'shared/apis/k2Api';
import { ApolloError } from '@apollo/client';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IMigrateBookingPatterns {
  migrateQikKidsBookingPatterns: number;
}

interface IMigrateBalances {
  migrateQikKidsBalances: number;
}

interface IMigrateQikKidsFamilies {
  migrateQikKidsFamilies: number;
}
interface IMigrateProcareFamilies {
  migrateProcareFamilies: number;
}
interface IMigrateQikKidsCCSS {
  migrateQikKidsCcss: number;
}

interface IMigrateQikKidsHistory {
  migrateQikKidsHistory: number;
}

interface IAdjustZeroBalance {
  adjustZeroBalance: number;
}

interface IFinalizeMigration {
  finalizeMigration: number;
}

interface IAdjustSubsidyZeroBalance {
  adjustSubsidyZeroBalance: number;
}

export const MIGRATE_QIKKIDS_BOOKING_PATTERNS = gql`
  mutation (
    $businessId: UUID!
    $centerId: UUID!
    $careTypes: UploadInput!
    $bookingPatterns: UploadInput!
    $startDate: DateTime!
    $dryRun: Boolean!
  ) {
    migrateQikKidsBookingPatterns(
      businessId: $businessId
      centerId: $centerId
      careTypes: $careTypes
      bookingPatterns: $bookingPatterns
      startDate: $startDate
      dryRun: $dryRun
    )
  }
`;

export const MIGRATE_QIKKIDS_BALANCES = gql`
  mutation (
    $businessId: UUID!
    $centerId: UUID!
    $careTypes: UploadInput!
    $balances: UploadInput!
    $date: DateTime!
    $dryRun: Boolean!
  ) {
    migrateQikKidsBalances(
      businessId: $businessId
      centerId: $centerId
      careTypes: $careTypes
      balances: $balances
      date: $date
      dryRun: $dryRun
    )
  }
`;

export const MIGRATE_QIKKIDS_FAMILIES = gql`
  mutation (
    $businessId: UUID!
    $centerId: UUID!
    $careTypes: UploadInput!
    $children: UploadInput!
    $contacts: UploadInput!
    $authorizations: UploadInput!
    $dryRun: Boolean!
  ) {
    migrateQikKidsFamilies(
      businessId: $businessId
      centerId: $centerId
      careTypes: $careTypes
      children: $children
      contacts: $contacts
      authorizations: $authorizations
      dryRun: $dryRun
    )
  }
`;

export const MIGRATE_PROCARE_FAMILIES = gql`
  mutation (
    $businessId: UUID!
    $centerId: UUID!
    $children: UploadInput!
    $contacts: UploadInput!
    $authorizations: UploadInput!
    $dryRun: Boolean!
  ) {
    migrateProcareFamilies(
      businessId: $businessId
      centerId: $centerId
      children: $children
      contacts: $contacts
      authorizations: $authorizations
      dryRun: $dryRun
    )
  }
`;
export const MIGRATE_QIKKIDS_CCSS = gql`
  mutation (
    $businessId: UUID!
    $centerId: UUID!
    $careTypes: UploadInput!
    $certificates: UploadInput!
    $determinations: UploadInput!
    $enrolments: UploadInput!
    $dryRun: Boolean!
  ) {
    migrateQikKidsCcss(
      businessId: $businessId
      centerId: $centerId
      careTypes: $careTypes
      certificates: $certificates
      determinations: $determinations
      enrolments: $enrolments
      dryRun: $dryRun
    )
  }
`;

export const MIGRATE_QIKKIDS_HISTORY = gql`
  mutation (
    $businessId: UUID!
    $centerId: UUID!
    $careTypes: UploadInput!
    $futureSessions: UploadInput!
    $sessions: UploadInput!
    $transactions: UploadInput!
    $zeroBalanceDate: DateTime
    $dryRun: Boolean!
  ) {
    migrateQikKidsHistory(
      businessId: $businessId
      centerId: $centerId
      careTypes: $careTypes
      futureSessions: $futureSessions
      sessions: $sessions
      transactions: $transactions
      zeroBalanceDate: $zeroBalanceDate
      dryRun: $dryRun
    )
  }
`;

export const ADJUST_ZERO_BALANCE = gql`
  mutation ($businessId: UUID!, $centerId: UUID!, $date: DateTime!) {
    adjustZeroBalance(businessId: $businessId, centerId: $centerId, date: $date)
  }
`;

export const FINALIZE_MIGRATION = gql`
  mutation ($businessId: UUID!, $centerId: UUID!, $date: DateTime!) {
    finalizeMigration(businessId: $businessId, centerId: $centerId, date: $date)
  }
`;

export const ADJUST_SUBSIDY_ZERO_BALANCE = gql`
  mutation ($businessId: UUID!, $centerId: UUID!, $date: DateTime!) {
    adjustSubsidyZeroBalance(businessId: $businessId, centerId: $centerId, date: $date)
  }
`;

const onError = (error: ApolloError) => {
  showToast(getApolloErrorMessage(error), 'error');
};

export const useMigrateQikKidsBookingPatterns = (
  options?: MutationHookOptions<IMigrateBookingPatterns, IQikKidsBookingPatternsVariables>
) => {
  return useK2Mutation<IMigrateBookingPatterns, IQikKidsBookingPatternsVariables>(MIGRATE_QIKKIDS_BOOKING_PATTERNS, {
    onError,
    ...options,
  });
};

export const useMigrateQikKidsBalances = (
  options?: MutationHookOptions<IMigrateBalances, IQikKidsBalancesVariables>
) => {
  return useK2Mutation<IMigrateBalances, IQikKidsBalancesVariables>(MIGRATE_QIKKIDS_BALANCES, { onError, ...options });
};

export const useMigrateQikKidsFamilies = (
  options?: MutationHookOptions<IMigrateQikKidsFamilies, IQikKidsFamiliesVariables>
) => {
  return useK2Mutation<IMigrateQikKidsFamilies, IQikKidsFamiliesVariables>(MIGRATE_QIKKIDS_FAMILIES, {
    onError,
    ...options,
  });
};

export const useMigrateQikKidsCCSS = (options?: MutationHookOptions<IMigrateQikKidsCCSS, IQikKidsCCSSVariables>) => {
  return useK2Mutation<IMigrateQikKidsCCSS, IQikKidsCCSSVariables>(MIGRATE_QIKKIDS_CCSS, { onError, ...options });
};
export const useMigrateProcareFamilies = (
  options?: MutationHookOptions<IMigrateProcareFamilies, IProcareFamiliesVariables>
) => {
  return useK2Mutation<IMigrateProcareFamilies, IProcareFamiliesVariables>(MIGRATE_PROCARE_FAMILIES, {
    onError,
    ...options,
  });
};

export const useMigrateQikKidsHistory = (
  options?: MutationHookOptions<IMigrateQikKidsHistory, IQikKidsHistoryVariables>
) => {
  return useK2Mutation<IMigrateQikKidsHistory, IQikKidsHistoryVariables>(MIGRATE_QIKKIDS_HISTORY, {
    onError,
    ...options,
  });
};

export const useFinalizeMigration = (
  options?: MutationHookOptions<IFinalizeMigration, IAdjustZeroBalanceVariables>
) => {
  return useK2Mutation<IFinalizeMigration, IFinalizeMigrationVariables>(FINALIZE_MIGRATION, { onError, ...options });
};

export const useAdjustZeroBalance = (
  options?: MutationHookOptions<IAdjustZeroBalance, IAdjustZeroBalanceVariables>
) => {
  return useK2Mutation<IAdjustZeroBalance, IAdjustZeroBalanceVariables>(ADJUST_ZERO_BALANCE, { onError, ...options });
};

export const useAdjustSubsidyZeroBalance = (
  options?: MutationHookOptions<IAdjustSubsidyZeroBalance, IAdjustSubsidyZeroBalanceVariables>
) => {
  return useK2Mutation<IAdjustSubsidyZeroBalance, IAdjustSubsidyZeroBalanceVariables>(ADJUST_SUBSIDY_ZERO_BALANCE, {
    onError,
    ...options,
  });
};
