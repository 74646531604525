import React, { useCallback, useEffect, useState } from 'react';
//components
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import TextInput from 'shared/components/TextInput';
import NotifiableEventsContainer from '../NotifiableEventsContainer';
import Select from 'shared/components/Select';
import { showToast } from 'shared/components/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//utils
import moment from 'moment';
import { uniqBy } from 'lodash';
import { useGetProviderNames } from 'gql/provider/queries';
import { useUpdateProviderName } from 'gql/provider/mutation';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  businessId: string;
  providers: IProvider[];
}

interface IProviderNameChangeInput {
  providerId: string;
  name: string;
  type?: 'BUS' | 'LGL' | { value: ProviderNameType; label: string };
  dateOfEvent: string;
}

const ProviderNameChange: React.FC<IProps> = ({ businessId, providers }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<IProviderNameChangeInput>({
    dateOfEvent: '',
    name: '',
    type: undefined,
    providerId: '',
  });
  const [cancelForm, setCancelForm] = useState<IProviderNameChangeInput>(formData);
  const [supportingDocuments, setSupportingDocuments] = useState<Array<File>>([]);
  const acceptedFileExts = ['.pdf', '.png', '.tiff', '.jpg'];
  const typeOptions = [
    { label: 'Business', value: 'BUS' },
    { label: 'Legal Name', value: 'LGL' },
  ];

  const { loading: nameLoading } = useGetProviderNames({
    variables: {
      businessId,
      providerId: formData.providerId,
    },
    skip: formData.providerId === '' || businessId === '',
    onCompleted: (data) => {
      const names = data.getProviderNames;
      if (names.length > 0) {
        const currentName: IProviderName = names[0];
        setFormData({
          ...formData,
          ...currentName,
        });
        setCancelForm({
          ...formData,
          ...currentName,
        });
      }
    },
  });

  const [updateName, { loading: updateNameLoading }] = useUpdateProviderName({
    onCompleted: () => {
      showToast('Successfully submitted the provider name.', 'success');
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  const setSelectedFiles = (files: File[]) => {
    // When you select the same file twice it duplicates in the file list, so we only use unique filenames.
    // Also the user can select AllFiles so filter out the files we don't support
    const acceptedFiles = uniqBy(files, (f) => f.name).filter((f) =>
      acceptedFileExts.includes('.' + (f.name.split('.').pop()?.toLowerCase() ?? ''))
    );

    setSupportingDocuments(acceptedFiles);
  };

  useEffect(() => {
    if (providers.length > 0) {
      setFormData((form) => ({
        ...form,
        providerId: providers[0].id,
      }));
    }
  }, [providers]);

  const validate = useCallback(() => {
    if (Object.values(formData).some((val: string) => val === '')) {
      return false;
    }
    return supportingDocuments.length > 0;
  }, [formData, supportingDocuments]);

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }
    updateName({
      variables: {
        input: {
          DateOfEvent: moment(formData.dateOfEvent).utc().format(),
          Name: formData.name,
          Type: typeof formData.type === 'string' ? formData.type : formData.type?.value,
          providerId: formData.providerId,
          businessId: businessId,
          SupportingDocumentFiles: supportingDocuments,
        },
      },
    });
  };

  const handleCancel = () => {
    setFormData(cancelForm);
    setSupportingDocuments([]);
  };

  return (
    <>
      <NotifiableEventsContainer
        title="Provider Name Change"
        subTitle="Update the provider name by completing the required fields below."
        notificationMessage="Update due within 14 days of name change"
        onCancel={handleCancel}
        onSave={handleSubmit}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!validate()}
        providerOnChange={(option) => setFormData({ ...formData, providerId: option.value })}
        selectedProvider={formData.providerId}
        isLoading={nameLoading}
        isSaving={updateNameLoading}
        child={
          <>
            <Row>
              <Col md={12} className="mb-2">
                <DateInput
                  label="Date of change"
                  required
                  date={formData.dateOfEvent}
                  onDateSelect={(date: string) => setFormData({ ...formData, dateOfEvent: date })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row align="end">
                  <Col md>
                    <Select
                      required
                      label="Type"
                      options={typeOptions}
                      value={formData.type}
                      onChange={(value: ProviderNameType) => setFormData({ ...formData, type: value })}
                    />
                  </Col>
                  <Col md>
                    <TextInput
                      label="Name"
                      required
                      value={formData.name}
                      onChange={(value: string) => setFormData({ ...formData, name: value })}
                      disabled={false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <div className="d-flex flex-row">
                  <label className="form-label">Attach Evidence of Provider name change</label>
                  <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
                </div>
                <FileSelectDropbox
                  value={supportingDocuments}
                  onChange={setSelectedFiles}
                  showFiles={true}
                  acceptedFileExts={acceptedFileExts.join(',')}
                />
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default ProviderNameChange;
