import React, { useEffect } from 'react';
//components
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
//utils
import { useTranslation } from 'react-i18next';
import { addressTypeOptions, IProviderAddressNotInput, stateOptionsForProvider } from '../common';

interface IProps {
  data: IProviderAddressNotInput;
  setData: (p: {
    streetLine2?: string;
    streetLine1?: string;
    postcode?: string;
    suburb?: string;
    state?: string;
    type: any;
  }) => void;
  disabledTypeSelect?: boolean;
  typeValue?: {
    value: string;
    label: string;
  };
}

export const AddressInputsForProvider: React.FC<IProps> = ({
  data,
  setData,
  disabledTypeSelect = false,
  typeValue = undefined,
}) => {
  const { t } = useTranslation('business');

  useEffect(() => {
    typeValue && !data.type && setData({ ...data, type: typeValue.value });
  }, [typeValue]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Row>
            <Col className="mb-n2">
              <Select
                required
                inputId="address-type"
                label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.address-type')}
                options={addressTypeOptions}
                value={typeValue ?? addressTypeOptions.find((option) => option.value === data.type)}
                onChange={(value) => setData({ ...data, type: value })}
                disabled={disabledTypeSelect}
              />
            </Col>
            <Col className="mb-n2">
              <Select
                required
                inputId="address-state-input"
                label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.state')}
                options={stateOptionsForProvider}
                value={data.state}
                onChange={(value) => setData({ ...data, state: value.value })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="mb-n2">
          <TextInput
            required
            label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.address-one')}
            name="address1"
            value={data.streetLine1}
            onChange={(value: string) => setData({ ...data, streetLine1: value })}
            disabled={false}
            maxlength="60"
          />
        </Col>
        <Col>{''}</Col>
      </Row>
      <Row>
        <Col className="mb-n2">
          <TextInput
            required={false}
            label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.address-two')}
            name="address2"
            value={data.streetLine2}
            onChange={(value: string) => setData({ ...data, streetLine2: value })}
            disabled={false}
            maxlength="40"
          />
        </Col>
        <Col>{''}</Col>
      </Row>
      <Row>
        <Col className="mb-n2">
          <TextInput
            required
            label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.suburb')}
            name="suburb"
            value={data.suburb}
            onChange={(value: string) => setData({ ...data, suburb: value })}
            disabled={false}
            maxlength="40"
          />
        </Col>
        <Col className="mb-n2">
          <TextInput
            required
            label={t('ccs-management.provider-enters-into-admn-recp-liqd.labels.postcode')}
            name="postcode"
            value={data.postcode}
            onChange={(value: string) => setData({ ...data, postcode: value })}
            disabled={false}
            maxlength="4"
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};
