export enum PersonnelPositionLabels {
  Z7 = 'Chairperson',
  Z8 = 'Chief Executive Officer',
  Z9 = 'Child Care Service Director',
  Z10 = 'Company Director',
  Z11 = 'Company Financial Officer',
  Z12 = 'Company Secretary',
  Z13 = 'Coordinator',
  Z14 = 'Executive Officer',
  Z15 = 'General Manager',
  Z16 = 'Manager',
  Z17 = 'Nominated Supervisor',
  Z18 = 'Operator',
  Z19 = 'President',
  Z20 = 'Principal',
  Z21 = 'Program Manager',
  Z22 = 'Treasurer',
  Z23 = 'Other',
}

export const suitabilityReasonOptions = [
  {
    value: 'OFPR',
    label:
      'Charging, convictions or findings of guilt for an offence punishable by up to 2 years imprisonment or 40 penalty units',
  },
  {
    value: 'OFVI',
    label: 'Charging, conviction of finding of guilt for an offence involving violence',
  },
  {
    value: 'OFSE',
    label: 'Charging, conviction or finding of guilt for a sexual offence',
  },
  {
    value: 'OFFR',
    label: 'Charging, conviction or finding of guilt for an offence involving fraud, stealing or dishonesty',
  },
  { value: 'CUBA', label: 'Current bankruptcy' },
  {
    value: 'DIBA',
    label:
      'The person was a Director or Secretary of a corporation when it went into administration, receivership or liquidation',
  },
  {
    value: 'WWST',
    label: 'Change in the status of working with children card',
  },
  {
    value: 'OTMA',
    label: "Other matter potentially affecting the person's suitability",
  },
];
