import * as type from './types';
import * as childActionTypes from 'pages/Families/subroutes/Children/duck/types';

interface IContactsStateShape {
  all: IContact[];
  byId: Record<string, IContact>;
}

const initialState: IContactsStateShape = {
  all: [],
  byId: {},
};

export const contactsReducers = (
  state: IContactsStateShape = initialState,
  action: type.ContactActionTypes | childActionTypes.ChildActionTypes
): IContactsStateShape => {
  const byId = { ...state.byId };

  switch (action.type) {
    case type.GET_CONTACTS_SUCCESS:
      return {
        all: action.contacts,
        byId: Object.fromEntries(action.contacts.map((c: IContact) => [c.id, c])),
      };
    case type.ADD_CONTACT_SUCCESS:
      return {
        all: [...state.all, action.contact],
        byId: { ...state.byId, [action.contact.id]: action.contact },
      };
    case type.GET_CONTACT_BY_ID_SUCCESS:
    case type.UPDATE_CONTACT_SUCCESS:
      return {
        all: [
          ...state.all.filter((c) => c.id !== action.contact.id),
          { ...state.byId[action.contact.id], ...action.contact },
        ],
        byId: { ...state.byId, [action.contact.id]: { ...state.byId[action.contact.id], ...action.contact } },
      };
    case childActionTypes.REMOVE_CHILD_CONTACT_RELATIONSHIP:
      if (byId[action.contactId]) {
        byId[action.contactId] = {
          ...byId[action.contactId],
          children: (byId[action.contactId].children ?? []).filter((c) => c.childId !== action.childId),
        };
      }

      return {
        ...state,
        all: Object.values(byId),
        byId: {
          ...byId,
        },
      };
    case childActionTypes.UPDATE_CONTACT_FOR_CHILD:
      if (byId[action.contact.id]) {
        byId[action.contact.id] = {
          ...byId[action.contact.id],
          children: (byId[action.contact.id].children ?? []).map((c) =>
            c.childId === action.contact.childId
              ? {
                  ...c,
                  relationshipType: action.contact.relationshipType,
                  accountPermissions: action.contact.accountPermissions,
                }
              : c
          ),
        };
      }
      return {
        ...state,
        all: Object.values(byId),
        byId: {
          ...byId,
        },
      };
    default:
      return state;
  }
};
