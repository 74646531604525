import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';
import { enrollmentProgramFields } from './fields';
import { ApolloError } from '@apollo/client';
import { showToast } from 'shared/components/Toast';
import { ApplicationFeeType } from 'shared/constants/enums/applicationFeeType';
import { EnrollmentProgram } from 'generated/graphql';
import { IEnrollmentProgram } from 'shared/types/enrollmentProgram';

interface IEnrollmentProgramCenterInput {
  id?: string;
  centerId: string;
  classId: string;
  feeId: string;
  applicationFeeType?: ApplicationFeeType;
  applicationFeeAmount?: number;
}

interface ICreateEnrollmentProgramInput {
  businessId: string;
  name: string;
  isOpenForEnrollment: boolean;
  startDate: string;
  endDate: string;
  enrolmentOpenAt?: string | null;
  enrolmentCloseAt?: string | null;
  operatingDays: string;
  description: string;
  minEnrolmentDays?: number;
  maxEnrolmentDays?: number;
  programCenters: IEnrollmentProgramCenterInput[];
  operatingWeekDays?: WeekDay;
}

interface ICreateEnrollmentProgramVariables {
  input: ICreateEnrollmentProgramInput;
}

interface ICreateEnrollmentProgramData {
  createEnrollmentProgram: ICreateEnrollmentProgramInput & {
    id: string;
  };
}
interface IUpdateEnrollmentProgramVariables {
  input: ICreateEnrollmentProgramInput & {
    id: string;
  };
}
interface IUpdateEnrollmentProgramData {
  updateEnrollmentProgram: IEnrollmentProgram;
}

const CREATE_ENROLLMENT_PROGRAM = gql`
  mutation ($input:CreateEnrollmentProgramInput!) {
  createEnrollmentProgram(input:$input) {
    ${enrollmentProgramFields}
  }
}
`;

const UPDATE_ENROLLMENT_PROGRAM = gql`
  mutation ($input:UpdateEnrollmentProgramInput!) {
  updateEnrollmentProgram(input:$input) {
    ${enrollmentProgramFields}
  }
}
`;

export const useCreateEnrollmentProgram = (
  options?: MutationHookOptions<ICreateEnrollmentProgramData, ICreateEnrollmentProgramVariables> | undefined
) => {
  return useMutation<ICreateEnrollmentProgramData, ICreateEnrollmentProgramVariables>(CREATE_ENROLLMENT_PROGRAM, {
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });
};

export const useUpdateEnrollmentProgram = (
  options?: MutationHookOptions<IUpdateEnrollmentProgramData, IUpdateEnrollmentProgramVariables> | undefined
) => {
  return useMutation<IUpdateEnrollmentProgramData, IUpdateEnrollmentProgramVariables>(UPDATE_ENROLLMENT_PROGRAM, {
    ...options,
    onError: (error: ApolloError) => {
      showToast(error.message, 'error');
    },
  });
};
