import { gql } from '@apollo/client';

export interface GetSageGlDetailReportQueryVariables {
  centerIds: string[];
  startDate: string;
  endDate: string;
}

export interface IGetSageGlDetailResponse {
  getSageGlDetailsReport: ISageGlDetailsReportWrapper;
}

export interface ISageGlDetailsReportWrapper {
  count: number;
  startDate: string;
  endDate: string;
  rows: ISageGlDetailsReportData[];
}

export interface ISageGlDetailsReportData {
  transactionNumber: string;
  centerName: string;
  accountName: string;
  amount: number;
  date: string;
  type?: string;
  adjustmentGlCode?: string;
  adjustment?: number;
  adjustmentFeeName?: string;
  originalSessionFeeName?: string;
  originalCost?: number;
  child?: string;
  glCode?: string;
  billedFeeName?: string;
}

export const GetSageGlDetailReportQuery = gql`
  query ($centerIds: [ID]!, $startDate: String!, $endDate: String!) {
    getSageGlDetailsReport(centerIds: $centerIds, startDate: $startDate, endDate: $endDate) {
      count
      startDate
      endDate
      rows {
        transactionNumber
        centerName
        accountName
        amount
        date
        type
        adjustmentGlCode
        adjustment
        adjustmentFeeName
        originalSessionFeeName
        originalCost
        child
        glCode
        billedFeeName
      }
    }
  }
`;
