import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Col from 'shared/components/Layout/Col';
import Row from 'shared/components/Layout/Row';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import Checkbox from 'shared/components/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { useCancelCcssCertificate } from 'gql/ccssCertificates/mutations';
import { ApolloError } from '@apollo/client';
import { updateCertificate } from 'pages/Families/subroutes/Accounts/duck/actions';
import DeclarationAccordion, { DeclarationType } from './DeclarationAccordion';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  account: IAccount;
  selectedChildId?: string;
  modalControlsProps?: ICancelCcssCertificateInput;
  baseCertificateInput?: ICancelCcssCertificateInput;
  title?: string;
  certificateId: string;
  onValidateForm?: (
    setFormErrors: (error: IFormErrors) => void,
    setIsFormValid: (isValid: boolean) => void,
    certificate: ICancelCcssCertificateInput
  ) => void;
  onSubmit?: (certificate: ICancelCcssCertificateInput, account: IAccount, handleClose: () => void) => void;
  loadingExternal?: boolean;
}

const newCertificate: ICancelCcssCertificateInput = {
  businessId: '',
  accountId: '',
  centerId: '',
  certificateId: '',
  isDeclarationGiven: false,
  reasonForCancellation: '',
};

export interface IFormErrors {
  businessId: string;
  accountId: string;
  centerId: string;
  isDeclarationGiven: string;
  reasonForCancellation: string;
}

export const getEmptyErrorState = (): IFormErrors => {
  const errors: IFormErrors = {
    businessId: '',
    accountId: '',
    centerId: '',
    isDeclarationGiven: '',
    reasonForCancellation: '',
  };

  return errors;
};

const areFormFieldsValid = (certificate: ICancelCcssCertificateInput): [boolean, IFormErrors] => {
  const errors = getEmptyErrorState();
  let hasError = false;

  if (!certificate.reasonForCancellation) {
    errors.reasonForCancellation = 'Reason for cancellation is required';
    hasError = true;
  }

  if (!certificate.isDeclarationGiven) {
    errors.isDeclarationGiven = 'You must declare the information provided is true and correct';
    hasError = true;
  }

  return [!hasError, errors];
};

const CancelCertificateModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  account,
  modalControlsProps,
  certificateId,
  baseCertificateInput = newCertificate,
  title = 'Cancel Certificate',
  onValidateForm,
  onSubmit,
  loadingExternal,
}) => {
  const dispatch = useDispatch();
  const [certificate, setCertificate] = useState({ ...baseCertificateInput });
  const [isFormValid, setIsFormValid] = useState(true);
  const [formErrors, setFormErrors] = useState<IFormErrors>(getEmptyErrorState());

  const [cancelCcssCertificate, { loading: loadingCreate, error }] = useCancelCcssCertificate();

  const loading = loadingExternal ?? loadingCreate;

  const validateForm = useCallback(() => {
    if (onValidateForm) {
      // use suppled onValidateForm if provided.
      onValidateForm(setFormErrors, setIsFormValid, certificate);
      return;
    }

    const [isValid, errors] = areFormFieldsValid(certificate);
    setFormErrors(errors);
    setIsFormValid(isValid);
    console.info('Form Errors: ', errors);
  }, [certificate]);

  useEffect(() => {
    validateForm();
  }, [certificate, validateForm]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleContinue = () => {
    validateForm();
    if (!isFormValid) {
      return;
    }
    if (onSubmit) {
      // Use onSubmit provided if it exists
      onSubmit(certificate, account, handleClose);
      return;
    }

    const cancelCcssCertificateInput = {
      ...certificate,
      businessId: account.entityId,
      accountId: account.id,
      centerId: account.centerId,
      certificateId,
    };

    cancelCcssCertificate({
      variables: {
        input: { ...cancelCcssCertificateInput },
      },
    })
      .then((data) => {
        if (!!data.data?.cancelCcssCertificate) {
          dispatch(updateCertificate(account.id, data.data?.cancelCcssCertificate));
        }
        showToast('Certificate(s) has been cancelled', 'success');
        handleClose();
      })
      .catch((error) => {
        if (error instanceof ApolloError) {
          showToast(
            `${error.graphQLErrors
              .map((err) => {
                // @ts-ignore
                return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
              })
              .join(', ')}`,
            'error'
          );
        } else {
          showToast(error, 'error');
        }
      });
  };

  const handleChange = useCallback(
    (value, name) => {
      setCertificate({ ...certificate, [name]: value });
    },
    [certificate]
  );

  return (
    <CenteredModal
      size="lg"
      show={isOpen}
      onHide={handleClose}
      title={title}
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryChoice="Submit"
      primaryCallback={handleContinue}
      primaryButtonProps={{ disabled: !isFormValid, loading }}
    >
      <Form>
        <Row>
          <Col>
            <TextInput
              label="Reason for cancellation"
              name="text"
              value={certificate.reasonForCancellation}
              onChange={(value) => handleChange(value, 'reasonForCancellation')}
              as="textarea"
              required
              rows={3}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <DeclarationAccordion declarationType={DeclarationType.Certficate} />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <div className="d-flex flex-row">
              <Checkbox
                label="I declare that the information provided is true and correct"
                value={certificate.isDeclarationGiven}
                onChange={(value) => handleChange(value, 'isDeclarationGiven')}
                // @ts-ignore
                {...modalControlsProps?.isDeclarationGiven}
              />
              <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
            </div>
          </Col>
        </Row>
      </Form>
    </CenteredModal>
  );
};

export default CancelCertificateModal;
