import React from 'react';
import classnames from 'classnames';
import { Col } from 'shared/components/Layout';
import RoleCard from './RoleCard';
import {
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import colors from '_colors.module.scss';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';

interface IProps {
  label: string;
  level: string; // used to determine the new level a role is dropped onto
  roles: IRole[];
  onEditRole: (role: IRole) => void;
  onDeleteRole: (role: IRole) => void;
}

const HierarchyLevelGroup: React.FC<IProps> = ({ label, level, roles, onEditRole, onDeleteRole, ...props }) => {
  const hasEditRolePermission = useHasRoleAreaLevel({
    area: AreaType.Business,
    permission: PermissionType.Roles,
    level: RoleLevelType.Edit,
  });

  return (
    <section className="kt-role-hierarchy-group mb-4">
      <label className="mb-2">{label}</label>
      <Droppable droppableId={level} direction="horizontal">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            ref={provided.innerRef}
            /**
             * - having `justify-content-center` applied when dragging causes some overflow issues
             * - using a div with Bootstrap's row class instead of a component since we need to pass a ref
             */
            className={classnames('row align-items-center kt-role-hierarchy-group-body mx-0 p-4', {
              'justify-content-center': !snapshot.isDraggingOver,
            })}
            // @ts-ignore
            style={{ backgroundColor: snapshot.isDraggingOver ? colors.info10 : '' }}
            {...provided.droppableProps}
          >
            {roles.map((role: IRole, idx: number) => (
              <Draggable
                draggableId={role.id}
                index={idx}
                key={`role-${role.id}`}
                isDragDisabled={!hasEditRolePermission || role.isAccountOwnerRole}
              >
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                  <Col md={6} sm={12}>
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <RoleCard
                        role={role}
                        canEdit={hasEditRolePermission}
                        onEdit={onEditRole}
                        onDelete={onDeleteRole}
                      />
                    </div>
                  </Col>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </section>
  );
};

export default HierarchyLevelGroup;
