export const glCodeFields = `
  id
  name
  glCode
  businessId
  type
`;

export const paginatedGLCodes = `
  totalRecords
  data {
    ${glCodeFields}
  }
`;

export const glCodeAssignmentFields = `
  businessId
  glCodeMappingId
  glCodeId
  glCodeName
  glCodeCode
  centerId
  centerName
  type
  entityName
  entityId
  surchargeType  
`;

export const paginatedGLCodeAssignments = `
  pageNumber
  pageSize
  totalRecords
  data {
    ${glCodeAssignmentFields}
  }
`;

export const glCodeMappingFields = `
  id
  businessId
  glCodeId
  glCodeName
  glCodeCode
  feeId
  transactionTypeId
  agencyId
  adjustmentId
  surchargeType
`;

export const billingBusinessSettingsFields = `
  useGlCodes
  mandatoryGlCodeMapping
`;
