import { gql } from '@apollo/client';
import { useMutation, useQuery } from 'shared/apis/core';
import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { integrationApiKeyFields } from './fields';
import { CREATE_INTEGRATION_API_KEY } from './mutations';

interface IIntegrationApiKey {
  id: string;
  key: string;
  createdBy: string;
  description: string;
  businessId: string;
  createdAt: string;
  revokedAt: string;
  isDeleted: boolean;
  centerScopes: string[];
  apiKeyWebHooks: IApiKeyWebHook[];
  apiScopes: IApiScopesType;
}

interface IApiKeyWebHook {
  id: string;
  apiKeyId: string;
  url: string;
  webHookType: number;
  webHookApiKey: string;
}

export enum ApiScopeAuthType {
  None = 'None',
  Read = 'Read',
  Write = 'Write',
}

export interface IApiScopesType {
  business?: ApiScopeAuthType;
  centers?: ApiScopeAuthType;
  classes?: ApiScopeAuthType;
  fees?: ApiScopeAuthType;
  sessions?: ApiScopeAuthType;
  applications?: ApiScopeAuthType;
  attendances?: ApiScopeAuthType;
  staff?: ApiScopeAuthType;
  accounts?: ApiScopeAuthType;
  children?: ApiScopeAuthType;
  contacts?: ApiScopeAuthType;
  transactions?: ApiScopeAuthType;
  deposits?: ApiScopeAuthType;
  entitlements?: ApiScopeAuthType;
}

interface IGetIntegrationApiKeys {
  getIntegrationApiKeyForBusiness: IIntegrationApiKey[];
}

interface IGetIntegrationApiKeyInput {
  input: {
    id: string;
    showRevoked: boolean;
  };
}

export const GET_INTEGRATION_API_KEY = gql`
  query($input: GetIntegrationApiKeyForBusinessInput) {
    getIntegrationApiKeyForBusiness(input: $input) {
      ${integrationApiKeyFields}
    }
  }
`;

export const useGetIntegrationApiKeyForBusiness = (
  options?: QueryHookOptions<IGetIntegrationApiKeys, IGetIntegrationApiKeyInput>
) =>
  useQuery(GET_INTEGRATION_API_KEY, {
    fetchPolicy: 'network-only',
    ...options,
  });
