import moment from 'moment';
import { INewTimeEntryShape, ISessionUpdateShape } from '../EditSessionModal';
import { minutesOfDay } from 'shared/util/timeUtils';
import { orderBy } from 'lodash';
import { isRegion } from 'shared/util/region';

const isAuRegion = isRegion('AU');

export function validateAllTimeframes(session: ISessionUpdateShape, timezone: Timezone): boolean {
  let timesValid = true;

  // check that the updates to any existing time entry and any new time entry do not overlap
  const combinedTimeEntries: { timeIn: string; timeOut: string | null }[] = orderBy(
    [
      ...session.timeEntries.map((te) => ({ timeIn: te.timeIn, timeOut: te.timeOut ?? null })),
      ...(session.newTimeEntries ?? []).map((te: INewTimeEntryShape) => ({
        timeIn: te.timeIn ?? '',
        timeOut: te.timeOut,
      })),
    ],
    (te) => new Date(te.timeIn).getTime(),
    'asc'
  );

  // check that the timeframes don't overlap each other
  if (combinedTimeEntries.length > 0) {
    for (let j = 1; j < combinedTimeEntries.length; j++) {
      const previousTimeEntry = combinedTimeEntries[j - 1];
      const currentTimeEntry = combinedTimeEntries[j];

      if (
        (previousTimeEntry.timeOut === null && currentTimeEntry.timeIn !== null) ||
        (previousTimeEntry.timeOut !== null &&
          minutesOfDay(moment(previousTimeEntry.timeOut, [moment.ISO_8601, 'HH:mm']).tz(timezone)) >
            minutesOfDay(moment(currentTimeEntry.timeIn, [moment.ISO_8601, 'HH:mm']).tz(timezone)))
      ) {
        timesValid = false;
        break;
      }
    }
  }

  return timesValid;
}

export function isValidSessionGroup(formData: ISessionUpdateShape, timezone: Timezone): boolean {
  let formValid = true;

  //a class and a fee are required
  if (formData.classId === null || formData.feeId === null) {
    formValid = false;
  }

  //you cannot update an attendance entry that is greater than 28 days ago unless you have a reasonForLate change which is only enabled for AU
  if (isAuRegion && moment().diff(moment(formData.date).startOf('week').tz(timezone), 'days') > 28) {
    if (!formData.reasonForLateChange) {
      formValid = false;
    }
  }

  // historical sessions require a time in and out for every time entry
  if (moment(formData.date).tz(timezone).isBefore(moment().tz(timezone), 'date')) {
    if (
      formData.timeEntries.some((te) => !te.timeIn || !te.timeOut) ||
      formData.newTimeEntries?.some((te) => !te.timeIn || !te.timeOut)
    ) {
      return false;
    }
  }

  // check that every time entry has an in time before its out. the only exception is the last time entry which can have an out time of be null
  for (let j = 0; j < formData.timeEntries.length; j++) {
    const timeEntry = formData.timeEntries[j];

    if ((j !== formData.timeEntries.length - 1 || Boolean(formData.newTimeEntries?.length)) && !timeEntry.timeOut) {
      formValid = false;
      break;
    }

    if (!timeEntry.timeIn) {
      formValid = false;
      break;
    }

    if (
      timeEntry.timeOut !== null &&
      minutesOfDay(moment(timeEntry.timeOut, [moment.ISO_8601, 'HH:mm'])) <
        minutesOfDay(moment(timeEntry.timeIn, [moment.ISO_8601, 'HH:mm']))
    ) {
      formValid = false;
      break;
    }
  }

  if (Boolean(formData.newTimeEntries)) {
    // easier making this a variable and cast it instead of adding ts-ignore's everywhere
    const _newTimeEntries = formData.newTimeEntries as INewTimeEntryShape[];

    // same conditions from above apply to these time entries
    for (let j = 0; j < _newTimeEntries.length; j++) {
      const newTimeEntry = _newTimeEntries[j];

      if (!newTimeEntry.timeIn) {
        formValid = false;
        break;
      }

      if (j !== _newTimeEntries.length - 1 && newTimeEntry.timeOut === null) {
        formValid = false;
        break;
      }

      if (
        newTimeEntry.timeOut !== null &&
        minutesOfDay(moment(newTimeEntry.timeOut, 'HH:mm')) < minutesOfDay(moment(newTimeEntry.timeIn ?? '', 'HH:mm'))
      ) {
        formValid = false;
        break;
      }
    }
  }

  return formValid;
}
