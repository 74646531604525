import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PREDICATES, SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import { Button, Modal } from 'react-bootstrap';
import { useGetSortedAccountsNoStateUpdate } from 'gql/account/queries';
import { DIRECTIONS } from 'shared/constants/elastic';
import moment from 'moment';
import DataTable, { SizePerPage, TableHeader, TableSearch } from '../../DataTable';
import { capitalize, debounce, orderBy, sortBy } from 'lodash';
import { ColoredBackgroundTag, TagsTableCell } from '../../Tag';
import { accountStatusColorHexes } from 'shared/constants/tagColors';
import { useTranslation } from 'react-i18next';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import DropdownFilter from '../../Dropdown/DropdownFilter';
import { useMediaQuery } from '@mui/material';
import theme from 'muiTheme';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { useGetActiveCentersQuery } from 'shared/hooks/useGetActiveCenters';
import { useGetTagsInUse } from 'shared/hooks/useGetTagsInUse';
import { TagsTypeElasticIndex } from 'shared/constants/enums/tagCategoryEnum';
import { IconButtonCircle } from '../../Buttons';
import { faInfoCircle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { showToast } from '../../Toast';
import { useGetCommsAllCenterSettingsQuery, CommsCenterSetting, AccountStatusType } from 'generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'shared/components/Tooltip';

const { TERM } = SEARCH_EXPRESSIONS;
const { CONTAINS } = PREDICATES;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedChannels: IAccount[] | ICenter[];
  setSelectedChannels: (selectedAccounts: IAccount[] | ICenter[]) => void;
  handleSubmit: () => void;
  setSelectedChannelName: (searchString: string) => void;
}

interface ISort {
  field: string;
  direction: ElasticsearchSortDirection;
}

const AccountChannelSelectModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  selectedChannels,
  setSelectedChannels,
  handleSubmit,
  setSelectedChannelName,
}) => {
  const { t } = useTranslation(['comms', 'translation']);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const businessId = useSelector((state: RootState) => state.context.businessId);

  const centers = useSelector((state: RootState) => state.centers.all.map((c) => c.id));
  const tags: ITag[] = useGetTagsInUse(TagsTypeElasticIndex.Account)?.data?.getTagsUsedAcrossEntity || [];
  const { data: activeCentersData } = useGetActiveCentersQuery();
  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const [accountDataResults, setAccountDataResults] = useState<IAccount[] | undefined>();
  const [totalResults, setTotalResults] = useState(0);
  const [disabledAccounts, setDisabledAccounts] = useState<string[]>([]);

  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: DIRECTIONS.ASCENDING }];
  const [tableState, tableFunctions] = useDatatableState();
  const [sort, setSortOptions] = useState<ISort[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [authCenters, setAuthCenters] = useState<(CommsCenterSetting | null | undefined)[] | undefined>([]);
  const [authCenterIds, setAuthCenterIds] = useState<string[]>([]);
  const [centerFilters, setCenterFilters] = useState<ITableFilterOption[]>([]);
  const [tagFilters, setTagFilters] = useState<ITableFilterOption[]>([]);
  const [statusFilters, setStatusFilters] = useState<ITableFilterOption[]>([]);

  const centerOptions: ITableFilterOption[] = useMemo(
    () =>
      orderBy(authCenters, (center) => center?.centerName, 'asc').map((center) => ({
        label: center?.centerName ?? '',
        value: center?.centerId ?? '',
        searchExpression: {
          [SEARCH_EXPRESSIONS.TERM]: {
            field: 'centerId.keyword',
            predicate: PREDICATES.CONTAINS,
            value: center?.centerId,
          },
        },
      })),
    [activeCentersData, authCenters]
  );

  const statusOptions: ITableFilterOption[] = [
    {
      label: AccountStatusType.Active,
      value: AccountStatusType.Active,
    },
    {
      label: AccountStatusType.Inactive,
      value: AccountStatusType.Inactive,
    },
  ];

  const tagOptions: ITableFilterOption[] = sortBy(tags, ['name']).map((tag) => ({
    label: tag.name,
    value: tag.id,
    searchExpression: { [TERM]: { field: 'tags.keyword', predicate: CONTAINS, value: tag.id } },
  }));

  const clearActiveFilters = () => {
    setCenterFilters([]);
    setTagFilters([]);
    setSearchTerm('');
  };

  const handleSearchDebounced = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      tableFunctions.changePage(1, tableState.pageSize);
    }, 250),
    []
  );

  const handleFiltersSelect = (values: ITableFilterOption[], target: 'center' | 'tag' | 'status') => {
    switch (target) {
      case 'center':
        setCenterFilters(values);
        break;
      case 'tag':
        setTagFilters(values);
        break;
      case 'status':
        setStatusFilters(values);
        break;
      default:
        break;
    }
    tableFunctions.changePage(1, tableState.pageSize);
  };

  const { data, loading, error } = useGetCommsAllCenterSettingsQuery({
    skip: businessId === null || businessId === undefined || businessId === '',
    variables: {
      businessId: businessId ?? '',
      pageNumber: 1,
      pageSize: 2000,
    },
  });

  const {
    data: accountsPagedResult,
    loading: accountsLoading,
    error: getAccountsError,
  } = useGetSortedAccountsNoStateUpdate({
    variables: {
      input: {
        from: tableState.activePage,
        size: tableState.pageSize,
        centerIds: centerFilters.length > 0 ? centerFilters.map((c) => c.value) : authCenterIds,
        sort: sort,
        searchKey: searchTerm,
        statusType:
          statusFilters && statusFilters.length === 1 ? (statusFilters[0].value as AccountStatusType) : undefined,
        statusAtDate: moment().format('YYYY-MM-DD'),
        tagIds: tagFilters ? tagFilters.map((tf) => tf.value) : tags.map((t) => t.name),
      },
    },
  });

  useEffect(() => {
    if (getAccountsError) showToast(t('comms:select-channel-modal.account.error'), 'error');
  }, [getAccountsError, data]);

  useEffect(() => {
    setTotalResults(accountsPagedResult?.getSortedAccounts.totalRecords ?? 0);
    setAccountDataResults(accountsPagedResult?.getSortedAccounts.data ?? []);
  }, [accountsPagedResult]);

  useEffect(() => {
    setAuthCenters(
      data?.getCommsAllCenterSettings?.data.filter(
        (c) => c.usesAccountChannels && centers.some((cs) => cs === c.centerId)
      )
    );
    setAuthCenterIds(
      data?.getCommsAllCenterSettings?.data
        .filter((c) => {
          return c.usesAccountChannels && typeof c.centerId === 'string' && centers.some((cs) => cs === c.centerId);
        })
        .map((c) => {
          if (typeof c.centerId === 'string') {
            return c.centerId;
          }
          return '';
        }) ?? []
    );
  }, [data]);

  return (
    <Modal
      centered
      backdrop="static"
      show={isOpen}
      onHide={onClose}
      scrollable={true}
      size="xl"
      className="channel-select-modal"
    >
      <Modal.Header closeButton className="px-6 py-4">
        <Modal.Title as="h5">{t('comms:select-channel-modal.account.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-4 px-4 gap-waiver-confirmation-container">
        <DataTable
          className="account-channel-select"
          data={accountDataResults ?? []}
          dataSize={totalResults}
          showLoadingOverlay={accountsLoading || loading}
          pageSize={tableState.pageSize}
          onPageChange={tableFunctions.changePage}
          activePage={tableState.activePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          selectedRows={selectedChannels}
          updateSelectedRows={setSelectedChannels}
          showSelect={true}
          nonSelectable={disabledAccounts}
          rowClasses={(row: IAccount, rowIndex: number) => {
            if (disabledAccounts.includes(row.id)) return 'disabled';
            else return '';
          }}
          columns={[
            {
              text: 'Name',
              dataField: 'name',
            },
            {
              text: 'Children',
              dataField: 'children',
              formatter: (cell: any, row: IAccount) => (
                <div className="d-flex">{row.children.map((c) => `${c.firstname} ${c.lastname}`).join(', ')}</div>
              ),
            },
            {
              text: capitalize(t('translation:spelling.center')),
              dataField: 'center.name',
            },
            {
              text: 'Status',
              dataField: 'status',
              formatter: (cell: any, row: IAccount) => (
                <div className="d-flex">
                  <ColoredBackgroundTag color={accountStatusColorHexes[row.status]} text={row.status} />
                </div>
              ),
            },
            {
              text: 'Tags',
              dataField: 'tags',
              formatter: (_: any, row: IAccount) => row.tags && <TagsTableCell tags={row.tags} />,
            },
            disabledAccounts.length > 0 && {
              text: '',
              formatter: (_: any, row: IAccount) => {
                if (disabledAccounts.includes(row.id))
                  return (
                    <Tooltip
                      text={`Messaging inactive accounts is not turned on for this ${t('translation:spelling.center')}`}
                      direction="top"
                    >
                      <FontAwesomeIcon icon={faInfoCircle} size={'lg'} className="ml-2" />
                    </Tooltip>
                  );
              },
            },
          ]}
          renderHeader={(paginationProps: any) => (
            <TableHeader className="flex-wrap align-items-center">
              <div className="d-flex flex-wrap mr-auto">
                <SizePerPage paginationProps={paginationProps} />
                <TableSearch
                  placeholder="Search"
                  className={isMobile ? 'my-2 mb-1' : ''}
                  onChange={handleSearchDebounced}
                />
              </div>
              <div className="d-flex flex-wrap align-items-center">
                <DropdownFilter
                  title={fieldLabels.center}
                  className={isMobile ? 'my-1 mr-4' : 'mr-4'}
                  options={centerOptions}
                  onFilterSelect={(val) => handleFiltersSelect(val, 'center')}
                  selectedFilters={centerFilters}
                />
                {authCenters && (
                  <DropdownFilter
                    title={'Status'}
                    className={isMobile ? ' my-1 mr-4' : 'mr-4'}
                    options={statusOptions}
                    onFilterSelect={(val) => handleFiltersSelect(val, 'status')}
                    selectedFilters={statusFilters}
                  />
                )}
                <DropdownFilter
                  title={'Tags'}
                  className={isMobile ? ' my-1 mr-4' : 'mr-4'}
                  options={tagOptions}
                  onFilterSelect={(val) => handleFiltersSelect(val, 'tag')}
                  selectedFilters={tagFilters}
                />
                <IconButtonCircle
                  icon={faTimes}
                  onClick={clearActiveFilters}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                  className={isMobile ? 'mx-auto' : ''}
                />
              </div>
            </TableHeader>
          )}
        />
      </Modal.Body>
      <Modal.Footer className="px-4 py-2">
        <Button
          variant="light"
          className="mr-3"
          onClick={() => {
            clearActiveFilters();
            tableFunctions.changePage(1, tableState.pageSize);
            onClose();
          }}
        >
          Close
        </Button>
        <Button
          disabled={!selectedChannels || selectedChannels.length <= 0}
          variant="primary"
          onClick={() => {
            if (selectedChannels.length === 1) {
              setSelectedChannelName(selectedChannels[0]?.name);
            }

            clearActiveFilters();
            handleSubmit();
          }}
        >
          {t('comms:select-channel-modal.account.select-btn')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountChannelSelectModal;
