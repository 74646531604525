import React from 'react';
import { useHistory } from 'react-router-dom';
import PageWrapper from 'shared/components/PageWrapper';
import { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import CenterClassesTable from './components/CenterClassesTable';
import { useGetClassesForCenters } from './graphql/queries';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { getAllClasses } from './duck/selectors';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { RoleLevelType, AreaType, PermissionType } from 'shared/constants/enums/permissionsEnums';

interface IProps {}

const Classes: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);
  const classes = useSelector(getAllClasses);
  const centerOptions = useGetActiveCenters().filter((center) => center.entityId === currentBusinessId);
  const center = centerOptions.find((c) => c.id === currentCenterId);
  const { loading } = useGetClassesForCenters(currentCenterId ?? '');

  return (
    <PageWrapper
      pageTitle="Classes"
      applyPadding={true}
      mobileButtonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Center, permission: PermissionType.Classes, level: RoleLevelType.Create }}
        >
          <CirclePlusButton
            variant="primary"
            className="mt-4 mb-4"
            disabled={!center}
            onClick={() =>
              history.push('/centers/classes/new', {
                centerName: center?.name,
                centerId: currentCenterId,
              })
            }
          />
        </HasRoleAreaLevel>
      }
      buttonComponent={
        <HasRoleAreaLevel
          action={{ area: AreaType.Center, permission: PermissionType.Classes, level: RoleLevelType.Create }}
        >
          <CreateButton
            disabled={!center}
            onClick={() =>
              history.push('/centers/classes/new', {
                centerName: center?.name,
                centerId: currentCenterId,
              })
            }
          >
            Add Class
          </CreateButton>
        </HasRoleAreaLevel>
      }
    >
      <CenterSelectBanner pageName="classes" />
      {loading && !classes.length ? (
        <PageWrapperBody>
          <DataTableLoadingSkeleton />
        </PageWrapperBody>
      ) : (
        <CenterClassesTable selectedCenter={center} classes={classes} />
      )}
    </PageWrapper>
  );
};

export default Classes;
