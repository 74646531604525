import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { GLCodeTypeOptions } from 'shared/constants/enums/glCodes';
import { capitalize } from 'shared/util/string';

export interface IGLCodeForm {
  name: string;
  id?: string;
  glCode: string;
  types?: { label: string; value: GLCodeAreaType }[];
  type: GLCodeAreaType | null;
}
interface IProps {
  formData: IGLCodeForm;
  setFormData: React.Dispatch<React.SetStateAction<IGLCodeForm>>;
  isCreate?: boolean;
}

const GLCodesForm: React.FC<IProps> = ({ formData, setFormData, isCreate = true }) => {
  const { t } = useTranslation();
  return (
    <div>
      <TextInput
        required
        label={capitalize(t('spelling.name'))}
        value={formData.name}
        onChange={(value) => {
          setFormData({ ...formData, name: value });
        }}
      />
      {isCreate ? (
        <Select
          required
          label={capitalize(t('spelling.type'))}
          options={GLCodeTypeOptions}
          isMulti
          onChange={(option: { label: string; value: GLCodeAreaType }[]) => {
            setFormData({ ...formData, types: option });
          }}
          value={formData.types ?? []}
        />
      ) : (
        <Select
          required
          label={capitalize(t('spelling.type'))}
          options={GLCodeTypeOptions}
          disabled
          onChange={(option) => {
            setFormData({ ...formData, type: option.value });
          }}
          value={formData.type}
        />
      )}
      <TextInput
        required
        label={capitalize(t('billing.settings.transactions.glCode'))}
        value={formData.glCode}
        onChange={(value) => {
          setFormData({ ...formData, glCode: value });
        }}
      />
    </div>
  );
};

export default GLCodesForm;
