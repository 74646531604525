import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import statementFields, { centerStatementRunsFields } from './fields';

interface IUpdateCenterStatementScheduleData {
  updateCenterStatementSchedule: ICenterStatementSchedule;
}
interface IUpdateCenterStatementScheduleVariables {
  input: ICenterStatementSchedule;
}

interface ICenterStatementManualScheduleVariables {
  input: IManualStatementRunsInput;
}

interface IAccountAdhocStatementRunVariables {
  input: IAccountAdhocStatementRunsInput;
}

interface IGenerateStatementVariables {
  input: IAccountAdhocStatementRunsInput;
}

export const UPDATE_CENTER_STATEMENT_SCHEDULE = (fields: string = statementFields) =>
  gql` mutation ($input: UpdateCenterStatementScheduleInput!) {
    updateCenterStatementSchedule(input: $input) {
      ${fields}
    }
  }`;

export const RUN_CENTER_STATEMENT_MANUAL_SCHEDULE = (fields: string = centerStatementRunsFields) =>
  gql` mutation ($input: ManualStatementRunsInput!) {
    manualStatementRuns(input: $input) {
      ${fields}
    }
  }`;

export const SEND_ADHOC_STATEMENT = () =>
  gql`
    mutation ($input: AccountAdhocStatementRunsInput!) {
      sendAdhocStatement(input: $input)
    }
  `;

export const GENERATE_PDF_STATEMENT = () =>
  gql`
    mutation ($input: GeneratePdfStatementInput!) {
      generatePdfStatement(input: $input)
    }
  `;

export const useUpdateCenterStatementSchedule = (
  options?: MutationHookOptions<IUpdateCenterStatementScheduleData, IUpdateCenterStatementScheduleVariables>,
  fields?: string
) => {
  return useMutation<IUpdateCenterStatementScheduleData, IUpdateCenterStatementScheduleVariables>(
    UPDATE_CENTER_STATEMENT_SCHEDULE(fields),
    {
      ...options,
    }
  );
};

export const useManualRunStatement = (
  options?: MutationHookOptions<IStatementRun, ICenterStatementManualScheduleVariables>,
  fields?: string
) => {
  return useMutation<IStatementRun, ICenterStatementManualScheduleVariables>(
    RUN_CENTER_STATEMENT_MANUAL_SCHEDULE(fields),
    { ...options }
  );
};

interface IUpdateCenterStatementMessageVariables {
  input: ICenterStatementMessage;
}

export const UPDATE_CENTER_STATEMENT_MESSAGE = (fields: string = statementFields) =>
  gql`
    mutation ($input: UpdateCenterStatementMessageInput!) {
      updateCenterStatementMessage(input: $input)
    }
  `;

export const useUpdateCenterStatementMessage = (
  options?: MutationHookOptions<string, IUpdateCenterStatementMessageVariables>,
  fields?: string
) => {
  const dispatch = useDispatch();
  return useMutation<string, IUpdateCenterStatementMessageVariables>(UPDATE_CENTER_STATEMENT_MESSAGE(fields), {
    ...options,
  });
};

interface IAdhocStatementRun {}
export const useSendAdhocStatement = (
  options?: MutationHookOptions<IAdhocStatementRun, IAccountAdhocStatementRunVariables>
) => {
  return useMutation<IAdhocStatementRun, IAccountAdhocStatementRunVariables>(SEND_ADHOC_STATEMENT(), { ...options });
};

export interface IGeneratePdfStatement {
  generatePdfStatement: string;
}

export const useGeneratePdfStatement = (
  options?: MutationHookOptions<IGeneratePdfStatement, IGenerateStatementVariables>
) => {
  return useMutation<IGeneratePdfStatement, IGenerateStatementVariables>(GENERATE_PDF_STATEMENT(), { ...options });
};
