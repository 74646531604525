import { SEARCH_EXPRESSIONS, PREDICATES } from 'shared/constants/elastic';
import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { ISearchCentersData, ISearchCentersVariables } from 'gql/center/queries';

const { TERM } = SEARCH_EXPRESSIONS;
const { ACTIVE } = PREDICATES;
interface IGetCenterData {
  getCenter: ICenter;
}

interface IGetCenterVariables {
  id: string;
}

export const GET_ACTIVE_CENTERS_PHOTO_SETTINGS = gql`
  query ($input: SearchInput!) {
    searchCenters(input: $input) {
      totalResults
      data {
        id
        entityId
        name
        gallerySettings {
          autoApprove
          releaseOnCheckout
        }
      }
    }
  }
`;

export const GET_CENTER_PHOTO_SETTINGS = gql`
  query ($id: String!) {
    getCenter(id: $id) {
      id
      entityId
      name
      gallerySettings {
        autoApprove
        releaseOnCheckout
      }
    }
  }
`;

export const useGetCenterGallerySettingsQuery = (
  options?: QueryHookOptions<ISearchCentersData, ISearchCentersVariables> | undefined
) =>
  useQuery<ISearchCentersData, ISearchCentersVariables>(GET_ACTIVE_CENTERS_PHOTO_SETTINGS, {
    variables: {
      input: {
        filter: { [TERM]: { field: 'active', predicate: ACTIVE } },
        sort: [{ field: 'name.keyword', direction: 'ASCENDING' }],
        size: 10000,
        from: 0,
      },
    },
    fetchPolicy: 'cache-and-network',
    ...options,
  });

const useGetCenterGallerySettings = (): ICenter[] => {
  const { data } = useGetCenterGallerySettingsQuery();

  return data?.searchCenters?.data || [];
};

export const useGetCenterGallerySettingsWithLoading = (): {
  data: ICenter[] | undefined;
  loading: boolean;
} => {
  const { data, loading } = useGetCenterGallerySettingsQuery();

  return { data: data?.searchCenters?.data, loading };
};

export const useGetCenterPhotoSettings = (
  options?: QueryHookOptions<IGetCenterData, IGetCenterVariables>,
  fields?: string
) =>
  useQuery<IGetCenterData, IGetCenterVariables>(GET_CENTER_PHOTO_SETTINGS, {
    fetchPolicy: 'network-only',
    skip: !options?.variables?.id ?? false,
    ...options,
  });
