import React from 'react';
import moment from 'moment';
import { stringToHsl } from 'shared/util/string';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';

interface IProps {
  data: IISCaseClaim;
}

interface IEducatorWithHours {
  educatorId: string;
  firstName: string;
  lastName: string;
  hours?: string;
  careDate?: string;
  hoursPerDay: Record<string, number>; // { '02-22-2021': 15}
}

const ISCaseClaimsCarerInfo: React.FC<IProps> = ({ data }) => {
  // collects all the hours per day per educator
  const filteredEducators: IEducatorWithHours = data.educators.reduce((acc: any, current: IEducator, {}) => {
    const key = `educ-${current.educatorId}`; // having a number index will make the object large
    const currentHours = moment.duration(current.hours).hours();
    if (!Boolean(acc[key])) {
      acc[key] = { ...current, hoursPerDay: { [current.careDate]: currentHours } };
    } else {
      acc[key].hoursPerDay[current.careDate] = currentHours;
    }
    return acc;
  }, []);

  const days = data.educators
    .sort((a: IEducator, b: IEducator) => (a.careDate > b.careDate ? 1 : -1))
    .map((educator) => educator.careDate);
  const uniqDays = Array.from(new Set(days));

  const renderTable = () => (
    <table className="table-simple" style={{ width: `${((uniqDays.length + 1) / 7) * 100}%` }}>
      <thead>
        <td>Educator</td>
        {uniqDays.map((d) => (
          <td>{moment(d).format('dddd')}</td>
        ))}
      </thead>
      <tbody>
        {Object.values(filteredEducators).map(({ firstName, lastName, educatorId, hoursPerDay }) => (
          <tr>
            <td>
              <AvatarDataTableCell
                size="sm"
                color={stringToHsl(educatorId)}
                initials={`${firstName.toUpperCase().charAt(0)}${lastName.toUpperCase().charAt(0)}`}
                avatar=""
                primaryText={`${firstName} ${lastName}`}
              />
            </td>
            {uniqDays.map((d) => (
              <td className="text-center">{hoursPerDay[d]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      <h5 className="mb-4 ml-4">Educator Information</h5>
      {data.educators.length > 0 ? renderTable() : <div className="ml-4">Not provided</div>}
    </div>
  );
};

export default ISCaseClaimsCarerInfo;
