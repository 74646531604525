import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { defaultCommentFields } from './fields';

interface IComment {
  id: string;
  businessId: string;
  attachedToEntityId: string;
  parentId: string;
  text: string;
  createdBy: string;
  createdAt: string;
  replyCount: number;
  createdByAccount: IStaff;
}

type CommentAttachedToObjectType = 'Note';

interface ICreateCommentInput {
  input: {
    businessId: string;
    attachedToId: string;
    text: string;
    parentId: string;
    attachedToEntityType: CommentAttachedToObjectType;
  };
}

interface IPagedListComments {
  totalRecords?: number;
  pageNumber?: number;
  pageSize?: number;
  data: IComment[];
}

type SortDirection = 'ASCENDING' | 'DESCENDING';

interface ISearchCommentInput {
  input: {
    isArchived?: boolean;
    businessId: string;
    sortDirection?: SortDirection;
    sortField?: string;
    from: number;
    size: number;
    attachedToEntityId?: string;
    parentCommentId?: string;
  };
}

export const SEARCH_COMMENTS = gql`
  query($input: SearchCommentInput!) {
    searchComment(input: $input) {
        totalRecords
        pageNumber
        pageSize
        data{
            ${defaultCommentFields}
        }
    }
  }
`;

export const useSearchComments = (
  options?: QueryHookOptions<{ searchComment: IPagedListComments }, ISearchCommentInput>
) =>
  useQuery(SEARCH_COMMENTS, {
    fetchPolicy: 'network-only',
    ...options,
  });
