import React, { useEffect } from 'react';
//components
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
//utils
import { useGetArrangementEmailPreview } from '../graphql/queries';
import { useTranslation } from 'react-i18next';
import { showToast } from 'shared/components/Toast';

interface IProps {
  modal: {
    isOpen: boolean;
    type: string;
  };
  hide: () => void;
  entityId: string;
  arrangementId: string;
}

export const PreviewModalCwa: React.FC<IProps> = ({ modal, hide, entityId, arrangementId }) => {
  const { t } = useTranslation(['comms', 'subsidies']);

  const CwaArrangementEmailInput = {
    businessId: entityId,
    arrangementId,
  };
  const { data, loading, error } = useGetArrangementEmailPreview(CwaArrangementEmailInput);
  useEffect(() => {
    if (!loading && !data && error) {
      hide();
      showToast(error.message, 'error');
    }
  }, [loading, data, error]);

  return (
    <Modal centered backdrop="static" show={modal.isOpen} onHide={hide} size="lg">
      {!loading && data && (
        <>
          <Modal.Header closeButton className="border-bottom-0 pb-4">
            <div className="d-flex flex-column">
              <Modal.Title as="h2">Email Preview</Modal.Title>
            </div>
          </Modal.Header>
          <div dangerouslySetInnerHTML={{ __html: data.getArrangementEmailPreview }}></div>
          <Modal.Footer className="border-top-0 p-1">
            <Button id="edit-cycle-modal-primary-btn" className="mr-3" variant="primary" onClick={hide}>
              {t('comms:close-btn')}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
