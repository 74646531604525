import React, { useMemo } from 'react';
//components
import Button from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import { Row, Col } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import Alert from 'shared/components/Alert';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
//utils
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useTranslation } from 'react-i18next';

interface IProps {
  feeOptions: IFee[];
  permanentFeeId: string | null;
  casualFeeId: string | null;
  openNewFeeModal: () => void;
  onFeeSelect: (type: 'defaultCasualFeeId' | 'defaultPermanentFeeId', fee: IFee | null) => void;
  classId: string;
  isReadOnly: boolean;
}

interface CasualFee {
  deactivatedAt?: string | null;
  id: string | null;
  name: string;
}

const ClassDefaultFees: React.FC<IProps> = ({
  feeOptions,
  permanentFeeId,
  casualFeeId,
  openNewFeeModal,
  onFeeSelect,
  classId,
  isReadOnly,
  ...props
}) => {
  const { t } = useTranslation('translation');

  const feesById: Record<string, string> = useMemo(
    () => feeOptions.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {}),
    [feeOptions]
  );

  const NO_CASUAL_FEE: CasualFee = {
    deactivatedAt: null,
    id: null,
    name: t('class.fees.casual-fee.no-casual-fee'),
  };

  return (
    <Card header="Default Fees" className="h-100" bodyClassName="d-flex flex-column">
      {feeOptions.length > 0 && (!permanentFeeId || !casualFeeId) && (
        <Alert className="mb-4" variant="warning">
          {t('class.fees.alert.no-fee')}
        </Alert>
      )}
      {!classId ? (
        <Alert className="mb-4" variant="info">
          {t('class.fees.alert.no-class')}
        </Alert>
      ) : (
        <div>
          <Row>
            <Col>
              <Select
                label={t('class.fees.permanent-fee.permanent')}
                value={permanentFeeId ? { id: permanentFeeId, name: feesById[permanentFeeId] ?? '' } : null}
                options={feeOptions}
                onChange={(option: IFee) => onFeeSelect('defaultPermanentFeeId', option)}
                getOptionLabel={(option: IFee) => option.name}
                getOptionValue={(option: IFee) => option.id}
                disabled={isReadOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                label={t('class.fees.casual-fee.casual')}
                options={[NO_CASUAL_FEE, ...feeOptions]}
                placeholder={t('class.fees.casual-fee.select')}
                value={casualFeeId ? { id: casualFeeId, name: feesById[casualFeeId] ?? '' } : null}
                onChange={(option: IFee) => onFeeSelect('defaultCasualFeeId', option)}
                getOptionLabel={(option: IFee) => option.name}
                getOptionValue={(option: IFee) => option.id}
                disabled={isReadOnly}
              />
            </Col>
          </Row>
          <HasRoleAreaLevel
            action={{ area: AreaType.Center, permission: PermissionType.Fees, level: RoleLevelType.Create }}
          >
            <Button variant="secondary" className="align-self-end" onClick={openNewFeeModal} disabled={isReadOnly}>
              {t('class.fees.buttons.add-fee')}
            </Button>
          </HasRoleAreaLevel>
        </div>
      )}
    </Card>
  );
};

export default ClassDefaultFees;
