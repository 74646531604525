import React from 'react';

import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';

import { ButtonAsLink } from 'shared/components/Buttons';
import Checkbox from 'shared/components/Checkbox';
import { getFullName, getInitials } from 'shared/util/string';
import { showToast } from 'shared/components/Toast';
import { useGetSessionDataForChildSelect } from 'gql/session/queries';
import Select from 'shared/components/Select';
import Avatar from 'shared/components/Avatar';
import Spinner from 'shared/components/Spinner';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useAddChildTag, useRemoveChildTag } from 'pages/Photos/gql/mutations';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFormatDate from 'shared/hooks/useFormatDate';

interface IProps {
  photo: IPhoto;
}

const TaggedChildren: React.FC<IProps> = ({ photo }) => {
  const hasGalleryEdit = useHasRoleAreaLevel({
    area: AreaType.Activities,
    permission: PermissionType.Photo,
    level: RoleLevelType.Edit,
  });
  const [children, setChildren] = React.useState(photo.children);
  const [addChildOpen, setAddChildOpen] = React.useState(false);

  return (
    <>
      <label className="font-weight-semi-bold">Tagged Children:</label>
      {children != null &&
        children.length > 0 &&
        sortBy(children, 'firstname').map((child) => (
          <ChildTag key={child.id} child={child} photoId={photo.id} setChildren={setChildren} />
        ))}
      <br />
      {hasGalleryEdit && (
        <ButtonAsLink className="sm" onClick={() => setAddChildOpen(!addChildOpen)}>
          <FontAwesomeIcon icon={faPlus} />
          {` Add Child`}
        </ButtonAsLink>
      )}
      {addChildOpen && (
        <AddChild photo={photo} children={children} setChildren={setChildren} onClose={() => setAddChildOpen(false)} />
      )}
    </>
  );
};

export default TaggedChildren;

interface IChildTagProps {
  photoId: string;
  child: IChildPhoto;
  setChildren: (children: IChildPhoto[]) => void;
}

const ChildTag: React.FC<IChildTagProps> = ({ photoId, child, setChildren }) => {
  const hasGalleryEdit = useHasRoleAreaLevel({
    area: AreaType.Activities,
    permission: PermissionType.Photo,
    level: RoleLevelType.Edit,
  });
  const [removeChildTag, { loading }] = useRemoveChildTag({
    onCompleted: (data) => {
      setChildren(data.removeChildTag.children);
      showToast('Child Untagged Successfully', 'success');
    },
    onError: () => {
      showToast('Error Untagged Child', 'error');
    },
  });

  const removeChild = () => {
    removeChildTag({
      variables: {
        input: {
          photoId: photoId,
          childId: child.childId,
        },
      },
    });
  };

  return loading ? (
    <div>
      <Spinner small />
    </div>
  ) : (
    <Checkbox
      disabled={!hasGalleryEdit}
      key={child.id}
      value={true}
      label={getFullName(child.child)}
      onChange={(v) => removeChild()}
    />
  );
};

interface IAddChildProps {
  photo: IPhoto;
  children: IChildPhoto[];
  setChildren: (children: IChildPhoto[]) => void;
  onClose: () => void;
}

interface IChildWithAccountId extends IChild {
  accountChildId: string;
}

const AddChild: React.FC<IAddChildProps> = ({ photo, children, setChildren, onClose }) => {
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();
  const formatDate = useFormatDate();
  const { data: sessionData, loading: dataLoading } = useGetSessionDataForChildSelect({
    variables: {
      input: {
        centerId: photo.centerId,
        startDate: moment(photo.takenAt).tz(timezone).format('YYYY-MM-DD'),
        endDate: moment(photo.takenAt).tz(timezone).format('YYYY-MM-DD'),
      },
    },
  });
  const childrenOptions =
    sortBy(
      sessionData?.getExpectedSessions
        .filter((s) => !children.map((c) => c.child.id).includes(s.child.id))
        .map((s) => ({
          ...s.child,
          accountChildId: s.accountChildId,
        })),
      'firstname'
    ) ?? [];

  const [addChildTag, { loading }] = useAddChildTag({
    onCompleted: (data) => {
      showToast('Child Tagged Successfully', 'success');
      setChildren(data.addChildTag.children);
      onClose();
    },
    onError: () => {
      showToast('Error Tagging Child', 'error');
    },
  });

  const addChildFn = (childId: string) => {
    const input = {
      photoId: photo.id,
      childId: childId,
    };
    addChildTag({
      variables: { input: input },
    });
  };
  return loading ? (
    <div>
      <Spinner small />
    </div>
  ) : (
    <Select
      isSearchable
      isLoading={dataLoading}
      options={childrenOptions}
      value={''}
      getOptionValue={(option: IChildWithAccountId) => option.id}
      onChange={(child: IChildWithAccountId) => addChildFn(child.accountChildId)}
      getOptionLabel={(option: IChildWithAccountId) => getFullName(option)}
      formatOptionLabel={(option: IChildWithAccountId) => (
        <div className="d-flex align-items-center">
          <Avatar initials={getInitials(option)} size="sm" image={option.avatar?.url} className="mr-2" />
          <div>{getFullName(option)}</div>
        </div>
      )}
      className="flex-wrap"
      noOptionsMessage={() => `No children attended center on selected date.`}
    />
  );
};
