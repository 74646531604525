import React, { useState, useCallback } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import RegulationInputs from './RegulationInputs';
import { useAddRegulation } from './graphql/mutations';
import cast from 'shared/util/cast';
import { showToast } from 'shared/components/Toast';
import { LoadingLines } from 'shared/components/LoadingSkeletons';

const DEFAULT_REGULATION: INewRegulationInput = {
  state: '',
  county: '',
  ratioTeachers: '',
  ratioChildren: '',
  capacity: '',
  startAge: {
    age: '',
    unit: 'WEEK',
  },
  endAge: {
    age: '',
    unit: 'WEEK',
  },
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  validateRegulation: (regulation: INewRegulationInput) => boolean;
}

const NewRegulationModal: React.FC<IProps> = ({ isOpen, onClose, validateRegulation, ...props }) => {
  const [regulation, setRegulation] = useState<INewRegulationInput>({ ...DEFAULT_REGULATION });
  const [addRegulation, { loading }] = useAddRegulation();

  const dismissModal = useCallback(() => {
    onClose();
    setRegulation({ ...DEFAULT_REGULATION });
  }, [onClose]);

  const handleAddRegulation = useCallback(
    (addAnother: boolean) => {
      addRegulation({
        variables: {
          input: {
            state: regulation.state,
            county: regulation.county ?? '',
            startAge: {
              age: parseFloat(regulation.startAge.age),
              unit: cast<RegulationAgeUnit>(regulation.startAge.unit),
            },
            endAge: {
              age: parseFloat(regulation.endAge.age),
              unit: cast<RegulationAgeUnit>(regulation.endAge.unit),
            },
            capacity: parseInt(regulation.capacity, 10),
            ratioTeachers: parseInt(regulation.ratioTeachers, 10),
            ratioChildren: parseInt(regulation.ratioChildren, 10),
          },
        },
      }).then(() => {
        showToast('Regulation created successfully.', 'success');

        if (addAnother) {
          setRegulation({ ...DEFAULT_REGULATION });
        }
      });
    },
    [addRegulation, regulation]
  );

  return (
    <SideModalDrawer
      title="New Regulation"
      show={isOpen}
      onHide={dismissModal}
      primaryChoice="Save"
      primaryCallback={() => handleAddRegulation(false)}
      primaryButtonProps={{
        disabled: !validateRegulation(regulation),
      }}
      closeOnSecondaryCallback={false}
      secondaryChoice="Save, Add Another"
      secondaryCallback={() => handleAddRegulation(true)}
      secondaryButtonProps={{
        disabled: !validateRegulation(regulation),
      }}
    >
      {loading ? <LoadingLines /> : <RegulationInputs regulation={regulation} updateRegulation={setRegulation} />}
    </SideModalDrawer>
  );
};

export default NewRegulationModal;
