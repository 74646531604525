import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { allergenFields } from './fields';

interface IUpdateAllergensVariables {
  input: {
    typeId: string;
    toCreate: string[];
    toArchive: string[];
  };
}

interface IUpdateAllergensData {
  updateAllergenListForHealthTerm: IAllergen[];
}

export const UPDATE_ALLERGENS = (fields: string = allergenFields) => gql`
  mutation ($input: UpdateAllergenListInput!) {
    updateAllergenListForHealthTerm(input: $input) {
      ${fields}
    }
  }
`;

export const useUpdateAllergens = (
  options?: MutationHookOptions<IUpdateAllergensData, IUpdateAllergensVariables>,
  fields?: string
) => useMutation<IUpdateAllergensData, IUpdateAllergensVariables>(UPDATE_ALLERGENS(fields), options);
