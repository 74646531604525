import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import DataTable, { TableHeader, TableSearch } from 'shared/components/DataTable';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable/DataTable';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import { Col, Row } from 'shared/components/Layout';
import { updateEnrollmentTableWeek } from 'pages/Enrollment/duck/actions';
import Avatar from 'shared/components/Avatar';
import { toProperCase } from 'shared/util/string';
import momentTz from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { ageToNumberOfDays } from 'shared/util/ageToNumberOfDays';
import { useGetCenterAvailability } from './graphql/queries';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import moment from 'moment-timezone';
import Availabilityfilter from '../../components/AvailabilityFilter/AvailabilityFilter';

interface IOpenSpotsCellProps {
  dayOfWeek: number;
  classAvailability: any;
}

interface IProps {
  onClassSelect: (_class: IClass) => void;
  centerTimezone: string;
}

const OpenSpotsData: React.FC<{ openSpots: number | undefined }> = ({ openSpots }) =>
  Boolean(openSpots) ? (
    <div className="text-success text-center">{openSpots}</div>
  ) : (
    <FontAwesomeIcon color={colors.slate} size="2x" className="mx-auto" icon={faTimesCircle} />
  );

const AvailabilityTable: React.FC<IProps> = ({ onClassSelect, centerTimezone, ...props }) => {
  const dispatch = useDispatch();
  const includeFutureContracts = useSelector((state: RootState) => state.enrollment.includeFutureContracts);
  const includeLtbOffers = useSelector((state: RootState) => state.enrollment.includeLtbOffers);
  const selectedCenterId = useSelector((state: RootState) => state.enrollment.activeCenter.id);
  const startOfWeek = useSelector((state: RootState) => state.enrollment.activeTimeframe.start);
  const endOfWeek = useSelector((state: RootState) => state.enrollment.activeTimeframe.end);
  const [filterString, setFilterString] = useState('');
  const { loading, data, networkStatus } = useGetCenterAvailability(
    selectedCenterId,
    startOfWeek.format(),
    endOfWeek.format(),
    includeLtbOffers,
    includeFutureContracts
  );
  const classesWithAvailability = data?.getClassesForCenter ?? [];
  const tableData = classesWithAvailability
    .filter((c) => !c.archivedAt)
    .filter((c) => !c.endsAt || moment(c.endsAt).isSameOrAfter(startOfWeek, 'date'))
    .filter((c) => moment(c.startsAt).isSameOrBefore(endOfWeek, 'date'))
    .filter((c) => !filterString || c.name.toLocaleLowerCase().includes(filterString.toLocaleLowerCase()));

  const handleSort = useCallback(
    (field: string, direction: ElasticsearchSortDirection) => {
      if (field === 'startAge') {
        tableData.sort((_classA, _classB) => {
          const ageA = ageToNumberOfDays(_classA.regulationOverride?.startAge || _classA.regulation.startAge);
          const ageB = ageToNumberOfDays(_classB.regulationOverride?.startAge || _classB.regulation.startAge);
          return direction === 'ASCENDING' ? ageA - ageB : ageB - ageA;
        });
      } else {
        tableData.sort((_classA, _classB) => {
          if (_classA.name < _classB.name) {
            return direction === 'ASCENDING' ? -1 : 1;
          }
          if (_classA.name > _classB.name) {
            return direction === 'ASCENDING' ? 1 : -1;
          }
          return 0;
        });
      }
    },
    [tableData]
  );

  if (loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES) {
    return <DataTableLoadingSkeleton />;
  }

  return (
    <div className="mx-n8">
      <DataTable
        keyField="id"
        data={tableData}
        handleRowClick={(event, rowData) => {
          onClassSelect(rowData);
        }}
        showLoadingOverlay={loading}
        showSelect={false}
        showPagination={false}
        onSort={handleSort}
        className="py-2 px-8"
        noPadding={true}
        columns={[
          {
            text: 'Class',
            dataField: 'name',
            sort: true,
            formatter: (cell: any, row: IClass) => (
              <Row noGutters>
                <Avatar className="mr-2" color={row.colorCode} initials={row.name[0]} size="md" image="" />
                <Col>
                  <div>{row.name}</div>
                  <div className="small"> Capacity: {row.capacity ?? row.regulation.capacity ?? 0}</div>
                </Col>
              </Row>
            ),
          },
          {
            text: 'Start / End Age',
            dataField: 'startAge',
            formatter: (cell: any, row: IClass) => {
              const regulation = row.regulationOverride || row.regulation;
              const startAge = regulation.startAge;
              const endAge = regulation.endAge;
              return `${startAge.age} ${toProperCase(startAge.unit)}${startAge.age === 1 ? '' : 's'} - ${
                endAge.age
              } ${toProperCase(endAge.unit)}${endAge.age === 1 ? '' : 's'} `;
            },
            sort: true,
          },
          {
            text: '',
            align: 'center',
            isDummyField: true,
            style: { padding: '0px' },
            formatter: (cell: any, row: IClass) => {
              const isFullDay = row.availabilityForTimeframe.every((data) => data.isFullDayUtilization);

              return isFullDay ? (
                <small>All Day</small>
              ) : (
                <div>
                  <small>AM</small>
                  <hr className="horizontal-divider border border-pale-grey" />
                  <small>PM</small>
                </div>
              );
            },
          },
          ...['Mon', 'Tues', 'Wed', 'Thurs', 'Fri'].map((day, i) => ({
            text: day,
            dataField: day,
            align: 'center',
            headerAlign: 'center',
            style: {
              padding: '0px',
            },
            formatter: (cell: any, row: IClass) => {
              const isFullDay = row.availabilityForTimeframe.every((data) => data.isFullDayUtilization);
              const availability = row.availabilityForTimeframe.find((a) => {
                // explanation in ClassEnrollmentTable.tsx as to why we're only looking at everything before the +
                const dateMoment = momentTz(a.date.split('+')[0]).tz(centerTimezone, true);

                return dateMoment.day() === i + 1;
              });

              return isFullDay ? (
                <OpenSpotsData openSpots={availability?.openSpotsAm} />
              ) : (
                <>
                  <OpenSpotsData openSpots={availability?.openSpotsAm} />
                  <hr className="horizontal-divider border border-pale-grey" />
                  <OpenSpotsData openSpots={availability?.openSpotsPm} />
                </>
              );
            },
          })),
        ]}
        renderHeader={() => (
          <TableHeader className="flex-wrap mx-8">
            <Col md={4} sm={12}>
              <TableSearch
                placeholder="Search Classes"
                onChange={(string) => {
                  setFilterString(string);
                }}
              />
            </Col>
            <Col md={4} sm={12}>
              <Row align="center">
                <WeekPicker
                  displayFormat="MMM D"
                  reactDatesController="RANGE"
                  onChange={(dates) => {
                    dispatch(updateEnrollmentTableWeek(dates.startDate, dates.endDate));
                  }}
                  className="flex-grow-0 m-auto"
                  startDate={startOfWeek}
                  endDate={endOfWeek}
                />
              </Row>
            </Col>
            <Availabilityfilter />
          </TableHeader>
        )}
      />
    </div>
  );
};

export default AvailabilityTable;
