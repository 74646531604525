import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { getFullName, getInitials } from 'shared/util/string';

interface IProps {
  data: IStaff[];
  onRowClick: (staff: IStaff) => void;
}

const RoleAssignedStaffTable: React.FC<IProps> = ({ data, onRowClick, ...props }) => {
  const { t } = useTranslation(['permissions']);

  return (
    <DataTable
      noPadding
      keyField="id"
      data={data}
      dataSize={data.length}
      showPagination={false}
      showSelect={false}
      handleRowClick={(event, row) => onRowClick(row)}
      noDataText={t('permissions:no-assigned-staff-table-message')}
      columns={[
        {
          dataField: 'id',
          text: 'Name',
          formatter: (cell: string, row: IStaff) => (
            <AvatarDataTableCell
              initials={getInitials(row)}
              avatar={row.avatar?.url ?? ''}
              primaryText={getFullName(row)}
            />
          ),
        },
      ]}
    />
  );
};

export default RoleAssignedStaffTable;
