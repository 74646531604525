import { gql } from '@apollo/client';
import { NOTIFICATION_SUBSCRIPTION_FIELDS } from './fields';
import { useK2Query } from 'shared/apis/k2Api';
import { QueryHookOptions } from '@apollo/client';
import { showToast } from 'shared/components/Toast';
import i18n from 'i18n';

const GET_USER_NOTIFICATION_SUBSCRIPTIONS = gql`
    query ($personId: UUID!){
      userNotificationSubscriptions(personId: $personId){
        ${NOTIFICATION_SUBSCRIPTION_FIELDS}
      }
    }
`;
interface GetUserNotificationSubscriptionData {
  userNotificationSubscriptions: INotificationSubscription[];
}

interface NotificationSubscriptionVariables {
  personId: string;
}

export const useGetUserNotificationSubscriptions = (
  options?: QueryHookOptions<GetUserNotificationSubscriptionData, NotificationSubscriptionVariables>
) => {
  return useK2Query<GetUserNotificationSubscriptionData, NotificationSubscriptionVariables>(
    GET_USER_NOTIFICATION_SUBSCRIPTIONS,
    {
      fetchPolicy: 'network-only',
      onError: () => showToast(i18n.t('general.error'), 'error'),
      ...options,
    }
  );
};
