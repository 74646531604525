import React, { useState, useCallback } from 'react';
import { CreatePasswordInput, PasswordInput } from 'shared/components/TextInput';
import Button from 'shared/components/Buttons';
import errorMessage from 'shared/constants/errorMessages';
import { meetsAllRequirements } from 'shared/util/passwordRequirements';
import { steps } from './Join';
import { Redirect } from 'react-router-dom';

interface IProps {
  newUser: INewUser;
  updateNewUser: React.Dispatch<React.SetStateAction<INewUser>>;
}

const CreatePassword: React.FC<IProps> = ({ newUser, updateNewUser }) => {
  const [confirmPassword, updateConfirmPassword] = useState('');
  const [redirectForward, setRedirectForward] = useState(false);
  const [redirectBack, setRedirectBack] = useState(false);

  const handleSave = useCallback((event: any) => {
    event.preventDefault();
    setRedirectForward(true);
  }, []);

  const formDisabled = !meetsAllRequirements(newUser.password) || newUser.password !== confirmPassword;

  if (redirectForward) return <Redirect to={`/join/?step=${steps.createPin}`} />;
  if (redirectBack) return <Redirect to={`/join/?step=${steps.inputName}`} />;
  return (
    <form onSubmit={handleSave} className="login-container p-8 my-auto mx-auto">
      <p className="h1 font-weight-light">Hi {newUser.firstName},</p>
      <p className="h1 mb-6">
        <span className="font-weight-light">Welcome to</span>
        <span className="font-weight-bold"> Kangarootime!</span>
      </p>
      <p className="mb-8">
        We just need a few more details to get you started. Let’s start with the password you want to use to access
        Kangarootime.
      </p>
      <CreatePasswordInput
        label="Create Password"
        value={newUser.password || ''}
        onChange={(password: string) => updateNewUser({ ...newUser, password })}
        isInvalid={!meetsAllRequirements(newUser.password)}
        errorText={errorMessage.passwordRequirements}
        required={true}
        autoComplete="new-password"
      />
      <PasswordInput
        label="Confirm Password"
        value={confirmPassword}
        onChange={updateConfirmPassword}
        isInvalid={newUser.password !== confirmPassword}
        errorText={errorMessage.confirmPassword}
        required={true}
      />
      <Button disabled={formDisabled} className="btn-block my-8" type="submit">
        Continue
      </Button>
      <Button variant="secondary" onClick={() => setRedirectBack(true)}>
        {' '}
        Back{' '}
      </Button>
    </form>
  );
};

export default CreatePassword;
