import React from 'react';
import Avatar from '../Avatar';

interface IProps {
  avatar: string;
  primaryText?: string;
  secondaryText?: string;
  initials: string;
  color?: string;
  size?: avatarSizes;
  className?: string;
}

const AvatarDataTableCell: React.FC<IProps> = ({
  avatar,
  primaryText,
  secondaryText,
  initials,
  color,
  size = 'md',
  className = '',
  ...props
}) => {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      <Avatar color={color} size={size} image={avatar} initials={initials} />
      <div className="d-flex flex-column pl-4 text-truncate">
        {primaryText && <span className="text-truncate">{primaryText}</span>}
        {secondaryText && <small>{secondaryText}</small>}
      </div>
    </div>
  );
};

export default AvatarDataTableCell;
