import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import DataTable from 'shared/components/DataTable';
import TargetsHeader from './TargetHeader';
import './BulkTransactionTargets.scss';

interface IProps {
  targets?: any[];
  targetType: string;
  onTargetAction: (targetId: string, resolution: string) => void;
}

function useGetColumnsForTargetType(targetType: string, onTargetAction: IProps['onTargetAction']) {
  const [t] = useTranslation();
  return useMemo(() => {
    const possibleColumns = [
      {
        text: 'Account',
        dataField: 'accountName',
        sort: true,
      },
      {
        text: capitalize(t('spelling.center')),
        dataField: 'centerName',
        sort: true,
      },
      {
        text: 'Account Status',
        dataField: 'accountStatus',
        sort: true,
      },
      { text: capitalize(t('spelling.class')), dataField: 'className', sort: true },
      { dataField: 'childName', text: 'Name', sort: true },
      {
        text: 'Transaction Status',
        dataField: 'status',
        sort: true,
        formatter: (cell: string, row: { id: string; status: 'ADDED' | 'NOT_ADDED' }) => {
          if (cell === 'ADDED') {
            return 'Added';
          } else if (cell === 'NOT_ADDED') {
            return (
              <div style={{ color: '#b81111' }}>
                <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '.5rem' }}></FontAwesomeIcon>
                <span>Not Added</span>
                <Button className="ml-3" onClick={() => onTargetAction(row.id, 'ADD')} variant="secondary" size="sm">
                  + Add
                </Button>
              </div>
            );
          }
          return '';
        },
      },
      {
        text: '',
        dataField: 'actions',
        formatter: (_cell, row: { id: string; status: 'ADDED' | 'NOT_ADDED' }) => {
          if (row.status !== 'ADDED') {
            return (
              <Button onClick={() => onTargetAction(row.id, 'ADD')} variant="secondary" size="sm">
                + Add
              </Button>
            );
          }
          return undefined;
        },
      },
    ];
    const expectedAccountAttributes = ['accountName', 'centerName', 'accountStatus'];
    const expectedChildAttributes = ['childName', 'accountName', 'centerName', 'className', 'accountStatus'];
    const sharedAttributes = ['status'];
    const columnsToDisplay: any[] = [];
    for (let expectedAttribute of [
      ...(targetType === 'Account' ? expectedAccountAttributes : expectedChildAttributes),
      ...sharedAttributes,
    ]) {
      const column = possibleColumns.find((c) => c.dataField === expectedAttribute);
      if (column) columnsToDisplay.push(column);
    }
    return columnsToDisplay;
  }, [onTargetAction, t, targetType]);
}

const BulkTransactionTargets: React.FC<IProps> = (props: IProps) => {
  const { targetType, onTargetAction } = props;
  const targets = useMemo(() => props.targets || [], [props.targets]);
  const columns = useGetColumnsForTargetType(targetType, onTargetAction);
  return (
    <Card header={<TargetsHeader targetType={targetType} amountSelected={targets.length}></TargetsHeader>}>
      <DataTable
        className="target-table"
        noPadding={true}
        data={targets || []}
        dataSize={targets.length}
        showLoadingOverlay={false}
        showSelect={false}
        columns={columns}
        showPagination={false}
      />
    </Card>
  );
};

export default BulkTransactionTargets;
