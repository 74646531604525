import { useManageUSModeSetting } from 'US/Billing/USMode';
import { useCallback } from 'react';
import Card from 'shared/components/Card';
import Switch from 'shared/components/Switch';
import { showToast } from 'shared/components/Toast';

export function USModeCard(props: { businessId: string }) {
  const { usMode, loading, turnOnUSMode, turnOffUSMode } = useManageUSModeSetting(props.businessId);
  const handleButtonClick = useCallback(async () => {
    if (usMode) {
      try {
        await turnOffUSMode();
        showToast('Successfully Turned OFF US Mode!!', 'success');
      } catch (e) {
        showToast('Whoops something went wrong', 'error');
      }
    } else {
      try {
        await turnOnUSMode();
        showToast('Successfully Turned ON US Mode...', 'success');
      } catch (e) {
        showToast('Whoops something went wrong', 'error');
      }
    }
  }, [turnOffUSMode, turnOnUSMode, usMode]);
  return (
    <Card header={'US Billing Mode'}>
      <p>This card turns on "US Mode" for the given business. This means that they will:</p>
      <ul>
        <li>
          See the Activity Tab (Transaction tab ordered by when they were created) under the Account's Billing Tab
        </li>
        <li>
          Transaction Types will be categorized in terms of "Charges" (what used to be called debits), and "Credits"
          (the same as before)
        </li>
        <li>
          Have their transaction display set to Parenthesised mode, and no longer be able to switch between Parens and
          Directed Mode
        </li>
      </ul>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Switch value={usMode} onChange={handleButtonClick} disabled={loading}></Switch>
      </div>
    </Card>
  );
}
