import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import Fade from 'react-bootstrap/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import ClassShift from './ClassShift';
import { IconButtonCircle } from 'shared/components/Buttons';
import Tooltip from 'shared/components/Tooltip';
import { Box, Circle } from 'shared/components/LoadingSkeletons';

interface IProps {
  showProjectedAttendance: boolean;
  onCreateNewShift: () => void;
  onShiftSelect: (shift: IShift) => void;
  canAddShifts: boolean;
  shifts: IShift[];
  highestDayShiftCount: number;
  loadingSchedule: boolean;
  calendarType: CalendarViewType;
  sessions?: ISession[];
  sessionsLoading?: boolean;
}

/**
 * This component is responsible for rendering a class's shifts for a single day of the week
 */
const ClassScheduleDay: React.FC<IProps> = ({
  showProjectedAttendance = true,
  canAddShifts,
  shifts,
  highestDayShiftCount,
  loadingSchedule,
  calendarType,
  onCreateNewShift,
  onShiftSelect,
  sessions = [],
  sessionsLoading,
  ...props
}) => {
  // see comment in ClassSchedule.tsx why this is necessary
  const numberOfFillers = highestDayShiftCount - shifts.length;
  const fillerRows = [...Array(numberOfFillers)].map((x, idx) => (
    <div key={`class-schedule-filler-${idx}`} className="kt-schedules-class-shift-filler my-1" />
  ));

  const isSplitDay = sessions.some((s) => s.fee.utilization < 1);
  const projectedChildAttendance = sessions.filter((s) => !s.absence);
  const projectedMorningChildAttendance = projectedChildAttendance.filter((s) =>
    moment(s.feeStartTime, 'hh:mm:ss').isBefore(moment({ hour: 12 }))
  );
  const projectedAfternoonChildAttendance = projectedChildAttendance.filter((s) =>
    moment(s.feeStartTime, 'hh:mm:ss').isSameOrAfter(moment({ hour: 12 }))
  );

  // apply different flex class names depending if we're looking at a week view or day view
  const shiftListClassNames = classnames({
    'p-1': true,
    'd-flex': true,
    'flex-column': calendarType === 'WEEK',
    'flex-row': calendarType === 'DAY',
    'align-items-center': true,
  });

  return (
    <div className="kt-schedules-class-single-day-schedule">
      <div className="kt-schedules-class-single-day-schedule-attendance-header">
        {showProjectedAttendance && !sessionsLoading && (
          <>
            <FontAwesomeIcon color={colors.dark} icon={faChild} />
            <span className="ml-2 d-none d-sm-block">
              {isSplitDay ? (
                <span>
                  {projectedMorningChildAttendance.length} (AM) / {projectedAfternoonChildAttendance.length} (PM)
                </span>
              ) : (
                projectedChildAttendance.length
              )}
            </span>
          </>
        )}
      </div>
      {loadingSchedule && (
        <div className="p-1 d-flex flex-column align-items-center">
          <Fade in appear>
            <div className="kt-schedules-class-shift">
              <Circle size="sm" />
              <Box height={32} width="90%" className="ml-1" />
            </div>
          </Fade>
        </div>
      )}
      {(shifts.length > 0 || canAddShifts) && !loadingSchedule && (
        <div className={shiftListClassNames}>
          {shifts.map((shift: IShift, idx: number) => (
            <ClassShift
              key={`class-shift-${shift.id}-${idx}`}
              className={calendarType === 'WEEK' ? 'my-1' : ' mx-1'}
              colorCode={
                shift.person?.classAssignments.find((p) => !p.endsAt || moment(p.endsAt).isAfter(moment()))
                  ?.colorCode ?? null
              }
              shift={shift}
              onClick={onShiftSelect}
            />
          ))}
          {fillerRows}
          {canAddShifts && (
            <Tooltip text="Create new shift" direction="bottom">
              <IconButtonCircle
                className="rounded-circle circle-md bg-pale-grey d-print-none"
                backgroundColor="transparent"
                icon={faPlusCircle}
                iconSize="lg"
                color={colors.secondary}
                onClick={() => onCreateNewShift()}
              />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default ClassScheduleDay;
