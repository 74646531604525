import { feeFields } from 'gql/fee/fields';

export const classFields = `
  id
  centerId
  name
  startsAt
  endsAt
  startTime
  endTime
  isPreSchoolProgram
  regulationId
  defaultSpaceId
  colorCode
  defaultSpace {
    id
    name
  }
  currentSpace {
    id
    name
  }
  regulation {
    id
    state
    startAge {
      age
      unit
    }
    endAge {
      age
      unit
    }
    ratioTeachers
    ratioChildren
    capacity
  }
  regulationOverride {
    startAge {
      age
      unit
    }
    endAge {
      age
      unit
    }
    youngestStartAge {
      age
      unit
    }
    oldestEndAge {
      age
      unit
    }
    ratioTeachers
    ratioChildren
  }
  staffAssignments {
    id
    nickname
    firstname
    lastname
    avatar {
      url
    }
    classAssignments {
      id
      startsAt
      endsAt
    }
  }
  archivedAt
  defaultCasualFeeId
  defaultPermanentFeeId
  fees {
    ${feeFields}
  }
  capacity
  glCode
`;

export const classCapacityOverrideFields = `
  id
  classId
  capacity
  date
`;

export const classClosuresFields = `
  id
  classId
  startDate
  endDate
  closureResolution
`;

export const activeClassFields = `
  id
  name
`;
