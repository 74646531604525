import { gql } from '@apollo/client';
import { createBusiness } from 'pages/Businesses/subroutes/BusinessProfiles/duck/actions';
import { useDispatch } from 'react-redux';
import { useMutation } from 'shared/apis/core';

interface ICreateEntityVariables {
  input: {
    name: string;
    phoneNumber: string;
    accountOwnerFirstName: string;
    accountOwnerLastName: string;
    accountOwnerEmail: string;
    address: {
      address1: string;
      address2?: string;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    timezone: string;
    dataType: DataType | null;
  };
}

interface ICreateEntityData {
  createEntity: IBusiness;
}

export const CREATE_ENTITY = gql`
  mutation ($input: CreateEntityInput!) {
    createEntity(input: $input) {
      id
      name
      phoneNumber
      avatar {
        url
      }
      address {
        address1
        address2
        city
        state
        postalCode
        country
      }
      timezone
    }
  }
`;

/**
 * Create a new center with spaces at an entity
 */
export const useCreateBusiness = () => {
  const dispatch = useDispatch();

  return useMutation<ICreateEntityData, ICreateEntityVariables>(CREATE_ENTITY, {
    onCompleted: (data) => {
      dispatch(createBusiness(data.createEntity));
    },
  });
};
