import React from 'react';
import { Row, Collapse } from 'react-bootstrap';
import ISCaseRawBasicColumn from './ISCaseRawBasicColumn';
import ISCaseRawChildrenColumn from './ISCaseRawChildrenColumn';
import ISCaseRawHoursColumn from './ISCaseRawHoursColumn';
interface IProps {
  data: IISCase;
  open: boolean;
}

const ISCaseRawDetails: React.FC<IProps> = ({ data, open }) => {
  return (
    <Collapse in={open}>
      <Row className="raw-is-case mt-6 mb-5">
        <ISCaseRawBasicColumn data={data} />
        <ISCaseRawHoursColumn data={data} />
        <ISCaseRawChildrenColumn data={data} />
      </Row>
    </Collapse>
  );
};

export default ISCaseRawDetails;
