export interface IDataType extends IEnumDefinition {
  color: string;
}

export const DataTypeEnum: Record<string, DataType> = {
  SANDBOX: 'SANDBOX',
  PROD: 'PROD',
  INTERNAL_TEST: 'INTERNAL_TEST',
  CHURNED: 'CHURNED',
};

export const dataType: Record<DataType, IDataType> = {
  PROD: { value: DataTypeEnum.PROD, label: 'PRODUCTION', color: 'seafoam-green' },
  SANDBOX: { value: DataTypeEnum.SANDBOX, label: 'SANDBOX', color: 'orange' },
  INTERNAL_TEST: { value: DataTypeEnum.INTERNAL_TEST, label: 'TEST', color: 'steel-blue' },
  CHURNED: { value: DataTypeEnum.CHURNED, label: 'CHURNED', color: 'yellow' },
};
