import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { orderBy } from 'lodash';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import { Col, Row } from 'shared/components/Layout';
import TextInput, { NumberInput } from 'shared/components/TextInput';
import {
  faPlus,
  faPercent,
  faDollarSign,
  faUndo,
  faTrashAlt,
  faMinus,
  faPencilAlt,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { IconButton, IconButtonCircle } from 'shared/components/Buttons';
import Select from 'shared/components/Select';
import MultipleCenterSelect from 'shared/components/Select/MultipleCenterSelect';
import DateInput from 'shared/components/DateInput';
import Checkbox from 'shared/components/Checkbox';
import useFormatDate from 'shared/hooks/useFormatDate';
import { useTranslation } from 'react-i18next';
import { useUpdateDiscount } from 'gql/discount/mutations';
import { showToast } from 'shared/components/Toast';
import { useDispatch } from 'react-redux';
import { updateDiscountSuccess } from './duck/actions';
import colors from '_colors.module.scss';
import GlCodeInput from 'shared/components/GlCodeInput';
import useIsGlCodeInputValid from 'shared/hooks/useIsGlCodeInputValid';
import { omitTypename } from 'shared/util/object';
import { isRegion } from 'shared/util/region';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IEditDiscountShape {
  id: string;
  name: string;
  glCode: string | null;
  centerIds: string[] | null;
  description: string;
  type: DiscountType;
  appliesTo: DiscountAppliesToType;
  schedules: IDiscountScheduleUpdateShape[];
  glCodeMapping?: IGLCodeMapping;
}

interface IDiscountScheduleUpdateShape {
  id: string | null;
  start: string;
  end: string | null;
  amount: number;
  amountType: DiscountAmountType;
  edited: boolean;
  deleted: boolean;
}

interface IProps {
  isOpen: boolean;
  discount: IDiscount;
  onClose: () => void;
}

const EditDiscountModal: React.FC<IProps> = ({ isOpen, discount, onClose, ...props }) => {
  const isAuRegion = isRegion('AU');
  const { k2AuDiscounts } = useFlags();
  const { t } = useTranslation(['billing']);
  const dispatch = useDispatch();
  const [isCreatingNewSchedule, setIsCreatingNewSchedule] = useState<boolean>(false);
  const [activeDiscountScheduleIndex, setIsActiveDiscountScheduleIndex] = useState<number>(0);
  const [formData, setFormData] = useState<IEditDiscountShape>({
    id: discount.id,
    name: discount.name,
    glCode: discount.glCode,
    centerIds: discount.centerIds,
    description: discount.description,
    appliesTo: discount.appliesTo,
    type: discount.type,
    schedules: orderBy(
      discount.schedules.map((schedule) => ({
        id: schedule.id,
        start: schedule.startDate,
        end: schedule.endDate,
        amount: schedule.amountType === 'PERCENTAGE' ? schedule.amount * 100 : schedule.amount, // percentage discounts are stored as decimal values but we need to display whole numbers
        amountType: schedule.amountType,
        edited: false,
        deleted: false,
      })),
      (schedule) => schedule.start,
      'desc'
    ),
    glCodeMapping: discount.glCodeMapping,
  });
  const selectedDiscountSchedule =
    activeDiscountScheduleIndex !== null ? formData.schedules[activeDiscountScheduleIndex] : null;
  const formatDate = useFormatDate();
  const [updateDiscountFn, { loading: updateDiscountLoading }] = useUpdateDiscount({
    onCompleted: (result) => {
      showToast(t('billing:discounts.edit-modal.success-toast-message'), 'success');
      dispatch(updateDiscountSuccess(result.updateDiscount));
      handleClose();
    },
    onError: (err) => {
      showToast(t('billing:discounts.edit-modal.error-toast-message'), 'error');
    },
  });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    updateDiscountFn({
      variables: {
        input: {
          id: formData.id,
          businessId: discount.businessId,
          centerIds: formData.centerIds,
          glCode: formData.glCode,
          name: formData.name,
          description: formData.description,
          schedules: formData.schedules
            .filter((schedule) => !schedule.deleted)
            .map((schedule) => ({
              id: schedule.id,
              amount:
                schedule.amountType === 'PERCENTAGE'
                  ? schedule.amount / 100 // local state is saving these as whole numbers but they need to be saved in the db as decimal values
                  : schedule.amount,
              amountType: schedule.amountType,
              discountId: formData.id,
              startDate: schedule.start,
              endDate: schedule.end,
            })),
          glCodeMapping: formData.glCodeMapping && omitTypename(formData.glCodeMapping),
        },
      },
    });
  }, [updateDiscountFn, formData, discount]);

  const addNewDiscountSchedule = useCallback((startDate: string) => {
    const discountScheduleForDate = {
      id: null,
      start: startDate,
      end: null,
      amount: 0,
      amountType: 'FLAT',
      edited: false,
      deleted: false,
    } as IDiscountScheduleUpdateShape;

    setIsCreatingNewSchedule(false);
    setFormData((prev) => {
      let sortedSchedules = orderBy([...prev.schedules, discountScheduleForDate], (schedule) => schedule.start, 'desc');

      const newlyCreatedScheduleIdx = sortedSchedules.map((s) => s.start).indexOf(startDate);

      if (newlyCreatedScheduleIdx >= 0) {
        const scheduleToEndIdx = newlyCreatedScheduleIdx + 1;

        // if a new schedule has been added the previous one must be ended if it is ongoing
        sortedSchedules = sortedSchedules.map((schedule, idx) => ({
          ...schedule,
          end:
            scheduleToEndIdx === idx && schedule.end === null
              ? moment(startDate).subtract(1, 'day').toISOString()
              : schedule.end,
        }));
      }

      return {
        ...prev,
        schedules: sortedSchedules,
      };
    });
  }, []);

  const updateDiscountScheduleLocally = useCallback(
    (index: number, name: keyof IDiscountScheduleUpdateShape, value: any) => {
      setFormData((prev) => ({
        ...prev,
        schedules: orderBy(
          prev.schedules.map((schedule, idx) =>
            idx === index ? { ...schedule, [name]: value, edited: true } : schedule
          ),
          (schedule) => schedule.start,
          'desc'
        ),
      }));
    },
    []
  );

  const undoLocalChangesForDiscountSchedule = useCallback(
    (schedule: IDiscountScheduleUpdateShape) => {
      const originalDiscountSchedule = discount.schedules.find((s) => s.id === schedule.id);

      if (originalDiscountSchedule) {
        setFormData((prev) => ({
          ...prev,
          schedules: prev.schedules.map((s, idx) =>
            s.id === schedule.id
              ? {
                  id: originalDiscountSchedule.id,
                  start: originalDiscountSchedule.startDate,
                  end: originalDiscountSchedule.endDate,
                  amount: originalDiscountSchedule.amount,
                  amountType: originalDiscountSchedule.amountType,
                  edited: false,
                  deleted: false,
                }
              : s
          ),
        }));
      }
    },
    [discount]
  );

  const removeSchedule = useCallback(() => {
    if (selectedDiscountSchedule?.id) {
      setFormData((prev) => ({
        ...prev,
        schedules: orderBy(
          prev.schedules.map((schedule, idx) =>
            idx === activeDiscountScheduleIndex ? { ...schedule, deleted: true } : schedule
          ),
          (schedule) => schedule.start,
          'desc'
        ),
      }));
    } else {
      // if the schedule being removed hasn't been saved in the database we can remove it entirely
      setFormData((prev) => ({
        ...prev,
        schedules: orderBy(
          prev.schedules.filter((schedule, idx) => idx !== activeDiscountScheduleIndex),
          (schedule) => schedule.start,
          'desc'
        ),
      }));
    }
  }, [activeDiscountScheduleIndex, selectedDiscountSchedule]);

  const isDiscountScheduleEdited = useCallback((schedule: IDiscountScheduleUpdateShape): boolean => {
    // a schedule must first exist to be able to be edited hence why we include the id check
    return Boolean(schedule.id) && schedule.edited;
  }, []);

  const hasOverlappingSchedules = useCallback((schedules: IDiscountScheduleUpdateShape[]): boolean => {
    let hasOverlaps = false;
    const sortedDesc = orderBy(schedules, (schedule) => schedule.start, 'desc');

    for (let i = 0; i < sortedDesc.length; i++) {
      const schedule = sortedDesc[i];

      for (let j = i + 1; j < sortedDesc.length; j++) {
        const previousSchedule = sortedDesc[j];
        const previousScheduleIsOngoing = !previousSchedule.end;
        const scheduleStartsBeforePreviousScheduleEnds =
          previousSchedule.end && moment(schedule.start).isSameOrBefore(moment(previousSchedule.end));
        const scheduleEndsBeforePreviousScheduleBegins =
          schedule.end && moment(schedule.end).isSameOrBefore(moment(previousSchedule.start));

        if (
          previousScheduleIsOngoing ||
          scheduleStartsBeforePreviousScheduleEnds ||
          scheduleEndsBeforePreviousScheduleBegins
        ) {
          hasOverlaps = true;
          break;
        }
      }

      if (hasOverlaps) break;
    }

    return hasOverlaps;
  }, []);

  const isDayDisabledFromScheduleDateSelection = useCallback(
    (day: moment.Moment): boolean => {
      const sortedDesc = orderBy(formData.schedules, (schedule) => schedule.start, 'desc');

      for (let i = 0; i < sortedDesc.length; i++) {
        const schedule = sortedDesc[i];
        const overlapsSchedule =
          Boolean(schedule.end) &&
          day.isSameOrAfter(moment(schedule.start)) &&
          day.isSameOrBefore(moment(schedule.end));

        if (overlapsSchedule) {
          return true;
        }
      }

      return false;
    },
    [formData.schedules]
  );

  const { isGlCodeMandatory, isGlCodeInputValid } = useIsGlCodeInputValid(formData.glCode, formData.glCodeMapping);

  var isPercentageValid = useMemo(() => {
    var hasInvalidPercentage = formData.schedules.some(
      (a) =>
        a.amountType === 'PERCENTAGE' &&
        (a.amount < 0 || a.amount > (isAuRegion && discount.type === 'RECURRING' ? 95 : 100))
    );
    return !hasInvalidPercentage;
  }, [discount.type, formData.schedules, isAuRegion]);

  const formDisabled: boolean =
    !formData.name ||
    formData.schedules.some((schedule) => !schedule.amount || !schedule.amountType || !schedule.start) ||
    hasOverlappingSchedules(formData.schedules) ||
    !isPercentageValid ||
    !isGlCodeInputValid;

  return (
    <CenteredModal
      size="xl"
      title={t('billing:discounts.edit-modal.title')}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice={t('billing:discounts.modal-inputs.primary-action-button')}
      secondaryChoice={t('billing:discounts.modal-inputs.secondary-action-button')}
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: formDisabled, loading: updateDiscountLoading }}
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      <Row noGutters align="stretch">
        <Col md={12} lg={3} className="mr-4">
          <Row>
            <Col>
              <TextInput
                required
                label={t('billing:discounts.modal-inputs.name-input-label')}
                value={formData.name}
                onChange={(value) => setFormData((prev) => ({ ...prev, name: value }))}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <GlCodeInput<IEditDiscountShape>
                areaType="DISCOUNT"
                legacyValue={formData.glCode}
                glCodeMapping={formData.glCodeMapping}
                updateFn={setFormData}
                required={isGlCodeMandatory}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <MultipleCenterSelect
                useNullForAllOption
                selectedCenterIds={formData.centerIds}
                onSelect={(centerIds) => setFormData((prev) => ({ ...prev, centerIds }))}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                disabled
                label={t('billing:discounts.modal-inputs.discount-type-input-label')}
                value={formData.type}
                options={[
                  { label: t('billing:discounts.modal-inputs.manual-discount-type'), value: 'MANUAL' },
                  { label: t('billing:discounts.modal-inputs.recurring-discount-type'), value: 'RECURRING' },
                ]}
                onChange={() => {}}
              />
            </Col>
            <Col>
              <Select
                disabled
                label={t('billing:discounts.modal-inputs.applies-to-input-label')}
                value={formData.appliesTo}
                options={[
                  { label: t('billing:discounts.modal-inputs.balance-applied-to'), value: 'BALANCE' },
                  { label: t('billing:discounts.modal-inputs.billing-cycle-applied-to'), value: 'BILLING_CYCLE' },
                  { label: t('billing:discounts.modal-inputs.fee-applied-to'), value: 'SESSION' },
                  { label: t('billing:discounts.modal-inputs.session-gap-applied-to'), value: 'SESSION_GAP' },
                ]}
                onChange={() => {}}
              />
            </Col>
          </Row>
          {isRegion('AU') && (
            <Row>
              <Checkbox
                label={t('billing:discounts.modal-inputs.include-zero-subsidy')}
                value={discount.applySessionGapDiscountIfNoSubsidy}
                disabled
                className="mb-4 ml-2"
              />
            </Row>
          )}
          <Row>
            <Col>
              <TextInput
                label={t('billing:discounts.modal-inputs.description-input-label')}
                value={formData.description}
                onChange={(value) => setFormData((prev) => ({ ...prev, description: value }))}
                as="textarea"
                rows={3}
              />
            </Col>
          </Row>
        </Col>
        <Col className="border rounded p-4">
          <Row noGutters align="stretch" className="h-100">
            <Col xs={3} align="stretch" className="mr-4 h-100 flex-column d-flex">
              <label>Schedule</label>
              <div className="border rounded d-flex flex-column flex-grow-1">
                {isCreatingNewSchedule && (
                  <div className="d-flex align-items-center p-2">
                    <DateInput
                      onDateSelect={addNewDiscountSchedule}
                      className="kt-date-input-no-max-width mr-4 mb-0"
                      placeholder="Start"
                      isOutsideRange={isDayDisabledFromScheduleDateSelection}
                    />
                    <IconButton
                      icon={faUndo}
                      onClick={() => setIsCreatingNewSchedule(false)}
                      tooltipText={t('billing:discounts.edit-modal.undo-button-tooltip')}
                    />
                  </div>
                )}
                <ul className="list-unstyled w-100">
                  {formData.schedules.map((discountSchedule: IDiscountScheduleUpdateShape, idx: number) => (
                    <li
                      key={`discount-schedule-${discountSchedule.id}-${idx}`}
                      onClick={() => setIsActiveDiscountScheduleIndex(idx)}
                      className={classnames({
                        'px-4 py-2 d-flex align-items-center': true,
                        'cursor-pointer': !isCreatingNewSchedule,
                        'font-weight-semi-bold': idx === activeDiscountScheduleIndex,
                        'bg-pale-grey':
                          idx === activeDiscountScheduleIndex &&
                          !discountSchedule.deleted &&
                          discountSchedule.id &&
                          !isDiscountScheduleEdited(discountSchedule),
                        'bg-danger-10': discountSchedule.deleted,
                        'bg-success-10': !discountSchedule.id,
                        'bg-warning-10': !discountSchedule.deleted && isDiscountScheduleEdited(discountSchedule),
                      })}
                    >
                      {!discountSchedule.deleted && idx === activeDiscountScheduleIndex && (
                        <IconButton
                          icon={faTrashAlt}
                          className="mr-2"
                          iconSize="1x"
                          tooltipText={t('billing:discounts.edit-modal.delete-schedule-tooltip')}
                          onClick={() => removeSchedule()}
                        />
                      )}
                      <div>{formatDate(discountSchedule.start)}</div>
                      {(discountSchedule.deleted ||
                        !discountSchedule.id ||
                        isDiscountScheduleEdited(discountSchedule)) && (
                        <FontAwesomeIcon
                          icon={discountSchedule.deleted ? faMinus : !discountSchedule.id ? faPlus : faPencilAlt}
                          color={
                            discountSchedule.deleted
                              ? colors.danger
                              : !discountSchedule.id
                              ? colors.success
                              : colors.warning
                          }
                          size="xs"
                          className="ml-2"
                        />
                      )}
                      {(discountSchedule.deleted || isDiscountScheduleEdited(discountSchedule)) && (
                        <IconButton
                          icon={faUndo}
                          className="ml-auto"
                          iconSize="1x"
                          onClick={() => undoLocalChangesForDiscountSchedule(discountSchedule)}
                          tooltipText={t('billing:discounts.edit-modal.undo-button-tooltip')}
                        />
                      )}
                    </li>
                  ))}
                </ul>
                {!isCreatingNewSchedule && (
                  <IconButtonCircle
                    icon={faPlus}
                    color="white"
                    backgroundColor="secondary"
                    className="mt-auto ml-auto mr-4 mb-4"
                    onClick={() => setIsCreatingNewSchedule(true)}
                    tooltipText={t('billing:discounts.edit-modal.add-schedule-button-tooltip')}
                  />
                )}
              </div>
            </Col>
            {!isCreatingNewSchedule && selectedDiscountSchedule && (
              <Col>
                <Row>
                  <Col>
                    <DateInput
                      required
                      label={t('billing:discounts.modal-inputs.start-date-input-label')}
                      date={selectedDiscountSchedule.start}
                      onDateSelect={(date) => updateDiscountScheduleLocally(activeDiscountScheduleIndex, 'start', date)}
                      className="kt-date-input-no-max-width"
                      isOutsideRange={isDayDisabledFromScheduleDateSelection}
                    />
                  </Col>
                  <Col>
                    <DateInput
                      label={t('billing:discounts.modal-inputs.end-date-input-label')}
                      date={selectedDiscountSchedule.end}
                      onDateSelect={(date) => updateDiscountScheduleLocally(activeDiscountScheduleIndex, 'end', date)}
                      className="kt-date-input-no-max-width"
                      disabled={selectedDiscountSchedule.end === null}
                      isOutsideRange={isDayDisabledFromScheduleDateSelection}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <NumberInput
                      required
                      key={`amount-input-${activeDiscountScheduleIndex}-${selectedDiscountSchedule.amountType}`}
                      label={t('billing:discounts.modal-inputs.amount-input-label')}
                      value={selectedDiscountSchedule.amount}
                      onChange={(value) => updateDiscountScheduleLocally(activeDiscountScheduleIndex, 'amount', value)}
                      step="0.01"
                      numberFormat={{
                        thousandSeparator: true,
                        decimalScale: 2,
                        fixedDecimalScale: selectedDiscountSchedule.amountType === 'FLAT',
                        max:
                          selectedDiscountSchedule.amountType === 'PERCENTAGE'
                            ? isAuRegion && discount.type === 'RECURRING'
                              ? 95
                              : 100
                            : undefined,
                      }}
                      appendNode={
                        <>
                          {(!isAuRegion || k2AuDiscounts) && (
                            <Button
                              onClick={() =>
                                updateDiscountScheduleLocally(activeDiscountScheduleIndex, 'amountType', 'FLAT')
                              }
                              variant={
                                selectedDiscountSchedule.amountType === 'FLAT' ? 'secondary' : 'outline-secondary'
                              }
                              className="px-3 h-100"
                            >
                              <FontAwesomeIcon icon={faDollarSign} />
                            </Button>
                          )}
                          <Button
                            onClick={() =>
                              updateDiscountScheduleLocally(activeDiscountScheduleIndex, 'amountType', 'PERCENTAGE')
                            }
                            variant={
                              selectedDiscountSchedule.amountType === 'PERCENTAGE' ? 'secondary' : 'outline-secondary'
                            }
                            className="px-3 h-100"
                          >
                            <FontAwesomeIcon icon={faPercent} />
                          </Button>
                        </>
                      }
                    />
                  </Col>
                  <Col>
                    {/* empty column */}
                    <div />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        {hasOverlappingSchedules(formData.schedules) && (
          <div className="text-danger">{t('billing:discounts.edit-modal.overlapping-schedules-error-text')}</div>
        )}
      </Row>
    </CenteredModal>
  );
};

export default EditDiscountModal;
