import * as PowerBI from 'powerbi-client';
const REPORT_HEIGHT = 1050;
const getWindow = () => window as any;

export const downloadAsPdf = async (pdfFileName: string, report: PowerBI.Report) => {
  const title = getWindow().document.title;
  getWindow().document.title = pdfFileName;
  await report.print();
  getWindow().document.title = title;
};

export const getReportContainer = () => getWindow().document.getElementsByClassName('powerbi')[0];

export const handleError = (error: any) => {
  const { detail } = error;

  if (
    detail &&
    (detail.message === 'TokenExpired' || detail.message === 'ExplorationContainer_FailedToLoadModel_DefaultDetails')
  ) {
    setTimeout(() => window.location.reload(), 8000);
  }
};

export const handleReportLoaded = (report: PowerBI.Report) => {
  (report as any).loaded = true;

  handleWindowResize(report);

  getReportContainer().addEventListener('touchstart', (e) => {
    if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return;
    e.preventDefault();
  });
};

export const handleWindowResize = (report: PowerBI.Report) => {
  if (!report) return;

  report.getZoom().then((zoom) => {
    const height = REPORT_HEIGHT * zoom + 100;
    getReportContainer().firstElementChild.style = `width:100%;height:${height}px`;
  });
};

// TODO : remove reference of the report page. issue with report not loading the right
export const defaultConfig = (token: string, reportId: string) => {
  return {
    type: 'report',
    tokenType: PowerBI.models.TokenType.Embed,
    accessToken: token,
    embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}`,
    id: reportId,
    permissions: PowerBI.models.Permissions.Read,
    settings: {
      // turn this on in dev
      hideErrors: true,
      navContentPaneEnabled: false,
      background: PowerBI.models.BackgroundType.Transparent,
      layoutType: PowerBI.models.LayoutType.Custom,
      customLayout: {
        displayOption: PowerBI.models.DisplayOption.FitToWidth,
      },
      panes: {
        filters: {
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  };
};
