import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Pubnub from 'pubnub';
import { ActivityType, ChannelType, useCreateChatActivityMutation, useGetChatAuthQuery } from 'generated/graphql';
import Input from 'shared/components/TextInput/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faSmile } from '@fortawesome/pro-light-svg-icons';
import ChannelCard from './ChannelCard';
import MessageBubble from './MessageBubble';
import { v4 as uuidv4 } from 'uuid';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useOuterClick } from '@pubnub/react-chat-components';

interface IProps {
  pubnub: Pubnub;
  channelType: ChannelType;
  isOpen: boolean;
  onClose: () => void;
  handleBack: () => void;
  selectedChannels: ICenter[] | IAccount[];
}

interface MessageProperties {
  text: string;
  createdAt: string;
}

const MultiChannelMessageModal: React.FC<IProps> = ({
  isOpen,
  channelType,
  onClose,
  handleBack,
  selectedChannels,
  pubnub,
}) => {
  const { t } = useTranslation(['translation', 'comms']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const pickerRef = useOuterClick((event) => {
    if ((event.target as Element).closest('.pn-msg__reactions-toggle')) return;
    setShowEmojiPicker(false);
  });

  const [messageText, setMessageText] = useState('');
  const [messageList, setmessageList] = useState<MessageProperties[]>([]);
  const [channels, setChannels] = useState(
    selectedChannels.map((c) => {
      return {
        ...c,
        channelIdentifier: '',
        loading: false,
        sent: false,
        resend: false,
      };
    })
  );

  const [createChatActivity, { data, loading: createActivityLoading, error: error }] = useCreateChatActivityMutation({
    onError: (err) => {},
    onCompleted: (response) => {},
  });

  const {
    data: getChatAuthData,
    loading: loadingSubscribedChannels,
    error: subscribedChannelsError,
  } = useGetChatAuthQuery({
    variables: {
      businessId: businessId ?? '',
      returnWildcardChannels: false,
      channelId: selectedChannels.map((c) => c.id),
    },
  });

  useEffect(() => {
    setChannels(
      selectedChannels.map((c) => {
        const authChannelIdentifier = getChatAuthData?.getChatAuth
          ? getChatAuthData?.getChatAuth.find((a) => a?.channelId === c.id)?.channelIdentifier
          : undefined;
        return {
          ...c,
          channelIdentifier: authChannelIdentifier,
          loading: false,
          sent: false,
          resend: false,
        };
      })
    );
  }, [getChatAuthData, selectedChannels]);

  const handleSelectEmoji = (emoji: { native: string }) => {
    if (!('native' in emoji)) return;
    setMessageText((text) => text + emoji.native);
    setShowEmojiPicker(false);
  };

  const handleSendMultipleMessage = (channel?: any, resend?: boolean) => {
    if (!channel && !resend) {
      setmessageList((prev) => [
        ...prev,
        { text: messageText, createdAt: moment().utc().tz(timezone).format().toString() },
      ]);
    }

    const channelsToSend = channel ? [channel] : resend ? channels.filter((c) => c.resend) : channels;

    if (channel) {
      setChannels((prev) => {
        return prev.map((c) => {
          if ((c.channelId = channel.channelId)) return { ...c, loading: true, sent: false, resend: false };
          else return { ...c };
        });
      });
    } else {
      setChannels((prev) => {
        return prev.map((c) => {
          return { ...c, loading: true, sent: false, resend: false };
        });
      });
    }

    channelsToSend.forEach((channel) => {
      const messageId = uuidv4();
      pubnub
        .publish({
          channel: channel.channelIdentifier,
          message: {
            text: messageText,
            createdAt: moment().utc().tz(timezone).format().toString(),
            id: messageId,
            sender: {
              id: user?.id,
              name: `${user?.firstname} ${user?.lastname}`,
              profileUrl: user?.avatar ? user?.avatar.url : undefined,
            },
          },
          storeInHistory: true,
        })
        .then((response) => {
          setChannels((prev) =>
            prev.map((c) => {
              if (c.channelId === channel.channelId) return { ...c, loading: false, sent: true, resend: false };
              else return c;
            })
          );
          createChatActivity({
            variables: {
              businessId: businessId ?? '',
              input: {
                businessId: businessId ?? '',
                channelType: channelType,
                channelId: channel.id,
                personId: user?.id,
                activityType: ActivityType.MessageSent,
                activityTime: parseInt(response.timetoken.toString()),
                pubNubMessageId: messageId,
                messageContent: messageText,
                channelWasRead: true,
                attachmentIds: null,
              },
            },
          });
        })
        .catch((error) => {
          setChannels((prev) =>
            prev.map((c) => {
              if (c.channelId === channel.channelId) return { ...c, loading: false, sent: false, resend: true };
              else return c;
            })
          );
        });
    });

    setMessageText('');
  };

  // icon from pubnub has to be hardcoded for consitency in UI
  const emojiIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path
        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
        fill="currentColor"
        vector-effect="non-scaling-stroke"
      ></path>
    </svg>
  );

  return (
    <Modal
      centered
      backdrop="static"
      show={isOpen}
      onHide={() => {
        onClose();
        setMessageText('');
        setmessageList([]);
      }}
      scrollable={true}
      size="xl"
      className="multi-channel-message"
    >
      <Modal.Header closeButton className="px-6 py-4">
        <Modal.Title as="h5">{t('comms:multi-channel-modal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-4 px-4 gap-waiver-confirmation-container">
        <Row>
          <Col xs={12} lg={4}>
            <h5>Channels: {`(${channels.length})`}</h5>
            <div className={'channels-container'}>
              {channels.map((channel, key) => {
                return (
                  <div key={key}>
                    <ChannelCard
                      channelKey={key}
                      channelName={channel.name ?? channel.centerName}
                      className={'pn-channel'}
                      channelId={channel.id}
                      account={channel}
                      sent={channel.sent}
                      resend={channel.resend}
                      loading={channel.loading}
                      hideLastActive={true}
                    />
                  </div>
                );
              })}
            </div>
          </Col>
          <Col xs={12} lg={8} className={'messages'}>
            <h5>Message</h5>
            <div className={'multi-messageList-container'}>
              <div className={'multi-messageList'}>
                {messageList.map((message, key) => {
                  return (
                    <div className={'msg-container'} key={key}>
                      <MessageBubble
                        messageText={message.text}
                        avatarUrl={user?.avatar ? user?.avatar.url ?? '' : ''}
                        senderName={`${user?.firstname} ${user?.lastname}`}
                        createdAt={message.createdAt}
                        attachments={[]}
                        onDownloadAttachment={() => {}}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="input-container">
                <div className="d-flex mb-4 align-items-center">
                  <Input
                    value={messageText}
                    disabled={channels.some((c) => c.resend === true)}
                    onChange={(value, name) => setMessageText(value)}
                    placeholder={'Type here...'}
                  />
                  <div className="emoji-picker" ref={pickerRef}>
                    {showEmojiPicker && (
                      <div className="">
                        <Picker data={emojiData} theme={'light'} onEmojiSelect={handleSelectEmoji} />
                      </div>
                    )}
                    <div className="ml-4 emoji-picker-btn" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                      {emojiIcon}
                    </div>
                  </div>
                </div>
                <div className={'buttons-container'}>
                  {channels.some((c) => c.resend === true) && (
                    <Button
                      variant="primary"
                      className="ml-3"
                      onClick={() => {
                        handleSendMultipleMessage(undefined, true);
                      }}
                    >
                      {t('comms:multi-channel-modal.resend-btn')}
                    </Button>
                  )}
                  <Button
                    variant="primary"
                    className="ml-3"
                    disabled={messageText.length <= 0 || channels.some((c) => c.resend === true)}
                    onClick={() => {
                      handleSendMultipleMessage();
                    }}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                    <span>
                      {channelType === ChannelType.Account ? t('comms:send-message') : t('comms:send-announcement')}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="px-4 py-2">
        <Button
          variant="light"
          className="mr-3"
          onClick={() => {
            setMessageText('');
            setmessageList([]);
            handleBack();
          }}
        >
          {t('comms:multi-channel-modal.back-btn')}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setChannels([]);
            setMessageText('');
            setmessageList([]);
            onClose();
          }}
        >
          {t('comms:multi-channel-modal.close-btn')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MultiChannelMessageModal;
