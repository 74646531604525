import React from 'react';
import { Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import { CircleDeleteButton } from 'shared/components/Buttons';

interface IProps {
  serviceFee: IServiceFee;
  serviceFeeRule: IServiceFeeRule;
  parentGroup: IServiceFeeRuleGroup;
  level: number;
  onAdd: (parentGroup: IServiceFeeRuleGroup) => void;
  onUpdate: (serviceFee: IServiceFee, updateRule: IServiceFeeRule, parentGroup: IServiceFeeRuleGroup) => void;
  onDelete: (deleteRule: IServiceFeeRule, parentGroup: IServiceFeeRuleGroup) => void;
}

const ServiceFeeRule: React.FC<IProps> = ({ serviceFee, serviceFeeRule, parentGroup, onUpdate, onDelete }) => {
  const conditionTypeOptions: { value: ConditionType; label: string }[] = [
    { value: 'EQUAL', label: 'is equal to' },
    { value: 'NOT_EQUAL', label: 'is not equal to' },
    { value: 'GREATER_THAN', label: 'is greater than' },
    { value: 'GREATER_THAN_EQUAL', label: 'is greater than or equal to' },
    { value: 'LESS_THAN', label: 'is less than' },
    { value: 'LESS_THAN_EQUAL', label: 'is less than or equal to' },
  ];

  return (
    <section id="service-fee-rule-section">
      <Row className="mb-4" noGutters>
        <span className="mr-2">Amount </span>
        <Select
          className="mb-0 flex-grow-0"
          options={conditionTypeOptions}
          getOptionLabel={(g) => g.label}
          getOptionValue={(g) => g.value}
          value={serviceFeeRule.condition}
          onChange={(option) => onUpdate(serviceFee, { ...serviceFeeRule, condition: option.value }, parentGroup)}
        />
        <span className="mr-2"> </span>
        <CurrencyInput2
          required
          type="number"
          className="mb-0 flex-grow-0 border-radius-0"
          id="rule-value-input"
          name="rule-value"
          value={Number(serviceFeeRule.value).toFixed(2)}
          onChange={(value: string) =>
            onUpdate(serviceFee, { ...serviceFeeRule, value: value.toString() }, parentGroup)
          }
        />
        <CircleDeleteButton onClick={() => onDelete(serviceFeeRule, parentGroup)} variant="light" className="ml-auto" />
      </Row>
    </section>
  );
};

export default ServiceFeeRule;
