import moment from 'moment';
import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Checkbox from 'shared/components/Checkbox';
import { HorizontalDivider } from 'shared/components/Dividers';
import { Col, Row } from 'shared/components/Layout';
import { ccsTypeEnum } from 'shared/constants/enums/ccssEnums';
import ActionDropdown from '../ActionDropdown/ActionDropdown';

interface IProps {
  enrolment: ICcsEnrolment;
  child: IAccountChild | IChild;
  contact?: IContact;
  renderPreviousEnrolments: () => React.ReactElement;
  onEditClick?: (row: any) => void;
  onUnlinkClick?: (row: any) => void;
  showEdit: boolean;
}

const dateFormat = 'DD/MM/YYYY';

const CcsEnrolmentCard: React.FC<IProps> = ({
  enrolment,
  child,
  contact,
  renderPreviousEnrolments,
  onEditClick,
  onUnlinkClick,
  showEdit,
}) => {
  const signingParty = enrolment.signingPartyFirstName
    ? `${enrolment.signingPartyFirstName} ${enrolment.signingPartyLastName}`
    : enrolment.signingPartyOrganisation;

  let enrolmentEndDateCol;
  let recordEffectiveEndDateCol;
  let endDatesRow;

  if (enrolment.endDate) {
    enrolmentEndDateCol = (
      <Col md="3">
        <HeadingCaption heading="Enrolment End Date" caption={moment(enrolment.endDate).format(dateFormat)} />
      </Col>
    );
  }

  if (enrolment.recordEffectiveEndDate) {
    recordEffectiveEndDateCol = (
      <Col md="3">
        <HeadingCaption
          heading="Record Effective End Date"
          caption={moment(enrolment.recordEffectiveEndDate).format(dateFormat)}
        />
      </Col>
    );
  }

  if (enrolmentEndDateCol || recordEffectiveEndDateCol) {
    endDatesRow = (
      <Row align="start" className="mb-4">
        {enrolmentEndDateCol}
        {recordEffectiveEndDateCol}
      </Row>
    );
  }

  return (
    <div className="kt-account-ccs-enrolment-card ">
      <Row align="end" className="float-right mb-4 mr-2">
        <ActionDropdown
          actions={[
            {
              label: 'Edit',
              disabled: !showEdit,
              onClick: () => (onEditClick ? onEditClick(enrolment) : {}), //TODO GET WORKING
            },
            {
              label: 'Remove',
              disabled: !showEdit,
              onClick: () => (onUnlinkClick ? onUnlinkClick(enrolment) : {}), //TODO GET WORKING
            },
          ]}
        />
      </Row>
      <Row align="start" className="mb-4">
        <Col md="3">
          <HeadingCaption
            heading="Arrangement Start Date"
            caption={moment(enrolment.arrangementStartDate).format(dateFormat)}
          />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="Arrangement End Date"
            caption={enrolment.arrangementEndDate ? moment(enrolment.arrangementEndDate).format(dateFormat) : 'Ongoing'}
          />
        </Col>
        <Col md="3">
          <HeadingCaption heading="Reason for late submission" caption={enrolment.lateSubmissionReason ?? 'N/A'} />
        </Col>
      </Row>
      {endDatesRow}
      <Row align="start" className="mb-4">
        <Col>
          <h6>Contact Details</h6>
          <Details
            nameHeader={'Contact Name'}
            name={contact ? `${contact?.firstname} ${contact?.lastname}` : 'N/A'}
            dob={contact?.dob}
            crn={contact?.ccssAttributes?.crn ?? 'N/A'}
          />
        </Col>
        <Col>
          <h6>Child Details</h6>
          <Details
            nameHeader={'Child Name'}
            name={child.fullName}
            dob={child.dob}
            crn={child.ccssAttributes?.crn ?? 'N/A'}
          />
        </Col>
      </Row>
      <Row align="start" className="mb-4">
        <Col>
          <h6>CCS Details</h6>
          <Details
            nameHeader={'Contact Name'}
            name={`${enrolment.individualFirstName ?? ''} ${enrolment.individualLastName ?? ''}`}
            dob={enrolment.individualDateOfBirth}
            crn={enrolment.individualCrn ?? ''}
          />
        </Col>
        <Col>
          <h6>&nbsp;</h6>
          <Details
            nameHeader={'Child Name'}
            name={`${enrolment.childFirstName} ${enrolment.childLastName}`}
            dob={enrolment.childDateOfBirth}
            crn={enrolment.childCrn ?? ''}
          >
            <Row>
              <Col>
                <HeadingCaption heading="In State Care" caption={enrolment.childInStateCare ? 'Yes' : 'No'} />
              </Col>
            </Row>
          </Details>
        </Col>
      </Row>
      <Row align="start" className="mb-4">
        <Col>
          <h6>CCS Type</h6>
          <Form.Check className="d-flex align-items-center mb-2">
            <Form.Check.Input id="ccsType" type="radio" name="ccs-type-option" checked={true} disabled />
            <Form.Check.Label>
              {ccsTypeEnum.find((t) => t.value === enrolment.arrangementType)?.label ?? 'UNKNOWN'}
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col>
          <h6>CCS Reporting Details</h6>
          <Checkbox
            value={enrolment.sessionIndicator === 'R' || enrolment.sessionIndicator === 'B'}
            label="Routine Sessions"
          />
          <Checkbox
            value={enrolment.sessionIndicator === 'C' || enrolment.sessionIndicator === 'B'}
            label="Casual Sessions"
          />
        </Col>
        <Col>
          <h6>Signing Party</h6>
          <Form.Check className="d-flex align-items-center mb-2">
            {signingParty && (
              <>
                <Form.Check.Input id="asdf" type="radio" name="ccs-type-option" checked={true} disabled />
                <Form.Check.Label>{signingParty}</Form.Check.Label>
              </>
            )}
          </Form.Check>
        </Col>
      </Row>
      <Row>
        <Col>
          <HorizontalDivider />
        </Col>
      </Row>

      <Row>
        <PreviousEnrolments renderPreviousEnrolments={renderPreviousEnrolments} />
      </Row>
    </div>
  );
};

const Details = (props: {
  nameHeader: string;
  name: string;
  dob?: string;
  crn: string;
  children?: React.ReactNode;
}) => {
  return (
    <>
      <Row align="start">
        <Col md="4">
          <HeadingCaption heading={props.nameHeader} caption={props.name ?? 'N/A'} />
        </Col>
        <Col md="3">
          <HeadingCaption heading="D.O.B" caption={props.dob ? moment(props.dob).format(dateFormat) : 'N/A'} />
        </Col>
        <Col md="3">
          <HeadingCaption heading="CRN" caption={props.crn ?? 'N/A'} />
        </Col>
      </Row>
      {props.children}
    </>
  );
};

const HeadingCaption = (props: { heading: string; caption: string }) => {
  return (
    <>
      <div>
        <small>{props.heading}</small>
      </div>
      <div>
        <small className="caption">{props.caption}</small>
      </div>
    </>
  );
};

const PreviousEnrolments = (props: { renderPreviousEnrolments: () => ReactElement }) => {
  const RenderPreviousEnrolments = props.renderPreviousEnrolments;

  return (
    <Accordion
      className={'d-flex flex-column justify-content-center pb-4'}
      defaultActiveKey="-1"
      style={{ width: '100%' }}
    >
      <Accordion.Toggle eventKey="0" className="kt-account-ccs-tab-accordion-toggle">
        <div className="float-center">
          <span>View Previous Occurences</span>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse className="" eventKey="0">
        <RenderPreviousEnrolments />
      </Accordion.Collapse>
    </Accordion>
  );
};

export default CcsEnrolmentCard;
