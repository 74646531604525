import moment from 'moment/moment';
import { RateAmountType } from '../../generated/graphql';

export const discountMatchesActiveStatus = (discount: IDiscount, targetStatus: string) => {
  let status: string | null = null;
  discount.schedules.forEach((schedule) => {
    if (moment(schedule.startDate) <= moment() && (!schedule.endDate || moment(schedule.endDate) >= moment())) {
      status = 'Active';
      return false;
    }
  });

  if (status) {
    return status === targetStatus;
  }

  discount.schedules.forEach((schedule) => {
    if (moment(schedule.startDate) > moment()) {
      status = 'Scheduled';
      return false;
    }
  });

  if (status) {
    return status === targetStatus;
  }

  discount.schedules.forEach((schedule) => {
    if (schedule.endDate && moment(schedule.endDate) < moment()) {
      status = 'Ended';
      return false;
    }
  });

  return status === targetStatus;
};

export const discountMatchesSearchFilter = (discount: IDiscount, searchFilter: string) => {
  return (
    discount.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1 ||
    discount.schedules
      .map((schedule) => (schedule.amountType === RateAmountType.Percentage ? schedule.amount * 100 : schedule.amount))
      .join(',')
      .indexOf(searchFilter) !== -1
  );
};
