import React from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  space: ISpace;
  classes: IClass[] | undefined;
  deleteSpace: any;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteSpaceModal: React.FC<IProps> = ({ space, classes = [], deleteSpace, isOpen, onClose }) => {
  const hasAffiliatedClasses = !!classes?.length;
  const title = hasAffiliatedClasses ? 'Cannot Delete Space' : 'Delete Space?';
  const primaryChoice = hasAffiliatedClasses ? 'Okay' : 'Continue';
  const primaryCallback = hasAffiliatedClasses ? () => {} : deleteSpace;

  return (
    <ConfirmationModal
      title={title}
      show={isOpen}
      onHide={onClose}
      primaryChoice={primaryChoice}
      primaryCallback={primaryCallback}
    >
      {classes?.length && space ? (
        <div>
          <div>This space cannot be deleted because it is associated with the following classes:</div>
          <ul>
            {classes.map((c) => {
              return <li key={c.id}>{c.name}</li>;
            })}
          </ul>
        </div>
      ) : (
        <div>Are you sure you want to delete {space.name}?</div>
      )}
    </ConfirmationModal>
  );
};

export default DeleteSpaceModal;
