import React from 'react';
import { lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';

interface IProps {
  bookingWindow: IBookingWindowInput | null | undefined;
}

const BookingAbsenceCell: React.FC<IProps> = ({ bookingWindow }) => {
  const { t } = useTranslation();

  if (!bookingWindow) {
    return <>-</>;
  }
  const { quantity, unit } = bookingWindow;
  // @ts-ignore - t() is complaining about template literals
  const translatedUnit: string = quantity > 1 ? t(`spelling.${lowerCase(unit)}s`) : t(`spelling.${lowerCase(unit)}`);

  return <>{`${quantity} ${lowerCase(translatedUnit)} or less`}</>;
};

export default BookingAbsenceCell;
