import React from 'react';
import { Col, Row } from 'shared/components/Layout';
import { ICcsEntitlement } from 'shared/types/ccsEntitlement';

interface IProps {
  entitlement: ICcsEntitlement;
}

const CcsEntitlementCard: React.FC<IProps> = ({ entitlement }) => {
  return (
    <div>
      <Row align="start" className="mb-4">
        <Col md="3">
          <HeadingCaption heading="Absence Count" caption={entitlement.absenceCount.toString()} />
        </Col>
        <Col md="3">
          <HeadingCaption heading="Paid Absences" caption={entitlement.paidAbsences.toString()} />
        </Col>
        <Col md="3">
          <HeadingCaption heading="Unpaid Absences" caption={entitlement.unpaidAbsences.toString()} />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="Absences Available No Evidence"
            caption={entitlement.absencesAvailableNoEvidence.toString()}
          />
        </Col>
      </Row>
      <Row align="start" className="mb-4">
        <Col md="3">
          <HeadingCaption heading="Total Hours Per Fortnight" caption={entitlement.totalHoursPerFortnight.toString()} />
        </Col>

        <Col md="3">
          <HeadingCaption heading="Preschool Exemption" caption={entitlement.preschoolExemption ? 'Yes' : 'No'} />
        </Col>
        <Col md="3">
          <HeadingCaption heading="Withholding Percentage" caption={`${entitlement.withholdingPercentage}%`} />
        </Col>
        <Col md="3">
          <HeadingCaption
            heading="ACCS Hourly Rate Cap Increase Percentage"
            caption={`${entitlement.accsHourlyRateCapIncreasePercentage}%`}
          />
        </Col>
      </Row>
    </div>
  );
};

const HeadingCaption = (props: { heading: string; caption: string }) => {
  return (
    <>
      <div>
        <small>{props.heading}</small>
      </div>
      <div>
        <small className="caption">{props.caption}</small>
      </div>
    </>
  );
};

export default CcsEntitlementCard;
