import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, isEmpty } from 'lodash';
import TextInput from 'shared/components/TextInput';
import EnrollmentProgramsTable, {
  IEnrollmentProgramsTableProps,
} from '../../../components/EnrollmentProgramsTable/EnrollmentProgramsTable';
import useFormatDate from 'shared/hooks/useFormatDate';
import Switch from 'shared/components/Switch';
import moment from 'moment';

import { EnrollmentProgramGroup } from 'generated/graphql';
import { IEnrollmentProgram } from 'shared/types/enrollmentProgram';
interface IProps {
  tableProps: IEnrollmentProgramsTableProps;
  values?: EnrollmentProgramGroup;
  timezone: Timezone;
  handleFieldChange: (value: any, name: string) => void;
}
const ProgramGroupForm: React.FC<IProps> = ({ tableProps, values, timezone, handleFieldChange }) => {
  const { t } = useTranslation();
  const formatter = useFormatDate();
  return (
    <div>
      <TextInput name="name" label="Name" value={values?.name} required onChange={handleFieldChange} />
      <TextInput
        as="textarea"
        name="description"
        label="Description"
        value={values?.description}
        required
        onChange={handleFieldChange}
      />
      <label>{t('enrollment.program-groups.available-for-enrollments')}</label>
      <Switch
        checkedText="Yes"
        uncheckedText="No"
        value={values?.isActive ?? false}
        onChange={(value) => {
          handleFieldChange(value, 'isActive');
        }}
        uncheckedClassName="mr-3"
      />
      <hr className="mb-4" />
      <p>{t('enrollment.program-groups.select-programs-for-this-group')}</p>
      <EnrollmentProgramsTable
        {...tableProps}
        showSelect
        showActions={false}
        showExpand={false}
        columns={[
          {
            text: capitalize(t('spelling.name')),
            dataField: 'name',
            sort: true,
          },
          {
            text: `Program ${t('spelling.start-date')}`,
            dataField: 'startDate',
            sort: true,
            formatter: (cell: string) => formatter(cell),
          },
          {
            text: `Program ${t('spelling.end-date')}`,
            dataField: 'endDate',
            sort: true,
            formatter: (cell: string) => formatter(cell),
          },
          {
            text: t('enrollment.programs.open-enrollment-period'),
            dataField: 'enrolmentOpenAt',
            sort: true,
            formatter: (cell: any, row: IEnrollmentProgram) => {
              if (!row.enrolmentOpenAt) return null;
              if (row.enrolmentOpenAt && !row.enrolmentCloseAt) {
                return `From ${moment(row.enrolmentOpenAt).tz(timezone).format(t('formatters.MM/DD/YYYY'))}`;
              }
              if (row.enrolmentOpenAt && row.enrolmentCloseAt) {
                return `${moment(row.enrolmentOpenAt).tz(timezone).format(t('formatters.MM/DD/YYYY'))} - ${moment(
                  row.enrolmentCloseAt
                )
                  .tz(timezone)
                  .format(t('formatters.MM/DD/YYYY'))}`;
              }
              return null;
            },
          },
        ]}
      />
    </div>
  );
};

export default ProgramGroupForm;
