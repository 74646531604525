import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { contractFields } from './fields';

interface IGetContractsForChildData {
  getContractsForChild: IContract[];
}

interface IGetContractsForAccountVariables {
  centerId: string;
  accountId: string;
}

interface IGetContractsForChildVariables {
  accountId: string;
  childId: string;
}

interface IGetContractsForAccountData {
  getContractsForAccount: IContract[];
}

interface IGetContractsForAccountChildData {
  getContractsForAccountChild: IContract[];
}

interface IGetContractsForAccountChildVariables {
  accountChildId: string;
}

export const GET_CONTRACTS_FOR_CHILD = (fields: string = contractFields) => gql`
  query ($accountId: ID!, $childId: ID!) {
    getContractsForChild(accountId: $accountId, childId: $childId) {
      ${fields}
      metadata {
        isWritable
      }
    }
  }
`;

export const GET_CONTRACTS_FOR_ACCOUNT = (fields: string = contractFields) => gql`
  query ($centerId: ID!, $accountId: ID!) {
    getContractsForAccount(centerId: $centerId, accountId: $accountId) {
      ${fields}
    }
  }
`;

export const GET_CONTRACTS_FOR_ACCOUNT_CHILD = (fields: string = contractFields) => gql`
  query ($accountChildId: ID!) {
    getContractsForAccountChild(accountChildId: $accountChildId) {
      ${fields}
    }
  }
`;

export const useGetContractsForChild = (
  options?: QueryHookOptions<IGetContractsForChildData, IGetContractsForChildVariables>,
  fields?: string
) => useQuery<IGetContractsForChildData, IGetContractsForChildVariables>(GET_CONTRACTS_FOR_CHILD(fields), options);

export const useGetContractsForAccount = (
  options?: QueryHookOptions<IGetContractsForAccountData, IGetContractsForAccountVariables>,
  fields?: string
) =>
  useQuery<IGetContractsForAccountData, IGetContractsForAccountVariables>(GET_CONTRACTS_FOR_ACCOUNT(fields), options);

export const useGetContractsForAccountChild = (
  options?: QueryHookOptions<IGetContractsForAccountChildData, IGetContractsForAccountChildVariables>,
  fields?: string
) =>
  useQuery<IGetContractsForAccountChildData, IGetContractsForAccountChildVariables>(
    GET_CONTRACTS_FOR_ACCOUNT_CHILD(fields),
    options
  );

interface IGetChildIdsWithContractsOnDateData {
  getChildIdsWithContractsOnDate: string[];
}

interface IGetChildIdsWithContractsOnDateVariables {
  input: IGetChildIdsWithContractsOnDateInput;
}

export const GET_CHILD_IDS_WITH_CONTRACTS_ON_DATE = () => gql`
  query ($input: GetChildIdsWithContractsOnDateInput) {
    getChildIdsWithContractsOnDate(input: $input)
  }
`;

export const useGetChildIdsWithContractsOnDate = (
  options?: QueryHookOptions<IGetChildIdsWithContractsOnDateData, IGetChildIdsWithContractsOnDateVariables>,
  fields?: string
) =>
  useQuery<IGetChildIdsWithContractsOnDateData, IGetChildIdsWithContractsOnDateVariables>(
    GET_CHILD_IDS_WITH_CONTRACTS_ON_DATE(),
    options
  );
