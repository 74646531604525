import { allergenFields } from 'gql/allergen/fields';
import { documentFields } from 'gql/document/fields';

export const allergyFields: string = `
  id
  allergenId
  allergen {
    ${allergenFields}
  }
  severity
  reactions
  instructions
  personId
  childId
  archivedAt
  createdAt
  createdByPerson {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
  }
  archivedByPerson {
    id
    firstname
    lastname
    nickname
    avatar {
      url
    }
  }
  isRevised
`;

export const allergyWithDocumentsFields: string = `
  ${allergyFields}
  documents {
    ${documentFields}
  }
`;
