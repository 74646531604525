import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotes } from '@fortawesome/pro-light-svg-icons';
import { ButtonAsLink } from 'shared/components/Buttons';
import { useLeadsContext } from '../../../LeadsContext';
import { useTranslation } from 'react-i18next';

interface IApplicationNotesButtonProps {
  applicationId: string;
}

const ApplicationNotesButton = ({ applicationId }: IApplicationNotesButtonProps) => {
  const { t } = useTranslation();
  const { setNoteModalState, applicationsData } = useLeadsContext();

  const application = applicationsData?.getApplicationsByStage.data.find((d) => d.id === applicationId);

  if (!application) return null;

  const notesCount = application.notes?.length ?? 0;

  const handleNoteModalOpen = () => {
    setNoteModalState({
      isOpen: true,
      applicationId,
    });
  };

  return (
    <div className="d-flex align-items-center">
      <FontAwesomeIcon icon={faNotes} className="mr-2" />
      <ButtonAsLink variant="outline-secondary" onClick={handleNoteModalOpen} className="mr-2">
        <span className="text-primary">
          {t('enrollment.lead-management.notes.notes')} ({notesCount})
        </span>
      </ButtonAsLink>
    </div>
  );
};

export default ApplicationNotesButton;
