import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import CustomDataFieldsRow from './CustomDataFieldsRow';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faPencilAlt, faTimes, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import ActionDropdown from 'shared/components/ActionDropdown';
import { IDatatableState, IStateControls } from 'shared/hooks/useDatatableState2';
import { customFieldAreaOptions } from 'shared/constants/enums/customFieldAreaOptions';
import { customFieldTypeOptions } from 'shared/constants/enums/customFieldTypeOptions';
import Select from 'shared/components/Select';
import { Area, ICustomField, Type } from 'shared/types/customField';

interface ITableProps {
  loading: boolean;
  data: ICustomField[];
  dataSize?: number;
  pageSize?: number;
  activePage?: number;
  showLoadingOverlay?: boolean;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  filteredArea: Area;
  filteredType: Type;
  filteredCenter: string[];
  onAreaFilter: (status: Area) => void;
  onTypeFilter: (status: Type) => void;
  onSearch: (searchText: string) => void;
  onCenterFilter: (status: string[]) => void;
  onPageChange?: (page: number, sizePerPage: number) => void;
  onSizePerPageChange?: (sizePerPage: number) => void;
  onSort?: (field: string, direction: ElasticsearchSortDirection) => void;
  handleActionEdit: (customField: ICustomField, isRowSelected?: boolean) => void;
  handleActionArchive: (customField: ICustomField, isRowSelected?: boolean) => void;
  handleRowClick: (event: React.SyntheticEvent, rowData: ICustomField) => void;
  showArchived: boolean;
}

export interface INewCustomFieldForm {
  businessId: string;
  centerIds: string[];
  label: string;
  area: string;
  displayOrder: number | undefined;
  notForConsoleDisplay: boolean;
  type: string;
  attributes: string;
}

export interface ICustomFieldFilter {
  businessId: string;
  centerIds: string[];
  label: string;
  area: string;
  type: string;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const CustomDataFieldsTable: React.FC<ITableProps> = ({
  loading,
  data,
  dataSize,
  pageSize,
  activePage,
  showLoadingOverlay,
  filteredArea,
  filteredType,
  filteredCenter,
  tableState,
  tableFunctions,
  onPageChange,
  onSizePerPageChange,
  onSort,
  onSearch,
  handleActionEdit,
  handleActionArchive,
  onAreaFilter,
  onTypeFilter,
  onCenterFilter,
  showArchived,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleRowClick = (event: React.SyntheticEvent, row: ICustomField) => {};

  // const canEditCustomFields = useHasRoleAreaLevel({ area: AreaType.Business, permission: PermissionType.CustomFields, level: RoleLevelType.Edit });
  const canEditCustomFields = true; // NB: currently hard coded to bypass the permissions

  const { data: centers = [] } = useGetActiveCentersWithLoading();

  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  useEffect(() => {
    setExpandedRows([]);
  }, [loading]);

  const dataWithKeys = data.map((row, index) => ({
    ...row,
    indexId: `${row.id}-${index}`,
  }));

  const onExpand = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    const expanded = isExpand ? [row.indexId] : [];
    setExpandedRows(expanded);
  };

  const clearAppliedFilters = useCallback(() => {
    onCenterFilter([]);
    onAreaFilter(null);
    onTypeFilter(null);
  }, [dispatch, onAreaFilter, onTypeFilter]);

  const generateActions = (customField: ICustomField) => [
    {
      label: t('custom-data-fields.action-edit'),
      disabled: false,
      icon: faPencilAlt,
      onClick: () => {
        handleActionEdit(customField, true);
      },
    },
    {
      label: t('custom-data-fields.action-archive'),
      disabled: false,
      icon: faTrashAlt,
      onClick: () => {
        handleActionArchive(customField, true);
      },
    },
  ];

  const expandRow = (row: ICustomField) => <CustomDataFieldsRow customFieldData={row} />;

  return (
    <SpinnerTableOverlay show={loading}>
      <DataTable
        keyField="indexId"
        noPadding={true}
        data={dataWithKeys}
        dataSize={dataSize}
        pageSize={pageSize}
        activePage={activePage}
        onPageChange={onPageChange}
        handleRowClick={handleRowClick}
        onSizePerPageChange={onSizePerPageChange}
        showLoadingOverlay={showLoadingOverlay}
        showSelect={false}
        showPagination={true}
        expandRow={expandRow}
        onExpand={onExpand}
        expanded={expandedRows}
        onlyOneExpanding={true}
        renderHeader={(paginationProps: any, searchProps: any) => (
          <TableHeader className="flex-wrap justify-content-between">
            <div className="d-flex flex-direction-row align-items-center">
              <SizePerPage paginationProps={paginationProps} />
              <TableSearch
                className="mr-4"
                onChange={(value) => {
                  onSearch(value);
                }}
                placeholder="Search Label"
              />
              <div className="d-flex">
                <DropdownFilter
                  title={t('core.capitalize', { value: t('spelling.center') })}
                  className="mr-4 my-2"
                  selectedFilters={filteredCenter}
                  options={centers.map((c) => ({ label: c.name, value: c.id })) ?? []}
                  onFilterSelect={(values) => onCenterFilter(values.map((value) => value.value as string))}
                />
              </div>

              <Select
                placeholder={capitalize(t('spelling.areas'))}
                options={customFieldAreaOptions}
                value={filteredArea}
                onChange={(selectedOption) => onAreaFilter(selectedOption.value)}
                className="mr-4 flex-grow-0 my-2"
              />

              <Select
                placeholder={capitalize(t('spelling.types'))}
                options={customFieldTypeOptions}
                value={filteredType}
                onChange={(selectedOption) => onTypeFilter(selectedOption.value)}
                className="mr-4 flex-grow-0 my-2"
              />
            </div>
            <IconButtonCircle
              icon={faTimes}
              onClick={clearAppliedFilters}
              tooltipDirection="bottom"
              tooltipText="Clear Filters"
              className="mr-4 my-2"
            />
          </TableHeader>
        )}
        onSort={onSort}
        columns={[
          {
            text: 'Label',
            dataField: 'label',
            sort: true,
          },
          {
            text: 'Area',
            dataField: 'area',
            sort: true,
          },
          {
            text: 'Type',
            dataField: 'type',
            sort: true,
            formatter: (cell: any, row: ICustomField) => (row.type === 'HtmlText' ? 'HTML Text' : row.type),
          },
          {
            text: 'Display Order',
            dataField: 'displayOrder',
            sort: true,
          },
          {
            text: 'Is Hidden',
            dataField: 'notForConsoleDisplay',
            sort: true,
          },
          ...(showArchived
            ? []
            : [
                {
                  text: capitalize(t('spelling.actions')),
                  dataField: '',
                  align: 'center',
                  headerClasses: 'text-center',
                  classes: 'td-overflow',
                  formatter: (cell: any, row: ICustomField) => <ActionDropdown actions={generateActions(row)} />,
                },
              ]),
        ]}
      ></DataTable>
    </SpinnerTableOverlay>
  );
};

export default CustomDataFieldsTable;
