import React, { useState } from 'react';
import { Pie } from '@nivo/pie';
import Card from 'shared/components/Card';
import { paymentStatusColorHexes } from 'shared/constants/tagColors';
import { currencyFormat } from 'shared/util/currency';
import { toProperCase } from 'shared/util/string';
import { Col, Row } from 'shared/components/Layout';
import { Circle } from 'shared/components/Shapes';
import { Button } from 'react-bootstrap';
import { statusText, helperText } from 'pages/BillingTransactions/components/PaymentStatusFilterCard';
import Tooltip from 'shared/components/Tooltip';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Select from 'shared/components/Select';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import {
  convertTimeRangeObjectToString,
  convertTimeRangeStringToObject,
  timeRangeOptions,
} from 'shared/util/timeUtils';
import moment from 'moment';
import { IPaymentSummary, useGetSumOfPaymentsInTimeFrame } from 'gql/transaction/queries';
import colors from '_colors.module.scss';
import { Circle as LoadingCircle, LoadingLines } from 'shared/components/LoadingSkeletons';
import { HorizontalDivider } from 'shared/components/Dividers';
import { isRegion } from 'shared/util/region';

interface IProps {
  centerIds?: string[];
}

const PaymentProcessing: React.FC<IProps> = ({ centerIds }) => {
  const [dateRange, setDateRange] = useState(convertTimeRangeStringToObject('This Week'));
  const { businessId } = useSelector((state: RootState) => state.context);
  const unsuccessfulStatuses: PaymentStatus[] = isRegion('AU') ? ['FAILED'] : ['FAILED', 'RETURNED'];
  const { data: sumOfPaymentsQueryResult, loading: processingTransactionsLoading } = useGetSumOfPaymentsInTimeFrame({
    variables: {
      input: {
        start: moment(dateRange.start).format('YYYY-MM-DD'),
        end: moment(dateRange.end).format('YYYY-MM-DD'),
        centerIds: centerIds ?? [],
        businessId: businessId ?? '',
      },
    },
  });

  const totalAmount = useSelector((_) => {
    const amount = sumOfPaymentsQueryResult?.getSumOfPaymentsInTimeFrame
      .filter((status) => status.paymentStatus !== 'CANCELLED')
      .reduce((prev, curr) => prev + curr.totalAmount, 0);

    return currencyFormat(amount);
  });

  const getStatusLabel = (status: PaymentStatus) => {
    if (status !== 'FAILED') {
      return statusText[status];
    }

    return 'Unsuccessful';
  };

  const getStatusCount = (data: IPaymentSummary[], status: PaymentStatus) => {
    if (status === 'FAILED') {
      const unsuccessfulStatusStrings = unsuccessfulStatuses.map((s) => s.toString());
      return data
        ?.filter((x) => unsuccessfulStatusStrings.includes(x.paymentStatus))
        .reduce((prev, curr) => prev + curr.paymentCount, 0);
    }

    return data?.find((x) => x.paymentStatus === status)?.paymentCount ?? 0;
  };

  const getStatusAmount = (data: IPaymentSummary[], status: PaymentStatus) => {
    if (status === 'FAILED') {
      const unsuccessfulStatusStrings = unsuccessfulStatuses.map((s) => s.toString());
      return data
        ?.filter((x) => unsuccessfulStatusStrings.includes(x.paymentStatus))
        .reduce((prev, curr) => prev + curr.totalAmount, 0);
    }

    return data?.find((x) => x.paymentStatus === status)?.totalAmount ?? 0;
  };

  const chartData = sumOfPaymentsQueryResult?.getSumOfPaymentsInTimeFrame
    .filter((status) => status.paymentStatus !== 'CANCELLED' && status.paymentStatus !== 'RETURNED')
    .map((status) => {
      return {
        id: toProperCase(status.paymentStatus),
        value: getStatusAmount(
          sumOfPaymentsQueryResult?.getSumOfPaymentsInTimeFrame,
          status.paymentStatus as PaymentStatus
        ),
        number: getStatusCount(
          sumOfPaymentsQueryResult?.getSumOfPaymentsInTimeFrame,
          status.paymentStatus as PaymentStatus
        ),
        color: paymentStatusColorHexes[status.paymentStatus as PaymentStatus],
        label: getStatusLabel(status.paymentStatus as PaymentStatus),
        helperText: helperText[status.paymentStatus as PaymentStatus],
      };
    });

  const startDate = moment(dateRange.start).format('YYYY-MM-DD');
  const endDate = moment(dateRange.end).format('YYYY-MM-DD');

  const history = useHistory();

  return (
    <Card className="p-0" bodyClassName="p-0">
      <Row noGutters className="my-4 mx-5">
        <h5>Processing Payments</h5>
        <Select
          options={timeRangeOptions.filter((o) => o !== 'Custom')}
          value={convertTimeRangeObjectToString(dateRange)}
          onChange={(string) => setDateRange(convertTimeRangeStringToObject(string))}
          className="ml-auto flex-grow-0 my-2"
        />
      </Row>
      <HorizontalDivider />
      <Row className="mb-4 mx-4">
        <Col className="mb-2">
          {processingTransactionsLoading ? (
            <LoadingCircle size={286} />
          ) : (
            <Pie
              data={chartData ?? []}
              colors={({ id, data }) => {
                return data.color;
              }}
              width={286}
              height={286}
              innerRadius={0.6}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              tooltip={(d) => (
                <div className="text-text-dark bg-white p-2 rounded box-shadow-lg">
                  <b>{d.datum.label}</b> ({d.datum.data.number}) {currencyFormat(d.datum.value)}
                </div>
              )}
              layers={[
                'arcs',
                'arcLabels',
                'arcLinkLabels',
                'legends',
                ({ dataWithArc, centerX, centerY }) => {
                  return (
                    <text
                      x={centerX}
                      y={centerY + 5}
                      textAnchor="middle"
                      dominantBaseline="central"
                      fontSize="20px"
                      fontFamily="proxima-soft"
                    >
                      {totalAmount}
                    </text>
                  );
                },
                ({ dataWithArc, centerX, centerY }) => {
                  return (
                    <text
                      x={centerX}
                      y={centerY - 25}
                      textAnchor="middle"
                      fontSize="14px"
                      dominantBaseline="central"
                      fill={colors['dark-gray']}
                    >
                      {convertTimeRangeObjectToString(dateRange)}
                    </text>
                  );
                },
              ]}
            />
          )}
        </Col>
        <Col>
          {chartData?.map((d) =>
            processingTransactionsLoading ? (
              <LoadingLines number={1} />
            ) : (
              <Row
                key={d.id}
                noGutters
                onClick={() =>
                  history.push(`/billing/payments?status=${d.id.toLowerCase()}&start=${startDate}&end=${endDate}`)
                }
                className="flex-nowrap grey-hover rounded px-4 py-2"
              >
                <Circle background={d.color} size={16} className="mr-2" />
                <Tooltip text={d.helperText} direction="top">
                  <h6 className="mb-0 mr-2 text-text-color">{d.label}</h6>
                </Tooltip>
                <div className="sm mr-2">({d.number})</div>
                <h5 className="mb-0 ml-auto mr-2 font-size-18">{currencyFormat(d.value)}</h5>
                <FontAwesomeIcon icon={faAngleRight} size="lg" />
              </Row>
            )
          )}
        </Col>
      </Row>
      <Row noGutters className="m-5">
        {processingTransactionsLoading ? (
          <LoadingLines number={1} />
        ) : (
          <div className="">
            You have {totalAmount} in payments {convertTimeRangeObjectToString(dateRange).toLowerCase()}.
          </div>
        )}
        <Button
          className="ml-auto"
          variant="outline-secondary"
          onClick={() => history.push(`/billing/payments?start=${startDate}&end=${endDate}`)}
        >
          See All Payments
        </Button>
      </Row>
    </Card>
  );
};

export default PaymentProcessing;
