import React from 'react';
import Modal from 'react-bootstrap/Modal';
import BillingCycleSentence from './BillingCycleSentence';

interface IProps {
  isOpen: boolean;
  template: IBillingCycleTemplate;
  onClose: () => void;
}

const EditBillingCycleTemplateModal: React.FC<IProps> = ({ isOpen, template, onClose, ...props }) => {
  return (
    <Modal centered backdrop="static" show={isOpen} onHide={onClose} size="lg">
      <Modal.Header closeButton className="border-bottom-0 pb-4">
        <div className="d-flex flex-column">
          <Modal.Title as="h3">{template.name}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-4">
        <BillingCycleSentence cycle={template} onUpdate={(template) => {}} readOnly />
      </Modal.Body>
    </Modal>
  );
};

export default EditBillingCycleTemplateModal;
