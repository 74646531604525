import React, { useState } from 'react';
import { ApplicationNote, Maybe } from 'generated/graphql';
import EditNote from './EditNote';
import ViewNote from './ViewNote';

interface INoteProps {
  note: Maybe<ApplicationNote>;
  onEditOpen: () => void;
  onEditSuccessful: () => void;
  onEditCanceled: () => void;
}

const Note = ({ note, onEditOpen, onEditSuccessful, onEditCanceled }: INoteProps) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const onEdit = () => {
    setMode('edit');
    onEditOpen();
  };
  const onView = () => {
    setMode('view');
    onEditSuccessful();
  };
  const onCancel = () => {
    setMode('view');
    onEditCanceled();
  };

  if (!note) return null;

  if (mode === 'view') {
    return <ViewNote note={note} onEdit={onEdit} />;
  } else {
    return <EditNote note={note} onEditSuccessful={onView} onCancel={onCancel} />;
  }
};

export default Note;
