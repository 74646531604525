import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { RootState } from 'store/reducers';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetKindyForAllCredentials } from 'gql/kindyForAll/queries';
import ActionDropdown from 'shared/components/ActionDropdown';
import { CreateButton } from 'shared/components/Buttons';
import { useTranslation } from 'react-i18next';
import { RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import CreateEditApiKeyModal from '../CreateEditApiKeyModal/CreateEditCredentialModal';
import { IKindyForAllCredential } from 'shared/types/kindyForAll';

interface IProps {}

interface IKindyForAllCredentialSort {
  direction: ElasticsearchSortDirection;
  field: string;
}

const KindyForAllCredentialsTab: React.FC<IProps> = ({ ...props }) => {
  const { businessId } = useSelector((state: RootState) => state.context);
  const [createEditApiKeyModalOpen, setCreateEditApiKeyModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCredential, setSelectedCredential] = useState<IKindyForAllCredential | undefined>();
  const [sort, setSort] = useState<IKindyForAllCredentialSort>({ field: 'applicationName', direction: 'ASCENDING' });
  const { t } = useTranslation(['translation', 'subsidies']);
  const [tableState, tableFunctions] = useDatatableState();

  const shouldSkip = businessId == null || businessId === '';

  const {
    data: data,
    loading,
    refetch,
  } = useGetKindyForAllCredentials({
    variables: {
      input: {
        businessId: businessId ?? '',
        searchKey: searchTerm,
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortBy: sort.field,
        sortDirection: sort.direction,
      },
    },
    skip: shouldSkip,
  });

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSort({ field, direction });
  }, []);

  const handleEdit = (credential: IKindyForAllCredential) => {
    setSelectedCredential(credential);
    setCreateEditApiKeyModalOpen(true);
  };

  const getTableColumns = useCallback((): any[] => {
    const tableColumns: any = [
      {
        dataField: 'applicationName',
        text: 'Application Name',
        sort: true,
      },
      {
        dataField: 'applicationKey',
        text: 'Application Key',
        sort: true,
      },
      {
        dataField: 'applicationSecret',
        text: 'Application Secret',
        sort: false,
      },
    ];

    tableColumns.push({
      text: '',
      dataField: '',
      align: 'center',
      headerAlign: 'center',
      formatter: (cell: any, row: IKindyForAllCredential) => (
        <div className="d-flex justify-content-center">
          <ActionDropdown
            actions={[
              {
                label: 'Edit',
                onClick: () => handleEdit(row),
              },
            ]}
          />
        </div>
      ),
    });

    return tableColumns;
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <CreateEditApiKeyModal
        isOpen={createEditApiKeyModalOpen}
        credential={selectedCredential}
        businessId={businessId ?? ''}
        onClose={() => {
          setCreateEditApiKeyModalOpen(false);
          setSelectedCredential(undefined);

          if (!shouldSkip) refetch();
        }}
      />

      <DataTable
        data={data?.getKindyForAllCredentials?.data ?? []}
        columns={getTableColumns()}
        dataSize={data?.getKindyForAllCredentials?.totalRecords ?? 0}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        showLoadingOverlay={loading}
        showSelect={false}
        onSort={handleSort}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        renderHeader={(paginationProps) => (
          <>
            <TableHeader className="flex-wrap align-items-center">
              <div className="d-flex flex-wrap mr-auto align-items-center">
                <SizePerPage paginationProps={paginationProps} />
                <TableSearch placeholder="Search" onChange={setSearchTerm} className={isMobile ? 'mt-2 mb-1' : ''} />
              </div>
              <div className="d-flex flex-wrap align-items-center">
                <div className="d-flex flex-direction-row align-items-center m-0 flex-wrap">
                  <CreateButton
                    onClick={() => setCreateEditApiKeyModalOpen(true)}
                    permission={
                      { permission: 'Base', level: RoleLevelType.Create, area: 'Business' } as IAreaPermissionLevel
                    }
                  >
                    {t('subsidies:add-api-key')}
                  </CreateButton>
                </div>
              </div>
            </TableHeader>
          </>
        )}
      />
    </>
  );
};

export default KindyForAllCredentialsTab;
