import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { photoCountFields, photoFields, photoStatisticsFields, searchPhotoFields } from './fields';

interface IGetClassPhotoCounts {
  getClassPhotoCounts: IClassPhotoCount[];
}

interface IGetGetPhotoCountVariables {
  input: {
    startDate: string;
    endDate: string;
    centerId: string;
    classIds: string[];
  };
}

interface IGetSearchedPhotos {
  searchPhoto: IPhotoPagedResult;
}

interface ISearchPhotoInput {
  input: {
    centerIds: string[];
    classIds?: string[] | null;
    takenAfter?: string | null;
    takenBefore?: string | null;
    from: number;
    size: number;
    isApproved?: boolean | null;
    isEvaluated?: boolean | null;
    sortDirection?: string | null;
  };
}

interface ICenterPhotoStatistics {
  centerId: string;
  takenAfter: string;
  takenBefore: string;
  approvedCount: number;
  waitingForApprovalCount: number;
  declinedCount: number;
}

export const GET_CLASS_PHOTO_COUNTS = gql`
  query($input: PhotoCountInput!) {
    getClassPhotoCounts(input: $input) {
      ${photoCountFields}
    }
  }
`;

export const useGetClassPhotoCounts = (options?: QueryHookOptions<IGetClassPhotoCounts, IGetGetPhotoCountVariables>) =>
  useQuery<IGetClassPhotoCounts, IGetGetPhotoCountVariables>(GET_CLASS_PHOTO_COUNTS, {
    fetchPolicy: 'network-only',
    skip: options?.variables?.input.centerId === '' || !options?.variables?.input.classIds.length,
    ...options,
  });

export const GET_SEARCHED_PHOTOS = gql`
  query($input: SearchPhotoInput!) {
    searchPhoto(input: $input) {
      ${searchPhotoFields}
    }
  }
`;

export const useGetSearchedPhotos = (options?: QueryHookOptions<IGetSearchedPhotos, ISearchPhotoInput>) =>
  useQuery<IGetSearchedPhotos, ISearchPhotoInput>(GET_SEARCHED_PHOTOS, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const GET_PHOTO_STATISTICS = gql`
  query($input: SearchPhotoInput!) {
    getPhotoStatistics(input: $input) {
      ${photoStatisticsFields}
    }
  }
`;

export const useGetPhotoStatistics = (
  options?: QueryHookOptions<{ getPhotoStatistics: ICenterPhotoStatistics }, ISearchPhotoInput>
) =>
  useQuery<{ getPhotoStatistics: ICenterPhotoStatistics }, ISearchPhotoInput>(GET_PHOTO_STATISTICS, {
    fetchPolicy: 'network-only',
    ...options,
  });

const GET_PRESIGNED_URL = gql`
  query {
    getPutGallerySignedUrl {
      url
      fields
      provisionalGetUrl
    }
  }
`;

export const useGetGalleryPresignedURL = (
  options?: QueryHookOptions<IGetPutGalleryUploadURLResponse, IGetPutGalleryUploadURLInput>,
  fields?: string
) =>
  useLazyQuery<IGetPutGalleryUploadURLResponse, IGetPutGalleryUploadURLInput>(GET_PRESIGNED_URL, {
    fetchPolicy: 'no-cache',
    skip: false,
    ...options,
  });
