import React, { useCallback, useMemo, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { groupBy, orderBy, sortBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild, faUser } from '@fortawesome/pro-light-svg-icons';
import Card, { BoldCardHeader } from 'shared/components/Card';
import WeekPicker from 'shared/components/DateInput/WeekPicker';
import Table from 'react-bootstrap/Table';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import colors from '_colors.module.scss';
import { Line } from 'shared/components/LoadingSkeletons';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import { IGetAttendanceOpenSpotsData } from 'gql/session/queries';
import { round } from 'shared/util/number';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'shared/components/Layout';
import Button, { ButtonAsLink } from 'shared/components/Buttons';
import { useHistory } from 'react-router-dom';
import { StatefulNumberInput } from 'shared/components/TextInput';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  centerId: string;
  timezone: string;
  startOfWeek: string;
  endOfWeek: string;
  classes: IClass[]; // assumes the array has already been sorted by age (youngest to oldest)
  utilizationData?: IGetAttendanceOpenSpotsData;
  loadingUtilizationData: boolean;
  onDateChange: (date: string) => void;
  // default is 'view', when mode is 'edit', all cells become editable number input to take user's input for session capacity override
  mode?: 'edit' | 'view';
  // required when mode is 'edit'
  onCapacityChange?: (classId: string, date: string, newCapacity: number, oldCapacity: number) => void;
  // required when mode is 'edit'
  capacityOverrides?: ICapacityOverridesDictionary;
  handleOpenClassOnDate?: (closureId: any, date: string) => Promise<void>;
  openClassOnDateLoading?: boolean;
  dateRangeForAttendance?: (date: moment.Moment) => boolean;
}

const UtilizationTableCard: React.FC<IProps> = ({
  centerId,
  timezone,
  startOfWeek,
  endOfWeek,
  classes,
  utilizationData,
  loadingUtilizationData,
  onDateChange,
  mode = 'view',
  onCapacityChange,
  capacityOverrides = {},
  handleOpenClassOnDate,
  openClassOnDateLoading = false,
  dateRangeForAttendance,
  ...props
}) => {
  const { k2AttendanceSorting } = useFlags();
  const { t } = useTranslation();
  const history = useHistory();
  const hasViewClassPermission = useHasRoleAreaLevel({
    area: AreaType.Center,
    permission: PermissionType.Classes,
    level: RoleLevelType.Read,
  });
  const hasEditCasualBookingPermission = useHasRoleAreaLevel({
    area: AreaType.Attendance,
    permission: PermissionType.CasualBookingManagement,
    level: RoleLevelType.Edit,
  });
  const [reopenClassModalState, setReopenClassModalState] = useState({
    isOpen: false,
    name: '',
    date: '',
    closureId: '',
  });
  const initialLoading = loadingUtilizationData && utilizationData === undefined;
  let activeClasses = useMemo(
    () =>
      classes
        .filter((c) => (c.archivedAt ? moment(c.archivedAt).isAfter(startOfWeek) : true))
        .filter((c) => (c.endsAt ? moment(c.endsAt).isAfter(startOfWeek) : true))
        .filter((c) => (c.startsAt ? moment(c.startsAt).isBefore(endOfWeek) : true)),
    [classes, startOfWeek, endOfWeek]
  );

  const openSpotsGroupedByClass = useMemo(() => {
    const grouped = groupBy(utilizationData?.getAttendanceOpenSpots.byClass ?? [], (data) => data.classId);

    Object.keys(grouped).forEach((classId) => {
      grouped[classId] = orderBy(grouped[classId], (data) => data.date);
    });

    return grouped;
  }, [utilizationData]);

  if (k2AttendanceSorting) {
    activeClasses = sortBy(activeClasses, 'name');
  }

  const isCellDirty = useCallback(
    (classId: string, date: string) => {
      const capacityOverride = capacityOverrides[`${classId}-${date}`];
      if (!capacityOverride) return false;
      return capacityOverride.newCapacity !== capacityOverride.oldCapacity;
    },
    [capacityOverrides]
  );

  const datesForWeek = useMemo((): moment.Moment[] => {
    const dates: moment.Moment[] = [];
    const start = moment(startOfWeek).tz(timezone);
    const end = moment(endOfWeek).tz(timezone);
    const current = start.clone();

    while (current.isSameOrBefore(end, 'date')) {
      if (current.day() > 0 && current.day() < 6) {
        // only shows weekdays
        dates.push(current.clone());
      }

      current.add(1, 'day');
    }

    return dates;
  }, [startOfWeek, endOfWeek]);

  const handleCapacityChange = useCallback(
    (classId: string, date: string, newCapacity: number, oldCapacity: number) => {
      onCapacityChange && onCapacityChange(classId, date, newCapacity, oldCapacity);
    },
    [onCapacityChange]
  );

  const isInputInvalid = useCallback(
    (key: string) => {
      const value = capacityOverrides[key];
      if (!value) return false;
      return typeof value.newCapacity === 'string' || value.newCapacity < 0;
    },
    [capacityOverrides]
  );

  const renderTableRow = useCallback(
    (_class: IClass): React.ReactNode => {
      const utilizationData = openSpotsGroupedByClass[_class.id] ?? [];
      const classUitlizationDataByDate: Record<string, IClassAttendanceOpenSpots> = utilizationData.reduce(
        (acc, curr) => ({ ...acc, [moment(curr.date.split('T')[0]).format('YYYY-MM-DD')]: curr }),
        {}
      );
      const regulation = _class.regulationOverride || _class.regulation;
      const ratioString = `Ratio ${regulation.ratioTeachers}:${regulation.ratioChildren}`;

      return (
        <tr key={`utilization-table-body-${_class.id}`}>
          {hasViewClassPermission ? (
            <td colSpan={3}>
              <Link to={{ pathname: `/centers/classes/${_class.id}`, state: { centerId: _class.centerId } }}>
                <AvatarDataTableCell
                  color={_class.colorCode}
                  initials={_class.name[0].toUpperCase()}
                  primaryText={_class.name}
                  secondaryText={ratioString}
                  avatar=""
                />
              </Link>
            </td>
          ) : (
            <td colSpan={3}>
              <AvatarDataTableCell
                color={_class.colorCode}
                initials={_class.name[0].toUpperCase()}
                primaryText={_class.name}
                secondaryText={ratioString}
                avatar=""
              />
            </td>
          )}
          {datesForWeek.map((date, idx) => {
            const lookupKey = date.format('YYYY-MM-DD');
            const utilizationDataForDate: IClassAttendanceOpenSpots | undefined = classUitlizationDataByDate[lookupKey];
            const closure = utilizationDataForDate?.closure;
            const utilizationRounded = ((utilizationDataForDate?.utilization ?? 0) * 100).toFixed(2);
            if (initialLoading) {
              return (
                <Fragment key={`utilization-table-body-cell-${_class.id}-${date.toString()}-${idx}-loading`}>
                  <td>
                    <Line />
                  </td>
                  <td>
                    <Line />
                  </td>
                  <td>
                    <Line />
                  </td>
                </Fragment>
              );
            }

            if (mode === 'view') {
              return (
                <Fragment key={`utilization-table-body-cell-${_class.id}-${date.toString()}-${idx}`}>
                  {closure ? (
                    <td className="closed-session-cell" colSpan={3}>
                      <div>
                        {t('attendance.manage-closures.class-closed', {
                          resolution:
                            closure?.closureResolution === 'Absent'
                              ? t('attendance.manage-closures.student-absent')
                              : t('attendance.manage-closures.booking-cleared'),
                        })}
                      </div>
                      {hasEditCasualBookingPermission && (
                        <ButtonAsLink
                          className="m-0"
                          onClick={() =>
                            setReopenClassModalState((prev) => ({
                              ...prev,
                              isOpen: true,
                              name: _class.name,
                              date: date.format('YYYY-MM-DD'),
                              closureId: closure.id,
                            }))
                          }
                        >
                          {t('attendance.manage-closures.open-class')}
                        </ButtonAsLink>
                      )}
                    </td>
                  ) : (
                    <>
                      <td>{utilizationRounded}%</td>
                      <td>
                        <div>
                          <div>
                            <FontAwesomeIcon icon={faChild} color={colors.dark} size="sm" className="mr-2" />
                            {utilizationDataForDate?.numberOfSessions ?? ''}
                          </div>
                          <div>
                            <FontAwesomeIcon icon={faUser} color={colors.dark} size="sm" className="mr-2" />
                            {utilizationDataForDate?.numberOfTeachersScheduled ?? ''}
                          </div>
                        </div>
                      </td>
                      <td className="cell-end">{round(utilizationDataForDate?.totalOpenSpots ?? 0) ?? ''}</td>
                    </>
                  )}
                </Fragment>
              );
            }

            if (mode === 'edit') {
              const isInValid = isInputInvalid(`${_class.id}-${date.format('YYYY-MM-DD')}`);
              return (
                <Fragment key={`utilization-table-body-cell-${_class.id}-${date.toString()}-${idx}`}>
                  <td colSpan={3} className="cell-end">
                    <StatefulNumberInput
                      defaultValue={utilizationDataForDate?.capacity}
                      step="1"
                      min="0"
                      isInvalid={isInValid}
                      // don't need to render the valid state when the input is valid
                      // only need to render the invalid state when the input is invalid
                      requiresValidation={isInValid}
                      onChange={(v) =>
                        handleCapacityChange(_class.id, date.format('YYYY-MM-DD'), v, utilizationDataForDate.capacity)
                      }
                      className={`m-0 ${isCellDirty(_class.id, date.format('YYYY-MM-DD')) && 'capacity-dirty-input'}`}
                    />
                  </td>
                </Fragment>
              );
            }

            return null;
          })}
        </tr>
      );
    },
    [
      openSpotsGroupedByClass,
      datesForWeek,
      initialLoading,
      mode,
      isCellDirty,
      handleCapacityChange,
      hasViewClassPermission,
      hasEditCasualBookingPermission,
      t,
      isInputInvalid,
    ]
  );

  const onConfirm = useCallback(
    (closureId: string, date: string) => {
      handleOpenClassOnDate &&
        handleOpenClassOnDate(closureId, date).then(() =>
          setReopenClassModalState((prev) => ({ ...prev, isOpen: false, name: '', date: '', closureId }))
        );
    },
    [handleOpenClassOnDate]
  );

  return (
    <Card bodyClassName="py-4">
      <Row className="mb-2">
        <Col>
          <BoldCardHeader
            text={mode === 'edit' ? t('attendance.manage-capacities.class-capacity') : t('attendance.overview')}
            className="d-flex align-items-center"
          />
        </Col>
        <Col>
          <WeekPicker
            className="d-flex justify-content-center mb-0"
            displayFormat="MMM D"
            startDate={moment(startOfWeek).tz(timezone)}
            endDate={moment(endOfWeek).tz(timezone)}
            rangeComponent={<span className="mx-1">-</span>}
            onChange={(dates) => onDateChange(dates.startDate.toISOString())}
            reactDatesController="RANGE"
            isOutsideRange={(date: moment.Moment) => (dateRangeForAttendance ? dateRangeForAttendance(date) : false)}
          />
        </Col>
        <Col className="text-right">
          {hasEditCasualBookingPermission && mode === 'view' && (
            <Button
              variant="outline-secondary"
              onClick={() => history.push('/attendance/manage/capacities')}
              className="ml-2 my-1"
            >
              {t('attendance.manage-capacities.title')}
            </Button>
          )}

          {hasEditCasualBookingPermission && mode === 'view' && (
            <Button
              variant="outline-secondary"
              onClick={() => history.push('/attendance/manage/closures')}
              className="ml-2 my-1"
            >
              {t('attendance.manage-closures.title')}
            </Button>
          )}
        </Col>
      </Row>
      <SpinnerOverlay show={loadingUtilizationData && !initialLoading}>
        <Table responsive borderless className="kt-attendance-utilization-table">
          <thead>
            <tr>
              <th colSpan={3}>Class</th>
              {datesForWeek.map((date: moment.Moment, idx: number) => (
                <th key={`utilization-table-header-${date.toString()}-${idx}`} colSpan={3}>
                  {date.format('dddd')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3} /> {/* filler for the 'class' column */}
              {datesForWeek.map((date: moment.Moment, idx: number) => (
                <Fragment key={`utilization-first-table-row-${date.toString()}-${idx}`}>
                  <td>Occupancy</td>
                  <td>Expected</td>
                  <td>
                    Open
                    <br />
                    Spots
                  </td>
                </Fragment>
              ))}
            </tr>
            {activeClasses.map((_class: IClass, idx: number) => renderTableRow(_class))}
          </tbody>
        </Table>
      </SpinnerOverlay>
      <ConfirmationModal
        title={t('attendance.manage-closures.reopen-confirmation-modal.title')}
        show={reopenClassModalState.isOpen}
        onHide={() => setReopenClassModalState((prev) => ({ ...prev, isOpen: false }))}
        primaryChoice={t('attendance.manage-closures.reopen-confirmation-modal.button-text')}
        primaryCallback={() => onConfirm(reopenClassModalState.closureId, reopenClassModalState.date)}
        hideOnCallback={false}
        primaryButtonProps={{
          variant: 'primary',
          loading: openClassOnDateLoading,
        }}
      >
        {t('attendance.manage-closures.reopen-confirmation-modal.message', {
          name: reopenClassModalState.name,
          date: moment(reopenClassModalState.date).format(t('formatters.MM/DD/YYYY')),
          interpolation: { escapeValue: false },
        })}
      </ConfirmationModal>
    </Card>
  );
};

export default UtilizationTableCard;
