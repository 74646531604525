import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const SubmitAgencyPaymentConfirmationModal: React.FC<IProps> = ({ isOpen, onConfirm, onClose, ...props }) => {
  const { t } = useTranslation(['subsidies']);

  return (
    <ConfirmationModal
      title={t('subsidies:agency-billing.confirm-payment-submit-modal.title')}
      show={isOpen}
      onHide={onClose}
      hideOnCallback={false}
      primaryChoice="Submit"
      primaryCallback={() => onConfirm()}
    >
      <p>{t('subsidies:agency-billing.confirm-payment-submit-modal.body')}</p>
      <b className="text-danger">{t('subsidies:agency-billing.confirm-payment-submit-modal.subtext')}</b>
    </ConfirmationModal>
  );
};

export default SubmitAgencyPaymentConfirmationModal;
