import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { useGetS3PresignedURL } from 'gql/s3SignedUrl/queries';

/**
 * Upload file to S3 using a presigned url
 * @param {File} file - file to upload
 * @returns {[Function, String]} - first param is a function to trigger the file upload, second param is the s3 key
 */

type UploadFn = (file: File) => Promise<void>;
type S3KeyType = string | null;

const useUploadFile = (): [UploadFn, S3KeyType] => {
  const [file, setFile] = useState(null);
  const [S3Key, setS3Key] = useState<string | null>(null);
  const [havePresignedUrl, setHavePresignedUrl] = useState<boolean>(false);

  const [presignedUrlFn, presignedUrlResponse] = useGetS3PresignedURL();

  // Step 1: getPutSignedUrl call
  const requestPresignedUrlFn = async (file) => {
    try {
      setFile(file);
      await presignedUrlFn();
      setHavePresignedUrl(true);
    } catch (error) {
      console.log(error);
    }
  };

  // Step 2: Send to S3 bucket
  useEffect(() => {
    (async () => {
      try {
        if (file && presignedUrlResponse && presignedUrlResponse.data && havePresignedUrl) {
          setHavePresignedUrl(false);
          const {
            data: {
              getPutSignedUrl: { fields, url },
            },
          } = presignedUrlResponse;
          const fieldsObject = fields;
          const s3Key = fieldsObject.Key;
          const formData = new FormData();

          Object.keys(fieldsObject).forEach((key) => formData.append(key, fieldsObject[key]));
          formData.append('file', file);

          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });

          if (response.ok) {
            setS3Key(s3Key);
          }
        }
      } catch (error) {}
    })();
  }, [presignedUrlResponse, file, havePresignedUrl]);

  return [requestPresignedUrlFn, S3Key];
};

export default useUploadFile;
