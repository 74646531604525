import React, { useState } from 'react';
import { Application, useCreateApplicationNoteMutation } from 'generated/graphql';
import TextInput from 'shared/components/TextInput';
import Button, { CreateButton } from 'shared/components/Buttons';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { showToast } from 'shared/components/Toast';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

interface IAddNotesProps {
  application: Application;
  disabled: boolean;
  onCancel: () => void;
}

const AddNote = ({ application, disabled, onCancel }: IAddNotesProps) => {
  const { t } = useTranslation();
  const { id, businessId } = application;
  const notesCount = application.notes.length;
  const [note, setNote] = useState('');
  const [isOpen, setIsOpen] = useState(notesCount === 0);

  const handleCancel = () => {
    setIsOpen(false);
    onCancel();
  };

  const [createApplicationNoteMutation, { loading }] = useCreateApplicationNoteMutation({
    variables: {
      input: {
        applicationId: id,
        businessId,
        comment: note,
      },
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
    onCompleted: () => {
      showToast('Add note successfully', 'success');
      setNote('');
    },
    update: (cache, data) => {
      cache.modify({
        id: cache.identify(application),
        fields: {
          notes: (existingNotes = []) => {
            const newNoteRef = cache.writeFragment({
              data: data.data?.createApplicationNote,
              fragment: gql`
                fragment NewNote on ApplicationNote {
                  id
                  comment
                  createdAt
                  createdBy
                  createdByName
                  createdByAvatar {
                    url
                  }
                  archivedAt
                  lastEditedAt
                  lastEditedByName
                }
              `,
            });
            return [newNoteRef, ...existingNotes];
          },
        },
      });
    },
  });

  const onSubmit = async () => {
    await createApplicationNoteMutation();
    setIsOpen(false);
  };

  if (!isOpen) {
    return (
      <div className="text-right mb-4">
        <CreateButton disabled={disabled} onClick={() => setIsOpen(true)}>
          {t('enrollment.lead-management.notes.new-note')}
        </CreateButton>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column justify-content-around">
      <TextInput
        as="textarea"
        name="note"
        label="Note"
        required
        value={note}
        rows={3}
        onChange={(v) => setNote(v)}
        className="add-notes-section__input"
        autoFocus
      />
      <div className="d-flex justify-content-end mb-2">
        <Button variant="light" size="sm" className="mr-4" onClick={handleCancel}>
          {t('enrollment.lead-management.notes.cancel')}
        </Button>
        <Button variant="primary" size="sm" className="px-6" disabled={!note} onClick={onSubmit} loading={loading}>
          {t('enrollment.lead-management.notes.save-note')}
        </Button>
      </div>
    </div>
  );
};

export default AddNote;
