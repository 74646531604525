import React from 'react';
import Card from 'shared/components/Card';
import { Row, Col } from 'shared/components/Layout';
import doctor from './assets/medical-banner.svg';

const InformationCard = () => (
  <Card>
    <Row className="my-n8">
      <Col xs={12} sm={5}>
        <Row>
          <img src={doctor} alt="doctor" className="mr-4 mt-4" />
          <Col className="my-4">
            <small>Did you know?</small>
            <p className="font-weight-bold text-info mt-2 font-size-18">
              Allergens that may result in anaphylaxis that require use of epinephrine.
            </p>
          </Col>
          <div className="m-4 vertical-divider align-self-stretch"></div>
        </Row>
      </Col>
      <Col xs={12} sm={7}>
        <ul className="ml-4 mb-0 p-0">
          <li className="mb-2">Foods such as peanuts, tree nuts, milk, eggs, fish or shellfish.</li>
          <li className="mb-2">Medications such as penicillin or aspirin.</li>
          <li className="mb-2">
            Bee venom or insect stings, such as from yellow jackets, wasps, hornets, or fire ants.
          </li>
          <li>Latex, such as from gloves.</li>
        </ul>
      </Col>
    </Row>
  </Card>
);

export default InformationCard;
