import React from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';

interface IDeleteProgramProps {
  onHide: () => void;
  onSubmit: () => void;
  loading: boolean;
  show: boolean;
}
const DeleteProgramGroupConfirmModal: React.FC<IDeleteProgramProps> = ({ onHide, onSubmit, show, loading }) => {
  return (
    <ConfirmationModal
      title="Delete Group"
      primaryChoice="Confirm"
      onHide={onHide}
      primaryCallback={onSubmit}
      primaryButtonProps={{ loading }}
      show={show}
      hideOnCallback={false}
    >
      <p>
        Deleting this group will mean all attached programs are no longer assigned to a group for guardians to find.
      </p>
      <p>Are you sure you would like to delete this group? </p>
    </ConfirmationModal>
  );
};

export default DeleteProgramGroupConfirmModal;
