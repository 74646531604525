import React from 'react';
import momentTz from 'moment-timezone';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { Row, Col } from 'shared/components/Layout';
import colors from '_colors.module.scss';
import { convert24HourTimeToFormatted } from 'shared/util/timeUtils';

export const DAYS_OF_WEEK: Record<string, { label: string; value: string }> = {
  SUNDAY: { label: 'S', value: 'Sunday ' },
  MONDAY: { label: 'M', value: 'Monday' },
  TUESDAY: { label: 'Tu', value: 'Tuesday' },
  WEDNESDAY: { label: 'W', value: 'Wednesday' },
  THURSDAY: { label: 'Th', value: 'Thursday' },
  FRIDAY: { label: 'F', value: 'Friday' },
  SATURDAY: { label: 'S', value: 'Saturday' },
};

interface IProps {
  contract: IContract;
  /*
   * IMPORTANT:
   * timeslots should come in pre-sorted by their DayOfWeek by <ContractSchedule />
   * since we're just mapping over the array directly when rendering
   */
  timeslots: IContractTimeslot[];
  centerTimezone: Timezone;
}

const ContractScheduleDays: React.FC<IProps> = ({ contract, timeslots, centerTimezone, ...props }) => {
  return (
    <>
      <Row className="mb-3">
        <Col>
          <div className="mb-2">Days</div>
          <ButtonGroup aria-label="Day selection" className="days-container">
            {timeslots.map((timeslot: IContractTimeslot, idx: number) => {
              if (!timeslot.day && !timeslot.date) {
                return null;
              }

              // @ts-ignore - Ignoring since at this point .day OR .date is not null due to the condition above
              const dayOfWeek = timeslot.day ?? momentTz.tz(timeslot.date, centerTimezone).format('dddd').toUpperCase();
              const day = DAYS_OF_WEEK[dayOfWeek];

              return (
                <Button
                  key={`day-btn-${day.value}-${idx}`}
                  id={`day-${day.value.toLowerCase()}-btn`}
                  className="px-3"
                  variant="secondary"
                >
                  {day.label}
                </Button>
              );
            })}
          </ButtonGroup>
        </Col>
        <Col>
          <div />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="mb-4">Days</div>
          {timeslots.map((timeslot: IContractTimeslot, idx: number) => {
            if (!timeslot.day && !timeslot.date) {
              return null;
            }

            // @ts-ignore
            const dayOfWeek = timeslot.day ?? momentTz(timeslot.date).tz(centerTimezone).format('dddd').toUpperCase();
            const day = DAYS_OF_WEEK[dayOfWeek];

            return (
              <div key={`day-${day.value}-${idx}-text`} className="mb-4">
                {day.value}
              </div>
            );
          })}
        </Col>
        <Col>
          <div className="mb-4">Drop Off</div>
          {timeslots.map((timeslot: IContractTimeslot, idx: number) => (
            <div className="mb-4" key={`timeslot-dropoff-time-${timeslot.id}-${idx}`}>
              <FontAwesomeIcon icon={faClock} size="lg" color={colors.gray} className="mr-1" />
              {timeslot.dropOffTime ? convert24HourTimeToFormatted(timeslot.dropOffTime) : '\u00A0'}
            </div>
          ))}
        </Col>
        <Col>
          <div className="mb-4">Pick Up</div>
          {timeslots.map((timeslot: IContractTimeslot, idx: number) => (
            <div className="mb-4" key={`timeslot-pickup-time-${timeslot.id}-${idx}`}>
              {timeslot.pickUpTime ? convert24HourTimeToFormatted(timeslot.pickUpTime) : '\u00A0'}
            </div>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default ContractScheduleDays;
