import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileSelectDropbox } from 'shared/components/FileOperations';
import { Col, Row } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import TextInput from 'shared/components/TextInput';
import { uniqBy } from 'lodash';
import Select from 'shared/components/Select';
import { IUpdateServiceAddressInput, useUpdateServiceAddress } from '../../graphql/mutations';
import { showToast } from 'shared/components/Toast';
import NotifiableServiceEventsContainer from '../NotifiableServiceEventsContainer';
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

interface IProps {}

interface IServiceAddressChangeInput {
  date: string;
  addressLin1: string;
  addressLine2: string;
  suburb: string;
  state: any;
  postcode: string;
  addressTye: any;
  service: any;
}

const ServiceAddressChange: React.FC<IProps> = ({}) => {
  const emptyInputs = {
    date: '',
    addressLin1: '',
    addressLine2: '',
    suburb: '',
    state: undefined,
    postcode: '',
    addressTye: '',
    service: undefined,
  };

  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<IServiceAddressChangeInput>(emptyInputs);
  const [supportingDocument, setSupportingDocument] = useState<Array<IServiceNameChangeEvidence>>([]);
  const acceptedFileExts = ['.pdf', '.png', '.tiff', '.jpg'];

  const [updateServiceAddress, { loading: updateLoading }] = useUpdateServiceAddress();

  const isFormValid = useMemo(
    () =>
      !!formData.date &&
      !!formData.service &&
      !!formData.service.value &&
      !!formData.addressLin1 &&
      !!formData.state &&
      !!formData.postcode &&
      !!formData.addressTye &&
      !!supportingDocument &&
      supportingDocument.length > 0,
    [formData, supportingDocument]
  );

  const setSelectedFiles = (files: File[]) => {
    const acceptedFiles = uniqBy(files, (f) => f.name).filter((f) =>
      acceptedFileExts.includes('.' + (f.name.split('.').pop()?.toLowerCase() ?? ''))
    );

    const supportingFiles: IServiceNameChangeEvidence[] = acceptedFiles.map((file) => {
      return {
        file: file,
      };
    });

    setSupportingDocument(supportingFiles);
    setFormIsDirty(false);
  };

  const addressTypeOptions = [
    { value: 'ZPHYSICAL', label: 'Physical' },
    { value: 'ZPOSTAL', label: 'Postal' },
  ];

  const handleCancel = () => {
    setFormData(emptyInputs);
    setSupportingDocument([]);
  };
  console.log(formData);
  const handleSave = useCallback(() => {
    updateServiceAddress({
      variables: {
        input: {
          serviceId: formData.service.value,
          startDate: formData.date,
          type: formData.addressTye.value,
          streetLine1: formData.addressLin1,
          streetLine2: formData.addressLine2,
          suburb: formData.suburb,
          state: formData.state.value,
          postcode: formData.postcode,
          supportingDocumentFiles: supportingDocument,
        } as IUpdateServiceAddressInput,
      },
    })
      .then((response) => {
        showToast('Service address was updated successfully.', 'success');
      })
      .catch((err) => {
        err = err.toString().replace('Error: GraphQL error:', '').trim();
        const errorMessage = !!err ? err : 'There was an error.';
        showToast(errorMessage, 'error');
      });
  }, [updateServiceAddress, formData, supportingDocument]);

  return (
    <>
      <NotifiableServiceEventsContainer
        title="Service address change"
        notificationMessage="At least 30 days before the event"
        onCancel={handleCancel}
        onSave={handleSave}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        saveDisabled={!isFormValid}
        isSaving={updateLoading}
        serviceOnChange={(option) => setFormData({ ...formData, service: option })}
        selectedService={formData.service?.value}
        child={
          <>
            <Row>
              <Col className="mb-2">
                <Row>
                  <Col>
                    <DateInput
                      required
                      label="Date of effect"
                      date={formData.date}
                      onDateSelect={(date: string) => setFormData({ ...formData, date: date })}
                      disabled={false}
                      className="mb-4"
                    />
                  </Col>
                  <Col>
                    <Select
                      required
                      inputId="address-type"
                      label={'Type of address'}
                      options={addressTypeOptions}
                      value={addressTypeOptions.find((option) => option.value === formData.addressTye)}
                      onChange={(option) => setFormData({ ...formData, addressTye: option })}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Address 1"
                  name="address1"
                  value={formData.addressLin1}
                  onChange={(value: string) => setFormData({ ...formData, addressLin1: value })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required={false}
                  label="Address 2"
                  name="address2"
                  value={formData.addressLine2}
                  onChange={(value: string) => setFormData({ ...formData, addressLine2: value })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <TextInput
                  required
                  label="Suburb"
                  name="suburb"
                  value={formData.suburb}
                  onChange={(value: string) => setFormData({ ...formData, suburb: value })}
                  disabled={false}
                />
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <Row align="end">
                  <Col md>
                    <Select
                      required
                      inputId="address-state-input"
                      label={'State'}
                      options={STATE_SELECT_OPTIONS['AU']}
                      value={STATE_SELECT_OPTIONS['AU'].find((option) => option.value === formData.state)}
                      onChange={(option) => setFormData({ ...formData, state: option })}
                    />
                  </Col>
                  <Col md>
                    <TextInput
                      required
                      label="Postcode"
                      name="postcode"
                      value={formData.postcode}
                      onChange={(value: string) => setFormData({ ...formData, postcode: value })}
                      disabled={false}
                      maxlength="4"
                    />
                  </Col>
                </Row>
              </Col>
              <Col>{''}</Col>
            </Row>
            <Row>
              <Col className="mb-4">
                <div className="d-flex flex-row">
                  <label className="form-label">
                    Upload Service Approval issued by the State/Teritory Regulatory Authority
                  </label>
                  <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
                </div>
                <FileSelectDropbox
                  value={supportingDocument?.map((d) => d?.file)}
                  onChange={setSelectedFiles}
                  showFiles={true}
                  acceptedFileExts={acceptedFileExts.join(',')}
                />
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default ServiceAddressChange;
