import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/Card';
import DateInput from 'shared/components/DateInput';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import CurrencyInput2 from 'shared/components/TextInput/CurrencyInput2';
import { TargetType } from '../../../interfaces/TargetType';

interface IProps {
  /**
   * Bulk Transaction form data
   */
  bulkTransaction: { description: string; transactionTypeId: string; date: string; amount: number };
  /**
   * A list of possible transaction types for the bulk transaction
   */
  transactionTypeOptions: ITransactionType[];
  transactionTypeLoading: boolean;
  appliesTo: TargetType;
  /**
   * Fires when any of the bulk transaction inputs are updated.
   * @param bulkTransactionUpdates A partial IBulkTransaction
   * @returns
   */
  onChange?: (bulkTransactionUpdates: Partial<IBulkTransaction>) => void;
  /**
   * Fires when the appliesTo changes
   * @param appliesTo
   * @returns
   */
  onAppliesToChange?: (appliesTo: 'Account' | 'Children') => void;
  /**
   * If true disables all form elements in the component
   */
  disabled?: boolean;
  /**
   * Whether or not the bulkTransaction data has been updated. This is passed to the form wrapper to provide functionality around
   * required fields
   */
  isDirty?: boolean;
}

const BulkTransactionDetails: React.FC<IProps> = ({
  bulkTransaction,
  transactionTypeOptions,
  transactionTypeLoading,
  appliesTo,
  onChange,
  onAppliesToChange,
  isDirty,
  disabled: disableForm,
}) => {
  const { t } = useTranslation();
  const handleChange = onChange === undefined ? () => {} : onChange;
  const handleAppliesToChange = onAppliesToChange === undefined ? () => {} : onAppliesToChange;
  return (
    <Card header={<div className="d-flex flex-row align-items-center">Transaction Details</div>}>
      <FormWrapper2 formIsDirty={!!isDirty} toggleDirty={() => {}}>
        <Row align="end">
          <Col xs={12} sm={4} className="order-1">
            <TextInput
              name="name"
              label="Name / Description"
              value={bulkTransaction.description}
              onChange={(value) => handleChange({ description: value })}
              disabled={disableForm}
            />
          </Col>
          <Col xs={12} sm={4} className="order-2">
            <Select
              label="Type"
              value={transactionTypeOptions.find((tt) => tt.id === bulkTransaction.transactionTypeId)}
              options={orderBy(
                (transactionTypeOptions ?? []).filter((t) => t.archivedAt === null && t.metadata.isWritable),
                (t) => t.name,
                'asc'
              )}
              isLoading={transactionTypeLoading}
              getOptionLabel={(option: ITransactionType) => `${option.name} (${option.isCredit ? '+' : '-'})`}
              getOptionValue={(option: ITransactionType) => option.id}
              onChange={(option: any) => {
                handleChange({ transactionTypeId: option.id, transactionType: option });
              }}
              noOptionsMessage={() => {
                return 'No transaction types found.';
              }}
              disabled={disableForm}
            />
          </Col>
          <Col xs={12} sm={2} className="order-3">
            <Form.Group>
              <DateInput
                label="Apply Date"
                date={bulkTransaction.date}
                onDateSelect={(date) => handleChange({ date })}
                placeholder={t('formatters.date')}
                disabled={disableForm}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={2} className="order-4">
            <CurrencyInput2
              className="border-radius-0"
              label="Amount"
              value={bulkTransaction.amount}
              onChange={(value) => handleChange({ amount: value })}
              disabled={disableForm}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="order-5">
            <Form.Group>
              <div className="d-flex flex-row">
                <Form.Label>Apply To</Form.Label>
                {/* <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" /> */}
              </div>
              <Form.Check
                inline
                label={'Account'}
                name="applies-to"
                type="radio"
                checked={appliesTo === TargetType.Account}
                onChange={() => handleAppliesToChange(TargetType.Account)}
                disabled={disableForm}
              />
              <Form.Check
                inline
                label={'Children'}
                name="applies-to"
                type="radio"
                checked={appliesTo === TargetType.Children}
                onChange={() => handleAppliesToChange(TargetType.Children)}
                disabled={disableForm}
              />
            </Form.Group>
          </Col>
        </Row>
      </FormWrapper2>
    </Card>
  );
};

export default BulkTransactionDetails;
