import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateBillingCycleTemplate } from 'gql/billingCycle/mutations';
import Modal from 'react-bootstrap/Modal';
import Button from 'shared/components/Buttons';
import { showToast } from 'shared/components/Toast';
import { updateBillingCycleTemplate } from 'pages/Billing/Settings/duck/actions';
import TextInput from 'shared/components/TextInput';
import PeriodBasedBillingCycleSentence from './PeriodBasedBillingCycleSentence';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';
import Alert from 'shared/components/Alert';

interface IProps {
  isOpen: boolean;
  template: IBillingCycleTemplate;
  onClose: () => void;
  onDelete: (template: IBillingCycleTemplate) => void;
}

const EditPeriodBasedBillingCycleTemplateModal: React.FC<IProps> = ({
  isOpen,
  template,
  onClose,
  onDelete,
  ...props
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IBillingCycleTemplate>({ ...template });
  const [updateTemplateFn, { loading: updateTemplateLoading }] = useUpdateBillingCycleTemplate({
    onCompleted: (data) => {
      if (data.updateBillingCycleTemplate) {
        handleClose();
        showToast(t('billing.toast.billingCycleUpdateSuccess'), 'success');
        dispatch(updateBillingCycleTemplate(data.updateBillingCycleTemplate));
      }
    },
    onError: (error) => {
      showToast(
        `${error.graphQLErrors
          .map((err: any) => {
            return typeof err.message === 'string' ? err.message : err.message?.message?.toString() ?? '';
          })
          .join(', ')}`,
        'error'
      );
    },
  });

  const handleUpdate = useCallback((updates: Partial<IBillingCycleTemplate>) => {
    setFormData((prev) => ({
      ...prev,
      ...updates,
    }));
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const validateForm = useCallback((form: IBillingCycleTemplate): boolean => {
    const isWeeklyFrequency = /WEEKLY/i.test(form.frequency);

    return (
      Boolean(form.name) &&
      form.frequency !== null &&
      form.period !== null &&
      Boolean(isWeeklyFrequency ? form.dayOfWeek !== null : true) &&
      Boolean(!isWeeklyFrequency ? form.dayOfMonth !== null : true)
    );
  }, []);

  const handleSubmit = useCallback(() => {
    // can't do a simple !formData.gracePeriodDays because 0 is a valid value to submit
    const emptyGracePeriodDays =
      formData.gracePeriodDays === null ||
      formData.gracePeriodDays === undefined ||
      // @ts-ignore - typed as a number in the interface but in some cases it appears a string can be saved in state :/
      formData.gracePeriodDays === '';

    updateTemplateFn({
      variables: {
        input: {
          id: formData.id,
          businessId: formData.businessId,
          name: formData.name,
          frequency: formData.frequency,
          period: formData.period,
          dayOfMonth: formData.dayOfMonth ?? null,
          dayOfWeek: formData.dayOfWeek ?? null,
          invoiceSchedule: {
            unit: formData.invoiceSchedule.unit,
            value: formData.invoiceSchedule.value,
          },
          frequencyPayPeriod: formData.frequencyPayPeriod,
          // if null, undefined, or an empty string end up in this property then send null to the api
          gracePeriodDays: !emptyGracePeriodDays ? formData.gracePeriodDays : null,
          lateChargeAmount: formData.lateChargeAmount ?? null,
        },
      },
    });
  }, [updateTemplateFn, formData]);

  return (
    <Modal centered backdrop="static" show={isOpen} onHide={handleClose} size="xl">
      <Modal.Header closeButton className="border-bottom-0 pb-4">
        <div className="d-flex flex-column">
          <Modal.Title as="h3">
            {capitalize(t('spelling.edit'))} {capitalize(t('spelling.billing'))} {capitalize(t('spelling.cycle'))}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0">
        {template.accountIds.length > 0 && (
          <Alert variant="warning" className="box-shadow-0 mb-6">
            {template.accountIds.length === 1
              ? t('billing.settings.cycles.edit-billing-cycle-modal.one-account-assigned', {
                  count: template.accountIds.length,
                })
              : t('billing.settings.cycles.edit-billing-cycle-modal.multiple-accounts-assigned', {
                  count: template.accountIds.length,
                })}
          </Alert>
        )}
        <h5 className="mb-6">{t('billing.settings.cycles.editCycleCompleteThisSentence')}</h5>
        <PeriodBasedBillingCycleSentence cycle={formData} onUpdate={handleUpdate} />
        <TextInput
          label={t('billing.settings.cycles.billingCycleName')}
          value={formData.name}
          onChange={(value: string) => setFormData((prev) => ({ ...prev, name: value }))}
          placeholder={t('billing.settings.cycles.billingCycleName')}
          className="w-50"
          isInvalid={!formData.name}
          errorText={t('billing.error.billingCyleNameRequired')}
        />
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div>
          <Button id="edit-cycle-modal-deactivate-btn" variant="outline-danger" onClick={() => onDelete(template)}>
            {capitalize(t('spelling.delete'))} {capitalize(t('spelling.cycle'))}
          </Button>
        </div>
        <div className="ml-auto">
          <Button id="edit-cycle-modal-secondary-btn" className="mr-3" variant="light" onClick={handleClose}>
            {capitalize(t('spelling.cancel'))}
          </Button>
          <Button
            id="edit-cycle-modal-primary-btn"
            variant="primary"
            disabled={updateTemplateLoading || !validateForm(formData)}
            loading={updateTemplateLoading}
            onClick={() => handleSubmit()}
          >
            {capitalize(t('spelling.save'))}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPeriodBasedBillingCycleTemplateModal;
