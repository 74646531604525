import * as Mui from '@mui/material';

const ArrayOf = (x: number) => Array.from(Array(x).keys());

export const Skeleton = () => (
  <Mui.Grid container spacing={2} style={{ width: '100%' }}>
    <Mui.Grid item xs={8}>
      {ArrayOf(4).map((i) => (
        <Mui.Skeleton key={i} />
      ))}
    </Mui.Grid>
    <Mui.Grid item xs={4}>
      {ArrayOf(4).map((i) => (
        <Mui.Skeleton key={i} />
      ))}
    </Mui.Grid>
    <Mui.Grid item xs={12}>
      {ArrayOf(4).map((i) => (
        <Mui.Skeleton key={i} />
      ))}
    </Mui.Grid>
    <Mui.Grid item xs={12}>
      {ArrayOf(20).map((i) => (
        <Mui.Skeleton variant="rectangular" key={i} />
      ))}
    </Mui.Grid>
  </Mui.Grid>
);
