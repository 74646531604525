import React, { useCallback, useMemo, useState } from 'react';
import Button from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import { Row } from 'shared/components/Layout';
import CentersWithSurchargeTable from './CentersWithSurchargeTable';
import { TableSearch } from 'shared/components/DataTable';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetCenterBillingSettingsByBusiness } from 'gql/center/queries';
import { showToast } from 'shared/components/Toast';
import { useTranslation } from 'react-i18next';
import {
  useUpdatePassThroughDishonor,
  useUpdatePassThroughSurcharge,
  useUpdatePassThroughSurchargeForAll,
} from 'gql/center/mutations';
import { orderBy, get } from 'lodash';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import CurrencyInput from 'shared/components/TextInput/CurrencyInput2';
import { SurchargesBillingSetting } from './SurchargesBillingSettings';
import { isRegion } from 'shared/util/region';

interface IProps {}

const CreateBillingFeesSearchCard: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortField, setSortField] = useState<{ field: string; direction: 'asc' | 'desc' }>({
    field: 'name',
    direction: 'asc',
  });
  const [tableData, setTableData] = useState<SurchargesBillingSetting[]>([]);
  const user = useSelector((state: RootState) => state.user);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const businessId = useMemo(
    () => (user?.isInternal && currentBusinessId != null ? currentBusinessId : user?.entityId ?? ''),
    [currentBusinessId, user?.entityId, user?.isInternal]
  );

  const { loading: getCenterListLoading, data: getCenterListData } = useGetCenterBillingSettingsByBusiness({
    variables: {
      input: {
        businessId,
      },
    },
    onCompleted: (result) => {
      setTableData(result.getCenterBillingSettingsByBusiness ?? []);
    },
  });

  const [updatePassThroughSurchargeFn] = useUpdatePassThroughSurcharge({
    onCompleted: (result) => {
      showToast('Updated surcharge pass through successfully.', 'success');
      setTableData((prev) =>
        prev.map((settings) =>
          settings.centerId === result.updatePassThroughSurcharge.centerId
            ? { ...settings, ...result.updatePassThroughSurcharge }
            : settings
        )
      );
    },
    onError: (err) => {
      showToast('There was an error updating surcharge pass through. Please try again later.', 'error');
    },
  });

  const [updatePassThroughDishonorFn] = useUpdatePassThroughDishonor({
    onCompleted: (result) => {
      showToast(`Updated ${t('spelling.dishonor')} pass through successfully.`, 'success');
      setTableData((prev) =>
        prev.map((settings) =>
          settings.centerId === result.updatePassThroughDishonor.centerId
            ? { ...settings, ...result.updatePassThroughDishonor }
            : settings
        )
      );
    },
    onError: (err) => {
      showToast(`There was an error updating ${t('spelling.dishonor')} pass through. Please try again later.`, 'error');
    },
  });

  const [updatePassThroughSurchargeForAllFn, { loading: updatePassThroughSurchargeForAllLoading }] =
    useUpdatePassThroughSurchargeForAll({
      onCompleted: (result) => {
        showToast('Updated surcharge pass through successfully.', 'success');
        updateData(result.updatePassThroughSurchargeForAll);
      },
      onError: (err) => {
        showToast('There was an error updating surcharge pass through. Please try again later.', 'error');
      },
    });

  const handleSurchargeClick = useCallback(
    (businessId: string, passThroughSurcharge: boolean) => {
      updatePassThroughSurchargeForAllFn({
        variables: {
          input: {
            businessId,
            passThroughSurcharge: passThroughSurcharge,
            passThroughAchSurcharge: user?.isInternal || !passThroughSurcharge ? passThroughSurcharge : null, // If the user is internal, allow any boolean; otherwise only allow false; if not internal and true, return null to not change the setting
            passThroughCreditSurcharge: passThroughSurcharge,
          },
        },
      });
    },
    [updatePassThroughSurchargeForAllFn, user?.isInternal]
  );

  const updateData = useCallback((updates: SurchargesBillingSetting[]) => {
    setTableData((prev) => {
      const updatesKeydByCenterId: Record<string, SurchargesBillingSetting> = updates.reduce(
        (acc, curr) => ({ ...acc, [curr.centerId]: curr }),
        {}
      );

      return prev.map((settings) =>
        updatesKeydByCenterId[settings.centerId]
          ? { ...settings, ...updatesKeydByCenterId[settings.centerId] }
          : settings
      );
    });
  }, []);

  const filteredTableData = useMemo((): SurchargesBillingSetting[] => {
    const activeCenters = tableData.filter((c) => !c.center.deactivatedAt);
    if (searchTerm.trim() === '') {
      return orderBy(activeCenters, (row) => get(row, sortField.field), [sortField.direction]);
    }

    return orderBy(
      activeCenters.filter(
        (row) =>
          row.center.name.toLowerCase().includes(searchTerm) ||
          row.center.address?.city.toLowerCase().includes(searchTerm) ||
          row.center.address?.state.toLowerCase().includes(searchTerm)
      ),
      (row) => get(row, sortField.field),
      [sortField.direction]
    );
  }, [searchTerm, sortField.direction, sortField.field, tableData]);

  const [turningDishonorOnFor, setTurningDishonorOnFor] = useState<SurchargesBillingSetting | undefined>(undefined);
  const [dishonorFeeAmount, setDishonorFeeAmount] = useState<number>();
  const handleDishonorToggle = useCallback(
    (centerId: string, passThroughDishonor: boolean) => {
      if (passThroughDishonor && !isRegion('AU')) {
        const setting = tableData.find((d) => d.centerId === centerId);
        if (setting?.dishonorFeeAmount !== undefined) {
          setDishonorFeeAmount(setting.dishonorFeeAmount);
        }
        setTurningDishonorOnFor(setting);
      } else {
        updatePassThroughDishonorFn({ variables: { input: { centerId, passThroughDishonor } } });
      }
    },
    [tableData, updatePassThroughDishonorFn]
  );

  const handleDishonorFeeConfirm = useCallback(() => {
    if (!turningDishonorOnFor) return;
    updatePassThroughDishonorFn({
      variables: { input: { centerId: turningDishonorOnFor.centerId, passThroughDishonor: true, dishonorFeeAmount } },
    });
    setTurningDishonorOnFor(undefined);
    setDishonorFeeAmount(undefined);
  }, [dishonorFeeAmount, turningDishonorOnFor, updatePassThroughDishonorFn]);

  const handleDishonorFeeCancel = useCallback(() => {
    setTurningDishonorOnFor(undefined);
    setDishonorFeeAmount(undefined);
  }, []);

  return (
    <>
      <Card bodyClassName="px-8 py-4">
        <Row className="flex-nowrap">
          <TableSearch onChange={setSearchTerm} placeholder={t('spelling.searchCenters')} />
          <HasRoleAreaLevel
            action={{ area: AreaType.Billing, permission: PermissionType.Surcharges, level: RoleLevelType.Edit }}
          >
            <div className="d-flex flex-1 justify-content-end">
              <Button
                variant="outline-primary"
                className="mr-4"
                loading={updatePassThroughSurchargeForAllLoading}
                onClick={() => handleSurchargeClick(businessId, false)}
              >
                Turn surcharge OFF for all {t('spelling.center')}s
              </Button>
              <Button
                loading={updatePassThroughSurchargeForAllLoading}
                onClick={() => handleSurchargeClick(businessId, true)}
              >
                Turn surcharge ON for all {t('spelling.center')}s
              </Button>
            </div>
          </HasRoleAreaLevel>
        </Row>
      </Card>
      <CentersWithSurchargeTable
        isLoading={getCenterListLoading && !getCenterListData}
        data={filteredTableData}
        onSortColumn={(field: string, direction: 'asc' | 'desc') => setSortField({ field, direction })}
        onCenterPassThroughDishonorToggle={handleDishonorToggle}
        onCenterPassThroughAchToggle={(centerId, passThroughAchSurcharge) =>
          updatePassThroughSurchargeFn({ variables: { input: { centerId, passThroughAchSurcharge } } })
        }
        onCenterPassThroughCreditToggle={(centerId, passThroughCreditSurcharge) =>
          updatePassThroughSurchargeFn({ variables: { input: { centerId, passThroughCreditSurcharge } } })
        }
        noPadding
      />
      <ConfirmationModal
        show={turningDishonorOnFor !== undefined}
        primaryCallback={handleDishonorFeeConfirm}
        primaryChoice="Set Dishonor Fee"
        secondaryCallback={handleDishonorFeeCancel}
        title={`Set dishonor fee for ${turningDishonorOnFor?.center.name ?? ''}`}
        onHide={handleDishonorFeeCancel}
      >
        <CurrencyInput
          label="Dishonor Fee Amount"
          value={dishonorFeeAmount}
          onChange={setDishonorFeeAmount}
        ></CurrencyInput>
      </ConfirmationModal>
    </>
  );
};

export default CreateBillingFeesSearchCard;
