import { QueryHookOptions } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import { gql } from '@apollo/client';

const GET_PRESIGNED_URL = gql`
  query {
    getPutSignedUrl {
      url
      fields
      provisionalGetUrl
    }
  }
`;

export const useGetS3PresignedURL = (
  options?: QueryHookOptions<IGetPutS3UploadURLResponse, IGetPutS3UploadURLInput>,
  fields?: string
) =>
  useLazyQuery<IGetPutS3UploadURLResponse, IGetPutS3UploadURLInput>(GET_PRESIGNED_URL, {
    fetchPolicy: 'no-cache',
    skip: false,
    ...options,
  });
