import React, { useState, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle, faExclamationTriangle, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import MaskedInput from 'react-text-mask';
import colors from '_colors.module.scss';
import { isValidCrn } from './isValidCrn';

interface IProps {
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  isInvalid?: boolean;
  errorText?: object | string | Node;
  className?: string;
  onChange?: (value: string, name: string) => void;
  value?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onFocus?: any;
  onBlur?: any;
  onValidityChanged?: (value: boolean) => void;
}

interface IInputHelperState {
  borderClass: 'success' | 'error' | 'focused' | '';
  icon: IconDefinition | null;
  iconColor: string | null;
}

const CrnInput: React.FC<IProps> = ({
  id = '',
  name = 'crn',
  label,
  required,
  isInvalid,
  errorText = 'Invalid CRN. CRN format is 9 digits followed by a letter',
  className,
  onChange = () => {},
  value = '',
  disabled = false,
  autoFocus = false,
  onFocus,
  onBlur,
  onValidityChanged,
  ...props
}) => {
  const [isFocused, setFocus] = useState(autoFocus);
  const [touched, setTouched] = useState(autoFocus);
  const [inputHelperState, setInputHelperState] = useState<IInputHelperState>({
    borderClass: '',
    icon: null,
    iconColor: null,
  });

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setTouched(true);
      let crnValue = value.replace(/\s/g, '');
      crnValue = crnValue.toUpperCase();
      onChange(crnValue, name);
    },
    [onChange, name]
  );

  const handleFocus = useCallback(
    (e) => {
      setFocus(true);
      onFocus && onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      setTouched(true);
      setFocus(false);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  useEffect(() => {
    if (isFocused) {
      setInputHelperState({
        borderClass: 'focused',
        icon: null,
        iconColor: null,
      });
    } else if (touched) {
      const crnValue = value.replace(/\s/g, '');
      const valid = isValidCrn(crnValue) || (crnValue === '' && !required);

      const hasError = !valid || isInvalid;

      if (onValidityChanged && !isInvalid) {
        onValidityChanged(valid);
      }

      if (required && !valid) {
        setInputHelperState({
          borderClass: 'error',
          icon: faExclamationTriangle,
          iconColor: colors.danger,
        });
      } else {
        setInputHelperState({
          borderClass: hasError ? 'error' : 'success',
          icon: hasError ? faExclamationTriangle : faCheckCircle,
          iconColor: hasError ? colors.danger : colors.success,
        });
      }
    }
  }, [touched, isInvalid, required, value, isFocused]);

  return (
    <div className={`mb-4 ${className || ''}`}>
      {label && (
        <div className="d-flex flex-row">
          <label>{label}</label>
          {required && !disabled && <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />}
        </div>
      )}
      <div
        className={`d-flex flex-row align-items-center ${
          inputHelperState.borderClass && 'kt-crn-input-' + inputHelperState.borderClass
        } kt-crn-input-container ${disabled ? 'kt-crn-input-container-disabled' : ''} ${
          touched && isInvalid ? 'is-invalid form-control' : ''
        }`}
      >
        <MaskedInput
          id={id}
          name={name}
          mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /[A-Za-z]/]}
          className={`kt-crn-input`}
          guide={true}
          placeholder={'___ ___ ____'}
          onChange={handleChange}
          value={value}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={disabled}
          autoComplete="off"
          spellCheck={false}
        />
        {inputHelperState.icon && (
          <div className="d-flex input-icon-container">
            <FontAwesomeIcon
              className="input-icon"
              icon={inputHelperState.icon}
              color={`${inputHelperState.iconColor}`}
            />
          </div>
        )}
      </div>
      <div className="invalid-feedback">{errorText}</div>
    </div>
  );
};

export default CrnInput;
