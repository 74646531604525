import moment from 'moment';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { displayDuration } from 'shared/util/timeUtils';
interface IProps {
  data: IISCase;
}

const ISCaseRawHoursColumn: React.FC<IProps> = ({ data }) => {
  const {
    nonFaceToFaceHoursAllowed,
    supportHoursAllowed,
    weeklyHoursAllowed,
    weeklyTolerance,
    supportHoursBalance,
    nonFaceToFaceHoursBalance,
    dateLastWithdrawn,
    dateLastReactivated,
    dateLastInactivated,
  } = data;

  return (
    <Col className="col-lg-5 col-xl-5 col-md-12 col-sm-12 col-12 raw-hours-column">
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Support Hours Allowed:{' '}
        </Col>
        <Col md={2} lg={4} xl={3} className="raw-hours-value">
          {displayDuration(supportHoursAllowed)}
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Support Hours Balance:{' '}
        </Col>
        <Col md={2} lg={4} xl={3} className="raw-hours-value">
          {displayDuration(supportHoursBalance)}
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Non-face To Face Hours Balance:{' '}
        </Col>
        <Col md={2} lg={4} xl={3} className="raw-hours-value">
          {displayDuration(nonFaceToFaceHoursBalance)}
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Non-face To Face Hours Allowed:{' '}
        </Col>
        <Col md={2} lg={4} xl={3} className="raw-hours-value">
          {displayDuration(nonFaceToFaceHoursAllowed)}
        </Col>
      </Row>

      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Weekly Hours Allowed:{' '}
        </Col>
        <Col md={2} lg={4} xl={3} className="raw-hours-value">
          {displayDuration(weeklyHoursAllowed)}
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Weekly Tolerance:{' '}
        </Col>
        <Col md={2} lg={4} xl={3} className="raw-hours-value">
          {displayDuration(weeklyTolerance)}
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Date Last Withdrawn:{' '}
        </Col>
        <Col md={2} lg={4} xl={3} className="raw-hours-value">
          {dateLastWithdrawn ? moment(dateLastWithdrawn).format('DD/MM/YYYY') : ''}
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Date Last Reactivated:{' '}
        </Col>
        <Col md={2} lg={4} xl={3} className="raw-hours-value">
          {moment(dateLastReactivated).format('DD/MM/YYYY')}
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={5} className="font-weight-semi-bold">
          Date Last Inactivated:{' '}
        </Col>
        <Col md={2} lg={4} xl={3} className="raw-hours-value">
          {dateLastInactivated ? moment(dateLastInactivated).format('DD/MM/YYYY') : ''}
        </Col>
      </Row>
    </Col>
  );
};

export default ISCaseRawHoursColumn;
