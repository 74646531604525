import { QueryHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';
import statementFields, { centerStatementRunsPagedResult, centerStatementsPagedResult } from './fields';

export interface IGetCenterStatementScheduleVariables {
  centerId: string;
}

export interface IGetCenterStatementScheduleData {
  getCenterStatementSchedule: ICenterStatementSchedule;
}

export const GET_CENTER_STATEMENT_SCHEDULE = gql`
  query($centerId: ID!) {
    getCenterStatementSchedule(centerId: $centerId) {
      ${statementFields}
    }
  }
`;

export const useGetCenterStatementSchedule = (
  options?: QueryHookOptions<IGetCenterStatementScheduleData, IGetCenterStatementScheduleVariables>
) =>
  useQuery(GET_CENTER_STATEMENT_SCHEDULE, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export interface IGetCenterStatementMessageVariables {
  centerId: string;
}

export interface IGetCenterStatementMessageData {
  getCenterStatementMessage?: string;
}

export const GET_CENTER_STATEMENT_MESSAGE = gql`
  query ($centerId: ID!) {
    getCenterStatementMessage(centerId: $centerId)
  }
`;

export const useGetCenterStatementMessage = (
  options?: QueryHookOptions<IGetCenterStatementMessageData, IGetCenterStatementMessageVariables>
) =>
  useQuery(GET_CENTER_STATEMENT_MESSAGE, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

interface IGetCenterStatementRunsData {
  getCenterStatementRuns: IPagedResult<IStatementRun>;
}

export const GET_CENTER_STATEMENT_RUNS = gql`
  query($input: GetCenterStatementRunsInput!) {
    getCenterStatementRuns(input: $input) {
      ${centerStatementRunsPagedResult}
    }
  }
`;

export const useGetCenterStatementRuns = (
  options?: QueryHookOptions<IGetCenterStatementRunsData, IGetCenterStatementRunsVariables>
) =>
  useQuery(GET_CENTER_STATEMENT_RUNS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

interface IGetCenterStatementsForAccountData {
  getCenterStatementsForAccount: IPagedResult<IStatement>;
}
interface IGetCenterStatementsForAccountVariables {
  input: {
    centerId: string;
    accountId: string;
    pageNumber?: number;
    pageSize?: number;
    sortDtos?: IElasticsearchSortFilter[];
    startDate?: string;
    endDate?: string;
  };
}

export const GET_CENTER_STATEMENTS_FOR_ACCOUNT = gql`
  query($input: GetCenterStatementsForAccountInput!) {
    getCenterStatementsForAccount(input: $input) {
      ${centerStatementsPagedResult}
    }
  }
`;

export const useGetCenterStatementsForAccount = (
  options?: QueryHookOptions<IGetCenterStatementsForAccountData, IGetCenterStatementsForAccountVariables>
) =>
  useQuery(GET_CENTER_STATEMENTS_FOR_ACCOUNT, {
    fetchPolicy: 'cache-and-network',
    ...options,
    skip: !(options?.variables?.input.accountId && options?.variables?.input.centerId),
  });

export const GET_CALCULATE_DATE = gql`
  query ($input: GetDateCalculateInput!) {
    getDateCalculate(input: $input) {
      fromDate
      toDate
    }
  }
`;

export const useGetDateCalculateLazy = (
  options?: QueryHookOptions<IGetDateCalculateData, IGetGetDateCalculateVariables>
) =>
  useLazyQuery(GET_CALCULATE_DATE, {
    ...options,
  });

export const useGetDateCalculate = (options?: QueryHookOptions<IGetDateCalculateData, IGetGetDateCalculateVariables>) =>
  useQuery(GET_CALCULATE_DATE, {
    ...options,
    skip: !options?.variables?.input.centerId,
  });

export interface IGetStatementLinkVariables {
  input: {
    centerId: string;
    statementId: string;
  };
}

export interface IGetStatementLinkData {
  getStatementLink: string;
}

export const GET_STATEMENT_LINK = gql`
  query ($input: IGetStatementLinkInput!) {
    getStatementLink(input: $input)
  }
`;

export const useGetStatementLink = (options?: QueryHookOptions<IGetStatementLinkData, IGetStatementLinkVariables>) =>
  useLazyQuery(GET_STATEMENT_LINK, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data: IGetStatementLinkData) => {
      window.open(data.getStatementLink);
    },
    ...options,
  });
