import React, { useState } from 'react';
import moment from 'moment';
import useDatatableState from 'shared/hooks/useDatatableState2';
import { useGetCcssEvents } from 'gql/ccssMessages/queries';
import CCSSEventsTable from '../components/CCSSEventTable/CCSSEventsTable';

interface IProps {
  selectedCenters: ITableFilterOption[];
  selectedBusiness: ITableFilterOption | null;
}

interface ISort {
  field: string;
  direction: ElasticsearchSortDirection;
}

const CCSSEventTab: React.FC<IProps> = ({ selectedBusiness, selectedCenters }) => {
  const businessId = selectedBusiness?.value || '';
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [activeFilters, setActiveFilters] = useState<IEventFilter>({ type: undefined });
  const [tableState, tableFunctions] = useDatatableState();
  const [sort, setSortOptions] = useState<ISort[]>([{ field: 'startDate', direction: 'DESCENDING' }]);

  const isRangeValid = () => {
    if (dateRange.start !== '' && dateRange.end !== '') {
      return moment(dateRange.start).isAfter(moment(dateRange.end));
    }
    return true;
  };

  const { data, loading, refetch } = useGetCcssEvents({
    variables: {
      input: {
        businessId,
        centerIds: selectedCenters.map((center: ITableFilterOption) => center.value),
        pageNumber: tableState.activePage,
        pageSize: tableState.pageSize,
        sortDtos: sort,
        startDate:
          dateRange.start !== '' && isRangeValid() ? moment(dateRange.start).hour(0).utc().format() : undefined,
        endDate: dateRange.end !== '' && isRangeValid() ? moment(dateRange.end).hour(0).utc().format() : undefined,
        type: activeFilters.type ?? undefined,
      },
    },
  });

  const handleSort = (field: string, direction: ElasticsearchSortDirection) => {
    setSortOptions([{ field, direction }]);
  };

  const handleFilterChange = (value: IEventFilter) => {
    setActiveFilters((state) => ({
      ...state,
      ...value,
    }));
  };

  const messages = data?.getCcssEvents?.data || [];

  return (
    <>
      {selectedBusiness && (
        <CCSSEventsTable
          setDateRange={setDateRange}
          dateRange={dateRange}
          loading={loading}
          data={messages}
          dataSize={data?.getCcssEvents?.totalRecords || 0}
          pageSize={tableState.pageSize}
          activePage={tableState.activePage}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          activeFilters={activeFilters}
          onActiveFilterChange={handleFilterChange}
          onSort={handleSort}
          selectedRows={tableState.selectedRows}
          updateSelectedRows={tableFunctions.updateSelectedRows}
        />
      )}
    </>
  );
};

export default CCSSEventTab;
