import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { integrationApiKeyFields } from './fields';
import { ApiScopeAuthType } from '../../../src/gql/integrationApiKey/queries';

export interface IApiScopesTypeInput {
  business?: ApiScopeAuthType;
  centers?: ApiScopeAuthType;
  classes?: ApiScopeAuthType;
  fees?: ApiScopeAuthType;
  sessions?: ApiScopeAuthType;
  applications?: ApiScopeAuthType;
  attendances?: ApiScopeAuthType;
  staff?: ApiScopeAuthType;
  accounts?: ApiScopeAuthType;
  children?: ApiScopeAuthType;
  contacts?: ApiScopeAuthType;
  transactions?: ApiScopeAuthType;
  deposits?: ApiScopeAuthType;
  entitlements?: ApiScopeAuthType;
}

interface IIntegrationApiKey {
  id: string;
  key: string;
  createdBy: string;
  description?: string;
  businessId: string;
  createdAt: string;
  revokedAt: string;
  isDeleted: boolean;
  centerScopes: string[] | null;
  apiScopes: IApiScopesTypeInput;
}

interface ICreateIntegrationApiKeyInput {
  businessId: string;
  description: string;
  centerScopes: string[] | null;
  apiScopes: IApiScopesTypeInput | null;
}

interface IUpdateIntegrationApiKeyInput {
  id: string;
  businessId: string;
  description: string;
  revoked: boolean;
  centerScopes: string[] | null;
  apiScopes: IApiScopesTypeInput | null;
}

export const CREATE_INTEGRATION_API_KEY = gql`
  mutation($input: CreateIntegrationApiKeyInput ) {
    createIntegrationApiKey(input: $input) {
      ${integrationApiKeyFields}
    }
  }
`;

export const UPDATE_INTEGRATION_API_KEY = gql`
  mutation($input: UpdateIntegrationApiKeyInput ) {
    updateIntegrationApiKey(input: $input) {
      ${integrationApiKeyFields}
    }
  }
`;

export const DELETE_INTEGRATION_API_KEY = gql`
  mutation($id: ID!, $bid: ID! ) {
    deleteIntegrationApiKey(id: $id, bid: $bid) {
      ${integrationApiKeyFields}
    }
  }
`;

export const useCreateIntegrationApiKey = (
  input?: MutationHookOptions<{ createIntegrationApiKey: IIntegrationApiKey }, { input: ICreateIntegrationApiKeyInput }>
) =>
  useMutation<{ createIntegrationApiKey: IIntegrationApiKey }, { input: ICreateIntegrationApiKeyInput }>(
    CREATE_INTEGRATION_API_KEY,
    input
  );

export const useUpdateIntegrationApiKey = (
  input?: MutationHookOptions<{ updateIntegrationApiKey: IIntegrationApiKey }, { input: IUpdateIntegrationApiKeyInput }>
) =>
  useMutation<{ updateIntegrationApiKey: IIntegrationApiKey }, { input: IUpdateIntegrationApiKeyInput }>(
    UPDATE_INTEGRATION_API_KEY,
    input
  );

export const useDeleteIntegrationApiKey = (
  input?: MutationHookOptions<{ deleteIntegrationApiKey: IIntegrationApiKey }, { id: string; bid: string }>
) =>
  useMutation<{ deleteIntegrationApiKey: IIntegrationApiKey }, { id: string; bid: string }>(
    DELETE_INTEGRATION_API_KEY,
    input
  );
