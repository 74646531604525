export const GET_CENTERS_SUCCESS = 'GET_CENTERS_SUCCESS';
export const GET_CENTER_SUCCESS = 'GET_CENTER_SUCCESS';
export const CREATE_CENTER = 'CREATE_CENTER';
export const UPDATE_CENTER = 'UPDATE_CENTER';
export const REMOVE_CENTER_FROM_STORE = 'REMOVE_CENTER_FROM_STORE';
export const UPDATE_CENTERS_TABLE_FILTERS = 'UPDATE_CENTERS_TABLE_FILTERS';

export interface IUpdateCentersTableFilters_Status {
  active: boolean;
  deactivated: boolean;
  deleted: boolean;
}

interface IGetCentersSuccessAction {
  type: typeof GET_CENTERS_SUCCESS;
  centers: ICenter[];
}

interface IGetCenterSuccessAction {
  type: typeof GET_CENTER_SUCCESS;
  center: ICenter;
}

interface ICreateCenterAction {
  type: typeof CREATE_CENTER;
  center: ICenter;
}

interface IUpdateCenterAction {
  type: typeof UPDATE_CENTER;
  center: ICenter;
}

interface IRemoveCenterFromStoreAction {
  type: typeof REMOVE_CENTER_FROM_STORE;
  id: string;
}

interface IUpdateCentersTableFiltersAction {
  type: typeof UPDATE_CENTERS_TABLE_FILTERS;
  filters: {
    status: IUpdateCentersTableFilters_Status;
  };
}

export type CentersActionTypes =
  | IGetCentersSuccessAction
  | IGetCenterSuccessAction
  | ICreateCenterAction
  | IUpdateCenterAction
  | IUpdateCentersTableFiltersAction
  | IRemoveCenterFromStoreAction;
