import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { paginatedGLCodes, paginatedGLCodeAssignments, billingBusinessSettingsFields, glCodeFields } from './fields';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';

interface IGetGLCodesData {
  getGLCodes: PagedDto<IGLCode>;
}

export const GET_GL_CODES = (fields: string = paginatedGLCodes) => gql`
  query ($input: GetGLCodesInput!){
    getGLCodes(input: $input) {
      ${paginatedGLCodes}
    }
  }
`;

export const useGetGLCodes = (options?: QueryHookOptions<IGetGLCodesData, Input<IGetGLCodeInput>>, fields?: string) =>
  useQuery<IGetGLCodesData, Input<IGetGLCodeInput>>(GET_GL_CODES(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

export const GET_GL_CODE_ASSIGNMENTS = (fields: string = paginatedGLCodeAssignments) => gql`
  query($input: GetGLCodeAssignmentsInput!) {
    getGLCodeAssignments(input: $input) {
      ${fields}
    }
  }
`;

interface IGetGLCodeAssignmentsInput {
  businessId: string;
  pageNumber: number;
  pageSize: number;
  sortDtos: ISearchSort[];
  areaType?: GLCodeAreaType;
  searchText?: string;
  centerIds?: string[];
  hideMapped?: boolean;
}

interface IGetGLCodeAssignmentsData {
  getGLCodeAssignments: PagedDto<IGLCodeAssignment>;
}

export const useGetGLCodeAssignments = (
  options?: QueryHookOptions<IGetGLCodeAssignmentsData, Input<IGetGLCodeAssignmentsInput>>,
  fields?: string
) =>
  useQuery<IGetGLCodeAssignmentsData, Input<IGetGLCodeAssignmentsInput>>(GET_GL_CODE_ASSIGNMENTS(fields), {
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
    ...options,
  });

export const GET_BILLING_BUSINESS_SETTINGS = gql`
    query ($businessId: ID!) {
      fetchBillingBusinessSettings(businessId: $businessId) {
        ${billingBusinessSettingsFields}
      }
    }
  `;
interface IGetBillingBusinessSettingsData {
  fetchBillingBusinessSettings: IBillingBusinessSettings;
}

export const useGetBillingBusinessSettings = (
  options?: QueryHookOptions<IGetBillingBusinessSettingsData, TenantAware>
) =>
  useQuery<IGetBillingBusinessSettingsData, TenantAware>(GET_BILLING_BUSINESS_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
    ...options,
  });

export const GET_GL_CODE_COUNT = (fields: string = glCodeFields) => gql`
  query ($input: GetGLCodeCountInput!) {
    getGLCodeCount(input: $input)
  }
`;

interface getGLCodeCountResponse {
  getGLCodeCount: number;
}

export const useGetGLCodeCount = (
  options?: QueryHookOptions<getGLCodeCountResponse, Input<IGetGLCodeCountInput>>,
  fields?: string
) =>
  useQuery<getGLCodeCountResponse, Input<IGetGLCodeCountInput>>(GET_GL_CODE_COUNT(fields), {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
