import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'shared/components/Layout';
import { Box } from 'shared/components/LoadingSkeletons';
import { paymentStatusColors } from 'shared/constants/tagColors';
import useWindowSize from 'shared/hooks/useWindowSize';
import { currencyFormat } from 'shared/util/currency';
import { isRegion } from 'shared/util/region';
import i18n from 'i18n';

export const helperText: Record<PaymentStatus, string> = {
  COMPLETED: i18n.t('billing:payment-status-text.completed'),
  FAILED: i18n.t('billing:payment-status-text.failed'),
  PENDING: i18n.t('billing:payment-status-text.pending'),
  UNSUBMITTED: i18n.t('billing:payment-status-text.unsubmitted'),
  FLAGGED: i18n.t('billing:payment-status-text.flagged'),
  RETURNED: i18n.t('billing:payment-status-text.returned'),
  CANCELLED: i18n.t('billing:payment-status-text.cancelled'),
  REFUNDED: i18n.t('billing:payment-status-text.refunded'),
};

export const statusText: Record<PaymentStatus, string> = {
  COMPLETED: i18n.t('billing:payment-status-label.completed'),
  FAILED: i18n.t('billing:payment-status-label.failed'),
  PENDING: i18n.t('billing:payment-status-label.pending'),
  UNSUBMITTED: i18n.t('billing:payment-status-label.unsubmitted'),
  FLAGGED: i18n.t('billing:payment-status-label.flagged'),
  RETURNED: i18n.t('billing:payment-status-label.returned'),
  CANCELLED: i18n.t('billing:payment-status-label.cancelled'),
  REFUNDED: i18n.t('billing:payment-status-label.refunded'),
};

const getStatusLabel = (status: PaymentStatus) => {
  if (status !== 'FAILED') {
    return statusText[status];
  }

  return 'Unsuccessful';
};

interface IProps {
  status: PaymentStatus;
  count: number;
  total: number;
  loading: boolean;
  onClick: () => void;
  className?: string;
  isSelected: boolean;
}

const PaymentStatusFilterCard: React.FC<IProps> = ({
  status,
  count,
  total,
  loading,
  onClick,
  className,
  isSelected,
}) => {
  const color = paymentStatusColors[status] ?? 'textColor';

  return (
    <Card className={`${className} ${isSelected ? 'border border-' + color : ''} p-4 cursor-pointer`} onClick={onClick}>
      <Row justify="between" align="start" noGutters className="h-100">
        <Col align="start" justify="between" className="mr-4 mb-2 align-items-start">
          <h5 className={`sans-serif text-${color}`} style={{ color }}>
            {getStatusLabel(status)}
          </h5>
          <div className={`sm text-${color} payment-card-subtitle mb-1`}>{helperText[status]}</div>
          <div className="sm mb-2 payment-card-subtitle">
            You have{' '}
            <b>
              {count} {getStatusLabel(status).toLowerCase()}
            </b>{' '}
            payment{count !== 1 && 's'}.
          </div>
          <div>
            {loading ? (
              <Box height={40} width={40} />
            ) : (
              <div className={`payment-card-amount font-weight-light mb-0 text-${color} text-break`}>
                {currencyFormat(total)}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default PaymentStatusFilterCard;
