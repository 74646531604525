import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { accountDepositsBalancePagedResults, accountDepositsFields, accountDepositsSummaryFields } from './fields';

interface IGetAccountDepositsBalance {
  getAccountDepositsBalance: IPagedResult<IAccountDepositsBalance>;
}

interface IGetAccountDepositsBalanceVariables {
  input: {
    businessId: string;
    centerIds: string[];
    start: string;
    end: string;
    searchKey?: string;
    pageNumber: number;
    pageSize: number;
    sortBy: string;
    sortDirection: string;
    statusType?: AccountStatusType;
  };
}

export const GET_ACCOUNT_DEPOSITS_BALANCE = gql`
  query($input: GetAccountDepositsBalanceInput!) {
    getAccountDepositsBalance(input: $input) {
      ${accountDepositsBalancePagedResults}
    }
  }
`;

export const useGetAccountDepositsBalance = (
  options?: QueryHookOptions<IGetAccountDepositsBalance, IGetAccountDepositsBalanceVariables>
) =>
  useQuery(GET_ACCOUNT_DEPOSITS_BALANCE, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

interface IGetAccountDeposits {
  getAccountDeposits: IAccountDeposit[];
}

interface IGetAccountDepositsVariables {
  input: {
    accountId: string;
  };
}

export const GET_ACCOUNT_DEPOSITS = gql`
  query($input: GetAccountDepositsInput!) {
    getAccountDeposits(input: $input) {
      ${accountDepositsFields}
    }
  }
`;

export const useGetAccountDeposits = (options?: QueryHookOptions<IGetAccountDeposits, IGetAccountDepositsVariables>) =>
  useQuery(GET_ACCOUNT_DEPOSITS, {
    fetchPolicy: 'network-only',
    ...options,
  });

interface IGetAccountDepositsSummary {
  getAccountDepositsSummary: IAccountDepositSummary[];
}

export const GET_ACCOUNT_DEPOSITS_SUMMARY = gql`
  query($input: GetAccountDepositsInput!) {
    getAccountDepositsSummary(input: $input) {
      ${accountDepositsSummaryFields}
    }
  }
`;

export const useGetAccountDepositsSummary = (
  options?: QueryHookOptions<IGetAccountDepositsSummary, IGetAccountDepositsVariables>
) =>
  useQuery(GET_ACCOUNT_DEPOSITS_SUMMARY, {
    fetchPolicy: 'network-only',
    ...options,
  });
