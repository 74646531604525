import FileDropbox from './FileDropbox';
import SelectedFile from './SelectedFile';

interface IProps {
  onChange?: (files: File[]) => void;
  value?: File[];
  multiple?: boolean;
  className?: string;
  acceptedFileExts?: string;
}

const FileSimpleDropbox = ({ onChange = () => {}, value = [], multiple = false, ...props }: IProps) => {
  const appendChange = (files: File[]) => onChange([...value, ...files.filter((f) => f.size > 0)]);
  const onDrop = (acceptedFiles: File[]) => appendChange(acceptedFiles);
  const onFilesAdded = (e: React.ChangeEvent<HTMLInputElement>) => appendChange(Array.from(e?.target?.files ?? []));
  const onFileRemoved = (fileName: string) => onChange(value.filter((f: IFile) => !(f.name === fileName)));

  if (value.length > 0)
    return (
      <>
        {value.map((f, i) => (
          <SelectedFile file={f} key={i} updateContentsCb={() => {}} onDeleteClick={onFileRemoved} />
        ))}
      </>
    );
  return <FileDropbox multiple={multiple} onFilesAdded={onFilesAdded} onDrop={onDrop} {...props} />;
};

export default FileSimpleDropbox;
