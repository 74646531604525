import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import Select from 'shared/components/Select/Select';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { IconButtonCircle } from 'shared/components/Buttons';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import DropdownFilter from 'shared/components/Dropdown/DropdownFilter';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { RootState } from 'store/reducers';
import { setCurrentBusiness, setCurrentBusinessFilters, setCurrentCenterFilters } from 'store/context/actions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionDropdown from 'shared/components/ActionDropdown';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useFormatDate from 'shared/hooks/useFormatDate';
import { capitalize } from 'shared/util/string';
import { IStateControls, IDatatableState } from 'shared/hooks/useDatatableState2';
import { accountStatusColorHexes } from 'shared/constants/tagColors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccountChildrenColumn from '../../Families/subroutes/Accounts/components/AccountChildrenColumn';
import Currency from 'shared/components/Currency';

interface IProps {
  data: IAccountDepositsBalance[];
  loading: boolean;
  setSearchTerm: (str: string) => void;
  centers: ICenter[] | undefined;
  pageSize: number;
  activePage: number;
  totalResults: number;
  tableState: IDatatableState;
  tableFunctions: IStateControls;
  filteredStatus: string[];
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  onStatusFilter: (status: string[]) => void;
  navigateToChildProfile: (child: IAccountChild) => void;
}

const DepositsAccountTable: React.FC<IProps> = ({
  data,
  pageSize,
  activePage,
  setSearchTerm,
  centers,
  loading,
  totalResults,
  tableState,
  tableFunctions,
  filteredStatus,
  navigateToChildProfile,
  onSort,
  onPageChange,
  onSizePerPageChange,
  onStatusFilter,
  ...props
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { centerFilterIds, businessId } = useSelector((state: RootState) => state.context);
  const { data: businessesData } = useGetAllowedEntities();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const hasViewAccountPermission = useHasRoleAreaLevel({
    area: AreaType.Account,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const hasViewChildPermission = useHasRoleAreaLevel({
    area: AreaType.Child,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  useEffect(() => {
    if (businesses.length > 0 && businessId === '') dispatch(setCurrentBusiness(businesses[0].id));
  }, [businesses, businessId, dispatch]);

  const renderAmount = useCallback(
    (amount: number): JSX.Element => (
      <b>
        <Currency amount={amount} />
      </b>
    ),
    []
  );

  const clearAppliedFilters = useCallback(() => {
    dispatch(setCurrentCenterFilters([]));
    dispatch(setCurrentBusinessFilters([]));
  }, [dispatch]);

  const statusOptions: ITableFilterOption[] = [
    {
      label: capitalize(t('spelling.active')),
      value: 'Active',
      indicatorColor: accountStatusColorHexes.Active,
    },
    {
      label: capitalize(t('spelling.inactive')),
      value: 'Inactive',
      indicatorColor: accountStatusColorHexes.Inactive,
    },
  ];

  const handleBusinessSelect = useCallback(
    (option: ITableFilterOption) => {
      dispatch(setCurrentBusiness(option.value));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const handleCenterFilterSelect = useCallback(
    (options: ITableFilterOption[]) => {
      dispatch(setCurrentCenterFilters(options.map((c) => c.value)));
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [dispatch, tableFunctions, tableState]
  );

  const getTableColumns = useCallback((): any[] => {
    const tableColumns: any = [
      {
        dataField: 'name',
        text: 'Account',
        sort: true,
      },
      {
        dataField: 'center.name',
        text: `${t('spelling.capitalCenter')} Name`,
      },
      ...(hasViewChildPermission
        ? [
            {
              headerAttrs: {
                id: 'account-children-header-column-cell',
              },
              text: 'Children',
              formatter: (cell: string, row: IAccountDepositsBalance) => (
                <AccountChildrenColumn
                  children={row.children?.accountChildren ?? []}
                  navigateToChildProfile={navigateToChildProfile}
                />
              ),
            },
          ]
        : []),
      {
        dataField: 'balance',
        text: 'Total Balance',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        formatter: renderAmount,
      },
    ];

    if (hasViewAccountPermission) {
      tableColumns.push({
        text: 'Actions',
        dataField: '',
        align: 'center',
        headerAlign: 'center',
        formatter: (cell: any, row: IAccountDepositsBalance) => (
          <div className="d-flex justify-content-center">
            <ActionDropdown
              actions={[
                {
                  label: 'View Account',
                  onClick: () => history.push(`/families/accounts/${row.accountId}/profile`),
                },
              ]}
            />
          </div>
        ),
      });
    }

    return tableColumns;
  }, [t, renderAmount, hasViewAccountPermission, formatDate, history]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <DataTable
      data={data}
      columns={getTableColumns()}
      dataSize={totalResults}
      pageSize={pageSize}
      activePage={activePage}
      showLoadingOverlay={loading}
      showSelect={false}
      onSort={onSort}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      handleRowClick={(e, row) => history.push(`/families/accounts/${row.accountId}/profile`)}
      renderHeader={(paginationProps, searchProps) => (
        <>
          <TableHeader className="flex-wrap align-items-center">
            <div className="d-flex flex-wrap mr-auto align-items-center">
              <SizePerPage paginationProps={paginationProps} />
              <TableSearch placeholder="Search" onChange={setSearchTerm} className={isMobile ? 'mt-2 mb-1' : ''} />
            </div>
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex flex-direction-row align-items-center m-0 flex-wrap">
                {centers && centers.length > 1 && (
                  <>
                    {user?.isInternal && (
                      <Select
                        title="Business"
                        className={isMobile ? 'my-1 mr-3' : 'mr-3 mb-0'}
                        options={businesses.map((b) => ({ label: b.name, value: b.id }))}
                        value={businessId}
                        name="business"
                        onChange={handleBusinessSelect}
                      />
                    )}
                    <DropdownFilter
                      title={fieldLabels.center}
                      className={isMobile ? 'my-1 mr-3' : 'mr-3 mb-0'}
                      selectedFilters={centerFilterIds}
                      options={
                        centers
                          ?.filter((c) => c.entityId === businessId)
                          .map((c) => ({ label: c.name, value: c.id }))
                          .sort((a, b) => a.label.localeCompare(b.label)) ?? []
                      }
                      onFilterSelect={handleCenterFilterSelect}
                    />
                  </>
                )}
                <DropdownFilter
                  title={capitalize(t('spelling.status'))}
                  className={isMobile ? ' mt-1 mr-3' : 'mr-3'}
                  selectedFilters={filteredStatus}
                  options={statusOptions}
                  onFilterSelect={(values) => onStatusFilter(values.map((value) => value.value))}
                />
                <IconButtonCircle
                  icon={faTimes}
                  onClick={clearAppliedFilters}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                />
              </div>
            </div>
          </TableHeader>
        </>
      )}
    />
  );
};

export default DepositsAccountTable;
