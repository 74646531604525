import React from 'react';
import _ from 'lodash';
import * as Mui from '@mui/material';

import { useHistory } from 'react-router-dom';
import { showToast } from 'shared/components/Toast';
import moment from 'moment';
import Avatar from 'shared/components/Avatar';
import { StatisticChannel } from 'shared/types/channel';
interface IRowProps {
  row: StatisticChannel;
  index: number;
  centerId: string;
}

const ChannelsTableRow: React.FC<IRowProps> = ({ row, index, centerId }) => {
  const history = useHistory();
  const [childInitials, setChildInitials] = React.useState('');

  React.useEffect(() => {
    setChildInitials(`${row?.accountChild?.firstname.charAt(0) ?? ''}${row?.accountChild?.lastname.charAt(0) ?? ''}`);
  }, [row]);

  return (
    <Mui.TableRow
      key={row?.channelId ?? ''}
      hover
      onClick={(event) => history.push(`/communications/student-messaging/center/${centerId}/channel/${row.channelId}`)}
      role="button"
      sx={{ borderTop: 'solid 10px #F8FAFB', borderBottom: 'solid 10px #F8FAFB' }}
    >
      <Mui.TableCell>
        <Mui.CardHeader
          avatar={<Avatar image={row?.accountChild?.avatar?.url ?? ''} size="md" initials={childInitials} />}
          title={
            <Mui.Typography variant="body1">
              {`${row?.accountChild?.firstname ?? ''} ${row?.accountChild?.lastname ?? ''}`}
            </Mui.Typography>
          }
          sx={{ borderBottom: 0 }}
        />
      </Mui.TableCell>
      <Mui.TableCell align="left">
        <Mui.Typography variant="body1">{row?.lastActive ? moment(row.lastActive).format('LLLL') : ''}</Mui.Typography>
      </Mui.TableCell>
      <Mui.TableCell align="center">
        <Mui.Typography variant="body1">{row?.statistics?.unreadCount}</Mui.Typography>
      </Mui.TableCell>
      <Mui.TableCell align="center">
        <Mui.Typography variant="body1">{row?.statistics?.flaggedCount}</Mui.Typography>
      </Mui.TableCell>
      <Mui.TableCell align="center">
        <Mui.Typography variant="body1">{row?.statistics?.dailyAverage}</Mui.Typography>
      </Mui.TableCell>
      <Mui.TableCell align="center">
        <Mui.Typography variant="body1">{row?.statistics?.totalCount}</Mui.Typography>
      </Mui.TableCell>
    </Mui.TableRow>
  );
};

export default ChannelsTableRow;
