export enum ISCaseStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  CREATED_IN_ERROR = 'CREATED_IN_ERROR',
  DUPLICATE = 'DUPLICATE',
  NOT_APPROVED = 'NOT_APPROVED',
  PENDING = 'PENDING',
  READY_FOR_APPROVAL = 'READY_FOR_APPROVAL',
  STARTED = 'STARTED',
  SUBMIT_FOR_APPROVAL = 'SUBMIT_FOR_APPROVAL',
  SUBMIT_FOR_ENDORSEMENT = 'SUBMIT_FOR_ENDORSEMENT',
  VARIED = 'VARIED',
  WAITLISTED = 'WAITLISTED',
}

export enum ISCaseStatusLabelEnum {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  APPROVED = 'Approved',
  CANCELLED = 'Cancelled',
  CREATED_IN_ERROR = 'Created In Error',
  DUPLICATE = 'Duplicate',
  NOT_APPROVED = 'Not Approved',
  PENDING = 'Pending',
  READY_FOR_APPROVAL = 'Ready for Approval',
  STARTED = 'Started',
  SUBMIT_FOR_APPROVAL = 'Submit for Approval',
  SUBMIT_FOR_ENDORSEMENT = 'Submit for Endorsement',
  VARIED = 'Varied',
  WAITLISTED = 'Withdrawn',
}

export enum ISCaseTypeLabelEnum {
  INNOVATIVE_SOLUTIONS = 'Innovative Solutions',
}

export const ISCaseStatusFilters: { label: string; value: string }[] = [
  { label: ISCaseStatusLabelEnum.ACTIVE, value: ISCaseStatusEnum.ACTIVE },
  { label: ISCaseStatusLabelEnum.INACTIVE, value: ISCaseStatusEnum.INACTIVE },
];

export enum ISCaseTypesEnum {
  IDF_SUBSIDY = 'IDF_SUBSIDY',
  IMMEDIATE_TIME_LIMITED = 'IMMEDIATE_TIME_LIMITED',
  FDC_TOP_UP = 'FDC_TOP_UP',
  INCLUSION_SUPPORT_IHC = 'INCLUSION_SUPPORT_IHC',
}

export enum ISCaseTypeLabelsEnum {
  IDF_SUBSIDY = 'IDF Subsidy',
  IMMEDIATE_TIME_LIMITED = 'Immediate/Time-Limited',
  FDC_TOP_UP = 'FDC Top Up',
  INCLUSION_SUPPORT_IHC = 'Inclusion Support - IHC',
}
