import React, { useEffect, useState } from 'react';
import _, { capitalize } from 'lodash';

import * as Mui from '@mui/material';

import DefaultRoyaltySetting from '../DefaultRoyaltySetting';
import RoyaltyFees from '../RoyaltyFees/RoyaltyFees';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes, faPencil } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

import { LoadingLines } from 'shared/components/LoadingSkeletons';
import CentersTableRow from './CentersTableRow';

interface ICentersTableProps {
  updatePage: (x: number) => void;
  currentCenters: ICenter[] | undefined;
  getPaginatedCentersLoading: boolean;
  totalCenters: number;
}

const CentersTable: React.FC<ICentersTableProps> = ({
  updatePage,
  currentCenters,
  getPaginatedCentersLoading,
  totalCenters,
}) => {
  const [centerListLoading, setCenterListLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    updatePage(newPage + 1);
  };

  return (
    <>
      <Mui.Box sx={{ width: '100%' }}>
        <Mui.Paper sx={{ mb: 2 }} elevation={0}>
          <Mui.TableContainer style={{ maxHeight: 400 }}>
            <Mui.Table aria-labelledby="royalty-fees-table" style={{ width: '100%' }}>
              <Mui.TableBody sx={{ height: 2 }}>
                {/* {!getPaginatedCentersLoading && currentCenters ? (
                    currentCenters.map((row, index) => (
                      <CentersTableRow row={row} index={index} key={`centersTable-${index}`} />
                    ))
                  ) : (
                    <LoadingLines number={4} />
                  )} */}
                {getPaginatedCentersLoading ? (
                  <LoadingLines number={4} />
                ) : (
                  currentCenters &&
                  currentCenters.map((row, index) => (
                    <CentersTableRow row={row} index={index} key={`centersTable-${index}`} />
                  ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          {/* Add condition for when list is empty */}
          {false && (
            <Mui.Box
              sx={{ width: '100%', margin: 0 }}
              display="flex"
              bgcolor="white"
              alignItems="center"
              justifyContent="center"
            >
              <Mui.Typography variant="body1" padding={'16px'}>
                Oops. We don’t have any data for that. Try another search.
              </Mui.Typography>
            </Mui.Box>
          )}
          <Mui.TablePagination
            sx={{
              '.MuiTablePagination-displayedRows': {
                margin: 0,
                padding: 0,
              },
            }}
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCenters}
            rowsPerPage={10}
            page={page}
            onPageChange={handleChangePage}
          />
        </Mui.Paper>
      </Mui.Box>
    </>
  );
};

export default CentersTable;
