import moment from 'moment';
import React from 'react';
import _ from 'lodash';

import * as Mui from '@mui/material';
import { Row, Col } from 'shared/components/Layout';

import { useGetActiveClassesForCenter } from 'gql/center/queries';

import ClassPhotoStatsCard, { PhotoStatisticType } from './ClassPhotoStatsCard';
import Select from 'shared/components/Select';
import { useGetClassPhotoCounts } from 'pages/Photos/gql/query';

interface IMetricOption {
  value: { type: PhotoStatisticType; minNumber?: number };
  label: string;
}

const dateRangeOptions = [
  { value: 1, label: 'Today' },
  { value: 2, label: 'Past 2 Days' },
  { value: 5, label: 'Past 5 Days' },
];

const metricsOptions: {
  value: { type: PhotoStatisticType; minNumber?: number };
  label: string;
}[] = [
  { value: { type: 'total' }, label: 'Total Photos' },
  { value: { type: 'percent', minNumber: 1 }, label: '1 Photo Per Child' },
  { value: { type: 'percent', minNumber: 2 }, label: '2 Photo Per Child' },
  { value: { type: 'percent', minNumber: 3 }, label: '3 Photo Per Child' },
  { value: { type: 'percent', minNumber: 4 }, label: '4 Photo Per Child' },
  { value: { type: 'percent', minNumber: 5 }, label: '5 Photos Per Child' },
];

interface IProps {
  centerId: string;
  refetchStats: boolean;
  setRefetchStats: (x: boolean) => void;
}

const ClassPhotoStats: React.FC<IProps> = ({ centerId, refetchStats, setRefetchStats }) => {
  const [dateRange, setDateRange] = React.useState(dateRangeOptions[0]);
  const [metrics, setMetrics] = React.useState(metricsOptions[0]);

  const { classes } = useGetActiveClassesForCenter({ variables: { centerId } }, `id name`);

  const { data, loading, refetch } = useGetClassPhotoCounts({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        centerId,
        classIds: classes.map((c) => c.id),
        startDate: moment()
          .subtract(dateRange.value - 1, 'days')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
    },
  });
  const photosCounts = data?.getClassPhotoCounts ?? [];

  React.useEffect(() => {
    if (refetchStats) {
      refetch({
        input: {
          centerId,
          classIds: classes.map((c) => c.id),
          startDate: moment()
            .subtract(dateRange.value - 1, 'days')
            .format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        },
      });
      setRefetchStats(false);
    }
  }, [refetchStats]);

  return (
    <>
      <Mui.Card elevation={0} sx={{ marginTop: '24px', marginBottom: '24px', overflow: 'visible' }}>
        <Mui.CardHeader
          title={
            <Mui.Box display="flex" justifyContent="space-between" alignContent="center">
              <Mui.Typography variant="h4">Photos Sent to Families</Mui.Typography>
              <Mui.Box display="flex" sx={{ overflow: 'visible' }} alignItems="center">
                <Select className="mb-0 mr-4" value={dateRange} options={dateRangeOptions} onChange={setDateRange} />
                <Select className="mb-0" value={metrics} options={metricsOptions} onChange={setMetrics} />
              </Mui.Box>
            </Mui.Box>
          }
        />
        <Mui.CardContent sx={{ margin: 0 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              margin: 0,
              flexWrap: 'wrap',
            }}
          >
            {loading
              ? classes?.map((c, index) => (
                  <Col className="flex-grow-0 flex-wrap" key={`loading info for class - ${c.id} ${index}`}>
                    <ClassPhotoStatsCard
                      loading={true}
                      _class={c}
                      selectedStatisticType={metrics.value.type}
                      minimumTaggedPhotosPerChild={metrics.value.minNumber ?? 0}
                    />
                  </Col>
                ))
              : photosCounts?.map((cpc, index) => (
                  <Col className="flex-grow-0 flex-wrap" key={`class photo stats card - ${cpc.classId} ${index}`}>
                    <ClassPhotoStatsCard
                      loading={false}
                      classPhotoCount={cpc}
                      selectedStatisticType={metrics.value.type}
                      minimumTaggedPhotosPerChild={metrics.value.minNumber ?? 0}
                    />
                  </Col>
                ))}
          </div>
        </Mui.CardContent>
      </Mui.Card>
    </>
  );
};

export default ClassPhotoStats;
