import { isEqual, isNil } from 'lodash';
import moment from 'moment';
import { isRegion } from 'shared/util/region';
import { isBlank } from 'shared/util/string';
import { isDateRangeInvalid, isTimeRangeInvalid } from 'shared/util/timeUtils';

export enum ContractValidationError {
  MissingRequiredField = 'MissingRequiredField',
  MissingBillingCycle = 'MissingBillingCycle',
  EndDateIsBeforeStartDate = 'EndDateIsBeforeStartDate',
  ContractStartsBeforeFee = 'ContractStartsBeforeFee',
  MissingAdjustmentOnOriginalFee = 'MissingAdjustmentOnOriginalFee',
  TimeslotHasInvalidTime = 'TimeslotHasInvalidTime',
  FlatRateFeeDuringSubsidy = 'FlatRateFeeDuringSubsidy',
  HasNoTimeslots = 'HasNoTimeslots',
}
interface IValidationError {
  validationError: ContractValidationError;
}
export class ValidationResults {
  readonly validationErrors: IValidationError[] = [];
  get isValid() {
    return this.validationErrors.length == 0;
  }

  addError(error: ContractValidationError) {
    this.validationErrors.push({ validationError: error });
  }

  hasValidationError(error: ContractValidationError) {
    return !!this.validationErrors.find((ve) => ve.validationError === error);
  }
  toJSON() {
    return { isValid: this.isValid, validaitonErrors: this.validationErrors };
  }
}

export function isValidContract(
  contract: ICreateContractInput,
  accountData: IAccount,
  childSubsidyEnrollments: IAgencyAccountChildEnrollment[] | undefined,
  selectedFee: IFee | undefined,
  isUsingStandardFee: boolean
): ValidationResults {
  const validationResults = new ValidationResults();
  if (isRegion('US')) {
    // check we have a billing cycle
    if (!(accountData.billingCycle || contract.billingCycle))
      validationResults.addError(ContractValidationError.MissingBillingCycle);
  }

  if (
    !(
      contract.centerId &&
      contract.accountId &&
      contract.childId &&
      contract.classId &&
      contract.permanentFeeId &&
      contract.startDate
    )
  ) {
    validationResults.addError(ContractValidationError.MissingRequiredField);
  }

  if (contract.endDate && isDateRangeInvalid(contract.startDate, contract.endDate)) {
    validationResults.addError(ContractValidationError.EndDateIsBeforeStartDate);
  }

  if (
    !isBlank(contract.startDate) &&
    !isNil(selectedFee) &&
    isFeeScheduleInvalidWithStartDate(contract.startDate, selectedFee)
  ) {
    validationResults.addError(ContractValidationError.ContractStartsBeforeFee);
  }

  // adjustment must be set if originalFee is set
  if (isRegion('US') && isUsingStandardFee && contract.originalFeeId && !contract.adjustmentId) {
    validationResults.addError(ContractValidationError.MissingAdjustmentOnOriginalFee);
  }

  if (contract.timeslots.length == 0) {
    validationResults.addError(ContractValidationError.HasNoTimeslots);
  }

  // ensure timeslots times are correct
  if (contract.timeslots.find((ts) => isTimeRangeInvalid(ts.dropOffTime, ts.pickUpTime))) {
    validationResults.addError(ContractValidationError.TimeslotHasInvalidTime);
  }

  if (
    selectedFee &&
    childSubsidyEnrollments &&
    selectedFee.feeType === 'FLAT_RATE' &&
    childSubsidyEnrollments.find((subsidyEnrollment) => {
      const contractStartDate = moment(contract.startDate);
      const subsidyEnrollmentStartDate = moment(subsidyEnrollment.startDate);
      const subsidyEnrollmentEndDate = moment(subsidyEnrollment.endDate);

      const checkIsAfter = contractStartDate.isAfter(subsidyEnrollmentStartDate);
      const checkIsBefore = contractStartDate.isBefore(subsidyEnrollmentEndDate);

      return (
        (checkIsAfter || isEqual(contractStartDate, subsidyEnrollmentStartDate)) &&
        (checkIsBefore || isEqual(contractStartDate, subsidyEnrollmentEndDate))
      );
    })
  ) {
    validationResults.addError(ContractValidationError.FlatRateFeeDuringSubsidy);
  }

  return validationResults;
}

export function isFeeScheduleInvalidWithStartDate(startDate: string, fee: IFee) {
  return isNil(fee?.schedules.find((s) => s.startDate <= startDate && s.archivedAt == null)) ?? false;
}
