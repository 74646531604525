import React from 'react';
import surchargesBanner from './Surcharges-Banner.svg';
import Card from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'shared/util/string';

interface IProps {}

const CreateBillingFeesInformationCard: React.FC<IProps> = () => {
  const showCartoon = window.matchMedia('(min-width: 1000px)').matches;
  const { t } = useTranslation();

  return (
    <Card bodyClassName="p-8">
      <Row className="flex-nowrap">
        {showCartoon && (
          <img
            src={surchargesBanner}
            alt="cartoon helper man"
            className="align-self-end mt-n2 mb-n8 mr-4 d-none d-md-block"
            style={{ width: '235px' }}
          />
        )}
        <Row>
          <Col className="pl-8">
            <h3>Processing Fees</h3>
            <br />
            <p>
              A surcharge is included as part of the Kangarootime automated payment service for each transaction that is
              processed.
            </p>
            <i>Passing these fees on to your families is optional and can be turned on or off at any time.</i>
            <br />
            <p>
              <b>When turned on</b>, families will be billed the surcharge for each payment that is processed.
            </p>
            <p>
              <b>When turned off</b>, {t('spelling.center')}s will retain those processing fees.
            </p>
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

export default CreateBillingFeesInformationCard;
