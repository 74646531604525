import React, { useEffect } from 'react';
import { useGetSessionById } from 'gql/subsidyTracking/queries';
interface IProps {
  sessionId: string;
  updateSessions: (session: ISession) => void;
  setLoading: (loading: boolean) => void;
}

const SessionQuery: React.FC<IProps> = ({ sessionId, updateSessions, setLoading }) => {
  const {
    loading,
    data: session,
    called,
  } = useGetSessionById({
    variables: {
      input: {
        sessionId,
      },
    },
    onCompleted: (result) => {
      updateSessions(result.getSessionById);
    },
  });
  useEffect(() => {
    setLoading(loading);
  }, [loading]);
  return <div>{loading}</div>;
};

export default React.memo(SessionQuery);
