import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { providerAddressFields, providerContactFields } from './fields';

interface IUpdateProviderContact {
  updateProviderContact: IProviderContact;
}

interface IUpdateProviderVariables {
  input: IProviderContactInput;
}

export const UPDATE_PROVIDER_CONTACT = gql`
  mutation($input: UpdateProviderContactInput!){
    updateProviderContact(input: $input) {
     ${providerContactFields}
    }
  }
`;

export const UPDATE_PROVIDER_NAME = gql`
  mutation ($input: UpdateProviderNameInput!) {
    updateProviderName(input: $input) {
      name
    }
  }
`;

export const UPDATE_PROVIDER_ADDRESS = gql`
  mutation($input: UpdateProviderAddressInput!){
    updateProviderAddress(input: $input) {
      ${providerAddressFields}
    }
  }
`;

export const UPDATE_PROVIDER_FINANCIAL_DETAILS = gql`
  mutation ($input: UpdateProviderFinancialDetailsInput!) {
    updateProviderFinancialDetails(input: $input)
  }
`;

export const CREATE_PROVIDER_CONFLICT_OF_INTERESTS = gql`
  mutation ($input: ProviderConflictOfInterestsInput!) {
    createProviderConflictOfInterests(input: $input)
  }
`;

export const ADD_PROVIDER_NOTIFICATION = gql`
  mutation ($input: AddCcssProviderNotificationInput!) {
    addCcssProviderNotification(input: $input)
  }
`;

export const useUpdateProviderContact = (
  options?: MutationHookOptions<IUpdateProviderContact, IUpdateProviderVariables>
) => {
  return useMutation<IUpdateProviderContact, IUpdateProviderVariables>(UPDATE_PROVIDER_CONTACT, options);
};

export const useUpdateProviderName = (
  options?: MutationHookOptions<IUpdateProviderName, IUpdateProviderNameVariables>
) => {
  return useMutation<IUpdateProviderName, IUpdateProviderNameVariables>(UPDATE_PROVIDER_NAME, options);
};

export const useUpdateProviderAddress = (
  options?: MutationHookOptions<IUpdateProviderAddress, IUpdateProviderAddressVariables>
) => {
  return useMutation<IUpdateProviderAddress, IUpdateProviderAddressVariables>(UPDATE_PROVIDER_ADDRESS, options);
};

export const useUpdateProviderFinancialDetails = (
  options?: MutationHookOptions<IUpdateProviderFinancialDetails, IUpdateProviderFinancialDetailsVariables>
) => {
  return useMutation<IUpdateProviderFinancialDetails, IUpdateProviderFinancialDetailsVariables>(
    UPDATE_PROVIDER_FINANCIAL_DETAILS,
    options
  );
};

export const useCreateProviderConflictOfInterests = (
  options?: MutationHookOptions<void, IUpdateProviderConflictOfInterestsVariables>
) => {
  return useMutation<void, IUpdateProviderConflictOfInterestsVariables>(CREATE_PROVIDER_CONFLICT_OF_INTERESTS, options);
};

export const useAddProviderNotification = (options?: MutationHookOptions<any, IAddProviderNotificationVariables>) => {
  return useMutation<any, IAddProviderNotificationVariables>(ADD_PROVIDER_NOTIFICATION, options);
};
