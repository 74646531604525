import moment from 'moment';
import React from 'react';
import Masonry from 'react-masonry-css';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import * as Mui from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro } from '@fortawesome/pro-light-svg-icons';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { RootState } from 'store/reducers';
import { useGetActiveClassesForCenter } from 'gql/center/queries';

import Button, { CirclePlusButton, CreateButton } from 'shared/components/Buttons';
import PageWrapper from 'shared/components/PageWrapper';
import './_photos.scss';
import AddPhotoModal from '../Dashboard/components/AddPhotoModal';
import SpinnerOverlay from 'shared/components/Spinner/SpinnerOverlay';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import GalleryItem from './components/GalleryItem/GalleryItem';
import SelectMultiple from 'shared/components/Select/SelectMultiple';
import CenterSelectBanner from 'shared/components/CenterSelectBanner';
import { useGetSearchedPhotos } from 'pages/Photos/gql/query';
import { isRegion } from 'shared/util/region';
import { GalleryDatePicker } from './components/GalleryDatePicker/GalleryDatePickerAU';
import { useTranslation } from 'react-i18next';

type RegionForDate = 'AU' | 'US';
type StartOrEnd = 'start' | 'end';
interface ISelectedClass {
  value: string;
  label: string;
}

const Gallery: React.FC = () => {
  const hasGalleryEdit = useHasRoleAreaLevel({
    area: AreaType.Activities,
    permission: PermissionType.Photo,
    level: RoleLevelType.Edit,
  });
  const hasGalleryCreate = useHasRoleAreaLevel({
    area: AreaType.Activities,
    permission: PermissionType.Photo,
    level: RoleLevelType.Create,
  });
  const { t } = useTranslation(['translation']);
  const [isAddPhotoOpen, setIsAddPhotoOpen] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [items, setItems] = React.useState<IPhoto[]>([]);

  const centerId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const { classes } = useGetActiveClassesForCenter({ variables: { centerId } }, `id name`);
  const [orderBy, setOrderBy] = React.useState('DESCENDING');
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[centerId]) ?? moment.tz.guess();
  const [selectedClasses, setSelectedClasses] = React.useState<ISelectedClass[] | null>(null);

  const dateFormat: string = isRegion('AU') ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
  const [takenAfter, setTakenAfter] = React.useState<string | null>(moment().startOf('week').format(dateFormat));
  const [takenBefore, setTakenBefore] = React.useState<string | null>(moment().endOf('week').format(dateFormat));
  const modifiedTakenAfterValue = moment(moment(takenAfter, 'DD/MM/YYYY'), 'MM/DD/YYYY');
  const modifiedTakenBeforeValue = moment(moment(takenBefore, 'DD/MM/YYYY'), 'MM/DD/YYYY');
  const {
    data: photosData,
    loading: loadingPhotos,
    refetch,
  } = useGetSearchedPhotos({
    skip: _.isNil(centerId) || _.isEmpty(centerId),
    variables: {
      input: {
        centerIds: [centerId],
        takenAfter: moment(takenAfter, dateFormat).startOf('day').format('YYYY-MM-DD'),
        takenBefore: moment(takenBefore, dateFormat).endOf('day').format('YYYY-MM-DD'),
        from: pageNumber,
        size: 24,
        isApproved: true,
        isEvaluated: true,
        classIds: _.isNil(selectedClasses) || _.isEmpty(selectedClasses) ? null : selectedClasses.map((i) => i.value),
        sortDirection: orderBy,
      },
    },
  });
  const totalRecords = photosData?.searchPhoto.totalRecords ?? 0;

  React.useEffect(() => {
    setItems(_.uniqBy([...items, ...(photosData?.searchPhoto.data ?? [])], 'id'));
  }, [photosData]);

  React.useEffect(() => {
    setItems([]);
  }, [centerId, selectedClasses, orderBy, takenAfter, takenBefore]);

  React.useEffect(() => {
    setSelectedClasses(null);
  }, [centerId]);

  const refetchPhotos = (size: number) => {
    refetch({
      input: {
        centerIds: [centerId],
        takenAfter: moment(takenAfter, dateFormat).startOf('day').format('YYYY-MM-DD'),
        takenBefore: moment(takenBefore, dateFormat).endOf('day').format('YYYY-MM-DD'),
        from: 1,
        size: size,
        isApproved: true,
        isEvaluated: true,
        classIds: _.isNil(selectedClasses) || _.isEmpty(selectedClasses) ? null : selectedClasses.map((i) => i.value),
        sortDirection: orderBy,
      },
    });
  };

  const shouldDisableDates = (date: string, region: RegionForDate, startOrEnd: StartOrEnd) => {
    if (region === 'AU') {
      if (startOrEnd === 'start') return moment(date).isAfter(moment(takenBefore, 'DD/MM/YYYY'));
      if (startOrEnd === 'end') return moment(date).isBefore(moment(takenAfter, 'DD/MM/YYYY'));
    }
    if (region === 'US') {
      if (startOrEnd === 'start') return moment(date).isAfter(moment(takenBefore));
      if (startOrEnd === 'end') return moment(date).isBefore(moment(takenAfter));
    }
    return true;
  };

  return (
    <PageWrapper
      applyPadding={true}
      buttonComponent={
        (hasGalleryCreate || hasGalleryEdit) && (
          <CreateButton onClick={() => setIsAddPhotoOpen(true)}>Add Photo / Video</CreateButton>
        )
      }
      mobileButtonComponent={
        (hasGalleryCreate || hasGalleryEdit) && (
          <CirclePlusButton variant="primary" className="my-4" onClick={() => setIsAddPhotoOpen(true)} />
        )
      }
      pageTitle="Gallery"
    >
      <CenterSelectBanner pageName="Gallery" showCenterSelect={true} />
      <Mui.Card elevation={0} style={{ overflow: 'visible', marginTop: '6px' }}>
        <Mui.CardContent>
          <Mui.Grid container spacing={2}>
            <Mui.Grid item sm={12} md={6} lg={6}>
              <SelectMultiple
                onChange={(v) => setSelectedClasses(_.isNil(v) ? null : v?.map((i) => i))}
                options={classes.map((c) => ({ value: c.id, label: c.name }))}
                placeholder="Select Classroom"
                value={selectedClasses}
              />
            </Mui.Grid>
            <Mui.Grid item sm={12} md={12} lg={6}>
              <Mui.Box display="flex" alignItems="baseline" className="min-width-350">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <GalleryDatePicker
                    label={t('translation:photos.gallery.input.label.start-date')}
                    modifiedTakenValue={modifiedTakenAfterValue}
                    shouldDisableDates={shouldDisableDates}
                    shouldDisableDatesValue={'start'}
                    setTaken={setTakenAfter}
                    takenDate={takenAfter}
                    views={['month', 'day']}
                  />
                  <Mui.Typography>&nbsp; to &nbsp;</Mui.Typography>
                  <GalleryDatePicker
                    label={t('translation:photos.gallery.input.label.end-date')}
                    modifiedTakenValue={modifiedTakenBeforeValue}
                    shouldDisableDates={shouldDisableDates}
                    shouldDisableDatesValue={'end'}
                    setTaken={setTakenBefore}
                    takenDate={takenBefore}
                    views={['month', 'day']}
                  />
                </LocalizationProvider>
              </Mui.Box>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.CardContent>
      </Mui.Card>
      <Mui.Box paddingTop={2}>
        <Mui.FormControl>
          <Mui.InputLabel id="order-by-select-label">Order By</Mui.InputLabel>
          <Mui.Select
            labelId="order-by-select-label"
            id="order-by-select"
            label="Order By"
            onChange={(e) => setOrderBy(e.target.value)}
            size="small"
            value={orderBy}
            sx={{ minWidth: '266px' }}
          >
            <Mui.MenuItem value="ASCENDING">Oldest to Newest</Mui.MenuItem>
            <Mui.MenuItem value="DESCENDING">Newest to Oldest</Mui.MenuItem>
          </Mui.Select>
        </Mui.FormControl>
        <SpinnerOverlay show={loadingPhotos}>
          {_.size(items) === 0 && (
            <Mui.Box padding={6}>
              <Mui.Stack spacing={2} textAlign="center">
                <FontAwesomeIcon className="mx-auto" icon={faCameraRetro} size="2x" />
                <p>No Photos or Videos Found</p>
              </Mui.Stack>
            </Mui.Box>
          )}
          <Mui.Box paddingTop={2}>
            <Masonry
              breakpointCols={{
                default: 4,
                1375: 3,
                1100: 2,
                600: 1,
              }}
              className="masonry-grid"
              style={{ minHeight: '200px' }}
              columnClassName="masonry-grid_column"
            >
              {_.size(items) > 0 &&
                items.map((galleryItem) => <GalleryItem key={galleryItem.id} photo={galleryItem} />)}
            </Masonry>
          </Mui.Box>
        </SpinnerOverlay>
        {items.length < totalRecords && !loadingPhotos && (
          <div className="d-flex">
            <Button className="mx-auto" variant="secondary" onClick={() => setPageNumber(pageNumber + 1)}>
              Load More
            </Button>
          </div>
        )}
      </Mui.Box>
      {isAddPhotoOpen && (
        <AddPhotoModal
          isOpen={isAddPhotoOpen}
          setIsOpen={setIsAddPhotoOpen}
          centerId={centerId}
          refetchPhotos={() => refetchPhotos(items.length + 1)}
        />
      )}
    </PageWrapper>
  );
};

export default Gallery;
