import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';

interface IProps {
  text: string;
}

const CopyButton: React.FC<IProps> = ({ text }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (showTooltip)
      setTimeout(() => {
        setShowTooltip(false);
      }, 1800);
  }, [showTooltip]);

  const handleCopy = () => {
    setShowTooltip(true);
    navigator.clipboard.writeText(text);
  };

  return showTooltip ? (
    <Tooltip
      text={`${t('spelling.copied')}!`}
      direction="top-start"
      showTooltip={showTooltip}
      delayHide={1800}
      additionalClass="mx-0"
    >
      <Button onClick={handleCopy} className="btn-copy" variant="secondary" size="sm">
        {t('spelling.copy')}
      </Button>
    </Tooltip>
  ) : (
    <Button onClick={handleCopy} className="btn-copy" variant="secondary" size="sm">
      {t('spelling.copy')}
    </Button>
  );
};

export default CopyButton;
