import React, { useCallback } from 'react';
import momentTz from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { ITimeInformation } from './WeeklyTimeLog';

interface IProps {
  attendanceData: ITimeInformation;
  centerTimezone: string;
}

// represents a single day of the week in the week view
const WeekTimeLogTableCell: React.FC<IProps> = ({ attendanceData, centerTimezone, ...props }) => {
  const getPaidTimeOutText = useCallback((attendanceData: ITimeInformation): string => {
    const events: string[] = [];

    attendanceData.hasTrainingTimeEvents && events.push('Training');
    attendanceData.hasHoldidayTimeEvents && events.push('Holiday');
    attendanceData.hasClosureTimeEvents && events.push('Closure');
    attendanceData.hasOutsideBusinessTimeEvents && events.push('Out of Business');

    return events.join(' / ');
  }, []);

  if (
    !attendanceData.isScheduled &&
    !attendanceData.isWorking &&
    !attendanceData.timeOff &&
    !attendanceData.hasTrainingTimeEvents &&
    !attendanceData.hasHoldidayTimeEvents &&
    !attendanceData.hasClosureTimeEvents &&
    !attendanceData.hasOutsideBusinessTimeEvents
  ) {
    return <td className="text-center">-</td>;
  }

  const showTrainingEventTextRow =
    attendanceData.hasHoldidayTimeEvents ||
    attendanceData.hasTrainingTimeEvents ||
    attendanceData.hasClosureTimeEvents ||
    attendanceData.hasOutsideBusinessTimeEvents;

  return (
    <td className="text-center">
      {showTrainingEventTextRow && <div>{getPaidTimeOutText(attendanceData)}</div>}
      <div className="font-weight-semi-bold row-height-21">
        {attendanceData.timeOff && attendanceData.timeOff.status === 'Approved' && 'Time Off'}
        {attendanceData.earliestTimeIn && momentTz(attendanceData.earliestTimeIn).tz(centerTimezone).format('h:mma')}
        {attendanceData.earliestTimeIn && ' - '}
        {attendanceData.latestTimeOut && momentTz(attendanceData.latestTimeOut).tz(centerTimezone).format('h:mma')}
        {momentTz().tz(centerTimezone).isAfter(momentTz(attendanceData.closingTime).tz(centerTimezone)) &&
          attendanceData.earliestTimeIn &&
          !attendanceData.latestTimeOut && (
            <FontAwesomeIcon className="ml-2" color={colors.danger} icon={faExclamationTriangle} />
          )}
      </div>
      <div className="text-gray row-height-21">
        {attendanceData.earliestShiftStart &&
          momentTz(attendanceData.earliestShiftStart).tz(centerTimezone).format('h:mma')}
        {attendanceData.earliestShiftStart && ' - '}
        {attendanceData.latestShiftEnd && momentTz(attendanceData.latestShiftEnd).tz(centerTimezone).format('h:mma')}
      </div>
    </td>
  );
};

export default WeekTimeLogTableCell;
