import React from 'react';
import { showToast } from 'shared/components/Toast';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import AddCcmsApprovalModal, { IProps } from '../AddCcmsApprovalModal';

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const EditCcmsApprovalModal: React.FC<IProps> = ({
  onSuccess = () => showToast('CCMS Approval updated!', 'success'),
  title = `Edit ${fieldLabels.center} CCMS Approval`,
  ...props
}) => {
  // Need to force AddCcmsApprovalModal to re-render for population to work
  if (!props.show) return <></>;
  return <AddCcmsApprovalModal {...props} onSuccess={onSuccess} title={title} />;
};

export default EditCcmsApprovalModal;
