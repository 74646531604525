import moment from 'moment';

export const getAgeStringFromDateOfBirth = (dob: moment.Moment | null, includePerenthesis: boolean = true): string => {
  if (!dob || !dob.isValid()) {
    return '';
  }

  const today = moment();
  const monthDiff = today.diff(dob, 'months');

  if (0 > monthDiff) {
    return '';
  }

  const years = monthDiff >= 12 ? Math.floor(monthDiff / 12) : 0;
  const months = monthDiff % 12;

  return includePerenthesis ? `(${years} yrs, ${months} mo)` : `${years} yrs, ${months} mo`;
};
