import { IFormData, TimeRange } from './types';
import moment from 'moment';
import { isDateRangeInvalid } from 'shared/util/timeUtils';

const isFormDataValid = (formData: IFormData) => {
  const invalidDateframe =
    formData.timeRange === TimeRange.Multiple
      ? Boolean(isDateRangeInvalid(formData.startDate, formData.endDate))
      : false;

  return (
    formData.classIds.length > 0 &&
    moment(formData.startDate, 'YYYY-MM-DD').isValid() &&
    (formData.timeRange === TimeRange.Multiple ? moment(formData.endDate, 'YYYY-MM-DD').isValid() : true) &&
    !invalidDateframe
  );
};

export { isFormDataValid };
