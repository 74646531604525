import React, { useCallback, Fragment } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import DateInput from 'shared/components/DateInput';
import { HorizontalDivider } from 'shared/components/Dividers';
import FileDropbox from 'shared/components/FileOperations/FileDropbox';
import SelectedFile from 'shared/components/FileOperations/SelectedFile';
import TextInput from 'shared/components/TextInput';
import colors from '_colors.module.scss';
import { RootState } from 'store/reducers';
import { getFullName } from 'shared/util/string';
import { useSelector } from 'react-redux';

interface IProps {
  documents: IHealthConditionDocumentUploadInput[];
  childId: string;
  onFilesAdd: (files: IHealthConditionDocumentUploadInput[]) => void;
  onFileUpdate: (updates: IHealthConditionDocumentUploadInput) => void;
  onRemoveFile: (filename: string) => void;
}

const ChildMedicalDocumentationUploadForm: React.FC<IProps> = ({
  documents,
  childId,
  onFilesAdd,
  onFileUpdate,
  onRemoveFile,
  ...props
}) => {
  const child = useSelector((state: RootState) => state.children.byId)[childId];
  const childName = getFullName(child);

  const numberOfDocuments = documents.length;

  const handleFilesAdd = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event?.target?.files ?? []);

      onFilesAdd(
        files.map((file, idx) => ({
          file,
          filename: `${childName}_${file.name}`,
          expirationDate: null,
        }))
      );
    },
    [onFilesAdd, childName, numberOfDocuments]
  );

  const handleFileDrop = useCallback(
    (files: File[]) => {
      onFilesAdd(
        files.map((file, idx) => ({
          file,
          filename: `${childName}_${file.name}`,
          expirationDate: null,
        }))
      );
    },
    [onFilesAdd, childName, numberOfDocuments]
  );

  const documentHasExpired = useCallback((expirationDate: string | null): boolean => {
    return expirationDate ? moment(expirationDate).isBefore(moment(), 'date') : false;
  }, []);

  return (
    <section>
      <div className="mb-4">
        <FileDropbox onFilesAdded={handleFilesAdd} onDrop={handleFileDrop} />
      </div>
      {documents.map((file, idx) => (
        <Fragment key={`selected-file-${idx}`}>
          <div className="mb-4">
            <SelectedFile
              key={`selected-file-${idx}`}
              file={file.file}
              onDeleteClick={onRemoveFile}
              updateContentsCb={() => {}}
            />
          </div>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column flex-1">
              <TextInput
                label="Name"
                value={file.filename}
                onChange={(value: string) => onFileUpdate({ ...file, filename: value })}
              />
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column flex-1">
              <DateInput
                label="Expiration Date"
                date={file.expirationDate}
                onDateSelect={(date) => onFileUpdate({ ...file, expirationDate: date })}
                className="kt-date-input-no-max-width"
              />
            </div>
            <div className="d-flex flex-column flex-1" />
          </div>
          {documentHasExpired(file.expirationDate) && (
            <div>
              <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} color={colors.warning} />
              <span>This document has expired</span>
            </div>
          )}
          {idx !== documents.length - 1 && <HorizontalDivider />}
        </Fragment>
      ))}
    </section>
  );
};

export default ChildMedicalDocumentationUploadForm;
