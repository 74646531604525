import React, { useState, useCallback } from 'react';
import { Row, Col } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import { useGetActiveCentersQuery } from 'shared/hooks/useGetActiveCenters';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { useAddCenterToAccount } from 'gql/account/mutations';
import { showToast } from 'shared/components/Toast';
import { useHistory } from 'react-router-dom';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  account: IAccount;
}

const AddCenterToAccountModal: React.FC<IProps> = ({ isOpen, onClose, account }) => {
  const history = useHistory();
  const { data: activeCentersData } = useGetActiveCentersQuery();
  const [centerId, updateCenterId] = useState('');
  const handleClose = useCallback(() => {
    onClose();
    updateCenterId('');
  }, [onClose]);

  const [addCenterToAccount, { loading }] = useAddCenterToAccount();

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const handleAddCenter = useCallback(() => {
    addCenterToAccount({
      variables: { input: { accountId: account.id, centerId } },
    })
      .then((data) => {
        showToast(`${fieldLabels.center} added successfully.`, 'success');
        handleClose();
        history.push(`/families/accounts/${data.data?.addCenterToAccount?.id}/profile`);
      })
      .catch(() => {
        showToast(
          `There was an error adding the ${fieldLabels.center.toLowerCase()}. Please try again later.`,
          'error'
        );
      });
  }, [account.id, addCenterToAccount, centerId, handleClose, history]);

  if (!isOpen || !account) {
    return null;
  }

  return (
    <ConfirmationModal
      title={`Add ${fieldLabels.center}`}
      show={isOpen}
      onHide={handleClose}
      hideOnCallback={false}
      primaryChoice={`Add ${fieldLabels.center}`}
      primaryCallback={handleAddCenter}
      secondaryCallback={handleClose}
      primaryButtonProps={{ loading, disabled: !centerId }}
    >
      <p>Select the {fieldLabels.center.toLowerCase()} to copy this account to</p>
      <Row>
        <Col>
          <Select
            required
            label={`${fieldLabels.center}`}
            value={centerId}
            options={
              activeCentersData?.searchCenters.data.filter(
                (center) => center.entityId === account.entityId && center.id !== account.centerId
              ) ?? []
            }
            onChange={(option: ICenter) => updateCenterId(option.id)}
            getOptionLabel={(option: ICenter) => option.name}
            getOptionValue={(option: ICenter) => option.id}
          />
        </Col>
      </Row>
    </ConfirmationModal>
  );
};

export default AddCenterToAccountModal;
