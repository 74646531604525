import React, { Fragment, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';

type ClassEnrollmentTableSortableColumns = 'lastName' | 'startDate' | 'ageUpDate';

interface ISortableColumnsStateShape {
  lastName: 'asc' | 'desc';
  startDate: 'asc' | 'desc';
  ageUpDate: 'asc' | 'desc';
}

interface IProps {
  isFullDayClass: boolean;
  onColumnSort: (column: ClassEnrollmentTableSortableColumns, direction: 'asc' | 'desc') => void;
}

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const TableHeader: React.FC<IProps> = ({ isFullDayClass, onColumnSort, ...props }) => {
  const [sortedFields, setSortedFields] = useState<ISortableColumnsStateShape>({
    lastName: 'asc', // default sort order
    startDate: 'desc',
    ageUpDate: 'desc',
  });

  const handleColumnSort = useCallback(
    (column: 'lastName' | 'startDate' | 'ageUpDate') => {
      setSortedFields((prev) => ({
        ...prev,
        [column]: prev[column] === 'desc' ? 'asc' : 'desc',
      }));

      onColumnSort(column, sortedFields[column]);
    },
    [onColumnSort, sortedFields]
  );

  return (
    <thead>
      <tr>
        <th tabIndex={0} className="sortable" onClick={() => handleColumnSort('lastName')}>
          Child Name
          <FontAwesomeIcon
            className="ml-4 icon-lowered"
            icon={sortedFields.lastName === 'desc' ? faAngleDown : faAngleUp}
            size="1x"
          />
        </th>
        <th tabIndex={0} className="sortable" onClick={() => handleColumnSort('startDate')}>
          Start Date
          <FontAwesomeIcon
            className="ml-4 icon-lowered"
            icon={sortedFields.startDate === 'desc' ? faAngleDown : faAngleUp}
            size="1x"
          />
        </th>
        <th tabIndex={0} className="sortable" onClick={() => handleColumnSort('ageUpDate')}>
          Age Up Date
          <FontAwesomeIcon
            className="ml-4 icon-lowered"
            icon={sortedFields.ageUpDate === 'desc' ? faAngleDown : faAngleUp}
            size="1x"
          />
        </th>
        {DAYS.map((day: string, idx: number) => (
          <th
            key={`enrollment-class-table-header-column-${day}-${idx}`}
            className="day-column-full font-weight-bold text-dark"
            colSpan={2}
          >
            {day}
          </th>
        ))}
        <th>Notes</th>
      </tr>
      {!isFullDayClass && (
        <tr>
          <th />
          <th />
          <th />
          {DAYS.map((day: string, idx: number) => (
            <Fragment key={`enrollment-class-table-sub-header-column-${day}-${idx}`}>
              <th className="day-column-half font-weight-bold text-dark">AM</th>
              <th className="day-column-half font-weight-bold text-dark">PM</th>
            </Fragment>
          ))}
          <th />
        </tr>
      )}
    </thead>
  );
};

export default TableHeader;
