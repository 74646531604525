export interface IAbsenceReasonOption {
  label: string;
  value: AbsenceReason;
}

export interface IAddtionalAbsenceReasonOption {
  label: string;
  value: AdditionalAbsenceReason;
}

const absenceReasons: IAbsenceReasonOption[] = [
  { label: 'Sick', value: 'SICK' },
  { label: 'Vacation', value: 'VACATION' },
  { label: 'Quarantine', value: 'QUARANTINE' },
  { label: 'Appointment', value: 'APPOINTMENT' },
  { label: 'Alternative Care', value: 'ALTERNATIVE_CARE' },
  { label: 'Transportation Issue', value: 'TRANSPORTATION_ISSUE' },
];

export const additionalAbsenceReasons: IAddtionalAbsenceReasonOption[] = [
  { label: 'Child ill', value: 'CHILD_ILL' },
  { label: 'Individual caring for child is ill', value: 'INDIVIDUAL_CARING_FOR_CHILD_IS_ILL' },
  { label: 'Partner of individual caring for child is ill', value: 'PARTNER_OF_INDIVIDUAL_CARING_FOR_CHILD_IS_ILL' },
  { label: 'Individual who lives with child is ill', value: 'INDIVIDUAL_WHO_LIVES_WITH_CHILD_IS_ILL' },
  { label: 'Child attending pre-school', value: 'CHILD_ATTENDING_PRE_SCHOOL' },
  { label: 'Pupil free day', value: 'PUPIL_FREE_DAY' },
  { label: 'Court order or parenting order in place', value: 'COURT_ORDER_OR_PARENTING_ORDER_IN_PLACE' },
  { label: 'Local emergency - service closed', value: 'LOCAL_EMERGENCY_SERVICE_CLOSED' },
  { label: 'Local emergency - unable to attend', value: 'LOCAL_EMERGENCY_UNABLE_TO_ATTEND' },
  {
    label: `Local emergency - child's carer does not wish to attend`,
    value: 'LOCAL_EMERGENCY_CHILDS_CARER_DOES_NOT_WISH_TO_ATTEND',
  },
  {
    label: 'Not immunised against particular infectious disease and absence during grace period',
    value: 'NOT_IMMUNISED_AGAINST_PARTICULAR_INFECTIOUS_DISEASE',
  },
  { label: 'Prescribed - Service has changed ownership', value: 'PRESCRIBED_SERVICE_HAS_CHANGED_OWNERSHIP' },
  {
    label: 'Prescribed - Usual service closed and child attending different service under the same provider',
    value: 'PRESCRIBED_USUAL_SERVICE_CLOSED_AND_CHILD_ATTENDING_DIFFERENT_SERVICE_UNDER_THE_SAME_PROVIDER',
  },
  { label: 'Prescribed - Enrolment ceased incorrectly', value: 'PRESCRIBED_ENROLMENT_CEASED_INCORRECTLY' },
  { label: 'Prescribed - Family tragedy', value: 'PRESCRIBED_FAMILY_TRAGEDY' },
];
export default absenceReasons;

/**
 * Prescribed - Service has changed ownership
Prescribed - Usual service closed and child attending different service under the same provider
Prescribed - Enrolment ceased incorrectly
Prescribed - Family tragedy
 */
