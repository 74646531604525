import { useGetLinkedCenters } from 'gql/business/queries';
import React, { useMemo, useState } from 'react';
import DataTable from 'shared/components/DataTable';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import useDatatableState from 'shared/hooks/useDatatableState2';

export interface IProps {
  providerId: string;
  businessId: string;
  isOpen: boolean;
  onClose: () => void;
}

const ViewLinkedCentersModal: React.FC<IProps> = ({ isOpen, onClose, providerId, businessId }) => {
  const [tableState, tableFunctions] = useDatatableState();

  const { data: linkedCenters, loading } = useGetLinkedCenters({
    variables: {
      providerId,
      businessId,
      input: {
        pageNumber: tableState.activePage,
        pageSize: 10,
      },
    },
    skip: !providerId,
  });

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  const linkedCenterRows = useMemo(
    () => linkedCenters?.getLinkedCenters?.data.map((value, index) => ({ id: index, data: value })) ?? [],
    [linkedCenters]
  );

  return (
    <CenteredModal
      size="lg"
      show={isOpen}
      onHide={onClose}
      title={`Linked ${fieldLabels.center}s`}
      primaryChoice="Close"
      primaryCallback={onClose}
      hasSecondaryChoice={false}
    >
      <DataTable
        data={linkedCenterRows}
        dataSize={linkedCenters?.getLinkedCenters.totalRecords ?? 0}
        pageSize={10}
        showLoadingOverlay={loading}
        showSelect={false}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        activePage={tableState.activePage}
        updateSelectedRows={tableFunctions.updateSelectedRows}
        noPadding
        columns={[
          {
            text: `${fieldLabels.center} Name`,
            dataField: 'data.centerName',
          },
          {
            text: 'CCSS Service ID',
            dataField: 'data.ccssServiceId',
          },
          {
            text: 'CCSS Service Name',
            dataField: 'data.ccssServiceName',
          },
        ]}
      />
    </CenteredModal>
  );
};

export default ViewLinkedCentersModal;
