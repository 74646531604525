import moment from 'moment';
import React from 'react';
import DataTable from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { getInitials, stringToHsl } from 'shared/util/string';
import { ICcsEntitlement } from 'shared/types/ccsEntitlement';

export interface ICcsEntitlementDetails {
  id: string;
  child: IAccountChild;
  entitlement: ICcsEntitlement;
}

interface ITableProps {
  data: Partial<ICcsEntitlementDetails>[];
  expandRow?: (row: any) => JSX.Element;
  account?: IAccount;
  loading: boolean;
}

const dateFormat = 'DD/MM/YYYY';

const EntitlementsTable: React.FC<ITableProps> = ({ data, expandRow, loading }) => {
  return (
    <DataTable
      noPadding={true}
      data={data}
      expandRow={expandRow}
      showLoadingOverlay={loading}
      showSelect={false}
      showPagination={false}
      expandHeaderColumnRenderer={(props) => <></>} // We don't have a table header so hide it
      columns={[
        {
          headerAttrs: {
            hidden: true,
          },
          text: '',
          dataField: 'avatar',
          formatter: (cell: string, row: ICcsEntitlementDetails) => (
            <AvatarDataTableCell
              color={stringToHsl(row.child.id)}
              initials={getInitials(row.child)}
              avatar={row.child.avatar?.url ?? ''}
              primaryText={row.child.fullName}
            />
          ),
        },
        {
          text: '',
          dataField: 'date',
          formatter: (cell: any, row: ICcsEntitlementDetails) => moment(row.entitlement.date).format(dateFormat),
        },
        {
          text: '',
          dataField: 'currentHours',
          formatter: (cell: any, row: ICcsEntitlementDetails) => `${row.entitlement.apportionedHoursPerFortnight}hr`,
        },
        {
          text: '',
          dataField: 'currentPercentage',
          formatter: (cell: any, row: ICcsEntitlementDetails) => `${row.entitlement.percentage}%`,
        },
      ]}
    />
  );
};

export default EntitlementsTable;
