import React from 'react';
import './_chip.scss';

interface ChipProps {
  text: string;
  type?: 'default' | 'info' | 'success' | 'warning' | 'danger';
  rounded?: boolean;
  border?: boolean;
  style?: object;
}

const Chip: React.FC<ChipProps> = ({ text, type = 'default', ...props }: ChipProps) => {
  const getStyles = () => {
    let myStyles = `chip chip-${type}`;
    if (props.rounded) myStyles += ' chip-rounded';
    if (props.border) myStyles += ` chip-outline-border-${type}`;
    return myStyles;
  };
  const chipClass = getStyles();

  return (
    <span className={chipClass} style={props.style}>
      {text}
    </span>
  );
};

export default Chip;
