import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import CrnInput from 'shared/components/CrnInput';
import DateInput from 'shared/components/DateInput';
import { VerticalDivider } from 'shared/components/Dividers';
import Col from 'shared/components/Layout/Col';
import Row from 'shared/components/Layout/Row';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Select from 'shared/components/Select';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import { ccsTypeEnum2, peaReasonOptions } from 'shared/constants/enums/ccssEnums';
import { isDateRangeInvalid } from 'shared/util/timeUtils';
import { useEditChildCcsEnrolment } from 'gql/ccssEnrolment/mutations';
import Checkbox from 'shared/components/Checkbox';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { ApolloError } from '@apollo/client';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { noop } from 'lodash';
import { isEnrolmentEndDateInvalid, useGetPeaWeeksRemaining } from './CustomHook';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  account: IAccount;
  enrolmentInEdit: ICcsEnrolment;
}

const EditCcsEnrolmentModal2: React.FC<IProps> = ({ isOpen, onClose, account, enrolmentInEdit }) => {
  const [enrolment, setEnrolment] = useState<IEditCcsEnrolmentInput>({
    id: enrolmentInEdit.id,
    startDate: enrolmentInEdit.arrangementStartDate,
    endDate: enrolmentInEdit.arrangementEndDate,
    lateSubmissionReason: enrolmentInEdit.lateSubmissionReason,
    centerId: account.centerId,
    ccsType: enrolmentInEdit.arrangementType,
    childInStateCare: enrolmentInEdit.childInStateCare,
    signingParty: {
      firstName: enrolmentInEdit.signingPartyFirstName,
      lastName: enrolmentInEdit.signingPartyLastName,
      organisationName: enrolmentInEdit.signingPartyOrganisation,
    },
  });

  const isSubmissionLate = useMemo(
    () =>
      !!enrolment.startDate &&
      moment(moment(enrolment.startDate).format('YYYY-MM-DD')).isAfter(
        moment(enrolmentInEdit.arrangementStartDate).format('YYYY-MM-DD')
      ),
    [enrolment]
  );

  const [editChildCcsEnrolment, { loading }] = useEditChildCcsEnrolment();

  const isPeaCcsType = enrolment.ccsType === 'PEA';

  const peaWeeksRemaining = useGetPeaWeeksRemaining({
    accountId: account.id,
    centerId: account.centerId,
    childIds: [enrolmentInEdit.childId],
  });

  const isEndDateInvalid = (day: any) => {
    return isEnrolmentEndDateInvalid(
      enrolment.startDate,
      day,
      isPeaCcsType && enrolmentInEdit.reasonForPea === 'FOSKIN',
      peaWeeksRemaining ?? 0
    );
  };

  const handleClose = useCallback(() => {
    onClose();
    setEnrolment({
      id: enrolmentInEdit.id,
      startDate: enrolmentInEdit.arrangementStartDate,
      endDate: enrolmentInEdit.arrangementEndDate,
      lateSubmissionReason: enrolmentInEdit.lateSubmissionReason,
      centerId: account.centerId,
      ccsType: enrolmentInEdit.arrangementType,
      childInStateCare: enrolmentInEdit.childInStateCare,
      signingParty: {
        firstName: enrolmentInEdit.signingPartyFirstName,
        lastName: enrolmentInEdit.signingPartyLastName,
        organisationName: enrolmentInEdit.signingPartyOrganisation,
      },
    });
  }, [
    account.centerId,
    enrolmentInEdit.arrangementEndDate,
    enrolmentInEdit.arrangementStartDate,
    enrolmentInEdit.arrangementType,
    enrolmentInEdit.childInStateCare,
    enrolmentInEdit.id,
    enrolmentInEdit.lateSubmissionReason,
    enrolmentInEdit.signingPartyFirstName,
    enrolmentInEdit.signingPartyLastName,
    enrolmentInEdit.signingPartyOrganisation,
    onClose,
  ]);

  const handleContinue = useCallback(() => {
    enrolment.centerId = account.centerId;

    if (enrolment.ccsType === 'OA') {
      enrolment.signingParty.firstName = undefined;
      enrolment.signingParty.lastName = undefined;
    } else {
      enrolment.signingParty.organisationName = undefined;
    }

    editChildCcsEnrolment({
      variables: {
        input: enrolment,
      },
    })
      .then(() => {
        showToast('Child CCS enrolment updated successfully', 'success');
        handleClose();
      })
      .catch((err: Error) => {
        const message = err instanceof ApolloError ? getApolloErrorMessage(err) : err.message;
        showToast(message, 'error');
      });
  }, [account.centerId, editChildCcsEnrolment, enrolment, handleClose]);

  const handleChange = useCallback(
    (value, name) => {
      setEnrolment({ ...enrolment, [name]: value });
    },
    [enrolment]
  );

  const handleSigningPartyChange = useCallback(
    (value, name) => {
      setEnrolment({
        ...enrolment,
        signingParty: { ...enrolment.signingParty, [name]: value },
      });
    },
    [enrolment]
  );

  const handleCcsTypeChange = useCallback(
    (value, name) => {
      setEnrolment({ ...enrolment, [name]: value });
    },
    [enrolment]
  );

  const selectedContact = useMemo(
    () => account?.contacts?.find((g) => g.id === enrolmentInEdit.contactId),
    [account, enrolmentInEdit.contactId]
  );

  const selectedChild = useMemo(
    () => account?.children?.find((c) => c.id === enrolmentInEdit.childId),
    [account, enrolmentInEdit.childId]
  );

  const isOaCcsType = useMemo(() => enrolment.ccsType === 'OA', [enrolment]);

  const isAccsCcsType = useMemo(() => enrolment.ccsType === 'ACCS', [enrolment]);

  const isFormValid = !isDateRangeInvalid(enrolment.startDate, enrolment.endDate);

  const canSetArrangementType = (c: string) => {
    if (
      enrolmentInEdit.arrangementType === 'CWA' ||
      enrolmentInEdit.arrangementType === 'ACCS' ||
      enrolmentInEdit.arrangementType === 'PEA'
    ) {
      return false;
    }

    if (c === 'CWA' || c === 'ACCS' || c === 'PEA') {
      return false;
    }

    return true;
  };

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <CenteredModal
      size="lg"
      show={isOpen}
      onHide={handleClose}
      title="Edit CCS Enrolment"
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryChoice="Continue"
      primaryCallback={handleContinue}
      primaryButtonProps={{ disabled: !isFormValid, loading }}
    >
      <Form>
        <Row align="start">
          <Col>
            <Row>
              <Col>
                <TextInput label={`${fieldLabels.center} Name`} value={account?.center?.name ?? ''} disabled={true} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DateInput
                  label="Start Date"
                  date={enrolment.startDate}
                  onDateSelect={(startDate: string) => handleChange(startDate, 'startDate')}
                  isValid={isFormValid}
                />
              </Col>
              <Col>
                <DateInput
                  label="End Date"
                  date={enrolment.endDate}
                  isOutsideRange={(day) => isEndDateInvalid(day)}
                  onDateSelect={(endDate: string) => handleChange(endDate, 'endDate')}
                  isValid={isFormValid && !isEndDateInvalid(enrolment.endDate)}
                  required={enrolment.ccsType === 'PEA' && enrolmentInEdit.reasonForPea === 'FOSKIN'}
                />
              </Col>
            </Row>
            {isSubmissionLate && (
              <Row>
                <Col>
                  <TextInput
                    name="lateSubmissionReason"
                    label="Late Reason"
                    value={enrolment.lateSubmissionReason}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            )}
            <h5>Contact Details</h5>
            <Row>
              <Col>
                <Select
                  label="Contact Name"
                  placeholder="Select Contact..."
                  value={selectedContact}
                  options={account?.contacts ?? []}
                  getOptionLabel={(g) => `${g.firstname} ${g.lastname}`}
                  disabled
                  onChange={() => {}}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-4">
                <DateInput label="D.O.B." placeholder="" date={selectedContact?.dob} disabled={true} />
              </Col>
              <Col>
                <CrnInput label="CRN" value={selectedContact?.ccssAttributes?.crn} disabled={true} />
              </Col>
            </Row>
            <h5>Child Details</h5>
            <Row>
              <Col>
                <Select
                  label="Child Name"
                  placeholder="Select Child..."
                  options={account?.children}
                  value={selectedChild}
                  getOptionLabel={(c) => `${c.firstname} ${c.lastname}`}
                  disabled
                  onChange={() => {}}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-4">
                <DateInput label="D.O.B." placeholder="" date={selectedChild?.dob} disabled={true} />
              </Col>
              <Col>
                <CrnInput label="CRN" value={selectedChild?.ccssAttributes?.crn} disabled={true} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Checkbox
                  label="Child in State Care?"
                  value={enrolment.childInStateCare}
                  onChange={(checked: boolean) => handleChange(checked, 'childInStateCare')}
                />
              </Col>
            </Row>
          </Col>
          <VerticalDivider />
          <Col>
            <Row>
              <h5>CCS Type</h5>
            </Row>
            <Row>
              {ccsTypeEnum2.map((c) => (
                <Form.Check className="d-flex align-items-center mb-2">
                  <Form.Check.Input
                    id={c.value}
                    disabled={!canSetArrangementType(c.value)}
                    type="radio"
                    name="ccs-type-option"
                    checked={enrolment.ccsType === c.value}
                    onClick={() => handleCcsTypeChange(c.value, 'ccsType')}
                  />
                  <Form.Check.Label>{c.label}</Form.Check.Label>
                </Form.Check>
              ))}
            </Row>
            <Row className="mt-4">
              <h5>Signing Party</h5>
            </Row>
            {!isOaCcsType && (
              <Row>
                <Col>
                  <TextInput
                    name="firstName"
                    label="First Name"
                    value={enrolment.signingParty.firstName}
                    onChange={handleSigningPartyChange}
                    disabled={isAccsCcsType || isPeaCcsType}
                  />
                </Col>
                <Col>
                  <TextInput
                    name="lastName"
                    label="Last Name"
                    value={enrolment.signingParty.lastName}
                    onChange={handleSigningPartyChange}
                    disabled={isAccsCcsType || isPeaCcsType}
                  />
                </Col>
              </Row>
            )}
            {isOaCcsType && (
              <Row>
                <TextInput
                  name="organisationName"
                  label="Organisation Name"
                  value={enrolment.signingParty.organisationName}
                  onChange={handleSigningPartyChange}
                />
              </Row>
            )}
            {isPeaCcsType && (
              <Row>
                <Col>
                  <Select
                    label="Reason for PEA"
                    options={peaReasonOptions}
                    value={enrolmentInEdit.reasonForPea}
                    onChange={noop}
                    disabled
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    </CenteredModal>
  );
};

export default EditCcsEnrolmentModal2;
