import React, { useEffect, useState } from 'react';
import { fileToK2Upload } from 'shared/util/file';

const useK2Upload = (shouldReset: boolean) => {
  const [files, setFiles] = useState<File[]>([]);
  const [k2File, setK2File] = useState<IK2UploadFile | undefined>();
  const transform = async (files: File[]) => {
    if (files.length > 0) {
      const k2File = await fileToK2Upload(files[0]);
      setK2File(k2File);
    }
  };
  useEffect(() => {
    transform(files);
  }, [files]);

  useEffect(() => {
    if (shouldReset) setFiles([]);
  }, [shouldReset]);

  return { files, setFiles, k2File };
};

export default useK2Upload;
