import React from 'react';
import BootstrapCol from 'react-bootstrap/Col';
import { ILayoutProps } from './LayoutProps';

const Col: React.FC<ILayoutProps> = ({ justify = 'center', align = 'center', className, children, ...props }) => {
  return (
    <BootstrapCol {...props} className={`justify-content-${justify} align-items-${align} ${className || ''}`}>
      {children}
    </BootstrapCol>
  );
};

export default Col;
