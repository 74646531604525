import React from 'react';

import * as Mui from '@mui/material';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faEllipsisH, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '_colors.module.scss';
import EditPayCode from './EditPayCode';
import { useArchivePayCode } from '../graphQL/mutations';
import { showToast } from 'shared/components/Toast';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
};

interface IProps {
  row: IPayCodeType;
  refetch: () => void;
}

const PayCodeTableRow: React.FC<IProps> = ({ row, refetch }) => {
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [archiveModalOpen, setArchiveModalOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const hasEditOperationsPermission = useHasRoleAreaLevel({
    area: AreaType.Operations,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });

  const hasDeleteOperationsPermission = useHasRoleAreaLevel({
    area: AreaType.Operations,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });

  const [archivePayCodeFn] = useArchivePayCode({
    onCompleted: () => {
      showToast('PTO Type Archived', 'success');
      refetch();
    },
    onError: (error) => {
      showToast(getApolloErrorMessage(error), 'error');
    },
  });

  const onArchive = () => {
    archivePayCodeFn({
      variables: {
        input: {
          businessId: row.businessId,
          id: row.id,
        },
      },
    });
  };

  return (
    <>
      <Mui.TableRow key={row.id}>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">{row.name}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">{row.trainingTimeEventType ? 'Center' : 'Personal'}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">{row.code}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left">
          <Mui.Typography variant="body1">{row.center?.name ?? 'All Centers'}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="right">
          <>
            {(hasDeleteOperationsPermission || hasEditOperationsPermission) && (
              <IconButtonCircle
                id="action-dropdown-btn"
                iconSize="2x"
                icon={faEllipsisH}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              />
            )}
            <Mui.Menu
              PaperProps={{ sx: { boxShadow: '1' } }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {hasEditOperationsPermission && (
                <Mui.MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setOpenEdit(true);
                  }}
                >
                  Edit
                </Mui.MenuItem>
              )}
              {hasDeleteOperationsPermission && (
                <Mui.MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setArchiveModalOpen(true);
                  }}
                >
                  Archive
                </Mui.MenuItem>
              )}
            </Mui.Menu>
          </>
        </Mui.TableCell>
      </Mui.TableRow>
      <EditPayCode open={openEdit} onClose={() => setOpenEdit(false)} payCode={row} />
      <Mui.Dialog open={archiveModalOpen} onClose={() => setArchiveModalOpen(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography variant="dialogTitle">Archive PTO Type</Mui.Typography>
          <Mui.IconButton onClick={() => setArchiveModalOpen(false)} disableRipple sx={styles.closeIcon}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>{`Are you sure you would like to archive ${row.name}?`}</Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setArchiveModalOpen(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button variant="contained" color="secondary" onClick={() => onArchive()}>
            Confirm
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default PayCodeTableRow;
