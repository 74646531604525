import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ViewNotes from './ViewNotes';
import AddNote from './AddNote';
import { useLeadsContext } from '../../../LeadsContext';
import { Application } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import './applicationNotesModal.scss';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import noNote from './images/no-note.png';
import Button from 'shared/components/Buttons';

const ApplicationNotesModal = () => {
  const { noteModalState, setNoteModalState, applicationsData } = useLeadsContext();
  const [isEditingNote, setIsEditingNote] = useState(false);
  const application = applicationsData?.getApplicationsByStage.data.find((d) => d.id === noteModalState.applicationId);
  const hasNote = (application?.notes.length ?? 0) > 0;
  const [skipNoNotesScreen, setSkipNoNotesScreen] = useState(hasNote);

  useEffect(() => {
    setSkipNoNotesScreen(
      (applicationsData?.getApplicationsByStage.data.find((d) => d.id === noteModalState.applicationId)?.notes.length ??
        0) > 0
    );
  }, [noteModalState, applicationsData]);

  const hasAddNotePermission = useHasRoleAreaLevel({
    area: AreaType.Enrollment,
    permission: PermissionType.LeadManagementMain,
    level: RoleLevelType.Create,
  });

  const { t } = useTranslation();

  const onClose = () => {
    setNoteModalState({
      isOpen: false,
      applicationId: undefined,
    });
  };

  const onEditOpen = () => setIsEditingNote(true);
  const oneEditSuccessful = () => setIsEditingNote(false);
  const oneEditCanceled = () => setIsEditingNote(false);

  const onAddNoteCancel = () => {
    if (application?.notes.length === 0) {
      setSkipNoNotesScreen(false);
    }
  };

  if (noteModalState.isOpen && noteModalState.applicationId) {
    return (
      <Modal centered show onHide={onClose} size="lg" className="application-note-modal" scrollable>
        <Modal.Header closeButton className="application-note-modal--header">
          <Modal.Title as="div">
            <h2 className="mb-0">{t('enrollment.lead-management.notes.notes')}</h2>
            <div className="application-note-modal--header--count">
              {t('enrollment.lead-management.notes.total-added', { count: application?.notes.length ?? 0 })}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="application-note-modal--body">
          {!skipNoNotesScreen && (
            <div className="no-notes-placeholder w-100 d-flex justify-content-center flex-column align-items-center">
              <img src={noNote} alt="cartoon helper man" width={200} height={200} className="mb-4" />
              <h2>{t('enrollment.lead-management.notes.no-notes.title')}</h2>
              <p>{t('enrollment.lead-management.notes.no-notes.msg')}</p>
              {hasAddNotePermission && (
                <Button variant="primary" className="px-8 my-5" onClick={() => setSkipNoNotesScreen(true)}>
                  {t('enrollment.lead-management.notes.add-note')}
                </Button>
              )}
            </div>
          )}

          {skipNoNotesScreen && (
            <>
              {hasAddNotePermission && (
                <AddNote application={application as Application} disabled={isEditingNote} onCancel={onAddNoteCancel} />
              )}
              <ViewNotes
                data={application?.notes ?? []}
                onEditOpen={onEditOpen}
                onEditSuccessful={oneEditSuccessful}
                onEditCanceled={oneEditCanceled}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  return null;
};

export default ApplicationNotesModal;
