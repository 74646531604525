import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { serviceContactFields, serviceLocationFields } from './fields';

export const UPDATE_SERVICE_NAME = gql`
  mutation ($input: UpdateServiceNameInput!) {
    updateServiceName(input: $input) {
      name
    }
  }
`;

export interface IUpdateServiceNameInput {
  serviceId: string;
  endDate: string;
  startDate: string;
  name: string;
  supportingDocumentFiles: IServiceNameChangeEvidence[];
}

interface IUpdateServiceNameVariables {
  input: IUpdateServiceNameInput;
}

interface IUpdateServiceNameData {
  returnFeeReductionId: string;
}

export const useUpdateServiceName = (
  options?: MutationHookOptions<IUpdateServiceNameData, IUpdateServiceNameVariables> | undefined
) => useMutation<IUpdateServiceNameData, IUpdateServiceNameVariables>(UPDATE_SERVICE_NAME, options);

export const UPDATE_SERVICE_ADDRESS = gql`
  mutation ($input: UpdateServiceAddressInput!) {
    updateServiceAddress(input: $input)
  }
`;

export interface IUpdateServiceAddressInput {
  serviceId: string;
  type: string;
  streetLine1: string;
  streetLine2: string;
  suburb: string;
  state: string;
  postcode: string;
  startDate: string;
  supportingDocumentFiles: IServiceNameChangeEvidence[];
}

interface IUpdateServiceAddressVariables {
  input: IUpdateServiceAddressInput;
}

interface IUpdateServiceNameData {}

export const useUpdateServiceAddress = (
  options?: MutationHookOptions<IUpdateServiceNameData, IUpdateServiceAddressVariables> | undefined
) => useMutation<IUpdateServiceNameData, IUpdateServiceAddressVariables>(UPDATE_SERVICE_ADDRESS, options);

export const UPDATE_SERVICE_CONTACT = gql`
  mutation($input: UpdateServiceContactInput!) {
    updateServiceContact(input: $input){
      ${serviceContactFields}
    }
  }
`;

export const UPDATE_SERVICE_FINANCIAL_DETAILS = gql`
  mutation ($input: UpdateServiceFinancialDetailsInput!) {
    updateServiceFinancialDetails(input: $input)
  }
`;

export const UPDATE_SERVICE_LOCATION_OF_RECORD = gql`
  mutation($input: UpdateServiceLocationOfRecordInput!) {
    updateServiceLocationOfRecord(input: $input){
      ${serviceLocationFields}
    }
  }
`;

interface IUpdateServiceContact {
  updateServiceContact: IServiceContact;
}

interface IUpdateServiceContactVariables {
  input: IUpdateServiceContactInput;
}

interface IUpdateServiceFinancialDetails {
  updateServiceFinancialDetails: boolean;
}

interface IUpdateServiceLocationOfRecordVariables {
  input: IUpdateServiceLocationOfRecordInput;
}

export const useUpdateServiceContact = (
  options?: MutationHookOptions<IUpdateServiceContact, IUpdateServiceContactVariables> | undefined
) => useMutation<IUpdateServiceContact, IUpdateServiceContactVariables>(UPDATE_SERVICE_CONTACT, options);

export const useUpdateServiceFinancialDetails = (
  options?: MutationHookOptions<IUpdateServiceFinancialDetails, IUpdateServiceFinancialDetailsVariables>
) => {
  return useMutation<IUpdateServiceFinancialDetails, IUpdateServiceFinancialDetailsVariables>(
    UPDATE_SERVICE_FINANCIAL_DETAILS,
    options
  );
};

export const useUpdateServiceLocationOfRecord = (
  options?: MutationHookOptions<IUpdateServiceLocationOfRecord, IUpdateServiceLocationOfRecordVariables>
) => {
  return useMutation<IUpdateServiceLocationOfRecord, IUpdateServiceLocationOfRecordVariables>(
    UPDATE_SERVICE_LOCATION_OF_RECORD,
    options
  );
};

export interface IUpdateProviderLocationOfRecord {
  updateServiceLocationOfRecord: boolean;
}

export const UPDATE_Provider_LOCATION_OF_RECORD = gql`
  mutation ($input: UpdateProviderLocationOfRecordInput!) {
    updateProviderLocationOfRecord(input: $input)
  }
`;

interface IUpdateProviderLocationOfRecordVariables {
  input: IUpdateProviderLocationOfRecordInput;
}

export const useUpdateProviderLocationOfRecord = (
  options?: MutationHookOptions<IUpdateProviderLocationOfRecord, IUpdateProviderLocationOfRecordVariables>
) => {
  return useMutation<IUpdateProviderLocationOfRecord, IUpdateProviderLocationOfRecordVariables>(
    UPDATE_Provider_LOCATION_OF_RECORD,
    options
  );
};

interface IStopOperatingServiceSaleInput {
  providerName: string;
  contactName?: string;
  email?: string;
  phoneNumber: string;
  mobileNumber?: string;
  expectedDate: Date | string;
}
interface IStopOperatingServiceInput {
  businessId: string;
  serviceId: string;
  expectedClosureDate: Date | string;
  isParentsNotified: boolean;
  isSale: boolean;
  notifyDept: boolean;
  sale?: IStopOperatingServiceSaleInput | null;
}
interface IStopOperatingServiceInput2 {
  input: IStopOperatingServiceInput;
}
interface IService {}

const stopOperatingService = gql`
  mutation ($input: StopOperatingServiceInput!) {
    stopOperatingService(input: $input)
  }
`;
export const useStopOperatingService = (
  options?: MutationHookOptions<IService, IStopOperatingServiceInput2> | undefined
) => useMutation<IService, IStopOperatingServiceInput2>(stopOperatingService, options);

interface IService {}
interface IProviderAddressInput {
  streetLine1?: string;
  streetLine2?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  type: string;
}
interface IProviderAdministratorInput {
  email?: string;
  name: string;
  abn: string;
  phone: string;
  startDate: Date | string;
  endDate: Date | string;
  addresses: IProviderAddressInput[];
}

interface ICreateCcssProviderFinancialStatus {
  businessId: string;
  providerId: string;
  status: string;
  startDate: Date | string;
  endDate: Date | string;
  administrator: IProviderAdministratorInput | null;
  supportingDocumentFiles: File[];
}
interface ICreateCcssProviderFinancialStatusInput {
  input: ICreateCcssProviderFinancialStatus;
}
const createCcssProviderFinancialStatus = gql`
  mutation ($input: CreateProviderFinancialStatusInput!) {
    createCcssProviderFinancialStatus(input: $input)
  }
`;
export const useCreateCcssProviderFinancialStatus = (
  options?: MutationHookOptions<IService, ICreateCcssProviderFinancialStatusInput> | undefined
) => useMutation<IService, ICreateCcssProviderFinancialStatusInput>(createCcssProviderFinancialStatus, options);

interface ICreateUpdateServiceTemporarilyCeasing {
  input: {
    startDate: string | Date;
    endDate: string | Date;
    reasonText: string;
    reasonType: string;
    serviceId: string;
    businessId: string;
  };
}
interface DeleteServiceTemporarilyCeasingInput {
  input: {
    startDate: string | Date;
    endDate: string | Date;
    reasonType: string;
    serviceId: string;
    businessId: string;
  };
}

const createServiceTemporarilyCeasing = gql`
  mutation ($input: ServiceTemporarilyCeasingInput!) {
    createServiceTemporarilyCeasing(input: $input)
  }
`;

const updateServiceTemporarilyCeasing = gql`
  mutation ($input: ServiceTemporarilyCeasingInput!) {
    updateServiceTemporarilyCeasing(input: $input)
  }
`;

const deleteServiceTemporarilyCeasing = gql`
  mutation ($input: DeleteServiceTemporarilyCeasingInput!) {
    deleteServiceTemporarilyCeasing(input: $input)
  }
`;

export const useCreateServiceTemporarilyCeasing = (
  options?: MutationHookOptions<IService, ICreateUpdateServiceTemporarilyCeasing> | undefined
) => useMutation<IService, ICreateUpdateServiceTemporarilyCeasing>(createServiceTemporarilyCeasing, options);

export const useUpdateServiceTemporarilyCeasing = (
  options?: MutationHookOptions<IService, ICreateUpdateServiceTemporarilyCeasing> | undefined
) => useMutation<IService, ICreateUpdateServiceTemporarilyCeasing>(updateServiceTemporarilyCeasing, options);

export const useDeleteServiceTemporarilyCeasing = (
  options?: MutationHookOptions<IService, DeleteServiceTemporarilyCeasingInput> | undefined
) => useMutation<IService, DeleteServiceTemporarilyCeasingInput>(deleteServiceTemporarilyCeasing, options);
