import React, { useState, useCallback } from 'react';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { LoadingLines } from 'shared/components/LoadingSkeletons';
import EmergencyContactInput from 'shared/components/EmergencyContactInput';
import { showToast } from 'shared/components/Toast';
import { ButtonAsLink } from 'shared/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { somePropsEmpty, omitTypename } from 'shared/util/object';
import { isValidPhoneNumber } from 'shared/util/string';
import { useUpdateMyEmergencyContacts } from '../../graphql/mutations';

const DEFAULT_EMERGENCY_CONTACT: IEmergencyContact = {
  firstname: '',
  lastname: '',
  relationship: '',
  phoneNumber: '',
};

interface IProps {
  primaryEmergencyContact: IEmergencyContact | null;
  additionalEmergencyContacts: IEmergencyContact[];
  readOnly?: boolean;
}

const EmergencyContactsForm: React.FC<IProps> = ({
  primaryEmergencyContact,
  additionalEmergencyContacts,
  readOnly = false,
  ...props
}) => {
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [primaryContact, setPrimaryContact] = useState<IEmergencyContact>(
    primaryEmergencyContact || { ...DEFAULT_EMERGENCY_CONTACT }
  );
  const [secondaryContacts, setSecondaryContacts] = useState<IEmergencyContact[]>(additionalEmergencyContacts || []);
  const [updateContactsFn, { loading }] = useUpdateMyEmergencyContacts();

  const resetForm = useCallback(() => {
    setPrimaryContact(primaryContact || { ...DEFAULT_EMERGENCY_CONTACT });
    setSecondaryContacts(additionalEmergencyContacts || []);
    setFormIsDirty(false);
  }, [primaryContact, additionalEmergencyContacts]);

  const validateEmergencyContact = useCallback((emergencyContact: IEmergencyContact): boolean => {
    return (
      emergencyContact &&
      !somePropsEmpty(emergencyContact) &&
      !!emergencyContact.phoneNumber &&
      isValidPhoneNumber(emergencyContact.phoneNumber)
    );
  }, []);

  const validateForm = useCallback(() => {
    const validPrimary: boolean = validateEmergencyContact(primaryContact);

    return secondaryContacts.length === 0
      ? validPrimary
      : validPrimary && validateEmergencyContact(secondaryContacts[secondaryContacts.length - 1]);
  }, [primaryContact, secondaryContacts, validateEmergencyContact]);

  const handleSecondaryContactUpdate = useCallback(
    (contact: IEmergencyContact, idx: number) => {
      const copy = [...secondaryContacts];
      copy[idx] = contact;

      setSecondaryContacts(copy);
      setFormIsDirty(true);
    },
    [secondaryContacts]
  );

  const saveChanges = useCallback(() => {
    // @ts-ignore - omitTypename needs to be rewritten to return the same type
    updateContactsFn({
      variables: {
        input: {
          primaryEmergencyContact: omitTypename(primaryContact),
          additionalEmergencyContacts: secondaryContacts.map((contact) => omitTypename(contact)),
        },
      },
    })
      .then(() => {
        showToast('Emergency contacts updated successfully.', 'success');
        setFormIsDirty(false);
      })
      .catch(() => {
        showToast('There was an error updating your emergency contacts. Please try again later.', 'error');
      });
  }, [primaryContact, secondaryContacts, updateContactsFn]);

  if (loading) {
    return <LoadingLines />;
  }

  return (
    <FormWrapper2
      formIsDirty={formIsDirty}
      toggleDirty={setFormIsDirty}
      saveDisabled={!validateForm()}
      onSave={saveChanges}
      onCancel={resetForm}
    >
      <EmergencyContactInput
        emergencyContact={primaryContact}
        updateEmergencyContact={(contact) => {
          setPrimaryContact((prev) => ({ ...prev, ...contact }));
          setFormIsDirty(true);
        }}
        readOnly={readOnly}
      />
      {secondaryContacts.map((emergencyContact, idx) => (
        <EmergencyContactInput
          key={`emergency-contact-input-group-${idx}`}
          emergencyContact={emergencyContact}
          updateEmergencyContact={(contact) => handleSecondaryContactUpdate(contact, idx)}
          readOnly={readOnly}
        />
      ))}
      {!readOnly && (
        <ButtonAsLink
          className="ml-auto"
          onClick={() => setSecondaryContacts((prev) => [...prev, { ...DEFAULT_EMERGENCY_CONTACT }])}
          disabled={!validateForm()}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="lg" className="mr-2" />
          Add another
        </ButtonAsLink>
      )}
    </FormWrapper2>
  );
};

export default EmergencyContactsForm;
