export const defaultPayCodeFields = `
  id
  businessId
  centerId
  description
  code
  name
  createdAt
  createdBy
  updatedAt
  updatedBy
  archivedAt
  archivedBy
  trainingTimeEventType
  overtimeEligible
  center {
    id
    entityId
    name
    avatar {
      url
    }
  }
`;

export const pagedListPayCodes = `
  totalRecords
  pageNumber
  pageSize
  data {
    ${defaultPayCodeFields}
 }
`;
