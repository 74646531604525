export interface ConfirmationContent {
  onConfirm?: () => Promise<void>;
  onHide?: () => void;
  title: string;
  primaryButtonLabel?: string;
  primaryButtonVariant?: string;
  message: React.ReactNode | string;
  modalSize?: 'sm' | 'lg' | 'xl';
}

export enum Event {
  Show,
}

export type Callback = (content: ConfirmationContent) => void;

export interface EventManager {
  list: Map<Event, Callback>;
  on(event: Event, callback: Callback): EventManager;
  emit(event: Event, content: ConfirmationContent): void;
  cancel(): void;
}

export const eventManager: EventManager = {
  list: new Map(),

  on(event: Event, callback: Callback) {
    this.list.set(event, callback);
    return this;
  },

  emit(event: Event, ...args: any[]) {
    const cb = this.list.get(event);
    // @ts-ignore
    cb && cb(...args);
  },

  cancel() {
    this.list = new Map();
  },
};
