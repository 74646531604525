import React, { useState, useCallback } from 'react';
//components
import { Col, Row } from 'shared/components/Layout';
import TextInput from 'shared/components/TextInput';
import DateInput from 'shared/components/DateInput';
import Select from 'shared/components/Select';
import NotifiableServiceEventsContainer from '../NotifiableServiceEventsContainer';
import { showToast } from 'shared/components/Toast';
//utils
import { STATE_SELECT_OPTIONS } from 'shared/constants/dropdownOptions/countryInfo';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import { useUpdateServiceLocationOfRecord } from '../../graphql/mutations';
import moment from 'moment';

interface Props {}

interface ILocationOfRecordsInput {
  dateOfEvent: string;
  addressLin1: string;
  addressLine2: string;
  suburb: string;
  state: any;
  postcode: string;
  services: string[];
}

const LocationOfRecordsServiceClosure: React.FC<Props> = ({}) => {
  const emptyInputs: ILocationOfRecordsInput = {
    dateOfEvent: '',
    addressLin1: '',
    addressLine2: '',
    suburb: '',
    state: '',
    postcode: '',
    services: [],
  };
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [formData, setFormData] = useState<ILocationOfRecordsInput>(emptyInputs);

  const centerSelectOptions: ICenter[] = useGetActiveCenters();

  const [updateLocation, { loading: updateLocationLoading }] = useUpdateServiceLocationOfRecord({
    onCompleted: (data: IUpdateServiceLocationOfRecord) => {
      showToast(
        `Successfully updated location of records for ${data.updateServiceLocationOfRecord.length} service/s.`,
        'success'
      );
    },
    onError: (error) => {
      showToast(error.graphQLErrors[0]?.message || error.message, 'error');
    },
  });

  const validate = useCallback(() => {
    const { dateOfEvent, addressLin1, suburb, state, postcode, services } = formData;
    const requiredFields = { dateOfEvent, suburb, postcode, addressLin1 };

    return (
      !Object.values(requiredFields).some((val: string) => val === '') && !!services && services.length > 0 && !!state
    );
  }, [formData]);

  const onSubmit = () => {
    if (!validate()) {
      setFormIsDirty(true);
      return;
    }

    updateLocation({
      variables: {
        input: {
          serviceIds: formData.services.map((service: any) => service.value),
          dateOfEvent: moment(formData.dateOfEvent).utc().format(),
          streetLine1: formData.addressLin1,
          streetLine2: formData.addressLine2,
          suburb: formData.suburb,
          state: formData.state.value.replace('AU-', ''),
          postcode: formData.postcode,
        },
      },
    });
  };

  return (
    <>
      <NotifiableServiceEventsContainer
        title="Location of Records (Service closure only)"
        subTitle={undefined}
        notificationMessage="Within 14 days of event"
        onCancel={() => setFormData(emptyInputs)}
        onSave={onSubmit}
        isSaving={updateLocationLoading}
        formIsDirty={!formIsDirty}
        toggleDirty={setFormIsDirty}
        serviceOnChange={(option) =>
          setFormData({
            ...formData,
            services: option ?? [],
          })
        }
        saveDisabled={!validate()}
        isMultiSelect
        selectedService={formData.services}
        isLoading={false}
        child={
          <>
            <Row>
              <Col lg={6} className="mb-2">
                <DateInput
                  required
                  label="Date the change of address took place"
                  date={formData.dateOfEvent}
                  onDateSelect={(date: string) => setFormData({ ...formData, dateOfEvent: date })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="mb-2">
                <TextInput
                  required
                  label="Address 1"
                  name="address1"
                  value={formData.addressLin1}
                  onChange={(value: string) => setFormData({ ...formData, addressLin1: value })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="mb-2">
                <TextInput
                  required={false}
                  label="Address 2"
                  name="address2"
                  value={formData.addressLine2}
                  onChange={(value: string) => setFormData({ ...formData, addressLine2: value })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="mb-2">
                <TextInput
                  required
                  label="Suburb"
                  name="suburb"
                  value={formData.suburb}
                  onChange={(value: string) => setFormData({ ...formData, suburb: value })}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="mb-2">
                <Row align="end">
                  <Col md>
                    <Select
                      required
                      inputId="address-state-input"
                      label="State"
                      options={STATE_SELECT_OPTIONS['AU']}
                      value={STATE_SELECT_OPTIONS['AU'].find((option) => option.value === formData.state)}
                      onChange={(option) => setFormData({ ...formData, state: option })}
                    />
                  </Col>
                  <Col md>
                    <TextInput
                      required
                      label="Postcode"
                      name="postcode"
                      value={formData.postcode}
                      onChange={(value: string) => setFormData({ ...formData, postcode: value })}
                      disabled={false}
                      maxlength="4"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default LocationOfRecordsServiceClosure;
