import React from 'react';
import * as Mui from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { showToast } from 'shared/components/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { isRegion } from 'shared/util/region';
import Button from 'shared/components/Buttons';
import ManageLocksTableRow from './ManageLocksTableRow';
import { useGetCenterLocks } from 'gql/kisiIntegration/queries';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
  iconButton: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
  inputStyles: {
    minWidth: '200px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

interface ILocksProps {
  centerId: string;
  centerName: string;
  openManageLocksDialogue: boolean;
  setOpenManageLocksDialogue: (x: boolean) => void;
}

const ManageLocksModal: React.FC<ILocksProps> = ({
  centerId,
  centerName,
  openManageLocksDialogue,
  setOpenManageLocksDialogue,
  ...props
}) => {
  const [locksList, setLocksList] = React.useState<ILock[]>([]);

  const { data: GetCenterLocksData, loading } = useGetCenterLocks({
    variables: {
      id: centerId,
    },
    skip: centerId === '00000000-0000-0000-0000-000000000000',
    onCompleted: (result) => {
      setLocksList(result.getCenter.locks);
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  return (
    <>
      <Mui.Dialog
        open={openManageLocksDialogue}
        onClose={() => setOpenManageLocksDialogue(false)}
        maxWidth="md"
        fullWidth
      >
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography
            variant="dialogTitle"
            display="flex"
            fontWeight={700}
            fontSize="1.75rem"
            component="span"
            sx={{ alignItems: 'center' }}
          >
            Manage Locks: {centerName}
          </Mui.Typography>
          <Mui.IconButton sx={styles.closeIcon} disableRipple onClick={() => setOpenManageLocksDialogue(false)}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.TableContainer>
            <Mui.Table aria-labelledby="billing-transactions-table" style={{ width: '100%' }}>
              <Mui.TableHead sx={{ backgroundColor: 'background.default', border: 0 }}>
                <Mui.TableCell>
                  <Mui.Typography variant="tableHeadCells">Lock name</Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell align="right">
                  <Mui.Typography variant="tableHeadCells">Click to unlock</Mui.Typography>
                </Mui.TableCell>
              </Mui.TableHead>
              <Mui.TableBody>
                {/* Map of the locks in a center goes right below */}
                {locksList.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell align="left">There are currently no locks assigned this Center</Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  locksList.map((lock) => <ManageLocksTableRow lock={lock} />)
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button color="secondary" variant="contained" onClick={() => setOpenManageLocksDialogue(false)}>
            Done
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ManageLocksModal;
