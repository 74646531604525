import { addressFields } from 'gql/address/fields';

export const GET_ALLOWED_ENTITIES: string = `
  id
  name
  address {
    ${addressFields}
  }
  avatar {
    url
  }
  phoneNumber
`;
