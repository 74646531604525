import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { RootState } from 'store/reducers';
import AdjustmentsTable from './AdjustmentsTable';
import AdjustmentModal from './AdjustmentModal';
import { useGetAdjustmentsForBusiness } from 'gql/adjustment/queries';
import EditAdjustmentConfirmationModal from './EditAdjustmentConfirmationModal';
import ArchiveAdjustmentConfirmationModal from './ArchiveAdjustmentConfirmationModal';

interface IAdjustmentModalShape {
  isOpen: boolean;
  adjustment: IAdjustment | null;
}

export interface IAdjustmentTableFilters {
  term: string;
  statuses: ('ACTIVE' | 'ARCHIVED')[];
  types: AdjustmentType[];
}

interface IProps {
  adjustmentModalState: IAdjustmentModalShape;
  onEditAdjustment: (adjustment: IAdjustment) => void;
  dismissAdjustmentModal: () => void;
}

const AdjustmentsTab: React.FC<IProps> = ({
  adjustmentModalState,
  onEditAdjustment,
  dismissAdjustmentModal,
  ...props
}) => {
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const [tableData, setTableData] = useState<IAdjustment[]>([]);
  const [tableFilters, setTableFilters] = useState<IAdjustmentTableFilters>({ term: '', statuses: [], types: [] });
  const [showEditConfirmationModal, setShowEditConfirmationModal] = useState<IAdjustmentModalShape>({
    isOpen: false,
    adjustment: null,
  });
  const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState<IAdjustmentModalShape>({
    isOpen: false,
    adjustment: null,
  });

  const { loading: getAdjustmentsForBusinessesLoading } = useGetAdjustmentsForBusiness({
    variables: {
      input: {
        businessId: currentBusinessId ?? '',
        type: null,
        showArchived: true,
      },
    },
    skip: !currentBusinessId,
    onCompleted: (result) => {
      setTableData(result.getAdjustmentsForBusiness);
    },
  });

  const filterTableData = useCallback(
    (data: IAdjustment[]): IAdjustment[] => {
      let filteredData = data;

      if (tableFilters.term) {
        const lowercasedTerm = tableFilters.term.trim().toLowerCase();

        filteredData = filteredData.filter(
          (adjustment) =>
            adjustment.name.toLowerCase().includes(lowercasedTerm) ||
            adjustment.glCode.toLowerCase().includes(lowercasedTerm) ||
            adjustment.type.toLowerCase().includes(lowercasedTerm)
        );
      }

      if (tableFilters.statuses.length > 0) {
        filteredData = filteredData.filter(
          (adjustment) =>
            (tableFilters.statuses.includes('ACTIVE') && adjustment.archivedAt === null) ||
            (tableFilters.statuses.includes('ARCHIVED') && adjustment.archivedAt !== null)
        );
      }

      if (tableFilters.types.length > 0) {
        filteredData = filteredData.filter((adjustment) => tableFilters.types.includes(adjustment.type));
      }

      return orderBy(filteredData, (adjustment) => adjustment.name.toLowerCase(), 'asc');
    },
    [tableFilters]
  );

  return (
    <>
      <AdjustmentsTable
        isLoading={getAdjustmentsForBusinessesLoading}
        data={filterTableData(tableData)}
        tableFilters={tableFilters}
        onTableFilterUpdate={(updates) => setTableFilters((prev) => ({ ...prev, ...updates }))}
        onEdit={(adjustment) => setShowEditConfirmationModal({ isOpen: true, adjustment })}
        onArchive={(adjustment) => setShowArchiveConfirmationModal({ isOpen: true, adjustment })}
        noPadding={true}
      />
      {adjustmentModalState.isOpen && (
        <AdjustmentModal
          isOpen={adjustmentModalState.isOpen}
          businessId={currentBusinessId ?? ''}
          adjustment={adjustmentModalState.adjustment}
          onClose={dismissAdjustmentModal}
          onCreateAdjustment={(adjustment) => setTableData((prev) => [...prev, adjustment])}
          onEditAdjustment={(adjustment) =>
            setTableData((prev) => prev.map((a) => (a.id === adjustment.id ? { ...adjustment } : { ...a })))
          }
        />
      )}
      <EditAdjustmentConfirmationModal
        isOpen={showEditConfirmationModal.isOpen}
        onClose={() => setShowEditConfirmationModal({ isOpen: false, adjustment: null })}
        onConfirm={() => {
          onEditAdjustment(showEditConfirmationModal.adjustment as IAdjustment);
          setShowEditConfirmationModal({ isOpen: false, adjustment: null });
        }}
      />
      {showArchiveConfirmationModal.adjustment && (
        <ArchiveAdjustmentConfirmationModal
          isOpen={showArchiveConfirmationModal.isOpen}
          adjustment={showArchiveConfirmationModal.adjustment}
          onClose={() => setShowArchiveConfirmationModal({ isOpen: false, adjustment: null })}
          onConfirm={(adjustment) =>
            setTableData((prev) => prev.map((a) => (a.id === adjustment.id ? { ...adjustment } : { ...a })))
          }
        />
      )}
    </>
  );
};

export default AdjustmentsTab;
