import React, { useState, useCallback, useEffect } from 'react';
import Card from 'shared/components/Card';
import { dataType as DT, IDataType } from 'shared/constants/enums/dataTypeEnum';
import Select from 'shared/components/Select';
import FormWrapper2 from 'shared/components/Form/FormWrapper2';
import { useUpdateEntityDataTypeInformation } from './graphql/mutations';
import { showToast } from 'shared/components/Toast';
import { ToastType } from 'react-toastify';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  entityId: string;
  dataType: DataType;
  loading: boolean;
}

const DataTypeCard: React.FC<IProps> = ({ entityId, dataType, loading = true, ...props }) => {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [selectedDataType, setSelectedDataType] = useState<DataType>(dataType);
  const dataTypeKeys: DataType[] = Object.keys(DT) as DataType[];
  const [saveBusinessDataType] = useUpdateEntityDataTypeInformation();

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const handleSaveDataType = useCallback(() => {
    saveBusinessDataType({
      variables: {
        input: {
          id: entityId,
          dataType: selectedDataType,
        },
      },
    })
      .then(() => {
        showToast('Business data type was updated successfully', ToastType.SUCCESS);
        setFormIsDirty(false);
      })
      .catch(() => {
        showToast('There was an error updating the data type of this business', ToastType.ERROR);
      });
  }, [saveBusinessDataType, entityId, selectedDataType]);

  // Because undefined means production - there was some conflicting behavior of making sure the
  // initial value of the select dropdown was correct upon first visit to the profile. Do not remove
  // below unless you've tested that works.
  useEffect(() => {
    if (dataType && dataType !== selectedDataType && !formIsDirty) {
      setSelectedDataType(dataType);
    }
  }, [dataType, selectedDataType, formIsDirty]);

  return (
    <Card header="Business Data Type" loading={false} loadingLines={4}>
      <p>
        This field is used to separate production data in this environment from any other data types. Examples: Test,
        Sandbox, etc.
      </p>
      <p>
        By marking a business as anything other than Production, you are also determining that anything owned by it is
        also not production data. Examples: {fieldLabels.center}s, Classes, etc.
      </p>
      <p>Non-production data will not be included in any reporting.</p>
      <FormWrapper2
        formIsDirty={formIsDirty}
        toggleDirty={setFormIsDirty}
        onSave={handleSaveDataType}
        onCancel={() => setSelectedDataType(dataType)}
        loading={loading}
      >
        <Select
          required={true}
          name="dataType"
          label="DataType"
          options={dataTypeKeys.map((item) => DT[item])}
          value={selectedDataType}
          getOptionLabel={(dt: IDataType) => dt.label}
          getOptionValue={(dt) => dt.value}
          onChange={(type: IDataType) => {
            setSelectedDataType(type.value);
            setFormIsDirty(true);
          }}
        />
      </FormWrapper2>
    </Card>
  );
};

export default DataTypeCard;
