import React, { useLayoutEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Checkbox from 'shared/components/Checkbox';
import { Row } from 'shared/components/Layout';
import { currencyFormat } from 'shared/util/currency';
import './_cpavDetailedFee.scss';

interface Props {
  sessionType: CpavSessionType;
  ageGroup: ICpavAgeGroup;
  handleChange: (sessionType: CpavSessionType, ageGroup: ICpavAgeGroup) => void;
}

const CpavFeeInclusion: React.FC<Props> = ({ sessionType, ageGroup, handleChange }) => {
  const { t } = useTranslation(['ccss']);
  const [selectedItems, setSelectedItems] = useState<boolean[]>([]);

  enum CpavInclusions {
    AFTTEA = 1 << 0,
    BRKFST = 1 << 1,
    EDUPRO = 1 << 2,
    EXCINC = 1 << 3,
    LUNCH = 1 << 4,
    MORTEA = 1 << 5,
    NAPPIE = 1 << 6,
    OTHMEA = 1 << 7,
    TRANSP = 1 << 8,
  }

  const enumToBitValues = (enumValue: object) => {
    return Object.keys(enumValue).map(Number).filter(Boolean);
  };

  const formValueToBit = (enumeration: object, selection: boolean[]) => {
    const bits = enumToBitValues(enumeration);
    return selection.reduce((total, selected, i) => total + (selected ? bits[i] : 0), 0);
  };

  const bitToFormValue = (enumeration: object, bit: number) => {
    const bits = enumToBitValues(enumeration);
    return bits.map((b) => (bit & b) === b);
  };

  const handleItemSelectedChange = (index: number) => {
    const newValues = [...selectedItems];
    newValues[index] = !newValues[index];
    setSelectedItems([...newValues]);
    const bitValue = formValueToBit(CpavInclusions, [...newValues]);
    handleChange(sessionType, { ...ageGroup, inclusions: bitValue });
  };

  useLayoutEffect(() => {
    setSelectedItems(bitToFormValue(CpavInclusions, ageGroup.inclusions));
  }, [ageGroup]);

  return (
    <Row align="start" className="mb-4">
      <Table className="cpav-service-fee-details">
        <thead>
          <tr>
            <th>Session type</th>
            <th>Fee</th>
            <th>Included in fee</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="cpav-service-fee-details__td-session-type">
              <div className="cpav-service-fee-details__session-type">{t(`ccss:cpav.session-type.${sessionType}`)}</div>
            </td>
            <td className="cpav-service-fee-details__td-fee">{currencyFormat(ageGroup.usualFeeAmount)}</td>
            <td>
              <div className="cpav-service-fee-details__inclusions">
                <Checkbox
                  label={t(`ccss:cpav.inclusions.AFTTEA`)}
                  value={selectedItems[0]}
                  onChange={() => handleItemSelectedChange(0)}
                />
                <Checkbox
                  label={t(`ccss:cpav.inclusions.BRKFST`)}
                  value={selectedItems[1]}
                  onChange={() => handleItemSelectedChange(1)}
                />
                <Checkbox
                  label={t(`ccss:cpav.inclusions.EDUPRO`)}
                  value={selectedItems[2]}
                  onChange={() => handleItemSelectedChange(2)}
                />
                <Checkbox
                  label={t(`ccss:cpav.inclusions.EXCINC`)}
                  value={selectedItems[3]}
                  onChange={() => handleItemSelectedChange(3)}
                />
                <Checkbox
                  label={t(`ccss:cpav.inclusions.LUNCH`)}
                  value={selectedItems[4]}
                  onChange={() => handleItemSelectedChange(4)}
                />
                <Checkbox
                  label={t(`ccss:cpav.inclusions.MORTEA`)}
                  value={selectedItems[5]}
                  onChange={() => handleItemSelectedChange(5)}
                />
                <Checkbox
                  label={t(`ccss:cpav.inclusions.NAPPIE`)}
                  value={selectedItems[6]}
                  onChange={() => handleItemSelectedChange(6)}
                />
                <Checkbox
                  label={t(`ccss:cpav.inclusions.OTHMEA`)}
                  value={selectedItems[7]}
                  onChange={() => handleItemSelectedChange(7)}
                />
                <Checkbox
                  label={t(`ccss:cpav.inclusions.TRANSP`)}
                  value={selectedItems[8]}
                  onChange={() => handleItemSelectedChange(8)}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </Row>
  );
};

export default CpavFeeInclusion;
