import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { setCurrentBusiness, setCurrentCenter } from 'store/context/actions';
import Select from 'shared/components/Select';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import useGetAllowedEntities from 'shared/hooks/useGetAllowedEntities';
import { sortBy } from 'lodash';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  centerId?: string;
  businessId?: string;
  updateCenterId?: (id: string) => void;
  updateBusinessId?: (id: string) => void;
  className?: string;
  businessSelectClassName?: string;
  centerSelectClassName?: string;
  required?: boolean;
  persistSelection?: boolean;
  showLabels?: boolean;
}

const BusinessAndCenterSelects: React.FC<IProps> = ({
  centerId,
  businessId,
  updateCenterId,
  updateBusinessId,
  className,
  businessSelectClassName,
  centerSelectClassName,
  required,
  persistSelection,
  showLabels = true,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const currentBusinessId = useSelector((state: RootState) => state.context.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context.centerId);

  const { data: businessesData, loading: businessesLoading } = useGetAllowedEntities();
  const { data: centers, loading: centersLoading } = useGetActiveCentersWithLoading();
  const businesses = sortBy(businessesData?.getAllowedEntities || [], 'name');
  const filteredCenters =
    centers?.filter(
      (c) => (!businessId && !currentBusinessId) || c.entityId === businessId || c.entityId === currentBusinessId
    ) || [];

  const handleUpdateCenterId = useCallback(
    (id) => {
      if (persistSelection) {
        dispatch(setCurrentCenter(id));
        localStorage.setItem(`${user?.email}-last-selected-centerId`, id);
      }
      if (updateCenterId) {
        updateCenterId(id);
      }
    },
    [user, dispatch]
  );

  const handleUpdateBusinessId = useCallback(
    (id) => {
      if (persistSelection) {
        dispatch(setCurrentBusiness(id));
        localStorage.setItem(`${user?.email}-last-selected-businessId`, id);
      }
      if (updateBusinessId) {
        updateBusinessId(id);
      }
    },
    [user, dispatch]
  );

  // when data has loaded, auto select business and center ids from cache or from first in list
  useEffect(() => {
    if (businessesData && centers) {
      const cachedBusinessId = localStorage.getItem(`${user?.email}-last-selected-businessId`) || '';
      const cachedCenterId = localStorage.getItem(`${user?.email}-last-selected-centerId`) || '';
      const activeCachedBusiness = businesses.find((b) => b.id === cachedBusinessId);
      const activeCachedCenter = centers.find((c) => c.id === cachedCenterId);
      const firstBusiness = businesses[0];
      const defaultBusinessId = user?.entityId || activeCachedBusiness?.id || firstBusiness?.id || '';
      const firstCenterAtDefaultBusiness = centers.filter((c) => c.entityId === defaultBusinessId)[0];
      const defaultCenterId = activeCachedCenter?.id || firstCenterAtDefaultBusiness?.id || '';

      !businessId && handleUpdateBusinessId(defaultBusinessId);
      !centerId && handleUpdateCenterId(defaultCenterId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessesData, centers]);

  if (businessesLoading || centersLoading || (!user?.isInternal && centers?.length === 1)) {
    return <></>;
  }

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  return (
    <div className={className}>
      {user?.isInternal && (
        <Select
          className={businessSelectClassName}
          label={showLabels ? 'Business' : undefined}
          options={businesses.map((b) => ({ value: b.id, label: b.name }))}
          value={businessId ?? currentBusinessId}
          onChange={(option) => {
            handleUpdateBusinessId(option.value);
            const firstCenterForSelectedBusiness = centers?.filter((c) => c.entityId === option.value)[0];
            handleUpdateCenterId(firstCenterForSelectedBusiness?.id ?? '');
          }}
          required={required}
        />
      )}
      <Select
        className={centerSelectClassName}
        label={showLabels ? fieldLabels.center : undefined}
        options={filteredCenters.map((c) => ({ value: c.id, label: c.name }))}
        value={centerId ?? currentCenterId}
        onChange={(option) => {
          handleUpdateCenterId(option.value);
        }}
        noOptionsMessage={() => `No Active ${fieldLabels.center}s Found.`}
        required={required}
      />
    </div>
  );
};

export default BusinessAndCenterSelects;
