import React, { useCallback, useEffect, useState } from 'react';
import TextInput, { MaskedDateTextInput } from 'shared/components/TextInput';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import moment from 'moment';
import Card from 'shared/components/Card';
import { useGetMedicareCardByChildId } from 'gql/medicareCard/queries';
import { useDeleteMedicareCard, useUpsertMedicareCard } from 'gql/medicareCard/mutations';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import showToast from 'shared/components/Toast/showToast';
import { ButtonAsLink } from 'shared/components/Buttons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'shared/components/Avatar';
import { stringToHueDegree } from 'shared/util/string';
import AccountLink from 'shared/components/AccountLink/AccountLink';
import Button from 'shared/components/Buttons';

const positionValues = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const NUMBER_ONLY = /^-?[0-9]*(\.[0-9]*)?$/;
const initialValues = {
  medicareCardNumber: '',
  position: '',
  expiry: '',
};

interface CardProps {
  data?: IMedicareCard;
  loading: boolean;
  update: (card: IMedicareCard) => void;
  create: (card: IMedicareCard) => void;
  delete: (card: IMedicareCard) => void;

  accountId: string;
  accountChildId: string;
  accountName: string;
  centerName: string;
}

const MedicareCardContent = (props: CardProps) => {
  const { t } = useTranslation();
  const businessId = useSelector((state: RootState) => state.user?.entityId) ?? '';
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [canEdit, setCanEdit] = useState(false);
  const handleCannotEdit = () => setCanEdit(false);
  const handleCanEdit = () => setCanEdit(true);

  useEffect(() => {
    setFormData({
      medicareCardNumber: props.data?.medicareNumber ?? '',
      position: props.data?.position ?? '',
      expiry: props.data?.expiry ?? '',
    });
  }, [props.data]);

  const [upsertMedicareCardFn, { loading: upsertMedicareCardLoading }] = useUpsertMedicareCard({
    onCompleted: (result) => {
      showToast('Medicare Card saved successfully.', 'success');

      if (props.data) {
        props.update(result.upsertMedicareCard);
      } else {
        props.create(result.upsertMedicareCard);
      }

      setFormIsDirty(false);
      handleCannotEdit();
    },
  });

  const [deleteHealthProfessionalFn, { loading: deleteHealthProfessionalLoading }] = useDeleteMedicareCard({
    onCompleted: (result) => {
      showToast('Medicare Card removed successfully.', 'success');
      props.delete(result.deleteMedicareCard);
      setFormIsDirty(false);
      handleCannotEdit();
    },
  });

  const handleDelete = useCallback(() => {
    if (!props.data) return;
    deleteHealthProfessionalFn({
      variables: {
        id: props.data.id,
      },
    });
  }, [deleteHealthProfessionalFn, props.data, businessId, formData]);

  const isCardNumberValid = formData.medicareCardNumber.length === 10;

  const isExpirationDateValid =
    moment(formData.expiry, 'MMYYYY').isValid() &&
    moment(formData.expiry, 'MMYYYY').endOf('month').isSameOrAfter(moment().endOf('month'));

  const resetMedicareCardValues = () => {
    handleCannotEdit();
    setFormData({
      medicareCardNumber: props.data?.medicareNumber ?? '',
      position: props.data?.position ?? '',
      expiry: props.data?.expiry ?? '',
    });
    setFormIsDirty(false);
  };

  const handleSaveMedicareCard = useCallback(
    (event) => {
      event.preventDefault();
      if (!isExpirationDateValid) {
        return;
      }

      upsertMedicareCardFn({
        variables: {
          input: {
            accountChildId: props.accountChildId,
            businessId: businessId,
            medicareCardNumber: formData.medicareCardNumber,
            position: formData.position,
            expiry: formData.expiry,
            ...(props.data ? { id: props.data.id } : {}),
          },
        },
      });
    },
    [
      businessId,
      props.accountChildId,
      formData.expiry,
      formData.medicareCardNumber,
      formData.position,
      isExpirationDateValid,
      upsertMedicareCardFn,
    ]
  );

  return (
    <div className={'list-by-account medicare'}>
      <Row noGutters justify="between" className="mb-6 pb-2 account-divider">
        <div className="flex-grow-1">
          <div className="d-flex">
            <Avatar
              color={`hsl(${stringToHueDegree(props.accountName)}, ${
                stringToHueDegree(props.accountName) < 50 ? '100%' : '40%'
              }, 40%`}
              size={'sm'}
              initials={props.accountName.charAt(0).toUpperCase()}
              image={''}
              className="mr-3"
            />
            <div>
              <p className="small-font mb-0">Account name:</p>
              <p className="mb-0 account-link">
                <AccountLink accountId={props.accountId} accountName={props.accountName} openInNewTab />
              </p>
              <p className="small-font mb-0">
                <i>{props.centerName}</i>
              </p>
            </div>
          </div>
        </div>
        <div>
          {!props.data && !canEdit && (
            <ButtonAsLink className="sm mr-auto" onClick={handleCanEdit}>
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Medicare Number
            </ButtonAsLink>
          )}
          {props.data && !canEdit && (
            <ButtonAsLink className="sm mr-auto" onClick={handleCanEdit}>
              <FontAwesomeIcon icon={faPen} className="mr-2" />
              Edit
            </ButtonAsLink>
          )}
        </div>
      </Row>
      {props.data || canEdit ? (
        <form
          id={`Medicare_${props.data?.id ?? ''}`}
          onSubmit={handleSaveMedicareCard}
          className={`d-flex flex-column`}
        >
          <Row align="start">
            <Col>
              <TextInput
                required
                disabled={!canEdit}
                label="Medicare Number"
                value={formData.medicareCardNumber}
                placeholder={'Medicare Number'}
                onChange={(value) => {
                  if ((!Number.isNaN(value) && NUMBER_ONLY.test(value)) || value === '') {
                    setFormData({ ...formData, medicareCardNumber: value });
                    setFormIsDirty(true);
                  }
                }}
                isInvalid={!isCardNumberValid}
                errorText={'Medicare Number must be 10 digits long.'}
                maxlength="10"
              />
            </Col>
            <Col sm={3}>
              <Select
                required
                disabled={!canEdit}
                te
                label="Position"
                options={positionValues}
                value={formData.position}
                onChange={(value: number) => {
                  setFormData({ ...formData, position: value.toString() });
                  setFormIsDirty(true);
                }}
              />
            </Col>
            <Col sm={3}>
              <MaskedDateTextInput
                id={`Medicare_expiration_${props.accountId}_${props.accountChildId}`}
                name={'expiration'}
                label="Expiry"
                disabled={!canEdit}
                value={formData.expiry}
                numberFormat={{ isNumericString: true }}
                onChange={(value) => {
                  setFormData({ ...formData, expiry: value });
                  setFormIsDirty(true);
                }}
                required
                isInvalid={!isExpirationDateValid}
                errorText={'Date is invalid or has already passed.'}
              />
            </Col>
          </Row>
          <Row noGutters justify={'between'} className="mt-4">
            {props.data && (
              <Button
                id={`Medicare_${props.data?.id ?? ''}-info-btn`}
                className={'mr-4'}
                loading={props.loading || upsertMedicareCardLoading || deleteHealthProfessionalLoading}
                variant={'outline-danger'}
                onClick={handleDelete}
                hidden={!canEdit}
              >
                Remove
              </Button>
            )}
            <div>
              <Button
                id={`Medicare_${props.data?.id ?? ''}-secondary-btn`}
                className={'mr-4'}
                variant="light"
                loading={props.loading || upsertMedicareCardLoading || deleteHealthProfessionalLoading}
                onClick={resetMedicareCardValues}
                hidden={!canEdit}
              >
                Cancel
              </Button>
              <Button
                id={`Medicare_${props.data?.id ?? ''}-primary-btn`}
                disabled={!formIsDirty}
                loading={props.loading || upsertMedicareCardLoading || deleteHealthProfessionalLoading}
                type="submit"
                hidden={!canEdit}
              >
                Save
              </Button>
            </div>
          </Row>
        </form>
      ) : (
        <div className={'no-data'}>
          <strong>A Medicare Number has not been added for this child on the account.</strong>
          <br />
          Click the 'Add Medicare Number' button above to add details to this child profile.
        </div>
      )}
    </div>
  );
};

type Props = {
  childId: string;
  childAccounts: {
    accountChildId: string;
    accountId: string;
    accountName: string;
    centerId: string;
    centerName: string;
  }[];
};

const MedicareCardTab = (props: Props) => {
  const { data, loading } = useGetMedicareCardByChildId(props.childId);
  const [cards, setCards] = useState<IMedicareCard[]>(data ? data.getMedicareCardByChildId : []);

  useEffect(() => {
    setCards(data ? data.getMedicareCardByChildId : []);
  }, [data]);

  const update = (medicareCard: IMedicareCard) => {
    setCards((cards ?? []).map((a) => (a.id === medicareCard.id ? { ...medicareCard } : a)));
  };

  const create = (medicareCard: IMedicareCard) => {
    setCards([...(cards ?? []), medicareCard]);
  };

  const deleteMedicareCard = (medicareCard: IMedicareCard) => {
    setCards(cards.filter((a) => a.id !== medicareCard.id));
  };

  return (
    <Card bodyClassName={'medicare-cards-list'} header={'Medicare Number'} loading={loading} loadingLines={2}>
      {props.childAccounts.map((accountDetails, index) => {
        return (
          <MedicareCardContent
            key={`MedicareCard_ChildAccount_${accountDetails.centerId}_${accountDetails.accountId}_${accountDetails.accountChildId}_${index}`}
            data={cards.find((c) => c.accountChildId === accountDetails.accountChildId)}
            loading={loading}
            update={update}
            create={create}
            delete={deleteMedicareCard}
            accountChildId={accountDetails.accountChildId}
            accountId={accountDetails.accountId}
            accountName={accountDetails.accountName}
            centerName={accountDetails.centerName}
          />
        );
      })}
      {props.childAccounts.length % 2 !== 0 && <div className={'medicare-empty'}></div>}
    </Card>
  );
};

export { MedicareCardTab };
