import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideModalDrawer from 'shared/components/ModalDrawer';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Row, Col } from 'shared/components/Layout';
import { isBlank, isValidPhoneNumber } from 'shared/util/string';
import { showToast } from 'shared/components/Toast';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import Alert from 'shared/components/Alert';
import User from 'shared/types/user';
import { isEmailValid } from 'shared/util/email';
import {
  useCreateStaff,
  useAddStaffToCenter,
  ICreateNewStaffInput,
} from 'pages/Employees/subroutes/Profiles/graphql/mutations';
import NewEmployeeForm, { INewEmployeeForm } from './NewEmployeeForm';
import ExistingEmployeeForm, { IExistingEmployeeForm } from './ExistingEmployeeForm';
import { completeStep } from 'store/onBoarding/actions';
import { ONBOARDING_STEPS } from 'shared/constants/Onboarding';
import { RootState } from 'store/reducers';
import { useGetRolesForBusiness } from 'shared/hooks/useGetRolesForBusiness';
import { increaseTotalCreatedStaff, increaseTotalInvitedStaff } from '../../duck/actions';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  currentUser: User;
  navigateToStaffProfile: (staff: IStaff) => void;
}

const DEFUALT_NEW_EMPLOYEE: INewEmployeeForm = {
  firstName: '',
  lastName: '',
  email: '',
  startDate: null,
  roleId: '',
  // title: '',
  positionId: null,
  primaryCenterId: null,
  entityId: null,
  sendInvite: false,
  payRate: undefined,
  payRateType: 'Hourly',
};

const DEFUALT_EXISTING_EMPLOYEE: IExistingEmployeeForm = {
  email: '',
  personId: null,
  centerId: null,
  positionId: '',
};

const NewEmployeeModalForm: React.FC<IProps> = ({ isOpen, onClose, currentUser, navigateToStaffProfile, ...props }) => {
  const dispatch = useDispatch();
  const [activeTabKey, setActiveTabKey] = useState<'newStaff' | 'existingStaff'>('newStaff');
  const [createStaffMutation, { loading: createStaffLoading, error: createStaffError }] = useCreateStaff();
  const [addStaffToCenter, { loading: addStaffToCenterLoading }] = useAddStaffToCenter();
  const currentBussinessId = useSelector((state: RootState) => state.context?.businessId);
  const currentCenterId = useSelector((state: RootState) => state.context?.centerId);
  const [newEmployeeFormData, setNewEmployeeFormData] = useState<INewEmployeeForm>({
    ...DEFUALT_NEW_EMPLOYEE,
    primaryCenterId: currentCenterId,
    entityId: currentBussinessId,
  });
  const [existingEmployeeFormData, setExistingEmployeeFormData] = useState<IExistingEmployeeForm>({
    ...DEFUALT_EXISTING_EMPLOYEE,
  });
  const { data } = useGetRolesForBusiness(newEmployeeFormData.entityId ?? '');
  const selectedRole = data?.getRolesForBusiness?.find((role) => role.id === newEmployeeFormData.roleId);

  const isValidPrimaryCenter = (): boolean => {
    if (currentUser.isInternal) {
      return Boolean(newEmployeeFormData.entityId);
    }

    if (selectedRole?.scopeType === 'ENTITY') {
      return true;
    }

    return Boolean(newEmployeeFormData.primaryCenterId);
  };

  const validateFormData = useCallback((): boolean => {
    if (activeTabKey === 'newStaff') {
      return (
        !isBlank(newEmployeeFormData.firstName) &&
        !isBlank(newEmployeeFormData.lastName) &&
        Boolean(newEmployeeFormData.positionId) &&
        isEmailValid(newEmployeeFormData.email) &&
        Boolean(newEmployeeFormData.roleId) &&
        isValidPrimaryCenter() &&
        (!newEmployeeFormData.phoneNumber || isValidPhoneNumber(newEmployeeFormData.phoneNumber))
      );
    } else if (activeTabKey === 'existingStaff') {
      return (
        !isBlank(existingEmployeeFormData.email) &&
        !isBlank(existingEmployeeFormData.positionId) &&
        Boolean(existingEmployeeFormData.centerId)
      );
    }

    return false;
  }, [activeTabKey, newEmployeeFormData, existingEmployeeFormData, selectedRole]);

  /**
   * Used within <ExistingEmployeeForm /> if an email is entered and no existing account is associated with it
   * Will navigate back to the "new staff" form and prefill the email input
   */
  const startNewStaffFormWithEmail = useCallback((email: string) => {
    setActiveTabKey('newStaff');
    setExistingEmployeeFormData({ ...DEFUALT_EXISTING_EMPLOYEE });
    setNewEmployeeFormData({
      ...DEFUALT_NEW_EMPLOYEE,
      primaryCenterId: currentCenterId,
      entityId: currentBussinessId,
      email,
    });
  }, []);

  const resetForm = useCallback(() => {
    setActiveTabKey('newStaff');
    setNewEmployeeFormData({ ...DEFUALT_NEW_EMPLOYEE, primaryCenterId: currentCenterId, entityId: currentBussinessId });
    setExistingEmployeeFormData({ ...DEFUALT_EXISTING_EMPLOYEE });
  }, []);

  const dismissForm = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  const submitNewEmployeeForm = useCallback(
    async (dismissFormAfterSubmit: boolean) => {
      if (!newEmployeeFormData.roleId || !newEmployeeFormData.entityId || !selectedRole) {
        return;
      }

      const createStaffInput: ICreateNewStaffInput = {
        staff: {
          firstname: newEmployeeFormData.firstName,
          lastname: newEmployeeFormData.lastName,
          email: newEmployeeFormData.email,
          phoneNumber: newEmployeeFormData.phoneNumber,
          positionId: newEmployeeFormData.positionId ?? '',
          roleship: {
            roleId: newEmployeeFormData.roleId,
            scopeType: selectedRole?.scopeType,
            scopeIds:
              selectedRole?.scopeType === 'ENTITY'
                ? [newEmployeeFormData.entityId]
                : [newEmployeeFormData.primaryCenterId ?? currentCenterId!], // seriously!@_@ scopedIds can't contain an empty string!!! look somewhere else if you want to solve the nullability of the currentCenterId
          },
          entityId: newEmployeeFormData.entityId,
          employmentStartDate: newEmployeeFormData.startDate,
          primaryCenterId: newEmployeeFormData.primaryCenterId,
          ...(newEmployeeFormData.payRate !== undefined &&
            newEmployeeFormData.payRate !== 0 && { payRateType: newEmployeeFormData.payRateType }),
          // replace any commas so parseFloat evaluates the entire string
          ...(newEmployeeFormData.payRate !== undefined &&
            newEmployeeFormData.payRate !== 0 && { payRate: newEmployeeFormData.payRate }),
        },
        sendInvitation: newEmployeeFormData.sendInvite,
      };

      await createStaffMutation({
        variables: {
          input: createStaffInput,
        },
      }).then((result) => {
        showToast('Added new employee successfully.', 'success');
        if (!createStaffInput.sendInvitation) {
          dispatch(increaseTotalCreatedStaff());
        } else {
          dispatch(increaseTotalInvitedStaff());
        }

        if (dismissFormAfterSubmit) {
          dismissForm();
          const staff = result.data?.createStaff;
          if (staff?.invitedAt) dispatch(completeStep(ONBOARDING_STEPS.inviteEmployee));
          staff && navigateToStaffProfile(staff);
        } else {
          resetForm();
        }
      });
    },
    [newEmployeeFormData, createStaffMutation, dismissForm, resetForm, navigateToStaffProfile, selectedRole, dispatch]
  );

  const submitExistingEmployeeForm = useCallback(async () => {
    if (existingEmployeeFormData.personId && existingEmployeeFormData.centerId) {
      await addStaffToCenter({
        variables: {
          personId: existingEmployeeFormData.personId,
          centerId: existingEmployeeFormData.centerId,
          positionId: existingEmployeeFormData.positionId,
        },
      }).then(() => {
        dismissForm();
        showToast('Added employee to center successfully.', 'success');
      });
    }
  }, [addStaffToCenter, existingEmployeeFormData, dismissForm]);

  const disableActionButtons = useCallback(() => {
    return !validateFormData() || createStaffLoading || addStaffToCenterLoading;
  }, [validateFormData, createStaffLoading, addStaffToCenterLoading]);

  useEffect(() => {
    setNewEmployeeFormData((prev) => ({ ...prev, primaryCenterId: currentCenterId, entityId: currentBussinessId }));
  }, [currentCenterId, currentBussinessId]);

  return (
    <SideModalDrawer
      title="Add Employee"
      show={isOpen}
      onHide={dismissForm}
      closeOnPrimaryCallback={false}
      primaryChoice="Save"
      primaryCallback={() => (activeTabKey === 'newStaff' ? submitNewEmployeeForm(true) : submitExistingEmployeeForm())}
      primaryButtonProps={{
        disabled: disableActionButtons(),
      }}
      closeOnSecondaryCallback={false}
      secondaryChoice={activeTabKey === 'newStaff' ? 'Save, Add Another' : 'Cancel'}
      secondaryCallback={() => (activeTabKey === 'newStaff' ? submitNewEmployeeForm(false) : dismissForm())}
      secondaryButtonProps={{
        disabled: activeTabKey === 'newStaff' && disableActionButtons(),
      }}
    >
      <Tabs activeKey={activeTabKey} id="new-staff-form-tabs" onSelect={(key: any) => setActiveTabKey(key)}>
        <Tab eventKey="newStaff" title="New">
          {createStaffError && (
            <Row className="mb-4">
              <Col>
                <Alert header="Error" variant="danger">
                  {createStaffError.message}
                </Alert>
              </Col>
            </Row>
          )}
          {createStaffLoading ? (
            <LoadingLines />
          ) : (
            <NewEmployeeForm
              currentUser={currentUser}
              formData={newEmployeeFormData}
              onChange={setNewEmployeeFormData}
            />
          )}
        </Tab>
        <Tab eventKey="existingStaff" title="Existing">
          {addStaffToCenterLoading ? (
            <LoadingLines />
          ) : (
            <ExistingEmployeeForm
              currentUser={currentUser}
              formData={existingEmployeeFormData}
              onChange={setExistingEmployeeFormData}
              startNewStaffFormWithEmail={startNewStaffFormWithEmail}
            />
          )}
        </Tab>
      </Tabs>
    </SideModalDrawer>
  );
};

export default NewEmployeeModalForm;
