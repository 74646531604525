import { gql } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { MutationHookOptions } from '@apollo/client';
import {
  IDeleteLinkCodeData,
  IDeleteLinkCodeVariables,
  IGenerateLinkCodeData,
  ILinkGenerateLinkCodeVariables,
  IUpdateLinkCodeVariables,
} from './queries';
import { InputVariable } from 'gql/program/mutation';

export const GENERATE_LINK_CODE = gql`
  mutation ($input: LinkGenerateLinkCodeInput!) {
    generateLinkCode(input: $input) {
      businessId
      linkCode
      name
      associatedCenterIds
    }
  }
`;

export const useGenerateLinkCode = (
  options?: MutationHookOptions<IGenerateLinkCodeData, InputVariable<ILinkGenerateLinkCodeVariables>> | undefined
) => useMutation<IGenerateLinkCodeData, InputVariable<ILinkGenerateLinkCodeVariables>>(GENERATE_LINK_CODE, options);

export const UPDATE_LINK_CODE = gql`
  mutation ($input: UpdateLinkCodeInput!) {
    updateLinkCode(input: $input) {
      businessId
      linkCode
      name
      associatedCenterIds
    }
  }
`;
export const useUpdateLinkCode = (
  options?: MutationHookOptions<IGenerateLinkCodeData, InputVariable<IUpdateLinkCodeVariables>> | undefined
) => useMutation<IGenerateLinkCodeData, InputVariable<IUpdateLinkCodeVariables>>(UPDATE_LINK_CODE, options);

export const DELETE_LINK_CODE = gql`
  mutation ($input: LinkDeleteLinkCodeInput!) {
    deleteLinkCode(input: $input)
  }
`;

export const useDeleteLinkCode = (
  options?: MutationHookOptions<IDeleteLinkCodeData, InputVariable<IDeleteLinkCodeVariables>> | undefined
) => useMutation<IDeleteLinkCodeData, InputVariable<IDeleteLinkCodeVariables>>(DELETE_LINK_CODE, options);
