import React from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import IconButtonCircle from 'shared/components/Buttons/IconButtonCircle';
import ButtonAsLink from 'shared/components/Buttons/ButtonAsLink';
import { ButtonProps } from 'react-bootstrap/Button';
import { Row } from 'shared/components/Layout';

interface IProps extends ButtonProps {
  onClick: () => void;
  variant?: 'light' | 'dark' | 'primary';
  label?: string;
  className?: string;
}

const CircleDeleteButton: React.FC<IProps> = ({ className, label, variant = 'dark', ...props }) => {
  return (
    <Row noGutters className={className || ''}>
      <IconButtonCircle
        {...props}
        icon={faTimes}
        color={variant === 'light' ? 'dark-gray' : 'white'}
        backgroundColor={variant === 'dark' ? 'secondary' : variant === 'light' ? 'pale-grey' : variant}
      />
      {label && (
        <ButtonAsLink {...props} className="ml-2">
          {label}
        </ButtonAsLink>
      )}
    </Row>
  );
};

export default CircleDeleteButton;
