import React, { useContext, useCallback, useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment, { Moment } from 'moment';
import Card from 'shared/components/Card';
import { ButtonAsLink } from 'shared/components/Buttons';
import Table from 'react-bootstrap/Table';
import ActionDropdown from 'shared/components/ActionDropdown';
import Alert from 'react-bootstrap/Alert';
import { TimezoneContext } from 'shared/contexts/timezoneContext';
import { calculateDifferenceBetweenTimes } from 'shared/util/timeUtils';
import { Line } from 'shared/components/LoadingSkeletons';
import SingleDayTimeCardTableHeader from './SingleDayTimeCardTableHeader';
import { useTranslation } from 'react-i18next';
import {
  createHourDecimalTimeString,
  getActualTotalTimeString,
  getAdjustedTotalTimeString,
  getActualScheduledAndOvertimeMinutesForTimeEntry,
  getActualMinutesWorked,
  getAdjustedMinutesWorked,
} from './timeCardUtils';
import CreateUpdateTimeEntryTableRow from './CreateUpdateTimeEntryTableRow';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { TrainingHolidayEvent } from '../TimeLogs/components/TrainingTimeEventModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetLockedUntilForCenter } from 'gql/timeLogLock/queries';
import _ from 'lodash';
import DeleteTimeEntryModal from './DeleteTimeEntryModal';
import { useGetStaffProfileInformation } from '../../../../gql/staff/queries';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from '../../../../shared/constants/dropdownOptions/countryInfo';

interface ICreateAdjustTimeEntryRow {
  show: boolean;
  timeEntry: ITimeEntry | null;
  isOpenTimeEntryNeedingToBeClosed: boolean; // control whether we need to close a previous open time entry before adding a new one
}

interface IDeleteAdjustTimeEntryRow {
  show: boolean;
  timeEntry: ITimeEntry | null;
}

interface IProps {
  date: string;
  personId: string;
  centerId: string;
  dailyAttendance: IDailyAttendance | null;
  isLoading: boolean;
  positionOptions: IStaffPosition[];
  onViewTimeEntryDetail: (timeEntry: ITimeEntry) => void;
  onSuccessfulTimeEntryAdjustment: (timeEntry: ITimeEntry, clockedOutTimeEntry: ITimeEntry | null) => void;
  onDeleteTrainingTimeEvent: (trainingTimeEvent: ITrainingHolidayEvent) => void;
  isMyTimeCard?: boolean;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const DayTimeCard: React.FC<IProps> = ({
  date,
  personId,
  centerId,
  dailyAttendance,
  isLoading,
  positionOptions,
  onViewTimeEntryDetail,
  onSuccessfulTimeEntryAdjustment,
  onDeleteTrainingTimeEvent,
  isMyTimeCard,
  ...props
}) => {
  const { t } = useTranslation(['timelog']);
  const { k2TimeLogLock } = useFlags();
  const { loading, error, data, refetch } = useGetStaffProfileInformation(personId);
  const centerTimezoneContext = useContext(TimezoneContext);
  const timezone =
    useSelector((state: RootState) => state?.timezone?.byCenterId[centerId]) ?? (moment.tz.guess() as Timezone);
  const businessId = useSelector((state: RootState) => state.context?.businessId) ?? '';
  const businessFeatures = useSelector((state: RootState) => state.context.businessFeature);
  const AdpFeature = Object.values(businessFeatures).find((x) => x.type === 'AdpWorkforce')?.subFeatures ?? undefined;
  const AdpTimeEnabled = AdpFeature?.find((x) => x.type === 'TimeAndAttendance')?.enabled ?? false;
  const [deleteTimeEntryModalOpen, setDeleteTimeEntryModalOpen] = useState<IDeleteAdjustTimeEntryRow>({
    show: false,
    timeEntry: null,
  });
  const [adjustEntryRow, setAdjustRow] = useState<ICreateAdjustTimeEntryRow>({
    show: false,
    timeEntry: null,
    isOpenTimeEntryNeedingToBeClosed: false,
  });

  const hasEditTimelogPermission = useHasRoleAreaLevel({
    area: AreaType.Schedule,
    permission: PermissionType.TimeLog,
    level: RoleLevelType.Edit,
  });
  const hasDeleteTimelogPermission = useHasRoleAreaLevel({
    area: AreaType.Schedule,
    permission: PermissionType.TimeLog,
    level: RoleLevelType.Delete,
  });

  const getTimeDifferenceString = useCallback((t1: string, t2: string): string => {
    const minuteDifference = calculateDifferenceBetweenTimes(t1, t2, 'minutes');

    return createHourDecimalTimeString(minuteDifference);
  }, []);

  const handleSuccessfulTimeEntryAdjustment = useCallback(
    (timeEntry: ITimeEntry, clockedOutTimeEntry: ITimeEntry | null) => {
      setAdjustRow({ show: false, timeEntry: null, isOpenTimeEntryNeedingToBeClosed: false });
      onSuccessfulTimeEntryAdjustment(timeEntry, clockedOutTimeEntry);
    },
    [onSuccessfulTimeEntryAdjustment]
  );

  const getDropdownOptionsForTimeEntry = useCallback(
    (timeEntry: ITimeEntry): IDropdownAction[] => {
      const actions: { label: string; onClick: () => void }[] = [];

      if (hasEditTimelogPermission && !isMyTimeCard && timeEntry.archivedAt === null) {
        actions.push({
          label: 'Edit',
          onClick: () => setAdjustRow({ show: true, timeEntry, isOpenTimeEntryNeedingToBeClosed: false }),
        });
      }

      if (hasDeleteTimelogPermission && !isMyTimeCard && timeEntry.archivedAt === null) {
        actions.push({ label: 'Delete', onClick: () => setDeleteTimeEntryModalOpen({ show: true, timeEntry }) });
      }

      if (timeEntry.adjustedAt !== null) {
        actions.push({ label: 'Adjustment Details', onClick: () => onViewTimeEntryDetail(timeEntry) });
      }

      return actions;
    },
    [hasEditTimelogPermission, onViewTimeEntryDetail]
  );

  const findOpenTimeEntryForDay = useCallback((timeEntriesForDay: ITimeEntry[]): ITimeEntry | null => {
    return timeEntriesForDay.find((te) => !te.trackedTimeOut) ?? null;
  }, []);

  const handleAddTimeEntryClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (dailyAttendance) {
        const openTimeEntry = findOpenTimeEntryForDay(dailyAttendance.timeEntries);

        setAdjustRow({
          show: true,
          timeEntry: openTimeEntry,
          isOpenTimeEntryNeedingToBeClosed: openTimeEntry !== null,
        });
        return;
      }

      setAdjustRow({ show: true, timeEntry: null, isOpenTimeEntryNeedingToBeClosed: false });
    },
    [dailyAttendance, findOpenTimeEntryForDay]
  );

  const getColumnTotalsString = useCallback(
    (columnType: 'actual' | 'adjusted', timeType: 'scheduled' | 'overtime'): string => {
      if (!dailyAttendance) return '0.00';

      const timeEntryTotals = dailyAttendance.timeEntries
        .map((te) => getActualScheduledAndOvertimeMinutesForTimeEntry(te, dailyAttendance, columnType))
        .filter((m) => m[timeType] !== null)
        .reduce<number>(
          (acc, curr) => (acc += Number.parseFloat(createHourDecimalTimeString(curr[timeType] as number))),
          0
        ); // type cast since we've already filtered out the null values

      const trainingTimeTotals =
        columnType === 'adjusted' && timeType === 'scheduled'
          ? dailyAttendance.trainingTimeEvents.reduce<number>((acc, curr) => (acc += curr.hours), 0)
          : 0; // training time should not display under the actual table

      return (timeEntryTotals + trainingTimeTotals).toFixed(2);
    },
    [dailyAttendance]
  );

  const [lockedUntil, setLockedUntil] = useState<Moment | null>(null);
  const { data: lockedUntilData } = useGetLockedUntilForCenter({
    variables: {
      input: {
        businessId: businessId ?? '',
        centerId,
      },
    },
    skip: !businessId,
  });

  useEffect(() => {
    // @ts-ignore
    const lockedUntilDate = lockedUntilData?.getLockedUntilForCenter?.data?.lockedUntil ?? '';
    if (lockedUntilDate) {
      setLockedUntil(moment(lockedUntilDate));
    }
  }, [lockedUntilData]);

  return (
    <Card
      className="kt-employee-time-card-single-day"
      bodyClassName="p-4"
      header={
        <div className="d-flex align-items-center">
          <div>{moment(date).tz(timezone).format('ddd, MMM Do')}</div>
          {moment(date).isSameOrBefore(moment(), 'date') && (
            <div className="ml-auto">
              {k2TimeLogLock && moment(date).isSameOrBefore(lockedUntil) ? (
                <span>{t('timelog:time-card.time-log-lock')}</span>
              ) : (
                <HasRoleAreaLevel
                  action={{ area: AreaType.Schedule, permission: PermissionType.TimeLog, level: RoleLevelType.Create }}
                >
                  {isMyTimeCard ? (
                    <></>
                  ) : AdpTimeEnabled ? (
                    <>
                      <small>MANUAL TIME MANAGEMENT CAN BE ADDED IN ADP </small>
                    </>
                  ) : (
                    <ButtonAsLink disabled={adjustEntryRow.show} onClick={handleAddTimeEntryClick}>
                      {t('timelog:time-card.add-time-entry-btn')}
                    </ButtonAsLink>
                  )}
                </HasRoleAreaLevel>
              )}
            </div>
          )}
        </div>
      }
    >
      {dailyAttendance && (dailyAttendance?.shifts ?? []).length > 0 && (
        <Alert variant="info">
          <b>{t('timelog:time-card.scheduled-shifts')}:</b>
          <span className="ml-4">
            {dailyAttendance.shifts
              .map(
                (s) =>
                  `${
                    s.position?.positionName
                      ? s.position.positionName.replace(/Center|Centre/g, fieldLabels.center)
                      : ''
                  } (${moment(s.startTime).tz(centerTimezoneContext.timezone).format('h:mm A')} - ${moment(s.endTime)
                    .tz(centerTimezoneContext.timezone)
                    .format('h:mm A')})`
              )
              .join(', ')}
          </span>
        </Alert>
      )}
      <Table responsive className="kt-employee-time-card-table kt-employee-time-card-detailed-table">
        <SingleDayTimeCardTableHeader />
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={12}>
                <Line />
              </td>
            </tr>
          ) : dailyAttendance ? (
            <>
              {dailyAttendance?.timeEntries.map((timeEntry: ITimeEntry, idx: number) => {
                const actualMinutesForTimeEntry = getActualScheduledAndOvertimeMinutesForTimeEntry(
                  timeEntry,
                  dailyAttendance,
                  'actual'
                );
                const adjustedMinutesForTimeEntry = getActualScheduledAndOvertimeMinutesForTimeEntry(
                  timeEntry,
                  dailyAttendance,
                  'adjusted'
                );
                const clockedSelfInAndOut = timeEntry.clockedSelfIn && timeEntry.clockedSelfOut;

                return (
                  <tr id={`time-entry-row-${timeEntry.id}`} key={`time-entry-row-${timeEntry.id}-${idx}`}>
                    <td id={`position-cell-${timeEntry.id}`}>
                      {timeEntry.staffPosition.positionName.replace(/Center|Centre/g, fieldLabels.center)}
                    </td>
                    <td id={`actual-time-in-cell-${timeEntry.id}`}>
                      {timeEntry.clockedSelfIn
                        ? moment(timeEntry.timeIn).tz(centerTimezoneContext.timezone).format('h:mm A')
                        : '--:--'}
                    </td>
                    <td id={`actual-time-out-cell-${timeEntry.id}`}>
                      {timeEntry.clockedSelfOut && timeEntry.timeOut
                        ? moment(timeEntry.timeOut).tz(centerTimezoneContext.timezone).format('h:mm A')
                        : '--:--'}
                    </td>
                    <td id={`actual-difference-cell-${timeEntry.id}`}>
                      {clockedSelfInAndOut && timeEntry.timeOut
                        ? getTimeDifferenceString(timeEntry.timeIn, timeEntry.timeOut)
                        : '-'}
                    </td>
                    <td id={`actual-scheduled-cell-${timeEntry.id}`}>
                      {clockedSelfInAndOut && actualMinutesForTimeEntry.scheduled
                        ? createHourDecimalTimeString(actualMinutesForTimeEntry.scheduled)
                        : '-'}
                    </td>
                    <td id={`actual-overtime-cell-${timeEntry.id}`}>
                      {clockedSelfInAndOut && actualMinutesForTimeEntry.overtime
                        ? createHourDecimalTimeString(actualMinutesForTimeEntry.overtime)
                        : '-'}
                    </td>
                    <td id={`adjusted-time-in-cell-${timeEntry.id}`}>
                      {timeEntry.adjustedAt && _.isEmpty(timeEntry.archivedAt)
                        ? moment(timeEntry.trackedTimeIn).tz(centerTimezoneContext.timezone).format('h:mm A')
                        : '--:--'}
                    </td>
                    <td id={`adjusted-time-out-cell-${timeEntry.id}`}>
                      {timeEntry.adjustedAt && timeEntry.trackedTimeOut && _.isEmpty(timeEntry.archivedAt)
                        ? moment(timeEntry.trackedTimeOut).tz(centerTimezoneContext.timezone).format('h:mm A')
                        : '--:--'}
                    </td>
                    <td id={`adjusted-difference-cell-${timeEntry.id}`}>
                      {timeEntry.adjustedAt && timeEntry.trackedTimeOut && _.isEmpty(timeEntry.archivedAt)
                        ? getTimeDifferenceString(timeEntry.trackedTimeIn, timeEntry.trackedTimeOut)
                        : _.isEmpty(timeEntry.archivedAt)
                        ? '-'
                        : '0.00'}
                    </td>
                    <td id={`adjusted-scheduled-cell-${timeEntry.id}`}>
                      {timeEntry.adjustedAt && adjustedMinutesForTimeEntry.scheduled
                        ? createHourDecimalTimeString(adjustedMinutesForTimeEntry.scheduled)
                        : '-'}
                    </td>
                    <td id={`adjusted-overtime-cell-${timeEntry.id}`}>
                      {timeEntry.adjustedAt && adjustedMinutesForTimeEntry.overtime
                        ? createHourDecimalTimeString(adjustedMinutesForTimeEntry.overtime)
                        : '-'}
                    </td>
                    <td id={`actions-cell-${timeEntry.id}`}>
                      {(k2TimeLogLock && moment(date).isSameOrBefore(lockedUntil)) || AdpTimeEnabled ? null : (
                        <ActionDropdown actions={getDropdownOptionsForTimeEntry(timeEntry)} />
                      )}
                    </td>
                  </tr>
                );
              })}
              {dailyAttendance.trainingTimeEvents?.map((tte: ITrainingHolidayEvent, idx: number) => {
                const event = new TrainingHolidayEvent(tte);

                return (
                  <tr id={`training-time-event-row-${tte.id}`} key={`training-time-event-row-${tte.id}-${idx}`}>
                    <td id={`position-cell-${tte.id}`}>{event.typeToString()}</td>
                    <td id={`actual-time-in-cell-${tte.id}`}>--:--</td>
                    <td id={`actual-time-out-cell-${tte.id}`}>--:--</td>
                    <td id={`actual-difference-cell-${tte.id}`}>-</td>
                    <td id={`actual-scheduled-cell-${tte.id}`}>-</td>
                    <td id={`actual-overtime-cell-${tte.id}`}>-</td>
                    <td id={`adjusted-time-in-cell-${tte.id}`}>--:--</td>
                    <td id={`adjusted-time-out-cell-${tte.id}`}>--:--</td>
                    <td id={`adjusted-difference-cell-${tte.id}`}>{event.hours.toFixed(2)}</td>
                    <td id={`adjusted-scheduled-cell-${tte.id}`}>{event.hours.toFixed(2)}</td>
                    <td id={`adjusted-overtime-cell-${tte.id}`}>-</td>
                    <td id={`actions-cell-${tte.id}`}>
                      {hasDeleteTimelogPermission && !(k2TimeLogLock && moment(date).isSameOrBefore(lockedUntil)) ? (
                        <ActionDropdown
                          actions={[{ label: 'Delete', onClick: () => onDeleteTrainingTimeEvent(tte) }]}
                        />
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <tr>
              <td colSpan={12}>{t('timelog:time-card.no-data-card')}</td>
            </tr>
          )}
          {adjustEntryRow.show && (
            <tr className="kt-time-card-adjust-time-entry-row">
              <td colSpan={12}>
                <CreateUpdateTimeEntryTableRow
                  date={date}
                  personId={personId}
                  centerId={centerId}
                  timeEntry={adjustEntryRow.timeEntry}
                  positionOptions={positionOptions}
                  showClosePreviousOpenShiftPrompt={adjustEntryRow.isOpenTimeEntryNeedingToBeClosed}
                  onSuccessfulTimeEntryAdjustment={handleSuccessfulTimeEntryAdjustment}
                  onCancel={() =>
                    setAdjustRow({ show: false, timeEntry: null, isOpenTimeEntryNeedingToBeClosed: false })
                  }
                />
              </td>
            </tr>
          )}
          {deleteTimeEntryModalOpen && (
            <>
              <DeleteTimeEntryModal
                isOpen={deleteTimeEntryModalOpen.show}
                onClose={() => setDeleteTimeEntryModalOpen({ show: false, timeEntry: null })}
                staff={data?.getStaff}
                timeEntry={deleteTimeEntryModalOpen.timeEntry}
              />
            </>
          )}
        </tbody>
        {dailyAttendance !== null && !isLoading && (
          <tfoot>
            <tr>
              <td>{t('timelog:time-card.sub-totals-option')}</td>
              <td colSpan={2} />
              <td>{getActualTotalTimeString(dailyAttendance.timeEntries)}</td>
              <td>{getColumnTotalsString('actual', 'scheduled')}</td>
              <td>{getColumnTotalsString('actual', 'overtime')}</td>
              <td colSpan={2} />
              <td>{getAdjustedTotalTimeString(dailyAttendance.timeEntries, dailyAttendance.trainingTimeEvents)}</td>
              <td>{getColumnTotalsString('adjusted', 'scheduled')}</td>
              <td>{getColumnTotalsString('adjusted', 'overtime')}</td>
              <td />
            </tr>
          </tfoot>
        )}
      </Table>
      {dailyAttendance !== null && !isLoading && (
        <div className="timeTotalsSection">
          <strong>{t('timelog:time-card.total-hours-option')}</strong>
          <strong>
            {(
              getActualMinutesWorked(dailyAttendance.timeEntries) +
              getAdjustedMinutesWorked(dailyAttendance.timeEntries, dailyAttendance.trainingTimeEvents)
            ).toFixed(2)}
          </strong>
        </div>
      )}
    </Card>
  );
};

export default DayTimeCard;
