export const base64ToPDF = (blob: string) => {
  return `data:application/pdf;base64,${blob}`;
};
/**
 *
 * @param linkSource - formatted data application with it's file format
 * @param fileName - output filename ex. file.pdf
 */
export const downloadFile = (linkSource: string, fileName: string) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.target = '_self';
  downloadLink.download = fileName;
  downloadLink.click();
};

/**
 *
 * @param base64 - base64 encoded string
 * @param fileName - output filename ex. file.pdf
 */
export const downloadPDF = (base64: string, fileName: string) => {
  const linkSource = base64ToPDF(base64);
  downloadFile(linkSource, fileName);
};
