import React, { useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactDates, { DayPickerRangeController, DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faArrowRight, faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { DefaultCountry } from './countryCodes';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

// https://github.com/airbnb/react-dates/issues/1343

interface IProps {
  id?: string;
  label?: string;
  autoFocus?: boolean;
  startDate?: moment.Moment | null;
  endDate?: moment.Moment | null;
  disabled?: boolean;
  disableNavigation?: boolean;
  displayFormat?: string;
  className?: string;
  rangeComponent?: JSX.Element;
  required?: boolean;
  isOutsideRange?: (day: any) => boolean;
  /**
   * use if you want to ensure your dates are never null
   */
  onChange: (dates: { startDate: moment.Moment; endDate: moment.Moment }) => void;
  /**
   * use if you want to allow a null date to be bubbled up
   * most likely will be used with reactDatesController='RANGE' and rangeType='CUSTOM'
   */
  onChangeWithNulls?: (dates: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => void;
  /**
   * which underlying react-dates component to render
   */
  reactDatesController: 'RANGE' | 'SINGLE';
  /**
   * control whether to allow only one week to be selected at a time or a custom range
   */
  rangeType?: 'WEEK' | 'CUSTOM';
}

const dateSettings = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;

// IProps & Omit<SingleDatePickerShape, keyof IProps> <-- gives an error :(
const WeekPicker: React.FC<IProps> = ({
  id = 'week-date-picker',
  autoFocus = false,
  startDate = moment().startOf(dateSettings.week),
  endDate = moment().endOf(dateSettings.week),
  disabled = false,
  disableNavigation = false,
  displayFormat = DefaultCountry.dateFormat,
  onChange,
  label,
  reactDatesController = 'RANGE',
  rangeType = 'WEEK',
  className,
  rangeComponent = <FontAwesomeIcon icon={faArrowRight} size="sm" className="mx-4" color="gray" />,
  required = false,
  onChangeWithNulls,
  isOutsideRange,
  ...props
}) => {
  const [focusedInput, setFocusedInput] = useState<ReactDates.FocusedInputShape>(autoFocus ? 'endDate' : 'startDate');
  const [showWeekPicker, setShowWeekPicker] = useState<boolean>(false);

  const onDateSelect = useCallback(
    (dates: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
      if (dates.startDate && dates.endDate) {
        onChange({
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      }
    },
    [onChange]
  );

  const onWeekChange = useCallback(
    (offset: number) => {
      const timeUnit = reactDatesController === 'SINGLE' ? 'days' : 'weeks';

      onDateSelect({
        startDate: startDate?.clone().add(offset, timeUnit) ?? null,
        endDate: endDate?.clone().add(offset, timeUnit) ?? null,
      });
    },
    [startDate, endDate, onDateSelect, reactDatesController]
  );

  const checkIsOutsideRange = useCallback(
    (offset: number) => {
      const timeUnit = reactDatesController === 'SINGLE' ? 'days' : 'weeks';
      return (
        isOutsideRange &&
        isOutsideRange(startDate?.clone().add(offset, timeUnit)) &&
        isOutsideRange(endDate?.clone().add(offset, timeUnit))
      );
    },
    [startDate, endDate, reactDatesController]
  );

  const renderRangeCalendar = useCallback(
    (type: 'WEEK' | 'CUSTOM') => {
      if (type === 'CUSTOM') {
        return (
          <DayPickerRangeController
            enableOutsideDays
            hideKeyboardShortcutsPanel
            startDate={startDate}
            endDate={endDate}
            onDatesChange={(dates) => {
              onChangeWithNulls &&
                onChangeWithNulls({
                  startDate: dates.startDate,
                  endDate: focusedInput === 'startDate' ? null : dates.endDate,
                });
            }}
            focusedInput={focusedInput}
            onFocusChange={(input) => setFocusedInput((prev) => (!input ? 'startDate' : input))}
            onOutsideClick={() => setShowWeekPicker(false)}
            numberOfMonths={2}
            isOutsideRange={isOutsideRange}
            firstDayOfWeek={DefaultCountry.firstDayOfWeek}
          />
        );
      }

      return (
        <DayPickerRangeController
          enableOutsideDays
          hideKeyboardShortcutsPanel
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDateSelect}
          focusedInput={focusedInput}
          onFocusChange={(input) => setFocusedInput((prev) => (!input ? 'startDate' : prev))}
          onOutsideClick={() => setShowWeekPicker(false)}
          numberOfMonths={1}
          startDateOffset={(day: moment.Moment) => day.startOf(dateSettings.week)}
          endDateOffset={(day: moment.Moment) => day.endOf(dateSettings.week)}
          isOutsideRange={isOutsideRange}
          firstDayOfWeek={DefaultCountry.firstDayOfWeek}
        />
      );
    },
    [focusedInput, endDate, onChangeWithNulls, onDateSelect, startDate]
  );

  const focusWeekPicker = useCallback(() => {
    if (!disabled) {
      setShowWeekPicker(true);
    }
  }, [disabled]);

  return (
    <Form.Group className={`kt-week-input ${className}`}>
      {label && !required && <Form.Label>{label}</Form.Label>}
      {label && required && (
        <div className="d-flex flex-row">
          <Form.Label className="text-nowrap">{label}</Form.Label>
          <FontAwesomeIcon className="ml-2 xxs" icon={faAsterisk} color="#FF2C2C" />
        </div>
      )}
      <InputGroup className="flex-nowrap" style={{ width: 'unset' }}>
        {!disableNavigation && (
          <InputGroup.Prepend>
            <Button variant="light" onClick={() => onWeekChange(-1)} disabled={checkIsOutsideRange(-1) || disabled}>
              <FontAwesomeIcon icon={faChevronLeft} size="sm" color="gray" />
            </Button>
          </InputGroup.Prepend>
        )}
        <div
          style={{ width: 'unset' }}
          className="form-control cursor-pointer d-flex align-items-center flex-grow-0"
          role="button"
          tabIndex={-1}
          onFocus={focusWeekPicker}
        >
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" className="mr-3" />
          {startDate?.format(displayFormat) ?? '?'}
          {reactDatesController === 'RANGE' && (
            <>
              {rangeComponent}
              {endDate?.format(displayFormat) ?? '?'}
            </>
          )}
        </div>
        {!disableNavigation && (
          <InputGroup.Append>
            <Button variant="light" onClick={() => onWeekChange(1)} disabled={checkIsOutsideRange(1) || disabled}>
              <FontAwesomeIcon icon={faChevronRight} size="sm" color="gray" />
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>
      {showWeekPicker && (
        <div className="position-absolute" style={{ zIndex: 3 }}>
          {reactDatesController === 'RANGE' ? (
            renderRangeCalendar(rangeType)
          ) : (
            <DayPickerSingleDateController
              hideKeyboardShortcutsPanel
              enableOutsideDays
              focused
              numberOfMonths={1}
              date={startDate}
              onDateChange={(date) => onDateSelect({ startDate: date, endDate: date })}
              onFocusChange={() => {}}
              onOutsideClick={() => setShowWeekPicker(false)}
              isOutsideRange={isOutsideRange}
            />
          )}
        </div>
      )}
    </Form.Group>
  );
};

export default WeekPicker;
