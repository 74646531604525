import { MessageEnvelope, MessagePayload } from '@pubnub/react-chat-components';
import { ActivityType, ChannelType, FlagReason, useCreateChatActivityMutation } from 'generated/graphql';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { RootState } from 'store/reducers';
import RadioButton from '../../RadioButton';
import TextInput from '../../TextInput';
import PubNub from 'pubnub';
import { showToast } from 'shared/components/Toast';
import { Channel } from 'pages/Engagement/subroutes/Messaging/Tabs/AccountMessagingTab/AccountMessagingTab';

interface IProps {
  pubnub: PubNub;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (reason: FlagReason, otherInfo?: string | undefined) => void;
  message: MessageEnvelope | undefined;
  currentChannel: string | undefined;
  currentChannelInfo: Channel | undefined;
}

const FlagMessageModal: React.FC<IProps> = ({
  pubnub,
  isOpen,
  onClose,
  handleSubmit,
  message,
  currentChannel,
  currentChannelInfo,
}) => {
  const { t } = useTranslation(['comms']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const selectedCenterId = useSelector((state: RootState) => state.context.centerId) ?? '';
  const timezone = useSelector((state: RootState) => state.timezone.byCenterId[selectedCenterId]) ?? moment.tz.guess();

  const [otherInputValue, setOtherInputValue] = useState('');
  const [flagReason, setFlagReason] = useState<FlagReason>(FlagReason.Abusive);
  const messagePayload = message ? (message.message as MessagePayload) : undefined;

  const [createChatActivity, { data, loading: createActivityLoading, error: createChatActivityError }] =
    useCreateChatActivityMutation({
      onError: (err) => showToast('Unable to send flag message', 'error'),
      onCompleted: (response) => {},
    });

  const handleFlagMessage = async () => {
    const payload = message?.message as MessagePayload;
    pubnub
      .addMessageAction({
        channel: message?.channel ?? currentChannel ?? '',
        messageTimetoken: message?.timetoken.toString() ?? '',
        action: {
          type: 'flagged',
          value: `${flagReason}`,
        },
      })
      .then((result) => {
        createChatActivity({
          variables: {
            businessId: businessId ?? '',
            input: {
              businessId: businessId ?? '',
              channelType: currentChannelInfo?.channelType === 'Account' ? ChannelType.Account : ChannelType.Center,
              channelId: currentChannelInfo?.channelId,
              personId: payload?.sender?.id ?? user?.id,
              activityType: ActivityType.MessageFlagged,
              activityTime: parseInt(message?.timetoken.toString() ?? ''),
              pubNubMessageId: payload.id,
              messageContent: payload.text,
              channelWasRead: true,
              flagReason: flagReason,
              additionalInformation: otherInputValue,
            },
          },
        });
        showToast(t('comms:flag-message.success-toast-message', { message: payload.text }), 'success');
      })
      .catch((error) => {
        showToast(t('comms:flag-message.error-toast-message'), 'error');
      });
  };

  return (
    <SideModalDrawer
      title={'Flag Message'}
      show={isOpen}
      onHide={onClose}
      primaryChoice={'Flag Message'}
      primaryCallback={() => {
        handleFlagMessage();
        handleSubmit(flagReason, otherInputValue);
      }}
      secondaryCallback={onClose}
      primaryButtonProps={{ loading: false, disabled: flagReason === FlagReason.Other && otherInputValue === '' }}
    >
      <Row>
        <Col>
          <p>
            You are flagging a message from {messagePayload?.sender?.name} posted on{' '}
            {moment(messagePayload?.createdAt).tz(timezone).format('MMM D[,] YYYY [-] h:mmA').toString()}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput label="Message" value={messagePayload?.text} disabled={true} />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioButton
            className="radio-margin-0"
            label="Abusive content"
            value={flagReason === FlagReason.Abusive}
            onChange={() => {
              setFlagReason(FlagReason.Abusive);
            }}
          />
          <RadioButton
            className="radio-margin-0"
            label="Inappropriate content"
            value={flagReason === FlagReason.Inappropriate}
            onChange={() => {
              setFlagReason(FlagReason.Inappropriate);
            }}
          />
          <RadioButton
            className="radio-margin-0"
            label="Other"
            value={flagReason === FlagReason.Other}
            onChange={() => {
              setFlagReason(FlagReason.Other);
            }}
          />
        </Col>
      </Row>
      {flagReason === FlagReason.Other && (
        <Row>
          <Col>
            <TextInput
              label="Reason:"
              value={otherInputValue}
              onChange={(value) => {
                setOtherInputValue(value);
              }}
              required={flagReason === FlagReason.Other}
            />
          </Col>
        </Row>
      )}
    </SideModalDrawer>
  );
};

export default FlagMessageModal;
