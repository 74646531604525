import React from 'react';
import { Card } from 'react-bootstrap';
import Currency from 'shared/components/Currency';
import { Row, Col } from 'shared/components/Layout';

interface IProps {
  balanceType: string;
  balance: number;
  date: string;
}

const BillingBalanceCard: React.FC<IProps> = ({ balanceType, balance, date }) => {
  return (
    <div className="mb2">
      <Card className="raised-hover h-100  p-4 cursor-pointer card ml-3">
        <Row justify="between" align="start" noGutters className="h-100">
          <Col align="start" justify="between" className="mr-4 mb-2 align-items-start">
            <h5 className="sans-serif text-info">{balanceType} Balance</h5>
            <div className="sm mb-2 payment-card-subtitle">
              Balance on <b>{date}</b>
            </div>
            <div>
              <div className="payment-card-amount font-weight-light mb-0 text-info text-break">
                <Currency themed={false} amount={balance} />
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default BillingBalanceCard;
