import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import Tooltip from 'shared/components/Tooltip';
import { getFullName, getInitials, stringToHsl } from 'shared/util/string';
import AvatarContent from 'shared/components/Avatar/AvatarContent';
import { ISessionUpdateShape } from './EditSessionModal';
import HasRoleAreaLevel from 'shared/components/HasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { IconButton } from 'shared/components/Buttons';

interface IProps {
  session: ISessionUpdateShape;
  timezone: Timezone;
  onDeleteTimeEntry: (timeEntry: IAttendanceTimeEntry) => void;
}

const ViewSessionGroup: React.FC<IProps> = ({ session, timezone, onDeleteTimeEntry, ...props }) => {
  const hasCheckIns = session.timeEntries.length > 0;
  const hasMultipleCheckIns = session.timeEntries.length > 1;
  const hasCheckOuts = hasCheckIns && session.timeEntries.some((te) => te.timeOut !== null);

  return (
    <div className="d-flex flex-row flex-grow-1">
      <table className="w-100">
        <thead>
          <tr>
            <th className="font-size-12 font-weight-normal">Class</th>
            <th className="font-size-12 font-weight-normal">Fee</th>
            <th className="font-size-12 font-weight-normal">Drop Off</th>
            <th className="font-size-12 font-weight-normal">Pick Up</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-dark py-1">{session.class?.name ?? ''}</td>
            <td className="text-dark py-1">{session.fee?.name ?? ''}</td>
            <td className="text-dark py-1">
              {session.dropOffTime ? moment(session.dropOffTime, 'HH:mm').format('h:mm A') : '-'}
            </td>
            <td className="text-dark py-1">
              {session.pickUpTime ? moment(session.pickUpTime, 'HH:mm').format('h:mm A') : '-'}
            </td>
            <td className="py-1" />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th />
            <th />
            <th className="font-size-12 font-weight-normal">{hasCheckIns ? 'Check In' : ''}</th>
            <th className="font-size-12 font-weight-normal">{hasCheckOuts ? 'Check Out' : ''}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {session.timeEntries.map((timeEntry: IAttendanceTimeEntry, idx: number) => (
            <tr key={`time-entry-${timeEntry.id}-${idx}`}>
              <td className="py-1" />
              <td className="py-1" />
              <td
                className={classnames('py-1', {
                  'border-bottom': hasMultipleCheckIns && idx !== session.timeEntries.length - 1,
                })}
              >
                <div className="d-flex flex-row align-items-center">
                  <span className="mr-2 w-80 text-dark">{moment(timeEntry.timeIn).tz(timezone).format('h:mm A')}</span>
                  <Tooltip text={getFullName(timeEntry.timeInBy)} direction="bottom">
                    <div className="avatar d-inline-block float-none">
                      <AvatarContent
                        size="xs"
                        image={timeEntry.timeInBy.avatar?.url ?? ''}
                        initials={getInitials(timeEntry.timeInBy)}
                        alt={`${getFullName(timeEntry.timeInBy)} avatar`}
                        color={stringToHsl(timeEntry.timeInBy.id)}
                      />
                    </div>
                  </Tooltip>
                </div>
              </td>
              <td
                className={classnames('py-1', {
                  'border-bottom': hasMultipleCheckIns && idx !== session.timeEntries.length - 1,
                })}
              >
                <div className="d-flex flex-row align-items-center">
                  <span className="mr-2 w-80 text-dark">
                    {timeEntry.timeOut ? moment(timeEntry.timeOut).tz(timezone).format('h:mm A') : '\u00A0'}
                  </span>
                  {timeEntry.timeOut && (
                    <Tooltip text={getFullName(timeEntry.timeOutBy)} direction="bottom">
                      <div className="avatar d-inline-block float-none">
                        <AvatarContent
                          size="xs"
                          image={timeEntry.timeOutBy?.avatar?.url ?? ''}
                          initials={getInitials(timeEntry.timeOutBy)}
                          alt={`${getFullName(timeEntry.timeOutBy)} avatar`}
                          color={stringToHsl(timeEntry.timeOutBy?.id ?? '1')}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </td>
              <td
                className={classnames('py-1', {
                  'border-bottom': hasMultipleCheckIns && idx !== session.timeEntries.length - 1,
                })}
              >
                <HasRoleAreaLevel
                  action={{ area: AreaType.Attendance, permission: PermissionType.Base, level: RoleLevelType.Delete }}
                >
                  <IconButton
                    icon={faTrashAlt}
                    iconSize="lg"
                    tooltipText="Delete Time Entry"
                    tooltipDirection="bottom"
                    onClick={() => onDeleteTimeEntry(timeEntry)}
                  />
                </HasRoleAreaLevel>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewSessionGroup;
