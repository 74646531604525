import { useCreateEditTransitionStatementMutation, useGetTransitionStatementsQuery } from 'generated/graphql';
import { useCallback, useContext, useReducer, useState } from 'react';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { getDefaultTransitionStatementYear, getTransitionStatementYears } from '../utils';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { useMediaQuery, useTheme } from '@mui/material';
import useDatatableState from 'shared/hooks/useDatatableState2';
import Select from 'shared/components/Select';
import { NumberInput } from 'shared/components/TextInput';
import TransitionStatementsCard from './TransitionStatementsCard';
import Card from 'shared/components/Card';
import { compact, orderBy } from 'lodash';
import Button, { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import PeriodPicker from '../PeriodPicker';
import { showToast } from 'shared/components/Toast';
import { PeriodContext } from '..';
import YearPicker from '../YearPicker';

interface IProps {}

interface ITransitionStatements {
  id: number;
  childId: string;
  childName: string;
  centerId: string;
  centerName: string;
  catholicCount: number | null;
  independentCount: number | null;
  stateCount: number | null;
}

// This is a terrible hack, read on...
let data: ITransitionStatements[] = [];
let originalData: ITransitionStatements[] = [];

export const TransitionStatementsTab: React.FC<IProps> = ({ ...props }) => {
  // ...If we kept track of data/originalData via the State Hook we end up re-rendering the
  // DataTable component every time the user changes a value. This in and of itself is how
  // it's meant to work however we aren't tracking enough state between re-renders for it to
  // be a smooth experience for the user. Things like which input control has focus is lost.
  // The DataTable component has its own methods for editing cells but (i) we haven't used it
  // elsewhere and (ii) it requires users to select each cell before they edit it rather than
  // tabbing between inputs efficiently.
  //
  // There is one case where we want React to re-render the table, however: when we first get
  // the data. This seems to be an accepted way of emulating forceUpdate from class components.
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [centerId, setCenterId] = useState<string | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [tableState, tableFunctions] = useDatatableState();
  const { year } = useContext(PeriodContext);

  const { data: centers } = useGetActiveCentersWithLoading();

  const { loading, refetch } = useGetTransitionStatementsQuery({
    variables: {
      input: {
        centerId,
        year,
      },
    },
    onCompleted: (d) => {
      const transitionStatements = compact(d.getTransitionStatements);

      data = transitionStatements.map((ts, idx) => ({
        id: idx,
        childId: ts.childId,
        childName: ts.childName ?? '',
        centerId: ts.centerId,
        centerName: ts.centerName ?? '',
        catholicCount: ts.catholicCount ?? null,
        independentCount: ts.independentCount ?? null,
        stateCount: ts.stateCount ?? null,
      }));

      originalData = Array.from(data);

      forceUpdate();
    },
  });

  const [createEditTransitionStatementMutation, { loading: saving }] = useCreateEditTransitionStatementMutation();

  const [pageNumber, pageSize] = [tableState.activePage, tableState.pageSize];
  const iteratees = ['centerName', 'childName'];

  const rowData = orderBy(data, iteratees)
    .filter((ts) => {
      const centerName = ts.centerName.toLowerCase();
      const childName = ts.childName.toLowerCase();
      const searchString = searchTerm.toLowerCase();

      return centerName.indexOf(searchString) >= 0 || childName.indexOf(searchString) >= 0;
    })
    .slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

  function getFormatter(propertyName: 'catholicCount' | 'independentCount' | 'stateCount') {
    return (count: number, _transitionStatement: ITransitionStatements) => (
      <NumberInput
        key={`${_transitionStatement.id}-${propertyName}`}
        className="mb-0 mt-1"
        onChange={(value) => {
          data = data.map((ts) => (ts.id == _transitionStatement.id ? { ...ts, [propertyName]: value } : ts));
        }}
        value={count}
        numberFormat={{ allowNegative: false }}
      />
    );
  }

  const getTableColumns = useCallback((): any[] => {
    const tableColumns: any = [
      {
        dataField: 'centerName',
        text: 'Centre',
        sort: false,
      },
      {
        dataField: 'childName',
        text: 'Child',
        sort: false,
      },
      {
        dataField: 'catholicCount',
        text: 'Catholic',
        sort: false,
        formatter: getFormatter('catholicCount'),
      },
      {
        dataField: 'independentCount',
        text: 'Independent',
        sort: false,
        formatter: getFormatter('independentCount'),
      },
      {
        dataField: 'stateCount',
        text: 'State',
        sort: false,
        formatter: getFormatter('stateCount'),
      },
    ];
    return tableColumns;
  }, []);

  return (
    <PageWrapperBody>
      <TransitionStatementsCard />
      <Card bodyClassName="px-4 py-2 text-right">
        <Button
          disabled={loading}
          loading={saving}
          onClick={async () => {
            for (let x = 0; x < data.length; ++x) {
              const transitionStatement = data[x];
              const originalTransitionStatement = originalData[x];

              if (
                transitionStatement.catholicCount === originalTransitionStatement.catholicCount &&
                transitionStatement.independentCount === originalTransitionStatement.independentCount &&
                transitionStatement.stateCount === originalTransitionStatement.stateCount
              )
                continue;

              try {
                await createEditTransitionStatementMutation({
                  variables: {
                    input: {
                      centerId: transitionStatement.centerId,
                      childId: transitionStatement.childId,
                      catholicCount: transitionStatement.catholicCount,
                      independentCount: transitionStatement.independentCount,
                      stateCount: transitionStatement.stateCount,
                    },
                  },
                });
              } catch (e: any) {
                if (e.message) {
                  showToast(e.message, 'error');
                }

                return;
              }

              showToast('Saved Transition Statements', 'success');
              refetch();
            }
          }}
        >
          Save
        </Button>
      </Card>
      <DataTable
        data={rowData}
        columns={getTableColumns()}
        dataSize={data.length}
        pageSize={tableState.pageSize}
        activePage={tableState.activePage}
        showLoadingOverlay={loading || saving}
        showSelect={false}
        onPageChange={tableFunctions.changePage}
        onSizePerPageChange={tableFunctions.changeSizePerPage}
        renderHeader={(paginationProps) => (
          <>
            <TableHeader className="flex-wrap align-items-center">
              <div className="d-flex flex-wrap mr-auto align-items-center">
                <SizePerPage paginationProps={paginationProps} />
                <TableSearch
                  placeholder="Search"
                  onChange={(value) => setSearchTerm(value)}
                  className={isMobile ? 'mt-2 mb-1' : ''}
                />
              </div>
              <div className="d-flex flex-wrap mr-auto align-items-center">
                <YearPicker />
              </div>
              <div className={isMobile ? 'd-flex flex-wrap align-items-center' : 'd-flex align-items-center'}>
                <Select
                  title="Centre"
                  className="pt-1 mr-4 mb-2 w-240px"
                  value={centerId}
                  options={centers?.map((c) => ({ label: c.name, value: c.id })) ?? []}
                  onChange={(option) => setCenterId(option.value)}
                />

                <IconButtonCircle
                  icon={faTimes}
                  onClick={() => {
                    tableFunctions.changePage(1, 25);
                    setSearchTerm('');
                    setCenterId(undefined);
                  }}
                  tooltipDirection="bottom"
                  tooltipText="Clear Filters"
                />
              </div>
            </TableHeader>
          </>
        )}
      />
    </PageWrapperBody>
  );
};
