import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetGLCodes } from 'gql/glCode/queries';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import * as Mui from '@mui/material';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const styles = {
  inputStyles: {
    minWidth: '300px',
    '& legend': {
      width: 0,
    },
  },
  labelIcon: {
    fontSize: '.5rem',
    height: '1em',
    marginBottom: '0.5rem',
    paddingLeft: '2px',
  },
  inputLabelTypography: {},
};

interface IProps {
  glCodeMapping?: IGLCodeMapping | null;
  updatedGlCodesMapping?: IGLCodeMapping | null;
  onChange: (glCode: IGLCode | null, oldGlCode: IGLCode | null) => void;
  disabled?: boolean;
  areaType: GLCodeAreaType;
  required?: boolean;
}

const GlCodesDropdown = ({
  glCodeMapping,
  updatedGlCodesMapping,
  onChange,
  disabled = false,
  areaType,
  required = false,
}: IProps) => {
  const [selectedGlCode, setSelectedGlCode] = React.useState<IGLCode | null>({
    id: '',
    name: '',
    type: 'NOT_SET',
    glCode: '',
  });
  const [oldGlCode, setOldGlCode] = React.useState<IGLCode | null>({
    id: '',
    name: '',
    type: 'NOT_SET',
    glCode: '',
  });
  const [shouldSetSelectedDefault, setShouldSetSelectedDefault] = React.useState(true);
  const { t } = useTranslation(['billing', 'translation']);
  const user = useSelector((state: RootState) => state.user);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? user?.entityId ?? '';
  const sortDtos = useMemo(() => [{ field: 'name', direction: 'ASCENDING' }], []);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { data, loading } = useGetGLCodes({
    variables: {
      input: {
        businessId,
        pageNumber: 1,
        pageSize: 10000,
        sortDtos,
        areaType,
      },
    },
  });

  React.useEffect(() => {
    onChange(selectedGlCode, oldGlCode);
  }, [selectedGlCode]);

  // React.useEffect(() => {
  //   console.log('data->>', data);
  // }, [data]);

  // React.useEffect(() => {
  //   console.log('glCodeMapping->>', glCodeMapping);
  // }, [glCodeMapping]);

  React.useEffect(() => {
    if (updatedGlCodesMapping) {
      setSelectedGlCode({
        id: updatedGlCodesMapping.id,
        name: updatedGlCodesMapping.glCodeName ?? '',
        type: 'NOT_SET',
        glCode: updatedGlCodesMapping.glCodeCode || '',
      });
      setOldGlCode({
        id: updatedGlCodesMapping.id,
        name: updatedGlCodesMapping.glCodeName ?? '',
        type: 'NOT_SET',
        glCode: updatedGlCodesMapping.glCodeCode || '',
      });
    }
  }, [updatedGlCodesMapping]);

  React.useEffect(() => {
    // console.log('glCodeMapping->>', glCodeMapping);
    if (glCodeMapping?.glCodeCode && shouldSetSelectedDefault) {
      setSelectedGlCode({
        id: glCodeMapping.id,
        name: glCodeMapping.glCodeName ?? '',
        type: 'NOT_SET',
        glCode: glCodeMapping.glCodeCode,
      });
      setOldGlCode({
        id: glCodeMapping.id,
        name: glCodeMapping.glCodeName ?? '',
        type: 'NOT_SET',
        glCode: glCodeMapping.glCodeCode,
      });
      setShouldSetSelectedDefault(false);
    }
  }, [glCodeMapping]);

  return (
    <Mui.FormControlLabel
      label={<span>GL Code</span>}
      labelPlacement="top"
      control={
        <Mui.Autocomplete
          disableClearable
          limitTags={1}
          fullWidth
          id="select-gl-codes"
          options={data?.getGLCodes.data ?? []}
          getOptionLabel={(option) => (option.glCode ? `${option.glCode} - ${option.name}` : '')}
          onChange={(evt, value) => setSelectedGlCode(value)}
          renderInput={(params) => <Mui.TextField {...params} sx={styles.inputStyles} placeholder={'GL Code'} />}
          size="small"
          value={selectedGlCode ?? undefined}
        />
      }
      componentsProps={{
        typography: { alignSelf: 'flex-start', marginBottom: '0.5rem' },
      }}
    />
  );
};

export default GlCodesDropdown;
