import { gql, useQuery } from '@apollo/client';

const query = gql`
  query PeriodBalancesQuery($accountId: ID!) {
    getAccountById(id: $accountId) {
      balance
      periodBalances {
        amountDue
        pendingPayments
        unbilledBalance
      }
      billingCycle {
        nextBillingDate
        nextInvoiceDate
      }
    }
  }
`;

/**
 * useAccountBalances
 * @summary Hook to help get account balances
 * @param accountId account id that you want the balances for
 * @returns account balances
 */
export default function useAccountBalances(accountId: string) {
  const { data, refetch, loading } = useQuery(query, { variables: { accountId } });
  return {
    data: {
      balance: data?.getAccountById?.balance ?? 0,
      amountDue: data?.getAccountById?.periodBalances?.amountDue ?? 0,
      pendingPayments: data?.getAccountById?.periodBalances?.pendingPayments ?? 0,
      unbilledBalance: data?.getAccountById?.periodBalances?.unbilledBalance ?? 0,
      nextBillingDate: data?.getAccountById?.billingCycle?.nextBillingDate,
      nextInvoiceDate: data?.getAccountById?.billingCycle?.nextInvoiceDate,
    },
    refetch,
    loading,
  };
}
