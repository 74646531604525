import React, { useCallback } from 'react';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { MsgStatusCodes } from 'shared/constants/enums/ccssMessageEnums';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  archiveMsg: (data: ICcsMessage | null) => void;
  msg: ICcsMessage | null;
}

const CCSSArchiveModal: React.FC<IProps> = ({ isOpen, isLoading, onClose, archiveMsg, msg, ...props }) => {
  const dimissModal = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const isArchived = msg?.viewedStatus !== MsgStatusCodes.ARCHIVED;
  const action = isArchived ? 'Unarchive' : 'Archive';

  return (
    // @ts-ignore
    <ConfirmationModal
      title={`${action} "${msg?.subject}?"`}
      show={isOpen}
      onHide={dimissModal}
      hideOnCallback={false}
      primaryChoice={'Confirm'}
      primaryCallback={() => {
        archiveMsg(msg);
      }}
      primaryButtonProps={{
        variant: isArchived ? 'success' : 'danger',
        loading: isLoading,
      }}
      secondaryCallback={dimissModal}
    >
      Are you sure you want to {action.toLocaleLowerCase()} this message?
    </ConfirmationModal>
  );
};

export default CCSSArchiveModal;
