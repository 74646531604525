import {
  ApplicationFlowType,
  ApplicationScheduleOfferState,
  ApplicationStage,
  ApplicationState,
  CorrespondenceType,
  ReEnrolAccountCorrespondenceMeta,
  ReenrollmentDeclineReason,
  ReEnrollmentHistory,
  RelatedApplication,
  RelatedApplicationChildren,
  RelatedApplicationOffers,
  RelatedApplicationScheduleOffers,
  Stage,
  useGetReEnrolCorrespondenceMetadataByLinkCodeQuery,
} from 'generated/graphql';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'shared/components/DataTable';
import './_reEnrollHistoryExpandedRow.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { Link } from 'react-router-dom';
import {
  NORMAL_FLOW_TABS,
  PROGRAM_FLOW_TABS,
  STAGE_TO_ASO_STATE_MAPPING_FOR_NORMAL_FLOW,
  STAGE_TO_ASO_STATE_MAPPING_FOR_PROGRAM_FLOW,
} from 'pages/Enrollment/subroutes/LeadManagement/types';
import moment from 'moment';
import AccountLink from 'shared/components/AccountLink/AccountLink';
import { capitalize } from 'lodash';
import getApolloErrorMessage from 'shared/util/getApolloErrorMessage';
import { showToast } from 'shared/components/Toast';
import EnrollmentFormDiffModal from 'pages/Enrollment/subroutes/LeadManagement/components/EnrollmentFormDiffModal';
import FormDiffLink from 'pages/Enrollment/subroutes/LeadManagement/components/EnrollmentFormDiffModal/EnrolmentFormDiffLink';
import { FormattedEnrolmentDiffAreas } from 'pages/Enrollment/subroutes/LeadManagement/components/EnrollmentFormDiffModal/useEnrolmentFormDiffValueFormatter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEnrolmentFormCompleted } from 'pages/Enrollment/subroutes/LeadManagement/components/EnrollmentFormDiffModal/utils';

interface IProps {
  row: ReEnrollmentHistory;
}

interface RelatedApplicationExtended extends RelatedApplication {
  formattedOffers?: RelatedApplicationOffersExtended[];
}

interface RelatedApplicationOffersExtended extends RelatedApplicationOffers {
  scheduleOffers?: RelatedApplicationScheduleOffers[];
  childrenForOffer?: RelatedApplicationChildren[];
  leadApplicationStageName?: string;
  leadTab?: string;
}

const closedStages = [Stage.Closed, Stage.ParentCompleted];

const ReEnrollHistoryExpandedRow: React.FC<IProps> = ({ row, ...props }) => {
  const { k2EnrolFormDifference } = useFlags();
  const { t } = useTranslation(['translation', 'enrollment']);
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const timezoneByCenterId = useSelector((state: RootState) => state.timezone.byCenterId);

  const [showViewFormDiffsModal, setShowViewFormDiffsModal] = useState<boolean>(false);
  const [enrolmentFormDataRecordId, setEnrolmentFormDataRecordId] = useState<string>('');
  const [formattedEnrolmentDiffData, setEormattedEnrolmentDiffData] = useState<
    FormattedEnrolmentDiffAreas | undefined
  >();

  const [correspondenceMetadata, setCorrespondenceMetadata] = useState<
    ReEnrolAccountCorrespondenceMeta | null | undefined
  >();
  const [relatedApplication, setRelatedApplication] = useState<RelatedApplicationExtended | undefined>();

  const { data, loading, error } = useGetReEnrolCorrespondenceMetadataByLinkCodeQuery({
    skip:
      businessId === undefined ||
      businessId === null ||
      businessId === '' ||
      row.linkCode == '' ||
      !closedStages.includes(row.stage ?? Stage.Sent),
    variables: {
      businessId: businessId ?? '',
      linkCode: row.linkCode ?? '',
    },
    onError: (err) => {
      showToast(getApolloErrorMessage(err), 'error');
    },
  });

  const getLink = useCallback(
    (applicationState: ApplicationState, applicationScheduleOfferState: ApplicationScheduleOfferState) => {
      const flowType = correspondenceMetadata?.relatedApplication?.appliedFlowType;
      const STAGE_TO_ASO_STATE_MAPPING =
        flowType === ApplicationFlowType.InquireOfferEnrollment
          ? STAGE_TO_ASO_STATE_MAPPING_FOR_NORMAL_FLOW
          : STAGE_TO_ASO_STATE_MAPPING_FOR_PROGRAM_FLOW;

      const TabValues = flowType === ApplicationFlowType.InquireOfferEnrollment ? NORMAL_FLOW_TABS : PROGRAM_FLOW_TABS;

      return Object.keys(TabValues).find((tabValue) => {
        const targetedAsoStates = STAGE_TO_ASO_STATE_MAPPING[tabValue as ApplicationStage];
        return targetedAsoStates.some((offerState) => offerState == applicationScheduleOfferState);
      });
    },
    [correspondenceMetadata?.relatedApplication?.appliedFlowType]
  );

  const getApplicationStageName = useCallback(
    (applicationScheduleOfferState: ApplicationScheduleOfferState) => {
      return t(`enrollment:reEnroll-history-application-stage.${applicationScheduleOfferState}`);
    },
    [t]
  );

  useEffect(() => {
    if (data?.getReEnrolCorrespondenceMetadataByLinkCode !== null) {
      setCorrespondenceMetadata(data?.getReEnrolCorrespondenceMetadataByLinkCode);

      if (data?.getReEnrolCorrespondenceMetadataByLinkCode?.relatedApplication) {
        const relatedApplicationData = data?.getReEnrolCorrespondenceMetadataByLinkCode?.relatedApplication;
        setRelatedApplication({
          ...relatedApplicationData,
          formattedOffers: relatedApplicationData.relatedApplicationOffers
            ?.filter((offer) => offer.applicationScheduleOfferIds?.length ?? 0 > 0) // if the offer has no schedules it is no longer a valid offer
            .map((offer) => {
              const scheduleOffersForApplicationOffer =
                correspondenceMetadata?.relatedApplication?.relatedApplicationScheduleOffers?.filter(
                  (scheduleOffer) => scheduleOffer.applicationOfferId == offer.id && scheduleOffer.applicationChildId
                );
              const chidrenForOffer = correspondenceMetadata?.relatedApplication?.relatedApplicationChildren.filter(
                (child) =>
                  scheduleOffersForApplicationOffer?.some(
                    (scheduleOffer) => scheduleOffer.applicationChildId == child.applicationChildId
                  )
              );

              // we are assuming that all the schedule offers will have the same state for the same application/offer
              const scheduleOfferState = scheduleOffersForApplicationOffer
                ? scheduleOffersForApplicationOffer[0].applicationScheduleOfferState ??
                  ApplicationScheduleOfferState.New
                : ApplicationScheduleOfferState.New;

              const link = getLink(
                correspondenceMetadata?.relatedApplication?.state ?? ApplicationState.Accepted,
                scheduleOfferState
              );
              const leadApplicationStageName = getApplicationStageName(scheduleOfferState);

              return {
                ...offer,
                scheduleOffers: scheduleOffersForApplicationOffer,
                childrenForOffer: chidrenForOffer,
                linkUrl: link,
                leadApplicationStageName: leadApplicationStageName,
              };
            }),
        });
      }
    }
  }, [
    correspondenceMetadata?.relatedApplication?.state,
    correspondenceMetadata?.relatedApplication?.relatedApplicationChildren,
    correspondenceMetadata?.relatedApplication?.relatedApplicationScheduleOffers,
    data?.getReEnrolCorrespondenceMetadataByLinkCode,
    getApplicationStageName,
    getLink,
  ]);

  console.log(
    'isEnrolmentFormCompleted',
    relatedApplication,
    relatedApplication?.state,
    relatedApplication?.appliedFlowType,
    isEnrolmentFormCompleted(relatedApplication?.state, relatedApplication?.appliedFlowType)
  );

  return (
    <>
      <DataTable
        className="reenroll-history-expanded-table"
        data={[row]}
        showSelect={false}
        showPagination={false}
        showLoadingOverlay={loading}
        columns={[
          {
            text: 'Sent By',
            dataField: 'createdByName',
          },
          {
            text: 'Completed By',
            dataField: '',
            formatter: (cell: any, row: ReEnrollmentHistory) => {
              if (row.stage !== null && row.stage == Stage.ParentDeclined) {
                return row.declinedByName;
              }
              if (closedStages.includes(row.stage ?? Stage.ReadyToBeSent)) {
                return row.completedByName; //completedBy
              }
              return null;
            },
          },
          {
            text: 'Response',
            dataField: '',
            formatter: (cell: any, row: ReEnrollmentHistory) => {
              return (
                <>
                  {row.declineReason
                    ? row.declineReason === ReenrollmentDeclineReason.NotReturning && row.otherReason
                      ? t(`enrollment:reenroll-decline-reason-extra.${row.declineReason}`, {
                          endContractDate: row.otherReason,
                        })
                      : t(`enrollment:reenroll-decline-reason.${row.declineReason}`, { reason: row.otherReason })
                    : null}
                  {row.stage === Stage.ParentCompleted && // for re-enrol requests we consider "parentCompleted" when they finish the inquiry
                    row.correspondenceType === CorrespondenceType.ReenrollmentRequest &&
                    relatedApplication?.formattedOffers?.map((offer) => {
                      const timezone = timezoneByCenterId[offer.centerId ?? row.centerId];

                      // link to the accounts page if its been over two weeks since the application has been completed/migrated over
                      const lastModifiedPlusTwoWeeks = moment
                        .tz(relatedApplication.lastModifiedAt, timezone)
                        .add('2', 'weeks');
                      const showAccountLink =
                        offer.applicationState === ApplicationState.Completed && lastModifiedPlusTwoWeeks < moment();

                      return (
                        <div className="d-flex">
                          <div className="enrol-form-link d-flex align-items-center">
                            {showAccountLink && (
                              <AccountLink
                                accountId={row.accountId ?? ''}
                                accountName={`${capitalize(t('translation:spelling.inquiry'))} Completed`}
                                openInNewTab
                              />
                            )}
                            {!showAccountLink && (
                              <Link
                                className="mr-1 mb-0 d-block text-primary text-primary-hover"
                                style={{ textDecoration: 'underline' }}
                                to={{
                                  pathname: `/enrollment/lead-management/${offer.leadTab ?? ''}`,
                                  state: { firstName: row.completedByName?.split(' ')[0], centreId: row.centerId },
                                }}
                              >
                                {offer.leadApplicationStageName}
                              </Link>
                            )}
                            <span className="mr-3">
                              for {offer.childrenForOffer?.map((child) => child.childFullName).join(', ')}.
                            </span>
                            {k2EnrolFormDifference &&
                              isEnrolmentFormCompleted(
                                relatedApplication.state ?? offer.applicationState,
                                relatedApplication.appliedFlowType
                              ) && (
                                <FormDiffLink
                                  enrolmentFormId={correspondenceMetadata?.enrolmentFormAccountDataRecordId ?? ''}
                                  handleOnClick={(formattedData) => {
                                    setEormattedEnrolmentDiffData(formattedData);
                                    setEnrolmentFormDataRecordId(
                                      correspondenceMetadata?.enrolmentFormAccountDataRecordId ?? ''
                                    );
                                    setShowViewFormDiffsModal(true);
                                  }}
                                  showNoChangesText
                                />
                              )}
                          </div>
                        </div>
                      );
                    })}
                  {k2EnrolFormDifference &&
                    row.stage === Stage.ParentCompleted &&
                    row.correspondenceType === CorrespondenceType.UpdateEnrollmentForm &&
                    correspondenceMetadata?.enrolmentFormAccountDataRecordId && (
                      <FormDiffLink
                        enrolmentFormId={correspondenceMetadata?.enrolmentFormAccountDataRecordId ?? ''}
                        handleOnClick={(formattedData) => {
                          setEormattedEnrolmentDiffData(formattedData);
                          setEnrolmentFormDataRecordId(correspondenceMetadata?.enrolmentFormAccountDataRecordId ?? '');
                          setShowViewFormDiffsModal(true);
                        }}
                        showNoChangesText
                      />
                    )}
                </>
              );
            },
          },
        ]}
      />
      <EnrollmentFormDiffModal
        isOpen={showViewFormDiffsModal}
        formattedEnrolmentDiffData={formattedEnrolmentDiffData}
        handleClose={() => {
          setEnrolmentFormDataRecordId('');
          setShowViewFormDiffsModal(false);
        }}
        accountId={row.accountId}
        accountName={row.accountName}
        enrolmentFormDataRecordId={enrolmentFormDataRecordId}
        lastModified={row.stageLastModifiedAt ?? undefined}
        formLinkCode={row.linkCode ?? ''}
        centerId={row.centerId}
        isUpdateFormRequest={row.correspondenceType === CorrespondenceType.UpdateEnrollmentForm}
      />
    </>
  );
};
export default ReEnrollHistoryExpandedRow;
