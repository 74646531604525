import { gql, QueryHookOptions } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { ClassEnrollmentAvailabilityFields, pagedEnrollmentProgramFields } from './fields';
import { IEnrollmentProgram } from 'shared/types/enrollmentProgram';

interface IClassEnrollmentAvailability {
  classId: string;
  availabilityByDayOfWeek: IEnrollmentAvailabilityByDayOfWeek[];
  openSpots: string;
}

interface IEnrollmentAvailabilityByDayOfWeek {
  dayOfWeek: string;
  openSpotsAm: number;
  openSpotsPm: number;
  totalOpenSpots: number;
}

interface IGetEnrollmentAvailability {
  getEnrollmentAvailability: IClassEnrollmentAvailability;
}

interface IGetEnrollmentAvailabilityInput {
  input: {
    centerId: string;
    classId?: string;
    startDate: string;
    endDate?: string;
    includeLtbOfferings: boolean;
    includeFutureContracts: boolean;
  };
}

export const GET_ENROLLMENT_AVAILABILITY = gql`
  query($input: GetEnrollmentAvailabilityInput) {
    getEnrollmentAvailability(input: $input) {
      ${ClassEnrollmentAvailabilityFields}
    }
  }
`;

export const useGetEnrollmentAvailability = (
  options?: QueryHookOptions<IGetEnrollmentAvailability, IGetEnrollmentAvailabilityInput>
) =>
  useQuery(GET_ENROLLMENT_AVAILABILITY, {
    fetchPolicy: 'network-only',
    ...options,
  });

const GET_ENROLLMENT_PROGRAMS = gql`
  query($input: GetEnrollmentProgramsInput!) {
    getEnrollmentPrograms(input: $input) {
      ${pagedEnrollmentProgramFields}
    }
  }
`;

export interface IGetEnrollmentProgramsData {
  getEnrollmentPrograms: IPagedResult<IEnrollmentProgram>;
}

interface IGetEnrollmentProgramsInput {
  businessId: string;
  centerIds: string[];
  pagination: IPaginateInput;
  sort?: ISearchSort[];
  searchText?: string;
  withNoProgramGroup?: boolean;
  programGroupId?: string;
}

interface IGetEnrollmentProgramsVariables {
  input: IGetEnrollmentProgramsInput;
}

export const useGetEnrollmentPrograms = (
  options?: QueryHookOptions<IGetEnrollmentProgramsData, IGetEnrollmentProgramsVariables>
) =>
  useQuery<IGetEnrollmentProgramsData, IGetEnrollmentProgramsVariables>(GET_ENROLLMENT_PROGRAMS, {
    fetchPolicy: 'network-only',
    ...options,
  });
