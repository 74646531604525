import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DataTable, { SizePerPage, TableHeader, TableSearch } from 'shared/components/DataTable';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { getInitials, stringToHsl } from 'shared/util/string';
import { faCaretDown, faCaretUp, faCircle } from '@fortawesome/pro-solid-svg-icons';
import './subsidiesTracking.scss';
import {
  LATE_CHANGE_DAYS,
  LATE_WITHDRAWAL_DAYS,
  SubsidySummaryColors,
  SubsidySummaryStatusCodes,
  SubsidySummaryStatusLabels,
} from 'shared/constants/enums/subsidyTrackingEnums';
import DateInput from 'shared/components/DateInput';
import colors from '_colors.module.scss';
import Select from 'shared/components/Select';
import { convertTimeRangeObjectToString, convertTimeRangeStringToObject } from 'shared/util/timeUtils';
import SubsidiesTrackingRow from './SubsidiesTrackingRow';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { IconButtonCircle } from 'shared/components/Buttons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { ColumnType, DataTableColumnFactory } from 'shared/components/DataTableColumnType/DataTableColumnFactory';
import ActionDropdown from 'shared/components/ActionDropdown';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ITableProps {
  loading: boolean;
  data: ISubsidyWeeklySummary[];
  dateRange: ITimeRange;
  setDateRange: (tr: ITimeRange) => void;
  dataSize?: number;
  pageSize?: number;
  activePage?: number;
  showLoadingOverlay?: boolean;
  onSearch: (searchText: string) => void;
  onUpdateReason: (summary?: ISubsidyWeeklySummary) => void;
  onResubmitReport: (summary?: ISubsidyWeeklySummary) => void;
  onPageChange?: (page: number, sizePerPage: number) => void;
  onSizePerPageChange?: (sizePerPage: number) => void;
  onSort?: (field: string, direction: ElasticsearchSortDirection) => void;
}

const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

const SubsidiesTrackingTable: React.FC<ITableProps> = ({
  loading,
  data,
  dateRange,
  setDateRange,
  dataSize,
  pageSize,
  activePage,
  showLoadingOverlay,
  onUpdateReason,
  onResubmitReport,
  onPageChange,
  onSizePerPageChange,
  onSort,
  onSearch,
}) => {
  const { t } = useTranslation();
  const { k2ResubmitCcsReport } = useFlags();
  const [isDateValid, setDateValidity] = useState<boolean>(true);

  const validateDateRange = (start: string, end: string) => {
    if (start !== '' && end !== '') {
      setDateValidity(moment(start).isBefore(moment(end)));
    }
  };

  useEffect(() => {
    validateDateRange(dateRange.start, dateRange.end);
  }, [dateRange]);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const renderCaret = (price: number) => {
    if (price > 0) {
      return <FontAwesomeIcon size="2x" className="mr-2" icon={faCaretUp} color={colors.success} />;
    } else if (price < 0) {
      return <FontAwesomeIcon size="2x" className="mr-2" icon={faCaretDown} color={colors.danger} />;
    }
    return <div className="mr-7" />;
  };

  // close the expanded rows when data is loading
  useEffect(() => {
    setExpandedRows([]);
  }, [loading]);

  const dateRangeOptions: TimeRange[] = ['Last 2 Weeks', 'This Week', 'Last Week', 'Last Month', 'Custom'];
  const dataWithKeys = data.map((row, index) => ({
    ...row,
    id: `${row.childId}-${index}`,
  }));

  const onExpand = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    const expanded = isExpand ? [row.id] : [];
    setExpandedRows(expanded);
  };

  const clearFilter = () => {
    setDateRange(convertTimeRangeStringToObject('Last 2 Weeks'));
  };

  return (
    <SpinnerTableOverlay show={loading}>
      <DataTable
        keyField="id"
        noPadding={true}
        data={dataWithKeys}
        dataSize={dataSize}
        pageSize={pageSize}
        activePage={activePage}
        onPageChange={onPageChange}
        handleRowClick={() => {}}
        onSizePerPageChange={onSizePerPageChange}
        showLoadingOverlay={showLoadingOverlay}
        showSelect={false}
        showPagination={true}
        expandRow={(row) => <SubsidiesTrackingRow data={row} />}
        onExpand={onExpand}
        expanded={expandedRows}
        onlyOneExpanding={true}
        renderHeader={(paginationProps: any, searchProps: any) => (
          <TableHeader className="flex-wrap justify-content-between">
            <div className="d-flex flex-direction-row align-items-center">
              <SizePerPage paginationProps={paginationProps} />
              <TableSearch
                className="mr-4"
                onChange={(value) => {
                  onSearch(value);
                }}
                placeholder="Search"
              />
              <Select
                name="status"
                options={dateRangeOptions}
                placeholder="Status"
                className="mr-4 my-2 ml-4"
                value={convertTimeRangeObjectToString(dateRange)}
                onChange={(string) => setDateRange(convertTimeRangeStringToObject(string))}
              />

              <DateInput
                date={dateRange.start}
                onDateSelect={(start) => setDateRange({ ...dateRange, start })}
                isValid={isDateValid}
                hasCustomValidation
                className="mr-2 flex-grow-0 my-2"
              />
              <div className="mr-2 my-2">to</div>
              <DateInput
                date={dateRange.end}
                isValid={isDateValid}
                hasCustomValidation
                onDateSelect={(end) => setDateRange({ ...dateRange, end })}
                className="mr-4 flex-grow-0 my-2"
              />
            </div>
            <IconButtonCircle
              icon={faTimes}
              onClick={clearFilter}
              tooltipDirection="bottom"
              tooltipText="Clear Filters"
              className="mr-4 my-2"
            />
          </TableHeader>
        )}
        onSort={onSort}
        columns={[
          {
            text: fieldLabels.center,
            dataField: 'centerName',
            sort: true,
          },
          {
            text: 'Account',
            dataField: 'accountName',
            sort: true,
            formatter: (cell: string, row: ISubsidyWeeklySummary) => {
              return DataTableColumnFactory.getColumn('accountName', ColumnType.ACCOUNT_CCS).render(row);
            },
          },
          {
            text: 'Child',
            dataField: 'childName',
            sort: true,
            formatter: (cell: string, row: ISubsidyWeeklySummary) => (
              <AvatarDataTableCell
                color={stringToHsl(row.child.id)}
                initials={getInitials(row.child)}
                avatar={row.child.avatar?.url ?? ''}
                primaryText={row.child.fullName}
              />
            ),
          },
          {
            text: 'Week Ending',
            dataField: 'weekEnding',
            sort: true,
            formatter: (cell: string, row: ISubsidyWeeklySummary) => moment(row.weekEnding).format('DD/MM/YYYY'),
          },
          {
            text: 'Estimated',
            dataField: 'estimated',
            sort: true,
            formatter: (cell: string, row: ISubsidyWeeklySummary) => `$${row.estimated.toFixed(2)}`,
          },
          {
            text: 'Paid',
            dataField: 'paid',
            sort: true,
            formatter: (cell: string, row: ISubsidyWeeklySummary) => `$${row.paid.toFixed(2)}`,
          },
          {
            text: 'Over/Under',
            dataField: 'overUnder',
            sort: true,
            formatter: (cell: string, row: ISubsidyWeeklySummary) => (
              <div className="d-flex align-items-center">
                {renderCaret(row.estimated - row.paid)} ${Math.abs(row.estimated - row.paid).toFixed(2)}
              </div>
            ),
          },
          {
            text: 'Status',
            dataField: 'status',
            sort: true,
            formatter: (cell: string, row: ISubsidyWeeklySummary) => (
              <span>
                <FontAwesomeIcon
                  className="mr-2 payment-status-circle"
                  icon={faCircle}
                  color={SubsidySummaryColors[row.status].color}
                  fontSize="8px"
                />
                {SubsidySummaryStatusLabels[row.status]}
              </span>
            ),
          },
          {
            text: 'Actions',
            dataField: '',
            classes: 'td-overflow',
            formatter: (cell: string, row: ISubsidyWeeklySummary) => {
              const isWithdrawal = row.sessionIds?.length == 0;
              const weekStart = moment(row.weekEnding).add(-6, 'days');

              const isOutsideTimeframe = isWithdrawal
                ? moment().diff(weekStart, 'd') < LATE_WITHDRAWAL_DAYS
                : moment().diff(moment(row.weekEnding), 'd') < LATE_CHANGE_DAYS;

              const disabled = !(
                !isEmpty(row.reasonForLateChange) || // enable update when there is existing reason for late change
                (row.status === SubsidySummaryStatusCodes.ERRORED && !isOutsideTimeframe)
              );

              return (
                <div className="d-flex justify-content-center">
                  <ActionDropdown
                    actions={[
                      {
                        label: t('subsidy-tracking.add-reason-for-late-change'),
                        onClick: () => {
                          onUpdateReason(row);
                        },
                        disabled,
                      },

                      {
                        label: t('subsidy-tracking.resubmit-report'),
                        onClick: () => {
                          onResubmitReport(row);
                        },
                        disabled: row.status !== SubsidySummaryStatusCodes.ERRORED,
                      },
                    ]}
                  />
                </div>
              );
            },
          },
        ]}
      ></DataTable>
    </SpinnerTableOverlay>
  );
};

export default SubsidiesTrackingTable;
