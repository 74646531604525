import React from 'react';
import { IInputWithAppendedNodeProps, IInputWithAppendedTextProps } from './Input';
import NumberInput, { INumberInputProps } from './NumberInput';

const CurrencyInputNegatives: React.FC<
  (IInputWithAppendedNodeProps | IInputWithAppendedTextProps) & INumberInputProps
> = ({ placeholder = '0.00', textAreaClass, ...props }) => {
  return (
    <NumberInput
      {...props}
      prepend="$"
      numberFormat={{ thousandSeparator: true, decimalScale: 2, fixedDecimalScale: true, allowNegative: true }}
      textAreaClass={textAreaClass}
    />
  );
};

export default CurrencyInputNegatives;
