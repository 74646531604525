export const AUTHENTICATED = 'AUTHENTICATED';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';

export const SIGNUP_COMPLETE = 'SIGNUP_COMPLETE';

export const LOGIN_WITH_EMAIL_AND_PASSWORD = 'LOGIN_WITH_EMAIL_AND_PASSWORD';
export const LOGIN_WITH_SAML = 'LOGIN_WITH_SAML';
export const LOGIN_WITH_SOCIAL_PROVIDER = 'LOGIN_WITH_SOCIAL_PROVIDER';
export const LOGOUT = 'LOGOUT';

export const UPDATE_SESSION_TOKEN = 'UPDATE_SESSION_TOKEN';
