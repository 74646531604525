import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PageWrapper from 'shared/components/PageWrapper';
import { RouteComponentProps } from 'react-router-dom';
import useDatatableState from 'shared/hooks/useDatatableState';
import DataTable, { SizePerPage, TableHeader, TableSearch, BulkActions } from 'shared/components/DataTable';
import DataTableLoadingSkeleton from 'shared/components/LoadingSkeletons/DataTable';
import { ISearchStaffData, useSearchStaff } from 'pages/Employees/subroutes/Profiles/graphql/queries';
import { NETWORK_STATUS } from 'shared/constants/apollo';
import { SEARCH_EXPRESSIONS } from 'shared/constants/elastic';
import AvatarDataTableCell from 'shared/components/DataTable/AvatarDataTableCell';
import { staffStatusColorHexes } from 'shared/constants/tagColors';
import { ColoredBackgroundTag } from 'shared/components/Tag';
import useGetActiveCenters from 'shared/hooks/useGetActiveCenters';
import useGetStaffSearchExpression from 'shared/hooks/useGetStaffSearchExpression';
import { created } from 'shared/constants/StaffStatusSearchExpressions';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import { useSendInvitation, useDeletePendingStaff } from 'pages/Employees/shared/graphql/mutations';
import { showToast } from 'shared/components/Toast';
import errorMessage from 'shared/constants/errorMessages';
import DeleteStaffModal from '../../shared/components/DeleteStaffModal/DeleteStaffModal';
import { faPaperPlane, faUserMinus } from '@fortawesome/pro-light-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { resetImportedStaffData } from 'store/importedData/actions';
import { RootState } from 'store/reducers';
import ActionDropdown from 'shared/components/ActionDropdown';
import { getTotalCreatedStaffSuccess } from '../Profiles/duck/actions';
import staticText from 'shared/constants/staticText';
import useFormatDate from 'shared/hooks/useFormatDate';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';
import { getFullName, getInitials } from 'shared/util/string';
import useHasRoleAreaLevel from '../../../../shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from '../../../../shared/constants/enums/permissionsEnums';
import { capitalize } from 'shared/util/string';
import { useTranslation } from 'react-i18next';

const { ALL } = SEARCH_EXPRESSIONS;

interface IProps {
  breadcrumbs: string[];
}

const CreatedStaffProfiles: React.FC<IProps & RouteComponentProps> = ({ history, breadcrumbs, location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatDate = useFormatDate();
  const hasDeleteStaffPermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Base,
    level: RoleLevelType.Delete,
  });
  const hasEditStaffPermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Base,
    level: RoleLevelType.Edit,
  });
  const hasReadStaffPermission = useHasRoleAreaLevel({
    area: AreaType.Staff,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });
  const profiles = useSelector((state: RootState) => state.profiles);
  const createdStaff = useSelector((state: RootState) => state.profiles.createdStaffData);
  const [showDeleteStaffModal, setShowDeleteStaffModal] = useState<boolean>(false);
  const [staffToDelete, setStaffToDelete] = useState<IStaff[]>([]);
  const [deleteStaffLoading, setDeleteStaffLoading] = useState<boolean>(false);
  const [sendInvitation] = useSendInvitation();
  const [deletePendingUserFn] = useDeletePendingStaff({
    update: (proxy, result) => {
      if (result.data?.deletePendingStaff) {
        const data = [
          ...profiles.createdStaffData.filter((staff: IStaff) => staff.id !== result.data?.deletePendingStaff.id),
        ];
        dispatch(getTotalCreatedStaffSuccess(profiles.totalCreatedStaff - 1, data));
      }
    },
  });
  const navigateToStaffProfile = useCallback(
    (staff: IStaff) => {
      history.push(`/employees/profiles/${staff.id}`, {
        name: getFullName(staff),
      });
    },
    [history]
  );
  const [tableState, tableFunctions, setTableFunc, tableStateDirty] = useDatatableState('createdStaff', [
    { field: 'lastname.keyword', direction: 'ASCENDING' },
  ]);
  const centers = useGetActiveCenters();
  const getSearchExpression = useGetStaffSearchExpression(centers); // converts search term string into query expression

  /**
   * Sometimes we get to this page on a redirect from the staff importer. Because we use ElasticSearch to retrieve our data,
   * we run into the situation where a redirection will sometimes result in data not appearing in the table YET. To resolve
   * this we are passing the imported data through routing state and merging it with the result. The customizer function that
   * we pass checks to see if the id exists already in the dataset. If it is, it skips merging that piece of data in, therefore
   * avoiding accidental duplicates. Also, the first argument in the unionWith function receives precendence in the event of a
   * duplicate staff record across imported data and table data.
   *
   *
   * UPDATE: 09-18-2020
   *  The merging of imported staff impacted the following:
   *  - total number of pages
   *  - determining staffs in next page
   *  - sorting of staffs relative to all created staff in the database
   *
   *  In order to resolve these drawbacks of the previous implementation,
   *  POLLING is used to at least make the data updated and prevent missing data.
   *  The polling is set up to start when there is imported data and stop when
   *  the user clicks any filters like sorting, search and next page.
   *  By default, polling is disabled.
   */

  const input = {
    filter: { [ALL]: [...tableState.searchExpressions, created] },
    sort: tableState.sort,
    size: tableState.pageSize,
    from: tableState.currentItemOffset,
  };

  const { loading, data, networkStatus, stopPolling } = useSearchStaff(input, {
    fetchPolicy: 'network-only',
    pollInterval: (createdStaff || [])?.length > 0 ? 500 : 0,
    onCompleted: (data: ISearchStaffData) => {
      dispatch(getTotalCreatedStaffSuccess(data.searchStaff.totalResults, data.searchStaff.data));
    },
  });

  const forceStopPolling = () => {
    stopPolling();
    dispatch(resetImportedStaffData());
  };

  const [mergedTableData, setMergedTableData] = useState<IStaff[]>([]);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  useEffect(() => {
    setMergedTableData(profiles.createdStaffData || []);
  }, [profiles.createdStaffData]);

  // stop polling when tableState is changed or the page is unmounted.
  useEffect(() => {
    if (tableStateDirty) {
      forceStopPolling();
    }
    return () => {
      forceStopPolling();
    };
  }, [tableStateDirty]);

  const removeDataFromMergedTableData = useCallback(
    (ids: string[]) => {
      setMergedTableData(
        mergedTableData.filter((d) => {
          return !ids.includes(d.id);
        })
      );
    },
    [mergedTableData]
  );

  const sendInvite = useCallback(
    (staff: IStaff) =>
      sendInvitation({
        variables: {
          input: {
            personId: staff.id,
            entityId: staff.entityId,
            primaryCenterId: staff.primaryCenterId,
          },
        },
      }),
    [sendInvitation]
  );

  const bulkInviteUsers = useCallback(
    (users: IStaff[]) => {
      Promise.all(users.map((user) => sendInvite(user)))
        .then((data) => {
          /**
           * Maintaining the local state
           * Invited users are no longer Created users
           */
          const invitedUserIds = data?.map((d) => {
            return d.data?.sendInvitation?.personId ?? '';
          });

          removeDataFromMergedTableData(invitedUserIds);
          showToast(t('staff.toast.bulk-invite-sent-success'), 'success');
        })
        .catch(() => showToast(errorMessage.generic, 'error'));
    },
    [sendInvite, removeDataFromMergedTableData]
  );

  const handleDeleteStaffRequest = useCallback(
    (staff: IStaff[]) => {
      setStaffToDelete(staff);
      setShowDeleteStaffModal(true);
      dispatch(resetImportedStaffData());
      stopPolling();
    },
    [dispatch]
  );

  const deleteSelectedStaff = useCallback(
    (users: IStaff[]) => {
      setDeleteStaffLoading(true);
      Promise.all(users.map((user) => deletePendingUserFn({ variables: { personId: user.id } })))
        .then((data) => {
          /**
           * Maintaining the local state
           */
          const deletedIds = data.map((d) => {
            return d.data?.deletePendingStaff?.id ?? '';
          });
          removeDataFromMergedTableData(deletedIds);
          showToast(
            `${capitalize(t('spelling.deleted'))} ${users.length} ${
              users.length === 1 ? t('spelling.user') : t('spelling.users')
            } ${t('spelling.successfully')}.`,
            'success'
          );
        })
        .catch(() => showToast(t('staff.toast.error-deleting-users'), 'error'))
        .finally(() => {
          dispatch(resetImportedStaffData());
          setDeleteStaffLoading(false);
          setShowDeleteStaffModal(false);
          setStaffToDelete([]);
        });
    },
    [deletePendingUserFn, removeDataFromMergedTableData, dispatch]
  );

  const tableActionsForRow = useCallback(
    (cell: any, row: IStaff) => {
      const actions: { label: string; onClick: () => void; id?: string }[] = [];
      if (hasEditStaffPermission) {
        actions.push({ label: 'Edit', onClick: () => navigateToStaffProfile(row) });
        actions.push({
          label: `${capitalize(t('spelling.send'))} ${capitalize(t('spelling.invite'))}`,
          onClick: () => {
            if (!!row.employmentStartDate) {
              sendInvite(row)
                .then(() => {
                  removeDataFromMergedTableData([row.id]);
                  showToast(t('staff.toast.invite-sent-success'), 'success');
                })
                .catch(() => {
                  showToast(t('staff.toast.error-sending-invite'), 'error');
                });
            } else {
              showToast(t('staff.toast.no-start-date-has-been-set'), 'error');
            }
          },
        });
      }
      if (hasDeleteStaffPermission) {
        actions.push({
          label: `${capitalize(t('spelling.delete'))}`,
          id: 'delete-user',
          onClick: () => handleDeleteStaffRequest([row]),
        });
      }
      return actions;
    },
    [navigateToStaffProfile, sendInvite, handleDeleteStaffRequest, removeDataFromMergedTableData]
  );

  const bulkActions = useMemo((): IDatatableBulkAction[] => {
    const actions: IDatatableBulkAction[] = [];

    if (hasEditStaffPermission)
      actions.push({
        icon: faPaperPlane,
        tooltip: `${capitalize(t('spelling.send'))} ${capitalize(t('spelling.invites'))}`,
        onClick: () => bulkInviteUsers(tableState.selectedRows),
      });
    if (hasDeleteStaffPermission)
      actions.push({
        icon: faUserMinus,
        tooltip: `${capitalize(t('spelling.delete'))} ${capitalize(t('spelling.users'))}`,
        onClick: () => handleDeleteStaffRequest(tableState.selectedRows),
      });
    return actions;
  }, [bulkInviteUsers, tableState.selectedRows, handleDeleteStaffRequest]);

  return (
    <PageWrapper
      pageTitle={`${capitalize(t('spelling.created'))} ${capitalize(t('spelling.employees'))}`}
      applyPadding={false}
    >
      {loading && networkStatus !== NETWORK_STATUS.SET_VARIABLES ? (
        <PageWrapperBody>
          <DataTableLoadingSkeleton />
        </PageWrapperBody>
      ) : (
        <DataTable
          data={mergedTableData}
          handleRowClick={(e, staff) => (hasReadStaffPermission ? navigateToStaffProfile(staff) : null)}
          showLoadingOverlay={loading || deleteStaffLoading}
          dataSize={data?.searchStaff?.totalResults || 0}
          pageSize={tableState.pageSize}
          columns={[
            {
              text: `${capitalize(t('spelling.name'))}`,
              dataField: 'lastname.keyword',
              sort: true,
              formatter: (cell: string, staff: IStaff) => (
                <AvatarDataTableCell
                  initials={getInitials(staff)}
                  avatar={staff.avatar?.url}
                  primaryText={getFullName(staff)}
                />
              ),
            },
            {
              text: `${capitalize(t('spelling.email'))}`,
              dataField: 'email.keyword',
              sort: true,
              formatter: (cell: string, staff: IStaff) => staff.email,
            },
            {
              text: fieldLabels.center,
              dataField: 'primaryCenterName.keyword',
              sort: true,
              formatter: (cell: string, staff: IStaff) => staff.primaryCenter?.name ?? '',
            },
            {
              text: `${capitalize(t('spelling.status'))}`,
              dataField: 'employmentStatus',
              sort: true,
              formatter: (cell: any, row: IStaff) => (
                <ColoredBackgroundTag
                  color={staffStatusColorHexes[row.employmentStatus]}
                  text={`${row.employmentStatus} ${!row.employmentStartDate ? '(No Start Date)' : ''}`}
                  tooltipDirection={'top'}
                  tooltipText={staticText.employmentStatusHelper[row.employmentStatus]}
                />
              ),
            },
            {
              text: `${capitalize(t('spelling.date'))} ${capitalize(t('spelling.created'))}`,
              dataField: 'createdAt',
              sort: true,
              formatter: (cell: string) => formatDate(cell, 'MM/DD/YYYY'),
            },
            hasEditStaffPermission && {
              text: `${capitalize(t('spelling.actions'))}`,
              dataField: '',
              align: 'center',
              headerClasses: 'text-center',
              formatter: (cell: any, row: IStaff) => <ActionDropdown actions={tableActionsForRow(cell, row)} />,
            },
          ]}
          renderHeader={(paginationProps: any) => (
            <TableHeader className="flex-wrap">
              {tableState.selectedRows.length ? (
                <BulkActions bulkActions={bulkActions} />
              ) : (
                <>
                  <SizePerPage paginationProps={paginationProps} />
                  <TableSearch
                    onChange={(term) => tableFunctions.updateSearchExpressions(getSearchExpression(term))}
                    placeholder={`${capitalize(t('spelling.search'))} ${capitalize(t('spelling.staff'))}`}
                  />
                </>
              )}
            </TableHeader>
          )}
          onPageChange={tableFunctions.changePage}
          onSizePerPageChange={tableFunctions.changeSizePerPage}
          activePage={tableState.activePage}
          selectedRows={tableState.selectedRows}
          updateSelectedRows={tableFunctions.updateSelectedRows}
          onSort={tableFunctions.updateSort}
        />
      )}
      <DeleteStaffModal
        isOpen={showDeleteStaffModal}
        isLoading={deleteStaffLoading}
        onClose={() => setShowDeleteStaffModal(false)}
        selectedStaff={staffToDelete}
        deleteSelectedStaff={() => deleteSelectedStaff(staffToDelete)}
      />
    </PageWrapper>
  );
};

export default CreatedStaffProfiles;
