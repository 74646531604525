import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { PeriodContext } from '.';
import { getYearOptions } from './utils';

interface IProps {}

const years = getYearOptions();
const minYear = years.at(0)?.value;
const maxYear = years.at(-1)?.value;

const YearPicker: React.FC<IProps> = () => {
  const { year, period, setContext } = useContext(PeriodContext);
  const onPeriodChange = (step: number = 1) => setContext({ year: year + step, period });

  return (
    <Form.Group className={`kt-week-input`}>
      <InputGroup className="flex-nowrap" style={{ width: 'unset' }}>
        <InputGroup.Prepend>
          <Button variant="light" onClick={() => onPeriodChange(-1)} disabled={year == minYear}>
            <FontAwesomeIcon icon={faChevronLeft} size="sm" color="gray" />
          </Button>
        </InputGroup.Prepend>
        <div
          style={{ width: 'unset' }}
          className="form-control cursor-pointer d-flex align-items-center flex-grow-0"
          role="button"
          tabIndex={-1}
        >
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" className="mr-3" />
          {year}
        </div>
        <InputGroup.Append>
          <Button variant="light" onClick={() => onPeriodChange()} disabled={year == maxYear}>
            <FontAwesomeIcon icon={faChevronRight} size="sm" color="gray" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  );
};

export default YearPicker;
