import { useGetProgramChildren } from 'gql/programChild/queries';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonAsLink } from 'shared/components/Buttons';
import Card from 'shared/components/Card';
import DateInput from 'shared/components/DateInput';
import { Col, Row } from 'shared/components/Layout';
import { convertTimeRangeStringToObject } from 'shared/util/timeUtils';
import ProgramChildTable from '../ProgramChildTable';

interface IProps {
  businessId: string;
  programId?: string;
  startDate: string;
  startStrong: boolean;
}

const ProgramChildrenCard: React.FC<IProps> = ({ businessId, programId, startDate, startStrong }) => {
  const { t } = useTranslation(['subsidies']);

  const [isDateValid, setDateValidity] = useState<boolean>(true);
  const [dateRange, setDateRange] = useState(convertTimeRangeStringToObject('This Quarter'));

  const validateDateRange = (start: string, end: string) => {
    if (start !== '' && end !== '') {
      setDateValidity(moment(start).isBefore(moment(end)));
    }
  };
  const { data, loading, fetchMore } = useGetProgramChildren({
    variables: {
      input: {
        businessId,
        programId: programId ?? '',
        startDate: moment(dateRange.start).format('YYYY-MM-DD'),
        endDate: moment(dateRange.end).format('YYYY-MM-DD'),
        sortDtos: [
          {
            direction: 'ASCENDING',
            field: 'child.firstname',
          },
        ],
        pageSize: 25,
        pageNumber: 1,
      },
    },
    skip: programId === undefined,
  });

  const loadMore = () => {
    fetchMore({
      variables: {
        input: {
          businessId,
          programId: programId ?? '',
          startDate: moment(dateRange.start).format('YYYY-MM-DD'),
          endDate: moment(dateRange.end).format('YYYY-MM-DD'),
          sortDtos: [
            {
              direction: 'ASCENDING',
              field: 'child.firstname',
            },
          ],
          pageSize: 25,
          pageNumber: (data?.getProgramChildren.pageNumber ?? 1) + 1,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          getProgramChildren: {
            ...(fetchMoreResult?.getProgramChildren || previousResult.getProgramChildren),
            data: [...previousResult.getProgramChildren.data, ...(fetchMoreResult?.getProgramChildren.data || [])],
          },
        };
      },
    });
  };

  const programChildren = data?.getProgramChildren.data || [];
  const totalRecords = data?.getProgramChildren.totalRecords || 0;
  useEffect(() => {
    validateDateRange(dateRange.start, dateRange.end);
  }, [dateRange]);

  return (
    <Card
      header={
        <div className="d-flex justify-content-between align-items-center">
          <span>
            {t('subsidies:program-child.list.active-children')} ({totalRecords})
          </span>
        </div>
      }
    >
      <p>{t('subsidies:program-child.list.active-children-info')}</p>
      <Row className="mb-5">
        <Col className="d-flex align-items-center">
          <DateInput
            date={dateRange.start}
            onDateSelect={(start) => setDateRange({ ...dateRange, start })}
            isValid={isDateValid}
            hasCustomValidation
            className="mr-2 flex-grow-0 my-2"
          />
          <div className="mr-2 my-2">to</div>
          <DateInput
            date={dateRange.end}
            isValid={isDateValid}
            hasCustomValidation
            onDateSelect={(end) => setDateRange({ ...dateRange, end })}
            className="mr-4 flex-grow-0 my-2"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ProgramChildTable programChildren={programChildren} startDate={startDate} startStrong={startStrong} />
        </Col>
      </Row>

      {programChildren.length < totalRecords && totalRecords != 0 && !loading && (
        <ButtonAsLink className="ml-2" onClick={loadMore}>
          {t('subsidies:program-child.list.view-more')}
        </ButtonAsLink>
      )}
    </Card>
  );
};

export default ProgramChildrenCard;
