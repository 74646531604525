import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from 'shared/apis/core';

export interface IValidateInviteData {
  checkInviteCodeEmailCombo: {
    inviteValid: boolean;
    emailValid: boolean;
    existingActiveStaff: boolean;
    existingRequest: boolean;
    personId: string | null;
    personFirstName: string | null;
    personLastName: string | null;
  };
}
interface IValidateInviteInput {
  email: string;
  inviteCode: string;
}

export const VALIDATE_INVITE = gql`
  query ($input: CheckInviteCodeEmailComboInput!) {
    checkInviteCodeEmailCombo(input: $input) {
      inviteValid
      emailValid
      existingActiveStaff
      existingRequest
      personId
      personFirstName
      personLastName
    }
  }
`;

export const useLazyValidateInvite = () =>
  useLazyQuery<IValidateInviteData, { input: IValidateInviteInput }>(VALIDATE_INVITE);

export const useValidateInvite = (input: IValidateInviteInput) =>
  useQuery<IValidateInviteData, { input: IValidateInviteInput }>(VALIDATE_INVITE, {
    variables: { input },
    fetchPolicy: 'network-only',
  });
