import { gql } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { trainingTimeEventFields } from './fields';

interface ICreateTrainingTimeEventData {
  createTrainingTimeEvent: ITrainingHolidayEvent;
}

interface ICreateTrainingTimeEventVariables {
  input: ICreateTrainingTimeEventInput;
}

interface IUpdateTrainingTimeEventData {
  updateTrainingTimeEvent: ITrainingHolidayEvent;
}

interface IUpdateTrainingTimeEventVariables {
  input: IUpdateTrainingTimeEventInput;
}

interface IDeleteTrainingTimeEventData {
  deleteTrainingTimeEvent: string | null;
}

interface IDeleteTrainingTimeEventVariables {
  input: {
    id: string;
  };
}

interface IRemoveEmployeeFromTrainingTimeEventVariables {
  input: {
    trainingTimeEventId: string;
    employeeId: string;
  };
}

interface IRemoveEmployeeFromTrainingTimeEventData {
  removeEmployeeFromTrainingTimeEvent: string | null; // this is the id of the training time event the employee was removed from
}

export const CREATE_TRAINING_TIME_EVENT = (fields: string = trainingTimeEventFields) => gql`
  mutation ($input: CreateTrainingTimeEventInput!) {
    createTrainingTimeEvent(input: $input) {
      ${fields}
    }
  }
`;

export const UPDATE_TRAINING_TIME_EVENT = (fields: string = trainingTimeEventFields) => gql`
  mutation ($input: UpdateTrainingTimeEventInput!) {
    updateTrainingTimeEvent(input: $input) {
      ${fields}
    }
  }
`;

export const DELETE_TRAINING_TIME_EVENT = (fields: string = trainingTimeEventFields) => gql`
  mutation ($input: DeleteTrainingTimeEventInput!) {
    deleteTrainingTimeEvent(input: $input)
  }
`;

export const REMOVE_EMPLOYEE_FROM_TRAINING_TIME_EVENT = (fields: string = trainingTimeEventFields) => gql`
  mutation ($input: RemoveEmployeeFromTrainingTimeEventInput!) {
    removeEmployeeFromTrainingTimeEvent(input: $input)
  }
`;

export const useCreateTrainingTimeEvent = (
  options?: MutationHookOptions<ICreateTrainingTimeEventData, ICreateTrainingTimeEventVariables>,
  fields?: string
) =>
  useMutation<ICreateTrainingTimeEventData, ICreateTrainingTimeEventVariables>(
    CREATE_TRAINING_TIME_EVENT(fields),
    options
  );

export const useUpdateTrainingTimeEvent = (
  options?: MutationHookOptions<IUpdateTrainingTimeEventData, IUpdateTrainingTimeEventVariables>,
  fields?: string
) =>
  useMutation<IUpdateTrainingTimeEventData, IUpdateTrainingTimeEventVariables>(
    UPDATE_TRAINING_TIME_EVENT(fields),
    options
  );

export const useDeleteTrainingTimeEvent = (
  options?: MutationHookOptions<IDeleteTrainingTimeEventData, IDeleteTrainingTimeEventVariables>,
  fields?: string
) =>
  useMutation<IDeleteTrainingTimeEventData, IDeleteTrainingTimeEventVariables>(
    DELETE_TRAINING_TIME_EVENT(fields),
    options
  );

export const useRemoveEmployeeFromTrainingTimeEvent = (
  options?: MutationHookOptions<
    IRemoveEmployeeFromTrainingTimeEventData,
    IRemoveEmployeeFromTrainingTimeEventVariables
  >,
  fields?: string
) =>
  useMutation<IRemoveEmployeeFromTrainingTimeEventData, IRemoveEmployeeFromTrainingTimeEventVariables>(
    REMOVE_EMPLOYEE_FROM_TRAINING_TIME_EVENT(fields),
    options
  );
