import { MutationHookOptions } from '@apollo/client';
import { useMutation } from 'shared/apis/core';
import { gql } from '@apollo/client';
import { healthTermFields } from './fields';

interface ICreateHealthTermData {
  createHealthTerm: IHealthTerm;
}

interface ICreateHealthTermVariables {
  input: IHealthTermInput;
}

interface IArchiveHealthTermData {
  archiveHealthTerm: IHealthTerm;
}

interface IUnarchiveHealthTermData {
  unarchiveHealthTerm: IHealthTerm;
}

interface IArchiveHealthTermVariables {
  id: string;
}

interface IUnarchiveHealthTermVariables {
  id: string;
}

export const CREATE_HEALTH_TERM = (fields: string = healthTermFields) => gql`
  mutation ($input: HealthTermInput!) {
    createHealthTerm(input: $input) {
      ${fields}
    }
  }
`;

export const ARCHIVE_HEALTH_TERM = (fields: string = healthTermFields) => gql`
  mutation ($id: ID!) {
    archiveHealthTerm(id: $id) {
      ${fields}
    }
  }
`;

export const UNARCHIVE_HEALTH_TERM = (fields: string = healthTermFields) => gql`
  mutation ($id: ID!) {
    unarchiveHealthTerm(id: $id) {
      ${fields}
    }
  }
`;

export const useCreateHealthTerm = (
  options?: MutationHookOptions<ICreateHealthTermData, ICreateHealthTermVariables>,
  fields?: string
) => useMutation<ICreateHealthTermData, ICreateHealthTermVariables>(CREATE_HEALTH_TERM(fields), options);

export const useArchiveHealthTerm = (
  options?: MutationHookOptions<IArchiveHealthTermData, IArchiveHealthTermVariables>,
  fields?: string
) => useMutation<IArchiveHealthTermData, IArchiveHealthTermVariables>(ARCHIVE_HEALTH_TERM(fields), options);

export const useUnarchiveHealthTerm = (
  options?: MutationHookOptions<IUnarchiveHealthTermData, IUnarchiveHealthTermVariables>,
  fields?: string
) => useMutation<IUnarchiveHealthTermData, IUnarchiveHealthTermVariables>(UNARCHIVE_HEALTH_TERM(fields), options);
