import React from 'react';
import _ from 'lodash';
import * as Mui from '@mui/material';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { showToast } from 'shared/components/Toast';
import Avatar from 'shared/components/Avatar';
import { IconButtonCircle } from 'shared/components/Buttons';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';

import { useEditMessageState } from 'gql/communications/mutations';
import { RootState } from 'store/reducers';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

const styles = {
  closeIcon: {
    '& svg': {
      opacity: 0.6,
    },
    '& svg:hover': {
      opacity: 1,
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        dataRow: {
          borderBottom: 'solid 0.5px #808080',
        },
        msgRow: {
          borderTop: 'solid 0px #000000',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        cell: {
          borderBottom: 'solid 0 #000000',
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
};

interface IRowProps {
  channelId: string;
  row: any;
  index: number;
  refetchChannel: () => void;
}

const ChannelMessagesTableRow: React.FC<IRowProps> = ({ channelId, row, index, refetchChannel }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openAcknowledgeModal, setOpenAcknowledgeModal] = React.useState(false);
  const [openFlagModal, setOpenFlagModal] = React.useState(false);
  const [senderInitials, setSenderInitials] = React.useState('');
  const businessId = useSelector((state: RootState) => state.context.businessId);
  const user = useSelector((state: RootState) => state.user);

  const canEditMessage = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.StudentMessaging,
    level: RoleLevelType.Edit,
  });

  const canDeleteMessage = useHasRoleAreaLevel({
    area: AreaType.Comms,
    permission: PermissionType.StudentMessaging,
    level: RoleLevelType.Delete,
  });

  const [editMessageState, { loading: editMessageStateLoading }] = useEditMessageState();

  const editMessageStateRequest = (activityType, additionalInformation, toastText) => {
    editMessageState({
      variables: {
        businessId: businessId ?? '',
        input: {
          businessId: businessId ?? '',
          channelType: 'Student',
          channelId: channelId,
          personId: user?.id ?? '',
          activityType: activityType,
          additionalInformation: additionalInformation,
        },
      },
    })
      .then((result) => {
        showToast(`Successfully ${toastText} Message.`, 'success');
        setOpenDeleteModal(false);
        setOpenAcknowledgeModal(false);
        setOpenFlagModal(false);
        refetchChannel();
      })
      .catch((error) =>
        error?.networkError?.result?.errors?.forEach(({ message }) => {
          showToast(message, 'error');
        })
      );
  };

  const adminAcknowledged = () => {
    var retVal = false;
    row.userAcknowledgements.map((a) => {
      if (a?.person?.id == user?.id) {
        retVal = true;
      }
    });
    return retVal;
  };

  React.useEffect(() => {
    setSenderInitials(
      (row?.activity?.person?.firstname?.charAt(0) ?? '') + (row?.activity?.person?.lastname?.charAt(0) ?? '')
    );
  }, [row]);

  return (
    <>
      <Mui.TableRow
        sx={styles.components.MuiTableRow.styleOverrides.dataRow}
        key={`${row?.activity?.activityId}1` ?? ''}
      >
        <Mui.TableCell align="left" sx={styles.components.MuiTableCell.styleOverrides.cell}>
          <Mui.Typography variant="body1">
            {row?.activity?.createdAt ? moment(row.activity.createdAt).format('LLLL') : ''}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="left" sx={styles.components.MuiTableCell.styleOverrides.cell}>
          <Mui.CardHeader
            avatar={<Avatar image={row?.activity?.person?.profileUrl ?? ''} size="md" initials={senderInitials} />}
            title={
              <Mui.Typography variant="body1">
                {row?.activity?.person?.firstname + ' ' + row?.activity?.person?.lastname}
              </Mui.Typography>
            }
            sx={{ borderBottom: 0, paddingLeft: 0 }}
          />
        </Mui.TableCell>
        <Mui.TableCell align="left" sx={styles.components.MuiTableCell.styleOverrides.cell}>
          <Mui.Typography variant="body1">{row?.activity?.person?.type == 'Staff' ? 'Staff' : 'Family'}</Mui.Typography>
        </Mui.TableCell>
        {((row?.activity?.person?.type === 'Guardian' && canEditMessage) ||
          (row?.activity?.person?.type === 'Staff' && canDeleteMessage)) && (
          <Mui.TableCell align="right" sx={styles.components.MuiTableCell.styleOverrides.cell}>
            <IconButtonCircle
              id="action-dropdown-btn"
              iconSize="2x"
              icon={faEllipsisH}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <Mui.Menu
              PaperProps={{ sx: { boxShadow: '1' } }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {row?.activity?.person?.type === 'Guardian' ? (
                <>
                  <Mui.MenuItem
                    disabled={adminAcknowledged()}
                    onClick={() => {
                      setOpenAcknowledgeModal(true);
                      setAnchorEl(null);
                    }}
                  >
                    Send Acknowledgement
                  </Mui.MenuItem>
                  <Mui.MenuItem
                    hidden={row?.flaggedMessages[0]?.isResolved || row?.flaggedMessages?.length == 0}
                    onClick={() => {
                      setOpenFlagModal(true);
                      setAnchorEl(null);
                    }}
                  >
                    {row?.flaggedMessages != null && row?.flaggedMessages?.length > 0 ? 'Resolve Flag' : 'Flag Message'}
                  </Mui.MenuItem>
                </>
              ) : (
                <Mui.MenuItem
                  disabled={row?.isMessageDeleted}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setAnchorEl(null);
                  }}
                >
                  Delete
                </Mui.MenuItem>
              )}
            </Mui.Menu>
          </Mui.TableCell>
        )}
      </Mui.TableRow>
      <Mui.TableRow
        sx={styles.components.MuiTableRow.styleOverrides.msgRow}
        key={`${row?.activity?.activityId}2` ?? ''}
      >
        <Mui.TableCell align="left" colSpan={6}>
          <Mui.Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
            {row?.message}
          </Mui.Typography>
          <br />
          {row?.userAcknowledgements != null && row?.userAcknowledgements?.length > 0 && (
            <>
              {row.userAcknowledgements.map((x, index) => (
                <Mui.Chip
                  key={`user-acknowledgements-${index}`}
                  label={`Acknowledged: ${x?.createdAt ? moment(row.createdAt).format('LLLL') : ''} by ${
                    x?.person?.name ?? ''
                  }`}
                  sx={{ color: 'white', backgroundColor: '#723D8F', marginRight: 1, marginBottom: 1 }}
                />
              ))}
            </>
          )}
          {row?.flaggedMessages != null && row?.flaggedMessages?.length > 0 && (
            <>
              {row.flaggedMessages.map((x, index) => (
                <Mui.Chip
                  key={`flagged-messages-${index}`}
                  label={
                    row?.flaggedMessages[0]?.isResolved
                      ? `Flag Resolved: ${x?.createdAt ? moment(row.createdAt).format('LLLL') : ''} by ${
                          x?.resolvedPerson?.name ?? ''
                        }`
                      : `Flagged: ${x?.createdAt ? moment(row.createdAt).format('LLLL') : ''} by ${
                          x?.person?.name ?? ''
                        }`
                  }
                  sx={{ color: 'white', backgroundColor: '#CC1100', marginRight: 1, marginBottom: 1 }}
                />
              ))}
            </>
          )}
          {row?.isMessageUnread && (
            <Mui.Chip
              label="Unread"
              sx={{ color: 'white', backgroundColor: '#8F3D5E', marginRight: 1, marginBottom: 1 }}
            />
          )}
          {row?.isMessageDeleted && (
            <Mui.Chip
              label="Deleted"
              sx={{ color: 'white', backgroundColor: '#CC6D00', marginRight: 1, marginBottom: 1 }}
            />
          )}
        </Mui.TableCell>
      </Mui.TableRow>
      {/* Delete Modal */}
      <Mui.Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography variant="dialogTitle">Delete Student Message</Mui.Typography>
          <Mui.IconButton onClick={() => setOpenDeleteModal(false)} disableRipple sx={styles.closeIcon}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>{`Are you sure you would like to delete this message?`}</Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setOpenDeleteModal(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button
            variant="contained"
            color="secondary"
            onClick={() =>
              editMessageStateRequest(
                'MessageDeleted',
                `{"DeletedMessageActivityId":\"${row?.activity?.activityId ?? ''}\"}`,
                'Deleted'
              )
            }
          >
            Confirm
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
      {/* Send Acknowledgement Modal */}
      <Mui.Dialog open={openAcknowledgeModal} onClose={() => setOpenAcknowledgeModal(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography variant="dialogTitle">Send Acknowledgement</Mui.Typography>
          <Mui.IconButton onClick={() => setOpenAcknowledgeModal(false)} disableRipple sx={styles.closeIcon}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>{`Are you sure you would like to send acknowledgement?`}</Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setOpenAcknowledgeModal(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button
            variant="contained"
            color="secondary"
            onClick={() =>
              editMessageStateRequest(
                'MessageAcknowledged',
                `{"AcknowledgedActivityId":\"${row?.activity?.activityId ?? ''}\"}`,
                'Acknowledged'
              )
            }
          >
            Confirm
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
      {/* Resolve Flag Modal */}
      <Mui.Dialog open={openFlagModal} onClose={() => setOpenFlagModal(false)}>
        <Mui.DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Mui.Typography variant="dialogTitle">
            {row?.flaggedMessages != null && row?.flaggedMessages?.length > 0 ? 'Resolve Flag' : 'Flag Message'}
          </Mui.Typography>
          <Mui.IconButton onClick={() => setOpenFlagModal(false)} disableRipple sx={styles.closeIcon}>
            <FontAwesomeIcon icon={faTimes} size="sm" color={colors['dark-gray']} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent>
          {row?.flaggedMessages != null && row?.flaggedMessages?.length > 0
            ? 'Are you sure you would like to resolve flag?'
            : 'Are you sure you would like to flag this message?'}
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button variant="cancel" onClick={() => setOpenFlagModal(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (row?.flaggedMessages != null && row?.flaggedMessages?.length > 0) {
                editMessageStateRequest(
                  'FlaggedMessageResolved',
                  `{"FlaggedMessageActivityId":\"${row?.activity?.activityId ?? ''}\"}`,
                  'Resolved Flag for'
                );
              } else {
                editMessageStateRequest(
                  'MessageFlagged',
                  `{"FlaggedMessageActivityId":\"${row?.activity?.activityId ?? ''}\"}`,
                  'Flagged'
                );
              }
            }}
          >
            Confirm
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ChannelMessagesTableRow;
