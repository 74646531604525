import React, { useState, useCallback, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import KangarootimeRoutes, { IRoute } from 'shared/util/routes';
import { RootState } from 'store/reducers';
import K2Sidebar from '../K2Sidebar';
import K2TopBar from '../K2TopBar';
import useGetMe from 'shared/hooks/useGetMe';
import { showToast } from 'shared/components/Toast';
import cast from 'shared/util/cast';
import OnboardingMain from 'shared/components/Onboarding/OnboardingMain';
import { isRegion } from 'shared/util/region';
import useMatchReduxValue from './useMatchReduxStoreValue';
import KindyFunding from 'pages/Subsidies/KindyFunding/KindyFunding';
import { useGetSubsidyProgramsCount } from 'gql/program/queries';
import { freeKindySubsidySchemeId, kindyForAllSubsidySchemeId } from 'pages/Subsidies/KindyFunding/utils';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';

interface IProps {}

const K2Navigation: React.FC<IProps> = ({ ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const { data, loading } = useGetMe();
  const user = useSelector((state: RootState) => state.user);
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(false);
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const businessId: string = useSelector((state: RootState) => state.context.businessId) ?? '';

  const shouldHideNavigationBars = localStorage.getItem('isPoc') === 'true';

  const isAU = isRegion('AU');

  const allFlags = useFlags();

  const isMatch = useMatchReduxValue();

  const hasReadAgencyBase = useHasRoleAreaLevel({
    area: AreaType.Agency,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const bodyWrapperClassNames = classnames({
    'd-flex': true,
    'flex-column': true,
    'k2-navigation-body': true,
    'sidebar-collapsed': !sidebarExpanded,
    'sidebar-expanded': sidebarExpanded,
  });

  const { data: qldKindyPrograms } = useGetSubsidyProgramsCount({
    variables: {
      input: {
        centerIds: [],
        subsidySchemeIds: [kindyForAllSubsidySchemeId, freeKindySubsidySchemeId],
      },
    },
    skip: !businessId || !isAU || !hasReadAgencyBase,
  });

  const sidebarMenuRoutes = useMemo((): IRoute[] => {
    // @ts-ignore
    const iterableMenu: IRoute[] = Object.values(KangarootimeRoutes).map((route: IRoute) => {
      const launchDarklyEnabled = process.env.NODE_ENV === 'production';

      if (!route.ignoreLaunchDarklyFlag) {
        // route has a LaunchDarkly flag that evaluates to false, don't render it
        if (launchDarklyEnabled && route.launchDarklyFlag && !allFlags[route.launchDarklyFlag]) {
          return null;
        }

        // route has a negativeLaunchDarkly flag that evaluates to true, don't render it
        if (launchDarklyEnabled && route.negativeLaunchDarklyFlag && allFlags[route.negativeLaunchDarklyFlag]) {
          return null;
        }
      }
      if (route.matchReduxStoreValue && !isMatch(route.matchReduxStoreValue)) {
        return null;
      }

      if (route.subRoutes) {
        return {
          ...route,
          subRoutes: Object.values(route.subRoutes)
            .filter((r) => !r.requiredPermission || user?.hasPermission(r.requiredPermission))
            .filter(
              (r) =>
                !r.requiredAreaLevel ||
                (r.requiredAreaLevel && r.requiredAreaLevel.some((al) => user?.hasAreaPermissionLevel(al)))
            )
            .filter((r) => (launchDarklyEnabled && r.launchDarklyFlag ? allFlags[r.launchDarklyFlag] : true))
            .filter((r) =>
              launchDarklyEnabled && r.negativeLaunchDarklyFlag ? !allFlags[r.negativeLaunchDarklyFlag] : true
            )
            .filter((r) => (r.matchReduxStoreValue ? isMatch(r.matchReduxStoreValue) : true))
            // TODO: this more elegantly / reusable and remove this customisation from here
            .filter(
              (r) =>
                r.component !== KindyFunding ||
                (qldKindyPrograms?.getSubsidySchemeProgramsCountByCenterId?.count ?? 0 > 0)
            ),
        };
      }

      return route;
    });

    return iterableMenu
      .filter((r) => r && (!r.requiredPermission || user?.hasPermission(r.requiredPermission)))
      .filter(
        (r) =>
          !r.requiredAreaLevel ||
          (r.requiredAreaLevel && r.requiredAreaLevel.some((al) => user?.hasAreaPermissionLevel(al)))
      );
  }, [isMatch, allFlags, user, qldKindyPrograms]);

  const showOnboardingModal = user?.onboardingStatus === 'Incomplete';

  useEffect(() => {
    if (location.pathname === '/user/profile/profile') {
      setIsFirstLoad(false);
      return;
    }

    const person: IStaff | IPerson | null = data?.getMe ?? null;

    if (Boolean(person) && Boolean(user) && !user?.isInternal) {
      const { firstname, lastname, address } = cast<IStaff>(person);
      // depending on where data is coming from these fields may not appear correct, check the cache and what is stored in redux
      const hasAddress = Boolean(address || user?.address);

      if (!(firstname && lastname && hasAddress)) {
        history.push('/user/profile/profile');
        if (!isFirstLoad) {
          showToast('Please complete your profile info first.', 'error');
        } else if (isFirstLoad && !showOnboardingModal) {
          showToast('Please complete your profile info.', 'success');
        }
        setIsFirstLoad(false);
        if (showOnboardingModal) setSidebarExpanded(true);
      }
    }
  }, [loading, history, location, data, isFirstLoad, showOnboardingModal, user, isAU]);

  useEffect(() => {
    // show onboardingModal on first load
    if (isFirstLoad && showOnboardingModal) setSidebarExpanded(true);
  }, [isFirstLoad, showOnboardingModal]);

  const navigateTo = useCallback(
    (route: IRoute) => {
      history.push(route.route);
    },
    [history]
  );

  const onBodyClick = useCallback(() => {
    if (showOnboardingModal) setSidebarExpanded(false);
  }, [showOnboardingModal]);

  if (shouldHideNavigationBars) {
    return <div>{props.children}</div>;
  } else {
    return (
      <div className="k2-navigation-wrapper">
        <K2Sidebar
          sidebarRoutes={sidebarMenuRoutes}
          isExpanded={sidebarExpanded}
          navigateTo={navigateTo}
          expandSidebar={() => setSidebarExpanded(true)}
        />
        {showOnboardingModal && (
          <OnboardingMain expanded={sidebarExpanded} closeToggleOnClick={() => setSidebarExpanded(!sidebarExpanded)} />
        )}

        <div className={bodyWrapperClassNames} onClick={onBodyClick}>
          <K2TopBar
            mobileMenuExpanded={mobileMenuExpanded}
            expandSidebar={setSidebarExpanded}
            expandMobileMenu={setMobileMenuExpanded}
            mobileMenuRoutes={sidebarMenuRoutes}
            navigateTo={navigateTo}
          />
          {props.children}
        </div>
      </div>
    );
  }
};

export default K2Navigation;
