import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DateInput from 'shared/components/DateInput';
import Col from 'shared/components/Layout/Col';
import Row from 'shared/components/Layout/Row';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import TextInput from 'shared/components/TextInput';
import { showToast } from 'shared/components/Toast';
import { isBlank } from 'shared/util/string';
import { useCreateReturnFeeReduction, ICreateReturnFeeReductionInput } from '../../graphql/mutations';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const newReturnFeeReduction: ICreateReturnFeeReductionInput = {
  enrolmentId: '',
  sessionReportStartDate: '',
  amountNotPassedOnToIndividual: '',
  amountPassedOnToIndividual: '',
  totalFeeReductionAmountForWeek: '',
  returnFeeReductionReason: '',
};

const CreateReturnFeeReductionModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const [createReturnFeeReduction, { loading: loading }] = useCreateReturnFeeReduction();

  const validateFormFields = (): boolean => {
    if (!returnFeeReduction.enrolmentId) return false;

    if (isBlank(returnFeeReduction.sessionReportStartDate)) return false;

    if (!returnFeeReduction.amountNotPassedOnToIndividual) return false;

    if (!returnFeeReduction.amountPassedOnToIndividual) return false;

    if (!returnFeeReduction.totalFeeReductionAmountForWeek) return false;

    if (!returnFeeReduction.returnFeeReductionReason) return false;

    return true;
  };

  const [returnFeeReduction, setReturnFeeReduction] = useState<ICreateReturnFeeReductionInput>(newReturnFeeReduction);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(validateFormFields());
  }, [returnFeeReduction]);

  const handleClose = useCallback(() => {
    onClose();
    setReturnFeeReduction(newReturnFeeReduction);
  }, [onClose]);

  const handleChange = useCallback(
    (value, name) => {
      setReturnFeeReduction({ ...returnFeeReduction, [name]: value });
    },
    [returnFeeReduction]
  );

  const handleSave = useCallback(() => {
    createReturnFeeReduction({
      variables: {
        input: returnFeeReduction,
      },
    })
      .then((response) => {
        if (response.data?.returnFeeReduction) {
          showToast('Return fee reduction created successfully.', 'success');
          handleClose();
        }
      })
      .catch((err) => {
        err = err.toString().replace('Error: GraphQL error:', '').trim();
        const errorMessage = !!err ? err : 'There was an error.';
        showToast(errorMessage, 'error');
      });
  }, [createReturnFeeReduction, returnFeeReduction, handleClose]);

  return (
    <CenteredModal
      size="lg"
      show={isOpen}
      onHide={handleClose}
      title="Create Return Fee Reduction"
      secondaryChoice="Close"
      secondaryCallback={handleClose}
      primaryChoice="Create"
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: !isFormValid, loading: loading }}
    >
      <Form>
        <Row align="start">
          <Col>
            <Row>
              <Col>
                <TextInput
                  label={`Enrolment ID`}
                  value={returnFeeReduction.enrolmentId ?? ''}
                  name="enrolmentId"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Col>
          <Col>{''}</Col>
          <Col>{''}</Col>
        </Row>
        <Row align="start">
          <Col>
            <Row>
              <Col>
                <label>The Monday date of the week that you are returning payment for</label>
                <DateInput
                  required={false}
                  date={returnFeeReduction.sessionReportStartDate ?? ''}
                  isOutsideRange={(day) => day.day() !== 1}
                  onDateSelect={(date: string) => {
                    var dateValue = !!date ? moment(date).format('YYYY-MM-DD') : date;
                    handleChange(dateValue, 'sessionReportStartDate');
                  }}
                  disabled={false}
                />
              </Col>
            </Row>
          </Col>
          <Col>{''}</Col>
          <Col>{''}</Col>
        </Row>
        <Row align="start">
          <Col>
            <Row>
              <Col>
                <TextInput
                  type="number"
                  label={`Total amount for week`}
                  value={
                    !!returnFeeReduction.totalFeeReductionAmountForWeek
                      ? `${returnFeeReduction.totalFeeReductionAmountForWeek}`
                      : '0.00'
                  }
                  name="totalFeeReductionAmountForWeek"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <TextInput
                  type="number"
                  label={`Amount passed on to family`}
                  value={
                    !!returnFeeReduction.amountPassedOnToIndividual
                      ? `${returnFeeReduction.amountPassedOnToIndividual}`
                      : '0.00'
                  }
                  name="amountPassedOnToIndividual"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <TextInput
                  type="number"
                  label={`Amount not passed on to family`}
                  value={
                    !!returnFeeReduction.amountNotPassedOnToIndividual
                      ? `${returnFeeReduction.amountNotPassedOnToIndividual}`
                      : '0.00'
                  }
                  name="amountNotPassedOnToIndividual"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row align="start">
          <Col>
            <Row>
              <Col>
                <TextInput
                  label={`Reason for the return of fee reduction`}
                  value={returnFeeReduction.returnFeeReductionReason ?? ''}
                  name="returnFeeReductionReason"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </CenteredModal>
  );
};

export default CreateReturnFeeReductionModal;
