import React, { useCallback, useState } from 'react';
import SideModalDrawer from 'shared/components/ModalDrawer';
import errorMessage from 'shared/constants/errorMessages';
import { showToast } from 'shared/components/Toast';
import { useUpdateRestriction } from 'gql/restriction/mutations';
import RestrictionInputGroup, { IRestrictionInputGroupFormShape } from './RestrictionInputGroup';
import { useDispatch } from 'react-redux';
import { updateChildRestrictionSuccess } from '../../../duck/actions';
import Checkbox from 'shared/components/Checkbox';
import { Collapse } from 'react-bootstrap';
import ChildMedicalDocumentationUploadForm from './ChildMedicalDocumentationUploadForm';
import ActiveDocumentsSummary from './ActiveDocumentsSummary';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  restriction: IRestriction;
  childId: string;
}

const EditRestrictionModal: React.FC<IProps> = ({ isOpen, onClose, restriction: savedRestriction, childId }) => {
  const [showDocumentationFormGroup, setShowDocumentationFormGroup] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [restrictionFormData, setRestrictionFormData] = useState<IRestrictionInputGroupFormShape>({
    type: savedRestriction.type,
    importance: savedRestriction.importance,
    name: savedRestriction.name,
    description: savedRestriction.description,
    documentation: [],
  });

  const [updateRestrictionFn, { loading }] = useUpdateRestriction();

  const handleSave = useCallback(() => {
    updateRestrictionFn({
      variables: {
        id: savedRestriction.id,
        input: {
          importance: restrictionFormData.importance ?? 'Low',
          description: restrictionFormData.description,
          documentation: restrictionFormData.documentation,
        },
      },
    })
      .then((data) => {
        showToast('Restriction saved.', 'success');
        data.data?.updateRestriction && dispatch(updateChildRestrictionSuccess(childId, data.data?.updateRestriction));

        onClose();
      })
      .catch(() => {
        showToast('There was an error saving restriction.', 'error');
      });
  }, [onClose, restrictionFormData, childId]);

  const isFormValid = restrictionFormData.importance && restrictionFormData.description;

  return (
    <SideModalDrawer
      title="Edit Restriction"
      show={isOpen}
      onHide={onClose}
      primaryChoice="Save"
      primaryCallback={handleSave}
      primaryButtonProps={{
        disabled: !isFormValid,
        loading,
      }}
      footerHelperText={!isFormValid ? errorMessage.formRequirements : ''}
      closeOnPrimaryCallback={false}
    >
      <RestrictionInputGroup
        formData={restrictionFormData}
        onUpdate={setRestrictionFormData}
        readOnlyTypeAndName={true}
      />
      <Checkbox
        label="Add Documentation"
        value={showDocumentationFormGroup}
        onChange={(checked) => setShowDocumentationFormGroup(checked)}
        className="mb-4"
      />
      <ActiveDocumentsSummary condition={savedRestriction} />
      <Collapse in={showDocumentationFormGroup}>
        <div>
          <ChildMedicalDocumentationUploadForm
            documents={restrictionFormData.documentation}
            onFilesAdd={(files) =>
              setRestrictionFormData((prev) => ({ ...prev, documentation: [...prev.documentation, ...files] }))
            }
            onFileUpdate={(updatedFile) =>
              setRestrictionFormData((prev) => ({
                ...prev,
                documentation: prev.documentation.map((f) => (f.file.name === updatedFile.file.name ? updatedFile : f)),
              }))
            }
            onRemoveFile={(filename) =>
              setRestrictionFormData((prev) => ({
                ...prev,
                documentation: prev.documentation.filter((file) => file.file.name !== filename),
              }))
            }
            childId={childId}
          />
        </div>
      </Collapse>
    </SideModalDrawer>
  );
};

export default EditRestrictionModal;
