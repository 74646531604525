import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useGetActiveCentersWithLoading } from 'shared/hooks/useGetActiveCenters';
import { RootState } from 'store/reducers';
import { convertTimeRangeStringToObject } from 'shared/util/timeUtils';
import PageWrapper from 'shared/components/PageWrapper';
import useDatatableState from 'shared/hooks/useDatatableState';
import BillingAccountTable from './components/BillingAccountTable';
import DepositAccounts from './DepositAccounts';
import { useGetAccountsBillingOverview } from 'gql/billingAccount/queries';
import { ButtonGroup } from 'react-bootstrap';
import Button from 'shared/components/Buttons';
import classnames from 'classnames';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { DIRECTIONS } from 'shared/constants/elastic';

interface IProps {}

const BillingAccounts: React.FC<IProps> = ({ ...props }) => {
  const [view, setView] = useState<'ACCOUNTBALANCE' | 'DEPOSITBALANCE'>('ACCOUNTBALANCE');
  const hasViewBilling = useHasRoleAreaLevel({
    area: AreaType.Billing,
    permission: PermissionType.Base,
    level: RoleLevelType.Read,
  });

  const { businessId, centerFilterIds } = useSelector((state: RootState) => state.context);
  const initalTableSort: IElasticsearchSortFilter[] = [{ field: 'name.keyword', direction: DIRECTIONS.ASCENDING }];
  const [tableState, tableFunctions] = useDatatableState('account', initalTableSort);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState<IBillingAccountSort[]>([{ field: 'name', direction: 'ASCENDING' }]);
  const [filteredBillingFrequencies, setFilteredBillingFrequencies] = useState<BillingFrequency[]>([]);
  const [filteredStatus, setFilteredStatus] = useState<string[]>(['Active']);

  const [dateRange] = useState(convertTimeRangeStringToObject('This Week'));
  const { data: centers } = useGetActiveCentersWithLoading();

  const { data: accountsBillingOverviewData, loading: accountsBillingOverviewLoading } = useGetAccountsBillingOverview({
    variables: {
      input: {
        businessId: businessId ?? '',
        centerIds: centerFilterIds,
        start: moment.utc(dateRange.start).format('YYYY-MM-DD'),
        end: moment.utc(dateRange.end).format('YYYY-MM-DD'),
        sortBy: sort[0].field,
        sortDirection: sort[0].direction === 'ASCENDING' ? 'asc' : 'desc',
        tagIds: tableState.selectedFilters.tags?.map((tag) => tag.value) ?? [],
        searchKey: searchTerm,
        billingFrequencies: filteredBillingFrequencies,
        pagination: {
          pageNumber: tableState.activePage,
          pageSize: tableState.pageSize,
        },
        statusType:
          !!filteredStatus && !!filteredStatus.length
            ? filteredStatus.length > 1
              ? undefined
              : (filteredStatus[0] as AccountStatusType)
            : undefined,
        statusAtDate: moment().format('YYYY-MM-DD'),
      },
    },
    skip: businessId == null || businessId === '',
  });

  const handleSort = useCallback((field: string, direction: ElasticsearchSortDirection) => {
    setSort([{ field, direction }]);
  }, []);

  const handleSearchDebounced = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      tableFunctions.changePage(1, tableState.pageSize);
    }, 250),
    []
  );

  const handleBillingFrequencyFilter = useCallback(
    (frequencies: BillingFrequency[]) => {
      setFilteredBillingFrequencies(frequencies);
      tableFunctions.changePage(1, tableState.pageSize);
    },
    [tableFunctions, tableState.pageSize]
  );

  const handleFilteredStatus = (value: string[]) => {
    setFilteredStatus(value);
    tableFunctions.changePage(1, tableState.pageSize);
  };

  return (
    <PageWrapper pageTitle="Accounts" applyPadding={true}>
      <div className="bg-white p-4 d-flex justify-content-between border-bottom">
        <ButtonGroup>
          <Button
            className={classnames({ invisible: !hasViewBilling })}
            onClick={() => setView('ACCOUNTBALANCE')}
            variant={view === 'ACCOUNTBALANCE' ? 'secondary' : 'light'}
          >
            Account Balance
          </Button>
          <Button
            disabled={!hasViewBilling}
            className={classnames({ invisible: !hasViewBilling })}
            onClick={() => setView('DEPOSITBALANCE')}
            variant={view === 'DEPOSITBALANCE' ? 'secondary' : 'light'}
          >
            Deposit Balance
          </Button>
        </ButtonGroup>
      </div>
      <div>
        {view === 'ACCOUNTBALANCE' && (
          <BillingAccountTable
            totalResults={accountsBillingOverviewData?.getAccountsBillingOverview.totalRecords ?? 0}
            tableState={tableState}
            tableFunctions={tableFunctions}
            onPageChange={tableFunctions.changePage}
            onSizePerPageChange={tableFunctions.changeSizePerPage}
            pageSize={tableState.pageSize}
            activePage={tableState.activePage}
            loading={accountsBillingOverviewLoading}
            data={accountsBillingOverviewData?.getAccountsBillingOverview.data ?? []}
            onSort={handleSort}
            centers={centers}
            filteredStatus={filteredStatus}
            setSearchTerm={handleSearchDebounced}
            onStatusFilter={handleFilteredStatus}
            filteredBillingFrequencies={filteredBillingFrequencies}
            onBillingFrequencyFilter={handleBillingFrequencyFilter}
          />
        )}
        {view === 'DEPOSITBALANCE' && <DepositAccounts />}
      </div>
    </PageWrapper>
  );
};

export default BillingAccounts;
