import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

interface IProps {
  action: IAreaPermissionLevel;
}

const HasRoleAreaLevel: React.FC<IProps> = ({ action, ...props }) => {
  const user = useSelector((state: RootState) => state.user);

  return user?.hasAreaPermissionLevel(action) ? <>{props.children}</> : null;
};

export default HasRoleAreaLevel;
