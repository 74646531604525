import i18n from 'i18n';
import { IApplicationCategory } from 'shared/types/application';
import colors from '_colors.module.scss';

export enum ApplicationCategoryCodes {
  NEW = 'NEW',
  OFFERED = 'OFFERED',
  WAITLISTED = 'WAITLISTED',
  LOST = 'LOST',
  CONTACTED = 'CONTACTED',
  COMPLETED = 'COMPLETED',
  ACCEPTED = 'ACCEPTED',
}

export const ApplicationCategoryLabels = {
  NEW: i18n.t('enrollment.lead-management.new'),
  OFFERED: i18n.t('enrollment.lead-management.offered'),
  WAITLISTED: i18n.t('enrollment.lead-management.waitlisted'),
  LOST: i18n.t('enrollment.lead-management.lost-opportunities'),
  CONTACTED: i18n.t('enrollment.lead-management.contacted'),
  COMPLETED: i18n.t('enrollment.lead-management.completed'),
  ACCEPTED: i18n.t('enrollment.lead-management.accepted'),
};

export const ApplicationCategoryColors: Record<IApplicationCategory, { color: string; colorName: string }> = {
  [ApplicationCategoryCodes.NEW]: {
    color: colors.success,
    colorName: 'success',
  },
  [ApplicationCategoryCodes.LOST]: {
    color: colors.danger,
    colorName: 'danger',
  },
  [ApplicationCategoryCodes.OFFERED]: {
    color: colors.warning,
    colorName: 'warning',
  },
  [ApplicationCategoryCodes.WAITLISTED]: {
    color: colors.info,
    colorName: 'info',
  },
  [ApplicationCategoryCodes.CONTACTED]: {
    color: colors['dark-gray'],
    colorName: 'dark-gray',
  },
  [ApplicationCategoryCodes.COMPLETED]: {
    color: colors.dark,
    colorName: 'dark',
  },
  [ApplicationCategoryCodes.ACCEPTED]: {
    color: colors.info,
    colorName: 'info',
  },
};

export const ApplicationCategoryOptions = [
  {
    label: ApplicationCategoryLabels.NEW,
    value: ApplicationCategoryCodes.NEW,
  },
  {
    label: ApplicationCategoryLabels.CONTACTED,
    value: ApplicationCategoryCodes.CONTACTED,
  },
  {
    label: ApplicationCategoryLabels.WAITLISTED,
    value: ApplicationCategoryCodes.WAITLISTED,
  },
  {
    label: ApplicationCategoryLabels.OFFERED,
    value: ApplicationCategoryCodes.OFFERED,
  },
  {
    label: ApplicationCategoryLabels.LOST,
    value: ApplicationCategoryCodes.LOST,
  },
  {
    label: ApplicationCategoryLabels.COMPLETED,
    value: ApplicationCategoryCodes.COMPLETED,
  },
  {
    label: ApplicationCategoryLabels.ACCEPTED,
    value: ApplicationCategoryCodes.ACCEPTED,
  },
];

export enum ApplicationState {
  NONE = 'NONE',
  SUBMITTED = 'SUBMITTED',
  CONTACTED = 'CONTACTED',
  OFFERED = 'OFFERED',
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  LOST = 'LOST',
  APPROVAL_PENDING = 'APPROVAL_PENDING',
  ON_WAITLIST = 'ON_WAITLIST',
}

export const ApplicationStateLabels: Record<ApplicationState, string> = {
  NONE: i18n.t('enrollment.lead-management.none'),
  SUBMITTED: i18n.t('enrollment.lead-management.submitted'),
  CONTACTED: i18n.t('enrollment.lead-management.contacted'),
  OFFERED: i18n.t('enrollment.lead-management.offered'),
  ACCEPTED: i18n.t('enrollment.lead-management.accepted'),
  COMPLETED: i18n.t('enrollment.lead-management.completed'),
  LOST: i18n.t('enrollment.lead-management.lost-opportunities'),
  APPROVAL_PENDING: i18n.t('enrollment.lead-management.approval-pending'),
  ON_WAITLIST: i18n.t('enrollment.lead-management.waitlisted'),
};

export const ApplicationStateColors: Record<ApplicationState, { color: string; colorName: string }> = {
  [ApplicationState.NONE]: {
    color: colors.gray,
    colorName: 'gray',
  },
  [ApplicationState.SUBMITTED]: {
    color: colors.success,
    colorName: 'success',
  },
  [ApplicationState.CONTACTED]: {
    color: colors['dark-gray'],
    colorName: 'dark-gray',
  },
  [ApplicationState.OFFERED]: {
    color: colors.warning,
    colorName: 'warning',
  },
  [ApplicationState.ACCEPTED]: {
    color: colors.info,
    colorName: 'info',
  },
  [ApplicationState.COMPLETED]: {
    color: colors.dark,
    colorName: 'dark',
  },
  [ApplicationState.LOST]: {
    color: colors.danger,
    colorName: 'danger',
  },
  [ApplicationState.APPROVAL_PENDING]: {
    color: colors.orange,
    colorName: 'orange',
  },
  [ApplicationState.ON_WAITLIST]: {
    color: colors.info,
    colorName: 'info',
  },
};
