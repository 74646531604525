import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Buttons';
import Select from 'shared/components/Select';
import { Page } from 'shared/types/enrollment-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { isEqual } from 'lodash';
import { Link } from 'react-router-dom';
import { EnrolmentFormLocationState } from '../../EnrollmentFormsTable';

type PageNavVariants = 'dropdown' | 'pagination';
interface PageNavProps {
  setShowManagePageModel: (show: boolean) => void;
  selectedPage: Page;
  pageOptions: { label: string; value: Page }[];
  setSelectedPage: (page: Page) => void;
  variant?: PageNavVariants;
  enrolmentFormLocationState: EnrolmentFormLocationState;
}

export default function PageNav({
  setShowManagePageModel,
  selectedPage,
  pageOptions,
  setSelectedPage,
  variant = 'dropdown',
  enrolmentFormLocationState,
}: PageNavProps) {
  const { t } = useTranslation(['translation', 'enrollment', 'business']);

  const navToPrev = () => {
    const index = pageOptions.findIndex((page) => isEqual(page.value, selectedPage));
    if (index > 0) {
      setSelectedPage(pageOptions[index - 1].value);
    }
  };

  const navToNext = () => {
    const index = pageOptions.findIndex((page) => isEqual(page.value, selectedPage));
    if (index < pageOptions.length - 1) {
      setSelectedPage(pageOptions[index + 1].value);
    }
  };

  return (
    <nav className="bg-white mb-5 p-4">
      <div className="d-flex justify-content-between align-content-center">
        <div>
          <Button variant={'link'} onClick={() => setShowManagePageModel(true)}>
            Manage Pages
          </Button>
        </div>
        <div>
          {variant === 'dropdown' && (
            <div className="d-flex align-content-center">
              <label htmlFor="page-navigation" className="mb-0 mr-2" style={{ lineHeight: '40px' }}>
                Page Navigation
              </label>
              <Select
                id="page-navigation"
                value={selectedPage}
                options={pageOptions}
                onChange={(o) => setSelectedPage(o.value)}
                placeholder={t('enrollment:form.page-navigation')}
                className="mb-0 me-1 w-200px"
              />
            </div>
          )}
          {variant === 'pagination' && pageOptions.length > 0 && (
            <ul className="pagination react-bootstrap-table-page-btns-ul">
              <li className="page-item" title="Previous">
                <Link
                  to={{
                    state: enrolmentFormLocationState,
                  }}
                  onClick={navToPrev}
                  className="page-link"
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </Link>
              </li>
              {pageOptions.map((page, index) => (
                <li
                  className={`page-item ${isEqual(page.value, selectedPage) && 'active'}`}
                  title={page.label}
                  key={index}
                >
                  <Link
                    to={{
                      state: enrolmentFormLocationState,
                    }}
                    onClick={() => setSelectedPage(page.value)}
                    className="page-link"
                  >
                    {index + 1}
                  </Link>
                </li>
              ))}

              <li className="page-item" title="Next">
                <Link
                  to={{
                    state: enrolmentFormLocationState,
                  }}
                  onClick={navToNext}
                  className="page-link"
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
}
