import { gql } from '@apollo/client';
import { useQuery, useLazyQuery } from 'shared/apis/core';
import { useDispatch } from 'react-redux';
import { getTimeOffSuccess } from '../duck/actions';
import { payCodeFields } from 'gql/payCode/fields';

interface IGetTimeOffRequestsByScopeData {
  getTimeOffRequestsByScope: ITimeOff[];
}

interface IGetTimeOffRequestsByScopeVariables {
  input: IGetTimeOffRequestByScopeInput;
}

export const timeOffFields = `
  id
  centerId
  entityId
  personId
  startTime
  endTime
  allDay
  description
  status
  createdAt
  hoursApproved
  person {
    id
    nickname
    firstname
    lastname
    avatar {
      url
    }
    classAssignments {
      colorCode
    }
    role {
      id
      name
      scheduleVisibility
    }
  }
  ptoType
  ptoTypeObject {
    ${payCodeFields}
  }
`;

export const GET_ACTIVE_STAFF_OPTIONS = gql`
  query ($input: SearchInput!) {
    searchStaff(input: $input) {
      totalResults
      data {
        id
        firstname
        lastname
        nickname
        roleship {
          name
        }
        primaryCenterId
      }
    }
  }
`;

interface IGetTimeOffRequestByScopeInput {
  scopeType: ScopeType;
  scopeId: string;
  startTime: string;
  endTime: string;
}

export const GET_TIME_OFF_FOR_SCOPE = gql`
  query($input: GetTimeOffRequestByScopeInput!){
    getTimeOffRequestsByScope(input: $input){
      ${timeOffFields}
    }
  }
`;

export const useGetTimeOffForScope = (input: IGetTimeOffRequestByScopeInput) => {
  const dispatch = useDispatch();

  return useQuery<IGetTimeOffRequestsByScopeData, IGetTimeOffRequestsByScopeVariables>(GET_TIME_OFF_FOR_SCOPE, {
    skip: input.scopeId === '' || !input.startTime || !input.endTime,
    fetchPolicy: 'cache-and-network',
    variables: { input },
    onCompleted: (data) => {
      dispatch(getTimeOffSuccess(data?.getTimeOffRequestsByScope ?? []));
    },
  });
};

export const useGetTimeOffForScopeLazy = () =>
  useLazyQuery<IGetTimeOffRequestsByScopeData, IGetTimeOffRequestsByScopeVariables>(GET_TIME_OFF_FOR_SCOPE, {
    fetchPolicy: 'cache-and-network',
  });

interface IGetMyTimeOffInput {
  startTime: string;
  endTime: string;
}

export const GET_MY_TIME_OFF = gql`
    query($input: GetMyTimeOffRequestInput!){
      getMyTimeOffRequests(input: $input){
        ${timeOffFields}
      }
    }
  `;

export const useGetMyTimeOff = (input: IGetMyTimeOffInput) => {
  const dispatch = useDispatch();

  return useQuery<{ getMyTimeOffRequests: ITimeOff[] }, { input: IGetMyTimeOffInput }>(GET_MY_TIME_OFF, {
    skip: !input.startTime || !input.endTime,
    variables: { input },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      dispatch(getTimeOffSuccess(data.getMyTimeOffRequests));
    },
  });
};
