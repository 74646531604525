import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';
import moment from 'moment';
import { useGetStaff } from 'shared/hooks/useGetPerson';
import { getFullName } from 'shared/util/string';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Button from 'react-bootstrap/Button';
import ProfilePageWrapper from 'shared/components/PageWrapper/ProfilePageWrapper';
import PageWrapperBody from 'shared/components/PageWrapper/Body';
import DateInput from 'shared/components/DateInput';
import { TimezoneContext } from 'shared/contexts/timezoneContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useGetTimeOffForPerson, useGetTimeCardsForPerson } from '../TimeSheets/graphql/queries';
import { generateListOfDatesBetweenDates } from 'shared/util/timeUtils';
import colors from '_colors.module.scss';
import { useTranslation } from 'react-i18next';
import DetailedTimeCardView from './DetailedTimeCardView';
import TotalsTimeCardView from './TotalsTimeCardView';
import { useGetCenter } from 'gql/center/queries';
import { removeTrainingTimeEventFromEmployeeTimecard, setCurrentEmployeeTimecards } from '../TimeLogs/duck/actions';
import { useGetAttendanceWithArchivedEntries } from '../TimeLogs/graphql/queries';

interface ITimeCardTimeframeShape {
  start: string;
  end: string;
}

interface ILocationProps {
  centerId: string;
  personId: string;
  start: string;
  end: string;
}

export interface IDailyAttendanceForDate {
  date: string;
  attendance: IDailyAttendance | null;
}

interface IRouteProps {}
interface IProps extends RouteComponentProps<{}, any, IRouteProps> {}

type TimeCardViewType = 'DETAILED' | 'TOTALS';

const BROWSER_TIMEZONE = moment.tz.guess() as Timezone;
const GET_STAFF_QUERY = gql`
  query ($id: String!) {
    getStaff(id: $id) {
      id
      firstname
      lastname
      nickname
      avatar {
        url
      }
      roleship {
        scopeType
      }
      positions {
        id
        personId
        positionId
        positionName
        scopeId
        scopeType
        businessId
        type
        payRate
      }
    }
  }
`;

const TimeCards: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['timelog']);
  const centerTimezones = useSelector((state: RootState) => state.timezone.byCenterId);
  const location = useLocation<ILocationProps>();
  const queryString = new URLSearchParams(location.search);
  const personId = queryString.get('personId') ?? '';
  const centerId = queryString.get('centerId') ?? '';
  const timezone = centerTimezones[centerId] ?? BROWSER_TIMEZONE;

  const [activeView, setActiveView] = useState<TimeCardViewType>('DETAILED');
  // if no dates are provided in the query string default to this week, Monday - Friday
  const [timeframe, setTimeframe] = useState<ITimeCardTimeframeShape>({
    start: queryString.get('start') ?? moment().tz(timezone).day(1).toISOString(),
    end: queryString.get('end') ?? moment().tz(timezone).day(5).toISOString(),
  });

  const {
    loading: getTimeEntriesLoading,
    data: getTimeEntriesData,
    refetch: getTimeEntriesRefetch,
  } = useGetAttendanceWithArchivedEntries({
    personId,
    startDate: timeframe.start,
    endDate: timeframe.end,
  });

  const employeeAttendance = React.useMemo(
    () => getTimeEntriesData?.getAttendanceWithArchivedEntries ?? [],
    [getTimeEntriesData]
  );

  const { loading: getStaffLoading, data: getStaffData } = useGetStaff(personId, GET_STAFF_QUERY);
  const { loading: getTimeSheetsForPersonLoading, data: getTimeSheetsForPersonData } = useGetTimeCardsForPerson({
    variables: {
      input: {
        personId,
        startDate: (moment(timeframe.start) ?? moment().tz(timezone).startOf('week')).format('YYYY-MM-DD'),
        endDate: (moment(timeframe.end) ?? moment().tz(timezone).endOf('week')).format('YYYY-MM-DD'),
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      dispatch(setCurrentEmployeeTimecards(result.getAttendance.filter((att) => att.centerId === centerId)));
    },
  });
  const { data: getCenterData } = useGetCenter(
    {
      variables: { id: centerId },
      onCompleted: (result) => {
        const start = (moment(timeframe.start) ?? moment().startOf('week'))
          .tz(timezone)
          .day(result.getCenter.payPeriodWeekStart ?? 'MONDAY');
        let end = (moment(timeframe.end) ?? moment().endOf('week'))
          .tz(timezone)
          .day(result.getCenter.payPeriodWeekEnd ?? 'FRIDAY');

        // pay period could be Wednesday to Wednesday so we need to set the end a week in the future
        if (end.isSameOrBefore(start, 'date')) {
          end = end.clone().add(1, 'week');
        }

        setTimeframe({
          start: start.format('YYYY-MM-DD'),
          end: end.format('YYYY-MM-DD'),
        });
      },
    },
    `id name payPeriodWeekStart payPeriodWeekEnd`
  );
  const scopeType = getStaffData?.getStaff.roleship?.scopeType ?? 'CENTER';
  const scopeId = (scopeType === 'ENTITY' ? getStaffData?.getStaff.entityId : centerId) ?? '';
  const { loading: getTimeOffRequestsLoading, data: getTimeOffRequestsData } = useGetTimeOffForPerson(
    timeframe.start,
    timeframe.end,
    scopeId,
    scopeType
  );

  const createListOfDatesInTimeframe = useCallback(
    (employeeAttendance: IAttendance[]): IDailyAttendanceForDate[] => {
      const datesToShow = generateListOfDatesBetweenDates(timeframe.start, timeframe.end, timezone);
      // filter the list returned from the api because the query only accepts a personId OR centerId. if both are provided no data is returned :(
      const days = employeeAttendance
        .filter((att) => att.centerId === centerId)
        .reduce((acc, curr) => {
          acc.push(...curr.days);

          return acc;
        }, [] as IDailyAttendance[]);

      return (
        datesToShow
          // .filter((date) => moment(date).day() !== 0 && moment(date).day() !== 6) // do not show weekends
          .map((date) => ({
            date,
            attendance:
              days.find((day) =>
                moment
                  .tz(day.date, 'YYYY-MM-DD', timezone)
                  .isSame(moment.utc(date, 'YYYY-MM-DDTHH:mm:ss').tz(timezone), 'date')
              ) ?? null,
          }))
      );
    },
    [timeframe, centerId, timezone]
  );
  /**
   * updates a time entry if found in a provided array, otherwise adds to the array
   */
  const updateOrAddTimeEntry = useCallback((timeEntry: ITimeEntry, timeEntries: ITimeEntry[]): ITimeEntry[] => {
    const timeEntryIds = timeEntries.map((te) => te.id);

    if (timeEntryIds.includes(timeEntry.id)) {
      return timeEntries.map((te) => (te.id === timeEntry.id ? { ...timeEntry } : te));
    }

    return [...timeEntries, timeEntry];
  }, []);

  const handleSuccessfulTimeEntryAdjustment = useCallback(
    (timeEntry: ITimeEntry, clockedOutTimeEntry: ITimeEntry | null) => {
      const timeEntryDateMoment = moment(timeEntry.trackedTimeIn).tz(timezone).startOf('day').format('YYYY-MM-DD');
      const clockedOutTimeEntryMoment = clockedOutTimeEntry
        ? moment(clockedOutTimeEntry.trackedTimeIn).tz(timezone)
        : null;
      const prevCopy = [...employeeAttendance];
      const hasInitialDateAttendanceRecord = prevCopy
        .flatMap((att) => att.days.map((d) => d.date))
        .some((date) =>
          moment(moment(date).tz(timezone).startOf('day').format('YYYY-MM-DD')).isSame(timeEntryDateMoment, 'date')
        );
      // the date of the time entry did not have any corresponding attendance object in state so one must be created so it shows in the ui
      if (!hasInitialDateAttendanceRecord) {
        // @ts-ignore
        prevCopy.push({
          centerId: timeEntry.centerId,
          days: [
            {
              centerId: timeEntry.centerId,
              date: timeEntryDateMoment,
              personId: timeEntry.personId,
              shifts: [],
              timeEntries: [timeEntry],
              trainingTimeEvents: [],
              totalHoursWorked: { hours: 0, minutes: 0 },
              totalHoursScheduled: { hours: 0, minutes: 0 },
            },
          ],
          personId: timeEntry.personId,
          totalHoursScheduled: { hours: 0, minutes: 0 },
          totalHoursWorked: { hours: 0, minutes: 0 },
        });
      }
      dispatch(
        setCurrentEmployeeTimecards(
          prevCopy.map((attendance) => {
            const attendanceCopy = { ...attendance };
            const isDateInState = attendance.days.some((day) =>
              moment(day.date).tz(timezone).isSame(timeEntryDateMoment, 'date')
            );
            if (!isDateInState) {
              attendanceCopy.days = [
                ...attendance.days,
                {
                  centerId,
                  personId,
                  date: timeEntryDateMoment,
                  shifts: [],
                  timeEntries: [timeEntry],
                  trainingTimeEvents: [],
                  totalHoursScheduled: { hours: 0, minutes: 0 },
                  totalHoursWorked: { hours: 0, minutes: 0 },
                },
              ];
            }

            attendanceCopy.days = attendanceCopy.days.map((day) => {
              const dayCopy = { ...day };

              if (moment(day.date).tz(timezone).isSame(timeEntryDateMoment, 'date')) {
                dayCopy.timeEntries = updateOrAddTimeEntry(timeEntry, dayCopy.timeEntries);
              }
              if (
                clockedOutTimeEntry &&
                clockedOutTimeEntryMoment &&
                moment(day.date).tz(timezone).isSame(clockedOutTimeEntryMoment, 'date')
              ) {
                dayCopy.timeEntries = updateOrAddTimeEntry(clockedOutTimeEntry, dayCopy.timeEntries);
              }
              return dayCopy;
            });
            return attendanceCopy;
          })
        )
      );
      getTimeEntriesRefetch();
    },
    [centerId, dispatch, employeeAttendance, personId, timezone, updateOrAddTimeEntry, getTimeEntriesData]
  );

  const dayOfWeekToMomentDayOfWeek = useCallback((day: DayOfWeek): { label: string; value: number } => {
    switch (day) {
      case 'SUNDAY':
        return { label: 'Sunday', value: 0 };
      case 'MONDAY':
        return { label: 'Monday', value: 1 };
      case 'TUESDAY':
        return { label: 'Tuesday', value: 2 };
      case 'WEDNESDAY':
        return { label: 'Wednesday', value: 3 };
      case 'THURSDAY':
        return { label: 'Thursday', value: 4 };
      case 'FRIDAY':
        return { label: 'Friday', value: 5 };
      case 'SATURDAY':
        return { label: 'Saturday', value: 6 };
      default:
        return { label: 'Sunday', value: 0 };
    }
  }, []);

  const payWeekStart = dayOfWeekToMomentDayOfWeek(getCenterData?.getCenter.payPeriodWeekStart ?? 'MONDAY');
  const payWeekEnd = dayOfWeekToMomentDayOfWeek(getCenterData?.getCenter.payPeriodWeekEnd ?? 'FRIDAY');

  return (
    <ProfilePageWrapper
      title={`${getFullName(getStaffData?.getStaff)} Time Card`}
      subtitle={getTimeSheetsForPersonData?.getAttendance.find((att) => att.centerId === centerId)?.center.name ?? ''}
      loading={getStaffLoading}
      readOnlyAvatar
      avatarLoading={getStaffLoading}
      avatarUrl={getStaffData?.getStaff.avatar?.url ?? ''}
      applyPadding={false}
    >
      <div>
        <div className="kt-employee-time-card-timeframe-container bg-white px-8 py-4">
          <ButtonGroup aria-label="Time card data view toggle">
            <Button
              onClick={() => setActiveView('DETAILED')}
              variant={activeView === 'DETAILED' ? 'secondary' : 'light'}
            >
              {t('timelog:time-card.detailed-option')}
            </Button>
            <Button onClick={() => setActiveView('TOTALS')} variant={activeView === 'TOTALS' ? 'secondary' : 'light'}>
              {t('timelog:time-card.totals-option')}
            </Button>
          </ButtonGroup>
          <div className="d-flex align-items-center date-range-container">
            <small className="mr-2">{t('timelog:time-card.date-range-label')}</small>
            <DateInput
              className="mb-0"
              date={timeframe.start}
              onDateSelect={(date) =>
                setTimeframe((prev) => ({ ...prev, start: moment(date).tz(timezone).format('YYYY-MM-DD') }))
              }
              isOutsideRange={(date) => date.day() !== payWeekStart.value}
              // @ts-ignore
              renderCalendarInfo={() => (
                <div className="d-flex date-input-footer p-4">
                  <FontAwesomeIcon className="mr-2" icon={faInfoCircle} color={colors.info} size="lg" />
                  <span>{t('timelog:time-card.start-date-info', { day: payWeekStart.label })}</span>
                </div>
              )}
            />
            <span className="mx-4">-</span>
            <DateInput
              className="mb-0"
              date={timeframe.end}
              onDateSelect={(date) =>
                setTimeframe((prev) => ({ ...prev, end: moment(date).tz(timezone).format('YYYY-MM-DD') }))
              }
              isOutsideRange={(date) => date.day() !== payWeekEnd.value}
              // @ts-ignore
              renderCalendarInfo={() => (
                <div className="d-flex date-input-footer p-4">
                  <FontAwesomeIcon className="mr-2" icon={faInfoCircle} color={colors.info} size="lg" />
                  <span>{t('timelog:time-card.end-date-info', { day: payWeekEnd.label })}</span>
                </div>
              )}
            />
          </div>
          {/* havethe date inputs be properly centered */}
          <ButtonGroup aria-label="Time card data view toggle" className="invisible">
            <Button
              onClick={() => setActiveView('DETAILED')}
              variant={activeView === 'DETAILED' ? 'secondary' : 'light'}
            >
              {t('timelog:time-card.detailed-option')}
            </Button>
            <Button onClick={() => setActiveView('TOTALS')} variant={activeView === 'TOTALS' ? 'secondary' : 'light'}>
              {t('timelog:time-card.totals-option')}
            </Button>
          </ButtonGroup>
        </div>
        <TimezoneContext.Provider value={{ timezone }}>
          <PageWrapperBody>
            {getTimeSheetsForPersonData?.getAttendance ? (
              activeView === 'DETAILED' ? (
                <DetailedTimeCardView
                  dailyAttendances={createListOfDatesInTimeframe(employeeAttendance)}
                  positionOptions={(getStaffData?.getStaff.positions ?? []).filter(
                    (sp) => sp.scopeType === 'ENTITY' || (sp.scopeType === 'CENTER' && sp.scopeId === centerId)
                  )}
                  isLoading={getTimeSheetsForPersonLoading}
                  personId={personId}
                  centerId={centerId}
                  onSuccessfulTimeEntryAdjustment={handleSuccessfulTimeEntryAdjustment}
                  onDeleteTrainingTimeEvent={(trainingTimeEvent) =>
                    dispatch(removeTrainingTimeEventFromEmployeeTimecard(personId, trainingTimeEvent.id))
                  }
                />
              ) : (
                <TotalsTimeCardView
                  dailyAttendances={createListOfDatesInTimeframe(employeeAttendance)}
                  timeOffRequestsForTimeframe={(getTimeOffRequestsData?.getTimeOffRequestsByScope ?? []).filter(
                    (te) => te.personId === personId && te.centerId === centerId
                  )}
                  isLoading={getTimeSheetsForPersonLoading}
                  startDate={timeframe.start}
                  endDate={timeframe.end}
                  payPeriodStartDay={getCenterData?.getCenter.payPeriodWeekStart ?? 'MONDAY'}
                  payPeriodEndDay={getCenterData?.getCenter.payPeriodWeekEnd ?? 'FRIDAY'}
                  centerId={centerId}
                />
              )
            ) : (
              <div>{t('timelog:time-card.loading')}</div>
            )}
          </PageWrapperBody>
        </TimezoneContext.Provider>
      </div>
    </ProfilePageWrapper>
  );
};

export default TimeCards;
