import React, { useCallback, useContext, useMemo, useState } from 'react';
import DataControlAccordion from 'pages/TimeManagement/subroutes/StaffSchedules/components/DataControls/DataControlAccordion';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import DateInput from 'shared/components/DateInput';
import DayBiweeklyInput from 'shared/components/DayBiweeklyInput';
import DayInput, { ConstrainedDayInput } from 'shared/components/DayInput';
import { Col, Row } from 'shared/components/Layout';
import Select from 'shared/components/Select';
import { capitalize } from 'shared/util/string';
import { ApplicationScheduleOffer, EnrollmentOptions, EnrollmentProgram } from 'generated/graphql';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import EnrollProgramSelect from 'shared/components/Select/EnrollProgramSelect';
import moment from 'moment';
import { ApplicationContext } from 'shared/contexts/ApplicationContext';
import { showToast } from 'shared/components/Toast';
import { generateScheduleDaysWords } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import colors from '_colors.module.scss';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { jsFilters } from 'pages/Centers/subroutes/Classes/components/CenterClassesTable/FilterGroup';

interface IProps {
  schedule: ApplicationScheduleOffer;
  handleChange: (value: any, field: keyof ApplicationScheduleOffer) => void;
  handleDayToggle: (value: WeekDay, weekType: WeekType) => void;
  title: string;
  isDisabled: boolean;
  centers: string[];
  summaryMode?: boolean;
  subtitle?: string;
  classHasAvailability?: boolean;
}

const OfferCard: React.FC<IProps> = ({
  schedule,
  centers,
  handleDayToggle,
  handleChange,
  title,
  isDisabled,
  summaryMode = false,
  subtitle,
  classHasAvailability,
}) => {
  const { t } = useTranslation();
  const centerDetails =
    useSelector((state: RootState) => state.centers.all).filter((c) => centers.includes(c.id)) ?? [];
  const [centerId, setCenterId] = useState<string>(centers?.[0]);
  const businessId = useSelector((state: RootState) => state.context.businessId) ?? '';
  const [pinnedProgram] = useState(schedule.program);
  let classOptions = useSelector((state: RootState) => state.classes.all) ?? [];
  classOptions = classOptions.filter((c) => !c.archivedAt && centers?.includes(c.centerId)) ?? [];
  const { isSupportMode } = useContext(ApplicationContext);
  const timezoneByCenterId = useSelector((state: RootState) => state.timezone.byCenterId);
  const timezone = timezoneByCenterId[centerId] ?? moment.tz.guess();

  const frequencyOptions = [
    { value: 'WEEKLY', label: 'Weekly' },
    { value: 'BIWEEKLY', label: capitalize(t('spelling.biweekly')) },
    ...[{ value: 'CASUAL', label: 'Casual' }],
  ];

  const enrolledProgram = schedule.program as EnrollmentProgram | undefined;
  const enrolledProgramEnrollmentOptions = enrolledProgram?.programCenters.find(
    (c) => c.classId === schedule.classId
  )?.enrollmentOptions;
  const cycleType = schedule.cycleType;
  const firstWeek = schedule.days.filter((d) => d.weekType === 'WEEK1');
  const secondWeek = schedule.days.filter((d) => d.weekType === 'WEEK2');

  const [week1DaysString, week2DaysString] = generateScheduleDaysWords(schedule.days, true);
  const isMultiWeekSchedule = !!week2DaysString;

  const daySelectHelperMessage = useMemo(() => {
    if (!isDisabled && enrolledProgram) {
      return enrolledProgram.maxEnrolmentDays === enrolledProgram.minEnrolmentDays
        ? t('enrollment.lead-management.child-schedule-helper', {
            count: enrolledProgram.maxEnrolmentDays,
          })
        : t('enrollment.lead-management.child-schedule-minimum-selection', {
            min: enrolledProgram.minEnrolmentDays,
            max: enrolledProgram.maxEnrolmentDays,
          });
    }

    return '';
  }, [enrolledProgram, isDisabled, t]);

  const daySelectValidationMessage = useMemo(() => {
    if (!isDisabled && enrolledProgram) {
      if (schedule.days.length < enrolledProgram.minEnrolmentDays) {
        return t('enrollment.lead-management.invalid-min-day', { count: enrolledProgram.minEnrolmentDays });
      }
    }

    return '';
  }, [enrolledProgram, schedule, isDisabled, t]);

  const renderTitle = () => {
    return (
      <div className="d-flex justify-content-between">
        <div>
          <div className="d-flex">
            {title !== '' && <h5>{title}</h5>}
            {isSupportMode && (
              <a
                className="ml-4"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (navigator?.clipboard) {
                    await navigator.clipboard.writeText(schedule?.id);
                    showToast('ScheduleOfferId copied to clipboard', 'info');
                  }
                }}
              >
                Copy ID
              </a>
            )}
          </div>
          {subtitle && (
            <div>
              <h6>{subtitle}</h6>
            </div>
          )}
        </div>
        {classHasAvailability !== undefined && (
          <div className="d-flex justify-content-center">
            {classHasAvailability && (
              <div className="d-flex justify-content-center align-items-center text-success pr-3 check-availability">
                <FontAwesomeIcon icon={faCircleCheck} className="mr-1" color={colors.success} />
                <label>Class has availability</label>
              </div>
            )}
            {!classHasAvailability && (
              <div className="d-flex justify-content-center align-items-center text-danger pr-3 check-availability">
                <FontAwesomeIcon icon={faTriangleExclamation} className="mr-1" color={colors.danger} />
                <label>Class has no availability</label>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const getProgramFrequencyOptions = (enrolmentOptions: EnrollmentOptions) => {
    switch (enrolmentOptions) {
      case EnrollmentOptions.CasualOnly:
        return frequencyOptions.filter((o) => o.value === 'CASUAL');
      case EnrollmentOptions.PermanentOnly:
        return frequencyOptions.filter((o) => o.value === 'WEEKlY');
      default:
        return frequencyOptions.filter((o) => o.value !== 'BIWEEKLY');
    }
  };

  return (
    <DataControlAccordion
      title={renderTitle()}
      className={classNames('px-4 py-2 mb-2 program-accordion offer-accordion', {
        'summary-view': summaryMode,
      })}
      defaultOpen={true}
      key={schedule?.id}
    >
      {!summaryMode && (
        <>
          <Row>
            {enrolledProgram && (
              <Col md={6}>
                <EnrollProgramSelect
                  classNames="mb-0"
                  value={enrolledProgram}
                  onSelect={(v) => handleChange(v, 'program')}
                  centerId={centerId}
                  businessId={businessId}
                  isDisabled={isDisabled}
                  pinnedProgram={pinnedProgram}
                />
              </Col>
            )}
            {/* the centre selection will only be available for multi-centre applications */}
            {centers.length > 1 && (
              <Col md={3}>
                <label>{capitalize(t('spelling.center'))}</label>
                <Select
                  disabled={isDisabled || !!enrolledProgram}
                  value={centerId}
                  onChange={(value) => {
                    setCenterId(value.value);
                    handleChange(null, 'classId');
                  }}
                  options={orderBy(
                    centerDetails.map((c) => ({
                      ...c,
                      value: c.id,
                      label: c.name,
                    })),
                    (opt) => opt.name,
                    'asc'
                  )}
                />
              </Col>
            )}
            <Col md={3}>
              <label>{capitalize(t('spelling.class'))}</label>
              <Select
                // class is read only for program flow since we will use the class linked to the selected program
                disabled={isDisabled || !!enrolledProgram}
                value={schedule.classId}
                onChange={(value) => {
                  handleChange(value?.id, 'classId');
                }}
                options={orderBy(
                  classOptions
                    .filter((c) => c.centerId === centerId && jsFilters.active(c))
                    .map((c) => ({
                      ...c,
                      value: c.id,
                      label: c.name,
                    })),
                  (opt) => opt.name,
                  'asc'
                )}
              />
            </Col>
            <Col md={3}>
              <label>{capitalize(t('spelling.fee'))}</label>
              <Select
                // fee is read only for program flow since we will use the fee linked to the selected program
                disabled={isDisabled || !!enrolledProgram}
                value={schedule.feeId}
                options={orderBy(
                  classOptions
                    .find((c) => c.id === schedule.classId)
                    ?.fees.filter((f) => !f.deactivatedAt)
                    .map((f) => ({
                      value: f.id,
                      label: f.name,
                    })) ?? [],
                  (fee) => fee.label,
                  'asc'
                )}
                onChange={(value) => {
                  handleChange(value?.value, 'feeId');
                }}
              />
            </Col>
            {centers.length > 1 && <div className="w-100"></div>}
            <Col md={3} className="mb-4">
              <label>{t('enrollment.lead-management.start-date')}</label>
              <DateInput
                required
                disabled={isDisabled}
                date={schedule.startDate}
                onDateSelect={(date) => handleChange(date, 'startDate')}
                isValid={
                  enrolledProgram
                    ? schedule.startDate &&
                      moment(schedule.startDate).isValid() &&
                      moment(schedule.startDate).isAfter(moment(enrolledProgram?.startDate)) &&
                      moment(schedule.startDate).isBefore(moment(enrolledProgram?.endDate))
                    : undefined
                }
                isOutsideRange={(day) => {
                  if (enrolledProgram) {
                    return (
                      day.isBefore(moment(enrolledProgram.startDate)) ||
                      day.isAfter(moment(enrolledProgram.endDate).endOf('day'))
                    );
                  }
                  return false;
                }}
              />
            </Col>
            <Col md={3} className="mb-4">
              <label>{t('enrollment.lead-management.end-date')}</label>
              <DateInput disabled={true} date={schedule.endDate} onDateSelect={() => {}} />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>{capitalize(t('spelling.frequency'))}</label>
              <Select
                disabled={
                  isDisabled ||
                  (!!enrolledProgram && enrolledProgramEnrollmentOptions !== EnrollmentOptions.PermanentAndCasual)
                }
                options={
                  !enrolledProgram
                    ? frequencyOptions
                    : getProgramFrequencyOptions(enrolledProgramEnrollmentOptions ?? EnrollmentOptions.PermanentOnly)
                }
                value={cycleType} // default to the enquiry cycle type
                onChange={(option) => {
                  handleChange(option.value, 'cycleType');
                }}
              />
            </Col>
            {cycleType !== 'CASUAL' && (
              <Col md={3}>
                <label>
                  {capitalize(t('spelling.days'))} {daySelectHelperMessage && `(${daySelectHelperMessage})`}
                </label>
                {cycleType === 'WEEKLY' ? (
                  <div>
                    {enrolledProgram && !isDisabled ? (
                      <>
                        <ConstrainedDayInput
                          value={firstWeek.map((d) => d.dayOfWeek) as WeekDay[]}
                          allowedDays={enrolledProgram?.operatingDays.map((d) => d!.toUpperCase() as WeekDay)}
                          maxEnrolmentDays={enrolledProgram?.maxEnrolmentDays}
                          onDaySelect={(value) => {
                            if (!isDisabled) {
                              handleDayToggle(value, 'WEEK1');
                            }
                          }}
                        />
                        {daySelectValidationMessage && (
                          <div className="text-danger pl-4">{daySelectValidationMessage}</div>
                        )}
                      </>
                    ) : (
                      <DayInput
                        value={firstWeek.map((d) => d.dayOfWeek) as WeekDay[]}
                        onDaySelect={(value) => {
                          if (!isDisabled) {
                            handleDayToggle(value, 'WEEK1');
                          }
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <DayBiweeklyInput
                      firstWeekDays={firstWeek.map((d) => d.dayOfWeek) as WeekDay[]}
                      secondWeekDays={secondWeek.map((d) => d.dayOfWeek) as WeekDay[]}
                      onDaySelect={(value: WeekDay, week: WeekType) => {
                        if (!isDisabled) {
                          handleDayToggle(value, week);
                        }
                      }}
                    />
                  </div>
                )}
              </Col>
            )}
          </Row>
        </>
      )}
      {summaryMode && (
        <div className="d-flex">
          <div className="mr-5">
            <label>{capitalize(t('spelling.class'))}</label>
            <p>{classOptions.find((c) => c.id === schedule.classId)?.name}</p>
          </div>
          <div className="mr-5">
            <label>{capitalize(t('spelling.fee'))}</label>
            <p>
              {classOptions.find((c) => c.id === schedule.classId)?.fees.find((f) => f.id === schedule.feeId)?.name}
            </p>
          </div>
          <div className="mr-5">
            <label>{t('enrollment.lead-management.start-date')}</label>
            <p>{moment(schedule.startDate).tz(timezone).format(t('formatters.date'))}</p>
          </div>
          <div className="mr-5">
            <label>{t('enrollment.lead-management.end-date')}</label>
            <p>{schedule.endDate ? moment(schedule.endDate).tz(timezone).format(t('formatters.date')) : 'N/A'}</p>
          </div>
          <div className="mr-5">
            <label>{capitalize(t('spelling.frequency'))}</label>
            {schedule.cycleType === 'CASUAL' && <p>Casual</p>}
            {schedule.cycleType !== 'CASUAL' && (
              <p>{isMultiWeekSchedule ? capitalize(t('spelling.biweekly')) : 'Weekly'}</p>
            )}
          </div>
          {schedule.cycleType !== 'CASUAL' && (
            <div className="mr-5">
              <label>{capitalize(t('spelling.days'))}</label>
              <p>
                {isMultiWeekSchedule && 'Week 1: '}
                {week1DaysString}
              </p>
              <p>
                {isMultiWeekSchedule && 'Week 2: '}
                {week2DaysString}
              </p>
            </div>
          )}
        </div>
      )}
    </DataControlAccordion>
  );
};

export default OfferCard;
