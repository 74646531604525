import React, { useState } from 'react';
import moment from 'moment';
import SpinnerTableOverlay from 'shared/components/Spinner/SpinnerTableOverlay';

import DataTable from 'shared/components/DataTable';
import CCSSMessageRow from '../CCSSMessageRow';
import CCSMessagesTd from './CCSMessagesTd';
import CCSMessagesTableHeader from './CCSMessagesTableHeader';

import { CcsMsgTypeLabelEnum, MsgStatusCodes, CcsMsgSourcesLabelEnum } from 'shared/constants/enums/ccssMessageEnums';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface ITableProps {
  data: ICcsMessage[];
  businessId: string;
  expandRow?: (row: any) => JSX.Element;
  loading: boolean;
  dataSize: number;
  pageSize: number;
  activePage: number;
  onPageChange: (page: number, sizePerPage: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
  activeFilters: IMessagesFilter;
  onActiveFilterChange: (value: IMessagesFilter) => void;
  onSort: (field: string, direction: ElasticsearchSortDirection) => void;
  dateRange: ITimeRange;
  setDateRange: (tr: ITimeRange) => void;
  selectedRows: any;
  updateSelectedRows: any;
  type?: 'provider' | 'service';
  archiveMsg: (data: ICcsMessage) => void;
  updateMsgStatus: (data: ICcsMessage) => void;
}

const CCSSMessagesTable: React.FC<ITableProps> = ({
  data,
  loading,
  dataSize,
  pageSize,
  activePage,
  businessId,
  onPageChange,
  onSizePerPageChange,
  onActiveFilterChange,
  activeFilters,
  onSort,
  dateRange,
  setDateRange,
  updateMsgStatus,
  archiveMsg,
  type = 'provider',
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const handleSourceChange = (values: ICcsMessageSourceOption[] | null) => {
    onActiveFilterChange({ source: values });
  };

  const handleTypeChange = (option: any) => {
    onActiveFilterChange({ type: option.value });
  };

  const handleStatusChange = (option: any) => {
    onActiveFilterChange({ status: option.value });
  };

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;

  const getDateFormat = (date: string) => {
    const today = new Date();
    if (moment(date).isSame(today, 'day')) {
      return 'hh:mm A';
    } else if (moment(date).isSame(today, 'year')) {
      return 'D MMM';
    }
    return 'DD/MM/YYYY';
  };

  const clearFilters = () => {
    setDateRange({ start: '', end: '' });
    onActiveFilterChange({ type: null, source: [], status: MsgStatusCodes.UNREAD });
  };

  const markAsRead = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    const expanded = isExpand ? [row.id] : [];
    setExpandedRows(expanded);
    if (row.viewedStatus === MsgStatusCodes.UNREAD) {
      updateMsgStatus({
        ...row,
        viewedStatus: MsgStatusCodes.READ,
      });
    }
  };

  return (
    <>
      <SpinnerTableOverlay show={loading}>
        <DataTable
          keyField="id"
          noPadding={true}
          data={data}
          dataSize={dataSize}
          handleRowClick={() => {}}
          hasOverlay={false}
          pageSize={pageSize}
          activePage={activePage}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          showPagination={true}
          showSelect={false}
          className="ccs-table"
          expandHeaderColumnRenderer={(props) => <></>}
          onExpand={markAsRead}
          expanded={expandedRows}
          expandRow={(row) => (
            <CCSSMessageRow data={row} updateMsgStatus={archiveMsg} businessId={businessId} type={type} />
          )}
          onSort={onSort}
          defaultSortField="created"
          defaultSorting="desc"
          onlyOneExpanding={true}
          renderHeader={(paginationProps: any, searchProps: any) => (
            <CCSMessagesTableHeader
              handleStatusChange={handleStatusChange}
              dateRange={dateRange}
              activeFilters={activeFilters}
              onActiveFilterChange={onActiveFilterChange}
              setDateRange={setDateRange}
              handleTypeChange={handleTypeChange}
              handleSourceChange={handleSourceChange}
              clearFilters={clearFilters}
            />
          )}
          columns={[
            {
              text: 'Type',
              dataField: 'type',
              sort: true,

              formatter: (cell: any, row: ICcsMessage) => (
                <CCSMessagesTd ccsMessage={row} column="type" text={CcsMsgTypeLabelEnum[row.type]} />
              ),
            },
            {
              text: 'Source',
              dataField: 'source',
              sort: true,
              formatter: (cell: any, row: ICcsMessage) => (
                <CCSMessagesTd ccsMessage={row} column="source" text={CcsMsgSourcesLabelEnum[row.source]} />
              ),
            },
            {
              text: 'Subject',
              dataField: 'subject',
              formatter: (cell: any, row: ICcsMessage) => <CCSMessagesTd ccsMessage={row} column="subject" />,
            },
            ...(type !== 'provider'
              ? [
                  {
                    text: fieldLabels.center,
                    dataField: 'centerName',
                    sort: true,
                    formatter: (cell: any, row: ICcsMessage) => {
                      return <CCSMessagesTd ccsMessage={row} column="centerName" />;
                    },
                  },
                ]
              : []),
            {
              text: 'Date',
              dataField: 'created',
              headerClasses: 'text-right',
              sort: true,
              formatter: (cell: any, row: ICcsMessage) => (
                <CCSMessagesTd
                  className="pr-4 text-right"
                  ccsMessage={row}
                  column="created"
                  text={moment(row.created).format(getDateFormat(row.created))}
                />
              ),
            },
          ]}
        />
      </SpinnerTableOverlay>
    </>
  );
};

export default CCSSMessagesTable;
