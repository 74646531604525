import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CustomField from '../CustomField';
import FormWrapper2 from '../Form/FormWrapper2';
import { useUpdateCustomFieldFileUpload } from 'gql/customField/mutation';
import useHasRoleAreaLevel from 'shared/hooks/useHasRoleAreaLevel';
import { AreaType, PermissionType, RoleLevelType } from 'shared/constants/enums/permissionsEnums';
import { orderBy } from 'lodash';
import {
  CustomFieldType,
  ICustomField,
  ICustomFieldFileUploadValue,
  ICustomFieldValue,
} from 'shared/types/customField';

interface IProps {
  customFields: ICustomField[];
  isReadOnly?: boolean;
  onSave: (values: ICustomFieldValue[]) => void;
  values: ICustomFieldValue[];
  loading?: boolean;
  entityId: string;
}
const CustomFieldForm: React.FC<IProps> = ({
  customFields,
  values,
  onSave,
  loading = false,
  isReadOnly = false,
  entityId,
}) => {
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [isDirty, setDirty] = useState(false);

  const handleChange = (value: any, id: string) => {
    setDirty(true);
    setFormValues((currentValues) => ({ ...currentValues, [id]: value }));
  };

  const getAreaType = (areaName: string) => {
    switch (areaName) {
      case 'Account':
        return AreaType.Account;
      case 'Contact':
        return AreaType.Contact;
      case 'Child':
        return AreaType.Child;
      default:
        return AreaType.Business;
    }
  };

  const hasUpdateAccess =
    useHasRoleAreaLevel({
      area: getAreaType(customFields[0].area),
      permission: PermissionType.Base,
      level: RoleLevelType.Edit,
    }) && getAreaType(customFields[0].area) !== AreaType.Business;

  const initializeFields = () => {
    if (customFields.length > 0 && values.length > 0) {
      const valuePerId = values.reduce((acc: Record<string, string | File>, curr: ICustomFieldValue) => {
        acc[curr.customFieldId] = curr.value;
        return acc;
      }, {});
      const initFields: Record<string, any> = {};
      customFields.forEach((field) => {
        let value = valuePerId[field.id];
        initFields[field.id] = value ?? null;
      });
      setFormValues(initFields);
    }
  };

  useEffect(() => {
    initializeFields();
  }, [customFields, values]);

  const handleCancel = () => {
    initializeFields();
  };

  const [updateFileUpload, { loading: updateLoading }] = useUpdateCustomFieldFileUpload();

  const handleSave = useCallback(async () => {
    const formValuesArray: ICustomFieldValue[] = Object.keys(formValues).map((key) => ({
      customFieldId: key,
      value: formValues[key],
    }));

    const documentFields = customFields.filter((x) => x.type === 'Document').map((id) => id.id);
    const formValuesWithoutDocumentFields = formValuesArray.filter((x) => !documentFields.includes(x.customFieldId));

    const formValuesForDocumentFields = formValuesArray.filter((x) => documentFields.includes(x.customFieldId));
    const businessId = customFields[0].businessId;
    const area = customFields[0].area;
    const centerIds = customFields[0].centerIds;

    const uploadFilePromises = formValuesForDocumentFields.map((item) => {
      if (item.value instanceof File) {
        const f = item.value as File;
        const fileToUpload: ICustomFieldFileUploadValue = {
          customFieldId: item.customFieldId,
          file: f,
          filename: f.name,
        };
        return updateFileUpload({
          variables: {
            input: {
              businessId: businessId,
              centerIds: centerIds,
              entityId: entityId,
              area: area,
              document: {
                customFieldId: fileToUpload.customFieldId,
                file: fileToUpload.file,
                filename: fileToUpload.filename,
              },
            },
          },
        });
      } else {
        return Promise.resolve(null);
      }
    });

    const uploadFilesResponses = await Promise.all(uploadFilePromises);
    const customFieldValueForUploadedFiles: ICustomFieldValue[] = uploadFilesResponses
      .filter((e) => e !== null && e !== undefined)
      .map((element) => {
        const { entityId, fileId } = element?.data?.updateCustomFieldFileUpload!;
        return {
          customFieldId: entityId,
          value: fileId,
        };
      });

    onSave([...formValuesWithoutDocumentFields, ...customFieldValueForUploadedFiles]);

    setDirty(false);
  }, [customFields, entityId, formValues, onSave, updateFileUpload]);

  const sortedFields: ICustomField[] = useMemo(() => {
    let fields = customFields.filter((c: ICustomField) => !c.notForConsoleDisplay) ?? [];
    fields = orderBy(fields, 'label');
    return orderBy(fields, 'displayOrder');
  }, [customFields]);

  return (
    <FormWrapper2
      toggleDirty={setDirty}
      formIsDirty={isDirty}
      onCancel={handleCancel}
      loading={updateLoading}
      onSave={handleSave}
      saveDisabled={!hasUpdateAccess}
      primaryChoice={'Update'}
    >
      {sortedFields.map((field) => (
        <CustomField
          key={field.id}
          isReadOnly={isReadOnly}
          type={field.type as CustomFieldType}
          customField={field}
          value={formValues[field.id]}
          onChange={handleChange}
          entityId={entityId}
        />
      ))}
    </FormWrapper2>
  );
};

export default CustomFieldForm;
