import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import LoggedOutPage from 'shared/components/SignUpPage';
import CreateNewPassword from './components/CreateNewPassword';
import SendEmail from './components/SendEmail';
import EmailSentSuccess from './components/EmailSentSuccess';
import PasswordResetSuccess from './components/PasswordResetSuccess';
import { clearReduxState } from './duck/actions';
import queryString from 'query-string';

const RecoverPassword = ({ isAuthed, location }) => {
  const { code } = queryString.parse(location.search);
  const { message, isEmailSent, isPasswordReset } = useSelector((state) => state.recoverPassword);
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      // clear state when component unmounts
      dispatch(clearReduxState());
    },
    [dispatch]
  );

  return (
    <LoggedOutPage isAuthed={isAuthed}>
      <div className="recover-password-container mx-auto p-8">
        {isPasswordReset ? (
          <PasswordResetSuccess />
        ) : code ? (
          <CreateNewPassword code={code} />
        ) : isEmailSent ? (
          <EmailSentSuccess />
        ) : (
          <SendEmail />
        )}
        {message && (
          <Alert className="mt-8" variant="danger">
            {message}
          </Alert>
        )}
      </div>
    </LoggedOutPage>
  );
};

export default withRouter(RecoverPassword);
