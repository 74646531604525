import React from 'react';
import Card from 'shared/components/Card';

interface IProps {
  scheduled: number;
  actual: number;
}

const PeriodTotalsCard: React.FC<IProps> = ({ scheduled, actual, ...props }) => {
  return (
    <Card bodyClassName="py-4 px-8">
      <div className="d-flex flex-row align-items-center justify-center flex-wrap">
        <b className="mr-auto">Total for this period:</b>
        <div className="d-flex flex-row flex-grow-1 justify-content-md-end">
          <div>
            Scheduled Time: <b>{scheduled.toFixed(2)}</b>
          </div>
          <div className="ml-8">
            Actual Time: <b>{actual.toFixed(2)}</b>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PeriodTotalsCard;
