import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import DateInput from 'shared/components/DateInput';
import CenteredModal from 'shared/components/Modals/CenteredModal';
import Select from 'shared/components/Select';
import { NumberInput } from 'shared/components/TextInput';
import { altArrangementTypeOptions, CcsAltArrangementType } from 'shared/constants/enums/ccssDebtEnums';
import './ccsAltArrangementModal.scss';

interface IProps {
  services: IServicesByBusiness[];
  onSubmit: (data: IAltArrangementInput) => void;
  show: boolean;
  loading: boolean;
  onClose: () => void;
}

interface IFormErrors {
  type: string;
  startDate: string;
  evenAmountsPaymentPeriod: string;
  offsettingArrangementPercentage: string;
  offsettingArrangementServiceId: string;
  services: string;
}
const CCSDebtAltArrangementModal: React.FC<IProps> = ({ services, onSubmit, show, loading, onClose }) => {
  const initialValues: IAltArrangementInput = {
    type: '',
    startDate: '',
    evenAmountsPaymentPeriod: '',
    offsettingArrangementPercentage: null,
    offsettingArrangementServiceId: '',
    providerId: '',
    businessId: '',
  };
  const [values, setValues] = useState<IAltArrangementInput>(initialValues);

  const [formErrors, setFormErrors] = useState({
    errors: {
      type: '',
      startDate: '',
      evenAmountsPaymentPeriod: '',
      offsettingArrangementPercentage: '',
      offsettingArrangementServiceId: '',
      services: '',
    },
    isValid: false,
  });
  const { WHS, P2P } = CcsAltArrangementType;

  const validateInput = (values: IAltArrangementInput) => {
    const errors = {
      type: '',
      startDate: '',
      evenAmountsPaymentPeriod: '',
      offsettingArrangementPercentage: '',
      offsettingArrangementServiceId: '',
      services: '',
    };
    const {
      startDate,
      evenAmountsPaymentPeriod,
      offsettingArrangementPercentage,
      type,
      offsettingArrangementServiceId,
    } = values;
    if (startDate === '') {
      errors.startDate = 'Start date is required.';
    }

    if (type === '') {
      errors.type = 'Type is required.';
    }

    if (type === P2P) {
      if (evenAmountsPaymentPeriod === '') {
        errors.evenAmountsPaymentPeriod = 'Payment period is required';
      } else if (
        evenAmountsPaymentPeriod &&
        (parseInt(evenAmountsPaymentPeriod) > 6 || parseInt(evenAmountsPaymentPeriod) < 2)
      ) {
        errors.evenAmountsPaymentPeriod = 'Payment period must be between 2 - 6 months';
      }
    } else if (type === WHS) {
      if (!offsettingArrangementPercentage) {
        errors.offsettingArrangementPercentage = 'Offset percentage is required';
      } else if (offsettingArrangementPercentage < 50 || offsettingArrangementPercentage > 100) {
        errors.offsettingArrangementPercentage = 'Offset percentage must be between 50% - 100%';
      }

      if (offsettingArrangementServiceId === '') {
        errors.offsettingArrangementServiceId = 'Service ID is required';
      }
    }
    return { errors, isValid: !Object.values(errors).some((err) => err !== '') };
  };
  useEffect(() => {
    const { errors, isValid } = validateInput(values);
    setFormErrors({ errors, isValid });
  }, [values]);

  useEffect(() => {
    if (values.type === WHS) {
      setValues({
        ...values,
        evenAmountsPaymentPeriod: '',
      });
    } else if (values.type === P2P) {
      setValues({
        ...values,
        offsettingArrangementPercentage: 0,
        offsettingArrangementServiceId: null,
      });
    }
  }, [values.type]);

  const handleSubmit = () => {
    const { type } = values;
    const payload = {
      ...values,
      evenAmountsPaymentPeriod: type === P2P ? `${values.evenAmountsPaymentPeriod}` : null,
      offsettingArrangementServiceId: type === WHS ? values.offsettingArrangementServiceId : null,
      offsettingArrangementPercentage: type === WHS ? values.offsettingArrangementPercentage : null,
      startDate: moment.utc(values.startDate).format(),
    };
    onSubmit(payload);
  };

  const handleClose = () => {
    setValues(initialValues);
    onClose();
  };

  return (
    <CenteredModal
      show={show}
      onHide={handleClose}
      primaryButtonProps={{
        disabled: !formErrors.isValid,
        loading,
      }}
      primaryCallback={handleSubmit}
      secondaryCallback={handleClose}
      size="lg"
      title="Alternate Arrangement"
      primaryChoice="Submit"
      dialogClassName="alt-arrangement-modal"
    >
      <Row className="mb-3">
        <Col lg={3}>
          <Select
            className="mb-1"
            label="Type"
            value={values.type}
            options={altArrangementTypeOptions || []}
            name="type"
            required
            onChange={(value) => {
              setValues((formValues) => ({ ...formValues, type: value.value }));
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={5}>
          <DateInput
            label="Request date to start payment"
            date={values.startDate}
            required
            className="date-input-block"
            onDateSelect={(value) => {
              setValues((formValues) => ({ ...formValues, startDate: value }));
            }}
          />
        </Col>
        <Col lg={3}>
          <NumberInput
            label="Payment period"
            required={values.type === 'P2P'}
            max="6"
            min="2"
            disabled={values.type === WHS}
            errorText={formErrors.errors.evenAmountsPaymentPeriod}
            isInvalid={formErrors.errors.evenAmountsPaymentPeriod !== ''}
            value={values.evenAmountsPaymentPeriod}
            onChange={(value) => {
              setValues((formValues) => ({ ...formValues, evenAmountsPaymentPeriod: value }));
            }}
          />
        </Col>
        <Col className="payment-period-label">Months</Col>
      </Row>

      <Row>
        <Col lg={8}>
          <Select
            className="mb-1"
            label="Service ID to be used for Offsetting"
            value={values.offsettingArrangementServiceId}
            options={services || []}
            getOptionLabel={(service: IServicesByBusiness) => (service ? `${service.serviceId} - ${service.name}` : '')}
            name="service id"
            disabled={values.type === P2P}
            required={values.type === WHS}
            getOptionValue={(c: IServicesByBusiness) => c.serviceId}
            onChange={(value) => {
              setValues((formValues) => ({ ...formValues, offsettingArrangementServiceId: value.serviceId }));
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg={3}>
          <NumberInput
            label="Offset percentage"
            placeholder="50% - 100%"
            disabled={values.type === P2P}
            errorText={formErrors.errors.offsettingArrangementPercentage}
            max="100"
            min="50"
            value={values.offsettingArrangementPercentage}
            isInvalid={formErrors.errors.offsettingArrangementPercentage !== ''}
            onChange={(value) => {
              setValues((formValues) => ({ ...formValues, offsettingArrangementPercentage: parseInt(value) }));
            }}
          />
        </Col>
      </Row>
    </CenteredModal>
  );
};

export default CCSDebtAltArrangementModal;
