import { takeLatest, put } from 'redux-saga/effects';
import * as actions from './actions';
import { AUTHENTICATED } from 'pages/Login/duck/types';
import { CoreApolloClient } from 'shared/apis/core';
import { GET_USER } from './graphql/queries';
import User from 'shared/types/user';
import { setCurrentBusiness } from 'store/context/actions';

// @ts-ignore
// TODO: fix typing of any
function* getUser(): any {
  try {
    const getUserResponse = yield CoreApolloClient.query({ query: GET_USER });
    const { data } = getUserResponse;
    const user = new User(data.getMe);
    yield put(actions.getUserSuccess(user));
    yield put(
      setCurrentBusiness(user.entityId || localStorage.getItem(`${user?.email}-last-selected-businessId`) || '')
    );
  } catch (error) {
    console.log(error);
  }
}

export const userSagas = [takeLatest(AUTHENTICATED, getUser)];
