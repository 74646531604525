import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { QueryHookOptions } from '@apollo/client';
import { royaltyFee, royaltyFeeConfig, royaltyFeeData } from './fields';

interface IRoyaltyFeeConfig {
  id: string;
  businessId: string;
  includeTransactionDebit: boolean;
  includeFees: boolean;
}

interface IRoyaltyFee {
  id: string;
  businessId: string;
  name: string;
  percentage: number;
  royaltyExceptions: IRoyaltyException[];
}

interface IRoyaltyException {
  id: string;
  name: string;
  overridePercentage: number;
  royaltyFeeId: string;
  participantCenterIds: string[];
}

interface ISearchRoyaltyFeeInput {
  businessId: string;
  from: number;
  size: number;
  nameContains?: string;
  sortDirection?: string;
}
interface IRoyaltyFeePagedResult {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  data: IRoyaltyFee[];
}

export const GET_ROYALTY_CONFIG = gql`
  query($id: ID!) {
    getRoyaltyConfigByBusinessId(id: $id) {
      ${royaltyFeeConfig}
    }
  }
`;

export const GET_ROYALTY_FEES = gql`
  query($id: ID!) {
    getRoyaltyFeesByBusinessId(id: $id) {
      ${royaltyFee}
    }
  }
`;

export const GET_ROYALTY_FEE_BY_ID = gql`
  query($id: ID!, $businessId: ID!) {
    getRoyaltyFeeById(id: $id, businessId: $businessId) {
      ${royaltyFee}
    }
  }
`;

export const SEARCH_ROYALTY_FEES = gql`
  query($input: SearchRoyaltyFeeInput!) {
    searchRoyaltyFee(input: $input) {
      pageNumber
      pageSize
      totalRecords
      ${royaltyFeeData}
    }
  }
`;

export const useGetRoyaltyConfig = (
  options?: QueryHookOptions<{ getRoyaltyConfigByBusinessId: IRoyaltyFeeConfig }, { id: string }>
) =>
  useQuery(GET_ROYALTY_CONFIG, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useGetRoyaltyFees = (
  options?: QueryHookOptions<{ getRoyaltyFeesByBusinessId: IRoyaltyFee[] }, { id: string }>
) =>
  useQuery(GET_ROYALTY_FEES, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useGetRoyaltyFeeById = (
  options?: QueryHookOptions<{ getRoyaltyFeeById: IRoyaltyFee }, { id: string; businessId: string }>
) =>
  useQuery(GET_ROYALTY_FEE_BY_ID, {
    fetchPolicy: 'network-only',
    ...options,
  });

export const useSearchRoyaltyFees = (
  options?: QueryHookOptions<{ searchRoyaltyFee: IRoyaltyFeePagedResult }, { input: ISearchRoyaltyFeeInput }>
) =>
  useQuery(SEARCH_ROYALTY_FEES, {
    fetchPolicy: 'network-only',
    ...options,
  });
