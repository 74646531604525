import React from 'react';
import { Card as BSCard, Col, Container, Row as BSRow } from 'react-bootstrap';

interface IProps {
  item: { id: number; name: string; description: string; amount: number | string; finances: boolean };
}

export const MainInfoCard: React.FC<IProps> = ({ item }) => {
  return (
    <BSCard>
      <BSCard.Body>
        <Container fluid>
          <BSRow className="justify-content-between">
            <Col className="align-self-center">
              <p className={`h1 text-secondary font-weight-bold font-size-24`}>{item.name}</p>
              <small className={`sans-serif font-size-12 d-flex`} style={{ maxWidth: '155px' }}>
                {item.description}
              </small>
            </Col>
            <Col className={'d-flex flex-column justify-content-center align-items-end'}>
              <p className={`h1 text-secondary font-weight-bold`} style={{ fontSize: 34 }}>
                {item.finances && '$'}
                {item.amount}
              </p>
            </Col>
          </BSRow>
        </Container>
      </BSCard.Body>
    </BSCard>
  );
};
