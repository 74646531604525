import React, { useState, useCallback } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { orderBy } from 'lodash';
import SideModalDrawer from 'shared/components/ModalDrawer';
import { Row, Col } from 'shared/components/Layout';
import DateInput from 'shared/components/DateInput';
import Checkbox from 'shared/components/Checkbox';
import Select from 'shared/components/Select';
import { useTranslation } from 'react-i18next';
import { useGetDiscountsWithFilters } from 'gql/discount/queries';
import { getFullName } from 'shared/util/string';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import { useCreateAppliedAccountDiscount } from 'gql/discount/mutations';
import { showToast } from 'shared/components/Toast';
import { useDispatch } from 'react-redux';
import { applyDiscountToAccountSuccess } from 'pages/Families/subroutes/Accounts/duck/actions';
import Alert from 'shared/components/Alert';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IFormShape {
  start: string;
  end: string | null;
  discount: IDiscount | null;
  appliesTo: 'ACCOUNT' | 'CHILD' | null;
  childIds: string[];
}

interface IProps {
  isOpen: boolean;
  account: IAccount;
  onClose: () => void;
}

const AddDiscountModal: React.FC<IProps> = ({ isOpen, account, onClose, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['billing']);
  const [isOngoingDiscount, setIsOnGoingDiscount] = useState<boolean>(false);
  const [formData, setFormData] = useState<IFormShape>({
    start: '',
    end: null,
    discount: null,
    appliesTo: null,
    childIds: [],
  });
  const { k2DiscountsBackdating } = useFlags();

  const { data: getDiscountsWithFiltersData, loading: getDiscountsForBusinessLoading } = useGetDiscountsWithFilters({
    variables: {
      input: {
        businessId: account.entityId,
        centerId: account.centerId,
        discountType: 'RECURRING',
        start: formData.start,
        end: formData.end ?? undefined,
      },
    },
    skip: !account,
  });

  const [createAppliedDiscountFn, { loading: createAppliedDiscountLoading }] = useCreateAppliedAccountDiscount({
    onCompleted: (result) => {
      dispatch(applyDiscountToAccountSuccess(account.id, result.createAppliedAccountDiscount));
      showToast(t('billing:discounts.account.apply-modal.success-toast-message'), 'success');
      handleClose();
    },
    onError: (err) => {
      showToast(t('billing:discounts.account.apply-modal.error-toast-message'), 'error');
    },
  });

  const orderDiscounts = useCallback((discounts: IDiscount[]): IDiscount[] => {
    // only active and recurring discounts should be able to be selected
    return orderBy(discounts, (discount) => discount.name.toLowerCase(), 'asc');
  }, []);

  const groupDiscountsByType = useCallback(
    (discounts: IDiscount[]): { label: string; options: IDiscount[] }[] => {
      const orderedDiscounts = orderDiscounts(discounts);

      return [
        {
          label: `${t('billing:discounts.modal-inputs.balance-applied-to')} Discounts`,
          options: orderedDiscounts.filter((discount) => discount.appliesTo === 'BALANCE'),
        },
        {
          label: `${t('billing:discounts.modal-inputs.billing-cycle-applied-to')} Discounts`,
          options: orderedDiscounts.filter((discount) => discount.appliesTo === 'BILLING_CYCLE'),
        },
        {
          label: `${t('billing:discounts.modal-inputs.fee-applied-to')} Discounts`,
          options: orderedDiscounts.filter((discount) => discount.appliesTo === 'SESSION'),
        },
        {
          label: `${t('billing:discounts.modal-inputs.session-gap-applied-to')} Discounts`,
          options: orderedDiscounts.filter((discount) => discount.appliesTo === 'SESSION_GAP'),
        },
      ];
    },
    [orderDiscounts, t]
  );

  const isStartDateOutsideRange = useCallback(
    (day: any): boolean => {
      const dayIsAfterEndDate = formData.end !== null && moment(day).isSameOrAfter(moment(formData.end));
      if (!k2DiscountsBackdating) {
        return moment(day).isBefore(moment()) || dayIsAfterEndDate;
      }

      return dayIsAfterEndDate;
    },
    [formData.end, k2DiscountsBackdating]
  );

  const handleSave = useCallback(() => {
    if (formData.discount) {
      createAppliedDiscountFn({
        variables: {
          input: {
            accountId: account.id,
            discountId: formData.discount.id,
            accountChildIds: formData.appliesTo === 'CHILD' ? formData.childIds : null,
            startDate: formData.start,
            endDate: formData.end,
          },
        },
      });
    }
  }, [account, formData, createAppliedDiscountFn]);

  const handleClose = useCallback(() => {
    setFormData({
      start: '',
      end: null,
      discount: null,
      appliesTo: null,
      childIds: [],
    });
    onClose();
  }, [onClose]);

  const showAppliesToAccountRadio = formData.discount?.appliesTo === 'BALANCE' || formData.discount?.appliesTo;
  const showAppliesToChildRadio =
    formData.discount?.appliesTo === 'BILLING_CYCLE' || formData.discount?.appliesTo === 'SESSION';
  const formDisabled =
    !formData.start ||
    !formData.discount ||
    !formData.appliesTo ||
    (formData.appliesTo === 'CHILD' && !formData.childIds.length);

  const showMultipleAppliedDiscountsWarning = account.discounts?.some(
    (d) => d.accountChildId === null || formData.childIds.includes(d.accountChildId)
  );

  const showBackdatedDiscountWarning = formData.start && moment(formData.start).isBefore(moment());

  return (
    <SideModalDrawer
      title={t('billing:discounts.account.apply-modal.title')}
      show={isOpen}
      onHide={handleClose}
      closeOnPrimaryCallback={false}
      primaryChoice={t('billing:discounts.modal-inputs.primary-action-button')}
      secondaryChoice={t('billing:discounts.modal-inputs.secondary-action-button')}
      primaryCallback={handleSave}
      primaryButtonProps={{ disabled: formDisabled, loading: createAppliedDiscountLoading }}
      secondaryCallback={handleClose}
      enforceFocus={false}
    >
      {showMultipleAppliedDiscountsWarning && (
        <Alert variant="warning" className="mb-4">
          {t('billing:discounts.account.apply-modal.multiple-discounts-applied-message')}
        </Alert>
      )}
      {showBackdatedDiscountWarning && (
        <Alert variant="warning" className="mb-4">
          {t('billing:discounts.account.apply-modal.backdated-discount-warning')}
        </Alert>
      )}
      <Row className="mb-4">
        <Col>
          <DateInput
            required
            label={t('billing:discounts.modal-inputs.start-date-input-label')}
            date={formData.start}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, start: date }))}
            className="kt-date-input-no-max-width"
            isOutsideRange={isStartDateOutsideRange}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <DateInput
            label={t('billing:discounts.modal-inputs.end-date-input-label')}
            date={formData.end}
            onDateSelect={(date) => setFormData((prev) => ({ ...prev, end: date }))}
            disabled={isOngoingDiscount}
            className="kt-date-input-no-max-width"
            isOutsideRange={(day) => moment(day).isSameOrBefore(moment(formData.start))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            required
            label={t('billing:discounts.account.apply-modal.discount-input-label')}
            options={groupDiscountsByType(getDiscountsWithFiltersData?.getDiscountsWithFilters ?? [])}
            onChange={(option: IDiscount) => setFormData((prev) => ({ ...prev, discount: option }))}
            getOptionLabel={(option: IDiscount) => option.name}
            getOptionValue={(option: IDiscount) => option.id}
            isLoading={getDiscountsForBusinessLoading}
            disabled={getDiscountsForBusinessLoading}
            noOptionsMessage={() => t('billing:discounts.account.apply-modal.empty-discounts-message')}
          />
        </Col>
      </Row>
      <Collapse in={formData.discount !== null}>
        <div>
          <Row className="mb-4">
            <Col>
              <Form.Label>{t('billing:discounts.modal-inputs.applies-to-input-label')}</Form.Label>
              {showAppliesToAccountRadio && (
                <Form.Check className="d-flex align-items-center mb-2">
                  <Form.Check.Input
                    id="applies-to-account-radio"
                    type="radio"
                    name="applies-to-option"
                    onClick={() => setFormData((prev) => ({ ...prev, appliesTo: 'ACCOUNT' }))}
                  />
                  <Form.Check.Label>
                    {t('billing:discounts.account.apply-modal.applies-to-account-label')}
                    {account.name}
                  </Form.Check.Label>
                </Form.Check>
              )}
              {showAppliesToChildRadio && (
                <Form.Check className="d-flex align-items-center mb-2">
                  <Form.Check.Input
                    id="applies-to-child-radio"
                    type="radio"
                    name="applies-to-option"
                    onClick={() => setFormData((prev) => ({ ...prev, appliesTo: 'CHILD' }))}
                  />
                  <Form.Check.Label>
                    {t('billing:discounts.account.apply-modal.applies-to-child-label')}
                  </Form.Check.Label>
                </Form.Check>
              )}
            </Col>
          </Row>
          {showAppliesToChildRadio && (
            <Collapse in={formData.appliesTo === 'CHILD'}>
              <Row className="mb-4">
                <Col>
                  <Form.Label>{t('billing:discounts.account.apply-modal.apply-to-child-section-label')}</Form.Label>
                  {account.children.map((child, idx) => (
                    <Checkbox
                      key={`child-checkbox-${child.id}-${idx}`}
                      label={`${getFullName(child)} (${getAgeStringFromDateOfBirth(moment(child.dob), false)})`}
                      value={formData.childIds.includes(child.accountChildId)}
                      onChange={(checked) =>
                        checked
                          ? setFormData((prev) => ({ ...prev, childIds: [...prev.childIds, child.accountChildId] }))
                          : setFormData((prev) => ({
                              ...prev,
                              childIds: prev.childIds.filter((id) => id !== child.accountChildId),
                            }))
                      }
                      className="mb-4"
                    />
                  ))}
                </Col>
              </Row>
            </Collapse>
          )}
        </div>
      </Collapse>
    </SideModalDrawer>
  );
};

export default AddDiscountModal;
