import { ApplicationChild, ApplicationScheduleOffer } from 'generated/graphql';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shared/components/Layout';
import useFormatDate from 'shared/hooks/useFormatDate';
import { getAgeStringFromDateOfBirth } from 'shared/util/getAgeStringFromDateOfBirth';
import { generateScheduleDaysWords, getProgramNamesString } from '../../utils';
import { capitalize } from 'lodash';

interface IProps {
  children: ApplicationChild[];
  offerId?: string;
}

export default ({ children, offerId }: IProps) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  return (
    <Row className="multi-child-enquiry-details" key={offerId}>
      {children.map((child) => {
        const childrenSchedulues = child?.offers as ApplicationScheduleOffer[];
        const programNames = getProgramNamesString((childrenSchedulues as ApplicationScheduleOffer[]) ?? []);

        return (
          <Col xs={12} sm={6} key={child.id}>
            <div className="pr-4">
              <h5>
                {child.firstName} {child.lastName}
              </h5>
              <p className="mb-1">
                Date of Birth: {formatDate(child.dateOfBirth)} (
                {getAgeStringFromDateOfBirth(moment(child.dateOfBirth)).replace(/\(|\)/g, '')})
              </p>
              <table className="enquiry-details-table">
                <thead>
                  <tr>
                    <th>
                      <label>{t('enrollment.lead-management.care-type')}</label>
                    </th>
                    <th>
                      <label>{'Days'}</label>
                    </th>
                    {programNames && (
                      <th>
                        <label>{capitalize(t('spelling.program'))}</label>
                      </th>
                    )}
                    <th>
                      <label>{t('enrollment.lead-management.start-date')}</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {child.enquiries.map((e, index) => {
                    const [week1Days, week2Days] = generateScheduleDaysWords(e.days, true);
                    const isMultiWeekSchedule = !!week2Days;
                    const programName = child.offers[index]?.program?.name;

                    return (
                      <tr key={e.id}>
                        <td>
                          <p>{e.careType}</p>
                        </td>
                        <td>
                          {e.cycleType === 'CASUAL' && <p>Casual</p>}
                          {e.cycleType !== 'CASUAL' && (
                            <>
                              <p>
                                <label className="underline">
                                  <u>{isMultiWeekSchedule ? capitalize(t('spelling.biweekly')) : 'Weekly'} </u>
                                </label>
                                <br />
                                {isMultiWeekSchedule && 'Week 1: '}
                                {week1Days}
                              </p>
                              <p>
                                {isMultiWeekSchedule && 'Week 2: '}
                                {week2Days}
                              </p>
                            </>
                          )}
                        </td>
                        {programNames && (
                          <td>
                            <p className="text-wrap">{programName}</p>
                          </td>
                        )}
                        <td>
                          <p>{formatDate(e.startDate)}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};
