import { gql } from '@apollo/client';
import { useQuery } from 'shared/apis/core';
import { medicareCardFields } from './fields';

interface IGetMedicareCardByChildIdData {
  getMedicareCardByChildId: IMedicareCard[];
}

interface IGetMedicareCardByChildIdVariables {
  childId: string;
}

export const GET_MEDICARE_CARD_BY_CHILD_ID = gql`
  query ($childId: ID!) {
    getMedicareCardByChildId(childId: $childId) {
      ${medicareCardFields}
    }
  }
`;

export const useGetMedicareCardByChildId = (childId: string) =>
  useQuery<IGetMedicareCardByChildIdData, IGetMedicareCardByChildIdVariables>(GET_MEDICARE_CARD_BY_CHILD_ID, {
    variables: {
      childId,
    },
  });
