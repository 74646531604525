import React from 'react';
import { ApolloError } from '@apollo/client';
import Alert from '../Alert';
import errorMessages from '../../constants/errorMessages';

interface IProps {
  errorApolloError: ApolloError;
}

const AlertContainer: React.FC<IProps> = ({ errorApolloError, ...props }) => {
  if (errorApolloError && errorApolloError.graphQLErrors)
    return (
      <>
        {errorApolloError.graphQLErrors?.map((value) => {
          return <Alert variant="danger">{value.message}</Alert>;
        })}
      </>
    );
  else return <Alert variant="danger">{errorMessages.generic}</Alert>;
};

export default AlertContainer;
