import React, { useMemo } from 'react';
import classnames from 'classnames';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRoute } from 'shared/util/routes';
import { faRocket } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  sidebarExpanded: boolean;
  route: IRoute;
  subRoute: IRoute;
  navigateTo: (route: IRoute) => void;
}

const K2SidebarSubItem: React.FC<IProps> = ({ sidebarExpanded, route, subRoute, navigateTo }) => {
  const routeMatch = useRouteMatch(subRoute.route);

  const classes = useMemo(() => {
    return classnames({
      'd-flex': true,
      'align-items-center': true,
      'w-100': true,
      'k2-navigation-sidebar-item': true,
      'justify-content-center': !sidebarExpanded,
      expanded: sidebarExpanded,
    });
  }, [sidebarExpanded]);

  if (subRoute.doNotShowInSidebar) {
    return null;
  }

  return (
    <NavLink
      exact={false}
      id={`nav-${route.name.toLowerCase()}-subroute-${subRoute.name.toLowerCase()}`}
      key={`sidebar-route-${route.name}-subroute-${subRoute.name}`}
      aria-label={subRoute.name}
      to={subRoute.navLinkTo ?? subRoute.route}
      activeClassName="active"
      isActive={(match, location) => {
        return !!routeMatch;
      }}
      onClick={() => navigateTo(subRoute)}
    >
      <div className={classes}>
        <FontAwesomeIcon icon={subRoute.icon ?? faRocket} />
        {sidebarExpanded && <span className="d-block ml-4 mr-auto">{subRoute.name}</span>}
      </div>
    </NavLink>
  );
};

export default K2SidebarSubItem;
