import React from 'react';

import * as Mui from '@mui/material';
import _ from 'lodash';

interface ICenterRow {
  id: string;
  avatarUrl?: string;
  name: string;
  city: string;
}

interface IRowProps {
  row: ICenterRow;
  selectedCenters: string[];
  setSelectedCenters: (value: string[]) => void;
  participantCenterIds: string[] | null;
  alreadySelectedCenters: string[] | null;
}

const CenterTableRow: React.FC<IRowProps> = ({
  row,
  selectedCenters,
  setSelectedCenters,
  participantCenterIds,
  alreadySelectedCenters,
}) => {
  const isNew = participantCenterIds === null;
  const iAmNotInThisList = (i: string) => participantCenterIds?.filter((c) => c === i).length === 0;

  const [centersICantChange, setCentersICantChange] = React.useState(
    isNew ? _.reject(alreadySelectedCenters, iAmNotInThisList) : _.filter(alreadySelectedCenters, iAmNotInThisList)
  );
  const [checked, setChecked] = React.useState(
    !_.isNil(participantCenterIds) ? participantCenterIds.filter((c) => c === row.id).length > 0 : false
  );
  const isDisabled = React.useMemo(() => {
    if (!_.isNil(centersICantChange)) {
      return centersICantChange.filter((c) => c === row.id).length > 0 && !checked;
    }
    return false;
  }, [row, centersICantChange, checked]);

  const onCheck = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(evt.target.checked);
    if (evt.target.checked) {
      setSelectedCenters(selectedCenters.concat(row.id));
    } else {
      setSelectedCenters(_.pull(selectedCenters, row.id));
    }
  };

  return (
    <Mui.TableRow key={row.name} sx={{ border: 'none' }}>
      <Mui.TableCell align="left" padding="checkbox">
        <Mui.Checkbox color="primary" checked={checked} disabled={isDisabled} onChange={(evt) => onCheck(evt)} />
      </Mui.TableCell>
      <Mui.TableCell align="left">
        <Mui.Stack spacing={1} alignItems="center" direction="row">
          {_.isNil(row.avatarUrl) ? (
            <Mui.Avatar
              alt={row.name}
              sx={{
                bgcolor: '#4682b4',
                opacity: isDisabled ? '50%' : '100%',
              }}
            >
              {row.name.charAt(1).toUpperCase()}
            </Mui.Avatar>
          ) : (
            <Mui.Avatar alt={row.name} src={row.avatarUrl} sx={{ opacity: isDisabled ? '50%' : '100%' }} />
          )}
          <Mui.Typography variant={isDisabled ? 'bodyDisabled' : 'body1'}>{row.name}</Mui.Typography>
        </Mui.Stack>
      </Mui.TableCell>
      <Mui.TableCell align="left">
        <Mui.Typography variant={isDisabled ? 'bodyDisabled' : 'body1'}>{row.city}</Mui.Typography>
      </Mui.TableCell>
      {/* <Mui.TableCell align="left">
      <Mui.Typography variant="body1">{row.tags}</Mui.Typography>
    </Mui.TableCell> */}
    </Mui.TableRow>
  );
};

export default CenterTableRow;
