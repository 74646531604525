import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { IconDefinition, faRocket } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '../Tooltip';

interface IConditionalToolTipProps {
  children: React.ReactNode;
  tooltipText?: string;
  tooltipDirection?: 'left' | 'right' | 'top' | 'bottom';
}

const ConditionalToolTip: React.FC<IConditionalToolTipProps> = ({
  children,
  tooltipText,
  tooltipDirection = 'top',
}) => {
  if (tooltipText) {
    return (
      <Tooltip text={tooltipText} direction={tooltipDirection}>
        {children}
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
};

interface IProps {
  icon: IconDefinition;
  iconSize?: 'xs' | 'sm' | 'lg' | '1x' | '2x' | '3x';
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  color?: string;
  className?: string;
  disabled?: boolean;
  tooltipText?: string;
  tooltipDirection?: 'left' | 'right' | 'top' | 'bottom';
}

const IconButton: React.FC<IProps> = ({
  icon = faRocket,
  iconSize = 'lg',
  onClick,
  color,
  className,
  disabled,
  tooltipText,
  tooltipDirection,
  ...props
}) => (
  <ConditionalToolTip tooltipText={tooltipText} tooltipDirection={tooltipDirection}>
    <Button
      {...props}
      disabled={disabled}
      onClick={onClick}
      className={`bg-transparent kt-button icon-button ${className}`}
      variant="light"
    >
      <FontAwesomeIcon size={iconSize} icon={icon} color={color} />
    </Button>
  </ConditionalToolTip>
);

export default IconButton;
