import React from 'react';
import { Col } from 'shared/components/Layout';
import Card from 'shared/components/Card/Card';
import Switch from 'shared/components/Switch';
import { showToast } from 'shared/components/Toast';
import { useUpsertBusinessConfiguration } from 'gql/businessConfiguration/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { setCurrentBusinessConfiguration } from 'store/context/actions';
import { useGetBusinessConfigurationOptionsById } from 'gql/businessConfiguration/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSirenOn, faSwordsLaser } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  entityId: string;
}

const ParentContactManagementCard: React.FC<IProps> = ({ entityId }) => {
  const dispatch = useDispatch();
  const parentContactManagementOn = useSelector(
    (state: RootState) => state.context.businessConfiguration?.parentContactManagementEnabled
  );
  const [parentContactManagementEnabled, setparentContactManagementEnabled] = React.useState<boolean>(
    parentContactManagementOn ?? false
  );

  const { data: getBusinessConfigData, loading } = useGetBusinessConfigurationOptionsById({
    variables: {
      id: entityId,
    },
  });

  React.useEffect(() => {
    if (getBusinessConfigData?.getBusinessConfigurationByBusinessId != null)
      setparentContactManagementEnabled(
        getBusinessConfigData?.getBusinessConfigurationByBusinessId.parentContactManagementEnabled ?? false
      );
  }, [getBusinessConfigData]);

  const [upsertBusinessConfig, { loading: loadingSave }] = useUpsertBusinessConfiguration({
    onCompleted: (getBusinessConfigData) => {
      dispatch(setCurrentBusinessConfiguration(getBusinessConfigData.upsertBusinessConfiguration));
      showToast(
        `Parent Contact Management is  ${parentContactManagementEnabled ? 'enabled' : 'disabled'} for business`,
        'success'
      );
    },
    onError: () => {
      showToast('Error updating Parent Contact Management Settings', 'error');
    },
  });

  const onToggle = (value: boolean) => {
    setparentContactManagementEnabled(value);
    upsertBusinessConfig({
      variables: {
        input: {
          businessId: entityId,
          parentContactManagementEnabled: value,
        },
      },
    });
  };

  return (
    <Card header="Parent Contact Management Enabled" loading={false} loadingLines={4}>
      <div style={{ display: 'flex', alignContent: 'center', marginBottom: '12px' }}>
        <div style={{ textAlign: 'center' }}>Allow the business to show current contacts in the parent app</div>
      </div>
      <Switch
        labelSide="top"
        value={parentContactManagementEnabled}
        onChange={(value: boolean) => onToggle(value)}
        className="mb-4"
        aria-describedby="enable or disable the parent contact management features"
        id="parent-contact-management-features-switch"
        disabled={loading || loadingSave}
      />
    </Card>
  );
};

export default ParentContactManagementCard;
