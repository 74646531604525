import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Divider } from '@mui/material';
import React from 'react';
import { IconButtonCircle } from 'shared/components/Buttons';
import DateInput from 'shared/components/DateInput';
import { CreateBatchButton } from './CreateBatchButton';

interface IFilterBarProps {
  dateRangeStart: string | null;
  dateRangeEnd: string | null;
  onDateRangeStartChange: (date: string | null) => void;
  onDateRangeEndChange: (date: string | null) => void;
  onClear: () => void;
  canCreateBatch: boolean;
  onCreateBatchClick: () => void;
}
export function ControllBar(props: IFilterBarProps) {
  const {
    dateRangeStart,
    dateRangeEnd,
    onDateRangeStartChange,
    onDateRangeEndChange,
    onClear,
    onCreateBatchClick,
    canCreateBatch,
  } = props;
  return (
    <div className="batchable_payments__control_container">
      <div className="batchable_payments__filter_container">
        <div className="batchable_payments__filter_container__left">
          <DateInput dateOnly date={dateRangeStart} onDateSelect={onDateRangeStartChange}></DateInput>
          <div className="mx-2">to</div>
          <DateInput dateOnly date={dateRangeEnd} onDateSelect={onDateRangeEndChange}></DateInput>
        </div>
        <div>
          <IconButtonCircle icon={faTimes} onClick={onClear} tooltipDirection="bottom" tooltipText="Clear Filters" />
        </div>
      </div>
      <Divider />
      <CreateBatchButton onClick={onCreateBatchClick} disabled={!canCreateBatch}></CreateBatchButton>
    </div>
  );
}
