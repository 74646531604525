import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'shared/components/Select';

interface ICenterSelectorProps {
  centers: ICenter[];
  onSelectedCenterChange: (centerId: string | undefined) => void;
  selectedCenter: string | undefined;
  loadingCenters: boolean;
}
export function CenterSelector(props: ICenterSelectorProps) {
  const { t } = useTranslation(['billing']);
  const { centers, onSelectedCenterChange, selectedCenter, loadingCenters } = props;
  const options = [
    { value: 'all', label: 'All' },
    ...(centers?.map((c) => ({ value: c.id, label: c.name })).sort((a, b) => a.label.localeCompare(b.label)) ?? []),
  ];

  return (
    <div className="d-flex align-items-center bg-baby-blue py-2 px-4 rounded">
      <div>{t('billing:transactions.create-batch-modal.select-center-to-create')}</div>
      <div className="ml-auto" style={{ width: 240 }}>
        <Select
          options={options}
          onChange={(option) => {
            onSelectedCenterChange(option.value === 'all' ? undefined : option.value);
          }}
          value={selectedCenter || 'all'}
          className="mb-0 flex-grow-0"
          isLoading={loadingCenters}
        />
      </div>
    </div>
  );
}
