import React, { useCallback, useState, useEffect, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Collapse from 'react-bootstrap/Collapse';
import Fade from 'react-bootstrap/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import Button from 'shared/components/Buttons';
import { useGetCenterScheduleForWeek } from '../../graphql/queries';
import Spinner from 'shared/components/Spinner';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { setOtherZone } from 'shared/util/timeUtils';
import { DefaultCountry } from '../../../../../../shared/components/DateInput/countryCodes';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface ICopyScheduleState {
  start: moment.Moment | null;
  end: moment.Moment | null;
}

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  centerId: string;
  startOfVisibleWeek: moment.Moment;
  onClose: () => void;
  onSave: (weekToCopyTo: moment.Moment) => void;
}

// this component renders a modal that will allow a user to choose a week in the future to copy the existing schedule to
const CopyScheduleModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  centerId,
  startOfVisibleWeek,
  onClose,
  onSave,
  ...props
}) => {
  const dateSettings = COUNTRY_INFO[DEFAULT_COUNTRY].dateSettings;
  const timezonesByCenter = useSelector((state: RootState) => state.timezone.byCenterId);
  const timezone = timezonesByCenter[centerId ?? ''] ?? moment.tz.guess();
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>('startDate');
  const [selectedWeek, setSelectedWeek] = useState<ICopyScheduleState>({
    start: null,
    end: null,
  });
  const weekStartInCenterTimeZone = selectedWeek.start ? setOtherZone(selectedWeek.start, timezone) : null;
  const {
    loading: getScheduleForCenterForWeekLoading,
    data: getScheduleForCenterForWeekData,
    refetch: refetchSchedule,
  } = useGetCenterScheduleForWeek(centerId ?? '', weekStartInCenterTimeZone?.toISOString() ?? '');
  useEffect(() => {
    if (selectedWeek.start) {
      refetchSchedule();
    }
  }, [selectedWeek.start, refetchSchedule]);

  const handleDateChange = useCallback(
    (dates: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
      if (dates.startDate && dates.endDate) {
        if (dates.startDate.isSame(selectedWeek.start as Moment)) {
          setSelectedWeek({
            start: null,
            end: null,
          });
        } else {
          setSelectedWeek({
            start: dates.startDate,
            end: dates.endDate,
          });
        }
      }
    },
    [selectedWeek, setSelectedWeek]
  );

  const checkOutsideRange = useCallback((date: moment.Moment): boolean => date < moment().endOf(dateSettings.week), []);

  const selectedDateChoiceSummary = useMemo(() => {
    if (!selectedWeek.start || !selectedWeek.end) return <></>;
    const clonedVisibleStartDateWeek = startOfVisibleWeek.clone();
    return (
      <div className="mb-4">
        Copying {clonedVisibleStartDateWeek.startOf(dateSettings.week)?.format('MMM D')} -{' '}
        {clonedVisibleStartDateWeek.endOf(dateSettings.week).format('MMM D')} to {selectedWeek.start?.format('MMM D')} -{' '}
        {selectedWeek.end?.format('MMM D')}
      </div>
    );
  }, [startOfVisibleWeek, selectedWeek]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal backdrop="static" centered show={isOpen} onHide={onClose} dialogClassName="kt-staff-schedules-shift-modal">
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Copy Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <div className="d-flex flex-column align-items-center">
          <div className="mb-4">Select the future week you wish to copy this schedule to, below.</div>
          {selectedDateChoiceSummary}
          <div className="mb-4">
            <DayPickerRangeController
              hideKeyboardShortcutsPanel
              focusedInput={focusedInput}
              numberOfMonths={1}
              startDate={selectedWeek.start}
              endDate={selectedWeek.end}
              onFocusChange={(input) => setFocusedInput((prev) => (!input ? 'startDate' : prev))}
              onDatesChange={handleDateChange}
              startDateOffset={(day) => moment(day).startOf(dateSettings.week)}
              endDateOffset={(day) => moment(day).endOf(dateSettings.week)}
              isOutsideRange={checkOutsideRange}
              isDayHighlighted={(day) =>
                moment(day).hours(12).isBetween(startOfVisibleWeek, startOfVisibleWeek.clone().endOf(dateSettings.week))
              }
              firstDayOfWeek={DefaultCountry.firstDayOfWeek}
            />
          </div>
          <Fade in={getScheduleForCenterForWeekLoading}>
            <div>
              <span className="mr-2">Checking for an existing schedule...</span>
              <Spinner small />
            </div>
          </Fade>
          <Collapse
            in={
              Boolean(
                getScheduleForCenterForWeekData?.getCenterScheduleForWeek &&
                  getScheduleForCenterForWeekData.getCenterScheduleForWeek.shifts.length
              ) ?? false
            }
          >
            <div>
              <Alert className="bg-info-10">
                <Alert.Heading as="h5">
                  <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
                  Notice
                </Alert.Heading>
                <p className="mb-0">A schedule already exists for the selected week.</p>
              </Alert>
            </div>
          </Collapse>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button variant="light" onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={isLoading}
          disabled={
            isLoading ||
            getScheduleForCenterForWeekLoading ||
            Boolean(
              getScheduleForCenterForWeekData?.getCenterScheduleForWeek &&
                getScheduleForCenterForWeekData.getCenterScheduleForWeek.shifts.length
            )
          }
          onClick={() => selectedWeek.start && onSave(selectedWeek.start)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CopyScheduleModal;
