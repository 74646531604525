import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { showToast } from 'shared/components/Toast';
import AddBankAccountModal from './components/AddBankAccountModal';
import AddCreditCardModal from 'shared/components/Modals/AddCreditCardModal';
import UpdateCreditCardModal from 'shared/components/Modals/UpdateCreditCardModal';
import { updatePaymentMethodForAccountSuccess } from 'pages/Families/subroutes/Accounts/duck/actions';

import DeletePaymentMethodModal from 'shared/components/Modals/DeletePaymentMethodModal';
import { createPaymentMethodForAccountSuccess } from 'pages/Families/subroutes/Accounts/duck/actions';
import { TokenizerService } from 'shared/services/tokenizerService';
import { useSelector } from 'react-redux';
import { PayrixService } from 'shared/services/payrixService';
import getLastFourOfPaymentNumber from 'shared/util/getLastFourOfPaymentNumber';
import { ToastType } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'shared/components/Layout';
import DefaultPaymentMethodCard from './components/DefaultPaymentMethodCard';
import PaymentContactsTable from './components/PaymentContactsTable';
import AutoPayManageCard from './components/AutoPayManageCard';
import AutoPayModal from './components/AutoPayModal';
import DefaultPaymentModal from './components/DefaultPaymentModal';
import { useCreateAuthorizedPersonPaymentMethod } from 'gql/paymentMethod/authorizedPerson/mutation';
import PaymentMethodTable from './components/PaymentMethodTable';
import './overviewTabV2.scss';
import { PeriodBasedAccountBalanceTile } from '../components/PeriodBasedAccountBalances';

interface IPaymentMethodActionModalState {
  open: boolean;
  paymentMethod: IPaymentMethod | null;
}

interface IProps {
  account: IAccount;
  loading: boolean;
  accountId: string;
}

const OverviewTabV2: React.FC<IProps> = ({ account, loading, accountId }) => {
  const { t } = useTranslation(['billing', 'translation']);
  const dispatch = useDispatch();
  const [showAutoPayModal, setShowAutoPayModal] = useState(false);
  const [showDefaultPaymentModal, setShowDefaultPaymentModal] = useState(false);
  const [showAddCreditCardModal, setShowCreditCardModal] = useState(false);
  const [showAddBankAccountModal, setShowAddBankAccountModal] = useState(false);

  const [updateCreditCardModalState, setUpdateCreditCardModalState] = useState<IPaymentMethodActionModalState>({
    open: false,
    paymentMethod: null,
  });
  const [activeContact, setActiveContact] = useState<IContact | undefined>(undefined);
  const [deletePaymentMethodModalState, setDeletePaymentMethodModalState] = useState<IPaymentMethodActionModalState>({
    open: false,
    paymentMethod: null,
  });
  // TODO: Fix this so that it is typed appropriately.
  // @ts-ignore
  const jwtToken = useSelector((state) => state.session.token);

  const [createAuthorizedPersonPaymentMethod] = useCreateAuthorizedPersonPaymentMethod({
    onCompleted: (result) => {
      const paymentMethod = result.createAuthorizedPersonPaymentMethod;
      dispatch(
        createPaymentMethodForAccountSuccess({
          ...paymentMethod,
          accountId: account?.id,
        })
      );
      showToast(t('billing:payment-methods.add-card-success'), 'success');
      setShowCreditCardModal(false);
    },
    onError: () => showToast(t('translation:general.error'), ToastType.ERROR),
  });

  const handleSaveCreditCard = useCallback(
    async (data: ICreateAccountCreditCardInput) => {
      try {
        const name = data.name.split(' ');
        let firstName = name[0];
        let lastName = name[1] ? name[1] : '';

        if (data.contact) {
          firstName = data?.contact?.firstname ?? '';
          lastName = data?.contact?.lastname ?? '';
        }

        const unencryptedTokenizerRequestBody: ITokenizerRequestBody = {
          merchantId: account.center?.merchant?.merchantId,
          centerId: null,
          secretKeyId: null,
          paymentNumber: data.number,
          expiryDate: data.expiration,
          first: firstName,
          last: lastName,
          ccv: data.ccv,
          address1: data.address1 ?? null,
          city: data.city ?? null,
          state: data.state ?? null,
          zip: data.zip ?? null,
          isSandbox: `${account.center?.merchant?.isSandbox ?? 'false'}`,
        };

        const tokenizerService = new TokenizerService();
        const paymentToken: IPayrixToken | IFatZebraCreditCardToken =
          await tokenizerService.encryptAndSendTokenizeRequest(unencryptedTokenizerRequestBody, jwtToken);

        if (paymentToken.toString().includes('error')) {
          showToast(t('translation:general.error'), ToastType.ERROR);
        } else {
          const lastFour: string =
            'payment' in paymentToken
              ? paymentToken.payment.number
              : getLastFourOfPaymentNumber(paymentToken.card_number);
          const processorId: string = 'card_number' in paymentToken ? paymentToken.token : paymentToken.id;
          const expirationDate: string | null =
            'payment' in paymentToken
              ? moment(paymentToken.expiration, 'MMYY').endOf('month').format('YYYY-MM-DD')
              : 'card_expiry' in paymentToken
              ? paymentToken.card_expiry
              : null;

          const input: ICreatePaymentMethodInput = {
            businessId: account.entityId, // TODO: this should be optional
            accountId: account.id,
            lastFour,
            processorId,
            // FatZebra ACH does not return a token but utilizes the id in the same way
            token: paymentToken.token,
            expirationDate,
            type: null,
            // previously we were using the auto-pay checkbox as the auto-pay + default, the default box is hidden behind LD
            isPrimary: data.isDefault,
            isAutoPay: data.isAutoPay,
            // person with authorization input
            personId: data?.contact?.id ?? '',
            centerId: account?.center?.id, // for editing card
          };

          const payrixService = new PayrixService();
          input.type = payrixService.enumValueToString(data.method);

          // new api for the create payment method
          await createAuthorizedPersonPaymentMethod({
            variables: {
              input,
            },
          });
        }
      } catch (error) {
        showToast(t('translation:general.error'), 'error');
      }
    },
    [account, createAuthorizedPersonPaymentMethod, jwtToken, t]
  );

  const legacyPaymentMethods = account?.paymentMethods?.filter((p) => p.personId === null) ?? [];
  return (
    <>
      <div className="kt-billing-overview-tab">
        <Row className="mb-4">
          <Col>
            <PeriodBasedAccountBalanceTile accountId={accountId} showLoading={loading} />
          </Col>
        </Row>
        <Row>
          <Col>
            <AutoPayManageCard
              hasAutoPay={account?.paymentMethods?.some((pm) => pm.isAutoPay) ?? false}
              paymentMethod={account?.paymentMethods?.find((pm) => pm.isAutoPay)}
              disabled={account?.paymentMethods?.length === 0}
              loading={loading}
              onClick={() => setShowAutoPayModal(true)}
            />
          </Col>
          <Col>
            <DefaultPaymentMethodCard
              hasAutoPay={account?.paymentMethods?.some((pm) => pm.isAutoPay) ?? false}
              paymentMethod={account?.paymentMethods?.find((pm) => pm.isPrimary)}
              loading={loading}
              disabled={account?.paymentMethods?.length === 0}
              onClick={() => setShowDefaultPaymentModal(true)}
            />
          </Col>
        </Row>

        <PaymentContactsTable
          loading={false}
          contacts={account?.contacts ?? []}
          paymentMethods={account?.paymentMethods ?? []}
          onCreateCardClick={(contact: IContact) => {
            setShowCreditCardModal(true);
            setActiveContact(contact);
          }}
          showCreateAchButton
          onCreateAchClick={(contact: IContact) => {
            setShowAddBankAccountModal(true);
            setActiveContact(contact);
          }}
          onDeletePaymentMethod={(paymentMethod) => setDeletePaymentMethodModalState({ open: true, paymentMethod })}
          onEditPaymentMethod={(paymentMethod) => setUpdateCreditCardModalState({ open: true, paymentMethod })}
        />
        {legacyPaymentMethods?.length > 0 && (
          <div style={{ marginTop: 70 }}>
            <h4>Payment Methods Unassociated with Contacts</h4>
            <PaymentMethodTable
              paymentMethods={legacyPaymentMethods}
              onDeletePaymentMethod={(paymentMethod) => setDeletePaymentMethodModalState({ open: true, paymentMethod })}
              onEditPaymentMethod={(paymentMethod) => setUpdateCreditCardModalState({ open: true, paymentMethod })}
            />
          </div>
        )}

        {showAutoPayModal && (
          <AutoPayModal
            isOpen={showAutoPayModal}
            accountId={account?.id ?? ''}
            paymentMethods={account.paymentMethods ?? []}
            onClose={() => setShowAutoPayModal(false)}
          />
        )}
        <DefaultPaymentModal
          isOpen={showDefaultPaymentModal}
          accountId={account?.id ?? ''}
          paymentMethods={account?.paymentMethods ?? []}
          onClose={() => setShowDefaultPaymentModal(false)}
        />
        {account && (
          <AddBankAccountModal
            isOpen={showAddBankAccountModal}
            account={account}
            contact={activeContact}
            onClose={() => {
              setShowAddBankAccountModal(false);
              setActiveContact(undefined);
            }}
          />
        )}
        {account && (
          <AddCreditCardModal
            isOpen={showAddCreditCardModal}
            onClose={() => {
              setShowCreditCardModal(false);
              setActiveContact(undefined);
            }}
            onSave={handleSaveCreditCard}
            contact={activeContact}
            billingAddressOptions={
              account.contacts?.filter((g) => g.address?.address1).map((g) => g.address) as IAddress[]
            }
          />
        )}
        {deletePaymentMethodModalState.paymentMethod && (
          <DeletePaymentMethodModal
            isOpen={deletePaymentMethodModalState.open}
            paymentMethod={deletePaymentMethodModalState.paymentMethod}
            account={account}
            onClose={() =>
              setDeletePaymentMethodModalState({
                open: false,
                paymentMethod: null,
              })
            }
          />
        )}

        {updateCreditCardModalState.paymentMethod && (
          <UpdateCreditCardModal
            isOpen={updateCreditCardModalState.open}
            centerTimezone={account.center?.timezone}
            onClose={() =>
              setUpdateCreditCardModalState({
                open: false,
                paymentMethod: null,
              })
            }
            creditCard={updateCreditCardModalState.paymentMethod}
            onSave={(paymentMethod) =>
              dispatch(
                updatePaymentMethodForAccountSuccess({
                  ...paymentMethod,
                  accountId: account?.id,
                })
              )
            }
          />
        )}
      </div>
    </>
  );
};

export default OverviewTabV2;
