import React, { useState, useCallback } from 'react';
import ContactInformationForm from '../Forms/ContactInformationForm';
import LoadingLines from 'shared/components/LoadingSkeletons/Line/LoadingLines';
import Card, { PersonCard } from 'shared/components/Card';
import { Col, Row } from 'shared/components/Layout';
import { ContactModal } from 'shared/components/ContactDisplayComponents';
import COUNTRY_INFO, { DEFAULT_COUNTRY } from 'shared/constants/dropdownOptions/countryInfo';

interface IProps {
  center: ICenter | undefined;
  canPerformEdit: boolean;
  loading: boolean;
}

const ContactInformationCards: React.FC<IProps> = ({ center, canPerformEdit, loading }) => {
  const [mutationLoading, setMutationLoading] = useState(false);
  const [centerContactModal, setCenterContactModal] = useState({
    isOpen: false,
    name: '',
    role: '',
    email: '',
  });

  const showContactDetails = useCallback((person: IStaff) => {
    setCenterContactModal({
      isOpen: true,
      name: `${person.firstname} ${person.lastname}`,
      role: person.roleship?.name,
      email: person.email,
    });
  }, []);

  const dismissContactModal = useCallback(() => {
    setCenterContactModal({
      isOpen: false,
      name: '',
      role: '',
      email: '',
    });
  }, []);

  const fieldLabels = COUNTRY_INFO[DEFAULT_COUNTRY].fieldLabels;
  return (
    <>
      <Card header="Contact Information">
        <p>
          Please assign your preferred contacts, below. The employees selected will be designated for communication and
          notifications related to this {fieldLabels.center.toLowerCase()}.
        </p>
        {loading || !center ? (
          <LoadingLines />
        ) : (
          <ContactInformationForm
            setMutationLoading={setMutationLoading}
            center={center}
            canPerformEdit={canPerformEdit}
          />
        )}
      </Card>
      {!loading && center && (
        <Row>
          {center.primaryContact && (
            <Col sm={4}>
              <PersonCard
                loading={mutationLoading}
                hasAdditionalActions={false}
                person={center.primaryContact}
                onClick={() => showContactDetails(center.primaryContact)}
                subtitle="Primary Contact"
              />
            </Col>
          )}
          {center.secondaryContact && (
            <Col sm={4}>
              <PersonCard
                hasAdditionalActions={false}
                loading={mutationLoading}
                person={center.secondaryContact}
                onClick={() => center.secondaryContact && showContactDetails(center.secondaryContact)}
                subtitle="Secondary Contact"
              />
            </Col>
          )}
        </Row>
      )}
      <ContactModal
        isOpen={centerContactModal.isOpen}
        onClose={dismissContactModal}
        name={centerContactModal.name}
        role={centerContactModal.role}
        email={centerContactModal.email}
      />
    </>
  );
};

export default ContactInformationCards;
